import styled from '@emotion/styled';
import { colorsSecondary, grayScale, shadowLight } from 'app/colors';
import { gutters } from 'app/spacing';
import * as PropTypes from 'prop-types';

const BaseStyles = styled.div`
  ${({ styles }) => styles || ''}
  .Authform-wrapper {
    background-color: ${grayScale.white};
    border-radius: 8px;
    width: 328px;
    box-shadow: 0px 18px 26px rgba(0, 0, 0, 0.03);
    padding: ${gutters.standard};
    margin: 33vh auto;
  }

  .Auth-inactivity-message {
    margin: ${gutters.spacing(15)}px auto ${gutters.spacing(4, 2)}px;
    width: 328px;
    font-weight: 300;
    text-align: center;
    line-height: 130%;
    letter-spacing: -0.25px;
  }

  .Authform-wrapper-with-message {
    background-color: ${grayScale.white};
    border-radius: 8px;
    width: 328px;
    box-shadow: 0px 18px 26px rgba(0, 0, 0, 0.03);
    padding: ${gutters.standard};
    margin: 0 auto 49vh;
  }

  .Authform-wrapper.verify-account {
    width: 400px;
    border: 1px solid ${grayScale.mediumLight};
    ${shadowLight}
  }

  .Authform-header {
    text-align: center;
    line-height: 1.1em;
  }

  .Authform-subheader {
    margin-top: 18px;
  }

  .Authform-buttons-wrapper {
    margin-top: 5px;
    .Authform-buttons {
      display: flex;
    }
  }

  .Authform-loader {
    padding-bottom: 10%;
  }

  .Authform-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${gutters.standard};
  }
  .Authform-actions.single-button-container {
    justify-content: flex-end;
  }

  .Authform-input {
    margin-top: 25px;
  }

  .Authform-password {
    margin-top: 21px;
  }

  .Authform-wrapper.verify-account .Authform-input label {
    margin-bottom: 0;
  }

  .Authform-button {
    margin-right: 0;
  }

  .Authform-success {
    line-height: 125%;
    margin-top: ${gutters.standard};
  }

  .Authform-error {
    margin-top: ${gutters.standard};
    text-align: center;
    color: ${colorsSecondary.red.dark};
  }
  .Authform-external {
    min-height: 153px;
    .icon {
      display: flex;
      min-height: 100px;

      justify-content: center;
    }
    .label {
      text-align: center;
    }
  }
`;

export default function AuthForm({ children, styles, ...rest }) {
  return (
    <BaseStyles {...rest} styles={styles}>
      {children}
    </BaseStyles>
  );
}

AuthForm.propTypes = {
  // expected to be valid CSS text
  styles: PropTypes.string,
};

AuthForm.defaultProps = {
  styles: null,
};
