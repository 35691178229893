import React, { forwardRef } from 'react';
import DashletTypeWizardSection from './sections/DashletType';
import DisplaySettingsWizardSection from './sections/DisplaySettings';
import ValuesAndContraintsWizardSection from './sections/ValuesAndConstraints';
import Wizard from '../shared/components/wizard';
import { AREA_RESPONSES, REPORT_TYPES } from './types';

const isStep2Current = ({ area, pipeline, reportType, activity }) => {
  if (area === AREA_RESPONSES.PIPELINES) {
    return Boolean(area) && Boolean(pipeline) && Boolean(reportType?.value);
  } else if (area === AREA_RESPONSES.ACTIVITIES) {
    if (reportType?.value === REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS) {
      return Boolean(area) && Boolean(reportType) && Boolean(activity);
    }
    return Boolean(area) && Boolean(reportType);
  } else if (
    area === AREA_RESPONSES.EMAILS ||
    area === AREA_RESPONSES.MARKETING
  ) {
    return Boolean(area) && Boolean(reportType);
  }
  return false;
};

const isStep3Current = ({
  reportType,
  area,
  pipeline,
  value,
  valueToDisplay,
  includedRecords,
  objectsWithLeads,
}) => {
  if (
    reportType?.value === REPORT_TYPES.LEADS_ADDED_BY_SOURCE ||
    reportType?.value === REPORT_TYPES.LEADS_ADDED
  ) {
    return (
      Boolean(area) &&
      Boolean(reportType) &&
      Boolean(valueToDisplay) &&
      objectsWithLeads?.length > 0
    );
  }
  if (reportType?.value === REPORT_TYPES.LEAD_SOURCE_BREAKDOWN) {
    return Boolean(area) && Boolean(reportType) && objectsWithLeads?.length > 0;
  }
  if (reportType?.value === REPORT_TYPES.VALUE_OVER_TIME) {
    return (
      Boolean(area) &&
      Boolean(pipeline) &&
      Boolean(reportType) &&
      Boolean(valueToDisplay) &&
      Boolean(includedRecords)
    );
  }
  if (area === AREA_RESPONSES.EMAILS) {
    return Boolean(area) && Boolean(reportType);
  }
  if (area === AREA_RESPONSES.ACTIVITIES) {
    return Boolean(area) && Boolean(reportType);
  }
  return (
    Boolean(area) && Boolean(pipeline) && Boolean(reportType) && Boolean(value)
  );
};

const isLoading = ({ pipelinesLoading }) => Boolean(pipelinesLoading);

const disableValuesAndConstraints = ({ area }, isHomepage) => {
  return area === AREA_RESPONSES.EMAILS && !isHomepage;
};

const DashletWizard = forwardRef(
  (
    {
      setModalSize,
      scrollActvitiyBuilderIntoView = false,
      canAutoScroll = true,
      ...rest
    },
    ref
  ) => {
    const enableAutoScroll = !scrollActvitiyBuilderIntoView && canAutoScroll;
    return (
      <Wizard
        ref={ref}
        controllers={[isStep2Current, isStep3Current]}
        {...rest}
        loading={isLoading}
        canAutoScroll={enableAutoScroll}
      >
        <DashletTypeWizardSection setModalSize={setModalSize} />
        <ValuesAndContraintsWizardSection
          disabled={disableValuesAndConstraints}
          scrollActvitiyBuilderIntoView={scrollActvitiyBuilderIntoView}
        />
        <DisplaySettingsWizardSection />
      </Wizard>
    );
  }
);

export default DashletWizard;
