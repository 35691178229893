import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { breakpoints, gutters, layers } from 'app/spacing';
import BackButton from 'components/Button/BackButton';
import {
  ToolbarWrapper,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
  DesktopContent,
} from 'pages/Common/styles/ControlBar';
import { isPipelineObject } from 'components/Modals/utilities';
import { SettingsButton } from 'pages/Common/components/CustomObjectSettingsButton';
import ViewVariant from 'components/ViewVariant';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import GroupSelector from 'components/DashboardGrid/GroupSelector';

const StyledToolbarWrapper = styled(ToolbarWrapper)`
  // Needs to open above table toolbar, which have z-index of 3
  z-index: ${layers.content(0, 4)};
  position: relative;

  @media (min-width: ${breakpoints.md + 1}px) {
    padding: ${gutters.standard} ${gutters.standard} 0;
  }
`;

const NAME_BREAKPOINT = breakpoints.md;

const ControlBar = ({
  viewVariant,
  model: customObject,
  settingOptions,
  onChoseSettings,
  chartGroupConfigProps,
  ...props
}) => {
  const { t } = useTranslation();

  const isPipelineType = isPipelineObject(customObject);
  const { access = {} } = customObject;
  return (
    <>
      <StyledToolbarWrapper className="toolbar-wrapper" {...props}>
        <Toolbar className="toolbar">
          <ToolbarLeft nameBreakpoint={NAME_BREAKPOINT}>
            <BackButton to="/custom-objects">
              <DesktopContent>{t('back to objects')}</DesktopContent>
            </BackButton>
          </ToolbarLeft>
          <ToolbarRight>
            {viewVariant === VIEW_VARIANTS.CHARTS ? (
              <GroupSelector {...chartGroupConfigProps} />
            ) : null}
            <ViewVariant
              objectId={customObject.id}
              hide={isPipelineType ? '' : VIEW_VARIANTS.BOARD}
            />
            {access.edit ? (
              <SettingsButton
                options={settingOptions}
                onChange={onChoseSettings}
                variant="text"
                color="blue"
              />
            ) : null}
          </ToolbarRight>
        </Toolbar>
      </StyledToolbarWrapper>
    </>
  );
};

export default ControlBar;
