import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

import { getDataQAForInput } from 'components/Inputs/helpers';
import {
  StyledLabel,
  StyledInput,
  StyledIcon,
  StyledText,
  StyledSwitch,
} from './styles';

const GridSelector = ({
  fieldId,
  className,
  icon,
  Icon,
  label,
  value,
  onChange,
  hover,
  selected,
  disabled,
  showToggle = false,
  error,
  name,
  ...rest
}) => {
  const { assignFieldHandle } = useKeyBoardContext();
  const ref = useRef();

  assignFieldHandle(fieldId, {
    customFocus: () => {
      ref.current?.focus();
      return ref.current;
    },
    disabled,
  });
  return (
    <StyledLabel
      ref={ref}
      className={className}
      checked={selected}
      hover={hover}
      error={error}
      {...rest}
    >
      {!showToggle && (
        <StyledInput
          type="checkbox"
          error={error}
          disabled={disabled}
          checked={selected}
          onChange={() => onChange(!selected)}
          value={value}
          {...getDataQAForInput(name, 'checkbox')}
        />
      )}
      {icon && typeof icon === 'string' && (
        <StyledIcon icon={icon} checked={selected} />
      )}
      {icon && React.isValidElement(icon) && icon}
      {Icon && <Icon />}
      <StyledText checked={selected}>{label}</StyledText>
      {showToggle && (
        <StyledSwitch
          checked={selected}
          onChange={() => onChange(!selected)}
          textPlacement="left"
        />
      )}
    </StyledLabel>
  );
};

GridSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  hover: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  Icon: PropTypes.func,
  showToggle: PropTypes.bool,
  error: PropTypes.any,
  name: PropTypes.string,
};

GridSelector.defaultProps = {
  className: '',
  icon: '',
  Icon: null,
  showToggle: false,
  error: null,
  selected: false,
  hover: false,
  disabled: false,
  value: undefined,
  name: '',
};

export default GridSelector;
