import * as PropTypes from 'prop-types';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { ListCardCell, ListCardRow } from 'components/Layout/ListCard';
import { DATE_DISPLAY_FORMAT } from 'components/Inputs/DateInput';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { StyledListCardItem } from 'pages/ContactsPage/styles';

const NO_VALUE = '—';

const getOwner = (owner) => {
  const splitOwner = owner.label.split(' (');
  return splitOwner[0];
};

const PipelineListItem = ({ onChecked, checked, record, model, ...others }) => {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();

  return (
    <StyledListCardItem {...others}>
      <ListCardRow>
        <ListCardCell>
          <TextEllipsisWithTooltip
            type="link"
            to={`${model.id}/${record.id}/details`}
          >
            {record.name}
          </TextEllipsisWithTooltip>
        </ListCardCell>
        <ListCardCell>
          {record.owner ? (
            preReleaseFeatures ? (
              <TextEllipsisWithTooltip
                type="link"
                to={`/team-member/${record.owner.value}/timeline`}
                target="_blank"
              >
                {getOwner(record.owner)}
              </TextEllipsisWithTooltip>
            ) : (
              <TextEllipsisWithTooltip>
                {getOwner(record.owner)}
              </TextEllipsisWithTooltip>
            )
          ) : (
            <KizenTypography>{NO_VALUE}</KizenTypography>
          )}
        </ListCardCell>
      </ListCardRow>
      <ListCardRow>
        <ListCardCell>
          <KizenTypography>
            {record.stage ? record.stage.label : NO_VALUE}
          </KizenTypography>
        </ListCardCell>
        <ListCardCell>
          <KizenTypography>
            {`${t('Close')}: ${
              record.estimatedCloseDate
                ? format(record.estimatedCloseDate, DATE_DISPLAY_FORMAT)
                : NO_VALUE
            }`}
          </KizenTypography>
        </ListCardCell>
      </ListCardRow>
    </StyledListCardItem>
  );
};

PipelineListItem.propTypes = {
  onChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    owner: PropTypes.object,
    estimatedCloseDate: PropTypes.object,
    stage: PropTypes.object,
  }).isRequired,
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default PipelineListItem;
