import { ModalBody, ModalHeader } from 'components/Modals';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modals';
import { BROADCAST } from 'queries/query-keys';
import { useQuery } from 'react-query';
import BroadcastService from 'services/BroadcastService';
import { ModalWrapper, StyledLoader } from './styles';
import { AutomationStatsList } from './AutomationStatsList';

export const AutomationStatisticsModal = ({ onHide, broadcast, ...props }) => {
  const { t } = useTranslation();

  const { data: automationStats, isLoading } = useQuery(
    [...BROADCAST.STATS, broadcast?.id],
    () => BroadcastService.getAutomationBroadcastStats(broadcast?.id),
    {
      retry: false,
      onError: onHide,
      enabled: Boolean(broadcast?.id),
    }
  );

  return (
    <Modal onHide={onHide} {...props} size="medium">
      <ModalHeader onClickClose={onHide}>
        {t('Automation Broadcast Stats')}
      </ModalHeader>
      <ModalBody>
        <ModalWrapper>
          <StyledLoader loading={isLoading}>
            <AutomationStatsList
              automations={broadcast?.action?.automations}
              automationStats={automationStats}
            />
          </StyledLoader>
        </ModalWrapper>
      </ModalBody>
    </Modal>
  );
};
