import { toastVariant, useToast } from 'components/ToastProvider';
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  startOfDay,
  endOfWeek,
  endOfDay,
  isWithinInterval,
} from 'date-fns';
import { EVENT_STATUSES } from 'pages/Broadcasts/constants';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';
import { isBroadcastInvalid } from 'pages/Broadcasts/helpers';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn, useDebounce } from 'react-use';
import BroadcastService from 'services/BroadcastService';
import { BROADCAST_FORMDATA } from '../ScheduleBroadcastModal/constants';

const getMonthQuery = (date) => {
  return {
    dateFrom: startOfWeek(startOfMonth(date)).toISOString(),
    dateTo: endOfWeek(endOfMonth(date)).toISOString(),
  };
};

const getWeekQuery = (date) => {
  return {
    dateFrom: startOfWeek(date).toISOString(),
    dateTo: endOfWeek(date).toISOString(),
  };
};

const isInRange = (view, date, { scheduledAt, rescheduleAt }) => {
  switch (view) {
    case CALENDAR_VIEW_OPTIONS.day: {
      return isWithinInterval(new Date(rescheduleAt || scheduledAt), {
        start: startOfDay(date),
        end: endOfDay(date),
      });
    }
    case CALENDAR_VIEW_OPTIONS.week: {
      return isWithinInterval(new Date(rescheduleAt || scheduledAt), {
        start: startOfWeek(date),
        end: endOfWeek(date),
      });
    }
    case CALENDAR_VIEW_OPTIONS.month: {
      return true;
    }
    default:
      return false;
  }
};

const defaultData = [[], {}];

export const useCalendarData = (date, view, broadcast) => {
  const stagedRef = useRef({ query: {} });
  const { t } = useTranslation();
  const [showToast] = useToast();

  const query = useMemo(() => {
    const monthQuery = getMonthQuery(date);
    switch (view) {
      case CALENDAR_VIEW_OPTIONS.day:
      case CALENDAR_VIEW_OPTIONS.month: {
        return monthQuery;
      }
      default: {
        const weekQuery = getWeekQuery(date);
        const interval = {
          start: new Date(monthQuery.dateFrom),
          end: new Date(monthQuery.dateTo),
        };
        return Object.values(weekQuery).every((d) =>
          isWithinInterval(d, interval)
        )
          ? monthQuery
          : weekQuery;
      }
    }
  }, [view, date]);

  const [{ loading, value: data = defaultData }, getData] = useAsyncFn(
    async (query) => {
      const broadcasts = await BroadcastService.getByDate(query);
      return [
        broadcasts,
        broadcasts.reduce((acc, b) => {
          const date = new Date(b[BROADCAST_FORMDATA.date]);
          const rescheduleAt = b[BROADCAST_FORMDATA.rescheduleAt]
            ? new Date(b[BROADCAST_FORMDATA.rescheduleAt])
            : null;
          const key = rescheduleAt
            ? rescheduleAt.toDateString()
            : date.toDateString();
          acc[key] = acc[key] || [];
          acc[key].push({
            ...b,
            [BROADCAST_FORMDATA.date]: date,
            [BROADCAST_FORMDATA.rescheduleAt]: rescheduleAt,
          });
          return acc;
        }, {}),
      ];
    },
    []
  );

  const events = data[0];
  const currentQueryRef = useRef();
  const dateRef = useRef(date);

  useEffect(() => {
    if (
      !loading &&
      events.length &&
      (stagedRef.current.query !== currentQueryRef.current ||
        (view !== CALENDAR_VIEW_OPTIONS.month && dateRef.current !== date))
    ) {
      currentQueryRef.current = stagedRef.current.query;
      dateRef.current = date;
      const failedEvents = events.filter(
        (b) =>
          b.status === EVENT_STATUSES.scheduled &&
          isBroadcastInvalid(b) &&
          isInRange(view, date, b)
      );
      if (failedEvents.length) {
        showToast({
          message: t(
            '{{eventCount}} scheduled broadcast(s) have an error with the broadcast group. Please fix the error or the broadcast will not send.',
            {
              eventCount: failedEvents.length,
            }
          ),
          variant: toastVariant.FAILURE,
          delay: 3000,
        });
      }
    }
  }, [view, date, events, loading, showToast, t]);

  useDebounce(
    () => {
      if (
        (!broadcast && stagedRef.current.broadcast) ||
        Object.entries(query).some(([k, v]) => v !== stagedRef.current.query[k])
      ) {
        getData({ ...query, validateFilters: true });
      }
      stagedRef.current = { broadcast, query };
    },
    500,
    [query, getData, broadcast]
  );

  return { loading, data };
};
