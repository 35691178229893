import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { getUniqueId } from '@kizen/page-builder/utils/id';
import { appBackground } from 'app/colors';
import Button from 'components/Button';
import AddButton from 'components/Button/AddButton';
import DragAndDropLayout from 'components/DragAndDropLayout';
import BasicModal from 'components/Modals/presets/BasicModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { useModalControl } from 'hooks/useModalControl';
import { getChosenBusiness } from 'store/authentication/selectors';
import { TopLayerSelect } from '../../components/TopLayerSelect';
import { createNewPage } from '../../utils/pages';
import { ChooseNewPageTargetModal } from './ChooseNewPageTargetModal';
import { DraggableItem } from './DraggableItem';

const shouldDisableDrag = (item) => item.hidden;

const updateGoToPageTransform = (oldPageId, newPageId) => (item) => {
  const buttonsToReassign = Object.entries(item.value.pageData).filter(
    ([_, node]) =>
      node.type.resolvedName === 'Button' && node.props.goToPage === oldPageId
  );
  const newButtons = buttonsToReassign.map(([key, node]) => {
    return [
      key,
      {
        ...node,
        props: { ...node.props, goToPage: newPageId },
      },
    ];
  });
  const goToPages =
    newButtons.length > 0
      ? item.goToPages.filter((id) => id !== oldPageId).concat(newPageId)
      : item.goToPages;
  return {
    ...item,
    goToPages,
    value: {
      ...item.value,
      pageData: {
        ...item.value.pageData,
        ...Object.fromEntries(newButtons),
      },
    },
  };
};

const getGoToPageIds = (page) => {
  return Object.values(page.pageData).reduce((acc, node) => {
    if (node.type.resolvedName === 'Button' && Boolean(node.props.goToPage)) {
      acc.push(node.props.goToPage);
    }
    return acc;
  }, []);
};

const createPageOption = (page, index) => ({
  label: page.pageName,
  value: index.toString(),
});

const createPageItem = (page) => ({
  id: page.id || getUniqueId(),
  value: page,
  label: page.pageName,
  hidden: page.hidden === undefined ? false : page.hidden,
  goToPages: getGoToPageIds(page),
});

const StyledSelect = styled(TopLayerSelect)`
  min-width: 175px;
  margin-left: 20px;
`;

const EditAndOrderPages = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button noSpace color="blue" variant="text" onClick={onClick}>
      {t('Edit & Order Pages')}
    </Button>
  );
};

const AddPageButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <AddButton color="blue" onClick={onClick}>
      {t('Add Page')}
    </AddButton>
  );
};

// When saving these edits, the entire form_ui field will be saved with the pages returned in
// the onPagesEdit callback. There two main steps to keep this data in sync:
//  - the onOpen callback should be calling the `updateEdits` method of the useBuilderEdits hook.
//  - there in an effect in this component to update the items state when the pages prop changes.
export const PageBuilderPageSelector = ({
  value,
  pages,
  onOpen,
  onChange,
  onPagesEdit,
}) => {
  const selectRef = useRef();
  const { t } = useTranslation();
  const [show, { showModal, hideModal }] = useModalControl();
  const [pageToDelete, setPageToDelete] = useState(null);
  const [pageToHide, setPageToHide] = useState(null);
  const [options, setOptions] = useState(
    pages.filter((p) => !p.hidden).map(createPageOption)
  );
  const [items, setItems] = useState(pages.map(createPageItem));
  const chosenBusiness = useSelector(getChosenBusiness);

  useEffect(() => {
    setItems(pages.map(createPageItem));
  }, [pages]);

  const navigablePageIds = useMemo(() => {
    return items.reduce((acc, page) => {
      if (!page.hidden && page.goToPages?.length) {
        acc.add(...page.goToPages);
      }
      return acc;
    }, new Set());
  }, [items]);

  const handleHideModal = (resetItems = true) => {
    if (resetItems) {
      setItems(pages.map(createPageItem));
    }
    selectRef.current?.focus();
    hideModal();
  };

  const handleEdit = async () => {
    await onPagesEdit(items.map(({ value }) => value));
    setOptions(
      items.reduce((acc, { hidden, value }, idx) => {
        if (!hidden) {
          acc.push(createPageOption(value, idx));
        }
        return acc;
      }, [])
    );
    handleHideModal(false);
  };

  const handleNameChange = useCallback((id, name) => {
    setItems((prev) => {
      const index = prev.findIndex((x) => x.id === id);
      const newItem = {
        ...prev[index],
        label: name,
        value: { ...prev[index].value, pageName: name },
      };
      const res = prev
        .slice(0, index)
        .concat(newItem, prev.slice(index + 1, prev.length));
      return res;
    });
  }, []);

  const handleAddPage = useCallback(() => {
    setItems((prev) => {
      const label = `${t('Page')} ${prev.length}`;
      return prev.slice(0, prev.length - 1).concat(
        {
          value: createNewPage(
            label,
            chosenBusiness?.primary_color ?? appBackground
          ),
          label,
          id: getUniqueId(),
        },
        prev[prev.length - 1]
      );
    });
  }, [chosenBusiness.primary_color, t]);

  const handleDeletePage = async (value, itemXform = (x) => x) => {
    const deleteIndex = items.findIndex((x) => x.id === value.id);
    setItems((prev) => {
      return prev
        .slice(0, deleteIndex)
        .concat(prev.slice(deleteIndex + 1, prev.length))
        .map(itemXform);
    });
    setPageToDelete(null);
  };

  const handleHidePage = (value, itemXform = (x) => x) => {
    setItems((prev) => {
      const { hidden, visible, movedItem } = prev.reduce(
        (acc, item) => {
          const newItem = itemXform(item);
          if (item.id === value.id) {
            acc.movedItem = {
              ...newItem,
              hidden: !newItem.hidden,
              value: { ...newItem.value, hidden: !item.value.hidden },
            };
          } else if (item.hidden) {
            acc.hidden.push(newItem);
          } else {
            acc.visible.push(newItem);
          }
          return acc;
        },
        { visible: [], hidden: [], movedItem: null }
      );
      return movedItem.hidden
        ? visible.concat(hidden, movedItem)
        : visible.concat(movedItem, hidden);
    });
  };

  const confirmHidePage = (value) => {
    if (!value.hidden && navigablePageIds.has(value.id)) {
      setPageToHide(value);
    } else {
      handleHidePage(value);
    }
  };

  const handleChooseNewPageCancel = () => {
    setPageToDelete(null);
    setPageToHide(null);
  };

  const chooseNewPageOptions = useMemo(() => {
    return items.reduce((acc, { value: { id, hidden, pageName } }) => {
      if (!hidden && id !== pageToDelete?.id && id !== pageToHide?.id) {
        acc.push({ value: id, label: pageName });
      }
      return acc;
    }, []);
  }, [items, pageToHide, pageToDelete]);

  return (
    <>
      <StyledSelect
        ref={selectRef}
        value={value}
        options={options}
        onChange={onChange}
        menuLeftButton={
          <EditAndOrderPages
            onClick={() => {
              showModal();
              onOpen();
            }}
          />
        }
      />
      {show && (
        <BasicModal
          show
          heading={t('Edit & Order Pages')}
          leftBtn={<AddPageButton />}
          leftBtnAlign="left"
          defaultLeftBtnHandler={handleAddPage}
          onHide={handleHideModal}
          onConfirm={handleEdit}
        >
          <DragAndDropLayout
            items={items}
            onChange={setItems}
            checkDisableDrag={shouldDisableDrag}
            skipField={shouldDisableDrag}
            itemElement={
              <DraggableItem
                onNameChange={handleNameChange}
                onDelete={setPageToDelete}
                onHide={confirmHidePage}
              />
            }
            itemClassName="dndItemWrapper"
            handleClassName="itemTarget"
          />
        </BasicModal>
      )}
      {pageToHide !== null && navigablePageIds.has(pageToHide.id) && (
        <ChooseNewPageTargetModal
          message={t(
            'Your form currently uses this page as a part of the form workflow and this action will hide the page. To proceed, please choose a new target page where you would like to direct your users.'
          )}
          pageOptions={chooseNewPageOptions}
          onConfirm={({ value }) => {
            handleHidePage(
              pageToHide,
              updateGoToPageTransform(pageToHide.id, value)
            );
            handleChooseNewPageCancel();
          }}
          onCancel={handleChooseNewPageCancel}
        />
      )}
      {pageToDelete !== null && navigablePageIds.has(pageToDelete.id) && (
        <ChooseNewPageTargetModal
          message={t(
            'Your form currently uses this page as a part of the form workflow and this action will permanently delete the page. To proceed, please choose a new target page where you would like to direct your users.'
          )}
          pageOptions={chooseNewPageOptions}
          onConfirm={({ value }) => {
            handleDeletePage(
              pageToDelete,
              updateGoToPageTransform(pageToDelete.id, value)
            );
            handleChooseNewPageCancel();
          }}
          onCancel={handleChooseNewPageCancel}
        />
      )}
      {pageToDelete !== null && !navigablePageIds.has(pageToDelete.id) && (
        <ConfirmDeletionModal
          show
          onConfirm={() => handleDeletePage(pageToDelete)}
          onHide={() => setPageToDelete(null)}
        >
          {t('Are you sure you want to delete this page?')}
        </ConfirmDeletionModal>
      )}
    </>
  );
};
