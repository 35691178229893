import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

import {
  HeaderSizedCell,
  SortableHeadCell,
  SizedCell,
  TextCell,
  ColorLabelWrapper,
} from 'pages/Account/pages/Profile/Forms/tableStyles';

import ColorLabel from 'components/Fields/ColorLabel';

import {
  getOAuthStatus,
  OAuthActionCell,
} from 'pages/Account/pages/Profile/Forms/OAuth/common';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';

export const createColumns = ({
  onSelectAction,
  columnWidths,
  canRevoke,
  t,
}) => {
  const columns = [
    {
      id: 'spacer',
      headCell: <SizedCell width="20px" />,
      cell: <SizedCell width="20px" />,
    },
    columnWidths?.application && {
      id: 'application',
      headCell: (
        <SortableHeadCell
          label={t('Application Name')}
          minWidth={columnWidths?.application}
          padding={columnWidths?.padding}
        />
      ),
      cell: (
        <SizedCell
          minWidth={columnWidths?.application}
          padding={columnWidths?.padding}
        />
      ),
      format: ({ id, displayName }) => {
        return (
          <TextCell>
            <TextEllipsisWithTooltip size="micro">
              {displayName}
            </TextEllipsisWithTooltip>
          </TextCell>
        );
      },
    },
    columnWidths?.employee && {
      id: 'employee',
      headCell: (
        <SortableHeadCell
          label={t('Team Member')}
          minWidth={columnWidths?.employee}
          padding={columnWidths?.padding}
        />
      ),
      cell: (
        <SizedCell
          minWidth={columnWidths?.employee}
          padding={columnWidths?.padding}
        />
      ),
      format: ({ displayName }) => {
        return (
          <TextCell>
            <TextEllipsisWithTooltip size="micro">
              {displayName}
            </TextEllipsisWithTooltip>
          </TextCell>
        );
      },
    },
    columnWidths?.status && {
      id: 'status',
      headCell: (
        <SortableHeadCell
          label={t('Status')}
          minWidth={columnWidths?.status}
          padding={columnWidths?.padding}
        />
      ),
      cell: (
        <SizedCell
          minWidth={columnWidths?.status}
          padding={columnWidths?.padding}
        />
      ),
      format: (status) => {
        const oauthStatus = getOAuthStatus(status);

        return (
          <ColorLabelWrapper>
            <ColorLabel color={oauthStatus.statusColor}>
              {oauthStatus.label(t)}
            </ColorLabel>
          </ColorLabelWrapper>
        );
      },
    },
    columnWidths?.created && {
      id: 'created',
      by: 'created',
      headCell: (
        <SortableHeadCell
          label={t('Last Refresh')}
          numeric
          minWidth={columnWidths?.created}
          padding={columnWidths?.padding}
        />
      ),
      cell: (
        <SizedCell
          minWidth={columnWidths?.created}
          padding={columnWidths?.padding}
        />
      ),
      format: (lastRefresh) => {
        return (
          <DateTimeInputInline value={lastRefresh} readOnly showDateTooltip />
        );
      },
    },
    columnWidths?.actions && {
      id: 'actions',
      headCell: (
        <HeaderSizedCell
          label={t('Actions')}
          minWidth={columnWidths?.actions}
          padding="0px"
          align="right"
          t={t}
        />
      ),
      cell: canRevoke ? (
        <OAuthActionCell
          minWidth={columnWidths?.actions}
          padding="0px"
          onSelectAction={onSelectAction}
          t={t}
        />
      ) : (
        <SizedCell minWidth={columnWidths?.actions} padding="0px"></SizedCell>
      ),
    },
    {
      id: 'right-spacer',
      headCell: <SizedCell width="20px" />,
      cell: <SizedCell width="20px" />,
    },
  ].filter(Boolean);

  return [...columns];
};
