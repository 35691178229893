import format from 'date-fns/format';
import AxiosService from './AxiosService';
import { snakeToCamelCaseKeys, camelToSnakeCaseKeys } from './helpers';

const API_DATE_FORMAT = 'YYYY-MM-DD';

const getOptionsList = (element) => ({
  label: element.name,
  value: element.id,
  type: element.type,
});

const leadSourceDetailsForApp = ({
  lead_source_type,
  lead_source_type_display,
  custom_lead_source_campaign,
  custom_lead_source_content,
  custom_lead_source_medium,
  custom_lead_source_source,
  custom_lead_source_term,
  ad_click_ad_group_name,
  ad_click_ad_name,
  ad_click_ad_network_type,
  ad_click_campaign_name,
  ad_click_keyword,
  ad_click_keyword_match_type,
  interaction_data,
  ...others
}) => ({
  leadSourceType: lead_source_type,
  leadSourceTypeDisplay: lead_source_type_display,
  customLeadSourceCampaign: custom_lead_source_campaign,
  customLeadSourceContent: custom_lead_source_content,
  customLeadSourceMedium: custom_lead_source_medium,
  customLeadSourceSource: custom_lead_source_source,
  customLeadSourceTerm: custom_lead_source_term,
  adClickAdGroupName: ad_click_ad_group_name,
  adClickAdName: ad_click_ad_name,
  adClickAdNetworkType: ad_click_ad_network_type,
  adClickCampaignName: ad_click_campaign_name,
  adClickKeyword: ad_click_keyword,
  adClickKeywordMatchType: ad_click_keyword_match_type,
  interactionData: snakeToCamelCaseKeys(interaction_data),
  ...others,
});

const costForApp = (cost) => {
  cost = snakeToCamelCaseKeys(cost);
  return cost;
};

const costForApi = (cost) => {
  cost = camelToSnakeCaseKeys({
    ...cost,
    date: (cost.date && format(cost.date, API_DATE_FORMAT)) || undefined,
  });
  return cost;
};

const leadSourcesForApp = (leadSources) => ({
  firstLeadSource: leadSourceDetailsForApp(leadSources.first_lead_source || {}),
  lastLeadSource: leadSourceDetailsForApp(leadSources.last_lead_source || {}),
  leadSourceTypeDisplays: leadSources.lead_source_type_displays,
});

class LeadSourceService {
  constructor() {
    if (!LeadSourceService.instance) {
      LeadSourceService.instance = this;
    }
    return LeadSourceService.instance;
  }

  customEndpointType = '/lead-source-custom-source-type';

  customEndpointList = '/lead-source-custom-source';

  leadSourceOverview = '/lead-source/overview';

  leadSourceDetails = '/lead-source';

  leadSourceCost = '/v2-lead-source-cost2';

  leadSourceCostBulk = '/v2-lead-source-cost2/bulk_create';

  v2LeadSourceSources = '/v2-lead-source-source';

  v2leadSourceCampaign = '/v2-lead-source-campaign';

  leadSourceOptions = '/lead-source/options';

  v2LeadSource = 'v2-lead-source';

  v2leadSourceMedium = '/v2-lead-source-medium';

  v2leadSourceTerm = '/v2-lead-source-term';

  v2leadSourceContent = '/v2-lead-source-content';

  getLeadSourceCommon = async (params = {}, url) => {
    const allResults = {
      count: 0,
      next: '',
      results: [],
    };
    try {
      let response = null;
      do {
        // we must have all existing LeadSources to have full matching
        // eslint-disable-next-line no-await-in-loop
        response = await AxiosService.get(`${allResults.next || url}`, {
          params: camelToSnakeCaseKeys(params),
        });
        allResults.count = response.data.count;
        allResults.next = response.data.next;
        allResults.results = allResults.results.concat(response.data.results);
      } while (response && response.data && response.data.next);
    } catch (e) {
      if (e.isAxiosError) {
        throw this.formatError(e);
      }
      throw e;
    }
    return allResults.results.map(getOptionsList);
  };

  getLeadSourceSources = async (params = {}) =>
    this.getLeadSourceCommon(params, this.v2LeadSourceSources);

  getLeadSourceCampaigns = async (params = {}) =>
    this.getLeadSourceCommon(params, this.v2leadSourceCampaign);

  getLeadSourceMediums = async (params = {}) =>
    this.getLeadSourceCommon(params, this.v2leadSourceMedium);

  getLeadSourceTerms = async (params = {}) =>
    this.getLeadSourceCommon(params, this.v2leadSourceTerm);

  getLeadSourceContents = async (params = {}) =>
    this.getLeadSourceCommon(params, this.v2leadSourceContent);

  getLeadSourceOverview = async (clientId) => {
    const { data } = await AxiosService.get(
      `/client/${clientId}/lead-source-overview`
    );
    return leadSourcesForApp(data);
  };

  getLeadSourceOptions = async () => {
    const { data } = await AxiosService.get(`${this.leadSourceOptions}`);
    return snakeToCamelCaseKeys(data);
  };

  getLeadSourceDetails = async ({ clientId, ordering, ...others }) => {
    const { data } = await AxiosService.get(`${this.leadSourceDetails}`, {
      params: {
        client_id: clientId,
        ordering,
        ...others,
      },
    });
    return data.results.map(leadSourceDetailsForApp);
  };

  search = async (
    { search, page = {}, ordering, criteria, groupId } = {},
    options
  ) => {
    const { data } = await AxiosService.post(
      `${this.leadSourceCost}/search`,
      criteria,
      {
        params: {
          search,
          ordering,
          group_id: groupId,
          page: page.number || 1,
          page_size: page.size || 50,
        },
        ...options,
      }
    );
    return {
      count: data.count,
      results: data.results.map(costForApp),
    };
  };

  addTransaction = async (transaction) => {
    const { data } = await AxiosService.post(
      `${this.leadSourceCost}`,
      costForApi(transaction)
    );
    return costForApp(data);
  };

  addTransactionsBulk = async (transactions) => {
    const { data } = await AxiosService.post(
      `${this.leadSourceCostBulk}`,
      transactions.map((transaction) => costForApi(transaction))
    );
    return data.map((transaction) => costForApp(transaction));
  };

  updateTransaction = async (id, patchData) => {
    const { data } = await AxiosService.patch(
      `${this.leadSourceCost}/${id}`,
      costForApi({
        ...patchData,
      })
    );
    return costForApp(data);
  };

  deleteTransaction = async (id) => {
    const { data } = await AxiosService.delete(`${this.leadSourceCost}/${id}`);
    return data;
  };

  getCategoryOptions = () => [
    { label: 'Advertising', value: 'advertising' },
    { label: 'Commissions', value: 'commissions' },
    { label: 'Contractors', value: 'contractors' },
    { label: 'Supplies', value: 'supplies' },
    { label: 'Travel', value: 'travel' },
    { label: 'Other Expenses', value: 'other_expenses' },
  ];

  getTypeOptions = () => [
    { label: 'Manual', value: 'manual' },
    { label: 'Automatic', value: 'automatic' },
    { label: 'Integration', value: 'integration' },
  ];

  createCustomLeadSource = async (leadSource) => {
    const { data } = await AxiosService.post(
      this.customEndpointList,
      leadSource
    );
    return data;
  };

  createCustomLeadSourceType = async (name) => {
    const { data } = await AxiosService.post(this.customEndpointType, { name });
    return data;
  };

  createLeadSource = async (leadSource) => {
    const { data } = await AxiosService.post(
      this.v2LeadSource,
      camelToSnakeCaseKeys(leadSource)
    );
    return data;
  };

  v2CreateLeadSourceCommon = async (params, url) => {
    const { data } = await AxiosService.post(url, camelToSnakeCaseKeys(params));
    return snakeToCamelCaseKeys(data);
  };

  v2CreateLeadSourceSource = async (params) =>
    this.v2CreateLeadSourceCommon(params, this.v2LeadSourceSources);

  v2CreateLeadSourceCampaign = async (params) =>
    this.v2CreateLeadSourceCommon(params, this.v2leadSourceCampaign);

  v2CreateLeadSourceMedium = async (params) =>
    this.v2CreateLeadSourceCommon(params, this.v2leadSourceMedium);

  v2CreateLeadSourceTerm = async (params) =>
    this.v2CreateLeadSourceCommon(params, this.v2leadSourceTerm);

  v2CreateLeadSourceContent = async (params) =>
    this.v2CreateLeadSourceCommon(params, this.v2leadSourceContent);

  v2GetClientLeadSources = async ({ clientId, ...params }) => {
    const {
      data: { results, count },
    } = await AxiosService.get(`/client/${clientId}/lead-sources`, {
      params: camelToSnakeCaseKeys(params),
    });
    return snakeToCamelCaseKeys({ results, count });
  };

  createLeadSourceSource = async (body) => {
    const { data } = await AxiosService.post(this.v2LeadSourceSources, body);
    return getOptionsList(data);
  };

  createLeadSourceCampaign = async (body) => {
    const { data } = await AxiosService.post(this.v2leadSourceCampaign, body);
    return getOptionsList(data);
  };

  createLeadSourceMedium = async (body) => {
    const { data } = await AxiosService.post(this.v2leadSourceMedium, body);
    return getOptionsList(data);
  };

  createLeadSourceTerm = async (body) => {
    const { data } = await AxiosService.post(this.v2leadSourceTerm, body);
    return getOptionsList(data);
  };

  createLeadSourceContent = async (body) => {
    const { data } = await AxiosService.post(this.v2leadSourceContent, body);
    return getOptionsList(data);
  };
}

const instance = new LeadSourceService();
Object.freeze(instance);

export default instance;
