import {
  useVirtualizer,
  VirtualItem,
  Virtualizer,
} from '@tanstack/react-virtual';
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
  RefObject,
  useEffect,
} from 'react';
import { applyRef, merge, debounce } from '../util';
import {
  PivotTableContextProvider,
  PivotTableDraggingContextProvider,
  usePivotTableDraggingContext,
} from './context';
import {
  HorizontalGradientContainer,
  VerticalGradientContainer,
} from './Gradients';
import { KeyedCell, RowCells } from './types';
import { clamp } from './util';

type StaticRowProps = {
  cells: KeyedCell[];
  rowNumber: number;
  staticLeftCount: number;
  staticRightCount: number;
  fixedLeft?: number;
  fixedRight?: number;
  hasResizableColumnControls?: boolean;
  hideScrollbar?: boolean;
  borderBottom?: boolean;
  borderTop?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  showBoxShadow?: boolean;
  onMouseLeave?(): void;
  onScroll?(ev: Event): void;
  columnSize?: number;
  initialColumnWidth: number;
};

type DataRowProps = PropsWithChildren<{
  columnTemplate?: string;
  className?: string;
  scrollable?: boolean;
  hideScrollbar?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  pointerEvents?: boolean;
  onScroll?(ev: Event): void;
}>;

export type PivotTableProps = {
  rows: RowCells[];
  width: number;
  height: number;
  rowHeight: number;
  fixedTop?: number;
  fixedBottom?: number;
  fixedRight?: number;
  fixedLeft?: number;
  enableColumnResizing?: boolean;
  minimumColumnWidth?: number;
  minimumFixedLeftWidth?: number;
  className?: string;
  frameless?: boolean;
  onChangeStandardColumnSizes?: (sizes: number[]) => void;
  onChangeFixedLeftColumnSizes?: (sizes: number[]) => void;
  onVerticalScroll: (rowIds: string[], scrollPosition: number) => void;
  onHorizontalScroll: (scrollPosition: number) => void;
  fixedLeftColumnSizes?: number[];
  standardColumnSizes?: number[];
  defaultColumnWidth?: number;
  verticalScrollPosition?: RefObject<number>;
  horizontalScrollPosition?: RefObject<number>;
};

type VerticalScrollContainerProps = PropsWithChildren<{
  columnTemplate: string;
  onScroll?(ev: Event): void;
  onMouseLeave?(): void;
}>;

type CenterScrollbarProps = {
  fixedLeft?: number;
  fixedRight?: number;
  scrollWidth: number;
  onScroll(ev: Event): void;
};

const StaticRow = forwardRef<HTMLDivElement, StaticRowProps>(
  (
    {
      cells,
      rowNumber,
      fixedLeft,
      fixedRight,
      staticLeftCount,
      staticRightCount,
      hasResizableColumnControls = false,
      hideScrollbar = true,
      borderBottom = false,
      borderTop = false,
      borderLeft = false,
      borderRight = false,
      showBoxShadow = false,
      onMouseLeave,
      onScroll,
      initialColumnWidth,
    },
    ref
  ) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const { isResizingStaticColumn, addVirtualizedRow, removeVirtualizedRow } =
      usePivotTableDraggingContext();
    const { left, center, right } = useMemo(() => {
      const left = staticLeftCount ? cells.slice(0, staticLeftCount) : [];
      const right = staticRightCount ? cells.slice(-staticRightCount) : [];
      const center = cells.slice(staticLeftCount, -staticRightCount);
      return { left, center, right };
    }, [cells, staticLeftCount, staticRightCount]);

    const containerStyle = useMemo(() => {
      let gridTemplateColumns = '1fr';
      if (fixedLeft && fixedRight) {
        gridTemplateColumns = `${`${fixedLeft}px`} 1fr ${`${fixedRight}px`}`;
      } else if (fixedLeft) {
        gridTemplateColumns = `${`${fixedLeft}px`} 1fr`;
      } else if (fixedRight) {
        gridTemplateColumns = `1fr ${`${fixedRight}px`}`;
      }

      if (showBoxShadow) {
        return {
          gridTemplateColumns,
          boxShadow: 'rgba(0, 0, 0, 0.03) 0px 18px 26px 0px',
        };
      }

      return { gridTemplateColumns };
    }, [fixedLeft, fixedRight, showBoxShadow]);

    const border = merge(
      'border-solid border-border-medium-light border-l-0 border-r-0',
      borderTop && borderBottom && 'border-t border-b',
      borderTop && !borderBottom && 'border-t border-b-0',
      borderBottom && !borderTop && 'border-b border-t-0',
      !borderBottom && !borderTop && 'border-b-0 border-t-0'
    );

    const virtualizer = useVirtualizer({
      horizontal: true,
      count: center.length,
      getScrollElement: () => scrollContainerRef.current,
      estimateSize: () => initialColumnWidth,
    });

    const mergeRef = useCallback(
      (node: HTMLDivElement) => {
        applyRef(ref, node);
        scrollContainerRef.current = node;
      },
      [ref]
    );

    useEffect(() => {
      addVirtualizedRow(virtualizer);

      return () => {
        removeVirtualizedRow(virtualizer);
      };
    }, [virtualizer, addVirtualizedRow, removeVirtualizedRow]);

    return (
      <div
        style={containerStyle}
        className={merge('grid', border)}
        onMouseLeave={onMouseLeave}
      >
        {left.map((Cell, idx) => (
          <Cell
            key={Cell.key}
            row={rowNumber}
            column={idx}
            isFirstColumn={idx === 0}
            isLastColumn={false}
          />
        ))}
        <DataRow
          scrollable
          ref={mergeRef}
          hideScrollbar={hideScrollbar}
          borderLeft={borderLeft}
          borderRight={borderRight}
          onScroll={onScroll}
          pointerEvents={
            hasResizableColumnControls ? !isResizingStaticColumn : true
          }
        >
          <VirtualizedColumnContainer width={virtualizer.getTotalSize()}>
            {virtualizer.getVirtualItems().map((vItem, _, arr) => {
              const idx = vItem.index;
              const Cell = center[idx];
              return (
                <VirtualizedColumnItemContainer
                  key={Cell.key}
                  start={vItem.start}
                  width={vItem.size}
                >
                  <Cell
                    key={Cell.key}
                    row={rowNumber}
                    column={staticLeftCount + idx}
                    isFirstColumn={left.length === 0 && idx === 0}
                    isLastColumn={right.length === 0 && idx === arr.length - 1}
                    virtualItem={vItem}
                  />
                </VirtualizedColumnItemContainer>
              );
            })}
          </VirtualizedColumnContainer>
        </DataRow>
        {right.map((Cell, idx, arr) => (
          <Cell
            key={Cell.key}
            row={rowNumber}
            column={staticLeftCount + center.length + idx}
            isFirstColumn={false}
            isLastColumn={idx === arr.length - 1}
          />
        ))}
      </div>
    );
  }
);

const VerticalScrollContainer = forwardRef<
  HTMLDivElement,
  VerticalScrollContainerProps
>(({ children, columnTemplate, onScroll, onMouseLeave }, ref) => {
  const style = useMemo(
    () => ({ gridTemplateColumns: columnTemplate }),
    [columnTemplate]
  );

  return (
    <VerticalGradientContainer
      ref={ref}
      enableTopGradient={false}
      onScroll={onScroll}
      onMouseLeave={onMouseLeave}
    >
      <div className="grid items-start basis-full" style={style}>
        {children}
      </div>
    </VerticalGradientContainer>
  );
});

const DataGrid = ({
  children,
  columnTemplate,
  hideScrollbar,
}: Pick<DataRowProps, 'children' | 'columnTemplate' | 'hideScrollbar'>) => {
  const style = useMemo(() => {
    return hideScrollbar
      ? { gridTemplateColumns: columnTemplate }
      : {
          height: 'calc(100% + var(--kds-width-scrollbar))',
          gridTemplateColumns: columnTemplate,
        };
  }, [hideScrollbar, columnTemplate]);

  return (
    <div className={'grid h-full'} style={style}>
      {children}
    </div>
  );
};

type VirtualChangeType = {
  scrollOffset: number;
  range: { startIndex: number; endIndex: number };
};

interface HandleVirtualizedChangeProps {
  virtualChange: VirtualChangeType;
  onVerticalScroll: (visibleRowIds: string[], scrollOffset: number) => void;
  rows: RowCells[];
  verticalRef: RefObject<HTMLElement>;
}

const debouncedVirtualizedChange = debounce(
  ({
    virtualChange,
    onVerticalScroll,
    rows,
    verticalRef,
  }: HandleVirtualizedChangeProps) => {
    if (verticalRef.current) {
      const { startIndex, endIndex } = virtualChange.range;
      const paddedIndex = endIndex + 2;
      const visibleRowIds = rows
        .slice(startIndex, paddedIndex)
        .filter(({ rowId }) => rowId)
        .map(({ rowId }) => rowId) as string[];

      if (visibleRowIds) {
        onVerticalScroll(visibleRowIds, virtualChange.scrollOffset);
      }
    }
  },
  50
);

const DataRow = forwardRef<HTMLDivElement, DataRowProps>(
  (
    {
      children,
      className,
      columnTemplate,
      scrollable = false,
      hideScrollbar = false,
      borderLeft = false,
      borderRight = false,
      pointerEvents = true,
      onScroll,
    },
    ref
  ) => {
    const border = merge(
      'border-solid border-border-medium-light border-b-0 border-t-0',
      borderLeft && borderRight && 'border-l border-r',
      borderLeft && !borderRight && 'border-l border-r-0',
      borderRight && !borderLeft && 'border-r border-l-0',
      !borderRight && !borderLeft && 'border-l-0 border-r-0'
    );

    return (
      <HorizontalGradientContainer
        ref={ref}
        className={merge(border, className)}
        scrollable={scrollable}
        hideScrollbar={hideScrollbar}
        pointerEvents={pointerEvents}
        onScroll={onScroll}
      >
        <DataGrid hideScrollbar={hideScrollbar} columnTemplate={columnTemplate}>
          {children}
        </DataGrid>
      </HorizontalGradientContainer>
    );
  }
);

const CenterScrollbar = forwardRef<HTMLDivElement, CenterScrollbarProps>(
  ({ fixedLeft, fixedRight, scrollWidth, onScroll }, ref) => {
    const containerStyle = useMemo(() => {
      let gridTemplateColumns = '1fr';
      if (fixedLeft && fixedRight) {
        gridTemplateColumns = `${`${fixedLeft}px`} 1fr ${`${fixedRight}px`}`;
      } else if (fixedLeft) {
        gridTemplateColumns = `${`${fixedLeft}px`} 1fr`;
      } else if (fixedRight) {
        gridTemplateColumns = `1fr ${`${fixedRight}px`}`;
      }

      return { gridTemplateColumns };
    }, [fixedLeft, fixedRight]);

    return (
      <div style={containerStyle} className="grid">
        {Boolean(fixedLeft) && <span />}
        <DataRow
          scrollable
          ref={ref}
          columnTemplate={`${scrollWidth}px`}
          onScroll={onScroll}
        />
        {Boolean(fixedRight) && <span />}
      </div>
    );
  }
);

const VirtualizedContainer = ({
  children,
  className,
  height,
}: PropsWithChildren<{ height: number; className?: string }>) => (
  <div
    className={merge('relative', className)}
    style={{ height: `${height}px` }}
  >
    {children}
  </div>
);

const VirtualizedColumnContainer = ({ children, width }: any) => {
  return (
    <div
      className="relative"
      style={{ width: `${width}px` }}
      data-virtualized-column
    >
      {children}
    </div>
  );
};

const VirtualizedColumnItemContainer = ({ children, start, width }: any) => {
  return (
    <div
      className={merge('absolute top-0 left-0 h-full')}
      style={{
        width: `${width}px`,
        transform: `translateX(${start}px)`,
      }}
    >
      {children}
    </div>
  );
};

const VirtualizedItemContainer = ({
  children,
  className,
  height,
  start,
  onMouseOver,
}: PropsWithChildren<{
  height: number;
  start: number;
  className?: string;
  onMouseOver?(): void;
}>) => (
  <div
    className={merge('absolute top-0 left-0 w-full', className)}
    onMouseOver={onMouseOver}
    style={{
      height: `${height}px`,
      transform: `translateY(${start}px)`,
    }}
  >
    {children}
  </div>
);

const getContainerStyle = (
  width: number,
  height: number,
  topRow?: number,
  bottomRow?: number
) => {
  const top = isNaN(Number(topRow)) ? 'auto' : `${topRow}px`;
  const bottom = isNaN(Number(bottomRow)) ? 'auto' : `${bottomRow}px`;

  return {
    width: `${width}px`,
    height: `${height}px`,
    gridTemplateColumns: '1fr',
    gridTemplateRows: `${top} 1fr ${bottom}`,
  };
};

export const PivotTable = ({
  rows,
  className,
  fixedTop,
  fixedBottom,
  fixedLeft,
  fixedRight,
  enableColumnResizing = false,
  minimumColumnWidth = 80,
  defaultColumnWidth = 120,
  minimumFixedLeftWidth = 120,
  rowHeight = 40,
  height = 400,
  width = 1200,
  frameless = false,
  onVerticalScroll,
  onHorizontalScroll,
  verticalScrollPosition,
  horizontalScrollPosition,
  onChangeStandardColumnSizes,
  onChangeFixedLeftColumnSizes,
  fixedLeftColumnSizes,
  standardColumnSizes,
}: PivotTableProps) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const centerRef = useRef<HTMLDivElement | null>(null);
  const verticalRef = useRef<HTMLDivElement | null>(null);
  const [scrolledTop, setScrolledTop] = useState(true);
  const [scrollSkips] = useState<Set<HTMLDivElement>>(new Set());
  const [hoveredRowIndex, setHoveredRowIndex] = useState<null | number>(null);
  const clearHoveredRowIndex = useCallback(() => setHoveredRowIndex(null), []);
  const [isResizingStaticColumn, setIsResizingStaticColumn] = useState(false);
  const [staticLeftColumnSizes, setStaticLeftColumnSizes] = useState(
    fixedLeftColumnSizes ?? (fixedLeft ? [fixedLeft] : [])
  );
  const [virtualizedRows, setVirtualizedRows] = useState<
    Virtualizer<HTMLDivElement, Element>[]
  >([]);
  const virtualRowOps = useMemo(() => {
    const remeasure = () => {
      for (const vRow of virtualizedRows) {
        vRow.measure();
      }
    };
    const resizeColumn = (virtualIndex: number, size: number) => {
      for (const vRow of virtualizedRows) {
        const vItem = vRow
          .getVirtualItems()
          .find((item) => item.index === virtualIndex);
        if (vItem) {
          vRow.resizeItem(vItem, size);
        }
      }
    };
    const scrollToOffset = (offset: number) => {
      for (const vRow of virtualizedRows) {
        vRow.scrollToOffset(offset);
      }
    };
    return { remeasure, resizeColumn, scrollToOffset };
  }, [virtualizedRows]);
  const { addVirtualizedRow, removeVirtualizedRow } = useMemo(() => {
    return {
      addVirtualizedRow: (
        virtualizer: Virtualizer<HTMLDivElement, Element>
      ) => {
        setVirtualizedRows((prev) => prev.concat(virtualizer));
      },
      removeVirtualizedRow: (
        virtualizer: Virtualizer<HTMLDivElement, Element>
      ) => {
        setVirtualizedRows((prev) => prev.filter((v) => v !== virtualizer));
      },
    };
  }, []);

  const staticTopCount = isNaN(Number(fixedTop)) ? 0 : 1;
  const staticBottomCount = isNaN(Number(fixedBottom)) ? 0 : 1;
  const staticLeftCount = isNaN(Number(fixedLeft)) ? 0 : 1;
  const staticRightCount = isNaN(Number(fixedRight)) ? 0 : 1;

  const containerStyle = useMemo(() => {
    return getContainerStyle(width, height, fixedTop, fixedBottom);
  }, [width, height, fixedTop, fixedBottom]);

  const { top, left, center, right, bottom } = useMemo(() => {
    const top = rows.slice(0, staticTopCount);
    const bottom =
      staticBottomCount === 0 ? [] : rows.slice(-staticBottomCount);

    const verticalScrollRows =
      staticBottomCount === 0
        ? rows.slice(staticTopCount)
        : rows.slice(staticTopCount, -staticBottomCount);

    const { left, center, right } = verticalScrollRows.reduce(
      (acc, row) => {
        for (let i = 0; i < staticLeftCount; i++) {
          acc.left[i] = acc.left[i] || [];
          acc.left[i].push(row?.KeyedCells[i]);
        }

        for (let i = 0; i < staticRightCount; i++) {
          acc.right[i] = acc.right[i] || [];
          acc.right[i].push(
            row?.KeyedCells[row?.KeyedCells?.length - staticRightCount + i]
          );
        }

        acc.center.push({
          ...row,
          KeyedCells: row?.KeyedCells.slice(staticLeftCount, -staticRightCount),
        });
        return acc;
      },
      {
        left: [] as KeyedCell[][],
        center: [] as RowCells[],
        right: [] as KeyedCell[][],
      }
    );
    return {
      top: top[0],
      left: left[0],
      center,
      right: right[0],
      bottom: bottom[0],
    };
  }, [
    rows,
    staticTopCount,
    staticBottomCount,
    staticLeftCount,
    staticRightCount,
  ]);

  const columnSizes =
    standardColumnSizes ??
    Array(
      center?.[0]?.KeyedCells?.length ??
        top?.KeyedCells.length - staticLeftCount - staticRightCount
    ).fill(defaultColumnWidth);
  const totalColumns = columnSizes.length;
  const [columnWidth, setColumnWidth] = useState(columnSizes[0]);

  const rowCount = center.length;

  const verticalColumnTemplate = `${staticLeftColumnSizes.map(
    (size) => `${size}px`
  )} 1fr ${fixedRight ? `${fixedRight}px` : ''}`.trim();

  const handleScroll = useCallback(
    (ev: Event) => {
      const target = ev.target as HTMLDivElement;
      if (scrollSkips.has(target)) {
        scrollSkips.delete(target);
        return;
      }
      for (const node of [
        headerRef.current,
        footerRef.current,
        centerRef.current,
      ]) {
        if (node && node !== target) {
          node.scrollLeft = target.scrollLeft;
          scrollSkips.add(node);
        }
      }
    },
    [scrollSkips]
  );

  const visibleRows = clamp(
    Math.ceil((height - (fixedTop ?? 0) - (fixedBottom ?? 0)) / rowHeight),
    0
  );

  const visibleHorizontalRows = clamp(
    Math.ceil((width - (fixedLeft ?? 0) - (fixedRight ?? 0)) / columnWidth),
    0
  );

  const handleVirtualizedChange = useCallback(
    (virtualChange: VirtualChangeType) => {
      debouncedVirtualizedChange({
        virtualChange,
        onVerticalScroll,
        rows,
        verticalRef,
      });
    },
    [onVerticalScroll, rows, verticalRef]
  );

  const rowVirtualizer = useVirtualizer({
    count: rowCount,
    getScrollElement: () => verticalRef.current,
    estimateSize: () => rowHeight,
    overscan: visibleRows,
    onChange: handleVirtualizedChange,
    initialOffset: verticalScrollPosition?.current ?? 0,
  });

  const columnVirtualizer = useVirtualizer({
    count: center[0]?.KeyedCells?.length ?? 0,
    getScrollElement: () => centerRef.current,
    estimateSize: () => columnWidth,
    overscan: visibleHorizontalRows,
    initialOffset: horizontalScrollPosition?.current ?? 0,
    onChange: (virtualChange) => onHorizontalScroll(virtualChange.scrollOffset),
    horizontal: true,
  });

  const dragHandlers = useMemo(() => {
    // the distance in pixels from the left side of the scroll container to the column resizer that initiated a drag
    let dragStartPosition = 0;
    let columnBeingResized: VirtualItem | null = null;
    const isStaticLeftColumn = (staticColumnCount: number, index: number) => {
      return index < staticColumnCount;
    };

    return {
      onColumnDrag: (
        columnIndex: number,
        diffOrNextSize: number,
        virtualIndex?: number
      ) => {
        if (isStaticLeftColumn(staticLeftCount, columnIndex)) {
          setStaticLeftColumnSizes((prev) => {
            const result = prev.map((size, idx) => {
              return columnIndex === idx
                ? clamp(size + diffOrNextSize, minimumFixedLeftWidth)
                : size;
            });
            return result;
          });
        } else if (virtualIndex !== undefined) {
          virtualRowOps.resizeColumn(
            virtualIndex,
            clamp(diffOrNextSize, minimumColumnWidth)
          );
        }
      },
      onColumnDragStart: (
        columnIndex: number,
        clientX: number,
        virtualItem: VirtualItem
      ) => {
        if (isStaticLeftColumn(staticLeftCount, columnIndex)) {
          setIsResizingStaticColumn(true);
        } else {
          const containerClientX =
            centerRef.current?.getBoundingClientRect()?.left ?? 0;
          dragStartPosition = clientX - containerClientX;
          columnBeingResized = virtualItem;
        }
      },
      onColumnDragEnd: (columnIndex: number, nextSize?: number) => {
        if (isStaticLeftColumn(staticLeftCount, columnIndex)) {
          setIsResizingStaticColumn(false);
          setStaticLeftColumnSizes((prev) => {
            onChangeFixedLeftColumnSizes?.(prev);
            return prev;
          });
        } else if (nextSize !== undefined) {
          const ns = clamp(nextSize, minimumColumnWidth);
          setColumnWidth(ns);
          onChangeStandardColumnSizes?.(Array(totalColumns).fill(ns));
          virtualRowOps.remeasure();
          if (columnBeingResized) {
            const sizeDiff = ns - columnBeingResized.size;
            const startOfScrollContainer = (columnBeingResized.index + 1) * ns;
            const originalPositionInTable =
              startOfScrollContainer - dragStartPosition;
            const newPositionInTable = originalPositionInTable - sizeDiff;
            virtualRowOps.scrollToOffset(newPositionInTable);
          }
          dragStartPosition = 0;
          columnBeingResized = null;
        }
      },
    };
  }, [
    staticLeftCount,
    totalColumns,
    minimumFixedLeftWidth,
    minimumColumnWidth,
    virtualRowOps,
    onChangeFixedLeftColumnSizes,
    onChangeStandardColumnSizes,
  ]);

  const handleVerticalScroll = useCallback((ev: Event) => {
    const target = ev.target as HTMLDivElement;
    setScrolledTop(target.scrollTop === 0);
  }, []);

  const context = useMemo(() => {
    return {
      hoveredRowIndex,
      setHoveredRowIndex,
    };
  }, [hoveredRowIndex, setHoveredRowIndex]);

  const draggingContext = useMemo(() => {
    return {
      ...dragHandlers,
      addVirtualizedRow,
      removeVirtualizedRow,
      isResizingStaticColumn,
    };
  }, [
    addVirtualizedRow,
    removeVirtualizedRow,
    isResizingStaticColumn,
    dragHandlers,
  ]);

  useEffect(() => {
    addVirtualizedRow(columnVirtualizer);

    return () => {
      removeVirtualizedRow(columnVirtualizer);
    };
  }, [columnVirtualizer, addVirtualizedRow, removeVirtualizedRow]);

  const horizontalVirtualItems = columnVirtualizer.getVirtualItems();

  return (
    <PivotTableContextProvider value={context}>
      <PivotTableDraggingContextProvider value={draggingContext}>
        <div
          style={containerStyle}
          className={merge(
            'grid overflow-hidden relative z-0',
            frameless
              ? ''
              : 'border-solid border border-border-medium-light rounded-lg',
            className
          )}
        >
          <StaticRow
            borderBottom
            showBoxShadow={!scrolledTop}
            rowNumber={-1}
            ref={headerRef}
            cells={top?.KeyedCells}
            hasResizableColumnControls={enableColumnResizing}
            staticLeftCount={staticLeftCount}
            staticRightCount={staticRightCount}
            fixedLeft={staticLeftColumnSizes[0]}
            fixedRight={fixedRight}
            onMouseLeave={clearHoveredRowIndex}
            onScroll={handleScroll}
            initialColumnWidth={columnWidth}
          />
          <VerticalScrollContainer
            ref={verticalRef}
            columnTemplate={verticalColumnTemplate}
            onMouseLeave={clearHoveredRowIndex}
            onScroll={handleVerticalScroll}
          >
            <VirtualizedContainer height={rowVirtualizer.getTotalSize()}>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const rowIndex = virtualRow.index;
                const Cell = left[rowIndex];
                const {
                  className: rowClassName,
                  backgroundColor: labelBackgroundColor,
                } = center[rowIndex];
                return (
                  <VirtualizedItemContainer
                    key={Cell.key}
                    height={rowHeight}
                    start={virtualRow.start}
                    className={merge(rowClassName, labelBackgroundColor)}
                  >
                    <Cell
                      key={Cell.key}
                      row={rowIndex}
                      column={0}
                      isFirstColumn={true}
                      isLastColumn={false}
                    />
                  </VirtualizedItemContainer>
                );
              })}
            </VirtualizedContainer>
            <HorizontalGradientContainer
              positionByContainerSize
              ref={centerRef}
              hideScrollbar
              onScroll={handleScroll}
              className="border-solid border-border-medium-light border-r border-l border-b-0 border-t-0 mr-[calc(var(--kds-width-scrollbar)*-1)]"
            >
              <VirtualizedContainer
                className="basis-full"
                height={rowVirtualizer.getTotalSize()}
              >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                  const rowIndex = virtualRow.index;
                  const {
                    className: rowClassName,
                    backgroundColor: rowBackgroundColorClassName,
                  } = center[rowIndex];

                  const backgroundColor =
                    hoveredRowIndex === rowIndex
                      ? 'bg-background-default'
                      : rowBackgroundColorClassName;

                  return (
                    <VirtualizedItemContainer
                      key={rowIndex}
                      height={rowHeight}
                      start={virtualRow.start}
                      onMouseOver={() => setHoveredRowIndex(rowIndex)}
                      className={merge(backgroundColor, rowClassName)}
                    >
                      <DataGrid hideScrollbar>
                        <VirtualizedColumnContainer
                          width={columnVirtualizer.getTotalSize()}
                        >
                          {horizontalVirtualItems.map(
                            (virtualColumn, _, arr) => {
                              const Cell =
                                center[rowIndex].KeyedCells[
                                  virtualColumn.index
                                ];

                              const columnIndex =
                                staticLeftCount + virtualColumn.index;
                              return (
                                <VirtualizedColumnItemContainer
                                  key={Cell.key}
                                  start={virtualColumn.start}
                                  onMouseOver={() =>
                                    setHoveredRowIndex(rowIndex)
                                  }
                                  className={merge(
                                    backgroundColor,
                                    rowClassName
                                  )}
                                  width={virtualColumn.size}
                                >
                                  <Cell
                                    key={Cell.key}
                                    row={rowIndex}
                                    column={columnIndex}
                                    isFirstColumn={
                                      left.length === 0 && columnIndex === 0
                                    }
                                    isLastColumn={
                                      right.length === 0 &&
                                      columnIndex === arr.length - 1
                                    }
                                    virtualItem={virtualColumn}
                                  />
                                </VirtualizedColumnItemContainer>
                              );
                            }
                          )}
                        </VirtualizedColumnContainer>
                      </DataGrid>
                    </VirtualizedItemContainer>
                  );
                })}
              </VirtualizedContainer>
            </HorizontalGradientContainer>
            <VirtualizedContainer
              className="ml-[var(--kds-width-scrollbar)]"
              height={rowVirtualizer.getTotalSize()}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const rowIndex = virtualRow.index;
                const Cell = right[rowIndex];
                const {
                  className: rowClassName,
                  backgroundColor: totalCellBackgroundColor,
                } = center[rowIndex];

                return (
                  <VirtualizedItemContainer
                    key={Cell.key}
                    height={rowHeight}
                    start={virtualRow.start}
                    className={merge(rowClassName, totalCellBackgroundColor)}
                  >
                    <Cell
                      hasVerticalScrollbar
                      row={rowIndex}
                      column={staticLeftCount + center[0]?.KeyedCells?.length}
                      isFirstColumn={false}
                      isLastColumn={true}
                    />
                  </VirtualizedItemContainer>
                );
              })}
            </VirtualizedContainer>
          </VerticalScrollContainer>
          {bottom ? (
            <StaticRow
              borderTop
              borderLeft
              borderRight
              ref={footerRef}
              cells={bottom?.KeyedCells}
              rowNumber={rowCount}
              hideScrollbar={false}
              staticLeftCount={staticLeftCount}
              staticRightCount={staticRightCount}
              fixedLeft={staticLeftColumnSizes[0]}
              fixedRight={fixedRight}
              onScroll={handleScroll}
              initialColumnWidth={columnWidth}
            />
          ) : (
            <CenterScrollbar
              ref={footerRef}
              fixedLeft={staticLeftColumnSizes[0]}
              fixedRight={fixedRight}
              onScroll={handleScroll}
              scrollWidth={columnVirtualizer.getTotalSize()}
            />
          )}
        </div>
      </PivotTableDraggingContextProvider>
    </PivotTableContextProvider>
  );
};

DataRow.displayName = 'DataRow';
StaticRow.displayName = 'StaticRow';
VerticalScrollContainer.displayName = 'VerticalScrollContainer';
