import { forwardRef } from 'react';

import FieldService from 'services/FieldService';

import RelationshipManyInline from 'components/Fields/InlineTableFieldInput/RelationshipMany';
import RelationshipOneInlineFullExperienceForm, {
  RelationshipOneSelectFullExperienceForm,
} from 'components/Fields/InlineTableFieldInput/RelationshipOneInlineFullExperienceForm';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';

import { withCellWrapper } from './helpers';
import { SummarizedMultiselectInline } from 'components/Inputs/inline/SummarizedMultiselect';
import { isSummarizedValue } from 'components/Fields/helpers';

const _RelationshipCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      shouldFocusNext,
      ...others
    },
    ref
  ) => {
    if (isSummarizedValue(value)) {
      return (
        <SummarizedMultiselectInline
          ref={ref}
          {...others}
          field={field}
          object={object}
          entity={object}
          value={value}
          onSubmit={onSubmit}
          readOnly={readOnly}
          onAutoFocus={onAutoFocus}
          handleUpdateTableRecords={handleUpdateTableRecords}
          shouldFocusNext={shouldFocusNext}
        />
      );
    }
    if (FieldService.getAllowMultiple(field)) {
      return (
        <RelationshipManyInline
          ref={ref}
          {...others}
          field={field}
          object={object}
          value={value}
          onSubmit={onSubmit}
          readOnly={readOnly}
          onAutoFocus={onAutoFocus}
          handleUpdateTableRecords={handleUpdateTableRecords}
          viewOnlySelect={readOnly}
          shouldFocusNext={shouldFocusNext}
        />
      );
    }

    return (
      <RelationshipOneInlineFullExperienceForm
        ref={ref}
        {...others}
        field={field}
        object={object}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        shouldFocusNext={shouldFocusNext}
        handleUpdateTableRecords={handleUpdateTableRecords}
      >
        <RelationshipOneSelectFullExperienceForm
          menuLeftButton={field.isRequired ? null : menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
          shouldUseKeyboardEventHandle
          shouldFocusNext={shouldFocusNext}
        />
      </RelationshipOneInlineFullExperienceForm>
    );
  }
);

export const RelationshipCell = withCellWrapper(_RelationshipCell);
