import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import { StyledToggleButton, SwitchWrapper } from './styles';
import { getRecordOverviewAccess } from 'store/authentication/selectors';
import { getPathnames, getViewOptions } from './helpers';
import { VIEW_VARIANTS } from './types';
import useField from 'hooks/useField';

const ViewVariant = ({ objectId, hide = '', isMobile }) => {
  const { t } = useTranslation();

  const { viewVariantsAccess } = useSelector((s) =>
    getRecordOverviewAccess(s, objectId)
  );
  const history = useHistory();
  const { subpage } = useParams();

  const pathnames = useMemo(
    () =>
      getPathnames(`/${objectId ? `custom-objects/${objectId}` : 'clients'}`),
    [objectId]
  );

  const [options, viewVariant] = useMemo(() => {
    const viewVariant = Object.keys(pathnames).includes(subpage)
      ? subpage
      : VIEW_VARIANTS.TABLE;
    const viewsToHide = hide.split(',').concat(
      Object.entries(viewVariantsAccess).reduce((acc, [key, value]) => {
        if (!value) {
          acc.push(key);
        }
        return acc;
      }, [])
    );
    return [getViewOptions(viewVariant, t, viewsToHide), viewVariant];
  }, [t, subpage, pathnames, hide, viewVariantsAccess]);

  const [value, setValue] = useField(viewVariant);

  const onChange = ({ value }) => {
    setValue(value);
    history.push(pathnames[value]);
  };

  return options.length > 1 ? (
    <SwitchWrapper>
      {isMobile ? null : <KizenTypography>{t('View')}</KizenTypography>}
      <StyledToggleButton options={options} value={value} onChange={onChange} />
    </SwitchWrapper>
  ) : null;
};

export default ViewVariant;
