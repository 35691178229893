import { cloneElement } from 'react';
import {
  ButtonTrayItem,
  CustomFieldTrayItem,
  ImageTrayItem,
  DividerTrayItem,
  FormFieldTrayItem,
  TextTrayItem,
  HTMLBlockTrayItem,
  SurveyFieldTrayItem,
  AttachmentsTrayItem,
} from '../tray-items';

export const emailTrayItems = [
  TextTrayItem,
  ImageTrayItem,
  DividerTrayItem,
  ButtonTrayItem,
  cloneElement(AttachmentsTrayItem, { cols: 2, row: 2 }),
];

export const formTrayItems = [
  TextTrayItem,
  ImageTrayItem,
  DividerTrayItem,
  ButtonTrayItem,
  HTMLBlockTrayItem,
  cloneElement(CustomFieldTrayItem, { cols: 2, row: 2 }),
  cloneElement(FormFieldTrayItem, { cols: 2, rows: 2 }),
];

export const surveyTrayItems = [
  TextTrayItem,
  ImageTrayItem,
  DividerTrayItem,
  ButtonTrayItem,
  HTMLBlockTrayItem,
  cloneElement(CustomFieldTrayItem, { cols: 2, row: 2 }),
  cloneElement(SurveyFieldTrayItem, { cols: 2, rows: 2 }),
];

export const homepageTrayItems = [
  TextTrayItem,
  ImageTrayItem,
  DividerTrayItem,
  ButtonTrayItem,
  HTMLBlockTrayItem,
];

export const staticBlockTrayItems = [
  TextTrayItem,
  ImageTrayItem,
  DividerTrayItem,
  ButtonTrayItem,
  HTMLBlockTrayItem,
];
