import React, { useRef, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import useToggle from 'react-use/lib/useToggle';
import useClickAway from 'react-use/lib/useClickAway';
import { grayScale } from 'app/colors';
import Button from 'components/Button';
import Select, { optionShape } from '.';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  margin: 0.5rem 0.75rem;
  button:first-of-type {
    flex: 1;
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button:last-of-type {
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const ButtonMenu = styled(Select)`
  > :first-child {
    width: 100%;
    margin-top: 0;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .ButtonMenu__control {
    display: none;
  }
  .ButtonMenu__menu {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    border: 1px solid ${grayScale.medium};
    border-radius: 4px;
  }
`;

export default function SelectWithActivator({
  color,
  disabled,
  options,
  onClick,
  onChange,
  children,
  variant,
  ...others
}) {
  const [open, toggleOpen] = useToggle(false);
  const dropdownRef = useRef(null);
  useClickAway(dropdownRef, () => toggleOpen(false));

  const handleChange = useCallback(
    (value) => {
      toggleOpen(false);
      if (onChange) {
        onChange(value);
      }
    },
    [toggleOpen, onChange]
  );

  return (
    <Wrapper ref={dropdownRef} {...others}>
      <Button
        variant={variant}
        color={color}
        disabled={disabled}
        onClick={toggleOpen}
      >
        {children}
      </Button>
      {open && !disabled && (
        <ButtonMenu
          classNamePrefix="ButtonMenu"
          menuIsOpen
          menuPlacement="bottom"
          filterOption={null}
          hideSelectedOptions={false}
          options={options}
          onChange={handleChange}
          {...others}
        />
      )}
    </Wrapper>
  );
}

SelectWithActivator.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(optionShape).isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'text']),
};

SelectWithActivator.defaultProps = {
  disabled: false,
  color: 'green',
  onClick: null,
  onChange: null,
  variant: 'default',
};
