import axios, { AxiosError } from 'axios';
import retry from 'axios-retry';
import Qs from 'qs';
import {
  languageLocalStorage,
  getCurrentLanguageFromStorage,
} from 'i18n-config';
import * as auth from 'utility/Authentication';
import store from 'store';
import { setError } from 'store/errors';
import {
  serviceLogoutStart,
  showTimeoutMessage,
} from 'store/authentication/authenticationAction';

export const NOBUSINESS_ERROR_CODE = 'NOBUSINESS';

export const DefaultAxiosRetryConfig = {
  retries: 2,
  retryCondition: (error) => {
    return error.response?.status >= 500;
  },
};

const isSubscriptionError = (response) =>
  response.data?.errors?.code === 'subscription_error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_PATH,
  withCredentials: true,
  timeout: 120000, // 2 minutes
});

retry(instance);

instance.interceptors.request.use((config) => {
  if (config.baseURL !== import.meta.env.VITE_API_BASE_PATH) {
    return config;
  }

  // if not in iframe
  if (window.location === window.parent.location && auth.status.authorized) {
    const businessId = auth.status.businessId;
    if (businessId && businessId !== 'null') {
      config.headers[`x-business-id`] = businessId;
    }
  }

  config.headers['accept-language'] =
    languageLocalStorage.val || getCurrentLanguageFromStorage();
  return config;
});

// errors shim
instance.interceptors.response.use(
  (response) => response,
  (err) => {
    const { data = {} } = err.response;

    if (typeof err.response.data !== 'string') {
      err.response.data.errors = { ...data };
    }
    return Promise.reject(err);
  }
);

instance.interceptors.response.useWrapped = function (
  onFullfilled,
  onRejected
) {
  instance.interceptors.response.use(function (response) {
    try {
      if (
        response.config.url === '/login' &&
        response.status === 200 &&
        !response.data.has_business
      ) {
        return onRejected(
          new AxiosError(
            'No Business Associated',
            response.config,
            NOBUSINESS_ERROR_CODE,
            response.request,
            response
          )
        );
      }
      return onFullfilled(response);
    } catch (e) {
      return onRejected(e);
    }
  }, onRejected);
};

instance.interceptors.response.useWrapped(
  (response) => response,
  async (error) => {
    const { response } = error;

    const isApiRequest =
      response.config.baseURL === import.meta.env.VITE_API_BASE_PATH;

    const isAuthenticationError =
      response.status === 401 || response.status === 403;

    // 403 error from v2 (hapi auth scope error) that represents only that the user hasn't yet
    // selected a business, but tried to do something business-specific.
    // The user can recover by selecting a business; the app _should_ be
    // in a state such that our auth routing will send the user to the
    // choose business page i.e. the user is logged in, but no business data in our store

    const isFreeTrialEnded =
      isApiRequest && window.location.href.endsWith('free-trial-ended');

    const isAppPermissionsError =
      response.status === 403 &&
      response.data &&
      String(response.data?.code).includes('permission_denied');
    if (
      isApiRequest &&
      isAuthenticationError &&
      !isAppPermissionsError &&
      error.code !== NOBUSINESS_ERROR_CODE
    ) {
      // we used to skip the logout API call, as making it would trigger
      // now the logout traps it
      // an infinite loop (call -> 401 --> intercept --> call)
      if (response.status === 403 && isSubscriptionError(response)) {
        const url = `${window.location.protocol}//${window.location.host}/free-trial-ended`;
        window.location.href = url;
      } else {
        if (response.status === 401) {
          store.dispatch(showTimeoutMessage());
        }
        store.dispatch(
          serviceLogoutStart({
            skipApi: false,
            redirect: window.location.pathname,
          })
        );
      }
      if (isFreeTrialEnded) return;
    } else if (isFreeTrialEnded) {
      return;
    } else {
      const skipErrorBoundary =
        error.config?.skipErrorBoundary === true ||
        (typeof error.config?.skipErrorBoundary === 'function' &&
          error.config.skipErrorBoundary(error));
      // display error page without logout
      if (
        error.isAxiosError &&
        !skipErrorBoundary &&
        (!response ||
          response?.status >= 500 ||
          [403, 404].includes(response?.status) ||
          error.code === NOBUSINESS_ERROR_CODE)
      ) {
        store.dispatch(
          setError({
            text: error.toString(),
            code:
              error.code === NOBUSINESS_ERROR_CODE
                ? error.code
                : error.response?.status ?? null,
            url: error.config.url,
          })
        );
      }
    }

    throw error;
  }
);

// to support &option_ids=123&option_ids=456 instead of option_ids[]=123
// https://github.com/axios/axios/issues/604#issuecomment-321460450
instance.defaults.paramsSerializer = (params) => {
  return Qs.stringify(params, { arrayFormat: 'repeat' });
};

export default instance;

export const getOriginalError = (err) => err?.response?.data;
export const getOriginalErrorStatusCode = (err) => err?.response.status;
