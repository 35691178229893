import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TriggerCard from '__pages/AutomationEngine/Engine/Steps/TriggerCard';
import { GenericOption } from '__pages/SmartConnectors/types';
import { FlowTrigger } from '../../types';
import { useHistory } from 'react-router-dom';
import { FlowBuilderContext } from '../../context';
import { SMARTCONNECTORS_PATHNAME } from '__pages/SmartConnectors/constants';

type TriggerStepSpreadsheetUploaded = {
  step: FlowTrigger;
};

export default function SpreadsheetUploadTriggerCard({
  step,
  ...others
}: TriggerStepSpreadsheetUploaded) {
  const { t } = useTranslation();
  const history = useHistory();

  const { smartConnector } = useContext(FlowBuilderContext);

  const menuOptions = useMemo(() => {
    return [
      {
        label: t('Edit'),
        value: 'edit',
      },
    ];
  }, [t]);

  const onSelectMenu = ({ value }: GenericOption) => {
    if (value === 'edit') {
      history.push(`${SMARTCONNECTORS_PATHNAME}/${smartConnector.id}`);
    }
  };

  return (
    <TriggerCard
      {...others}
      menuOptions={menuOptions}
      onSelectMenu={onSelectMenu}
    >
      {t('Spreadsheet Upload')}
    </TriggerCard>
  );
}
