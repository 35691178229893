import { forwardRef } from 'react';
import { FIELD_TYPES } from 'utility/constants';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';
import DropdownInlineField from 'components/Fields/InlineTableFieldInput/Dropdown';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import { isEmailStatusImmutable } from 'components/Fields/helpers';
import { withCellWrapper } from './helpers';

const _DropdownCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      editable,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { fieldType } = field;
    return (
      <DropdownInlineField
        ref={ref}
        {...others}
        value={value}
        onSubmit={onSubmit}
        readOnly={!editable || isEmailStatusImmutable(object, field)}
        onAutoFocus={onAutoFocus}
        field={field}
        object={object}
      >
        <Select
          isColored={fieldType === FIELD_TYPES.Status.type}
          placeholder={
            fieldType === FIELD_TYPES.Status.type &&
            FIELD_TYPES.Status.localizedPlaceholder(t)
          }
          menuLeftButton={field.isRequired ? null : menuLeftButton}
          menuRightButton={
            menuRightButton === undefined ? (
              <ApplySelectOverlayButton />
            ) : (
              menuRightButton
            )
          }
        />
      </DropdownInlineField>
    );
  }
);

export const DropdownCell = withCellWrapper(_DropdownCell);
