import React from 'react';
import styled from '@emotion/styled';

const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BuilderActivity = (props) => {
  const { children, ...others } = props;

  return (
    <>
      <ActivityWrapper {...others}>{children}</ActivityWrapper>
    </>
  );
};

BuilderActivity.displayName = 'BuilderActivity';

export default BuilderActivity;
