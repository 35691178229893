import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';
import { SizedCell, TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { gutters } from 'app/spacing';
import IconButtonAbsoluteMenu from 'components/Tables/Big/IconButtonAbsoluteMenu';
import IconButtonMenuWithObserver from 'components/Tables/IconButtonMenuWithObserver';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const styles = ({ above, position, overlay }) => css`
  && .IconButtonMenu__menu {
    ${!above &&
    `transform: translate(${
      position === 'right' ? '-100%' : '0'
    }, -${gutters.spacing(1)}px);`}
    ${above &&
    `transform: translate(${
      position === 'right' ? '-100%' : '0'
    }, -100%) translateY(-${gutters.spacing(4)}px);`}
  }
  ${overlay && 'position: absolute'}
`;

const ActionButtonAbsoluteMenu = styled(IconButtonAbsoluteMenu)`
  ${styles}
`;

export const ActionButtonMenu = styled(IconButtonMenu)`
  ${styles}
`;

export const ActionButtonMenuWithObserver = styled(IconButtonMenuWithObserver)`
  ${styles}
`;

const StyledSizedCell = styled(SizedCell)`
  ${({ overlay }) =>
    overlay &&
    css`
      && > div {
        height: 20px;
      }
    `}

  ${({ buttonWidth }) =>
    buttonWidth &&
    css`
      && > div > div {
        width: ${buttonWidth};
      }
    `}
`;

const MenuTypes = {
  absolute: ActionButtonAbsoluteMenu,
  relative: ActionButtonMenu,
  observable: ActionButtonMenuWithObserver,
};
export default styled(
  ({
    open,
    onOpen,
    onClose,
    options,
    data,
    overlay: overlayProp,
    title,
    onSelectAction,
    menuType = 'relative',
    observableRootMargin,
    position = 'right',
    value = {},
    isDisabledOption = false,
    ...others
  }) => {
    const Menu = MenuTypes[menuType];
    const overlay = menuType === 'absolute' ? overlayProp : undefined;

    return (
      <StyledSizedCell data={data} {...others} overlay={overlay}>
        <Menu
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          sizing="dense"
          position={position}
          overlay={overlay}
          above={data.meta && data.meta.openMenuAbove}
          color={colorsButton.iconGray}
          onChange={(value) => onSelectAction(value, data)}
          options={options}
          title={title}
          value={value}
          isDisabledOption={isDisabledOption}
          observableRootMargin={observableRootMargin}
          menuType={menuType}
        >
          <Icon icon="three-dot" />
        </Menu>
      </StyledSizedCell>
    );
  }
)`
  > div {
    display: flex;
    justify-content: flex-end;
  }
`;

export const ActionHeadCell = styled((props) => {
  const { t } = useTranslation();
  return (
    <SizedCell {...props}>
      <TextEllipsisWithTooltip as="span" weight="bold">
        {t('Actions')}
      </TextEllipsisWithTooltip>
    </SizedCell>
  );
})`
  text-align: right;
`;
ActionHeadCell.propTypes = {
  title: PropTypes.string.isRequired,
};
