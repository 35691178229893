// Common dynamic content components used in image & text settings
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ButtonRadio } from 'ts-components/ButtonToggle';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KizenTypography } from '__app/typography';
import Button from '__components/Button';
import { FlexSelect } from '../components';

export const STATIC = 'static';
export const DYNAMIC = 'dynamic';

type EditRulesButtonProps = {
  onClick(): void;
};

type ContentTypeRadioProps = {
  value: typeof STATIC | typeof DYNAMIC;
  onChange(x: ContentTypeRadioProps['value']): void;
};

type ContentRuleDropdownProps = {
  value: { value: string; label: string };
  options: ContentRuleDropdownProps['value'][];
  marginBottom?: number;
  onChange(option: ContentRuleDropdownProps['value']): void;
};

const StyledButton = styled(Button)`
  width: 100%;
  margin: 0;
`;

const DropdownContainer = styled.div<{ marginBottom?: number }>`
  margin-top: 20px;

  ${({ marginBottom }) =>
    Boolean(marginBottom) &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
`;

export const StyledButtonRadio = styled(ButtonRadio)`
  min-height: 34px;
`;

export const ContentTypeRadio = ({
  value,
  onChange,
  ...rest
}: ContentTypeRadioProps) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return [
      { value: STATIC, label: t('Static Content') },
      { value: DYNAMIC, label: t('Dynamic Content') },
    ];
  }, [t]);

  return (
    <StyledButtonRadio
      value={value}
      onChange={onChange}
      options={options}
      {...rest}
    />
  );
};

export const EditRulesButton = ({ onClick, ...rest }: EditRulesButtonProps) => {
  const { t } = useTranslation();

  return (
    <StyledButton variant="outline" color="blue" onClick={onClick} {...rest}>
      {t('Edit Rules')}
    </StyledButton>
  );
};

export const ContentRuleDropdown = ({
  value,
  options,
  marginBottom,
  onChange,
}: ContentRuleDropdownProps) => {
  const { t } = useTranslation();

  return (
    <DropdownContainer marginBottom={marginBottom}>
      <KizenTypography weight="bold">{t('Content Rule')}:</KizenTypography>
      <FlexSelect
        variant="underline"
        options={options}
        value={value}
        onChange={onChange}
      />
    </DropdownContainer>
  );
};
