import styled from '@emotion/styled';
import TextInput from '__components/Inputs/TextInput';
import { InputProps } from './types';

const TextInputContainer = styled.div<Pick<InputProps, 'width'>>`
  div {
    ${({ width }) => width && `width: ${width}px;`}

    input {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const Text = ({
  step,
  value,
  placeholder,
  width,
  error,
  onChange,
}: InputProps) => (
  <TextInputContainer width={width}>
    <TextInput
      value={value}
      width={width}
      placeholder={placeholder}
      error={error}
      onChange={(val: string) => onChange(step, val)}
    />
  </TextInputContainer>
);
