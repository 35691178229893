import { getTimezone } from 'countries-and-timezones';
import { addMinutes } from 'date-fns';
import { format } from 'date-fns2';

export const getDateWithTimezone = (datetime, timezone) => {
  return new Date(datetime).toLocaleString('en-US', {
    timeZone: timezone,
  });
};

export const businessLocalTimezoneOffset = (timezone) => {
  const businessTZ = getTimezone(timezone);
  const localTZ = getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

  if (!businessTZ || !localTZ) {
    return 0;
  }

  return businessTZ.utcOffset - localTZ.utcOffset;
};

export const businessLocalTimezoneDstOffset = (timezone) => {
  const businessTZ = getTimezone(timezone);
  const localTZ = getTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

  if (!businessTZ || !localTZ) {
    return 0;
  }

  return businessTZ.dstOffset - localTZ.dstOffset;
};

export const getNowTimezoneAdjusted = (timezone) => {
  const now = new Date();
  const amount = businessLocalTimezoneOffset(timezone);
  return addMinutes(now, amount);
};

export const getScheduledTimezoneAdjusted = (
  scheduled,
  timezone,
  reverse = false
) => {
  const amount = businessLocalTimezoneOffset(timezone);
  return addMinutes(scheduled, amount * (reverse ? -1 : 1));
};

export const getScheduledAutomationTimezoneAdjusted = (scheduled, timezone) => {
  const amount = businessLocalTimezoneOffset(timezone);
  return addMinutes(scheduled, amount);
};

export const isTimezoneEqualToLocal = (timezone) => {
  const tz = getTimezone(timezone);
  const local_tz = getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  if (!tz || !local_tz) {
    return false;
  }

  return tz.utcOffset === local_tz.utcOffset;
};

export const formatDateFns2 = (...args) => format(...args);
