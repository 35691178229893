import { useState, useCallback, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { toastVariant, useToast } from 'components/ToastProvider';
import useField from 'hooks/useField';
import { isReasonLostField, isStageField } from 'checks/fields';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { getOriginalError } from 'services/AxiosService';
import Loader from 'components/Kizen/Loader';
import { useObjectDetails } from 'queries/models/custom-objects';
import BasicModalWithConfirmation from '../presets/BasicModalWithConfirmation';
import {
  CONTACT,
  PIPELINE,
  CUSTOM_OBJECT,
  dummyObject,
  toCategoryOption,
  notHidden,
  getToastConfigMessage,
  getObjectName,
  saveEntity,
  getCreateEntityFormValues,
  PERMISSION_DENIED,
  UNARCHIVE_CONFLICT,
  UNARCHIVE_PROMPT,
  UNARCHIVE,
} from './helpers';

import { CategorySelector } from 'pages/Common/styles/NewEntityModal';
import {
  UnarchiveConflictModal,
  UnarchiveForbiddenModal,
} from '../UnarchiveModal';

import useFormValidation from 'hooks/useFormValidation';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { Fields } from './Fields';

export { CONTACT, PIPELINE, CUSTOM_OBJECT };

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const useDetails = (show, objectType, id) => {
  const clientObject = useSelector(getBusinessClientObject);
  const objectId = objectType === CONTACT ? clientObject.id : id;

  const { data: objectDetails = EMPTY_OBJECT, isLoading } = useObjectDetails(
    objectId,
    {
      enabled: show && !!objectId,
      refetchOnWindowFocus: false,
    }
  );

  const details = useMemo(
    () => ({
      ...objectDetails,
      ...(objectType !== CONTACT
        ? {
            access: {
              ...(objectDetails.access || {}),
              edit: objectDetails.entityAccess,
            },
          }
        : {}),
    }),
    [objectDetails, objectType]
  );

  return {
    details,
    isLoading,
  };
};

const CreateEntityModalFull = ({
  objectType,
  objectId,
  onCreated,
  onCancel,
  show,
  initialValues = EMPTY_OBJECT,
  handleUpdateFieldOption,
  defaultDirty = false,
}) => {
  const { t } = useTranslation();

  const [showToast] = useToast();
  const [dirty, setDirty] = useState(defaultDirty);
  const [isUnarchiveForbidden, setIsUnarchiveForbidden] = useState(false);
  const [isUnarchiveConflict, setIsUnarchiveConflict] = useState(false);

  const toastMessage = useRef(getToastConfigMessage(objectType, t));

  const { details, isLoading } = useDetails(show, objectType, objectId);

  const { fields = EMPTY_ARRAY, fieldCategories = EMPTY_ARRAY } = details;

  const categoryOptions = useMemo(() => {
    if (fields && fieldCategories) {
      return [...fieldCategories]
        .sort(({ order: a }, { order: b }) => a - b)
        .map((c) =>
          toCategoryOption({
            ...c,
            fields: fields?.filter((field) => field.category === c.id),
          })
        );
    }
  }, [fields, fieldCategories]);

  const [selectedCategory, setSelectedCategory] = useField(
    () => (categoryOptions ? categoryOptions[0] : null),
    [show, categoryOptions]
  );

  // used for UI
  const selectedFields = useMemo(() => {
    const categoryFelds =
      (selectedCategory && selectedCategory.original.fields) || [];
    return categoryFelds
      .filter(notHidden)
      .sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
  }, [selectedCategory]);

  const currentName = useMemo(
    () => getObjectName(details, objectType),
    [details, objectType]
  );

  const [values, setValues] = useField(() => {
    if (objectType === CONTACT && categoryOptions) {
      return getCreateEntityFormValues(
        { ...dummyObject, ...initialValues },
        fields
      );
    }

    if (!details) return {};

    const { name, owner, created, ...rest } = details;
    const reasonLostField = fields.find(isReasonLostField);
    const reasonLostFieldData = reasonLostField
      ? { ...reasonLostField, value: [] }
      : [];

    return getCreateEntityFormValues(
      { ...rest, ...initialValues, fields: [reasonLostFieldData] },
      fields
    );
  }, [details, show, categoryOptions]);

  const {
    validateFormState,
    validationProps,
    handleInputChange,
    setValidationState,
    firstFieldWithErrorRef,
  } = useFormValidation({
    setFormDirty: setDirty,
    formState: values,
    setFormState: setValues,
  });

  const handleClose = useCallback(() => {
    setValues({});
    onCancel?.();
    setDirty(false);
  }, [onCancel, setValues]);

  const handleChangeField = useCallback(
    (val, field, err) => {
      let reasonLostField;
      if (isStageField(field)) {
        const stage = field.options.find((opt) => opt.id === val);
        const stageDetails = details?.pipeline?.stages?.find(
          (s) => s.id === stage.id
        );
        if (stageDetails?.status !== 'lost') {
          reasonLostField = selectedFields.find(isReasonLostField);
        }
      }
      const relatedFields = reasonLostField
        ? {
            [reasonLostField.id]: [],
          }
        : {};
      handleInputChange(field.id, val, err, relatedFields);
    },
    [selectedFields, details, handleInputChange]
  );

  const handleSubmit = useCallback(
    async (unarchive = UNARCHIVE_PROMPT) => {
      try {
        const newRecord = await saveEntity(
          objectType,
          values,
          fields,
          unarchive,
          objectId,
          true
        );
        await onCreated(newRecord);
        showToast(
          unarchive === UNARCHIVE
            ? {
                variant: toastVariant.SUCCESS,
                message: `${t(`EntityName was successfully unarchived`, {
                  entityName: currentName,
                })}`,
              }
            : toastMessage.current.success
        );
        handleClose();
      } catch (error) {
        const orig = snakeToCamelCaseKeys(getOriginalError(error));
        if (orig && orig.code) {
          if (orig.code === PERMISSION_DENIED) {
            setIsUnarchiveForbidden(true);
          }
          if (orig.code === UNARCHIVE_CONFLICT) {
            setIsUnarchiveConflict(true);
          }
        }
        // special error status
        if (orig && orig.nonFieldErrors) {
          showToast({
            ...toastMessage.current.error,
            message: orig.nonFieldErrors.toString(),
          });
        } else if (orig) {
          const errState = {};
          let firstField;
          Object.keys(orig).forEach((item) => {
            const f = fields.find((i) => item === i.name);
            if (!f) return;
            if (!firstField) {
              firstField = f;
            }
            errState[f.id] = Array.isArray(orig[item])
              ? orig[item][0]
              : orig[item];
          });

          const categoryWithRequiredFields = categoryOptions.find((item) => {
            const fieldIds = item.original.fields.flatMap((field) => field.id);
            return fieldIds.includes(firstField.id);
          });
          setSelectedCategory(categoryWithRequiredFields);
          firstFieldWithErrorRef.current = firstField.id;
          setValidationState(errState);
        } else {
          // rest unhandled cases
          showToast(toastMessage.current.error);
        }
      }
    },
    [
      fields,
      categoryOptions,
      currentName,
      handleClose,
      objectId,
      objectType,
      onCreated,
      setSelectedCategory,
      showToast,
      values,
      t,
      firstFieldWithErrorRef,
      setValidationState,
    ]
  );

  const handleUnarchive = useCallback(
    (unarchive) => {
      handleSubmit(unarchive);
      setIsUnarchiveConflict(false);
    },
    [handleSubmit]
  );

  return (
    <BasicModalWithConfirmation
      dirty={dirty}
      className={`no-drag`}
      show={!!objectType && show}
      onHide={handleClose}
      onConfirm={async () => {
        if (!validateFormState(t)) {
          const field = fields.find(
            (f) => f.id === firstFieldWithErrorRef.current
          );
          const categoryOption = categoryOptions.find(
            (opt) => opt.value === field.category
          );
          setSelectedCategory(categoryOption);
          return;
        }
        await handleSubmit();
      }}
      heading={
        objectType === CONTACT
          ? t(`Add Contact`)
          : `${t('Add')} ${details?.entityName || ''}`
      }
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      hasOneBtnSubmit
      showLoadingIndicator
    >
      <Loader loading={isLoading}>
        <UnarchiveForbiddenModal
          show={isUnarchiveForbidden}
          onConfirm={() => setIsUnarchiveForbidden(false)}
          onHide={() => setIsUnarchiveForbidden(false)}
          name={currentName}
        />
        <UnarchiveConflictModal
          show={isUnarchiveConflict}
          onConfirm={() => handleUnarchive(UNARCHIVE)}
          onAdditionalConfirm={() => handleUnarchive('overwrite')}
          onHide={() => setIsUnarchiveConflict(false)}
          name={currentName}
        />
        <CategorySelector
          small
          portal
          classNamePrefix="CategorySelector"
          options={categoryOptions}
          value={selectedCategory}
          onChange={setSelectedCategory}
          objectId={objectId}
        />
        <Fields
          key={selectedCategory?.value}
          selectedFields={selectedFields}
          handleChangeField={handleChangeField}
          validationProps={validationProps}
          handleUpdateFieldOption={handleUpdateFieldOption}
          details={details}
          objectType={objectType}
          values={values}
          objectId={objectId}
        />
      </Loader>
    </BasicModalWithConfirmation>
  );
};

export default CreateEntityModalFull;
