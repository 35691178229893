import { ForwardedRef, ReactNode, forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ElementContainerProps } from '../../types';
import { handleLegacyBackgroundColor } from './utils';

type Props = ElementContainerProps & {
  children: ReactNode;
  ignoreChildrenDrag?: boolean;
  isInBuilderContext?: boolean;
};

export const DEFAULT_ELEMENT_CONTAINER_PROPS = {
  containerBackgroundColor: 'inherit',
  containerBackgroundImageName: '',
  containerBackgroundPositionX: '0%',
  containerBackgroundPositionY: '0%',
  containerBackgroundSize: 'auto',
  containerBackgroundRepeat: 'repeat',
  containerBorderColor: '#4A5660',
  containerBorderStyle: 'solid',
  containerBorderWidth: '0',
  containerBorderRadius: true,
  containerBorderTopLeftRadius: '4',
  containerBorderTopRightRadius: '4',
  containerBorderBottomLeftRadius: '4',
  containerBorderBottomRightRadius: '4',
  containerMarginTop: '0',
  containerMarginRight: '0',
  containerMarginBottom: '0',
  containerMarginLeft: '0',
  containerPaddingTop: '0',
  containerPaddingRight: '0',
  containerPaddingBottom: '0',
  containerPaddingLeft: '0',
};

export const EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES = {
  containerBackgroundPositionX: 'center',
  containerBackgroundPositionY: 'top',
  containerBorderTopLeftRadius: '0',
  containerBorderTopRightRadius: '0',
  containerBorderBottomLeftRadius: '0',
  containerBorderBottomRightRadius: '0',
};

const pixelValue = (value: any, fallback: number | undefined = undefined) => {
  const numValue = Number(value);
  return isNaN(numValue) ? fallback : `${numValue}px`;
};

const ignoreChildrenDragStyle = ({
  ignoreChildrenDrag,
}: Pick<Props, 'ignoreChildrenDrag'>) =>
  ignoreChildrenDrag &&
  css`
    pointer-events: none;
  `;

const Container = styled.div<Props>`
  ${ignoreChildrenDragStyle}
  ${(props) => css`
    background-color: ${handleLegacyBackgroundColor(
      props.containerBackgroundColor
    )};
    ${props.containerBackgroundImageSrc &&
    css`
      background-image: url(${props.containerBackgroundImageSrc});
    `}
    background-position-x: ${props.containerBackgroundPositionX};
    background-position-y: ${props.containerBackgroundPositionY};
    background-size: ${props.containerBackgroundSize};
    background-repeat: ${props.containerBackgroundRepeat};
    border-color: ${props.containerBorderColor};
    border-style: ${props.containerBorderStyle};
    border-width: ${pixelValue(props.containerBorderWidth, 0)};
    ${!props.isInBuilderContext &&
    css`
      margin-top: ${pixelValue(props.containerMarginTop)};
      margin-right: ${pixelValue(props.containerMarginRight)};
      margin-bottom: ${pixelValue(props.containerMarginBottom)};
      margin-left: ${pixelValue(props.containerMarginLeft)};
    `}
    padding-top: ${pixelValue(props.containerPaddingTop)};
    padding-right: ${pixelValue(props.containerPaddingRight)};
    padding-bottom: ${pixelValue(props.containerPaddingBottom)};
    padding-left: ${pixelValue(props.containerPaddingLeft)};
  `}
  ${({ containerBorderRadius, ...props }) =>
    containerBorderRadius &&
    css`
      border-top-left-radius: ${pixelValue(props.containerBorderTopLeftRadius)};
      border-top-right-radius: ${pixelValue(
        props.containerBorderTopRightRadius
      )};
      border-bottom-left-radius: ${pixelValue(
        props.containerBorderBottomLeftRadius
      )};
      border-bottom-right-radius: ${pixelValue(
        props.containerBorderBottomRightRadius
      )};
    `}
`;

export const ElementContainer = forwardRef(
  ({ children, ...rest }: Props, ref: ForwardedRef<HTMLDivElement>) => (
    <Container ref={ref} {...rest}>
      {children}
    </Container>
  )
);
