import { Extension } from '@tiptap/core';

const DEFAULT = 'default';

export const LineHeight = Extension.create({
  name: 'lineHeight',

  addOptions() {
    return {
      // The configurable `defaultValue` option is what will be used when the lineHeight
      // setting is set to the string value 'default' rather than a number.
      defaultValue: 1.25,
      types: ['paragraph'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          lineHeight: {
            parseHTML: (element) => element.getAttribute('data-line-height'),
            renderHTML: (attributes) => {
              if (!attributes.lineHeight) {
                return {
                  style: `line-height: ${this.options.defaultValue}`,
                  'data-line-height': DEFAULT,
                };
              }

              const value =
                attributes.lineHeight === DEFAULT
                  ? this.options.defaultValue
                  : attributes.lineHeight;

              return {
                style: `line-height: ${value}`,
                'data-line-height': attributes.lineHeight,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setLineHeight:
        (lineHeight) =>
        ({ commands }) => {
          return this.options.types.every((type) =>
            commands.updateAttributes(type, {
              lineHeight,
              'data-line-height': lineHeight,
            })
          );
        },
      unsetLineHeight:
        () =>
        ({ commands }) => {
          return this.options.types.every((type) =>
            commands.updateAttributes(type, {
              lineHeight: DEFAULT,
              'data-line-height': DEFAULT,
            })
          );
        },
    };
  },
});
