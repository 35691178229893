import React from 'react';
import * as PropTypes from 'prop-types';

import Button from 'components/Button';

const ActionButtons = ({ mobile, onSelect, performActionOptions }) => (
  <>
    {performActionOptions.map(({ value, label }) => (
      <Button
        key={value}
        color="blue"
        variant="outline"
        onClick={() => onSelect({ label, value })}
      >
        {label}
      </Button>
    ))}
    {/* These spacers allow for up to four columns of buttons */}
    {!mobile && (
      <>
        <span />
        <span />
      </>
    )}
  </>
);

ActionButtons.propTypes = {
  mobile: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  performActionOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ActionButtons.defaultProps = {};

export default ActionButtons;
