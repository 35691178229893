import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';

import { ContentWidth } from 'components/Layout/PageContentWidth';
import { ApiCard } from '../styles';
import { TableTitleWrapper, TableBoxesWrapper } from '../tableStyles';
import ProfileInfiniteTable from '../ProfileInfiniteTable';

import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';

import { getOAuthTokenAccess } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';
import { useAccessTokens } from 'hooks/accessTokens/useAccessTokens';

import { createColumns } from 'pages/Account/pages/Profile/Forms/OAuth/columns';
import { APIKeysNotice } from 'pages/Account/pages/Profile/Forms/OAuth/APIKeysNotice';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const APPLICATION_WIDTH = '245px';
const STATUS_WIDTH = '80px';
const REFRESH_WIDTH = '140px';
const ACTION_WIDTH = '51px';
const PADDING_WIDTH = '40px';

const columnWidths = {
  application: APPLICATION_WIDTH,
  status: STATUS_WIDTH,
  created: REFRESH_WIDTH,
  actions: ACTION_WIDTH,
  padding: PADDING_WIDTH,
};

const _OAuth = () => {
  const { t } = useTranslation();
  const teamMember = useSelector((s) => s.authentication.teamMember || {}); // current user
  const {
    handleTokenRevoke: onTokenRevoke,
    accessTokens,
    oauthTokensModalProps,
    messageHeadData,
    messagesSort,
    setMessagesSort,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,
  } = useAccessTokens(teamMember?.id);

  const columns = useMemo(
    () =>
      createColumns({
        onSelectAction: ({ value }, key) => {
          const { id } = key;
          if (value === 'onTokenRevoke') {
            onTokenRevoke(id);
          }
        },
        columnWidths,
        canRevoke: true,
        t,
      }),
    [onTokenRevoke, t]
  );

  const anyAccessTokens = accessTokens?.pages
    ? Boolean(accessTokens?.pages[0].count)
    : false;

  return (
    <>
      <ApiCard as={ContentWidth}>
        <TableTitleWrapper>
          <KizenTypography as="h3" type="subheader">
            {t('OAuth Authorizations')}
          </KizenTypography>
        </TableTitleWrapper>
        <TableBoxesWrapper>
          <APIKeysNotice>
            <KizenTypography as="div">
              {t(
                `(For Techies) A list of OAuth authorizations you've established in this business for your user are enumerated below. You can revoke your token at any time.`
              )}
              {!anyAccessTokens
                ? t(
                    ` You don't yet have any authorizations. Establish an authorization starting from the tool you are trying to integrate with.`
                  )
                : null}
            </KizenTypography>
          </APIKeysNotice>
        </TableBoxesWrapper>

        {accessTokens && (
          <>
            <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
              <ScrollContainerBlocker />
            </ScrollContainerBlockerWrapper>
            <ProfileInfiniteTable
              columns={columns}
              data={accessTokens}
              messageHeadData={messageHeadData}
              messagesSort={messagesSort}
              setMessagesSort={setMessagesSort}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isFetching={isFetching}
              status={status}
            />
          </>
        )}
      </ApiCard>
      <ConfirmationModal
        buttonText={t('Confirm')}
        heading={t('Please Confirm Revocation')}
        {...oauthTokensModalProps}
      >
        {t(
          'Revoking this authorization will make all subsequent requests using the token invalid and denied by Kizen.'
        )}
      </ConfirmationModal>
    </>
  );
};

const OAuth = (props) => {
  const access = useSelector(getOAuthTokenAccess);
  return access ? _OAuth(props) : false;
};

export default OAuth;
