import WholeNumberInput from '__components/Inputs/TextInput/presets/WholeNumber';
import { InputProps } from './types';

export const Integer = ({
  step,
  value,
  width,
  placeholder,
  error,
  onChange,
}: InputProps) => {
  return (
    <WholeNumberInput
      value={value}
      width={width}
      placeholder={placeholder}
      error={error}
      onChange={(val: string) => onChange(step, val.replaceAll(',', ''))}
    />
  );
};
