import { CUSTOM_RECORDS } from 'queries/query-keys';
import { useInfiniteQuery } from 'react-query';
import { getHash } from 'utility/encode';
import { handleFetchRecords } from '../util';

const useInfiniteQueryForTable = ({
  objectId,
  search,
  dashlet,
  filtersForCharts,
  contacts,
  model,
  sort,
  fieldIds = [],
  searchWithinFieldIds,
}) => {
  const infiniteQuery = useInfiniteQuery({
    enabled: Boolean(objectId) && Boolean(model),
    queryKey: [
      ...CUSTOM_RECORDS.LIST(objectId),
      search,
      getHash(
        dashlet?.config?.filters?.customFilters
          ? JSON.stringify(dashlet.config.filters.customFilters)
          : ''
      ),
      getHash(filtersForCharts ? JSON.stringify(filtersForCharts) : ''),
      contacts,
      sort,
      fieldIds,
      dashlet?.config?.filters?.inGroupIds,
      dashlet?.config?.filters?.notInGroupIds,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await handleFetchRecords({
        modelId: objectId,
        search,
        page: pageParam,
        pageSize: 50,
        model,
        dashletFilters: dashlet?.config?.filters?.customFilters,
        inGroupIds: dashlet?.config?.filters?.inGroupIds ?? [],
        notInGroupIds: dashlet?.config?.filters?.notInGroupIds ?? [],
        filtersForCharts,
        client: contacts,
        ordering: `${sort.direction === 'desc' ? '-' : ''}${
          // Special case to handle difference in ID and same when using client vs custom object fields
          sort.isDefault ? sort.name : sort.id ?? sort.name
        }`,
        fieldIds,
        searchWithinFieldIds,
      });

      return response;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.next) {
        return new URL(lastPage.next).searchParams.get('page');
      }
    },
    getPreviousPageParam: (previousPage) => {
      if (previousPage.previous) {
        return new URL(previousPage.previous).searchParams.get('page');
      }
    },
    keepPreviousData: true,
  });

  return infiniteQuery;
};

export default useInfiniteQueryForTable;
