import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactive } from '@kizen/permissions/hooks/useReactive';
import { usePermissionsContext } from '@kizen/permissions/hooks/context';
import TextInput from 'components/Inputs/TextInput';
import { useFlashTransition } from 'hooks/useFlashState';
import {
  REGISTERED_ROLE_AND_GROUP_NAME,
  registeredRoleAndGroupError,
} from 'pages/Settings/helpers';

export const PermissionGroupName = ({
  isEdit,
  nameError,
  scrollToRef,
  autoFocus = false,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { name } = usePermissionsContext();
  const value = useReactive(name);
  const [message, showMessage, flash] = useFlashTransition();

  useEffect(() => {
    if (nameError) {
      flash(nameError);
      if (ref?.current) {
        ref?.current?.scrollIntoView({
          behavior: 'instant',
          block: 'center',
        });
      }
    }
  }, [nameError, flash, scrollToRef]);

  return (
    <TextInput
      ref={ref}
      label={t('Name Your Permission Group')}
      placeholder={t('Name Your Permission Group')}
      value={value}
      error={!!nameError}
      disabled={isEdit && value === REGISTERED_ROLE_AND_GROUP_NAME}
      validate={{
        full: (value) => {
          if (value === REGISTERED_ROLE_AND_GROUP_NAME) {
            return registeredRoleAndGroupError(t);
          }
          return null;
        },
        message,
        showMessage,
      }}
      autoFocus={autoFocus}
      onChange={(v) => name.next(v)}
    />
  );
};
