import {
  ColorIconSettings,
  CompoundIconSettings,
  CustomIconSettings,
  IconAnimation,
  IconRotation,
  IconSemantics,
  KDSIcon,
  SemanticCategory,
  FixedWidthIconSettings,
  IconLabel,
} from './types';

interface CreateIconSetParams {
  icons: IconSemantics;
  SEMANTIC_CATEGORY: SemanticCategory;
  rotation: IconRotation;
  animation: IconAnimation;
  compoundIcons?: CompoundIconSettings;
  colorDefinitions?: ColorIconSettings;
  customIcons?: CustomIconSettings;
  fixedWidthIcons?: FixedWidthIconSettings;
  labels?: IconLabel;
}

const getFontAwesomeName = (
  icons: IconSemantics,
  iconKey: string,
  compoundIcons?: CompoundIconSettings
) => {
  if (icons[iconKey]) {
    return `${icons[iconKey]?.prefix}-${icons[iconKey]?.iconName}`;
  } else if (compoundIcons?.[iconKey]) {
    let baseName = '';
    if (compoundIcons[iconKey].primary) {
      baseName = `${compoundIcons[iconKey].primary!.prefix}-${
        compoundIcons[iconKey].primary!.iconName
      }`;
    }

    if (compoundIcons[iconKey].secondary) {
      if (baseName.length) {
        baseName += ' + ';
      }
      baseName += `${compoundIcons[iconKey].secondary!.prefix}-${
        compoundIcons[iconKey].secondary!.iconName
      }`;
    }

    return baseName;
  }

  return '';
};

export const createIconSet = ({
  icons,
  SEMANTIC_CATEGORY,
  rotation,
  animation,
  compoundIcons,
  colorDefinitions,
  customIcons,
  fixedWidthIcons,
  labels,
}: CreateIconSetParams) => {
  return Object.keys(icons).reduce((acc, iconKey) => {
    const semanticName = iconKey
      ? `${SEMANTIC_CATEGORY}-${iconKey}`
      : SEMANTIC_CATEGORY;

    return {
      ...acc,
      [semanticName]: {
        icon: icons[iconKey],
        category: SEMANTIC_CATEGORY,
        rotation: rotation[iconKey],
        animation: animation[iconKey],
        semanticName,
        title: labels?.[iconKey]?.title,
        description: labels?.[iconKey]?.description,
        fontAwesomeName: getFontAwesomeName(icons, iconKey, compoundIcons),
        compoundIconSettings: compoundIcons?.[iconKey],
        colorDefinition: colorDefinitions?.[iconKey],
        customIcon: customIcons?.[iconKey],
        fixedWidth: fixedWidthIcons?.[iconKey],
      } as KDSIcon,
    };
  }, {}) as Record<string, KDSIcon>;
};
