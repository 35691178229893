import { useCallback, useContext } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { VALUE_RESPONSES } from 'components/Wizards/RTDV/types';
import { BUCKETING_TYPES } from 'components/Wizards/utils';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import ValueBreakdown from 'components/Wizards/shared/components/BreakdownAndBucketing/ValueBreakdown';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import { useTranslation } from 'react-i18next';
import BucketConfiguration from 'components/Wizards/shared/components/BreakdownAndBucketing/BucketConfiguration';
import EntityBreakdown from 'components/Wizards/shared/components/BreakdownAndBucketing/EntityBreakdown';
import { StyledSwitch } from '../styles';

const BreakdownValueType = ({ selectedField }) => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const { t } = useTranslation();

  const { value, bucketingType, valueBreakdown, bucketBlank } = state;

  const currencySymbol = selectedField?.moneyOptions?.symbol;

  const isDecimal =
    selectedField?.fieldType === FIELD_TYPES.Decimal.type ||
    selectedField?.fieldType === FIELD_TYPES.Money.type;

  const handleValueBreakdownChange = useCallback(
    (breakdown, isErrored) => {
      setState('valueBreakdown', breakdown);
      setState('valueBreakdownError', isErrored);
    },
    [setState]
  );

  const onChangeBucketBlank = useCallback(
    (e) => {
      return setState('bucketBlank', e.target.checked);
    },
    [setState]
  );

  if (value?.value !== VALUE_RESPONSES.BREAKDOWN) {
    return null;
  }

  const shouldHideNullToggle = [
    DEFAULT_FIELD_NAMES.emailStatus,
    DEFAULT_FIELD_NAMES.stage,
  ].includes(selectedField?.name);

  return (
    <>
      {value?.value === VALUE_RESPONSES.BREAKDOWN &&
      bucketingType === BUCKETING_TYPES.NUMBER ? (
        <StyledWizardField top={28}>
          <KizenTypography type="subheader">
            {t('Configure Value Breakdown')}
          </KizenTypography>
          <StyledWizardField top={6}>
            <ValueBreakdown
              integer={!isDecimal}
              onChange={handleValueBreakdownChange}
              initial={valueBreakdown}
              currencySymbol={currencySymbol}
              field={selectedField}
              key={selectedField?.id}
            />
          </StyledWizardField>
        </StyledWizardField>
      ) : null}
      {value?.value === VALUE_RESPONSES.BREAKDOWN &&
      bucketingType === BUCKETING_TYPES.OPTIONS ? (
        <>
          <StyledWizardField top={28}>
            <KizenTypography type="subheader">
              {t('Configure Buckets')}
            </KizenTypography>
            <StyledWizardField top={6}>
              <BucketConfiguration
                onChange={handleValueBreakdownChange}
                initial={valueBreakdown}
                field={selectedField}
                fieldObject={state?.fieldObject}
                key={selectedField?.id}
              />
            </StyledWizardField>
          </StyledWizardField>
          {shouldHideNullToggle ? null : (
            <StyledWizardField top={8}>
              <KizenTypography>
                {t('Include Null/Blank Values in Separate Bucket')}
              </KizenTypography>
              <StyledWizardField top={2}>
                <StyledSwitch
                  checked={bucketBlank ?? false}
                  onChange={onChangeBucketBlank}
                  className="include-blank-switch"
                />
              </StyledWizardField>
            </StyledWizardField>
          )}
        </>
      ) : null}
      {!!selectedField &&
        value?.value === VALUE_RESPONSES.BREAKDOWN &&
        bucketingType === BUCKETING_TYPES.ENTITIES && (
          <>
            <StyledWizardField top={12}>
              <KizenTypography type="subheader">
                {t(
                  'Configure Entity Records to Display as Buckets (Leave Blank for All)'
                )}
              </KizenTypography>
              <StyledWizardField top={6}>
                <EntityBreakdown
                  onChange={handleValueBreakdownChange}
                  values={valueBreakdown}
                  field={selectedField}
                  key={selectedField?.id}
                />
              </StyledWizardField>
            </StyledWizardField>
            {shouldHideNullToggle ? null : (
              <StyledWizardField top={8}>
                <KizenTypography>
                  {t('Include Null/Blank Values in Separate Bucket')}
                </KizenTypography>
                <StyledWizardField top={2}>
                  <StyledSwitch
                    checked={bucketBlank ?? false}
                    onChange={onChangeBucketBlank}
                    className="include-blank-switch"
                  />
                </StyledWizardField>
              </StyledWizardField>
            )}
          </>
        )}
    </>
  );
};

export default BreakdownValueType;
