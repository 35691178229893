import { Fragment, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@kizen/kds/Button';
import produce from 'immer';
import Draggable from 'react-draggable';
import ActivityService from 'services/ActivityService';
import { camelToSnakeCaseKeys } from 'services/helpers';
import CustomFieldChooserModal from 'components/Modals/CustomFieldChooser';
import ActivityBuilderPreview from 'components/Modals/ActivityBuilderPreview';
import useModal from 'components/Modals/useModal';

import BuilderField, {
  fieldMargin,
  menuBuildOptions as menuBuildOptionsOrigin,
} from 'components/Builders/CustomFieldsBuilder/Field';
import { FieldPlaceholder } from 'components/Builders/CustomFieldsBuilder/styles';

import {
  HorizontalDropzone,
  VerticalDropzone,
} from 'components/DragAndDropLayout/Dropzone';

import { KizenTypography } from 'app/typography';
import {
  dropzoneIs,
  applyDropzone,
  getItemCols,
  hiddenFieldsToBottom,
} from 'components/DragAndDropLayout/helpers';
import { isOwnerField } from 'checks/fields';

import { getFieldDropzone } from 'components/Builders/CustomFieldsBuilder/helpers';

import {
  MENU_ACTION_SHOW,
  MENU_ACTION_HIDE,
  MENU_ACTION_REQUIRED,
  MENU_ACTION_OPTIONAL,
  MENU_ACTION_EDIT_OPTIONALS,
  MENU_ACTION_ADD_RULE,
} from 'components/Builders/CustomFieldsBuilder/menuActionOptions';

import { isDefaultUndeletableNotesField } from 'checks/fields';
import { invalidate } from 'queries/invalidate';
import BuilderActivity from './Activity';
import ActivityFieldWizard from './Field';

import {
  BigCard,
  TitleWrapper,
  ActivitiesTitle,
  ActivitiesFooter,
  FooterNewFieldTypography,
  FieldLayout,
  ItemWrapper,
  RightHeader,
} from './styles';
import { useCustomFieldChooserWithCache } from 'components/Modals/CustomFieldChooser/hooks';
import useExportFieldMetadata from 'hooks/useExportFieldMetadata';
import {
  deleteFieldFromVisibilityRules,
  isVisibilityRuleInvalid,
} from 'ts-activities/utils';
import { getOriginalError } from 'services/AxiosService';
import { toastVariant, useToast } from 'components/ToastProvider';
import { flushSync } from 'react-dom';

// we do not show required options for activity Owner field
class menuBuildOptions extends menuBuildOptionsOrigin {
  static required = (field, t) => {
    if (isOwnerField(field)) return [];
    return menuBuildOptionsOrigin.required(field, t);
  };
}

function NewField({ onClick, label, disabled, ...rest }) {
  return (
    <FooterNewFieldTypography
      as="span"
      weight="bold"
      size="buttonLabel"
      textTransform="uppercase"
      onClick={(ev) => {
        if (!disabled) {
          onClick(ev);
        }
      }}
      disabled={disabled}
      {...rest}
    >
      {label}
    </FooterNewFieldTypography>
  );
}

const DELETE_ACTIVITY_MESSAGE =
  'This will permanently delete the activity field.';
const DELETE_CUSTOM_MESSAGE =
  'This will delete the custom field from the activity.';

const ACTIVITY_CUSTOM_FIELD = 'activity_custom_field';

const findFieldInActivity = ({ fields }, fieldId) => {
  return fields.find((field) => field.id === fieldId);
};

// dynamic tags expect a model and a fetch url, I've asked Scott where the tags should go,
// but this stops things crashing for now
const activityToModel = (activity) => {
  const { fields, name, ...others } = activity;
  return {
    ...others,
    name,
    description: 'Submissions',
    fetchUrl: '',
    noLink: true,
    hideCount: true,
  };
};

function ActivitiesBuilder({ activity, setActivity }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [fieldDragging, setFieldDragging] = useState(null);
  const [fieldDropzone, setFieldDropzone] = useState(null);
  const [showToast] = useToast();
  const [wizardData, setWizardData] = useState({});
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const { handleFieldExport } = useExportFieldMetadata({ activity });

  const customFieldChooserProps = useCustomFieldChooserWithCache(
    (categories, field) => {
      return categories.reduce((acc, category) => {
        const options = category.options.filter(
          ({ item: { isReadOnly, id } }) => {
            return (
              !isReadOnly &&
              (field ||
                !activity.fields?.some(
                  ({ customObjectField }) => customObjectField?.id === id
                ))
            );
          }
        );

        if (!options.length) {
          return acc;
        }

        acc.push({ ...category, options });

        return acc;
      }, []);
    }
  );
  const applyFieldDropzone = async () => {
    if (!fieldDragging || !fieldDropzone) {
      return;
    }
    const nextActivity = produce(activity, (draft) => {
      const [fromFields] = applyDropzone(
        fieldDragging,
        [draft.fields],
        fieldDropzone
      );
      draft.fields = fromFields;
    });

    // Optimistically display the drop
    setActivity(nextActivity);
    await ActivityService.updateObjectStyles(nextActivity);
  };

  const updateFieldStore = (field) => {
    const { id } = field;
    const nextActivity = produce(activity, (draft) => {
      const f = findFieldInActivity(draft, id);

      if (f) {
        // update the two fields & options
        f.isRequired = field.isRequired;
        f.displayName = field.displayName;
        f.options = field.options;
        f.meta = field.meta;

        if (f.fieldType === 'relationship') {
          f.relation.cardinality = field.relation.cardinality;
        }
      } else {
        const order = draft.fields.length + 1;
        draft.fields.push({
          ...field,
          order,
        });
      }
      draft.fields = hiddenFieldsToBottom(draft.fields);
    });
    setActivity(nextActivity);
    ActivityService.updateObjectStyles(nextActivity);
  };

  const [activityFieldWizardModalProps, , activityFieldWizardModal] = useModal({
    handleSubmit: (field) => updateFieldStore(field),
  });

  const handleFieldEditOptions = async (field) => {
    setWizardData({
      activityObjectId: activity.id,
      field,
      model: activityToModel(activity), // the activity is the model in this case as far as the wizard is concerned
    });
    activityFieldWizardModal.show();
  };

  const handleNewActivityField = () => {
    setWizardData({
      activityObjectId: activity.id,
      model: activityToModel(activity), // the activity is the model in this case as far as the wizard id concerned
    });

    activityFieldWizardModal.show();
  };

  const updateVisibilityRulesAfterDeleteOrDeactivate = async (
    fieldId,
    isDelete,
    nextActivity
  ) => {
    const updatedVisibilityRules = Array.isArray(activity.visibilityRules)
      ? deleteFieldFromVisibilityRules(
          camelToSnakeCaseKeys(activity.visibilityRules),
          fieldId
        )
      : [];
    const invalidChecks = updatedVisibilityRules.map(isVisibilityRuleInvalid);
    const firstInvalidRule = invalidChecks.findIndex((x) => x === true);

    if (firstInvalidRule > -1) {
      const error_message = isDelete
        ? t('The field has been deleted')
        : t('The field has been deactivated');
      const invalidCount = invalidChecks.filter((x) => x === true).length;
      const withErrorMessages = updatedVisibilityRules.map((vr) => {
        if (vr.rule.filters.length) return vr;
        const filter = {
          view_model: [['field_id', { error: true, error_message }]],
        };
        return { ...vr, rule: { ...vr.rule, filters: [filter] } };
      });
      history.push(`/activities/${activity.id}/rules`, {
        visibilityRules: withErrorMessages,
        scrollCardIndex: firstInvalidRule,
        errorMessage: t(
          'You have {{count}} invalid {{rules}} after {{action}} your field. Please update your rules before leaving activity settings.',
          {
            count: invalidCount,
            rules: invalidCount > 1 ? t('rules') : t('rule'),
            action: isDelete ? t('deleting') : t('deactivating'),
          }
        ),
      });
    } else {
      const res = await ActivityService.v2UpdateActivity({
        id: activity.id,
        visibilityRules: updatedVisibilityRules,
      });
      invalidate.ACTIVITIES.ACTIVITY(activity.id);

      if (nextActivity) {
        setActivity({ ...nextActivity, visibilityRules: res.visibilityRules });
      } else {
        setActivity((prev) => ({
          ...prev,
          visibilityRules: res.visibilityRules,
        }));
      }
    }
  };

  const handleFieldDelete = async ({ id }) => {
    const nextActivity = produce(activity, (draft) => {
      const f = draft.fields.filter((field) => field.id !== id);
      if (f) {
        draft.fields = f;
      }
    });
    setActivity(nextActivity);

    await ActivityService.v2DeleteField({
      activityObjectId: nextActivity.id,
      id,
    });

    updateVisibilityRulesAfterDeleteOrDeactivate(id, true);
  };

  const handleFieldResize = async (cols, { id, meta: prev }) => {
    const meta = { ...prev, cols };

    const nextActivity = produce(activity, (draft) => {
      const f = findFieldInActivity(draft, id);
      if (f) {
        f.meta = meta;
      }
    });
    // Optimisitically change field width
    setActivity(nextActivity);

    await ActivityService.v2UpdateField({
      activityObjectId: nextActivity.id,
      id,
      meta,
    });
  };
  const handleNewCustomField = () => {
    setWizardData({
      title: t('Add Custom Field'),
      displayNamePrefix: 'Activity',
      activityObjectId: activity.id,
      activityCustomObjects: activity.customObjects,
    });
    setShowCustomFieldModal(true);
    return true;
  };

  const handleEditCustomField = (field) => {
    setWizardData({
      title: t('Edit Custom Field'),
      displayNamePrefix: 'Activity',
      field,
      activityObjectId: activity.id,
    });
    setShowCustomFieldModal(true);
    return true;
  };

  const handleFieldSelectAction = async ({ value: action }, field) => {
    if (
      action === MENU_ACTION_SHOW ||
      action === MENU_ACTION_HIDE ||
      action === MENU_ACTION_REQUIRED ||
      action === MENU_ACTION_OPTIONAL
    ) {
      const { id } = field;
      let updateFields = false;

      const nextActivity = produce(activity, (draft) => {
        const f = findFieldInActivity(draft, id);
        if (f) {
          switch (action) {
            case MENU_ACTION_HIDE:
              f.isHidden = true;
              f.isRequired = false;
              updateFields = true;
              break;

            case MENU_ACTION_SHOW:
              f.isHidden = false;
              break;

            case MENU_ACTION_REQUIRED:
              f.isRequired = true;
              break;

            case MENU_ACTION_OPTIONAL:
              f.isRequired = false;
              break;

            default:
              break;
          }

          draft.fields = hiddenFieldsToBottom(draft.fields);
        }
      });

      // Optimistically show/hide/required/optional the field
      setActivity(nextActivity);

      const f = findFieldInActivity(nextActivity, id);
      if (f) {
        const { isHidden, isRequired } = f;
        const { id: activityObjectId } = nextActivity;
        const payload = {
          id,
          activityObjectId,
          isHidden,
          isRequired,
        };

        await ActivityService.v2UpdateField(payload);
        if (updateFields) {
          await ActivityService.updateObjectStyles(nextActivity);

          updateVisibilityRulesAfterDeleteOrDeactivate(id, false, nextActivity);
        }
      }
    } else if (action === MENU_ACTION_EDIT_OPTIONALS) {
      if (field.fieldType === ACTIVITY_CUSTOM_FIELD) {
        // custom field
        handleEditCustomField(field);
      } else {
        // activity field
        handleFieldEditOptions(field);
      }
    } else if (action === MENU_ACTION_ADD_RULE) {
      history.push(`/activities/${activity.id}/rules`, {
        addRuleField: camelToSnakeCaseKeys(field),
      });
    }
  };

  const handleChangeFieldDescription = async (
    displayName,
    { id, displayName: prev }
  ) => {
    if (displayName === prev) {
      return;
    }

    if (displayName === '') {
      // throw an error so the stagedDescription is reset
      throw new Error(`Field name can't be blank`);
    }

    const { id: activityObjectId } = activity;
    const payload = {
      activityObjectId,
      id,
      displayName,
    };

    const nextActivity = produce(activity, (draft) => {
      const f = findFieldInActivity(draft, id);
      if (f) {
        f.displayName = displayName;
      }
    });
    setActivity(nextActivity);

    await ActivityService.v2UpdateField(payload);
  };

  const handleNewCustomFieldSave = async (field) => {
    // todo back out adding fieldType to meta once api returns model_field
    const { id: activityObjectId } = activity;
    try {
      let payload;
      const { orgField } = field;
      if (!orgField) {
        const {
          id: customObjectField,
          isRequired,
          isDefault,
          displayName,
          meta,
        } = field;
        payload = {
          activityObjectId,
          fieldType: ACTIVITY_CUSTOM_FIELD,
          isDefault,
          displayName,
          customObjectField,
          isRequired,
          meta: { ...meta, cols: 1 },
        };
        payload = await ActivityService.v2CreateField(payload);
      } else {
        const { isRequired, displayName, meta } = field;
        const { id } = orgField;
        payload = {
          activityObjectId,
          id,
          displayName,
          isRequired,
          meta,
        };
        payload = await ActivityService.v2UpdateField(payload);
      }
      updateFieldStore(payload);
      return true;
    } catch (error) {
      const orig = getOriginalError(error);
      if (orig?.message) {
        showToast({
          variant: toastVariant.FAILURE,
          message: orig.message,
        });
        setShowCustomFieldModal(true);
      }
      return false;
    }
  };

  const ruleIndexesForField = useMemo(() => {
    const init = { hidden: {}, conditions: {} };

    if (!activity.visibilityRules) {
      return init;
    }

    return activity.visibilityRules.reduce((acc, vr, idx) => {
      for (const field of vr.fields) {
        if (acc.hidden[field.id] === undefined) {
          acc.hidden[field.id] = idx;
        }
      }
      for (const cond of vr.rule.filters) {
        if (acc.conditions[cond.activityFieldId] === undefined) {
          acc.conditions[cond.activityFieldId] = idx;
        }
      }
      return acc;
    }, init);
  }, [activity.visibilityRules]);

  let x = 0;
  let endOfLine = true;
  return (
    <>
      <BigCard data-qa="activity-builder-modal">
        <ActivitiesTitle as={TitleWrapper}>
          <KizenTypography as="h3" type="subheader">
            {t('Activity Builder')}
          </KizenTypography>
          <RightHeader>
            <Button
              className="pb-[10px]"
              color="secondary"
              onClick={handleFieldExport}
              variant="text"
            >
              {t('Export Field Metadata')}
            </Button>
          </RightHeader>
        </ActivitiesTitle>

        <BuilderActivity
          onMouseMove={(ev) => {
            if (fieldDragging) {
              setFieldDropzone(getFieldDropzone(fieldDragging, activity, ev));
            }
          }}
        >
          <FieldLayout>
            <>
              {dropzoneIs(fieldDropzone, {
                sectionId: activity.id,
                position: 'first',
              }) && <HorizontalDropzone margin={fieldMargin} />}
            </>
            {(activity.fields || []).map((field, i, arr) => {
              const f = { ...field };
              if (f && isOwnerField(f)) {
                f.isRequired = true;
              }
              const wasEndOfLine = endOfLine;
              const cols = getItemCols(f);
              const nextField = arr[i + 1];
              const nextCols = nextField ? getItemCols(nextField) : 0;
              let menuOptions;
              // notes fields is a special case as it can't be deleted or edited
              if (isDefaultUndeletableNotesField(f)) {
                menuOptions = [
                  ...menuBuildOptions.visibility(f, t, {
                    showLabel: t('Activate'),
                    hideLabel: t('Deactivate'),
                  }),
                ];
              } else {
                menuOptions = [
                  ...menuBuildOptions.edit({ ...f, isDefault: false }, t),
                  ...menuBuildOptions.visibility(f, t, {
                    showLabel: t('Activate'),
                    hideLabel: t('Deactivate'),
                  }),
                  ...menuBuildOptions.required(f, t),
                  ...menuBuildOptions.addRule(f, t),
                  ...menuBuildOptions.delete({ ...f, isDefault: false }, t),
                ];
              }

              endOfLine = x + cols >= 2 || x + cols + nextCols > 2;
              x = endOfLine ? 0 : 1;
              return (
                <Fragment key={f.id}>
                  {wasEndOfLine &&
                    dropzoneIs(fieldDropzone, {
                      id: f.id,
                      position: 'before',
                      direction: 'horizontal',
                    }) && <HorizontalDropzone margin={fieldMargin} />}
                  <ItemWrapper
                    data-qa="builder-field"
                    key={f.id}
                    className="FieldItemWrapper"
                    cols={2}
                    width={cols}
                    dragging={fieldDragging && fieldDragging.id === f.id}
                  >
                    {dropzoneIs(fieldDropzone, {
                      id: f.id,
                      position: 'before',
                      direction: 'vertical',
                    }) && <VerticalDropzone margin={fieldMargin} />}
                    <FieldPlaceholder />
                    <Draggable
                      disabled={f.isHidden || !activity.id}
                      onStart={() => flushSync(() => setFieldDragging(f))}
                      onStop={() => {
                        flushSync(() => {
                          applyFieldDropzone();
                          setFieldDragging(null);
                          setFieldDropzone(null);
                        });
                      }}
                      position={fieldDragging ? null : { x: 0, y: 0 }}
                      handle=".FieldHandle"
                    >
                      <BuilderField
                        field={f}
                        handleProps={{ className: 'FieldHandle' }}
                        dragging={fieldDragging && fieldDragging.id === f.id}
                        onChangeDescription={handleChangeFieldDescription}
                        onConfirmDelete={handleFieldDelete}
                        onClickChangeColumnWidth={handleFieldResize}
                        onCloseOptions={() => {}}
                        menuOptions={menuOptions}
                        isDisabled={
                          isDefaultUndeletableNotesField(f) || !activity.id
                        }
                        onSelectAction={handleFieldSelectAction}
                        deleteMessage={
                          f.fieldType === ACTIVITY_CUSTOM_FIELD
                            ? DELETE_CUSTOM_MESSAGE
                            : DELETE_ACTIVITY_MESSAGE
                        }
                        disableChangeColumnWidth={isDefaultUndeletableNotesField(
                          f
                        )}
                        confirmDeleteMessage={t(
                          'This will permanently delete this field and all historical submission data from this activity.'
                        )}
                        canDeleteFields
                        isHiddenByRule={
                          ruleIndexesForField.hidden[f.id] !== undefined
                        }
                        isRuleCondition={
                          ruleIndexesForField.conditions[f.id] !== undefined
                        }
                        onHiddenByRuleClick={() =>
                          history.push(`/activities/${activity.id}/rules`, {
                            scrollCardIndex: ruleIndexesForField.hidden[f.id],
                          })
                        }
                        onRuleConditionClick={() =>
                          history.push(`/activities/${activity.id}/rules`, {
                            scrollCardIndex:
                              ruleIndexesForField.conditions[f.id],
                          })
                        }
                      />
                    </Draggable>
                    {dropzoneIs(fieldDropzone, {
                      id: f.id,
                      position: 'after',
                      direction: 'vertical',
                    }) && <VerticalDropzone margin={fieldMargin} />}
                  </ItemWrapper>
                  {(endOfLine || i === arr.length - 1) &&
                    dropzoneIs(fieldDropzone, {
                      id: f.id,
                      position: 'after',
                      direction: 'horizontal',
                    }) && <HorizontalDropzone margin={fieldMargin} />}
                </Fragment>
              );
            })}
          </FieldLayout>
        </BuilderActivity>

        <ActivitiesFooter>
          <NewField
            data-qa="add-new-custom-field-button"
            onClick={handleNewCustomField}
            label={t('+ add new custom field')}
            disabled={!activity.id}
          />

          <NewField
            data-qa="add-new-activity-field-button"
            onClick={handleNewActivityField}
            label={t('+ add new activity field')}
            disabled={!activity.id}
          />
        </ActivitiesFooter>

        <ActivityFieldWizard
          context={wizardData}
          {...activityFieldWizardModalProps}
        />

        {showCustomFieldModal && (
          <CustomFieldChooserModal
            show
            context={wizardData}
            onHide={() => setShowCustomFieldModal(false)}
            onSave={(field) => handleNewCustomFieldSave(field)}
            {...customFieldChooserProps}
          />
        )}

        {showPreview && (
          <ActivityBuilderPreview
            activity={activity}
            onHide={() => {
              setShowPreview(false);
            }}
          />
        )}
      </BigCard>
    </>
  );
}

export default ActivitiesBuilder;
