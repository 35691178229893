import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  format,
  addDays,
  isBefore,
  isAfter,
  isSameDay,
  isSameMonth,
  addMonths,
  startOfISOWeek,
  endOfISOWeek,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  compareAsc,
} from 'date-fns';
import IconButtonWithBadge from '../IconButtonWithBadge';
import IntlProvider, { defaultLocale } from './IntlProvider';
import DatePicker from './DatePicker';
import {
  defaultProps,
  prefix,
  withPickerMethods,
  setTimingMargin,
  TYPE,
} from './utils';
import { getToggleWrapperClassName } from './Picker';
import QuickSelection from './QuickSelection';
import range from './range';
import './style.css';
import './stylemods.css';

class DateRangePicker extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;

    if (typeof value === 'undefined') {
      return null;
    }

    if (
      (value[0] && !isSameDay(value[0], prevState.value[0])) ||
      (value[1] && !isSameDay(value[1], prevState.value[1]))
    ) {
      return {
        value,
        selectValue: value,
        calendarDate: this.getCalendarDate(value),
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { defaultValue, value, defaultCalendarValue, defaultRange, ranges } =
      props;
    const defaultRangeIndex = ranges.findIndex((element) => {
      return element.default;
    });
    const defaultRangeValue =
      defaultRange ||
      (defaultRangeIndex !== -1 ? ranges[defaultRangeIndex].value : null);
    const activeValue = value || defaultValue || defaultRangeValue || [];
    const calendarDate = this.getCalendarDate(value || defaultCalendarValue);

    this.state = {
      value: activeValue,
      selectValue: activeValue,
      doneSelected: true,
      calendarDate,
      hoverValue: [],
      currentHoverDate: null,
      selectedIndex: defaultRangeIndex !== -1 ? defaultRangeIndex : null,
      tempSelectedIndex: null,
      showCalendar: false,
    };

    this.menuContainerRef = React.createRef();
    this.wrapper = React.createRef();
    this.toggleCalendarButton = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getCalendarDate = (value = [], nextSelectedIndex = null) => {
    if (value[0] && value[1]) {
      if (this.isAllTime(nextSelectedIndex)) {
        return [value[1], addMonths(value[1], 1)];
      }
      const sameMonth = isSameMonth(value[0], value[1]);
      return [value[0], sameMonth ? addMonths(value[1], 1) : value[1]];
    }
    return [new Date(), addMonths(new Date(), 1)];
  };

  getValue = () => {
    const { value } = this.props;
    const { value: stateValue } = this.state;

    if (typeof value !== 'undefined') {
      return value;
    }

    return stateValue || [];
  };

  getDateString() {
    const { format: formatType, ranges, t } = this.props;
    const nextValue = this.getValue();
    const { selectedIndex } = this.state;
    const startDate = nextValue && nextValue[0];
    const endDate = nextValue && nextValue[1];

    if (startDate && endDate) {
      const displayValue = [startDate, endDate].sort(compareAsc);

      let matchingRange = null;
      if (selectedIndex) {
        matchingRange = ranges[selectedIndex];
      } else {
        matchingRange = ranges.find((element) => {
          return (
            isSameDay(element.value[0], displayValue[0]) &&
            isSameDay(element.value[1], displayValue[1])
          );
        });
      }

      if (matchingRange && matchingRange.customPlaceholderLabel) {
        return matchingRange.localizedPlaceholderLabel(t);
      }

      return `${format(displayValue[0], formatType)} - ${format(
        displayValue[1],
        formatType
      )}`;
    }

    return '';
  }

  // hover range presets
  getWeekHoverRange = (date) => {
    const { isoWeek } = this.props;

    if (isoWeek) {
      // set to the first day of this week according to ISO 8601, 12:00 am
      return [startOfISOWeek(date), endOfISOWeek(date)];
    }

    return [startOfWeek(date), endOfWeek(date)];
  };

  getMonthHoverRange = (date) => [startOfMonth(date), endOfMonth(date)];

  getHoverRange(date) {
    const { hoverRange } = this.props;
    if (!hoverRange) {
      return [];
    }

    let hoverRangeFunc = hoverRange;
    if (hoverRange === 'week') {
      hoverRangeFunc = this.getWeekHoverRange;
    }

    if (hoverRangeFunc === 'month') {
      hoverRangeFunc = this.getMonthHoverRange;
    }

    if (typeof hoverRangeFunc !== 'function') {
      return [];
    }

    const hoverValues = hoverRangeFunc(date);
    const isHoverRangeValid =
      hoverValues instanceof Array && hoverValues.length === 2;
    if (!isHoverRangeValid) {
      return [];
    }
    if (isAfter(hoverValues[0], hoverValues[1])) {
      hoverValues.reverse();
    }
    return hoverValues;
  }

  handleClickOutside = (event) => {
    if (
      this.toggleCalendarButton.current &&
      this.toggleCalendarButton.current.contains(event.target)
    ) {
      this.toggleCalendar();
    } else if (this.menuContainerRef.current) {
      if (
        event.target &&
        !this.menuContainerRef.current.contains(event.target)
      ) {
        this.toggleCalendar();
      }
    }
  };

  isAllTime = (nextSelectedIndex = null) => {
    const { selectedIndex } = this.state;
    const { ranges } = this.props;

    const allTimeRangeIndex = ranges.findIndex((element) => {
      return element.isAllTime;
    });

    return nextSelectedIndex !== null
      ? nextSelectedIndex === allTimeRangeIndex
      : selectedIndex === allTimeRangeIndex;
  };

  handleChangeCalendarDate = (index, date) => {
    const { calendarDate } = this.state;
    calendarDate[index] = date;

    this.setState({ calendarDate });
  };

  handleClear = (value, closeOverlay, event) => {
    this.updateValue(event, value, closeOverlay, true);
  };

  handleQuickSelection = (value, selectedIndex) => {
    const { onOk } = this.props;
    this.setState({
      selectValue: value,
      value,
      selectedIndex,
      tempSelectedIndex: null,
    });

    this.toggleCalendar();
    // eslint-disable-next-line no-restricted-globals
    if (typeof onOk === 'function') onOk(value, event);
  };

  handleOK = (event) => {
    const { onOk } = this.props;
    const { selectValue } = this.state;
    this.updateValue(event);
    this.toggleCalendar();
    if (typeof onOk === 'function') onOk(selectValue, event);
  };

  handleChangeSelectValue = (date, event) => {
    // do some checking somewhere in here if it matches with a quick select option?
    const { selectValue, doneSelected, selectedIndex } = this.state;
    const { onSelect, oneTap, ranges } = this.props;
    let nextValue = [];
    let nextHoverValue = this.getHoverRange(date);

    if (doneSelected) {
      if (nextHoverValue.length) {
        nextValue = [nextHoverValue[0], nextHoverValue[1], date];
        nextHoverValue = [nextHoverValue[0], nextHoverValue[1], date];
      } else {
        nextValue = [date, undefined, date];
      }
    } else {
      if (nextHoverValue.length) {
        nextValue = [selectValue[0], selectValue[1]];
      } else {
        nextValue = [selectValue[0], date];
      }

      if (isAfter(nextValue[0], nextValue[1])) {
        nextValue.reverse();
      }

      nextValue[0] = setTimingMargin(nextValue[0]);
      nextValue[1] = setTimingMargin(nextValue[1], 'right');

      // Find if the selected values are in our ranges
      const rangeExistsIndex = ranges.findIndex((element) => {
        const dates = element.value;
        return (
          isSameDay(dates[0], nextValue[0]) && isSameDay(dates[1], nextValue[1])
        );
      });

      this.setState({
        selectedIndex,
        tempSelectedIndex: rangeExistsIndex,
        calendarDate: this.getCalendarDate(nextValue, rangeExistsIndex),
      });
    }

    const nextState = {
      doneSelected: !doneSelected,
      selectValue: nextValue,
      hoverValue: nextHoverValue,
    };

    event.persist();

    this.setState(nextState, () => {
      const { doneSelected: doneSelectedV2 } = this.state;
      // 如果是单击模式，并且是第一次点选，再触发一次点击
      if (oneTap && !doneSelectedV2) {
        this.handleChangeSelectValue(date, event);
      }
      // 如果是单击模式，并且是第二次点选，更新值，并关闭面板
      if (oneTap && doneSelectedV2) {
        this.updateValue(event);
      }

      if (typeof onSelect === 'function') onSelect(date, event);
    });
  };

  handleMouseMoveSelectValue = (date) => {
    const { doneSelected, selectValue, hoverValue, currentHoverDate } =
      this.state;
    const { hoverRange } = this.props;

    if (currentHoverDate && isSameDay(date, currentHoverDate)) {
      return;
    }

    const nextHoverValue = this.getHoverRange(date);

    if (doneSelected && !_.isUndefined(hoverRange)) {
      this.setState({
        currentHoverDate: date,
        hoverValue: nextHoverValue,
      });
      return;
    } else if (doneSelected) {
      return;
    }

    let nextValue = selectValue;

    if (!nextHoverValue.length) {
      nextValue[1] = date;
    } else if (hoverValue) {
      nextValue = [
        isBefore(nextHoverValue[0], hoverValue[0])
          ? nextHoverValue[0]
          : hoverValue[0],
        isAfter(nextHoverValue[1], hoverValue[1])
          ? nextHoverValue[1]
          : hoverValue[1],
        nextValue[2],
      ];
    }

    // If `nextValue[0]` is greater than `nextValue[1]` then reverse order
    if (isAfter(nextValue[0], nextValue[1])) {
      nextValue.reverse();
    }

    this.setState({
      currentHoverDate: date,
      selectValue: nextValue,
    });
  };

  disabledOkButton = () => {
    const { selectValue, doneSelected } = this.state;

    if (!selectValue[0] || !selectValue[1] || !doneSelected) {
      return true;
    }

    return this.disabledByBetween(
      selectValue[0],
      selectValue[1],
      TYPE.TOOLBAR_BUTTON_OK
    );
  };

  disabledShortcutButton = (value = []) => {
    if (!value[0] || !value[1]) {
      return true;
    }

    return this.disabledByBetween(value[0], value[1], TYPE.TOOLBAR_SHORTCUT);
  };

  handleDisabledDate = (date, values, type) => {
    const { disabledDate } = this.props;
    const { doneSelected } = this.state;
    if (disabledDate) {
      return disabledDate(date, values, doneSelected, type);
    }
    return false;
  };

  addPrefix = (name) => {
    const { classPrefix } = this.props;
    return prefix(classPrefix)(name);
  };

  toggleCalendar = () => {
    this.setState((ps) => ({ ...ps, showCalendar: !ps.showCalendar }));
  };

  updateValue(event, nextSelectValue) {
    const { value, selectValue } = this.state;
    const { onChange, ranges } = this.props;

    let nextValue = !_.isUndefined(nextSelectValue)
      ? nextSelectValue
      : selectValue;

    // Odd case where you can only select 1 value and right click out of selection to hit apply
    if (nextValue && nextValue[1] === undefined) {
      // eslint-disable-next-line prefer-destructuring
      nextValue[1] = nextValue[0];
    }

    // Remove any extra dates caused by not selecting
    if (nextValue.length > 2) {
      nextValue = nextValue.slice(0, 2);
    }

    if (isAfter(nextValue[0], nextValue[1])) {
      nextValue.reverse();
    }

    setTimingMargin(nextValue[1], 'right');

    // Find if the selected values are in our ranges
    const rangeExistsIndex = ranges.findIndex((element) => {
      const dates = element.value;
      return (
        isSameDay(dates[0], nextValue[0]) && isSameDay(dates[1], nextValue[1])
      );
    });

    this.setState({
      selectValue: nextValue || [],
      value: nextValue,
      selectedIndex: rangeExistsIndex !== -1 ? rangeExistsIndex : null,
      tempSelectedIndex: null,
    });

    if (
      onChange &&
      (!isSameDay(nextValue[0], value[0]) || !isSameDay(nextValue[1], value[1]))
    ) {
      onChange(nextValue, event);
    }
  }

  disabledByBetween(start, end, type) {
    const { disabledDate } = this.props;
    const { selectValue, doneSelected } = this.state;
    const selectStartDate = selectValue[0];
    const selectEndDate = selectValue[1];
    const nextSelectValue = [selectStartDate, selectEndDate];

    // If the date is between the start and the end
    // the button is disabled
    while (isBefore(start, end) || isSameDay(start, end)) {
      if (
        disabledDate &&
        disabledDate(start, nextSelectValue, doneSelected, type)
      ) {
        return true;
      }
      start = addDays(start, 1);
    }

    return false;
  }

  renderDropdownMenu() {
    const {
      menuClassName,
      ranges,
      isoWeek,
      limitEndYear,
      showWeekNumbers,
      placement,
      t,
    } = this.props;
    const {
      calendarDate,
      selectValue,
      hoverValue,
      doneSelected,
      selectedIndex,
      tempSelectedIndex,
    } = this.state;
    const classes = classNames(
      'rs-picker-menu',
      this.addPrefix('daterange-menu'),
      menuClassName
    );

    const pickerProps = {
      isoWeek,
      doneSelected,
      hoverValue,
      calendarDate,
      limitEndYear,
      showWeekNumbers,
      value: selectValue,
      disabledDate: this.handleDisabledDate,
      onSelect: this.handleChangeSelectValue,
      onMouseMove: this.handleMouseMoveSelectValue,
      onChangeCalendarDate: this.handleChangeCalendarDate,
    };

    return ReactDOM.createPortal(
      <div
        className={classes}
        style={{ top: placement.top, left: placement.left }}
        ref={this.menuContainerRef}
      >
        <div className={this.addPrefix('daterange-panel')}>
          <div
            className={classNames(
              this.addPrefix('daterange-content'),
              'kizen-daterange-content'
            )}
          >
            <div className="kizen-quick-selection-container">
              <div className="kizen-quick-selection-header">
                {t('Quick Selection')}
              </div>
              <QuickSelection
                ranges={ranges}
                selectValue={selectValue}
                disabledShortcutButton={this.disabledShortcutButton}
                onShortcut={this.handleQuickSelection}
                selectedIndex={selectedIndex}
                tempSelectedIndex={tempSelectedIndex}
              />
            </div>
            <div className={this.addPrefix('daterange-calendar-group')}>
              <DatePicker index={0} {...pickerProps} />
              <DatePicker index={1} {...pickerProps} />
            </div>
          </div>
          <div className="kizen-daterangepicker-toolbar">
            <button
              className="applyButton"
              type="button"
              onClick={this.handleOK}
            >
              {t('Apply')}
            </button>
          </div>
        </div>
      </div>,
      this.wrapper.current
    );
  }

  render() {
    const { locale, style } = this.props;
    const { showCalendar } = this.state;
    const value = this.getValue();
    const hasValue = value && value.length > 1;
    const classes = getToggleWrapperClassName(
      'daterange',
      this.addPrefix,
      this.props,
      hasValue
    );

    return (
      <IntlProvider locale={locale}>
        <div className={classes} style={style} ref={this.wrapper}>
          <IconButtonWithBadge
            icon="calendar"
            badge={this.getDateString()}
            ref={this.toggleCalendarButton}
            badgeMaxWidth
          />
          {showCalendar && this.renderDropdownMenu()}
        </div>
      </IntlProvider>
    );
  }
}

DateRangePicker.propTypes = {
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  defaultValue: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  defaultCalendarValue: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  defaultRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  format: PropTypes.string,
  disabled: PropTypes.bool,
  locale: PropTypes.object,
  hoverRange: PropTypes.oneOfType([
    PropTypes.oneOf(['week', 'month']),
    PropTypes.func,
  ]),
  isoWeek: PropTypes.bool,
  oneTap: PropTypes.bool,
  limitEndYear: PropTypes.number,
  className: PropTypes.string,
  menuClassName: PropTypes.string,
  classPrefix: PropTypes.string,
  container: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  containerPadding: PropTypes.number,
  block: PropTypes.bool,
  style: PropTypes.object,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  preventOverflow: PropTypes.bool,
  showWeekNumbers: PropTypes.bool,
  onChange: PropTypes.func,
  onOk: PropTypes.func,
  disabledDate: PropTypes.func,
  onSelect: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onHide: PropTypes.func,
  onEntering: PropTypes.func,
  onExiting: PropTypes.func,
  ranges: PropTypes.arrayOf(PropTypes.object),
  placement: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

DateRangePicker.defaultProps = {
  limitEndYear: 1000,
  format: 'MM/DD/YY',
  locale: defaultLocale,
  ranges: [
    range.today,
    range.yesterday,
    range.thisMonth,
    range.last7Days,
    range.last30Days,
    range.thisYear,
    range.allTime,
  ],
  placement: {
    top: '100%',
    left: 0,
  },
};

const comp2 = (fn1, fn2) => {
  return (...args) => fn1(fn2(...args));
};

const enhance = comp2(
  defaultProps({
    classPrefix: 'picker',
  }),
  withPickerMethods()
);

export default withTranslation()(enhance(DateRangePicker));
