import { Spacing } from '../spacing';

export const getPaddingClass = (padding: Spacing) => {
  switch (padding) {
    case 0:
      return '';
    case 15:
      return 'p-spacer-15';
    case 20:
      return 'p-spacer-20';
    case 30:
      return 'p-spacer-30';
  }
};
