import { useCallback, useState, useReducer, useEffect } from 'react';
import { builderReducer, initial } from './reducers/builderReducer';
import BUILDER_ACTIONS from './actions/builderActions';
import { useTranslation } from 'react-i18next';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import useFormValidation from 'hooks/useFormValidation';
import { isEqual } from 'lodash';
import { CUSTOM_LAYOUT_TABS } from './utilities';

const castPercentChanceToClose = (stages) => {
  return stages?.map((stage) => {
    return {
      ...stage,
      percentageChanceToClose: String(stage.percentageChanceToClose),
    };
  });
};

export function useCustomObjectConfiguration(
  model = EMPTY_OBJECT,
  fullListOfCustomObjects
) {
  const [isDirty, setDirty] = useState(false);
  const [customLayoutSelectedTab, setCustomLayoutSelectedTab] = useState(
    CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT
  );
  const [formData, dispatchFormData] = useReducer(
    builderReducer,
    [model, fullListOfCustomObjects],
    initial
  );

  useEffect(() => {
    dispatchFormData({
      type: BUILDER_ACTIONS.reset,
      payload: [model, fullListOfCustomObjects],
    });
  }, [fullListOfCustomObjects, model]);

  const { t } = useTranslation();

  const updateField = useCallback(
    (field, value, shouldIgnoreDirty) => {
      if (!shouldIgnoreDirty) {
        setDirty(true);
      }

      dispatchFormData({
        type: BUILDER_ACTIONS.update,
        payload: { field, value },
      });
    },
    [dispatchFormData]
  );

  const {
    fieldsRef,
    validateFormState,
    validationProps,
    handleInputChange: _handleInputChange,
    setValidationState,
    firstFieldWithErrorRef,
    resetValidationState,
  } = useFormValidation({
    setFormDirty: setDirty,
    formState: { ...formData },
    setFormState: (model) => {
      dispatchFormData({
        type: BUILDER_ACTIONS.updateModel,
        payload: model,
      });
    },
  });

  const handleInputChange = useCallback(
    (type, payload, ...args) => {
      // When the user changes the pipeline stages, we need to update the form data
      // only if the stages are different from the ones in the form data
      // otherwise we get stuck in the dirty state.
      if (
        !isEqual(
          castPercentChanceToClose(payload?.stages),
          castPercentChanceToClose(formData?.pipeline?.stages)
        ) ||
        type !== 'pipeline'
      ) {
        _handleInputChange(type, payload, ...args);
      }
    },
    [_handleInputChange, formData]
  );

  const resetField = useCallback(() => {
    setDirty(false);
    dispatchFormData({
      type: BUILDER_ACTIONS.reset,
      payload: [model, fullListOfCustomObjects],
    });
  }, [model, fullListOfCustomObjects]);

  const validate = useCallback(
    ({ validateCallback }) => {
      return validateCallback?.(formData, t);
    },
    [formData, t]
  );

  const resetDirty = useCallback(() => setDirty(false), []);

  return {
    formData,
    updateField,
    resetField,
    validate,
    isDirty,
    model,
    resetDirty,
    setDirty,
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    validationFormProps: {
      fieldsRef,
      validateFormState,
      validationProps,
      handleInputChange,
      setValidationState,
      firstFieldWithErrorRef,
      resetValidationState,
    },
  };
}
