import React, { useState } from 'react';
import styled from '@emotion/styled';
import MainSelector from 'components/Kizen/MainSelector';
import MOCK_TEAM_MEMBERS from 'services/MOCK_TEAM_MEMBERS';
import { gutters } from 'app/spacing';

const StyledSelect = styled(MainSelector)`
  margin-bottom: ${gutters.standard};
`;

const SingleSelect = () => {
  const [valueA, setValueA] = useState(MOCK_TEAM_MEMBERS[0]);
  const [valueB, setValueB] = useState(MOCK_TEAM_MEMBERS[0]);
  const [valueC, setValueC] = useState(MOCK_TEAM_MEMBERS[0]);

  return (
    <>
      <StyledSelect
        fullWidth
        label="Standard"
        options={MOCK_TEAM_MEMBERS}
        value={valueA}
        onChange={setValueA}
      />
      <StyledSelect
        fullWidth
        label="Disabled"
        options={MOCK_TEAM_MEMBERS}
        value={valueB}
        onChange={setValueB}
        disabled
      />
      <StyledSelect
        fullWidth
        label="Error"
        options={MOCK_TEAM_MEMBERS}
        value={valueC}
        onChange={setValueC}
        error
      />
    </>
  );
};

export default SingleSelect;
