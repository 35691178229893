import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ErrorBoundary from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { getCustomObjectConfigFetching } from 'store/recordDetailPage/record.redux';
import { TimelineErrorFallback, useTimeLine } from 'components/Timeline2';
import { useSelector } from 'react-redux';
import { getChosenBusiness } from 'store/authentication/selectors';
import useTimelineInteraction from 'components/Timeline2/useTimelineInteraction';
import useTimelineQuery from 'components/Timeline2/useTimelineQuery';
import useExpandingVerticalScrollbar from 'hooks/useExpandingVericalScrollbar';
import useTimelineComment from 'components/Timeline2/useTimelineComment';
import { TimelineProvider } from 'components/Timeline2/TimelineContext';
import { LoadPrevButton } from 'components/Timeline2/styles';
import {
  FixedHeightLoadingWrapper,
  LoadPrevWrapper,
  ScrollWrapper,
  StyledLoader,
} from 'components/Timeline2/Constrained/styles';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { grayScale } from 'app/colors';
import { renderableEvents } from 'components/Timeline2/constants';
import { timeLineConfig } from 'components/Timeline2/EventBlocks';
import UnknownTypeBlock from 'components/Timeline2/EventBlocks/View/UnknownType';
import { KizenTypography } from '__app/typography';
import { ActionBar } from '@kizen/kds/ActionBar';
import { ActionGroup } from '@kizen/kds/ActionGroup';
import { SearchPill } from '@kizen/kds/SearchPill';
import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';
import { Panel, VerticalTile } from '@kizen/kds/Tile';
import {
  ObjectMultiSelect,
  useObjectMultiSelect,
} from './components/ObjectMultiSelect';
import { TypeMultiSelect } from './components/TypeMultiSelect';
import { FieldUpdated } from './components/FieldUpdated';
import { TeamMultiSelect } from './components/TeamMultiSelect';
import { range } from 'components/Kizen/DateRangePicker';
import { DateRangePicker } from './components/DateRangePicker';
import { KDSLoadMoreWrapper, ScrollStopMarker } from './components/styles';
import { withErrorBoundary } from 'ts-components/RecordLayout/blocks/ErrorBoundary';
import { RoleMultiSelect } from './components/RoleMultiSelect';
import { CommentSelect } from './components/CommentSelect';
import { TIMELINE } from 'queries/query-keys';

const BUFFER = 20;
const THRESHOLD = 0;

const RANGE_PICKER_RANGES = [
  range.today,
  range.yesterday,
  {
    ...range.thisMonth,
    default: false,
  },
  range.last30Days,
  {
    ...range.last180Days,
    default: true,
  },
  range.thisYear,
  range.allTime,
];

// Traverses up through the parents of the expanding scrollbar
// component in order to access the element that is scrollable
const getScrollParentForExpandingScrollbar = (parent) => {
  return parent?.parentElement?.parentElement?.parentElement;
};

const Block = ({
  customObjectId,
  isCustomObjectPage,
  id,
  clientObject,
  displayName,
  mobileOverride,
  isClient,
  blockId,
  metadata,
  eventId,
  created,
  isLoading,
  queryKeyAddition,
  resetOnQueryChange = false,
}) => {
  const { t } = useTranslation();
  const { methods } = useTimeLine();
  const business = useSelector(getChosenBusiness);
  const isFetchingCustomObjectFilterConfig = useSelector(
    getCustomObjectConfigFetching
  );
  const isFetchingClientFilterConfig = useSelector(
    (s) => s.contactPage.isFetching
  );

  const isFetchingFilterConfig = isClient
    ? isFetchingClientFilterConfig
    : isFetchingCustomObjectFilterConfig;

  const aggregateObjectId = customObjectId || clientObject?.id;

  const {
    filters,
    filtersForApi, // maybe different based on restricted event types and include related settings
    showRelatedObjectsFilter,
    showEventsFilter,
    showFieldsFilter,
    searchRef,
    searchTerm,
    event_id,
    filterByDateHandler,
    setFilters,
    setObjectsFilter,
    setEventsFilter,
    setExcludeEventsFilter,
    resetAllFilters,
    setSearchTerm,
  } = useTimelineInteraction(
    aggregateObjectId,
    metadata,
    blockId,
    eventId,
    created
  );

  const [blockScroll, setBlockScroll] = useState(Boolean(event_id));
  const [, setIsScrolled] = useState(false);

  const includeRelatedObjects = metadata?.includeRelated ?? true;

  const {
    relatedObjectsOptions,
    relatedObjectsDictionary,
    loadingRelatedObjectsOptions,
    isMoreThanOneRelatedOption,
  } = useObjectMultiSelect(includeRelatedObjects, isCustomObjectPage);

  const timelineQueryKey = useMemo(() => {
    return [
      ...TIMELINE.RECORD(id),
      searchTerm,
      filtersForApi,
      searchRef.current,
      queryKeyAddition,
    ];
  }, [id, searchTerm, filtersForApi, queryKeyAddition, searchRef]);

  const infiniteQuery = useTimelineQuery({
    id,
    filters: filtersForApi,
    params: searchRef.current,
    searchTerm,
    enabled: !isLoading && !isFetchingFilterConfig,
    keepPreviousData: !resetOnQueryChange,
    queryKey: timelineQueryKey,
  });

  const data = useMemo(() => {
    return (
      infiniteQuery.data?.pages?.reduce((acc, page) => {
        return [...acc, ...page.items];
      }, []) ?? []
    );
  }, [infiniteQuery.data]);

  const { containerProps } = useExpandingVerticalScrollbar();

  const scrollAreaRef = useRef(null);
  const scrollParentRef = useRef(null);
  const topStopRef = useRef(null);
  const bottomStopRef = useRef(null);
  const previousBottomRef = useRef(0);

  const onScrollTop = useCallback(
    async (e) => {
      if (
        e[0]?.isIntersecting &&
        infiniteQuery.hasPreviousPage &&
        !infiniteQuery.isLoading &&
        !infiniteQuery.isFetchingPreviousPage &&
        !blockScroll
      ) {
        const parent = getScrollParentForExpandingScrollbar(
          scrollParentRef.current
        );
        const bottom = parent.scrollHeight - parent.scrollTop;
        previousBottomRef.current = bottom;

        await infiniteQuery.fetchPreviousPage();

        const newTop = parent.scrollHeight - bottom;
        parent.scrollTop = newTop - BUFFER;
      }
    },
    [infiniteQuery, blockScroll]
  );

  const onScrollBottom = useCallback(
    (e) => {
      if (
        e[0]?.isIntersecting &&
        infiniteQuery.hasNextPage &&
        !infiniteQuery.isLoading &&
        !infiniteQuery.isFetchingNextPage
      ) {
        infiniteQuery.fetchNextPage();
      }
    },
    [infiniteQuery]
  );

  useEffect(() => {
    const topRef = topStopRef?.current;
    let topObserver;
    if (topRef) {
      topObserver = new IntersectionObserver(onScrollTop, {
        root: scrollAreaRef?.current,
        threshold: THRESHOLD,
      });

      topObserver.observe(topRef);
    }

    const bottomRef = bottomStopRef?.current;
    let bottomObserver;
    if (bottomRef) {
      bottomObserver = new IntersectionObserver(onScrollBottom, {
        root: scrollAreaRef?.current,
        threshold: THRESHOLD,
      });
      bottomObserver.observe(bottomRef);
    }
    return () => {
      if (topRef) {
        topObserver.unobserve(topRef);
      }
      if (bottomRef) {
        bottomObserver.unobserve(bottomRef);
      }
    };
  }, [onScrollTop, onScrollBottom]);

  const { handleComment } = useTimelineComment({
    onComment: methods.onComment,
    id,
    filters: filtersForApi,
    params: searchRef.current,
    searchTerm,
    queryKey: timelineQueryKey,
  });

  const objectsCount = filters?.objectsCount ?? 0;
  const teamMemberCount = filters?.teamMemberCount ?? 0;
  const rolesCount = filters?.rolesCount ?? 0;
  const typesCount = filters?.typesCount ?? 0;
  const excludeTypesCount = filters?.excludeTypesCount ?? 0;

  const menuElements = useMemo(() => {
    return [
      ...[
        showRelatedObjectsFilter && isMoreThanOneRelatedOption
          ? {
              order: 0,
              id: 'layer-filter',
              label: t('Objects'),
              icon: 'action-filter-objects',
              badge: objectsCount > 0,
              collapseToTray: true,
              qa: {
                action: 'filter-objects',
                'object-count': objectsCount,
              },
              children: ({ isShowing, setIsShowing, trigger }) => {
                return (
                  <ObjectMultiSelect
                    onChange={setObjectsFilter}
                    isCustomObjectPage={isCustomObjectPage}
                    position="relative"
                    isShowing={isShowing}
                    setIsShowing={setIsShowing}
                    trigger={trigger}
                    data-dropdown-body="timeline-filter-objects"
                    includeRelatedObjects={includeRelatedObjects}
                    blockId={blockId}
                    enableClearLocalState
                    relatedObjectsOptions={relatedObjectsOptions}
                    relatedObjectsDictionary={relatedObjectsDictionary}
                    loadingRelatedObjectsOptions={loadingRelatedObjectsOptions}
                    {...filters}
                  />
                );
              },
            }
          : null,
      ],
      ...[
        showEventsFilter && {
          order: 1,
          id: 'type-filter',
          label: t('Event Type'),
          icon: 'action-filter-event-type',
          collapseToTray: false,
          badge: typesCount > 0,
          qa: {
            action: 'filter-event-type',
          },
          children: ({ isShowing, setIsShowing, trigger }) => {
            return (
              <TypeMultiSelect
                onChange={setEventsFilter}
                position="relative"
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                trigger={trigger}
                metadata={metadata}
                {...filters}
              />
            );
          },
        },
      ],
      ...[
        showFieldsFilter && {
          order: 2,
          id: 'fields-filter',
          label: t('Fields'),
          icon: 'action-filter-fields',
          badge: excludeTypesCount > 0,
          collapseToTray: true,
          qa: {
            'fields-filter': excludeTypesCount <= 0,
          },
          children: ({ isShowing, setIsShowing, trigger }) => {
            return (
              <FieldUpdated
                onChange={setExcludeEventsFilter}
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                trigger={trigger}
                {...filters}
              />
            );
          },
        },
      ],
      {
        order: 3,
        id: 'comment-filter',
        label: t('Comments'),
        icon: 'action-filter-comments',
        badge: !!filters?.hasComment,
        collapseToTray: true,
        qa: {
          action: 'filter-comments',
        },
        children: ({ isShowing, setIsShowing, trigger }) => {
          return (
            <CommentSelect
              onChange={setFilters}
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              trigger={trigger}
              data-dropdown-body="timeline-filter-comments"
              {...filters}
            />
          );
        },
      },
      {
        order: 4,
        id: 'role-filter',
        label: t('Roles'),
        icon: 'action-filter-roles',
        badge: rolesCount > 0,
        qa: {
          action: 'filter-roles',
          'role-count': rolesCount,
        },
        collapseToTray: true,
        children: ({ isShowing, setIsShowing, trigger }) => {
          return (
            <RoleMultiSelect
              badge={rolesCount > 0}
              onChange={setFilters}
              position="relative"
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              trigger={trigger}
              data-dropdown-body="timeline-filter-roles"
              {...filters}
            />
          );
        },
      },
      {
        order: 5,
        id: 'team-filter',
        label: t('Team'),
        icon: 'action-filter-team',
        badge: teamMemberCount > 0,
        collapseToTray: false,
        children: ({ isShowing, setIsShowing, trigger }) => {
          return (
            <TeamMultiSelect
              onChange={setFilters}
              position="relative"
              isShowing={isShowing}
              setIsShowing={setIsShowing}
              trigger={trigger}
              {...filters}
            />
          );
        },
      },
      {
        order: 6,
        id: 'date-filter',
        label: t('Date'),
        icon: 'action-filter-date',
        badge: false,
        collapseToTray: false,
        trigger: ({ setIsShowing, safeDirection, collapsed }) => {
          return (
            <DateRangePicker
              title={t('Filter Date')}
              ranges={RANGE_PICKER_RANGES}
              onOk={filterByDateHandler}
              collapseBadge={collapsed}
              setIsShowing={setIsShowing}
              direction={safeDirection}
              popover
            />
          );
        },
      },
    ].filter(Boolean);
  }, [
    excludeTypesCount,
    filters,
    isCustomObjectPage,
    t,
    teamMemberCount,
    rolesCount,
    typesCount,
    filterByDateHandler,
    setExcludeEventsFilter,
    setEventsFilter,
    setFilters,
    setObjectsFilter,
    objectsCount,
    metadata,
    blockId,
    showRelatedObjectsFilter,
    showEventsFilter,
    showFieldsFilter,
    includeRelatedObjects,
    relatedObjectsOptions,
    relatedObjectsDictionary,
    loadingRelatedObjectsOptions,
    isMoreThanOneRelatedOption,
  ]);

  const collapsedFilterCount = useMemo(() => {
    return menuElements
      .filter((element) => element.collapseToTray)
      .reduce((acc, curr) => {
        return acc + (curr.badge ? 1 : 0);
      }, 0);
  }, [menuElements]);

  const head = isFetchingFilterConfig ? null : (
    <ActionBar
      leftChildren={
        <div className="flex gap-[12px]">
          <Typography
            variant="header"
            size="lg"
            weight="semibold"
            color="font-primary"
            className="max-w-[250px] min-w-0"
          >
            {displayName || t('Timeline')}
          </Typography>
          <SearchPill
            onChange={setSearchTerm}
            value={searchTerm}
            placeholder={t('Search')}
            boundaryClassName="kds-action-bar"
            loading={infiniteQuery.isLoading && searchTerm !== ''}
          />
        </div>
      }
      rightChildren={
        <ActionGroup
          mode="icon"
          elements={menuElements}
          trayIndicatorSettings={{
            badge: collapsedFilterCount > 0,
          }}
          onClearAll={resetAllFilters}
        />
      }
    />
  );

  const body =
    isFetchingFilterConfig || infiniteQuery.isLoading || isLoading ? (
      <FixedHeightLoadingWrapper configuredHeight={metadata?.blockHeight}>
        <StyledLoader loading />
      </FixedHeightLoadingWrapper>
    ) : (
      <ScrollWrapper
        noPadding
        kdsCompatability
        configuredHeight={metadata?.blockHeight}
        data-qa="timeline-scroll-wrapper"
        ref={scrollAreaRef}
      >
        <TableScrollContainer
          {...containerProps}
          onChangeScrolled={([scrolledToTop]) => setIsScrolled(!scrolledToTop)}
          bottom
          kdsCompatability
        >
          <div
            className="scroll-parent"
            ref={scrollParentRef}
            data-qa="timeline-container"
          >
            <span className="top-scroll-stop" ref={topStopRef} />
            {infiniteQuery.isFetchingPreviousPage && <StyledLoader loading />}
            {infiniteQuery.hasPreviousPage && blockScroll && (
              <LoadPrevWrapper>
                <LoadPrevButton
                  variant="text"
                  onClick={() => setBlockScroll(false)}
                  loading={infiniteQuery.isFetchingPreviousPage}
                  loadingColor={grayScale.medium}
                  color="blue"
                  data-qa="timeline-more-recent-events"
                >
                  {t('More Recent Events')}
                </LoadPrevButton>
              </LoadPrevWrapper>
            )}
            {data.map((event, index) => {
              const Block = renderableEvents.has(event.typeName)
                ? timeLineConfig[event.typeName].component
                : UnknownTypeBlock;

              return (
                <Block
                  key={event.id}
                  event={event}
                  business={business}
                  onComment={(comment) => handleComment(event, comment)}
                  onDelete={() => methods.onDelete(event)}
                  commentOpenDefault={event_id === event.id}
                  index={index}
                  mobileOverride={mobileOverride}
                />
              );
            })}
            <ScrollStopMarker
              className="bottom-scroll-stop"
              ref={bottomStopRef}
              threshold={80} // 80 is the shortest single timeline event we have
            />
            {infiniteQuery.isFetchingNextPage && <StyledLoader loading />}
            {!infiniteQuery.hasNextPage &&
            !infiniteQuery.isLoading &&
            data.length > 0 ? (
              <KDSLoadMoreWrapper className="LoadMore-wrapper">
                <Spacer mode="horizontal" size={15} />
                <KizenTypography>{t('NO MORE EVENTS.')}</KizenTypography>
              </KDSLoadMoreWrapper>
            ) : null}
            {!data.length && !infiniteQuery.isLoading && !isLoading ? (
              <KizenTypography>{t('No Timeline entries.')}</KizenTypography>
            ) : null}
          </div>
        </TableScrollContainer>
      </ScrollWrapper>
    );

  // Handle scrolling to the timeline if there is an event visible.
  const tileRef = useRef(null);
  const hasScrolled = useRef(false);
  if (
    event_id &&
    created &&
    !hasScrolled.current &&
    tileRef.current &&
    data?.length
  ) {
    hasScrolled.current = true;
    tileRef.current?.scrollIntoView();
  }

  return (
    <TimelineProvider isConstrained scrollContainerRef={scrollAreaRef}>
      <ErrorBoundary FallbackComponent={TimelineErrorFallback}>
        <VerticalTile
          middle={<Panel padding={20}>{body}</Panel>}
          top={<Panel padding={[20, 20, 0, 20]}>{head}</Panel>}
          padding={0}
          gap={0}
          ref={tileRef}
        />
      </ErrorBoundary>
    </TimelineProvider>
  );
};

export const Timeline = withErrorBoundary(Block);
