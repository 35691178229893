function removeTrailingZeros(numString) {
  return String(numString).replace(/\.?0+$/, '');
}

// Only remove one trailing zero, if present.
function removeTrailingZero(numString) {
  return String(numString).replace(/(\.\d)0$/, '$1');
}

// Slight fix for currency, when number has been formatted to something
// like '50.1' and the missing "zero" cents isn't appearing,
// or when precision appears better than one cent e.g. '50.132'.
export function ensureCents(numString) {
  if (numString.match(/\.\d$/)) {
    return `${numString}0`;
  }
  if (numString.match(/\.\d{3,}$/)) {
    return Number(numString).toFixed(2);
  }
  return numString;
}

// Depending on the locale, the decimal separator could be a different character.
export const getDecimalSeparator = () => {
  return (1.1).toLocaleString().substring(1, 2);
};

export const formatFullDecimal = (num) => {
  const formattedValue = parseFloat(Number(num).toFixed(2));

  const isWhole = formattedValue % 1 === 0;

  let stringValue;

  if (isWhole) {
    stringValue = `${formattedValue.toLocaleString()}${getDecimalSeparator()}0`;
  } else {
    stringValue = formattedValue.toLocaleString();
  }

  return stringValue;
};

// Format numbers we want to always indicate as a decimal
// For example:
//    35.11 -> 35.11
//    35.10 -> 35.1
//    35    -> 35.0
export const formatDecimal = (num) => {
  const value = Number(num);
  const stringNumber = String(num);
  const collapsedNumber = collapseNumber(num);

  // If collapseNumber changes the result, return that because it's
  // already formatted
  if (stringNumber !== collapsedNumber) {
    return collapsedNumber;
  }

  const fixed = value.toFixed(2);

  return removeTrailingZero(fixed);
};

export function collapseNumber(num) {
  // Defensively format non-numbers to zero
  num = Number(num);
  num = Number.isNaN(num) ? 0 : num;

  const maybeNeg = num < 0 ? '-' : '';
  const absoluteNum = Math.abs(num);

  // 1. Don’t exceed 5 characters in length (inclusive of multiplier letter - K, M, B, etc.)

  if (absoluteNum < 1) {
    // This is a special case since num.toPrecision(5) in the case below will not fulfill our requirement
    // of max 5 digits for certain numbers. In particular leading zeros mess this up: .00123456 becomes "0.0012345"
    // because that has 5 digits of precision. Instead, we just have to round to a fixed number of decimal points,
    // leaving room for the leading "0."
    return maybeNeg + removeTrailingZeros(absoluteNum.toFixed(4));
  }

  if (absoluteNum < 1000) {
    // 2. Numbers from $0.01 - $999, show each digit including decimals
    return maybeNeg + removeTrailingZeros(absoluteNum.toPrecision(5));
  }

  if (absoluteNum < 10000) {
    // 3. Numbers from $1000 - $9999, show each digit but not decimals
    return maybeNeg + Number(absoluteNum.toFixed(0)).toLocaleString();
  }

  // 4. Numbers greater than $10,000, show 4 digits and then the multiplier letter - K for thousand, M for million, B for billion

  const [, base, tens = '0'] = absoluteNum
    .toPrecision(4)
    .match(/^(.+?)(?:e\+(.+))?$/); // Parse scientific notation

  const thousands = Math.floor(Number(tens) / 3);
  const remainingTens = Number(tens) - 3 * thousands;

  const numericPart = removeTrailingZeros(
    (Number(base) * 10 ** remainingTens).toPrecision(4)
  );

  const multiplier = ['', 'K', 'M', 'B'][thousands];

  if (typeof multiplier === 'undefined') {
    return '999B+';
  }

  return maybeNeg + numericPart + multiplier;
}

export const moneyNumberFormatter = {
  format(num) {
    return ensureCents(collapseNumber(num));
  },
};

export const percentageFormatter = {
  format(num, fractionDigits = 2) {
    const n = typeof num === 'number' ? num : parseFloat(num);
    return isNaN(n) ? num : `${(n * 100).toFixed(fractionDigits)}%`;
  },
};
