import {
  call,
  takeLatest,
  put,
  all,
  select,
  debounce,
} from 'redux-saga/effects';
import TeamMemberService from 'services/TeamMemberService';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { DEFAULT_DELAY } from 'utility/config';
import {
  buildPage as buildPageAction,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  getActivities as getActivitiesAction,
  getActivitiesSuccess,
  getActivitiesFail as getActivitiesFailAction,
  deleteActivity as deleteActivityAction,
  deleteActivitySuccess,
  deleteActivityFail,
  duplicateActivity as duplicateActivityAction,
  duplicateActivitySuccess,
  duplicateActivityFail,
  setPageConfig as setPageConfigAction,
  setPageConfigSuccess,
  setPageConfigFail,
  generatePageConfig,
  getActivitiesStart,
  getActivitiesFinish,
} from './activities.redux';
import { toastVariant } from 'components/ToastProvider';
import ActivityService from 'services/ActivityService';
import { getOriginalError } from 'services/AxiosService';
import i18n from 'i18n-config';

const PAGE_CONFIG_KEY = 'activity_list';

function* getActivities(actionData) {
  try {
    yield put(getActivitiesStart());
    const payload = actionData && actionData.payload;
    const pageConfig = yield select((s) => s.activitiesPage.pageConfig);
    const data = {
      values: {
        search: pageConfig.search,
        ordering: pageConfig.sort,
        page: pageConfig.page,
        size: pageConfig.size,
      },
    };

    const [result] = yield all([
      call(ActivityService.v2GetActivityList, data.values),
      payload.updatePageConfig &&
        call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig),
    ]);
    yield put(
      getActivitiesSuccess({
        activities: snakeToCamelCaseKeys(result.results),
        activitiesCount: result.count,
      })
    );
  } catch (error) {
    yield put(getActivitiesFailAction(error && error.message));
  } finally {
    yield put(getActivitiesFinish());
  }
}

function* buildPage() {
  try {
    const [pageResponse] = yield all([
      call(
        TeamMemberService.getPageConfig,
        PAGE_CONFIG_KEY,
        generatePageConfig()
      ),
    ]);
    yield put(buildPageComplete({ pageResponse }));
    yield call(getActivities, { payload: { updatePageConfig: false } });
    yield put(buildPageFinish());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* deleteActivity({ payload }) {
  try {
    const data = {
      values: {
        activityObjectId: payload?.id,
      },
    };
    yield call(ActivityService.v2DeleteActivity, data.values);
    yield put(
      deleteActivitySuccess({
        variant: toastVariant.SUCCESS,
        message: i18n.t('The activity has been successfully deleted.'),
      })
    );
    yield call(getActivities, { payload: { updatePageConfig: false } });
  } catch (error) {
    const orig = getOriginalError(error);
    yield put(
      deleteActivityFail({
        variant: toastVariant.FAILURE,
        message:
          orig?.message ||
          i18n.t('The activity has not been successfully deleted.'),
      })
    );
  }
}

function* duplicateActivity({ payload }) {
  const { id, t } = payload;
  try {
    yield call(ActivityService.v2DuplicateActivity, {
      activityObjectId: id,
    });
    yield put(
      duplicateActivitySuccess({
        variant: toastVariant.SUCCESS,
        message: t('The activity has been successfully duplicated.'),
      })
    );
    yield call(getActivities, { payload: { updatePageConfig: false } });
  } catch (error) {
    const orig = getOriginalError(error);
    yield put(
      duplicateActivityFail({
        variant: toastVariant.FAILURE,
        message:
          orig?.message ||
          t('The activity has not been successfully duplicated.'),
      })
    );
  }
}
function* setPageConfig() {
  try {
    const pageConfig = yield select((s) => s.activitiesPage.pageConfig);
    yield call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig);
    yield put(setPageConfigSuccess());
  } catch (error) {
    yield put(setPageConfigFail(error));
  }
}

function* activitiesPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield debounce(DEFAULT_DELAY, getActivitiesAction.type, getActivities);
  yield takeLatest(deleteActivityAction.type, deleteActivity);
  yield takeLatest(duplicateActivityAction.type, duplicateActivity);
  yield takeLatest(setPageConfigAction.type, setPageConfig);
}

export default activitiesPage;
