import {
  isDefaultEmailField,
  isEntityNameField,
  isFirstNameField,
  isLastNameField,
} from 'checks/fields';

/**
 * Recursivly gets all child node and linked node ids and returns them in separate arrays.
 * @param {*} id - craftjs node id
 * @param {*} query - query object from useEditor hook
 * @returns {[string[], string[]]} - 2-tuple of string arrays [childNodeIds, linkedNodeIds]
 */
export const getChildren = (id, query) => {
  const childs = query.node(id).descendants(true, 'childNodes');
  const linked = query.node(id).descendants(true, 'linkedNodes');

  const [descendantChilds, descendantLinked] = childs.concat(linked).reduce(
    (acc, i) => {
      const [children, linkedChildren] = getChildren(i, query);
      acc[0].push(...children);
      acc[1].push(...linkedChildren);
      return acc;
    },
    [[], []]
  );

  childs.push(...descendantChilds);
  linked.push(...descendantLinked);

  return [childs, linked];
};

export const isDefaultFormField = (field) => {
  if (!field?.customObjectField) return false;
  return (
    isEntityNameField(field.customObjectField) ||
    isFirstNameField(field.customObjectField) ||
    isLastNameField(field.customObjectField) ||
    isDefaultEmailField(field.customObjectField)
  );
};
