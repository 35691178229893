import { useTranslation } from 'react-i18next';
import { colorsSecondary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { CardHeader } from '../components/CardHeader';
import { initiatorTypes } from '../../constants';
import {
  defaultEmployeeString,
  defaultAutomationString,
  defaultFormString,
  defaultSurveyString,
  defaultSmartConnectorString,
} from '../helpers';

const cardHeaderConfig = (t) => {
  const prefix = t('by');
  return {
    [initiatorTypes.employee]: {
      prefix,
      creationType: defaultEmployeeString,
    },
    [initiatorTypes.employee_upload]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via Upload'),
    },
    [initiatorTypes.employee_api_request]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via API'),
    },
    [initiatorTypes.automation]: {
      prefix,
      creationType: defaultAutomationString,
    },
    [initiatorTypes.smart_connector]: {
      prefix,
      creationType: defaultSmartConnectorString,
    },
    [initiatorTypes.form]: {
      prefix,
      creationType: defaultFormString,
    },
    [initiatorTypes.survey]: {
      prefix,
      creationType: defaultSurveyString,
    },
  };
};

const EntityCreatedEventBlock = ({ event, ...others }) => {
  const { t } = useTranslation();
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          icon="user-plus"
          size="16px"
          color={colorsSecondary.gray.dark}
        />
      }
      isEmpty
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={`${event.instance.entityRecordName} ${t('Created')}`}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    />
  );
};

export default EntityCreatedEventBlock;
