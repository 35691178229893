import { colorsSecondary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import FieldsTable from '../FieldsTable';
import { CardHeader } from '../components/CardHeader';
import { initiatorTypes } from '../../constants';
import {
  defaultEmployeeString,
  defaultAutomationString,
  checkBlankHtml,
} from '../helpers';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { AssociationsBlock } from '../components/AssosiationBlock';
import { useTranslation } from 'react-i18next';

const cardHeaderConfig = (t) => {
  const prefix = t('activity logged by');
  return {
    [initiatorTypes.employee]: {
      prefix,
      creationType: defaultEmployeeString,
    },
    [initiatorTypes.employee_api_request]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via API'),
    },
    [initiatorTypes.automation]: {
      prefix,
      creationType: defaultAutomationString,
    },
  };
};

const ActivityLoggedEventBlock = ({ event, ...others }) => {
  const { t } = useTranslation();
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsSecondary.aqua.dark}
          icon="clipboard-list"
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={event.data.activityName}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    >
      {event.data.associations ? (
        <>
          <TextEllipsisWithTooltip weight="bold">
            {t('Associations')}
          </TextEllipsisWithTooltip>
          <AssociationsBlock
            data-qa-field="associations"
            associations={event.data.associations}
          />
        </>
      ) : null}
      <FieldsTable fields={event.data.fields} />
    </BasicBlock>
  );
};

export default ActivityLoggedEventBlock;

const filterValues = (field) =>
  field && !(field.fieldType === 'wysiwyg' && !checkBlankHtml(field.value));

export const getActivityLoggedEvent = (event, t) => {
  const { data } = event;

  const eventFields = Array.isArray(data.fields) ? data.fields : [];
  let fields = eventFields
    .filter(({ id }) => filterValues(data.fieldsWithValues[id]))
    .map(({ displayName, id, ...metaInfo }) => ({
      label: displayName === 'Notes' ? t('Notes') : displayName,
      value: data.fieldsWithValues[id].value,
      type:
        data.fieldsWithValues[id].fieldType === 'wysiwyg'
          ? 'html'
          : data.fieldsWithValues[id].fieldType,
      id,
      metaInfo,
    }));

  // handle old data with just notes and no fields
  if (
    fields.length === 0 &&
    data.notes?.length > 0 &&
    checkBlankHtml(data.notes)
  ) {
    fields = [
      {
        label: t('Notes'),
        value: data.notes,
        type: 'html',
        id: '',
        metaInfo: {},
      },
    ];
  }

  return {
    ...event,
    data: {
      ...data,
      fields,
    },
  };
};
