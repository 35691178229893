import { useCallback } from 'react';
import { usePermissionsContext } from './context';
import type { AccessNumber } from '../src/types';

const emptyStreamError = (key: string) => `
Attempted to set a new permission value to a source stream with key ${key} but that stream has no current value. 
This should never happen. All permission sources should be set up with the 'reactive()' stream helper. Was this 
permission created with the 'stream()' helper instead?
`;

export const usePermissionSetter = (key: string) => {
  const ctx = usePermissionsContext();

  return useCallback(
    (value: boolean | AccessNumber) => {
      if (ctx.sources.has(key)) {
        const stream = ctx.sources.get(key)!;
        const current = stream.deref();

        if (!current) {
          throw Error(emptyStreamError(key));
        }

        const { allowed_access } = current;
        stream.next({ value, allowed_access });
      }
    },
    [key, ctx]
  );
};
