import React from 'react';
import { KizenTypography } from 'app/typography';
import { StyledTable } from '../styles';
import Section from './helpers/Section';

const TypographyList = () => {
  return (
    <Section title="Typography">
      <StyledTable responsive>
        <thead>
          <tr>
            <th style={{ minWidth: '150px' }}>
              <KizenTypography type="label-small">Component</KizenTypography>
            </th>
            <th>
              <KizenTypography type="label-small">Weight</KizenTypography>
            </th>
            <th>
              <KizenTypography type="label-small">
                Font Size (px)
              </KizenTypography>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* Hero */}
          <tr>
            <td>
              <KizenTypography type="hero">Hero</KizenTypography>
            </td>
            <td>
              <KizenTypography type="hero">Regular</KizenTypography>
            </td>
            <td>
              <KizenTypography type="hero">32px</KizenTypography>
            </td>
          </tr>

          {/* Header */}
          <tr>
            <td>
              <KizenTypography type="header">Header</KizenTypography>
            </td>
            <td>
              <KizenTypography type="header">Light</KizenTypography>
            </td>
            <td>
              <KizenTypography type="header">24px</KizenTypography>
            </td>
          </tr>

          {/* Sub Header */}
          <tr>
            <td>
              <KizenTypography type="subheader">Subheader</KizenTypography>
            </td>
            <td>
              <KizenTypography type="subheader">Semibold</KizenTypography>
            </td>
            <td>
              <KizenTypography type="subheader">18px</KizenTypography>
            </td>
          </tr>

          {/* Regular */}
          <tr>
            <td>
              <KizenTypography>Regular Text</KizenTypography>
            </td>
            <td>
              <KizenTypography>Regular</KizenTypography>
            </td>
            <td>
              <KizenTypography>14px</KizenTypography>
            </td>
          </tr>

          <tr>
            <td>
              <KizenTypography weight="bold">Regular Text</KizenTypography>
            </td>
            <td>
              <KizenTypography weight="bold">Semibold</KizenTypography>
            </td>
            <td>
              <KizenTypography weight="bold">14px</KizenTypography>
            </td>
          </tr>

          <tr>
            <td>
              <KizenTypography type="link" href="#">
                Link
              </KizenTypography>
            </td>
            <td>
              <KizenTypography type="link" href="#">
                Regular
              </KizenTypography>
            </td>
            <td>
              <KizenTypography type="link" href="#">
                14px
              </KizenTypography>
            </td>
          </tr>

          <tr>
            <td>
              <KizenTypography
                type="link"
                weight="bold"
                size="buttonLabel"
                textTransform="uppercase"
              >
                Button Text
              </KizenTypography>
            </td>
            <td>
              <KizenTypography
                type="link"
                weight="bold"
                size="buttonLabel"
                textTransform="uppercase"
              >
                Semibold
              </KizenTypography>
            </td>
            <td>
              <KizenTypography
                type="link"
                weight="bold"
                size="buttonLabel"
                textTransform="uppercase"
              >
                12px
              </KizenTypography>
            </td>
          </tr>

          {/* Micro */}
          <tr>
            <td>
              <KizenTypography type="micro">Micro Text</KizenTypography>
            </td>
            <td>
              <KizenTypography type="micro">Semibold</KizenTypography>
            </td>
            <td>
              <KizenTypography type="micro">11px</KizenTypography>
            </td>
          </tr>

          <tr>
            <td>
              <KizenTypography type="link-micro" href="#">
                Micro Link
              </KizenTypography>
            </td>
            <td>
              <KizenTypography type="link-micro" href="#">
                Semibold
              </KizenTypography>
            </td>
            <td>
              <KizenTypography type="link-micro" href="#">
                11px
              </KizenTypography>
            </td>
          </tr>

          <tr>
            <td>
              <KizenTypography type="label-small">Label</KizenTypography>
            </td>
            <td>
              <KizenTypography type="label-small">Regular</KizenTypography>
            </td>
            <td>
              <KizenTypography type="label-small">11px</KizenTypography>
            </td>
          </tr>

          <tr>
            <td>
              <KizenTypography size="micro">Dashlet Axis</KizenTypography>
            </td>
            <td>
              <KizenTypography size="micro">Regular</KizenTypography>
            </td>
            <td>
              <KizenTypography size="micro">10px</KizenTypography>
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </Section>
  );
};

export default TypographyList;
