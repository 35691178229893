import styled from '@emotion/styled';

import { grayScale, shadowLight, border } from 'app/colors';
import { breakpoints, gutters } from 'app/spacing';

export const ChartAggregatesBar = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${grayScale.white};
  ${shadowLight}
  ${border}
  border-color: ${grayScale.light};
  height: 52px;
  width: 100%;
  max-width: 845px;
  margin: 0 auto;
  padding: ${gutters.spacing(2)}px ${gutters.spacing(4)}px;
  align-items: center;
  overflow: hidden;

  div:nth-child(odd) {
    flex: 2;
    text-align: center;
  }

  div:nth-child(even) {
    flex: 1;
    height: 100%;
    left: 0;
    @media (min-width: ${breakpoints.lg}px) {
      left: -24px;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    height: 62px;
    margin-bottom: ${gutters.spacing(4)}px;
  }

  @media (max-width: ${breakpoints.md}px) {
    height: 48px;
    margin: ${gutters.spacing(1, -1)}px 0 ${gutters.spacing(3, -1)}px;
    padding: ${gutters.spacing(2)}px 0;

    i {
      font-size: 0.75em;
      color: ${grayScale.light};
    }

    p + p {
      margin-top: 5px;
    }
  }
`;

export const AggregatePercentBlock = styled.div`
  height: 100%;
  position: relative;

  p {
    position: absolute;
    right: 40%;
    top: calc(50% - 6px); // 6px is half of line-height
  }

  i {
    height: 100%;
    position: absolute;
    // Should roughly overlap with percentage symbol
    right: -10px;
    @media (min-width: ${breakpoints.lg}px) {
      right: 10px;
    }
  }
`;
