import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import useAsync from 'react-use/lib/useAsync';
import useField from 'hooks/useField';
import CustomObjectService from 'services/CustomObjectsService';
import Select from 'components/Kizen/Select';
import { gutters } from 'app/spacing';
import { Text } from 'app/typography';
import { FIELD_TYPES } from 'utility/constants';

const Wrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

const StyledRow = styled(Row)`
  margin-bottom: ${gutters.spacing(4)}px !important;
`;

const modelToOption = (model) => ({
  value: model.id,
  label: 'objectName' in model ? model.objectName : model.name,
  name: model.name,
});

const EMPTY_ARRAY = [];

// Simplyfied  RelationShip for the activities
export default function ActivityRelationshipOptions({
  updateField,
  updateValidation,
  formData,
  data,
}) {
  const { t } = useTranslation();
  const currentRelation = formData.relation || {};

  const { relatedObject } = currentRelation;
  const [relatedEntityId, setRelatedEntityId] = useField(relatedObject);
  const [cardinality, setCardinality] = useField(
    currentRelation.cardinality || 'one_to_one'
  );

  const isValid = Boolean(relatedEntityId);

  // Load models on mount; account-specific, independent of formData,
  // shouldn't need to be reloaded
  const { loading, value: models = EMPTY_ARRAY } = useAsync(
    CustomObjectService.getAllowedRelations,
    []
  );

  useEffect(() => {
    updateValidation(isValid);
  }, [isValid, updateValidation]);

  useEffect(() => {
    updateField('relation', {
      name: 'relationship field',
      fieldType: FIELD_TYPES.Relationship.type,
      relatedObject: relatedEntityId,
      cardinality,
    });
  }, [cardinality, relatedEntityId, updateField]);

  if (loading) {
    return null;
  }

  const selectedModel = models.find((model) => model.id === relatedEntityId);

  return (
    <Wrapper>
      <StyledRow>
        <Col>
          <Text>{t('Related Entity')}</Text>
          <Select
            fullWidth
            placeholder={t('Choose Related Entity')}
            value={selectedModel ? modelToOption(selectedModel) : null}
            options={models.map(modelToOption)}
            onChange={(opt) => {
              setRelatedEntityId(opt.value);
            }}
            disabled={!!data.field}
          />
        </Col>
        {selectedModel ? (
          <Col>
            <Text>{t('Relationship Type')}</Text>
            <Select
              fullWidth
              value={cardinality}
              placeholder={t('Choose Related Entity')}
              options={[
                { value: 'one_to_one', label: t('Choose One') },
                { value: 'one_to_many', label: t('Choose Many') },
              ]}
              onChange={(opt) => {
                setCardinality(opt.value);
              }}
            />
          </Col>
        ) : (
          <Col />
        )}
      </StyledRow>
    </Wrapper>
  );
}

ActivityRelationshipOptions.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  formData: PropTypes.object,
  data: PropTypes.object,
};

ActivityRelationshipOptions.defaultProps = {
  formData: {},
  data: {},
};
