import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { fontSizes } from 'app/typography';
import KizenTypography from 'app/kizentypo';

const Wrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${gutters.spacing(6)}px;
  }
  & > h3 {
    line-height: 1;
    margin-bottom: ${gutters.spacing(4, { baseline: fontSizes.subheader })}px;
  }
`;

const Subsection = forwardRef(function Subsection(
  { title, children, ...props },
  ref
) {
  return (
    <Wrapper {...props} ref={ref}>
      {title && (
        <KizenTypography as="h3" type="subheader">
          {title}
        </KizenTypography>
      )}
      {children}
    </Wrapper>
  );
});

Subsection.propTypes = {
  title: PropTypes.string,
};

Subsection.defaultProps = {
  title: null,
};

export default Subsection;
