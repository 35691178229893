import { useTranslation } from 'react-i18next';
import { StyledCols } from '../styles';
import SwitchControl from 'components/Wizards/CustomObject/components/SwitchControl';
import Switch from 'components/Kizen/Switch';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { getToggles } from '../helpers';

export const Actions = ({ values, setValues, isContact }) => {
  const { t } = useTranslation();

  const handleChange = (key, checked) =>
    setValues((prev) => ({
      ...prev,
      meta: { ...prev.meta, [key]: checked },
    }));

  return (
    <StyledCols columns={4} gutter="20px">
      {getToggles(t).map(({ key, forContacts, label }) => {
        return isContact || !forContacts ? (
          <SwitchControl key={key} label={label}>
            <Switch
              disabled={false}
              checked={values.meta[key]}
              onChange={(e) => handleChange(key, e.target.checked)}
              {...getDataQAForInput(`action_block_${key}`)}
            />
          </SwitchControl>
        ) : null;
      })}
    </StyledCols>
  );
};
