import { colorsPrimary, colorsSecondary } from 'app/colors';
import { ActionCell } from '../columns';

export const OAUTH_STATUS = {
  ACTIVE: 'active',
  RGP: 'rgp',
  EXPIRED: 'expired',
};

export const getOAuthStatus = (status) => {
  const dictionary = {
    [OAUTH_STATUS.ACTIVE]: {
      label: (t) => t('Active'),
      statusColor: colorsPrimary.green.dark,
    },
    [OAUTH_STATUS.RGP]: {
      label: (t) => t('RGP?'),
      statusColor: colorsSecondary.red.dark,
    },
    [OAUTH_STATUS.EXPIRED]: {
      label: (t) => t('Expired'),
      statusColor: colorsPrimary.orange.dark,
    },
  };
  return dictionary[status];
};

export const OAuthActionCell = ({ t, ...props }) => {
  return (
    <ActionCell
      options={[{ value: 'onTokenRevoke', label: t('Revoke') }]}
      t={t}
      title={t('Revoke')}
      {...props}
    />
  );
};
