import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  forwardRef,
  ChangeEvent,
  FocusEvent,
  useImperativeHandle,
  MouseEvent,
  useCallback,
} from 'react';
import { Button, ButtonProps, IconSettings } from '../Button/Button';
import { ValidIcons } from '../Icon/library';
import { Typography } from '../Typography/Typography';
import { useExpandDirection } from '../hooks/useExpandDirection';
import { useDebounce, DEFAULT_INPUT_DELAY } from '../hooks/useDebounce';
import { isDebug } from '../debug';
import { getKDSClasses, merge, isDescendant } from '../util';
import { Spacer } from '../Spacer/Spacer';

type SearchPillSizes = 'sm' | 'lg';

export interface SearchPillProps {
  debounceDelay?: number;
  inline?: boolean;
  placeholder: string;
  boundaryClassName?: string;
  onChange?: (value: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  pilled?: boolean;
  loading?: boolean;
  value: string;
  size?: SearchPillSizes;
  qa?: string;
}

const availableWidths: { [key: string]: string } = {
  '120': 'w-[120px]',
  '125': 'w-[125px]',
  '130': 'w-[130px]',
  '135': 'w-[135px]',
  '140': 'w-[140px]',
  '145': 'w-[145px]',
  '150': 'w-[150px]',
  '155': 'w-[155px]',
  '160': 'w-[160px]',
  '165': 'w-[165px]',
  '170': 'w-[170px]',
  '175': 'w-[175px]',
  '180': 'w-[180px]',
  '185': 'w-[185px]',
  '190': 'w-[190px]',
  '195': 'w-[195px]',
};

const getCalculatedWidth = (availableWidth: number) => {
  const roundedAvailableWidth = Math.floor(availableWidth / 5) * 5;
  let calculatedWidth = '195';
  if (roundedAvailableWidth < 120) {
    calculatedWidth = '120';
  } else if (roundedAvailableWidth > 195) {
    calculatedWidth = '195';
  } else {
    calculatedWidth = String(roundedAvailableWidth);
  }
  return availableWidths[calculatedWidth];
};

const getBorderClasses = (focused: boolean) => {
  if (focused) {
    return 'border-button-secondary-hover hover:border-button-secondary-hover';
  }
  return 'border-border-dark hover:border-button-secondary-hover';
};

const getPilledExpandedDirectionClasses = (expandDirection: string) => {
  switch (expandDirection) {
    case 'left':
      return 'absolute -left-[167px] pl-[10px] pr-[24px]';
    case 'right':
      return 'absolute -left-[8px] pl-[25px] pr-[30px]';
  }
};

const getFormExpandedDirectionClasses = (expandDirection: string) => {
  switch (expandDirection) {
    case 'left':
      return 'absolute -left-[167px] pr-[20px] ml-[-5px]';
    case 'right':
      return 'absolute -left-2 pl-[20px] ml-[5px]';
  }
};

const getExpandedClassNames = (
  pilled: boolean,
  expanded: boolean,
  expandDirection: string = 'right', // Defaulting to 'right' if not provided
  focused: boolean,
  availableWidth: number,
  size: SearchPillSizes = 'lg',
  inline: boolean = false
) => {
  const baseClasses = merge(
    pilled ? 'pilled' : 'form',
    expandDirection + '-expand',
    'inline-flex border-box items-center bg-background-white',
    !inline && pilled ? 'shadow-tile' : ''
  );

  // round the available width from border to the nearest 5px
  const roundedAvailableWidth = getCalculatedWidth(availableWidth);

  const sharedClasses = expanded ? roundedAvailableWidth : 'hidden';

  if (pilled) {
    return merge(
      baseClasses,
      'rounded-full min-h-[30px] max-h-[36px] border-1 border-solid z-[1]',
      getPilledExpandedDirectionClasses(expandDirection),
      getBorderClasses(focused),
      sharedClasses,
      size === 'lg' ? 'h-[36px]' : 'h-[30px]'
    );
  }
  return merge(
    baseClasses,
    'max-h-[36px] min-h-[30px] h-full border-b z-[1]',
    getFormExpandedDirectionClasses(expandDirection),
    getBorderClasses(focused),
    sharedClasses
  );
};

const getInlineClasses = (
  pilled: boolean,
  focused: boolean,
  availableWidth: number,
  size: SearchPillSizes = 'lg'
) => {
  const baseClasses = merge(
    getKDSClasses('search-pill, default-expanded'),
    pilled ? 'pilled' : 'form',
    'relative inline-flex items-center bg-background-white'
  );

  if (pilled) {
    return merge(
      baseClasses,
      'rounded-full min-h-[28px] max-h-[36px] px-[10px] border-1 border-solid ',
      getCalculatedWidth(availableWidth),
      getBorderClasses(focused),
      size === 'lg' ? 'h-[36px]' : 'h-[30px]'
    );
  }
  return merge(
    baseClasses,
    'px-[5px] border-b w-full',
    size === 'sm' ? 'h-[30px]' : 'max-h-[36px] min-h-[29px]',
    getBorderClasses(focused)
  );
};

const pillIcon = (
  buttonRef: React.RefObject<HTMLButtonElement>,
  absolutePositioning: boolean,
  buttonProps: ButtonProps,
  expandDirection?: string
): React.ReactElement => {
  const absolute = absolutePositioning ? 'z-[2] flex' : 'flex';
  return (
    <>
      {expandDirection === 'left' ? <Spacer size={5} mode="vertical" /> : null}
      <div className={merge(absolute)}>
        <Button
          ref={buttonRef}
          onClick={buttonProps.onClick}
          rightIcon={buttonProps.rightIcon}
          size="small"
          variant="text"
          rightIconSettings={{
            badge: buttonProps.rightIconSettings?.badge ?? false,
          }}
          color="tertiary"
        />
      </div>
      {expandDirection === 'right' ? <Spacer size={5} mode="vertical" /> : null}
    </>
  );
};

export const SearchPill = forwardRef<HTMLInputElement, SearchPillProps>(
  (props, ref) => {
    const {
      debounceDelay = DEFAULT_INPUT_DELAY,
      placeholder = '',
      boundaryClassName = 'kds-menu-boundary',
      value = '',
      onFocus = () => {},
      onChange = () => {},
      onBlur = () => {},
      inline = false,
      pilled = true,
      loading = false,
      size = 'lg',
      qa,
    } = props;
    const [internalValue, setInternalValue] = useState(value);
    const [expanded, setExpanded] = useState(!pilled || inline); // !pilled removes collapsable functionality
    const [focused, setFocused] = useState(false);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current!);

    const { expandDirection, remainingSpace } = useExpandDirection(
      containerRef,
      expanded,
      boundaryClassName
    );

    const debouncedValue = useDebounce(internalValue, debounceDelay);

    useEffect(() => {
      if (debouncedValue || debouncedValue === '') {
        onChange(debouncedValue);
      }
    }, [debouncedValue, onChange]);

    useEffect(() => {
      setExpanded(inline);
    }, [inline]);

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    const handleSearchIconClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setExpanded((e) => !e);
        if (!expanded) {
          setTimeout(() => {
            const input = inputRef.current;
            input?.focus();
            const textLength = input?.value.length ?? 0;
            inputRef.current?.setSelectionRange(textLength, textLength);
          }, 0);
        }
      },
      [expanded, inputRef]
    );

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      if (isDescendant(buttonRef.current, event.relatedTarget)) {
        return;
      }
      setFocused(false);
      onBlur?.(event);
      setExpanded(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const eventValue = event?.target?.value ?? '';
      setInternalValue(eventValue);
    };

    const handleCancel = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setInternalValue('');
      inputRef.current?.focus();
    }, []);

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus?.(event);
    };

    const buttonProps = useMemo<ButtonProps>(() => {
      let rightIconSettings: IconSettings = {};
      let rightIcon: ValidIcons = 'action-search';
      let onClick: (event: MouseEvent<HTMLButtonElement>) => void =
        handleSearchIconClick;

      if (loading && expanded) {
        rightIcon = 'action-loading';
        onClick = () => {};
      } else if (internalValue !== '' && expanded) {
        rightIcon = 'action-close';
        onClick = handleCancel;
      }

      if (internalValue !== '' && rightIcon === 'action-search') {
        rightIconSettings = { badge: true, color: 'button-primary-default' };
      }

      return {
        onClick,
        rightIcon: rightIcon as ValidIcons,
        rightIconSettings,
      };
    }, [internalValue, expanded, loading, handleSearchIconClick, handleCancel]);

    const inlineButtonProps = useMemo<ButtonProps>(() => {
      let rightIcon: ValidIcons = 'action-search';
      let onClick = (event: MouseEvent<HTMLButtonElement>) => {};
      if (loading) {
        rightIcon = 'action-loading';
      } else if (internalValue !== '') {
        rightIcon = 'action-close';
        onClick = handleCancel;
      }

      return {
        onClick,
        rightIcon: rightIcon as ValidIcons,
      };
    }, [internalValue, loading, handleCancel]);

    const { debugClassName } = isDebug();
    const expandedClassNames = useMemo(
      () =>
        getExpandedClassNames(
          pilled,
          expanded,
          expandDirection,
          focused,
          remainingSpace,
          size,
          inline
        ),
      [pilled, expanded, expandDirection, focused, remainingSpace, size, inline]
    );
    const kdsClassNameExtension = pilled ? 'pilled' : 'form';
    const textColor = internalValue ? 'font-primary' : 'font-placeholder';

    if (inline || !pilled) {
      // !pilled removes support for absolute positioning of a non pilled search pill
      const defaultExpandedClassNames = getInlineClasses(
        pilled,
        focused,
        remainingSpace,
        size
      );
      return (
        <div
          ref={containerRef}
          className={merge(
            debugClassName,
            getKDSClasses('search-pill', kdsClassNameExtension),
            defaultExpandedClassNames
          )}
        >
          {expandDirection === 'right'
            ? pillIcon(buttonRef, !inline, inlineButtonProps, expandDirection)
            : null}
          {expandDirection === 'left' ? (
            <Spacer size={5} mode="vertical" />
          ) : null}
          <Typography size="md" truncate className={merge(textColor, 'grow')}>
            <input
              data-qa={qa || 'search-pill-input'}
              ref={inputRef}
              value={internalValue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={placeholder}
              className="w-full outline-none"
            />
          </Typography>
          {expandDirection === 'left'
            ? pillIcon(buttonRef, !inline, inlineButtonProps, expandDirection)
            : null}
        </div>
      );
    }

    return (
      <div
        ref={containerRef}
        className={merge(
          debugClassName,
          getKDSClasses('search-pill', kdsClassNameExtension),
          'inline-flex relative items-center h-full'
        )}
      >
        {pillIcon(buttonRef, !inline, buttonProps)}
        <div className={expandedClassNames}>
          <Spacer size={5} mode="vertical" />
          {expandDirection === 'right' ? (
            <Spacer size={5} mode="vertical" />
          ) : null}
          <Typography size="md" truncate className={merge(textColor, 'grow')}>
            <input
              data-qa={qa || 'search-pill-input'}
              ref={inputRef}
              value={internalValue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={placeholder}
              className="w-full outline-none"
            />
          </Typography>
          <Spacer size={5} mode="vertical" />
        </div>
      </div>
    );
  }
);
