import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';

export const SaveAndPreviewButton = ({ disabled, onClick }) => {
  const { t } = useTranslation();

  return (
    <Button color="blue" onClick={onClick} disabled={disabled}>
      {t('Save & Preview')}
    </Button>
  );
};
