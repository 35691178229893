import css from '@emotion/css';
import styled from '@emotion/styled';
import FieldInput from '../internal/components/Fields/FieldInput';

const setTextStyles = (textStyles) => {
  return textStyles
    .map((style) => {
      switch (style) {
        case 'bold':
          return 'font-weight: bold;';
        case 'underline':
          return 'text-decoration: underline;';
        case 'italic':
          return 'font-style: italic;';
        default:
          return '';
      }
    })
    .join('');
};

const defineFontStyles = ({ field }) =>
  field.useOwnStyles
    ? css`
        font-size: unset;
      `
    : '';

const defineSpanFontStyles = ({ field }) =>
  field.useOwnStyles
    ? css`
        font-family: ${field.fontFamily || '"Arial", sans-serif'};
        font-size: ${field.fontSize || 14}px;
        color: ${field.fontColor || '#4A5660'};
      `
    : css`
        font-size: inherit;
      `;

const setFontStyles = ({ field }) => css`
  text-align: ${field.alignment || 'left'};
`;

const setSpanFontStyles = ({ field }) => css`
  ${field.fontStyle?.length ? setTextStyles(field.fontStyle) : ''}
`;

export const StyledFieldInput = styled(FieldInput)`
  input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.InputControl > label {
    ${setFontStyles}
    ${defineFontStyles}
  }
  &.InputControl > label > span {
    white-space: pre-line;
    ${setSpanFontStyles}
    ${defineSpanFontStyles}
  }
`;
