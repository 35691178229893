import { useCallback, useEffect, useMemo } from 'react';
import { useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useEditor, useNode } from '@craftjs/core';
import { ViewImage } from '@kizen/page-builder/nodes/Image';
import { getImageDimensions } from '@kizen/page-builder/utils/image';
import { clamp } from 'utility/clamp';
import { useDraggingType } from '../useDraggingType';
import { useShowControls } from '../useShowControls';
import Control, { CarouselControl } from '../Control';
import {
  useBuilderContext,
  useIsInBuilderContext,
  useSetActiveNode,
  useSetActiveNodeProp,
} from 'components/PageBuilder/BuilderContext';
import { colorsPrimary } from 'app/colors';
import { useCycleWithTimer } from '../useCycleWithTimer';
import { IMAGE_SIZES, DIMENSION } from '../../options';
import { getProportionalSizes } from '../../settings/ImageSettings/shared';

// Calculate the space that will be taken up by images and set a min-height
// so the ScrollFadeContainer gradients will be accurate prior to the images loading.
// Only applies when the image has not loaded (width === 0)
const calculateMinHeight = (width, props) => {
  if (width > 0) return undefined;

  return props.size === 'dynamic' && !props.height
    ? (props.naturalHeight / props.naturalWidth) *
        clamp(
          props.unit === 'percent'
            ? width * (props.width / 100)
            : props.width ?? width,
          1,
          width
        )
    : props.height || props.naturalHeight;
};

export const Image = (props) => {
  const { t } = useTranslation();
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
  }));

  const {
    id,
    connectors: { connect },
    setProp,
    node,
    actions: { setCustom },
  } = useNode((node) => ({ id: node.id, node }));
  const setActiveNodeProp = useSetActiveNodeProp();

  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const [measureRef, { width }] = useMeasure();
  const { activeNode, setTrayProps } = useBuilderContext();
  const setActiveNode = useSetActiveNode();
  const hasDynamicImages = node.data.custom?.dynamicImages?.length > 0;
  const images = useMemo(() => {
    return hasDynamicImages
      ? [node.data].concat(node.data.custom.dynamicImages)
      : [];
  }, [hasDynamicImages, node.data]);
  const [cycledImage, isActive, { start, stop, next, prev, setFromIndex }] =
    useCycleWithTimer(images);
  const currentImage = cycledImage || node.data;
  const minHeight = calculateMinHeight(width, props);
  const handleSetActive = () => {
    stop();
    setTrayProps({
      imageFilterId: currentImage.props.filterId,
      onImageChange,
    });
  };

  useEffect(() => {
    if (props.containerWidth !== width) {
      setProp((p) => (p.containerWidth = width));
      if (id === activeNode?.id) {
        setActiveNodeProp('containerWidth', width);
      }
    }
  }, [width, props.containerWidth, setProp, id, activeNode, setActiveNodeProp]);

  useEffect(() => {
    // check an old size 'custom' and change it to the 'dynamic' size
    if (props.size === 'custom') {
      const { width, height } = getProportionalSizes({
        width: props.width,
        proportion: props.naturalWidth / props.naturalHeight,
        containerWidth: props.containerWidth,
        unit: props.unit,
        height: props.height,
      });
      setProp((p) => {
        p.size = IMAGE_SIZES.DYNAMIC;
        p.dimension = props.height ? DIMENSION.HEIGHT : DIMENSION.WIDTH;
        p.width = width;
        p.height = height;
      });
    }

    if (
      hasDynamicImages &&
      node.data.custom.dynamicImages.some(
        ({ props }) => props.size === 'custom'
      )
    ) {
      for (let i = 0; i < node.data.custom.dynamicImages.length; i++) {
        const item = node.data.custom.dynamicImages[i];
        if (item.props.size === 'custom') {
          async function updateDynamicImage() {
            let naturalWidth = item.props.naturalWidth;
            let naturalHeight = item.props.naturalHeight;

            if (!naturalWidth || !naturalHeight) {
              const [width, height] = await getImageDimensions(item.props.src);
              naturalWidth = width;
              naturalHeight = height;
            }

            const { width, height } = getProportionalSizes({
              width: item.props.width,
              proportion: naturalWidth / naturalHeight,
              containerWidth: props.containerWidth,
              unit: item.props.unit,
              height: item.props.height,
            });
            setCustom((c) => {
              c.dynamicImages[i].props.size = IMAGE_SIZES.DYNAMIC;
              c.dynamicImages[i].props.dimension = props.height
                ? DIMENSION.HEIGHT
                : DIMENSION.WIDTH;
              c.dynamicImages[i].props.width = width;
              c.dynamicImages[i].props.height = height;
              c.dynamicImages[i].props.naturalWidth = naturalWidth;
              c.dynamicImages[i].props.naturalHeight = naturalHeight;
            });
          }

          updateDynamicImage();
        }
      }
    }
  }, [hasDynamicImages, id, node, props, setCustom, setProp]);

  const isInBuilderContext = useIsInBuilderContext();

  const onImageChange = useCallback(
    (imageData) => {
      setFromIndex(
        images.findIndex(
          (img) => img.props.filterId === imageData.props.filterId
        ) ?? 0
      );
    },
    [setFromIndex, images]
  );

  useEffect(() => {
    // update the tray props with the current active image's filterId
    // so the settings data updates when viewing the dynamic content section
    setTrayProps({
      imageFilterId: cycledImage?.props?.filterId,
      onImageChange,
    });
  }, [cycledImage, onImageChange, setTrayProps]);

  return (
    <Control
      ref={connect}
      label={t('Content')}
      show={showControls}
      color={colorsPrimary.green.dark}
      {...props}
      mayHaveChildren={false}
      fullWidth={true}
      onSetActive={handleSetActive}
      rightControl={
        hasDynamicImages ? (
          <CarouselControl
            isActive={isActive}
            onPreviousClick={(ev) => {
              ev.stopPropagation();
              prev();
            }}
            onNextClick={(ev) => {
              ev.stopPropagation();
              next();
            }}
            onPlayClick={(ev) => {
              ev.stopPropagation();
              if (isActive) stop();
              else {
                setActiveNode(null);
                start();
              }
            }}
          />
        ) : null
      }
    >
      <ViewImage
        key={currentImage?.props?.src}
        nodeId={id}
        ref={measureRef}
        clickable
        removeLink
        draggingType={draggingType}
        minHeight={minHeight}
        onNoImageClick={() => setTrayProps({ imageLibraryOpen: true })}
        isInBuilderContext={isInBuilderContext}
        {...currentImage?.props}
      />
    </Control>
  );
};
