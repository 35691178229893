import BasicModal from 'components/Modals/presets/BasicModal';
import DashletWizard from 'components/Wizards/Dashlet';
import { useTranslation } from 'react-i18next';
import useWizardInModal from 'components/Wizards/shared/hooks/useWizardInModal';
import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import KizenTypography from 'app/kizentypo';
import { isDashletValid } from 'components/DashboardGrid/validation';
import { useModalClickedState } from 'components/DashboardGrid/hooks/useModalClickedState';

const areasWithoutObjects = [
  AREA_RESPONSES.EMAILS,
  AREA_RESPONSES.ACTIVITIES,
  'custom_object',
];
export const getRelevantObjectIds = (dashlet) => {
  if (areasWithoutObjects.includes(dashlet.area)) {
    return [];
  }

  return dashlet.area === AREA_RESPONSES.MARKETING
    ? dashlet.objectsWithLeads ?? []
    : [dashlet.pipeline?.value].filter(Boolean);
};

const DashletModal = (props) => {
  const {
    existing = {},
    onConfirm,
    hideModal,
    clientObjectId = '',
    homepage,
    scrollActvitiyBuilderIntoView,
    ...rest
  } = props;
  const { t } = useTranslation();
  const access = useSelector((s) => s.authentication.access);
  const [modalSize, setModalSize] = useState('medium');

  const hasAccess = useMemo(() => {
    const ids = getRelevantObjectIds(existing);
    const objectAccess = access.custom_objects?.custom_object_entities ?? {};
    const contactAccess = access.sections?.contacts_section?.view ?? false;

    return ids.every((id) => {
      if (id === clientObjectId) {
        return contactAccess;
      }
      return objectAccess[id]?.enabled;
    });
  }, [existing, access, clientObjectId]);

  const { modalProps, wizardProps } = useWizardInModal(
    onConfirm,
    // Compute validity of the wizard form data
    isDashletValid,
    existing,
    setModalSize
  );

  const isEditing = Object.keys(existing).length > 0;

  const { clicked, handleClickConfirm } = useModalClickedState(
    modalProps?.onConfirm
  );

  return (
    <BasicModal
      defaultLeftBtnText={hasAccess ? t('Cancel') : undefined}
      leftBtn={hasAccess ? undefined : false}
      buttonText={hasAccess ? (isEditing ? t('Save') : t('Add')) : t('Close')}
      actionBtnColor={hasAccess ? 'green' : 'blue'}
      className="no-drag"
      fitContent={hasAccess ? false : 'y'}
      heading={isEditing ? t('Edit Dashlet') : t('Add New Dashlet')}
      size={hasAccess ? modalSize : undefined}
      {...modalProps}
      disabled={(hasAccess ? modalProps.disabled : false) || clicked}
      typeOfContent={hasAccess ? modalProps.typeOfContent : 'text'}
      onConfirm={hasAccess ? handleClickConfirm : hideModal}
      animateSizeChange
      {...rest}
    >
      {hasAccess ? (
        <DashletWizard
          existing={existing}
          homepage={homepage}
          setModalSize={setModalSize}
          scrollActvitiyBuilderIntoView={scrollActvitiyBuilderIntoView}
          isEditing={isEditing}
          {...wizardProps}
        />
      ) : (
        <KizenTypography>
          {t(
            'You must have permission to view the data for all custom object(s) in this dashlet to edit'
          )}
        </KizenTypography>
      )}
    </BasicModal>
  );
};

export default DashletModal;
