import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import useField from 'hooks/useField';
import { colorsButton } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import FileLibraryModal from 'components/Inputs/FileInput/Modal';
import ReadonlyFileLibraryModal from './Modal/ReadonlyModal';
import useModal from 'components/Modals/useModal';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { gutters, resetButtonCss } from 'app/spacing';
import { cleanFiles } from 'components/Inputs/helpers';
import { OutlineInputLayout } from 'components/Inputs/TextInput/Outline';
import { MaybeInputControl } from '../InputControl';
import Validation, { useValidation } from '../Validation';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const StyledOutlineInputLayout = styled(OutlineInputLayout)`
  margin-top: ${gutters.spacing(2, 2)}px;
  padding: 0;
`;
const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
`;

const FileInputWrapper = styled.button`
  ${resetButtonCss({ padding: '0 12px' })}
  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}

  display: flex;
  flex: 1;
  width: 100%;

  > div {
    height: 100%;
  }

  > div:first-of-type {
    display: flex;
    flex: 1;
    align-content: center;
  }

  ${({ showVisibleIcon }) =>
    showVisibleIcon &&
    css`
      > div:last-of-type {
        display: flex;
        flex: 0 0 20px;

        align-content: center;
        justify-content: flex-end;
      }
    `}
`;

const DefaultPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <StyledPlaceholder>
      <KizenTypography as="span" color={colorsButton.blue.default}>
        {t('Add Files')}
      </KizenTypography>
    </StyledPlaceholder>
  );
};

const FilledPlaceholder = ({ children }) => (
  <StyledPlaceholder>
    <KizenTypography as="span" color={colorsButton.blue.default}>
      {children}
    </KizenTypography>
  </StyledPlaceholder>
);

const OutlineFileInput = (props) => {
  const { t } = useTranslation();

  const validationRef = useRef();
  const [validation, validationProps] = useValidation(validationRef, props);
  const {
    fieldId,
    onChange,
    inModal,
    errorPlacement,
    error,
    readonly,
    showVisibleIcon,
    source,
    publicFile = false,
    field,
    ...inputControlProps
  } = props;
  const { assignFieldHandle } = useKeyBoardContext();
  const { disabled, files: filesProp, initialFiles, sizing, viewable } = props;
  const [focused, setFocused] = useState(false);
  const [files] = useField(() => {
    return cleanFiles(filesProp);
  }, [filesProp]);
  const [modalProps, , modal] = useModal();
  const disabledProp = disabled && !viewable;
  const buttonRef = useRef(null);
  assignFieldHandle(fieldId, {
    customFocus: () => {
      buttonRef.current?.focus();
      return buttonRef.current;
    },
    disabled: disabledProp,
  });

  return (
    <>
      <MaybeInputControl
        forInput
        variant="outline"
        {...{
          ...inputControlProps,
          error: true,
          disabled: false,
          shrink: true,
        }}
        margin
        sizing={sizing}
      >
        <StyledOutlineInputLayout
          ref={validationRef}
          disabled={disabledProp}
          viewable={viewable}
          error={error || validation.error}
          focused={focused}
        >
          <FileInputWrapper
            ref={buttonRef}
            data-qa-file-input="open-button"
            onClick={() => (!disabled || viewable) && modal.show()}
            disabled={disabled && !viewable}
            showVisibleIcon={showVisibleIcon}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          >
            <div>
              <FilledPlaceholder>
                {`${files.length} ${
                  files.length !== 1 ? t('Files Selected') : t('File Selected')
                }`}
              </FilledPlaceholder>
            </div>
            {showVisibleIcon && (
              <div>
                {(!disabled || viewable) && <IconAdornment icon="visible" />}
              </div>
            )}
          </FileInputWrapper>
        </StyledOutlineInputLayout>
        <Validation
          inModal={inModal}
          errorPlacement={errorPlacement}
          {...validationProps}
        />
      </MaybeInputControl>
      {modalProps.show && !readonly && (
        <FileLibraryModal
          source={source}
          files={files}
          initialFiles={initialFiles}
          onChange={onChange}
          modalProps={modalProps}
          modal={modal}
          publicFile={publicFile}
          disabled={disabled}
          viewable={viewable}
          field={field}
          hasUnsavedConfirmation
          show
        />
      )}
      {modalProps.show && readonly && (
        <ReadonlyFileLibraryModal
          files={files}
          onHide={modal.hide}
          viewable={viewable}
          {...modalProps}
        />
      )}
    </>
  );
};

OutlineFileInput.defaultProps = {
  label: null,
  placeholder: <DefaultPlaceholder />,
  id: '',
  className: '',
  error: null,
  disabled: false,
  shrink: null,
  sizing: null,
  viewable: true,
  errorPlacement: undefined,
  inModal: null,
  readonly: false,
  showVisibleIcon: true,
};
export default OutlineFileInput;
