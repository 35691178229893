import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { borderRadii, breakpoints, layers, resetButtonCss } from 'app/spacing';
import { OutlineInputLayout } from 'components/Inputs/TextInput/Outline';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { appBackground, grayScale } from 'app/colors';
import AddButton from 'components/Button/AddButton';
import BasicModal from 'components/Modals/presets/BasicModal';
import KizenTypography from 'app/kizentypo';
import { _modalSize } from 'components/Modals';
import IconButton from 'components/Kizen/IconButton';

export const StyledLinkWrapper = styled.div`
  max-width: calc(100vw - 144px);
  overflow: hidden;
`;

export const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const StyledOutlineInputLayout = styled(OutlineInputLayout)`
  margin-top: 12px;
  padding: 0;
`;

export const SummaryInputWrapper = styled.button`
  ${resetButtonCss}
  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}

  display: flex;
  flex: 1;
  width: 100%;

  > div {
    height: 100%;
  }

  > div:first-of-type {
    display: flex;
    flex: 1;
    align-content: center;
  }

  ${({ showVisibleIcon }) =>
    showVisibleIcon &&
    css`
      > div:last-of-type {
        display: flex;
        flex: 0 0 20px;

        align-content: center;
        justify-content: flex-end;
      }
    `}
`;

export const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: calc(100vh - 244px);
  position: relative;
`;

export const TableWrapper = styled.div`
  width: 100%;
  flex: 1;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  overflow-y: hidden;
  flex-direction: column;
  position: relative;
  height: 100%;
  > div {
    position: relative;
    height: 100%;
  }
  min-height: 135px; // create some height for the pagination menu
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  flex: 0 0 45px;
  padding-top: 20px;
`;

export const StyledTableScrollContainer = styled(TableScrollContainer)`
  height: 100%;
  & .gradient-vertical-container {
    max-width: 100%;
    width: 100%;
  }
`;

export const StyledAddButton = styled(AddButton)`
  margin-left: auto;
  margin-right: 0;
  padding-right: 6px;
  position: absolute;
  bottom: -49px;
  right: 9px;
  z-index: ${layers.popoverInModal};
  @media screen and (max-width: ${breakpoints.lg}px) {
    bottom: unset;
    right: 0;
    top: -80px;
    padding-right: 0;
  }
`;

export const StyledFieldInputWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 14px;
  box-shadow: 0 18px 26px rgba(0, 0, 0, 0.03);
  z-index: 10000;
  @media screen and (max-width: ${breakpoints.lg}px) {
    top: -53px;
    right: 0;
  }
`;

export const StyledModal = styled(BasicModal)`
  @media screen and (max-width: ${_modalSize.medium - 1}px) {
    && .modal-dialog {
      width: 100%;
      max-width: calc(100% - 30px);
    }
  }
  && .modal-body {
    overflow: visible;
  }
`;

export const StyledAddButtonWrapper = styled.div`
  position: relative;
`;

export const StyledModalHeader = styled(KizenTypography)`
  text-align: center;
  width: 100%;
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    min-width: 100px;
    flex-shrink: 0;
    margin-top: 13px;
  }
`;

export const StyledModalHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (max-width: ${_modalSize.medium - 1}px) {
    justify-content: center;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 11px;
  }
`;

export const StyledSearchInputWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 200px;
  flex-shrink: 0;
  @media screen and (max-width: ${breakpoints.lg}px) {
    padding-right: 15px;
    margin-right: auto;
    position: static;
    width: 175px;
  }
`;

export const StyledSummary = styled(KizenTypography)`
  background-color: ${appBackground};
  border-radius: ${borderRadii.small};
  padding: 9px 12px;
  margin-top: 20px;
  & > p {
    display: block;
  }
`;

export const StyledDeleteButton = styled(IconButton)`
  margin-left: 12px;
`;
