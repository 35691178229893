import allSteps from '../steps';
import UUIDUtil from 'utility/UUID';

const { goToAutomationStep, delay, goal } = allSteps;

export const defaultValidationState = { show: false, type: null };

export const VALIDATION_STATE_TYPES = {
  GO_TO_STEP_LOOP: 'go_to_step_loop',
};

const isGoToStep = (e) => e.type === goToAutomationStep.type;

const parentChecker = (arr, parentKey) => {
  const parentItem = arr.find((e) => e.id === parentKey);
  if (!parentItem) return false;

  if (parentItem.type === delay.type || parentItem.type === goal.type)
    return true;

  if (!parentItem.parentKey) return false;
  return parentChecker(arr, parentItem.parentKey);
};

const buildBranches = (steps) => {
  const nodes = new Map();

  const worker = (arr, i, totalArr) => {
    const parentItem = arr.find((e) => e.id === i.parentKey);
    const total = parentItem ? totalArr.concat(parentItem) : totalArr;
    if (!parentItem) return total;
    return worker(arr, parentItem, total);
  };

  steps.forEach((el) => {
    nodes.set(el.id, worker(steps, el, [el]).reverse());
  });

  return nodes;
};

const checkBranches = (branches, steps, id, next, total) => {
  const ways = Array.from(branches.entries())
    .filter(([, v]) => v.some((e) => e.id === next.id) && v.some(isGoToStep))
    .flatMap(([, v]) => [...v].splice(v.findIndex((e) => e.id === next.id)));

  const newTotal = total.concat(ways);

  const inLoop = newTotal.find((el) => el.id === id);
  const nextGoTo = newTotal.find(isGoToStep);

  if (!nextGoTo || nextGoTo.details.triggerKey) return null; // that means we jumps to trigger

  const nextStep = steps.find((el) => el.id === nextGoTo.details.stepKey.value);
  const nextStepInLoop = newTotal.find((el) => el.id === next.id);

  if (inLoop || nextStepInLoop) return newTotal;

  // if the nextStep is the same as next, quit to avoid an infinite loop
  if (next?.id === nextStep?.id) return null;

  return checkBranches(branches, steps, id, nextStep, newTotal);
};

/**
 * function check all gotoStep which goto another actions in the end we build full way step by step
 * if we meet a "goto", we find out next step, then we check all possible brunches for this step and find out if some branch contain another "goto" or same "goto"
 * if we meet same "goto" = we have a circle, and we should validate this
 * if we meet another "goto" = repeat first step
 * if we don't meet any "goto" and steps ended, we are good
 *
 * Our main goal find out "goto" which will be inside a circle of steps
 * @param {Array} all steps
 * @param {Object} step item goto
 * @param {Map} all possible branches based on steps
 * @returns {Boolean} validation result
 */

const goToActionChecker = (steps, item, branches) => {
  // find out next node after goto_step
  const nextItem = steps.find((el) => el.id === item.details.stepKey.value);
  const ways = Array.from(branches.entries())
    .filter(
      ([, v]) => v.some((e) => e.id === nextItem.id) && v.some(isGoToStep)
    ) // find out in which branches this node exists and return branches with goto_step
    .map(([, v]) => [...v].splice(v.findIndex((e) => e.id === nextItem.id))); // cut and flat

  return ways
    .map((v) => {
      const inLoop = v.find((el) => el.id === item.id);
      const nextGoTo = v.find(isGoToStep);
      if (!nextGoTo || nextGoTo.details.triggerKey) return null; // that means we jumps to trigger
      const next = steps.find((el) => el.id === nextGoTo.details.stepKey.value);
      const nextStepInLoop = v.find((el) => el.id === next.id);

      if (inLoop || nextStepInLoop) return [item].concat(v);
      return [item].concat(checkBranches(branches, steps, item.id, next, v));
    })
    .reduce((acc, el) => {
      if (acc) return acc; // skip if we already had failed before
      if (!el || el.includes(null)) return false;
      return !el.some((e) => e.type === delay.type || e.type === goal.type);
    }, false);
};

export const VALIDATION = {
  [VALIDATION_STATE_TYPES.GO_TO_STEP_LOOP]: ({ steps }) => {
    const branches = buildBranches(steps);

    // get all the goto steps
    return steps.filter(isGoToStep).reduce((acc, item) => {
      // found already bail early
      if (acc) return acc;

      if (item.details.triggerKey) {
        // if goto_step jumps to trigger
        return !parentChecker(steps, item.parentKey);
      }
      // check the action steps
      return goToActionChecker(steps, item, branches);
    }, false);
  },
};

export const isValidStepId = (stepId) =>
  //should match either a uuid generated by BE or new step id genereted by FE as `new.${step.type}.${Date.now()}`
  UUIDUtil.validate(stepId) || /^new\.[a-z_]*\.\d{13,16}$/.test(stepId);
