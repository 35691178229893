import { Button } from '@kizen/kds/Button';
import { useEditor } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import { TextEditorToolbarSection } from '../../styles';

export const HistoryToolbarSection = () => {
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  if (!editor) return null;

  return (
    <TextEditorToolbarSection ref={ref} gap={15} {...props}>
      <Button
        variant="text"
        color="inherit"
        rightIcon="text-undo"
        disabled={!editor.can().undo()}
        onClick={() => {
          editor.chain().focus().undo().run();
        }}
      />
      <Button
        variant="text"
        color="inherit"
        rightIcon="text-redo"
        disabled={!editor.can().redo()}
        onClick={() => {
          editor.chain().focus().redo().run();
        }}
      />
    </TextEditorToolbarSection>
  );
};
