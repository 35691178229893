import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '__components/Kizen/IconButton';
import {
  StyledDiv,
  StyledRequiredSwitch,
  StyledVariableCard,
  StyledVariableWarpper,
} from './styles';
import Icon from '__components/Kizen/Icon';
import { grayScale } from '__app/colors';
import { IconSizing } from '__components/Kizen/Icon';
import Select from '__components/Inputs/Select';
import {
  AdditionalVariable,
  GenericOption,
  SmartConnectorFlowErrors,
  SmartConnectorMetaData,
  VariableDataType,
} from '__pages/SmartConnectors/types';
import { NameInput } from './NameInput';
import { useErrors } from './useErrors';
import { ValueInput } from './ValueInput';

const buttonColor = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

export type OnChangeAdditionalVariableHandler = ({
  id,
  prop,
  value,
}: {
  id: string;
  prop: keyof AdditionalVariable;
  value: AdditionalVariable[keyof AdditionalVariable];
}) => AdditionalVariable[keyof AdditionalVariable];

export type VarsByHeaders = Record<string, AdditionalVariable>;

type AdditionalVariableSettingsProps = {
  variable: AdditionalVariable;
  index: number;
  dataSourceOptions: GenericOption[];
  onChange: OnChangeAdditionalVariableHandler;
  onDelete: (id: string, index: number) => void;
  indexToScroll: null | number;
  setIndexToScroll: Dispatch<SetStateAction<null | number>>;
  metaData: SmartConnectorMetaData;
  errors: SmartConnectorFlowErrors;
  setErrors: Dispatch<SetStateAction<SmartConnectorFlowErrors>>;
};

export const AdditionalVariableSettings = ({
  variable,
  index,
  dataSourceOptions,
  onChange,
  onDelete,
  indexToScroll,
  setIndexToScroll,
  metaData,
  errors,
  setErrors,
}: AdditionalVariableSettingsProps) => {
  const { t } = useTranslation();

  const {
    id,
    name,
    data_type,
    data_source,
    required,
    input_format,
    output_format,
    value,
  } = variable;

  const { hasError, variableError, errorKey, validate, clearError, setError } =
    useErrors({ errors, setErrors, index });

  const variableMeta = useMemo(() => {
    return metaData.execution_variables.variable_data_types.find(
      ({ value }) => value === data_type
    );
  }, [metaData, data_type]);

  const onDeleteVariable = () => {
    onDelete(id, index);
  };

  const onChangeName = (value: string) => {
    const result = onChange({ id, prop: 'name', value });
    if (result) {
      clearError('name');
    }
    return result as string;
  };

  const onChangeDataType = ({ value }: { value: VariableDataType }) => {
    onChange({ id, prop: 'data_type', value });
    clearError('data_type');
    clearError('value');
  };

  const onChangeDataSource = ({ value }: { value: string }) => {
    onChange({ id, prop: 'data_source', value });
    clearError('data_source');

    if (!name) {
      onChangeName(value);
    }
  };

  const onChangeOutputFormat = ({ value }: { value: string }) => {
    onChange({ id, prop: 'output_format', value });
    clearError('output_format');
  };

  const onChangeRequired = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ id, prop: 'required', value: e.target.checked });
    clearError('required');
  };

  const onChangeValue = (value: string, isValid: boolean = true) => {
    onChange({ id, prop: 'value', value });
    if (isValid) {
      clearError('value');
    }
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorKey) {
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [errorKey]);

  useEffect(() => {
    if (indexToScroll === index) {
      setIndexToScroll(null);
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [indexToScroll, index, setIndexToScroll]);

  return (
    <StyledVariableWarpper ref={wrapperRef}>
      <IconButton
        title={t('Delete')}
        sizing="dense"
        color={buttonColor}
        onClick={onDeleteVariable}
      >
        <IconSizing size="15px">
          <Icon icon="trash" />
        </IconSizing>
      </IconButton>
      <StyledVariableCard hasError={hasError}>
        <StyledDiv width={180}>
          <NameInput
            value={name}
            onChange={onChangeName}
            error={variableError?.name}
            validate={errorKey === 'name' ? validate : undefined}
          />
        </StyledDiv>
        <StyledDiv width={70}>
          <StyledRequiredSwitch
            label={t('Required?')}
            checked={required}
            onChange={onChangeRequired}
            textPlacement="regular-top"
            removeMargin
          />
        </StyledDiv>
        <StyledDiv width={180}>
          <Select
            value={data_type}
            options={metaData.execution_variables.variable_data_types}
            label={t('Variable Type')}
            placeholder={t('Choose Variable Type')}
            onChange={onChangeDataType}
            error={variableError?.data_type}
            validate={errorKey === 'data_type' ? validate : undefined}
          />
        </StyledDiv>
        <StyledDiv width={160}>
          <Select
            value={data_source}
            options={dataSourceOptions}
            label={t('Data Source')}
            placeholder={t('Choose Column')}
            onChange={onChangeDataSource}
            error={variableError?.data_source}
            validate={errorKey === 'data_source' ? validate : undefined}
          />
        </StyledDiv>
        <StyledDiv width={variableMeta?.meta.output_format.length ? 237 : 437}>
          <ValueInput
            value={value}
            onChange={onChangeValue}
            error={variableError?.value}
            validate={errorKey === 'value' ? validate : undefined}
            dataType={data_type}
            inputFormat={input_format}
            setError={setError}
          />
        </StyledDiv>
        {variableMeta?.meta.output_format.length ? (
          <StyledDiv width={180}>
            <Select
              value={output_format}
              options={variableMeta?.meta.output_format || []}
              label={t('Output Variable Format')}
              placeholder={t('Choose Output Format')}
              onChange={onChangeOutputFormat}
              error={variableError?.output_format}
              validate={errorKey === 'output_format' ? validate : undefined}
            />
          </StyledDiv>
        ) : null}
      </StyledVariableCard>
    </StyledVariableWarpper>
  );
};
