import KizenTypography from 'app/kizentypo';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavBarPreviewArea } from '../../styles';
import { serializeToKey } from '../../utils';
import CustomNavigation from 'components/CustomNavigation';
import useWidthMeasurement from 'hooks/useWidthMeasurement';
import { useWindowSize } from 'app/spacing';
import useToolbarAccessFiltering from '../../hooks/useToolbarAccessFiltering';
import TwoColumnContainer from 'components/MultiColumnBuilder/components/TwoColumnContainer';
import ToolbarBuilderOption from '../Option';

const ToolbarBuilder = ({
  rightColumnItems,
  optionsSearchTerm,
  setOptionsSearchTerm,
  leftColumnBodyProps,
  leftColumnProps,
  rightColumnBodyProps,
  rightColumnProps,
  leftColumnHeaderProps,
  rightColumnHeaderProps,
}) => {
  const { t } = useTranslation();

  const { ref, width } = useWidthMeasurement();

  const filterToolbar = useToolbarAccessFiltering();

  const layout = useMemo(
    () => filterToolbar(rightColumnItems),
    [rightColumnItems, filterToolbar]
  );

  const { width: windowWidth } = useWindowSize();
  const key = useMemo(
    () => serializeToKey(layout, windowWidth),
    [layout, windowWidth]
  );

  return (
    <>
      <KizenTypography weight="bold">{t('Preview')}</KizenTypography>

      <NavBarPreviewArea ref={ref}>
        <CustomNavigation
          layout={layout}
          parentWidth={width + 10}
          hideLogo
          flat
          readOnly
          key={key}
        />
      </NavBarPreviewArea>
      <TwoColumnContainer
        leftColumnHeaderProps={leftColumnHeaderProps}
        leftHeaderText={t('Available Options (Drag to Add)')}
        rightHeaderText={t('Your Toolbar')}
        searchable
        searchPlaceholder={t('Find Options')}
        searchTerm={optionsSearchTerm}
        onChangeSearchTerm={setOptionsSearchTerm}
        leftColumnProps={leftColumnProps}
        leftColumnBodyProps={leftColumnBodyProps}
        rightColumnHeaderProps={rightColumnHeaderProps}
        rightColumnBodyProps={rightColumnBodyProps}
        rightColumnProps={rightColumnProps}
        renderOption={(props) => {
          return <ToolbarBuilderOption {...props} />;
        }}
      />
    </>
  );
};

export default ToolbarBuilder;
