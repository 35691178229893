import styled from '@emotion/styled';
import { useCallback, useRef } from 'react';
import {
  ImageSettingsRowOne,
  ImageSettingsRowTwo,
} from './DefaultContentSettings';
import {
  addFilter,
  clearDynamicContentError,
  createFilter,
  deleteRule,
  moveRuleDown,
  moveRuleUp,
  setDynamicAltText,
  setDynamicHasLink,
  setDynamicImage,
  setDynamicHeight,
  setDynamicWidth,
  setDynamicPosition,
  setDynamicSize,
  setDynamicUnit,
  setDynamicLink,
  setFilterType,
  setDynamicDimension,
  setDynamicMaxWidth,
  setDynamicMaxHeight,
  deleteFilter,
  setGroups,
  setOperation,
  opsForFilter,
} from './state';
import { CardRowNoPadding } from './ts-components';

import { defFilter } from '@kizen/filters/filter';
import { DynamicContentCard } from './ContentCard';

const ImageSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

export const DynamicImageContentCard = ({
  id,
  index,
  scrollSelf,
  modalLayer,
  customObjectId,
  groupOptions,
  loadingGroups,
  dispatch,
  ruleType,
  hasLink,
  position,
  size,
  unit,
  width,
  height,
  maxWidth,
  maxHeight,
  link,
  alt,
  src,
  fileId,
  name,
  dimension,
  containerWidth,
  naturalHeight,
  naturalWidth,
  linkError,
  showErrors,
  isFirst,
  isLast,
  filterErrors,
  operation,
  groups,
  filterType,
  filterMetadata,
  filterVariables,
  ...rest
}) => {
  const ref = useRef();

  const handleReorderUp = () => {
    if (!isFirst) dispatch(moveRuleUp(id));
  };

  const handleReorderDown = () => {
    if (!isLast) dispatch(moveRuleDown(id));
  };

  const handleDelete = () => {
    dispatch(deleteRule(id));
  };

  const hanldeCreateFilter = (filterIndex, type) => {
    const filterConfig = filterMetadata.filters[type];
    const filter = defFilter(filterConfig, filterMetadata, {
      vars: filterVariables,
    });
    const filterData = {
      filter,
      type,
      ops: opsForFilter(filter, dispatch),
    };
    dispatch(createFilter(id, filterData, filterIndex));
  };

  const refCallback = useCallback(
    (node) => {
      if (scrollSelf && !ref.current) {
        node.scrollIntoView({ behavior: 'smooth' });
      }
      ref.current = node;
    },
    [scrollSelf]
  );

  return (
    <DynamicContentCard
      ref={refCallback}
      customObjectId={customObjectId}
      customObjectFetchUrl="client"
      filterType={filterType}
      filterErrors={filterErrors}
      initialGroups={groups}
      modalLayer={modalLayer}
      operation={operation}
      onReorderUp={handleReorderUp}
      onReorderDown={handleReorderDown}
      onDelete={handleDelete}
      onFilterTypeChange={(type) => {
        dispatch(setFilterType(id, type));
      }}
      groupOptions={groupOptions}
      loadingGroups={loadingGroups}
      onCreateFilter={hanldeCreateFilter}
      onAddFilter={() => {
        dispatch(addFilter(id));
        dispatch({ type: 'render-filters' });
      }}
      onDeleteFilter={(filterIndex) => dispatch(deleteFilter(id, filterIndex))}
      onGroupsChange={(groups) => dispatch(setGroups(id, groups))}
      onOperationChange={(operation) => dispatch(setOperation(id, operation))}
      {...rest}
    >
      <ImageSettingsContainer>
        <ImageSettingsRowOne
          Row={CardRowNoPadding}
          dispatch={dispatch}
          position={position}
          size={size}
          unit={unit}
          width={width}
          height={height}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          src={src}
          fileId={fileId}
          name={name}
          dimension={dimension}
          containerWidth={containerWidth}
          naturalHeight={naturalHeight}
          naturalWidth={naturalWidth}
          onImageChange={({ id: file_id, name, url }) =>
            dispatch(setDynamicImage(id, { id: file_id, name, url }))
          }
          onPositionChange={({ value }) =>
            dispatch(setDynamicPosition(id, value))
          }
          onSizeChange={(value) => dispatch(setDynamicSize(id, value))}
          onUnitChange={(value) => dispatch(setDynamicUnit(id, value))}
          onWidthChange={(value) => dispatch(setDynamicWidth(id, value))}
          onHeightChange={(value) => dispatch(setDynamicHeight(id, value))}
          onMaxWidthChange={(value) => dispatch(setDynamicMaxWidth(id, value))}
          onMaxHeightChange={(value) =>
            dispatch(setDynamicMaxHeight(id, value))
          }
          onDimensionChange={(value) =>
            dispatch(setDynamicDimension(id, value))
          }
        />
        <ImageSettingsRowTwo
          Row={CardRowNoPadding}
          dispatch={dispatch}
          hasLink={hasLink}
          link={link}
          alt={alt}
          linkError={linkError}
          showErrors={showErrors}
          onHasLinkChange={(value) => {
            dispatch(setDynamicHasLink(id, value));
          }}
          onLinkChange={(value) => {
            dispatch(setDynamicLink(id, value));
            dispatch(clearDynamicContentError(index));
          }}
          onAltTextChange={(value) => dispatch(setDynamicAltText(id, value))}
        />
      </ImageSettingsContainer>
    </DynamicContentCard>
  );
};
