import {
  dateOffsetValuesTranslated,
  _checkDeleted,
  _checkListDeleted,
  displayNameDescriptor,
  nameDescriptor,
} from 'pages/AutomationEngine/helpers';

import { isArray } from 'lodash/lang';
import {
  camelToSnakeCase,
  getIdFullNameWithEmail,
  getOptionValue,
  snakeToCamelCase,
  namedToOptionDeleted,
  namedToOptionError,
  fullNameToOptionDeleted,
  displayNameToOption,
  fullNameToOptionError,
} from 'services/helpers';
import {
  SA_TYPE_LAST_ACTIVE_TEAM_MEMBER_SPEC_ROLE,
  SA_TYPE_SPEC_ROLE,
  SA_TYPE_ROUND_ROBIN_ROLE,
  SA_TYPE_ROUND_ROBIN_TEAM_MEMBERS,
} from './common';

const typesWithRole = [
  SA_TYPE_LAST_ACTIVE_TEAM_MEMBER_SPEC_ROLE,
  SA_TYPE_ROUND_ROBIN_ROLE,
  SA_TYPE_SPEC_ROLE,
];
const forApi = (step) => {
  const orgDetails = { ...step.details };
  const scheduleType = getOptionValue(orgDetails.schedule.type);
  const scheduleData = Object.keys(orgDetails.schedule)
    .filter((key) => key === scheduleType)
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: orgDetails.schedule[key],
      };
    }, {});
  delete orgDetails.schedule;
  const notifications = orgDetails?.notifications.length
    ? {
        notifications: [...orgDetails.notifications],
      }
    : {};

  delete orgDetails.notifications;
  const additionalAssociationRelationshipFields =
    orgDetails.additionalAssociationRelationshipFields
      .filter(({ value }) => value != null)
      .map(getOptionValue) || [];

  const api = {
    details: {
      ...orgDetails,
      activityTypeId: getOptionValue(orgDetails.activityType),
      assignedTo: {
        ...Object.fromEntries(
          Object.entries(orgDetails.assignedTo)
            .filter(([_, v]) => v != null)
            .map(([k, v]) => [
              k,
              isArray(v) ? v.map((i) => getOptionValue(i)) : getOptionValue(v),
            ])
            .filter(([_, v]) => v != null && v !== '')
        ),
      },
      schedule: {
        type: camelToSnakeCase(scheduleType),
        ...scheduleData,
      },
      ...notifications,
      additionalAssociationRelationshipFields,
    },
  };
  return api;
};
const forFlow = (apiObject, addErrorMessage, messageDictionary) => {
  let errors = {};
  const { activityType, assignedTo, schedule, ...rest } = apiObject;
  const {
    assignedTo: { role = {}, assignmentType = '' },
    schedule: { type = '' },
    additionalAssociationRelationshipFields = [],
  } = apiObject;

  errors = {
    ...errors,
    ..._checkDeleted({
      messageDictionary,
      addErrorMessage,
      objectToCheck: activityType,
      descriptorCallback: nameDescriptor,
      objectPath: 'activityType',
      objectErrorTrans: namedToOptionError,
    }),
  };

  if (typesWithRole.includes(assignmentType)) {
    errors = {
      ...errors,
      ..._checkDeleted({
        messageDictionary,
        addErrorMessage,
        objectToCheck: role,
        descriptorCallback: nameDescriptor,
        itemName: messageDictionary.deletedRole,
        objectPath: 'assignedTo.role',
        objectErrorTrans: namedToOptionError,
      }),
    };
  }

  errors = {
    ...errors,
    ..._checkDeleted({
      messageDictionary,
      addErrorMessage,
      objectToCheck: assignedTo.employee,
      descriptorCallback: displayNameDescriptor,
      objectPath: 'assignedTo.employeeId',
      objectErrorTrans: fullNameToOptionError,
    }),
  };

  if (assignmentType === SA_TYPE_ROUND_ROBIN_TEAM_MEMBERS) {
    errors = {
      ...errors,
      ..._checkListDeleted({
        messageDictionary,
        addErrorMessage,
        parentObject: assignedTo.employees,
        descriptorCallback: displayNameDescriptor,
        itemName: messageDictionary.teamMember,
        objectPath: 'assignedTo.employeeIds',
        objectErrorTrans: (o) => o,
        checkChildren: true,
      }),
    };
  }

  const flow = {
    ...rest,
    activityType: namedToOptionDeleted(activityType),
    assignedTo: {
      ...assignedTo,
      employeeIds: assignedTo.employees.map(getIdFullNameWithEmail),
      employeeId: fullNameToOptionDeleted(assignedTo.employee || {}),
      roleId: role ? namedToOptionDeleted(role) : null,
      fieldId: displayNameToOption(assignedTo.field || {}),
      assignmentType:
        messageDictionary.dictionaryAssignToTypeOptions[assignmentType],
    },
    schedule: {
      ...schedule,
      type: messageDictionary.dictionaryDateDueTypeOptions[
        snakeToCamelCase(type)
      ],
      onOrAroundDate: apiObject.schedule.onOrAroundDate || {
        dateOffset: dateOffsetValuesTranslated((str) => str)[0].value,
      },
    },
    additionalAssociationRelationshipFields:
      additionalAssociationRelationshipFields.map(
        ({ displayName, id, rest }) => ({
          label: displayName,
          value: id,
          ...rest,
        })
      ),
    errors,
  };

  return flow;
};
export const scheduleActivityDTO = {
  forApi,
  forFlow,
};
