import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useLength } from 'hooks/useLength';
import { BasicRichTextEditor } from 'components/WYSIWYG';
import {
  StyledLabelWrapper,
  StyledLabel,
  StyledLabelRowWrapper,
  LimitLabel,
} from './styles';

const customizeDefaultOptions = {
  showSelectComponent: true,
  showCountComponent: true,
  disableFocus: false,
  wrapperClassName: '',
  mergeFields: null,
};

const getTextFromContent = (editor) => {
  return editor
    .getJSON()
    .content.map(
      ({ content }) =>
        content?.reduce((acc, { text }) => acc + (text || ''), '') || ''
    )
    .join('\n');
};

const dummyFn = () => null;

const TextTemplater = ({
  title: titleProp,
  textareaOptions,
  onSave,
  defaultValue,
  customize,
  toggleDisabled,
  disabled,
  appendMergeFieldExtraSpace = true,
  oneLineLayout = false,
  onChange = dummyFn,
  onToggleEncoding,
  encodeMergeFieldsInBody,
  dataQA,
}) => {
  const { t } = useTranslation();
  const title = titleProp || t('Text Message Body');
  const [textareaValue, setTextareaValue] = useState(defaultValue || '');
  const saveFn = useRef(onSave);
  useEffect(() => {
    saveFn.current = onSave;
  });

  const customizeOptions = useMemo(
    () =>
      customize?.mergeFieldProps?.mergeFields
        ? {
            ...customizeDefaultOptions,
            ...customize,
            mergeFields: customize.mergeFieldProps.mergeFields,
          }
        : { ...customizeDefaultOptions, ...customize },
    [customize]
  );

  const [
    {
      maxLength,
      singleMessageCount,
      maxLengthForSingleMessage,
      countOfMessages,
      maxCountOfMessages,
      totalCount,
    },
    updateEvent,
  ] = useLength(textareaValue.length);

  useEffect(() => {
    // we need to update characters counter
    if (toggleDisabled && totalCount > maxLength !== disabled) toggleDisabled();
  }, [disabled, toggleDisabled, maxLength, totalCount]);

  const textareaChangeEvent = useCallback(
    (editor) => {
      const text = getTextFromContent(editor);
      const html = editor.getHTML();
      saveFn.current &&
        saveFn.current({
          content: editor.getText(),
          htmlContent: html,
        });
      setTextareaValue(text);
      updateEvent(text);
    },
    [updateEvent]
  );

  const overflow = totalCount - maxLength;

  return (
    <StyledLabelWrapper
      className={customizeOptions.wrapperClassName}
      oneLineLayout={oneLineLayout}
      data-qa={dataQA}
    >
      {title && <StyledLabel>{title}</StyledLabel>}
      <BasicRichTextEditor
        autofocus={!customizeOptions.disableFocus}
        initialValue={textareaValue}
        onChange={({ editor }) => {
          onChange({ editor });
          textareaChangeEvent(editor);
        }}
        onInit={({ editor }) => {
          if (defaultValue) {
            updateEvent(getTextFromContent(editor));
          }
        }}
        appendMergeFieldExtraSpace={appendMergeFieldExtraSpace}
        enableMergeFields={customizeOptions.showSelectComponent}
        mergeFields={customizeOptions.mergeFields}
        enableLists={false}
        enableTextStyles={false}
        {...(oneLineLayout
          ? { ...textareaOptions, initialHeight: 36 }
          : textareaOptions)}
        onToggleEncoding={onToggleEncoding}
        encodeMergeFieldsInBody={encodeMergeFieldsInBody}
      />

      {customizeOptions.showCountComponent && (
        <StyledLabelRowWrapper>
          <LimitLabel error={overflow > 0}>
            {overflow <= 0
              ? `${singleMessageCount}/${maxLengthForSingleMessage} ${t(
                  'characters left in message'
                )} ${countOfMessages} ${t('of')} ${maxCountOfMessages}`
              : `${t('You are')} ${overflow} ${
                  overflow === 1 ? t('character') : t('characters')
                } ${t('over the message limit')}`}
          </LimitLabel>
          <StyledLabel>
            {t('{{totalCount}} of {{maxLength}} total characters', {
              totalCount,
              maxLength,
            })}
          </StyledLabel>
        </StyledLabelRowWrapper>
      )}
    </StyledLabelWrapper>
  );
};

TextTemplater.propTypes = {
  onSave: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textareaOptions: PropTypes.object,
  error: PropTypes.bool,
  customize: PropTypes.object,
  trigOnValue: PropTypes.string,
};

TextTemplater.defaultProps = {
  title: null,
  defaultValue: '',
  textareaOptions: {},
  error: false,
  customize: {},
  trigOnValue: '',
};

export default TextTemplater;
