import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MyQuickFiltersToolBar } from '../components/ToolBar';
import { CardTitle, ContentCard, SwitchControl } from '../styles';
import { MobileFallback } from 'pages/Common/MobileFallback';
import QuickFiltersService from 'services/QuickFiltersService';
import useModal from 'components/Modals/useModal';
import { SharingAccessModal } from 'components/Modals/SharingAccessModal';
import { getQuickFiltersPayload, repairSettings } from '../helpers';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import { ChooseTemplateModal } from 'components/Modals/ApplyTemplateModal';
import { CONFIG_KEY } from '../constants';
import { useDraggableColumns } from '../hooks/useDraggableColumns';
import { DraggableColumns } from '../components/DraggableColumns';
import useField from 'hooks/useField';
import ConfirmNavigationWithActionModal from 'components/Modals/presets/ConfirmNavigationWithAction';
import { useDirtyState } from '../hooks/useDirtyState';
import { PageSizing } from 'components/Layout/PageContentWidth';
import { useApplyTemplateByParams } from '../hooks/useApplyTemplateByParams';
import { useSharingAccessModal } from '../hooks/useSharingAccessModal';
import { toastVariant, useToast } from 'components/ToastProvider';
import Switch from 'components/Kizen/Switch';
import { enrichAndRepairQuickFilterSettings } from 'store/utilities';
import { deferExecution } from 'utility/defer';

export const MyQuickFilters = ({
  onSubmitQuickFilters,
  quickFilterSettings,
  quickFilteringFacets,
  categorizedFields,
  fields,
  disabled,
  isMobile,
  model,
  goBackPath,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();

  const history = useHistory();
  const [settings, setSettings] = useField(quickFilterSettings);
  const [applyingTemplate, setApplyingTemplate] = useState(false);
  const [facets, setFacets] = useState(quickFilteringFacets || false);

  const setQuickFilteringFacets = (e) => {
    setFacets(e.target.checked);
  };

  const { leftItems, rightItems, ...DnDProps } = useDraggableColumns(
    settings,
    categorizedFields,
    fields
  );

  const [initialSettings, setInitialSettings] = useState(
    getQuickFiltersPayload(rightItems)
  );

  const handleSave = async () => {
    const nextSettings = getQuickFiltersPayload(rightItems);
    await onSubmitQuickFilters({
      quickFilterSettings: nextSettings,
      quickFilteringFacets: facets,
    });
    setInitialSettings(nextSettings);
  };

  const handleSubmit = async () => {
    await handleSave();
    deferExecution(() => {
      history.push(goBackPath);
    });
  };

  const dirtySettings = useDirtyState(initialSettings, rightItems);
  const dirty = dirtySettings || facets !== quickFilteringFacets;

  const onSubmitSharingAccessModal = useCallback(
    async ({ name, permissions, isPrivate }) => {
      await QuickFiltersService.createTemplate(
        name,
        {
          quick_filter_settings: getQuickFiltersPayload(rightItems, leftItems),
          quick_filtering_facets: facets,
        },
        {
          ...buildSharingSettingsForAPI(permissions).sharing_settings,
          private: isPrivate,
        },
        model?.id
      );
      showToast({
        message: t(
          "'{{name}}' Quick Filter template has been saved successfully.",
          {
            name,
          }
        ),
        variant: toastVariant.SUCCESS,
      });
    },
    [rightItems, leftItems, model?.id, showToast, facets, t]
  );

  const { sharingAccessModalProps, onShowSharingAccessModal } =
    useSharingAccessModal(onSubmitSharingAccessModal);

  const handleApplyTemplate = useCallback(
    async (id) => {
      setApplyingTemplate(true);

      const template = await QuickFiltersService.getTemplate(id, model?.id);

      if (template?.[CONFIG_KEY]?.quick_filter_settings) {
        const enrichedSettings = await enrichAndRepairQuickFilterSettings(
          repairSettings(template[CONFIG_KEY].quick_filter_settings),
          fields
        );
        setSettings(enrichedSettings);
      }
      setFacets(template?.[CONFIG_KEY]?.quick_filtering_facets ?? false);
      setApplyingTemplate(false);
    },
    [setSettings, model?.id, fields]
  );

  useApplyTemplateByParams(handleApplyTemplate);

  const [chooseTemplateModalProps, { onClick: onShowChooseTemplateModal }] =
    useModal({
      handleSubmit: handleApplyTemplate,
    });

  const fetchProps = useMemo(() => [model?.id], [model?.id]);

  return (
    <PageSizing data-qa="my-quick-filters">
      <MyQuickFiltersToolBar
        onSave={handleSubmit}
        onSaveTemplate={onShowSharingAccessModal}
        onChooseTemplate={onShowChooseTemplateModal}
        disabled={disabled || applyingTemplate}
        disabledSave={!dirty}
        isMobile={isMobile}
        goBackPath={goBackPath}
      />
      <ContentCard>
        <CardTitle title={t('Edit My Quick Filters')} />
        <MobileFallback isMobile={isMobile}>
          <SwitchControl
            label={t('Make Search Faceted')}
            labelInfo={t(
              'Faceting your search will reduce the selectable options to only those that contain results. You will also be able to preview how many results will be returned for each facet.'
            )}
          >
            <Switch checked={facets} onChange={setQuickFilteringFacets} />
          </SwitchControl>
          <DraggableColumns
            leftItems={leftItems}
            rightItems={rightItems}
            fields={fields}
            {...DnDProps}
          />
        </MobileFallback>
      </ContentCard>
      {sharingAccessModalProps.show ? (
        <SharingAccessModal
          {...sharingAccessModalProps}
          isEditing={false}
          disabled={disabled}
          instanceName={t('Quick Filter')}
          showPrivateToggle
        />
      ) : null}
      {chooseTemplateModalProps.show ? (
        <ChooseTemplateModal
          {...chooseTemplateModalProps}
          serviceToUse={QuickFiltersService}
          fetchProps={fetchProps}
          disabled={disabled || applyingTemplate}
          hasDefaultTemplate={false}
        />
      ) : null}
      <ConfirmNavigationWithActionModal
        when={dirty}
        heading={t('Your Template Has Unsaved Changes')}
        buttonText={t('Save')}
        actionBtnColor="green"
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        action={handleSave}
      >
        {t('All unsaved changes will be lost unless you save your filters.')}
      </ConfirmNavigationWithActionModal>
    </PageSizing>
  );
};
