import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import { gutters } from 'app/spacing';

const blue = colorsButton.blue.default;

export const StyledStepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${gutters.spacing(6)}px;
  height: ${gutters.spacing(6)}px;
  border-radius: 50%;
  color: ${(props) => (props.active ? 'white' : grayScale.mediumDark)};
  background-color: ${(props) => (props.active ? blue : grayScale.medium)};
  margin-right: 50px;
  font-size: 11px;
`;

export const StyledStepHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledProgress = styled.div`
  margin-right: ${gutters.spacing(10)}px;
  display: flex;
  justify-content: center;
`;

export const StyledProgressLine = styled.div`
  width: 1px;
  border-right: 1px solid ${(props) => (props.active ? blue : grayScale.medium)};
`;

export const StyledModalSection = styled.div`
  margin-top: ${gutters.spacing(2)}px;
  margin-bottom: ${gutters.spacing(8)}px;
  ${({ last }) => (last ? `margin-bottom: 2px;` : '')}
  & p {
    margin-bottom: ${gutters.spacing(2)}px;
  }
`;

export const StyledWizardParagraph = styled.p`
  font-size: 14px;
  padding-top: ${(props) =>
    typeof props.top === 'number' ? props.top : gutters.spacing(5)}px;

  ${({ disabled }) =>
    disabled
      ? css`
          font-style: italic;
        `
      : ''}
`;

export const StyledWizardBody = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: ${gutters.standard} ${gutters.spacing(4)}px 0px;

  .modal-select,
  .modal-select > div {
    width: 100%;
    max-width: 100%;
  }
  .modal-select > div {
    margin-top: ${gutters.spacing(3)}px;
  }
  .modal-radio .entity.child {
    max-height: 100px;
  }
  .modal-frequency,
  .modal-frequency label,
  .modal-frequency .child {
    max-height: 35px;
  }
`;

export const MetaFilterSummaryWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledWizardField = styled.div`
  ${({ flex }) => (flex ? '' : 'max-width: 600px;')}
  padding-top: ${(props) =>
    typeof props.top === 'number' ? props.top : gutters.spacing(5)}px;

  .wizard-buttons {
    display: flex;
    width: 100%;
  }

  .wizard-buttons button {
    border-radius: 4px;
  }

  .modal-select > div {
    margin-top: 0;
  }

  .modal-select {
    margin-top: 16px;
  }

  .lead-sources__menu-notice--loading {
    font-size: 1.4em;
  }

  ${({ marginTop }) =>
    typeof marginTop === 'number'
      ? css`
          margin-top: ${marginTop}px;
        `
      : ''}

  ${({ gap }) =>
    gap
      ? css`
          gap: ${gap}px;
          display: flex;
          flex-direction: column;
        `
      : ''}
`;

export const StyledHistorySwitch = styled.div`
  display: flex;
  align-items: center;

  .history-switch {
    margin-left: ${({ left }) =>
      typeof left === 'number' ? left : gutters.spacing(2)}px;
  }
`;

export const ChartSelectionWrapper = styled.div`
  display: flex;
  gap: 0 ${({ mobile }) => gutters.spacing(mobile ? 2 : 3)}px;
  width: 100%;

  && div.chartType.child {
    padding: 0;
    padding-bottom: 15px;
    user-select: none;
  }

  && div.chartType.child p {
    margin-top: 2px;
    font-size: 1.1em;
  }

  && div.chartType.child img {
    user-select: none;
    -webkit-user-drag: none;
  }

  & > label {
    // We have to do a bit of complicated math here to get the chart selector to line up
    // well with the buttons in other wizard sections. The +5px is a fixed offset to make sure
    // each button lines up in a vertical line.
    max-width: calc(
      ${({ mobile }) => 100 / (mobile ? 2 : 4)}% -
        ${({ mobile }) => gutters.spacing(mobile ? 2 : 3)}px + 5px
    );
  }
`;
