import styled from '@emotion/styled';
import Cols from 'components/Layout/Cols';
import BuilderCard from 'components/Wizards/CustomObject/components/BuilderCard';
import Loader from 'components/Kizen/Loader';
import { grayScale } from 'app/colors';
import { breakpoints } from 'app/spacing';
import { CONTENT_MAX_WIDTH } from 'components/Layout/PageContentWidth';

export const TabsWrapper = styled.div`
  background-color: ${grayScale.white};
  max-width: 450px;
  margin: 20px auto;
  button:not(:last-of-type) {
    border-right: 1px solid ${grayScale.mediumLight};
  }
  button:focus {
    outline: 0;
  }
  button:after {
    height: 0px;
  }
`;

export const StyledLoader = styled(Loader)`
  padding-bottom: 10%;
`;
export const DesktopBuilderCard = styled(BuilderCard)`
  min-width: ${CONTENT_MAX_WIDTH};
  h3 {
    padding-bottom: 10px;
  }
`;
export const VerticalSpacer = styled.div`
  width: 1px !important;
  height: inherit;
  background-color: ${grayScale.mediumLight};
  min-height: 100%;
  margin-left: 0;
  margin-right: 10px;
`;
export const StyledCols = styled(Cols)`
  display: flex;
  > * {
    width: 100%;
    @media (min-width: ${breakpoints.lg}px) {
      width: calc(
        (100% - 52px) / 2
      ); // 52 means margin 30px + 20px and 2pixes from  VerticalSpacer border
    }
  }
`;
export const StageSettingsSubsectionWrapper = styled.div`
  padding-right: 33px;
`;
