import {
  checkDeleted,
  nameDescriptor,
  displayNameDescriptor,
} from 'pages/AutomationEngine/helpers';
import { SENDER_TYPE } from 'components/MessageBuilder/components';
import { initState } from './index';
import { templateForApp } from 'services/LibraryService';
import {
  getTeamLabel,
  namedToOptionDeleted,
  displayNameToOptionDeleted,
} from 'services/helpers';

import {
  forFlowTeamMember,
  forApiTeamMember,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/notify-member-via-email/notifyMemberViaEmailDTO';

import {
  AddErrorMessage,
  MessageDictionary,
} from 'pages/AutomationEngine/types/automation';

import { ForApiProps, FromApiStep } from './types';

interface GetFullName {
  firstName: string;
  lastName: string;
}

const getFullName = ({ firstName, lastName }: GetFullName) =>
  `${firstName} ${lastName}`.trim();

const forApi = (step: ForApiProps) => {
  const orgDetails = {
    ...{ email: { id: null } },
    ...step.details,
  };

  const {
    sendToContactField,
    email,
    sendFromOwner = false,
    ccTeamMember,
  } = orgDetails;

  const apiCcTeamMember = ccTeamMember ? forApiTeamMember(ccTeamMember) : null;

  const api = {
    details: {
      sendToContactField: sendToContactField?.value,
      email: { id: email.id },
      sendFromOwner,
      ccTeamMember: apiCcTeamMember,
    },
  };

  return api;
};

// last_team_member┃last_team_member_role┃specific_team_member┃business
const forFlow = (
  apiObject: FromApiStep,
  addErrorMessage: AddErrorMessage,
  messageDictionary: MessageDictionary
) => {
  try {
    const { sendToContactField, email: _email, sendFromOwner } = apiObject;
    const { externalAccount } = _email;
    const email = templateForApp(_email);

    checkDeleted(
      messageDictionary,
      addErrorMessage,
      sendToContactField,
      displayNameDescriptor,
      messageDictionary.associatedRole
    );
    const { senderRole, senderTeamMember } = email;

    switch (_email.senderType) {
      case SENDER_TYPE.LAST_ROLE:
        checkDeleted(
          messageDictionary,
          addErrorMessage,
          senderRole,
          nameDescriptor,
          messageDictionary.associatedRole
        );
        break;

      case SENDER_TYPE.TEAM_MEMBER:
        checkDeleted(
          messageDictionary,
          addErrorMessage,
          senderTeamMember,
          getFullName
        );
        break;

      case SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX:
        checkDeleted(
          messageDictionary,
          addErrorMessage,
          externalAccount,
          displayNameDescriptor,
          messageDictionary.integratedInbox
        );
        break;

      // case SENDER_TYPE.LAST_ANY:
      default:
        if (!_email.senderType) {
          addErrorMessage(
            messageDictionary.thereWasanErrorLoadingTheAssociatedItem
          );
        }
        break;
    }

    const ccTeamMember = forFlowTeamMember(
      apiObject.ccTeamMember,
      addErrorMessage,
      messageDictionary
    );

    const flow = {
      sendToContactField: displayNameToOptionDeleted(sendToContactField),
      sendFromOwner: sendFromOwner,
      email: {
        ...email,
        senderRole: senderRole ? namedToOptionDeleted(senderRole) : senderRole,
        senderTeamMember:
          senderTeamMember && !senderTeamMember?.deleted
            ? {
                ...senderTeamMember,
                displayName: getTeamLabel(senderTeamMember),
                fullName: getFullName(senderTeamMember),
              }
            : senderTeamMember,
      },
      ccTeamMember,
    };
    return flow;
  } catch (error) {
    addErrorMessage(messageDictionary.automationFailedToLoad);
    return initState;
  }
};

export const sendRelatedContactEmailDTO = {
  forApi,
  forFlow,
};
