import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { breakpoints, gutters, isMobile, useWindowSize } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import Loader from 'components/Kizen/Loader';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import SurveyService from 'services/SurveyService';
import {
  SubNavigationNavLink,
  SubNavigationNavBar,
} from 'components/Navigation/SubNavigation';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { replaceSurvey } from 'store/surveysPage/surveys.redux';
import navItems from './navigation';
import BuilderModal from './Builder';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';

const MobileContainer = styled.div`
  padding: ${gutters.standard};
  text-align: center;
`;

const Container = styled.div`
  margin-bottom: ${gutters.spacing(4)}px;
`;

const setSurveyTitle = (title, { name = '' }) =>
  name ? `${name} - ${title}` : title;

export default function SurveySettingsPage({ title }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const [noViewPermissions, setNoViewPermissions] = useState(false);
  const { id } = params;
  const { width } = useWindowSize();
  const mobileDisplay = isMobile(width, breakpoints.lg);
  const preReleaseFeature = usePreReleaseFeatures();

  const routeItems = useMemo(() => {
    const forHide = ['messages', 'rules'];

    return preReleaseFeature
      ? navItems
      : Object.keys(navItems)
          .filter((e) => !forHide.includes(e))
          .reduce((acc, el) => ({ ...acc, [el]: navItems[el] }), {});
  }, [preReleaseFeature]);

  const [{ value: { survey } = {} }, fetchData] =
    useAsyncFnKeepLast(async () => {
      try {
        const currentSurvey = await SurveyService.getById(id);
        dispatch(replaceSurvey({ survey: currentSurvey }));
        return {
          survey: currentSurvey,
        };
      } catch (err) {
        const data = err.isAxiosError && err.response && err.response.data;
        const badPermissions = data && data.badPermissions;
        if (badPermissions) {
          setNoViewPermissions(true);
        }
        return false;
      }
    }, [id]);

  useSetTitleOnLoad(title(t), survey, setSurveyTitle);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onGoBack = useCallback(() => {
    const formUrl = navItems.settings.path.replace(':id', id);
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push(formUrl);
    }
  }, [history, id]);

  if ((!survey && !noViewPermissions) || (survey && survey.id !== id)) {
    return <Loader loading />;
  }

  const routeComponents = Object.entries(routeItems)
    .filter(([, route]) => !!route.component)
    .map(([, route], index) => (
      <Route
        key={index}
        path={route.path}
        exact={route.exact !== false}
        render={() => (
          <route.component survey={survey} fetchSurvey={fetchData} />
        )}
      />
    ));

  if (mobileDisplay) {
    return (
      <MobileContainer>
        <KizenTypography>
          {t(
            'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
          )}
        </KizenTypography>
      </MobileContainer>
    );
  }

  return (
    <>
      <SubNavigationNavBar>
        {Object.entries(routeItems).map(([key, route], idx, arr) => {
          const { label } = route;
          const common = {
            isLast: idx === arr.length - 1,
            numberOfLinks: arr.length,
            params,
          };

          if (key === 'build') {
            return (
              <SubNavigationNavLink
                key={label}
                route={route}
                saveLocationOnNavigate
                {...common}
              />
            );
          }

          return <SubNavigationNavLink key={label} route={route} {...common} />;
        })}
      </SubNavigationNavBar>
      <Container>
        <Switch location={location}>{routeComponents}</Switch>
      </Container>
      {/* react-router way of storing a page behind a modal https://v5.reactrouter.com/web/example/modal-gallery */}
      <Route path="/surveys/:id/build">
        <BuilderModal
          surveyId={survey.id}
          surveySlug={survey.slug}
          onGoBack={onGoBack}
        />
      </Route>
    </>
  );
}
