import React from 'react';
import FontIcon from './FontIcon';

export default function CalendarIcon({ icon, size, ...others }) {
  return (
    <FontIcon
      style={{ transform: 'scale(0.9, 0.9)', fontSize: 'inherit' }}
      icon={['fal', 'calendar-day']}
      {...others}
    />
  );
}
