import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile, useWindowSize } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import { getSearchParam, setSearchParams } from 'hooks/useSearchParam';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { camelToSnakeCase, snakeToCamelCase } from 'services/helpers';
import {
  buildPage,
  setPageConfig,
  getAutomations,
  updateAutomationSuccess,
} from 'store/libraryPage/library.redux';
import { setOpenMenuAbove } from 'components/Tables/helpers';
import DesktopLayout from './DesktopLayout';
import { actions } from './columns';
import { useTranslation } from 'react-i18next';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import SentMessageModal from 'components/Modals/SentMessage';
import LibraryService from 'services/LibraryService';
import { messageMapper } from '../../helpers';
import EmailInteractionStatsModal from 'components/Modals/EmailInteractionStats';
import { deferExecution } from 'utility/defer';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

const AutomationsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();
  const isFetching = useSelector((s) => s.libraryPage.isFetching);
  const pageConfig = useSelector((s) => s.libraryPage.pageConfig?.automations);
  const automations = useSelector((s) => s.libraryPage.automations);
  const automationsCount = useSelector((s) => s.libraryPage.automationsCount);
  const [chosenMessage, setChosenMessage] = useState(null);
  const [statsMessage, setStatsMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [directoryPage, setDirectoryPage] = useState(null);
  const [directorySearch, setDirectorySearch] = useState(null);

  const { directories, handleChangeDirectory, parentFolder, currentFolder } =
    useBreadcrumbs({
      name: t('Automations'),
    });
  // This ref handles some wonky state management going on (should be rewritten entirely).
  // - Filter state is stored in redux and folder state comes from the useBreadcrumbs hook.
  // - Local directoryPage state exists because we never store those in the page config.
  // - The table pagination component is reused for all folder tables and has an effect that calls
  //   the handleChangePageNumber when the page number and options don't line up. When this happens
  //   the current folder state is not current since that is updated via a callback passed to in the
  //   redux action and only called at the end of the saga.
  const navigatingBackRef = useRef(false);
  const businessName = useSelector(
    ({ authentication }) => authentication?.chosenBusiness?.name ?? ''
  );

  const automationsWithMeta = useMemo(() => {
    if (!automations) {
      return automations;
    }
    const backToParent = directories.length > 1 && {
      id: parentFolder.id,
      name: `${t('Back to')} '${parentFolder.name}'`,
      type: 'folder', // can be either text or email
      levelUp: true,
      created: null,
    };

    return (backToParent ? [backToParent] : []).concat(
      automations.map(setOpenMenuAbove)
    );
  }, [automations, parentFolder, directories, t]);

  const onChangeDirectory = useCallback(
    (folder) => {
      if (folder?.type !== 'folder') {
        navigatingBackRef.current = true;
      }
      const page = folder?.type === 'folder' ? 1 : null;
      setDirectoryPage(page);
      setDirectorySearch(folder?.type === 'folder' ? '' : null);
      const callback = () => handleChangeDirectory(folder);
      dispatch(getAutomations({ ...folder, callback, page }));
    },
    [handleChangeDirectory, dispatch]
  );

  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');
    const search = getSearchParam(history.location, 'q');
    syncDispatch(
      buildPage({
        type: 'automations',
        sort,
        page: page && parseInt(page, 10),
        size: size && parseInt(size, 10),
        search,
      })
    );
  }, [syncDispatch, history]);

  const sortValue = useMemo(
    () => getSortValue(snakeToCamelCase(pageConfig.sort)),
    [pageConfig?.sort]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      if (!navigatingBackRef.current) {
        dispatch(
          getAutomations({
            ...currentFolder,
            page: value,
            search: directoryPage !== null ? directorySearch : null,
          })
        );
      }
      if (directoryPage === null) {
        if (!navigatingBackRef.current) {
          dispatch(setPageConfig({ type: 'automations', page: value }));
        }
        deferExecution(() => {
          setSearchParams(history, { page: value });
        });
      } else {
        setDirectoryPage(value);
      }
      navigatingBackRef.current = false;
    },
    [directoryPage, dispatch, currentFolder, directorySearch, history]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(setPageConfig({ type: 'automations', size: value }));
      getAutomations({
        ...currentFolder,
        search: directoryPage !== null ? directorySearch : null,
      });
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [history, dispatch, currentFolder, directoryPage, directorySearch]
  );

  const handleChangeSearch = useCallback(
    (q) => {
      if (directoryPage === null) {
        dispatch(
          setPageConfig({
            type: 'automations',
            search: q,
            page: 1,
          })
        );
        dispatch(getAutomations(currentFolder));
      } else {
        setDirectorySearch(q);
        dispatch(getAutomations({ ...currentFolder, search: q }));
      }
      deferExecution(() => {
        setSearchParams(history, { q, page: null }, { method: 'replace' });
      });
    },
    [currentFolder, directoryPage, dispatch, history]
  );

  const handleSortTable = useCallback(
    ({ column, direction }) => {
      const data = camelToSnakeCase(column);
      const value = getOrderingParam({ column: data, direction });

      dispatch(setPageConfig({ type: 'automations', sort: value }));
      dispatch(
        getAutomations({
          ...currentFolder,
          search: directoryPage !== null ? directorySearch : null,
        })
      );
      deferExecution(() => {
        setSearchParams(history, {
          sort: value,
        });
      });
    },
    [currentFolder, directoryPage, directorySearch, dispatch, history]
  );
  // columns handlers
  const handleUpdateAutomation = useCallback(
    async (patch) => {
      try {
        const updatedBroadcast =
          await LibraryService.updateAutomationName(patch);
        dispatch(updateAutomationSuccess(updatedBroadcast));
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = Object.entries(err.response.data.errors);
          if (errors && errors.length) {
            // we show only 1st error
            error = new Error(errors[0][1]);
          }
        }
        throw error;
      }
    },
    [dispatch]
  );

  const handleActionView = useCallback(
    async (message) => {
      try {
        setIsLoading(true);
        setChosenMessage({
          ...messageMapper(message, businessName, t),
          bodySanitized: true,
        });
        const data = await LibraryService.getAutomationById({
          id: message.id,
        });
        setChosenMessage(
          messageMapper(data, businessName, t, { hideDynamicImages: true })
        );
      } catch (err) {
        setChosenMessage(null);
      } finally {
        setIsLoading(false);
      }
    },
    [businessName, t]
  );

  const handleActionStats = useCallback(
    async (message) => {
      setIsLoading(false);
      setStatsMessage({
        ...messageMapper(message, businessName, t),
        bodySanitized: true,
      });
    },
    [businessName, t]
  );

  const handleSelectAction = useCallback(
    async ({ value }, message) => {
      switch (value) {
        case actions.view: {
          handleActionView(message);
          break;
        }
        case actions.stats: {
          handleActionStats(message);
          break;
        }
        default: {
        }
      }
    },
    [handleActionView, handleActionStats]
  );

  const hideModal = () => {
    setChosenMessage(null);
    setStatsMessage(null);
  };

  if (isFetching) {
    return <Loader loading />;
  }
  return (
    <>
      {isMobile(width) ? (
        <></>
      ) : (
        <>
          <DesktopLayout
            search={
              directorySearch !== null ? directorySearch : pageConfig.search
            }
            onChangeSearch={handleChangeSearch}
            parentFolder={parentFolder}
            currentFolder={currentFolder}
            automations={automationsWithMeta}
            automationsCount={automationsCount}
            pageNumber={directoryPage || pageConfig.page}
            pageSize={pageConfig.size}
            handleChangePageNumber={handleChangePageNumber}
            handleChangePageSize={handleChangePageSize}
            sort={sortValue}
            handleChangeSort={handleSortTable}
            handleSelectAction={handleSelectAction}
            directories={directories}
            onChangeDirectory={onChangeDirectory}
            handleUpdateAutomation={handleUpdateAutomation}
          />
          {chosenMessage && (
            <SentMessageModal
              show
              isLoading={isLoading}
              message={chosenMessage}
              onHide={hideModal}
            />
          )}
          {statsMessage && (
            <EmailInteractionStatsModal
              show
              isLoading={isLoading}
              message={statsMessage}
              sandbox="allow-popups"
              onHide={hideModal}
            />
          )}
        </>
      )}
    </>
  );
};
export default AutomationsPage;
