import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { breakpoints } from '../../../app/spacing';
import { colorsButton, grayScale } from '../../../app/colors';
import {
  fontWeights,
  notificationCss,
  fontSizes,
} from '../../../app/typography';
import KizenTypography from '../../../app/kizentypo';

import Icon from '../Icon';
import IconButton from '../IconButton';

export const IconButtonWithBadgeStyled = styled(IconButton)`
  position: relative;
`;

const CustomNotificationText = styled(KizenTypography)`
  margin-top: 1px; // Design nudge
  font-weight: ${fontWeights.regular};
  color: ${grayScale.white};
  font-size: ${fontSizes.tiny};

  @media (max-width: ${breakpoints.md}px) {
    ${notificationCss('tiny')}
  }
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ badgeMaxWidth }) => (badgeMaxWidth ? 'max-content' : '13px')};
  height: 13px;
  border-radius: ${({ badgeMaxWidth }) => (badgeMaxWidth ? '1rem' : '50%')};
  background-color: ${colorsButton.blue.default};
  transform: translate(1px, -50%);
  padding: ${({ badgeMaxWidth }) => badgeMaxWidth && css`0.3rem 0.65rem`};

  ${({ inlineBadge }) =>
    !inlineBadge &&
    css`
      position: absolute;
      left: 100%;
      top: 3px;
    `}

  @media (max-width: ${breakpoints.md}px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -85%;
    padding: 0;
  }
`;

const IconButtonWithBadge = forwardRef(
  ({ disabled, icon, badge, inlineBadge, badgeMaxWidth, ...rest }, ref) => {
    return (
      <IconButtonWithBadgeStyled
        sizing="dense"
        color={!disabled ? colorsButton.blue : grayScale.medium}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        <Icon icon={icon} />
        {!!badge && (
          <BadgeContainer
            inlineBadge={inlineBadge}
            badgeMaxWidth={badgeMaxWidth}
          >
            <CustomNotificationText size="small">
              {badge}
            </CustomNotificationText>
          </BadgeContainer>
        )}
      </IconButtonWithBadgeStyled>
    );
  }
);

IconButtonWithBadge.displayName = 'IconButtonWithBadge';

IconButtonWithBadge.propTypes = {
  disabled: PropTypes.bool,
  inlineBadge: PropTypes.bool,
  badgeMaxWidth: PropTypes.bool,
  icon: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconButtonWithBadge.defaultProps = {
  disabled: false,
  icon: 'filter',
  badge: 0,
  inlineBadge: true,
  badgeMaxWidth: false,
};

export default IconButtonWithBadge;
