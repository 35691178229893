import { Typography } from '@kizen/kds/Typography';
import { Tooltip, useTooltip } from '@kizen/kds/Tooltip';
import { collapseNumber } from 'utility/numbers';

// to be extended and defined in a more commmon place
type AutomationExecutionStatus = 'active' | 'completed' | 'paused';

type StatisticProps = {
  label: string;
  tooltipLabel: string;
  href: string;
  value: string;
  status: AutomationExecutionStatus;
};

export const Statistic = ({
  label,
  tooltipLabel,
  href,
  value,
  status,
}: StatisticProps) => {
  const { triggerProps, tooltipProps, targetProps, showTooltip } =
    useTooltip(true);
  return (
    <div className="inline-flex flex-col items-center min-w-[60px]">
      <Typography
        variant="header"
        size="lg"
        weight="semibold"
        className="mb-[10px]"
        qa={{ '': `automation-stats-${status}-stats` }}
      >
        {collapseNumber(value)}
      </Typography>
      <span {...triggerProps} {...targetProps}>
        <Typography
          variant="link"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          qa={{ '': `automation-stats-${status}-link` }}
        >
          {label}
        </Typography>
      </span>
      {showTooltip ? (
        <Tooltip position="bottom" text={tooltipLabel} {...tooltipProps} />
      ) : null}
    </div>
  );
};
