import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-use';

/**
 * Return true if pre-release features are enabled for the current user/session
 *
 * ```ts
 * const preReleaseFeature = usePreReleaseFeatures();
 *
 * return <SomeComponent showNewButton={preReleaseFeature} />
 * ```
 *
 * @returns boolean
 */
export const usePreReleaseFeatures = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search || '');
  const hasSettingsPermission = !!useSelector(
    (store) =>
      store?.authentication?.access?.sections?.settings_section
        ?.pre_release_features
  );
  const isEnabled = String(import.meta.env.VITE_PRERELEASE_FEATURE_TESTING);
  const override =
    isEnabled === 'true' &&
    params.get('prerelease_feature_override') === 'true';
  return hasSettingsPermission || override;
};

/**
 * Wrap a sub component in this component to determine if we should show
 * the child component when the prerelease features are enabled or not.
 *
 * ```ts
 * return (
 *   <PreReleaseFeature>
 *      <MyBetaFeatureComponent />
 *   </PreReleaseFeature>
 * )
 * ```
 *
 * @returns
 */
export const PreReleaseFeature = ({ children }) => {
  const preReleaseFeature = usePreReleaseFeatures();
  if (preReleaseFeature) {
    return <>{children}</>;
  }
  return null;
};
//import { PreReleaseFeature } from 'hooks/usePreReleaseFeatures';
