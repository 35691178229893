/* eslint-disable react/display-name */
import { TextEllipsis, TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  ActionSizedCell,
} from 'components/Kizen/Table/CellLineHeightStyles';
import { colorsPrimary } from 'app/colors';
import { useTranslation } from 'react-i18next';
import LinkTextInline from 'components/Inputs/inline/LinkText';
import PhoneNumberInlineField from 'components/Fields/InlineTableFieldInput/PhoneNumber';
import TextInputInline from 'components/Inputs/inline/TextInput';
import TeamMemberRolesInlineField from 'components/Fields/InlineTableFieldInput/TeamMemberRoles';
import TeamMemberPermissionGroupsInlineField from 'components/Fields/InlineTableFieldInput/TeamMemberPermissionGroups';
import ActionCell from 'components/Tables/ActionCell';
import DateInputInline, {
  DateInputInlineSelect,
} from 'components/Inputs/inline/DateInput';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import PriceNumberInline from 'components/Inputs/inline/TextInput/presets/PriceNumber';
import { errorData } from './TabularPage';
import { REGISTERED_ROLE_AND_GROUP_NAME } from 'pages/Settings/helpers';

const textWithTruncation = (value) => (
  <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
);

const TeamMemberActionCell = ({ data, userId, access, ...others }) => {
  const { t } = useTranslation();
  return (
    <ActionCell
      title={t('Edit Team Member')}
      menuType="absolute"
      data={data}
      options={[
        !data.verified && {
          value: 'resend-verification',
          label: t('Resend Verification'),
        },
        { value: 'edit', label: t('Edit') },
        data.verified && {
          value: 'reset-password',
          label: t('Reset Password'),
        },
        access.teamMembers.edit && {
          value: 'apply-toolbar',
          label: t('Apply Toolbar'),
        },
        access.teamMembers.edit && {
          value: 'apply-columns',
          label: t('Apply Column Template'),
        },
        access.teamMembers.edit && {
          value: 'apply-quick-filters',
          label: t('Apply Quick Filter Template'),
        },
        data.user !== userId &&
          access.teamMembers.remove && { value: 'delete', label: t('Archive') },
      ].filter(Boolean)}
      {...others}
    />
  );
};

const RoleActionCell = ({ access, adminPermissionRole, ...props }) => {
  const { t } = useTranslation();
  const {
    data: { name, id },
  } = props;
  return (
    <ActionCell
      title={t('Edit Role')}
      options={[
        adminPermissionRole?.id !== id && { value: 'edit', label: t('Edit') },
        { value: 'duplicate', label: t('Duplicate') },
        access.roles.edit && {
          value: 'apply-toolbar',
          label: t('Apply Toolbar'),
        },
        access.roles.edit && {
          value: 'apply-columns',
          label: t('Apply Column Template'),
        },
        access.roles.edit && {
          value: 'apply-quick-filters',
          label: t('Apply Quick Filter Template'),
        },
        adminPermissionRole?.id !== id &&
          access.roles.remove &&
          name !== REGISTERED_ROLE_AND_GROUP_NAME && {
            value: 'delete',
            label: t('Delete'),
          },
      ].filter(Boolean)}
      {...props}
    />
  );
};

const PermissionGroupActionCell = ({
  access,
  adminPermissionGroup,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    data: { name, id },
  } = props;

  return (
    <ActionCell
      title={t('Edit Permission Group')}
      options={[
        adminPermissionGroup?.id !== id && { value: 'edit', label: t('Edit') },
        { value: 'duplicate', label: t('Duplicate') },
        // KZN-8714 suppressing this option untill the same functionality is implemented for toolbar templates
        // access.permissionGroups.edit && {
        //   value: 'apply-toolbar',
        //   label: t('Apply Toolbar'),
        // },
        // access.permissionGroups.edit && {
        //   value: 'apply-columns',
        //   label: t('Apply Column Template'),
        // },
        // access.permissionGroups.edit && {
        //   value: 'apply-quick-filters',
        //   label: t('Apply Quick Filter Template'),
        // },
        adminPermissionGroup?.id !== id &&
          access.permissionGroups.remove &&
          name !== REGISTERED_ROLE_AND_GROUP_NAME && {
            value: 'delete',
            label: t('Delete'),
          },
      ].filter(Boolean)}
      {...props}
    />
  );
};

const ActionHeadCell = (props) => {
  const { t } = useTranslation();
  return (
    <ActionSizedCell {...props}>
      <TextEllipsisWithTooltip as="span" weight="bold">
        {t('Actions')}
      </TextEllipsisWithTooltip>
    </ActionSizedCell>
  );
};

export const getTeamColumns = (
  { onUpdateField, onSelectAction },
  t,
  preReleaseFeature,
  showToast,
  userId,
  access,
  chosenBusiness
) =>
  [
    {
      id: 'spacer',
      headCell: <SizedCell width="20px" />,
      cell: <SizedCell width="20px" />,
    },
    {
      id: 'fullName',
      headCell: (
        <SortableHeadCell
          by="full_name"
          label={t('Team Member')}
          minWidth="140px"
          padding="30px"
        />
      ),
      cell: <SizedCell minWidth="140px" padding="30px" />,
      format: (fullName, member) => {
        return preReleaseFeature ? (
          <LinkTextInline
            type="link"
            to={`/team-member/${member.id}/timeline`}
            target="_blank"
            rel="noopener noreferrer"
            value={fullName}
            onSubmit={async (value) => {
              const [firstName, ...lastNames] = (value || '').split(' ');
              await onUpdateField(member, {
                firstName,
                lastName: lastNames.join(' '),
              });
            }}
          />
        ) : (
          <TextInputInline
            value={fullName}
            onSubmit={async (value) => {
              const [firstName, ...lastNames] = (value || '').split(' ');
              await onUpdateField(member, {
                firstName,
                lastName: lastNames.join(' '),
              });
            }}
          />
        );
      },
    },
    {
      id: 'email',
      headCell: (
        <SortableHeadCell label={t('Email')} minWidth="135px" padding="30px" />
      ),
      cell: <SizedCell minWidth="140px" padding="30px" />,
      format: textWithTruncation,
    },
    {
      id: 'phone',
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Phone Number')}
          minWidth="118px"
          padding="30px"
        />
      ),
      cell: <SizedCell minWidth="113px" padding="30px" />,
      format: (phone, member) => {
        return (
          <PhoneNumberInlineField
            defaultCountry={chosenBusiness.phone_default_region}
            value={phone}
            onSubmit={async (value) => {
              if (!value) {
                await onUpdateField(member, {
                  phone: null,
                });
              } else {
                await onUpdateField(member, {
                  phone: value,
                });
              }
            }}
          />
        );
      },
    },
    access.roles.edit && {
      id: 'roles',
      headCell: (
        <SortableHeadCell
          label={t('Role(s)')}
          minWidth="170px"
          padding="30px"
        />
      ),
      cell: <SizedCell text minWidth="173px" padding="30px" />,
      format: (roles, member) => (
        <TeamMemberRolesInlineField
          value={roles}
          onSubmit={async (value) => {
            try {
              await onUpdateField(member, {
                roles: (value || []).map((role) => role.id),
              });
            } catch (err) {
              const { data } = err.response;
              if (data.statusCode === 400) {
                const [error] = data.errors;
                showToast(errorData(error));
              }
              throw new Error(err);
            }
          }}
          data-field-id="roles"
        />
      ),
    },
    access.permissionGroups.edit && {
      id: 'permissionGroups',
      headCell: (
        <SortableHeadCell
          by="permission_groups"
          label={t('Additional Permission Group(s)')}
          minWidth="210px"
          padding="30px"
        />
      ),
      cell: <SizedCell text minWidth="213px" padding="30px" />,
      format: (permissionGroups, member) => {
        return (
          <TeamMemberPermissionGroupsInlineField
            value={permissionGroups}
            onSubmit={async (value) => {
              try {
                await onUpdateField(member, {
                  permissionGroups: (value || []).map((pg) => pg.id),
                });
              } catch (err) {
                const { data } = err.response;
                if (data.statusCode === 400) {
                  const [error] = data.errors;
                  showToast(errorData(error));
                }
                throw new Error(err);
              }
            }}
            data-field-id="groups"
          />
        );
      },
    },
    {
      id: 'annualSalesGoal',
      headCell: (
        <SortableHeadCell
          numeric
          by="annual_sales_goal"
          label={`$ ${t('Goal')}`}
          minWidth="80px"
          padding="30px"
        />
      ),
      cell: <SizedCell minWidth="80px" padding="30px" />,
      format: (annualSalesGoal, member) => {
        return (
          <PriceNumberInline
            value={annualSalesGoal}
            onSubmit={async (value) => {
              await onUpdateField(member, {
                annualSalesGoal: value,
              });
            }}
          />
        );
      },
    },
    {
      id: 'startDate',
      headCell: (
        <SortableHeadCell
          numeric
          by="start_date"
          label={t('Start Date')}
          minWidth="88px"
          padding="30px"
        />
      ),
      cell: <SizedCell minWidth="82px" padding="30px" />,
      format: (startDate, member) => (
        <DateInputInline
          onSubmit={async (value) => {
            await onUpdateField(member, {
              startDate: value,
            });
          }}
          trackExternal
        >
          <DateInputInlineSelect
            value={startDate}
            menuLeftButton={<ClearSelectButton />}
            menuRightButton={<ApplySelectOverlayButton />}
          />
        </DateInputInline>
      ),
    },
    {
      id: 'verified',
      headCell: (
        <SortableHeadCell label={t('Status')} minWidth="65px" padding="35px" />
      ),
      cell: <SizedCell minWidth="65px" padding="35px" />,
      format: (verified) => (
        <TextEllipsis
          color={
            verified ? colorsPrimary.green.dark : colorsPrimary.orange.dark
          }
        >
          {verified ? t('Verified') : t('Unverified')}
        </TextEllipsis>
      ),
    },
    {
      id: 'actions',
      headCell: <ActionHeadCell minWidth="50px" padding="20px" />,
      cell: (
        <TeamMemberActionCell
          userId={userId}
          minWidth="50px"
          padding="20px"
          onSelectAction={onSelectAction}
          access={access}
        />
      ),
    },
  ].filter(Boolean);

const formatNameColumn = (permissionId, onClickName) => (value, pg) => {
  return permissionId === pg.id ? (
    <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
  ) : (
    <TextEllipsisWithTooltip
      type="link"
      onClick={(event) => {
        // Have to prevent default so the URL does not change and strip query parameters
        event.preventDefault();
        onClickName(pg);
      }}
    >
      {value}
    </TextEllipsisWithTooltip>
  );
};

export const getRoleColumns = (
  { onClickName, onSelectAction },
  t,
  access,
  adminPermissionRole
) => [
  {
    id: 'spacer',
    headCell: <SizedCell width="20px" />,
    cell: <SizedCell width="20px" />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        label={t('Name')}
        minWidth="80px"
        maxWidth="100px"
        padding="30px"
      />
    ),
    cell: <SizedCell minWidth="80px" maxWidth="100px" padding="30px" />,
    format: formatNameColumn(adminPermissionRole.id, onClickName),
  },
  {
    id: 'permissionGroups',
    headCell: (
      <SortableHeadCell
        label={t('Default Permission Group(s)')}
        by="permission_groups"
        minWidth="100px"
        maxWidth="200px"
        padding="30px"
      />
    ),
    cell: <SizedCell minWidth="100px" maxWidth="200px" padding="30px" />,
    format: (pgs) => textWithTruncation(pgs.map(({ name }) => name).join(', ')),
  },
  {
    id: 'actions',
    headCell: <ActionHeadCell padding="16px" maxWidth="97px" minWidth="97px" />,
    cell: (
      <RoleActionCell
        padding="16px"
        onSelectAction={onSelectAction}
        access={access}
        adminPermissionRole={adminPermissionRole}
      />
    ),
  },
];

export const getPermissionGroupColumns = (
  { onClickName, onSelectAction },
  t,
  access,
  adminPermissionGroup
) => [
  {
    id: 'spacer',
    headCell: <SizedCell width="20px" />,
    cell: <SizedCell width="20px" />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell label={t('Name')} minWidth="80px" padding="30px" />
    ),
    cell: <SizedCell minWidth="80px" padding="30px" />,
    format: formatNameColumn(adminPermissionGroup?.id, onClickName),
  },
  {
    id: 'summary',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Permission(s) Summary')}
        minWidth="100px"
        maxWidth="200px"
        padding="30px"
      />
    ),
    cell: <SizedCell minWidth="100px" maxWidth="200px" padding="30px" />,
    format: ({ remove = 0, edit = 0, view = 0, none = 0 } = {}) => {
      const text = t(
        '{{remove}} All, {{edit}} Create/Edit, {{view}}  View, {{none}} No Access',
        {
          remove,
          edit,
          view,
          none,
        }
      );
      return textWithTruncation(text);
    },
  },
  {
    id: 'actions',
    headCell: <ActionHeadCell padding="16px" maxWidth="97px" minWidth="97px" />,
    cell: (
      <PermissionGroupActionCell
        padding="16px"
        onSelectAction={onSelectAction}
        access={access}
        adminPermissionGroup={adminPermissionGroup}
      />
    ),
  },
];
