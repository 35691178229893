import styled from '@emotion/styled';

import {
  GenericWizardModalBody,
  GenericWizardSection,
} from 'components/GenericWizard';
import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';

export const StyledGenericWizardModalBody = styled(GenericWizardModalBody)`
  padding: ${gutters.spacing(1, -4)}px ${gutters.spacing(9)}px 0px
    ${gutters.spacing(7)}px;
`;

export const StyledUploadDataSection = styled(GenericWizardSection)`
  margin-top: ${gutters.spacing(3, -3)}px;
  margin-bottom: ${gutters.spacing(6, -2)}px;
`;

export const StyledMatchRecordsSection = styled(GenericWizardSection)`
  margin-top: ${gutters.spacing(3, -3)}px;
  margin-bottom: ${gutters.spacing(6, -1)}px;
`;

export const StyledFinalizeUploadSection = styled(GenericWizardSection)`
  margin-top: ${gutters.spacing(4)}px;
`;
export const CategoryWrapper = styled.div`
  align-items: center;
  margin-top: 15px;
  padding-bottom: 2px;
`;
export const SpanName = styled.span`
  font-size: inherit;
  font-weight: inherit;
  color: ${grayScale.mediumDark};
`;
