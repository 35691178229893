import React, { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { gutters } from 'app/spacing';
import { applyRef, selectedValueProp } from '../props';
import ButtonGroupButton from '../ButtonGroup/Button';
import Validation, { useValidation } from '../Validation';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const ButtonsWrapper = styled.div`
  display: flex;

  & > * {
    width: 50px;

    margin-left: ${gutters.spacing(2)}px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const OutlineYesNoMaybe = React.forwardRef(
  (
    {
      value: valueProp,
      options,
      error,
      disabled,
      onChange,
      fieldId,
      ...others
    },
    ref
  ) => {
    const { assignFieldHandle } = useKeyBoardContext();
    const validationRef = useRef();
    const [, validationProps] = useValidation(validationRef, {
      value: valueProp,
      ...others,
    });
    const value =
      typeof valueProp === 'string'
        ? options.find((opt) => opt.value === valueProp) || null
        : valueProp;

    const mergeRef = useCallback(
      (node) => {
        applyRef(ref, node);
        applyRef(validationRef, node);
      },
      [ref]
    );

    const selectedElem = useMemo(() => {
      const index = options.map((i) => i.value).indexOf(value?.value);
      return index >= 0 ? index : 0;
    }, [options, value]);

    const buttonRef = useRef([]);
    const focused = useRef(selectedElem);

    assignFieldHandle(fieldId, {
      customRight: () => {
        if (focused.current === buttonRef.current?.length - 1) return false;
        focused.current += 1;
        buttonRef.current[focused.current]?.focus();
        return true;
      },
      customLeft: () => {
        if (options.length === 1) {
          return false;
        }
        if (focused.current === 0) return false;
        focused.current -= 1;
        buttonRef.current[focused.current]?.focus();
        return true;
      },
      customFocus: () => {
        buttonRef.current?.[selectedElem]?.focus();
        focused.current = selectedElem;
        return buttonRef.current?.[selectedElem];
      },
      customSpace: (e) => {
        e.preventDefault();
        buttonRef.current[focused.current]?.click();
        buttonRef.current[focused.current]?.focus();
      },
      disabled,
    });

    const { required } = others;

    return (
      <ButtonsWrapper ref={mergeRef} {...others}>
        {options.map((opt, index) => {
          const selected = Boolean(value && value.value === opt.value);
          return (
            <ButtonGroupButton
              ref={(el) => {
                buttonRef.current[index] = el;
              }}
              option={opt}
              key={opt.value}
              selected={selected}
              error={error}
              disabled={disabled}
              onClick={(ev) => {
                if (onChange) {
                  if (selected && !required) {
                    ev.currentTarget.blur();
                    onChange(null, ev);
                  } else {
                    onChange(opt, ev);
                  }
                }
              }}
            />
          );
        })}
        <Validation {...validationProps} />
      </ButtonsWrapper>
    );
  }
);

OutlineYesNoMaybe.displayName = 'OutlineYesNoMaybe';
OutlineYesNoMaybe.propTypes = {
  options: PropTypes.array.isRequired,
  value: selectedValueProp,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};
OutlineYesNoMaybe.defaultProps = {
  value: null,
  onChange: null,
  error: null,
  disabled: null,
};

export default OutlineYesNoMaybe;
