import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useBustCache, useTriggerRefetch } from './getters';

export const useManagedQueryRefetch = () => {
  const queryClient = useQueryClient();

  const triggerRefetch = useTriggerRefetch();
  const onBustCache = useBustCache();

  const refetch = useCallback(
    ({ dashletId, queryKey, exact = false, bustCache = false }) => {
      if (bustCache) {
        onBustCache(dashletId);
      }

      triggerRefetch(dashletId);

      if (queryKey) {
        setTimeout(() => queryClient.invalidateQueries(queryKey, { exact }));
      }
    },
    [triggerRefetch, queryClient, onBustCache]
  );

  return refetch;
};
