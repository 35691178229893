import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import TablePagination from 'components/Tables/Big/TablePagination';
import ListCard from 'components/Layout/ListCard';
import BigListLayout, { BigListTitle } from 'components/Layout/BigListLayout';
import { BigListSearchInput } from 'components/Layout/BigListLayout/Toolbar';
import {
  SurveyListItem,
  SurveyListHeader,
  EmptySurveyListItem,
} from './ListItem';
import { SURVEY_ACTIONS } from './columns';
import { useSelector } from 'react-redux';
import { selectSurveysIsFiltering } from 'store/surveysPage/surveys.redux';
export default function SurveyListPage({
  surveys,
  surveysCount,
  search,
  onChangeSearch,
  sort,
  onChangeSort,
  onSelectPreviewSurvey,
  onSelectDeleteSurvey,
  pageNumber,
  pageSize,
  onChangePageNumber,
  onChangePageSize,
  ...others
}) {
  const { t } = useTranslation();
  const surveyActions = SURVEY_ACTIONS(t);
  const isFiltering = useSelector(selectSurveysIsFiltering);

  const handleCheckedSelection = (selection, survey) => {
    if (selection.value === surveyActions.Preview.value) {
      onSelectPreviewSurvey(survey.slug);
    }
    if (selection.value === surveyActions.Delete.value) {
      onSelectDeleteSurvey(survey.id);
    }
  };
  return (
    <BigListLayout
      search={
        <BigListSearchInput
          placeholder="Find Surveys"
          value={search}
          onChange={onChangeSearch}
          loading={isFiltering}
          isClearable
        />
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={surveysCount}
          onChangePage={onChangePageNumber}
          onChangePerPage={onChangePageSize}
        />
      }
      {...others}
    >
      <BigListTitle count={surveysCount} single="Survey" plural="Surveys" />
      <ListCard>
        <SurveyListHeader
          label="Survey Name"
          sort={sort}
          onChangeSort={(direction) =>
            onChangeSort({ column: 'name', direction })
          }
        />
        {!surveys.length && <EmptySurveyListItem />}
        {surveys.map((survey) => (
          <SurveyListItem
            key={survey.id}
            survey={survey}
            onSelectAction={(selection) =>
              handleCheckedSelection(selection, survey)
            }
          />
        ))}
      </ListCard>
    </BigListLayout>
  );
}

SurveyListPage.propTypes = {
  surveys: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveysCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onSelectPreviewSurvey: PropTypes.func.isRequired,
  onSelectDeleteSurvey: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
};
