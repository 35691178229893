import React from 'react';
import styled from '@emotion/styled';

const RawTrendCaretIcon = (props) => (
  /* eslint-disable */
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 0.445953L9.33013 7.94595H0.669873L5 0.445953Z"
      fill="currentColor"
    />
  </svg>
  /* eslint-enable */
);

export default styled(RawTrendCaretIcon)`
  transform: scaleY(${({ negative }) => (negative ? -1 : 1)});
`;
