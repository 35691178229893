import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import { gutters } from 'app/spacing';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import EditableText from 'components/Kizen/EditableText';

export const StyledProfileImage = styled.div`
  margin-right: 10px;
`;

export const StyledEditableText = styled(EditableText)`
  display: flex;
  flex-grow: 1;
  margin-right: ${gutters.spacing(4)}px;
  width: 0;

  & input {
    width: 0;
  }
`;

export const StyledKizenTypography = styled(TextEllipsisWithTooltip)`
  width: 0;
  flex-grow: 1;
  margin-right: ${gutters.spacing(2)}px;

  ${({ isDynamic }) =>
    isDynamic
      ? css`
          color: ${colorsButton.green.default};
        `
      : ''}
`;
