import css from '@emotion/css';
import styled from '@emotion/styled';
import { PropsWithChildren, ReactNode, forwardRef } from 'react';
import OverlayTrigger from 'react-bootstrap2/OverlayTrigger';
import { useTranslation } from 'react-i18next';
import { grayScale, appBackground, colorsButton } from '__app/colors';
import { borderRadii } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import { useModalControl } from 'hooks/useModalControl';
import Button from '__components/Button';
import Icon from '__components/Kizen/Icon';
import ConfirmDeletionModal from '__components/Modals/presets/ConfirmDeletion';
import KizenTooltip from '__components/Kizen/Tooltip';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';

export type RuleCardProps = {
  className?: string;
  condition: string | ReactNode;
  conditionInfoTooltip?: string;
  confirmDeleteText: string;
  id: string;
  isFirst: boolean;
  isLast: boolean;
  onDelete(id: string): void;
  onReorderUp(id: string): void;
  onReorderDown(id: string): void;
  highlighted?: boolean;
};

export const REORDER_ICON_WIDTH = 12;
export const ROW_ONE_FLEX_GAP = 15;

const Card = styled.div<{ highlighted?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadii.small};
  min-height: 100px;
  ${({ highlighted }) =>
    highlighted
      ? css`
          border: 1px solid ${colorsButton.blue.hover};
          background: ${appBackground};
        `
      : css`
          border: 1px solid ${grayScale.medium};
          background-color: #fff;
          transition:
            border-color 0.5s linear,
            background-color 0.5s linear;
        `};
`;

export const CardRow = styled.div<{
  column?: boolean;
  paddingBottom?: number;
  paddingTop?: number;
}>`
  display: flex;
  align-items: flex-start;
  padding: 15px;
  ${({ column }) => column && 'flex-direction: column;'}
  ${({ paddingBottom = 15, paddingTop = 15 }) => css`
    padding-bottom: ${paddingBottom}px;
    padding-top: ${paddingTop}px;
  `}
`;

const Tooltip = styled(KizenTooltip)`
  ${({ width }) => width && `width: ${width}px;`}

  .tooltip-inner {
    max-width: 100%;
  }
`;

const ReorderArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 1px;
`;

const RowOne = styled(CardRow)`
  gap: ${ROW_ONE_FLEX_GAP}px;
  align-items: center;
`;

const ReorderIcon = styled(Icon)`
  height: 7px;
  cursor: pointer;
  color: ${grayScale.mediumDark};

  && svg {
    width: ${REORDER_ICON_WIDTH}px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: unset;
      color: ${grayScale.medium};

      &:hover {
        color: ${grayScale.medium};
      }
    `}
`;

const ChevronUpIcon = styled(ReorderIcon)`
  transform: rotateX(180deg);
`;

const DeleteRuleButton = styled(Button)`
  margin: 0;
  padding: 0;
  height: unset;
  line-height: unset;
  align-self: flex-start;
`;

const StyledIconWithTooltip = styled(IconWithTooltip)`
  margin-left: 5px;
`;

const ConditionText = styled(KizenTypography)`
  display: flex;
  align-items: center;
`;

export const RuleCardConditionContainer = styled.div`
  flex: 1;
`;

export const RuleCard = forwardRef<
  HTMLDivElement | null,
  PropsWithChildren<RuleCardProps>
>(
  (
    {
      children,
      className,
      condition = null,
      conditionInfoTooltip,
      confirmDeleteText,
      id,
      isFirst,
      isLast,
      onDelete,
      onReorderDown,
      onReorderUp,
      highlighted,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [modalOpen, { showModal, hideModal }] = useModalControl();

    return (
      <>
        <Card
          ref={ref}
          className={className}
          highlighted={highlighted}
          {...rest}
        >
          <RowOne paddingBottom={10}>
            <ReorderArrowsContainer>
              <OverlayTrigger overlay={<Tooltip label={t('Move Up')} />}>
                <ChevronUpIcon
                  icon="chevron-down"
                  onClick={() => onReorderUp(id)}
                  disabled={isFirst}
                />
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip label={t('Move Down')} />}>
                <ReorderIcon
                  icon="chevron-down"
                  onClick={() => onReorderDown(id)}
                  disabled={isLast}
                />
              </OverlayTrigger>
            </ReorderArrowsContainer>
            <RuleCardConditionContainer>
              {typeof condition === 'string' ? (
                <ConditionText weight="bold">
                  {condition}
                  {conditionInfoTooltip ? (
                    <StyledIconWithTooltip label={conditionInfoTooltip} />
                  ) : null}
                </ConditionText>
              ) : (
                condition
              )}
            </RuleCardConditionContainer>
            <DeleteRuleButton variant="text" color="red" onClick={showModal}>
              {t('Delete Rule')}
            </DeleteRuleButton>
          </RowOne>
          {children}
        </Card>
        {modalOpen && (
          <ConfirmDeletionModal
            show={modalOpen}
            onConfirm={() => onDelete(id)}
            onHide={hideModal}
          >
            {confirmDeleteText}
          </ConfirmDeletionModal>
        )}
      </>
    );
  }
);
