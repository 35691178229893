import { cloneElement, useEffect } from 'react';
import { ResizableBox as ResizableBoxBase } from 'react-resizable';
import 'react-resizable/css/styles.css';
import Icon from 'components/Kizen/Icon';
import styled from '@emotion/styled';
import { TraySection, TraySubsection } from '../components/TraySection';
import { PageSettingsSection, ViewSettingsSection } from '../settings';
import { SectionTrayItem, RowTrayItem } from '../tray-items';
import { useBuilderContext } from '../BuilderContext';
import { useTranslation } from 'react-i18next';
import { useDraggableAsFixedProps } from './useDraggableAsFixedProps';

const SIZE = 55;
const hasOwn = Object.prototype.hasOwnProperty;

const TrayButtonLayout = styled.div`
  display: grid;
  grid: auto-flow ${SIZE}px / repeat(auto-fill, ${SIZE}px);
  gap: 20px;
`;

const ResizableBox = styled(ResizableBoxBase)`
  display: flex;
  flex-direction: column;
  min-width: 320px;
`;

const UnresizableBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: 320px;
`;

const HandleIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);
  cursor: ew-resize;
  &&,
  && svg {
    width: 3px;
    height: 30px;
  }
`;

function groupTrayElements(trayItems) {
  return trayItems.reduce(
    (acc, elem) => {
      if (elem.props.group === 'advanced') {
        acc.advancedItems.push(elem);
      } else {
        acc.basicItems.push(elem);
      }
      return acc;
    },
    {
      basicItems: [],
      advancedItems: [],
    }
  );
}

const Container = ({ canResize, children }) => {
  if (canResize) {
    return (
      <ResizableBox
        data-qa="page-builder-tray"
        axis="x"
        width={320}
        minConstraints={[320]}
        maxConstraints={[800]}
        resizeHandles={['w']}
        handle={
          <HandleIcon
            icon="column-resize"
            size="intrinsic"
            preserveAspectRatio="none"
          />
        }
      >
        {children}
      </ResizableBox>
    );
  }

  return (
    <UnresizableBox data-qa="page-builder-tray">{children}</UnresizableBox>
  );
};

export const PageBuilderTray = ({
  trayItems,
  nodeSettings,
  RootNodeSettings,
  emailMode = false,
  canResize = true,
}) => {
  const [measureRef, trayItemProps] = useDraggableAsFixedProps();
  const { basicItems, advancedItems } = groupTrayElements(
    trayItems.map((el) => cloneElement(el, trayItemProps))
  );
  const { t } = useTranslation();
  const {
    activeNode,
    rootNode,
    trayProps,
    setTrayProps,
    isViewSettingsOpen,
    setIsViewSettingsOpen,
  } = useBuilderContext();
  const activeNodeType = activeNode?.data?.name;

  let settingsElement = null;
  if (activeNodeType && hasOwn.call(nodeSettings, activeNodeType)) {
    const Component = nodeSettings[activeNodeType];
    settingsElement = <Component node={activeNode} {...trayProps} />;
  }

  useEffect(() => {
    if (settingsElement === null) {
      setTrayProps({});
    }
  }, [settingsElement, setTrayProps]);

  return (
    <Container canResize={canResize}>
      {rootNode
        ? settingsElement || (
            <>
              <TraySection
                header={t('Elements (Drag and Drop)')}
                collapsable={false}
                ref={measureRef}
              >
                <TraySubsection header={t('Layout')}>
                  <TrayButtonLayout>
                    {cloneElement(SectionTrayItem, trayItemProps)}
                    {cloneElement(RowTrayItem, trayItemProps)}
                  </TrayButtonLayout>
                </TraySubsection>
                <TraySubsection header={t('Basic Content Element')}>
                  <TrayButtonLayout>{basicItems}</TrayButtonLayout>
                </TraySubsection>
                {!emailMode && advancedItems?.length > 0 && (
                  <TraySubsection header={t('Advanced Content Element')}>
                    <TrayButtonLayout>{advancedItems}</TrayButtonLayout>
                  </TraySubsection>
                )}
              </TraySection>
              {RootNodeSettings ? (
                <RootNodeSettings />
              ) : (
                <PageSettingsSection emailMode={emailMode} />
              )}
              <TraySection
                header={t('View Settings')}
                scrollable={false}
                open={isViewSettingsOpen}
                onCollapse={() => {
                  setIsViewSettingsOpen((x) => !x);
                }}
              >
                <ViewSettingsSection />
              </TraySection>
            </>
          )
        : null}
    </Container>
  );
};
