import { useReducer } from 'react';
import { useAsync } from 'react-use';
import FieldService from 'services/FieldService';
import { FIELDCACHE_SET_FIELDS, MAX_CACHE_AGE } from './constants';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const fieldCacheReducer = (state, action) => {
  const { id, fieldOptions } = action;
  switch (action.type) {
    case FIELDCACHE_SET_FIELDS:
      return { ...state, [id]: { fieldOptions, timeStamp: Date.now() } };

    default:
      throw new Error();
  }
};

export function useCustomFieldChooserWithCache(xform = (x) => x) {
  const [fieldCache, dispatchFieldCache] = useReducer(fieldCacheReducer, {});

  const clientObject = useSelector(getBusinessClientObject);

  // get field options caching as needed
  const getRawFieldOptions = async (modelsVal, modelsOptions, field = null) => {
    let fieldOptions = [];
    if (modelsVal && modelsOptions) {
      if (
        fieldCache[modelsVal.value] &&
        Date.now() - fieldCache[modelsVal.value].timeStamp < MAX_CACHE_AGE
      ) {
        // if cached use that
        fieldOptions = fieldCache[modelsVal.value].fieldOptions;
        // [todo] the new api doesn't support find field yet
        // } else if (field) {
        //   // editing we don't need all the fields, but don't cache a particaly hydrated option set
        //   fieldOptions = await FieldService.getCategorizedCustomField({
        //     object: modelsVal.object,
        //     objectFieldId: field.customObjectField.id
        //   });
      } else {
        // fetch the the full option set
        fieldOptions = await FieldService.getCategorizedCustomFields(
          modelsVal.object,
          true
        );
        // and cache it
        dispatchFieldCache({
          type: FIELDCACHE_SET_FIELDS,
          id: modelsVal.value,
          fieldOptions,
        });
      }
    }
    return xform(fieldOptions, field);
  };

  const { loading: modelsOptionsLoading, value: modelsOptions = null } =
    useAsync(
      () =>
        FieldService.getModelsWithPriority({
          clientObjectId: clientObject.id,
          includeCustomObjects: true,
        }),
      []
    );

  return {
    modelsOptions,
    onGetRawFieldOptions: getRawFieldOptions,
    loading: modelsOptionsLoading,
  };
}
