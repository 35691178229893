import {
  namedToOptionDeleted,
  specificTeamMemberToOptionDeleted,
} from 'services/helpers';
import {
  RECEIVING_INBOX,
  checkDeleted,
  displayNameDescriptor,
} from 'pages/AutomationEngine/helpers';
import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const {
    specificTeamRole,
    inboxCriteria,
    specificTeamMember,
    receivingInbox,
    ...others
  } = orgDetails;

  const stepGoal = {
    ...others,
    triggers: [
      {
        type: stepConfig.emailReceived.type,
        triggerEmailReceivedFromContact: {
          receivingInbox,
          ...(specificTeamRole && {
            specificTeamRole: {
              roleId: specificTeamRole.value,
              inboxCriteria,
            },
          }),
          ...(specificTeamMember && {
            specificTeamMemberId: specificTeamMember.value,
          }),
        },
      },
    ],
  };

  if (specificTeamRole) {
    orgDetails.specificTeamRole = {
      roleId: specificTeamRole.value,
      inboxCriteria,
    };
    delete orgDetails.inboxCriteria;
  }

  if (specificTeamMember) {
    orgDetails.specificTeamMemberId = specificTeamMember.value;
    delete orgDetails.specificTeamMember;
  }

  delete orgDetails.type;
  delete orgDetails.field;
  return {
    stepGoal,
    details: orgDetails,
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { specificTeamRole, receivingInbox, specificTeamMember } =
    firstTrigger.triggerEmailReceivedFromContact;

  const flow = {
    type: stepConfig.emailReceivedGoal.type,
    receivingInbox,
  };

  if (receivingInbox === RECEIVING_INBOX.teamRole) {
    // if set to be specific_team_role and the role has been deleted then there is no specificTeamRole
    if (specificTeamRole) {
      const { inboxCriteria, role } = specificTeamRole;
      flow.specificTeamRole = namedToOptionDeleted(role);
      flow.inboxCriteria = inboxCriteria;
    } else {
      // so add the generic error
      addErrorMessage(
        messageDictionary.thereWasanErrorLoadingTheAssociatedRole
      );
    }
  }

  if (specificTeamMember) {
    checkDeleted(
      messageDictionary,
      addErrorMessage,
      specificTeamMember,
      displayNameDescriptor
    );

    flow.specificTeamMember =
      specificTeamMemberToOptionDeleted(specificTeamMember);
  }
  return flow;
};

export const emailReceivedGoalDTO = {
  forApi,
  forFlow,
};
