import TextInput from 'components/Inputs/TextInput';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useState } from 'react';

const StyledKizenTypography = styled(KizenTypography)`
  padding-bottom: 18px;
  text-align: center;
`;
export const ConfirmModalWithInput = ({
  show = true,
  onConfirm,
  onHide,
  unblockName = 'Object Name',
  message,
  ...others
}) => {
  const [objectNameValue, setObjectNameValue] = useState();

  const disabled = objectNameValue !== unblockName;

  const { t } = useTranslation();
  return (
    <BasicModal
      show={show}
      buttonText={t('Delete')}
      heading={t('Please Confirm Deletion')}
      defaultLeftBtnText={t('Cancel')}
      onConfirm={onConfirm}
      onHide={onHide}
      actionBtnColor="red"
      modalBodyProps={{
        style: {
          paddingTop: '9px',
        },
      }}
      disabled={disabled}
      {...others}
    >
      <>
        <StyledKizenTypography>{message}</StyledKizenTypography>
        <TextInput
          value={objectNameValue}
          onChange={setObjectNameValue}
          placeholder={t('Type {{unblockName}} to confirm', {
            unblockName: unblockName,
          })}
          data-qa={'confirm-modal-input'}
        />
      </>
    </BasicModal>
  );
};
