import { Button } from '@kizen/kds/Button';
import { Panel, VerticalTile } from '@kizen/kds/Tile';
import { Typography } from '@kizen/kds/Typography';
import { Block } from './Block';
import { Metadata } from './Metadata';
import { Divider } from './Divider';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicModalWithConfirmation from '__components/Modals/presets/BasicModalWithConfirmation';
import { InfoModalContents as __InfoModalContents } from 'pages/Common/styles/InfoModal';
import __ScheduledActivities from 'components/Charts/ScheduledActivities';
import useSearchParam from 'hooks/useSearchParam';
import TeamAssociations from 'pages/ContactDetail/pages/Profile/TeamAssociations/TeamAssociations';
import { useAssociatedTeamMembers } from './hooks/useAssociatedTeamMembers';
import { useScheduledActivities } from './hooks/useScheduledActivities';
import {
  formatSplitTimestamp,
  formatTimestamp,
} from 'components/Inputs/DateTimeInput/helpers';
import { AddTeamAssociationModal } from 'pages/ContactDetail/pages/Profile/TeamAssociations/AddTeamAssociationModal';
import { CompleteActivityModal } from 'components/Modals/ActivityCompletionModal/ActivityCompletionModal';
import DatePicker from '__components/Kizen/DatePicker';
import { Overlay as __Overlay } from 'react-bootstrap';
import { format } from 'date-fns';
import ActivityService from 'services/ActivityService';
import { TimePickerMenu } from './styles';
import { StyledDateMenuWrapper } from 'components/Kizen/DatePicker/styles';
import useModal from 'components/Modals/useModal';
import { SCHEDULE_ACTIVITY_SUCCESS } from 'pages/Common/Actions/activityHelpers';
import { toastVariant, useToast } from '__components/ToastProvider';
import { ScheduleActivityModal } from '__components/Charts/ScheduledActivities/ScheduleActivityModal';
import {
  useCanAddTeamAssociation,
  useCanViewTeamAssociation,
} from 'ts-components/hooks/permissions/teamAssociations';
import { withErrorBoundary } from '../ErrorBoundary';
import { useCanViewScheduledActivities } from 'ts-components/hooks/permissions/activities';
import { useModalControl } from 'hooks/useModalControl';
import { ConfirmationModal } from 'components/Charts/ScheduledActivities/ConfirmationModal';

const InfoModalContents = __InfoModalContents as any;
const Overlay = __Overlay as any;
const ScheduledActivities = __ScheduledActivities as any;

export interface TeamActivitiesProps {
  isClient?: boolean;
  customObject?: any;
  entity?: any;
  id: string;
  refetch: () => void;
  fieldState?: any;
  fieldStateDirty?: boolean;
  handleSubmit: (...args: any[]) => any;
  hasScheduledActivityPermission?: boolean;
  touchedFormData?: boolean;
  saveFormData: () => Promise<any>;
  resetAllFields: () => void;
}

const popperConfig = {
  modifiers: {
    preventOverflow: {
      // Allows element to appear outside of the target's
      // scrolling parent but not outside the viewport.
      boundariesElement: 'viewport',
    },
  },
};

export const InfoBlockTypes = {
  TEAM: 'TEAM',
  ACTIVITIES: 'ACTIVITIES',
};

const ModalContentWrapper = ({
  children,
  type,
}: {
  children: any;
  type: any;
}) => {
  return type === InfoBlockTypes.TEAM ? (
    children
  ) : (
    <InfoModalContents
      loading={false}
      isActivities={[InfoBlockTypes.ACTIVITIES].includes(type)}
    >
      {children}
    </InfoModalContents>
  );
};

const BlockComponent = (props: TeamActivitiesProps) => {
  const {
    isClient = false,
    entity,
    customObject,
    refetch,
    fieldState,
    fieldStateDirty,
    handleSubmit,
    hasScheduledActivityPermission,
    touchedFormData,
    saveFormData,
    resetAllFields,
  } = props;
  const [infoModal, setInfoModal] = useState<any>(null);
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState(false);
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);
  const scheduledActivityId = useSearchParam('scheduled_activity_id');
  const dateOverlayTarget = useRef<any>(null);
  const timeOverlayTarget = useRef<any>(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [selectedTime, setSelectedTime] = useState<{
    value: string;
    label: string;
  }>();
  const [timePickerDirection, setTimePickerDirection] = useState<'up' | 'down'>(
    'up'
  );
  const [schedulingActivity, setSchedulingActivity] = useState(false);
  const [showToast] = useToast();

  const canAddTeamMember = useCanAddTeamAssociation({
    isClient,
    objectId: customObject.id,
    entity,
    associationSource: customObject.associationSource,
  });

  const canViewTeamAssociations = useCanViewTeamAssociation({
    isClient,
    objectId: customObject.id,
  });

  const canViewActivities = useCanViewScheduledActivities();

  const clickTeamBlock = useCallback(() => {
    setInfoModal({
      type: InfoBlockTypes.TEAM,
      title: t('Team Interactions'),
      action: t('Add Team Member'),
      width: 'large',
      actionBtnColor: 'green',
      hasOneBtnSubmit: true,
      hasActionButton: canAddTeamMember,
    });
  }, [t, canAddTeamMember]);

  const clickActivitiesBlock = useCallback(() => {
    setDirty(false);
    setInfoModal({
      type: InfoBlockTypes.ACTIVITIES,
      title: t('Scheduled Activities'),
      action: t('CLOSE'),
      width: 'large',
      actionBtnColor: 'blue',
      hasActionButton: true,
    });
  }, [t]);

  const {
    scheduledActivities,
    refetchScheduledActivitiesCount,
    activities,
    fetchActivities,
    completeModalProps,
    handleClickCompleteActivity,
    completeActivity,
    loggingActivity,
  } = useScheduledActivities({
    customObject,
    isClient,
    entity,
    skipErrorBoundary: true,
  });

  const logActivityActions = useMemo(() => {
    return {
      update: async (dirtyFields: unknown) => {
        if (dirtyFields) {
          await refetch();
        }
      },
      save: async () => {
        await handleSubmit({
          displayToast: true,
        });
      },
    };
  }, [refetch, handleSubmit]);

  const handleCloseAddTeamMemberModal = useCallback(
    () => setShowAddTeamMemberModal(false),
    []
  );

  const {
    associatedTeamMembers,
    fetchAssociatedTeamMembers,
    handleAddNewTeamAssociation,
    forceRefetchKey,
  } = useAssociatedTeamMembers({
    customObject,
    entity,
    closeModal: handleCloseAddTeamMemberModal,
    enabled: canViewTeamAssociations,
  });

  const handleInfoBlockAddModalOpen = useCallback(() => {
    if (infoModal) {
      switch (infoModal.type) {
        case InfoBlockTypes.ACTIVITIES:
          setInfoModal(null);
          break;
        case InfoBlockTypes.TEAM:
          setShowAddTeamMemberModal(true);
          break;
        default:
          return null;
      }
    }
    return null;
  }, [infoModal]);

  const whatFor = useMemo(() => {
    return {
      type: 'custom-objects',
      objectId: customObject?.id,
      entityId: entity?.id,
    };
  }, [customObject, entity]);

  const renderInfoModalBody = (showModal: boolean) => {
    if (infoModal) {
      switch (infoModal.type) {
        case InfoBlockTypes.ACTIVITIES:
          return (
            <ScheduledActivities
              whatFor={whatFor}
              onUpdateSources={refetchScheduledActivitiesCount}
              logActivityActions={logActivityActions}
              fieldState={fieldState}
              handleNoteUpdated={setDirty}
              fieldStateDirty={fieldStateDirty}
              updateData={refetch}
              isCustomObject={!isClient}
              scheduledActivityId={scheduledActivityId as any}
              activities={activities}
              fetchActivities={fetchActivities}
              currentEntity={entity}
              currentObject={customObject}
              disableFilterToggle
              showModal={showModal}
              inModal
            />
          );
        case InfoBlockTypes.TEAM:
          return (
            <TeamAssociations
              fetchAssociated={fetchAssociatedTeamMembers}
              countOfMember={associatedTeamMembers.count}
              isPaginated
              customObject={customObject}
              entity={entity}
              canAddTeamMember={canAddTeamMember}
              forceRefetchKey={forceRefetchKey}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  const timeStampForFirstActivitySplits = useMemo(() => {
    if (scheduledActivities?.results?.[0]?.dueDatetime) {
      const { day, time } = formatSplitTimestamp(
        scheduledActivities.results[0].dueDatetime
      );

      return { day, time };
    }

    return { day: undefined, time: undefined };
  }, [scheduledActivities]);

  const formattedTimestampForFirstActivity = useMemo(() => {
    if (timeStampForFirstActivitySplits.day) {
      const { day, time } = timeStampForFirstActivitySplits;
      return (
        <div className="flex">
          <Typography
            truncate
            decoration="underline-dashed"
            onClick={(e: any) => {
              e.stopPropagation();
              setDatePickerOpen(true);
              setTimePickerOpen(false);
            }}
          >
            {day}
          </Typography>
          <Typography>&nbsp;{t('at')}&nbsp;</Typography>
          <Typography
            truncate
            decoration="underline-dashed"
            onClick={(e: any) => {
              e.stopPropagation();
              setTimePickerOpen(true);
              setDatePickerOpen(false);
            }}
          >
            {time}
          </Typography>
        </div>
      );
    }

    return '';
  }, [t, timeStampForFirstActivitySplits]);

  const formattedTimestampForFirstInteraction = useMemo(() => {
    if (associatedTeamMembers?.values?.[0]?.lastActivityTimestamp) {
      return formatTimestamp(
        associatedTeamMembers?.values?.[0]?.lastActivityTimestamp
      );
    }

    return '';
  }, [associatedTeamMembers]);

  const handleHideDatePicker = useCallback(() => {
    setDatePickerOpen(false);
  }, []);

  const handleHideTimePicker = useCallback(() => {
    setTimePickerOpen(false);
  }, []);

  const handleChangeDateForActivity = useCallback(async () => {
    handleHideDatePicker();

    if (scheduledActivities?.results?.[0] && selectedDate) {
      const activity = scheduledActivities.results[0];
      const dateParts = activity.dueDatetime.split('T');
      const newFormattedDate = format(selectedDate, 'YYYY-MM-DD');

      const newActivity = {
        ...activity,
        dueDatetime: `${newFormattedDate}T${dateParts[1]}`,
      };

      await ActivityService.v2UpdateScheduledActivities(newActivity);
      await refetchScheduledActivitiesCount();
    }

    setSelectedDate(undefined);
  }, [
    scheduledActivities,
    selectedDate,
    refetchScheduledActivitiesCount,
    handleHideDatePicker,
  ]);

  const handleChangeTimeForActivity = useCallback(
    async (time: { value: string; label: string }) => {
      setSelectedTime(time);
    },
    []
  );

  const handleApplyTimeForActivity = useCallback(async () => {
    handleHideTimePicker();

    if (scheduledActivities?.results?.[0] && selectedTime) {
      const activity = scheduledActivities.results[0];
      const dateParts = activity.dueDatetime.split('T');
      const offset = format(new Date(), 'Z');

      const newTime = `${selectedTime.value}:00${offset}`;

      const newActivity = {
        ...activity,
        dueDatetime: new Date(`${dateParts[0]}T${newTime}`).toISOString(),
      };
      await ActivityService.v2UpdateScheduledActivities(newActivity);
      await refetchScheduledActivitiesCount();
    }

    setSelectedTime(undefined);
  }, [
    selectedTime,
    scheduledActivities,
    refetchScheduledActivitiesCount,
    handleHideTimePicker,
  ]);

  const handleScheduleActivity = async (activityInfo: any) => {
    setSchedulingActivity(true);
    await ActivityService.v2CreateScheduledActivities(activityInfo);
    showToast({
      message: SCHEDULE_ACTIVITY_SUCCESS(t),
      variant: toastVariant.SUCCESS,
    });
    refetchScheduledActivitiesCount();
    refetch();

    setSchedulingActivity(false);
  };

  const [scheduleActivityModalProps, , scheduleActivityModal] = useModal({
    handleSubmit: handleScheduleActivity,
    // handleError: handleScheduleActivityError,
  } as any);

  const [
    isConfirmActivityModalOpen,
    {
      showModal: showConfirmActivityModal,
      hideModal: hideConfirmActivityModal,
    },
  ] = useModalControl();

  const handleClickCompleteActivityWithConfirmation = useCallback(() => {
    if (touchedFormData) {
      showConfirmActivityModal();
    } else {
      handleClickCompleteActivity();
    }
  }, [touchedFormData, showConfirmActivityModal, handleClickCompleteActivity]);

  const handleDismissCompleteActivityModal = useCallback(async () => {
    resetAllFields();
    hideConfirmActivityModal();
    handleClickCompleteActivity();
  }, [handleClickCompleteActivity, hideConfirmActivityModal, resetAllFields]);

  const handleUpdate = useCallback(async () => {
    await saveFormData();
    hideConfirmActivityModal();
    handleClickCompleteActivity();
  }, [saveFormData, handleClickCompleteActivity, hideConfirmActivityModal]);

  const body = (
    <>
      <div
        className="flex justify-around w-full"
        data-qa-block-type="team-interactions"
      >
        {canViewTeamAssociations ? (
          <>
            <Block
              id="team-interactions"
              count={associatedTeamMembers.count}
              label={t('Team')}
              tooltipLabel={t('View Interactions')}
              onClickLabel={clickTeamBlock}
              defaultGap={isClient ? 15 : 30}
              metadata={
                <Metadata
                  title={t('Most Recent Interaction')}
                  subject={
                    associatedTeamMembers?.values?.[0]?.fullName ?? (
                      <Typography truncate fontStyle="italic">
                        {t('No Team Members')}
                      </Typography>
                    )
                  }
                  detail={
                    associatedTeamMembers?.values?.[0] ? (
                      <Typography truncate>
                        {formattedTimestampForFirstInteraction}
                      </Typography>
                    ) : canAddTeamMember ? (
                      <Button
                        variant="text"
                        size="xs"
                        leftIcon="action-add"
                        onClick={() => setShowAddTeamMemberModal(true)}
                      >
                        {t('New Association')}
                      </Button>
                    ) : null
                  }
                />
              }
            />
            <Divider />
          </>
        ) : null}
        <Block
          id="open-activities"
          count={scheduledActivities?.count ?? 0}
          label={
            scheduledActivities?.count === 1
              ? t('Open Activity')
              : t('Open Activities')
          }
          tooltipLabel={canViewActivities ? t('View Notes') : undefined}
          onClickLabel={canViewActivities ? clickActivitiesBlock : undefined}
          defaultGap={isClient ? 15 : 30}
          metadata={
            scheduledActivities?.results?.[0] ? (
              <Metadata
                title={t('Next Due Activity')}
                subject={
                  <Button
                    variant="text"
                    rightIcon="action-complete-activity"
                    color="secondary"
                    size="xs"
                    onClick={handleClickCompleteActivityWithConfirmation}
                    preserveCase
                  >
                    {scheduledActivities.results[0].activityObject.name}
                  </Button>
                }
                detail={
                  <div>
                    {formattedTimestampForFirstActivity}
                    <div ref={dateOverlayTarget} className="relative">
                      <Overlay
                        transition={false}
                        show={datePickerOpen}
                        popperConfig={popperConfig}
                        placement="bottom-end"
                        rootClose
                        target={dateOverlayTarget.current}
                        onHide={handleHideDatePicker}
                      >
                        <StyledDateMenuWrapper>
                          <DatePicker
                            value={
                              selectedDate ||
                              timeStampForFirstActivitySplits.day
                            }
                            onSelect={setSelectedDate}
                            onHide={handleChangeDateForActivity}
                            showClearButton={false}
                          />
                        </StyledDateMenuWrapper>
                      </Overlay>
                    </div>
                    <div
                      ref={(element) => {
                        timeOverlayTarget.current = element;
                        const top = element?.getBoundingClientRect().top ?? 201;
                        setTimePickerDirection(top < 200 ? 'down' : 'up');
                      }}
                      className="relative"
                    >
                      <Overlay
                        show={timePickerOpen}
                        transition={false}
                        popperConfig={popperConfig}
                        placement="bottom-end"
                        rootClose
                        target={timeOverlayTarget.current}
                        onHide={handleHideTimePicker}
                      >
                        <div>
                          <TimePickerMenu
                            classNamePrefix="TimePicker"
                            menuIsOpen
                            value={
                              selectedTime
                                ? selectedTime.value
                                : scheduledActivities?.results?.[0]
                                  ? format(
                                      scheduledActivities?.results?.[0]
                                        .dueDatetime,
                                      'HH:mm'
                                    )
                                  : undefined
                            }
                            onChange={handleChangeTimeForActivity}
                            direction={timePickerDirection}
                            onApply={handleApplyTimeForActivity}
                          />
                        </div>
                      </Overlay>
                    </div>
                  </div>
                }
              />
            ) : (
              <Metadata
                title={t('Next Due Activity')}
                subject={
                  <Typography truncate fontStyle="italic">
                    {/* non-breaking space is required so that the ascender on the italic "d" doesn't get cut off */}
                    {t('Nothing Scheduled')}&nbsp;
                  </Typography>
                }
                detail={
                  hasScheduledActivityPermission ? (
                    <Button
                      variant="text"
                      size="xs"
                      leftIcon="action-add"
                      onClick={() => {
                        (scheduleActivityModal as any)?.show?.();
                      }}
                    >
                      {t('Schedule Activity')}
                    </Button>
                  ) : null
                }
              />
            )
          }
        />
      </div>
      {infoModal ? (
        <BasicModalWithConfirmation
          show
          buttonText={infoModal.action}
          heading={infoModal.title}
          leftBtn={infoModal.type === InfoBlockTypes.TEAM ? undefined : null}
          defaultLeftBtnText={t('Cancel')}
          size={infoModal.width || 'medium'}
          onConfirm={handleInfoBlockAddModalOpen}
          onHide={() => setInfoModal(null)}
          displayFlex={[
            InfoBlockTypes.ACTIVITIES,
            InfoBlockTypes.TEAM,
          ].includes(infoModal.type)}
          className="BasicModal"
          actionButton={infoModal.hasActionButton}
          actionBtnColor={infoModal.actionBtnColor}
          hasOneBtnSubmit={infoModal.hasOneBtnSubmit}
          enforceFocus={false}
          dirty={dirty}
        >
          <ModalContentWrapper type={infoModal.type}>
            {renderInfoModalBody(Boolean(infoModal))}
          </ModalContentWrapper>
        </BasicModalWithConfirmation>
      ) : null}
      {showAddTeamMemberModal ? (
        <AddTeamAssociationModal
          onHide={() => setShowAddTeamMemberModal(false)}
          onConfirm={handleAddNewTeamAssociation}
        />
      ) : null}
      {completeModalProps.show ? (
        <CompleteActivityModal
          {...completeActivity}
          {...completeModalProps}
          disabled={loggingActivity}
          predefinedOptions={undefined}
        />
      ) : null}
      <ScheduleActivityModal
        currentEntity={entity}
        currentObject={customObject}
        {...scheduleActivityModalProps}
        disabled={schedulingActivity}
      />
      <ConfirmationModal
        show={isConfirmActivityModalOpen}
        onConfirm={handleUpdate}
        onHide={hideConfirmActivityModal}
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        onAdditionalConfirm={handleDismissCompleteActivityModal}
        actionBtnColor="green"
        buttonText={t('Save')}
      >
        {t(
          'The record has unsaved changes. Would you like to save them or discard them before logging your activity?'
        )}
      </ConfirmationModal>
    </>
  );

  return <VerticalTile middle={<Panel>{body}</Panel>} />;
};

export const TeamActivities = withErrorBoundary(BlockComponent);
