import { getOptionValue, namedToOptionDeleted } from 'services/helpers';

import { setDelayAmount } from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { tagId, tagOperation, delayAmount } = orgDetails;
  return {
    stepGoal: {
      ...orgDetails,
      delayAmount: setDelayAmount(delayAmount),
      triggers: [
        {
          type: stepConfig.contactTagAddedRemoved.type,
          triggerContactTagAddedRemoved: {
            tagOperation,
            tagId: getOptionValue(tagId),
          },
        },
      ],
    },
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { tag, tagOperation } = firstTrigger.triggerContactTagAddedRemoved;
  let tagId = null;
  if (!tag) {
    addErrorMessage(messageDictionary.thereWasanErrorLoadingTheAssociatedTag);
  } else {
    tagId = namedToOptionDeleted(tag);
  }
  const type = stepConfig.contactTagAddedRemovedGoal.type;

  return { tagId, tagOperation, type };
};

export const contactTagAddedRemovedDTO = {
  forApi,
  forFlow,
};
