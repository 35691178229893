import { useCallback, useMemo } from 'react';
import { clamp } from 'utility/clamp';
import { range } from 'utility/iterables';

const SEGMENTS = 60;
const INCREMENT = 1 / SEGMENTS;
const PERCENTAGES = Array.from(range(0, 1, INCREMENT)).reduce(
  (acc, inc, idx) => {
    acc[idx] = inc;
    return acc;
  },
  { [SEGMENTS]: 1 }
);
const MIN_COLUMN_SEGMENTS = 5;
const MIN_COLUMN_PERCENT = PERCENTAGES[MIN_COLUMN_SEGMENTS];

const getSegmentsFromPercent = (percent) => {
  return Math.round(percent * SEGMENTS);
};

/**
 * Checks if a value is greater than or equal to one. We want to round the remainder
 * only when it is very close to the next integer (0.9999999)
 *
 * @example isRemainderGreaterThanOneSegment(0.99999999) // true
 * @example isRemainderGreaterThanOneSegment(0.66666666) // false
 *
 * @param {number} remainder
 * @returns boolean
 */
const isRemainderGreaterThanOneSegment = (remainder) => {
  return remainder >= 1 || remainder.toFixed(2).endsWith('.00');
};

/**
 * Gets the quotient of a decimal value while rounding the decimal iff it is very
 * close to the next integer (0.9999999 or 1.9999999).
 *
 * @example getQuotient(0.99999987) // 1
 * @example getQuotient(1.99999999) // 2
 * @example getQuotient(1.66666667) // 1
 * @example getQuotient(0.5) // 0
 *
 * @param {number} remainder
 * @returns number
 */
const getQuotient = (remainder) => parseInt(remainder.toFixed(2)[0]);

export const useResizeDragHandlers = (numColumns, rowWidth, setState) => {
  return useMemo(() => {
    return Array.from(range(0, numColumns)).map((column) => {
      let cumulativeMovement = 0;
      let updatedLastDrag = false;

      // subtract the minimum size of the two columns being operated on from the calculation in order to bump up the speed
      const totalWidth = rowWidth - rowWidth * MIN_COLUMN_PERCENT * 2;

      return ({ movementX }) => {
        if (updatedLastDrag) {
          // disregard the drag event immediately after updating the width. Updating the widths causes the
          // movementX value to be a movement in the other direction (mouse didn't move but the container
          // got closer to it).
          updatedLastDrag = false;
          return;
        }

        cumulativeMovement = movementX + cumulativeMovement;
        const rightDir = cumulativeMovement > 0 ? -1 : 1;
        const leftDir = cumulativeMovement > 0 ? 1 : -1;
        const steps = Math.floor(
          Math.abs(cumulativeMovement / totalWidth) / INCREMENT
        );

        if (steps > 0) {
          setState((prev) => {
            const right = prev[column];
            const left = prev[column - 1];
            const rightSegments = getSegmentsFromPercent(right);
            const leftSegments = getSegmentsFromPercent(left);
            const maxSteps = Math.min(
              clamp(rightSegments - MIN_COLUMN_SEGMENTS, 1, SEGMENTS),
              clamp(leftSegments - MIN_COLUMN_SEGMENTS, 1, SEGMENTS),
              steps
            );
            const newRight = PERCENTAGES[rightSegments + maxSteps * rightDir];
            const newLeft = PERCENTAGES[leftSegments + maxSteps * leftDir];

            if (
              (right === MIN_COLUMN_PERCENT && newRight < MIN_COLUMN_PERCENT) ||
              (left === MIN_COLUMN_PERCENT && newLeft < MIN_COLUMN_PERCENT)
            ) {
              // update nothing when a column is already at the minimum and the user is attempting to make
              // it smaller (we can't increase the size of one column without taking from the other)
              return prev;
            }

            return prev
              .slice(0, column - 1)
              .concat(newLeft, newRight, prev.slice(column + 1, prev.length));
          });
          updatedLastDrag = true;
          cumulativeMovement = 0;
        }
      };
    });
  }, [numColumns, rowWidth, setState]);
};

/**
 * Provides a React callback function to add a column to an existing row. Row column widths are
 * stored as percents represented as decimal values (i.e. the sum of the widths array equals one).
 * When calculating new widths, we remove size from each existing column relative to their current
 * size (i.e. more is taken from larger columns than smaller ones). Also, we cannot take any size
 * from columns already at the minimum (5 units).
 *
 * @remarks Here is an overview of the process used to ensure new widths are always set in a 1/60 increment
 * - Calculate the new size (integer 1-60) of the new column to add.
 * - Convert the current decimal values to 1-60 integer sizes.
 * - Calculate the relative sizes of each existing column (array of percents).
 * - Calculate the new widths by subtracting out it's proportion of the new column size. While building
 * out these values always round down and collect the remainder and identify the largest column.
 * - If the remainder is greater than one, we still have to reduce segments to have 60 units after adding
 * the new column - take the remainder out of the largest column.
 * - Convert 1-60 size units back to decimals and return.
 *
 * @param {*} setState - a React useState setter function (setWidths from Row).
 * @returns - useCallback
 */
export const useAddColumn = (setState) => {
  return useCallback(() => {
    setState((prev) => {
      const newColumnSize = SEGMENTS / (prev.length + 1);
      const currentSizes = prev.map((w) => Math.round(w * SEGMENTS));
      const reductionPercents = currentSizes.map((s, _, arr) => {
        return (
          (s - MIN_COLUMN_SEGMENTS) /
          (SEGMENTS - MIN_COLUMN_SEGMENTS * arr.length)
        );
      });
      const { newWidths, remainder, largestIndex } = reductionPercents.reduce(
        (acc, p, i) => {
          const reduction = p === 0 ? 0 : clamp(p * newColumnSize, 1, SEGMENTS);
          const newWidth = currentSizes[i] - Math.floor(reduction);
          acc.newWidths.push(newWidth);
          acc.remainder += reduction % 1;
          if (newWidth > acc.largest) {
            acc.largest = newWidth;
            acc.largestIndex = i;
          }
          return acc;
        },
        { newWidths: [], remainder: 0, largest: 0, largestIndex: -1 }
      );
      if (isRemainderGreaterThanOneSegment(remainder)) {
        newWidths[largestIndex] = clamp(
          newWidths[largestIndex] -
            Math.floor(getQuotient(remainder), MIN_COLUMN_SEGMENTS, SEGMENTS)
        );
      }
      return newWidths.concat(newColumnSize).map((s) => s / SEGMENTS);
    });
  }, [setState]);
};

/**
 * Provides functions for each column in the Row to remove that column. The size of the
 * removed column is added to the other columns relative to their existing size (i.e. larger
 * columns recieve more than smaller ones).
 *
 * @remarks New columns sizes are calculated with the following process
 * - Calculate the size (1-60 integer value) of the removed column and the size of the
 * remaining columns.
 * - Calculate the new width of each column by adding a percent of the removed column's
 * size to the current size - always round down.
 * - Calculate the number of segments not added into the remaining columns due to rounding down.
 * - Sort the remaining columns largest to smallest and add one additional segment into the
 * largest columns until all 60 segments have been accounted for.
 * - Convert integer sized back to decimals and return.
 *
 * @param {number} columns - number of columns in the row.
 * @param {*} setState - a React useState setter function (setWidths from Row).
 * @returns - memoized Function[]
 */
export const useRemoveColumn = (columns, setState) =>
  useMemo(() => {
    return Array.from(range(columns)).map((index) => {
      return () => {
        setState((prev) => {
          const removedSize = Math.round(prev[index] * SEGMENTS);
          const remainingTotal = prev.reduce((acc, w, idx) => {
            if (idx === index) return acc;
            return acc + Math.round(w * SEGMENTS);
          }, 0);
          const newWidths = prev.reduce((acc, w, idx) => {
            if (index === idx) return acc;
            const size = Math.round(w * SEGMENTS);
            const percentOfRemovedSize = size / remainingTotal;
            const addition = Math.floor(percentOfRemovedSize * removedSize);
            acc.push(addition + w * SEGMENTS);
            return acc;
          }, []);

          const shortfall = SEGMENTS - newWidths.reduce((acc, x) => acc + x, 0);
          const sortedLargestIndexes = newWidths
            .map((x, i) => [x, i])
            .sort((a, b) => b[0] - a[0])
            .map(([_, index]) => index);
          for (const index of sortedLargestIndexes.slice(0, shortfall)) {
            newWidths[index] += 1;
          }
          return newWidths.map((x) => x / SEGMENTS);
        });
      };
    });
  }, [columns, setState]);
