import React, { useContext } from 'react';
import * as PropTypes from 'prop-types';
import { HorizontalDropzone } from 'components/DragAndDropLayout/Dropzone';
import { DraggingContext } from './draggingContext';
import { useObservable } from './behaviorSubject';

export const CardDropzone = ({ id, position, props }) => {
  const draggingCtxt = useContext(DraggingContext);
  const cardDropzone = useObservable(draggingCtxt.cardDropzone);

  if (cardDropzone?.id === id && cardDropzone?.position === position) {
    return <HorizontalDropzone {...props} />;
  }
  return null;
};

CardDropzone.propTypes = {
  id: PropTypes.string.isRequired,
  postion: PropTypes.string.isRequired,
};

export const StageDropzone = ({ id, props }) => {
  const draggingCtxt = useContext(DraggingContext);
  const stageDropzone = useObservable(draggingCtxt.stageDropzone);

  if (stageDropzone?.id === id) {
    return <HorizontalDropzone {...props} />;
  }
  return null;
};

StageDropzone.propTypes = {
  id: PropTypes.string.isRequired,
};
