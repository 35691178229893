import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TextSpan } from 'app/typography';
import { colorsPrimary, grayScale } from 'app/colors';

const Wrapper = styled(TextSpan)`
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${({ isRole }) =>
    isRole ? colorsPrimary.purple.light : colorsPrimary.blue.light};
  color: ${grayScale.white};
  font-size: 11px;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
`;

function getInitials(fullName) {
  const initials = (fullName || '')
    .split('(')[0] // remove any (email) suffix
    .trim()
    .split(/\s+/)
    .map((part) => part.charAt(0).toUpperCase());

  if (initials.length === 0) {
    return '';
  }
  if (initials.length === 1) {
    return initials[0];
  }
  return initials[0] + initials[initials.length - 1];
}

export default function Avatar({ fullName, ...others }) {
  return <Wrapper {...others}>{getInitials(fullName)}</Wrapper>;
}

Avatar.propTypes = {
  fullName: PropTypes.string.isRequired,
};
