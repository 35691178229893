import { useRef } from 'react';

export const useHorizontalScrollWhilePlacing = (placing, columnWidth) => {
  const stateRef = useRef({
    x: 0,
    nativeEvent: null,
    container: null,
    mouseOnContainer: false,
    moveInterval: null,
    speed: 0,
  });

  return {
    onMouseMove: placing ? () => {} : null,
    onMouseEnter: () => {
      stateRef.current.mouseOnContainer = true;
    },
    onMouseLeave: () => {
      stateRef.current.mouseOnContainer = false;
    },
  };
};

export const useVerticalScrollWhilePlacing = (
  placing,
  cardHeight,
  onMouseMove
) => {
  const stateRef = useRef({
    y: 0,
    nativeEvent: null,
    container: null,
    mouseOnContainer: false,
    moveInterval: null,
    speed: 0,
  });

  return {
    onMouseMove: placing
      ? (ev) => {
          onMouseMove(ev);
        }
      : null,
    onMouseEnter: () => {
      stateRef.current.mouseOnContainer = true;
    },
    onMouseLeave: () => {
      stateRef.current.mouseOnContainer = false;
    },
  };
};
