import { useTranslation } from 'react-i18next';
import { getOptionNameWithEmail, getOptionName } from 'services/helpers';
import { getValueOrDeleted } from './action-helpers';
import {
  SA_TYPE_TEAM_MEMBER,
  SA_TYPE_LAST_ACTIVE_TEAM_MEMBER_SPEC_ROLE,
  SA_TYPE_ROUND_ROBIN_ROLE,
  SA_TYPE_ROUND_ROBIN_TEAM_MEMBERS,
  SA_TYPE_LAST_ACTIVE_TEAM_MEMBER,
  SA_TYPE_SPEC_ROLE,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/ScheduleActivity/common';
import DraggableStepCard from '../../DraggableStepCard';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  const {
    assignedTo: { assignmentType = {}, roleId = {}, employee, employeeId },
    activityType = {},
  } = step.details;
  const roleLabel = getValueOrDeleted(roleId?.label, t);

  const mapped = `Schedule Activity ‘${getValueOrDeleted(
    activityType?.label,
    t
  )}’ to`;
  switch (assignmentType.value) {
    case SA_TYPE_ROUND_ROBIN_ROLE:
      return `${mapped} Round Robin (${roleLabel} Role)`;
    case SA_TYPE_LAST_ACTIVE_TEAM_MEMBER_SPEC_ROLE:
      return `${mapped} Last Active (${roleLabel} Role)`;
    case SA_TYPE_LAST_ACTIVE_TEAM_MEMBER:
      return `${mapped} Last Active (Any Role)`;
    case SA_TYPE_ROUND_ROBIN_TEAM_MEMBERS:
      return `${mapped} Round Robin (Specific Team)`;
    case SA_TYPE_SPEC_ROLE:
      return `${mapped} ${roleLabel} Role`;
    case SA_TYPE_TEAM_MEMBER:
      return `${mapped} ${
        employee && !employee?.deleted
          ? getOptionNameWithEmail(employee)
          : getValueOrDeleted(getOptionName(employeeId || {}), t)
      }`;
    default:
      return `${mapped} ${assignmentType?.label}`;
  }
};

export default function ScheduleActivityCard({ step, ...others }) {
  const { t } = useTranslation();
  return (
    <DraggableStepCard {...others}>{content({ step, t })}</DraggableStepCard>
  );
}
