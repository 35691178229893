import { deleteFile, updateFile, uploadFile } from '@kizen/api/file';
import UUID from 'utility/UUID';
import AxiosService from './AxiosService';
import { camelToSnakeCaseKeys } from './helpers';
import { FIELD_VALUE_FILE_UPLOAD_SOURCE } from 'hooks/uploadFiles/useUploadFile';

class FileService {
  constructor() {
    if (!FileService.instance) {
      FileService.instance = this;
    }
    const {
      VITE_FILE_UPLOAD_S3_BUCKET,
      VITE_AWS_ACCESS_KEY_ID,
      VITE_AWS_REGION,
      VITE_FILE_UPLOAD_S3_URL,
    } = import.meta.env;
    this.bucket = VITE_FILE_UPLOAD_S3_BUCKET;
    this.awsAccessKeyId = VITE_AWS_ACCESS_KEY_ID;
    this.awsRegion = VITE_AWS_REGION;
    this.s3URL = (
      VITE_FILE_UPLOAD_S3_URL || 'https://{{bucket}}.s3.amazonaws.com/'
    ).replace('{{bucket}}', VITE_FILE_UPLOAD_S3_BUCKET);
    return FileService.instance;
  }

  createId = () => UUID.generate();

  getThumbnailUrl = (id) =>
    `${import.meta.env.VITE_API_BASE_PATH}/files/${id}/thumbnail`;

  getImageByCommonKey = async (commonKey) => {
    const {
      data: { results = [] },
    } = await AxiosService.get('/images', {
      params: {
        common_key: commonKey,
      },
    });
    return results.find((q) => q.common_key === commonKey);
  };

  // In order to upload a file, we need to obtain some signing info (basically, permission to talk directly to S3)
  // prior to performing the upload.  Then we tell the API we're done so that it can keep a record of the file.
  upload = async ({
    file,
    id,
    source = FIELD_VALUE_FILE_UPLOAD_SOURCE,
    handleProgress,
    businessId,
    publicFile = false,
    usePublicEndpoint = false,
  }) => {
    const envConfig = {
      AWS_ACCESS_KEY_ID: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
      AWS_REGION: import.meta.env.VITE_AWS_REGION,
      S3_BUCKET: import.meta.env.VITE_FILE_UPLOAD_S3_BUCKET,
      S3_URL: import.meta.env.VITE_FILE_UPLOAD_S3_URL,
    };
    return uploadFile(AxiosService, envConfig, file, id, businessId, {
      source,
      publicFile,
      usePublicEndpoint,
      handleProgress,
    });
  };

  delete = async ({ id, key }) => {
    await deleteFile(AxiosService, this.bucket, id, key);
  };

  update = async ({ id, params }) => {
    const { data: file } = await updateFile(
      AxiosService,
      id,
      camelToSnakeCaseKeys(params)
    );
    return file;
  };

  async getPublicFiles() {
    const { data } = await AxiosService.get(`/files?is_public=true`);
    return data;
  }

  async getPublicFile(id) {
    const { data } = await AxiosService.get(`/files/${id}`);
    return data;
  }
}

const instance = new FileService();
Object.freeze(instance);

export default instance;
