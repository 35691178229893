import { createContext } from 'react';

const defaultBS = {
  subscribe: () => {},
  unsubscribe: () => {},
  next: () => {},
  getValue: () => {},
};
export const DraggingContext = createContext({
  draggingData: defaultBS,
  cardDropzone: defaultBS,
  stageDropzone: defaultBS,
});

export const MouseContext = createContext({
  subscribe: () => {},
  unsubscribe: () => {},
  next: () => {},
});
