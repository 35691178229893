import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile, useWindowSize } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import { getSearchParam, setSearchParams } from 'hooks/useSearchParam';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { camelToSnakeCase, snakeToCamelCase } from 'services/helpers';
import DesktopLayout from './DesktopLayout';
import { useDebounce } from 'react-use';
import {
  buildPage,
  getAccounts,
  setPageConfig,
  deleteAccount,
} from 'store/adManagerPage/adManager.redux';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { useTranslation } from 'react-i18next';
import { actions } from './columns';
import {
  updateAccount,
  updatePageConfig,
} from 'store/adManagerPage/adManager.redux';
import ConnectionsListPage from './ListPage';
import { maybeTrackSearch } from 'utility/analytics';
import { DEFAULT_DELAY } from 'utility/config';
import { deferExecution } from 'utility/defer';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

const ConnectionsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();
  const isFetching = useSelector((s) => s.adManagerPage.isFetching);
  const pageConfig = useSelector(
    (s) => s.adManagerPage.pageConfig?.connections
  );
  const connections = useSelector((s) => s.adManagerPage.connections);
  const connectionsCount = useSelector((s) => s.adManagerPage.connectionsCount);
  const businessId = useSelector((s) => s.authentication.chosenBusiness.id);

  // Track debounced changes to the search term and send to google analytics
  const { search } = pageConfig;
  useDebounce(
    () => maybeTrackSearch(search, 'adManager/connections'),
    DEFAULT_DELAY,
    [search]
  );

  const [deletedAccount, setAccountForDelete] = useState(null);
  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const page = getSearchParam(history.location, 'page');
    const search = getSearchParam(history.location, 'q');
    const size = getSearchParam(history.location, 'size');

    syncDispatch(
      buildPage({
        search,
        type: 'connections',
        sort,
        page: page && parseInt(page, 10),
        size: size && parseInt(size, 50),
      })
    );
  }, [syncDispatch, history]);

  const sortValue = useMemo(
    () => getSortValue(snakeToCamelCase(pageConfig.sort)),
    [pageConfig?.sort]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(setPageConfig({ type: 'connections', page: value }));
      dispatch(getAccounts());
      deferExecution(() => {
        setSearchParams(history, { page: value });
      });
    },
    [dispatch, history]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(setPageConfig({ type: 'connections', size: value }));
      dispatch(getAccounts());
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleSelectAction = useCallback(
    async ({ value }, adAccount) => {
      switch (value) {
        case actions.delete: {
          setAccountForDelete(adAccount.id);
          break;
        }
        case actions.resync: {
          dispatch(updateAccount({ ...adAccount, status: 'active' }));
          break;
        }
        case actions.deactivate: {
          dispatch(updateAccount({ ...adAccount, status: 'inactive' }));
          break;
        }
        default: {
        }
      }
    },
    [dispatch]
  );

  const handleSortTable = useCallback(
    ({ column, direction }) => {
      const data = camelToSnakeCase(column);
      const value = getOrderingParam({ column: data, direction });

      dispatch(setPageConfig({ type: 'connections', sort: value }));
      dispatch(getAccounts());
      deferExecution(() => {
        setSearchParams(history, {
          sort: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleChangeSearch = useCallback(
    (q) => {
      dispatch(
        updatePageConfig({
          connections: {
            search: q,
            page: 1,
          },
        })
      );
      dispatch(getAccounts());
      deferExecution(() => {
        setSearchParams(history, { q, page: null }, { method: 'replace' });
      });
    },
    [dispatch, history]
  );

  const handleDeleteTemplate = useCallback(() => {
    dispatch(deleteAccount(deletedAccount));
    setAccountForDelete(null);
  }, [dispatch, deletedAccount]);

  if (isFetching) {
    return <Loader loading />;
  }

  return (
    <>
      {isMobile(width) ? (
        <ConnectionsListPage
          handleChangeSearch={handleChangeSearch}
          onChangePageNumber={handleChangePageNumber}
          search={search}
          pageNumber={pageConfig.page}
          onChangeSort={handleSortTable}
          pageSize={pageConfig.size}
          sort={sortValue}
          onChangePageSize={handleChangePageSize}
          connections={connections}
          connectionsCount={connectionsCount}
          handleSelectAction={handleSelectAction}
        />
      ) : (
        <DesktopLayout
          connections={connections}
          connectionsCount={connectionsCount}
          pageNumber={pageConfig.page}
          pageSize={pageConfig.size}
          search={search}
          handleChangePageNumber={handleChangePageNumber}
          handleChangePageSize={handleChangePageSize}
          sort={sortValue}
          handleChangeSort={handleSortTable}
          businessId={businessId}
          handleSelectAction={handleSelectAction}
          handleChangeSearch={handleChangeSearch}
        />
      )}
      <ConfirmDeletionModal
        show={Boolean(deletedAccount)}
        onConfirm={handleDeleteTemplate}
        onHide={() => setAccountForDelete(null)}
      >
        {t(
          'This will permanently delete the Connected Account and stop future ad data from populating.'
        )}
      </ConfirmDeletionModal>
    </>
  );
};
export default ConnectionsPage;
