import { useEffect, useMemo } from 'react';
import { useSetUnregisteredVisibility } from './getters';
import { VERTICAL_VISIBILITY_LOOKAHEAD } from './useVisibility';

export const useBasicVisibility = ({ id, ref, shouldRegister = false }) => {
  const setVisibility = useSetUnregisteredVisibility();

  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        if (entries[0]) {
          const { isIntersecting } = entries[0];
          setVisibility(id, isIntersecting);
        }
      },
      {
        rootMargin: `${VERTICAL_VISIBILITY_LOOKAHEAD}px 0px`,
      }
    );
  }, [setVisibility, id]);

  useEffect(() => {
    const elem = ref?.current;

    if (elem && shouldRegister) {
      observer.observe(elem);
    }
    return () => {
      if (elem && shouldRegister) {
        observer.unobserve(elem);
      }
    };
  }, [observer, ref, shouldRegister]);
};
