import React from 'react';
import PropTypes from 'prop-types';
import { MaybeInputControl } from '../InputControl';
import OutlineYesNoMaybe from './OutlineYesNoMaybe';
import UnderlineYesNoMaybe from './UnderlineYesNoMaybe';

const YesNoMaybe = React.forwardRef(({ variant, ...props }, ref) => {
  const VariantComponent =
    variant === 'underline' ? UnderlineYesNoMaybe : OutlineYesNoMaybe;

  return (
    <MaybeInputControl variant={variant} forInput {...props}>
      <VariantComponent ref={ref} {...props} />
    </MaybeInputControl>
  );
});

YesNoMaybe.displayName = 'YesNoMaybe';
YesNoMaybe.propTypes = {
  variant: PropTypes.oneOf(['outline', 'underline']),
};
YesNoMaybe.defaultProps = {
  variant: 'outline',
};

export default YesNoMaybe;
