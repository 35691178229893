import { useMemo, forwardRef, useCallback } from 'react';

import FieldService from 'services/FieldService';

import { getValueForPayload } from 'components/Modals/CreateEntity/helpers';
import {
  isSummarizedValue,
  scrollFieldIntoView,
} from 'components/Fields/helpers';

import {
  getFieldAccessPermissions,
  dummyAccessObject,
  isDefaultDynamicTag,
  isTeamSelector,
} from 'components/Fields/FieldInput/helpers';

import { getDisplayNameTooltipProps } from 'components/Inputs/inline/WithTooltip';

export const withCellWrapper = (Component) => {
  // return the Component wrapped with our cell wrapper

  return forwardRef((props, ref) => {
    const {
      value: orgValue,
      object = dummyAccessObject,
      field,
      isCustomObjects,
      column,
      onSubmit: onSubmitProp,
      showDisplayNameTooltip,
      forceReadOnly = false,
      supportsInfiniteScroll,
    } = props;

    const value =
      orgValue ?? FieldService.getFieldValue(object, field, isCustomObjects);

    // default fields handle setting up the patch
    const onSubmit = useCallback(
      async (...args) => {
        const { isDefault } = field;
        const fieldId = field.isDefault ? field.name : field.id;
        if (isCustomObjects) {
          if (isDefault) {
            return await onSubmitProp(...args);
          }

          const value = args[0];

          const patch = {
            fields: isSummarizedValue(value)
              ? [
                  {
                    id: field.id,
                    addValues: (value.addValues || []).map(getValueForPayload),
                    removeValues: (value.removeValues || []).map(
                      getValueForPayload
                    ),
                  },
                ]
              : [
                  {
                    id: fieldId,
                    value: getValueForPayload(...args),
                  },
                ],
          };
          await onSubmitProp(object.id, patch, column);
        } else {
          // contacts

          // if the field is a default dynamictag field, we need to pass the value directly
          const patch = {
            [fieldId]:
              isDefaultDynamicTag(field) || isTeamSelector(field)
                ? args[0]
                : getValueForPayload(...args),
          };
          await onSubmitProp(object.id, patch, column);
        }
      },
      [field, object, column, onSubmitProp, isCustomObjects]
    );

    const [editable, viewable] = useMemo(
      () => getFieldAccessPermissions(field, object),
      [field, object]
    );

    if (!viewable) {
      return null;
    }

    return (
      <Component
        ref={ref}
        {...props}
        value={value}
        orgValue={orgValue}
        object={object}
        field={field}
        onSubmit={onSubmit}
        readOnly={forceReadOnly || !editable || props.readOnly}
        editable={!forceReadOnly && editable}
        viewable={viewable}
        onAutoFocus={scrollFieldIntoView}
        supportsInfiniteScroll={supportsInfiniteScroll}
        {...getDisplayNameTooltipProps(showDisplayNameTooltip, field)}
      />
    );
  });
};

export const EMPTY_VALUE = '—';
