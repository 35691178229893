import { useCallback, useEffect, useRef, useState } from 'react';
import { applyRef } from 'components/Inputs/props';

//simple implementation for cases when outer xform is not supposed to change the length of string value
// like length limitation or symbol by symbol replacement
export const usePreserveCaretPosition = (value = '', enabled = true) => {
  const inpuRef = useRef(null);
  const caretPosition = useRef(value?.length);

  caretPosition.current = inpuRef?.current?.selectionStart;

  const mergeRef = useCallback((el) => {
    applyRef(inpuRef, el);
  }, []);

  useEffect(() => {
    if (enabled && inpuRef.current && caretPosition.current) {
      inpuRef.current.selectionStart = inpuRef.current.selectionEnd =
        caretPosition.current;
    }
  }, [value, enabled]);

  return mergeRef;
};

//more complex implementation for cases when xform can change the length of string value
export const usePreserveCaretPositionActive = ({
  initialValue = '',
  xform,
  enabled = true,
}) => {
  const ref = useRef(null);
  const caretPosition = useRef(initialValue.length);
  const xformRef = useRef(xform || ((v) => v));
  const [innerValue, setInnerValue] = useState(initialValue);
  const [value, setValue] = useState('');

  caretPosition.current = ref?.current?.selectionStart;

  const inputRef = useCallback((el) => {
    applyRef(ref, el);
  }, []);

  useEffect(() => {
    if (
      enabled &&
      ref.current &&
      caretPosition.current &&
      innerValue !== value
    ) {
      ref.current.selectionStart = ref.current.selectionEnd =
        caretPosition.current - (innerValue.length - value.length);
      setInnerValue(value);
    }
  }, [innerValue, value, enabled]);

  const onChange = useCallback(
    (v) => {
      setInnerValue(v);
      enabled && setValue(xformRef.current(v));
    },
    [enabled]
  );

  useEffect(() => {
    onChange(initialValue);
  }, [initialValue, onChange]);

  return { inputRef, value: enabled ? value : innerValue, onChange };
};
