import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Modal as BaseModal } from 'react-bootstrap';
import { ContentWidth, PageSizing } from 'components/Layout/PageContentWidth';
import { grayScale } from 'app/colors';
import { scrollbarCss } from 'app/spacing';

const Modal = styled(BaseModal)`
  .modal-content {
    border: none;
    border-radius: 0;
  }
  .modal-dialog {
    display: flex;
    width: auto;
    min-width: 100%;
    margin: 0;
    min-height: auto;
    height: 100%;
  }
`;

const Header = styled(({ contentWidth, children, ...props }) => (
  <PageSizing {...props}>
    <ContentWidth contentWidth={contentWidth}>{children}</ContentWidth>
  </PageSizing>
))`
  display: flex;
  align-items: center;
  height: 56px;
  background-color: ${grayScale.white};
`;

const Body = styled.div`
  flex: 1;
  overflow-y: auto;
  ${scrollbarCss}
`;

const FullscreenModal = forwardRef(
  ({ header, contentWidth, children, ...props }, ref) => {
    return (
      <Modal animation={false} {...props}>
        <Header contentWidth={contentWidth}>{header}</Header>
        <Body ref={ref}>{children}</Body>
      </Modal>
    );
  }
);

FullscreenModal.defaultProps = {
  header: null,
};
export default FullscreenModal;
