import { map } from '@thi.ng/transducers';
import { ISubscription, SyncTuple } from '@thi.ng/rstream';
import { PickPartial } from './types';
import { CombinatorRuleDef, RuleDef, RuleResult, SingleRuleDef } from './types';
import {
  isMapKeysXformDef,
  isReturnXformDef,
  isTakeXformDef,
  isTakeFirstXformDef,
  isCombinatorRule,
} from './utils';

type SyncStreamValue<T> = SyncTuple<
  Record<string, ISubscription<RuleResult<T>, RuleResult<T>>>
>;

export type CombinatorResult<T> = RuleResult<SyncStreamValue<T>>;

export type ReturnResult<T> = PickPartial<RuleResult<Partial<T>>, 'value'>;

export const orTransducer = <T>() =>
  map<SyncStreamValue<T>, CombinatorResult<T>>((value) => {
    if (Object.values(value).some(({ result }) => result === true)) {
      return { value, result: true };
    }
    return { value, result: false };
  });

export const andTransducer = <T>() =>
  map<SyncStreamValue<T>, CombinatorResult<T>>((value) => {
    if (Object.values(value).every(({ result }) => result === true)) {
      return { value, result: true };
    }
    return { value, result: false };
  });

export const takeTransducer = <T>(rule: RuleDef<any, any, T>) => {
  return map<CombinatorResult<T>, ReturnResult<T>>((v) => {
    const xform_def = rule[rule.length - 1];

    if (isTakeXformDef(xform_def)) {
      const key = Object.keys(v.value).find((k) =>
        k.startsWith(xform_def.take.toString())
      );
      return key
        ? {
            result: v.result,
            value: v.value[key].value,
            text: v.value[key].text,
          }
        : { result: false };
    }
    return { result: false };
  });
};

export const takeFirstTransducer = <T>(
  _rule: CombinatorRuleDef<any, any, T>
) => {
  return map<CombinatorResult<T>, ReturnResult<T>>((v) => {
    const first_success = Object.entries(v.value)
      .sort(([a], [b]) => {
        const a_int = parseInt(a[0]);
        const b_int = parseInt(b[0]);
        return a_int === b_int ? 0 : b_int < a_int ? 1 : -1;
      })
      .map(([_, value]) => value)
      .find(({ result }) => result === true);

    return first_success
      ? {
          result: true,
          value: first_success.value,
          text: first_success.text,
        }
      : { result: false };
  });
};

export const returnTransducer = <T>(rule: RuleDef<any, any, T>) => {
  return map<RuleResult<any> | CombinatorResult<any>, ReturnResult<T>>((v) => {
    const xform_def = rule[rule.length - 1];
    if (v.result && isReturnXformDef<T>(xform_def)) {
      return {
        result: v.result,
        value: xform_def.return,
        text: xform_def.text,
      };
    }
    return { result: false };
  });
};

// Note: mapKeysTransducer isn't used in any existing rules
export const mapKeysTransducer = <T>(rule: RuleDef<any, any, T>) => {
  return map<CombinatorResult<T>, ReturnResult<T>>((v) => {
    const xform_def = rule[rule.length - 1];

    if (!isMapKeysXformDef(xform_def)) {
      return { result: false };
    }

    const ordered_sources = Object.entries(v.value).reduce<Partial<T>[]>(
      (acc, [key, val]) => {
        const idx = parseInt(key[0]);
        acc[idx] = val.value;
        return acc;
      },
      []
    );
    const mapped_value = Object.entries(xform_def.map_keys).reduce(
      (acc: any, [k, idx]) => {
        acc[k] = (ordered_sources[idx] as any)[k];
        return acc;
      },
      {}
    );

    return { result: v.result, value: mapped_value, text: xform_def.text };
  });
};

export const getCombinatorResultTransducer = <T>(
  rule: RuleDef<any, any, T>
) => {
  if (isReturnXformDef(rule[rule.length - 1])) return returnTransducer<T>(rule);
  if (isTakeXformDef(rule[rule.length - 1])) return takeTransducer<T>(rule);
  if (isMapKeysXformDef(rule[rule.length - 1]))
    return mapKeysTransducer<T>(rule);
  if (isCombinatorRule(rule) && isTakeFirstXformDef(rule[rule.length - 1]))
    return takeFirstTransducer<T>(rule);
  return null;
};

export const getSingleResultTransducer = <T>(
  rule: SingleRuleDef<any, any, T>
) => {
  if (isReturnXformDef(rule[rule.length - 1])) return returnTransducer<T>(rule);
  if (isTakeXformDef(rule[rule.length - 1]))
    throw Error('take result xform for single rules not implemented');
  if (isMapKeysXformDef(rule[rule.length - 1]))
    throw Error('map_key result xform for single rules not implemented');
  return null;
};
