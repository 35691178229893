import { useEffect, useMemo, useRef } from 'react';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { addDays, eachDayOfInterval, startOfWeek } from 'date-fns';
import { grayScale, shadowLight } from 'app/colors';
import { borderRadii, hideScrollbarCss } from 'app/spacing';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import WeekCalendarCell from './components/WeekCalendarCell';
import FloatingDashedLine from './components/FloatingDashedLine';
import {
  BoldCellText,
  CalendarScrollGradient,
  Container,
  DateTitle,
  TimeIntervalCell,
  WeekdayHeaderCell,
} from './components/styled';
import useCurrentTimePosition from './useCurrentTimePosition';
import {
  getCalendarTimeIntervals,
  isCellToday,
  isCellTomorrow,
  isTodaySaturday,
  isThisWeek,
  getDateTitle,
} from './utils';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';
import { CALENDAR_VIEW_OPTIONS } from './constants';
import { useTranslation } from 'react-i18next';

const DAYS_IN_WEEK = 7;
const TIME_CELL_HEIGHT = 42;
const COLUMN_ONE_WIDTH = 70;
const HEADER_ROW_HEIGTH = 34;

const getScrollPositionForTime = (times, time) => {
  return times.indexOf(time) * TIME_CELL_HEIGHT;
};

const GridWrapper = styled.div`
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${COLUMN_ONE_WIDTH}px repeat(${DAYS_IN_WEEK}, 1fr);
  grid-auto-rows: 1fr;
  height: 100%;
`;

const CalendarWrapper = styled.div`
  display: flex;
  flex: 1;
  height: calc(100% - ${HEADER_ROW_HEIGTH}px);
  ${shadowLight}
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.standard};
  overflow: hidden;
  background-color: #fff;
`;

const StyledScrollFadeContainer = styled(ScrollFadeContainer)`
  width: 100%;
  flex-shrink: 0;

  ${hideScrollbarCss}
  overflow-y: auto;
  ${({ isSaturday }) =>
    // The right border is removed on the last day of the week because the TimeCell components are
    // are setup to apply the blue right border for that day.
    isSaturday &&
    css`
      border-right-width: 0;
    `}
`;

const StyledScrollBarDetached = styled(ScrollBarDetached)`
  position: relative;
  left: -9px;
  flex-shrink: 0;
  z-index: 1;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: ${COLUMN_ONE_WIDTH}px repeat(${DAYS_IN_WEEK}, 1fr);
  grid-template-rows: ${HEADER_ROW_HEIGTH}px;
`;

const HeaderCell = styled(WeekdayHeaderCell)`
  width: ${({ width }) => `${width}px`};

  :last-child {
    margin-right: 1px;
  }
`;

/**
 * Calendar component to view dates for a single week.
 *
 * @remarks Unlike the {@link MonthCalendar} the wrapping ScrollFadeContainer has its own border so it is alwasy visible. Therefore, the {@link TimeCell}
 * components borders do not make up the outer borders, just the internal grid lines.
 *
 * @param props.date - the reference date for the calendar used to show the current week.
 * @param props.times - an array of time intervals used to render each row of the calendar. Defaults to 12:00 AM - 11:30 PM using 30 minute intervals
 * @param props.initialTopTime - the time used as the inital top position. Defaults 8:00 AM.
 * @param props.CellComponent - the cell component to be used when rendering each time. Defaults to {@link WeekCalendarCell}
 */
const WeekCalendar = ({
  date,
  times,
  initialTopTime,
  CellComponent,
  ...rest
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const gridRef = useRef(null);
  const [measureRef, { width }] = useMeasure();
  const showCurrentTimeBar = isThisWeek(date);
  const currentTimePosition = useCurrentTimePosition(
    gridRef.current ? gridRef.current.scrollHeight : 0
  );
  const cellWidth = Math.max(0, (width - COLUMN_ONE_WIDTH) / DAYS_IN_WEEK);
  const visibleDates = useMemo(() => {
    const sunday = startOfWeek(date);
    return eachDayOfInterval({ start: sunday, end: addDays(sunday, 6) });
  }, [date]);

  const scrollbarRef = useRef();
  const [refFn, scrollRef] = useSyncSizes(
    scrollbarRef,
    '.ContentForSyncHeight',
    'height'
  );
  useSyncScroll(useRef([scrollRef, scrollbarRef]), {
    vertical: true,
  });
  const scrolled = useEndsOfScroll(scrollRef, [date]);

  useEffect(() => {
    scrollRef.current.scrollTo({
      top: getScrollPositionForTime(times, initialTopTime),
      behavior: 'smooth',
    });
  }, [times, scrollRef, initialTopTime]);

  const weekdays = useMemo(
    () =>
      visibleDates.map((day, i) =>
        getDateTitle(day, language, CALENDAR_VIEW_OPTIONS.week)
      ),
    [language, visibleDates]
  );

  return (
    <Container {...rest}>
      <HeaderRow>
        <div />
        {visibleDates.map((day, i) => (
          <HeaderCell
            key={day.getTime()}
            startCol={2}
            width={cellWidth}
            isToday={isCellToday(day)}
            isTomorrow={isCellTomorrow(day)}
          >
            <DateTitle size="buttonLabel">{weekdays[i]}</DateTitle>
          </HeaderCell>
        ))}
      </HeaderRow>
      <CalendarWrapper ref={measureRef}>
        <StyledScrollFadeContainer
          ref={refFn}
          isSaturday={isTodaySaturday()}
          scrolled={scrolled}
          gradient={<CalendarScrollGradient />}
          bottom
          top
        >
          <GridWrapper className="ContentForSyncHeight" ref={gridRef}>
            {showCurrentTimeBar && (
              <FloatingDashedLine
                offSet={{ y: currentTimePosition }}
                containerWidth={width}
              />
            )}
            <Grid>
              {times.map((t, idx, arr) => (
                <TimeIntervalCell
                  key={t}
                  isFirstRow={idx === 0}
                  isLastRow={idx === arr.length - 1}
                >
                  <BoldCellText size="buttonLabel">{t}</BoldCellText>
                </TimeIntervalCell>
              ))}
              {visibleDates.map((d, idx, a1) =>
                times.map((t, tIdx, a2) => (
                  <CellComponent
                    key={`${d.getTime()}${t}`}
                    date={d}
                    time={t}
                    isLastColumn={idx === a1.length - 1}
                    isFirstRow={tIdx === 0}
                    isLastRow={tIdx === a2.length - 1}
                    style={{
                      gridColumn: idx + 2,
                      gridRow: tIdx + 1,
                      width: `${cellWidth}px`,
                    }}
                  />
                ))
              )}
            </Grid>
          </GridWrapper>
        </StyledScrollFadeContainer>
        <StyledScrollBarDetached
          ref={scrollbarRef}
          direction="vertical"
          scrollClassName="ContentForSyncHeight"
        />
      </CalendarWrapper>
    </Container>
  );
};

export default WeekCalendar;

WeekCalendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  times: PropTypes.arrayOf(PropTypes.string),
  initialTopTime: PropTypes.string,
  CellComponent: PropTypes.elementType,
};

WeekCalendar.defaultProps = {
  times: getCalendarTimeIntervals(),
  initialTopTime: '8:00 AM',
  CellComponent: WeekCalendarCell,
};
