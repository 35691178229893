import type { AndRuleDef, SingleRuleDef } from './rules/types';
import {
  Access,
  ContactPermission,
  CustomObjectPermission,
  StreamValue,
} from './types';

type ContactKey = `contacts_section__${ContactPermission}`;
type CustomObjectKey = `__${CustomObjectPermission}`;

export const getFieldRelationRuleDef = (
  related_object: string
): SingleRuleDef<any, any, any> => {
  return [
    related_object,
    '=',
    'none',
    {
      return: { allowed_access: ['none'], value: 'none' },
      text: 'This permission group has no access to the related Object, so the maximum permission is none.',
    },
  ];
};

export const defaultContactFieldRule: AndRuleDef<
  ContactKey,
  Access,
  StreamValue
> = [
  'and',
  [
    ['contacts_section__all_records', '<', 'edit'],
    ['contacts_section__associated_records', '<', 'edit'],
  ],
  { return: { allowed_access: ['none', 'view'] } },
];

export const defaultCustomObjectFieldRule: AndRuleDef<
  CustomObjectKey,
  Access,
  StreamValue
> = [
  'and',
  [
    ['__all_records', '<', 'edit'],
    ['__associated_records', '<', 'edit'],
  ],
  { return: { allowed_access: ['none', 'view'] } },
];
