import KizenTypography from 'app/kizentypo';

import DraggableStepCard from '../DraggableStepCard';
export const content = ({ step, automation }) => {
  return step.description;
};

export default function ConditionCard({ step, ...others }) {
  const { description } = step;
  return (
    <DraggableStepCard {...others}>
      <KizenTypography as="span">{description}</KizenTypography>
    </DraggableStepCard>
  );
}
