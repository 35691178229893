import { forwardRef } from 'react';

import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import RelationshipOneInline, {
  RelationshipOneSelect,
} from 'components/Fields/InlineTableFieldInput/RelationshipOne';

import { addRelationToTeamSelector } from 'components/Fields/helpers';
import { namedToOption } from 'services/helpers';

import { withCellWrapper } from './../helpers';
import { getDataQAForInput } from 'components/Inputs/helpers';

const _OwnerCell = forwardRef(
  (
    {
      field,
      object: record,
      onSubmit,
      value: valueProp, // remove from orhers as we get value from record
      objectId: modelId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      editable,
      onAutoFocus,
      shouldFocusNext,
      enableLink = true,
      ...others
    },
    ref
  ) => {
    const value = record.owner;
    const f = addRelationToTeamSelector(field);

    return (
      <RelationshipOneInline
        ref={ref}
        {...others}
        value={value}
        shouldFocusNext={shouldFocusNext}
        onSubmit={async (data) => {
          await onSubmit(
            record.id,
            { ownerId: data.id, owner: namedToOption(data) },
            column
          );
        }}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        field={f}
        object={record}
        enableLink={enableLink}
        {...getDataQAForInput(`inline-record-field-${f.name}`, f.fieldType)}
      >
        <RelationshipOneSelect
          shouldFocusNext={shouldFocusNext}
          menuLeftButton={f.isRequired ? null : menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </RelationshipOneInline>
    );
  }
);

export const OwnerCell = withCellWrapper(_OwnerCell);
