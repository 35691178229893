import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, Global } from '@emotion/core';
import { Link as RouterLink } from 'react-router-dom';
import { colorsButton, colorsText, grayScale } from './colors';
import KizenTypography from './kizentypo';
import ProximaNovaLight from './fonts/ProximaNova-Light.woff';
import ProximaNovaLight2 from './fonts/ProximaNova-Light.woff2';
import ProximaNova from './fonts/ProximaNova-Regular.woff';
import ProximaNova2 from './fonts/ProximaNova-Regular.woff2';
import ProximaNovaSemiBold from './fonts/ProximaNova-Semibold.woff';
import ProximaNovaSemiBold2 from './fonts/ProximaNova-Semibold.woff2';
import ProximaNovaBold from './fonts/ProximaNova-Bold.woff';
import ProximaNovaBold2 from './fonts/ProximaNova-Bold.woff2';
// This is a base styling used and shared by multiple components.
// Most of the time you'll be using the KizenTypography export
// unless you need some lower level customization

export const baseFontSize = 10; // px

// This is the proportion of the baseline compared to the font size
export const baselineConstant = 3 / 14;

export const fontWeights = {
  light: 100,
  regular: 400,
  bold: 600,
  black: 900,
};

export const fontSizes = {
  hero: '3.2em',
  header: '2.4em',
  subheader: '1.8em',
  text: '1.4em',
  button: '1.3em',
  buttonLabel: '1.2em',
  small: '1.1em',
  micro: '1em',
  tiny: '0.8rem',
};

export const bottomMarginOptions = {
  xl: '3rem',
  lg: '2.5rem',
  md: '2rem',
  sm: '1.5rem',
  xs: '1em',
  none: 0,
};

export const truncateCss = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const textCss = (
  size = 'text',
  weight = 'regular',
  margin = 'none',
  textTransform = 'none',
  color = colorsText.dark
) => css`
  font-size: ${fontSizes[size] || size};
  font-weight: ${fontWeights[weight] || weight};
  color: ${color};
  margin-bottom: ${bottomMarginOptions[margin]};
  ${textTransform ? `text-transform: ${textTransform}` : ''}
`;

const Text = styled.p(({ size, weight, margin, textTransform, color }) =>
  textCss(size, weight, margin, textTransform, color)
);
const TextSpan = styled.span(({ size, weight, margin, textTransform, color }) =>
  textCss(size, weight, margin, textTransform, color)
);

export const labelCss = (
  size = 'text',
  weight = 'regular',
  margin = 'none',
  textTransform = null,
  color = null
) => css`
  color: unset;
  text-transform: uppercase;
  ${textCss(size, weight, margin, textTransform, color)};
`;
const Label = styled.p(({ size, weight, margin, textTransform, color }) =>
  labelCss(size, weight, margin, textTransform, color)
);

export const linkCss = (
  size = 'text',
  weight = 'regular',
  margin = 'none',
  textTransform = 'none'
) => css`
  ${textCss(size, weight, margin, textTransform)};
  color: ${colorsButton.blue.default};
  :hover {
    color: ${colorsButton.blue.hover};
  }
  :active {
    color: ${colorsButton.blue.hover};
  }
`;

const Link = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== 'textTransform',
})(
  ({ size, weight, margin, textTransform }) => css`
    ${linkCss(size, weight, margin, textTransform)}
  `
);

const UtilityLink = styled('a', {
  shouldForwardProp: (prop) => prop !== 'textTransform',
})(
  ({ size, weight, margin, textTransform, onClick }) => css`
    ${linkCss(size, weight, margin, textTransform)}
    ${onClick &&
    css`
      :hover {
        cursor: pointer;
      }
    `}
  `
);

export const Typing = () => {
  return (
    <Global
      styles={css`
        * {
          font-family: 'Proxima Nova', sans-serif;
          font-size: ${baseFontSize}px;
          line-height: 1;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src:
            url(${ProximaNovaLight}) format('woff'),
            url(${ProximaNovaLight2}) format('woff2');
          font-weight: ${fontWeights.light};
          font-style: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src:
            url(${ProximaNova}) format('woff'),
            url(${ProximaNova2}) format('woff2');
          font-weight: ${fontWeights.regular};
          font-style: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src:
            url(${ProximaNovaSemiBold}) format('woff'),
            url(${ProximaNovaSemiBold2}) format('woff2');
          font-weight: ${fontWeights.bold};
          font-style: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src:
            url(${ProximaNovaBold}) format('woff'),
            url(${ProximaNovaBold2}) format('woff2');
          font-weight: ${fontWeights.black};
          font-style: normal;
        }

        :root {
          --font-family-sans-serif: 'Proxima Nova', sans-serif;
          -webkit-font-smoothing: antialiased;
        }
      `}
    />
  );
};

export const notificationCss = (
  size = 'micro',
  weight = 'regular',
  margin = 'none'
) => css`
  ${textCss(size, weight, margin)};
  background-color: ${colorsButton.blue.default};
  color: ${grayScale.white};
  padding: 0.3rem 0.65rem;
  vertical-align: top;
  border-radius: 1rem;
  text-align: center;
`;
const Notification = styled.span(({ size, weight, margin }) =>
  notificationCss(size, weight, margin)
);

const typographyPropTypes = {
  size: PropTypes.oneOf(Object.keys(fontSizes)),
  weight: PropTypes.oneOf(Object.keys(fontWeights)),
  margin: PropTypes.oneOf(Object.keys(bottomMarginOptions)),
};
Text.propTypes = typographyPropTypes;
TextSpan.propTypes = typographyPropTypes;
Label.propTypes = typographyPropTypes;
Link.propTypes = typographyPropTypes;
UtilityLink.propTypes = typographyPropTypes;

const typographyDefaultProps = {
  size: 'text',
  weight: 'regular',
  margin: 'none',
};
Text.defaultProps = typographyDefaultProps;
TextSpan.defaultProps = typographyDefaultProps;
Label.defaultProps = typographyDefaultProps;
Link.defaultProps = typographyDefaultProps;
UtilityLink.defaultProps = typographyDefaultProps;

export {
  Text,
  TextSpan,
  Label,
  Link,
  UtilityLink,
  Notification,
  KizenTypography,
};
