import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useDashboardSettingsBreakpoint from 'components/DashboardGrid/hooks/useDashboardSettingsBreakpint';
import { DASHLET_ACTIONS } from '../constants';
import { debugLog } from 'utility/debug';
import AccessRequestsToolbar from '../Toolbar';
import {
  AccessRequestsContentCard,
  AccessRequestsDragAndDropWrapper,
  AccessRequestsMobileContainer,
  AccessRequestsScrollContainer,
  AccessRequestsSubsection,
  AccessRequestsTable,
  AccessRequestsTableHeader,
  AccessRequestsTHead,
} from '../styles';
import Loader from 'components/Kizen/Loader';
import DraggableItem from 'components/AccessRequests/DraggableItem';
import DragAndDropLayout from 'components/DragAndDropLayout';
import KizenTypography from 'app/kizentypo';
import { useBreakpoint } from 'app/spacing';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import useModal from 'components/Modals/useModal';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const AccessRequestTableMine = ({
  getColumns,
  handleMutateDashboard,
  handleReorderDashboards,
  handleStartEditing,
  updateDashboardMutation,
  handleStartDuplication,
  deleteDashboardMutation,
  handleStartRequesting,
  handleSettingsBackClick,
  goBackPath,
  data,
  handleShowModal,
  isRefetching,
  loading,
  entitySingular,
  entityPlural,
  prefix,
  deleteConfirmationMessage,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint();
  const parentRef = useRef(null);

  const columns = useMemo(() => getColumns(t), [t, getColumns]);

  const calculateWidth = useDashboardSettingsBreakpoint(columns);

  const handleChangeName = useCallback(
    (id, name) => {
      handleMutateDashboard({ name, id }, true, id);
    },
    [handleMutateDashboard]
  );

  const handleChangeActive = useCallback(
    (id, published) => {
      handleMutateDashboard({ published, id }, true, id);
    },
    [handleMutateDashboard]
  );

  const handleChangeOwner = useCallback(
    (id, owner) => {
      handleMutateDashboard({ owner, id }, true, id);
    },
    [handleMutateDashboard]
  );

  const handleReorder = useCallback(
    (newItems, item) => {
      const { id } = item;
      const newIndex = newItems.findIndex((i) => i.id === id);
      if (newIndex === 0) {
        handleReorderDashboards(
          { move_before_dashboard_id: newItems[1].id },
          id
        );
      } else {
        handleReorderDashboards(
          {
            move_after_dashboard_id: newItems[newIndex - 1].id,
          },
          id
        );
      }
    },
    [handleReorderDashboards]
  );

  const [deleteBlockModalProps, deleteBlockModalTriggerProps] = useModal({
    handleSubmit: () => {
      deleteDashboardMutation.mutate(intermediateDeleteState.current);
      intermediateDeleteState.current = null;
    },
  });

  const intermediateDeleteState = useRef(null);
  const handleDeleteWithConfirmation = useCallback(
    (id) => {
      intermediateDeleteState.current = id;
      deleteBlockModalTriggerProps.onClick();
    },
    [deleteBlockModalTriggerProps]
  );

  const handleAction = useCallback(
    (action, id, dashboard, canChangePermissions) => {
      switch (action) {
        case DASHLET_ACTIONS.EDIT: {
          return handleStartEditing(dashboard, canChangePermissions);
        }
        case DASHLET_ACTIONS.HIDE: {
          return updateDashboardMutation.mutate({
            id,
            payload: { hidden: true },
          });
        }
        case DASHLET_ACTIONS.SHOW: {
          return updateDashboardMutation.mutate({
            id,
            payload: { hidden: false },
          });
        }
        case DASHLET_ACTIONS.DUPLICATE: {
          return handleStartDuplication(dashboard, canChangePermissions);
        }
        case DASHLET_ACTIONS.DELETE: {
          if (deleteConfirmationMessage) {
            return handleDeleteWithConfirmation(id);
          } else {
            return deleteDashboardMutation.mutate(id);
          }
        }
        case DASHLET_ACTIONS.REQUEST: {
          return handleStartRequesting(dashboard);
        }
        default: {
          debugLog('Invalid action for dashlet', id);
        }
      }
    },
    [
      handleStartEditing,
      handleStartDuplication,
      updateDashboardMutation,
      deleteDashboardMutation,
      handleStartRequesting,
      deleteConfirmationMessage,
      handleDeleteWithConfirmation,
    ]
  );

  const prefixText = prefix ?? `${t('My')} `;

  return (
    <>
      <AccessRequestsToolbar
        goBackPath={goBackPath}
        onBackClick={handleSettingsBackClick}
        count={data.length}
        canAdd
        handleShowModal={handleShowModal}
        mobile={isMobile}
        loading={isRefetching}
        entitySingular={entitySingular}
        entityPlural={entityPlural}
      />
      <AccessRequestsContentCard>
        {loading ? (
          <Loader loading />
        ) : (
          <>
            <AccessRequestsSubsection
              title={`${prefixText}${entityPlural}`}
            ></AccessRequestsSubsection>
            <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
              <ScrollContainerBlocker compactHeader top="-14" />
            </ScrollContainerBlockerWrapper>
            <AccessRequestsScrollContainer
              smallHeader
              bottom
              outlineBorder
              refresh={[data]}
            >
              {!isMobile ? (
                <AccessRequestsTable>
                  <AccessRequestsTHead sticky>
                    <tr>
                      {columns.map((col) => {
                        return (
                          <AccessRequestsTableHeader
                            width={calculateWidth(col.width, col.shrinkable)}
                            key={col.id}
                          >
                            <div>
                              <TextEllipsisWithTooltip>
                                {col.name}
                              </TextEllipsisWithTooltip>
                            </div>
                          </AccessRequestsTableHeader>
                        );
                      })}
                    </tr>
                  </AccessRequestsTHead>
                  <tbody>
                    <tr>
                      <td colSpan={columns.length}>
                        <AccessRequestsDragAndDropWrapper ref={parentRef}>
                          <DragAndDropLayout
                            items={data}
                            countItems={data.length}
                            itemElement={
                              <DraggableItem
                                handleAction={handleAction}
                                handleChangeName={handleChangeName}
                                handleChangeOwner={handleChangeOwner}
                                calculateWidth={calculateWidth}
                                handleChangeActive={handleChangeActive}
                                countItems={data.length}
                                columns={columns}
                                errors={errors}
                                setErrors={setErrors}
                              />
                            }
                            checkDisableDrag={(el) => el.hidden}
                            parentRef={parentRef}
                            itemClassName="dndItemWrapper"
                            handleClassName="itemTarget"
                            onChange={handleReorder}
                          />
                        </AccessRequestsDragAndDropWrapper>
                      </td>
                    </tr>
                  </tbody>
                </AccessRequestsTable>
              ) : (
                <AccessRequestsMobileContainer>
                  <KizenTypography>
                    {t(
                      'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
                    )}
                  </KizenTypography>
                </AccessRequestsMobileContainer>
              )}
            </AccessRequestsScrollContainer>
          </>
        )}
        <DeleteConfirmationModal
          {...deleteBlockModalProps}
          message={deleteConfirmationMessage}
        />
      </AccessRequestsContentCard>
    </>
  );
};

export default AccessRequestTableMine;
