export const compose = (...fns) => {
  const [a, b, c, d, e] = fns;
  switch (fns.length) {
    case 0:
      return new Error('illegal arity');
    case 1:
      return a;
    case 2:
      return (...args) => a(b(...args));
    case 3:
      return (...args) => a(b(c(...args)));
    case 4:
      return (...args) => a(b(c(d(...args))));
    default: {
      const fn = (...args) => a(b(c(d(e(...args)))));
      return fns.length === 5 ? fn : compose(fn, ...fns.slice(5));
    }
  }
};

/**
 * Utility function to be used within a compose pipeline for debugging purposes.
 * @param {string} msg - message to log
 * @returns and identity function that logs the value and (optional) message
 */
export const trace =
  (msg = '') =>
  (value) => {
    console.log(msg, value);
    return value;
  };
