import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { KizenTypography } from 'app/typography';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { CollapsedButton } from './styles';
import { TimelineContext } from '../TimelineContext';

const ACTION_BAR_HEIGHT = 87;

const ExpandableText = styled(KizenTypography)`
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin-bottom: ${({ hasCollapsedButton }) => (hasCollapsedButton ? 19 : 0)}px;
  ${({ expanded }) => {
    if (expanded) {
      return css`
        & p:not(:empty) {
          height: unset !important;
        }
        display: block;
      `;
    }

    return css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      max-height: calc(
        1.5em * 3
      ); // 3 lines tall; Fallback in case line-clamp isn't supported because i line is 1.5em right now
    `;
  }};
`;

const CollapsedButtonWrapper = styled.div`
  position: absolute;
  left: 0;
`;

export default function LongTextEllipsis({
  children,
  isExpandable,
  ...others
}) {
  const { t } = useTranslation();

  const { isConstrained, scrollContainerRef } = useContext(TimelineContext);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textRef = useRef();
  const [tooltipProps, tooltip] = useTruncationTooltip({}, 'y');

  useEffect(() => {
    if (isExpandable) {
      const observeOverflow = () => {
        if (textRef.current && !isExpanded) {
          setIsOverflowed(
            textRef.current.scrollHeight > textRef.current.offsetHeight
          );
        }
      };

      observeOverflow();
      window.addEventListener('resize', observeOverflow);
      return () => {
        window.removeEventListener('resize', observeOverflow);
      };
    }
  }, [isExpandable, isExpanded]);

  const onToggleExpanded = () => {
    setIsExpanded((prevExp) => !prevExp);
    const { top = 0 } = textRef.current?.getBoundingClientRect() || {};
    //we want to scroll long text block to the top of the timeline container (screen) after it is collapsed if it was to high to fit in the screen in expanded state
    if (isConstrained) {
      const { top: scrollWrapperTop = 0 } =
        scrollContainerRef.current?.getBoundingClientRect() || {};
      if (isExpanded && top < scrollWrapperTop) {
        scrollContainerRef.current.firstElementChild.scrollTop -=
          scrollWrapperTop - top;
      }
    } else {
      if (isExpanded && top < ACTION_BAR_HEIGHT) {
        // Wait for expanded state to be upadated before scrolling to the element to trigger horizonList update
        setTimeout(() => {
          window.scrollBy(0, top - ACTION_BAR_HEIGHT);
        });
      }
    }
  };

  return (
    <div>
      <ExpandableText
        ref={textRef}
        hasCollapsedButton={isExpandable && isOverflowed}
        expanded={isExpanded}
        {...(!isExpandable && tooltipProps)}
        {...others}
      >
        {children}
      </ExpandableText>
      {isExpandable && isOverflowed ? (
        <CollapsedButtonWrapper>
          <CollapsedButton
            variant="text"
            color="blue"
            onClick={onToggleExpanded}
          >
            {isExpanded ? t('Hide Full Message') : t('View Full Message')}
          </CollapsedButton>
        </CollapsedButtonWrapper>
      ) : null}
      {!isExpandable && tooltip}
    </div>
  );
}

LongTextEllipsis.propTypes = {
  // truncate with ellipsis tooltip if false, truncate with ellipsis and allow revealing full text with button if true
  isExpandable: PropTypes.bool,
};

LongTextEllipsis.defaultProps = {
  isExpandable: false,
};
