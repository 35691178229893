import { useState, useMemo, useCallback, useEffect } from 'react';
import { invalidate } from 'queries/invalidate';
import Select from 'components/Inputs/Select';
import BasicModal from 'components/Modals/presets/BasicModal';
import FieldService from 'services/FieldService';
import Loader from 'components/Kizen/Loader';
import { useTranslation } from 'react-i18next';
import { useAsync, useToggle } from 'react-use';
import { FIELD_TYPES } from 'utility/constants';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import Dropdown from 'components/Fields/FieldInput/Dropdown';
import PipelineService from 'services/PipelineService';
import { toastVariant, useToast } from 'components/ToastProvider';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { RelationshipConfirmationModal } from 'components/Modals/RelationshipConfirmationModal';
import {
  CO_RELATION_TYPE_ONE_TO_ONE,
  CO_RELATION_TYPE_PRIMARY_REVERSE,
} from 'components/Modals/utilities';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import CreateEntityModalFull, {
  PIPELINE,
} from 'components/Modals/CreateEntity/FullForm.jsx';

const defaultPipeline = {
  id: null,
  name: '',
  objectId: null,
  relationField: null,
  entityValue: null,
  stage: null,
  estimatedCloseDate: null,
};

const disableSave = ({ objectId, relationField }) => {
  return objectId === null || relationField === null;
};

const PipelineModal = ({
  id,
  objectId,
  pipelineOptions,
  clientObject,
  onAdd,
  updateData,
  fieldState,
  relationshipFields,
  handleFieldBlur,
  handleUpdateRecord,
  show: showFirstStepModal,
  setShowModal: setShowFirstStepModal,
  refetch,
}) => {
  const { t } = useTranslation();

  const [showCreateCustomObjectModal, setShowCreateCustomObjectModal] =
    useState(false);
  const [customObject, setCustomObject] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [pipeline, setPipeline] = useState(defaultPipeline);
  const [showToast] = useToast();
  const [relationshipConfirmationModal, setRelationshipConfirmationModal] =
    useToggle(false);

  const { value: fields, loading: fieldsLoading } = useAsync(async () => {
    if (customObject.id) {
      return await PipelineService.getObjectFieldsWithoutRestrictions(
        customObject.id,
        {
          params: {
            field_type: FIELD_TYPES.Relationship.type,
          },
        }
      );
    }
    return EMPTY_ARRAY;
  }, [customObject?.id]);

  const handlePipelineChange = async ({ value }) => {
    setPipeline({
      ...pipeline,
      objectId: value,
      relationField: null,
      stage: null,
    });
    let model = {};
    try {
      setLoading(true);
      model = await FieldService.getModel({
        id: value,
      });
      setCustomObject(model);
    } finally {
      setLoading(false);
    }
  };

  const [showChangesConfirmation, setShowChangesConfirmation] = useState(false);

  const onHideCanceled = () => setShowChangesConfirmation(false);

  const onHideConfirmed = () => {
    setPipeline(defaultPipeline);
    setShowChangesConfirmation(false);
    setShowFirstStepModal(false);
  };

  const handleOnHide = () => {
    if (pipeline.objectId) {
      setShowChangesConfirmation(true);
    } else {
      onHideConfirmed();
    }
  };

  const handleUpdateEntityRecord = useCallback(
    async (data) => {
      try {
        const fieldValues = Array.isArray(
          fieldState[pipeline.relationField?.value]
        )
          ? fieldState[pipeline.relationField?.value]?.map((field) => field.id)
          : [];
        const payload = {
          fields: [
            {
              id: pipeline.relationField?.value,
              value: [...fieldValues, data?.id],
            },
          ],
        };
        const isClient = clientObject?.id === objectId;
        if (isClient) {
          await FieldService.updateObject('contacts', id, payload);
        } else {
          await handleUpdateRecord(payload);
        }
        invalidate.TIMELINE.ALL();
      } catch (error) {
        const erorrMessagePrefix = t(
          'The related record was created, but there was an error relating it to this record'
        );
        const toastMessage =
          erorrMessagePrefix + `${error.message ? ': ' + error.message : '.'}`;
        setTimeout(() =>
          showToast({
            variant: toastVariant.FAILURE,
            message: toastMessage,
          })
        );
      }
    },
    [
      pipeline,
      fieldState,
      handleUpdateRecord,
      showToast,
      clientObject?.id,
      objectId,
      id,
      t,
    ]
  );

  const handleCreateEntityRecord = useCallback(
    async (newObject) => {
      await handleUpdateEntityRecord(newObject);
      refetch();
      setPipeline(defaultPipeline);
      onAdd();
      updateData?.();
      handleFieldBlur?.();
      setShowFirstStepModal(false);
    },
    [
      handleUpdateEntityRecord,
      handleFieldBlur,
      onAdd,
      refetch,
      updateData,
      setShowFirstStepModal,
    ]
  );

  const handleFirstStepConfirm = useCallback(async () => {
    if (
      [CO_RELATION_TYPE_PRIMARY_REVERSE, CO_RELATION_TYPE_ONE_TO_ONE].includes(
        pipeline.relationField.field.relation.relationType
      ) &&
      fieldState?.[pipeline.relationField.value]
    ) {
      setRelationshipConfirmationModal();
    } else {
      setShowCreateCustomObjectModal(true);
      setShowFirstStepModal(false);
    }
  }, [
    setShowFirstStepModal,
    setRelationshipConfirmationModal,
    fieldState,
    pipeline,
  ]);

  const options = useMemo(() => {
    if (!fields?.length) {
      return EMPTY_ARRAY;
    }

    return fields
      .filter((field) => {
        const relationshipFieldsIds = relationshipFields?.[customObject?.id]
          ? relationshipFields[customObject?.id].map((field) => field.value)
          : [];

        // if no relationship fields are selected, show all fields
        return (
          (field.relation?.relatedObject === objectId &&
            relationshipFieldsIds.length === 0) ||
          relationshipFieldsIds.includes(field.relation.relatedField)
        );
      })
      .map((field) => ({
        value: field.relation.relatedField,
        label: field.relation.relatedName,
        field,
      }));
  }, [fields, objectId, relationshipFields, customObject]);

  useEffect(() => {
    if (options.length === 1) {
      setPipeline((prev) => ({ ...prev, relationField: options[0] }));
    }
  }, [options, setPipeline]);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      ...(pipeline.objectId
        ? [
            { id: 'workflow-pipeline' },
            { id: 'relationship-to-new' },
            customObject?.trackEntityValue
              ? { id: 'track-entity-value' }
              : null,
          ]
        : []),
    ].filter(Boolean),
    {},
    () => true
  );

  const handleConfirmRelationship = useCallback(() => {
    setRelationshipConfirmationModal();
    setShowCreateCustomObjectModal(true);
    setShowFirstStepModal(false);
  }, [
    setRelationshipConfirmationModal,
    setShowFirstStepModal,
    setShowCreateCustomObjectModal,
  ]);

  const handleHideConfirmRelationship = useCallback(() => {
    setRelationshipConfirmationModal();
  }, [setRelationshipConfirmationModal]);

  return (
    <>
      <BasicModal
        onHide={handleOnHide}
        onConfirm={handleFirstStepConfirm}
        show={showFirstStepModal}
        actionBtnColor="blue"
        buttonText={t('Continue')}
        defaultLeftBtnText={t('Cancel')}
        heading={t('Add Related Entity')}
        disabled={disableSave(pipeline)}
        size="medium"
      >
        <KeyBoardContext.Provider value={{ assignFieldHandle }}>
          <Select
            label={t('Workflow/Pipeline*')}
            placeholder={t('Choose Workflow/Pipeline')}
            options={pipelineOptions}
            value={pipeline.objectId}
            onChange={handlePipelineChange}
            inModal
            menuInline
            margin
            {...getKeyListenersProps('workflow-pipeline')}
          />
          <Loader loading={isLoading || fieldsLoading}>
            {pipeline.objectId && customObject && (
              <>
                <Dropdown
                  label={`${t('Field to Create New Entity In (This Object)')}*`}
                  placeholder={t('Choose Relationship Field')}
                  options={options}
                  value={pipeline.relationField}
                  isGetFullObject
                  onChange={(val) =>
                    setPipeline({ ...pipeline, relationField: val })
                  }
                  inModal
                  menuInline
                  margin
                  field={{
                    fieldType: FIELD_TYPES.Dropdown,
                    options: options,
                  }}
                  {...getKeyListenersProps('relationship-to-new')}
                />
              </>
            )}
          </Loader>
        </KeyBoardContext.Provider>
      </BasicModal>
      <RelationshipConfirmationModal
        show={relationshipConfirmationModal}
        onConfirm={handleConfirmRelationship}
        onHide={handleHideConfirmRelationship}
      />
      {showCreateCustomObjectModal && (
        <CreateEntityModalFull
          objectType={PIPELINE}
          objectId={customObject.id}
          show={showCreateCustomObjectModal}
          onCreated={handleCreateEntityRecord}
          defaultDirty
          onCancel={() => {
            setPipeline(defaultPipeline);
            setShowCreateCustomObjectModal(false);
          }}
        />
      )}
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        show={showChangesConfirmation}
        onConfirm={onHideConfirmed}
        onHide={onHideCanceled}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
};

export default PipelineModal;
