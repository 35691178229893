import { useCallback, useEffect, useState } from 'react';
import { PageSizing } from 'components/Layout/PageContentWidth';
import { useTranslation } from 'react-i18next';
import BuilderCard from '../../components/BuilderCard';
import WorkflowSettingsSubsection from './subsections/WorkflowSettingsSubsection';
import { WorkflowReasonsSubsection } from './subsections/WorkflowReasonsSubsection';
import WorkflowStagesSubsection from './subsections/WorkflowStagesSubsection';
import Row from '../../components/Row';
import Cols from 'components/Layout/Cols';
import { gutters } from 'app/spacing';
import {
  CO_STAGE_STATUS_DISQUALIFIED,
  CO_STAGE_STATUS_LOST,
} from '../../utilities';
import { capitalize } from 'lodash/string';
import { useCustomObjectWizard } from 'components/Wizards/CustomObject/CustomObjectWizardContext';
import _ from 'lodash';
import { deleteStageHandler } from 'components/Wizards/CustomObject/utilities';

export default function StageSettings({
  label,
  updateStepField,
  objectContainsWorkflow,
  formData,
  isCreateMode,
  errors,
  validationFormProps: {
    validationProps,
    handleInputChange,
    fieldsRef,
    setValidationState,
    firstFieldWithErrorRef,
  },
  ...props
}) {
  const [stagesToDelete, setStageToDelete] = useState([]);
  const { preSaveFn: setPreSaveCallBack } = useCustomObjectWizard();

  const { t } = useTranslation();

  const preSaveCallBack = useCallback(async () => {
    const groups = _.groupBy(
      formData?.pipeline?.stages.map((stage) => ({
        ...stage,
        name: stage.name.trim(),
      })),
      'name'
    );
    let firstField;
    const errState = {};
    Object.entries(groups).forEach(([_, value]) => {
      if (value.length > 1) {
        value.forEach((item) => {
          const originalIdx = formData?.pipeline?.stages
            .map((e) => e.order)
            .indexOf(item.order);
          const id = `pipeline.stages[${originalIdx}].name`;
          if (!fieldsRef.current.has(id)) return;
          if (!firstField) {
            firstField = id;
          }
          errState[id] = t('Stage Names must be unique');
          firstFieldWithErrorRef.current = firstField;
          setValidationState(errState);
        });
      }
    });
    // we need to delete () stages before save
    if (stagesToDelete.length && !firstFieldWithErrorRef.current) {
      await Promise.all(
        stagesToDelete.map(async (stage) =>
          deleteStageHandler(stage, { values: formData })
        )
      );
    }
    return firstFieldWithErrorRef.current ? null : true;
  }, [
    fieldsRef,
    firstFieldWithErrorRef,
    formData,
    setValidationState,
    t,
    stagesToDelete,
  ]);

  useEffect(() => {
    setPreSaveCallBack(preSaveCallBack);
  }, [preSaveCallBack, setPreSaveCallBack]);

  const reasonsLostKey = `reasons${capitalize(CO_STAGE_STATUS_LOST)}`;
  const disqualifiedLostKey = `reasons${capitalize(
    CO_STAGE_STATUS_DISQUALIFIED
  )}`;
  return (
    <PageSizing {...props}>
      <BuilderCard label={label}>
        <WorkflowSettingsSubsection
          values={formData}
          updateStepField={updateStepField}
        />
        <WorkflowStagesSubsection
          values={formData}
          handleInputChange={handleInputChange}
          validationProps={validationProps}
          setStageToDelete={setStageToDelete}
        />
        <Row>
          <Cols columns={2} gutter={`${gutters.spacing(4)}px`}>
            <WorkflowReasonsSubsection
              label={t('Reasons Lost')}
              onChange={(value) => {
                updateStepField(reasonsLostKey, [...value]);
              }}
              values={formData}
              type={CO_STAGE_STATUS_LOST}
              objectKey={reasonsLostKey}
              noItemsNotification={t(
                'This section will be available once you add a stage with the Lost status.'
              )}
            />
            <WorkflowReasonsSubsection
              label={t('Reasons Disqualified')}
              onChange={(value) => {
                updateStepField(disqualifiedLostKey, [...value]);
              }}
              values={formData}
              type={CO_STAGE_STATUS_DISQUALIFIED}
              objectKey={disqualifiedLostKey}
              noItemsNotification={t(
                'This section will be available once you add a stage with the Disqualified status.'
              )}
            />
          </Cols>
        </Row>
      </BuilderCard>
    </PageSizing>
  );
}
