import { toastVariant, useToast } from 'components/ToastProvider';
import { useModalControl } from 'hooks/useModalControl';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import ToolbarService from 'services/ToolbarService';

const useToolbarAccessRequest = () => {
  const [showToast] = useToast();
  const { t } = useTranslation();
  const requestingAccessState = useRef({});

  const [
    isAccessRequestModalShowing,
    { showModal: showAccessRequestModal, hideModal: hideAccessRequestModal },
  ] = useModalControl();

  const sendRequestMutation = useMutation(
    (result) =>
      ToolbarService.requestAccess(
        result.templateId,
        result.grantingAdmin,
        result.accessLevel
      ),
    {
      onSettled: (data, e) => {
        if (data?.status === 'pending') {
          showToast({
            message: t('Your access request has been sent.'),
            variant: toastVariant.SUCCESS,
          });
        } else {
          showToast({
            message: t('Your access request could not be sent.'),
            variant: toastVariant.FAILURE,
          });
        }
      },
    }
  );

  const handleSendAccessRequest = useCallback(
    (templateId, accessLevel, grantingAdmin) => {
      sendRequestMutation.mutate({ templateId, accessLevel, grantingAdmin });
      hideAccessRequestModal();
    },
    [sendRequestMutation, hideAccessRequestModal]
  );

  const accessRequestModalProps = useMemo(() => {
    return {
      isOpen: isAccessRequestModalShowing,
      show: isAccessRequestModalShowing,
      onHide: hideAccessRequestModal,
      onConfirm: handleSendAccessRequest,
    };
  }, [
    isAccessRequestModalShowing,
    hideAccessRequestModal,
    handleSendAccessRequest,
  ]);

  return {
    modalProps: accessRequestModalProps,
    showAccessRequestModal,
    requestingAccessState,
  };
};

export default useToolbarAccessRequest;
