import {
  ForwardedRef,
  HTMLAttributes,
  MouseEventHandler,
  createElement,
  forwardRef,
} from 'react';
import { getKDSClasses, getQaAttributes, merge, mergeRefs } from '../util';
import { ButtonColors, Colors } from '../Colors/definitions';
import { getTailwindSafeColorClassName } from '../Colors/utils';
import { createStyleObject } from '@capsizecss/core';
import { isDebug } from '../debug';
import { useTooltip } from '../Tooltip/useTooltip';
import { Tooltip } from '../Tooltip/Tooltip';

export const weights = [
  'bold',
  'semibold',
  'medium',
  'regular',
  'light',
] as const;

export type Weight = (typeof weights)[number];

export const sizes = [
  '4xl',
  '3xl',
  '2xl',
  'xl',
  'lg',
  'base',
  'md',
  'sm',
  'xs',
] as const;

export type Size = (typeof sizes)[number];

export const variants = [
  'header',
  'body',
  'table',
  'label',
  'placeholder',
  'button',
  'link',
] as const;

export type Variant = (typeof variants)[number];

export const styles = ['normal', 'italic'] as const;

export type Style = (typeof styles)[number];

export const decorations = ['none', 'underline', 'underline-dashed'] as const;

export type Decoration = (typeof decorations)[number];

export interface TypographyProps {
  children: any;
  size?: Size;
  variant?: Variant;
  weight?: Weight;
  href?: string;
  target?: string;
  rel?: string;
  color?: Colors;
  buttonColor?: ButtonColors;
  truncate?: boolean;
  className?: string;
  fontStyle?: Style;
  onClick?: MouseEventHandler<HTMLElement>;
  decoration?: Decoration;
  enableTooltip?: boolean;
  modalCompatability?: boolean;
  qa?: Record<string, string>;
}

export type PreconfiguredTypographyProps = Omit<TypographyProps, 'variant'>;

export interface TypographyLinkProps extends PreconfiguredTypographyProps {
  href: string;
  target?: string;
  rel?: string;
  buttonColor?: ButtonColors;
}

const getTrackingClassName = (size: Size, variant: Variant) => {
  switch (size) {
    case '3xl':
    case '2xl':
    case 'xl':
      return 'tracking-sm';
    case 'lg':
      return 'tracking-md';
  }

  return 'tracking-lg';
};

const getLineHeightClassName = (size: Size, variant: Variant) => {
  switch (size) {
    case 'md':
    case 'lg':
      return 'leading-normal';
  }

  return 'leading-tight';
};

const getFontSizeClassName = (size: Size) => {
  switch (size) {
    case '4xl':
      return 'text-4xl';
    case '3xl':
      return 'text-3xl';
    case '2xl':
      return 'text-2xl';
    case 'xl':
      return 'text-xl';
    case 'lg':
      return 'text-lg';
    case 'base':
      return 'text-base';
    case 'md':
      return 'text-md';
    case 'sm':
      return 'text-sm';
    case 'xs':
      return 'text-xs';
  }
};

const getWeightClassName = (weight: Weight) => {
  switch (weight) {
    case 'bold':
      return 'font-bold';
    case 'semibold':
      return 'font-semibold';
    case 'regular':
      return 'font-regular';
    case 'light':
      return 'font-light';
    case 'medium':
      return 'font-medium';
  }
};

const getCursorClassName = (onClick?: MouseEventHandler<HTMLElement>) => {
  if (onClick) {
    return 'cursor-pointer';
  }

  return '';
};

const getElement = (
  variant: Variant,
  size: Size,
  href?: string,
  onClick?: MouseEventHandler<HTMLElement>
) => {
  switch (variant) {
    case 'header': {
      switch (size) {
        case 'xl':
        case '2xl':
        case '3xl':
        case '4xl':
          return 'h1';
        case 'lg':
          return 'h2';
        case 'base':
          return 'h3';
        case 'md':
          return 'h4';
        case 'sm':
          return 'h5';
        case 'xs':
          return 'h6';
      }
      break;
    }
    case 'body':
    case 'table':
      return 'span';
    case 'label':
      return 'label';
    case 'placeholder':
      return 'span';
    case 'button':
      return 'span';
    case 'link': {
      if (onClick && !href) {
        return 'span';
      }

      return 'a';
    }
  }
};

const getColorClassName = (variant: Variant, size: Size) => {
  if (variant === 'label') {
    if (size === 'sm') {
      return 'text-font-placeholder';
    }
  } else if (variant === 'placeholder') {
    return 'text-font-placeholder';
  } else if (variant === 'link') {
    return 'text-button-secondary-default hover:text-button-secondary-hover active:text-button-secondary-pressed';
  }

  return 'text-inherit';
};

const getStyleClassName = (style: Style) => {
  switch (style) {
    case 'normal':
      return '';
    case 'italic':
      return 'italic';
  }
};

const getTransformClassName = (variant: Variant) => {
  if (variant === 'button') {
    return 'uppercase';
  }

  return '';
};

const getDecorationClassName = (decoration: Decoration) => {
  const decorationClassName =
    'underline underline-offset-[3px] decoration-font-secondary';
  switch (decoration) {
    case 'underline-dashed': {
      return merge(decorationClassName, 'decoration-dashed');
    }
    case 'underline': {
      return decorationClassName;
    }
  }
};

const getCapsizeStyles = (variant: Variant, size: Size) => {
  const fontSizeClassName = getFontSizeClassName(size);
  const lineHeightClassName = getLineHeightClassName(size, variant);

  const mappingToPixels = {
    'text-xs': 10,
    'text-sm': 12,
    'text-md': 14,
    'text-base': 16,
    'text-lg': 18,
    'text-xl': 20,
    'text-2xl': 24,
    'text-3xl': 34,
    'text-4xl': 48,
  };

  const mappingToRatio = {
    'leading-none': 0,
    'leading-tightest': 1,
    'leading-tighter': 1.2,
    'leading-tight': 1.3,
    'leading-normal': 1.5,
  };

  const fontSize = mappingToPixels[fontSizeClassName];
  const lineHeight = mappingToRatio[lineHeightClassName] * fontSize;

  // These values can be found using the old version of capsize at https://capsize--25710c253ab71ec0379d027a39cf108465a38050.surge.sh/
  // and were teaked slightly to align better with our text
  const capsizeStyles = createStyleObject({
    fontSize: fontSize,
    leading: lineHeight,
    fontMetrics: {
      capHeight: 700,
      ascent: 790,
      descent: -210,
      lineGap: 0,
      unitsPerEm: 1000,
    },
  });

  return {
    '--kizen-text-before-content': capsizeStyles['::before'].content,
    '--kizen-text-before-display': capsizeStyles['::before'].display,
    '--kizen-text-before-margin-bottom': capsizeStyles['::before'].marginBottom,
    '--kizen-text-after-content': capsizeStyles['::after'].content,
    '--kizen-text-after-display': capsizeStyles['::after'].display,
    '--kizen-text-after-margin-top': capsizeStyles['::after'].marginTop,
  };
};

type InternalTypographyProps<T> = TypographyProps &
  HTMLAttributes<T> & {
    enableDebug?: boolean;
  };

export const __Internal_Typography = forwardRef(
  (
    props: InternalTypographyProps<HTMLSpanElement>,
    forwardedRef: ForwardedRef<HTMLSpanElement>
  ) => {
    const {
      children,
      size = 'md',
      variant = 'body',
      weight = 'regular',
      fontStyle = 'normal',
      href,
      target,
      rel,
      color,
      truncate,
      className,
      enableDebug = false,
      decoration = 'none',
      enableTooltip = true,
      modalCompatability = false,
      qa,
      ...htmlAttributes
    } = props;

    const { onClick } = htmlAttributes;

    const styleClassName = getStyleClassName(fontStyle);
    const fontSizeClassName = getFontSizeClassName(size);
    const weightClassName = getWeightClassName(weight);
    const colorClassName = getColorClassName(variant, size);
    const lineHeightClassName = getLineHeightClassName(size, variant);
    const trackingClassName = getTrackingClassName(size, variant);
    const transformClassName = getTransformClassName(variant);
    const customColorClassName = getTailwindSafeColorClassName(color, 'text');
    const cursorClassName = getCursorClassName(onClick);
    const decorationClassName = getDecorationClassName(decoration);

    const {
      triggerProps,
      targetProps: { ref: tooltipTargetRef },
      showTooltip,
      tooltipProps,
    } = useTooltip();

    const hasDefaultTruncate = variant !== 'body';
    const shouldTruncate =
      (hasDefaultTruncate || truncate) && truncate !== false;

    const { debugClassName } = isDebug(enableDebug);

    const dynamicClassNames = merge(
      getKDSClasses('typography-child'),
      transformClassName,
      trackingClassName,
      lineHeightClassName,
      weightClassName,
      fontSizeClassName,
      styleClassName,
      cursorClassName,
      decorationClassName,
      color ? customColorClassName : colorClassName,
      variant === 'button' && 'flex-grow',
      'block font-body kizen-line-adjust m-0',
      shouldTruncate && 'w-full',
      enableDebug ? 'relative' : ''
    );

    const element = createElement(
      getElement(variant, size, href, onClick),
      {
        className: dynamicClassNames,
        style: {
          ...getCapsizeStyles(variant, size),
        },
        ...(variant === 'link' && href
          ? {
              href,
              target,
              rel,
            }
          : {}),
        ...(enableTooltip ? triggerProps : {}),
      },
      <span
        className={
          shouldTruncate
            ? merge(
                'kds-typography-inner truncate text-unset leading-unset block w-full',
                debugClassName
              )
            : merge(
                'kds-typography-inner text-unset leading-unset inline',
                debugClassName
              )
        }
        ref={mergeRefs(forwardedRef, tooltipTargetRef)}
        {...htmlAttributes}
      >
        {children}
        {showTooltip && enableTooltip ? (
          <Tooltip
            position="top"
            text={children}
            modalCompatability={modalCompatability}
            {...tooltipProps}
          />
        ) : null}
      </span>
    );

    return (
      <span
        className={merge(getKDSClasses('typography', variant), className)}
        {...getQaAttributes(qa)}
      >
        {element}
      </span>
    );
  }
);

const InternalTypography = __Internal_Typography;

export const Typography = forwardRef(
  (props: TypographyProps, forwardedRef: ForwardedRef<HTMLSpanElement>) => {
    const {
      children,
      className,
      size = 'md',
      variant = 'body',
      weight = 'regular',
      fontStyle = 'normal',
      href,
      target,
      rel,
      color,
      truncate,
      onClick,
      decoration,
      enableTooltip = true,
      modalCompatability = false,
      qa,
    } = props;

    return (
      <InternalTypography
        className={className}
        size={size}
        variant={variant}
        weight={weight}
        href={href}
        target={target}
        rel={rel}
        color={color}
        truncate={truncate}
        fontStyle={fontStyle}
        onClick={onClick}
        decoration={decoration}
        enableDebug
        ref={forwardedRef}
        enableTooltip={enableTooltip}
        modalCompatability={modalCompatability}
        qa={qa}
      >
        {children}
      </InternalTypography>
    );
  }
);

const withPreconfiguredProps =
  (WrappedComponent: any, variant: Variant) =>
  (props: PreconfiguredTypographyProps) => {
    return <WrappedComponent size="sm" {...props} variant={variant} />;
  };

export const HeaderText = withPreconfiguredProps(Typography, 'header');
export const BodyText = withPreconfiguredProps(Typography, 'body');
export const LabelText = withPreconfiguredProps(Typography, 'label');
export const ButtonText = forwardRef(
  (
    { preserveCase, ...props }: TypographyProps & { preserveCase?: boolean },
    forwardedRef: ForwardedRef<HTMLSpanElement>
  ) => {
    return (
      <Typography
        size={preserveCase ? 'md' : 'sm'}
        weight={preserveCase ? 'regular' : 'semibold'}
        {...props}
        variant={preserveCase ? 'body' : 'button'}
        truncate
        className="w-full"
        ref={forwardedRef}
        enableTooltip={false} // The button component handles the tooltip
      />
    );
  }
);
export const PlaceholderText = withPreconfiguredProps(
  Typography,
  'placeholder'
);
export const LinkText = (props: TypographyLinkProps) => {
  return <Typography {...props} variant="link" />;
};
