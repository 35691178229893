import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';
import { ensureDeserializedFormPages } from 'xforms/api';
import {
  getContactsAccess,
  getCustomObjectAccess,
} from '../authentication/selectors';
import { initialStore } from '../initialStore';

export const generatePageConfig = (fromApi = {}, fromState = {}) => {
  const defaultPageConfigWhichMustBe = initialStore.formsPage.pageConfig;

  return Object.keys(defaultPageConfigWhichMustBe).reduce((acc, el) => {
    const compare = (property) => {
      const propertyWhichShouldBeAlwaysFromBackEnd = [
        'page',
        'size',
        'sort',
        'search',
      ];
      // highest priority from url string
      if (fromState && fromState[property]) return fromState[property];

      // check extra fields
      if (
        propertyWhichShouldBeAlwaysFromBackEnd.includes(property) &&
        fromApi
      ) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      return defaultPageConfigWhichMustBe[property];
    };

    return { ...acc, [el]: compare(el) };
  }, {});
};

export const getForms = createAction(
  'formsPage/getForms',
  function prepare(updatePageConfig = true, refreshPage = false) {
    return {
      payload: {
        updatePageConfig,
        refreshPage,
      },
    };
  }
);
export const updateFormField = createAction('formsPage/updateFormField');
export const duplicateForm = createAction('formsPage/duplicateForm');
export const deleteForm = createAction('formsPage/deleteForm');
export const duplicateFormSuccess = createAction(
  'formsPage/duplicateFormSuccess'
);
// setPageConfig
export const setPageConfig = createAction('formsPage/setPageConfig');
export const setPageConfigSuccess = createAction(
  'formsPage/setPageConfigSuccess'
);
export const setPageConfigFail = createAction('formsPage/setPageConfigFail');

// Slices
const defaultState = initialStore.formsPage;

const formsSlice = createSlice({
  name: 'formsPage',
  initialState: defaultState,
  reducers: {
    buildPage(state, action) {
      const { page } = action.payload;
      state.isFetching = true;
      state.pageConfig = {
        ...state.pageConfig,
        ...page,
      };
    },
    buildPageComplete(state, action) {
      const { pageResponse, models } = action.payload;
      state.pageConfig = generatePageConfig(pageResponse, state.pageConfig);
      state.models = models;
    },
    buildPageFinish(state) {
      state.isFetching = false;
    },
    buildPageFail(state) {
      state.isFetching = false;
    },
    updatePageConfig(state, action) {
      const { payload } = action;
      state.pageConfig = {
        ...state.pageConfig,
        ...payload,
      };
    },
    getFormsStart(state) {
      state.isFiltering = true;
    },
    getFormsSuccess(state, action) {
      const { forms, formsCount } = action.payload;
      state.forms = forms;
      state.formsCount = formsCount;
    },
    getFormsFail(state, action) {
      state.error = action.payload;
    },
    getFormsFinish(state) {
      state.isFiltering = false;
    },
    updateFormFieldFail(state, action) {
      state.error = action.payload;
    },
    duplicateFormFail(state, action) {
      state.error = action.payload;
    },
    deleteFormSuccess(state, action) {
      state.toastMessage = action.payload;
    },
    cleanDeleteFormToastMessage(state) {
      state.toastMessage = null;
    },
    deleteFormFail(state, action) {
      state.toastMessage = action.payload;
      state.error = action.payload;
    },
    addNewForm(state, { payload }) {
      state.forms.unshift(payload);
      state.formsCount += 1;
    },
    updateFormFieldSuccess(state, action) {
      const { payload } = action;
      const forms = state.forms.map((item) => {
        if (item.id === payload.id) {
          item.name = payload.name ?? item.name;
          item.formUi = payload.formUi ?? item.formUi;
        }
        return item;
      });
      state.forms = forms;
    },
    replaceForm(state, { payload: { form } }) {
      const index = state.forms.findIndex((f) => f.id === form.id);
      const forms = [...state.forms];
      if (index < 0) {
        forms.unshift(form);
      } else {
        forms.splice(index, 1, form);
      }
      state.forms = forms;
    },
  },
});

export const {
  buildPage,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  updatePageConfig,
  getFormsSuccess,
  getFormsFail,
  updateFormFieldFail,
  duplicateFormFail,
  deleteFormFail,
  deleteFormSuccess,
  cleanDeleteFormToastMessage,
  updateFormFieldSuccess,
  addNewForm,
  replaceForm,
  getFormsStart,
  getFormsFinish,
} = formsSlice.actions;

export const { reducer } = formsSlice;

// Selectors
export const selectFormsPageIsFiltering = (state) =>
  state.formsPage.isFiltering;
export const selectFormsPageConfig = (state) => state.formsPage.pageConfig;
export const selectForms = (state) => state.formsPage.forms;
export const formsIsFetching = (state) => state.formsPage.isFetching;
export const selectFormsCount = (state) => state.formsPage.formsCount;
export const selectFormsDeleteToastMessage = (state) =>
  state.formsPage.toastMessage;
export const selectFormById = (id) => (state) => {
  const form = state.formsPage.forms.find((f) => f.id === id);

  return form
    ? {
        ...form,
        formUi: {
          ...form.formUi,
          pages: ensureDeserializedFormPages(form.formUi.pages),
        },
      }
    : { formUi: { pages: [] } };
};
export const selectAddFormModels = createSelector(
  (state) => state.formsPage.models,
  getContactsAccess,
  getCustomObjectAccess,
  (models, contactAccess, { customObjectEntities }) => {
    return models.filter((model) => {
      return model.name === 'client_client'
        ? contactAccess.canCreateNewContact
        : customObjectEntities[model.id]?.canCreate;
    });
  }
);
