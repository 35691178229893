export const THROTTLE_INTERVAL = 10;
export const CARD_OFFSET = 7.5;

export const DEFAULT_FA_ICONS = [
  'chart-bar',
  'database',
  'user',
  ['bag-shopping', 'shopping-bag'],
  'layer-group',
  'receipt',
  'sack-dollar',
  'browser',
  ['calendar-days', 'calendar-alt'],
  ['book-blank', 'book-alt'],
  'form-entity', // custom
  'survey-entity', // custom
  'clipboard-list',
  ['bullseye-arrow', 'bullseye-pointer'],
  'play',
  ['gear', 'cog'],
  ['circle-user', 'user-circle'],
  'repeat',
  ['plus', 'add'],
  ['right-from-bracket', 'sign-out'],
  ['circle-question', 'question-circle'],
  'graduation-cap',
  'comments',
  'kizen-k',
  ['external-link', 'toolbar-external-link'],
  'bars-light',
];
