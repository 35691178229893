import { PageSizing } from 'components/Layout/PageContentWidth';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Toolbar from './components/Toolbar';
import { useHistory, useParams } from 'react-router-dom';
import useToolbarBuilder from '../../hooks/useToolbarBuilder';
import ToolbarBuilder from '../../components/Builder';
import { StyledSettingsContentCard } from '../../styles';
import Loader from 'components/Kizen/Loader';
import { StyledKizenTypography } from './styles';
import ConfirmNavigationWithActionModal from 'components/Modals/presets/ConfirmNavigationWithAction';
import NewURLDialog from '../../components/NewURLDialog';
import { useSelector } from 'react-redux';
import { getUserProfile } from 'store/authentication/selectors';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import NewRecentItemsDialog from '../../components/NewRecentItemsDialog';

// This is the difference in height from what's expected. It will be dynamic in the future.
const OFFSET = -35;
const GO_BACK_PATH = '/account/toolbar/templates';

const EditTemplatePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const user = useSelector(getUserProfile);

  const handleGoBack = useCallback(() => {
    history.push(GO_BACK_PATH);
  }, [history]);

  const {
    builderProps,
    templateToolbarProps,
    columnsLoading,
    newURLModalProps,
    recentItemsModalProps,
    confirmNavigationModalProps,
  } = useToolbarBuilder(params?.id, handleGoBack);

  useSetTitleOnLoad(
    user?.full_name
      ? `${user.full_name} - ${t('Edit Toolbar Template')} - KIZEN`
      : `${t('Edit Toolbar Template')} - KIZEN`
  );

  return (
    <>
      {newURLModalProps.modalOpen && <NewURLDialog {...newURLModalProps} />}
      {recentItemsModalProps.modalOpen && (
        <NewRecentItemsDialog {...recentItemsModalProps} />
      )}
      <ConfirmNavigationWithActionModal {...confirmNavigationModalProps} />
      <PageSizing>
        {columnsLoading ? (
          <Loader loading />
        ) : (
          <>
            <Toolbar
              goBackPath={GO_BACK_PATH}
              handleGoBack={handleGoBack}
              {...templateToolbarProps}
            />
            <StyledSettingsContentCard navBarHeight={OFFSET}>
              <StyledKizenTypography type="subheader">
                {t('Edit Toolbar Template')}
              </StyledKizenTypography>
              <ToolbarBuilder {...builderProps} />
            </StyledSettingsContentCard>
          </>
        )}
      </PageSizing>
    </>
  );
};

export default EditTemplatePage;
