import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PreviewBlock from './PreviewBlock';
import {
  FileLibraryModalHeaderWrapper,
  FileLibraryDetailsWrapper,
  Spacer,
  StyledModal,
  FileLibraryModalHeader,
  SearchInputWrapper,
  LeftColumn,
  RightColumn,
} from './styles';
import { fileShape } from 'components/FilePicker/defaults';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import { FilesList } from './FilesList';
import { useModalConfiguration } from './useModalConfiguration';

const ReadonlyFileLibraryModal = ({
  files: allFiles = [],
  viewable = true,
  onHide,
  ...rest
}) => {
  const { t } = useTranslation();

  const {
    files,
    filePreview,
    selectedFile,
    setSearch,
    setSelectedFile,
    showPreviewBlock,
    windowHeight,
    isMobile,
    columnRef,
  } = useModalConfiguration(allFiles);

  return (
    <StyledModal
      show
      heading={t('File Library')}
      buttonText={t('Close')}
      actionBtnColor="blue"
      size="large"
      onHide={onHide}
      leftBtn={null}
      {...rest}
    >
      <FileLibraryModalHeaderWrapper>
        <FileLibraryModalHeader type="header">
          {`${files.length} ${
            files.length === 1 ? t('Selected File') : t('Selected Files')
          }`}
        </FileLibraryModalHeader>
        <SearchInputWrapper>
          <PageSearchInput
            onChange={setSearch}
            placeholder={t('Find Files')}
            maxWidth={250}
          />
        </SearchInputWrapper>
      </FileLibraryModalHeaderWrapper>
      <FileLibraryDetailsWrapper>
        <LeftColumn
          ref={columnRef}
          isVisible={showPreviewBlock}
          windowHeight={windowHeight}
        >
          <PreviewBlock
            file={filePreview}
            viewable={viewable}
            isMobile={isMobile}
            disabled
          />
        </LeftColumn>
        {showPreviewBlock ? <Spacer /> : null}
        <RightColumn windowHeight={windowHeight}>
          <FilesList
            files={files}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            viewable={viewable}
            showPreviewOption={showPreviewBlock}
          />
        </RightColumn>
      </FileLibraryDetailsWrapper>
    </StyledModal>
  );
};

ReadonlyFileLibraryModal.propTypes = {
  files: PropTypes.arrayOf(fileShape),
  onHide: PropTypes.func,
  viewable: PropTypes.bool,
};

export default ReadonlyFileLibraryModal;
