import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KizenTypography } from '__app/typography';
import { colorsButton, grayScale } from '__app/colors';
import { ButtonRadio } from 'ts-components/ButtonToggle';

export type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6;

export type ColumnSizingProps = {
  value: ColumnSize;
  onChange(value: ColumnSize): void;
};

const columnOptions = (t: (x: string) => string) => [
  { label: t('One'), value: 1 },
  { label: t('Two'), value: 2 },
  { label: t('Three'), value: 3 },
  { label: t('Four'), value: 4 },
  { label: t('Five'), value: 5 },
  { label: t('Six'), value: 6 },
];

const ColumnSizingContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-self: center;
`;

const Label = styled(KizenTypography)`
  text-align: center;
  white-space: nowrap;
`;

const StyledButtonRadio = styled(ButtonRadio)`
  max-width: 345px;
`;

export const ColumnSizing = ({ value, onChange }: ColumnSizingProps) => {
  const { t } = useTranslation();
  const options = useMemo(() => columnOptions(t), [t]);

  return (
    <ColumnSizingContainer>
      <Label>{t('Column Sizing')}</Label>
      <StyledButtonRadio
        value={value}
        onChange={onChange}
        variant="outline"
        options={options}
        optionHorizontalPadding={15}
        color={colorsButton.blue.default}
        selectedColor={grayScale.white}
        fontColor={colorsButton.blue.default}
        height="36px"
        highlightBackground={true}
        bold={true}
        fontSize="buttonLabel"
        textTransform="uppercase"
        borderRadius="standard"
      />
    </ColumnSizingContainer>
  );
};
