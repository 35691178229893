import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

import { gutters } from 'app/spacing';
import { TextSpan } from 'app/typography';
import { colorsText } from 'app/colors';
import Loader from 'components/Kizen/Loader';

const StyledLoadingOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    padding-top: 0 !important;
    margin-right: ${gutters.spacing(1)}px;
  }
`;

export const NoOptionsMessage = ({ children, ...propsNoOption }) => {
  const { t } = useTranslation();
  return (
    <components.NoOptionsMessage {...propsNoOption}>
      <StyledLoadingOption>
        <Loader loading size={20} />
        <TextSpan style={{ color: colorsText.medium }}>
          {t('Loading...')}
        </TextSpan>
      </StyledLoadingOption>
    </components.NoOptionsMessage>
  );
};
