import { Button } from '@kizen/kds/Button';
import {
  PivotTableCell,
  PivotTableCellProps,
  PivotTableDataCell,
  PivotTableResizableCell,
} from '@kizen/kds/PivotTable';
import { Icon, type IconProps, type ValidIcons } from '@kizen/kds/Icon';
import { Tooltip, useTooltip } from '@kizen/kds/Tooltip';
import { __Internal_Typography } from '@kizen/kds/Typography';
import { merge } from '@kizen/kds/util';
import { collapseNumber } from 'utility/numbers';
import { MouseEvent } from 'react';

export type IconName = IconProps['icon'];

type OnExpandCollapse =
  | (() => void)
  | ((
      e: MouseEvent<HTMLButtonElement>,
      ids: string[],
      autoExpand?: boolean
    ) => void);

type WithPivotTableAdditionalProps<T> = T & {
  id?: string;
  data: string | number;
  statusColor?: string;
  expandable?: boolean;
  expanded?: boolean;
  onExpandCollapse?: OnExpandCollapse;
  loading?: boolean;
  startAdornment?: string;
  subRow?: boolean;
};

export type TypographyPivotTableCellProps = Omit<
  PivotTableCellProps<typeof __Internal_Typography>,
  'as'
>;

type HeaderProps =
  WithPivotTableAdditionalProps<TypographyPivotTableCellProps> & {
    iconColor: IconProps['color'];
    iconHoverClass?: string;
    isSortActive: boolean;
    sortDirection: 'asc' | 'desc';
    onSort: SortableColumnCellProps['onSort'];
    statusColor?: string;
  };

export type SortableColumnCellProps = TypographyPivotTableCellProps & {
  icon: IconName;
  iconColor: IconProps['color'];
  iconHoverClass?: string;
  text: string;
  onSort(col: number): void;
  statusColor?: string;
};

export type TextCellProps = TypographyPivotTableCellProps & {
  id?: string;
  tooltipLabel: string;
  alwaysShowTooltip?: boolean;
  statusColor?: string;
  expandable?: boolean;
  expanded?: boolean;
  onExpandCollapse?: OnExpandCollapse;
  loading?: boolean;
  subRow?: boolean;
};

export type StatusCellProps = TextCellProps & {
  statusColor: string;
};

export type ExpandableCellProps = TextCellProps & {
  id: string;
  expanded?: boolean;
  loading?: boolean;
  onExpandCollapse: () => void;
};

export type StatusCircleProps = {
  color: string;
};

export type StatusCircleStyle = Record<string, string>;

// Cell Adornments
export const StatusCircle = ({ color }: StatusCircleProps) => (
  <span
    className="inline-block rounded-full mr-[10px] w-[10px] h-[10px] bg-[var(--kizen-custom-status-color)]"
    style={{ '--kizen-custom-status-color': color } as StatusCircleStyle}
  />
);

export const SubRowIcon = ({
  id,
  expanded,
  onExpandCollapse,
  loading,
  color,
}: {
  id: string;
  expanded?: boolean;
  onExpandCollapse: (
    e: MouseEvent<HTMLButtonElement>,
    ids: string[],
    autoExpand: boolean
  ) => void;
  loading?: boolean;
  color?: string;
}) => {
  let rightIcon = expanded
    ? ('table-collapse' as ValidIcons)
    : ('table-expand' as ValidIcons);
  if (loading) rightIcon = 'action-loading' as ValidIcons;
  const buttonColor =
    color === 'button-secondary-default' ? 'secondary' : 'tertiary';
  return (
    <Button
      color={buttonColor}
      onActionButtonClick={() => {}}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        onExpandCollapse(e, [id], false);
      }}
      rightIcon={rightIcon}
      rightIconSettings={{
        tooltipEnabled: true,
      }}
      variant="text"
    />
  );
};

// Core cell behaviors
export const TextCell = ({
  children,
  tooltipLabel,
  alwaysShowTooltip = false,
  loading,
  subRow,
  ...rest
}: TextCellProps) => {
  const { triggerProps, targetProps, showTooltip, tooltipProps } =
    useTooltip(alwaysShowTooltip);
  let subRowStyles = '';
  let bottomBorderStyle = '';
  let rowColor = '';
  if (subRow) {
    subRowStyles = 'pl-[22px]';
    rowColor = 'bg-background-lighter';
  }
  if (loading) {
    bottomBorderStyle = 'border-b-1 border-button-secondary-hover';
  }
  return (
    <>
      {showTooltip ? <Tooltip {...tooltipProps} text={tooltipLabel} /> : null}
      <PivotTableCell
        as={__Internal_Typography}
        {...triggerProps}
        {...targetProps}
        className={merge(bottomBorderStyle, subRowStyles, rowColor)}
        {...rest}
      >
        {children}
      </PivotTableCell>
    </>
  );
};

export const TextDataCell = ({
  id,
  children,
  color,
  tooltipLabel,
  alwaysShowTooltip = false,
  statusColor,
  expandable,
  expanded,
  onExpandCollapse,
  loading,
  isLastColumn,
  isFirstColumn,
  subRow,
  href,
  ...rest
}: TextCellProps) => {
  const expandCallback = onExpandCollapse ? onExpandCollapse : () => {};
  const rowId = id === undefined ? '' : id;
  const { triggerProps, tooltipProps, targetProps, showTooltip } =
    useTooltip(alwaysShowTooltip);

  let statusColorStyles = isFirstColumn ? 'ml-[5px]' : '';
  let subRowStyles = isFirstColumn ? 'ml-[5px]' : '';
  let bottomBorderStyle = '';
  let firstColumnStyles = '';
  if (statusColor && isFirstColumn) {
    subRowStyles = '';
  }
  if (subRow && statusColor && isFirstColumn) {
    statusColorStyles = 'ml-[28px]';
  } else if (subRow && isFirstColumn && !statusColor) {
    subRowStyles = 'ml-[28px]';
  }
  if (loading) {
    bottomBorderStyle = 'border-b-1 border-button-secondary-hover';
  }
  if (isFirstColumn) {
    firstColumnStyles = 'w-full';
  }

  return (
    <>
      {showTooltip ? (
        <Tooltip position="top" text={tooltipLabel} {...tooltipProps} />
      ) : null}
      <PivotTableDataCell
        as={__Internal_Typography}
        data-row-headers-loaded={Boolean(children)}
        className={bottomBorderStyle}
        isLastColumn={isLastColumn}
        isFirstColumn={isFirstColumn}
        href={href ? href : undefined}
        variant={href ? 'link' : undefined}
        color={color}
        backgroundColor={subRow ? 'bg-background-lighter' : undefined}
        {...rest}
      >
        <div className={merge('flex items-center', firstColumnStyles)}>
          {expandable && id !== undefined && id !== '' ? (
            <div className="mr-[5px]">
              <SubRowIcon
                id={rowId}
                expanded={expanded}
                onExpandCollapse={expandCallback}
                loading={loading}
                color={color}
              />
            </div>
          ) : null}
          {statusColor ? (
            <div className={statusColorStyles}>
              <StatusCircle color={statusColor} />
            </div>
          ) : null}
          <span
            className={merge(subRowStyles, 'truncate text-center')}
            {...triggerProps}
            {...targetProps}
          >
            {children}
          </span>
        </div>
      </PivotTableDataCell>
    </>
  );
};

export const SortableColumnCell = ({
  className,
  icon,
  iconColor,
  iconHoverClass,
  text,
  onSort,
  column,
  statusColor,
  ...rest
}: SortableColumnCellProps) => {
  return (
    <PivotTableResizableCell
      className={merge('flex flex-row gap-10 relative', className)}
      data-column-headers-loaded={Boolean(text)}
      column={column}
      {...rest}
    >
      <__Internal_Typography
        truncate
        weight="semibold"
        color="font-primary"
        className="max-w-[calc(100%-35px)]"
      >
        {statusColor ? <StatusCircle color={statusColor} /> : null}
        {text}
      </__Internal_Typography>
      <button className="kds" onClick={() => onSort(column)}>
        <Icon
          className={iconHoverClass}
          icon={icon}
          color={iconColor}
          size="sm"
        />
      </button>
    </PivotTableResizableCell>
  );
};

// Header Cells
export const NameHeaderCell = ({
  data,
  iconColor,
  isSortActive,
  sortDirection,
  onSort,
  isLastColumn,
  ...rest
}: HeaderProps) => {
  let icon: ValidIcons = 'table-sort';
  if (isSortActive) {
    if (isLastColumn) {
      icon = sortDirection === 'desc' ? 'table-sort-9-1' : 'table-sort-1-9';
    } else {
      icon = sortDirection === 'desc' ? 'table-sort-z-a' : 'table-sort-a-z';
    }
  }

  return (
    <SortableColumnCell
      {...rest}
      className="kds pl-spacer-20"
      color="font-primary"
      icon={icon}
      iconColor={iconColor}
      text={String(data)}
      onSort={onSort}
      isLastColumn={isLastColumn}
    />
  );
};

export const DataHeaderCell = ({
  column,
  data,
  iconColor,
  isSortActive,
  sortDirection,
  onSort,
  statusColor,
  ...rest
}: HeaderProps) => {
  let icon: ValidIcons = 'table-sort';
  if (isSortActive) {
    icon = sortDirection === 'desc' ? 'table-sort-9-1' : 'table-sort-1-9';
  }

  return (
    <SortableColumnCell
      {...rest}
      className={
        column === 1 ? 'kds pl-spacer-20 pr-spacer-10' : 'kds px-spacer-10'
      }
      column={column}
      icon={icon}
      iconColor={iconColor}
      text={String(data)}
      onSort={onSort}
      statusColor={statusColor}
    />
  );
};

export const TotalsHeaderCell = ({
  data,
  ...rest
}: WithPivotTableAdditionalProps<TypographyPivotTableCellProps>) => {
  return (
    <TextCell
      {...rest}
      truncate
      className="kds px-spacer-10 text-center mr-[var(--kds-width-scrollbar)]"
      color="font-primary"
      weight="semibold"
      tooltipLabel={String(data)}
    >
      {data}
    </TextCell>
  );
};

// Data Cells
export const NameColumnCell = ({
  id,
  data,
  color = 'font-primary',
  statusColor,
  expandable,
  expanded,
  loading,
  onExpandCollapse,
  subRow,
  ...rest
}: WithPivotTableAdditionalProps<TypographyPivotTableCellProps>) => {
  const weight = expandable ? 'semibold' : rest.weight;
  return (
    <TextDataCell
      {...rest}
      id={id}
      truncate
      className="kds px-spacer-20"
      color={color}
      tooltipLabel={String(data)}
      statusColor={statusColor}
      expandable={expandable}
      expanded={expanded}
      onExpandCollapse={onExpandCollapse}
      loading={loading}
      subRow={subRow}
      weight={weight}
    >
      {data}
    </TextDataCell>
  );
};

export const TotalsColumnCell = ({
  data,
  startAdornment = '',
  color,
  ...rest
}: WithPivotTableAdditionalProps<TypographyPivotTableCellProps>) => {
  const tooltipLabel = data?.toLocaleString();
  const cellData = collapseNumber(data);

  return (
    <TextDataCell
      {...rest}
      className="kds text-center justify-center"
      color={color}
      weight="semibold"
      tooltipLabel={`${startAdornment}${tooltipLabel}`}
      alwaysShowTooltip={tooltipLabel !== cellData}
    >
      {startAdornment}
      {cellData}
    </TextDataCell>
  );
};

export const DataCell = ({
  column,
  data,
  color,
  startAdornment = '',
  ...rest
}: WithPivotTableAdditionalProps<TypographyPivotTableCellProps>) => {
  const tooltipLabel = data?.toLocaleString();
  const cellData = collapseNumber(data);

  return (
    <TextDataCell
      {...rest}
      className={
        column === 1 ? 'kds pl-spacer-20 pr-spacer-10' : 'kds px-spacer-10'
      }
      color={color}
      column={column}
      tooltipLabel={`${startAdornment}${tooltipLabel}`}
      alwaysShowTooltip={tooltipLabel !== cellData}
    >
      {startAdornment}
      {cellData}
    </TextDataCell>
  );
};

// Footer Row Cell
export const TotalsRowCell = ({
  column,
  data,
  isFirstColumn,
  isLastColumn,
  startAdornment = '',
  ...rest
}: WithPivotTableAdditionalProps<TypographyPivotTableCellProps>) => {
  const tooltipLabel = data?.toLocaleString();
  const isNumberCell = typeof data === 'number';
  const cellData = isNumberCell ? collapseNumber(data) : data;

  return (
    <TextCell
      {...rest}
      className={
        isLastColumn
          ? 'text-center justify-center'
          : isFirstColumn
            ? 'justify-end px-spacer-20'
            : column === 1
              ? 'kds pl-spacer-20 pr-spacer-10'
              : 'kds px-spacer-10'
      }
      color="font-primary"
      weight="semibold"
      column={column}
      isFirstColumn={isFirstColumn}
      isLastColumn={isLastColumn}
      tooltipLabel={`${startAdornment}${tooltipLabel}`}
      alwaysShowTooltip={tooltipLabel !== cellData}
    >
      {isNumberCell ? startAdornment : ''}
      {cellData}
    </TextCell>
  );
};
