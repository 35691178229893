import {
  checkDeleted,
  displayNameDescriptor,
  nameDescriptor,
} from 'pages/AutomationEngine/helpers';

import { SENDER_TYPE } from 'components/MessageBuilder/components';

import {
  forFlowTeamMember,
  forApiTeamMember,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/notify-member-via-email/notifyMemberViaEmailDTO';

import { templateForApp } from 'services/LibraryService';

import {
  AddErrorMessage,
  MessageDictionary,
} from 'pages/AutomationEngine/types/automation';

import { ForApiProps, FromApiStep } from './types';

const forApi = (step: ForApiProps) => {
  const orgDetails = {
    ...{ email: { id: null } },
    ...step.details,
  };

  const { email, ccTeamMember } = { ...orgDetails };

  const apiCcTeamMember = ccTeamMember ? forApiTeamMember(ccTeamMember) : null;

  return {
    details: { email: { id: email.id }, ccTeamMember: apiCcTeamMember },
  };
};

const forFlow = (
  apiObject: FromApiStep,
  addErrorMessage: AddErrorMessage,
  messageDictionary: MessageDictionary
) => {
  const { senderRole, senderTeamMember, senderType, externalAccount } =
    apiObject;

  switch (senderType) {
    case SENDER_TYPE.LAST_ROLE:
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        senderRole,
        nameDescriptor,
        messageDictionary.associatedRole
      );
      break;

    case SENDER_TYPE.TEAM_MEMBER:
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        senderTeamMember,
        displayNameDescriptor,
        messageDictionary.teamMember
      );
      break;

    case SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX:
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        externalAccount,
        displayNameDescriptor,
        messageDictionary.integratedInbox
      );
      break;

    default:
      if (!senderType) {
        addErrorMessage(
          messageDictionary.thereWasanErrorLoadingTheAssociatedItem
        );
      }
  }

  const ccTeamMember = forFlowTeamMember(
    apiObject.ccTeamMember,
    addErrorMessage,
    messageDictionary
  );

  const flow = {
    email: {
      ...templateForApp(
        apiObject,
        true // kzn-6161 we ned to maintain the baseMessageId to avoid duplicate email creation
      ),
      ...(senderRole && !senderRole.deleted
        ? {
            senderRole: {
              ...senderRole,
              label: senderRole.name,
            },
          }
        : null),
      ...(senderTeamMember && !senderTeamMember.deleted
        ? {
            senderTeamMember: {
              ...senderTeamMember,
              fullName: `${senderTeamMember.firstName} ${senderTeamMember.lastName}`,
            },
          }
        : null),
    },
    ccTeamMember,
  };

  return flow;
};
export const sendEmailDTO = {
  forApi,
  forFlow,
};
