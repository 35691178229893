import React from 'react';
import { useBuilderContext } from 'components/PageBuilder/BuilderContext';
import { RowSettings, ElementContainerSettingsSection } from '../../settings';

const DefaultMaxWidth = '900';

export const EmailRowSettings = ({ node }) => {
  const { rootNode } = useBuilderContext();

  return (
    <>
      <RowSettings
        node={node}
        defaultMaxWidth={rootNode?.data?.props?.maxWidth ?? DefaultMaxWidth}
      />
      <ElementContainerSettingsSection
        node={node}
        enableBackgroundImage={false}
        enableBorder={false}
        enableMargin={false}
      />
    </>
  );
};

export const FormRowSettings = ({ node }) => {
  const { rootNode } = useBuilderContext();

  return (
    <>
      <RowSettings
        node={node}
        defaultMaxWidth={rootNode?.data?.props?.maxWidth ?? DefaultMaxWidth}
      />
      <ElementContainerSettingsSection node={node} />
    </>
  );
};
