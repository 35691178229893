import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { usePermissionValue } from '@kizen/permissions/hooks/usePermissionValue';
import { usePermissionSetter } from '@kizen/permissions/hooks/usePermissionSetter';
import { borderRadii, layers } from 'app/spacing';
import { grayScale, tableHover } from 'app/colors';
import KizenLoader from 'components/Kizen/Loader';
import KizenSwitch from 'components/Kizen/Switch';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import Expander from 'components/Containers/Expander';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import useField from 'hooks/useField';
import { gutters } from 'app/spacing';

const Wrapper = styled.div`
  position: relative;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ??
    gutters.spacing(
      3
    )}px; // Give arrow button enough space so it doesn't scroll the modal
  margin-top: ${({ marginTop }) => marginTop ?? gutters.spacing(4)}px;
  table {
    width: calc(100% + 3px);
    margin-right: -7px;
  }
`;

const Header = styled.div`
  height: 34px;
  background-color: ${tableHover};
  border-top-left-radius: ${borderRadii.small};
  border-top-right-radius: ${borderRadii.small};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  z-index: ${layers.content(0, 2)}; // Appear on top of the gradient
  position: relative;
`;

const Switch = styled(KizenSwitch)`
  margin: 0 0 0 5px;
  label {
    margin: 0;
  }
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const FittedGradient = styled(Gradient)`
  // match to rounding of bottom of Wrapper
  > :first-child {
    border-bottom-left-radius: ${borderRadii.small};
    border-bottom-right-radius: ${borderRadii.small};
  }
`;

const Loader = styled(KizenLoader)`
  padding-top: 5px !important;
`;

export const Section = ({
  title,
  sectionId,
  children,
  onChange,
  onExpand,
  onExpanderHover,
  loading = false,
  showExpander = true,
  showArrowBtn,
  initialExpanderState = 'partially-open',
  showSwitchToggle,
  ...rest
}) => {
  const setPermission = usePermissionSetter(sectionId);
  const show = usePermissionValue(sectionId, ({ value }) => value);
  const showSwitch = usePermissionValue(
    sectionId,
    ({ allowed_access }) => allowed_access?.length > 1
  );
  const [expanderState, setExpanderState] = useField(
    show ? initialExpanderState : 'close'
  );
  const [lastOpenState, setLastOpenState] = useState(initialExpanderState);
  const showLoader = loading && expanderState === 'open';

  return (
    <Wrapper {...rest} data-qa-permission-section={sectionId}>
      <Header>
        <TextEllipsisWithTooltip as="h3">{title}</TextEllipsisWithTooltip>
        {(showSwitchToggle ?? showSwitch) && (
          <Switch
            data-qa-permission-section-toggle
            textPlacement="none"
            checked={show}
            onChange={(ev) => {
              const { checked } = ev.target;
              setPermission(checked);
              setExpanderState(checked ? lastOpenState : 'close');
              onChange?.(checked);
            }}
          />
        )}
      </Header>
      {showExpander && (
        <Expander
          title={title}
          expanded={expanderState}
          gradient={<FittedGradient />}
          partiallyOpenHeight={50}
          showArrowBtn={showArrowBtn ?? show}
          onMouseOver={onExpanderHover}
          onChange={(newState) => {
            if (newState !== 'close') {
              setLastOpenState(newState);
            }
            if (newState === 'open') {
              onExpand?.();
              setExpanderState('open');
            }
          }}
        >
          {showLoader ? (
            <Loader loading />
          ) : (
            <ContentWrapper>{children}</ContentWrapper>
          )}
        </Expander>
      )}
    </Wrapper>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  showExpander: PropTypes.bool,
};
