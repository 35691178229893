import styled from '@emotion/styled';
import { gutters } from 'app/spacing';

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(0, auto);
  column-gap: ${gutters.spacing(4)}px;

  .left-column,
  .right-column {
    p {
      display: block;
      margin: 0 0 ${gutters.spacing(3)}px;
    }

    .text-message {
      word-break: break-word;
      white-space: break-spaces;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
`;
