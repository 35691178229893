import React from 'react';
import PropTypes from 'prop-types';
import { colorsSecondary } from 'app/colors';
import { TextSpan } from 'app/typography';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import FieldsTable from '../FieldsTable';

function SubmissionLabel({ initiator }) {
  const { name, type } = initiator;
  const base = 'survey submitted';

  if (type === 'employee') {
    return (
      <>
        <TextSpan size="micro">{base} by </TextSpan>
        <TextSpan weight="bold" size="micro">
          {name}
        </TextSpan>
      </>
    );
  }

  return <TextSpan size="micro">{base}</TextSpan>;
}

SubmissionLabel.propTypes = {
  initiator: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

const SurveySubmittedEventBlock = ({ event, ...others }) => {
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsSecondary.magenta.dark}
          icon="poll"
          size="16px"
        />
      }
      isEmpty
      timestamp={event.created}
      title={
        <>
          <TextSpan weight="bold" size="micro">
            {event.data.surveyName}
            {event.data.formName}
          </TextSpan>{' '}
          <SubmissionLabel initiator={event.initiator} />
        </>
      }
      event={event}
      {...others}
    >
      <FieldsTable fields={event.data.fields} />
    </BasicBlock>
  );
};

SurveySubmittedEventBlock.propTypes = {
  event: PropTypes.shape({
    data: PropTypes.shape({
      surveyName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SurveySubmittedEventBlock;
