import { useMemo } from 'react';
import { ActionGroupElement } from './types';

export const useElementsForActionGroup = (elements: ActionGroupElement[]) => {
  const sortedElements = useMemo(() => {
    return elements.sort((a, b) => a.order - b.order);
  }, [elements]);

  const topLevelElements = useMemo(() => {
    return sortedElements.filter((element) => !element.collapseToTray);
  }, [sortedElements]);

  const trayElements = useMemo(() => {
    return sortedElements.filter((element) => element.collapseToTray);
  }, [sortedElements]);

  return { sortedElements, topLevelElements, trayElements };
};
