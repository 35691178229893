import { PageSizing } from '__components/Layout/PageContentWidth';
import { StyledTwoColumnsRow } from './styles';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useContext, useEffect } from 'react';
import { SmartConnectorContext } from '../../context';
import { SmartConnectorType } from 'pages/SmartConnectors/types';
import { SmartConnectorTypeButtons } from './SmartConnectorTypeButtons';
import { CustomObjectSelect } from './CustomObjectSelect';
import { NameInput } from './NameInput';
import { EmailInput } from './EmailInput';
import { StyledCard, StyledLoader, StyledStepHeader } from '../styles';
import { SMART_CONNECTOR_STEPS } from '__pages/SmartConnectors/constants';
import Switch from '__components/Kizen/Switch';

export const GeneralSettingsStep = () => {
  const { t } = useTranslation();
  const { smartConnector, stepData, setStepData, setIsValid, loading } =
    useContext(SmartConnectorContext);

  useEffect(() => {
    setIsValid(true);
  }, [setIsValid]);

  const handleChangeName = (value: string) => {
    setStepData((prev) => ({ ...prev, name: value }));
  };

  const handleChangeEmail = (value: string) => {
    setStepData((prev) => ({ ...prev, error_notification_email: value }));
  };

  const handleChangeConnectorType = ({
    value,
  }: {
    value: SmartConnectorType;
  }) => {
    // we do not support connector types other than spreadsheet
    //setStepData((prev) => ({ ...prev, connector_type: value }));
  };

  const handleChangeObject = (value: string) => {
    setStepData((prev) => ({ ...prev, custom_object: value }));
  };

  const handleChangeDiffChecking = (ev: ChangeEvent<HTMLInputElement>) =>
    setStepData((prev) => ({
      ...prev,
      is_diff_check_enabled: ev.target.checked,
    }));

  return (
    <PageSizing>
      <StyledCard data-qa={`${SMART_CONNECTOR_STEPS.general}-step`}>
        <StyledStepHeader type="subheader">
          {t('General Settings')}
        </StyledStepHeader>
        <StyledLoader loading={loading}>
          <StyledTwoColumnsRow>
            <NameInput value={stepData.name} onChange={handleChangeName} />
            <div />
          </StyledTwoColumnsRow>
          <SmartConnectorTypeButtons
            value={stepData.connector_type!}
            onChange={handleChangeConnectorType}
          />
          <StyledTwoColumnsRow>
            <CustomObjectSelect
              value={stepData.custom_object!}
              onChange={handleChangeObject}
              disabled={!!smartConnector.id}
            />
            <EmailInput
              value={stepData.error_notification_email}
              onChange={handleChangeEmail}
            />
          </StyledTwoColumnsRow>
          <StyledTwoColumnsRow>
            <Switch
              onChange={handleChangeDiffChecking}
              checked={stepData.is_diff_check_enabled || false}
              removeMargin
              label={t('Only Process Rows that Contain New Data?')}
              tooltip={t(
                'Kizen can optionally run a diff check and conditionally only process rows that contain new data since the last time this connector ran.'
              )}
              textPlacement="regular-top"
            />
          </StyledTwoColumnsRow>
        </StyledLoader>
      </StyledCard>
    </PageSizing>
  );
};
