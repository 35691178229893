import {
  colorsText,
  grayScale,
  colorsPrimary,
  colorsSecondary,
} from 'app/colors';
import Icon from 'components/Kizen/Icon';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  color: ${colorsText.dark};
  > :first-child {
    margin: ${gutters.standard};
  }
`;

export const SummaryContainer = styled.div`
  margin: ${gutters.standard} 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HistoricalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const HistoricalDatum = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  &:first-child {
    border-right: 1px solid ${grayScale.mediumLight};
  }
`;

export const StyledTrendCaretIcon = styled(Icon)`
  font-size: inherit;
  height: inherit;
  width: inherit;
  vertical-align: middle;
  margin-right: 3px;
`;

export const getColor = (value, defined) => {
  if (!defined || value === 0) {
    return grayScale.dark;
  }
  return value > 0 ? colorsPrimary.green.dark : colorsSecondary.red.dark;
};
