import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import { namedToOption } from 'services/helpers';
import BasicModal from 'components/Modals/presets/BasicModal';

const MessageText = styled(KizenTypography)`
  text-align: center;
  margin-bottom: ${gutters.spacing(4, { baseline: true })}px;
`;

export default function StageDeletionModal({
  model,
  stage,
  onHide,
  onConfirm,
  ...others
}) {
  const { t } = useTranslation();
  const [targetStage, setTargetStage] = useState(null);
  const options = model.pipeline.stages
    .filter((s) => s.id !== stage.id)
    .map(namedToOption);
  return (
    <BasicModal
      heading={t('Please Confirm Stage Deletion')}
      buttonText={t('Confirm')}
      defaultLeftBtnText={t('Cancel')}
      disabled={!targetStage}
      onHide={onHide}
      defaultLeftBtnHandler={onHide}
      onConfirm={() => onConfirm(targetStage.value)}
      {...others}
    >
      <MessageText>
        {`${t(
          'This will delete the stage permanently, including all historical stage data used in your dashboards. To proceed, please choose a new stage where you would like to move all existing deals currently in'
        )} ${stage.name}.`}
      </MessageText>
      <Select
        label={t('New Target Stage')}
        placeholder={t('Choose New Target Stage for Records')}
        menuInline
        value={targetStage}
        onChange={setTargetStage}
        options={options}
      />
    </BasicModal>
  );
}

StageDeletionModal.propTypes = {
  model: PropTypes.object.isRequired,
  stage: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
