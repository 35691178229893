import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

type Ctx = {
  filterType: string | null;
  objectType: 'client_client' | 'pipeline' | 'standard' | null;
  relation: null | {
    cardinality: string;
    inverse_relation_rollup_leadsources: boolean;
    inverse_relation_rollup_timeline: boolean;
    fetch_url: 'client' | 'pipeline' | 'standard';
    related_cateogry: string;
    related_entity_name: string;
    related_field: string;
    related_name: string;
    related_object: string;
    related_object_default_on_activities: boolean;
    related_object_name: string;
    relation_type: string;
    rollup_leadsources: boolean;
    rollup_timeline: boolean;
  };
  getGroupLink(groupId: string): string;
};

const context = createContext<Ctx>({
  filterType: 'fields',
  objectType: null,
  relation: null,
  getGroupLink: () => '',
});

export const useFilterContext = () => {
  return useContext(context);
};

export const FilterContext = ({
  children,
  filterType,
  objectType,
  relation,
}: PropsWithChildren<Pick<Ctx, 'filterType' | 'objectType' | 'relation'>>) => {
  const access = useSelector((state: any) => state.authentication.access);
  const clientObject = useSelector(getBusinessClientObject);
  const contactId = clientObject?.id;
  const canViewContacts =
    clientObject.access && access.sections.contacts_section.view;

  const ctx = useMemo(() => {
    const canViewCustomObject = (id: string) => {
      if (canViewContacts && id === contactId) {
        return true;
      }
      return access.custom_objects.custom_object_entities[id]?.enabled;
    };

    const getGroupLink = (groupId: string) => {
      if (
        !relation ||
        !groupId ||
        !canViewCustomObject(relation.related_object)
      ) {
        return '';
      }

      return relation.fetch_url === 'client'
        ? `/clients?group=${groupId}`
        : `/custom-objects/${relation.related_object}?group=${groupId}`;
    };

    return {
      filterType,
      objectType,
      relation,
      getGroupLink,
    };
  }, [access, canViewContacts, contactId, filterType, objectType, relation]);

  return <context.Provider value={ctx}>{children}</context.Provider>;
};
