import { useState, useRef } from 'react';
import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { ICON_BUTTON_SIZING } from 'components/Kizen/IconButton';
import { grayScale, colorsButton } from 'app/colors';
import useModal from 'components/Modals/useModal';
import ActivityActions from 'pages/Common/Actions/ActivityActions';
import AutomationActions from 'pages/Common/Actions/AutomationActions';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

const IconBar = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${({ condensed }) => (condensed ? 'flex-end' : 'center')};
  position: relative;

  /* Handle the StagedSelect wrapped in a div to style the menu header/footer */
  > a,
  > div > a,
  > button,
  > div > button {
    transition: box-shadow 0.5s ease-in-out;
    transition: padding 0.5s ease-in-out;
    transition: border-color 0.5s ease-in-out;
    padding: ${({ condensed }) => (condensed ? `0 10px` : `0 15`)};

    > i {
      transition-property: opacity;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }

    &:hover i {
      color: ${grayScale.dark};
    }
  }

  &.is-scrolled {
    > a,
    div > a,
    > button,
    > div > button {
      cursor: default;
      border-color: transparent;
      box-shadow: none;
      > i {
        opacity: 0 !important;
      }
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 10px;
  margin-right: 10px;
`;

const ActionBar = ({
  condensed = false,
  contact = EMPTY_OBJECT,
  clientObject,
  scrolled,
  onStartAutomation,
  logActivityActions,
  fieldState,
  activityList,
  canStartAutomations,
  onClickEmail,
  onClickPhone,
  ...props
}) => {
  const [showLogActivity, setShowLogActivity] = useState(false);
  const actionButtonRef = useRef(null);

  const [showSelectedAutomation, setShowSelectedAutomation] = useState();
  const automationButtonRef = useRef(null);
  const [predefinedOptions, setPredefinedOptions] = useState({});

  const [, , scheduleActivityModal] = useModal();

  return (
    <>
      <IconBar
        className={scrolled ? 'is-scrolled' : ''}
        condensed={condensed}
        {...props}
        scrolled={scrolled}
      >
        {/* phone */}
        <StyledIconButton
          sizing={ICON_BUTTON_SIZING.overlay}
          color={colorsButton.iconGray}
          borderColor={grayScale.medium}
          disabled={scrolled}
          onClick={onClickPhone}
        >
          <Icon icon="phone" />
        </StyledIconButton>
        {/* email */}
        <StyledIconButton
          sizing={ICON_BUTTON_SIZING.overlay}
          color={colorsButton.iconGray}
          borderColor={grayScale.medium}
          disabled={scrolled}
          onClick={onClickEmail}
        >
          <Icon icon="email" />
        </StyledIconButton>
        {/* log activity */}
        <StyledIconButton
          sizing={ICON_BUTTON_SIZING.overlay}
          color={colorsButton.iconGray}
          borderColor={grayScale.medium}
          disabled={scrolled}
          onClick={() => setShowLogActivity(true)}
          ref={actionButtonRef}
        >
          <Icon icon="clipboard-check" />
        </StyledIconButton>
        <ActivityActions
          {...props}
          showLogActivity={showLogActivity}
          target={actionButtonRef.current}
          contact={contact}
          clientObject={clientObject}
          onSetVisible={(val) => setShowLogActivity(val)}
          scrolled={scrolled}
          onShowScheduleActivity={() => scheduleActivityModal.show()}
          setPredefinedOptions={(val) => setPredefinedOptions(val)}
          shownScrolled={false}
          logActivityActions={logActivityActions}
          fieldState={fieldState}
          activityList={activityList}
        />
        {/* automation */}
        {canStartAutomations && contact?.access?.edit ? (
          <StyledIconButton
            sizing={ICON_BUTTON_SIZING.overlay}
            color={colorsButton.iconGray}
            borderColor={grayScale.medium}
            disabled={scrolled}
            onClick={() => setShowSelectedAutomation(true)}
            ref={automationButtonRef}
          >
            <Icon icon="sync" />
          </StyledIconButton>
        ) : null}

        <AutomationActions
          {...props}
          showSelectedAutomation={showSelectedAutomation}
          target={automationButtonRef.current}
          contact={contact}
          onSetVisible={(val) => setShowSelectedAutomation(val)}
          scrolled={scrolled}
          predefinedOptions={predefinedOptions}
          shownScrolled={false}
          scheduleActivityModal={scheduleActivityModal}
          customObjectId={clientObject?.id}
          onStartAutomation={onStartAutomation}
        />
      </IconBar>
    </>
  );
};

export default ActionBar;
