import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { CardHeader } from '../components/CardHeader';
import { defaultEmployeeString, defaultSmartConnectorString } from '../helpers';
import { eventTypes, initiatorTypes } from '../../constants';
import { TextSpan } from 'app/typography';

const cardHeaderConfig = (t, { typeName }) => {
  const prefix =
    typeName === eventTypes.entityArchived ? (
      <>
        <TextSpan weight="bold" size="micro">
          {t('Archived')}
        </TextSpan>{' '}
        {t('by')}
      </>
    ) : (
      <>
        <TextSpan weight="bold" size="micro">
          {t('Unarchived')}
        </TextSpan>{' '}
        {t('by')}
      </>
    );
  return {
    [initiatorTypes.employee]: {
      prefix,
      creationType: defaultEmployeeString,
    },
    [initiatorTypes.employee_upload]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via Upload'),
    },
    [initiatorTypes.employee_api_request]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via API'),
    },
    [initiatorTypes.smart_connector]: {
      prefix,
      creationType: defaultSmartConnectorString,
    },
  };
};

const ArchivedUnArchivedEventBlock = ({ event, ...others }) => {
  const { typeName, instance } = event;
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          icon={
            typeName === eventTypes.entityArchived ? 'user-minus' : 'user-plus'
          }
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <>
          <CardHeader
            name={instance?.entityRecordName}
            nameWeight={'normal'}
            event={event}
            config={cardHeaderConfig}
          />
        </>
      }
      event={event}
      {...others}
    />
  );
};

export default ArchivedUnArchivedEventBlock;
