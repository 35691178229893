import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissionsContext } from '@kizen/permissions/hooks/context';
import {
  StyledCard,
  Wrapper,
  StyledKizenTypography,
  StyledButton,
} from './styled';
import { useCustomObjectWizard } from '../../CustomObjectWizardContext';

export default function CustomBuilderCard({
  children,
  label,
  applyButton,
  saving,
  onClick,
  disabled,
  ...rest
}) {
  const { payload } = usePermissionsContext();
  const { preSaveFn: setPreSaveCallBack } = useCustomObjectWizard();
  const { t } = useTranslation();

  const onApply = useCallback(async () => {
    await onClick(payload?.deref());
    return false;
  }, [payload, onClick]);

  useEffect(() => {
    setPreSaveCallBack(!disabled && onApply);
    return () => setPreSaveCallBack(null);
  }, [setPreSaveCallBack, disabled, onApply]);

  return (
    <StyledCard {...rest}>
      <Wrapper>
        {label ? (
          <StyledKizenTypography as="h3" type="subheader">
            {label}
          </StyledKizenTypography>
        ) : null}
        {applyButton ? (
          <StyledButton
            onClick={onApply}
            disabled={disabled}
            loading={saving}
            color="green"
            data-qa={'apply_button'}
          >
            {t('APPLY')}
          </StyledButton>
        ) : null}
      </Wrapper>
      {children}
    </StyledCard>
  );
}
