import styled from '@emotion/styled';
import {
  SubNavigationNavLink,
  SubNavigationNavBar,
} from 'components/Navigation/SubNavigation';
import css from '@emotion/css';
import { grayScale, shadowOverlay, appBackground } from 'app/colors';
import Loader from 'components/Kizen/Loader';
import { breakpoints, gutters, layers } from 'app/spacing';
import FullscreenModal from 'components/Modals/Fullscreen';
import KizenTypography from 'app/kizentypo';
const TOP_NAV_HEIGHT = 56 + 40;

export const StyledFullscreenModal = styled(FullscreenModal)`
  min-width: ${breakpoints.lg}px;
  .modal-content {
    background-color: ${({ scrolledTop }) =>
      scrolledTop ? appBackground : grayScale.white};
    & > div:first-child {
      margin: 0;
      z-index: ${layers.modals(1, 6)};
      height: ${TOP_NAV_HEIGHT}px;
      background-color: ${({ scrolledTop }) =>
        scrolledTop
          ? appBackground
          : css`
              ${grayScale.white};
              ${shadowOverlay}
            `};
    }
    & > div:last-child {
      background-color: ${appBackground};
      overflow-y: scroll;
    }
  }
`;

export const StyledSubNavigationNavBar = styled(SubNavigationNavBar)`
  & > .navbar {
    margin-top: 0;
  }
`;
export const StyledLoader = styled(Loader)`
  height: 100%;
  padding-top: 0 !important;
`;

export const StyledSubNavigationNavLink = styled(SubNavigationNavLink)`
  ${({ disabled }) =>
    disabled &&
    css`
      & > i,
      & > span {
        color: ${grayScale.mediumDark};
      }
      background-color: ${grayScale.light};
    `}
`;

export const MobileContainer = styled.div`
  padding: ${gutters.standard};
  text-align: center;
`;
export const StyledKizenTypography = styled(KizenTypography)`
  flex: none;
`;
