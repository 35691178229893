import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { breakpoints, scrollbarCss, gutters, layers } from 'app/spacing';
import { colorsPrimary } from 'app/colors';
import Toast from 'components/Toast';
import RangeInput from 'components/Kizen/RangeInput';
import ColorPickerInput from 'components/Kizen/ColorPickerInput';
import Button from 'components/Button';
import { TextEllipsis } from 'components/Kizen/Table';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { FORMAT_TYPES } from 'components/Kizen/RangeInput';
import { KizenRangeInput } from 'pages/Settings/pages/Team/PermissionGroupModal_V2/components/KizenRangeInput';
import Pill from 'components/Kizen/Pill';
import Pills from 'components/Kizen/Pill/Pills';
import Section from './helpers/Section';
import SubSection from './helpers/SubSection';
import Cols from './helpers/Cols';

const StyledSubSection = styled(SubSection)`
  // This is needed to display the dashlet shadow, not
  // on top of the menu. But I never fully understood why.
  z-index: ${layers.content(0, 1)};
  position: relative;
  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 30px;
    hr {
      display: none;
    }
  }
`;

const ScrollBar = styled.div`
  ${scrollbarCss}
  ${({ width, height }) => css`
    height: ${height || '5px'};
    width: ${width || '5px'};
    overflow: auto;
    > div {
      height: ${height ? `calc(${height} * 2)` : '1px'};
      width: ${width ? `calc(${width} * 2)` : '1px'};
    }
  `}
`;

const PermissionRangeInput = styled(KizenRangeInput)`
  margin-bottom: ${gutters.standard};
`;

export default function OtherComponentsSample() {
  const [color, setColor] = useState(colorsPrimary.blue.dark);
  const [showToast, setShowToast] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [toastMessage, setToastMessage] = useState('Confirmation');
  const [tooltip1Props, tooltip1] = useTruncationTooltip();
  const [tooltip2Props, tooltip2] = useTruncationTooltip();
  const [rangeValue, setRangeValue] = useState(0);
  const [rangeTooltip, setRangeTooltip] = useState(0);
  const [multiSliderValue1, setMultiSliderValue1] = useState(1);
  const [multiSliderValue2, setMultiSliderValue2] = useState(1);
  const [multiSliderValue3, setMultiSliderValue3] = useState(2);

  const pills = [
    { id: 'ID_1', name: 'PILL_NAME_1' },
    { id: 'ID_2', name: 'PILL_NAME_2' },
    { id: 'ID_3', name: 'PILL_NAME_3' },
    { id: 'ID_4', name: 'PILL_NAME_4' },
    { id: 'ID_5', name: 'PILL_NAME_5' },
  ];
  return (
    <Section title="Other Components">
      <Cols columns={3} gutter="30px">
        <StyledSubSection title="Horizontal Scrollbar">
          <ScrollBar width="100px">
            <div />
          </ScrollBar>
        </StyledSubSection>
        <StyledSubSection title="Vertical Scrollbar">
          <ScrollBar height="100px">
            <div />
          </ScrollBar>
        </StyledSubSection>
        <StyledSubSection title="Color Picker" style={{ zIndex: 2 }}>
          <ColorPickerInput color={color} onChange={setColor} />
        </StyledSubSection>
        <StyledSubSection title="Multilevel Sliders">
          <PermissionRangeInput min={1} max={1} value={1} />
          <PermissionRangeInput
            max={1}
            value={multiSliderValue1}
            onChange={setMultiSliderValue1}
          />
          <PermissionRangeInput
            max={2}
            value={multiSliderValue2}
            onChange={setMultiSliderValue2}
          />
          <PermissionRangeInput
            max={3}
            value={multiSliderValue3}
            onChange={setMultiSliderValue3}
          />
        </StyledSubSection>
        <StyledSubSection title="Message: Confirmation">
          <Toast yOffset={10} onClose={() => {}} show>
            Message: Confirmation
          </Toast>
        </StyledSubSection>
        <StyledSubSection title="Message: Failure">
          <Toast yOffset={10} variant="alert" onClose={() => {}} show>
            Message: Failure
          </Toast>
        </StyledSubSection>
        <StyledSubSection title="Show Message: Confirmation & Failure">
          <Button
            onClick={() => {
              setToastVariant('success');
              setToastMessage('Confirmation');
              setShowToast(true);
            }}
          >
            Show Message: Confirmation
          </Button>
          <Button
            color="red"
            onClick={() => {
              setToastVariant('alert');
              setToastMessage('Failure');
              setShowToast(true);
            }}
          >
            Show Message: Failure
          </Button>
          <Toast
            yOffset={10}
            onClose={() => setShowToast(false)}
            variant={toastVariant}
            show={showToast}
          >
            Message: {toastMessage}
          </Toast>
        </StyledSubSection>
        <StyledSubSection title="Tooltips">
          {tooltip1}
          <TextEllipsis style={{ maxWidth: 100 }} {...tooltip1Props}>
            Some short, truncated text.
          </TextEllipsis>
          {tooltip2}
          <TextEllipsis {...tooltip2Props}>
            Some very long, truncated text. Over t&apos; ankle biteah from away,
            bluebries suppah huntin&apos; deeah Moody&apos;s huck, got in a gaum
            Laum Ipsum sumpin&apos; fierce numb stove up back woods cubboard
            back woods, grindah out in th&apos; willie-wacks yow uns nummah than
            a faht batrees wee bit nippy Katahdin some cunnin no-see-um, grindah
            yut paypuh bowee, tube steak Up in thah county unthaw muckle riyht
            on&apos;ta her gettin&apos; ugly down east, Laum Ipsum back woods
            grindah can&apos;t get theyah from heeyah blizzahd. Mummah yahd sawr
            Loyston-Ahban owt jeezly crunchah lobstahrin&apos; sawr fellers.
            Blizzahd potatoes idear robin showah wickid decent got in a gaum The
            County no-see-um You is sum wicked suhmart, Bangah huntin&apos;
            deeah crunchah.
          </TextEllipsis>
        </StyledSubSection>
        <StyledSubSection title="Input Range Value Slider">
          <RangeInput
            label="Input Range Value Slider"
            value={rangeValue}
            min={0}
            max={10000}
            step={100}
            onChange={(value) => {
              setRangeValue(value);
              setRangeTooltip(value);
            }}
            tooltipValue={rangeTooltip}
            onTooltipChange={setRangeTooltip}
            formatMarker={FORMAT_TYPES.COMMAS}
            style={{ maxWidth: '75%' }}
          />
        </StyledSubSection>
        <StyledSubSection title="Standalone Pill">
          <Pill id="pill_ID" name="PILL_NAME" maxWidth="193px" />
        </StyledSubSection>
        <StyledSubSection title="Standalone Pills">
          <Pills
            pills={pills}
            maxPillWidth="193px"
            label="Tags"
            noPillsMessage="No Tags"
          />
        </StyledSubSection>
      </Cols>
    </Section>
  );
}
