import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton } from '__app/colors';
import { layers } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import Button from '__components/Button';
import TextInput from '__components/Inputs/TextInput';
import KizenErrorCard from '__components/Kizen/ErrorCard';
import Switch from '__components/Kizen/Switch';

export const Container = styled.div`
  margin-bottom: 40px;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

export const SaveControlsContainer = styled(ControlsContainer)`
  margin-bottom: -5px;
`;

export const SavedItemsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SavedItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SaveNameInput = styled(TextInput)`
  align-self: center;
`;

export const CustomObjectIdInput = styled(TextInput)`
  width: 300px;
`;

export const Divider = styled.hr`
  width: 100%;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`;

export const PrefixText = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

export const SearchButton = styled(Button)`
  margin: 0;
  padding: 0;
  align-self: flex-end;
`;

export const StyledSwitch = styled(Switch)`
  margin: 0;
  align-self: center;
  padding-top: 28px;
`;

export const XRemove = styled.span`
  font-size: 12px;
  cursor: pointer;
  padding-top: 0.25em;
`;

export const SavedItemContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(max-content, 1fr) 5%;
  gap: 20px;
  margin-bottom: 5px;

  a {
    font-size: 1.6em;
  }
`;

export const FilterPayloadTextarea = styled.textarea`
  width: 100%;
  min-height: 200px;
  font-size: 1.6em;
`;

export const SaveAndLoadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ClickableText = styled(KizenTypography)`
  color: #528ef9 !important;
`;

export const LoadV1Filter = styled(ClickableText)`
  padding-top: 5px;
`;

/**
 * DateTime input components have a z-index of 2 for usage with the
 * bigtable on the overview pages. Error popups need a z-index of 3
 * because of that.
 */
export const ErrorCardWrapper = styled.div<{
  modalLayer?: number;
  outOfBoundaries?: boolean;
  hideOutOfBoundaries?: boolean;
}>`
  opacity: ${({ outOfBoundaries, hideOutOfBoundaries }) =>
    outOfBoundaries && hideOutOfBoundaries ? `0` : `1`};

  ${({ modalLayer }) =>
    modalLayer
      ? css`
          z-index: ${layers.modals(modalLayer, 1)};
        `
      : css`
          z-index: 3;
        `}
`;

export const ErrorCard = styled(KizenErrorCard)`
  z-index: ${layers.validation};
  border-color: ${colorsButton.red.hover};
  max-width: unset;

  ${({ translateX = null, translateY = null }) =>
    translateX || translateY
      ? css`
          transform: translate(${translateX || 0}px, ${translateY || 0}px);
        `
      : null}
`;
