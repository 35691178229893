import { SENDER_TYPE } from 'components/MessageBuilder/components';
import { EmailSendOptionsTeamMember } from './email-send-options/types';

interface ValidateProps {
  email: any;
  teamMember: EmailSendOptionsTeamMember;
  type: string;
  externalAccount: any;
  senderType: string;
}

export const validIntegratedInbox = (email: ValidateProps) => {
  return email && email?.senderType === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX
    ? email?.externalAccount
    : true;
};
