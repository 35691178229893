import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import UUID from '../../utility/UUID';

export default function useModal({
  handleError,
  handleHide,
  handleSubmit,
  closeOnSubmit = true,
  processHideOnSubmit = true,
} = {}) {
  const [show, setShow] = useState(false);
  const hideFn = useRef(handleHide);
  const submitFn = useRef(handleSubmit);
  const errorFn = useRef(handleError);
  const closeOnSubmitBool = useRef(closeOnSubmit);
  const processHideOnSubmitBool = useRef(processHideOnSubmit);

  useEffect(() => {
    hideFn.current = handleHide;
    submitFn.current = handleSubmit;
    errorFn.current = handleError;
  });

  const onHide = useCallback(() => {
    if (hideFn.current) {
      hideFn.current();
    }
    return setShow(false);
  }, []);

  const showModal = useCallback(() => {
    setShow(true);
  }, []);

  const onConfirm = useCallback(
    async (...args) => {
      let maybeError;
      try {
        if (submitFn.current) {
          await submitFn.current(...args);
        }
      } catch (err) {
        maybeError = err;
      }
      if (maybeError && errorFn.current) {
        errorFn.current(maybeError);
      } else if (closeOnSubmitBool.current) {
        if (processHideOnSubmitBool.current) {
          onHide();
        } else {
          setShow(false);
        }
      }
    },
    [onHide]
  );

  const uuid = useMemo(() => UUID.generate(), []);
  const key = `${show}-${uuid}`;

  return [
    {
      key,
      onConfirm,
      onHide,
      show,
    },
    {
      onClick: showModal,
    },
    // controller interface, for when modal logic is used piecemeal, not spread
    {
      // listing mostly for completeness; seems dubious you'd ever need / want to submit a modal other than by clicking own submission button
      confirm: onConfirm,
      showing: show,
      show: showModal,
      hide: onHide,
      // Not ideal, but sometimes the modal we need to manage isn't collocated with a useModal call, but we need to get a key to it anyway
      // see pages/ContactDetail/ActionBar, usage of AutomationActions (which contains a modal)
      key,
    },
  ];
}
