import { QUICK_FILTERS_FIELD_TYPES } from 'components/Filters/QuickFilters/constants';
import { QUICK_FILTERS_IN_GROUPS } from 'store/utilities';
import { v4 as uuidv4 } from 'uuid';

export const getQuickFiltersPayload = (rightItems) =>
  rightItems.map(({ id, label, fieldId, relatedObjectFieldId }) => ({
    id,
    fieldId,
    relatedObjectFieldId,
    label,
  }));

const getFieldLabel = (fieldId, fields) => {
  return fields.find(({ id }) => id === fieldId)?.displayName;
};

export const getDefaultRelatedFieldLabel = (
  fieldName,
  relatedObjectFieldId,
  relatedObjectFieldName,
  t
) => {
  return relatedObjectFieldId === QUICK_FILTERS_IN_GROUPS
    ? t('{{ relationshipField}} Groups', {
        relationshipField: fieldName,
      })
    : `${fieldName}: ${relatedObjectFieldName}`;
};

export const getRightItems = (settings, allFields, t) => {
  return settings.reduce(
    (acc, { label, id, fieldId, relatedObjectFieldId, relatedObjectField }) => {
      const existingField = allFields.find((field) => fieldId === field.id);

      if (existingField) {
        acc.push({
          label,
          id,
          fieldId,
          relatedObjectFieldId,
          relatedObjectField,
          defaultLabel: relatedObjectFieldId
            ? getDefaultRelatedFieldLabel(
                existingField.displayName,
                relatedObjectFieldId,
                relatedObjectField ? relatedObjectField.displayName : null,
                t
              )
            : existingField.displayName,
        });
      }
      return acc;
    },
    []
  );
};

export const getLeftItems = (categorizedFields, allFields) => {
  return categorizedFields
    .filter(({ fields }) => fields.length)
    .map((group) => ({
      id: group.id,
      isGroupedItem: true,
      label: group.label,
      items: group.fields.reduce((acc, field) => {
        if (QUICK_FILTERS_FIELD_TYPES.includes(field.fieldType)) {
          return acc.concat({
            label: field.label || field.displayName,
            id: uuidv4(),
            fieldId: field.id,
            defaultLabel: getFieldLabel(field.id, allFields),
            fieldType: field.fieldType,
          });
        }
        return acc;
      }, []),
    }))
    .flatMap((group) => {
      return [{ ...group }, ...group.items];
    });
};

export const getPathBack = (path, backSteps = 1) =>
  path.split('/').slice(0, -backSteps).join('/');

// We do not have propper way to migrate invalid settings (snake_case field_id) on BE
// so we repair them on FE on the fly where needed
export const repairSettings = (settings = []) =>
  settings.map((s) => ({
    ...s,
    fieldId: s.fieldId || s.field_id,
  }));
