import get from 'lodash/get';
import { AbsoluteString, RelativeString, StepData } from './types';
import { optionFilter } from './option-filter';

type Option = { label: string; value: string };
type BuildOptionsArgs = Pick<
  StepData,
  'option_filter' | 'result_path' | 'value_id_path'
> & {
  option_paths?: Record<string, string[]>;
};

export function* drop<T>(iter: IterableIterator<T>, n: number = 1) {
  while (n-- > 0 && !iter.next().done) {}
  yield* iter;
}

export function* first<T>(iter: IterableIterator<T>) {
  yield iter.next().value;
}

export const getContactVariables = (
  id: string,
  isContact: boolean,
  isPipeline: boolean
): [string, string][] => {
  return [
    ['custom_object_id', id],
    [
      'object_type',
      isContact ? 'client_client' : isPipeline ? 'pipeline' : 'standard',
    ],
  ];
};

export const getKeys = <T extends object>(paths: T, src: object) => {
  return (Object.entries(paths) as [keyof T, any][]).reduce(
    (acc, [key, path]) => {
      if (!path.length) {
        acc[key] = src;
      } else if (path.length > 1 && path.slice(0, -1).every(Array.isArray)) {
        let idx = 0;
        do {
          if (idx === path.length) acc[key] = '';
          else if (idx === path.length - 1) acc[key] = path[idx];
          else acc[key] = get(src, path[idx]);
          idx++;
        } while (!acc[key]);
      } else {
        acc[key] = get(src, path);
      }
      return acc;
    },
    {} as Record<keyof T, any>
  );
};

export const buildOptions = (
  response: any,
  { option_filter, option_paths, result_path, value_id_path }: BuildOptionsArgs
) => {
  const src: any[] = result_path?.length
    ? get(response, result_path)
    : response;
  const filtered = option_filter
    ? src.filter(optionFilter(option_filter))
    : src;

  return filtered.reduce<[Option[], Map<any, any>]>(
    (acc, x: any) => {
      const data = option_paths ? getKeys(option_paths, x) : x;

      if (value_id_path) {
        const value = get(data, ['value', ...value_id_path]);
        acc[0].push({ ...data, value });
        acc[1].set(value, data.value);
      } else {
        acc[0].push(data);
        acc[1].set(data.value, data.value);
      }
      return acc;
    },
    [[], new Map()]
  );
};

export const getAbsoluteStringPath = (ref: AbsoluteString) => {
  return ref.substring(2).split('/');
};

export const getRelativeStringPath = (ref: RelativeString) => {
  return ref.substring(1).split('/');
};

export const mergeEntries = <T extends string = string, V = unknown>(
  ...sources: (Iterable<[T, V]> | undefined)[]
) => {
  return Object.entries(
    sources
      .filter(Array.isArray)
      .reduce(
        (acc, entries) => ({ ...acc, ...Object.fromEntries(entries) }),
        {}
      )
  ) as [T, V][];
};
