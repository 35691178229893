import {
  CSSProperties,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { applyRef, getKDSClasses, merge } from '../util';

type HorizontalScrollContainerProps = PropsWithChildren<{
  className?: string;
  hideScrollbar?: boolean;
  pointerEvents?: boolean;
  positionByContainerSize?: boolean;
  scrollable?: boolean;
  onScroll?: (ev: Event) => void;
  onScrollEnd?: (ev: Event) => void;
  onMouseLeave?: () => void;
}>;

type VerticalGradientContainerProps = HorizontalScrollContainerProps & {
  enableTopGradient?: boolean;
};

const isScrolledTop = (node: HTMLElement | null) => {
  if (!node) return true;
  if (node.scrollHeight <= node.clientHeight) return true;
  return node.scrollTop === 0;
};

const isScrolledBottom = (node: HTMLElement | null) => {
  if (!node) return true;
  if (node.scrollHeight <= node.clientHeight) return true;
  return node.scrollHeight - Math.ceil(node.scrollTop) <= node.clientHeight;
};

const isScrolledLeft = (node: HTMLElement | null) => {
  if (!node) return true;
  if (node.scrollWidth <= node.clientWidth) return true;
  return node.scrollLeft === 0;
};

const isScrolledRight = (node: HTMLElement | null) => {
  if (!node) return true;
  if (node.scrollWidth <= node.clientWidth) return true;
  return node.scrollWidth - Math.ceil(node.scrollLeft) <= node.clientWidth;
};

export const HorizontalGradientContainer = forwardRef<
  HTMLDivElement,
  HorizontalScrollContainerProps
>(
  (
    {
      children,
      className,
      onScroll,
      hideScrollbar = false,
      pointerEvents = true,
      positionByContainerSize = false,
      scrollable = true,
      ...rest
    },
    ref
  ) => {
    const innerRef = useRef<HTMLDivElement | null>(null);
    const [scrolledLeft, setScrolledLeft] = useState(true);
    const [scrolledRight, setScrolledRight] = useState(true);
    const [rightStyle, setRightStyle] = useState<CSSProperties>({
      right: '0',
    });
    const [scrollListener] = useState(() => {
      return (ev: Event) => {
        const target = ev.target as HTMLDivElement;
        setScrolledLeft(isScrolledLeft(target));
        setScrolledRight(isScrolledRight(target));
        onScroll?.(ev);
      };
    });

    const observer = useMemo(
      () =>
        new ResizeObserver((entries) => {
          if (entries[0]) {
            const target = entries[0].target as HTMLDivElement;
            setScrolledLeft(isScrolledLeft(target));
            setScrolledRight(isScrolledRight(target));

            if (positionByContainerSize && target.clientWidth > 0) {
              // TODO (alexlurvey): look into not needing this. The infinite scroll container requires
              // `position: relative` which makes `right: 0` not work (that needs position: static)
              setRightStyle({ left: `${target.clientWidth}px` });
            }
          }
        }),
      [positionByContainerSize]
    );

    const refCb = useCallback(
      (node: HTMLDivElement) => {
        if (!node && innerRef.current) {
          innerRef.current.removeEventListener('scroll', scrollListener);
          observer.disconnect();
        }

        applyRef(ref, node);
        innerRef.current = node;

        if (innerRef.current) {
          innerRef.current.addEventListener('scroll', scrollListener);
          observer.observe(innerRef.current);
        }
      },
      [ref, observer, scrollListener]
    );

    useEffect(() => {
      return () => {
        observer.disconnect();
      };
    }, [observer]);

    const classes = merge(
      getKDSClasses('gradient', 'horizontal'),
      'flex',
      'h-full',
      'overflow-y-hidden',
      'kds-expanding-scrollbar',
      scrollable && 'overflow-x-scroll',
      scrollable && hideScrollbar && 'kds-hide-scrollbars',
      !pointerEvents && 'pointer-events-none',
      className
    );

    return (
      <div ref={refCb} className={classes} {...rest}>
        {!scrolledLeft && (
          <div className="sticky z-10 left-0 pointer-events-none">
            <div className="absolute left-0 h-full w-[85px] bg-gradient-to-r from-background-white" />
          </div>
        )}
        {children}
        {!scrolledRight && (
          <div className="sticky z-10 pointer-events-none" style={rightStyle}>
            <div className="absolute right-0 h-full w-[85px] bg-gradient-to-l from-background-white" />
          </div>
        )}
      </div>
    );
  }
);

export const VerticalGradientContainer = forwardRef<
  HTMLDivElement,
  VerticalGradientContainerProps
>(
  (
    {
      children,
      className,
      onScroll,
      enableTopGradient = true,
      scrollable = true,
      hideScrollbar = false,
      ...rest
    },
    ref
  ) => {
    const innerRef = useRef<HTMLDivElement | null>(null);
    const [scrolledTop, setScrolledTop] = useState(true);
    const [scrolledBottom, setScrolledBottom] = useState(true);
    const [scrollListener] = useState(() => {
      return (ev: Event) => {
        const target = ev.target as HTMLDivElement;
        setScrolledTop(isScrolledTop(target));
        setScrolledBottom(isScrolledBottom(target));
        onScroll?.(ev);
      };
    });

    const observer = useMemo(
      () =>
        new ResizeObserver((entries) => {
          if (entries[0]) {
            const target = entries[0].target as HTMLDivElement;
            setScrolledTop(isScrolledTop(target));
            setScrolledBottom(isScrolledBottom(target));
          }
        }),
      []
    );

    const refCb = useCallback(
      (node: HTMLDivElement) => {
        if (!node && innerRef.current) {
          innerRef.current.removeEventListener('scroll', scrollListener);
          observer.disconnect();
        }

        applyRef(ref, node);
        innerRef.current = node;

        if (innerRef.current) {
          innerRef.current.addEventListener('scroll', scrollListener);
          observer.observe(innerRef.current);
        }
      },
      [ref, observer, scrollListener]
    );

    useEffect(() => {
      return () => {
        observer.disconnect();
      };
    }, [observer]);

    const classes = merge(
      getKDSClasses('gradient', 'vertical'),
      'flex',
      'flex-col',
      'w-full',
      'kds-expanding-scrollbar',
      scrollable && 'overflow-y-scroll',
      scrollable && hideScrollbar && 'kds-hide-scrollbars',
      className
    );

    return (
      <div ref={refCb} className={classes} {...rest}>
        {enableTopGradient && !scrolledTop && (
          <div className="sticky z-10 top-0 pointer-events-none">
            <div className="absolute top-0 w-full h-[85px] bg-gradient-to-b from-background-white" />
          </div>
        )}
        {children}
        {!scrolledBottom && (
          <div className="sticky z-10 bottom-0 pointer-events-none">
            <div className="absolute bottom-0 w-full h-[85px] bg-gradient-to-t from-background-white" />
          </div>
        )}
      </div>
    );
  }
);

HorizontalGradientContainer.displayName = 'HorizontalGradientContainer';
VerticalGradientContainer.displayName = 'VerticalGradientContainer';
