import { addDays, subDays, startOfMonth, startOfYear, setYear } from 'date-fns';
import { setTimingMargin } from './utils';

export default {
  today: {
    label: 'today',
    value: [
      setTimingMargin(new Date()).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
    customPlaceholderLabel: 'Today',
    localizedPlaceholderLabel: (t) => t('Today'),
  },
  yesterday: {
    label: 'yesterday',
    value: [
      setTimingMargin(addDays(new Date(), -1)).toString(),
      setTimingMargin(addDays(new Date(), -1), 'right').toString(),
    ],
    closeOverlay: false,
    customPlaceholderLabel: 'Yesterday',
    localizedPlaceholderLabel: (t) => t('Yesterday'),
  },
  thisMonth: {
    label: 'thisMonth',
    value: [
      setTimingMargin(startOfMonth(new Date())).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
    default: true,
    customPlaceholderLabel: 'This Month',
    localizedPlaceholderLabel: (t) => t('This Month'),
  },
  last7Days: {
    label: 'last7Days',
    value: [
      setTimingMargin(subDays(new Date(), 6)).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
  },
  last30Days: {
    label: 'last30Days',
    value: [
      setTimingMargin(subDays(new Date(), 29)).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
  },
  thisYear: {
    label: 'thisYear',
    value: [
      setTimingMargin(startOfYear(new Date())).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
    customPlaceholderLabel: 'This Year',
    localizedPlaceholderLabel: (t) => t('This Year'),
  },
  allTime: {
    label: 'allTime',
    value: [
      // hard coded to Jan 1, 2000... for now, potentially a prop to pass in

      setTimingMargin(setYear(startOfYear(new Date()), 2000)).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
    isAllTime: true,
    customPlaceholderLabel: 'All Time',
    localizedPlaceholderLabel: (t) => t('All Time'),
  },
};
