import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from '../../../app/colors';
import { gutters, scrollbarCss } from '../../../app/spacing';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import BaseInput from '../TextInput/BaseInput';
import BaseInputLayout from '../TextInput/BaseInputLayout';

const UnderlineTextarea = styled(BaseInput, {
  shouldForwardProp: (prop) => !['shrink', 'as'].includes(prop),
})`
  ${scrollbarCss}
  // Makes top spacing similar to the natural vertical flow of UnderlineTextInput
  margin-top: ${gutters.spacing(2, { baseline: true, lineHeight: 1.15 })}px;
  height: 82px;
  line-height: 1.15;
  resize: none;
  &::placeholder {
    opacity: 0;
    transition: opacity 10ms;
  }
  ${({ shrink }) =>
    shrink &&
    css`
      &::placeholder {
        opacity: 1;
      }
    `}
`;

export const UnderlineLayout = styled(BaseInputLayout, {
  shouldForwardProp: (prop) => !['disabled', 'as'].includes(prop),
})`
  border-left: none;
  border-right: none;
  border-top: none;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${grayScale.white};
      border-color: transparent;
      &:hover {
        border-color: transparent;
      }
    `}
`;

export default function UnderlineLongText(props) {
  const {
    className,
    value,
    label,
    placeholder,
    disabled,
    error,
    shrink: shrinkProp,
    onChange,
    onFocus,
    onBlur,
    ...others
  } = props;
  const [focused, setFocused] = useState(false);
  const shrink =
    typeof shrinkProp === 'boolean'
      ? shrinkProp
      : Boolean(value || focused || disabled || !label);
  return (
    <MaybeInputControl variant="underline" forInput {...props} shrink={shrink}>
      <UnderlineLayout
        className={!hasInputControl(props) && className}
        focused={focused}
        disabled={disabled}
        error={error}
      >
        <UnderlineTextarea
          as="textarea"
          value={disabled ? value || '—' : value}
          onChange={(ev) => {
            if (onChange) onChange(ev.target.value, ev);
          }}
          placeholder={shrink && placeholder}
          shrink={shrink}
          disabled={disabled}
          onFocus={(ev) => {
            setFocused(true);
            if (onFocus) onFocus(ev);
          }}
          onBlur={(ev) => {
            setFocused(false);
            if (onBlur) onBlur(ev);
          }}
          {...others}
        />
      </UnderlineLayout>
    </MaybeInputControl>
  );
}

UnderlineLongText.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  shrink: PropTypes.bool,
};

UnderlineLongText.defaultProps = {
  value: undefined,
  onChange: null,
  error: null,
  disabled: null,
  label: null,
  placeholder: null,
  onFocus: null,
  onBlur: null,
  shrink: null,
};
