import styled from '@emotion/styled';
import { borderRadii } from 'app/spacing';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { grayScale } from 'app/colors';
import BasicModal from 'components/Modals/presets/BasicModal';

export const StyledLinkWrapper = styled.div`
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
`;

export const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: calc(100vh - 244px);
  position: relative;
`;

export const TableWrapper = styled.div`
  width: 100%;
  flex: 1;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  overflow-y: hidden;
  flex-direction: column;
  position: relative;
  height: 100%;
  > div {
    position: relative;
    height: 100%;
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  flex: 0 0 45px;
  padding-top: 20px;
`;

export const StyledTableScrollContainer = styled(TableScrollContainer)`
  height: 100%;
  & .gradient-vertical-container {
    max-width: 100%;
    width: 100%;
  }
`;

export const StyledModal = styled(BasicModal)`
  && .modal-body {
    overflow: visible;
  }
`;
