import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import TextInput from 'components/Inputs/TextInput';
import Select from 'components/Inputs/Select';
import BasicModal from 'components/Modals/presets/BasicModal';
import Switch from 'components/Kizen/Switch';
import { useFlashValidation } from 'hooks/useFlashState';
import { useGetUrlError } from 'utility/validate';

const CUSTOM_URL = 'custom-url';
export const SCRIPT_EXECUTION = 'script-execution';

export const ScriptExecutionOption = (t) => {
  return {
    label: t('Execute Approved JavaScript'),
    value: SCRIPT_EXECUTION,
  };
};

const StyledBasicModal = styled(BasicModal)`
  .modal-body {
    overflow: unset;
  }
`;

const EmptySelect = styled(({ label, value, children, ...rest }) => (
  <TextInput
    inModal
    shrink
    variant="underline"
    label={label}
    value={value}
    {...rest}
  />
))`
  pointer-events: none;

  > div {
    border: none;
  }
`;

const InputWrapper = styled.div`
  margin-bottom: ${gutters.spacing(3)}px;
`;

const LinkActionWrapper = styled(InputWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${gutters.spacing(2)}px;
`;

const StyledSelect = styled(Select)`
  flex: 1;
`;

const StyledSwitch = styled(Switch)`
  margin-top: 0;
  margin-bottom: 0;
  padding-top: ${gutters.spacing(3)}px;
`;

export const ConfigureLinkModal = ({
  show,
  defaultOpenInNewTab = true,
  defaultLinkText = '',
  defaultLink = null,
  defaultScriptId = null,
  linkOptions = [],
  scriptExecutionOptions = [],
  onConfirm,
  onHide,
  allowRelativeLinks = false,
}) => {
  const { t } = useTranslation();
  const options = useMemo(
    () => [{ label: t('Custom URL'), value: CUSTOM_URL }].concat(linkOptions),
    [linkOptions, t]
  );
  const [linkAction, setLinkAction] = useState(() => {
    if (defaultScriptId) {
      return SCRIPT_EXECUTION;
    }

    return (
      (defaultLink &&
        linkOptions.find((o) => o.value === defaultLink)?.value) ??
      CUSTOM_URL
    );
  });
  const [openInNewTab, toggleOpenInNewTab] = useToggle(defaultOpenInNewTab);
  const [linkText, setLinkText] = useState(defaultLinkText);
  const [url, setUrl] = useState(defaultLink || '');
  const [scriptId, setScriptId] = useState(defaultScriptId);
  const [linkTextError, setLinkTextError] = useFlashValidation();
  const [urlError, setUrlError] = useFlashValidation();
  const [scriptError, setScriptError] = useState();
  const getUrlError = useGetUrlError(allowRelativeLinks);
  const hanldeLinkActionChange = useCallback(({ value }) => {
    setLinkAction(value);
    setUrl('');
  }, []);

  const checkLink = () => {
    if (!linkText.length) {
      setLinkTextError(t('This field is required.'));
      return true;
    }
    if (linkAction === SCRIPT_EXECUTION && scriptId === null) {
      setScriptError({
        showMessage: true,
        message: t('This field is required.'),
      });
      setTimeout(() => {
        setScriptError();
      }, 3000);
      return true;
    }
    if (linkAction === CUSTOM_URL) {
      if (!url.length) {
        setUrlError(t('This field is required.'));
        return true;
      } else {
        const urlError = getUrlError(url);
        if (urlError) {
          setUrlError(urlError);
          return true;
        }
      }
    }
    return false;
  };

  const handleConfirm = () => {
    const critical = checkLink();
    if (critical) {
      return;
    }
    const href = linkAction === CUSTOM_URL ? url : linkAction;
    onConfirm({
      action: linkAction,
      href,
      linkText,
      openInNewTab,
      scriptId,
    });
  };

  return (
    <StyledBasicModal
      show={show}
      enforceFocus={false}
      onConfirm={handleConfirm}
      onHide={onHide}
      heading={t('Configure Link')}
    >
      <LinkActionWrapper>
        {options.length > 1 && (
          <StyledSelect
            variant="underline"
            value={linkAction}
            label={t('Link Action')}
            options={options}
            onChange={hanldeLinkActionChange}
          />
        )}
        {options.length === 1 && (
          <EmptySelect label={t('Link Action')} value={t('Custom URL')} />
        )}
        {linkAction !== SCRIPT_EXECUTION && (
          <StyledSwitch
            textPlacement="left"
            label={t('Open link in new tab')}
            checked={openInNewTab}
            onChange={toggleOpenInNewTab}
          />
        )}
      </LinkActionWrapper>
      <InputWrapper>
        <TextInput
          inModal
          shrink
          variant="underline"
          value={linkText}
          label={t('Link Text')}
          placeholder={t('Enter text to display')}
          onChange={setLinkText}
          validate={linkTextError}
        />
      </InputWrapper>
      {linkAction === CUSTOM_URL && (
        <InputWrapper>
          <TextInput
            inModal
            shrink
            variant="underline"
            value={url}
            label={t('URL')}
            placeholder={t('Enter URL address')}
            onChange={setUrl}
            validate={urlError}
            onBlur={checkLink}
          />
        </InputWrapper>
      )}
      {linkAction === SCRIPT_EXECUTION && (
        <StyledSelect
          inModal
          variant="underline"
          label={t('JavaScript to Execute')}
          options={scriptExecutionOptions}
          value={scriptId}
          onChange={({ value }) => setScriptId(value)}
          validate={scriptError}
        />
      )}
    </StyledBasicModal>
  );
};
