import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { layers } from 'app/spacing';
import Button from 'components/Button';
import DragItem from 'components/DragAndDropLayout/DragItem';
import { ThreeDotDropdown } from 'components/Kizen/Dropdown';
import EditableText from 'components/Kizen/EditableText';

const DragItemContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const ThreeDotButton = ({ label, onClick }) => (
  <Button noSpace variant="text" onPointerDown={onClick}>
    <KizenTypography as="span" size="14px">
      {label}
    </KizenTypography>
  </Button>
);

export const DraggableItem = ({
  element,
  onNameChange,
  onDelete,
  onHide,
  ...rest
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState(element.label);
  const {
    value: { isDeletable, isDeleteable, isHideable },
  } = element;
  // There was a short window where there was a typo in the isDeletable property which was released.
  // We're checking both of these propteries so forms that were created within that window still work,
  // but this may not be necessary in the future.
  const canDelete = isDeletable || isDeleteable;

  return (
    <DragItem isHidden={element.hidden} {...rest}>
      <DragItemContent>
        <EditableText
          sizing="dense"
          value={name}
          onChange={setName}
          onBlur={() => onNameChange(element.id, name)}
        />
        {(isDeletable || isHideable) && (
          <ThreeDotDropdown
            autoClose
            closeOnSelect
            asOverlay
            zIndex={layers.modals(4)}
          >
            {isHideable && (
              <ThreeDotButton
                label={element.hidden ? t('Show') : t('Hide')}
                onClick={() => onHide(element)}
              />
            )}
            {canDelete && (
              <ThreeDotButton
                label={t('Delete')}
                onClick={() => onDelete(element)}
              />
            )}
          </ThreeDotDropdown>
        )}
      </DragItemContent>
    </DragItem>
  );
};
