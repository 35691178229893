import styled from '@emotion/styled';

export const StyledTwoColumnsRow = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  & > div {
    width: 100%;
  }
`;
