import React, { useCallback, useMemo, useRef, useState } from 'react';
import KizenTypography from 'app/kizentypo';
import { useNavBarState } from 'app/navBarController';
import Button from 'components/Button';
import Loader from 'components/Kizen/Loader';
import { SizedCell, SortableHeadCell, TRow } from 'components/Kizen/Table';
import BigTableLayout, {
  useBigTableLayout,
} from 'components/Layout/BigTableLayout';
import PageToolbar, {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import BigTable from 'components/Tables/Big';
import TablePagination from 'components/Tables/Big/TablePagination';
import { TOOLBAR_TEMPLATES } from 'queries/query-keys';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ToolbarService from 'services/ToolbarService';
import { useHistory } from 'react-router-dom';
import useModal from 'components/Modals/useModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import TemplateNameCell from './components/NameCell';
import useCreateNewTemplate from '../../hooks/useCreateNewTemplate';
import { maybeInjectPermanentItems } from '../../hooks/useRightColumn';
import ActionCell from './components/ActionCell';
import SharingStatsCell from './components/SharingStatsCell';
import { StyledCell } from './styles';
import OwnerCell from './components/OwnerCell';
import useToolbarAccessRequest from '../../hooks/useToolbarAccessRequest';
import useRespondToAccessRequest from '../../hooks/useRespondToAccessRequest';
import useToolbarAccessFiltering from '../../hooks/useToolbarAccessFiltering';
import { DEFAULT_TEMPLATE_ID } from 'components/Modals/ApplyTemplateModal';
import { SharingAccessModal } from 'components/Modals/SharingAccessModal';
import { AccessRequestModal } from 'components/Modals/AccessRequestModal';
import {
  EMPLOYEE_ACCESS,
  EMPLOYEE_ACCESS_DICTIONARY,
} from 'components/AccessRequests/utils';
import { getOrderingParam } from 'utility/SortingHelpers';

const paddingSize = {
  basic: '50px',
  small: '20px',
};
const columnSize = {
  spacer: '20px',
  name: '380px',
  owner: '280px',
  sharingSettings: '266px',
  yourAccess: '150px',
  actions: '47px',
};

// The exact height of any UI "chrome" above the standard builder
const TABLE_OFFSET = 168;

const ToolbarTemplates = () => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const history = useHistory();
  const templateActionId = useRef('');
  const queryClient = useQueryClient();
  const currentlyEditingTemplate = useRef(null);
  const temporaryOptimisticOwner = useRef(null);
  const [sort, setSort] = useState({
    column: 'name',
    direction: 'asc',
  });
  const [search, setSearch] = useState('');
  const [errors, setErrors] = useState();

  useRespondToAccessRequest();

  const listQueryKey = useMemo(
    () => [
      TOOLBAR_TEMPLATES.LIST,
      pageNumber,
      perPage,
      sort.column,
      sort.direction,
      search,
    ],
    [pageNumber, perPage, sort, search]
  );

  const {
    data: responseData,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    listQueryKey,
    () =>
      ToolbarService.listTemplates(
        pageNumber,
        perPage,
        getOrderingParam(sort),
        search,
        {
          skipErrorBoundary: true,
        }
      ),
    {
      keepPreviousData: true,
      onError: (e) => {
        if (e?.message?.includes('404')) {
          setPageNumber(1);
        }
      },
    }
  );
  const { height } = useNavBarState();

  const data = useMemo(() => {
    if (responseData && pageNumber === 1) {
      const res = { ...responseData };

      return {
        ...res,
        results: [
          {
            id: DEFAULT_TEMPLATE_ID,
            employee_access: 'View',
            name: t('Default Template'),
            owner: {
              display_name: t('Kizen Administrator'),
              static: true,
            },
          },
          ...res.results,
        ],
      };
    }

    return responseData;
  }, [responseData, t, pageNumber]);

  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout(height + TABLE_OFFSET);
  const { scrolledToTable } = bigTablePageProps;

  const deleteTemplateMutation = useMutation(
    (res) => ToolbarService.deleteTemplate(res),
    {
      onSuccess: refetch,
      onMutate: () => {
        templateActionId.current = '';
      },
    }
  );

  const filterToolbar = useToolbarAccessFiltering();
  const { modalProps, handleClickSaveTemplate } = useCreateNewTemplate(
    filterToolbar(maybeInjectPermanentItems([], false)),
    {
      onSuccess: (created) => {
        if (created.id) {
          history.push(`/account/toolbar/templates/edit/${created.id}`);
        }
      },
      onHide: () => {
        currentlyEditingTemplate.current = null;
      },
    }
  );

  const handleDuplicateTemplate = useCallback(
    async (id) => {
      const res = await ToolbarService.getTemplate(id);
      if (res) {
        currentlyEditingTemplate.current = {
          name: `${res.name} ${t('Copy')}`,
          sharing_settings: res.sharing_settings,
          configuration_json: res.configuration_json,
        };
        modalProps.showModal();
      }
    },
    [modalProps, t]
  );

  const onConfirmDelete = useCallback(() => {
    const id = templateActionId.current;
    deleteTemplateMutation.mutate(id);
  }, [deleteTemplateMutation]);

  const [confirmDeletionModalProps, , confirmDeletionModal] = useModal({
    handleSubmit: onConfirmDelete,
  });

  const handleClickEditSharingSettings = useCallback(
    (data) => {
      currentlyEditingTemplate.current = data;
      handleClickSaveTemplate();
    },
    [handleClickSaveTemplate]
  );

  const {
    modalProps: accessRequestModalProps,
    showAccessRequestModal,
    requestingAccessState,
  } = useToolbarAccessRequest();

  const handleActionMenu = useCallback(
    ({ value }, data) => {
      const { id } = data;

      if (value === 'edit') {
        history.push(`/account/toolbar/templates/edit/${id}`);
      } else if (value === 'delete') {
        templateActionId.current = id;
        confirmDeletionModal.show();
      } else if (value === 'request') {
        requestingAccessState.current = data;
        showAccessRequestModal();
      } else if (value === 'edit_sharing') {
        handleClickEditSharingSettings(data);
      } else if (value === 'duplicate') {
        handleDuplicateTemplate(id);
      } else if (value === 'apply') {
        history.push(`/account/toolbar?apply_template=${id}`);
      }
    },
    [
      history,
      confirmDeletionModal,
      handleClickEditSharingSettings,
      showAccessRequestModal,
      requestingAccessState,
      handleDuplicateTemplate,
    ]
  );

  const templateMutation = useMutation(
    (payload) =>
      ToolbarService.updateTemplate(
        payload.id,
        payload.name,
        payload.config,
        payload.sharing,
        payload.owner
      ),
    {
      onMutate: async (updated) => {
        await queryClient.cancelQueries(listQueryKey);
        setErrors();
        const previousData = queryClient.getQueryData(listQueryKey);

        queryClient.setQueryData(listQueryKey, (old) => {
          return {
            ...old,
            results: old.results.map((item) =>
              item.id === updated.id
                ? {
                    ...item,
                    ...updated,
                    owner: temporaryOptimisticOwner.current ?? item.owner,
                  }
                : item
            ),
          };
        });
        return { previousData, id: updated.id };
      },
      onError: (err, _updated, context) => {
        const fields = err?.response?.data;
        setErrors({ fields, id: context.id });

        queryClient.setQueryData(listQueryKey, context.previousData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(listQueryKey);
        temporaryOptimisticOwner.current = null;
      },
    }
  );

  const handleChangeName = useCallback(
    (value, id) => {
      templateMutation.mutate({ id, name: value });
    },
    [templateMutation]
  );

  const handleChangeOwner = useCallback(
    (owner, id) => {
      const { value, label } = owner;
      temporaryOptimisticOwner.current = { id: value, display_name: label };
      templateMutation.mutate({ id, owner: value });
    },
    [templateMutation]
  );

  const columns = useMemo(() => {
    return [
      {
        id: 'spacer-1',
        headCell: <SizedCell width={columnSize.spacer} />,
        cell: <SizedCell width={columnSize.spacer} />,
      },
      {
        id: 'name',
        headCell: (
          <SortableHeadCell
            label={t('Template Name')}
            width={columnSize.name}
            padding={paddingSize.basic}
          />
        ),
        cell: <SizedCell width={columnSize.name} padding={paddingSize.basic} />,
        format: (value, data) => (
          <TemplateNameCell
            value={value}
            data={data}
            onChange={handleChangeName}
            error={errors?.id === data.id ? errors.fields?.name : null}
          />
        ),
      },
      {
        id: 'owner',
        headCell: (
          <SortableHeadCell
            label={t('Creator/Owner')}
            width={columnSize.owner}
            padding={paddingSize.basic}
          />
        ),
        cell: (
          <SizedCell width={columnSize.owner} padding={paddingSize.basic} />
        ),
        format: (value, innerData) => {
          return (
            <OwnerCell
              value={value}
              data={innerData}
              items={data}
              onChange={(owner) => handleChangeOwner(owner, innerData.id)}
            />
          );
        },
      },
      {
        id: 'sharing_stats',
        headCell: (
          <SortableHeadCell
            label={t('Sharing Settings')}
            width={columnSize.sharingSettings}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell
            width={columnSize.sharingSettings}
            padding={paddingSize.basic}
          />
        ),
        format: (value, data) => (
          <SharingStatsCell
            value={value}
            data={data}
            onClickEdit={() => handleClickEditSharingSettings(data)}
          />
        ),
      },
      {
        id: 'employee_access',
        headCell: (
          <SortableHeadCell
            label={t('Your Access')}
            width={columnSize.yourAccess}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell
            width={columnSize.yourAccess}
            padding={paddingSize.basic}
          />
        ),
        format: (value) => (
          <KizenTypography>
            {EMPLOYEE_ACCESS_DICTIONARY[value]?.(t) ||
              EMPLOYEE_ACCESS_DICTIONARY[EMPLOYEE_ACCESS.NONE](t)}
          </KizenTypography>
        ),
      },
      {
        id: 'actions',
        headCell: (
          <SortableHeadCell
            label={t('Actions')}
            width={columnSize.actions}
            padding={paddingSize.small}
            hasSorting={false}
          />
        ),
        cell: <StyledCell padding={paddingSize.small} />,
        format: (_value, innerData) => {
          return (
            <ActionCell
              title={t('Edit Toolbar')}
              onChange={handleActionMenu}
              data={innerData}
              items={data}
              openMenuAbove
            />
          );
        },
      },
    ];
  }, [
    t,
    handleActionMenu,
    handleChangeName,
    handleClickEditSharingSettings,
    handleChangeOwner,
    errors,
    data,
  ]);

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => setSort({ column, direction }),
      },
    }),
    [sort]
  );

  const handleChangeSearchInput = useCallback((value) => {
    setPageNumber(1);
    setSearch(value);
  }, []);

  if (!data) {
    return <Loader loading />;
  }

  return (
    <>
      <BigTableLayout
        additionalNavHeight={TABLE_OFFSET} // The height of the sub-navigation
        toolbar={
          <PageToolbar>
            <PageToolbarSection>
              <PageSearchInput
                placeholder={t('Find Templates')}
                value={search}
                onChange={handleChangeSearchInput}
                loading={isLoading || isRefetching}
              />
            </PageToolbarSection>
            <PageToolbarTitle
              count={(data?.count ?? 0) + 1}
              single={t('Template')}
              plural={t('Templates')}
            />
            <PageToolbarSection>
              <Button onClick={modalProps.showModal}>
                {t('ADD TEMPLATE')}
              </Button>
            </PageToolbarSection>
          </PageToolbar>
        }
        pagination={
          <TablePagination
            totalCount={data?.count}
            page={pageNumber}
            perPage={perPage}
            onChangePerPage={setPerPage}
            onChangePage={setPageNumber}
          />
        }
        {...bigTablePageProps}
      >
        <BigTable
          scrolledToTable={scrolledToTable}
          columns={columns}
          head={<TRow head columns={columns} data={headData} />}
          staleData={isLoading || isRefetching}
        >
          {data?.results.map((result) => {
            return <TRow key={result.id} columns={columns} data={result} />;
          }) ?? []}
        </BigTable>
      </BigTableLayout>
      <ConfirmDeletionModal {...confirmDeletionModalProps}>
        <KizenTypography>
          {t('This will permanently delete the Template.')}
        </KizenTypography>
      </ConfirmDeletionModal>
      {modalProps.isOpen ? (
        <SharingAccessModal
          {...modalProps}
          existing={currentlyEditingTemplate.current}
          isEditing={Boolean(currentlyEditingTemplate.current?.id)}
          showPrivateToggle
        />
      ) : null}
      {accessRequestModalProps.isOpen ? (
        <AccessRequestModal
          template={requestingAccessState.current}
          {...accessRequestModalProps}
        />
      ) : null}
    </>
  );
};

export default ToolbarTemplates;
