import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Draggable from 'react-draggable';
import { gutters, layers } from 'app/spacing';
import whichServiceToUse from 'services/utils';

import Loader from 'components/Kizen/Loader';
import {
  HorizontalDropzone,
  VerticalDropzone,
} from 'components/DragAndDropLayout/Dropzone';
import {
  getItemCols,
  dropzoneIs,
  applyDropzone,
  hiddenItemsToBottom,
} from 'components/DragAndDropLayout/helpers';
import useModal from 'components/Modals/useModal';

import { getFieldDropzone, getCategoryDropzone } from './helpers';
import BuilderField, { fieldMargin, NewField, FieldWizard } from './Field';
import BuilderCategory, {
  categoryMargin,
  CategoryPlaceholder,
  NewCategory,
} from './Category';
import { useSelector } from 'react-redux';
import { getSettingsAccess } from 'store/authentication/selectors';
import { FieldPlaceholder } from './styles';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { flushSync } from 'react-dom';

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  ${({ width, cols }) => css`
    width: ${(100 * width) / cols}%;
  `}
  ${({ dragging, draggingFrom, optioning }) =>
    (dragging || draggingFrom || optioning) &&
    css`
      z-index: ${layers.content(0, 1)};
    `}
  ${({ dragging }) =>
    dragging &&
    css`
      pointer-events: none;
    `}
`;

export const CategoryLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${gutters.spacing(4) - categoryMargin}px;
`;

export const FieldLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${gutters.spacing(4) - fieldMargin}px;
`;

export const BuilderLoader = styled(Loader)`
  padding: ${gutters.spacing(20)}px 0 ${gutters.spacing(40)}px;
`;

export const findCategoryById = (categories, id) => {
  return categories.find((cat) => cat.id === id);
};

const findFieldInCategory = ({ fields }, fieldId) => {
  return fields.find((field) => field.id === fieldId);
};

export const categoryHasField = (...args) =>
  Boolean(findFieldInCategory(...args));

export const findCategoryByFieldId = (categories, fieldId) => {
  return categories.find((cat) => categoryHasField(cat, fieldId));
};

export const findFieldInCategories = (categories, fieldId) => {
  const category = findCategoryByFieldId(categories, fieldId);
  return category && findFieldInCategory(category, fieldId);
};

/**
 * @param {*} props.field - when provided will open the 'edit options' modal for that field
 *  be sure to only provide a field that can be edited (not default and deletable - see ./Field.js)
 */
function CustomFieldsBuilderNoMemo({
  model,
  updateCategories,
  rawCategories,
  loading,
  field,
  onOpenEditFieldOptions,
  onCloseEditFieldOptions,
  ...others
}) {
  const { t } = useTranslation();
  const [fieldDragging, setFieldDragging] = useState(null);
  const [fieldDropzone, setFieldDropzone] = useState(null); // { id, position: 'before' | 'after', direction: 'vertical' | 'horizontal' }
  const [categoryDragging, setCategoryDragging] = useState(null);
  const [categoryDropzone, setCategoryDropzone] = useState(null); // { id, position: 'before' | 'after' }
  const [wizardData, setWizardData] = useState({ model });
  const [categoryOptioning, setCategoryOptioning] = useState(null);
  const inFlightUpdates = useRef(0);

  const { customFields } = useSelector(getSettingsAccess);
  const canDeleteFields = customFields.customizeFields.remove;

  useEffect(() => {
    setWizardData({ model });
  }, [model]);

  // set which service is in use
  const serviceInUse = useMemo(() => whichServiceToUse(model), [model]);

  // This ensures that categories are only refreshed once when a series of in-flight updates are in progress.
  // This should allow optimistic UI updates to hold when many changes are made in parallel (and possibly completing out of order).
  const withFinalCategoriesUpdate = async (promise) => {
    try {
      inFlightUpdates.current += 1;
      return await promise;
    } finally {
      inFlightUpdates.current -= 1;
      if (inFlightUpdates.current === 0) {
        updateCategories();
      }
    }
  };

  // This is the category state we'll show. It may contain optimistic updates. It also helps
  // maintain some invariants not imposed by the API, like pushing hidden fields to the bottom
  // of the field lists.
  const [categories, setCategories] = useState(rawCategories);

  useEffect(() => {
    setCategories(
      rawCategories.map(({ fields, ...cat }) => ({
        ...cat,
        fields: hiddenItemsToBottom(fields, 'isHidden'),
      }))
    );
  }, [rawCategories]);

  const applyFieldDropzone = async () => {
    if (!fieldDragging || !fieldDropzone) {
      return;
    }

    const nextCategories = produce(categories, (draft) => {
      const fromCategory = findCategoryByFieldId(draft, fieldDragging.id);
      const toCategory = fieldDropzone.sectionId
        ? findCategoryById(draft, fieldDropzone.sectionId)
        : findCategoryByFieldId(draft, fieldDropzone.id);
      const [fromFields, toFields] = applyDropzone(
        fieldDragging,
        [fromCategory.fields, toCategory.fields],
        fieldDropzone
      );
      fromCategory.fields = hiddenItemsToBottom(fromFields, 'isHidden');
      toCategory.fields = hiddenItemsToBottom(toFields, 'isHidden');
    });

    // Optimistically display the drop
    setCategories(nextCategories);

    await withFinalCategoriesUpdate(
      serviceInUse.updateObjectStyles(model.id, nextCategories)
    );
  };

  const applyCategoryDropzone = async () => {
    if (!categoryDragging || !categoryDropzone) {
      return;
    }

    const [nextCategories] = applyDropzone(
      categoryDragging,
      [categories],
      categoryDropzone
    );

    // Optimistically display the drop
    setCategories(nextCategories);

    await withFinalCategoriesUpdate(
      serviceInUse.updateObjectStyles(model.id, nextCategories)
    );
  };

  const handleCategoryDelete = async ({ id }) => {
    try {
      await serviceInUse.deleteObjectCategory(wizardData.model.id, id);
    } finally {
      updateCategories();
    }
  };

  const handleChangeFieldDescription = async (
    displayName,
    { id, displayName: prev }
  ) => {
    if (displayName === prev) {
      return;
    }

    try {
      await serviceInUse.patchObjectField(wizardData.model.id, id, {
        displayName,
      });
    } finally {
      updateCategories();
    }
  };

  const [fieldWizardModalProps, , { setShow, show: showFieldWizardModal }] =
    useModal({
      handleSubmit: updateCategories,
      handleHide: onCloseEditFieldOptions,
    });

  const handleFieldEditOptions = useCallback(
    async (field) => {
      setWizardData({
        model,
        field,
        category: field.category,
      });
      showFieldWizardModal();
      onOpenEditFieldOptions?.(field);
    },
    [model, showFieldWizardModal, onOpenEditFieldOptions]
  );

  const handleFieldDelete = async ({ id }) => {
    try {
      await serviceInUse.deleteObjectField(wizardData.model.id, id);
    } finally {
      updateCategories();
    }
  };

  const handleFieldResize = async (cols, { id, meta: prev }) => {
    const meta = { ...prev, cols };

    // Optimisitically change field width
    setCategories(
      produce((draft) => {
        const f = findFieldInCategories(draft, id);
        if (f) {
          f.meta = meta;
        }
      })
    );

    await withFinalCategoriesUpdate(
      serviceInUse.patchObjectField(wizardData.model.id, id, {
        meta,
      })
    );
  };

  const fieldToHideRef = useRef(null);

  const [hideConfirmationModalProps, , { show: showHideConfirmation }] =
    useModal({
      handleSubmit: async () => {
        const field = fieldToHideRef.current;
        const nextCategories = produce(categories, (draft) => {
          const c = findCategoryByFieldId(draft, field.id);
          const f = findFieldInCategories(draft, field.id);
          if (f) {
            f.isHidden = true;
            c.fields = hiddenItemsToBottom(c.fields, 'isHidden');
          }
        });
        setCategories(nextCategories);
        await withFinalCategoriesUpdate(
          serviceInUse.updateObjectStyles(model.id, nextCategories)
        );
      },
    });

  const handleFieldSelectAction = async ({ value: action }, field) => {
    if (action === 'show') {
      const nextCategories = produce(categories, (draft) => {
        const c = findCategoryByFieldId(draft, field.id);
        const f = findFieldInCategories(draft, field.id);
        if (f) {
          f.isHidden = false;
          c.fields = hiddenItemsToBottom(c.fields, 'isHidden');
        }
      });

      // Optimistically show/hide the field
      setCategories(nextCategories);

      await withFinalCategoriesUpdate(
        serviceInUse.updateObjectStyles(model.id, nextCategories)
      );
    }
    if (action === 'hide') {
      fieldToHideRef.current = field;
      showHideConfirmation();
    } else if (action === 'edit-options') {
      handleFieldEditOptions(field);
    }
  };

  const handleChangeCategoryName = async (name, { id, name: prev }) => {
    if (name === prev) {
      return;
    }
    try {
      await serviceInUse.patchObjectCategory(model.id, id, { name });
    } finally {
      updateCategories();
    }
  };

  const handleCreateCategory = async ({ name }) => {
    try {
      await serviceInUse.createObjectCategory(model.id, {
        name: name || `Custom Category ${categories.length + 1}`,
      });
    } finally {
      updateCategories();
    }
  };

  const handleAddNewField = (category) => {
    setWizardData({
      model,
      category,
    });
    showFieldWizardModal();
  };

  useEffect(() => {
    // if field exist that means there is a field id present in the url
    // if not we assume the modal was open so the browser back/forward buttons
    // can close the modal when changing the route
    if (field) {
      setWizardData({
        model,
        field,
        category: field.category,
      });
      showFieldWizardModal();
    } else {
      setShow(false);
    }
  }, [field, model, showFieldWizardModal, handleFieldEditOptions, setShow]);

  if (loading && !categories.length) {
    return <BuilderLoader loading />;
  }

  return (
    <>
      <CategoryLayout
        onMouseMove={(ev) => {
          if (categoryDragging) {
            setCategoryDropzone(
              getCategoryDropzone(categoryDragging, categories, ev)
            );
          }
        }}
        {...others}
      >
        {categories.map((c) => {
          let x = 0;
          let endOfLine = true;
          return (
            <ItemWrapper
              key={c.id}
              className="CategoryItemWrapper"
              cols={3}
              width={1}
              dragging={categoryDragging && categoryDragging.id === c.id}
              optioning={categoryOptioning && categoryOptioning.id === c.id}
              draggingFrom={
                fieldDragging && categoryHasField(c, fieldDragging.id)
              }
            >
              {dropzoneIs(categoryDropzone, {
                id: c.id,
                position: 'before',
              }) && <VerticalDropzone margin={categoryMargin} />}
              <CategoryPlaceholder />
              <Draggable
                onStart={() => flushSync(() => setCategoryDragging(c))}
                onStop={() => {
                  flushSync(() => {
                    applyCategoryDropzone();
                    setCategoryDragging(null);
                    setCategoryDropzone(null);
                  });
                }}
                position={categoryDragging ? null : { x: 0, y: 0 }}
                handle=".CategoryHandle"
              >
                <BuilderCategory
                  category={c}
                  dragging={categoryDragging && categoryDragging.id === c.id}
                  handleProps={{ className: 'CategoryHandle' }}
                  onMouseMove={(ev) => {
                    if (fieldDragging) {
                      setFieldDropzone(getFieldDropzone(fieldDragging, c, ev));
                    }
                  }}
                  allowDeletion={categories.length > 1 && canDeleteFields}
                  onChangeName={handleChangeCategoryName}
                  onConfirmDelete={handleCategoryDelete}
                >
                  <FieldLayout>
                    <>
                      {dropzoneIs(fieldDropzone, {
                        sectionId: c.id,
                        position: 'first',
                      }) && <HorizontalDropzone margin={fieldMargin} />}
                    </>
                    {c.fields.map((f, i, arr) => {
                      const wasEndOfLine = endOfLine;
                      const cols = getItemCols(f);
                      const nextField = arr[i + 1];
                      const nextCols = nextField ? getItemCols(nextField) : 0;
                      endOfLine = x + cols >= 2 || x + cols + nextCols > 2;
                      x = endOfLine ? 0 : 1;
                      return (
                        <>
                          {wasEndOfLine &&
                            dropzoneIs(fieldDropzone, {
                              id: f.id,
                              position: 'before',
                              direction: 'horizontal',
                            }) && <HorizontalDropzone margin={fieldMargin} />}
                          <ItemWrapper
                            key={f.id}
                            className="FieldItemWrapper"
                            data-qa-field-id={f.id}
                            cols={2}
                            width={cols}
                            dragging={
                              fieldDragging && fieldDragging.id === f.id
                            }
                          >
                            {dropzoneIs(fieldDropzone, {
                              id: f.id,
                              position: 'before',
                              direction: 'vertical',
                            }) && <VerticalDropzone margin={fieldMargin} />}
                            <FieldPlaceholder />
                            <Draggable
                              disabled={f.isHidden}
                              onStart={(ev) => {
                                ev.preventDefault();
                                if (document.activeElement) {
                                  document.activeElement.blur();
                                }
                                flushSync(() => {
                                  setFieldDragging(f);
                                });
                              }}
                              onStop={() => {
                                flushSync(() => {
                                  applyFieldDropzone();
                                  setFieldDragging(null);
                                  setFieldDropzone(null);
                                });
                              }}
                              position={fieldDragging ? null : { x: 0, y: 0 }}
                              handle=".FieldHandle"
                            >
                              <BuilderField
                                field={f}
                                objectId={model.id}
                                handleProps={{ className: 'FieldHandle' }}
                                dragging={
                                  fieldDragging && fieldDragging.id === f.id
                                }
                                onChangeDescription={
                                  handleChangeFieldDescription
                                }
                                onConfirmDelete={handleFieldDelete}
                                onClickChangeColumnWidth={handleFieldResize}
                                onClickOptions={() => setCategoryOptioning(c)}
                                onCloseOptions={() => {
                                  if (
                                    categoryOptioning &&
                                    categoryOptioning.id === c.id
                                  ) {
                                    setCategoryOptioning(null);
                                  }
                                }}
                                onSelectAction={handleFieldSelectAction}
                                isDisabled={f.isDefault || !f.isDeletable}
                                canDeleteFields={canDeleteFields}
                              />
                            </Draggable>
                            {dropzoneIs(fieldDropzone, {
                              id: f.id,
                              position: 'after',
                              direction: 'vertical',
                            }) && <VerticalDropzone margin={fieldMargin} />}
                          </ItemWrapper>
                          {(endOfLine || i === arr.length - 1) &&
                            dropzoneIs(fieldDropzone, {
                              id: f.id,
                              position: 'after',
                              direction: 'horizontal',
                            }) && <HorizontalDropzone margin={fieldMargin} />}
                        </>
                      );
                    })}
                  </FieldLayout>
                  <NewField
                    onClick={() => handleAddNewField(c.id)}
                    label={`+ ${t('Add New Field')}`}
                  />
                </BuilderCategory>
              </Draggable>
              {dropzoneIs(categoryDropzone, {
                id: c.id,
                position: 'after',
              }) && <VerticalDropzone margin={categoryMargin} />}
            </ItemWrapper>
          );
        })}
        <ItemWrapper cols={3} width={1}>
          <NewCategory onConfirmCreate={handleCreateCategory} />
        </ItemWrapper>
      </CategoryLayout>
      <FieldWizard context={wizardData} {...fieldWizardModalProps} />
      <ConfirmationModal
        {...hideConfirmationModalProps}
        actionBtnColor="blue"
        heading={t('Confirm Field Hide')}
      >
        {t(
          'This field will not be shown in record layouts, tables, board view, or timeline field updates. It will remain visible in automations, filters, charts, and dashboards.'
        )}
      </ConfirmationModal>
    </>
  );
}

export default React.memo(CustomFieldsBuilderNoMemo);
