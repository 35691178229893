import React from 'react';
import {
  ElementContainerSettingsSection,
  ImageSettingsSection,
} from '../../settings';
import { useBuilderContext } from 'components/PageBuilder/BuilderContext';

export const EmailImageSettings = ({
  node,
  imageFilterId,
  onChangeImage,
  imageLibraryOpen = false,
  ...rest
}) => (
  <>
    <ImageSettingsSection
      node={node}
      imageFilterId={imageFilterId}
      imageLibraryOpen={imageLibraryOpen}
      onChangeImage={onChangeImage}
      supportPercentages
      {...rest}
    />
    <ElementContainerSettingsSection
      node={node}
      enableBackgroundImage={false}
      enableBorder={false}
      enableMargin={false}
    />
  </>
);

export const FormImageSettings = ({ node, imageLibraryOpen = false }) => {
  const { allowRelativeLinks } = useBuilderContext();

  return (
    <>
      <ImageSettingsSection
        key={node.id}
        node={node}
        imageLibraryOpen={imageLibraryOpen}
        supportPercentages
        allowRelativeLinks={allowRelativeLinks}
      />
      <ElementContainerSettingsSection node={node} />
    </>
  );
};
