import { useMemo } from 'react';
import {
  ButtonSettingsSection,
  ElementContainerSettingsSection,
} from '../../settings';
import {
  SubmitOption,
  GoToUrlOption,
  GoToPageOption,
  GoToPageAndSubmitOption,
  ScriptExecutionOption,
  LogActivityOption,
  StartAutomationOption,
} from '../../settings/ButtonSettings';
import { useBuilderContext } from 'components/PageBuilder/BuilderContext';
import { useActivityQuery } from 'queries/models/activites';
import { useAutomationQuery } from 'queries/models/automations';

export const EmailButtonSettings = ({ node }) => {
  return (
    <>
      <ButtonSettingsSection node={node} emailMode />
      <ElementContainerSettingsSection
        node={node}
        enableBackgroundImage={false}
        enableBorder={false}
        enableMargin={false}
      />
    </>
  );
};

export const FormButtonSettings = ({ node, isFormPage }) => {
  const {
    form,
    currentPage,
    allowRelativeLinks,
    enableScriptExecution,
    javascriptActions,
  } = useBuilderContext();
  const {
    data: { custom, props },
  } = node;
  const isSubmitButton = custom?.isSubmitButton || props?.isSubmitButton;

  const actionOptions = useMemo(() => {
    const options = [];

    if (isSubmitButton) {
      options.push(GoToPageOption, GoToPageAndSubmitOption, SubmitOption);
    } else if (isFormPage) {
      options.push(
        GoToUrlOption,
        GoToPageOption,
        GoToPageAndSubmitOption,
        SubmitOption
      );
    } else {
      options.push(GoToUrlOption);
    }

    if (enableScriptExecution && javascriptActions?.length) {
      options.push(ScriptExecutionOption);
    }

    return options;
  }, [isFormPage, isSubmitButton, enableScriptExecution, javascriptActions]);

  const pageOptions = useMemo(() => {
    return (
      form?.formUi?.pages?.reduce((acc, p, index) => {
        if (index !== currentPage && !p.hidden) {
          acc.push({ value: p.id, label: p.pageName });
        }
        return acc;
      }, []) ?? []
    );
  }, [form, currentPage]);

  return (
    <>
      <ButtonSettingsSection
        node={node}
        actionOptions={actionOptions}
        pageOptions={pageOptions}
        allowRelativeLinks={allowRelativeLinks}
      />
      <ElementContainerSettingsSection node={node} />
    </>
  );
};

export const HomepageButtonSettings = ({ node }) => {
  const { allowRelativeLinks, enableScriptExecution, javascriptActions } =
    useBuilderContext();

  const { activityId } = node.data.props;

  const { data: selectedActivity } = useActivityQuery(activityId, {
    enabled: Boolean(activityId),
    retry: 1,
    useErrorBoundary: false,
    select: (response) => {
      return {
        label: response.name,
        value: response.id,
      };
    },
  });

  const actionOptions = useMemo(() => {
    const options = [GoToUrlOption];

    if (enableScriptExecution && javascriptActions?.length) {
      options.push(ScriptExecutionOption);
    }

    options.push(LogActivityOption);

    return options;
  }, [enableScriptExecution, javascriptActions]);

  return (
    <>
      <ButtonSettingsSection
        node={node}
        actionOptions={actionOptions}
        allowRelativeLinks={allowRelativeLinks}
        selectedActivity={selectedActivity}
      />
      <ElementContainerSettingsSection node={node} />
    </>
  );
};

export const StaticBlockButtonSettings = ({ node }) => {
  const { allowRelativeLinks, enableScriptExecution, javascriptActions } =
    useBuilderContext();

  const { activityId, automationId } = node.data.props;

  const { data: selectedActivity } = useActivityQuery(activityId, {
    enabled: Boolean(activityId),
    retry: 1,
    useErrorBoundary: false,
    select: (response) => {
      return {
        label: response.name,
        value: response.id,
      };
    },
  });

  const { data: selectedAutomation } = useAutomationQuery(automationId, {
    enabled: Boolean(automationId),
    retry: 1,
    useErrorBoundary: false,
    select: (response) => {
      if (response.active) {
        return {
          label: response.name,
          value: response.id,
        };
      }
      return null;
    },
  });

  const actionOptions = useMemo(() => {
    const options = [GoToUrlOption];

    if (enableScriptExecution && javascriptActions?.length) {
      options.push(ScriptExecutionOption);
    }

    options.push(LogActivityOption);
    options.push(StartAutomationOption);

    return options;
  }, [enableScriptExecution, javascriptActions]);

  return (
    <>
      <ButtonSettingsSection
        node={node}
        actionOptions={actionOptions}
        allowRelativeLinks={allowRelativeLinks}
        selectedActivity={selectedActivity}
        selectedAutomation={selectedAutomation}
      />
      <ElementContainerSettingsSection node={node} />
    </>
  );
};
