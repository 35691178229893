import { useTranslation } from 'react-i18next';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';
import ValidatedField from 'components/Kizen/ValidatedField';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import ActionCell from 'components/Tables/ActionCell';

const NO_VALUE = '—';

const numberWithTruncation = (value) => {
  return (
    <TextEllipsisWithTooltip>
      {!Number.isNaN(value) ? value : NO_VALUE}
    </TextEllipsisWithTooltip>
  );
};

const ActivitiesActionCell = ({ data, ...others }) => {
  const { t } = useTranslation();
  return (
    <ActionCell
      menuType="absolute"
      title={t('Edit Activity')}
      data={data}
      options={Object.values(ACTIVITY_ACTIONS(t))}
      {...others}
    />
  );
};

export const ACTIVITY_ACTIONS = (t) => ({
  Edit: { value: 'edit', label: t('Edit Activity') },
  Preview: { value: 'preview', label: t('Preview Activity') },
  Export: { value: 'export', label: t('Export Responses') },
  Duplicate: { value: 'duplicate', label: t('Duplicate') },
  Delete: { value: 'delete', label: t('Delete') },
});

export const getColumns = ({ onUpdateField, onSelectAction, t }) => [
  {
    id: 'spacer',
    headCell: <SizedCell width="20px" />,
    cell: <SizedCell width="20px" />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        by="name"
        label={t('Activity Name')}
        width="400px"
        padding="200px"
      />
    ),
    cell: <SizedCell width="400px" padding="200px" />,
    format: (name, activity) => (
      <ValidatedField
        type="link"
        to={`/activities/${activity.id}`}
        value={name}
        onSubmit={(value) => {
          if (!value) {
            throw new Error('Activity names cannot be blank.');
          }
          if (activity.name === value) {
            return null;
          }
          return onUpdateField(activity, {
            name: value,
          });
        }}
      />
    ),
  },
  {
    id: 'nSubmissions',
    headCell: (
      <SortableHeadCell
        by="n_submissions"
        numeric
        label={t('Number of Submissions')}
        width="175px"
        padding="200px"
      />
    ),
    cell: <SizedCell width="175px" padding="200px" />,
    format: numberWithTruncation,
  },
  {
    id: 'created',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Date Created')}
        minWidth="150px"
        padding="60px"
      />
    ),
    cell: <SizedCell minWidth="150px" padding="60px" />,
    format: (created) => (
      <DateTimeInputInline readOnly value={created} showDateTooltip />
    ),
  },
  {
    id: 'actions',
    headCell: (
      <SubActionHeadCell minWidth="50px" padding="16px" label={t('Actions')} />
    ),
    cell: (
      <ActivitiesActionCell
        minWidth="50px"
        padding="16px"
        onSelectAction={onSelectAction}
      />
    ),
  },
];
