import { shallowEqual } from 'react-redux';

export const getColumns = (pageConfig) => {
  if (
    Object.keys(pageConfig).length === 0 ||
    !pageConfig.columnOrder ||
    !Array.isArray(pageConfig.columnOrder) ||
    pageConfig.columnOrder.length === 0
  ) {
    return null;
  }
  const { columnOrder, columns } = pageConfig;
  return columnOrder.map((id) => {
    return columns[id] || { id };
  });
};

export const shallowEqualList = (listA, listB) => {
  if (!Array.isArray(listA) || !Array.isArray(listB)) {
    return shallowEqual(listA, listB);
  }
  if (listA.length !== listB.length) {
    return false;
  }
  return listA.every((item, i) => shallowEqual(item, listB[i]));
};

export const getVisibleCustomFields = (fields, columns) => {
  const visibleColumns = columns.reduce((accumulator, { id, visible }) => {
    if (visible) {
      accumulator[id] = { visible };
    }
    return accumulator;
  }, {});
  return fields
    .filter(
      (field) =>
        visibleColumns[field.id] && (!field.isDefault || field.isDeletable)
    )
    .map((field) => field.id);
};
