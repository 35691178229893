import { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import LinkTextDisplay from './Display';

// TODO convert to use TextInputInline and TextControl

export default function LinkTextInline({
  value,
  field,
  object,
  onSubmit,
  ...others
}) {
  const [editing, setEditing] = useState(false);
  const [stagedValue, setStagedValue] = useState(value);
  useEffect(() => {
    setStagedValue(value);
  }, [value, editing]);
  return (
    <LinkTextDisplay
      field={field}
      object={object}
      editing={editing}
      onEditing={setEditing}
      value={editing ? stagedValue : value}
      onChange={setStagedValue}
      onSubmit={onSubmit}
      {...others}
    />
  );
}

LinkTextInline.propTypes = {
  value: PropTypes.string.isRequired,
};
