import { useEditor } from './editor';

const UNSPECIFIED = '__UNSPECIFIED__';
const findTextStyleMark = (mark) => mark.type?.name === 'textStyle';

const getAttribute = (doc, from, to, attribute) => {
  let value = UNSPECIFIED;
  let areEqual = true;

  doc.nodesBetween(from, to, (node) => {
    if (node.isText) {
      const textStyle = node.marks?.find(findTextStyleMark);
      const a = textStyle?.attrs?.[attribute];
      areEqual = value === UNSPECIFIED ? true : a === value;
      value = a;
    }
  });

  return areEqual && value !== UNSPECIFIED ? value : null;
};

const getAttributes = (doc, from, to, attributes) => {
  const values = Array(attributes.length).fill(UNSPECIFIED);
  const areEqual = Array(attributes.length).fill(true);

  doc.nodesBetween(from, to, (node) => {
    if (node.isText) {
      const textStyle = node.marks?.find(findTextStyleMark);
      for (let i = 0; i < attributes.length; i++) {
        const a = textStyle?.attrs?.[attributes[i]];
        areEqual[i] = values[i] === UNSPECIFIED ? true : a === values[i];
        values[i] = a;
      }
    }
  });

  return attributes.map((_, i) =>
    areEqual[i] && values[i] !== UNSPECIFIED ? values[i] : null
  );
};

/**
 * Gets the value of an attribute for the current node's textStyle mark.
 *
 * @remarks the editor can have one or multiple nodes selected. `editor.state.selection.empty`
 * signifies only one node is selected. In that case we use the the marks of the `from` position.
 * When there are multiple nodes selected (meaning the textStyle attributes may not be the same
 * for all nodes) we check if all text nodes have the same attribute value applied and return null
 * when that is not the case.
 * @param {*} attr - (string | string[]) the textStyle mark's attribute name
 * @returns
 */
export const useTextStyleValue = (attr) => {
  const multiple = Array.isArray(attr);
  const editor = useEditor();
  if (!editor) return multiple ? Array(attr.length).fill(null) : null;

  const {
    state: {
      doc,
      selection: { empty, $from, from, to },
      storedMarks,
    },
  } = editor;

  if (empty) {
    const editorStyle = storedMarks?.find(findTextStyleMark);
    const textStyle = $from.marks()?.find(findTextStyleMark);
    return multiple
      ? attr.map(
          (a) => textStyle?.attrs?.[a] || editorStyle?.attrs?.[a] || null
        )
      : textStyle?.attrs?.[attr] || editorStyle?.attrs?.[attr] || null;
  }

  return multiple
    ? getAttributes(doc, from, to, attr)
    : getAttribute(doc, from, to, attr);
};
