import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import * as customizeComponents from './customize';
import { groupableOptionProp, selectedValueProp } from '../props';
import IconAdornment from '../Adornments/IconAdornment';

export function multiSelectSortValue(value) {
  if (!value) {
    return value;
  }
  return [...value].sort((a, b) => String(a.label).localeCompare(b.label));
}

export function useMultiSelectValue({ value, options }) {
  return useMemo(() => {
    if (!value || !options) {
      return value;
    }
    return multiSelectSortValue(
      value
        .map((val) =>
          typeof val === 'string'
            ? options.find((opt) => opt.value === val)
            : val
        )
        .filter(Boolean)
    );
  }, [value, options]);
}

const MultiSelect = React.forwardRef(
  (
    {
      value: valueProp,
      onChange,
      options,
      endAdornment,
      components: componentsProp,
      ...props
    },
    ref
  ) => {
    const value = useMultiSelectValue({ value: valueProp, options });
    const handleChange =
      onChange &&
      ((val, ...args) => {
        return onChange(multiSelectSortValue(val), ...args);
      });
    return (
      <Select
        ref={ref}
        isMulti
        value={value}
        onChange={handleChange}
        options={options}
        components={{ ...customizeComponents, ...componentsProp }}
        closeMenuOnSelect={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        endAdornment={endAdornment || <IconAdornment icon="search" />}
        {...props}
      />
    );
  }
);

MultiSelect.displayName = 'MultiSelect';

// Also all props from Select
MultiSelect.propTypes = {
  value: PropTypes.arrayOf(selectedValueProp),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(groupableOptionProp),
  components: PropTypes.object,
  PillComponent: PropTypes.elementType,
  endAdornment: PropTypes.elementType,
};

MultiSelect.defaultProps = {
  value: [],
  onChange: null,
  options: [],
  components: null,
  PillComponent: null,
  endAdornment: null,
};

export default MultiSelect;
