import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from 'app/colors';
import { borderRadii, gutters } from 'app/spacing';
import { truncateCss } from 'app/typography';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import Icon from '../Icon';
import { Dot } from 'components/Fields/ColorLabel';

const StyledPill = styled.span`
  display: flex;
  padding: calc(7px - 1px) 7px calc(7px - 3px) 7px;
  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${colorsButton.red.default};
          border-left: 3px solid ${colorsButton.red.hover};
        `
      : css`
          border: 1px solid ${grayScale.medium};
          border-left: 3px solid ${colorsButton.blue.default};
        `}
  border-radius: ${borderRadii.small};
  // Note we rely on this spacing for MultiSelect. Feel free to
  // customize elsewhere using styled() for other purposes, though.
  margin-left: ${gutters.spacing(2)}px;
  margin-bottom: ${gutters.spacing(1)}px;
  background-color: ${grayScale.white};
  ${(props) =>
    props.maxWidth ? `max-width: ${props.maxWidth};` : 'max-width: 100%;'}
  ${truncateCss}
  .selected-value-text {
    display: inline-block;
    ${truncateCss}
  }
  .selected-value-remove {
    cursor: pointer;
    color: ${grayScale.mediumDark};
    margin-bottom: 1px;
    font-size: 95%;
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${grayScale.light};
        pointer-events: none;
      `};
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: ${gutters.spacing(1)}px;
  // styles for storybook for not to import font-awesome css
  & svg {
    font-size: 1.25em;
    height: 1em;
  }
`;

const DotWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 13px;
  && > * {
    margin: 0;
  }
`;

const Pill = React.forwardRef(function Pill(
  {
    id,
    name,
    maxWidth,
    hideRemoveIcon,
    onRemove,
    disabled,
    PillComponent,
    colorIndicator,
    ...others
  },
  ref
) {
  const handleClick = useCallback(
    (ev) => {
      if (!disabled) {
        onRemove(id, ev);
      }
    },
    [disabled, id, onRemove]
  );

  return (
    <StyledPill ref={ref} maxWidth={maxWidth} {...others}>
      {!hideRemoveIcon && (
        <StyledIcon
          icon="close"
          size="lg"
          className="selected-value-remove"
          // There is an issue with react-select where onClick is prevented
          // from firing on touch devices, so onTouchEnd is used instead
          // https://github.com/JedWatson/react-select/issues/4000
          onTouchEnd={handleClick}
          onClick={handleClick}
        />
      )}
      {PillComponent && (
        <PillComponent as="span" className="selected-value-text" value={id}>
          {name}
        </PillComponent>
      )}
      {colorIndicator && (
        <DotWrapper>
          <Dot color={colorIndicator} />
        </DotWrapper>
      )}
      {!PillComponent && (
        <TextEllipsisWithTooltip as="span" className="selected-value-text">
          {name}
        </TextEllipsisWithTooltip>
      )}
    </StyledPill>
  );
});

Pill.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hideRemoveIcon: PropTypes.bool,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  maxWidth: PropTypes.string,
  PillComponent: PropTypes.elementType,
};

Pill.defaultProps = {
  hideRemoveIcon: true,
  onRemove: () => {},
  disabled: false,
  maxWidth: '100%',
  PillComponent: null,
};

export default Pill;
