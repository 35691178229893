import React from 'react';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { grayScale, colorsPrimary } from 'app/colors';
import { PreReleaseFeature } from 'hooks/usePreReleaseFeatures';
import { useTranslation } from 'react-i18next';
import { layers, gutters } from 'app/spacing';

const TestingModeWrapper = styled.div`
  position: fixed;
  bottom: ${({ zendeskLoaded }) => (zendeskLoaded ? '70' : '10')}px;
  right: 10px;
  /* to be below ScrollTopButton (1039) and modal backdrop (1040)  */
  z-index: ${layers.toast};
  padding: ${gutters.spacing(1)}px 0;
  background: ${grayScale.white};
  border: 1px solid ${grayScale.medium};
  border-radius: 4px;

  && p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 19px;
    font-size: 12px;
    line-height: ${gutters.spacing(3, -1)}px;
    color: ${colorsPrimary.blue.dark};
  }
`;

export const TestingMode = () => {
  const { t } = useTranslation();

  return (
    <PreReleaseFeature>
      <TestingModeWrapper data-qa="testing-mode">
        <KizenTypography>{t('Testing Mode')}</KizenTypography>
      </TestingModeWrapper>
    </PreReleaseFeature>
  );
};
