import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { gutters } from 'app/spacing';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import ChartTypeField from '../../fields/chartType';
import TitleField from '../../fields/title';
import DatapointFrequencyField from '../../fields/datapointFrequency';
import { StyledWizardField } from 'components/Wizards/shared/styles';

const BASIC_REPORT_TYPES = [
  REPORT_TYPES.EMAILS_SENT,
  REPORT_TYPES.DELIVERY_PERCENT,
  REPORT_TYPES.COMPLAINT_PERCENT,
  REPORT_TYPES.OPT_OUT_PERCENT,
];

const EmailBasicStatisticsReportType = ({
  defaultName,
  handleSetName,
  mobile,
  active,
}) => {
  const { state, setState, entityName } = useContext(
    WizardStateContext.Context
  );
  const { name, reportType, display, isTrendDisplay, datapointFrequency } =
    state;

  const onChangeDatapointFrequency = useCallback(
    (value) => {
      return setState('datapointFrequency', value);
    },
    [setState]
  );

  // Hide self if one of the basic report types
  if (!BASIC_REPORT_TYPES.includes(reportType?.value)) {
    return null;
  }

  return (
    <>
      <ChartTypeField mobile={mobile} top={gutters.spacing(2, 2)} />
      {isTrendDisplay ? (
        <>
          <DatapointFrequencyField
            value={datapointFrequency}
            onChange={onChangeDatapointFrequency}
            mobile={mobile}
            active={active && isTrendDisplay}
          />
          {/* spacer for next step special case */}
          <StyledWizardField top={gutters.spacing(4)} />
        </>
      ) : null}
      {display ? (
        <TitleField
          defaultName={defaultName}
          name={name}
          onChangeName={handleSetName}
          top={gutters.spacing(2)}
          entityName={entityName}
        />
      ) : null}
    </>
  );
};

EmailBasicStatisticsReportType.propTypes = {
  defaultName: PropTypes.string,
  handleSetName: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
};

EmailBasicStatisticsReportType.defaultProps = {
  defaultName: '',
  mobile: false,
};

export default EmailBasicStatisticsReportType;
