import React from 'react';

export default function StageIcon(props) {
  return (
    /* eslint-disable */
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6562 0H2.34375C1.04932 0 0 1.04932 0 2.34375V19.5312C0 20.8257 1.04932 21.875 2.34375 21.875H22.6562C23.9507 21.875 25 20.8257 25 19.5312V2.34375C25 1.04932 23.9507 0 22.6562 0ZM11.7188 20.3125H2.34375C1.91226 20.3125 1.5625 19.9627 1.5625 19.5312V3.125H11.7188V20.3125ZM23.4375 19.5312C23.4375 19.9627 23.0877 20.3125 22.6562 20.3125H13.2812V3.125H23.4375V19.5312Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3312 8.09416L14.1418 8.28353C14.0163 8.40905 14.0163 8.61257 14.1418 8.73811L16.3898 10.9732H6.32143C6.14392 10.9732 6 11.1171 6 11.2947V11.5625C6 11.74 6.14392 11.8839 6.32143 11.8839H16.3898L14.1418 14.119C14.0163 14.2446 14.0163 14.4481 14.1418 14.5736L14.3312 14.763C14.4567 14.8885 14.6602 14.8885 14.7857 14.763L17.9059 11.6559C18.0314 11.5303 18.0314 11.3268 17.9059 11.2013L14.7857 8.09416C14.6602 7.96861 14.4567 7.96861 14.3312 8.09416Z"
        fill="currentColor"
      />
    </svg>
    /* eslint-enable */
  );
}
