export const APPLY_TEMPLATES_TYPES = {
  roles: 'roles',
  teamMembers: 'teamMembers',
  permissionGroups: 'permissionGroups',
};

export const TEMPLATES_TYPES = {
  toolbars: 'toolbars',
  columns: 'columns',
  quickFilters: 'quickFilters',
};
