import { colorsButton } from 'app/colors';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import { getItemText, getTooltipText } from './helpers';
import { StyledPlaceholder } from './styles';

export const SummarizedPlaceholder = ({
  count = 0,
  addValues = [],
  removeValues = [],
  showVisibleIcon,
  field,
  isFullText = false,
}) => {
  const { t } = useTranslation();
  const total = count + addValues.length - removeValues.length;
  return (
    <>
      <StyledPlaceholder>
        <TextEllipsisWithTooltip color={colorsButton.blue.default}>
          {`${total} ${getItemText(field, total, t)}${
            isFullText ? ` ${t('Selected')}` : ''
          }`}
        </TextEllipsisWithTooltip>
      </StyledPlaceholder>
      {showVisibleIcon ? (
        <div>
          <IconWithTooltip
            icon="visible"
            iconSize="12px"
            label={getTooltipText(field, t)}
          />
        </div>
      ) : null}
    </>
  );
};
