/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Col } from 'react-bootstrap';
import Divider from 'components/Layout/Divider';
import { getChosenBusiness } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';
import { webApplicationTrackerEmbed } from 'utility/embed';
import { SubSectionWithHeader as SubSection } from 'components/Layout/SubSection';
import Notice from 'components/Kizen/Notice';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import {
  Sections,
  StyledRow as Row,
  StyledTextArea,
  StyledList,
} from './styles';

const webApplicationTrackingScript = `<script>
  KIZEN("interaction", "Pressed Play", {
    video: "Feature Overview",
    email: "contact@example.com"
  });
</script>`;

const TrackingScripts = () => {
  const business = useSelector(getChosenBusiness);

  const websiteTrackingScript = webApplicationTrackerEmbed(business);

  const { t } = useTranslation();

  return (
    <Sections>
      <SubSection title={t('Website Tracking')}>
        <Row>
          <Col>
            <Notice>
              <KizenTypography>
                {t(
                  'Place the following script on your website and you will be able to view your contacts’ website impressions. In order for us to assign tracking, they will need to perform one of the following actions'
                )}
                :
              </KizenTypography>
              <StyledList>
                <li>
                  <KizenTypography>
                    {t('Click a link in an email you send through Kizen')}
                  </KizenTypography>
                </li>
                <li>
                  <KizenTypography>
                    {t('Submit a form or survey')}
                  </KizenTypography>
                </li>
                <li>
                  <KizenTypography>
                    {t('Register for an event')}
                  </KizenTypography>
                </li>
                <li>
                  <KizenTypography>
                    {t(
                      'Create an account or purchase a product through your store'
                    )}
                  </KizenTypography>
                </li>
              </StyledList>
            </Notice>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledTextArea value={websiteTrackingScript} readOnly />
          </Col>
        </Row>
      </SubSection>
      <Divider />
      <SubSection title={t('Web Application Tracking')}>
        <Row>
          <Col>
            <Notice>
              <KizenTypography>
                {t(
                  "If you have a web application and you know the email address of your users then you can use the following code to automatically populate your contact database and view your contact's visits."
                )}
              </KizenTypography>
              <KizenTypography>
                {t(
                  "Simply replace the email, first_name and last_name (optional) values below with your user's values. You should always URI encode your parameter values just in case there are any characters such as a space that would require encoding."
                )}
              </KizenTypography>
            </Notice>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledTextArea value={webApplicationTrackingScript} readOnly />
          </Col>
        </Row>
      </SubSection>
    </Sections>
  );
};

export default TrackingScripts;
