import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';
import ShortTextViewDisplay from 'components/Inputs/inline/LinkText/ViewDisplay';
import { canAdminTemplate } from 'pages/Account/pages/Toolbar/utils';
import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { getTranslatedAccessStats } from 'components/AccessRequests/utils';

const SharingStatsCell = ({ data, onClickEdit }) => {
  const { t } = useTranslation();
  const editable = canAdminTemplate(data);

  const result = getTranslatedAccessStats(data, t);

  return (
    <Wrapper>
      {editable ? (
        <ShortTextViewDisplay value={result} onClickEditButton={onClickEdit} />
      ) : (
        <TextEllipsisWithTooltip>
          {result || emptyLongDash}
        </TextEllipsisWithTooltip>
      )}
    </Wrapper>
  );
};

export default SharingStatsCell;
