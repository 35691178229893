import { RefObject, useEffect, useLayoutEffect, useState } from 'react';

export const useElementSize = (
  elementRef?: RefObject<HTMLElement>,
  key?: string
) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    scrollWidth: 0,
    x: 0,
    y: 0,
  });

  useLayoutEffect(() => {
    if (elementRef?.current) {
      const { width, height, x, y } =
        elementRef.current.getBoundingClientRect();
      setDimensions({
        width,
        height,
        scrollWidth: elementRef.current.scrollWidth,
        x,
        y,
      });
    }
  }, [elementRef]);

  useEffect(() => {
    if (!elementRef?.current) {
      return;
    }
    const observer = new ResizeObserver((entries) => {
      const rect = entries[0].target.getBoundingClientRect();
      setDimensions({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
        scrollWidth: entries[0].target.scrollWidth,
        x: rect.x,
        y: rect.y,
      });
    });

    observer.observe(elementRef.current);
    return () => {
      observer.disconnect();
    };
  }, [elementRef, key]);

  return dimensions;
};
