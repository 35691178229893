import { NO_VALUE } from './constants';
import { Filter } from './filter';
import { StepData } from './types';

type TFunction = (key: string) => string;

export type FilterSetErrors = ReturnType<typeof defFilterSetErrors>;
export type EmailValidationFn = (v: string, t: TFunction) => string | null;

const isEmptyValue = (value: any) => {
  return value === NO_VALUE || value === undefined || value === '';
};

const coerceNumber = (value: any) => {
  return isEmptyValue(value) || value === null ? NaN : Number(value);
};

/**
 * The `not_found` property is specified in the backend metadata
 */
export const isNotFoundObject = (value: any) => {
  return (
    value !== null && typeof value === 'object' && value.not_found === true
  );
};

/**
 * The `not_allowed` property is specified in the backend metadata
 */
export const isNotAllowedObject = (value: any) => {
  return (
    value !== null && typeof value === 'object' && value.not_allowed === true
  );
};

export const validateStep = (
  step: string,
  data: StepData,
  isValidPhoneNumber: (v: string) => boolean,
  isValidEmail: EmailValidationFn,
  t: TFunction
) => {
  const { input_type, value } = data;

  switch (input_type) {
    case 'phonenumber':
      if (!value) return { [step]: t('Required field') };
      if (!isValidPhoneNumber(value))
        return { [step]: t('Please enter a valid phone number') };
      break;
    case 'email': {
      if (!value) {
        return { [step]: t('Required field') };
      }

      const emailError = isValidEmail(value, t);
      if (emailError) {
        return { [step]: emailError };
      }
      break;
    }
    case 'date_range_inclusive': {
      if (
        isEmptyValue(value) ||
        (isEmptyValue(value[0]) && isEmptyValue(value[1]))
      ) {
        return { [step]: t('Required field') };
      }

      // wrapping in String because new Date(null) is a valid date
      const d1 =
        value[0] instanceof Date ? value[0] : new Date(String(value[0]));
      const d2 =
        value[1] instanceof Date ? value[1] : new Date(String(value[1]));

      const t1 = d1.getTime();
      const t2 = d2.getTime();
      const startIsValid = !isNaN(t1);
      const endIsValid = !isNaN(t2);

      if (!startIsValid) {
        return { [step]: t('Invalid start date.') };
      }

      if (!endIsValid) {
        return { [step]: t('Invalid end date.') };
      }

      if (startIsValid && endIsValid && t1 > t2) {
        return {
          [step]: t('End date must come after start date.'),
        };
      }

      break;
    }
    case 'decimal_range_inclusive':
    case 'integer_range_inclusive':
    case 'money_range_inclusive': {
      const lower = coerceNumber(value?.[0]);
      const upper = coerceNumber(value?.[1]);
      const lowerIsNumber = !isNaN(lower);
      const upperIsNumber = !isNaN(upper);

      if (isEmptyValue(value) || (!lowerIsNumber && !upperIsNumber)) {
        return { [step]: t('Required field') };
      }
      if (lowerIsNumber && upperIsNumber && value[0] > value[1]) {
        return {
          [step]: t('The minimum value must be less than the maximum.'),
        };
      }
      break;
    }
    case 'integer':
      if (isEmptyValue(value) || isNaN(parseInt(value))) {
        return { [step]: t('Required field') };
      }
      break;
    case 'decimal':
      if (isEmptyValue(value) || isNaN(parseFloat(value))) {
        return { [step]: t('Required field') };
      }
      break;
    default:
      if (isEmptyValue(value) || (Array.isArray(value) && value.length === 0)) {
        return { [step]: t('Required field') };
      }
      if (isNotFoundObject(value) || isNotAllowedObject(value)) {
        return { [step]: t('Required field') };
      }
      if (Array.isArray(value)) {
        if (value.every((x) => isNotFoundObject(x) || isNotAllowedObject(x))) {
          return { [step]: t('Required field') };
        }

        const optionInError = value.find(
          (x) => isNotAllowedObject(x) || isNotFoundObject(x)
        );

        if (optionInError) {
          return {
            [step]: optionInError.error_message || t('Required field'),
          };
        }
      }

      if (value?.error) {
        return { [step]: value.error_message ?? t('Required field') };
      }
  }
  return null;
};

export const defFilterSetErrors = (
  init: (Record<string, string> | null)[] = []
): {
  readonly hasErrors: boolean;
  errors: (Record<string, string> | null)[];
} => {
  return {
    errors: init,
    get hasErrors() {
      return this.errors.some((x) => x !== null);
    },
  };
};

export const getError = (
  steps: Iterable<[string, StepData]>,
  isValidPhoneNumber: (v: string) => boolean,
  isValidEmail: EmailValidationFn,
  t: TFunction
) => {
  for (const [step, data] of steps) {
    const error = validateStep(step, data, isValidPhoneNumber, isValidEmail, t);
    if (error) {
      return error;
    }
  }

  return null;
};

export const getFilterSetErrors = (
  filters: Iterable<[string, StepData]>[],
  isValidPhoneNumber: (v: string) => boolean,
  isValidEmail: EmailValidationFn,
  t: TFunction,
  { hasFilterTypeStep = false } = {}
) => {
  if (!hasFilterTypeStep) {
    return defFilterSetErrors(
      filters.map((x) => getError([...x], isValidPhoneNumber, isValidEmail, t))
    );
  }

  return defFilterSetErrors(
    filters.map((x) => {
      if (!(x instanceof Filter)) {
        return {
          'filter-type': t('Please finish setting up your filter.'),
        };
      } else {
        const filter = [...x];
        return getError(filter, isValidPhoneNumber, isValidEmail, t);
      }
    })
  );
};
