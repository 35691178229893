import { FC } from 'react';
import { Cell, KeyedCell, PivotTableCellProps } from './types';
import { randomID } from '../util';

export const clamp = (x: number, a: number, b: number = Infinity) => {
  return x < a ? a : x > b ? b : x;
};

export function createKeyedCell<T extends PivotTableCellProps>(
  cell: FC<T>
): KeyedCell;
export function createKeyedCell<T extends Cell>(
  key: string,
  cell: FC<T>
): KeyedCell;
export function createKeyedCell<T extends Cell>(
  ...args: [FC<T>] | [string, FC<T>]
): KeyedCell {
  const key = args.length === 2 ? args[0] : randomID();
  const cell = (args.length === 2 ? args[1] : args[0]) as unknown as KeyedCell;
  cell.key = key;
  return cell;
}
