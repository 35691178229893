import styled from '@emotion/styled';

import { breakpoints, gutters, layers } from 'app/spacing';
import { ToolbarWrapper } from 'pages/Common/styles/ControlBar';

export const StyledToolbarWrapper = styled(ToolbarWrapper)`
  z-index: ${layers.content(0, 4)};
  position: relative;

  @media (min-width: ${breakpoints.md + 1}px) {
    padding: ${gutters.standard} ${gutters.standard} 0;
  }
`;
