import { forwardRef } from 'react';
import Icon from 'components/Kizen/Icon';
import { DEFAULT_ITEM_IDS } from 'pages/Account/pages/Toolbar/constants';
import { Child, ChildrenContainer, IconContainer } from './styles';
import { NavLink } from 'react-router-dom';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { grayScale } from 'app/colors';
import { getLabel, getQAForItem, getRoute } from './util';

const NavigationChildren = forwardRef(
  (
    {
      item,
      mobile,
      chosenBusinessName,
      readOnly,
      onClickLogout,
      translationKeys,
      offset,
      shouldShrinkToLeft,
    },
    forwardedRef
  ) => {
    return (
      <ChildrenContainer
        collapse={item.children.every((c) => c.widthPercent !== 50)}
        ref={forwardedRef}
        mobile={mobile}
        offset={offset}
        shouldShrinkToLeft={shouldShrinkToLeft}
      >
        {item.id === DEFAULT_ITEM_IDS.MY_ACCOUNT ? (
          <TextEllipsisWithTooltip
            color={grayScale.dark}
            type="small"
            as="span"
            weight="bold"
            style={{
              lineHeight: '100%',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {chosenBusinessName}
          </TextEllipsisWithTooltip>
        ) : null}
        {item.children.map((child) => {
          const childRoute = getRoute(child);
          return (
            <Child
              key={child.id}
              full={child.widthPercent !== 50}
              as={
                readOnly || child.id === DEFAULT_ITEM_IDS.LOGOUT
                  ? 'div'
                  : childRoute.external
                    ? 'a'
                    : NavLink
              }
              to={childRoute.external ? null : childRoute.path}
              href={childRoute.external ? childRoute.path : null}
              exact={childRoute.exact}
              target={childRoute.target}
              rel={childRoute.rel}
              onClick={
                readOnly
                  ? undefined
                  : child.id === DEFAULT_ITEM_IDS.LOGOUT
                    ? onClickLogout
                    : childRoute.onClick
              }
              {...getQAForItem(child, childRoute)}
            >
              <IconContainer className={child.id}>
                {typeof child.icon === 'string' ? (
                  <Icon icon={child.icon} />
                ) : child.icon?.className ? (
                  <i className={`${child.icon.className} CustomIcon`} />
                ) : (
                  <Icon
                    icon={child.icon?.value ?? 'bars-light'}
                    className={child.icon?.value ?? 'bars-light'}
                  />
                )}
              </IconContainer>
              <TextEllipsisWithTooltip>
                {getLabel(child, translationKeys)}
              </TextEllipsisWithTooltip>
            </Child>
          );
        })}
      </ChildrenContainer>
    );
  }
);

export default NavigationChildren;
