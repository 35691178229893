import { COLUMN_SIZE } from '@kizen/kds/Grid';
import { merge } from '@kizen/kds/util';
import { useTranslation } from 'react-i18next';
import { RecordLayoutPreviewProps } from './types';
import { getWidthClassName } from '../Builder/util';

const getImageForBlock = (type: string, size: COLUMN_SIZE) => {
  switch (type) {
    case 'team_and_activities': {
      switch (size) {
        case COLUMN_SIZE.FULL_WIDTH:
          return '/images/recordLayoutPreview/team/team_block_full.svg';
        case COLUMN_SIZE.TWO_THIRD_WIDTH:
          return '/images/recordLayoutPreview/team/team_block_large.svg';
        case COLUMN_SIZE.HALF_WIDTH:
          return '/images/recordLayoutPreview/team/team_block_medium.svg';
        case COLUMN_SIZE.THIRD_WIDTH:
          return '/images/recordLayoutPreview/team/team_block_small.svg';
      }
      break;
    }
    case 'action_block': {
      switch (size) {
        case COLUMN_SIZE.FULL_WIDTH:
          return '/images/recordLayoutPreview/action/action_block_full.svg';
        case COLUMN_SIZE.TWO_THIRD_WIDTH:
          return '/images/recordLayoutPreview/action/action_block_large.svg';
        case COLUMN_SIZE.HALF_WIDTH:
          return '/images/recordLayoutPreview/action/action_block_medium.svg';
        case COLUMN_SIZE.THIRD_WIDTH:
          return '/images/recordLayoutPreview/action/action_block_small.svg';
      }
      break;
    }
    case 'lead_sources': {
      switch (size) {
        case COLUMN_SIZE.FULL_WIDTH:
          return '/images/recordLayoutPreview/leadInfo/lead_info_block_full.svg';
        case COLUMN_SIZE.TWO_THIRD_WIDTH:
          return '/images/recordLayoutPreview/leadInfo/lead_info_block_large.svg';
        case COLUMN_SIZE.HALF_WIDTH:
          return '/images/recordLayoutPreview/leadInfo/lead_info_block_medium.svg';
        case COLUMN_SIZE.THIRD_WIDTH:
          return '/images/recordLayoutPreview/leadInfo/lead_info_block_small.svg';
      }
      break;
    }
    case 'related_object_fields': {
      switch (size) {
        case COLUMN_SIZE.FULL_WIDTH:
          return '/images/recordLayoutPreview/relatedObjects/objects_block_full.svg';
        case COLUMN_SIZE.TWO_THIRD_WIDTH:
          return '/images/recordLayoutPreview/relatedObjects/objects_block_large.svg';
        case COLUMN_SIZE.HALF_WIDTH:
          return '/images/recordLayoutPreview/relatedObjects/objects_block_medium.svg';
        case COLUMN_SIZE.THIRD_WIDTH:
          return '/images/recordLayoutPreview/relatedObjects/objects_block_small.svg';
      }
      break;
    }
    case 'related_pipelines': {
      switch (size) {
        case COLUMN_SIZE.FULL_WIDTH:
          return '/images/recordLayoutPreview/relatedPipelines/pipelines_block_full.svg';
        case COLUMN_SIZE.TWO_THIRD_WIDTH:
          return '/images/recordLayoutPreview/relatedPipelines/pipelines_block_large.svg';
        case COLUMN_SIZE.HALF_WIDTH:
          return '/images/recordLayoutPreview/relatedPipelines/pipelines_block_medium.svg';
        case COLUMN_SIZE.THIRD_WIDTH:
          return '/images/recordLayoutPreview/relatedPipelines/pipelines_block_small.svg';
      }
      break;
    }
    case 'timeline': {
      switch (size) {
        case COLUMN_SIZE.FULL_WIDTH:
          return '/images/recordLayoutPreview/timeline/timeline_block_full.svg';
        case COLUMN_SIZE.TWO_THIRD_WIDTH:
          return '/images/recordLayoutPreview/timeline/timeline_block_large.svg';
        case COLUMN_SIZE.HALF_WIDTH:
          return '/images/recordLayoutPreview/timeline/timeline_block_medium.svg';
        case COLUMN_SIZE.THIRD_WIDTH:
          return '/images/recordLayoutPreview/timeline/timeline_block_small.svg';
      }
      break;
    }
    case 'fields': {
      switch (size) {
        case COLUMN_SIZE.FULL_WIDTH:
          return '/images/recordLayoutPreview/fieldCategories/field_categories_block_full.svg';
        case COLUMN_SIZE.TWO_THIRD_WIDTH:
          return '/images/recordLayoutPreview/fieldCategories/field_categories_block_large.svg';
        case COLUMN_SIZE.HALF_WIDTH:
          return '/images/recordLayoutPreview/fieldCategories/field_categories_block_medium.svg';
        case COLUMN_SIZE.THIRD_WIDTH:
          return '/images/recordLayoutPreview/fieldCategories/field_categories_block_small.svg';
      }
      break;
    }
    case 'custom_content': {
      switch (size) {
        case COLUMN_SIZE.FULL_WIDTH:
          return '/images/recordLayoutPreview/customContent/custom_content_block_full.svg';
        case COLUMN_SIZE.TWO_THIRD_WIDTH:
          return '/images/recordLayoutPreview/customContent/custom_content_block_large.svg';
        case COLUMN_SIZE.HALF_WIDTH:
          return '/images/recordLayoutPreview/customContent/custom_content_block_medium.svg';
        case COLUMN_SIZE.THIRD_WIDTH:
          return '/images/recordLayoutPreview/customContent/custom_content_block_small.svg';
      }
      break;
    }
  }
  return null;
};

export const RecordLayoutPreview = (props: RecordLayoutPreviewProps) => {
  const { layout } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-y-10">
      {layout
        .map((row) => {
          if (!row.columns.some((column) => column.items.length > 0)) {
            return null;
          }

          return (
            <div key={row.id} className="kds grid grid-cols-6 gap-x-spacer-10">
              {row.columns.map((column) => {
                const { width } = column;
                const widthClassName = getWidthClassName(width);

                return (
                  <div
                    key={column.id}
                    className={merge('kds grow flex', widthClassName)}
                  >
                    <div className="kds min-w-0 w-full flex flex-col gap-y-spacer-10">
                      {column.items.map((item) => {
                        if (item.hidden) {
                          return null;
                        }

                        const ImageForBlock: any = getImageForBlock(
                          item.type,
                          column.width
                        );
                        return ImageForBlock ? (
                          <div
                            key={item.id}
                            className="select-none pointer-events-none"
                          >
                            <img
                              src={ImageForBlock}
                              className="w-full"
                              alt={t('Block Preview')}
                            />
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })
        .filter(Boolean)}
    </div>
  );
};
