import React from 'react';
import Underline from './Underline';
import OutLine from './Outline';
import { variantProp } from '../props';

const FileInput = React.forwardRef(({ variant, ...props }, ref) => {
  const VariantFileInput = variant === 'underline' ? Underline : OutLine;
  return <VariantFileInput ref={ref} {...props} />;
});

FileInput.displayName = 'FileInput';

FileInput.propTypes = {
  variant: variantProp,
};

FileInput.defaultProps = {
  variant: 'outline',
};

export default FileInput;
