import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { useMemo } from 'react';
import css from '@emotion/css';

const StyledButtons = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
`;

const StyledButton = styled.button`
  height: 36px;
  outline: none;
  border: none;
  padding: 0 10px;
  width: ${({ tabsAmount }) => 100 / tabsAmount}%;
  min-width: 60px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${({ selected }) => selected && colorsButton.blue.default};
  background: none;
  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    ${({ selected }) =>
      selected
        ? css`
            height: 2px !important;
          `
        : css`
            height: 1px;
          `};
    background-color: ${({ selected }) =>
      selected ? colorsButton.blue.default : grayScale.medium};
  }
  &:hover,
  &:focus {
    color: ${colorsButton.blue.hover};
    &:after {
      background-color: ${({ selected }) =>
        selected ? colorsButton.blue.hover : grayScale.medium};
    }
  }
`;

export const TabsButton = ({ onSelectTab, options, selectedTab }) => {
  const tabs = useMemo(
    () => (Array.isArray(options) ? options.filter((opt) => opt.tabKey) : []),
    [options]
  );
  const handleSelect = (key) => () => {
    onSelectTab(key);
  };
  return tabs.length ? (
    <StyledButtons>
      {tabs.map((tab) => (
        <StyledButton
          key={tab.tabKey}
          data-qa={`${tab.tabKey}_button`}
          onClick={handleSelect(tab)}
          selected={selectedTab === tab.tabKey}
          tabsAmount={tabs.length}
        >
          <TextEllipsisWithTooltip as="span" color="inherit">
            {tab.label}
          </TextEllipsisWithTooltip>
        </StyledButton>
      ))}
    </StyledButtons>
  ) : null;
};
