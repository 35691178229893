import { useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ClientService from 'services/ClientService';
import FieldService from 'services/FieldService';
import { isMobile, useWindowSize } from 'app/spacing';
import { getSearchParam, setSearchParams } from 'hooks/useSearchParam';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import Loader from 'components/Kizen/Loader';
import { useToast, toastVariant } from 'components/ToastProvider';
import useModal from 'components/Modals/useModal';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import ContactsListPage from './ListPage';
import ContactsTabularPage from './TabularPage';
import ConfirmActionModal from './ConfirmActionModal';
import PerformActionModal from 'pages/Common/bulkactions/PerformActionModal';
import { getPerformActionOptions } from 'pages/Common/bulkactions/model';
import { useTranslation } from 'react-i18next';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { getRecordOverviewAccess } from 'store/authentication/selectors';
import { useMutation, useQuery } from 'react-query';

import {
  buildPage,
  changeGroup,
  clearFilter as clearFilterAction,
  clearFilterSearch,
  createOrUpdateGroup,
  updatePageConfig,
  updatePageConfigBySearch,
  updateContact,
  addNewContact,
  archiveContactSuccess,
  setCheckedContact,
  setCheckedSelection,
  setFilterName,
  setCountsNeedToLoad,
  setPageFetching,
  setIsAllContactsGroup,
  setPageConfig,
} from 'store/contactsPage/actions';
import {
  getContacts,
  getCounts,
  removeGroup,
} from 'store/contactsPage/actions';
import {
  getContactsPageReady,
  getFilterGroupReason,
  getHasFilterError,
  getIsLoadingView,
  getNeedToLoadCountsSelector,
} from 'store/contactsPage/selectors';
import CreateEntityModalFull, {
  CONTACT,
} from 'components/Modals/CreateEntity/FullForm';
import { useDebounce } from 'react-use';
import { DEFAULT_DELAY } from 'utility/config';
import { maybeTrackSearch } from 'utility/analytics';
import ControlBar from './ControlBar';
import ObjectCharts from 'components/ObjectCharts';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import { DEFAULT_PATHS } from 'routes/paths';
import { deferExecution } from 'utility/defer';
import { DASHBOARD } from 'queries/query-keys';
import {
  createDashboardQuery,
  fetchDashboardsQuery,
} from 'components/AccessRequests/useDashboardList';
import useClientObjectChartGroupConfig from './useContactsChartGroupConfig';
import { getSortedDashboards } from 'components/DashboardGrid/utils';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import CreateLayoutModal from 'components/DashboardGrid/CreateLayoutModal';
import { setNumberOfFilters } from 'store/contactsPage/actions';
import { FilterDropdownContext } from 'pages/Common/components/filterDropdownContext';
import {
  DATA_MANAGER_SESSION_ACTIONS,
  useDataManagerSession,
} from 'app/dataManagerSession';
import { buildPageFilterQuery } from 'ts-filters/utils';
import { useFilterErrorEffect } from 'hooks/useFilterErrorEffect';
import { useRecordsPageRouting } from 'hooks/useRecordsPageRouting';
import { RECORD_LIST_CONFIG_KEYS } from 'services/TeamMemberService';
import { getColumns, shallowEqualList } from 'pages/Common/helpers/table';
import {
  CUSTOMIZE_FIELDS_STEP_KEY,
  GO_BACK_KEYS,
} from 'components/Wizards/CustomObject/constants';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';
import { flushSync } from 'react-dom';
import { snakeToCamelCaseKeys } from '__services/helpers';
import { areFiltersEqual } from 'utility/validate';

const SupportedQueryFilters = [
  'automations',
  'dynamictag',
  'form',
  'survey',
  'tag',
  'titles',
];

const updateContactHandler = async ({ data, state }) => {
  const { id, patch } = data;
  const { fields, contacts } = state;
  if (fields.length === 0) {
    throw new Error('Cannot update contact without field information');
  }
  const contact = contacts.find((c) => c.id === id);
  if (!contact) {
    throw new Error('Unknown contact to update');
  }

  const result = await FieldService.patchObject(
    { id, for: 'contacts', params: { return_all_fields: true } },
    patch,
    fields
  );
  return result;
};

const defaultPageSettings = {
  pageNumber: 1,
  pageSize: 50,
};

export default function ContactsPage(props) {
  const { t } = useTranslation();
  useSetTitleOnLoad(props.title(t));
  const history = useHistory();
  const hasGroupQueryParam = useRef(
    Boolean(getSearchParam(history.location, 'group'))
  );
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const preReleaseFeatures = usePreReleaseFeatures();
  const [showToast] = useToast();
  const { subpage, chartId } = useParams();
  const isChartView = Boolean(subpage === VIEW_VARIANTS.CHARTS);

  // state functions -----------------------------------------------
  const [userActionChoice, setUserActionChoice] = useState(null);
  const [isMenuOpen, toggleMenu] = useState(false);
  const [newContactModal, setNewContactModal] = useState(false);

  // ---------------------------------------------------------------

  // selectors functions -------------------------------------------
  const bulkActionsPermissions = useSelector(({ authentication }) => {
    const { access } = authentication;
    return access.contacts && access.contacts.bulk_actions;
  });
  const isFetching = useSelector((s) => s.contactPage.isFetching);
  const categorizedFields = useSelector((s) => s.contactPage.categorizedFields);
  const fields = useSelector((s) => s.contactPage.fields);
  const allFields = useSelector((s) => s.contactPage.allFields);
  const groups = useSelector((s) => s.contactPage.groups);
  const contacts = useSelector((s) => s.contactPage.contacts);
  const contactsCount = useSelector((s) => s.contactPage.contactsCount);
  // Specifically for count shown in header
  const contactsCountAndNew = useSelector(
    (s) => s.contactPage.contactsCountAndNew
  );
  const groupCount = useSelector((s) => s.contactPage.groupCount);
  const allCount = useSelector((s) => s.contactPage.allCount);
  const pageConfig = useSelector((s) => s.contactPage.pageConfig);
  const isLoadingView = useSelector(getIsLoadingView);
  const selection = useSelector((s) => s.contactPage.selection);
  const filters = useSelector((s) => s.contactPage.filters);
  const hasFilterError = useSelector(getHasFilterError);
  const needToLoadCounts = useSelector(getNeedToLoadCountsSelector);
  const { dispatchSessionState } = useDataManagerSession();
  const clientObject = useSelector((s) => s.contactPage.clientObject);
  const filterGroupReason = useSelector(getFilterGroupReason);
  const pageReady = useSelector(getContactsPageReady);
  const { viewVariantsAccess, quickFiltersAccess } = useSelector(
    getRecordOverviewAccess
  );
  const [lastCheckedSelection, setLastCheckedSelection] = useState(null);

  const savedColumnSettings = useSelector(
    (s) => getColumns(s.contactPage.pageConfig),
    shallowEqualList
  );

  const lastFetchedCriteria = useSelector((s) => s.contactPage.fetchedCriteria);

  const { search } = pageConfig;

  // Track debounced changes to the search term and send to google analytics
  useDebounce(() => maybeTrackSearch(search, 'contacts'), DEFAULT_DELAY, [
    search,
  ]);

  const clientObjectId = clientObject?.id;

  // memo functions
  const sortValue = useMemo(
    () => getSortValue(pageConfig.sort),
    [pageConfig.sort]
  );

  const performActionOptionsWithPermissions = useMemo(
    () =>
      getPerformActionOptions(t, 'Contacts').filter(
        (option) =>
          !(option.isPrereleaseFeature && !preReleaseFeatures) &&
          bulkActionsPermissions &&
          bulkActionsPermissions[option.value]
      ),
    [bulkActionsPermissions, preReleaseFeatures, t]
  );

  const [pageConfigGroup, filterGroup] = useMemo(
    () => [
      pageConfig.queryFilter ? null : pageConfig.group,
      pageConfig.queryFilter
        ? undefined
        : (groups || []).find(({ id }) => id === pageConfig.group),
    ],
    [pageConfig.group, pageConfig.queryFilter, groups]
  );

  const allInGroupDisabled = useMemo(() => {
    const matches =
      !lastFetchedCriteria ||
      areFiltersEqual(
        // It seems a little silly to convert both to camel case, but depending on how the data is fetched, one or the other
        // might be snake case, so we just force them both camel case for consistency
        snakeToCamelCaseKeys(filterGroup?.config),
        snakeToCamelCaseKeys(lastFetchedCriteria)
      );

    return !matches;
  }, [filterGroup, lastFetchedCriteria]);

  const selectionOptions = useMemo(() => {
    return !needToLoadCounts
      ? [
          {
            label:
              contacts.length !== 0
                ? `${t('This Page')} (${Number(
                    contacts.length
                  ).toLocaleString()})`
                : t('This Page'),
            value: 'page',
            numberContacts: contacts.length,
          },
          {
            label:
              contacts.length !== 0
                ? `${t('All in Search')} (${Number(
                    contactsCount
                  ).toLocaleString()})`
                : t('All in Search'),
            value: 'search',
            numberContacts: contactsCount,
          },
          {
            label:
              groupCount !== 0 && !allInGroupDisabled
                ? `${t('All in Group')} (${Number(
                    pageConfigGroup ? groupCount : allCount
                  ).toLocaleString()})`
                : t('All in Group'),
            value: 'group',
            numberContacts: pageConfigGroup ? groupCount : allCount,
            disabled: allInGroupDisabled,
          },
          {
            label:
              allCount !== 0
                ? `${t('All Contacts')} (${Number(allCount).toLocaleString()})`
                : t('All Contacts'),
            value: 'all',
            numberContacts: allCount,
          },
          {
            label: t('None'),
            value: 'none',
            numberContacts: 0,
          },
        ]
      : [];
  }, [
    contacts,
    groupCount,
    contactsCount,
    allCount,
    pageConfigGroup,
    needToLoadCounts,
    allInGroupDisabled,
    t,
  ]);
  // ---------------------------------------------------------------

  // async effects -------------------------------------------------
  useEffect(() => {
    syncDispatch(setPageFetching());

    const sort = getSearchParam(history.location, 'sort');
    const group = getSearchParam(history.location, 'group');
    const search = getSearchParam(history.location, 'q');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');

    const queryFilter = SupportedQueryFilters.reduce((acc, el) => {
      try {
        const data = getSearchParam(history.location, el);
        if (!data) return acc;
        const filter = JSON.parse(data);
        return {
          ...filter,
          queryParam: el,
        };
      } catch (error) {
        return acc;
      }
    }, null);

    syncDispatch(
      buildPage({
        history,
        page: {
          search: search || '',
          group,
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
          queryFilter,
        },
      })
    );

    if (queryFilter) toggleMenu(true);

    setSearchParams(
      history,
      SupportedQueryFilters.reduce((acc, el) => {
        return {
          ...acc,
          [el]: null,
        };
      }, {}),
      { method: 'replace' }
    );
  }, [syncDispatch, history]);

  useEffect(() => {
    if (userActionChoice) {
      // eslint-disable-next-line no-use-before-define
      openPerformModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActionChoice]);

  const storeViewVariant = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          // if filter is applied on page load then default to table view
          viewVariant: value,
        })
      );
      if (!hasGroupQueryParam.current) {
        dispatch(
          setPageConfig({
            updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
          })
        );
      }
    },
    [dispatch]
  );

  const pageConfigQueryFilterParam = pageConfig?.queryFilter?.queryParam;
  const dynamicTagFilterApplied =
    pageConfigQueryFilterParam === 'dynamictag' ||
    pageConfigQueryFilterParam === 'titles' ||
    pageConfigQueryFilterParam === 'tag';

  const { viewVariant, changeChartGroup } = useRecordsPageRouting(
    !!clientObjectId,
    storeViewVariant,
    dynamicTagFilterApplied ? VIEW_VARIANTS.TABLE : pageConfig?.viewVariant,
    {
      viewVariantsAccess,
      isContact: true,
      queryParams: { group: getSearchParam(history.location, 'group') },
    }
  );

  useEffect(() => {
    if (clientObject?.id && pageReady) {
      if (hasGroupQueryParam.current) {
        syncDispatch(clearFilterSearch());
      }
      hasGroupQueryParam.current = false;
      syncDispatch(
        getContacts({
          triggerViewLoading: true,
          updatePageConfig: false,
          onlyUpdateCounts: viewVariant === VIEW_VARIANTS.CHARTS,
        })
      );
    }
  }, [syncDispatch, viewVariant, clientObject, pageReady]);

  // ---------------------------------------------------------------

  // filter handlers -----------------------------------------------

  const filterInfo = useMemo(
    () => ({
      ...filters,
      name: pageConfig.queryFilter
        ? pageConfig.queryFilter?.maybeName
        : filters.name,
    }),
    [filters, pageConfig.queryFilter]
  );

  const resetFilterToast = useFilterErrorEffect({
    toggleMenu,
    filterGroupReason,
    hasFilterError,
  });

  const updateFilterName = useCallback(
    (name) => {
      dispatch(setFilterName({ name }));
    },
    [dispatch]
  );

  const updateFilterCount = useCallback(
    (count) => {
      dispatch(setNumberOfFilters(count));
    },
    [dispatch]
  );

  const applyFiltersAction = useCallback(
    (payload) => {
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: clientObjectId,
      });

      dispatch(
        updatePageConfig({
          page: 1,
          filterObject: payload,
        })
      );
      dispatch(
        getContacts({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
          onlyUpdateCounts: viewVariant === VIEW_VARIANTS.CHARTS,
        })
      );
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );
    },
    [dispatch, viewVariant, dispatchSessionState, clientObjectId]
  );

  const applyQuickFilters = useCallback(
    (payload) => {
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: clientObjectId,
      });
      dispatch(
        updatePageConfig({
          page: 1,
          quickFilters: payload,
        })
      );
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );
      dispatch(
        getContacts({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
          onlyUpdateCounts: viewVariant === VIEW_VARIANTS.CHARTS,
        })
      );
    },
    [dispatch, viewVariant, dispatchSessionState, clientObjectId]
  );

  const saveGroupAction = useCallback(
    ({ filterGroup, isPatch }) => {
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: clientObjectId,
      });

      dispatch(
        createOrUpdateGroup({
          filterGroup,
          history,
          isPatch,
        })
      );
      dispatch(
        updatePageConfig({
          filterObject: filterGroup.config,
        })
      );
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );
    },
    [history, dispatch, dispatchSessionState, clientObjectId]
  );

  const deleteGroupAction = useCallback(() => {
    dispatchSessionState({
      type: DATA_MANAGER_SESSION_ACTIONS.RESET,
      parentId: clientObjectId,
    });

    dispatch(removeGroup({ history }));
    dispatch(
      setCheckedSelection({
        value: 'none',
      })
    );
    toggleMenu(false);
  }, [dispatch, history, dispatchSessionState, clientObjectId]);

  const clearFilter = useCallback(() => {
    dispatchSessionState({
      type: DATA_MANAGER_SESSION_ACTIONS.RESET,
      parentId: clientObjectId,
    });

    dispatch(clearFilterAction());
    dispatch(
      getContacts({
        updatePageConfig: true,
        updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        onlyUpdateCounts: viewVariant === VIEW_VARIANTS.CHARTS,
      })
    );
    syncDispatch(
      setCheckedSelection({
        value: 'none',
      })
    );
    deferExecution(() => {
      setSearchParams(history, {
        page: null,
        ...(pageConfig.group ? { group: null } : {}),
        ...(pageConfig.search ? { q: '' } : {}),
      });
    });
  }, [
    dispatch,
    history,
    pageConfig.group,
    pageConfig.search,
    viewVariant,
    dispatchSessionState,
    clientObjectId,
    syncDispatch,
  ]);

  // ---------------------------------------------------------------

  // table handlers ------------------------------------------------
  const handleChangeGroupId = useCallback(
    (value) => {
      // reset error flag
      resetFilterToast();

      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: clientObjectId,
      });

      dispatch(
        changeGroup({
          id: value,
          t,
        })
      );
      dispatch(setIsAllContactsGroup(value === null));
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );
      dispatch(setCountsNeedToLoad());
      deferExecution(() => {
        setSearchParams(history, {
          group: value,
          page: null,
        });
      });
    },
    [
      history,
      dispatch,
      t,
      dispatchSessionState,
      clientObjectId,
      resetFilterToast,
    ]
  );

  const handleChangeSearch = useCallback(
    (value) => {
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: clientObjectId,
      });

      dispatch(
        updatePageConfigBySearch({
          search: value,
          page: defaultPageSettings.pageNumber,
        })
      );
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );
      dispatch(
        getContacts({
          updatePageConfig: false,
          onlyUpdateCounts: viewVariant === VIEW_VARIANTS.CHARTS,
        })
      );
      deferExecution(() =>
        setSearchParams(
          history,
          {
            q: value,
            page: null,
          },
          { method: 'replace' }
        )
      );
    },
    [history, dispatch, viewVariant, dispatchSessionState, clientObjectId]
  );

  const handleSortTable = useCallback(
    ({ column, direction }) => {
      const value = getOrderingParam({ column, direction });
      dispatch(
        updatePageConfig({
          sort: value,
        })
      );
      dispatch(
        getContacts({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        })
      );
      deferExecution(() => {
        setSearchParams(history, {
          sort: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          page: value,
        })
      );
      dispatch(
        getContacts({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        })
      );
      deferExecution(() => {
        setSearchParams(history, {
          page: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          size: value,
        })
      );
      dispatch(
        getContacts({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        })
      );
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleUpdateContact = useCallback(
    async (id, patch, { created } = {}) => {
      if (created) {
        dispatch(
          addNewContact({
            id,
            contact: patch,
            fields,
          })
        );
        return true;
      } else {
        const contact = await updateContactHandler({
          data: { id, patch },
          state: {
            fields,
            contacts,
          },
        });
        dispatch(
          updateContact({
            contact,
            patch,
            fields,
          })
        );
        return true;
      }
    },
    [dispatch, fields, contacts]
  );

  const handleChecked = useCallback(
    (id) => {
      dispatch(setCheckedContact({ id }));
    },
    [dispatch]
  );

  const handleCheckedSelection = useCallback(
    (value) => {
      setLastCheckedSelection(value.value);
      dispatch(
        setCheckedSelection({
          value,
        })
      );
    },
    [dispatch]
  );

  if (allInGroupDisabled && lastCheckedSelection === 'group') {
    handleCheckedSelection({ value: 'none' });
  }

  const handleSelectViewContact = useCallback(
    ({ id }) => {
      history.push(`/client/${id}/details`);
    },
    [history]
  );

  const handleSelectArchiveContact = useCallback(
    async ({ id }) => {
      await ClientService.archive(id);
      flushSync(() => {
        showToast({
          variant: toastVariant.SUCCESS,
          message: t('The contact has been successfully archived.'),
        });
      });

      dispatch(archiveContactSuccess({ id }));
      dispatch(getContacts());
    },
    [dispatch, showToast, t]
  );

  const handleUpdateTableRecords = useCallback(() => {
    dispatch(
      getContacts({
        updatePageConfig: true,
        updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
      })
    );
  }, [dispatch]);

  const [, , performActionModal] = useModal();
  const [confirmActionModalProps, , confirmActionModal] = useModal({
    handleSubmit: (selectedActionGroup) => {
      handleCheckedSelection(selectedActionGroup);
      performActionModal.show();
    },
    handleHide: () => {
      setUserActionChoice(null);
    },
    processHideOnSubmit: false, // no handleHide on handleSubmit
  });
  const openPerformModal = () => {
    if (!selection.checkedCount && selection.value !== 'all') {
      confirmActionModal.show();
    } else {
      performActionModal.show();
    }
  };

  const choiceAction = useCallback(
    (options) => setUserActionChoice(options),
    []
  );

  const handleChoseSettings = ({ value }) => {
    if (value === 'contactSettings') {
      history.push({
        pathname: `/custom-objects/${clientObjectId}/settings`,
        search: history.location.search,
        state: {
          from: history.location.pathname,
          fromKey: GO_BACK_KEYS.CONTACTS,
        },
      });
    } else if (value === 'customizeFields') {
      history.push({
        pathname: `/custom-objects/${clientObjectId}/settings`,
        search: history.location.search,
        state: {
          from: history.location.pathname,
          fromKey: GO_BACK_KEYS.CONTACTS,
          focusStepKey: CUSTOMIZE_FIELDS_STEP_KEY,
        },
      });
    }
  };

  const handleOpenSelectionMenu = useCallback(() => {
    if (needToLoadCounts) {
      dispatch(getCounts());
    }
  }, [dispatch, needToLoadCounts]);

  const {
    isLoading: chartGroupsLoading,
    data,
    refetch: refetchChartGroups,
  } = useQuery(
    [...DASHBOARD.DASHBOARDS, clientObjectId, false],
    () => fetchDashboardsQuery(null, false, clientObjectId, 'chart_group'),
    {
      enabled: viewVariant === VIEW_VARIANTS.CHARTS && !!clientObjectId,
    }
  );

  const sortedGroups = useMemo(() => getSortedDashboards(data ?? []), [data]);

  const { loading: configLoading, ...chartGroupConfigProps } =
    useClientObjectChartGroupConfig(
      sortedGroups,
      viewVariant !== VIEW_VARIANTS.CHARTS || !!chartId,
      viewVariant === VIEW_VARIANTS.CHARTS && !!data
    );

  const resetSelectedAction = useCallback(() => setUserActionChoice(null), []);

  const onHidePerformActionModal = useCallback(() => {
    setUserActionChoice(null);
    performActionModal.hide();
    confirmActionModal.hide();
  }, [performActionModal, confirmActionModal]);

  const onResetSelection = useCallback(() => {
    dispatch(
      setCheckedSelection({
        value: 'none',
      })
    );
  }, [dispatch]);

  const createDashboardMutation = useMutation(
    (result) => createDashboardQuery(result, clientObjectId, 'chart_group'),
    {
      onSuccess: (data) => {
        chartGroupConfigProps.afterCreate(data.id);
        deferExecution(() => {
          history.push(`${DEFAULT_PATHS.CONTACTS}/charts/${data.id}`);
        });
      },
    }
  );

  const handleCreateDashboard = useCallback(
    ({ name, permissions, isPrivate }) => {
      createDashboardMutation.mutate({
        name,
        sharing_settings: {
          ...buildSharingSettingsForAPI(permissions).sharing_settings,
          private: isPrivate,
        },
      });
    },
    [createDashboardMutation]
  );

  const [modalProps, , { show: handleShowModal }] = useModal({
    handleSubmit: handleCreateDashboard,
  });

  const handleClickAddChartGroup = useCallback(() => {
    handleShowModal();
  }, [handleShowModal]);

  const fullFilterObject = useMemo(() => {
    const { criteria } = buildPageFilterQuery(
      pageConfig.filterObject,
      pageConfig.quickFilters,
      allFields,
      clientObject,
      pageConfig.quickFilterSettings
    );
    return criteria;
  }, [
    pageConfig.filterObject,
    pageConfig.quickFilters,
    allFields,
    clientObject,
    pageConfig.quickFilterSettings,
  ]);

  const quickFilterConfig = useMemo(
    () => ({
      quickFilteringFacets: pageConfig.quickFilteringFacets,
      quickFilterSettings: pageConfig.quickFilterSettings,
    }),
    [pageConfig.quickFilteringFacets, pageConfig.quickFilterSettings]
  );

  if (isFetching) {
    return <Loader loading />;
  }

  const isLoadingViewProp =
    isLoadingView ||
    (viewVariant === VIEW_VARIANTS.CHARTS &&
      (chartGroupsLoading || configLoading));

  return (
    <>
      {isMobile(width) ? (
        <ContactsListPage
          contacts={contacts}
          contactsCount={contactsCount}
          contactsCountAndNew={contactsCountAndNew}
          groups={groups}
          groupId={pageConfigGroup}
          onChangeGroupId={handleChangeGroupId}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSortTable}
          onChecked={handleChecked}
          onCheckedSelection={handleCheckedSelection}
          selection={selection}
          selectionOptions={selectionOptions}
          pageNumber={pageConfig.page || defaultPageSettings.pageNumber}
          pageSize={pageConfig.size || defaultPageSettings.pageSize}
          onChangePageNumber={handleChangePageNumber}
          onChangePageSize={handleChangePageSize}
          checkedCount={selection.checkedCount}
          choiceAction={choiceAction}
          openAddContactModal={() => setNewContactModal(true)}
          openPerformModal={openPerformModal}
          handleUpdateTableRecords={handleUpdateTableRecords}
          clientObject={clientObject}
          isChartView={isChartView}
          chartGroupConfigProps={{
            ...chartGroupConfigProps,
            refetchChartGroups: refetchChartGroups,
            setCurrentDashboardId: changeChartGroup,
          }}
          fullFilterObject={fullFilterObject}
          isLoadingView={isLoadingViewProp}
          {...props}
        />
      ) : (
        <FilterDropdownContext key={filterInfo.key} config={filterInfo.config}>
          <ControlBar
            onChoseSettings={handleChoseSettings}
            disabled={isFetching}
            isChartView={isChartView}
            chartGroupConfigProps={{
              ...chartGroupConfigProps,
              refetchChartGroups: refetchChartGroups,
              setCurrentDashboardId: changeChartGroup,
              handleClickAddDashboard: handleClickAddChartGroup,
            }}
          />
          {isChartView ? (
            <ObjectCharts
              model={clientObject}
              allFields={allFields}
              groups={groups}
              groupId={pageConfigGroup}
              filterGroup={filterGroup}
              handleChangeGroup={({ value }) =>
                handleChangeGroupId(value === 'none' ? null : value)
              }
              filterInfo={filterInfo}
              hasFilterError={hasFilterError}
              openMenu={isMenuOpen}
              search={pageConfig.search}
              onChangeSearch={handleChangeSearch}
              recordsCountAndNew={contactsCountAndNew}
              chartGroupConfigProps={{
                ...chartGroupConfigProps,
                refetchChartGroups: refetchChartGroups,
                setCurrentDashboardId: changeChartGroup,
                handleClickAddDashboard: handleClickAddChartGroup,
              }}
              fullFilterObject={fullFilterObject}
              quickFilters={
                clientObject?.quickFilteringEnabled &&
                quickFiltersAccess &&
                pageConfig.quickFilters
              }
              quickFilterConfig={quickFilterConfig}
              toggleMenu={toggleMenu}
              applyFiltersAction={applyFiltersAction}
              applyQuickFilters={applyQuickFilters}
              updateFilterCount={updateFilterCount}
              saveGroupAction={saveGroupAction}
              deleteGroupAction={deleteGroupAction}
              setFilterName={updateFilterName}
              clearFilter={clearFilter}
              preReleaseFeatures={preReleaseFeatures}
              errorsReturned={filterInfo.errors}
              isLoadingView={isLoadingViewProp}
            />
          ) : (
            <ContactsTabularPage
              model={clientObject}
              contacts={contacts}
              contactsCount={contactsCount}
              contactsCountAndNew={contactsCountAndNew}
              onSubmitContact={handleUpdateContact}
              groups={groups}
              groupId={pageConfigGroup}
              filterGroup={filterGroup}
              fields={fields}
              allFields={allFields}
              categorizedFields={categorizedFields}
              onChangeGroupId={handleChangeGroupId}
              search={pageConfig.search}
              onChangeSearch={handleChangeSearch}
              sort={sortValue}
              onChangeSort={handleSortTable}
              selectionOptions={selectionOptions}
              pageNumber={pageConfig.page || defaultPageSettings.pageNumber}
              pageSize={pageConfig.size || defaultPageSettings.pageSize} // just for first render
              onChangePageSize={handleChangePageSize}
              onChangePageNumber={handleChangePageNumber}
              selection={selection}
              onChecked={handleChecked}
              onCheckedSelection={handleCheckedSelection}
              openAddContactModal={() => setNewContactModal(true)}
              openPerformModal={openPerformModal}
              filterInfo={filterInfo}
              quickFilters={
                clientObject?.quickFilteringEnabled &&
                quickFiltersAccess &&
                pageConfig.quickFilters
              }
              quickFilterConfig={quickFilterConfig}
              fullFilterObject={fullFilterObject}
              applyQuickFilters={applyQuickFilters}
              openMenu={isMenuOpen}
              applyFiltersAction={applyFiltersAction}
              updateFilterCount={updateFilterCount}
              choiceAction={choiceAction}
              performActionOptions={performActionOptionsWithPermissions}
              saveGroupAction={saveGroupAction}
              deleteGroupAction={deleteGroupAction}
              setFilterName={updateFilterName}
              toggleMenu={toggleMenu}
              clearFilter={clearFilter}
              onSelectViewContact={handleSelectViewContact}
              onSelectArchiveContact={handleSelectArchiveContact}
              handleUpdateTableRecords={handleUpdateTableRecords}
              onOpenSelectionMenu={handleOpenSelectionMenu}
              data-qa="clients-table"
              errorsReturned={filterInfo.errors}
              isLoadingView={isLoadingViewProp}
              {...props}
            />
          )}
        </FilterDropdownContext>
      )}
      <ConfirmActionModal
        selectionOptions={selectionOptions.filter(
          (item) => item.value !== 'none'
        )}
        onMenuOpen={handleOpenSelectionMenu}
        {...confirmActionModalProps}
      />
      {performActionModal.showing && (
        <PerformActionModal
          allCount={allCount}
          selection={selection}
          search={pageConfig.search}
          groupId={pageConfigGroup}
          groupOptions={groups}
          onHide={onHidePerformActionModal}
          selectedAction={userActionChoice}
          resetSelectedAction={resetSelectedAction}
          show={performActionModal.showing}
          onSelect={choiceAction}
          performActionOptions={performActionOptionsWithPermissions}
          filters={fullFilterObject}
          isMobile={isMobile(width)}
          model={clientObject}
          // additional props
          recordsCount={contactsCount}
          records={contacts}
          onResetSelection={onResetSelection}
          savedColumnSettings={savedColumnSettings}
          fields={fields}
          // onUpdateClientsList added so that clients list can get refreshed on tag change
          // TODO: maybe add redux action which will trigger clients list refresh with the same params?
        />
      )}

      {newContactModal ? (
        <CreateEntityModalFull
          objectType={CONTACT}
          show={true}
          onCreated={async (v) =>
            // we can to do everything what we want with new record
            await handleUpdateContact(v.id, v, {
              created: true,
            })
          }
          onCancel={() => setNewContactModal(false)}
        />
      ) : null}
      <CreateLayoutModal
        {...modalProps}
        canUserEditPermissions
        entity={t('Chart Group')}
      />
    </>
  );
}
