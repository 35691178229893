import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Logo = ({ width, height }) => {
  const { t } = useTranslation();
  return (
    <img
      src="/images/kizen-icon.png"
      height={height}
      width={width}
      role="button"
      tabIndex={0}
      aria-label={t('Kizen Logo')}
      className="KizenLogo"
    />
  );
};

Logo.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
};

export default Logo;
