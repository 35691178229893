import { useEffect, useMemo, useState } from 'react';
import MultiColumnBuilder from 'components/MultiColumnBuilder';
import useBasicBuilder, {
  RIGHT_ID,
  LEFT_ID,
} from 'components/MultiColumnBuilder/useBasicBuilder';
import KizenTypography from 'app/kizentypo';
import { fontWeights } from 'app/typography';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import { useTranslation } from 'react-i18next';
import { StyledDropPlaceholder } from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/styles';
import EditableTextEllipsisWithTooltip from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/EditableTextEllipsisWithTooltip';

import { StyledDndColumns, StyledDragItem } from './styles';
import { RELATED_ENTITITY_QUICK_FILTER_FIELD } from './constants';
import IconButton from 'components/Kizen/IconButton';
import { colorsButton } from 'app/colors';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';

const selfDisabledDropZones = [LEFT_ID];

const RightEmpty = ({ dropZone, id }) => {
  const { t } = useTranslation();
  return dropZone?.sectionId === id ? (
    <StyledDropPlaceholder>
      <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
        <KizenTypography>{t('Place Here')}</KizenTypography>
      </StyledDragItem>
    </StyledDropPlaceholder>
  ) : (
    <StyledDropPlaceholder noHover>
      <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
        <KizenTypography>
          {t('No Objects Currently Selected (Drag and Drop to Add)')}
        </KizenTypography>
      </StyledDragItem>
    </StyledDropPlaceholder>
  );
};

const Option = ({ setShowModal, ...renderProps }) => {
  const { t } = useTranslation();
  const { element, swapItemColumn, index, editValue } = renderProps;

  const deleteItem = () => {
    swapItemColumn(index, RIGHT_ID);
  };

  const onBlur = () => {
    if (element.label.trim() === '') {
      editValue(index, undefined, 'label', element.defaultLabel);
    }
  };

  const onChangeLabel = (value) => {
    editValue(index, undefined, 'label', value);
  };

  const onSelectOption = ({ value }) => {
    if (value === 'remove') {
      deleteItem();
    } else if (value === 'edit') {
      setShowModal({
        index,
        ...element,
      });
    }
  };

  const relatedFieldFilterOptions = useMemo(
    () => [
      {
        value: 'remove',
        label: t('Remove Quick Filter'),
      },
      {
        value: 'edit',
        label: t('Edit Options'),
      },
    ],
    [t]
  );

  return (
    <div>
      <StyledDragItem
        {...renderProps}
        isDropzone
        className="innerItem"
        onBlur={onBlur}
        data-qa-label={element.label}
      >
        <EditableTextEllipsisWithTooltip
          sizing="dense"
          value={element.label}
          placeholder={t('Enter Display Name')}
          onChange={onChangeLabel}
        />
        {element.relatedObjectFieldId ? (
          <IconButtonMenu
            title={t('Edit Settings')}
            position="left"
            sizing="dense"
            color={colorsButton.iconGray}
            options={relatedFieldFilterOptions}
            onChange={onSelectOption}
            restrictCloseHandler
            overlay
          >
            <Icon icon="three-dot" />
          </IconButtonMenu>
        ) : (
          <IconButton
            sizing="dense"
            color={colorsButton.iconGray}
            onClick={deleteItem}
            title={t('Remove Quick Filter')}
          >
            <IconSizing size="16px">
              <Icon icon="delete" />
            </IconSizing>
          </IconButton>
        )}
      </StyledDragItem>
    </div>
  );
};

export const DraggableColumns = ({
  leftItems,
  rightItems,
  onChange,
  isTemplate,
  fields,
}) => {
  const { t } = useTranslation();

  const [, setShowModal] = useState(null);

  const {
    rightColumnItems,
    leftColumnProps,
    setOptionsSearchTerm,
    rightColumnProps,
    ...props
  } = useBasicBuilder({
    initialLeftColumnItems: leftItems,
    initialRightColumnItems: rightItems,
    editable: true,
    showCategoryOnSearch: false,
    selfDisabledDropZones,
    rightColumnItemsWithContentForceDirty: false,
  });

  useEffect(() => {
    const relatedFilterAddedIndex = rightColumnItems.findIndex(
      ({ fieldId }) => fieldId === RELATED_ENTITITY_QUICK_FILTER_FIELD
    );
    if (relatedFilterAddedIndex >= 0) {
      setShowModal({
        index: relatedFilterAddedIndex,
      });
    } else {
      onChange(rightColumnItems);
    }
  }, [onChange, rightColumnItems]);

  return (
    <StyledDndColumns isTemplate={isTemplate} data-qa="related-object-builder">
      <MultiColumnBuilder
        data-qa="related-object-builder"
        {...props}
        leftColumnProps={leftColumnProps}
        rightColumnProps={rightColumnProps}
        searchable
        weight={fontWeights.regular}
        onChangeSearchTerm={setOptionsSearchTerm}
        searchPlaceholder={t('Find Objects')}
        leftHeaderText={t('Available Objects (Drag to Add)')}
        rightHeaderText={t('Objects in Block')}
        renderRightEmpty={(rightEmptyProps) => (
          <RightEmpty {...rightEmptyProps} />
        )}
        renderOption={(renderProps) => (
          <Option setShowModal={setShowModal} {...renderProps} />
        )}
      />
    </StyledDndColumns>
  );
};
