import AxiosService from './AxiosService';
import { dashletForApp } from './DashletService';
import { FORCE_ALL_RECORDS_SIZE } from './helpers';
import { monitoringExceptionHelper } from 'sentry/helpers';

export const dashboardForApp = ({ dashlets, ...dashboard }) => ({
  ...dashboard,
  dashlets: dashlets.map(dashletForApp),
});

class DashboardService {
  /*
   * Singleton ES6 pattern
   * https://www.sitepoint.com/javascript-design-patterns-singleton/
   */
  constructor() {
    if (!DashboardService.instance) {
      DashboardService.instance = this;
    }
    return DashboardService.instance;
  }

  v2endpoint = '/dashboards';

  async getDashboardList(
    searchTerm,
    config,
    includeSharing = true,
    objectId = '',
    type = ''
  ) {
    try {
      const response = await AxiosService.get(
        `${
          this.v2endpoint
        }/mine?size=${FORCE_ALL_RECORDS_SIZE}&include_sharing=${String(
          includeSharing
        )}${searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : ''}${
          objectId ? `&custom_object_id=${objectId}` : ''
        }${type ? `&dashboard_type=${type}` : ''}`,
        config
      );
      return response.data.results;
    } catch (error) {
      return [];
    }
  }

  async getOthersDashboardList(searchTerm, page, pageSize, objectId, type) {
    try {
      const response = await AxiosService.get(
        `${
          this.v2endpoint
        }/other?page_size=${pageSize}&page=${page}&include_sharing=true${
          searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : ''
        }${objectId ? `&custom_object_id=${objectId}` : ''}${
          type ? `&dashboard_type=${type}` : ''
        }
       `
      );
      return {
        results: response.data.results,
        count: response.data.count,
      };
    } catch (error) {
      return { results: [], count: 0 };
    }
  }

  async updateDashboard(id, payload) {
    const url = `${this.v2endpoint}/${id}`;
    const response = await AxiosService.patch(url, payload);

    return response.data;
  }

  async createDashboard(payload, objectId, type) {
    const url = this.v2endpoint;
    const response = await AxiosService.post(url, {
      ...payload,
      custom_object: objectId || undefined,
      type,
    });

    return response.data;
  }
  async duplicateDashboard(id, payload) {
    try {
      const url = `${this.v2endpoint}/${id}/duplicate`;
      const response = await AxiosService.post(url, payload);
      await this.updateDashboard(response.data.id, {
        ...payload,
        hidden: false,
      });
      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      return false;
    }
  }

  async deleteDashboard(id) {
    try {
      const url = `${this.v2endpoint}/${id}`;
      const response = await AxiosService.delete(url);

      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      return false;
    }
  }

  async getDashboard(id, config) {
    try {
      const url = `${this.v2endpoint}/${id}`;
      const response = await AxiosService.get(url, config);

      return response.data;
    } catch (error) {
      // Do not log here, this is expected to error in certain situations
      return { error: true, response: error };
    }
  }

  async reorderDashboards(id, payload) {
    try {
      const url = `${this.v2endpoint}/mine/reorder`;
      const response = await AxiosService.post(url, {
        dashboard_id: id,
        ...payload,
      });

      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      return false;
    }
  }

  async getGrantingAdmins(id) {
    try {
      const url = `${this.v2endpoint}/${id}/admins`;
      const response = await AxiosService.get(url);
      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      return [];
    }
  }

  async requestAccess(dashboardId, adminId, accessLevel) {
    try {
      const url = `${this.v2endpoint}/request-access`;
      const response = await AxiosService.post(url, {
        entity_type: 'dashboard',
        entity_id: dashboardId,
        admin_responder: adminId,
        access_level_requested: accessLevel,
      });

      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      return false;
    }
  }

  async respondToAccessRequest(requestId, approved) {
    const url = `${this.v2endpoint}/${requestId}/request-access-response`;
    const response = await AxiosService.put(url, {
      approved,
    });

    return response.data;
  }

  async getEmailInteractionStats(emailId, opts = {}) {
    const url = `${this.v2endpoint}/library-messages/interaction-stats-modal/${emailId}`;
    const response = await AxiosService.get(url, opts);
    return response.data;
  }
}

const instance = new DashboardService();
Object.freeze(instance);

export default instance;
