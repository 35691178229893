import { useMemo } from 'react';
import { getColumnsPayload } from '../helpers';

export const useDirtyState = (initialSettings, rightItems, leftItems) => {
  return useMemo(() => {
    const nextSettings = getColumnsPayload(rightItems, leftItems);
    const initialVisibles = initialSettings.filter(({ visible }) => visible);
    const nextVisibles = nextSettings.filter(({ visible }) => visible);

    return (
      initialVisibles.length !== nextVisibles.length ||
      initialVisibles.some(({ id }, i) => id !== nextVisibles[i].id)
    );
  }, [initialSettings, rightItems, leftItems]);
};
