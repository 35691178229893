import { useRef } from 'react';
import { colorsButton } from 'app/colors';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import TextInput from 'components/Inputs/TextInput';
import TextInputOverlay from 'components/Inputs/TextInputOverlay';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { BadgeContainer } from 'components/Kizen/IconButtonWithBadge';
import { useTranslation } from 'react-i18next';
import { CustomBadgeText, SearchWrapper } from './styles';

export const PopoverSearch = ({
  showSearch,
  setShowSearch,
  resultsCount,
  inputSearchProps,
  inline,
  color = colorsButton.blue,
  activeColor = colorsButton.blue,
  icon = 'search',
  size = '15px',
  title,
}) => {
  const { t } = useTranslation();
  const searchRef = useRef(null);
  return (
    <SearchWrapper ref={searchRef} inline={inline}>
      <IconButton
        title={title ?? t('Find Events')}
        sizing="dense"
        color={showSearch ? activeColor : color}
        onClick={() => setShowSearch(true)}
        style={{ position: 'relative' }}
      >
        <IconSizing size={size}>
          <Icon icon={icon} />
        </IconSizing>
        {resultsCount ? (
          <BadgeContainer
            small={inline}
            color={showSearch ? activeColor.default : color.default}
          >
            <CustomBadgeText size="tiny">{1}</CustomBadgeText>
          </BadgeContainer>
        ) : null}
      </IconButton>
      <TextInputOverlay
        show={showSearch}
        target={searchRef.current}
        onHide={() => {
          setShowSearch(false);
        }}
        placement="bottom-end"
        sizing="mobile"
      >
        <TextInput
          autoFocus
          {...inputSearchProps}
          endAdornment={<IconAdornment icon="search" />}
          placeholder={t('Search')}
          sizing="mobile"
        />
      </TextInputOverlay>
    </SearchWrapper>
  );
};
