import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Cols from 'components/Layout/Cols';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import RangeInput, { FORMAT_TYPES } from 'components/Kizen/RangeInput';
import Subsection from 'components/Wizards/CustomObject/components/Subsection';
import { SUMMARY_VALUES } from 'store/customObjectsRecordsPage/records.redux';

const StyledRangeInput = styled(RangeInput)`
  && {
    padding-bottom: 10px;
    & > span {
      margin-bottom: 17px;
    }
    [data-reach-slider-input][data-orientation='horizontal'] {
      width: 525px;
      max-width: 525px;
      margin-left: 0;
    }
    [data-reach-slider-marker][data-value='400'] > span {
      right: 20px;
      left: 10px;
    }
  }
`;
const StyledSubsection = styled(Subsection)`
  margin-top: -1px; //design nudge

  & > h3 {
    margin-bottom: 15px;
  }
`;

const SLIDER_PARAMS = {
  MIN: 200,
  MAX: 400,
  STEP: 1,
};

const StageSettingsSubsection = ({
  trackValue,
  values,
  onChange,
  numericCustomFields,
  isMobile,
  errors,
  ...props
}) => {
  const { t } = useTranslation();
  const handleChangeSummaryMetric = (opt) => {
    onChange({
      ...values,
      summaryMetric: opt.value,
      summaryExplanation:
        opt.value === SUMMARY_VALUES.numberOfRecords ? t('Records') : '',
    });
  };
  const handleChangeSecondarySummaryMetric = (opt) => {
    onChange({
      ...values,
      secondarySummaryMetric: opt.value,
      secondarySummaryExplanation:
        opt.value === SUMMARY_VALUES.numberOfRecords ? t('Records') : '',
    });
  };

  const selectOptions = useMemo(() => {
    return [
      { label: t('No Summary'), value: SUMMARY_VALUES.noSummary },
      { label: t('# Records'), value: SUMMARY_VALUES.numberOfRecords },
      trackValue && {
        label: t('Total $ Value'),
        value: SUMMARY_VALUES.totalValue,
      },
      ...numericCustomFields,
    ].filter(Boolean);
  }, [trackValue, numericCustomFields, t]);

  const handleChangeExplanation = (data) => {
    onChange({ ...values, summaryExplanation: data });
  };

  const handleChangeSecondaryExplanation = (data) => {
    onChange({ ...values, secondarySummaryExplanation: data });
  };
  const showExplanation = useMemo(() => {
    return (
      values.summaryMetric &&
      ![SUMMARY_VALUES.noSummary].includes(values.summaryMetric)
    );
  }, [values.summaryMetric]);

  const showSecondaryExplanation = useMemo(() => {
    return (
      values.secondarySummaryMetric &&
      ![SUMMARY_VALUES.noSummary].includes(values.secondarySummaryMetric)
    );
  }, [values.secondarySummaryMetric]);

  return (
    <StyledSubsection title={t('Customize Board View Layout')} {...props}>
      <Cols columns={2} gutter="20px">
        <Select
          margin
          label={t('Primary Stage Summary Metric')}
          labelInfo={t(
            'The summary metric that will show on the stage header.'
          )}
          labelInfoPlacement="top"
          value={
            showExplanation === undefined
              ? SUMMARY_VALUES.noSummary
              : values.summaryMetric
          }
          onChange={handleChangeSummaryMetric}
          options={selectOptions}
        />
        {showExplanation ? (
          <TextInput
            margin={isMobile ? 0 : true}
            label={t('Summary Explanation')}
            labelInfo={t(
              'What will display after the summary (e.g. 101 Deals).'
            )}
            labelInfoPlacement="top"
            placeholder={t('Enter Summary Explanation')}
            value={values.summaryExplanation}
            onChange={handleChangeExplanation}
            inModal
            validate={
              errors?.summaryExplanation && {
                message: errors.summaryExplanation,
                showMessage: true,
                inModal: true,
              }
            }
          />
        ) : null}
      </Cols>
      <Cols columns={2} gutter="20px">
        <Select
          margin
          label={t('Secondary Stage Summary Metric')}
          labelInfo={t(
            'The summary metric that will show on the stage header.'
          )}
          labelInfoPlacement="top"
          value={
            showSecondaryExplanation === undefined
              ? SUMMARY_VALUES.noSummary
              : values.secondarySummaryMetric
          }
          onChange={handleChangeSecondarySummaryMetric}
          options={selectOptions}
        />
        {showSecondaryExplanation ? (
          <TextInput
            margin={isMobile ? 0 : true}
            label={t('Summary Explanation')}
            labelInfo={t(
              'What will display after the summary (e.g. 101 Deals).'
            )}
            labelInfoPlacement="top"
            inModal
            placeholder={t('Enter Summary Explanation')}
            value={values.secondarySummaryExplanation}
            onChange={handleChangeSecondaryExplanation}
            validate={
              errors?.secondarySummaryExplanation && {
                message: errors.secondarySummaryExplanation,
                showMessage: true,
                inModal: true,
              }
            }
          />
        ) : null}
      </Cols>
      {!isMobile && (
        <StyledRangeInput
          label={t('Stage Column Widths')}
          value={values.columnWidth}
          min={SLIDER_PARAMS.MIN}
          max={SLIDER_PARAMS.MAX}
          step={SLIDER_PARAMS.STEP}
          onChange={(data) => {
            if (values.columnWidth !== data) {
              onChange({ ...values, columnWidth: data });
            }
          }}
          tooltipValue={values.columnWidth}
          onTooltipChange={(data) => onChange({ ...values, columnWidth: data })}
          formatMarker={FORMAT_TYPES.PIXELS}
        />
      )}
    </StyledSubsection>
  );
};

export default StageSettingsSubsection;
