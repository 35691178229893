import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import Switch from 'components/Kizen/Switch';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import ButtonGroup from 'components/Inputs/ButtonGroup';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import { VALUE_METRICS } from '../../../types';
import { allowedNumberFieldTypes } from '../../../../utils';
import { CHART_TYPES } from 'components/Wizards/shared/components/chart/types';
import { isPercentageChanceToCloseField } from 'checks/fields';

const StyledSwitch = styled.div`
  display: flex;
  align-items: center;

  .switch {
    margin-left: ${gutters.spacing(2)}px;
  }
`;

const StyledFieldWrapper = styled.div`
  display: flex;
  gap: 0 ${gutters.spacing(2)}px;
  width: 100%;

  && > div {
    width: calc(50% - 5px);
  }
`;

export const Metrics = ({
  display,
  mobile,
  objectFields,
  metric,
  metricField,
  includeSummary,
  summaryExplanation,
  onChangeMetrics,
  onChangeMetricField,
  onChangeSummaryToggle,
  onChangeSummaryExplanation,
}) => {
  const { t } = useTranslation();

  const handleChangeSummaryToggle = useCallback(
    (e) => {
      onChangeSummaryToggle(e.target.checked);
    },
    [onChangeSummaryToggle]
  );

  const handleChangeMetrics = (opt) => {
    onChangeMetrics(opt.value);
  };

  const handleChangeNumericField = ({ value }) => {
    onChangeMetricField(value);
  };

  const metricOptions = useMemo(
    () => [
      {
        value: VALUE_METRICS.COUNT,
        label: t('Record Count'),
      },
      {
        value: VALUE_METRICS.NUMBER_FIELD,
        label: t('Number Field'),
      },
    ],
    [t]
  );

  const fieldOptions = useMemo(
    () =>
      objectFields.reduce((acc, item) => {
        if (
          allowedNumberFieldTypes.includes(item.fieldType) &&
          !isPercentageChanceToCloseField(item)
        ) {
          return [
            ...acc,
            {
              value: item.id,
              label: item.displayName,
            },
          ];
        }

        return acc;
      }, []),
    [objectFields]
  );

  const isDonutChartType = display === CHART_TYPES.DONUT;

  return (
    <div>
      {isDonutChartType && (
        <StyledSwitch>
          <StyledWizardField top={gutters.spacing(1)}>
            <KizenTypography>{t('Include Summary')}</KizenTypography>
          </StyledWizardField>
          <Switch
            checked={includeSummary}
            onChange={handleChangeSummaryToggle}
            className="summary-switch"
          />
        </StyledSwitch>
      )}
      <StyledWizardField
        top={isDonutChartType ? gutters.spacing(1, -2) : gutters.spacing(3, -1)}
      >
        <KizenTypography type="subheader">
          {t('Metric to Display')}
        </KizenTypography>
        <StyledWizardField top={gutters.spacing(1, 1)}>
          <ButtonGroup
            value={metric}
            onChange={handleChangeMetrics}
            sizing={!mobile ? 'auto' : ''}
            options={metricOptions}
            className="wizard-buttons metric-to-display"
            columns={mobile ? 2 : 4}
            mode="wizard"
            gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
          />
        </StyledWizardField>
      </StyledWizardField>
      {metric === VALUE_METRICS.NUMBER_FIELD && (
        <StyledWizardField top={gutters.spacing(1, -2)}>
          <StyledFieldWrapper>
            <Select
              label={t('Field to Display')}
              value={metricField}
              onChange={handleChangeNumericField}
              captureMenuScroll={true}
              className="modal-select field-analyze"
              placeholder={t('Choose Field')}
              options={fieldOptions}
              fullWidth
            />
            {isDonutChartType && includeSummary ? (
              <TextInput
                fullWidth
                label={t('Summary Explanation')}
                labelInfo={t(
                  'What will display after the summary (e.g. 101 Deals).'
                )}
                className="modal-select summary-explanation"
                placeholder={t('Enter Summary Explanation')}
                value={summaryExplanation}
                onChange={onChangeSummaryExplanation}
              />
            ) : (
              <div />
            )}
          </StyledFieldWrapper>
        </StyledWizardField>
      )}
    </div>
  );
};
