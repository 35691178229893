import { useCallback, useState } from 'react';

/**
 * Just need to use some external state for Dnd Columns
 * @param prefix
 * @returns {[number,(function(): void)]}
 */
export const useForceUpdate = (prefix = '') => {
  const [key, setKey] = useState(0);
  const forceUpdate = useCallback(() => setKey((value) => value + 1), []);
  return [key, forceUpdate];
};
