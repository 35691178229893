import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell as KizenSizedCell,
  SortableHeadCell as KizenSortableHeadCell,
  HeaderSizedCell as KizenHeaderSizedCell,
} from 'components/Kizen/Table';

// Small alignment tweak, esp to align correctly with action button
export const SizedCell = styled(KizenSizedCell)`
  > div {
    line-height: 0;
  }
`;

// Small alignment tweak, esp to align correctly with action button
export const SortableHeadCell = styled(KizenSortableHeadCell)`
  > div {
    line-height: 0;
  }
`;
// Small alignment tweak, esp to align correctly with action button
export const HeaderSizedCell = styled(KizenHeaderSizedCell)`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
  }
`;

export const ActionSizedCell = styled(KizenSizedCell)`
  > div {
    line-height: 0;
    text-align: right;
  }
`;

export const SubActionHeadCell = styled(({ label, ...props }) => {
  const { t } = useTranslation();
  return (
    <SizedCell {...props}>
      <TextEllipsisWithTooltip as="span" weight="bold">
        {label || t('Actions')}
      </TextEllipsisWithTooltip>
    </SizedCell>
  );
})`
  text-align: right;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const StyledFolder = styled.div`
  display: flex;
  align-items: center;
  && > i {
    margin-right: 10px;
  }
`;
