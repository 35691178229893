import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';

export const URLItemWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const URLToolsWrapper = styled.div`
  margin-left: auto;
  ${({ child }) =>
    child
      ? css`
          margin-right: ${gutters.spacing(2)}px;
        `
      : ''}

  &&& i {
    color: ${grayScale.mediumDark};
  }

  &&& i:hover {
    color: ${grayScale.dark};
  }
`;
