import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Text } from './styles';

const NoData = (props) => {
  const { t } = useTranslation();
  return (
    <Wrapper {...props}>
      <Text type="hero">{t('No Data')}</Text>
    </Wrapper>
  );
};

export default NoData;
