import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { borderRadii, scrollbarCss, gutters, layers } from 'app/spacing';
import {
  grayScale,
  tableHover,
  colorsButton,
  appBackground,
  shadowLight,
} from 'app/colors';
import { Table as KizenTable, TRow } from 'components/Kizen/Table';
import {
  useEndsOfYScroll,
  useScrollThreshold,
  GradientBottom,
  GradientRight,
  Loader,
  TableText,
} from './helpers';
import useWidthMeasurement from 'hooks/useWidthMeasurement';
import { useEffect } from 'react';
import { mergeRefs } from '@kizen/kds/util';

const HEADER_HEIGHT = 52;
const ROW_HEIGHT = 40;

const Table = styled(KizenTable)`
  position: relative;
  border-collapse: separate; // Ensures bottom border on sticky th doesn't scroll away
  tr:not(.collapsible) {
    height: ${ROW_HEIGHT}px;
  }

  tr.virtual td {
    height: ${ROW_HEIGHT}px;
  }

  && thead {
    tr {
      height: ${HEADER_HEIGHT}px;
      z-index: ${layers.content(0, 2)};
      th {
        vertical-align: middle;
        border-bottom: 1px solid ${grayScale.mediumLight};
        ${({ inModal }) =>
          inModal &&
          css`
            border-top: 1px solid transparent; // stop the pop on hover
          `}
      }
      th:not(.Resizing) .ResizerLayout {
        display: none;
      }
      &:hover th .ResizerLayout {
        display: flex;
      }
    }
  }

  tbody {
    tr:hover {
      background-color: ${tableHover};
    }

    tr.selected {
      background-color: ${appBackground};
      a {
        cursor: default;
        text-decoration: none;

        :hover,
        :focus {
          color: ${colorsButton.blue.default};
        }
      }
      &&:hover {
        background-color: ${appBackground};
      }
    }

    tr.collapsible:hover {
      background-color: ${grayScale.white};
    }

    // Make last menu open upwards
    tr:last-of-type .IconButtonMenu__menu {
      transform: translateY(-100%) translateY(-24px) translateX(-100%);
    }

    tr.collapsible > td:first-child > * {
      background: ${appBackground};
      > * {
        background: ${appBackground};
        padding-left: 40px;
        margin-left: -70px;
      }
    }
  }
`;

const TableScrollWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  margin-right: ${({ inModal }) => (inModal ? gutters.spacing(0, -5) : 0)}px;

  &.in-modal {
    flex-basis: auto;
    flex: 1;
    height: 100%;
    overflow-y: scroll;
    border-radius: ${borderRadii.small};
    border: solid 1px ${grayScale.medium};
    table {
      width: 100%;
    }
  }
  overflow-y: auto;
  ${scrollbarCss({ track: false })}
  &::-webkit-scrollbar-track {
    // A hack for scrollbar to appear below sticky header.
    // Much better than the alternative hacks to detach/size/scroll the header,
    // especially since we already have a custom scrollbar.
    margin-top: calc(${HEADER_HEIGHT}px - 1px);
  }
  table {
    width: 100%;
  }
  thead th {
    position: sticky;
    top: 0;
    background-color: ${grayScale.white};
    z-index: ${layers.content(
      0,
      1
    )}; // For some reason the editor toolbar was appearing on top of these
  }

  ${({ headerShadow }) =>
    headerShadow
      ? css`
          thead {
            position: sticky;
            top: 0;
            background: white;
            ${shadowLight};
            z-index: ${layers.content(0, 9)};
          }
        `
      : ''}

  ${({ virtualized }) =>
    virtualized
      ? css`
          thead {
            position: sticky;
            z-index: ${layers.content(0, 9)};
          }
        `
      : ''}
`;

export default function LazyLoadTable({
  className,
  loading,
  fetchingNextPage,
  columns,
  headData,
  onScrollThreshold,
  data,
  noDataMessage,
  children,
  loaderSize = 50,
  noLoaderPadding = false,
  showNotesId,
  inModal,
  onChangeScrollEnds,
  tableBodyRef,
  virtualScrollerRef,
  virtualized = false,
  ...others
}) {
  const {
    ref: wrapperRef,
    plainRef: tableScrollRef,
    width,
  } = useWidthMeasurement();
  const { ref: tableRef, width: tableWidth } = useWidthMeasurement();

  // Depend on templates so we calculate scroll bottom when layout's data and thereby its height updates
  const [scrolledTop, scrolledBottom] = useEndsOfYScroll(tableScrollRef, [
    data,
  ]);
  useScrollThreshold(tableScrollRef, [data], onScrollThreshold);

  useEffect(() => {
    onChangeScrollEnds?.({ scrolledTop, scrolledBottom });
  }, [scrolledTop, scrolledBottom, onChangeScrollEnds]);

  const noScrollBar =
    tableScrollRef.current?.scrollHeight ===
    tableScrollRef.current?.clientHeight;

  return (
    <>
      <TableScrollWrapper
        className={className}
        ref={mergeRefs(wrapperRef, virtualScrollerRef)}
        inModal={inModal}
        headerShadow={!scrolledTop}
        noScrollBar={noScrollBar}
        virtualized={virtualized}
      >
        <Table
          head={<TRow head columns={columns} data={headData} {...others} />}
          inModal={inModal}
          ref={tableRef}
          tableBodyRef={tableBodyRef}
        >
          {!loading && children}
        </Table>
        {noDataMessage && !loading && data && data.length === 0 && (
          <TableText color={grayScale.mediumDark}>{noDataMessage}</TableText>
        )}
        <Loader
          loading={fetchingNextPage || loading}
          noPadding={noLoaderPadding || (inModal && loading)}
          size={loaderSize}
        />
      </TableScrollWrapper>
      <GradientBottom
        fadeIn={!scrolledBottom && !noScrollBar}
        inModal={inModal}
        scrollable={tableWidth > width}
      />
      {!virtualized ? <GradientRight fadeIn inModal={inModal} /> : null}
    </>
  );
}

LazyLoadTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  fetchingNextPage: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  headData: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  noDataMessage: PropTypes.string,
  onScrollThreshold: PropTypes.func.isRequired,
  loaderSize: PropTypes.number,
  noLoaderPadding: PropTypes.bool,
  inModal: PropTypes.bool.isRequired,
};

LazyLoadTable.defaultProps = {
  noDataMessage: '',
  loaderSize: 50,
  noLoaderPadding: false,
};
