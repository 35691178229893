import { forwardRef } from 'react';

import Select from 'components/Inputs/Select';
import DropdownInlineField from 'components/Fields/InlineTableFieldInput/Dropdown';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';

import { withCellWrapper } from '../helpers';

import { getDataQAForInput } from 'components/Inputs/helpers';

const _StageCell = forwardRef(
  (
    {
      field,
      object: record,
      onSubmit,
      value: valueProp, // remove from orhers as we get value from record
      objectId: modelId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      editable,
      onAutoFocus,
      meta,
      ...others
    },
    ref
  ) => {
    const value = record.stage;
    return (
      <DropdownInlineField
        ref={ref}
        {...others}
        value={value}
        onSubmit={async (data) => {
          await onSubmit(record.id, { stageId: data }, column);
        }}
        readOnly={!editable}
        onAutoFocus={onAutoFocus}
        field={field}
        object={record}
        {...getDataQAForInput(
          `inline-record-field-${field.name}`,
          field.fieldType
        )}
      >
        <Select
          menuLeftButton={field.isRequired ? null : menuLeftButton}
          menuRightButton={
            menuRightButton === undefined ? (
              <ApplySelectOverlayButton />
            ) : (
              menuRightButton
            )
          }
        />
      </DropdownInlineField>
    );
  }
);

export const StageCell = withCellWrapper(_StageCell);
