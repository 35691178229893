import React from 'react';
import { css } from '@emotion/core';
import { breakpoints, gutters } from 'app/spacing';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import { fontSizes, fontWeights, truncateCss } from 'app/typography';
import Button from 'components/Button';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';

export const ButtonGroupButtonBase = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${grayScale.medium};
  border-radius: 4px;
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.text};
  ${truncateCss};
  background-color: ${grayScale.white};

  &:focus {
    outline: none;
    border-color: ${colorsButton.blue.hover};
  }

  ${({ selected }) =>
    selected
      ? css`
          color: ${colorsButton.blue.hover};
          border-color: ${colorsButton.blue.hover};
          &:hover {
            border-color: ${colorsButton.blue.hover};
            background-color: ${grayScale.white};
            color: ${colorsButton.blue.hover};
          }
        `
      : css`
          &:hover {
            border-color: ${colorsButton.blue.hover};
            background-color: ${grayScale.white};
            color: ${grayScale.mediumDark};
          }
        `}

  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: ${gutters.spacing(4)}px;
`;

const smButtonWidth = '202px';
const lgButtonWidth = '292px';
const xlButtonWidth = '236px';

const RequestButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(2, ${smButtonWidth});
  }
  @media (min-width: ${breakpoints.lg}px) {
    grid-template-columns: repeat(2, ${lgButtonWidth});
  }
  @media (min-width: ${'1200'}px) {
    grid-template-columns: repeat(2, ${xlButtonWidth});
  }

  grid-column-gap: ${gutters.spacing(4)}px;
`;

export const SimpleBuilderButtons = ({ value, onChange, options }) => {
  return (
    <RequestButtonsWrapper>
      {options.map((option) => {
        return (
          <ButtonGroupImageButton
            variant={'outline'}
            key={option.value}
            selected={option.value === value}
            onClick={() => onChange(option)}
            option={option}
            data-qa-button={option.value}
          >
            {option.label}
          </ButtonGroupImageButton>
        );
      })}
    </RequestButtonsWrapper>
  );
};
