import { useState, useMemo, useCallback } from 'react';
import {
  Route,
  Switch,
  useParams,
  useHistory,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { invalidate } from 'queries/invalidate';
import styled from '@emotion/styled';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import { useDebouncedPastScrollThreshold } from 'hooks/useDebouncedWindowScroll';
import { FILTER_MENU_Z_INDEX } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import FieldService from 'services/FieldService';
import Automation2Service from 'services/Automation2Service';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';
import LoggableActivityService from 'services/LoggableActivityService';
import { isPipelineObject } from 'components/Modals/utilities';
import {
  StickyNavWrapper,
  StickyNavMobileWrapper,
  StyledSubNav,
} from 'pages/Common/styles';
import { getFieldsForUI } from 'store/customObjectsRecordsPage/saga';
import { useToast } from 'components/ToastProvider';
import { getToastConfig } from 'pages/ContactDetail/toastConfig';
import getRoutes from './routes';
import { useRouteTrigger, useTimelineCachePrune } from 'hooks/useRouteTrigger';
import { ControlBar } from './ControlBar';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useSelector } from 'react-redux';
import { getCustomObjectConfig } from 'store/recordDetailPage/record.redux';
import { DEFAULT_FILTER_CONFIG } from 'components/Timeline2/useTimelineInteraction';
import { usePartialLayoutControl } from 'ts-components/RecordLayout/usePartialLayoutControl';
import { useAutomationPermissions } from 'ts-components/hooks/permissions/automations';
import { ScrollTopButton } from 'ts-components/ScrollTopButton';
import { useQuery } from 'react-query';
import { CUSTOM_OBJECTS, CUSTOM_RECORDS } from 'queries/query-keys';
import { monitoringExceptionHelper } from 'sentry/helpers';

const StyledStickyNavWrapper = styled(StickyNavWrapper)`
  z-index: ${FILTER_MENU_Z_INDEX + 3};
`;

export const fieldMapper = (field) => {
  // for each of these entries we reach into the associated object to get the value
  const valuesFromObject = new Map([
    ['entityValue', 'amount'],
    ['stage', 'id'],
  ]);

  return Object.keys(field).reduce((acc, item) => {
    const key = valuesFromObject.get(item);
    return {
      ...acc,
      [item]: key ? field[item] && field[item][key] : field[item], // make sure that field[item] exist before using it
    };
  }, {});
};

const setRecordTitle = (title, { objectName = '', entityName = '' }) =>
  (entityName && objectName ? `${entityName} - ${objectName} - ` : '') + title;

const defaultPageConfig = {
  timeline: {
    filter: DEFAULT_FILTER_CONFIG,
  },
};

const CustomObject = ({ title }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { id, objectId } = params;
  const scrolled = useDebouncedPastScrollThreshold();

  const [noViewPermissions, setNoViewPermissions] = useState(false);
  const [showToast] = useToast();
  const toastConfig = getToastConfig(t, history);
  const { detailPageConfig = defaultPageConfig } = useSelector(
    getCustomObjectConfig(objectId)
  );
  const invalidateTimelineRoutes = useMemo(() => {
    return Object.values(getRoutes(t)).filter((r) => r.invalidateTimeline);
  }, [t]);

  const timelineCachePrune = useTimelineCachePrune();
  useRouteTrigger(invalidateTimelineRoutes, () => {
    timelineCachePrune(id);
    invalidate.TIMELINE.RECORD(id);
  });

  const { data: customObjectModel } = useQuery({
    queryKey: CUSTOM_OBJECTS.MODEL(objectId),
    queryFn: async () => {
      try {
        return await FieldService.getModel({
          id: objectId,
        });
      } catch (err) {
        monitoringExceptionHelper(err);
        const data = err.isAxiosError && err.response && err.response.data;
        const badPermissions = data && data.badPermissions;
        if (badPermissions) {
          setNoViewPermissions(true);
          showToast(toastConfig.general.noPermission);
        }
        return {};
      }
    },
  });

  const {
    data: customObjectData,
    isLoading: primaryLoad,
    refetch: refetchEntity,
  } = useQuery({
    queryKey: CUSTOM_OBJECTS.RECORD(objectId, id),
    queryFn: async () => {
      try {
        const record = isPipelineObject(customObjectModel)
          ? await PipelineService.getPipelineRecord({
              id,
              objectId,
            })
          : await CustomObjectsService.getCustomObjectRecord({
              id,
              objectId,
            });

        return record;
      } catch (err) {
        monitoringExceptionHelper(err);
        const data = err.isAxiosError && err.response && err.response.data;
        const badPermissions = data && data.badPermissions;
        if (badPermissions) {
          setNoViewPermissions(true);
          showToast(toastConfig.general.noPermission);
        }
        return {};
      }
    },
    enabled: Boolean(customObjectModel),
  });

  const customObject = useMemo(() => {
    if (!customObjectData) {
      return {};
    }

    return {
      ...fieldMapper(customObjectData),
      fields: getFieldsForUI(customObjectData.fields),
    };
  }, [customObjectData]);

  const { data: activityList = DROPDOWN_SHOULD_LOAD } = useQuery({
    queryKey: CUSTOM_RECORDS.LOGGABLE_ACTIVITY_LIST(objectId),
    queryFn: () => {
      return LoggableActivityService.getActivityFullList({
        customObjectId: objectId,
        ordering: 'name',
        detail: 'light',
      });
    },
    enabled: Boolean(objectId),
  });

  useSetTitleOnLoad(
    title(t),
    {
      entityName: customObject?.name,
      objectName: customObjectModel?.objectName,
    },
    setRecordTitle
  );

  const [isDeleted, setIsDeleted] = useState(false);

  const logActivityActions = {
    update: refetchEntity,
    save: refetchEntity,
  };

  const handleDelete = useCallback(async () => {
    // we need deleted to be true to leave the page if the form is dirty
    if (isPipelineObject(customObjectModel)) {
      await PipelineService.archiveCustomObjectRecord({
        recordId: id,
        modelId: objectId,
      });
    } else {
      await FieldService.deleteCustomModelRecord({
        id,
        modelId: objectId,
      });
    }

    setIsDeleted(true);
  }, [customObjectModel, id, objectId]);

  if (isDeleted) {
    history.push(`/custom-objects/${customObjectModel.id}`);
  }

  const onStartAutomation = useCallback(
    async (automationId) => {
      try {
        await Automation2Service.start({
          automationId,
          recordId: id,
        });
        showToast(toastConfig.automation.success);
      } catch (error) {
        showToast(toastConfig.automation.error);
      }
    },
    [id, showToast, toastConfig.automation]
  );

  const routes = useMemo(() => getRoutes(t), [t]);

  const routeKeys = Object.keys(routes);
  const routeComponents = routeKeys.map((routeKey) => {
    const route = routes[routeKey];
    return (
      <Route
        key={routeKey}
        path={route.path}
        exact={route.exact !== false}
        render={() => (
          <route.component
            entity={customObject} // Needed for automations
            onStartAutomation={onStartAutomation} // Needed for automations
            customObjectId={objectId} // Needed for automations
          />
        )}
      />
    );
  });

  const { layoutOptions, selectedLayoutOption } = usePartialLayoutControl(
    customObjectModel,
    detailPageConfig
  );

  const { canStart } = useAutomationPermissions({
    objectId,
    entityObject: customObject,
  });

  if (location.pathname.endsWith('/deprecated-details')) {
    return <Redirect to={`/custom-objects/${objectId}/${id}/details`} />;
  }

  if (
    primaryLoad ||
    (!customObject && !noViewPermissions) ||
    (customObject && customObject.id !== id)
  ) {
    return <Loader loading />;
  }
  return (
    <>
      <ControlBar
        customObject={customObject}
        customObjectModel={customObjectModel}
        routes={routes}
        params={params}
        hasUnsavedChanges={false}
        // This button is a noop on any page but the detail page, at least for the time being
        onSubmit={() => null}
        onDelete={handleDelete}
        onStartAutomation={onStartAutomation}
        logActivityActions={logActivityActions}
        activityList={activityList}
        defaultOnActivities={!!customObjectModel.defaultOnActivities}
        layoutOptions={layoutOptions}
        selectedLayout={selectedLayoutOption}
        setSelectedLayout={(newLayout) => {
          history.push(
            `/custom-objects/${objectId}/${id}/details?layout=${newLayout.value}`
          );
        }}
        canStartAutomations={canStart}
      />
      <StyledStickyNavWrapper scrolled={scrolled}>
        <StickyNavMobileWrapper routes={routes}>
          <StyledSubNav routes={routes} params={params} />
        </StickyNavMobileWrapper>
      </StyledStickyNavWrapper>
      <Switch>{routeComponents}</Switch>
      <ScrollTopButton />
    </>
  );
};

export default CustomObject;
