import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TextInput from '..';
import { urlValidation } from 'utility/validate';

const UrlTextInput = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const validate = useMemo(() => {
    return {
      full: (value) => {
        const error = urlValidation(t)(value);
        if (value && error !== true) {
          return error;
        }

        return null;
      },
    };
  }, [t]);

  return (
    <TextInput
      ref={ref}
      {...props}
      validate={{ ...validate, ...props.validate }}
      placeholder={props.placeholder || t('Enter URL Here')}
    />
  );
});

export default UrlTextInput;
