import { useRef } from 'react';
import { Text, TextSpan } from 'app/typography';
import {
  StyledDiv,
  StyledLabel,
  StyledSmallLabel,
  StyledInput,
  StyledSpan,
  StyledInfoIcon,
  StyledLabelWrapper,
} from './styles';
import { useTooltip } from 'components/Kizen/Tooltip';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const Switch = ({
  fieldId,
  disabled = null,
  checked = null,
  onChange,
  value = '',
  label = null,
  tooltip = '',
  tooltipPlacement = 'left',
  textPlacement = 'right',
  backgroundColor = null,
  tooltipMaxWidth,
  onFocus,
  onBlur,
  removeMargin = false,
  removeLabelMargin = false,
  onClick,
  ...props
}) => {
  const { assignFieldHandle } = useKeyBoardContext();
  const [tooltipProps, tooltipLabel] = useTooltip({
    label: tooltip,
    maxWidth: tooltipMaxWidth,
  });
  const ref = useRef();
  assignFieldHandle(fieldId, {
    customFocus: () => {
      ref.current?.focus();
      return ref.current;
    },
    disabled,
  });

  return (
    <StyledDiv
      textPlacement={textPlacement}
      removeMargin={removeMargin}
      {...props}
    >
      <StyledLabelWrapper removeLabelMargin={removeLabelMargin}>
        {textPlacement === 'left' && <Text>{label}</Text>}
        {textPlacement === 'top' && (
          <StyledSmallLabel>{label}</StyledSmallLabel>
        )}
        {textPlacement === 'regular-top' && <TextSpan>{label}</TextSpan>}
        {tooltip && tooltipPlacement === 'left' ? (
          <>
            {tooltipLabel}
            <StyledInfoIcon
              icon="info-circle"
              {...tooltipProps}
              textPlacement={textPlacement}
            />
          </>
        ) : null}
      </StyledLabelWrapper>
      <StyledLabel
        label={label}
        textPlacement={textPlacement}
        removeMargin={removeMargin}
      >
        <StyledInput
          ref={ref}
          aria-checked={checked}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          value={value}
          backgroundColor={backgroundColor}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        />
        <StyledSpan disabled={disabled} />
      </StyledLabel>
      {tooltip && tooltipPlacement === 'right' ? (
        <>
          {tooltipLabel}
          <StyledInfoIcon
            icon="info-circle"
            {...tooltipProps}
            textPlacement={textPlacement}
            tooltipPlacement={tooltipPlacement}
          />
        </>
      ) : null}
      {textPlacement === 'right' && <Text>{label}</Text>}
    </StyledDiv>
  );
};

export default Switch;
