import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import ButtonGroup from 'components/Inputs/ButtonGroup';
import MultiSelect from 'components/Inputs/MultiSelect';
import {
  DISPLAY_VALUE_RESPONSES,
  REPORT_TYPES,
} from 'components/Wizards/Dashlet/types';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import useLeadSourceOptions from 'components/Wizards/Dashlet/hooks/useLeadSourceOptions';
import useObjectOptions from 'components/Wizards/Dashlet/hooks/useObjectOptions';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const hasTrackEntityValue = (object) => object?.trackEntityValue;

const LeadsAddedBySourceReportType = ({ mobile, bySource = false }) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);
  const {
    reportType,
    value,
    objectsWithLeads,
    sourcesToBreakDown,
    currencySymbol,
  } = state;

  const displayValueRecords = useMemo(() => {
    return {
      label: t('# Records'),
      value: DISPLAY_VALUE_RESPONSES.RECORDS,
    };
  }, [t]);

  const clientObject = useSelector(getBusinessClientObject);

  const clientObjectId = clientObject?.id;

  const { selectedObjectsMetadata, ...objectsSelectProps } = useObjectOptions({
    useClient: true,
    enabled: Boolean(clientObjectId),
    clientObjectId,
  });

  const leadSourceSelectProps = useLeadSourceOptions({ enabled: bySource });
  useMetadataKey('isLoadingBlockingSave', objectsSelectProps.isLoading);

  const hasAnyValueData = useMemo(() => {
    return selectedObjectsMetadata.some((obj) => hasTrackEntityValue(obj));
  }, [selectedObjectsMetadata]);

  const valueOptions = useMemo(() => {
    const result = [displayValueRecords];

    if (hasAnyValueData) {
      result.push({
        label: `${currencySymbol ?? ''} ${t('Value')}`,
        value: DISPLAY_VALUE_RESPONSES.VALUE,
      });
    }

    return result;
  }, [hasAnyValueData, currencySymbol, displayValueRecords, t]);

  const objectsOnChange = useCallback(
    (value) => {
      setState('objectsWithLeads', value);
      setState('customFilters', undefined);
      setState('inGroupFilters', undefined);
      setState('notInGroupFilters', undefined);
    },
    [setState]
  );

  const sourcesOnChange = useCallback(
    (value) => {
      setState('sourcesToBreakDown', value);
    },
    [setState]
  );

  // Hide self if the report type is not leads added (or leads added by source if bySource)
  if (
    bySource
      ? reportType?.value !== REPORT_TYPES.LEADS_ADDED_BY_SOURCE
      : reportType?.value !== REPORT_TYPES.LEADS_ADDED
  ) {
    return null;
  }

  return (
    <>
      <StyledWizardField top={gutters.spacing(2, 2)}>
        <KizenTypography type="subheader">
          {t('Choose Objects that Have Records Qualifying as Leads')}
        </KizenTypography>
        <MultiSelect
          placeholder={t('Find Object(s)')}
          className={`modal-multiselect objects ${
            objectsSelectProps.isLoading ? 'loading' : 'ready'
          }`}
          value={objectsWithLeads}
          onChange={objectsOnChange}
          {...objectsSelectProps}
        />
      </StyledWizardField>
      {bySource ? (
        <StyledWizardField top={gutters.spacing(6)}>
          <KizenTypography type="subheader">
            {t('Choose Lead Sources to Breakdown')} {t('(Leave Blank for All)')}
          </KizenTypography>
          <MultiSelect
            placeholder={t('Find Lead Source(s)')}
            className="modal-multiselect lead-sources"
            value={sourcesToBreakDown}
            onChange={sourcesOnChange}
            {...leadSourceSelectProps}
            includeGroupPlaceholder={false}
            classNamePrefix="lead-sources"
          />
        </StyledWizardField>
      ) : null}

      <StyledWizardField top={gutters.spacing(6)}>
        <KizenTypography type="subheader">
          {t('Select Value to Display')}
        </KizenTypography>
        <StyledWizardField top={gutters.spacing(1, 1)}>
          <ButtonGroup
            value={value}
            onChange={(v) => setState('value', v)}
            sizing={!mobile ? 'auto' : ''}
            options={valueOptions}
            className="wizard-buttons value-to-display"
            columns={mobile ? 2 : 4}
            mode="wizard"
            gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
          />
        </StyledWizardField>
      </StyledWizardField>
    </>
  );
};

export default LeadsAddedBySourceReportType;
