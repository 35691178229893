import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { borderRadii, gutters, layers } from 'app/spacing';
import { colorsText, grayScale, shadowLight } from 'app/colors';

export const GridWrapper = styled.div`
  & .layout {
    margin-left: -${gutters.spacing(2)}px;
    margin-right: -${gutters.spacing(2)}px;
    margin-bottom: ${({ rowHeight }) => rowHeight}px;
    z-index: ${layers.content(0)};
    min-height: ${({ minHeight }) => minHeight}px;
  }

  & .react-draggable-dragging {
    // 11 gets us above most things, but the chart legend sits at 90
    z-index: 91;
  }

  ${({ enableTouchActions }) =>
    enableTouchActions
      ? css`
          & .react-grid-item {
            touch-action: revert !important;
          }
        `
      : ''}
`;

export const OverFlowContainer = styled.span`
  overflow: hidden;
`;

export const DashletCard = styled.div`
  border-radius: ${borderRadii.small};

  ${({ isHTML }) =>
    isHTML
      ? css`
          .dashlet-head {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: ${gutters.standard};
            padding-top: 11px;
            z-index: 15;
          }
        `
      : css`
          padding: ${gutters.standard};
          padding-top: 11px;
        `}

  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? grayScale.white};

  & .drag-handle {
    opacity: ${({ forceShowUI }) => (forceShowUI ? 1 : 0)};
    transition: opacity 0.1s;
    z-index: 20;
  }

  & .Resizer {
    opacity: 0;
    transition: opacity 0.1s;
  }

  &:hover .drag-handle,
  &:hover .Resizer {
    opacity: 1;
  }

  & .dashlet-filter {
    opacity: ${({ forceShowUI }) => (forceShowUI ? 1 : 0)};
    transition: opacity 0.1s;
    transform: scale(0.8);
  }

  &:hover .dashlet-filter {
    opacity: 1;
  }

  .kds-icon-arrows-rotate {
    opacity: ${({ forceShowUI }) => (forceShowUI ? 1 : 0)};
    transition: opacity 0.1s;
    z-index: 20;
  }

  .react-resizable-handle {
    opacity: ${({ forceShowUI }) => (forceShowUI ? 1 : 0)};
    transition: opacity 0.1s;
    z-index: 20;
  }

  .react-resizable-handle::after {
    border-color: ${grayScale.mediumDark};
  }

  .react-resizable-handle:hover::after {
    border-color: ${grayScale.dark};
  }

  &:hover .react-resizable-handle,
  &:hover .kds-icon-arrows-rotate {
    opacity: 1;
  }

  .dashlet-menu {
    opacity: ${({ forceShowUI, alwaysShowMenu }) =>
      forceShowUI || alwaysShowMenu ? 1 : 0};
    transition: opacity 0.1s;
    z-index: 11;
  }

  .dashlet-filter {
    ${({ alwaysShowMenu }) =>
      alwaysShowMenu
        ? css`
            opacity: 1;
          `
        : ''}
  }

  && button.dashlet-menu > i {
    color: ${grayScale.mediumDark};
  }

  && button.dashlet-menu > i:hover {
    color: ${grayScale.dark};
  }

  &:hover .dashlet-menu {
    opacity: 1;
  }

  &:before {
    ${({ hasShadow }) => (hasShadow === false ? '' : shadowLight)};
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${layers.content(0, -1)};
  }
`;

export const FirstDashletCard = styled(DashletCard)`
  border: 1px dashed ${colorsText.medium};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DashboardGridPageContainer = styled.div`
  position: relative;
  z-index: 0;
  ${({ isMobile }) => (isMobile ? 'overflow: hidden;' : '')};

  .react-grid-item.react-grid-placeholder {
    background-color: #cedefc;
    opacity: 1;
    z-index: ${layers.content(0)};
  }

  .react-grid-item.react-grid-placeholder .react-resizable-handle {
    display: none;
  }
`;

export const DashboardGridContainerBox = styled.div`
  width: 100%;

  .error-container {
    padding-right: 10px;
    padding-left: 0;
  }
`;
