import TriggerCard from 'pages/AutomationEngine/Engine/Steps/TriggerCard';
import StepCard from 'pages/AutomationEngine/Engine/Steps/StepCard';
import { getValueOrDeleted } from '../action/action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  return `Survey '${getValueOrDeleted(
    step.details.surveyId?.label,
    t
  )}' Submitted`;
};

export default function Card({ step, ...others }) {
  return step?.goalType ? (
    <StepCard {...others}>{content({ step })}</StepCard>
  ) : (
    <TriggerCard {...others}>{content({ step })}</TriggerCard>
  );
}
