import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsText, dropdownColors, grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import { textCss, truncateCss } from 'app/typography';

export default styled.li`
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  ${truncateCss};
  ${textCss()}
  padding-left: ${gutters.spacing(2, 2)}px;
  padding-right: ${gutters.spacing(2, 2)}px;
  ${({ selected }) =>
    selected &&
    css`
      color: ${colorsText.medium};
    `}
  ${({ focused }) =>
    focused &&
    css`
      background-color: ${dropdownColors.hover};
    `}
  ${({ selected }) =>
    !selected &&
    css`
      &:hover {
        background-color: ${dropdownColors.hover};
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: auto;
      color: ${grayScale.mediumDark};
      &:hover {
        background: none;
      }
    `}
`;
