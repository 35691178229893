import { Extension } from '@tiptap/core';

export const FontSize = Extension.create({
  name: 'fontSize',

  defaultOptions: {
    types: ['textStyle'],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            parseHTML: (element) => element.style.fontSize,
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: `font-size: ${attributes.fontSize}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain, tr }) => {
          const { attrs } =
            tr.storedMarks?.find((m) => m.type.name === 'textStyle') ?? {};
          return chain()
            .setMark('textStyle', { ...attrs, fontSize })
            .run();
        },
      unsetFontSize:
        () =>
        ({ chain, tr }) => {
          const { attrs } =
            tr.storedMarks?.find((m) => m.type.name === 'textStyle') ?? {};
          return chain()
            .setMark('textStyle', { ...attrs, fontSize: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
