import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from '../Button/Button';
import { ActionGroupElement } from './types';
import { ActionGroupContext } from './context';
import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import { getQaAttributes } from '../util';

export interface ActionItemProps {
  element: ActionGroupElement;
  insideTray?: boolean;
  collapsed?: boolean;
}

export const ActionItem = (props: ActionItemProps) => {
  const { element, insideTray = false, collapsed } = props;
  const elementId = element.id;
  const customTriggerRef = useRef<HTMLDivElement>(null);
  const [elementX, setElementX] = useState(0);

  const handleResize = useCallback(() => {
    if (customTriggerRef.current) {
      const elem = customTriggerRef.current;
      if (elem) {
        const rect = elem.getBoundingClientRect();
        setElementX(rect.x);
      }
    }
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const direction = useMemo(() => {
    if (elementX > window.innerWidth / 2) {
      return 'left';
    }
    return 'right';
  }, [elementX]);

  const buttonRef = useRef(null);
  const { openMenu, setOpenMenu } = useContext(ActionGroupContext);

  const toggleShowing = useCallback(() => {
    setOpenMenu(elementId);
  }, [elementId, setOpenMenu]);

  const isShowing = openMenu === element.id;

  const setIsShowing = useCallback(
    (isShowing: boolean) => {
      if (isShowing) {
        setOpenMenu(elementId);
      } else {
        setOpenMenu('');
      }
    },
    [setOpenMenu, elementId]
  );

  if (element.trigger) {
    return (
      <div ref={customTriggerRef} {...getQaAttributes(element.qa)}>
        {element.trigger?.({
          isShowing,
          setIsShowing,
          trigger: buttonRef,
          safeDirection: direction,
          collapsed,
        }) ?? null}
      </div>
    );
  }

  if (insideTray) {
    return (
      <>
        <button
          className="flex items-center gap-10"
          ref={buttonRef}
          onClick={toggleShowing}
          {...getQaAttributes(element.qa)}
        >
          <Icon
            icon={element.icon}
            size="xl"
            badge={Boolean(element.count) || element.badge}
          />
          <Typography size="md" variant="body">
            {element.label}
          </Typography>
        </button>
        {element.children?.({
          isShowing,
          setIsShowing,
          trigger: buttonRef,
        })}
      </>
    );
  }

  return (
    <>
      <Button
        variant="text"
        color={element.iconColor ?? 'secondary'}
        size="small"
        ref={buttonRef}
        onClick={toggleShowing}
        rightIcon={element.icon}
        rightIconSettings={{
          counter: element.count ? element.count : undefined,
          badge: element.badge,
          size: 'xl',
        }}
        qa={element.qa}
      />
      {element.children?.({
        isShowing,
        setIsShowing,
        trigger: buttonRef,
      })}
    </>
  );
};
