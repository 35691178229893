import { useMemo, useState, useRef } from 'react';

import useModal from 'components/Modals/useModal';
import { useOAuthReactQuery } from './useOAuthReactQuery';
import { getSortParam } from 'utility/getSortParam';

const defaultOAuthApiTokensSort = { column: 'application', direction: 'desc' };

export const useAccessTokens = (employeeId) => {
  const accessTokenId = useRef(null);
  const setAccessTokenId = (id) => {
    accessTokenId.current = id;
  };

  const [messagesSort, setMessagesSort] = useState(defaultOAuthApiTokensSort);
  const [search, setSearch] = useState(null);
  const oauthReactQuery = useOAuthReactQuery({
    search,
    ordering: getSortParam(messagesSort),
    pageSize: 20,
    setAccessTokenId,
    employeeId,
  });

  const { infiniteQuery, deleteOAuthTokenMutation } = oauthReactQuery;

  const [oauthTokensModalProps, , oauthTokensModal] = useModal({
    handleSubmit: () => {
      deleteOAuthTokenMutation.mutate(accessTokenId.current);
    },
  });
  const anyAccessTokensRef = useRef(false);

  const {
    data: accessTokens,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,
  } = infiniteQuery;

  const handleTokenRevoke = async (id) => {
    accessTokenId.current = id;
    oauthTokensModal.show();
  };

  const messageHeadData = useMemo(
    () => ({
      meta: {
        sort: messagesSort,
        onSort: (column, direction) =>
          setMessagesSort({
            column,
            direction,
          }),
      },
    }),
    [messagesSort, setMessagesSort]
  );

  // prevent search filtering out entries and giving a false null
  if (!search) {
    anyAccessTokensRef.current =
      accessTokens?.pages && Boolean(accessTokens?.pages[0].count);
  } else if (
    !anyAccessTokensRef.current &&
    accessTokens?.pages &&
    Boolean(accessTokens?.pages[0].count)
  ) {
    anyAccessTokensRef.current = true;
  }

  return {
    handleTokenRevoke,
    accessTokens,
    oauthTokensModalProps,
    messageHeadData,
    messagesSort,
    setMessagesSort,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,
    search,
    setSearch,
    anyAccessTokens: anyAccessTokensRef.current,
  };
};
