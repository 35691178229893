import produce from 'immer';

const SET_ERROR = 'SET_ERROR';
const RESET_ERROR = 'RESET_ERROR';

export const setError = (error) => ({
  type: SET_ERROR,
  error,
});

export const resetError = () => ({
  type: RESET_ERROR,
});

const setErrorHandler = produce((draft, { error }) => {
  const { code, text, url } = error;
  draft.code = code;
  draft.text = text;
  draft.url = url;
});

const resetErrorHandler = produce((draft) => {
  draft.code = null;
  draft.text = null;
  draft.url = null;
});

export const errorsInitial = {
  code: null,
  text: null,
  url: null,
};

export const errorsHandler = {
  [SET_ERROR]: setErrorHandler,
  [RESET_ERROR]: resetErrorHandler,
};
