import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { validate } from 'components/Inputs/TextInput/presets/DatePicker';
import TextInputInline from '..';

const DateTextInlineInput = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <TextInputInline
      ref={ref}
      validate={validate(t)}
      isShowCheckIcon={false}
      {...props}
    />
  );
});

export default DateTextInlineInput;
