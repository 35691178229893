import styled from '@emotion/styled';
import { layers } from 'app/spacing';

import Select from 'components/Inputs/Select';
import TableScrollContainer from 'components/Tables/ScrollContainerV2';
import Icon from 'components/Kizen/Icon';
import Loader from 'components/Kizen/Loader';
import css from '@emotion/css';
import { borderRadii } from 'app/spacing';

export const Header = styled.div`
  display: grid;
  grid-template-rows: 36px;
  grid-template-columns: 60px auto 175px;
  column-gap: 40px;
  row-gap: 10px;
  width: 100%;
  min-width: 0;
  min-height: 0;

  > .rof--related {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    > h3 {
      line-height: 36px;
    }
  }
  > .rof--object-selector {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    &.no-overflow {
      overflow: hidden;
    }
    min-width: 0;
    width: 100%;
    position: relative;
  }

  > .rof--search {
    grid-column: 3 / span 1;
    grid-row: 1 / span 1;
  }
`;

export const RelatedObjectSelect = styled(Select)`
  max-width: 250px;
`;

export const StyledTableScrollContainer = styled(TableScrollContainer)`
  & .BodyForSync {
    flex-direction: column;
  }
`;

export const StyledLoader = styled(Loader)`
  position: relative;
  ${(props) =>
    props.noPadding &&
    `
  padding: 20px 0 !important; // :( Sorry, Loader component sets styles inline
`}
  z-index: ${layers.toast};

  ${({ frameless }) =>
    frameless
      ? css`
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : ''}

  ${({ center }) =>
    center
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : ''}
`;

export const StyledButtonSelectorWrapper = styled.div`
  display: grid;
  margin: 10px 0;
  grid-auto-flow: column;
  column-gap: 40px;
  justify-content: start;

  overflow: hidden;
`;
export const StyledButtonWrapper = styled.div`
  min-width: 10px;
  overflow: hidden;
  width: 100%;
`;

export const StyledButtonSelector = styled.button`
  &&& {
    outline: none;
    box-shadow: none;

    display: inline-flex;
    align-items: center;

    min-width: 10px;
    max-width: 100%;
    background: none;
    border: none;
    > p {
      padding-top: 1px;
      min-width: 0;
    }
    padding: 0;

    overflow: hidden;

    ${({ color, selected }) =>
      selected
        ? css`
            color: ${color};
            && svg {
              color: ${color};
            }
            > p {
              color: ${color};
            }
          `
        : css`
            &:hover {
              color: ${color};
              && svg {
                color: ${color};
              }
              > p {
                color: ${color};
              }
            }
          `}
  }
  &&& svg {
    // font-awesome fonts are wider than tall
    width: auto;
  }
  i {
    margin-right: 10px;
    font-size: 14px;
  }
`;

export const StyledIcon = styled(Icon)`
  color: inherit;

  &&& {
    cursor: unset;
  }

  && svg {
    /* DragItem (the parent) has some styling that can't be overridden without !important */
    color: inherit !important;
  }

  &&&&:hover svg {
    color: inherit;
  }

  && svg {
    height: 14px;
  }
`;

export const StyledI = styled.i`
  color: inherit;

  &&& {
    cursor: unset;
  }

  &&:before {
    color: inherit !important;
  }

  &&&&:hover:before {
    color: inherit;
  }
`;

export const HiddenWrapper = styled.div`
  position: fixed;
  top: -40px;
  left: 0;
  width: 10000000px; // just a big value to let the buttons breath
  background: #fff;
  overflowx: hidden;
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;
  visibility: hidden;
`;

export const TableWrapper = styled.div`
  border-bottom-left-radius: ${borderRadii.standard};
  border-bottom-right-radius: ${borderRadii.standard};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ frameless }) =>
    frameless
      ? css`
          border: none;
          border-radius: 0;
          flex-grow: 1;
        `
      : ''}
`;
