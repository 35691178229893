import type { Next, Option } from '@kizen/filters/types';
import { useCallback } from 'react';
import IconAdornment from '__components/Inputs/Adornments/IconAdornment';
import { Dropdown } from './Dropdown';
import { DropdownInfinite } from './DropdownInfinite';
import { DropdownProps, OnChangeProp, Props, SelectOption } from './types';

type MultiSelectOneProps = Props<[SelectOption]> &
  OnChangeProp<{ next?: Next[]; value: [Option['value']] }>;

export const MultiSelectOne = ({
  value,
  onChange,
  step,
  paginated,
  ...rest
}: MultiSelectOneProps) => {
  const handleChange = useCallback(
    (...args: Parameters<DropdownProps['onChange']>) => {
      const [step, { next, value }] = args;
      onChange(step, { next, value: [value] });
    },
    [onChange]
  );

  if (paginated) {
    return (
      <DropdownInfinite
        step={step}
        value={value?.[0]}
        onChange={handleChange}
        endAdornment={<IconAdornment icon="search" />}
        {...rest}
      />
    );
  }

  return (
    <Dropdown
      step={step}
      value={value?.[0]}
      onChange={handleChange}
      endAdornment={<IconAdornment icon="search" />}
      {...rest}
    />
  );
};
