import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, colorsText, grayScale } from '../../../app/colors';
import { borderRadii, gutters } from '../../../app/spacing';
import { fontSizes, fontWeights, truncateCss } from '../../../app/typography';
import { optionProp } from '../props';

export const ButtonGroupButtonBase = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${gutters.spacing(1)}px;
  height: 36px;
  border: 1px solid ${grayScale.medium};
  border-radius: 2px;
  color: ${grayScale.mediumDark};
  & > span {
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.small};
    ${truncateCss};
  }
  background-color: ${grayScale.white};
  &:disabled {
    background-color: ${grayScale.light};
  }
  &:not(:disabled):hover {
    border-color: ${colorsButton.blue.hover};
  }
  &:focus {
    outline: none;
    border-color: ${colorsButton.blue.hover};
  }
  ${({ selected }) =>
    selected &&
    css`
      color: ${colorsButton.blue.hover};
      border-color: ${colorsButton.blue.hover};
      &:disabled {
        color: ${colorsText.dark};
        border-color: ${colorsText.dark};
      }
    `}
  ${({ error }) =>
    error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}
`;

export default function ButtonGroupButton({ option, ...props }) {
  return (
    <ButtonGroupButtonBase {...props}>
      <span>{option.label}</span>
    </ButtonGroupButtonBase>
  );
}

ButtonGroupButton.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};

ButtonGroupButton.defaultProps = {
  option: {},
};

export const ButtonGroupImageButtonBase = styled(ButtonGroupButtonBase)`
  display: inline-flex;
  flex-direction: column;
  ${({ customHeight }) =>
    customHeight
      ? css`
          height: ${customHeight}px;
        `
      : css`
          height: auto;
        `};
  border-radius: ${borderRadii.small};
  padding: ${gutters.spacing(2)}px;
  padding-bottom: ${gutters.spacing(2, { baseline: true })}px;

  ${({ imageFullSizeBtn }) =>
    imageFullSizeBtn &&
    css`
      padding: 0;
    `}

  & > svg {
    max-width: 100%;
    ${({ hasText }) =>
      hasText
        ? css`
            margin-bottom: ${gutters.spacing(2, -2)}px;
          `
        : css`
            margin-bottom: 0;
          `}
  }
  & > span {
    font-size: ${fontSizes.text};
    font-weight: ${fontWeights.regular};
  }
`;

export function ButtonGroupImageButton({ option, ...props }) {
  return (
    <ButtonGroupImageButtonBase hasText={Boolean(option.label)} {...props}>
      {option.image}
      <span>{option.label}</span>
    </ButtonGroupImageButtonBase>
  );
}

ButtonGroupImageButton.propTypes = {
  option: optionProp.isRequired,
  imageFullSizeBtn: PropTypes.bool,
};

ButtonGroupImageButton.defaultProps = {
  imageFullSizeBtn: false,
};
