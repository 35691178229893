import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useSharingAccessModal } from '../hooks/useSharingAccessModal';
import FilterGroupsService from 'services/FilterGroupsService';
import { TableTitle, StyledBigTable, StyledTableLayout } from '../styles';
import { SharingAccessModal } from 'components/Modals/SharingAccessModal';
import { usePaginationSettings } from 'hooks/usePaginationSettings';
import {
  DEFAULT_PAGINATION_SETTINGS,
  GET_FILTER_GROUPS_PATHNAMES,
  JSON_PROP,
} from '../constants';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import { useRespondToAccessRequest } from '../hooks/useRespondToAccessRequest';
import useModal from 'components/Modals/useModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import KizenTypography from 'app/kizentypo';
import { AccessRequestModal } from 'components/Modals/AccessRequestModal';
import Loader from 'components/Kizen/Loader';
import TablePagination from 'components/Tables/Big/TablePagination';
import { MobileFallback } from 'pages/Common/MobileFallback';
import { TRow } from 'components/Kizen/Table';
import { getOriginalError } from 'services/AxiosService';
import { useColumns } from '../hooks/useColumns';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import { TablePillRow } from './TablePillRow';
import { TableToolBar } from './TableToolBar';
import { monitoringExceptionHelper } from 'sentry/helpers';

export const FilterGroupsTable = ({ isMobile, model, isMine }) => {
  const { t } = useTranslation();
  const [showToast] = useToast();

  const actionId = useRef('');
  const requestingAccessState = useRef(null);
  const currentlyEditingGroup = useRef(null);
  const [errors, setErrors] = useState({});
  const [loadingGroup, setLoadingGroup] = useState(false);

  const {
    page,
    pageSize,
    search,
    ordering,
    handleChangePage,
    handleChangeSearch,
    handleChangePageSize,
    headData,
  } = usePaginationSettings(DEFAULT_PAGINATION_SETTINGS);

  const [{ value: filterGroups, loading: loadingFilterGroups }, refetch] =
    useAsyncFnKeepLast(
      async () =>
        FilterGroupsService.getFilterGroups(
          model?.id,
          isMine
            ? GET_FILTER_GROUPS_PATHNAMES.MINE
            : GET_FILTER_GROUPS_PATHNAMES.OTHER,
          {
            skipErrorBoundary: true,
            params: {
              page,
              page_size: pageSize,
              search,
              ordering,
            },
          }
        ),
      [page, pageSize, search, ordering, model?.id]
    );

  useRespondToAccessRequest(refetch, model?.id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onConfirmDelete = useCallback(async () => {
    try {
      await FilterGroupsService.deleteFilterGroup(model?.id, actionId.current);
      refetch();
    } catch (err) {
      monitoringExceptionHelper(err);
    } finally {
      actionId.current = null;
    }
  }, [refetch, model?.id]);

  const sendRequestAccess = useCallback(
    async (groupId, accessLevel, grantingAdmin) => {
      try {
        const data = await FilterGroupsService.requestFilterGroupAccess(
          groupId,
          grantingAdmin,
          accessLevel,
          model?.id
        );
        if (data?.status === 'pending') {
          showToast({
            message: t('Your access request has been sent.'),
            variant: toastVariant.SUCCESS,
          });
        } else {
          showToast({
            message: t('Your access request could not be sent.'),
            variant: toastVariant.FAILURE,
          });
        }
      } catch (err) {
        showToast({
          message: t('Your access request could not be sent.'),
          variant: toastVariant.FAILURE,
        });
      }
    },
    [model?.id, showToast, t]
  );

  const createFilterGroup = useCallback(
    async (
      { name, permissions, isPrivate },
      isEditing,
      filterGroupId,
      config
    ) => {
      if (isEditing) {
        await FilterGroupsService.updateFilterGroup(model?.id, filterGroupId, {
          name,
          sharingSettings: {
            ...buildSharingSettingsForAPI(permissions).sharing_settings,
            private: isPrivate,
          },
        });
      } else {
        await FilterGroupsService.createFilterGroup(model?.id, {
          name,
          config,
          sharingSettings: {
            ...buildSharingSettingsForAPI(permissions).sharing_settings,
            private: isPrivate,
          },
        });
      }
      refetch();
      showToast({
        message: t("'{{name}}' filter group has been saved successfully.", {
          name,
        }),
        variant: toastVariant.SUCCESS,
      });
    },
    [model?.id, showToast, refetch, t]
  );

  const [confirmDeletionModalProps, { onClick: showConfirmDeletionModal }] =
    useModal({
      handleSubmit: onConfirmDelete,
    });

  const [accessRequestModalProps, { onClick: showAccessRequestModal }] =
    useModal({
      handleSubmit: sendRequestAccess,
    });

  const { sharingAccessModalProps, onShowSharingAccessModal } =
    useSharingAccessModal(createFilterGroup);

  const handleEdit = useCallback(
    async (data) => {
      try {
        currentlyEditingGroup.current = data;
        onShowSharingAccessModal();
        setLoadingGroup(true);
        const res = await FilterGroupsService.getFilterGroup(
          model?.id,
          data.id
        );
        currentlyEditingGroup.current = res;
      } catch (err) {
        monitoringExceptionHelper(err);
      } finally {
        setLoadingGroup(false);
      }
    },
    [onShowSharingAccessModal, model?.id]
  );

  const handleDuplicate = useCallback(
    async (id, name) => {
      try {
        currentlyEditingGroup.current = {
          name: `${name} ${t('(copy)')}`,
        };
        onShowSharingAccessModal();
        setLoadingGroup(true);
        const res = await FilterGroupsService.getFilterGroup(model?.id, id);
        currentlyEditingGroup.current = {
          name: `${res.name} ${t('(copy)')}`,
          sharing_settings: res.sharing_settings,
          [JSON_PROP]: res[JSON_PROP],
        };
      } catch (err) {
        monitoringExceptionHelper(err);
      } finally {
        setLoadingGroup(false);
      }
    },
    [onShowSharingAccessModal, model?.id, t]
  );

  const handleEditVisibility = useCallback(
    async (filterGroupId, isHidden) => {
      try {
        await FilterGroupsService.updateFilterGroupVisibility(
          model?.id,
          filterGroupId,
          !isHidden
        );
        refetch();
      } catch (err) {
        monitoringExceptionHelper(err);
      }
    },
    [model?.id, refetch]
  );

  const handleActionMenu = useCallback(
    ({ value }, data) => {
      const { id, hidden, name } = data;

      if (value === 'delete') {
        actionId.current = id;
        showConfirmDeletionModal();
      } else if (value === 'request') {
        requestingAccessState.current = data;
        showAccessRequestModal();
      } else if (value === 'edit_sharing') {
        handleEdit(data);
      } else if (value === 'duplicate') {
        handleDuplicate(id, name);
      } else if (value === 'edit_visibility') {
        handleEditVisibility(id, hidden);
      }
    },
    [
      showConfirmDeletionModal,
      handleEdit,
      handleDuplicate,
      showAccessRequestModal,
      handleEditVisibility,
    ]
  );

  const handleChangeName = useCallback(
    async (name, filterGroupId) => {
      setErrors({});
      try {
        await FilterGroupsService.updateFilterGroup(model?.id, filterGroupId, {
          name,
        });
      } catch (err) {
        const { name } = getOriginalError(err) || {};
        if (name) {
          setErrors({ [filterGroupId]: { name } });
        }
      }
    },
    [model?.id]
  );

  const handleChangeOwner = useCallback(
    async ({ value }, filterGroupId) => {
      await FilterGroupsService.updateFilterGroup(model?.id, filterGroupId, {
        sharingSettings: value,
      });
      refetch();
    },
    [model?.id, refetch]
  );

  const handleClickEditSharingSettings = useCallback(
    async (data) => {
      try {
        currentlyEditingGroup.current = data;
        onShowSharingAccessModal();
        setLoadingGroup(true);
        const res = await FilterGroupsService.getFilterGroup(
          model?.id,
          data?.id
        );
        currentlyEditingGroup.current = {
          ...data,
          sharing_settings: res.sharing_settings,
          [JSON_PROP]: res[JSON_PROP],
        };
      } catch (err) {
        monitoringExceptionHelper(err);
      } finally {
        setLoadingGroup(false);
      }
    },
    [onShowSharingAccessModal, model?.id]
  );

  const columns = useColumns(
    handleActionMenu,
    handleChangeName,
    handleClickEditSharingSettings,
    handleChangeOwner,
    errors,
    filterGroups
  );

  const fetchProps = useMemo(() => [model?.id], [model?.id]);

  return (
    <>
      <Loader loading={loadingFilterGroups && !filterGroups}>
        <StyledTableLayout
          stickyToolbar={false}
          hideCurtainWrapper
          toolbar={
            <TableToolBar
              onChangeSearch={handleChangeSearch}
              count={filterGroups?.count || 0}
              loading={loadingFilterGroups}
              isMobile={isMobile}
            />
          }
          pagination={
            isMobile ? null : (
              <TablePagination
                totalCount={filterGroups?.count}
                page={page}
                perPage={pageSize}
                onChangePerPage={handleChangePageSize}
                onChangePage={handleChangePage}
              />
            )
          }
        >
          <MobileFallback isMobile={isMobile}>
            <StyledBigTable
              tableTitle={
                <TableTitle
                  title={
                    isMine
                      ? t('My Filter Groups')
                      : t('Other Team Member Filter Groups')
                  }
                />
              }
              columns={columns}
              head={<TRow head columns={columns} data={headData} />}
              staleData={loadingFilterGroups}
              headerPosition="unset"
            >
              {filterGroups?.results.map((result) => {
                return (
                  <TablePillRow
                    key={result.id}
                    columns={columns}
                    data={result}
                    isHidden={result.hidden}
                  />
                );
              }) ?? []}
            </StyledBigTable>
          </MobileFallback>
        </StyledTableLayout>
      </Loader>
      {sharingAccessModalProps.show ? (
        <SharingAccessModal
          {...sharingAccessModalProps}
          showPrivateToggle
          key={`${currentlyEditingGroup.current?.id}${loadingGroup}`}
          existing={currentlyEditingGroup.current}
          isEditing={Boolean(currentlyEditingGroup.current?.id)}
          isDuplicating={!currentlyEditingGroup.current?.id}
          loading={loadingGroup}
          instanceName={t('Filter Group')}
          isTemplate={false}
          jsonProp={JSON_PROP}
        />
      ) : null}
      {accessRequestModalProps.show ? (
        <AccessRequestModal
          template={requestingAccessState.current}
          instanceName={t('Filter Group')}
          serviceToUse={FilterGroupsService}
          fetchProps={fetchProps}
          {...accessRequestModalProps}
        />
      ) : null}
      <ConfirmDeletionModal {...confirmDeletionModalProps}>
        <KizenTypography>
          {t('This will permanently delete the Filter Group.')}
        </KizenTypography>
      </ConfirmDeletionModal>
    </>
  );
};
