import { forwardRef, useRef } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, colorsText, grayScale } from 'app/colors';
import { borderRadii, gutters } from 'app/spacing';
import { fontSizes, fontWeights } from 'app/typography';
import { optionProp } from '../props';
import * as PropTypes from 'prop-types';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

export const ButtonGroupButtonBase = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${gutters.spacing(1)}px;
  height: 36px;
  border: 1px solid ${grayScale.medium};
  border-radius: 2px;
  color: ${grayScale.mediumDark};
  & > span {
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.small};
    color: ${grayScale.mediumDark};
  }
  background-color: ${grayScale.white};
  &:disabled {
    background-color: ${grayScale.light};
  }
  &:not(:disabled):hover {
    border-color: ${colorsButton.blue.hover};
  }
  &:focus {
    outline: none;
    border-color: ${colorsButton.blue.hover};
  }
  ${({ selected }) =>
    selected &&
    css`
      color: ${colorsButton.blue.hover};
      & > span {
        color: ${colorsButton.blue.hover};
      }
      border-color: ${colorsButton.blue.hover};
      &:disabled {
        color: ${colorsText.dark};
        border-color: ${colorsText.dark};
      }
    `}
  ${({ error }) =>
    error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}
`;

const ButtonGroupButton = forwardRef(
  ({ option = {}, fieldId, disabled, ...props }, ref) => {
    const { assignFieldHandle } = useKeyBoardContext();
    const buttonRef = useRef();

    assignFieldHandle(fieldId, {
      customFocus: () => {
        buttonRef.current?.focus();
        return buttonRef.current;
      },
      disabled,
    });

    return (
      <ButtonGroupButtonBase ref={ref} disabled={disabled} {...props}>
        <span>{option.label}</span>
      </ButtonGroupButtonBase>
    );
  }
);

export default ButtonGroupButton;

export const ButtonGroupImageButtonBase = styled(ButtonGroupButtonBase)`
  display: inline-flex;
  flex-direction: column;
  ${({ customHeight }) =>
    customHeight
      ? css`
          height: ${customHeight}px;
        `
      : css`
          height: auto;
        `};
  border-radius: ${borderRadii.small};
  padding: ${gutters.spacing(2)}px;
  padding-bottom: ${gutters.spacing(2, { baseline: true })}px;

  ${({ imageFullSizeBtn }) =>
    imageFullSizeBtn &&
    css`
      padding: 0;
    `}

  & > svg {
    max-width: 100%;
    ${({ hasText }) =>
      hasText
        ? css`
            margin-bottom: ${gutters.spacing(2, -2)}px;
          `
        : css`
            margin-bottom: 0;
          `}
  }
  & > span {
    font-size: ${fontSizes.text};
    font-weight: ${fontWeights.regular};
  }
`;

export function ButtonGroupImageButton({
  option,
  fieldId,
  disabled,
  ...props
}) {
  const { assignFieldHandle } = useKeyBoardContext();
  const buttonRef = useRef();

  assignFieldHandle(fieldId, {
    customFocus: () => {
      buttonRef.current?.focus();
      return buttonRef.current;
    },
    disabled,
  });

  return (
    <ButtonGroupImageButtonBase
      ref={buttonRef}
      hasText={Boolean(option.label)}
      disabled={disabled}
      {...props}
    >
      {option.image}
      <TextEllipsisWithTooltip as="span">
        {option.label}
      </TextEllipsisWithTooltip>
    </ButtonGroupImageButtonBase>
  );
}

ButtonGroupImageButton.propTypes = {
  option: optionProp.isRequired,
  imageFullSizeBtn: PropTypes.bool,
};

ButtonGroupImageButton.defaultProps = {
  imageFullSizeBtn: false,
};

export const ButtonGroupImageBackgroundButtonBase = styled(
  ButtonGroupImageButtonBase
)`
  position: relative;
`;

const ButtonGroupImageBackgroundText = styled.div`
  position: absolute;
  top: 56px;
  font-size: ${fontSizes.text};
  font-weight: ${fontWeights.regular};
  ${({ selected }) =>
    selected &&
    css`
      color: ${colorsButton.blue.hover};
      &:disabled {
        color: ${colorsText.dark};
        border-color: ${colorsText.dark};
      }
    `}
`;
export function ButtonGroupImageBackgroundButton({ option, ...props }) {
  return (
    <ButtonGroupImageBackgroundButtonBase
      //  hasText={Boolean(option.label)}
      {...props}
    >
      {option.image}
      <ButtonGroupImageBackgroundText {...props}>
        {option.label}
      </ButtonGroupImageBackgroundText>
      {/* <span>{option.label}</span> */}
    </ButtonGroupImageBackgroundButtonBase>
  );
}

ButtonGroupImageBackgroundButton.propTypes = {
  option: optionProp.isRequired,
  imageFullSizeBtn: PropTypes.bool,
};

ButtonGroupImageBackgroundButton.defaultProps = {
  imageFullSizeBtn: false,
};
