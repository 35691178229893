import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ActivityService from 'services/ActivityService';
import CustomObjectService from 'services/CustomObjectsService';
import { toastVariant, useToast } from 'components/ToastProvider';

const useExportFieldMetadata = ({ model, activity }) => {
  const { t } = useTranslation();
  const [showToast] = useToast();

  const handleFieldExport = useCallback(async () => {
    try {
      const serviceToUse = activity ? ActivityService : CustomObjectService;
      const id = activity ? activity.id : model.id;
      await serviceToUse.exportFields(id);
      const successMessage = t(
        'Export started, You will be emailed a download link once complete.'
      );
      showToast({
        message: successMessage,
        variant: toastVariant.SUCCESS,
      });
    } catch (error) {
      const errorMessage =
        error?.message ?? t('Export failed. Please try again later.');
      showToast({
        message: errorMessage,
        variant: toastVariant.FAILURE,
      });
    }
  }, [activity, model, showToast, t]);

  return {
    handleFieldExport,
  };
};

export default useExportFieldMetadata;
