/**
 * @example [...range(10)]       // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
 * @example [...range(1, 10)]    // [1, 2, 3, 4, 5, 6, 7, 8, 9]
 * @example [...range(1, 20, 2)] // [1, 3, 5, 7, 9, 11, 13, 15, 17, 19]
 * @example [...range(10, 0)]    // [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
 * @example [...range(5, 6, 2)]  // [5]
 * @example range()              // infinite iterable (0..Infinity)
 */
// export function range(): Iterable<number>;
// export function range(to: number): Iterable<number>;
// export function range(from: number, to: number): Iterable<number>;
// export function range(from: number, to: number, step: number): Iterable<number>;
export function* range(...args) {
  const from = args.length > 1 ? args[0] : 0;
  const to =
    args.length === 0 ? Infinity : args.length === 1 ? args[0] : args[1];
  const step = args.length === 3 ? args[2] : from < to ? 1 : -1;

  let count = from;
  if (step > 0) {
    while (count < to) {
      yield count;
      count += step;
    }
  } else if (step < 0) {
    while (count > to) {
      yield count;
      count += step;
    }
  }
}
