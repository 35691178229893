import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';
import { getKDSClasses, merge } from '../util';
import { isDebug } from '../debug';
import { Spacer } from '../Spacer/Spacer';
import { ValidIcons } from '../Icon/library';

export interface PillProps {
  icon: ValidIcons;
  label?: string;
}

export const Pill = (props: PillProps) => {
  const { icon, label } = props;

  const { debugClassName } = isDebug();

  const classes = merge(
    getKDSClasses('pill'),
    'inline-flex flex-col relative bg-background-light rounded-pill-sm text-font-secondary',
    debugClassName
  );
  return (
    <div
      className={classes}
      data-kds-pill-label={label}
      data-kds-pill-icon={icon}
    >
      <Spacer mode="horizontal" size={5} />
      <div className="flex items-center">
        <Spacer mode="vertical" size={5} />
        {icon && <Icon icon={icon} size="xs" />}
        {icon && label && <Spacer mode="vertical" size={5} />}
        {label && <Typography size="xs">{label}</Typography>}
        <Spacer mode="vertical" size={5} />
      </div>
      <Spacer mode="horizontal" size={5} />
    </div>
  );
};
