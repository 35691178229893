import { useMemo } from 'react';
import parsePhoneNumber, { getCountryCallingCode } from 'libphonenumber-js/max';
export default function usePhoneFormatter(phoneNumber, country = 'US') {
  const businessCC = getCountryCallingCode(country) || '';
  const asYouTypeOptions = useMemo(
    () => ({
      defaultCallingCode: phoneNumber?.countryCallingCode || businessCC || '1',
      defaultCountry: phoneNumber?.country || country,
    }),
    [phoneNumber, businessCC, country]
  );

  const formattedValue = useMemo(() => {
    if (!phoneNumber) return '';
    const phoneNumberObj = phoneNumber?.number
      ? phoneNumber
      : parsePhoneNumber(phoneNumber.toString());
    if (phoneNumberObj?.formatNational && businessCC) {
      const format =
        +businessCC === +phoneNumberObj.countryCallingCode
          ? 'NATIONAL'
          : 'INTERNATIONAL';
      return phoneNumberObj.format(format, {
        formatExtension: (number, extension) => {
          return number + (extension ? ' x ' + extension : '');
        },
      });
    }
    return '';
  }, [businessCC, phoneNumber]);

  return {
    formattedValue,
    asYouTypeOptions,
    businessCC,
  };
}
