import { map } from '@thi.ng/transducers';
import { Access, StreamValue } from './types';
import { accessIter, getAccessNumber } from './utils';
import {
  MISSING_SOURCE,
  type SingleRuleDef,
  type RuleResult,
} from './rules/types';

type SourceValueOrMissing = StreamValue | typeof MISSING_SOURCE;

const BLANK_STREAM_VALUE = {
  allowed_access: ['none'],
  value: 0,
  __blank_stream_value: true, // tagged for debugging/testing purposes
} as StreamValue;

const BLANK_RULE_RESULT_VALUE: RuleResult<StreamValue> = {
  value: BLANK_STREAM_VALUE,
  result: false,
};

const getStreamAccessNumber = ({ value }: StreamValue) => {
  return typeof value === 'boolean' ? getAccessNumber(value) : value;
};

export const getGreaterThanOrEqualTransducer = (
  rule: SingleRuleDef<any, any, any>
) => {
  return map((value: SourceValueOrMissing) => {
    if (value === MISSING_SOURCE) {
      return BLANK_RULE_RESULT_VALUE;
    }

    const val = getStreamAccessNumber(value);
    if (rule[2] && val >= getAccessNumber(rule[2])) {
      return { value, result: true };
    }
    return { value, result: false };
  });
};

export const getLessThanTransducer = (rule: SingleRuleDef<any, any, any>) => {
  return map((value: SourceValueOrMissing) => {
    if (value === MISSING_SOURCE) {
      return BLANK_RULE_RESULT_VALUE;
    }

    const val = getStreamAccessNumber(value);
    if (rule[2] && val < getAccessNumber(rule[2])) {
      return { value, result: true };
    }
    return { value, result: false };
  });
};

// Note: these operation transducers do not have access to the current/default
// value of the permission in which they are being applied. As it is, min-value
// will potentially alter the default/current max value. If that is ever undesirable
// we'll need to either:
//   1. Add a config option to this rule to indicate the max value.
//   2. Add a max-value rule (with explicit return value) and a 'chain' combinator rule
//      to pass the result of one rule to the next for modification.
export const getMinValueTransducer = () => {
  return map((value: SourceValueOrMissing) => {
    if (value === MISSING_SOURCE) {
      return BLANK_RULE_RESULT_VALUE;
    }

    if (typeof value.value === 'boolean') {
      const allowed_access: Access[] = value.value
        ? ['none', 'remove']
        : ['none'];
      return { result: true, value: { allowed_access } };
    }
    return {
      result: true,
      value: { allowed_access: [...accessIter(value.value)] },
    };
  });
};

export const getEqualTransducer = (rule: SingleRuleDef<any, any, any>) => {
  return map((value: SourceValueOrMissing) => {
    if (value === MISSING_SOURCE) {
      return BLANK_RULE_RESULT_VALUE;
    }

    const rval = getAccessNumber(rule[2]);
    const val = getStreamAccessNumber(value);

    return { result: rval === val, value };
  });
};

export const getExistsTransducer = (_rule: SingleRuleDef<any, any, any>) => {
  return map((value: SourceValueOrMissing) => {
    const isSourceMissing = value === MISSING_SOURCE;

    return {
      result: !isSourceMissing,
      value: isSourceMissing ? BLANK_STREAM_VALUE : value,
    };
  });
};

export const getNotExistsTransducer = (_rule: SingleRuleDef<any, any, any>) => {
  return map((value: SourceValueOrMissing) => {
    const isSourceMissing = value === MISSING_SOURCE;

    return {
      result: isSourceMissing,
      value: isSourceMissing ? BLANK_STREAM_VALUE : value,
    };
  });
};
