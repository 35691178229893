import { DISPLAY_VALUE_RESPONSES } from 'components/Wizards/Dashlet/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Donut from '..';
import { buildChartData } from '../../helpers';
import { useQuery } from 'react-query';
import { FIELDS } from 'queries/query-keys';
import FieldService from 'services/FieldService';

export const DonutChartGroupAdapter = ({
  data,
  dashlet,
  fieldData,
  fieldOptions,
  model,
  ...props
}) => {
  const height = dashlet?.layout?.h;
  const width = dashlet?.layout?.w;
  const { t } = useTranslation();

  const isClient = model?.fetchUrl === 'client';

  const { data: breakdownField, isLoading: breakdownFieldLoading } = useQuery(
    FIELDS.GET_FIELD(dashlet?.config?.metricTypeExtraInfo?.fieldToAnalyze),
    () =>
      isClient
        ? FieldService.getClientField(
            dashlet.config.metricTypeExtraInfo.fieldToAnalyze,
            {
              skipErrorBoundary: true,
            }
          )
        : FieldService.getField(
            dashlet.config.metricTypeExtraInfo.fieldToAnalyze,
            model,
            {
              skipErrorBoundary: true,
            }
          ),
    {
      enabled:
        Boolean(dashlet?.config?.metricTypeExtraInfo?.fieldToAnalyze) &&
        Boolean(data),
    }
  );

  const breakdownCurrencySymbol = breakdownField?.moneyOptions?.symbol;
  const isBreakdownFieldDecimal = breakdownField?.fieldType === 'decimal';

  const currencySymbol = fieldData?.moneyOptions?.symbol;
  const chartData = useMemo(() => {
    return buildChartData({
      data,
      dashlet,
      fieldData,
      fieldOptions,
      currencySymbol,
      t,
    });
  }, [data, t, dashlet, currencySymbol, fieldOptions, fieldData]);

  const summaryData = useMemo(() => {
    if (data && dashlet.config.metricTypeExtraInfo.includeSummary) {
      let descriptor = {};

      if (dashlet.config.metricTypeExtraInfo.fieldToAnalyze) {
        if (dashlet.config.metricTypeExtraInfo.summaryExplanation) {
          descriptor = {
            descriptor: {
              singular: dashlet.config.metricTypeExtraInfo.summaryExplanation,
              plural: dashlet.config.metricTypeExtraInfo.summaryExplanation,
            },
          };
        }
      } else {
        descriptor = {
          descriptor: {
            singular: t('Record'),
            plural: t('Records'),
          },
        };
      }

      const chartTotal = data.data.find(
        (item) => item.type === 'summary'
      )?.value;

      return chartTotal !== undefined
        ? {
            chartTotal,
            noSummary: false,
            ...descriptor,
          }
        : {};
    }
    return {};
  }, [dashlet, data, t]);

  const isValue = dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE;
  const isDataValue = data?.metric_type === DISPLAY_VALUE_RESPONSES.VALUE;

  return (
    <Donut
      {...props}
      isLoading={
        props.isLoading || isValue !== isDataValue || breakdownFieldLoading
      }
      data={chartData}
      legendRows={height === 4 ? 3 : 2}
      small={height === 3}
      noSummary
      skipSorting
      getLabel={(d) => {
        if (d?.label) {
          return String(d.label).toUpperCase();
        }
      }}
      wide={width === 4}
      breakdownCurrencySymbol={breakdownCurrencySymbol}
      forceDecimal={isBreakdownFieldDecimal}
      {...summaryData}
    />
  );
};
