import React, { useState } from 'react';
import styled from '@emotion/styled';
import DateRangePicker from 'components/Kizen/DateRangePicker';
import { breakpoints, gutters } from 'app/spacing';
import DateInput from 'components/Kizen/DateInput';
import Input from 'components/Kizen/Input';
import Section from './helpers/Section';
import SubSection from './helpers/SubSection';
import Cols from './helpers/Cols';
import SingleSelect from './SingleSelect';
import MainSelector from './MainSelector';
import MultiSelect from './MultiSelect';
import Switch from './Switch';
import GridSelector from './GridSelector';
import YesNoSelector from './YesNoSelector';
import { RadioButtons, RadioButtonGroup } from './RadioButton';
import { Checkboxes, CheckboxGroup } from './Checkbox';
import FileInput from './FileInput';
import ToggleGridSelector from './ToggleGridSelector';

const StyledInput = styled(Input)`
  margin-bottom: ${gutters.standard};
`;

const StyledSubSection = styled(SubSection)`
  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 30px;
    & > hr {
      display: none;
    }
  }
`;

export default function OtherInputsSample() {
  const [search, setSearch] = useState('');
  const [contentSearch, setContentSearch] = useState('This input has content');
  const [errorSearch, setErrorSearch] = useState('');
  const [date, setDate] = useState(null);

  return (
    <Section title="Other Inputs - Standard">
      <Cols columns={3} gutter="30px">
        <StyledSubSection title="Single Select">
          <SingleSelect />
        </StyledSubSection>
        <StyledSubSection title="Single Select (Main Selector)">
          <MainSelector />
        </StyledSubSection>
        <StyledSubSection title="Multi Select">
          <MultiSelect />
        </StyledSubSection>
        <StyledSubSection title="Search Input">
          {' '}
          <StyledInput
            type={Input.INPUT_TYPES.SEARCH}
            pill
            label="Placeholder"
            value={search}
            placeholder="Default"
            onChange={setSearch}
          />
          <StyledInput
            type={Input.INPUT_TYPES.SEARCH}
            pill
            label="With Content"
            value={contentSearch}
            onChange={setContentSearch}
          />
          <StyledInput
            type={Input.INPUT_TYPES.SEARCH}
            pill
            label="Error"
            value={errorSearch}
            onChange={setErrorSearch}
            error
          />
        </StyledSubSection>
        <StyledSubSection title="Switches">
          <Switch />
        </StyledSubSection>
        <StyledSubSection title="Selector (Grid)">
          <GridSelector />
        </StyledSubSection>
        <StyledSubSection title="Selector (Yes/No)">
          <YesNoSelector />
        </StyledSubSection>
        <StyledSubSection title="Selector (Grid with Toggle)">
          <ToggleGridSelector />
        </StyledSubSection>
        <StyledSubSection title="Radio Button">
          <RadioButtons />
        </StyledSubSection>
        <StyledSubSection title="Radio Button Group">
          <RadioButtonGroup />
        </StyledSubSection>
        <StyledSubSection title="Checkbox">
          <Checkboxes />
        </StyledSubSection>
        <StyledSubSection title="Checkbox Group">
          <CheckboxGroup />
        </StyledSubSection>
        <StyledSubSection title="File Input">
          <FileInput />
        </StyledSubSection>
        <StyledSubSection title="Date Range Picker">
          <DateRangePicker />
        </StyledSubSection>
        <StyledSubSection title="Single Date Picker">
          <DateInput
            placeholder="Choose Date"
            value={date}
            onChange={setDate}
            format="MM/DD/YYYY"
          />
        </StyledSubSection>
      </Cols>
    </Section>
  );
}
