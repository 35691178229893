import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import cloneDeep from 'lodash/cloneDeep';
import ColorPickerInput from 'components/Kizen/ColorPickerInput';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { grayScale, colorsPrimary, colorsSecondary } from 'app/colors';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';

const DeleteIcon = styled(Icon)`
  font-size: 8px;
  color: ${grayScale.mediumDark} !important;

  &:hover {
    color: inherit !important;
  }
`;

export const numberOfOptionLimit = 250;
export const OPTION_NAME_MAX_LENGTH = 255;
export const optionsAreValid = (options) =>
  !!options.length &&
  options.length <= numberOfOptionLimit &&
  options.every((opt) => !!opt.name) &&
  options.every((opt) => opt.name.length <= OPTION_NAME_MAX_LENGTH) &&
  [...new Set(options.map(({ name }) => name))].length === options.length;

export const validateOptions = (stagedOptions, t) => (newName, field) => {
  const changedFieldIndex = stagedOptions.map(({ id }) => id).indexOf(field.id);
  const prevNames = stagedOptions.map((opt) => opt.name);
  const nextNames = [...prevNames];
  nextNames[changedFieldIndex] = newName;
  // If the proposed new name is responsible for a uniqueness error, mark as invalid
  // Note that there may be other uniqueness errors from other options
  if (nextNames.filter((name) => name === newName).length > 1) {
    return t('Option name must be unique');
  }
  if (newName.length > OPTION_NAME_MAX_LENGTH) {
    return t('Option name must be less than 256 characters');
  }
  return true;
};

export const cleanOptions = (options, { preserveMeta = false } = {}) =>
  cloneDeep(options).map((option) => {
    if (option.id && option.id.startsWith('tmp_')) {
      delete option.id;
      delete option.description;

      if (!preserveMeta) {
        delete option.meta;
      }
    }

    return option;
  });

export function FieldActions({ field, onDelete }) {
  const { t } = useTranslation();
  return (
    <IconButton
      title={t('Delete')}
      sizing="dense"
      onClick={() => onDelete(field)}
    >
      <DeleteIcon icon="delete" />
    </IconButton>
  );
}

FieldActions.propTypes = {
  field: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledColorPicker = styled(ColorPickerInput)`
  .styled-swatch {
    width: 20px;
    height: 20px;

    // The color picker doesn't have a friendly selector
    // This forces it to pop up instead of below the swatch
    + div {
      top: auto;
      bottom: 100%;
    }
  }
`;

const DEFAULT_COLOR = colorsPrimary.blue.dark;

// This works around some funky state management/re-rendering
// issues that were causing the picker to close prematurely
export const ControlledColorPicker = ({ field, onColorChange, ...rest }) => {
  const [stagedColor, setStagedColor] = useState(
    field.meta ? field.meta.color : DEFAULT_COLOR
  );

  return (
    <StyledColorPicker
      color={stagedColor}
      onChange={setStagedColor}
      onClose={(rgba) => onColorChange(field, rgba)}
      {...rest}
    />
  );
};

ControlledColorPicker.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const StyledText = styled(KizenTypography)`
  margin-left: ${gutters.spacing(4)}px;
`;

export const OptionsLimitWarning = ({ limit }) => {
  const { t } = useTranslation();

  return (
    <StyledText fontStyle="italic" color={colorsSecondary.red.dark}>
      <br />*
      {t('This field type is limited to {{limit}} options.', {
        limit,
      })}
    </StyledText>
  );
};
