import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from 'i18n-config';
import { gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import { CardBasic } from 'components/Card/Card';
import Select from 'components/Inputs/Select';

const StyledCard = styled(CardBasic)`
  margin: 80px auto;
  max-width: 500px;
  padding: ${gutters.spacing(4)}px;
`;

export default function TranslationProofOfConcept() {
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  return (
    <StyledCard>
      <Select
        label={t('Application Language')}
        options={availableLanguages}
        value={language}
        onChange={async ({ value }) => {
          await i18n.changeLanguage(value);
          setLanguage(value);
        }}
        margin
      />
      <KizenTypography>{t('welcome')}</KizenTypography>
      <KizenTypography>
        {t('todayIs')}: {t('today', { date: new Date() })}
      </KizenTypography>
    </StyledCard>
  );
}
