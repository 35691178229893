import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { KizenTypography } from 'app/typography';
import { colorsText } from 'app/colors';
import Loader from 'components/Kizen/Loader';
import { DashletShape } from 'utility/CommonPropTypes';
import styled from '@emotion/styled';
import { units } from './helpers';
import { gutters } from 'app/spacing';
import { collapseNumber } from 'utility/numbers';
import { PERCENTAGE_REPORT_TYPES } from 'components/Wizards/Dashlet/types';

const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${colorsText.dark};
  > * {
    // To match designs, needs a slight bump off of center
    margin-top: -${gutters.spacing(1, 2)}pxpx;
  }
`;

const Summary = ({ dashlet, data, isLoading, fieldData }) => {
  const fieldCurrencySymbol = fieldData?.moneyOptions?.symbol;

  const value = useMemo(() => {
    if (!data) {
      return 0;
    }
    const value = data.data?.find((d) => d.type === 'value');
    return value?.value ?? 0;
  }, [data]);

  const isPercent = useMemo(() => {
    return PERCENTAGE_REPORT_TYPES.includes(dashlet?.config?.reportType);
  }, [dashlet]);

  return (
    <Loader loading={isLoading}>
      <Container>
        <KizenTypography size="hero" data-qa-dashlet-display-field="value">
          {fieldCurrencySymbol}
          {units(collapseNumber(value), dashlet.config)}
          {isPercent ? '%' : ''}
        </KizenTypography>
      </Container>
    </Loader>
  );
};

Summary.propTypes = {
  dashlet: DashletShape.isRequired,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

Summary.defaultProps = {
  isLoading: true,
};

export default Summary;
