import { useTimeout } from 'react-use';
import { StyledCalendarLoader } from './styled';

const StaleLoader = () => {
  const [isReady] = useTimeout(2000);
  return isReady() ? <StyledCalendarLoader loading /> : null;
};

export const CalendarLoader = ({ loading }) =>
  loading ? <StaleLoader /> : null;
