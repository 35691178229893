import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { ICON_BUTTON_SIZING } from 'components/Kizen/IconButton';
import { shadowLight, grayScale } from 'app/colors';
import { useTranslation } from 'react-i18next';

const BaseIconButton = styled(IconButton)`
  border-color: ${grayScale.white};
  ${({ scrolled }) =>
    scrolled &&
    css`
      border: 1px solid ${grayScale.white};
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 50%;

      // Clip shadow so that it doesn't appear on top of table
      clip-path: inset(0px -26px -44px 0px);
      ${shadowLight};
    `}
  overflow: hidden;

  transition-property: border, box-shadow, border-radius, transform, height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
`;

export default function ExpandTableTab({ expanded, scrolled, ...others }) {
  const { t } = useTranslation();
  const toolTipTitle = useMemo(
    () => (expanded ? t('Collapse Table') : t('Expand Table')),
    [expanded, t]
  );
  return (
    <BaseIconButton
      scrolled={scrolled}
      title={toolTipTitle}
      sizing={ICON_BUTTON_SIZING.overlay}
      {...others}
    >
      {expanded && <Icon icon="arrow-from-right" />}
      {!expanded && <Icon icon="arrow-from-left" />}
    </BaseIconButton>
  );
}

ExpandTableTab.propTypes = {
  expanded: PropTypes.bool,
  scrolled: PropTypes.bool,
};

ExpandTableTab.defaultProps = {
  expanded: undefined,
  scrolled: undefined,
};
