import { useCallback, useEffect, useMemo, useRef } from 'react';
import { PageSizing } from 'components/Layout/PageContentWidth';
import Section from 'components/Wizards/CustomObject/components/Section';
import Row from 'components/Wizards/CustomObject/components/Row';
import { FIELD_TYPES } from 'utility/constants';
import { isMobile, useWindowSize } from 'app/spacing';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useBoardViewSettings } from './useBoardViewSettings';
import Preview from './subsections/previews/BoardViewPreview';
import StageSettingsSubsection from './subsections/StageSettings';
import CardDisplaySettingsSubsection from './subsections/CardDisplaySettigns';
import { DefaultColumns } from './subsections/DefaultColumns';
import {
  CUSTOM_LAYOUT_TABS,
  PIPELINE_CO,
} from 'components/Wizards/CustomObject/utilities';
import { useTranslation } from 'react-i18next';
import { TabsButton } from 'components/Inputs/Select/TabsButton';
import { useCustomObjectWizard } from '../../CustomObjectWizardContext';
import {
  TabsWrapper,
  StyledLoader,
  DesktopBuilderCard,
  VerticalSpacer,
  StyledCols,
  StageSettingsSubsectionWrapper,
} from './styles';
import { RecordLayoutBuilder } from 'ts-components/RecordLayout/Builder';
import { useHistory } from 'react-router-dom';
import { isEntityNameField } from 'checks/fields';

export default function CustomLayout({
  label,
  updateStepField,
  objectContainsWorkflow,
  formData,
  isCreateMode,
  errors,
  setDirty,
  setStagedLayoutChanges,
  stagedLayoutChanges,
  handleSaveLayoutChanges,
  layoutErrors,
  setLayoutErrors,
  ...props
}) {
  const {
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    modalProps,
    isDirty,
  } = useCustomObjectWizard();
  const history = useHistory();
  const goingRef = useRef();
  const secondarySettingsRef = useRef();
  const { t } = useTranslation();

  const {
    loading,
    categorizedFields = EMPTY_ARRAY,
    model = EMPTY_OBJECT,
    stagedValues,
    demoTeamMember,
    setStagedValues,
    errors: boardViewErrors,
  } = useBoardViewSettings({
    id: formData.id,
    isPipeline: formData.objectType === PIPELINE_CO,
    customLayoutSelectedTab,
  });

  const fields = categorizedFields
    .flatMap((c) => c.fields)
    .filter((f) => !f.isHidden);

  const fieldData = useMemo(() => {
    const numberFields = [];
    const priceFields = [];
    let nameField;

    fields.forEach((item) => {
      if (!item.isDefault) {
        if (
          item.fieldType === FIELD_TYPES.Decimal.type ||
          item.fieldType === FIELD_TYPES.Integer.type
        ) {
          numberFields.push({
            label: item.displayName,
            value: item.id,
            showExplanation: true,
            showSecondaryExplanation: true,
          });
        }
        if (item.fieldType === FIELD_TYPES.Money.type) {
          priceFields.push({
            label: item.displayName,
            value: item.id,
            showExplanation: false,
            showSecondaryExplanation: false,
          });
        }
      }

      if (isEntityNameField(item)) {
        nameField = item.displayName;
      }
    });

    return {
      nameField,
      numericCustomFields: [...numberFields, ...priceFields],
    };
  }, [fields]);

  const fieldsOptions = useMemo(
    () =>
      formData.objectType === PIPELINE_CO &&
      categorizedFields.map((item) => ({
        label: item.name,
        options: item.fields.reduce((acc, el) => {
          if (
            (stagedValues?.fields || []).some(
              (field) => field.value === el.id
            ) ||
            el.isHidden // KZN-6896
          ) {
            return acc;
          }

          return [
            ...acc,
            {
              label: el.displayName,
              value: el.id,
              field: el,
            },
          ];
        }, []),
      })),
    [categorizedFields, formData.objectType, stagedValues?.fields]
  );

  const { width } = useWindowSize();
  const isMobileView = isMobile(width);

  //callbacks
  const handleChangeValues = useCallback(
    (data) => {
      setStagedValues(data);
      setDirty(true);
    },
    [setDirty, setStagedValues]
  );

  const handleClickVisibility = useCallback(
    (opt) => {
      setStagedValues((prev) => ({
        ...prev,
        [opt]: !prev[opt],
      }));
      setDirty(true);
    },
    [setDirty, setStagedValues]
  );

  const handleSaveField = useCallback(
    (field) => {
      setStagedValues((prev) => ({
        ...prev,
        fields: [...prev.fields, field],
      }));
      setDirty(true);
    },
    [setDirty, setStagedValues]
  );

  const handleChangeOrder = useCallback(
    (arr) => {
      setStagedValues((prev) => ({ ...prev, fields: arr }));
      setDirty(true);
    },
    [setDirty, setStagedValues]
  );

  const handleDeleteField = useCallback(
    (id) => {
      setStagedValues((prev) => ({
        ...prev,
        fields: prev.fields.filter((item) => item.value !== id),
      }));
      setDirty(true);
    },
    [setDirty, setStagedValues]
  );

  useEffect(() => {
    if (history.location?.state?.customLayoutTabKey) {
      setCustomLayoutSelectedTab(history.location.state.customLayoutTabKey);
      history.replace({
        ...history.location,
        state: {
          ...history.location.state,
          customLayoutTabKey: null,
        },
      });
    }
  }, [history, setCustomLayoutSelectedTab]);

  useEffect(() => {
    if (!isDirty && goingRef.current) {
      setCustomLayoutSelectedTab(goingRef.current);
      goingRef.current = null;
    }
  }, [isDirty, setCustomLayoutSelectedTab]);

  const subroutes = useMemo(
    () =>
      [
        {
          tabKey: CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT,
          label: t('Record Layout'),
        },
        formData.objectType === PIPELINE_CO && {
          tabKey: CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT,
          label: t('Board View Layout'),
        },
        {
          tabKey: CUSTOM_LAYOUT_TABS.DEFAULT_COLUMNS,
          label: t('Default Columns'),
        },
      ].filter(Boolean),
    [formData.objectType, t]
  );

  const isClient = formData.fetchUrl === 'client';

  return (
    <>
      <TabsWrapper>
        <TabsButton
          onSelectTab={(value) => {
            if (isDirty) {
              goingRef.current = value.tabKey;
              modalProps.intermediate.modal.show();
            } else {
              setCustomLayoutSelectedTab(value.tabKey);
            }
          }}
          options={subroutes}
          selectedTab={customLayoutSelectedTab}
        />
      </TabsWrapper>
      <PageSizing>
        {customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT ? (
          <>
            <DesktopBuilderCard
              {...props}
              data-qa={`${CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT}_tab`}
            >
              <StyledLoader loading={loading}>
                <Section>
                  <RecordLayoutBuilder
                    oldData={formData}
                    existing={formData?.meta?.recordDetailLayout}
                    objectId={formData.id}
                    setStagedLayoutChanges={setStagedLayoutChanges}
                    stagedLayoutChanges={stagedLayoutChanges}
                    handleSaveLayoutChanges={handleSaveLayoutChanges}
                    layoutErrors={layoutErrors}
                    setLayoutErrors={setLayoutErrors}
                    secondarySettingsContainer={secondarySettingsRef}
                    categorizedFields={categorizedFields}
                    isClient={isClient}
                  />
                </Section>
              </StyledLoader>
            </DesktopBuilderCard>
            <div ref={secondarySettingsRef} key={customLayoutSelectedTab} />
          </>
        ) : null}
        {formData.objectType === PIPELINE_CO &&
        customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT ? (
          <DesktopBuilderCard
            {...props}
            data-qa={`${CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT}_tab`}
          >
            {loading ? (
              <StyledLoader loading />
            ) : (
              <Section>
                <Row>
                  <StyledCols columns={2} gutter={'20px'}>
                    <StageSettingsSubsectionWrapper>
                      <StageSettingsSubsection
                        values={stagedValues}
                        onChange={handleChangeValues}
                        trackValue={model.trackEntityValue}
                        numericCustomFields={fieldData.numericCustomFields}
                        isMobile={isMobileView}
                        errors={boardViewErrors}
                      />
                      {!isMobileView && (
                        <CardDisplaySettingsSubsection
                          values={stagedValues}
                          onClickVisibility={handleClickVisibility}
                          nameField={fieldData.nameField}
                          fieldsOptions={fieldsOptions}
                          fields={fields}
                          onSaveField={handleSaveField}
                          onChangeOrder={handleChangeOrder}
                          onDeleteField={handleDeleteField}
                        />
                      )}
                    </StageSettingsSubsectionWrapper>
                    <VerticalSpacer className="verticalSpacer" />
                    <div>
                      <Preview
                        values={stagedValues}
                        stageName={model.pipeline?.stages[0].name}
                        fields={fields}
                        teamMember={demoTeamMember}
                        isMobile={isMobileView}
                      />
                    </div>
                  </StyledCols>
                </Row>
              </Section>
            )}
          </DesktopBuilderCard>
        ) : null}
        {customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.DEFAULT_COLUMNS ? (
          <DesktopBuilderCard
            {...props}
            data-qa={`${CUSTOM_LAYOUT_TABS.DEFAULT_COLUMNS}_tab`}
          >
            <StyledLoader loading={loading}>
              <Section>
                <DefaultColumns
                  updateStepField={updateStepField}
                  model={formData}
                  fields={fields}
                  categorizedFields={categorizedFields}
                />
              </Section>
            </StyledLoader>
          </DesktopBuilderCard>
        ) : null}
      </PageSizing>
    </>
  );
}
