import { IconSizing } from 'components/Kizen/Icon';
import { useTooltip } from 'components/Kizen/Tooltip';
import { useTranslation } from 'react-i18next';
import { CenteredIcon, IconWrapper } from './styles';

const Icon = ({ size = '16px', color, icon }) => (
  <IconSizing size={size}>
    <CenteredIcon color={color} icon={icon} />
  </IconSizing>
);

export const EventFilterIcon = ({
  className,
  color,
  icon,
  size,
  onClick,
  getTooltip,
}) => {
  const { t } = useTranslation();
  const [tooltipProps, tooltip] = useTooltip(
    { tooltipLabel: getTooltip(t) },
    () => false
  );
  return (
    <IconWrapper
      color={color}
      className={className}
      onClick={onClick}
      {...tooltipProps}
      isHoverable
    >
      {tooltip}
      <Icon size={size} color={color} icon={icon} />
    </IconWrapper>
  );
};

export const EventIcon = ({ className, color, icon, size }) => (
  <IconWrapper color={color} className={className}>
    <Icon size={size} color={color} icon={icon} />
  </IconWrapper>
);
