import React, { useRef, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import useClickAway from 'react-use/lib/useClickAway';

// Just a utility. This has been pulled into its own component
// so that we only register clickaway listeners while edit views
// are currently displayed, rather than for each field on the page.

const ClickAway = React.forwardRef(
  ({ component: Component, onClickAway, ...others }, ref) => {
    const clickAwayRef = useRef(null);
    useClickAway(
      clickAwayRef,
      (...args) => onClickAway && onClickAway(...args)
    );
    const refs = useCallback(
      (val) => {
        clickAwayRef.current = val;
        if (ref) {
          ref.current = val;
        }
      },
      [ref, clickAwayRef]
    );
    return <Component ref={refs} {...others} />;
  }
);

ClickAway.displayName = 'ClickAway';

ClickAway.propTypes = {
  onClickAway: PropTypes.func,
  component: PropTypes.elementType,
};

ClickAway.defaultProps = {
  onClickAway: null,
  component: 'div',
};

export default ClickAway;
