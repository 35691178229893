import styled from '@emotion/styled';
import { Panel, VerticalTile } from '@kizen/kds/Tile';
import { StaticContentBlock } from '@kizen/page-builder/viewer';
import Color from 'color';
import {
  CSSProperties,
  Fragment,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useBreakpoint } from 'app/spacing';
import {
  useBusinessConfigQuery,
  useBusinessCountriesQuery,
} from 'queries/models/business';
import {
  useContactRecordQuery,
  useCustomObjectRecordQuery,
} from 'queries/models/custom-objects';
import { useDisplayNamesQuery } from 'queries/models/records';
import { camelToSnakeCaseKeys } from 'services/helpers';
import {
  FieldValue,
  getBusinessMergeFieldLookup,
  getClientFieldValues,
  getMergeFieldLookupForFields,
  getTeamMemberMergeFieldLookup,
  replaceMergeFields,
} from 'utility/merge-fields';
import { withErrorBoundary } from '../ErrorBoundary';
import ScrollFadeContainer from '__components/Kizen/ScrollFadeContainer';
import Loader from '__components/Kizen/Loader';
import { toastVariant, useToast } from '__components/ToastProvider';
import { useTranslation } from 'react-i18next';
import nodes from './nodes';

import TableScrollContainer from '__components/Tables/ScrollContainer';
import useExpandingVerticalScrollbar from 'hooks/useExpandingVericalScrollbar';
import { useRecordLayoutLogActivityContext } from 'ts-components/RecordLayout/RecordLayoutLogActivityContext';
import { useRecordLayoutStartAutomationContext } from 'ts-components/RecordLayout/RecordLayoutStartAutomationContext';

const ScrollWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  border-radius: 8px;
`;

const StyledScrollContainer = styled(TableScrollContainer)`
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  overflow-y: auto;
  height: 100%;

  & > div,
  & .ScrollChildren,
  & .ScrollChildren > div:first-child {
    width: 100%;
  }

  & .ScrollChildren > div:last-child {
    margin-left: -8px;
    right: 0;
    z-index: 16;
  }

  &
    .gradient-vertical-container
    .ScrollChildren
    .gradient-vertical-container
    > div:first-child
    > div {
    z-index: 10;
    pointer-events: none;
  }

  &
    .gradient-vertical-container
    .ScrollChildren
    .gradient-vertical-container
    > div:first-child {
    z-index: 10;
    pointer-events: none;
  }
`;

interface CustomContentStyle extends CSSProperties {
  '--custom-content-block-height'?: string;
}

type Scrolled = [boolean, boolean, boolean, boolean];

type FieldLike = {
  displayName: string;
  fieldType: string;
  id: string;
  isDefault: boolean;
  name: string;
  originalSnakeCaseName: string;
  relation?: FieldValue['relation'];
  options?: any[];
};

type CustomContnetBlockProps = {
  blockJson: any;
  fields: FieldLike[];
  id: string;
  isClient: boolean;
  javascriptActions: {
    id: string;
    name: string;
    script: string;
  }[];
  objectId: string;
  objectName: string;
  objectType: 'pipeline' | 'standard';
};

type StaticBlockWrapperProps = PropsWithChildren<{
  height: number;
  isMobile: boolean;
}>;

const getSnakeCaseName = (field: FieldLike) => {
  if (field.originalSnakeCaseName === undefined) {
    return field.name;
  }
  return field.originalSnakeCaseName;
};

const getDefaultFieldValues = (
  record: any,
  defaultFields: FieldLike[]
): Omit<FieldValue, 'name' | 'relation'>[] => {
  return defaultFields.map((field: FieldLike) => {
    if (getSnakeCaseName(field) === 'email_status') {
      const option = field.options?.find(
        (opt: any) => opt.code === record.email_status
      );
      return {
        displayName: field.displayName,
        id: field.id,
        value: { name: option?.name ?? '' },
        fieldType: field.fieldType,
      };
    }

    if (getSnakeCaseName(field) === 'percentage_chance_to_close') {
      return {
        displayName: field.displayName,
        id: field.id,
        value: record[field.name] ?? record.stage.percentageChanceToClose,
        fieldType: field.fieldType,
      };
    }

    return {
      displayName: field.displayName,
      id: field.id,
      value: record[field.name],
      fieldType: field.fieldType,
    };
  });
};

const StaticBlockWrapper = ({
  children,
  isMobile,
  height,
}: StaticBlockWrapperProps) => {
  const { containerProps } = useExpandingVerticalScrollbar();
  const [scrolled, setScrolled] = useState<Scrolled>([true, true, true, true]);

  if (isMobile) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <ScrollWrapper style={{ height: `${height}px` }}>
      <StyledScrollContainer
        {...containerProps}
        className="rounded-lg"
        onChangeScrolled={(scroll: Scrolled) => {
          setScrolled(scroll);
        }}
      >
        {height < 105 ? (
          children
        ) : (
          <ScrollFadeContainer top bottom scrolled={scrolled}>
            {children}
          </ScrollFadeContainer>
        )}
      </StyledScrollContainer>
    </ScrollWrapper>
  );
};

const Block = (props: CustomContnetBlockProps) => {
  const {
    blockJson,
    fields,
    id,
    isClient,
    javascriptActions,
    objectId,
    objectName,
    objectType,
  } = props;
  const auth = useSelector((state: any) => state.authentication);
  const [showToast] = useToast();
  const { t } = useTranslation();
  const isMobile = useBreakpoint();
  const { openLogActivityModal } = useRecordLayoutLogActivityContext();
  const { startAutomation } = useRecordLayoutStartAutomationContext();

  const { isTransparent, style } = useMemo<{
    isTransparent: boolean;
    style: CustomContentStyle;
  }>(() => {
    if (!blockJson) {
      return { isTransparent: false, style: {} };
    }

    const background = Color(blockJson.ROOT.props.backgroundColor).object();
    const height = blockJson.ROOT.props.height;

    return {
      style: { '--custom-content-block-height': `${height}px` },
      isTransparent: background.alpha === 0,
    };
  }, [blockJson]);

  const { data: roles, isLoading: loadingRoles } = useDisplayNamesQuery<
    string[]
  >(
    { role_ids: auth.teamMember.roles },
    {
      select: (res) => {
        return res.data.results.roles.map((role) => role.display_name);
      },
    }
  );

  const { data: countries, isLoading: loadingCountries } =
    useBusinessCountriesQuery(true);

  const { data: publicData, isLoading: loadingPublicData } =
    useBusinessConfigQuery(true);

  const mergeFieldNames = useMemo(() => {
    const textContent: string[] = Object.values(blockJson)
      .filter((node: any) => node.type.resolvedName === 'Text')
      .map((node: any) => node.custom.text);
    const ids = textContent.reduce((acc, text) => {
      const matches = text.matchAll(
        new RegExp(`{{\\s*${objectName}\\.([A-Za-z0-9_]*)\\s*}}`, 'g') // {{ object_name.field_name }}
      );
      for (const match of matches) {
        if (match[1]) {
          acc.add(match[1]);
        }
      }
      return acc;
    }, new Set());

    return [...ids];
  }, [objectName, blockJson]);

  const { data: customObject, isLoading: loadingRecord } =
    useCustomObjectRecordQuery(
      objectId,
      id,
      objectType,
      {
        field_names: String(mergeFieldNames),
      },
      { enabled: !isClient }
    );

  const { data: contact, isLoading: loadingContact } = useContactRecordQuery(
    id,
    { field_names: String(mergeFieldNames) },
    { enabled: isClient }
  );

  const isLoading =
    loadingRoles ||
    loadingCountries ||
    loadingPublicData ||
    loadingRecord ||
    loadingContact;

  const textTransform = useMemo(() => {
    if (isLoading) {
      return null;
    }

    const record = isClient ? camelToSnakeCaseKeys(contact) : customObject;
    const defaultFields = fields.filter((field: FieldLike) => field.isDefault);
    const fieldsById = fields.reduce<Record<string, FieldLike>>(
      (acc, field) => {
        acc[field.id] = field;
        return acc;
      },
      {}
    );
    const recordFields: FieldValue[] = isClient
      ? getClientFieldValues(fields, record)
      : Object.values(record.fields);
    const fieldValues: FieldValue[] = Array.from(
      [...getDefaultFieldValues(record, defaultFields), ...recordFields],
      (field) => {
        const fieldId = field.id ?? field.field!;

        return {
          ...field,
          name: isClient
            ? fieldsById[fieldId].name
            : fieldsById[fieldId].originalSnakeCaseName,
          relation: fieldsById[fieldId].relation,
        };
      }
    );
    const queriedFields = fields.filter((field) => {
      return mergeFieldNames.includes(field.name);
    });
    const missingFieldValues = queriedFields
      .filter((field) => {
        return !fieldValues.some(
          (fv) => fv.id === field.id || fv.name === field.name
        );
      })
      .reduce<FieldValue[]>((acc, field) => {
        if (field.fieldType === 'checkbox') {
          acc.push({
            id: field.id,
            fieldType: field.fieldType,
            name: field.name,
            value: false,
          });
        }
        return acc;
      }, []);

    const lookup = {
      [objectName]: getMergeFieldLookupForFields(
        fieldValues.concat(missingFieldValues),
        t
      ),
      business: getBusinessMergeFieldLookup({
        business: auth.chosenBusiness,
        countries,
        phoneRegions: publicData?.phoneRegions,
      }),
      team_member: getTeamMemberMergeFieldLookup({
        teamMember: auth.teamMember,
        roles,
      }),
    };

    return (content: string) => {
      return replaceMergeFields(content, lookup);
    };
  }, [
    t,
    isLoading,
    isClient,
    objectName,
    auth,
    countries,
    publicData?.phoneRegions,
    roles,
    contact,
    customObject,
    fields,
    mergeFieldNames,
  ]);

  return (
    <VerticalTile
      enableBoxShadow={Boolean(blockJson.ROOT.props.hasShadow)}
      transparent={isTransparent}
      padding={0}
      middle={
        <Panel>
          <StaticBlockWrapper
            height={blockJson.ROOT.props.height}
            isMobile={isMobile}
          >
            {isLoading ? (
              <div
                style={style}
                className="h-[var(--custom-content-block-height)]"
              >
                <Loader loading />
              </div>
            ) : (
              <StaticContentBlock
                borderRadius="8px"
                data={blockJson}
                nodes={nodes}
                applyMobileBreakpoints={isMobile}
                fullHeight={isMobile}
                javascriptActions={javascriptActions}
                textTransform={textTransform}
                entityId={id}
                objectId={objectId}
                onLogActivityButtonClick={(activityId: string) => {
                  openLogActivityModal(activityId);
                }}
                onStartAutomationButtonClick={(automationId: string) => {
                  startAutomation(automationId);
                }}
                handleScriptError={(e: unknown) => {
                  showToast({
                    message: `${t('Script could not be executed')}: ${
                      (e as Error)?.message
                    }`,
                    variant: toastVariant.FAILURE,
                  });
                }}
              />
            )}
          </StaticBlockWrapper>
        </Panel>
      }
    />
  );
};

export const CustomContentBlock = withErrorBoundary(Block);
