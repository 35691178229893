import React, { useCallback, useRef, useEffect } from 'react';
import FileService from 'services/FileService';
import FileInputWithImage, { ACCEPTED_FILES } from '.';
import {
  monitoringExceptionHelper,
  monitoringMessageHelper,
} from 'sentry/helpers';

export default function ImageFileInputWithUpload(props) {
  // We put all the props behind a ref because our callback is async,
  // and we'll need to eventually react using up-to-date values.
  const propsRef = useRef();
  useEffect(() => {
    propsRef.current = props;
  });

  const handleChange = useCallback(async (updatedFilesList) => {
    const updatedFiles = [...updatedFilesList];
    propsRef.current.processing && propsRef.current.processing(true);
    const filesType = !!updatedFiles.length && updatedFiles[0].type;
    if (
      updatedFiles.length &&
      (!filesType ||
        !ACCEPTED_FILES.split(',').some((item) => filesType === item))
    ) {
      propsRef.current.onChange({
        error: true,
      });
      propsRef.current.processing && propsRef.current.processing(false);
      monitoringMessageHelper('File that is being uploaded is not an image!');
      return;
    }

    const createFiles = updatedFiles
      .filter((obj) => obj instanceof File)
      .map((obj) => {
        // Optimistically give this file an id
        obj.$id = FileService.createId();
        return obj;
      });

    // Give the consumer a "tentative" file record
    await propsRef.current.onChange(
      updatedFiles.map((obj) => {
        if (obj instanceof File) {
          return {
            id: obj.$id,
            name: obj.name,
            created: false,
            originalObject: [obj],
          };
        }
        return obj;
      })
    );
    // Now reach out to the API to create and delete the appropriate files,
    // eventually replacing "tentatively" created files with the records themselves.
    await Promise.all([
      Promise.all(
        createFiles.map(async (file) => {
          try {
            const fileRecord = await FileService.upload({
              file,
              id: file.$id,
              publicFile: true,
            });
            propsRef.current.onChange(
              [].concat(propsRef.current.file).map((current) => {
                if (current.id === fileRecord.id) {
                  return fileRecord;
                }
                return current;
              })
            );
          } catch (err) {
            monitoringExceptionHelper(err);
          }
        })
      ),
    ]);
    propsRef.current.processing && propsRef.current.processing(false);
  }, []);

  return <FileInputWithImage {...props} onChange={handleChange} />;
}
