import { colorsPrimary } from 'app/colors';
import { FIELD_TYPES } from 'utility/constants';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import FieldsTable from '../FieldsTable';
import { CardHeader } from '../components/CardHeader';
import { initiatorTypes } from '../../constants';
import {
  defaultEmployeeString,
  defaultAutomationString,
  defaultFormString,
  defaultSurveyString,
  defaultSmartConnectorString,
} from '../helpers';

const cardHeaderConfig = (t) => {
  const prefix = t('updated by');
  return {
    [initiatorTypes.employee]: {
      prefix,
      creationType: defaultEmployeeString,
    },
    [initiatorTypes.employee_upload]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via Upload'),
    },
    [initiatorTypes.employee_api_request]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via API'),
    },
    [initiatorTypes.automation]: {
      prefix,
      creationType: defaultAutomationString,
    },
    [initiatorTypes.smart_connector]: {
      prefix,
      creationType: defaultSmartConnectorString,
    },
    [initiatorTypes.form]: {
      prefix,
      creationType: defaultFormString,
    },
    [initiatorTypes.survey]: {
      prefix,
      creationType: defaultSurveyString,
    },
    [initiatorTypes.system]: {
      prefix,
      creationType: () => t('Kizen Administrator'),
    },
    [initiatorTypes['empty-initiator']]: {
      prefix,
    },
  };
};

const FieldUpdatedEventBlock = ({ event, ...others }) => {
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsPrimary.purple.dark}
          icon="id-card"
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={event.data.fieldDisplayName}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    >
      {({ isExpanded, maxHeight, maxLines, lineHeight }) => (
        <FieldsTable
          fields={event.data.fields}
          dataQA={event.data.fieldDisplayName}
          isExpanded={isExpanded}
          maxHeight={maxHeight}
          maxLines={maxLines}
          lineHeight={lineHeight}
        />
      )}
    </BasicBlock>
  );
};

export default FieldUpdatedEventBlock;

const commonFields = {
  new: { label: (t) => t('New Value') },
  old: { label: (t) => t('Old Value') },
};

const complexFieldMapping = {
  relationship: (el) =>
    typeof el.displayValue === 'string'
      ? { id: el.value, name: el.displayValue }
      : { id: el.value, ...el.displayValue },
  dynamictags: (el) => ({ name: el.displayValue }),
  checkboxes: (el) => ({ name: el.displayValue }),
};

const getSummarizedLabel = (fieldType, newCount = 0, t) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return t('{{newCount}} Related Entities', { newCount });
    case FIELD_TYPES.DynamicTags.type:
    case FIELD_TYPES.Checkboxes.type:
      return newCount > 0
        ? t('{{newCount}} Selected Options', { newCount })
        : '';
    default:
      return '';
  }
};

const complexCases = [
  FIELD_TYPES.Relationship.type,
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Checkboxes.type,
];
const complexFields = {
  new: {
    label: (t) => t('New Value'),
    callback: ({ new: newValues, fieldType, isSummarized, newCount }, t) => {
      if (isSummarized) {
        return getSummarizedLabel(fieldType, newCount, t);
      }
      return newValues ? newValues.map(complexFieldMapping[fieldType]) : null;
    },
  },
  add: {
    label: (t) => t('Values Added'),
    callback: ({ new: newValues, old, fieldType, isSummarized, added }) => {
      if (isSummarized) {
        return (added || []).map(complexFieldMapping[fieldType]);
      }
      if (!old) return (newValues || []).map(complexFieldMapping[fieldType]);

      return (newValues || [])
        .filter(({ value: id1 }) => !old.some(({ value: id2 }) => id2 === id1))
        .map(complexFieldMapping[fieldType]);
    },
  },
  remove: {
    label: (t) => t('Values Removed'),
    callback: ({ new: newValues, old, fieldType, isSummarized, removed }) => {
      if (isSummarized) {
        return (removed || []).map(complexFieldMapping[fieldType]);
      }
      if (!newValues) return old.map(complexFieldMapping[fieldType]);

      if (!old) return null;
      return old
        .filter(
          ({ value: id1 }) => !newValues.some(({ value: id2 }) => id2 === id1)
        )
        .map(complexFieldMapping[fieldType]);
    },
  },
};

const parseItemToFloat = (item) =>
  item.displayValue === null
    ? item.displayValue
    : parseFloat(`${item.displayValue}`.replaceAll(',', ''));

const GET_VALUE = {
  [FIELD_TYPES.Checkbox.type]: (item) => item.value,
  [FIELD_TYPES.Date.type]: (item) => item.value,
  [FIELD_TYPES.DateTime.type]: (item) => item.value,
  [FIELD_TYPES.Email.type]: (item) => item.value,
  [FIELD_TYPES.LongText.type]: (item) => item.value,
  [FIELD_TYPES.Integer.type]: (item) => parseItemToFloat(item),
  [FIELD_TYPES.Decimal.type]: (item) => parseItemToFloat(item),
  [FIELD_TYPES.Rating.type]: (item) => item.value,
  [FIELD_TYPES.Text.type]: (item) => item.value,
  [FIELD_TYPES.Selector.type]: (item) => item.displayValue, // we need this type for timeline
  [FIELD_TYPES.TeamSelector.type]: (item) => item.displayValue,
  [FIELD_TYPES.Money.type]: (item) => item.displayValue,
  [FIELD_TYPES.PhoneNumber.type]: (item) => item.value,
  [FIELD_TYPES.YesNoMaybe.type]: (item) =>
    item.displayValue ? { name: item.displayValue, id: item.value } : null,
  [FIELD_TYPES.Dropdown.type]: (item) => {
    if (!item.displayValue) return null;
    if (typeof item.displayValue === 'string') {
      return { name: item.displayValue, id: item.value };
    }
    return item.displayValue;
  },
  [FIELD_TYPES.Timezone.type]: (item) => {
    if (!item.displayValue) return null;
    if (typeof item.displayValue === 'string') {
      return { name: item.displayValue, id: item.value };
    }
    return item.displayValue;
  },
  [FIELD_TYPES.Radio.type]: (item) =>
    item.displayValue ? { name: item.displayValue, id: item.value } : null,
  [FIELD_TYPES.Status.type]: (item) =>
    item.displayValue ? { name: item.displayValue, id: item.value } : null,
  [FIELD_TYPES.Files.type]: (item) =>
    item ? item.map((e) => ({ ...e, ...e.displayValue })) : null,
  // complex
  [FIELD_TYPES.Relationship.type]: (item, data, t) =>
    complexFields[item].callback(data, t),
  [FIELD_TYPES.DynamicTags.type]: (item, data, t) =>
    complexFields[item].callback(data, t),
  [FIELD_TYPES.Checkboxes.type]: (item, data, t) =>
    complexFields[item].callback(data, t),
};

export const getFieldUpdatedEvent = (event, t) => {
  const { data, id } = event;

  const isComplexCase = complexCases.includes(data.fieldType);
  const fieldCase = isComplexCase ? complexFields : commonFields;

  const fields = Object.keys(fieldCase).reduce((acc, elem) => {
    const item = isComplexCase ? elem : data[elem];

    return acc.concat({
      label: fieldCase[elem].label(t),
      value: GET_VALUE[data.fieldType](item, data, t),
      type: data.fieldType,
      id: `${id}::${elem}`,
      metaInfo: data.field,
    });
  }, []);

  return {
    ...event,
    data: {
      ...data,
      fields,
    },
  };
};
