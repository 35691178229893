import { RefObject, useCallback, useRef, useState } from 'react';
import { useElementSize } from '../hooks/useElementSize';

export const useTooltip = (
  always?: boolean
): {
  triggerProps: {
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
  };
  targetProps: {
    ref: RefObject<HTMLSpanElement>;
  };
  showTooltip: boolean;
  tooltipProps: {
    target: HTMLSpanElement | null;
  };
} => {
  const textRef = useRef<HTMLSpanElement>(null);
  const { width: textWidth, scrollWidth: textScrollWidth } =
    useElementSize(textRef);

  const [showTooltip, setShowTooltip] = useState(false);

  const overflowAmount = textScrollWidth - textWidth;

  const textIsOverflowing = Math.abs(overflowAmount) > 1;

  const handleMouseOver = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return {
    triggerProps: {
      onMouseOver: textIsOverflowing || always ? handleMouseOver : undefined,
      onMouseLeave: textIsOverflowing || always ? handleMouseLeave : undefined,
    },
    targetProps: {
      ref: textRef,
    },
    showTooltip,
    tooltipProps: {
      target: textRef.current,
    },
  };
};
