import { PropsWithChildren, ReactElement } from 'react';
import ScrollFadeContainer from '__components/Kizen/ScrollFadeContainer';
import { useScrollBar } from './useScrollBar';
import { StyledDetachedScrollBar } from './styles';

type Axis = 'x' | 'y';

type ScrollbarProps = {
  axis: Axis;
  topOffset?: number;
  zedIndex?: number;
  setInteracting?: (interacting: boolean) => void;
};

const ScrollBar = ({
  axis,
  topOffset,
  zedIndex,
  setInteracting,
}: ScrollbarProps) => {
  const [innerSize, outerProps] = useScrollBar(axis, setInteracting);
  return (
    <StyledDetachedScrollBar
      direction={axis === 'x' ? 'horizontal' : 'vertical'}
      size={innerSize}
      topOffset={topOffset}
      zedIndex={zedIndex}
      {...outerProps}
    />
  );
};

type FlowScrollContainerProps = {
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
  gradient?: ReactElement;
  topOffset?: number;
  setInteracting?: (interacting: boolean) => void;
};

export const FlowScrollContainer = ({
  children,
  topOffset,
  setInteracting,
  top,
  bottom,
  left,
  right,
  gradient,
}: PropsWithChildren<FlowScrollContainerProps>) => {
  return (
    <ScrollFadeContainer
      className="FlowContainer"
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      gradient={gradient}
    >
      {children}
      <ScrollBar
        axis="x"
        topOffset={topOffset}
        setInteracting={setInteracting}
        zedIndex={right ? 6 : 4}
      />
      <ScrollBar
        axis="y"
        topOffset={topOffset}
        setInteracting={setInteracting}
        zedIndex={bottom ? 6 : 4}
      />
    </ScrollFadeContainer>
  );
};
