import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '__components/Inputs/TextInput';

type NameInputProps = {
  value: string;
  onChange: (value: string) => string;
  error?: string[];
  validate?: any;
};

export const NameInput = ({
  value,
  onChange,
  error,
  validate,
}: NameInputProps) => {
  const { t } = useTranslation();

  const [innerValue, setInnerValue] = useState<string>(value);

  const handleBlur = () => {
    const changed = onChange(innerValue);
    setInnerValue(changed);
  };

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return (
    <TextInput
      value={innerValue}
      label={t('Variable Name')}
      placeholder={t('Enter Name')}
      onChange={setInnerValue}
      onBlur={handleBlur}
      error={error}
      validate={validate}
    />
  );
};
