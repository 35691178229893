import styled from '@emotion/styled';

export const TotalValue = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: ${({ topMargin }) => `${topMargin}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 0;
`;

export const Chart = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

export const ChartContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`;

export const StyledExplanation = styled.div`
  width: 50%;
  text-align: center;
  margin-bottom: 10px;
`;

export const TotalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => `${fullWidth ? '60' : '80'}`}%;
  margin-top: 10px;
`;
