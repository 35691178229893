import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { appBackground } from '__app/colors';
import { borderRadii } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import TextInput from '__components/Inputs/TextInput';
import BasicModal from '__components/Modals/presets/BasicModal';

type ConfrimBulkActionModalProps = {
  isAllRecordsAction: boolean;
  recordCount: number;
  onConfirm(): void;
  onHide(): void;
};

type ConfirmTextProps = {
  confirmText: string;
  recordCount: string;
};

const LOCALE = navigator.language;
const NumberFormatter = new Intl.NumberFormat(LOCALE);

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const TextWrapper = styled.div`
  line-height: 1.25;
  max-width: 710px;
`;

const BlueBackground = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
  border-radius: ${borderRadii.small};
  background-color: ${appBackground};
`;

const AllRecordConfirmationText = ({
  confirmText,
}: Omit<ConfirmTextProps, 'recordCount'>) => {
  const { t } = useTranslation();

  return (
    <BlueBackground>
      <TextWrapper>
        <KizenTypography as="span">
          {t(
            'This action will be performed on a large number of records and may take a long time to process, depending on data complexity. You must confirm the intention for this to be performed on'
          )}
        </KizenTypography>{' '}
        <KizenTypography
          as="span"
          weight="bold"
          textTransform="uppercase"
          textDecoration="underline"
        >
          {t('all records')}
        </KizenTypography>{' '}
        <KizenTypography as="span">
          {t(
            'within this object to proceed. Please type "{{confirmText}}" in the field below.',
            { confirmText }
          )}
        </KizenTypography>
      </TextWrapper>
    </BlueBackground>
  );
};

const OverRecordLimitConfirmationText = ({
  confirmText,
  recordCount,
}: ConfirmTextProps) => {
  const { t } = useTranslation();

  return (
    <BlueBackground>
      <TextWrapper>
        <KizenTypography as="span">
          {t(
            'This action will be performed on a large number of records and may take a long time to process, depending on data complexity. You must confirm the intention for this to be performed on'
          )}
        </KizenTypography>{' '}
        <KizenTypography
          as="span"
          weight="bold"
          textTransform="uppercase"
          textDecoration="underline"
        >
          {t('{{recordCount}} records', { recordCount })}
        </KizenTypography>{' '}
        <KizenTypography as="span">
          {t(
            'within this object to proceed. Please type “{{confirmText}}” in the field below.',
            { confirmText }
          )}
        </KizenTypography>
      </TextWrapper>
    </BlueBackground>
  );
};

export const ConfrimBulkActionModal = (props: ConfrimBulkActionModalProps) => {
  const { isAllRecordsAction, recordCount, onHide, onConfirm } = props;
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const formattedCount = NumberFormatter.format(recordCount);
  const confirmText = isAllRecordsAction
    ? t('confirm all').toLocaleUpperCase(LOCALE)
    : t('confirm {{records}}', { records: formattedCount }).toLocaleUpperCase(
        LOCALE
      );
  const placeholder = t('Type {{confirmText}} here', { confirmText });

  return (
    <BasicModal
      show
      size="medium"
      typeOfContent="text"
      heading={t('Please Confirm Bulk Action')}
      buttonText={t('Confirm Action')}
      disabled={text !== confirmText}
      onConfirm={onConfirm}
      onHide={onHide}
    >
      <ModalContent>
        {isAllRecordsAction && (
          <AllRecordConfirmationText confirmText={confirmText} />
        )}
        {!isAllRecordsAction && (
          <OverRecordLimitConfirmationText
            confirmText={confirmText}
            recordCount={formattedCount}
          />
        )}
        <TextInput
          placeholder={placeholder}
          value={text}
          onChange={(value: string) => setText(value)}
        />
      </ModalContent>
    </BasicModal>
  );
};
