import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { FIELD_TYPES } from '../../../../utility/constants';
import TextInput from '..';
import TextAdornment from '../../Adornments/TextAdornment';
import { toDecimalNumber } from './DecimalNumber';

import {
  validateWholeNumber,
  negativesValidateWholeNumber,
} from '../NumericValidation/helpers';
import { withDecimalNumberValidation } from '../NumericValidation';

const PriceNumberTextInput = React.forwardRef(
  ({ startAdornment, currencySymbol, ...props }, ref) => {
    const { allowNegatives = true, value, disabled, variant } = props;
    const { t } = useTranslation();

    return (
      <TextInput
        ref={ref}
        // The empty, disabled state needs to allow "—" to appear, so we disabled the NumericInput
        Input={disabled && !value ? undefined : NumberInput}
        thousandSeparator
        decimalScale={2}
        validate={{
          ...(allowNegatives
            ? negativesValidateWholeNumber(t)
            : validateWholeNumber(t)),
          ...props.validate,
        }}
        startAdornment={
          disabled && !value && variant === 'underline'
            ? null
            : startAdornment || <TextAdornment text={currencySymbol} />
        }
        {...props}
      />
    );
  }
);

export default PriceNumberTextInput;

PriceNumberTextInput.displayName = 'PriceNumberTextInput';
PriceNumberTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['outline', 'underline']),
  placeholder: PropTypes.string,
  startAdornment: PropTypes.element,
  currencySymbol: PropTypes.string,
  allowNegatives: PropTypes.bool,
};
PriceNumberTextInput.defaultProps = {
  disabled: false,
  variant: 'outline',
  placeholder: FIELD_TYPES.Money.placeholder,
  startAdornment: null,
  currencySymbol: '$',
  allowNegatives: true,
};

export const toPriceNumber = toDecimalNumber;

export const ValidatedPriceNumberTextInput =
  withDecimalNumberValidation(PriceNumberTextInput);
