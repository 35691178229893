import { comp, drop, map } from '@thi.ng/transducers';
import { useEffect, useState } from 'react';
import { useReactive } from './useReactive';
import { usePermissionsContext } from './context';

/**
 * Returns true if the name or any permission range/checkbox has been changed.
 *
 * @remarks We create a stream merge of all the source inputs in the context
 * (`hasPermissionEdits`) to know when values have been directly edited by user input.
 * This merge stream lives in the context becasue we need to add new source streams to its
 * input in `state-ops`. We do the same for the name field here and 'or' the two results.
 *
 * Remember, once `name_sub` or `permission_sub` returns true, all additional permission/name
 * edits won't emit additional `true` values and won't trigger rerenders.
 *
 * @returns - boolean
 */
export const useHasEdits = () => {
  const { name, hasPermissionEdits } = usePermissionsContext();
  const [nameEdits, setNameEdits] = useState(false);
  const permissionEdits = useReactive(hasPermissionEdits);

  useEffect(() => {
    if (name) {
      const name_sub = name.subscribe(
        { next: setNameEdits },
        {
          xform: comp(
            drop(1),
            map((v) => v !== undefined)
          ),
        }
      );

      return () => {
        name_sub.unsubscribe();
      };
    }
  }, [name]);

  return permissionEdits || nameEdits;
};
