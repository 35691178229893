import { Filter } from './filter';

export type FilterSet = {
  and: boolean;
  filters: Filter[];
};

export const isFilterSet = (x: any): x is FilterSet => {
  return (
    x !== null &&
    typeof x === 'object' &&
    typeof x.and === 'boolean' &&
    Array.isArray(x.filters) &&
    x.filters.every((y: any) => y instanceof Filter)
  );
};

export const isFilterSetArray = (x: any): x is FilterSet[] => {
  return Array.isArray(x) && x.every(isFilterSet);
};

export const buildFilterSet = (set: FilterSet, index = 0) => {
  return {
    and: set.and,
    filters: set.filters.map((filter) => ({
      ...filter.build(),
      view_model: [...filter.save()],
    })),
    id: `query-${index}`,
  };
};

/**
 * Returns the `query` property of a filter object
 *
 * @param sets
 * @returns
 */
export const buildFilterSets = (sets: FilterSet | FilterSet[]) => {
  return Array.isArray(sets)
    ? sets.map((set, idx) => buildFilterSet(set, idx))
    : [buildFilterSet(sets)];
};
