import ChartManager from '../ChartManager';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import { colorsPrimary, colorsSecondary, grayScale } from 'app/colors';
import { throttle } from 'lodash';
import { ensureCents, formatFullDecimal } from 'utility/numbers';

const MAX_VISIBLE_ITEMS = 7;
const STROKE_DASHARRAY = [4, 4];
const SOLID_STROKE_DASHARRAY = [4, 0];
const MAX_BAR_WIDTH = 100;
const MIN_BAR_WIDTH = 30;
const BAR_GAP = 90;
const LABEL_PADDING = 5;

export const OTHER_CATEGORY_NAME = 'other';

export const colors = [
  colorsPrimary.green,
  colorsSecondary.aqua,
  colorsPrimary.blue,
  colorsPrimary.orange,
  colorsPrimary.purple,
  colorsSecondary.tangerine,
  colorsSecondary.red,
  colorsSecondary.magenta,
  colorsSecondary.brown,
];

const getStart = () => {
  return 0;
};

const getEnd = (numDataPoints) => {
  if (numDataPoints <= MAX_VISIBLE_ITEMS) {
    return 1;
  }
  const perc = MAX_VISIBLE_ITEMS / numDataPoints;
  return perc;
};

class BarChartManager extends ChartManager {
  constructor(
    element,
    t,
    data = [],
    {
      mobile = false,
      yAxisTitle = '',
      xAxisTitle = '',
      currencyMode,
      currencySymbol,
      showXAxisLabel = true,
      getLabel,
      forceDecimal = false,
    },
    rootConfig,
    ready
  ) {
    super(
      element,
      t,
      am5xy.XYChart,
      {
        panX: data.length > MAX_VISIBLE_ITEMS,
        wheelX: data.length > MAX_VISIBLE_ITEMS ? 'panX' : 'none',
        zoom: false,
        panY: false,
        wheelY: 'none',
      },
      (root) => ({
        scrollbarX: am5.Scrollbar.new(root, {
          orientation: 'horizontal',
          start: getStart(data.length),
          end: getEnd(data.length),
          height: 8,
          maxHeight: 8,
          marginTop: -5,
        }),
        layout: root.verticalLayout,
        paddingTop: data.length > MAX_VISIBLE_ITEMS ? 15 : -10,
      }),
      rootConfig,
      ready
    );

    this.xAxisWidth = 0;
    this.yAxisTitle = yAxisTitle;
    this.xAxisTitle = xAxisTitle;
    this.showXAxisLabel = showXAxisLabel;
    this.data = data;
    this.mobile = mobile;
    const hasData = this.data?.some((d) => d.value !== 0);
    this.isEmpty = !hasData;
    this.currencyMode = currencyMode;
    this.currencySymbol = currencySymbol;
    this.willScroll = this.data.length > MAX_VISIBLE_ITEMS;
    this.getLabel = getLabel;
    this.forceDecimal = forceDecimal;

    if (this.processedConfig?.scrollbarX && this.willScroll) {
      this.chart.children.push(this.processedConfig.scrollbarX);
    }

    if (this.showXAxisLabel) {
      this.createXAxisLabel();
    }

    this.chart.zoomOutButton.set('forceHidden', true);
  }

  createXAxisLabel = () => {
    this.chart.children.push(
      am5.Label.new(this.root, {
        text: this.t('Lead Source'),
        x: am5.p50,
        centerX: am5.p50,
        fill: grayScale.mediumDark,
        marginTop: -5,
      })
    );
  };

  init = (setScrollWidth, setChartWidth, setTooltipVisible) => {
    this.scrollCb = throttle(setScrollWidth, this.THROTTLE_DELAY);
    this.widthCb = throttle(setChartWidth, this.THROTTLE_DELAY);
    this.tooltipCb = setTooltipVisible;
    this.chart.set('dx', -15);

    this.createYAxis(
      `${this.currencyMode ? this.currencySymbol : ''}${this.defaultFormat}`
    );
    this.createXAxis();
    this.createSeries();
    this.setData(this.data);
    this.configureScrollbar();

    if (!this.isEmpty) {
      this.createCursor(this.mobile);
    }

    this.series.appear(1000);
    this.chart.appear(1000, 100);
  };

  createCursor = () => {
    this.assertRoot('cursor');
    this.assertChart('cursor');

    this.cursor = am5xy.XYCursor.new(this.root, {});
    this.cursor.lineY.setAll({
      visible: false,
    });
    this.cursor.lineX.setAll({
      visible: false,
    });

    this.chart.set('cursor', this.cursor);
  };

  configureEmptyState = (data = []) => {
    if (data) {
      const isZeros = data.every((record) => record.value === 0);
      if (isZeros) {
        this.element.setAttribute('data-qa-dashlet-empty', 'true');
        this.yAxis.set('min', 0);
      } else {
        this.element.setAttribute('data-qa-dashlet-empty', 'false');
        this.yAxis.set('min', undefined);
      }
    }
  };

  getYAxisLabel = (override, ml, mr) => {
    this.assertRoot('y-axis label');

    if (override) {
      return am5.Label.new(this.root, {
        rotation: -90,
        text: override,
        y: am5.p50,
        centerX: am5.p50,
        marginLeft: ml,
        marginRight: mr,
        fill: grayScale.mediumDark,
      });
    }
    this.assertYAxisTitle('y-axis label');

    const shouldTruncate = this.yAxisTitle.length > 40;
    const displayTitle = shouldTruncate
      ? `${this.yAxisTitle.slice(0, 40)}...`
      : this.yAxisTitle;

    const label = am5.Label.new(this.root, {
      rotation: -90,
      text: displayTitle,
      y: am5.p50,
      centerX: am5.p50,
      fill: grayScale.mediumDark,
      background: am5.Rectangle.new(this.root, {
        fill: am5.color(grayScale.white),
      }),
    });

    if (shouldTruncate) {
      const tooltip = this.createStyledTooltip(
        undefined,
        {
          pointerOrientation: 'left',
          dx: 6,
        },
        {
          width: 300,
          maxWidth: 300,
          oversizedBehavior: 'wrap',
        }
      );
      label.set('tooltip', tooltip);
      label.set('tooltipText', this.yAxisTitle);
    }

    return label;
  };

  getXAxisLabel = (override) => {
    this.assertRoot('x-axis label');

    if (override) {
      return am5.Label.new(this.root, {
        text: override,
        x: am5.p50,
        fill: grayScale.mediumDark,
      });
    }
    this.assertYAxisTitle('x-axis label');

    const shouldTruncate = this.xAxisTitle.length > 40;
    const displayTitle = shouldTruncate
      ? `${this.xAxisTitle.slice(0, 40)}...`
      : this.xAxisTitle;

    const label = am5.Label.new(this.root, {
      text: displayTitle,
      x: am5.p50,
      fill: grayScale.mediumDark,
      background: am5.Rectangle.new(this.root, {
        fill: am5.color(grayScale.white),
      }),
    });

    if (shouldTruncate) {
      const tooltip = this.createStyledTooltip(
        undefined,
        {
          pointerOrientation: 'left',
          dx: 6,
        },
        {
          width: 300,
          maxWidth: 300,
          oversizedBehavior: 'wrap',
        }
      );
      label.set('tooltip', tooltip);
      label.set('tooltipText', this.yAxisTitle);
    }

    return label;
  };

  handleScrollCb = () => {
    if (this.start === 0) {
      this.scrollCb?.(0);
    } else if (this.end === 1) {
      this.scrollCb?.(1);
    } else {
      this.scrollCb?.(this.start);
    }
  };

  handleStart = (value) => {
    this.start = value;
    this.handleScrollCb();
  };

  handleEnd = (value) => {
    this.end = value;
    this.handleScrollCb();
  };

  configureBarSizing = () => {
    const numItems =
      this.data.length < MAX_VISIBLE_ITEMS
        ? this.data.length
        : MAX_VISIBLE_ITEMS;

    const widthPerItem = this.xAxisWidth / numItems;

    const desiredBarWidth = widthPerItem - BAR_GAP;
    const desiredBarPercentage = (desiredBarWidth / widthPerItem) * 100;

    if (desiredBarWidth > MAX_BAR_WIDTH) {
      this.series.columns.template.setAll({
        width: MAX_BAR_WIDTH,
      });
    } else if (desiredBarWidth < MIN_BAR_WIDTH) {
      this.series.columns.template.setAll({
        width: MIN_BAR_WIDTH,
      });
    } else {
      this.series.columns.template.setAll({
        width: am5.percent(desiredBarPercentage),
      });
    }

    const renderer = this.xAxis.get('renderer');
    renderer.labels.template.setAll({
      maxWidth: widthPerItem - LABEL_PADDING,
    });
  };

  configureScrollbarSizing = () => {
    this.processedConfig.scrollbarX.setAll({
      dx: 45,
      width: this.xAxisWidth,
    });
  };

  handleXAxisWidth = (width) => {
    this.xAxisWidth = width;
    this.widthCb?.(width);
    this.configureBarSizing();
    this.configureScrollbarSizing();
  };

  configureScrollbar = () => {
    this.assertChart('configure scrollbar');
    this.assertRoot('configure scrollbar');

    this.configureExpandingScrollbarHorizontal(
      this.data.length > MAX_VISIBLE_ITEMS
    );
  };

  createYAxis = (format) => {
    this.assertRoot('y-axis');
    this.assertChart('y-axis');
    this.assertYAxisTitle('y-axis');

    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {
          strokeWidth: 1,
          strokeOpacity: 1,
          stroke: grayScale.mediumLight,
        }),
        tooltip: this.createStyledTooltip(),
        numberFormat: format,
      })
    );

    this.yAxis.children.unshift(this.getYAxisLabel());

    const renderer = this.yAxis.get('renderer');

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
    });

    renderer.labels.template.setup = (target) => {
      target.set('dx', -10);
    };

    renderer.grid.template.setAll({
      strokeDasharray: [4, 4],
      stroke: grayScale.mediumLight,
      strokeOpacity: 1,
    });
  };

  createXAxis = () => {
    this.assertRoot('x-axis');
    this.assertChart('x-axis');

    this.xAxis = this.chart.xAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'category',
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 1,
          strokeWidth: 1,
          strokeOpacity: 1,
          stroke: grayScale.mediumLight,
        }),
        start: getStart(this.data.length),
        end: getEnd(this.data.length),
        maxDeviation: 0,
        minGridDistance: 1,
      })
    );

    if (this.xAxisTitle) {
      this.chart.children.push(this.getXAxisLabel());
    }

    const renderer = this.xAxis.get('renderer');

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
      oversizedBehavior: 'truncate',
    });

    renderer.labels.template.setup = (target) => {
      target.setAll({
        background: am5.Rectangle.new(this.root, {
          fill: `${grayScale.white}00`,
        }),
        interactive: true,
        showTooltipOn: 'hover',
        tooltip: this.createStyledTooltipWithHandler(
          undefined,
          {
            pointerOrientation: 'down',
          },
          {
            maxWidth: 200,
            oversizedBehavior: 'wrap',
          },
          undefined,
          'labels'
        ),
        tooltipY: 0,
        dy: 10,
        paddingBottom: this.willScroll ? 5 : 15,
      });
    };

    renderer.labels.template.adapters.add('text', (text, target) => {
      return this.getLabel?.(target?.dataItem?.dataContext) ?? text;
    });

    renderer.labels.template.events.on('boundschanged', ({ target }) => {
      const maxW = target.get('maxWidth');
      const width = target.width();

      if (width < maxW) {
        return;
      }

      target.setAll({
        tooltipText:
          this.getLabel?.(target?.dataItem?.dataContext) ??
          target?.dataItem?.dataContext?.category,
      });
    });

    renderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    this.xAxis.on('start', this.handleStart);
    this.xAxis.on('end', this.handleEnd);
    this.xAxis.onPrivate('width', this.handleXAxisWidth);
  };

  createStyledTooltipWithHandler = (
    labelText,
    opts,
    labelOpts,
    backgroundOpts,
    handlerId
  ) => {
    const tooltip = this.createStyledTooltip(
      labelText,
      opts,
      labelOpts,
      backgroundOpts
    );

    if (this.tooltipCb) {
      tooltip.on('visible', (v) => this.tooltipCb?.(v, handlerId));
    }

    tooltip.setAll({ pointerOrientation: opts?.pointerOrientation ?? 'down' });

    return tooltip;
  };

  createTheme = () => {
    this.assertRoot('create theme');

    this.theme = am5.Theme.new(this.root);

    this.theme.rule('Grid').setAll({
      strokeDasharray: STROKE_DASHARRAY,
    });

    this.theme.rule('Grid', ['base']).setAll({
      strokeDasharray: SOLID_STROKE_DASHARRAY,
      stroke: grayScale.mediumLight,
    });

    this.root.setThemes([this.theme]);
  };

  createSeries = (name = 'Unnamed Series') => {
    this.assertRoot('series');
    this.assertYAxis('series');
    this.assertXAxis('series');

    const tooltip = this.createStyledTooltipWithHandler(
      `${this.categoryAccessor}: ${
        this.currencyMode ? this.currencySymbol : ''
      }${this.valueYAccessor}`,
      undefined,
      {
        maxWidth: 300,
        oversizedBehavior: 'wrap',
      },
      undefined,
      undefined,
      'series'
    );

    tooltip.label.adapters.add('text', (text, target) => {
      const value = target?.dataItem?.dataContext?.value ?? 0;
      if (this.getLabel) {
        const stringValue = value.toLocaleString();
        const formattedStringValue = this.currencyMode
          ? ensureCents(stringValue)
          : stringValue;

        return `${this.getLabel(target?.dataItem?.dataContext)}: ${
          this.currencyMode ? this.currencySymbol : ''
        }${
          this.forceDecimal ? formatFullDecimal(value) : formattedStringValue
        }`;
      }

      return text;
    });

    this.series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: 'value',
        categoryXField: 'category',
        tooltip,
      })
    );

    this.series.columns.template.setAll({
      width: am5.percent(100),
    });

    this.series.columns.template.adapters.add('fill', (_fill, target) => {
      const index = this.series.columns.indexOf(target);
      if (
        target.dataItem?.dataContext?.category?.toLowerCase() ===
        OTHER_CATEGORY_NAME
      ) {
        return am5.color(grayScale.medium);
      }
      return am5.color(colors[index % colors.length]?.light);
    });

    this.series.columns.template.adapters.add('stroke', (_stroke, target) => {
      const index = this.series.columns.indexOf(target);
      if (
        target.dataItem?.dataContext?.category?.toLowerCase() ===
        OTHER_CATEGORY_NAME
      ) {
        return am5.color(grayScale.medium);
      }
      return am5.color(colors[index % colors.length]?.light);
    });
  };

  setData = (data) => {
    this.assertRoot('data');
    this.assertSeries('data');
    this.assertYAxis('data');

    this.xAxis.data.setAll(data);
    this.series.data.setAll(data);

    this.configureEmptyState(data);
  };
}

export default BarChartManager;
