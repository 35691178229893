import stepConfig, { edgeLabels } from '../steps';

const ensureNode = (nodesById, id) => {
  if (!id) {
    return null;
  }
  nodesById[id] = nodesById[id] || {
    item: null,
    parent: null,
    children: [],
  };
  return nodesById[id];
};

// Sometimes we need to interact with the steps as a tree. Below builds a very
// simple graph data structure, plus an index of steps nodes by id so that you
// can hop into the middle of the tree.

export default function createGraph(steps) {
  const roots = [];
  const nodesById = {};
  steps.forEach((step) => {
    const node = ensureNode(nodesById, step.id);
    node.item = step;
    const parent = ensureNode(nodesById, step.parentKey);
    if (parent) {
      node.parent = parent;
      parent.children.push(node);
    } else {
      roots.push(node);
    }
  });
  Object.values(nodesById).forEach(({ item: step, children }) => {
    // this is a specific situation when we have several cards with variations branches (yes <> no)
    // and only `yes` cards are presents
    if (step && (step.type === stepConfig.condition.type || step.goalType)) {
      // Yes branches before no branches
      children.sort((a, b) => {
        return (
          Number(b.item.fromLabel === edgeLabels.yes.type) -
          Number(a.item.fromLabel === edgeLabels.yes.type)
        );
      });
    }
  });
  return { roots, nodesById };
}

const pushInReverse = (arr, items) => {
  for (let i = 0; i < items.length; i += 1) {
    arr.push(items[items.length - i - 1]);
  }
  return arr;
};

// This is a pre-order traversal. Replacing the stack with a queue (and not pushing
// in reverse) would provide a level-order traversal, which might also be appropriate.
// The key here is to preserve the relative order of siblings, which dagre will use
// to determine where to draw each branch.
export function listGraphItems({ roots }) {
  const results = [];
  const stack = pushInReverse([], roots);
  while (stack.length) {
    const node = stack.pop();
    results.push(node.item);
    pushInReverse(stack, node.children);
  }
  return results;
}
