import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useReactQueryTypeahead from 'components/Inputs/Select/useReactQueryTypeahead';
import { TEAM_MEMBERS } from 'queries/query-keys';

import TeamMemberService from 'services/TeamMemberService';
import MultiSelectInline from 'components/Inputs/inline/MultiSelect';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import MultiSelect from 'components/Inputs/MultiSelect';
import { namedToOption, optionToNamed, filterOption } from 'services/helpers';

const defaultOrdering = 'name';

const useTeamMemberRolesOptions = (options = {}) => {
  const { enabled = true } = options;
  const { results, selectProps: teamMemberRolesSelectProps } =
    useReactQueryTypeahead(
      async () => {
        const results = await TeamMemberService.getRoles({
          ordering: defaultOrdering,
        });
        return { results };
      },
      [...TEAM_MEMBERS.ROLES, defaultOrdering],
      {
        enabled,
        optionMapper: (option) => option,
        keepPreviousData: true,
        refetchOnReconnect: false,
        staleTime: Infinity,
      }
    );

  const rolesOptions = useMemo(() => {
    return results
      .filter(({ name }) =>
        filterOption(teamMemberRolesSelectProps.inputValue, name)
      )
      .map(namedToOption);
  }, [results, teamMemberRolesSelectProps.inputValue]);

  return {
    teamMemberRolesSelectProps: {
      ...teamMemberRolesSelectProps,
      options: rolesOptions,
    },
  };
};
export default function TeamMemberRolesInlineField({
  value: valueProp = [],
  onSubmit,
  ...others
}) {
  const { t } = useTranslation();

  const value = useMemo(
    () => (valueProp || []).map(namedToOption),
    [valueProp]
  );

  const { teamMemberRolesSelectProps } = useTeamMemberRolesOptions();

  return (
    <MultiSelectInline
      onSubmit={(val) => onSubmit(val.map(optionToNamed))}
      {...others}
    >
      <MultiSelect
        value={value}
        placeholder={t('Find Roles')}
        menuLeftButton={<ClearSelectButton />}
        menuRightButton={<ApplySelectOverlayButton />}
        {...teamMemberRolesSelectProps}
      />
    </MultiSelectInline>
  );
}
