import { createAction, createSlice } from '@reduxjs/toolkit';
import { initialStore } from '../initialStore';

// templates
export const getTemplates = createAction(
  'libraryPage/templates/GET_TEMPLATES',
  function prepare({ updatePageConfig = true } = {}) {
    return {
      payload: {
        updatePageConfig,
      },
    };
  }
);
export const getAutomations = createAction(
  'libraryPage/automations/GET_AUTOMATIONS',
  function prepare({ updatePageConfig = true, ...other } = {}) {
    return {
      payload: {
        updatePageConfig,
        ...other,
      },
    };
  }
);
export const getBroadcasts = createAction(
  'libraryPage/templates/GET_BROADCASTS',
  function prepare({ updatePageConfig = true, ...other } = {}) {
    return {
      payload: {
        updatePageConfig,
        ...other,
      },
    };
  }
);
export const getTemplatesFail = createAction(
  'libraryPage/templates/GET_TEMPLATES_FAIL'
);
export const deleteTemplate = createAction(
  'libraryPage/templates/DELETE_TEMPLATE'
);
export const deleteTemplateFail = createAction(
  'libraryPage/templates/DELETE_TEMPLATE_FAIL'
);
export const deleteAutomations = createAction(
  'libraryPage/automations/DELETE_AUTOMATIONS'
);
export const deleteAutomationsFail = createAction(
  'libraryPage/automations/DELETE_AUTOMATIONS_FAIL'
);
export const deleteBroadcasts = createAction(
  'libraryPage/templates/DELETE_BROADCASTS'
);
export const deleteBroadcastsFail = createAction(
  'libraryPage/templates/DELETE_BROADCASTS_FAIL'
);
export const updateTemplate = createAction(
  'libraryPage/templates/UPDATE_TEMPLATE'
);
export const updateTemplateFail = createAction(
  'libraryPage/templates/UPDATE_TEMPLATE_FAIL'
);
export const updateAutomation = createAction(
  'libraryPage/templates/UPDATE_AUTOMATION'
);
export const updateAutomationFail = createAction(
  'libraryPage/templates/UPDATE_AUTOMATION_FAIL'
);

export const duplicateTemplate = createAction(
  'libraryPage/templates/duplicateTemplate'
);
export const duplicateTemplateSuccess = createAction(
  'libraryPage/templates/duplicateTemplateSuccess'
);
export const duplicateTemplateFail = createAction(
  'libraryPage/templates/duplicateTemplateFail'
);

// setPageConfig
export const setPageConfig = createAction(
  'libraryPage/templates/SET_PAGE_CONFIG'
);

// Slices
const defaultState = initialStore.libraryPage;

export const libraryPageSlice = createSlice({
  name: 'libraryPage/templates',
  initialState: defaultState,
  reducers: {
    buildPage: (state) => {
      state.isFetching = true;
    },
    buildPageComplete: (state, { payload }) => {
      const { pageResponse } = payload;
      const automations = {
        ...state.pageConfig.automations,
        ...pageResponse.automations,
      };
      const broadcasts = {
        ...state.pageConfig.broadcasts,
        ...pageResponse.broadcasts,
      };
      const templates = {
        ...state.pageConfig.templates,
        ...pageResponse.templates,
      };
      state.pageConfig = {
        automations,
        broadcasts,
        templates,
      };
    },
    buildPageFinish: (state) => {
      state.isFetching = false;
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    getTemplatesStart: (state) => {
      state.isTemplatesFiltering = true;
    },
    getTemplatesFinish: (state) => {
      state.isTemplatesFiltering = false;
    },
    getBroadcastsFinish: (state) => {
      state.isBroadcastsFiltering = false;
    },
    getTemplatesSuccess: (state, { payload }) => {
      const { templatesCount, templates } = payload;
      state.templatesCount = templatesCount;
      state.templates = templates;
    },
    getAutomationsSuccess: (state, { payload }) => {
      const { automationsCount, automations } = payload;
      state.automations = automations;
      state.automationsCount = automationsCount;
      state.isAutomationsFetching = false;
    },
    getBroadcastsSuccess: (state, { payload }) => {
      const { broadcastsCount, broadcasts } = payload;
      state.broadcasts = broadcasts;
      state.broadcastsCount = broadcastsCount;
      state.isBroadcastsFetching = false;
    },
    updateTemplateSuccess: (state, { payload }) => {
      state.templates = state.templates.map((item) =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
    },
    updateBroadcastSuccess: (state, { payload }) => {
      state.broadcasts = state.broadcasts.map((item) =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
    },
    updateAutomationSuccess: (state, { payload }) => {
      state.automations = state.automations.map((item) =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
    },
    updatePageConfig: (state, { payload }) => {
      state.pageConfig = payload;
    },
    addTemplate(state, { payload }) {
      state.templates.unshift(payload);
      state.templatesCount += 1;
    },
    fetchingToggle(state) {
      state.isFetching = !state.isFetching;
    },
    templateUpdatingToggle(state) {
      state.isTemplateUpdating = !state.isTemplateUpdating;
    },
    getAutomationsStart: (state) => {
      state.isAutomationsFetching = true;
      state.isAutomationsFiltering = true;
    },
    getAutomationsFinish: (state) => {
      state.isAutomationsFiltering = false;
    },
    getAutomationsFail: (state) => {
      state.isAutomationsFetching = false;
    },
    getBroadcastsStart: (state) => {
      state.isBroadcastsFetching = true;
      state.isBroadcastsFiltering = true;
    },
    getBroadcastsFail: (state) => {
      state.isBroadcastsFetching = false;
    },
  },
});

export const {
  buildPage,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  getTemplatesSuccess,
  getAutomationsStart,
  getAutomationsSuccess,
  getBroadcastsStart,
  getBroadcastsSuccess,
  updateTemplateSuccess,
  updateAutomationSuccess,
  addTemplate,
  updatePageConfig,
  fetchingToggle,
  updateBroadcastSuccess,
  templateUpdatingToggle,
  getAutomationsFail,
  getBroadcastsFail,
  getTemplatesStart,
  getTemplatesFinish,
  getAutomationsFinish,
  getBroadcastsFinish,
} = libraryPageSlice.actions;

export const { reducer } = libraryPageSlice;

// Selectors
export const selectLibraryAutomationsIsFiltering = (state) =>
  state.libraryPage.isAutomationsFiltering;
export const selectLibraryAutomationsFetching = (state) =>
  state.libraryPage.isAutomationsFetching;
export const selectLibraryBroadcastsIsFiltering = (state) =>
  state.libraryPage.isBroadcastsFiltering;
export const selectLibraryBroadcastsFetching = (state) =>
  state.libraryPage.isBroadcastsFetching;
export const selectLibraryTemplatesFiltering = (state) =>
  state.libraryPage.isTemplatesFiltering;
