import { grayScale } from 'app/colors';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CALENDAR_VIEW_OPTIONS } from '../constants';
import { ClickableIcon, DateContainer, DateText } from './styled';
import { endOfWeek } from 'date-fns2';
import IconButton from 'components/Kizen/IconButton';
import { IconSizing } from 'components/Kizen/Icon';
import { getWeekNumberOfTheMonth } from 'components/Calendar';

export const IncrementalDatePicker = ({
  view,
  date,
  previous,
  next,
  label,
  className,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formattedDate = useMemo(() => {
    if (view === CALENDAR_VIEW_OPTIONS.day) {
      return new Intl.DateTimeFormat(language, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }).format(date);
    }
    if (view === CALENDAR_VIEW_OPTIONS.week) {
      return `${new Intl.DateTimeFormat(language, {
        month: 'long',
        year: 'numeric',
      }).format(endOfWeek(date))} - ${t('Week')} ${getWeekNumberOfTheMonth(
        date
      )}`;
    }
    return new Intl.DateTimeFormat(language, {
      month: 'long',
      year: 'numeric',
    }).format(date);
  }, [date, view, t, language]);

  return (
    <DateContainer view={view} className={className}>
      <IconButton
        title={`${t('Previous')} ${label}`}
        sizing="dense"
        color={{ light: grayScale.dark, dark: grayScale.mediumDark }}
        onClick={previous}
      >
        <IconSizing size="20px">
          <ClickableIcon icon="chevron-left" />
        </IconSizing>
      </IconButton>
      <DateText size="header">{formattedDate}</DateText>
      <IconButton
        title={`${t('Next')} ${label}`}
        sizing="dense"
        color={{ light: grayScale.dark, dark: grayScale.mediumDark }}
        onClick={next}
      >
        <IconSizing size="20px">
          <ClickableIcon icon="chevron-right" />
        </IconSizing>
      </IconButton>
    </DateContainer>
  );
};
