import { SMART_CONNECTOR_STEPS } from 'pages/SmartConnectors/constants';
import { SmartConnectorStep } from 'pages/SmartConnectors/types';
import { TFunction } from 'react-i18next';
import { useContext } from 'react';
import { SmartConnectorContext } from '../context';
import { ConnectionStep } from './ConnectionStep';
import { GeneralSettingsStep } from './GeneralSettingsStep';
import { VariablesStep } from './VariablesStep';

export const steps: Record<SmartConnectorStep, any> = {
  [SMART_CONNECTOR_STEPS.general]: {
    idx: 0,
    key: SMART_CONNECTOR_STEPS.general,
    label: (t: TFunction) => t('General Settings'),
    component: GeneralSettingsStep,
  },
  [SMART_CONNECTOR_STEPS.connection]: {
    idx: 1,
    key: SMART_CONNECTOR_STEPS.connection,
    label: (t: TFunction) => t('Configure Connection'),
    component: ConnectionStep,
  },
  [SMART_CONNECTOR_STEPS.variables]: {
    idx: 2,
    key: SMART_CONNECTOR_STEPS.variables,
    label: (t: TFunction) => t('Initialize Variables'),
    component: VariablesStep,
  },
};

export const Steps = () => {
  const { step } = useContext(SmartConnectorContext);
  const Component = steps[step].component;
  return <Component />;
};
