import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Kizen/Icon';
import { StyledIconButtonMenu } from './styles';

export default function DashletMenu({
  onDeleteHandler,
  onEditHandler,
  onRenameHandler,
  onDuplicateHandler,
  id,
  entity,
}) {
  const { t } = useTranslation();

  const handleChooseValue = useCallback(
    ({ value }) => {
      switch (value) {
        case 'rename':
          return onRenameHandler();
        case 'edit':
          return onEditHandler();
        case 'duplicate':
          return onDuplicateHandler();
        case 'delete':
          return onDeleteHandler();
        default:
          return;
      }
    },
    [onRenameHandler, onEditHandler, onDeleteHandler, onDuplicateHandler]
  );

  return (
    <StyledIconButtonMenu
      sizing="dense"
      position="right"
      title={t('Edit {{entity}}', { entity: entity ?? t('Dashlet') })}
      onChange={handleChooseValue}
      options={[
        onRenameHandler
          ? {
              value: 'rename',
              label: t('Rename'),
            }
          : undefined,
        onEditHandler
          ? {
              value: 'edit',
              label: t('Edit'),
            }
          : undefined,
        onDuplicateHandler
          ? {
              value: 'duplicate',
              label: t('Duplicate'),
            }
          : undefined,
        {
          value: 'delete',
          label: t('Delete'),
        },
      ].filter(Boolean)}
      className="no-drag dashlet-menu"
    >
      <Icon icon="three-dot" data-qa-dashlet-id={id} />
    </StyledIconButtonMenu>
  );
}

DashletMenu.propTypes = {
  onDeleteHandler: PropTypes.func.isRequired,
  onEditHandler: PropTypes.func.isRequired,
  onRenameHandler: PropTypes.func.isRequired,
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
};

DashletMenu.defaultProps = {
  children: null,
};
