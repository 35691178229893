import AccessRequestTableMine from 'components/AccessRequests/Tables/Mine';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { useMutation, useQuery } from 'react-query';
import {
  createDashboardQuery,
  deleteDashboardQuery,
  duplicateDashboardQuery,
  fetchDashboardsQuery,
} from 'components/AccessRequests/useDashboardList';
import useModal from 'components/Modals/useModal';
import CreateLayoutModal from 'components/DashboardGrid/CreateLayoutModal';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import Loader from 'components/Kizen/Loader';
import useDashboardConfig from 'pages/Dashboard/hooks/useDashboardConfig';
import { useHomepageOptimisticUpdate } from 'pages/Homepages/hooks/useHomepageOptimisticUpdate';
import { getDashboardsQueryKeyForHomepages } from 'pages/Homepages/utils';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useReorderDashboards } from 'components/DashboardGrid/hooks/useReorderDashboards';
import searchState from 'components/AccessRequests/searchState';
import SearchState from 'components/AccessRequests/searchState';

const INCLUDE_SHARING = true;

const HomepagesSettingsPageContent = () => {
  const [existingDashboard, setExistingDashboard] = useState({});
  const [isDuplication, setIsDuplication] = useState(false);

  const { t } = useTranslation();
  const { debouncedTerm } = useContext(searchState.Context);

  useSetTitleOnLoad(t('Homepage Settings'));

  const handleFetchDashboards = useCallback(() => {
    return fetchDashboardsQuery(
      debouncedTerm,
      INCLUDE_SHARING,
      undefined,
      'homepage'
    );
  }, [debouncedTerm]);

  const dashboardsQueryKey = getDashboardsQueryKeyForHomepages({
    debouncedTerm,
    includeSharing: INCLUDE_SHARING,
  });

  const {
    isLoading: myDashboardsLoading,
    data: homepages = [],
    refetch: refetchDashboards,
    isRefetching: isRefetchingDashboards,
  } = useQuery(dashboardsQueryKey, handleFetchDashboards, {
    keepPreviousData: true,
  });

  const handleReorderDashboards = useReorderDashboards({
    onSuccess: refetchDashboards,
    queryKey: dashboardsQueryKey,
  });

  const {
    afterUpdate,
    afterCreate,
    afterDelete,
    loading: configLoading,
  } = useDashboardConfig(homepages, true, 'homepage');
  const [errors, setErrors] = useState({});

  const createDashboardMutation = useMutation(
    (result) => createDashboardQuery(result, undefined, 'homepage'),
    {
      onMutate: () => {
        setErrors({});
      },
      onSuccess: (data) => {
        handleHideModal();
        afterCreate(data.id);
        setExistingDashboard({});
        setIsDuplication(false);
      },
      onError: (error) => {
        setErrors(error?.response?.data?.errors);
      },
    }
  );

  const deleteDashboardMutation = useMutation(
    (result) => deleteDashboardQuery(result),
    {
      onSuccess: (_data, id) => {
        afterDelete(id);
        refetchDashboards();
      },
    }
  );

  const duplicateDashboardMutation = useMutation(
    (result) => duplicateDashboardQuery(result.id, result.payload),
    {
      onSuccess: (data) => {
        handleHideModal();
        afterCreate(data.id);
        setExistingDashboard({});
        setIsDuplication(false);
        refetchDashboards();
      },
    }
  );

  const updateDashboardMutation = useHomepageOptimisticUpdate({
    afterUpdate,
    refetchDashboards,
    includeSharing: INCLUDE_SHARING,
    debouncedTerm,
    onSuccess: () => {
      handleHideModal();
      setExistingDashboard({});
      setIsDuplication(false);
    },
    onError: (errs, { id, isFromModal, ...rest }) => {
      setErrors((...prev) => {
        return {
          ...prev,
          ...errs,
          errorForId: isFromModal ? undefined : id,
        };
      });
    },
  });

  const handleMutateDashboard = useCallback(
    (
      { name, permissions, owner = {}, published },
      isEditing,
      id,
      isDuplication,
      isFromModal
    ) => {
      if (isDuplication) {
        duplicateDashboardMutation.mutate({
          id,
          payload: {
            name,
            ...buildSharingSettingsForAPI(permissions),
            ...owner,
          },
        });
      } else if (isEditing) {
        const payload = {
          name,
          ...buildSharingSettingsForAPI(permissions),
          ...owner,
        };

        if (typeof published !== 'undefined') {
          payload.published = published;
        }

        updateDashboardMutation.mutate({
          id,
          payload,
          isFromModal,
        });
      } else {
        createDashboardMutation.mutate({
          name,
          ...buildSharingSettingsForAPI(permissions),
        });
      }
    },
    [
      createDashboardMutation,
      updateDashboardMutation,
      duplicateDashboardMutation,
    ]
  );

  const [modalProps, , { show: handleShowModal, hide: handleHideModal }] =
    useModal({
      closeOnSubmit: false,
      handleSubmit: (data, isEditing, id, isDuplication) =>
        handleMutateDashboard(data, isEditing, id, isDuplication, true),
      handleHide: () => {
        setErrors({});
        setExistingDashboard({});
        // setCanUserEditPermissions(true);
      },
    });

  if (myDashboardsLoading) {
    return <Loader loading />;
  }

  return (
    <>
      <AccessRequestTableMine
        getColumns={getColumns}
        entityPlural={t('Homepages')}
        entitySingular={t('Homepage')}
        data={homepages}
        prefix=""
        goBackPath="/home"
        handleMutateDashboard={handleMutateDashboard}
        handleShowModal={(...args) => {
          setErrors({});
          handleShowModal(...args);
        }}
        handleStartEditing={(d) => {
          setExistingDashboard(d);
          setIsDuplication(false);
          setErrors({});
          handleShowModal();
        }}
        handleStartDuplication={(d) => {
          setExistingDashboard(d);
          setIsDuplication(true);
          setErrors({});
          handleShowModal();
        }}
        loading={configLoading || myDashboardsLoading}
        handleReorderDashboards={handleReorderDashboards}
        updateDashboardMutation={updateDashboardMutation}
        deleteDashboardMutation={deleteDashboardMutation}
        isRefetching={isRefetchingDashboards}
        deleteConfirmationMessage={t(
          'Deleting this homepage will remove it for all team members, including homepage administrators. This action is permanent and the homepage cannot be recovered.'
        )}
        errors={errors}
        setErrors={setErrors}
      />
      <CreateLayoutModal
        {...modalProps}
        isDuplication={isDuplication}
        canUserEditPermissions
        existing={existingDashboard}
        entity={t('Homepage')}
        small
        errors={errors}
        blockHideIfDirty
        showPrivateToggle={false}
      />
    </>
  );
};

const HomepageSettingsPage = () => {
  return (
    <SearchState.Wrapper>
      <HomepagesSettingsPageContent />
    </SearchState.Wrapper>
  );
};

export default HomepageSettingsPage;
