import { useTranslation } from 'react-i18next';
import {
  AIToolbarSection,
  ClearFormattingToolbarSection,
  FontFamilyToolbarSection,
  FontSizeToolbarSection,
  InsertMergeFieldToolbarSection,
  InsertMultiSourceMergeFieldToolbarSection,
  HistoryToolbarSection,
  LinkToolbarSection,
  ListStyleToolbarSection,
  TextAlignToolbarSection,
  TextColorToolbarSection,
  TextStyleToolbarSection,
} from '../sections/wysiwyg';

import {
  FloatingToolbarWrapper,
  ToolbarWrapper,
  ToolbarSwitch,
} from '../styles';

export const BasicToolBar = ({
  enableAI,
  enableTextStyles,
  enableColor,
  enableHighlight,
  enableHistoryControls,
  enableFontSize,
  defaultFontSize,
  enableFontFamily,
  fonts,
  defaultFontFamily,
  enableLineHeight,
  enableLists,
  enableTextAlign,
  enableLinks,
  textLinkOptions,
  scriptExecutionOptions,
  enableClearFormatting,
  enableMergeFields,
  mergeFields,
  appendMergeFieldExtraSpace,
  multipleMergeFields,
  onFontFamilyChange,
  onToggleEncoding,
  encodeMergeFieldsInBody,
}) => {
  const { t } = useTranslation();
  return (
    <ToolbarWrapper spaceBetween={Boolean(onToggleEncoding)}>
      {enableTextStyles && (
        <TextStyleToolbarSection
          enableColor={enableColor}
          enableHighlight={enableHighlight}
        />
      )}
      {enableFontSize && (
        <FontSizeToolbarSection defaultFontSize={defaultFontSize} />
      )}
      {enableFontFamily && (
        <FontFamilyToolbarSection
          enableLineHeight={enableLineHeight}
          fonts={fonts}
          defaultFontFamily={defaultFontFamily}
          onChange={onFontFamilyChange}
        />
      )}
      {enableLists && <ListStyleToolbarSection />}
      {(enableColor || enableHighlight) && (
        <TextColorToolbarSection
          enableColor={enableColor}
          enableHighlight={enableHighlight}
        />
      )}
      {enableTextAlign ? <TextAlignToolbarSection /> : null}

      {enableLinks && (
        <LinkToolbarSection
          linkOptions={textLinkOptions}
          scriptExecutionOptions={scriptExecutionOptions}
        />
      )}
      {enableClearFormatting && <ClearFormattingToolbarSection />}
      {enableMergeFields ? (
        multipleMergeFields ? (
          <InsertMultiSourceMergeFieldToolbarSection
            appendMergeFieldExtraSpace={appendMergeFieldExtraSpace}
            mergeFields={mergeFields}
            multipleMergeFields={multipleMergeFields}
          />
        ) : (
          <InsertMergeFieldToolbarSection
            appendMergeFieldExtraSpace={appendMergeFieldExtraSpace}
            mergeFields={mergeFields}
          />
        )
      ) : null}
      {enableAI && <AIToolbarSection />}
      {enableHistoryControls && <HistoryToolbarSection />}
      {onToggleEncoding ? (
        <div>
          <ToolbarSwitch
            textPlacement="left"
            label={t('Encode Merge Fields')}
            checked={encodeMergeFieldsInBody}
            onChange={onToggleEncoding}
            tooltip={t(
              'Escapes merge fields for use in JSON payloads so that special characters won’t result in invalid JSON.'
            )}
          />
        </div>
      ) : null}
    </ToolbarWrapper>
  );
};

export const FloatingToolbar = ({
  target,
  asOverlay,
  zIndex,
  maxToolbarRows,
  toolbarActive,
  enableColor,
  enableHighlight,
  enableHistoryControls,
  enableFontSize,
  defaultFontSize,
  enableFontFamily,
  fonts,
  defaultFontFamily,
  enableAI,
  enableLineHeight,
  enableLinks,
  textLinkOptions,
  scriptExecutionOptions,
  enableClearFormatting,
  enableMergeFields,
  mergeFields,
  multipleMergeFields,
  onFontFamilyChange,
  allowRelativeLinks,
}) => {
  return (
    <FloatingToolbarWrapper
      target={target}
      asOverlay={asOverlay}
      zIndex={zIndex}
      maxRows={maxToolbarRows}
      onPointerDown={() => (toolbarActive.current = true)}
      onPointerOver={() => (toolbarActive.current = true)}
      onPointerLeave={() => (toolbarActive.current = false)}
    >
      <TextStyleToolbarSection
        enableColor={enableColor}
        enableHighlight={enableHighlight}
      />
      {enableFontSize && (
        <FontSizeToolbarSection defaultFontSize={defaultFontSize} />
      )}
      {enableFontFamily && (
        <FontFamilyToolbarSection
          enableLineHeight={enableLineHeight}
          fonts={fonts}
          defaultFontFamily={defaultFontFamily}
          onChange={onFontFamilyChange}
        />
      )}
      <ListStyleToolbarSection />
      {(enableColor || enableHighlight) && (
        <TextColorToolbarSection
          enableColor={enableColor}
          enableHighlight={enableHighlight}
        />
      )}
      <TextAlignToolbarSection />
      {enableLinks && (
        <LinkToolbarSection
          linkOptions={textLinkOptions}
          scriptExecutionOptions={scriptExecutionOptions}
          allowRelativeLinks={allowRelativeLinks}
        />
      )}
      {enableClearFormatting && <ClearFormattingToolbarSection />}
      {enableMergeFields ? (
        multipleMergeFields ? (
          <InsertMultiSourceMergeFieldToolbarSection
            mergeFields={mergeFields}
            multipleMergeFields={multipleMergeFields}
          />
        ) : (
          <InsertMergeFieldToolbarSection mergeFields={mergeFields} />
        )
      ) : null}
      {enableAI && <AIToolbarSection />}
      {enableHistoryControls && <HistoryToolbarSection />}
    </FloatingToolbarWrapper>
  );
};
