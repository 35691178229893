/**
 * Group fields by their category. The optional second parameter is a mapping
 * function that will be applied to each field. This function will also behave
 * like a filter by returning null or undefined. These values will not be
 * collected into the final result.
 *
 * @param {Field[]} fields
 * @param {Function} mapper - function to apply to each field
 * @returns Record<string, Field[]>
 */
export const categorizeFields = (fields, mapper = (x) => x) => {
  return fields.reduce((acc, field) => {
    acc[field.category] = acc[field.category] || [];
    const mapped = mapper(field);
    if (mapped !== undefined && mapped !== null) {
      acc[field.category].push(mapped);
    }
    return acc;
  }, {});
};
