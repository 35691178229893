import { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'app/spacing';
import Automation2Service, {
  AUTOMATION_ACTION,
} from 'services/Automation2Service';
import useSearchParam, { setSearchParams } from 'hooks/useSearchParam';
import { useHistory } from 'react-router-dom';
import { PageSizing } from 'components/Layout/PageContentWidth';
import DesktopView from '../DesktopView';
import MobileView from '../MobileView';
import { toastVariant, useToast } from 'components/ToastProvider';
import { usePaginationSettings } from 'hooks/usePaginationSettings';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import TablePagination from 'components/Tables/Big/TablePagination';
import { StyledContentWidth } from './styles';
import { DEFAULT_PAGINATION_SETTINGS } from '../contstants';
import { getToastMessage } from './helpers';
import { useSkipStepModal } from './hooks';
import { ConfirmSkipStepModal } from './ConfirmSkipStepModal';
import { setOpenMenuAbove } from 'components/Tables/helpers';

export const AutomationsPage = ({
  customObjectId,
  isContact = false,
  entity,
  onStartAutomation,
  ...rest
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();

  const isMobile = useBreakpoint();
  const history = useHistory();

  const orderingParam = useSearchParam('sort') || 'start_time';
  const searchParam = useSearchParam('q') || '';

  const [initialPaginationSettings] = useState({
    ...DEFAULT_PAGINATION_SETTINGS,
    search: searchParam,
    ordering: orderingParam,
  });

  const {
    page,
    pageSize,
    search,
    actualSearch,
    ordering,
    handleChangePage,
    handleChangeSearch,
    handleChangePageSize,
    handleChangeOrdering,
    headData,
  } = usePaginationSettings(initialPaginationSettings);

  useEffect(() => {
    setSearchParams(
      history,
      { q: search || undefined, sort: ordering || undefined },
      { method: 'replace' }
    );
  }, [search, ordering, history]);

  const [
    {
      value: { results: executions = EMPTY_ARRAY, count = 0 } = {},
      loading = true,
    },
    fetchExecutions,
  ] = useAsyncFnKeepLast(async (params) => {
    const data = await Automation2Service.getAutomationExecutions(params);
    return { ...data, results: data.results.map(setOpenMenuAbove) };
  }, []);

  useEffect(() => {
    if (entity?.id) {
      fetchExecutions({
        entityId: entity.id,
        search,
        ordering,
        page,
        pageSize,
      });
    }
  }, [entity?.id, search, ordering, page, pageSize, fetchExecutions]);

  const paramsRef = useRef({
    entityId: entity?.id,
    search,
    ordering,
    page,
    pageSize,
  });

  paramsRef.current = {
    entityId: entity?.id,
    search,
    ordering,
    page,
    pageSize,
  };

  const refetchExecutions = useCallback(
    () => fetchExecutions(paramsRef.current),
    [fetchExecutions]
  );

  const [show, modalProps, showSkipStepModal] =
    useSkipStepModal(refetchExecutions);

  const onUpdateAutomation = useCallback(
    async (automation, action) => {
      let data = null;
      const messages = getToastMessage(action, t);

      try {
        switch (action) {
          case AUTOMATION_ACTION.PAUSE:
            data = await Automation2Service.pauseAutomation({
              automationId: automation.id,
            });
            break;
          case AUTOMATION_ACTION.RESUME:
            data = await Automation2Service.playAutomation({
              automationId: automation.id,
            });
            break;
          case AUTOMATION_ACTION.CANCEL:
            data = await Automation2Service.cancelAutomation({
              automationId: automation.id,
            });
            break;
          case AUTOMATION_ACTION.SKIP_STEP_AND_RESUME:
            showSkipStepModal(automation);
            break;
          case AUTOMATION_ACTION.DOWNLOAD:
            await Automation2Service.downloadAutomationExecutionDetails(
              automation.id,
              {
                skipErrorBoundary: true,
              }
            );

            break;
          default:
            break;
        }

        if (action !== AUTOMATION_ACTION.SKIP_STEP_AND_RESUME) {
          showToast({
            variant: toastVariant.SUCCESS,
            message: messages.success,
          });
        }

        if (data === '') {
          await refetchExecutions();
        }
      } catch (error) {
        showToast({
          variant: toastVariant.FAILURE,
          message: messages.failure,
        });
      }
    },
    [refetchExecutions, showSkipStepModal, showToast, t]
  );

  const handleStartAutomation = useCallback(
    async (...args) => {
      await onStartAutomation(...args);
      fetchExecutions(paramsRef.current);
    },
    [fetchExecutions, onStartAutomation]
  );

  const [pageName, pageNameSingular] = [t('Automations'), t('Automation')];

  return (
    <PageSizing {...rest} data-qa="automations">
      {isMobile ? (
        <MobileView
          executions={executions}
          pageNameSingular={pageNameSingular}
          pageName={pageName}
          search={actualSearch}
          onHandleChangeSearch={handleChangeSearch}
          ordering={ordering}
          onHandleSort={handleChangeOrdering}
          onUpdateAutomation={onUpdateAutomation}
          totalCount={count}
          customObjectId={customObjectId}
          entity={entity}
          isClient={isContact}
        />
      ) : (
        <DesktopView
          executions={executions}
          pageNameSingular={pageNameSingular}
          pageName={pageName}
          search={actualSearch}
          onHandleChangeSearch={handleChangeSearch}
          headData={headData}
          onUpdateAutomation={onUpdateAutomation}
          onStartAutomation={handleStartAutomation}
          loading={loading}
          totalCount={count}
          customObjectId={customObjectId}
          entity={entity}
          isClient={isContact}
        />
      )}
      <StyledContentWidth>
        <TablePagination
          small={isMobile}
          page={page}
          perPage={pageSize}
          totalCount={count}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePageSize}
        />
      </StyledContentWidth>
      {show ? <ConfirmSkipStepModal {...modalProps} /> : null}
    </PageSizing>
  );
};
