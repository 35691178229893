import { useTranslation } from 'react-i18next';
import { useEditor } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import { useMemo, useState } from 'react';
import { useTextStyleValue } from '../../../WYSIWYG/useTextStyleValue';
import { getLinkAttributes, getLinkText } from '../../../WYSIWYG/utils/links';
import {
  ConfigureLinkModal,
  SCRIPT_EXECUTION,
} from '../../components/ConfigureLinkModal';
import {
  ToggleButton,
  ToolbarIcon,
  TextEditorToolbarSection,
} from '../../styles';
import { grayScale } from 'app/colors';
import { useModalControl } from 'hooks/useModalControl';

export const LinkToolbarSection = ({
  linkOptions,
  scriptExecutionOptions,
  allowRelativeLinks,
}) => {
  const { t } = useTranslation();
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  const [modalOpen, { showModal, hideModal }] = useModalControl();
  const fontFamily = useTextStyleValue('fontFamily');
  const [defaultLinkProps, setDefaultLinkProps] = useState({});
  const options = useMemo(
    () =>
      linkOptions.map(({ href, label, value }) => ({
        value: href || value,
        label,
      })),
    [linkOptions]
  );
  if (!editor) return null;

  const handleModalClose = () => {
    hideModal();
    editor.chain().focus().run();
  };

  const handleLink = ({ action, href, linkText, openInNewTab, scriptId }) => {
    hideModal();
    setDefaultLinkProps({});

    if (action === SCRIPT_EXECUTION) {
      const attrs = getLinkAttributes(editor);
      const currentScriptId = attrs['data-script-id'];

      editor
        .chain()
        .focus()
        .command(({ commands: { extendMarkRange } }) => {
          return currentScriptId
            ? extendMarkRange('link', {
                'data-script-id': currentScriptId,
              })
            : true;
        })
        .command(({ tr, editor }) => {
          const linkMark = editor.schema.mark('link', {
            'data-script-id': scriptId,
          });
          const textStyleMark = editor.schema.mark('textStyle', {
            fontFamily,
          });
          const textNode = editor.schema.text(linkText, [
            linkMark,
            textStyleMark,
          ]);
          tr.curSelection.replaceWith(tr, textNode);
          return true;
        })
        .run();
    } else {
      const currentLink = getLinkAttributes(editor)?.href;

      editor
        .chain()
        .focus()
        .command(({ commands: { extendMarkRange } }) => {
          return currentLink
            ? extendMarkRange('link', { href: currentLink })
            : true;
        })
        .command(({ tr, editor }) => {
          const linkMark = editor.schema.mark('link', {
            href,
            target: openInNewTab ? '_blank' : null,
          });
          const textStyleMark = editor.schema.mark('textStyle', {
            fontFamily,
          });
          const textNode = editor.schema.text(linkText, [
            linkMark,
            textStyleMark,
          ]);
          tr.curSelection.replaceWith(tr, textNode);
          return true;
        })
        .run();
    }
  };

  const handleUnlink = () => {
    editor.chain().focus().unsetLink().run();
  };

  const handleLinkClick = () => {
    const attrs = getLinkAttributes(editor);
    const defaultLinkText = getLinkText(editor);
    const { href: defaultLink, target } = attrs;

    setDefaultLinkProps({
      defaultLinkText,
      defaultLink,
      defaultOpenInNewTab: defaultLink ? target === '_blank' : true,
      defaultScriptId: attrs['data-script-id'],
    });
    showModal();
  };

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      {modalOpen && (
        <ConfigureLinkModal
          show={modalOpen}
          linkOptions={options}
          scriptExecutionOptions={scriptExecutionOptions}
          onConfirm={handleLink}
          onHide={handleModalClose}
          allowRelativeLinks={allowRelativeLinks}
          {...defaultLinkProps}
        />
      )}
      <ToggleButton title={t('Link Text')} onClick={handleLinkClick}>
        <ToolbarIcon icon="link" color={grayScale.dark} />
      </ToggleButton>
      <ToggleButton title={t('Unlink Text')} onClick={handleUnlink}>
        <ToolbarIcon icon="unlink" color={grayScale.dark} />
      </ToggleButton>
    </TextEditorToolbarSection>
  );
};
