import { DynamicTagsManagerTable } from './components/DynamicTagsManagerTable';
import { DynamicTagsManagerToolbar } from './components/DynamicTagsManagerToolbar';
import { useDynamicTagsManager } from './hooks/useDynamicTagsManager';
import Loader from 'components/Kizen/Loader';
import { StyledTableScrollContainer } from './styles';
import { useDynamicTagsAggregator } from './hooks/useDynamicTagsAggregator';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

export const DynamicTagsManager = ({
  field,
  model,
  activityObjectId,
  includeTitle = false,
  importedOptions,
  ...others
}) => {
  const [tagToolbarProps, tagTableProps] = useDynamicTagsManager(
    field,
    model,
    activityObjectId,
    importedOptions
  );
  return (
    <Loader loading={!field?.id || !model}>
      <DynamicTagsManagerToolbar
        includeTitle={includeTitle}
        {...tagToolbarProps}
        field={field}
        model={model}
      />
      <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
        <ScrollContainerBlocker compactHeader />
      </ScrollContainerBlockerWrapper>
      <StyledTableScrollContainer
        compactHeader
        bottom
        refresh={[tagTableProps.tags]}
        ref={tagTableProps.tableRef}
      >
        <DynamicTagsManagerTable
          {...tagTableProps}
          field={field}
          model={model}
          {...others}
        />
      </StyledTableScrollContainer>
    </Loader>
  );
};

const dummyFn = () => null;

export const DynamicTagAggregator = ({
  model,
  field,
  includeTitle = false,
  onChange = dummyFn,
  importedOptions,
  ...others
}) => {
  const [tagToolbarProps, tagTableProps] = useDynamicTagsAggregator({
    onChange,
    field,
    importedOptions,
  });
  return (
    <>
      <DynamicTagsManagerToolbar
        includeTitle={includeTitle}
        {...tagToolbarProps}
        field={field}
        model={model}
      />
      <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
        <ScrollContainerBlocker compactHeader />
      </ScrollContainerBlockerWrapper>
      <StyledTableScrollContainer
        compactHeader
        bottom
        refresh={[tagTableProps.tags]}
        ref={tagTableProps.tableRef}
      >
        <DynamicTagsManagerTable
          {...tagTableProps}
          field={field}
          model={model}
          {...others}
        />
      </StyledTableScrollContainer>
    </>
  );
};
