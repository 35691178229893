import { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Kizen/Icon';
import KizenTypography from 'app/kizentypo';
import Notice from 'components/Kizen/Notice';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import {
  ButtonsContainer,
  StyledAnchor,
  ActionsIcon,
  ProfileCard as Card,
  ActionsIconContainer,
} from './styles';
import ProfileTable from './ProfileTable';
import {
  HeaderSizedCell,
  SizedCell,
  TextCell,
  StatusCell,
  TableTitleWrapper,
  TableBoxesWrapper,
} from './tableStyles';
import useThirdPartyAuth, {
  PROVIDERS,
} from 'components/Modals/ThirdPartyAuth/useThirdPartyAuth';
import { CardToolbarButton } from 'components/Layout/CardToolbar';
import IntegratedEmailModal from 'components/Modals/ThirdPartyAuth/modal';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import useModal from 'components/Modals/useModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';

const StyledCard = styled(Card)`
  max-width: 100%;
`;

export const ProviderTypeCodes = {
  GMAIL: 1,
  OUTLOOK: 2,
};

export const StatusCodeTypes = {
  NOT_SETUP: 1,
  SYNCING: 2,
  SYNCED: 3,
  ERROR: 4,
};

export const isErrorStatusCode = (status) =>
  status === StatusCodeTypes.NOT_SETUP || status === StatusCodeTypes.ERROR;

const columnSizings = {
  spacer: { width: '20px' },
  email: { width: '236px', padding: '20px' },
  provider: { width: '106px', padding: '20px' },
  email_status: { width: '74px', padding: '20px' },
  actions: { width: '159px', padding: '20px' },
};
const createColumns = (onAccountDelete, t, handleRefresh) => {
  const columns = [
    {
      id: 'spacer',
      headCell: <SizedCell {...columnSizings.spacer} />,
      cell: <SizedCell {...columnSizings.spacer} />,
    },
    {
      id: 'email',
      headCell: (
        <HeaderSizedCell label={t('Email Address')} {...columnSizings.email} />
      ),
      cell: <SizedCell />,
      format: (value) => <TextCell>{value}</TextCell>,
    },
    {
      id: 'provider',
      headCell: (
        <HeaderSizedCell label={t('Provider')} {...columnSizings.provider} />
      ),
      cell: <SizedCell />,
      format: (value) => (
        <TextCell>
          {value === ProviderTypeCodes.GMAIL ? t('Gmail') : t('Outlook')}
        </TextCell>
      ),
    },
    {
      id: 'email_status',
      headCell: (
        <HeaderSizedCell label={t('Status')} {...columnSizings.email_status} />
      ),
      cell: <SizedCell />,
      format: (_, { emailStatus }) => (
        <StatusCell statusCode={emailStatus}>
          {isErrorStatusCode(emailStatus) ? t('Inactive') : t('Active')}
        </StatusCell>
      ),
    },
    {
      id: 'actions',
      headCell: (
        <HeaderSizedCell
          label={t('Actions')}
          {...columnSizings.actions}
          style={{ textAlign: 'right' }}
        />
      ),
      cell: <SizedCell padding="20px" />,
      format: (_, { id, emailStatus, provider }) => (
        <ActionsIconContainer>
          {isErrorStatusCode(emailStatus) ? (
            <StyledAnchor onClick={() => handleRefresh(provider)}>
              <ActionsIcon>
                <Icon size="1x" icon="sync" />
              </ActionsIcon>
            </StyledAnchor>
          ) : null}
          <ActionsIcon>
            <Icon size="1x" icon="delete" onClick={() => onAccountDelete(id)} />
          </ActionsIcon>
        </ActionsIconContainer>
      ),
    },
  ];

  return [...columns];
};

const EmailIntegrationNotice = styled(Notice)`
  margin-top: 5px;
  margin-bottom: 20px;
`;

const EmailIntegration = ({
  businessId,
  externalAccounts = EMPTY_ARRAY,
  onAccountDelete,
}) => {
  const { t } = useTranslation();

  const {
    triggerProps,
    modalProps,
    setProviderReadOnly,
    setProvider,
    openModal,
  } = useThirdPartyAuth({ businessId, useCurrentTeamMember: true });

  const handleRefresh = useCallback(
    (provider) => {
      if (provider === ProviderTypeCodes.GMAIL) {
        setProvider(PROVIDERS.GOOGLE);
      } else if (provider === ProviderTypeCodes.OUTLOOK) {
        setProvider(PROVIDERS.OUTLOOK);
      }
      setProviderReadOnly(true);
      openModal();
    },
    [setProvider, setProviderReadOnly, openModal]
  );

  const deletingIdRef = useRef(null);

  const [confirmationModalProps, , { show: showConfirmationModal }] = useModal({
    handleSubmit: () => onAccountDelete(deletingIdRef.current),
  });

  const handleDelete = useCallback(
    (id) => {
      showConfirmationModal();
      deletingIdRef.current = id;
    },
    [showConfirmationModal]
  );

  const columns = useMemo(
    () => createColumns(handleDelete, t, handleRefresh),
    [handleDelete, t, handleRefresh]
  );

  return (
    <>
      <StyledCard as={ContentWidth}>
        <TableTitleWrapper>
          <KizenTypography as="h3" type="subheader">
            {t('Integrated Inboxes')}
          </KizenTypography>
          <ButtonsContainer>
            <CardToolbarButton {...triggerProps}>
              {`+ ${t('Add Integrated Email')}`}
            </CardToolbarButton>
            <IntegratedEmailModal {...modalProps} />
          </ButtonsContainer>
        </TableTitleWrapper>
        {externalAccounts && !!externalAccounts.length ? (
          <ProfileTable columns={columns} data={externalAccounts} />
        ) : (
          <TableBoxesWrapper>
            <EmailIntegrationNotice>
              {t(
                'You don’t yet have any integrated emails. Please add an integrated email using the buttons above.'
              )}
            </EmailIntegrationNotice>
          </TableBoxesWrapper>
        )}
      </StyledCard>
      <ConfirmDeletionModal {...confirmationModalProps}>
        <KizenTypography>
          {t(
            'This will permanently delete the integrated email and will remove associated emails on the timeline.'
          )}
        </KizenTypography>
      </ConfirmDeletionModal>
    </>
  );
};

export default EmailIntegration;
