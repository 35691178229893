import { breakpoints, useBreakpoint } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import Loader from 'components/Kizen/Loader';
import { isEqual } from 'lodash';
import { useDebounce } from 'react-use';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import PersistentState from 'pages/Dashboard/context/persistentState';
import { getSummaryData } from 'pages/Dashboard/queries';
import { DASHBOARD } from 'queries/query-keys';
import DashletService from 'services/DashletService';
import {
  DASHLET_SLOW_STALE_TIME,
  getDashletQueryKey,
} from 'pages/Dashboard/util';
import React, { useCallback, useState, useMemo, useContext } from 'react';
import EmailInteractionStatsDashletList from '../components/List';
import { Container, Divider, RightColumn } from '../styles';
import EmailInteractionStatsChart from '../index';
import useChartData from '../hooks/useChartData';
import { DASHBOARD_COLUMNS } from 'components/DashboardGrid/types';
import { useManagedQuery } from 'pages/Dashboard/context/dataManager/useManagedQuery';
import { useTriggerRefetch } from 'pages/Dashboard/context/dataManager/getters';

const PAGE_SIZE = 20;

const DashletAdapter = ({
  dashlet,
  isLoading,
  dashboardId,
  dateFilter,
  teamFilter,
  breakpoint,
  refreshedAt,
  setRefreshedAt,
}) => {
  const { dispatch, state } = useContext(PersistentState.Context);
  const queryClient = useQueryClient();
  const currentEmail = state?.[dashlet.id];
  const isMobile = useBreakpoint(breakpoints.md);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(
    () => searchTerm
  );
  const [sort, setSort] = useState({ column: 'date', direction: 'desc' });
  const [initialLoad, setInitialLoad] = useState(true);
  const triggerRefetch = useTriggerRefetch();

  useDebounce(() => setDebouncedSearchTerm(searchTerm), 300, [searchTerm]);

  const emailListQueryKey = useMemo(() => {
    return [
      ...DASHBOARD.EMAIL_INTERACTION_STATS_LIST,
      dashboardId,
      dashlet?.id,
      PAGE_SIZE,
      debouncedSearchTerm,
      sort,
      dateFilter.start,
      dateFilter.end,
    ];
  }, [dashboardId, dashlet?.id, debouncedSearchTerm, sort, dateFilter]);

  const {
    data: emailList,
    isLoading: emailListLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: emailListQueryKey,
    queryFn: async ({ pageParam: urlWithCursor = null }) => {
      const res = await DashletService.getEmailList(
        dashboardId,
        dashlet?.id,
        PAGE_SIZE,
        urlWithCursor,
        debouncedSearchTerm,
        sort,
        dateFilter
      );
      return res;
    },
    getNextPageParam: (lastPage) => {
      const pageParam = lastPage.next;
      return pageParam;
    },
    getPreviousPageParam: (previousPage) => {
      const pageParam = previousPage.previous;
      return pageParam;
    },
    retry: false,
    useErrorBoundary: false,
  });

  const handleSetCurrentEmail = useCallback(
    (data) => {
      dispatch({
        [dashlet.id]: data,
      });
      triggerRefetch(dashlet.id);
    },
    [dashlet, dispatch, triggerRefetch]
  );

  const columns = DASHBOARD_COLUMNS[breakpoint];

  const invert = columns < 10;

  const handleFetchSummaryData = useCallback(
    async (bustCache = false) => {
      if (bustCache) {
        queryClient.invalidateQueries(emailListQueryKey);
      }
      return getSummaryData({
        dashboardId,
        dashletId: dashlet.id,
        dateFilter,
        teamFilter,
        currentEmail: currentEmail?.id,
        bustCache,
      });
    },
    [
      dashlet?.id,
      currentEmail?.id,
      dashboardId,
      teamFilter,
      dateFilter,
      emailListQueryKey,
      queryClient,
    ]
  );

  const summaryDataQueryKey = useMemo(() => {
    return getDashletQueryKey({
      dashletData: dashlet,
      dateFilter,
      teamFilter,
      currentEmail: currentEmail?.id,
    });
  }, [currentEmail?.id, dateFilter, dashlet, teamFilter]);

  const {
    data,
    isLoading: summaryDataLoading,
    isRefetching: summaryDataRefetching,
    __unsafeAdditionalQueryValues: { isFetchedAfterMount }, // use to determine if we are refetching via user email name select
  } = useManagedQuery({
    dashletId: dashlet.id,
    queryKey: summaryDataQueryKey,
    queryFn: handleFetchSummaryData,
    additionalParams: {
      staleTime: DASHLET_SLOW_STALE_TIME,
      retry: false,
      onError: (e) => {
        // If we try to fetch the chart data for an email that falls outside the selected date
        // range, it will fail with a 400 code, which indicates that we need to clear the email
        // selection and instead show the "all emails" chart.
        if (e.response?.status === 400) {
          handleSetCurrentEmail(null);
        }
      },
    },
  });

  if (data?.refreshed_at && data.refreshed_at !== refreshedAt) {
    setRefreshedAt(data.refreshed_at);
  }

  const { lineData, stages, tooltipLabels, smartSendStages } =
    useChartData(data);

  if (!emailListLoading && !summaryDataLoading && !isLoading && initialLoad) {
    setInitialLoad(false);
  }

  const showSingleLoader = useMemo(
    () =>
      ((emailListLoading || summaryDataLoading) && initialLoad) ||
      isLoading ||
      (isFetchedAfterMount && summaryDataRefetching),
    [
      emailListLoading,
      summaryDataLoading,
      isLoading,
      isFetchedAfterMount,
      summaryDataRefetching,
      initialLoad,
    ]
  );

  return (
    <Loader loading={showSingleLoader}>
      <Container invert={invert} mobile={isMobile}>
        <EmailInteractionStatsDashletList
          dashboardId={dashboardId}
          emailList={emailList}
          dashlet={dashlet}
          setCurrentEmail={handleSetCurrentEmail}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          sort={sort}
          setSort={setSort}
          invert={invert}
          dateFilter={dateFilter}
          mobile={isMobile}
          queryKey={emailListQueryKey}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          emailListLoading={emailListLoading}
        />
        <Divider invert={invert} mobile={isMobile}>
          <Icon icon={invert ? 'chevrons-down' : 'chevrons-right'} />
        </Divider>
        <RightColumn invert={invert} mobile={isMobile}>
          <EmailInteractionStatsChart
            isMobile={isMobile}
            summaryDataLoading={summaryDataLoading || summaryDataRefetching}
            currentEmail={currentEmail}
            stages={stages}
            smartSendStages={smartSendStages}
            id={dashlet?.id}
            lineData={lineData}
            tooltipLabels={tooltipLabels}
            emailNameKey="name"
          />
        </RightColumn>
      </Container>
    </Loader>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  const dashletEqual = isEqual(prevProps.dashlet, nextProps.dashlet);
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const dashboardIdEqual = prevProps.dashboardId === nextProps.dashboardId;
  const dateFilterEqual = isEqual(prevProps.dateFilter, nextProps.dateFilter);
  const teamFilterEqual = isEqual(prevProps.teamFilter, nextProps.teamFilter);
  const breakpointEqual = prevProps.breakpoint === nextProps.breakpoint;

  return (
    dashletEqual &&
    isLoadingEqual &&
    dashboardIdEqual &&
    dateFilterEqual &&
    teamFilterEqual &&
    breakpointEqual
  );
};

export default React.memo(DashletAdapter, propsAreEqual);
