import css from '@emotion/css';
import styled from '@emotion/styled';
import ActionCell from 'components/Tables/ActionCell';

export const StyledActionCell = styled(ActionCell)``;

export const StyledNoActionsCell = styled.div`
  ${({ width, padding, maxWidth }) => css`
    width: ${width || '20px'};
    padding: ${padding || 0};
    max-width: ${maxWidth || 'initial'};
  `}
`;
