import { useCallback, useEffect, useRef, useState } from 'react';
import {
  CALENDAR_EVENTS_OPTIONS,
  EVENTS_SEARCH_DATA,
} from 'pages/Broadcasts/constants';
import BroadcastService from 'services/BroadcastService';
import { useAsyncFn, useDebounce } from 'react-use';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { DEFAULT_INPUT_DELAY } from 'utility/config';

export const useEventsSearch = (upcomingPast, broadcast, filters, perPage) => {
  const [search, setSearch] = useState('');
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [events, setEvents] = useState(EMPTY_ARRAY);
  const eventLength = useRef();
  eventLength.current = events.length;
  const stagedRef = useRef({ query: {} });

  const onChange = useCallback((val) => setSearch(val), []);
  const onKeyPress = useCallback((e) => {
    switch (e.key) {
      case 'Enter':
      case 'Escape':
        setShowSearch(false);
        break;
      default:
        break;
    }
  }, []);

  const [{ loading, value: data = EVENTS_SEARCH_DATA }, getData] = useAsyncFn(
    async (isUpcoming, search, types, page = 1, pageSIze) => {
      const handler = isUpcoming
        ? BroadcastService.getPending
        : BroadcastService.getProcessed;
      const loadedEvents = await handler({
        q: search,
        type: types.toString(),
        page,
        page_size:
          pageSIze ||
          // we want to refetch events after new event was added, so we increase pageSize to be more than we already got, but to be a multiple of perPage
          (eventLength.current > perPage
            ? eventLength.current - (eventLength.current % perPage) + perPage
            : perPage),
        validate_filters: true,
      });
      setLoadingEvents(false);
      return loadedEvents;
    },
    []
  );

  useEffect(() => {
    setEvents((prev) =>
      data?.previous ? [...prev, ...data.results] : data?.results || EMPTY_ARRAY
    );
  }, [data]);

  useDebounce(
    () => {
      const query = { upcomingPast, search, filters: filters.toString() };
      if (
        (!broadcast && stagedRef.current.broadcast) ||
        Object.keys(query).some(([k, v]) => stagedRef.current.query[k] !== v)
      ) {
        getData(
          upcomingPast === CALENDAR_EVENTS_OPTIONS.upcoming,
          search,
          filters
        );
      }
      stagedRef.current = { broadcast, query };
    },
    DEFAULT_INPUT_DELAY,
    [broadcast, search, upcomingPast, getData, filters]
  );

  useEffect(() => {
    setLoadingEvents(true);
    setInitialLoading(true);
  }, [search, upcomingPast, filters]);

  useEffect(() => {
    setInitialLoading(false);
  }, [events]);

  const getMoreData = useCallback(
    (page) => {
      getData(
        upcomingPast === CALENDAR_EVENTS_OPTIONS.upcoming,
        search,
        filters,
        page,
        perPage
      );
    },
    [upcomingPast, search, filters, getData, perPage]
  );

  return {
    data: events,
    hasMorePages: !initialLoading && !!data.next,
    getMoreData,
    loading: loadingEvents || loading,
    initialLoading,
    resultsCount: search && data.results.length.toString(),
    inputSearchProps: { value: search, onChange, onKeyPress },
    showSearch,
    setShowSearch,
  };
};
