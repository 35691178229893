import AxiosService from './AxiosService';
import { snakeToCamelCaseKeys, camelToSnakeCaseKeys } from './helpers';

class PerformAction {
  constructor() {
    if (!PerformAction.instance) {
      PerformAction.instance = this;
    }
    return PerformAction.instance;
  }

  objectEndpoint = 'custom-objects';

  saveTextEndpoint = '/pending-text/send';

  getSummaryEndpoint = 'client/summary';

  getSummaryCOEndpoint = (objectId) =>
    `/${this.objectEndpoint}/${objectId}/bulk-action-summary`;

  changeTagsEndpoint = '/client/bulk_change_tags';

  manageTeamEndpoint = '/client/bulk_associate_team_member';

  getManageTeamCOEndpoint = (objectId) =>
    `/${this.objectEndpoint}/${objectId}/bulk-assign-team-members`;

  manageSubscriptionsEndpoint = 'client/bulk-manage-subscriptions';

  exportEndpoint = '/client/export-contacts';

  getExportCOEndpoint = (objectId) =>
    `/${this.objectEndpoint}/${objectId}/export-entity-records`;

  archiveEndpoint = '/client/bulk_destroy';

  getArchiveCOEndpoint = (objectId) =>
    `/${this.objectEndpoint}/${objectId}/bulk-archive-entity-record`;

  changeFieldValueEndpoint = '/client/bulk-change-field-value';

  getChangeFieldValueCOEndpoint = (objectId) =>
    `/${this.objectEndpoint}/${objectId}/bulk-change-field-value`;

  surveyEndpoint = '/survey/';

  clientEndpoint = '/client';

  automationCOEndpoint = (objectId, action = 'start') =>
    `/${this.objectEndpoint}/${objectId}/bulk-${action}-automation`;

  async saveText({ clientKey, contactIds, body }) {
    const { data } = await AxiosService.post(this.saveTextEndpoint, {
      client_key: clientKey, // Provide either a client key...
      contact_ids: contactIds, // ...or a list of contact ids, but not both.
      body,
    });
    return data;
  }

  sendEmail = async ({ emailId, clientKey }) => {
    const { data } = await AxiosService.post(`email-template/${emailId}/send`, {
      client_key: clientKey,
    });
    return data;
  };
  //Email by Template
  sendEmailTemplate = async (payload) => {
    const { data } = await AxiosService.post(`${this.clientEndpoint}/message`, {
      ...payload,
    });
    return data;
  };

  async modifyAutomation({ objectId, action, ...rest }) {
    const payload = camelToSnakeCaseKeys(rest);
    const { data } = await AxiosService.post(
      this.automationCOEndpoint(objectId, action),
      payload
    );
    return data;
  }

  async saveSendSurvey({ clientKey, id }) {
    const { data } = await AxiosService.post(
      `${this.surveyEndpoint}${id}/send_request`,
      {
        client_key: clientKey,
        id,
      }
    );
    return data;
  }

  async changeTags(tagsData) {
    const { data } = await AxiosService.post(
      `${this.changeTagsEndpoint}?v2=true`,
      camelToSnakeCaseKeys(tagsData)
    );
    return data;
  }

  async manageTeam({ objectId, ...rest }) {
    const payload = camelToSnakeCaseKeys(rest);
    const { data } = await AxiosService.post(
      objectId
        ? this.getManageTeamCOEndpoint(objectId)
        : `${this.manageTeamEndpoint}?v2=true`,
      payload
    );
    return data;
  }

  async manageSubscriptions({ clientKey, subscriptionListId, status }) {
    const { data } = await AxiosService.post(
      `${this.manageSubscriptionsEndpoint}?v2=true`,
      camelToSnakeCaseKeys({
        clientKey,
        subscriptionListId,
        status,
      })
    );
    return data;
  }

  async exportRecords({ objectId, ...rest }) {
    const payload = camelToSnakeCaseKeys(rest);
    const { data } = await AxiosService.post(
      objectId ? this.getExportCOEndpoint(objectId) : this.exportEndpoint,
      payload
    );
    return snakeToCamelCaseKeys(data);
  }

  async archiveRecords({ objectId, ...rest }) {
    const payload = camelToSnakeCaseKeys(rest);
    const { data } = await AxiosService.post(
      objectId
        ? this.getArchiveCOEndpoint(objectId)
        : `${this.archiveEndpoint}?v2=true`,
      payload
    );
    return snakeToCamelCaseKeys(data);
  }

  async getSummary({ objectId, ...body }, params = {}) {
    const { data } = await AxiosService.post(
      objectId ? this.getSummaryCOEndpoint(objectId) : this.getSummaryEndpoint,
      camelToSnakeCaseKeys(body),
      {
        params: camelToSnakeCaseKeys(params),
      }
    );
    return snakeToCamelCaseKeys(data);
  }

  async changeFieldValue({ objectId, ...rest }) {
    const payload = camelToSnakeCaseKeys(rest);
    const { data } = await AxiosService.post(
      objectId
        ? this.getChangeFieldValueCOEndpoint(objectId)
        : this.changeFieldValueEndpoint,
      payload
    );
    return snakeToCamelCaseKeys(data);
  }

  getBulkActionProggress = async (params, options) => {
    const { data } = await AxiosService.get('bulk-action-progress', {
      ...options,
      params,
    });
    return data;
  };

  getBulkActionProggressById = async (executionId, options) => {
    const { data } = await AxiosService.get(
      `bulk-action-progress/${executionId}`,
      options
    );
    return data;
  };
}

const instance = new PerformAction();
Object.freeze(instance);

export default instance;
