export const movingStrategies = {
  ADD_STEPS_TO_YES: 'addStepsToYes',
  ADD_STEPS_TO_NO: 'addStepsToNo',
  MERGE_PATH_STRATEGY: 'mergePathStrategy',
  LEAVE_BOTH_PATHS_SPLIT_STRATEGY: 'leaveBothPathsSplitStrategy',
  KEEP_ONLY_YES_PATH_STRATEGY: 'keepOnlyYesPathStrategy',
  KEEP_ONLY_NO_PATH_STRATEGY: 'keepOnlyNoPathStrategy',
  DELETE_BOTH_PATH_STRATEGY: 'deleteBothPathStrategy',
};

export const deleteStrategy = {
  MERGE_PATH_STRATEGY: 'mergePathStrategy',
  LEAVE_BOTH_PATHS_SPLIT_STRATEGY: 'leaveBothPathsSplitStrategy',
  KEEP_ONLY_YES_PATH_STRATEGY: 'keepOnlyYesPathStrategy',
  KEEP_ONLY_NO_PATH_STRATEGY: 'keepOnlyNoPathStrategy',
};
