import React from 'react';
import * as PropTypes from 'prop-types';
import { KizenTypography } from 'app/typography';
import { IconWithTooltip } from './IconWithTooltip';
import { LinkWrapper } from './styled';

export const LinkWithIconTooltip = ({
  children,
  href,
  label,
  dataQA = 'link-with-icon-tooltip',
  ...iconProps
}) => {
  return (
    <LinkWrapper>
      <KizenTypography
        type="anchor"
        target="_blank"
        href={href}
        data-qa={dataQA}
      >
        {children}
      </KizenTypography>
      <IconWithTooltip label={label} {...iconProps} />
    </LinkWrapper>
  );
};

LinkWithIconTooltip.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
