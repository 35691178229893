import UUID from 'utility/UUID';
import { FIELD_TYPES } from 'utility/constants';
import { namedToOptionWithCode } from 'services/helpers';
import { EMPTY_ARRAY, generateRatingsOptions } from 'utility/fieldHelpers';
import { MatchManyToOneRelations } from '../ModifyRelatedEntities/common';
import {
  isContactTagsField,
  isContactTitlesField,
  isStageField,
  isReasonLostField,
} from 'checks/fields';

export const inputFieldValueMappings = {
  SOURCE_VALUES: 'sourceValues',
  TARGET_VALUE: 'targetValue',
  TARGET_VALUES: 'targetValues',
  ORDER: 'order',
};

export const newFieldValueMappings = (order) => ({
  key: UUID.generate(),
  [inputFieldValueMappings.SOURCE_VALUES]: null,
  [inputFieldValueMappings.TARGET_VALUE]: null,
  order,
});

export const getTargetOptions = (
  fieldToModify,
  teammembersData,
  fieldType,
  t
) => {
  if (fieldType === FIELD_TYPES.TeamSelector.type) {
    return teammembersData;
  }
  if (fieldType === FIELD_TYPES.Rating.type) {
    return generateRatingsOptions(fieldToModify?.fieldData?.rating, t);
  }
  return (
    fieldToModify?.options || // for change field
    fieldToModify?.fieldData?.options || // for modify related
    EMPTY_ARRAY
  ).map(namedToOptionWithCode);
};

export const valueMappingsIsRequired = (fieldValueMappingsType = {}) => {
  if (isStageField(fieldValueMappingsType)) {
    return true;
  }

  return false;
};

export const defaultFieldValueMappingErrors = (key) => ({
  key,
  source: false,
  target: false,
});

export const fieldValueMappingsForFlow = (fieldValueMappings) => {
  if (fieldValueMappings) {
    const mapped = fieldValueMappings.map(
      ({ sourceValues, targetValue, targetValues, ...rest }) => {
        return {
          key: UUID.generate(),
          sourceValues: sourceValues.map((sourceValue) =>
            sourceValue?.value
              ? { ...sourceValue, value: String(sourceValue?.value) }
              : sourceValue?.id
                ? { ...sourceValue, value: String(sourceValue?.id) }
                : sourceValue !== null
                  ? String(sourceValue)
                  : null
          ),
          targetValues: targetValues.map((targetValue) => {
            return targetValue?.value
              ? { ...targetValue, value: String(targetValue?.value) }
              : targetValue?.id
                ? { ...targetValue, value: String(targetValue?.id) }
                : targetValue !== null
                  ? String(targetValue)
                  : null;
          }),
          ...rest,
        };
      }
    );
    return mapped;
  }

  return [newFieldValueMappings(0)];
};

export const fieldValueMappingsErrorsForFlow = (fieldValueMappings) => {
  const mapped = (fieldValueMappings || []).map(({ key }) =>
    defaultFieldValueMappingErrors(key)
  );
  return mapped;
};

export const defaultFieldValueMappingTypes = {
  source: { fieldType: null, name: null },
  target: { fieldType: null, name: null },
};

export const getMappingTypes = (args) => {
  // do it this way to handle null and undefined args
  const { fieldType, name, isDefault } = args || {
    fieldType: null,
    name: null,
    isDefault: null,
  };
  return {
    fieldType,
    name,
    isDefault,
  };
};

export const multipleValueFieldsFilter = [
  FIELD_TYPES.Relationship.type,
  FIELD_TYPES.Checkboxes.type,
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Files.type,
];

export const multipleValueFieldPredicate = (field, isContact) => {
  return (
    !MatchManyToOneRelations.includes(field?.relation?.cardinality) &&
    (multipleValueFieldsFilter.includes(field?.fieldType) ||
      isContactTitlesField(field) ||
      isContactTagsField(field) ||
      isReasonLostField(field))
  );
};

export const valueMappingsHasError = (
  fieldValueMappingsType = {},
  value,
  isSource,
  nullCount = 0
) => {
  if (isSource && nullCount > 1) {
    return true;
  }

  if (
    !isSource &&
    isStageField(fieldValueMappingsType) &&
    (!value || value?.length === 0)
  ) {
    return true;
  }

  return false;
};
