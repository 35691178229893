import styled from '@emotion/styled';
import { gutters, borderRadii } from 'app/spacing';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from 'app/colors';

export default styled.div`
  position: relative;
  ${({ focused }) =>
    focused &&
    css`
      border-color: ${colorsButton.blue.hover} !important;
    `}
  ${({ addBorder }) =>
    addBorder &&
    css`
      border-radius: ${borderRadii.small};
      border: 1px solid ${grayScale.medium};
    `};

  ${({ enableResize, minHeight, minWidth = 230 }) =>
    enableResize &&
    css`
      height: ${minHeight ||
      gutters.spacing(
        6
      )}px; // arbitury value that looks right to stop the resize going to small
      min-height: ${minHeight ||
      gutters.spacing(
        6
      )}px; // arbitury value that looks right to stop the resize going to small
      resize: both;
      overflow-x: hidden;

      max-width: 100%;
      ${minWidth &&
      css`
        min-width: ${minWidth}px;
      `}
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
  ${({ error }) =>
    error &&
    css`
      &&,
      &&:hover {
        border-radius: ${borderRadii.small};
        border: 1px solid ${colorsButton.red.hover};
      }
    `}
`;
