import Button from 'components/Button';
import BackButton from 'components/Button/BackButton';
import { ToolbarRight } from 'pages/Common/styles/ControlBar';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledToolbar, StyledToolbarLeft } from './styles';

const EditTemplateToolbar = ({
  handleGoBack,
  goBackPath,
  commit,
  commitAsync,
  dirty,
}) => {
  const { t } = useTranslation();

  const handleSaveAndClose = useCallback(async () => {
    await commitAsync();
    handleGoBack();
  }, [commitAsync, handleGoBack]);

  return (
    <StyledToolbar>
      <StyledToolbarLeft>
        <BackButton to={goBackPath}>{t('back')}</BackButton>
      </StyledToolbarLeft>
      <ToolbarRight>
        <Button disabled={!dirty} onClick={commit}>
          {t('Save')}
        </Button>
        <Button disabled={!dirty} onClick={handleSaveAndClose}>
          {t('Save & Close')}
        </Button>
      </ToolbarRight>
    </StyledToolbar>
  );
};

export default EditTemplateToolbar;
