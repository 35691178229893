import React from 'react';

export default function Kizen(props) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.33036 12H9.9759L4.6297 5.66717L9.60847 0H6.94455L2.1495 5.72114V0H0V12H2.1495V8.16792L3.21507 6.94453L7.33036 12Z"
        fill="currentColor"
      />
    </svg>
  );
}
