import React from 'react';
import styled from '@emotion/styled';
import { Col } from 'react-bootstrap';
import { KizenTypography } from 'app/typography';
import {
  background,
  colorsPrimary,
  colorsSecondary,
  colorsText,
  colorsButton,
  dropdownColors,
  tableHover,
  grayScale,
} from 'app/colors';
import { StyledSwatch, StyledSwatchGroup, StyledRow } from '../styles';
import SubSection from './helpers/SubSection';
import Section from './helpers/Section';

const renderSwatch = (hex, label) => {
  return (
    <Col xs={3} md={2} xl={1} key={hex} className="pb-2">
      <StyledSwatchGroup>
        <StyledSwatch bgColor={hex} />
        <KizenTypography type="label" margin="xs">
          {hex}
        </KizenTypography>
        {label && (
          <KizenTypography type="micro" textTransform="capitalize">
            {label}
          </KizenTypography>
        )}
      </StyledSwatchGroup>
    </Col>
  );
};

const renderColor = (color) => {
  const colorKey = Object.keys(color);

  if (typeof color === 'string') {
    return renderSwatch(color);
  }
  return colorKey.reverse().map((variantKey, i) => {
    const variant = color[variantKey];
    const label = colorKey[i] ? colorKey[i] : null;

    return renderSwatch(variant, label);
  });
};

const renderColorGroup = (colorsObj) => {
  return Object.keys(colorsObj).map((colorKey) =>
    renderColor(colorsObj[colorKey])
  );
};

const backgroundColors = {
  app: {
    'Application Background': background,
  },
  selects: {
    'Dropdown Background Hover': dropdownColors.hover,
  },
  tables: {
    'Table Hover': tableHover,
  },
};

// NOTE the grayScale, textSize, and labels here do not all
// correspond, but they do seem to be "to spec" per KZN-1369.
const textColorsWithLabels = {
  dark: {
    'Gray-Dark': colorsText.dark,
  },
  medium: {
    'Gray-Medium-Dark': colorsText.medium,
  },
  soft: {
    'Gray-Medium': colorsText.soft,
  },
  light: {
    'Gray-Medium-Light': colorsText.light,
  },
  grayLight: {
    'Gray-Light': grayScale.light,
  },
};

const Row = styled(StyledRow)`
  margin-bottom: -20px;
`;

const Swatches = () => (
  <Section title="Colors">
    <SubSection title="Background Colors">
      <Row>{renderColorGroup(backgroundColors)}</Row>
    </SubSection>

    <SubSection title="Text Colors">
      <Row>{renderColorGroup(textColorsWithLabels)}</Row>
    </SubSection>

    <SubSection title="Button Colors">
      <Row>{renderColorGroup(colorsButton)}</Row>
    </SubSection>

    <SubSection title="Primary Chart Colors">
      <Row>{renderColorGroup(colorsPrimary)}</Row>
    </SubSection>

    <SubSection title="Secondary Chart Colors">
      <Row>{renderColorGroup(colorsSecondary)}</Row>
    </SubSection>
  </Section>
);

export default Swatches;
