import { colorsPrimary, colorsSecondary } from 'app/colors';

export const CloudTypes = {
  MARKETING: 'marketing',
  SALES: 'sales',
  EXPERIENCE: 'experience',
  BUSINESS: 'business',
};

export const cloudsMap = {
  [CloudTypes.MARKETING]: {
    icon: 'envelope-open',
    label: 'Marketing Cloud',
    name: 'marketing_cloud',
  },
  [CloudTypes.SALES]: {
    icon: 'pipeline-entity',
    label: 'Sales Cloud',
    name: 'sales_cloud',
  },
  [CloudTypes.EXPERIENCE]: {
    icon: 'experience-entity',
    label: 'Experience Cloud',
    name: 'experience_cloud',
  },
  [CloudTypes.BUSINESS]: {
    icon: 'survey-entity',
    label: 'Business Intelligence',
    name: 'business_intelligence',
  },
};

export const BusinessLevelTypes = {
  ESSENTIALS: 'ESSENTIALS',
  PROFESSIONAL: 'PROFESSIONAL',
  ENTERPRISE: 'ENTERPRISE',
};

export const businessLevelsMap = {
  [BusinessLevelTypes.ESSENTIALS]: {
    icon: 'seedling',
    label: 'ESSENTIALS',
    name: 'essentials',
    value: 'ESSENTIALS',
  },
  [BusinessLevelTypes.PROFESSIONAL]: {
    icon: 'store',
    label: 'PROFESSIONAL',
    name: 'professional',
    value: 'PROFESSIONAL',
  },
  [BusinessLevelTypes.ENTERPRISE]: {
    icon: 'building',
    label: 'ENTERPRISE',
    name: 'enterprise',
    value: 'ENTERPRISE',
  },
};

export const cloudsOptionsMap = {
  [CloudTypes.MARKETING]: {
    [BusinessLevelTypes.ESSENTIALS]: [
      'Email Marketing',
      'Form Studio',
      'Surveys',
      'Marketing Automation',
    ],
    [BusinessLevelTypes.PROFESSIONAL]: [
      'Advertising Studio',
      'Two-Way Text Marketing',
      'ConnectAPI Access',
      'Reputation Management',
    ],
    [BusinessLevelTypes.ENTERPRISE]: [
      'Behavioral Tracking',
      'Revenue Attribution Models',
      'Compliance Management',
      'Full Access Control',
    ],
  },
  [CloudTypes.SALES]: {
    [BusinessLevelTypes.ESSENTIALS]: [
      'Activities & To Do Lists',
      'Contacts & Companies',
      'Customizable Pipelines',
      'Web-To-Lead Forms',
    ],
    [BusinessLevelTypes.PROFESSIONAL]: [
      'In-App Collaboration',
      'Sales Automations',
      'Intelligent Lead Routing',
      'Customer/Deal Attribution',
    ],
    [BusinessLevelTypes.ENTERPRISE]: [
      'Territory Management',
      'AI Lead Scoring',
      'Enterprise Integrations',
      'Full Access Control',
    ],
  },
  [CloudTypes.EXPERIENCE]: {
    [BusinessLevelTypes.ESSENTIALS]: ['TBD', 'TBD', 'TBD', 'TBD'],
    [BusinessLevelTypes.PROFESSIONAL]: ['TBD', 'TBD', 'TBD', 'TBD'],
    [BusinessLevelTypes.ENTERPRISE]: ['TBD', 'TBD', 'TBD', 'TBD'],
  },
  [CloudTypes.BUSINESS]: {
    [BusinessLevelTypes.ESSENTIALS]: [
      'Lead Growth Tracking',
      'Activity Dashboards',
      'Email Health Monitoring',
      'Form & Survey Reporting',
    ],
    [BusinessLevelTypes.PROFESSIONAL]: [
      'Revenue & Order Tracking',
      'Goals & Forecasts',
      'Team Leaderboards',
      '6-Hour Refresh Schedule',
    ],
    [BusinessLevelTypes.ENTERPRISE]: [
      'AI Reporting Insights',
      'Access to Data Scientist',
      'Scheduled Reports',
      'Custom Refresh Schedule',
    ],
  },
};

export const CreditCardTypes = {
  AMEX: 'AMERICAN_EXPRESS',
  VISA: 'VISA',
  MASTERCARD: 'MASTER_CARD',
  DISCOVERCARD: 'DISCOVER_CARD',
};

export const priceTypes = {
  MONTHLY: {
    name: 'monthly',
    label: '/mo',
  },
  PER_USER: {
    name: 'per_user',
    label: '/pu',
  },
  INCLUDED: {
    name: 'included',
    label: 'Included',
  },
};

export const pricingTableMap = {
  [CloudTypes.MARKETING]: {
    name: cloudsMap[CloudTypes.MARKETING].name,
    pricings: [
      {
        type: priceTypes.MONTHLY,
        levels: {
          [BusinessLevelTypes.ESSENTIALS]: {
            price: 20,
            label: 'Marketing Cloud - Essentials',
          },
          [BusinessLevelTypes.PROFESSIONAL]: {
            price: 100,
            label: 'Marketing Cloud - Professional',
          },
          [BusinessLevelTypes.ENTERPRISE]: {
            price: 800,
            label: 'Marketing Cloud - Enterprise',
          },
        },
      },
      {
        type: priceTypes.PER_USER,
        levels: {
          [BusinessLevelTypes.ESSENTIALS]: {
            price: 10,
            label: 'Additional Contacts',
          },
          [BusinessLevelTypes.PROFESSIONAL]: {
            price: 10,
            label: 'Additional Contacts',
          },
          [BusinessLevelTypes.ENTERPRISE]: {
            price: 10,
            label: 'Additional Contacts',
          },
        },
      },
    ],
  },
  [CloudTypes.SALES]: {
    name: cloudsMap[CloudTypes.SALES].name,
    pricings: [
      {
        type: priceTypes.PER_USER,
        levels: {
          [BusinessLevelTypes.ESSENTIALS]: {
            price: 20,
            label: 'Sales Cloud - Essentials Users',
          },
          [BusinessLevelTypes.PROFESSIONAL]: {
            price: 50,
            label: 'Sales Cloud - Professional Users',
          },
          [BusinessLevelTypes.ENTERPRISE]: {
            price: 75,
            label: 'Sales Cloud - Enterprise Users',
          },
        },
      },
    ],
  },
  [CloudTypes.EXPERIENCE]: {
    name: cloudsMap[CloudTypes.EXPERIENCE].name,
    pricings: [
      {
        type: priceTypes.MONTHLY,
        levels: {
          [BusinessLevelTypes.ESSENTIALS]: {
            price: 20,
            label: 'Experience Cloud - Essentials',
          },
          [BusinessLevelTypes.PROFESSIONAL]: {
            price: 100,
            label: 'Experience Cloud - Professional',
          },
          [BusinessLevelTypes.ENTERPRISE]: {
            price: 800,
            label: 'Experience Cloud - Enterprise',
          },
        },
      },
      {
        type: priceTypes.PER_USER,
        levels: {
          [BusinessLevelTypes.ESSENTIALS]: {
            price: 20,
            label: 'Experience Cloud - Essentials Users',
          },
          [BusinessLevelTypes.PROFESSIONAL]: {
            price: 40,
            label: 'Experience Cloud - Professional Users',
          },
          [BusinessLevelTypes.ENTERPRISE]: {
            price: 60,
            label: 'Experience Cloud - Enterprise Users',
          },
        },
      },
    ],
  },
  [CloudTypes.BUSINESS]: {
    name: cloudsMap[CloudTypes.BUSINESS].name,
    pricings: [
      {
        type: priceTypes.INCLUDED,
        levels: {
          price: 'Included',
          label: 'Business Inteligence Cloud',
        },
      },
    ],
  },
};

export const creditCards = {
  [CreditCardTypes.AMEX]: {
    icon: 'cc-amex',
    label: 'American Express',
    firstDigit: 3,
    activeColor: colorsPrimary.blue.dark,
  },
  [CreditCardTypes.VISA]: {
    icon: 'cc-visa',
    label: 'VISA',
    firstDigit: 4,
    activeColor: colorsPrimary.orange.dark,
  },
  [CreditCardTypes.MASTERCARD]: {
    icon: 'cc-mastercard',
    label: 'MasterCard',
    firstDigit: 5,
    activeColor: colorsSecondary.red.dark,
  },
  [CreditCardTypes.DISCOVERCARD]: {
    icon: 'cc-discover',
    label: 'Discover Card',
    firstDigit: 6,
    activeColor: colorsSecondary.tangerine.dark,
  },
};
