import { SyntheticEvent, useContext } from 'react';
import { steps } from './steps';
import { PrimaryObjectUploadContext } from './context';
import {
  StyledSubNavigationNavBar,
  StyledSubNavigationNavLink,
} from '../../styles';
import { PRIMARY_OBJECT_UPLOAD_STEPS } from '__pages/SmartConnectors/constants';

const stepOrder = {
  [PRIMARY_OBJECT_UPLOAD_STEPS.matching]: 0,
  [PRIMARY_OBJECT_UPLOAD_STEPS.mapping]: 1,
  [PRIMARY_OBJECT_UPLOAD_STEPS.preview]: 2,
};

export const SubNavigation = () => {
  const { handleGoToStep, step, isNew, loading } = useContext(
    PrimaryObjectUploadContext
  );

  return (
    <StyledSubNavigationNavBar>
      {Object.values(PRIMARY_OBJECT_UPLOAD_STEPS).map((_step, idx) => {
        const onClick = (event: SyntheticEvent) => {
          event.preventDefault();
          if (!loading) {
            handleGoToStep(_step);
          }
        };

        return (
          <StyledSubNavigationNavLink
            key={_step + idx}
            disabled={isNew && stepOrder[_step] > stepOrder[step]}
            icon={`circle-${idx + 1}`}
            route={steps[_step]}
            onClick={onClick}
            active={_step === step}
          />
        );
      })}
    </StyledSubNavigationNavBar>
  );
};
