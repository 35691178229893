import React, { useCallback, useContext } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';
import EmailBasicStatisticsReportType from './reportTypes/EmailBasicStatistics';
import EmailInteractionStatsReportType from './reportTypes/EmailInteractionStats';

const EmailsDisplaySettings = ({ defaultName = '', active = false }) => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const { name, area } = state;

  const onChangeName = useCallback(
    (value) => {
      return setState('name', value);
    },
    [setState]
  );

  // Hide self if area is not emails
  if (area !== AREA_RESPONSES.EMAILS) {
    return null;
  }

  return (
    <>
      <EmailBasicStatisticsReportType
        name={name}
        defaultName={defaultName}
        handleSetName={onChangeName}
        active={active}
      />
      <EmailInteractionStatsReportType
        name={name}
        defaultName={defaultName}
        handleSetName={onChangeName}
        active={active}
      />
    </>
  );
};

export default EmailsDisplaySettings;
