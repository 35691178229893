import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import LinkedSelection from 'components/Inputs/Select/LinkedSelection';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import {
  useRelationshipSelect,
  useSelectEnter,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { TopMenuButton } from 'components/Kizen/Menu';
import CreateEntityModalShort from 'components/Modals/CreateEntity/ShortForm';
import { useEntityCreationModal } from 'components/Modals/CreateEntity/hook';
import CreateEntityModalFull from 'components/Modals/CreateEntity/FullForm';
import FieldService from 'services/FieldService';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useQueryClient } from 'react-query';
import { RELATION_FIELD } from 'queries/query-keys';
import { useModalControl } from 'hooks/useModalControl';
import { FIELD_TYPES } from 'utility/constants';
import { RelationshipConfirmationModal } from 'components/Modals/RelationshipConfirmationModal';
import useField from 'hooks/useField';

/**
 * Visually similar component to RealtionshipOne but with options already supplied from `field.options`
 */
export const RelationshipOneWithOptions = forwardRef(
  ({ field, fieldType, value = null, fieldId, ...rest }, ref) => {
    const { t } = useTranslation();
    const { assignFieldHandle } = useKeyBoardContext();
    const preReleaseFeatures = usePreReleaseFeatures();
    const relationship = useMemo(
      () => FieldService.getRelationshipInfo(field, t, preReleaseFeatures),
      [field, t, preReleaseFeatures]
    );

    const selectRef = useRef(null);

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, rest);

    assignFieldHandle(fieldId, {
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      },
      customEscape: () => {
        setMenuIsOpen(false);
      },
      customUp: () => {
        setMenuIsOpen(true);
      },
      customDown: () => {
        setMenuIsOpen(true);
      },
    });

    return (
      <Select
        ref={selectRef}
        value={value}
        options={field?.options}
        placeholder={`Find ${relationship.name.single}`}
        menuLeftButton={<ClearSelectButton />}
        menuRightButton={<ApplySelectButton />}
        {...rest}
        {...customSelectMenuProps}
      />
    );
  }
);

const RelationshipOne = forwardRef(
  (
    {
      fieldId,
      field,
      label,
      value: valueProp = null,
      onChange,
      className = '',
      disabled,
      disableTopMenuButton = false,
      onFocus,
      optionsReadyCallback = null,
      fieldType,
      onMenuOpen,
      onMenuClose,
      keepPreviousData = true,
      ...others
    },
    ref
  ) => {
    const { assignFieldHandle } = useKeyBoardContext();
    const selectRef = useRef(null);
    const queryClient = useQueryClient();
    const [showEntityCreateModal, setShowEntityCreateModal] = useState(false);

    const [value, setValue] = useField(valueProp);

    const isContact = field.relation?.fetchUrl === 'client';

    const {
      initialValues,
      setInitialValues,
      parseTypeAhead,
      objectType,
      hasAbilityToAdd,
    } = useEntityCreationModal(field);

    const { t } = useTranslation();

    const [{ displayValue: displayWithoutMeta, handleChange, relationship }] =
      useRelationshipSelect({
        value,
        onChange,
        field,
      });

    const [typeaheadProps, typeaheadState, { refetch }] =
      useSelectTypeaheadWithScroll({
        fetch: relationship.search,
        objectToOption: relationship.toOption,
        onFocus,
        fieldId: fieldId || field.id,
        onMenuOpen,
        onMenuClose,
        selectRef,
        keepPreviousData,
        relatedObjectId: field.relation?.relatedObject,
      });

    const displayValue = useMemo(
      () =>
        displayWithoutMeta && {
          ...displayWithoutMeta,
          // See LinkedSelection definition
          meta: {
            link: relationship.link && relationship.link(value),
          },
        },
      [relationship, displayWithoutMeta, value]
    );

    const entityCreatedHandler = useCallback(
      async (newEntity) => {
        await refetch();
        // need to invalidate all the fields that are related to this object

        if (field?.relation?.relatedObject) {
          await queryClient.invalidateQueries({
            queryKey: [
              ...RELATION_FIELD.RELATED_OBJECTS(field.relation.relatedObject),
            ],
          });
        }

        handleChange(relationship.toOption(newEntity));
        setInitialValues({});
      },
      [
        refetch,
        queryClient,
        field.relation,
        handleChange,
        relationship,
        setInitialValues,
      ]
    );

    const onStartCreate = () => {
      if (typeaheadProps.inputValue) {
        parseTypeAhead(typeaheadProps.inputValue, isContact);
      }
      setShowEntityCreateModal(true);
      typeaheadProps.onInputChange('');
    };

    const selectEnterProps = useSelectEnter({
      handlePressEnter: onStartCreate,
      options: typeaheadProps.options,
      loadingOptions: typeaheadState.loading,
    });

    useEffect(() => {
      //  for scroll to bottom
      if (
        optionsReadyCallback &&
        typeaheadState.onOptionsReady &&
        selectRef?.current?.state.menuIsOpen
      ) {
        optionsReadyCallback(selectRef.current.select.controlRef);
      }
    });

    const [
      isConfirmModalOpen,
      { showModal: showConfirmationModal, hideModal: hideConfirmationModal },
    ] = useModalControl();

    const onConfirmConfirmation = useCallback(() => {
      handleChange(value);
      hideConfirmationModal();
    }, [value, hideConfirmationModal, handleChange]);

    const onHideConfirmation = useCallback(() => {
      setValue(valueProp);
      hideConfirmationModal();
    }, [setValue, hideConfirmationModal, valueProp]);

    const checkPrimaryField = useCallback(
      async (option, nextValue) => {
        if (
          option.action !== 'remove-value' &&
          option.action !== 'clear' &&
          field.fieldType === FIELD_TYPES.Relationship.type &&
          field.relation.cardinality === 'one_to_one'
        ) {
          const relatedObject = field.relation.relatedObject;
          const relatedField = field.relation.relatedField;

          const entityRecord = await FieldService.getEntityRecordById(
            relatedObject,
            nextValue.value
          );

          if (entityRecord?.fields[relatedField]?.value) {
            showConfirmationModal();
            return;
          }
        }
        handleChange(nextValue);
      },
      [field.fieldType, field.relation, showConfirmationModal, handleChange]
    );

    const handleOnCheckChange = useCallback(
      (val, option) => {
        checkPrimaryField(option, val);
        setValue(val);
      },
      [checkPrimaryField, setValue]
    );

    const Modal = field.useFullExperienceForm
      ? CreateEntityModalFull
      : CreateEntityModalShort;

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, { ...others, ...typeaheadProps });

    assignFieldHandle(fieldId || field.id, {
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      },
      customEscape: () => {
        setMenuIsOpen(false);
      },
      customUp: () => {
        setMenuIsOpen(true);
      },
      customDown: () => {
        setMenuIsOpen(true);
      },
      disabled,
    });

    return (
      <>
        <Select
          ref={selectRef}
          className={className}
          disabled={disabled}
          label={label}
          value={displayValue}
          onChange={handleOnCheckChange}
          placeholder={`${t('Find')} ${relationship.name.single}`}
          menuTopButton={
            !disableTopMenuButton &&
            hasAbilityToAdd && (
              <TopMenuButton onClick={onStartCreate}>
                {`${t('Add')} ${relationship.name.single}`}
              </TopMenuButton>
            )
          }
          menuLeftButton={<ClearSelectButton />}
          menuRightButton={<ApplySelectButton />}
          endAdornment={<IconAdornment icon={'chevron-down'} />}
          isLoading={typeaheadState?.loading}
          {...selectEnterProps}
          {...typeaheadProps}
          components={{
            SingleValue: LinkedSelection,
            ...typeaheadProps.components,
          }}
          {...others}
          {...customSelectMenuProps}
        />

        {showEntityCreateModal ? (
          <Modal
            objectType={objectType}
            objectId={isContact ? null : field.relation?.relatedObject}
            show={showEntityCreateModal}
            onCreated={entityCreatedHandler}
            onCancel={() => {
              setShowEntityCreateModal(false);
              setInitialValues({});
            }}
            initialValues={initialValues}
          />
        ) : null}
        <RelationshipConfirmationModal
          show={isConfirmModalOpen}
          onConfirm={onConfirmConfirmation}
          onHide={onHideConfirmation}
        />
      </>
    );
  }
);

RelationshipOneWithOptions.displayName = 'RelationshipOneWithOptions';

export default RelationshipOne;
