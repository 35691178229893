import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { getLink, emptyLongDash, getRelationshipLabel } from '../helpers';
import { forwardRef } from 'react';

const LinksWrapper = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
  overflow: hidden;
`;

const StyledLink = styled(TextEllipsisWithTooltip)`
  word-break: break-all;
  display: inline;
  white-space: unset;
`;

const Links = ({ links, type, metaInfo }) => {
  return links?.length
    ? links.map((el, i) => (
        <StyledLink
          key={el.id}
          type="anchor"
          href={getLink(el, { type, metaInfo })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getRelationshipLabel(metaInfo, el)}
          {links.length - 1 === i ? '' : ', '}
        </StyledLink>
      ))
    : emptyLongDash;
};

const RelationshipBlockUnwrapped = ({ item, lineClamp }, ref) => {
  return (
    <LinksWrapper
      data-qa-label={item?.label || ''}
      lineClamp={lineClamp}
      ref={ref}
    >
      {typeof item.value === 'string' ? (
        <StyledLink>{item.value}</StyledLink>
      ) : (
        <Links links={item.value} metaInfo={item.metaInfo} type={item.type} />
      )}
    </LinksWrapper>
  );
};

export const RelationshipBlock = forwardRef(RelationshipBlockUnwrapped);

RelationshipBlock.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.array,
    metaInfo: PropTypes.object,
  }).isRequired,
};
