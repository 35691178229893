import React, { useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormListItem } from 'components/Kizen/FormList';
import { StyledSection, StyledAddNotificationButton } from '../styled';
import { NotificationForm } from '../components';
import { DEFAULT_NOTIFICATION, FORMDATA_FIELDS, STEPS } from '../config';

const { NOTIFICATIONS } = FORMDATA_FIELDS;

const checkValidNotifications = ({ notifications }) =>
  notifications.every(({ timeAmount }) => timeAmount > 0);

const ScheduleActivityNotifications = ({
  updateValidation,
  notifications,
  updateField,
}) => {
  const { t } = useTranslation();
  const isValid = checkValidNotifications({ notifications });

  useEffect(() => {
    updateValidation(STEPS.NOTIFICATIONS, isValid);
  }, [isValid, updateValidation]);

  const scrollToView = useCallback(
    (el) => {
      notifications.length && el?.scrollIntoView();
    },
    [notifications.length]
  );

  const handleChange = useCallback(
    ({ index, value }) =>
      updateField(
        NOTIFICATIONS,
        notifications.map((el, j) => (index === j ? value : el))
      ),
    [updateField, notifications]
  );

  const handleAdd = useCallback(
    () =>
      updateField(NOTIFICATIONS, notifications.concat([DEFAULT_NOTIFICATION])),
    [updateField, notifications]
  );

  const handleRemove = useCallback(
    (index) => () =>
      updateField(
        NOTIFICATIONS,
        notifications.filter((_, i) => index !== i)
      ),
    [updateField, notifications]
  );

  return (
    <StyledSection index={2} header={t('Notifications')} isLastIndex isActive>
      {notifications.map((notification, i) => (
        <FormListItem
          key={i}
          index={i}
          value={notification}
          onChange={handleChange}
          onClickRemove={handleRemove(i)}
        >
          <NotificationForm />
        </FormListItem>
      ))}
      <StyledAddNotificationButton
        type="text"
        size="12px"
        onClick={handleAdd}
        ref={scrollToView}
      >
        + {t('ADD NOTIFICATION')}
      </StyledAddNotificationButton>
    </StyledSection>
  );
};

ScheduleActivityNotifications.propTypes = {
  updateField: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  updateValidation: PropTypes.func.isRequired,
};

export default ScheduleActivityNotifications;
