import React from 'react';
import { isMatch } from 'date-fns2';
import { useTranslation } from 'react-i18next';
import IconAdornment from '../../../Inputs/Adornments/IconAdornment';
import TextInput from '..';

export const validate = (t) => ({
  character: (char) =>
    /[0-9,/]/.test(char) || t('Only numeric characters and `/` are accepted.'),
  full: (value) => {
    if (value && (!isMatch(value, 'MM/dd/yyyy') || value.length !== 10)) {
      return t(
        'The date entered does not exist or is incorrectly formatted. Please use MM/DD/YYYY (e.g 01/30/2000).'
      );
    }

    return null;
  },
});

const DatePickerTextInput = React.forwardRef(
  function DatePickerTextInput(props, ref) {
    const { t } = useTranslation();

    return (
      <TextInput
        inModal
        endAdornment={<IconAdornment icon="calendar" />}
        ref={ref}
        {...props}
        validate={{ ...validate(t), ...props.validate }}
        onChange={(val, ev) => {
          const errorMessage = validate(t).full(val);
          props.onChange(val, ev, errorMessage ? { errorMessage } : null);
        }}
      />
    );
  }
);

DatePickerTextInput.defaultProps = {
  placeholder: 'MM/DD/YYYY',
};

export default DatePickerTextInput;
