import React from 'react';
import { ClearMenuButton } from '../../Kizen/Menu';

export default function ClearSelectButton({
  onClick = null,
  select,
  ...props
}) {
  return (
    <ClearMenuButton
      onClick={(ev) => {
        if (select.selectProps.closeOnClearClick)
          select.selectProps.selectManager.onMenuClose();
        select.setValue(select.isMulti ? [] : null, 'clear');
        if (onClick) onClick(ev);
      }}
      {...props}
    />
  );
}

ClearSelectButton.enrich = {
  select: true,
};
