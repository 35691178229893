import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  cloneElement,
} from 'react';
import { defaultsDeep } from 'lodash';
import { shallowEqual } from 'react-redux';
import useField from 'hooks/useField';
import { grayScale } from 'app/colors';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import KizenTypography from 'app/kizentypo';
import { Dot } from 'components/Fields/ColorLabel';
import SelectOverlay from '../SelectOverlay';
import TextControl from './TextControl';
import { useMultiSelectValue } from '../MultiSelect';
import { basePopperConfig } from './helpers';
import { RelationshipConfirmationModal } from 'components/Modals/RelationshipConfirmationModal';
import { popperModifiers } from 'components/helpers';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useCustomSelectMenuProps } from '../../../hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useRelationshipSubmit } from './useRelationshipSubmit';

export function MultiSelectInlineLabelItem({
  value = null,
  to = null,
  type = null,
  disabled = null,
  children,
  isColored = null,
  dataQa = '',
  ...props
}) {
  const label = children || (value && value.label);
  const color = isColored && value && value.color;
  return (
    <KizenTypography
      as={type === 'link' && !disabled ? null : 'span'}
      to={!value ? null : to}
      type={!value || (disabled && type === 'link') ? null : type}
      disabled={disabled}
      color={disabled ? grayScale.mediumDark : undefined}
      size="inherit"
      data-qa={dataQa}
      {...props}
    >
      {!children && label && color && <Dot color={color} />}
      {label || '—'}
    </KizenTypography>
  );
}

export function MultiSelectInlineLabel({
  value: valueProp,
  disabled = null,
  children,
  labelLinkProps = null,
  labelItemEl = <MultiSelectInlineLabelItem />,
  dataQa = '',
  ...props
}) {
  const value = valueProp || [];
  const getLabelProps = (option) =>
    labelLinkProps
      ? {
          type: 'link',
          to: value && labelLinkProps.link({ id: option.value }),
          target: '_blank',
        }
      : {};

  return (
    <TextEllipsisWithTooltip
      color={disabled ? grayScale.mediumDark : undefined}
      {...props}
    >
      {children}
      {!children &&
        value.map((val, i) => (
          <>
            {cloneElement(labelItemEl, {
              key: val.value,
              value: val,
              disabled,
              dataQa,
              ...getLabelProps(val),
            })}
            {i !== value.length - 1 && ', '}
          </>
        ))}
      {!children && !value.length && '—'}
    </TextEllipsisWithTooltip>
  );
}

const nudgePopperUp = defaultsDeep({}, basePopperConfig, {
  modifiers: [
    popperModifiers.offSet(0, -3), // Align directly under TextControl text
  ],
});

export default function MultiSelectInline({
  icon = 'chevron-down',
  hoverable = true,
  onSubmit = null,
  autoFocus = null,
  onAutoFocus = null,
  submitUnchanged = null,
  readOnly = false,
  className,
  children: selectEl,
  labelEl = <MultiSelectInlineLabel />,
  onHide = null,
  handleUpdateTableRecords,
  showToolTip,
  placement,
  tooltipLabel,
  popperConfig,
  title,
  viewOnlySelect,
  object,
  shouldFocusNext,
  enableLink = true,
  ...others
}) {
  // Setup state
  const layoutRef = useRef();
  const viewRef = useRef();
  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    value: valueProp,
    options,
    onChange,
    relationship,
    field,
  } = selectEl.props;

  const fieldId = useMemo(() => `${field?.id}-${object?.id}`, [field, object]);
  const value = useMultiSelectValue({
    value: valueProp,
    options,
  });

  const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();
  const [stagedValue, setStagedValue] = useField(value, [
    JSON.stringify(value), // Prevents losing staged value when options change, breaking useMultiSelectValue's memo
  ]);
  const displayValue = onSubmit ? stagedValue : value;

  // Support autoFocus

  const onAutoFocusRef = useRef();
  onAutoFocusRef.current = onAutoFocus;

  useEffect(() => {
    if (autoFocus && !assignFieldHandle) {
      setShow(true);
      if (onAutoFocusRef.current) onAutoFocusRef.current(layoutRef.current);
    }
  }, [autoFocus, assignFieldHandle]);

  // Handlers

  const handleChange = useCallback(
    (val) => {
      if (onSubmit) {
        setStagedValue(val);
      } else {
        setShow(false);
      }
      if (onChange) onChange(val);
    },
    [onChange, onSubmit, setStagedValue]
  );

  const handleSubmit = useCallback(async () => {
    if (submitUnchanged || !shallowEqual(stagedValue, value)) {
      try {
        setSubmitting(true);
        await onSubmit(stagedValue);
      } catch (err) {
        setStagedValue(value);
      } finally {
        setSubmitting(false);
      }
    }
  }, [
    onSubmit,
    setStagedValue,
    setSubmitting,
    stagedValue,
    submitUnchanged,
    value,
  ]);

  const { labelLinkProps, readOnlyIcon, readOnlySelect } = useMemo(() => {
    const showViewOnlySelect = viewOnlySelect && Boolean(displayValue?.length);
    return {
      labelLinkProps: enableLink && relationship?.link ? relationship : null,
      readOnlyIcon: showViewOnlySelect ? 'search' : false,
      readOnlySelect: () => showViewOnlySelect && setShow(true),
    };
  }, [relationship, viewOnlySelect, displayValue, enableLink]);

  const selectRef = useRef();
  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    {
      shouldUseKeyboardEventHandle: true,
      onBlur: (e, shouldGoNext) =>
        getKeyListenersProps?.(fieldId)?.onBlur(
          e,
          shouldGoNext && shouldFocusNext
        ),
      ...others,
    }
  );
  assignFieldHandle(fieldId, {
    customFocus: () => {
      setShow(true);
      selectRef.current?.focus();
      getKeyListenersProps?.(fieldId)?.onFocus();
      return layoutRef.current;
    },
    customEscape: () => {
      setShow(false);
    },
    disabled: readOnly,
    shouldFocusNext: shouldFocusNext,
  });

  const {
    handleHide,
    handleConfirm,
    handleHideConfirm,
    handleChangeWithConfirmation,
    modal,
  } = useRelationshipSubmit({
    onHide,
    onSubmit: onSubmit && handleSubmit,
    field,
    stagedValue,
    value,
    setStagedValue,
    handleUpdateTableRecords,
    setShow,
    setSubmitting,
    displayValue,
    handleChange,
  });

  if (readOnly) {
    return (
      <>
        <TextControl
          className={className}
          icon={readOnlyIcon}
          onSelect={readOnlySelect}
          ref={viewRef}
        >
          {cloneElement(labelEl, {
            value: displayValue,
            disabled: submitting,
            labelLinkProps,
            tooltipLabel,
            popperConfig,
          })}
        </TextControl>
        <SelectOverlay
          show={show}
          target={viewRef.current}
          onHide={() => setShow(false)}
          popperConfig={nudgePopperUp}
          {...others}
        >
          {cloneElement(selectEl, {
            value: displayValue,
            onChange: handleChange,
          })}
        </SelectOverlay>
      </>
    );
  }

  return (
    <>
      <TextControl
        ref={layoutRef}
        className={className}
        hoverable={hoverable}
        disabled={submitting}
        selected={show}
        onSelect={() => {
          getKeyListenersProps?.(fieldId)?.onFocus(fieldId);
          setShow(true);
        }}
        icon={icon}
        title={title}
        data-qa={`${others['data-field-id']}-multi-select-list`}
      >
        {cloneElement(labelEl, {
          value: displayValue,
          disabled: submitting,
          labelLinkProps: relationship?.link ? relationship : null,
          tooltipLabel,
          popperConfig,
        })}
      </TextControl>
      <SelectOverlay
        show={show}
        target={layoutRef.current}
        onHide={handleHide}
        popperConfig={nudgePopperUp}
        {...others}
      >
        {cloneElement(selectEl, {
          value: displayValue,
          ref: selectRef,
          inputRef: (el) => (selectRef.current = el),
          onChange: handleChangeWithConfirmation,
          ...getKeyListenersProps?.(fieldId),
          setShown: setShow,
          handleSubmit: handleSubmit,
          ...customSelectMenuProps,
        })}
      </SelectOverlay>
      <RelationshipConfirmationModal
        show={modal}
        onConfirm={handleConfirm}
        onHide={handleHideConfirm}
        className="rel-confirm-modal"
      />
    </>
  );
}
