import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { colorsButton, grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import TablePagination from 'components/Tables/Big/TablePagination';
import BigListLayout, { BigListTitle } from 'components/Layout/BigListLayout';
import {
  BigListMainSelector,
  BigListSearchInput,
  BigListToolbar,
  BigListToolbarSection,
} from 'components/Layout/BigListLayout/Toolbar';
import { isPipelineObject } from 'components/Modals/utilities';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';
import { IconSizing } from 'components/Kizen/Icon';
import { useDebouncedPastScrollThreshold } from 'hooks/useDebouncedWindowScroll';
import MobileTable from './MobileTable';
import MobileBoard from './MobileBoard';
import ViewVariant from 'components/ViewVariant';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import ObjectCharts from 'components/ObjectCharts';
import { useSelector } from 'react-redux';
import { getRecordsIsFilteringSelector } from 'store/customObjectsRecordsPage/selectors';
import Loader from 'components/Kizen/Loader';

const StyledBigListToolbar = styled(BigListToolbar)`
  padding-top: 5px;
  height: 51px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  flex-grow: 1;

  && > div:first-child {
    width: 100%;
  }
`;

const StyledIconButtonMenu = styled(IconButtonMenu)`
  margin-left: 10px;
`;

const StyledBigListTitle = styled(BigListTitle)`
  margin-top: 10px;
  margin-bottom: 9px;
`;

const StyledBigListLayout = styled(BigListLayout)`
  & > div:first-child > div {
    height: 101px;
  }
`;
const newMobileRecordOptions = (t) => [
  {
    value: 'newRecord',
    label: t('New Record'),
  },
];

const MobileLayout = ({
  model,
  createAccess,
  records,
  recordsCount,
  recordsCountAndNew,
  search = '',
  sort,
  settingOptions,
  viewVariant,
  pageNumber,
  pageSize,
  selectionOptions,
  selection,
  boardData,
  stages,
  fields,
  getMoreCards,
  onChangeSearch,
  onChangeSort,
  onChangePageSize,
  onChangePageNumber,
  onChecked,
  onChoseSettings,
  onChoseNewRecord,
  onCheckedSelection,
  onSelectStageColumn,
  onChoseCardMenu,
  onSubmitRecord,
  handleChangeGroup,
  groups,
  groupId = null,
  onChangeStage,
  chartGroupConfigProps,
  fullFilterObject,
  isLoadingView,
  ...others
}) => {
  const { t } = useTranslation();
  const isRecordsFiltering = useSelector(getRecordsIsFilteringSelector);

  const scrolled = useDebouncedPastScrollThreshold();
  const preReleaseFeatures = usePreReleaseFeatures();

  return (
    <StyledBigListLayout
      toolbar={
        <StyledBigListToolbar>
          <BigListToolbarSection>
            <ViewVariant
              objectId={model.id}
              hide={isPipelineObject(model) ? '' : VIEW_VARIANTS.BOARD}
              isMobile
            />
          </BigListToolbarSection>
          <BigListToolbarSection>
            {createAccess ? (
              <StyledIconButtonMenu
                position="right"
                title={t('Add Record')}
                sizing="overlay"
                color={colorsButton.iconGray}
                options={newMobileRecordOptions(t)}
                onChange={onChoseNewRecord}
                borderColor={scrolled ? grayScale.medium : null}
              >
                <IconSizing size="12px">
                  <Icon icon="plus" />
                </IconSizing>
              </StyledIconButtonMenu>
            ) : null}
          </BigListToolbarSection>
        </StyledBigListToolbar>
      }
      search={
        <BigListToolbar>
          <BigListToolbarSection>
            <BigListMainSelector
              value={groupId || 'none'}
              options={groups}
              onChange={handleChangeGroup}
            />
          </BigListToolbarSection>
          <SearchWrapper>
            <BigListSearchInput
              placeholder={`${t('Find')} ${model.entityName} ${t('Records')}`}
              value={search}
              onChange={onChangeSearch}
              loading={isRecordsFiltering}
            />
          </SearchWrapper>
        </BigListToolbar>
      }
      pagination={
        viewVariant === VIEW_VARIANTS.TABLE &&
        !isLoadingView && (
          <TablePagination
            page={pageNumber}
            perPage={pageSize}
            totalCount={recordsCount}
            onChangePage={onChangePageNumber}
            onChangePerPage={onChangePageSize}
            {...others}
          />
        )
      }
    >
      <Loader loading={isLoadingView}>
        {viewVariant === VIEW_VARIANTS.TABLE ? (
          <>
            <StyledBigListTitle
              count={recordsCountAndNew}
              single={`${model.entityName} ${t('Record')}`}
              plural={`${model.entityName} ${t('Records')}`}
            />
            <MobileTable
              model={model}
              sort={sort}
              records={records}
              selectionOptions={selectionOptions}
              selection={selection}
              onChecked={onChecked}
              onChangeSort={onChangeSort}
              onCheckedSelection={onCheckedSelection}
            />
          </>
        ) : viewVariant === VIEW_VARIANTS.CHARTS ? (
          <ObjectCharts
            mobile
            model={model}
            groupId={groupId}
            groups={groups}
            search={search}
            onChangeSearch={onChangeSearch}
            recordsCountAndNew={recordsCountAndNew}
            chartGroupConfigProps={chartGroupConfigProps}
            fullFilterObject={fullFilterObject}
            handleChangeGroup={handleChangeGroup}
            preReleaseFeatures={preReleaseFeatures}
          />
        ) : (
          <MobileBoard
            model={model}
            boardData={boardData}
            settings={boardData.settings}
            fields={fields}
            stages={stages}
            getMoreCards={getMoreCards}
            onSelectStageColumn={onSelectStageColumn}
            onChoseCardMenu={onChoseCardMenu}
            onChoseNewRecord={onChoseNewRecord}
            onSubmitRecord={onSubmitRecord}
            onChangeStage={onChangeStage}
          />
        )}
      </Loader>
    </StyledBigListLayout>
  );
};

export default MobileLayout;
