import React, { forwardRef } from 'react';
import OutLine from './Outline';
import { variantProp } from '../props';

const LibraryMessageInput = forwardRef(({ variant, ...props }, ref) => {
  const VariantFileInput = OutLine;
  return <VariantFileInput ref={ref} {...props} />;
});

LibraryMessageInput.displayName = 'LibraryMessageInput';

LibraryMessageInput.propTypes = {
  variant: variantProp,
};

LibraryMessageInput.defaultProps = {
  variant: 'outline',
};

export default LibraryMessageInput;
