import { CUSTOM_OBJECTS } from 'queries/query-keys';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import CustomObjectService from 'services/CustomObjectsService';
import { getBusinessClientObject } from 'store/authentication/selectors';

const useMetadataForTable = ({ objectId, contacts }) => {
  const { t } = useTranslation();

  const clientObject = useSelector(getBusinessClientObject);
  const id = contacts ? clientObject.id : objectId;

  const { data: objectDetails, isLoading: objectDataLoading } = useQuery(
    CUSTOM_OBJECTS.DETAILS(id),
    () => {
      return CustomObjectService.getCustomObjectDetails(id);
    }
  );

  const fieldsById = useMemo(() => {
    const fieldMap =
      objectDetails?.fields?.reduce((acc, field) => {
        acc[field.id] = field;
        return acc;
      }, {}) ?? {};

    if (contacts) {
      return {
        fullName: {
          id: 'fullName',
          displayName: t('Full Name'),
          name: 'full_name',
        },
        ...fieldMap,
      };
    }

    return fieldMap;
  }, [objectDetails, contacts, t]);

  const fields = useMemo(() => {
    return objectDetails?.fields ?? [];
  }, [objectDetails]);

  const loading = objectDataLoading;

  return {
    loading,
    objectDetails,
    fieldsById,
    fields,
  };
};

export default useMetadataForTable;
