import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useClickAway } from 'react-use';
import Overlay from 'react-bootstrap/Overlay';
import {
  border,
  borderTight,
  dropdownColors,
  grayScale,
  shadow,
  shadowLight,
} from 'app/colors';
import { isMobile, gutters, layers, useWindowSize } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';

const StyledDropdown = styled.div`
  margin: 0;
  padding: 0;
  position: ${({ position }) => position || 'static'};
  min-width: 2rem;

  .item {
    position: relative;
    ${({ mobileView }) =>
      mobileView
        ? `display: flex;
          flex-direction: column-reverse;
          justify-content:center;
          align-items: center;`
        : ''}
  }
`;

const StyledMenu = styled.div`
  position: absolute;
  // Needs to open above radio buttons and checkboxes, which have z-index of 1
  z-index: ${layers.content(0, 2)};
  background-color: ${grayScale.white};
  ${shadow};
  ${border};
  border-style: none;
  min-width: 387px; // Same as desktop multi-select min-width
`;

const OverlayContainer = ({ enableOverlay, target, zIndex, children }) => {
  if (!enableOverlay || !target) return children;

  return (
    <Overlay
      show
      transition={false}
      placement="bottom-start"
      target={target}
      popperConfig={{
        modifiers: {
          computeStyle: {
            fn(data, options) {
              const { fn } =
                data.instance.constructor.Defaults.modifiers.computeStyle;
              data = fn(data, options);
              if (!isNaN(zIndex)) {
                data.styles.zIndex = zIndex;
              }
              return data;
            },
          },
        },
      }}
    >
      <div>{children}</div>
    </Overlay>
  );
};

const Dropdown = ({
  setOpen,
  zIndex,
  toggle,
  open = false,
  autoClose = true,
  closeOnSelect = false,
  asOverlay = false,
  MenuComponent = StyledMenu,
  children,
  ...props
}) => {
  const [internalOpen, setInternalOpen] = useState(open);
  const internalGetOpen = setOpen ? open : internalOpen;
  const internalSetOpen = (flag) => {
    if (setOpen) setOpen(flag);
    else setInternalOpen(flag);
  };
  const dropdownRef = useRef(null);
  const { width } = useWindowSize();

  useClickAway(dropdownRef, () => {
    if (internalGetOpen && autoClose) {
      internalSetOpen(false);
    }
  });

  return (
    <StyledDropdown ref={dropdownRef} mobileView={isMobile(width)} {...props}>
      <div
        className="item"
        role="presentation"
        onClick={() => {
          internalSetOpen(!internalGetOpen);
        }}
      >
        {toggle}
      </div>
      {internalGetOpen && (
        <OverlayContainer
          enableOverlay={asOverlay}
          target={dropdownRef.current}
          zIndex={zIndex}
        >
          <MenuComponent
            className="menu"
            onClick={closeOnSelect ? () => internalSetOpen(false) : undefined}
          >
            {children}
          </MenuComponent>
        </OverlayContainer>
      )}
    </StyledDropdown>
  );
};

const ThreeDotMenu = styled(StyledMenu)`
  transform: translateX(-100%) translateX(22px);
  text-align: left;
  ${borderTight};
  ${shadowLight}
  min-width: 0; // Take-up no extra space
  button {
    text-align: left;
    width: 100%;
    padding: 0 ${gutters.standard};
    height: 32px;
    cursor: pointer;
    display: block;
    border-radius: 0;
    &:hover {
      // TODO some day consider using Select's dropdown directly as in the InlineTimePicker
      background-color: ${dropdownColors.hover};
    }
  }
`;

const ThreeDotDropdownIcon = () => (
  <IconButton
    sizing="dense"
    color={{
      default: grayScale.mediumDark,
      hover: grayScale.dark,
    }}
    style={{ marginTop: -1 }} // Just needs a nudge to match alignment in designs
  >
    <Icon icon="three-dot" />
  </IconButton>
);

export const ThreeDotDropdown = (props) => (
  <Dropdown
    MenuComponent={ThreeDotMenu}
    toggle={<ThreeDotDropdownIcon />}
    {...props}
  />
);

Dropdown.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  autoClose: PropTypes.bool,
  toggle: PropTypes.element.isRequired,
  closeOnSelect: PropTypes.bool,
  asOverlay: PropTypes.bool,
  zIndex: PropTypes.number,
  MenuComponent: PropTypes.node,
};

export default Dropdown;

export const Menu = StyledMenu;
