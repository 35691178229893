import { useCallback } from 'react';
import { usePermissionsContext } from './context';
import { addPermissionsForSection } from '../src/state-ops';
import type { PermissionsContext } from '../src/types';

const getCustomObjectConfigs = (id: string, ctx: PermissionsContext) => {
  const existingValues = ctx.existing_custom_object_values?.[id];

  return ctx.meta_config.custom_objects.map((config) => ({
    ...config,
    default: existingValues?.[config.key] ?? config.default,
    metadata: { custom_object_id: id },
  }));
};

export const useGetCustomObjectConfigs = () => {
  const ctx = usePermissionsContext();

  return useCallback(
    (id: string) => {
      return getCustomObjectConfigs(id, ctx);
    },
    [ctx]
  );
};

export const useAddCustomObjectPermissions = () => {
  const ctx = usePermissionsContext();

  return useCallback(
    (id: string) => {
      const configs = getCustomObjectConfigs(id, ctx);
      addPermissionsForSection(configs, id, ctx);
    },
    [ctx]
  );
};
