import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  colorsPrimary,
  tableHover,
} from '@kizen/page-builder/internal/app/colors';
import KizenTypography from '@kizen/page-builder/internal/app/kizentypo';

const typeBackgrounds = {
  content: '#EAF9EE', // primary green dark at 10% alpha
  row: '#FEF6E5', // primary orange dark at 10% alpha
  section: '#E6EFFD', // primary blue dark at 10% alpha
};

const textStyle = css`
  color: #4a5660;
  font-size: 14px;
  font-family: 'Proxima Nova', sans-serif;
`;

const StyledKizenTypography = styled(KizenTypography)`
  && {
    ${textStyle}

    u,
    strong {
      ${textStyle}
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  background-color: ${({ dropType }) =>
    typeBackgrounds[dropType] || tableHover};
  border: 1px dotted ${colorsPrimary.blue.light};
  margin: ${({ dropType }) => (dropType === 'content' ? '5px' : '10px')};
  > span {
    text-align: center;
    margin: 5px;
  }
`;

export default function EmptyNode({ dropType, children, ...others }) {
  return (
    <Wrapper dropType={dropType} {...others}>
      <StyledKizenTypography as="span">{children}</StyledKizenTypography>
    </Wrapper>
  );
}

export const EmptyOutline = styled.div`
  display: flex;
  flex-direction: column;
`;
