import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import { KizenTypography } from 'app/typography';
import { tableHover } from 'app/colors';
import SurveyService from 'services/SurveyService';
import Select from 'components/Inputs/Select';

const Wrapper = styled.div`
  width: 100%;

  .select {
    margin-bottom: ${gutters.spacing(4, -1)}px;
  }
`;

const TextBox = styled.div`
  width: 100%;
  background-color: ${tableHover};
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;

  margin-top: ${gutters.spacing(4, -2)}px;
  margin-bottom: ${gutters.spacing(4, -2)}px;

  > span {
    line-height: 15px;
  }
`;

const SendSurvey = ({ surveyValue, setSurveyValue }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const populateOptions = async () => {
      const res = await SurveyService.getList();
      setOptions(res.map((e) => ({ label: e.name, value: e.id })));
    };
    populateOptions();
  }, []);

  return (
    <Wrapper>
      <TextBox>
        <KizenTypography type="text" as="span">
          {t(
            'This will add the selected contacts to the respective Survey Request Email Sequence.'
          )}
        </KizenTypography>
      </TextBox>

      <Select
        className="select"
        fullWidth
        menuInline
        label={t('Choose Survey to Send')}
        options={options}
        value={surveyValue}
        onChange={(op) => setSurveyValue(op)}
        placeholder={t('Choose Survey')}
      />
    </Wrapper>
  );
};

SendSurvey.propTypes = {
  surveyValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  ]).isRequired,
  setSurveyValue: PropTypes.func.isRequired,
};
SendSurvey.defaultProps = {};

export default SendSurvey;
