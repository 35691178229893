import { searchClients } from '@kizen/api/client';
import {
  searchEntityRecords,
  searchPipelineEntityRecords,
} from '@kizen/api/custom-object';
import { convert } from '@kizen/filters/convert';
import {
  getFilterTypeStepDataFromSave,
  loadSavedFilter,
} from '@kizen/filters/load';
import { getContactVariables } from '@kizen/filters/utils';
import { useFilter } from '@kizen/filters/hooks/use-filter';
import type { Metadata, Next, Option } from '@kizen/filters/types';
import { useCallback, useEffect, useState } from 'react';
import { fetchUrlConfigWithAxios } from 'ts-filters/utils';

import { KizenTypography } from '__app/typography';
import Button from '__components/Button';
import BasicModal from '__components/Modals/presets/BasicModal';
import AxiosService from '__services/AxiosService';

import { FilterInputContainer } from './Filter';
import { FilterTypeSelector } from './FilterTypeSelector';
import { Date } from './inputs/Date';
import { DateTime } from './inputs/DateTime';
import { Decimal } from './inputs/Decimal';
import { Dropdown } from './inputs/Dropdown';
import { EmailAddress } from './inputs/EmailAddress';
import { GroupedDropdown } from './inputs/GroupedDropdown';
import { Integer } from './inputs/Integer';
import { LibraryMessage } from './inputs/LibraryMessage';
import { LongText } from './inputs/LongText';
import { MultiSelect } from './inputs/MultiSelect';
import { MultiSelectOne } from './inputs/MultiSelectOne';
import { PhoneNumber } from './inputs/PhoneNumber';
import { Price } from './inputs/Price';
import { TeamSelector } from './inputs/TeamSelector';
import { Text } from './inputs/Text';
import {
  ClickableText,
  Container,
  ControlsWrapper,
  ControlsContainer,
  CustomObjectIdInput,
  Divider,
  FilterContainer,
  FilterPayloadTextarea,
  LoadV1Filter,
  PrefixText,
  SaveControlsContainer,
  SavedItemContainer,
  SavedItems,
  SavedItemsContainer,
  SaveNameInput,
  SearchButton,
  StyledSwitch,
  XRemove,
} from './styled';
import { InputContainer } from './inputs/styled';

type DropdownPayload = {
  value: Option['value'] | Option['value'][];
  next?: Next[];
};
const LocalStoragePrefix = 'kzn_filters_testing__';

const saveLocal = (name: string, value: any) => {
  localStorage.setItem(`${LocalStoragePrefix}${name}`, JSON.stringify(value));
};

const getLocal = (name: string) => {
  return JSON.parse(localStorage.getItem(`${LocalStoragePrefix}${name}`)!);
};

const removeLocal = (name: string) => {
  localStorage.removeItem(`${LocalStoragePrefix}${name}`);
};

const getAllSavedNames = () => {
  return Object.keys(localStorage).reduce<string[]>((acc, x) => {
    if (x.startsWith(LocalStoragePrefix)) {
      acc.push(x.split(LocalStoragePrefix).pop()!);
    }
    return acc;
  }, []);
};

export const TestFixture = () => {
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [id, setId] = useState('7d7b69d3-a21e-4b24-9f80-c6777d73fbd1');
  const [isContact, setIsContact] = useState(true);
  const [isPipeline, setIsPipeline] = useState(false);
  const [isAutomation, setIsAutomation] = useState(false);
  const [type, setType] = useState<string | null>(null);
  const [saveName, setSaveName] = useState('');
  const [savedItems, setSavedItems] = useState(getAllSavedNames());
  const [steps, { build, create, next, save, set, toString, update }] =
    useFilter(id, isContact, isPipeline);
  const [modalOpen, setModalOpen] = useState(false);
  const [v1Payload, setV1Payload] = useState('');
  const [response, setResponse] = useState({});
  const objectType = isContact
    ? 'client_client'
    : isPipeline
      ? 'pipeline'
      : isAutomation
        ? 'automation'
        : 'standard';
  const { filter_type_step, filters } = metadata ?? {};

  useEffect(() => {
    const load = async () => {
      const meta = await AxiosService.get('/filters/metadata');
      setMetadata(meta.data.definition);
    };
    load();
  }, []);

  const handleDropdownChange = useCallback(
    (step: string, { value, next: n }: DropdownPayload) => {
      set(step, value);
      if (n) {
        const meta = typeof value === 'string' ? filters![value] : undefined;
        next(step, n, meta);
      }
      update();
    },
    [filters, set, next, update]
  );

  const handleValueChange = useCallback(
    (step: string, value: any) => {
      set(step, value);
      update();
    },
    [set, update]
  );

  const handleFilterChange = useCallback(
    ({ value }: { value: string }) => {
      if (value) {
        create(filters![value], metadata!);
        setType(value);
      }
    },
    [metadata, filters, create]
  );

  const handleSearch = useCallback(async () => {
    const filter = {
      and: null,
      field_ids: [],
      query: [
        {
          and: false,
          filters: [build()],
          id: 'query-0',
        },
      ],
    };
    const res = isContact
      ? await searchClients(AxiosService, { filter })
      : isPipeline
        ? await searchPipelineEntityRecords(AxiosService, id, { filter })
        : await searchEntityRecords(AxiosService, id, { filter });
    setResponse(res.data);
  }, [isContact, isPipeline, id, build]);

  const handleSave = () => {
    if (
      saveName?.length &&
      type &&
      steps.every(([, { value }]) => value !== null) // TODO: handle multiselect default values of [] (or change to null)
    ) {
      saveLocal(saveName, [...save()]);
      setSavedItems(getAllSavedNames());
      setSaveName('');
    }
  };

  const handleLoad = async (data: [string, any][]) => {
    const { filter_type, vars = null } = data[0][1];
    const entries: typeof data = [
      getFilterTypeStepDataFromSave(data[0]),
      ...data.slice(1),
    ];
    const init = await loadSavedFilter(
      fetchUrlConfigWithAxios,
      metadata!,
      entries,
      getContactVariables(id, isContact, isPipeline)
    );
    create(filters![filter_type], metadata!, { init, vars });
    setType(filter_type);
  };

  return (
    <Container>
      <BasicModal
        show={modalOpen}
        size="medium"
        heading="Enter full filter payload"
        onHide={() => setModalOpen(false)}
        onConfirm={() => {
          const payload = JSON.parse(v1Payload);
          handleLoad(
            convert(payload.query[0].filters[0], {
              object_type: objectType,
              custom_object_id: id,
              access: {},
            })
          );
          setModalOpen(false);
        }}
      >
        <FilterPayloadTextarea
          value={v1Payload}
          onChange={(ev: any) => setV1Payload(ev.target.value)}
        />
      </BasicModal>
      <ControlsWrapper>
        <ControlsContainer>
          <CustomObjectIdInput
            value={id}
            label="Custom Object Id"
            onChange={setId}
          />
          <StyledSwitch
            label="Contact"
            checked={isContact}
            onChange={(ev: any) => {
              setIsContact(ev.target.checked);
              if (ev.target.checked) {
                setIsPipeline(false);
                setIsAutomation(false);
              }
            }}
          />
          <StyledSwitch
            label="Pipeline"
            checked={isPipeline}
            onChange={(ev: any) => {
              setIsPipeline(ev.target.checked);
              if (ev.target.checked) {
                setIsContact(false);
                setIsAutomation(false);
              }
            }}
          />
          <StyledSwitch
            label="Automation"
            checked={isAutomation}
            onChange={(ev: any) => {
              setIsAutomation(ev.target.checked);
              if (ev.target.checked) {
                setIsPipeline(false);
                setIsContact(false);
              }
            }}
          />
          <SearchButton onClick={handleSearch}>Search</SearchButton>
        </ControlsContainer>
        <LoadV1Filter
          type="anchor"
          size="18px"
          onClick={() => setModalOpen(true)}
        >
          Load v1 Filter
        </LoadV1Filter>
        <SaveControlsContainer>
          <SaveNameInput
            placeholder="Filter Name"
            value={saveName}
            onChange={setSaveName}
          />
          <Button noSpace onClick={handleSave}>
            Save
          </Button>
        </SaveControlsContainer>
      </ControlsWrapper>
      <SavedItemsContainer>
        <SavedItems>
          {savedItems.map((name) => (
            <SavedItemContainer key={name}>
              <ClickableText
                key={name}
                type="anchor"
                onClick={() => handleLoad(getLocal(name))}
              >
                {name}
              </ClickableText>
              <XRemove
                onClick={() => {
                  removeLocal(name);
                  setSavedItems(getAllSavedNames());
                }}
              >
                X
              </XRemove>
            </SavedItemContainer>
          ))}
        </SavedItems>
      </SavedItemsContainer>
      <Divider />
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
        <KizenTypography as="span" weight="bold">
          Description:
        </KizenTypography>
        <KizenTypography as="span">{toString()}</KizenTypography>
      </div>
      <FilterContainer>
        <FilterTypeSelector
          value={type}
          objectType={objectType}
          placeholder={filter_type_step?.placeholder}
          options={filter_type_step?.options ?? []}
          width={filter_type_step?.width ?? 200}
          onChange={handleFilterChange}
        />
        {steps.map(([step, { value, ...config }], idx, arr) => {
          const { input_type, width, prefix, suffix, group_url } = config;
          const key = arr
            .slice(0, idx)
            .map(([s, { value: v }]) => `${s}_${v}`)
            .join('')
            .concat(`_${step}`);

          return (
            <FilterInputContainer key={key}>
              {prefix && (
                <PrefixText>
                  <KizenTypography>{prefix}</KizenTypography>
                </PrefixText>
              )}
              <InputContainer width={width}>
                {input_type === 'choices' && Boolean(group_url) ? (
                  <GroupedDropdown
                    {...config}
                    value={value}
                    group_url={group_url}
                    step={step}
                    onChange={handleDropdownChange}
                  />
                ) : input_type === 'choices' ? (
                  <Dropdown
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleDropdownChange}
                  />
                ) : input_type === 'date' ? (
                  <Date
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'datetime' ? (
                  <DateTime
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'decimal' ? (
                  <Decimal
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'dropdown' || input_type === 'timezone' ? (
                  <Dropdown
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleDropdownChange}
                  />
                ) : input_type === 'email' ? (
                  <EmailAddress
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'integer' ? (
                  <Integer
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'library_message' ? (
                  <LibraryMessage
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'longtext' ? (
                  <LongText
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'multiselect' ? (
                  <MultiSelect
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleDropdownChange}
                  />
                ) : input_type === 'multiselect_one' ? (
                  <MultiSelectOne
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleDropdownChange}
                  />
                ) : input_type === 'money' ? (
                  <Price
                    {...config}
                    currencySymbol={(config as any).currency_symbol}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'phonenumber' ? (
                  <PhoneNumber
                    {...config}
                    enableExtension={(config as any).enable_extension}
                    defaultCountry={(config as any).default_country}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : input_type === 'status' ? (
                  <Dropdown
                    {...config}
                    value={value}
                    step={step}
                    isStatus={true}
                    onChange={handleDropdownChange}
                  />
                ) : input_type === 'team_selector' ? (
                  <TeamSelector
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                ) : (
                  <Text
                    {...config}
                    value={value}
                    step={step}
                    onChange={handleValueChange}
                  />
                )}
              </InputContainer>
              {suffix && (
                <PrefixText>
                  <KizenTypography>{suffix}</KizenTypography>
                </PrefixText>
              )}
            </FilterInputContainer>
          );
        })}
      </FilterContainer>
      <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
          <KizenTypography>Payload</KizenTypography>
          <textarea
            style={{ fontSize: '16px', height: '500px', marginTop: '5px' }}
            value={JSON.stringify(build(), null, '\t')}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
          <KizenTypography>Search Response</KizenTypography>
          <textarea
            style={{ fontSize: '16px', height: '500px', marginTop: '5px' }}
            value={JSON.stringify(response, null, '\t')}
          />
        </div>
      </div>
      <div style={{ marginTop: '40px' }}>
        <h4>Step Data</h4>
        <textarea
          value={JSON.stringify(
            steps.map(([k, { value }]) => [k, value]),
            null,
            '\t'
          )}
          style={{ fontSize: '16px', width: '100%', height: '300px' }}
        />
      </div>
      <div style={{ marginTop: '40px' }}>
        <h4>Step Data (full config)</h4>
        <textarea
          value={JSON.stringify(steps, null, '\t')}
          style={{ fontSize: '16px', width: '100%', height: '300px' }}
        />
      </div>
      <div style={{ marginTop: '40px' }}>
        <h4>Save Result</h4>
        <textarea
          value={type ? JSON.stringify(Array.from(save()), null, '\t') : ''}
          style={{ fontSize: '16px', width: '100%', height: '300px' }}
        />
      </div>
    </Container>
  );
};
