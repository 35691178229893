export const LOG_ACTIVITY_SUCCESS = (t) =>
  t('This activity has been successfully logged.');

export const FAILED_COMPLETE_ACTIVITY = (t) =>
  t('Failed to complete activity, please try again');

export const SCHEDULE_ACTIVITY_SUCCESS = (t) =>
  t('This activity has been successfully scheduled.');

export const SCHEDULE_ACTIVITY_FAIL = (t) =>
  t('This activity could not be scheduled.');

export const SCHEDULE_ACTIVITY_LOAD_FAIL = (t) =>
  t(
    'The scheduled activities could not be loaded. Please try again or contact Kizen support.'
  );
