import React from 'react';
import * as PropTypes from 'prop-types';
import {
  StyledLabel,
  StyledInput,
  StyledInputFacade,
  StyledText,
} from './styles';
import { useTruncationTooltip } from '../Tooltip';

const RadioButton = ({
  hover,
  error,
  disabled,
  checked,
  onChange,
  value,
  label,
  name,
  className,
  size,
}) => {
  const [tooltipProps, tooltip] = useTruncationTooltip();
  return (
    <StyledLabel size={size} className={className}>
      <StyledInput
        type="radio"
        error={error}
        disabled={disabled}
        checked={checked}
        onChange={() => onChange(value)}
        value={value}
        name={name}
        size={size}
      />
      <StyledInputFacade size={size} error={error} />
      {tooltip}
      <StyledText disabled={disabled} error={error} {...tooltipProps}>
        {label}
      </StyledText>
    </StyledLabel>
  );
};

RadioButton.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
};

RadioButton.defaultProps = {
  error: false,
  disabled: false,
  checked: false,
  className: '',
  size: 'medium',
};

export default RadioButton;
