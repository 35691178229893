import ChartManager from '../ChartManager';
import * as am5percent from '@amcharts/amcharts5/percent';
import * as am5 from '@amcharts/amcharts5';
import { colorsPrimary, colorsSecondary, grayScale } from 'app/colors';
import { ensureCents, formatFullDecimal } from 'utility/numbers';

export const colors = [
  colorsPrimary.green,
  colorsSecondary.aqua,
  colorsPrimary.blue,
  colorsPrimary.orange,
  colorsPrimary.purple,
  colorsSecondary.tangerine,
  colorsSecondary.red,
  colorsSecondary.magenta,
  colorsSecondary.brown,
  { light: grayScale.medium },
];

class DonutChartManager extends ChartManager {
  constructor(
    element,
    t,
    data = [],
    {
      mobile = false,
      computedColors,
      isValue,
      currencySymbol,
      toggleable = true,
      small = false,
      total = 0,
      getLabel,
      wide = false,
      forceDecimal = false,
      wideSlices = false,
      strokeWidth = 2,
    },
    rootConfig,
    ready
  ) {
    super(
      element,
      t,
      am5percent.PieChart,
      {
        radius: am5.percent(90),
        innerRadius: wideSlices
          ? am5.percent(small ? 50 : 60)
          : am5.percent(small ? 75 : 80),
      },
      () => {},
      rootConfig,
      ready ?? (() => {})
    );

    this.data = data.slice(0, colors.length);
    this.mobile = mobile;
    this.computedColors = computedColors;
    this.isValue = isValue;
    this.currencySymbol = currencySymbol;
    this.toggleable = toggleable;
    this.small = small;
    this.total = total;
    this.getLabel = getLabel;
    this.wide = wide;
    this.forceDecimal = forceDecimal;
    this.wideSlices = wideSlices;
    this.strokeWidth = strokeWidth;
  }

  init = () => {
    this.createSeries();
    this.setData(this.data);

    this.series.appear();
    this.chart.appear();
  };

  configureEmptyState = (data = []) => {
    if (data) {
      const isZeros = data.every((record) => record.value === 0);
      if (isZeros) {
        this.element.setAttribute('data-qa-dashlet-empty', 'true');
      } else {
        this.element.setAttribute('data-qa-dashlet-empty', 'false');
      }
    }
  };

  setData = (data = []) => {
    let visibleCount = 0;
    this.series.data.setAll(
      data.map((d) => {
        const isVisible = d.value !== 0;
        visibleCount += +isVisible;
        return {
          ...d,
          sliceSettings: {
            visible: isVisible,
          },
        };
      })
    );
    this.visibleCount = visibleCount;
    if (this.visibleCount > 1 && this.toggleable) {
      this.series.slices.template.set('toggleKey', undefined);
    } else {
      this.series.slices.template.set('toggleKey', 'none');
    }

    this.configureEmptyState(data);
  };

  createSeries = () => {
    this.assertChart('series');

    const tooltip = this.createStyledTooltip(
      undefined,
      {},
      {
        maxWidth: this.wide ? 300 : 200,
        oversizedBehavior: 'wrap',
      }
    );

    // We need more complex formatting of the tooltip than amcharts provides, so have a
    // custom adapter for the text
    tooltip.label.adapters.add('text', (text, target) => {
      const category =
        this.getLabel?.(target?.dataItem?.dataContext) ??
        target?.dataItem?.dataContext?.category.toUpperCase() ??
        '';
      const value = target?.dataItem?.dataContext?.value ?? 0;
      const percent = this.total === 0 ? 0 : (value / this.total) * 100;
      const stringValue = value.toLocaleString();
      const formattedStringValue = this.isValue
        ? ensureCents(stringValue)
        : stringValue;
      const result = `${category}: ${this.isValue ? this.currencySymbol : ''}${
        this.forceDecimal ? formatFullDecimal(value) : formattedStringValue
      } (${percent.toFixed(percent < 1 && percent >= 0.1 ? 1 : 0)}%)`;

      return result;
    });

    this.series = this.chart.series.push(
      am5percent.PieSeries.new(this.root, {
        name: 'Series',
        valueField: 'value',
        categoryField: 'category',
        tooltip,
      })
    );

    this.series.labels.template.set('visible', false);
    this.series.ticks.template.set('visible', false);

    this.series.get('colors').set(
      'colors',
      (this.computedColors ?? colors).map((color) => color.light)
    );

    this.series.slices.template.setAll({
      templateField: 'sliceSettings',
      stroke: this.strokeWidth ? am5.color(0xffffff) : undefined,
      strokeWidth: this.strokeWidth ? this.strokeWidth : undefined,
    });

    this.series.slices.template.states.create('active', {
      shiftRadius: 10,
    });
  };
}

export default DonutChartManager;
