import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../../../components/Inputs/Select';
import ClearSelectButton from '../../Inputs/Select/ClearButton';
import ApplySelectButton from '../../Inputs/Select/ApplyButton';
import { FIELD_TYPES } from '../../../utility/constants';
import { getIdentifierForOption } from '../helpers';

const Dropdown = React.forwardRef(
  ({ field, onChange, menuLeftButton, ...others }, ref) => {
    const { t } = useTranslation();
    const { fieldType } = field;

    const options = useMemo(() => {
      return field.options.map((opt) => ({
        value: getIdentifierForOption(opt, field),
        label: opt.name,
        color: opt.meta && opt.meta.color,
      }));
    }, [field]);

    const handleChange = useCallback(
      (opt) => onChange(opt ? opt.value : null),
      [onChange]
    );

    return (
      <Select
        ref={ref}
        placeholder={
          fieldType === FIELD_TYPES.Status.type
            ? FIELD_TYPES.Status.localizedPlaceholder(t)
            : FIELD_TYPES.Dropdown.localizedPlaceholder(t)
        }
        options={options}
        isColored={fieldType === FIELD_TYPES.Status.type}
        onChange={handleChange}
        menuLeftButton={menuLeftButton}
        menuRightButton={<ApplySelectButton />}
        {...others}
      />
    );
  }
);

Dropdown.defaultProps = {
  menuLeftButton: <ClearSelectButton />,
};
export default Dropdown;
