import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';

export const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${({ color }) => `${color}`};
  display: flex;
  cursor: ${({ isHoverable }) => (isHoverable ? 'pointer' : 'auto')};
`;

export const CenteredIcon = styled(Icon)`
  margin: auto;
`;
