import AxiosService from './AxiosService';
import {
  snakeToCamelCaseKeys,
  camelToSnakeCaseKeys,
  FORCE_ALL_RECORDS_SIZE,
} from './helpers';

const getOptionsList = (element) => {
  return {
    label: element.value_display || element.label,
    value: element.value,
  };
};

class LeadSourceServiceV2 {
  constructor() {
    if (!LeadSourceServiceV2.instance) {
      LeadSourceServiceV2.instance = this;
    }
    return LeadSourceServiceV2.instance;
  }

  endpoint = '/lead-sources';

  /*
    "type": "google_ads" | "direct" | "organic_search" | "site_referral" | "google_ads" | "facebook_ads" | "utm" | "custom" | "social" | "paid_social",
    "entityRecordId": <record_uuid>,
    "referralUrl"?: <URL_String>
    "source"?: <user provided string>,
    "campaign"?: <user provided string>,
    "medium"?: <user provided string>,
    "term"?: <user provided string>,
    "content"?: <user provided string>
  * */
  create = async (leadSource, options = { skipErrorBoundary: true }) => {
    const { data } = await AxiosService.post(
      `${this.endpoint}`,
      camelToSnakeCaseKeys(leadSource),
      options
    );
    return snakeToCamelCaseKeys(data);
  };

  getAll = async (recordId, params = {}) => {
    const { data } = await AxiosService.get(`${this.endpoint}/${recordId}`, {
      params: {
        page_size: FORCE_ALL_RECORDS_SIZE, // Despite the endpoint being paginated, we'd like to fetch all automations at once
        ...camelToSnakeCaseKeys(params),
      },
    });
    return snakeToCamelCaseKeys(data);
  };

  filterSearchOptions = async (fieldName, search, leadSource, extra = []) => {
    const size = 10000;
    const requestBody = {
      ...leadSource,
      search: {
        [fieldName]: search,
      },
    };
    const { data } = await AxiosService.post(
      `${this.endpoint}/typeahead-search?size=${size}`,
      camelToSnakeCaseKeys(requestBody)
    );

    return [...extra, ...data.results].map(getOptionsList);
  };

  searchOptions = async (fieldName, search, leadSource, size = 20) => {
    const requestBody = {
      ...leadSource,
      search: {
        [fieldName]: search,
      },
    };
    const { data } = await AxiosService.post(
      `${this.endpoint}/typeahead-search?size=${size}`,
      camelToSnakeCaseKeys(requestBody)
    );
    return data.results.map(snakeToCamelCaseKeys);
  };

  searchOptionsTypeahead = async (
    fieldName,
    search,
    page = 1,
    pageSize = 20,
    leadSource
  ) => {
    const requestBody = {
      ...leadSource,
      search: {
        [fieldName]: search,
      },
    };
    const { data } = await AxiosService.post(
      `${this.endpoint}/typeahead-search?page_size=${pageSize}&page=${page}`,
      camelToSnakeCaseKeys(requestBody)
    );

    return snakeToCamelCaseKeys(data);
  };

  combinedSearch = async (term = '') => {
    const { data } = await AxiosService.get(
      `${this.endpoint}/combined-typeahead-search?search=${encodeURIComponent(
        term
      )}`
    );

    return data;
  };
}

const instance = new LeadSourceServiceV2();
Object.freeze(instance);

export default instance;
