import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import InlineOwnerDropdown from 'components/Sharing/InlineOwnerDropdown';
import { canChangeOwner } from 'pages/Account/pages/Toolbar/utils';
import { useMemo, useRef } from 'react';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';

export const StyledText = styled(TextEllipsisWithTooltip)`
  width: calc(100% - 6px);
`;

export const StyledLink = styled(KizenTypography)`
  font-size: 1em;
`;

const OwnerCell = ({ value, data, onChange, items }) => {
  const selectRef = useRef();
  const editable = canChangeOwner(data);

  const [selectProps] = useSelectTypeaheadWithScroll({
    selectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
  });

  const openMenuAbove = useMemo(() => {
    const count = items?.results?.length ?? 0;
    const index = items?.results?.findIndex((item) => item.id === data.id);
    return Math.max(10, count) - index <= 8 && index > 5;
  }, [items, data.id]);

  if (value.static) {
    return <KizenTypography>{value.display_name}</KizenTypography>;
  }

  return (
    <div style={{ position: 'relative', maxWidth: '100%' }}>
      <InlineOwnerDropdown
        ref={selectRef}
        value={{ label: value.display_name, id: value.id }}
        onChange={onChange}
        editable={editable}
        portal
        menuPlacement={openMenuAbove ? 'top' : undefined}
        {...selectProps}
      />
    </div>
  );
};

export default OwnerCell;
