const optionMapper = ({ value }) => value;

export const buildSharingSettingsForAPI = (permissions) => {
  if (!permissions) {
    return {};
  }

  const result = {
    sharing_settings: {
      all_team_members: permissions.allTeamMembersPermission,
      roles: {
        view: permissions.viewRoles.map(optionMapper),
        edit: permissions.editRoles.map(optionMapper),
        admin: permissions.adminRoles.map(optionMapper),
      },
      team_members: {
        view: permissions.viewTeamMembers.map(optionMapper),
        edit: permissions.editTeamMembers.map(optionMapper),
        admin: permissions.adminTeamMembers.map(optionMapper),
      },
    },
  };

  return result;
};
