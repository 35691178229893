import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { gutters } from 'app/spacing';
import { fontSizes } from 'app/typography';
import { colorsButton, grayScale, colorsPrimary } from 'app/colors';
import BaseCard, { TitleWrapper, DragIcon } from 'components/Board/BaseCard';
import { IconSizing } from 'components/Kizen/Icon';
import Icon from 'components/Kizen/Icon';

const Title = styled.div`
  height: 5px;
  ${({ width }) => css`
    width: ${width}px;
  `}
  background: ${colorsButton.blue.default};
  margin-top: ${gutters.spacing(1, -2)}px;
  margin-right: ${gutters.spacing(2)}px;
`;

export const StyledIconWrapper = styled(IconSizing)`
  &:hover {
    color: ${grayScale.mediumDark};
  }
`;

const InputBlock = styled.div`
  height: 5px;
  background: ${grayScale.mediumDark};
  margin-bottom: ${gutters.spacing(4)}px;
  &:last-of-type {
    margin-bottom: ${gutters.spacing(1, 1)}px;
  }
  ${({ width }) => css`
    width: ${width}px;
  `}
`;

const BodyWrapper = styled.div`
  padding-top: ${gutters.spacing(3)}px;
  overflow: hidden;
`;

const DateWrapper = styled.div`
  width: 50px;
  background: ${grayScale.mediumLight};
  margin-right: ${gutters.spacing(2)}px;
  border-radius: 10px;
  padding: ${gutters.spacing(1)}px;

  p {
    text-align: center;
  }
`;

const DateBlock = styled.div`
  height: 5px;
  background: ${grayScale.mediumDark};
`;

const TimeBlock = styled.div`
  width: 25px;
  height: 5px;
  margin: ${gutters.spacing(1)}px 0;
  background: ${grayScale.medium};
`;

export const BubbleWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 15px;
  height: 15px;
  background: ${colorsPrimary.blue.light};
  color: ${grayScale.white};
  font-size: ${fontSizes.tiny};
  border-radius: 50%;
  padding-left: ${gutters.spacing(1, -4)}px;
`;

const inputWidth = [204, 150, 190, 128, 197];

const FakeCard = ({
  showESTDate,
  showTime,
  showOwner,
  showTitle,
  fieldsAmount,
  hide,
  ...props
}) => (
  <BaseCard
    title={
      <>
        <TitleWrapper>
          <StyledIconWrapper size="15px">
            <DragIcon icon="reorder" />
          </StyledIconWrapper>
          {showTitle && <Title width={125} />}
        </TitleWrapper>
        <StyledIconWrapper size="15px">
          <Icon icon="three-dot" />
        </StyledIconWrapper>
      </>
    }
    footer={
      (showESTDate || showTime || showOwner) && (
        <>
          <div>
            {showESTDate && (
              <DateWrapper>
                <DateBlock />
              </DateWrapper>
            )}
            {showTime && <TimeBlock />}
          </div>
          {showOwner && <BubbleWrapper>AA</BubbleWrapper>}
        </>
      )
    }
    {...props}
  >
    {fieldsAmount !== 0 && (
      <BodyWrapper>
        {[...Array(fieldsAmount)].map((item, i) => (
          <InputBlock key={i} width={hide ? 0 : inputWidth[i]} />
        ))}
      </BodyWrapper>
    )}
  </BaseCard>
);

FakeCard.propTypes = {
  showESTDate: PropTypes.bool.isRequired,
  showTime: PropTypes.bool.isRequired,
  showOwner: PropTypes.bool.isRequired,
  fieldsAmount: PropTypes.number.isRequired,
  showTitle: PropTypes.bool,
};

FakeCard.defaultProps = {
  showTitle: true,
};

export default FakeCard;
