import { format } from 'date-fns';
import { isPipelineObject } from 'components/Modals/utilities';
import { preventOverflow } from 'components/Inputs/inline/helpers';
import { popperModifiers } from 'components/helpers';
import { defaultsDeep } from 'lodash';
import { layers } from 'app/spacing';
import { STAGECACHE_SET_FIELDS } from './constants';

export const LEAD_STATUS = {
  OPEN: 'open',
  WON: 'won',
  LOST: 'lost',
  DISQUALIFIED: 'disqualified',
};

export const entityUp = ({
  objectId,
  name,
  relationField,
  stage,
  entityValue,
  estimatedCloseDate,
}) => ({
  objectId,
  relationField: relationField.field,
  name,
  stageId: stage.value || stage,
  ...(entityValue && { entityValue }),
  ...(estimatedCloseDate && {
    estimatedCloseDate: format(estimatedCloseDate, 'YYYY-MM-DD'),
  }),
});

export const getPipelineOptions = (
  relatedObjects = [],
  customObjectEntities = {},
  addId = false
) => {
  const dictionary = relatedObjects
    .filter(
      (obj) =>
        isPipelineObject(obj) &&
        customObjectEntities[obj.relatedObject]?.canCreate === true
    )
    .reduce((acc, { objectName, relatedObject }) => {
      if (!acc[relatedObject]) {
        acc[relatedObject] = {
          label: objectName,
          value: relatedObject,
          ...(addId && { id: relatedObject }),
        };
      }
      return acc;
    }, {});

  return Object.values(dictionary);
};

export const searchPopperConfig = {
  modifiers: [
    preventOverflow,
    popperModifiers.addStyles({ zIndex: layers.content(0, 3) }),
  ],
};

export const stagesSelectPopperConfig = defaultsDeep({}, searchPopperConfig, {
  modifiers: [
    popperModifiers.offSet(-70, -1), // Align directly under the checron
  ],
});

export const stageCacheReducer = (stageCache, action) => {
  const { id, stages } = action;
  switch (action.type) {
    case STAGECACHE_SET_FIELDS:
      return { ...stageCache.current, [id]: { stages, timeStamp: Date.now() } };

    default:
      throw new Error();
  }
};

const isNewFormat = (rp) => rp.stages === undefined;

const hundredPercentStages = [LEAD_STATUS.LOST, LEAD_STATUS.DISQUALIFIED];

export const oldCalcPercentageToDisplay = (rp) => {
  const percentageToDisplay = hundredPercentStages.includes(rp.stage.status)
    ? 100
    : rp.stage.percentageChanceToClose;
  return percentageToDisplay;
};

export const newCalcPercentageToDisplay = (rp) => {
  if (hundredPercentStages.includes(rp.stage.status)) {
    return 100;
  }

  const stageOption = rp.stageField.options.find(
    ({ id }) => id === rp.stage.id
  );
  return (
    stageOption?.percentageChanceToClose || rp.stage.percentageChanceToClose
  );
};

export const calcPercentageToDisplay = (rp) =>
  isNewFormat(rp)
    ? newCalcPercentageToDisplay(rp)
    : oldCalcPercentageToDisplay(rp);

export const setPipeline = (rp) => {
  if (!rp.includePercentageToClose) {
    // eek 5591
    // stages were removed in KZN-5845 and replaced by stage_field options - but that field does not contain the status so this can't work
    // this check is temporay to stop the page crashing
    if (rp.stages) {
      const filtered = rp.stages.filter(({ status }) => status === 'open');
      const percentage = filtered.length ? 100 / filtered.length : 0;
      let current = 0.0;
      rp.stages = rp.stages.map((stg) => {
        if (stg.status === 'open') {
          stg.percentageChanceToClose = current;
          current += percentage;
        }
        return stg;
      });
    }
  }

  rp.displayPercentage = oldCalcPercentageToDisplay(rp);
};

const getPercentage = (stages) => {
  const filtered = stages.filter(({ status }) => status === 'open');
  return filtered.length ? 100 / filtered.length : 0;
};

const updateOptions = (options) => {
  const percentage = getPercentage(options);
  let current = 0.0;
  return options.map((stg) => {
    if (stg.status === 'open') {
      const r = {
        ...stg,
        percentageChanceToClose: current,
        chanceToClosePercentage: current,
      };
      current += percentage;
      return r;
    }
    return stg;
  });
};

export const addDisplayPercentage = (rp) => {
  const isNew = isNewFormat(rp);
  if (!rp.includePercentageToClose && !isNew) {
    // eek 5591
    // stages were removed in KZN-5845 and replaced by stage_field options - but that field does not contain the status so this can't work
    // this check is temporay to stop the page crashing

    const options = isNew ? rp?.stageField?.options : rp?.stages;

    if (options) {
      const updatedOptions = updateOptions(options);
      return {
        ...rp,
        stages: updatedOptions,
        displayPercentage: oldCalcPercentageToDisplay(rp),
      };
    }
  }

  return {
    ...rp,
    displayPercentage: isNew
      ? newCalcPercentageToDisplay(rp)
      : oldCalcPercentageToDisplay(rp),
  };
};
