import useHeightMeasurement from './useHeightMeasurement';

const useExpandingVerticalScrollbar = (opts = {}) => {
  const { ref, height } = useHeightMeasurement();
  const { headBorder = false, isTable = true, shadow = true } = opts;

  return {
    headProps: {
      ref,
    },
    containerProps: {
      headHeight: height,
      useExpandingScrollbar: true,
      headBorder,
      isTable,
      shadow,
    },
  };
};

export default useExpandingVerticalScrollbar;
