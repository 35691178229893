import { FIELD_TYPES } from 'utility/constants';
import { displayNameToOption } from 'services/helpers';

export const getEditorOneLineContext = (editor) => {
  const html = editor.getHTML();
  const parser = new DOMParser();
  const document = parser.parseFromString(html, 'text/html');
  const paragrafs = document.querySelectorAll('p');
  const textContents = [];
  paragrafs.forEach((p) => {
    textContents.push(p.innerHTML);
  });
  const selection = editor.view.state.selection.from;
  const content = `<p>${textContents.join('')}</p>`;
  editor.commands.setContent(content, false, {
    preserveWhitespace: true,
  });
  editor.commands.setTextSelection(
    selection - (paragrafs.length > 1 ? paragrafs.length : 0)
  );
  return content;
};

export const checkIfPipelineObjectFieldSelected = (field) =>
  field?.relation?.fetchUrl === 'pipeline';

export const checkIfCustomObjectFieldSelected = (field) =>
  field?.relation?.fetchUrl === 'standard' ||
  field?.relation?.fetchUrl === 'pipeline';

export const getRelationshipFieldsContext = (
  automationContextFields,
  selectedFieldId
) => {
  const relationshipFields = automationContextFields
    .filter(
      (field) =>
        field.fieldType === FIELD_TYPES.Relationship.type &&
        checkIfCustomObjectFieldSelected(field)
    )
    .map((field) => ({ ...displayNameToOption(field), field }));
  const selected =
    relationshipFields.find(({ value }) => value === selectedFieldId) || {};
  return {
    relationshipFields,
    selectedEntityModelId: selected.field?.relation?.relatedObject || null,
    relatedObjectName: selected.field?.relation?.relatedObjectName,
    isCustomObjectFieldSelected: checkIfCustomObjectFieldSelected(
      selected.field
    ),
    isPiplineObjectFieldSelected: checkIfPipelineObjectFieldSelected(
      selected.field
    ),
  };
};

const allowedFieldTypes = [
  FIELD_TYPES.Date,
  FIELD_TYPES.DateTime,
  FIELD_TYPES.Dropdown,
  FIELD_TYPES.Email,
  FIELD_TYPES.Number,
  FIELD_TYPES.Decimal,
  FIELD_TYPES.Integer,
  FIELD_TYPES.Price,
  FIELD_TYPES.Money,
  FIELD_TYPES.Radio,
  FIELD_TYPES.Rating,
  FIELD_TYPES.Status,
  FIELD_TYPES.TeamSelector,
  FIELD_TYPES.Text,
  FIELD_TYPES.LongText,
  FIELD_TYPES.YesNo,
  FIELD_TYPES.YesNoMaybe,
];

export const filterFields = ({ fieldType }) => {
  return allowedFieldTypes.some(({ type }) => type === fieldType);
};
