import { useSelector } from 'react-redux';

const useManageSubscriptionLevel = ({
  objectId,
  isClient,
  level,
}: {
  objectId?: string;
  isClient?: boolean;
  level: 'edit' | 'view';
}) => {
  const access = useSelector((s: any) => s.authentication.access);

  const canManageContactSubscriptions = Boolean(
    access?.contacts?.single_record_actions?.subscription_lists?.[level]
  );

  const canManageCustomObjectSubscriptions = Boolean(
    objectId &&
      access?.custom_objects?.custom_object_entities?.[objectId]
        ?.single_record_actions?.subscription_lists?.[level]
  );

  const hasManageSubscriptionsPermission = isClient
    ? canManageContactSubscriptions
    : canManageCustomObjectSubscriptions;

  return hasManageSubscriptionsPermission;
};

export const useCanManageSubscriptions = ({
  entityObject,
  objectId,
  isClient,
}: {
  entityObject?: { access: { view: boolean; edit: boolean; remove: boolean } };
  objectId?: string;
  isClient?: boolean;
}) => {
  const hasEditAccessToEntity = Boolean(entityObject?.access?.edit);

  const hasManageSubscriptionsPermission = useManageSubscriptionLevel({
    objectId,
    isClient,
    level: 'edit',
  });

  return hasManageSubscriptionsPermission && hasEditAccessToEntity;
};

export const useCanViewSubscriptions = ({
  objectId,
  isClient,
}: {
  objectId?: string;
  isClient?: boolean;
}) => {
  const hasViewSubscriptionsPermission = useManageSubscriptionLevel({
    objectId,
    isClient,
    level: 'view',
  });

  return hasViewSubscriptionsPermission;
};
