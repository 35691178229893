/* eslint-disable react/display-name */
import FieldService, { getObjectUrl } from 'services/FieldService';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { CustomFieldDataCell } from './CustomFieldCell';
import { CustomFieldHeadCell } from './../CustomFieldHeadCell';
import { SizedCell, SortableHeadCell } from 'components/Kizen/Table';
import { SpacerCell } from 'pages/Common/components/BigTableCells';

import {
  getCellForFieldType,
  EmailCell,
  PhoneNumberCell,
  DynamicTagsCell,
  DateTimeCell,
} from 'components/Layout/BigTableLayout/InlineCells';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import { DEFAULT_WIDTH_IN_PIXELS, PADDING_IN_PIXELS } from '../constants';

const defaultFieldNameExceptions = {
  fullName: DEFAULT_FIELD_NAMES.firstName,
  mobilePhone: DEFAULT_FIELD_NAMES.mobilePhone,
};

export const getDefaultField = (name, fields) => {
  name = defaultFieldNameExceptions[name] || name;
  return fields.find((f) => f.isDefault && f.name === name);
};

export const getEditable = ({ access } = {}) => Boolean(access && access.edit);

export const getBasicColumns = ({
  onSubmitContact,
  widthsLookup = {},
  fields,
  serviceToUse,
  model,
  handleUpdateFieldOption,
  handleUpdateTableRecords,
  columnSettings,
}) => {
  const firstNameField = getDefaultField(DEFAULT_FIELD_NAMES.firstName, fields);
  const lastNameField = getDefaultField(DEFAULT_FIELD_NAMES.lastName, fields);
  const emailField = getDefaultField(DEFAULT_FIELD_NAMES.email, fields);
  const mobilePhoneField = getDefaultField(
    DEFAULT_FIELD_NAMES.mobilePhone,
    fields
  );
  const titlesField = getDefaultField(DEFAULT_FIELD_NAMES.titles, fields);
  const tagsField = getDefaultField(DEFAULT_FIELD_NAMES.tags, fields);
  const createdField = getDefaultField(DEFAULT_FIELD_NAMES.created, fields);
  const updatedField = getDefaultField(DEFAULT_FIELD_NAMES.updated, fields);
  const { enableLink: fullNameEnableLink = true } =
    columnSettings?.find((column) => column.id === 'fullName') || {};
  const { enableLink: tagsEnableLink = true } =
    columnSettings?.find((column) => column.id === DEFAULT_FIELD_NAMES.tags) ||
    {};
  const { enableLink: titlesEnableLink = true } =
    columnSettings?.find(
      (column) => column.id === DEFAULT_FIELD_NAMES.titles
    ) || {};

  const defaultCellProps = {
    onSubmit: onSubmitContact,
    serviceToUse,
    model,
    handleUpdateFieldOption,
    handleUpdateTableRecords,
    isCustomObjects: false,
  };

  return {
    fullName: {
      cell: (
        <SizedCell
          minWidth={widthsLookup['fullName'] || DEFAULT_WIDTH_IN_PIXELS}
          padding={PADDING_IN_PIXELS}
        />
      ),
      headCell: (
        <SortableHeadCell
          by="full_name"
          minWidth={widthsLookup['fullName'] || DEFAULT_WIDTH_IN_PIXELS}
          padding={PADDING_IN_PIXELS}
        />
      ),
      meta: { editable: false },
      format: (_, contact) => {
        const first = FieldService.getFieldValue(contact, firstNameField);
        const last = FieldService.getFieldValue(contact, lastNameField);
        const fullName = [first, last].filter(Boolean).join(' ');
        const { id } = contact;

        return (
          <TextEllipsisWithTooltip
            type={fullNameEnableLink ? 'link' : 'text'}
            target="_blank"
            to={id && `/client/${id}/details`}
          >
            {fullName}
          </TextEllipsisWithTooltip>
        );
      },
    },
    ...(emailField
      ? {
          [DEFAULT_FIELD_NAMES.email]: {
            headCell: (
              <SortableHeadCell
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.email] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
                padding={PADDING_IN_PIXELS}
              />
            ),
            meta: { editable: false },
            bodyCell: {
              ...defaultCellProps,
              field: emailField,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.email] ||
                DEFAULT_WIDTH_IN_PIXELS,
              padding: PADDING_IN_PIXELS,
              Component: EmailCell,
              meta: { editable: false },
              forceReadOnly: true,
              editable: false,
              disabled: true,
              ...getDataQAForInput(
                `inline-contact-${DEFAULT_FIELD_NAMES.email}`,
                emailField?.fieldType
              ),
            },
          },
        }
      : {}),
    ...(mobilePhoneField
      ? {
          [DEFAULT_FIELD_NAMES.mobilePhone]: {
            headCell: (
              <SortableHeadCell
                numeric
                by={DEFAULT_FIELD_NAMES.mobilePhone}
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.mobilePhone] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
                padding={PADDING_IN_PIXELS}
              />
            ),
            meta: { editable: false },
            bodyCell: {
              ...defaultCellProps,
              field: { ...mobilePhoneField, readOnly: true },
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.mobilePhone] ||
                DEFAULT_WIDTH_IN_PIXELS,
              padding: PADDING_IN_PIXELS,
              Component: PhoneNumberCell,
              meta: { editable: false },
              ...getDataQAForInput(
                `inline-contact-${DEFAULT_FIELD_NAMES.mobilePhone}`,
                mobilePhoneField?.fieldType
              ),
              forceReadOnly: true,
              editable: false,
              disabled: true,
            },
          },
        }
      : {}),
    ...(titlesField
      ? {
          [DEFAULT_FIELD_NAMES.titles]: {
            headCell: (
              <CustomFieldHeadCell
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.titles] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
                hasSorting={false}
                field={titlesField}
                padding={PADDING_IN_PIXELS}
              />
            ),
            meta: { editable: false },
            bodyCell: {
              ...defaultCellProps,
              field: { ...titlesField, readOnly: true },
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.titles] ||
                DEFAULT_WIDTH_IN_PIXELS,
              padding: PADDING_IN_PIXELS,
              Component: DynamicTagsCell,
              fetchUrl: getObjectUrl('contacts'),
              meta: { editable: false },
              ...getDataQAForInput(
                `inline-contact-${DEFAULT_FIELD_NAMES.titles}`,
                titlesField?.fieldType
              ),
              forceReadOnly: true,
              enableLink: titlesEnableLink,
            },
          },
        }
      : {}),
    ...(tagsField
      ? {
          [DEFAULT_FIELD_NAMES.tags]: {
            headCell: (
              <CustomFieldHeadCell
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.tags] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
                hasSorting={false}
                field={tagsField}
                padding={PADDING_IN_PIXELS}
              />
            ),
            meta: { editable: false },
            bodyCell: {
              ...defaultCellProps,
              field: { ...tagsField, readOnly: true },
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.tags] ||
                DEFAULT_WIDTH_IN_PIXELS,
              padding: PADDING_IN_PIXELS,
              Component: DynamicTagsCell,
              fetchUrl: getObjectUrl('contacts'),
              meta: { editable: false },
              ...getDataQAForInput(
                `inline-contact-${DEFAULT_FIELD_NAMES.tags}`,
                tagsField?.fieldType
              ),
              forceReadOnly: true,
              enableLink: tagsEnableLink,
            },
          },
        }
      : {}),
    ...(createdField
      ? {
          [DEFAULT_FIELD_NAMES.created]: {
            headCell: (
              <SortableHeadCell
                numeric
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.created] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
                padding={PADDING_IN_PIXELS}
              />
            ),
            meta: { editable: false },
            bodyCell: {
              ...defaultCellProps,
              field: createdField,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.created] ||
                DEFAULT_WIDTH_IN_PIXELS,
              padding: PADDING_IN_PIXELS,
              Component: DateTimeCell,
              meta: { editable: false },
              ...getDataQAForInput(
                `inline-contact-${DEFAULT_FIELD_NAMES.created}`,
                createdField?.fieldType
              ),
              showDateTooltip: true,
            },
          },
        }
      : {}),
    ...(updatedField
      ? {
          [DEFAULT_FIELD_NAMES.updated]: {
            headCell: (
              <SortableHeadCell
                numeric
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.updated] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
                padding={PADDING_IN_PIXELS}
              />
            ),
            meta: { editable: false },
            bodyCell: {
              ...defaultCellProps,
              field: updatedField,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.updated] ||
                DEFAULT_WIDTH_IN_PIXELS,
              padding: PADDING_IN_PIXELS,
              Component: DateTimeCell,
              meta: { editable: false },
              ...getDataQAForInput(
                `inline-contact-${DEFAULT_FIELD_NAMES.updated}`,
                updatedField?.fieldType
              ),
              showDateTooltip: true,
            },
          },
        }
      : {}),
  };
};

export const getContactRecordsColumns = ({
  fields,
  columnSettings,
  onSubmitContact,
  categorizedFields,
  handleUpdateTableRecords,
  handleUpdateFieldOption,
  serviceToUse,
  model,
}) => {
  const fieldsById = fields.reduce(
    (collect, field) => ({
      ...collect,
      [field.id]: field,
    }),
    {}
  );

  const fieldsByName = fields
    .filter(({ isDefault }) => isDefault)
    .reduce(
      (collect, field) => ({
        ...collect,
        [field.name]: field,
      }),
      {}
    );

  const widthsLookup = columnSettings.reduce((collect, column) => {
    collect[column.id] = column.width;
    return collect;
  }, {});

  const basicColumns = getBasicColumns({
    widthsLookup,
    onSubmitContact,
    fields,
    serviceToUse,
    model,
    handleUpdateFieldOption, // kzn-8229
    handleUpdateTableRecords,
    columnSettings,
  });

  const columnSettingsFiltered = columnSettings.filter(
    ({ id }) =>
      !fields
        .filter(({ isSuppressed }) => isSuppressed)
        .map(({ id }) => id)
        .includes(id)
  );

  return [
    {
      id: 'left-spacer',
      headCell: <SpacerCell width="15px" />,
      cell: <SpacerCell width="15px" />,
    },
    ...columnSettingsFiltered.map(
      ({ id, label, enableLink }) => {
        if (basicColumns[id]) {
          return {
            id,
            label,
            ...basicColumns[id],
          };
        }
        const field = fieldsById[id] || fieldsByName[id];
        if (!field) {
          return null;
        }

        const fieldProps =
          field?.fieldType === FIELD_TYPES.Relationship.type &&
          field.relation.fetchUrl === 'client'
            ? { categorizedFields }
            : {};

        return {
          id,
          label,
          cell: (
            <CustomFieldDataCell
              field={{ ...field, isReadOnly: true }}
              onSubmit={onSubmitContact}
              fieldProps={fieldProps}
              fetchUrl={getObjectUrl('contacts')}
              handleUpdateTableRecords={handleUpdateTableRecords}
              padding={PADDING_IN_PIXELS}
              minWidth={
                widthsLookup[field.name] ||
                widthsLookup[field.id] ||
                DEFAULT_WIDTH_IN_PIXELS
              }
            />
          ),
          headCell: (
            <CustomFieldHeadCell
              field={field}
              hasSorting={
                field?.fieldType !== FIELD_TYPES.Relationship.type ||
                field.relation.cardinality === 'many_to_one' ||
                field.relation.cardinality === 'one_to_one'
              }
              padding={PADDING_IN_PIXELS}
              minWidth={
                widthsLookup[field.name] ||
                widthsLookup[field.id] ||
                DEFAULT_WIDTH_IN_PIXELS
              }
            />
          ),
          bodyCell: {
            field: { ...field, isReadOnly: true },
            onSubmit: onSubmitContact,
            fetchUrl: getObjectUrl('contacts'),
            serviceToUse: serviceToUse,
            model,
            handleUpdateFieldOption, // kzn-8229
            handleUpdateTableRecords,
            padding: PADDING_IN_PIXELS,
            minWidth:
              widthsLookup[field.name] ||
              widthsLookup[field.id] ||
              DEFAULT_WIDTH_IN_PIXELS,
            menuLeftButton: <ClearSelectButton />,
            Component: getCellForFieldType(field.fieldType), // cellMap.get(field.fieldType) || MissedCell,
            isCustomObjects: false,
            fieldProps,
            enableLink,
            dataQaPrefix: 'inline-contact-',
          },
          meta: { editable: false },
          // Formatting happens internal to CustomFieldDataCell, but this
          // provides a hook that can be used by e.g. AddRecordTRow.
          format: (x) => x,
        };
      },
      {
        id: 'right-spacer',
        headCell: <SpacerCell width="15px" />,
        cell: <SpacerCell width="15px" />,
      }
    ),
  ].filter(Boolean);
};
