import { GO_BACK_KEYS } from 'components/Wizards/CustomObject/constants';

export const getBackButtonTitle = (t, fromKey) => {
  switch (fromKey) {
    case GO_BACK_KEYS.CONTACTS:
      return t('back to contacts');
    case GO_BACK_KEYS.OBJECTS:
      return t('back to objects');
    case GO_BACK_KEYS.COLUMNS:
      return t('back to columns');
    default:
      return t('back to overview');
  }
};
