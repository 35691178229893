import { useState, useEffect } from 'react';

// This is useful for form fields that can deviate from some initial value.
// Oftentime syncState will be a component prop, or value derived from component props,
// that you want to use but not actively control.
export default function useField(syncState, deps) {
  const [state, setState] = useState(syncState);
  useEffect(
    () => {
      setState(syncState);
    },
    deps || [syncState] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return [state, setState];
}
