import { faTableColumns } from '@fortawesome/pro-regular-svg-icons/faTableColumns';
import { faTable } from '@fortawesome/pro-regular-svg-icons/faTable';
import { faArrowDownArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowDownArrowUp';
import { faArrowDownAZ } from '@fortawesome/pro-regular-svg-icons/faArrowDownAZ';
import { faArrowDownZA } from '@fortawesome/pro-regular-svg-icons/faArrowDownZA';
import { faArrowDown91 } from '@fortawesome/pro-regular-svg-icons/faArrowDown91';
import { faArrowDown19 } from '@fortawesome/pro-regular-svg-icons/faArrowDown19';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons/faEllipsis';
import { faSquarePlus } from '@fortawesome/pro-regular-svg-icons/faSquarePlus';
import { faSquareMinus } from '@fortawesome/pro-regular-svg-icons/faSquareMinus';
import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import {
  IconAnimation,
  IconRotation,
  SemanticCategory,
  FixedWidthIconSettings,
  IconLabel,
} from '../types';

export const SEMANTIC_CATEGORY: SemanticCategory = 'table';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  '': faTable,
  columns: faTableColumns,
  sort: faArrowDownArrowUp,
  'sort-a-z': faArrowDownAZ,
  'sort-z-a': faArrowDownZA,
  'sort-9-1': faArrowDown91,
  'sort-1-9': faArrowDown19,
  options: faEllipsis,
  expand: faSquarePlus,
  collapse: faSquareMinus,
  folder: faFolder,
  'edit-cell': faPen,
  'save-cell': faCheck,
};

export const labels: IconLabel = {
  '': {
    title: (t) => '',
    description: (t) => t('Table'),
  },
  columns: {
    title: (t) => '',
    description: (t) => t('Columns'),
  },
  sort: {
    title: (t) => '',
    description: (t) => t('Sort'),
  },
  'sort-a-z': {
    title: (t) => '',
    description: (t) => t('Sort A-Z'),
  },
  'sort-z-a': {
    title: (t) => '',
    description: (t) => t('Sort Z-A'),
  },
  'sort-9-1': {
    title: (t) => '',
    description: (t) => t('Sort 9-1'),
  },
  'sort-1-9': {
    title: (t) => '',
    description: (t) => t('Sort 1-9'),
  },
  options: {
    title: (t) => '',
    description: (t) => t('Options'),
  },
  expand: {
    title: (t) => '',
    description: (t) => t('Expand'),
  },
  collapse: {
    title: (t) => '',
    description: (t) => t('Collapse'),
  },
  folder: {
    title: (t) => '',
    description: (t) => t('Folder'),
  },
  'edit-cell': {
    title: (t) => '',
    description: (t) => t('Edit Cell'),
  },
  'save-cell': {
    title: (t) => '',
    description: (t) => t('Save Cell'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};

export const fixedWidthIcons: FixedWidthIconSettings = {
  collapse: true,
  expand: true,
};
