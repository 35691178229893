import React, { useRef } from 'react';
import { useEditor } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import { useTextStyleValue } from '../../../WYSIWYG/useTextStyleValue';
import { fontSizes } from '../../constants';
import { TextEditorToolbarSection, ToolbarCreatableSelect } from '../../styles';

const fontSizeOptionXform = (v) => ({ value: `${v}px`, label: v });
const fontSizeValueToOption = (value) => ({
  value,
  label: value?.split('px')[0] ?? '',
});

export const FontSizeToolbarSection = ({ defaultFontSize }) => {
  const selectRef = useRef();
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  const fontSize = useTextStyleValue('fontSize');
  if (!editor) return null;

  const selectValue = selectRef.current?.state?.value?.value;
  const activeFontSize =
    selectValue && editor.isActive({ fontSize: selectValue })
      ? selectValue
      : fontSize;

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <ToolbarCreatableSelect
        ref={selectRef}
        value={activeFontSize || defaultFontSize}
        options={fontSizes}
        width={62}
        isInputValid={(v) => !isNaN(v) && v > 0}
        valueToOption={fontSizeValueToOption}
        inputToOption={fontSizeOptionXform}
        onChange={({ value }) => {
          editor.chain().focus().setFontSize(value).run();
        }}
      />
    </TextEditorToolbarSection>
  );
};
