import useModal from 'components/Modals/useModal';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';
import ClearDialog from './ClearModal';
import TeamMemberService from 'services/TeamMemberService';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const QUERY_PARAM = 'delete_page_config';

// Pulls the parameter delete_page_config from the url
// and returns the UI for deleting the page config if appropriate.
// If skipRedirect is true, the redirect will not occur
// and the query param will remain in the URL (this is mostly useful for testing)
const useClearPageConfig = (afterDelete, skipRedirect = false) => {
  const location = useLocation();
  const history = useHistory();
  const didClear = useRef(false);

  const [modalProps, , modal] = useModal();

  const { show, hide } = modal;
  const { pathname, search } = location;

  const isClearing = useMemo(() => {
    return search.includes(`${QUERY_PARAM}=true`);
  }, [search]);

  const clientObject = useSelector(getBusinessClientObject);
  const clientObjectId = clientObject.id;

  const getKey = useCallback(() => {
    // All dashboard routes use the same config
    if (/^\/dashboard.*/.test(pathname)) {
      return 'dashboard_list';
    }

    if (/^\/home.*/.test(pathname)) {
      return 'homepage_list';
    }

    if (/^\/automations$/.test(pathname)) {
      return 'automation_list';
    }

    if (/^\/activities$/.test(pathname)) {
      return 'activity_list';
    }

    if (/^\/library\/.*/.test(pathname)) {
      return 'message_library_list';
    }

    if (/^\/forms$/.test(pathname)) {
      return 'form_list';
    }

    if (/^\/surveys$/.test(pathname)) {
      return 'survey_list';
    }

    // Custom Object Entity Record
    const entityRecordExpressionResult =
      /^\/custom-objects\/(.+)\/.+\/details$/.exec(pathname);
    if (entityRecordExpressionResult) {
      return `custom-object-detail/${entityRecordExpressionResult[1]}`;
    }

    // Custom Object Entity List
    const entityListExpressionResult = /^\/custom-objects\/(.+)/.exec(pathname);
    if (entityListExpressionResult) {
      return `custom-objects/${entityListExpressionResult[1]}`;
    }

    // Custom Object List
    if (/^\/custom-objects.*/.test(pathname)) {
      return 'custom_object_list';
    }

    if (/^\/clients.*/.test(pathname)) {
      return `custom-objects/${clientObjectId}`;
    }

    if (/^\/client\/(.+)\/details$/.test(pathname)) {
      return `custom-object-detail/${clientObjectId}`;
    }
  }, [pathname, clientObjectId]);

  useEffect(() => {
    if (!didClear.current && isClearing && clientObjectId) {
      const key = getKey();
      if (!skipRedirect) {
        history.replace(pathname);
      }
      didClear.current = true;
      if (key) {
        show();
      }
    }
  }, [
    isClearing,
    show,
    pathname,
    history,
    skipRedirect,
    getKey,
    clientObjectId,
  ]);

  const handleSettled = () => {
    if (afterDelete) {
      afterDelete();
    } else {
      window.location.reload();
    }
  };

  const subPageConfigMutation = useMutation(
    (key) =>
      TeamMemberService.deleteSubPageConfig(key, { skipErrorBoundary: true }),
    {
      onSettled: handleSettled,
    }
  );

  const handleConfirmDelete = useCallback(() => {
    const key = getKey();
    if (key) {
      subPageConfigMutation.mutate(key.includes('/') ? key : `page/${key}`);
      hide();
    }
  }, [hide, subPageConfigMutation, getKey]);

  return modalProps.show ? (
    <ClearDialog {...modalProps} onConfirm={handleConfirmDelete} />
  ) : null;
};

export default useClearPageConfig;
