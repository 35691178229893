import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import CustomObjectsService from 'services/CustomObjectsService';
import Select from '__components/Inputs/Select';
import { useErrors } from '../../hooks/useErrors';
import { getDataQAForInput } from '__components/Inputs/helpers';
import { FORCE_ALL_RECORDS_SIZE } from '__services/helpers';

type CustomObjectsSelectProps = {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
};

export const CustomObjectSelect = ({
  value,
  onChange,
  disabled,
}: CustomObjectsSelectProps) => {
  const { t } = useTranslation();

  const { value: customObjects = [], loading: loadingObjects } = useAsync<
    () => Promise<
      {
        value: string;
        label: string;
      }[]
    >
  >(async () => {
    const { results } = await CustomObjectsService.getCustomObjectList({
      size: FORCE_ALL_RECORDS_SIZE,
      customOnly: false,
    });
    return results.map(
      ({ id, objectName }: { id: string; objectName: string }) => ({
        value: id,
        label: objectName,
      })
    );
  }, []);

  const { clearError, validate, error } = useErrors('custom_object');

  const handleChange = ({ value }: { value: string }) => {
    onChange(value);
    clearError();
  };

  return (
    <Select
      label={t('Choose Primary Object')}
      placeholder={t('Choose Object')}
      error={error}
      validate={validate}
      loadItems={loadingObjects}
      value={value}
      onChange={handleChange}
      options={customObjects}
      required
      disabled={disabled}
      {...getDataQAForInput('smart-connector-context-object', 'dropdown')}
    />
  );
};
