import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import UUID from 'utility/UUID';
import { inputFieldValueMappings } from './../CommonComponents/valueMappingsCommon';
import { getTeamLabel } from 'services/helpers';

export const mreChangeTypeOptionsConst = {
  SPECIFIC_VALUE: 'specific_value',
  RELATED_OBJECT_FIELD: 'context_entity_value',
};
export const inputsModifyRelatedEntities = {
  OBJECT_TO_MODIFY: 'objectToModify',
  AUTOMATION_TARGET_RELATIONSHIP_FIELDS: 'automationTargetRelationshipFields',
  FIELDS_TO_MODIFY: 'fieldsToModify',
  ADD_FIELD_TO_MODIFY: 'addFieldToModify',
  DELETE_FIELD_TO_MODIFY: 'deleteFieldToModify',
};

export const inputFieldToModify = {
  FIELD_TO_MODIFY: 'fieldToModify',
  CHANGE_TYPE: 'valueType',
  SPECIFIC_FIELD_VALUE: 'specificFieldValue',
  CONTEXT_ENTITY_FIELD: 'contextEntityField',
  DUPLICATE_ERROR: 'duplicateError',
  FIELD_RESOLUTION: 'fieldResolution',
  FIELD_VALUE_MAPPINGS: 'fieldValueMappings',
  ADD_FIELD_VALUE_MAPPINGS: 'addFieldValueMappings',
  DELETE_FIELD_VALUE_MAPPINGS: 'deleteFieldValueMappings',
  FIELD_VALUE_MAPPINGS_TYPES: 'fieldValueMappingsTypes',
  FIELD_VALUE_MAPPINGS_ERRORS: 'fieldValueMappingsErrors',
  FIELD_VALUE_MAPPINGS_VALIDATION: 'fieldValueMappingsValidation',
  DELETED_ERROR: 'deletedError',
};

export const fieldResolutionOptionsConst = {
  OVERWRITE: 'overwrite',
  ONLY_UPDATE_BLANK: 'update_if_blank',
  ONLY_ADD_OPTIONS: 'add_only',
};

export const fieldResolutionOptionsLabelsConst = {
  OVERWRITE: 'Overwrite Field',
  ONLY_UPDATE_BLANK: 'Only Update Blank',
  ONLY_ADD_OPTIONS: 'Only Add Options',
};

export const fieldTypesMeta = {
  [FIELD_TYPES.Decimal.type]: {
    type: FIELD_TYPES.Decimal.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Integer.type]: {
    type: FIELD_TYPES.Integer.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Number.type]: {
    type: FIELD_TYPES.Number.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Price.type]: {
    type: FIELD_TYPES.Price.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Money.type]: {
    type: FIELD_TYPES.Money.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  // text
  [FIELD_TYPES.Email.type]: {
    type: FIELD_TYPES.Email.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.LongText.type]: {
    type: FIELD_TYPES.LongText.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Text.type]: {
    type: FIELD_TYPES.Text.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  // 5628
  [FIELD_TYPES.Checkbox.type]: {
    type: FIELD_TYPES.Checkbox.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Date.type]: {
    type: FIELD_TYPES.Date.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.DateTime.type]: {
    type: FIELD_TYPES.DateTime.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  // 5629
  [FIELD_TYPES.Dropdown.type]: {
    type: FIELD_TYPES.Dropdown.type,
    showFieldResolution: true,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.Radio.type]: {
    type: FIELD_TYPES.Radio.type,
    showFieldResolution: true,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.Rating.type]: {
    type: FIELD_TYPES.Rating.type,
    showFieldResolution: true,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.Status.type]: {
    type: FIELD_TYPES.Status.type,
    showFieldResolution: true,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.TeamSelector.type]: {
    type: FIELD_TYPES.TeamSelector.type,
    showFieldResolution: false,
    fieldRequiresWizard: false,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.YesNoMaybe.type]: {
    type: FIELD_TYPES.YesNoMaybe.type,
    showFieldResolution: true,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.Checkboxes.type]: {
    type: FIELD_TYPES.Checkboxes.type,
    showFieldResolution: true,
    fieldRequiresWizard: true,
    specifiedIsOption: false,
  },
  // 5630
  [FIELD_TYPES.DynamicTags.type]: {
    type: FIELD_TYPES.DynamicTags.type,
    showFieldResolution: false,
    fieldRequiresWizard: false,
    fieldSpecificValueStack: (t) => [
      { label: t('Options to Add'), prop: 'tagsToAdd' },
      { label: t('Options to Remove'), prop: 'tagsToRemove' },
    ],
  },
  // 5630
  [FIELD_TYPES.Files.type]: {
    type: FIELD_TYPES.Files.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
  },
  // 5630
  [FIELD_TYPES.Relationship.type]: {
    type: FIELD_TYPES.Relationship.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
  },
  // 7436
  [FIELD_TYPES.Timezone.type]: {
    type: FIELD_TYPES.Timezone.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
  },
};

export const emailStatus = {
  OPTED_IN: 'opted_in',
  NOT_OPTED_IN: 'not_opted_in',
  UNSUBSCRIBED: 'unsubscribed',
  SUPPRESSION_LIST: 'suppression_list',
};

export const fieldTypesFilter = Object.values(fieldTypesMeta).reduce(
  (acc, { type }) => [...acc, type],
  []
);

export const fieldBlackList = [
  DEFAULT_FIELD_NAMES.created,
  DEFAULT_FIELD_NAMES.actualCloseDate,
  DEFAULT_FIELD_NAMES.updated,
];

export const fieldBlackListPredicate = (f) =>
  f.isDefault && fieldBlackList.includes(f.name);

export const fieldTypePredicate = (f) => fieldTypesFilter.includes(f.fieldType);

export const shouldShowFieldResolution = (type) =>
  type ? fieldTypesMeta[type].showFieldResolution || false : false;

export const getSpecificValueStack = (type, t) =>
  type
    ? fieldTypesMeta[type].fieldSpecificValueStack?.(t) || [
        { label: t('Value') },
      ]
    : [{ label: t('Value') }];

export const fieldRequiresWizard = (type) =>
  type ? fieldTypesMeta[type].fieldRequiresWizard || false : false;

export const specifiedIsOption = (type) =>
  type ? fieldTypesMeta[type].specifiedIsOption || false : false;

export const newFieldValueMappings = (order) => ({
  key: UUID.generate(),
  [inputFieldValueMappings.SOURCE_VALUES]: null,
  [inputFieldValueMappings.TARGET_VALUE]: null,
  order,
});

export const fieldToModifyfieldType = (ftm) => ftm?.fieldData?.fieldType;

export const isValueTypeRelated = (ftm) =>
  ftm?.valueType?.value === mreChangeTypeOptionsConst.RELATED_OBJECT_FIELD;

// if the target field is one_to_many or many_to_many  it can match any related type
const MatchAllRelations = ['one_to_many', 'many_to_many'];
// if the target field is many_to_one it can only match many_to_one or one_to_one
export const MatchManyToOneRelations = ['many_to_one', 'one_to_one'];

export const hasAppropriateRelation = (targetField = {}, relatedField = {}) => {
  return (
    targetField.fieldType !== FIELD_TYPES.Relationship.type ||
    (targetField.relation?.relatedObject ===
      relatedField.relation?.relatedObject &&
      (MatchAllRelations.some(
        (rel) => rel === targetField?.relation?.cardinality
      ) ||
        (targetField?.relation?.cardinality === 'many_to_one' &&
          MatchManyToOneRelations.some(
            (rel) => rel === relatedField?.relation?.cardinality
          ))))
  );
};

// if firstName, lastName or displayName are present, use them
export const namedToLabel = ({
  id,
  name,
  email,
  firstName,
  lastName,
  displayName,
}) =>
  firstName || lastName || displayName
    ? {
        id,
        label: displayName
          ? displayName
          : getTeamLabel({ firstName, lastName, email }),
      }
    : {
        id,
        label: email ? `(${email})` : name,
      };
