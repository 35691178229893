import { gutters, layers } from 'app/spacing';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { css } from '@emotion/core';

const LEGEND_CONTROLS_GAP = 1;
const LEGEND_COLUMN_GAP = 20;

export const LegendContainer = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  z-index: ${layers.content(9)};

  ${({ shouldWrap, rows = 2 }) =>
    shouldWrap
      ? css`
          flex-wrap: wrap;
          row-gap: 10px;
          height: ${rows === 2 ? rows * 18 : rows * 20}px;
        `
      : ''}

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          column-gap: ${LEGEND_COLUMN_GAP}px;
        `
      : ''}
`;

export const LegendParent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 85%;
  max-width: 600px;
  padding-top: ${gutters.spacing(2)}px;

  ${({ compact }) =>
    compact
      ? css`
          padding-top: ${gutters.spacing(1)}px;
        `
      : ''}

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          margin-left: 0;
          margin-right: auto;
          width: calc(100% - 11px);
        `
      : ''}
`;

export const LegendItem = styled.div`
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  ${({ fixedWidth, columnCount, fullWidth }) =>
    fixedWidth
      ? css`
          max-width: calc(
            ${100 / columnCount}% - ${fullWidth ? LEGEND_COLUMN_GAP : 0}px
          );
          min-width: calc(
            ${100 / columnCount}% - ${fullWidth ? LEGEND_COLUMN_GAP : 0}px
          );
        `
      : ''}

  margin: 0 auto;

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          margin: 0;
        `
      : ''}
`;

export const LegendButton = styled.span`
  display: flex;
  align-items: center;
  padding: 0 10px;
  & p {
    color: ${({ disabled }) =>
      disabled ? grayScale.mediumDark : grayScale.dark};
    font-size: 10px;
  }

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          padding: 0;
        `
      : ''}
`;

export const LegendControls = styled.div`
  display: flex;

  ${({ vertical }) =>
    vertical
      ? css`
          flex-direction: column;
        `
      : ''}

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          padding-left: ${LEGEND_CONTROLS_GAP}px;
        `
      : ''}
`;

export const LegendDot = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 10px;
  background: ${({ legendColor, disabled }) =>
    disabled ? grayScale.mediumDark : legendColor};
  margin-right: ${gutters.spacing(2)}px;
  margin-bottom: 2px;
`;

export const Arrow = styled.img`
  ${({ flip }) =>
    flip
      ? css`
          transform: rotate(180deg) translateX(0.5px);
        `
      : ''}
  margin-left: ${({ left }) => left ?? 0}px;
  margin-right: ${({ right }) => right ?? 0}px;
  margin-top: ${({ top }) => top ?? 0}px;

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
        `
      : ''}
`;
