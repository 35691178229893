import { library, config } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faPlus as faPlusLight } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faAngleRight as farAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import { faAngleDoubleRight } from '@fortawesome/pro-light-svg-icons/faAngleDoubleRight';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave';
import { faClock as faClockLight } from '@fortawesome/pro-light-svg-icons/faClock';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faEnvelope as faEnvelopeLight } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faEnvelopeOpen } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpen';
import { faMousePointer } from '@fortawesome/pro-light-svg-icons/faMousePointer';
import { faPoll } from '@fortawesome/pro-light-svg-icons/faPoll';
import { faAlignCenter as faAlignCenterRegular } from '@fortawesome/pro-regular-svg-icons/faAlignCenter';
import { faAlignJustify as faAlignJustifyRegular } from '@fortawesome/pro-regular-svg-icons/faAlignJustify';
import { faAlignLeft as faAlignLeftRegular } from '@fortawesome/pro-regular-svg-icons/faAlignLeft';
import { faAlignRight as faAlignRightRegular } from '@fortawesome/pro-regular-svg-icons/faAlignRight';
import { faAlignCenter } from '@fortawesome/pro-light-svg-icons/faAlignCenter';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons/faAlignJustify';
import { faAlignLeft } from '@fortawesome/pro-light-svg-icons/faAlignLeft';
import { faAlignRight } from '@fortawesome/pro-light-svg-icons/faAlignRight';
import { faStickyNote } from '@fortawesome/pro-light-svg-icons/faStickyNote';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faShoppingBag } from '@fortawesome/pro-light-svg-icons/faShoppingBag';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faMagnifyingGlass as faMagnifyingGlassRegular } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faCopy as faCopyRegular } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faSortAlt } from '@fortawesome/pro-regular-svg-icons/faSortAlt';
import { faSortNumericDown } from '@fortawesome/pro-regular-svg-icons/faSortNumericDown';
import { faSortNumericDownAlt } from '@fortawesome/pro-regular-svg-icons/faSortNumericDownAlt';
import { faSortAlphaDown } from '@fortawesome/pro-regular-svg-icons/faSortAlphaDown';
import { faSortAlphaDownAlt } from '@fortawesome/pro-regular-svg-icons/faSortAlphaDownAlt';
import { faSync as faSyncRegular } from '@fortawesome/pro-regular-svg-icons/faSync';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faTableColumns } from '@fortawesome/pro-regular-svg-icons/faTableColumns';
import { faTableColumns as faTableColumnsLight } from '@fortawesome/pro-light-svg-icons/faTableColumns';
import { faTag } from '@fortawesome/pro-regular-svg-icons/faTag';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronDoubleDown } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleDown';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight as faChevronRightRegular } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronDoubleRight as faChevronDoubleRightRegular } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faCogs as faCogsRegular } from '@fortawesome/pro-regular-svg-icons/faCogs';
import { faCogs } from '@fortawesome/pro-light-svg-icons/faCogs';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faUsersCog } from '@fortawesome/pro-light-svg-icons/faUsersCog';
import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faClipboardCheck as faClipboardCheckRegular } from '@fortawesome/pro-regular-svg-icons/faClipboardCheck';
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faBellExclamation } from '@fortawesome/pro-light-svg-icons/faBellExclamation';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faWallet } from '@fortawesome/pro-light-svg-icons/faWallet';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faCompass } from '@fortawesome/pro-light-svg-icons/faCompass';
import { faSmile } from '@fortawesome/pro-light-svg-icons/faSmile';
import { faChartBar } from '@fortawesome/pro-regular-svg-icons/faChartBar';
import { faChartColumn } from '@fortawesome/pro-light-svg-icons/faChartColumn';
import { faDatabase as faDatabaseRegular } from '@fortawesome/pro-regular-svg-icons/faDatabase';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faBrowser } from '@fortawesome/pro-regular-svg-icons/faBrowser';
import { faPlay as faPlayRegular } from '@fortawesome/pro-regular-svg-icons/faPlay';
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faSackDollar } from '@fortawesome/pro-light-svg-icons/faSackDollar';
import { faReceipt } from '@fortawesome/pro-light-svg-icons/faReceipt';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faBullseyePointer } from '@fortawesome/pro-light-svg-icons/faBullseyePointer';
import { faBookAlt } from '@fortawesome/pro-light-svg-icons/faBookAlt';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck';
import { faRepeat } from '@fortawesome/pro-light-svg-icons/faRepeat';
import { faSignIn } from '@fortawesome/pro-light-svg-icons/faSignIn';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faArrowFromLeft } from '@fortawesome/pro-regular-svg-icons/faArrowFromLeft';
import { faArrowFromRight } from '@fortawesome/pro-regular-svg-icons/faArrowFromRight';
import { faArrowFromTop } from '@fortawesome/pro-regular-svg-icons/faArrowFromTop';
import { faArrowFromBottom } from '@fortawesome/pro-regular-svg-icons/faArrowFromBottom';
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft';
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons/faArrowToRight';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faBars as faBarsLight } from '@fortawesome/pro-light-svg-icons/faBars';
import { faSeedling } from '@fortawesome/pro-light-svg-icons/faSeedling';
import { faStore } from '@fortawesome/pro-light-svg-icons/faStore';
import { faCheck as faCheckLight } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa';
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import { faCommentsAlt } from '@fortawesome/pro-regular-svg-icons/faCommentsAlt';
import { faCommentsAlt as faCommentsAltLight } from '@fortawesome/pro-light-svg-icons/faCommentsAlt';
import { faMessageLines } from '@fortawesome/pro-regular-svg-icons/faMessageLines';
import { faPause } from '@fortawesome/pro-regular-svg-icons/faPause';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn';
import { faBullseye } from '@fortawesome/pro-regular-svg-icons/faBullseye';
import { faBullseye as faBullseyeLight } from '@fortawesome/pro-light-svg-icons/faBullseye';
import { faCodeBranch } from '@fortawesome/pro-regular-svg-icons/faCodeBranch';
import { faCodeBranch as faCodeBranchLight } from '@fortawesome/pro-light-svg-icons/faCodeBranch';
import { faFlask } from '@fortawesome/pro-regular-svg-icons/faFlask';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faUserMinus } from '@fortawesome/pro-light-svg-icons/faUserMinus';
import { faTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { faMobile } from '@fortawesome/pro-light-svg-icons/faMobile';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faExternalLink as faExternalLinkLight } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faTools } from '@fortawesome/pro-regular-svg-icons/faTools';
import { faTools as faToolsLight } from '@fortawesome/pro-light-svg-icons/faTools';
import { faTags as faTagsLight } from '@fortawesome/pro-light-svg-icons/faTags';
import { faHandPointer } from '@fortawesome/pro-light-svg-icons/faHandPointer';
import { faInboxOut } from '@fortawesome/pro-light-svg-icons/faInboxOut';
import { faInboxIn } from '@fortawesome/pro-light-svg-icons/faInboxIn';
import { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faFolder as faFolderLight } from '@fortawesome/pro-light-svg-icons/faFolder';
import { faLevelUp } from '@fortawesome/pro-light-svg-icons/faLevelUp';
import { faBrush } from '@fortawesome/pro-regular-svg-icons/faBrush';
import { faHighlighter } from '@fortawesome/pro-regular-svg-icons/faHighlighter';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faUnlink } from '@fortawesome/pro-regular-svg-icons/faUnlink';
import { faBold } from '@fortawesome/pro-regular-svg-icons/faBold';
import { faItalic } from '@fortawesome/pro-regular-svg-icons/faItalic';
import { faUnderline } from '@fortawesome/pro-regular-svg-icons/faUnderline';
import { faStrikethrough } from '@fortawesome/pro-regular-svg-icons/faStrikethrough';
import { faSignalStream } from '@fortawesome/pro-regular-svg-icons/faSignalStream';
import { faListOl } from '@fortawesome/pro-regular-svg-icons/faListOl';
import { faListUl } from '@fortawesome/pro-regular-svg-icons/faListUl';
import { faCode } from '@fortawesome/pro-regular-svg-icons/faCode';
import { faRemoveFormat } from '@fortawesome/pro-regular-svg-icons/faRemoveFormat';
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faShareAlt } from '@fortawesome/pro-light-svg-icons/faShareAlt';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faLineHeight } from '@fortawesome/pro-regular-svg-icons/faLineHeight';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleRight';
import { faFiles } from '@fortawesome/pro-regular-svg-icons/faFiles';
import { faFiles as faFilesLight } from '@fortawesome/pro-light-svg-icons/faFiles';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faCircle1 } from '@fortawesome/pro-light-svg-icons/faCircle1';
import { faCircle2 } from '@fortawesome/pro-light-svg-icons/faCircle2';
import { faCircle3 } from '@fortawesome/pro-light-svg-icons/faCircle3';
import { faCircle4 } from '@fortawesome/pro-light-svg-icons/faCircle4';
import { faCircle5 } from '@fortawesome/pro-light-svg-icons/faCircle5';
import { faCircle6 } from '@fortawesome/pro-light-svg-icons/faCircle6';
import { faChartPie } from '@fortawesome/pro-regular-svg-icons/faChartPie';
import { faLessThanEqual } from '@fortawesome/pro-regular-svg-icons/faLessThanEqual';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faImageLandscape } from '@fortawesome/pro-light-svg-icons/faImageLandscape';
import { faText } from '@fortawesome/pro-regular-svg-icons/faText';
import { faChartPie as faChartPieLight } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faCircleDollar } from '@fortawesome/pro-light-svg-icons/faCircleDollar';
import { faHorizontalRule } from '@fortawesome/pro-light-svg-icons/faHorizontalRule';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faHouseBlank } from '@fortawesome/pro-light-svg-icons/faHouseBlank';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faSquareList } from '@fortawesome/pro-light-svg-icons/faSquareList';
import { faInputPipe } from '@fortawesome/pro-light-svg-icons/faInputPipe';
import { faTablePivot } from '@fortawesome/pro-light-svg-icons';
import { faTimer } from '@fortawesome/pro-light-svg-icons';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import { faArrowsToCircle } from '@fortawesome/pro-light-svg-icons/faArrowsToCircle';
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons/faClockRotateLeft';

library.add(
  faEllipsisH,
  faAngleDown,
  faTimes,
  faPlusLight,
  faAngleLeft,
  faAngleRight,
  farAngleRight,
  farAngleLeft,
  faAngleDoubleRight,
  faCalendarDay,
  faSave,
  faClockLight,
  faClock,
  faUserFriends,
  faCheck,
  faMinus,
  faCircle,
  faPen,
  faEnvelopeLight,
  faEnvelopeOpen,
  faMousePointer,
  faPoll,
  faAlignCenterRegular,
  faAlignJustifyRegular,
  faAlignLeftRegular,
  faAlignRightRegular,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faStickyNote,
  faFilter,
  faShoppingBag,
  faMagnifyingGlass,
  faDollarSign,
  faTrashAlt,
  faCopyRegular,
  faCopy,
  faEye,
  faEyeSlash,
  faExclamationTriangle,
  faSortAlt,
  faSortNumericDown,
  faSortNumericDownAlt,
  faSortAlphaDown,
  faSortAlphaDownAlt,
  faSyncRegular,
  faSync,
  faTableColumns,
  faTableColumnsLight,
  faTag,
  faChevronDown,
  faChevronDoubleDown,
  faChevronLeft,
  faChevronRightRegular,
  faChevronRight,
  faChevronDoubleRightRegular,
  faPlus,
  faCogsRegular,
  faCogs,
  faPhone,
  faEnvelopeRegular,
  faBuilding,
  faUsersCog,
  faIdCard,
  faClipboardList,
  faClipboardCheckRegular,
  faClipboardCheck,
  faBellExclamation,
  faGlobe,
  faShieldCheck,
  faWallet,
  faUserCircle,
  faCompass,
  faSmile,
  faChartBar,
  faChartColumn,
  faDatabaseRegular,
  faDatabase,
  faBrowser,
  faPlayRegular,
  faPlay,
  faCog,
  faInfoCircle,
  faUser,
  faUserCircle,
  faSackDollar,
  faReceipt,
  faCalendarAlt,
  faBullseyePointer,
  faBookAlt,
  faClipboardListCheck,
  faRepeat,
  faSignIn,
  faSignOut,
  faArrowFromLeft,
  faArrowFromRight,
  faArrowFromTop,
  faArrowFromBottom,
  faArrowToLeft,
  faArrowToRight,
  faBars,
  faBarsLight,
  faSeedling,
  faStore,
  faCheckLight,
  faCcVisa,
  faCcDiscover,
  faCcAmex,
  faCcMastercard,
  faCommentsAlt,
  faCommentsAltLight,
  faMessageLines,
  faPause,
  faBullhorn,
  faBullseye,
  faBullseyeLight,
  faCodeBranch,
  faCodeBranchLight,
  faFlask,
  faUserPlus,
  faUserMinus,
  faTable,
  faMobile,
  faExternalLink,
  faExternalLinkLight,
  faTools,
  faLayerGroup,
  faToolsLight,
  faTagsLight,
  faHandPointer,
  faInboxOut,
  faInboxIn,
  faCloudArrowUp,
  faFileAlt,
  faFolder,
  faFolderLight,
  faLevelUp,
  faBrush,
  faHighlighter,
  faLink,
  faUnlink,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faSignalStream,
  faListOl,
  faListUl,
  faCode,
  faRemoveFormat,
  faBan,
  faGraduationCap,
  faComments,
  faQuestionCircle,
  faShareAlt,
  faArrowRight,
  faImage,
  faLineHeight,
  faChevronDoubleRight,
  faTwitter,
  faFacebookF,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faCircle5,
  faCircle6,
  faChartPie,
  faLessThanEqual,
  faKey,
  faChartLine,
  faImageLandscape,
  faText,
  faChartPieLight,
  faCircleDollar,
  faHorizontalRule,
  faBriefcase,
  faHouseBlank,
  faXmark,
  faHouseBlank,
  faFiles,
  faFilesLight,
  faMagnifyingGlassRegular,
  faSquareList,
  faInputPipe,
  faTablePivot,
  faTimer,
  faArrowsRotate,
  faArrowsToCircle,
  faClockRotateLeft
);

// Auto-adding CSS must be turned off due to our security policies. The CSS must be added manually
// to any app using the icons
config.autoAddCss = false;

export { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export * from './src/icons';
