import {
  DISPLAY_NAME_PROPS_BY_FIELD_TYPE,
  PILL_TYPES,
} from 'components/Inputs/PillDropdown/constants';
import {
  DATE_FIELDS,
  MULTISELECT_FIELDS,
  NUMBER_FIELDS,
  SINGLE_SELECT_FIELDS,
  TEXT_FIELDS,
} from './constants';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { QUICK_FILTERS_IN_GROUPS } from 'store/utilities';

export const getPillType = (field) => {
  if (!field) {
    return null;
  }
  if (field.fieldType === QUICK_FILTERS_IN_GROUPS) {
    return PILL_TYPES.filterGroupMultiselect.type;
  }
  if (MULTISELECT_FIELDS.includes(field.fieldType)) {
    return PILL_TYPES.facetMultiselect.type;
  }
  if (SINGLE_SELECT_FIELDS.includes(field.fieldType)) {
    return PILL_TYPES.facetSingleselect.type;
  }
  if (NUMBER_FIELDS.includes(field.fieldType)) {
    return PILL_TYPES.number.type;
  }
  if (TEXT_FIELDS.includes(field.fieldType)) {
    return PILL_TYPES.text.type;
  }
  if (DATE_FIELDS.includes(field.fieldType)) {
    return PILL_TYPES.date.type;
  }
  return null;
};

export const getFieldsForPills = (
  quickFilterSettings,
  fields = [],
  model = {}
) => {
  if (quickFilterSettings?.length) {
    return quickFilterSettings.reduce(
      (
        collect,
        { fieldId, relatedObjectFieldId, relatedObjectField, id, label }
      ) => {
        const quickFilterField = fields.find(({ id }) => id === fieldId);

        const field =
          relatedObjectFieldId === QUICK_FILTERS_IN_GROUPS
            ? { id: relatedObjectFieldId, fieldType: QUICK_FILTERS_IN_GROUPS }
            : relatedObjectField || quickFilterField;

        if (field && quickFilterField) {
          collect.push({
            title: label,
            key: id,
            field,
            model: relatedObjectFieldId
              ? { id: quickFilterField.relation.relatedObject }
              : model,
            canBeFaceted: !relatedObjectFieldId,
          });
        }
        return collect;
      },
      []
    );
  }
  return EMPTY_ARRAY;
};

export const getDisplayNamesPropNames = (fieldType) =>
  DISPLAY_NAME_PROPS_BY_FIELD_TYPE[fieldType] ||
  DISPLAY_NAME_PROPS_BY_FIELD_TYPE.default;

export const getDisplayNamesPayload = (pills = [], quickFilters = {}) => {
  return pills.reduce((collect, { field, key }) => {
    const value = quickFilters[key]?.value;

    if (value?.length && MULTISELECT_FIELDS.includes(field.fieldType)) {
      const { propName } = getDisplayNamesPropNames(field.fieldType);

      if (propName) {
        collect[propName] = [
          ...new Set([...(collect[propName] || []), ...value]),
        ];
      }
    }
    return collect;
  }, {});
};

export const getLookup = (array) =>
  array.reduce(
    (collect, record) => ({
      ...collect,
      [record.id]: record,
    }),
    {}
  );
