import BasicModal from 'components/Modals/presets/BasicModal';
import { useTimeLine } from 'components/Timeline2';
import {
  timeLineConfig,
  timelineEventsMapper,
} from 'components/Timeline2/EventBlocks';
import UnknownTypeBlock from 'components/Timeline2/EventBlocks/View/UnknownType';
import { renderableEvents } from 'components/Timeline2/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getChosenBusiness } from 'store/authentication/selectors';

interface TimelineEventModalProps {
  modalProps: any;
  event: any;
  refetch: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
}

export const TimelineEventModal = ({
  modalProps,
  event,
  refetch,
  onClose,
}: TimelineEventModalProps) => {
  const { t } = useTranslation();
  const business = useSelector(getChosenBusiness);

  const { typeName } = event ?? {};

  const Block = useMemo(() => {
    return renderableEvents.has(typeName)
      ? timeLineConfig[typeName].component
      : UnknownTypeBlock;
  }, [typeName]);

  const processedEvent = useMemo(() => {
    if (!event) {
      return null;
    }

    return timelineEventsMapper(event, t);
  }, [event, t]);

  const { methods } = useTimeLine();

  if (!processedEvent) {
    return null;
  }

  return (
    <BasicModal
      heading={t('You Were Mentioned in a Comment')}
      buttonText={t('Close')}
      actionBtnColor="blue"
      {...modalProps}
      onConfirm={onClose}
      onHide={onClose}
      size={'large'}
      leftBtn={false}
    >
      <div className="mb-[15px]">
        <Block
          key={processedEvent.id}
          event={processedEvent}
          business={business}
          commentOpenDefault
          // If this modal is open, it means the timeline event wasn't returned on any visible timeline.
          // This means we don't need to do any refetches or optimistic updates on our main timelines,
          // but we do need to refetch the metadata for the single event we are viewing.
          onComment={async (comment: any) => {
            await methods.onComment({
              event: processedEvent,
              newComment: comment,
            });
            await refetch();
          }}
          index={0}
        />
      </div>
    </BasicModal>
  );
};
