import { useMemo, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import { DEFAULT_DELAY } from 'utility/config';
import { useInfinityFetch } from 'components/Inputs/Select/hooks';

export function useTypeaheadWithScroll({
  fetch,
  params,
  wrapperElement,
  keepPreviousData = true,
  queryKey = [],
  defaultHeight = 40,
  itemsBuffer = 10,
}) {
  const [search, setSearch] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const queryInfo = useMemo(
    () => ({
      queryKey: queryKey,
      fetch,
    }),
    [fetch, queryKey]
  );

  useDebounce(
    () => {
      setDebouncedValue(search);
    },
    DEFAULT_DELAY,
    [search]
  );

  const queryParams = useMemo(
    () => ({
      ...params,
      search: debouncedValue,
    }),
    [params, debouncedValue]
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfinityFetch({
    enabled: true,
    params: queryParams,
    ...queryInfo,
    keepPreviousData,
  });

  const { visibleItems: options } = useInfinityScroll({
    wrapperElement,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    objectToOption: null,
    chosenItems: [],
    defaultHeight: defaultHeight,
    itemsBuffer: itemsBuffer,
  });

  return { search, setSearch, options, isLoading, isFetching, refetch };
}
