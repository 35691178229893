export const CONTENT_TYPE = {
  applicationJavascript: 'application/javascript',
  applicationXml: 'application/xml',
  applicationXhtmlXml: 'application/xhtml+xml',
  applicationJson: 'application/json',
  applicationXwwwFormUrlencoded: 'application/x-www-form-urlencoded',
  textPlain: 'text/plain',
  textHtml: 'text/html',
  textXml: 'text/xml',
  textCsv: 'text/csv',
};

export const CUSTOM_CONTENT_TYPE = {
  omit: 'omit',
  custom: 'custom',
};

export const CONTENT_TYPE_HEADER = 'content-type';

export const HTTP_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const ACTION_TYPES = {
  start: 'start',
  pause: 'pause',
  cancel: 'cancel',
};
