import { useForceUpdate } from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/useForceUpdate';
import useField from 'hooks/useField';
import { useEffect, useMemo } from 'react';
import { getLeftItems, getRightItems } from '../helpers';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { RELATED_ENTITITY_QUICK_FILTER_FIELD } from '../constants';

export const useDraggableColumns = (settings, categorizedFields, fields) => {
  const { t } = useTranslation();
  const [forceKey, forceUpdate] = useForceUpdate();

  const [rightItems, setRightItems] = useField(
    () => getRightItems(settings, fields, t),
    [settings, fields, t]
  );

  useEffect(() => {
    forceUpdate();
  }, [settings, forceUpdate]);

  const leftItems = useMemo(
    () =>
      [
        {
          id: uuidv4(),
          fieldId: RELATED_ENTITITY_QUICK_FILTER_FIELD,
          label: `${t('Relationship Field')}: ${t('Field Name')}`,
          defaultLabel: `${t('Relationship Field')}: ${t('Field Name')}`,
        },
      ].concat(getLeftItems(categorizedFields, fields)),
    [categorizedFields, fields, t]
  );

  return {
    key: forceKey,
    leftItems,
    rightItems,
    onChange: setRightItems,
  };
};
