import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { gutters } from 'app/spacing';
import BackButton from 'components/Button/BackButton';
import LoadingButton from 'components/Button/LoadingButton';
import { maybeTrackBuilderSave } from 'utility/analytics';

const SAVE = 'save';
const SAVE_AND_CLOSE = 'save-and-close';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ChildContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBackButton = styled(BackButton)`
  margin-right: ${gutters.spacing()}px;
`;

const SaveButton = styled(LoadingButton)`
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
`;

export const PageBuilderHeader = ({
  saving = false,
  centerContent = null,
  rightActionButton = null,
  onSave,
  onSaveAndClose,
  onGoBack,
}) => {
  const { t } = useTranslation();
  const [saveType, setSaveType] = useState(null);

  const handleSave = useCallback(async () => {
    setSaveType(SAVE);
    maybeTrackBuilderSave('library_templates');
    await onSave?.();
  }, [onSave]);

  const hanldeSaveAndClose = useCallback(() => {
    setSaveType(SAVE_AND_CLOSE);
    maybeTrackBuilderSave('library_templates');
    onSaveAndClose?.();
  }, [onSaveAndClose]);

  return (
    <Container>
      <ChildContainer>
        <StyledBackButton variant="text" color="blue" onClick={onGoBack}>
          {t('back')}
        </StyledBackButton>
        {centerContent}
      </ChildContainer>
      <ChildContainer>
        <SaveButton
          disabled={saving}
          loading={saving && saveType === SAVE}
          minWidth={80}
          onClick={handleSave}
        >
          {t('Save')}
        </SaveButton>
        <SaveButton
          disabled={saving}
          loading={saving && saveType === SAVE_AND_CLOSE}
          minWidth={122}
          onClick={hanldeSaveAndClose}
        >
          {t('Save & Close')}
        </SaveButton>
        {rightActionButton}
      </ChildContainer>
    </Container>
  );
};

PageBuilderHeader.propTypes = {
  onSave: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  centerContent: PropTypes.elementType,
  saving: PropTypes.bool,
  rightActionButton: PropTypes.elementType,
};
