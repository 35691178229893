import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import Select from 'components/Kizen/Select';
import Input from 'components/Kizen/Input';
import { gutters } from 'app/spacing';
import { Text, fontSizes } from 'app/typography';
import { grayScale, colorsSecondary } from 'app/colors';

const Wrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

const InputLimit = styled.div`
  margin-top: ${gutters.spacing(2)}px;
  font-size: ${fontSizes.small};
  font-style: italic;

  ${({ error }) => `
    color: ${error ? colorsSecondary.red.dark : grayScale.dark};
  `}
`;

const StyledRow = styled(Row)`
  margin-bottom: ${gutters.spacing(4)}px !important;
`;

export default function RatingOptions({
  updateField,
  updateValidation,
  formData,
  data,
}) {
  const { t } = useTranslation();
  const [maxValue, setMaxValue] = useState(
    formData.rating
      ? { value: formData.rating.maxValue, label: formData.rating.maxValue }
      : { value: 5, label: '5' }
  );
  const [minLabel, setMinLabel] = useState(
    (formData.rating && formData.rating.minLabel) || t('Unsatisfied')
  );
  const [maxLabel, setMaxLabel] = useState(
    (formData.rating && formData.rating.maxLabel) || t('Satisfied')
  );

  const isValid =
    maxValue &&
    minLabel?.trim() &&
    maxLabel?.trim() &&
    minLabel.length <= 15 &&
    maxLabel.length <= 15;

  useEffect(() => {
    updateValidation(isValid);
  }, [isValid, updateValidation]);

  useEffect(() => {
    updateField('rating', {
      minValue: 1,
      maxValue: maxValue.value,
      minLabel,
      maxLabel,
      meta: {},
    });
  }, [maxValue, minLabel, maxLabel, updateField]);

  return (
    <Wrapper>
      <StyledRow>
        <Col>
          <Text>{t('Choose Number of Options')}</Text>
          <Select
            disabled={!!data.field}
            fullWidth
            value={maxValue}
            options={[
              { value: 5, label: '5' },
              { value: 10, label: '10' },
            ]}
            onChange={setMaxValue}
          />
        </Col>
        {/* Placeholder */}
        <Col />
      </StyledRow>
      <StyledRow>
        <Col>
          <Text>{t('Minimum Label')}</Text>
          <Input
            data-qa-field-wizard-input="Minimum Label"
            value={minLabel}
            onChange={setMinLabel}
            error={minLabel.length > 15}
          />
          <InputLimit error={minLabel.length > 15}>
            {`15 ${t('Character Limit')}`}
          </InputLimit>
        </Col>
        <Col>
          <Text>{t('Maximum Label')}</Text>
          <Input
            data-qa-field-wizard-input="Maximum Label"
            value={maxLabel}
            onChange={setMaxLabel}
            error={maxLabel.length > 15}
          />
          <InputLimit error={maxLabel.length > 15}>
            {`15 ${t('Character Limit')}`}
          </InputLimit>
        </Col>
      </StyledRow>
    </Wrapper>
  );
}

RatingOptions.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  formData: PropTypes.object,
  data: PropTypes.object,
};

RatingOptions.defaultProps = {
  formData: {},
  data: {},
};
