import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { borderRadii, gutters } from 'app/spacing';

export const ButtonImage = styled.div`
  width: 191px; // specific width so that the image matches our designs
  height: 46px;
  cursor: pointer;

  background: url(${({ standard }) => standard});

  &:hover {
    background: url(${({ hover }) => hover});
    background-size: contain;
  }

  background-size: contain;
`;

export const ProviderWrapper = styled.div`
  margin-top: ${({ compact }) => gutters.spacing(compact ? 0 : 4)}px;
`;

export const StyledLink = styled.a`
  font-size: 1em;
`;

export const StyledDisclaimer = styled(KizenTypography)`
  margin-top: ${({ compact }) => gutters.spacing(compact ? 0 : 3)}px;
`;

export const ProviderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProviderButton = styled.div`
  && {
    cursor: pointer;
    outline: none;
    width: 179px;
    height: 80px;
    padding: 0;
    color: ${colorsButton.iconGray.default};
    && p {
      color: ${colorsButton.iconGray.default};
    }

    background: #ffffff;
    text-decoration: none;

    border: 1px solid ${grayScale.medium};

    box-sizing: border-box;
    border-radius: ${borderRadii.small};
    flex-direction: column;
    display: flex;
    > div {
      width: 100%;
      :first-of-type {
        display: flex;
        flex: 0 0 55px;
        justify-content: center;
        align-items: center;
      }
      :last-of-type {
        flex: 0 0 25px;
        display: flex;
        justify-content: center;
        align-items: top;
      }
    }
  }

  &&:hover {
    border: 1px solid ${colorsButton.blue.hover};
  }

  ${({ active }) =>
    active
      ? css`
          && {
            border: 1px solid ${colorsButton.blue.hover};

            && {
              p {
                color: ${colorsButton.blue.hover};
              }
            }
          }
        `
      : ''}
`;
