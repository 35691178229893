import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { borderRadii, gutters } from '../../../app/spacing';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import BaseInput from './BaseInput';
import BaseInputLayout from './BaseInputLayout';
import Validation, { useValidation } from '../Validation';
import { applyRef, validateProp } from '../props';

export const OutlineInputLayout = styled(BaseInputLayout)`
  height: 36px;
  padding-left: ${gutters.spacing(2, 2)}px;
  padding-right: ${gutters.spacing(2, 2)}px;
  border-radius: ${borderRadii.small};
`;

const OutlineTextInput = React.forwardRef((props, ref) => {
  // props was spread on MaybeInputControl previously, but that causes the event handlers to fire twice
  const { onBlur, onChange, onFocus, onKeyPress, ...inputControlProps } = props;
  const {
    value,
    error,
    disabled,
    startAdornment,
    endAdornment,
    placeholder,
    className,
    Input,
    inModal,
    errorPlacement,
    style, // pull out style as we don't want it passed via others into the BaseInput
    errorCallback,
    validateOnFocus = false,
    ...others
  } = props;
  const validationRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(validationRef, el);
      applyRef(ref, el);
    },
    [ref]
  );
  const [focused, setFocused] = useState(false);
  const [validation, validationProps] = useValidation(validationRef, props);
  useEffect(() => {
    if (others.forceValidation) {
      validation.onSubmission();
    }
  }, [others.forceValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  // send the validation error stae back to the <DateTimeInput />
  useEffect(() => {
    errorCallback?.(validation.error);
  }, [validation.error, errorCallback]);

  return (
    <MaybeInputControl variant="outline" {...inputControlProps}>
      <OutlineInputLayout
        ref={mergeRef}
        className={!hasInputControl(props) && className}
        disabled={disabled}
        error={error || validation.error}
        focused={focused}
      >
        {startAdornment &&
          React.cloneElement(startAdornment, {
            variant: 'outline',
            start: true,
          })}
        <BaseInput
          as={Input}
          value={value}
          disabled={disabled}
          data-placeholder={placeholder}
          placeholder={placeholder}
          {...others} // spread others here since the handlers are destructed separately for the inputControlProps
          onChange={(ev) => {
            if (onChange) onChange(ev.target.value, ev);
          }}
          onFocus={(ev) => {
            setFocused(true);
            if (onFocus) onFocus(ev);
            if (validation.onBlur && validateOnFocus) validation.onBlur(ev);
          }}
          onBlur={(ev) => {
            setFocused(false);
            if (onBlur) onBlur(ev);
            if (validation.onBlur) validation.onBlur(ev);
          }}
          // eslint-disable-next-line consistent-return
          onKeyPress={(ev) => {
            if (onKeyPress) onKeyPress(ev);
            if (validation.onKeyPress) return validation.onKeyPress(ev);
          }}
        />
        {endAdornment &&
          React.cloneElement(endAdornment, {
            variant: 'outline',
            end: true,
          })}
      </OutlineInputLayout>
      <Validation
        inModal={inModal}
        errorPlacement={errorPlacement}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

OutlineTextInput.displayName = 'OutlineTextInput';

OutlineTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  startAdornment: PropTypes.element,
  endAdornment: PropTypes.element,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  Input: PropTypes.elementType,
  validate: validateProp, // Consumed by useValidate()
  inModal: PropTypes.bool,
  errorPlacement: PropTypes.string,
  forceValidation: PropTypes.number,
};

OutlineTextInput.defaultProps = {
  value: undefined,
  onChange: null,
  error: null,
  disabled: null,
  startAdornment: null,
  endAdornment: null,
  placeholder: null,
  onFocus: null,
  onBlur: null,
  onKeyPress: null,
  Input: undefined,
  validate: null,
  inModal: null,
  errorPlacement: undefined,
  forceValidation: 0,
};

export default OutlineTextInput;
