import PropTypes from 'prop-types';
import { FIELDS, getStatusColor } from '../helpers';
import { StatusLongTextElipsis } from '../styles';

export const StatusBlock = ({ item, ...others }) => {
  const showStatusCircle = item.value && item.metaInfo?.options;
  return (
    <StatusLongTextElipsis
      {...others}
      showStatusCircle={showStatusCircle}
      circleColor={showStatusCircle && getStatusColor(item)}
    >
      {FIELDS[item.type](item)}
    </StatusLongTextElipsis>
  );
};

StatusBlock.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.object,
  }).isRequired,
};
