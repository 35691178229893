import { ModalBody, ModalHeader } from 'components/Modals';
import EmailInteractionStatsModalAdapter from 'pages/Dashboard/components/Charts/EmailInteractionStats/adapters/ModalAdapter';
import { useTranslation } from 'react-i18next';
import { StyledModal } from './styles';

export default function EmailInteractionStatsModal({
  onHide,
  isLoading,
  message,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <StyledModal onHide={onHide} {...props} size="medium">
      <ModalHeader onClickClose={onHide}>
        {t('Email Broadcast Stats')}
      </ModalHeader>
      <ModalBody>
        <EmailInteractionStatsModalAdapter
          isLoading={isLoading}
          message={message}
          onError={onHide}
        />
      </ModalBody>
    </StyledModal>
  );
}
