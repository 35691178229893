import React, { useRef } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { StyledDivider } from '@kizen/page-builder/nodes/Divider';
import { DividerContainer } from '@kizen/page-builder/nodes/containers';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { gutters } from '@kizen/page-builder/internal/app/spacing';
import {
  useBuilderContext,
  useIsInBuilderContext,
} from 'components/PageBuilder/BuilderContext';
import { DividerToolbar } from '../../../StandaloneToolBar/presets/DividerToolbar';
import Control from '../Control';
import { useDraggingType } from '../useDraggingType';
import { useShowControls } from '../useShowControls';

const BuilderDividerContainer = styled(DividerContainer)`
  ${({ containerPaddingTop, containerPaddingBottom, isActive }) =>
    containerPaddingBottom === '0' &&
    containerPaddingTop === '0' &&
    css`
      :hover {
        padding-top: ${gutters.spacing(2)}px;
        padding-bottom: ${gutters.spacing(2)}px;
      }
      ${isActive &&
      css`
        padding-top: ${gutters.spacing(2)}px;
        padding-bottom: ${gutters.spacing(2)}px;
      `}
    `}
`;

const PageBuilderDivider = styled(StyledDivider)`
  // The divider's width is being set on the Control component so the Control border shrinks along with
  // the content - force the actual divider's width to 100% so we do not apply a percentage width twice.
  width: 100%;
`;

const AlignmentAndWidthContainer = styled.div`
  display: flex;

  ${({ alignment = 'center' }) => css`
    justify-content: ${alignment};
  `}
`;

export const Divider = (props) => {
  const { t } = useTranslation();
  const { borderStyle, color, size, width, alignment, ...rest } = props;
  const ref = useRef();
  const { activeNode, modalLayer, canvasScrollContainer } = useBuilderContext();
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
  }));
  const {
    id,
    connectors: { connect },
  } = useNode((node) => ({ id: node.id }));
  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const isInBuilderContext = useIsInBuilderContext();

  return (
    <>
      {activeNode?.id === id && (
        <DividerToolbar
          target={ref.current}
          node={activeNode}
          modalLayer={modalLayer}
          scrollContainer={canvasScrollContainer}
        />
      )}
      <AlignmentAndWidthContainer alignment={alignment}>
        <Control
          ref={connect}
          label={t('Content')}
          show={showControls}
          color={colorsPrimary.green.dark}
          mayHaveChildren={false}
          width={`${width}%`}
          fullWidth={true}
          {...rest}
        >
          <BuilderDividerContainer
            ref={ref}
            draggingType={draggingType}
            alignment={props.alignment}
            isActive={activeNode?.id === id}
            isInBuilderContext={isInBuilderContext}
            {...props}
          >
            <PageBuilderDivider
              borderStyle={borderStyle}
              color={color}
              size={size}
              width={width}
            />
          </BuilderDividerContainer>
        </Control>
      </AlignmentAndWidthContainer>
    </>
  );
};
