import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import useModal from 'components/Modals/useModal';
import { SummarizedMultiSelectModal } from '../SummarizedMultiselect/Modal';
import useField from 'hooks/useField';
import { SummarizedPlaceholder } from '../SummarizedMultiselect/SummarizedPlaceholder';
import TextControl from './TextControl';
import { getTooltipText } from '../SummarizedMultiselect/helpers';
import { OverlayTrigger } from 'react-bootstrap2';
import Tooltip from 'components/Kizen/Tooltip';
import { CO_RELATION_TYPE_PRIMARY_REVERSE } from 'components/Modals/utilities';

const SummarizedPlaceholderWithTooltip = ({
  showToolTip,
  tooltipLabel,
  placement,
  popperConfig,
  children,
}) => {
  return showToolTip ? (
    <OverlayTrigger
      overlay={<Tooltip label={tooltipLabel} />}
      placement={placement}
      popperConfig={popperConfig}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  );
};

export const SummarizedMultiselectInline = ({
  value,
  entity,
  field,
  onSubmit,
  autoFocus,
  onAutoFocus,
  disabled,
  viewable = true,
  tooltipLabel,
  popperConfig,
  placement,
  showToolTip,
  className,
  hoverable = true,
  readOnly,
  handleUpdateTableRecords,
  enableLink = true,
  ...others
}) => {
  const { t } = useTranslation();
  const prevFocusRef = useRef(false);

  const [stagedValue, setStagedValue] = useField(value);

  const [modalProps, , modal] = useModal();

  const handleSubmit = (next) => {
    setStagedValue(next);
    onSubmit(next).then(() => {
      if (field.relation.relationType === CO_RELATION_TYPE_PRIMARY_REVERSE) {
        handleUpdateTableRecords?.();
      }
    });
  };
  const textRef = useRef();
  const onAutoFocusRef = useRef();
  onAutoFocusRef.current = onAutoFocus;

  useEffect(() => {
    if (autoFocus) {
      if (prevFocusRef.current !== autoFocus) {
        modal.show();
      }
      if (onAutoFocusRef.current) {
        onAutoFocusRef.current(textRef.current);
      }
    }
    prevFocusRef.current = autoFocus;
  }, [autoFocus, modal]);

  return (
    <>
      <TextControl
        icon={viewable ? 'visible' : null}
        iconSize="12px"
        className={className}
        hoverable={hoverable}
        tooltipDisabled={!viewable}
        title={getTooltipText(field, t)}
        ref={textRef}
        onClick={(ev) => {
          ev.preventDefault();
          if (viewable) {
            modal.show();
          }
        }}
      >
        <SummarizedPlaceholderWithTooltip
          showToolTip={showToolTip}
          tooltipLabel={tooltipLabel}
          placement={placement}
          popperConfig={popperConfig}
        >
          <KizenTypography
            type={viewable && enableLink ? 'link' : ''}
            {...others}
          >
            <SummarizedPlaceholder
              {...stagedValue}
              field={field}
              showVisibleIcon={false}
            />
          </KizenTypography>
        </SummarizedPlaceholderWithTooltip>
      </TextControl>
      {modalProps.show && (
        <SummarizedMultiSelectModal
          show
          entity={entity}
          field={field}
          value={stagedValue}
          onChange={handleSubmit}
          modalProps={modalProps}
          readOnly={!!disabled || !!readOnly}
        />
      )}
    </>
  );
};
