import styled from '@emotion/styled';
import { grayScale, colorsButton, colorsPrimary } from 'app/colors';
import GridSelector from 'components/Kizen/GridSelector';
import { gutters } from 'app/spacing';
import { fontSizes, fontWeights } from 'app/typography';

export const StyledAreaBox = styled.div({
  minWidth: 182,
  minHeight: 103,
  backgroundColor: grayScale.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const StyledGridSelector = styled(GridSelector)({
  width: 182,
  height: 103,
  marginBottom: 0,
  textAlign: 'center',
  marginRight: gutters.standard,
  padding: 10,
  '&:last-of-type': {
    marginRight: 0,
  },
  '&.active span, &.active > i': {
    color: colorsButton.blue.default,
  },
  '& > span': {
    fontSize: fontSizes.small,
    marginTop: 10,
    fontWeight: fontWeights.bold,
    lineHeight: '13px',
  },
  '&:hover, &:hover > i': {
    cursor: 'default',
    borderColor: grayScale.mediumDark,
    color: grayScale.mediumDark,
  },
  '&.selected:hover, &.selected:hover > i': {
    borderColor: colorsPrimary.blue.dark,
    color: colorsPrimary.blue.dark,
  },
});
