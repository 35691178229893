import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { breakpoints, layers } from 'app/spacing';
import { colorsButton, grayScale, shadowLight } from 'app/colors';
import { Text, fontSizes, fontWeights, KizenTypography } from 'app/typography';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import { contentWidthCss } from 'components/Layout/PageContentWidth';

const desktopBreak = breakpoints.lg;
const tabletBreak = breakpoints.md;

const contactPillboxBreakOneColumn = 845;
const contactPillboxBreakTwoColumn = 1265;
const customObjectsPillboxBreakTwoColumn = 1046;

export const InfoBlockTypes = {
  ADDRESS: 'ADDRESS',
  TEAM: 'TEAM',
  ACTIVITIES: 'ACTIVITIES',
};

export const PILLBOX_CONTACT = 'PILLBOX_CONTACT';
export const PILLBOX_CUSTOM_OBJECT = 'PILLBOX_CUSTOM_OBJECT';

const contentWidthStyles = css`
  ${contentWidthCss}
`;

export const ContentWidth = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${contentWidthStyles};
  flex: 1;
  flex-direction: column;
  @media (min-width: ${desktopBreak + 1}px) {
    flex-direction: row;
  }
`;

export const StyledCard = styled(Card)`
  margin: unset;
  width: 100%;
  max-width: unset;
`;

export const PaneContainer = styled(StyledCard)`
  padding: 20px;
  display: none;

  /* 
    + 1 is to sync this rule with the corresponding
    one on the ActionBar (icon menu for accessing same
    controls as displayed in MainContent on mobile): see ControlBar.js, MobileContent component
  */
  @media (min-width: ${tabletBreak + 1}px) {
    display: block;
  }
`;

export const MainContent = styled.div`
  flex: 2;
  width: 100%;
  min-width: 0;
  margin-bottom: 35px;

  && > * {
    margin-bottom: 20px;
  }
`;

export const LeftPane = styled.div`
  flex: 1;
  width: 100%;
  flex-direction: column;
  z-index: ${layers.content(0, 3)};

  @media (max-width: ${tabletBreak}px) {
    margin-top: 5px;
  }

  @media (min-width: ${desktopBreak + 1}px) {
    margin-right: 20px;
    min-width: 400px;
    max-width: 400px;
  }
`;

export const LeftPaneCard = styled(StyledCard)`
  flex: 1;
  background: ${grayScale.white};
  ${shadowLight};

  margin-bottom: 15px;

  @media (min-width: ${tabletBreak + 1}px) {
    padding: 20px 15px;
    margin-bottom: 20px;
  }

  @media (min-width: ${desktopBreak + 1}px) {
    max-width: 400px;
    margin-bottom: 20px;
    padding: 20px;
  }
`;

export const PillNav = styled.div`
  display: flex;
  flex: 1;
  min-height: 46px;

  ${({ extendedPanel = true }) => css`
    margin: ${extendedPanel ? `0 -7px 20px` : `-10px -7px`};
  `}

  flex-wrap: wrap;

  ${({ context }) => {
    if (context === PILLBOX_CUSTOM_OBJECT) {
      return css`
        align-content: stretch;
        align-items: stretch;

        @media (min-width: ${desktopBreak + 1}px) {
          align-content: space-between;
          align-items: reset;
        }
        @media (min-width: ${customObjectsPillboxBreakTwoColumn + 1}px) {
          align-content: stretch;
          align-items: stretch;
        }
      `;
    }
    // default is PILLBOX_CONTACT
    return css`
      align-content: space-between;

      @media (min-width: ${contactPillboxBreakOneColumn + 1}px) {
        align-content: stretch;
        align-items: stretch;
      }
      @media (min-width: ${desktopBreak + 1}px) {
        align-content: space-between;
        align-items: reset;
      }
      @media (min-width: ${contactPillboxBreakTwoColumn + 1}px) {
        align-content: stretch;
        align-items: stretch;
      }
    `;
  }}
`;

export const PillBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ context }) => {
    if (context === PILLBOX_CUSTOM_OBJECT) {
      return css`
        flex-basis: auto;
        @media (min-width: ${desktopBreak + 1}px) {
          flex-basis: calc(100% / 2);
        }
        @media (min-width: ${customObjectsPillboxBreakTwoColumn + 1}px) {
          flex-basis: auto;
        }
      `;
    }
    // default is PILLBOX_CONTACT
    return css`
      flex-basis: calc(100% / 3);
      @media (min-width: ${contactPillboxBreakOneColumn + 1}px) {
        flex-basis: auto;
      }
      @media (min-width: ${desktopBreak + 1}px) {
        flex-basis: calc(100% / 3);
      }
      @media (min-width: ${contactPillboxBreakTwoColumn + 1}px) {
        flex-basis: auto;
      }
    `;
  }}
`;

// we have to adjust the margin and padding on the start animation button
// so that in stacked mode the combined width of the buttons doesn't force
// the block to be too wide
export const LastPillBox = styled.div`
  flex: 1 1 auto;
  > div {
    position: relative;
    display: flex;
    justify-content: flex-end;
    > button {
      padding-left: 0 !important;
      margin: 0.5rem 0;
    }
  }
  display: flex;
  align-items: center;

  ${({ context }) => {
    if (context === PILLBOX_CUSTOM_OBJECT) {
      return css`
        flex-basis: auto;
        justify-content: flex-end;

        @media (min-width: ${desktopBreak + 1}px) {
          > div > button {
            margin: 0.5rem 0;
          }
          display: flex;
          flex-basis: calc(100% / 2);
          align-items: center;
          justify-content: center;
        }

        @media (min-width: ${customObjectsPillboxBreakTwoColumn + 1}px) {
          flex-basis: auto;
          justify-content: flex-end;
          > div > button {
            margin: 0.5rem 0.75rem;
          }
        }
      `;
    }
    // default is PILLBOX_CONTACT
    return css`
      flex-basis: calc(100% / 3);
      justify-content: center;

      @media (min-width: ${contactPillboxBreakOneColumn + 1}px) {
        flex-basis: auto;
        justify-content: flex-end;
        > div > button {
          margin: 0.5rem 0.75rem;
        }
      }

      @media (min-width: ${desktopBreak + 1}px) {
        > div > button {
          margin: 0.5rem 0;
        }
        display: flex;
        flex-basis: calc(100% / 3);
        align-items: center;
        justify-content: center;
      }

      @media (min-width: ${contactPillboxBreakTwoColumn + 1}px) {
        flex-basis: auto;
        justify-content: flex-end;
        > div > button {
          margin: 0.5rem 0.75rem;
        } `;
  }}
`;

export const Pill = styled.button`
  display: flex;
  align-items: center;
  position: relative;

  // Uneven values here to achieve 45px gap
  margin: 0 8px 0 7px;
  padding: 4px 15px;
  border: none;
  border-radius: 25px;
  background: none;
  font-size: ${fontSizes.text};
  min-height: 32px;
  &:hover {
    color: ${colorsButton.blue.hover};
  }

  &:focus {
    outline: none;
  }

  ${({ open }) =>
    open &&
    css`
      color: ${colorsButton.blue.hover};
    `}

  ${({ active }) =>
    active &&
    css`
      background: ${colorsButton.blue.default};
      color: ${grayScale.white};

      i {
        color: ${grayScale.white};
      }

      &:hover {
        background: ${colorsButton.blue.default};
        color: ${grayScale.white};
        cursor: default;
      }
    `}
`;

export const LogActivityPill = styled(Pill)`
  &:hover > .PillIcon {
    color: ${({ iconHover }) => css`
      ${iconHover}
    `};
  }

  & > .PillIcon {
    ${({ open }) =>
      open &&
      css`
        color: ${colorsButton.blue.hover};
      `}
  }
`;

export const SubmitButton = styled(Button)`
  margin: 20px 0 0;
  padding-left: 25px;
  padding-right: 25px;
`;

export const LabelPaneHeader = styled(Text)`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.micro};
  margin-bottom: 0;
`;

export const NotesLabel = styled(Text)`
  font-size: ${fontSizes.micro};
  margin-bottom: 0;
`;

export const ActionMenu = styled.div`
  width: 250px;
  z-index: ${layers.content(0, 5)};
  left: -10px !important;

  /* Breakpoint consistency with MobileContent in ControlBar (layout change of ActionBar, specifically) */
  @media (min-width: ${tabletBreak + 1}px) {
    margin-top: -10px;
    left: unset;
  }

  .Select__menu {
    max-height: 240px;
    padding-bottom: 0;
  }
`;

export const LogActivitySelector = styled(ActionMenu)`
  width: 240px;

  .Select__menu {
    max-height: 360px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  flex-direction: row;
`;

export const InfoBlockItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > p {
    margin-bottom: 7px;
    line-height: 20px;
  }
`;

// used in leadinfo and workflow/pipeline
export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  // the header is supposed to be 50px high but the left
  ${({ margin }) =>
    margin &&
    css`
      padding: 0 15px 15px;
      @media (min-width: ${breakpoints.md}px) {
        padding: 0 20px 20px;
      }
    `}

  ${({ scrolled }) =>
    scrolled &&
    css`
      ${shadowLight};
      border-bottom: 1px solid ${grayScale.mediumLight};
    `}
`;

export const InfoBlockLink = styled(KizenTypography)`
  text-align: center;
  font-size: ${fontSizes.text};
  font-weight: ${fontWeights.regular};
  margin: 0;
  text-transform: none;
  margin-bottom: 0 !important;
  line-height: 1 !important;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
      color: ${colorsButton.blue.default};
      &:hover {
        text-decoration: underline;
      }
    `}
`;
