import { useState, useEffect } from 'react';
import { flushSync } from 'react-dom';

export default function useEndsOfScroll(ref, deps = []) {
  const [ends, setEnds] = useState([false, false, false, false]);
  useEffect(() => {
    const { current: el } = ref;

    const handler = () => {
      if (el) {
        const top = el.scrollTop === 0;
        const right =
          el.scrollWidth - Math.ceil(el.scrollLeft) <= el.clientWidth;
        const bottom =
          el.scrollHeight - Math.ceil(el.scrollTop) <= el.clientHeight;
        const left = el.scrollLeft === 0;
        flushSync(() => {
          setEnds((currentEnds) => {
            if (
              top === currentEnds[0] &&
              right === currentEnds[1] &&
              bottom === currentEnds[2] &&
              left === currentEnds[3]
            ) {
              return currentEnds;
            }
            return [top, right, bottom, left];
          });
        });
      }
    };

    // Wrapping in setTimeout ensures the scrollHeight & scrollWidth values are accurate
    // on the first render. Without this scrollHeight==clientHeight and scrollWidth==clientWidth
    // for content that is scrollable causing the ScrollFadeContainer gradients not to show.
    setTimeout(handler, 0);

    if (el) {
      el.addEventListener('scroll', handler, {
        capture: false,
        passive: true,
      });
      // When resizing the screen end of right scroll wouldn't be registered in some cases
      // which would result in showing right table gradient even when scrollbar is not present
      // el.scrollWidth - el.scrollLeft === el.clientWidth would be false because widths would be wrong
      // Adding resize listener to fix right scroll calculation
      window.addEventListener('resize', handler, { passive: true });
    }

    return () => {
      if (el) {
        el.removeEventListener('scroll', handler);
        window.removeEventListener('resize', handler);
      }
    };
  }, [ref, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  return ends;
}
