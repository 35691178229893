import { useState, useEffect } from 'react';
import { QueryClient, useQueryClient } from 'react-query';
import { camelToSnakeCaseKeys } from 'services/helpers';
import { fetchUrlConfigWithReactQuery } from 'ts-filters/utils';
import { Field, evalRules, getFieldValues, getRuleEvalMap } from './eval';
import { FieldLike, VisibilityRule } from './types';

const attachIsValidToRuleFilters = async (
  queryClient: QueryClient,
  visibilityRules: VisibilityRule[]
): Promise<VisibilityRule[]> => {
  const result = [];

  for await (const vr of visibilityRules) {
    const filters = [];

    for await (const filter of vr.rule.filters) {
      let is_valid = true;
      const urlConfig = filter.view_model[0][1];

      try {
        const field = await fetchUrlConfigWithReactQuery(
          queryClient,
          urlConfig
        );
        if (field.is_hidden) {
          is_valid = false;
        }
      } catch (error) {
        is_valid = false;
      }

      filters.push({ ...filter, is_valid });
    }
    result.push({ ...vr, rule: { ...vr.rule, filters } });
  }

  return result;
};

export const useActivityRules = (
  visibilityRules: VisibilityRule[],
  values: Record<string, any> | Field[]
) => {
  const queryClient = useQueryClient();
  const [loadedVisibilityRules, setLoadedVisibilityRules] = useState(
    camelToSnakeCaseKeys(visibilityRules)
  );
  const [loading, setLoading] = useState(false);
  const rules = getRuleEvalMap(loadedVisibilityRules);
  const ruleResults = evalRules(
    rules,
    Array.isArray(values) ? getFieldValues(values) : values
  );

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const res = await attachIsValidToRuleFilters(
          queryClient,
          camelToSnakeCaseKeys(visibilityRules)
        );
        setLoadedVisibilityRules(res);
      } finally {
        setLoading(false);
      }
    };

    if (Array.isArray(visibilityRules)) {
      load();
    }
  }, [queryClient, visibilityRules]);

  const isRuleActiveForField = (field: FieldLike) => {
    return !loading && (!rules.has(field.id) || ruleResults[field.id]);
  };

  return { isRuleActiveForField, loading };
};
