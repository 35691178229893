import { createContext } from 'react';

export const ForceFieldRevalidationContext = createContext<{
  alwaysRevalidate: boolean;
}>({
  alwaysRevalidate: false,
});

export const ForceFieldRevalidationProvider = ({
  children,
}: {
  children: any;
}) => {
  return (
    <ForceFieldRevalidationContext.Provider value={{ alwaysRevalidate: true }}>
      {children}
    </ForceFieldRevalidationContext.Provider>
  );
};
