import { useSelector } from 'react-redux';

export const useCanScheduleActivities = (): boolean => {
  const access = useSelector((s: any) => s.authentication.access);

  return access?.scheduled_activities?.associated_records?.edit ?? false;
};

export const useCanViewScheduledActivities = (): boolean => {
  const access = useSelector((s: any) => s.authentication.access);

  return access?.sections?.scheduled_activities_section?.view ?? false;
};
