import { toastVariant, useToast } from 'components/ToastProvider';
import { useTranslation } from 'react-i18next';
import Automation2Service, {
  AUTOMATION_ACTION,
} from 'services/Automation2Service';
import { getToastMessage } from './helpers';
import { useCallback, useState } from 'react';

export const useSkipStepModal = (refetchExecutions) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [execution, setExecution] = useState(null);

  const showModal = useCallback((data) => {
    setExecution(data);
  }, []);

  const onHide = useCallback(() => {
    setExecution(null);
  }, []);

  const onConfirm = useCallback(
    async (branchPath) => {
      const messages = getToastMessage(
        AUTOMATION_ACTION.SKIP_STEP_AND_RESUME,
        t
      );
      try {
        await Automation2Service.skipStepAndResumeAutomation(
          execution.id,
          {
            skip_step_id: execution?.pausedOnStep?.id,
            continue_with_branch: branchPath,
          },
          { skipErrorBoundary: true }
        );
        showToast({
          variant: toastVariant.SUCCESS,
          message: messages.success,
        });

        onHide();
        refetchExecutions();
      } catch (error) {
        showToast({
          variant: toastVariant.FAILURE,
          message: messages.failure,
        });
      }
    },
    [onHide, showToast, execution, refetchExecutions, t]
  );

  return [
    !!execution,
    {
      onConfirm,
      onHide,
      isCondition: execution?.pausedOnStep?.type === 'condition',
      stepLabel: execution?.pausedOnStep?.label,
      isBranching: execution?.pausedOnStep?.branchingStep,
    },
    showModal,
  ];
};
