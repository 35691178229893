// class to handle session storage

export class SessionStorage {
  constructor(
    key = 'key',
    { serialize = JSON.stringify, deserialize = JSON.parse } = {}
  ) {
    this.key = key;
    this.serialize = serialize;
    this.deserialize = deserialize;
    try {
      this.sessionStorage = window.sessionStorage;
    } catch {
      this.sessionStorage = null;
    }
  }

  get val() {
    if (this.sessionStorage) {
      try {
        const val = this.sessionStorage.getItem(this.key);
        if (val) {
          return this.deserialize(val);
        }
      } catch {}
    }
    return null;
  }

  set val(val) {
    if (this.sessionStorage) {
      try {
        this.sessionStorage.setItem(this.key, this.serialize(val));
      } catch {}
    }
  }

  clear() {
    if (this.sessionStorage) {
      try {
        this.sessionStorage.removeItem(this.key);
      } catch {}
    }
  }
}
