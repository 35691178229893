import {
  StyledButtonSelectorWrapper,
  StyledButtonWrapper,
  StyledButtonSelector,
  StyledIcon,
  StyledI,
} from './styles';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

const GetIcon = ({ icon }) => {
  const color = { default: 'inherit', hover: 'inherit' };

  return icon?.className ? (
    <StyledI className={icon.className} />
  ) : (
    <StyledIcon
      className="ElementIcon"
      icon={icon?.value || 'bars-light'}
      color={color}
    />
  );
};

export const RelatedObjectButtonSelector = ({
  options = [],
  value,
  onChange,
}) => {
  return (
    <StyledButtonSelectorWrapper>
      {options.map((option) => (
        <StyledButtonWrapper key={option.value}>
          <StyledButtonSelector
            data-qa={`relation-button-${option.value}`}
            onClick={(e) => {
              e.preventDefault();
              onChange(option);
            }}
            {...option}
            selected={value?.value === option?.value}
          >
            <GetIcon
              icon={option.icon}
              selected={value?.value === option?.value}
            />
            <TextEllipsisWithTooltip>{option?.label}</TextEllipsisWithTooltip>
          </StyledButtonSelector>
        </StyledButtonWrapper>
      ))}
    </StyledButtonSelectorWrapper>
  );
};
