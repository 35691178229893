import { useMemo, useState, useRef, useCallback } from 'react';
import { breakpoints, isMobile, useWindowSize } from 'app/spacing';

/*
 * A hook for controlling the wizard state in the context of a modal. This includes validation and submission.
 *
 * @param {Function} onConfirm - Called with the wizard data when submitted from the modal, and a boolean
 * to indicate if this is an edit action, or a create action
 *
 * @param {Function} computeValid - Called with the wizard data whenever it is updated, and should return
 * either true or false to indicate if the data is valid and the modal can be submitted
 *
 * @param {Object} existing - Exisiting data that should be used to populate the wizard. If passed, the wizard
 * will be running in edit mode rather than create
 */
const useWizardInModal = (
  onConfirm = () => null,
  computeValid = () => false,
  existing
) => {
  const [data, setData] = useState({});
  const [canAutoScroll, setCanAutoScroll] = useState(true);
  const { width } = useWindowSize();
  const modalDataRef = useRef();
  const modalBodyRef = useRef();

  const valid = useMemo(() => {
    return !computeValid(data);
  }, [data, computeValid]);

  const handleSubmit = useCallback(() => {
    onConfirm(modalDataRef.current, Object.keys(existing ?? {}).length > 0);
  }, [onConfirm, existing]);

  const mobile = useMemo(() => {
    return isMobile(width, breakpoints.md);
  }, [width]);

  const handleScroll = useCallback((e) => {
    const scrollDistance = e.target.scrollHeight - e.target.clientHeight;
    if (scrollDistance === e.target.scrollTop) {
      setCanAutoScroll(true);
    } else {
      setCanAutoScroll(false);
    }
  }, []);

  return {
    modalProps: {
      disabled: !!valid,
      onConfirm: handleSubmit,
      typeOfContent: mobile ? 'new-wizard-mobile' : 'new-wizard',
      modalBodyProps: {
        onScroll: handleScroll,
        ref: modalBodyRef,
      },
    },
    wizardProps: {
      setData,
      ref: modalDataRef,
      canAutoScroll,
      setCanAutoScroll,
      modalBody: modalBodyRef,
    },
  };
};

export default useWizardInModal;
