import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { gutters } from 'app/spacing';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import TitleField from '../../fields/title';

const ScheduledActivitiesReportType = ({ defaultName, handleSetName }) => {
  const { state, entityName } = useContext(WizardStateContext.Context);
  const { name, reportType } = state;

  // Hide self if not scheduled activities
  if (reportType?.value !== REPORT_TYPES.SCHEDULED_ACTIVITIES) {
    return null;
  }

  return (
    <TitleField
      name={name}
      defaultName={defaultName}
      onChangeName={handleSetName}
      top={gutters.spacing(2, 2)}
      entityName={entityName}
    />
  );
};

ScheduledActivitiesReportType.propTypes = {
  defaultName: PropTypes.string,
  handleSetName: PropTypes.func.isRequired,
};

ScheduledActivitiesReportType.defaultProps = {
  defaultName: '',
};

export default ScheduledActivitiesReportType;
