import { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageSizing, ContentWidth } from 'components/Layout/PageContentWidth';

import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import EmailService from 'services/EmailService';
import TeamMemberService from 'services/TeamMemberService';
import CardSection from 'components/Layout/CardSection';
import SubscriptionLists from './SubscriptionLists';
import IntegratedEmail from './IntegratedEmail';
import Privacy from './Privacy';
import { Loader } from './styles';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useBlockedEmailsQuery } from './queries';
import { monitoringExceptionHelper } from 'sentry/helpers';
import { useQuery, useQueryClient } from 'react-query';
import { EXTERNAL_ACCOUNT_ALL } from 'queries/query-keys';

const defaultExternalFetchArgs = { ordering: 'team_member__name', search: '' };

const fetchExternalAccounts = async (fetchArgs) => {
  return TeamMemberService.getAllExternalAccounts(fetchArgs);
};

export default function PrivacyPage({ access, ...props }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showToast] = useToast();

  const [
    integratedEmailExternalAccountsArgs,
    setIntegratedEmailExternalAccountsArgs,
  ] = useState(defaultExternalFetchArgs);

  // subscription lists
  const [{ value: rawSubscriptionList }, updateSubscriptions] =
    useAsyncFnKeepLast(EmailService.getAllSubscriptions, []);

  useEffect(() => {
    // Initial fetch on mount
    updateSubscriptions();
  }, [updateSubscriptions]);

  const [subscriptionList, setSubscriptionList] = useState(rawSubscriptionList);

  useEffect(() => {
    if (rawSubscriptionList) {
      setSubscriptionList(rawSubscriptionList);
    }
  }, [rawSubscriptionList]);

  // blocked email
  const { data: rawBlockedEmails, refetch } = useBlockedEmailsQuery(
    access.privacy.edit
  );

  const updateBlockedEmails = useCallback(() => {
    access.privacy.edit && refetch();
  }, [access.privacy.edit, refetch]);

  useEffect(() => {
    updateBlockedEmails();
  }, [updateBlockedEmails]);

  const [blockedEmails, setBlockedEmails] = useState(rawBlockedEmails);

  useEffect(() => {
    if (rawBlockedEmails) {
      setBlockedEmails(rawBlockedEmails);
    }
  }, [rawBlockedEmails]);

  const saveBlockedEmails = async (updatedblockedEmails, errorMessage) => {
    try {
      const emails =
        await TeamMemberService.setBlockedEmails(updatedblockedEmails);
      setBlockedEmails(emails);
    } catch (error) {
      showToast({
        variant: toastVariant.FAILURE,
        message: `${errorMessage} ${t(
          'Please try again or contact Kizen support.'
        )}`,
      });
      throw error;
    }
  };

  const refetchExternalAccounts = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: EXTERNAL_ACCOUNT_ALL.ALL });
  }, [queryClient]);

  const { data: privacyExternalAccounts } = useQuery(
    [...EXTERNAL_ACCOUNT_ALL.FILTERED(defaultExternalFetchArgs)],
    () => fetchExternalAccounts(defaultExternalFetchArgs),
    {
      keepPreviousData: true,
    }
  );

  const handleFetchIntegratedEmailExternalAccounts = useCallback(() => {
    return fetchExternalAccounts(integratedEmailExternalAccountsArgs);
  }, [integratedEmailExternalAccountsArgs]);

  const {
    isLoading: integratedEmailExternalAccountsLoading,
    data: integratedEmailExternalAccounts,
  } = useQuery(
    [...EXTERNAL_ACCOUNT_ALL.FILTERED(integratedEmailExternalAccountsArgs)],
    handleFetchIntegratedEmailExternalAccounts,
    {
      keepPreviousData: true,
    }
  );

  const chosenBusinessId = useSelector(
    ({ authentication }) =>
      !!authentication.chosenBusiness && authentication.chosenBusiness.id
  );
  const handleAccountDelete = async (id) => {
    try {
      await TeamMemberService.deleteExternalAccount(id, {
        skipErrorBoundary: true,
      });

      refetchExternalAccounts();
      updateBlockedEmails();
      showToast({
        variant: toastVariant.SUCCESS,
        message: t('The integrated email was successfully deleted.'),
      });
    } catch (error) {
      showToast({
        variant: toastVariant.FAILURE,
        message: t('The integrated email could not be deleted.'),
      });
      monitoringExceptionHelper(error);
    }
  };

  if (
    !subscriptionList ||
    !blockedEmails ||
    !privacyExternalAccounts ||
    !integratedEmailExternalAccounts
  ) {
    return <Loader loading />;
  }

  return (
    <PageSizing>
      {access.subscriptionLists.edit ? (
        <SubscriptionLists
          subscriptionList={subscriptionList || []}
          setSubscriptionList={setSubscriptionList}
          updateSubscriptions={updateSubscriptions}
          {...props}
          access={access}
        />
      ) : null}

      <CardSection as={ContentWidth}>
        {access.privacy.edit ? (
          <Privacy
            blockedEmails={blockedEmails}
            saveBlockedEmails={saveBlockedEmails}
            externalAccounts={privacyExternalAccounts}
            access={access}
          />
        ) : null}

        {access.integratedInboxes.edit ? (
          <IntegratedEmail
            externalAccounts={integratedEmailExternalAccounts}
            blockedEmails={blockedEmails}
            access={access}
            saveBlockedEmails={saveBlockedEmails}
            onAccountDelete={handleAccountDelete}
            businessId={chosenBusinessId}
            onUpdateExternalAccounts={setIntegratedEmailExternalAccountsArgs}
            externalAccountsLoading={integratedEmailExternalAccountsLoading}
          />
        ) : null}
      </CardSection>
    </PageSizing>
  );
}
