import { useEffect, useState } from 'react';
import { StyledLoader } from './styles';

export const DelayedLoader = ({ loading, initial }) => {
  const [showLoader, setShowLoader] = useState(initial);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setShowLoader(loading);
      },
      !loading ? 500 : 0
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [loading]);

  return showLoader ? <StyledLoader loading /> : null;
};
