import React, { useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IconButton from 'components/Kizen/IconButton';
import Icon from 'components/Kizen/Icon';
import { ICON_BUTTON_SIZING } from 'components/Kizen/IconButton';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import useField from 'hooks/useField';
import useMeasure from 'react-use/lib/useMeasure';
import { layers } from 'app/spacing';
import { useTranslation } from 'react-i18next';

const PARTIAL_OPEN_HEIGHT = 70;

const Body = styled.div`
  transition: height 0.3s ease-in-out;
  overflow: hidden;
`;
const Wrapper = styled.div`
  overflow: hidden;
`;

const ExpansionWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ fudge }) => layers.content(0, fudge)};
`;

const isClosed = (expanded) => ['close', 'partially-open'].includes(expanded);

const ExpansionButton = styled(({ expanded, ...others }) => {
  const { t } = useTranslation();
  return (
    <IconButton
      title={isClosed(expanded) ? t('Open') : t('Close')}
      sizing={ICON_BUTTON_SIZING.overlay}
      {...others}
    >
      {isClosed(expanded) && <Icon icon="arrow-from-top" />}
      {expanded === 'open' && <Icon icon="arrow-from-bottom" />}
    </IconButton>
  );
})`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

function calcInitState(expanded, partiallyOpenHeight) {
  if (expanded === 'open' && partiallyOpenHeight) return 'partially-open';
  if (expanded === 'open' && !partiallyOpenHeight) return 'close';
  return expanded;
}

function Expander(props) {
  const {
    expanded,
    onChange,
    children,
    gradient,
    partiallyOpenHeight,
    showArrowBtn,
    arrowBtnLayerFudge,
    title,
    onMouseOver,
    ...others
  } = props;
  const initExpState = calcInitState(expanded, partiallyOpenHeight);

  const bodyRef = useRef();
  const [expansionRef, { height: openHeight = 0 }] = useMeasure(); // Use absolute values for height for smooth animation
  const [expandedState, setExpandedState] = useField(expanded);
  const handleChangeRef = useRef();
  useEffect(() => {
    handleChangeRef.current = onChange;
  });

  const getHeight = () => {
    if (expandedState === 'open') {
      return openHeight;
    }

    if (expandedState === 'partially-open') {
      return partiallyOpenHeight;
    }

    // expandedState === 'close' as default
    return 0;
  };

  const toggle = (x) => {
    return x === 'open' ? initExpState : 'open';
  };

  useEffect(() => {
    if (handleChangeRef.current) {
      handleChangeRef.current(expandedState);
    }
  }, [expandedState]);

  return (
    <>
      <Wrapper {...others}>
        <Body ref={bodyRef} style={{ height: getHeight() }}>
          <div ref={expansionRef}>{children}</div>
        </Body>
        {gradient &&
          React.cloneElement(gradient, {
            position: 'bottom',
            fadeIn: isClosed(expandedState),
          })}
      </Wrapper>
      {showArrowBtn && (
        // You must set position: relative; on whichever ancestor element along
        // whose width you want this button to be centered, as ExpansionWrapper is absolute positioned
        <ExpansionWrapper fudge={arrowBtnLayerFudge} onMouseOver={onMouseOver}>
          <ExpansionButton
            data-qa={`${title}_expansion_button`}
            expanded={expandedState}
            onClick={() => {
              setExpandedState(toggle);
            }}
            onMouseDown={(ev) => {
              // Prevent focus so that the browser does not try to keep
              // this button element in the viewport when the page height changes.
              ev.preventDefault();
            }}
          />
        </ExpansionWrapper>
      )}
    </>
  );
}
export default Expander;

Expander.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element])
    .isRequired,
  expanded: PropTypes.oneOf(['open', 'close', 'partially-open']),
  partiallyOpenHeight: PropTypes.number,
  gradient: PropTypes.element,
  onChange: PropTypes.func,
  showArrowBtn: PropTypes.bool,
  arrowBtnLayerFudge: PropTypes.number,
  title: PropTypes.string,
};

Expander.defaultProps = {
  gradient: <Gradient />,
  partiallyOpenHeight: PARTIAL_OPEN_HEIGHT,
  expanded: 'partially-open',
  onChange: function onChange() {},
  showArrowBtn: true,
  arrowBtnLayerFudge: 2,
  title: '',
};
