import { addMinutes, differenceInCalendarDays } from 'date-fns';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MONTHS_SHORT = MONTHS.map((m) => m.substring(0, 3));

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const WEEKDAYS_SHORT = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thur',
  'Fri',
  'Sat',
];

export const WEEKDAYS_INDICES = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export const INDICES_WEEKDAYS = [
  'sun',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
];

export const buildTimeIntervals = (interval, xform = (date) => date) => {
  const result = [];
  const beginningOfDay = new Date('2/2/1990'); // it is not a special date. Just need a date object from which to derive time intervals
  let iterator = beginningOfDay;
  do {
    result.push(xform(iterator));
    iterator = addMinutes(iterator, interval);
  } while (!differenceInCalendarDays(beginningOfDay, iterator));
  return result;
};

// This function is in a newer version of date-fns
export const isPast = (d) => d.getTime() < Date.now();
