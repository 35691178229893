import { AxiosInstance, AxiosResponse } from 'axios';
import { components, paths } from 'schema';
import { curry1 } from './utils';

// use types from client endpoint - the custom object ones are wrong
type SearchOpts = {
  filter?: Required<
    paths['/api/client/v2']['post']
  >['requestBody']['content']['application/json'];
  params?: paths['/api/client/v2']['post']['parameters'];
};

export type FieldCategory =
  paths['/api/custom-objects/{object_pk}/categories']['get']['responses']['200']['content']['application/json'][number];
export type FilterGroup =
  paths['/api/custom-objects/{object_pk}/filter-groups']['post']['responses']['201']['content']['application/json'];
export type FilterGroupRequest =
  paths['/api/custom-objects/{object_pk}/filter-groups']['post']['requestBody']['content']['application/json'];
export type CustomObject = components['schemas']['CustomObject'];
export type CustomObjectMinimal =
  components['schemas']['CustomObjectMinimalRead'];
export type CustomObjectRequest =
  paths['/api/custom-objects']['post']['requestBody']['content']['application/json'];
export type StandardEntityRecord =
  components['schemas']['StandardEntityRecordRead'];

export const createFilterGroup = async (
  instance: AxiosInstance,
  customObjectid: string,
  filterGroup: FilterGroupRequest
): Promise<AxiosResponse<FilterGroup>> => {
  return instance.post(
    `/custom-objects/${customObjectid}/filter-groups`,
    filterGroup
  );
};

export const createCustomObject = async (
  instance: AxiosInstance,
  customObject: CustomObjectRequest
): Promise<AxiosResponse<CustomObject>> => {
  return instance.post('/custom-objects', customObject);
};

/**
 * @remarks this bypasses permissions to return all custom objects
 */
export const getAllCustomObjects = async (
  instance: AxiosInstance
): Promise<AxiosResponse<CustomObjectMinimal[]>> => {
  return instance.get('/custom-objects/settings-search?page_size=10000');
};

export const getCustomObjectCategories = async (
  instance: AxiosInstance,
  customObjectId: string
): Promise<AxiosResponse<FieldCategory[]>> => {
  return instance.get(`/custom-objects/${customObjectId}/categories`);
};

export const searchEntityRecords = async (
  instance: AxiosInstance,
  id: string,
  { filter, params }: SearchOpts = {}
): Promise<AxiosResponse<StandardEntityRecord[]>> => {
  return instance.post(`/custom-objects/${id}/entity-records`, filter, {
    params,
  });
};

export const searchPipelineEntityRecords = async (
  instance: AxiosInstance,
  id: string,
  { filter, params }: SearchOpts = {}
): Promise<AxiosResponse<StandardEntityRecord[]>> => {
  return instance.post(`/pipelines/${id}/entity-records`, filter, {
    params,
  });
};

export class CustomObjectService {
  public createFilterGroup;
  public createCustomObject;
  public getAllCustomObjects;
  public getCustomObjectCategories;
  public searchEntityRecords;
  public searchPipelineEntityRecords;

  constructor(private instance: AxiosInstance) {
    this.createFilterGroup = curry1(createFilterGroup)(this.instance);
    this.createCustomObject = curry1(createCustomObject)(this.instance);
    this.getAllCustomObjects = curry1(getAllCustomObjects)(this.instance);
    this.getCustomObjectCategories = curry1(getCustomObjectCategories)(
      this.instance
    );
    this.searchEntityRecords = curry1(searchEntityRecords)(this.instance);
    this.searchPipelineEntityRecords = curry1(searchPipelineEntityRecords)(
      this.instance
    );
  }
}
