import { useCallback, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCustomObjectsWithBulkAccess } from 'store/authentication/selectors';
import { StyledPicker, StyledLoader, StyledSwitch } from '../styled';
import { BROADCAST_FORMDATA } from '../constants';
import { BroadcastName } from './BroadcastName';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'components/Inputs/MultiSelect';
import Select from 'components/Inputs/Select';
import InputControl from 'components/Inputs/InputControl';
import { useAsync } from 'react-use';
import CustomObjectsService from 'services/CustomObjectsService';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import { EMPTY_ARRAY, isContact } from 'utility/fieldHelpers';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';

const getAutomationOption = (opt, t) => ({
  value: opt.id,
  label: opt.active ? opt.name : `[${t('Inactive')}] ${opt.name}`,
  automation: opt,
});

const AutomationsSelect = ({ value, onChange, customObjectId, disabled }) => {
  const { t } = useTranslation();
  const params = useMemo(
    () => ({ active: true, custom_object_id: customObjectId }),
    [customObjectId]
  );
  const selectRef = useRef(null);
  const objectToOption = useCallback((opt) => getAutomationOption(opt, t), [t]);
  const chosenValueIds = useMemo(() => value.map((opt) => opt.value), [value]);

  const [typeaheadProps, { loading: loadingAutomationOptions }] =
    useSelectTypeaheadWithScroll({
      entity: Entities.Automations,
      enabled: !!customObjectId,
      objectToOption,
      chosenValueIds,
      selectRef,
      params,
    });

  return (
    <MultiSelect
      ref={selectRef}
      loadItems={loadingAutomationOptions}
      isLoading={loadingAutomationOptions}
      label={t('Choose Automation(s) to Start')}
      placeholder={t('Find Automation(s)')}
      value={value}
      onChange={onChange}
      menuInline
      margin
      inModal
      disabled={disabled}
      {...typeaheadProps}
      noOptionsMessage={t('No Automations Found')}
    />
  );
};

export const BroadcastAutomationPicker = ({
  name,
  action,
  setFormData,
  disabled,
  setBroadcastObject,
  customObject,
}) => {
  const { t } = useTranslation();

  const [actionObject, setActionObject] = useState(() => ({
    ...action,
    automations: action.automations.map((automation) =>
      getAutomationOption(automation, t)
    ),
  }));

  const objectsWithBulkAutomationAccess = useSelector((store) =>
    getCustomObjectsWithBulkAccess(store, 'bulk_start_automation')
  );

  const { value: objectList = EMPTY_ARRAY, loading: loadingObjectList } =
    useAsync(async () => {
      const { results } = await CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
        customOnly: false,
      });
      return results.map((object) => ({
        value: object.id,
        label: object.objectName,
        object: {
          ...object,
          objectClass: isContact(object) ? 'contacts' : 'customObjects',
        },
      }));
    }, []);

  const objectOptionList = useMemo(
    () =>
      objectList.filter(
        ({ value }) =>
          value === customObject?.id || objectsWithBulkAutomationAccess[value]
      ),
    [objectList, objectsWithBulkAutomationAccess, customObject?.id]
  );

  const handleChangeAutomationValue = useCallback(
    (val) => {
      const newAction = {
        ...actionObject,
        automations: val,
      };
      setActionObject(newAction);
      setFormData(BROADCAST_FORMDATA.action, {
        ...actionObject,
        automations: val.map(({ automation }) => automation),
      });
    },
    [actionObject, setFormData]
  );

  const handleChangeObject = useCallback(
    ({ object }) => {
      setBroadcastObject(object);
      const newActionObject = {
        ...actionObject,
        automations: [],
      };
      setActionObject(newActionObject);
      setFormData(BROADCAST_FORMDATA.customObjectId, object.id);
      setFormData(BROADCAST_FORMDATA.action, newActionObject);
    },
    [actionObject, setBroadcastObject, setFormData]
  );

  const handleChangeRestartValue = useCallback(
    ({ target }) => {
      setFormData(BROADCAST_FORMDATA.action, {
        automations: actionObject.automations.map(
          ({ automation }) => automation
        ),
        resumePausedAutomations: target.checked,
      });
      setActionObject({
        ...actionObject,
        resumePausedAutomations: target.checked,
      });
    },
    [actionObject, setFormData]
  );

  return (
    <StyledLoader loading={loadingObjectList}>
      <StyledPicker>
        <Select
          options={objectOptionList}
          placeholder={t('Choose Object')}
          value={customObject?.id}
          onChange={handleChangeObject}
          label={t('Choose Object for Automation')}
          margin
          inModal
          disabled={disabled}
        />
        {customObject?.id ? (
          <>
            <AutomationsSelect
              key={customObject?.id}
              value={actionObject.automations}
              customObjectId={customObject?.id}
              onChange={handleChangeAutomationValue}
              disabled={disabled}
            />
            <InputControl
              label={t('Resume Paused Automations')}
              labelInfo={t(
                'Records with this existing paused automation will be resumed instead of starting a new automation at step 1.'
              )}
            >
              <StyledSwitch
                checked={actionObject?.resumePausedAutomations}
                onChange={handleChangeRestartValue}
                data-qa="resume-paused-automations"
                disabled={disabled}
              />
            </InputControl>
          </>
        ) : null}
      </StyledPicker>
      <BroadcastName
        name={name}
        setFormData={setFormData}
        action={actionObject}
        disabled={disabled}
        eventType={EVENT_TYPES.automation.type}
      />
    </StyledLoader>
  );
};
