import { FIELD_TYPES } from 'utility/constants';

import { TextCell } from './TextCell';
import { LongTextCell } from './LongTextCell';
import { RelationshipCell } from './RelationshipCell';
import { EmailCell } from './EmailCell';
import { WholeNumberCell } from './WholeNumberCell';
import { DecimalNumberCell } from './DecimalNumberCell';
import { PriceNumberCell } from './PriceNumberCell';
import { PhoneNumberCell } from './PhoneNumberCell';
import { DateCell } from './DateCell';
import { DateTimeCell } from './DateTimeCell';
import { RatingCell } from './RatingCell';
import { DropdownCell } from './DropdownCell';
import { TeamSelectorCell } from './TeamSelectorCell';
import { DynamicTagsCell } from './DynamicTagsCell';
import { CheckboxCell } from './CheckboxCell';
import { CheckboxesCell } from './CheckboxesCell';
import { FilesCell } from './FilesCell';
import { TimezoneCell } from './TimezoneCell';
import { NameCell as CustomObjectNameCell } from './CustomObject/NameCell';
import { OwnerCell as CustomObjectOwnerCell } from './CustomObject/OwnerCell';
import { EntityValueCell as CustomObjectEntityValueCell } from './CustomObject/EntityValueCell';
import { StageCell as CustomObjectStageCell } from './CustomObject/StageCell';
import { EstimatedCloseDateCell as CustomObjectEstimatedCloseDateCell } from './CustomObject/EstimatedCloseDateCell';
import { ActualCloseDateCell as CustomObjectActualCloseDateCell } from './CustomObject/ActualCloseDateCell';
import { DateCreatedCell as CustomObjectDateCreatedCell } from './CustomObject/DateCreatedCell';
import { LastModifiedCell as CustomObjectLastModifiedCell } from './CustomObject/LastModifiedCell';
import { ChanceToCloseCell as CustomObjectChanceToCloseCell } from './CustomObject/ChanceToCloseCell';

const NoneCell = ({ field }) => <div>{field.fieldType}</div>;
const MissedCell = () => <div>missed</div>;

export {
  TextCell,
  RelationshipCell,
  LongTextCell,
  EmailCell,
  WholeNumberCell,
  DecimalNumberCell,
  PriceNumberCell,
  PhoneNumberCell,
  DateCell,
  DateTimeCell,
  RatingCell,
  DropdownCell,
  TeamSelectorCell,
  DynamicTagsCell,
  CheckboxCell,
  CheckboxesCell,
  FilesCell,
  TimezoneCell,
  NoneCell,
  MissedCell,
  // custom objects default cells
  CustomObjectNameCell,
  CustomObjectOwnerCell,
  CustomObjectEntityValueCell,
  CustomObjectStageCell,
  CustomObjectEstimatedCloseDateCell,
  CustomObjectActualCloseDateCell,
  CustomObjectDateCreatedCell,
  CustomObjectLastModifiedCell,
  CustomObjectChanceToCloseCell,
};

export const cellMap = new Map([
  [FIELD_TYPES.Text.type, TextCell],
  [FIELD_TYPES.LongText.type, LongTextCell],
  [FIELD_TYPES.Email.type, EmailCell],
  [FIELD_TYPES.Integer.type, WholeNumberCell],
  [FIELD_TYPES.Decimal.type, DecimalNumberCell],
  [FIELD_TYPES.Money.type, PriceNumberCell],
  [FIELD_TYPES.PhoneNumber.type, PhoneNumberCell],
  [FIELD_TYPES.Date.type, DateCell],
  [FIELD_TYPES.DateTime.type, DateTimeCell],
  [FIELD_TYPES.Rating.type, RatingCell],
  [FIELD_TYPES.Choices.type, DropdownCell],
  [FIELD_TYPES.Dropdown.type, DropdownCell],
  [FIELD_TYPES.Radio.type, DropdownCell],
  [FIELD_TYPES.YesNoMaybe.type, DropdownCell],
  [FIELD_TYPES.Status.type, DropdownCell],
  [FIELD_TYPES.Relationship.type, RelationshipCell],
  [FIELD_TYPES.TeamSelector.type, TeamSelectorCell],
  [FIELD_TYPES.DynamicTags.type, DynamicTagsCell],
  [FIELD_TYPES.Checkbox.type, CheckboxCell],
  [FIELD_TYPES.Checkboxes.type, CheckboxesCell],
  [FIELD_TYPES.Files.type, FilesCell],
  [FIELD_TYPES.Timezone.type, TimezoneCell],
]);

export const getCellForFieldType = (fieldType) =>
  cellMap.get(fieldType) || MissedCell;
