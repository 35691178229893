import { Spacer } from '@kizen/kds/Spacer';
import { HorizontalTile, Panel } from '@kizen/kds/Tile';
import { HeaderText, LinkText, Typography } from '@kizen/kds/Typography';
import Switch from '__components/Kizen/Switch';
import { IntegrationModal } from './Modal';
import useModal from '__components/Modals/useModal';
import { Button } from '@kizen/kds/Button';
import { InstalledPlugin, MarketplacePlugin } from 'pages/Marketplace/types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@kizen/kds/Icon';
import {
  PanelFooter,
  PanelSizer,
  Thumbnail,
  ThumbnailSizer,
} from 'pages/Marketplace/styles';

interface TileProps {
  onChangeEnabled?: (pluginApiName: string, enabled: boolean) => void;
  onChangeConfig?: (pluginApiName: string, config: Record<string, any>) => void;
  plugin: MarketplacePlugin;
  installation?: InstalledPlugin;
  overrideCheckedState?: boolean;
  hasBusinessPluginApp: boolean;
  currentConfig?: Record<string, any>;
  isAdmin: boolean;
}

export const Tile = (props: TileProps) => {
  const {
    onChangeEnabled,
    onChangeConfig,
    plugin,
    installation,
    overrideCheckedState,
    hasBusinessPluginApp,
    currentConfig,
    isAdmin,
  } = props;

  const { t } = useTranslation();

  const [integrationModalProps, , integrationModal] = useModal({
    handleSubmit: (config: Record<string, any>) => {
      onChangeConfig?.(plugin.api_name, config);
    },
  });
  const isEnabled = Boolean(installation);

  return (
    <>
      <HorizontalTile
        center={
          <div className="w-full">
            <Panel expand>
              <PanelSizer>
                {plugin.thumbnail ? (
                  <>
                    <ThumbnailSizer>
                      <Thumbnail
                        src={`${import.meta.env.VITE_API_BASE_PATH}/files/${plugin.thumbnail}/download`}
                      />
                    </ThumbnailSizer>
                    <Spacer size={20} mode="horizontal" />
                  </>
                ) : null}

                <div className="flex items-center justify-start kds">
                  <Switch
                    checked={overrideCheckedState ?? isEnabled}
                    onChange={(ev) =>
                      onChangeEnabled?.(plugin.api_name, ev.target.checked)
                    }
                    removeMargin
                    disabled={typeof overrideCheckedState === 'boolean'}
                  />
                  <Spacer size={10} mode="vertical" />
                  <HeaderText size="xl" weight="semibold">
                    {plugin.name}
                  </HeaderText>
                  {plugin.description ? (
                    <>
                      <Spacer size={10} mode="vertical" />
                      <Icon
                        tooltipEnabled
                        tooltipDescriptionOverride={plugin.description}
                        icon="message-info-circle"
                        size="sm"
                      />
                    </>
                  ) : null}
                </div>
                {plugin.external_link ? (
                  <>
                    <Spacer size={15} mode="horizontal" />
                    <LinkText
                      href={plugin.external_link}
                      target="_blank"
                      truncate
                    >
                      {plugin.external_link}
                    </LinkText>
                  </>
                ) : null}
                <PanelFooter>
                  {hasBusinessPluginApp && isAdmin ? (
                    <>
                      <Button
                        onClick={integrationModal.show}
                        variant="text"
                        size="xs"
                        color="secondary"
                      >
                        {t('Edit Configuration')}
                      </Button>
                      <Spacer size={15} mode="horizontal" />
                    </>
                  ) : null}
                  {plugin.managed_by_business?.reply_to_email ? (
                    <Typography
                      color="gray-700"
                      variant="link"
                      href={`mailto:${plugin.managed_by_business.reply_to_email}?subject=${encodeURIComponent(`Kizen Plugin Support for ${plugin.name}`)}`}
                    >
                      {t('Support Contact')}:{' '}
                      {plugin.managed_by_business.reply_to_email}
                    </Typography>
                  ) : null}
                </PanelFooter>
              </PanelSizer>
            </Panel>
          </div>
        }
      />
      <IntegrationModal
        {...integrationModalProps}
        config={currentConfig ?? {}}
        plugin={plugin}
      />
    </>
  );
};
