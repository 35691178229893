import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import {
  validatePriceNumber,
  negativesValidatePriceNumber,
} from 'components/Inputs/TextInput/NumericValidation/helpers';
import { useTranslation } from 'react-i18next';
import NumericInline from './Numeric';
import { toPriceNumber } from 'components/Inputs/TextInput/presets/PriceNumber';
import { FIELD_TYPES } from 'utility/constants';

export default function PriceNumberInline({
  currencySymbol,
  allowNegatives = true,
  ignoreEmptyValue,
  onSubmit,
  ...others
}) {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (val) => {
      return onSubmit(toPriceNumber(val));
    },
    [onSubmit]
  );

  return (
    <NumericInline
      numberFormat={{
        prefix: currencySymbol,
        decimalScale: 2,
        fixedDecimalScale: true,
        thousandSeparator: true,
        isInteger: false,
        ignoreEmptyValue,
        placeholder: FIELD_TYPES.Money.placeholder,
      }}
      validate={
        allowNegatives
          ? negativesValidatePriceNumber(t)
          : validatePriceNumber(t)
      }
      onSubmit={handleSubmit}
      {...others}
    />
  );
}

PriceNumberInline.propTypes = {
  currencySymbol: PropTypes.string,
  allowNegatives: PropTypes.bool,
  ignoreEmptyValue: PropTypes.bool,
};

PriceNumberInline.defaultProps = {
  currencySymbol: '$',
  allowNegatives: true,
  ignoreEmptyValue: true,
};
