import { useEffect, useState } from 'react';

const MINUTES_IN_DAY = 1440;
const ONE_MINUTE_MS = 60000;

const calculateOffset = (height, date) => {
  const minutes = date.getHours() * 60 + date.getMinutes();
  return (minutes / MINUTES_IN_DAY) * height;
};

const useCurrentTimePosition = (
  scrollHeight,
  updateInterval = ONE_MINUTE_MS
) => {
  const [offset, setOffset] = useState(
    calculateOffset(scrollHeight, new Date())
  );

  useEffect(() => {
    setOffset(calculateOffset(scrollHeight, new Date()));
  }, [scrollHeight]);

  useEffect(() => {
    const id = setInterval(() => {
      setOffset(calculateOffset(scrollHeight, new Date()));
    }, updateInterval);
    return () => clearInterval(id);
  }, [scrollHeight, updateInterval]);

  return offset;
};

export default useCurrentTimePosition;
