import { useSelector } from 'react-redux';
import {
  getUserProfile,
  getManageStanadardApiKeys,
} from 'store/authentication/selectors';

import APIKeys from './APIKeys';
import OAuth from './OAuth';

import {
  Container,
  StyledCols as Cols,
  Sections,
  GoLiveStyledSection,
  CardContainer,
} from 'pages/Account/pages/styles/common';

const ManageConnections = () => {
  const user = useSelector(getUserProfile);
  const chosenBusinessId = useSelector(
    ({ authentication }) =>
      !!authentication.chosenBusiness && authentication.chosenBusiness.id
  );

  const keyAccess = useSelector(getManageStanadardApiKeys);

  return (
    <GoLiveStyledSection>
      <Sections>
        <Container>
          <Cols columns={2} gutter={1}>
            {keyAccess?.view ? (
              <CardContainer>
                <APIKeys
                  userId={user && user.id}
                  businessId={chosenBusinessId}
                />
              </CardContainer>
            ) : (
              false
            )}
            <CardContainer>
              <OAuth userId={user && user.id} businessId={chosenBusinessId} />
            </CardContainer>
          </Cols>
        </Container>
      </Sections>
    </GoLiveStyledSection>
  );
};

export default ManageConnections;
