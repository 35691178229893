import { CustomField, NodeProps } from '../types';
import { CustomObjectField } from '../../types';

type Props = Partial<NodeProps['FormField']>;
type Args = [CustomObjectField] | [CustomObjectField, Props];

export function customField(field: CustomObjectField): CustomField;
export function customField(
  field: CustomObjectField,
  props: Props
): CustomField;
export function customField(...args: Args): CustomField {
  return ['CustomField', { ...args[1], field: args[0] }];
}
