import { useCallback } from 'react';
import { useViewerContext } from '../../../../../viewer/ViewerContext';

/**
 * @param {boolean} publicFile - flag to indicate if the uploaded file is publicly available
 * @param {function} handleProgress - function called during the upload to provide a progress update
 * @param {boolean} businessId - business id
 * @param {string} source - uploading source
 * @returns {Function}
 */
export const useUploadFile = (
  publicFile,
  handleProgress,
  businessId,
  source = 'field_value'
) => {
  const { onUploadFile } = useViewerContext();

  return useCallback(
    (file) => {
      return onUploadFile(file, file.$id, businessId, {
        publicFile: !!publicFile,
        usePublicEndpoint: !!publicFile,
        handleProgress,
        source,
      });
    },
    [publicFile, handleProgress, businessId, onUploadFile, source]
  );
};
