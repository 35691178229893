import { format } from 'date-fns';
import get from 'lodash/get';
import { Path, RelativeString, TransformArg, TransformDef } from '../types';
import { isRelativeString, isTransform } from '../checks';

type Ctx = Record<string, string>;

const isVariable = (x: any) => {
  return typeof x === 'string' && x.startsWith('$');
};

const resovleRelative = (ref: RelativeString, root: any) => {
  const path = ref.substring(1).split('/');
  return get(root, path);
};

const resolveArg = (arg: any, root: any, ctx: Ctx) => {
  if (isRelativeString(arg)) {
    return resovleRelative(arg, root);
  }
  if (isTransform(arg)) {
    return transform(arg, root, ctx);
  }
  if (isVariable(arg)) {
    return arg === '$' ? ctx.$ : ctx[arg.substring(1)];
  }
  return arg;
};

const getTransforms = (root: any, ctx: Ctx) => {
  return {
    bool: (ref: TransformArg) => {
      return Boolean(resolveArg(ref, root, ctx));
    },
    concat: (...args: TransformArg[]) => {
      return args.reduce<any[]>((acc, x) => {
        const resolved = resolveArg(x, root, ctx);
        if (Array.isArray(resolved)) {
          acc.push(...resolved);
        } else {
          acc.push(resolved);
        }
        return acc;
      }, []);
    },
    date_format: (a: TransformArg, b: string) => {
      const v = resolveArg(a, root, ctx);
      const d = new Date(v);
      return isNaN(d.getTime()) ? '' : format(d, b);
    },
    eq: (a: TransformArg, b: TransformArg, ret?: any) => {
      const x = resolveArg(a, root, ctx);
      const y = resolveArg(b, root, ctx);
      const result = x === y;
      return ret && result ? ret : result;
    },
    hoist: (a: TransformArg): any => {
      const x = resolveArg(a, root, ctx);
      if (isTransform(x)) {
        const y = transform(x, root, ctx);
        return Array.isArray(y) ? y[0] : y;
      }
      return Array.isArray(x) ? x[0] : x;
    },
    invert: (ref: TransformArg) => {
      return !resolveArg(ref, root, ctx);
    },
    join: (...parts: TransformArg[]): any => {
      return parts
        .map((x) => {
          const res = resolveArg(x, root, ctx);
          return isTransform(res) ? transform(res, root, ctx) : res;
        })
        .filter(Boolean)
        .join('');
    },
    not_eq: (a: TransformArg, b: TransformArg, ret?: any) => {
      const x = resolveArg(a, root, ctx);
      const y = resolveArg(b, root, ctx);
      const result = x !== y;
      return ret && result ? ret : result;
    },
    map: (a: TransformArg, b: Record<string, any> | Path) => {
      const array = resolveArg(a, root, ctx);
      if (!Array.isArray(array)) return [];
      const res = array.map((x: any): any => {
        const iterCtx = { ...ctx, $: x };
        if (isTransform(b)) {
          return transform(b, root, iterCtx);
        }
        if (Array.isArray(b)) {
          return get(x, b);
        }
        if (b !== null && typeof b === 'object') {
          return Object.entries(b).reduce<Record<string, any>>(
            (acc, [k, v]) => {
              acc[k] = resolveArg(v, root, iterCtx);
              return acc;
            },
            {}
          );
        }
        return b;
      });
      return res;
    },
    pick: (a: TransformArg, b: Path): any => {
      const src = resolveArg(a, root, ctx);
      return get(src, b);
    },
    some: (a: TransformArg, b: TransformArg, ret?: any) => {
      const aValue = resolveArg(a, root, ctx);
      const bValue = resolveArg(b, root, ctx);

      if (!Array.isArray(aValue)) {
        return false;
      }

      const result = aValue.some((x) => x === bValue);
      return ret && result ? ret : result;
    },
  };
};

export const transform = (xf: TransformDef, root: any, ctx: Ctx = {}) => {
  const xfs = getTransforms(root, ctx);
  const [xform, ...args] = xf;
  const fn_lookup = xform.substring(3);

  if (!(fn_lookup in xfs)) {
    console.log(`cannot handle transform ${xform}`);
    return;
  }

  try {
    const fn = (xfs as any)[fn_lookup];
    return fn(...args);
  } catch (error) {
    console.log('transform error', error);
  }
};
