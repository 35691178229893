import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@craftjs/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { getTypeInfo } from '@kizen/page-builder/nodes/types';
import { useDispatch } from 'react-redux';
import { reset } from 'store/pageBuilder/pageBuilder.redux';
import { grayScale } from 'app/colors';
import { layers } from 'app/spacing';
import { isContactForm } from 'checks/forms';
import { useDraggingType } from '../nodes/useDraggingType';
import FormBuilderContext from '../BuilderContext';

const editorIndicatorCss = ({ color }) => css`
  height: 0px !important;
  background-color: transparent !important;
  border-top: 1px dashed ${color || grayScale.dark} !important;
  border-width: 0 !important;
  border-top-width: 1px !important;
  transition: none !important;
  z-index: ${layers.content(1)} !important;
  pointer-events: none; // Avoids flicker when dragging over indicator from tray
  &:after {
    content: ' ';
    display: block;
    position: relative;
    top: -3px;
    height: 6px;
    border-radius: 3px;
    width: 30px;
    max-width: 50%;
    background-color: ${color || grayScale.dark};
    margin: 0 auto;
  }
  // Below is a hack to target error styles using
  // the background colors configured in PageBuilder/Editor
  &[style*='red'] {
    display: none !important;
  }
`;

const PageBuilderWrapper = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
  // TODO below is a temporary workaround for displaying the drop indicator
  // rendered by craft.js.  It's currently not highly customizable, see prevwong/craft.js#122.
  > *:nth-last-child(${({ numChildren }) => numChildren + 1}) {
    ${editorIndicatorCss}
  }
`;

const IndicatorColor = ({ onColor }) => {
  const type = useDraggingType();
  const color = getTypeInfo(type)?.color;
  useEffect(() => {
    onColor(color);
  }, [onColor, color]);
  return null;
};

export const PageBuilderContainer = ({
  onAIRequestStart,
  nodes,
  creatorElements,
  onNodeCreate,
  onNodeDelete,
  onNodeDuplicate,
  onNodeUpdate,
  onNodePropChange,
  enableAI,
  javascriptActions,
  currentPage = 0,
  enableMergeFields = false,
  enableTextLinks = false,
  enableDynamicImages = false,
  enableGoogleFonts = false,
  enableScriptExecution = false,
  textLinkOptions = [],
  customObjectId,
  modalLayer = 0,
  form = null,
  children,
  className,
  style,
  mergeFields = null,
  multipleMergeFields = null,
  initialGridlinesActive = false,
  staticBlock = false,
  defaultPhoneRegion,
  allowRelativeLinks = false,
  measureRootWidth = true,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [color, setColor] = useState('transparent');
  const [activeTextEditorId, setActiveTextEditorId] = useState(null);
  const [activeNode, setActiveNode] = useState(null);
  const [rootNode, setRootNode] = useState(null);
  const [rootWidth, setRootWidth] = useState();
  const [gridLinesActive, setGridLinesActive] = useState(
    initialGridlinesActive
  );
  const [resizingRowId, setResizingRowId] = useState(null);
  const [resizingHeight, setResizingHeight] = useState(false);
  const [view, setView] = useState('desktop');
  const [canvasScrollContainer, setCanvasScrollContainer] = useState(null);
  const [canvasWrapper, setCanvasWrapper] = useState(null); // same element as canvasScrollContainer on desktop
  const [scrolling, setScrolling] = useState(false);
  const [trayProps, setTrayProps] = useState({});
  const preventCloseSettingsTray = useRef(false);
  const [additionalRootHeight, setAdditionalRootHeight] = useState(0);
  const [isPageSettingsOpen, setIsPageSettingsOpen] = useState(true);
  const [isViewSettingsOpen, setIsViewSettingsOpen] = useState(true);
  const [isElementSettingsOpen, setIsElementSettingsOpen] = useState(true);
  const indicator = {
    // These will end-up in the style tag, where we match on them to determine the state.
    // It's a hack for now. See prevwong/craft.js#122. These need to be real colors or they
    // wont appear in the style tag, interestingly enough.
    success: 'green',
    error: 'red',
  };

  const setPreventCloseSettingsTray = useCallback((val) => {
    preventCloseSettingsTray.current = val;
  }, []);

  const clearContentSettingsTray = useCallback(() => {
    if (!preventCloseSettingsTray.current) {
      setActiveNode(null);
    } else {
      preventCloseSettingsTray.current = false;
    }
  }, []);

  const overrideSetActiveNode = useCallback((...args) => {
    if (!preventCloseSettingsTray.current) {
      setActiveNode(...args);
    } else {
      preventCloseSettingsTray.current = false;
    }
  }, []);

  const staticBlockAndNonDesktop = staticBlock && view !== 'desktop';
  let rw = rootWidth;
  if (staticBlock) {
    // Homepage blocks' root width controlled via the column sizing toggles in ts-components/HomepageBuilder
    // and is not measured from the actual root node like in the form builder. We still want the tablet mobile
    // breakpoints to take effect when using those views, so we override the root width here.
    if (view === 'tablet') rw = parseInt(rootNode.data.props.tabletBreak);
    if (view === 'mobile') rw = parseInt(rootNode.data.props.mobileBreak);
  }

  const showCanvasGradient = rootNode?.data?.props?.height >= 105;

  const ctx = {
    form,
    isContactForm: isContactForm(form),
    isEmail: !form,
    enableDynamicImages,
    enableMergeFields,
    mergeFields,
    multipleMergeFields,
    enableTextLinks,
    enableGoogleFonts,
    enableScriptExecution,
    textLinkOptions,
    gridLinesActive,
    activeNode,
    rootNode,
    showCanvasGradient,
    rootWidth: rw,
    resizingRowId,
    resizingHeight,
    activeTextEditorId,
    modalLayer,
    javascriptActions,
    customObjectId,
    view,
    applyMobileBreakpoints: staticBlock ? staticBlockAndNonDesktop : true,
    fullWidth: staticBlockAndNonDesktop,
    fullHeight: staticBlockAndNonDesktop,
    onNodeCreate,
    onNodeDelete,
    onNodeDuplicate,
    onNodeUpdate,
    onNodePropChange,
    canvasScrollContainer,
    canvasWrapper,
    currentPage,
    trayProps,
    defaultPhoneRegion,
    enableAI,
    measureRootWidth,
    additionalRootHeight,
    isPageSettingsOpen,
    isViewSettingsOpen,
    isElementSettingsOpen,
    setIsPageSettingsOpen,
    setIsViewSettingsOpen,
    setIsElementSettingsOpen,
    scrolling,
    setScrolling,
    clearContentSettingsTray,
    setActiveNode: overrideSetActiveNode,
    setActiveTextEditorId,
    setResizingRowId,
    setResizingHeight,
    setCanvasScrollContainer,
    setCanvasWrapper,
    setRootNode,
    setRootWidth,
    setView,
    setTrayProps,
    toggleGridLines: () => setGridLinesActive((x) => !x),
    setPreventCloseSettingsTray,
    setAdditionalRootHeight,
    allowRelativeLinks,
    onAIRequestStart,
  };

  useEffect(() => {
    // Reset page builder state when it disappears
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <FormBuilderContext.Provider value={ctx}>
      <PageBuilderWrapper
        color={color}
        className={className}
        style={style}
        numChildren={React.Children.toArray(children).length}
      >
        {/* The showIndicator prop was added in our fork https://github.com/kizen/craft.js/pull/4 */}
        <Editor
          resolver={nodes}
          showIndicator={!scrolling}
          indicator={indicator}
          {...rest}
        >
          <IndicatorColor onColor={setColor} />
          {children}
          {creatorElements}
        </Editor>
      </PageBuilderWrapper>
    </FormBuilderContext.Provider>
  );
};
