import {
  blue,
  teal,
  red,
  orange,
  gray,
  aliceblue,
  aliases,
  semanticNames,
} from '../theme';

export type TealColors = keyof typeof teal;
export type BlueColors = keyof typeof blue;
export type RedColors = keyof typeof red;
export type OrangeColors = keyof typeof orange;
export type GrayColors = keyof typeof gray;
export type AliceBlueColors = keyof typeof aliceblue;
export type SemanticColors = keyof typeof semanticNames;

export type Colors =
  | TealColors
  | BlueColors
  | RedColors
  | OrangeColors
  | GrayColors
  | AliceBlueColors
  | SemanticColors;

export const buttonColors = [
  'primary',
  'secondary',
  'warning',
  'inherit',
  'tertiary',
] as const;

export type ButtonColors = (typeof buttonColors)[number] | 'white';

export const notificationColors = [
  'information',
  'success',
  'warning',
  'error',
] as const;

export type NotificationColors = (typeof notificationColors)[number];

export const colors = {
  gray,
  aliceblue,
  teal,
  blue,
  red,
  orange,
  aliases,
};

type ColorFamily = keyof typeof colors;

export const colorOptions = Object.keys(colors).reduce((acc: string[], key) => {
  return [...acc, ...Object.keys((colors as any)[key])];
}, []);

export const semantics = Object.keys(semanticNames).reduce(
  (acc, name) => {
    const color = semanticNames[name as SemanticColors] as Colors;
    const splits = name.split('-');
    const colorSplits = color.split('-');
    const colorFamily = colorSplits[0] as ColorFamily;
    let palette = splits[0];
    let colorName = splits[1];

    const res = { ...acc };

    if (splits.length === 3 && splits[0] !== 'border') {
      // Borders have a color called medium-light that needs special treatment
      palette = `${palette}-${splits[1]}`;
      colorName = splits[2];
    } else if (splits.length === 3) {
      palette = splits[0];
      colorName = `${splits[1]}-${splits[2]}`;
    } else if (splits[0] === 'viz') {
      // Vizualization colors have a color and might have a sequence that needs special treatment
      if (splits.length === 4) {
        palette = `${palette}-${splits[1]}`;
        colorName = `${splits[2]}-${splits[3]}`;
      } else {
        palette = `${palette}`;
        colorName = splits[1];
      }
    }

    if (!res[palette]) {
      res[palette] = {
        colors: {},
      };
    }

    res[palette].colors[colorName] = {
      hex: (colors[colorFamily] as any)[color],
      color,
    };

    return res;
  },
  {} as Record<string, any>
);
