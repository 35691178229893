import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
  ActionButtonMenu,
} from 'components/Layout/ListCard';
import { TextEllipsis } from 'components/Kizen/Table';
import { gutters } from 'app/spacing';
import { DATE_DISPLAY_FORMAT } from 'components/Inputs/DateTimeInput';
import format from 'date-fns/format';
import { ACTIVITY_ACTIONS } from './columns';
import Pluralize from 'pluralize';

const NO_VALUE = '—';

const getSubmissionsText = (numberSubmissions) => {
  return `${numberSubmissions || 0} ${Pluralize(
    'Submission',
    numberSubmissions
  )}`;
};

export const EmptyActivityListItem = ListCardItem;

export function ActivityListItem({ activity, onSelectAction, ...others }) {
  const { t } = useTranslation();
  const activityActions = ACTIVITY_ACTIONS(t);
  return (
    <ListCardItem
      action={
        <ActionButtonMenu
          sizing="dense"
          color={colorsButton.iconGray}
          onChange={onSelectAction}
          options={[
            activityActions.Edit,
            activityActions.Export,
            activityActions.Duplicate,
            activityActions.Delete,
          ]}
        >
          <Icon icon="three-dot" style={{ width: 15 }} />
        </ActionButtonMenu>
      }
      actionStyles={{ paddingTop: `${gutters.spacing(2)}px` }}
      {...others}
    >
      <ListCardRow>
        <TextEllipsis as="span">{activity.name || NO_VALUE}</TextEllipsis>
      </ListCardRow>
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis as="span">
            {getSubmissionsText(activity.nSubmissions)}
          </TextEllipsis>
        </ListCardCell>
        <ListCardCell>
          <TextEllipsis as="span">
            {format(activity.created, DATE_DISPLAY_FORMAT) || NO_VALUE}
          </TextEllipsis>
        </ListCardCell>
      </ListCardRow>
    </ListCardItem>
  );
}

ActivityListItem.propTypes = {
  onChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    numberSubmissions: PropTypes.number,
    created: PropTypes.string,
    meta: PropTypes.shape({
      openMenuAbove: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  onSelectAction: PropTypes.func.isRequired,
};

export function ActivityListHeader({ label, sort, onChangeSort, ...others }) {
  return (
    <ListCardHeader
      compactHeader
      action={<div style={{ width: 15 }} />}
      {...others}
    >
      <ListCardCell>
        <TextEllipsis as="span" weight="bold">
          {label}
        </TextEllipsis>
        <ListCardSortButton sort={sort} onChangeSort={onChangeSort} />
      </ListCardCell>
    </ListCardHeader>
  );
}

ActivityListHeader.propTypes = {
  label: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }),
  onChangeSort: PropTypes.func,
};

ActivityListHeader.defaultProps = {
  sort: null,
  onChangeSort: null,
};
