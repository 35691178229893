import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Input from 'components/Kizen/Input';
import { StyledLabel } from 'components/Kizen/Input/styles';
import { getDataQAForInput } from 'components/Inputs/helpers';
import {
  StyledFormHeader,
  StyledFormInput,
  StyledFormActions,
  StyledFormButton,
  StyledFormLinkButton,
  StyledGridSelector,
  StyledFormGridSelectorWrapper,
  StyledFormGridSelectorOptionsWrapper,
  StyledInputLabel,
} from './styles';
import { CloudTypes } from './FreeTrialProcessTypes';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';

const MAX_POSTGRES_INT = 2147483647;
const cloudsMap = {
  [CloudTypes.MARKETING]: {
    icon: 'envelope',
    label: (t) => t('Marketing Cloud'),
    name: 'marketing_cloud',
  },
  [CloudTypes.SALES]: {
    icon: 'pipeline-entity',
    label: (t) => t('Sales Cloud'),
    name: 'sales_cloud',
  },
  [CloudTypes.EXPERIENCE]: {
    icon: 'experience-entity',
    label: (t) => t('Experience Cloud'),
    name: 'experience_cloud',
  },
  [CloudTypes.BUSINESS]: {
    icon: 'survey-entity',
    label: (t) => t('Business Cloud'),
    name: 'business_intelligence',
  },
};

export const UsageInfo = ({
  title,
  values,
  onChange,
  onAdminFieldChange,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();
  const renderOption = (entity, key, getKeyListenersProps) => {
    return (
      <StyledGridSelector
        key={entity.name}
        name={entity.name}
        icon={entity.icon}
        label={entity.label(t)}
        onChange={(value) => onChange(entity.name, value)}
        selected={!!values[entity.name]}
        {...getKeyListenersProps(key)}
      />
    );
  };

  const [tempAdministratorInputValue, setTempAdministratorInputValue] =
    useState('');

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      ...Object.keys(cloudsMap).map((value) => ({ id: value })),
      { id: 'number-of-members' },
      ...values.other_admins.map((_, index) => ({
        id: `other_admins-${index}`,
      })),
      {
        id: 'other_admins_new',
      },
    ],
    {},
    () => true
  );

  return (
    <>
      <KeyBoardContext.Provider value={{ assignFieldHandle }}>
        <StyledFormHeader type="header">
          {t('How Will You Use Kizen?')}
        </StyledFormHeader>
        <StyledFormGridSelectorWrapper>
          <StyledLabel>{title}</StyledLabel>
          <StyledFormGridSelectorOptionsWrapper>
            {Object.keys(cloudsMap).map((key) =>
              renderOption(cloudsMap[key], key, getKeyListenersProps)
            )}
          </StyledFormGridSelectorOptionsWrapper>
        </StyledFormGridSelectorWrapper>
        <StyledFormInput
          type={Input.INPUT_TYPES.NUMBER}
          min="0"
          placeholder={t('Enter Number Of Team Members')}
          label={t('Number Of Team Members')}
          value={values.num_team_members}
          onChange={(value) => {
            if (value > -1 && value <= MAX_POSTGRES_INT)
              onChange('num_team_members', value);
          }}
          required
          {...getDataQAForInput('number-of-members', 'number')}
          {...getKeyListenersProps('number-of-members')}
        />
        <StyledInputLabel>{t('Invite Other Administrators')}</StyledInputLabel>
        {!!values.other_admins &&
          values.other_admins.map((input, index) => (
            <StyledFormInput
              className="admin-input"
              key={index}
              value={input}
              name={input}
              onChange={(value) =>
                onAdminFieldChange(value, values.other_admins[index])
              }
              required
              placeholder={t('Enter Email Address')}
              type={Input.INPUT_TYPES.EMAIL}
              {...getKeyListenersProps(`other_admins-${index}`)}
            />
          ))}
        {!values.other_admins.length ||
        (!loading &&
          (tempAdministratorInputValue !== '' ||
            values.other_admins.length > 0)) ? (
          <StyledFormInput
            value={tempAdministratorInputValue}
            onChange={(value) => setTempAdministratorInputValue(value)}
            required
            className="admin-input"
            placeholder={t('Enter Email Address')}
            type={Input.INPUT_TYPES.EMAIL}
            id={'other_admins-111'}
            {...getKeyListenersProps('other_admins_new')}
          />
        ) : null}
        <StyledFormLinkButton
          variant="text"
          color="blue"
          onClick={() => {
            onChange('other_admins', tempAdministratorInputValue);
            setTempAdministratorInputValue('');
          }}
        >
          {`+ ${t('Add Administrator')}`}
        </StyledFormLinkButton>
        <StyledFormActions>
          <StyledFormButton
            onClick={() => {
              // If user has entered value in other_admins input we need to apply it to form values
              if (tempAdministratorInputValue !== '') {
                onChange('other_admins', tempAdministratorInputValue);
                setTempAdministratorInputValue('');
              }
              onSubmit();
            }}
            // Form can't be submitted without at least one cloud option selected and with number of team members added
            disabled={
              (!values.marketing_cloud &&
                !values.sales_cloud &&
                !values.experience_cloud &&
                !values.business_intelligence) ||
              !values.num_team_members ||
              loading
            }
          >
            {t('Get Started')}
          </StyledFormButton>
        </StyledFormActions>
      </KeyBoardContext.Provider>
    </>
  );
};

UsageInfo.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdminFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UsageInfo;
