import { useEffect } from 'react';

const standardWebFonts = [
  'Arial',
  'Courier New',
  'Georgia',
  'Helvetica Neue',
  'Lucida Sans Unicode',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];

export const loadFontFamily = (fontFamily) => {
  const fontFamilyName = fontFamily.split(',')[0];
  const fontLinkId = `font-${fontFamilyName}`;
  if (
    !standardWebFonts.includes(fontFamilyName) &&
    !document.getElementById(fontLinkId)
  ) {
    const link = document.createElement('link');
    link.setAttribute(
      'href',
      `https://fonts.googleapis.com/css?family=${fontFamilyName}`
    );
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('id', fontLinkId);
    document.head.appendChild(link);
  }
};

export const useLoadNonWebFont = (fontFamily) => {
  useEffect(() => {
    if (!fontFamily) return;
    if (!(typeof fontFamily === 'string' || Array.isArray(fontFamily))) return;

    const fonts = Array.isArray(fontFamily) ? fontFamily : [fontFamily];

    for (const font of fonts) {
      loadFontFamily(font);
    }
  }, [fontFamily]);
};
