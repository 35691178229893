import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';

export const CONTACT_TYPE_PHONE = 'phone_number';
export const CONTACT_TYPE_EMAIL = 'email_address';

export const MissingContactInformationModal = ({ onHide, ...props }) => {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      {...props}
      buttonText={t('Confirm')}
      heading={t('Cannot Complete Request')}
      leftBtn={null}
      onHide={onHide}
      onConfirm={onHide}
    >
      <KizenTypography>
        {t(
          'This contact does not have a phone number associated. Please save and try again.'
        )}
      </KizenTypography>
    </ConfirmationModal>
  );
};
