import * as PropTypes from 'prop-types';

import BasicModal from 'components/Modals/presets/BasicModal';
import useField from 'hooks/useField';
import { useTranslation } from 'react-i18next';
import ActivityForm from 'components/ActivityForm';

const ActivityBuilderPreview = ({ activity, onHide, ...others }) => {
  const { t } = useTranslation();
  const [currentActivity] = useField(activity);
  const headerText = t('Completion Preview');
  return (
    <BasicModal
      show
      size="medium"
      buttonText={t('Close')}
      heading={`${t('Previewing')} ${currentActivity.name}`}
      leftBtn={null}
      onConfirm={onHide}
      onHide={onHide}
      {...others}
    >
      <ActivityForm activity={activity} headerText={headerText} />
    </BasicModal>
  );
};

ActivityBuilderPreview.propTypes = {
  onHide: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
};

export default ActivityBuilderPreview;
