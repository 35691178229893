import CustomObjectsService from 'services/CustomObjectsService';
import PipelineService from 'services/PipelineService';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import { isStandardObject } from 'components/Modals/utilities';

import { getColumnEditable } from 'components/Fields/InlineTableFieldInput';

import { CustomFieldHeadCell } from '../CustomFieldHeadCell';

import { SortableHeadCell } from 'components/Kizen/Table';

import { SpacerCell } from 'pages/Common/components/BigTableCells';

import {
  getCellForFieldType,
  CustomObjectNameCell,
  CustomObjectOwnerCell,
  CustomObjectEntityValueCell,
  CustomObjectStageCell,
  CustomObjectEstimatedCloseDateCell,
  CustomObjectActualCloseDateCell,
  CustomObjectDateCreatedCell,
  CustomObjectLastModifiedCell,
  CustomObjectChanceToCloseCell,
} from 'components/Layout/BigTableLayout/InlineCells';
import { DEFAULT_WIDTH_IN_PIXELS, PADDING_IN_PIXELS } from '../constants';

const getDefaultField = (name, fields) => {
  return fields.find((f) => (f.isDefault || !f.isDeletable) && f.name === name);
};

const getEditable = ({ access } = {}) => {
  return Boolean(access && access.edit);
};

export const getBasicColumns = ({
  widthsLookup = {},
  onSubmitRecord,
  model,
  fields,
  handleUpdateFieldOption,
  handleUpdateTableRecords,
  serviceToUse,
  columnSettings,
  t,
  supportsInfiniteScroll,
}) => {
  const nameField = getDefaultField(DEFAULT_FIELD_NAMES.name, fields);
  const ownerField = getDefaultField(DEFAULT_FIELD_NAMES.owner, fields);
  const entityValueField = getDefaultField(
    DEFAULT_FIELD_NAMES.entityValue,
    fields
  );
  const stageField = getDefaultField(DEFAULT_FIELD_NAMES.stage, fields);
  const estimatedCloseDate = getDefaultField(
    DEFAULT_FIELD_NAMES.estimatedCloseDate,
    fields
  );
  const actualCloseDateField = getDefaultField(
    DEFAULT_FIELD_NAMES.actualCloseDate,
    fields
  );
  const percentageChanceToClose = getDefaultField(
    DEFAULT_FIELD_NAMES.percentageChanceToClose,
    fields
  );
  const createdField = getDefaultField(DEFAULT_FIELD_NAMES.created, fields);

  const nameSettings =
    columnSettings?.find((column) => column.id === DEFAULT_FIELD_NAMES.name) ??
    {};

  return {
    ...(nameField
      ? {
          [DEFAULT_FIELD_NAMES.name]: {
            headCell: (
              <SortableHeadCell
                label={nameField?.description}
                padding={PADDING_IN_PIXELS}
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.name] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
              />
            ),
            meta: { editable: getEditable(nameField) },
            isRequired: true,
            errorMessage:
              model.objectType !== 'standard'
                ? t(
                    'The Name and Stage fields are required to create an entity record in Kizen. Please edit and save.'
                  )
                : t(
                    'The Name is required to create an entity record in Kizen. Please edit and save.'
                  ),
            bodyCell: {
              field: nameField,
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.name] ||
                DEFAULT_WIDTH_IN_PIXELS,
              Component: CustomObjectNameCell,
              isCustomObjects: true,
              meta: { editable: getEditable(nameField) },
              isRequired: true,
              forceReadOnly: true,
              editable: false,
              enableLink: nameSettings?.enableLink,
              disabled: true,
              pathPrefix: '/custom-objects/',
              target: '_blank',
              errorMessage:
                model.objectType !== 'standard'
                  ? t(
                      'The Name and Stage fields are required to create an entity record in Kizen. Please edit and save.'
                    )
                  : t(
                      'The Name is required to create an entity record in Kizen. Please edit and save.'
                    ),
            },
          },
        }
      : {}),
    [DEFAULT_FIELD_NAMES.owner]: {
      headCell: (
        <SortableHeadCell
          padding={PADDING_IN_PIXELS}
          minWidth={
            widthsLookup[DEFAULT_FIELD_NAMES.owner] || DEFAULT_WIDTH_IN_PIXELS
          }
        />
      ),
      meta: { editable: getEditable(ownerField) },
      bodyCell: {
        field: ownerField,
        onSubmit: onSubmitRecord,
        serviceToUse,
        model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth:
          widthsLookup[DEFAULT_FIELD_NAMES.owner] || DEFAULT_WIDTH_IN_PIXELS,
        Component: CustomObjectOwnerCell,
        isCustomObjects: true,
        meta: { editable: getEditable(ownerField) },
        forceReadOnly: true,
        editable: false,
        disabled: true,
      },
    },
    ...(entityValueField
      ? {
          [DEFAULT_FIELD_NAMES.entityValue]: {
            headCell: (
              <SortableHeadCell
                label={entityValueField && entityValueField.description}
                numeric
                padding={PADDING_IN_PIXELS}
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.entityValue] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
              />
            ),
            meta: { editable: getEditable(entityValueField) },
            bodyCell: {
              field: entityValueField,
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.entityValue] ||
                DEFAULT_WIDTH_IN_PIXELS,
              Component: CustomObjectEntityValueCell,
              isCustomObjects: true,
              meta: { editable: getEditable(entityValueField) },
              forceReadOnly: true,
              editable: false,
              disabled: true,
            },
          },
        }
      : {}),
    [DEFAULT_FIELD_NAMES.stage]: {
      headCell: (
        <SortableHeadCell
          padding={PADDING_IN_PIXELS}
          minWidth={
            widthsLookup[DEFAULT_FIELD_NAMES.stage] || DEFAULT_WIDTH_IN_PIXELS
          }
        />
      ),
      meta: { editable: getEditable(stageField) },
      isRequired: true,
      errorMessage: t(
        'The Name and Stage fields are required to create an entity record in Kizen. Please edit and save.'
      ),
      bodyCell: {
        field: stageField,
        onSubmit: onSubmitRecord,
        serviceToUse,
        model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth:
          widthsLookup[DEFAULT_FIELD_NAMES.stage] || DEFAULT_WIDTH_IN_PIXELS,
        Component: CustomObjectStageCell,
        isCustomObjects: true,
        meta: { editable: getEditable(stageField) },
        forceReadOnly: true,
        editable: false,
        disabled: true,
      },
    },
    ...(estimatedCloseDate
      ? {
          [DEFAULT_FIELD_NAMES.estimatedCloseDate]: {
            headCell: (
              <SortableHeadCell
                numeric
                padding={PADDING_IN_PIXELS}
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.estimatedCloseDate] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
              />
            ),
            meta: { editable: getEditable(estimatedCloseDate) },
            bodyCell: {
              field: estimatedCloseDate,
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.estimatedCloseDate] ||
                DEFAULT_WIDTH_IN_PIXELS,
              Component: CustomObjectEstimatedCloseDateCell,
              isCustomObjects: true,
              meta: { editable: getEditable(estimatedCloseDate) },
              forceReadOnly: true,
              editable: false,
              disabled: true,
            },
          },
        }
      : {}),
    ...(actualCloseDateField
      ? {
          [DEFAULT_FIELD_NAMES.actualCloseDate]: {
            headCell: (
              <SortableHeadCell
                numeric
                padding={PADDING_IN_PIXELS}
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.actualCloseDate] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
              />
            ),
            bodyCell: {
              field: actualCloseDateField,
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.actualCloseDate] ||
                DEFAULT_WIDTH_IN_PIXELS,
              Component: CustomObjectActualCloseDateCell,
              isCustomObjects: true,
              meta: { editable: getEditable(actualCloseDateField) },
              forceReadOnly: true,
              editable: false,
              disabled: true,
              showDateTooltip: true,
            },
          },
        }
      : {}),

    ...(createdField
      ? {
          [DEFAULT_FIELD_NAMES.created]: {
            headCell: (
              <SortableHeadCell
                numeric
                label={t('Date Created')}
                padding={PADDING_IN_PIXELS}
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.created] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
              />
            ),
            bodyCell: {
              field: {
                access: { view: true, edit: false, remove: false },
                isDefault: true,
              },
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.created] ||
                DEFAULT_WIDTH_IN_PIXELS,
              Component: CustomObjectDateCreatedCell,
              isCustomObjects: true,
              forceReadOnly: true,
              editable: false,
              disabled: true,
              showDateTooltip: true,
            },
          },
        }
      : {}),
    [DEFAULT_FIELD_NAMES.updated]: {
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Last Modified')}
          padding={PADDING_IN_PIXELS}
          minWidth={
            widthsLookup[DEFAULT_FIELD_NAMES.updated] || DEFAULT_WIDTH_IN_PIXELS
          }
        />
      ),
      bodyCell: {
        field: {
          access: { view: true, edit: false, remove: false },
          isDefault: true,
        },
        onSubmit: onSubmitRecord,
        serviceToUse,
        model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth:
          widthsLookup[DEFAULT_FIELD_NAMES.updated] || DEFAULT_WIDTH_IN_PIXELS,
        Component: CustomObjectLastModifiedCell,
        isCustomObjects: true,
        forceReadOnly: true,
        editable: false,
        disabled: true,
        showDateTooltip: true,
      },
    },
    ...(percentageChanceToClose
      ? {
          [DEFAULT_FIELD_NAMES.percentageChanceToClose]: {
            headCell: (
              <SortableHeadCell
                label={
                  percentageChanceToClose?.displayName ||
                  percentageChanceToClose?.description
                }
                numeric
                padding={PADDING_IN_PIXELS}
                minWidth={
                  widthsLookup[DEFAULT_FIELD_NAMES.percentageChanceToClose] ||
                  DEFAULT_WIDTH_IN_PIXELS
                }
              />
            ),
            meta: { editable: getEditable(percentageChanceToClose) },

            bodyCell: {
              field: { ...percentageChanceToClose, isReadOnly: true },
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth:
                widthsLookup[DEFAULT_FIELD_NAMES.percentageChanceToClose] ||
                DEFAULT_WIDTH_IN_PIXELS,
              Component: CustomObjectChanceToCloseCell,
              isCustomObjects: true,
              meta: {
                editable: false,
                stageField,
              },
              forceReadOnly: true,
              editable: false,
              disabled: true,
              supportsInfiniteScroll,
            },
          },
        }
      : {}),
  };
};

export const getCustomRecordsColumns = ({
  model,
  fields,
  columnSettings,
  onSubmitRecord: onSubmitRecordProp,
  handleUpdateFieldOption,
  handleUpdateTableRecords,
  t,
}) => {
  const fieldsById = fields.reduce(
    (collect, field) => ({
      ...collect,
      [field.id]: field,
    }),
    {}
  );

  const widthsLookup = columnSettings.reduce((collect, column) => {
    collect[column.id] = column.width;
    return collect;
  }, {});

  const serviceToUse = isStandardObject(model)
    ? CustomObjectsService
    : PipelineService;

  const basicColumns = getBasicColumns({
    widthsLookup,
    onSubmitRecord: onSubmitRecordProp,
    model,
    fields,
    handleUpdateFieldOption,
    handleUpdateTableRecords,
    serviceToUse,
    supportsInfiniteScroll: true,
    columnSettings,
    t,
  });

  const columnSettingsFiltered = columnSettings.filter(
    ({ id }) =>
      !fields
        .filter(({ isSuppressed }) => isSuppressed)
        .map(({ id }) => id)
        .includes(id)
  );

  return [
    {
      id: 'left-spacer',
      headCell: <SpacerCell width="15px" />,
      cell: <SpacerCell width="15px" />,
    },
    ...columnSettingsFiltered.map(
      ({ id, label, enableLink }) => {
        // id = defaultNameMappings[id] || id;

        if (basicColumns[id]) {
          return {
            id,
            label,
            ...basicColumns[id],
          };
        }
        const field = fieldsById[id];

        return !field
          ? null
          : {
              id,
              label,
              enableLink,
              headCell: (
                <CustomFieldHeadCell
                  field={field}
                  hasSorting={
                    field?.fieldType !== FIELD_TYPES.Relationship.type ||
                    field.relation.cardinality === 'many_to_one' ||
                    field.relation.cardinality === 'one_to_one'
                  }
                  padding={PADDING_IN_PIXELS}
                  minWidth={widthsLookup[id] || DEFAULT_WIDTH_IN_PIXELS}
                  data-qa={`custom-field-${field.label ?? field.displayName}`}
                />
              ),
              bodyCell: {
                field: { ...field, isReadOnly: true },
                onSubmit: onSubmitRecordProp,
                serviceToUse: serviceToUse,
                fetchUrl: `custom-objects/${model.id}`,
                model,
                handleUpdateFieldOption,
                handleUpdateTableRecords,
                minWidth: widthsLookup[id] || DEFAULT_WIDTH_IN_PIXELS,
                Component: getCellForFieldType(field?.fieldType), // cellMap.get(field.fieldType) || MissedCell,
                isCustomObjects: true,
                forceReadOnly: true,
                editable: false,
                enableLink,
                disabled: true,
              },

              meta: {
                editable: getEditable(field) && getColumnEditable(field),
              },
              format: (x) => x,
            };
      },
      {
        id: 'right-spacer',
        headCell: <SpacerCell width="15px" />,
        cell: <SpacerCell width="15px" />,
      }
    ),
  ].filter(Boolean);
};
