import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';

export const StyledInfoWrapper = styled.div`
  ${({ isInherited }) =>
    isInherited
      ? css`
          display: flex;
          justify-content: space-between;
          width: 66px; // ensures a minimum of 10px padding on the right
        `
      : ''}
`;

export const StyledMockPlaceholder = styled(KizenTypography)`
  color: ${grayScale.mediumDark};
`;
