import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { routePathsWithoutNavbar } from './config';

/**
 * Determine if the top app navbar should be shown for the provided path. Routes hava a hidden navbar
 * when their config has both `private` and `hideNavigation` set to true and `external` is undefined/false.
 * See {@link ./config#routePathsWithoutNavbar}
 *
 * @param {string} pathname - the value of window.location.pathname
 * @returns boolean
 */
export const shouldHideNavbarForPath = (pathname) => {
  return routePathsWithoutNavbar.some((path) => matchPath(pathname, { path }));
};

/**
 * Determine if the current pathname provided by {@link react-router-dom#useLocation}
 * should show the top app navbar
 *
 * @returns boolean
 */
export const useShouldHideNavbar = () => {
  const { pathname } = useLocation();
  return useMemo(() => shouldHideNavbarForPath(pathname), [pathname]);
};
