import get from 'lodash/get';
import { FilterDef, XFOr } from './types';
import { isOptionFilterList } from './checks';

type NonOrFilterDef = Exclude<FilterDef, XFOr>;

const eqFilter = ([, path, value]: NonOrFilterDef) => {
  return (opt: any) => get(opt, path) === value;
};

const notEqFilter = ([, path, value]: NonOrFilterDef) => {
  return (opt: any) => get(opt, path) !== value;
};

const boolFilter = ([, path]: NonOrFilterDef) => {
  return (opt: any) => Boolean(get(opt, path));
};

const invertFilter = ([, path]: NonOrFilterDef) => {
  return (opt: any) => !get(opt, path);
};

const orFilter = ([, filters]: XFOr) => {
  return (opt: any): boolean => {
    return filters.some((x: NonOrFilterDef) => Boolean(runFilter(x, opt)));
  };
};

const filters = {
  xf_eq: eqFilter,
  xf_not_eq: notEqFilter,
  xf_invert: invertFilter,
  xf_bool: boolFilter,
  xf_or: orFilter,
};

const runFilter = (x: FilterDef, option: any) => {
  if (x[0] === 'xf_or') return filters.xf_or(x)(option);
  return filters[x[0]] ? filters[x[0]](x)(option) : true;
};

export const optionFilter = (x: FilterDef | FilterDef[]) => {
  return isOptionFilterList(x)
    ? (opt: any) => x.map((f) => runFilter(f, opt)).every(Boolean)
    : (opt: any) => runFilter(x, opt);
};
