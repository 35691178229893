import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';

import { colorsPrimary } from 'app/colors';
import classNames from 'classnames';
import useFormSubmit from 'hooks/useFormSubmit';
import { serviceLoginStart } from 'store/authentication/authenticationAction';

import Input from 'components/Kizen/Input';
import AuthForm from 'components/Kizen/AuthForm';
import Button from 'components/Button';
import { KizenTypography } from 'app/typography';
import loginCounter from './LoginCounter';

import { oauthSessionStorage } from 'utility/_oauthSessionStorage';
import { useLoginState } from './LoginContext';
import AuthenticationService from 'services/AuthenticationService';
import { AnchorButton } from './styles';
import { validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';

import { styles } from './common';
import Icon from 'components/Kizen/Icon';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Kizen/Loader';
import { RedirectLocalStorage } from 'utility/RedirectLocalStorage';

const PASSWORD_OPTIONS = {
  PASSWORD: 'password',
  EXTERNAL: 'external',
  MULTIPLE_EXTERNAL: 'multiple_external',
  COMBINED: 'combined',
};

const setPasswordOption = (passwords, externals) => {
  if (passwords && externals.length) {
    return PASSWORD_OPTIONS.COMBINED;
  }

  if (externals.length) {
    return externals.length > 1
      ? PASSWORD_OPTIONS.MULTIPLE_EXTERNAL
      : PASSWORD_OPTIONS.EXTERNAL;
  }

  return PASSWORD_OPTIONS.PASSWORD;
};

const showEmail = (passwordOption) =>
  [PASSWORD_OPTIONS.COMBINED, PASSWORD_OPTIONS.PASSWORD].includes(
    passwordOption
  );

const showLoginMethod = (passwordOption) =>
  [PASSWORD_OPTIONS.COMBINED, PASSWORD_OPTIONS.MULTIPLE_EXTERNAL].includes(
    passwordOption
  );

const showKizenLogin = (passwordOption) =>
  [PASSWORD_OPTIONS.COMBINED].includes(passwordOption);

const showPassword = (passwordOption) =>
  [PASSWORD_OPTIONS.PASSWORD].includes(passwordOption);

export const Password = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { email, setEmail, password, setPassword } = useLoginState();

  const [username, setUsername] = useState(email);
  const [loginWithKizen, setLoginWithKizen] = useState(false);
  const [ssoUrl, setSsoUrl] = useState(null);

  const { value: loginOptions, loading: loadingLoginOptions } =
    useAsync(async () => {
      const options = await AuthenticationService.preLogin({ username: email });
      const passwords = options.some(
        ({ loginMethod }) => loginMethod === PASSWORD_OPTIONS.PASSWORD
      );
      const externals = options.filter(
        ({ loginMethod }) => loginMethod === PASSWORD_OPTIONS.EXTERNAL
      );

      // the api will always return password option if no others exist
      const passwordOption = setPasswordOption(passwords, externals);

      if (passwordOption === PASSWORD_OPTIONS.EXTERNAL) {
        setSsoUrl(externals[0].signinUrl);
      }

      return { options, passwordOption, externals };
    }, [email]);

  useEffect(() => {
    if (ssoUrl) {
      const timer = setTimeout(() => {
        // this is an en external link so we don't use history.replace
        window.location.replace(ssoUrl);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [ssoUrl]);

  const [submitState, fetchSubmit] = useFormSubmit(
    async (ev) => {
      loginCounter.count(email);
      ev.preventDefault();
      const [error, { chosenBusiness } = {}] = await dispatch(
        serviceLoginStart(email, password)
      );

      const hasRedirect = oauthSessionStorage.val;
      if (hasRedirect && !error && chosenBusiness) {
        // we are loged in, with a valid business and have valid redirect

        oauthSessionStorage.clear();
        history.replace(hasRedirect);
      }
    },
    [email, password]
  );

  const relayState = useMemo(() => {
    const localStorage = new RedirectLocalStorage('loginRedirect');
    return localStorage.path || null;
  }, []);

  return (
    <AuthForm styles={styles} isShowInactivityMessage={false}>
      <div
        className={classNames({
          'Authform-wrapper': true,
          'Authform-wrapper-with-message': false,
        })}
      >
        {loadingLoginOptions && !loginOptions ? (
          // show a loader if we are loading the login options
          <div>
            <KizenTypography
              type="header"
              className="Authform-header"
              data-qa="login-header"
            >
              {t('Login to Kizen')}
            </KizenTypography>
            <Loader loading />
          </div>
        ) : ssoUrl ? (
          // sso login
          <div className="Authform-external">
            <div className="icon">
              <Icon icon="sync" color={colorsPrimary.blue.dark} />
            </div>
            <KizenTypography className="label">
              {t(`We're taking you to your authorized login.`)}
            </KizenTypography>
          </div>
        ) : (
          <>
            <KizenTypography
              type="header"
              className="Authform-header"
              data-qa="login-header"
            >
              {t('Login to Kizen')}
            </KizenTypography>
            {showEmail(loginOptions.passwordOption) ? (
              <Input
                value={username}
                label={t('Email Address')}
                type={Input.INPUT_TYPES.EMAIL}
                required
                className="Authform-input"
                onChange={setUsername}
                disabled
              />
            ) : null}
            {showLoginMethod(loginOptions.passwordOption) ? (
              <>
                <div>
                  <KizenTypography
                    type="subheader"
                    className="Authform-subheader"
                    data-qa="login-subheader"
                  >
                    {t('Choose Login Method')}
                  </KizenTypography>
                </div>
                <div className="Authform-buttons-wrapper">
                  {loginOptions.externals.map(({ name, signinUrl }) => (
                    <div
                      className="Authform-buttons"
                      key={`${name}-${signinUrl}`}
                    >
                      <AnchorButton
                        variant="outline"
                        onClick={(e) => {
                          e.preventDefault();
                          setSsoUrl(
                            relayState
                              ? `${signinUrl}&RelayState=${relayState}`
                              : signinUrl
                          );
                        }}
                        data-qa={`sso-login-with-${name}`
                          .replace(/\s/g, '-')
                          .toLowerCase()}
                      >
                        <span>{name}</span>
                      </AnchorButton>
                    </div>
                  ))}
                  {showKizenLogin(loginOptions.passwordOption) ? (
                    <div className="Authform-buttons">
                      <AnchorButton
                        color="blue"
                        selected={loginWithKizen}
                        onClick={() => setLoginWithKizen(true)}
                        data-qa="login-with-kizen"
                      >
                        <span>{t('Login with Kizen Password')}</span>
                      </AnchorButton>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
            <form action="">
              {loginWithKizen || showPassword(loginOptions.passwordOption) ? (
                <Input
                  value={password}
                  label={t('Password')}
                  className="Authform-password"
                  type={Input.INPUT_TYPES.PASSWORD}
                  required
                  onChange={setPassword}
                  placeholder={t('Password')}
                />
              ) : null}
              <KizenTypography
                type="link"
                weight="bold"
                textTransform="uppercase"
                to="/reset"
                className="Login-help"
              >
                {t('Need help logging in?')}
              </KizenTypography>
              <div className="Authform-actions">
                <div>
                  <KizenTypography
                    type="link"
                    weight="bold"
                    textTransform="uppercase"
                    className="Login-help"
                    onClick={() => setEmail('')}
                  >
                    {t('Change User')}
                  </KizenTypography>
                </div>
                <Button
                  className="Authform-button"
                  onClick={fetchSubmit}
                  disabled={
                    !(email && password) ||
                    submitState.loading ||
                    !validateEmail(username)
                  }
                >
                  {t('Login')}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </AuthForm>
  );
};
