import ChartManager from '../ChartManager';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import { colorsPrimary, colorsSecondary, grayScale } from 'app/colors';

const Y_AXIS_GRID_DISTANCE = 60;
const Y_AXIS_MAX_DEVIATION = 0;

const WIDTHS = {
  SMALL: 550,
  MEDIUM: 1000,
};

export const colors = [
  colorsPrimary.blue,
  colorsPrimary.green,
  colorsPrimary.purple,
  colorsSecondary.brown,
  colorsPrimary.orange,
  colorsSecondary.red,
];

class MultiTrendChartManager extends ChartManager {
  constructor(
    element,
    t,
    data = [],
    {
      yAxisTitle = '',
      tooltipLabels = {},
      mobile = false,
      useAllBullets = true,
      legendColumns,
    },
    rootConfig,
    ready
  ) {
    super(
      element,
      t,
      am5xy.XYChart,
      { panY: false },
      (root) => ({
        layout: root.verticalLayout,
        maxTooltipDistance: -1,
      }),
      rootConfig,
      ready
    );

    this.data = data;
    this.yAxisTitle = yAxisTitle;
    this.tooltipLabels = tooltipLabels;
    this.hasTooltipLabels = Object.keys(this.tooltipLabels).length > 0;
    this.mobile = mobile;
    const hasData = this.data?.some((d) => d.data.some((d) => d.value !== 0));
    this.isEmpty = !hasData;
    this.useAllBullets = useAllBullets;
    this.legendColumns = legendColumns;
  }

  init = () => {
    this.chart.set('dx', -15);
    this.createYAxis();
    this.createXAxis();
    this.createMultiSeries();
    this.setData();
    this.createMultiBullets();
    this.createLegend(this.legendColumns);
    this.configureYAxis(this.data);
    if (!this.isEmpty) {
      this.createCursor(this.mobile);
    }

    this.chart.show();
  };

  // Set the axis minimum if the data is all 0s, so that the data renders
  // along the baseline, not in the center of the chart
  configureYAxis = (data = []) => {
    this.assertYAxis('y-axis configuration');
    if (data) {
      if (this.isEmpty) {
        this.element.setAttribute('data-qa-dashlet-empty', 'true');
        this.yAxis.set('min', 0);
      } else {
        this.element.setAttribute('data-qa-dashlet-empty', 'false');
        this.yAxis.set('min', undefined);
      }
    }
  };

  createCursor = (mobile = false) => {
    this.assertRoot('cursor');
    this.assertChart('cursor');

    this.cursor = am5xy.XYCursor.new(this.root, {
      // We don't want the zoom enabled on mobile because it makes it very difficult to
      // use the cursor
      behavior: mobile ? undefined : 'zoomX',
    });

    this.chart.set('cursor', this.cursor);
  };

  createLegend = (columns) => {
    this.assertRoot('legend');
    this.assertChart('legend');

    this.legend = this.chart.children.push(
      am5.Legend.new(this.root, {
        x: am5.p50,
        centerX: am5.p50,
        useDefaultMarker: true,
        paddingTop: 4,
        paddingBottom: -2,
        layout: am5.GridLayout.new(this.root, {
          maxColumns: columns,
          fixedWidthGrid: true,
        }),
      })
    );
    this.legend.data.setAll(this.chart.series.values);
    this.legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });
    this.legend.markers.template.setAll({
      width: 8,
      height: 8,
    });
    this.legend.labels.template.setAll({
      fill: grayScale.dark,
    });
  };

  setData = () => {
    this.data.forEach((seriesData, index) => {
      const result =
        seriesData?.data?.map((d) => {
          return {
            date: this.getMilliseconds(d.date),
            rawDate: d.date,
            value: d.value,
          };
        }) ?? [];
      this.labelData = result;
      this.xAxis.data.setAll(result);

      this.seriesList[index].data.setAll(result);
    });
  };

  createMultiBullets = () => {
    this.seriesList.forEach((series, index) => {
      series.bullets.push((_root, _series, item) => {
        // Only show the bullet if the value is non-zero
        if (item.dataContext.value !== 0 || this.useAllBullets) {
          return am5.Bullet.new(this.root, {
            sprite: am5.Circle.new(this.root, {
              radius: 3,
              fill: colors[index % colors.length].dark,
              stroke: grayScale.white,
            }),
          });
        }
      });
    });
  };

  // TODO (keegandonley) - as this chart gets used in more places, we'll likely want
  // to use it with percentage values as well and will need a way to determine this state.
  isPercentage = () => false;

  createMultiSeries = () => {
    this.seriesList = [];
    this.data.forEach((series, index) => {
      this.seriesList.push(
        this.chart.series.push(
          am5xy.LineSeries.new(this.root, {
            name: series.name,
            xAxis: this.xAxis,
            yAxis: this.yAxis,
            valueYField: 'value',
            valueXField: 'date',
            stroke: colors[index % colors.length].dark,
            fill: colors[index % colors.length].dark,
            tooltip: this.createStyledTooltip(
              `${series.name}: ${
                this.isPercentage()
                  ? `${this.valueYAccessor}%`
                  : `${this.isValue ? this.currencySymbol : ''}${
                      this.valueYAccessor
                    }`
              }`
            ),
          })
        )
      );
    });
  };

  handleXAxisWidth = (width) => {
    const frequency = this.frequency ?? this.DEFAULT_TIME_UNIT_FREQUENCY;
    const labels = this.getLabelCount(width, frequency);
    const interval = Math.ceil(this.data.length / labels);

    // If there's only 1 data point visible, always label it no matter where it
    // is in the sequence of data
    if (this.visibleDataPoints === 1) {
      this.forceLabelAll = true;
    } else {
      this.forceLabelAll = false;
    }

    // If there are too few data points visible (but more than 1), we need to
    // do some special logic for calculating which labels to show
    if (this.visibleDataPoints <= interval) {
      this.forceLabel = true;
      this.createOrUpdateAxisLabelAdapter(this.labelData, labels, width);
    } else {
      this.forceLabel = false;
      this.createOrUpdateAxisLabelAdapter(this.labelData, labels, width);
    }
  };

  // These label counts were determined by trial-and-error with different
  // chart widths and configurations to find the optimal counts, since we need
  // to manually choose which x-axis labels to hide and show
  getLabelCount = (width, frequency) => {
    if (width < WIDTHS.SMALL) {
      if (frequency === 'day') {
        return 4;
      }
      if (frequency === 'week') {
        return 3;
      }
      if (frequency === 'month') {
        return 4;
      }
    } else if (width < WIDTHS.MEDIUM) {
      if (frequency === 'day') {
        return 6;
      }
      if (frequency === 'week') {
        return 5;
      }
      if (frequency === 'month') {
        return 6;
      }
    } else {
      if (frequency === 'day') {
        return 8;
      }
      if (frequency === 'week') {
        return 6;
      }
      if (frequency === 'month') {
        return 8;
      }
    }

    return 5;
  };

  getManualFrequencyForWidth = (width, visibleCount) => {
    if (width < WIDTHS.SMALL) {
      if (visibleCount <= 3) {
        return 1;
      }
      return 3;
    }
    if (width < WIDTHS.MEDIUM) {
      if (visibleCount <= 2) {
        return 1;
      }
      return 3;
    }
    return 1;
  };

  createOrUpdateAxisLabelAdapter = (data, labelCount = 5, width) => {
    if (this.hasTooltipLabels) {
      const mapping = data.reduce((acc, curr, index) => {
        return {
          ...acc,
          [curr.rawDate]: index,
        };
      }, {});

      const interval = Math.ceil(data.length / labelCount);

      const renderer = this.xAxis.get('renderer');

      renderer.labels.template.adapters.remove('text');
      renderer.labels.template.adapters.add('text', (_text, target) => {
        if (target?.dataItem?.dataContext) {
          const label = target.dataItem.dataContext.rawDate;
          const index = mapping[target.dataItem.dataContext.rawDate];

          // Manually select which labels to show and which to hide based
          // on the selected interval and how many datapoints are visible
          if (index % interval !== 0 && !this.forceLabel && data.length > 3) {
            return undefined;
          }

          // Calculate a secondary frequency for cases when the chart is zoomed in
          const secondaryFrequency = this.getManualFrequencyForWidth(
            width,
            this.visibleDataPoints
          );

          if (
            (label &&
              this.tooltipLabels[label] &&
              (!this.forceLabel ||
                (this.forceLabel && index % secondaryFrequency === 0))) ||
            this.forceLabelAll
          ) {
            return this.tooltipLabels[label];
          }
        }
        return ' ';
      });
    }
  };

  handleZoomChange = (start, end) => {
    const realStart = start ?? this.rangeStart ?? 0;
    const realEnd = end ?? this.rangeEnd ?? 1;

    const range = realEnd - realStart;

    const visibleDataPoints = Math.ceil(this.data.length * range);
    this.visibleDataPoints = visibleDataPoints;

    const w = this.xAxis.getPrivate('width');

    this.handleXAxisWidth(w);

    this.rangeStart = realStart;
    this.rangeEnd = realEnd;
  };

  createXAxis = () => {
    this.assertRoot('x-axis');
    this.assertChart('x-axis');

    this.xAxis = this.chart.xAxes.push(
      am5xy.GaplessDateAxis.new(this.root, {
        baseInterval: {
          timeUnit: this.frequency ?? this.DEFAULT_TIME_UNIT_FREQUENCY,
          count: this.DEFAULT_TIME_UNIT_COUNT,
        },
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 0,
          strokeOpacity: 0,
        }),
        tooltip: this.createStyledTooltip(),
        markUnitChange: false,
      })
    );

    this.xAxis.on('start', (value) => this.handleZoomChange(value));
    this.xAxis.on('end', (value) => this.handleZoomChange(undefined, value));

    const renderer = this.xAxis.get('renderer');

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
    });

    renderer.labels.template.setup = (target) => {
      target.set('dy', 10);
    };

    renderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    if (this.hasTooltipLabels) {
      const tooltip = this.xAxis.get('tooltip');

      tooltip.label.adapters.add('text', (text) => {
        let dataItem;

        // Getting a series in amcharts is tricky, so for this use case we can just iterate
        // until the final one since every series will have the same timescale by definition.
        this.chart.series.each(function (series) {
          const result = series.get('tooltipDataItem') ?? series.dataItems?.[0];
          if (result) {
            dataItem = result;
          }
        });
        if (dataItem) {
          const label = dataItem.dataContext?.rawDate;
          if (label && this.tooltipLabels[label]) {
            return this.tooltipLabels[label];
          }
        }
        return text;
      });

      this.xAxis.onPrivate('width', this.handleXAxisWidth);
    }
  };

  getYAxisLabel = (override, ml, mr) => {
    this.assertRoot('y-axis label');

    if (override) {
      return am5.Label.new(this.root, {
        rotation: -90,
        text: override,
        y: am5.p50,
        centerX: am5.p50,
        marginLeft: ml,
        marginRight: mr,
        fill: grayScale.mediumDark,
      });
    }
    this.assertYAxisTitle('y-axis label');

    const shouldTruncate = this.yAxisTitle.length > 40;
    const displayTitle = shouldTruncate
      ? `${this.yAxisTitle.slice(0, 40)}...`
      : this.yAxisTitle;

    const label = am5.Label.new(this.root, {
      rotation: -90,
      text: displayTitle,
      y: am5.p50,
      centerX: am5.p50,
      fill: grayScale.mediumDark,
      background: am5.Rectangle.new(this.root, {
        fill: am5.color(grayScale.white),
      }),
    });

    if (shouldTruncate) {
      const tooltip = this.createStyledTooltip(
        undefined,
        {
          pointerOrientation: 'left',
          dx: 6,
        },
        {
          width: 300,
          maxWidth: 300,
          oversizedBehavior: 'wrap',
        }
      );
      label.set('tooltip', tooltip);
      label.set('tooltipText', this.yAxisTitle);
    }

    return label;
  };

  createYAxis = (format) => {
    this.assertRoot('y-axis');
    this.assertChart('y-axis');
    this.assertYAxisTitle('y-axis');

    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {
          strokeOpacity: 1,
          minGridDistance: Y_AXIS_GRID_DISTANCE,
          stroke: grayScale.mediumLight,
        }),
        numberFormat: format,
        tooltip: this.createStyledTooltip(),
        maxDeviation: Y_AXIS_MAX_DEVIATION,
      })
    );

    this.yAxis.children.unshift(this.getYAxisLabel());

    const renderer = this.yAxis.get('renderer');

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
    });

    renderer.labels.template.setup = (target) => {
      target.set('dx', -10);
    };

    renderer.grid.template.setAll({
      strokeDasharray: [4, 4],
      stroke: grayScale.mediumLight,
      strokeOpacity: 1,
    });
  };
}

export default MultiTrendChartManager;
