import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import ToggleButton from './..';
import { colorsButton, grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';

export const ICON_TOGGLE_BUTTON_SIZINGS = {
  default: 'default',
  compact: 'compact',
};

export const COLORS_ICON_TOGGLE_BUTTON = {
  blue: {
    selected: grayScale.white,
    default: colorsButton.blue,
  },
  green: {
    selected: grayScale.white,
    default: colorsButton.green,
  },
  red: {
    selected: grayScale.white,
    default: colorsButton.red,
  },

  iconGray: {
    selected: grayScale.white,
    default: colorsButton.iconGray,
  },
  lightRed: {
    selected: grayScale.white,
    default: colorsButton.lightRed,
  },
  disabled: {
    selected: grayScale.mediumDark,
    default: grayScale.medium,
  },
};

export const useIconToggleButton = (options, color, disabled) => {
  const getViewOptions = useCallback(
    (currentValue) => {
      const colours = disabled ? COLORS_ICON_TOGGLE_BUTTON.disabled : color;
      return options.map(({ value, icon }) => ({
        value,
        label: (
          <Icon
            icon={icon}
            color={currentValue === value ? colours.selected : colours.default}
          />
        ),
      }));
    },

    [options, color, disabled]
  );

  return { getViewOptions };
};

export const IconToggleButton = styled(ToggleButton)`
  && button {
    height: 28px;
    padding-top: 0;

    i {
      height: auto;
      svg {
        height: auto;
      }
    }

    ${({ sizing = ICON_TOGGLE_BUTTON_SIZINGS.default }) =>
      sizing === ICON_TOGGLE_BUTTON_SIZINGS.compact
        ? css`
            width: 33px;
            i {
              width: 12px;
              svg {
                width: 12px;
              }
            }
          `
        : css`
            width: 44px;
            i {
              width: 14px;
              svg {
                width: 14px;
              }
            }
          `}
  }
`;
