import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';

export const DIVIDER_TYPE = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};

const StyledDivider = styled.div`
  ${(props) =>
    props.type === DIVIDER_TYPE.HORIZONTAL
      ? css`
          height: 1px;
          width: 85%;
          margin: ${gutters.spacing(2)}px auto;
        `
      : css`
          width: 1px;
          margin: 0 ${gutters.spacing(6)}px;
          height: auto;
        `}
  background-color: ${grayScale.medium};
`;

function Divider({ type, ...props }) {
  return <StyledDivider type={type} {...props} />;
}

Divider.propTypes = {
  type: PropTypes.string,
};

Divider.defaultProps = {
  type: DIVIDER_TYPE.VERTICAL,
};

export default Divider;
