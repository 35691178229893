import isEqual from 'lodash/isEqual';
import { DEFAULT_LINE_HEIGHT_VALUE } from '../constants';
import {
  CustomFieldProps,
  FormFieldProps,
  PageBuilderButton,
  PageBuilderImage,
  PageBuilderNode,
  PageBuilderText,
  PageBuilderTree,
  TextStyles,
} from '../types';
import {
  isCellNode,
  isButtonNode,
  isCustomFieldNode,
  isFormFieldNode,
  isImageNode,
  isRowNode,
  isTextNode,
} from './node-is';

const isBlank = (val: any) => {
  return (
    val === '' ||
    isNullOrUndefiend(val) ||
    (Array.isArray(val) && val.length === 0)
  );
};

const isNullOrUndefiend = (val: any) => {
  return val === null || val === undefined;
};

const booleanEq = (a: any, b: any) => {
  return Boolean(a) === Boolean(b);
};

const eq = (a: any, b: any, ommittedKeys: string[] = []): boolean => {
  if (!isNullOrUndefiend(a) && !isNullOrUndefiend(b) && typeof a !== typeof b) {
    return false;
  }

  if (Array.isArray(a)) {
    if (isBlank(a) && isBlank(b)) return true;
    if (a?.length !== b?.length) return false;
    return a.reduce((acc, val, i) => {
      return acc === true ? eq(val, b[i]) : acc;
    }, true);
  }

  if (a && typeof a === 'object') {
    return Object.keys(a).reduce((acc: boolean, key) => {
      return ommittedKeys.includes(key) || acc === false
        ? acc
        : eq(a[key], b[key]);
    }, true);
  }

  if (typeof a === 'boolean' || typeof b === 'boolean') {
    return booleanEq(a, b);
  }

  return (isNullOrUndefiend(a) && isNullOrUndefiend(b)) || a === b;
};

/**
 * Checks if two text styles arrays are equal (ignores the order of elements).
 *
 * @param s1 - node #1 text styles prop
 * @param s2 - node #2 text styles prop
 * @returns boolean
 */
const textStylesEq = (
  s1: TextStyles | undefined,
  s2: TextStyles | undefined
) => {
  if (isNullOrUndefiend(s1) && isNullOrUndefiend(s2)) return true;
  if (s1?.length !== s2?.length) return false;
  const set = new Set(s1);
  return s2?.every((x) => set.has(x)) ?? false;
};

/**
 * Node columns are initially created as array of integers to determine their relative sizes.
 * When resizing these values are converted and saved as decimals decimals (percents). See
 * src/components/PageBuilder/nodes/Row/useResizeDragHandlers for more details.
 *
 * @param c1 - node #1 columns prop
 * @param c2 - node #2 columns prop
 * @returns - boolean
 */
const columnsPropEq = (c1: number[] | undefined, c2: number[] | undefined) => {
  if (!Array.isArray(c1) || !Array.isArray(c2)) return false;
  if (c1.length !== c2.length) return false;

  const isIntegerSize = (x: number) => x >= 1;
  const intSizesToPercents = (x: number, _: number, arr: number[]) =>
    (x * 60) / arr.length / 60;
  const columnSizeEq = (a: number, b: number) => a.toFixed(2) === b.toFixed(2);
  const one = c1.every(isIntegerSize) ? c1.map(intSizesToPercents) : c1;
  const two = c2.every(isIntegerSize) ? c2.map(intSizesToPercents) : c2;
  return one.every((size, i) => columnSizeEq(size, two[i]));
};

/**
 * Compares two nodes' field props for equality, handling certain props separately and ignoring others.
 *
 * @remarks Special comparisions:
 *   - `labelText` is not set by default and falls back to use displayName
 *   - `placeholder` needs to treat null, undefined, and '' the same
 *   - `fontStyles` requires the `textStylesEq` comparison to ignore the array order.
 *
 * @param f1 - node #1 field prop
 * @param f2 - node #2 field props
 * @returns - boolean
 */
const fieldPropEq = (
  f1: CustomFieldProps['field'] | FormFieldProps['field'],
  f2: CustomFieldProps['field'] | FormFieldProps['field']
) => {
  if (isNullOrUndefiend(f1) && isNullOrUndefiend(f2)) return true;
  if (!f1 || !f2) return false;

  const labelTextEqual =
    f1.labelText === f2.labelText || f1.labelText === f2.displayName;
  const placeholderEqual =
    (isBlank(f1.placeholder) && isBlank(f2.placeholder)) ||
    f1.placeholder === f2.placeholder;
  const fontStylesEqual = textStylesEq(f1.fontStyle, f2.fontStyle);

  return (
    labelTextEqual &&
    placeholderEqual &&
    fontStylesEqual &&
    eq(f1, f2, [
      'customObjectField',
      'options',
      'displayName',
      'labelText',
      'placeholder',
      'fontStyle',
    ])
  );
};

/**
 * Compares two node props for equality. 'columnContents' is always ignored since it is a
 * legacy attribute
 *
 * @param n1 - node #1
 * @param n2 - node #2
 * @param ommittedKeys - array of props to ignore
 * @returns boolean
 */
const nodePropsEq = (
  n1: PageBuilderNode,
  n2: PageBuilderNode,
  ommittedKeys: string[] = []
) => {
  const columnsSizesEqual =
    isRowNode(n1) && isRowNode(n2)
      ? columnsPropEq(n1.props.columns, n2.props.columns)
      : true;
  const fieldPropEqual =
    (isCustomFieldNode(n1) && isCustomFieldNode(n2)) ||
    (isFormFieldNode(n1) && isFormFieldNode(n2))
      ? fieldPropEq(n1.props.field, n2.props.field)
      : true;

  return (
    columnsSizesEqual &&
    fieldPropEqual &&
    eq(n1.props, n2.props, [
      ...ommittedKeys,
      'containerWidth', // non-configurable prop
      'columnContents', // old/unused
      'columns', // compared separately
      'field', // compared separately
    ])
  );
};

/**
 * Custom text content comparision that is necessary after adding line heights to paragraph elements.
 * When clicking into an already saved form/email the line height style and data- attribute will be added to
 * support the new behavior. If the saved copy of the text content has no line-height style, we need to
 * remove that style and attribute from the current content when checking for equality.
 *
 * @remarks If there are more styling features added that require manipulation like this we should use an
 * XML parser instead of adding more regular expressions.
 *
 * @param c1 - the current text content of the node.
 * @param c2 - the saved node's text content.
 * @returns - boolean
 */
const textContentEq = (c1: string, c2: string) => {
  const lh_check = '<p style="line-height';
  if (c1.indexOf(lh_check) === -1 || c2.indexOf(lh_check) !== -1) {
    // We can do the standard compare once the saved data (c2) has been saved with line height data.
    // We can also do a standard compare if the current data does not have line height data meaning
    // the old text node was not click on.
    return c1 === c2;
  }

  const current_sanitized = c1
    .replaceAll(
      new RegExp(
        // hanlde empty paragraphs - we know the exact contents of its style and attributes
        `<p style="line-height: ${DEFAULT_LINE_HEIGHT_VALUE}" data-line-height="default">`,
        'g'
      ),
      '<p>'
    )
    .replaceAll(
      new RegExp(
        // a paragraph may have text-align styling - remove the line-height style and retain the rest
        // while discarding the trailing semicolon if it exists
        `<p style="line-height: ${DEFAULT_LINE_HEIGHT_VALUE};? ?(.*?")`,
        'g'
      ),
      '<p style="$1'
    )
    // remove the default data-line-height attribute
    .replaceAll(new RegExp(' ?data-line-height="default"', 'g'), '');
  return (
    current_sanitized ===
    // remove the trailing semicolon from all paragraphs in the saved content to match what we did above
    // also removing all new lines since some saved content seems to have extra spaces
    c2
      .replaceAll(new RegExp('<p style="(.*);"', 'g'), '<p style="$1"')
      .replaceAll(/[\n\s]{2,}/g, '')
      .replaceAll(/\n/g, '')
  );
};

/**
 * Same as {@link nodeEq} but handles the textStyles prop separately.
 *
 * @param n1 - button node #1
 * @param n2 - button node #2
 * @returns - boolean
 */
const buttonEq = (n1: PageBuilderButton, n2: PageBuilderButton) => {
  return (
    textStylesEq(n1.props.textStyles, n2.props.textStyles) &&
    eq(n1.props, n2.props, ['textStyles']) &&
    nodeEq(n1, n2)
  );
};

const textEq = (n1: PageBuilderText, n2: PageBuilderText) => {
  return (
    textContentEq(n1.custom.text, n2.custom.text) &&
    nodePropsEq(n1, n2) &&
    eq(n1, n2, ['props', 'custom', 'displayName', 'name'])
  );
};

/**
 * Compares two image nodes for equality. Uses a deep equality check on
 * the `custom` property because that is where we store dynamic images
 * and filter data.
 *
 * @param n1 - image node 1
 * @param n2 - image node 2
 * @returns - boolean
 */
const imageEq = (n1: PageBuilderImage, n2: PageBuilderImage) => {
  return isEqual(n1.custom, n2.custom) && nodeEq(n1, n2, ['custom']);
};

const nodeEq = (
  n1: PageBuilderNode,
  n2: PageBuilderNode,
  ommittedKeys: string[] = []
) => {
  return (
    nodePropsEq(n1, n2) &&
    eq(n1, n2, ['props', 'displayName', 'name', ...ommittedKeys])
  );
};

/**
 * Compares two page builder trees for equality.
 *
 * @param t1 - Record<string, PageBuilderNode>
 * @param t2 - Record<string, PageBuilderNode>
 * @returns - boolean
 */
export const areTreesEqual = (t1: PageBuilderTree, t2: PageBuilderTree) => {
  let result = true;

  const t1_keys = Object.keys(t1);
  const t2_keys = Object.keys(t2);

  if (t1_keys.length !== t2_keys.length) {
    return false;
  }

  if (!t2_keys.every((key) => Boolean(t1[key]))) {
    return false;
  }

  for (const id of t1_keys) {
    const node1 = t1[id];
    const node2 = t2[id];

    if (!node1 || !node2) {
      result = false;
    } else if (isCellNode(t1[id])) {
      result = nodeEq(node1, node2, ['columns']);
    } else if (isButtonNode(node1) && isButtonNode(node2)) {
      result = buttonEq(node1, node2);
    } else if (isTextNode(node1) && isTextNode(node2)) {
      result = textEq(node1, node2);
    } else if (isImageNode(node1) && isImageNode(node2)) {
      result = imageEq(node1, node2);
    } else {
      result = nodeEq(node1, node2);
    }

    if (!result) break;
  }
  return result;
};
