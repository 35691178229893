import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import { useEndsOfYScroll } from '__components/Kizen/LazyLoad/helpers';
import { useRef } from 'react';
import { StyledFullscreenModal, MobileContainer } from '../../styles';
import { SubNavigation } from './SubNavigation';
import { Steps } from './steps';
import { Header } from './Header';
import { PrimaryObjectUploadProvider } from './PrimaryObjectUploadProvider';
import { ConfirmationManager } from './ConfirmationManager';

export const PrimaryObjectUploadWizard = () => {
  const { t } = useTranslation();
  const mobileDisplay = useBreakpoint();

  const bodyRef = useRef<HTMLDivElement>(null);
  const [scrolledTop] = useEndsOfYScroll(bodyRef, []);

  return (
    <PrimaryObjectUploadProvider>
      <StyledFullscreenModal
        ref={bodyRef}
        show
        enforceFocus={false}
        header={<Header scrolledTop={scrolledTop} />}
        scrolledTop={scrolledTop}
      >
        {mobileDisplay ? (
          <MobileContainer>
            <KizenTypography>
              {t(
                'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
              )}
            </KizenTypography>
          </MobileContainer>
        ) : (
          <>
            <SubNavigation />
            <Steps />
          </>
        )}
      </StyledFullscreenModal>
      <ConfirmationManager />
    </PrimaryObjectUploadProvider>
  );
};
