import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyledLoader } from '../styled';
import { BROADCAST_FORMDATA, CONTENT_COMPONENTS } from '../constants';
import { getContent } from '../helpers';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

export const BroadcastContentBuilder = (props) => {
  const { type, action, name, setFormData } = props;
  const actionRef = useRef({ action, name, type });

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState(null);

  const resetFormDataAction = useCallback(() => {
    setFormData(BROADCAST_FORMDATA.action, null);
    setContent(EMPTY_OBJECT);
  }, [setFormData]);

  useEffect(() => {
    if (actionRef.current.action?.deleted) {
      resetFormDataAction();
      return;
    }
    setLoading(true);
    getContent(type, actionRef.current.action)
      .then(({ actionData, actionFormData }) => {
        setFormData(BROADCAST_FORMDATA.action, actionFormData);
        setFormData(BROADCAST_FORMDATA.name, actionRef.current.name);
        setContent(actionData);
      }, resetFormDataAction)
      .finally(() => setLoading(false));
  }, [type, setFormData, resetFormDataAction]);

  const BroadcastPicker = useMemo(() => CONTENT_COMPONENTS[type], [type]);

  return (
    <StyledLoader loading={loading || !content}>
      <BroadcastPicker {...props} action={content} />
    </StyledLoader>
  );
};
