import { useTranslation } from 'react-i18next';
import { useEditor } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import {
  ToggleButton,
  ToolbarIcon,
  TextEditorToolbarSection,
} from '../../styles';
import React from 'react';
import { grayScale } from 'app/colors';
export const TextAlignToolbarSection = () => {
  const { t } = useTranslation();
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  if (!editor) return null;

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <ToggleButton
        active={editor.isActive({ textAlign: 'left' })}
        title={t('Align Left')}
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
      >
        <ToolbarIcon icon="align-left" color={grayScale.dark} />
      </ToggleButton>
      <ToggleButton
        active={editor.isActive({ textAlign: 'center' })}
        title={t('Align Center')}
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
      >
        <ToolbarIcon icon="align-center" color={grayScale.dark} />
      </ToggleButton>
      <ToggleButton
        active={editor.isActive({ textAlign: 'right' })}
        title={t('Align Right')}
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
      >
        <ToolbarIcon icon="align-right" color={grayScale.dark} />
      </ToggleButton>
      <ToggleButton
        active={editor.isActive({ textAlign: 'justify' })}
        title={t('Justify')}
        onClick={() => editor.chain().focus().setTextAlign('justify').run()}
      >
        <ToolbarIcon icon="align-justify" color={grayScale.dark} />
      </ToggleButton>
    </TextEditorToolbarSection>
  );
};
