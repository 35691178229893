import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Kizen/Loader';
import { Header, Cell, Wrapper, DataContainer } from './styles';
import KizenTypography from 'app/kizentypo';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import { DISPLAY_VALUE_RESPONSES } from 'components/Wizards/Dashlet/types';
import ChartRow, { PERCENT_COLUMN_WIDTH } from './components/ChartRow';
import useScroll from './hooks/useScroll';
import NoData from '../components/NoData';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { useTranslation } from 'react-i18next';
import { gutters } from 'app/spacing';
import { isEqual } from 'lodash';

const DEFAULT_COLUMN_WIDTH = 0;
const DEFAULT_MAX_BAR_WIDTH = 100;
const STAGE_NAME_MAX_WIDTH = {
  MOBILE: 90,
  DESKTOP: 180,
};

const OpportunityConversionChart = ({
  data,
  dashlet,
  isLoading,
  pipeline,
  currencySymbol,
  mobile,
}) => {
  const { t } = useTranslation();
  const [maxWidth, setMaxWidth] = useState(DEFAULT_COLUMN_WIDTH);
  const { containerProps, headerProps, fadeProps } = useScroll([data, dashlet]);

  const isValue = dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE;
  const valuePrefix = isValue ? currencySymbol : '';

  const dataForDisplay = useMemo(() => {
    const values = data?.data?.find((d) => d.type === 'values')?.values ?? [];
    const conversionRate =
      data?.data?.find((d) => d.type === 'conversion_rate')?.values ?? [];

    const combined = values.map((v, index) => {
      const stage = data?.metadata?.stages?.find((s) => s.id === v.id);
      return {
        ...stage,
        value: v.value,
        conversionRate: conversionRate[index].value,
      };
    });

    const { open, won, rest } = combined.reduce(
      (acc, curr) => {
        if (curr.status === 'open') {
          acc.open.push(curr);
        } else if (curr.status === 'won') {
          acc.won.push(curr);
        } else {
          acc.rest.push(curr);
        }
        return acc;
      },
      { open: [], won: [], rest: [] }
    );

    return [...open, ...won, ...rest];
  }, [data]);

  const maxBarValue = dataForDisplay[0]?.value ?? DEFAULT_MAX_BAR_WIDTH;

  const openCount = useMemo(() => {
    return dataForDisplay.filter((d) => d.status === 'open').length;
  }, [dataForDisplay]);

  const stageNameMaxWidth = mobile
    ? STAGE_NAME_MAX_WIDTH.MOBILE
    : STAGE_NAME_MAX_WIDTH.DESKTOP;
  const stageNameWidth =
    maxWidth > stageNameMaxWidth ? stageNameMaxWidth : maxWidth;

  const handleWidthMeasurementChange = useCallback((width) => {
    setMaxWidth((res) => {
      return width > res ? width : res;
    });
  }, []);

  return (
    <Loader loading={isLoading}>
      {dataForDisplay.length > 0 ? (
        <Wrapper>
          <Header mobile={mobile} {...headerProps}>
            <Cell widthPixels={stageNameWidth} textAlign="right">
              <KizenTypography type="label" weight="bold">
                {t('Stage')}
              </KizenTypography>
            </Cell>
            <Cell widthPixels={PERCENT_COLUMN_WIDTH} textAlign="center">
              <KizenTypography type="label" weight="bold">
                %
              </KizenTypography>
            </Cell>
            <Cell right={gutters.spacing(3)}>
              <TextEllipsisWithTooltip type="label" weight="bold">
                {isValue ? t('Value of') : t('Number of')}{' '}
                {pipeline?.entityName ?? ''} {t('Records')}
              </TextEllipsisWithTooltip>
            </Cell>
          </Header>
          <DataContainer key={data} {...containerProps}>
            <ScrollFadeContainer {...fadeProps}>
              {dataForDisplay.map((row, index) => {
                return (
                  <ChartRow
                    key={row.id}
                    row={row}
                    index={index}
                    mobile={mobile}
                    dataForDisplay={dataForDisplay}
                    stageNameWidth={stageNameWidth}
                    handleWidthMeasurementChange={handleWidthMeasurementChange}
                    maxBarValue={maxBarValue}
                    openCount={openCount}
                    valuePrefix={valuePrefix}
                  />
                );
              })}
            </ScrollFadeContainer>
          </DataContainer>
        </Wrapper>
      ) : (
        <NoData />
      )}
    </Loader>
  );
};

OpportunityConversionChart.propTypes = {
  data: PropTypes.object,
  dashlet: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  pipeline: PropTypes.object,
  currencySymbol: PropTypes.string,
  mobile: PropTypes.bool,
};

OpportunityConversionChart.defaultProps = {
  data: { data: [] },
  isLoading: true,
  pipeline: {},
  currencySymbol: '',
  mobile: false,
};

const propsAreEqual = (prevProps, nextProps) => {
  const dataEqual = isEqual(prevProps.data, nextProps.data);
  const dashletEqual = isEqual(prevProps.dashlet, nextProps.dashlet);
  const pipelineEqual = isEqual(prevProps.pipeline, nextProps.pipeline);
  const currencySymbolEqual =
    prevProps.currencySymbol === nextProps.currencySymbol;
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const mobileEqual = prevProps.mobile === nextProps.mobile;

  return (
    dataEqual &&
    dashletEqual &&
    isLoadingEqual &&
    mobileEqual &&
    pipelineEqual &&
    currencySymbolEqual
  );
};

export default React.memo(OpportunityConversionChart, propsAreEqual);
