import React, { useCallback, useContext, useMemo } from 'react';
import { AREA_RESPONSES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { useTranslation } from 'react-i18next';
import Select from 'components/Inputs/Select';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import PropTypes from 'prop-types';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';

const EmailsArea = ({ disabled }) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);
  const { area, reportType } = state;
  const preReleaseFeatures = usePreReleaseFeatures();

  const dashletReportTypes = useMemo(() => {
    const opts = [
      {
        label: t('Emails Sent'),
        value: REPORT_TYPES.EMAILS_SENT,
        enabled: true,
      },
      {
        label: t('Email Delivery %'),
        value: REPORT_TYPES.DELIVERY_PERCENT,
        enabled: true,
      },
      {
        label: t('Email Complaint %'),
        value: REPORT_TYPES.COMPLAINT_PERCENT,
        enabled: true,
      },
      {
        label: t('Email Opt Out %'),
        value: REPORT_TYPES.OPT_OUT_PERCENT,
        enabled: true,
      },
      {
        label: t('Email Interaction Stats'),
        value: REPORT_TYPES.INTERACTION_STATS,
        enabled: true,
      },
    ];

    return opts
      .filter((opt) => opt.enabled || preReleaseFeatures)
      .sort(({ label: nameA }, { label: nameB }) => {
        return nameA.localeCompare(nameB);
      });
  }, [t, preReleaseFeatures]);

  const reportTypeValue = useMemo(() => {
    if (reportType?.value) {
      return {
        value: reportType.value,
        label: dashletReportTypes.find((r) => r.value === reportType?.value)
          ?.label,
      };
    }
    return reportType;
  }, [reportType, dashletReportTypes]);

  const reportTypeLabel = t('Choose Report Type');

  const reportTypeOnChange = useCallback(
    (value) => {
      return setState('reportType', value);
    },
    [setState]
  );

  // Hide self if the area is not emails
  if (area !== AREA_RESPONSES.EMAILS) {
    return null;
  }

  return (
    <StyledWizardField>
      <KizenTypography type="subheader">{reportTypeLabel}</KizenTypography>
      <Select
        value={reportTypeValue}
        onChange={reportTypeOnChange}
        captureMenuScroll={false}
        className="modal-select report-type"
        placeholder={reportTypeLabel}
        options={dashletReportTypes}
        disabled={disabled}
      />
    </StyledWizardField>
  );
};

EmailsArea.propTypes = {
  active: PropTypes.bool,
};

export default EmailsArea;
