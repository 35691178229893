import AxiosService, { DefaultAxiosRetryConfig } from './AxiosService';
import { invalidate } from 'queries/invalidate';
import {
  FORCE_ALL_RECORDS_SIZE,
  camelToSnakeCaseKeys,
  snakeToCamelCaseKeys,
  fieldForApp,
  categoryForApp,
} from './helpers';
import {
  checkDataExistsInFilter,
  filterDataMatches,
} from './CustomObjectsService';
import { TIMEZONE_OFFSET_HEADER } from './constants';
import { getTimezoneOffset } from './utils';

const getOptionsList = (element) => ({
  label: element.name,
  value: element.id,
});

class PipelineService {
  constructor() {
    if (!PipelineService.instance) {
      PipelineService.instance = this;
    }
    return PipelineService.instance;
  }

  // because the service gets frozen, we put the abortControllers in an object so they can be updated
  abortControllers = {
    getBoardData: undefined,
  };
  pipelineEndpoint = '/deal-pipeline';

  objectEndpoint = 'pipelines';

  getPipelineOptionsList = async () => {
    const { data } = await AxiosService.get(this.pipelineEndpoint);

    return data.results.map(getOptionsList);
  };

  getStageOptionsList = async ({ id }) => {
    const { data } = await AxiosService.get(this.pipelineEndpoint);

    const currentPipeline = data.results.find((el) => el.id === id);
    if (!currentPipeline) return [];

    return currentPipeline.stages.map(getOptionsList);
  };

  getStagesListByModel = async (id, opts) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${id}/stages`,
      opts
    );
    return snakeToCamelCaseKeys(data);
  };

  getPipelineRecord = async ({ id, objectId }, params) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/entity-records/${id}`,
      params
    );
    return snakeToCamelCaseKeys(data);
  };

  createPipelineRecord = async (objectId, body, params = {}) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/entity-records/add`,
      camelToSnakeCaseKeys(body),
      params
    );
    return snakeToCamelCaseKeys(data);
  };

  updatePipelineRecord = async (
    id,
    objectId,
    body,
    options,
    updateTimeline = true
  ) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/entity-records/${id}`,
      camelToSnakeCaseKeys(body),
      options
    );
    if (updateTimeline) {
      invalidate.TIMELINE.ALL();
    }
    return snakeToCamelCaseKeys(data);
  };

  archiveCustomObjectRecord = async ({ recordId, modelId }) => {
    await AxiosService.delete(
      `/${this.objectEndpoint}/${modelId}/entity-records/${recordId}`
    );
  };

  getBoardData = async ({ id, params, body, signal }, options) => {
    const { criteria, fieldIds } = body;
    const additionalConfig = options || {};

    if (
      criteria &&
      checkDataExistsInFilter(criteria, filterDataMatches.timezoneRelated)
    ) {
      additionalConfig.headers = {
        [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
      };
    }

    const bodyParams = {
      ...camelToSnakeCaseKeys(criteria),
      field_ids: fieldIds || [],
    };

    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${id}/board-view`,
      bodyParams,
      { signal, params: camelToSnakeCaseKeys(params), ...additionalConfig }
    );

    return snakeToCamelCaseKeys(data);
  };

  getBoardDataCancelable = async (props, options) => {
    // Check if there are any previous pending requests
    if (this.abortControllers.getBoardData) {
      this.abortControllers.getBoardData.abort();
    }
    // Save the abortController for the current request
    this.abortControllers.getBoardData = new AbortController();

    return this.getBoardData(
      {
        ...props,
        signal: this.abortControllers.getBoardData.signal,
      },
      options
    );
  };

  getRecordsInStage = async ({ stageId, modelId, params, body }, options) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${modelId}/stages/${stageId}/records`,
      camelToSnakeCaseKeys(body.criteria),
      { ...options, params: camelToSnakeCaseKeys(params) }
    );

    return snakeToCamelCaseKeys(data);
  };

  getBoardSettings = async (id) => {
    const { data } = await AxiosService.get(`/pipelines/${id}/board-style`);
    return snakeToCamelCaseKeys(data);
  };

  updateBoardSettings = async ({ id, body }) => {
    const { data } = await AxiosService.put(
      `/pipelines/${id}/board-style`,
      camelToSnakeCaseKeys(body)
    );

    return snakeToCamelCaseKeys(data);
  };

  moveRecord = async ({ id, payload }, options) => {
    const { data } = await AxiosService.post(
      `/pipelines/${id}/entity-records/move`,
      camelToSnakeCaseKeys(payload),
      options
    );

    return snakeToCamelCaseKeys(data);
  };

  // these are the "new" endpoints with matching names in the FieldService

  getObjects = async (
    params = { pageSize: FORCE_ALL_RECORDS_SIZE },
    opts = {}
  ) => {
    const { data } = await AxiosService.get(`/${this.objectEndpoint}`, {
      params: camelToSnakeCaseKeys(params),
      ...opts,
    });
    return data.results.map(snakeToCamelCaseKeys);
  };

  getObjectCategories = async (objectId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/categories`
    );
    return snakeToCamelCaseKeys(data);
  };

  createObjectCategory = async (objectId, payload) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/categories`,
      camelToSnakeCaseKeys(payload)
    );
    return categoryForApp(data);
  };

  patchObjectCategory = async (objectId, id, payload) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/categories/${id}`,
      camelToSnakeCaseKeys(payload)
    );
    return categoryForApp(data);
  };

  deleteObjectCategory = async (objectId, id) => {
    const { data } = await AxiosService.delete(
      `/${this.objectEndpoint}/${objectId}/categories/${id}`
    );
    return data;
  };

  getObjectFields = async (objectId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/fields`
    );
    return fieldForApp(data);
  };

  getObjectFieldsWithoutRestrictions = async (objectId, options) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/fields/settings-search`,
      options
    );
    return fieldForApp(data);
  };

  getObjectField = async (id, objectId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/fields/${id}`
    );
    return fieldForApp(data);
  };

  createObjectField = async (objectId, payload, options) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/fields`,
      camelToSnakeCaseKeys(payload),
      { ...options, 'axios-retry': DefaultAxiosRetryConfig }
    );
    return fieldForApp(data);
  };

  patchObjectField = async (objectId, id, payload, options) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/fields/${id}`,
      camelToSnakeCaseKeys(payload),
      options
    );
    return fieldForApp(data);
  };

  deleteObjectField = async (objectId, id) => {
    const { data } = await AxiosService.delete(
      `/${this.objectEndpoint}/${objectId}/fields/${id}`
    );
    return data;
  };

  updateObjectStyles = async (objectId, categories) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/update-styles`,
      camelToSnakeCaseKeys({
        categories: categories.map(({ id, name, fields }) => ({
          id,
          name,
          fields: fields.map((field) => ({
            id: field.id,
            isHidden: field.isHidden,
            // Must ensure cols are set
            meta: { ...field.meta, cols: field.meta.cols || 1 },
          })),
        })),
      })
    );
    invalidate.FIELDS.CUSTOM_OBJECTS('pipeline', objectId);
    return snakeToCamelCaseKeys(data);
  };

  createDynamicTag = async ({ fieldId, name, objectId }, options) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options`,
      {
        name,
      },
      options
    );
    return data;
  };

  deleteDynamicTag = async ({ fieldId, tagId, objectId }, options) => {
    await AxiosService.delete(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${tagId}`,
      options
    );
  };

  updateDynamicTag = async ({ fieldId, tagId, objectId, name }, options) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${tagId}`,
      {
        name,
      },
      options
    );
    return data;
  };

  replaceDynamicTag = async (
    origTagId,
    replacementTagId,
    fieldId,
    objectId
  ) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${origTagId}/replace`,
      camelToSnakeCaseKeys({ optionId: replacementTagId })
    );
    return data;
  };

  getDynamicTagsOptions = async (fieldId, objectId, page, search, options) => {
    const { data } = await AxiosService.get(
      `/${
        this.objectEndpoint
      }/${objectId}/fields/${fieldId}/tags?ordering=name&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}`,
      options
    );
    return data;
  };

  getDynamicTagsOptionsOrdered = async (
    fieldId,
    objectId,
    page,
    search,
    ordering = 'name',
    options
  ) => {
    const { data } = await AxiosService.get(
      `/${
        this.objectEndpoint
      }/${objectId}/fields/${fieldId}/options?ordering=${ordering}&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}&include_entity_count=true`,
      options
    );
    return data;
  };
}

const instance = new PipelineService();
Object.freeze(instance);

export default instance;
