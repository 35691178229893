import { useContext } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { FlowBuilderProvider } from './FlowBuilderProvider';
import { FlowBuilderContext } from './context';
import { Flow } from './Flow';
import Loader from '__components/Kizen/Loader';
import { FlowBar } from './styles';
import { ControlBar } from './ControlBar';
import { FlowScrollContainer } from 'ts-components/AutomationEngine/FlowScrollContainer';
import { useNavBarState } from '__app/navBarController';
import { Wrapper } from '__pages/AutomationEngine/styles';
import { UploadRelatedObjectModal } from './UploadRelatedObjectModal';
import ConfirmNavigationModal from '__components/Modals/presets/ConfirmNavigation';
import { AdditionalVariablesModal } from './AdditioanalVariablesModal';
import { NODE_TYPES } from '__pages/AutomationEngine/Engine/Flow/layout';

export const FlowBuilderView = () => {
  const { height } = useNavBarState();

  const { smartConnectorLoading, setInteracting, droppedStep, isDirty } =
    useContext(FlowBuilderContext);

  return (
    <>
      <Wrapper navHeight={height} className="wrapper">
        <ControlBar className="ControlBar" />
        <FlowBar />
        <Loader loading={smartConnectorLoading}>
          <FlowScrollContainer setInteracting={setInteracting}>
            <Flow />
          </FlowScrollContainer>
        </Loader>
      </Wrapper>
      {droppedStep?.step.type === NODE_TYPES.upload ? (
        <UploadRelatedObjectModal index={droppedStep.index} />
      ) : null}
      {droppedStep?.step.type ===
      NODE_TYPES.connection_intialize_additional_variables ? (
        <AdditionalVariablesModal />
      ) : null}
      <ConfirmNavigationModal when={isDirty} />
    </>
  );
};

export const FlowBuilder = () => {
  return (
    <ReactFlowProvider>
      <FlowBuilderProvider>
        <FlowBuilderView />
      </FlowBuilderProvider>
    </ReactFlowProvider>
  );
};
