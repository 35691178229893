import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters, scrollbarCss } from '../../../app/spacing';
import StylePassthrough from '../../Kizen/StylePassthrough';
import Pill from '../../Kizen/Pill';
import { TextEllipsisWithTooltip } from '../../Kizen/Table';
import { Control as SelectControl } from '../Select/customize';

const SelectedValuesBase = React.forwardRef(
  (
    { value, disabled, setValue, PillComponent, hideRemoveIcon, ...props },
    ref
  ) => {
    return (
      <div ref={ref} {...props}>
        {value.map((val) => (
          <Pill
            key={val.value}
            id={val.value}
            name={val.label}
            hideRemoveIcon={disabled || hideRemoveIcon}
            PillComponent={PillComponent}
            onRemove={() =>
              setValue(
                value.filter((v) => v.value !== val.value),
                'remove-value',
                val.value,
                val.label
              )
            }
          />
        ))}
        {/* four spacers allows for up to five
         * correctly-sized columns of tags */}
        {!!value.length && (
          <>
            <span />
            <span />
            <span />
            <span />
          </>
        )}
      </div>
    );
  }
);

SelectedValuesBase.displayName = 'SelectedValuesBase';

SelectedValuesBase.propTypes = {
  value: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  PillComponent: PropTypes.elementType,
  hideRemoveIcon: PropTypes.bool,
};

SelectedValuesBase.defaultProps = {
  PillComponent: null,
  hideRemoveIcon: false,
};

const SelectedValues = styled(SelectedValuesBase)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: scroll;
  max-height: 126px;
  ${scrollbarCss({ track: false })}
  &::-webkit-scrollbar-track:vertical {
    // Align top of scrollbar with top of pills
    margin-top: ${gutters.spacing(2)}px;
  }
  &:empty {
    display: none;
  }
  && > * {
    flex: 1 0 calc(115px - 1px); // 1px accounts for borders, so two pills can show in mobile-sized selector
    min-width: 0;
    // Unclear why setting width in addition to min-width is needed,
    // but it fixed an odd bug where the hidden, overflowed text in the
    // pill could actually stretch .selected-values.  Weird, right?
    width: 0;
    margin: ${gutters.spacing(2)}px ${gutters.spacing(1)}px 0;
    &:empty {
      margin-top: 0;
    }
  }
`;

// Because Pill doesn't know how to generate links for each value,
// we use this wrapper to inject that link fn and any arbitrary additional data (...args)
// into our custom pill component
export const getSelectedValueLink = ({ genLink, ...args }) => {
  // pick off "as" so UtilityLink isn't mistakenly set as a different element
  return function SelectedValueLink({ children, value, as, ...others }) {
    // Hacky, sorry; see components/Kizen/Pill
    // only child of custom PillComponent is always name i.e. the label of the selected item
    const name = children;
    return (
      <TextEllipsisWithTooltip
        type="anchor"
        href={genLink({ id: value, name, ...args })}
        target="_blank"
        rel="noopener noreferrer"
        // padding fudge that somehow prevents a very odd truncation issue
        // seen only (so far) on the FieldsForm on the ContactDetail page (DynamicFormFields)
        // in the RelationshipMany Multiselect: on desktop, some combination of the width
        // of the input, the width of the pill component, and the width of specific
        // strings — "Scott Turner+GAForm" and "Scott Turner+GATrack" were the reproducible cases —
        // led to the pill label overflowing and truncating with an ellipsis but the tooltip
        // not displaying.
        style={{ paddingRight: '0.5px' }}
        {...others}
      >
        {name}
      </TextEllipsisWithTooltip>
    );
  };
};

const ControlStyles = styled.div`
  && {
    display: block; // Undo flex styles, essentially forward them down to nth-child(2)
    height: auto;
    min-height: ${({ variant }) => (variant === 'underline' ? '29px' : '36px')};
    ${({ variant, hasValue }) =>
      variant === 'underline' &&
      hasValue &&
      css`
        // Illusion of top margin, do not allow pills to scroll all the way up label
        clip-path: inset(${gutters.spacing(2)}px 0 0 0);
        margin-top: ${-gutters.spacing(1, -1)}px;
      `}
    > div:first-child {
      pointer-events: all; // Undo react-select default of "none", disallowing scroll
      // Assume full width, escape the container padding
      // 1 unit of horiz padding and 2 of top padding and comes from the items themselves
      ${({ variant }) =>
        variant === 'underline' &&
        css`
          width: calc(100% + ${gutters.spacing(3)}px);
          padding-left: 0px;
          margin-left: ${-gutters.spacing(1)}px;
          margin-bottom: 1px;
        `}
      ${({ variant }) =>
        variant !== 'underline' &&
        css`
          width: calc(100% + ${2 * gutters.spacing(2, 2)}px);
          margin-left: ${-gutters.spacing(2, 2)}px;
          padding-left: ${gutters.spacing(2 - 1)}px;
        `}
      ${({ isDisabled, variant, hasValue }) =>
        isDisabled &&
        variant !== 'underline' &&
        hasValue &&
        css`
          padding-bottom: ${gutters.spacing(2)}px;
          &::-webkit-scrollbar-track:vertical {
            // Align bottom of scrollbar with bottom of pills
            margin-bottom: ${gutters.spacing(2)}px;
          }
        `}
      // 4px from the permanent scrollbar
      padding-right: ${gutters.spacing(2 - 1, -4)}px;
    }
    > div:nth-child(2) {
      height: ${({ variant }) => (variant === 'underline' ? '28px' : '34px')};
      display: flex;
      align-items: space-between;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    > div:nth-child(2) > div {
      margin-left: -2px;
      padding: 0;
    }
  }
`;

export function Control(props) {
  const selectedValuesRef = useRef();
  const { getValue, isDisabled, setValue, selectProps, children } = props;
  const value = getValue();
  const hasValue = Boolean(value && value.length);
  useEffect(() => {
    // Remain scrolled to bottom when value changes
    const el = selectedValuesRef.current;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }, [selectProps.value]);
  return (
    <ControlStyles
      as={StylePassthrough}
      isDisabled={isDisabled}
      hasValue={hasValue}
      variant={selectProps.variant}
    >
      <SelectControl {...props}>
        <SelectedValues
          ref={selectedValuesRef}
          value={value}
          disabled={isDisabled}
          setValue={setValue}
          PillComponent={selectProps.PillComponent}
          hideRemoveIcon={selectProps.hideRemoveIcon}
          // Clicking on this area does not need to trigger focus of the Select
          onMouseDown={(ev) => ev.stopPropagation()}
        />
        {(!isDisabled || !hasValue) && <div>{children}</div>}
      </SelectControl>
    </ControlStyles>
  );
}

Control.propTypes = {
  getValue: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export const MultiValue = () => null;

export const ClearIndicator = () => null;
