import { DEFAULT_ITEM_IDS } from 'pages/Account/pages/Toolbar/constants';
import { DEFAULT_PATHS } from 'routes/paths';

export const DEFAULT_ROUTES = {
  [DEFAULT_ITEM_IDS.HOMEPAGES]: {
    path: DEFAULT_PATHS.HOMEPAGES,
    exact: false,
  },
  [DEFAULT_ITEM_IDS.DASHBOARD]: {
    path: DEFAULT_PATHS.DASHBOARD,
    exact: false,
  },
  [DEFAULT_ITEM_IDS.CONTACTS]: {
    path: DEFAULT_PATHS.CONTACTS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.CUSTOM_OBJECTS]: {
    path: DEFAULT_PATHS.CUSTOM_OBJECTS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.SMART_CONNECTORS]: {
    path: DEFAULT_PATHS.SMART_CONNECTORS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.MARKETING_COSTS]: {
    path: DEFAULT_PATHS.MARKETING_COSTS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.BROADCASTS]: {
    path: DEFAULT_PATHS.BROADCASTS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.LIBRARY]: {
    path: DEFAULT_PATHS.LIBRARY,
    exact: false,
  },
  [DEFAULT_ITEM_IDS.FORMS]: {
    path: DEFAULT_PATHS.FORMS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.SURVEYS]: {
    path: DEFAULT_PATHS.SURVEYS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.ACTIVITIES]: {
    path: '/activities',
    exact: true,
  },
  [DEFAULT_ITEM_IDS.ADS]: {
    path: DEFAULT_PATHS.ADS,
    exact: false,
  },
  [DEFAULT_ITEM_IDS.AUTOMATIONS]: {
    path: DEFAULT_PATHS.AUTOMATIONS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.SETTINGS]: {
    path: DEFAULT_PATHS.SETTINGS,
    exact: false,
  },
  [DEFAULT_ITEM_IDS.MARKETPLACE]: {
    path: DEFAULT_PATHS.MARKETPLACE,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.MY_PROFILE]: {
    path: DEFAULT_PATHS.ACCOUNT,
    exact: false,
  },
  [DEFAULT_ITEM_IDS.CHANGE_BUSINESS]: {
    path: DEFAULT_PATHS.CHOOSE_BUSINESS,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.ADD_BUSINESS]: {
    path: DEFAULT_PATHS.FREE_TRIAL,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.KNOWLEDGE_BASE]: {
    path: 'https://kizensupport.zendesk.com/hc/en-us',
    exact: true,
    target: '__blank',
    rel: 'noopener noreferrer',
    external: true,
  },
  [DEFAULT_ITEM_IDS.CHAT_WITH_US]: {
    external: true,
    onClick: () => window?.loadContactUsScript?.(),
  },
  [DEFAULT_ITEM_IDS.MY_ACCOUNT]: {
    path: DEFAULT_PATHS.ACCOUNT,
    exact: true,
  },
  [DEFAULT_ITEM_IDS.LOGOUT]: {
    path: '/logout',
    exact: true,
  },
};

export const getCustomObjectPath = (id) => {
  return { path: `/custom-objects/${id}`, exact: false };
};
