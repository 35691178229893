import { FIELD_TYPES } from 'utility/constants';
import { useActivityErrorToastQuery } from 'queries/models/activites';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  getContactSectionAccess,
  getCustomObjectEntitiesAccessById,
} from 'store/authentication/selectors';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

export const EMPTY_ARRAY = [];
export const FIRST_PRIORITY = 0;
export const SECOND_PRIORITY = 1;
export const THIRD_PRIORITY = 2;

export const ASSIGNMENT_INIT_CLIENT = 'ASSIGNMENT_INIT_CLIENT';
export const ASSIGNMENT_SET_CLIENT = 'ASSIGNMENT_SET_CLIENT';
export const ASSIGNMENT_INIT = 'ASSIGNMENT_INIT';
export const ASSIGNMENT_SET = 'ASSIGNMENT_SET';
export const ASSIGNMENT_SET_MULTIPLE = 'ASSIGNMENT_SET_MULTIPLE';
export const CONTACT_SELECTOR = 'CONTACT_SELECTOR';
export const CUSTOM_SELECTOR = 'CUSTOM_SELECTOR';

export const assignmentReducer = (state, action) => {
  switch (action.type) {
    case ASSIGNMENT_INIT_CLIENT:
      return { ...state, client_client: null };

    case ASSIGNMENT_SET_CLIENT:
      return { ...state, client_client: action.value };

    case ASSIGNMENT_INIT:
      return { ...state, [action.name]: null };

    case ASSIGNMENT_SET:
      return { ...state, [action.name]: action.value };

    case ASSIGNMENT_SET_MULTIPLE:
      return { ...state, ...action.value };

    default:
      throw new Error();
  }
};
export const dummyObject = {
  access: {
    edit: true,
    view: true,
  },
};

// Simple helper function to use an ever-updating ref
const useLatest = (val) => {
  const ref = useRef(val);
  ref.current = val;
  return ref;
};

export const useGetRelationshipsForActivity = (
  { client, company, defaultAssignment, dispatchAssignment }, // predefined options
  { activityObjectId, queryOptions, apiOptions, errorCallback } // activity call options
) => {
  const { currentActivity, isFetching } = useCurrentActivity(
    activityObjectId || null,
    {
      ...queryOptions,
      enabled: !!activityObjectId,
      initialData: EMPTY_OBJECT,
    },
    apiOptions,
    errorCallback
  );
  const authentication = useSelector((s) => s.authentication);
  const authenticationRef = useRef(authentication);
  // Keep track of these values to be used in stable form, whatever the latest value is.
  // We cannot run the `getRelatedActivityFieldWithSideEffects` all the time, otherwise
  // resulting in infinite render loop. We stabilize the incoming values here instead.
  const dispatchAssignmentRef = useLatest(dispatchAssignment);

  const relatedFields = useMemo(() => {
    if (!activityObjectId || isFetching) return EMPTY_ARRAY;
    return getRelatedActivityFieldWithSideEffects(
      client,
      company,
      defaultAssignment,
      dispatchAssignmentRef.current,
      currentActivity?.customObjects || EMPTY_ARRAY,
      authenticationRef.current
    );
  }, [
    activityObjectId,
    dispatchAssignmentRef,
    isFetching,
    currentActivity,
    client,
    company,
    defaultAssignment,
  ]);

  return {
    value: relatedFields,
    isFetching,
  };
};

/**
 * I look and feel like a transform function, but I actually dispatch side effects
 */
const getRelatedActivityFieldWithSideEffects = (
  client,
  company,
  defaultAssignment = {},
  dispatchAssignment,
  relationshipDefaults,
  authentication
) => {
  return relationshipDefaults
    .reduce((current, model) => {
      const flds = EMPTY_ARRAY;
      let component;
      if (model.name === 'client_client') {
        const contactSectionAccess = getContactSectionAccess({
          authentication,
        });
        const setterProp = {
          type: ASSIGNMENT_SET,
          name: model.name,
        };
        component = {
          type: CONTACT_SELECTOR,
          setterProp,
          getterProp: model.name,
        };
        dispatchAssignment({
          type: ASSIGNMENT_INIT,
          name: model.name,
        });
        if (client) {
          dispatchAssignment({
            ...setterProp,
            value: {
              value: client.value || client.id,
              label:
                client.label ||
                client.displayName ||
                client.email ||
                client.fullName,
            },
          });
        }
        current = [
          ...current,
          {
            ...model,
            fields: flds,
            component,
            access: {
              edit: contactSectionAccess.view,
              view: contactSectionAccess.view,
            },
            priority: FIRST_PRIORITY,
          },
        ];
      } else {
        const customObjectEntitiesAccess = getCustomObjectEntitiesAccessById(
          { authentication },
          model.id
        );
        const setterProp = {
          type: ASSIGNMENT_SET,
          name: model.name,
        };
        component = {
          type:
            model.name !== 'client_client' ? CUSTOM_SELECTOR : CONTACT_SELECTOR,
          setterProp,
          getterProp: model.name,
        };
        dispatchAssignment({
          type: ASSIGNMENT_INIT,
          name: model.name,
        });
        // set up default if a match
        Object.keys(defaultAssignment).forEach((key) => {
          if (model.name === key || model.id === key) {
            dispatchAssignment({
              ...setterProp,
              value: defaultAssignment[key],
            });
          }
        });
        if (company && model.objectName.match(/^company.*/i)) {
          dispatchAssignment({
            ...setterProp,
            value: {
              value: company.id,
              label: company.display_name || client.name, // eek snake case
            },
          });
        }
        current = [
          ...current,
          {
            ...model,
            fields: flds,
            component,
            ...(customObjectEntitiesAccess
              ? {
                  access: {
                    edit: customObjectEntitiesAccess.enabled,
                    view: customObjectEntitiesAccess.enabled,
                  },
                }
              : dummyObject),
            fieldType: FIELD_TYPES.Relationship.type,
            relations: [
              {
                fetchUrl: `field-model-custom`,
                model: model.id,
                modelFetchUrl: model.fetchUrl,
                objectName: model.objectName,
              },
            ],
            priority: model.objectName.match(/^contact.*/i)
              ? FIRST_PRIORITY
              : model.objectName.match(/^company.*/i)
                ? SECOND_PRIORITY
                : THIRD_PRIORITY,
          },
        ];
      }
      return current;
    }, [])
    .sort((a, b) =>
      a.priority === b.priority
        ? a.objectName.localeCompare(b.objectName)
        : a.priority - b.priority
    );
};

export const useCurrentActivity = (
  id,
  queryOptions,
  apiOptions,
  errorCallback
) => {
  const { data, isFetching, isRefetching, refetch } =
    useActivityErrorToastQuery(id, queryOptions, apiOptions, errorCallback);

  useEffect(() => {
    id && refetch?.(id);
  }, [id, refetch]);

  return {
    currentActivity: data,
    isFetching: isFetching || isRefetching,
  };
};
