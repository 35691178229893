import SearchState from 'components/AccessRequests/searchState';
import ContactsChartGroupSettingsController from './Controller';

const ContactsChartGroupSettingsPage = () => {
  return (
    <SearchState.Wrapper>
      <ContactsChartGroupSettingsController />
    </SearchState.Wrapper>
  );
};

export default ContactsChartGroupSettingsPage;
