import AxiosService from './AxiosService';
import { camelToSnakeCaseKeys, snakeToCamelCaseKeys } from './helpers';

class LoggableActivityService {
  constructor() {
    if (!LoggableActivityService.instance) {
      LoggableActivityService.instance = this;
    }
    return LoggableActivityService.instance;
  }

  objectEndpoint = 'activities/loggable';
  getActivityFullList = async (params, skipErrorBoundary = false) => {
    const getData = async (params) => {
      const { data } = await AxiosService.get(`/${this.objectEndpoint}`, {
        params: camelToSnakeCaseKeys(params),
        skipErrorBoundary,
      });
      return data.next
        ? [
            ...snakeToCamelCaseKeys(data.results),
            ...(await getData({ ...params, page: params.page + 1 })),
          ]
        : snakeToCamelCaseKeys(data.results);
    };

    return await getData({ ...params, page: 1 });
  };
  getActivities = async (options) => {
    const { data } = await AxiosService.get(`/${this.objectEndpoint}`, options);
    return snakeToCamelCaseKeys(data);
  };
}

const instance = new LoggableActivityService();

export default instance;
