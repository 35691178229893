import { Link } from 'react-router-dom';
import { StyledButton, StyledIcon } from './styles';

function BackButton({
  onClick,
  children,
  color = 'blue',
  to,
  as,
  preventDefault,
  ...others
}) {
  return (
    <StyledButton
      as={to ? Link : as}
      variant="text"
      color={color}
      onClick={onClick}
      to={to}
      preventDefault={to ? false : preventDefault}
      {...others}
    >
      <StyledIcon icon="chevron-left" color="inherit" />
      {children}
    </StyledButton>
  );
}

export default BackButton;
