import { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { debugMode } from './useDataManager';
import {
  useCacheBustRequests,
  useHasExecuted,
  useIsAcquired,
  useMarkExecution,
  useRelease,
} from './getters';
import { isFlagEnabled } from 'debug/flags';
import { FLAGS } from 'debug/identifiers';
import { useTriggerRefetch } from './getters';

export const useManagedQuery = ({
  dashletId,
  queryKey,
  queryFn,
  enabled = true,
  additionalParams,
}) => {
  const cacheBustRequests = useCacheBustRequests();
  const hasExecuted = useHasExecuted(dashletId);
  const isAcquired = useIsAcquired(dashletId);
  const release = useRelease(dashletId);
  const markExecution = useMarkExecution(dashletId);
  const triggerRefetch = useTriggerRefetch();
  const skipCache = isFlagEnabled(FLAGS.ALWAYS_REFRESH_DASHLET);

  const cacheBustRequestsTracker = useRef(cacheBustRequests);
  cacheBustRequestsTracker.current = cacheBustRequests;

  const {
    data,
    isLoading,
    isRefetching,
    // These will generally not be safe to use, because we have special state
    // management that react-query doesn't know about. Still, they're exposed
    // but in a way that it's not easy to use them inadvertently.
    ...__unsafeAdditionalQueryValues
  } = useQuery({
    queryKey,
    queryFn: () => {
      const isBustingCache =
        skipCache || cacheBustRequestsTracker.current?.has(dashletId);
      return queryFn(isBustingCache);
    },
    enabled: enabled && (isAcquired || hasExecuted),
    ...additionalParams,

    // Disable the built-in refetching mechanism because the data manager
    // will handle it.
    refetchOnWindowFocus: false,
    staleTime: isAcquired ? 0 : Infinity, // when acquired we need to reset the cache

    onSettled: (...args) => {
      release(dashletId);
      markExecution(dashletId);
      return additionalParams?.onSettled?.(...args);
    },
  });

  const aggregateIsLoading = isLoading || (!isAcquired && !data && enabled);
  const aggregateIsRefetching =
    isRefetching || (!isAcquired && !data && enabled);

  // Detect if queryKeyChanged
  const oldQueryKey = useRef(queryKey.toString());
  useEffect(() => {
    if (oldQueryKey.current !== queryKey.toString()) {
      if (debugMode) {
        console.log(
          `%c[DATA MANAGER] - query key changed for ${dashletId}`,
          'color: #db8000;'
        );
      }
      oldQueryKey.current = queryKey.toString();
      triggerRefetch(dashletId);
    }
  }, [queryKey, dashletId, triggerRefetch]);

  useEffect(() => {
    if (debugMode && (aggregateIsLoading || aggregateIsRefetching)) {
      console.group(
        `%c[DATA MANAGER] - loading conditions for ${dashletId}`,
        'color: #ebae34;'
      );
      console.log({
        isLoading,
        isAcquired,
        enabled,
        hasData: Boolean(data),
        aggregateIsLoading,
        aggregateIsRefetching,
      });
      console.groupEnd();
    } else if (debugMode) {
      console.log(
        `%c[DATA MANAGER] - loading finished for ${dashletId}`,
        'color: #ebae34;'
      );
    }
  }, [
    dashletId,
    data,
    isLoading,
    isRefetching,
    hasExecuted,
    enabled,
    isAcquired,
    aggregateIsLoading,
    aggregateIsRefetching,
  ]);

  return {
    data,
    isLoading: aggregateIsLoading,
    isRefetching: aggregateIsRefetching,
    hasExecuted,
    __unsafeAdditionalQueryValues,
  };
};
