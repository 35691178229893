import InputControl from 'components/Inputs/InputControl';
import { useTranslation } from 'react-i18next';
import Cols from 'components/Layout/Cols';
import { StyledSubheader } from '../styles';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { useAsync } from 'react-use';
import ActivityService from 'services/ActivityService';
import AutomationsService from 'services/Automation2Service';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import MultiSelect from 'components/Inputs/MultiSelect';
import { FORCE_ALL_RECORDS_SIZE, namedToOption } from 'services/helpers';
import { CO_LAYOUT_ACTION_BLOCK_ACTIONS } from 'components/Wizards/CustomObject/utilities';
import Loader from 'components/Kizen/Loader';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import { useMemo, useRef, useState } from 'react';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';

export const DisplayOptions = ({ values, setValues, customObjectId }) => {
  const { t } = useTranslation();
  const [automationValues, setAutomationValues] = useState([]);

  const { value: activities = DROPDOWN_SHOULD_LOAD, loading } =
    useAsync(async () => {
      if (
        values.meta[CO_LAYOUT_ACTION_BLOCK_ACTIONS.START_AUTOMATIONS_LIST]
          ?.length
      ) {
        const { results } = await AutomationsService.search({
          ordering: 'name',
          page: { size: FORCE_ALL_RECORDS_SIZE, number: 1 },
          criteria: {
            and: null,
            query: [
              {
                and: true,
                filters: [
                  {
                    type: 'automation2',
                    subtype: 'custom_object_id',
                    condition: '=',
                    value: customObjectId,
                  },
                  {
                    type: 'automation2',
                    subtype: 'automation_id',
                    condition: 'is_any_of',
                    value:
                      values.meta[
                        CO_LAYOUT_ACTION_BLOCK_ACTIONS.START_AUTOMATIONS_LIST
                      ],
                  },
                  {
                    type: 'automation2',
                    subtype: 'status',
                    condition: '=',
                    value: 'active',
                  },
                ],
              },
            ],
          },
          ...params,
        });

        setAutomationValues(results.map(namedToOption));
      }

      const activities = await ActivityService.v2GetActivityFullList({
        ordering: 'name',
        customObjectId,
      });

      return activities.map(namedToOption);
    }, [customObjectId]);

  const handleChange = (key, value) => {
    setValues((prev) => ({
      ...prev,
      meta: { ...prev.meta, [key]: value.map(({ value }) => value) },
    }));
  };

  const handleChangeAutomations = (value) => {
    setAutomationValues(value);
    handleChange(CO_LAYOUT_ACTION_BLOCK_ACTIONS.START_AUTOMATIONS_LIST, value);
  };

  const selectRef = useRef(null);

  const params = useMemo(
    () => ({
      active: true,
      custom_object_id: customObjectId,
    }),
    [customObjectId]
  );

  const [typeaheadProps, { loading: loadingAutomations }] =
    useSelectTypeaheadWithScroll({
      entity: Entities.Automations,
      enabled: !!customObjectId,
      selectRef,
      params,
      chosenValueIds:
        values.meta[CO_LAYOUT_ACTION_BLOCK_ACTIONS.START_AUTOMATIONS_LIST],
    });

  return (
    <Loader loading={loading}>
      <StyledSubheader type="subheader">{t('Activities')}</StyledSubheader>
      <Cols columns={2} gutter="20px">
        <InputControl margin>
          <MultiSelect
            label={t('When Logging (Leave Blank for All)')}
            options={activities}
            value={
              values.meta[CO_LAYOUT_ACTION_BLOCK_ACTIONS.LOG_ACTIVITIES_LIST]
            }
            placeholder={t('Find Activities')}
            onChange={(value) =>
              handleChange(
                CO_LAYOUT_ACTION_BLOCK_ACTIONS.LOG_ACTIVITIES_LIST,
                value
              )
            }
            endAdornment={<IconAdornment icon="search" />}
            menuLeftButton={<ClearSelectButton />}
            menuRightButton={<ApplySelectButton />}
            closeOnClearClick
          />
        </InputControl>
        <InputControl margin>
          <MultiSelect
            label={t('When Scheduling (Leave Blank for All)')}
            options={activities}
            value={
              values.meta[
                CO_LAYOUT_ACTION_BLOCK_ACTIONS.SCHEDULE_ACTIVITIES_LIST
              ]
            }
            placeholder={t('Find Activities')}
            onChange={(value) =>
              handleChange(
                CO_LAYOUT_ACTION_BLOCK_ACTIONS.SCHEDULE_ACTIVITIES_LIST,
                value
              )
            }
            endAdornment={<IconAdornment icon="search" />}
            menuLeftButton={<ClearSelectButton />}
            menuRightButton={<ApplySelectButton />}
            closeOnClearClick
          />
        </InputControl>
      </Cols>
      <StyledSubheader type="subheader">{t('Automations')}</StyledSubheader>
      <Cols columns={2} gutter="20px">
        <InputControl margin>
          <MultiSelect
            ref={selectRef}
            label={t(
              'Choose Automations that can be Started Manually (Leave Blank for All)'
            )}
            value={automationValues}
            {...typeaheadProps}
            loadItems={loadingAutomations}
            placeholder={t('Find Automations')}
            onChange={handleChangeAutomations}
            endAdornment={<IconAdornment icon="search" />}
            menuLeftButton={<ClearSelectButton />}
            menuRightButton={<ApplySelectButton />}
            closeOnClearClick
          />
        </InputControl>
      </Cols>
    </Loader>
  );
};
