import { useCallback } from 'react';
import { GenericWizardModalFooter } from 'components/GenericWizard';
import Button from 'components/Button';
import { createPayload } from './utilities';
import { useTranslation } from 'react-i18next';
import { isCustomFilter } from './types';
import { useWizardFilterContext } from 'components/Wizards/MetaFilters/context';

const FilterWizardFooter = ({ valid, onConfirm }) => {
  const { t } = useTranslation();

  const {
    setFilterHasErrors,
    setHasErrors,
    operation,
    filterOps: { build, validate },
    values,
    state,
    disabled,
  } = useWizardFilterContext();

  const handleSave = useCallback(() => {
    let filterConfig = null;
    if (isCustomFilter(values.type)) {
      const { errors, hasErrors } = validate();
      if (hasErrors) {
        setFilterHasErrors({ errors, hasErrors });
        return;
      }
      setHasErrors(false);
      filterConfig = build(operation === 'and');
    }
    const payload = createPayload(values, state, filterConfig);
    return onConfirm(payload);
  }, [
    validate,
    build,
    values,
    state,
    setFilterHasErrors,
    setHasErrors,
    operation,
    onConfirm,
  ]);

  return (
    <GenericWizardModalFooter>
      <Button
        disabled={!valid || disabled}
        data-qa-action="save-filter"
        onClick={handleSave}
      >
        {t('Save')}
      </Button>
    </GenericWizardModalFooter>
  );
};

export default FilterWizardFooter;
