import styled from '@emotion/styled';
import { FILTER_MENU_Z_INDEX } from 'app/spacing';
import { useDebouncedPastScrollThreshold } from 'hooks/useDebouncedWindowScroll';
import {
  StickyNavMobileWrapper,
  StickyNavWrapper,
  StyledSubNav,
} from 'pages/Common/styles';

const StyledStickyNavWrapper = styled(StickyNavWrapper)`
  z-index: ${FILTER_MENU_Z_INDEX + 3};
`;

export const StickyNavigation = ({ params, routes }) => {
  const scrolled = useDebouncedPastScrollThreshold();
  return (
    <StyledStickyNavWrapper scrolled={scrolled}>
      <StickyNavMobileWrapper routes={routes}>
        <StyledSubNav routes={routes} params={params} />
      </StickyNavMobileWrapper>
    </StyledStickyNavWrapper>
  );
};
