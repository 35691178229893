import { forwardRef } from 'react';
import KizenTypography from 'app/kizentypo';
import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import SingleValue from './SingleValue';
import { StyledLink, StyledSelect } from './styles';

const InlineOwnerDropdown = forwardRef(
  ({ editable, value, onChange, components, ...rest }, ref) => {
    return editable ? (
      <StyledSelect
        ref={ref}
        placeholder="Choose Team Member"
        margin
        variant="underline"
        collapse
        value={value}
        onChange={onChange}
        menuPortalTarget={document.body}
        components={{
          ...components,
          SingleValue: SingleValue,
        }}
        {...rest}
      />
    ) : (
      <TextEllipsisWithTooltip>
        {value?.label ? (
          <StyledLink type="link" to={`/team-member/${value.id}/timeline`}>
            {value.label}
          </StyledLink>
        ) : (
          <KizenTypography>{emptyLongDash}</KizenTypography>
        )}
      </TextEllipsisWithTooltip>
    );
  }
);

export default InlineOwnerDropdown;
