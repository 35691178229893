import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { addDays, addWeeks, addMonths } from 'date-fns';
import {
  DayCalendarCell,
  DefaultMonthCalendarCell,
  WeekCalendarCell,
} from './components';
import MonthCalendar from './MonthCalendar';
import WeekCalendar from './WeekCalendar';
import DayCalendar from './DayCalendar';
import { getCalendarTimeIntervals } from './utils';
import { CALENDAR_VIEW_OPTIONS } from './constants';

export const useCalendar = (view = 'month') => {
  const [date, setDate] = useState(new Date());
  const [calendarView, setCalendarView] = useState(view);
  let dateUpdater = addMonths;
  if (calendarView === 'day') {
    dateUpdater = addDays;
  }
  if (calendarView === 'week') {
    dateUpdater = addWeeks;
  }

  const next = useCallback(() => {
    setDate((current) => dateUpdater(current, 1));
  }, [dateUpdater, setDate]);

  const previous = useCallback(() => {
    setDate((current) => dateUpdater(current, -1));
  }, [dateUpdater, setDate]);

  const setToday = useCallback(() => setDate(new Date()), []);

  return [
    date,
    calendarView,
    { next, previous, setToday, setDate, setCalendarView },
  ];
};

/**
 * Calendar component to view dates for a single day, week, or month.
 *
 * @param props.date - the reference date for the calendar used to show the current day, week, or month
 * @param props.view - the view of the calendar (day, week, month)
 * @param props.MonthCellComponent - the cell component to be used when rendering the month view. Defaults to {@link DefaultDateCell}
 * @param props.WeekCellComponent - the cell component to be used when rendering the week view. Defaults to {@link WeekCalendarCell}
 * @param props.DayCellComponent - the cell component to be used when rendering the day view. Defaults to {@link WeekCalendarCell}
 */
const Calendar = ({
  date,
  expandable,
  view,
  timeIntervals,
  MonthCellComponent,
  WeekCellComponent,
  DayCellComponent,
  ...rest
}) => {
  if (view === CALENDAR_VIEW_OPTIONS.day) {
    return (
      <DayCalendar
        date={date}
        CellComponent={DayCellComponent}
        times={timeIntervals}
        {...rest}
      />
    );
  }
  if (view === CALENDAR_VIEW_OPTIONS.week) {
    return (
      <WeekCalendar
        date={date}
        CellComponent={WeekCellComponent}
        times={timeIntervals}
        {...rest}
      />
    );
  }
  return (
    <MonthCalendar
      expandable={expandable}
      date={date}
      CellComponent={MonthCellComponent}
      {...rest}
    />
  );
};

export default Calendar;

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  view: PropTypes.oneOf(Object.values(CALENDAR_VIEW_OPTIONS)),
  expandable: PropTypes.bool,
  timeIntervals: PropTypes.arrayOf(PropTypes.string),
  initialTopTime: PropTypes.string,
  MonthCellComponent: PropTypes.elementType,
  WeekCellComponent: PropTypes.elementType,
  DayCellComponent: PropTypes.elementType,
};

Calendar.defaultProps = {
  view: CALENDAR_VIEW_OPTIONS.month,
  expandable: false,
  timeIntervals: getCalendarTimeIntervals(),
  initialTopTime: '8:00 AM',
  MonthCellComponent: DefaultMonthCalendarCell,
  WeekCellComponent: WeekCalendarCell,
  DayCellComponent: DayCalendarCell,
};
