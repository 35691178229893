import { useBreakpoint } from 'app/spacing';
import { useTranslation } from 'react-i18next';
import {
  ComponentWrapper,
  PaginationWrapper,
  StyledModal,
  StyledTableScrollContainer,
  TableWrapper,
} from './styles';
import SmartConnectorService from 'services/SmartConnectorService';
import TablePagination from '__components/Tables/Big/TablePagination';
import { Gradient } from '__components/Kizen/ScrollFadeContainer';
import { TRow } from '__components/Kizen/Table';
import BasicTable from '__components/Tables/Basic';
import { useAsync } from 'react-use';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { useColumns } from './hooks';
import { DEFAULT_PAGINATION_SETTINGS } from './constants';
import { usePaginationSettings } from 'hooks/usePaginationSettings';
import { FC } from 'react';

type ModalProps = {
  smartConnector: any;
  modalProps: any;
};

export const SmartConnectorHistoryModal: FC<ModalProps> = ({
  smartConnector,
  modalProps,
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint();

  const {
    page,
    pageSize,
    ordering,
    handleChangePage,
    handleChangePageSize,
    headData,
  } = usePaginationSettings(DEFAULT_PAGINATION_SETTINGS);

  const { value: { results = EMPTY_ARRAY, count = 0 } = {}, loading } =
    useAsync(async () => {
      return await SmartConnectorService.getSmartConnectorHistory(
        smartConnector.id,
        {
          page,
          page_size: pageSize,
          ordering,
        }
      );
    }, [page, pageSize, ordering, smartConnector.id]);

  const columns = useColumns();

  return isMobile ? null : (
    <StyledModal
      buttonText={t('Close')}
      actionBtnColor="blue"
      leftBtn={null}
      heading={t('SmartConnector History Log')}
      size="medium"
      {...modalProps}
      displayFlex
    >
      <ComponentWrapper>
        <TableWrapper>
          <StyledTableScrollContainer bottom top gradient={<Gradient />}>
            <BasicTable
              key={`table-${smartConnector.id}`}
              stickyHeader
              head={<TRow head columns={columns} data={headData} />}
              data-qa="smartConnector-history-table"
              staleData={loading}
            >
              {count ? null : <TRow />}
              {results.map((data: any) => (
                <TRow key={data.id} columns={columns} data={data} />
              ))}
            </BasicTable>
          </StyledTableScrollContainer>
        </TableWrapper>
        <PaginationWrapper>
          <TablePagination
            page={page}
            perPage={pageSize}
            totalCount={count}
            onChangePage={handleChangePage}
            onChangePerPage={handleChangePageSize}
          />
        </PaginationWrapper>
      </ComponentWrapper>
    </StyledModal>
  );
};
