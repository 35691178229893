import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { STEPS } from '../config';
import Section from '../../../section';
import FieldOptions from './options';
import { SelectorGrid, StyledIconBlock, StyledImageSelector } from './styles';
import { FIELD_TYPES } from 'utility/constants';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

const defaultExeptions = ['category', 'displayName', 'name', 'type'];

const FieldType = ({
  updateField,
  resetField,
  updateValidation,
  formData = EMPTY_OBJECT,
  index,
  isActive,
  data,
  fieldTypes,
  exceptions = defaultExeptions,
}) => {
  const { t } = useTranslation();
  const stagedType = formData.type;
  const hasOptions = formData.type
    ? fieldTypes.some(
        (field) =>
          field.type === stagedType &&
          !!(field.options || stagedType === FIELD_TYPES.DynamicTags.type)
      )
    : false;

  const isValid = Boolean(stagedType);

  useEffect(() => {
    updateValidation(STEPS.FIELD_TYPE, isValid);
  }, [isValid, updateValidation]);

  useEffect(() => {
    if (!hasOptions) {
      updateValidation(STEPS.FIELD_OPTIONS);
    }
  }, [hasOptions, updateValidation]);

  const selectedField = fieldTypes.find((field) => field.type === stagedType);

  const isEdit = !!data.field;

  return (
    <>
      <Section
        header={isEdit ? t('Selected Field Type') : t('Choose Field Type')}
        index={index}
        isLastIndex={!hasOptions}
        isActive={isActive}
      >
        <SelectorGrid>
          {isEdit ? (
            <StyledImageSelector
              key={selectedField.label}
              label={selectedField.localizedLabel(t)}
              value="default"
              icon={
                <StyledIconBlock>
                  <selectedField.icon />
                </StyledIconBlock>
              }
              onChange={() => {}}
              selected
            />
          ) : (
            fieldTypes.map((field) => (
              <StyledImageSelector
                key={field.label}
                label={field.localizedLabel(t)}
                value="default"
                icon={
                  <StyledIconBlock>
                    <field.icon />
                  </StyledIconBlock>
                }
                selected={formData.type === field.type}
                onChange={() => {
                  if (formData.type === field.type) {
                    // Take no action when selecting the same item twice. This is necessary become some field
                    // settings components (e.g. SimpleOptions) initialize the field config on mount, and the
                    // update below might reset some configuration without triggering a new mount/initialization
                    // of that config.
                    return;
                  }
                  updateField('type', field.type);
                  // Clear any type-specific changes from state, reset field state to initial values
                  // i.e. all field properties except the ones listed below, which are general to all field types
                  resetField(exceptions);
                }}
                data-qa={field.label}
              />
            ))
          )}
        </SelectorGrid>
      </Section>
      {hasOptions && (
        <FieldOptions
          key={formData.type} // Guarantee any component state is thrown away, for cases where different types render the same options component
          updateField={updateField}
          updateValidation={updateValidation}
          index={index + 1}
          isActive={!!formData.type}
          isValidated={selectedField.optionsValidation}
          formData={formData}
          component={selectedField.options}
          optionsLimit={selectedField.optionsLimit}
          data={data}
        />
      )}
    </>
  );
};

export default FieldType;
