import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale, colorsPrimary, colorsButton } from 'app/colors';
import { gutters } from 'app/spacing';

export const AreaSelectionWrapper = styled.div`
  display: flex;
  column-gap: ${({ charts }) => gutters.spacing(charts ? 3 : 2)}px;
  flex-wrap: wrap;
  row-gap: ${gutters.spacing(2)}px;
  margin-bottom: ${gutters.spacing(1)}px;
  margin-right: -${gutters.spacing(2)}px;
`;

export const DashletArea = styled.button`
  width: ${({ mobile, charts }) =>
    mobile
      ? `calc(100% / 3 - ${gutters.spacing(2)}px)`
      : charts
        ? '140px'
        : '100px'};
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 ${gutters.spacing(1, 2)}px;
  border: 1px solid ${grayScale.mediumDark};
  border-radius: 4px;
  color: ${grayScale.mediumDark};
  cursor: pointer;
  background: ${grayScale.white};
  outline: none;

  &:hover {
    border-color: ${colorsButton.blue.hover};
  }

  &:focus {
    outline: none;
  }

  i {
    width: 25px;
    height: 25px;
  }

  i:hover {
    color: ${grayScale.mediumDark};
  }

  && svg {
    width: 25px;
    height: 25px;
  }

  ${({ selected }) =>
    selected
      ? css`
          border-color: ${colorsPrimary.blue.dark};
          color: ${colorsPrimary.blue.dark};

          i,
          i:hover {
            color: ${colorsPrimary.blue.dark};
          }
        `
      : css`
          &:disabled {
            border-color: ${grayScale.mediumDark};
            color: ${grayScale.mediumDark};
          }

          &:disabled i {
            color: ${grayScale.mediumDark};
          }
        `}
`;

export const DashletAreaText = styled.p`
  font-size: 1.4rem;
  margin-top: ${gutters.spacing(4)}px;
  && {
    margin-bottom: -5px;
  }
`;
