import React, { useMemo } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { grayScale, colorsButton } from '../../../../app/colors';
import KizenTypography from '../../../../app/kizentypo';
import { gutters, borderRadii, breakpoints } from '../../../../app/spacing';
import Icon from '../../../Kizen/Icon';
import KizenIconButtonMenu from '../../../Kizen/IconButtonMenu';
import { TextEllipsisWithTooltip } from '../../../Kizen/Table';
import { fileShape } from '../defaults';

const Wrapper = styled.div`
  width: 120px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0 ${gutters.spacing(4)}px ${gutters.spacing(4)}px 0;
  position: relative;

  @media screen and (max-width: ${breakpoints.sm}px) {
    height: unset;
    margin: 0 ${gutters.spacing(4)}px ${gutters.spacing(2, 2)}px 0;
  }
`;

const PreviewImageContainer = styled.div`
  width: 120px;
  height: 120px;
  flex: 0 0 120px;
  border-radius: ${borderRadii.small};
  background: ${grayScale.light};
  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid ${colorsButton.blue.default};
    `}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  span {
    color: ${grayScale.mediumDark};
  }

  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${gutters.spacing(2)}px;
  box-sizing: border-box;

  > p {
    font-size: 32px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    height: unset;
    margin-bottom: ${gutters.spacing(1, 3)}px;
  }
`;

const PreviewImageHeader = styled.div`
  top: 0;
  right: 0;
  left: 0;
  height: 15px;
  color: ${colorsButton.iconWhite.default};
  display: flex;
  justify-content: ${({ hasOptions }) =>
    hasOptions ? 'space-between' : 'center'};
  align-items: center;
  border-top-left-radius: ${borderRadii.small};
  border-top-right-radius: ${borderRadii.small};
  ${({ selected }) =>
    selected
      ? css`
          background: ${colorsButton.blue.default};
        `
      : css`
          cursor: pointer;
        `}
  box-sizing: border-box;
  position: absolute;
`;
const PreviewImageHeaderTitle = styled.div``;
const PreviewImageHeaderEnds = styled.div`
  flex: 0 0 25px;
  width: 25px;
`;

const AlignText = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const IconButtonMenu = styled(KizenIconButtonMenu)`
  && .IconButtonMenu__menu {
    ${({ above }) => !above && 'transform: translate(-100%, -5px);'}
    ${({ above }) =>
      above && 'transform: translate(-100%, -100%) translateY(-20px);'}
  }
`;

const FileBlock = ({
  file,
  focused,
  onClick,
  onDeselectFile,
  disabled,
  viewable,
  selected = true,
}) => {
  const { t } = useTranslation();

  const ext = file && (file?.name || '').split('.').pop();
  const canDeselect = !!onDeselectFile;

  const options = useMemo(
    () =>
      [
        viewable &&
          !disabled &&
          canDeselect && { value: 'deselect', label: t('Deselect') },
      ].filter(Boolean),
    [viewable, disabled, canDeselect, t]
  );
  const onSelectAction = ({ value }) => {
    switch (value) {
      case 'deselect':
        onDeselectFile(file);
        break;

      default:
        // also preview
        onClick(file);
        break;
    }
  };

  return (
    <Wrapper onClick={() => onClick(file)}>
      <PreviewImageHeader selected={selected} hasOptions={options.length > 0}>
        {options.length > 0 && <PreviewImageHeaderEnds />}
        {selected && (
          <PreviewImageHeaderTitle>
            <KizenTypography type="micro" color={grayScale.white}>
              {t('Selected')}
            </KizenTypography>{' '}
          </PreviewImageHeaderTitle>
        )}
        {options.length > 0 && (
          <PreviewImageHeaderEnds
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          >
            <IconButtonMenu
              sizing="dense"
              position="right"
              color={colorsButton.iconWhite}
              onChange={(value) => onSelectAction(value)}
              options={options}
            >
              <Icon icon="three-dot" />
            </IconButtonMenu>
          </PreviewImageHeaderEnds>
        )}
      </PreviewImageHeader>
      <PreviewImageContainer selected={selected} focused={focused}>
        {Boolean(ext) && (
          <KizenTypography type="header" weight="bold">
            .{ext.toUpperCase()}
          </KizenTypography>
        )}
      </PreviewImageContainer>
      <AlignText>
        <TextEllipsisWithTooltip>{file?.name}</TextEllipsisWithTooltip>
      </AlignText>
    </Wrapper>
  );
};

FileBlock.propTypes = {
  file: PropTypes.objectOf(fileShape).isRequired,
  focused: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onDeselectFile: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  viewable: PropTypes.bool.isRequired,
};

FileBlock.defaultProps = {
  focused: false,
  selected: true,
  onClick: undefined,
  onDeselectFile: undefined,
};

export default FileBlock;
