import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

import {
  HeaderSizedCell,
  SortableHeadCell,
  SizedCell,
  TextCell,
} from 'pages/Account/pages/Profile/Forms/tableStyles';

import { ApiKeysActionCell } from 'pages/Account/pages/Profile/Forms/APIKeys/ApiKeysActionCell';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';

export const createColumns = ({
  onSelectAction,
  columnWidths,
  canRemove,
  t,
}) => {
  const columns = [
    {
      id: 'spacer',
      headCell: <SizedCell width="20px" />,
      cell: <SizedCell width="20px" />,
    },
    columnWidths?.key && {
      id: 'key_preview',

      headCell: (
        <SortableHeadCell
          by="key"
          label={t('Key')}
          minWidth={columnWidths?.key}
          padding={columnWidths?.padding}
        />
      ),
      cell: (
        <SizedCell
          minWidth={columnWidths?.key}
          padding={columnWidths?.padding}
        />
      ),
      format: (keyPreview) => {
        return (
          <TextCell>
            <TextEllipsisWithTooltip size="micro">
              {keyPreview}
            </TextEllipsisWithTooltip>
          </TextCell>
        );
      },
    },
    columnWidths?.employee && {
      id: 'employee',
      headCell: (
        <SortableHeadCell
          label={t('Team Member')}
          minWidth={columnWidths?.employee}
          padding={columnWidths?.padding}
        />
      ),
      cell: (
        <SizedCell
          minWidth={columnWidths?.employee}
          padding={columnWidths?.padding}
        />
      ),
      format: ({ display_name }) => {
        return (
          <TextCell>
            <TextEllipsisWithTooltip size="micro">
              {display_name}
            </TextEllipsisWithTooltip>
          </TextCell>
        );
      },
    },
    columnWidths?.created && {
      id: 'created',
      by: 'created',
      headCell: (
        <SortableHeadCell
          label={t('Date Created')}
          numeric
          minWidth={columnWidths?.created}
          padding={columnWidths?.padding}
        />
      ),
      cell: (
        <SizedCell
          minWidth={columnWidths?.created}
          padding={columnWidths?.padding}
        />
      ),
      format: (dateCreated) => {
        return (
          <DateTimeInputInline value={dateCreated} readOnly showDateTooltip />
        );
      },
    },
    columnWidths?.actions && {
      id: 'actions',
      headCell: (
        <HeaderSizedCell
          label={t('Actions')}
          minWidth={columnWidths?.actions}
          padding="0px"
          align="right"
          t={t}
        />
      ),
      cell: (
        <ApiKeysActionCell
          minWidth={columnWidths?.actions}
          padding="0px"
          onSelectAction={onSelectAction}
          canRemove={canRemove}
          t={t}
        />
      ),
    },
    {
      id: 'right-spacer',
      headCell: <SizedCell width="20px" />,
      cell: <SizedCell width="20px" />,
    },
  ].filter(Boolean);

  return [...columns];
};
