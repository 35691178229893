import React, {
  useEffect,
  forwardRef,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';
import * as PropTypes from 'prop-types';
import TextInputInline from 'components/Inputs/inline/TextInput';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import usePhoneFormatter from 'hooks/usePhoneFormatter';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { useTranslation } from 'react-i18next';
import { useFlashTransition } from 'hooks/useFlashState';
import { isCountryCallingCode } from '../helpers';
import PhoneNumberWithOverlay from './PhoneNumberWithOverlay';
import EditableText from 'components/Kizen/EditableText';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const getInputEl = (inputRef) => {
  // Get ref to input, handling case of react-input-autosize's getInput()
  return inputRef.current && inputRef.current.getInput
    ? inputRef.current.getInput()
    : inputRef.current;
};
const PhoneNumberInline = styled.div`
  input:hover:not([disabled]) {
    background-image: url(/images/inputUnderline--hover.svg}) !important;
  }
  // This is the size of the bottom padding on EditableText.
  // They should match so that there is no jitter switching between text and the input.
  margin-bottom: -${gutters.spacing(2, -3)}px;
  .ViewText {
    margin-top: -${gutters.spacing(1, -4)}px;
    max-width: 100%;
  }
  .ViewText input:not(:hover) {
    background-image: none;
  }
  &:hover,
  tr:hover & {
    .ViewText input:not(:hover) {
      background-image: url(/images/inputUnderline.svg);
    }
  }
  input {
    min-width: 30px;
    padding-left: 0;
  }
  &&& input[disabled] {
    background: unset;
    // A bit blunt, but we need to make sure there's no image otherwise the background repeats
    background-image: none !important;
    color: ${grayScale.mediumDark};
  }
`;

const emptyValue = '—';

const PhoneNumberInlineField = forwardRef(
  (
    {
      field,
      object,
      input,
      value,
      onSubmit,
      enableExtension,
      editable,
      callback,
      submitUnchanged,
      autoFocus,
      onAutoFocus,
      readOnly,
      onKeyPress,
      emptyPlaceholder = emptyValue,
      defaultCountry = 'US',
      disabled,
      'data-field-type': qaFieldType,
      'data-field-id': qaFieldId,
      shouldFocusNext,
      ...others
    },
    ref
  ) => {
    const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();
    const [editing, setEditing] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [stagedValue, setStagedValue] = useState(value);
    const [isError, setIsError] = useState(false);
    const [error, showErrorMessage, flashError, cancelFlash] =
      useFlashTransition();
    const inputRef = useRef();
    const targetRef = useRef(null);
    const { t } = useTranslation();

    const onAutoFocusRef = useRef();
    onAutoFocusRef.current = onAutoFocus;

    useEffect(() => {
      const inputEl = getInputEl(inputRef); // || getInputEl(validationRef);
      if (autoFocus && inputEl && !getKeyListenersProps) {
        if (onAutoFocusRef.current) onAutoFocusRef.current(targetRef);
        inputEl.focus({ preventScroll: true });
      }
    }, [autoFocus, getKeyListenersProps]);

    const { formattedValue } = usePhoneFormatter(value, defaultCountry);

    const handleChange = useCallback(
      (v) => {
        setStagedValue(v);

        if (callback) {
          callback();
        }
      },
      [callback]
    );

    useEffect(() => {
      setStagedValue(value);
    }, [value, editing]);

    const submit = useCallback(
      async ({ clickAway = false } = {}, data) => {
        const phoneData = data ?? stagedValue;
        if (submitUnchanged || value !== phoneData) {
          try {
            setSubmitting(true);
            if (
              phoneData &&
              !isValidPhoneNumber(phoneData) &&
              !isCountryCallingCode(phoneData)
            ) {
              setIsError(true);
              const errMsg = t('Please enter a valid phone number');
              flashError(errMsg);
              if (getKeyListenersProps && !clickAway) {
                return true;
              }
              throw new Error(errMsg);
            }
            await onSubmit(phoneData);
            setEditing(false);
            setIsError(false);

            if (clickAway && inputRef?.current) {
              inputRef?.current.blur();
            }
            if (getKeyListenersProps && !clickAway) {
              return false;
            }
          } catch (err) {
            if (isCountryCallingCode(phoneData)) {
              setIsError(false);
              setEditing(false);
            }
            if (err instanceof TextInputInline.Error && !err.failed) {
              if (getKeyListenersProps && !clickAway) {
                return false;
              }
              return;
            }
            if (clickAway && isError) {
              // When clicking away after two failures, bail on editing
              setIsError(false);
              setEditing(false);
              cancelFlash();
              if (getKeyListenersProps && !clickAway) {
                return false;
              }
            }
          } finally {
            setSubmitting(false);
          }
        } else {
          setEditing(false);
          cancelFlash();
          if (getKeyListenersProps && !clickAway) {
            return false;
          }
        }
      },
      [
        getKeyListenersProps,
        flashError,
        onSubmit,
        stagedValue,
        submitUnchanged,
        t,
        value,
        isError,
        cancelFlash,
      ]
    );

    const handleFocus = useCallback((ev) => {
      setEditing(true);
      ev.target.click();
    }, []);

    const fieldId = useMemo(
      () => `${field?.id}-${object?.id}`,
      [field, object]
    );

    assignFieldHandle(fieldId, {
      customFocus: () => {
        setEditing(true);
        return targetRef.current;
      },
      disabled: readOnly,
      shouldFocusNext: shouldFocusNext,
    });

    return (
      <PhoneNumberInline ref={targetRef}>
        {readOnly ? (
          <TextEllipsisWithTooltip {...others}>
            {formattedValue}
          </TextEllipsisWithTooltip>
        ) : editing ? (
          <>
            <EditableText
              className="ViewText"
              value={stagedValue ?? emptyPlaceholder}
              disabled={submitting || editing}
              onClick={() => {
                setEditing(true);
              }}
            />
            <PhoneNumberWithOverlay
              editing={editing}
              submitting={submitting}
              targetRef={targetRef}
              ref={ref}
              others={others}
              showErrorMessage={showErrorMessage}
              error={error}
              stagedValue={stagedValue}
              inputRef={inputRef}
              handleChange={handleChange}
              enableExtension={enableExtension}
              defaultCountry={defaultCountry}
              qaFieldType={qaFieldType}
              qaFieldId={qaFieldId}
              submit={submit}
              field={field}
              object={object}
              t={t}
              {...getKeyListenersProps(fieldId)}
              onBlur={() => {
                setEditing(false);
              }}
            />
          </>
        ) : (
          <>
            <TextInputInline
              isShowCheckIcon={false}
              ref={inputRef}
              inputRef={(el) => (inputRef.current = el)}
              value={
                (submitting ? stagedValue : formattedValue) ?? emptyPlaceholder
              }
              editing
              autoFocus={autoFocus}
              onFocus={handleFocus}
              disabled={disabled || submitting}
              {...getDataQAForInput(qaFieldId, qaFieldType)}
            />
          </>
        )}
      </PhoneNumberInline>
    );
  }
);

PhoneNumberInlineField.propTypes = {
  value: PropTypes.string,
};

PhoneNumberInlineField.defaultProps = {
  value: null,
};

export default PhoneNumberInlineField;
