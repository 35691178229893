import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePermissionValue } from '@kizen/permissions/hooks/usePermissionValue';
import { useUpdatePermissionValues } from '@kizen/permissions/hooks/useUpdatePermissionValues';
import { useDisableSectionPermissions } from '@kizen/permissions/hooks/useDisableSectionPermissions';
import { getStreamKey } from '@kizen/permissions/utils';
import {
  PermissionLabel,
  PermissionRow,
  Section,
  SectionContent,
} from './components';

const EMPTY_ARRAY = [];

const getColumnTwoLabel = (key, t) => {
  if (key === 'scheduled_activities_section') return `${t('View')}/${t('Log')}`;
};

const RowWithTooltip = ({
  section,
  permission,
  affordance,
  label,
  allowedAccess = [],
  ...rest
}) => {
  const tooltip = usePermissionValue(
    getStreamKey(permission, section),
    ({ tooltip }) => tooltip
  );
  const isBooleanPermission = usePermissionValue(
    getStreamKey(permission, section),
    ({ initial }) => typeof initial.value === 'boolean'
  );

  const disabledSteps = useMemo(
    () =>
      isBooleanPermission
        ? EMPTY_ARRAY
        : Object.entries({
            view: 1,
            edit: 2,
            remove: 3,
          }).reduce(
            (collect, [key, value]) =>
              allowedAccess.includes(key) ? collect : [...collect, value],
            []
          ),
    [allowedAccess, isBooleanPermission]
  );

  return (
    <PermissionRow
      section={section}
      permission={permission}
      affordance={affordance}
      label={label}
      tooltip={tooltip}
      disabledSteps={disabledSteps}
      {...rest}
    />
  );
};

export const AppAreaSection = ({
  section: { key, label, permissions, categories },
}) => {
  const { t } = useTranslation();
  const isSectionEnabled = usePermissionValue(key, ({ value }) => value);
  const updatePermissionValues = useUpdatePermissionValues();
  const disableSectionPermissions = useDisableSectionPermissions();

  const hasPermissions = permissions?.length > 0;
  const hasCategories = categories?.length > 0;

  const handleSectionToggle = useCallback(
    (value) => {
      if (permissions) {
        value
          ? updatePermissionValues(
              permissions.reduce((acc, config) => {
                acc[getStreamKey(config.key, key)] = config.default;
                return acc;
              }, {})
            )
          : disableSectionPermissions(key);
      }
    },
    [key, permissions, updatePermissionValues, disableSectionPermissions]
  );

  const categorizedPermissions = useMemo(() => {
    return hasPermissions
      ? permissions.reduce((acc, permission) => {
          if (
            hasCategories &&
            permission.category &&
            !acc.find(({ key }) => key === permission.category)
          ) {
            const category = categories.find(
              ({ key }) => key === permission.category
            );
            if (category) acc.push({ ...category, isCategory: true });
          }
          acc.push(permission);
          return acc;
        }, [])
      : [];
  }, [permissions, categories, hasCategories, hasPermissions]);

  return (
    <Section
      showExpander={hasPermissions}
      title={label}
      sectionId={key}
      onChange={handleSectionToggle}
    >
      {isSectionEnabled && hasPermissions && (
        <SectionContent columnTwoLabel={getColumnTwoLabel(key, t)}>
          {categorizedPermissions.map(
            ({
              affordance,
              key: permission_key,
              label,
              tooltip,
              category,
              isCategory,
              allowed_access,
            }) =>
              isCategory ? (
                <PermissionLabel
                  key={permission_key}
                  label={label}
                  tooltip={tooltip}
                />
              ) : (
                <RowWithTooltip
                  key={permission_key}
                  section={key}
                  permission={permission_key}
                  affordance={affordance}
                  label={label}
                  labelIndent={category ? 25 : 0}
                  allowedAccess={allowed_access}
                />
              )
          )}
        </SectionContent>
      )}
    </Section>
  );
};
