import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { handleLegacyBackgroundColor } from '@kizen/page-builder/nodes/containers/utils';
import { TraySection, TraySubsection } from '../components/TraySection';
import { MarginAndPaddingSettings, PaddingSettings } from './SpacingSettings';
import {
  FlexColorPicker,
  FlexSelect,
  FlexSwitch,
  FlexWholeNumberInput,
  FlexWrapper,
} from './components';
import { useCraftProps } from './useCraftProps';
import { SelectImageSubsection } from './SelectImageSubsection';
import { useBuilderContext } from '../BuilderContext';

const styleOptions = (t) => [
  { value: 'none', label: t('None') },
  { value: 'solid', label: t('Solid') },
  { value: 'dashed', label: t('Dashed') },
  { value: 'dotted', label: t('Dotted') },
  { value: 'double', label: t('Double') },
];
const backgroundSizeOptions = (t) => [
  { value: 'auto', label: t('Auto') },
  { value: 'contain', label: t('Contain') },
  { value: 'cover', label: t('Cover') },
];
const backgroundRepeatOptions = (t) => [
  { value: 'repeat', label: t('On') },
  { value: 'repeat-x', label: t('Horizontal') },
  { value: 'repeat-y', label: t('Vertical') },
  { value: 'no-repeat', label: t('Off') },
];

const withDefault = (value, fallback) => {
  return value === undefined ? fallback : value;
};

const BorderRadiusWrapper = styled(FlexWrapper)`
  height: 0;
  transition: height 0.2s linear;
  ${({ show }) =>
    show &&
    css`
      height: 44px;
    `}
`;

const ContainerBorderRadiusToggle = styled(FlexSwitch)`
  margin-top: 4px;
`;

/**
 * @remarks `containerBackgroundPositionX` and `containerBackgroundPositionY` are not configurable props, but are set up for
 * that eventuality. Currently, the defaults differ between forms and emails (overridden in SectionSettings). Forms and surveys
 * specify the browser default of 0% and emails sepecify MJML's default of 'top center'. We set these defaults on image change
 * to accommodate old forms/surveys/emails.
 */
export const ElementContainerSettingsSection = ({
  node: {
    id,
    data: { props: initialProps },
  },
  onBackClick,
  collapsable = true,
  defaultBackgroundPositionX = '0%',
  defaultBackgroundPositionY = '0%',
  enableBackgroundImage = true,
  enableBorder = true,
  enableBorderRadius = true,
  enableMargin = true,
}) => {
  const traySectionRef = useRef();
  const previousId = useRef(id);
  const { t } = useTranslation();
  const [props, setProp, setProps] = useCraftProps(id, initialProps);
  const { isElementSettingsOpen, setIsElementSettingsOpen } =
    useBuilderContext();

  useEffect(() => {
    if (previousId.current !== id) {
      setProps(initialProps);
    }
    previousId.current = id;
  }, [initialProps, id, setProps]);

  const handleImageChange = (file) => {
    setProp('containerBackgroundImageSrc', file.url);
    setProp('containerBackgroundImageId', file.id);
    setProp('containerBackgroundImageName', file.name);
    setProp('containerBackgroundPositionX', defaultBackgroundPositionX);
    setProp('containerBackgroundPositionY', defaultBackgroundPositionY);
  };

  const handleRemoveImage = () => {
    setProp('containerBackgroundImageSrc', null);
    setProp('containerBackgroundImageId', null);
    setProp('containerBackgroundImageName', '');
  };

  return (
    <TraySection
      ref={traySectionRef}
      onBackClick={onBackClick}
      header={t('Element Styles')}
      collapsable={collapsable}
      onCollapse={() => setIsElementSettingsOpen((x) => !x)}
      open={isElementSettingsOpen}
    >
      {enableBackgroundImage && (
        <TraySubsection header={t('Background Image')}>
          <SelectImageSubsection
            fileId={props.containerBackgroundImageId}
            fileName={props.containerBackgroundImageName}
            onImageChange={handleImageChange}
            onImageDelete={handleRemoveImage}
            onImageRemove={handleRemoveImage}
          />
          <FlexWrapper>
            <FlexSelect
              variant="underline"
              options={backgroundSizeOptions(t)}
              label={t('Size')}
              value={props.containerBackgroundSize || 'auto'}
              onChange={(v) => setProp('containerBackgroundSize', v.value)}
            />
            <FlexSelect
              variant="underline"
              options={backgroundRepeatOptions(t)}
              label={t('Repeat')}
              value={props.containerBackgroundRepeat || 'repeat'}
              onChange={(v) => setProp('containerBackgroundRepeat', v.value)}
            />
          </FlexWrapper>
        </TraySubsection>
      )}
      <TraySubsection header={t('Colors')}>
        <FlexWrapper>
          <FlexColorPicker
            color={handleLegacyBackgroundColor(props.containerBackgroundColor)}
            label={t('Background')}
            onChange={(rgba) => setProp('containerBackgroundColor', rgba)}
          />
          {enableBorder && (
            <FlexColorPicker
              color={props.containerBorderColor}
              label={t('Border')}
              align="center"
              onChange={(rgba) => setProp('containerBorderColor', rgba)}
            />
          )}
        </FlexWrapper>
      </TraySubsection>
      {enableBorder && (
        <TraySubsection header={t('Borders')}>
          <FlexWrapper
            marginBottom={props.containerBorderRadius ? undefined : 0}
          >
            <FlexSelect
              variant="underline"
              options={styleOptions(t)}
              scrollContainer={traySectionRef.current}
              value={withDefault(props.containerBorderStyle, 'none')}
              label={t('Style')}
              onChange={(v) => setProp('containerBorderStyle', v.value)}
            />
            <FlexWholeNumberInput
              shrink
              variant="underline"
              value={props.containerBorderWidth}
              label={t('Thickness (px)')}
              onChange={(v) => setProp('containerBorderWidth', v)}
            />
            {enableBorderRadius && (
              <ContainerBorderRadiusToggle
                checked={props.containerBorderRadius}
                label={t('Radius (px)')}
                textPlacement="top"
                onChange={({ target: { checked } }) =>
                  setProp('containerBorderRadius', checked)
                }
              />
            )}
          </FlexWrapper>
          {enableBorderRadius && (
            <BorderRadiusWrapper show={props.containerBorderRadius}>
              <FlexWholeNumberInput
                shrink
                variant="underline"
                value={props.containerBorderTopLeftRadius}
                label={t('Top-left')}
                onChange={(v) => setProp('containerBorderTopLeftRadius', v)}
              />
              <FlexWholeNumberInput
                shrink
                variant="underline"
                value={props.containerBorderTopRightRadius}
                label={t('Top-right')}
                onChange={(v) => setProp('containerBorderTopRightRadius', v)}
              />
              <FlexWholeNumberInput
                shrink
                variant="underline"
                value={props.containerBorderBottomLeftRadius}
                label={t('Bot-left')}
                onChange={(v) => setProp('containerBorderBottomLeftRadius', v)}
              />
              <FlexWholeNumberInput
                shrink
                variant="underline"
                value={props.containerBorderBottomRightRadius}
                label={t('Bot-right')}
                onChange={(v) => setProp('containerBorderBottomRightRadius', v)}
              />
            </BorderRadiusWrapper>
          )}
        </TraySubsection>
      )}
      <TraySubsection header={t('Spacing (px)')}>
        <FlexWrapper>
          {enableMargin ? (
            <MarginAndPaddingSettings
              topMargin={props.containerMarginTop}
              rightMargin={props.containerMarginRight}
              bottomMargin={props.containerMarginBottom}
              leftMargin={props.containerMarginLeft}
              topPadding={props.containerPaddingTop}
              rightPadding={props.containerPaddingRight}
              bottomPadding={props.containerPaddingBottom}
              leftPadding={props.containerPaddingLeft}
              onTopMarginChange={(v) => setProp('containerMarginTop', v)}
              onRightMarginChange={(v) => setProp('containerMarginRight', v)}
              onBottomMarginChange={(v) => setProp('containerMarginBottom', v)}
              onLeftMarginChange={(v) => setProp('containerMarginLeft', v)}
              onTopPaddingChange={(v) => setProp('containerPaddingTop', v)}
              onRightPaddingChange={(v) => setProp('containerPaddingRight', v)}
              onBottomPaddingChange={(v) =>
                setProp('containerPaddingBottom', v)
              }
              onLeftPaddingChange={(v) => setProp('containerPaddingLeft', v)}
            />
          ) : (
            <PaddingSettings
              top={props.containerPaddingTop}
              right={props.containerPaddingRight}
              bottom={props.containerPaddingBottom}
              left={props.containerPaddingLeft}
              onTopChange={(val) => setProp('containerPaddingTop', val)}
              onRightChange={(val) => setProp('containerPaddingRight', val)}
              onBottomChange={(val) => setProp('containerPaddingBottom', val)}
              onLeftChange={(val) => setProp('containerPaddingLeft', val)}
            />
          )}
        </FlexWrapper>
      </TraySubsection>
    </TraySection>
  );
};
