import { camelToSnakeCase, camelToSnakeCaseKeys } from 'services/helpers';
import FieldService from 'services/FieldService';
import ClientService from 'services/ClientService';
import {
  getFieldsForUI,
  ownerToOption,
} from 'store/customObjectsRecordsPage/saga';

export const ITEMS_BUFFER = 20;
export const ROW_HEIGHT = 40;
export const HEADER_HEIGHT = 50;

const emptyData = {
  count: 0,
  errors: [],
  results: [],
  next: null,
  previous: null,
};

export const handleFetchRecords = async ({
  search,
  page,
  pageSize,
  ordering,
  model,
  dashletFilters,
  filtersForCharts,
  client,
  fieldIds,
  searchWithinFieldIds,
  inGroupIds,
  notInGroupIds,
}) => {
  // We need to build a filter object, then AND together the dashlet filters
  // and the global filters
  const filterObject = {
    and: true,
    query: [],
  };

  if (filtersForCharts?.query?.length) {
    filterObject.query.push(filtersForCharts);
  }

  if (dashletFilters?.query?.length) {
    filterObject.query.push(dashletFilters);
  }

  const body = {
    search: search,
    ordering: camelToSnakeCase(ordering),
    number: page,
    size: pageSize,
    modelName: model.name,
    fieldIds,
    criteria: {
      searchWithinFieldIds,
      ...camelToSnakeCaseKeys(filterObject),
    },
  };

  const params = {
    search,
    ordering: camelToSnakeCase(ordering),
    page,
    pageSize,
    inGroupIds,
    notInGroupIds,
  };
  if (!client) {
    const data = await FieldService.getModelRecords({
      model: model,
      params,
      body,
    });

    // don't return anything if we get an error
    if (data?.errors?.length) {
      return emptyData;
    }

    // need to remap taken from `store/customObjectsRecordsPage/saga.js`
    const results = data.results.map((item) => {
      const fields = getFieldsForUI(item.fields);
      return {
        ...item,
        owner: item.owner ? ownerToOption(item.owner) : null,
        stage: item.stage
          ? {
              value: item.stage.id,
              label: item.stage.name,
            }
          : null,
        fields,
      };
    });

    return {
      count: data.count,
      errors: data.errors || [],
      results,
      next: data.next || null,
      previous: data.previous || null,
    };
  }

  try {
    const data = await ClientService.search({
      search,
      page: {
        number: page,
        size: pageSize,
      },
      ordering: camelToSnakeCase(ordering),
      criteria: { ...filterObject, searchWithinFieldIds },
      fieldIds,
      inGroupIds,
      notInGroupIds,
    });

    const results = data.results.map((item) => {
      const fields = getFieldsForUI(
        item.fields.map((f) => {
          return {
            ...f,
            //  The data shape differs slightly for contacts, so in order to call the same function
            // we need to manually convert from field to id
            id: f.id ?? f.field,
          };
        })
      );
      return {
        ...item,
        fields,
      };
    });

    return {
      count: data.count,
      errors: data.errors || [],
      results: results,
      next: data.next,
      previous: data.previous,
    };
  } catch (e) {
    return {
      count: 0,
      errors: [],
      results: [],
      next: null,
      previous: null,
    };
  }
};

export const getColumnsFromConfig = (dashlet, fieldsById) => {
  return (
    dashlet?.config?.feExtraInfo?.columns
      .map((c) => {
        const field = fieldsById?.[c.id];
        if (!field) {
          return null;
        }

        return {
          ...c,
          name: field?.name,
        };
      })
      ?.filter(Boolean) ?? []
  );
};
