import { useTranslation } from 'react-i18next';
import StepCard from '__pages/AutomationEngine/Engine/Steps/StepCard';

type StepProps = {
  step: {
    details: any;
  };
  color: string;
  title: string;
};

export default function RunGetRequestsCard({
  step,
  color,
  title,
  ...others
}: StepProps) {
  const { t } = useTranslation();
  return (
    <StepCard
      {...others}
      color={color}
      showMovingPlaceholder={false}
      onSelectMenu={null}
      title={title}
      panel={null}
      editOnly
    >
      {t('Retrieve {{#}} Payloads', {
        interpolation: { prefix: '{{{', suffix: '}}}' },
      })}
    </StepCard>
  );
}
