import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomObjectsService from 'services/CustomObjectsService';
import Select from 'components/Inputs/Select';
import BasicModal from 'components/Modals/presets/BasicModal';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { useAsync } from 'react-use';
import { FIELD_TYPES } from 'utility/constants';
import { QUICK_FILTERS_FIELD_TYPES } from 'components/Filters/QuickFilters/constants';
import ToggleButton from 'components/Kizen/ToggleButton';
import InputControl from 'components/Inputs/InputControl';
import Loader from 'components/Kizen/Loader';
import { v4 as uuidv4 } from 'uuid';
import { getDefaultRelatedFieldLabel } from '../helpers';
import { QUICK_FILTERS_IN_GROUPS } from 'store/utilities';
import { deferExecution } from 'utility/defer';

const getToggleButtonOptions = (t) => [
  { label: t('Is In Group(s)'), value: QUICK_FILTERS_IN_GROUPS },
  { label: t('Has Field Value(s)'), value: 'field_values' },
];

export const RelatedFilterModal = ({
  onHide,
  index,
  setShowModal,
  fieldId,
  relatedObjectField,
  relatedObjectFieldId,
  fields,
  editValue,
}) => {
  const { t } = useTranslation();
  const [field, setField] = useState(() => {
    if (fieldId) {
      const selectedField = fields.find(({ id }) => id === fieldId);
      return (
        selectedField && {
          value: selectedField.id,
          label: selectedField.displayName,
          field: selectedField,
        }
      );
    }
  });

  const [isInGroups, setIsInGroups] = useState(
    !relatedObjectField || relatedObjectFieldId === QUICK_FILTERS_IN_GROUPS
  );
  const [relatedField, setRelatedObjectField] = useState(
    relatedObjectField && {
      value: relatedObjectField.id,
      label: relatedObjectField.displayName,
    }
  );

  const isEdit = !!relatedObjectFieldId;

  const handleChangeField = (fieldOption) => {
    setField(fieldOption);
    setRelatedObjectField((prev) =>
      fieldOption.value === field?.value ? prev : null
    );
  };

  const handleChangeRelatedObjectField = (fieldOption) => {
    setRelatedObjectField(fieldOption);
  };

  const HandleToggleOption = ({ value }) =>
    setIsInGroups(value === QUICK_FILTERS_IN_GROUPS);

  const relationshipFieldOptions = useMemo(() => {
    return fields.reduce(
      (acc, field) =>
        field.fieldType === FIELD_TYPES.Relationship.type
          ? acc.concat({ value: field.id, label: field.displayName, field })
          : acc,
      []
    );
  }, [fields]);

  const toggleOptions = useMemo(() => getToggleButtonOptions(t), [t]);

  const {
    value: reletedObjectFeildOptions = EMPTY_ARRAY,
    loading: loadingRelatedObjectFields,
  } = useAsync(async () => {
    if (!field?.field?.relation?.relatedObject) {
      return EMPTY_ARRAY;
    }
    const fields = await CustomObjectsService.getCustomModelFields(
      field.field.relation.relatedObject
    );

    return fields.reduce((acc, field) => {
      if (QUICK_FILTERS_FIELD_TYPES.includes(field.fieldType)) {
        acc.push({ value: field.id, label: field.displayName, field });
      }
      return acc;
    }, []);
  }, [field?.field?.relation?.relatedObject]);

  const isDirty =
    field?.value !== fieldId ||
    (isInGroups
      ? relatedObjectFieldId !== QUICK_FILTERS_IN_GROUPS
      : relatedField?.value !== relatedObjectFieldId);

  const onConfirm = async () => {
    const newFieldId = field.value;
    const newRelatedObjectFieldId = isInGroups
      ? QUICK_FILTERS_IN_GROUPS
      : relatedField?.value || relatedObjectFieldId;

    editValue(index, undefined, 'id', uuidv4());
    editValue(index, undefined, 'fieldId', newFieldId);
    editValue(
      index,
      undefined,
      'relatedObjectFieldId',
      newRelatedObjectFieldId
    );
    if (!isInGroups) {
      editValue(index, undefined, 'relatedObjectField', {
        id: relatedField.value,
        displayName: relatedField.label,
      });
    }

    const defaultLabel = getDefaultRelatedFieldLabel(
      field?.label,
      newRelatedObjectFieldId,
      relatedField?.label,
      t
    );
    if (!isEdit) {
      editValue(index, undefined, 'label', defaultLabel);
    }
    editValue(index, undefined, 'defaultLabel', defaultLabel);

    deferExecution(() => {
      setShowModal(null);
    });
  };

  return (
    <BasicModal
      heading={
        isEdit
          ? t('Edit Related Entity Quick Filter Field')
          : t('Add Related Entity Quick Filter Field')
      }
      disabled={
        !isDirty ||
        loadingRelatedObjectFields ||
        !field ||
        (!isInGroups && !relatedField)
      }
      onConfirm={onConfirm}
      size="small"
      show
      onHide={onHide}
      data-qa="related-entity-quick-filter-modal"
    >
      <Select
        label={t('Has Entity in Relationship Field')}
        placeholder={t('Choose Relationship Field')}
        menuInline
        value={field}
        onChange={handleChangeField}
        options={relationshipFieldOptions}
        margin
      />
      {field ? (
        <InputControl label={t('Quick Filter Function')} margin>
          <ToggleButton
            sizing={null}
            options={toggleOptions}
            value={isInGroups ? QUICK_FILTERS_IN_GROUPS : 'field_values'}
            onChange={HandleToggleOption}
          />
        </InputControl>
      ) : null}
      {field && !isInGroups ? (
        <Loader loading={loadingRelatedObjectFields}>
          <Select
            label={t('Related Field')}
            placeholder={t('Choose Field from Related Object')}
            menuInline
            value={relatedField}
            onChange={handleChangeRelatedObjectField}
            options={reletedObjectFeildOptions}
            margin
          />
        </Loader>
      ) : null}
    </BasicModal>
  );
};
