import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import LoadingButton from 'components/Button/LoadingButton';
import Modal, { ModalBody, ModalFooter, ModalHeader } from 'components/Modals';

const StyledLoadingButton = styled(LoadingButton)`
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `}
`;

const StyledModalFooter = styled(ModalFooter)`
  > button:first-child {
    // Cancel button
    padding: 0;
    padding-top: 2px;
    margin: 0 15px;
  }
  > button:not(:first-child) {
    // Discard Changes and Save & Close buttons
    margin-left: 5px;
  }
`;

export const ConfirmExitModal = ({
  heading,
  onCancel,
  onDiscard,
  onSave,
  cancelButtonText,
  discardButtonText,
  saveButtonText,
  isSaving = false,
  show = true,
  children,
  ...rest
}) => {
  const { t } = useTranslation();
  const ref = useRef();

  const handleCancel = () => {
    if (!isSaving) {
      onCancel();
    }
  };

  return (
    <Modal size="small" show={show} onHide={handleCancel} {...rest}>
      <ModalHeader onClickClose={handleCancel}>{heading}</ModalHeader>
      <ModalBody typeOfContent="text">
        <KizenTypography>{children}</KizenTypography>
      </ModalBody>
      <StyledModalFooter actionBtnWithoutSpace>
        <Button onClick={handleCancel} variant="text" color="blue">
          {cancelButtonText || t('Cancel')}
        </Button>
        <Button onClick={onDiscard} color="red">
          {discardButtonText || t('Discard Changes')}
        </Button>
        <StyledLoadingButton
          ref={ref}
          minWidth={ref.current?.offsetWidth}
          onClick={onSave}
          loading={isSaving}
        >
          {saveButtonText || t('Save & Close')}
        </StyledLoadingButton>
      </StyledModalFooter>
    </Modal>
  );
};
