import {
  colorsButton,
  colorsPrimary,
  colorsSecondary,
  grayScale,
} from 'app/colors';
import { snakeToCamelCase } from 'services/helpers';

export const TRIGGER = 'trigger';
export const CONDITION = 'condition';
export const GOAL = 'goal';
export const CONNECTION = 'connection';
export const TRANSFORM = 'transform';
export const UPLOAD = 'upload';

const actionDisplay = {
  title: 'Action',
  color: colorsPrimary.green.dark,
  stats: null,
  movable: true,
  creatable: true,
  deletable: true,
  actionType: true,
  prefix: 'action',
};

export const triggerDisplay = {
  title: 'Trigger',
  color: colorsPrimary.blue.dark,
  stats: null,
  deletable: true,
  creatable: true,
  triggerType: true,
  prefix: 'trigger',
};

const goalDisplay = {
  type: 'goal',
  title: 'Goal',
  color: colorsPrimary.blue.dark,
  stats: null,
  movable: true,
  creatable: true,
  deletable: true,
  goalType: true,
  prefix: 'step',
};

const connectionDisplay = {
  type: 'connection',
  title: 'Connection',
  color: grayScale.mediumDark,
  stats: null,
  movable: true,
  creatable: true,
  deletable: true,
  goalType: true,
  prefix: 'step',
};

const uploadDisplay = {
  type: 'upload',
  title: 'Upload',
  color: colorsPrimary.orange.dark,
  stats: null,
  movable: true,
  creatable: true,
  deletable: true,
  goalType: true,
  prefix: 'step',
};

const steps = {
  // Action types
  action: {
    // Generic, just here temporarily for drag-and-drop
    type: 'action',
    ...actionDisplay,
  },
  httpRequest: {
    type: 'http_request',
    ...actionDisplay,
    CardComponent: 'HttpRequestCard',
  },
  changeFieldValue: {
    type: 'change_field_value',
    ...actionDisplay,
    CardComponent: 'ChangeFieldValueCard',
  },
  modifyAutomation: {
    type: 'modify_automation',
    ...actionDisplay,
    CardComponent: 'ModifyAutomationCard',
  },
  createRelatedEntity: {
    type: 'create_related_entity',
    ...actionDisplay,
    CardComponent: 'CreateRelatedEntityCard',
  },
  modifyRelatedEntities: {
    type: 'modify_related_entities',
    ...actionDisplay,
    CardComponent: 'ModifyRelatedEntitiesCard',
  },
  sendRelatedContactEmail: {
    type: 'send_related_contact_email',
    ...actionDisplay,
    CardComponent: 'SendRelatedContactEmailCard',
  },
  changeTags: {
    type: 'change_tags',
    ...actionDisplay,
    CardComponent: 'ChangeTagsCard',
  },
  sendEmail: {
    type: 'send_email',
    ...actionDisplay,
    CardComponent: 'SendEmailCard',
  },
  notifyMemberViaText: {
    type: 'notify_member_via_text',
    ...actionDisplay,
    CardComponent: 'NotifyMemberViaTextCard',
  },
  startCancelAutomation: {
    type: 'start_cancel_automation',
    ...actionDisplay,
  },
  notifyMemberViaEmail: {
    type: 'notify_member_via_email',
    ...actionDisplay,
    CardComponent: 'NotifyMemberViaEmailCard',
  },
  scheduleActivity: {
    type: 'schedule_activity',
    ...actionDisplay,
    CardComponent: 'ScheduleActivityCard',
  },
  assignTeamMember: {
    type: 'assign_team_member',
    ...actionDisplay,
    CardComponent: 'AssignTeamMemberCard',
  },
  mathOperator: {
    type: 'math_operator',
    ...actionDisplay,
    CardComponent: 'MathOperatorCard',
  },
  goToAutomationStep: {
    type: 'go_to_automation_step',
    ...actionDisplay,
    CardComponent: 'GoToAutomationStepCard',
  },
  modifyRelatedEntitiesAutomation: {
    type: 'modify_related_entities_automation',
    ...actionDisplay,
    CardComponent: 'ModifyRelatedEntitiesAutomationCard',
  },

  condition: {
    type: 'condition',
    title: 'Condition',
    color: colorsPrimary.purple.dark,
    stats: '34 Completed | 24 Yes & 10 No',
    movable: true,
    creatable: true,
    deletable: true,
    CardComponent: 'ConditionCard',
    prefix: 'step',
  },
  delay: {
    type: 'delay',
    title: 'Delay',
    color: colorsPrimary.orange.dark,
    stats: '34 Completed',
    movable: true,
    creatable: true,
    deletable: true,
    CardComponent: 'DelayCard',
    prefix: 'step',
  },
  upload: {
    type: 'upload',
    title: 'Upload',
    color: colorsPrimary.orange.dark,
    stats: '34 Completed',
    movable: true,
    creatable: true,
    deletable: true,
    CardComponent: 'DelayCard',
    prefix: 'step',
  },
  splitTest: {
    type: 'split_test',
    title: 'Split Test',
    color: colorsSecondary.tangerine.dark,
    stats: '34 Completed',
    movable: false,
    creatable: false,
    deletable: false,
    prefix: 'action',
  },
  goal: {
    ...goalDisplay,
  },
  activityLoggedGoal: {
    ...goalDisplay,
    type: 'activity_logged_goal',
    CardComponent: 'ActivityLoggedCard',
  },
  scheduledActivityOverdueGoal: {
    ...goalDisplay,
    type: 'scheduled_activity_overdue_goal',
    CardComponent: 'ActivityOverdueCard',
  },
  fieldUpdatedGoal: {
    ...goalDisplay,
    type: 'field_updated_goal',
    CardComponent: 'FieldUpdatedCard',
  },
  contactTagAddedRemovedGoal: {
    ...goalDisplay,
    type: 'contact_tag_added_removed_goal',
    CardComponent: 'ContactTagAddedRemovedCard',
  },
  onOrAroundDateGoal: {
    ...goalDisplay,
    type: 'on_or_around_date_goal',
    CardComponent: 'OnOrAroundDateCard',
  },
  emailReceivedGoal: {
    ...goalDisplay,
    type: 'email_received_goal',
    CardComponent: 'EmailReceivedCard',
  },
  emailLinkClickedGoal: {
    ...goalDisplay,
    type: 'email_link_clicked_goal',
    CardComponent: 'EmailLinkClickedCard',
  },
  formSubmittedGoal: {
    ...goalDisplay,
    type: 'form_submitted_goal',
    CardComponent: 'FormSubmittedCard',
  },
  stageUpdatedGoal: {
    ...goalDisplay,
    type: 'stage_updated_goal',
    CardComponent: 'StageUpdatedCard',
  },

  surveySubmittedGoal: {
    ...goalDisplay,
    type: 'survey_submitted_goal',
    CardComponent: 'SurveySubmittedCard',
  },

  trigger: {
    ...triggerDisplay,
    type: TRIGGER,
    title: 'Trigger (Manual)',
    CardComponent: 'ManualTriggerCard',
  },
  manual: {
    ...triggerDisplay,
    type: 'manual',
    title: 'Trigger (Manual)',
    CardComponent: 'ManualTriggerCard',
  },
  addTrigger: {
    ...triggerDisplay,
    type: 'add_trigger',
    title: 'Trigger',
    CardComponent: 'AddTriggerCard',
  },
  newEntityCreated: {
    type: 'new_entity_created',
    ...triggerDisplay,
    CardComponent: 'NewEntityCreatedCard',
  },
  activityLogged: {
    type: 'activity_logged',
    ...triggerDisplay,
    CardComponent: 'ActivityLoggedCard',
  },
  scheduledActivityOverdue: {
    ...triggerDisplay,
    type: 'scheduled_activity_overdue',
    CardComponent: 'ActivityOverdueCard',
  },
  emailInteraction: {
    type: 'email_interaction',
    ...triggerDisplay,
  },
  emailLinkClicked: {
    type: 'email_link_clicked',
    ...triggerDisplay,
    CardComponent: 'EmailLinkClickedCard',
  },
  fieldUpdated: {
    type: 'field_updated',
    ...triggerDisplay,
    CardComponent: 'FieldUpdatedCard',
  },
  formSubmitted: {
    type: 'form_submitted',
    ...triggerDisplay,
    CardComponent: 'FormSubmittedCard',
  },
  interactionOccurred: {
    type: 'interaction_occurred',
    ...triggerDisplay,
  },
  onOrAroundDate: {
    type: 'on_or_around_date',
    ...triggerDisplay,
    CardComponent: 'OnOrAroundDateCard',
  },
  emailReceived: {
    ...triggerDisplay,
    type: 'email_received',
    CardComponent: 'EmailReceivedCard',
  },
  surveySubmitted: {
    type: 'survey_submitted',
    ...triggerDisplay,
    CardComponent: 'SurveySubmittedCard',
  },
  contactTagAddedRemoved: {
    type: 'contact_tag_added_removed',
    ...triggerDisplay,
    CardComponent: 'ContactTagAddedRemovedCard',
  },
  websiteVisited: {
    type: 'website_visited',
    ...triggerDisplay,
  },
  stageUpdated: {
    type: 'stage_updated',
    ...triggerDisplay,
    CardComponent: 'StageUpdatedCard',
  },
  unknown: {
    type: 'unknown',
    title: 'Unknown',
    color: grayScale.mediumDark,
    stats: null,
  },
  unknownTrigger: {
    type: 'unknown_trigger',
    ...triggerDisplay,
    title: 'Trigger (Unknown)',
    stats: null,
  },
  errorUnknown: {
    type: 'error_unknown',
    title: 'Unknown',
    color: colorsButton.red.hover,
    stats: null,
    CardComponent: 'ErrorCard',
  },
  errorUnknownTrigger: {
    type: 'unknown_trigger',
    ...triggerDisplay,
    color: colorsButton.red.hover,
    title: 'Trigger (Unknown)',
    stats: null,
    CardComponent: 'ErrorCard',
  },
  /// Smart Connector
  spreadsheetUpload: {
    ...triggerDisplay,
    type: 'spreadsheet_upload',
    title: (t) => t('Connection Trigger'),
    CardComponent: 'TriggerStepSpreadsheetUploadedCard',
  },
  connectionIntializeAdditionalVariables: {
    ...connectionDisplay,
    type: 'connection_intialize_additional_variables',
    title: (t) => t('Initialize Additional Variables'),
    CardComponent: 'InitializeAdditionalVariablesCard',
  },
  connectionRunGetRequests: {
    ...connectionDisplay,
    type: 'connection_run_get_requests',
    title: (t) => t('Run GET Requests'),
    CardComponent: 'RunGetRequestsCard',
  },
  uploadPrimaryObject: {
    ...uploadDisplay,
    type: 'upload_primary_object',
    title: (t) => t('Upload Primary Object'),
    CardComponent: 'UploadPrimaryObjectCard',
  },
  uploadRelatedObject: {
    ...uploadDisplay,
    type: 'upload_related_object',
    title: (t) => t('Upload Related Object'),
    CardComponent: 'UploadPrimaryObjectCard',
  },
};

export default steps;

export const getStepConfig = (type, { isTrigger } = {}) =>
  steps[snakeToCamelCase(type)] ||
  (isTrigger ? steps.unknownTrigger : steps.unknown);

export const getErrorStepConfig = (type, { isTrigger } = {}) =>
  steps[snakeToCamelCase(type)]
    ? {
        ...steps[snakeToCamelCase(type)],
        color: colorsButton.red.hover,
        stats: null,
        CardComponent: 'ErrorCard',
      }
    : isTrigger
      ? steps.errorUnknownTrigger
      : steps.errorUnknown;

export const edgeLabels = {
  yes: {
    type: 'yes',
    text: 'Yes',
    goalText: 'met',
    color: colorsButton.green.hover,
    detailsKey: 'yesSteps',
  },
  no: {
    type: 'no',
    text: 'No',
    goalText: 'not met',
    color: colorsButton.red.hover,
    detailsKey: 'noSteps',
  },
};

export const defaultTrigger = {
  id: `new.${TRIGGER}`,
  type: steps.manual.type,
  details: {},
  order: 0,
  prefix: triggerDisplay.prefix,
};

export const defaultSmartConnectorTrigger = {
  id: `new.${TRIGGER}`,
  type: steps.spreadsheetUpload.type,
  details: {},
  order: 0,
  prefix: triggerDisplay.prefix,
};

export const defaultUploadStep = {
  id: `new.${UPLOAD}.primary_object`,
  type: steps.uploadPrimaryObject.type,
  details: {},
  order: 0,
  prefix: uploadDisplay.prefix,
};

export const defaultConnectionStep = {
  id: `new.${CONNECTION}.intialize_additional_variables`,
  type: steps.connectionIntializeAdditionalVariables.type,
  details: {},
  order: 0,
  prefix: uploadDisplay.prefix,
};

export const defaultConnectionGetRequestsStep = {
  id: `new.${CONNECTION}.run_get_requests`,
  type: steps.connectionRunGetRequests.type,
  details: {},
  order: 0,
  prefix: uploadDisplay.prefix,
};
