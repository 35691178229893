import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import useToggle from 'react-use/lib/useToggle';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as PropTypes from 'prop-types';
import useSyncScroll from 'react-use-sync-scroll';

import { tableHover } from 'app/colors';
import { gutters, hideScrollbarCss, layers } from 'app/spacing';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { CONTENT_MAX_WIDTH, ContentWidth } from '../Layout/PageContentWidth';
import { PAGE_TOOLBAR_HEIGHT } from '../Layout/PageToolbar';
import ScrollBarDetached from '../Layout/ScrollBarDetached';
import { useSyncSizes } from '../Tables/Big/hooks';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';
import { ADD_RECORD_TAB_MAX_WIDTH } from '../Tables/Big/AddRecordTab';
import { useHorizontalScrollWhilePlacing } from './utils';
import { useNavBarState } from 'app/navBarController';
import { DEFAULT_DELAY } from 'utility/config';

const num = (px) => parseInt(px, 10);

const BOTTOM_SPACE = 10;
const QUICK_FILTERS_HEIGHT = 61;
export const PADDING_TO_SCROLL = gutters.spacing(6, 1);

const EXPANDABLE_MIN_WIDTH = `${
  num(CONTENT_MAX_WIDTH) +
  2 * (num(ADD_RECORD_TAB_MAX_WIDTH) + num(gutters.standard))
}px`;

const BoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - ${PAGE_TOOLBAR_HEIGHT}px - ${({ navHeight }) => navHeight}px - 43px -
      ${BOTTOM_SPACE}px -
      ${({ hasQuickFilters }) => (hasQuickFilters ? QUICK_FILTERS_HEIGHT : 0)}px
  ); // 43px padding bottom
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: none;
    `}
`;

const ExpandButtonWrapper = styled.div`
  display: none;
  @media (min-width: ${EXPANDABLE_MIN_WIDTH}) {
    display: flex;
  }
  top: ${PAGE_TOOLBAR_HEIGHT}px;
  height: 0;
  justify-content: flex-end;
  z-index: ${layers.content(0, 2)};
  && > * {
    margin-top: -${gutters.spacing(4)}px;
    // y-translation is calculated centering
    // relative to the table row: 50px + (40px - 30px) / 2
    transform: translate(50%, 55px);
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  height: 100%;
  padding-bottom: ${PADDING_TO_SCROLL}px;
  ${hideScrollbarCss}
`;

const BoardContent = styled.div`
  display: flex;
`;

const Gradient = styled((props) => (
  <BaseGradient fadeStart={0} fadeLength={50} color={tableHover} {...props} />
))`
  height: 100%;
  z-index: ${layers.content(0, 1)};
`;

export const DndContext = React.createContext();

let timer = null;

const BigBoard = ({
  expandTab,
  children,
  columnWidth,
  handleUpdateLocalPageConfig,
  localPageConfig,
  ...props
}) => {
  const [showExpandIcon, setShowExpandIcon] = useState(true);
  const [boardExpanded, toggleBoardExpanded] = useToggle(
    localPageConfig?.boardExpanded
  );
  const [placing, togglePlacing] = useToggle(false);
  const scrollProps = useHorizontalScrollWhilePlacing(placing, columnWidth);
  const { height } = useNavBarState();

  const scrollbarContentWrapperRef = useRef();
  const [boardWrapperRefFn, boardWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncWidth',
    'width'
  );

  useSyncScroll(useRef([boardWrapperRef, scrollbarContentWrapperRef]), {
    horizontal: true,
  });

  const [, rightScrolled, , leftScrolled] = useEndsOfScroll(boardWrapperRef, [
    boardExpanded,
  ]);

  useEffect(() => {
    if (boardWrapperRef.current) {
      setShowExpandIcon(
        boardWrapperRef.current.scrollWidth >
          boardWrapperRef.current.clientWidth
      );
    }
  }, [boardWrapperRef]);

  const scrollToSaved = useCallback(() => {
    if (boardWrapperRef.current && localPageConfig?.scroll?.left) {
      boardWrapperRef.current.scrollTo({
        left: localPageConfig.scroll.left,
        behavior: 'smooth',
      });
    }
  }, [boardWrapperRef, localPageConfig?.scroll?.left]);

  useLayoutEffect(() => {
    const scrollTimer = setTimeout(scrollToSaved);
    return () => {
      clearTimeout(scrollTimer);
    };
  }, [scrollToSaved]);

  const handleBoardExpanded = useCallback(() => {
    handleUpdateLocalPageConfig({ boardExpanded: !boardExpanded });
    toggleBoardExpanded();
  }, [handleUpdateLocalPageConfig, boardExpanded, toggleBoardExpanded]);

  const onScrollFinish = (delay = DEFAULT_DELAY) => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      handleUpdateLocalPageConfig({
        scroll: {
          left: boardWrapperRef.current.scrollLeft,
        },
      });
    }, delay);
  };

  return (
    <BoardWrapper
      as={ContentWidth}
      fullWidth={boardExpanded}
      navHeight={height}
      className="board"
      {...props}
    >
      {expandTab && showExpandIcon && (
        <ExpandButtonWrapper>
          {React.cloneElement(expandTab, {
            expanded: boardExpanded,
            onClick: handleBoardExpanded,
          })}
        </ExpandButtonWrapper>
      )}
      <ColumnWrapper
        ref={boardWrapperRefFn}
        {...scrollProps}
        onScroll={() => {
          onScrollFinish();
        }}
      >
        <Gradient position="left" fadeIn={!leftScrolled} />
        <DndContext.Provider value={{ placing, togglePlacing }}>
          <BoardContent className="ContentForSyncWidth">
            {children}
          </BoardContent>
        </DndContext.Provider>
        <Gradient position="right" fadeIn={!rightScrolled} />
      </ColumnWrapper>
      <ScrollBarDetached
        ref={scrollbarContentWrapperRef}
        direction="horizontal"
        scrollClassName="ContentForSyncWidth"
      />
    </BoardWrapper>
  );
};

BigBoard.propTypes = {
  columnWidth: PropTypes.number.isRequired,
  expandTab: PropTypes.element,
};

BigBoard.defaultProps = {
  expandTab: null,
};

export default BigBoard;
