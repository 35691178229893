import { useMemo } from 'react';
import { useDynamicTagOptionsForCSVTemplate } from '__components/DownloadButton/useDynamicTagOptionsForCSVTemplate';
import { CustomObjectLike, FieldLike } from '__pages/SmartConnectors/types';
import { getFieldsForUpload } from '__components/Modals/UploadCSVData/utilities';
import DownloadSCVTemplateButton from '__components/DownloadButton/DownloadSCVTemplateButton';
import { useTranslation } from 'react-i18next';

type DownloadTemplateButtonProps = {
  fields: FieldLike;
  customObject: CustomObjectLike;
};

export const DownloadTemplateButton = ({
  customObject,
  fields,
}: DownloadTemplateButtonProps) => {
  const { t } = useTranslation();
  const { dynamicTagsWithOptions, loadingDynamictagsOptions } =
    useDynamicTagOptionsForCSVTemplate(fields, customObject);

  const orderedFields = useMemo(() => {
    const [defaultFields, customFields] = getFieldsForUpload(
      fields,
      dynamicTagsWithOptions
    );
    return [...defaultFields, ...customFields];
  }, [fields, dynamicTagsWithOptions]);

  return (
    <DownloadSCVTemplateButton
      fields={orderedFields}
      text={t('Download Template')}
      customObject={customObject}
      includeRow
      disabled={loadingDynamictagsOptions}
    />
  );
};
