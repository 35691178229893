import { BROADCAST } from 'queries/query-keys';
import { useQuery } from 'react-query';
import DashboardService from 'services/DashboardService';
import EmailInteractionStatsChart from 'pages/Dashboard/components/Charts/EmailInteractionStats/index';
import { ModalWrapper } from '../styles';
import useChartData from '../hooks/useChartData';
import { useTranslation } from 'react-i18next';

const ModalAdapter = ({ isLoading, message, onError }) => {
  const { t } = useTranslation();
  const { data, isLoading: summaryDataLoading } = useQuery(
    [...BROADCAST.STATS, message?.id],
    () =>
      DashboardService.getEmailInteractionStats(message?.id, {
        skipErrorBoundary: true,
      }),
    {
      retry: false,
      onError,
      enabled: Boolean(message?.id),
    }
  );

  const { lineData, tooltipLabels, stages, smartSendStages } =
    useChartData(data);

  const title = message?.subject ?? message?.name ?? '';

  return (
    <ModalWrapper>
      <EmailInteractionStatsChart
        summaryDataLoading={summaryDataLoading || isLoading}
        currentEmail={{
          ...message,
          title: `${t('Email')}${title ? ` - ${title}` : ''}`,
        }}
        stages={stages}
        smartSendStages={smartSendStages}
        id={message?.id}
        lineData={lineData}
        tooltipLabels={tooltipLabels}
        legendColumns={3}
        emailNameKey="title"
      />
    </ModalWrapper>
  );
};

export default ModalAdapter;
