import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton } from '../../../app/colors';
import { gutters } from '../../../app/spacing';
import Icon from '../../Kizen/Icon';

const ColorlessIcon = (props) => <Icon color={null} {...props} />;

export default styled(ColorlessIcon)`
  display: inline-flex;
  align-items: center;
  height: auto;
  width: auto;
  color: ${colorsButton.iconGray.default};
  ${({ start, end }) => {
    if (!start && !end) {
      return css``;
    }
    const side = start ? 'right' : 'left';
    return css`
      margin-${side}: ${gutters.spacing(2)}px;
    `;
  }}
  ${({ variant, icon }) => {
    const sizedDimension = icon === 'false' ? 'height' : 'width';
    const otherDimension = sizedDimension === 'height' ? 'width' : 'height';
    const widenPx = variant === 'underline' ? 0 : 2;
    const size = gutters.spacing(2, widenPx);
    return css`
      && svg {
        position: relative;
        color: inherit;
        ${sizedDimension}: ${size}px;
        ${otherDimension}: auto;
      }
    `;
  }}
`;
