import KizenTypography from 'app/kizentypo';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import useHeightMeasurement from 'hooks/useHeightMeasurement';
import OptionsList from '../OptionsList';
import {
  ColumnSearchContainer,
  ConfigurationArea,
  ConfigurationPanel,
  ConfigurationPanelBody,
  ConfigurationPanelHeader,
} from './styles';
import { useMemo } from 'react';
import { dropzoneIs } from 'components/DragAndDropLayout/helpers';

const VIRTUAL_HEADER_OFFSET = 61; // 59 for the header, 2 for the border

const TwoColumnContainer = ({
  leftColumnHeaderProps,
  leftHeaderText = '',
  rightHeaderText = '',
  searchable = true,
  searchPlaceholder = '',
  searchTerm,
  onChangeSearchTerm,
  leftColumnProps,
  leftColumnBodyProps,
  rightColumnHeaderProps,
  rightColumnBodyProps,
  rightColumnProps,
  renderOption,
  renderLeftEmpty,
  renderRightEmpty,
  weight = 'bold',
  disabledDropZoneText,
  minHeight,
  testId,
  noTopMargin = false,
  fullHieght = false,
}) => {
  const { ref: leftColumnRef, height } = useHeightMeasurement();

  const isRightOverallDropZone = useMemo(() => {
    return dropzoneIs(rightColumnProps.dropZone, {
      position: 'overall',
      sectionId: rightColumnProps.id,
    });
  }, [rightColumnProps]);

  const isLeftOverallDropZone = useMemo(() => {
    return dropzoneIs(leftColumnProps.dropZone, {
      position: 'overall',
      sectionId: leftColumnProps.id,
    });
  }, [leftColumnProps]);

  return (
    <ConfigurationArea
      data-qa={testId}
      className={testId}
      noTopMargin={noTopMargin}
      fullHieght={fullHieght}
    >
      <ConfigurationPanel
        ref={leftColumnRef}
        data-qa="left-column"
        highlighted={isLeftOverallDropZone}
      >
        <ConfigurationPanelHeader {...leftColumnHeaderProps}>
          <KizenTypography weight={weight}>{leftHeaderText}</KizenTypography>
          {searchable ? (
            <ColumnSearchContainer>
              <PageSearchInput
                value={searchTerm}
                onChange={onChangeSearchTerm}
                placeholder={searchPlaceholder}
              />
            </ColumnSearchContainer>
          ) : null}
        </ConfigurationPanelHeader>
        <OptionsList
          {...leftColumnProps}
          {...leftColumnBodyProps}
          // TODO (keegandonley) - this builder only works when the left column is a virtual list. In the future
          // this should be modified so it is configurable.
          virtualHeight={height - VIRTUAL_HEADER_OFFSET}
          virtual
          renderOption={renderOption}
          renderEmpty={renderLeftEmpty}
        />
      </ConfigurationPanel>
      <ConfigurationPanel
        data-qa="right-column"
        highlighted={isRightOverallDropZone}
      >
        <ConfigurationPanelHeader {...rightColumnHeaderProps}>
          <KizenTypography weight={weight}>{rightHeaderText}</KizenTypography>
        </ConfigurationPanelHeader>
        <ConfigurationPanelBody
          bottom
          minHeight={minHeight}
          {...rightColumnBodyProps}
        >
          <OptionsList
            {...rightColumnProps}
            renderOption={renderOption}
            renderEmpty={renderRightEmpty}
            disabledDropZoneText={disabledDropZoneText}
          />
        </ConfigurationPanelBody>
      </ConfigurationPanel>
    </ConfigurationArea>
  );
};

export default TwoColumnContainer;
