import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import { getStatusName } from './helpers';
import { EXPORT_IMPORT_SOURCES } from './constants';

const paddingSize = {
  small: '20px',
};
const columnSize = {
  spacer: '20px',
  status: '100px',
};

export const useColumns = (action) => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        id: 'spacer-1',
        headCell: <SizedCell width={columnSize.spacer} />,
        cell: <SizedCell width={columnSize.spacer} />,
      },
      {
        id:
          action === EXPORT_IMPORT_SOURCES.EXPORT
            ? 'export_file'
            : 'imported_file',
        headCell: (
          <SortableHeadCell
            label={t('File name')}
            padding={paddingSize.small}
            hasSorting={false}
          />
        ),
        cell: <SizedCell padding={paddingSize.small} />,
        format: (value, data) => (
          <TextEllipsisWithTooltip
            type={value?.url ? 'anchor' : undefined}
            href={value?.url || undefined}
          >
            {value?.name || t('Started {{started_at}}', data)}
          </TextEllipsisWithTooltip>
        ),
      },
      {
        id: 'status',
        headCell: (
          <SortableHeadCell
            label={t('Status')}
            width={columnSize.status}
            padding={paddingSize.small}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell width={columnSize.status} padding={paddingSize.small} />
        ),
        format: (value, data) => (
          <TextEllipsisWithTooltip
            type={data.failure_report?.url ? 'anchor' : undefined}
            href={data.failure_report?.url || undefined}
          >
            {getStatusName(value, t)}
          </TextEllipsisWithTooltip>
        ),
      },
    ];
  }, [action, t]);
};
