import { Button } from '@kizen/kds/Button';
import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';
import Input from '__components/Kizen/Input';
import Validation from '__components/Inputs/Validation';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useFlashTransition } from 'hooks/useFlashState';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import useModal from '__components/Modals/useModal';
import PermissionSettings from '__components/Sharing/PermissionSettings';
import Switch from '__components/Kizen/Switch';

const getDuplicatedName = (t: TFunction, name?: string) => {
  if (name) {
    return `${name} (${t('copy')})`;
  }

  return '';
};

export const RecordLayoutModal = ({
  onConfirm,
  newRecordError,
  setNewRecordError,
  duplicateData,
  ...modalProps
}: any) => {
  const { t } = useTranslation();
  const [name, setName] = useState(() =>
    getDuplicatedName(t, duplicateData?.name)
  );
  const [permissions, setPermissions] = useState<any>({});
  const [isActive, setIsActive] = useState<boolean>(
    duplicateData?.active ?? true
  );
  const nameInputRef = useRef<HTMLInputElement>(null);

  const [message, showMessage, flashErrorMessage] = useFlashTransition();
  const [unsavedChangesModalProps, , unsavedChangesModal] = useModal();

  const handleConfirm = useCallback(async () => {
    unsavedChangesModal.hide();
    const shouldClose = await onConfirm?.({
      name,
      order: duplicateData?.order,
      layout: duplicateData?.layout,
      permissions,
      active: isActive,
    });

    if (shouldClose.success) {
      modalProps.onHide();
    }
  }, [
    onConfirm,
    name,
    modalProps,
    duplicateData,
    unsavedChangesModal,
    permissions,
    isActive,
  ]);

  useEffect(() => {
    if (newRecordError?.name) {
      flashErrorMessage(newRecordError?.name);
    }
  }, [newRecordError?.name, flashErrorMessage]);

  const changeName = useCallback(
    (name: string) => {
      setNewRecordError(undefined);
      setName(name);
    },
    [setName, setNewRecordError]
  );

  const interceptedOnHide = useCallback(() => {
    if (name) {
      unsavedChangesModal.show();
    } else {
      modalProps.onHide();
    }
  }, [unsavedChangesModal, modalProps, name]);

  return (
    <>
      <BasicModal
        heading={
          duplicateData ? t('Duplicate Record Layout') : t('Add Record Layout')
        }
        {...modalProps}
        onHide={interceptedOnHide}
        onConfirm={handleConfirm}
        disabled={!name}
      >
        <Typography>{t('Record Layout Name')}</Typography>
        <Spacer mode="horizontal" size={15} />
        <Input
          type="text"
          placeholder={t('Enter Name')}
          value={name}
          onChange={changeName}
          error={newRecordError?.name}
          ref={nameInputRef}
        />
        {showMessage ? <div className="w-full h-[50px]" /> : null}
        <Validation
          message={message}
          showMessage={showMessage}
          target={nameInputRef.current}
        />
        <Spacer mode="horizontal" size={20} />
        <div className="flex items-center">
          <Typography>{t('Active?')}</Typography>
          <Spacer mode="vertical" size={10} />
          <Switch
            removeMargin
            checked={isActive}
            onChange={(ev) => {
              setIsActive(ev.target.checked);
            }}
          />
        </div>
        <PermissionSettings
          title={t('Sharing Settings')}
          onChange={setPermissions}
          existing={duplicateData?.sharingSettings}
          small
          infoBubble={t(
            'Matching any of the criteria below will grant the team member access to view this record layout.'
          )}
        />
      </BasicModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        rightBtn={<Button color="warning">{t('Discard Changes')}</Button>}
        rightBtnClickHandler={modalProps.onHide}
        defaultLeftBtnHandler={() => {
          handleConfirm();
        }}
        leftBtn={<Button />}
        defaultLeftBtnText={t('Save Changes')}
        actionButton={false}
        {...unsavedChangesModalProps}
      >
        {t(
          'You have unsaved changes. Would you like to save them or discard them before continuing?'
        )}
      </ConfirmationModal>
    </>
  );
};
