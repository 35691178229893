import { useContext, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { fadeIn } from 'app/animations';
import { grayScale, shadow } from 'app/colors';
import { borderRadii, gutters, hideScrollbarCss } from 'app/spacing';
import { fontWeights, KizenTypography } from 'app/typography';
import {
  ExpandableMonthCalendarCell,
  MonthCalendarCellHeader,
  isCellInLastRow,
} from 'components/Calendar';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { WEEKDAYS_SHORT } from 'utility/datetime';
import BroadcastCalendarContext from './BroadcastCalendarContext';
import CalendarEventPill from './CalendarEventPill';
import { useScheduleBroadcast } from './utils';
import { useTranslation } from 'react-i18next';
import { ScheduleBroadcastCalendarButton } from './ScheduleBroadcastCalendarButton';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';
import { BROADCAST_FORMDATA } from '../ScheduleBroadcastModal/constants';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { StyledGradient } from './styles';

const EVENT_PILL_HEIGHT = 26; // 21 + 5 margin bottom
const COLLAPSED_HEADER_HEIGHT = 24;
const EXPANDED_HEADER_HEIGHT = 57;
const MAX_EVENTS_EXPANDED = 10; // show scrollbar if more than 10 events & expanded
const TRANSITION_DURATION = 300;
const WIDTH_EXPANSION = 60;
const headerIconColor = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

const EventsContainer = styled.div`
  height: 100%;
  ${({ expanded, rowHeight, expansion }) =>
    expanded
      ? css`
          max-height: ${rowHeight +
          expansion[1] -
          EXPANDED_HEADER_HEIGHT +
          10}px;
          padding-bottom: ${gutters.spacing(2)}px;
        `
      : css`
          max-height: ${rowHeight - COLLAPSED_HEADER_HEIGHT}px;
          padding-bottom: ${gutters.spacing()}px;
        `}
`;

const EventsScrollContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  overflow: auto;
  height: 99%;
  width: calc(100% - 9px);
  flex-shrink: 0;
  padding-left: ${gutters.spacing()}px;

  ${({ expanded }) =>
    expanded &&
    css`
      padding-left: ${gutters.spacing(2)}px;
    `}

  > div {
    width: 100%;
  }
`;

const EventsScrollContainerWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const MonthCellHeader = styled(MonthCalendarCellHeader)`
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  ${({ expanded }) =>
    expanded &&
    css`
      opacity: 0;
      transition: opacity ${TRANSITION_DURATION}ms;
    `}
`;

const SelectableDateCell = styled(ExpandableMonthCalendarCell)`
  ${({ expanded }) =>
    expanded &&
    css`
      ${shadow}
      border-radius: ${borderRadii.small};
    `}
`;

const ExpandedMonthCellHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${gutters.spacing(2)}px;
  animation: ${fadeIn} ${TRANSITION_DURATION}ms ease-in;
`;

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: ${gutters.spacing(2)}px;
  top: ${gutters.spacing(2)}px;
`;

const ExpandedDayOfMonth = styled(KizenTypography)`
  font-weight: ${fontWeights.bold};
  margin-top: ${gutters.spacing()}px;
`;

const buildMonthCell =
  (
    onCellClick,
    openStatisticsModal,
    openBroadcastModal,
    canScheduleBroadcasts,
    loading
  ) =>
  ({ date, calendarMonth, calendarYear, rowHeight, index, total, ...rest }) => {
    const { t } = useTranslation();
    const { broadcastLookup, isCalendarModalOpen } = useContext(
      BroadcastCalendarContext
    );
    const [selected, setSelected] = useState(false);
    const [transitionComplete, setTransitionComplete] = useState(false);
    const events = broadcastLookup[date.toDateString()] || [];
    const eventPills = events.map((b) => (
      <CalendarEventPill
        key={b.id}
        broadcast={b}
        date={b[BROADCAST_FORMDATA.rescheduleAt] || b[BROADCAST_FORMDATA.date]}
        name={b[BROADCAST_FORMDATA.name]}
        type={b[BROADCAST_FORMDATA.type]}
        openBroadcastModal={openBroadcastModal}
        openStatisticsModal={openStatisticsModal}
      />
    ));
    const isLastRow = isCellInLastRow(index, total);
    const scrollable = selected
      ? eventPills.length > MAX_EVENTS_EXPANDED
      : eventPills.length * EVENT_PILL_HEIGHT + COLLAPSED_HEADER_HEIGHT >
        rowHeight;
    const heightExpansion =
      EVENT_PILL_HEIGHT * Math.min(MAX_EVENTS_EXPANDED, eventPills.length) +
      EXPANDED_HEADER_HEIGHT +
      gutters.spacing() -
      rowHeight;
    const expansion = [
      WIDTH_EXPANSION,
      Math.max(WIDTH_EXPANSION / 2, heightExpansion),
    ];
    const gradientColor =
      date.getMonth() === calendarMonth ? grayScale.white : grayScale.light;

    useEffect(() => {
      const listener = () => {
        if (selected && !isCalendarModalOpen) {
          setSelected(false);
        }
      };
      window.addEventListener('click', listener);
      return () => window.removeEventListener('click', listener);
    }, [selected, isCalendarModalOpen]);

    const { show, onMouseLeave, onMouseMove, onClick } = useScheduleBroadcast(
      onCellClick,
      date
    );
    const scrollbarRef = useRef();
    const [refFn, scrollRef] = useSyncSizes(
      scrollbarRef,
      '.ContentForSyncHeight',
      'height'
    );
    useSyncScroll(useRef([scrollRef, scrollbarRef]), {
      vertical: true,
    });
    // specifying scrollable as a dependency is necessary to get the gradient to appear on initial render
    const scrolled = useEndsOfScroll(scrollRef, [
      events,
      scrollable,
      selected,
      expansion,
    ]);

    const open = (ev) => {
      ev.stopPropagation(); // container date cell opens modal on click - stop that handler from being called
      setSelected(true);
      setTimeout(() => setTransitionComplete(true), TRANSITION_DURATION);
    };

    const close = (ev) => {
      ev.stopPropagation();
      setSelected(false);
      setTransitionComplete(false);
      onMouseLeave();
    };

    return (
      <SelectableDateCell
        index={index}
        total={total}
        expanded={selected}
        expansion={expansion}
        translation={[-WIDTH_EXPANSION / 2, -WIDTH_EXPANSION / 2]}
        date={date}
        calendarMonth={calendarMonth}
        calendarYear={calendarYear}
        rowHeight={rowHeight}
        pinBottom={isLastRow}
        {...rest}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      >
        {canScheduleBroadcasts && !selected ? (
          <ScheduleBroadcastCalendarButton
            view={CALENDAR_VIEW_OPTIONS.month}
            show={show}
            onClick={onClick}
          />
        ) : null}
        {(!selected || !transitionComplete) && (
          <MonthCellHeader
            date={date}
            calendarMonth={calendarMonth}
            expanded={selected}
          >
            {scrollable && (
              <IconButton
                sizing="dense"
                title={t('Expand')}
                color={headerIconColor}
                disabled={loading}
                onClick={open}
              >
                <IconSizing size="10px">
                  <Icon icon="external-link" />
                </IconSizing>
              </IconButton>
            )}
          </MonthCellHeader>
        )}
        {selected && transitionComplete && (
          <ExpandedMonthCellHeader expanded={selected}>
            <StyledCloseButton
              sizing="dense"
              color={headerIconColor}
              title={t('Close')}
            >
              <IconSizing size="12px">
                <Icon icon="close-cross" onClick={close} />
              </IconSizing>
            </StyledCloseButton>
            <KizenTypography>
              {WEEKDAYS_SHORT[date.getDay()].toUpperCase()}
            </KizenTypography>
            <ExpandedDayOfMonth type="subheader">
              {date.getDate()}
            </ExpandedDayOfMonth>
          </ExpandedMonthCellHeader>
        )}
        <EventsContainer
          expanded={selected}
          rowHeight={rowHeight}
          expansion={expansion}
        >
          <EventsScrollContainerWrapper>
            <EventsScrollContainer
              ref={refFn}
              scrolled={scrolled}
              gradient={<StyledGradient color={gradientColor} />}
              top={scrollable}
              bottom={scrollable}
              expanded={selected}
            >
              <div className="ContentForSyncHeight">{eventPills}</div>
            </EventsScrollContainer>
            <ScrollBarDetached
              ref={scrollbarRef}
              direction="vertical"
              scrollClassName="ContentForSyncHeight"
            />
          </EventsScrollContainerWrapper>
        </EventsContainer>
      </SelectableDateCell>
    );
  };

export default buildMonthCell;
