import { useCallback, useMemo, useState } from 'react';
import { PageSizing } from 'components/Layout/PageContentWidth';
import Cols from 'components/Layout/Cols';
import TextInput from 'components/Inputs/TextInput';
import { useTranslation } from 'react-i18next';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import Switch from 'components/Kizen/Switch';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import SwitchControl from 'components/Wizards/CustomObject/components/SwitchControl';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import useModal from 'components/Modals/useModal';
import BuilderCard from '../../components/BuilderCard';
import Section from '../../components/Section';
import Row from '../../components/Row';
import { getObjectType, PIPELINE_CO } from '../../utilities';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { ConfirmModalWithInput } from '../../components/ConfirmModalWithInput';
import { useCustomObjectWizard } from 'components/Wizards/CustomObject/CustomObjectWizardContext';
import { PreReleaseFeature } from 'hooks/usePreReleaseFeatures';
import { StyledTemplateButton, StyledTemplateButtonWrapper } from './styles';
import { EditNameTemplateModal } from './EditNameTemplateModal';

const StyledButtonGroupImageButton = styled(ButtonGroupImageButton)`
  &:hover {
    border-color: ${colorsButton.blue.hover};
    color: ${colorsButton.blue.hover};
  }
`;
export default function GeneralSettings({
  label,
  updateStepField,
  objectContainsWorkflow,
  formData,
  errors,
  validationFormProps,
  ...props
}) {
  const { validationProps, handleInputChange } = validationFormProps;
  const { isCreateMode, isContact } = useCustomObjectWizard();
  const { t } = useTranslation();
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const [confirmationModalProps, , confirmationModal] = useModal();
  const [modalProps, , modal] = useModal();
  const [confirmType, setConfirmType] = useState(null);
  const [nameTemplateData, setNameTemplateData] = useState({
    content: formData.entityDisplayNamePatternText,
    htmlContent: formData.entityDisplayNamePatternHtml,
  });

  const confirmationManager = useMemo(
    () => ({
      trackEntityValue: {
        message: t(
          'This will disable entity value tracking on your records, but keep your data if you choose to reactivate. Pipeline dashlets with $ values will no longer function.'
        ),
        onConfirm: () => {
          confirmationModal.hide();
          updateStepField('trackEntityValue', false);
        },
      },
      defaultOnActivities: {
        message: t(
          'This will hide the custom object association with submitted activities. You will no longer be able to assign these entity records to scheduled or logged activities.'
        ),
        onConfirm: () => {
          confirmationModal.hide();
          updateStepField('defaultOnActivities', false);
        },
      },
      allowOnForms: {
        message: t(
          "This will disable this object's entities from displaying on public forms/surveys. Forms/Surveys with this field will have no options to choose from."
        ),
        onConfirm: () => {
          confirmationModal.hide();
          updateStepField('allowOnForms', false);
        },
      },
    }),
    [t, updateStepField, confirmationModal]
  );

  // modal handlers
  const handleConfirmationConfirm = useCallback(() => {
    modal.hide();
    updateStepField('allowOnForms', !formData?.allowOnForms);
  }, [modal, updateStepField, formData?.allowOnForms]);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'object_name' },
      {
        id: 'entity_name',
      },
      { id: 'object_contains_workflow' },
      { id: 'default_on_activities' },
      { id: 'track_entity_value' },
      { id: 'allow_on_forms' },
      { id: 'has_commerce_data' },
    ],
    {},
    () => true
  );

  const onToggleChange = useCallback(
    (e, type) => {
      if (isCreateMode) {
        updateStepField(type, e.target.checked);
      } else {
        if (e.target.checked) {
          updateStepField(type, true);
          return;
        }
        e.preventDefault();
        setConfirmType(type);
        confirmationModal.show();
      }
    },
    [confirmationModal, isCreateMode, updateStepField]
  );

  const [templateModalProps, templateButtonProps] = useModal({
    handleSubmit: setNameTemplateData,
  });

  return (
    <PageSizing>
      <KeyBoardContext.Provider value={{ assignFieldHandle }}>
        <BuilderCard {...props} label={label}>
          <Section>
            {isCreateMode ? (
              <Row label={t('Choose Creation Type')}>
                <Cols columns={4} gutter="20px">
                  <StyledButtonGroupImageButton
                    selected={true}
                    option={{ label: t('Start from blank').toUpperCase() }}
                  />
                  <StyledButtonGroupImageButton
                    selected={false}
                    option={{ label: t('Start from template').toUpperCase() }}
                    onClick={() => openInNewTab('https://templates.kizen.com')}
                  />
                </Cols>
              </Row>
            ) : null}
            <Row>
              <Cols columns={2} gutter="20px">
                <TextInput
                  label={t('Object Name')}
                  onChange={(value, ev, err) => {
                    handleInputChange('objectName', value, err);
                  }}
                  placeholder={t('Support Pipeline, Apartments, etc.')}
                  inModal
                  disabled={isContact}
                  value={formData.objectName}
                  labelInfo={t('What will show on the toolbar.')}
                  {...getKeyListenersProps('object_name')}
                  {...getDataQAForInput('object_name')}
                  {...validationProps('objectName')}
                  required
                />
                <TextInput
                  label={t('Entity Name')}
                  onChange={(value, ev, err) => {
                    handleInputChange('entityName', value, err);
                  }}
                  placeholder={t('Ticket, Unit, etc.')}
                  inModal
                  disabled={isContact}
                  value={formData.entityName}
                  labelInfo={t(
                    'Also known as Record Name. Often singular version of Object Name.'
                  )}
                  {...getKeyListenersProps('entity_name')}
                  {...getDataQAForInput('entity_name')}
                  {...validationProps('entityName')}
                  required
                />
              </Cols>
            </Row>
            {isCreateMode ? null : (
              <Row>
                <Cols columns={2} gutter="20px">
                  <TextInput
                    label={t('Object API Name')}
                    inModal
                    disabled
                    value={formData.name}
                  />
                  <TextInput
                    label={t('Object ID')}
                    inModal
                    disabled
                    value={formData.id}
                  />
                </Cols>
              </Row>
            )}
            <Row>
              <Cols columns={2} gutter="20px">
                <Cols columns={2} gutter="20px">
                  {!isContact ? (
                    <SwitchControl
                      label={t('Contains Workflow')}
                      labelInfo={t(
                        'Toggle this setting on if your entities will be moving through a workflow with open and closed stages. Examples include Sales Pipelines, Support Ticketing, and Project Management.'
                      )}
                    >
                      <Switch
                        disabled={!isCreateMode}
                        checked={formData.objectType === PIPELINE_CO}
                        onChange={(e) => {
                          if (!isCreateMode) return;
                          return updateStepField(
                            'objectType',
                            getObjectType(e.target.checked)
                          );
                        }}
                        {...getKeyListenersProps('object_contains_workflow')}
                        {...getDataQAForInput('object_contains_workflow')}
                      />
                    </SwitchControl>
                  ) : null}
                  <SwitchControl
                    label={t('Enable Quick Filters')}
                    labelInfo={t(
                      'Quick filters enable a second form of less-detailed filtering on records, chart groups, and board views that are pre-defined for ease of use.'
                    )}
                  >
                    <Switch
                      checked={formData.quickFilteringEnabled}
                      onChange={(e) =>
                        updateStepField(
                          'quickFilteringEnabled',
                          e.target.checked
                        )
                      }
                      {...getKeyListenersProps('quick_filtering_enabled')}
                      {...getDataQAForInput('quick_filtering_enabled')}
                    />
                  </SwitchControl>
                </Cols>
                {isCreateMode || isContact ? null : (
                  <Cols columns={2} gutter="20px">
                    <TextInput
                      label={t('Display Name Template')}
                      inModal
                      disabled
                      readOnly
                      value={nameTemplateData.content}
                    />
                    <StyledTemplateButtonWrapper>
                      <StyledTemplateButton
                        variant="text"
                        color="blue"
                        {...templateButtonProps}
                      >
                        {t('Edit Name Template')}
                      </StyledTemplateButton>
                    </StyledTemplateButtonWrapper>
                  </Cols>
                )}
              </Cols>
            </Row>
          </Section>
          <Section label={t('Choose Where to Include the Object')}>
            <Row>
              <Cols columns={4} gutter="20px">
                <SwitchControl
                  label={t('Enable Activities')}
                  labelInfo={t(
                    'Associate this object with logged or scheduled activities.'
                  )}
                >
                  <Switch
                    checked={formData?.defaultOnActivities || false}
                    onChange={(e) => onToggleChange(e, 'defaultOnActivities')}
                    {...getKeyListenersProps('default_on_activities')}
                    {...getDataQAForInput('default_on_activities')}
                    disabled={isContact}
                  />
                </SwitchControl>
                {!isContact ? (
                  <>
                    <SwitchControl label={t('Track Entity $ Value')}>
                      <Switch
                        checked={formData?.trackEntityValue || false}
                        onChange={(e) => onToggleChange(e, 'trackEntityValue')}
                        {...getKeyListenersProps('track_entity_value')}
                        {...getDataQAForInput('track_entity_value')}
                      />
                    </SwitchControl>
                    <PreReleaseFeature>
                      <SwitchControl
                        label={t('Allow as Field on Public Forms/Surveys')}
                        labelInfo={t(
                          'Toggle this setting on to allow entity record fields to display on public forms. This should not be used with sensitive data that should not be available to the public.'
                        )}
                      >
                        <Switch
                          checked={formData?.allowOnForms || false}
                          onChange={(e) => {
                            e.preventDefault();
                            if (!e.target.checked) {
                              setConfirmType('allowOnForms');
                              confirmationModal.show();
                            } else {
                              modal.show();
                            }
                          }}
                          {...getKeyListenersProps('allow_on_forms')}
                          {...getDataQAForInput('allow_on_forms')}
                        />
                      </SwitchControl>
                    </PreReleaseFeature>
                  </>
                ) : null}
              </Cols>
            </Row>
          </Section>
        </BuilderCard>
        <ConfirmModalWithInput
          className={`no-drag`}
          heading={t('Please Confirm Setting')}
          buttonText={t('Confirm')}
          defaultLeftBtnText={t('Cancel')}
          actionBtnColor="green"
          {...modalProps}
          onConfirm={handleConfirmationConfirm}
          message={t(
            'This setting will allow entity records to display on public forms. Please confirm this object has no sensitive data (PII, etc.) that should not be available to the public. Please type “{{name}}” below to confirm.',
            {
              name: formData.objectName || 'ALLOW',
            }
          )}
          unblockName={formData.objectName || 'ALLOW'}
        />
        <ConfirmationModal
          className={`no-drag`}
          heading={t('Please Confirm Setting')}
          buttonText={t('Confirm')}
          defaultLeftBtnText={t('Cancel')}
          actionBtnColor="green"
          {...confirmationModalProps}
          {...confirmationManager[confirmType]}
        >
          {confirmationManager[confirmType]?.message}
        </ConfirmationModal>
        {templateModalProps.show ? (
          <EditNameTemplateModal
            {...templateModalProps}
            data={nameTemplateData}
            customObject={formData}
          />
        ) : null}
      </KeyBoardContext.Provider>
    </PageSizing>
  );
}
