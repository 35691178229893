import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal from 'components/Modals/presets/BasicModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import useModal from 'components/Modals/useModal';
import {
  TableBlockWithHeader,
  TimeLineWrapper,
  ExplanationEvent,
} from '../styles';
import Actions from '../Actions';
import CommentSection from '../CommentSection';
import Header from '../Header';
import RelationshipDetailsModal from 'components/Modals/RelationshipDetailsModal';
import LongTextEllipsis from 'components/Timeline2/EventBlocks/LongTextEllipsis';
import SupportLink from 'components/SupportLink';
import { TableArea } from '../TableArea';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

export default function BasicBlock({
  children,
  comments = EMPTY_ARRAY,
  icon,
  isEditable,
  isEmpty = false,
  isDeletable = false,
  onComment,
  onDelete,
  timestamp,
  title,
  event,
  commentOpenDefault = false,
  index,
  mobileOverride = false,
}) {
  const { t } = useTranslation();
  const [editModalProps, , editModal] = useModal();
  const [deleteModalProps, , deleteModal] = useModal({
    handleSubmit: onDelete,
  });
  const [relationModalProps, , relationModal] = useModal();
  const [isCommenting, setIsCommenting] = useState(commentOpenDefault);

  const getExplanation = (key, t) => {
    // all future reasons must be added to this map-object with translations
    const explanationMap = {
      suppression_list_reason: SupportLink({
        prefix: t(
          `The email status has been updated since this contact's email address is on Kizen's system-wide suppression list. Please contact`
        ),
        postfix: t('if you feel this is in error.'),
      }),
      'Condition error': t('Condition error'),
    };
    return explanationMap[key] || null;
  };
  return (
    <>
      <TimeLineWrapper
        isDisplayingComments={isCommenting}
        data-qa={event.typeName}
        data-qa-index={index}
      >
        <TableBlockWithHeader
          data-qa={event.id}
          data-event-id={event.id}
          isExplanation={event.data?.explanation?.length > 0}
        >
          <Header
            icon={icon}
            isEmpty={isEmpty}
            timestamp={timestamp}
            onClickDetails={relationModal.show}
            event={event}
            mobileOverride={mobileOverride}
          >
            {title}
          </Header>
          {event?.data?.explanation?.length > 0
            ? event.data.explanation.map((explanation) => (
                <ExplanationEvent
                  key={explanation.key}
                  marginBottom={!!children}
                >
                  <LongTextEllipsis>
                    {getExplanation(explanation.key, t)}
                  </LongTextEllipsis>
                </ExplanationEvent>
              ))
            : null}
          <TableArea event={event}>
            {/* TableArea uses a "render prop" pattern https://react.dev/reference/react/cloneElement#passing-data-with-a-render-prop  */}
            {typeof children === 'function' ? children : () => children}
          </TableArea>
          <Actions
            isCommenting={isCommenting}
            isDeletable={isDeletable}
            isEditable={isEditable}
            onClickComment={() =>
              setIsCommenting((prevCommenting) => !prevCommenting)
            }
            commentsCount={comments?.length || 0}
            onClickDelete={deleteModal.show}
            onClickEdit={editModal.show}
          />
        </TableBlockWithHeader>
        <CommentSection
          comments={comments}
          isCommenting={isCommenting}
          onOpenComments={setIsCommenting}
          onSubmit={onComment}
          eventId={event.id}
        />
      </TimeLineWrapper>
      <BasicModal heading="Edit Form" buttonText="Save" {...editModalProps}>
        Timeline Edit Form
      </BasicModal>
      <ConfirmDeletionModal {...deleteModalProps}>
        This will permanently delete the Timeline Entry.
      </ConfirmDeletionModal>
      {relationModal.showing ? (
        <RelationshipDetailsModal event={event} {...relationModalProps} />
      ) : null}
    </>
  );
}
