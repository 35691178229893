import { createElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { useAddNewNode } from '../useAddNewNode';
import {
  DEFAULT_FORM_DIVIDER_PROPS,
  DEFAULT_EMAIL_DIVIDER_PROPS,
} from './props';

const CreateDivider = ({ defaultProps }) => {
  const addNewNode = useAddNewNode();
  const dividerItem = useSelector((s) => getCreateItemByType(s, 'Divider'));
  useEffect(() => {
    if (dividerItem) {
      addNewNode({ ...dividerItem, ...defaultProps });
    }
  }, [dividerItem, defaultProps, addNewNode]);

  return null;
};

export const EmailDividerCreator = createElement(CreateDivider, {
  defaultProps: DEFAULT_EMAIL_DIVIDER_PROPS,
});

export const FormDividerCreator = createElement(CreateDivider, {
  defaultProps: DEFAULT_FORM_DIVIDER_PROPS,
});
