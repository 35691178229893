import { createContext, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toastVariant, useToast } from '__components/ToastProvider';
import { getToastConfig } from 'pages/ContactDetail/toastConfig';
import Automation2Service from '__services/Automation2Service';

type RecordLayoutStartAutomationCtx = {
  startAutomation: (automationId: string) => void;
};

type RecordLayoutStartAutomationContextProps = {
  children: any;
  entityId: string;
};

const context = createContext<RecordLayoutStartAutomationCtx>({
  startAutomation: () => {},
});

export const useRecordLayoutStartAutomationContext = () => {
  return useContext(context);
};

export const RecordLayoutStartAutomationContext = (
  props: RecordLayoutStartAutomationContextProps
) => {
  const { children, entityId } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [showToast] = useToast();

  const handleStartAutomation = useCallback(
    async (automationId: string) => {
      const toast = getToastConfig(t, history);

      try {
        await Automation2Service.start(
          { automationId, recordId: entityId },
          { skipErrorBoundary: true }
        );

        showToast(toast.automation.success);
      } catch (error: any) {
        if (error.response.status === 403) {
          showToast({
            message: t(
              'You do not have permission to start this automation. Please contact your Kizen administrator.'
            ),
            variant: toastVariant.FAILURE,
          });
        } else if (error.response.status === 404) {
          showToast({
            message: t('Automation was deleted'),
            variant: toastVariant.FAILURE,
          });
        } else if (
          error.response.status === 400 &&
          error.response.data[0] === 'automation must be active'
        ) {
          showToast({
            message: t('Automation is inactive and cannot be started'),
            variant: toastVariant.FAILURE,
          });
        } else {
          showToast(toast.automation.error);
        }
      }
    },
    [entityId, showToast, history, t]
  );

  return (
    <context.Provider value={{ startAutomation: handleStartAutomation }}>
      {children}
    </context.Provider>
  );
};
