import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Select from 'components/Inputs/Select';
import BasicModal from 'components/Modals/presets/BasicModal';

const InputWrapper = styled.div`
  margin-bottom: ${gutters.spacing(3)}px;
`;

const StyledSelect = styled(Select)`
  flex: 1;
`;

const defaultState = { object: null, field: null };
export const MultiSourceMergeModal = ({
  show,
  onConfirm,
  onHide,
  multipleMergeFields,
}) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      multipleMergeFields.map(({ label, fields }) => ({
        value: label,
        label,
        fields: fields.map(({ label, value, items: options }) => ({
          label,
          value,
          options: options.map(({ key: value, label, relationship }) => ({
            value,
            label,
            relationship,
          })),
        })),
      })),
    [multipleMergeFields]
  );
  const [state, setState] = useState(defaultState);
  const disabled = !state.field;
  const showFields = state.object;

  const handleUpdateObject = useCallback((evt) => {
    setState((previous) => ({
      ...previous,
      object: evt,
      field: null,
    }));
  }, []);

  const handleUpdateField = useCallback((evt) => {
    setState((previous) => ({
      ...previous,
      field: evt,
    }));
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm({ objectname: state.object.label, ...state.field });
  }, [state, onConfirm]);

  return (
    <BasicModal
      show={show}
      enforceFocus={false}
      onConfirm={handleConfirm}
      onHide={onHide}
      heading={t('Merge Field')}
      buttonText={t('Insert')}
      defaultLeftBtnText={null}
      disabled={disabled}
      data-qa="multi-source-merge-modal"
    >
      <InputWrapper>
        <StyledSelect
          value={state.object}
          label={t('Choose Object')}
          placeholder={t('Choose Object')}
          options={options}
          onChange={handleUpdateObject}
          inModal
          menuInline
        />
      </InputWrapper>
      {showFields ? (
        <InputWrapper>
          <StyledSelect
            value={state.field}
            label={t('Choose Field')}
            placeholder={t('Choose Field')}
            options={state.object.fields}
            onChange={handleUpdateField}
            inModal
            menuInline
          />
        </InputWrapper>
      ) : null}
    </BasicModal>
  );
};
