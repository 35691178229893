import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import Loader from 'components/Kizen/Loader';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';

export const AddButton = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${grayScale.medium};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  &:hover {
    border-color: ${colorsButton.blue.hover};
    &&& > button > i {
      color: ${colorsButton.blue.hover};
    }
  }
  cursor: pointer;

  ${({ view }) =>
    view === CALENDAR_VIEW_OPTIONS.day
      ? css`
          width: 25px;
          height: 30px;
          left: -1px;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 0 15px 15px 0;
          &:hover {
            border-left-color: ${grayScale.medium};
          }
        `
      : css`
          width: 30px;
          height: 25px;
          top: -1px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 0 0 15px 15px;
          &:hover {
            border-top-color: ${grayScale.medium};
          }
        `}
`;

export const StyledGradient = styled(Gradient)`
  z-index: 1;
`;

export const StyledBroadcastLoader = styled(Loader)`
  margin-top: calc(-10% + 34px);
  position: absolute;
  z-index: 1;
`;
