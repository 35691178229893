/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/display-name */
import React from 'react';
import * as PropTypes from 'prop-types';
import Notice from 'components/Kizen/Notice';
import Button from 'components/Button';
import DomainTextInput, {
  useDomainValidation,
} from 'components/Inputs/TextInput/presets/Domain';
import { Col } from 'react-bootstrap';
import KizenTypography from 'app/kizentypo';
import { colorsPrimary } from 'app/colors';
import { useTranslation } from 'react-i18next';
import { StyledRow as Row, ActionsContainer, StyledFormError } from '../styles';

import {
  StyledTable as Table,
  TableBody,
  TableRow,
  TableHeader,
  TableHeaderCell,
  TableCell,
} from './styles';

// Default to "normal" domain e.g. example.com
const DEFAULT_SUBDOMAIN_SEGMENTS = 3;

export const SUBDOMAIN_STEPS = {
  SUBDOMAIN_FORM: 'SUBDOMAIN_FORM',
  EMAIL_WITH_SSL_CERTIFICATE: 'EMAIL_WITH_SSL_CERTIFICATE',
  CONFIRM_SSL_CERTIFICATE: 'CONFIRM_SSL_CERTIFICATE',
  UPDATE_DNS_RECORD: 'UPDATE_DNS_RECORD',
  COMPLETED: 'COMPLETED',
};

const validateRequired = (t) => {
  const validate = {
    full: (value) => {
      if (!value) {
        return t('This field is required');
      }

      return null;
    },
  };
  return validate;
};

const SubdomainSteps = ({
  step,
  values,
  domainName,
  onFieldSet,
  onSubmit,
  onCreateDistribution,
  onDeleteDomain,
  onChangeStep,
  errorMessage,
}) => {
  const isDomainValid = useDomainValidation(values.name, {
    minDomainSegments: DEFAULT_SUBDOMAIN_SEGMENTS,
  });
  const isValid = values.name && isDomainValid;

  const handleSubmit = () => {
    if (isValid) {
      onSubmit();
    }
  };

  const { t } = useTranslation();

  const subdomainData = {
    [SUBDOMAIN_STEPS.SUBDOMAIN_FORM]: {
      content: () => (
        <>
          <Row>
            <Col>
              <Notice as="p">
                {t(
                  'You can use your own domain to host your store, forms, surveys, and more. If your domain is mydomain.com, enter a subdomain like store.mydomain.com. If you’d like to use a root domain instead of a subdomain, please contact Kizen support.'
                )}
              </Notice>
            </Col>
          </Row>
          <Row>
            <Col>
              <DomainTextInput
                label={t('Subdomain Name')}
                placeholder={t('store.mydomain.com')}
                value={values.name}
                onChange={(value) => onFieldSet('name', value)}
                required
                minDomainSegments={3} // value must be subdomain of domain provided by user
                validate={validateRequired(t)}
              />
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Button
                disabled={!isValid}
                onClick={handleSubmit}
                style={{ margin: 0, marginTop: 3 }}
              >
                {t('ADD SUBDOMAIN')}
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    [SUBDOMAIN_STEPS.EMAIL_WITH_SSL_CERTIFICATE]: {
      content: () => (
        <>
          <Row>
            <Col>
              <Notice>
                <KizenTypography>
                  {t(
                    'You will receive an email from no-reply@certificates.amazon.com. Please open this email, click the Amazon Certificate Approvals link, and click the “I Approve” button. This step is required for issuing an SSL Certificate for your domain to secure your website.'
                  )}
                </KizenTypography>
                <KizenTypography>
                  {t(
                    "The email will be sent to the addresses listed in your domain's WHOIS information and additionally domainName. Make sure you can receive emails to at least one of these!",
                    {
                      entityName: domainName,
                    }
                  )}
                </KizenTypography>
              </Notice>
              <KizenTypography style={{ marginTop: '20px' }}>
                {t(
                  'If you do not receive the email, please add no-reply@certificates.amazon.com to your address book, delete your subdomain using the Delete Subdomain button below, and restart the process.'
                )}
              </KizenTypography>
              <br />
              <KizenTypography>
                {t(
                  'After receiving the email and approving the certificate please click Next Step button below.'
                )}
              </KizenTypography>
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ActionsContainer>
                <Button onClick={onCreateDistribution} color="green">
                  {t('NEXT STEP')}
                </Button>
                <Button onClick={onDeleteDomain} color="red">
                  {t('DELETE SUBDOMAIN')}
                </Button>
              </ActionsContainer>
            </Col>
          </Row>
        </>
      ),
    },
    [SUBDOMAIN_STEPS.CONFIRM_SSL_CERTIFICATE]: {
      content: () => (
        <>
          <Row>
            <Col>
              <Notice backgroundColor={colorsPrimary.orange.light}>
                <KizenTypography>
                  {t(
                    'You have not yet approved the SSL Certificate for your domain.'
                  )}
                </KizenTypography>
                <KizenTypography>
                  {t(
                    "The email was sent to the addresses listed in your domain's WHOIS information and additionally dmainName Make sure you can receive emails to at least one of these!",
                    {
                      entityName: domainName,
                    }
                  )}
                </KizenTypography>
              </Notice>
              <KizenTypography style={{ marginTop: '20px' }}>
                {t(
                  'If you do not receive the email, please add no-reply@certificates.amazon.com to your address book, delete your subdomain using the Delete Subdomain button below, and restart the process.'
                )}
              </KizenTypography>
              <br />
              <KizenTypography>
                {t(
                  'After receiving the email and approving the certificate please click Next Step button below.'
                )}
              </KizenTypography>
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ActionsContainer>
                <Button
                  onClick={() =>
                    onChangeStep(SUBDOMAIN_STEPS.EMAIL_WITH_SSL_CERTIFICATE)
                  }
                  color="blue"
                >
                  {t('BACK')}
                </Button>
                <Button onClick={onDeleteDomain} color="red">
                  {t('DELETE SUBDOMAIN')}
                </Button>
              </ActionsContainer>
            </Col>
          </Row>
        </>
      ),
    },
    [SUBDOMAIN_STEPS.UPDATE_DNS_RECORD]: {
      content: () => (
        <>
          <Row>
            <Col>
              <Notice>
                <KizenTypography>{t('Update Your DNS')}</KizenTypography>
                <KizenTypography>
                  {t(
                    'Go to the DNS provider you use to manage values and add the following DNS record. This change will not affect your email or other services.',
                    {
                      entityName: values.name,
                    }
                  )}
                </KizenTypography>
                <KizenTypography>
                  {t(
                    'Common DNS providers include Godaddy, Rackspace, Amazon Route 53, and NameCheap.'
                  )}
                </KizenTypography>
                <KizenTypography>
                  {t(
                    'It usually takes less than 30 minutes for a subdomain to go live, however it can sometimes take up to 24 hours. Please contact Kizen support if your DNS hasn’t taken effect after 24 hours.'
                  )}
                </KizenTypography>
              </Notice>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{t('Type')}</TableHeaderCell>
                    <TableHeaderCell>{t('Hostname')}</TableHeaderCell>
                    <TableHeaderCell>{t('Value')}</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('CNAME')}</TableCell>
                    <TableCell>{values.domain}</TableCell>
                    <TableCell>{values.cname_target}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <KizenTypography>
                `${t('Note')}: $
                {t(
                  'Your DNS provider may require only the subdomain prefix in the "Host/Hostname" value.'
                )}
                `
              </KizenTypography>
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ActionsContainer>
                <Button onClick={onDeleteDomain} color="red">
                  {t('DELETE SUBDOMAIN')}
                </Button>
              </ActionsContainer>
            </Col>
          </Row>
        </>
      ),
    },
    [SUBDOMAIN_STEPS.COMPLETED]: {
      content: () => (
        <>
          <Row>
            <Col>
              <Notice as="p">
                {t('Success! Your subdomain has been verified.')}
              </Notice>
            </Col>
          </Row>
          <Row>
            <Col>
              <DomainTextInput
                label={t('Verified Subdomain for Hosted Content')}
                value={values.name}
                disabled
              />
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ActionsContainer>
                <Button
                  onClick={onDeleteDomain}
                  color="lightRed"
                  style={{ margin: 0, marginTop: 3 }}
                >
                  {t('DELETE DOMAIN')}
                </Button>
              </ActionsContainer>
            </Col>
          </Row>
        </>
      ),
    },
  };

  return subdomainData[step].content();
};

SubdomainSteps.propTypes = {
  step: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  onFieldSet: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  onDeleteDomain: PropTypes.func.isRequired,
};

export default SubdomainSteps;
