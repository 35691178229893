import { TraySubsection } from '../components/TraySection';
import { ImageSelector, SelectedFileLabel } from '../components/ImageSelector';

export const SelectImageSubsection = ({
  onImageChange,
  onImageDelete,
  onImageRemove,
  imageLibraryOpen = false,
  fileId = null,
  fileName = '',
  ...rest
}) => (
  <TraySubsection header={<SelectedFileLabel fileName={fileName} />} {...rest}>
    <ImageSelector
      onImageChange={onImageChange}
      onImageDelete={onImageDelete}
      onImageRemove={onImageRemove}
      imageLibraryOpen={imageLibraryOpen}
      fileId={fileId}
      fileName={fileName}
    />
  </TraySubsection>
);
