import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import { fontSizes } from 'app/typography';

export const StyledFooter = styled.div`
  border-top: 1px solid ${grayScale.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding-left: ${gutters.spacing(2, 2)}px;
  padding-right: ${gutters.spacing(2, 2)}px;
  > .apply-button {
    height: unset;
    font-size: ${fontSizes.small};
    line-height: 1;
  }
  column-gap: 20px;
`;
