import { toastVariant } from 'components/ToastProvider';

export const CO_OBJECT_TYPE_STANDARD = 'standard';
export const CO_OBJECT_TYPE_PIPELINE = 'pipeline';

export const CO_LAYOUT_FIELDS = 'fields';
export const CO_LAYOUT_RELATED_PIPELINES = 'related_pipelines';
export const CO_LAYOUT_LEAD_SOURCES = 'lead_sources';
export const CO_LAYOUT_TIMELINE = 'timeline';
export const CO_LAYOUT_TEAM_ACTIVITIES = 'team_and_activities';
export const CO_LAYOUT_RELATED_OBJECT_FIELDS = 'related_object_fields';

export const CO_RELATION_TYPE_PRIMARY = 'primary';

export const CO_RELATION_TYPE_PRIMARY_REVERSE = 'primary_for';
export const CO_RELATION_TYPE_ONE_TO_ONE = 'one_to_one';

export const isPipelineObject = (obj) =>
  obj.objectType === CO_OBJECT_TYPE_PIPELINE ||
  obj.fetchUrl === CO_OBJECT_TYPE_PIPELINE;

export const isStandardObject = (obj) =>
  obj.objectType === CO_OBJECT_TYPE_STANDARD ||
  obj.fetchUrl === CO_OBJECT_TYPE_STANDARD;

export const isClientObject = (obj) => obj?.name === 'client_client';

export const isDefaultOnActivities = (obj) => obj.defaultOnActivities;

export const getToastConfig = (t, isNew) => {
  return {
    success: {
      variant: toastVariant.SUCCESS,
      message: isNew
        ? t(`The Custom Object was successfully created.`)
        : t(`The Custom Object was successfully updated.`),
    },
    error: {
      variant: toastVariant.FAILURE,
      message: `${
        isNew
          ? t('The Custom Object was not created.')
          : t('The Custom Object was not updated.')
      } ${t('Please try again or contact Kizen support.')}`,
    },
  };
};

export const isPrimaryField = ({ relation }) =>
  relation.relationType === CO_RELATION_TYPE_PRIMARY ||
  relation.relationType === CO_RELATION_TYPE_PRIMARY_REVERSE;
