import { useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';

/**
 * Helper hook to initiate a prefetch, but a bit later as to not block other requests
 * Automatically runs once after a short delay.
 *
 * Looks like:
 *
 * usePrefetchQuery((queryClient) => queryClient.prefetch(...))
 *
 *
 * Docs:
 * https://react-query.tanstack.com/guides/prefetching
 */
export const usePrefetchQuery = (prefetchFn, delay = 100) => {
  const queryClient = useQueryClient();

  const delayRef = useRef(delay);
  delayRef.current = delay;

  const fnRef = useRef();
  fnRef.current = () => prefetchFn(queryClient);

  // We only run one time, even if vals change on us
  useEffect(() => {
    const timeout = setTimeout(() => fnRef.current(), delayRef.current);
    // Cancel, if we are unmounted before we expire
    return () => clearTimeout(timeout);
  }, []);
};

/***
 * Cleans query by key when unMounted
 *
 * Docs:
 * https://react-query.tanstack.com/guides/scroll-restoration
 *
 * @param key: any[]
 */
export const useUnmountQueryReset = (key = []) => {
  const queryClient = useQueryClient();
  const keyRef = useRef(key);
  keyRef.current = key;

  useEffect(() => {
    return () => queryClient.resetQueries([...keyRef.current]);
  }, [queryClient]);
};
