import { Extension } from '@tiptap/core';

export const Highlight = Extension.create({
  name: 'highlight',

  defaultOptions: {
    types: ['textStyle'],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          highlight: {
            parseHTML: (element) => element.style.backgroundColor,
            renderHTML: (attributes) => {
              if (!attributes.highlight) {
                return {};
              }

              return {
                style: `background-color: ${attributes.highlight}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setHighlight:
        (highlight) =>
        ({ chain, tr }) => {
          const { attrs } =
            tr.storedMarks?.find((m) => m.type.name === 'textStyle') ?? {};
          return chain()
            .setMark('textStyle', { ...attrs, highlight })
            .run();
        },
      unsetHighlight:
        () =>
        ({ chain, tr }) => {
          const { attrs } =
            tr.storedMarks?.find((m) => m.type.name === 'textStyle') ?? {};
          return chain()
            .setMark('textStyle', { ...attrs, highlight: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
