import {
  CustomIconSettings,
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faPenNib } from '@fortawesome/pro-light-svg-icons/faPenNib';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faClipboard } from '@fortawesome/pro-light-svg-icons/faClipboard';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare';
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faThumbtack } from '@fortawesome/pro-light-svg-icons/faThumbtack';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faCity } from '@fortawesome/pro-light-svg-icons/faCity';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faCompass } from '@fortawesome/pro-light-svg-icons/faCompass';
import { faAddressBook } from '@fortawesome/pro-light-svg-icons/faAddressBook';
import { faBusinessTime } from '@fortawesome/pro-light-svg-icons/faBusinessTime';
import { faComputerClassic } from '@fortawesome/pro-light-svg-icons/faComputerClassic';
import { faPenFancy } from '@fortawesome/pro-light-svg-icons/faPenFancy';
import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons/faBadgeCheck';
import { faMugSaucer } from '@fortawesome/pro-light-svg-icons/faMugSaucer';
import { faLandmark } from '@fortawesome/pro-light-svg-icons/faLandmark';
import { faChartSimple } from '@fortawesome/pro-light-svg-icons/faChartSimple';
import { faEnvelopes } from '@fortawesome/pro-light-svg-icons/faEnvelopes';
import { faDiagramVenn } from '@fortawesome/pro-light-svg-icons/faDiagramVenn';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faPhoneVolume } from '@fortawesome/pro-light-svg-icons/faPhoneVolume';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faNetworkWired } from '@fortawesome/pro-light-svg-icons/faNetworkWired';
import { faAddressCard } from '@fortawesome/pro-light-svg-icons/faAddressCard';
import { faTags } from '@fortawesome/pro-light-svg-icons/faTags';
import { faRegistered } from '@fortawesome/pro-light-svg-icons/faRegistered';
import { faSignature } from '@fortawesome/pro-light-svg-icons/faSignature';
import { faPhoneOffice } from '@fortawesome/pro-light-svg-icons/faPhoneOffice';
import { faProjector } from '@fortawesome/pro-light-svg-icons/faProjector';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';
import { faMarker } from '@fortawesome/pro-light-svg-icons/faMarker';
import { faBadge } from '@fortawesome/pro-light-svg-icons/faBadge';
import { faWallet } from '@fortawesome/pro-light-svg-icons/faWallet';
import { faCopyright } from '@fortawesome/pro-light-svg-icons/faCopyright';
import { faBullseye } from '@fortawesome/pro-light-svg-icons/faBullseye';
import { faCalculator } from '@fortawesome/pro-light-svg-icons/faCalculator';
import { faCertificate } from '@fortawesome/pro-light-svg-icons/faCertificate';
import { faPercent } from '@fortawesome/pro-light-svg-icons/faPercent';
import { faEraser } from '@fortawesome/pro-light-svg-icons/faEraser';
import { faFileSpreadsheet } from '@fortawesome/pro-light-svg-icons/faFileSpreadsheet';
import { faGlasses } from '@fortawesome/pro-light-svg-icons/faGlasses';
import { faIndustry } from '@fortawesome/pro-light-svg-icons/faIndustry';
import { faVault } from '@fortawesome/pro-light-svg-icons/faVault';
import { faUserTieHairLong } from '@fortawesome/pro-light-svg-icons/faUserTieHairLong';
import { faUserTieHair } from '@fortawesome/pro-light-svg-icons/faUserTieHair';
import { faUserHairMullet } from '@fortawesome/pro-light-svg-icons/faUserHairMullet';
import { faTrademark } from '@fortawesome/pro-light-svg-icons/faTrademark';
import { faTimelineArrow } from '@fortawesome/pro-light-svg-icons/faTimelineArrow';
import { faTimeline } from '@fortawesome/pro-light-svg-icons/faTimeline';
import { faTableTree } from '@fortawesome/pro-light-svg-icons/faTableTree';
import { faTableRows } from '@fortawesome/pro-light-svg-icons/faTableRows';
import { faTablePivot } from '@fortawesome/pro-light-svg-icons/faTablePivot';
import { faTableLayout } from '@fortawesome/pro-light-svg-icons/faTableLayout';
import { faTableColumns } from '@fortawesome/pro-light-svg-icons/faTableColumns';
import { faStapler } from '@fortawesome/pro-light-svg-icons/faStapler';
import { faSquarePollVertical } from '@fortawesome/pro-light-svg-icons/faSquarePollVertical';
import { faSquarePollHorizontal } from '@fortawesome/pro-light-svg-icons/faSquarePollHorizontal';
import { faSquarePhoneFlip } from '@fortawesome/pro-light-svg-icons/faSquarePhoneFlip';
import { faSquarePhone } from '@fortawesome/pro-light-svg-icons/faSquarePhone';
import { faSquarePen } from '@fortawesome/pro-light-svg-icons/faSquarePen';
import { faSquareKanban } from '@fortawesome/pro-light-svg-icons/faSquareKanban';
import { faSquareEnvelope } from '@fortawesome/pro-light-svg-icons/faSquareEnvelope';
import { faSocks } from '@fortawesome/pro-light-svg-icons/faSocks';
import { faSlotMachine } from '@fortawesome/pro-light-svg-icons/faSlotMachine';
import { faSignatureSlash } from '@fortawesome/pro-light-svg-icons/faSignatureSlash';
import { faSignatureLock } from '@fortawesome/pro-light-svg-icons/faSignatureLock';
import { faShredder } from '@fortawesome/pro-light-svg-icons/faShredder';
import { faScissors } from '@fortawesome/pro-light-svg-icons/faScissors';
import { faScannerImage } from '@fortawesome/pro-light-svg-icons/faScannerImage';
import { faScaleUnbalancedFlip } from '@fortawesome/pro-light-svg-icons/faScaleUnbalancedFlip';
import { faScaleUnbalanced } from '@fortawesome/pro-light-svg-icons/faScaleUnbalanced';
import { faScaleBalanced } from '@fortawesome/pro-light-svg-icons/faScaleBalanced';
import { faRouter } from '@fortawesome/pro-light-svg-icons/faRouter';
import { faRectanglePro } from '@fortawesome/pro-light-svg-icons/faRectanglePro';
import { faPrintSlash } from '@fortawesome/pro-light-svg-icons/faPrintSlash';
import { faPrintMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faPrintMagnifyingGlass';
import { faPresentationScreen } from '@fortawesome/pro-light-svg-icons/faPresentationScreen';
import { faPodium } from '@fortawesome/pro-light-svg-icons/faPodium';
import { faPhoneSlash } from '@fortawesome/pro-light-svg-icons/faPhoneSlash';
import { faPhoneIntercom } from '@fortawesome/pro-light-svg-icons/faPhoneIntercom';
import { faPhoneFlip } from '@fortawesome/pro-light-svg-icons/faPhoneFlip';
import { faPersonChalkboard } from '@fortawesome/pro-light-svg-icons/faPersonChalkboard';
import { faPenNibSlash } from '@fortawesome/pro-light-svg-icons/faPenNibSlash';
import { faPenClip } from '@fortawesome/pro-light-svg-icons/faPenClip';
import { faPaste } from '@fortawesome/pro-light-svg-icons/faPaste';
import { faPaperclipVertical } from '@fortawesome/pro-light-svg-icons/faPaperclipVertical';
import { faNotebook } from '@fortawesome/pro-light-svg-icons/faNotebook';
import { faNoteSticky } from '@fortawesome/pro-light-svg-icons/faNoteSticky';
import { faMoneyCheckPen } from '@fortawesome/pro-light-svg-icons/faMoneyCheckPen';
import { faMoneyCheckDollarPen } from '@fortawesome/pro-light-svg-icons/faMoneyCheckDollarPen';
import { faMagnifyingGlassChart } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassChart';
import { faMagnifyingGlassArrowRight } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassArrowRight';
import { faListTree } from '@fortawesome/pro-light-svg-icons/faListTree';
import { faListTimeline } from '@fortawesome/pro-light-svg-icons/faListTimeline';
import { faListRadio } from '@fortawesome/pro-light-svg-icons/faListRadio';
import { faListDropdown } from '@fortawesome/pro-light-svg-icons/faListDropdown';
import { faListCheck } from '@fortawesome/pro-light-svg-icons/faListCheck';
import { faLaptopFile } from '@fortawesome/pro-light-svg-icons/faLaptopFile';
import { faLampDesk } from '@fortawesome/pro-light-svg-icons/faLampDesk';
import { faKeynote } from '@fortawesome/pro-light-svg-icons/faKeynote';
import { faIndustryWindows } from '@fortawesome/pro-light-svg-icons/faIndustryWindows';
import { faInboxes } from '@fortawesome/pro-light-svg-icons/faInboxes';
import { faInboxFull } from '@fortawesome/pro-light-svg-icons/faInboxFull';
import { faHouseLaptop } from '@fortawesome/pro-light-svg-icons/faHouseLaptop';
import { faHighlighter } from '@fortawesome/pro-light-svg-icons/faHighlighter';
import { faFolders } from '@fortawesome/pro-light-svg-icons/faFolders';
import { faFolderXmark } from '@fortawesome/pro-light-svg-icons/faFolderXmark';
import { faFolderTree } from '@fortawesome/pro-light-svg-icons/faFolderTree';
import { faFolderPlus } from '@fortawesome/pro-light-svg-icons/faFolderPlus';
import { faFolderMinus } from '@fortawesome/pro-light-svg-icons/faFolderMinus';
import { faFolderArrowUp } from '@fortawesome/pro-light-svg-icons/faFolderArrowUp';
import { faFolderArrowDown } from '@fortawesome/pro-light-svg-icons/faFolderArrowDown';
import { faFloppyDiskCircleXmark } from '@fortawesome/pro-light-svg-icons/faFloppyDiskCircleXmark';
import { faFloppyDiskCircleArrowRight } from '@fortawesome/pro-light-svg-icons/faFloppyDiskCircleArrowRight';
import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons/faFloppyDisk';
import { faFileUser } from '@fortawesome/pro-light-svg-icons/faFileUser';
import { faFileLines } from '@fortawesome/pro-light-svg-icons/faFileLines';
import { faFileCirclePlus } from '@fortawesome/pro-light-svg-icons/faFileCirclePlus';
import { faFileCircleInfo } from '@fortawesome/pro-light-svg-icons/faFileCircleInfo';
import { faFileChartPie } from '@fortawesome/pro-light-svg-icons/faFileChartPie';
import { faFileChartColumn } from '@fortawesome/pro-light-svg-icons/faFileChartColumn';
import { faFax } from '@fortawesome/pro-light-svg-icons/faFax';
import { faEnvelopeOpen } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpen';
import { faEnvelopeDot } from '@fortawesome/pro-light-svg-icons/faEnvelopeDot';
import { faEnvelopeCircleCheck } from '@fortawesome/pro-light-svg-icons/faEnvelopeCircleCheck';
import { faCoffeePot } from '@fortawesome/pro-light-svg-icons/faCoffeePot';
import { faCloudWord } from '@fortawesome/pro-light-svg-icons/faCloudWord';
import { faClipboardQuestion } from '@fortawesome/pro-light-svg-icons/faClipboardQuestion';
import { faChartUser } from '@fortawesome/pro-light-svg-icons/faChartUser';
import { faChartTreeMap } from '@fortawesome/pro-light-svg-icons/faChartTreeMap';
import { faChartSimpleHorizontal } from '@fortawesome/pro-light-svg-icons/faChartSimpleHorizontal';
import { faChartPyramid } from '@fortawesome/pro-light-svg-icons/faChartPyramid';
import { faChartPieSimple } from '@fortawesome/pro-light-svg-icons/faChartPieSimple';
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faChartLineUp } from '@fortawesome/pro-light-svg-icons/faChartLineUp';
import { faChartLineDown } from '@fortawesome/pro-light-svg-icons/faChartLineDown';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faCakeCandles } from '@fortawesome/pro-light-svg-icons/faCakeCandles';
import { faCabinetFiling } from '@fortawesome/pro-light-svg-icons/faCabinetFiling';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faBriefcaseBlank } from '@fortawesome/pro-light-svg-icons/faBriefcaseBlank';
import { faBriefcaseArrowRight } from '@fortawesome/pro-light-svg-icons/faBriefcaseArrowRight';
import { faBrainArrowCurvedRight } from '@fortawesome/pro-light-svg-icons/faBrainArrowCurvedRight';
import { faBoxesPacking } from '@fortawesome/pro-light-svg-icons/faBoxesPacking';
import { faBoxArchive } from '@fortawesome/pro-light-svg-icons/faBoxArchive';
import { faBookSection } from '@fortawesome/pro-light-svg-icons/faBookSection';
import { faBarsStaggered } from '@fortawesome/pro-light-svg-icons/faBarsStaggered';
import { faBarsProgress } from '@fortawesome/pro-light-svg-icons/faBarsProgress';
import { faBadgePercent } from '@fortawesome/pro-light-svg-icons/faBadgePercent';
import { faBadgeDollar } from '@fortawesome/pro-light-svg-icons/faBadgeDollar';
import { faArrowsToEye } from '@fortawesome/pro-light-svg-icons/faArrowsToEye';
import { faArrowsToDot } from '@fortawesome/pro-light-svg-icons/faArrowsToDot';
import { faArrowsSpin } from '@fortawesome/pro-light-svg-icons/faArrowsSpin';
import { faSparkles } from '@fortawesome/pro-light-svg-icons/faSparkles';
import { faMegaphone } from '@fortawesome/pro-light-svg-icons/faMegaphone';
import { faBlockQuestion } from '@fortawesome/pro-light-svg-icons/faBlockQuestion';
import { faLightbulbSlash } from '@fortawesome/pro-light-svg-icons/faLightbulbSlash';
import { faCommentsDollar } from '@fortawesome/pro-light-svg-icons/faCommentsDollar';
import { faBullseyePointer } from '@fortawesome/pro-light-svg-icons/faBullseyePointer';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb';
import { faUserGroupCrown } from '@fortawesome/pro-light-svg-icons/faUserGroupCrown';
import { faUserCrown } from '@fortawesome/pro-light-svg-icons/faUserCrown';
import { faSignalStreamSlash } from '@fortawesome/pro-light-svg-icons/faSignalStreamSlash';
import { faSignalStream } from '@fortawesome/pro-light-svg-icons/faSignalStream';
import { faRectangleAd } from '@fortawesome/pro-light-svg-icons/faRectangleAd';
import { faRankingStar } from '@fortawesome/pro-light-svg-icons/faRankingStar';
import { faPersonRays } from '@fortawesome/pro-light-svg-icons/faPersonRays';
import { faPeopleGroup } from '@fortawesome/pro-light-svg-icons/faPeopleGroup';
import { faMessagesDollar } from '@fortawesome/pro-light-svg-icons/faMessagesDollar';
import { faMessageDollar } from '@fortawesome/pro-light-svg-icons/faMessageDollar';
import { faMagnifyingGlassLocation } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassLocation';
import { faMagnifyingGlassDollar } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassDollar';
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons/faLightbulbOn';
import { faLightbulbExclamation } from '@fortawesome/pro-light-svg-icons/faLightbulbExclamation';
import { faLightbulbDollar } from '@fortawesome/pro-light-svg-icons/faLightbulbDollar';
import { faLightbulbCflOn } from '@fortawesome/pro-light-svg-icons/faLightbulbCflOn';
import { faLightbulbCfl } from '@fortawesome/pro-light-svg-icons/faLightbulbCfl';
import { faHundredPoints } from '@fortawesome/pro-light-svg-icons/faHundredPoints';
import { faGroupArrowsRotate } from '@fortawesome/pro-light-svg-icons/faGroupArrowsRotate';
import { faGiftCard } from '@fortawesome/pro-light-svg-icons/faGiftCard';
import { faFilterCircleDollar } from '@fortawesome/pro-light-svg-icons/faFilterCircleDollar';
import { faEnvelopesBulk } from '@fortawesome/pro-light-svg-icons/faEnvelopesBulk';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faEnvelopeOpenDollar } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenDollar';
import { faCommentDollar } from '@fortawesome/pro-light-svg-icons/faCommentDollar';
import { faChartMixed } from '@fortawesome/pro-light-svg-icons/faChartMixed';
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons/faBullseyeArrow';
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faCameraRetro } from '@fortawesome/pro-light-svg-icons/faCameraRetro';
import { faTruckFast } from '@fortawesome/pro-light-svg-icons/faTruckFast';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faCartShopping } from '@fortawesome/pro-light-svg-icons/faCartShopping';
import { faGift } from '@fortawesome/pro-light-svg-icons/faGift';
import { faBarcode } from '@fortawesome/pro-light-svg-icons/faBarcode';
import { faBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faCamera } from '@fortawesome/pro-light-svg-icons/faCamera';
import { faThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faThumbsDown } from '@fortawesome/pro-light-svg-icons/faThumbsDown';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faTruck } from '@fortawesome/pro-light-svg-icons/faTruck';
import { faBalloons } from '@fortawesome/pro-light-svg-icons/faBalloons';
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faShop } from '@fortawesome/pro-light-svg-icons/faShop';
import { faShirt } from '@fortawesome/pro-light-svg-icons/faShirt';
import { faBagShopping } from '@fortawesome/pro-light-svg-icons/faBagShopping';
import { faMoped } from '@fortawesome/pro-light-svg-icons/faMoped';
import { faCartPlus } from '@fortawesome/pro-light-svg-icons/faCartPlus';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faStore } from '@fortawesome/pro-light-svg-icons/faStore';
import { faCashRegister } from '@fortawesome/pro-light-svg-icons/faCashRegister';
import { faBoothCurtain } from '@fortawesome/pro-light-svg-icons/faBoothCurtain';
import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';
import { faReceipt } from '@fortawesome/pro-light-svg-icons/faReceipt';
import { faTruckContainer } from '@fortawesome/pro-light-svg-icons/faTruckContainer';
import { faTruckClock } from '@fortawesome/pro-light-svg-icons/faTruckClock';
import { faTruckBolt } from '@fortawesome/pro-light-svg-icons/faTruckBolt';
import { faTrophyStar } from '@fortawesome/pro-light-svg-icons/faTrophyStar';
import { faStoreSlash } from '@fortawesome/pro-light-svg-icons/faStoreSlash';
import { faStoreLock } from '@fortawesome/pro-light-svg-icons/faStoreLock';
import { faStarExclamation } from '@fortawesome/pro-light-svg-icons/faStarExclamation';
import { faShopSlash } from '@fortawesome/pro-light-svg-icons/faShopSlash';
import { faShopLock } from '@fortawesome/pro-light-svg-icons/faShopLock';
import { faRectangleBarcode } from '@fortawesome/pro-light-svg-icons/faRectangleBarcode';
import { faPersonBooth } from '@fortawesome/pro-light-svg-icons/faPersonBooth';
import { faNfcTrash } from '@fortawesome/pro-light-svg-icons/faNfcTrash';
import { faNfcSymbol } from '@fortawesome/pro-light-svg-icons/faNfcSymbol';
import { faNfcSlash } from '@fortawesome/pro-light-svg-icons/faNfcSlash';
import { faNfcSignal } from '@fortawesome/pro-light-svg-icons/faNfcSignal';
import { faNfcPen } from '@fortawesome/pro-light-svg-icons/faNfcPen';
import { faNfcMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faNfcMagnifyingGlass';
import { faNfcLock } from '@fortawesome/pro-light-svg-icons/faNfcLock';
import { faNfc } from '@fortawesome/pro-light-svg-icons/faNfc';
import { faMoneyCheckDollar } from '@fortawesome/pro-light-svg-icons/faMoneyCheckDollar';
import { faMoneyCheck } from '@fortawesome/pro-light-svg-icons/faMoneyCheck';
import { faMobileSignalOut } from '@fortawesome/pro-light-svg-icons/faMobileSignalOut';
import { faMobileSignal } from '@fortawesome/pro-light-svg-icons/faMobileSignal';
import { faHexagonVerticalNftSlanted } from '@fortawesome/pro-light-svg-icons/faHexagonVerticalNftSlanted';
import { faHexagonVerticalNft } from '@fortawesome/pro-light-svg-icons/faHexagonVerticalNft';
import { faHexagonImage } from '@fortawesome/pro-light-svg-icons/faHexagonImage';
import { faHandHoldingBox } from '@fortawesome/pro-light-svg-icons/faHandHoldingBox';
import { faGifts } from '@fortawesome/pro-light-svg-icons/faGifts';
import { faGem } from '@fortawesome/pro-light-svg-icons/faGem';
import { faCreditCardFront } from '@fortawesome/pro-light-svg-icons/faCreditCardFront';
import { faCreditCardBlank } from '@fortawesome/pro-light-svg-icons/faCreditCardBlank';
import { faCartXmark } from '@fortawesome/pro-light-svg-icons/faCartXmark';
import { faCartShoppingFast } from '@fortawesome/pro-light-svg-icons/faCartShoppingFast';
import { faCartMinus } from '@fortawesome/pro-light-svg-icons/faCartMinus';
import { faCartCircleXmark } from '@fortawesome/pro-light-svg-icons/faCartCircleXmark';
import { faCartCirclePlus } from '@fortawesome/pro-light-svg-icons/faCartCirclePlus';
import { faCartCircleExclamation } from '@fortawesome/pro-light-svg-icons/faCartCircleExclamation';
import { faCartCircleCheck } from '@fortawesome/pro-light-svg-icons/faCartCircleCheck';
import { faCartCircleArrowUp } from '@fortawesome/pro-light-svg-icons/faCartCircleArrowUp';
import { faCartCircleArrowDown } from '@fortawesome/pro-light-svg-icons/faCartCircleArrowDown';
import { faCartArrowUp } from '@fortawesome/pro-light-svg-icons/faCartArrowUp';
import { faCartArrowDown } from '@fortawesome/pro-light-svg-icons/faCartArrowDown';
import { faBoxOpenFull } from '@fortawesome/pro-light-svg-icons/faBoxOpenFull';
import { faBasketShoppingSimple } from '@fortawesome/pro-light-svg-icons/faBasketShoppingSimple';
import { faBasketShopping } from '@fortawesome/pro-light-svg-icons/faBasketShopping';
import { faBarcodeScan } from '@fortawesome/pro-light-svg-icons/faBarcodeScan';
import { faBarcodeRead } from '@fortawesome/pro-light-svg-icons/faBarcodeRead';
import { faBalloon } from '@fortawesome/pro-light-svg-icons/faBalloon';
import { faBagsShopping } from '@fortawesome/pro-light-svg-icons/faBagsShopping';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBrowser } from '@fortawesome/pro-regular-svg-icons/faBrowser';
import { faChartBar } from '@fortawesome/pro-regular-svg-icons/faChartBar';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faDatabase } from '@fortawesome/pro-regular-svg-icons/faDatabase';
import { Kizen } from '../custom/Kizen';
import { faAdd } from '@fortawesome/pro-light-svg-icons/faAdd';
import { faBookAlt } from '@fortawesome/pro-light-svg-icons/faBookAlt';
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments';
import { faArrowsToCircle } from '@fortawesome/pro-light-svg-icons/faArrowsToCircle';
import { FormEntity } from '../custom/FormEntity';

export const SEMANTIC_CATEGORY: SemanticCategory = 'custom';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  phone: faPhone,
  envelope: faEnvelope,
  'pen-nib': faPenNib,
  'calendar-days': faCalendarDays,
  paperclip: faPaperclip,
  file: faFile,
  clipboard: faClipboard,
  pen: faPen,
  tag: faTag,
  book: faBook,
  print: faPrint,
  'pen-to-square': faPenToSquare,
  folder: faFolder,
  'folder-open': faFolderOpen,
  thumbtack: faThumbtack,
  globe: faGlobe,
  city: faCity,
  briefcase: faBriefcase,
  compass: faCompass,
  'address-book': faAddressBook,
  'business-time': faBusinessTime,
  'computer-classic': faComputerClassic,
  'pen-fancy': faPenFancy,
  'badge-check': faBadgeCheck,
  'mug-saucer': faMugSaucer,
  landmark: faLandmark,
  'chart-simple': faChartSimple,
  envelopes: faEnvelopes,
  'diagram-venn': faDiagramVenn,
  calendar: faCalendar,
  pencil: faPencil,
  'phone-volume': faPhoneVolume,
  copy: faCopy,
  table: faTable,
  building: faBuilding,
  'network-wired': faNetworkWired,
  'address-card': faAddressCard,
  tags: faTags,
  registered: faRegistered,
  signature: faSignature,
  'phone-office': faPhoneOffice,
  projector: faProjector,
  sitemap: faSitemap,
  marker: faMarker,
  badge: faBadge,
  wallet: faWallet,
  copyright: faCopyright,
  bullseye: faBullseye,
  calculator: faCalculator,
  certificate: faCertificate,
  percent: faPercent,
  eraser: faEraser,
  'file-spreadsheet': faFileSpreadsheet,
  glasses: faGlasses,
  industry: faIndustry,
  vault: faVault,
  'user-tie-hair-long': faUserTieHairLong,
  'user-tie-hair': faUserTieHair,
  'user-hair-mullet': faUserHairMullet,
  trademark: faTrademark,
  'timeline-arrow': faTimelineArrow,
  timeline: faTimeline,
  'table-tree': faTableTree,
  'table-rows': faTableRows,
  'table-pivot': faTablePivot,
  'table-layout': faTableLayout,
  'table-columns': faTableColumns,
  stapler: faStapler,
  'square-poll-vertical': faSquarePollVertical,
  'square-poll-horizontal': faSquarePollHorizontal,
  'square-phone-flip': faSquarePhoneFlip,
  'square-phone': faSquarePhone,
  'square-pen': faSquarePen,
  'square-kanban': faSquareKanban,
  'square-envelope': faSquareEnvelope,
  socks: faSocks,
  'slot-machine': faSlotMachine,
  'signature-slash': faSignatureSlash,
  'signature-lock': faSignatureLock,
  shredder: faShredder,
  scissors: faScissors,
  'scanner-image': faScannerImage,
  'scale-unbalanced-flip': faScaleUnbalancedFlip,
  'scale-unbalanced': faScaleUnbalanced,
  'scale-balanced': faScaleBalanced,
  router: faRouter,
  'rectangle-pro': faRectanglePro,
  'print-slash': faPrintSlash,
  'print-magnifying-glass': faPrintMagnifyingGlass,
  'presentation-screen': faPresentationScreen,
  podium: faPodium,
  'phone-slash': faPhoneSlash,
  'phone-intercom': faPhoneIntercom,
  'phone-flip': faPhoneFlip,
  'person-chalkboard': faPersonChalkboard,
  'pen-nib-slash': faPenNibSlash,
  'pen-clip': faPenClip,
  paste: faPaste,
  'paperclip-vertical': faPaperclipVertical,
  notebook: faNotebook,
  'note-sticky': faNoteSticky,
  'money-check-pen': faMoneyCheckPen,
  'money-check-dollar-pen': faMoneyCheckDollarPen,
  'magnifying-glass-chart': faMagnifyingGlassChart,
  'magnifying-glass-arrow-right': faMagnifyingGlassArrowRight,
  'list-tree': faListTree,
  'list-timeline': faListTimeline,
  'list-radio': faListRadio,
  'list-dropdown': faListDropdown,
  'list-check': faListCheck,
  'laptop-file': faLaptopFile,
  'lamp-desk': faLampDesk,
  keynote: faKeynote,
  'industry-windows': faIndustryWindows,
  inboxes: faInboxes,
  'inbox-full': faInboxFull,
  'house-laptop': faHouseLaptop,
  highlighter: faHighlighter,
  folders: faFolders,
  'folder-xmark': faFolderXmark,
  'folder-tree': faFolderTree,
  'folder-plus': faFolderPlus,
  'folder-minus': faFolderMinus,
  'folder-arrow-up': faFolderArrowUp,
  'folder-arrow-down': faFolderArrowDown,
  'floppy-disk-circle-xmark': faFloppyDiskCircleXmark,
  'floppy-disk-circle-arrow-right': faFloppyDiskCircleArrowRight,
  'floppy-disk': faFloppyDisk,
  'file-user': faFileUser,
  'file-lines': faFileLines,
  'file-circle-plus': faFileCirclePlus,
  'file-circle-info': faFileCircleInfo,
  'file-chart-pie': faFileChartPie,
  'file-chart-column': faFileChartColumn,
  fax: faFax,
  'envelope-open': faEnvelopeOpen,
  'envelope-dot': faEnvelopeDot,
  'envelope-circle-check': faEnvelopeCircleCheck,
  'coffee-pot': faCoffeePot,
  'cloud-word': faCloudWord,
  'clipboard-question': faClipboardQuestion,
  'chart-user': faChartUser,
  'chart-tree-map': faChartTreeMap,
  'chart-simple-horizontal': faChartSimpleHorizontal,
  'chart-pyramid': faChartPyramid,
  'chart-pie-simple': faChartPieSimple,
  'chart-pie': faChartPie,
  'chart-line-up': faChartLineUp,
  'chart-line-down': faChartLineDown,
  'chart-line': faChartLine,
  'cake-candles': faCakeCandles,
  'cabinet-filing': faCabinetFiling,
  bullhorn: faBullhorn,
  'briefcase-blank': faBriefcaseBlank,
  'briefcase-arrow-right': faBriefcaseArrowRight,
  'brain-arrow-curved-right': faBrainArrowCurvedRight,
  'boxes-packing': faBoxesPacking,
  'box-archive': faBoxArchive,
  'book-section': faBookSection,
  'bars-staggered': faBarsStaggered,
  'bars-progress': faBarsProgress,
  'badge-percent': faBadgePercent,
  'badge-dollar': faBadgeDollar,
  'arrows-to-eye': faArrowsToEye,
  'arrows-to-dot': faArrowsToDot,
  'arrows-spin': faArrowsSpin,
  sparkles: faSparkles,
  megaphone: faMegaphone,
  'block-question': faBlockQuestion,
  'lightbulb-slash': faLightbulbSlash,
  'comments-dollar': faCommentsDollar,
  comments: faComments,
  'bullseye-pointer': faBullseyePointer,
  lightbulb: faLightbulb,
  'user-group-crown': faUserGroupCrown,
  'user-crown': faUserCrown,
  'signal-stream-slash': faSignalStreamSlash,
  'signal-stream': faSignalStream,
  'rectangle-ad': faRectangleAd,
  'ranking-star': faRankingStar,
  'person-rays': faPersonRays,
  'people-group': faPeopleGroup,
  'messages-dollar': faMessagesDollar,
  'message-dollar': faMessageDollar,
  'magnifying-glass-location': faMagnifyingGlassLocation,
  'magnifying-glass-dollar': faMagnifyingGlassDollar,
  'lightbulb-on': faLightbulbOn,
  'lightbulb-exclamation': faLightbulbExclamation,
  'lightbulb-dollar': faLightbulbDollar,
  'lightbulb-cfl-on': faLightbulbCflOn,
  'lightbulb-cfl': faLightbulbCfl,
  'hundred-points': faHundredPoints,
  'group-arrows-rotate': faGroupArrowsRotate,
  'gift-card': faGiftCard,
  'filter-circle-dollar': faFilterCircleDollar,
  'envelopes-bulk': faEnvelopesBulk,
  'envelope-open-text': faEnvelopeOpenText,
  'envelope-open-dollar': faEnvelopeOpenDollar,
  'comment-dollar': faCommentDollar,
  'chart-mixed': faChartMixed,
  'bullseye-arrow': faBullseyeArrow,
  star: faStar,
  heart: faHeart,
  'camera-retro': faCameraRetro,
  'truck-fast': faTruckFast,
  bell: faBell,
  'cart-shopping': faCartShopping,
  gift: faGift,
  barcode: faBarcode,
  bookmark: faBookmark,
  camera: faCamera,
  'thumbs-up': faThumbsUp,
  'thumbs-down': faThumbsDown,
  key: faKey,
  truck: faTruck,
  balloons: faBalloons,
  handshake: faHandshake,
  shop: faShop,
  shirt: faShirt,
  'bag-shopping': faBagShopping,
  moped: faMoped,
  'cart-plus': faCartPlus,
  'credit-card': faCreditCard,
  store: faStore,
  'cash-register': faCashRegister,
  'booth-curtain': faBoothCurtain,
  trophy: faTrophy,
  receipt: faReceipt,
  'truck-container': faTruckContainer,
  'truck-clock': faTruckClock,
  'truck-bolt': faTruckBolt,
  'trophy-star': faTrophyStar,
  'store-slash': faStoreSlash,
  'store-lock': faStoreLock,
  'star-exclamation': faStarExclamation,
  'shop-slash': faShopSlash,
  'shop-lock': faShopLock,
  'rectangle-barcode': faRectangleBarcode,
  'person-booth': faPersonBooth,
  'nfc-trash': faNfcTrash,
  'nfc-symbol': faNfcSymbol,
  'nfc-slash': faNfcSlash,
  'nfc-signal': faNfcSignal,
  'nfc-pen': faNfcPen,
  'nfc-magnifying-glass': faNfcMagnifyingGlass,
  'nfc-lock': faNfcLock,
  nfc: faNfc,
  'money-check-dollar': faMoneyCheckDollar,
  'money-check': faMoneyCheck,
  'mobile-signal-out': faMobileSignalOut,
  'mobile-signal': faMobileSignal,
  'hexagon-vertical-nft-slanted': faHexagonVerticalNftSlanted,
  'hexagon-vertical-nft': faHexagonVerticalNft,
  'hexagon-image': faHexagonImage,
  'hand-holding-box': faHandHoldingBox,
  gifts: faGifts,
  gem: faGem,
  'credit-card-front': faCreditCardFront,
  'credit-card-blank': faCreditCardBlank,
  'cart-xmark': faCartXmark,
  'cart-shopping-fast': faCartShoppingFast,
  'cart-minus': faCartMinus,
  'cart-circle-xmark': faCartCircleXmark,
  'cart-circle-plus': faCartCirclePlus,
  'cart-circle-exclamation': faCartCircleExclamation,
  'cart-circle-check': faCartCircleCheck,
  'cart-circle-arrow-up': faCartCircleArrowUp,
  'cart-circle-arrow-down': faCartCircleArrowDown,
  'cart-arrow-up': faCartArrowUp,
  'cart-arrow-down': faCartArrowDown,
  'box-open-full': faBoxOpenFull,
  'basket-shopping-simple': faBasketShoppingSimple,
  'basket-shopping': faBasketShopping,
  'barcode-scan': faBarcodeScan,
  'barcode-read': faBarcodeRead,
  balloon: faBalloon,
  'bags-shopping': faBagsShopping,
  bars: faBars,
  browser: faBrowser,
  'chart-bar': faChartBar,
  cog: faCog,
  database: faDatabase,
  'calendar-alt': faCalendarAlt,
  play: faPlay,
  'kizen-k': null,
  add: faAdd,
  'book-alt': faBookAlt,
  'form-entity': null,
  'arrows-to-circle': faArrowsToCircle,
};

export const labels: IconLabel = {
  phone: {
    title: (t) => '',
    description: (t) => t('Phone'),
  },
  envelope: {
    title: (t) => '',
    description: (t) => t('Envelope'),
  },
  'pen-nib': {
    title: (t) => '',
    description: (t) => 'Pen',
  },
  'calendar-days': {
    title: (t) => '',
    description: (t) => t('Calendar'),
  },
  paperclip: {
    title: (t) => '',
    description: (t) => t('Paper Clip'),
  },
  file: {
    title: (t) => '',
    description: (t) => t('File'),
  },
  clipboard: {
    title: (t) => '',
    description: (t) => t('Copy'),
  },
  pen: {
    title: (t) => '',
    description: (t) => t('Pen'),
  },
  tag: {
    title: (t) => '',
    description: (t) => t('Tag'),
  },
  book: {
    title: (t) => '',
    description: (t) => t('Book'),
  },
  print: {
    title: (t) => '',
    description: (t) => t('Print'),
  },
  'pen-to-square': {
    title: (t) => '',
    description: (t) => t('Pen To Square'),
  },
  folder: {
    title: (t) => '',
    description: (t) => t('Folder'),
  },
  'folder-open': {
    title: (t) => '',
    description: (t) => t('Open Folder'),
  },
  thumbtack: {
    title: (t) => '',
    description: (t) => t('Thumbtack'),
  },
  globe: {
    title: (t) => '',
    description: (t) => t('Globe'),
  },
  city: {
    title: (t) => '',
    description: (t) => t('City'),
  },
  briefcase: {
    title: (t) => '',
    description: (t) => t('Briefcase'),
  },
  compass: {
    title: (t) => '',
    description: (t) => t('Compass'),
  },
  'address-book': {
    title: (t) => '',
    description: (t) => t('Address Book'),
  },
  'business-time': {
    title: (t) => '',
    description: (t) => t('Business Time'),
  },
  'computer-classic': {
    title: (t) => '',
    description: (t) => t('Computer Classic'),
  },
  'pen-fancy': {
    title: (t) => '',
    description: (t) => t('Pen Fancy'),
  },
  'badge-check': {
    title: (t) => '',
    description: (t) => t('Badge Check'),
  },
  'mug-saucer': {
    title: (t) => '',
    description: (t) => t('Mug Saucer'),
  },
  landmark: {
    title: (t) => '',
    description: (t) => t('Landmark'),
  },
  'chart-simple': {
    title: (t) => '',
    description: (t) => t('Chart Simple'),
  },
  envelopes: {
    title: (t) => '',
    description: (t) => t('Envelopes'),
  },
  'diagram-venn': {
    title: (t) => '',
    description: (t) => t('Diagram Venn'),
  },
  calendar: {
    title: (t) => '',
    description: (t) => t('Calendar'),
  },
  pencil: {
    title: (t) => '',
    description: (t) => t('Pencil'),
  },
  'phone-volume': {
    title: (t) => '',
    description: (t) => t('Phone Volume'),
  },
  copy: {
    title: (t) => '',
    description: (t) => t('Copy'),
  },
  table: {
    title: (t) => '',
    description: (t) => t('Table'),
  },
  building: {
    title: (t) => '',
    description: (t) => t('Building'),
  },
  'network-wired': {
    title: (t) => '',
    description: (t) => t('Network Wired'),
  },
  'address-card': {
    title: (t) => '',
    description: (t) => t('Address Card'),
  },
  tags: {
    title: (t) => '',
    description: (t) => t('Tags'),
  },
  registered: {
    title: (t) => '',
    description: (t) => t('Registered'),
  },
  signature: {
    title: (t) => '',
    description: (t) => t('Signature'),
  },
  'phone-office': {
    title: (t) => '',
    description: (t) => t('Phone Office'),
  },
  projector: {
    title: (t) => '',
    description: (t) => t('Projector'),
  },
  sitemap: {
    title: (t) => '',
    description: (t) => t('Sitemap'),
  },
  marker: {
    title: (t) => '',
    description: (t) => t('Marker'),
  },
  badge: {
    title: (t) => '',
    description: (t) => t('Badge'),
  },
  wallet: {
    title: (t) => '',
    description: (t) => t('Wallet'),
  },
  copyright: {
    title: (t) => '',
    description: (t) => t('Copy Right'),
  },
  bullseye: {
    title: (t) => '',
    description: (t) => t('Bullseye'),
  },
  calculator: {
    title: (t) => '',
    description: (t) => t('Calculator'),
  },
  certificate: {
    title: (t) => '',
    description: (t) => t('Certificate'),
  },
  percent: {
    title: (t) => '',
    description: (t) => t('Percent'),
  },
  eraser: {
    title: (t) => '',
    description: (t) => t('Eraser'),
  },
  'file-spreadsheet': {
    title: (t) => '',
    description: (t) => t('File Spreadsheet'),
  },
  glasses: {
    title: (t) => '',
    description: (t) => t('Glasses'),
  },
  industry: {
    title: (t) => '',
    description: (t) => t('Industry'),
  },
  vault: {
    title: (t) => '',
    description: (t) => t('Vault'),
  },
  'user-tie-hair-long': {
    title: (t) => '',
    description: (t) => t('User Tie Hair Long'),
  },
  'user-tie-hair': {
    title: (t) => '',
    description: (t) => t('User Tie Hair'),
  },
  'user-hair-mullet': {
    title: (t) => '',
    description: (t) => t('User Hair Mullet'),
  },
  trademark: {
    title: (t) => '',
    description: (t) => t('Trademark'),
  },
  'timeline-arrow': {
    title: (t) => '',
    description: (t) => t('Timeline Arrow'),
  },
  timeline: {
    title: (t) => '',
    description: (t) => t('Timeline'),
  },
  'table-tree': {
    title: (t) => '',
    description: (t) => t('Table Tree'),
  },
  'table-rows': {
    title: (t) => '',
    description: (t) => t('Table Rows'),
  },
  'table-pivot': {
    title: (t) => '',
    description: (t) => t('Table Pivot'),
  },
  'table-layout': {
    title: (t) => '',
    description: (t) => t('Table Layout'),
  },
  'table-columns': {
    title: (t) => '',
    description: (t) => t('Table Columns'),
  },
  stapler: {
    title: (t) => '',
    description: (t) => t('Stapler'),
  },
  'square-poll-vertical': {
    title: (t) => '',
    description: (t) => t('Square Poll Vertical'),
  },
  'square-poll-horizontal': {
    title: (t) => '',
    description: (t) => t('Square Poll Horizontal'),
  },
  'square-phone-flip': {
    title: (t) => '',
    description: (t) => t('Square Phone Flip'),
  },
  'square-phone': {
    title: (t) => '',
    description: (t) => t('Square Phone'),
  },
  'square-pen': {
    title: (t) => '',
    description: (t) => t('Square Pen'),
  },
  'square-kanban': {
    title: (t) => '',
    description: (t) => t('Square Kanban'),
  },
  'square-envelope': {
    title: (t) => '',
    description: (t) => t('Square Envelope'),
  },
  socks: {
    title: (t) => '',
    description: (t) => t('Socks'),
  },
  'slot-machine': {
    title: (t) => '',
    description: (t) => t('Slot Machine'),
  },
  'signature-slash': {
    title: (t) => '',
    description: (t) => t('Signature Slash'),
  },
  'signature-lock': {
    title: (t) => '',
    description: (t) => t('Signature Lock'),
  },
  shredder: {
    title: (t) => '',
    description: (t) => t('Shredder'),
  },
  scissors: {
    title: (t) => '',
    description: (t) => t('Scissors'),
  },
  'scanner-image': {
    title: (t) => '',
    description: (t) => t('Scanner Image'),
  },
  'scale-unbalanced-flip': {
    title: (t) => '',
    description: (t) => t('Scale Unbalanced Flip'),
  },
  'scale-unbalanced': {
    title: (t) => '',
    description: (t) => t('Scale Unbalanced'),
  },
  'scale-balanced': {
    title: (t) => '',
    description: (t) => t('Scale Balanced'),
  },
  router: {
    title: (t) => '',
    description: (t) => t('Router'),
  },
  'rectangle-pro': {
    title: (t) => '',
    description: (t) => t('Rectangle Pro'),
  },
  'print-slash': {
    title: (t) => '',
    description: (t) => t('Print Slash'),
  },
  'print-magnifying-glass': {
    title: (t) => '',
    description: (t) => t('Print Magnifying Glass'),
  },
  'presentation-screen': {
    title: (t) => '',
    description: (t) => t('Presentation Screen'),
  },
  podium: {
    title: (t) => '',
    description: (t) => t('Podium'),
  },
  'phone-slash': {
    title: (t) => '',
    description: (t) => t('Phone Slash'),
  },
  'phone-intercom': {
    title: (t) => '',
    description: (t) => t('Phone Intercom'),
  },
  'phone-flip': {
    title: (t) => '',
    description: (t) => t('Phone Flip'),
  },
  'person-chalkboard': {
    title: (t) => '',
    description: (t) => t('Person Chalkboard'),
  },
  'pen-nib-slash': {
    title: (t) => '',
    description: (t) => t('Pen Nib Slash'),
  },
  'pen-clip': {
    title: (t) => '',
    description: (t) => t('Pen Clip'),
  },
  paste: {
    title: (t) => '',
    description: (t) => t('Paste'),
  },
  'paperclip-vertical': {
    title: (t) => '',
    description: (t) => t('Paperclip Vertical'),
  },
  notebook: {
    title: (t) => '',
    description: (t) => t('Notebook'),
  },
  'note-sticky': {
    title: (t) => '',
    description: (t) => t('Note Sticky'),
  },
  'money-check-pen': {
    title: (t) => '',
    description: (t) => t('Money Check Pen'),
  },
  'money-check-dollar-pen': {
    title: (t) => '',
    description: (t) => t('Money Check Dollar Pen'),
  },
  'magnifying-glass-chart': {
    title: (t) => '',
    description: (t) => t('Magnifying Glass Chart'),
  },
  'magnifying-glass-arrow-right': {
    title: (t) => '',
    description: (t) => t('Magnifying Glass Arrow Right'),
  },
  'list-tree': {
    title: (t) => '',
    description: (t) => t('List Tree'),
  },
  'list-timeline': {
    title: (t) => '',
    description: (t) => t('List Timeline'),
  },
  'list-radio': {
    title: (t) => '',
    description: (t) => t('List Radio'),
  },
  'list-dropdown': {
    title: (t) => '',
    description: (t) => t('List Dropdown'),
  },
  'list-check': {
    title: (t) => '',
    description: (t) => t('List Check'),
  },
  'laptop-file': {
    title: (t) => '',
    description: (t) => t('Laptop File'),
  },
  'lamp-desk': {
    title: (t) => '',
    description: (t) => t('Lamp Desk'),
  },
  keynote: {
    title: (t) => '',
    description: (t) => t('Keynote'),
  },
  'industry-windows': {
    title: (t) => '',
    description: (t) => t('Industry Windows'),
  },
  inboxes: {
    title: (t) => '',
    description: (t) => t('Inboxes'),
  },
  'inbox-full': {
    title: (t) => '',
    description: (t) => t('Inbox Full'),
  },
  'house-laptop': {
    title: (t) => '',
    description: (t) => t('House Laptop'),
  },
  highlighter: {
    title: (t) => '',
    description: (t) => t('Highlighter'),
  },
  folders: {
    title: (t) => '',
    description: (t) => t('Folders'),
  },
  'folder-xmark': {
    title: (t) => '',
    description: (t) => t('Folder Xmark'),
  },
  'folder-tree': {
    title: (t) => '',
    description: (t) => t('Folder Tree'),
  },
  'folder-plus': {
    title: (t) => '',
    description: (t) => t('Folder Plus'),
  },
  'folder-minus': {
    title: (t) => '',
    description: (t) => t('Folder Minus'),
  },
  'folder-arrow-up': {
    title: (t) => '',
    description: (t) => t('Folder Arrow Up'),
  },
  'folder-arrow-down': {
    title: (t) => '',
    description: (t) => t('Folder Arrow Down'),
  },
  'floppy-disk-circle-xmark': {
    title: (t) => '',
    description: (t) => t('Floppy Disk Circle Xmark'),
  },
  'floppy-disk-circle-arrow-right': {
    title: (t) => '',
    description: (t) => t('Floppy Disk Circle Arrow Right'),
  },
  'floppy-disk': {
    title: (t) => '',
    description: (t) => t('Floppy Disk'),
  },
  'file-user': {
    title: (t) => '',
    description: (t) => t('File User'),
  },
  'file-lines': {
    title: (t) => '',
    description: (t) => t('File Lines'),
  },
  'file-circle-plus': {
    title: (t) => '',
    description: (t) => t('File Circle Plus'),
  },
  'file-circle-info': {
    title: (t) => '',
    description: (t) => t('File Circle Info'),
  },
  'file-chart-pie': {
    title: (t) => '',
    description: (t) => t('File Chart Pie'),
  },
  'file-chart-column': {
    title: (t) => '',
    description: (t) => t('File Chart Column'),
  },
  fax: {
    title: (t) => '',
    description: (t) => t('Fax'),
  },
  'envelope-open': {
    title: (t) => '',
    description: (t) => t('Envelope Open'),
  },
  'envelope-dot': {
    title: (t) => '',
    description: (t) => t('Envelope Dot'),
  },
  'envelope-circle-check': {
    title: (t) => '',
    description: (t) => t('Envelope Circle Check'),
  },
  'coffee-pot': {
    title: (t) => '',
    description: (t) => t('Coffee Pot'),
  },
  'cloud-word': {
    title: (t) => '',
    description: (t) => t('Cloud Word'),
  },
  'clipboard-question': {
    title: (t) => '',
    description: (t) => t('Clipboard Question'),
  },
  'chart-user': {
    title: (t) => '',
    description: (t) => t('Chart User'),
  },
  'chart-tree-map': {
    title: (t) => '',
    description: (t) => t('Chart Tree Map'),
  },
  'chart-simple-horizontal': {
    title: (t) => '',
    description: (t) => t('Chart Simple Horizontal'),
  },
  'chart-pyramid': {
    title: (t) => '',
    description: (t) => t('Chart Pyramid'),
  },
  'chart-pie-simple': {
    title: (t) => '',
    description: (t) => t('Chart Pie Simple'),
  },
  'chart-pie': {
    title: (t) => '',
    description: (t) => t('Chart Pie'),
  },
  'chart-line-up': {
    title: (t) => '',
    description: (t) => t('Chart Line Up'),
  },
  'chart-line-down': {
    title: (t) => '',
    description: (t) => t('Chart Line Down'),
  },
  'chart-line': {
    title: (t) => '',
    description: (t) => t('Chart Line'),
  },
  'cake-candles': {
    title: (t) => '',
    description: (t) => t('Cake Candles'),
  },
  'cabinet-filing': {
    title: (t) => '',
    description: (t) => t('Cabinet Filing'),
  },
  bullhorn: {
    title: (t) => '',
    description: (t) => t('Bullhorn'),
  },
  'briefcase-blank': {
    title: (t) => '',
    description: (t) => t('Briefcase Blank'),
  },
  'briefcase-arrow-right': {
    title: (t) => '',
    description: (t) => t('Briefcase Arrow Right'),
  },
  'brain-arrow-curved-right': {
    title: (t) => '',
    description: (t) => t('Brain Arrow Curved Right'),
  },
  'boxes-packing': {
    title: (t) => '',
    description: (t) => t('Boxes Packing'),
  },
  'box-archive': {
    title: (t) => '',
    description: (t) => t('Box Archive'),
  },
  'book-section': {
    title: (t) => '',
    description: (t) => t('Book Section'),
  },
  'bars-staggered': {
    title: (t) => '',
    description: (t) => t('Bars Staggered'),
  },
  'bars-progress': {
    title: (t) => '',
    description: (t) => t('Bars Progress'),
  },
  'badge-percent': {
    title: (t) => '',
    description: (t) => t('Badge Percent'),
  },
  'badge-dollar': {
    title: (t) => '',
    description: (t) => t('Badge Dollar'),
  },
  'arrows-to-eye': {
    title: (t) => '',
    description: (t) => t('Arrows To Eye'),
  },
  'arrows-to-dot': {
    title: (t) => '',
    description: (t) => t('Arrows To Dot'),
  },
  'arrows-spin': {
    title: (t) => '',
    description: (t) => t('Arrows Spin'),
  },
  sparkles: {
    title: (t) => '',
    description: (t) => t('Sparkles'),
  },
  megaphone: {
    title: (t) => '',
    description: (t) => t('Megaphone'),
  },
  'block-question': {
    title: (t) => '',
    description: (t) => t('Block Question'),
  },
  'lightbulb-slash': {
    title: (t) => '',
    description: (t) => t('Lightbulb Slash'),
  },
  'comments-dollar': {
    title: (t) => '',
    description: (t) => t('Comments Dollar'),
  },
  'bullseye-pointer': {
    title: (t) => '',
    description: (t) => t('Bullseye Pointer'),
  },
  lightbulb: {
    title: (t) => '',
    description: (t) => t('Lightbulb'),
  },
  'user-group-crown': {
    title: (t) => '',
    description: (t) => t('User Group Crown'),
  },
  'user-crown': {
    title: (t) => '',
    description: (t) => t('User Crown'),
  },
  'signal-stream-slash': {
    title: (t) => '',
    description: (t) => t('Signal Stream Slash'),
  },
  'signal-stream': {
    title: (t) => '',
    description: (t) => t('Signal Stream'),
  },
  'rectangle-ad': {
    title: (t) => '',
    description: (t) => t('Rectangle Ad'),
  },
  'ranking-star': {
    title: (t) => '',
    description: (t) => t('Ranking Star'),
  },
  'person-rays': {
    title: (t) => '',
    description: (t) => t('Person Rays'),
  },
  'people-group': {
    title: (t) => '',
    description: (t) => t('People Group'),
  },
  'messages-dollar': {
    title: (t) => '',
    description: (t) => t('Messages Dollar'),
  },
  'message-dollar': {
    title: (t) => '',
    description: (t) => t('Message Dollar'),
  },
  'magnifying-glass-location': {
    title: (t) => '',
    description: (t) => t('Magnifying Glass Location'),
  },
  'magnifying-glass-dollar': {
    title: (t) => '',
    description: (t) => t('Magnifying Glass Dollar'),
  },
  'lightbulb-on': {
    title: (t) => '',
    description: (t) => t('Lightbulb On'),
  },
  'lightbulb-exclamation': {
    title: (t) => '',
    description: (t) => t('Lightbulb Exclamation'),
  },
  'lightbulb-dollar': {
    title: (t) => '',
    description: (t) => t('Lightbulb Dollar'),
  },
  'lightbulb-cfl-on': {
    title: (t) => '',
    description: (t) => t('Lightbulb Cfl On'),
  },
  'lightbulb-cfl': {
    title: (t) => '',
    description: (t) => t('Lightbulb Cfl'),
  },
  'hundred-points': {
    title: (t) => '',
    description: (t) => t('Hundred Points'),
  },
  'group-arrows-rotate': {
    title: (t) => '',
    description: (t) => t('Group Arrows Rotate'),
  },

  'gift-card': {
    title: (t) => '',
    description: (t) => t('Gift Card'),
  },
  'filter-circle-dollar': {
    title: (t) => '',
    description: (t) => t('Filter Circle Dollar'),
  },
  'envelopes-bulk': {
    title: (t) => '',
    description: (t) => t('Envelopes Bulk'),
  },
  'envelope-open-text': {
    title: (t) => '',
    description: (t) => t('Envelope Open Text'),
  },
  'envelope-open-dollar': {
    title: (t) => '',
    description: (t) => t('Envelope Open Dollar'),
  },
  'comment-dollar': {
    title: (t) => '',
    description: (t) => t('Comment Dollar'),
  },
  'chart-mixed': {
    title: (t) => '',
    description: (t) => t('Chart Mixed'),
  },
  'bullseye-arrow': {
    title: (t) => '',
    description: (t) => t('Bullseye Arrow'),
  },
  star: {
    title: (t) => '',
    description: (t) => t('Star'),
  },
  heart: {
    title: (t) => '',
    description: (t) => t('Heart'),
  },
  'camera-retro': {
    title: (t) => '',
    description: (t) => t('Camera Retro'),
  },
  'truck-fast': {
    title: (t) => '',
    description: (t) => t('Truck Fast'),
  },
  bell: {
    title: (t) => '',
    description: (t) => t('Bell'),
  },
  'cart-shopping': {
    title: (t) => '',
    description: (t) => t('Cart Shopping'),
  },
  gift: {
    title: (t) => '',
    description: (t) => t('Gift'),
  },
  barcode: {
    title: (t) => '',
    description: (t) => t('Barcode'),
  },
  bookmark: {
    title: (t) => '',
    description: (t) => t('Bookmark'),
  },
  camera: {
    title: (t) => '',
    description: (t) => t('Camera'),
  },
  'thumbs-up': {
    title: (t) => '',
    description: (t) => t('Thumbs Up'),
  },
  'thumbs-down': {
    title: (t) => '',
    description: (t) => t('Thumbs Down'),
  },
  key: {
    title: (t) => '',
    description: (t) => t('Key'),
  },
  truck: {
    title: (t) => '',
    description: (t) => t('Truck'),
  },
  balloons: {
    title: (t) => '',
    description: (t) => t('Balloons'),
  },
  handshake: {
    title: (t) => '',
    description: (t) => t('Handshake'),
  },
  shop: {
    title: (t) => '',
    description: (t) => t('Shop'),
  },
  shirt: {
    title: (t) => '',
    description: (t) => t('Shirt'),
  },
  'bag-shopping': {
    title: (t) => '',
    description: (t) => t('Bag Shopping'),
  },
  moped: {
    title: (t) => '',
    description: (t) => t('Moped'),
  },
  'cart-plus': {
    title: (t) => '',
    description: (t) => t('Cart Plus'),
  },
  'credit-card': {
    title: (t) => '',
    description: (t) => t('Credit Card'),
  },
  store: {
    title: (t) => '',
    description: (t) => t('Store'),
  },
  'cash-register': {
    title: (t) => '',
    description: (t) => t('Cash Register'),
  },
  'booth-curtain': {
    title: (t) => '',
    description: (t) => t('Booth Curtain'),
  },
  trophy: {
    title: (t) => '',
    description: (t) => t('Trophy'),
  },
  receipt: {
    title: (t) => '',
    description: (t) => t('Receipt'),
  },
  'truck-container': {
    title: (t) => '',
    description: (t) => t('Truck Container'),
  },
  'truck-clock': {
    title: (t) => '',
    description: (t) => t('Truck Clock'),
  },
  'truck-bolt': {
    title: (t) => '',
    description: (t) => t('Truck Bolt'),
  },
  'trophy-star': {
    title: (t) => '',
    description: (t) => t('Trophy Star'),
  },
  'store-slash': {
    title: (t) => '',
    description: (t) => t('Store Slash'),
  },
  'store-lock': {
    title: (t) => '',
    description: (t) => t('Store Lock'),
  },
  'star-exclamation': {
    title: (t) => '',
    description: (t) => t('Star Exclamation'),
  },
  'shop-slash': {
    title: (t) => '',
    description: (t) => t('Shop Slash'),
  },
  'shop-lock': {
    title: (t) => '',
    description: (t) => t('Shop Lock'),
  },
  'rectangle-barcode': {
    title: (t) => '',
    description: (t) => t('Rectangle Barcode'),
  },
  'person-booth': {
    title: (t) => '',
    description: (t) => t('Person Booth'),
  },
  'nfc-trash': {
    title: (t) => '',
    description: (t) => t('Nfc Trash'),
  },
  'nfc-symbol': {
    title: (t) => '',
    description: (t) => t('Nfc Symbol'),
  },
  'nfc-slash': {
    title: (t) => '',
    description: (t) => t('Nfc Slash'),
  },
  'nfc-signal': {
    title: (t) => '',
    description: (t) => t('Nfc Signal'),
  },
  'nfc-pen': {
    title: (t) => '',
    description: (t) => t('Nfc Pen'),
  },
  'nfc-magnifying-glass': {
    title: (t) => '',
    description: (t) => t('Nfc Magnifying Glass'),
  },
  'nfc-lock': {
    title: (t) => '',
    description: (t) => t('Nfc Lock'),
  },
  nfc: {
    title: (t) => '',
    description: (t) => t('Nfc'),
  },
  'money-check-dollar': {
    title: (t) => '',
    description: (t) => t('Money Check Dollar'),
  },
  'money-check': {
    title: (t) => '',
    description: (t) => t('Money Check'),
  },
  'mobile-signal-out': {
    title: (t) => '',
    description: (t) => t('Mobile Signal Out'),
  },
  'mobile-signal': {
    title: (t) => '',
    description: (t) => t('Mobile Signal'),
  },
  'hexagon-vertical-nft-slanted': {
    title: (t) => '',
    description: (t) => t('Hexagon Vertical Nft Slanted'),
  },
  'hexagon-vertical-nft': {
    title: (t) => '',
    description: (t) => t('Hexagon Vertical Nft'),
  },
  'hexagon-image': {
    title: (t) => '',
    description: (t) => t('Hexagon Image'),
  },
  'hand-holding-box': {
    title: (t) => '',
    description: (t) => t('Hand Holding Box'),
  },
  gifts: {
    title: (t) => '',
    description: (t) => t('Gifts'),
  },
  gem: {
    title: (t) => '',
    description: (t) => t('Gem'),
  },
  'credit-card-front': {
    title: (t) => '',
    description: (t) => t('Credit Card Front'),
  },
  'credit-card-blank': {
    title: (t) => '',
    description: (t) => t('Credit Card Blank'),
  },
  'cart-xmark': {
    title: (t) => '',
    description: (t) => t('Cart Xmark'),
  },
  'cart-shopping-fast': {
    title: (t) => '',
    description: (t) => t('Cart Shopping Fast'),
  },
  'cart-minus': {
    title: (t) => '',
    description: (t) => t('Cart Minus'),
  },
  'cart-circle-xmark': {
    title: (t) => '',
    description: (t) => t('Cart Circle Xmark'),
  },
  'cart-circle-plus': {
    title: (t) => '',
    description: (t) => t('Cart Circle Plus'),
  },
  'cart-circle-exclamation': {
    title: (t) => '',
    description: (t) => t('Cart Circle Exclamation'),
  },
  'cart-circle-check': {
    title: (t) => '',
    description: (t) => t('Cart Circle Check'),
  },
  'cart-circle-arrow-up': {
    title: (t) => '',
    description: (t) => t('Cart Circle Arrow Up'),
  },
  'cart-circle-arrow-down': {
    title: (t) => '',
    description: (t) => t('Cart Circle Arrow Down'),
  },
  'cart-arrow-up': {
    title: (t) => '',
    description: (t) => t('Cart Arrow Up'),
  },
  'cart-arrow-down': {
    title: (t) => '',
    description: (t) => t('Cart Arrow Down'),
  },
  'box-open-full': {
    title: (t) => '',
    description: (t) => t('Box Open Full'),
  },
  'basket-shopping-simple': {
    title: (t) => '',
    description: (t) => t('Basket Shopping Simple'),
  },
  'basket-shopping': {
    title: (t) => '',
    description: (t) => t('Basket Shopping'),
  },
  'barcode-scan': {
    title: (t) => '',
    description: (t) => t('Barcode Scan'),
  },
  'barcode-read': {
    title: (t) => '',
    description: (t) => t('Barcode Read'),
  },
  balloon: {
    title: (t) => '',
    description: (t) => t('Balloon'),
  },
  'bags-shopping': {
    title: (t) => '',
    description: (t) => t('Bags Shopping'),
  },
  bars: {
    title: (t) => '',
    description: (t) => t('Bars'),
  },
  browser: {
    title: (t) => '',
    description: (t) => t('Browser'),
  },
  'chart-bar': {
    title: (t) => '',
    description: (t) => t('Chart Bar'),
  },
  cog: {
    title: (t) => '',
    description: (t) => t('Cog'),
  },
  database: {
    title: (t) => '',
    description: (t) => t('Database'),
  },
  'calendar-alt': {
    title: (t) => '',
    description: (t) => t('Calendar Alt'),
  },
  play: {
    title: (t) => '',
    description: (t) => t('Play'),
  },
  'kizen-k': {
    title: (t) => '',
    description: (t) => t('Kizen K'),
  },
  add: {
    title: (t) => '',
    description: (t) => t('Add'),
  },
  'book-alt': {
    title: (t) => '',
    description: (t) => t('Book Alt'),
  },
  'form-entity': {
    title: (t) => '',
    description: (t) => t('Form Entity'),
  },
  'arrows-to-circle': {
    title: (t) => '',
    description: (t) => t('Arrows to Circle'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};

export const customIcons: CustomIconSettings = {
  'kizen-k': Kizen,
  'form-entity': FormEntity,
};
