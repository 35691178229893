import { forwardRef } from 'react';

import { getAdditionalFieldProps } from 'checks/fields';
import WholeNumberInline from 'components/Inputs/inline/TextInput/presets/WholeNumber';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import { StyledInfoWrapper } from 'components/Fields/InlineTableFieldInput/styles';
import { withCellWrapper } from './helpers';

const _WholeNumberCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      editable,
      ...others
    },
    ref
  ) => {
    return (
      <StyledInfoWrapper isInherited={!!info}>
        <WholeNumberInline
          ref={ref}
          {...others}
          value={value}
          onSubmit={onSubmit}
          readOnly={readOnly}
          onAutoFocus={onAutoFocus}
          field={field}
          object={object}
          {...getAdditionalFieldProps(field, info, placeholder)}
        />
        {info && editable && !readOnly ? (
          <IconWithTooltip label={info} />
        ) : null}
      </StyledInfoWrapper>
    );
  }
);

export const WholeNumberCell = withCellWrapper(_WholeNumberCell);
