export type FilterType =
  | (typeof ClientFilters)[number]
  | (typeof CustomObjectFilters)[number]
  | (typeof PipelineObjectFilters)[number];

export const ClientFilters = [
  'automations',
  'fields',
  'forms',
  'interactions',
  'lead_sources',
  'logged_activities',
  'messages',
  'related_object',
  'scheduled_activities',
  'subscription_lists',
  'surveys',
  'tags',
  'team_interactions',
] as const;

export const CustomObjectFilters = [
  'automations',
  'fields',
  'forms',
  'lead_sources',
  'logged_activities',
  'owner',
  'related_object',
  'scheduled_activities',
  'surveys',
  'team_interactions',
] as const;

export const PipelineObjectFilters = [...CustomObjectFilters, 'stage'] as const;

export const AutomationFilters = [
  'number_active',
  'number_completed',
  'number_paused',
  'name',
  'created',
  'custom_object_id',
  'status',
];
