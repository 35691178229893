import { forwardRef } from 'react';

import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';

import DynamicTagsInlineField, {
  DynamicTagsSelect,
} from 'components/Fields/InlineTableFieldInput/DynamicTags';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';

import { withCellWrapper } from './helpers';

const _DynamicTagsCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton = <ClearSelectButton select={null} />,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    return (
      <DynamicTagsInlineField
        ref={ref}
        {...others}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        field={field}
        object={object}
      >
        <DynamicTagsSelect
          objectId={objectId}
          serviceToUse={serviceToUse}
          fetchUrl={fetchUrl}
          menuLeftButton={menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
          handleUpdateFieldOption={handleUpdateFieldOption}
        />
      </DynamicTagsInlineField>
    );
  }
);

export const DynamicTagsCell = withCellWrapper(_DynamicTagsCell);
