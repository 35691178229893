import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { DEFAULT_INPUT_DELAY } from 'utility/config';
import { maybeTrackSearch } from 'utility/analytics';

const SearchContext = React.createContext({
  term: '',
  debouncedTerm: '',
  setSearchTerm: () => '',
});

const SearchStateWrapper = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useDebounce(() => setDebouncedTerm(searchTerm), DEFAULT_INPUT_DELAY, [
    searchTerm,
  ]);

  useEffect(() => {
    if (debouncedTerm) {
      maybeTrackSearch(debouncedTerm, 'dashboard_settings');
    }
  }, [debouncedTerm]);

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        debouncedTerm,
        setSearchTerm,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default {
  Wrapper: SearchStateWrapper,
  Context: SearchContext,
};
