import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import BaseIconButton from 'components/Kizen/IconButton';
import KizenTypography from 'app/kizentypo';
import Icon from 'components/Kizen/Icon';
import { IconSizing } from 'components/Kizen/Icon';
import { grayScale } from 'app/colors';
import { borderRadii, gutters } from 'app/spacing';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: ${gutters.spacing(2)}px;
  }
`;

const IconButton = styled(BaseIconButton)`
  border-radius: ${borderRadii.standard};
  :first-of-type {
    // This is the plus button
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  :last-of-type {
    // This is the minus button
    border-left: 1px solid ${grayScale.mediumLight};
    // Do not count border as part of total width
    box-sizing: content-box;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    // Do not overlay box shadow on plus button. 6px is max size of the box-shadow.
    clip-path: inset(-6px -6px -6px 0);
  }
`;

export default function ZoomControl({ onClickPlus, onClickMinus, ...others }) {
  const { t } = useTranslation();
  return (
    <Wrapper {...others}>
      <KizenTypography as="span">{t('Zoom')}</KizenTypography>
      <IconButton sizing="overlay" onClick={onClickPlus} title={t('Zoom In')}>
        <IconSizing size="16px" force={1}>
          {/* Effectively 12px wide due to icon's aspect ratio and padding */}
          <Icon icon="plus" />
        </IconSizing>
      </IconButton>
      <IconButton sizing="overlay" title={t('Zoom Out')} onClick={onClickMinus}>
        <IconSizing size="16px" force={1}>
          {/* Effectively 12px wide due to icon's aspect ratio and padding */}
          <Icon icon="minus" />
        </IconSizing>
      </IconButton>
    </Wrapper>
  );
}

ZoomControl.propTypes = {
  onClickPlus: PropTypes.func,
  onClickMinus: PropTypes.func,
};

ZoomControl.defaultProps = {
  onClickPlus: null,
  onClickMinus: null,
};
