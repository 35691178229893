import Switch from 'components/Kizen/Switch';
import useModal from 'components/Modals/useModal';
import { ActiveStateModal } from 'pages/Homepages/components/ActiveModal';
import { ToggleWrapper } from './styles';

export const ActiveToggleCell = ({ data, handleChangeActive }) => {
  const [activeStateModalProps, activeStateModalTriggerProps] = useModal({
    handleSubmit: () => handleChangeActive(data.id, !data.published),
  });

  return (
    <>
      <ToggleWrapper>
        <Switch
          onClick={activeStateModalTriggerProps.onClick}
          checked={Boolean(data.published)}
          removeMargin
        />
      </ToggleWrapper>
      <ActiveStateModal active={data.published} {...activeStateModalProps} />
    </>
  );
};
