import MultiSelect from 'components/Inputs/MultiSelect';
import { ApplyMenuButton, ClearMenuButton } from 'components/Kizen/Menu';
import useField from 'hooks/useField';
import { useTranslation } from 'react-i18next';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

export const PillMultiselect = ({
  onChange,
  onHide,
  value,
  isReadOnly,
  ...props
}) => {
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = useField(value);
  const handleClickApply = () => {
    onHide();
    onChange(innerValue);
  };

  const handleClickClear = () => {
    onHide();
    onChange(EMPTY_ARRAY);
  };
  const handleChange = (val) => setInnerValue(val);

  return (
    <MultiSelect
      {...props}
      onSubmit={handleClickApply}
      handleClickApply={handleClickApply}
      value={innerValue}
      onChange={handleChange}
      isReadOnly={isReadOnly}
      disabled={isReadOnly}
      menuIsOpen={!isReadOnly}
      menuLeftButton={
        <ClearMenuButton onClick={handleClickClear}>
          {t('Clear & Close')}
        </ClearMenuButton>
      }
      menuRightButton={<ApplyMenuButton onClick={handleClickApply} />}
      hideRemoveIcon={isReadOnly}
    />
  );
};
