import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal from 'components/Modals/presets/BasicModal';
import Select from 'components/Inputs/Select';
import { getDataQAForInput } from 'components/Inputs/helpers';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import Switch from 'components/Kizen/Switch';

export const AddTeamAssociationModal = ({ onConfirm, onHide }) => {
  const { t } = useTranslation();
  const selectRef = useRef();

  const [newTeamMember, setNewTeamMember] = useState(null);
  const [hasRecordAccess, setHasRecordAccess] = useState(true);

  const onHideEvent = useCallback(() => {
    onHide();
  }, [onHide]);

  const onConfirmEvent = useCallback(
    () =>
      onConfirm({
        employee_id: newTeamMember.value,
        has_record_access: hasRecordAccess,
      }),
    [onConfirm, newTeamMember, hasRecordAccess]
  );

  const [selectProps] = useSelectTypeaheadWithScroll({
    selectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
  });

  const onChangeAccess = (e) => {
    setHasRecordAccess(e.target.checked);
  };

  return (
    <BasicModal
      show
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      heading={t('Add Team Association')}
      disabled={!newTeamMember}
      onHide={onHideEvent}
      onConfirm={onConfirmEvent}
    >
      <Select
        ref={selectRef}
        fullWidth
        inModal
        menuInline
        margin
        label={t('Choose Team Member to Associate')}
        placeholder={t('Find Team Member')}
        value={newTeamMember}
        onChange={(selected) => setNewTeamMember(selected)}
        {...selectProps}
        {...getDataQAForInput(`contact-info-team-association`, 'dropdown')}
      />
      <Switch
        removeMargin
        label={t('Grant "My Records" Access')}
        checked={hasRecordAccess}
        onChange={onChangeAccess}
        textPlacement="left"
      />
    </BasicModal>
  );
};
