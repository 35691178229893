import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { colorsPrimary, grayScale } from 'app/colors';
import { css } from '@emotion/core';
import Select from 'components/Inputs/Select';
import Icon from 'components/Kizen/Icon';
import Switch from 'components/Kizen/Switch';
import { ICON_HEIGHT } from './constants';
import { GridLikeFlex, GridLikeFlexOverlay } from './components/GridLikeFlex';
import { dropdownColors } from 'app/colors';
import { borderRadii, layers } from 'app/spacing';
import { ToolbarColorPicker } from './components/ToolbarColorPicker';
import { CreatableSelect } from '../WYSIWYG/components/CreatableSelect';
import { gutters } from 'app/spacing';
import IconButton from 'components/Kizen/IconButton';
import Menu, { MenuList } from 'components/Kizen/Menu';
import CommentListItem from 'components/Kizen/List/CommentListItem';

export const StoryBookContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
`;

export const ToolbarIcon = styled(Icon)`
  width: 25px;

  && svg:hover {
    color: inherit;
  }
`;

export const StyledToolbarColorPicker = styled(ToolbarColorPicker)`
  height: ${ICON_HEIGHT};
  cursor: pointer;

  i > svg {
    height: ${ICON_HEIGHT};
  }

  ${({ menuPlacement }) =>
    menuPlacement === 'top' &&
    css`
      > div {
        top: -313px;
      }
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: ${grayScale.dark};
  margin-bottom: -1px;
  background: white;
  border: 1px solid ${grayScale.medium};
  padding: 10px 0;
  border-radius: 5px;
  height: 45px;
  box-shadow: 0 18px 26px rgba(0, 0, 0, 0.03);
`;
export const ToolbarSection = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-left: 1px solid ${grayScale.medium};
  padding: 0 10px;
  height: 25px;

  :first-child {
    border: none;
  }

  ${({ paddingRight }) =>
    !isNaN(paddingRight) &&
    css`
      padding-right: ${paddingRight}px;
    `}
  ${({ paddingLeft }) =>
    !isNaN(paddingLeft) &&
    css`
      padding-left: ${paddingLeft}px;
    `}
`;

export const ToggleButton = styled((props) => {
  return <IconButton color={grayScale.dark} {...props} />;
})`
  width: 25px;
  height: 25px;
  margin-right: 5px;
  padding: 0;
  background-color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;

  :focus,
  :hover,
  :active {
    outline: none;
  }

  :last-child {
    margin-right: 0;
  }

  i {
    padding: 0;
    justify-content: center;
  }

  i > svg {
    height: ${ICON_HEIGHT};
  }

  ${({ active }) =>
    active &&
    css`
      i,
      i:hover {
        color: ${colorsPrimary.blue.dark} !important;
      }
    `}
`;

export const TOOLBAR_BORDER_WIDTH = 1;
export const TOOLBAR_VERTICAL_PADDING = 10;
export const TOOLBAR_PADDING_LEFT = 5;
export const TOOLBAR_ROW_HEIGHT = 25;
export const TOOLBAR_ROW_GAP = 20;

const textEditorToolbarStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: ${TOOLBAR_ROW_GAP}px;
  color: ${grayScale.dark};
  margin-bottom: -1px;
  background: white;
  border: 1px solid ${grayScale.medium};
  padding: ${TOOLBAR_VERTICAL_PADDING}px 0 ${TOOLBAR_VERTICAL_PADDING}px
    ${TOOLBAR_PADDING_LEFT}px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const TextEditorToolbar = styled(GridLikeFlex)`
  ${textEditorToolbarStyle}
  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}
`;

export const ToolbarWrapper = ({ children, ...rest }) => {
  return (
    <TextEditorToolbar
      showGridLines
      borderWidth={TOOLBAR_BORDER_WIDTH}
      paddingTop={TOOLBAR_VERTICAL_PADDING}
      paddingBottom={TOOLBAR_VERTICAL_PADDING}
      paddingLeft={TOOLBAR_PADDING_LEFT}
      rowHeight={TOOLBAR_ROW_HEIGHT}
      rowGap={TOOLBAR_ROW_GAP}
      {...rest}
    >
      {children}
    </TextEditorToolbar>
  );
};

export const FloatingTextEditorToolbar = styled(
  forwardRef(({ children, ctx, ...rest }, ref) => (
    <div ref={ref} {...rest}>
      {children}
    </div>
  ))
)`
  ${textEditorToolbarStyle}
  position: absolute;
  top: ${({ ctx: { rows = 1, rowHeight, rowGap } }) =>
    `-${rows * (rowHeight + rowGap) + 3}px`};
  border-radius: ${borderRadii.small};
  box-shadow: 0 18px 26px rgba(0, 0, 0, 0.03);
`;

export const FloatingToolbarWrapper = ({ asOverlay, children, ...rest }) => {
  const Container = asOverlay ? GridLikeFlexOverlay : GridLikeFlex;
  return (
    <Container
      dataQA={'floating-toolbar-wrapper'}
      showGridLines
      borderWidth={TOOLBAR_BORDER_WIDTH}
      paddingTop={TOOLBAR_VERTICAL_PADDING}
      paddingBottom={TOOLBAR_VERTICAL_PADDING}
      paddingLeft={TOOLBAR_PADDING_LEFT}
      rowHeight={TOOLBAR_ROW_HEIGHT}
      rowGap={TOOLBAR_ROW_GAP}
      Component={FloatingTextEditorToolbar}
      {...rest}
    >
      {children}
    </Container>
  );
};

export const TextEditorToolbarSection = styled.div`
  padding: ${gutters.spacing(1)}px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  height: ${TOOLBAR_ROW_HEIGHT}px;
  padding: 0 ${gutters.spacing(2)}px;
  border-left: 1px solid ${grayScale.medium};

  :first-child {
    border: none;
  }

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}

  ${({ isFirstColumn }) =>
    isFirstColumn &&
    css`
      border: none;
    `};
`;

const selectStyle = ({ width = 120 }) => css`
  width: ${width}px;

  > div {
    border-bottom-width: 0px;
    padding-left: ${gutters.spacing(1, 2)}px;
  }

  > div ~ div {
    width: auto;
    border-bottom-width: 1px;
    // 12 = toolbar cell container padding (5px left/right padding & -1px right margin)
    min-width: ${width + 12}px;
    margin-left: -${gutters.spacing(1, 1)}px;
    margin-top: ${gutters.spacing(1, 3)}px;
    padding-left: 0px;
  }
`;

export const ToolbarSelectWysiwyg = styled(Select)`
  ${selectStyle}
`;
export const ToolbarSelect = styled(Select)`
  width: ${({ width = 67 }) => `${width}px`};
  margin-right: ${({ width = 67 }) => `${67 - width}px`};

  i {
    margin-bottom: 1px;
  }

  > div {
    border-bottom-width: 0;
    padding: 0;
  }

  > div ~ div {
    width: auto;
    border-bottom-width: 1px;
    margin-left: -13px;
    ${({ menuPlacement }) =>
      menuPlacement === 'bottom' &&
      css`
        margin-top: 8px;
      `}
    ${({ menuPlacement }) =>
      menuPlacement === 'top' &&
      css`
        margin-bottom: 8px;
      `}
  }
`;
export const ToolbarCreatableSelect = styled(CreatableSelect)`
  ${selectStyle}
`;

export const ToolbarSwitch = styled(Switch)`
  margin: ${gutters.spacing(1, 0)}px ${gutters.spacing(3, 0)}px 0 0;
  > div > i {
    padding-top: 1px;
  }
`;

export const ToolbarIconMenuContainer = styled.div`
  position: relative;
  max-width: 25px;
`;

export const ToolbarIconMenu = styled(Menu)`
  position: absolute;
  top: ${({ top = 35 }) => `${top}px;`};
  left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  z-index: ${layers.content(1)};
`;

export const ToolbarIconMenuList = MenuList;

export const ToolbarIconMenuListItem = styled(CommentListItem)`
  ${({ selected }) =>
    !selected &&
    css`
      &:hover {
        background-color: ${dropdownColors.hover};
      }
    `}
`;
