import { getPayloadFieldValue } from 'services/FieldService';
import { FIELD_TYPES } from 'utility/constants';
import {
  defaultFieldValueMappingTypes,
  fieldValueMappingsForFlow,
  fieldValueMappingsErrorsForFlow,
} from '../CommonComponents/valueMappingsCommon';
import { cfvChangeTypeOptionsConst } from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/ChangeFieldValue/common';

import {
  getTeamLabel,
  relatedIdToOption,
  displayNameToOptionError,
  namedToOptionError,
  deletedWrapper,
} from 'services/helpers';
import {
  _checkDeleted,
  displayNameDescriptor,
  descriptionDescriptor,
  getRelatedObjectField,
  getThisRecordField,
  getRelatedObject,
  getAutomationTargetRelationshipField,
  nameDescriptor,
} from 'pages/AutomationEngine/helpers';
import { hasOptionsWizard } from './common';

const isNaInt = (value) => isNaN(parseInt(value));
const parseIntOrNull = (value) => (isNaInt(value) ? null : parseInt(value));
export const fieldToModifyfieldType = (ftm) => ftm?.fieldData?.fieldType;

const justTheIds = (valueList) => valueList.map(({ id }) => id);
const isValidArray = (list) => Array.isArray(list) && list.length;
const isEmptyArray = (list) => Array.isArray(list) && list.length === 0;
const listOrId = (value) => (isValidArray(value) ? justTheIds(value) : value);
const validForOption = (value) => Boolean(value?.id);
const getValueForApi = (field, fieldValue) => {
  const { value } = fieldValue;
  switch (field.fieldType) {
    case FIELD_TYPES.Dropdown.type:
    case FIELD_TYPES.Radio.type:
    case FIELD_TYPES.Status.type:
      return value?.id ?? null;
    case FIELD_TYPES.Checkbox.type:
      return value || false;
    case FIELD_TYPES.Checkboxes.type:
    case FIELD_TYPES.Files.type:
      if (isValidArray(value)) {
        return justTheIds(value);
      }
      return null;
    case FIELD_TYPES.Relationship.type:
      if (value) {
        return isValidArray(value)
          ? justTheIds(value)
          : isEmptyArray(value)
            ? value
            : value.id;
      }
      return null;
    case FIELD_TYPES.TeamSelector.type:
      return value?.id || null;
    case FIELD_TYPES.Choices.type:
      return value ? String(value) : null;
    case FIELD_TYPES.Date.type:
      return getPayloadFieldValue(value, field) || null;
    case FIELD_TYPES.Text.type:
    case FIELD_TYPES.LongText.type:
      return value || '';
    case FIELD_TYPES.Decimal.type:
    case FIELD_TYPES.Money.type:
    case FIELD_TYPES.Integer.type:
      return isNaN(parseInt(value)) ? null : parseFloat(value);
    case FIELD_TYPES.DynamicTags.type: {
      return {
        tags_to_add: listOrId(fieldValue.tagsToAdd),
        tags_to_remove: listOrId(fieldValue.tagsToRemove),
      };
    }
    default: {
      return value || null;
    }
  }
};

const getfieldValueMapping = (value, fieldType) =>
  fieldType === FIELD_TYPES.Rating.type ? parseIntOrNull(value) : value;

const getfieldValueMappings = (fieldValueMappings, fieldType) => {
  const mapped = fieldValueMappings.map(
    ({ order, sourceValues, targetValues }) => ({
      order,
      sourceValues: (sourceValues || []).map((sourceValue) =>
        getfieldValueMapping(sourceValue?.value || sourceValue, fieldType)
      ),
      targetValues: (targetValues || [])
        .filter(Boolean)
        .map((targetValue) =>
          getfieldValueMapping(targetValue?.value || targetValue, fieldType)
        ),
    })
  );
  return mapped;
};

const forApi = (step) => {
  const {
    fieldToModify,
    specificFieldValue,
    changeType: { value: changeTypeValue },
    relatedObject,
    automationTargetRelationshipField,
    relatedObjectField,
    fieldResolution,
    fieldValueMappings,
    thisRecordField,
  } = { ...step.details };

  return {
    details: {
      fieldToModify: fieldToModify.id,
      specificFieldValue: getValueForApi(fieldToModify, specificFieldValue),
      changeType: changeTypeValue,
      relatedObject:
        changeTypeValue === cfvChangeTypeOptionsConst.RELATED_OBJECT_FIELD
          ? relatedObject?.value
          : null,
      automationTargetRelationshipField:
        automationTargetRelationshipField?.value,
      relatedObjectField:
        changeTypeValue === cfvChangeTypeOptionsConst.THIS_RECORD_FIELD
          ? thisRecordField?.value
          : relatedObjectField?.value,
      fieldResolution: fieldResolution?.value,
      fieldValueMappings: fieldValueMappings?.length
        ? getfieldValueMappings(
            fieldValueMappings,
            fieldToModifyfieldType(fieldToModify)
          )
        : null,
    },
  };
};

const valueWrap = (value) => ({ value });

const getValueForFlow = (field, specificFieldValue) => {
  const { value } = specificFieldValue || { value: null };

  switch (field.fieldType) {
    case FIELD_TYPES.YesNo.type:
    case FIELD_TYPES.YesNoMaybe.type:
      return valueWrap(specificFieldValue?.id);

    case FIELD_TYPES.Dropdown.type:
    case FIELD_TYPES.Radio.type:
    case FIELD_TYPES.Status.type:
    case FIELD_TYPES.Checkboxes.type:
      return valueWrap(specificFieldValue);
    case FIELD_TYPES.Checkbox.type:
      return valueWrap(value || false);

    case FIELD_TYPES.Files.type:
      return valueWrap(specificFieldValue);
    case FIELD_TYPES.Relationship.type:
      // todo ====>> look for deleted field info in the paylaod
      if (specificFieldValue) {
        if (Array.isArray(specificFieldValue)) {
          return valueWrap(specificFieldValue.map(relatedIdToOption));
        }
        if (validForOption(specificFieldValue)) {
          return valueWrap(relatedIdToOption(specificFieldValue));
        }
        // KZN-5591 if we got a singleton with no id in it throw error, as it means the relation has been deleted
        throw new Error();
      }
      return valueWrap(null);
    case FIELD_TYPES.TeamSelector.type:
      if (specificFieldValue) {
        return valueWrap({
          ...specificFieldValue,
          displayName: getTeamLabel(specificFieldValue),
        });
      }
      return valueWrap(null);
    case FIELD_TYPES.Choices.type:
      return valueWrap(specificFieldValue ? String(specificFieldValue) : null);
    case FIELD_TYPES.Date.type:
      return valueWrap(getPayloadFieldValue(value, field) || null);
    case FIELD_TYPES.DateTime.type:
      return valueWrap(value);
    case FIELD_TYPES.Text.type:
    case FIELD_TYPES.LongText.type:
    case FIELD_TYPES.Email.type:
      return valueWrap(value || '');
    case FIELD_TYPES.PhoneNumber.type:
      // TODO: remove it once we migrate our DB and update our API do not send object anymore.
      return valueWrap(
        value?.formatted ??
          value?.formattedNationalNumber ??
          value?.formatted_national_number ??
          value?.e164 ??
          value
      );
    case FIELD_TYPES.Decimal.type:
    case FIELD_TYPES.Money.type:
    case FIELD_TYPES.Integer.type:
      return valueWrap(isNaN(parseInt(value)) ? null : parseFloat(value));

    case FIELD_TYPES.DynamicTags.type: {
      if (specificFieldValue) {
        const tagsMapDown = (tag) => ({
          ...tag,
          value: tag.id,
          label: tag.name,
        });
        const { tagsToAdd, tagsToRemove } = specificFieldValue;
        return {
          ...specificFieldValue,
          tagsToAdd: tagsToAdd.map(tagsMapDown),
          tagsToRemove: tagsToRemove.map(tagsMapDown),
          value: specificFieldValue.tagsToAdd,
        };
      }
      return valueWrap(null);
    }

    case FIELD_TYPES.Timezone.type:
      return valueWrap(value || null);

    case FIELD_TYPES.Rating.type:
      return valueWrap(isNaN(parseInt(value)) ? null : parseInt(value));

    default: {
      return valueWrap(specificFieldValue || null);
    }
  }
};

const getFieldValueErrors = (fieldValueMappings) =>
  fieldValueMappings.map(({ key, sourceValues, targetValues }) => ({
    key,
    source: !sourceValues.length,
    target: false,
  }));

const getStageFieldValueErrors = (fieldValueMappings) =>
  fieldValueMappings.map(({ key, sourceValues, targetValues }) => ({
    key,
    source: false,
    target: !targetValues.length,
  }));

const forFlow = (apiObject, addErrorMessage, messageDictionary) => {
  const {
    fieldToModify,
    specificFieldValue,
    changeType: changeTypeValue,
    primaryRelatedObjectField,
    automationTargetRelationshipField,
    relatedObjectField,
    relatedObject,
    fieldResolution,
    fieldValueMappings: _fieldValueMappings,
  } = apiObject;

  let fieldValueMappings = fieldValueMappingsForFlow(_fieldValueMappings);
  let transformedValue;

  const errors = {
    ..._checkDeleted({
      messageDictionary,
      addErrorMessage,
      objectToCheck: fieldToModify,
      descriptorCallback: displayNameDescriptor,
      objectPath: 'fieldToModify',
      objectErrorTrans: displayNameToOptionError,
    }),
    ..._checkDeleted({
      messageDictionary,
      addErrorMessage,
      objectToCheck: relatedObject,
      descriptorCallback: descriptionDescriptor,
      objectPath: 'relatedObject',
      objectErrorTrans: namedToOptionError,
    }),
    ..._checkDeleted({
      messageDictionary,
      addErrorMessage,
      objectToCheck: automationTargetRelationshipField,
      descriptorCallback: displayNameDescriptor,
      objectPath: 'automationTargetRelationshipField',
      objectErrorTrans: displayNameToOptionError,
    }),
    ..._checkDeleted({
      messageDictionary,
      addErrorMessage,
      objectToCheck: relatedObjectField,
      descriptorCallback: displayNameDescriptor,
      objectPath: 'relatedObjectField',
      objectErrorTrans: displayNameToOptionError,
    }),
    // find and spread the first error in the specificFieldValue
    ...[
      ...(specificFieldValue?.tagsToAdd || []),
      ...(specificFieldValue?.tagsToRemove || []),
      ...(Array.isArray(specificFieldValue)
        ? specificFieldValue
        : [specificFieldValue]),
    ].reduce((acc, value) => {
      return Object.keys(acc).length
        ? acc
        : _checkDeleted({
            messageDictionary,
            addErrorMessage,
            objectToCheck: value,
            descriptorCallback: nameDescriptor,
            objectPath: 'specificFieldValue',
          });
    }, {}),
  };

  const withOptionWizard = hasOptionsWizard(
    fieldToModify,
    changeTypeValue,
    relatedObjectField
  );

  const initialValidation = {};
  if (withOptionWizard) {
    const targetOptionIds = (fieldToModify.options || []).map(({ id }) => id);
    const sourceOptionIds = (relatedObjectField.options || []).map(
      ({ id }) => id
    );

    fieldValueMappings = fieldValueMappings.map(
      ({ targetValues, sourceValues, ...rest }) => {
        let sourceValuesArray;
        let targetValuesArray;

        if (fieldToModify.fieldType === FIELD_TYPES.Rating.type) {
          sourceValuesArray = sourceValues.map((sourceValue) => ({
            value: sourceValue,
            id: sourceValue,
            name: sourceValue,
            label: sourceValue,
            order: sourceValue,
          }));

          targetValuesArray = targetValues.map((targetValue) => ({
            value: targetValue,
            id: targetValue,
            name: targetValue,
            label: targetValue,
            order: targetValue,
          }));
        } else {
          sourceValuesArray = sourceValues.filter((sourceValue) =>
            sourceOptionIds.includes(sourceValue)
          );

          targetValuesArray = targetValues.filter((targetValue) =>
            targetOptionIds.includes(targetValue)
          );
        }
        return {
          targetValues: targetValuesArray,
          sourceValues: sourceValuesArray,
          ...rest,
        };
      }
    );
    // first deal with stage as it is a special case

    if (
      fieldToModify.fieldType === FIELD_TYPES.Dropdown.type &&
      fieldToModify.isDefault &&
      fieldToModify.name === 'stage' && // stages are special they can't have blank targets
      fieldValueMappings.some(({ targetValues }) => !targetValues.length)
    ) {
      addErrorMessage(`${messageDictionary.targetValueMayNotBeBlank}`);
      initialValidation.errors = [
        `${messageDictionary.targetValueMayNotBeBlank}`,
      ];

      initialValidation.fieldValueMappingsErrors =
        getStageFieldValueErrors(fieldValueMappings);
      initialValidation.fieldValueMappingsValidation =
        getStageFieldValueErrors(fieldValueMappings);
    } else {
      // now check for multiple double blank rows once the stage issue is fixed we can move above the if

      const blankRows = fieldValueMappings.filter(
        ({ sourceValues }) => !sourceValues.length
      );
      if (blankRows.length > 1) {
        addErrorMessage(`${messageDictionary.onlyOneFromValueMayBeLeftAsNull}`);
        initialValidation.errors = [
          `${messageDictionary.onlyOneFromValueMayBeLeftAsNull}`,
        ];

        initialValidation.fieldValueMappingsErrors = getFieldValueErrors(
          fieldValueMappings.slice(1)
        );
        initialValidation.fieldValueMappingsValidation = getFieldValueErrors(
          fieldValueMappings.slice(1)
        );
      }
    }
  }

  try {
    transformedValue = getValueForFlow(fieldToModify, specificFieldValue);
  } catch (error) {
    addErrorMessage(
      `${
        messageDictionary.thereWasanErrorLoadingThe
      } '${fieldToModify?.displayName || messageDictionary.associatedItem}'.`
    );

    transformedValue = null;
  }
  const ftm = deletedWrapper(fieldToModify, (field) => ({
    ...field,
    access: { edit: true, remove: true },
  }));

  return {
    ...apiObject,
    fieldToModify: ftm,
    specificFieldValue: transformedValue,
    changeType: { value: changeTypeValue },
    relatedObject: getRelatedObject(relatedObject),
    automationTargetRelationshipField: getAutomationTargetRelationshipField(
      automationTargetRelationshipField
    ),
    relatedObjectField:
      changeTypeValue === cfvChangeTypeOptionsConst.RELATED_OBJECT_FIELD
        ? getRelatedObjectField(relatedObjectField, primaryRelatedObjectField)
        : null,
    thisRecordField:
      changeTypeValue === cfvChangeTypeOptionsConst.THIS_RECORD_FIELD
        ? getThisRecordField(relatedObjectField, null)
        : null,

    fieldResolution: { value: fieldResolution },
    fieldValueMappings,
    fieldValueMappingsTypes: defaultFieldValueMappingTypes,
    fieldValueMappingsErrors:
      fieldValueMappingsErrorsForFlow(fieldValueMappings),
    fieldValueMappingsValidation:
      fieldValueMappingsErrorsForFlow(fieldValueMappings),
    errors,
    ...initialValidation,
  };
};

export const changeFieldValueDTO = {
  forApi,
  forFlow,
};
