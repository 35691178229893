import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KizenTypography from '../../../app/kizentypo';
import Button from '../../Button';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '..';

export default function BasicModal({
  size,
  heading,
  buttonText: buttonTextProp,
  disabled,
  onHide,
  onConfirm,
  children,
  typeOfContent,
  leftBtn,
  showBorder,
  defaultLeftBtnText: defaultLeftBtnTextProp,
  defaultLeftBtnHandler,
  leftBtnAlign,
  actionBtnColor,
  actionButton,
  ...others
}) {
  const { t } = useTranslation();

  const buttonText = buttonTextProp || t('Save');
  const defaultLeftBtnText = defaultLeftBtnTextProp || t('Cancel');

  const [submitting, setSubmitting] = useState(false);
  const handleClick = async (ev) => {
    try {
      setSubmitting(true);
      await onConfirm(ev);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal size={size} onHide={onHide} {...others}>
      <ModalHeader onClickClose={onHide}>{heading}</ModalHeader>
      <ModalBody typeOfContent={typeOfContent}>
        {typeof children !== 'string' && children}
        {typeof children === 'string' && (
          <KizenTypography>{children}</KizenTypography>
        )}
      </ModalBody>
      <ModalFooter
        showBorder={showBorder}
        actionBtnWithoutSpace={leftBtn && leftBtn.props.variant === 'text'}
        leftBtnAlign={leftBtnAlign}
      >
        {leftBtn &&
          React.cloneElement(leftBtn, {
            children: defaultLeftBtnText,
            onClick: defaultLeftBtnHandler || onHide,
          })}
        {actionButton && (
          <Button
            onClick={handleClick}
            disabled={disabled || submitting}
            color={actionBtnColor}
          >
            {buttonText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

BasicModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  heading: PropTypes.node.isRequired,
  buttonText: PropTypes.node,
  disabled: PropTypes.bool,
  typeOfContent: PropTypes.oneOf(['wizard', 'text', 'default']),
  leftBtn: PropTypes.node,
  showBorder: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  defaultLeftBtnText: PropTypes.string,
  defaultLeftBtnHandler: PropTypes.func,
  leftBtnAlign: PropTypes.oneOf(['right', 'left']),
  actionBtnColor: PropTypes.oneOf(['green', 'red', 'blue']),
  actionButton: PropTypes.bool,
};

BasicModal.defaultProps = {
  buttonText: '',
  disabled: false,
  typeOfContent: 'default',
  leftBtn: <Button variant="text" color="blue" />,
  showBorder: false,
  size: 'small',
  defaultLeftBtnText: '',
  defaultLeftBtnHandler: null,
  leftBtnAlign: 'right',
  actionBtnColor: 'green',
  actionButton: true,
};
