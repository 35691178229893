import React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { gutters } from 'app/spacing';
import { borderTight, grayScale, shadowLight } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { useTranslation } from 'react-i18next';

const PlusButton = styled(IconButton)`
  position: absolute;
  bottom: -15px;
  left: calc(50% - 15px); // centered

  && svg {
    width: auto;
    height: 16px;
  }
`;

const ColumnWrapper = styled.div`
  position: relative;
  background: ${grayScale.white};
  height: 100%;
  ${({ columnWidth = 270 }) => css`
    width: ${columnWidth}px;
  `};
  margin-right: ${gutters.spacing(4)}px;

  &:last-child {
    margin-right: 0;
  }
  .ViewText.DateText input {
    min-width: 0 !important;
  }
  ${borderTight}
  ${shadowLight}
`;

const BaseColumn = ({ onClickPlus, children, ...props }) => {
  const { t } = useTranslation();
  return (
    <ColumnWrapper {...props}>
      {children}
      {onClickPlus && (
        <PlusButton
          sizing="overlay"
          onClick={onClickPlus}
          title={t('Quick Add')}
        >
          <Icon icon="plus" />
        </PlusButton>
      )}
    </ColumnWrapper>
  );
};

BaseColumn.propTypes = {
  onClickPlus: PropTypes.func.isRequired,
};

export default BaseColumn;
