import { useMemo } from 'react';

import { getBusinessClientObject } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';

import { format } from 'date-fns';
import { Link } from 'app/typography';
import {
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { AccessSwitch } from '../AccessSwitch';
import { ASSOCIATION_SOURCE_TYPES } from 'components/Wizards/CustomObject/constants';

const NO_VALUE = '—';

const layoutPropsByColumnIdDirect = {
  fullName: {
    width: '180px',
    padding: '30px',
  },
  firstActivityTimestamp: {
    width: '150px',
    padding: '30px',
  },
  lastActivityTimestamp: {
    width: '150px',
    padding: '30px',
  },
  numActivities: {
    width: '120px',
    padding: '30px',
  },
  roles: {
    width: '230px',
    padding: '30px',
  },
  hasRecordAccess: {
    width: '150px',
  },
};

const layoutPropsByColumnIdWithInherited = {
  fullName: {
    width: '180px',
    padding: '20px',
  },
  inheritedFrom: {
    width: '120px',
    padding: '20px',
  },
  firstActivityTimestamp: {
    width: '145px',
    padding: '20px',
  },
  lastActivityTimestamp: {
    width: '145px',
    padding: '20px',
  },
  numActivities: {
    width: '110px',
    padding: '20px',
  },
  roles: {
    width: '170px',
    padding: '20px',
  },
  hasRecordAccess: {
    width: '140px',
  },
};

const layoutPropsByColumnIdMobile = {
  fullName: {
    minWidth: '150px',
    padding: '20px',
  },
  lastActivityTimestamp: {
    width: '100px',
  },
};

export const useColumns = (
  { isMobile, customObject, entity, canAddTeamMember },
  t
) => {
  const preReleaseFeature = usePreReleaseFeatures();
  const includeInheritedFrom =
    customObject?.associationSource !== ASSOCIATION_SOURCE_TYPES.direct;

  const layoutPropsByColumnId = includeInheritedFrom
    ? layoutPropsByColumnIdWithInherited
    : layoutPropsByColumnIdDirect;

  const clientObject = useSelector(getBusinessClientObject);

  return useMemo(
    () =>
      isMobile
        ? [
            {
              id: 'spacer-start',
              headCell: <SizedCell width="15px" />,
              cell: <SizedCell width="15px" />,
              format: () => <span />,
            },
            {
              id: 'fullName',
              headCell: (
                <SortableHeadCell
                  by="full_name"
                  label={t('Team Member')}
                  {...layoutPropsByColumnIdMobile.fullName}
                />
              ),
              cell: <SizedCell {...layoutPropsByColumnIdMobile.fullName} />,
              format: (fullName, association) => {
                return preReleaseFeature ? (
                  <TextEllipsisWithTooltip
                    as={Link}
                    type={fullName && 'link'}
                    to={
                      fullName &&
                      `/team-member/${association.employeeId}/timeline`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {fullName}
                  </TextEllipsisWithTooltip>
                ) : (
                  <TextEllipsisWithTooltip>{fullName}</TextEllipsisWithTooltip>
                );
              },
            },
            {
              id: 'lastActivityTimestamp',
              headCell: (
                <SortableHeadCell
                  by="last_activity_timestamp"
                  label={t('Last Activity')}
                  {...layoutPropsByColumnIdMobile.lastActivityTimestamp}
                  numeric
                />
              ),
              cell: (
                <SizedCell
                  {...layoutPropsByColumnIdMobile.lastActivityTimestamp}
                />
              ),
              format: (data) => (
                <TextEllipsisWithTooltip>
                  {data && format(data, 'MM/DD/YYYY')}
                </TextEllipsisWithTooltip>
              ),
            },
            {
              id: 'spacer-end',
              headCell: <SizedCell width={`${15 - 4}px`} />,
              cell: <SizedCell width={`${15 - 4}px`} />,
              format: () => <span />,
            },
          ]
        : [
            {
              id: 'spacer-start',
              headCell: <SizedCell width="15px" />,
              cell: <SizedCell width="15px" />,
              format: () => <span />,
            },
            {
              id: 'fullName',
              headCell: (
                <SortableHeadCell
                  by="full_name"
                  label={t('Team Member')}
                  {...layoutPropsByColumnId.fullName}
                />
              ),
              cell: <SizedCell {...layoutPropsByColumnId.fullName} />,
              format: (fullName, association) => {
                return preReleaseFeature ? (
                  <TextEllipsisWithTooltip
                    as={Link}
                    type={fullName && 'link'}
                    to={
                      fullName &&
                      `/team-member/${association.employeeId}/timeline`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {fullName}
                  </TextEllipsisWithTooltip>
                ) : (
                  <TextEllipsisWithTooltip>{fullName}</TextEllipsisWithTooltip>
                );
              },
            },
            includeInheritedFrom && {
              id: 'associationSource',
              headCell: (
                <SortableHeadCell
                  by="association_source__record_display_name"
                  label={t('Inherited From')}
                  {...layoutPropsByColumnId.inheritedFrom}
                />
              ),
              cell: <SizedCell {...layoutPropsByColumnId.inheritedFrom} />,
              format: (data) => {
                const path = data?.customObjectId
                  ? data?.customObjectId === clientObject.id
                    ? `/client/${data.recordId}/details`
                    : `/custom-objects/${data.customObjectId}/${data.recordId}/details`
                  : null;

                return (
                  <TextEllipsisWithTooltip
                    as={data ? 'link' : 'text'}
                    type={data ? 'link' : 'text'}
                    to={path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data ? data?.recordDisplayName : null}
                  </TextEllipsisWithTooltip>
                );
              },
            },
            {
              id: 'firstActivityTimestamp',
              headCell: (
                <SortableHeadCell
                  by="first_activity_timestamp"
                  label={t('First Activity')}
                  {...layoutPropsByColumnId.firstActivityTimestamp}
                  numeric
                />
              ),
              cell: (
                <SizedCell {...layoutPropsByColumnId.firstActivityTimestamp} />
              ),
              format: (data) =>
                data ? (
                  <DateTimeInputInline value={data} readOnly showDateTooltip />
                ) : (
                  NO_VALUE
                ),
            },
            {
              id: 'lastActivityTimestamp',
              headCell: (
                <SortableHeadCell
                  by="last_activity_timestamp"
                  label={t('Last Activity')}
                  {...layoutPropsByColumnId.lastActivityTimestamp}
                  numeric
                />
              ),
              cell: (
                <SizedCell {...layoutPropsByColumnId.lastActivityTimestamp} />
              ),
              format: (data) =>
                data ? (
                  <DateTimeInputInline value={data} readOnly showDateTooltip />
                ) : (
                  NO_VALUE
                ),
            },
            {
              id: 'numActivities',
              headCell: (
                <SortableHeadCell
                  by="num_activities"
                  label={t('Total Activity')}
                  {...layoutPropsByColumnId.numActivities}
                  numeric
                />
              ),
              cell: <SizedCell {...layoutPropsByColumnId.numActivities} />,
              format: (numActivities) => {
                return (
                  <TextEllipsisWithTooltip>
                    {numActivities}
                  </TextEllipsisWithTooltip>
                );
              },
            },
            {
              id: 'roles',
              headCell: (
                <SortableHeadCell
                  label={t('Roles')}
                  {...layoutPropsByColumnId.roles}
                />
              ),
              cell: <SizedCell {...layoutPropsByColumnId.roles} />,
              format: (roles) => {
                return (
                  <TextEllipsisWithTooltip>
                    {(roles || []).join(', ')}
                  </TextEllipsisWithTooltip>
                );
              },
            },
            {
              id: 'hasRecordAccess',
              headCell: (
                <SortableHeadCell
                  label={t('"My Record" Access')}
                  {...layoutPropsByColumnId.hasRecordAccess}
                  hasSorting={false}
                />
              ),
              cell: <SizedCell {...layoutPropsByColumnId.hasRecordAccess} />,
              format: (access, association) => {
                const { associationSource } = association;

                return (
                  <AccessSwitch
                    access={!!access}
                    association={association}
                    customObjectId={customObject?.id}
                    entityId={entity?.id}
                    disabled={
                      !canAddTeamMember ||
                      (includeInheritedFrom && associationSource)
                    }
                    tooltip={
                      includeInheritedFrom && associationSource
                        ? t('Can only be edited from the related record.')
                        : null
                    }
                    tooltipPlacement="right"
                  />
                );
              },
            },
            {
              id: 'spacer-end',
              // Leave room for persistent scroll bar
              headCell: <SizedCell minWidth={`${15 - 4}px`} />,
              cell: <SizedCell minWidth={`${15 - 4}px`} />,
              format: () => <span />,
            },
          ].filter(Boolean),
    [
      isMobile,
      t,
      layoutPropsByColumnId,
      includeInheritedFrom,
      preReleaseFeature,
      clientObject.id,
      customObject?.id,
      entity?.id,
      canAddTeamMember,
    ]
  );
};
