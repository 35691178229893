import { FC, useCallback, useEffect, useMemo, useReducer } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { isMobile, useWindowSize } from 'app/spacing';
import { toastVariant, useToast } from '__components/ToastProvider';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { SMART_CONNECTORS } from 'queries/query-keys';
import SmartConnectorService from 'services/SmartConnectorService';
import TeamMemberService from 'services/TeamMemberService';
import { SmartConnector, SmartConnectorPayload } from './types';
import ConfirmDeletionModal from '__components/Modals/presets/ConfirmDeletion';
import { SmartConnectorHistoryModal } from './SmartConnectorHistoryModal';
import useModal from '__components/Modals/useModal';
import { DesktopLayout } from './DesktopLayout';
import { MobileContainer } from './styles';
import { KizenTypography } from '__app/typography';
import { SmartConnectorsProvider } from './context';
import { SmartConnectorType } from './types';
import { useRunSmartConnectorModal } from './RunSmartConnectorModal/useRunSmartConnectorModal';
import { RunSmartConnectorModal } from './RunSmartConnectorModal';
import { useAsync } from 'react-use';
import { getOrderingParam } from 'utility/SortingHelpers';

type PageProps = {
  title: (t: TFunction) => string;
};

type ReducerState = {
  historySmartConnector: SmartConnector | null;
  smartConnectorIdForDeletion: string | null;
  pageNumber: number;
  pageSize: number;
  search: string;
  sort: string;
};

type PageConfig = Pick<ReducerState, 'pageSize' | 'search' | 'sort'>;

const defaultPageCOnfig: PageConfig = {
  pageSize: 25,
  search: '',
  sort: 'name',
};

const defaultSettings: ReducerState = {
  historySmartConnector: null,
  smartConnectorIdForDeletion: null,
  pageNumber: 1,
  ...defaultPageCOnfig,
};

type ReducerAction = {
  type:
    | 'setHistorySmartConnector'
    | 'setSmartConnectorIdForDeletion'
    | 'setPageNumber'
    | 'setPageSize'
    | 'setSearch'
    | 'setSort'
    | 'init';
  payload: any;
};

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  let nextState: ReducerState = state;

  const { type, payload } = action;

  switch (type) {
    case 'setHistorySmartConnector':
      nextState = { ...state, historySmartConnector: action.payload };
      break;
    case 'setSmartConnectorIdForDeletion':
      nextState = { ...state, smartConnectorIdForDeletion: action.payload };
      break;
    case 'setPageNumber':
      nextState = { ...state, pageNumber: payload };
      break;
    case 'setPageSize':
      nextState = { ...state, pageNumber: 1, pageSize: payload };
      break;
    case 'setSearch':
      nextState = { ...state, pageNumber: 1, search: payload };
      break;
    case 'setSort':
      nextState = { ...state, pageNumber: 1, sort: payload };
      break;
    case 'init':
      nextState = { ...state, ...payload, pageNumber: 1 };
      break;
  }

  return nextState;
};

const useSmartConnectors = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const [showToast] = useToast();

  const [state, smartConnectorDispatch] = useReducer(reducer, defaultSettings);

  const [historyModalProps, , historyModal] = useModal({
    handleHide: () =>
      smartConnectorDispatch({
        type: 'setHistorySmartConnector',
        payload: null,
      }),
  });

  const { runSmartConnectorModalProps, showRunSmartConnectorModal } =
    useRunSmartConnectorModal();

  const queryKey = useMemo(
    () =>
      SMART_CONNECTORS.ALL(
        state.pageSize,
        state.pageNumber,
        state.search,
        state.sort
      ),
    [state.pageSize, state.pageNumber, state.search, state.sort]
  );

  const { value: pageConfig, loading: loadingPageConfig } =
    useAsync(async () => {
      const config = await TeamMemberService.getPageConfig(
        'smart_connector_list',
        defaultPageCOnfig
      );
      smartConnectorDispatch({
        type: 'init',
        payload: config,
      });
      return config;
    }, []);

  const pageConfigFetched = !!pageConfig;

  useEffect(() => {
    if (pageConfigFetched) {
      TeamMemberService.setPageConfig('smart_connector_list', {
        pageSize: state.pageSize,
        search: state.search,
        sort: state.sort,
      });
    }
  }, [state.pageSize, state.search, state.sort, pageConfigFetched]);

  const { data, isFetching, isLoading } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      return SmartConnectorService.getSmartConnectors({
        page: state.pageNumber,
        pageSize: state.pageSize,
        search: state.search,
        ordering: state.sort,
      });
    },
    enabled: pageConfigFetched,
    // Disable the built-in refetching mechanism because we don't want unsaved data being errased
    refetchOnMount: 'always',
    keepPreviousData: true,
  });

  const loadingSmartConnectors = loadingPageConfig || isFetching || isLoading;

  const { smartConnectors, totalSmartConnectors } = useMemo(() => {
    const connectionTypeNames: Record<SmartConnectorType, string> = {
      spreadsheet: t('Spreadsheet Style Upload (Optional SQL)'),
      polling: t('Polling'),
      direct_api: t('Direct API'),
    };

    if (data?.results) {
      const results = data.results.map((smartConnector: SmartConnector) => {
        return {
          ...smartConnector,
          connector_type_name:
            connectionTypeNames[smartConnector.connector_type] ||
            smartConnector.connector_type,
        };
      });
      return {
        smartConnectors: results,
        totalSmartConnectors: data?.count || 0,
      };
    }
    return {
      smartConnectors: [],
      totalSmartConnectors: 0,
    };
  }, [data, t]);

  const handleChangeSearch = useCallback(
    (search: string) => {
      smartConnectorDispatch({
        type: 'setSearch',
        payload: search,
      });
    },
    [smartConnectorDispatch]
  );

  const handleChangeSort = useCallback(
    (column: string, direction: string) => {
      smartConnectorDispatch({
        type: 'setSort',
        payload: getOrderingParam({ column, direction }),
      });
    },
    [smartConnectorDispatch]
  );

  const handleChangePageSize = useCallback(
    (pageSize: number) => {
      smartConnectorDispatch({
        type: 'setPageSize',
        payload: pageSize,
      });
    },
    [smartConnectorDispatch]
  );

  const handleChangePageNumber = useCallback(
    (pageNumber: number) =>
      smartConnectorDispatch({
        type: 'setPageNumber',
        payload: pageNumber,
      }),
    [smartConnectorDispatch]
  );

  // deletion
  const deleteMutation = useCallback(async (id: any) => {
    return SmartConnectorService.deleteSmartConnector(id, {
      skipErrorBoundary: true,
    });
  }, []);

  const { mutate: deleteSmartConnector } = useMutation({
    mutationFn: deleteMutation,
    onMutate: async (idToDelete: any) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousData: any = queryClient.getQueryData(queryKey);
      const updatedResults = previousData.results.filter(
        (smartConnector: SmartConnector) => {
          return smartConnector.id !== idToDelete;
        }
      );

      const next = {
        ...previousData,
        results: updatedResults,
      };

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, next);

      // Return a context with the previousData
      return { previousData };
    },
    // If the mutation fails, use the context we returned above
    onError: (_err, _newData, context) => {
      showToast({
        message: t('Failed to delete the SmartConnector'),
        variant: toastVariant.FAILURE,
      });
      queryClient.setQueryData(queryKey, context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const handleDeleteSmartConnector = useCallback(() => {
    deleteSmartConnector(state.smartConnectorIdForDeletion);
    smartConnectorDispatch({
      type: 'setSmartConnectorIdForDeletion',
      payload: null,
    });
  }, [deleteSmartConnector, state, smartConnectorDispatch]);

  // duplication
  const duplicateMutation = useCallback(async (id: any) => {
    return SmartConnectorService.duplicateSmartConnector(id, {
      skipErrorBoundary: true,
    });
  }, []);

  const { mutate: duplicateSmartConnector } = useMutation({
    mutationFn: duplicateMutation,
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(queryKey);

      // Return a context with the previousData
      return { previousData };
    },
    // If the mutation fails, use the context we returned above
    onError: (_err, _newData, context) => {
      showToast({
        message: t('Failed to duplicate the SmartConnector'),
        variant: toastVariant.FAILURE,
      });
      queryClient.setQueryData(queryKey, context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const handleDuplicateSmartConnector = useCallback(
    async (id: string | null) => {
      return duplicateSmartConnector(id);
    },
    [duplicateSmartConnector]
  );

  // patch
  const patchMutation = useCallback(
    ({ id, patch }: { id: any; patch: any }) => {
      return SmartConnectorService.updateSmartConnector(id, patch, {
        skipErrorBoundary: true,
      });
    },
    []
  );

  const { mutateAsync: asyncPatchSmartConnector } = useMutation({
    mutationFn: patchMutation,

    onMutate: async (payload: any) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousData: SmartConnectorPayload = queryClient.getQueryData(
        queryKey
      ) as SmartConnectorPayload;

      const updatedResults = previousData.results.map((sc: SmartConnector) =>
        sc.id === payload.id ? { ...sc, ...payload.patch } : sc
      );

      const next = {
        ...previousData,
        results: updatedResults,
      };

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, next);

      // Return a context with the previousData
      return { previousData };
    },
    // If the mutation fails, use the context we returned above
    onError: (_err: AxiosError, _newData, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const handlePatchSmartConnector = useCallback(
    async (id: string, patch: any) => {
      try {
        return await asyncPatchSmartConnector({ id, patch });
      } catch (err: any) {
        let error = err;
        if (err.response && err.response.data && err.response.data.errors) {
          const errors: any = Object.entries(err.response.data.errors);
          if (errors && errors.length && errors[0] && errors[0][1]) {
            // we show only 1st error
            error = new Error(errors[0][1]);
          }
        }
        throw error;
      }
    },
    [asyncPatchSmartConnector]
  );

  return {
    smartConnectors,
    loadingSmartConnectors,
    totalSmartConnectors,
    smartConnectorDispatch,
    pageNumber: state.pageNumber,
    handleChangePageNumber,
    pageSize: state.pageSize,
    handleChangePageSize,
    search: state.search,
    handleChangeSearch,
    sort: state.sort,
    handleChangeSort,
    smartConnectorIdForDeletion: state.smartConnectorIdForDeletion,
    historySmartConnector: state.historySmartConnector,
    historyModalProps,
    runSmartConnectorModalProps,
    showRunSmartConnectorModal,
    historyModal,
    handleDeleteSmartConnector,
    handleDuplicateSmartConnector,
    handlePatchSmartConnector,
  };
};

const SmartConnectors: FC<PageProps> = ({ title }) => {
  const { t } = useTranslation();

  const { width } = useWindowSize() || {};

  useSetTitleOnLoad(title(t));

  const context = useSmartConnectors();
  return (
    <SmartConnectorsProvider value={context}>
      <div data-qa="smart-connectors">
        {!isMobile(width) ? (
          <DesktopLayout />
        ) : (
          <MobileContainer>
            <KizenTypography>
              {t(
                'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
              )}
            </KizenTypography>
          </MobileContainer>
        )}
      </div>
      <ConfirmDeletionModal
        show={Boolean(context.smartConnectorIdForDeletion)}
        onConfirm={() => context.handleDeleteSmartConnector()}
        onHide={() =>
          context.smartConnectorDispatch({
            type: 'setSmartConnectorIdForDeletion',
            payload: null,
          })
        }
      >
        {t(
          'This will permanently delete the SmartConnector and uploads will no longer be able to be processed through these steps.'
        )}
      </ConfirmDeletionModal>
      {context.historySmartConnector ? (
        <SmartConnectorHistoryModal
          smartConnector={context.historySmartConnector}
          modalProps={context.historyModalProps}
        />
      ) : null}
      {context.runSmartConnectorModalProps?.show ? (
        <RunSmartConnectorModal {...context.runSmartConnectorModalProps} />
      ) : null}
    </SmartConnectorsProvider>
  );
};

export default SmartConnectors;
