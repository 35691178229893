import { useCallback, useMemo, useRef, useState } from 'react';
import format from 'date-fns/format';
import { colorsButton } from 'app/colors';
import { breakpoints, useBreakpoint } from 'app/spacing';
import BasicTable from 'components/Tables/Basic';
import {
  TRow,
  TextEllipsisWithTooltip,
  TextEllipsis,
} from 'components/Kizen/Table';
import {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
} from 'components/Layout/ListCard';
import IconButton from 'components/Kizen/IconButton';
import ColorLabel from 'components/Fields/ColorLabel';
import TableHeader from '../TableHeader';
import {
  getMessageColumns,
  StyledEyeIcon,
  WrapperStatus,
  messageStatusColors,
} from '../columns';
import {
  StyledListCardCell,
  TableScrollWrapper,
  StyledWrapper,
} from '../styles';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Loader from 'components/Kizen/Loader';
import { useSelector } from 'react-redux';
import SentMessageModalQuered from './SentMessageModalQuered';
import { layers } from 'app/spacing';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import { TableRow } from 'components/Tables/Query/TableRow';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const StyledTableScrollContainer = styled(TableScrollContainer)`
  & .BodyForSync {
    flex-direction: column;
  }
`;
const ITEMS_BUFFER = 20;
const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 52;

const StyledLoader = styled(Loader)`
  position: relative;
  ${(props) =>
    props.noPadding &&
    `
  padding: 20px 0 !important; // :( Sorry, Loader component sets styles inline
`}
  z-index: ${layers.toast};
`;

const MessageHistoryTable = ({
  data,
  messagesSort,
  isFetchingNextPage,
  isFetching,
  handleSortMessages,
  hasNextPage,
  fetchNextPage,
  status,
}) => {
  const { t } = useTranslation();
  const businessName = useSelector(
    ({ authentication }) => authentication?.chosenBusiness?.name ?? ''
  );
  const [chosenMessage, setChosenMessage] = useState(null);
  const isMobile = useBreakpoint(breakpoints.md);

  const handleClickMessage = useCallback(async (message) => {
    setChosenMessage(message);
  }, []);

  const messageColumns = useMemo(
    () =>
      getMessageColumns(
        {
          onClickMessage: handleClickMessage,
        },
        t
      ),
    [handleClickMessage, t]
  );

  const messageHeadData = useMemo(
    () => ({
      meta: {
        sort: messagesSort,
        onSort: (column, direction) =>
          handleSortMessages({ column, direction }),
      },
    }),
    [messagesSort, handleSortMessages]
  );

  const wrapperRef = useRef(null);

  const tableScroll = useInfinityScroll({
    wrapperElement: wrapperRef.current,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    defaultHeight: ROW_HEIGHT,
    itemsBuffer: ITEMS_BUFFER,
    shouldFetchOnMount: true,
    headerHeight: HEADER_HEIGHT,
    component: 'table',
  });
  const { horizonList, visibleItems, handleItemMounted } = tableScroll;
  const hideModal = () => setChosenMessage(null);
  const getTable = () =>
    isMobile ? (
      <TableScrollWrapper compactHeader shadow bottom>
        <StyledWrapper>
          <ListCardHeader
            compactHeader
            stickyHeader
            action={<div style={{ width: 15 }} />}
            withScrollbarPadding
            className="sticky-header-with-shadow"
          >
            <ListCardCell style={{ minHeight: '42px' }}>
              <TextEllipsis as="span" weight="bold">
                {t('Message')}
              </TextEllipsis>
              <ListCardSortButton
                sort={messagesSort}
                onChangeSort={(direction) =>
                  handleSortMessages({ column: 'message', direction })
                }
              />
            </ListCardCell>
          </ListCardHeader>
          {horizonList.items.map(({ item: mess }) => (
            <ListCardItem
              key={mess.id}
              style={{ minHeight: '64px' }}
              action={
                <IconButton
                  sizing="dense"
                  title={t('Show Message')}
                  onClick={() => setChosenMessage(mess)}
                  color={colorsButton.iconGray}
                >
                  <StyledEyeIcon icon="visible" />
                </IconButton>
              }
              withScrollbarPadding
            >
              <ListCardRow>
                <StyledListCardCell>
                  <TextEllipsisWithTooltip
                    type="link"
                    onClick={(e) => {
                      e.preventDefault();
                      setChosenMessage(mess);
                    }}
                  >
                    {mess.message.preview}
                  </TextEllipsisWithTooltip>
                </StyledListCardCell>
              </ListCardRow>
              <ListCardRow>
                <ListCardCell>
                  {messageStatusColors[mess.status] ? (
                    <WrapperStatus>
                      <ColorLabel color={messageStatusColors[mess.status]}>
                        {mess.displayStatus}
                      </ColorLabel>
                    </WrapperStatus>
                  ) : (
                    <div />
                  )}
                </ListCardCell>
                <StyledListCardCell>
                  <TextEllipsis>
                    {mess.sentAt && format(mess.sentAt, 'MM/DD/YYYY')}
                  </TextEllipsis>
                </StyledListCardCell>
              </ListCardRow>
            </ListCardItem>
          ))}
        </StyledWrapper>
      </TableScrollWrapper>
    ) : (
      <>
        <TableHeader title={t('Message History')} />
        <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
          <ScrollContainerBlocker />
        </ScrollContainerBlockerWrapper>
        <StyledTableScrollContainer ref={wrapperRef} bottom fixedHeight>
          <BasicTable
            stickyHeader
            loading={isFetchingNextPage || isFetching}
            head={<TRow head columns={messageColumns} data={messageHeadData} />}
          >
            {visibleItems.map(({ item: message }, index) => (
              <TableRow
                key={message.id}
                columns={messageColumns}
                onMount={handleItemMounted}
                item={message}
                index={index}
              />
            ))}
          </BasicTable>
          <StyledLoader
            loading={isFetchingNextPage || isFetching}
            noPadding={true}
          />
        </StyledTableScrollContainer>
      </>
    );

  return (
    <>
      {getTable()}
      {chosenMessage && (
        <SentMessageModalQuered
          t={t}
          businessName={businessName}
          message={chosenMessage}
          onHide={hideModal}
        />
      )}
    </>
  );
};

export default MessageHistoryTable;
