import PropTypes from 'prop-types';

const common = {
  className: PropTypes.string,
  children: PropTypes.array,
};
const date = PropTypes.instanceOf(Date).isRequired;
const calendarMonth = PropTypes.number.isRequired;

export const MonthCalendarCellProps = {
  ...common,
  date,
  calendarMonth,
  calendarYear: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export const ExpandableMonthCalendarCellProps = {
  ...MonthCalendarCellProps,
  expanded: PropTypes.bool.isRequired,
  rowWidth: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  expansion: PropTypes.arrayOf(PropTypes.number).isRequired, // 2-tuple [x, y]
  translation: PropTypes.arrayOf(PropTypes.number), // 2-tuple [x, y]
};

export const MonthCalendarCellHeaderProps = {
  ...common,
  date,
  calendarMonth,
};
