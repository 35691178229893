import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { resetError } from 'store/errors/index';

import { NOBUSINESS_ERROR_CODE } from 'services/AxiosService';

import { LinkButton } from 'components/CatchError/LinkButton';
import { getSupportEmail } from 'components/CatchError/getSupportEmail';
import { ErrorMessage } from 'components/ErrorMessage';
import styled from '@emotion/styled';

const Actions = styled.p`
  padding-bottom: 20px;
`;

export const getErrorComoponents = (code, t) => {
  const errors = {
    [NOBUSINESS_ERROR_CODE]: {
      title: t('You Are No Longer Associated With Any Businesses'),
      text: (
        <KizenTypography as="p" className="not_found_long-text">
          {t(
            'You do not have access to a business. If you feel this is in error, please contact your system administrator and ensure you are a verified team member. If the problem persists, please contact Kizen support.'
          )}
        </KizenTypography>
      ),
      supportEmail: getSupportEmail(t),
      button: <LinkButton route={'/logout'} text={t('Logout')} />,
    },
    403: {
      title: t('Permissions needed'),
      text: (
        <KizenTypography as="p" className="not_found_long-text">
          {t(
            'The action you tried to complete requires special permissions. If you believe you should have those permissions please contact your Kizen Administrator.'
          )}
        </KizenTypography>
      ),
      supportEmail: getSupportEmail(t),
      button: (
        <Actions>
          <LinkButton route={'/'} text={t('Go Back')} />{' '}
          <KizenTypography as="span">{t('or')}</KizenTypography>{' '}
          <LinkButton route={'/logout'} text={t('Logout')} />
        </Actions>
      ),
    },
    404: {
      title: t('No Access'),
      text: (
        <KizenTypography as="p" className="not_found_long-text">
          {t(
            'The page you are trying to reach either does not exist or you do not have permissions to view it. Please contact your Kizen Administrator if you believe that this is in error.'
          )}
        </KizenTypography>
      ),
      supportEmail: getSupportEmail(t),
    },
    default: {
      title: t('An error occurred'),
      text: (
        <KizenTypography as="p" className="not_found_long-text">
          {t(
            "We're unable to complete the action at this time. Please try again in a few minutes or contact "
          )}
          <KizenTypography
            type="link-mail"
            size="micro"
            weight="bold"
            href="mailto:support@kizen.com"
          >
            support@kizen.com
          </KizenTypography>
        </KizenTypography>
      ),
      supportEmail: null,
    },
  };
  return errors[code] || errors.default;
};

const CatchError = ({ onReset, error }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    return history.listen(() => {
      dispatch(resetError());
      onReset?.();
    });
  }, [dispatch, onReset, history]);

  const errorComoponents = useMemo(
    () => getErrorComoponents(error.code, t),
    [error.code, t]
  );

  return (
    <ErrorMessage
      title={errorComoponents.title}
      text={errorComoponents.text}
      button={
        errorComoponents.button || (
          <Button
            color="blue"
            className="not_found_button"
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            {t('Dashboard')}
          </Button>
        )
      }
      supportEmail={errorComoponents.supportEmail}
      code={
        error.code ? (
          <KizenTypography as="p" className="not_found_text">
            {t('Error code')}: {error.code}
          </KizenTypography>
        ) : (
          <KizenTypography as="p" className="not_found_text">
            {t('General error')}
          </KizenTypography>
        )
      }
    />
  );
};

export default CatchError;

CatchError.propTypes = {
  onReset: PropTypes.func,
};
