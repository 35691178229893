import { combineReducers } from '@reduxjs/toolkit';
import {
  authenticationHandlers,
  authenticationInitial,
} from './authentication';
import {
  CHOOSE_BUSINESS_SUCCESS,
  LOGOUT_SUCCESS,
} from './authentication/authenticationAction';
import {
  filterMetaDataHandlers,
  filterMetaDataInitial,
} from './filterMetaData';

// reducers
import { reducer as contactPageReducer } from './contactsPage/contacts.redux';
import { reducer as formsPageReducer } from './formsPage/forms.redux';
import { reducer as recordsPageReducer } from './customObjectsRecordsPage/records.redux';
import { reducer as automationPageReducer } from './automationsPage/automations.redux';
import { reducer as surveysPageReducer } from './surveysPage/surveys.redux';
import { reducer as activitiesPageReducer } from './activitiesPage/activities.redux';
import { reducer as customObjectsPageReducer } from './customObjectsPage/customObjects.redux';
import { reducer as customObjectsSettingsPageReducer } from './customObjectsSettingsPage/customObjectsSettings.redux';
import { reducer as pageBuilderReducer } from './pageBuilder/pageBuilder.redux';
import { errorsHandler, errorsInitial } from './errors';
import { reducer as toastProviderReducer } from './toastProvider/toastProvider.redux';
import { reducer as libraryPageReducer } from './libraryPage/library.redux';
import { reducer as recordDetailPageReducer } from './recordDetailPage/record.redux';
import { reducer as adManagerReducer } from './adManagerPage/adManager.redux';
import { reducer as modalControlReducer } from './modalControl/modalControl.redux';

const hasProperty = (obj, prop) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) =>
    hasProperty(handlers, action.type)
      ? handlers[action.type](state, action)
      : state;

const appReducer = combineReducers({
  authentication: createReducer(authenticationInitial, authenticationHandlers),
  contactPage: contactPageReducer,
  formsPage: formsPageReducer,
  automationPage: automationPageReducer,
  surveysPage: surveysPageReducer,
  activitiesPage: activitiesPageReducer,
  customObjectsPage: customObjectsPageReducer,
  recordsPage: recordsPageReducer,
  recordDetailPage: recordDetailPageReducer,
  customObjectsSettingsPage: customObjectsSettingsPageReducer,
  pageBuilder: pageBuilderReducer,
  libraryPage: libraryPageReducer,
  adManagerPage: adManagerReducer,
  errors: createReducer(errorsInitial, errorsHandler),
  toastProvider: toastProviderReducer,
  modalControl: modalControlReducer,
  filterMetaData: createReducer(filterMetaDataInitial, filterMetaDataHandlers),
});

const rootReducer = (state, action) => {
  // Ensure the store is completely reset on logout
  // modified authenticationInitial is necessary to prevent the
  // app from mistakenly thinking its rehydrating

  if (action.type === LOGOUT_SUCCESS) {
    const { isShowInactivityMessage = false, isShowTimeoutMessage = false } =
      state.authentication;
    state = {
      authentication: {
        ...authenticationInitial,
        rehydrating: false,
        isShowInactivityMessage,
        isShowTimeoutMessage,
      },
    };
  }

  // Clear all non-authentication state, ensuring that store
  // doesn't contain any data from the previously selected business
  if (action.type === CHOOSE_BUSINESS_SUCCESS) {
    state = { authentication: state.authentication };
  }

  return appReducer(state, action);
};

export default rootReducer;
