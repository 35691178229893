import React, { forwardRef, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import Color from 'color';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import { SketchPicker } from 'react-color';
import {
  colorsPrimary,
  colorsSecondary,
  grayScale,
  shadowLight,
} from 'app/colors';
import { layers } from 'app/spacing';
import { applyRef } from 'components/Inputs/props';

const StyledColorPickerPopover = styled.div`
  position: absolute;
  z-index: ${layers.content(0, 2)};
  top: 100%;
  left: ${({ align }) =>
    align === 'center' ? '-110px' : align === 'right' ? '-209px' : '-10px'};
  ${shadowLight}
  border-radius: 4px;
  & .sketch-picker {
    border: 1px solid ${grayScale.medium};
    border-radius: 4px;
    box-shadow: none !important;
    margin-left: 10px;
  }
`;

export const RedX = ({ width, height, scale = 1 }) => (
  <svg viewBox="0 0 12 12" width={width} height={height}>
    <defs>
      <line
        id="redline"
        x1="-0.5"
        y1="6"
        x2="12.5"
        y2="6"
        stroke="red"
        strokeLinecap="round"
      />
    </defs>
    <use
      xlinkHref="#redline"
      style={{
        transformOrigin: 'center',
        transform: `scale(${scale}) rotate(45deg)`,
      }}
    />
    <use
      xlinkHref="#redline"
      style={{
        transformOrigin: 'center',
        transform: `scale(${scale}) rotate(-45deg)`,
      }}
    />
  </svg>
);

const transparentPreviewRedX = <RedX width={24} height={24} scale={1.08} />;

const StyledSketchPicker = styled(SketchPicker)`
  // Hides the default Checkboard component. We provide our own component to render in presetColors below.
  div[title='transparent'] > div {
    display: none;
  }
`;

const presetColors = [
  colorsPrimary.blue.dark,
  colorsSecondary.aqua.dark,
  colorsPrimary.green.dark,
  colorsSecondary.red.dark,
  colorsSecondary.tangerine.dark,
  colorsPrimary.orange.dark,
  colorsPrimary.purple.dark,
  colorsSecondary.magenta.dark,
  colorsSecondary.brown.dark,
  '#C45100',
  grayScale.black,
  grayScale.dark,
  grayScale.mediumDark,
  grayScale.mediumLight,
  grayScale.white,
  {
    // These attributes are a non-standard way of consuming react-color's api. The docs say to
    // provide color objects with `color` and `title` attributes (https://casesandberg.github.io/react-color/#api-individual).
    // These objects are spread in SketchPresetColors (https://github.com/casesandberg/react-color/blob/master/src/components/sketch/SketchPresetColors.js#L54)
    // which is what allows us to provide a child component.
    // Note: StyledSketchPicker targets the default Checkboard component that is normally shown for
    //   color = transparent and hides it.
    children: <RedX width={16} height={16} />,
    color: 'transparent',
  },
];

const getColorProp = (colorString) => {
  try {
    const { model, color, valpha } = Color(colorString);
    if (model === 'rgb') {
      const [r, g, b] = color;
      return { r, g, b, a: valpha };
    }
    if (model === 'hsl') {
      const [h, s, l] = color;
      return { h, s, l, a: valpha };
    }
    return '';
  } catch (error) {
    return '';
  }
};

const isTransparent = ({ r, g, b, a } = {}) => {
  return r === 0 && g === 0 && b === 0 && a === 0;
};

const useHandleChange = (fn, init) => {
  const previous = useRef(getColorProp(init));

  return useCallback(
    (value) => {
      if (fn) {
        const { r, g, b, a } = value.rgb;
        // clicking on the 'swatch' does not update the alpha value - force alpha to
        // 1 when changing from transparent to another color so the preview has color
        const alpha =
          isTransparent(previous.current) && !isTransparent(value.rgb) ? 1 : a;
        fn(`rgba(${r},${g},${b},${alpha})`, value);
        previous.current = value.rgb;
      }
    },
    [fn]
  );
};

const ColorPicker = forwardRef(
  (
    {
      handleClose,
      align,
      color,
      onChange,
      onChangeComplete,
      className,
      clickAwayEvents, // https://github.com/streamich/react-use/blob/master/docs/useClickAway.md#reference
      ...props
    },
    ref
  ) => {
    const pickerRef = useRef(null);
    const handleChange = useHandleChange(onChange, color);
    const handleChangeComplete = useHandleChange(onChangeComplete, color);

    const refCallback = useCallback(
      (node) => {
        applyRef(ref, node);
        applyRef(pickerRef, node);
      },
      [ref]
    );

    useClickAway(pickerRef, handleClose, clickAwayEvents || ['click']);

    return (
      <StyledColorPickerPopover
        className={className}
        align={align}
        ref={refCallback}
      >
        <StyledSketchPicker
          presetColors={presetColors}
          color={getColorProp(color)}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
          transparentPreviewComponent={transparentPreviewRedX} // This prop was added via a patch. See main README's Patches section (react-color+2.18.1.patch)
          {...props}
        />
      </StyledColorPickerPopover>
    );
  }
);

ColorPicker.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ColorPicker;
