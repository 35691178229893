import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Select from 'components/Inputs/Select';
import { ValidatedDecimalNumberTextInput } from 'components/Inputs/TextInput/presets/DecimalNumber';
import { ELEMENT_TYPES } from '../constants';
import { scrollOptions } from 'hooks/use-scroll-height-tracker';
import { useScrollIntoView } from 'hooks/use-scroll-into-view';

const INPUT_WIDTH = 175;

const ElementInputWrapper = styled.div`
  width: ${INPUT_WIDTH}px;
`;

export const ElementInputs = ({
  element,
  onUpdateElement,
  fieldOptions,
  index,
}) => {
  const { t } = useTranslation();
  const { scrollNext, componentRef } = useScrollIntoView(scrollOptions);
  return (
    <ElementInputWrapper
      className="help-me"
      ref={componentRef}
      onClick={scrollNext}
    >
      {element.type === ELEMENT_TYPES.constant ? (
        <ValidatedDecimalNumberTextInput
          value={element.value}
          onChange={onUpdateElement}
        />
      ) : (
        <Select
          value={element.value}
          options={fieldOptions}
          label={null}
          placeholder={t('Choose Field')}
          onChange={onUpdateElement}
        />
      )}
    </ElementInputWrapper>
  );
};
