import React, { forwardRef, useImperativeHandle, useState } from 'react';
import * as PropTypes from 'prop-types';

import { StyledDraggableItem, StyledDraggableItemWrapper } from './styles';

const DragItem = forwardRef(
  (
    {
      handleProps,
      isHidden,
      darken,
      disabled,
      children,
      forceToTop,
      compact,
      redBorder = false,
      ...others
    },
    ref
  ) => {
    const [bringToTop, setBringToTop] = useState(false);

    // This pattern allows us to treat this as a controllable component,
    // meaning the parent can call bringToTop or sendToBack on it.
    useImperativeHandle(
      ref,
      () => {
        return {
          bringToTop: () => {
            setBringToTop(true);
          },
          sendToBack: () => {
            setBringToTop(false);
          },
        };
      },
      []
    );

    return (
      <StyledDraggableItemWrapper
        bringToTop={bringToTop}
        forceToTop={forceToTop}
        onFocus={() => setBringToTop(true)}
        onBlur={() => setBringToTop(false)}
        {...others}
      >
        <StyledDraggableItem
          handleProps={handleProps}
          isHidden={isHidden}
          darken={darken}
          disabled={disabled}
          compact={compact}
          redBorder={redBorder}
        >
          {children}
        </StyledDraggableItem>
      </StyledDraggableItemWrapper>
    );
  }
);

DragItem.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  handleProps: PropTypes.object.isRequired,
  forceToTop: PropTypes.bool,
};

DragItem.defaultProps = {
  forceToTop: false,
};

export default DragItem;
