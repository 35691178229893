import { isEmailStatusField } from 'checks/fields';
import { EmptyCell } from 'components/Kizen/Table';
import InlineTableFieldInput, {
  getColumnNumeric,
} from 'components/Fields/InlineTableFieldInput';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { SizedCell } from 'components/Kizen/Table';
import { SortableHeadCell } from './../SortableHeadCell';
import { DEFAULT_WIDTH_IN_PIXELS, PADDING_IN_PIXELS } from '../constants';

export const CustomFieldDataCell = ({
  field = null,
  column = null,
  data = null,
  onSubmit,
  children,
  fieldProps = {},
  fetchUrl = null,
  handleUpdateTableRecords,
  ...others
}) => {
  if (!field) {
    return <EmptyCell {...others} />;
  }
  const onSubmitRecord = async (patch) => {
    await onSubmit(data.id, patch, column);
  };
  const additionalProps = isEmailStatusField(field)
    ? { menuLeftButton: null, menuRightButton: null }
    : {};
  return (
    <SizedCell
      minWidth={DEFAULT_WIDTH_IN_PIXELS}
      padding={PADDING_IN_PIXELS}
      column={column}
      {...others}
    >
      {column.format(
        <InlineTableFieldInput
          field={field}
          object={data}
          onSubmit={onSubmitRecord}
          fieldProps={fieldProps}
          fetchUrl={fetchUrl}
          handleUpdateTableRecords={handleUpdateTableRecords}
          {...getDataQAForInput(
            `inline-contact-${field.name}`,
            field.fieldType
          )}
          {...additionalProps}
          disabled
          readOnly
        />
      )}
    </SizedCell>
  );
};

export const CustomFieldHeadCell = ({
  field = null,
  column = null,
  minWidth = '200px',
  width = '200px',
  ...others
}) => {
  if (!field) {
    return <EmptyCell {...others} column={column} />;
  }
  return (
    <SortableHeadCell
      by={field.id}
      numeric={getColumnNumeric(field)}
      column={column}
      {...others}
    />
  );
};
