import { ChangeEvent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowBuilderContext } from '../context';
import { colorsButton } from 'app/colors';
import { PreReleaseFeature } from 'hooks/usePreReleaseFeatures';
import Icon from '__components/Kizen/Icon';
import { Button as ButtonKDS } from '@kizen/kds/Button';
import IconButtonWithBadge from '__components/Kizen/IconButtonWithBadge';
import LoadingButton from '__components/Button/LoadingButton';
import BackButton from '__components/Button/BackButton';
import StepOption from '__pages/AutomationEngine/ControlBar/Steps/StepOption';
import Draggable from '__pages/AutomationEngine/Draggable';
import steps from '__pages/AutomationEngine/steps';
import useModal from '__components/Modals/useModal';
import {
  SMART_CONNECTOR_STATUSES,
  SMARTCONNECTORS_PATHNAME,
} from 'pages/SmartConnectors/constants';
import { green, orange } from '@kizen/kds/Colors';
import { EmailModal } from './EmailModal';
import {
  SmartConnector,
  SmartConnectorStatus,
} from '__pages/SmartConnectors/types';
import { NameInput } from './NameInput';
import { Wrapper, Left, Center, Right, StepSelector, Switch } from './styles';
import { RunSmartConnectorModal } from '__pages/SmartConnectors/RunSmartConnectorModal';
import { useRunSmartConnectorModal } from '__pages/SmartConnectors/RunSmartConnectorModal/useRunSmartConnectorModal';

type ControlBarProps = {
  className: string;
};

export function ControlBar({ className }: ControlBarProps) {
  const { t } = useTranslation();

  const {
    preRelease,
    smartConnector,
    setSmartConnector,
    status,
    setStatus,
    showStats,
    saveable,
    persisting,
    placeStep,
    setShowStats,
    saveFlow,
  } = useContext(FlowBuilderContext);

  const handleSubmitErrorNotification = useCallback(
    (updatedSmartConnector: SmartConnector) => {
      setSmartConnector(updatedSmartConnector);
    },
    [setSmartConnector]
  );

  const [emailModalProps, emailButtonProps] = useModal({
    handleSubmit: handleSubmitErrorNotification,
    processHideOnSubmit: true,
  });

  const { runSmartConnectorModalProps, showRunSmartConnectorModal } =
    useRunSmartConnectorModal();

  const handleStartDryRun = () => {
    showRunSmartConnectorModal(
      smartConnector.id!,
      true,
      smartConnector.is_diff_check_enabled
    );
  };

  return (
    <Wrapper className={className}>
      <div>
        <StepSelector columns={preRelease ? 3 : 1}>
          <div>
            {preRelease ? (
              <>
                <Draggable
                  onStart={() => {
                    placeStep({ type: steps.action.type });
                  }}
                  onStop={() => placeStep(false)}
                  disabled={!steps.action.creatable}
                >
                  <StepOption
                    color={steps.action.color}
                    backgroundColor={green['green-50']}
                    icon={<Icon icon="bullhorn" />}
                    label={t('Action')}
                    disabled={!steps.action.creatable}
                  />
                </Draggable>
                <Draggable
                  onStart={() => placeStep({ type: steps.condition.type })}
                  onStop={() => placeStep(false)}
                  disabled={!steps.condition.creatable}
                >
                  <StepOption
                    color={steps.condition.color}
                    backgroundColor={green['green-50']}
                    icon={<Icon icon="code-branch" />}
                    label={t('Condition')}
                    disabled={!steps.condition.creatable}
                  />
                </Draggable>
              </>
            ) : null}
            <Draggable
              onStart={() => placeStep({ type: steps.upload.type })}
              onStop={() => placeStep(false)}
              disabled={!steps.upload.creatable}
            >
              <StepOption
                color={steps.upload.color}
                backgroundColor={orange['orange-40']}
                icon={<Icon icon="cloud-upload" />}
                label={t('Upload')}
                disabled={!steps.upload.creatable}
              />
            </Draggable>
          </div>
        </StepSelector>
        <Left>
          <BackButton to={SMARTCONNECTORS_PATHNAME}>{t('Back')}</BackButton>
          <Switch
            textPlacement="right"
            label={t('Stats')}
            checked={showStats}
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              setShowStats(ev.target.checked)
            }
            data-qa="smartconnector-stats-toggle"
          />
          <ButtonKDS
            {...emailButtonProps}
            leftIcon="message-automation-email-warning"
            leftIconSettings={{
              size: '2xl',
              tooltipDescriptionOverride: t('Email Error Notification'),
            }}
            variant="text"
            color="secondary"
          />
          <PreReleaseFeature>
            <IconButtonWithBadge
              title={t('Comments')}
              icon="comment"
              badge={1}
            />
          </PreReleaseFeature>
        </Left>
        <Center>
          <NameInput />
        </Center>
        <Right>
          <Switch
            textPlacement="left"
            label={t('Active?')}
            checked={(
              [
                SMART_CONNECTOR_STATUSES.operational,
                SMART_CONNECTOR_STATUSES.need_attention,
              ] as SmartConnectorStatus[]
            ).includes(status)}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              setStatus(
                ev.target.checked
                  ? SMART_CONNECTOR_STATUSES.operational
                  : SMART_CONNECTOR_STATUSES.inactive
              );
            }}
            backgroundColor={colorsButton.red.hover}
          />
          <ButtonKDS
            onClick={handleStartDryRun}
            leftIcon="action-smartconnector-dry-run"
            leftIconSettings={{
              size: '2xl',
            }}
            variant="text"
            color="secondary"
          />
          <LoadingButton
            disabled={!saveable}
            onClick={saveFlow}
            loading={persisting}
          >
            {t('Save')}
          </LoadingButton>
        </Right>
      </div>
      {emailModalProps.show ? (
        <EmailModal smartConnector={smartConnector} {...emailModalProps} />
      ) : null}

      {runSmartConnectorModalProps.show ? (
        <RunSmartConnectorModal {...runSmartConnectorModalProps} />
      ) : null}
    </Wrapper>
  );
}
