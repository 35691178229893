import {
  all,
  takeLatest,
  put,
  call,
  select,
  debounce,
} from 'redux-saga/effects';
import { DEFAULT_DELAY, SET_CONFIG_DELAY } from 'utility/config';
import { setSearchParams } from 'hooks/useSearchParam';
import Automation2Service, {
  getAutomationObjectName,
} from 'services/Automation2Service';
import TeamMemberService from 'services/TeamMemberService';
import CustomObjectsService from 'services/CustomObjectsService';
import { allowedRelationshipDropdownOptions } from 'xforms/api/customObjects';
import { sortByStringField } from 'xforms/sorting';
import {
  buildPage as buildPageAction,
  buildPageFail,
  buildPageComplete,
  buildPageFinish,
  changeGroupAction,
  getAutomations as getAutomationsAction,
  getAutomationsSuccess,
  deleteAutomation as deleteAutomationAction,
  deleteAutomationFail,
  duplicateAutomation as duplicateAutomationAction,
  setToast,
  setPageConfig as setPageConfigAction,
  updatePageConfig,
  updatePageConfigBySearch,
  setPageConfigSuccess,
  setPageConfigFail,
  createOrUpdateGroup as createOrUpdateGroupAction,
  setCreateOrUpdateGroupFail as setCreateOrUpdateGroupFailAction,
  removeGroup as removeGroupAction,
  createOrUpdateGroupFail,
  createOrUpdateGroupSuccess,
  removeGroupFail,
  removeGroupSuccess,
  getAutomationsStart,
  getAutomationsFinish,
  resetFilters,
  setFilters,
  setFilterErrors,
  setFilterName,
  setGroupId,
} from './automations.redux';
import { toastVariant } from 'components/ToastProvider';
import { anyFilterErrors, getFilterErrors } from 'hooks/useFilterErrorEffect';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { loadSavedFilter } from 'ts-filters/utils';

const PAGE_CONFIG_KEY = 'automation_list';

function* getAutomations({ payload }) {
  try {
    yield put(getAutomationsStart());
    const pageConfig = yield select((s) => s.automationPage.pageConfig);
    const data = {
      values: {
        search: pageConfig.search,
        ordering: pageConfig.sort,
        groupId:
          !pageConfig.filterObject?.query && pageConfig.group
            ? pageConfig.group
            : null,
        criteria: !pageConfig.filterObject?.query
          ? null
          : pageConfig.filterObject,
        page: {
          number: pageConfig.page,
          size: pageConfig.size,
        },
      },
    };

    const [{ results, count }] = yield all([
      call(Automation2Service.search, data.values),
      payload.updatePageConfig &&
        call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig),
    ]);

    yield put(
      getAutomationsSuccess({
        automations: results.map((item, index) => ({
          ...item,
          customObjectName: getAutomationObjectName(item),
          status: item.active,
          meta: {
            ...item.meta,
            openMenuAbove: results.length - index <= 3,
          },
        })),
        automationsCount: count,
      })
    );
  } catch (error) {
    if (anyFilterErrors(error?.response)) {
      const { errors } = getFilterErrors(error.response.data?.errors);
      yield put(setFilterErrors(errors));
    }
  } finally {
    yield put(getAutomationsFinish());
  }
}

function* deleteAutomation({ payload }) {
  const { id, t } = payload;
  try {
    yield call(Automation2Service.delete, id);
    yield put(
      setToast({
        variant: toastVariant.SUCCESS,
        message: t('The automation has been successfully deleted.'),
      })
    );
    yield getAutomations({ payload: { updatePageConfig: false } });
  } catch (error) {
    yield put(
      setToast({
        variant: toastVariant.FAILURE,
        message: t('The automation has not been successfully deleted.'),
      })
    );
    yield put(deleteAutomationFail(error));
  }
}

function* duplicateAutomation({ payload }) {
  try {
    yield call(Automation2Service.duplicate, payload);
    yield getAutomations({ payload: { updatePageConfig: false } });
  } catch (error) {
    yield put(deleteAutomationFail(error));
  }
}

function* buildPage({ payload }) {
  try {
    const { groups, t } = payload;
    const [groupsResponse, { results }, pageResponse] = yield all([
      call(Automation2Service.getGroups, groups),
      call(CustomObjectsService.getCustomObjectList, {
        size: FORCE_ALL_RECORDS_SIZE,
        customOnly: false,
      }),
      call(TeamMemberService.getPageConfig, PAGE_CONFIG_KEY),
    ]);

    let config = null;
    if (pageResponse.group) {
      const group = groupsResponse.find((g) => g.id === pageResponse.group);
      if (group) {
        config = group.config;
        yield put(setGroupId(group.id));
        yield put(setFilterName({ name: group.name }));
      }
    } else if (Object.keys(pageResponse.filterObject || {}).length) {
      config = pageResponse.filterObject;
    }

    let data = null;
    if (config) {
      const metadata = yield select(selectFilterMetadataDefinition);
      data = yield call(loadSavedFilter, metadata, config, []);
    }

    yield put(
      buildPageComplete({
        t,
        groupsResponse,
        models: results
          .map((item) => ({
            ...allowedRelationshipDropdownOptions(item),
            data: item,
          }))
          .sort(sortByStringField((x) => x.label)),
        pageResponse,
        filters: data,
      })
    );

    yield getAutomations({ payload: { updatePageConfig: false } });

    yield put(buildPageFinish());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* setPageConfig({ payload }) {
  try {
    const { fetchContacts = false } = payload;
    const pageConfig = yield select((s) => s.automationPage.pageConfig);

    yield call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig);

    if (fetchContacts) {
      yield call(getAutomations, { payload: { updatePageConfig: false } });
    }

    yield put(setPageConfigSuccess());
  } catch (error) {
    yield put(setPageConfigFail(error));
  }
}

function* createOrUpdateGroup({ payload }) {
  try {
    const { history, ...rest } = payload;
    const pageConfig = yield select((s) => s.automationPage.pageConfig);

    let result;
    if (pageConfig.group) {
      result = yield call(
        Automation2Service.updateGroup,
        rest,
        pageConfig.group
      );
    } else {
      result = yield call(Automation2Service.createGroup, rest);
      yield setSearchParams(history, {
        group: result.id,
        page: null,
      });
    }
    yield put(createOrUpdateGroupSuccess(result));
    yield getAutomations({ payload: { updatePageConfig: true } });
  } catch (error) {
    yield put(setCreateOrUpdateGroupFailAction(error));
  }
}

function* removeGroup({ payload }) {
  try {
    const pageConfig = yield select((s) => s.automationPage.pageConfig);
    yield call(Automation2Service.deleteGroup, pageConfig.group);
    yield setSearchParams(payload.history, {
      group: null,
      page: null,
    });
    yield put(removeGroupSuccess({ id: pageConfig.group }));
    yield getAutomations({ payload: { updatePageConfig: true } });
  } catch (error) {
    yield put(removeGroupFail(error));
  }
}

function* changeGroup({ payload }) {
  const { id } = payload;

  if (id === 'none') {
    yield put(resetFilters());
    return;
  }

  const groups = yield select((s) => s.automationPage.groups);
  const group = groups.find((g) => g.id === id);

  if (group) {
    const metadata = yield select(selectFilterMetadataDefinition);
    const data = yield call(loadSavedFilter, metadata, group.config, []);

    yield put(setFilters(data));
    yield put(setFilterName({ name: group.name }));
    yield put(setGroupId(id));
    yield put(updatePageConfig({ page: 1 }));
    yield getAutomations({ payload: { updatePageConfig: true } });
  }
}

function* automationPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield debounce(DEFAULT_DELAY, getAutomationsAction.type, getAutomations);
  yield takeLatest(deleteAutomationAction.type, deleteAutomation);
  yield takeLatest(duplicateAutomationAction.type, duplicateAutomation);
  yield takeLatest(setPageConfigAction.type, setPageConfig);
  yield debounce(
    SET_CONFIG_DELAY,
    updatePageConfigBySearch.type,
    setPageConfig
  );
  yield takeLatest(createOrUpdateGroupAction.type, createOrUpdateGroup);
  yield takeLatest(removeGroupAction.type, removeGroup);
  yield takeLatest(changeGroupAction.type, changeGroup);
}

export default automationPage;
