import { FLOW_BAR_HEIGHT } from '../../constants';

export const DPR = window.devicePixelRatio;

export const TITLE_HEIGHT = 25 * DPR;
export const TITLE_PADDING = 20 * DPR;
export const LINE_DASH = 4 * DPR;
export const CURTAIN_WIDTH = 100 * DPR;
export const TOP_CURTAIN_OFFSET = FLOW_BAR_HEIGHT * DPR;

export const SECTION_PADDING = 25;
export const TITLE_Y = 1;
