import {
  getItemCols,
  unlessItem,
  applyDropzone,
  getNaiveDropzone,
} from 'components/DragAndDropLayout/helpers';

// Find the row index for a given item.
export const getRow = (colsPerRow, list, id) => {
  let x = 0;
  let row = 0;
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    const nextItem = list[i + 1];
    const cols = getItemCols(item);
    const nextCols = nextItem ? getItemCols(nextItem) : 0;
    if (item.id === id) {
      return row;
    }
    if (
      x + cols >= colsPerRow ||
      x + cols + nextCols > colsPerRow ||
      !nextItem
    ) {
      x = 0;
      row += 1;
    } else {
      x += cols;
    }
  }
  return -1;
};

// Find the first item in a row given the row's index.
const getFirstInRow = (colsPerRow, list, findRow) => {
  let x = 0;
  let row = 0;
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    const nextItem = list[i + 1];
    const cols = getItemCols(item);
    const nextCols = nextItem ? getItemCols(nextItem) : 0;
    if (row === findRow) {
      return item.id;
    }
    if (
      x + cols >= colsPerRow ||
      x + cols + nextCols > colsPerRow ||
      !nextItem
    ) {
      x = 0;
      row += 1;
    } else {
      x += cols;
    }
  }
  return null;
};

// Find the last item in a row given the row's index.
const getLastInRow = (colsPerRow, list, findRow) => {
  let x = 0;
  let row = 0;
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    const nextItem = list[i + 1];
    const cols = getItemCols(item);
    const nextCols = nextItem ? getItemCols(nextItem) : 0;
    if (
      x + cols >= colsPerRow ||
      x + cols + nextCols > colsPerRow ||
      !nextItem
    ) {
      x = 0;
      row += 1;
    } else {
      x += cols;
    }
    if (row === findRow + 1) {
      return item.id;
    }
  }
  return null;
};

// In the case of a full-width field, adjust the naive dropzone to ensure
// it isn't dropped between two fields on the same row.
export const chooseHorizontal = (colsPerRow, fields, dropzone) => {
  if (dropzone.position === 'first') {
    return dropzone;
  }
  const row = getRow(colsPerRow, fields, dropzone.id);
  if (row === -1) {
    return null;
  }
  return {
    id:
      dropzone.position === 'before'
        ? getFirstInRow(colsPerRow, fields, row)
        : getLastInRow(colsPerRow, fields, row),
    position: dropzone.position,
    direction: 'horizontal',
  };
};

// Adjust the naive dropzone to determine whether it should be directionally
// horizontal or vertical. If the item would land on a row by itself then
// it should be horizontal.
const choosePositionForShortCol = (colsPerRow, field, fields, dropzone) => {
  const [nextFields] = applyDropzone(field, [fields], dropzone);
  const row = getRow(colsPerRow, nextFields, field.id);
  return {
    ...dropzone,
    direction:
      getFirstInRow(colsPerRow, nextFields, row) ===
      getLastInRow(colsPerRow, nextFields, row)
        ? 'horizontal'
        : 'vertical',
  };
};

/*
 * Steps:
 *   1. Determine which existing field item the mouse coordinates are near, and
 *      whether the drop would be before or after that item.  This is the "naive
 *      dropzone."
 *   2. If the dropzone is "first" then there are no items in this category, so
 *      indicate that the drop needs to be the new first item.
 *   3. If the dragging field has width of 1 (50%), determine whether the dropzone
 *      direction is "vertical" or "horizontal", using choosePositionForShortCol().
 *   4. If the dragging field has a width of 2 (100%), adjust the naive dropzone
 *      to enforce that it isn't allowed to be dropped between two fields on the
 *      same row, using chooseHorizontal().
 *   5. In all cases, do not return the dropzone if it would not cause the position
 *      of the dragging field to change, using unlessItem().
 */

export const getFieldDropzone = (draggingField, toCategory, event) => {
  const { fields } = toCategory;
  const naiveDropzone = getNaiveDropzone({
    items: fields,
    skip: (field) => field.isHidden,
    selector: '.FieldItemWrapper',
    event,
    colsPerRow: 2,
    draggingItemCols: getItemCols(draggingField),
  });
  if (naiveDropzone && naiveDropzone.position === 'first') {
    naiveDropzone.sectionId = toCategory.id;
  }
  if (getItemCols(draggingField) === 1) {
    return (
      naiveDropzone &&
      unlessItem(
        draggingField,
        fields,
        choosePositionForShortCol(2, draggingField, fields, naiveDropzone)
      )
    );
  }
  if (getItemCols(draggingField) === 2) {
    return (
      naiveDropzone &&
      unlessItem(
        draggingField,
        fields,
        chooseHorizontal(2, fields, naiveDropzone)
      )
    );
  }
  return null;
};

// See comment above getFieldDropzone(). This is simpler because all
// category items have a width of 1 (33.3%), and are simply placed next
// to each other.
export const getCategoryDropzone = (draggingCategory, categories, event) => {
  const naiveDropzone = getNaiveDropzone({
    items: categories,
    selector: '.CategoryItemWrapper',
    event,
    colsPerRow: 3,
    draggingItemCols: 1,
  });
  return (
    naiveDropzone && unlessItem(draggingCategory, categories, naiveDropzone)
  );
};

export const hasDeletedOptions = (initialData, data) =>
  initialData?.options?.some(
    ({ id }) => !data?.options?.find((opt) => id === opt.id)
  );
