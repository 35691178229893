import { VALID_STATE_TYPES } from './constants';
import { validate as validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';
import { INDICES_WEEKDAYS } from 'utility/datetime';
import { isEmailValid } from 'utility/validate';

const EMPTY_ARRAY = [];

export const requiredFieldProps = (t) => {
  return {
    required: true,
    validate: {
      full: (value) => {
        if (!value) {
          return t('This field is required');
        }
        return null;
      },
    },
  };
};

export const validateRequired = (formData = {}, requiredFields) =>
  Object.keys(requiredFields).every((f) => !!formData[f]);

export const validateMail = (t) => {
  const validateEmailExpanded = {
    full: (value) => {
      return (
        validateEmail.full(value) ||
        // Incidentally blocks an empty email
        (!isEmailValid(value)
          ? t('You did not enter a valid email address!')
          : null)
      );
    },
  };
  return validateEmailExpanded;
};

export const countriesToCountryOptions = (countryList) => {
  if (!countryList?.length) return EMPTY_ARRAY;
  return countryList.map(({ name, alpha2 }) => {
    return {
      value: alpha2,
      extraValue: alpha2,
      label: name,
    };
  });
};

export const countriesToStateOptions = (countryList, countryCode) => {
  if (!countryList?.length) return EMPTY_ARRAY;
  const chosenCountry = countryList.find(
    ({ alpha2 }) => alpha2 === countryCode
  );
  if (!chosenCountry || !chosenCountry.subdivision_groups?.length)
    return EMPTY_ARRAY;
  return chosenCountry.subdivision_groups.reduce(
    (collect, { name: groupName, subdivisions }) => [
      ...collect,
      ...(VALID_STATE_TYPES.includes(groupName)
        ? subdivisions.map(({ code, name }) => ({
            value: code.split('-')[1],
            label: name,
          }))
        : EMPTY_ARRAY),
    ],
    []
  );
};

const weekdaysDictionary = (t) => [
  t('Sunday'),
  t('Monday'),
  t('Tuesday'),
  t('Wednesday'),
  t('Thursday'),
  t('Friday'),
  t('Saturday'),
];

export const getWeekdays = (t) => {
  const weekdaysTranslated = weekdaysDictionary(t);
  return weekdaysTranslated.map((weekday, index) => ({
    weekday,
    weekdayKey: INDICES_WEEKDAYS[index],
  }));
};
