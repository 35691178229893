import { getIsEditable } from 'components/AccessRequests/utils';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { useTooltip } from 'components/Kizen/Tooltip';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledEditableText } from './styles';
import Validation from 'components/Inputs/Validation';
import { useFlashTransition } from 'hooks/useFlashState';

const EditableName = ({
  handleChangeName,
  name,
  id,
  disabled,
  access,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const [currentName, setCurrentName] = useState(() => name);
  const titleInput = useRef();
  const [shouldUseTooltip, setShouldUseTooltip] = useState(false);

  useEffect(() => {
    setCurrentName(name);
  }, [name]);

  const handleBlur = useCallback(() => {
    const trimmedName = currentName?.trim();
    setErrors?.({});
    if (trimmedName) {
      if (trimmedName !== name) {
        handleChangeName(id, currentName);
      }
    } else {
      setCurrentName(name);
    }
  }, [id, handleChangeName, currentName, name, setErrors]);

  const hasEditPermission = useMemo(() => {
    return getIsEditable(access);
  }, [access]);

  const [titleTooltipProps, titleTooltip] = useTooltip({
    label: currentName,
  });

  const calculateTootlip = useCallback(() => {
    // A bit of a hack to only show the tooltip if the dashboard title is
    // causing an overflow.
    const scrollW = titleInput?.current?.scrollWidth;
    const clientW = titleInput?.current?.clientWidth;
    setShouldUseTooltip(clientW < scrollW);
  }, []);

  const handleMouseOver = useCallback(() => {
    calculateTootlip();
  }, [calculateTootlip]);

  const setTitleInputRef = useCallback((el) => {
    // react-input-autosize only respects function-style refs
    titleInput.current = el;
  }, []);

  const mergedBlur = useCallback(
    (e) => {
      handleBlur(e);
      titleTooltipProps.onBlur(e);
    },
    [handleBlur, titleTooltipProps]
  );

  // if the user presses enter, attempt to save
  const onKeyDownHandler = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        titleInput.current?.blur();
        return handleBlur(event);
      }
    },
    [handleBlur]
  );

  const [message, showMessage, flashErrorMessage] = useFlashTransition();

  useEffect(() => {
    if (errors?.name) {
      flashErrorMessage(errors.name);
      setCurrentName(name);
      setErrors?.({});
    }
  }, [errors?.name, flashErrorMessage, name, setErrors]);

  if (!hasEditPermission) {
    return <TextEllipsisWithTooltip>{name}</TextEllipsisWithTooltip>;
  }

  return (
    <>
      <StyledEditableText
        sizing="dense"
        placeholder={t('Enter Dashboard Name')}
        value={currentName}
        onChange={setCurrentName}
        inputRef={setTitleInputRef}
        disabled={disabled || !hasEditPermission}
        onMouseOver={handleMouseOver}
        onKeyDown={(event) => onKeyDownHandler(event)}
        {...titleTooltipProps}
        onBlur={mergedBlur}
        error={errors?.name}
      />
      <Validation
        message={message}
        showMessage={showMessage}
        target={titleInput.current}
      />
      {shouldUseTooltip ? titleTooltip : null}
    </>
  );
};

export default EditableName;
