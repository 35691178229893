import { DefaultHomepageProps } from '@kizen/page-builder/gen';
import { createElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { useAddNewNode } from '../useAddNewNode';
import {
  DEFAULT_EMAIL_SECTION_PROPS,
  DEFAULT_FORM_SECTION_PROPS,
} from './props';

const CreateSection = ({ defaultProps }) => {
  const addNewNode = useAddNewNode();
  const sectionItem = useSelector((s) => getCreateItemByType(s, 'Section'));
  useEffect(() => {
    if (sectionItem) {
      addNewNode({ ...sectionItem, ...defaultProps });
    }
  }, [sectionItem, defaultProps, addNewNode]);

  return null;
};

export const EmailSectionCreator = createElement(CreateSection, {
  defaultProps: DEFAULT_EMAIL_SECTION_PROPS,
});

export const FormSectionCreator = createElement(CreateSection, {
  defaultProps: DEFAULT_FORM_SECTION_PROPS,
});

export const HomepageSectionCreator = createElement(CreateSection, {
  defaultProps: DefaultHomepageProps.Section,
});
