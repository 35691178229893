import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { displayBreakpoints, isMobile, useWindowSize } from 'app/spacing';
import { colorsButton, grayScale } from 'app/colors';
import { fontSizes, Text, TextSpan } from 'app/typography';
import { useClickAway } from 'react-use';
import Button from 'components/Button';
import Icon from '../Icon';
import { optionShape } from '../Select';
import StyledSelect, {
  StyledLabelWrapper,
  StyledLabel,
  StyledUnderlinedSelect,
  StyledUnderlinedLabelWrapper,
  StyledUnderlinedLabel,
  StyledAddOptionButton,
} from './styles';
import { useTruncationTooltip } from '../Tooltip';

const useFilteredOptions = ({
  options,
  filterOption,
  search,
  isOptionSelected,
}) =>
  Array.isArray(options)
    ? options.filter((option) => {
        if (
          (!filterOption || filterOption(search, option.label)) &&
          !isOptionSelected(option)
        ) {
          return true;
        }
        return false;
      })
    : null;

const SelectedValue = ({
  values,
  onChange,
  value,
  PillComponent,
  disabled,
  hideCloseIcon,
}) => {
  return (
    <span className="selected-value">
      {!hideCloseIcon && (
        <Icon
          icon="close"
          size="lg"
          className="selected-value-remove"
          onClick={() => {
            if (!disabled) {
              onChange(values.filter((element) => element !== value));
            }
          }}
        />
      )}
      {PillComponent && (
        <PillComponent as="span" className="selected-value-text" value={value}>
          {value.label}
        </PillComponent>
      )}
      {!PillComponent && (
        <Text as="span" className="selected-value-text">
          {value.label}
        </Text>
      )}
    </span>
  );
};

const MenuValue = ({ values, onChange, value }) => {
  const selected = values.includes(value);
  // TODO just a hack to continue avoiding a lint issue related to the onClick
  // on a non-interactive element. Should be fixed some day by ensuring
  // this menu supports keyboard usage!
  const Wrapper = 'div';
  return (
    <Wrapper
      className={`menu-value ${selected ? 'selected' : 'unselected'}`}
      onClick={() => {
        if (!selected) onChange([...values, value]);
        else onChange(values.filter((element) => element !== value));
      }}
    >
      <TextSpan>{value.label}</TextSpan>
    </Wrapper>
  );
};

const subComponentPropTypes = {
  values: PropTypes.arrayOf(optionShape).isRequired,
  onChange: PropTypes.func.isRequired,
  value: optionShape.isRequired,
};

SelectedValue.propTypes = {
  ...subComponentPropTypes,
  PillComponent: PropTypes.elementType,
  disabled: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
};

SelectedValue.defaultProps = {
  ...subComponentPropTypes,
  PillComponent: null,
  disabled: false,
  hideCloseIcon: false,
};

MenuValue.propTypes = subComponentPropTypes;

const MenuValueWrapper = ({ options, filteredOptions, onChange, values }) => {
  const { t } = useTranslation();

  if (Array.isArray(options)) {
    return filteredOptions.length > 0 ? (
      filteredOptions.map((value, key) => (
        <MenuValue
          value={value}
          key={key}
          onChange={onChange}
          values={values}
        />
      ))
    ) : (
      <Text className="no-options">{t('No Options')}</Text>
    );
  }
  return <Text className="no-options">{t('Loading Options')}</Text>;
};

MenuValueWrapper.propTypes = {
  options: PropTypes.oneOf([PropTypes.arrayOf(optionShape), null]).isRequired,
  filteredOptions: PropTypes.oneOf([PropTypes.arrayOf(optionShape), null])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(optionShape).isRequired,
};

const MultiSelect = ({
  size,
  fullWidth,
  options,
  values,
  onChange,
  button,
  selectAllCumulative,
  placeholder,
  expanded,
  onClickApply,
  onInputChange,
  onFocus,
  filterOption,
  onKeyDown,
  showClearButton,
  hideCloseIcon,
  label,
  className,
  labelClassName,
  dropUp = false,
  disabled,
  withoutDropdown,
  addOptionButtonLabel,
  onAddOption,
  ...props
}) => {
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const containerRef = useRef(null);
  const searchRef = useRef(null);
  const selectedValuesRef = useRef(null);

  useClickAway(containerRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    selectedValuesRef.current.scrollTop =
      selectedValuesRef.current.scrollHeight;
  }, [values]);

  // if we have the multi-select open, focus on the search input
  useEffect(() => {
    if (expanded && searchRef.current) {
      // Scrolling was causing issues when placed in an overlay
      searchRef.current.focus({ preventScroll: true });
    }
  }, [expanded]);

  // Is the option selected? Check control value equal to selected values
  const isOptionSelected = (control) => {
    return values.some((option) => option.value === control.value);
  };

  // Filter options that show up to select
  const filteredOptions = useFilteredOptions({
    options,
    filterOption,
    search,
    isOptionSelected,
  });

  return (
    <div className={className}>
      {!!label && (
        <StyledLabelWrapper className={labelClassName}>
          <StyledLabel>{label}</StyledLabel>
        </StyledLabelWrapper>
      )}
      <StyledSelect
        size={size}
        mobile={isMobile(width, displayBreakpoints.desktop)}
        fullWidth={fullWidth}
        open={open}
        expanded={expanded}
        ref={containerRef}
        dropUp={dropUp}
        {...props}
      >
        <div role="presentation" className="control" disabled={disabled}>
          <div className="selected-values" ref={selectedValuesRef}>
            {values.map((value, key) => (
              <SelectedValue
                value={value}
                key={key}
                values={values}
                onChange={onChange}
                disabled={disabled}
                hideCloseIcon={hideCloseIcon}
              />
            ))}
            {/*
             four spacers allows for up to
             five correctly-sized columns of tags
          */}
            {!!values.length && (
              <>
                <span />
                <span />
                <span />
                <span />
              </>
            )}
          </div>
          <div className="search-container" disabled={disabled}>
            {!withoutDropdown && (
              <>
                <Text
                  as="input"
                  className="search"
                  placeholder={placeholder}
                  ref={searchRef}
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    if (onInputChange) {
                      onInputChange(event.target.value);
                    }
                  }}
                  onFocus={(ev) => {
                    setOpen(true);
                    if (onFocus) {
                      onFocus(ev);
                    }
                  }}
                  onKeyDown={(ev) => {
                    if (onKeyDown) {
                      // This may be used to implement creation of a new option when
                      // there are no current suggestions, so options is also reported.
                      onKeyDown(ev, { options });
                    }
                  }}
                  disabled={disabled}
                />
                <Icon
                  icon="search"
                  size="1x"
                  className="search-icon"
                  color={grayScale.mediumDark}
                />
              </>
            )}
            {withoutDropdown && (
              <StyledAddOptionButton
                onClick={onAddOption}
                color={colorsButton.blue.default}
              >
                {addOptionButtonLabel}
              </StyledAddOptionButton>
            )}
          </div>
        </div>
        {!disabled && expanded !== false && (expanded || open) && (
          <div className="menu">
            {button !== false && (
              <div className="menu-header">
                {button || (
                  <Button
                    className="select-all-button"
                    variant="text"
                    color="blue"
                    onClick={() => {
                      if (selectAllCumulative) {
                        onChange([...(values || []), ...options]);
                      } else {
                        onChange(options);
                      }
                    }}
                  >
                    {`${t('Select All')} (${options.length})`}
                  </Button>
                )}
              </div>
            )}
            <div className="menu-values">
              <MenuValueWrapper
                options={options}
                filteredOptions={filteredOptions}
                onChange={onChange}
                values={values}
              />
            </div>
            <hr className="m-0" />
            <div className="menu-footer w-100">
              {showClearButton && (
                <Button
                  noSpace
                  className="close-button"
                  variant="text"
                  color="red"
                  onClick={() => {
                    onChange([]);
                  }}
                >
                  {t('Clear')}
                </Button>
              )}
              <Button
                noSpace
                variant="text"
                className="apply-button"
                onClick={() => {
                  setOpen(false);
                  if (onClickApply) {
                    onClickApply();
                  }
                }}
              >
                {t('Apply')}
              </Button>
            </div>
          </div>
        )}
      </StyledSelect>
    </div>
  );
};

// Underlined
export const UnderlinedMultiSelect = ({
  label,
  className,
  disabled,
  size,
  fullWidth,
  options,
  values,
  onChange,
  button,
  selectAllCumulative,
  placeholder,
  expanded,
  onClickApply,
  onInputChange,
  onFocus,
  filterOption,
  onKeyDown,
  showClearButton,
  hideCloseIcon,
  InputLabelProps,
  variant,
  PillComponent,
  withoutDropdown,
  addOptionButtonLabel,
  onAddOption,
  ...props
}) => {
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const [tooltipProps, tooltip] = useTruncationTooltip();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const containerRef = useRef(null);
  const searchRef = useRef(null);
  const selectedValuesRef = useRef(null);

  useClickAway(containerRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (selectedValuesRef.current)
      selectedValuesRef.current.scrollTop =
        selectedValuesRef.current.scrollHeight;
  }, [values]);

  // if we have the multi-select open, focus on the search input
  useEffect(() => {
    if (expanded && searchRef.current) {
      // Scrolling was causing issues when placed in an overlay
      searchRef.current.focus({ preventScroll: true });
    }
  }, [expanded]);

  // Is the option selected? Check control value equal to selected values
  const isOptionSelected = (control) => {
    return values.some((option) => option.value === control.value);
  };

  // Filter options that show up to select
  const filteredOptions = useFilteredOptions({
    options,
    filterOption,
    search,
    isOptionSelected,
  });

  const renderMultiSelect = () => (
    <StyledUnderlinedSelect
      size={size}
      mobile={isMobile(width, displayBreakpoints.desktop)}
      fullWidth={fullWidth}
      open={open}
      expanded={expanded}
      // Unlike other inputs, where we may test open || expanded || !!values.length
      // Tags and multi-select always appear with the shrink state true
      shrink
      hasValues={!!values.length}
      ref={containerRef}
      disabled={disabled}
      {...props}
    >
      <div role="presentation" className="control" disabled={disabled}>
        <div className="selected-values" ref={selectedValuesRef}>
          {values.map((value, key) => (
            <SelectedValue
              value={value}
              key={key}
              values={values}
              onChange={onChange}
              PillComponent={PillComponent}
              disabled={disabled}
              hideCloseIcon={hideCloseIcon}
            />
          ))}
          {/*
               four spacers allows for up to
               five correctly-sized columns of tags
            */}
          {!!values.length && (
            <>
              <span />
              <span />
              <span />
              <span />
            </>
          )}
        </div>
        <div className="search-container">
          {!withoutDropdown && (
            <>
              <Text
                as="input"
                className="search"
                placeholder={placeholder}
                ref={searchRef}
                value={search}
                onChange={(event) => {
                  setSearch(event.target.value);
                  if (onInputChange) {
                    onInputChange(event.target.value);
                  }
                }}
                onFocus={(ev) => {
                  setOpen(true);
                  if (onFocus) {
                    onFocus(ev);
                  }
                }}
                onKeyDown={(ev) => {
                  if (onKeyDown) {
                    // This may be used to implement creation of a new option when
                    // there are no current suggestions, so options is also reported.
                    onKeyDown(ev, { options });
                  }
                }}
                disabled={disabled}
              />
              <Icon
                icon={variant === 'tag' ? 'tag' : 'search'}
                size="1x"
                className="search-icon"
                color={open ? grayScale.dark : grayScale.mediumDark}
              />
            </>
          )}
          {withoutDropdown && (
            <StyledAddOptionButton
              onClick={onAddOption}
              color={colorsButton.blue.default}
            >
              {addOptionButtonLabel}
            </StyledAddOptionButton>
          )}
        </div>
      </div>
      {!disabled && expanded !== false && (expanded || open) && (
        <div className="menu">
          {button !== false && (
            <div className="menu-header">
              {button || (
                <Button
                  className="select-all-button"
                  variant="text"
                  color="blue"
                  onClick={() => {
                    if (selectAllCumulative) {
                      onChange([...(values || []), ...options]);
                    } else {
                      onChange(options);
                    }
                  }}
                >
                  {`${t('Select All')} (${options.length})`}
                </Button>
              )}
            </div>
          )}
          <div className="menu-values">
            <MenuValueWrapper
              options={options}
              filteredOptions={filteredOptions}
              onChange={onChange}
              values={values}
            />
          </div>
          <hr className="m-0" />
          <div className="menu-footer w-100">
            {showClearButton && (
              <Button
                noSpace
                className="close-button"
                variant="text"
                color="red"
                onClick={() => {
                  onChange([]);
                }}
              >
                {t('Clear')}
              </Button>
            )}
            <Button
              noSpace
              variant="text"
              className="apply-button"
              onClick={() => {
                setOpen(false);
                if (onClickApply) {
                  onClickApply();
                }
              }}
            >
              {t('Apply')}
            </Button>
          </div>
        </div>
      )}
    </StyledUnderlinedSelect>
  );

  if (label) {
    return (
      <div className={className}>
        <StyledUnderlinedLabelWrapper>
          <StyledUnderlinedLabel
            shrink
            hasValues={!!values.length}
            {...InputLabelProps}
            disabled={disabled}
            {...tooltipProps}
          >
            {label}
          </StyledUnderlinedLabel>
        </StyledUnderlinedLabelWrapper>
        {renderMultiSelect()}
        {tooltip}
      </div>
    );
  }

  return renderMultiSelect();
};

export const defaultFilterOption = (searchTerm, stringToSearch) => {
  // Check option.label with search state
  return stringToSearch.toLowerCase().includes(searchTerm.toLowerCase());
};

MultiSelect.propTypes = {
  size: PropTypes.oneOf(['large', 'small']),
  fullWidth: PropTypes.bool,
  options: PropTypes.arrayOf(optionShape).isRequired,
  values: PropTypes.arrayOf(optionShape),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  expanded: PropTypes.bool,
  onClickApply: PropTypes.func,
  onInputChange: PropTypes.func,
  onFocus: PropTypes.func,
  filterOption: PropTypes.func,
  button: PropTypes.element,
  selectAllCumulative: PropTypes.bool,
  onKeyDown: PropTypes.func,
  showClearButton: PropTypes.bool,
  hideCloseIcon: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  dropUp: PropTypes.bool,
  withoutDropdown: PropTypes.bool,
  addOptionButtonLabel: PropTypes.string,
  onAddOption: PropTypes.func,
};

UnderlinedMultiSelect.propTypes = {
  ...MultiSelect.propTypes,
  variant: PropTypes.oneOf(['tag', 'standard']),
  creatable: PropTypes.bool,
};

MultiSelect.defaultProps = {
  size: 'small',
  fullWidth: false,
  values: [],
  placeholder: '',
  expanded: null,
  onClickApply: null,
  onInputChange: null,
  onFocus: null,
  filterOption: defaultFilterOption,
  button: null,
  selectAllCumulative: false,
  onKeyDown: null,
  showClearButton: true,
  hideCloseIcon: false,
  label: '',
  className: '',
  dropUp: false,
  withoutDropdown: false,
  addOptionButtonLabel: 'Add Option',
  onAddOption: () => {},
  onChange: () => {},
};

UnderlinedMultiSelect.defaultProps = {
  ...MultiSelect.defaultProps,
  variant: 'standard',
  creatable: false,
};

const MultiSelectButtonBase = styled(Button)`
  // Hard to override due to MultiSelect styles
  button&&& {
    text-transform: none;
    font-weight: normal;
    font-size: ${fontSizes.text};
  }
`;

function MultiSelectButton(props) {
  return <MultiSelectButtonBase variant="text" color="blue" {...props} />;
}

export {
  MultiSelect as default,
  MultiSelect as OutlinedMultiSelect,
  MultiSelectButton,
};
