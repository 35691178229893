import { useMemo } from 'react';
import { CONTROL_HEIGHT } from './Control';

export const useControlPadding = (props) => {
  const { containerPaddingTop, containerBorderWidth } = props;

  return useMemo(() => {
    const top = parseInt(containerPaddingTop);
    const b = parseInt(containerBorderWidth);
    const border = isNaN(b) ? 0 : b;

    return {
      paddingTop: `${Math.max(
        CONTROL_HEIGHT - border,
        isNaN(top) ? 0 : top - border
      )}px`,
    };
  }, [containerPaddingTop, containerBorderWidth]);
};
