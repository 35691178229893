import styled from '@emotion/styled';
import { FlowBar as FlowBarBase } from './ControlBar/FlowBar';

export const FlowBar = styled(FlowBarBase)`
  height: 0;
  width: 100%;
  max-width: 1402px;
  margin: 0 auto;
  position: relative;
  & > div {
    z-index: 1;
    height: 55px;
    position: absolute;
    top: 0;
    right: 15px;
    width: 100%;
  }
`;
