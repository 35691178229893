import { getValueForPayload } from 'components/Modals/CreateEntity/helpers';
import useModal from 'components/Modals/useModal';

export const useAddReasonLostModal = ({ handleUpdate, handleHide }) => {
  const [addReasonLostModalProps, , addReasonLostModal] = useModal({
    handleSubmit: async (selectedOptions, fieldId) => {
      selectedOptions.forEach(({ selected, recordId, stageId }) => {
        const patch = {
          fields: [
            {
              id: fieldId,
              value: getValueForPayload(selected),
            },
          ],
        };
        handleUpdate?.({ recordId, patch, stageId });
      });
    },
    handleHide,
  });

  return [addReasonLostModalProps, addReasonLostModal];
};
