import DesktopBoard from './DesktopBoard';
import DesktopTable from './DesktopTable';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import ObjectCharts from 'components/ObjectCharts';

const DesktopLayout = ({ viewVariant, ...props }) => {
  const VariantView =
    viewVariant === VIEW_VARIANTS.TABLE
      ? DesktopTable
      : viewVariant === VIEW_VARIANTS.CHARTS
        ? ObjectCharts
        : DesktopBoard;

  return <VariantView {...props} />;
};

export default DesktopLayout;
