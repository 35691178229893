import { useMemo } from 'react';
import { useBuilderContext } from '../BuilderContext';

export const usePhoneFieldWithBusinessRegion = (field) => {
  const { defaultPhoneRegion } = useBuilderContext();

  return useMemo(
    () => ({
      ...field,
      phonenumberOptions: {
        ...field.phonenumberOptions,
        defaultRegion: defaultPhoneRegion,
      },
    }),
    [field, defaultPhoneRegion]
  );
};
