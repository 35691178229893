import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';

export const StyledSwitchWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledModalText = styled(KizenTypography)`
  margin: 15px 0;
  line-height: 1.5;
`;
