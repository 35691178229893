import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import { SizedCell, SortableHeadCell } from 'components/Kizen/Table';
import { StyledCell } from 'pages/Account/pages/Toolbar/pages/ToolbarTemplates/styles';
import OwnerCell from 'pages/Account/pages/Toolbar/pages/ToolbarTemplates/components/OwnerCell';
import {
  EMPLOYEE_ACCESS,
  EMPLOYEE_ACCESS_DICTIONARY,
} from 'components/AccessRequests/utils';
import SharingSettingsCell from 'components/AccessRequests/Tables/Cells/SharingSettingsCell';
import { FilterGroupNameCell } from '../components/FilterGroupNameCell';
import { ActionCell } from '../components/ActionCell';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

const paddingSize = {
  basic: '20px',
  small: '10px',
};
const columnSize = {
  minWidth: '100px',
  maxWidth: '150px',
  spacer: '31px',
  name: '220px',
  owner: '220px',
  sharingSettings: '266px',
  yourAccess: '120px',
  count: '80px',
  actions: '50px',
};

export const useColumns = (
  handleActionMenu,
  handleChangeName,
  handleClickEditSharingSettings,
  handleChangeOwner,
  errors,
  data
) => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        id: 'spacer-1',
        headCell: <SizedCell width={columnSize.spacer} />,
        cell: <SizedCell width={columnSize.spacer} />,
      },
      {
        id: 'name',
        headCell: (
          <SortableHeadCell
            label={t('Filter Group Name')}
            minWidth={columnSize.minWidth}
            maxWidth={columnSize.name}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell
            minWidth={columnSize.minWidth}
            maxWidth={columnSize.maxWidth}
            padding={paddingSize.basic}
          />
        ),
        format: (value, data) => (
          <FilterGroupNameCell
            value={value}
            data={data}
            onChange={handleChangeName}
            error={errors[data.id]?.name}
          />
        ),
      },
      {
        id: 'owner',
        headCell: (
          <SortableHeadCell
            label={t('Creator/Owner')}
            minWidth={columnSize.minWidth}
            maxWidth={columnSize.owner}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell
            minWidth={columnSize.minWidth}
            maxWidth={'150px'}
            padding={paddingSize.basic}
          />
        ),
        format: (value, innerData) => {
          return (
            <OwnerCell
              value={value || {}}
              data={innerData}
              items={data}
              onChange={(owner) => handleChangeOwner(owner, innerData.id)}
            />
          );
        },
      },
      {
        id: 'sharing_settings',
        headCell: (
          <SortableHeadCell
            label={t('Sharing Settings')}
            minWidth={columnSize.minWidth}
            maxWidth={columnSize.sharingSettings}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell
            minWidth={columnSize.minWidth}
            maxWidth={'150px'}
            padding={paddingSize.basic}
          />
        ),
        format: (value, data) => (
          <SharingSettingsCell
            access={data.employee_access}
            data={data}
            onClickEdit={() => handleClickEditSharingSettings(data)}
          />
        ),
      },
      {
        id: 'employee_access',
        headCell: (
          <SortableHeadCell
            label={t('Your Access')}
            minWidth={columnSize.minWidth}
            maxWidth={columnSize.yourAccess}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell
            minWidth={columnSize.minWidth}
            maxWidth={columnSize.yourAccess}
            padding={paddingSize.basic}
          />
        ),
        format: (value) => (
          <KizenTypography>
            {EMPLOYEE_ACCESS_DICTIONARY[value]?.(t) ||
              EMPLOYEE_ACCESS_DICTIONARY[EMPLOYEE_ACCESS.NONE](t)}
          </KizenTypography>
        ),
      },
      {
        id: 'filters_count',
        headCell: (
          <SortableHeadCell
            label={t('# Filters')}
            width={columnSize.count}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell width={columnSize.count} padding={paddingSize.basic} />
        ),
        format: (value) => {
          return <KizenTypography>{value}</KizenTypography>;
        },
      },
      {
        id: 'actions',
        headCell: (
          <SortableHeadCell
            label={t('Actions')}
            width={columnSize.actions}
            padding={paddingSize.small}
            hasSorting={false}
          />
        ),
        cell: <StyledCell padding={paddingSize.small} />,
        format: (_value, innerData) => {
          return (
            <ActionCell
              onChange={handleActionMenu}
              data={innerData}
              items={data?.results || EMPTY_ARRAY}
              openMenuAbove
            />
          );
        },
      },
    ];
  }, [
    t,
    handleActionMenu,
    handleChangeName,
    handleClickEditSharingSettings,
    handleChangeOwner,
    errors,
    data,
  ]);
};
