import styled from '@emotion/styled';
import Select from 'components/Inputs/Select';
import { layers } from 'app/spacing';
import { useRef, forwardRef, useCallback } from 'react';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { applyRef } from 'components/Inputs/props';

export const TopLayerSelectStyled = styled(Select)`
  z-index: ${({ modalLayer }) => layers.modals(modalLayer, 15)};
`;

/**
 * Select dropdown that will appear above all page builder content, specifically
 * the Control popups which have a z-index of layers.modals(modalLayer, 10).
 * See ControlsContainer in src/components/PageBuilder/nodes/Control.js
 */
export const TopLayerSelect = forwardRef(
  ({ fieldId, disabled, ...props }, ref) => {
    const { assignFieldHandle } = useKeyBoardContext();
    const selectRef = useRef();

    const mergeRef = useCallback(
      (select) => {
        applyRef(selectRef, select);
        applyRef(ref, select);
      },
      [ref]
    );

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, props);

    assignFieldHandle(fieldId, {
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      },
      customEscape: () => {
        setMenuIsOpen(false);
      },
      customUp: () => {
        setMenuIsOpen(true);
      },
      customDown: () => {
        setMenuIsOpen(true);
      },
      disabled,
    });

    return (
      <TopLayerSelectStyled
        ref={mergeRef}
        disabled={disabled}
        {...props}
        {...customSelectMenuProps}
      />
    );
  }
);
