import * as PropTypes from 'prop-types';
import { EmptyCell } from 'components/Kizen/Table';
import { getColumnNumeric } from 'components/Fields/InlineTableFieldInput';
import { SortableHeadCell } from './SortableHeadCell';

export const CustomFieldHeadCell = ({
  field = null,
  column = null,
  ...others
}) => {
  if (!field) {
    return <EmptyCell {...others} />;
  }
  return (
    <SortableHeadCell
      by={field.id}
      numeric={getColumnNumeric(field)}
      column={column}
      {...others}
    />
  );
};

CustomFieldHeadCell.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
  }),
  column: PropTypes.shape({ id: PropTypes.string.isRequired }),
};
