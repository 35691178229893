import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { grayScale } from 'app/colors';
import { DEFAULT_DELAY } from 'utility/config';
import {
  StyledUnderlinedLabelWrapper as StyledLabelWrapper,
  StyledUnderlinedLabel as StyledLabel,
  StyledUnderlinedInputWrapper as StyledInputWrapper,
  StyledUnderlinedInputEndcap as StyledInputEndcap,
  StyledUnderlinedInput as StyledInput,
  StyledUnderlinedNumberInput as StyledNumberInput,
  INPUT_TYPES,
  StyledLabelContainer,
  StyledCreditCardsContainer,
  StyledCreditCardIcon,
} from './styles';
import {
  getInputType,
  validateEmail,
  validateNumber,
  hasValue,
} from './helpers';
import Icon from '../Icon';
import { useTruncationTooltip } from '../Tooltip';

const Input = React.forwardRef(
  (
    {
      error,
      className,
      label,
      value,
      placeholder,
      onChange,
      type,
      disabled,
      underline,
      pill,
      id,
      inputType,
      endcap,
      onClick,
      onFocus,
      onBlur,
      hideLabel = false,
      hideIcon = false,
      creditCards = null,
      InputLabelProps,
      currencyCharacter,
      percentageCharacter,
      ...others
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);
    const [tooltipProps, tooltip] = useTruncationTooltip();
    const isFilled = hasValue(value) && value !== '';
    // If the input has an icon at the front, put the label in the shrink state to avoid a clash
    const isAdornedStart = type === INPUT_TYPES.PRICE;
    const [numberErrorClass, setNumberErrorClass] = useState('');
    const [emailErrorClass, setEmailErrorClass] = useState('');
    const idAttr = id ? { id } : {};
    const isShrunk =
      isFilled ||
      focused ||
      isAdornedStart ||
      (InputLabelProps && InputLabelProps.shrink);

    // When time allows, moving renderInputField() to helpers could DRY up the Outlined/Underlined files significantly
    const renderInputField = () => {
      const commonInputProps = {
        value,
        onChange: (event) => {
          if (
            type === INPUT_TYPES.NUMBER &&
            !validateNumber(event.target.value)
          ) {
            event.preventDefault();
            setNumberErrorClass('number-error');
            setTimeout(
              () => setNumberErrorClass('no-number-error'),
              DEFAULT_DELAY
            );
            const { value: val } = event.target;
            onChange(val.substring(0, val.length - 2));
          } else if (
            type === INPUT_TYPES.WHOLE_NUMBER &&
            !validateNumber(event.target.value)
          ) {
            setNumberErrorClass('number-error');
            setTimeout(
              () => setNumberErrorClass('no-number-error'),
              DEFAULT_DELAY
            );
          } else {
            onChange(event.target.value);
          }
        },
        type: inputType || getInputType(type),
        placeholder,
        onFocus: (ev) => {
          setFocused(true);
          if (onFocus) {
            onFocus(ev);
          }
        },
        onBlur: (ev) => {
          setFocused(false);
          if (onBlur) {
            onBlur(ev);
          }
          if (type === INPUT_TYPES.EMAIL) {
            if (!validateEmail(ev.target.value)) {
              setEmailErrorClass('email-error');
            } else {
              setEmailErrorClass('no-email-error');
            }
          }
        },
        disabled,
        ...idAttr,
        ...others,
      };

      if (
        [INPUT_TYPES.PRICE, INPUT_TYPES.DECIMAL].includes(type) ||
        [INPUT_TYPES.PERCENTAGE, INPUT_TYPES.DECIMAL].includes(type)
      ) {
        const decimalScale =
          type === INPUT_TYPES.PRICE || type === INPUT_TYPES.PERCENTAGE
            ? { decimalScale: 2, fixedDecimalScale: true }
            : {};

        return (
          <StyledNumberInput
            shrink={isShrunk}
            defaultValue=""
            {...decimalScale}
            {...commonInputProps}
          />
        );
      }

      return <StyledInput shrink={isShrunk} {...commonInputProps} />;
    };

    return (
      <div ref={ref} className={className}>
        <StyledLabelWrapper htmlFor={id}>
          {type === INPUT_TYPES.CREDIT_CARD && creditCards ? (
            <StyledLabelContainer>
              <StyledLabel {...tooltipProps}>{label}</StyledLabel>
              <StyledCreditCardsContainer>
                {Object.keys(creditCards).map((key) => {
                  const { icon, firstDigit, activeColor } = creditCards[key];
                  const active =
                    value &&
                    parseInt(value && value.slice(0, 1), 10) === firstDigit;
                  return (
                    <StyledCreditCardIcon
                      key={key}
                      icon={icon}
                      color={active ? activeColor : grayScale.mediumDark}
                      active={active}
                    />
                  );
                })}
              </StyledCreditCardsContainer>
            </StyledLabelContainer>
          ) : (
            <>
              {label && !hideLabel && (
                <StyledLabel
                  shrink={focused || isFilled || isAdornedStart}
                  {...InputLabelProps}
                  {...tooltipProps}
                >
                  {label}
                </StyledLabel>
              )}
            </>
          )}

          <StyledInputWrapper
            focused={focused}
            error={error}
            underline={underline}
            pill={pill}
            disabled={disabled}
            className={`input-wrapper ${numberErrorClass} ${emailErrorClass}`}
            onClick={onClick}
          >
            {type === INPUT_TYPES.PRICE && (
              <StyledInputEndcap position="front">
                {currencyCharacter}
              </StyledInputEndcap>
            )}
            {type === INPUT_TYPES.PERCENTAGE && (
              <StyledInputEndcap position="front">
                {percentageCharacter}
              </StyledInputEndcap>
            )}
            {renderInputField()}
            {!endcap && type === INPUT_TYPES.SEARCH ? (
              <StyledInputEndcap className="search">
                <Icon
                  icon="search"
                  className="search-icon"
                  color={grayScale.mediumDark}
                />
              </StyledInputEndcap>
            ) : null}
            {endcap && type === INPUT_TYPES.TEXT && !hideIcon && (
              <StyledInputEndcap>{endcap}</StyledInputEndcap>
            )}
          </StyledInputWrapper>
        </StyledLabelWrapper>
        {tooltip}
      </div>
    );
  }
);

Input.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  underline: PropTypes.bool,
  pill: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(INPUT_TYPES)),
  inputType: PropTypes.string,
  endcap: PropTypes.node,
  creditCards: PropTypes.object,
  hideLabel: PropTypes.bool,
  hideIcon: PropTypes.bool,
  currencyCharacter: PropTypes.string,
  percentageCharacter: PropTypes.string,
};

Input.defaultProps = {
  value: null,
  onFocus: null,
  onBlur: null,
  onClick: null,
  placeholder: '',
  id: '',
  className: '',
  label: null,
  error: false,
  disabled: false,
  underline: false,
  pill: false,
  type: INPUT_TYPES.TEXT,
  inputType: null,
  endcap: null,
  creditCards: null,
  hideLabel: false,
  hideIcon: false,
  currencyCharacter: '$',
  percentageCharacter: '%',
  onChange: () => {},
};

Input.INPUT_TYPES = INPUT_TYPES;

export default Input;
