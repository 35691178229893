import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { icons } from '@kizen/icons';
import { grayScale } from '../../../app/colors';
import FilterIcon from './custom/FilterIcon';
import CalendarIcon from './custom/CalendarIcon';
import ThreeDotIcon from './custom/ThreeDotIcon';
import ReorderIcon from './custom/ReorderIcon';
import TrendCaretIcon from './custom/TrendCaretIcon';
import FontIcon from './custom/FontIcon';
import SurveyEntityIcon from './custom/SurveyEntityIcon';
import FormEntityIcon from './custom/FormEntityIcon';
import ActivityEntityIcon from './custom/ActivityEntityIcon';
import PipelineEntityIcon from './custom/PipelineEntityIcon';
import CommerceEntityIcon from './custom/CommerceEntityIcon';
import MarketingEntityIcon from './custom/MarketingEntityIcon';
import ColumnResizeIcon from './custom/ColumnResizeIcon';
import ExperienceEntityIcon from './custom/ExperienceEntityIcon';
import CloseIcon from './custom/CloseIcon';
import StylePassthrough from '../StylePassthrough';
import CycleHamburger from './custom/CycleHamburger';

const customs = {
  filter: FilterIcon,
  calendar: CalendarIcon,
  'three-dot': ThreeDotIcon,
  reorder: ReorderIcon,
  'trend-caret': TrendCaretIcon,
  'survey-entity': SurveyEntityIcon,
  'form-entity': FormEntityIcon,
  'activity-entity': ActivityEntityIcon,
  'pipeline-entity': PipelineEntityIcon,
  'commerce-entity': CommerceEntityIcon,
  'marketing-entity': MarketingEntityIcon,
  'column-resize': ColumnResizeIcon,
  'experience-entity': ExperienceEntityIcon,
  'close-cross': CloseIcon,
  'cycle-hamburger': CycleHamburger,
};

export const iconList = [...Object.keys(icons), ...Object.keys(customs)].sort();

const StyledIconSpan = styled.i(
  {
    margin: 0,
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
  },
  ({ color }) =>
    color
      ? {
          color:
            typeof color === 'string' ? color : color.light || color.default,
          ':hover': {
            color:
              typeof color === 'string' ? color : color.dark || color.hover,
          },
        }
      : {}
);

const Icon = ({
  icon,
  rotation,
  size,
  color,
  className,
  style,
  children,
  noCustom,
  ...props
}) => {
  return customs[icon] && !noCustom ? (
    <StyledIconSpan
      className={`${
        size === 'intrinsic' ? '' : `fa-layers fa-${size}`
      } ${className || ''}`}
      color={color}
      style={style}
    >
      {React.createElement(customs[icon], { icon, rotation, size, ...props })}
      {children}
    </StyledIconSpan>
  ) : (
    <StyledIconSpan
      className={`${className || ''}`}
      color={color}
      style={style}
    >
      <FontIcon
        icon={icons[icon] || icon}
        rotation={rotation}
        size={size}
        {...props}
      />
    </StyledIconSpan>
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
  rotation: PropTypes.number,
  size: PropTypes.string,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string),
  ]),
  noCustom: PropTypes.bool,
};

Icon.defaultProps = {
  icon: 'three-dot',
  rotation: null,
  size: '2x',
  color: { light: grayScale.mediumDark, dark: grayScale.dark },
  noCustom: false,
};

export default Icon;

// The force trick here is a hack to intentionally clobber other sizing styles.
// For example, the overlay-type button enforces a specific size icon, and this
// can be used to circumvent that.
export const IconSizing = styled(StylePassthrough)`
  ${({ size, force = 0 }) => css`
    ${'&'.repeat(force)}&,
    ${'&'.repeat(force)}&& svg {
      height: ${size};
      max-width: ${size};
      width: auto;
      transform: none !important; // Override e.g. transform on custom calendar icon
    }
  `}
`;
