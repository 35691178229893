import { CardToolbarButton } from 'components/Layout/CardToolbar';
import { useTranslation } from 'react-i18next';
import IntegratedEmailModal from './modal';
import useThirdPartyAuth from './useThirdPartyAuth';

const IntegratedEmailControl = ({ businessId, useCurrentTeamMember }) => {
  const { triggerProps, modalProps } = useThirdPartyAuth({
    businessId,
    useCurrentTeamMember,
  });
  const { t } = useTranslation();

  return (
    <>
      <CardToolbarButton {...triggerProps}>
        {`+ ${t('Add Integrated Email')}`}
      </CardToolbarButton>
      <IntegratedEmailModal {...modalProps} />
    </>
  );
};

export default IntegratedEmailControl;
