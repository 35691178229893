import { getTimezone } from 'countries-and-timezones';
import { format } from 'date-fns';
import get from 'lodash/get';
import parsePhoneNumber from 'libphonenumber-js/max';
import { isTimezoneEqualToLocal } from 'app/timezone';

type TeamMember = {
  email: string;
  firstName: string;
  lastName: string;
};

type FieldLike = {
  fieldType: string;
  id: string;
  name: string;
};

export type FieldValue = {
  displayName?: string;
  fieldType: string;
  id?: string;
  field?: string; // this is sometimes the id
  name: string;
  value: any;
  relation?: Relation;
};

type OptionValue = {
  code: string;
  id: string;
  name: string;
};

type Relation = {
  /** Format: uuid */
  relatedField: string;
  /** Format: uuid */
  relatedObject: string;
  /** Format: uuid */
  relatedCategory: string | null;
  relatedName?: string | null;
  relatedObjectName: string;
  relatedEntityName: string;
  relation_type?:
    | 'one_to_one'
    | 'primary'
    | 'additional'
    | 'primary_for'
    | 'additional_for';
  cardinality: 'one_to_one' | 'many_to_many' | 'many_to_one' | 'one_to_many';
  fetchUrl: string;
  rollupTimeline?: boolean;
  rollupLeadsources?: boolean;
  inverseRelationRollupTimeline?: boolean;
  inverseRelationRollupLeadsources?: boolean;
  relatedObjectDefaultOnActivities: string;
};

type BusinessLike = {
  city: string;
  country: string;
  name: string;
  phone: string;
  phone_default_region: string;
  postal_code: string;
  street_address: string;
  state: string;
  timezone: { name: string };
};

type Country = {
  alpha2: string;
  id: string;
  name: string;
  subdivision_groups: {
    id: string;
    name: string;
    subdivisions: {
      code: string;
      id: string;
      name: string;
    }[];
  }[];
};

type PhoneRegion = {
  value: string;
  label: string;
};

type GetMergeFieldLookupOptions = {
  business: BusinessLike;
  countries?: Country[];
  phoneRegions?: PhoneRegion[];
};

type GetTeamMemberMergeFieldLookup = {
  teamMember: {
    email: string;
    first_name: string;
    last_name: string;
  };
  roles?: string[];
};

const isTeamMember = (value: any): value is TeamMember => {
  if (value && typeof value === 'object') {
    return (
      typeof value.firstName === 'string' &&
      typeof value.lastName === 'string' &&
      typeof value.email === 'string'
    );
  }
  return false;
};

const getCodeFromRegion = (phoneRegion?: PhoneRegion): string => {
  if (!phoneRegion) return '';
  const matches = phoneRegion.label.match(/.*\((.*)\)/); // ex. United States (+1)
  return matches?.pop() ?? '';
};

const getRelationLink = (fieldId: string, relation: Relation) => {
  if (relation.fetchUrl === 'client') {
    return `/client/${fieldId}/details`;
  }

  return `/custom-objects/${relation.relatedObject}/${fieldId}/details`;
};

/**
 * Converts contact field values returned from `/client/{id}` into a list of field values that more
 * closely represents the field values returned from `custom-objects/{id}}/entity-records/{entity_id}`
 * so {@link getMergeFieldValue} does not need to be changed to accommodate both.
 *
 * What this does:
 * - Discards the tags and titles field values contained within the `fields` list (those values are on the root fo the reponse).
 * - Collects option values (dynamictags, checkboxes, files) within `fields` into a single field value.
 * - Converts all other field types into a structure that matches the custom object endpoint.
 *     - team_selector is the only exception since contacts already returns the fully formatted display name.
 * - Adds the `fieldType` property to all field values
 *
 * Some of the above may be considered bugs, see https://kizen.atlassian.net/browse/KZN-10538
 */
export const getClientFieldValues = (
  fields: FieldLike[],
  contactRecord: any
): FieldValue[] => {
  const tagsFieldId = fields.find((field) => field.name === 'tags')?.id;
  const titlesFieldId = fields.find((field) => field.name === 'titles')?.id;
  const fieldsById = fields.reduce<Record<string, FieldLike>>((acc, field) => {
    acc[field.id] = field;
    return acc;
  }, {});

  const withoutDefaults: any[] = Object.values(contactRecord.fields).filter(
    (fv: any) => fv.field !== tagsFieldId && fv.field !== titlesFieldId
  );

  const optionFields = new Map<string, FieldValue>();
  const regularFields: FieldValue[] = [];

  for (const entry of withoutDefaults) {
    const fieldId = entry.field!;
    const fieldType = fieldsById[fieldId]?.fieldType;

    if (
      ['dynamictags', 'checkboxes', 'files', 'relationship'].includes(fieldType)
    ) {
      if (!optionFields.has(fieldId)) {
        optionFields.set(fieldId, {
          displayName: entry.field_display_name,
          fieldType,
          id: entry.field,
          name: fieldsById[fieldId]?.name,
          value: [],
        });
      }
      const field = optionFields.get(fieldId)!;
      if (fieldType === 'files') {
        field.value.push({ fieldType, id: entry.value, name: entry.name.name });
      } else {
        field.value.push({ fieldType, id: entry.value, name: entry.name });
      }
    } else {
      if (fieldType === 'money') {
        const value = {
          symbol: entry.name.symbol,
          amount: entry.value,
        };
        regularFields.push({ ...entry, fieldType, value });
      } else if (fieldType === 'relationship') {
        regularFields.push({
          ...entry,
          fieldType,
          value: [{ name: entry.name, id: entry.value }],
        });
      } else if (
        fieldType === 'dropdown' ||
        fieldType === 'yesnomaybe' ||
        fieldType === 'status' ||
        fieldType === 'radio'
      ) {
        regularFields.push({
          ...entry,
          fieldType,
          value: { name: entry.name },
        });
      } else if (fieldType === 'team_selector') {
        regularFields.push({ ...entry, fieldType, value: entry.name });
      } else {
        regularFields.push({ ...entry, fieldType });
      }
    }
  }

  return [...regularFields, ...optionFields.values()];
};

const getMergeFieldValue = (
  field: FieldValue,
  t: (x: string) => string
): string => {
  const { value, fieldType, relation } = field;

  if (value === undefined) {
    return '';
  }

  switch (fieldType) {
    case 'checkbox':
      return value ? t('Is Checked') : t('Is Not Checked');
    case 'checkboxes': {
      const checked = value.map((option: OptionValue) => option.name);
      return checked.join(', ');
    }
    case 'date':
      if (!value) {
        return '';
      }
      return format(value, 'MMMM DD, YYYY');
    case 'datetime':
      if (!value) {
        return '';
      }
      return format(value, 'MMMM DD, YYYY h:mm A');
    case 'decimal':
      return value.toString();
    case 'dropdown':
      return value.name;
    case 'dynamictags':
      return value.map((tag: OptionValue) => tag.name).join(', ');
    case 'files':
      return value.map((file: any) => file.name).join(', ');
    case 'integer':
      return value.toString();
    case 'money':
      if (!value) {
        return '';
      }
      return `${value.symbol}${value.amount}`;
    case 'phonenumber':
      try {
        const parsedPhone = parsePhoneNumber(value?.e164 ?? value);
        if (parsedPhone) {
          return parsedPhone.formatInternational();
        }
        return value?.e164 ?? value;
      } catch (err) {
        return '';
      }
    case 'radio':
      return value.name;
    case 'rating':
      return value.toString();
    case 'relationship': {
      const relations = value.map((v: any) => {
        const link = getRelationLink(v.id, relation!);
        let display = v.name;
        if (isTeamMember(v)) {
          const name = `${v.firstName} ${v.lastName}`.trim();
          display = name ? `${name} (${v.email})` : v.email;
        }
        return `<a target="_blank" href="${link}">${display}</a>`;
      });
      return relations.join(', ');
    }
    case 'stage-dropdown':
      return value.name;
    case 'status':
      return value.name;
    case 'team_selector':
      if (typeof value === 'string') {
        return value;
      }
      if (value.fullName) {
        return `${value.fullName} (${value.email})`;
      }
      if (value.firstName || value.lastName) {
        return `${value.firstName ?? ''} ${value.lastName ?? ''} (${
          value.email
        })`;
      }
      return value.email;
    case 'yesnomaybe':
      return value.name;
    default:
      return value;
  }
};

/**
 * Business merge fields look like `{{ business.name }}`. This utility should be used to create
 * an object such that the `business.name` path lookup resolves when using lodash/get.
 *
 * ```ts
 * const lookup = {
 *  business: getBusinessMergeFieldLookup(...)
 * }
 * ```
 *
 * @param param0
 * @returns
 */
export const getBusinessMergeFieldLookup = ({
  business,
  countries,
  phoneRegions,
}: GetMergeFieldLookupOptions) => {
  const now = new Date();
  const businessTimezone = business.timezone.name;
  const businessDate = now.toLocaleString('en-US', {
    timeZone: businessTimezone,
  });
  const defaultCallingCode = getCodeFromRegion(
    phoneRegions?.find((x) => x.value === business.phone_default_region)
  );

  let businessTime = format(businessDate, 'h:mm A');
  if (!isTimezoneEqualToLocal(businessTimezone)) {
    const tz = getTimezone(businessTimezone);
    businessTime += ` ${businessTimezone}`;
    if (tz) {
      businessTime += ` (GMT${tz.utcOffsetStr})`;
    }
  }

  let businessPhone = business.phone;
  if (businessPhone) {
    const parsedPhone = parsePhoneNumber(business.phone);
    if (parsedPhone) {
      businessPhone =
        defaultCallingCode && business.phone.startsWith(defaultCallingCode)
          ? parsedPhone.formatNational()
          : parsedPhone.formatInternational();
    }
  }

  const country = countries?.find((c) => c.id === business.country);
  let state;
  if (country) {
    state = country.subdivision_groups
      .flatMap((x) => x.subdivisions)
      .find((y) => y.id === `${country.id}-${business.state}`);
  }

  return {
    city: business.city,
    country: country?.name ?? business.country,
    name: business.name,
    phone: businessPhone,
    postal_code: business.postal_code,
    street_address: business.street_address,
    state: state?.name ?? business.state,
    time: businessTime,
  };
};

/**
 * Team member merge fields look like `{{ team_member.email }}`. This utility should be used to create
 * an object such that the `team_member.email` path lookup resolves when using lodash/get.
 *
 * ```ts
 * const lookup = {
 *  team_member: getTeamMemberMergeFieldLookup(...)
 * }
 * ```
 *
 * @param param0
 * @returns
 */
export const getTeamMemberMergeFieldLookup = ({
  teamMember,
  roles,
}: GetTeamMemberMergeFieldLookup) => {
  const now = new Date();

  return {
    email: `<a href="mailto:${teamMember.email}">${teamMember.email}</a>`,
    first_name: teamMember.first_name,
    last_name: teamMember.last_name,
    roles: roles?.join(', ') ?? '',
    date: format(now, 'MMMM DD, YYYY'),
    time: format(now, 'h:mm A'),
  };
};

export const getMergeFieldLookupForFields = (
  fields: FieldValue[],
  t: (x: string) => string
): Record<string, string> => {
  const lookup = fields.reduce<Record<string, string>>((acc, field) => {
    acc[field.name] = getMergeFieldValue(field, t);
    return acc;
  }, {});

  return lookup;
};

/**
 * Example merge fields
 * - `{{ business.name }}`
 * - `{{ team_member.first_name }}`
 * - `{{ my_object_dk32eds.text_dk3kf }}`
 */
export const replaceMergeFields = (
  content: string,
  lookup: Record<string, Record<string, string>>
) => {
  return content.replace(/{{\s*([0-9A-Za-z._-]+)\s*}}/g, (_match, path) => {
    const value = get(lookup, path, '');
    return typeof value === 'string' ? value : '';
  });
};
