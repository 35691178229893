import {
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getDataQAForInput, getMaskedValue } from 'components/Inputs/helpers';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import EditableText from 'components/Kizen/EditableText';
import IconButton from 'components/Kizen/IconButton';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import ClickAway from '../ClickAway';
import Validation, {
  InputValidationError,
  useValidation,
} from '../../Validation';
import { useTranslation } from 'react-i18next';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { getAppRoot } from 'utility/app';
import { TextInputInlineWrapper } from './styles';
import { getOriginalError } from 'services/AxiosService';

const getInputEl = (inputRef) => {
  // Get ref to input, handling case of react-input-autosize's getInput()
  return inputRef.current && inputRef.current.getInput
    ? inputRef.current.getInput()
    : inputRef.current;
};

const TextInputInline = forwardRef(
  (
    {
      isNew,
      shouldFocusNext,
      field,
      object,
      editing: editingProp = true,
      input = <EditableText />,
      isReadOnlyNumeric,
      value = '',
      autoFocus = false,
      onAutoFocus,
      emptyPlaceholder = '—',
      readOnly = false,
      error = false,
      onKeyPress = null,
      onSubmit,
      submitUnchanged = false,
      callback = null,
      isShowCheckIcon = true,
      validate,
      disabled,
      tooltipLabel = '',
      popperConfig,
      placeholder = '',
      'data-field-type': qaFieldType,
      'data-field-id': qaFieldId,
      onFocused,
      onParentControl,
      displayMaskedValue,
      ...others
    },
    ref
  ) => {
    const clickTabCounter = useRef(0);
    const fieldId = useMemo(
      () => `${field?.id}-${object?.id}`,
      [field, object]
    );
    const maskedValue =
      displayMaskedValue && field?.meta?.isMasked
        ? getMaskedValue(value)
        : value;
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [failed, setFailed] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [stagedValue, setStagedValue] = useState(maskedValue ?? '');
    const [tooltipProps, tooltip, hideTooltip] = useTruncationTooltip({
      label: displayMaskedValue ? value : undefined,
      tooltipLabel: !displayMaskedValue ? tooltipLabel : value,
      popperConfig,
    });
    const inputRef = useRef();
    const validationRef = useRef();

    const [
      validation,
      validationProps,
      { validateFull },
      { flashErrorMessage, cancelFlash },
    ] = useValidation(inputRef, {
      validate,
      value: stagedValue,
    });

    const handleChange = useCallback(
      (v) => {
        setStagedValue(v);
        if (callback) {
          callback(v);
        }
      },
      [callback]
    );

    const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();

    useEffect(() => {
      setFailed(false);
      setStagedValue(value);
    }, [value, editing]);

    const onAutoFocusRef = useRef();
    onAutoFocusRef.current = onAutoFocus;

    useEffect(() => {
      const inputEl = getInputEl(inputRef) || getInputEl(validationRef);
      if (autoFocus && inputEl && !assignFieldHandle) {
        if (onAutoFocusRef.current) onAutoFocusRef.current(inputEl);
        inputEl.focus({ preventScroll: true });
      }
    }, [autoFocus, assignFieldHandle]);

    useEffect(() => {
      if (!editing) {
        // This avoids a minor glitch on mobile where the order of events
        // was allowing the tooltip to appear for a short while when blurring
        // the input, in between the clickaway and the tooltip's off handler.
        hideTooltip();
      }
    }, [editing, hideTooltip]);

    const showError = Boolean(failed || error || validation.error);
    const submit = async ({ clickAway } = {}) => {
      if (submitUnchanged || value !== stagedValue) {
        try {
          const valid = validateFull();
          if (!valid) {
            throw new InputValidationError();
          }
          setSubmitting(true);
          const res = await onSubmit(stagedValue);
          clickTabCounter.current += 1;
          setEditing(false);
          if (clickAway && inputRef?.current) {
            inputRef?.current.blur();
          }
          return [null, undefined, NaN, ''].includes(res) ? true : res;
        } catch (err) {
          if (err instanceof TextInputInline.Error && !err.failed) {
            return false;
          }

          const orig = getOriginalError(err);
          const message =
            orig?.errors?.percentage_chance_to_close?.[0] ||
            orig?.fields?.[0]?.value[0];

          if (message) {
            flashErrorMessage(message);
          }

          setFailed(true);
          if (clickAway && showError) {
            // When clicking away after two failures, bail on editing
            setEditing(false);
            cancelFlash();
          }
          return false;
        } finally {
          setSubmitting(false);
        }
      } else {
        setEditing(false);
      }
      return true;
    };

    assignFieldHandle(fieldId, {
      customFocus: () => {
        clickTabCounter.current = 0;
        setEditing(true);
        const inputEl = getInputEl(inputRef) || getInputEl(validationRef);
        inputEl?.focus();
        return inputEl;
      },
      customTab: async (e) => {
        e.preventDefault();
        const valid = validateFull();
        if (!valid) {
          return true;
        }
        const res = await submit();
        const inputEl = getInputEl(inputRef) || getInputEl(validationRef);
        inputEl?.focus();
        if (!res) {
          //refocus if submit is not successful
          const inputEl = getInputEl(inputRef) || getInputEl(validationRef);
          inputEl?.focus();
          setEditing(true);
        }
        if (clickTabCounter.current > 1 && !res) {
          setStagedValue(null);
        }
        const shouldStayFocused =
          clickTabCounter.current > 1 && !res ? false : !res;
        if (!shouldStayFocused && onParentControl) {
          return onParentControl(e);
        }
        return shouldStayFocused;
      },
      customEnter: async (e) => {
        e.preventDefault();
        const valid = validateFull();
        if (!valid) {
          return true;
        }
        const res = await submit();
        const inputEl = getInputEl(inputRef) || getInputEl(validationRef);
        inputEl?.focus();
        if (!res) {
          //refocus on submit is not successful
          const inputEl = getInputEl(inputRef) || getInputEl(validationRef);
          inputEl?.focus();
          setEditing(true);
        }
        if (clickTabCounter.current > 1 && !res) {
          setStagedValue(null);
        }

        const shouldStayFocused =
          clickTabCounter.current > 1 && !res ? false : !res;

        if (!shouldStayFocused) {
          return onParentControl?.(e);
        }
        return shouldStayFocused;
      },
      customEscape: (e) => {
        e.preventDefault();
        setEditing(false);
        return onParentControl?.(e) || true;
      },
      disabled: disabled || readOnly,
      shouldFocusNext,
    });

    if (editing && editingProp) {
      return (
        <TextInputInlineWrapper
          ref={ref}
          as={ClickAway}
          onClickAway={(ev) => {
            if (!getAppRoot().contains(ev.target)) {
              return;
            }
            !submitting && submit({ clickAway: true });
          }}
          button={isShowCheckIcon}
          {...others}
          isInherited={false}
        >
          {cloneElement(input, {
            ref: validationRef,
            disabled: submitting || disabled,
            inputRef: (el) => (validationRef.current = el),
            value: stagedValue || stagedValue === 0 ? stagedValue : '', // Handles display of null case
            onChange: handleChange,
            error: showError,
            editing,
            className: [
              input.props.className,
              showError && 'ValidationError',
            ].filter(Boolean),
            placeholder: input.props.placeholder || placeholder,
            ...getDataQAForInput(qaFieldId, qaFieldType),
            ...getKeyListenersProps?.(fieldId),
          })}
          {isShowCheckIcon && (
            <IconButton
              sizing="dense"
              disabled={submitting || disabled}
              title={t('Save')}
              color={
                submitting || disabled
                  ? colorsButton.iconGray.default
                  : colorsButton.iconGray
              }
              onClick={async (e) => {
                getKeyListenersProps?.(fieldId)?.onFocus(fieldId);
                if (
                  validateFull() &&
                  !submitting &&
                  (await submit({ clickAway: true }))
                ) {
                  getKeyListenersProps?.(fieldId)?.onBlur(e, shouldFocusNext);
                } else {
                  //refocus on submit is not successful
                  const inputEl =
                    getInputEl(inputRef) || getInputEl(validationRef);
                  inputEl?.focus();
                  setEditing(true);
                }
              }}
            >
              <Icon icon="check" />
            </IconButton>
          )}
          <Validation
            {...validationProps}
            // Depending on the field type, validationRef can be undefined
            // so we need to fall back to the target from validationProps
            target={getInputEl(validationRef) ?? validationProps.target}
          />
        </TextInputInlineWrapper>
      );
    }

    return readOnly ? (
      isReadOnlyNumeric ? (
        cloneElement(input, {
          ref: inputRef,
          inputRef: (el) => (inputRef.current = el),
          value:
            maskedValue || maskedValue === 0 ? maskedValue : emptyPlaceholder,
          ...tooltipProps,
          displayType: 'text',
        })
      ) : (
        <TextEllipsisWithTooltip
          {...others}
          tooltipLabel={tooltipLabel}
          popperConfig={popperConfig}
          showLabel={displayMaskedValue}
          label={value}
        >
          {maskedValue}
        </TextEllipsisWithTooltip>
      )
    ) : (
      <>
        <TextInputInlineWrapper
          // Doesn't need to be a ClickAway, except it maintains component consistency that allows normal input focusing when switching to editing
          as={ClickAway}
          ref={ref}
          button={false}
          {...others}
        >
          {cloneElement(input, {
            ref: inputRef,
            fieldId: field?.id,
            inputRef: (el) => (inputRef.current = el),
            className: 'ViewText',
            value:
              maskedValue || maskedValue === 0 ? maskedValue : emptyPlaceholder,
            editing,
            disabled: disabled || submitting,
            ...tooltipProps,
            // Override tooltipProps.onFocus
            ...getDataQAForInput(qaFieldId, qaFieldType),
            ...getKeyListenersProps?.(fieldId),
            onFocus: (e) => {
              getKeyListenersProps?.(fieldId)?.onFocus(fieldId);
              setEditing(true);
              onFocused?.(e);
            },
          })}
          <Validation {...validationProps} />
        </TextInputInlineWrapper>
        {tooltip}
      </>
    );
  }
);

TextInputInline.Error = class extends Error {
  constructor(message, { failed } = {}) {
    super(message);
    this.failed = Boolean(failed);
  }
};

TextInputInline.displayName = 'TextInputInline';

export default TextInputInline;
