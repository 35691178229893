import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import BaseNotice from 'components/Kizen/Notice';
import { StyledFormInput } from './styles';

const Notice = styled(BaseNotice)`
  margin-bottom: ${gutters.spacing(4)}px;
`;

const ApiKeysModalBody = ({
  apiKey,
  userId,
  businessId,
  showCreatedKeyInfo,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {showCreatedKeyInfo && (
        <Notice>
          {t(
            'Your API key has been created and is included below, please store it in a secure location. API Keys allow full access to your account, just like your password.'
          )}
        </Notice>
      )}
      <StyledFormInput
        margin
        className="first-row"
        label={t('API Key')}
        value={(apiKey && apiKey.key) || (apiKey && apiKey.key_preview)}
        disabled
      />
      <StyledFormInput margin label={t('User ID')} value={userId} disabled />
      <StyledFormInput label={t('Business ID')} value={businessId} disabled />
    </>
  );
};

ApiKeysModalBody.propTypes = {
  apiKey: PropTypes.object,
  userId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
  showCreatedKeyInfo: PropTypes.bool,
};

ApiKeysModalBody.defaultProps = {
  apiKey: null,
  showCreatedKeyInfo: false,
};

export default ApiKeysModalBody;
