import { buildOptions } from '@kizen/filters/utils';
import { useCallback, useMemo, useRef, useState } from 'react';
import IconAdornment from '__components/Inputs/Adornments/IconAdornment';
import useTeamMemberTypeaheadInfinite from '__hooks/useTeamMemberTypeaheadInfinite';
import { useMenuList, LOAD_THRESHOLD } from '../hooks/useMenuList';
import Select from '__components/Inputs/Select';
import { InputProps } from './types';

type TeamMember = {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  not_found?: boolean;
  not_allowed?: boolean;
};

type TeamMemberOption = {
  value: string;
  label: string;
  not_found?: boolean;
  not_allowed?: boolean;
  data: TeamMember;
};

type DisplayNamesResponse = {
  code: string;
  display_name: string;
  id: string;
  not_found?: boolean;
  not_allowed?: boolean;
};

const optionFromTeamMember = (tm: TeamMember) => ({
  value: tm.id,
  label: `${tm.first_name ?? ''} ${tm.last_name ?? ''} ${
    tm.email ? `(${tm.email})` : ''
  }`.trim(),
  data: tm,
});

const optionFromDisplayName = (tm: DisplayNamesResponse) => {
  return {
    value: tm.id,
    label: tm.display_name,
    data: tm,
  };
};

const isDisplayNamesValue = (tm: any): tm is DisplayNamesResponse => {
  return tm.display_name !== undefined;
};

export const TeamSelector = ({
  step,
  value,
  width,
  placeholder,
  option_paths,
  value_id_path,
  error,
  onChange,
}: InputProps<TeamMember | DisplayNamesResponse>) => {
  const init = useRef<DisplayNamesResponse | TeamMember | undefined>(value);

  const [invalidOption, setInvalidOption] = useState(() => {
    if (value?.not_found || value?.not_allowed) {
      const [[invalid]] = buildOptions(
        [value].filter((opt) => opt.not_found || opt.not_allowed),
        { value_id_path, option_paths }
      );
      return invalid;
    }
    return null;
  });

  // we need extra option for any team member that is not in the list
  const extraOptions = useRef<Record<string, TeamMemberOption>>({});
  const {
    options,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    ...typeaheadRest
  } = useTeamMemberTypeaheadInfinite();

  const lookup = useMemo(() => {
    const result = options.reduce(
      (acc: Record<string, TeamMemberOption>, x: TeamMemberOption) => {
        acc[x.value] = x;
        return acc;
      },
      extraOptions.current
    );

    if (init.current && !result[init.current.id]) {
      result[init.current.id] = isDisplayNamesValue(init.current)
        ? optionFromDisplayName(init.current)
        : optionFromTeamMember(init.current);
    }
    if (invalidOption) {
      result[invalidOption.value] = invalidOption;
    }
    return result;
  }, [options, invalidOption]);

  const handleChange = useCallback(
    (option: TeamMemberOption) => {
      if (!option.not_found && !option.not_allowed) {
        setInvalidOption(null);
      }

      const { value, data } = option;
      if (!extraOptions.current[value]) {
        extraOptions.current = {
          ...extraOptions.current,
          [value]: option,
        };
      }
      onChange(step, data);
    },
    [onChange, step]
  );

  const allOptions = useMemo(() => {
    return invalidOption ? options.concat(invalidOption) : options;
  }, [options, invalidOption]);

  const { setMenuList, ref } = useMenuList(
    isFetchingNextPage,
    fetchNextPage,
    LOAD_THRESHOLD
  );

  return (
    <Select
      ref={ref}
      value={value?.id ? lookup[value?.id] : null}
      width={width}
      placeholder={placeholder}
      options={allOptions}
      loadItems={isLoading}
      error={error}
      onChange={handleChange}
      endAdornment={<IconAdornment icon="search" />}
      onMenuOpen={() =>
        setTimeout(() => setMenuList(ref.current?.select.menuListRef), 50)
      }
      {...typeaheadRest}
    />
  );
};
