import React, { useCallback, useContext } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';
import OpportunityConversionReportType from './reportTypes/OpportunityConversion';
import RecordsAddedLostReportType from './reportTypes/RecordsAddedWonLost';
import PipelineValueOverTimeReportType from './reportTypes/PipelineValueOverTime';
import SalesLeaderboardWithProjectionsReportType from './reportTypes/SalesLeaderboardWithProjections';
import StagesOverTimeReportType from './reportTypes/StagesOverTime';

const PipelineAreaDisplaySettings = ({
  mobile = false,
  defaultName = '',
  active = false,
}) => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const { name, area } = state;

  const onChangeName = useCallback(
    (value) => {
      return setState('name', value);
    },
    [setState]
  );

  // Hide self if area is not pipelines
  if (area !== AREA_RESPONSES.PIPELINES) {
    return null;
  }

  return (
    <>
      {/* These components will dynamically hide and show based on the wizard state */}
      <OpportunityConversionReportType
        name={name}
        defaultName={defaultName}
        onChangeName={onChangeName}
      />
      <RecordsAddedLostReportType
        mobile={mobile}
        defaultName={defaultName}
        onChangeName={onChangeName}
        active={active}
      />
      <PipelineValueOverTimeReportType
        mobile={mobile}
        name={name}
        defaultName={defaultName}
        onChangeName={onChangeName}
        active={active}
      />
      <SalesLeaderboardWithProjectionsReportType
        name={name}
        defaultName={defaultName}
        onChangeName={onChangeName}
      />
      <StagesOverTimeReportType
        name={name}
        defaultName={defaultName}
        onChangeName={onChangeName}
        active={active}
      />
    </>
  );
};

export default PipelineAreaDisplaySettings;
