import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  generatePath,
  matchPath,
  useLocation,
} from 'react-router-dom';

import { colorsButton } from 'app/colors';
import { breakpoints, gutters } from 'app/spacing';
import {
  commonNavItemStyle,
  navItemHoverColor,
  regularNavItemWidth,
  SubNavigationNavItemDivider,
} from './styles';
import NavItemContent from './NavItemContent';

const regularNavItemStyle = css`
  text-align: center;
  &.active {
    span,
    i {
      ${navItemHoverColor}
    }
    @media (max-width: ${breakpoints.md}px) {
      box-shadow: inset ${colorsButton.blue.hover} 0 -1px 0;
    }
  }
  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    // Equivalent of 10px above capline and below baseline
    padding: calc(${gutters.spacing(2, { baseline: true })}px + 2px) 0
      calc(${gutters.spacing(2, { baseline: true })}px);
    justify-content: center;
    span {
      margin: 0;
    }
  }
`;

const smallNavItemStyle = css`
  && {
    padding: ${gutters.spacing(2, 1)}px ${gutters.spacing(8)}px
      ${gutters.spacing(2, { baseline: true })}px !important;
    &.active span {
      ${navItemHoverColor}
    }
    border-bottom: 1px solid transparent;
    &.active {
      border-bottom: 1px solid ${colorsButton.blue.hover};
    }
    > span {
      margin: 0;
    }
    > i {
      display: none;
    }
  }
`;

export const StyledSubNavigationNavLink = styled(Nav.Link)`
  ${commonNavItemStyle}
  ${({ small }) => (small ? smallNavItemStyle : regularNavItemStyle)}
  ${({ small, numberOfLinks }) => !small && regularNavItemWidth(numberOfLinks)}
`;

export const StyledSubNavigationNavItem = styled.div`
  ${commonNavItemStyle}
  ${({ small }) => (small ? smallNavItemStyle : regularNavItemStyle)};
  ${({ small, numberOfLinks }) => !small && regularNavItemWidth(numberOfLinks)}
  cursor: pointer;
`;

export const SubNavigationNavLink = ({
  route,
  isLast,
  params = {},
  saveLocationOnNavigate,
  icon,
  noCustomIcon,
  ...rest
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <>
      <StyledSubNavigationNavLink
        as={NavLink}
        to={{
          pathname: generatePath(route.path, params),
          state: saveLocationOnNavigate ? { prevLocation: location } : {},
        }}
        // There are routes that have active class as parent components although they shouldn't
        // show as active, this way we override that react router feature and add active class only
        // when route path is matched with current path
        isActive={(_, { pathname }) => matchPath(pathname, route)}
        {...rest}
      >
        <NavItemContent
          icon={icon || route.icon}
          label={
            typeof route.label === 'function' ? route.label(t) : route.label
          }
          noCustomIcon={noCustomIcon}
        />
      </StyledSubNavigationNavLink>
      {!isLast && <SubNavigationNavItemDivider />}
    </>
  );
};

export const SubNavigationNavItem = ({ icon, label, isLast, ...rest }) => (
  <>
    <StyledSubNavigationNavItem {...rest}>
      <NavItemContent icon={icon} label={label} />
    </StyledSubNavigationNavItem>
    {!isLast && <SubNavigationNavItemDivider />}
  </>
);
