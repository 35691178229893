import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TextInput from 'components/Inputs/TextInput';

export const SubjectLine = React.memo(
  ({ errorMessage, showError, ...rest }) => {
    const { t } = useTranslation();

    return (
      <TextInput
        variant="underline"
        label={t('Subject Line')}
        validate={{ message: errorMessage, showMessage: showError }}
        {...rest}
        data-qa="builder-subject-line"
      />
    );
  }
);

SubjectLine.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.bool,
  showError: PropTypes.bool,
};

SubjectLine.defaultProps = {
  errorMessage: '',
  showError: false,
};
