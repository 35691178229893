import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  GenericWizardBaseModal,
  GenericWizardModalBody,
  GenericWizardModalFooter,
  GenericWizardModalHeader,
  GenericWizardSection,
} from 'components/GenericWizard';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { BROADCAST_FORMDATA, defaultFilterConfig } from './constants';
import { EventTypesButtons } from './EventTypesButtons';
import { useDirtyState } from 'pages/Broadcasts/hooks/useDirtyState';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { BroadcastDate } from './BroadcastDate';
import { useStepsState } from '../../hooks/useStepsState';
import {
  getBroadcastFormData,
  getPayload,
  getSmartSendTimeSplitPercentage,
  validateDate,
  isTodayDate,
  getInitialFilterState,
} from './helpers';
import { BroadcastContentBuilder } from './BroadcastContentBuilder/index';
import { BroadcastFilters } from './BroadcastFilters';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import { isCustomFilter } from 'ts-filters/legacy';
import { getEventAccess } from 'pages/Broadcasts/helpers';
import { toastVariant, useToast } from 'components/ToastProvider';
import { EVENT_STATUSES } from 'pages/Broadcasts/constants';

import useFilters from 'components/Wizards/MetaFilters/hooks/useFilters';
import {
  WizardFilterContext,
  useWizardFilterContext,
} from 'components/Wizards/MetaFilters/context';

const ScheduleBroadcastModalCore = ({
  broadcast,
  setBroadcast,
  businessTimezone,
  onHide,
  onConfirm,
  onDelete,
  access,
  clientObject,
  usingCache,
  ...modalProps
}) => {
  const [showToast] = useToast();
  const { t } = useTranslation();

  const filterContext = useWizardFilterContext();

  const {
    setFilterHasErrors,
    setHasErrors,
    operation,
    filterOps: { build, validate, reset },
  } = filterContext;

  const [isValidDate, setIsValidDate] = useState(
    validateDate(broadcast[BROADCAST_FORMDATA.date])
  );
  const [isTodaySmartDate, setIsTodaySmartDate] = useState(
    getSmartSendTimeSplitPercentage(broadcast)
      ? isTodayDate(
          broadcast[BROADCAST_FORMDATA.rescheduleAt] ||
            broadcast[BROADCAST_FORMDATA.date]
        )
      : false
  );

  const [smartSendTimeSplitPercentage, setSmartSendTimeSplitPercentage] =
    useState(getSmartSendTimeSplitPercentage(broadcast));
  const [
    isSmartSendTimeSplitPercentageValid,
    setIsSmartSendTimeSplitPercentageValid,
  ] = useState(true);

  // data ref is to keep track of the broadcast data without re-rendering the modal and is used to build the payload
  const dataRef = useRef({ ...broadcast });
  const { readySteps, onUpdateStep } = useStepsState(broadcast);
  const { first, second } = readySteps;

  // valid when all steps are viewable, date is valid and smart send is not active
  const valid = useMemo(() => {
    return (
      isValidDate &&
      Object.values(readySteps).every(Boolean) &&
      !isTodaySmartDate
    );
  }, [readySteps, isValidDate, isTodaySmartDate]);

  const { remove: canDelete, edit: canEdit } = useMemo(
    () =>
      getEventAccess(
        broadcast[BROADCAST_FORMDATA.type]
          ? access[
              EVENT_TYPES[broadcast[BROADCAST_FORMDATA.type]]?.accessKey
            ] || {}
          : { edit: true },
        broadcast.access
      ),
    [access, broadcast]
  );

  const {
    showConfirmation,
    onChangeData,
    onCheckToHide,
    onHideConfirmed,
    onHideCanceled,
  } = useDirtyState(broadcast, dataRef, onHide, canEdit, filterContext);

  const setFormData = useCallback(
    (key, value) => {
      if (key === BROADCAST_FORMDATA.smartSendTimeSplitPercentage) {
        const smartSendTimeSplitPercentage = value
          ? (value / 100).toString()
          : value === undefined
            ? undefined
            : 0;
        setIsTodaySmartDate(
          smartSendTimeSplitPercentage
            ? isTodayDate(
                dataRef.current[BROADCAST_FORMDATA.rescheduleAt] ||
                  dataRef.current[BROADCAST_FORMDATA.date]
              )
            : false
        );
        setIsSmartSendTimeSplitPercentageValid(
          smartSendTimeSplitPercentage !== 0
        );
        dataRef.current[BROADCAST_FORMDATA.action] = {
          ...dataRef.current[BROADCAST_FORMDATA.action],
          [BROADCAST_FORMDATA.smartSendTimeSplitPercentage]:
            smartSendTimeSplitPercentage,
        };
        setSmartSendTimeSplitPercentage(
          getSmartSendTimeSplitPercentage(dataRef.current)
        );
      } else if (key === BROADCAST_FORMDATA.action) {
        dataRef.current[BROADCAST_FORMDATA.action] = {
          ...dataRef.current[BROADCAST_FORMDATA.action],
          ...value,
        };
      } else {
        dataRef.current[key] = value;
      }
      if (key === BROADCAST_FORMDATA.type) {
        // we would to resset the filters when the type is changed here,
        // but it makes setFormData unstable so it's done in EventTypesButtons
        if (value !== EVENT_TYPES.email.type) {
          setSmartSendTimeSplitPercentage(undefined);
          setIsTodaySmartDate(false);
        }
        setBroadcast((prev) =>
          getBroadcastFormData({
            id: prev.id,
            [BROADCAST_FORMDATA.type]: value,
            [BROADCAST_FORMDATA.date]: prev[BROADCAST_FORMDATA.date],
            customObject:
              value === EVENT_TYPES.email.type ? clientObject : undefined,
          })
        );
        dataRef.current[BROADCAST_FORMDATA.customObjectId] =
          value === EVENT_TYPES.email.type ? clientObject?.id : undefined;
      }
      onChangeData(dataRef.current);
      onUpdateStep(dataRef.current);
    },
    [onChangeData, onUpdateStep, clientObject, setBroadcast]
  );

  // changing the broadcast type will reset the broadcast data
  const setBroadcastObject = useCallback(
    (model) => {
      reset();
      setBroadcast((prev) => ({
        ...prev,
        customObject: model,
        ...defaultFilterConfig,
      }));
    },
    [setBroadcast, reset]
  );

  const isEdit = Boolean(broadcast.id);
  const isSmartSendEventSending = Boolean(
    (getSmartSendTimeSplitPercentage(broadcast) &&
      !validateDate(broadcast[BROADCAST_FORMDATA.date])) ||
      broadcast[BROADCAST_FORMDATA.status] === EVENT_STATUSES.prestarted
  );

  const isDisabled = !canEdit || isSmartSendEventSending;

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    let filterConfig = null;
    if (isCustomFilter(dataRef.current[BROADCAST_FORMDATA.filterType])) {
      const { errors, hasErrors } = validate();
      if (hasErrors) {
        setFilterHasErrors({ errors, hasErrors });
        return;
      }
      setHasErrors(false);
      filterConfig = build(operation === 'and');
    }
    if (!validateDate(dataRef.current[BROADCAST_FORMDATA.date])) {
      setIsValidDate(false);
    } else if (
      smartSendTimeSplitPercentage &&
      isTodayDate(
        dataRef.current[BROADCAST_FORMDATA.rescheduleAt] ||
          dataRef.current[BROADCAST_FORMDATA.date]
      ) &&
      !isSmartSendEventSending
    ) {
      setIsTodaySmartDate(true);
    } else {
      if (
        isEdit &&
        broadcast[BROADCAST_FORMDATA.rescheduleAt] &&
        broadcast[BROADCAST_FORMDATA.date] ===
          dataRef.current[BROADCAST_FORMDATA.date]
      ) {
        dataRef.current[BROADCAST_FORMDATA.date] =
          dataRef.current[BROADCAST_FORMDATA.rescheduleAt];
      }
      setSubmitting(true);
      await onConfirm(isEdit, getPayload(dataRef.current, filterConfig, t));
      setSubmitting(false);
    }
  }, [
    onConfirm,
    isEdit,
    build,
    operation,
    setFilterHasErrors,
    setHasErrors,
    validate,
    isSmartSendEventSending,
    smartSendTimeSplitPercentage,
    broadcast,
    t,
  ]);

  useEffect(() => {
    isEdit &&
      isSmartSendEventSending &&
      showToast({
        variant: toastVariant.ALERT,
        message: t(
          "Processing for the scheduled event '{{broadcastName}}' has already started and can't be edited.",
          {
            broadcastName: broadcast[BROADCAST_FORMDATA.name],
          }
        ),
      });
  }, [isEdit, broadcast, showToast, t, isSmartSendEventSending]);

  return (
    <>
      <GenericWizardBaseModal
        {...modalProps}
        size="large"
        fitContent="y"
        onHide={onCheckToHide}
        data-qa="schedule-broadcast-modal"
      >
        <GenericWizardModalHeader onClickClose={onCheckToHide}>
          {isEdit ? t(`Edit Broadcast`) : t('Schedule New Broadcast')}
        </GenericWizardModalHeader>
        <GenericWizardModalBody>
          <GenericWizardSection header={t('Broadcast Settings')} isActive>
            <BroadcastDate
              date={
                broadcast[BROADCAST_FORMDATA.rescheduleAt] ??
                broadcast[BROADCAST_FORMDATA.date]
              }
              isValidDate={isValidDate}
              isTodaySmartDate={isTodaySmartDate}
              smartSendTimeSplitPercentage={smartSendTimeSplitPercentage}
              setIsTodaySmartDate={setIsTodaySmartDate}
              setIsValidDate={setIsValidDate}
              setFormData={setFormData}
              businessTimezone={businessTimezone}
              disabled={isDisabled || isSmartSendEventSending}
            />
            <EventTypesButtons
              setFormData={setFormData}
              type={broadcast[BROADCAST_FORMDATA.type]}
              smartSendTimeSplitPercentage={smartSendTimeSplitPercentage}
              disabled={!canEdit}
              isSmartSendEventSending={isSmartSendEventSending}
              access={access}
              isEdit={isEdit}
            />
          </GenericWizardSection>
          <GenericWizardSection
            header={t('Customize Your Broadcast')}
            isActive={first}
          >
            {first ? (
              <BroadcastContentBuilder
                key={broadcast[BROADCAST_FORMDATA.type]}
                type={broadcast[BROADCAST_FORMDATA.type]}
                name={broadcast[BROADCAST_FORMDATA.name]}
                action={broadcast[BROADCAST_FORMDATA.action]}
                setFormData={setFormData}
                customObject={broadcast.customObject}
                setBroadcastObject={setBroadcastObject}
                disabled={isDisabled || isSmartSendEventSending}
              />
            ) : null}
          </GenericWizardSection>
          {first ? (
            <BroadcastFilters
              filterType={broadcast[BROADCAST_FORMDATA.filterType]}
              filterConfig={broadcast[BROADCAST_FORMDATA.filterConfig]}
              filterGroups={broadcast.filterGroups}
              setFormData={setFormData}
              disabled={isDisabled || isSmartSendEventSending}
              customObject={broadcast.customObject}
              broadcastType={broadcast[BROADCAST_FORMDATA.type]}
              isEdit={isEdit}
              isActive={second}
              usingCache={usingCache}
            />
          ) : null}
        </GenericWizardModalBody>
        <GenericWizardModalFooter>
          {isSmartSendEventSending ? (
            <>
              <Button onClick={onHide} color="blue" data-qa-action="close">
                {t('Close')}
              </Button>
            </>
          ) : (
            <>
              {isEdit && canDelete ? (
                <Button
                  onClick={onDelete}
                  variant="text"
                  color="red"
                  data-qa-action="delete"
                >
                  {t('Delete')}
                </Button>
              ) : null}
              <Button
                onClick={onCheckToHide}
                variant="text"
                color="blue"
                data-qa-action="close"
              >
                {canEdit ? t('Cancel') : t('Close')}
              </Button>
              {canEdit ? (
                <Button
                  disabled={
                    !valid || !isSmartSendTimeSplitPercentageValid || submitting
                  }
                  onClick={handleSubmit}
                  data-qa-action="save"
                >
                  {isEdit ? t('Save') : t('Schedule')}
                </Button>
              ) : null}
            </>
          )}
        </GenericWizardModalFooter>
      </GenericWizardBaseModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        show={showConfirmation}
        onConfirm={onHideConfirmed}
        onHide={onHideCanceled}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
};

const ScheduleBroadcastModal = memo(
  ({
    broadcast: broadcastProp,
    businessTimezone,
    onHide,
    onConfirm,
    onDelete,
    access,
    clientObject,
    ...modalProps
  }) => {
    // we use state to avoid re-rendering the modal when the broadcast prop changes, we also update it when:
    // 1. the user changes the broadcast type
    // 2. the user changes the client object
    const [broadcast, setBroadcast] = useState(broadcastProp);

    const filterPropsCache = useRef(null);
    const { customObject, filterType, filterConfig, filterGroups } = broadcast;

    const initialFilterState = useMemo(
      () =>
        getInitialFilterState(
          customObject ?? clientObject,
          filterType,
          filterConfig,
          filterGroups ?? [],
          clientObject
        ),
      [customObject, filterType, filterConfig, filterGroups, clientObject]
    );

    const filterProps = useFilters(initialFilterState);

    // We have intruduced a filterCache which we use to keep the modal visible
    // when the filter is being loaded when the object is changed
    if (filterProps.loading) {
      if (!filterPropsCache.current) {
        return null;
      }
    } else {
      filterPropsCache.current = filterProps;
    }

    return (
      <WizardFilterContext
        filterProps={filterPropsCache.current}
        isClient={!filterPropsCache.current.isForCustomObject}
        objectId={filterPropsCache.current?.customObject?.id}
      >
        <ScheduleBroadcastModalCore
          broadcast={broadcast}
          setBroadcast={setBroadcast}
          businessTimezone={businessTimezone}
          onHide={onHide}
          onConfirm={onConfirm}
          onDelete={onDelete}
          access={access}
          clientObject={clientObject}
          usingCache={filterProps.loading}
          {...modalProps}
        />
      </WizardFilterContext>
    );
  }
);

export default ScheduleBroadcastModal;
