import { useSelector } from 'react-redux';
import { getPlacing } from 'store/pageBuilder/selectors';
import { useBuilderContext } from '../BuilderContext';

function getShowControls(nodeId, otherId, query) {
  const controlNodeIds =
    // we need to be sure that node exists in query before call `ancestors`
    // cuz this function throw error instead of return empty []
    otherId && query.node(otherId).get()
      ? [otherId, ...query.node(otherId).ancestors(true)]
      : [];
  return controlNodeIds.includes(nodeId);
}

function getResizingRowShowControls(nodeId, resizingRowId, query) {
  return (
    nodeId === resizingRowId ||
    query.node(resizingRowId).ancestors().includes(nodeId)
  );
}

export const useShowControls = (nodeId, hoveredId, query) => {
  const { activeNode, activeTextEditorId, resizingRowId, scrolling } = useBuilderContext();
  const placing = useSelector(getPlacing);


  if (scrolling) {
    return false;
  }

  // do not show controls for text elements that are in edit mode (toolbar showing)
  if (nodeId === activeTextEditorId) {
    return false;
  }

  if (resizingRowId) {
    return getResizingRowShowControls(nodeId, resizingRowId, query);
  }

  const isActive = activeNode && getShowControls(nodeId, activeNode.id, query);
  const isHovered =
    !placing &&
    !activeTextEditorId &&
    getShowControls(nodeId, hoveredId, query);
  return isActive || isHovered;
};
