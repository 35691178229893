import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from 'react-number-format';
import PropTypes from 'prop-types';
import { FIELD_TYPES } from '../../../../utility/constants';
import TextInput from '..';

import {
  validateWholeNumber,
  negativesValidateWholeNumber,
} from '../NumericValidation/helpers';
import { withWholeNumberValidation } from '../NumericValidation';

const WholeNumberTextInput = React.forwardRef((props, ref) => {
  const { allowNegatives = true, value, disabled } = props;
  const { t } = useTranslation();

  return (
    <TextInput
      ref={ref}
      // The empty, disabled state needs to allow "—" to appear, so we disabled the NumericInput
      Input={disabled && !value ? undefined : NumberInput}
      thousandSeparator
      decimalScale={0}
      validate={{
        ...(allowNegatives
          ? negativesValidateWholeNumber(t)
          : validateWholeNumber(t)),
        ...props.validate,
      }}
      {...props}
    />
  );
});

export default WholeNumberTextInput;

WholeNumberTextInput.displayName = 'WholeNumberTextInput';
WholeNumberTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  allowNegatives: PropTypes.bool,
};
WholeNumberTextInput.defaultProps = {
  disabled: false,
  placeholder: FIELD_TYPES.Integer.placeholder,
  allowNegatives: true,
};

export const toWholeNumber = (str) => {
  if (!str) return null;
  const num = Number.parseInt(str.replace(/,/g, ''), 10);
  if (Number.isNaN(num)) return null;
  return num;
};

export const ValidatedWholeNumberTextInput =
  withWholeNumberValidation(WholeNumberTextInput);
