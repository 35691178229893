import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints, gutters } from 'app/spacing';
import BackButton from 'components/Button/BackButton';
import {
  DesktopContent,
  Toolbar,
  ToolbarWrapper,
  ToolbarLeft,
  centerNameBreak,
} from 'pages/Common/styles/ControlBar';

const StyledToolbarWrapper = styled(ToolbarWrapper)`
  position: relative;
  @media (min-width: ${breakpoints.md + 1}px) {
    padding: 0 ${gutters.spacing(4)}px;
  }
`;

export const BackBreadcrumbToolbar = ({
  goBackPath,
  backLabel,
  children,
  ...rest
}) => {
  return (
    <StyledToolbarWrapper className="toolbar-wrapper" {...rest}>
      <Toolbar className="toolbar">
        <ToolbarLeft nameBreakpoint={centerNameBreak}>
          <BackButton to={goBackPath} style={{ marginRight: 0 }}>
            <DesktopContent>{backLabel}</DesktopContent>
          </BackButton>
        </ToolbarLeft>
        {children}
      </Toolbar>
    </StyledToolbarWrapper>
  );
};

BackBreadcrumbToolbar.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  backLabel: PropTypes.string,
};

BackBreadcrumbToolbar.defaultProps = {
  backLabel: 'back to forms',
};
