import styled from '@emotion/styled';
import Loader from 'components/Kizen/Loader';

export const StyledLoader = styled(Loader)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: white;
`;
