import { useEffect, useRef, useState } from 'react';
import {
  ArrowWrapper,
  LeftArrowButton,
  RightArrowButton,
  StyledArrowIcon,
  StyledQuickFiltersList,
  StyledScrollContainer,
} from './styles';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { colorsButton } from 'app/colors';
import { throttle } from 'lodash';
import { isFirefox } from 'react-device-detect';

export const ScrollOnHoverContainer = ({
  children,
  scrollDeps,
  onScrollStart,
}) => {
  const [scrolling, setScrolling] = useState(false);
  const scrollRef = useRef(null);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);

  const [, right, , left] = useEndsOfScroll(scrollRef, scrollDeps);

  useEffect(() => {
    let timeout;
    let hoverInterval;
    const container = scrollRef.current;
    const leftArrow = leftArrowRef.current;
    const rightArrow = rightArrowRef.current;

    const handleScroll = throttle(() => {
      clearTimeout(timeout);
      setScrolling((prev) => {
        !prev && onScrollStart?.();
        return true;
      });
      timeout = setTimeout(() => setScrolling(false), 500);
    }, 40);

    const handleScrollRight = () => {
      clearInterval(hoverInterval);
      hoverInterval = setInterval(
        () => container.scrollBy({ left: 2, behavior: 'instant' }),
        10
      );
    };
    const handleScrollLeft = () => {
      clearInterval(hoverInterval);
      hoverInterval = setInterval(
        () => container.scrollBy({ left: -2, behavior: 'instant' }),
        10
      );
    };

    const handleBlur = () => {
      clearInterval(hoverInterval);
    };

    container.addEventListener('scroll', handleScroll);
    leftArrow.addEventListener('mouseenter', handleScrollLeft);
    rightArrow.addEventListener('mouseenter', handleScrollRight);
    leftArrow.addEventListener('mouseleave', handleBlur);
    rightArrow.addEventListener('mouseleave', handleBlur);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
      rightArrow?.removeEventListener('mouseenter', handleScrollRight);
      leftArrow?.removeEventListener('mouseenter', handleScrollLeft);
      rightArrow?.removeEventListener('mouseleave', handleBlur);
      leftArrow?.removeEventListener('mouseleave', handleBlur);
    };
  }, [onScrollStart]);

  return (
    <StyledScrollContainer
      ref={scrollRef}
      scrolling={scrolling}
      compensateHeight={!isFirefox && scrolling}
    >
      <ArrowWrapper>
        <LeftArrowButton
          ref={leftArrowRef}
          color={colorsButton.blue}
          show={!left}
        >
          <StyledArrowIcon icon="left-regular" />
        </LeftArrowButton>
      </ArrowWrapper>
      <StyledQuickFiltersList>{children}</StyledQuickFiltersList>
      <ArrowWrapper right>
        <RightArrowButton
          ref={rightArrowRef}
          color={colorsButton.blue}
          show={!right}
        >
          <StyledArrowIcon icon="right-regular" />
        </RightArrowButton>
      </ArrowWrapper>
    </StyledScrollContainer>
  );
};
