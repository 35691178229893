import { AxiosInstance, AxiosResponse } from 'axios';
import { paths } from 'schema';
import { curry1 } from './utils';

export type DisplayNamesRequest = Exclude<
  paths['/api/records/display-names']['post']['requestBody'],
  undefined
>['content']['application/json'];
export type DisplayNamesResponse =
  paths['/api/records/display-names']['post']['responses']['200']['content']['application/json'];

export const getDisplayNames = async (
  instance: AxiosInstance,
  ids: DisplayNamesRequest
): Promise<AxiosResponse<DisplayNamesResponse>> => {
  return instance.post('/records/display-names', ids);
};

export class RecordsService {
  public getDisplayNames;

  constructor(private instance: AxiosInstance) {
    this.getDisplayNames = curry1(getDisplayNames)(this.instance);
  }
}
