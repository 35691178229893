import OptionParent from '../OptionParent';

const Item = ({ style, data, index }) => {
  const {
    options,
    dropZone,
    childDropZone,
    dropFirstChild,
    draggingItem,
    editingItem,
    setDraggingItem,
    endDrag,
    getChildDropZone,
    id,
    editValue,
    setEditingItem,
    editable,
    removeItem,
    editOptions,
    collapseItem,
    expandItem,
    collapsedItems,
    setDropFirstChild,
    moveItemToBottom,
    width,
    renderOption,
    renderEmpty,
  } = data;
  const option = options[index];
  const nextOption = options?.[index + 1];
  return (
    <div
      style={{
        ...style,
        top: `${parseFloat(style.top) + 6}px`,
        paddingRight: '19px',
        paddingLeft: '10px',
      }}
      data-original-index={index}
    >
      <OptionParent
        key={option.id}
        dropZone={dropZone}
        option={option}
        nextOption={nextOption}
        childDropZone={childDropZone}
        dropFirstChild={dropFirstChild}
        draggingItem={draggingItem}
        editingItem={editingItem}
        setDraggingItem={setDraggingItem}
        endDrag={endDrag}
        getChildDropZone={getChildDropZone}
        id={id}
        index={index}
        editValue={editValue}
        setEditingItem={setEditingItem}
        editable={editable}
        removeItem={removeItem}
        editOptions={editOptions}
        collapseItem={collapseItem}
        expandItem={expandItem}
        collapsedItems={collapsedItems}
        setDropFirstChild={setDropFirstChild}
        moveItemToBottom={moveItemToBottom}
        columnWidth={width}
        renderOption={renderOption}
        renderEmpty={renderEmpty}
      />
    </div>
  );
};

export default Item;
