import { FIELD_TYPES } from 'utility/constants';

export const MULTISELECT_FIELDS = [
  FIELD_TYPES.Checkboxes.type,
  FIELD_TYPES.Dropdown.type,
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Radio.type,
  FIELD_TYPES.Relationship.type,
  FIELD_TYPES.Status.type,
  FIELD_TYPES.TeamSelector.type,
  FIELD_TYPES.YesNoMaybe.type,
  FIELD_TYPES.Rating.type,
];

export const NUMBER_FIELDS = [
  FIELD_TYPES.Integer.type,
  FIELD_TYPES.Decimal.type,
  FIELD_TYPES.Money.type,
];

export const DATE_FIELDS = [FIELD_TYPES.Date.type, FIELD_TYPES.DateTime.type];

export const TEXT_FIELDS = [FIELD_TYPES.Text.type];

export const SINGLE_SELECT_FIELDS = [FIELD_TYPES.Checkbox.type];

export const QUICK_FILTERS_FIELD_TYPES = MULTISELECT_FIELDS.concat(
  SINGLE_SELECT_FIELDS,
  NUMBER_FIELDS,
  DATE_FIELDS,
  TEXT_FIELDS
);

export const MAX_FACET_RECORDS = 50_000;
