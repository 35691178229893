import { DefaultHomepageProps } from '@kizen/page-builder/gen';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditor } from '@craftjs/core';
import { created } from 'store/pageBuilder/pageBuilder.redux';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { RowModal } from './RowModal';
import { useAddNewNode } from '../useAddNewNode';
import { DEFAULT_EMAIL_ROW_PROPS, DEFAULT_FORM_ROW_PROPS } from './props';

const CreateRow = ({ defaultProps }) => {
  const dispatch = useDispatch();
  const { actions } = useEditor();
  const rowItem = useSelector((s) => getCreateItemByType(s, 'Row'));
  const addNewNode = useAddNewNode();

  return (
    <RowModal
      show={!!rowItem}
      onHide={() => {
        actions.setIndicator(null);
        dispatch(created(false));
      }}
      onConfirm={(columns) =>
        addNewNode({
          ...rowItem,
          ...defaultProps,
          columns,
        })
      }
    />
  );
};

export const EmailRowCreator = React.createElement(CreateRow, {
  defaultProps: DEFAULT_EMAIL_ROW_PROPS,
});

export const FormRowCreator = React.createElement(CreateRow, {
  defaultProps: DEFAULT_FORM_ROW_PROPS,
});

export const HomepageRowCreator = React.createElement(CreateRow, {
  defaultProps: DefaultHomepageProps.Row,
});
