import React from 'react';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Input, { INPUT_TYPES } from 'components/Kizen/Input';
import { css } from '@emotion/core';

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 ${gutters.spacing(4)}px;
  margin-bottom: ${gutters.spacing(2, -4)}px;
  h3 {
    margin-top: -1px; // Design nudge
    line-height: 1; // Sidestep bootstrap
  }
  button {
    margin-top: 1px; // Design nudge
    background: none;
    outline: none;
    padding: 0;
    color: ${colorsButton.green.default};
    &:hover {
      color: ${colorsButton.green.hover};
    }
  }
  margin-top: ${gutters.spacing(4)}px;
  ${({ tall }) =>
    // Most often used with toolbars that contain a search input
    tall &&
    css`
      margin-top: ${gutters.spacing(2)}px;
      margin-bottom: ${gutters.spacing(0)}px;
      align-items: center;
      button {
        margin-top: -2px;
      }
    `}
`;

export const CardToolbarSection = styled.div`
  display: flex;
`;

export function CardToolbarTitle(props) {
  return <KizenTypography as="h3" type="subheader" {...props} />;
}

export function CardToolbarButton(props) {
  return (
    <KizenTypography
      as="button"
      weight="bold"
      size="buttonLabel"
      textTransform="uppercase"
      {...props}
    />
  );
}

export const CardToolbarSearch = styled((props) => (
  <Input pill type={INPUT_TYPES.SEARCH} {...props} />
))`
  max-width: 200px;
  &:not(:last-child) {
    margin-right: ${gutters.spacing(3)}px;
  }
  label {
    margin: 0;
  }
  .input-wrapper {
    margin: 0;
    background-color: ${grayScale.white};
  }
  input {
    min-width: 0;
    padding-right: 0;
  }
`;
