import { useState, useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Switch from 'components/Kizen/Switch';
import { BasicRichTextEditor } from 'components/WYSIWYG';
import { gutters } from 'app/spacing';
import styled from '@emotion/styled';
import { fontSizes } from 'app/typography';
import TextInput from 'components/Inputs/TextInput';
import { pickBy, isEqual } from 'lodash';

import { toastVariant, useToast } from 'components/ToastProvider';

import BasicModal from 'components/Modals/presets/BasicModal';
import { modalConstrainPopperConfig } from 'components/Inputs/Validation';

import {
  frequencyOptions,
  A_FEW_PER_MONTH_INDEX,
  toOption,
  toValue,
  TOAST_DISPLAY,
  ERROR_STATUS,
} from 'pages/Settings/pages/PrivacySettings/SubscriptionLists/helpers';

import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import Dropdown from 'components/Fields/FieldInput/Dropdown';
import { FIELD_TYPES } from 'utility/constants';
import { FIELD_NAMES } from './formConstants';
import useFormValidation from 'hooks/useFormValidation';

const EMPTY_ARRAY = [];
export const StyledLabel = styled.div`
  margin-bottom: ${gutters.spacing(2, 2)}px;
  font-size: ${fontSizes.text};
`;

const AddStandardComponentMargin = styled.div`
  margin-bottom: ${gutters.spacing(4)}px;
`;

const DEFAULT_SUBSCRIPTION = (t) => ({
  autoSubscribe: true,
  description: '',
  frequency: frequencyOptions(t)[A_FEW_PER_MONTH_INDEX].value,
  frequencyDisplay: frequencyOptions(t)[A_FEW_PER_MONTH_INDEX].label,
  name: '',
});

const SubscriptionList = ({
  context,
  onHide,
  onSave,
  uniqueNames,
  ...others
}) => {
  const [showToast] = useToast();
  const { t } = useTranslation();
  const frequencyOptionsT = useMemo(() => frequencyOptions(t), [t]);
  const { title, subscription } = context;
  const [formData, setFormData] = useState({
    ...DEFAULT_SUBSCRIPTION(t),
    ...subscription,
  });

  const [touchedFormData, setTouchedFormData] = useState(false);

  const {
    validateFormState,
    validationProps,
    handleInputChange,
    setValidationState,
    firstFieldWithErrorRef,
  } = useFormValidation({
    setFormDirty: setTouchedFormData,
    formState: formData,
    setFormState: setFormData,
  });

  const [submitting, setSubmitting] = useState(false);
  const [apiUniqueNames, setApiUniqueNames] = useState(EMPTY_ARRAY);

  const validateUnique = (value) => {
    if (
      value &&
      !(subscription && value === subscription.name) &&
      (uniqueNames.includes(value) || apiUniqueNames.includes(value))
    ) {
      return t(
        'List Names must be unique. Please edit the name and try again.'
      );
    }
    return null;
  };

  const handleSave = async () => {
    if (!validateFormState(t)) {
      return;
    }
    // check unique
    const dupeError = validateUnique(formData[FIELD_NAMES.name]);

    if (dupeError) {
      firstFieldWithErrorRef.current = FIELD_NAMES.name;
      setValidationState({
        [FIELD_NAMES.name]: dupeError,
      });
      return;
    }

    setSubmitting(true);
    let patch;

    if (subscription) {
      // editing pick the changes
      patch = pickBy(formData, (val, key) => !isEqual(val, subscription[key]));
    } else {
      // new make sure frequency is set to null for Not Specified
      const { frequency } = formData;
      patch = { ...formData, frequency: toValue(frequency) };
    }

    try {
      const returnStatus = await onSave(
        subscription,
        patch,
        TOAST_DISPLAY.SUCCESS_ONLY
      );
      if (returnStatus.status === ERROR_STATUS.DUPLICATE_NAME) {
        // add name to the duplicates
        setApiUniqueNames([...apiUniqueNames, formData[FIELD_NAMES.name]]);
        firstFieldWithErrorRef.current = FIELD_NAMES.name;
        setValidationState({
          [FIELD_NAMES.name]: returnStatus.message,
        });
        return;
      }
      if (returnStatus.status === ERROR_STATUS.GENERAL_FAIL) {
        showToast({
          variant: toastVariant.FAILURE,
          message: returnStatus.message,
        });
        return;
      }
      onHide(false);
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = useCallback(
    ({ editor }) => {
      handleInputChange(FIELD_NAMES.description, editor.getHTML(), null);
    },
    [handleInputChange]
  );

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'list-name' },
      { id: 'list-description' },
      { id: 'frequency' },
      { id: 'add-contacts' },
    ],
    {},
    () => true
  );

  return (
    <BasicModal
      heading={title}
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      {...others}
      show
      onHide={() => onHide(touchedFormData)}
      disabled={submitting}
      onConfirm={handleSave}
    >
      <KeyBoardContext.Provider value={{ assignFieldHandle }}>
        <TextInput
          label={`${t('List Name')}`}
          value={formData[FIELD_NAMES.name]}
          onChange={(name) => handleInputChange(FIELD_NAMES.name, name, null)}
          placeholder={t('Enter List Name')}
          margin
          isRequired
          required
          {...{
            ...validationProps(FIELD_NAMES.name),
            validate: {
              ...validationProps(FIELD_NAMES.name).validate,
              popperConfig: {
                ...modalConstrainPopperConfig,
                modifiers: {
                  ...modalConstrainPopperConfig.modifiers,
                  preventOverflow: {
                    enabled: true,
                  },
                },
              },
            },
          }}
          inModal
          {...getKeyListenersProps('list-name')}
          popperConfig={{
            ...modalConstrainPopperConfig,
            modifiers: {
              ...modalConstrainPopperConfig.modifiers,
              offset: { offset: '0, -5px' }, // Touch top of text
              preventOverflow: {
                enabled: true,
              },
            },
          }}
        />
        <AddStandardComponentMargin>
          <StyledLabel>{t('List Description')}</StyledLabel>
          <BasicRichTextEditor
            initialValue={formData[FIELD_NAMES.description]}
            value={formData[FIELD_NAMES.description]}
            placeholder={t('Enter List Description')}
            onChange={handleChange}
            initialHeight={135}
            enableResize={true}
            enableTextStyles={true}
            {...getKeyListenersProps('list-description')}
          />
        </AddStandardComponentMargin>
        <Dropdown
          label={t('Frequency')}
          options={frequencyOptionsT}
          value={toOption(formData[FIELD_NAMES.frequency])}
          onChange={(frequency) => {
            const { label } = frequencyOptionsT.find(
              ({ value }) => value === frequency
            );
            handleInputChange(FIELD_NAMES.frequency, toValue(frequency), null);
            handleInputChange(FIELD_NAMES.frequencyDisplay, label, null);
          }}
          menuInline
          fullWidth
          placeholder={t('Choose Frequency')}
          margin
          field={{
            fieldType: FIELD_TYPES.Dropdown,
            options: frequencyOptionsT,
          }}
          menuLeftButton={null}
          menuRightButton={null}
          {...getKeyListenersProps('frequency')}
        />

        <Switch
          label={t('Auto Add Contacts to List?')}
          textPlacement="regular-top"
          checked={formData[FIELD_NAMES.autoSubscribe]}
          onChange={({ target }) =>
            handleInputChange(FIELD_NAMES.autoSubscribe, target.checked, null)
          }
          {...getKeyListenersProps('add-contacts')}
        />
      </KeyBoardContext.Provider>
    </BasicModal>
  );
};

SubscriptionList.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  uniqueNames: PropTypes.array,
};

SubscriptionList.defaultProps = {
  uniqueNames: [],
};
export default SubscriptionList;
