import { useEffect, useMemo } from 'react';
import { debugMode } from './useDataManager';
import { useInstanceId, useRegister, useSetVisibility } from './getters';

export const VERTICAL_VISIBILITY_LOOKAHEAD = 400;

export const useVisibility = ({
  id,
  index,
  ref,
  shouldRegister = true,
  queryCount = 1,
  instanceId: externalInstanceId,
}) => {
  const register = useRegister();
  const setVisibility = useSetVisibility();
  const internalInstanceId = useInstanceId();

  useEffect(() => {
    if (id && shouldRegister && externalInstanceId === internalInstanceId) {
      register(id, index);
    } else if (debugMode) {
      console.log(
        `%c[DATA MANAGER] - skipping registration for ${id} due to ongoing transition`,
        'color: #d1d1d1;'
      );
    }
  }, [
    id,
    index,
    register,
    shouldRegister,
    externalInstanceId,
    internalInstanceId,
  ]);

  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        if (entries[0]) {
          const { isIntersecting } = entries[0];
          setVisibility(id, isIntersecting, queryCount);
        }
      },
      {
        rootMargin: `${VERTICAL_VISIBILITY_LOOKAHEAD}px 0px`,
      }
    );
  }, [setVisibility, id, queryCount]);

  useEffect(() => {
    const elem = ref?.current;

    if (elem && shouldRegister) {
      observer.observe(elem);
    }
    return () => {
      if (elem && shouldRegister) {
        observer.unobserve(elem);
      }
    };
  }, [observer, ref, shouldRegister]);
};
