import {
  ACTIVITY_NOTIFICATION_EMAIL,
  ACTIVITY_NOTIFICATION_SMS,
} from 'services/ActivityService';
import { getTeamLabel } from 'services/helpers';

export const getInitialEmailSubscribers = (subscribers) => {
  return subscribers
    .filter(
      ({ notificationType }) => notificationType === ACTIVITY_NOTIFICATION_EMAIL
    )
    .map(({ employee }) => ({
      value: employee.id,
      label: getTeamLabel(employee),
      notificationType: ACTIVITY_NOTIFICATION_EMAIL,
    }));
};

export const getInitialSmsSubscribers = (subscribers) => {
  return subscribers
    .filter(
      ({ notificationType }) => notificationType === ACTIVITY_NOTIFICATION_SMS
    )
    .map(({ employee }) => ({
      value: employee.id,
      label: getTeamLabel(employee),
      notificationType: ACTIVITY_NOTIFICATION_SMS,
    }));
};
