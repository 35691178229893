import styled from '@emotion/styled';
import { DateArea } from 'components/Timeline2/EventBlocks/styles';
import Icon from 'components/Kizen/Icon';
import { breakpoints, gutters, hideScrollbarCss } from 'app/spacing';
import { grayScale, shadowLight } from 'app/colors';
import { EventIcon } from '../EventIcon';
import KizenTypography from 'app/kizentypo';
import css from '@emotion/css';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import { Cell, tileCell } from 'pages/Broadcasts/styles';
import Select from 'components/Inputs/Select';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import IconButton from 'components/Kizen/IconButton';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: ${gutters.spacing(7, 1)}px 1fr;
  gap: ${gutters.spacing(1, 3)}px 0;
  margin-bottom: ${gutters.spacing(6)}px;
`;

export const MainIcon = styled(EventIcon)`
  grid-row: 1 / 3;
  align-self: center;
  margin-right: ${gutters.spacing(3)}px;
`;

export const ActionIcon = styled(Icon)`
  margin-left: ${({ marginLeft }) => marginLeft};
`;

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${gutters.spacing(3)}px;
  overflow: hidden;
`;

export const FilterContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${gutters.spacing(4)}px;
  padding: ${gutters.spacing(4)}px ${gutters.spacing(11)}px;

  @media (max-width: ${breakpoints.sm}px),
    (min-width: ${breakpoints.lg + 1}px) and (max-width: 1040px) {
    gap: ${gutters.spacing(2)}px;
  }

  ${({ scrolled }) =>
    scrolled &&
    css`
      ${shadowLight};
      border-bottom: 1px solid ${grayScale.medium};
    `}
`;

export const EventsHeaderWrapper = styled.div`
  width: 100%;
`;

export const EventsScrollWrapper = styled.div`
  display: flex;
  flex: 1;
  max-height: calc(100% - ${({ offSet }) => offSet || 0}px);
  width: 100%;
`;

export const EventsScrollContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  padding: 0 ${gutters.spacing(4)}px;
  width: calc(100% - ${gutters.spacing(2)}px);
  padding-right: ${gutters.spacing(2)}px;
  overflow-y: auto;
  & > div {
    margin-top: ${gutters.spacing(4)}px;
  }
`;

export const NoDataMessage = styled(KizenTypography)`
  margin-bottom: 40px;
`;

export const ActionIconsContainer = styled.div`
  display: flex;
  padding-left: ${gutters.spacing(4)}px;
`;

export const DateWrapper = styled(DateArea)`
  flex-shrink: 0;
  margin-left: ${gutters.spacing(4)}px;
  && {
    padding-top: 0;
  }
  color: ${({ color }) => color || grayScale.mediumDark};
`;

export const EventsCell = styled(Cell)`
  overflow: hidden;
  ${tileCell}
  // 95 = grid vertical margin (30) + row 1 (50) + grid gap (15)
  height: calc(100vh - 95px - ${({ navBarHeight = 0 }) => `${navBarHeight}px`});

  @media (min-width: ${breakpoints.md + 1}px) {
    // 110 = grid vertical margin (40) + row 1 (50) + grid gap (20)
    height: calc(
      100vh - 110px - ${({ navBarHeight = 0 }) => `${navBarHeight}px`}
    );
  }
`;

export const EventActionsSelect = styled(Select)`
  width: fit-content !important;
`;

export const StyledScrollBarDetached = styled(ScrollBarDetached)`
  position: relative;
  left: 1px;
`;

export const StyledActionsButton = styled(IconButton)`
  &:not(:last-child) {
    margin-right: ${gutters.spacing(2)}px;
  }
`;
