import styled from '@emotion/styled';
import CustomObjectsService from 'services/CustomObjectsService';
import PipelineService from 'services/PipelineService';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import { isStandardObject } from 'components/Modals/utilities';
import RowMenuCell from 'components/Tables/Big/RowMenuCell';
import NewRowMenuCell from 'components/Tables/Big/NewRowMenuCell';
import { getColumnEditable } from 'components/Fields/InlineTableFieldInput';
import {
  CheckboxCell,
  CheckboxMenuCell,
  ColumnsIconLinkCell,
} from 'pages/Common/components/BigTableCells';
import { SortableHeadCell, CustomFieldHeadCell } from './Table';
import {
  getCellForFieldType,
  CustomObjectNameCell,
  CustomObjectOwnerCell,
  CustomObjectEntityValueCell,
  CustomObjectStageCell,
  CustomObjectEstimatedCloseDateCell,
  CustomObjectActualCloseDateCell,
  CustomObjectDateCreatedCell,
  CustomObjectLastModifiedCell,
  CustomObjectChanceToCloseCell,
} from 'components/Layout/BigTableLayout/InlineCells';
import { getColumnWidth } from 'components/Fields/InlineTableFieldInput';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';

const StyledCheckboxMenuCell = styled(CheckboxMenuCell)`
  & {
    label {
      margin: 0 21px 0 15px;
    }
  }
`;

const StyledCheckboxCell = styled(CheckboxCell)`
  & {
    label {
      margin: 0 21px 0 15px;
    }
  }
`;

const getDefaultField = (name, fields) => {
  return fields.find((f) => (f.isDefault || !f.isDeletable) && f.name === name);
};

const getEditable = ({ access } = {}) => {
  return Boolean(access && access.edit);
};

const defaultFieldWidths = {
  nameField: 200,
  ownerField: 150,
  entityValueField: 150,
  stageField: 150,
  estimatedCloseDate: 160,
  actualCloseDateField: 150,
  createdField: 150,
  updatedField: 150,
  percentageChanceToClose: 150,
};

const getBasicColumns = ({
  onSubmitRecord,
  model,
  fields,
  handleUpdateFieldOption,
  handleUpdateTableRecords,
  serviceToUse,
  columnSettings,
  t,
}) => {
  const nameField = getDefaultField(DEFAULT_FIELD_NAMES.name, fields);
  const ownerField = getDefaultField(DEFAULT_FIELD_NAMES.owner, fields);
  const entityValueField = getDefaultField(
    DEFAULT_FIELD_NAMES.entityValue,
    fields
  );
  const stageField = getDefaultField(DEFAULT_FIELD_NAMES.stage, fields);
  const estimatedCloseDate = getDefaultField(
    DEFAULT_FIELD_NAMES.estimatedCloseDate,
    fields
  );
  const actualCloseDateField = getDefaultField(
    DEFAULT_FIELD_NAMES.actualCloseDate,
    fields
  );
  const percentageChanceToClose = getDefaultField(
    DEFAULT_FIELD_NAMES.percentageChanceToClose,
    fields
  );

  return {
    name: {
      headCell: (
        <SortableHeadCell
          label={nameField?.description}
          minWidth={`${defaultFieldWidths.nameField}px`}
          columnSettings={columnSettings}
        />
      ),
      meta: { editable: getEditable(nameField) },
      isRequired: true,
      errorMessage:
        model.objectType !== 'standard'
          ? t(
              'The Name and Stage fields are required to create an entity record in Kizen. Please edit and save.'
            )
          : t(
              'The Name is required to create an entity record in Kizen. Please edit and save.'
            ),
      bodyCell: {
        field: nameField,
        onSubmit: onSubmitRecord,
        shouldFocusNext: false,
        serviceToUse,
        model,
        object: model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth: `${defaultFieldWidths.nameField}px`,
        Component: CustomObjectNameCell,
        isCustomObjects: true,
        meta: { editable: getEditable(nameField) },
        isRequired: true,
        errorMessage:
          model.objectType !== 'standard'
            ? t(
                'The Name and Stage fields are required to create an entity record in Kizen. Please edit and save.'
              )
            : t(
                'The Name is required to create an entity record in Kizen. Please edit and save.'
              ),
      },
    },
    owner: {
      headCell: (
        <SortableHeadCell
          minWidth={`${defaultFieldWidths.ownerField}px`}
          columnSettings={columnSettings}
        />
      ),
      meta: { editable: getEditable(ownerField) },
      bodyCell: {
        field: ownerField,
        onSubmit: onSubmitRecord,
        serviceToUse,
        shouldFocusNext: false,
        model,
        object: model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth: `${defaultFieldWidths.ownerField}px`,
        Component: CustomObjectOwnerCell,
        isCustomObjects: true,
        meta: { editable: getEditable(ownerField) },
      },
    },
    ...(entityValueField
      ? {
          entityValue: {
            headCell: (
              <SortableHeadCell
                label={entityValueField && entityValueField.description}
                numeric
                minWidth={`${defaultFieldWidths.entityValueField}px`}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: getEditable(entityValueField) },
            bodyCell: {
              field: entityValueField,
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              shouldFocusNext: false,
              object: model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth: `${getColumnWidth(entityValueField)}px`,
              Component: CustomObjectEntityValueCell,
              isCustomObjects: true,
              meta: { editable: getEditable(entityValueField) },
            },
          },
        }
      : {}),
    stage: {
      headCell: (
        <SortableHeadCell
          minWidth={`${defaultFieldWidths.stageField}px`}
          columnSettings={columnSettings}
        />
      ),
      meta: { editable: getEditable(stageField) },
      isRequired: true,
      errorMessage: t(
        'The Name and Stage fields are required to create an entity record in Kizen. Please edit and save.'
      ),
      bodyCell: {
        field: stageField,
        onSubmit: onSubmitRecord,
        serviceToUse,
        model,
        shouldFocusNext: false,
        object: model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth: `${defaultFieldWidths.stageField}px`,
        Component: CustomObjectStageCell,
        isCustomObjects: true,
        meta: { editable: getEditable(stageField) },
      },
    },
    ...(estimatedCloseDate
      ? {
          estimatedCloseDate: {
            headCell: (
              <SortableHeadCell
                numeric
                minWidth={`${defaultFieldWidths.estimatedCloseDate}px`}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: getEditable(estimatedCloseDate) },
            bodyCell: {
              shouldFocusNext: false,
              field: estimatedCloseDate,
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              object: model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth: `${defaultFieldWidths.estimatedCloseDate}px`,
              Component: CustomObjectEstimatedCloseDateCell,
              isCustomObjects: true,
              meta: { editable: getEditable(estimatedCloseDate) },
            },
          },
        }
      : {}),
    actualCloseDate: {
      headCell: (
        <SortableHeadCell
          numeric
          minWidth={`${defaultFieldWidths.actualCloseDateField}px`}
          columnSettings={columnSettings}
        />
      ),
      meta: { editable: false },
      bodyCell: {
        field: actualCloseDateField,
        onSubmit: onSubmitRecord,
        shouldFocusNext: false,
        serviceToUse,
        model,
        object: model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth: `${defaultFieldWidths.actualCloseDateField}px`,
        Component: CustomObjectActualCloseDateCell,
        isCustomObjects: true,
        meta: { editable: getEditable(actualCloseDateField) },
        showDateTooltip: true,
      },
    },
    created: {
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Date Created')}
          minWidth={`${defaultFieldWidths.createdField}px`}
          columnSettings={columnSettings}
        />
      ),
      meta: { editable: false },
      bodyCell: {
        field: {
          access: { view: true, edit: false, remove: false },
          isDefault: true,
        },
        shouldFocusNext: false,
        onSubmit: onSubmitRecord,
        serviceToUse,
        model,
        object: model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth: `${defaultFieldWidths.createdField}px`,
        Component: CustomObjectDateCreatedCell,
        isCustomObjects: true,
        showDateTooltip: true,
      },
    },
    updated: {
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Last Modified')}
          minWidth={`${defaultFieldWidths.updatedField}px`}
          columnSettings={columnSettings}
        />
      ),
      meta: { editable: false },
      bodyCell: {
        field: {
          access: { view: true, edit: false, remove: false },
          isDefault: true,
        },
        shouldFocusNext: false,
        onSubmit: onSubmitRecord,
        serviceToUse,
        model,
        object: model,
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        minWidth: `${defaultFieldWidths.updatedField}px`,
        Component: CustomObjectLastModifiedCell,
        isCustomObjects: true,
        showDateTooltip: true,
      },
    },
    ...(percentageChanceToClose
      ? {
          percentageChanceToClose: {
            headCell: (
              <SortableHeadCell
                label={
                  percentageChanceToClose?.displayName ||
                  percentageChanceToClose?.description
                }
                numeric
                minWidth={`${defaultFieldWidths.percentageChanceToClose}px`}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: getEditable(percentageChanceToClose) },
            bodyCell: {
              shouldFocusNext: false,
              field: percentageChanceToClose,
              onSubmit: onSubmitRecord,
              serviceToUse,
              model,
              object: model,
              handleUpdateFieldOption,
              handleUpdateTableRecords,
              minWidth: `${defaultFieldWidths.percentageChanceToClose}px`,
              Component: CustomObjectChanceToCloseCell,
              isCustomObjects: true,
              meta: {
                editable: getEditable(percentageChanceToClose),
                stageField,
              },
            },
          },
        }
      : {}),
  };
};

export const getRecordsColumns = ({
  model,
  fields,
  columnSettings,
  onSelectAction,
  onSubmitRecord: onSubmitRecordProp,
  indeterminate,
  handleUpdateFieldOption,
  handleUpdateTableRecords,
  t,
}) => {
  const fieldsById = fields.reduce(
    (collect, field) => ({
      ...collect,
      [field.id]: field,
    }),
    {}
  );

  const serviceToUse = isStandardObject(model)
    ? CustomObjectsService
    : PipelineService;

  const basicColumns = getBasicColumns({
    onSubmitRecord: onSubmitRecordProp,
    model,
    fields,
    handleUpdateFieldOption,
    handleUpdateTableRecords,
    serviceToUse,
    columnSettings,
    t,
  });

  return [
    {
      id: 'selection',
      cell: <StyledCheckboxCell indeterminate={indeterminate} />,
      headCell: (
        <StyledCheckboxMenuCell
          data-qa-selection-checkbox-menu
          indeterminate={indeterminate}
        />
      ),
    },
    ...columnSettings
      .filter(({ visible }) => visible)
      .map(({ id, label }) => {
        if (basicColumns[id]) {
          return {
            id,
            label,
            ...basicColumns[id],
          };
        }
        const field = fieldsById[id];
        return {
          id,
          label,
          headCell: (
            <CustomFieldHeadCell
              field={field}
              hasSorting={field?.fieldType !== FIELD_TYPES.Relationship.type}
            />
          ),
          bodyCell: {
            field,
            displayMaskedValue: true,
            shouldFocusNext: false,
            onSubmit: onSubmitRecordProp,
            serviceToUse: serviceToUse,
            fetchUrl: `custom-objects/${model.id}`,
            model,
            object: model,
            handleUpdateFieldOption,
            handleUpdateTableRecords,
            minWidth: `${getColumnWidth(field)}px`,
            menuLeftButton: <ClearSelectButton />,
            Component: getCellForFieldType(field.fieldType), // cellMap.get(field.fieldType) || MissedCell,
            isCustomObjects: true,
          },

          meta: { editable: getEditable(field) && getColumnEditable(field) },
          format: (x) => x,
        };
      }),
    {
      id: 'columns',
      cell: <RowMenuCell title={t('Edit Record')} onSelect={onSelectAction} />,
      headCell: <ColumnsIconLinkCell zIndex={2} />,
      bodyCell: {
        isMenuCell: true,
        Component: NewRowMenuCell,
        isCustomObjects: true,
        title: t('Edit Record'),
        onSelect: onSelectAction,
      },
    },
  ];
};
