import { Nav } from 'react-bootstrap';
import styled from '@emotion/styled';
import Switch from 'components/Kizen/Switch';

export const StyledNav = styled(Nav)`
  margin-left: auto;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 33px;
`;

export const StyledSwitch = styled(Switch)`
  margin: 0;
  padding-top: 4px;
  padding-left: 9px;
`;
