import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, grayScale, shadowOverlay } from '../../app/colors';
import { isMobile, useWindowSize, breakpoints } from '../../app/spacing';
import { ignoreSsrWarning } from '../helpers';
import Icon from './Icon';

export const OVERLAY_ICON_BUTTON_SIZE = 30;

const ICON_BUTTON_SIZING = {
  normal: 'normal',
  dense: 'dense',
  touch: 'touch',
  mobile: 'mobile',
  overlay: 'overlay',
};

// TODO - add checks for disabled to change the hover behavior when disabled
const StyledButton = styled('button', {
  shouldForwardProp: (prop) => !['sizing', 'color', 'as'].includes(prop),
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: ${({ sizing }) =>
    sizing === ICON_BUTTON_SIZING.touch ? '-12px' : 0};
  height: ${({ sizing }) =>
    sizing === ICON_BUTTON_SIZING.dense ? 'auto' : '44px'};
  width: ${({ sizing }) =>
    sizing === ICON_BUTTON_SIZING.dense ? 'auto' : '44px'};
  border-radius: ${({ sizing }) =>
    sizing === ICON_BUTTON_SIZING.dense ? 'initial' : '50%'};
  background: none;
  && {
    outline: none;
  }
  && > :first-child${ignoreSsrWarning} {
    color: ${({ color }) =>
      typeof color === 'string' ? color : color.dark || color.default};
  }
  &&:hover > :first-child {
    color: ${({ color }) =>
      typeof color === 'string' ? color : color.light || color.hover};
  }
`;

const MobileButton = React.forwardRef((props, ref) => {
  const size = useWindowSize();
  return (
    <StyledButton
      ref={ref}
      type="button"
      sizing={
        isMobile(size.width, breakpoints.sm)
          ? ICON_BUTTON_SIZING.touch
          : ICON_BUTTON_SIZING.dense
      }
      {...props}
    />
  );
});

MobileButton.displayName = 'MobileButton';

const OverlayedIconButton = styled('button', {
  shouldForwardProp: (prop) => !['as', 'borderColor'].includes(prop),
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: ${OVERLAY_ICON_BUTTON_SIZE}px;
  height: ${OVERLAY_ICON_BUTTON_SIZE}px;
  border-radius: 50%;

  ${({ borderColor }) => {
    return borderColor
      ? css`
          border: 1px solid ${borderColor};
        `
      : css`
          border: none;
        `;
  }};

  && {
    outline: none;
  }

  ${({ color }) => css`
    && > :first-child${ignoreSsrWarning} {
      color: ${typeof color === 'string' ? color : color.dark || color.default};
    }
    &&:hover > :first-child {
      color: ${typeof color === 'string' ? color : color.light || color.hover};
    }
  `}

  background: ${grayScale.white};
  ${shadowOverlay}

  && svg {
    // font-awesome fonts are wider than tall
    width: auto;
    height: 12px;
  }
`;

const IconButton = React.forwardRef(({ sizing, color, ...props }, ref) => {
  if (sizing === ICON_BUTTON_SIZING.mobile) {
    return (
      <MobileButton
        ref={ref}
        color={color || Icon.defaultProps.color}
        {...props}
      />
    );
  }
  if (sizing === ICON_BUTTON_SIZING.overlay) {
    return (
      <OverlayedIconButton
        ref={ref}
        type="button"
        color={color || colorsButton.iconGray}
        {...props}
      />
    );
  }
  return (
    <StyledButton
      ref={ref}
      type="button"
      sizing={sizing}
      color={color || Icon.defaultProps.color}
      {...props}
    />
  );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  // normal: 44px by 44px
  // dense: sized to icon (usually 20px by 20px)
  // touch: 44px by 44px touch area, but 20px by 20px in layout
  // mobile: switch between touch for small screens, and dense for the rest
  // overlay: 30px by 30px overlay
  sizing: PropTypes.oneOf([
    ICON_BUTTON_SIZING.normal,
    ICON_BUTTON_SIZING.dense,
    ICON_BUTTON_SIZING.touch,
    ICON_BUTTON_SIZING.mobile,
    ICON_BUTTON_SIZING.overlay,
  ]),
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string),
  ]),
  borderColor: PropTypes.string,
};

IconButton.defaultProps = {
  sizing: ICON_BUTTON_SIZING.normal,
  color: null,
  borderColor: null,
};

export default IconButton;
export { ICON_BUTTON_SIZING };
