import styled from '@emotion/styled';
import { colorsButton, grayScale } from '__app/colors';
import { borderRadii } from '__app/spacing';
import Button from '__components/Button';
import css from '@emotion/css';
import Select from '__components/Inputs/Select';
import { KizenTypography } from '__app/typography';
import IconButton from '__components/Kizen/IconButton';
import {
  StyledCard,
  StyledStepHeader,
} from '__pages/SmartConnectors/SmartConnector/steps/styles';

export const StyledMatchingStepHeader = styled(StyledStepHeader)`
  margin-bottom: 16px;
`;

export const StyledMatchingCard = styled(StyledCard)`
  min-height: 323px;
`;

export const StyledAddRuleButton = styled(Button)`
  margin: 0;
  padding: 0;
  height: fit-content;
`;

export const StyledMatchingRuleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  &:not(:empty) {
    margin-bottom: 17px;
  }
`;

export const StyledRulesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledRuleWarpper = styled.div<{ hasError: boolean }>`
  width: 100%;
  display: flex;
  padding: 15px;
  padding-bottom: 20px;
  gap: 15px;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? colorsButton.red.default : grayScale.medium};
  border-radius: ${borderRadii.small};
`;

export const StyledRuleCard = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const StyledDiv = styled.div<{ width?: number }>`
  ${({ width }) => css`
    width: ${width}px;
  `}
`;

export const StyledRow = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const StyledBoldSelect = styled(Select)`
  margin-top: 5px;
  & label {
    font-weight: 600;
  }
`;

export const StyledSubheader = styled(KizenTypography)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: -3px;
`;

export const StyledRotatedButon = styled(IconButton)`
  transform: rotate(180deg);
`;

export const StyledReorderButtons = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;

export const StyledDeleteButton = styled(Button)`
  margin: 0;
  margin-left: auto;
  padding: 0;
  height: fit-content;
`;
