/* eslint-disable react/display-name */
import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { scrollbarCss } from 'app/spacing';
import { grayScale } from 'app/colors';
import {
  useEndsOfYScroll,
  useScrollThreshold,
  GradientBottom,
  DropShadowTop,
  Loader,
  TableText as ListText,
} from './helpers';
import { mergeRefs } from '@kizen/kds/util';

const ListWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  &.in-modal {
    flex-basis: auto;
  }
  padding-top: 5px;
  overflow: auto;
  height: 100%;
  min-width: 335px;
  ${scrollbarCss()}
`;

export default function LazyLoadList({
  className,
  loading,
  fetchingNextPage,
  onScrollThreshold,
  data,
  noDataMessage,
  children,
  loaderSize,
  scrollContainerRef,
  ...others
}) {
  const listScrollRef = useRef(null);
  // Depend on templates so we calculate scroll bottom when layout's data and thereby its height updates
  const [scrolledTop, scrolledBottom] = useEndsOfYScroll(listScrollRef, [data]);
  useScrollThreshold(listScrollRef, [data], onScrollThreshold);

  return (
    <>
      <DropShadowTop fadeIn={!scrolledTop && children} />
      <ListWrapper
        className={className}
        ref={mergeRefs(listScrollRef, scrollContainerRef)}
        {...others}
      >
        {!loading && children}
        {fetchingNextPage || loading ? (
          <Loader loading size={loaderSize} />
        ) : null}
        {noDataMessage && !loading && data && data.length === 0 && (
          <ListText color={grayScale.mediumDark}>{noDataMessage}</ListText>
        )}
        <GradientBottom fadeIn={!scrolledBottom && children} />
      </ListWrapper>
    </>
  );
}

LazyLoadList.propTypes = {
  loading: PropTypes.bool.isRequired,
  fetchingNextPage: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  noDataMessage: PropTypes.string,
  onScrollThreshold: PropTypes.func.isRequired,
  loaderSize: PropTypes.number,
};

LazyLoadList.defaultProps = {
  noDataMessage: '',
  loaderSize: 50,
};
