import { gutters } from 'app/spacing';
import styled from '@emotion/styled';
import KizenNotice from 'components/Kizen/Notice';
import KizenSwitch from 'components/Kizen/Switch';

export const Notice = styled(KizenNotice)`
  margin: ${gutters.spacing(4)}px ${gutters.spacing(4)}px 0
    ${gutters.spacing(4)}px;

  > p {
    > strong,
    span {
      font-size: inherit;
    }
  }
`;

export const StyledInputsBox = styled.div`
  margin: ${gutters.spacing(4)}px;
`;
export const Switch = styled(KizenSwitch)`
  margin-left: 0;
  margin-right: 0;
`;
