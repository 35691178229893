import { PropsWithChildren } from 'react';
import { FlowBuilderContext } from './context';
import { useFlowBuilderContext } from './hooks/useFlowBuilderContext';

export const FlowBuilderProvider = ({ children }: PropsWithChildren) => {
  const context = useFlowBuilderContext();
  return (
    <FlowBuilderContext.Provider value={context}>
      {children}
    </FlowBuilderContext.Provider>
  );
};
