import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Notice from 'components/Kizen/Notice';
import KizenTypography from 'app/kizentypo';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import {
  HeaderSizedCell,
  SizedCell,
  TextEllipsisWithTooltip,
  TRow,
} from 'components/Kizen/Table';

import BasicTable from 'components/Tables/Basic';
import * as PropTypes from 'prop-types';
import { grayScale, border } from 'app/colors';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';

const StyledTableScrollContainer = styled(TableScrollContainer)`
  ${border}
  border-color: ${grayScale.mediumLight};
  margin-top: ${gutters.standard};
  margin-bottom: ${gutters.spacing(1, -3)}px;
  overflow-y: auto;
`;

const text = (x) => {
  return <TextEllipsisWithTooltip>{x}</TextEllipsisWithTooltip>;
};

const getFinalizeUploadColumns = (field) => {
  return {
    id: field.id,
    cell: <SizedCell minWidth="200px" padding="30px" />,
    headCell: (
      <HeaderSizedCell
        label={field.canonicalDisplayName}
        minWidth="200px"
        maxWidth="100%"
        padding="30px"
      />
    ),
    format: text,
  };
};

const FinalizeUploadSection = ({ fields, finalizeUploadData }) => {
  const { t } = useTranslation();

  const [sort, setSort] = useState({ column: 'name', direction: 'asc' });
  const columns = useMemo(
    () => [
      {
        id: 'spacer',
        cell: <SizedCell width="15px" />,
        headCell: <SizedCell width="15px" />,
      },
      ...fields.map((el) => getFinalizeUploadColumns(el)),
    ],
    [fields]
  );
  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => setSort({ column, direction }),
      },
    }),
    [sort]
  );

  return (
    <>
      <Notice>
        <KizenTypography>
          {t(
            'Please confirm the preview of your first 5 rows of data, based on the fields assignment above.'
          )}
        </KizenTypography>
      </Notice>
      <StyledTableScrollContainer left right>
        <BasicTable
          compactHeader
          head={<TRow head columns={columns} data={headData} />}
        >
          {finalizeUploadData?.length &&
            finalizeUploadData.map((record) => (
              <TRow key={record.id} columns={columns} data={record} />
            ))}
        </BasicTable>
      </StyledTableScrollContainer>
    </>
  );
};

export default FinalizeUploadSection;

FinalizeUploadSection.propTypes = {
  fields: PropTypes.array.isRequired,
  finalizeUploadData: PropTypes.array.isRequired,
};
