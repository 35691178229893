import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { shadowLight, colorsButton, grayScale } from 'app/colors';
import { KizenTypography, textCss, truncateCss } from 'app/typography';
import { borderRadii, dashedBorderCss, layers } from 'app/spacing';
import DraggableItem from 'components/Kizen/DraggableItem';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import { FIELD_MARGIN } from './constants';

export const FieldWrapper = styled(DraggableItem)`
  flex: 1;
  min-width: 0;
  height: 42px;
  padding: 10px;
  margin: ${FIELD_MARGIN}px;

  && {
    ${({ dashedBorder = false }) =>
      dashedBorder &&
      css`
        border: unset;
        ${dashedBorderCss({ dashLength: 4, color: grayScale.medium })}
      `}
    )}
  }


  ${({ dragging }) => dragging && shadowLight}
  ${({ bringToTop, forceToTop }) =>
    (bringToTop || forceToTop) &&
    css`
      z-index: ${layers.content(0, 1)};
    `}
  .FieldHandle {
    cursor: grab;
    margin-right: 10px;
  }
  && .FieldHandle:hover svg {
    color: ${grayScale.dark};
  }
  &:hover .FieldHandle svg {
    color: ${grayScale.mediumDark};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${grayScale.light};
      && .FieldHandle,
      && .FieldHandle:hover {
        cursor: default;
        svg {
          color: ${grayScale.medium};
        }
      }
    `}
`;

export const FieldLayout = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  // Input now Text Wrapper

  // Icon wrapper
  > *:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > * {
      margin-left: 10px;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;

  > *:first-child {
    // Naturally truncate if the container is sized
    max-width: 100%;
    ${truncateCss}
    input[disabled] {
      background: none;
    }
  }
`;

export const FieldPlaceholder = styled.div`
  position: absolute;
  top: ${FIELD_MARGIN}px;
  bottom: ${FIELD_MARGIN}px;
  left: ${FIELD_MARGIN}px;
  right: ${FIELD_MARGIN}px;
  background-color: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.standard};
`;

export const NewFieldTypography = styled(KizenTypography)`
  align-self: flex-start;
  margin-bottom: 20px;
  margin-left: 20px;
  cursor: pointer;
  color: ${colorsButton.green.default};
  &:hover {
    color: ${colorsButton.green.hover};
  }
`;

export const VisibilityRuleIcon = styled(IconWithTooltip)`
  cursor: pointer;

  && svg {
    color: ${colorsButton.blue.default};
    width: ${({ width = 11.25 }) => `${width}px`};
    height: ${({ height = 15 }) => `${height}px`};

    :hover {
      color: ${colorsButton.blue.hover};
    }
  }
`;

export const RequiredAsterisk = styled.div`
  width: 15px;
  text-align: right;
  line-height: 20px;
  ${({ color = colorsButton.red.hover }) =>
    textCss('header', 'regular', 'none', 'none', color)}}
  ${({ hasTooltip = false }) => hasTooltip && 'cursor: pointer;'}
`;

export const LeftContainer = styled.div`
  display: flex;
  gap: 10px;
  min-width: 0;
`;
