import styled from '@emotion/styled';
import TableScrollContainer from 'components/Tables/ScrollContainer';

export const VirtualItemsWrapper = styled.div`
  margin-left 10px;
  display: flex;
  flex-direction: column;
  flex: 1;

  & > div > div > div:first-child {
    will-change: auto !important;
  }

  & > div > div > div:last-child {
   position: sticky !important;
  }
`;

export const ConfigurationPanelBodyVirtual = styled(TableScrollContainer)`
  flex-grow: 1;
  width: 100%;
  height: 0;
  overflow-y: auto;
`;

export const ItemsWrapper = styled.div`
  margin: 5px 12px 5px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
