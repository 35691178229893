import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { optionProp } from 'components/Inputs/props';
import SelectInline, {
  SelectInlineLabel,
} from 'components/Inputs/inline/Select';
import Select from 'components/Inputs/Select';
import { ratingOptions, exists, integerToOption } from '../helpers';
import { FIELD_TYPES } from 'utility/constants';
import { getDataQAForInput } from 'components/Inputs/helpers';

function ratingInlineFieldContent({ field, label, t }) {
  const { maxValue } = field.rating;
  return `${label} ${t('out of')} ${maxValue}`;
}

const RatingInlineFieldLabel = ({ field, value, t, ...props }) => (
  <SelectInlineLabel {...props}>
    {value && ratingInlineFieldContent({ field, label: value.label, t })}
  </SelectInlineLabel>
);

RatingInlineFieldLabel.propTypes = {
  field: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  value: optionProp,
};

RatingInlineFieldLabel.defaultProps = {
  value: null,
};

export default function RatingInlineField({
  field,
  value,
  onSubmit,
  children: selectEl,
  ...others
}) {
  const { t } = useTranslation();

  const options = useMemo(() => ratingOptions(field), [field]);

  const displayValue = useMemo(
    () => (exists(value) ? integerToOption(value) : null),
    [value]
  );

  return (
    <SelectInline
      onSubmit={(opt) => onSubmit(opt && parseInt(opt.value, 10))}
      labelEl={<RatingInlineFieldLabel field={field} t={t} />}
      {...others}
      field={field}
    >
      {React.cloneElement(selectEl, {
        value: displayValue,
        options,
        ...getDataQAForInput(field.id, field.fieldType),
        placeholder: FIELD_TYPES.Rating.localizedPlaceholder(t),
      })}
    </SelectInline>
  );
}

RatingInlineField.propTypes = {
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  children: PropTypes.element,
};

RatingInlineField.defaultProps = {
  value: null,
  children: <Select />,
};
