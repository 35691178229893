import { keyframes } from '@emotion/core';

export const pulse = keyframes({
  'from, to': {
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.3)',
  },
  '75%': {
    boxShadow: '0 0 0 20px rgba(0, 0, 0, 0)',
  },
});

export const bounce = keyframes({
  'from, 20%, 53%, 80%, to': {
    transform: 'translate3d(0, 0, 0)',
  },
  '40%, 43%': {
    transform: 'translate3d(0, -30px, 0)',
  },
  '70%': {
    transform: 'translate3d(0, -15px, 0)',
  },
  '90%': {
    transform: 'translate3d(0, -4px, 0)',
  },
});

export const shake = keyframes({
  '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
  '10%': { transform: 'translate(-1px, -2px) rotate(-1deg)' },
  '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
  '30%': { transform: 'translate(3px, 2px) rotate(0deg)' },
  '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
  '50%': { transform: 'translate(-1px, 2px) rotate(-1deg)' },
  '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
  '70%': { transform: 'translate(3px, 1px) rotate(-1deg)' },
  '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
  '90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
  '100%': { transform: 'translate(1px, -2px) rotate(-1deg)' },
});

export const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});

export const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

export const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
});
