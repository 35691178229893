import React from 'react';

export default function CommerceEntityIcon(props) {
  return (
    <svg
      width="21"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5 6C16.5 2.69161 13.8084 0 10.5 0C7.19156 0 4.5 2.69161 4.5 6H0V20.25C0 22.3211 1.67892 24 3.75 24H17.25C19.3211 24 21 22.3211 21 20.25V6H16.5ZM10.5 1.5C12.9813 1.5 15 3.51867 15 6H6C6 3.51867 8.01867 1.5 10.5 1.5ZM19.5 20.25C19.5 21.4906 18.4906 22.5 17.25 22.5H3.75C2.50936 22.5 1.5 21.4906 1.5 20.25V7.5H4.5V9.75C4.5 10.1642 4.83581 10.5 5.25 10.5C5.66419 10.5 6 10.1642 6 9.75V7.5H15V9.75C15 10.1642 15.3358 10.5 15.75 10.5C16.1642 10.5 16.5 10.1642 16.5 9.75V7.5H19.5V20.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
