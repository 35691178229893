import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Notice from 'components/Kizen/Notice';
import Section from 'components/Wizards/CustomObject/components/Section';
import BuilderCard from 'components/Wizards/CustomObject/components/BuilderCard';
import BaseSelect from 'components/Inputs/Select';
import { layers } from 'app/spacing';
import Switch from 'components/Kizen/Switch';
import { border } from 'app/colors';
import css from '@emotion/css';
import SwitchControl from 'components/Wizards/CustomObject/components/SwitchControl';
import { CONTENT_MAX_WIDTH } from 'components/Layout/PageContentWidth';
import Loader from 'components/Kizen/Loader';
export const ReverseWrapper = styled.div`
  margin-top: ${gutters.spacing(4, { baseline: true })}px;
  > *:last-child {
    margin-bottom: ${gutters.spacing(2, 4)}px;
  }
`;
export const PrimaryWrapper = styled.div`
  > *:last-child {
    margin-bottom: ${gutters.spacing(2, 4)}px;
  }
`;
export const StyledBuilderCard = styled(BuilderCard)`
  min-width: ${CONTENT_MAX_WIDTH};
  padding-top: ${gutters.spacing(3, 3)}px;
  > h3 {
    padding-bottom: ${gutters.spacing(5)}px;
  }
`;
export const SwitchSection = styled(Section)`
  margin-bottom: ${gutters.spacing(5, 3)}px;
`;
export const StyledNotice = styled(Notice)`
  padding-top: ${gutters.spacing(1, 4)}px;
  padding-bottom: ${gutters.spacing(1, 2)}px;
  margin-bottom: ${gutters.spacing(0, -1)}px;
  ${({ noRows }) =>
    noRows &&
    css`
      margin-bottom: ${gutters.spacing(3, 4)}px;
    `}
`;
export const StyledSection = styled(Section)`
  h3 {
    margin-bottom: ${gutters.spacing(3, 1)}px;
  }
  margin-bottom: ${gutters.spacing(5, 1)}px;
  &&:last-child {
    margin-bottom: ${gutters.spacing(0, 3)}px;
  }
`;

export const Select = styled(BaseSelect)`
  z-index: ${layers.popoverInModal};
`;

export const LineItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 235px 150px repeat(2, 210px) 218px 280px 28px;
  grid-column-gap: 0;
  padding: ${gutters.spacing(3, -2)}px 0 ${gutters.spacing(3, -2)}px 0;
  margin-bottom: ${gutters.spacing(3)}px;
  ${border}
`;

export const TimelineSwitch = styled(Switch)`
  margin: 0;

  label {
    margin: 0;
  }
`;
export const StyledSwitchControl = styled(SwitchControl)`
  > label {
    margin-bottom: ${gutters.spacing(4, 2)}px;
  }
`;
export const EditTextWrapper = styled.div`
  & > * {
    display: block !important;
  }
`;

export const StyledLoader = styled(Loader)`
  padding-top: 20px !important;
  padding-bottom: 18px;
`;

export const AssociationHeader = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 0 15px;
`;
