import phoneCountryCodes from '../../utility/phone-country-codes';
import { FIELD_TYPES } from '../../utility/constants';
import phoneMeta from 'libphonenumber-js/metadata.min.json';

export const isCountryCallingCode = (number) => {
  return !!phoneMeta.country_calling_codes[number?.replace('+', '')];
};

export const exists = (n) => Boolean(n || n === 0);

export const integerToOption = (n) => ({
  label: n.toLocaleString(),
  value: n.toString(),
});

export const ratingOptions = (field) => {
  const { minValue, maxValue } = field.rating;
  return Array.from(Array(maxValue - minValue + 1), (_, i) => minValue + i).map(
    integerToOption
  );
};

export const getIdentifierForOption = (opt, { fieldType }) => {
  if (fieldType === FIELD_TYPES.Choices.type) {
    return opt.code;
  }
  return opt.id;
};

export const getCurrencySymbol = ({ moneyOptions }) => {
  return moneyOptions && (moneyOptions.symbol || moneyOptions.currency);
};

export const countryToCode = Object.entries(phoneCountryCodes).reduce(
  (collect, [code, countries]) => {
    countries.forEach((country) => {
      collect[country] = code;
    });
    return collect;
  },
  {}
);

// The input shows country codes, but we store countries.
// Many countries can have the same code, so some information is lost along the way.

export const lossyCodeToCountry = Object.entries(phoneCountryCodes).reduce(
  (collect, [code, countries]) => {
    const [country] = countries;
    collect[code] = country;
    return collect;
  },
  {}
);

const getXScrollParentOffset = (el, prevEl = null, offset = 0) => {
  if (!el || el.scrollWidth > el.clientWidth) {
    return [el, offset];
  }
  return getXScrollParentOffset(
    el.parentNode,
    el,
    offset + ((prevEl && prevEl.offsetLeft) || 0)
  );
};

const scrollX = (el, x) => {
  if (el.scrollTo) {
    // If there's browser support
    el.scrollTo({
      left: x,
      behavior: 'smooth',
    });
  } else {
    el.scrollLeft = x;
  }
};

export const scrollFieldIntoView = (el) => {
  if (el) {
    const [scrollEl, offset] = getXScrollParentOffset(el);
    if (scrollEl) {
      // Nudge away from gradient fade-out on most tables
      scrollX(scrollEl, offset - 75);
    }
  }
};
