type SizeDeltas = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

const sizes = [
  'xs',
  'sm',
  'md',
  'base',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
] as const;

export type SizeOptions = (typeof sizes)[number];

const sizeIndices = sizes.reduce(
  (acc, curr, index) => {
    return {
      ...acc,
      [curr]: index,
    };
  },
  {} as Record<SizeOptions, number>
);

class Size {
  private size: SizeOptions;

  constructor(size: SizeOptions) {
    this.size = size;
  }

  value() {
    return this.size;
  }

  isLessThan(size: SizeOptions) {
    const originalIndex = sizeIndices[this.size];
    const compareIndex = sizeIndices[size];

    return originalIndex < compareIndex;
  }

  isGreaterThan(size: SizeOptions) {
    const originalIndex = sizeIndices[this.size];
    const compareIndex = sizeIndices[size];

    return originalIndex > compareIndex;
  }

  subtract(amount: SizeDeltas) {
    const originalIndex = sizeIndices[this.size];
    let newIndex = originalIndex - amount;

    if (newIndex < 0) {
      newIndex = 0;
    }

    return new Size(sizes[newIndex]);
  }

  add(amount: SizeDeltas) {
    const originalIndex = sizeIndices[this.size];
    let newIndex = originalIndex + amount;

    if (newIndex > sizes.length - 1) {
      newIndex = sizes.length - 1;
    }

    return new Size(sizes[newIndex]);
  }

  ceiling(ceiling: SizeOptions) {
    if (this.isGreaterThan(ceiling)) {
      return new Size(ceiling);
    }

    return this;
  }

  floor(floor: SizeOptions) {
    if (this.isLessThan(floor)) {
      return new Size(floor);
    }

    return this;
  }
}

export const kdsSize = (size: SizeOptions) => new Size(size);
