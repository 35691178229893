// import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { gutters, layers } from 'app/spacing';
import { KizenTypography } from 'app/typography';
import { colorsButton, grayScale } from 'app/colors';
import Loader from 'components/Kizen/Loader';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import { fieldMargin } from 'components/Builders/CustomFieldsBuilder/Field';

export const BuilderLoader = styled(Loader)`
  padding: ${gutters.spacing(20)}px 0 ${gutters.spacing(40)}px;
`;

export const Header = styled.div`
  width: 100%; // width set to 1372px as per design
  margin: ${gutters.spacing(4)}px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
`;

export const HeaderSection = styled.div``;

export const HeaderButton = styled(Button)`
  &:last-of-type {
    margin-right: 0;
  }
`;

export const PageSizing = styled.div`
  margin: 0 ${gutters.standard};
`;

export const BigCard = styled(Card)`
  padding-top: ${gutters.spacing(3)}px;
  margin: ${gutters.spacing(3)}px auto ${gutters.spacing(4)}px;
  display: flex;
  flex-direction: column;
  > div:last-of-type {
    flex: 1;
  }
  max-width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-bottom: ${gutters.spacing(2, -4)}px;
  h3 {
    margin-top: -1px; // Design nudge
    line-height: 1; // Sidestep bootstrap
  }
`;

export const RightHeader = styled.div`
  margin-top: -3px;
  display: flex;
  justify-content: start;
`;

export const ActivitiesTitle = styled.div`
  align-items: flex-start;
  margin-bottom: ${gutters.spacing(4)}px;
  margin-left: ${gutters.spacing(0)}px;

  h3 {
    margin-top: ${gutters.spacing(1, -1)}px;
  }
  button {
    margin: 0;
  }
`;

export const ActivitiesFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin-top: ${gutters.spacing(2, -4)}px;
`;

export const FooterNewFieldTypography = styled(KizenTypography)`
  align-self: flex-start;
  margin-bottom: ${gutters.spacing(0)}px;
  margin-right: ${gutters.spacing(4)}px;
  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: default;
        color: ${grayScale.medium};
      `;
    }

    return css`
      cursor: pointer;
      color: ${colorsButton.green.default};
      &:hover {
        color: ${colorsButton.green.hover};
      }
    `;
  }}
`;

export const FieldLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${gutters.spacing(0) - fieldMargin}px
    ${gutters.spacing(4) - fieldMargin}px;
`;

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  ${({ width, cols }) => css`
    width: ${(100 * width) / cols}%;
  `}
  ${({ dragging, draggingFrom, optioning }) =>
    (dragging || draggingFrom || optioning) &&
    css`
      z-index: ${layers.content(0, 1)};
    `}
  ${({ dragging }) =>
    dragging &&
    css`
      pointer-events: none;
    `}
`;
