import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

export const StyledContentWidth = styled(ContentWidth)`
  margin-bottom: 20px;
`;

export const StyledModalText = styled(KizenTypography)`
  margin-bottom: 17px;
`;

export const StyledModal = styled(ConfirmationModal)`
  .modal-body {
    overflow: unset;
    padding-right: 15px;
  }
`;

export const StyledSelect = styled(Select)`
  text-align: start;
`;
