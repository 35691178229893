import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import { colorsPrimary, colorsSecondary, grayScale } from 'app/colors';
import TrendChartManager from '../Trend/TrendChartManager';
import { monitoringMessageHelper } from 'sentry/helpers';

export const colors = [
  colorsPrimary.green,
  colorsSecondary.aqua,
  colorsPrimary.blue,
  colorsPrimary.orange,
  colorsPrimary.purple,
  colorsSecondary.tangerine,
  colorsSecondary.red,
  colorsSecondary.magenta,
  colorsSecondary.brown,
];

class AreaLineChartManager extends TrendChartManager {
  constructor(
    element,
    t,
    data,
    { yAxisTitle, currencyMode = false, ...opts },
    secondOpts,
    ready
  ) {
    super(
      element,
      t,
      data,
      {
        ...opts,
        chartConfig: (root) => ({
          layout: root.verticalLayout,
          maxTooltipDistance: -1,
          paddingTop: -5,
        }),
      },
      secondOpts,
      ready
    );

    this.yAxisTitle = yAxisTitle;
    this.currencyMode = currencyMode;
    this.isValue = opts.isValue;
  }

  hideSeries = (id) => {
    this.seriesMap[id].hide();
    this.hiddenSeries[id] = true;
    this.createTotalView();
  };

  showSeries = (id) => {
    this.seriesMap[id].show();
    this.hiddenSeries[id] = false;
    this.createTotalView();
  };

  init = () => {
    this.chart.set('dx', -15);
    this.seriesMap = {};
    this.hiddenSeries = {};
    this.createYAxis(
      `${this.currencyMode ? this.currencySymbol : ''}${this.defaultFormat}`
    );
    this.createXAxis();
    this.createSeries();

    if (this.data.length) {
      this.createTotalView();
      this.createCursor(this.mobile);
    }
    this.chart.appear(1000, 100);

    return (id, hide) => {
      if (this.seriesMap[id]) {
        if (hide) {
          this.hideSeries(id);
        } else {
          this.showSeries(id);
        }
      } else {
        monitoringMessageHelper(
          '[EventQueue] - Queue may not function as expected with multiple entries',
          {
            extra: {
              id,
            },
          }
        );
      }
    };
  };

  getDataLength = () => {
    return this.data?.[0]?.values?.length ?? 0;
  };

  createTotalView = () => {
    const hiddenSeries = Object.keys(this.hiddenSeries).filter((x) =>
      Boolean(this.hiddenSeries[x])
    );
    const visibleData = this.data.filter(
      (row) => !hiddenSeries.includes(row.category.id)
    );

    if (visibleData.length) {
      const firstArray = visibleData[0].values;
      const newData = firstArray.map(({ date }, index) => {
        const sum = visibleData.reduce((acc, curr) => {
          const currentData = curr.values;
          return acc + currentData[index].value;
        }, 0);

        return {
          date,
          value: sum,
        };
      });

      if (newData.length) {
        this.createTotalAxis();
        this.createTotalSeries(newData);
      }
    } else {
      const originalSeries = this.data[0]?.values ?? [];
      if (originalSeries.length) {
        this.createTotalAxis();
        this.createTotalSeries(
          originalSeries.map(({ date }) => {
            return {
              date,
              value: 0,
            };
          })
        );
      }
    }
  };

  configureYAxis = (data = []) => {
    this.assertYAxis('y-axis configuration');
    if (data) {
      const isZeros = data.every((record) => {
        return record.values.every(({ value }) => value === 0);
      });
      if (isZeros) {
        this.element.setAttribute('data-qa-dashlet-empty', 'true');
        this.yAxis.set('min', 0);
      } else {
        this.element.setAttribute('data-qa-dashlet-empty', 'false');
        this.yAxis.set('min', undefined);
      }
    }
  };

  makeSeries = (seriesData, index) => {
    this.assertChart('make series');
    this.assertRoot('make series');
    const series = this.chart.series.push(
      am5xy.LineSeries.new(this.root, {
        name: seriesData.category.name,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: colors[index % colors.length].dark,
        fill: colors[index % colors.length].light,
        tooltip: this.createStyledTooltip(
          `${seriesData.category.name}: ${
            this.isValue ? this.currencySymbol : ''
          }${this.valueYAccessor}`,
          // Flip the pointer orientation so it doesn't conflict with
          // the "total" tooltip
          { pointerOrientation: 'up' }
        ),
        stacked: true,
      })
    );

    series.fills.template.setAll({
      opacity: 1,
      visible: true,
    });

    series.data.setAll(
      seriesData.values?.map((d) => ({
        date: this.getMilliseconds(d.date),
        rawDate: d.date,
        value: d.value,
      }))
    );

    series.bullets.push((_root, _series, item) => {
      // Only show the bullet if the value is non-zero
      if (item.dataContext.value !== 0 || this.useAllBullets) {
        return am5.Bullet.new(this.root, {
          sprite: am5.Circle.new(this.root, {
            radius: 3,
            fill: colors[index % colors.length].dark,
            stroke: grayScale.white,
          }),
        });
      }
    });

    series.appear(1000);

    this.seriesMap[seriesData.id] = series;
  };

  createSeries = () => {
    this.data.forEach((value, index) =>
      this.makeSeries(value, index, this.data.length)
    );
    this.configureYAxis(this.data);
    this.labelData =
      this.data[0]?.values?.map((d) => ({
        date: this.getMilliseconds(d.date),
        rawDate: d.date,
        value: d.value,
      })) ?? [];
    this.xAxis.data.setAll(this.labelData);
  };

  // Create an internal legend, instead of using a custom one
  createLegend = () => {
    this.assertRoot('legend');
    this.assertChart('legend');

    const legend = am5.Legend.new(this.root, {
      x: am5.p50,
      centerX: am5.p50,
      useDefaultMarker: true,
      paddingTop: 4,
      paddingBottom: -2,
      layout: am5.GridLayout.new(this.root, {
        maxColumns: 4,
        fixedWidthGrid: true,
      }),
      height: 15,
    });

    this.legend = this.chart.children.push(legend);

    this.legend.data.setAll(this.chart.series.values);
    this.legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });
    this.legend.markers.template.setAll({
      width: 8,
      height: 8,
    });
    this.legend.labels.template.setAll({
      fill: grayScale.dark,
    });
  };
}

export default AreaLineChartManager;
