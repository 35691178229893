import styled from '@emotion/styled';
import { gutters } from 'app/spacing';

// Designed to contain cards that size themselves with
// spaceBetweenGutters(), e.g. SmallTableCard

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${gutters.spacing(4)}px;
  &:last-child {
    // More space at bottom of page
    margin-bottom: ${gutters.spacing(8)}px;
  }
`;
