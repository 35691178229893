import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KizenTypography } from 'app/typography';
import Loader from 'components/Kizen/Loader';
import { DashletShape } from 'utility/CommonPropTypes';
import { units } from '../helpers';

import {
  Container,
  SummaryContainer,
  HistoricalContainer,
  HistoricalDatum,
  StyledTrendCaretIcon,
  getColor,
} from './styles';
import { collapseNumber } from 'utility/numbers';
import { PERCENTAGE_REPORT_TYPES } from 'components/Wizards/Dashlet/types';

/*
 * This is a summary chart that shows the trend data from the previous period
 * and the previous year, in addition to the standard summary value.
 */
const SummaryHistorical = ({ dashlet, data, isLoading }) => {
  const { t } = useTranslation();

  const currentPeriod = useMemo(() => {
    if (!data) {
      return 0;
    }
    const value = data.data?.find((d) => d.type === 'value');
    return value?.value;
  }, [data]);

  const lastPeriod = useMemo(() => {
    if (!data) {
      return 0;
    }
    const value = data.data?.find((d) => d.type === 'previous_period_value');
    return value?.value;
  }, [data]);

  const lastYear = useMemo(() => {
    if (!data) {
      return 0;
    }
    const value = data.data?.find((d) => d.type === 'last_year_value');
    return value?.value;
  }, [data]);

  const {
    lastPeriodAbsolute,
    lastPeriodColor,
    lastPeriodDefined,
    lastYearAbsolute,
    lastYearColor,
    lastPeriodValue,
    lastYearValue,
    lastYearDefined,
  } = useMemo(() => {
    const decreaseDesired = data?.inverse ?? false;

    const lastPeriodValue = (currentPeriod ?? 0) - (lastPeriod ?? 0);
    const lastPeriodAbsolute = Math.abs(lastPeriodValue);
    const lastPeriodDefined = lastPeriod !== null;
    const lastPeriodColor = getColor(
      decreaseDesired ? -lastPeriodValue : lastPeriodValue,
      lastPeriodDefined
    );

    const lastYearValue = (currentPeriod ?? 0) - (lastYear ?? 0);
    const lastYearAbsolute = Math.abs(lastYearValue);
    const lastYearDefined = lastYear !== null;
    const lastYearColor = getColor(
      decreaseDesired ? -lastYearValue : lastYearValue,
      lastYearDefined
    );

    return {
      lastPeriodAbsolute,
      lastPeriodColor,
      lastPeriodDefined,
      lastYearAbsolute,
      lastYearColor,
      lastPeriodValue,
      lastYearValue,
      lastYearDefined,
    };
  }, [currentPeriod, data, lastPeriod, lastYear]);

  const isPercent = useMemo(() => {
    return PERCENTAGE_REPORT_TYPES.includes(dashlet?.config?.reportType);
  }, [dashlet]);

  return (
    <Loader loading={isLoading}>
      <Container>
        <SummaryContainer>
          <KizenTypography size="hero" data-qa-dashlet-display-field="value">
            {units(collapseNumber(currentPeriod), dashlet.config)}
            {isPercent ? '%' : ''}
          </KizenTypography>
        </SummaryContainer>
        <HistoricalContainer>
          <HistoricalDatum>
            <KizenTypography color={lastPeriodColor} size="header">
              {!lastPeriodDefined && '-'}
              {lastPeriodDefined &&
                lastPeriodValue === 0 &&
                units(collapseNumber(lastPeriodAbsolute), dashlet.config)}
              {lastPeriodDefined && lastPeriodValue !== 0 && (
                <>
                  <StyledTrendCaretIcon
                    icon="trend-caret"
                    color={lastPeriodColor}
                    negative={lastPeriodValue < 0}
                  />
                  {units(collapseNumber(lastPeriodAbsolute), dashlet.config)}
                  {isPercent ? '%' : ''}
                </>
              )}
            </KizenTypography>
            <KizenTypography size="small">
              {t('Previous Period')}
            </KizenTypography>
          </HistoricalDatum>
          <HistoricalDatum>
            <KizenTypography color={lastYearColor} size="header">
              {!lastYearDefined && '-'}
              {lastYearDefined &&
                lastYearValue === 0 &&
                units(collapseNumber(lastYearAbsolute), dashlet.config)}
              {lastYearDefined && lastYearValue !== 0 && (
                <>
                  <StyledTrendCaretIcon
                    icon="trend-caret"
                    color={lastYearColor}
                    negative={lastYearValue < 0}
                  />
                  {units(collapseNumber(lastYearAbsolute), dashlet.config)}
                  {isPercent ? '%' : ''}
                </>
              )}
            </KizenTypography>
            <KizenTypography size="small">{t('Last Year')}</KizenTypography>
          </HistoricalDatum>
        </HistoricalContainer>
      </Container>
    </Loader>
  );
};

SummaryHistorical.propTypes = {
  dashlet: DashletShape.isRequired,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

SummaryHistorical.defaultProps = {
  isLoading: true,
};

export default SummaryHistorical;
