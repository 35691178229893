import { IconName as FreeBrandsIconName } from '@fortawesome/free-brands-svg-icons';
import { IconName as LightIconName } from '@fortawesome/pro-light-svg-icons';
import { IconName as RegularIconName } from '@fortawesome/pro-regular-svg-icons';

type FreeBrandsIconDef = ['fab', FreeBrandsIconName];
type LightIconDef = ['fal', LightIconName];
type RegularIconDef = ['far', RegularIconName];

export type IconDef = FreeBrandsIconDef | LightIconDef | RegularIconDef;

export type IconName =
  | 'three-dot'
  | 'down'
  | 'close'
  | 'close-regular'
  | 'add'
  | 'left'
  | 'right'
  | 'left-regular'
  | 'right-regular'
  | 'double-right'
  | 'calendar'
  | 'save'
  | 'clock'
  | 'clock-heavy'
  | 'people'
  | 'check'
  | 'minus'
  | 'circle'
  | 'pen'
  | 'envelope'
  | 'envelope-open'
  | 'pointer'
  | 'poll'
  | 'align-center'
  | 'align-justify'
  | 'align-left'
  | 'align-right'
  | 'align-center-light'
  | 'align-justify-light'
  | 'align-left-light'
  | 'align-right-light'
  | 'sticky-note'
  | 'filter'
  | 'shopping-bag'
  | 'search'
  | 'search-inline'
  | 'money'
  | 'delete'
  | 'copy'
  | 'copy-light'
  | 'visible'
  | 'hidden'
  | 'warning'
  | 'no-sort'
  | 'sort-numeric-asc'
  | 'sort-numeric-desc'
  | 'sort-alpha-asc'
  | 'sort-alpha-desc'
  | 'sync'
  | 'sync-light'
  | 'table-columns'
  | 'table-columns-light'
  | 'tag'
  | 'chevron-down'
  | 'chevron-double-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-right-light'
  | 'chevron-double-right'
  | 'plus'
  | 'cogs'
  | 'cogs-light'
  | 'phone'
  | 'email'
  | 'email-light'
  | 'building'
  | 'users-cog'
  | 'id-card'
  | 'clipboard-list'
  | 'clipboard-check'
  | 'clipboard-check-light'
  | 'bell-exclamation'
  | 'globe'
  | 'shield-check'
  | 'wallet'
  | 'user-circle'
  | 'compass'
  | 'smile'
  | 'chart-bar'
  | 'chart-column'
  | 'database'
  | 'database-light'
  | 'browser'
  | 'play'
  | 'play-light'
  | 'cog'
  | 'info-circle'
  | 'user'
  | 'layer-group'
  | 'sack-dollar'
  | 'receipt'
  | 'calendar-alt'
  | 'bullseye-pointer'
  | 'book-alt'
  | 'clipboard-list-check'
  | 'repeat'
  | 'sign-in'
  | 'sign-out'
  | 'arrow-from-left'
  | 'arrow-from-right'
  | 'arrow-from-top'
  | 'arrow-from-bottom'
  | 'arrow-to-left'
  | 'arrow-to-right'
  | 'bars'
  | 'bars-light'
  | 'seedling'
  | 'store'
  | 'check-light'
  | 'cc-visa'
  | 'cc-discover'
  | 'cc-amex'
  | 'cc-mastercard'
  | 'comments-alt'
  | 'comments-alt-light'
  | 'comment'
  | 'pause'
  | 'bullhorn'
  | 'bullseye'
  | 'bullseye-light'
  | 'code-branch'
  | 'code-branch-light'
  | 'flask'
  | 'user-plus'
  | 'user-minus'
  | 'table'
  | 'mobile'
  | 'external-link'
  | 'toolbar-external-link'
  | 'tools'
  | 'tools-light'
  | 'tags-light'
  | 'hand-pointer'
  | 'inbox-out'
  | 'inbox-in'
  | 'cloud-arrow-up'
  | 'file-alt'
  | 'file'
  | 'folder'
  | 'folder-alt'
  | 'level-up'
  | 'brush'
  | 'highlighter'
  | 'link'
  | 'unlink'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'signal-stream'
  | 'list-ol'
  | 'list-ul'
  | 'code'
  | 'remove-format'
  | 'cancel'
  | 'graduation-cap'
  | 'comments'
  | 'question-circle'
  | 'share-alt'
  | 'arrow-right'
  | 'image'
  | 'line-height'
  | 'chevrons-right'
  | 'chevrons-down'
  | 'twitter'
  | 'facebook'
  | 'circle-1'
  | 'circle-2'
  | 'circle-3'
  | 'circle-4'
  | 'circle-5'
  | 'circle-6'
  | 'chart-pie'
  | 'less-than-equal'
  | 'key'
  | 'circle-dollar'
  | 'briefcase'
  | 'chart-line'
  | 'image-landscape'
  | 'text'
  | 'chart-pie-light'
  | 'house'
  | 'files'
  | 'files-light'
  | 'xmark'
  | 'square-list'
  | 'input-pipe'
  | 'pivot-table'
  | 'timer'
  | 'arrows-rotate'
  | 'arrows-to-circle'
  | 'history'
  | 'cloud-upload';

export const icons: Record<IconName, IconDef> = {
  'three-dot': ['far', 'ellipsis-h'],
  down: ['fal', 'angle-down'],
  close: ['fal', 'times'],
  'close-regular': ['far', 'xmark'],
  add: ['fal', 'plus'],
  left: ['fal', 'angle-left'],
  right: ['fal', 'angle-right'],
  'left-regular': ['far', 'angle-left'],
  'right-regular': ['far', 'angle-right'],
  'double-right': ['fal', 'angle-double-right'],
  calendar: ['fal', 'calendar-day'],
  save: ['far', 'save'],
  clock: ['fal', 'clock'],
  'clock-heavy': ['far', 'clock'],
  people: ['fal', 'user-friends'],
  check: ['far', 'check'],
  minus: ['far', 'minus'],
  circle: ['far', 'circle'],
  pen: ['far', 'pen'],
  envelope: ['fal', 'envelope'],
  'envelope-open': ['fal', 'envelope-open'],
  pointer: ['fal', 'mouse-pointer'],
  poll: ['fal', 'poll'],
  'align-center': ['far', 'align-center'],
  'align-justify': ['far', 'align-justify'],
  'align-left': ['far', 'align-left'],
  'align-right': ['far', 'align-right'],
  'align-center-light': ['fal', 'align-center'],
  'align-justify-light': ['fal', 'align-justify'],
  'align-left-light': ['fal', 'align-left'],
  'align-right-light': ['fal', 'align-right'],
  'sticky-note': ['fal', 'sticky-note'],
  filter: ['fal', 'filter'],
  'shopping-bag': ['fal', 'shopping-bag'],
  search: ['fal', 'magnifying-glass'],
  'search-inline': ['far', 'magnifying-glass'],
  money: ['fal', 'dollar-sign'],
  delete: ['far', 'trash-alt'],
  copy: ['far', 'copy'],
  'copy-light': ['fal', 'copy'],
  visible: ['far', 'eye'],
  hidden: ['far', 'eye-slash'],
  warning: ['far', 'exclamation-triangle'],
  'no-sort': ['far', 'sort-alt'],
  'sort-numeric-asc': ['far', 'sort-numeric-down'],
  'sort-numeric-desc': ['far', 'sort-numeric-down-alt'],
  'sort-alpha-asc': ['far', 'sort-alpha-down'],
  'sort-alpha-desc': ['far', 'sort-alpha-down-alt'],
  sync: ['far', 'sync'],
  'sync-light': ['fal', 'sync'],
  'table-columns': ['far', 'table-columns'],
  'table-columns-light': ['fal', 'table-columns'],
  tag: ['far', 'tag'],
  'chevron-down': ['far', 'chevron-down'],
  'chevron-double-down': ['far', 'chevron-double-down'],
  'chevron-left': ['far', 'chevron-left'],
  'chevron-right': ['far', 'chevron-right'],
  'chevron-right-light': ['fal', 'chevron-right'],
  'chevron-double-right': ['far', 'chevron-double-right'],
  plus: ['far', 'plus'],
  cogs: ['far', 'cogs'],
  'cogs-light': ['fal', 'cogs'],
  phone: ['far', 'phone'],
  email: ['far', 'envelope'],
  'email-light': ['fal', 'envelope'],
  building: ['fal', 'building'],
  'users-cog': ['fal', 'users-cog'],
  'id-card': ['fal', 'id-card'],
  'clipboard-list': ['fal', 'clipboard-list'],
  'clipboard-check': ['far', 'clipboard-check'],
  'clipboard-check-light': ['fal', 'clipboard-check'],
  'bell-exclamation': ['fal', 'bell-exclamation'],
  globe: ['fal', 'globe'],
  'shield-check': ['fal', 'shield-check'],
  wallet: ['fal', 'wallet'],
  'user-circle': ['fal', 'user-circle'],
  compass: ['fal', 'compass'],
  smile: ['fal', 'smile'],
  'chart-bar': ['far', 'chart-bar'],
  'chart-column': ['fal', 'chart-column'],
  database: ['far', 'database'],
  'database-light': ['fal', 'database'],
  browser: ['far', 'browser'],
  play: ['far', 'play'],
  'play-light': ['fal', 'play'],
  cog: ['far', 'cog'],
  'info-circle': ['fal', 'info-circle'],
  user: ['fal', 'user'],
  'layer-group': ['fal', 'layer-group'],
  'sack-dollar': ['fal', 'sack-dollar'],
  receipt: ['fal', 'receipt'],
  'calendar-alt': ['fal', 'calendar-alt'],
  'bullseye-pointer': ['fal', 'bullseye-pointer'],
  'book-alt': ['fal', 'book-alt'],
  'clipboard-list-check': ['fal', 'clipboard-list-check'],
  repeat: ['fal', 'repeat'],
  'sign-in': ['fal', 'sign-in'],
  'sign-out': ['fal', 'sign-out'],
  'arrow-from-left': ['far', 'arrow-from-left'],
  'arrow-from-right': ['far', 'arrow-from-right'],
  'arrow-from-top': ['far', 'arrow-from-top'],
  'arrow-from-bottom': ['far', 'arrow-from-bottom'],
  'arrow-to-left': ['fal', 'arrow-to-left'],
  'arrow-to-right': ['fal', 'arrow-to-right'],
  bars: ['far', 'bars'],
  'bars-light': ['fal', 'bars'],
  seedling: ['fal', 'seedling'],
  store: ['fal', 'store'],
  'check-light': ['fal', 'check'],
  'cc-visa': ['fab', 'cc-visa'],
  'cc-discover': ['fab', 'cc-discover'],
  'cc-amex': ['fab', 'cc-amex'],
  'cc-mastercard': ['fab', 'cc-mastercard'],
  'comments-alt': ['far', 'comments-alt'],
  'comments-alt-light': ['fal', 'comments-alt'],
  comment: ['far', 'message-lines'],
  pause: ['far', 'pause'],
  bullhorn: ['far', 'bullhorn'],
  bullseye: ['far', 'bullseye'],
  'bullseye-light': ['fal', 'bullseye'],
  'code-branch': ['far', 'code-branch'],
  'code-branch-light': ['fal', 'code-branch'],
  flask: ['far', 'flask'],
  'user-plus': ['fal', 'user-plus'],
  'user-minus': ['fal', 'user-minus'],
  table: ['fal', 'table'],
  mobile: ['fal', 'mobile'],
  'external-link': ['far', 'external-link'],
  'toolbar-external-link': ['fal', 'external-link'],
  tools: ['far', 'tools'],
  'tools-light': ['fal', 'tools'],
  'tags-light': ['fal', 'tags'],
  'hand-pointer': ['fal', 'hand-pointer'],
  'inbox-out': ['fal', 'inbox-out'],
  'inbox-in': ['fal', 'inbox-in'],
  'cloud-arrow-up': ['fal', 'cloud-arrow-up'],
  'file-alt': ['fal', 'file-alt'],
  file: ['fal', 'file-alt'],
  folder: ['far', 'folder'],
  'folder-alt': ['fal', 'folder'],
  'level-up': ['fal', 'level-up'],
  brush: ['far', 'brush'],
  highlighter: ['far', 'highlighter'],
  link: ['far', 'link'],
  unlink: ['far', 'unlink'],
  bold: ['far', 'bold'],
  italic: ['far', 'italic'],
  underline: ['far', 'underline'],
  strikethrough: ['far', 'strikethrough'],
  'signal-stream': ['far', 'signal-stream'],
  'list-ol': ['far', 'list-ol'],
  'list-ul': ['far', 'list-ul'],
  code: ['far', 'code'],
  'remove-format': ['far', 'remove-format'],
  cancel: ['far', 'ban'],
  'graduation-cap': ['fal', 'graduation-cap'],
  comments: ['fal', 'comments'],
  'question-circle': ['fal', 'question-circle'],
  'share-alt': ['fal', 'share-alt'],
  'arrow-right': ['far', 'arrow-right'],
  image: ['far', 'image'],
  'line-height': ['far', 'line-height'],
  'chevrons-right': ['far', 'chevron-double-right'],
  'chevrons-down': ['far', 'chevron-double-down'],
  twitter: ['fab', 'twitter'],
  facebook: ['fab', 'facebook-f'],
  'circle-1': ['fal', 'circle-1'],
  'circle-2': ['fal', 'circle-2'],
  'circle-3': ['fal', 'circle-3'],
  'circle-4': ['fal', 'circle-4'],
  'circle-5': ['fal', 'circle-5'],
  'circle-6': ['fal', 'circle-6'],
  'chart-pie': ['far', 'chart-pie'],
  'less-than-equal': ['far', 'less-than-equal'],
  key: ['fal', 'key'],
  'chart-line': ['fal', 'chart-line'],
  'image-landscape': ['fal', 'image-landscape'],
  text: ['far', 'text'],
  'chart-pie-light': ['fal', 'chart-pie'],
  'circle-dollar': ['fal', 'circle-dollar'],
  briefcase: ['fal', 'briefcase'],
  house: ['fal', 'house-blank'],
  files: ['far', 'files'],
  'files-light': ['fal', 'files'],
  xmark: ['far', 'xmark'],
  'square-list': ['fal', 'square-list'],
  'input-pipe': ['fal', 'input-pipe'],
  'pivot-table': ['fal', 'table-pivot'],
  timer: ['fal', 'timer'],
  'arrows-rotate': ['far', 'arrows-rotate'],
  'arrows-to-circle': ['fal', 'arrows-to-circle'],
  history: ['far', 'clock-rotate-left'],
  'cloud-upload': ['fal', 'cloud-upload'],
};
