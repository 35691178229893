import { ContactsPage, CustomObjectsPage } from './pages';

import paths from './paths';

const getRoutes = () => {
  const routes = {
    contacts: {
      label: (t) => t('Contacts'),
      component: ContactsPage,
      exact: false,
    },
    'custom-objects/:id?': {
      label: (t) => t('Custom Objects'),
      component: CustomObjectsPage,
      exact: false,
    },
  };

  paths.forEach((path, routeId) => {
    if (routes[routeId]) {
      routes[routeId].path = path;
    }
  });

  return routes;
};

export default getRoutes;
