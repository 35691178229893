import styled from '@emotion/styled';
import InputControl from '__components/Inputs/InputControl';

export default styled(InputControl)`
  > label {
    margin-bottom: 20px;
    & + * {
      margin: 0;
      label {
        // Incidentally part of <Switch />
        margin: 0;
      }
    }
  }
`;
