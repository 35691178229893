import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import TablePagination from 'components/Tables/Big/TablePagination';
import ListCard from 'components/Layout/ListCard';
import BigListLayout, { BigListTitle } from 'components/Layout/BigListLayout';
import { BigListSearchInput } from 'components/Layout/BigListLayout/Toolbar';
import { ConnectionsListHeader, ConnectionsListItem } from './ListItem';
import { ListCardItem } from 'components/Layout/ListCard';
import { useSelector } from 'react-redux';
import { selectAdManagerIsFiltering } from 'store/adManagerPage/adManager.redux';

export const EmptySurveyListItem = ListCardItem;

export default function ConnectionsListPage({
  connections,
  connectionsCount,
  search,
  handleChangeSearch,
  sort,
  onChangeSort,
  pageNumber,
  pageSize,
  onChangePageNumber,
  onChangePageSize,
  handleSelectAction,
  ...others
}) {
  const { t } = useTranslation();
  const isFiltering = useSelector(selectAdManagerIsFiltering);

  return (
    <BigListLayout
      search={
        <BigListSearchInput
          placeholder={t('Find Accounts')}
          value={search}
          onChange={handleChangeSearch}
          loading={isFiltering}
          isClearable
        />
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={connectionsCount}
          onChangePage={onChangePageNumber}
          onChangePerPage={onChangePageSize}
        />
      }
      {...others}
    >
      <BigListTitle
        count={connectionsCount}
        single={t('Connected Account')}
        plural={t('Connected Accounts')}
      />
      <ListCard>
        <ConnectionsListHeader
          label={t('Account Name')}
          sort={sort}
          onChangeSort={(direction) =>
            onChangeSort({ column: 'name', direction })
          }
        />
        {!connections.length && <EmptySurveyListItem />}
        {connections.map((account) => (
          <ConnectionsListItem
            key={account.id}
            adAccount={account}
            onSelectAction={handleSelectAction}
          />
        ))}
      </ListCard>
    </BigListLayout>
  );
}

ConnectionsListPage.propTypes = {
  connections: PropTypes.arrayOf(PropTypes.object).isRequired,
  connectionsCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  handleChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  handleSelectAction: PropTypes.func.isRequired,
};
