import { cloneElement, forwardRef, useContext, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useToggle } from 'react-use';
import useSyncScroll from 'react-use-sync-scroll';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { grayScale, shadowLight } from 'app/colors';
import {
  borderRadii,
  hideScrollbarCss,
  scrollbarCss,
  layers,
} from 'app/spacing';
import {
  ContentWidth,
  CONTENT_MAX_WIDTH,
} from 'components/Layout/PageContentWidth';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';
import { PAGE_TOOLBAR_HEIGHT } from 'components/Layout/PageToolbar';
import { useDetachedHead, useSyncSizes } from './hooks';
import { ADD_RECORD_TAB_MAX_WIDTH } from './AddRecordTab';
import BaseBasicTable from '../Basic';
import { TableLoader } from './TableLoader';
import { BigTableLayoutContext } from 'components/Layout/BigTableLayout';

const num = (px) => parseInt(px, 10);

const TOOLBAR_HEIGHT = `${PAGE_TOOLBAR_HEIGHT}px`;
const HEADER_HEIGHT = '50px';
const HEADER_SCROLL_PLAY = '10px'; // The table header can scroll up a bit after sticking

// Determine the width at which the table has zoom to expand,
// based upon its default min width and fixed left/right margins.
const EXPANDABLE_MIN_WIDTH = `${
  num(CONTENT_MAX_WIDTH) + 2 * (num(ADD_RECORD_TAB_MAX_WIDTH) + 20)
}px`;

const CompleteTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  border-radius: ${borderRadii.standard};
  ${shadowLight}
  background-color: ${grayScale.white};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: none;
    `}
`;

const BasicTable = styled(BaseBasicTable)`
  margin-bottom: 0;
`;

const Gradient = (props) => (
  <BaseGradient fadeStart={38} fadeLength={50} {...props} />
);

// The standard display mode of table head cells does not allow
// their width to be set, so we will instead treat them as flex items.
export const detachedHeadStyles = css`
  thead tr {
    display: flex;
    align-items: center;
  }
`;

const TableHeadWrapper = styled.div`
  display: flex;
  overscroll-behavior-x: none;
  border-top-left-radius: ${borderRadii.standard};
  border-top-right-radius: ${borderRadii.standard};
  background: ${grayScale.white};
  height: ${HEADER_HEIGHT};
  ${({ headerPosition }) =>
    headerPosition &&
    css`
      position: ${headerPosition};
    `}

  top: calc(${TOOLBAR_HEIGHT} - ${HEADER_SCROLL_PLAY});
  z-index: ${layers.content(0, 1)};
  transition: box-shadow 0.5s ease-in-out;
  overflow-x: auto;
  ${hideScrollbarCss}

  table {
    ${detachedHeadStyles}
    width: 100%;
    thead > tr {
      // The bottom border should go into the table
      // so that the left/right fade gradient appears
      // on top of it. We chose this element because it is
      // explicitly sized and will not grow any taller despite the border.
      transition: border 0.5s ease-in-out;
      border-bottom: 1px solid transparent;
    }
  }
  ${({ scrolled }) =>
    !scrolled &&
    css`
      table {
        thead > tr {
          border-color: ${grayScale.mediumLight};
        }
      }
      clip-path: inset(-26px -26px 0.25px -26px);
    `};
  th:not(.Resizing) .ResizerLayout {
    display: none;
  }
  &:hover th .ResizerLayout {
    display: flex;
  }
`;

const TableWrapper = styled.div`
  overscroll-behavior-x: none;
  display: flex;
  flex: 1;
  border-bottom-left-radius: ${borderRadii.standard};
  border-bottom-right-radius: ${borderRadii.standard};
  background-color: ${grayScale.white};
  overflow-x: auto;

  ${hideScrollbarCss}
  table {
    margin-top: -${HEADER_HEIGHT}; // Hide underneath the detached header
    width: 100%;

    thead {
      visibility: hidden;
    }
  }

  transition: opacity 0.3s ease-in-out;
  ${({ staleData }) =>
    staleData &&
    css`
      opacity: 0.5;
    `}
`;

const ScrollbarContentWrapper = styled.div`
  position: sticky;
  bottom: 5px;
  overflow-x: auto;
  min-height: 8px;
  ${scrollbarCss()}
  &::-webkit-scrollbar-thumb {
    margin-top: 4px;
    width: 2.4px;
    border-radius: 8px;
    background-color: ${grayScale.medium};
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: ${grayScale.mediumDark};
  }
  &:hover::-webkit-scrollbar {
    height: 8px;
  }
  > div {
    // We do need some height here in order for any scrollbars to show,
    // but we don't really want to take-up any space. It seems smaller
    // than .25px doesn't take-up space, at least on chrome.
    height: 0.1px;
  }
`;

const AddButtonWrapper = styled.div`
  position: sticky;
  top: calc(${PAGE_TOOLBAR_HEIGHT}px - ${HEADER_SCROLL_PLAY});
  height: 0;
  > * {
    transform: translate(-100%, 50px);
  }
`;

const ExpandButtonWrapper = styled.div`
  display: none;
  @media (min-width: ${EXPANDABLE_MIN_WIDTH}) {
    display: flex;
  }
  position: sticky;
  top: calc(${PAGE_TOOLBAR_HEIGHT}px - ${HEADER_SCROLL_PLAY});
  height: 0;
  justify-content: flex-end;
  // Appear above gradient when overlapping table
  // and below shadow toolbar when not overlapping table.
  z-index: ${({ scrolled }) =>
    scrolled ? layers.content(0) : layers.content(0, 2)};
  && > * {
    margin-top: -20px;
    // y-translation is calculated centering
    // relative to the table row: 50px + (40px - 30px) / 2
    transform: translate(50%, 55px);
    ${({ scrolled }) =>
      scrolled &&
      css`
        // The transition just looks better with a margin,
        // perhaps because the margin is _not_ animated.
        margin-top: -4px;
        transform: translate(100%, 55px);
      `}
  }
`;

export default function BigTable({
  head,
  columns,
  addRecordTab = null,
  expandTableTab = null,
  scrolledToTable,
  children,
  staleData = false,
  zIndex = null,
  headerPosition = 'sticky',
  tableTitle,
  ...props
}) {
  const [tableExpanded, toggleTableExpanded] = useToggle();
  const scrollbarContentWrapperRef = useRef();
  const tableHeadRef = useRef();
  const tableHeadWrapperRef = useRef();
  const [tableRefFn] = useDetachedHead(tableHeadRef, [columns, tableExpanded]);
  const [tableWrapperRefFn, tableWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.BodyForSyncWidth',
    'width'
  );

  useSyncScroll(
    useRef([tableWrapperRef, tableHeadWrapperRef, scrollbarContentWrapperRef]),
    {
      horizontal: true,
    }
  );

  const [, rightScrolled, , leftScrolled] = useEndsOfScroll(tableWrapperRef, [
    columns,
    tableExpanded,
  ]);

  return (
    <CompleteTableWrapper
      as={ContentWidth}
      fullWidth={tableExpanded}
      {...props}
    >
      {addRecordTab && (
        <AddButtonWrapper>
          {cloneElement(addRecordTab, {
            scrolled: scrolledToTable,
            expanded: tableExpanded,
          })}
        </AddButtonWrapper>
      )}
      {expandTableTab && (
        <ExpandButtonWrapper scrolled={scrolledToTable}>
          {cloneElement(expandTableTab, {
            scrolled: scrolledToTable,
            expanded: tableExpanded,
            onClick: toggleTableExpanded,
          })}
        </ExpandButtonWrapper>
      )}
      {staleData && <TableLoader />}
      {tableTitle || null}
      <TableHeadWrapper
        ref={tableHeadWrapperRef}
        scrolled={scrolledToTable}
        headerPosition={headerPosition}
      >
        <Gradient position="left" fadeIn={!leftScrolled} zIndex={zIndex} />
        <BasicTable ref={tableHeadRef} head={head} />
        <Gradient position="right" fadeIn={!rightScrolled} zIndex={zIndex} />
      </TableHeadWrapper>
      <TableWrapper ref={tableWrapperRefFn} staleData={staleData}>
        <Gradient position="left" fadeIn={!leftScrolled} zIndex={zIndex} />
        <BasicTable ref={tableRefFn} className="BodyForSyncWidth" head={head}>
          {children}
        </BasicTable>
        <Gradient
          className="biggrad"
          position="right"
          fadeIn={!rightScrolled}
          zIndex={zIndex}
        />
      </TableWrapper>
      <ScrollbarContentWrapper ref={scrollbarContentWrapperRef}>
        <div className="BodyForSyncWidth" />
      </ScrollbarContentWrapper>
    </CompleteTableWrapper>
  );
}

const AddButtonTabWithLayoutConsumer = ({ addRecordTab, expanded }) => {
  const { scrolledToTable } = useContext(BigTableLayoutContext);
  return addRecordTab ? (
    <AddButtonWrapper>
      {cloneElement(addRecordTab, {
        scrolled: scrolledToTable,
        expanded,
      })}
    </AddButtonWrapper>
  ) : null;
};

const ExpandButtonWrapperWithLayoutConsumer = ({
  expandTableTab,
  tableExpanded,
  toggleTableExpanded,
}) => {
  const { scrolledToTable } = useContext(BigTableLayoutContext);
  return (
    expandTableTab && (
      <ExpandButtonWrapper scrolled={scrolledToTable}>
        {cloneElement(expandTableTab, {
          scrolled: scrolledToTable,
          expanded: tableExpanded,
          onClick: toggleTableExpanded,
        })}
      </ExpandButtonWrapper>
    )
  );
};

const TableHeadWrapperWithLayoutConsumer = forwardRef(
  ({ headerPosition, children }, ref) => {
    const { scrolledToTable } = useContext(BigTableLayoutContext);
    return (
      <TableHeadWrapper
        ref={ref}
        scrolled={scrolledToTable}
        headerPosition={headerPosition}
      >
        {children}
      </TableHeadWrapper>
    );
  }
);

export const BigTableWithLayoutConsumer = ({
  head,
  columns,
  addRecordTab,
  expandTableTab,
  children,
  staleData,
  zIndex = null,
  headerPosition = 'sticky',
  tableTitle,
  ...props
}) => {
  const [tableExpanded, toggleTableExpanded] = useToggle();
  const scrollbarContentWrapperRef = useRef();
  const tableHeadRef = useRef();
  const tableHeadWrapperRef = useRef();
  const [tableRefFn] = useDetachedHead(tableHeadRef, [columns, tableExpanded]);
  const [tableWrapperRefFn, tableWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.BodyForSyncWidth',
    'width'
  );

  useSyncScroll(
    useRef([tableWrapperRef, tableHeadWrapperRef, scrollbarContentWrapperRef]),
    {
      horizontal: true,
    }
  );

  const [, rightScrolled, , leftScrolled] = useEndsOfScroll(tableWrapperRef, [
    columns,
    tableExpanded,
  ]);

  return (
    <CompleteTableWrapper
      as={ContentWidth}
      fullWidth={tableExpanded}
      {...props}
    >
      <AddButtonTabWithLayoutConsumer
        addRecordTab={addRecordTab}
        expanded={tableExpanded}
      />
      <ExpandButtonWrapperWithLayoutConsumer
        expandTableTab={expandTableTab}
        tableExpanded={tableExpanded}
        toggleTableExpanded={toggleTableExpanded}
      />
      {staleData && <TableLoader />}
      {tableTitle || null}
      <TableHeadWrapperWithLayoutConsumer
        ref={tableHeadWrapperRef}
        headerPosition={headerPosition}
      >
        <Gradient position="left" fadeIn={!leftScrolled} zIndex={zIndex} />
        <BasicTable ref={tableHeadRef} head={head} />
        <Gradient position="right" fadeIn={!rightScrolled} zIndex={zIndex} />
      </TableHeadWrapperWithLayoutConsumer>
      <TableWrapper ref={tableWrapperRefFn} staleData={staleData}>
        <Gradient position="left" fadeIn={!leftScrolled} zIndex={zIndex} />
        <BasicTable ref={tableRefFn} className="BodyForSyncWidth" head={head}>
          {children}
        </BasicTable>
        <Gradient
          className="biggrad"
          position="right"
          fadeIn={!rightScrolled}
          zIndex={zIndex}
        />
      </TableWrapper>
      <ScrollbarContentWrapper ref={scrollbarContentWrapperRef}>
        <div className="BodyForSyncWidth" />
      </ScrollbarContentWrapper>
    </CompleteTableWrapper>
  );
};
