import React, { useRef, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import Cols from 'components/Layout/Cols';
import { useFlashTransition } from 'hooks/useFlashState';
import { StyledFormInput } from './styles';
import { StyledSubSection } from '../styles';

const StyledCard = styled(Card)`
  max-width: 100%;
`;

const UpdatePasswordForm = ({ values, onFieldChange, onSubmit, formError }) => {
  const [
    currentPasswordError,
    showCurrentPasswordError,
    flashCurrentPasswordError,
  ] = useFlashTransition();
  const currentPasswordRef = useRef();

  const { t } = useTranslation();

  useUpdateEffect(() => {
    if (!currentPasswordError && currentPasswordRef.current) {
      // TODO this functionality appears not to be working
      currentPasswordRef.current.focus();
    }
  }, [currentPasswordError]);

  const [passwordError, showPasswordError, flashPasswordError] =
    useFlashTransition();
  const passwordRef = useRef();

  useUpdateEffect(() => {
    if (!passwordError && passwordRef.current) {
      // TODO this functionality appears not to be working
      passwordRef.current.focus();
    }
  }, [passwordError]);

  useEffect(() => {
    if (formError.current_password) {
      flashCurrentPasswordError(formError.current_password);
    }

    if (formError.password) {
      flashPasswordError(formError.password);
    }
  }, [formError, flashCurrentPasswordError, flashPasswordError]);

  return (
    <StyledCard>
      <StyledSubSection
        title={t('Update Password')}
        action={
          <Button noSpace disabled={false} onClick={onSubmit}>
            {t('Update')}
          </Button>
        }
      >
        <Cols columns={2} gutter={`${gutters.spacing(4)}px`}>
          <StyledFormInput
            ref={currentPasswordRef}
            type="password"
            label={t('Current Password')}
            autoComplete="new-password"
            placeholder={t('Enter Current Password')}
            value={values.current_password}
            onChange={(value) => onFieldChange('current_password', value)}
            className="first-row"
            validate={{
              message: currentPasswordError,
              showMessage: showCurrentPasswordError,
            }}
          />
          <StyledFormInput
            ref={passwordRef}
            type="password"
            label={t('New Password')}
            placeholder={t('Enter New Password')}
            value={values.password}
            onChange={(value) => onFieldChange('password', value)}
            className="first-row"
            required
            validate={{
              message: passwordError,
              showMessage: showPasswordError,
            }}
          />
        </Cols>
      </StyledSubSection>
    </StyledCard>
  );
};

UpdatePasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formError: PropTypes.string,
};

UpdatePasswordForm.defaultProps = {
  formError: null,
};

export default UpdatePasswordForm;
