import { useCallback, useState } from 'react';
import { gutters } from 'app/spacing';
import { getBreakpointForWidth } from '../utils';
import {
  DASHBOARD_BREAKPOINT_NAMES,
  DASHBOARD_COLUMNS,
  MOBILE_DASHLET_HEIGHT,
} from '../types';

const useDashboardGridBreakpoint = (loading = false) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [gridBreakpointState, setGridBreakpointState] = useState(null);

  const draggable = gridBreakpointState === DASHBOARD_BREAKPOINT_NAMES.XL;
  const editable = draggable && !loading;

  const handleChangeContainerWidth = useCallback((w) => {
    if (typeof w === 'number') {
      setContainerWidth(w);
      setGridBreakpointState(getBreakpointForWidth(w));
    }
  }, []);

  const columnCount = DASHBOARD_COLUMNS[gridBreakpointState];

  const height =
    columnCount === 2
      ? MOBILE_DASHLET_HEIGHT
      : containerWidth / columnCount - gutters.spacing(2, 1);

  return {
    breakpoint: gridBreakpointState,
    setBreakpoint: setGridBreakpointState,
    setContainerWidth: handleChangeContainerWidth,
    containerWidth,
    rowHeight: height,
    editable,
    draggable,
    columnCount,
  };
};

export default useDashboardGridBreakpoint;
