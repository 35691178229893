import { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { toWholeNumber } from 'components/Inputs/TextInput/presets/WholeNumber';
import { FIELD_TYPES } from 'utility/constants';

import {
  validateWholeNumber,
  negativesValidateWholeNumber,
} from 'components/Inputs/TextInput/NumericValidation/helpers';

import NumericInline from './Numeric';

export default function WholeNumberInline({
  currencySymbol,
  allowNegatives = true,
  onSubmit,
  placeholder,
  ...others
}) {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (val) => onSubmit(toWholeNumber(val)),
    [onSubmit]
  );

  return (
    <NumericInline
      numberFormat={{
        decimalScale: 0,
        thousandSeparator: true,
        isInteger: true,
        placeholder: placeholder || FIELD_TYPES.Integer.placeholder,
      }}
      validate={
        allowNegatives
          ? negativesValidateWholeNumber(t)
          : validateWholeNumber(t)
      }
      onSubmit={handleSubmit}
      placeholder={placeholder}
      {...others}
    />
  );
}

WholeNumberInline.propTypes = {
  currencySymbol: PropTypes.string,
  allowNegatives: PropTypes.bool,
};

WholeNumberInline.defaultProps = {
  currencySymbol: '$',
  allowNegatives: true,
};
