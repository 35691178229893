import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import Button from 'components/Button';
import { useCSVString } from './useCSVString';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

const StyledButton = styled(Button)`
  margin: ${gutters.spacing(1, -2)}px 0 ${gutters.spacing(3)}px;
`;

const DownloadSCVTemplateButton = ({
  customObject = EMPTY_OBJECT,
  fields,
  text,
  includeRow = true,
  ...props
}) => {
  const teamMember = useSelector((s) => s.authentication.teamMember || {}); // current user
  const { downloadHandler } = useCSVString({
    fields,
    teamMember,
    includeRow,
    customObject,
  });

  return (
    <StyledButton onClick={downloadHandler} variant="outline" {...props}>
      {text}
    </StyledButton>
  );
};

export default DownloadSCVTemplateButton;
