import Loader from 'components/Kizen/Loader';
import { ContactsSection } from 'pages/Settings/pages/Team/PermissionGroupModal_V2/ContactsSection';
const ContactSectionCard = ({ isLoading }) => {
  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <ContactsSection
      showArrowBtn={false}
      initialExpanderState={'open'}
      showSwitchToggle={false}
      marginTop={0}
      marginBottom={0}
    />
  );
};
export default ContactSectionCard;
