import { debugMode } from 'pages/Dashboard/context/dataManager/useDataManager';
import { createContext, useContext, useReducer } from 'react';

const DataManagerSessionContext = createContext({});

export const DATA_MANAGER_SESSION_ACTIONS = {
  UPDATE: 'update',
  RESET: 'reset',
  CLEAR_EXECUTION: 'clearExecution',
  DELETE: 'delete',
};

const defaultState = {
  parents: new Map(),
};

const sessionStateReducer = (state, action) => {
  const {
    id,
    ids,
    payload,
    parentId,
    type = DATA_MANAGER_SESSION_ACTIONS.UPDATE,
  } = action;

  switch (type) {
    case DATA_MANAGER_SESSION_ACTIONS.UPDATE: {
      const next = {
        ...state,
        [id]: payload,
      };

      if (parentId) {
        next.parents = {
          ...state.parents,
          [parentId]: {
            ...state.parents[parentId],
            [id]: true,
          },
        };
      }

      if (debugMode) {
        console.log(
          `%c[DATA MANAGER] - setting session state for ${id} (single mode)`,
          'color: #319cde;'
        );
      }

      return next;
    }
    case DATA_MANAGER_SESSION_ACTIONS.CLEAR_EXECUTION: {
      const next = {
        ...state,
      };

      if (ids?.length) {
        ids.forEach((id) => {
          if (debugMode) {
            console.log(
              `%c[DATA MANAGER] - setting session state for ${id} (multi-mode)`,
              'color: #319cde;'
            );
          }
          if (next[id]) {
            next[id] = {
              ...next[id],
              executed: new Map(),
            };
          }
        });
      }

      return next;
    }
    case DATA_MANAGER_SESSION_ACTIONS.RESET: {
      if (parentId) {
        if (debugMode) {
          console.log(
            `%c[DATA MANAGER] - resetting session state for ${parentId} (parent)`,
            'color: #319cde;'
          );
        }

        const next = {
          ...state,
        };

        const children = state.parents[parentId];
        if (children) {
          const childIds = Object.keys(children);

          if (childIds.length) {
            childIds.forEach((id) => {
              if (debugMode) {
                console.log(
                  `%c[DATA MANAGER] - resetting session state for ${id} (child)`,
                  'color: #319cde;'
                );
              }

              delete next[id];
            });
          }

          return next;
        }
      }

      return state;
    }
    case DATA_MANAGER_SESSION_ACTIONS.DELETE: {
      if (debugMode) {
        console.log(
          `%c[DATA MANAGER] - deleting session state`,
          'color: #319cde;'
        );
      }
      return defaultState;
    }
    default:
      return state;
  }
};

export const DataManagerSessionProvider = ({ children }) => {
  const [sessionState, dispatchSessionState] = useReducer(
    sessionStateReducer,
    defaultState
  );

  if (debugMode) {
    console.log('[DATA MANAGER] (Session)', sessionState);
  }

  return (
    <DataManagerSessionContext.Provider
      value={{ sessionState, dispatchSessionState }}
    >
      {children}
    </DataManagerSessionContext.Provider>
  );
};

export const useDataManagerSession = () => {
  const ctx = useContext(DataManagerSessionContext);

  return ctx;
};
