import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BasicModal from 'components/Modals/presets/BasicModal';
import useModal from 'components/Modals/useModal';
import { ModalBody } from './styles';
import Button from 'components/Button';
import styled from '@emotion/styled';
import css from '@emotion/css';
export const EXPIRED_MODAL_TYPES = {
  SECURITY: 'security',
  INACTIVITY: 'inactivity',
};

const StyledBasicModal = styled(BasicModal)`
  ${({ diffPaddings }) =>
    diffPaddings &&
    css`.modal-footer button.btn {
    margin-left: 15px;`}
`;

export const ExpiredModal = ({
  type,
  handleSubmit,
  handleCancel,
  onHide,
  loading,
  logOut,
  remaining,
}) => {
  const { t } = useTranslation();
  const [modalProps, , { hide: hideModal }] = useModal({
    handleSubmit,
    handleHide: () => {},
    initialValue: true,
  });

  const modalAdditionalProps = useMemo(() => {
    const map = {
      [EXPIRED_MODAL_TYPES.INACTIVITY]: {
        heading: t('Inactivity Session Time Out'),
        defaultLeftBtnText: t('Log Out Now'),
        defaultLeftBtnHandler: () => logOut(null),
        leftBtn: <Button color="blue" />,
        buttonText: t('Extend Session'),
        bodyText: t(
          `Your session is expiring due to inactivity. You will be logged out at the time below if you don't take action`
        ),
      },
      [EXPIRED_MODAL_TYPES.SECURITY]: {
        heading: t('Security Session Time Out'),
        buttonText: t('Log Out Now'),
        defaultLeftBtnText: t('Close'),
        actionBtnColor: 'blue',
        bodyText: t(
          `Your session is expiring due to security. You will be logged out at the time below`
        ),
      },
    };
    return map[type] || map[EXPIRED_MODAL_TYPES.SECURITY];
  }, [type, t, logOut]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (remaining === 0) {
        clearInterval(interval);
        EXPIRED_MODAL_TYPES.INACTIVITY === type ? handleCancel() : logOut();
        hideModal();
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [type, handleCancel, hideModal, logOut, remaining]);

  return (
    <StyledBasicModal
      diffPaddings={EXPIRED_MODAL_TYPES.INACTIVITY === type}
      {...modalProps}
      {...modalAdditionalProps}
      onHide={onHide}
      loading={loading}
    >
      <ModalBody>
        <div className="text"> {modalAdditionalProps.bodyText}:</div>
        <div className="timer">
          {remaining >= 0 ? <>{Math.floor(remaining / 1000)} s</> : null}
        </div>
      </ModalBody>
    </StyledBasicModal>
  );
};
