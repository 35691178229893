import styled from '@emotion/styled';
import TextTemplater from 'components/Kizen/TextTemplater';

import {
  SEND_TEXT_IDENTIFIER,
  CHANGE_TAGS_IDENTIFIER,
  MANAGE_TEAM_IDENTIFIER,
  MANAGE_SUBSCRIPTIONS,
  EXPORT_IDENTIFIER,
  ARCHIVE_IDENTIFIER,
  CHANGE_FIELD_IDENTIFIER,
  SEND_EMAIL_IDENTIFIER,
  SEND_SURVEY_IDENTIFIER,
  MODIFY_AUTOMATION_IDENTIFIER,
} from './model';
import ChangeTags from './StepsContent/ChangeTags';
import ManageTeam from './StepsContent/ManageTeam';
import ManageSubscriptions from './StepsContent/ManageSubscriptions';
import { ChangeFieldValue } from './StepsContent/ChangeFieldValue';
import SendEmail from './StepsContent/SendEmail';
import SendSurvey from './StepsContent/SendSurvey';
import StartAutomation from './StepsContent/StartAutomation';
import ChooseColumnsToExport from './StepsContent/ChooseColumnsToExport';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
`;

const SelectedActionStep = ({
  userAction = null,
  sendTextValue = '',
  sendTextMergeFields,
  onSetSendTextValue,
  tagsToAdd,
  tagsToRemove,
  tagsField,
  field = null,
  fieldValue,
  resolution = null,
  removeTags,
  onAddTagsToAdd,
  onAddTagsToRemove,
  teamMembers,
  onAddTeamMembers,
  grantMyRecordsAccess,
  onGrantMyRecordsAccess,
  onSetSubscriptionItem,
  onSetSubscriptionStatus,
  subscriptionItem,
  subscriptionStatus,
  setField,
  setFieldValue,
  setResolutionValue,
  setRemoveTags,
  sendEmailStep,
  surveyValue,
  setSurveyValue,
  automation,
  setAutomation,
  model,
  selectedOption,
  setSelectedOption,
  includeKizenId,
  setIncludeKizenId,
  secondaryAction,
  setSecondaryAction,
}) => {
  if (userAction) {
    switch (userAction.value) {
      case SEND_TEXT_IDENTIFIER:
        return (
          <TextTemplater
            onSave={onSetSendTextValue}
            defaultValue={sendTextValue}
            customize={{
              mergeFields: sendTextMergeFields,
            }}
            textareaOptions={{
              initialHeight: 140,
            }}
          />
        );
      case SEND_EMAIL_IDENTIFIER:
        return <SendEmail {...sendEmailStep} />;
      case CHANGE_TAGS_IDENTIFIER:
        return (
          <ModalBody>
            <ChangeTags
              tagsToAdd={tagsToAdd}
              tagsToRemove={tagsToRemove}
              onAddTagsToAdd={onAddTagsToAdd}
              onAddTagsToRemove={onAddTagsToRemove}
              field={tagsField}
            />
          </ModalBody>
        );
      case MANAGE_TEAM_IDENTIFIER:
        return (
          <ModalBody>
            <ManageTeam
              teamMembers={teamMembers}
              onAddTeamMembers={onAddTeamMembers}
              onGrantMyRecordsAccess={onGrantMyRecordsAccess}
              grantMyRecordsAccess={grantMyRecordsAccess}
            />
          </ModalBody>
        );
      case MANAGE_SUBSCRIPTIONS:
        return (
          <ModalBody>
            <ManageSubscriptions
              onSetSubscriptionItem={onSetSubscriptionItem}
              onSetSubscriptionStatus={onSetSubscriptionStatus}
              subscriptionItem={subscriptionItem}
              subscriptionStatus={subscriptionStatus}
            />
          </ModalBody>
        );
      case CHANGE_FIELD_IDENTIFIER: {
        return (
          <ChangeFieldValue
            field={field}
            fieldValue={fieldValue}
            resolution={resolution}
            removeTags={removeTags}
            setField={setField}
            setFieldValue={setFieldValue}
            setResolutionValue={setResolutionValue}
            setRemoveTags={setRemoveTags}
            model={model}
          />
        );
      }
      case SEND_SURVEY_IDENTIFIER: {
        return (
          <SendSurvey
            surveyValue={surveyValue}
            setSurveyValue={setSurveyValue}
          />
        );
      }
      case MODIFY_AUTOMATION_IDENTIFIER: {
        return (
          <StartAutomation
            automation={automation}
            setAutomation={setAutomation}
            objectId={model.id}
            secondaryAction={secondaryAction}
            setSecondaryAction={setSecondaryAction}
          />
        );
      }
      case EXPORT_IDENTIFIER:
        return (
          <ChooseColumnsToExport
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            includeKizenId={includeKizenId}
            setIncludeKizenId={setIncludeKizenId}
          />
        );
      case ARCHIVE_IDENTIFIER:
        return null;
      default: {
        return null;
      }
    }
  }
  return null;
};

export default SelectedActionStep;
