import { useMemo } from 'react';
import styled from '@emotion/styled';
import { colorsPrimary } from 'app/colors';
import css from '@emotion/css';
import { useMergeFields } from '../use-merge-fields';
import { Pill, PillWrapper } from './PillNodeView';

const StyledPillWrapper = styled(PillWrapper)`
  height: unset;
  top: 1px;

  > span {
    ${({ selected }) =>
      selected &&
      css`
        border-color: ${colorsPrimary.blue.dark};
      `}
    ${({ backgroundColor }) =>
      backgroundColor &&
      css`
        background-color: ${backgroundColor};
      `}
  }
`;

const StyledPill = styled(Pill)`
  span {
    color: unset;
    font-weight: unset;
  }

  ${({ textDecoration }) =>
    textDecoration?.length &&
    css`
      text-decoration: ${textDecoration};
    `}
`;

export const MergeFieldPill = ({
  deleteNode,
  editor,
  getPos,
  node,
  selected,
}) => {
  const { editable } = editor.options;
  const {
    attrs: { label, relationship, objectname },
  } = node;
  const { data, isLoading } = useMergeFields();

  const mergeField = useMemo(() => {
    return isLoading
      ? null
      : data.find((field) => field.relationship === relationship);
  }, [data, relationship, isLoading]);

  const { textDecoration, backgroundColor } = useMemo(() => {
    const { decoration, highlight } = node.marks.reduce(
      (acc, { type: { name }, attrs }) => {
        if (name === 'underline') acc.decoration.push('underline');
        if (name === 'strike') acc.decoration.push('line-through');
        if (name === 'textStyle') acc.highlight = attrs.highlight;
        return acc;
      },
      { decoration: [], highlight: null }
    );
    return { textDecoration: decoration.join(' '), backgroundColor: highlight };
  }, [node.marks]);

  const handleDelete = (_, ev) => {
    ev.stopPropagation();
    deleteNode();
  };

  return (
    <StyledPillWrapper
      as="button"
      selected={selected}
      backgroundColor={backgroundColor}
      onClick={() =>
        editor
          .chain()
          // Ensures a single position is selected (state.selection.empty = true)
          // Without this the entire editor content is selected, messing up the active marks
          .setTextSelection(getPos())
          .setNodeSelection(getPos()) // this is what makes props.selected = true
          .run()
      }
    >
      <StyledPill
        hideRemoveIcon={!editable}
        maxWidth="250px"
        name={`{{ ${mergeField?.label ?? label} ${
          objectname ? `(${objectname}) ` : ''
        }}}`}
        onRemove={handleDelete}
        textDecoration={textDecoration}
      />
    </StyledPillWrapper>
  );
};
