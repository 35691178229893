import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import BackButton from 'components/Button/BackButton';
import { appBackground, grayScale } from 'app/colors';
import { breakpoints } from 'app/spacing';

const Container = styled.div`
  background-color: ${({ scrolledTop }) =>
    scrolledTop ? appBackground : grayScale.white} !important;
  display: flex;
  max-width: ${breakpoints.xl}px;
`;

const ChildContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  & > button:last-child {
    margin-right: 0;
  }
`;

const ChildCenterContainer = styled(ChildContainer)`
  flex: 1;
  padding-left: ${gutters.spacing(4, -2)}px;
  padding-right: ${gutters.spacing(1, 3)}px;
`;

const StyledBackButton = styled(BackButton)`
  padding-right: 0;
`;

export const Header = ({
  onGoBack,
  centerContent,
  rightActionButton,
  backButtonTitle,
  scrolledTop,
  goBackPath,
}) => {
  return (
    <Container scrolledTop={scrolledTop}>
      <ChildContainer>
        <StyledBackButton
          to={goBackPath || window.location.pathname}
          variant="text"
          color="blue"
          onClick={onGoBack}
        >
          {backButtonTitle}
        </StyledBackButton>
      </ChildContainer>
      <ChildCenterContainer>{centerContent}</ChildCenterContainer>
      <ChildContainer>{rightActionButton}</ChildContainer>
    </Container>
  );
};
