import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { sleep } from 'utility/snippets';

const useDelayedMutation = (func) => {
  const queryClient = useQueryClient();

  const mutate = useCallback(
    async (...args) => {
      let isMutating = queryClient.isMutating();
      let iterations = 0;

      while (isMutating > 0 && iterations < 20) {
        iterations += 1;
        await sleep(2 ** iterations * 20);
        isMutating = queryClient.isMutating();
      }

      if (!isMutating) {
        func(...args);
      }
    },
    [queryClient, func]
  );

  return mutate;
};

export default useDelayedMutation;
