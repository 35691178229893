import { useCallback, useRef, useState } from 'react';
import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import BasicModal from 'components/Modals/presets/BasicModal';
import { ErrorCard, Notice, Underline } from '../styles';
import { useTranslation } from 'react-i18next';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { Overlay } from 'react-bootstrap';
import { useFlashTransition } from 'hooks/useFlashState';
import { decideErrorMessage } from '../helpers';
import { modalConstrainPopperConfig } from 'components/Inputs/Validation';

export default function ReplaceDynamicTagsModal({
  tag,
  tagLabel,
  tagLabelShort,
  field,
  model,
  serviceToUse,
  onConfirm,
  onHide,
  ...props
}) {
  const { t } = useTranslation();
  const [tagOption, setTagOption] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, showError, flashError] = useFlashTransition();

  const selectRef = useRef(null);

  const searchTags = useCallback(
    ({ search = '', page = 1 }, options) => {
      return serviceToUse.getDynamicTagsOptions(
        field.id,
        model.id,
        page,
        search,
        { ...options, params: { include_entity_count: true } }
      );
    },
    [serviceToUse, field.id, model.id]
  );

  const [typeaheadProps, typeaheadState] = useSelectTypeaheadWithScroll({
    entity: Entities.DynamicTags,
    fetch: searchTags,
    alwaysOpen: false,
    fieldId: field.id,
    selectRef,
    shouldResetOptions: false,
  });
  return (
    <BasicModal
      heading={t('Replace {{tagLabel}}', { tagLabel: tagLabel || t('Tag') })}
      buttonText={t('Replace')}
      disabled={submitting || !tagOption}
      onConfirm={async () => {
        try {
          setSubmitting(true);
          await onConfirm(tag.id, tagOption.value);
          onHide();
        } catch (err) {
          flashError(decideErrorMessage(err, t));
        } finally {
          setSubmitting(false);
        }
      }}
      onHide={onHide}
      {...props}
    >
      <Notice>
        <KizenTypography as="span">
          {t(
            'All {{objectName}} with "{{tagName}}" will now have that {{tagLabel}} removed and replaced with the chosen {{tagLabel}} below.',
            {
              objectName: model.objectName || t('Submissions'),
              tagName: tag.name,
              tagLabel: tagLabelShort || t('Tag'),
            }
          )}{' '}
          &quot;{tag.name}&quot;{' '}
          <Underline>{t('will not be deleted')}</Underline>.{' '}
          {t(
            'To delete it, click delete next to the {{tagLabel}}. For assurance, automations triggered by the new {{tagLabel}} will not be triggered.',
            { tagLabel: tagLabelShort || t('Tag') }
          )}
        </KizenTypography>
      </Notice>
      <Select
        ref={selectRef}
        label={t('Replacement {{tagLabel}}', {
          tagLabel: tagLabelShort || t('Tag'),
        })}
        placeholder={t('Find Replacement {{tagLabel}}', {
          tagLabel: tagLabelShort || t('Tag'),
        })}
        menuInline
        {...typeaheadProps}
        isLoading={typeaheadState.loading}
        value={tagOption}
        onChange={setTagOption}
      />
      <Overlay
        transition={false}
        target={selectRef.current}
        show={typeof error === 'string'}
        placement="bottom-start"
        popperConfig={modalConstrainPopperConfig}
      >
        <ErrorCard light show={showError} duration="300ms">
          <KizenTypography>{error}</KizenTypography>
        </ErrorCard>
      </Overlay>
    </BasicModal>
  );
}
