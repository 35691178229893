import TriggerCard from 'pages/AutomationEngine/Engine/Steps/TriggerCard';
import DraggableStepCard from 'pages/AutomationEngine/Engine/Steps/DraggableStepCard';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  return t(`Stage Update from '{{fromStage}}' to '{{toStage}}'`, {
    fromStage: step.details?.fromStage?.label,
    toStage: step.details?.toStage?.label,
  });
};

export default function Card({ step, ...others }) {
  return step?.goalType ? (
    <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>
  ) : (
    <TriggerCard {...others}>{content({ step })}</TriggerCard>
  );
}
