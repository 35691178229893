import { PageContentContainer } from 'components/Layout/PageContentWidth';
import { StyledCard } from './styles';
import KizenTypography from 'app/kizentypo';

export const ErrorMessage = ({
  title,
  text,
  button,
  supportEmail,
  code,
  dashboard = false,
}) => {
  return (
    <PageContentContainer className="error-container">
      <StyledCard dashboard={dashboard} code={code}>
        <KizenTypography as="h1" type="hero">
          {title}
        </KizenTypography>
        {text}
        {button}
        {supportEmail}
        {code}
      </StyledCard>
    </PageContentContainer>
  );
};
