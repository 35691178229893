import { forwardRef } from 'react';
import { components } from 'react-select';
import { LoadingMessageWrapper } from '../styles';

const LoadingMessage = forwardRef(({ children, ...others }, ref) => {
  return (
    <components.LoadingMessage {...others} ref={ref}>
      <LoadingMessageWrapper>{children}</LoadingMessageWrapper>
    </components.LoadingMessage>
  );
});

export default LoadingMessage;
