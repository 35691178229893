import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from '../../../app/colors';
import { gutters } from '../../../app/spacing';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import BaseInput from './BaseInput';
import BaseInputLayout from './BaseInputLayout';
import Validation, { useValidation } from '../Validation';
import { applyRef, validateProp } from '../props';

export const UnderlineInputLayout = styled(BaseInputLayout, {
  shouldForwardProp: (prop) => !['disabled', 'as'].includes(prop),
})`
  height: 29px;
  padding-right: ${gutters.spacing(2)}px;
  border-left: none;
  border-right: none;
  border-top: none;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${grayScale.white};
      border-color: transparent;
      &:hover {
        border-color: transparent;
      }
    `}
`;

const Input = styled(BaseInput, {
  shouldForwardProp: (prop) => !['shrink', 'as'].includes(prop),
})`
  &::placeholder,
  &::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }
  ${({ shrink }) =>
    shrink &&
    css`
      &::placeholder,
      &::-webkit-input-placeholder {
        opacity: 1;
      }
    `}
`;

const UnderlineTextInput = React.forwardRef((props, ref) => {
  // props was spread on MaybeInputControl previously, but that causes the event handlers to fire twice
  const { onBlur, onChange, onFocus, onKeyPress, ...inputControlProps } = props;
  const {
    value,
    error,
    disabled,
    startAdornment,
    endAdornment,
    label,
    placeholder,
    shrink: shrinkProp,
    className,
    Input: InputProp,
    inModal,
    errorPlacement,
    style, // pull out style as we don't want it passed via others into the BaseInput
    ...others
  } = props;
  const validationRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(validationRef, el);
      applyRef(ref, el);
    },
    [ref]
  );
  const [focused, setFocused] = useState(false);
  const [validation, validationProps] = useValidation(validationRef, props);
  const shrink =
    typeof shrinkProp === 'boolean'
      ? shrinkProp
      : Boolean(
          value ||
            focused ||
            disabled ||
            startAdornment ||
            endAdornment ||
            !label
        );
  return (
    <MaybeInputControl
      forInput
      variant="underline"
      {...inputControlProps}
      shrink={!!shrink}
    >
      <UnderlineInputLayout
        ref={mergeRef}
        className={!hasInputControl(props) && className}
        disabled={disabled}
        error={error || validation.error}
        focused={focused}
      >
        {startAdornment &&
          React.cloneElement(startAdornment, {
            variant: 'underline',
            start: true,
          })}
        <Input
          as={InputProp}
          value={disabled ? value || '—' : value}
          shrink={shrink}
          disabled={disabled}
          data-placeholder={placeholder}
          // The space allows placeholder fade-in to happen
          placeholder={shrink ? placeholder : ' '}
          {...others} // spread others here since the handlers are destructed separately for the inputControlProps
          onChange={(ev) => {
            if (onChange) onChange(ev.target.value, ev);
          }}
          onFocus={(ev) => {
            setFocused(true);
            if (onFocus) onFocus(ev);
          }}
          onBlur={(ev) => {
            setFocused(false);
            if (onBlur) onBlur(ev);
            if (validation.onBlur) validation.onBlur(ev);
          }}
          // eslint-disable-next-line consistent-return
          onKeyPress={(ev) => {
            if (onKeyPress) onKeyPress(ev);
            if (validation.onKeyPress) return validation.onKeyPress(ev);
          }}
        />
        {endAdornment &&
          !disabled &&
          React.cloneElement(endAdornment, {
            variant: 'underline',
            end: true,
          })}
      </UnderlineInputLayout>
      <Validation
        inModal={inModal}
        errorPlacement={errorPlacement}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

UnderlineTextInput.displayName = 'UnderlineTextInput';

UnderlineTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  startAdornment: PropTypes.element,
  endAdornment: PropTypes.element,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  shrink: PropTypes.bool,
  Input: PropTypes.elementType,
  validate: validateProp, // Consumed by useValidate()
  inModal: PropTypes.bool,
  errorPlacement: PropTypes.string,
};

UnderlineTextInput.defaultProps = {
  value: undefined,
  onChange: null,
  error: null,
  disabled: null,
  startAdornment: null,
  endAdornment: null,
  label: null,
  placeholder: null,
  onFocus: null,
  onBlur: null,
  onKeyPress: null,
  shrink: null,
  Input: undefined,
  validate: null,
  inModal: null,
  errorPlacement: undefined,
};

export default UnderlineTextInput;
