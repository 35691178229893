import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway, useToggle } from 'react-use';
import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import AddButton from 'components/Button/AddButton';
import TextInput from 'components/Inputs/TextInput';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { SuggestionList } from 'components/WYSIWYG/components/SuggestionList';
import { useEditor } from 'components/WYSIWYG';
import { colorsPrimary } from 'app/colors';
import { insertMergeField } from 'components/WYSIWYG/utils/commands';
import {
  filterMergeField,
  useMergeFields,
} from 'components/WYSIWYG/use-merge-fields';
import { useGridLikeFlexChildProps } from 'components/StandaloneToolBar/components/GridLikeFlex';
import { TextEditorToolbarSection } from 'components/StandaloneToolBar/styles';

const InsertMergeFieldButton = styled(AddButton)`
  width: 154px;
  padding: 0;
  margin: 0 15px;
  height: unset;
  line-height: unset;
  cursor: pointer;
`;

const ToolbarSuggestionContainer = styled.div`
  position: relative;
`;

const ToolbarSuggestionList = styled.div`
  position: absolute;
  top: 30px;
  left: 5px;
  z-index: ${layers.content(1)};
  width: 200px;
`;

const StyledTextInput = styled(TextInput)`
  border: 1px solid ${colorsPrimary.blue.dark};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

const StyledSuggestionList = styled(SuggestionList)`
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;

const SuggestionWithInput = forwardRef(
  ({ options, onChange, onClose, isLoading = false }, ref) => {
    const suggestionListRef = useRef();
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const items = useMemo(() => {
      return options
        .map(({ label, items, key }) => {
          return Array.isArray(items)
            ? {
                label,
                key,
                items: search ? items.filter(filterMergeField(search)) : items,
              }
            : { label, items: [], key };
        })
        .filter(({ items }) => items.length);
    }, [options, search]);

    const inputRef = useCallback((node) => {
      node?.querySelector('input')?.focus();
    }, []);

    useEffect(() => {
      const listener = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      window.addEventListener('keydown', listener);
      return () => window.removeEventListener('keydown', listener);
    }, [onClose]);

    return (
      <ToolbarSuggestionList
        ref={ref}
        onKeyDown={(event) => suggestionListRef.current?.onKeyDown({ event })}
      >
        <StyledTextInput
          inModal
          ref={inputRef}
          value={search}
          onChange={setSearch}
          placeholder={t('Find Field')}
          endAdornment={<IconAdornment icon="search" />}
        />
        <StyledSuggestionList
          ref={suggestionListRef}
          items={items}
          onSelect={onChange}
          isLoading={isLoading}
          topButton
        />
      </ToolbarSuggestionList>
    );
  }
);

const ToolbarSuggestion = ({ options, onChange, isLoading = false }) => {
  const ref = useRef();
  const closedViaMouseDown = useRef(false);
  const { t } = useTranslation();
  const [show, toggleShow] = useToggle();

  const handleToggle = useCallback(() => {
    if (!closedViaMouseDown.current) {
      toggleShow();
    }
    closedViaMouseDown.current = false;
  }, [toggleShow]);

  const handleClickAway = useCallback(() => {
    closedViaMouseDown.current = true;
    toggleShow();
  }, [toggleShow]);

  // using 'mousedown' instead of 'click' to handle the case when the next immediate action is opening a modal
  useClickAway(ref, handleClickAway, ['mousedown']);

  const handleSelect = (value) => {
    toggleShow();
    onChange(value);
  };

  return (
    <ToolbarSuggestionContainer>
      <InsertMergeFieldButton
        as="span"
        color="blue"
        className="insert-btn"
        onClick={handleToggle}
      >
        {t('Insert Merge Field')}
      </InsertMergeFieldButton>
      {show && (
        <SuggestionWithInput
          ref={ref}
          options={options}
          isLoading={isLoading}
          onChange={handleSelect}
          onClose={toggleShow}
        />
      )}
    </ToolbarSuggestionContainer>
  );
};

export const InsertMergeFieldToolbarSection = ({
  mergeFields,
  appendMergeFieldExtraSpace,
}) => {
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  const { data, isLoading } = useMergeFields({
    listHeaders: true,
    mergeFields,
  });
  if (!editor) return null;
  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <ToolbarSuggestion
        options={data}
        isLoading={isLoading}
        onChange={(mergeFieldData) => {
          editor
            .chain()
            .focus()
            .command(
              insertMergeField(mergeFieldData, appendMergeFieldExtraSpace)
            )
            .run();
        }}
      />
    </TextEditorToolbarSection>
  );
};
