import { useRef, useState, useEffect } from 'react';
import KizenTypography from 'app/kizentypo';
import { useTooltip } from 'components/Kizen/Tooltip';
import { collapseNumber, percentageFormatter } from 'utility/numbers';
import TimelineDividerDual from './images/timeline-divider-dual-line.svg?react';
import TimelineDividerSingle from './images/timeline-divider-single-line.svg?react';
import {
  AggregateGrid,
  Block,
  TopBlockStart,
  TopBlockCenter,
  TopBlockEnd,
  BottomBlockStart,
  BottomBlockCenter,
  BottomBlockEnd,
  LeftAligned,
  RightAligned,
} from './styles';

const EmailInteractionStatsAggregate = ({
  stages,
  smartSendStages,
  tooltips,
}) => {
  const [isMinSize, setIsMinSize] = useState(false);
  const leftRefs = useRef([]);
  const rightRefs = useRef([]);

  useEffect(() => {
    // calculate overflow from left aligned to right aligned containers.
    // We use 11px to calculate overflow is due to gap between left and right being 10px
    const hasOverflow = leftRefs.current.some(
      (left, index) =>
        left?.getBoundingClientRect().right + 11 >
        rightRefs.current[index]?.getBoundingClientRect().left
    );
    setIsMinSize(hasOverflow);
  }, []);

  const showSmartSendStats = smartSendStages?.length > 0;

  const [firstTooltipProps, firstTooltip] = useTooltip({
    label: tooltips[0],
  });

  const [lastTooltipProps, lastTooltip] = useTooltip({
    label: tooltips[1],
  });

  return (
    <AggregateGrid>
      <Block>
        <TopBlockStart>
          <LeftAligned
            ref={(el) => (leftRefs.current[0] = el)}
            minSize={isMinSize}
          >
            <KizenTypography type="header">
              {collapseNumber(stages[0].value)}
            </KizenTypography>
            <KizenTypography textTransform="uppercase">
              {stages[0].label}
            </KizenTypography>
          </LeftAligned>
          <KizenTypography
            ref={(el) => (rightRefs.current[0] = el)}
            {...firstTooltipProps}
            size="small"
            fullHeight={smartSendStages?.length === 0}
          >
            {percentageFormatter.format(stages[0].percentage)}
          </KizenTypography>
          {firstTooltip}
        </TopBlockStart>
        {showSmartSendStats ? (
          <BottomBlockStart>
            {smartSendStages[0].value || smartSendStages[0].value === 0 ? (
              <KizenTypography size="small">
                {collapseNumber(smartSendStages[0].value)}
              </KizenTypography>
            ) : (
              <div />
            )}
            <KizenTypography size="small" truncate>
              {smartSendStages[0].label}
            </KizenTypography>
            <KizenTypography size="small">
              {percentageFormatter.format(smartSendStages[0].percentage)}
            </KizenTypography>
          </BottomBlockStart>
        ) : null}
      </Block>
      {showSmartSendStats ? <TimelineDividerDual /> : <TimelineDividerSingle />}
      <Block>
        <TopBlockCenter>
          <LeftAligned
            ref={(el) => (leftRefs.current[1] = el)}
            minSize={isMinSize}
          >
            <KizenTypography type="header">
              {collapseNumber(stages[1].value)}
            </KizenTypography>
            <KizenTypography textTransform="uppercase">
              {stages[1].label}
            </KizenTypography>
          </LeftAligned>
          <KizenTypography
            ref={(el) => (rightRefs.current[1] = el)}
            {...lastTooltipProps}
            fullHeight={smartSendStages?.length === 0}
            size="small"
          >
            {percentageFormatter.format(stages[1].percentage)}
          </KizenTypography>
          {lastTooltip}
        </TopBlockCenter>
        {showSmartSendStats ? (
          <BottomBlockCenter>
            <KizenTypography size="small">
              {percentageFormatter.format(smartSendStages[1].percentage)}
            </KizenTypography>
          </BottomBlockCenter>
        ) : null}
      </Block>
      {showSmartSendStats ? <TimelineDividerDual /> : <TimelineDividerSingle />}
      <Block>
        <TopBlockEnd>
          <LeftAligned minSize={isMinSize}>
            <KizenTypography type="header">
              {collapseNumber(stages[2].value)}
            </KizenTypography>
            <KizenTypography textTransform="uppercase">
              {stages[2].label}
            </KizenTypography>
          </LeftAligned>
          <RightAligned />
        </TopBlockEnd>
        {showSmartSendStats ? <BottomBlockEnd /> : null}
      </Block>
    </AggregateGrid>
  );
};

export default EmailInteractionStatsAggregate;
