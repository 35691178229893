import { Fragment, forwardRef, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Overlay from 'react-bootstrap/Overlay';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import {
  FilterSetData,
  useFilterSets,
} from '@kizen/filters/hooks/use-filter-sets';
import { FilterSet } from '@kizen/filters/filter-sets';
import { KizenTypography } from '__app/typography';
import { useTruncationTooltip } from '__components/Kizen/Tooltip';
import { validate as validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import { useFlashTransition } from 'hooks/useFlashState';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import {
  setNumberOfFilters,
  clearGroupError,
} from 'store/automationsPage/automations.redux';
import {
  AddFilterButton,
  AddFilterSetButton,
  ErrorsReturned,
  AutomationFilterTypeSelector,
  Filter,
  FilterOperatorText,
  FilterRow,
  FilterSetContainer,
  FilterSetOperationDropdown,
  FilterTypeSelectorContainer,
  useFilterErrors,
  useApiFilterErrors,
  useMappedReturnErrors,
} from 'ts-components/filters';

import {
  FilterMenu,
  FilterMenuHeader,
  FilterControlButton,
  FilterNameInput,
  ErrorCard,
  FilterControls2,
  FilterNameWrapper,
} from 'pages/Common/components/filterDropdownComponents';
import { getOriginalError } from '__services/AxiosService';

type AutomationFilterDropdownProps = {
  and: boolean;
  className: string;
  errorsReturned: ErrorsReturned;
  filterData: FilterSet | FilterSet[];
  filterName: string;
  groupId?: string | null;
  numberOfFilters: number;
  applyFiltersAction(payload: any): void;
  updateFilterCount(count: number): void;
  saveGroupAction(payload: any): void;
  deleteGroupAction(): void;
  setFilterName(name: string): void;
  clearFilter(): void;
};

const countFilters = (acc: number, [, { filters }]: FilterSetData) => {
  // filters are counted not after adding a row but once the first dropdown (type) is selected
  return acc + filters.filter(({ type }) => type !== null).length;
};

export const AutomationFilterDropdown = forwardRef<
  HTMLDivElement,
  AutomationFilterDropdownProps
>(
  (
    {
      filterData,
      and,
      className,
      applyFiltersAction,
      updateFilterCount,
      saveGroupAction,
      deleteGroupAction,
      setFilterName,
      filterName,
      groupId,
      clearFilter,
      numberOfFilters,
      errorsReturned,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const filterNameRef = useRef<HTMLInputElement>(null);
    const metadata = useSelector(selectFilterMetadataDefinition);
    const [operation, setOperation] = useState<'and' | 'or'>(
      and ? 'and' : 'or'
    );
    const [isDeleteAction, setDeleteAction] = useState(false);
    const [groupError, showGroupError, flashGroupError] = useFlashTransition();
    const [nameTooltipProps, nameTooltip] = useTruncationTooltip({
      label: filterName,
    });
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const dispatchedGroupError = useSelector(
      (s: any) => s.automationPage.groupErrors
    );

    useEffect(() => {
      if (dispatchedGroupError) {
        const originalError = getOriginalError(dispatchedGroupError);
        flashGroupError(originalError?.name?.[0]);
        clearGroupError();
      }
    }, [dispatchedGroupError]);

    const [
      filterSets,
      {
        addFilter,
        addFilterSet,
        build,
        createFilter,
        validate,
        removeFilter,
        reset,
        setFilterSetOperation,
      },
    ] = useFilterSets(
      metadata,
      [],
      isValidPhoneNumber,
      validateEmail.withDomain,
      filterData
    );
    const [filterErrors, setFilterErrors] = useFilterErrors();
    const errorsDictionary = useMappedReturnErrors(errorsReturned, filterSets);
    const [apiErrors, setApiErrors] = useApiFilterErrors(
      errorsDictionary,
      errorsReturned?.timestamp
    );
    const multipleSets = filterSets.length > 1;
    const confirmMessage = isDeleteAction
      ? t('This will permanently delete the Filter Group.')
      : t('Your filters are unsaved, this will clear your work.');

    const handleApplyFilter = () => {
      const { errors, hasErrors } = validate();
      setApiErrors({});
      if (hasErrors) {
        setFilterErrors(errors);
      } else {
        applyFiltersAction(build(operation === 'and'));
      }
    };

    const handleSaveFilterGroup = () => {
      if (!filterName) {
        flashGroupError(t('Please type a group name before saving.'));
      } else {
        const { errors, hasErrors } = validate();
        setApiErrors({});
        if (hasErrors) {
          setFilterErrors(errors);
        } else {
          saveGroupAction({
            config: build(operation === 'and'),
            name: filterName,
          });
        }
      }
    };

    const handleClearFilter = () => {
      reset();
      clearFilter();
      setApiErrors({});
    };

    const incrementFilterCount = () => {
      dispatch(setNumberOfFilters(filterSets.reduce(countFilters, 1)));
    };

    const decrementFilterCount = () => {
      dispatch(setNumberOfFilters(filterSets.reduce(countFilters, -1)));
    };
    return (
      <>
        <div ref={ref} className={className}>
          <FilterMenu {...others}>
            <FilterMenuHeader sticky>
              <FilterNameWrapper>
                <FilterNameInput
                  ref={filterNameRef}
                  placeholder={t('Enter Filter Group Name')}
                  value={filterName}
                  onChange={setFilterName}
                  error={!!groupError}
                  debounce
                  {...nameTooltipProps}
                />
              </FilterNameWrapper>
              {filterName ? nameTooltip : null}
              {/* @ts-expect-error - Overlay prop types are incorrect */}
              <Overlay
                transition={false}
                target={filterNameRef.current!}
                show={groupError}
                placement="bottom-start"
              >
                <ErrorCard show={showGroupError} duration="300ms">
                  <KizenTypography>{groupError}</KizenTypography>
                </ErrorCard>
              </Overlay>
              <FilterControls2>
                <FilterControlButton
                  variant="outline"
                  color="blue"
                  onClick={handleApplyFilter}
                  data-qa="apply-filter"
                >
                  {t('Apply Filter')}
                </FilterControlButton>
                <FilterControlButton
                  variant="outline"
                  color="green"
                  onClick={handleSaveFilterGroup}
                  data-qa="save-group"
                >
                  {t('Save Group')}
                </FilterControlButton>
                {groupId ? (
                  <FilterControlButton
                    variant="outline"
                    color="red"
                    onClick={() => {
                      setDeleteAction(true);
                      setShowConfirmationModal(true);
                    }}
                  >
                    {t('Delete Group')}
                  </FilterControlButton>
                ) : null}
                <FilterControlButton
                  variant="outline"
                  color="red"
                  onClick={() => {
                    setDeleteAction(false);
                    setShowConfirmationModal(true);
                  }}
                  data-qa="clear-filter"
                >
                  {t('Clear All Filters')}
                </FilterControlButton>
              </FilterControls2>
            </FilterMenuHeader>
            {filterSets.map(([setId, { and, filters }], setIdx) => {
              const multipleFilters = filters.length > 1;
              return (
                <Fragment key={setId}>
                  {setIdx > 0 && (
                    <FilterSetOperationDropdown
                      operation={operation}
                      onChange={setOperation}
                    />
                  )}
                  <FilterSetContainer
                    value={and ? 'all' : 'any'}
                    showBorder={multipleFilters || multipleSets}
                    onChange={(value) =>
                      setFilterSetOperation(setId, value === 'all')
                    }
                  >
                    {filters.map(({ type, steps, ops, id }, idx, arr) => {
                      const isLastFilter = idx === arr.length - 1;
                      const filterTypeChosen = type !== null;
                      const errors = filterErrors[setId]?.[idx] ?? {};
                      return (
                        <FilterRow key={id} data-qa="filter-row">
                          <FilterTypeSelectorContainer
                            showDeleteIcon={multipleFilters || multipleSets}
                            onDelete={() => {
                              decrementFilterCount();
                              removeFilter(setId, idx);
                            }}
                          >
                            <AutomationFilterTypeSelector
                              value={type}
                              error={
                                errors['filter-type'] ||
                                (apiErrors[setId]?.[idx] &&
                                  t('This filter is invalid.'))
                              }
                              onChange={({ value }: any) => {
                                if (steps.length === 0) {
                                  incrementFilterCount();
                                }
                                createFilter(value, setId, idx);
                                setApiErrors({});
                              }}
                            />
                          </FilterTypeSelectorContainer>
                          <Filter
                            filterType={type}
                            steps={steps}
                            next={ops.next}
                            set={ops.set}
                            errors={errors}
                            onChange={() => {
                              setApiErrors({});
                              ops.update();
                            }}
                          />
                          {arr.length > 1 && !isLastFilter && (
                            <FilterOperatorText and={and} />
                          )}
                          {filterTypeChosen && isLastFilter && (
                            <AddFilterButton onClick={() => addFilter(setId)} />
                          )}
                        </FilterRow>
                      );
                    })}
                  </FilterSetContainer>
                  {filterSets.length === 1 && multipleFilters && (
                    <AddFilterSetButton
                      onClick={(value) => {
                        addFilterSet();
                        setOperation(value);
                      }}
                    />
                  )}
                </Fragment>
              );
            })}
            {multipleSets && (
              <AddFilterSetButton disableSelect onClick={addFilterSet} />
            )}
          </FilterMenu>
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            data-qa="clear-confirm-modal"
            show={showConfirmationModal}
            buttonText={
              isDeleteAction ? t('Confirm Delete') : t('Confirm Clear')
            }
            heading={
              isDeleteAction
                ? t('Please Confirm Deletion')
                : t('Please Confirm')
            }
            onConfirm={() => {
              if (isDeleteAction) {
                deleteGroupAction();
              } else {
                handleClearFilter();
              }
              setShowConfirmationModal(false);
            }}
            onHide={() => {
              setDeleteAction(false);
              setShowConfirmationModal(false);
            }}
          >
            {confirmMessage}
          </ConfirmationModal>
        )}
      </>
    );
  }
);
