import React, { useEffect, useState } from 'react';

import Loader from 'components/Kizen/Loader';
import Button from 'components/Button';
import { Redirect, useHistory } from 'react-router-dom';
import { KizenTypography } from 'app/typography';
import paths from 'routes/paths';
import { getDataQAForInput } from 'components/Inputs/helpers';
import AuthenticationService from 'services/AuthenticationService';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useTranslation } from 'react-i18next';
import PhoneNumberTextInput from 'components/Inputs/TextInput/presets/PhoneNumber';
import TextInput from 'components/Inputs/TextInput';
import Cols from 'components/Layout/Cols';
import {
  ButtonContainer,
  Links,
  StyledCheckboxSingle,
  StyledKizenTypography,
  Terms,
  VerifyAccountWrapper,
} from './styled';
import AuthForm from 'components/Kizen/AuthForm';
import useFormValidation from 'hooks/useFormValidation';
import { deferExecution } from 'utility/defer';

const VerifyAccountPage = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  const history = useHistory();
  const search = new URLSearchParams(history.location.search);
  const token = search.get('token');

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    phone: '',
    term: true,
  });

  const {
    validateFormState,
    validationProps,
    handleInputChange,
    setValidationState,
    firstFieldWithErrorRef,
  } = useFormValidation({
    formState: values,
    setFormState: setValues,
  });

  const onClickSubmit = async (ev) => {
    ev.preventDefault();
    if (!validateFormState(t)) {
      return;
    }
    try {
      setLoading(true);
      await AuthenticationService.verifyaccount(
        token,
        values.password,
        values.firstName,
        values.lastName,
        values.phone
      );
      setLoading(false);
      deferExecution(() => {
        history.push('/login');
      });
    } catch (err) {
      setLoading(false);
      const defaultMessage = (
        <>
          {t(
            'An unknown error has occurred, please try again. If you continue to experience issues setting up your account, please contact our team at'
          )}{' '}
          <KizenTypography
            type="link-mail"
            size="micro"
            weight="bold"
            href="mailto:support@kizen.com"
          >
            support@kizen.com
          </KizenTypography>{' '}
          {t('for assistance.')}
        </>
      );
      const { name, payload, status } = err;
      if (name !== 'AuthenticationServiceError' || !payload) {
        return setErrorMessage(defaultMessage);
      }

      const { errors } = payload;

      // could be from v1 or v2, both express the issue the same
      if (errors.password) {
        firstFieldWithErrorRef.current = 'password';
        setValidationState({
          password: errors.password[0],
        });
        return;
      }

      // invalid reset token (invalid format or already used)
      if (status === 400 && errors.token) {
        return setErrorMessage(
          <>
            {t(
              "Your account has already been created. If you can't remember your password, please"
            )}{' '}
            <KizenTypography type="link" size="micro" weight="bold" to="/reset">
              {t('reset your password')}
            </KizenTypography>{' '}
            {t('and follow the link in your email.')}
          </>
        );
      }

      // Any invalid payload error e.g. token missing
      if (status === 400) {
        return setErrorMessage(
          t('Valid password and user token required to verify your account')
        );
      }

      return setErrorMessage(defaultMessage);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const user =
        await AuthenticationService.fetchCurrentUserUsingToken(token);
      // warning if the user is logged in then the data is in user.profile that is currently incorrect
      setValues((prev) => ({
        ...prev,
        firstName: user.first_name,
        lastName: user.last_name,
        username: user.email,
        phone: user.phone,
      }));
    };
    fetchData();
  }, [token]);

  if (!token) {
    // User can't set their account's password (only pre-req for verfying)
    // if they don't supply an at least potentially valid token (which the v1
    // API uses to identify users without requesting personally identifying info)
    return <Redirect to={paths.get('login')} />;
  }

  return (
    <AuthForm>
      <VerifyAccountWrapper className="verify-account">
        <Loader className="Authform-loader" loading={loading}>
          <form action="">
            <StyledKizenTypography type="header" className="Authform-header">
              {t('Verify Your Account')}
            </StyledKizenTypography>
            <Cols columns={2}>
              <TextInput
                placeholder={t('Enter First Name')}
                label={t('First Name')}
                value={values.firstName}
                onChange={(value) => handleInputChange('firstName', value)}
                required
                {...getDataQAForInput('first-name')}
                {...validationProps('firstName')}
              />
              <TextInput
                placeholder={t('Enter Last Name')}
                label={t('Last Name')}
                value={values.lastName}
                onChange={(value) => handleInputChange('lastName', value)}
                required
                {...getDataQAForInput('last-name')}
                {...validationProps('lastName')}
              />
            </Cols>
            <TextInput
              placeholder={t('Username')}
              label={t('Username')}
              value={values.username}
              onChange={() => {}}
              required
              disabled
            />
            <PhoneNumberTextInput
              label={t('Phone Number')}
              placeholderNumber={t('Enter Phone Number')}
              value={values.phone}
              onChange={(value, ev, errors) => {
                handleInputChange('phone', value, errors);
              }}
              {...validationProps('phone')}
            />
            <TextInput
              value={values.password}
              onChange={(value) => handleInputChange('password', value)}
              required
              label={t('Password')}
              placeholder={t('Enter Password')}
              type="PASSWORD"
              {...getDataQAForInput('password', 'password')}
              {...validationProps('password')}
            />
            <Terms>
              <StyledCheckboxSingle
                onChange={(checked) =>
                  handleInputChange('term', checked ? checked : '')
                }
                checked={values.term}
                required
                {...validationProps('term')}
              />
              <div>
                <KizenTypography size={'buttonLabel'}>
                  {t(
                    'I agree to receive notification messaging from Kizen at the phone number provided above. I understand I will receive a variable number of messages depending on application configuration. Data rates may apply, reply STOP to opt out. Your manager may be notified if you opt out.'
                  )}
                </KizenTypography>
                <Links>
                  <KizenTypography
                    type="link"
                    size="buttonLabel"
                    target="_blank"
                    to={{ pathname: 'https://kizen.com/privacy-policy' }}
                  >
                    {t('Privacy Policy')}
                  </KizenTypography>
                </Links>
              </div>
            </Terms>
            <ButtonContainer>
              <Button
                className="Authform-button"
                onClick={onClickSubmit}
                disabled={!values.username}
              >
                {t('Submit')}
              </Button>
            </ButtonContainer>
            {errorMessage && (
              <KizenTypography className="Authform-error">
                {errorMessage}
              </KizenTypography>
            )}
          </form>
        </Loader>
      </VerifyAccountWrapper>
    </AuthForm>
  );
};

export default VerifyAccountPage;
