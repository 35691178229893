import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import MultiSelect from 'components/Inputs/MultiSelect';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import useField from 'hooks/useField';
import { useModelQuery } from 'queries/models/custom-objects';
import {
  GroupPill,
  useCanViewCustomObject,
  getGroupLink,
} from 'ts-components/filters/GroupPill';

export const FilterGroupsSelect = ({
  values,
  setValues,
  groupOptions,
  disabled,
  setShowSummary,
  customObjectId,
}) => {
  const { t } = useTranslation();
  const { data: customObject } = useModelQuery(
    customObjectId,
    Boolean(customObjectId)
  );
  const canViewCustomObject = useCanViewCustomObject(customObjectId);
  const [innerValue, setInnerValue] = useField(
    () =>
      values.groups.map((group) => ({
        value: group.id,
        label: group.name,
        group,
      })),
    [values.groups]
  );

  const selectRef = useRef(null);

  const onMenuClose = () => {
    setShowSummary(!!innerValue.length);
    if (
      values.groups.length !== innerValue.length ||
      innerValue.some(
        ({ value }) => !values.groups.some((group) => group.id === value)
      )
    ) {
      setValues({
        ...values,
        groups: innerValue.map((opt) => opt.group),
      });
    }
  };

  const onChange = (value) => {
    setShowSummary(false);
    if (selectRef.current.state.menuIsOpen) {
      setInnerValue(value);
    } else {
      setValues({
        ...values,
        groups: value.map((opt) => opt.group),
      });
    }
  };

  const PillComponent = useMemo(() => {
    if (canViewCustomObject && customObject) {
      return ({ value, ...rest }) => {
        const href = getGroupLink(value, customObject.fetchUrl, customObjectId);
        return <GroupPill {...rest} value={value.id} href={href} />;
      };
    }
  }, [canViewCustomObject, customObjectId, customObject]);

  return (
    <MultiSelect
      ref={selectRef}
      label={
        values.type === 'in_group'
          ? t('Is In Group(s)')
          : t('Is Not In Group(s)')
      }
      placeholder={t('Choose Group(s)')}
      PillComponent={PillComponent}
      menuInline
      options={groupOptions}
      value={innerValue.map(({ value }) => value)}
      onChange={onChange}
      menuLeftButton={<ClearSelectButton />}
      menuRightButton={<ApplySelectButton />}
      onMenuClose={onMenuClose}
      disabled={disabled}
    />
  );
};
