import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { breakpoints } from 'app/spacing';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useWindowSize } from 'react-use';

export const useModalConfiguration = (
  allFiles = EMPTY_ARRAY,
  selectedFileId = null
) => {
  const [search, setSearch] = useState('');
  const [selectedFile, setSelectedFile] = useState(
    (selectedFileId
      ? allFiles.find((f) => f.id === selectedFileId)
      : allFiles[0]) ?? null
  );

  const files = useMemo(() => {
    if (!search) return allFiles;
    return allFiles.filter(({ name }) =>
      name.toLowerCase().includes(search.toLowerCase())
    );
  }, [allFiles, search]);

  const filePreview = useMemo(() => {
    return files.find(({ id }) => id === selectedFile?.id) ?? EMPTY_OBJECT;
  }, [files, selectedFile?.id]);

  const { height, width } = useWindowSize();
  const [showPreviewBlock, setShowPreviewBlock] = useState(true);
  const columnRef = useRef(null);
  useLayoutEffect(() => {
    setShowPreviewBlock(
      columnRef.current &&
        columnRef.current.offsetHeight === columnRef.current.scrollHeight
    );
  }, [height, width]);

  const isMobile = width <= breakpoints.sm;

  return {
    files,
    filePreview,
    selectedFile,
    setSearch,
    setSelectedFile,
    showPreviewBlock,
    setShowPreviewBlock,
    windowHeight: height,
    isMobile,
    columnRef,
  };
};
