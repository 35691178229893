import {
  useCallback,
  useEffect,
  useState,
  useReducer,
  useRef,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useToast, toastVariant } from 'components/ToastProvider';
import ScheduleActivitySettings from './sections/settings';
import ScheduleActivityObjects from './sections/relatedObjects';
import ScheduleActivityNotifications from './sections/notifications';
import {
  FORMDATA_FIELDS,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  FORM_ACTIONS,
  ASSIGNMENT_TYPES,
} from './config';
import { useActivityAssociation } from '../completeActivity/helpers';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { getBusinessTimeZone } from 'store/authentication/selectors';
import { useEntityPredefinedOptions } from 'ts-components/hooks/activities/useEntityPredefinedOptions';
import { getOriginalError } from 'services/AxiosService';

const {
  ACTIVITY_ID,
  NOTE,
  DATETIME,
  EMPLOYEE,
  ROLE,
  ENTITIES,
  NOTIFICATIONS,
  MENTIONS,
} = FORMDATA_FIELDS;

const activityInit = (scheduledActivity) => ({
  [ACTIVITY_ID]: null,
  [NOTE]: '',
  [DATETIME]: null,
  [EMPLOYEE]: null,
  [ROLE]: null,
  [ENTITIES]: EMPTY_ARRAY,
  [NOTIFICATIONS]: EMPTY_ARRAY,
  [MENTIONS]: EMPTY_ARRAY,
  ...scheduledActivity,
});

export const activityReducer = (state, { type, payload }) => {
  switch (type) {
    case FORM_ACTIONS.update: {
      const { field, value } = payload;
      return { ...state, [field]: value };
    }
    case FORM_ACTIONS.reset: {
      const { scheduledActivity } = payload;
      return activityInit(scheduledActivity);
    }
    case FORM_ACTIONS.setAssignmentType: {
      const { assignmentType, value } = payload;
      if (assignmentType === ASSIGNMENT_TYPES.TEAMMEMBER_ASSIGNMENT)
        return { ...state, [EMPLOYEE]: value, [ROLE]: null };
      else
        return {
          ...state,
          [EMPLOYEE]: null,
          [ROLE]: value,
          [NOTIFICATIONS]: EMPTY_ARRAY,
        };
    }
    default:
      throw new Error('Unknown action');
  }
};

export const STEP_ACTIONS = {
  validate: 'VALIDATE',
};

export const stepValReducer = (state, { type, payload }) => {
  switch (type) {
    case STEP_ACTIONS.validate: {
      const { step, isValid } = payload;
      if (step in state && state[step] === isValid) {
        return state;
      }

      if (!(step in state) && isValid === null) {
        return state;
      }

      const nextStepValidation = {
        ...state,
        [step]: isValid,
      };

      Object.keys(nextStepValidation).forEach(
        (k) => nextStepValidation[k] === null && delete nextStepValidation[k]
      );

      return nextStepValidation;
    }
    default:
      throw new Error('Unknown step validation action');
  }
};

export function useActivityConfiguration(scheduledActivity) {
  const [formData, dispatchFormData] = useReducer(
    activityReducer,
    scheduledActivity,
    activityInit
  );

  const [isDirty, setDirty] = useState(false);

  const updateField = useCallback(
    (f, value, shouldIgnoreDirty) => {
      if (!shouldIgnoreDirty) setDirty(true);
      dispatchFormData({
        type: FORM_ACTIONS.update,
        payload: { field: f, value },
      });
    },
    [dispatchFormData]
  );

  const updateAssignmentType = useCallback(
    (assignmentType, value) => {
      setDirty(true);
      dispatchFormData({
        type: FORM_ACTIONS.setAssignmentType,
        payload: { assignmentType, value },
      });
    },
    [dispatchFormData]
  );

  const resetField = useCallback(() => {
    setDirty(false);
    dispatchFormData({
      type: FORM_ACTIONS.reset,
      payload: { scheduledActivity },
    });
  }, [scheduledActivity, dispatchFormData]);

  const [stepValidation, dispatchStepValidation] = useReducer(
    stepValReducer,
    EMPTY_OBJECT
  );

  const updateValidation = useCallback(
    (step, isValid = null) => {
      dispatchStepValidation({
        type: STEP_ACTIONS.validate,
        payload: { step, isValid },
      });
    },
    [dispatchStepValidation]
  );

  const isComplete = Object.keys(stepValidation).every(
    (step) => !!stepValidation[step] || stepValidation[step] === null
  );

  return {
    formData,
    updateField,
    updateAssignmentType,
    resetField,
    updateValidation,
    isComplete,
    isDirty,
  };
}

const ScheduleActivitytWizard = ({
  onChange,
  data,
  predefinedOptions: existingPredefinedOptions,
  scheduleActivityList,
  currentEntity,
  currentObject,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [activityError, setActivityError] = useState(false);
  const [chosenActivity, setChosenActivity] = useState(null);

  const teamMember = useSelector(
    (s) => s.authentication.teamMember || EMPTY_OBJECT
  );
  const businessTimezone = useSelector(getBusinessTimeZone);

  const scheduledActivity = data.id
    ? {
        id: data.id,
        activityObjectId: data.activityObject?.id,
        employeeId: data.employee?.id || (data.role?.id ? null : teamMember.id),
        dueDatetime: data.dueDatetime,
        note: data.note,
        notifications: data.notifications,
        roleId: data.role?.id,
        associatedEntities: data.associatedEntities,
        mentions: data.mentions
          ? data.mentions.map(({ id }) => id)
          : EMPTY_ARRAY,
      }
    : { employeeId: teamMember.id };

  const {
    formData,
    updateField,
    resetField,
    updateAssignmentType,
    updateValidation,
    isComplete,
    isDirty,
  } = useActivityConfiguration(scheduledActivity);

  const {
    activityObjectId,
    dueDatetime,
    notifications,
    employeeId,
    roleId,
    associatedEntities,
    note,
  } = formData;

  const shownErrorToast = useRef(false);

  const handleLoadError = useCallback(
    (error) => {
      if (shownErrorToast.current) {
        return;
      }
      const orig = getOriginalError(error);
      shownErrorToast.current = true;
      showToast({
        message:
          orig?.message ||
          t(
            'There was an error loading the {{activityName}}. Please fix and try again.',
            {
              activityName: t('Chosen Activity'),
            }
          ),
        variant: toastVariant.FAILURE,
      });
      setActivityError(true);
    },
    [t, showToast]
  );

  const { predefinedOptions, loading: predefinedOptionsLoading } =
    useEntityPredefinedOptions({
      currentEntity,
      currentObject,
      activity: data.id
        ? data
        : { activityObject: { id: chosenActivity?.value } },
      existingPredefinedOptions,
    });

  const {
    assignments,
    dispatchAssignment,
    activityRelatedObjects,
    isFetching,
  } = useActivityAssociation(
    activityObjectId,
    predefinedOptions,
    { retry: false, refetchInterval: false },
    { skipErrorBoundary: true },
    handleLoadError
  );

  useEffect(() => {
    if (data?.associatedEntities?.length && activityRelatedObjects?.length) {
      data.associatedEntities.forEach((entity) => {
        const relObject = activityRelatedObjects.find(
          (act) => act.id === entity.customObjectId
        );
        if (relObject) {
          const { setterProp } = relObject.component;
          const value = {
            label: entity.displayName,
            value: entity.entityId,
          };
          dispatchAssignment({
            ...setterProp,
            value,
          });
        }
      });
    }
  }, [data, activityRelatedObjects, dispatchAssignment]);

  useEffect(() => {
    onChange({ data: formData, isComplete, isDirty });
  }, [formData, isComplete, isDirty, onChange]);

  const hideNotificationsStep = !!(roleId || !employeeId);
  const fieldsData = useMemo(
    () => [
      { id: ACTIVITY_ID },
      { id: ASSIGNMENT_TYPES.TEAMMEMBER_ASSIGNMENT },
      { id: 'assignmentType' },
      { id: DATETIME },
      { id: NOTE },
      ...activityRelatedObjects.map(({ id }) => ({ id })),
    ],
    [activityRelatedObjects]
  );

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    fieldsData,
    {},
    () => true
  );

  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <>
        <ScheduleActivitySettings
          updateField={updateField}
          getKeyListenersProps={getKeyListenersProps}
          resetField={resetField}
          updateAssignmentType={updateAssignmentType}
          updateValidation={updateValidation}
          activityObjectId={activityObjectId}
          dueDatetime={dueDatetime}
          note={note}
          employeeId={employeeId}
          roleId={roleId}
          objectId={currentObject?.id ?? predefinedOptions.objectId}
          activityError={activityError}
          setActivityError={setActivityError}
          businessTimezone={businessTimezone}
          activity={data}
          scheduleActivityList={scheduleActivityList}
          setChosenActivity={setChosenActivity}
        />
        <ScheduleActivityObjects
          dispatchAssignment={dispatchAssignment}
          getKeyListenersProps={getKeyListenersProps}
          assignments={assignments}
          updateField={updateField}
          updateValidation={updateValidation}
          associatedEntities={associatedEntities}
          activityRelatedObjects={activityRelatedObjects}
          loading={isFetching || predefinedOptionsLoading}
          isLastIndex={hideNotificationsStep}
        />
        {hideNotificationsStep ? null : (
          <ScheduleActivityNotifications
            getKeyListenersProps={getKeyListenersProps}
            updateValidation={updateValidation}
            notifications={notifications}
            updateField={updateField}
          />
        )}
      </>
    </KeyBoardContext.Provider>
  );
};

export default ScheduleActivitytWizard;
