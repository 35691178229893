import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Kizen/Icon';
import { grayScale } from 'app/colors';
import {
  Container,
  StyledHeader as Header,
  StyledInfoBox as InfoBox,
  StyledParagraph,
  StyledIconsContainer,
  StyledContactIcon,
  StyledCard as Card,
} from './styles';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';

import KizenTypography from 'app/kizentypo';

const FreeTrialEnded = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));

  return (
    <Container>
      <Card>
        <Header type="header" margin="lg">
          {t('Your Free Trial Has Ended')}
        </Header>
        <InfoBox>
          <StyledParagraph>
            {t(
              'Your 14 day free trial has ended - we hope you’ve been loving Kizen and appreciate the attention to detail we put into every feature. To continue with your account, please contact us via phone or email and we’ll walk you through our product plans to create your perfect Kizen experience.'
            )}
          </StyledParagraph>
        </InfoBox>
        <StyledIconsContainer>
          <StyledContactIcon href="tel:5123875846">
            <Icon icon="phone" color={grayScale.dark} />
            <span>{t('(512) 387-5846')}</span>
          </StyledContactIcon>
          <StyledContactIcon href="mailto:Support@Kizen.com">
            <Icon icon="envelope" color={grayScale.dark} />
            <span>{t('Support@Kizen.com')}</span>
          </StyledContactIcon>
        </StyledIconsContainer>
        <StyledIconsContainer>
          <KizenTypography type="anchor" color="blue" href="/logout">
            {t('Login')}
          </KizenTypography>
        </StyledIconsContainer>
      </Card>
    </Container>
  );
};

export default FreeTrialEnded;
