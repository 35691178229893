import {
  call,
  takeLatest,
  put,
  all,
  select,
  debounce,
} from 'redux-saga/effects';
import CustomObjectsService from 'services/CustomObjectsService';
import TeamMemberService from 'services/TeamMemberService';
import SurveyService from 'services/SurveyService';
import { FORCE_ALL_RECORDS_SIZE, snakeToCamelCaseKeys } from 'services/helpers';
import { DEFAULT_DELAY } from 'utility/config';
import {
  buildPage as buildPageAction,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  getSurveys as getSurveysAction,
  getSurveysSuccess,
  getSurveysFail,
  duplicateSurvey as duplicateSurveyAction,
  duplicateSurveySuccess,
  duplicateSurveyFail,
  deleteSurvey as deleteSurveyAction,
  deleteSurveySuccess,
  deleteSurveyFail,
  setPageConfig as setPageConfigAction,
  setPageConfigSuccess,
  setPageConfigFail,
  generatePageConfig,
  getSurveysStart,
  getSurveysFinish,
} from './surveys.redux';
import { toastVariant } from 'components/ToastProvider';

const PAGE_CONFIG_KEY = 'survey_list';

function* getSurveys(actionData) {
  try {
    yield put(getSurveysStart());
    const payload = actionData && actionData.payload;
    const cancelToken = payload && payload.cancelToken;
    const pageConfig = yield select((s) => s.surveysPage.pageConfig);
    const data = {
      values: {
        search: pageConfig.search,
        ordering: pageConfig.sort,
        page: pageConfig.page,
        size: pageConfig.size,
      },
      options: { cancelToken },
    };

    const [result] = yield all([
      call(SurveyService.getListWithoutFields, data.values, data.options),
      payload.updatePageConfig &&
        call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig),
    ]);

    yield put(
      getSurveysSuccess({
        surveys: snakeToCamelCaseKeys(result.results),
        surveysCount: result.count,
      })
    );
  } catch (error) {
    yield put(getSurveysFail(error && error.message));
  } finally {
    yield put(getSurveysFinish());
  }
}

function* buildPage() {
  try {
    const [pageResponse, modelsData] = yield all([
      call(
        TeamMemberService.getPageConfig,
        PAGE_CONFIG_KEY,
        generatePageConfig()
      ),
      call(CustomObjectsService.getCustomObjectList, {
        size: FORCE_ALL_RECORDS_SIZE,
        ordering: 'object_name',
        customOnly: false,
      }),
    ]);
    yield put(
      buildPageComplete({
        pageResponse,
        models: modelsData.results,
      })
    );
    yield call(getSurveys, { payload: { updatePageConfig: false } });
    yield put(buildPageFinish());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* duplicateSurvey({ payload }) {
  try {
    const data = {
      values: {
        id: payload,
      },
    };

    yield call(SurveyService.duplicate, data.values);

    yield put(duplicateSurveySuccess());
    yield call(getSurveys, { payload: { updatePageConfig: false } });
  } catch (error) {
    yield put(duplicateSurveyFail(error));
  }
}

function* deleteSurvey({ payload }) {
  try {
    const data = {
      values: {
        id: payload,
      },
    };

    yield call(SurveyService.remove, data.values);

    yield put(
      deleteSurveySuccess({
        variant: toastVariant.SUCCESS,
        message: 'The survey has been successfully deleted.',
      })
    );
    yield call(getSurveys, { payload: { updatePageConfig: false } });
  } catch (error) {
    yield put(
      deleteSurveyFail({
        variant: toastVariant.FAILURE,
        message: 'The survey has not been successfully deleted.',
      })
    );
  }
}

function* setPageConfig() {
  try {
    const pageConfig = yield select((s) => s.surveysPage.pageConfig);
    yield call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig);
    yield put(setPageConfigSuccess());
  } catch (error) {
    yield put(setPageConfigFail(error));
  }
}

function* surveysPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield debounce(DEFAULT_DELAY, getSurveysAction.type, getSurveys);
  yield takeLatest(duplicateSurveyAction.type, duplicateSurvey);
  yield takeLatest(deleteSurveyAction.type, deleteSurvey);
  yield takeLatest(setPageConfigAction.type, setPageConfig);
}

export default surveysPage;
