import ButtonGroup from 'components/Inputs/ButtonGroup';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import KizenTypography from 'app/kizentypo';
import { StyledWizardField } from '../../../../shared/styles';
import { gutters } from 'app/spacing';
import { useTranslation } from 'react-i18next';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { AREA_RESPONSES, DISPLAY_VALUE_RESPONSES } from '../../../types';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import useDataConstraint from 'components/Wizards/shared/hooks/useDataConstraint';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';

const MainReportType = ({ mobile, active }) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);

  const { value, area, pipelineDetail, currencySymbol, reportType } = state;

  const displayValueRecords = useMemo(() => {
    return {
      label: t('# Records'),
      value: DISPLAY_VALUE_RESPONSES.RECORDS,
    };
  }, [t]);

  useDefaultValues(
    {
      value: displayValueRecords,
    },
    active &&
      reportType?.value !== REPORT_TYPES.VALUE_OVER_TIME &&
      reportType?.value !== REPORT_TYPES.STAGES_OVER_TIME
  );

  useDataConstraint((internalState) => {
    if (
      active &&
      internalState.pipelineDetail?.pipeline &&
      !internalState.pipelineDetail?.trackEntityValue &&
      value?.value === DISPLAY_VALUE_RESPONSES.VALUE
    ) {
      return {
        key: 'value',
        value: displayValueRecords,
      };
    }
  });

  const valueOptions = useMemo(() => {
    const result = [displayValueRecords];

    if (pipelineDetail?.trackEntityValue) {
      result.push({
        label: `${currencySymbol ?? ''} ${t('Value')}`,
        value: DISPLAY_VALUE_RESPONSES.VALUE,
      });
    }

    return result;
  }, [pipelineDetail, currencySymbol, displayValueRecords, t]);

  // Hide self if not a standard report type section
  if (
    reportType?.value === REPORT_TYPES.VALUE_OVER_TIME ||
    reportType?.value === REPORT_TYPES.STAGES_OVER_TIME ||
    area === AREA_RESPONSES.ACTIVITIES ||
    area === AREA_RESPONSES.MARKETING
  ) {
    return null;
  }

  return (
    <StyledWizardField top={gutters.spacing(2, 2)}>
      <KizenTypography type="subheader">
        {t('Select Value to Display')}
      </KizenTypography>
      <StyledWizardField top={gutters.spacing(1, 1)}>
        <ButtonGroup
          value={value}
          onChange={(v) => setState('value', v)}
          sizing={!mobile ? 'auto' : ''}
          options={valueOptions}
          className="wizard-buttons value-to-display"
          columns={mobile ? 2 : 4}
          mode="wizard"
          gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
        />
      </StyledWizardField>
    </StyledWizardField>
  );
};

MainReportType.propTypes = {
  mobile: PropTypes.bool,
  active: PropTypes.bool,
};

MainReportType.defaultProps = {
  mobile: false,
  active: false,
};

export default MainReportType;
