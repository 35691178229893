import { DEFAULT_LINE_HEIGHT_VALUE } from '../constants';
import { ElementContainerProps } from '../types';
import { NodeProps, PartialValues } from './types';

export type EmailPropDefaults = PartialValues<
  Omit<NodeProps, 'CustomField' | 'FormField'>
>;

export type FormPropDefaults = PartialValues<NodeProps>;

export type StaticBlockPropDefaults = PartialValues<
  Omit<NodeProps, 'CustomField' | 'FormField' | 'Root'>
> & {
  Root: Omit<Partial<NodeProps['Root']>, 'maxWidth'> & {
    hasShadow: boolean;
    height: number;
    maxWidth: number;
  };
};

export type DefaultProps =
  | Partial<EmailPropDefaults>
  | Partial<FormPropDefaults>;

const DefaultContainerProps: ElementContainerProps = {
  containerBackgroundColor: 'rgba(0,0,0,0)',
  containerBackgroundImageSrc: undefined,
  containerBackgroundImageId: undefined,
  containerBackgroundImageName: '',
  containerBackgroundPositionX: '0%',
  containerBackgroundPositionY: '0%',
  containerBackgroundSize: 'auto',
  containerBackgroundRepeat: 'repeat',
  containerBorderColor: 'rgba(74,86,96,1)',
  containerBorderStyle: 'solid',
  containerBorderWidth: '0',
  containerBorderRadius: true,
  containerBorderTopLeftRadius: '4',
  containerBorderTopRightRadius: '4',
  containerBorderBottomLeftRadius: '4',
  containerBorderBottomRightRadius: '4',
  containerMarginTop: '0',
  containerMarginRight: '0',
  containerMarginBottom: '0',
  containerMarginLeft: '0',
  containerPaddingTop: '0',
  containerPaddingRight: '0',
  containerPaddingBottom: '0',
  containerPaddingLeft: '0',
};

const EmailContainerPropOverrides = {
  containerBackgroundPositionX: 'center',
  containerBackgroundPositionY: 'top',
  containerBorderTopLeftRadius: '0',
  containerBorderTopRightRadius: '0',
  containerBorderBottomLeftRadius: '0',
  containerBorderBottomRightRadius: '0',
};

const CommonRootProps: Partial<NodeProps['Root']> = {
  backgroundColor: 'rgba(255,255,255,1)',
  width: '100',
  maxWidth: '900',
  alignment: 'center',
  mobileBreak: '414',
  color: 'rgba(74,86,96,1)',
  fontFamily: 'Arial',
  fontSize: '14',
  linkColor: 'rgba(82,142,249,1)',
  lineHeight: DEFAULT_LINE_HEIGHT_VALUE,
};

const CommonRowProps: Partial<NodeProps['Row']> = {
  ...DefaultContainerProps,
  containerPaddingTop: '10',
  containerPaddingRight: '10',
  containerPaddingBottom: '10',
  containerPaddingLeft: '10',
  maxWidth: CommonRootProps.maxWidth,
  width: '100',
  alignment: 'center',
};

const CommonSectionProps: Partial<NodeProps['Section']> = {
  ...DefaultContainerProps,
  containerPaddingTop: '10',
  containerPaddingRight: '10',
  containerPaddingBottom: '10',
  containerPaddingLeft: '10',
  maxWidth: CommonRootProps.maxWidth,
  width: '100',
  alignment: 'center',
};

const CommonTextProps: Partial<NodeProps['Text']> = {
  ...DefaultContainerProps,
  containerPaddingTop: '10',
  containerPaddingRight: '10',
  containerPaddingBottom: '10',
  containerPaddingLeft: '10',
};

export const DefaultFormProps: FormPropDefaults = {
  Button: {
    ...DefaultContainerProps,
    containerPaddingTop: '10',
    containerPaddingRight: '0',
    containerPaddingBottom: '10',
    containerPaddingLeft: '0',

    color: 'rgba(75,199,180,1)',
    label: 'Submit',
    textColor: 'rgba(255,255,255,1)',
    action: 'submit',
    url: '',
    borderSize: '0',
    borderRadius: '8',
    borderColor: 'rgba(0,0,0,1)',
    paddingTop: '10',
    paddingRight: '20',
    paddingBottom: '10',
    paddingLeft: '20',
    fontSize: '12',
    textStyles: [],
    alignment: 'center',
    fontFamily: 'Arial',
    isSubmitButton: false,
    openLinkInNewTab: true,
  },
  CustomField: DefaultContainerProps,
  FormField: DefaultContainerProps,
  Image: {
    ...DefaultContainerProps,
    size: 'auto',
    unit: 'pixel',
    height: null,
    width: null,
    display: 'flex',
    position: 'center',
    alt: '',
    link: '',
  },
  Root: {
    ...DefaultContainerProps,
    ...CommonRootProps,
    tabletBreak: '768',
  },
  Row: CommonRowProps,
  Section: CommonSectionProps,
  Text: CommonTextProps,
};

export const DefaultEmailProps: EmailPropDefaults = {
  Button: { ...DefaultFormProps.Button, ...EmailContainerPropOverrides },
  Image: { ...DefaultFormProps.Image, ...EmailContainerPropOverrides },
  Root: { ...DefaultContainerProps, ...CommonRootProps },
  Row: CommonRowProps,
  Section: CommonSectionProps,
  Text: { ...CommonTextProps, ...EmailContainerPropOverrides },
};

export const DefaultHomepageProps: StaticBlockPropDefaults = {
  ...DefaultFormProps,
  Root: {
    backgroundColor: 'rgba(255,255,255,1)',
    color: 'rgba(74,86,96,1)',
    fontFamily: 'Arial',
    fontSize: '14',
    linkColor: 'rgba(82,142,249,1)',
    lineHeight: DEFAULT_LINE_HEIGHT_VALUE,
    height: 335,
    maxWidth: 1372,
    hasShadow: true,
    tabletBreak: '768',
    mobileBreak: '414',
  },
  Section: {
    ...CommonSectionProps,
    maxWidth: '1372',
    containerBorderRadius: false,
  },
  Row: {
    ...CommonRowProps,
    maxWidth: '1372',
    containerBorderRadius: false,
  },
};

export const DefaultStaticBlockProps: StaticBlockPropDefaults = {
  ...DefaultHomepageProps,
  Root: {
    ...DefaultHomepageProps.Root,
    height: 400,
  },
};
