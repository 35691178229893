import { grayScale } from 'app/colors';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import { IconSizing } from 'components/Kizen/Icon';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import { isEventTypeAvailable } from 'pages/Broadcasts/helpers';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { BROADCAST_FORMDATA, defaultFilterConfig } from './constants';
import { EventButtonIcon, SubHeader, TypesButtonGrid } from './styled';
import SmartSend from 'ts-components/SmartSend';
import { useWizardFilterContext } from 'components/Wizards/MetaFilters/context';

export const EventTypesButtons = ({
  setFormData,
  type,
  smartSendTimeSplitPercentage,
  access,
  disabled,
  isSmartSendEventSending,
  isEdit,
}) => {
  const { t } = useTranslation();

  const {
    filterOps: { reset },
  } = useWizardFilterContext();
  const handleChange = (value) => () => {
    if (value !== type) {
      // reset the filters here so that setFormData is stable
      reset();
      setFormData(BROADCAST_FORMDATA.type, value);
      setFormData(BROADCAST_FORMDATA.action, EMPTY_OBJECT);
      setFormData(
        BROADCAST_FORMDATA.filterType,
        defaultFilterConfig[BROADCAST_FORMDATA.filterType]
      );
      setFormData(
        BROADCAST_FORMDATA.filterGroupsIds,
        defaultFilterConfig[BROADCAST_FORMDATA.filterGroupsIds]
      );
      setFormData(
        BROADCAST_FORMDATA.filterConfig,
        defaultFilterConfig[BROADCAST_FORMDATA.filterConfig]
      );
      setFormData(BROADCAST_FORMDATA.name, '');
    }
  };

  const preReleaseFeature = usePreReleaseFeatures();

  const eventTypes = useMemo(() => {
    return Object.values(EVENT_TYPES)
      .filter(isEventTypeAvailable(preReleaseFeature, access, isEdit, type))
      .map(({ type, icon, getTitle }) => ({
        value: type,
        label: getTitle(t),
        image: (
          <IconSizing size="25px">
            <EventButtonIcon icon={icon} color={grayScale.mediumDark} />
          </IconSizing>
        ),
      }));
  }, [preReleaseFeature, isEdit, type, access, t]);

  return (
    <>
      <SubHeader>
        {isEdit ? t('Selected Broadcast Type') : t('Choose Broadcast Type')}
      </SubHeader>
      <TypesButtonGrid>
        {eventTypes.map((option) => (
          <ButtonGroupImageButton
            key={option.value}
            selected={type === option.value}
            onClick={handleChange(option.value)}
            option={option}
            disabled={disabled}
            coloredIfDisabled
          />
        ))}
      </TypesButtonGrid>
      {type === EVENT_TYPES.email.type ? (
        <SmartSend
          disabled={isEdit && isSmartSendEventSending}
          value={smartSendTimeSplitPercentage}
          onChange={(value) => {
            setFormData(BROADCAST_FORMDATA.smartSendTimeSplitPercentage, value);
          }}
        />
      ) : null}
    </>
  );
};
