import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { isSentryEnvironment } from './utils';

const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
const sentryTraceSample = import.meta.env.VITE_SENTRY_TRACESAMPLE;
const sentryENV = import.meta.env.VITE_SENTRY_ENV;
const sentryRelease = import.meta.env.VITE_SENTRY_RELEASE;

const initSentry = () => {
  console.log(`Sentry environment - '${sentryENV}'`);
  Sentry.init({
    dsn: sentryDSN,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: sentryTraceSample,
    environment: sentryENV,
    ...(sentryRelease && { release: sentryRelease }),
    enableAutoSessionTracking: false,
    autoSessionTracking: false,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
  });
};

export const SentryProvider = () => {
  const sentryInitialized = useRef(false);
  const user = useSelector((s) => s.authentication.user);
  const business = useSelector((s) => s.authentication.chosenBusiness);

  useEffect(() => {
    if (isSentryEnvironment(sentryENV)) {
      if (!user && !business) {
        initSentry();
        sentryInitialized.current = true;
      } else if (user && business) {
        if (!sentryInitialized.current) {
          initSentry();
          sentryInitialized.current = true;
        }

        console.log(`Sentry environment - '${sentryENV}' - User set`);
        const { email, id, full_name: username } = user.profile;
        Sentry.setUser({
          email,
          id,
          username,
          ip_address: '{{auto}}',
          business_name: business.name,
          business_id: business.id,
        });
        Sentry.setTag('kzn-business', business.name);
      }
    }
  }, [user, business]);

  return null;
};
