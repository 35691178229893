export const MAX_SLOTS_PER_PARENT = 20;
export const MAX_ROWS_PER_PARENT = 10;

export const calculateItemColumnWidth = (item) => {
  return item.widthPercent === 50 ? 1 : 2;
};

export const getRowCount = (items) => {
  let rowCount = 0;
  let isHalfRow = false;

  items.forEach((item) => {
    const cols = calculateItemColumnWidth(item);

    if (cols === 1) {
      if (!isHalfRow) {
        rowCount += 1;
        isHalfRow = true;
      } else {
        isHalfRow = false;
      }
    } else {
      rowCount += 1;
      isHalfRow = false;
    }
  });

  return rowCount;
};

export const getHalfRowItems = (items) => {
  const halfRowItems = [];
  let previousItem = '';
  let isHalfRow = false;

  for (let i = 0; i <= items.length; i++) {
    const item = items[i];
    if (item) {
      const cols = item.widthPercent === 50 ? 1 : 2;

      if (cols === 1) {
        if (!isHalfRow) {
          isHalfRow = true;
        } else {
          isHalfRow = false;
        }
      } else {
        if (isHalfRow) {
          halfRowItems.push({
            after: previousItem,
            before: item,
          });
          isHalfRow = false;
        }
      }
    } else {
      if (isHalfRow) {
        halfRowItems.push({
          after: previousItem,
          before: item,
        });
      }
    }

    previousItem = item;
  }

  return halfRowItems;
};
class ChildDropValidator {
  constructor(
    dropZone,
    draggingItem,
    items = [],
    maxSlots = MAX_SLOTS_PER_PARENT,
    maxRows = MAX_ROWS_PER_PARENT
  ) {
    this.dropZone = dropZone;
    this.items = items;
    this.draggingItem = draggingItem;
    this.maxSlots = maxSlots;
    this.maxRows = maxRows;
  }

  getIsDropAllowed(halfItems) {
    const { position, id } = this.dropZone;

    if (position && id) {
      return halfItems.some((item) => item[position]?.id === id);
    }

    return false;
  }

  getRemainingChildSlotCount(items) {
    const usedSlots = items.reduce((acc, curr) => {
      return acc + (curr.widthPercent === 50 ? 1 : 2);
    }, 0);

    return this.maxSlots - usedSlots;
  }

  getSection() {
    return this.items.find((i) => i.id === this.dropZone.sectionId);
  }

  getChildrenToConsider() {
    const section = this.getSection();
    return (section?.children ?? []).filter(
      (x) => x.id !== this.draggingItem.id
    );
  }

  getCurrentItemColumnWidth() {
    return calculateItemColumnWidth(this.draggingItem);
  }

  getHalfRowItems(items) {
    const halfRowItems = [];
    let previousItem = '';
    let isHalfRow = false;

    for (let i = 0; i <= items.length; i++) {
      const item = items[i];
      if (item) {
        const cols = item.widthPercent === 50 ? 1 : 2;

        if (cols === 1) {
          if (!isHalfRow) {
            isHalfRow = true;
          } else {
            isHalfRow = false;
          }
        } else {
          if (isHalfRow) {
            halfRowItems.push({
              after: previousItem,
              before: item,
            });
            isHalfRow = false;
          }
        }
      } else {
        if (isHalfRow) {
          halfRowItems.push({
            after: previousItem,
            before: item,
          });
        }
      }

      previousItem = item;
    }

    return halfRowItems;
  }

  isItemAtPositionN(children, itemId, n) {
    let currentColumn = 1;

    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      if (child.id === itemId) {
        return currentColumn === n;
      }

      if (child.widthPercent !== 50) {
        currentColumn = 1;
      } else {
        if (currentColumn === 1) {
          const nextItem = children[i + 1];
          if (nextItem) {
            const cols = nextItem.widthPercent === 50 ? 1 : 2;
            if (cols === 1) {
              currentColumn = 2;
            } else {
              currentColumn = 1;
            }
          } else {
            currentColumn = 2;
          }
        } else {
          currentColumn = 1;
        }
      }
    }

    return false;
  }

  wouldCauseJump(children, halfRowItems) {
    if (this.draggingItem.widthPercent === 50) {
      return { jump: false };
    }

    const dropZoneItem = children.find((i) => i.id === this.dropZone.id);

    if (dropZoneItem.widthPercent !== 50) {
      return { jump: false };
    }

    const isDropZoneInFirstColumn = this.isItemAtPositionN(
      children,
      dropZoneItem.id,
      1
    );

    const isDropZoneInLastColumn = this.isItemAtPositionN(
      children,
      dropZoneItem.id,
      2
    );

    if (isDropZoneInFirstColumn && this.dropZone.position === 'before') {
      return { jump: false };
    }

    if (isDropZoneInLastColumn && this.dropZone.position === 'after') {
      return { jump: false };
    }

    if (isDropZoneInFirstColumn && this.dropZone.position === 'after') {
      // if empty space is after first column, allow drop
      const canDropHere = Boolean(
        halfRowItems.find((i) => i.after?.id === dropZoneItem.id)
      );

      if (canDropHere) {
        return { jump: false };
      }

      // otherwise, put the drop zone after the item in the second column
      const itemIndex = children.findIndex((c) => c.id === dropZoneItem.id);
      const nextItem = children[itemIndex + 1];

      if (nextItem) {
        return { jump: false, newId: nextItem.id };
      }
    }

    if (isDropZoneInLastColumn && this.dropZone.position === 'before') {
      const itemIndex = children.findIndex((c) => c.id === dropZoneItem.id);
      const previousItem = children[itemIndex - 1];

      if (previousItem) {
        return { jump: false, newId: previousItem.id };
      }
    }

    return { jump: true };
  }

  getValidDropZone() {
    // If there is no ID, that signifies the drop is happening in the same spot it started,
    // which is always allowed no matter what state the siblings are in
    if (!this.dropZone.id) {
      return this.dropZone;
    }

    const currentItemColumnWidth = this.getCurrentItemColumnWidth();

    const section = this.getSection();
    if (section.isLocked && currentItemColumnWidth === 1) {
      return null;
    }

    const childrenToConsider = this.getChildrenToConsider();
    const halfRowItems = getHalfRowItems(childrenToConsider);
    const remainingSlotCount =
      this.getRemainingChildSlotCount(childrenToConsider);

    // Disable drop if there are no remaining slots available for an
    // item of this size
    if (currentItemColumnWidth > remainingSlotCount) {
      return null;
    }

    // Disable drop if it would cause a bad UX (a full width item between two half width items)
    const { jump, newId } = this.wouldCauseJump(
      childrenToConsider,
      halfRowItems
    );

    if (jump) {
      return null;
    }

    // If we can avoid the jump in the UI by shifting the dropzone, do that instead
    if (newId) {
      this.dropZone.id = newId;
    }

    // If there is an entire additional row available, allow the drop
    // no matter where it is
    const usedRows = getRowCount(childrenToConsider);
    if (usedRows < this.maxRows) {
      return this.dropZone;
    }

    // There are no more rows available, which means it's impossible to fit
    // a two-column item anywhere, so disable the drop
    if (currentItemColumnWidth === 2) {
      return null;
    }

    const isDropAllowed = this.getIsDropAllowed(halfRowItems);

    if (isDropAllowed) {
      return this.dropZone;
    }

    return null;
  }
}

export default ChildDropValidator;
