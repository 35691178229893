/* eslint-disable react/jsx-key */
import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';
import { colorsButton } from 'app/colors';
import { KizenTypography } from 'app/typography';
import { StyledTable } from '../styles';
import Section from './helpers/Section';
import Cols from './helpers/Cols';

function IconTable({ children, ...others }) {
  return (
    <StyledTable responsive {...others}>
      <thead>
        <tr>
          <th>
            <KizenTypography type="label-small">Label</KizenTypography>
          </th>
          <th>
            <KizenTypography type="label-small">Icon</KizenTypography>
          </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </StyledTable>
  );
}

// Make all rows the height of a row with a large icon
const TrFixedHeight = styled.tr`
  height: 53px;
`;

function IconRow({ icon, ...others }) {
  return (
    <TrFixedHeight>
      <td>
        <KizenTypography>{icon}</KizenTypography>
      </td>
      <td>
        <Icon icon={icon} {...others} />
      </td>
    </TrFixedHeight>
  );
}

IconRow.propTypes = {
  icon: PropTypes.string.isRequired,
};

const SortIconRow = styled(IconRow)`
  && svg {
    height: 12px;
    width: auto;
  }
`;

const IconSamples = () => {
  const renderRow = (label, props) => {
    return <IconRow icon={label} {...props} />;
  };

  const renderWithKey = (el) => React.cloneElement(el, { key: el.props.icon });

  const icons = [
    renderRow('three-dot'),
    renderRow('down'),
    renderRow('right'),
    renderRow('filter'),
    renderRow('calendar'),
    renderRow('people'),
    renderRow('check'),
    renderRow('pen'),
    renderRow('envelope'),
    renderRow('envelope-open'),
    renderRow('pointer'),
    renderRow('reorder'),
    renderRow('delete'),
    renderRow('visible'),
    renderRow('hidden'),
    renderRow('warning'),
    renderRow('survey-entity'),
    renderRow('form-entity'),
    renderRow('activity-entity'),
    renderRow('pipeline-entity'),
    renderRow('commerce-entity'),
    renderRow('marketing-entity'),
    <SortIconRow icon="no-sort" />,
    <SortIconRow icon="sort-numeric-asc" color={colorsButton.blue} />,
    <SortIconRow icon="sort-numeric-desc" color={colorsButton.blue} />,
    <SortIconRow icon="sort-alpha-asc" color={colorsButton.blue} />,
    <SortIconRow icon="sort-alpha-desc" color={colorsButton.blue} />,
    renderRow('columns'),
    renderRow('tag'),
    renderRow('chevron-down'),
    renderRow('chevron-double-down'),
    renderRow('chevron-left'),
    renderRow('chevron-right'),
    renderRow('chevron-double-right'),
    renderRow('plus'),
    renderRow('cogs'),
    renderRow('phone'),
    renderRow('email'),
    renderRow('building'),
    renderRow('users-cog'),
    renderRow('id-card'),
    renderRow('clipboard-list'),
    renderRow('globe'),
    renderRow('shield-check'),
    renderRow('wallet'),
    renderRow('user-circle'),
    renderRow('compass'),
    renderRow('smile'),
    renderRow('experience-entity'),
    renderRow('chart-bar'),
    renderRow('database'),
    renderRow('browser'),
    renderRow('play'),
    renderRow('play-light'),
    renderRow('cog'),
    renderRow('info-circle'),
    renderRow('user'),
    renderRow('layer-group'),
    renderRow('sack-dollar'),
    renderRow('receipt'),
    renderRow('calendar-alt'),
    renderRow('bullseye-pointer'),
    renderRow('book-alt'),
    renderRow('clipboard-list-check'),
    renderRow('clipboard-check'),
    renderRow('user-circle'),
    renderRow('repeat'),
    renderRow('sign-out'),
    renderRow('bars'),
    renderRow('arrow-from-left'),
    renderRow('arrow-from-right'),
    renderRow('arrow-to-left'),
    renderRow('arrow-to-right'),
    renderRow('seedling'),
    renderRow('store'),
    renderRow('check-light'),
    renderRow('cc-visa'),
    renderRow('cc-discover'),
    renderRow('cc-amex'),
    renderRow('cc-mastercard'),
    renderRow('save'),
    renderRow('sync'),
  ];

  const middleIndex = Math.ceil(icons.length / 2);

  return (
    <Section title="Icons">
      <Cols columns={2}>
        <IconTable>{icons.slice(0, middleIndex).map(renderWithKey)}</IconTable>
        <IconTable>{icons.slice(middleIndex).map(renderWithKey)}</IconTable>
      </Cols>
    </Section>
  );
};

export default IconSamples;
