import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { HttpError } from 'services/errors';

const AxiosErrorCatcher = () => {
  const errors = useSelector((s) => s.errors);

  useEffect(() => {
    if (errors.text || errors.code)
      throw new HttpError(errors.code, errors.text, errors.url);
  }, [errors]);
  return <></>;
};
export default AxiosErrorCatcher;
