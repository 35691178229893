import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import SubNavigation from 'components/Navigation/SubNavigation';
import routes, { AdsUrlBase } from './routes';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useTranslation } from 'react-i18next';
import AdsService from '__services/AdvsService';
import { isMobile } from 'app/spacing';
import { useWindowSize } from 'react-use';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useDispatch } from 'react-redux';
import { getAccounts } from 'store/adManagerPage/adManager.redux';
import { useModalControl } from 'hooks/useModalControl';
import SyncAdAccountsModal from './SyncAdAccountsModal';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { monitoringExceptionHelper } from 'sentry/helpers';
const setupSubpage = 'setup-adwords';

const routeComponents = Object.keys(routes).map((routeKey, index) => {
  const route = routes[routeKey];
  return (
    <Route
      key={index}
      path={route.path}
      exact={route.exact !== false}
      component={route.component}
    />
  );
});

const AdManagerPage = ({ title }) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  useSetTitleOnLoad(title(t));
  const preReleaseFeature = usePreReleaseFeatures();
  const { subpage } = useParams();
  const dispatch = useDispatch();
  const [modalOpen, { showModal, hideModal }] = useModalControl();

  const [accounts, setAccounts] = useState([]);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = useRef(null);
  const { width } = useWindowSize();

  const onConfirm = useCallback(async () => {
    try {
      const data = accounts.filter((el) => {
        return values.some((f) => {
          return f.value === el.id;
        });
      });
      await AdsService.addAccounts(data, token.current);
      showToast({
        message: t('The account(s) were(was) successfully added.'),
        variant: toastVariant.SUCCESS,
      });
      dispatch(getAccounts());
      hideModal();
    } catch (e) {
      showToast({
        message: t('The account(s) were(was) not successfully added.'),
        variant: toastVariant.FAILURE,
      });
    }
  }, [accounts, dispatch, hideModal, showToast, t, values]);

  useEffect(() => {
    if (subpage === setupSubpage) {
      showModal();
      const fetchData = async () => {
        const data = await AdsService.getSyncableAdAccounts({
          encrypted_access_token: token.current,
        });
        setLoading(false);
        setAccounts(data.accounts.filter((acc) => !acc?.syncEnabled));
      };
      fetchData()
        // make sure to catch any error
        .catch((error) => {
          monitoringExceptionHelper(error);
        });
    }
  }, [showModal, subpage]);

  if (!subpage || subpage === setupSubpage) {
    const url = new URL(window.location);
    token.current = url.searchParams.get('encrypted_access_token');
    return <Redirect exact to={`${AdsUrlBase}/connections`} />;
  }

  return (
    <>
      {!isMobile(width) ? (
        <SubNavigation
          routes={Object.fromEntries(
            Object.entries(routes).filter(
              ([, item]) => preReleaseFeature || !item?.preRelease
            )
          )}
        />
      ) : null}
      <Switch>{routeComponents}</Switch>
      {modalOpen ? (
        <SyncAdAccountsModal
          show={true}
          loading={loading}
          onHide={hideModal}
          onConfirm={onConfirm}
          setValues={setValues}
          values={values}
          accounts={accounts}
        />
      ) : null}
    </>
  );
};

export default AdManagerPage;
