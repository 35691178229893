import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from 'react-bootstrap/Overlay';
import KizenTypography from 'app/kizentypo';
import Switch from 'components/Kizen/Switch';
import TextInput from 'components/Inputs/TextInput';
import KizenErrorCard from 'components/Kizen/ErrorCard';
import styled from '@emotion/styled';
import MultiSelect from '../MultiSelect';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { FIELD_TYPES } from 'utility/constants';

export const ErrorCard = styled(KizenErrorCard)`
  margin-top: -1px;
`;

export const getFieldValueForParam = (
  { value, fieldType, fieldData },
  notes
) => {
  switch (fieldType) {
    case 'wysiwyg':
      return (
        new DOMParser().parseFromString(notes || '', 'text/html').body
          .textContent || ''
      );
    case FIELD_TYPES.Timezone.type:
      return value?.id || value?.name || '';
    case FIELD_TYPES.PhoneNumber.type:
      return value?.e164 || value || '';
    case FIELD_TYPES.Dropdown.type:
    case FIELD_TYPES.Status.type:
    case FIELD_TYPES.Radio.type:
    case FIELD_TYPES.YesNoMaybe.type:
      return fieldData?.options.find(({ id }) => id === value)?.name || '';
    case FIELD_TYPES.Checkbox.type:
      return !!value;
    case FIELD_TYPES.Checkboxes.type:
      return Array.isArray(value) && value?.length
        ? value.reduce((acc, id) => {
            const label = fieldData?.options.find((opt) => opt.id === id)?.name;
            if (label) {
              acc.push(label);
            }
            return acc;
          }, []) || ''
        : '';
    case FIELD_TYPES.DynamicTags.type:
    case FIELD_TYPES.Relationship.type:
      if (Array.isArray(value)) {
        return value.length
          ? value.map(({ label, shortLabel }) => shortLabel || label || '')
          : '';
      }
      return value?.shortLabel || value?.label || '';
    case FIELD_TYPES.TeamSelector.type:
      return value?.email || value?.id || '';
    default:
      if (Array.isArray(value)) {
        return value.length ? value.map((v) => v.id || v) : '';
      }
      return value || '';
  }
};

export const getParameterName = ({ customObjectField, name }) =>
  customObjectField
    ? `${customObjectField.customObject.name}.${customObjectField.name}`
    : name;

const StyledFullWidthMultiSelect = styled(MultiSelect)`
  &&& {
    width: 100%;
  }
`;

export const generateRedirtectURL = ({
  redirectUrl,
  passVariablesOnRedirect,
  redirectParameterFields,
  fields,
  notes,
}) => {
  const url = new URL(redirectUrl);
  if (passVariablesOnRedirect) {
    const parameterFieldsLookup = redirectParameterFields?.length
      ? redirectParameterFields.reduce((acc, field) => {
          acc[field.id] = true;
          return acc;
        }, {})
      : null;

    fields.forEach((field) => {
      if (
        (!parameterFieldsLookup || parameterFieldsLookup[field.id]) &&
        !field.fieldData.isHidden
      ) {
        const value = getFieldValueForParam(field, notes);
        const parameterName = getParameterName(field.fieldData);

        if (Array.isArray(value)) {
          value.forEach((v) => {
            url.searchParams.append(parameterName, v);
          });
        } else {
          url.searchParams.set(parameterName, value);
        }
      }
    });
  }
  return url;
};

const RedirectUrlInput = ({
  redirectUrl,
  passVariablesOnRedirect,
  redirectParameterFieldIds,
  onTextChange,
  onTextBlur,
  onPaste,
  onSwitchChange,
  onRedirectParametersChange,
  errorMessage,
  fields = EMPTY_ARRAY,
  showPassParametersSwitch = true,
  label,
  labelInfo,
  loading,
}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const showError = errorMessage.length > 0;

  const options = useMemo(() => {
    return fields.map((field) => {
      const displayName = field.display_name || field.displayName;
      const customObjectField =
        field.custom_object_field || field.customObjectField;
      return {
        value: field.id,
        label: `${displayName} (${customObjectField?.name || field.name})`,
      };
    });
  }, [fields]);

  const handleParamsChange = (values) => {
    onRedirectParametersChange(values.map(({ value }) => value));
  };

  return (
    <>
      <TextInput
        ref={ref}
        label={label || t('Redirect URL')}
        margin
        error={showError}
        placeholder={t('Enter URL')}
        value={redirectUrl}
        onChange={onTextChange}
        onBlur={onTextBlur}
        onPaste={onPaste}
        labelInfo={labelInfo}
      />
      {showPassParametersSwitch && (
        <Switch
          textPlacement="regular-top"
          label={t('Pass Parameters on Redirect')}
          checked={passVariablesOnRedirect}
          onChange={onSwitchChange}
          tooltip={t(
            'Passing parameters on redirect is not secure. Please do not pass any information that would risk violations of security or compliance rules if intercepted. All parameter names will be API names.'
          )}
        />
      )}
      {passVariablesOnRedirect ? (
        <StyledFullWidthMultiSelect
          label={t('Included Parameters')}
          value={redirectParameterFieldIds}
          options={options}
          onChange={handleParamsChange}
          loadItems={loading}
          placeholder={t('Leave blank for all ')}
        />
      ) : null}
      <Overlay
        transition={!showError}
        target={ref.current}
        show={showError}
        placement="bottom-start"
      >
        <ErrorCard show={showError} data-qa="form-redirect-url-error-message">
          <KizenTypography>{errorMessage}</KizenTypography>
        </ErrorCard>
      </Overlay>
    </>
  );
};

export default RedirectUrlInput;
