import {
  cloneElement,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  useRelationshipSelect,
  useSelectEnter,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import CreateEntityModalFull from 'components/Modals/CreateEntity/FullForm';
import { useEntityCreationModal } from 'components/Modals/CreateEntity/hook';
import { TopMenuButton } from 'components/Kizen/Menu';
import { applyRef } from 'components/Inputs/props';
import SelectInline from 'components/Inputs/inline/Select';
import { RelationshipLink } from './RelationshipOne';
import { RELATION_FIELD } from 'queries/query-keys';
import { useQueryClient } from 'react-query';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

// Implementation based on FieldInput/RelationshipOne
export const RelationshipOneSelectFullExperienceForm = forwardRef(
  (
    {
      field,
      object,
      relationship = EMPTY_OBJECT,
      disabled = false,
      onChange = null,
      onModalOpen = null,
      modalOpen = false,
      onFocus,
      onMenuOpen,
      onMenuClose,
      endAdornmentIcon,
      setShown,
      onSubmit,
      shouldFocusNext,
      ...props
    },
    ref
  ) => {
    const fieldId = useMemo(
      () => `${field?.id}-${object?.id}`,
      [field, object]
    );
    const { t } = useTranslation();
    const selectRef = useRef(null);
    const queryClient = useQueryClient();
    const isContact = field.relation?.fetchUrl === 'client';

    const {
      initialValues,
      setInitialValues,
      parseTypeAhead,
      objectType,
      hasAbilityToAdd,
    } = useEntityCreationModal(field);

    const [typeaheadProps, typeaheadState, { refetch }] =
      useSelectTypeaheadWithScroll({
        alwaysOpen: true, // The select mounts with the menu open
        fetch: relationship.search,
        objectToOption: relationship.toOption,
        fieldId: field.id,
        onFocus,
        onMenuOpen,
        onMenuClose,
        selectRef,
        relatedObjectId: field.relation?.relatedObject,
      });

    const entityCreatedHandler = useCallback(
      async (newEntity) => {
        onChange(relationship.toOption(newEntity));
        setInitialValues({});
        await refetch();
        // need to invalidate all the fields that are related to this object
        await queryClient.invalidateQueries({
          queryKey: [
            ...RELATION_FIELD.RELATED_OBJECTS(field.relation.relatedObject),
          ],
        });
      },
      [
        field.relation.relatedObject,
        onChange,
        queryClient,
        refetch,
        relationship,
        setInitialValues,
      ]
    );

    const onStartCreate = () => {
      if (typeaheadProps.inputValue) {
        parseTypeAhead(typeaheadProps.inputValue, isContact);
      }
      onModalOpen(true);
      typeaheadProps.onInputChange('');
    };

    const selectEnterProps = useSelectEnter({
      handlePressEnter: onStartCreate,
      options: typeaheadProps.options,
      loadingOptions: typeaheadState.loading,
    });

    const mergeRef = useCallback(
      (el) => {
        applyRef(selectRef, el);
        applyRef(ref, el);
      },
      [ref]
    );

    const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();
    const { ...customSelectMenuProps } = useCustomSelectMenuProps(selectRef, {
      ...props,
      ...typeaheadProps,
    });
    assignFieldHandle(fieldId, {
      customFocus: () => {
        selectRef.current?.select?.focus();
        onModalOpen?.(false);
        setShown?.(true);
        return selectRef.current?.select;
      },
      customTab: () => {
        setShown?.(false);
        onModalOpen?.(false);
        onSubmit?.(props.value);
      },
      customEnter: () => {
        setShown?.(false);
        onModalOpen?.(false);
        onSubmit?.(props.value);
      },
      customEscape: () => {
        onModalOpen?.(false);
      },
      disabled,
      shouldFocusNext: shouldFocusNext,
    });

    return (
      <>
        <Select
          ref={mergeRef}
          disabled={disabled}
          placeholder={`${t('Find')} ${relationship.name.single}`}
          menuTopButton={
            hasAbilityToAdd && (
              <TopMenuButton onClick={onStartCreate}>
                {`${t('Add')} ${relationship.name.single}`}
              </TopMenuButton>
            )
          }
          endAdornment={<IconAdornment icon={'chevron-down'} />}
          isLoading={typeaheadState?.loading}
          onChange={onChange}
          {...selectEnterProps}
          {...props}
          {...typeaheadProps}
          {...getKeyListenersProps(fieldId)}
          {...customSelectMenuProps}
        />
        {modalOpen && (
          <CreateEntityModalFull
            objectType={objectType}
            objectId={isContact ? null : field.relation?.relatedObject}
            show={modalOpen}
            onCreated={entityCreatedHandler}
            onCancel={() => {
              onModalOpen(false);
              setInitialValues({});
            }}
            initialValues={initialValues}
          />
        )}
      </>
    );
  }
);

RelationshipOneSelectFullExperienceForm.displayName =
  'RelationshipOneSelectFullExperienceForm';

export default function RelationshipOneInlineFullExperienceForm({
  field,
  value: relationshipValue = null,
  onSubmit,
  className,
  disabled,
  children: selectEl = <RelationshipOneSelectFullExperienceForm />,
  object,
  enableLink = true,
  ...others
}) {
  const [{ displayValue: value, handleChange: handleSubmit, relationship }] =
    useRelationshipSelect({
      field,
      value: relationshipValue,
      onChange: onSubmit,
    });
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <SelectInline
      field={field}
      object={object}
      onSubmit={handleSubmit}
      rootCloseDisabled={modalOpen}
      labelEl={
        relationship.link && enableLink ? (
          <RelationshipLink relationship={relationship} />
        ) : (
          <TextEllipsisWithTooltip>
            {value?.label || '—'}
          </TextEllipsisWithTooltip>
        )
      }
      {...others}
    >
      {cloneElement(selectEl, {
        value,
        field,
        object,
        disabled,
        modalOpen,
        onModalOpen: setModalOpen,
        relationship,
      })}
    </SelectInline>
  );
}
