import AxiosService from './AxiosService';

class ConfigurationService {
  constructor() {
    if (!ConfigurationService.instance) {
      ConfigurationService.instance = this;
    }
    return ConfigurationService.instance;
  }

  exportConfiguration = async (body, options) => {
    return AxiosService.post('meta-export', body, options);
  };

  importConfiguration = async (s3_object_id, options) => {
    return AxiosService.post('meta-import', { s3_object_id }, options);
  };
}

const instance = new ConfigurationService();
Object.freeze(instance);

export default instance;
