import { format } from 'date-fns2';
import { range } from 'components/Kizen/DateRangePicker';
import { StyledDateRangePicker } from '../styles';

const ranges = [
  range.today,
  range.yesterday,
  range.thisMonth,
  range.last7Days,
  range.last30Days,
  range.thisYear,
  range.allTime1900to2100,
];

export const PillDateRangePicker = ({
  isReadOnly,
  onChange,
  onHide,
  value,
}) => {
  const onClickApply = (dates) => {
    const newValue = dates?.length
      ? dates
          .slice(0, 2)
          .map((date) => {
            try {
              return format(new Date(date), 'yyyy-MM-dd');
            } catch (err) {
              return null;
            }
          })
          .sort()
      : undefined;
    onChange(newValue);
    onHide();
  };

  const handleClear = () => {
    onChange();
    onHide();
  };

  return (
    <StyledDateRangePicker
      showClearButton
      value={value}
      onOk={onClickApply}
      onClear={handleClear}
      ranges={ranges}
      alwaysOpen
      menuOnly
      inPortal={false}
    />
  );
};
