import KizenTypography from 'app/kizentypo';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddRangeButton,
  BreakdownWrapper,
  DropdownValuesLabelContainer,
  LabelRow,
} from '../shared/styles';
import useFlashState from 'hooks/useFlashState';
import { v4 as uuidv4 } from 'uuid';
import BucketRow, { VALUE_BUCKET_ACTION_TYPES } from './BucketRow';
import { FIELD_TYPES } from 'utility/constants';
import { range } from 'utility/iterables';

export const getFallbackState = () => ({
  values: [],
  id: uuidv4(),
});

const handlers = {
  [VALUE_BUCKET_ACTION_TYPES.ADD_ROW]: (state, payload) => {
    const newState = [...state];

    newState.push(getFallbackState());

    return newState;
  },
  [VALUE_BUCKET_ACTION_TYPES.DELETE_ROW]: (state, payload) => {
    const { index } = payload;
    const newState = [...state];
    newState.splice(index, 1);

    return newState;
  },
  [VALUE_BUCKET_ACTION_TYPES.UPDATE]: (state, payload) => {
    const { index, values, label, remove, userInitiated } = payload;
    const newState = [...state];
    newState[index] = {
      ...newState[index],
      values: values ?? newState[index].values,
      label: label ?? newState[index].label,
      stale: remove ?? false,
      userInitiated: userInitiated ?? false,
    };

    return newState;
  },
};

const buildValidationArray = (breakdown, field, t) => {
  let errorCount = 0;
  const validation = breakdown.map((bucket, index) => {
    const errors = {
      errorCount: 0,
      missing: false,
      validationMessage: '',
    };

    if (bucket.stale && !bucket.userInitiated) {
      // stale buckets aren't validated
      return errors;
    }

    if (!bucket?.values || bucket.values.length === 0) {
      errorCount += 1;
      errors.errorCount += 1;
      errors.missing = true;
      errors.validationMessage = t('You must choose at least 1 value');
    }

    return errors;
  });

  return { validation, errorCount };
};

const BucketConfiguration = ({
  onChange,
  initial = [],
  field,
  fieldObject,
}) => {
  const { t } = useTranslation();

  const [showErrors, setShowErrors] = useFlashState(3000, false);
  const BreakdownReducer = useCallback(
    (state, { type, payload = {}, config }) => {
      return (
        handlers[type]?.(state, payload, {
          ...config,
        }) ?? state
      );
    },
    []
  );

  const [breakdown, dispatchBreakdown] = useReducer(BreakdownReducer, initial);

  const { validation, errorCount } = useMemo(() => {
    return buildValidationArray(breakdown, field, t);
  }, [breakdown, t, field]);

  const handleClickAddBucket = useCallback(() => {
    dispatchBreakdown({
      type: VALUE_BUCKET_ACTION_TYPES.ADD_ROW,
    });
  }, []);

  useEffect(() => {
    onChange?.(breakdown, errorCount > 0);
  }, [breakdown, onChange, errorCount]);

  const handleAnyFieldBlur = useCallback(() => {
    if (errorCount > 0) {
      setShowErrors(true);
    }
  }, [setShowErrors, errorCount]);

  const options = useMemo(() => {
    const valueByFieldType = (type, option) => {
      switch (type) {
        case FIELD_TYPES.YesNo.type:
          return option.id;
        case FIELD_TYPES.YesNoMaybe.type:
          return option.id;
        default:
          return option.code || option.id;
      }
    };

    if (field?.fieldType === FIELD_TYPES.Rating.type) {
      const min = field.rating.minValue;
      const max = field.rating.maxValue;

      return Array.from(range(min, max + 1), (value) => {
        return {
          label: String(value),
          value: value,
        };
      });
    }
    return (
      field?.options?.map((option) => {
        return {
          label: option.name,
          value: valueByFieldType(field?.fieldType, option),
        };
      }) ?? []
    );
  }, [field]);

  const filteredBreakdown = useMemo(() => {
    return breakdown.filter((b) => !b.stale);
  }, [breakdown]);

  const emptyBreakdowns = useMemo(() => {
    return breakdown.filter((b) => b.userInitiated);
  }, [breakdown]);

  return (
    <>
      <BreakdownWrapper>
        {!filteredBreakdown.length && !emptyBreakdowns.length ? (
          <KizenTypography fontStyle="italic">
            {t(
              'By default, each of the options in the field will be broken out into its own bucket and charted separately. If you click "+ Add Bucket", you can specify the specific buckets that you wish to view.'
            )}
          </KizenTypography>
        ) : null}
        {filteredBreakdown.length || emptyBreakdowns.length ? (
          <LabelRow>
            <DropdownValuesLabelContainer>
              <KizenTypography>{t('Values to Include')}</KizenTypography>
            </DropdownValuesLabelContainer>
            <KizenTypography>{t('Bucket Label')}</KizenTypography>
          </LabelRow>
        ) : null}
        {breakdown.map((value, index) => {
          if (value.stale && !value.userInitiated) {
            return null;
          }
          return (
            <BucketRow
              {...value}
              key={index}
              index={index}
              breakdown={breakdown}
              field={field}
              options={options}
              dispatchBreakdown={dispatchBreakdown}
              onBlur={handleAnyFieldBlur}
              showErrors={showErrors}
              validation={validation[index]}
              fieldObject={fieldObject}
            />
          );
        })}
        {filteredBreakdown.length < 10 ? (
          <AddRangeButton variant="text" onClick={handleClickAddBucket}>
            + {t('Add Bucket')}
          </AddRangeButton>
        ) : null}
      </BreakdownWrapper>
    </>
  );
};

export default BucketConfiguration;
