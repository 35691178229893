import { useEffect, useMemo, useRef, useState } from 'react';
import { TextSpan } from 'app/typography';
import {
  StyledFilterSummary,
  StyledLoader,
  StyledPreviewButton,
} from './styled';
import { isCustomFilter } from 'ts-filters/legacy';
import { useDebounce } from 'react-use';
import BroadcsatService from 'services/BroadcastService';
import { useTranslation } from 'react-i18next';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { DEFAULT_DELAY } from 'utility/config';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import useField from 'hooks/useField';
import { useSummaryScroll } from 'pages/Broadcasts/hooks/useSummaryScroll';
import { camelToSnakeCaseKeys } from 'services/helpers';
import { useWizardFilterContext } from 'components/Wizards/MetaFilters/context';

export const FilterSummary = ({
  values,
  valid,
  setFilter,
  customObject: customObjectProp,
  broadcastType,
  showSummary,
  setShowSummary,
}) => {
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useField(showSummary);
  const { t } = useTranslation();

  const filterContext = useWizardFilterContext();

  const {
    setHasErrors,
    setFilterHasErrors,
    filterSets,
    operation,
    filterOps: { build, validate },
    customObject: contextCustomObject,
  } = filterContext;

  const ogFilterSets = useRef({
    value: JSON.stringify(filterSets),
    updated: false,
  });
  const customObject = useMemo(
    () => contextCustomObject || customObjectProp,
    [contextCustomObject, customObjectProp]
  );

  const lines = useMemo(() => {
    const {
      matched_records = 0,
      emailable = 0,
      in_suppression_list = 0,
      unsubscribed = 0,
      opted_out = 0,
    } = valid ? summary : {};

    return (
      <>
        <TextEllipsisWithTooltip type="label-micro">
          {t("Based on the above filters, your send group's current size is")}
          :&nbsp;
          <TextSpan
            weight="bold"
            size="micro"
            data-qa-summary-selected={matched_records}
          >{`${matched_records} ${customObject?.entityName} ${
            matched_records === 1 ? t('Record') : t('Records')
          }`}</TextSpan>
          .&nbsp;
          {t('This is subject to change prior to the send.')}
        </TextEllipsisWithTooltip>
        {broadcastType === EVENT_TYPES.email.type ? (
          <>
            <TextEllipsisWithTooltip type="label-micro">
              {t('This broadcast will be sent to')}&nbsp;
              <TextSpan
                weight="bold"
                size="micro"
                data-qa-summary-count={emailable}
              >
                {`${emailable} ${
                  emailable === 1 ? t('Contact') : t('Contacts')
                }`}
                .
              </TextSpan>
            </TextEllipsisWithTooltip>
            <TextEllipsisWithTooltip
              type="label-micro"
              data-qa-summary-suppressed={in_suppression_list}
              data-qa-summary-unsubscribed={unsubscribed}
              data-qa-summary-optedout={opted_out}
            >
              <TextSpan weight="bold" size="micro">
                {t('Not Included')}:&nbsp;
              </TextSpan>
              {t(
                '{{ opted_out }} Opted Out, {{ unsubscribed }} Unsubscribed, {{ in_suppression_list }} Suppression List',
                {
                  opted_out,
                  unsubscribed,
                  in_suppression_list,
                }
              )}
              .
            </TextEllipsisWithTooltip>
          </>
        ) : null}
      </>
    );
  }, [valid, summary, t, customObject, broadcastType]);

  useEffect(() => {
    // not pretty but it works, once updated is true, we don't need the value anymore
    if (ogFilterSets.current.updated) {
      setShowSummary(false);
    } else if (ogFilterSets.current.value !== JSON.stringify(filterSets)) {
      setShowSummary(false);
      ogFilterSets.current = { ...ogFilterSets.current, updated: true };
    }
  }, [filterSets, setShowSummary]);

  useDebounce(
    () => {
      if (!showSummary) {
        return;
      }
      if (!valid && !(isCustomFilter(values.type) && values.filter.invalid)) {
        setLoading(false);
        return setSummary(EMPTY_OBJECT);
      }
      let filterConfig = null;
      const { type, groups } = values;

      if (isCustomFilter(type)) {
        const { errors, hasErrors } = validate();
        if (hasErrors) {
          setFilterHasErrors({ errors, hasErrors });
          setLoading(false);

          return setSummary(EMPTY_OBJECT);
        }
        setHasErrors(false);

        filterConfig = build(operation === 'and');
      }

      const body = {
        custom_object_id: customObject.id,
        filter_type: values.type,
        action: broadcastType,
      };

      if (isCustomFilter(type) && filterConfig) {
        body.filter_config = camelToSnakeCaseKeys(filterConfig);
      } else {
        body.filter_groups_ids = groups.map(({ id }) => id);
      }

      setLoading(true);

      BroadcsatService.getBroadcastSummary(body)
        .then(
          (data) => {
            if (isCustomFilter(values.type) && values.filter.invalid) {
              setFilter({ ...values.filter, invalid: undefined });
            }
            setSummary(data);
          },
          () => {
            if (
              isCustomFilter(values.type) &&
              values.filter.invalid === false
            ) {
              setFilter({
                ...values.filter,
                invalid: true,
                updateValidity: true,
              });
            }
            setSummary(EMPTY_OBJECT);
          }
        )
        .finally(() => setLoading(false));
    },
    DEFAULT_DELAY,
    [values, valid, showSummary, customObject?.id, filterSets, operation]
  );

  const scrollOnMount = useSummaryScroll(showSummary);

  return showSummary ? (
    <StyledLoader loading={loading}>
      <StyledFilterSummary ref={scrollOnMount}>{lines}</StyledFilterSummary>
    </StyledLoader>
  ) : (
    <StyledPreviewButton
      onClick={() => setShowSummary(true)}
      disabled={values.filter.invalid}
      variant="outline"
      color="blue"
      data-qa="show-summary-button"
    >
      {t('Preview')}
    </StyledPreviewButton>
  );
};
