import { forwardRef, useCallback, useRef } from 'react';
import useSyncScroll from 'react-use-sync-scroll';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useMeasure } from 'react-use';
import { hideScrollbarCss } from 'app/spacing';
import { applyRef } from 'components/Inputs/props';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { clamp } from 'utility/clamp';
import { useBuilderContext } from '../BuilderContext';
import tabletImage from '../images/ipadpro.png';
import { CanvasWrapper } from './CanvasWrapper';
import {
  CanvasMeasureContainer,
  canvasScrollFadeContainerStyle,
  Content,
} from './styled';
import { fitClamped } from './utils';

const SCROLLBAR_WIDTH = 0; // is now zero because the scrollbar is detached (doesn't affect placement of content within tablet image)
const PAGE_BUILDER_PADDING = 20;
const TABLET_IMAGE_WIDTH = 848; // this value equates to the internal tablet screen width being 768px, matching our default tablet breakpoint.
const TABLET_IMAGE_HEIGHT = 1190;
const TABLET_ASPECT_RATIO = TABLET_IMAGE_WIDTH / TABLET_IMAGE_HEIGHT;
const TABLET_MIN_WIDTH = 632 - SCROLLBAR_WIDTH;
const TABLET_BEZEL = 40; // this will actually be a range of ~30-40px since we scale the image down at small screen sizes
const TABLET_CURVATURE_HEIGHT = 20;

const TabletWrapper = styled.div`
  background: url(${tabletImage});
  background-size: ${({ bezel, screenWidth }) =>
    // when the bezel is less than the default of 40px the image is being scaled down due to lack of space
    // do not set our fixed size in this case (the internal screen width will be less then the default tablet
    // break of 768px anyways).
    screenWidth < TABLET_MIN_WIDTH
      ? 'cover'
      : bezel < TABLET_BEZEL
        ? 'contain'
        : `${TABLET_IMAGE_WIDTH}px;`};
  background-repeat: no-repeat;
  width: 100%;
  max-width: ${TABLET_IMAGE_WIDTH + SCROLLBAR_WIDTH}px;
  min-width: ${TABLET_MIN_WIDTH}px;
  max-height: ${({ height }) => `${height + SCROLLBAR_WIDTH}px`};
  ${({ hasScrollbar, bezel, padding = 45, paddingBottom = 45 }) =>
    hasScrollbar
      ? css`
          padding: ${padding}px ${padding - (padding - bezel)}px
            ${paddingBottom}px ${padding}px;
        `
      : css`
          padding: ${padding - SCROLLBAR_WIDTH}px;
          padding-bottom: ${paddingBottom}px;
        `};
`;

const StyledScrollFadeContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  ${canvasScrollFadeContainerStyle}
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow: overlay;
  border-radius: ${({ borderRadius = 25 }) => `${borderRadius}px`};
  ${({ fullscreen }) =>
    !fullscreen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const StyledScrollbarDetached = styled(ScrollBarDetached)`
  transform: ${({ bezel }) =>
    `translate(-${bezel + 9}px, ${bezel + TABLET_CURVATURE_HEIGHT}px`});
  height: ${({ height }) => `${height}px`};
`;

export const TabletCanvas = forwardRef(
  ({ disabled = false, paddingTop, children }, ref) => {
    const widthInterpolation = [
      TABLET_MIN_WIDTH + 2 * TABLET_BEZEL,
      TABLET_IMAGE_WIDTH,
    ];
    const {
      clearContentSettingsTray,
      setCanvasScrollContainer,
      setCanvasWrapper,
      setScrolling,
    } = useBuilderContext();
    const [canvasMeasureRef, { height, width }] = useMeasure();
    const [screenMeasureRef, { width: screenWidth }] = useMeasure();
    const imageWidth = width - 2 * PAGE_BUILDER_PADDING;
    const scrollbarRef = useRef();
    const [scrollFadeRef, scrollRef] = useSyncSizes(
      scrollbarRef,
      '.ContentForSyncWidth',
      'height'
    );
    const scrolled = useEndsOfScroll(scrollRef, [height]);
    const bezel = fitClamped(
      imageWidth,
      ...widthInterpolation,
      30,
      TABLET_BEZEL
    );
    const hasScrollbar = !scrolled[0] || !scrolled[2];
    const padding = fitClamped(
      imageWidth,
      ...widthInterpolation,
      30 + SCROLLBAR_WIDTH,
      TABLET_BEZEL + SCROLLBAR_WIDTH
    );
    const borderRadius = fitClamped(imageWidth, ...widthInterpolation, 20, 25);
    const tabletHeight =
      imageWidth > TABLET_IMAGE_WIDTH
        ? TABLET_IMAGE_HEIGHT
        : clamp(imageWidth / TABLET_ASPECT_RATIO, 0, TABLET_IMAGE_HEIGHT);
    const paddingBottom =
      height - PAGE_BUILDER_PADDING <= tabletHeight
        ? bezel - clamp(tabletHeight - height + PAGE_BUILDER_PADDING, 0, bezel)
        : padding;
    const verticleBezels = // 1 when the bottom bezel is completely hidden
      height - PAGE_BUILDER_PADDING + bezel < tabletHeight ? 1 : 2;
    const scrollbarHeight =
      verticleBezels === 1
        ? height - bezel - PAGE_BUILDER_PADDING - TABLET_CURVATURE_HEIGHT
        : tabletHeight - 2 * bezel - 2 * PAGE_BUILDER_PADDING;

    useSyncScroll(useRef([scrollRef, scrollbarRef]), {
      vertical: true,
    });

    const refCallback = useCallback(
      (node) => {
        scrollbarRef.current = node;
        setCanvasScrollContainer(node);

        let timeoutId = null;

        const onscroll = (ev) => {
          const isScrolledTop = ev.target.scrollTop === 0;
          const isScrolledBottom =
            ev.target.scrollHeight ===
            ev.target.scrollTop + ev.target.clientHeight;

          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          if (!isScrolledTop && !isScrolledBottom) {
            setScrolling(true);
          }

          timeoutId = setTimeout(() => setScrolling(false), 50);
        };

        if (node) {
          node.addEventListener('scroll', onscroll);
        }

        return () => {
          scrollbarRef.current.removeEventListener('scroll', onscroll);
        };
      },
      [setCanvasScrollContainer, setScrolling]
    );

    const scrollFadeRefCallback = useCallback(
      (node) => {
        scrollFadeRef(node);
        screenMeasureRef(node);
      },
      [scrollFadeRef, screenMeasureRef]
    );

    const canvasWrapperRefCallback = useCallback(
      (node) => {
        if (node) {
          setCanvasWrapper(node);
        }
      },
      [setCanvasWrapper]
    );

    const mergeRef = useCallback(
      (node) => {
        canvasMeasureRef(node);
        applyRef(ref, node);
      },
      [ref, canvasMeasureRef]
    );

    return (
      <CanvasMeasureContainer ref={mergeRef} onClick={clearContentSettingsTray}>
        <CanvasWrapper
          ref={canvasWrapperRefCallback}
          removePaddingBottom
          width={screenWidth}
          paddingTop={paddingTop}
        >
          <TabletWrapper
            height={tabletHeight}
            hasScrollbar={hasScrollbar}
            bezel={bezel}
            screenWidth={screenWidth}
            padding={padding}
            paddingBottom={paddingBottom}
          >
            <StyledScrollFadeContainer
              ref={scrollFadeRefCallback}
              scrolled={scrolled}
              fullscreen={paddingBottom > 0}
              borderRadius={borderRadius}
              top
              bottom
            >
              <Content
                ref={scrollRef}
                className="ContentForSyncWidth"
                disabled={disabled}
              >
                {children}
              </Content>
            </StyledScrollFadeContainer>
          </TabletWrapper>
          <StyledScrollbarDetached
            ref={refCallback}
            direction="vertical"
            scrollClassName="ContentForSyncWidth"
            height={scrollbarHeight}
            bezel={bezel}
          />
        </CanvasWrapper>
      </CanvasMeasureContainer>
    );
  }
);

TabletCanvas.displayName = 'TabletCanvas';
