import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToolbarService from 'services/ToolbarService';
import { StyledModal } from './styles';
import { getSortValue } from 'utility/SortingHelpers';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { getCanView } from 'components/AccessRequests/utils';

export const DEFAULT_TEMPLATE_ID = 'default';
const TOP_PADDING = 12;

const wrappedTemplateTypeahead = (
  serviceToUse,
  fetchProps,
  hasDefaultTemplate,
  t
) => {
  return async ({ page, search }) => {
    const response = await serviceToUse.listTemplates(
      page,
      20,
      getSortValue('name'),
      search,
      ...fetchProps
    );
    const options = (response?.results ?? [])
      .filter(getCanView)
      .sort((a, b) => a.name.localeCompare(b.name));

    const defaultName = t('Default Template');

    if (
      hasDefaultTemplate &&
      (page === 1 || !page) &&
      (!search || defaultName.toLowerCase().includes(search.toLowerCase()))
    ) {
      options.splice(0, 0, {
        name: defaultName,
        id: DEFAULT_TEMPLATE_ID,
      });
    }

    return { ...response, results: options };
  };
};

export const ChooseTemplateModal = ({
  onConfirm,
  disabled,
  serviceToUse = ToolbarService,
  fetchProps = EMPTY_ARRAY,
  hasDefaultTemplate = true,
  ...others
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const { t } = useTranslation();

  const selectRef = useRef(null);

  const fetch = useMemo(
    () =>
      wrappedTemplateTypeahead(serviceToUse, fetchProps, hasDefaultTemplate, t),
    [t, serviceToUse, fetchProps, hasDefaultTemplate]
  );

  const [typeaheadProps, typeaheadState] = useSelectTypeaheadWithScroll({
    entity: Entities.ToolbarTemplates,
    fetch,
    selectRef,
    shouldRefetchOnMount: true,
  });

  const handleConfirm = useCallback(() => {
    onConfirm?.(selectedTemplate?.value);
  }, [onConfirm, selectedTemplate]);

  return (
    <StyledModal
      {...others}
      onConfirm={handleConfirm}
      heading={t('Choose Template to Apply')}
      disabled={!selectedTemplate?.value || disabled}
      leftBtn={false}
      buttonText={t('Apply')}
    >
      <StyledWizardField top={0} flex>
        <KizenTypography>{t('Template Name')}</KizenTypography>
        <StyledWizardField top={TOP_PADDING} flex>
          <Select
            ref={selectRef}
            value={selectedTemplate}
            onChange={setSelectedTemplate}
            placeholder={t('Choose Template')}
            {...typeaheadProps}
            isLoading={typeaheadState.loading}
            captureMenuScroll={false}
            inModal
            menuInline
            fullWidth
          />
        </StyledWizardField>
      </StyledWizardField>
    </StyledModal>
  );
};
