import React, { useCallback } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { HTMLBlockContainer } from '@kizen/page-builder/nodes/containers';
import { SharedViewBuilderHTMLBlock } from '@kizen/page-builder/nodes/HTMLBlock';
import { useAttachListener } from 'hooks/useAttachListener';
import {
  useBuilderContext,
  useIsInBuilderContext,
} from 'components/PageBuilder/BuilderContext';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { useDraggingType } from '../useDraggingType';
import { useShowControls } from '../useShowControls';
import Control from '../Control';

const BuilderHTMLBlock = styled(SharedViewBuilderHTMLBlock)`
  a {
    cursor: default;
    pointer-events: none;
  }
`;

export const HTMLBlock = (props) => {
  const { t } = useTranslation();
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
  }));
  const {
    id,
    connectors: { connect },
  } = useNode((node) => ({
    id: node.id,
  }));
  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const { activeNode } = useBuilderContext();
  const isActiveNode = activeNode?.id === id;

  const clickListener = useCallback(
    (ev) => {
      // The default behavior of the page builder is to open/close the settings for an element when clicking on it in the canvas.
      // This event listener ensures that the settings tray is not closed when interacting with input elements of the HTML Block.
      if (isActiveNode && ev.path[0]?.nodeName === 'INPUT') {
        ev.stopPropagation();
      }
    },
    [isActiveNode]
  );

  const ref = useAttachListener(clickListener, 'click');
  const isInBuilderContext = useIsInBuilderContext();

  return (
    <Control
      ref={connect}
      label={t('Content')}
      show={showControls}
      color={colorsPrimary.green.dark}
      mayHaveChildren={false}
      fullWidth={true}
      {...props}
    >
      <HTMLBlockContainer
        ref={ref}
        draggingType={draggingType}
        isInBuilderContext={isInBuilderContext}
        {...props}
      >
        <BuilderHTMLBlock {...props} />
      </HTMLBlockContainer>
    </Control>
  );
};

export const ViewHTMLBlock = (props) => {
  return (
    <HTMLBlockContainer {...props}>
      <SharedViewBuilderHTMLBlock {...props} />
    </HTMLBlockContainer>
  );
};
