import { memo } from 'react';
import { getSmoothStepPath, EdgeText } from 'reactflow';
import { gutters } from 'app/spacing';
import { colorsButton, grayScale } from 'app/colors';
import { fontWeights } from 'app/typography';

// This is disabled because effectively this is a passthrough to react-flow's
// getSmoothStepPath() internals, and this component is rendered by react-flow.
export default memo(
  ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    sourcePosition,
    targetPosition,
    label,
    data,
    markerEnd,
    markerStart,
  }) => {
    // This matches the designs, edge bends after 2 spacing units
    const offset = gutters.spacing(2, -2);
    const centerX = sourceX + (sourceX < targetX ? 1 : -1) * offset;

    const [path] = getSmoothStepPath({
      sourceX,
      centerX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      borderRadius: 0,
      offset,
    });

    // In the react-flow source there is an example of UI for
    // for edge text, which we may want to reference.

    // Centered no further than 30px, even if the source and target are further apart
    const textCenterSign =
      (sourceY < targetY && 1) || (sourceY > targetY && -1) || 0;
    const textCenterY =
      sourceY +
      textCenterSign *
        Math.min(gutters.spacing(6, -1), Math.abs(sourceY - targetY) / 2);
    const padding = { x: 4, y: 2 };

    return (
      <>
        <path
          style={style}
          className="react-flow__edge-path"
          d={path}
          markerEnd={markerEnd}
          markerStart={markerStart}
        />
        {label && (
          <EdgeText
            x={centerX}
            y={textCenterY}
            label={
              <>
                <tspan x="0" style={{ fontSize: 'inherit' }}>
                  {label}
                </tspan>
                <tspan
                  x="0"
                  textLength={`${gutters.spacing(4, -2 * padding.x)}px`}
                  style={{ fontSize: 'inherit' }}
                >
                  {/* This ensures that the label has a max with of 30px. */}
                  {/* We need two characters so that they can be */}
                  {/* spaced-apart from each other. These are nbsps. */}
                  {'\xa0\xa0'}
                </tspan>
              </>
            }
            labelBgPadding={[padding.x, padding.y]}
            labelStyle={{
              fill: grayScale.white,
              fontWeight: fontWeights.bold,
              textTransform: 'uppercase',
              fontSize: '.7em', // Just a one-off size, not part of the style guide
              transformBox: 'fill-box',
              // Below are some fun SVG flips to center the text
              textAnchor: 'middle',
              transform: `rotate(-90deg) translate(50%)`,
              transformOrigin: 'right center',
            }}
            labelBgStyle={{
              fill: (data && data.color) || colorsButton.green.hover,
              transformBox: 'fill-box',
              transform: 'rotate(-90deg)',
              transformOrigin: 'center',
            }}
            labelBgBorderRadius={2}
          />
        )}
      </>
    );
  }
);
