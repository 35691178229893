export const FIELD_NAMES = {
  name: 'name',
  description: 'description',
  frequency: 'frequency',
  frequencyDisplay: 'frequencyDisplay',
  autoSubscribe: 'autoSubscribe',
};

export const SUBSCRIPTION_FIELDS = {
  [FIELD_NAMES.name]: {
    required: true,
  },
  [FIELD_NAMES.description]: {
    required: false,
  },
  [FIELD_NAMES.frequency]: {
    required: false,
  },
  [FIELD_NAMES.frequencyDisplay]: {
    required: false,
  },
  [FIELD_NAMES.autoSubscribe]: {
    required: false,
  },
};

export const REQUIRED_FIELDS = Object.entries(SUBSCRIPTION_FIELDS)
  .filter(([, { required }]) => required)
  .map(([key]) => key);

export const FORM_VALUES = Object.keys(SUBSCRIPTION_FIELDS).reduce(
  (collect, el) => ({ ...collect, [el]: '' }),
  {}
);
