import React from 'react';
import UnderlineLongText from './Underline';
import OutlineLongText from './Outline';
import { variantProp } from '../props';

const LongText = React.forwardRef(({ variant, ...props }, ref) => {
  const VariantLongText =
    variant === 'underline' ? UnderlineLongText : OutlineLongText;
  return <VariantLongText ref={ref} {...props} />;
});

export default LongText;

LongText.displayName = 'LongText';
LongText.propTypes = {
  variant: variantProp,
};
LongText.defaultProps = {
  variant: 'outline',
};
