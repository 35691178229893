import styled from '@emotion/styled';
import { Row } from 'react-bootstrap';
import { colorsButton, grayScale, colorsText, colorsPrimary } from 'app/colors';
import { fontSizes, fontWeights } from 'app/typography';
import { breakpoints, gutters } from 'app/spacing';

const lgBreakpoint = `@media(min-width: ${breakpoints.lg}px)`;

export const StyledLabel = styled.label(
  {
    cursor: 'pointer',
    textAlign: 'center',
    'div.child,div.child.chartType': {
      ':hover': {
        'p,svg': {
          color: colorsButton.blue.hover,
        },
      },
    },
    '&.shrink-gutter': {
      paddingRight: gutters.spacing(1),
      paddingLeft: gutters.spacing(1),
      ':first-child': {
        paddingLeft: gutters.spacing(3),
      },
      ':last-child': {
        paddingRight: gutters.spacing(3),
      },
    },
  },
  ({ variant }) => {
    const variants = {
      content: {
        input: {
          display: 'none',
        },
        'div.child': {
          border: '1px solid',
          borderRadius: '4px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '3rem 2rem',
          height: '100%',
          img: {
            width: '100%',
          },
          'svg,div[value="pipeline"]>div': {
            height: '25px',
            width: '25px',
          },
          'span,p,h1,h2,h3,h4,h5,h6': {
            marginTop: '1rem',
            fontSize: fontSizes.text,
          },
        },

        'div.child.chartType': {
          padding: 5,
          p: {
            color: colorsText.dark,
            fontWeight: fontWeights.bold,
            fontSize: fontSizes.small,
            marginBottom: 0,
            marginTop: 5,
          },
          [lgBreakpoint]: {
            padding: gutters.standard,
            p: {
              marginTop: gutters.standard,
              fontSize: fontSizes.text,
            },
          },
          ':hover': {
            borderColor: colorsButton.blue.hover,
            'p,svg': {
              color: 'unset',
            },
          },
        },

        '.select-box': {
          padding: 5,
          p: {
            color: colorsText.dark,
            fontWeight: fontWeights.bold,
          },
        },
      },
      button: {
        padding: '0',
        marginRight: '15px',
        input: {
          display: 'none',
        },
        'div.child': {
          border: '1px solid',
          borderColor: colorsButton.blue.default,
          borderRadius: '8px',
          padding: '1.1rem 1rem 0.9rem',
          letterSpacing: '0.5px',

          p: {
            color: colorsButton.blue.default,
            fontWeight: fontWeights.bold,
            textTransform: 'uppercase',
            fontSize: fontSizes.button,
          },
        },
      },
      radio: {
        display: 'flex',
        input: {
          borderRadius: '100%',
          marginRight: '0.5rem',
          outline: 'none',
          boxShadow: '0px 1px 2px black',
        },
      },
    };
    return variants[variant] || variants.radio;
  },
  ({ checked, variant }) => {
    if (checked) {
      if (variant === 'button') {
        return {
          'div.child': {
            backgroundColor: colorsButton.blue.default,
            p: {
              color: 'white',
            },

            ':hover': {
              backgroundColor: colorsButton.blue.hover,
              p: {
                color: 'white',
              },
            },
          },
        };
      }

      if (variant === 'select-box') {
        return {
          'div.child': {
            div: {
              'p, svg': {
                color: grayScale.dark,
              },
            },
            ':hover': {
              div: {
                'p, svg': {
                  color: grayScale.dark,
                },
              },
              '.selected': {
                'p, svg': {
                  color: colorsButton.blue.hover,
                },
              },
            },
            '.selected': {
              'p, svg': {
                color: colorsButton.blue.default,
              },
            },
          },

          '.select-button': {
            backgroundColor: colorsButton.blue.default,
            color: 'white',
            ':hover': {
              backgroundColor: colorsButton.blue.hover,
              color: 'white',
            },
          },
        };
      }

      if (variant === 'content') {
        return {
          'div.child, div.child.chartType': {
            color: colorsPrimary.blue.dark,
            'p,svg': {
              color: colorsPrimary.blue.dark,
            },
          },
        };
      }

      return {
        'div.child, div.child.chartType': {
          color: colorsButton.blue.default,
          'p,svg': {
            color: colorsButton.blue.default,
          },
        },
      };
    } else {
      if (variant === 'select-box') {
        return {
          'div.child': {
            div: {
              'p, svg': {
                color: grayScale.dark,
              },
            },
            ':hover': {
              div: {
                'p, svg': {
                  color: colorsButton.blue.hover,
                },
              },
            },
          },

          '.select-button': {
            color: colorsButton.blue.default,
            ':hover': {
              color: colorsButton.blue.hover,
            },
          },
        };
      }
      return {
        'div.child': {
          borderColor:
            variant === 'button'
              ? colorsButton.blue.default
              : grayScale.mediumDark,

          'p,svg': {
            color:
              variant === 'button'
                ? colorsButton.blue.default
                : grayScale.mediumDark,
          },
        },
      };
    }
  }
);

export const StyledRow = styled(Row)(
  {
    marginBottom: '2rem',
  },
  ({ variant, noMargin }) => {
    const variants = {
      content: {},
      button: {},
      radio: {},
    };
    const style = variants[variant] || variants.radio;

    if (noMargin) {
      style.margin = 0;
    }

    return style;
  }
);
