import { useCallback, useState, useEffect, useReducer, useMemo } from 'react';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import { CUSTOM_OBJECTS } from 'queries/query-keys';
import FieldService from 'services/FieldService';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';
import Loader from 'components/Kizen/Loader';
import { isPipelineObject } from 'components/Modals/utilities';
import { FIELD_TYPES } from 'utility/constants';
import FieldSettings from './sections/settings';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

export const CenteredLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 0 !important;
  transform: translate(-50%, -50%);
`;

export const EMPTY_ARRAY = [];

export const FORM_ACTIONS = {
  update: 'UPDATE',
  reset: 'RESET',
};

const fieldInit = ({ category, field = {} }) => ({
  displayName: field.displayName || '',
  name: field.name,
  isRequired: field.isRequired || false,
  category: category || null,
  type: field.fieldType || '',
  options: field.options || [],
  phonenumberOptions: field.phonenumberOptions || null,
  moneyOptions: field.moneyOptions || null,
  rating: field.rating || null,
  enableMaybe: field.fieldType === FIELD_TYPES.YesNoMaybe.type,
  relation: field.relation || null,
  meta: field.meta || {},
});

// Form state for the entire wizard is managed here
export const fieldReducer = (state, { type, payload }) => {
  switch (type) {
    case FORM_ACTIONS.update: {
      // Updates formData, which triggers the effect below
      // that calls onChange with the new data
      const { field, value } = payload;
      return { ...state, [field]: value }; // allow new item in payload to null out fields
    }
    case FORM_ACTIONS.reset: {
      const { data, exceptions = [] } = payload;
      if (exceptions && exceptions.length) {
        const keep = exceptions.reduce((collect, key) => {
          if (key in state) {
            return {
              ...collect,
              [key]: state[key],
            };
          }

          return collect;
        }, {});
        return {
          ...fieldInit(data),
          ...keep,
        };
      }
      return fieldInit(data);
    }
    default:
      throw new Error('Unknown action');
  }
};

export const STEP_ACTIONS = {
  validate: 'VALIDATE',
};

// Step components can register their status using this function
// This allows an arbitrary number of steps without requiring us to keep track of what they are here
// A step can pass null to let the wizard know to stop tracking its status
export const stepValReducer = (state, { type, payload }) => {
  switch (type) {
    case STEP_ACTIONS.validate: {
      const { step, isValid } = payload;
      if (step in state && state[step] === isValid) {
        return state;
      }

      if (!(step in state) && isValid === null) {
        return state;
      }

      const nextStepValidation = {
        ...state,
        [step]: isValid,
      };

      Object.keys(nextStepValidation).forEach(
        (k) => nextStepValidation[k] === null && delete nextStepValidation[k]
      );

      return nextStepValidation;
    }
    default:
      throw new Error('Unknown step validation action');
  }
};

export function useFieldSettings(data) {
  const [formData, dispatchFormData] = useReducer(
    fieldReducer,
    data,
    fieldInit
  );

  useEffect(() => {
    dispatchFormData({ type: FORM_ACTIONS.reset, payload: { data } });
  }, [dispatchFormData, data]);

  const updateField = useCallback(
    (f, value) => {
      dispatchFormData({
        type: FORM_ACTIONS.update,
        payload: { field: f, value },
      });
    },
    [dispatchFormData]
  );

  const resetField = useCallback(
    (exceptions) => {
      dispatchFormData({
        type: FORM_ACTIONS.reset,
        payload: { data, exceptions },
      });
    },
    [data]
  );

  const [stepValidation, dispatchStepValidation] = useReducer(
    stepValReducer,
    {}
  );
  const updateValidation = useCallback(
    (step, isValid = null) => {
      dispatchStepValidation({
        type: STEP_ACTIONS.validate,
        payload: { step, isValid },
      });
    },
    [dispatchStepValidation]
  );

  // Lets the parent component know the wizard is complete
  const isComplete = Object.keys(stepValidation).every(
    (step) => !!stepValidation[step] || stepValidation[step] === null
  );

  return {
    formData,
    updateField,
    resetField,
    updateValidation,
    isComplete,
  };
}

const FieldWizard = ({ onChange, data = EMPTY_OBJECT, ...others }) => {
  const { formData, updateField, resetField, updateValidation, isComplete } =
    useFieldSettings(data);
  const [categories, setCategories] = useState(DROPDOWN_SHOULD_LOAD);

  const isClient = useMemo(() => !data.model?.isCustom, [data]);

  const {
    data: fetchedCategories = DROPDOWN_SHOULD_LOAD,
    isLoading: loading,
    refetch,
  } = useQuery(CUSTOM_OBJECTS.CATEGORIES(data.model.id), async () => {
    if (!isClient) {
      return isPipelineObject(data.model)
        ? PipelineService.getObjectCategories(data.model.id)
        : CustomObjectsService.getObjectCategories(data.model.id);
    }
    return FieldService.getCategories({ for: 'contacts' });
  });

  useEffect(() => {
    refetch();
  }, [data.model.id, refetch]);

  useEffect(() => {
    setCategories(fetchedCategories);

    if (
      data.category &&
      Array.isArray(fetchedCategories) &&
      fetchedCategories.length &&
      !loading
    ) {
      const activeCategory = fetchedCategories.find(
        (cat) => cat.id === data.category
      );
      if (activeCategory) {
        updateField('category', {
          value: activeCategory.id,
          label: activeCategory.name,
        });
      }
    }
  }, [data, fetchedCategories, updateField, loading]);

  // Fires whenever local form state changes, with the onChange handler
  // allowing the parent to see and react to those changes
  useEffect(() => {
    onChange({ data: formData, isComplete });
  }, [formData, isComplete]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading && !Array.isArray(categories)) {
    return <CenteredLoader loading />;
  }

  // This is the initial step of your wizard
  // Subsequent steps will be defined within each step
  return (
    <FieldSettings
      updateField={updateField}
      resetField={resetField}
      updateValidation={updateValidation}
      formData={formData}
      categories={categories}
      data={data}
      {...others}
    />
  );
};

export default FieldWizard;
