import { useInfiniteQuery } from 'react-query';
import TimelineService from 'services/TimelineService';
import { timelineEventsMapper } from './EventBlocks';
import { useTranslation } from 'react-i18next';
import { buildFallbackQueryKey } from './util';

// translation from timeline to window scroll format
const translateDown = ({ count, events, next_cursor, prev_cursor }, t) => ({
  count,
  items: events.map((item, index) => ({
    index,
    ...timelineEventsMapper(item, t), // ideally should be mapper by a dto layer
  })),
  next_cursor,
  prev_cursor,
});

const useTimelineQuery = ({
  id,
  size = 20,
  filters,
  params,
  searchTerm,
  enabled = true,
  keepPreviousData = true,
  queryKey: queryKeyOverride,
}) => {
  const { t } = useTranslation();

  const infiniteQuery = useInfiniteQuery({
    // Do not change this query key structure without also changing it in useTimelineComment
    queryKey:
      queryKeyOverride ||
      buildFallbackQueryKey({
        id,
        searchTerm,
        size,
        filters,
        params,
      }),
    queryFn: async ({ pageParam: cursor = null }) => {
      const response = await TimelineService.getPagedTimelineRecords(
        id,
        {
          ...{ ...(cursor && { cursor }) },

          size,
          ...(params.event_id ? params : {}),
          ...filters,
        },
        true,
        searchTerm
      );

      return translateDown(response, t);
    },

    getNextPageParam: (lastPage) => {
      const pageParam = lastPage.next_cursor;
      return pageParam;
    },
    getPreviousPageParam: (previousPage) => {
      const pageParam = previousPage.prev_cursor;
      return pageParam;
    },
    retry: false,
    useErrorBoundary: false,
    enabled,
    keepPreviousData,
  });

  return infiniteQuery;
};

export default useTimelineQuery;
