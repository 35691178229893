import FieldService from 'services/FieldService';

export const getSelectedOptions = (
  reasonsDialogData,
  reasonDisqualifiedField
) =>
  reasonsDialogData.map(({ record, stageId }) => ({
    selected: FieldService.getFieldValue(record, reasonDisqualifiedField, true),
    stageId,
    recordId: record.id,
  }));
