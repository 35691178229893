import { useEffect, useMemo, useRef, useCallback, forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { useFlashTransition } from 'hooks/useFlashState';
import FieldService from 'services/FieldService';
import { gutters } from 'app/spacing';
import {
  getAdditionalFieldProps,
  isEmailStatusField,
  isStageField,
} from 'checks/fields';
import FileInput from 'components/Inputs/FileInput';
import CheckboxGroup, { CheckboxSingle } from 'components/Inputs/CheckboxGroup';
import TextInput from 'components/Inputs/TextInput';
import MaskedTextInput from 'components/Inputs/TextInput/Masked';
import { ValidatedWholeNumberTextInput } from 'components/Inputs/TextInput/presets/WholeNumber';
import LongText from 'components/Inputs/LongText';
import MaskedLongText from 'components/Inputs/LongText/Masked';
import RadioGroup from 'components/Inputs/RadioGroup';
import EmailAddressTextInput from 'components/Inputs/TextInput/presets/EmailAddress';
import { ValidatedDecimalNumberTextInput } from 'components/Inputs/TextInput/presets/DecimalNumber';
import { ValidatedPriceNumberTextInput } from 'components/Inputs/TextInput/presets/PriceNumber';
import Rating from 'components/Inputs/Rating';
import DateInput from 'components/Inputs/DateInput';
import DateTimeInput from 'components/Inputs/DateTimeInput';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { integerToOption, optionToInteger } from 'components/helpers';
import { TransformToSelectOptions } from 'utility/TransformToSelectOptions';
import { FIELD_TYPES } from 'utility/constants';
import {
  dummyAccessObject,
  FIELD_TYPE_STAGE_DROPDOWN,
  getFieldAccessPermissions,
} from 'components/Fields/FieldInput/helpers';
import SelectTeamMember from './SelectTeamMember';
import RelationshipOne, { RelationshipOneWithOptions } from './RelationshipOne';
import RelationshipMany from './RelationshipMany';
import DynamicTags from './DynamicTags';
import Dropdown from './Dropdown';
import YesNoMaybe from './YesNoMaybe';
import PhoneNumber from './PhoneNumber';
import Timezone from './Timezone';
import {
  getCurrencySymbol,
  isEmailStatusImmutable,
  isSummarizedValue,
} from '../helpers';
import { transformDateDown, transformDateUp } from './helpers';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import LibraryMessageInput from 'components/Inputs/LibraryMessageInput';
import { applyRef } from 'components/Inputs/props';
import SummarizedMultiSelect from 'components/Inputs/SummarizedMultiselect';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

// TODO We need a quick fix for the fact that components don't have standard bottom spacing,
// yet we need them to appear uniformly in this form. So we'll make some exceptions up here and apply them below.

const BottomMarginFix = styled(StylePassthrough)`
  ${({ margin }) =>
    margin &&
    css`
      margin-bottom: ${gutters.spacing(3, -5)}px;
    `}
  // Most of the time legacy input components have labels
  // that provide some amount of bottom margin,
  // which we want to just remove for now and rely entirely
  // on the wrapper's margin (set above).
  ${({ collapseLabel }) =>
    collapseLabel &&
    css`
      label {
        margin-bottom: 0;
      }
    `}
`;

const FieldInputRenderFunc = (
  {
    object = dummyAccessObject,
    field,
    fetchUrl,
    value,
    initialValue,
    onChange,
    isGetFullObject = false,
    label = '',
    error = null,
    validateSettings = EMPTY_OBJECT,
    serviceToUse = null,
    objectId = null,
    fullWidth,
    stages,
    onMenuOpen, // react-select specific
    onMenuClose, // react-select specific
    handleUpdateFieldOption,
    disableOldValidation = false,
    ...others
  },
  ref
) => {
  const { t } = useTranslation();
  const fieldRef = useRef(null);
  const [message, showMessage, flash] = useFlashTransition();
  const validate = {
    message,
    showMessage,
    ...validateSettings,
  };

  useEffect(() => {
    if (disableOldValidation) return;
    if (error && error.message) {
      flash(error.message);
      if (fieldRef.current?.scrollIntoView) {
        fieldRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }

      // If the field is a select, we need to get the input ref because
      // react-select adds a bunch of stuff to the ref that it's passed
      if (fieldRef.current?.select?.inputRef?.scrollIntoView) {
        fieldRef.current.select.inputRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [error, flash, disableOldValidation]);

  const mergeRef = useCallback(
    (el) => {
      applyRef(fieldRef, el);
      applyRef(ref, el);
    },
    [ref]
  );

  const { fieldType, displayName, meta } = field;

  const [editable, viewable] = useMemo(
    () => getFieldAccessPermissions(field, object),
    [field, object]
  );
  // to hide the label set it to false
  others.label = label === false ? false : label || displayName;
  others.disabled =
    others.disabled || !editable || isEmailStatusImmutable(object, field);
  others.viewable = viewable;
  if (!viewable) return null;
  if (fieldType === FIELD_TYPES.Text.type && meta?.isMasked) {
    return (
      <MaskedTextInput
        ref={mergeRef}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        truncate={!meta?.isMasked}
        displayMaskedValue={meta?.isMasked}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
        placeholder={
          others.placeholder || FIELD_TYPES.Text.localizedPlaceholder(t)
        }
      />
    );
  }
  if (fieldType === FIELD_TYPES.Text.type) {
    return (
      <TextInput
        ref={mergeRef}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
        placeholder={
          others.placeholder || FIELD_TYPES.Text.localizedPlaceholder(t)
        }
      />
    );
  }
  if (fieldType === FIELD_TYPES.Email.type) {
    return (
      <EmailAddressTextInput
        ref={mergeRef}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.PhoneNumber.type) {
    return (
      <PhoneNumber
        ref={mergeRef}
        field={field}
        // TODO: remove it once we migrate our DB and update our API do not send object anymore.
        value={
          value?.formatted ??
          value?.formattedNationalNumber ??
          value?.formatted_national_number ??
          value?.e164 ??
          value ??
          ''
        }
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        object={object}
        objectId={objectId}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Integer.type) {
    return (
      <ValidatedWholeNumberTextInput
        ref={mergeRef}
        value={value || value === 0 ? value : ''}
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        {...getAdditionalFieldProps(field, others.info, others.placeholder)}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Decimal.type) {
    return (
      <ValidatedDecimalNumberTextInput
        ref={mergeRef}
        value={value || value === 0 ? value : ''}
        onChange={onChange}
        validate={validate}
        error={error}
        decimalOptions={field.decimalOptions}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Money.type) {
    return (
      <ValidatedPriceNumberTextInput
        ref={mergeRef}
        value={value || value === 0 ? value : ''}
        onChange={onChange}
        currencySymbol={getCurrencySymbol(field)}
        validate={validate}
        error={error}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.LongText.type && meta?.isMasked) {
    return (
      <MaskedLongText
        ref={mergeRef}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        displayMaskedValue={meta?.isMasked}
        truncate={!meta?.isMasked}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
        placeholder={
          others.placeholder || FIELD_TYPES.LongText.localizedPlaceholder(t)
        }
      />
    );
  }
  if (fieldType === FIELD_TYPES.LongText.type) {
    return (
      <LongText
        ref={mergeRef}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
        placeholder={
          others.placeholder || FIELD_TYPES.LongText.localizedPlaceholder(t)
        }
      />
    );
  }
  if (fieldType === FIELD_TYPES.Date.type) {
    return (
      <DateInput
        ref={mergeRef}
        value={transformDateDown(value)}
        onChange={(val) => {
          onChange(transformDateUp(val));
        }}
        error={error}
        validate={validate}
        fieldType={fieldType}
        {...others}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(transformDateUp());
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        fullWidth={fullWidth}
        {...(disableOldValidation && { required: field.isRequired })}
      />
    );
  }
  if (fieldType === FIELD_TYPES.DateTime.type) {
    return (
      <DateTimeInput
        ref={mergeRef}
        error={error}
        value={value ? new Date(value) : null}
        onChange={(val) => onChange(val ? val.toISOString() : null)}
        validate={validate}
        showDateTooltip={field.isDefault && others.variant === 'underline'}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Relationship.type) {
    if (isSummarizedValue(value)) {
      return (
        <SummarizedMultiSelect
          ref={mergeRef}
          field={field}
          value={value}
          entity={object}
          onChange={onChange}
          validate={validate}
          error={error}
          fieldType={fieldType}
          {...(disableOldValidation && { required: field.isRequired })}
          {...others}
        />
      );
    }
    if (FieldService.getAllowMultiple(field)) {
      return (
        <RelationshipMany
          ref={mergeRef}
          field={field}
          entity={object}
          value={value}
          onChange={onChange}
          validate={validate}
          error={error}
          fieldType={fieldType}
          {...(disableOldValidation && { required: field.isRequired })}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          {...others}
        />
      );
    }
    const RelationshipOneComponent = field?.options?.length
      ? RelationshipOneWithOptions
      : RelationshipOne;
    return (
      <RelationshipOneComponent
        ref={mergeRef}
        field={field}
        value={value?.[0] ?? value} // allow multiple is false, so if the value is an array take the first element
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.DynamicTags.type) {
    return (
      <DynamicTags
        ref={mergeRef}
        field={field}
        value={value}
        onChange={onChange}
        fetchUrl={fetchUrl}
        validate={validate}
        serviceToUse={serviceToUse}
        objectId={objectId}
        error={error}
        handleUpdateFieldOption={handleUpdateFieldOption}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Checkbox.type) {
    return (
      <CheckboxSingle
        ref={mergeRef}
        checked={value}
        onChange={onChange}
        validate={validate}
        error={error}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Checkboxes.type) {
    return (
      <CheckboxGroup
        ref={mergeRef}
        onChange={(val) => {
          // API expects only option UUIDs for checkbox field submissions
          onChange(val.map(({ value: id }) => id));
        }}
        options={TransformToSelectOptions(field.options, 'id', 'name')}
        value={value}
        validate={validate}
        error={error}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange([]);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        showMenuLeftButton
        showMenuRightButton
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Radio.type) {
    return (
      <RadioGroup
        ref={mergeRef}
        onChange={(res) => {
          // API expects only option UUID for radio field submissions
          onChange(res?.value ?? null);
        }}
        options={TransformToSelectOptions(field.options, 'id', 'name')}
        value={value}
        validate={validate}
        error={error}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        showMenuLeftButton
        showMenuRightButton
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.YesNoMaybe.type) {
    return (
      <YesNoMaybe
        ref={mergeRef}
        field={field}
        value={value}
        error={error}
        onChange={onChange}
        validate={validate}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        showMenuLeftButton
        showMenuRightButton
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (
    fieldType === FIELD_TYPES.Choices.type ||
    fieldType === FIELD_TYPES.Dropdown.type ||
    fieldType === FIELD_TYPES.Status.type ||
    fieldType === FIELD_TYPE_STAGE_DROPDOWN
  ) {
    const additionalProps = isEmailStatusField(field)
      ? { menuLeftButton: null, menuRightButton: null }
      : {};

    return (
      <Dropdown
        ref={mergeRef}
        field={field}
        value={value}
        onChange={onChange}
        error={error}
        validate={validate}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        {...(isStageField(field) && {
          menuLeftButton: null,
        })}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
        {...additionalProps}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Rating.type) {
    return (
      <Rating
        ref={mergeRef}
        allowDynamicVariant={false}
        maxRating={field.rating.maxValue}
        lowLabel={field.rating.minLabel}
        highLabel={field.rating.maxLabel}
        value={integerToOption(value)}
        error={error}
        onChange={(val) => onChange(optionToInteger(val))}
        validate={validate}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        showMenuLeftButton
        showMenuRightButton
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Files.type) {
    const { variant, margin, isActivityField, ...fileInputProps } = others;
    return (
      <BottomMarginFix
        margin={margin && !isActivityField}
        collapseLabel
        ref={mergeRef}
      >
        <FileInput
          label="File Input"
          files={value || []}
          initialFiles={initialValue}
          error={error}
          onChange={(val) => {
            // eslint-disable-next-line array-callback-return,consistent-return
            const idArray = (val || []).map((x) => {
              if (typeof x === 'string') return x;
              if (isGetFullObject) return x;
              if (x.id) return x.id;
              return null;
            });
            onChange(idArray);
          }}
          variant={variant === 'underline' ? 'underline' : 'outline'}
          validate={validate}
          {...fileInputProps}
          fieldType={fieldType}
          field={field}
          margin={isActivityField}
          {...(disableOldValidation && { required: field.isRequired })}
        />
      </BottomMarginFix>
    );
  }
  if (fieldType === FIELD_TYPES.LibraryMessage.type) {
    const { variant, margin } = others;
    return (
      <BottomMarginFix margin={margin} collapseLabel ref={mergeRef}>
        <LibraryMessageInput
          label="Library Message"
          message={value}
          error={error}
          onChange={onChange}
          variant={variant}
          validate={validate}
          {...others}
          fieldType={fieldType}
          {...(disableOldValidation && { required: field.isRequired })}
        />
      </BottomMarginFix>
    );
  }
  if (fieldType === FIELD_TYPES.TeamSelector.type) {
    return (
      <SelectTeamMember
        ref={mergeRef}
        value={value}
        error={error}
        onChange={(val) => {
          onChange(val ? val.data : null);
        }}
        validate={validate}
        {...others}
        placeholder={
          others.placeholder || FIELD_TYPES.TeamSelector.localizedPlaceholder(t)
        }
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        field={field}
        fieldType={fieldType}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        {...(disableOldValidation && { required: field.isRequired })}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Timezone.type) {
    return (
      <Timezone
        ref={mergeRef}
        field={field}
        value={value}
        onChange={onChange}
        error={error}
        validate={validate}
        menuLeftButton={field.isRequired ? null : <ClearSelectButton />}
        menuRightButton={<ApplySelectButton />}
        fieldType={fieldType}
        {...(disableOldValidation && { required: field.isRequired })}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        {...others}
        placeholder={
          others.placeholder ||
          FIELD_TYPES.Timezone.localizedCommonPlaceholder(t)
        }
      />
    );
  }
  return null;
};

// forwardRef render functions do not support propTypes or defaultProps.
const FieldInput = forwardRef(FieldInputRenderFunc);

FieldInput.defaultProps = {
  variant: 'outline',
  margin: null,
  optionsReadyCallback: null,
};

export default FieldInput;
