import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import { AREA_RESPONSES } from 'components/Wizards/RTDV/types';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const SalesArea = ({ reportTypes, reportTypesLoading, disabled }) => {
  const { t } = useTranslation();

  const { state, setState } = useContext(WizardStateContext.Context);

  const { reportType, salesReportType } = state;

  const salesReportTypeValue = useMemo(() => {
    if (salesReportType?.value) {
      return {
        value: salesReportType.value,
        label: reportTypes?.find((p) => p.value === salesReportType?.value)
          ?.label,
      };
    }
    return salesReportType;
  }, [salesReportType, reportTypes]);

  const salesReportTypeOnChange = useCallback(
    (value) => {
      return setState('salesReportType', value);
    },
    [setState]
  );

  // Hide self if area is not metrics
  if (reportType?.value !== AREA_RESPONSES.SALES) {
    return null;
  }

  return (
    <>
      <StyledWizardField>
        <KizenTypography type="subheader">
          {t('Choose Report Type')}
        </KizenTypography>
        <Select
          value={salesReportTypeValue}
          onChange={salesReportTypeOnChange}
          captureMenuScroll={false}
          className="modal-select sales"
          placeholder={t('Choose Report')}
          options={reportTypes}
          fullWidth
          isLoading={reportTypesLoading}
          disabled={disabled}
        />
      </StyledWizardField>
    </>
  );
};

export default SalesArea;
