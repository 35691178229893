import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import useWizardStepController from '../hooks/useWizardStepController';
import useWizardValues from '../hooks/useWizardValues';
import { getDashletFilterCount } from 'pages/Dashboard/util';
import { FILTER_TYPES } from 'ts-filters/legacy';
import { useTranslation } from 'react-i18next';

const WizardStateContext = React.createContext({
  state: {},
  setState: () => {},
  currentStep: 0,
  editMode: false,
  isLoading: false,
  isBlockingLoading: false,
  filterCount: 0,
  filterState: {},
  entityName: '',
  setModalSize: () => {},
  modalSize: 'medium',
  homepage: false,
  model: {},
});

const WizardStateWrapper = forwardRef(
  (
    {
      children,
      existing = {},
      controllers = [],
      setData,
      loading,
      charts,
      setModalSize,
      modalSize,
      model,
      homepage,
    },
    ref
  ) => {
    const [state, setState] = useWizardValues(ref, {
      existing,
      setData,
    });
    const currentStep = useWizardStepController(state, controllers);
    const { t } = useTranslation();

    const isEditMode = useMemo(() => {
      return Object.keys(existing).length > 0;
    }, [existing]);

    const isLoadingState = useMemo(() => {
      if (typeof loading === 'function') {
        return loading(state);
      }
      return loading;
    }, [loading, state]);

    const shouldBlock = useMemo(() => {
      return isEditMode && isLoadingState;
    }, [isLoadingState, isEditMode]);

    const filterCount = useMemo(() => {
      return getDashletFilterCount(state);
    }, [state]);

    const filterState = useMemo(() => {
      if (state.customFilters) {
        return {
          details: {
            type: FILTER_TYPES.CUSTOM,
            groups: [],
            filterConfig: state.customFilters,
          },
        };
      }
      if (state.inGroupFilters?.length > 0) {
        return {
          details: {
            type: FILTER_TYPES.IN_GROUP,
            groups: state.inGroupFilters.map((id) => ({
              id,
            })),
          },
        };
      }
      if (state.notInGroupFilters?.length > 0) {
        return {
          details: {
            type: FILTER_TYPES.NOT_IN_GROUP,
            groups: state.notInGroupFilters.map((id) => ({
              id,
            })),
          },
        };
      }
    }, [state.customFilters, state.inGroupFilters, state.notInGroupFilters]);

    return (
      <WizardStateContext.Provider
        value={{
          state,
          setState,
          currentStep,
          editMode: isEditMode,
          isLoading: isLoadingState,
          isBlockingLoading: shouldBlock,
          filterCount,
          filterState,
          entityName: charts ? t('Chart') : t('Dashlet'),
          setModalSize,
          modalSize,
          model: model ?? state?.fieldObject,
          homepage,
        }}
      >
        {children}
      </WizardStateContext.Provider>
    );
  }
);

WizardStateWrapper.propTypes = {
  children: PropTypes.any,
  existing: PropTypes.object,
  controllers: PropTypes.arrayOf(PropTypes.func),
  setData: PropTypes.func,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  filterState: PropTypes.object,
};

export default {
  Wrapper: WizardStateWrapper,
  Context: WizardStateContext,
};
