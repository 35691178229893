import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useElementSize } from '../hooks/useElementSize';
import { Tab, TabProps } from './Tab';
import { TabGroup } from './TabGroup';
import { Button } from '../Button/Button';
import { Spacer } from '../Spacer/Spacer';
import { merge } from '../util';
import { Menu } from '../Menu/Menu';

export type TabConfig = Omit<TabProps, 'onClick'> & {
  id: string;
};

export interface TabMenuProps {
  tabs?: TabConfig[];
  onChange?: (tab: TabConfig) => void;
  value?: string;
}

export interface VisibilityTrackerProps extends TabMenuProps {
  width: number;
  onChangeVisibility: (visibility: Record<string, boolean>) => void;
}

const VisibilityTracker = (props: VisibilityTrackerProps) => {
  const { tabs = [], width, onChangeVisibility } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibilityState, setVisibilityState] = useState<
    Record<string, boolean>
  >({});
  const [elements, setElements] = useState<Record<string, HTMLDivElement>>({});

  const handleObserve = (element: HTMLDivElement | null, id: string) => {
    if (element) {
      setElements((prev) => {
        if (prev[id]) {
          return prev;
        }

        return {
          ...prev,
          [id]: element,
        };
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        setVisibilityState(
          entries.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.target.getAttribute('data-tab-id') ?? 'unknown']:
                curr.isIntersecting,
            };
          }, {})
        );
      },
      {
        root: containerRef.current,
        threshold: 1,
      }
    );

    Object.values(elements).forEach((element) => {
      observer.observe(element);
    });

    return () => {
      Object.values(elements).forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, [elements, width, tabs]);

  useLayoutEffect(() => {
    onChangeVisibility(visibilityState);
  }, [visibilityState, onChangeVisibility]);

  const hidePreview = true;

  return (
    <div
      className={merge(
        'flex items-center absolute left-0 w-[var(--tracker-width)] select-none pointer-events-none',
        hidePreview ? 'top-[-10000px] opacity-0' : 'top-0 z-[99] opacity-50'
      )}
      style={
        {
          '--tracker-width': `${width}px`,
        } as any
      }
    >
      <div className="w-full overflow-hidden" ref={containerRef}>
        <TabGroup>
          {tabs.map((tab) => {
            return (
              <Tab
                ref={(element) => handleObserve(element, tab.id)}
                key={tab.id}
                {...tab}
                truncate
              />
            );
          })}
        </TabGroup>
      </div>
      <Spacer mode="vertical" size={10} />
      <Button
        variant="text"
        color="tertiary"
        rightIcon="action-options-alt"
        rightIconSettings={{
          size: '2xl',
        }}
      />
    </div>
  );
};

export const TabMenu = (props: TabMenuProps) => {
  const { tabs = [], onChange, value } = props;
  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const { width: menuWidth } = useElementSize(menuRef);
  const [_visibility, setVisibility] = useState<Record<string, boolean>>({});
  const [active, setActive] = useState('');
  const [sortedItems, setSortedItems] = useState<TabConfig[]>(tabs);

  useEffect(() => {
    if (value && !active) {
      setActive(value);
    }
  }, [value, active]);

  const visibility = useMemo(() => {
    if (Object.values(_visibility).every((v) => v === false)) {
      return {
        ..._visibility,
        [sortedItems[0]?.id]: true,
      };
    }

    return _visibility;
  }, [_visibility, sortedItems]);

  useEffect(() => {
    if (!sortedItems.length) {
      setSortedItems(tabs);
    }
  }, [tabs, sortedItems]);

  const visibleItems = useMemo(() => {
    return sortedItems.filter((item) => visibility[item.id]);
  }, [sortedItems, visibility]);

  const invisibleItems = useMemo(() => {
    return sortedItems.filter((item) => !visibility[item.id]);
  }, [sortedItems, visibility]);

  const hasOverflow = invisibleItems.length > 0;

  const removedItem = useRef<TabConfig | null>(null);

  const handleChangeHiddenTab = useCallback(
    (tab: TabConfig, fireOnChange = true) => {
      setSortedItems((prev) => {
        const result: TabConfig[] = structuredClone(prev);
        const clickedItemIndex = result.findIndex((item) => item.id === tab.id);
        const [clickedItem] = result.splice(clickedItemIndex, 1);
        const newIndex = clickedItemIndex - 1;

        if (newIndex < 0) {
          removedItem.current = null;
          return prev;
        }

        result.splice(newIndex, 0, clickedItem);
        removedItem.current = clickedItem;

        return result;
      });

      if (fireOnChange) {
        onChange?.(tab);
        setActive(tab.id);
      }
    },
    [onChange]
  );

  // We're using the render loop here to "iterate" and determine if the removed item is visible or not.
  // On each render, if it's not visible, we move it one space closer to the front of the list, until it's
  // either visible or we get to index 0 (above).
  useEffect(() => {
    if (removedItem.current) {
      if (!visibility[removedItem.current?.id]) {
        handleChangeHiddenTab(removedItem.current, false); // don't fire onChange because we already did the first time
      } else {
        removedItem.current = null;
      }
    }
  }, [visibility, handleChangeHiddenTab]);

  const handleChangeMainTab = useCallback(
    (tab: TabConfig) => {
      onChange?.(tab);
      setActive(tab.id);
    },
    [onChange]
  );
  return (
    <div className="w-full relative flex justify-start" ref={menuRef}>
      <TabGroup>
        {visibleItems.map((tab) => {
          return (
            <Tab
              key={tab.id}
              onClick={() => handleChangeMainTab(tab)}
              {...tab}
              active={active === tab.id}
              truncate
            />
          );
        })}
      </TabGroup>
      {hasOverflow ? (
        <div ref={dropdownWrapperRef} className="relative flex">
          <Spacer mode="vertical" size={10} />
          <Menu
            items={invisibleItems}
            onChange={handleChangeHiddenTab}
            renderItem={(item, onClick) => {
              return (
                <Button
                  leftIcon={item.icon}
                  color="inherit"
                  hoverColor={item.color}
                  variant="text"
                  size="small"
                  onClick={onClick}
                  preserveCase
                  maxWidth={false}
                  expand
                >
                  {item.children}
                </Button>
              );
            }}
          >
            <Button
              variant="text"
              color="tertiary"
              rightIcon="action-options-alt"
              rightIconSettings={{
                size: '2xl',
              }}
            />
          </Menu>
        </div>
      ) : null}
      <VisibilityTracker
        width={menuWidth}
        onChangeVisibility={setVisibility}
        {...props}
        tabs={sortedItems}
      />
    </div>
  );
};
