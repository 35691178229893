/* -------------------- */
// Filter Meta Data
/* -------------------- */

export const FILTER_META_DATA_START = 'FILTER_META_DATA_START';
export const FILTER_META_DATA_SUCCESS = 'FILTER_META_DATA_SUCCESS';
export const FILTER_META_DATA_FAILURE = 'FILTER_META_DATA_FAILURE';

export const filterMetaDataStart = () => ({
  type: FILTER_META_DATA_START,
});
export const filterMetaDataSuccess = (filterMetaData) => ({
  type: FILTER_META_DATA_SUCCESS,
  filterMetaData,
});
export const filterMetaDataFailure = () => ({
  type: FILTER_META_DATA_FAILURE,
});
