import KizenTypography from 'app/kizentypo';
import MultiSelect from 'components/Inputs/MultiSelect';
import useTeamMemberTypeahead from 'hooks/useTeamMemberTypeahead';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DebouncedInput from '../shared/DebouncedInput';
import {
  BreakdownDelimiter,
  BreakdownRow,
  RangeIcon,
  StyledMultiSelect,
  BreakdownLabelInputContainer,
} from '../shared/styles';
import useTeamMemberNameRepair from 'components/Wizards/shared/hooks/useTeamMemberNameRepair';
import useDynamicTagRepair from 'components/Wizards/shared/hooks/useDynamicTagRepair';
import { FIELD_TYPES } from 'utility/constants';
import { getDefaultLabel } from '../shared/utils';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import ClientService from 'services/ClientService';
import CustomObjectsService from 'services/CustomObjectsService';
import FieldService from 'services/FieldService';
import PipelineService from 'services/PipelineService';

export const VALUE_BUCKET_ACTION_TYPES = {
  ADD_ROW: 'ADD_ROW',
  UPDATE: 'UPDATE',
  RESET: 'RESET',
  DELETE_ROW: 'DELETE_ROW',
};

const BucketRow = ({
  options = [],
  onBlur,
  label,
  index,
  dispatchBreakdown,
  values: selectedValues,
  field,
  fieldObject,
  validation,
  showErrors,
}) => {
  const selectRef = useRef();
  const { t } = useTranslation();

  const changeLabel = useCallback(
    (value) => {
      dispatchBreakdown({
        type: VALUE_BUCKET_ACTION_TYPES.UPDATE,
        payload: { index, label: value },
      });
    },
    [dispatchBreakdown, index]
  );

  const deleteRow = useCallback(() => {
    dispatchBreakdown({
      type: VALUE_BUCKET_ACTION_TYPES.DELETE_ROW,
      payload: { index },
    });
  }, [dispatchBreakdown, index]);

  const changeValues = useCallback(
    (values, remove, isUserAction) => {
      dispatchBreakdown({
        type: VALUE_BUCKET_ACTION_TYPES.UPDATE,
        payload: {
          index,
          values,
          remove: remove && values.length === 0,
          userInitiated: isUserAction,
        },
      });
    },
    [dispatchBreakdown, index]
  );

  const {
    needsToFetchTeamMembers,
    valuesWithNames,
    loading: teamMembersBatchLoading,
  } = useTeamMemberNameRepair({
    fieldData: field,
    values: selectedValues,
  });

  const {
    needsToFetchDynamicTags,
    valuesWithNames: dynamicTagsWithNames,
    loading: dynamicTagsBatchLoading,
  } = useDynamicTagRepair({
    fieldData: field,
    values: selectedValues,
  });

  const serviceToUse = useMemo(() => {
    if (fieldObject?.fetchUrl === 'client') {
      return ClientService;
    }
    switch (fieldObject?.objectType) {
      case 'standard':
        return CustomObjectsService;
      case 'pipeline':
        return PipelineService;
      default:
        return FieldService;
    }
  }, [fieldObject?.fetchUrl, fieldObject?.objectType]);

  const searchDynamicTags = useCallback(
    ({ search = '', page = 1 }, options) => {
      return serviceToUse.getDynamicTagsOptions(
        field.id,
        fieldObject?.id,
        page,
        search,
        options
      );
    },
    [field.id, fieldObject, serviceToUse]
  );

  const { valuesForSelect, needsRepair } = useMemo(() => {
    let needsRepair = false;
    const values = selectedValues
      ?.map((value) => {
        if (typeof value === 'string') {
          if (field?.fieldType === FIELD_TYPES.TeamSelector.type) {
            const teamMember = valuesWithNames.find((c) => c.id === value);
            if (!teamMember && !teamMembersBatchLoading) {
              needsRepair = true;
              return null;
            }
            return {
              value,
              label: teamMember?.displayName,
            };
          }
          if (field?.fieldType === FIELD_TYPES.DynamicTags.type) {
            const dynamicTag = dynamicTagsWithNames.find((c) => c.id === value);
            if (!dynamicTag && !dynamicTagsBatchLoading) {
              needsRepair = true;
              return null;
            }
            return {
              value,
              label: dynamicTag?.displayName,
            };
          } else {
            // If we get a string and it's not the team selector, something went wrong and this
            // option likely doesn't exist any more
            const option = options.find((o) => o.value === value);
            if (!option) {
              needsRepair = true;
              return null;
            }
            return option;
          }
        }
        if (typeof value === 'number') {
          return {
            value,
            label: String(value),
          };
        }
        return value;
      })
      .filter(Boolean);
    return {
      valuesForSelect: values,
      needsRepair,
    };
  }, [
    dynamicTagsWithNames,
    selectedValues,
    valuesWithNames,
    field,
    options,
    teamMembersBatchLoading,
    dynamicTagsBatchLoading,
  ]);

  const teamTypeaheadProps = useTeamMemberTypeahead(undefined, {
    enabled: needsToFetchTeamMembers,
  });

  const chosenDynamicTagValues = useMemo(
    () => valuesForSelect?.map((item) => item.value ?? item.id),
    [valuesForSelect]
  );

  const tagToOption = ({ id, label, name }) => {
    return { value: id, label: label || name };
  };

  const [dynamicTagTypeaheadProps, dynamicTagTypeheadState] =
    useSelectTypeaheadWithScroll({
      entity: Entities.DynamicTags,
      fetch: searchDynamicTags,
      objectToOption: tagToOption,
      fieldId: field.id,
      selectRef,
      alwaysOpen: false,
      chosenValueIds: chosenDynamicTagValues,
      keepPreviousData: true,
    });

  const additionalProps = useMemo(() => {
    if (needsToFetchTeamMembers) {
      return teamTypeaheadProps;
    }
    if (needsToFetchDynamicTags) {
      return dynamicTagTypeaheadProps;
    }

    return {};
  }, [
    dynamicTagTypeaheadProps,
    needsToFetchDynamicTags,
    needsToFetchTeamMembers,
    teamTypeaheadProps,
  ]);

  // If one of the options has been deleted, we need to remove it from the options
  // so the configuration can still be saved
  useEffect(() => {
    if (needsRepair) {
      changeValues(valuesForSelect, true, false);
    }
  }, [needsRepair, valuesForSelect, changeValues]);

  const fieldOptions = useMemo(() => {
    return [...options, ...(additionalProps?.options ?? [])];
  }, [options, additionalProps]);

  const hasError = validation.missing && showErrors;

  // This array may end up with duplicate options, but that's okay because it's
  // only used to generate the placeholder. It should not be passed to the dropdown
  // as the options prop
  const placeholderOptions = useMemo(() => {
    return [...fieldOptions, ...(valuesForSelect || [])];
  }, [fieldOptions, valuesForSelect]);

  return (
    <BreakdownRow
      center={false}
      key={`value-bucket-row-${index}`}
      className="bucket-row"
    >
      <StyledMultiSelect>
        <MultiSelect
          ref={selectRef}
          value={valuesForSelect}
          options={options}
          onChange={(value, remove) => changeValues(value, remove, true)}
          placeholder={t('Choose Value(s)')}
          className={`modal-multiselect fieldValues ${
            teamMembersBatchLoading ? 'loading' : 'ready'
          }`}
          classNamePrefix={`bucket-values-${index}`}
          validate={
            hasError
              ? {
                  message: validation.validationMessage,
                  showMessage: validation.missing,
                }
              : undefined
          }
          onMenuClose={onBlur}
          isLoading={
            teamMembersBatchLoading || dynamicTagTypeheadState?.isLoading
          }
          {...additionalProps}
        />
      </StyledMultiSelect>
      <BreakdownDelimiter compact>
        <KizenTypography>,</KizenTypography>
      </BreakdownDelimiter>
      <BreakdownLabelInputContainer>
        <DebouncedInput
          value={label}
          onChange={changeLabel}
          onBlur={onBlur}
          placeholder={getDefaultLabel({
            t,
            values: valuesForSelect?.map(({ value }) => value) ?? [],
            fieldOptions: placeholderOptions,
            fallback: t('Label'),
          })}
          className="breakdown-label"
        />
      </BreakdownLabelInputContainer>
      <RangeIcon center={false} icon="trash" onClick={deleteRow} compact />
    </BreakdownRow>
  );
};

export default BucketRow;
