import { FILTER_TYPES } from 'ts-filters/legacy';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import { BroadcastAutomationPicker } from './BroadcastContentBuilder/BroadcastAutomationPicker';
import { BroadcastEmailPicker } from './BroadcastContentBuilder/BroadcastEmailPicker';

export const BROADCAST_FORMDATA = {
  type: 'type',
  date: 'scheduledAt',
  rescheduleAt: 'rescheduleAt',
  action: 'action',
  customObjectId: 'customObjectId',
  name: 'name',
  filterType: 'filterType',
  filterGroupsIds: 'filterGroupsIds',
  filterConfig: 'filterConfig',
  status: 'status',
  smartSendTimeSplitPercentage: 'smartSendTimeSplitPercentage',
};

export const defaultFilterConfig = {
  [BROADCAST_FORMDATA.filterType]: FILTER_TYPES.IN_GROUP,
  [BROADCAST_FORMDATA.filterConfig]: { query: [] },
  [BROADCAST_FORMDATA.filterGroupsIds]: [],
};

export const defaultBroadcastFormData = {
  [BROADCAST_FORMDATA.type]: null,
  [BROADCAST_FORMDATA.date]: null,
  [BROADCAST_FORMDATA.name]: '',
  ...defaultFilterConfig,
};

export const CONTENT_COMPONENTS = {
  [EVENT_TYPES.email.type]: BroadcastEmailPicker,
  [EVENT_TYPES.automation.type]: BroadcastAutomationPicker,
  default: () => null,
};
