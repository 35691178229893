import ct from 'countries-and-timezones';
import { addMinutes } from 'date-fns';

export const getDateWithTimezone = (datetime, timezone) =>
  new Date(datetime).toLocaleString('en-US', {
    timeZone: timezone,
  });

export const businessLocalTimezoneOffset = (timezone) => {
  const businessTZ = ct.getTimezone(timezone);
  const localTZ = ct.getTimezone(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  return businessTZ.utcOffset - localTZ.utcOffset;
};

export const getNowTimezoneAdjusted = (timezone) => {
  const now = new Date();
  const amount = businessLocalTimezoneOffset(timezone);
  return addMinutes(now, amount);
};

export const getScheduledTimezoneAdjusted = (scheduled, timezone) => {
  const amount = businessLocalTimezoneOffset(timezone);
  return addMinutes(scheduled, amount);
};
