import { VisibilityRule } from './types';

type EvalMap = ReturnType<typeof getRuleEvalMap>;

export type Field =
  | {
      fieldType: 'dynamictags';
      id: string;
      value?: { id: string }[];
    }
  | {
      fieldType: 'dropdown';
      id: string;
      value?: string;
    }
  | {
      fieldType: 'relationship';
      id: string;
      value?: { id: string } | { id: string }[];
    };

type TestFn = (
  values: Record<string, any>,
  rules: EvalMap,
  self: string
) => boolean;

const hasAny = (a: any, b: any) => {
  return Array.isArray(a) && b.some((x: any) => a.includes(x));
};
const notHasAny = (a: any, b: any) => !a || (a.length > 0 && !hasAny(a, b));
const isBlank = (a: any) => (Array.isArray(a) ? a.length === 0 : !a);
const isNotBlank = (a: any) => (Array.isArray(a) ? a.length > 0 : Boolean(a));
const eq = (a: any, b: any) => a === b;
const notEq = (a: any, b: any) => a !== b;

const ops = {
  '=': eq,
  '!=': notEq,
  has_any: hasAny,
  '!has_any': notHasAny,
  is_any_of: hasAny,
  is_none_of: notHasAny,
  is_blank: isBlank,
};

export const getRuleEvalMap = (visibilityRules?: VisibilityRule[]) => {
  if (!visibilityRules) return new Map<string, TestFn>();

  return visibilityRules.reduce<Map<string, TestFn>>(
    (acc, { fields, rule }) => {
      const test = (
        values: Record<string, any>,
        rules: EvalMap,
        self: string
      ) => {
        const conditions = rule.filters.map(
          ({ activity_field_id, condition, is_valid, value }) => {
            if (is_valid === false) {
              return null;
            }

            if (activity_field_id === self) {
              // We don't allow configuring rules for fields to "hide themselves" but we
              // need to implement the logic to prevent infinite recursion.
              return false;
            }

            const isConditionFieldHiddenByRule =
              rules.has(activity_field_id) &&
              !rules.get(activity_field_id)!(values, rules, activity_field_id);

            if (isConditionFieldHiddenByRule) {
              return false;
            }

            const src = values[activity_field_id];
            if (condition === 'is_blank') {
              return value ? isBlank(src) : isNotBlank(src);
            }
            return ops[condition](src, value);
          }
        );

        const activeConditions = conditions.filter((x) => x !== null);

        return rule.and
          ? activeConditions.every(Boolean)
          : activeConditions.some(Boolean);
      };

      for (const field of fields) {
        acc.set(field.id, test);
      }

      return acc;
    },
    new Map()
  );
};

/**
 * Get field values key by the field id. The fields parameter is the log activity form state.
 */
export const getFieldValues = (fields?: Field[]) => {
  if (!fields) return {};
  return fields.reduce<Record<string, undefined | string | string[]>>(
    (acc, { id, fieldType, value }) => {
      if (fieldType === 'relationship') {
        acc[id] = Array.isArray(value)
          ? value.map((x) => x.id)
          : value
            ? [value.id]
            : [];
      } else if (fieldType === 'dynamictags') {
        acc[id] = value?.map((x) => x.id) ?? [];
      } else {
        acc[id] = value;
      }

      return acc;
    },
    {}
  );
};

export const evalRules = (map: EvalMap, values: Record<string, any>) => {
  return [...map.entries()].reduce<Record<string, boolean>>(
    (acc, [id, testFn]) => {
      acc[id] = testFn(values, map, id);
      return acc;
    },
    {}
  );
};
