import { isEqual as eq } from 'lodash';
import { Rule, State } from './state';

const ruleEq = (r1: Rule, r2: Rule) => {
  const f1 = r1.filters.map(({ filter }) => (filter ? [...filter] : null));
  const f2 = r2.filters.map(({ filter }) => (filter ? [...filter] : null));

  const filtersEqual = eq(f1, f2);

  if (!filtersEqual) {
    return false;
  }

  const { filters: _f1, ...rule1 } = r1;
  const { filters: _f2, ...rule2 } = r2;

  return eq(rule1, rule2);
};

export const isEqual = (s1: State, s2: State) => {
  const { default_content: d1, rules: r1 } = s1;
  const { default_content: d2, rules: r2 } = s2;

  return (
    eq(d1, d2) &&
    r1.length === r2.length &&
    r1.every((r, i) => ruleEq(r, r2[i]))
  );
};
