import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from '__app/colors';
import { borderRadii } from '__app/spacing';
import Button from '__components/Button';
import InputControl from '__components/Inputs/InputControl';
import {
  StyledCard,
  StyledStepHeader,
} from '__pages/SmartConnectors/SmartConnector/steps/styles';
import Select from '__components/Inputs/Select';

export const StyledMappingStepHeader = styled(StyledStepHeader)`
  margin-bottom: 16px;
  &:not(:first-of-type) {
    margin-top: 0;
  }
`;

export const StyledMappingCard = styled(StyledCard)`
  padding-bottom: 400px;
`;

export const StyledMappingRules = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledRuleWarpper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const StyledRuleCard = styled.div<{ hasError: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  gap: 20px;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? colorsButton.red.default : grayScale.medium};
  border-radius: ${borderRadii.small};
`;

export const StyledDiv = styled.div<{ width?: number }>`
  ${({ width }) => css`
    width: ${width}px;
  `}
`;

export const StyledInputControl = styled(InputControl)`
  & label {
    margin-bottom: 23px;
  }
`;

export const StyledButtonsRow = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

export const StyledButtonsInputControl = styled(InputControl)`
  width: 50%;
`;

export const StyledCategorySelectWrapper = styled.div`
  width: 50%;
`;

export const StyledCategorySelect = styled(Select)`
  width: calc(50% - 10px);
`;

export const StyledAddRuleButton = styled(Button)`
  margin: 0;
  padding: 0;
  height: fit-content;
  width: fit-content;
`;
