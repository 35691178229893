import styled from '@emotion/styled';
import { format } from 'date-fns';
import { grayScale, colorsButton } from 'app/colors';
import { borderRadii } from 'app/spacing';
import { KizenTypography, fontWeights } from 'app/typography';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { isPast } from 'utility/datetime';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';

const Container = styled.div`
  position: relative;
  cursor: default;
  height: 21px;
  width: 100%;
  border-radius: ${borderRadii.tiny};
  border: 1px solid
    ${({ isInvalid }) =>
      isInvalid ? colorsButton.red.hover : grayScale.medium};
`;

const TextContainer = styled.div`
  display: flex;
  padding: 5px 0;
`;

const TimeText = styled(KizenTypography)`
  flex-shrink: 0;
  margin: 0 5px;
  font-weight: ${fontWeights.bold};
  color: ${({ past, isInvalid }) =>
    isInvalid ? colorsButton.red.hover : past && grayScale.mediumDark};
`;

const TypeText = styled(TextEllipsisWithTooltip)`
  margin-top: 0;
  padding-right: 5px;
  color: ${({ past, isInvalid }) =>
    isInvalid ? colorsButton.red.hover : past && grayScale.mediumDark};
`;

const LeftBar = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2px);
  width: 2px;
  border-top-left-radius: ${borderRadii.tiny};
  border-bottom-left-radius: ${borderRadii.tiny};
  background-color: ${({ type, past, isInvalid }) =>
    isInvalid
      ? colorsButton.red.hover
      : past
        ? EVENT_TYPES.default.color
        : EVENT_TYPES[type]?.color || EVENT_TYPES.default.color};
`;

const EventPill = ({
  date,
  type,
  name,
  isInvalid,
  isPastDate = false,
  ...rest
}) => {
  const past = date ? isPast(date) : isPastDate;

  return (
    <Container type={type} past={past} isInvalid={isInvalid} {...rest}>
      <LeftBar past={past} type={type} isInvalid={isInvalid} />
      <TextContainer>
        <TimeText past={past} isInvalid={isInvalid} size="buttonLabel">
          {date ? `${format(date, 'h:mm')}${format(date, 'a')[0]}` : null}
        </TimeText>
        <TypeText
          past={past}
          size="buttonLabel"
          noSpace
          data-qa-name={name}
          isInvalid={isInvalid}
        >
          {name}
        </TypeText>
      </TextContainer>
    </Container>
  );
};

export default EventPill;
