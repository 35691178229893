import { PILL_TYPES } from '../constants';
import { FacetFieldMultiselect } from './FacetFieldMultiselect';
import { FacetFieldSingleselect } from './FacetFieldSingleselect';
import { NumberFieldRangeInput } from './NumberFieldRangeInput';
import { PillMultiselect } from './PillMultiselect';
import { PillSingleselect } from './PillSingleselect';
import { PillTextInput } from './PillTextInput';
import { PillDateRangePicker } from './PillDateRangePicker';
import { FilterGroupMultiselect } from './FilterGroupMultiselect';

export const PillDropdownComponent = ({ pillType, ...rest }) => {
  switch (pillType) {
    case PILL_TYPES.multiselect.type:
      return <PillMultiselect {...rest} />;
    case PILL_TYPES.facetMultiselect.type:
      return <FacetFieldMultiselect {...rest} />;
    case PILL_TYPES.filterGroupMultiselect.type:
      return <FilterGroupMultiselect {...rest} />;
    case PILL_TYPES.singleselect.type:
      return <PillSingleselect {...rest} />;
    case PILL_TYPES.facetSingleselect.type:
      return <FacetFieldSingleselect {...rest} />;
    case PILL_TYPES.number.type:
      return <NumberFieldRangeInput {...rest} />;
    case PILL_TYPES.text.type:
      return <PillTextInput {...rest} />;
    case PILL_TYPES.date.type:
      return <PillDateRangePicker {...rest} />;
    default:
      return null;
  }
};
