// class to handle localstorage

export class LocalStorage {
  constructor(
    key = 'key',
    { serialize = JSON.stringify, deserialize = JSON.parse } = {}
  ) {
    this.key = key;
    this.serialize = serialize;
    this.deserialize = deserialize;
    try {
      this.localStorage = window.localStorage;
    } catch {
      this.localStorage = null;
    }
  }

  get val() {
    if (this.localStorage) {
      try {
        const val = this.localStorage.getItem(this.key);
        if (val) {
          return this.deserialize(val);
        }
      } catch {}
    }
    return null;
  }

  set val(val) {
    if (this.localStorage) {
      try {
        this.localStorage.setItem(this.key, this.serialize(val));
      } catch {}
    }
  }

  clear() {
    if (this.localStorage) {
      try {
        this.localStorage.removeItem(this.key);
      } catch {}
    }
  }
}
