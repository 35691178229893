import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useAccessRequest = (respond) => {
  const { search } = useLocation();
  const processingPermission = useRef(false);

  const params = useMemo(() => {
    const p = new URLSearchParams(search);
    return {
      requestId: p.get('access_request_id'),
      approved: p.get('approved') === 'true',
    };
  }, [search]);

  const handlePermissionParams = useCallback(async () => {
    // This should only run once by design, however in case the router does something weird,
    // lock out processing so it can only be run once.
    if (Boolean(params.requestId) && !processingPermission.current) {
      processingPermission.current = true;
      respond({ requestId: params.requestId, approved: params.approved });
    }
  }, [params.requestId, params.approved, respond]);

  useEffect(() => {
    handlePermissionParams();
  }, [handlePermissionParams]);
};

export default useAccessRequest;
