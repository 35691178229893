import BasicModal from 'components/Modals/presets/BasicModal';
import { useTranslation } from 'react-i18next';
import Row1 from 'components/RowPicker/images/Row-1.svg?react';
import Row11 from 'components/RowPicker/images/Row-1-1.svg?react';
import Row111 from 'components/RowPicker/images/Row-1-1-1.svg?react';
import Row12 from 'components/RowPicker/images/Row-1-2.svg?react';
import Row21 from 'components/RowPicker/images/Row-2-1.svg?react';
import { COLUMN_SIZE } from '@kizen/kds/Grid';
import { ButtonGroupImageButton } from '__components/Inputs/ButtonGroup/Button';

const options = (t: (x: string) => string) => [
  {
    label: t('1 Column'),
    value: [COLUMN_SIZE.FULL_WIDTH],
    image: <Row1 />,
  },
  {
    label: t('2 Columns'),
    value: [COLUMN_SIZE.HALF_WIDTH, COLUMN_SIZE.HALF_WIDTH],
    image: <Row11 />,
  },
  {
    label: t('2 Columns (1/3 and 2/3)'),
    value: [COLUMN_SIZE.THIRD_WIDTH, COLUMN_SIZE.TWO_THIRD_WIDTH],
    image: <Row12 />,
  },
  {
    label: t('2 Columns (2/3 and 1/3)'),
    value: [COLUMN_SIZE.TWO_THIRD_WIDTH, COLUMN_SIZE.THIRD_WIDTH],
    image: <Row21 />,
  },
  {
    label: t('3 Columns'),
    value: [
      COLUMN_SIZE.THIRD_WIDTH,
      COLUMN_SIZE.THIRD_WIDTH,
      COLUMN_SIZE.THIRD_WIDTH,
    ],
    image: <Row111 />,
  },
];

export const RowModal = ({ onChoose, ...props }: any) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      size="large"
      {...props}
      heading={t('Add New Column Layout')}
      leftBtn={false}
      actionBtnColor="blue"
      buttonText={t('Cancel')}
    >
      <div className="grid gap-[20px] grid-cols-[repeat(5,1fr)]">
        {options(t).map((option) => {
          return (
            <ButtonGroupImageButton
              key={option.label}
              option={option}
              onClick={() => onChoose(option.value)}
            />
          );
        })}
      </div>
    </BasicModal>
  );
};
