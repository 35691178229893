import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { isMobile, useWindowSize } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import { getSearchParam, setSearchParams } from 'hooks/useSearchParam';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { camelToSnakeCase, snakeToCamelCase } from 'services/helpers';
import {
  buildPage,
  setPageConfig,
  getTemplates,
  addTemplate,
  deleteTemplate,
  duplicateTemplate,
} from 'store/libraryPage/library.redux';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { setOpenMenuAbove } from 'components/Tables/helpers';
import DesktopLayout from './DesktopLayout';
import { useTranslation } from 'react-i18next';
import LibraryService from 'services/LibraryService';
import { getOriginalError } from 'services/AxiosService';
import { updateTemplateSuccess } from 'store/libraryPage/library.redux';
import useModal from 'components/Modals/useModal';
import { getNowTimezoneAdjusted } from 'app/timezone';
import { toastVariant, useToast } from 'components/ToastProvider';
import { templateUpdatingToggle } from 'store/libraryPage/library.redux';
import { templateForApi, templateForApp } from 'services/LibraryService';
import { MessageBuilder, useDefaultTemplate } from 'components/MessageBuilder';
import { actions } from './columns';
import { AppError } from 'services/errors';
import { deferExecution } from 'utility/defer';
import { getBusinessTimeZone } from 'store/authentication/selectors';
import routes, { LibraryUrlBase } from 'pages/Library/routes';
import { SENDER_TYPE } from 'components/MessageBuilder/components';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

const validTemplateTypes = ['email', 'integrated_inbox_email'];

const TemplatesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();
  const [deletedTemplate, setTemplateForDelete] = useState(null);
  const [storedTemplate, setStoredTemplate] = useState({});
  const defaultTemplate = useDefaultTemplate();
  const [showToast] = useToast();
  const { templateId, subpage } = useParams();

  const isFetching = useSelector((s) => s.libraryPage.isFetching);
  const isTemplateUpdating = useSelector(
    (s) => s.libraryPage.isTemplateUpdating
  );
  const pageConfig = useSelector((s) => s.libraryPage.pageConfig?.templates);
  const templatesWithoutMeta = useSelector((s) => s.libraryPage.templates);
  const templatesWithoutMetaCount = useSelector(
    (s) => s.libraryPage.templatesCount
  );
  const businessTimezone = useSelector(getBusinessTimeZone);
  const templates = useMemo(() => {
    return templatesWithoutMeta && templatesWithoutMeta.map(setOpenMenuAbove);
  }, [templatesWithoutMeta]);

  const [, , addModal] = useModal({
    handleHide: () => {
      setStoredTemplate({});
      // on direct link to build page, we need to go back to templates page
      // as previous page is dashboard
      if (
        history.location.pathname !== routes.templates.path &&
        history.length > 2
      ) {
        history.goBack();
      } else {
        history.push(routes.templates.path);
      }
    },
  });
  const { show } = addModal;
  useEffect(() => {
    const fetchData = async () => {
      if (templateId) {
        const fullTemplate = await LibraryService.getTemplateById({
          id: templateId,
        });
        setStoredTemplate(templateForApp(fullTemplate));
        show();
      }
    };
    fetchData();
  }, [templateId, show]);

  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');
    const search = getSearchParam(history.location, 'q');
    syncDispatch(
      buildPage({
        page: {
          type: 'templates',
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
          search,
        },
      })
    );
  }, [syncDispatch, history]);

  // memo functions ------------------------------------------------
  const sortValue = useMemo(
    () => getSortValue(snakeToCamelCase(pageConfig.sort)),
    [pageConfig.sort]
  );

  // columns handlers
  const handleUpdateTemplate = useCallback(
    async (patch) => {
      try {
        const { id, name, subject } =
          await LibraryService.updateTemplate(patch);
        dispatch(
          updateTemplateSuccess({
            id,
            name,
            subject,
          })
        );
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = Object.entries(err.response.data.errors);
          if (errors && errors.length) {
            // we show only 1st error
            error = new Error(errors[0][1]);
          }
        }
        throw error;
      }
    },
    [dispatch]
  );

  const handleSubmit = useCallback(
    async (email, shouldClose) => {
      try {
        dispatch(templateUpdatingToggle());
        const type =
          email?.sender?.type === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX
            ? 'integrated_inbox_email'
            : 'email';
        if (storedTemplate?.id) {
          const { id } = storedTemplate;
          const updatedTemplate = await LibraryService.updateTemplate(
            templateForApi({
              ...email,
              id,
              type,
            })
          );
          dispatch(updateTemplateSuccess(updatedTemplate));
        } else {
          const newTemplate = await LibraryService.createTemplateMessage({
            ...templateForApi(email),
            type,
          });
          setStoredTemplate(templateForApp(newTemplate));
          const now = getNowTimezoneAdjusted(businessTimezone);
          dispatch(
            addTemplate({
              created: now,
              ...newTemplate,
            })
          );
        }
        showToast({
          variant: toastVariant.SUCCESS,
          message: t('Message Template was successfully saved'),
        });
        if (shouldClose) {
          addModal.hide();
        }
      } catch (err) {
        const { name } = getOriginalError(err);
        if (name) {
          throw new AppError(
            'internalNameError',
            t('Template names must be unique.')
          );
        }
        showToast({
          variant: toastVariant.FAILURE,
          message: t(
            'There was an error when trying to save. Please try again or contact Kizen support if the problem persists'
          ),
        });
      } finally {
        dispatch(templateUpdatingToggle());
      }
    },
    [businessTimezone, dispatch, showToast, storedTemplate, addModal, t]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(setPageConfig({ type: 'templates', page: value }));
      dispatch(getTemplates());
      deferExecution(() => {
        setSearchParams(history, { page: value });
      });
    },
    [history, dispatch]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(setPageConfig({ type: 'templates', size: value }));
      dispatch(getTemplates());
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleChangeSearch = useCallback(
    (q) => {
      dispatch(
        setPageConfig({
          type: 'templates',
          search: q,
          page: 1,
        })
      );
      dispatch(getTemplates());
      deferExecution(() => {
        setSearchParams(history, { q, page: null }, { method: 'replace' });
      });
    },
    [dispatch, history]
  );

  const handleSortTable = useCallback(
    ({ column, direction }) => {
      const data = camelToSnakeCase(column);
      const value = getOrderingParam({ column: data, direction });

      dispatch(setPageConfig({ type: 'templates', sort: value }));
      dispatch(getTemplates());
      deferExecution(() => {
        setSearchParams(history, {
          sort: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleDeleteTemplate = useCallback(() => {
    dispatch(deleteTemplate(deletedTemplate));
    setTemplateForDelete(null);
  }, [dispatch, deletedTemplate]);

  const handleSelectAction = useCallback(
    async ({ value }, template) => {
      switch (value) {
        case actions.edit: {
          const fullTemplate = await LibraryService.getTemplateById(template);
          if (validTemplateTypes.includes(fullTemplate.type)) {
            history.push(`/library/templates/${template.id}/build`);
          }
          break;
        }
        case actions.delete: {
          setTemplateForDelete(template.id);
          break;
        }
        case actions.duplicate: {
          dispatch(duplicateTemplate(template));
          break;
        }
        default: {
        }
      }
    },
    [dispatch, history]
  );

  const initialData = useMemo(() => {
    return { craftJsContent: defaultTemplate, ...storedTemplate };
  }, [storedTemplate, defaultTemplate]);

  if (addModal.showing) {
    return (
      <MessageBuilder
        initialData={initialData}
        onCancel={addModal.hide}
        onConfirm={handleSubmit}
        isTemplateUpdating={isTemplateUpdating}
        useAllIntgegratedInboxes
      />
    );
  }

  if (isFetching) {
    return <Loader loading />;
  }

  if (templateId && subpage !== 'build') {
    return <Redirect exact to={`${LibraryUrlBase}/templates`} />;
  }

  return (
    <>
      {isMobile(width) ? (
        <></>
      ) : (
        <DesktopLayout
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          templates={templates}
          templatesWithoutMetaCount={templatesWithoutMetaCount}
          pageNumber={pageConfig.page}
          pageSize={pageConfig.size}
          handleChangePageNumber={handleChangePageNumber}
          handleChangePageSize={handleChangePageSize}
          sort={sortValue}
          handleChangeSort={handleSortTable}
          handleSelectAction={handleSelectAction}
          handleClickNewTemplate={show}
          timezone={businessTimezone}
          handleUpdateTemplate={handleUpdateTemplate}
          handleChangeSearch={() => {}}
        />
      )}
      <ConfirmDeletionModal
        show={Boolean(deletedTemplate)}
        onConfirm={handleDeleteTemplate}
        onHide={() => setTemplateForDelete(null)}
      >
        {t('This will permanently delete the Template.')}
      </ConfirmDeletionModal>
    </>
  );
};

export default TemplatesPage;
