import styled from '@emotion/styled';
import { borderRadii, breakpoints, layers } from 'app/spacing';
import { grayScale, shadowLight } from 'app/colors';
import { TextSpan, fontSizes, fontWeights } from 'app/typography';
import IconButtonWithBadge from 'components/Kizen/IconButtonWithBadge';
import Card from 'components/Card/Card';
import DateRangePicker from 'components/Kizen/DateRangePicker';
import Loader from 'components/Kizen/Loader';
import Button from 'components/Button';
import LoadingButton from 'components/Button/LoadingButton';
import { PREVIOUS_PAGE_HEIGHT } from './constants';
import css from '@emotion/css';
import Switch from 'components/Kizen/Switch';

export const Container = styled(Card)`
  margin: unset;
  width: 100%;
  max-width: unset;

  padding: 13px 15px 15px;
  @media (min-width: ${breakpoints.md}px) {
    padding: 10px 20px 20px;
  }

  ${({ constrained, menuActive }) =>
    constrained
      ? css`
          & {
            // Accounts for the expanding scrollbar
            padding-bottom: 0;
            z-index: ${
              menuActive
                ? css`
                    ${layers.content(0, 2)};
                  `
                : css`
                    ${layers.content(0, 0)};
                  `
            }
            padding: 0;
          }

          & > div:first-child {
            padding-right: 12px;
          }

          & .scroll-parent {
            padding-top: 5px;
          }
        `
      : ''}
`;

export const LeftHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const Header = styled.div`
  margin-bottom: 15px;
  padding-top: 10px;
  width: 100%;
  display: grid;
  align-items: center;

  /* Update the grid-template-columns to include the new item */
  grid-template-columns: max-content max-content 1fr repeat(3, max-content);
  grid-template-areas:
    'col0 col1 . col3 col4 col5 col6 col7'
    'col0 col2 col2 col2 col2 col2 col2 col2';
  row-gap: 15px;

  .kizen-daterange-content {
    grid-template-columns: 103px 1fr;
  }

  @media (min-width: ${breakpoints.md}px) {
    grid-template-columns: max-content max-content minmax(70px, 200px) 1fr repeat(
        3,
        max-content
      );
    grid-template-areas: 'col0 col1 col2 . col3 col4 col5 col6 col7';
    row-gap: initial;
  }

  .rs-picker-menu.rs-picker-daterange-menu {
    left: auto !important;
    right: -65%;
  }

  @media (min-width: 450px) {
    .rs-picker-menu.rs-picker-daterange-menu {
      left: auto !important;
      right: 0;
    }
  }

  ${({ constrained }) =>
    constrained
      ? css`
          transition:
            box-shadow 0.5s ease-in-out,
            border-color 0.5s ease-in-out;
          margin-bottom: 0;
          border-bottom: 1px solid transparent;
          padding: 13px 15px 10px;
          @media (min-width: ${breakpoints.md}px) {
            padding: 20px 20px 20px;
          }
        `
      : ''}

  ${({ scrolled }) =>
    scrolled
      ? css`
          border-color: ${grayScale.mediumLight};
          ${shadowLight}
        `
      : ''}
`;

export const Title = styled(TextSpan)`
  font-size: ${fontSizes.subheader};
  color: inherit;
  font-weight: ${fontWeights.bold};
`;

// export const SearchInput = styled(BigListSearchInput)`
export const SearchInput = styled.div`
  // temporarily until the search function is implemented
  height: 36px;
  grid-area: col2;
  // Addresses an odd edge-case where the input's end adornment icon (magnifying glass)
  // was appearing nudged too far to the right on some clients (at least on Chrome v.88, Windows 10)
  // Unclear why that discrepancy occurred, but this fixes it without affecting other clients
  input {
    min-width: 0;
    padding-right: 0;
  }
`;

export const StyledDateRangePicker = styled(DateRangePicker)`
  grid-area: col7;
  margin-left: 30px !important;
  transform: translateY(1px);
  z-index: ${layers.content(0, 1)};

  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-left: 15px !important;
  }

  ${({ collapseBadge }) =>
    collapseBadge
      ? // Nudge to align with designs
        css`
          margin-right: -2px;
        `
      : ''}
`;

const FilterButton = styled(IconButtonWithBadge)`
  grid-area: ${({ gridArea }) => gridArea};
  padding: 0 !important;
  margin-left: 30px !important;
  margin-right: 0;
  position: relative;
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-left: 15px !important;
  }
`;

export const LayerGroupButton = styled(FilterButton)``;

export const TeamIconButton = styled(FilterButton)`
  height: 20px;
  ${({ sizeAdjust }) =>
    sizeAdjust
      ? css`
          .fa-2x {
            font-size: 2.3em;
            padding-top: 2px;
          }
        `
      : ''}
`;

export const FieldUpdatedButton = styled(FilterButton)`
  font-size: 30px;
  height: 20px;

  .fa-2x {
    font-size: 2.7em;
    padding-top: 2px;
  }
`;

export const LoadMoreWrapper = styled.div`
  box-sizing: border-box;
  text-align: center;
  height: 50px;
  && p {
    line-height: 50px;
    height: 50px;
  }
`;

export const LoadPrevWrapper = styled.div`
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: ${PREVIOUS_PAGE_HEIGHT}px;
  display: flex;
  flex: 1;
  margin-top: -15px; // shift up into the header padding
  margin-bottom: 15px;

  line-height: ${PREVIOUS_PAGE_HEIGHT}px;

  &:before,
  &:after {
    background-color: ${grayScale.medium};
    content: '';
    display: flex;
    flex: 1;
    height: 1px;
  }
`;

export const LoadPrevButton = styled(LoadingButton)`
  margin: 0;
  height: ${PREVIOUS_PAGE_HEIGHT}px;
  padding: 0;
  min-width: 168px;
`;

export const TimelineLoader = styled(Loader)`
  &&& {
    padding-top: 0 !important;
  }
`;

export const InitialFailRetryButton = styled(Button)`
  margin-top: 15px;
  margin-left: 0;
`;

export const HiddenWrapper = styled.div`
  background: #fee;
  height: 00px;
  overflow-y: scroll;
`;

export const StyledFieldEventToggleWrapper = styled.div`
  display: flex;
  ${shadowLight}
  padding: 10px 15px;
  border-radius: ${borderRadii.small};
  background: #fff;
  border: 1px solid ${grayScale.medium};
`;

export const StyledSwitch = styled(Switch)`
  margin: 0;
  & p {
    line-height: 1em;
  }
  & label {
    margin-bottom: 0;
  }
`;
