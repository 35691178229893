import { ListCardHeader, ListCardItem } from 'components/Layout/ListCard';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Loader from 'components/Kizen/Loader';

export const StyledListCardItem = styled(ListCardItem)`
  padding-left: ${gutters.spacing(4)}px;
`;

export const StyledListCardHeader = styled(ListCardHeader)`
  padding-left: ${gutters.spacing(4)}px;
`;

export const StyledLoader = styled(Loader)`
  margin-top: -10%;
`;
