import { useState, useCallback } from 'react';

export const useDirty = () => {
  const [showSubscriptionListModal, setShowSubscriptionListModal] =
    useState(null);
  const [showConfirmation, setShowConfirmation] = useState(null);

  const onHideConfirmed = useCallback(() => {
    setShowConfirmation(null);
    setShowSubscriptionListModal(null);
  }, [setShowConfirmation, setShowSubscriptionListModal]);

  const onHideCanceled = useCallback(() => {
    setShowConfirmation(false);
  }, [setShowConfirmation]);

  const onCheckConfirm = useCallback(
    (isDirty) => {
      if (isDirty) {
        setShowConfirmation(true);
      } else {
        setShowSubscriptionListModal(null);
      }
    },
    [setShowConfirmation, setShowSubscriptionListModal]
  );

  return {
    showSubscriptionListModal,
    setShowSubscriptionListModal,
    showConfirmation,
    setShowConfirmation,
    onHideConfirmed,
    onHideCanceled,
    onCheckConfirm,
  };
};
