import { useCallback, useMemo, useRef, useState } from 'react';
import { defaultsDeep } from 'lodash';
import Select from 'components/Inputs/Select';
import { Menu } from 'components/Inputs/Select/customize';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import { baseModalPopperConfig } from 'components/Inputs/inline/helpers';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { popperModifiers } from 'components/helpers';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';

const TABS = {
  TEAM: 'team',
  ROLE: 'role',
};

const modalNudgePopperUp = defaultsDeep({}, baseModalPopperConfig, {
  modifiers: [popperModifiers.offSet(-1, 7)],
});

export const EmployeeDropdown = ({
  value: valueProp,
  clickRef,
  setShow,
  activity,
  onUpdateActivity,
}) => {
  const scheduledActivitiesAccess = useSelector(
    (s) => s.authentication.access['scheduled_activities']
  );
  const teamMember = useSelector((s) => s.authentication.teamMember || {}); // current user

  const [value] = useState({
    value: valueProp.id,
    label: valueProp.displayName || valueProp.name,
  });

  const [selectedTab, setSelectedTab] = useState(TABS.TEAM);

  const selectRef = useRef(null);

  const canEditOnlyAssociated =
    !activity.access.edit ||
    (scheduledActivitiesAccess.associated_records.edit &&
      !scheduledActivitiesAccess.all_records.edit);

  const [
    {
      options: teamOptions,
      inputValue,
      onInputChange: onInputChangeTeam,
      isLoading: isLoadingTeam,
      components,
      ...teamSelectProps
    },
  ] = useSelectTypeaheadWithScroll({
    enabled: selectedTab === TABS.TEAM && !canEditOnlyAssociated,
    selectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    alwaysOpen: true,
  });

  const [
    {
      options: roleOptions,
      inputValue: _roleInputValue,
      onInputChange: onInputChangeRole,
      isLoading: isLoadingRoles,
      components: _roleComponents,
      ...roleSelectProps
    },
  ] = useSelectTypeaheadWithScroll({
    enabled: selectedTab === TABS.ROLE,
    selectRef,
    entity: Entities.Role,
    alwaysOpen: true,
  });

  const handleHide = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const onInputChange = useCallback(
    (...args) => {
      onInputChangeRole(...args);
      onInputChangeTeam(...args);
    },
    [onInputChangeTeam, onInputChangeRole]
  );

  const habdleSubmit = useCallback(
    (value) => {
      const payload = {
        employeeId: selectedTab === TABS.ROLE ? null : value.value,
        roleId: selectedTab === TABS.ROLE ? value.value : null,
      };
      onUpdateActivity(activity, payload);
      setShow(false);
    },
    [onUpdateActivity, activity, setShow, selectedTab]
  );

  const { t } = useTranslation();

  const options = useMemo(() => {
    let filteredTeamOptions = teamOptions;
    let filteredRoleOptions = roleOptions;
    const filteredOptions = [];

    if (scheduledActivitiesAccess.all_records.edit) {
      filteredOptions.push({
        label: t('Roles'),
        options: filteredRoleOptions,
        tabKey: TABS.ROLE,
      });
    } else if (scheduledActivitiesAccess.role) {
      filteredRoleOptions = filteredRoleOptions.filter((item) =>
        teamMember.roles.includes(item.value)
      );
      filteredOptions.push({
        label: t('Roles'),
        options: filteredRoleOptions,
        tabKey: TABS.ROLE,
      });
    }

    if (canEditOnlyAssociated) {
      filteredTeamOptions = [
        { value: teamMember.id, label: teamMember.display_name },
      ];
    }
    filteredOptions.push({
      label: t('Team Members'),
      options: filteredTeamOptions,
      tabKey: TABS.TEAM,
    });

    return filteredOptions;
  }, [
    teamOptions,
    roleOptions,
    teamMember,
    scheduledActivitiesAccess,
    canEditOnlyAssociated,
    t,
  ]);

  //KZN-5431 -
  // “Assign Activities to My Role(s)” is On If max View permission for My Scheduled Activities, and it’s already assigned to you, the inline edit for Assignee should be readonly
  // “Assign Activities to My Role(s)” is Off If max View permission for My Scheduled Activities, and it’s already assigned to you, the inline edit for Assignee should display and is only populated with your name and your roles
  if (
    !activity.access.edit &&
    teamMember.id === activity.employee?.id &&
    !scheduledActivitiesAccess.role
  ) {
    return <></>;
  }

  return (
    <SelectOverlay
      show
      target={clickRef.current}
      onHide={handleHide}
      popperConfig={modalNudgePopperUp}
    >
      <Select
        ref={selectRef}
        value={value}
        options={options}
        onChange={habdleSubmit}
        autoFocus={true}
        isSearchable={true}
        onFocus={(ev) => {
          ev.nativeEvent.stopImmediatePropagation();
        }}
        showTabs={options.length > 1}
        selectedTab={selectedTab}
        onTabChange={(tab) => {
          setSelectedTab(tab.tabKey);
        }}
        {...(selectedTab === TABS.ROLE ||
        (selectedTab === TABS.TEAM && canEditOnlyAssociated)
          ? roleSelectProps
          : teamSelectProps)}
        components={{
          Menu,
          ...components,
        }}
        inputValue={inputValue}
        onInputChange={onInputChange}
        isLoading={
          selectedTab === TABS.ROLE
            ? isLoadingRoles
            : isLoadingTeam && !canEditOnlyAssociated
        }
      />
    </SelectOverlay>
  );
};
