import { DEFAULT_FA_ICONS } from 'components/MultiColumnBuilder/constants';

export const flattenIcons = () => {
  return DEFAULT_FA_ICONS.reduce((acc, curr) => {
    if (typeof curr === 'string') {
      return [...acc, curr];
    }
    return [...acc, ...curr];
  }, []);
};

export const filterFontIcons = (icon, defaults) => {
  return !defaults.includes(icon.name);
};

export const getDefaultIconOptions = () => {
  return DEFAULT_FA_ICONS.map((icon) => {
    const result = typeof icon === 'string' ? icon : icon[1];
    return { label: result, value: result };
  });
};
