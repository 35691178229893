import React from 'react';

const CloseIcon = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75126 0.292462C1.34447 -0.103769 0.701341 -0.0964921 0.303292 0.308845C-0.107465 0.727124 -0.100041 1.40758 0.319742 1.81646L4.61631 6.00145L0.322671 10.1836C-0.097112 10.5925 -0.104536 11.2729 0.306221 11.6912C0.704269 12.0965 1.3474 12.1038 1.75419 11.7076L6.00147 7.57061L10.2488 11.7076C10.6555 12.1038 11.2987 12.0965 11.6967 11.6912C12.1075 11.2729 12.1001 10.5925 11.6803 10.1836L7.38371 5.9986L11.6773 1.81646C12.0971 1.40758 12.1046 0.727123 11.6938 0.308845C11.2957 -0.0964923 10.6526 -0.103769 10.2458 0.292462L5.99854 4.42944L1.75126 0.292462Z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
