import styled from '@emotion/styled';
import InputControl from 'components/Inputs/InputControl';
import WholeNumberInput from '__components/Inputs/TextInput/presets/WholeNumber';

import { textCss } from 'app/typography';
import css from '@emotion/css';

export const SwitchControl = styled(InputControl)`
  > label {
    line-height: 1.2;
    margin-top: 17px;
    margin-bottom: 10px;
    & + * {
      margin: 0;
      label {
        // Incidentally part of <Switch />
        margin: 0;
        > span {
          ${({ disabledBackgroundColor, disabled, checked }) =>
            disabledBackgroundColor &&
            disabled &&
            checked &&
            css(`
              background-color: ${disabledBackgroundColor}; !important;
              opacity: 0.5;
            `)}
        }
      }
    }
  }
`;

export const StyledValidatedWholeNumberTextInput = styled(WholeNumberInput)`
  max-width: 45px;
  input {
    max-width: 23px;
  }
  &::after {
    ${textCss()}
    content: "%";
    margin-left: 14px;
    padding-top: 10px;
  }
`;
