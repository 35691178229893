import { Dispatch, SetStateAction, createContext } from 'react';
import {
  ExecutionVariable,
  SmartConnector,
  SmartConnectorBase,
  SmartConnectorMetaData,
  SmartConnectorStep,
} from '../types';
import {
  SMART_CONNECTOR_STEPS,
  SMART_CONNECTOR_TYPES,
  VARIABLE_DATA_TYPES,
} from '../constants';

export const defaultSmartConnector: SmartConnectorBase = {
  name: '',
  status: 'setup',
  connector_type: SMART_CONNECTOR_TYPES.spreadsheet,
  custom_object: null,
  error_notification_email: null,
  flow: {
    additional_variables: [],
    loads: [],
  },
  is_diff_check_enabled: true,
};

export type SmartConnectorErrors = Partial<
  Omit<Record<keyof SmartConnector, string[]>, 'execution_variables'> & {
    execution_variables: Partial<Record<keyof ExecutionVariable, string[]>>[];
  }
>;

export type SmartConnectorContextType = {
  smartConnector: SmartConnector | SmartConnectorBase;
  loading: boolean;
  refreshing: boolean;
  step: SmartConnectorStep;
  setStep: Dispatch<SetStateAction<SmartConnectorStep>>;
  nextStep: SmartConnectorStep;
  setNextStep: Dispatch<SetStateAction<SmartConnectorStep>>;
  handleGoToStep: (step: SmartConnectorStep) => void;
  handleSave: (withClose: boolean) => Promise<boolean>;
  isNew: boolean;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isValid: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  stepData: Partial<SmartConnector>;
  setStepData: Dispatch<SetStateAction<Partial<SmartConnector>>>;
  errors: SmartConnectorErrors;
  setErrors: Dispatch<SetStateAction<SmartConnectorErrors>>;
  handleUpdate: (promise: Promise<Partial<SmartConnector>>) => Promise<boolean>;
  refetchSmartConnector: () => Promise<void>;
  metaData: SmartConnectorMetaData;
};

export const defaultContext: SmartConnectorContextType = {
  smartConnector: defaultSmartConnector,
  loading: true,
  refreshing: false,
  step: SMART_CONNECTOR_STEPS.general,
  setStep: () => {},
  nextStep: SMART_CONNECTOR_STEPS.general,
  setNextStep: () => {},
  handleGoToStep: () => {},
  handleSave: () => Promise.resolve(true),
  isNew: false,
  isDirty: false,
  setIsDirty: () => {},
  stepData: {},
  setStepData: () => {},
  errors: {},
  setErrors: () => {},
  handleUpdate: async () => {
    return true;
  },
  refetchSmartConnector: async () => {},
  isValid: true,
  setIsValid: () => {},
  metaData: {
    execution_variables: {
      variable_data_types: [],
      array_delimiter: [],
      default_array_delimiter: ',',
      default_variable_data_type: VARIABLE_DATA_TYPES.string,
    },
    field_mapping_rules: {
      conflict_resolution: [],
      field_type_meta: {},
      variable_to_field_matching: {
        string: {
          array: {},
          single: {},
        },
      },
    },
    matching_rules: {
      match_archive_actions: [],
      multiple_match_actions: [],
      no_match_actions: [],
      single_match_actions: [],
    },
  },
};

export const SmartConnectorContext =
  createContext<SmartConnectorContextType>(defaultContext);
