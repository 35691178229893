import { useEffect, useRef, useState, useCallback } from 'react';
import { useFlashTransition } from 'hooks/useFlashState';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import AutosizeInput from 'react-input-autosize';
import { monitoringMessageHelper } from 'sentry/helpers';

export default function useFormValidation({
  setFormDirty,
  formState,
  setFormState,
  resetToDefaultOnValidateErr = false,
}) {
  const fieldsRef = useRef(new Map());
  const firstFieldWithErrorRef = useRef();
  const [validationState, setValidationState] = useState(null);

  const handleInputChange = useCallback(
    (field, value, err, relatedFieldsUpdate = {}) => {
      if (setFormDirty) {
        setFormDirty(true);
      }

      const orderedFields = Array.from(fieldsRef.current.keys());
      const fieldIndex = orderedFields.findIndex((item) => item === field);
      const newState = structuredClone(formState);
      if (err) {
        if (!newState.errors) {
          newState.errors = new Map();
        }
        const error = {
          fieldId: field,
          order: fieldIndex,
          ...err,
        };
        newState.errors.set(field, error);
        newState[field] = value;
        const result = { ...newState, ...relatedFieldsUpdate };
        setFormState(result);
        return result;
      }
      // reset error for the field
      if (newState?.errors?.has(field)) {
        newState.errors.delete(field);
      }
      newState[field] = value;
      const result = { ...newState, ...relatedFieldsUpdate };
      setFormState(result);
      // clear error state on input
      if (validationState && validationState[field]) {
        const currentValidationState = validationState;
        delete currentValidationState[field];
        setValidationState(currentValidationState);
      }

      return result;
    },
    [formState, setFormState, setFormDirty, validationState]
  );

  const validateFormState = useCallback(
    (t, initialFormData) => {
      function modifyState(errors) {
        const newState = formState;
        if (errors?.size) {
          for (const error of errors.values()) {
            // resetToDefaultOnValidateErr - submit with init values if error
            if (resetToDefaultOnValidateErr && error.errorMessage) {
              // we do not reset errors as we want show them again on next submit or validation circle
              // newState.errors.delete(error.fieldId);
              newState[error.fieldId] = initialFormData[error.fieldId];
              continue;
            }
            // before submit we want to clean all values with removeOnSubmit flag
            if (error.removeOnSubmit) {
              // we delete error as removeOnSubmit means it is not real error and the value should be skipped in payload
              newState.errors.delete(error.fieldId);
              newState[error.fieldId] = null;
            }
          }
        }
        return newState;
      }

      const newFormState = modifyState(formState.errors, setFormState);
      setFormState(newFormState);

      let isValidStatus = true;
      firstFieldWithErrorRef.current = null;
      // required field validation
      const sortedFields = fieldsRef.current.keys();

      let errors = {};
      for (const field of sortedFields) {
        let currentFieldRef = fieldsRef.current.get(field);
        currentFieldRef =
          currentFieldRef?.props ??
          // special case for our Wysiwyg editor ref
          currentFieldRef?.containerRef?.current ??
          currentFieldRef;
        let isRequired = false;
        if (currentFieldRef) {
          // if it is a StateManager of react-select
          if (currentFieldRef.selectManager || 'required' in currentFieldRef) {
            isRequired = currentFieldRef.required;
          } else if (currentFieldRef?.querySelector) {
            isRequired = !!currentFieldRef?.querySelector('[required]');
          } else {
            monitoringMessageHelper(
              'This form input is not supported for required field.',
              {
                extra: { currentFieldRef: currentFieldRef },
              }
            );
          }
        }

        const noValue = (item = '', error) => {
          if (error?.removeOnSubmit) return true;

          return typeof item === 'object' ? isEmpty(item) : !('' + item).trim();
        };
        if (
          isRequired &&
          noValue(_.get(newFormState, field), newFormState?.errors?.get(field))
        ) {
          errors[field] = t('This field is required');
          isValidStatus = false;
          if (!firstFieldWithErrorRef.current) {
            firstFieldWithErrorRef.current = field;
          }
        }
      }
      if (!isValidStatus) {
        setValidationState(errors);
        return isValidStatus;
      }

      errors = {};
      let min;
      if (newFormState.errors?.size) {
        //sortedFields[0];
        for (const error of newFormState.errors.values()) {
          if (!firstFieldWithErrorRef?.current || error.order < min) {
            min = error.order;
            firstFieldWithErrorRef.current = error.fieldId;
          }
          errors[error.fieldId] = error.errorMessage;
        }
      }

      isValidStatus = isEmpty(errors);
      setValidationState(isValidStatus ? null : errors);
      return isValidStatus;
    },
    [formState, setFormState, resetToDefaultOnValidateErr]
  );

  const [message, showMessage, flash] = useFlashTransition();

  useEffect(() => {
    if (validationState && Object.keys(validationState).length) {
      flash({
        [firstFieldWithErrorRef.current]:
          validationState[firstFieldWithErrorRef.current],
      });
      // scroll on field with error
      const field = firstFieldWithErrorRef.current;
      let currentFieldRef = fieldsRef.current.get(field);
      currentFieldRef =
        currentFieldRef?.select?.controlRef ??
        // special case for our Wysiwyg editor ref
        currentFieldRef?.containerRef?.current ??
        currentFieldRef;

      // special case for AutosizeInput input
      if (
        currentFieldRef instanceof AutosizeInput &&
        currentFieldRef.input?.scrollIntoView
      ) {
        currentFieldRef.input.scrollIntoView({
          behavior: 'instant',
          block: 'center',
        });
        currentFieldRef.input.focus();
      } else if (currentFieldRef && currentFieldRef.scrollIntoView) {
        async function scrollIntoView() {
          await currentFieldRef.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          currentFieldRef.querySelector('input').focus();
        }
        scrollIntoView();
      }
    }
  }, [validationState, flash]);

  function getValidationObject(field) {
    let validate = null;
    if (message && message[field]) {
      validate = {
        message: message[field],
        showMessage,
      };
    }
    return validate;
  }
  return {
    fieldsRef, // ref to all fields on form
    validationProps: (field) => {
      const validate = getValidationObject(field);
      return {
        ref: (el) => {
          fieldsRef.current.set(field, el);
        }, // ref to relevant field
        validate, // flash error message
        error:
          validationState && validationState[field]
            ? validationState[field]
            : null, // keep highlight in red
        resetToDefaultOnValidateErr,
        disableOldValidation: true, // temporary property to disable validation in FieldInput
      };
    },
    validateFormState, // run form validation
    handleInputChange, // run field/form state change
    resetValidationState: () => setValidationState(null),
    firstFieldWithErrorRef,
    setValidationState,
    validationState,
  };
}
