import { components } from 'react-select';
import React from 'react';
import { StyledIconSelectorOption } from './styles';
import Icon from 'components/Kizen/Icon';

const IconSelectorOption = ({ children, innerRef, innerProps, ...rest }) => {
  const { selectProps, value } = rest;
  const { externalOptions, optionsMapping } = selectProps;

  const icon = externalOptions[optionsMapping[value]];

  return (
    <StyledIconSelectorOption ref={innerRef} {...innerProps}>
      <components.Option {...rest}>
        {icon.className ? (
          <i className={icon.className} />
        ) : (
          <Icon className={`DefaultIcon ${icon.value}`} icon={icon.value} />
        )}
      </components.Option>
    </StyledIconSelectorOption>
  );
};

export default IconSelectorOption;
