import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { breakpoints, spaceBetweenGutters, gutters } from 'app/spacing';

const Wrapper = styled.div`
  display: flex;
  ${({ addSpacer }) =>
    addSpacer &&
    css`
      flex-wrap: wrap;
      justify-content: space-between;
    `}
  > * {
    width: 100%;
    @media (min-width: ${breakpoints.lg}px) {
      width: ${spaceBetweenGutters};
    }
  }
`;

const Cols = forwardRef(
  ({ columns, gutter, children, addSpacer = true, ...others }, ref) => {
    return (
      <Wrapper
        ref={ref}
        columns={columns}
        gutter={gutter}
        addSpacer={addSpacer}
        {...others}
      >
        {children}
        {addSpacer
          ? [...Array(Math.max(0, columns - 2))].map((_, i) => (
              // Spacers so that items remain next to each other even when spaced-between
              // (e.g. when there are two items on a three-column row).
              // Not using <div /> so that it can't screw-up SubSection's last-of-type selector
              <span className="spacer" key={i} />
            ))
          : null}
      </Wrapper>
    );
  }
);

Cols.displayName = 'Cols';

Cols.propTypes = {
  columns: PropTypes.number.isRequired,
  gutter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Cols.defaultProps = {
  gutter: gutters.standard,
};

export default Cols;
