import { CloseMode, Stream, reactive } from '@thi.ng/rstream';
import { Permission, permissionsTransducers } from './permission';
import { AndRule, OrRule, Rule } from './rules/rule';
import type { RuleDef } from './rules/types';
import { isCombinatorRule, isAndRule, isOrRule } from './rules/utils';
import {
  PermissionConfig,
  PermissionResultData,
  PermissionsContext,
  SectionConfig,
  StreamValue,
} from './types';
import { getAccessNumber, getStreamKey } from './utils';

type Config =
  | Pick<SectionConfig, 'allowed_access' | 'default' | 'key'>
  | Pick<PermissionConfig, 'allowed_access' | 'default' | 'key'>;

/**
 * Build a key/stream tuple. The stream is created with an initial value taken
 * from the `default` property in the config.
 *
 * @param config - a section or permission config object
 * @param parentKey - a section key if the provided config is a permission config.
 * @returns {[string, Stream<StreamValue>]}
 */
export const getSourceStreamEntry = (
  config: Config,
  parentKey?: string
): [string, Stream<StreamValue>] => {
  const value: StreamValue = {
    value:
      typeof config.default === 'boolean'
        ? config.default
        : getAccessNumber(config.default),
    allowed_access: config.allowed_access,
  };
  const key = parentKey ? getStreamKey(config.key, parentKey) : config.key;
  return [key, reactive(value, { id: key, closeOut: CloseMode.NEVER })];
};

/**
 * Return the proper Rule class based on the rule definition.
 *
 * @param permissions - the context's `permissions` Map.
 * @param rule - a config object's rule definition.
 * @param parentKey - a section key if the rule comes from a permission config object.
 * @returns - Rule, AndRule, or OrRule class
 */
export const getRule = (
  permissions: PermissionsContext['permissions'],
  rule: RuleDef<any, any, any>,
  parentKey?: string
) => {
  const permissionStreams: Map<string, Permission['result']> = new Map(
    Array.from(permissions.entries(), ([key, perm]) => [key, perm.value])
  );
  if (!isCombinatorRule(rule)) {
    return new Rule<PermissionResultData>(
      rule,
      permissionStreams,
      permissionsTransducers,
      parentKey
    );
  }
  if (isAndRule(rule))
    return new AndRule<PermissionResultData>(
      rule,
      permissionStreams,
      permissionsTransducers,
      parentKey
    );
  if (isOrRule(rule))
    return new OrRule<PermissionResultData>(
      rule,
      permissionStreams,
      permissionsTransducers,
      parentKey
    );
  throw Error(`Unable to create rule for config section with ${rule}`);
};
