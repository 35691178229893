import React, { useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import TitleField from '../../fields/title';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import {
  LEVEL_OF_DETAIL_OPTIONS,
  REPORT_TYPES,
} from 'components/Wizards/Dashlet/types';
import { gutters } from 'app/spacing';
import DatapointFrequencyField from '../../fields/datapointFrequency';
import {
  StyledHistorySwitch,
  StyledWizardField,
} from 'components/Wizards/shared/styles';
import Switch from 'components/Kizen/Switch';
import KizenTypography from 'app/kizentypo';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'components/Inputs/ButtonGroup';

const StagesOverTimeReportType = ({
  name,
  defaultName,
  onChangeName,
  mobile,
  active,
}) => {
  const { t } = useTranslation();
  const { state, setState, entityName } = useContext(
    WizardStateContext.Context
  );
  const { reportType, datapointFrequency, displayPercent, levelOfDetail } =
    state;

  const onChangeDatapointFrequency = useCallback(
    (value) => {
      return setState('datapointFrequency', value);
    },
    [setState]
  );

  const onChangeDisplayPercent = useCallback(
    (e) => {
      return setState('displayPercent', e.target.checked);
    },
    [setState]
  );

  const sumOfStagesOption = useMemo(() => {
    return {
      label: t('Sum Of Stages'),
      value: LEVEL_OF_DETAIL_OPTIONS.SUM_OF_STAGES,
    };
  }, [t]);

  const levelOfDetailOptions = useMemo(() => {
    return [
      sumOfStagesOption,
      {
        label: t('Stage Breakdown'),
        value: LEVEL_OF_DETAIL_OPTIONS.STAGE_BREAKDOWN,
      },
    ];
  }, [t, sumOfStagesOption]);

  useDefaultValues(
    {
      displayPercent: true,
      levelOfDetail: sumOfStagesOption,
    },
    active
  );

  // Hide self if the report type is not stages over time
  if (reportType?.value !== REPORT_TYPES.STAGES_OVER_TIME) {
    return null;
  }

  return (
    <>
      <StyledWizardField top={gutters.spacing(2, 2)}>
        <KizenTypography type="subheader">
          {t('Choose Level of Detail')}
        </KizenTypography>
        <StyledWizardField top={gutters.spacing(1, 1)}>
          <ButtonGroup
            value={levelOfDetail}
            onChange={(v) => setState('levelOfDetail', v)}
            sizing={!mobile ? 'auto' : ''}
            options={levelOfDetailOptions}
            className="wizard-buttons level-of-detail"
            columns={mobile ? 2 : 4}
            mode="wizard"
            gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
          />
        </StyledWizardField>
      </StyledWizardField>
      {levelOfDetail?.value === LEVEL_OF_DETAIL_OPTIONS.SUM_OF_STAGES ? (
        <StyledWizardField top={gutters.spacing(3, 3)}>
          <StyledHistorySwitch left={gutters.spacing(4)}>
            <StyledWizardField top={gutters.spacing(1, 4)}>
              <KizenTypography type="subheader">
                {t('Display % Change Over Time?')}
              </KizenTypography>
            </StyledWizardField>
            <Switch
              checked={displayPercent ?? false}
              onChange={onChangeDisplayPercent}
              className="history-switch"
            />
          </StyledHistorySwitch>
        </StyledWizardField>
      ) : (
        <StyledWizardField top={gutters.spacing(3, 2)} />
      )}
      <DatapointFrequencyField
        value={datapointFrequency}
        onChange={onChangeDatapointFrequency}
        mobile={mobile}
        active={active}
        top={gutters.spacing(2, 3)}
      />
      <TitleField
        name={name}
        defaultName={defaultName}
        onChangeName={onChangeName}
        top={gutters.spacing(6)}
        entityName={entityName}
      />
    </>
  );
};

StagesOverTimeReportType.propTypes = {
  defaultName: PropTypes.string,
  name: PropTypes.string,
  onChangeName: PropTypes.func.isRequired,
  top: PropTypes.number,
  mobile: PropTypes.bool,
  active: PropTypes.bool,
};

StagesOverTimeReportType.defaultProps = {
  defaultName: '',
  name: '',
  top: gutters.spacing(2),
  mobile: false,
  active: false,
};

export default StagesOverTimeReportType;
