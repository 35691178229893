import steps, {
  edgeLabels,
  getStepConfig,
  TRIGGER,
  defaultTrigger,
} from '../steps';

// checking that action/trigger don't have relations to `Go To Step Action`
export const ensureNoGoToStepDependency = (state, id) => {
  return state.steps.some((step) => {
    const data = step.details.stepKey || step.details.triggerKey;
    return step.type === steps.goToAutomationStep.type && data?.value === id;
  });
};

// get a list of that action/trigger have relations to `Go To Step Action`
export const getAllGoToStepDependencies = (state, id) => {
  return state.steps.filter((step) => {
    const data = step.details.stepKey || step.details.triggerKey;
    return step.type === steps.goToAutomationStep.type && data?.value === id;
  });
};

const ensureNoExtraBranches = (state, id) => {
  // When moving or deleting a step, we need to ensure
  // it doesn't leave any extra branches lying around.
  const hasSibling = state.steps.some(({ parentKey }) => id === parentKey);

  if (!hasSibling) {
    delete state.branches[id || TRIGGER];
  }
  return state;
};

function findLastIndex(tree, parentKey, label = '') {
  const children = tree.filter(
    (el) => el.fromLabel === label && el.parentKey === parentKey
  );
  if (!children.length) {
    return 0;
  }
  const getLastIndex = (arr) => {
    const childrenIndexes = arr.map((el) =>
      tree.findIndex((curr) => curr.id === el.id)
    );
    return childrenIndexes[childrenIndexes.length - 1];
  };
  if (label === 'yes') {
    const filteredChildren = children.filter((el) => el.fromLabel === label);
    if (filteredChildren.length) {
      return getLastIndex(filteredChildren);
    }
    return 0;
  }

  if (label === 'no') {
    const filteredChildrenNo = children.filter((el) => el.fromLabel === label);
    if (filteredChildrenNo.length) {
      return getLastIndex(filteredChildrenNo);
    }
    const filteredChildrenYes = children.filter((el) => el.fromLabel === 'yes');
    if (filteredChildrenYes.length) {
      return getLastIndex(filteredChildrenYes);
    }
    return 0;
  }
}

export const moveOrInsertStep = (
  state,
  { step, id, parentKey, leaf, label }
) => {
  const stepConfig = getStepConfig(step.type);

  if (step.id && !stepConfig.movable) {
    // Currently we only know how to move action, delay and goal steps
    return state;
  }

  // if leaf is true adding from an new branch so we don't want to add another
  if (leaf && (parentKey || TRIGGER) in state.branches) {
    // When dropping on an open branch, ensure it doesn't stick around
    delete state.branches[parentKey || TRIGGER];
  }
  const lastIndex = label && findLastIndex(state.steps, parentKey, label.type);
  const newEl = step.id
    ? {
        // Moving an existing step
        ...step,
        parentKey,
        fromLabel: (label && label.type) || '',
      }
    : {
        // Adding a new step
        ...step,
        id: `new.${step.type}.${Date.now()}`,
        parentKey,
        fromLabel: (label && label.type) || '',
        details: step.details || {},
      };
  state.dirty = true;
  state.steps = state.steps.flatMap((el, index) => {
    if (step.id && el.parentKey === step.id) {
      // Attach child of existing step to that step's parent.
      // This is the simplest move strategy, which we use for action and delay types.
      // Could apply to multiple opterations below, particularly the leaf case and fallthrough.
      el = {
        ...el,
        parentKey: step.parentKey,
        fromLabel: step.fromLabel,
      };
    }
    if (step.id && el.id === step.id) {
      // When moving a step, remove it from its previous position.
      return [];
    }
    if (!lastIndex && parentKey === el.id && leaf) {
      // For leaves, place the element after the source node.
      return [el, newEl];
    }
    if (el.id === id) {
      // Add element before the target node, and update the target node's parent.
      // Placing the new element first helps keep the graph layout stable as nodes are added.
      return [
        newEl,
        {
          ...el,
          parentKey: newEl.id,
          fromLabel:
            newEl.type === steps.condition.type || newEl.goalType
              ? edgeLabels.yes.type
              : '',
        },
      ];
    }
    return el;
  });

  if (lastIndex && leaf) {
    //as we can create multiple branches from a condition/goal yes(no) branch we want to insert new node to the top of sibling nodes
    const firstChildIndex = state.steps.findIndex(
      (el) => el.fromLabel === label.type && el.parentKey === parentKey
    );
    state.steps = state.steps.reduce(
      (collect, el, index) =>
        firstChildIndex === index ? [...collect, newEl, el] : [...collect, el],
      []
    );
  }
  if (!parentKey && !id) {
    // Hey, we have a new root step! This is a direct child of triggers.
    if (state.branches[TRIGGER]) {
      // ensure there isn't a hanging trigger branch
      delete state.branches[TRIGGER];
    }
    state.steps = [newEl, ...state.steps];
  }

  if (step.id) {
    // Ensure no bonus branches where step was dragged from
    ensureNoExtraBranches(state, step.parentKey);
  }

  return state;
};

export const editStep = (
  state,
  { step: { id, parentKey, ...others } },
  entityName = 'steps'
) => {
  const step = state[entityName].find((s) => s.id === id);
  if (step) {
    state.dirty = true;

    Object.assign(step, { ...others, hasError: false, parentKey });

    // remove from error steps if need
    if (state.errorSteps.some((err) => err === id)) {
      state.deletedSteps = [...state.deletedSteps, id];

      state.errorSteps = state.errorSteps.filter((err) => err !== id);
    }
  }
  return state;
};

export const updateStep = (
  state,
  {
    step: { id, ...others },
    entityName = 'steps',
    queName = 'conditionDescriptionQue',
  }
) => {
  const step = state[entityName].find((s) => s.id === id);
  if (step) {
    Object.assign(step, { ...others });

    state[queName] = state[queName].filter((conditionId) => conditionId !== id);
    state.dirty = true;
  }
  return state;
};

export const clearDescriptionQue = (
  state,
  { queName = 'conditionDescriptionQue' }
) => {
  state[queName] = [];
  return state;
};

export const updateTrigger = (
  state,
  {
    trigger: { id, ...others },
    entityName = 'triggers',
    queName = 'triggerDescriptionQue',
  }
) => {
  const trigger = state[entityName].find((s) => s.id === id);
  if (trigger) {
    Object.assign(trigger, { ...others });

    state[queName] = state[queName].filter((conditionId) => conditionId !== id);
    state.dirty = true;
  }
  return state;
};

export const moveStepsAfterGotoMove = (
  state,
  { step: { id, ...others }, dropParent: { parentKey, label } },
  entityName = 'steps'
) => {
  const step = state[entityName].find((s) => s.id === id);
  if (step) {
    state.dirty = true;
    if (!label) {
      // not a branch
      Object.assign(step, { ...others, parentKey });
    }
  }
  return state;
};
export const setStepError = (
  state,
  { step: { id, parentKey, ...others } },
  entityName = 'steps'
) => {
  const step = state[entityName].find((s) => s.id === id);
  if (step) {
    Object.assign(step, { ...others, hasError: true, parentKey });
  }
  return state;
};

export const deleteStep = (
  state,
  { id, force = false },
  entityName = 'steps'
) => {
  const deleted = state[entityName].find((el) => el.id === id);

  if (!force && ensureNoGoToStepDependency(state, deleted.id)) {
    state.interrupting = true;
    return state;
  }

  if (!deleted) {
    return state;
  }

  state.dirty = true;
  state.steps = state.steps.flatMap((el) => {
    if (el.id === id) {
      return []; // Remove
    }
    if (el.parentKey === id) {
      return {
        ...el,
        parentKey: deleted.parentKey,
        fromLabel: deleted.fromLabel,
      };
    }
    return el;
  });
  // did we delete from a condtion with an additonal branch ?
  if (
    state.branches[deleted.parentKey] &&
    state.branches[deleted.parentKey] === deleted.fromLabel
  ) {
    delete state.branches[deleted.parentKey];
  }
  // don't delete branch if the parent is the trigger block
  if (deleted.parentKey) {
    ensureNoExtraBranches(state, deleted.parentKey);
  }
  // clear branch from the step we are deleting
  if (state.branches[id]) {
    delete state.branches[id];
  }

  state.prevSteps = state.steps;
  state.interrupting = false;

  // remove from error steps if need
  if (state.errorSteps.some((err) => err === id)) {
    state.deletedSteps = [...state.deletedSteps, id];

    state.errorSteps = state.errorSteps.filter((err) => err !== id);
  }

  return state;
};

// good to have it for future
// eslint-disable-next-line no-unused-vars
function findLastInBranch(node, branch, condition) {
  const child = branch.find(
    (el) =>
      el.parentKey === node.id && (!el.fromLabel || el.fromLabel === condition)
  );
  if (child) {
    return findLastInBranch(child, branch, condition);
  }
  return node;
}

export function deleteNode(tree, node) {
  const newTree = tree.flatMap((el) => {
    if (el.id === node.id) {
      return []; // Remove
    }
    if (el.parentKey !== node.id) {
      return el;
    }
    return {
      ...el,
      parentKey: node.parentKey,
      fromLabel: node.fromLabel,
    };
  });
  return newTree;
}

function deleteLastNode(tree, node) {
  const child = tree.find((el) => el.parentKey === node.id);
  if (child) {
    return deleteLastNode(tree, child);
  }

  return deleteNode(tree, node);
}

export function deleteBranchStrategy(tree, id) {
  let deleted = tree.find((el) => el.id === id);
  if (!deleted) {
    return tree;
  }

  do {
    tree = deleteLastNode(tree, deleted);
    deleted = tree.find((el) => el.id === id);
  } while (deleted);
  return tree;
}

export const mergePathStrategy = (tree, id) => {
  const deleted = tree.find((el) => el.id === id);
  if (!deleted) {
    return tree;
  }

  tree = tree.flatMap((el) => {
    if (el.id === deleted.id) {
      return []; // Remove
    }
    if (el.parentKey === deleted.id && el.fromLabel) {
      // if fromLabel === 'no' - we want to marge with 'yes' branch
      const yesConditionSibling = tree.find(
        (n) => n.parentKey === deleted.id && n.fromLabel === edgeLabels.yes.type
      );
      if (el.fromLabel === edgeLabels.yes.type || !yesConditionSibling) {
        return {
          ...el,
          parentKey: deleted.parentKey,
          fromLabel: deleted.fromLabel,
        };
      }
      if (yesConditionSibling) {
        const leafInYesBranch = findLastInBranch(
          yesConditionSibling,
          tree,
          'yes'
        );

        return {
          ...el,
          parentKey: leafInYesBranch.id,
          fromLabel: ['condition', 'goal'].includes(leafInYesBranch.type)
            ? leafInYesBranch.fromLabel
            : '',
        };
      }
    }
    return el;
  });

  return tree;
};

export const leaveBothStrategy = (tree, id) => {
  const deleted = tree.find((el) => el.id === id);
  if (!deleted) {
    return tree;
  }
  tree = deleteNode(tree, deleted);

  return tree;
};

export const keepOnlyStrategy = (tree, id, fromLabelToKeep) => {
  const deleted = tree.find((el) => el.id === id);
  if (!deleted) {
    return tree;
  }
  const branchesToRemoved = tree.filter(
    (el) => el.parentKey === deleted.id && el.fromLabel !== fromLabelToKeep
  );
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < branchesToRemoved.length; i++) {
    const element = branchesToRemoved[i];
    tree = deleteBranchStrategy(tree, element.id);
  }
  tree = deleteNode(tree, deleted);

  return tree;
};

export const deleteTrigger = (state, { id, force = false }) => {
  if (!force && ensureNoGoToStepDependency(state, id)) {
    state.interrupting = true;
    return state;
  }
  const firstTrigger = state.triggers[0];
  if (state.triggers.length === 1) {
    if (firstTrigger.type !== 'manual') {
      state.triggers[0] = {
        ...defaultTrigger,
        id: `${defaultTrigger.id}.${Date.now()}`,
        order: state.triggers.length,
      };
      state.dirty = true;
    }
  } else {
    if (!id.startsWith('new')) {
      state.dirty = true;
    }
    state.triggers = state.triggers.filter((trigger) => trigger.id !== id);
  }

  // triggers are part of error steps, remove  if need
  if (state.errorSteps.some((err) => err === id)) {
    state.deletedSteps = [...state.deletedSteps, id];

    state.errorSteps = state.errorSteps.filter((err) => err !== id);
  }

  state.interrupting = false;
  return state;
};

export const moveBranch = (
  state,
  { step, id, parentKey, leaf, label, strategy }
) => {
  const stepConfig = getStepConfig(step.type);
  if (step.id && !stepConfig.movable) {
    // Currently we only know how to move action and delay steps
    return state;
  }

  const lastIndex = label && findLastIndex(state.steps, parentKey, label.type);
  const newEl = {
    // Moving an existing step
    ...step,
    parentKey,
    fromLabel: (label && label.type) || '',
  };

  state.dirty = true;
  state.steps = state.steps.flatMap((el, index) => {
    if (step.id && el.parentKey === step.id) {
      // Attach child of existing step to that step's parent.
      // This is the simplest move strategy, which we use for action and delay types.
      // Could apply to multiple opterations below, particularly the leaf case and fallthrough.
      el = {
        ...el,
      };
    }

    if (step.id && el.id === step.id) {
      // When moving a step, remove it from its previous position.
      return [];
    }

    if (lastIndex && lastIndex === index && leaf) {
      return [el, newEl];
    }

    if (!lastIndex && el.id === parentKey && leaf) {
      // For leaves, place the element after the source node.
      return [el, newEl];
    }

    const firstElem = state.steps.find(
      (e) => e.parentKey === step.id && e.fromLabel === strategy
    );

    if (el.id === id) {
      // Add element before the target node, and update the target node's parent.
      // Placing the new element first helps keep the graph layout stable as nodes are added.
      const lastInBranch =
        firstElem && findLastInBranch(firstElem, state.steps, strategy);
      return [
        newEl,
        {
          ...el,
          parentKey: firstElem
            ? findLastInBranch(firstElem, state.steps, strategy).id
            : newEl.id,
          fromLabel: lastInBranch
            ? ['condition', 'goal'].includes(lastInBranch.type)
              ? edgeLabels.yes.type
              : ''
            : strategy,
        },
      ];
    }
    return el;
  });

  if (!parentKey && !id) {
    // Hey, we have a new root step! This is a direct child of triggers.
    if (state.branches[TRIGGER]) {
      // ensure there isn't a hanging trigger branch
      delete state.branches[TRIGGER];
    }
    state.steps = [newEl, ...state.steps];
  }

  if (step.id) {
    // Ensure no bonus branches where step was dragged from
    ensureNoExtraBranches(state, step.parentKey);
  }
  return state;
};
