import {
  EmailButtonCreator,
  HomepageButtonCreator,
  buildFormButtonCreator,
} from '../nodes/Button/CreateButton';
import { CustomFieldCreator } from '../nodes/CustomField/CreateCustomField';
import { AttachmentsCreator } from '../nodes/Attachments/CreateAttachments';
import {
  EmailImageCreator,
  FormImageCreator,
} from '../nodes/Image/CreateImage';
import { buildFormFieldCreator } from '../nodes/FormField/CreateFormField';
import {
  EmailRowCreator,
  FormRowCreator,
  HomepageRowCreator,
} from '../nodes/Row/CreateRow';
import {
  EmailSectionCreator,
  FormSectionCreator,
  HomepageSectionCreator,
} from '../nodes/Section/CreateSection';
import { EmailTextCreator, FormTextCreator } from '../nodes/Text/CreateText';
import { FormHTMLBlockCreator } from '../nodes/HTMLBlock/CreateHTMLBlock';
import { EmailDividerCreator, FormDividerCreator } from '../nodes/Divider';

export const emailCreatorElements = [
  EmailButtonCreator,
  EmailImageCreator,
  EmailRowCreator,
  EmailSectionCreator,
  EmailTextCreator,
  EmailDividerCreator,
  AttachmentsCreator,
];

export const formCreatorElements = (isFormPage, heading) => [
  CustomFieldCreator,
  buildFormButtonCreator(isFormPage),
  FormImageCreator,
  buildFormFieldCreator(heading),
  FormRowCreator,
  FormSectionCreator,
  FormTextCreator,
  FormHTMLBlockCreator,
  FormDividerCreator,
];

export const homePageCreatorElements = [
  HomepageSectionCreator,
  HomepageRowCreator,
  FormTextCreator,
  FormImageCreator,
  FormDividerCreator,
  FormHTMLBlockCreator,
  HomepageButtonCreator,
];

export const staticBlockCreatorElements = [
  HomepageSectionCreator,
  HomepageRowCreator,
  FormTextCreator,
  FormImageCreator,
  FormDividerCreator,
  FormHTMLBlockCreator,
  HomepageButtonCreator,
];
