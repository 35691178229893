import Switch from '__components/Kizen/Switch';
import { SwitchControl, StyledValidatedWholeNumberTextInput } from './styles';
import useToggle from 'react-use/lib/useToggle';
import { useTranslation } from 'react-i18next';
import InputControl from 'components/Inputs/InputControl';
import { blue } from '@kizen/kds/Colors';
import { useState } from 'react';

interface SmartSendProps {
  onChange: (value: number | undefined) => void;
  value?: number;
  defaultValues?: number;
  disabled?: boolean;
}
const SmartSend = ({
  onChange,
  value, // [0-100] or undefined
  defaultValues = 50,
  disabled = false,
}: SmartSendProps) => {
  const { t } = useTranslation();
  const [showSmartSend, toggleShowSmartSend] = useToggle(!!value);
  const [internalValue, setInternalValue] = useState(
    !value || !showSmartSend ? defaultValues : value
  );

  return (
    <>
      <SwitchControl
        margin
        disabled={disabled}
        checked={showSmartSend}
        disabledBackgroundColor={blue['blue-500']}
        label={t('Enable SmartSend A.I.')}
        labelInfo={t(
          'Send at predicted optimal send time to maximize interaction.'
        )}
        variant={undefined}
        className={undefined}
      >
        <Switch
          disabled={disabled}
          checked={showSmartSend}
          onChange={(e) => {
            toggleShowSmartSend();
            if (e.target.checked) {
              setInternalValue(defaultValues);
            }
            onChange(e.target.checked ? defaultValues : undefined);
          }}
        />
      </SwitchControl>
      {showSmartSend ? (
        <InputControl
          label={t('What % of Emails Should Use SmartSend?')}
          sizing="shrink"
          variant={undefined}
          className={undefined}
        >
          <StyledValidatedWholeNumberTextInput
            value={internalValue}
            disabled={disabled || !showSmartSend}
            minValue={0}
            maxValue={100}
            onChange={(v: string) => {
              setInternalValue(parseInt(v));
              onChange(parseInt(v));
            }}
            allowNegatives={false}
            validate={
              !internalValue && {
                message: t('This value should be more than 0'),
                showMessage: true,
                inModal: true,
              }
            }
          />
        </InputControl>
      ) : null}
    </>
  );
};
export default SmartSend;
