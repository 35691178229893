import DOMPurify from 'dompurify';
import { useMemo } from 'react';

export const useSanitizedHtml = (html, options) => {
  return useMemo(() => {
    try {
      const cleanHtml = DOMPurify.sanitize(html, options);
      // need this for better understanding what was removed;
      console.debug('sanitized elements:', DOMPurify.removed);
      return cleanHtml;
    } catch (err) {
      return '';
    }
  }, [html, options]);
};
