import { getDefaultField, getEditable } from 'pages/ContactsPage/columns';
import React from 'react';
import { DEFAULT_FIELD_NAMES } from 'utility/constants';

export const getNextField = (
  {
    columnSettings,
    getColumns,
    fields,
    model,
    businessTimezone,
    t,
    preReleaseFeatures,
  },
  creationFieldId,
  currentId
) => {
  const columnIds = getColumns({
    fields,
    columnSettings,
    model,
    businessTimezone,
    preReleaseFeatures,
    t,
  })
    .filter((c) => c?.meta?.editable)
    .map(({ id }) => id);
  if (!columnIds.length) {
    return null;
  }
  if (currentId) {
    const currentIndex = columnIds.indexOf(currentId);

    if (currentIndex + 1 >= columnIds.length) {
      return null;
    }

    return columnIds[currentIndex + 1];
  }
  return creationFieldId;
};

export const cloneFormattedElement = (fn, props) => {
  if (!fn) {
    return fn;
  }
  return (...args) => {
    const el = fn(...args);
    if (!el) {
      return el;
    }
    return React.cloneElement(el, props);
  };
};

export const getDefaultNewRecord = (owner, id = 'new') => ({
  id: id,
  fields: [],
  access: { view: true, edit: true },
  owner,
});

export const getCreationFieldIds = (isCustomObject, fields) => {
  if (isCustomObject)
    return [DEFAULT_FIELD_NAMES.name, DEFAULT_FIELD_NAMES.name];
  const firstNameField = getDefaultField(DEFAULT_FIELD_NAMES.firstName, fields);
  return [
    getEditable(firstNameField) ? 'fullName' : DEFAULT_FIELD_NAMES.email,
    DEFAULT_FIELD_NAMES.email,
  ];
};
