import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import {
  ListCardCell,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
} from 'components/Layout/ListCard';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { TextEllipsis } from 'components/Kizen/Table';
import { layers } from 'app/spacing';
import { useTranslation } from 'react-i18next';
import { StyledListCardHeader, StyledListCardItem } from './styles';

export const CheckboxStyles = styled(StylePassthrough)`
  label,
  label& {
    margin: 0;
    padding: 0;
    // Change hover color to dark grey
    &:hover input[type='checkbox']:not(:checked) + .CheckboxInputFacade {
      border-color: ${colorsButton.iconGray.hover};
    }
  }
  .CheckboxText {
    display: none;
  }
  .CheckboxInputFacade {
    // In chrome the input facade's position: relative creates a new stacking
    // context, causing this element to appear on top of the page's sticky header.
    z-index: ${layers.content(0)};
    margin-left: 0;
  }
`;

const ContactItemCell = styled(ListCardCell)`
  > :first-child {
    flex: 1;
  }
  > :last-child:not(:first-child) {
    margin-left: 5px;
  }
`;

const EmailIcon = styled(Icon)`
  && svg {
    width: 16px;
    height: auto;
  }
`;

const PhoneIcon = styled(Icon)`
  && svg {
    width: 12px;
    height: auto;
  }
`;

const NO_VALUE = '—';

export const EmptyContactListItem = ListCardItem;

export function ContactListItem({ onChecked, checked, contact, ...others }) {
  const { t } = useTranslation();
  return (
    <StyledListCardItem {...others}>
      <ListCardRow>
        <ContactItemCell>
          <TextEllipsis type="link" to={`/client/${contact.id}/details`}>
            {contact.fullName || NO_VALUE}
          </TextEllipsis>
        </ContactItemCell>
      </ListCardRow>
      <ListCardRow>
        <ContactItemCell>
          {!contact.email && <TextEllipsis as="span">{NO_VALUE}</TextEllipsis>}
          {contact.email && (
            <TextEllipsis as="span">{contact.email}</TextEllipsis>
          )}
          {contact.email && (
            <IconButton
              as="a"
              title={t('Send Email')}
              href={`mailto:${contact.email}`}
              sizing="dense"
              color={colorsButton.iconGray}
            >
              <EmailIcon icon="email" />
            </IconButton>
          )}
        </ContactItemCell>
        <ContactItemCell>
          {!contact.mobilePhone && (
            <TextEllipsis as="span">{NO_VALUE}</TextEllipsis>
          )}
          {contact.mobilePhone && (
            <TextEllipsis as="span">{contact.mobilePhone}</TextEllipsis>
          )}
          {contact.mobilePhone && (
            <IconButton
              title={t('Call')}
              as="a"
              href={`tel:${contact.mobilePhone}`}
              sizing="dense"
              color={colorsButton.iconGray}
            >
              <PhoneIcon icon="phone" />
            </IconButton>
          )}
        </ContactItemCell>
      </ListCardRow>
    </StyledListCardItem>
  );
}

export function ContactListHeader({
  label,
  sort = null,
  onChangeSort = null,
  onChangeSelection = null,
  selectionOptions,
  checked = false,
  ...others
}) {
  return (
    <StyledListCardHeader {...others} checked={checked}>
      <ListCardCell>
        <TextEllipsis as="span" weight="bold">
          {label}
        </TextEllipsis>
        <ListCardSortButton
          sort={sort && sort.column === 'full_name' ? sort : null}
          onChangeSort={(direction) => {
            onChangeSort({
              column: 'full_name',
              direction,
            });
          }}
        />
      </ListCardCell>
    </StyledListCardHeader>
  );
}
