import { useToast, toastVariant } from '__components/ToastProvider';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { useGenericPluginCustomScript } from 'ts-components/ScriptRunner/hooks/useGenericPluginCustomScript';

export const usePluginCustomHTML = (currentPage?: any, args?: any) => {
  const [showToast] = useToast();
  const { t } = useTranslation();
  const outputUIRef = useRef<any>(null);
  const interactableScriptRef = useRef<any>(null);

  const css = currentPage?.css;

  const html = useMemo(() => {
    const rawHTML = currentPage?.html;

    return rawHTML ? DOMPurify.sanitize(rawHTML) : null;
  }, [currentPage]);

  const interactableScripts = useMemo(() => {
    return currentPage?.event_scripts ?? {};
  }, [currentPage]);

  const [executeInline, { pending: inlinePending }] =
    useGenericPluginCustomScript({
      onError: (e) => {
        showToast({
          message: `${t('Script could not be executed')}: ${e?.message}`,
          variant: toastVariant.FAILURE,
        });
      },
      scriptUIRef: outputUIRef,
      plugin: currentPage,
    });

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (!inlinePending) {
        const target = e.target;
        const scriptName = target.getAttribute('data-script');
        if (scriptName && interactableScripts[scriptName]) {
          executeInline(interactableScripts[scriptName], args);
        }
      }
    },
    [inlinePending, executeInline, interactableScripts, args]
  );

  useEffect(() => {
    const e = interactableScriptRef.current;

    if (e) {
      e.addEventListener('click', handleClick);
    }

    return () => {
      if (e) {
        e.removeEventListener('click', handleClick);
      }
    };
  }, [handleClick, currentPage]);

  return {
    scopedCss: `
        @scope {
          ${css}
        }
    `,
    sanitizedHtml: html,
    outputUIRef,
    interactableScriptRef,
  };
};
