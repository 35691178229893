import { AREA_RESPONSES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import {
  CHART_TYPES as RTDV_CHART_TYPES,
  AREA_RESPONSES as RTDV_AREA_RESPONSES,
} from 'components/Wizards/RTDV/types';

const allowedAreas = [
  AREA_RESPONSES.PIPELINES,
  AREA_RESPONSES.ACTIVITIES,
  AREA_RESPONSES.MARKETING,
  'custom_object', // special value to allow filters on object charts
];

const allowedReportTypes = [
  REPORT_TYPES.RECORDS_ADDED,
  REPORT_TYPES.RECORDS_LOST,
  REPORT_TYPES.RECORDS_WON,
  REPORT_TYPES.OPPORTUNITY_CONVERSION,
  REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS,
  REPORT_TYPES.VALUE_OVER_TIME,
  REPORT_TYPES.STAGES_OVER_TIME,
  REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS,
  REPORT_TYPES.SCHEDULED_ACTIVITIES,
  REPORT_TYPES.LEADS_ADDED_BY_SOURCE,
  REPORT_TYPES.LEADS_ADDED,
  REPORT_TYPES.LEAD_SOURCE_BREAKDOWN,
  RTDV_CHART_TYPES.FIELD_METRICS,
  RTDV_CHART_TYPES.TABLE_OF_RECORDS,
  RTDV_CHART_TYPES.SALES,
  RTDV_AREA_RESPONSES.PIVOT_TABLE,
];

export const areFiltersEnabled = (area, reportType, objectIds) => {
  return (
    allowedAreas.includes(area) &&
    allowedReportTypes.includes(reportType) &&
    (!objectIds || objectIds.length === 1)
  );
};
