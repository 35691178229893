import { useCallback, useRef } from 'react';
import { ListItem, StyledDragItem } from './styles';
import PropTypes from 'prop-types';
import { DASHLET_ACTIONS } from '../constants';
import { deferExecution } from 'utility/defer';
import { EMPLOYEE_ACCESS } from 'components/AccessRequests/utils';

const MENU_BUFFER = 8;

const Item = ({
  element = {},
  handleAction,
  handleChangeName,
  handleChangeOwner,
  handleChangeActive,
  requestMode,
  calculateWidth,
  columns,
  errors,
  setErrors,
  ...others
}) => {
  const itemRef = useRef(null);

  const editEnabled = true;
  const hideShowEnabled = true;
  const deleteEnabled = true;
  const duplicateEnabled = true;

  const editHandler = useCallback(
    (canChangePermissions) => {
      handleAction(
        DASHLET_ACTIONS.EDIT,
        element.id,
        element,
        canChangePermissions
      );

      // Send the item to the back of the z-index stack on click
      // because the blur event won't be fired
      itemRef.current?.sendToBack?.();
    },
    [element, handleAction]
  );

  const hideHandler = useCallback(() => {
    handleAction(DASHLET_ACTIONS.HIDE, element.id, element);
    itemRef.current?.sendToBack?.();
  }, [element, handleAction]);

  const showHandler = useCallback(() => {
    handleAction(DASHLET_ACTIONS.SHOW, element.id, element);
    itemRef.current?.sendToBack?.();
  }, [element, handleAction]);

  const deleteHandler = useCallback(() => {
    handleAction(DASHLET_ACTIONS.DELETE, element.id, element);
    itemRef.current?.sendToBack?.();
  }, [element, handleAction]);

  const duplicateHandler = useCallback(() => {
    handleAction(DASHLET_ACTIONS.DUPLICATE, element.id, element);
    itemRef.current?.sendToBack?.();
  }, [element, handleAction]);

  const requestHandler = useCallback(() => {
    handleAction(DASHLET_ACTIONS.REQUEST, element.id, element);
    itemRef.current?.sendToBack?.();
  }, [element, handleAction]);

  return (
    <StyledDragItem
      ref={itemRef}
      isHidden={element.hidden}
      hideHandle={requestMode}
      compact={element.employee_access === EMPLOYEE_ACCESS.OWNER}
      {...others}
    >
      {columns.map((col) => {
        const content = col.accessor(element, {
          editHandler: editEnabled ? editHandler : undefined,
          hideHandler: hideShowEnabled ? hideHandler : undefined,
          showHandler: hideShowEnabled ? showHandler : undefined,
          deleteHandler: deleteEnabled ? deleteHandler : undefined,
          duplicateHandler: duplicateEnabled ? duplicateHandler : undefined,
          requestHandler,
          handleChangeName,
          handleChangeOwner,
          handleChangeActive,
          onOpen: () => deferExecution(() => itemRef.current?.bringToTop?.()),
          onClose: () => itemRef.current?.sendToBack?.(),
          disabled: !editEnabled,
          requestMode,
          index: others.index,
          menuPlacement:
            Math.max(10, others.countItems) - others.index <= MENU_BUFFER &&
            others.index >= MENU_BUFFER
              ? 'top'
              : undefined,
          errors: errors?.errorForId === element.id ? errors : {},
          setErrors,
        });

        return (
          <ListItem
            key={`${element.id}-${col.id}`}
            width={calculateWidth(col.width, col.shrinkable)}
            align={col.align}
            data-qa-field={col.qaField}
            shrinkMargin={col.id === 'count'}
          >
            {content}
          </ListItem>
        );
      })}
    </StyledDragItem>
  );
};

Item.propTypes = {
  element: PropTypes.object,
  handleAction: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  calculateWidth: PropTypes.func.isRequired,
  countItems: PropTypes.number.isRequired,
};

export default Item;
