export const getSortedFieldsFromPageData = (pageData) => {
  const parser = (pageData, nodeId = 'ROOT') => {
    const nodes = pageData[nodeId].nodes.length
      ? pageData[nodeId].nodes
      : Object.values(pageData[nodeId].linkedNodes || {});
    return nodes.length
      ? nodes.map((id) => parser(pageData, id)).flat()
      : pageData[nodeId].props?.field || null;
  };
  return pageData ? parser(pageData).filter(Boolean) : [];
};
