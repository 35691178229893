import {
  compileEmail,
  compileForm,
  createImage,
  customField,
  image,
  root,
  row1,
  row2,
  row3,
  section,
  submitButton,
  text,
  Row,
  Section,
  DefaultEmailProps,
  compileHomepage,
  compileStaticBlock,
  createText,
} from '@kizen/page-builder/gen';
import {
  CustomObjectField,
  ImageProps,
  SectionProps,
  TextProps,
} from '../types';
import { getDefaultImageProps } from './image';

type Fn<T, R = T> = (x: T) => R;

type Business = {
  logo?: {
    url: string;
    name: string;
    id: string;
  };
  name: string;
  primaryColor: string;
};

type ImageData = {
  id: string;
  url: string;
  name: string;
};

const WHITE = '#FFFFFF';
const APP_BACKGROUND = '#F8FAFF';
const containerPadding = (arr: [number, number, number, number]) => ({
  containerPaddingTop: `${arr[0]}`,
  containerPaddingRight: `${arr[1]}`,
  containerPaddingBottom: `${arr[2]}`,
  containerPaddingLeft: `${arr[3]}`,
});
const noPaddingProps = containerPadding([0, 0, 0, 0]);
const rootProps = { backgroundColor: APP_BACKGROUND };
const customFieldProps = {
  containerBorderRadius: false,
  ...containerPadding([10, 10, 10, 10]),
};
const mainSectionProps = {
  containerBorderColor: '#D8DDE1',
  containerBorderWidth: '1',
  containerBorderRadius: false,
  containerBackgroundColor: WHITE,
};

// When creating html template strings for default content be sure to do the following to match the
// expected behavior of the text element's output (so the equality comparison works):
//  1. escape all newlines
//  2. do not use a trailing ; in the style attribute
//  3. use rgb strings for color (not hex)
const centeredText = (text: string, fontSize = 18) =>
  `<p style="line-height: 1.25; text-align: center" data-line-height="default"><span style="font-size: ${fontSize}px">${text}</span></p>`;
const copyrightText = (businessName: string, year: number, t: Fn<string>) =>
  `<p style="line-height: 1.25; text-align: center" data-line-height="default">${t(
    'Copyright'
  )} ${year} | ${businessName}</p>`;
const captureTheirAttentionText = (t: Fn<string>) => `\
<p style="line-height: 1.25" data-line-height="default"><span style="font-size: 18px; color: rgb(16, 103, 179)">${t(
  'Capture Their Attention.'
)}</span></p>\
<p style="line-height: 1.25" data-line-height="default"><span style="font-size: 24px"><strong>${t(
  'Attract and engage your ideal buyers.'
)}</strong></span></p>\
<p style="line-height: 1.25" data-line-height="default">${t(
  'Seamlessly capture new leads from every touchpoint including website, offline events, ad partners, and in-store interactions. Score leads in real time and intelligently nurture with hyper-personalized communication that decreases customer acquisition cost. Easily hand off qualified leads to sales teams once ready.'
)}</p>\
`;
const knowYourAudienceText = (t: Fn<string>) =>
  `<p style="line-height: 1.25" data-line-height="default"><span style="font-size: 18px; color: rgb(16, 103, 179)">${t(
    'Know Your Audience.'
  )}</span></p><p style="line-height: 1.25" data-line-height="default"><span style="font-size: 24px"><strong>${t(
    'Laser focus outreach to make interactions count.'
  )}</strong></span></p><p style="line-height: 1.25" data-line-height="default">${t(
    "Effortlessly slice-and-dice your data to micro-segment your contact list for each promotion. Automatically customize content and schedule broadcasts with ease to deliver omnichannel, multi-touch campaigns that hit the mark. Put upsell and cross-sell on autopilot with Zoe's nurturing, remarketing, and suppression skills."
  )}</p>`;

const businessColorSection = (
  containerBackgroundColor: SectionProps['containerBackgroundColor']
): Section => {
  return section({ containerBackgroundColor, containerBorderRadius: false });
};

const businessLogoRow = async (
  logo: Business['logo'],
  name: Business['name']
): Promise<Row> => {
  return !logo
    ? row1([text(centeredText(name))])
    : row1([
        image({
          ...(await getDefaultImageProps(logo)),
          containerBorderRadius: false,
          size: 'dynamic',
          unit: 'pixel',
          dimension: 'width',
          width: 150,
        }),
      ]);
};

const submitButtonRow = () => {
  return row3([
    null,
    submitButton({ label: 'Submit', containerBorderRadius: false }),
    null,
  ]);
};

export const getDefaultThankYouPage = async (
  defaultText: string,
  business: Business
) => {
  return compileForm(
    root(rootProps, [
      businessColorSection(business.primaryColor),
      section(mainSectionProps, [
        await businessLogoRow(business.logo, business.name),
        row1([text(centeredText(defaultText, 20))]),
      ]),
    ])
  );
};

export const getDefaultContactForm = async (
  firstName: CustomObjectField,
  lastName: CustomObjectField,
  email: CustomObjectField,
  business: Business
) => {
  return compileForm(
    root(rootProps, [
      businessColorSection(business.primaryColor),
      section(mainSectionProps, [
        await businessLogoRow(business.logo, business.name),
        row2(noPaddingProps, [
          customField(firstName, customFieldProps),
          customField(lastName, customFieldProps),
        ]),
        row1(noPaddingProps, [customField(email, customFieldProps)]),
        row3([
          null,
          submitButton({ label: 'Submit', containerBorderRadius: false }),
          null,
        ]),
      ]),
    ])
  );
};

export const getDefaultCustomObjectForm = async (
  name: CustomObjectField,
  business: Business
) => {
  return compileForm(
    root(rootProps, [
      businessColorSection(business.primaryColor),
      section(mainSectionProps, [
        await businessLogoRow(business.logo, business.name),
        row1(noPaddingProps, [customField(name, customFieldProps)]),
        submitButtonRow(),
      ]),
    ])
  );
};

export const getDefaultEmailTemplate = async (
  business: Business,
  attractAndEngageImage: ImageData,
  laserFocusImage: ImageData,
  translator: Fn<string> = (x) => x
) => {
  const year = new Date().getFullYear();
  const [attractEngageProps, laserFocusProps, logoRow] = await Promise.all([
    getDefaultImageProps(attractAndEngageImage),
    getDefaultImageProps(laserFocusImage),
    businessLogoRow(business.logo, business.name),
  ]);

  return compileEmail(
    root(rootProps, [
      businessColorSection(business.primaryColor),
      section({ containerBackgroundColor: WHITE }, [logoRow]),
      section({ containerBackgroundColor: WHITE }, [
        row2(containerPadding([10, 10, 30, 10]), [
          image({
            ...attractEngageProps,
            size: 'dynamic',
            unit: 'pixel',
            dimension: 'width',
            width: 400,
          }),
          text(captureTheirAttentionText(translator)),
        ]),
        row2([
          text(knowYourAudienceText(translator)),
          image({
            ...laserFocusProps,
            size: 'dynamic',
            unit: 'pixel',
            dimension: 'width',
            width: 400,
          }),
        ]),
        row1([text(copyrightText(business.name, year, translator))]),
      ]),
      section({ containerBackgroundColor: APP_BACKGROUND }),
    ])
  );
};

export const getSendTextEmailTemplate = (content: string) => {
  return compileEmail(root([section([row1([text(content)])])]));
};

export const buildEmailImageNode = (
  props: ImageProps,
  custom: Record<string, any> = {}
) => {
  return createImage({ ...DefaultEmailProps.Image, ...props }, custom);
};

export const buildEmailTextNode = (
  text: string,
  props: TextProps,
  custom: Record<string, any> = {}
) => {
  return createText(text, { ...DefaultEmailProps.Text, ...props }, custom);
};

export const getDefaultHomepageTemplate = (t: Fn<string>) => {
  const elem = text(
    {
      containerBorderRadius: false,
      containerMarginBottom: '10',
      containerMarginLeft: '10',
      containerMarginRight: '10',
      containerMarginTop: '10',
    },
    `<p><span>${t('Customize this')} <strong>${t('rich')}</strong> <em>${t(
      'text'
    )}</em> <u>${t('block')}</u>.</span></p>`
  );
  return compileHomepage(root([section([row3([elem, elem, elem])])]));
};

export const getDefaultStaticBlockContent = (t: Fn<string>) => {
  const top_text = text(
    { containerBorderRadius: false },
    `<p><span style="font-family: Montserrat, sans-serif; font-size: 18px"><strong>Custom Content</strong></span></p>`
  );

  const customize_text = text(
    {
      containerBorderRadius: false,
      containerMarginBottom: '0',
      containerMarginLeft: '10',
      containerMarginRight: '10',
      containerMarginTop: '0',
    },
    `<p><span>${t('Customize this')} <strong>${t('rich')}</strong> <em>${t(
      'text'
    )}</em> <u>${t('block')}</u>.</span></p>`
  );

  return compileStaticBlock(
    root([
      section(
        {
          alignment: 'left',
          containerPaddingTop: '0',
          containerPaddingRight: '0',
          containerPaddingBottom: '0',
          containerPaddingLeft: '0',
        },
        [
          row1(
            {
              alignment: 'left',
              containerPaddingTop: '20',
              containerPaddingRight: '0',
              containerPaddingBottom: '0',
              containerPaddingLeft: '20',
            },
            [top_text]
          ),
        ]
      ),
      section([row3([customize_text, customize_text, customize_text])]),
    ])
  );
};
