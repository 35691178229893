import React, { useCallback, useEffect, useRef } from 'react';
import { breakpoints, useBreakpoint } from 'app/spacing';
import Button from 'components/Button';
import { PageContentContainer } from 'components/Layout/PageContentWidth';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Body, Header, StyledCard } from './styles';
import FileService from 'services/FileService';

const DownloadFilePage = () => {
  const { t } = useTranslation();
  const { id: fileId } = useParams();
  const downloaded = useRef(false);
  const isMobile = useBreakpoint(breakpoints.sm);

  const downloadFile = useCallback(async () => {
    if (fileId) {
      try {
        // first try and get the file from the public endpoint
        await FileService.getPublicFile(fileId);

        // if that worked then we can download the file
        const tempLink = document.createElement('a');
        tempLink.href = `${import.meta.env.VITE_API_BASE_PATH}/files/${fileId}/download`; // todo 4796
        tempLink.download = true;
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      } catch {
        // if that failed then the No Access Page is shown by default
      }
    }
  }, [fileId]);

  useEffect(() => {
    if (!downloaded.current) {
      downloadFile();
      downloaded.current = true;
    }
  }, [downloadFile]);

  return (
    <PageContentContainer>
      <StyledCard mobile={isMobile}>
        <Header
          as="h1"
          type={isMobile ? 'subheader' : 'hero'}
          mobile={isMobile}
        >
          {t('Your File is Ready for Download')}
        </Header>
        <Body as="p" mobile={isMobile}>
          {t(
            'If the download does not begin automatically, please click the button below'
          )}
          .
        </Body>

        <Button onClick={downloadFile} color="blue" preventDefault={false}>
          {t('Download Now')}
        </Button>
      </StyledCard>
    </PageContentContainer>
  );
};

export default DownloadFilePage;
