import { useCallback, useMemo, useState } from 'react';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';

export const usePagination = (isPaginated) => {
  const [ordering, setOrdering] = useState('-last_activity_timestamp');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    isPaginated ? 100 : FORCE_ALL_RECORDS_SIZE
  );
  const handleChangePageSize = (value) => {
    setPage(1);
    setPageSize(value);
  };
  const sortValue = useMemo(() => getSortValue(ordering), [ordering]);
  const handleSort = useCallback(
    (orderingValues) => setOrdering(getOrderingParam(orderingValues)),
    [setOrdering]
  );

  const headData = useMemo(
    () => ({
      meta: {
        sort: sortValue,
        onSort: (column, direction) => handleSort({ column, direction }),
      },
    }),
    [sortValue, handleSort]
  );

  return {
    ordering,
    page,
    pageSize,
    headData,
    setPage,
    setPageSize: handleChangePageSize,
  };
};
