import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { DEFAULT_INPUT_DELAY } from 'utility/config';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';

export const usePaginationSettings = (defultPaginationSettings) => {
  const [{ page, pageSize, search, ordering }, setPaginationSettings] =
    useState(defultPaginationSettings);

  const [debauncedSearch, setDebouncedSearch] = useState(search);

  useDebounce(() => setDebouncedSearch(search), DEFAULT_INPUT_DELAY, [search]);

  const handleChangePage = useCallback(
    (page) =>
      setPaginationSettings((prev) => ({
        ...prev,
        page,
      })),
    []
  );

  const handleChangeSearch = useCallback(
    (search) =>
      setPaginationSettings((prev) => ({
        ...prev,
        page: 1,
        search,
      })),
    []
  );

  const handleChangePageSize = useCallback((pageSize) => {
    setPaginationSettings((prev) => ({
      ...prev,
      page: 1,
      pageSize,
    }));
  }, []);

  const handleChangeOrdering = useCallback(
    (column, direction) =>
      setPaginationSettings((prev) => ({
        ...prev,
        ordering: getOrderingParam({ column, direction }),
      })),
    []
  );

  const headData = useMemo(
    () => ({
      meta: {
        sort: getSortValue(ordering),
        onSort: handleChangeOrdering,
      },
    }),
    [ordering, handleChangeOrdering]
  );

  return {
    page,
    pageSize,
    search: debauncedSearch,
    actualSearch: search,
    ordering,
    handleChangePage,
    handleChangeSearch,
    handleChangePageSize,
    handleChangeOrdering,
    headData,
  };
};
