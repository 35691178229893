import React from 'react';

export default function SurveyEntityIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.4286 0H2.57143C1.15179 0 0 1.15179 0 2.57143V21.4286C0 22.8482 1.15179 24 2.57143 24H21.4286C22.8482 24 24 22.8482 24 21.4286V2.57143C24 1.15179 22.8482 0 21.4286 0ZM22.2857 21.4286C22.2857 21.9011 21.9011 22.2857 21.4286 22.2857H2.57143C2.09893 22.2857 1.71429 21.9011 1.71429 21.4286V2.57143C1.71429 2.09893 2.09893 1.71429 2.57143 1.71429H21.4286C21.9011 1.71429 22.2857 2.09893 22.2857 2.57143V21.4286ZM7.28571 10.2857H6.42857C6.19179 10.2857 6 10.4775 6 10.7143V18.4286C6 18.6654 6.19179 18.8571 6.42857 18.8571H7.28571C7.5225 18.8571 7.71429 18.6654 7.71429 18.4286V10.7143C7.71429 10.4775 7.5225 10.2857 7.28571 10.2857ZM12.4286 5.14286H11.5714C11.3346 5.14286 11.1429 5.33464 11.1429 5.57143V18.4286C11.1429 18.6654 11.3346 18.8571 11.5714 18.8571H12.4286C12.6654 18.8571 12.8571 18.6654 12.8571 18.4286V5.57143C12.8571 5.33464 12.6654 5.14286 12.4286 5.14286ZM17.5714 13.7143H16.7143C16.4775 13.7143 16.2857 13.9061 16.2857 14.1429V18.4286C16.2857 18.6654 16.4775 18.8571 16.7143 18.8571H17.5714C17.8082 18.8571 18 18.6654 18 18.4286V14.1429C18 13.9061 17.8082 13.7143 17.5714 13.7143Z"
        fill="currentColor"
      />
    </svg>
  );
}
