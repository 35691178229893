import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  forwardRef,
} from 'react';
import * as PropTypes from 'prop-types';
import Overlay from 'react-bootstrap2/Overlay';
import EditableText from 'components/Kizen/EditableText';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { ViewWrapper, TextareaWrapper } from './styles';
import { basePopperConfig } from '../helpers';
import { BasicRichTextEditor } from '../../../WYSIWYG';
import Button from 'components/Button';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

const ButtonWrapper = styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const newlinesToSpaces = (str) => str && str.replace(/\n|\r\n|\r/g, ' ');

const strip = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const WYSIWYGTextInline = forwardRef(
  (
    {
      value,
      onSubmit,
      autoFocus,
      onAutoFocus,
      emptyPlaceholder,
      readOnly,
      enableResize,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [stagedValue, setStagedValue] = useState(value);
    const plainText = useMemo(() => strip(stagedValue), [stagedValue]);
    const plainValue = useMemo(() => strip(value), [value]);

    useEffect(() => {
      setStagedValue(value);
    }, [value, editing]);

    const overlayTarget = useRef(null);
    const onAutoFocusRef = useRef();
    onAutoFocusRef.current = onAutoFocus;
    const divRef = useRef(null);

    useEffect(() => {
      if (autoFocus) {
        setEditing(true);
        if (onAutoFocusRef.current)
          onAutoFocusRef.current(overlayTarget.current);
      }
    }, [autoFocus]);

    const handleSubmit = useCallback(async () => {
      if (value !== stagedValue) {
        try {
          setSubmitting(true);
          await onSubmit(stagedValue);
          setEditing(false);
        } finally {
          setSubmitting(false);
        }
      } else {
        setEditing(false);
      }
    }, [onSubmit, stagedValue, value]);

    const [tooltipProps] = useTruncationTooltip();

    const onClickAway = useCallback(async () => {
      await handleSubmit();
      setEditing(false);
    }, [handleSubmit]);

    useClickAway(divRef || {}, onClickAway, ['mousedown']);

    return (
      <ViewWrapper ref={overlayTarget} {...others}>
        {readOnly ? (
          <TextEllipsisWithTooltip>{plainText}</TextEllipsisWithTooltip>
        ) : (
          <>
            <EditableText
              className="ViewText"
              value={
                newlinesToSpaces(submitting ? plainText : plainValue) ||
                emptyPlaceholder
              }
              disabled={submitting || editing}
              onClick={() => {
                setEditing(true);
              }}
              {...tooltipProps}
            />

            <Overlay
              transition={false}
              target={overlayTarget.current}
              show={editing && !submitting}
              placement="bottom-start"
              popperConfig={basePopperConfig}
            >
              <TextareaWrapper
                width={
                  overlayTarget.current && overlayTarget.current.offsetWidth
                }
                enableResize={false}
              >
                <div ref={divRef}>
                  <BasicRichTextEditor
                    ref={ref}
                    onClickAway={onClickAway}
                    enableHorizontalRule
                    initialValue={stagedValue}
                    placeholder={emptyPlaceholder}
                    onChange={({ editor }) => setStagedValue(editor.getHTML())}
                    enableResize={enableResize}
                    autofocus={autoFocus}
                  />
                  <ButtonWrapper>
                    <Button variant="text" onClick={handleSubmit}>
                      {t('Save')}
                    </Button>
                  </ButtonWrapper>
                </div>
              </TextareaWrapper>
            </Overlay>
          </>
        )}
      </ViewWrapper>
    );
  }
);

WYSIWYGTextInline.propTypes = {
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  onAutoFocus: PropTypes.func,
  emptyPlaceholder: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  enableResize: PropTypes.bool,
};

WYSIWYGTextInline.defaultProps = {
  value: '',
  autoFocus: false,
  onAutoFocus: null,
  emptyPlaceholder: '—',
  readOnly: false,
  placeholder: null,
  enableResize: false,
};
export default WYSIWYGTextInline;
