import { useCallback } from 'react';
import { canEditTemplate } from 'pages/Account/pages/Toolbar/utils';
import { useDynamicError } from 'hooks/useDynamicError';
import { NameCellInput } from '../styles';
import useField from 'hooks/useField';

export const FilterGroupNameCell = ({ value, data, onChange, error }) => {
  const errorProps = useDynamicError(error?.[0]);

  const [innerValue, setInnerValue] = useField(value);

  const handleSubmit = useCallback(
    (val) => {
      onChange(val, data.id);
      setInnerValue(val);
    },
    [onChange, data.id, setInnerValue]
  );

  return (
    <NameCellInput
      value={innerValue}
      onSubmit={handleSubmit}
      {...errorProps}
      readOnly={!canEditTemplate(data)}
    />
  );
};
