import { useEffect, useState, useCallback } from 'react';
import { useTimeoutFn } from 'react-use';

export default function useFlashState(ms = 1500, initialState) {
  const [state, setState] = useState(initialState);
  const [, cancel, scheduleEndState] = useTimeoutFn(
    () => setState(initialState),
    ms
  );
  useEffect(() => {
    cancel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const flashState = useCallback(
    (value) => {
      setState(value);
      scheduleEndState();
    },
    [scheduleEndState]
  );
  return [state, flashState];
}

// This can be used alongside css animations
export function useFlashTransition(
  { transition = 300, stay = 1500 } = {},
  initialState
) {
  const [state, flashState] = useFlashState(
    2 * transition + stay,
    initialState
  );
  const [show, flashShow] = useFlashState(transition + stay, false);
  const flashTransition = useCallback(
    (value) => {
      flashShow(true);
      flashState(value);
    },
    [flashState, flashShow]
  );
  return [state, show, flashTransition];
}
