import Notice from 'components/Kizen/Notice';
import Button from 'components/Button';
import { Col } from 'react-bootstrap';
import KizenTypography from 'app/kizentypo';
import EmailAddressTextInput, {
  validate as validateEmail,
} from 'components/Inputs/TextInput/presets/EmailAddress';
import DomainTextInput, {
  useDomainValidation,
} from 'components/Inputs/TextInput/presets/Domain';
import { useTranslation } from 'react-i18next';
import { StyledRow as Row, ActionsContainer, StyledFormError } from '../styles';
import {
  StyledTable as Table,
  TableBody,
  TableRow,
  TableHeader,
  TableHeaderCell,
  TableCell,
  StatusCell,
} from './styles';

export const DOMAIN_STEPS = {
  DOMAIN_FORM: 'DOMAIN_FORM',
  UPDATE_DNS_RECORD: 'UPDATE_DNS_RECORD',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  COMPLETED: 'COMPLETED',
};

export const STATUS_TYPES = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const validateRequired = (value, t) => {
  if (!value) {
    return t('This field is required');
  }

  return null;
};

const validateSameDomain = (email, domain, t) => {
  if (!email.endsWith(domain)) {
    return `${t(
      'The Verification Email must be on the same domain. For example'
    )}: me@${domain}`;
  }

  return null;
};

const DomainSteps = ({
  step,
  values,
  onFieldSet,
  onSubmit,
  onRestartVerification,
  onDeleteDomain,
  errorMessage,
}) => {
  const { t } = useTranslation();

  const isDomainValid = useDomainValidation(values.name);
  const isValid =
    values.name &&
    values.verification_email &&
    isDomainValid &&
    !validateSameDomain(values.verification_email, values.name, t) &&
    !validateEmail.full(values.verification_email);

  const handleSubmit = () => {
    if (isValid) {
      onSubmit();
    }
  };

  const isComplete = values?.requiredDnsRecords?.every(
    (record) => record.complete
  );

  const onExportToCSV = () => {
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      encodeURI(
        `data:text/csv;charset=utf-8,${t('Type')},${t('Name')},${t(
          'Value'
        )}\n${values.requiredDnsRecords
          .map(
            (record) =>
              `${record.record_type || ''},${
                record.name || ''
              },${record.value || ''}`
          )
          .join('\n')}`
      )
    );
    link.setAttribute('download', 'dns_records.csv');
    link.click();
  };

  const domainData = {
    [DOMAIN_STEPS.DOMAIN_FORM]: {
      content: () => (
        <>
          <Row>
            <Col>
              <Notice as="p">
                {t(
                  'A few easy steps are required before you can send email from your domain name. Before continuing, please verify that you will be able to add the necessary DNS records within a couple days and that the verification email address that you specify below is active and accessible.'
                )}
              </Notice>
            </Col>
          </Row>
          <Row>
            <Col>
              <DomainTextInput
                label={t('Send Email from Your Domain')}
                placeholder={t('mydomain.com')}
                value={values.name}
                onChange={(value) => onFieldSet('name', value)}
                required
                validate={{
                  full: (value) => {
                    validateRequired(value, t);
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EmailAddressTextInput
                label={t('Verification Email Address')}
                placeholder={t('email@mydomain.com')}
                value={values.verification_email}
                onChange={(value) => onFieldSet('verification_email', value)}
                required
                validate={{
                  full: (value) => {
                    return (
                      validateRequired(value, t) ||
                      validateSameDomain(value, values.name, t) ||
                      validateEmail.full(value, t)
                    );
                  },
                }}
              />
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Button disabled={!isValid} onClick={handleSubmit}>
                {t('ADD DOMAIN')}
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    [DOMAIN_STEPS.UPDATE_DNS_RECORD]: {
      content: () => (
        <>
          <Row>
            <Col>
              <KizenTypography>
                {t(
                  'To send email from your own domain, verification DNS records are'
                )}{' '}
                <KizenTypography as="span" size="micro" weight="bold">
                  {t('required')}
                </KizenTypography>
                {'. '}
                {t(
                  'Go to the DNS provider you use to manage values for {{domain}} and add the following DNS records.',
                  {
                    domain:
                      values?.original?.email_sending_domain?.domain ||
                      t('your domain'),
                  }
                )}
              </KizenTypography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{t('Type')}</TableHeaderCell>
                    <TableHeaderCell>{t('Name')}</TableHeaderCell>
                    <TableHeaderCell>{t('Value')}</TableHeaderCell>
                    <TableHeaderCell>{t('Status')}</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {(values?.requiredDnsRecords || []).map((record) => (
                    <TableRow key={record.value}>
                      <TableCell>{record.record_type}</TableCell>
                      <TableCell>
                        <span className="name-cell">{record.name}</span>
                      </TableCell>
                      <TableCell>
                        <span className="value-cell">{record.value}</span>
                      </TableCell>
                      <TableCell>
                        <StatusCell
                          type={
                            record.complete
                              ? STATUS_TYPES.SUCCESS
                              : STATUS_TYPES.ERROR
                          }
                        >
                          {record.complete ? t('Found') : t('Missing')}
                        </StatusCell>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <KizenTypography>
                {t(
                  'Once you make the above changes to your DNS, it can take several hours to propagate. Once your DNS records are found, we will send you an email with a verification link to complete setup process.'
                )}
              </KizenTypography>
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ActionsContainer>
                {values?.requiredDnsRecords?.length ? (
                  <Button
                    variant="outline"
                    onClick={onExportToCSV}
                    color="green"
                  >
                    {t('DOWNLOAD DNS RECORDS')}
                  </Button>
                ) : null}
                <Button
                  variant="outline"
                  onClick={onRestartVerification}
                  color="blue"
                >
                  {t('RESTART VERIFICATION')}
                </Button>
                <Button onClick={onDeleteDomain} color="red">
                  {t('DELETE DOMAIN')}
                </Button>
              </ActionsContainer>
            </Col>
          </Row>
        </>
      ),
    },
    [DOMAIN_STEPS.VERIFY_EMAIL]: {
      content: () => (
        <>
          <Row>
            <Col>
              {isComplete ? (
                <Notice as="p">
                  <KizenTypography as="p">
                    {t(
                      'Almost done! Your DNS records are complete and we have sent a verification email to '
                    )}
                    <KizenTypography weight="bold" size="micro" as="span">
                      {values.original && values.original.verification_email}
                    </KizenTypography>
                    {t(
                      '. If you need to change your verification email, please delete the domain and restart the verification process.'
                    )}
                  </KizenTypography>
                </Notice>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <KizenTypography>
                {t(
                  'To send email from your own domain, verification DNS records are'
                )}{' '}
                <KizenTypography weight="bold" size="micro" as="span">
                  {t('required')}
                </KizenTypography>
                {'. '}
                {t(
                  'Go to the DNS provider you use to manage values for {{domain}} and add the following DNS records.',
                  {
                    domain:
                      values?.original?.email_sending_domain?.domain ||
                      t('your domain'),
                  }
                )}
              </KizenTypography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{t('Type')}</TableHeaderCell>
                    <TableHeaderCell>{t('Name')}</TableHeaderCell>
                    <TableHeaderCell>{t('Value')}</TableHeaderCell>
                    <TableHeaderCell>{t('Status')}</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {(values?.requiredDnsRecords || []).map((record) => (
                    <TableRow key={record.value}>
                      <TableCell>{record.record_type}</TableCell>
                      <TableCell>
                        <span className="name-cell">{record.name}</span>
                      </TableCell>
                      <TableCell>
                        <span className="value-cell">{record.value}</span>
                      </TableCell>
                      <TableCell>
                        <StatusCell
                          type={
                            record.complete
                              ? STATUS_TYPES.SUCCESS
                              : STATUS_TYPES.ERROR
                          }
                        >
                          {record.complete ? t('Found') : t('Missing')}
                        </StatusCell>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <KizenTypography>
                {t(
                  'Once you make the above changes to your DNS, it can take several hours to propagate. Once your DNS records are found, we will send you an email with a verification link to complete the setup process.'
                )}
              </KizenTypography>
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ActionsContainer>
                {values?.requiredDnsRecords?.length ? (
                  <Button
                    variant="outline"
                    onClick={onExportToCSV}
                    color="green"
                  >
                    {t('DOWNLOAD DNS RECORDS')}
                  </Button>
                ) : null}
                {!isComplete ? (
                  <Button
                    variant="outline"
                    onClick={onRestartVerification}
                    color="blue"
                  >
                    {t('RESTART VERIFICATION')}
                  </Button>
                ) : null}
                <Button onClick={onDeleteDomain} color="red">
                  {t('DELETE DOMAIN')}
                </Button>
              </ActionsContainer>
            </Col>
          </Row>
        </>
      ),
    },
    [DOMAIN_STEPS.COMPLETED]: {
      content: () => (
        <>
          <Row>
            <Col>
              <Notice as="p">
                {t('Success! Your domain has been verified.')}
              </Notice>
            </Col>
          </Row>

          <Row>
            <Col>
              <DomainTextInput
                label={t('Send From Domain Name')}
                value={
                  values &&
                  values.original &&
                  values.original.email_sending_domain &&
                  values.original.email_sending_domain.domain
                }
                disabled
              />
            </Col>
          </Row>
          {errorMessage && (
            <Row>
              <Col>
                <StyledFormError>{errorMessage}</StyledFormError>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <ActionsContainer>
                {values?.requiredDnsRecords?.length ? (
                  <Button
                    variant="outline"
                    onClick={onExportToCSV}
                    color="green"
                  >
                    {t('DOWNLOAD DNS RECORDS')}
                  </Button>
                ) : null}
                <Button onClick={onDeleteDomain} color="red">
                  {t('DELETE DOMAIN')}
                </Button>
              </ActionsContainer>
            </Col>
          </Row>
        </>
      ),
    },
  };

  return domainData[step].content();
};

export default DomainSteps;
