import { forwardRef } from 'react';

import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';

import { withCellWrapper } from '../helpers';

const _DateCreatedCell = forwardRef(
  ({ object: record, field, shouldFocusNext }, ref) => {
    const value = record.created;

    return (
      <DateTimeInputInline
        field={field}
        object={record}
        readOnly
        value={value}
        shouldFocusNext={shouldFocusNext}
        showDateTooltip
      />
    );
  }
);

export const DateCreatedCell = withCellWrapper(_DateCreatedCell);
