import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, grayScale, shadowLight } from 'app/colors';
import { borderRadii, gutters } from 'app/spacing';
import IconButton from 'components/Kizen/IconButton';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import Draggable from 'pages/AutomationEngine/Draggable';
import StepCard from './StepCard';
import { PANEL_HEIGHT } from './constants';
import { flushUiChangesHack } from '../utilities';

const StepCardWithHandleWrapper = styled.div`
  position: relative;
  .Handle {
    width: 18px;
    height: 25px;
    ${shadowLight}
    // Would use visibility, but hidden elements don't
    // respond to events e.g. hover, which is important here.
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-100%) translateY(-50%);
    ${({ offsetForPanel }) =>
      offsetForPanel &&
      css`
        transform: translateX(-100%) translateY(-50%)
          translateY(${-PANEL_HEIGHT / 2}px);
      `}
    ${({ grabbable }) =>
      grabbable &&
      css`
        cursor: grab;
      `}
    background: ${grayScale.white};
    padding: ${gutters.spacing(1)}px;
    border-top-left-radius: ${borderRadii.small};
    border-bottom-left-radius: ${borderRadii.small};
  }
  &:hover .Handle {
    opacity: 1;
  }
  ${({ showHandle }) =>
    showHandle &&
    css`
      .Handle {
        opacity: 1;
      }
    `}
  ${({ showHandle }) =>
    showHandle === false &&
    css`
      &&:hover .Handle {
        opacity: 0;
      }
    `}
    ${({ showPlaceholder }) =>
    showPlaceholder &&
    css`
      & > *,
      .Handle,
      &:hover .Handle {
        opacity: 0;
      }
    `}
`;

export const StepCardHandle = forwardRef(
  (
    {
      children,
      showHandle = null,
      icon,
      onClick = null,
      tooltipTitle,
      ...props
    },
    ref
  ) => {
    return (
      <StepCardWithHandleWrapper ref={ref} showHandle={showHandle} {...props}>
        {children}
        <IconButton
          title={tooltipTitle}
          onClick={onClick}
          className="Handle"
          sizing="dense"
          color={
            showHandle === true
              ? colorsButton.iconGray.hover
              : colorsButton.iconGray
          }
        >
          {icon}
        </IconButton>
      </StepCardWithHandleWrapper>
    );
  }
);

StepCardHandle.displayName = 'StepCardWithHandle';

export default function DraggableStepCard({
  scale = null,
  position = null,
  onStart = null,
  onStop = null,
  panel = null,
  showHandle = null,
  style,
  ...props
}) {
  const { t } = useTranslation();
  const [dragging, setDragging] = useState(false);
  const [dragStopping, setDragStopping] = useState(false);
  return (
    <Draggable
      scale={scale}
      position={position}
      onStart={(ev) => {
        setDragging(true);
        if (onStart) onStart(ev);
        setDragStopping(false);
      }}
      onStop={async (ev) => {
        setDragging(false);
        if (onStop) onStop(ev);

        // TODO this solves an issue where dropping a step during a move would very briefly
        // show the step in its original position before painting the updated automation flow.
        // Instead we hide it temporarily, which seems to be imperceptible to the user.
        // We'll need a much better way to synchronize changes between state and react-flow,
        // but that will be a significanly more complex project.  Luckily these hacks aren't
        // really critical, and nothing burns down if they fail: just some UI flicker.

        setDragStopping(true);
        await flushUiChangesHack(); // This waits for the drop to occur (see use of flushUiChangesHack() in AddButton)
        await flushUiChangesHack(); // And this waits for the automation flow to paint with the new state.
        setDragStopping(false);
      }}
      style={style}
    >
      <StepCardHandle
        grabbable
        icon={
          <IconSizing size="15px">
            <Icon icon="reorder" />
          </IconSizing>
        }
        showPlaceholder={dragStopping}
        offsetForPanel={!!panel}
        showHandle={dragging || showHandle}
        tooltipTitle={t('Move Item')}
      >
        <StepCard panel={panel} {...props} />
      </StepCardHandle>
    </Draggable>
  );
}
