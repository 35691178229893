import { call, takeLatest, put } from 'redux-saga/effects';
import FieldService from 'services/FieldService';
import {
  getCustomObjects as getCustomObjectsAction,
  getCustomObjectsFail,
  getCustomObjectsSuccess,
  getCategories as getCategoriesAction,
  getCategoriesFail,
  getCategoriesSuccess,
  setChosenObject,
} from './customObjectsSettings.redux';
import {
  isEntityValueField,
  isPercentageChanceToCloseField,
} from 'checks/fields';

function* getCategories({ payload }) {
  try {
    let data = yield call(FieldService.getCategorizedFields, {
      for: { ...payload, objectClass: 'customObjects' },
      includeVisible: true,
      settingsRequest: true,
    });

    if (payload.pipeline) {
      data = data.map((cat) => ({
        ...cat,
        fields: cat.fields.filter((field) => {
          return !(
            (!payload.trackEntityValue && isEntityValueField(field)) ||
            (!payload.pipeline.includePercentageToClose &&
              isPercentageChanceToCloseField(field))
          );
        }),
      }));
    }
    yield put(getCategoriesSuccess(data));
  } catch (error) {
    yield put(getCategoriesFail(error));
  }
}

function* getCustomObjects({ payload }) {
  const { id } = payload;
  try {
    const data = yield call(FieldService.getObjects);
    const objects = data.map((item) => ({
      ...item,
      value: item.id,
      label: item.description,
    }));

    yield put(getCustomObjectsSuccess(objects));

    if (id) {
      const model = objects.find((item) => item.id === id);

      yield put(setChosenObject(model));
      yield getCategories({ payload: model });
    }
  } catch (error) {
    yield put(getCustomObjectsFail(error));
  }
}

function* customObjectsSettingsPage() {
  yield takeLatest(getCustomObjectsAction.type, getCustomObjects);
  yield takeLatest(getCategoriesAction.type, getCategories);
}

export default customObjectsSettingsPage;
