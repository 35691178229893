import { useEffect, useRef, useState } from 'react';
import useSyncScroll from 'react-use-sync-scroll';
import { tableHover } from 'app/colors';
import Expander from 'components/Containers/Expander';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import BubbleProfileImage from 'components/Charts/ScheduledActivities/BubbleProfileImage';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import { defaultFonts } from 'components/WYSIWYG';
import { CommentEditor } from 'components/WYSIWYG/CommentEditor';

import {
  DateSpan,
  EditorWrapper,
  Message,
  MessageList,
  MessageExpanderGradient,
  MessageBlockWrapper,
  MessageItem,
  SendButton,
  CommentsWrapper,
  Comments,
} from './styles';
import { formatTimestamp } from './helpers';
import { useTranslation } from 'react-i18next';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { useDateTooltip } from 'components/Inputs/hooks/useDateTooltip';

const DateTimeStamp = ({ date, ...others }) => {
  const [dateTooltipProps, dateTooltip] = useDateTooltip({
    date,
  });
  return (
    <DateSpan {...dateTooltipProps} {...others}>
      {dateTooltip}
      {formatTimestamp(date)}
    </DateSpan>
  );
};

const CommentsScrollSection = ({
  isCommenting = false,
  comments = EMPTY_ARRAY,
}) => {
  const messageListRef = useRef();
  const scrollbarContentWrapperRef = useRef();

  const [contentWrapperRefFn, contentWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncHeight',
    'height'
  );
  const [topScrolled, , bottomScrolled] = useEndsOfScroll(
    scrollbarContentWrapperRef,
    [isCommenting, comments, contentWrapperRef]
  );

  useSyncScroll(useRef([contentWrapperRef, scrollbarContentWrapperRef]), {
    vertical: true,
  });

  useEffect(() => {
    if (comments.length && contentWrapperRef.current) {
      contentWrapperRef.current.scroll({
        top: 0,
        left: 0,
        behavior: isCommenting ? 'smooth' : 'auto',
      });
    }
  }, [isCommenting, comments.length, contentWrapperRef]);

  return (
    <CommentsWrapper isEmpty={false}>
      <Comments ref={contentWrapperRefFn} allowScrolling={isCommenting}>
        <Gradient color={tableHover} position="top" fadeIn={!topScrolled} />
        <MessageList ref={messageListRef} className="ContentForSyncHeight">
          {comments
            .sort((a, b) => (a.updated < b.updated ? 1 : -1))
            .map(({ id, text, updated, createdBy }, i, arr) => {
              const fullName = `${createdBy.firstName}${
                createdBy.lastName ? ` ${createdBy.lastName}` : ''
              }`;

              return (
                <MessageItem key={id} isLast={i === arr.length - 1}>
                  <BubbleProfileImage
                    imageURL={createdBy?.pictureUrl}
                    fullName={fullName}
                    showToolTip
                  />
                  <Message html={text} data-qa={`comment-${i}`} />
                  <DateTimeStamp date={updated} />
                </MessageItem>
              );
            })}
        </MessageList>
        <Gradient
          color={tableHover}
          position="bottom"
          fadeIn={!bottomScrolled}
        />
      </Comments>
      <ScrollBarDetached
        ref={scrollbarContentWrapperRef}
        style={{
          display: `${isCommenting ? 'block' : 'none'}`,
          marginRight: `${isCommenting ? '0' : '20px'}`,
        }}
        direction="vertical"
        scrollClassName="ContentForSyncHeight"
      />
    </CommentsWrapper>
  );
};

export default function CommentSection({
  comments,
  isCommenting,
  onOpenComments,
  onSubmit,
  eventId,
}) {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [isClosing, setIsClosing] = useState(true);

  const editorRef = useRef();
  const openState = useRef(false);
  const timerRef = useRef();
  // todo add isEmpty ....
  const handleChangeEditor = ({ editor }) => {
    if (!editorRef.current) {
      editorRef.current = editor;
    }
    const value = editor.getHTML();
    setComment(editor.getText().length === 0 ? '' : value);
  };

  const handleClickSave = async () => {
    await onSubmit(comment);
    setComment('');
    editorRef.current.commands.clearContent();
  };

  useEffect(() => {
    if (!isCommenting) {
      timerRef.current = setTimeout(() => setIsClosing(false), 300);
    }
    return () => clearTimeout(timerRef.current);
  }, [isCommenting]);

  if (!isCommenting && !isClosing) {
    return null;
  }

  const handleStateChange = (newState) => {
    onOpenComments(newState === 'open');
    setIsClosing(openState.current);
    openState.current = true;
    if (newState === 'partially-open') {
      timerRef.current = setTimeout(() => setIsClosing(false), 300);
    }
  };
  return (
    <MessageBlockWrapper className="plink">
      <Expander
        expanded={isCommenting ? 'open' : comments.length && 'partially-open'}
        onChange={handleStateChange}
        gradient={<MessageExpanderGradient />}
        arrowBtnLayerFudge={0}
      >
        {isCommenting || isClosing ? (
          <EditorWrapper>
            <div data-qa="test-editor">
              <CommentEditor
                ref={(ref) => (editorRef.current = ref?.editor)}
                initialValue={comment}
                onChange={handleChangeEditor}
                defaultFonts={defaultFonts}
                suggestionAppendToSelector={`[data-event-id="${eventId}"] + div.plink`}
              />
            </div>

            <SendButton
              disabled={!comment}
              onClick={handleClickSave}
              color="blue"
              variant="text"
              data-qa-action="send-comment"
            >
              {t('Send')}
            </SendButton>
          </EditorWrapper>
        ) : null}
        {comments.length ? (
          <CommentsScrollSection
            isCommenting={isCommenting}
            comments={comments}
          />
        ) : null}
      </Expander>
    </MessageBlockWrapper>
  );
}
