import React, { forwardRef } from 'react';

import { useEditor, useNode } from '@craftjs/core';
import { AttachmentsContainer } from '@kizen/page-builder/nodes/containers';

import { useDraggingType } from '../useDraggingType';
import { useShowControls } from '../useShowControls';
import Control from '../Control';

import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';

import { useTranslation } from 'react-i18next';
import css from '@emotion/css';
import { colorsButton } from 'app/colors';
import { useBuilderContext } from 'components/PageBuilder/BuilderContext';
import { TextEllipsisWithTooltip } from '@kizen/page-builder/internal/components/Kizen/Table';
import { useIsInBuilderContext } from 'components/PageBuilder/BuilderContext';

const IconWrapper = styled.span`
  ${({ icon }) =>
    icon &&
    css`
      width: 9px;
      background-image: url(${icon});
      height: 12px;
      margin-left: 5px;
      background-size: cover;
    `}
  padding-right: ${gutters.spacing(1, 3)}px;
  margin-right: ${gutters.spacing(1)}px;
`;
const Wrapper = styled.div`
  margin: 10px;
`;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  background: ${grayScale.light};
  padding: 6px 5px 6px ${({ empty }) => (empty ? gutters.spacing(3, 4) : 0)}px;
  height: 25px;
  margin-bottom: 5px;
  ${({ empty }) =>
    empty &&
    css`
      cursor: pointer;
    `};
  border: 1px solid ${grayScale.mediumLight};
  width: 100%;
  :last-child {
    margin-bottom: 0;
  }
`;
const StyledText = styled.span`
  color: ${colorsButton.blue.default};
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  font-weight: 600;
`;

const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
  color: ${colorsButton.blue.default};
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  font-weight: 600;
`;
export const ViewAttachments = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { attachments = [], attachmentIconUrl, ...rest } = props;
  const { setTrayProps } = useBuilderContext();

  if (!attachments.length)
    return (
      <AttachmentsContainer {...rest}>
        <Wrapper>
          <Container
            empty
            onClick={() => {
              setTrayProps({ fileLibraryOpen: true });
            }}
          >
            <StyledText>{t('Please Select a File.')}</StyledText>
          </Container>
        </Wrapper>
      </AttachmentsContainer>
    );
  return (
    <AttachmentsContainer {...rest}>
      <Wrapper>
        {attachments.map((file, i) => {
          return (
            <Container key={file.id}>
              <IconWrapper icon={attachmentIconUrl} />
              <StyledTextEllipsisWithTooltip as="span">
                {file.name}
              </StyledTextEllipsisWithTooltip>
            </Container>
          );
        })}
      </Wrapper>
    </AttachmentsContainer>
  );
});

export const Attachments = (props) => {
  const { t } = useTranslation();
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
  }));

  const {
    id,
    connectors: { connect },
  } = useNode((node) => ({ id: node.id }));

  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const { setTrayProps } = useBuilderContext();
  const isInBuilderContext = useIsInBuilderContext();

  return (
    <Control
      ref={connect}
      label={t('Content')}
      show={showControls}
      color={colorsPrimary.green.dark}
      mayHaveChildren={true}
      fullWidth={true}
      {...props}
    >
      <ViewAttachments
        nodeId={id}
        clickable
        removeLink
        draggingType={draggingType}
        onContainerClick={() => setTrayProps({ fileLibraryOpen: true })}
        isInBuilderContext={isInBuilderContext}
        {...props}
      />
    </Control>
  );
};
