import { DYNAMIC_ITEM_IDS } from './constants';
import { COLUMNS } from './utils';

const RESTRICTED_ITEMS = [
  DYNAMIC_ITEM_IDS.NEW_CATEGORY,
  DYNAMIC_ITEM_IDS.NEW_CUSTOM_URL,
];

class ParentDropValidator {
  constructor(dropZone, draggingItem, selfDisabledDropZones) {
    this.dropZone = dropZone;
    this.draggingItem = draggingItem;
    this.selfDisabledDropZones = selfDisabledDropZones;
  }

  isDraggingItemRestricted() {
    return RESTRICTED_ITEMS.includes(this.draggingItem.id);
  }

  isDraggingItemAllowedToDrop() {
    const { sectionId } = this.dropZone;
    return sectionId !== COLUMNS.FIND_OPTIONS;
  }

  isDropZoneRestricted() {
    return RESTRICTED_ITEMS.includes(this.dropZone.id);
  }

  isDropZoneAllowed() {
    if (this.dropZone.id === DYNAMIC_ITEM_IDS.NEW_CATEGORY) {
      return false;
    }
    if (
      this.dropZone.id === DYNAMIC_ITEM_IDS.NEW_CUSTOM_URL &&
      this.dropZone.position === 'before'
    ) {
      return false;
    }

    return true;
  }

  wouldBeDestructive() {
    const { sectionId } = this.dropZone;
    if (
      this.draggingItem?.children?.length &&
      sectionId === COLUMNS.FIND_OPTIONS
    ) {
      return true;
    }

    return false;
  }

  getValidDropZone() {
    if (!this.dropZone?.id) {
      return this.dropZone;
    }

    if (this.isDraggingItemRestricted()) {
      if (this.isDraggingItemAllowedToDrop()) {
        return this.dropZone;
      }
      return null;
    }

    // if we passed sectionId with selfDisabledDropZones then we want to disable drop zone for this id
    if (
      this.draggingItem?.fromColumn === this.dropZone?.sectionId &&
      this.selfDisabledDropZones.includes(this.dropZone?.sectionId)
    ) {
      return null;
    }

    if (this.wouldBeDestructive()) {
      return null;
    }

    // If the dropzone is not restricted, return a valid dropzone
    if (!this.isDropZoneRestricted()) {
      return this.dropZone;
    }

    // If the dropzone is restricted, only return a valid dropzone
    // if the drop is within the constraints
    if (this.isDropZoneAllowed()) {
      return this.dropZone;
    }

    return null;
  }
}

export default ParentDropValidator;
