import ReactDOM from 'react-dom';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'components/Kizen/Select';
import IconButton, { ICON_BUTTON_SIZING } from 'components/Kizen/IconButton';
import useClickAway from 'react-use/lib/useClickAway';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { borderTight } from 'app/colors';
import { applyRef } from 'components/Inputs/props';
import { layers } from 'app/spacing';
import { gutters } from 'app/spacing';

const INITIAL_STYLE = {
  top: '-1000px',
  left: '-1000px',
};

const ContainerPosition = styled.div`
  position: relative;
`;

export const Menu = styled(Select)`
  position: absolute;
  z-index: ${layers.toast};
  bottom: -2px;
  .IconButtonMenu__control {
    display: none;
  }
  .IconButtonMenu__menu {
    overflow: hidden; // Primarily for border-radius
    width: auto;
    max-width: 200px;
    ${borderTight};
  }
  ${({ isTopHalf, position, menuType }) => {
    if (menuType === 'observable' && !isTopHalf) {
      return css`
        &&& .IconButtonMenu__menu {
          transform: translate(${position === 'right' ? '-100%' : '0'}, -100%)
            translateY(-${gutters.spacing(4)}px);
        }
      `;
    }
  }}
  ${({ position }) =>
    position === 'right'
      ? css`
          right: 0;
          .IconButtonMenu__menu {
            transform: translateX(-100%);
          }
        `
      : css`
          left: 0;
        `}
`;

const IconButtonMenu = forwardRef(
  (
    {
      sizing = ICON_BUTTON_SIZING.normal,
      color = null,
      className,
      children,
      onOpen = null,
      onClose = null,
      position = 'left',
      open,
      borderColor = null,
      dataQa,
      restrictCloseHandler = false,
      title,
      onChange,
      overlay,
      overlayOffsetY = 0,
      ...others
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(
      typeof open === 'boolean' ? open : false
    );
    const targetRef = useRef(null);
    const containerRef = useRef(null);
    const [menuWidth, setMenuWidth] = useState(0);
    const [styleProp, setStyleProp] = useState(INITIAL_STYLE);

    const mergeRef = useCallback(
      (node) => {
        applyRef(ref, node);
        applyRef(targetRef, node);
        applyRef(containerRef, node);
      },
      [ref]
    );

    const handleChange = useCallback(
      (...value) => {
        setIsOpen(false);
        onChange(...value);
      },
      [onChange]
    );

    useClickAway(containerRef, () => {
      if ((isOpen || !restrictCloseHandler) && onClose) {
        onClose();
      }
      setIsOpen(false);
    });

    useEffect(() => {
      if (containerRef.current && overlay && isOpen) {
        const process = () => {
          const wrapperRect = containerRef.current.getBoundingClientRect();
          const right = wrapperRect.right;
          const top =
            wrapperRect.top +
            wrapperRect.height +
            document.documentElement.scrollTop;

          setStyleProp({
            top: `${top - 10 + overlayOffsetY}px`,
            right:
              position === 'right'
                ? `calc(100% - ${right}px)`
                : `calc(100% - ${right}px + ${menuWidth}px)`,
          });
        };

        process();

        const scrollHandler = () => {
          process();
        };

        document.addEventListener('scroll', scrollHandler, true);

        return () => {
          setStyleProp(INITIAL_STYLE);
          document.removeEventListener('scroll', scrollHandler, true);
        };
      }
    }, [overlay, position, menuWidth, isOpen, overlayOffsetY]);

    const menuOpen = typeof open === 'boolean' ? open : isOpen;

    return (
      <ContainerPosition ref={containerRef}>
        <IconButton
          ref={mergeRef}
          sizing={sizing}
          title={title}
          color={isOpen && color ? color.light || color.hover : color}
          className={className}
          borderColor={borderColor}
          data-qa={dataQa}
          onClick={() => {
            if (onOpen) {
              onOpen(isOpen, containerRef.current);
            }

            setIsOpen(typeof open === 'boolean' && isOpen ? open : !isOpen);
          }}
        >
          {children}
        </IconButton>
        {overlay && menuOpen
          ? ReactDOM.createPortal(
              <div
                style={{
                  position: 'absolute',
                  ...styleProp,
                }}
              >
                <Menu
                  fullWidth
                  position={position}
                  classNamePrefix="IconButtonMenu"
                  menuIsOpen
                  onChange={handleChange}
                  wrapperDataQa="icon-button-menu"
                  {...others}
                  ref={(state) =>
                    setMenuWidth(
                      state?.select?.menuListRef?.getBoundingClientRect?.()
                        ?.width ?? 0
                    )
                  }
                />
              </div>,
              document.body
            )
          : null}
        {!overlay && menuOpen ? (
          <Menu
            fullWidth
            position={position}
            classNamePrefix="IconButtonMenu"
            menuIsOpen
            onChange={handleChange}
            wrapperDataQa="icon-button-menu"
            {...others}
          />
        ) : null}
      </ContainerPosition>
    );
  }
);

export default IconButtonMenu;
