import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import { gutters } from 'app/spacing';
import Button from 'components/Button';
import Icon from 'components/Kizen/Icon';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

export const BreakdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BreakdownRow = styled.div`
  display: flex;
  align-items: ${({ center = true }) => (center ? 'center' : 'flex-start')};
  padding-bottom: ${gutters.spacing(2)}px;
`;

export const BreakdownIcon = styled(Icon)`
  color: ${colorsButton.blue.default};
  pointer-events: none;
  ${({ minWidth }) => (minWidth ? `width: ${gutters.spacing(3)}px;` : '')}
`;

export const BreakdownLabel = styled(TextEllipsisWithTooltip)`
  && {
    margin-bottom: 0;
    margin-top: 4px;
  }
  white-space: nowrap;
  width: 60px;
  text-align: center;
  margin-left: ${gutters.spacing(2)}px;
  margin-right: ${gutters.spacing(2)}px;
`;

export const RangeContainer = styled.div`
  display: flex;
  flex: 0 0;
  margin-left: ${gutters.spacing(3)}px;
  margin-right: ${gutters.spacing(3)}px;
`;

export const BreakdownInputContainer = styled.div`
  width: 100px;
  ${({ compact }) => (compact ? `width: 85px;` : '')}
`;

export const BreakdownLabelInputContainer = styled.div`
  ${({ compact }) => (compact ? 'width: 139px;' : 'width: 100%;')}
  ${({ expandInline }) => (expandInline ? 'width: 167px;' : '')}
`;

export const BreakdownDelimiter = styled.div`
  margin-left: ${gutters.spacing(1)}px;
  margin-right: ${({ compact }) => (compact ? 10 : 15)}px;

  padding-top: ${gutters.spacing(2)}px;
`;

export const LabelRow = styled.div`
  display: flex;

  &&& p {
    margin-bottom: ${gutters.spacing(1, 2)}px;
  }
`;

export const MinLabelContainer = styled.div`
  width: 245px;
  ${({ compact }) => (compact ? 'width: 230px;' : '')}
`;

export const MaxLabelContainer = styled.div`
  width: 125px;
  ${({ compact }) => (compact ? 'width: 102px;' : '')}
`;

export const DropdownValuesLabelContainer = styled.div`
  width: 407px;
`;

export const AddRangeButton = styled(Button)`
  margin-right: auto;
  white-space: nowrap;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0px;
  padding-top: 0;
  height: 20px;
`;

export const RangeIcon = styled(Icon)`
  margin-left: ${({ compact }) => (compact ? 10 : 15)}px;
  cursor: pointer;
  ${({ center = true }) =>
    center
      ? ''
      : css`
          /* Exact nudge so it appears centered when flex-align center is not set */
          padding-top: 13px;
        `}
`;

export const StyledMultiSelect = styled.div`
  flex: 0 0 387px;
`;
