import styled from '@emotion/styled';
import Table from 'react-bootstrap/Table';
import { Row } from 'react-bootstrap';
import { shadow } from 'app/colors';

export const Block = styled.div(
  {
    margin: '4rem 0',
    backgroundColor: 'white',
    padding: '2rem',
  },
  shadow
);

export const StyledRow = styled(Row)({
  marginBottom: '3em',
});

export const Section = styled.div({
  marginBottom: '3em',
});

export const StyledTable = styled(Table)({
  'td,th': {
    padding: '0.5rem',
    verticalAlign: 'middle',

    '@media(min-width: 765px)': {
      padding: '1.5rem',
    },

    code: {
      fontSize: '12px',
    },
  },
});

export const StyledSwatch = styled.div(({ bgColor }) => ({
  backgroundColor: bgColor,
  width: '80%',
  paddingTop: '80%',
  borderRadius: '8px',
  marginBottom: '1.2em',
}));

export const StyledSwatchGroup = styled.div({
  width: '100%',
  display: 'inline-block',
  marginBottom: '1.5em',
});

export const StyledInput = styled.input({
  textAlign: 'center',
  border: 'none',
  outline: 'none',
});
