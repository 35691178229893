import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useParams, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import ActivityService, {
  ACTIVITY_NOTIFICATION_EMAIL,
  ACTIVITY_NOTIFICATION_SMS,
} from 'services/ActivityService';
import Notice from 'components/Kizen/Notice';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Cols from 'components/Layout/Cols';
import useField from 'hooks/useField';
import { PageSizing, ContentWidth } from 'components/Layout/PageContentWidth';
import { SubSectionWithHeader as SubSection } from 'components/Layout/SubSection';
import MultiSelect from 'components/Inputs/MultiSelect';
import InputControl from 'components/Inputs/InputControl';
import ActivityNameInput from './components/ActivityNameInput';
import {
  BigTitle,
  TitleWrapper,
  // TODO - temporarily removed for go live
  // CardsWrapper,
  // SmallCard,
  // ActionButton,
  BigCard,
  CardContent,
  StyledSettingsContentCard,
  BottomCardsBlock,
} from './styles';
import {
  NAVIGATE_AWAY_MESSAGE,
  NAVIGATE_AWAY_URL_MESSAGE,
} from '../../ControlBar';
import {
  MISSING_URL_PROTOCOL_MESSAGE,
  MISSING_URL_TLD_MESSAGE,
} from 'constants/errorMessages';

import { BuilderLoader } from '../Builder/styles';
import Select from 'components/Inputs/Select';
import { checkUrl } from 'utility/validate';
import RedirectUrlInput from 'components/Inputs/RedirectUrlInput';
import { allowNavigation } from 'components/Modals/presets/ConfirmNavigation';
import { getTeamLabel } from 'services/helpers';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import { toastVariant, useToast } from 'components/ToastProvider';
import { getOriginalError } from 'services/AxiosService';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

export const SUBMISSION_ACTIONS = {
  NONE: 'none',
  URL: 'redirect',
  WEBHHOOK: 'trigger_webhook',
};
const submissionOptions = (t) => [
  {
    label: t('None'),
    value: SUBMISSION_ACTIONS.NONE,
  },
  {
    label: t('Go to URL'),
    value: SUBMISSION_ACTIONS.URL,
  },
  {
    label: t('Trigger Webhook'),
    value: SUBMISSION_ACTIONS.WEBHHOOK,
  },
];

const NO_NAME_MESSAGE = (t) => t('Please name your activity.');
const REQUIRED_REDIRECT_URL_MESSAGE = (t) => t('Redirect URL is required.');

const customObjectsToOptions = (selectedObjects = EMPTY_ARRAY) =>
  selectedObjects.map(({ id, objectName }) => ({
    value: id,
    label: objectName,
  }));

const optionsToCustomObjectIds = (values = EMPTY_ARRAY) =>
  values.map(({ value }) => value);

const objectsToCustomObjectIds = (values = EMPTY_ARRAY) =>
  values.map(({ id }) => id);

const optionsToCustomObjects = (values = EMPTY_ARRAY) =>
  values.map(({ value: id, label: objectName }) => ({
    id,
    objectName,
  }));

const subscribersToTeamMembers = (subs) => {
  return subs.map((sub) => {
    const { employee } = sub;

    return {
      value: employee.id,
      label: getTeamLabel(employee),
      subscription: sub,
    };
  });
};

const formatError = (error = {}) => {
  const { response } = error;
  if (!response || response.status === 404) {
    return new Error(
      'Something went wrong. Check your network connection and try again.'
    );
  }
  // check for name exist error
  if (response.data && response.data.errors) {
    const { errors } = response.data;
    if (errors.redirect_url && errors.redirect_url[0]) {
      return new Error(errors.redirect_url[0]);
    }
    if (errors?.name?.length) {
      return new Error(errors.name[0]);
    }
  }
  return new Error(
    `${response.statusText} -- ${response.data ? response.data.message : ''}`
  );
};

const getURLErrorMessage = (url, t) => {
  if (url.length === 0) {
    return REQUIRED_REDIRECT_URL_MESSAGE(t);
  }

  if (!(url.startsWith('http://') || url.startsWith('https://'))) {
    return MISSING_URL_PROTOCOL_MESSAGE(t);
  }

  if (!checkUrl(url)) {
    return MISSING_URL_TLD_MESSAGE(t);
  }

  return null;
};

export default function ActivitySettingsPage({
  activity,
  errorMessages,
  setErrorMessages,
  activityNameRef,
  setActivity,
}) {
  const {
    passVariablesOnRedirect: passVariablesOnRedirectInitial,
    redirectUrl: redirectUrlInitial = '',
    redirectParameterFields = EMPTY_ARRAY,
    redirectParameterFieldIds: redirectParameterFieldIdsInitial,
    webhookUrl: webhookUrlInitial = '',
    submissionAction: submissionActionInitial,
  } = activity;
  const [showToast] = useToast();
  const params = useParams();
  const { t } = useTranslation();
  const emailSelectRef = useRef();
  const smsSelectRef = useRef();
  const { id: activityObjectId } = params;
  const [name, setName] = useField(activityNameRef.current);
  const [emailTeamMembers, setEmailTeamMembers] = useState([]);
  const [smsTeamMembers, setSmsTeamMembers] = useState([]);

  const [readyToSave, setReadyToSave] = useState(false);
  const [submissionAction, setSubmissionAction] = useState(
    submissionActionInitial || SUBMISSION_ACTIONS.NONE
  );
  const [redirectUrl, setRedirectUrl] = useState(redirectUrlInitial);
  const [webhookUrl, setWebhookUrl] = useState(webhookUrlInitial);
  const [passVariablesOnRedirect, setPassVariablesOnRedirect] = useState(
    passVariablesOnRedirectInitial
  );
  const [redirectParameterFieldIds, setRedirectParameterFieldIds] = useState(
    redirectParameterFieldIdsInitial ||
      redirectParameterFields.map(({ id }) => id)
  );
  const addingSubs = useRef(false);

  const chosenCustomObjects = customObjectsToOptions(activity?.selectedObjects);

  const [
    {
      loading,
      value: {
        activitySubscribers = EMPTY_ARRAY,
        defaultObjects = EMPTY_ARRAY,
      } = EMPTY_OBJECT,
    },
    fetchData,
  ] = useAsyncFnKeepLast(async () => {
    const [subscribers, defaultObjects] = await Promise.all([
      ActivityService.v2GetActivitySubscribers({
        activityObjectId,
      }),
      ActivityService.v2GetRelatedDefaultOnActivities(),
    ]);
    // once these have both loaded the first time then ready to save
    setReadyToSave(true);
    return {
      activitySubscribers: subscribers,
      defaultObjects,
    };
  }, []);

  useEffect(() => {
    fetchData(activityObjectId);
  }, [activityObjectId, fetchData]);

  useEffect(() => {
    if (activitySubscribers.length === 0) {
      return;
    }
    const emailSubscribers = [];
    const smsSubscribers = [];

    activitySubscribers.forEach((sub) => {
      if (sub.notificationType === ACTIVITY_NOTIFICATION_EMAIL) {
        emailSubscribers.push(sub);
      } else if (sub.notificationType === ACTIVITY_NOTIFICATION_SMS) {
        smsSubscribers.push(sub);
      }
    });

    setEmailTeamMembers(subscribersToTeamMembers(emailSubscribers));

    setSmsTeamMembers(subscribersToTeamMembers(smsSubscribers));
  }, [activitySubscribers]);

  const updateSubscribers = async (
    subscribers,
    notificationType,
    members,
    setMembers
  ) => {
    if (addingSubs.current) return;
    // we need to check if we have added or deleted subscribers
    setMembers(subscribers);
    const currentIds = members.flatMap(({ value }) => value);
    const newSubs = subscribers.filter(
      ({ value }) => !currentIds.includes(value)
    );

    // have we added any ?
    if (newSubs.length > 0) {
      const newSubscribers = newSubs.map(({ value }) => ({
        employee: value,
        notificationType,
      }));
      try {
        addingSubs.current = true;
        const appliedSubscribers =
          await ActivityService.v2CreateActivitySubscribers({
            activityObjectId,
            newSubscribers,
          });
        setMembers(
          subscribers.map((sub) => {
            const subscription = appliedSubscribers.find(
              ({ employee }) => employee.id === sub.value
            );
            return subscription ? { ...sub, subscription } : sub;
          })
        );
      } catch (error) {
        const orig = getOriginalError(error);
        if (orig?.message) {
          showToast({
            message: orig?.message,
            variant: toastVariant.FAILURE,
          });
        }
        const subscribersOld = subscribers.filter(
          (s) => s.value !== newSubscribers[0].employee
        );
        setMembers(subscribersOld);
      } finally {
        addingSubs.current = false;
      }
    }

    const subscribersId = subscribers.flatMap(({ value }) => value);
    const deleteSubs = members.filter(
      ({ value }) => !subscribersId.includes(value)
    );
    // have we deleted any ?
    if (deleteSubs.length) {
      const removedSubscribers = deleteSubs.map(({ subscription }) => ({
        notificationType: subscription.notificationType,
        subscriptionId: subscription.id,
      }));
      // we need to wait for all to finish so push them into an array
      const forDeletion = removedSubscribers.map((sub) =>
        ActivityService.v2DeleteActivitySubscriber({
          activityObjectId,
          subscriptionId: sub.subscriptionId,
        })
      );
      await Promise.all(forDeletion);
    }
  };

  const updateEmailSubscribers = (subscribers) =>
    updateSubscribers(
      subscribers,
      ACTIVITY_NOTIFICATION_EMAIL,
      emailTeamMembers,
      setEmailTeamMembers
    );

  const handleChangeName = (value) => {
    setName(value);
    activityNameRef.current = value;
  };

  const handleReset = useCallback(async () => {
    const updatedActivity = await ActivityService.v2UpdateActivity({
      id: activity.id,
      submissionAction: '',
      passVariablesOnRedirect: false,
      redirectParameterFieldIds: [],
      redirectUrl: '',
      webhookUrl: '',
    });
    setActivity((activity) => ({
      ...activity,
      ...updatedActivity,
    }));
    setPassVariablesOnRedirect(false);
    setRedirectUrl('');
    setRedirectParameterFieldIds([]);
  }, [activity, setActivity]);

  const handleChange = useCallback(
    async (field) => {
      if (!field) return;
      const payload = {};
      Object.entries(field).forEach(([key, value]) => {
        setErrorMessages((prev) => ({ ...prev, [key]: '' }));
        const prev = activity[key];

        if (value !== prev && value !== '') {
          payload[key] = value;
        }
      });

      if (Object.keys(payload).length === 0) {
        return;
      }

      try {
        const updatedActivity = await ActivityService.v2UpdateActivity({
          id: activity.id,
          ...payload,
        });
        setActivity((activity) => ({
          ...activity,
          ...payload,
          name: updatedActivity.name,
        }));
      } catch (error) {
        let err = error;
        const orig = getOriginalError(error);
        if (orig?.message) {
          showToast({
            message: orig?.message,
            variant: toastVariant.FAILURE,
          });
        } else if (error.isAxiosError) {
          err = formatError(error);
          const [key] = Object.entries(field)[0];
          setErrorMessages((p) => ({ ...p, [key]: err.message }));
        }
      }
    },
    [activity, setActivity, setErrorMessages, showToast]
  );

  const handleChangeObjects = async (objects) => {
    if (!objects) return;
    setErrorMessages((prev) => ({ ...prev, selectedObjectIds: '' }));
    const objectsOld = objectsToCustomObjectIds(activity.selectedObjects);
    setActivity((ps) => ({
      ...ps,
      selectedObjects: optionsToCustomObjects(objects),
    }));
    try {
      await ActivityService.v2UpdateActivity({
        id: activity.id,
        selectedObjectIds: optionsToCustomObjectIds(objects),
      });
    } catch (error) {
      let err = error;
      const orig = getOriginalError(error);
      if (orig?.message) {
        showToast({
          message: orig?.message,
          variant: toastVariant.FAILURE,
        });
      } else if (error.isAxiosError) {
        err = formatError(error);
        setErrorMessages((p) => ({ ...p, selectedObjectIds: err.message }));
        setActivity((ps) => ({ ...ps, selectedObjects: objectsOld }));
      }
    }
  };

  const handleBlockNavigation = (location, action) => {
    if (allowNavigation(location, action)) {
      return true;
    }

    if (shouldBlockNavigation) {
      setErrorMessages((prev) => ({
        ...prev,
        name: prev.name ? NAVIGATE_AWAY_MESSAGE(t) : prev.name,
        redirectUrl:
          submissionAction === SUBMISSION_ACTIONS.URL &&
          (prev.redirectUrl || !redirectUrl)
            ? NAVIGATE_AWAY_URL_MESSAGE(t)
            : prev.redirectUrl,
        webhookUrl:
          submissionAction === SUBMISSION_ACTIONS.WEBHHOOK &&
          (prev.webhookUrl || !webhookUrl)
            ? NAVIGATE_AWAY_URL_MESSAGE(t)
            : prev.webhookUrl,
      }));
    }

    return false;
  };

  const updateSmsSubscribers = (subscribers) =>
    updateSubscribers(
      subscribers,
      ACTIVITY_NOTIFICATION_SMS,
      smsTeamMembers,
      setSmsTeamMembers
    );

  const handleRedirectUrlChange = useCallback(
    async (event) => {
      const pastedUrl = event.clipboardData?.getData('text') || '';
      const url = pastedUrl.trim() || redirectUrl.trim();
      setErrorMessages((prev) => ({ ...prev, redirectUrl: '' }));

      const errorMessage = getURLErrorMessage(url, t);

      if (errorMessage) {
        setErrorMessages((prev) => ({
          ...prev,
          redirectUrl: errorMessage,
        }));
        return;
      }

      try {
        await handleChange({
          submissionAction: SUBMISSION_ACTIONS.URL,
          redirectUrl: pastedUrl || redirectUrl,
        });
      } catch (error) {
        const orig = getOriginalError(error);
        if (orig?.message) {
          showToast({
            message: orig?.message,
            variant: toastVariant.FAILURE,
          });
        } else if (error.isAxiosError) {
          const err = formatError(error);
          setErrorMessages((prev) => ({ ...prev, redirectUrl: err.message }));
        }
      }
    },
    [redirectUrl, handleChange, setErrorMessages, t, showToast]
  );

  const handleWebhookUrlChange = useCallback(
    async (event) => {
      const pastedUrl = event.clipboardData?.getData('text') || '';
      const url = pastedUrl.trim() || webhookUrl.trim();
      setErrorMessages((prev) => ({ ...prev, webhookUrl: '' }));

      const errorMessage = getURLErrorMessage(url, t);

      if (errorMessage) {
        setErrorMessages((prev) => ({
          ...prev,
          webhookUrl: errorMessage,
        }));
        return;
      }

      try {
        await handleChange({
          submissionAction: SUBMISSION_ACTIONS.WEBHHOOK,
          webhookUrl: pastedUrl || webhookUrl,
        });
      } catch (error) {
        const orig = getOriginalError(error);
        if (orig?.message) {
          showToast({
            message: orig?.message,
            variant: toastVariant.FAILURE,
          });
        } else if (error.isAxiosError) {
          const err = formatError(error);
          setErrorMessages((prev) => ({ ...prev, webhookUrl: err.message }));
        }
      }
    },
    [webhookUrl, handleChange, setErrorMessages, t, showToast]
  );

  const handleRedirectParametersChange = useCallback(
    async (fieldIds) => {
      try {
        setRedirectParameterFieldIds(fieldIds);
        await handleChange({
          redirectParameterFieldIds: fieldIds,
        });
      } catch (error) {
        const orig = getOriginalError(error);
        if (orig?.message) {
          showToast({
            message: orig?.message,
            variant: toastVariant.FAILURE,
          });
        } else if (error.isAxiosError) {
          const err = formatError(error);
          setErrorMessages((prev) => ({
            ...prev,
            redirectParameterFieldIds: err.message,
          }));
        }
      }
    },
    [handleChange, setErrorMessages, showToast]
  );

  const handleBlur = useCallback(async () => {
    if (name === '') {
      setErrorMessages((prev) => ({
        ...prev,
        name: NO_NAME_MESSAGE(t),
      }));
    } else {
      await handleChange({ name });
    }
  }, [name, handleChange, setErrorMessages, t]);

  const handlePasteRedirectUrl = useCallback(
    async (event) => {
      await handleRedirectUrlChange(event);
    },
    [handleRedirectUrlChange]
  );

  const handlePasteWebhookUrl = useCallback(
    async (event) => {
      await handleWebhookUrlChange(event);
    },
    [handleWebhookUrlChange]
  );

  const handleOnSwitchChange = useCallback(
    async (ev) => {
      setPassVariablesOnRedirect(ev.target.checked);
      setRedirectParameterFieldIds([]);
      await handleChange({
        passVariablesOnRedirect: ev.target.checked,
        redirectParameterFieldIds: [],
      });
    },
    [handleChange]
  );

  const handleActionChange = useCallback(
    async ({ value }) => {
      setSubmissionAction(value);
      if (value === SUBMISSION_ACTIONS.NONE) {
        await handleReset();
      } else if (
        value === SUBMISSION_ACTIONS.URL &&
        !getURLErrorMessage(redirectUrl, t)
      ) {
        await handleChange({ submissionAction: value, redirectUrl });
      } else if (
        value === SUBMISSION_ACTIONS.WEBHHOOK &&
        !getURLErrorMessage(webhookUrl, t)
      ) {
        await handleChange({ submissionAction: value, webhookUrl });
      }
    },
    [redirectUrl, webhookUrl, handleReset, handleChange, t]
  );

  const chosenEmailValueIds = useMemo(
    () => emailTeamMembers.map(({ value }) => value),
    [emailTeamMembers]
  );
  const chosenSMSValueIds = useMemo(
    () => smsTeamMembers.map(({ value }) => value),
    [smsTeamMembers]
  );

  const [emailNotificationSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: emailSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds: chosenEmailValueIds,
  });
  const [smsNotificationSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: smsSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds: chosenSMSValueIds,
  });

  if (loading && !readyToSave) {
    return <BuilderLoader loading />;
  }

  const shouldBlockNavigation = Boolean(
    errorMessages.name ||
      (submissionAction === SUBMISSION_ACTIONS.URL &&
        (errorMessages.redirectUrl || !redirectUrl)) ||
      (submissionAction === SUBMISSION_ACTIONS.WEBHHOOK &&
        (errorMessages.webhookUrl || !webhookUrl))
  );

  return (
    <PageSizing>
      <Prompt when={shouldBlockNavigation} message={handleBlockNavigation} />
      <ContentWidth>
        <StyledSettingsContentCard>
          <SubSection title={t('Activity Settings')}>
            <Cols columns={1} gutter={`${gutters.spacing(4)}px`}>
              <InputControl margin>
                <ActivityNameInput
                  errorMessage={errorMessages.name}
                  value={name}
                  onChange={handleChangeName}
                  data-qa="activity-name-input"
                  onBlur={handleBlur}
                />
              </InputControl>
            </Cols>
            <Cols columns={3} gutter={`${gutters.spacing(4)}px`}>
              <Select
                margin
                fullWidth
                label={t('Default Submission Action')}
                value={submissionAction}
                options={submissionOptions(t)}
                onChange={(ev) => handleActionChange(ev)}
              />
              {submissionAction === SUBMISSION_ACTIONS.URL && (
                <RedirectUrlInput
                  redirectUrl={redirectUrl}
                  passVariablesOnRedirect={passVariablesOnRedirect}
                  redirectParameterFieldIds={redirectParameterFieldIds}
                  errorMessage={errorMessages.redirectUrl}
                  onTextChange={setRedirectUrl}
                  onTextBlur={handleRedirectUrlChange}
                  onSwitchChange={handleOnSwitchChange}
                  onRedirectParametersChange={handleRedirectParametersChange}
                  onPaste={handlePasteRedirectUrl}
                  fields={activity.fields}
                />
              )}
              {submissionAction === SUBMISSION_ACTIONS.WEBHHOOK && (
                <RedirectUrlInput
                  showPassParametersSwitch={false}
                  label={t('Webhook URL')}
                  labelInfo={t(
                    'The business ID and ID of the logged activity will be included as a URL param.'
                  )}
                  redirectUrl={webhookUrl}
                  errorMessage={errorMessages.webhookUrl}
                  onTextChange={setWebhookUrl}
                  onTextBlur={handleWebhookUrlChange}
                  onPaste={handlePasteWebhookUrl}
                />
              )}
            </Cols>
          </SubSection>
        </StyledSettingsContentCard>
        <BottomCardsBlock>
          <BigCard>
            <BigTitle as={TitleWrapper}>
              <KizenTypography as="h3" type="subheader">
                {t('Objects To Include On Activity')}
              </KizenTypography>
            </BigTitle>
            <CardContent>
              <MultiSelect
                label={`${t('Objects to Include')} ${t(
                  '(Leave Blank for All)'
                )}`}
                placeholder={t('Find Objects')}
                options={customObjectsToOptions(defaultObjects)}
                value={chosenCustomObjects}
                onChange={handleChangeObjects}
                margin="normal"
              />
            </CardContent>
          </BigCard>
          <BigCard>
            <BigTitle as={TitleWrapper}>
              <KizenTypography as="h3" type="subheader">
                {t('Notifications')}
              </KizenTypography>
            </BigTitle>
            <CardContent>
              <Notice style={{ marginBottom: `${gutters.spacing(4)}px` }}>
                <KizenTypography as="span">
                  {t(
                    'Notify team members through text or email each time this activity is logged.'
                  )}
                </KizenTypography>
              </Notice>
              <MultiSelect
                ref={emailSelectRef}
                label={t('Notify Team Members via Email')}
                placeholder={t('Find Team Members')}
                value={emailTeamMembers}
                onChange={updateEmailSubscribers}
                margin="normal"
                {...emailNotificationSelectProps}
              />
              <MultiSelect
                ref={smsSelectRef}
                label={t('Notify Team Members via Text')}
                placeholder={t('Find Team Members')}
                value={smsTeamMembers}
                onChange={updateSmsSubscribers}
                {...smsNotificationSelectProps}
              />
            </CardContent>
            <div />
          </BigCard>
        </BottomCardsBlock>
      </ContentWidth>
    </PageSizing>
  );
}
