/*
 ** WholeNumber
 */
export const validateWholeNumber = (t) => ({
  character: (char) =>
    /[0-9,]/.test(char) || t('Only numeric characters are accepted.'),
});
export const negativesValidateWholeNumber = (t) => ({
  character: (char) =>
    /-??[0-9,.]*/.test(char.trimStart()) ||
    t('Only numeric characters are accepted.'),
});

/*
 ** PriceNumber
 */
export const validatePriceNumber = (t) => {
  return {
    character: (char) =>
      /[0-9,.]/.test(char) || t("Only numeric characters and '.' are accepted"),
  };
};
export const negativesValidatePriceNumber = (t) => {
  return {
    character: (char) =>
      /-??[0-9,.]*/.test(char.trimStart()) ||
      t("Only numeric characters and '.' are accepted"),
  };
};

/*
 ** DecimalNumber
 */
export const validateDecimalNumber = validatePriceNumber;

export const negativesValidateDecimalNumber = negativesValidatePriceNumber;
