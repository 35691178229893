import useField from 'hooks/useField';
import { useCallback, useRef } from 'react';

const getEls = (node, selector) => [...node.querySelectorAll(selector)];

/**
 *
 * @param targetRef
 * @param selector
 * @param param
 * @param deps
 * @param fixedHeight
 * @returns {((function(*): void)|*|React.MutableRefObject<null>)[]}
 */
export function useSyncSizes(
  targetRef,
  selector,
  param,
  deps = [],
  fixedHeight
) {
  const sourceRef = useRef(null);
  const [observer] = useField(
    (prev) => {
      prev?.disconnect?.();
      return new ResizeObserver((entries) => {
        if (!targetRef.current || !sourceRef.current) {
          return;
        }
        const sourceEls = getEls(sourceRef.current, selector);
        const targetEls = getEls(targetRef.current, selector);
        entries.forEach(({ target: sourceEl, contentRect }) => {
          const index = sourceEls.indexOf(sourceEl);
          const targetEl = targetEls[index];
          if (targetEl) {
            targetEl.style[param] = `${
              fixedHeight
                ? sourceRef.current.firstChild.clientHeight
                : contentRect[param]
            }px`;
            targetEl.style[`min-${param}`] = 'unset';
            targetEl.style[`max-${param}`] = 'unset';
          }
        });
      });
    },
    [fixedHeight]
  );

  const sourceRefFn = useCallback(
    (node) => {
      sourceRef.current = node;
      observer.disconnect();
      if (node) {
        getEls(node, selector).forEach((el) => {
          observer.observe(el);
        });
      }
    },
    [observer, selector, fixedHeight, ...deps] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // This is a way of exposing both a "normal" mutable ref and a function-style ref at the same time.
  // The former is for consumers who need a normal ref, and the latter is required due to implementation
  // details of this hook (i.e. needing to detect when the ref changes to setup/cleanup the resize observer).
  // The consumer will always use the function-style ref on the source component.
  return [sourceRefFn, sourceRef];
}

// A detached table head is required in order to have sticky headers and horizontal table
// scrolling at the same time, since the overflow property breaks position: sticky.
// This hook will ensure that the detached head sizes each column header correctly based
// upon the table's contents.
// There is another hook useSyncScroll() responsible for matching the scroll position
// between the detached head and the scrollable table.
export function useDetachedHead(targetRef, deps) {
  return useSyncSizes(targetRef, 'thead th', 'width', deps);
}
