import { useQueries, useQuery } from 'react-query';
import {
  CATEGORIES,
  CLIENTS,
  CUSTOM_OBJECTS,
  FIELDS,
} from 'queries/query-keys';
import CustomObjectService from 'services/CustomObjectsService';
import PipelineService from 'services/PipelineService';
import FieldService from 'services/FieldService';

const CONTACT = 'contact';
const PIPELINE = 'pipeline';

export const useContactFields = (enabled) => {
  return useQuery({
    queryKey: CUSTOM_OBJECTS.CONTACT_FIELDS,
    queryFn: CustomObjectService.getContactFields,
    enabled,
  });
};

/**
 * @typedef {'contact' | 'pipeline' | 'standard'} ObjectType
 * @param {{ objectType: ObjectType, id: string }} options id MUST be provided if objectType !== 'contact'
 * @param {boolean} [enabled]
 */
export const useFieldsQuery = ({ objectType, id }, enabled) => {
  return useQuery({
    queryKey:
      objectType === CONTACT
        ? FIELDS.CLIENT()
        : FIELDS.CUSTOM_OBJECTS(objectType, id),
    queryFn: async () => {
      if (objectType === CONTACT) {
        return await FieldService.getFields({ for: 'contacts' });
      }
      if (objectType === PIPELINE) {
        return await PipelineService.getObjectFields(id);
      }
      return await CustomObjectService.getObjectFields(id);
    },
    enabled,
  });
};

export const useCategoriesQuery = ({ objectType, id }, enabled) => {
  return useQuery({
    queryKey:
      objectType === CONTACT
        ? CATEGORIES.CLIENT()
        : CATEGORIES.CUSTOM_OBJECTS(objectType, id),
    queryFn: async () => {
      if (objectType === CONTACT) {
        return await FieldService.getCategories({ for: 'contacts' });
      }
      if (objectType === PIPELINE) {
        return await PipelineService.getObjectCategories(id);
      }
      return await CustomObjectService.getObjectCategories(id);
    },
    enabled,
  });
};

export const useModelQuery = (id, enabled, options) => {
  return useQuery({
    queryKey: CUSTOM_OBJECTS.MODEL(id),
    queryFn: async () => {
      try {
        const res = await FieldService.getModel({ id }, options);
        return res;
      } catch (ex) {
        return { error: ex };
      }
    },
    enabled,
  });
};

export const useContactCategories = (enabled) => {
  return useQuery({
    queryKey: CUSTOM_OBJECTS.CONTACT_CATEGORIES,
    queryFn: CustomObjectService.getContactCategories,
    enabled,
  });
};

export const useObjectDetails = (id, config) => {
  return useQuery({
    queryKey: CUSTOM_OBJECTS.DETAILS(id),
    queryFn: () => CustomObjectService.getCustomObjectDetails(id),
    ...config,
  });
};

export const useDefaultModelQuery = (clientObjectId, config, axiosConfig) => {
  const { useErrorBoundary, ...rest } = config ?? {};

  return useQuery({
    queryKey: CLIENTS.DEFAULT_MODEL,
    queryFn: () => FieldService.getModel({ id: clientObjectId }, axiosConfig),
    useErrorBoundary,
    ...rest,
  });
};

export const useUnrestrictedFieldsForObject = (id, enabled) => {
  return useQuery({
    queryKey: CUSTOM_OBJECTS.FIELDS_UNRESTRICTED(id),
    queryFn: CustomObjectService.getObjectFieldsWithoutRestrictions,
    enabled,
  });
};

export const useUnrestrictedContactFieldsAndCategoriesQuery = (enabled) => {
  return useQueries([
    {
      queryKey: CUSTOM_OBJECTS.CONTACT_FIELDS_UNRESTRICTED,
      queryFn: () =>
        FieldService.getFields({
          for: 'contacts',
          includeVisible: true,
          settingsRequest: true,
        }),
      enabled,
    },
    {
      queryKey: CUSTOM_OBJECTS.CONTACT_CATEGORIES,
      queryFn: CustomObjectService.getContactCategories,
      enabled,
    },
  ]);
};

export const useUnrestrictedFieldsAndDetailsQuery = (
  id,
  { isPipeline = false, enabled = false } = {}
) => {
  return useQueries([
    {
      queryKey: CUSTOM_OBJECTS.FIELDS_UNRESTRICTED(id),
      queryFn: () =>
        CustomObjectService.getObjectFieldsWithoutRestrictions(id, isPipeline),
      enabled,
    },
    {
      queryKey: CUSTOM_OBJECTS.DETAILS(id),
      queryFn: () => CustomObjectService.getCustomObjectDetails(id),
      enabled,
    },
  ]);
};

export const useContactRecordQuery = (id, params, opts) => {
  return useQuery({
    queryKey: CUSTOM_OBJECTS.CONTACT_RECORD(id, params),
    queryFn: () => {
      return CustomObjectService.getContactRecord(id, { params });
    },
    ...opts,
  });
};

export const useCustomObjectRecordQuery = (
  objectId,
  entityId,
  objectType,
  params,
  opts
) => {
  return useQuery({
    queryKey: CUSTOM_OBJECTS.RECORD(objectId, entityId, params),
    queryFn: () => {
      if (objectType === 'pipeline') {
        return PipelineService.getPipelineRecord(
          { id: entityId, objectId },
          { params }
        );
      }

      return CustomObjectService.getCustomObjectRecord(
        { id: entityId, objectId },
        { params }
      );
    },
    ...opts,
  });
};
