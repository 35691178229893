import { range } from 'components/Kizen/DateRangePicker';
import { useCallback, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-use';
import {
  updatePageConfig,
  getCustomObjectConfig,
} from 'store/recordDetailPage/record.redux';

import { setTimingMargin } from 'components/Kizen/DateRangePicker/utils';
import { subDays } from 'date-fns2';

export const DEFAULT_FILTER_CONFIG = {
  objects: {
    customObjectIds: '',
    objectsCount: 0,
  },
  excludeEvents: {
    excludeEventType: 'field_updated',
    excludeTypesCount: 1,
  },
  events: {
    eventType: '',
    typesCount: 0,
  },
  includeRelated: true,
};

const DEFAULT_REST_FILTER_CONFIG = {
  date_from: setTimingMargin(subDays(new Date(), 179)).toISOString(),
  date_to: setTimingMargin(new Date(), 'right').toISOString(),
};

const defaultPageConfig = {
  timeline: {
    filter: DEFAULT_FILTER_CONFIG,
  },
};

// when no related objects are included, we need to set the object filter to the current object
const mergeObjectFilters = (filter, noRelatedId) => {
  const { customObjectIds, objectsCount } = filter;
  const customObjectValues = customObjectIds.split(',');
  const customObjectValuesFiltered = customObjectValues.filter(
    (id) => id !== noRelatedId
  );

  if (noRelatedId) {
    customObjectValuesFiltered.push(noRelatedId);
    const customObjectIdsFiltered = customObjectValuesFiltered
      .filter((id) => id !== '')
      .join(',');
    return {
      customObjectIds: customObjectIdsFiltered,
      objectsCount: objectsCount,
    };
  }

  return filter;
};

const useDateFilters = (setFilters) => {
  const filterByDateHandler = useCallback(
    ([from, to]) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        date_from: new Date(from).toISOString(),
        date_to: new Date(to).toISOString(),
      }));
    },
    [setFilters]
  );
  return { filterByDateHandler };
};

const buildFilterPayload = ({
  preserveBlockFilters,
  eventId,
  created,
  detailPageConfig,
  blockId,
  metadata,
}) => {
  if (preserveBlockFilters && eventId && created) {
    return {
      ...DEFAULT_FILTER_CONFIG,
      ...detailPageConfig?.[blockId]?.filter,
      excludeEvents: {
        excludeEventType: '',
        excludeTypesCount: 0,
      },
      includeRelated: metadata?.includeRelated,
    };
  }

  if (eventId && created) {
    return {
      ...DEFAULT_FILTER_CONFIG,
      excludeEvents: {
        excludeEventType: '',
        excludeTypesCount: 0,
      },
      includeRelated: metadata?.includeRelated,
    };
  }

  if (detailPageConfig?.[blockId]?.filter) {
    return {
      ...detailPageConfig?.[blockId]?.filter,
      includeRelated: metadata?.includeRelated,
    };
  }

  return DEFAULT_FILTER_CONFIG;
};

export const getPrimaryFilters = (
  eventId,
  created,
  metadata,
  detailPageConfig,
  blockId,
  preserveBlockFilters = false
) => {
  const {
    objects: objectsFilter = DEFAULT_FILTER_CONFIG.objects,
    events: eventsFilter = DEFAULT_FILTER_CONFIG.events,
    excludeEvents: excludeEventsFilter = DEFAULT_FILTER_CONFIG.excludeEvents,
    includeRelated: includeRelatedFilter = DEFAULT_FILTER_CONFIG.includeRelated,
  } = buildFilterPayload({
    preserveBlockFilters,
    eventId,
    created,
    detailPageConfig,
    blockId,
    metadata,
  });

  return {
    objectsFilter,
    eventsFilter,
    excludeEventsFilter,
    includeRelatedFilter,
  };
};

export const getAggregatedFilters = ({
  metadata,
  restFilters,
  objectsFilter,
  eventsFilter,
  noRelatedId,
  excludeEventsFilter,
  includeRelatedFilter,
}) => {
  // whats going on here:
  // 1. if there is only one event type and its field_updated:
  //    a. we clear out the excluded
  //    b. and we don't show the fields filter
  // 2. if not include all event types:
  //    a. If some are chosen we use them
  //    b. If none are chosen we use all include so we don't fetch all

  const showFieldsFilter =
    metadata?.includeAll !== false ||
    (metadata?.included || []).find(({ id }) => id === 'field_updated');

  const justFieldsUpdated =
    showFieldsFilter && (metadata?.included || []).length === 1;

  const filters = {
    ...restFilters,
    ...mergeObjectFilters(objectsFilter, noRelatedId),
    ...eventsFilter,
    ...(justFieldsUpdated
      ? {
          excludeEventType: '',
          excludeTypesCount: 0,
        }
      : excludeEventsFilter),
    includeRelated: includeRelatedFilter,
  };

  const eventType =
    metadata?.includeAll === false && filters.eventType === ''
      ? (metadata?.included || []).map(({ id }) => id).join(',')
      : filters.eventType;

  const filtersForApi = {
    ...filters,
    eventType,
  };

  const showRelatedObjectsFilter = metadata?.includeRelated !== false;

  const showEventsFilter =
    metadata?.includeAll !== false || (metadata?.included || []).length > 1;

  return {
    filters,
    filtersForApi,
    showRelatedObjectsFilter,
    showEventsFilter,
    showFieldsFilter: justFieldsUpdated ? false : showFieldsFilter,
  };
};

const useTimelineInteraction = (
  customObjectId,
  metadata,
  blockId = 'timeline',
  overrideEventId,
  overrideCreated
) => {
  const dispatch = useDispatch();
  const [restFilters, setFilters] = useState(DEFAULT_REST_FILTER_CONFIG);
  const [searchTerm, setSearchTerm] = useState('');
  const { detailPageConfig = defaultPageConfig } = useSelector(
    getCustomObjectConfig(customObjectId)
  );

  const { search } = useLocation();
  const params = new URLSearchParams(search || '');

  // it's important that the empty string can allow us to override these and not use the params
  const event_id = overrideEventId ?? params.get('event_id');
  const created = overrideCreated ?? params.get('created');

  const searchRef = useRef({ event_id, created });
  const noRelatedId =
    metadata?.includeRelated === false ? customObjectId : undefined;

  const {
    objectsFilter,
    eventsFilter,
    excludeEventsFilter,
    includeRelatedFilter,
  } = getPrimaryFilters(
    searchRef.current.event_id,
    searchRef.current.created,
    metadata,
    detailPageConfig,
    blockId
  );

  const updatePageConfigFilters = useCallback(
    (payload) => {
      const blockDetails = detailPageConfig?.[blockId] ?? {};
      dispatch(
        updatePageConfig({
          customObjectId,
          blockId,
          detailPageConfig: {
            ...detailPageConfig,
            [blockId]: {
              ...blockDetails,
              filter: {
                ...blockDetails?.filter,
                ...payload,
              },
            },
          },
        })
      );
      searchRef.current = { event_id: null, created: null };
    },
    [dispatch, customObjectId, detailPageConfig, blockId]
  );

  const setObjectsFilter = useCallback(
    (objects) => {
      updatePageConfigFilters({ objects });
    },
    [updatePageConfigFilters]
  );

  const setEventsFilter = useCallback(
    (events) => {
      const payload = { events };

      updatePageConfigFilters(payload);
    },
    [updatePageConfigFilters]
  );

  const setExcludeEventsFilter = useCallback(
    (excludeEvents) => {
      const payload = { excludeEvents };

      updatePageConfigFilters(payload);
    },
    [updatePageConfigFilters]
  );

  const { filterByDateHandler } = useDateFilters(setFilters);

  const resetAllFilters = useCallback(() => {
    updatePageConfigFilters({
      objects: {
        customObjectIds: '',
        objectsCount: 0,
      },
      events: {
        eventType: '',
        typesCount: 0,
      },
      excludeEvents: {
        excludeEventType: '',
        excludeTypesCount: 0,
      },
    });
    filterByDateHandler(range.allTime.value);
    setFilters({});
  }, [updatePageConfigFilters, filterByDateHandler, setFilters]);

  const {
    filters,
    filtersForApi,
    showRelatedObjectsFilter,
    showEventsFilter,
    showFieldsFilter,
  } = useMemo(() => {
    return getAggregatedFilters({
      metadata,
      restFilters,
      objectsFilter,
      eventsFilter,
      noRelatedId,
      excludeEventsFilter,
      includeRelatedFilter,
    });
  }, [
    restFilters,
    objectsFilter,
    eventsFilter,
    excludeEventsFilter,
    noRelatedId,
    includeRelatedFilter,
    metadata,
  ]);

  return {
    filters,
    filtersForApi,
    showRelatedObjectsFilter,
    showEventsFilter,
    showFieldsFilter,
    params,
    searchRef,
    event_id,
    filterByDateHandler,
    searchTerm,
    setFilters,
    setObjectsFilter,
    setEventsFilter,
    setExcludeEventsFilter,
    setSearchTerm,
    resetAllFilters,
  };
};

export default useTimelineInteraction;
