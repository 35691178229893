import css from '@emotion/css';
import styled from '@emotion/styled';
import TableScrollContainer from 'components/Tables/ScrollContainer';

// We have to emulate what react-select does but without
// using their components, so these are essentially copied from their
// inline styles
const DEFAULT_REACT_SELECT_STYLES = `
  color: #4a5660;
  cursor: pointer;
  display: block;
  font-size: 1.4em;
  padding: 0 12px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;  
`;

export const StyledLink = styled.a`
  text-decoration: none;
  display: block;
  ${DEFAULT_REACT_SELECT_STYLES}

  &:hover {
    text-decoration: none;
    background-color: #cedefc;
    color: #4a5660;
  }

  &:active {
    text-decoration: none;
  }

  ${({ selected }) =>
    selected
      ? css`
          background-color: unset;
          color: #a8b3bc;
          cursor: default;

          &:hover {
            background-color: unset;
            color: #a8b3bc;
          }
        `
      : ''}
`;

export const StyledScrollContainer = styled(TableScrollContainer)`
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  overflow-y: auto;
  max-height: 36rem;
  min-height: 3rem;

  & > div,
  & .ScrollChildren,
  & .ScrollChildren > div:first-child {
    width: 100%;
  }

  & .ScrollChildren > div:last-child {
    margin-left: -8px;
    right: 0;
  }
`;
