import AuthenticationService from 'services/AuthenticationService';
import FieldService from 'services/FieldService';
import { BUSINESS } from '../query-keys';
import { useQuery } from 'react-query';

export const useBusinessConfigQuery = (enabled = false) => {
  return useQuery(
    [...BUSINESS.ALL],
    AuthenticationService.getBusinessTypesAndTimezones,
    {
      enabled,
    }
  );
};

export const useBusinessCountriesQuery = (enabled = false) => {
  return useQuery([...BUSINESS.COUNTRIES], FieldService.getCountryOptions, {
    enabled,
  });
};

export const useBusinessHolidaysCountriesQuery = () => {
  return useQuery(
    [...BUSINESS.HOLIDAYS_COUNTRIES],
    AuthenticationService.getBusinessHolidaysCountries
  );
};

export const useBusinessTextNumberQuery = (enabled = false) => {
  return useQuery(
    [...BUSINESS.TEXT_NUMBER],
    AuthenticationService.getBusinessTextNumber,
    {
      enabled,
    }
  );
};
