const columns = (t) => ({
  name: {
    label: t('List Name'),
    key: 'name',
    fixed: true,
    width: 250 + 60,
  },
  description: {
    label: t('List Description'),
    key: 'description',
    fixed: false,
    width: 555 + 60,
  },
  frequency: {
    label: t('Frequency'),
    key: 'frequency',
    fixed: true,
    width: 120 + 60,
  },
  autoSubscribe: {
    label: t('Auto Add?'),
    key: 'autoSubscribe',
    fixed: true,
    width: 70 + 60,
  },
  actions: {
    label: t('Actions'),
    key: 'actions',
    fixed: true,
    width: 54,
  },
});

export default columns;
