import React, { useState } from 'react';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import FileInput from 'components/Kizen/FileInput';

const StyledFileInput = styled(FileInput)`
  margin-bottom: ${gutters.standard};
`;

const FileInputs = () => {
  const [files, setFiles] = useState([]);
  const [errorFiles, setErrorFiles] = useState([]);

  return (
    <>
      <StyledFileInput
        label="File Input"
        files={files}
        onChange={(nextFiles) => setFiles(nextFiles)}
      />
      <StyledFileInput
        label="Disabled"
        files={[]}
        onChange={() => {}}
        disabled
      />
      <StyledFileInput
        label="Error"
        files={errorFiles}
        onChange={(nextFiles) => setErrorFiles(nextFiles)}
        error
      />
    </>
  );
};

export default FileInputs;
