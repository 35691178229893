export enum COLUMN_SIZE {
  FULL_WIDTH = 'full-width',
  HALF_WIDTH = 'half-width',
  THIRD_WIDTH = 'third-width',
  TWO_THIRD_WIDTH = 'two-third-width',
}

export type OneColumn = [COLUMN_SIZE.FULL_WIDTH];

export type TwoColumns =
  | [COLUMN_SIZE.HALF_WIDTH, COLUMN_SIZE.HALF_WIDTH]
  | [COLUMN_SIZE.THIRD_WIDTH, COLUMN_SIZE.TWO_THIRD_WIDTH]
  | [COLUMN_SIZE.TWO_THIRD_WIDTH, COLUMN_SIZE.THIRD_WIDTH];

export type ThreeColumns = [
  COLUMN_SIZE.THIRD_WIDTH,
  COLUMN_SIZE.THIRD_WIDTH,
  COLUMN_SIZE.THIRD_WIDTH,
];

export interface TwoColumnRow {
  id: string;
  layout: TwoColumns;
  content: [any, any];
}

export interface ThreeColumnRow {
  id: string;
  layout: ThreeColumns;
  content: [any, any, any];
}

export interface OneColumnRow {
  id: string;
  layout: OneColumn;
  content: [any];
}

export type Row = TwoColumnRow | ThreeColumnRow | OneColumnRow;

export type GridConfig = Row[];
