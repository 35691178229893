import { useEditor } from '@craftjs/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPlacingItem } from 'store/pageBuilder/selectors';

export const useDraggingType = () => {
  const placingItem = useSelector(getPlacingItem);
  const { query, dragId } = useEditor((state) => {
    return {
      dragId: [...state.events.dragged.values()][0],
    };
  });
  return useMemo(() => {
    if (placingItem) {
      return placingItem.type;
    }
    if (dragId) {
      const node = query.node(dragId).get();
      return node.data.name;
    }
    return null;
  }, [placingItem, dragId, query]);
};
