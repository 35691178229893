import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import Button from 'components/Button';
import { TRow } from 'components/Kizen/Table';
import BigTableLayout, {
  useBigTableLayout,
} from 'components/Layout/BigTableLayout';
import PageToolbar, {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import TablePagination from 'components/Tables/Big/TablePagination';
import BigTable from 'components/Tables/Big';
import getSurveysColumns, { SURVEY_ACTIONS } from './columns';
import { useSelector } from 'react-redux';
import { selectSurveysIsFiltering } from 'store/surveysPage/surveys.redux';
export default function SurveyTabularPage(props) {
  const {
    surveys,
    surveysCount,
    search,
    onChangeSearch,
    sort,
    onChangeSort,
    pageNumber,
    pageSize,
    onChangePageNumber,
    onChangePageSize,
    onUpdateSurveyField,
    onSelectPreviewSurvey,
    onSelectDuplicateSurvey,
    onSelectDeleteSurvey,
    openAddModal,
    ...others
  } = props;
  const { t } = useTranslation();
  const isFiltering = useSelector(selectSurveysIsFiltering);

  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout();

  const { scrolledToTable } = bigTablePageProps;

  // So that we can reference props in memoized values
  const propsRef = useRef();
  propsRef.current = props;

  const columns = useMemo(() => {
    const surveyActions = SURVEY_ACTIONS(t);
    return getSurveysColumns({
      onUpdateField: (survey, patch) =>
        propsRef.current.onUpdateSurveyField(survey, patch),
      onSelectAction: ({ value }, survey) => {
        const { id, slug } = survey;
        if (value === surveyActions.Edit.value) {
          propsRef.current.onSelectEditSurvey(id);
        }
        if (value === surveyActions.Preview.value) {
          propsRef.current.onSelectPreviewSurvey(slug);
        }
        if (value === surveyActions.Duplicate.value) {
          propsRef.current.onSelectDuplicateSurvey(id);
        }
        if (value === surveyActions.Delete.value) {
          propsRef.current.onSelectDeleteSurvey(id);
        }
      },
      t,
    });
  }, [t]);

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => onChangeSort({ column, direction }),
      },
    }),
    [sort, onChangeSort]
  );

  return (
    <BigTableLayout
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={t('Find Surveys')}
              value={search}
              onChange={onChangeSearch}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={surveysCount}
            single={t('Survey')}
            plural={t('Surveys')}
          />
          <PageToolbarSection>
            <Button onClick={openAddModal}>{t('New Survey')}</Button>
          </PageToolbarSection>
        </PageToolbar>
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={surveysCount}
          onChangePage={onChangePageNumber}
          onChangePerPage={onChangePageSize}
        />
      }
      recordTabSpacing={false}
      {...bigTablePageProps}
      {...others}
    >
      <BigTable
        scrolledToTable={scrolledToTable}
        columns={columns}
        head={<TRow head columns={columns} data={headData} />}
        staleData={isFiltering}
      >
        {/* Just a spacer when there are no results */}
        {!surveys.length && <tr />}
        {surveys.map((survey) => (
          <TRow
            key={survey.id}
            columns={columns}
            data={survey}
            data-qa-survey={survey.id}
          />
        ))}
      </BigTable>
    </BigTableLayout>
  );
}

SurveyTabularPage.propTypes = {
  surveys: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveysCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onUpdateSurveyField: PropTypes.func.isRequired,
  onSelectEditSurvey: PropTypes.func.isRequired,
  onSelectPreviewSurvey: PropTypes.func.isRequired,
  onSelectDuplicateSurvey: PropTypes.func.isRequired,
  onSelectDeleteSurvey: PropTypes.func.isRequired,
};
