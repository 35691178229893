export const ENTITY_TYPES = {
  SURVEY: 'survey',
  FORM: 'form',
  ACTIVITY: 'activity',
  PIPELINE: 'pipeline',
  COMMERCE: 'commerce',
  MARKETING: 'marketing',
  EMAILS: 'email',
};

export const AREA_RESPONSES = {
  PIPELINES: 'pipeline',
  ACTIVITIES: 'activity',
  EMAILS: 'email',
  MARKETING: 'marketing',
  CUSTOM_OBJECT: 'custom_object',
  TABLE: 'table_of_records',
};

export const DISPLAY_VALUE_RESPONSES = {
  RECORDS: 'records_number',
  VALUE: 'records_value',
};

export const INCLUDED_RECORDS_RESPONSES = {
  OPEN: 'open',
  WON: 'won',
  OPEN_AND_WON: 'open_and_won',
};

export const VALUE_TO_DISPLAY_RESPONSES = {
  TOTAL: 'records_value',
  WEIGHTED: 'records_weighted_value',
};

export const DATAPOINT_FREQUENCY_REPONSES = {
  DAILY: 'day',
  WEEKLY: 'week',
  MONTHLY: 'month',
  QUARTERLY: 'quarter',
  YEARLY: 'year',
};

export const REPORT_TYPES = {
  RECORDS_ADDED: 'records_added',
  RECORDS_LOST: 'records_lost',
  RECORDS_WON: 'records_won',
  RECORDS_DISQUALIFIED: 'records_dq',
  SCHEDULED_ACTIVITIES: 'scheduled_activities',
  OPPORTUNITY_CONVERSION: 'opportunity_conversion',
  VALUE_OVER_TIME: 'pipeline_values_over_time',
  LEADERBOARD_WITH_PROJECTIONS: 'leaderboard',
  NUMBER_OF_ACTIVITY_SUBMISSIONS: 'records_added',
  STAGES_OVER_TIME: 'stage_values_over_time',
  EMAILS_SENT: 'email_sent',
  DELIVERY_PERCENT: 'email_delivery',
  COMPLAINT_PERCENT: 'email_complaint',
  OPT_OUT_PERCENT: 'email_opt_out',
  INTERACTION_STATS: 'email_interaction_stats',
  LEADS_ADDED_BY_SOURCE: 'leads_added_by_source',
  LEADS_ADDED: 'leads_added',
  LEAD_SOURCE_BREAKDOWN: 'lead_source_breakdown_over_time',
};

export const PERCENTAGE_REPORT_TYPES = [
  REPORT_TYPES.COMPLAINT_PERCENT,
  REPORT_TYPES.DELIVERY_PERCENT,
  REPORT_TYPES.OPT_OUT_PERCENT,
];

export const LEVEL_OF_DETAIL_OPTIONS = {
  SUM_OF_STAGES: 'sum_of_stages',
  STAGE_BREAKDOWN: 'stages_breakdown',
};

export const entityMap = {
  [ENTITY_TYPES.ACTIVITY]: {
    icon: 'clipboard-list',
    label: (t) => t('Activities'),
    value: AREA_RESPONSES.ACTIVITIES,
    size: 'lg',
    order: 0,
    enabled: true,
  },
  [ENTITY_TYPES.PIPELINE]: {
    icon: 'pipeline-entity',
    label: (t) => t('Pipelines'),
    value: AREA_RESPONSES.PIPELINES,
    order: 1,
    enabled: true,
  },
  [ENTITY_TYPES.EMAILS]: {
    icon: 'email-light',
    label: (t) => t('Emails'),
    value: AREA_RESPONSES.EMAILS,
    order: 3,
    enabled: true,
  },
  [ENTITY_TYPES.MARKETING]: {
    icon: 'bullseye-pointer',
    label: (t) => t('Marketing'),
    value: AREA_RESPONSES.MARKETING,
    order: 2,
    enabled: true,
  },
};

export const REPORT_NAMES = {
  [REPORT_TYPES.RECORDS_ADDED]: (t) => t('Added'),
  [REPORT_TYPES.RECORDS_LOST]: (t) => t('Lost'),
  [REPORT_TYPES.RECORDS_WON]: (t) => t('Won'),
  [REPORT_TYPES.RECORDS_DISQUALIFIED]: (t) => t('Disqualified'),
};

export const LEVEL_OF_DETAIL_NAMES = {
  [LEVEL_OF_DETAIL_OPTIONS.SUM_OF_STAGES]: (t) => t('Sum of Stages'),
  [LEVEL_OF_DETAIL_OPTIONS.STAGE_BREAKDOWN]: (t) => t('Stage'),
};

export const INVERSE_MAPPING = {
  [REPORT_TYPES.RECORDS_ADDED]: false,
  [REPORT_TYPES.RECORDS_LOST]: true,
  [REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS]: false,
  [REPORT_TYPES.RECORDS_WON]: false,
  [REPORT_TYPES.RECORDS_DISQUALIFIED]: true,
  [REPORT_TYPES.EMAILS_SENT]: false,
  [REPORT_TYPES.COMPLAINT_PERCENT]: true,
  [REPORT_TYPES.DELIVERY_PERCENT]: false,
  [REPORT_TYPES.OPT_OUT_PERCENT]: true,
};

export const RECORD_INCLUDED_NAMES = {
  [INCLUDED_RECORDS_RESPONSES.WON]: (t) => t('Won'),
  [INCLUDED_RECORDS_RESPONSES.OPEN]: (t) => t('Open'),
  [INCLUDED_RECORDS_RESPONSES.OPEN_AND_WON]: (t) => t('Open & Won'),
};
