import React from 'react';
import { gutters } from 'app/spacing';
import styled from '@emotion/styled';

import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import { fontSizes } from 'app/typography';
import { OutlineLabel } from 'components/Inputs/InputControl';

const Section = styled.div`
  &:not(:last-child) {
    margin-bottom: ${gutters.spacing(4, -2)}px;
  }
  h3 {
    line-height: 1;
    margin-bottom: ${gutters.spacing(3, {
      baseline: fontSizes.subheader,
    })}px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${gutters.spacing(4)}px;
`;

const InfoOutlineLabel = styled(OutlineLabel)`
  display: inline-flex;
`; // to center the icon

export const ButtonGrid = ({ value, onChange, section }) => {
  const { label, options } = section;
  return (
    <Section>
      <InfoOutlineLabel>{label}</InfoOutlineLabel>
      <Grid>
        {options.map((option) => (
          <ButtonGroupImageButton
            key={option.value}
            selected={option.value === value}
            onClick={() => onChange(option)}
            option={option}
          />
        ))}
      </Grid>
    </Section>
  );
};
// export default ButtonGrid;
