import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { borderRadii, breakpoints } from 'app/spacing';

export const InfoModalContents = styled.div`
  display: flex;
  flex-direction: column;
  ${({ loading }) => loading && `min-height: 100px;`}

  ${({ isScrollableTable }) =>
    isScrollableTable &&
    `
overflow: hidden;
border: 1px solid ${grayScale.medium};
border-radius: ${borderRadii.small};
`}

${({ isActivities }) =>
    isActivities &&
    `
width: 100%;
@media (max-width: ${breakpoints.lg}px) {
border: none;
}`}
`;
