import { forwardRef, useCallback, useRef } from 'react';
import useSyncScroll from 'react-use-sync-scroll';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useMeasure } from 'react-use';
import { hideScrollbarCss } from 'app/spacing';
import { applyRef } from 'components/Inputs/props';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { clamp } from 'utility/clamp';
import { useBuilderContext, useRootNodeProps } from '../BuilderContext';
import mobileImage from '../images/iphone13.png';
import { CanvasWrapper } from './CanvasWrapper';
import {
  CanvasMeasureContainer,
  canvasScrollFadeContainerStyle,
  Content,
} from './styled';
import { fitClamped } from './utils';

const MOBILE_IMAGE_HEIGHT = 890;
const MOBILE_BEZEL = 20;
const MOBILE_CURVATURE_HEIGHT = 40;

const MobileWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  max-width: 420px;
  max-height: 870px;
  padding: 75px 12px 16px 13px;
  padding-bottom: ${({ paddingBottom = 16 }) => `${paddingBottom}px`};
  background-color: ${({ backgroundColor = '#FFFFFF' }) => backgroundColor};
  border-radius: 75px;
`;

const MobileImage = styled.div`
  position: absolute;
  top: -9px; // ensures to 20px top padding of page builder canvas
  left: -25px;
  background: url(${mobileImage});
  background-size: cover;
  width: 450px;
  height: ${MOBILE_IMAGE_HEIGHT}px;
  pointer-events: none;
`;

const StyledCanvasWrapper = styled(CanvasWrapper)`
  overflow: hidden;
`;

const StyledScrollFadeContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  ${canvasScrollFadeContainerStyle}
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow: overlay;
  ${({ borderRadius }) => css`
    border-bottom-right-radius: ${borderRadius}px;
    border-bottom-left-radius: ${borderRadius}px;
  `}
`;

const StyledScrollbarDetached = styled(ScrollBarDetached)`
  transform: translateX(-10px);
  height: calc(
    100% + ${({ heightOffset }) => `${heightOffset}px`}
  ); // stop at the bezel's curvature so scrollbar does not appear over the image
`;

export const MobileCanvas = forwardRef(
  ({ disabled = false, paddingTop, children }, ref) => {
    const {
      clearContentSettingsTray,
      setCanvasScrollContainer,
      setCanvasWrapper,
      setScrolling
    } = useBuilderContext();
    const [canvasMeasureRef, { height }] = useMeasure();
    const [screenMeasureRef, { width: screenWidth }] = useMeasure();
    const { backgroundColor } = useRootNodeProps();
    const scrollbarRef = useRef();
    const [scrollFadeRef, scrollRef] = useSyncSizes(
      scrollbarRef,
      '.ContentForSyncWidth',
      'height'
    );
    const scrolled = useEndsOfScroll(scrollRef, [height]);
    const paddingBottom =
      height <= MOBILE_IMAGE_HEIGHT // height == MOBILE_IMAGE_HEIGHT is when the bottom of the screen touches the bottom of the phone
        ? 16 - clamp(MOBILE_IMAGE_HEIGHT - height, 0, 16)
        : 16;
    const getBorderRadius = (fullheight) =>
      height > MOBILE_IMAGE_HEIGHT - MOBILE_BEZEL
        ? fullheight
        : fitClamped(
            height,
            MOBILE_IMAGE_HEIGHT - MOBILE_BEZEL * 2, // MOBILE_BEZEL * 2 is arbitrary - gradually reduce border radius along the curvature
            MOBILE_IMAGE_HEIGHT - MOBILE_BEZEL,
            0,
            35
          );
    const scrollbarHeightOffset =
      // if the bottom is not cuttoff, stop at the curvature; otherwise go to bottom of screen
      height > MOBILE_IMAGE_HEIGHT - 2 * MOBILE_BEZEL
        ? -MOBILE_CURVATURE_HEIGHT
        : 0;

    useSyncScroll(useRef([scrollRef, scrollbarRef]), {
      vertical: true,
    });

    const refCallback = useCallback(
      (node) => {
        scrollbarRef.current = node;
        setCanvasScrollContainer(node);

        let timeoutId = null;

        const onscroll = (ev) => {
          const isScrolledTop = ev.target.scrollTop === 0;
          const isScrolledBottom =
            ev.target.scrollHeight ===
            ev.target.scrollTop + ev.target.clientHeight;

          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          if (!isScrolledTop && !isScrolledBottom) {
            setScrolling(true);
          }

          timeoutId = setTimeout(() => setScrolling(false), 50);
        };

        if (node) {
          node.addEventListener('scroll', onscroll);
        }

        return () => {
          scrollbarRef.current.removeEventListener('scroll', onscroll);
        };
      },
      [setCanvasScrollContainer, setScrolling]
    );

    const scrollFadeRefCallback = useCallback(
      (node) => {
        scrollFadeRef(node);
        screenMeasureRef(node);
      },
      [scrollFadeRef, screenMeasureRef]
    );

    const canvasWrapperRefCallback = useCallback(
      (node) => {
        if (node) {
          setCanvasWrapper(node);
        }
      },
      [setCanvasWrapper]
    );

    const mergeRef = useCallback(
      (node) => {
        canvasMeasureRef(node);
        applyRef(ref, node);
      },
      [ref, canvasMeasureRef]
    );

    return (
      <CanvasMeasureContainer ref={mergeRef} onClick={clearContentSettingsTray}>
        <StyledCanvasWrapper
          ref={canvasWrapperRefCallback}
          width={screenWidth}
          removePaddingBottom={height - MOBILE_BEZEL < MOBILE_IMAGE_HEIGHT}
          paddingTop={paddingTop}
        >
          <MobileWrapper
            backgroundColor={backgroundColor}
            paddingBottom={paddingBottom}
          >
            <StyledScrollFadeContainer
              ref={scrollFadeRefCallback}
              scrolled={scrolled}
              borderRadius={getBorderRadius(45)}
              top
              bottom
            >
              <Content
                ref={scrollRef}
                className="ContentForSyncWidth"
                disabled={disabled}
              >
                {children}
              </Content>
            </StyledScrollFadeContainer>
            <MobileImage />
            <StyledScrollbarDetached
              ref={refCallback}
              direction="vertical"
              scrollClassName="ContentForSyncWidth"
              heightOffset={scrollbarHeightOffset}
            />
          </MobileWrapper>
        </StyledCanvasWrapper>
      </CanvasMeasureContainer>
    );
  }
);

MobileCanvas.displayName = 'MobileCanvas';
