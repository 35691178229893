import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import { useBuilderContext } from '../BuilderContext';
import { TraySection, TraySubsection } from '../components/TraySection';
import { useTranslation } from 'react-i18next';
import {
  ButtonActions,
  getBackwardsCompatibleButtonProps,
} from '@kizen/page-builder/nodes/Button';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { urlValidation } from 'utility/validate';
import { PaddingSettings } from './SpacingSettings';
import {
  alignmentOptions,
  fontSizeOptions,
  textStyleOptions,
} from '../options';
import {
  FlexSelect,
  FlexUnderlineMultiSelect,
  FlexTextInput,
  FlexWholeNumberInput,
  FlexWrapper,
  FlexColorPicker,
  FlexSwitch,
} from './components';
import { useFonts, useFontOptionLookup } from './useFonts';
import { useCraftProps } from './useCraftProps';

export const GoToUrlOption = {
  value: ButtonActions.URL,
  label: (t) => t('Go to URL'),
};
export const GoToPageOption = {
  value: ButtonActions.GO_TO_PAGE,
  label: (t) => t('Do Not Submit and Go To Page'),
};
export const GoToPageAndSubmitOption = {
  value: ButtonActions.GO_TO_PAGE_SUBMIT,
  label: (t) => t('Submit and Go To Page'),
};
export const SubmitOption = {
  value: ButtonActions.SUBMIT,
  label: (t) => t('Submit and Finish'),
};
export const ScriptExecutionOption = {
  value: ButtonActions.SCRIPT_EXECUTION,
  label: (t) => t('Execute Approved Javascript'),
};
export const LogActivityOption = {
  value: ButtonActions.LOG_ACTIVITY,
  label: (t) => t('Log Activity'),
};
export const StartAutomationOption = {
  value: ButtonActions.START_AUTOMATION,
  label: (t) => t('Start Automation'),
};

const RecaptchaButtonAction = new Set([
  ButtonActions.SUBMIT,
  ButtonActions.GO_TO_PAGE_SUBMIT,
  ButtonActions.GO_TO_PAGE,
]);

const isNavigationButtonAction = (action) =>
  action === ButtonActions.GO_TO_PAGE ||
  action === ButtonActions.GO_TO_PAGE_SUBMIT;

const ButtonText = styled(FlexTextInput)`
  input {
    text-overflow: ellipsis;
  }
`;

const ActionSelect = styled(FlexSelect)`
  z-index: ${layers.content(4)};
`;

const PageSelect = styled(FlexSelect)`
  z-index: ${layers.content(3)};
`;

const FontSelect = styled(FlexSelect)`
  // above all z-index values used in PageBuilder/components/UnderlineRadio (font style input)
  z-index: ${layers.content(2)};
`;

const TransitionableFlexWrapper = styled(FlexWrapper)`
  height: 0;
  transition: height 0.2s linear;
  ${({ show }) =>
    show
      ? css`
          height: 44px;
        `
      : css`
          margin: 0;
        `};
`;

export const ButtonSettingsSection = ({
  node,
  pageOptions = [],
  emailMode,
  actionOptions = [
    GoToUrlOption,
    GoToPageOption,
    GoToPageAndSubmitOption,
    SubmitOption,
  ],
  allowRelativeLinks = false,
  selectedActivity = null,
  selectedAutomation = null,
}) => {
  const {
    id,
    data: { props: initialProps, custom = {} },
  } = node;
  const isDefaultSubmitButton = !!custom.isSubmitButton;
  const hasLogActivityOption = actionOptions.some(
    (opt) => opt.value === ButtonActions.LOG_ACTIVITY
  );
  const hasStartAutomationOption = actionOptions.some(
    (opt) => opt.value === ButtonActions.START_AUTOMATION
  );

  const traySectionRef = useRef();
  const { t } = useTranslation();
  const {
    clearContentSettingsTray,
    setPreventCloseSettingsTray,
    customObjectId,
    onNodePropChange,
    enableScriptExecution,
    javascriptActions,
  } = useBuilderContext();
  const fontOptions = useFonts(emailMode);
  const fontOptionLookup = useFontOptionLookup(fontOptions);
  const [props, setProp, setProps] = useCraftProps(
    id,
    getBackwardsCompatibleButtonProps(initialProps)
  );
  const [url, setUrl] = useState(props.url);
  const [activity, setActivity] = useState(selectedActivity);
  const [automation, setAutomation] = useState(selectedAutomation);
  const showPageSelector = !emailMode && isNavigationButtonAction(props.action);
  const showEnableRecaptcha = RecaptchaButtonAction.has(props.action);

  const activitySelectRef = useRef();
  const [activityInfiniteOpts] = useSelectTypeaheadWithScroll({
    selectRef: activitySelectRef,
    entity: customObjectId ? Entities.ActivitiesLoggable : Entities.Activities,
    enabled: hasLogActivityOption,
    fieldId: customObjectId,
  });

  const options = useMemo(
    () =>
      actionOptions.map((item) => ({
        ...item,
        label: typeof item.label === 'function' ? item.label(t) : item.label,
      })),
    [actionOptions, t]
  );

  const automationSelectRef = useRef();
  const [automationInfinityOpts] = useSelectTypeaheadWithScroll({
    selectRef: automationSelectRef,
    entity: Entities.Automations,
    enabled: hasStartAutomationOption,
    params: {
      active: true,
      custom_object_id: customObjectId,
    },
  });

  const javascriptActionOptions = Array.isArray(javascriptActions)
    ? javascriptActions.map((action) => {
        return {
          label: action.name,
          value: action.id,
        };
      })
    : [];

  useEffect(() => {
    setProps(initialProps);
    setUrl(initialProps.url);
  }, [initialProps, setProps]);

  useEffect(() => {
    if (selectedActivity) {
      setActivity(selectedActivity);
    } else {
      setActivity(null);
    }
  }, [selectedActivity]);

  useEffect(() => {
    if (selectedAutomation) {
      setAutomation(selectedAutomation);
    } else {
      setAutomation(null);
    }
  }, [selectedAutomation]);

  useEffect(() => {
    if (!emailMode && isDefaultSubmitButton) {
      if (props.action === undefined) {
        // convert old forms/surveys that may not have an action specified.
        setProp('action', ButtonActions.SUBMIT);
      } else if (
        pageOptions.length === 1 &&
        props.action !== ButtonActions.SUBMIT
      ) {
        // reset the default submit button to "Submit and Finish" action type if it was set to
        // another type when there were mulitple form pages and then those form pages were deleted
        setProp('action', ButtonActions.SUBMIT);
      }
    }
    if (emailMode && props.action === undefined) {
      // convert old emails that may not have an action specified.
      setProp('action', ButtonActions.URL);
    }
  }, [
    isDefaultSubmitButton,
    emailMode,
    setProp,
    props.action,
    props.openLinkInNewTab,
    pageOptions.length,
  ]);

  return (
    <TraySection
      ref={traySectionRef}
      onBackClick={clearContentSettingsTray}
      collapsable={false}
      header={t('Button Settings')}
    >
      <TraySubsection header={t('Action')}>
        <FlexWrapper>
          {emailMode ? null : (
            <ActionSelect
              disabled={
                (isDefaultSubmitButton && pageOptions.length === 1) ||
                options.length === 1
              }
              variant="underline"
              label={t('Action')}
              value={props.action}
              options={options}
              scrollContainer={traySectionRef.current}
              onChange={(val) => {
                if (val.value === ButtonActions.URL) {
                  setProp('openLinkInNewTab', true);
                }
                if (hasLogActivityOption) {
                  setProp('activityId', undefined);
                  setActivity(null);
                }
                if (hasStartAutomationOption) {
                  setProp('automationId', undefined);
                  setAutomation(null);
                }

                const goToPage = isNavigationButtonAction(val.value)
                  ? pageOptions[0]?.value
                  : undefined;
                setProp('goToPage', goToPage);
                setProp('action', val.value);
              }}
            />
          )}
        </FlexWrapper>
        <FlexWrapper>
          {props.action === ButtonActions.URL && (
            <FlexTextInput
              variant="underline"
              label={t('URL')}
              flex={1}
              fullWidth
              value={url}
              onChange={(val) => {
                setUrl(val);
                if (
                  !emailMode ||
                  urlValidation(t, allowRelativeLinks)(val) === true
                ) {
                  setProp('url', val);
                }
              }}
              validate={{ full: urlValidation(t, allowRelativeLinks) }}
              onErrorShow={() => setPreventCloseSettingsTray(true)}
              onErrorHide={() => setPreventCloseSettingsTray(false)}
              key={id}
            />
          )}
          {props.action === ButtonActions.LOG_ACTIVITY && (
            <FlexSelect
              {...activityInfiniteOpts}
              ref={activitySelectRef}
              variant="underline"
              label={t('Activity to Log')}
              placeholder={t('Choose Activity')}
              value={activity}
              onChange={(opt) => {
                setActivity(opt);
                setProp('activityId', opt.value);
              }}
            />
          )}
          {props.action === ButtonActions.START_AUTOMATION && (
            <FlexSelect
              {...automationInfinityOpts}
              ref={automationSelectRef}
              variant="underline"
              label={t('Automation to Start')}
              placeholder={t('Choose Automation')}
              value={automation}
              onChange={(opt) => {
                setAutomation(opt);
                setProp('automationId', opt.value);
              }}
            />
          )}
        </FlexWrapper>
        {enableScriptExecution && (
          <TransitionableFlexWrapper
            show={props.action === ButtonActions.SCRIPT_EXECUTION}
          >
            <FlexSelect
              variant="underline"
              label={t('JavaScript to Execute')}
              value={props.scriptId}
              options={javascriptActionOptions}
              onChange={({ value }) => {
                setProp('scriptId', value);
              }}
            />
          </TransitionableFlexWrapper>
        )}
        {emailMode ? null : (
          <TransitionableFlexWrapper show={showPageSelector}>
            <PageSelect
              variant="underline"
              label={t('Page')}
              value={props.goToPage}
              options={pageOptions}
              scrollContainer={traySectionRef.current}
              onChange={(val) => setProp('goToPage', val.value)}
            />
          </TransitionableFlexWrapper>
        )}
        {showEnableRecaptcha && (
          <FlexWrapper>
            <FlexSwitch
              variant="underline"
              label={t('Enable reCAPTCHA')}
              textPlacement="top"
              checked={props.enableRecaptcha}
              onChange={(ev) => {
                onNodePropChange({
                  ...node.data,
                  type: { resolvedName: 'Button' }, // override the runtime type prop (a function)
                  props: {
                    ...props,
                    enableRecaptcha: ev.target.checked,
                  },
                });
                setProp('enableRecaptcha', ev.target.checked);
              }}
            />
          </FlexWrapper>
        )}
        {props.action === ButtonActions.URL && (
          <FlexWrapper>
            <FlexSwitch
              variant="underline"
              label={t('Open link in new tab')}
              textPlacement="top"
              checked={props.openLinkInNewTab}
              onChange={(ev) => setProp('openLinkInNewTab', ev.target.checked)}
            />
          </FlexWrapper>
        )}
      </TraySubsection>

      <TraySubsection header={t('Text')}>
        <FlexWrapper>
          <ButtonText
            variant="underline"
            label={t('Button Text')}
            value={props.label}
            onChange={(val) => setProp('label', val)}
          />
        </FlexWrapper>
        <FlexWrapper>
          <FontSelect
            variant="underline"
            label={t('Font')}
            value={fontOptionLookup[props.fontFamily]}
            options={fontOptions}
            scrollContainer={traySectionRef.current}
            onChange={(option) => setProp('fontFamily', option.value)}
          />
        </FlexWrapper>
        <FlexWrapper>
          <FlexSelect
            variant="underline"
            label={t('Size')}
            value={props.fontSize}
            options={fontSizeOptions}
            scrollContainer={traySectionRef.current}
            onChange={({ value }) => setProp('fontSize', value)}
          />
          <FlexUnderlineMultiSelect
            label={t('Style')}
            variant="outline"
            fontSize="text"
            value={props.textStyles}
            options={textStyleOptions}
            onChange={(val) => setProp('textStyles', val)}
          />
          <FlexSelect
            variant="underline"
            label={t('Alignment')}
            value={props.alignment}
            options={alignmentOptions(t)}
            scrollContainer={traySectionRef.current}
            onChange={({ value }) => setProp('alignment', value)}
          />
        </FlexWrapper>
      </TraySubsection>

      <TraySubsection header={t('Button Colors')}>
        <FlexWrapper>
          <FlexColorPicker
            color={props.color}
            label={t('Background')}
            onChange={(rgba) => setProp('color', rgba)}
          />
          <FlexColorPicker
            color={props.textColor}
            label={t('Text Color')}
            align="center"
            onChange={(rgba) => setProp('textColor', rgba)}
          />
          <FlexColorPicker
            color={props.borderColor}
            label={t('Border Color')}
            align="right"
            onChange={(rgba) => setProp('borderColor', rgba)}
          />
        </FlexWrapper>
      </TraySubsection>

      <TraySubsection header={t('Border')}>
        <FlexWrapper>
          <FlexWholeNumberInput
            variant="underline"
            label={t('Thickness (px)')}
            value={props.borderSize}
            onChange={(val) => setProp('borderSize', val)}
          />
          <FlexWholeNumberInput
            variant="underline"
            label={t('Radius (px)')}
            value={props.borderRadius}
            onChange={(val) => setProp('borderRadius', val)}
          />
        </FlexWrapper>
      </TraySubsection>

      <TraySubsection header={t('Spacing (px)')}>
        <FlexWrapper>
          <PaddingSettings
            top={props.paddingTop}
            right={props.paddingRight}
            bottom={props.paddingBottom}
            left={props.paddingLeft}
            onTopChange={(val) => setProp('paddingTop', val)}
            onRightChange={(val) => setProp('paddingRight', val)}
            onBottomChange={(val) => setProp('paddingBottom', val)}
            onLeftChange={(val) => setProp('paddingLeft', val)}
          />
        </FlexWrapper>
      </TraySubsection>
    </TraySection>
  );
};

ButtonSettingsSection.propTypes = {
  node: PropTypes.object.isRequired,
};
