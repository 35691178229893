import { useMemo } from 'react';
import {
  getDefaultColumnSettings,
  getLeftColumnOrdering,
  getLeftItems,
} from '../helpers';
import { useTranslation } from 'react-i18next';

export const useDraggableColumns = ({ model, categorizedFields }) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const fields = categorizedFields.flatMap(({ fields }) =>
      fields.filter(({ isHidden }) => !isHidden)
    );

    const defaultColumnSettings = getDefaultColumnSettings(model, fields, t);

    const rightItems = defaultColumnSettings.filter(({ visible }) => visible);

    const leftItems = getLeftItems(
      categorizedFields,
      defaultColumnSettings,
      rightItems
    );

    const itemsOrdering = getLeftColumnOrdering(
      categorizedFields,
      defaultColumnSettings
    );

    return { leftItems, rightItems, itemsOrdering };
  }, [model, categorizedFields, t]);
};
