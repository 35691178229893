/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import { grayScale, colorsSecondary } from 'app/colors';
import { gutters } from 'app/spacing';
import { Row } from 'react-bootstrap';
import Cols from 'components/Layout/Cols';
import Card from 'components/Card/Card';
import KizenTypography from 'app/kizentypo';
import { fontSizes } from 'app/typography';
import {
  contentWidthCss,
  CONTENT_MAX_WIDTH,
} from 'components/Layout/PageContentWidth';
import TextareaAutosize from 'react-autosize-textarea';

export const Container = styled.div`
  ${contentWidthCss}
  background-color: unset;

  & > div {
    margin: ${gutters.spacing(4)}px auto;
    max-width: 100%;
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledRow = styled(Row)`
  margin-bottom: ${gutters.spacing(4, { baseline: true })}px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledCols = styled(Cols)`
  max-width: ${CONTENT_MAX_WIDTH};
  width: ${CONTENT_MAX_WIDTH};
  flex-wrap: nowrap;

  > div {
    margin-right: ${fontSizes.micro};
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  > div:last-of-type {
    margin-right: 0;
    margin-left: ${fontSizes.micro};
  }

  display: flex;
  flex-direction: row;
`;

export const StyledCard = styled(Card)`
  max-width: 50%;

  &:empty {
    padding: 0;
  }
`;

// override base styles
export const StyledTextArea = styled(TextareaAutosize)`
  font-family: 'Courier New', Monospace;
  border-radius: 4px !important;
  border-color: ${grayScale.medium} !important;
  margin-top: 0;
  width: 100%;
  padding: ${gutters.standard};
  font-size: ${fontSizes.text};
  line-height: 125%;
  color: rgb(74, 86, 96);
  outline: none;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${gutters.standard};

  > button {
    margin: 0 !important;
  }
`;

export const StyledFormError = styled(KizenTypography)`
  margin-bottom: -3px;
  text-align: center;
  color: ${colorsSecondary.red.dark};
`;

export const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  & li {
    position: relative;
    text-indent: ${gutters.spacing(3)}px;
    margin-left: ${gutters.spacing(2)}px;
  }

  & li p:before {
    content: '-';
    position: absolute;
    left: -10px;
  }
`;
