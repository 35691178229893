import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import Loader from 'components/Kizen/Loader';

const kdsCompatHeight = '616';
const kdsConfilguredModifier = '82'; // for the header and padding

const getCompatHeight = (configuredHeight) => {
  return `${
    configuredHeight
      ? configuredHeight - kdsConfilguredModifier
      : kdsCompatHeight
  }px`;
};

export const FixedHeightLoadingWrapper = styled.div`
${({ configuredHeight }) => css`
  height: ${getCompatHeight(configuredHeight)};
  max-height: ${getCompatHeight(configuredHeight)};
`}
  width: 100%'
`;

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${({ kdsCompatability, configuredHeight }) =>
    kdsCompatability ? getCompatHeight(configuredHeight) : '700px'};
  ${({ kdsCompatability, configuredHeight }) =>
    kdsCompatability
      ? css`
          height: ${getCompatHeight(configuredHeight)};
        `
      : ''}
  min-height: 50px;
  ${({ noPadding }) =>
    noPadding
      ? css`
          padding: 0;
        `
      : css`
          padding: 0 ${gutters.spacing(1, 3)}px 0 ${gutters.spacing(4)}px;
        `}

  & .scroll-parent {
    width: 100%;
  }

  & .scroll-parent > div {
    ${({ kdsCompatability }) =>
      kdsCompatability
        ? css`
            padding: 0 12px 15px 0;
          `
        : css`
            padding: 0 12px 27px 0;
          `}
  }

  & .scroll-parent > div:first-child {
    padding-top: 0;
  }

  & .scroll-parent > div:last-child {
    padding-bottom: 0;
  }
`;

export const StyledLoader = styled(Loader)`
  & {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  &:last-child {
    padding-top: 0 !important;
  }

  &:first-child {
    padding-top: 0 !important;
  }
`;

export const LoadPrevWrapper = styled.div`
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
  &&& {
    padding-top: 0;
  }

  &:before,
  &:after {
    background-color: ${grayScale.medium};
    content: '';
    display: flex;
    flex: 1;
    height: 1px;
  }
`;
