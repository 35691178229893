import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getDateWithTimezone } from 'app/timezone';
import { colorsSecondary } from 'app/colors';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import LongTextEllipsis from '../LongTextEllipsis';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import {
  formatTimestamp,
  defaultEmployeeString,
  defaultAutomationString,
} from '../helpers';
import { TeamMemberBlock } from '../components/TeamMemberBlock';
import { CardHeader } from '../components/CardHeader';
import { initiatorTypes } from '../../constants';
import { RoleBlock } from '../components/RoleBlock';
import { HtmlContainer } from '../FieldsTable';
import { AssociationsBlock } from '../components/AssosiationBlock';

const cardHeaderConfig = (t) => {
  const prefix = t('activity scheduled by');
  return {
    [initiatorTypes.employee]: {
      prefix,
      creationType: defaultEmployeeString,
    },
    [initiatorTypes.employee_api_request]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via API'),
    },
    [initiatorTypes.automation]: {
      prefix,
      creationType: defaultAutomationString,
    },
  };
};

const ActivityScheduledEventBlock = ({ event, business, ...others }) => {
  const { t } = useTranslation();

  const dueDate = useMemo(() => {
    const date = getDateWithTimezone(
      event.data.dueDatetime,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    return formatTimestamp(date);
  }, [event]);

  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsSecondary.aqua.dark}
          icon="clipboard-list"
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={event.data.activityName}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    >
      <TextEllipsisWithTooltip weight="bold">
        {t('Due Date (My Time)')}
      </TextEllipsisWithTooltip>
      <LongTextEllipsis data-qa-field="due-date">{dueDate}</LongTextEllipsis>
      <TextEllipsisWithTooltip weight="bold">
        {t('Assignee')}
      </TextEllipsisWithTooltip>
      {event.data.employee ? (
        <TeamMemberBlock
          data-qa-field="assignee"
          item={{ value: event.data.employee, type: 'selector' }}
        />
      ) : (
        <RoleBlock data-qa-field="assignee" item={{ value: event.data.role }} />
      )}
      {event.data?.associations ? (
        <>
          <TextEllipsisWithTooltip weight="bold">
            {t('Associations')}
          </TextEllipsisWithTooltip>
          <AssociationsBlock
            data-qa-field="associations"
            associations={event.data.associations}
          />
        </>
      ) : null}
      {event.data?.note ? (
        <>
          <TextEllipsisWithTooltip weight="bold">
            {t('Notes')}
          </TextEllipsisWithTooltip>
          <HtmlContainer
            data-qa-field="note"
            isExpandable
            dangerouslySetInnerHTML={{ __html: event.data.note }}
            className="note"
          />
        </>
      ) : null}
    </BasicBlock>
  );
};

export default ActivityScheduledEventBlock;
