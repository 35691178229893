import Button from 'components/Button';
import DashboardGrid from 'components/DashboardGrid';
import useDashboardGridChildren, {
  MODES,
} from 'components/DashboardGrid/hooks/useDashboardChildren';
import useDashboardInteraction from 'components/DashboardGrid/hooks/useDashboardInteraction';
import {
  DashboardGridContainerBox,
  DashboardGridPageContainer,
} from 'components/DashboardGrid/styles';
import SubNavigationBar from 'components/Kizen/SubNavigationBar';
import useModal from 'components/Modals/useModal';
import EditDashboardsButton from 'pages/Dashboard/components/EditDashboardsButton';
import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { StyledNav, StyledSwitch, SwitchContainer } from './styles';
import { StyledSubNavLabel } from 'pages/Dashboard/components/SubNav/styles';
import { ActiveStateModal } from './components/ActiveModal';
import BlockModal from './components/Block/modal';
import {
  createDashboardQuery,
  fetchDashboardsQuery,
} from 'components/AccessRequests/useDashboardList';
import { DASHBOARD } from 'queries/query-keys';
import { useMutation, useQuery } from 'react-query';
import { getBusinessClientObject } from 'store/authentication/selectors';
import {
  DEFAULT_LABEL_INDICATOR,
  getSortedDashboards,
} from 'components/DashboardGrid/utils';
import Loader from 'components/Kizen/Loader';
import useDashboardConfig from 'pages/Dashboard/hooks/useDashboardConfig';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import CreateLayoutModal from 'components/DashboardGrid/CreateLayoutModal';
import { useHomepageOptimisticUpdate } from './hooks/useHomepageOptimisticUpdate';
import {
  DEFAULT_NAME_KEY,
  calculateNewDashletPosition,
} from 'pages/Dashboard/util';
import { IntermediateDashlet } from './components/IntermediateDashlet';
import {
  getDashletConstraints,
  getDashletDimensions,
} from 'components/DashboardGrid/dimensions';
import {
  getActivityIds,
  getChartType,
  getFeExtraInfo,
  getLeadSources,
  getMetricType,
  getObjectId,
  getObjectIds,
  getStages,
} from 'pages/Dashboard';
import {
  AREA_RESPONSES,
  INVERSE_MAPPING,
} from 'components/Wizards/Dashlet/types';
import { v4 as uuidv4 } from 'uuid';
import { getHTMLConfig } from './hooks/useHTMLConfig';
import { useSelector } from 'react-redux';
import { useHomepageAdminNotice } from './hooks/useHomepageAdminNotice';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { DASHBOARD_BREAKPOINT_NAMES } from 'components/DashboardGrid/types';
import { breakpoints, useBreakpoint } from 'app/spacing';
import { DeleteConfirmationModal } from './components/DeleteConfirmationModal';
import { DashboardStateWrapper } from '../Dashboard/context/persistentState';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  DashboardErrorCodeText,
  DashboardWarningText,
} from 'pages/Dashboard/styles';
import { AREA_RESPONSES as CHART_AREA_RESPONSES } from 'components/Wizards/RTDV/types';
import { DataManagerWrapper } from 'pages/Dashboard/context/dataManager/context';
import { getLayoutRowsFromHeight } from './utils';
import getDashboardUrl from 'utility/getDashboardUrl';
import { deferExecution } from 'utility/defer';
import { HomepageLogActivityContext } from './HomepageLogActivityContext';

const responseValues = [
  AREA_RESPONSES.PIPELINES,
  AREA_RESPONSES.ACTIVITIES,
  AREA_RESPONSES.EMAILS,
  AREA_RESPONSES.MARKETING,
];
const chartTypes = Object.values(CHART_AREA_RESPONSES);

const getDynamicBlockDimensions = (builderData) => {
  const { htmlBlockConfig } = getHTMLConfig({
    config: {
      content: builderData,
    },
  });

  if (!htmlBlockConfig) {
    return {};
  }

  const { height, columns } = htmlBlockConfig;
  const gridColumns = columns * 2;

  return {
    ...getDashletConstraints({ area: 'static_content' }),
    w: gridColumns,
    h: getLayoutRowsFromHeight(height),
  };
};

const HomepagesPage = () => {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const history = useHistory();
  const access = useSelector((s) => s.authentication.access);
  const clientObject = useSelector(getBusinessClientObject);
  const isAdmin = Boolean(
    access?.sections?.homepages_section?.customize_homepages
  );

  const clientObjectId = clientObject?.id;
  const intermediateDeleteState = useRef({});

  const mobile = useBreakpoint(breakpoints.md);
  const showSettings = isAdmin;

  const handleFetchDashboards = useCallback(() => {
    return fetchDashboardsQuery('', false, undefined, 'homepage');
  }, []);

  const dashboardsQueryKey = [...DASHBOARD.DASHBOARDS, 'homepage', false];

  const {
    isLoading: myDashboardsLoading,
    data: homepages = [],
    // isRefetching: dashboardsRefetching,
  } = useQuery(dashboardsQueryKey, handleFetchDashboards, {
    keepPreviousData: true,
  });

  const homepageOptions = useMemo(() => {
    return getSortedDashboards(homepages.filter((h) => !h.hidden));
  }, [homepages]);

  const hasDashboards = homepageOptions.length > 0;

  const {
    loading: configLoading,
    currentDashboard: currentHomepage,
    refetchDashboard,
    dashboardQueryKey,
    currentDashboardPermission: currentHomepagePermission,
    afterCreate,
    setPersistentDashboardState,
    persistentDashboardState,
    dashboardError,
    unsafeDashboardId,
    ranges,
    todayDateInBusinessTimeZone,
    setCurrentDashboardId,
  } = useDashboardConfig(homepageOptions, false, 'homepage', isAdmin, true);

  useSetTitleOnLoad(
    currentHomepage
      ? `${currentHomepage.name} - ${t('Homepage')}`
      : t('Homepage')
  );

  const isLoading = configLoading || myDashboardsLoading;

  const clearToasts = useHomepageAdminNotice(isAdmin && !mobile, isLoading);

  useEffect(() => {
    const unregisterListener = history.listen(({ pathname }) => {
      if (
        !pathname.startsWith('/home') ||
        pathname.startsWith('/home/settings')
      ) {
        clearToasts();
      }
    }, []);

    return unregisterListener;
  });

  const {
    breakpoint,
    setBreakpoint,
    editable,
    rowHeight,
    setContainerWidth,
    dashlets,
    hasEditPermission,
    resizeHandles,
    updateLayouts,
    forceShowUIElements,
    mutateDashlet: mutateChartGroupTypeDashlet,
    duplicateDashlet,
    createDashletMutation,
    updateDashletMutation,
    deleteDashlet,
    draggable,
    columnCount,
  } = useDashboardInteraction({
    currentPermission: currentHomepagePermission,
    refetchDashboard,
    entityName: t('Block'),
    dashboardQueryKey,
    currentDashboard: currentHomepage,
    isLoading,
  });

  const [deleteBlockModalProps, deleteBlockModalTriggerProps] = useModal({
    handleSubmit: () => {
      deleteDashlet(
        intermediateDeleteState.current.dashboardId,
        intermediateDeleteState.current.dashletId
      );
      intermediateDeleteState.current = {};
    },
  });

  const handlePromptForDeleteDashlet = useCallback(
    (dashboardId, dashletId) => {
      intermediateDeleteState.current = {
        dashboardId,
        dashletId,
      };
      deleteBlockModalTriggerProps.onClick();
    },
    [deleteBlockModalTriggerProps]
  );

  const getDashletPosition = useCallback(
    (dimensions) => {
      return calculateNewDashletPosition(
        { w: dimensions.w, h: dimensions.h },
        currentHomepage.dashlets ?? [],
        true
      );
    },
    [currentHomepage]
  );

  // TODO (keegandonley) - there are currently two implementations of this function in different places - they
  // need to be kept in sync. Once we get these features settled the goal is to go back and unify them.
  const mutateDashletForDashboardOnly = useCallback(
    async ({
      dashboardId,
      dashletData,
      isUpdate = false,
      layoutUpdate = false,
      skipOptimisticUpdate = false,
      isDuplicate = false,
    }) => {
      const oldDashlet = dashlets.find((d) => d.id === dashletData.id);
      const dimensions = getDashletDimensions(dashletData);
      const position = getDashletPosition(dimensions);
      const hasFilters =
        Boolean(dashletData.customFilters) ||
        Boolean(dashletData.inGroupFilters) ||
        Boolean(dashletData.notInGroupFilters);
      const payload = {
        config: {
          entity_type: dashletData.area,
          report_type: dashletData.reportType?.value,
          object_id: getObjectId(dashletData),
          object_ids: getObjectIds(dashletData),
          lead_sources: getLeadSources(dashletData),
          metric_type: getMetricType(dashletData),
          chart_type: getChartType(dashletData),
          historical: dashletData.compare ?? false,
          inverse: INVERSE_MAPPING[dashletData.reportType?.value],
          records_to_include: dashletData?.includedRecords?.value,
          percentage_change_over_time: dashletData?.displayPercent,
          frequency: dashletData.datapointFrequency?.value,
          pipeline_level_of_detail: dashletData.levelOfDetail?.value,
          stages: getStages(dashletData),
          activity_ids: getActivityIds(dashletData),
          fe_extra_info: getFeExtraInfo(dashletData),
          filters: hasFilters
            ? {
                custom_filters: dashletData.customFilters,
                in_group_ids: dashletData.inGroupFilters,
                not_in_group_ids: dashletData.notInGroupFilters,
                custom_object_id:
                  dashletData.customObjectId ??
                  dashletData.selectedFilterObject?.value ??
                  dashletData.selectedFilterObject?.id,
              }
            : undefined,
        },
        layout: {
          i: uuidv4(),
          ...dimensions,
          ...position,
        },
        name: dashletData.name || DEFAULT_NAME_KEY,
      };
      if (isUpdate && oldDashlet) {
        const updatePayload = {
          dashletId: dashletData.id,
          dashboardId,
          payload: {
            config: payload.config,
            name: payload.name || DEFAULT_NAME_KEY,
            layout: {
              i: oldDashlet.layout.i,
              h: oldDashlet.layout.h,
              w: oldDashlet.layout.w,
              x: oldDashlet.layout.x,
              y: oldDashlet.layout.y,
            },
          },
        };
        const { minW, minH, maxW, maxH } = dimensions;
        const { h: oldHeight, w: oldWidth } = oldDashlet.layout;
        if (minW && oldWidth < minW) {
          updatePayload.payload.layout.w = minW;
        }
        if (maxW && oldWidth > maxW) {
          updatePayload.payload.layout.w = maxW;
        }
        if (minH && oldHeight < minH) {
          updatePayload.payload.layout.h = minH;
        }
        if (maxH && oldHeight > maxH) {
          updatePayload.payload.layout.h = maxH;
        }
        updatePayload.layoutUpdate = layoutUpdate;
        updatePayload.skipOptimisticUpdate = skipOptimisticUpdate;

        await updateDashletMutation.mutateAsync(updatePayload);
      } else {
        if (isDuplicate) {
          await duplicateDashlet({
            payload,
            oldDashlet,
            dashlets,
            dashboardId,
          });
        } else {
          await createDashletMutation.mutateAsync({ dashboardId, payload });
        }
      }
    },
    [
      createDashletMutation,
      updateDashletMutation,
      getDashletPosition,
      dashlets,
      duplicateDashlet,
    ]
  );

  const mutateDynamicBlock = useCallback(
    async (dashboardId, builderData, dashletId, isDuplicate = false) => {
      const dimensions = getDynamicBlockDimensions(builderData);
      const oldDashlet = dashlets.find((d) => d.id === dashletId);
      const position = getDashletPosition(dimensions);
      const payload = {
        config: {
          entity_type: 'static_content',
          report_type: 'html',
          chart_type: 'html',
          content: builderData,
        },
        name: DEFAULT_LABEL_INDICATOR,
        layout: {
          i: uuidv4(),
          ...dimensions,
          ...position,
        },
      };

      if (Boolean(dashletId) && !isDuplicate) {
        const dimensions = getDynamicBlockDimensions(builderData);
        if (!oldDashlet) {
          return;
        }

        await updateDashletMutation.mutateAsync({
          dashboardId,
          dashletId,
          payload: {
            config: payload.config,
            layout: {
              i: uuidv4(),
              ...dimensions,
              x: oldDashlet.layout.x,
              y: oldDashlet.layout.y,
            },
          },
        });
      } else {
        if (isDuplicate) {
          await duplicateDashlet({
            payload,
            oldDashlet,
            dashlets,
            dashboardId,
          });
        } else {
          await createDashletMutation.mutateAsync({
            dashboardId,
            payload,
          });
        }
      }
    },
    [
      createDashletMutation,
      getDashletPosition,
      updateDashletMutation,
      duplicateDashlet,
      dashlets,
    ]
  );

  const [addDashletModalProps, addDashletModalTriggerProps] = useModal({
    handleSubmit: (chart, builderData) => {
      // We need to remove the background, height, and shadow from the builder configuration
      // because that needs to instead be controlled by the dashlet container. As far
      // as the builder is concerned, the background is always transparent and there's no shadow
      if (!chart) {
        mutateDynamicBlock(currentHomepage.id, builderData);
      } else if (responseValues.includes(chart.area)) {
        mutateDashletForDashboardOnly({
          dashboardId: currentHomepage.id,
          dashletData: chart,
          isUpdate: false,
        });
      } else {
        mutateChartGroupTypeDashlet({
          dashletData: chart,
          isUpdate: false,
          customObject: chart.fieldObject?.id,
        });
      }
    },
  });

  const [dynamicHeights, setDynamicHeights] = useState({});

  const handleSetDynamicHeight = useCallback(
    (height, dashletId) => {
      if (!dynamicHeights[dashletId] || dynamicHeights[dashletId] !== height) {
        setDynamicHeights((prev) => ({
          ...prev,
          [dashletId]: height,
        }));
      }
    },
    [dynamicHeights]
  );

  const rowHeightOverrides = useMemo(() => {
    if (
      [
        DASHBOARD_BREAKPOINT_NAMES.SM,
        DASHBOARD_BREAKPOINT_NAMES.MD,
        DASHBOARD_BREAKPOINT_NAMES.XS,
        DASHBOARD_BREAKPOINT_NAMES.XXS,
      ].includes(breakpoint)
    ) {
      return Object.keys(dynamicHeights).reduce((acc, dashletId) => {
        // We need to account for the margin when calculating heights
        const margin = 10;
        const newHeight =
          (dynamicHeights[dashletId] + margin) / (rowHeight + margin);
        return {
          ...acc,
          [dashletId]: Math.ceil(newHeight),
        };
      }, {});
    }

    return {};
  }, [dynamicHeights, breakpoint, rowHeight]);

  const renderChild = useCallback(
    ({ dashlet, errorPadding, index }) => {
      return (
        <IntermediateDashlet
          setContentHeight={(height) =>
            handleSetDynamicHeight(height, dashlet.id)
          }
          errorPadding={errorPadding}
          deleteDashlet={handlePromptForDeleteDashlet}
          currentHomepage={currentHomepage}
          dashlet={dashlet}
          isDashletUpdating={updateDashletMutation.isLoading}
          onDeleteDashlet={handlePromptForDeleteDashlet}
          isDraggable={draggable && hasEditPermission && isAdmin}
          breakpoint={breakpoint}
          editDashlet={(newDashlet, builderData, isTableColumnUpdate) => {
            if (builderData && typeof builderData === 'object' && !newDashlet) {
              return mutateDynamicBlock(
                currentHomepage.id,
                builderData,
                dashlet.id
              );
            }

            if (chartTypes.includes(newDashlet.reportType.value)) {
              return mutateChartGroupTypeDashlet({
                dashletData: { ...newDashlet, id: dashlet.id },
                isUpdate: true,
                customObject: newDashlet.homepageSelectedObjectId,
                skipOptimisticUpdate: !isTableColumnUpdate,
                layoutUpdate: isTableColumnUpdate,
              });
            }

            if (responseValues.includes(newDashlet.area)) {
              return mutateDashletForDashboardOnly({
                dashboardId: currentHomepage.id,
                dashletData: {
                  ...newDashlet,
                  id: dashlet.id,
                },
                isUpdate: true,
                layoutUpdate: isTableColumnUpdate,
                skipOptimisticUpdate: false,
              });
            }
          }}
          duplicateDashlet={(newDashlet, builderData) => {
            if (builderData && typeof builderData === 'object') {
              return mutateDynamicBlock(
                currentHomepage.id,
                dashlet.config.content,
                dashlet.id,
                true
              );
            }
            //
            if (chartTypes.includes(newDashlet.reportType.value)) {
              return mutateChartGroupTypeDashlet({
                dashletData: { ...newDashlet, id: dashlet.id },
                isUpdate: false,
                customObject: dashlet.customObject,
                skipOptimisticUpdate: true,
                isDuplicate: true,
              });
            }

            if (responseValues.includes(newDashlet.area)) {
              return mutateDashletForDashboardOnly({
                dashboardId: currentHomepage.id,
                dashletData: {
                  ...newDashlet,
                  id: dashlet.id,
                },
                isUpdate: false,
                isDuplicate: true,
              });
            }
          }}
          mobile={mobile}
          canEdit={hasEditPermission && isAdmin}
          index={index}
          ranges={ranges}
          todayDateInBusinessTimeZone={todayDateInBusinessTimeZone}
        />
      );
    },
    [
      breakpoint,
      currentHomepage,
      mobile,
      mutateChartGroupTypeDashlet,
      handlePromptForDeleteDashlet,
      draggable,
      hasEditPermission,
      mutateDynamicBlock,
      mutateDashletForDashboardOnly,
      isAdmin,
      handleSetDynamicHeight,
      ranges,
      todayDateInBusinessTimeZone,
      updateDashletMutation.isLoading,
    ]
  );

  const children = useDashboardGridChildren({
    renderChild,
    forceShowUIElements,
    dashlets,
    hasEditPermission: hasEditPermission && isAdmin,
    breakpoint,
    addDashletModalTriggerProps,
    mobile,
    dateFilter: {}, // no global date filters for charts
    mode: MODES.HOMEPAGES,
    rowHeightOverrides,
    columnCount,
  });

  const handleClickEditHomepages = useCallback(() => {
    history.push('/home/settings');
  }, [history]);

  const createDashboardMutation = useMutation(
    (result) => createDashboardQuery(result, undefined, 'homepage'),
    {
      onMutate: () => {
        setErrors({});
      },
      onSuccess: (data) => {
        afterCreate(data.id);
        handleHideModal();
        deferExecution(() => {
          history.push(`/home/${data.id}`);
        });
      },
      onError: (error) => {
        setErrors(error?.response?.data?.errors);
      },
    }
  );

  const handleCreateDashboard = useCallback(
    ({ name, permissions }) => {
      createDashboardMutation.mutate({
        name,
        ...buildSharingSettingsForAPI(permissions),
      });
    },
    [createDashboardMutation]
  );

  const [modalProps, , { show: handleShowModal, hide: handleHideModal }] =
    useModal({
      closeOnSubmit: false,
      handleSubmit: handleCreateDashboard,
    });

  const handleClickAddDashboard = useCallback(() => {
    handleShowModal();
  }, [handleShowModal]);

  const updateDashboardMutation = useHomepageOptimisticUpdate({
    includeSharing: false,
    debouncedTerm: '',
  });

  const [activeStateModalProps, activeStateModalTriggerProps] = useModal({
    handleSubmit: () => {
      updateDashboardMutation.mutate({
        id: currentHomepage.id,
        payload: {
          published: !currentHomepage.published,
        },
      });
    },
  });

  const missingPermission = useMemo(() => {
    if (isLoading) {
      return false;
    }

    if (
      dashboardError?.response?.status === 403 &&
      Boolean(unsafeDashboardId)
    ) {
      return true;
    }

    if (
      !currentHomepage &&
      !isAdmin &&
      !dashboardError &&
      Boolean(unsafeDashboardId)
    ) {
      return true;
    }

    return false;
  }, [isLoading, dashboardError, unsafeDashboardId, currentHomepage, isAdmin]);

  const doesNotExist = useMemo(() => {
    if (isLoading) {
      return false;
    }

    // This is a bit of a hack to get around the issue when the backend
    // rejects the request with a 302 because of a malformed UUID
    if (dashboardError && !dashboardError.response) {
      return Boolean(unsafeDashboardId);
    }

    return (
      dashboardError?.response?.status === 404 && Boolean(unsafeDashboardId)
    );
  }, [isLoading, dashboardError, unsafeDashboardId]);

  if (isLoading) {
    return <Loader loading />;
  }

  const missing = !currentHomepage;
  const unavailable = missingPermission || doesNotExist;

  if (currentHomepage === null && !isLoading && hasDashboards) {
    const fallbackDashboardId = homepageOptions?.filter((d) => !d.hidden)?.[0]
      ?.id;
    if (fallbackDashboardId && fallbackDashboardId !== unsafeDashboardId) {
      setCurrentDashboardId(fallbackDashboardId);
      return <Redirect to={`/home/${fallbackDashboardId}`} />;
    }
  }

  if (
    currentHomepage?.type === 'generic_dashboard' ||
    currentHomepage?.type === 'chart_group'
  ) {
    const fallbackDashboardId = homepageOptions[0]?.id;
    setCurrentDashboardId(fallbackDashboardId);
    return (
      <Redirect
        to={getDashboardUrl(
          currentHomepage,
          currentHomepage?.customObject,
          clientObjectId
        )}
      />
    );
  }

  return (
    <HomepageLogActivityContext>
      <DashboardStateWrapper
        setPersistentDashboardState={setPersistentDashboardState}
        persistentDashboardState={persistentDashboardState}
      >
        <DashboardGridPageContainer isMobile={mobile}>
          {(homepageOptions.length > 1 || isAdmin) && mobile ? (
            <StyledSubNavLabel
              options={homepageOptions}
              value={
                missing || unavailable
                  ? {
                      name: unavailable ? t('Unavailable') : t('None'),
                    }
                  : currentHomepage
              }
              onChange={(id) => history.push(`/home/${id}`)}
              classNamePrefix="homepage-menu"
              buildHref={(id) => `/home/${id}`}
              entitySingular={t('Homepage')}
              entityPlural={t('Homepages')}
              mobile
            />
          ) : null}
          {(homepageOptions.length > 1 ||
            isAdmin ||
            (unavailable && homepageOptions.length > 0)) &&
          !mobile ? (
            <SubNavigationBar>
              {homepageOptions.length > 1 ||
              (unavailable && homepageOptions.length > 0) ||
              isAdmin ? (
                <StyledSubNavLabel
                  options={homepageOptions}
                  value={
                    missing || unavailable
                      ? {
                          name: unavailable ? t('Unavailable') : t('None'),
                        }
                      : currentHomepage
                  }
                  onChange={(id) => history.push(`/home/${id}`)}
                  handleClickEditDashboards={handleClickEditHomepages}
                  handleClickAddDashboard={handleClickAddDashboard}
                  components={
                    showSettings
                      ? {
                          Menu: EditDashboardsButton,
                        }
                      : undefined
                  }
                  classNamePrefix="homepage-menu"
                  buildHref={(id) => `/home/${id}`}
                  entitySingular={t('Homepage')}
                  entityPlural={t('Homepages')}
                />
              ) : null}

              {isAdmin && (
                <StyledNav
                  data-qa="homepage-nav"
                  data-qa-settings-visible={showSettings}
                  data-qa-add-block-visible={
                    currentHomepage && hasEditPermission && isAdmin
                  }
                >
                  {currentHomepage ? (
                    <SwitchContainer>
                      <StyledSwitch
                        checked={currentHomepage?.published}
                        onClick={activeStateModalTriggerProps.onClick}
                        label={t('Active?')}
                        textPlacement="left"
                      />
                    </SwitchContainer>
                  ) : null}
                  <Button color="blue" onClick={handleClickEditHomepages}>
                    {t('Manage Homepages')}
                  </Button>
                  {currentHomepage && hasEditPermission && isAdmin ? (
                    <Button {...addDashletModalTriggerProps}>
                      {t('Add Block')}
                    </Button>
                  ) : null}
                </StyledNav>
              )}
            </SubNavigationBar>
          ) : null}
          <DashboardGridContainerBox>
            <Container>
              {!currentHomepage && (
                <>
                  {missingPermission ? (
                    <ErrorMessage
                      title={t('Permissions Needed')}
                      text={
                        <DashboardWarningText as="p">
                          {t(
                            'The page you are trying to view requires special permissions or is no longer active. If you believe you should have permissions to view, please contact your Business Administrator.'
                          )}
                        </DashboardWarningText>
                      }
                      code={
                        <DashboardErrorCodeText as="p">
                          {t('Error code')}: 403
                        </DashboardErrorCodeText>
                      }
                      dashboard
                    />
                  ) : doesNotExist ? (
                    <ErrorMessage
                      title={t('This Page No Longer Exists')}
                      text={
                        <DashboardWarningText as="p">
                          {t(
                            'The page you are trying to reach no longer exists.'
                          )}
                          {isAdmin
                            ? ` ${t(
                                'Please select another homepage to view and edit or use the button below to create a new homepage.'
                              )}`
                            : ''}
                          {homepageOptions.length > 0 && !isAdmin
                            ? ` ${t(
                                'Please select another homepage from the dropdown on the top left.'
                              )}`
                            : ''}
                        </DashboardWarningText>
                      }
                      dashboard
                      button={
                        isAdmin ? (
                          <Button
                            color="green"
                            onClick={handleClickAddDashboard}
                          >
                            {t('Add Homepage')}
                          </Button>
                        ) : null
                      }
                    />
                  ) : !hasDashboards ? (
                    <ErrorMessage
                      title={t('No Homepages Available')}
                      text={
                        isAdmin ? (
                          <DashboardWarningText as="p">
                            {t(
                              'There are currently no homepages created for this business. To get started, please click the Homepage Settings button at the top right of the screen or the Add Homepage button below.'
                            )}
                          </DashboardWarningText>
                        ) : (
                          <DashboardWarningText as="p">
                            {t(
                              "There are currently no homepages created for this business. Please contact your administrator if you believe you're seeing this page in error."
                            )}
                          </DashboardWarningText>
                        )
                      }
                      button={
                        isAdmin ? (
                          <Button
                            color="green"
                            onClick={handleClickAddDashboard}
                          >
                            {t('Add Homepage')}
                          </Button>
                        ) : null
                      }
                      dashboard
                    />
                  ) : null}
                </>
              )}
              <DashboardGrid
                updateLayouts={updateLayouts}
                resizeHandles={isAdmin ? resizeHandles : []}
                rowHeight={rowHeight}
                editable={editable && isAdmin}
                setBreakpoint={setBreakpoint}
                setContainerWidth={setContainerWidth}
              >
                {children}
              </DashboardGrid>
            </Container>
          </DashboardGridContainerBox>
        </DashboardGridPageContainer>
        <BlockModal {...addDashletModalProps} />
        <ActiveStateModal
          active={currentHomepage?.published}
          {...activeStateModalProps}
        />
        <DeleteConfirmationModal {...deleteBlockModalProps} />
        <CreateLayoutModal
          {...modalProps}
          canUserEditPermissions
          showPrivateToggle={false}
          entity={t('Homepage')}
          small
          errors={errors}
          blockHideIfDirty
        />
      </DashboardStateWrapper>
    </HomepageLogActivityContext>
  );
};

const HomepagesPageWithDataManager = (props) => {
  const { id } = useParams();

  if (!id) {
    return <HomepagesPage {...props} />;
  }

  return (
    <DataManagerWrapper key={id} instanceId={id}>
      <HomepagesPage {...props} id={id} />
    </DataManagerWrapper>
  );
};

export default HomepagesPageWithDataManager;
