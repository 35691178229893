import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';

export const useApiFilterErrors = (
  errorsDictionary: Record<string, boolean[]>,
  timestamp: string | null,
  duration = 3000
): [
  Record<string, boolean[]>,
  Dispatch<SetStateAction<Record<string, boolean[]>>>,
] => {
  const [errors, setErrors] =
    useState<Record<string, boolean[]>>(errorsDictionary);
  const timestampRef = useRef(timestamp);
  if (timestampRef.current !== timestamp) {
    timestampRef.current = timestamp;
    setErrors(errorsDictionary);
  }
  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;
    if (Object.keys(errors).length) {
      id = setTimeout(() => setErrors({}), duration);
    }
    return () => clearTimeout(id);
  }, [errors, duration]);

  return [errors, setErrors];
};
