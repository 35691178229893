import styled from '@emotion/styled';
import { grayScale, colorsButton } from 'app/colors';
import { css } from '@emotion/core';

import { layers } from 'app/spacing';
import DraggableItem from 'components/Kizen/DraggableItem';
import { itemVerticalMargin } from '../Dropzone';

export const StyledDraggableItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: ${itemVerticalMargin}px 0;

  ${({ bringToTop, forceToTop }) =>
    (bringToTop || forceToTop) &&
    css`
      z-index: ${layers.content(0, 1)};
    `}
`;

export const StyledDraggableItem = styled(DraggableItem)`
  flex-grow: 1;
  flex-wrap: nowrap;
  min-width: 0;
  ${({ compact }) =>
    compact
      ? css`
          padding: 7px 10px 6px 10px;
        `
      : ''}

  background: ${grayScale.white};
  ${({ redBorder }) =>
    redBorder
      ? css`
          border: 1px solid ${colorsButton.red.hover};
        `
      : ''}

  && > i + * {
    // fix content height
    max-height: 20px;

    input {
      padding-top: 0;
      padding-left: 0;
      position: relative;
      top: -1px;
    }
  }

  && > i {
    cursor: grab;
  }

  &&:hover > i svg {
    color: ${grayScale.mediumDark};
  }

  && > i:hover svg {
    color: ${grayScale.dark};
  }

  ${({ darken }) =>
    darken
      ? css`
          background-color: ${grayScale.light};
        `
      : null}

  ${({ disabled }) =>
    disabled
      ? css`
          && > i.ToolbarDragHandle,
          &&:hover > i.ToolbarDragHandle,
          && > i.ToolbarDragHandle:hover {
            cursor: default;
            svg {
              color: ${grayScale.medium} !important;
            }
          }
        `
      : null}
  ${({ isHidden }) =>
    isHidden &&
    css`
      background-color: ${grayScale.light};

      && > i,
      &&:hover > i,
      && > i:hover {
        cursor: default;
        svg {
          color: ${grayScale.medium};
        }
      }
    `}
`;
