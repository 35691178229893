import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { KizenTypography, fontSizes } from 'app/typography';
import { colorsButton, grayScale, shadowLight } from 'app/colors';
import { borderRadii } from 'app/spacing';
import BaseSelect from 'components/Inputs/Select';
import {
  PANEL_HEIGHT,
  STEP_CARD_MAX_HEIGHT,
  STEP_CARD_WIDTH,
} from './constants';

const WrapperCSS = ({ borderColor }) => css`
  width: ${STEP_CARD_WIDTH}px;
  max-height: ${STEP_CARD_MAX_HEIGHT}px;
  ${shadowLight};
  border-radius: ${borderRadii.small};
  overflow: hidden;
  background: ${grayScale.white};
  ${borderColor
    ? css`
        border: solid 1px ${borderColor};
      `
    : ''}
`;

export const Wrapper = styled.div`
  ${WrapperCSS}
`;

export const AnchorAsWrapper = styled.a`
  ${WrapperCSS}
  display: block;
  cursor: pointer;
  span.button-text {
    color: ${colorsButton.blue.default};
  }
  &:hover {
    span.button-text {
      color: ${colorsButton.blue.hover};
    }
  }
`;

export const ColorBar = styled.div`
  height: 2px;
  background: ${({ color }) => color};
`;

export const Header = styled.div`
  padding: 7px 10px;
  display: flex;
  align-items: center;
  h4 {
    flex: 1;
    line-height: 1.2; // Wrestling against styles from the date picker, only appears in build
  }
`;

export const Body = styled.div`
  padding: 0 10px;
  margin-bottom: 7px;
  max-height: calc(2 * ${fontSizes.text}); // 2 lines, fallback from line clamp
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const PannelWrapper = styled.div`
  padding: 5px 10px;
  border-top: 1px solid ${grayScale.mediumLight};
  min-height: ${PANEL_HEIGHT}px;
  max-height: calc(3 * ${PANEL_HEIGHT}px); // 3 lines, fallback from line clamp
`;

export const StyledSelect = styled(BaseSelect)`
  && {
    width: auto;
  }
`;

export const ThreeDotsMenu = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 5px;
`;

export const StyledStepId = styled(KizenTypography)`
  flex-shrink: 0;
  margin: 0 5px;
`;
