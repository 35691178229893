import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import BasicModal from 'components/Modals/presets/BasicModal';
import useFlashState from 'hooks/useFlashState';

const StyledBasicModal = styled(BasicModal)`
  .modal-body {
    overflow: unset;
  }
`;

const Message = styled(KizenTypography)`
  text-align: center;
  margin-bottom: 20px;
`;

export const ChooseNewPageTargetModal = ({
  message,
  pageOptions,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [pageId, setPageId] = useState(null);
  const [error, setError] = useFlashState(1500, null);

  const handleConfirm = () => {
    setError(null);
    if (!pageId) {
      setError({
        showMessage: true,
        isModal: true,
        message: t('This field is required.'),
      });
    } else {
      onConfirm(pageId);
    }
  };

  return (
    <StyledBasicModal
      show
      heading={t('Please Confirm Page Hiding')}
      buttonText={t('Confirm')}
      onHide={onCancel}
      onConfirm={handleConfirm}
    >
      <Message>{message}</Message>
      <Select
        inModal
        value={pageId}
        label={t('New Go To Target Page')}
        onChange={setPageId}
        options={pageOptions}
        validate={error}
      />
    </StyledBasicModal>
  );
};
