import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import {
  StyledUnderlinedLabelWrapper as StyledLabelWrapper,
  StyledUnderlinedLabel as StyledLabel,
  StyledUnderlinedTextareaWrapper as StyledTextareaWrapper,
  StyledUnderlinedTextarea as StyledTextarea,
} from './styles';
import { useTruncationTooltip } from '../Tooltip';

const UnderlinedTextarea = ({
  error,
  className,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  underline,
  id,
  rows,
  resize,
  InputLabelProps,
}) => {
  const hasValue = (value) =>
    value != null && !(Array.isArray(value) && value.length === 0);
  const [focused, setFocused] = useState(false);
  const [tooltipProps, tooltip] = useTruncationTooltip();
  const isFilled = hasValue(value) && value !== '';
  return (
    <div className={className}>
      <StyledLabelWrapper htmlFor={id}>
        {label && (
          <StyledLabel
            shrink={focused || isFilled}
            {...InputLabelProps}
            {...tooltipProps}
            disabled={disabled}
          >
            {label}
          </StyledLabel>
        )}
        <StyledTextareaWrapper
          focused={focused}
          error={error}
          underline={underline}
          disabled={disabled}
        >
          <StyledTextarea
            value={value}
            placeholder={placeholder}
            id={id}
            onChange={(event) => onChange(event.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={disabled}
            rows={rows}
            resize={resize}
          />
        </StyledTextareaWrapper>
      </StyledLabelWrapper>
      {tooltip}
    </div>
  );
};

UnderlinedTextarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  underline: PropTypes.bool,
  rows: PropTypes.string,
  resize: PropTypes.oneOf(['none', 'vertical']),
  InputLabelProps: PropTypes.object,
};

UnderlinedTextarea.defaultProps = {
  placeholder: '',
  id: '',
  className: '',
  error: false,
  disabled: false,
  underline: false,
  rows: '3',
  resize: 'none',
  InputLabelProps: null,
};

export default UnderlinedTextarea;
