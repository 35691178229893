import {
  GeneralSettings,
  StageSettings,
  CustomLayout,
  Permissions,
} from './steps/index';
import CustomizeFields from './steps/CustomizeFields';
import RelatedObjects from './steps/RelatedObjects';
import { KEYS } from 'pages/Settings/pages/Team/PermissionGroupModal_V2/query';
import {
  CUSTOMIZE_FIELDS_STEP_KEY,
  PERMISSIONS_STEP_KEY,
  RELATED_OBJECTS_STEP_KEY,
  CUSTOMIZE_LAYOUT_STEP_KEY,
  GENERAL_STEP_KEY,
} from './constants';

/**
 * common steps for custom object and workflow wizards
 *
 * @typedef {Object} Step
 * @property {number} idx - index of the step
 * @property {string} key - key of the step
 * @property {function} label - function that returns the label of the step
 * @property {function} component - component of the step
 * @property {boolean} workflowOnly - if true, the step is only available for workflows
 * @property {string} nextButtonTitle - title of the next button
 * @property {boolean} handleBeforeNavigation - async function - called before navigating to the step
 *
 */
const steps = [
  {
    idx: 0,
    key: GENERAL_STEP_KEY,
    label: (t) => t('General Settings'),
    component: GeneralSettings,
  },
  {
    idx: 1,
    key: 'stageSettings',
    label: (t) => t('Stage Settings'),
    component: StageSettings,
    workflowOnly: true,
  },
  {
    idx: 2,
    key: RELATED_OBJECTS_STEP_KEY,
    label: (t) => t('Related Objects'),
    component: RelatedObjects,
  },
  {
    idx: 3,
    key: CUSTOMIZE_FIELDS_STEP_KEY,
    label: (t) => t('Customize Fields'),
    component: CustomizeFields,
    nextButtonTitle: (t) => t('Next Step'),
  },
  {
    idx: 4,
    key: CUSTOMIZE_LAYOUT_STEP_KEY,
    label: (t) => t('Customize Layout'),
    component: CustomLayout,
    nextButtonTitle: (t) => t('Next Step'),
  },
  {
    idx: 5,
    key: PERMISSIONS_STEP_KEY,
    label: (t) => t('Permissions'),
    component: Permissions,
    nextButtonTitle: (t) => t('Finalize Object'),
    hideOnClick: true,
    handleBeforeNavigation: async ({ queryClient }) => {
      // Invalidate the cache for the permissions objects query
      await queryClient.refetchQueries([KEYS.OBJECTS]);
    },
  },
];
export const getStepByKey = (key) => {
  return steps.find((step) => step.key === key);
};
export default steps;
