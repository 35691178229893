// Currently, this only needs to support storing one item
// at a time
import { monitoringMessageHelper } from 'sentry/helpers';

class EventQueue {
  constructor(timeout) {
    this.timeout = timeout;
    this.q = [];
  }

  replace(cb, id) {
    this.maybeClearTimer();

    if (id !== this.id) {
      this.execute();
    }
    this.id = id;
    this.q = [cb];
    this.timer = setTimeout(this.execute.bind(this), this.timeout);
  }

  push(cb) {
    this.q.push(cb);
  }

  maybeClearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  set id(value) {
    this._id = value;
  }

  get id() {
    return this._id;
  }

  execute() {
    if (this.q) {
      if (this.q.length > 1) {
        monitoringMessageHelper(
          '[EventQueue] - Queue may not function as expected with multiple entries'
        );
      }
      this.q.shift()?.();
    }
  }
}

export default EventQueue;
