import { useTranslation } from 'react-i18next';
import { useTextStyleValue } from '../../../WYSIWYG/useTextStyleValue';
import { useEditor } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import { ToggleButton, TextEditorToolbarSection } from '../../styles';
import { ToolbarColorPicker } from '../../components/ToolbarColorPicker';
import { FontColorIcon } from '../../../WYSIWYG/components/FontColorIcon';
import React from 'react';

/**
 * The ColorPicker's onChange handler do not focus the editor so they work with the floating
 * toolbar implementation. See toolbarActive state in FloatingRichTextEditor (./index.js)
 */
export const TextColorToolbarSection = ({ enableColor, enableHighlight }) => {
  const { t } = useTranslation();
  const [color, highlight] = useTextStyleValue(['color', 'highlight']);
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  if (!editor) return null;

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      {enableColor && (
        <ToggleButton title={t('Color')}>
          <ToolbarColorPicker
            icon={<FontColorIcon />}
            color={color}
            onChange={(rgba) => editor.chain().setColor(rgba).run()}
          />
        </ToggleButton>
      )}
      {enableHighlight && (
        <ToggleButton title={t('Highlight')}>
          <ToolbarColorPicker
            icon="highlighter"
            color={highlight}
            onChange={(rgba) => editor.chain().setHighlight(rgba).run()}
          />
        </ToggleButton>
      )}
    </TextEditorToolbarSection>
  );
};
