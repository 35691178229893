import produce from 'immer';
import { hasMultipleBusinesses } from 'store/authentication/helpers';

/* -------------------- */
// Reducers
/* -------------------- */
export const loginStart = produce((draft) => {
  draft.authenticating = true;
  draft.errors.login = null;
});

export const loginSuccess = produce((draft, action) => {
  const { user, access, teamMember, externalApps, pluginApps } = action;
  const { first_business, is_superuser } = user;
  draft.user = user;
  draft.access = access;
  draft.teamMember = teamMember;
  draft.externalApps = externalApps;
  draft.pluginApps = pluginApps;
  draft.isShowInactivityMessage = false;
  draft.isShowTimeoutMessage = false;

  // A multi-business user or a superuser should have to select a business
  // A multi-biz user with a first business is probably a bug, but we
  // have a graceful default here
  if (is_superuser || hasMultipleBusinesses(user)) {
    draft.authenticating = false;
    return;
  }

  draft.chosenBusiness = first_business;
  draft.authenticating = false;
});

export const updatePluginAppsListSuccess = produce((draft, action) => {
  draft.pluginApps = action.pluginApps;
});

export const loginFailure = produce((draft, action) => {
  draft.user = null;
  draft.access = null;
  draft.chosenBusiness = null;
  draft.authenticating = false;
  draft.teamMember = null;
  draft.externalApps = {};
  draft.pluginApps = [];
  draft.errors.login = action.error;
});

export const logoutStart = produce((draft) => {
  draft.errors.login = null;
});

export const logoutSuccess = produce((draft) => {
  draft.user = null;
  draft.access = null;
  draft.chosenBusiness = null;
  draft.teamMember = null;
  draft.externalApps = {};
  draft.pluginApps = [];
});

export const logoutFailure = produce(() => {});

export const chooseBusinessStart = produce(() => {});

export const chooseBusinessSuccess = produce((draft, action) => {
  const { business, access, teamMember, user, externalApps, pluginApps } =
    action;

  draft.access = access;
  draft.chosenBusiness = business;
  draft.teamMember = teamMember;
  draft.user = user;
  draft.externalApps = externalApps;
  draft.pluginApps = pluginApps;
});

export const chooseBusinessFailure = produce(() => {
  // Do NOT clear any previously selected business
  // Failure to select should not hose the current session's
  // business scope
});

export const updateBusinessStart = produce(() => {});

export const updateBusinessSuccess = produce((draft, action) => {
  const { business } = action;
  draft.chosenBusiness = business;
});

export const updateBusinessFailure = produce(() => {});

export const rehydrateStart = produce((draft) => {
  // redundant with initial state, just to be safe
  draft.rehydrating = true;
});

export const rehydrateSuccess = produce((draft, action) => {
  const { user, access, business, teamMember, externalApps, pluginApps } =
    action;
  draft.user = user;
  draft.access = access;
  draft.chosenBusiness = business;
  draft.teamMember = teamMember;
  draft.externalApps = externalApps;
  draft.pluginApps = pluginApps;
  draft.rehydrating = false;
});

export const rehydrateFailure = produce((draft) => {
  draft.user = null;
  draft.access = null;
  draft.chosenBusiness = null;
  draft.teamMember = null;
  draft.externalApps = {};
  draft.pluginApps = [];
  draft.rehydrating = false;
});

export const requestPasswordResetStart = produce((draft) => {
  draft.errors.requestPasswordReset = null;
});

export const requestPasswordResetSuccess = produce(() => {});

export const requestPasswordResetFailure = produce((draft, action) => {
  draft.errors.requestPasswordReset = action.error;
});

export const resetPasswordStart = produce((draft) => {
  draft.errors.resetPassword = null;
});

export const resetPasswordSuccess = produce(() => {});

export const resetPasswordFailure = produce((draft, action) => {
  draft.errors.resetPassword = action.error;
});

export const verifyAccountStart = produce((draft) => {
  draft.errors.verifyAccount = null;
});

export const refreshAccessSuccess = produce((draft, action) => {
  const { access } = action;
  draft.access = access;
});

export const refreshTeamMemberSuccess = produce((draft, action) => {
  const { teamMember } = action;
  draft.errors.refreshTeamMember = null;
  draft.teamMember = teamMember;
});

export const refreshTeamMemberFailure = produce((draft, action) => {
  draft.errors.refreshTeamMember = action.error;
});

export const showInactivityMessage = produce((draft) => {
  draft.isShowInactivityMessage = true;
});

export const showTimeoutMessage = produce((draft) => {
  draft.isShowTimeoutMessage = true;
});
