import React from 'react';
import PropTypes from 'prop-types';
import { ApplyMenuButton } from '../../Kizen/Menu';

export default function ApplySelectButton({ onClick, select, ...props }) {
  return (
    <ApplyMenuButton
      onClick={(ev) => {
        select.selectProps.selectManager.onMenuClose();
        // This is a special case for date pickers so they can be closed in the same
        // manner as normal selects
        if (select.selectProps.handleClose) {
          select.selectProps.handleClose();
        }
        if (onClick) onClick(ev);
      }}
      {...props}
    />
  );
}

ApplySelectButton.propTypes = {
  onClick: PropTypes.func,
  select: PropTypes.object.isRequired,
};

ApplySelectButton.defaultProps = {
  onClick: null,
};

ApplySelectButton.enrich = {
  select: true,
};
