import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonRadio } from 'ts-components/ButtonToggle';
import { useBuilderContext } from '../BuilderContext';
import { TraySection } from '../components/TraySection';
import { FlexWrapper, FlexWholeNumberInput } from './components';
import { useCraftProps } from './useCraftProps';

const alignmentOptions = [
  { value: 'left', iconName: 'align-left' },
  { value: 'center', iconName: 'align-center' },
  { value: 'right', iconName: 'align-right' },
];

export const SectionSettings = ({
  defaultMaxWidth,
  enableAlignment = true,
  node: {
    id,
    data: { props: initialProps },
  },
}) => {
  const { t } = useTranslation();
  const { clearContentSettingsTray } = useBuilderContext();
  const [props, setProp, setProps] = useCraftProps(id, initialProps);

  useEffect(() => {
    setProps(initialProps);
  }, [initialProps, setProps]);

  useEffect(() => {
    if (initialProps.width === undefined) {
      setProp('width', '100');
    }
    if (initialProps.maxWidth === undefined) {
      setProp('maxWidth', defaultMaxWidth);
    }
    if (initialProps.alignment === undefined) {
      setProp('alignment', 'center');
    }
  }, [
    defaultMaxWidth,
    initialProps.width,
    initialProps.maxWidth,
    initialProps.alignment,
    setProp,
  ]);

  return (
    <TraySection
      onBackClick={clearContentSettingsTray}
      collapsable={false}
      header={`${t('Section Settings')}`}
    >
      <FlexWrapper>
        <FlexWholeNumberInput
          shrink
          variant="underline"
          maxValue={100}
          value={props.width}
          label={t('Width (%)')}
          onChange={(val) => setProp('width', val)}
        />
        <FlexWholeNumberInput
          shrink
          variant="underline"
          maxDigits={4}
          value={props.maxWidth}
          label={t('Max Width (px)')}
          onChange={(val) => setProp('maxWidth', val)}
        />
        {enableAlignment && (
          <ButtonRadio
            variant="underline"
            value={props.alignment}
            label={t('Alignment')}
            options={alignmentOptions}
            onChange={(val) => setProp('alignment', val)}
          />
        )}
      </FlexWrapper>
    </TraySection>
  );
};
