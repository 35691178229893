import React, { useRef } from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { KizenTypography } from 'app/typography';
import { gutters } from 'app/spacing';
import { getNonInputControlProps, hasInputControl } from '../InputControl';
import Select from '../Select';
import { selectedValueProp } from '../props';
import ClearSelectButton from '../Select/ClearButton';
import ApplySelectButton from '../Select/ApplyButton';
import { Menu as BaseMenu } from '../Select/customize';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const emptyValue = '—';

const StyledSelect = styled(Select)`
  width: 50px;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: baseline;

  & > * {
    display: inline-block;
  }

  & > div:first-child {
    margin-right: ${gutters.spacing(1)}px;
  }
`;

const Menu = styled(BaseMenu)`
  width: 120px;
`;

const DisabledValueContainer = styled.div`
  margin-top: 7px;
`;

const UnderlineRating = React.forwardRef(function UnderlineRating(props, ref) {
  const {
    fieldId,
    value,
    className,
    maxRating,
    showMenuLeftButton,
    showMenuRightButton,
    disabled,
    options,
    mergeRef,
  } = props;
  const { assignFieldHandle } = useKeyBoardContext();

  const { t } = useTranslation();

  const selectProps = getNonInputControlProps(props);

  const selectRef = useRef(null);

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    props
  );
  assignFieldHandle(fieldId, {
    customFocus: () => {
      selectRef.current?.select?.focus();
      setMenuIsOpen(false);
      return selectRef.current?.select;
    },
    customEscape: () => {
      setMenuIsOpen(false);
    },
    customUp: () => {
      setMenuIsOpen(true);
    },
    customDown: () => {
      setMenuIsOpen(true);
    },
    disabled,
  });

  return (
    <SelectWrapper ref={mergeRef}>
      {disabled ? (
        <DisabledValueContainer>
          <KizenTypography>{value?.value ?? emptyValue}</KizenTypography>
        </DisabledValueContainer>
      ) : (
        <StyledSelect
          ref={selectRef}
          className={!hasInputControl(props) && className}
          isSearchable={false}
          {...selectProps}
          placeholder="#"
          variant="underline"
          options={options}
          error={false}
          menuLeftButton={showMenuLeftButton && <ClearSelectButton />}
          menuRightButton={showMenuRightButton && <ApplySelectButton />}
          components={{
            Menu: showMenuLeftButton || showMenuRightButton ? Menu : BaseMenu,
            ...selectProps.components,
          }}
          {...customSelectMenuProps}
        />
      )}
      {!(disabled && !value) && (
        <KizenTypography>{`${t('out of')} ${maxRating}`}</KizenTypography>
      )}
    </SelectWrapper>
  );
});

UnderlineRating.propTypes = {
  value: selectedValueProp,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  showMenuLeftButton: PropTypes.bool,
  showMenuRightButton: PropTypes.bool,
  maxRating: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
};

UnderlineRating.defaultProps = {
  value: null,
  onChange: null,
  error: null,
  disabled: null,
  showMenuLeftButton: false,
  showMenuRightButton: false,
};

export default Object.assign(UnderlineRating, {
  // eslint-disable-next-line react/forbid-foreign-prop-types
  propTypes: UnderlineRating.propTypes,
  defaultProps: UnderlineRating.defaultProps,
});
