import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import {
  colorsButton,
  colorsPrimary,
  colorsSecondary,
  grayScale,
} from 'app/colors';
import { fontSizes } from 'app/typography';
import Icon from 'components/Kizen/Icon';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';

import IconButton from 'components/Kizen/IconButton';
import ColorLabel from 'components/Fields/ColorLabel';
import ActionCell from 'components/Tables/ActionCell';
import { MESSAGE_TYPES } from 'utility/constants';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { blue } from '@kizen/kds/Colors';
export const WrapperStatus = styled.p`
  font-size: ${fontSizes.text};
  margin-bottom: 0;
`;

export const StyledEyeIcon = styled(Icon)`
  && {
    width: 15px;
    svg {
      width: 15px;
      height: auto;
    }
  }
`;

const NO_VALUE = '—';

export const EmptyActionCell = (props) => (
  <SizedCell {...props}>
    <KizenTypography>{NO_VALUE}</KizenTypography>
  </SizedCell>
);

const SubscriptionActionCell = ({ t, data, ...props }) => (
  <ActionCell
    options={[
      { value: 'opted_in', label: t('Mark Opted In') },
      { value: 'not_opted_in', label: t('Mark Not Opted In') },
      { value: 'opted_out', label: t('Mark Opted Out') },
    ]}
    title={t('Edit Subscription')}
    value={{ value: data.status }}
    data={data}
    isDisabledOption
    data-qa-list-action={data.name}
    {...props}
  />
);

export const MobileSubscriptionActionCell = styled(SubscriptionActionCell)`
  i {
    width: 15px;
    svg {
      width: 15px;
      height: auto;
    }
  }
`;

export const messageStatisticColors = {
  delivered: colorsPrimary.green.dark,
  opened: colorsPrimary.green.dark,
  clicked: colorsPrimary.green.dark,
  undelivered: colorsPrimary.orange.dark,
  pending: colorsPrimary.orange.dark,
  unsubscribed: colorsSecondary.red.dark,
  complaint: colorsSecondary.red.dark,
  soft_bounced: colorsSecondary.red.dark,
  bounced: colorsSecondary.red.dark,
  opened_attachment: colorsPrimary.green.dark,
};
export const messageStatusColors = {
  pending: colorsPrimary.orange.dark,
  sent: colorsPrimary.green.dark,
  failed: colorsSecondary.red.dark,
  complained: colorsSecondary.red.dark,
  bounced: colorsSecondary.red.dark,
  unsubscribed: colorsSecondary.red.dark,
  opened_attachment: colorsPrimary.green.dark,
  clicked: colorsPrimary.green.dark,
  opened: colorsPrimary.green.dark,
  pending_smart_sent: blue['blue-500'],
};

export const messageStatisticText = {
  delivered: (t) => t('Delivered'),
  opened: (t) => t('Opened'),
  clicked: (t) => t('Clicked'),
  undelivered: (t) => t('Undelivered'),
  pending: (t) => t('Pending'),
  unsubscribed: (t) => t('Unsubscribed'),
  complaint: (t) => t('Complaint'),
  soft_bounced: (t) => t('Softbounced'),
  bounced: (t) => t('Bounced'),
  opened_attachment: (t) => t('Opened Attachment'),
};

export const subscriptionStatusColors = {
  // todo: remove once BE is ready KZN-4580, KZN-4680
  unsubscribed: colorsPrimary.orange.dark,
  opted_out: colorsPrimary.orange.dark,
  opted_in: colorsPrimary.green.dark,
  not_opted_in: grayScale.dark,
  suppression_list: colorsSecondary.red.dark,
};

export const subscriptionStatusLabel = {
  // todo: remove once BE is ready KZN-4580, KZN-4680
  unsubscribed: (t) => t('Unsubscribed'),
  opted_out: (t) => t('Opted Out'),
  opted_in: (t) => t('Opted In'),
  not_opted_in: (t) => t('Not Opted In'),
  suppression_list: (t) => t('Suppression List'),
};

export const getMessageColumns = ({ onClickMessage }, t) => [
  {
    id: 'spacer-1',
    headCell: <SizedCell width="20px" />,
    cell: <SizedCell width="20px" />,
  },
  {
    id: 'message',
    headCell: (
      <SortableHeadCell label={t('Message')} width="171px" padding="20px" />
    ),
    cell: <SizedCell width="171px" padding="20px" />,
    format: function TextCell(_, value) {
      const {
        hasContent,
        message: { preview },
      } = value;
      return hasContent ? (
        <TextEllipsisWithTooltip
          type="link"
          onClick={(e) => {
            e.preventDefault();
            onClickMessage(value);
          }}
        >
          {preview.trim()}
        </TextEllipsisWithTooltip>
      ) : (
        <TextEllipsisWithTooltip>{preview.trim()}</TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'type',
    headCell: (
      <SortableHeadCell label={t('Type')} width="95px" padding="20px" />
    ),
    cell: <SizedCell width="95px" padding="20px" />,
    format: function MessageTypeCell(value) {
      return (
        <TextEllipsisWithTooltip>
          {MESSAGE_TYPES[value](t)}
        </TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'sentAt',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Date Sent')}
        width="150px"
        padding="20px"
      />
    ),
    cell: <SizedCell width="150px" padding="20px" />,
    format: function DateCell(sentAt, message) {
      const { smartSentAt, status } = message;
      const isPendingSmartSent = smartSentAt && status === 'pending';
      return isPendingSmartSent ? (
        <TextEllipsisWithTooltip />
      ) : (
        <DateTimeInputInline value={sentAt} readOnly showDateTooltip />
      );
    },
  },
  {
    id: 'emailStatus',
    headCell: (
      <SortableHeadCell
        label={t('Status')}
        width="125px"
        padding="20px"
        hasSorting={false}
      />
    ),
    cell: <SizedCell width="125px" padding="20px" />,
    format: function StateCell(status, message) {
      const { displayStatus, smartSentAt } = message;
      const isPendingSmartSent = smartSentAt && status === 'pending';
      const color = isPendingSmartSent
        ? messageStatusColors['pending_smart_sent']
        : messageStatusColors[status];
      return (
        color && (
          <WrapperStatus>
            <ColorLabel color={color}>
              {isPendingSmartSent ? t('Smart Scheduled') : displayStatus}
            </ColorLabel>
          </WrapperStatus>
        )
      );
    },
  },
  {
    id: 'show',
    // Set 16 px padding because we have 4 px width of the scrollbar.
    // As a result, we have 20px padding to the border of table
    headCell: <SizedCell width="15px" padding="11px" />,
    cell: <SizedCell width="15px" padding="11px" />,
    format: function ShowCell(value, message) {
      const { hasContent } = message;
      return (
        hasContent && (
          <IconButton
            sizing="dense"
            title={t('Show Message')}
            onClick={() => onClickMessage(message)}
            color={colorsButton.iconGray}
          >
            <StyledEyeIcon icon="visible" />
          </IconButton>
        )
      );
    },
  },
];

export const getSubscriptionColumns = (
  { emailIsSuppressionList, onSelectAction, editSubscriptionsAccess },
  t
) =>
  [
    {
      id: 'spacer-1',
      headCell: <SizedCell width="20px" />,
      cell: <SizedCell width="20px" />,
    },
    {
      id: 'name',
      headCell: (
        <SortableHeadCell
          label={t('Subscription List Name')}
          width="320px"
          padding="50px"
        />
      ),
      cell: <SizedCell width="320px" padding="50px" />,
      format: function TextCell(value) {
        return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
      },
    },
    {
      id: 'status',
      headCell: (
        <SortableHeadCell label={t('Status')} width="124px" padding="50px" />
      ),
      cell: <SizedCell width="124px" padding="50px" />,
      format: function StateCell(status, data) {
        const color =
          subscriptionStatusColors[
            emailIsSuppressionList ? 'suppression_list' : status
          ];
        return (
          <WrapperStatus data-qa-subscription-status={data.id}>
            <ColorLabel color={color}>
              {subscriptionStatusLabel[
                emailIsSuppressionList ? 'suppression_list' : status
              ](t)}
            </ColorLabel>
          </WrapperStatus>
        );
      },
    },
    editSubscriptionsAccess && {
      id: 'actions',
      headCell: (
        <SubActionHeadCell
          width="51px"
          padding="11px"
          data-qa="cell-head-actions"
        />
      ),
      cell: emailIsSuppressionList ? (
        <EmptyActionCell width="51px" padding="11px" />
      ) : (
        <SubscriptionActionCell
          width="51px"
          padding="11px"
          onSelectAction={onSelectAction}
          t={t}
        />
      ),
    },
  ].filter(Boolean);
