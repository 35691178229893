import { Dispatch, RefObject, SetStateAction, useMemo, useState } from 'react';
import { DataRenderer } from './DataRenderer';
import {
  IngestibleLayouts,
  LayoutChangeHandler,
  LayoutOption,
  CategoryOptions,
} from './types';

interface ActiveHandlerProps {
  layouts: IngestibleLayouts;
  formData: any;
  handleChange: LayoutChangeHandler;
  revision: number;
  setRevision: Dispatch<SetStateAction<number>>;
  objectId: string;
  refetchLayouts: () => void;
  autoSelectFirstItem: boolean;
  setAutoSelectFirstItem: Dispatch<SetStateAction<boolean>>;
  clearStagedChanges: () => void;
  dirty: boolean;
  handleSaveLayoutChanges: () => Promise<void>;
  layoutErrors: any;
  setLayoutErrors: Dispatch<SetStateAction<any>>;
  canUseRelatedPipelinesBlock: boolean;
  categorizedFields: CategoryOptions;
  secondarySettingsContainer?: RefObject<HTMLDivElement>;
  isClient: boolean;
}

export const ActiveHandler = (props: ActiveHandlerProps) => {
  const {
    layouts,
    formData,
    handleChange,
    revision,
    setRevision,
    objectId,
    refetchLayouts,
    autoSelectFirstItem,
    setAutoSelectFirstItem,
    clearStagedChanges,
    dirty,
    handleSaveLayoutChanges,
    layoutErrors,
    setLayoutErrors,
    canUseRelatedPipelinesBlock,
    categorizedFields,
    secondarySettingsContainer,
    isClient,
  } = props;

  const activeLayouts = useMemo(() => {
    return layouts.filter((layout) => layout.active);
  }, [layouts]);

  const firstLayout = useMemo(() => {
    return layouts[0];
  }, [layouts]);

  const [currentLayout, setCurrentLayout] = useState<
    LayoutOption | undefined
  >();

  const activeLayout = useMemo(() => {
    return layouts.find((layout: any) => layout.id === currentLayout?.value);
  }, [layouts, currentLayout]);

  if (!activeLayout && currentLayout?.value) {
    setCurrentLayout(undefined);
    setLayoutErrors(undefined);
  }

  if (autoSelectFirstItem && firstLayout && !currentLayout) {
    setCurrentLayout({
      value: firstLayout.id,
      label: firstLayout.name,
      active: firstLayout.active,
    });
    setAutoSelectFirstItem(false);
    setLayoutErrors(undefined);
  }

  if (
    activeLayout &&
    currentLayout &&
    activeLayout.name &&
    currentLayout.label &&
    activeLayout.name !== currentLayout.label
  ) {
    setCurrentLayout((prev: any) => {
      return {
        ...prev,
        label: activeLayout?.name,
      };
    });
  }

  if (!activeLayout && dirty) {
    clearStagedChanges();
  }

  return (
    <DataRenderer
      layouts={layouts}
      activeLayouts={activeLayouts}
      formData={formData}
      handleChange={handleChange}
      currentLayout={currentLayout}
      setCurrentLayout={setCurrentLayout}
      activeLayout={activeLayout?.layout}
      activeLayoutName={activeLayout?.name}
      activeSharingSettings={activeLayout?.sharingSettings}
      activeLayoutIsActive={activeLayout?.active ?? true}
      activeLayoutShowAutomationsTab={activeLayout?.tabs?.automations ?? true}
      activeLayoutShowMessagesTab={activeLayout?.tabs?.messages ?? true}
      key={activeLayout?.id}
      revision={revision}
      setRevision={setRevision}
      objectId={objectId}
      refetchLayouts={refetchLayouts}
      clearStagedChanges={clearStagedChanges}
      dirty={dirty}
      handleSaveLayoutChanges={handleSaveLayoutChanges}
      layoutErrors={layoutErrors}
      setLayoutErrors={setLayoutErrors}
      canUseRelatedPipelinesBlock={canUseRelatedPipelinesBlock}
      categorizedFields={categorizedFields}
      secondarySettingsContainer={secondarySettingsContainer}
      isClient={isClient}
    />
  );
};
