import {
  getIdFullNameWithEmail,
  getIdFullNameWithEmailDeleted,
  getIdNameDeleted,
  optionToNamed,
  getIdFullNameError,
} from 'services/helpers';
import {
  TYPE_ROUND_ROBIN_ALL,
  TYPE_ROUND_ROBIN_ROLE,
  TYPE_ROUND_ROBIN_TEAM_MEMBERS,
  TYPE_TEAM_MEMBER,
} from './common';

import {
  checkDeleted,
  displayNameDescriptor,
  checkListDeleted,
  nameDescriptor,
} from 'pages/AutomationEngine/helpers';

const forApi = (step) => {
  const orgDetails = { ...step.details };
  const type =
    orgDetails?.assignTeamMember?.assignmentType?.id || TYPE_ROUND_ROBIN_ALL;

  let api = {};
  switch (type) {
    case TYPE_TEAM_MEMBER:
      api = {
        type,
        employeeId: orgDetails.assignTeamMember.teamMember.id,
      };
      break;
    case TYPE_ROUND_ROBIN_ROLE:
      api = {
        type,
        roleId: orgDetails.assignTeamMember.role.id,
      };
      break;
    case TYPE_ROUND_ROBIN_TEAM_MEMBERS:
      api = {
        type,
        employeeIds: orgDetails.assignTeamMember.teamMembers.map(
          ({ id }) => id
        ),
      };
      break;

    default:
      // TYPE_ROUND_ROBIN_ALL:
      api = { type };
      break;
  }
  return { details: api };
};

const forFlow = (apiObject, addErrorMessage, messageDictionary, stepType) => {
  const { type, employee, employees, role } = apiObject;

  if (type === TYPE_ROUND_ROBIN_ROLE) {
    checkDeleted(
      messageDictionary,
      addErrorMessage,
      role,
      nameDescriptor,
      messageDictionary.associatedRole
    );
  }

  const flow = {
    // type is clashing with step type so let's force it here now we have set assignmentType
    type: stepType,
    assignTeamMember: {
      assignmentType: optionToNamed(
        messageDictionary.dictionaryAassignmentTypeOptions[type]
      ),
    },
  };

  if (employee) {
    checkDeleted(
      messageDictionary,
      addErrorMessage,
      employee,
      displayNameDescriptor
    );

    flow.assignTeamMember.teamMember = getIdFullNameWithEmailDeleted(employee);
  }

  if (role) {
    // deleting the role cause a back end issue
    flow.assignTeamMember.role = getIdNameDeleted(role);
  }

  if (employees || type === TYPE_ROUND_ROBIN_TEAM_MEMBERS) {
    // employees are getting deleted from the list \o/
    checkListDeleted(
      messageDictionary,
      addErrorMessage,
      employees,
      displayNameDescriptor,
      type === TYPE_ROUND_ROBIN_TEAM_MEMBERS
        ? messageDictionary.teamMember
        : null,
      'employees',
      getIdFullNameError
    );
    flow.assignTeamMember.teamMembers = employees
      .filter(({ deleted }) => !deleted)
      .map(getIdFullNameWithEmail);
  }

  return flow;
};
export const assignTeamMemberDTO = {
  forApi,
  forFlow,
};
