import { Spacing } from '../spacing';
import { merge } from '../util';
import { COLUMN_SIZE, OneColumn, ThreeColumns, TwoColumns } from './types';

export const getWidthForGap = (gap: Spacing, size: COLUMN_SIZE) => {
  switch (gap) {
    case 15:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg/grid:w-[calc(50%-7.5px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg/grid:w-[calc(33.333333%-7.5px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg/grid:w-[calc(66.666666%-7.5px)]';
      }
      return '';
    case 20:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg/grid:w-[calc(50%-10px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg/grid:w-[calc(33.333333%-10px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg/grid:w-[calc(66.666666%-10px)]';
      }
      return '';
    case 30:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg/grid:w-[calc(50%-15px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg/grid:w-[calc(33.333333%-15px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg/grid:w-[calc(66.666666%-15px)]';
      }
      return '';

    default:
      return '';
  }
};

export const getAltWidthForGap = (gap: Spacing, size: COLUMN_SIZE) => {
  switch (gap) {
    case 15:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg-alt/grid:w-[calc(50%-7.5px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg-alt/grid:w-[calc(33.333333%-7.5px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg-alt/grid:w-[calc(66.666666%-7.5px)]';
      }
      return '';
    case 20:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg-alt/grid:w-[calc(50%-10px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg-alt/grid:w-[calc(33.333333%-10px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg-alt/grid:w-[calc(66.666666%-10px)]';
      }
      return '';
    case 30:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg-alt/grid:w-[calc(50%-15px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg-alt/grid:w-[calc(33.333333%-15px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg-alt/grid:w-[calc(66.666666%-15px)]';
      }
      return '';

    default:
      return '';
  }
};

export const getSubGridWidthForGap = (gap: Spacing, size: COLUMN_SIZE) => {
  switch (gap) {
    case 15:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg-sub/grid:w-[calc(50%-7.5px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg-sub/grid:w-[calc(33.333333%-7.5px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg-sub/grid:w-[calc(66.666666%-7.5px)]';
      }
      return '';
    case 20:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg-sub/grid:w-[calc(50%-10px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg-sub/grid:w-[calc(33.333333%-10px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg-sub/grid:w-[calc(66.666666%-10px)]';
      }
      return '';
    case 30:
      if (size === COLUMN_SIZE.HALF_WIDTH) {
        return '@lg-sub/grid:w-[calc(50%-15px)]';
      }
      if (size === COLUMN_SIZE.THIRD_WIDTH) {
        return '@lg-sub/grid:w-[calc(33.333333%-15px)]';
      }
      if (size === COLUMN_SIZE.TWO_THIRD_WIDTH) {
        return '@lg-sub/grid:w-[calc(66.666666%-15px)]';
      }
      return '';

    default:
      return '';
  }
};

const getWidthClass = (
  hasOneThird: boolean,
  subgrid: boolean,
  gap: Spacing,
  size: COLUMN_SIZE
) => {
  if (hasOneThird && !subgrid) {
    return getAltWidthForGap(gap, size);
  }
  if (subgrid) {
    return getSubGridWidthForGap(gap, size);
  }
  return getWidthForGap(gap, size);
};

export const getColumnClassNames = (
  layout: OneColumn | TwoColumns | ThreeColumns,
  gap: Spacing,
  hasOneThird: boolean,
  subgrid: boolean
) => {
  const baseClasses = '';
  let column1ClassName = baseClasses + ' ';
  let column2ClassName = baseClasses + ' ';
  let column3ClassName = baseClasses + ' ';

  const fullWidthClass = 'flex flex-col w-full';
  const halfWidthClass = merge(
    'flex flex-col',
    getWidthClass(hasOneThird, subgrid, gap, COLUMN_SIZE.HALF_WIDTH)
  );
  const thirdWidthClass = merge(
    'flex flex-col',
    getWidthClass(hasOneThird, subgrid, gap, COLUMN_SIZE.THIRD_WIDTH)
  );
  const twoThirdWidthClass = merge(
    'flex flex-col',
    getWidthClass(hasOneThird, subgrid, gap, COLUMN_SIZE.TWO_THIRD_WIDTH)
  );

  if (layout[0] === COLUMN_SIZE.HALF_WIDTH) {
    column1ClassName += halfWidthClass;
  }

  if (layout[0] === COLUMN_SIZE.THIRD_WIDTH) {
    column1ClassName += thirdWidthClass;
  }

  if (layout[0] === COLUMN_SIZE.TWO_THIRD_WIDTH) {
    column1ClassName += twoThirdWidthClass;
  }

  if (layout[0] === COLUMN_SIZE.FULL_WIDTH) {
    column1ClassName += fullWidthClass;
  }

  if (layout[1] === COLUMN_SIZE.HALF_WIDTH) {
    column2ClassName += halfWidthClass;
  }

  if (layout[1] === COLUMN_SIZE.THIRD_WIDTH) {
    column2ClassName += thirdWidthClass;
  }

  if (layout[1] === COLUMN_SIZE.TWO_THIRD_WIDTH) {
    column2ClassName += twoThirdWidthClass;
  }

  if (layout[2] === COLUMN_SIZE.THIRD_WIDTH) {
    column3ClassName += thirdWidthClass;
  }

  return {
    column1ClassName,
    column2ClassName,
    column3ClassName,
  };
};
