import { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import GenericWizard from 'components/GenericWizard';
import { LIST_OF_FIELDS } from 'components/GenericWizard/wizards/field/config';
import BasicModal from 'components/Modals/presets/BasicModal';
import ActivityService from 'services/ActivityService';
import { FIELD_TYPES } from 'utility/constants';
import { useDirtyState } from 'components/Builders/CustomFieldsBuilder/hooks/useDirtyState';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { getOriginalError } from 'services/AxiosService';
import { toastVariant, useToast } from 'components/ToastProvider';

const stripNullKeys = (object) => {
  Object.keys(object).forEach(
    (key) => object[key] == null && delete object[key]
  );
};

export default function ActivityFieldWizard({
  show,
  onHide,
  context,
  onConfirm,
}) {
  const { t } = useTranslation();
  const [wizardData, setWizardData] = useState({});
  const [canBeSaved, setCanBeSaved] = useState(false);
  const [showToast] = useToast();
  const handleSave = async () => {
    const preSend = cloneDeep(wizardData);
    const field = LIST_OF_FIELDS.find((f) => f.type === preSend.type);
    try {
      if (field.onBeforeSave) {
        await field.onBeforeSave(wizardData, field);
      }
      const {
        displayName,
        isRequired,
        type,
        options,
        phonenumberOptions,
        moneyOptions,
        relation,
        rating,
        meta,
      } = preSend;

      let payload = {
        activityObjectId: context.activityObjectId,
        displayName,
        isRequired: isRequired || false,
        fieldType: type,
        ...(meta !== undefined && {
          meta,
        }),
        ...(options && options.length && { options }),
        ...(type === FIELD_TYPES.PhoneNumber.type && { phonenumberOptions }),
        ...(type === FIELD_TYPES.Rating.type && { rating }),
        ...(type === FIELD_TYPES.Money.type && { moneyOptions }),
        ...(type === FIELD_TYPES.Relationship.type && { relation }),
      };

      if (context.field) {
        // patch,
        const { id, meta: oldMeta } = context.field;
        payload = {
          ...payload,
          meta: {
            ...oldMeta,
            ...meta,
          },
          id,
        };
        stripNullKeys(payload);

        payload = await ActivityService.v2UpdateField(payload);
      } else {
        // create
        payload = {
          ...payload,
          isDefault: false,
          allowsNulls: true,
          allowsEmpty: true,
          isReadOnly: false,
          isHidden: false,
          meta: { ...meta, cols: 1 }, // default to 1 column
        };
        stripNullKeys(payload);
        payload = await ActivityService.v2CreateField(payload);
      }
      onConfirm(payload);
    } catch (error) {
      const orig = getOriginalError(error);
      if (orig?.message) {
        showToast({
          message: orig?.message,
          variant: toastVariant.FAILURE,
        });
      }
    }
  };
  const initialDataRef = useRef(null);

  const onChange = ({ data, isComplete }) => {
    if (!initialDataRef.current) {
      initialDataRef.current = data;
    }
    setWizardData(data);
    setCanBeSaved(isComplete);
  };

  const { field } = context;

  const { showConfirmation, onCheckToHide, onHideConfirmed, onHideCanceled } =
    useDirtyState(initialDataRef.current, wizardData, onHide);

  return (
    <>
      <BasicModal
        disabled={!canBeSaved}
        fitContent={false}
        heading={field ? t('Edit Field') : t('Add New Field')}
        onConfirm={handleSave}
        onHide={onCheckToHide}
        show={show}
        size="medium"
        typeOfContent="wizard"
      >
        <GenericWizard
          wizard="ActivityFieldWizard"
          onChange={onChange}
          data={context}
        />
      </BasicModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        show={showConfirmation}
        onConfirm={onHideConfirmed}
        onHide={onHideCanceled}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
}

ActivityFieldWizard.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  context: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
};

ActivityFieldWizard.defaultProps = {
  show: false,
  context: {},
};
