import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale, tableHover } from 'app/colors';
import { gutters, layers } from 'app/spacing';
import { Table } from 'components/Kizen/Table';

export const TABLE_HEADER_HEIGHT = 50;

export default styled(Table)`
  width: 100%;
  margin-bottom: ${gutters.spacing(4, -17)}px;
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
  thead > tr {
    height: ${TABLE_HEADER_HEIGHT}px;
  }
  tbody > tr {
    height: 40px;
    &:hover {
      background-color: ${tableHover};
    }
  }

  tbody > tr.active {
    background-color: ${tableHover};
  }

  ${({ height }) =>
    typeof height !== 'undefined'
      ? css`
          height: ${height}px;
        `
      : ''}
  ${({ compactHeader }) =>
    // This is often used alongside "tall" toolbars
    compactHeader &&
    css`
      thead > tr {
        height: 42px;
      }
    `}
  ${({ stickyHeader }) =>
    stickyHeader &&
    css`
      border-collapse: separate; // Ensures bottom border on sticky th doesn't scroll away
      thead > tr {
        th {
          position: sticky;
          top: 0;
          z-index: ${layers.content(0, 1)}; // Appear above cell contents
          background-color: ${grayScale.white};
          border-bottom: 1px solid ${grayScale.mediumLight};
        }
      }
    `}
  transition: opacity 0.3s ease-in-out;
  ${({ staleData }) =>
    staleData &&
    css`
      tbody {
        opacity: 0.5;
      }
    `}
`;
