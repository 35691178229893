import { forwardRef } from 'react';

import { withCellWrapper } from './../helpers';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import ValidatedField from 'components/Kizen/ValidatedField';
import TextInputInline from 'components/Inputs/inline/TextInput';
import { scrollFieldIntoView } from 'components/Fields/helpers';

const _NameCell = forwardRef(
  (
    {
      field,
      object: record,
      onSubmit,
      value: valueProp, // remove from orhers as we get value from record
      objectId: modelId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      editable,
      onAutoFocus,
      autoFocus,
      pathPrefix = '',
      target = '',
      shouldFocusNext,
      enableLink = true,
      ...others
    },
    ref
  ) => {
    const value = record.name;

    if (!column.meta.editable || !editable) {
      return (
        <TextEllipsisWithTooltip
          type={enableLink ? 'link' : 'text'}
          to={
            enableLink ? `${pathPrefix}${modelId}/${record.id}/details` : null
          }
          target={target}
        >
          {value}
        </TextEllipsisWithTooltip>
      );
    }
    return record.id !== 'new' ? (
      <ValidatedField
        type={enableLink ? 'link' : 'text'}
        shouldFocusNext={shouldFocusNext}
        to={enableLink ? `${pathPrefix}${modelId}/${record.id}/details` : null}
        target={target}
        value={value}
        field={field}
        object={record}
        position="unset"
        fieldId={column.id}
        onSubmit={async (data) => {
          return await onSubmit(record.id, { name: data }, column);
        }}
      />
    ) : (
      <TextInputInline
        isNew
        shouldFocusNext={shouldFocusNext}
        value={value}
        field={field}
        object={record}
        onSubmit={async (data) => {
          return await onSubmit(record.id, { name: data }, column);
        }}
        onAutoFocus={scrollFieldIntoView}
        autoFocus={autoFocus}
        submitUnchanged={true}
      />
    );
  }
);

export const NameCell = withCellWrapper(_NameCell);
