import React from 'react';
import {
  FieldSettingsSection,
  ElementContainerSettingsSection,
} from '../../settings';

export const CustomFieldSettings = ({ node, relatedObject }) => (
  <>
    <FieldSettingsSection
      key={node.id}
      node={node}
      relatedObject={relatedObject}
    />
    <ElementContainerSettingsSection node={node} />
  </>
);
