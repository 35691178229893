export const CONTEXT_RELATIONSHIP_FIELD = 'contextEntityField';

export const AUTOMATIONS_TO_START = 'automationsToStart';

export const inputsAddRelatedEntity = {
  NEW_ENTITY_NAME: 'newEntityName',
  NEW_ENTITY_NAME_HTML: 'newEntityNameHtml',
  NEW_ENTITY_OWNER_TYPE: 'newEntityOwnerType',
  NEW_ENTITY_OWNER_SUB_TYPE: 'newEntityOwnerSubType',
  NEW_ENTITY_OWNER_EMPLOYEES: 'newEntityOwnerEmployees',
  NEW_ENTITY_OWNER_ROLE: 'newEntityOwnerRole',
  NEW_ENTITY_STAGE: 'newEntityStage',
};

export const ownerTypes = {
  FROM_CONTEXT_RECORD: 'assign_from_context_record',
  NEWLY_ASSIGNED: 'newly_assigned_owner',
};

export const ownerSubtypes = {
  ROUND_ROBIN_ANY_ROLE: 'round_robin_any_role',
  ROUND_ROBIN_SPECIFIC_ROLE: 'round_robin_specific_role',
  ROUND_ROBIN_CHOOSE_TEAM_MEMBERS: 'round_robin_choose_team_members',
  SPECIFIC_TEAM_MEMBER: 'specific_team_member',
};

export const getOwnerTypeOptions = (t, isForCustomObject) =>
  [
    isForCustomObject && {
      value: ownerTypes.FROM_CONTEXT_RECORD,
      label: t('Assign Owner from Current Record'),
    },
    {
      value: ownerTypes.NEWLY_ASSIGNED,
      label: t('Newly Assigned Owner'),
    },
  ].filter(Boolean);

export const getOwnerSubtypeOptions = (t) => [
  {
    value: ownerSubtypes.ROUND_ROBIN_ANY_ROLE,
    label: t('Round Robin (Any Role)'),
  },
  {
    value: ownerSubtypes.ROUND_ROBIN_SPECIFIC_ROLE,
    label: t('Round Robin (Specific Role)'),
  },
  {
    value: ownerSubtypes.ROUND_ROBIN_CHOOSE_TEAM_MEMBERS,
    label: t('Round Robin Choose Team Members'),
  },
  {
    value: ownerSubtypes.SPECIFIC_TEAM_MEMBER,
    label: t('Specific Team Member'),
  },
];

export const EMPTY_ARRAY = [];
