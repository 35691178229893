import Loader from 'components/Kizen/Loader';
import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

export const PluginCallbackPage = () => {
  const location = useLocation();

  useEffect(() => {
    const queryString = new URLSearchParams(location.search);
    const query = Object.fromEntries(queryString);
    window.parent?.postMessage({ type: 'kizen:plugin_callback', query }, '*');
  }, [location.search]);

  if (window.parent === window) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Loader loading />
    </div>
  );
};
