import { forwardRef } from 'react';

import { getCurrencySymbol } from 'components/Fields/helpers';
import PriceNumberInline from 'components/Inputs/inline/TextInput/presets/PriceNumber';

import { withCellWrapper } from '../helpers';

import { getDataQAForInput } from 'components/Inputs/helpers';

const _EntityValueCell = forwardRef(
  (
    {
      field,
      object: record,
      onSubmit,
      value: valueProp, // remove from orhers as we get value from record
      objectId: modelId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      editable,
      onAutoFocus,
      meta,
      ...others
    },
    ref
  ) => {
    const value = record.entityValue;

    return (
      <PriceNumberInline
        ref={ref}
        {...others}
        field={field}
        object={record}
        value={value?.amount ?? value}
        onSubmit={async (data) => {
          await onSubmit(
            record.id,
            { entityValue: data?.entity_value ?? data },
            column
          );
        }}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        currencySymbol={getCurrencySymbol(field)}
        {...getDataQAForInput(
          `inline-record-field-${field.name}`,
          field.fieldType
        )}
      />
    );
  }
);

export const EntityValueCell = withCellWrapper(_EntityValueCell);
