import { SettingsContentCard } from 'components/Card/Card';
import CardToolbar from 'components/Layout/CardToolbar';
import SmallTableCard from 'components/Layout/SmallTableCard';
import KizenEditableText from 'components/Kizen/EditableText';
import KizenLoader from 'components/Kizen/Loader';
import Switch from 'components/Kizen/Switch';
import { gutters, layers } from 'app/spacing';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { fontSizes, fontWeights } from 'app/typography';
import { grayScale } from 'app/colors';
import TableScrollContainer from 'components/Tables/ScrollContainer';

export const TOOLBAR_HEIGHT = 50;
export const HEADER_HEIGHT = 42;
export const BODY_HEIGHT = 368;
export const FOOTER_HEIGHT = 0;

export const SettingsCardToolbar = styled(CardToolbar)`
  padding: 0 20px;
  margin-top: 0;
`;

export const SubscriptionListCard = styled(SettingsContentCard)`
  height: ${TOOLBAR_HEIGHT + HEADER_HEIGHT + BODY_HEIGHT + FOOTER_HEIGHT}px;
  padding: ${gutters.spacing(4)}px 0;
  margin-bottom: ${gutters.spacing(4)}px;
  position: unset;
`;

export const StyledTableScrollContainer = styled(TableScrollContainer)`
  height: 414px;
  table {
    margin-right: -8px;
  }
`;

export const VariableHeightSmallTableCard = styled(SmallTableCard)`
  height: auto;
`;

export const ItemWrapper = styled.span`
  display: flex;
  width: calc(100% - 30px);
  justify-content: space-between;
  z-index: ${layers.content(0, 2)};
`;

export const ItemCell = styled.span`
  ${({ width, fixed }) =>
    width &&
    (fixed
      ? css`
          flex: 0 0 ${width}px;
          max-width: ${width}px;
        `
      : css`
          flex: 0 1 ${width}px;
          max-width: ${width}px;
          min-width: 1px;
        `)}
  padding-right: ${gutters.spacing(12)}px;
  &:last-of-type {
    padding-right: ${gutters.spacing(0)}px;
    justify-content: flex-end;
  }
  display: flex;
  align-content: center;
`;

export const StyledSwitch = styled(Switch)`
  margin: 0;
  > label {
    margin: 0;
  }
`;

export const Loader = styled(KizenLoader)`
  padding: ${gutters.spacing(20)}px 0 ${gutters.spacing(40)}px;
`;

export const EditableTextWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const EditableText = styled(KizenEditableText)`
  width: 100%;
`;

export const SubscriptionTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  margin-bottom: 3px;
  transition: opacity 0.3s ease-in-out;
  thead {
    z-index: ${layers.content(0, 2)};
    tr {
      th {
        background-color: ${grayScale.white};
        height: ${HEADER_HEIGHT}px;
        position: sticky;
        top: 0;
        z-index: ${layers.content(
          0,
          2
        )}; // Appear above cell contents including dragging
        font-size: ${fontSizes.text};
        font-weight: ${fontWeights.bold};
        color: ${grayScale.dark};
        padding-right: 20px;
        &:nth-last-of-type(2) {
          text-align: right;
          padding-right: 0;
        }
        &:first-of-type {
          width: 52px;
        }
        &:last-of-type {
          width: 40px;
        }
      }
    }
  }
  tbody {
    tr {
      td:first-child {
        padding-left: 20px;
      }
      td:last-child {
        padding-right: 19px;
        span:last-child {
          padding-right: 0;
        }
      }
    }
  }
`;

export const DnDWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PaddedItemCell = styled(ItemCell)`
  && {
    padding-right: ${gutters.spacing(4)}px;
  }
`;

export const SubscriptionHeaderCell = styled.th`
  max-width: ${({ maxWidth }) => maxWidth}px;
`;
