import styled from '@emotion/styled';
import { getFilterSetErrors } from '@kizen/filters/validate';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import Modal, { ModalBody } from 'components/Modals';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useFlashTransition } from 'hooks/useFlashState';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { useFilterVariables, useFilterSetErrors } from 'ts-components/filters';
import {
  AddSaveRow,
  DynamicContentHeader,
  DynamicContentInstructionText,
  DynamicContentSettingsContainer,
} from './ts-components';
import { useClientGroupsQuery } from '__queries/models/client';
import { DefaultContentSettings } from './DefaultContentSettings';
import { DynamicImageContentCard } from './DynamicImageContentCard';
import { isEqual } from './is-equal';
import {
  addRule,
  createInitialState,
  dynamicContentReducer,
  initialState,
  setErrors,
  setState,
  vaidateUrls,
} from './state';
import { validate as validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';
import { getBusinessClientObject } from 'store/authentication/selectors';

const MAX_WIDTH = '1200px';

const Body = styled(ModalBody)`
  padding: 0 20px;
`;

/**
 * State comes from two locations - the useReducer hook from ./state.ts and the useFilter hook usage
 * in each of the DynamicContentCard components. There is an effect in DynamicContentCard to keep the
 * filter data in-sync with the reducer state. Error messages for the image settings (non-filter)  data
 * is stored in the reducer (this is only the url field for each card). Filter error messages are housed
 * here in `filterErrors` - the full filter object with updated `validation` data to trigger the filter
 * input component's error message/styling. Setting the filter prop on DynamicContentCard is how we trigger
 * filter validation. The effects in DynamicContentCard will update the local hook state and reducer state.
 */
export const DynamicContentModal = ({
  defaultImageProps,
  images,
  modalLayer = 0,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const metadata = useSelector(selectFilterMetadataDefinition);
  const { variables } = useFilterVariables();
  const clientObject = useSelector(getBusinessClientObject);
  const { containerWidth, naturalHeight, naturalWidth } = defaultImageProps;
  const initialStateRef = useRef();
  const [state, dispatch] = useReducer(dynamicContentReducer, initialState);
  const [filterErrors, setFilterErrors] = useFilterSetErrors();
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [, showErrors, flashError] = useFlashTransition();
  const { default_content, rules, errors } = state;
  const prevRuleCount = useRef(rules.length);

  const [loading, setLoading] = useState(false);

  const { data: groupOptions = [], isLoading: loadingGroupOptions } =
    useClientGroupsQuery({
      select: (results) =>
        results.map((opt) => {
          return { label: opt.name, value: opt };
        }),
    });

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const init = await createInitialState(
          defaultImageProps,
          metadata,
          variables,
          dispatch,
          queryClient,
          images
        );
        dispatch(setState(init));
        initialStateRef.current = init;
      } finally {
        setLoading(false);
      }
    };

    if (variables.length) {
      load();
    }
  }, [queryClient, metadata, variables, images, defaultImageProps]);

  const handleSave = async () => {
    const imageErrors = vaidateUrls(state, t);
    const hasImageSettingError =
      imageErrors.default_content || imageErrors.rules.some(Boolean);
    const errors = rules.map((rule) => {
      return rule.filterType === 'custom_filter'
        ? getFilterSetErrors(
            rule.filters.map((x) => x.filter),
            isValidPhoneNumber,
            validateEmail.withDomain,
            t,
            {
              hasFilterTypeStep: true,
            }
          )
        : { hasErrors: false };
    });
    const hasFilterError = errors.some((error) => error.hasErrors);

    if (hasImageSettingError) {
      dispatch(setErrors(imageErrors));
      flashError();
    }

    if (hasFilterError) {
      setFilterErrors(errors);
    }

    if (hasFilterError || hasImageSettingError) {
      return;
    }

    await onSave(state);
    onClose();
  };

  const handleClose = () => {
    if (!isEqual(state, initialStateRef.current)) {
      setShowConfirmClose(true);
    } else {
      onClose();
    }
  };

  const scrollLast = rules.length > prevRuleCount.current;
  if (scrollLast) {
    prevRuleCount.current = rules.length;
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <Modal show size="large" onHide={handleClose}>
        <DynamicContentHeader onClose={handleClose} />
        <Body contentWidth={MAX_WIDTH}>
          <DynamicContentInstructionText />
          <DefaultContentSettings
            dispatch={dispatch}
            fileId={default_content.fileId}
            name={default_content.name}
            position={default_content.position}
            size={default_content.size}
            unit={default_content.unit}
            hasLink={default_content.hasLink}
            width={default_content.width}
            height={default_content.height}
            maxWidth={default_content.maxWidth}
            maxHeight={default_content.maxHeight}
            link={default_content.link}
            alt={default_content.alt}
            linkError={errors.default_content}
            showErrors={showErrors}
            dimension={default_content.dimension}
            containerWidth={containerWidth}
            naturalHeight={naturalHeight}
            naturalWidth={naturalWidth}
          />
          <DynamicContentSettingsContainer>
            {rules.map(({ filter, ...rule }, idx, arr) => (
              <DynamicImageContentCard
                key={rule.id}
                index={idx}
                customObjectId={clientObject.id}
                customObjectFetchUrl="client"
                scrollSelf={scrollLast && idx === arr.length - 1}
                cardCount={arr.length}
                groupOptions={groupOptions}
                loadingGroups={loadingGroupOptions}
                dispatch={dispatch}
                isFirst={idx === 0}
                isLast={idx === arr.length - 1}
                linkError={errors.rules[idx]}
                showErrors={showErrors}
                filterErrors={filterErrors?.[idx]?.errors ?? {}}
                filterMetadata={metadata}
                filterVariables={variables}
                modalLayer={modalLayer + 1}
                {...rule}
              />
            ))}
          </DynamicContentSettingsContainer>
        </Body>
        <AddSaveRow onAdd={() => dispatch(addRule())} onSave={handleSave} />
      </Modal>
      {showConfirmClose && (
        <BasicModal
          show
          size="small"
          heading={t('You Have Unsaved Changes')}
          buttonText={t('Discard Changes')}
          actionBtnColor="red"
          onHide={() => setShowConfirmClose(false)}
          onConfirm={() => {
            setShowConfirmClose(false);
            onClose();
          }}
        >
          {t('Unsaved changes will be lost, would you like to continue?')}
        </BasicModal>
      )}
    </>
  );
};
