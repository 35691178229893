import { useEffect, useMemo, useRef, useState } from 'react';
import { SingleFilePicker } from 'components/Inputs/FileInput/Modal/SingleFilePicker';
import { useTranslation } from 'react-i18next';
import { useTypeaheadWithScroll } from 'hooks/useTypeaheadWithScroll';
import ImageService from 'services/ImageService';
import { IMAGES } from 'queries/query-keys';
import { useDebounce } from 'react-use';
import { DEFAULT_DELAY } from 'utility/config';

const OPTIONS = (t) => [
  {
    id: '-created',
    label: t('Newest First'),
  },
  {
    id: 'created',
    label: t('Oldest First'),
  },
  {
    id: 'name',
    label: t('Name A-Z'),
  },
  {
    id: '-name',
    label: t('Name Z-A'),
  },
];

export const ImageLibrary = ({
  show,
  selectedFileId,
  onConfirm,
  onDelete,
  onHide,
  source,
}) => {
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const [sort, setSort] = useState(OPTIONS(t)[0]);
  const [loaded, setLoaded] = useState(false);

  const queryKey = useMemo(() => {
    return IMAGES.SORTED(sort?.id || sort?.value);
  }, [sort]);

  const { search, setSearch, options, isLoading, isFetching } =
    useTypeaheadWithScroll({
      fetch: ImageService.getPublicImages,
      queryKey: queryKey,
      params: { ordering: sort?.id || sort?.value },
      wrapperElement: containerRef.current,
      defaultHeight: 24,
      itemsBuffer: 10,
    });

  useEffect(() => {
    if (show) {
      setLoaded(true);
    }
  }, [show]);

  useDebounce(
    () => {
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }
    },
    DEFAULT_DELAY,
    [search, sort]
  );

  if (!show) {
    return null;
  }

  return (
    <SingleFilePicker
      enableFileBlockHoverOptions
      loaded={loaded}
      ref={containerRef}
      source={source}
      loading={isLoading}
      fetching={isFetching}
      key={`real-picker`}
      files={options}
      selectedFileId={selectedFileId}
      publicFile
      onlyImages
      show={show}
      onConfirm={onConfirm}
      onDelete={onDelete}
      onHide={onHide}
      sortOptions={OPTIONS(t)}
      sort={sort}
      setSort={setSort}
      search={search}
      setSearch={setSearch}
    />
  );
};
