import { getOptionValue } from 'services/helpers';

import {
  setDelayAmount,
  getStageName,
  validStageId,
} from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { fromStage, toStage, delayAmount, ...other } = orgDetails;
  return {
    stepGoal: {
      ...orgDetails,
      delayAmount: setDelayAmount(delayAmount),
      triggers: [
        {
          ...other,
          type: stepConfig.stageUpdated.type,
          triggerStageUpdated: {
            fromStage: getOptionValue(fromStage),
            toStage: getOptionValue(toStage),
          },
        },
      ],
    },
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary, model) => {
  const { fromStage, toStage } = firstTrigger.triggerStageUpdated;
  const type = stepConfig.stageUpdatedGoal.type;
  if (!validStageId(fromStage, model) || !validStageId(toStage, model)) {
    addErrorMessage(messageDictionary.thereWasanErrorLoadingTheAssociatedStage);
  }
  return {
    type,
    fromStage: getStageName(fromStage, model, messageDictionary),
    toStage: getStageName(toStage, model, messageDictionary),
  };
};

export const stageUpdatedGoalDTO = {
  forApi,
  forFlow,
};
