import { useCallback, useContext, useEffect, useMemo } from 'react';
import { PrimaryObjectUploadContext } from '../../context';
import { useFlashTransition } from '__hooks/useFlashState';
import { MatchingRule } from '__pages/SmartConnectors/types';

const ruleErrorsOrder: Partial<Record<keyof MatchingRule, number>> = {
  order: 0,
  field: 1,
  variable: 2,
  no_match_action: 3,
  single_match_action: 4,
  multiple_match_action: 5,
  match_archive_action: 6,
};

export const useMatchingRulesErrors = (index: number) => {
  const {
    loadId,
    stepData,
    errors: { loads = [] },
    setErrors,
  } = useContext(PrimaryObjectUploadContext);

  const [message, showMessage, flashErrorMessage, cancelFlash] =
    useFlashTransition();

  const loadIndex = useMemo(() => {
    return (stepData.loads || []).findIndex(
      (load) => (loadId === 'new' && !load.id) || load.id === loadId
    );
  }, [stepData.loads, loadId]);

  const { matching_rules = [] } = loads[loadIndex] || {};

  const ruleError = matching_rules[index];

  const hasError = Boolean(ruleError && Object.values(ruleError).length);

  const isFirstVariableError =
    hasError &&
    !matching_rules!.some(
      (el, i) =>
        i < index && el && typeof el === 'object' && Object.values(el).length
    );

  const errorKey = useMemo<keyof MatchingRule | null>(() => {
    return isFirstVariableError
      ? (Object.keys(ruleError) as (keyof MatchingRule)[]).sort(
          (a, b) => ruleErrorsOrder[a]! - ruleErrorsOrder[b]!
        )[0]
      : null;
  }, [isFirstVariableError, ruleError]);

  useEffect(() => {
    if (errorKey && ruleError[errorKey]) {
      flashErrorMessage(ruleError[errorKey]);
    }
  }, [ruleError, flashErrorMessage, errorKey]);

  useEffect(() => {
    cancelFlash();
  }, [index, cancelFlash]);

  const clearError = useCallback(
    (prop: keyof MatchingRule) => {
      if (hasError) {
        if (showMessage && errorKey === prop) {
          cancelFlash();
        }

        setErrors((prev) => ({
          ...prev,
          loads: (prev.loads || []).map((load, i) => {
            if (i === loadIndex) {
              return {
                ...load,
                matching_rules: (load.matching_rules || []).map((el, i) => {
                  if (i === index) {
                    const { [prop]: _, ...rest } = el;
                    return rest;
                  }
                  return el;
                }),
              };
            }
            return load;
          }),
        }));
      }
    },
    [showMessage, cancelFlash, hasError, setErrors, errorKey, index, loadIndex]
  );

  const validate = useMemo(
    () => ({ showMessage, message }),
    [showMessage, message]
  );

  return {
    hasError,
    ruleError,
    errorKey,
    validate,
    clearError,
  };
};
