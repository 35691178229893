import { useMemo } from 'react';
import { getQuickFiltersPayload } from '../helpers';

export const useDirtyState = (initialSettings, rightItems) => {
  return useMemo(() => {
    const nextSettings = getQuickFiltersPayload(rightItems);
    return (
      initialSettings.length !== nextSettings.length ||
      initialSettings.some(
        ({ id, label }, i) =>
          id !== nextSettings[i].id || label !== nextSettings[i].label
      )
    );
  }, [initialSettings, rightItems]);
};
