import { useEffect, useState, useRef } from 'react';

export const DEFAULT_DELAY = 300;
export const DEFAULT_INPUT_DELAY = 400;
export const DEFAULT_LONG_INPUT_DELAY = 1000;

export const useDebounce = (
  value: string,
  delay: number = DEFAULT_INPUT_DELAY
): string => {
  const [debouncedValue, setDeboucedValue] = useState<string>(value);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => setDeboucedValue(value), delay);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [value, delay]);

  return debouncedValue;
};
