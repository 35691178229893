import { DISPLAY_VALUE_RESPONSES } from 'components/Wizards/Dashlet/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AreaLine from '..';

const LeadsAddedBySourceAreaLineAdapter = ({
  data,
  dashlet,
  currencySymbol,
  ...rest
}) => {
  const { t } = useTranslation();
  const chartData = useMemo(() => {
    return (
      data?.data.map((series, index) => {
        return {
          id: series.lead_source,
          category: {
            name: series.lead_source,
            id: series.lead_source,
          },
          values: series.values ?? [],
          order: index,
        };
      }) ?? []
    );
  }, [data]);

  const tooltipLabels = useMemo(() => {
    return (
      data?.metadata?.tooltip_label?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.date]: curr.label,
        };
      }, {}) ?? {}
    );
  }, [data]);

  const isValue = dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE;
  const frequency = data?.frequency;

  return (
    <AreaLine
      data={chartData}
      dashlet={dashlet}
      tooltipLabels={tooltipLabels}
      isValue={isValue}
      frequency={frequency}
      currencyMode={isValue}
      yAxisTitle={
        isValue ? `${t('Value')} (${currencySymbol})` : t('# Leads Added')
      }
      currencySymbol={currencySymbol}
      {...rest}
    />
  );
};

export default LeadsAddedBySourceAreaLineAdapter;
