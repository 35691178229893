import PropTypes from 'prop-types';

export const buttonProps = {
  // Button Appearance
  variant: PropTypes.oneOf(['default', 'outline', 'text']),
  // Button Color
  color: PropTypes.string,
  // What Happens When You Click
  onClick: PropTypes.func,
  preventDefault: PropTypes.bool,
  // Whether It should have default spacing
  noSpace: PropTypes.bool,
  // Enabled/Disabled
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export const buttonDefaultProps = {
  className: '',
  variant: 'default',
  color: 'green',
  onClick: () => {},
  preventDefault: true,
  noSpace: false,
  disabled: false,
};
