import React from 'react';
import { Cell } from '../../styles';
import { Row } from './styles';
import StageNameComponent from '../StageName';
import BarCell from '../BarCell';
import KizenTypography from 'app/kizentypo';
import PropTypes from 'prop-types';

export const PERCENT_COLUMN_WIDTH = 34;

const ChartRow = ({
  row,
  index,
  mobile,
  dataForDisplay,
  stageNameWidth,
  handleWidthMeasurementChange,
  maxBarValue,
  openCount,
  valuePrefix,
}) => {
  return (
    <Row
      key={row.id}
      className={`chart-row ${
        index === dataForDisplay.length - 1 ? 'last' : ''
      }`}
      mobile={mobile}
    >
      <Cell widthPixels={stageNameWidth} textAlign="right">
        <StageNameComponent
          onWidthChange={handleWidthMeasurementChange}
          name={row.name}
          mobile={mobile}
        />
      </Cell>
      <Cell widthPixels={PERCENT_COLUMN_WIDTH} textAlign="center">
        <KizenTypography type="label" className={row.status}>
          {Math.round(row.conversionRate)}%
        </KizenTypography>
      </Cell>
      <BarCell
        row={row}
        maxBarValue={maxBarValue}
        index={index}
        openCount={openCount}
        valuePrefix={valuePrefix}
      />
    </Row>
  );
};

ChartRow.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
  dataForDisplay: PropTypes.array.isRequired,
  stageNameWidth: PropTypes.number.isRequired,
  handleWidthMeasurementChange: PropTypes.func.isRequired,
  maxBarValue: PropTypes.number.isRequired,
  openCount: PropTypes.number.isRequired,
  valuePrefix: PropTypes.string,
};

ChartRow.defaultProps = {
  mobile: false,
  valuePrefix: '',
};

export default ChartRow;
