import { optionFilter } from '@kizen/filters/option-filter';
import type { Option, StepData } from '@kizen/filters/types';
import { buildOptions } from '@kizen/filters/utils';
import { get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { SelectOption } from '../inputs/types';
import {
  useFilterQuery,
  useInfiniteFilterQuery,
  EMPTY_ARRAY,
} from './useFilterQuery';

const isPrimitive = (x: any) => {
  return (
    (typeof x === 'string' && x.length > 0) ||
    typeof x === 'number' ||
    typeof x === 'boolean'
  );
};

/**
 * The Select component requires string values - convert `null` / `undefined`  to `''` and stringify boolean values.
 */
export const getSelectValue = (value?: Option['value']) => {
  if (value === null || value === undefined) return '';
  if (typeof value === 'boolean') return String(value);
  return value;
};

const replaceNullAndBoolValues = (options: Option[]) => {
  return options.map((x) => {
    return { ...x, value: getSelectValue(x.value) };
  });
};

const getInitOptionLookupEntries = (
  init: NonNullable<StepData['options']>,
  value_id_path?: string[]
) => {
  return init.map<[string, Option['value']]>(({ value }) => [
    getSelectValue(
      Array.isArray(value_id_path) ? get(value, value_id_path) : value
    ),
    value,
  ]);
};

const getInitialOptions = (
  init: StepData['options'],
  filter: StepData['option_filter'],
  value_id_path?: string[]
) => {
  if (!init) return EMPTY_ARRAY;
  const filtered = filter ? init.filter(optionFilter(filter)) : init;
  const mapped = value_id_path
    ? filtered.map((x) => {
        if (x.value !== null && typeof x.value === 'object') {
          return { ...x, value: get(x.value, value_id_path) };
        }
        return x;
      })
    : filtered;
  return replaceNullAndBoolValues(mapped);
};

const dedupeOptions = (options: SelectOption[]) => {
  const ids = new Set();

  return options.reduce<SelectOption[]>((acc, opt) => {
    if (!ids.has(opt.value)) {
      acc.push(opt);
    }
    ids.add(opt.value);
    return acc;
  }, []);
};

export const useFilterOptions = (
  method: StepData['method'],
  url: StepData['url'],
  result_path: StepData['result_path'],
  value_id_path: StepData['value_id_path'],
  option_paths: StepData['option_paths'],
  option_filter: StepData['option_filter'],
  body: StepData['body'],
  params: StepData['params'],
  init?: StepData['options'],
  selected?: any
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initial] = useState(() => {
    const src = Array.isArray(selected) ? selected : selected ? [selected] : [];
    const mappableSelected = src.filter(
      (x) => x !== null && typeof x === 'object'
    );
    return mappableSelected?.length
      ? buildOptions(mappableSelected, { option_paths, value_id_path })
      : ([[], new Map()] as const);
  });
  // This override ensures a filter step's value is visible on load when it's options come from an API call
  // and there was no save_value specified to build an option for it (which would mean typeof selection === 'object' here).
  const overrideOptionsFetching = url !== undefined && isPrimitive(selected);
  const { data, isLoading } = useFilterQuery(
    method,
    url,
    body,
    params,
    overrideOptionsFetching || isOpen
  );

  const { options, lookup } = useMemo<{
    options: SelectOption[];
    lookup: Map<string, Option['value']>;
  }>(() => {
    if (!data) {
      const entries = [
        ...(init ? getInitOptionLookupEntries(init, value_id_path) : []),
        ...initial[1].entries(),
      ];
      return {
        options: dedupeOptions([
          ...getInitialOptions(init, option_filter, value_id_path),
          ...initial[0],
        ]),
        lookup: new Map(entries),
      };
    }
    const [opts, look] = buildOptions(data, {
      option_filter,
      option_paths,
      result_path,
      value_id_path,
    });
    if (Array.isArray(init)) {
      return {
        options: dedupeOptions(
          replaceNullAndBoolValues(init).concat(opts, initial[0])
        ),
        lookup: new Map([
          ...getInitOptionLookupEntries(init),
          ...look.entries(),
          ...initial[1].entries(),
        ]),
      };
    }
    return {
      options: dedupeOptions([...initial[0], ...opts]),
      lookup: new Map([...initial[1].entries(), ...look.entries()]),
    };
  }, [
    data,
    initial,
    init,
    option_filter,
    option_paths,
    result_path,
    value_id_path,
  ]);

  const onMenuOpen = useCallback(() => setIsOpen(true), []);
  const onMenuClose = useCallback(() => setIsOpen(false), []);

  return {
    isLoading: isOpen && isLoading,
    lookup,
    options,
    onMenuOpen,
    onMenuClose,
  };
};

export const useInfiniteFilterOptions = (
  method: StepData['method'],
  url: StepData['url'],
  result_path: StepData['result_path'],
  value_id_path: StepData['value_id_path'],
  option_paths: StepData['option_paths'],
  option_filter: StepData['option_filter'],
  body: StepData['body'],
  params: StepData['params'],
  init?: StepData['options'],
  selected?: any
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initial] = useState(() => {
    const src = Array.isArray(selected) ? selected : selected ? [selected] : [];
    const mappableSelected = src.filter(
      (x) => x !== null && typeof x === 'object'
    );
    return mappableSelected?.length
      ? buildOptions(mappableSelected, { option_paths, value_id_path })
      : ([[], new Map()] as const);
  });
  // This override ensures a filter step's value is visible on load when it's options come from an API call
  // and there was no save_value specified to build an option for it (which would mean typeof selection === 'object' here).
  const overrideOptionsFetching = url !== undefined && isPrimitive(selected);
  const { data, isLoading, isFetchingNextPage, fetchNextPage } =
    useInfiniteFilterQuery(
      method,
      url,
      body,
      params,
      overrideOptionsFetching || isOpen
    );

  const { options, lookup } = useMemo<{
    options: SelectOption[];
    lookup: Map<string, Option['value']>;
  }>(() => {
    if (!data) {
      const entries = [
        ...(init ? getInitOptionLookupEntries(init, value_id_path) : []),
        ...initial[1].entries(),
      ];
      return {
        options: dedupeOptions([
          ...getInitialOptions(init, option_filter, value_id_path),
          ...initial[0],
        ]),
        lookup: new Map(entries),
      };
    }
    let all_pages = data.pages.flatMap((x) =>
      result_path ? get(x, result_path) : x
    );
    if (selected !== null && typeof selected === 'object') {
      // for dropdowns (not multiselects) we need to ensure the option selected
      // when using a search term does not disappear. Always add the current selection
      // prior to building options (will be deduped below)
      all_pages = all_pages.concat(selected);
    }
    const [initialSelected, initialLookup] = initial;
    const [opts, look] = buildOptions(all_pages, {
      option_filter,
      option_paths,
      value_id_path,
    });
    const deduped = [
      ...new Map(
        opts.concat(initialSelected).map((x) => [x.value, x])
      ).values(),
    ];
    const lookup = new Map([
      ...(Array.isArray(init) ? getInitOptionLookupEntries(init) : []),
      ...initialLookup.entries(),
      ...look.entries(),
    ]);
    const options = Array.isArray(init)
      ? dedupeOptions(replaceNullAndBoolValues(init).concat(deduped))
      : deduped;
    return { options, lookup };
  }, [
    data,
    init,
    initial,
    option_filter,
    option_paths,
    value_id_path,
    result_path,
    selected,
  ]);

  const morePages = data?.pages?.length
    ? Boolean(data.pages[data.pages.length - 1].next)
    : false;

  const onMenuOpen = useCallback(() => setIsOpen(true), []);
  const onMenuClose = useCallback(() => setIsOpen(false), []);

  return {
    isLoading: isOpen && isLoading,
    lookup,
    options,
    fetchNextPage,
    isFetchingNextPage,
    morePages,
    onMenuOpen,
    onMenuClose,
  };
};
