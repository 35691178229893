import { isFlagEnabled } from 'debug/flags';
import { WorkerManager } from './WorkerManager';
import { OnShowToastFn, WorkerContextArgs } from './types';
import { History } from 'history';
import {
  DataAdornment,
  FloatingFrame,
  RoutablePage,
} from 'ts-components/Plugins/PluginContext';
import { MutableRefObject } from 'react';
import { getHash } from 'utility/encode';

const hashArgs = (args: any = {}) => {
  const orderedKeys = Object.keys(args).sort();
  const values = orderedKeys.map((k) => {
    return [k, args[k]];
  });
  const str = JSON.stringify(values);
  return getHash(str);
};

export const runScript = async ({
  scriptBody,
  user,
  business,
  onError,
  setLoadingState,
  clientObject,
  scriptUIRef,
  onStateChange,
  workerName,
  context,
  args,
  history,
  plugin,
  onShowToast,
  onClearToasts,
  terminators,
  executionPlugin,
}: Omit<
  WorkerContextArgs & {
    setLoadingState: (loading: boolean) => void;
    workerName: 'recordDetail' | 'genericPlugin' | 'floatingFramePlugin';
    context?: Record<string, unknown>;
    history?: History;
    plugin?: FloatingFrame | RoutablePage;
    executionPlugin?: DataAdornment;
    onShowToast?: OnShowToastFn;
    onClearToasts?: () => void;
    terminators: MutableRefObject<Record<string, Array<() => void>>>;
  },
  'functionBody' | 'instance' | 'kizenRequest' | 'postFormData'
>) => {
  const isDebug = isFlagEnabled('script-runner-logging');

  let worker: Worker;

  // Webpack detects and bundles workers by precisely matching a statement that looks like this:
  //        new Worker(new URL(<string>, import.meta.url))
  // This means we can't have any part of the building dynamic, and need to create the worker
  // like the following:
  if (workerName === 'recordDetail') {
    worker = new Worker(
      new URL('./workers/recordDetail.worker.ts', import.meta.url),
      {
        type: 'module',
      }
    );
  } else if (workerName === 'genericPlugin') {
    worker = new Worker(
      new URL('./workers/genericPlugin.worker.ts', import.meta.url),
      {
        type: 'module',
      }
    );
  } else if (workerName === 'floatingFramePlugin') {
    worker = new Worker(
      new URL('./workers/floatingFramePlugin.worker.ts', import.meta.url),
      {
        type: 'module',
      }
    );
  } else {
    throw new Error(`Unknown worker name: ${workerName}`);
  }

  return new Promise((resolve) => {
    const hashedScript = getHash(scriptBody);
    const hashedArgs = hashArgs(args);

    let workerId = `${plugin?.plugin_api_name}-${plugin?.api_name}-${hashedScript}-${hashedArgs}`;

    if (executionPlugin) {
      workerId = `${executionPlugin.plugin_api_name}-${executionPlugin.field_type}-${hashedScript}-${hashedArgs}`;
    }

    if (terminators.current[workerId]) {
      terminators.current[workerId]?.forEach((fn) => fn());
      terminators.current[workerId] = [];
    }

    if (!terminators.current[workerId]) {
      terminators.current[workerId] = [];
    }

    terminators.current[workerId].push(() => {
      worker.terminate();
    });

    const instance = new WorkerManager({
      worker,
      done: (preserve: boolean, result?: any) => {
        setLoadingState(false);
        if (!preserve) {
          terminators.current[workerId]?.forEach((fn) => fn());
          terminators.current[workerId] = [];
        }
        resolve(result);
      },
      onError,
      scriptUIRef,
      onStateChange,
      history,
      plugin,
      executionPlugin,
      onShowToast,
      onClearToasts,
    });

    setLoadingState(true);

    instance.run(
      scriptBody,
      {
        ...context,
        user,
        business,
        clientObject,
        isDebug,
      },
      args
    );
  });
};
