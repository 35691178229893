import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from 'react-bootstrap/Overlay';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import { fontSizes } from 'app/typography';
import EditableText from 'components/Kizen/EditableText';
import { ErrorCard } from '../../styles';

const EditableNameText = styled(EditableText)`
  align-self: flex-start;
  margin: 0 ${gutters.spacing(3)}px 0 0;
  min-width: 0;
  max-width: 350px;
  input {
    font-size: ${fontSizes.text};
    padding-left: 0;
    // so we can see the placeholder
    &:placeholder-shown {
      min-width: 120px;
    }
  }
`;

const SurveyNameInput = ({ value, errorMessage, ...rest }) => {
  const ref = useRef();
  const { t } = useTranslation();
  const showError = errorMessage.length > 0;

  return (
    <>
      <EditableNameText
        ref={ref}
        value={value}
        placeholder={t('Enter Name')}
        error={value?.length === 0}
        {...rest}
      />
      <Overlay
        transition={!showError}
        target={ref.current}
        show={showError}
        placement="bottom-start"
      >
        <ErrorCard show={showError}>
          <KizenTypography>{errorMessage}</KizenTypography>
        </ErrorCard>
      </Overlay>
    </>
  );
};

export default SurveyNameInput;

SurveyNameInput.displayName = 'SurveyNameInput';

SurveyNameInput.propTypes = {
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
