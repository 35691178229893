import { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import Select from 'components/Inputs/Select';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';

export const CommentSelect = ({
  hasComment,
  onChange,
  teamMemberCount = 0,
  trigger,
  isShowing,
  setIsShowing,
  ...others
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const selectRef = useRef();

  useEffect(() => {
    if (!hasComment) {
      setValue(null);
    }
  }, [hasComment]);

  const handleApply = useCallback(() => {
    onChange((prev) => ({
      ...prev,
      hasComment: value?.value,
    }));
    setIsShowing(false);
  }, [value, onChange, setIsShowing]);

  const handleClear = useCallback(() => {
    setValue(null);
  }, []);

  return (
    <SelectOverlay
      show={isShowing}
      target={trigger.current}
      onHide={handleApply}
      placement="bottom-end"
      popperConfig={{}}
    >
      <Select
        ref={selectRef}
        value={value}
        onChange={setValue}
        placeholder={t('Search Message Filters')}
        menuLeftButton={<ClearSelectButton onClick={handleClear} />}
        menuRightButton={<ApplySelectButton onClick={handleApply} />}
        endAdornment={<IconAdornment icon="search" />}
        options={[
          {
            value: 'mentions_me',
            label: t('Mentions Me'),
          },
          {
            value: 'any',
            label: t('Has Comments'),
          },
        ]}
        {...others}
      />
    </SelectOverlay>
  );
};
