export const isPercentageChanceToCloseField = (field) => {
  return (
    field?.name === 'percentage_chance_to_close' ||
    field?.customObjectField?.name === 'percentage_chance_to_close'
  );
};

export const isContactTitlesField = (field) => {
  return (
    field?.name === 'titles' || field?.customObjectField?.name === 'titles'
  );
};
