import { getUniqueId } from '@kizen/page-builder/utils/id';
import { getChildren, isDefaultFormField, shouldNodeBeSaved } from '../utils';

export const deleteNode = (nodeId, { actions, query, contextDeleteNode }) => {
  const [childIds] = getChildren(nodeId, query);

  // Call PageBuilderContext's onNodeDelete callback with every child field
  // NOTE: currently linked nodes are only Cells of Rows so those won't need deleting.
  childIds.concat(nodeId).forEach((id) => {
    const n = query.node(id).get();
    contextDeleteNode?.(id, n.data.props.field);
  });

  actions.delete(nodeId);
};

const getDuplicateFieldName = (name) => {
  const match = /(.*)_copy$/.exec(name);

  if (!match) {
    return `${name}_copy`;
  }

  return `${match[1]}_copy`;
};

const getCloneTree = (
  nodeId,
  { isContactForm, query, contextDuplicateNode }
) => {
  // Cloning approach adapted from prevwong/craft.js#209
  const tree = query.node(nodeId).toNodeTree();
  const newNodes = {};

  // Recursively step through tree and assign new ids
  const changeNodeId = (node, newParentId) => {
    const newNodeId = getUniqueId();
    const childNodes = node.data.nodes.map((childId) => {
      return changeNodeId(tree.nodes[childId], newNodeId);
    });

    const linkedEntries = Object.keys(node.data.linkedNodes).map((id) => {
      const newId = changeNodeId(
        tree.nodes[node.data.linkedNodes[id]],
        newNodeId
      );
      return [id, newId];
    });
    const linkedNodes = Object.fromEntries(linkedEntries);
    const field = {
      ...node.data.props.field,
      ...(isDefaultFormField(node.data.props.field) && {
        isRequired: false,
      }),
      id: null,
      name: null,
    };

    if (node.data.props.field?.customObjectField) {
      field.customObjectField = {
        ...node.data.props.field.customObjectField,
        name: getDuplicateFieldName(
          node.data.props.field.customObjectField.name
        ),
      };
    }

    if (shouldNodeBeSaved(node)) {
      contextDuplicateNode(newNodeId, field);
    }

    // ensure there is only one submit button so duplicated submit buttons can be deleted
    const custom = node.data.custom.isSubmitButton
      ? { isSubmitButton: false }
      : {};

    const data = {
      ...node.data,
      custom: { ...node.data.custom, ...custom },
      props: node.data.props.field
        ? { ...node.data.props, field }
        : node.data.props,
      parent: newParentId || node.data.parent,
      nodes: childNodes,
      linkedNodes,
    };

    newNodes[newNodeId] = query
      .parseFreshNode({ ...node, data, id: newNodeId })
      .toNode();

    return newNodeId;
  };

  const rootNodeId = changeNodeId(tree.nodes[tree.rootNodeId]);
  return {
    rootNodeId,
    nodes: newNodes,
  };
};

/**
 * @param {string} nodeId - craftjs id attribute of node
 * @param {object} opts.actions - actions object returned from useEditor hook
 * @param {object} opts.query - query object returned from useEditor hook
 * @param {function} opts.contextDuplicateNode - the `onNodeDuplicate` function from the {@link FormBuilderContext}
 */
export const duplicateNode = (
  nodeId,
  { isContactForm, actions, query, contextDuplicateNode }
) => {
  const node = query.node(nodeId).get();
  const parentNode = query.node(node.data.parent).get();
  const indexToAdd = parentNode.data.nodes.indexOf(nodeId);
  const tree = getCloneTree(nodeId, {
    isContactForm,
    query,
    contextDuplicateNode,
  });
  actions.addNodeTree(tree, parentNode.id, indexToAdd + 1);
};
