import { Location, Position } from './types';

const DISTANCE = 3;

export const getTooltipLocation = (
  position: Position,
  tooltip: DOMRect,
  target: DOMRect
): Location => {
  const halfTooltipWidth = tooltip.width / 2;
  const halfTooltipHeight = tooltip.height / 2;
  const halfTargetWidth = target.width / 2;
  const halfTargetHeight = target.height / 2;

  if (position === 'top') {
    const top = target.y - tooltip.height - DISTANCE;
    const left = target.x - halfTooltipWidth + halfTargetWidth;
    return { top, left };
  }

  if (position === 'right') {
    const top = target.y - halfTooltipHeight + halfTargetHeight;
    const left = target.x + target.width;
    return { top, left };
  }

  if (position === 'left') {
    const top = target.y - halfTooltipHeight + halfTargetHeight;
    const left = target.x - tooltip.width;
    return { top, left };
  }

  // position === 'bottom'
  const top = target.y + target.height + DISTANCE;
  const left = target.x - halfTooltipWidth + halfTargetWidth;
  return { top, left };
};
