import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import TablePagination from 'components/Tables/Big/TablePagination';
import ListCard from 'components/Layout/ListCard';
import BigListLayout, { BigListTitle } from 'components/Layout/BigListLayout';
import { BigListSearchInput } from 'components/Layout/BigListLayout/Toolbar';
import {
  ActivityListItem,
  ActivityListHeader,
  EmptyActivityListItem,
} from './ListItem';
import { ACTIVITY_ACTIONS } from './columns';
import { useSelector } from 'react-redux';
import { selectActivitiesIsFiltering } from 'store/activitiesPage/activities.redux';

export default function ActivitiesListPage({
  activities,
  activitiesCount,
  search,
  onChangeSearch,
  sort,
  onChangeSort,
  pageNumber,
  pageSize,
  onChangePageNumber,
  onChangePageSize,
  onUpdateActivity,
  onDeleteActivity,
  onExportResponses,
  onDuplicateActivity,
  ...others
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const activityActions = ACTIVITY_ACTIONS(t);
  const handleCheckedSelection = ({ value: action }, activity) => {
    const { id } = activity;
    if (action === activityActions.Delete.value) {
      onDeleteActivity({ id });
    }
    if (action === activityActions.Edit.value) {
      history.push(`/activities/${activity.id}`);
    }
    if (action === activityActions.Export.value) {
      onExportResponses(activity);
    }
    if (action === activityActions.Duplicate.value) {
      onDuplicateActivity({ id });
    }
  };

  const isFiltering = useSelector(selectActivitiesIsFiltering);

  return (
    <BigListLayout
      search={
        <BigListSearchInput
          placeholder="Find Activities"
          value={search}
          onChange={onChangeSearch}
          loading={isFiltering}
          isClearable
        />
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={activitiesCount}
          onChangePage={onChangePageNumber}
          onChangePerPage={onChangePageSize}
        />
      }
      {...others}
    >
      <BigListTitle
        count={activitiesCount}
        single="Activity"
        plural="Activities"
      />
      <ListCard>
        <ActivityListHeader
          label="Activity Name"
          sort={sort}
          onChangeSort={(direction) =>
            onChangeSort({ column: 'name', direction })
          }
        />
        {!activities.length && <EmptyActivityListItem />}
        {activities.map((activity) => (
          <ActivityListItem
            key={activity.id}
            activity={activity}
            onSelectAction={(selection) =>
              handleCheckedSelection(selection, activity)
            }
          />
        ))}
      </ListCard>
    </BigListLayout>
  );
}

ActivitiesListPage.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  activitiesCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onSelectPreviewSurvey: PropTypes.func.isRequired,
  onSelectDeleteSurvey: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onUpdateActivity: PropTypes.func.isRequired,
  onDeleteActivity: PropTypes.func.isRequired,
  onExportResponses: PropTypes.func.isRequired,
};
