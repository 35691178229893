import { useNodes } from 'reactflow';
import { useAutomationsSelector } from 'pages/AutomationEngine/store/react';
import { getStepsInMovingBranch } from 'pages/AutomationEngine/store/selectors';
import BasicEdgeBase from '../BasicEdge';

const isLeaf = (id, nodes) => {
  const node = nodes.find((n) => n.id === id);
  return Boolean(node && node.data?.leaf);
};

export function BasicEdgeCore({
  source,
  target,
  placing,
  stepsInMovingBranch = [],
  lastPlacingStep,
  ...props
}) {
  const nodes = useNodes();
  if (
    placing &&
    (source === lastPlacingStep.id || stepsInMovingBranch.includes(source)) &&
    isLeaf(target, nodes)
  ) {
    // When placing a step, we want to hide its following edge
    // if it points to leaf "add button" (the button will hide as well).
    return null;
  }
  return <BasicEdgeBase {...props} source={source} target={target} />;
}

export function BasicEdge({ source, target, ...props }) {
  const placing = useAutomationsSelector((state) => state.placing);
  const stepsInMovingBranch = useAutomationsSelector(getStepsInMovingBranch);
  const lastPlacingStep = useAutomationsSelector(
    (state) => state.lastPlacingStep
  );
  return BasicEdgeCore({
    source,
    target,
    placing,
    stepsInMovingBranch,
    lastPlacingStep,
    ...props,
  });
}
