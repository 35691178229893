import { checkDeleted, nameDescriptor } from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { message, ...others } = orgDetails;

  const stepGoal = {
    ...others,
    triggers: [
      {
        type: stepConfig.emailLinkClicked.type,
        triggerEmailLinkClicked: {
          message: {
            id: message.id,
          },
        },
      },
    ],
  };

  delete orgDetails.type;

  return {
    stepGoal,
    details: orgDetails,
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { message } = firstTrigger.triggerEmailLinkClicked;
  const type = stepConfig.emailLinkClickedGoal.type;

  checkDeleted(messageDictionary, addErrorMessage, message, nameDescriptor);
  return { message, type };
};

export const emailLinkClickedDTO = {
  forApi,
  forFlow,
};
