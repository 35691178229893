import React from 'react';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';

const Container = styled.i`
  margin: 0;
  display: inline-flex;
  align-items: center;
  width: 25px;
`;

// svg taken from https://fontawesome.com/v6.0/icons/font
const FontIcon = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width={width}
    height={height}
    color={color}
  >
    <path
      d="M424 432h-31.44L246.4 47.47c-3.5-9.25-12.51-15.37-22.38-15.46c-.0938 0 .0625 0 0 0c-9.812 0-18.68 5.961-22.34 15.09L47.76 432H24C10.75 432 0 442.8 0 456S10.75 480 24 480h112C149.3 480 160 469.3 160 456S149.3 432 136 432H99.45l35.21-88h173.1l33.44 88H312c-13.25 0-24 10.75-24 24S298.8 480 312 480h112c13.25 0 24-10.75 24-24S437.3 432 424 432zM153.9 296l69.58-173.9L289.5 296H153.9z"
      fill="currentColor"
    />
  </svg>
);

export const FontColorIcon = ({
  color = grayScale.dark,
  underlineColor = grayScale.dark,
  ...rest
}) => (
  <Container {...rest}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
      <FontIcon width="13" height="10" color={color} />
      <rect width="13" height="2" y="11" fill={underlineColor || color} />
    </svg>
  </Container>
);
