import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import { fontSizes, KizenTypography } from 'app/typography';
import BasePill from '.';

const InfoMessage = styled(KizenTypography)`
  font-size: 14px;
  margin-left: ${gutters.spacing(4)}px;
  line-height: 26px;
`;

const PillRow = styled.div`
  font-size: ${fontSizes.text};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

const Col = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Pill = styled(BasePill)`
  margin-left: ${gutters.spacing(4)}px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const LabelContainer = styled.span`
  font-size: ${fontSizes.text};
`;

const Pills = ({
  label,
  pills,
  maxPillWidth,
  noPillsMessage,
  onRemovePill,
  hideRemoveIcon,
  disabled,
  PillComponent,
  ...others
}) => {
  return (
    <PillRow {...others}>
      <Col>
        <LabelContainer>
          {label}:{` `}
        </LabelContainer>
      </Col>
      <Col>
        {pills &&
          pills.map((tag) => (
            <Pill
              key={tag.id}
              id={tag.id}
              name={tag.name}
              maxWidth={maxPillWidth}
              onRemove={(id) =>
                onRemovePill(pills.filter((element) => element.id !== id))
              }
              hideRemoveIcon={hideRemoveIcon}
              disabled={disabled}
              PillComponent={PillComponent}
              {...others}
            />
          ))}
        {pills && !pills.length && (
          <InfoMessage type="text" color={grayScale.mediumDark}>
            {noPillsMessage}
          </InfoMessage>
        )}
      </Col>
    </PillRow>
  );
};

Pills.propTypes = {
  label: PropTypes.string,
  pills: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRemovePill: PropTypes.func,
  maxPillWidth: PropTypes.string,
  noPillsMessage: PropTypes.string,
  hideRemoveIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  PillComponent: PropTypes.elementType,
};

Pills.defaultProps = {
  label: 'Pill',
  noPillsMessage: 'No Pills',
  maxPillWidth: '100%',
  onRemovePill: () => {},
  hideRemoveIcon: true,
  disabled: false,
  PillComponent: null,
};

export default Pills;
