/* eslint-disable no-underscore-dangle */
import { useRef, useState, useMemo, forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import produce from 'immer';
import { useTranslation } from 'react-i18next';

import EmailService from 'services/EmailService';
import DragAndDropLayout from 'components/DragAndDropLayout';
import DragItem from 'components/DragAndDropLayout/DragItem';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import SubscriptionListModal from 'components/Modals/SubscriptionList';
import { getOriginalError } from 'services/AxiosService';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

import {
  CardToolbarButton,
  CardToolbarTitle,
} from 'components/Layout/CardToolbar';

import ListItem, { subscriptionActions } from './ListItem';
import { TOAST_DISPLAY, ERROR_STATUS } from './helpers';
import columns from './columns';
import {
  SubscriptionListCard,
  SettingsCardToolbar,
  SubscriptionTable,
  StyledTableScrollContainer,
  DnDWrapper,
  SubscriptionHeaderCell,
} from '../styles';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useDirty } from './useDirty';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const findSubscriptionIndex = (list, id) => {
  return list.findIndex((item) => item.id === id);
};

const SubscriptionHeader = forwardRef(({ children }, ref) => {
  return (
    <thead ref={ref}>
      <tr>
        <th>&nbsp;</th>
        {children}
        <th>&nbsp;</th>
      </tr>
    </thead>
  );
});

const SubscriptionList = ({
  element,
  onUpdate,
  onAction,
  list,
  uniqueNames,
  canDelete,
  ...others
}) => {
  const [forceToTop, setForceToTop] = useState(false);

  return (
    <DragItem isHidden={element.hidden} forceToTop={forceToTop} {...others}>
      <ListItem
        element={element}
        onUpdate={onUpdate}
        onAction={onAction}
        setForceToTop={(state) => {
          setForceToTop(state);
        }}
        list={list}
        uniqueNames={uniqueNames}
        canDelete={canDelete}
      />
    </DragItem>
  );
};

SubscriptionList.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  uniqueNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const SubscriptionLists = ({
  subscriptionList,
  setSubscriptionList,
  updateSubscriptions,
  access,
}) => {
  const { t } = useTranslation();

  const wrapperRef = useRef();
  const [showToast] = useToast();
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);

  const {
    showSubscriptionListModal,
    setShowSubscriptionListModal,
    showConfirmation,
    onHideConfirmed,
    onHideCanceled,
    onCheckConfirm,
  } = useDirty();

  const [context, setContext] = useState({});

  const uniqueNames = useMemo(() => {
    return subscriptionList.map(({ name }) => name);
  }, [subscriptionList]);

  const handleDragEnd = async (val) => {
    setSubscriptionList(val);
    await EmailService.setSubscriptionsOrdering(val);
    updateSubscriptions();
  };

  const handleUpdate = async (
    subscription,
    patch,
    toastDisplay = TOAST_DISPLAY.FAIL_ONLY
  ) => {
    const returnStatus = {
      status: ERROR_STATUS.SUCCESS,
      message: '',
    };

    const update = { ...subscription, ...patch };

    const nextSubscriptionList = produce(subscriptionList, (draft) => {
      const index = findSubscriptionIndex(draft, subscription.id);
      if (index !== -1) {
        draft[index] = update;
      }
    });

    try {
      setSubscriptionList(nextSubscriptionList);
      await EmailService.updateSubscription(update);
      if (
        toastDisplay === TOAST_DISPLAY.SUCCESS_ONLY ||
        toastDisplay === TOAST_DISPLAY.ALWAYS
      ) {
        showToast({
          variant: toastVariant.SUCCESS,
          message: t('The subscription list was successfully updated.'),
        });
      }
      return returnStatus;
    } catch (error) {
      const orig = getOriginalError(error);
      if (
        orig &&
        orig.__all__ &&
        orig.__all__.length > 0 &&
        orig.__all__[0].startsWith(
          'This name matches another subscription list'
        )
      ) {
        // eslint-disable-next-line prefer-destructuring
        returnStatus.message = orig.__all__[0];
        returnStatus.status = ERROR_STATUS.DUPLICATE_NAME;
      } else {
        returnStatus.message = t(
          'The subscription list could not be updated. Please try again or contact Kizen support.'
        );
        returnStatus.status = ERROR_STATUS.GENERAL_FAIL;
      }

      if (
        toastDisplay === TOAST_DISPLAY.FAIL_ONLY ||
        toastDisplay === TOAST_DISPLAY.ALWAYS
      ) {
        showToast({
          variant: toastVariant.FAILURE,
          message: returnStatus.message,
        });
      }
      return returnStatus;
    } finally {
      updateSubscriptions();
    }
  };

  const handleCreate = async (
    subscription,
    toastDisplay = TOAST_DISPLAY.FAIL_ONLY
  ) => {
    const returnStatus = {
      status: ERROR_STATUS.SUCCESS,
      message: '',
    };

    try {
      await EmailService.createSubscription(subscription);
      if (
        toastDisplay === TOAST_DISPLAY.SUCCESS_ONLY ||
        toastDisplay === TOAST_DISPLAY.ALWAYS
      ) {
        showToast({
          variant: toastVariant.SUCCESS,
          message: t('The subscription list was successfully created.'),
        });
      }
      return returnStatus;
    } catch (error) {
      const orig = getOriginalError(error);
      if (
        orig &&
        orig.__all__ &&
        orig.__all__.length > 0 &&
        orig.__all__[0].startsWith(
          'This name matches another subscription list'
        )
      ) {
        // eslint-disable-next-line prefer-destructuring
        returnStatus.message = orig.__all__[0];
        returnStatus.status = ERROR_STATUS.DUPLICATE_NAME;
      } else {
        returnStatus.message = t(
          'The subscription list could not be created. Please try again or contact Kizen support.'
        );
        returnStatus.status = ERROR_STATUS.GENERAL_FAIL;
      }

      if (
        toastDisplay === TOAST_DISPLAY.FAIL_ONLY ||
        toastDisplay === TOAST_DISPLAY.ALWAYS
      ) {
        showToast({
          variant: toastVariant.FAILURE,
          message: returnStatus.message,
        });
      }
      return returnStatus;
    } finally {
      updateSubscriptions();
    }
  };

  const handleAction = ({ value, element }) => {
    const { id } = element;
    switch (value) {
      case subscriptionActions(t).edit.value: {
        setContext({
          title: t('Edit Subscription List'),
          subscription: element,
        });
        setShowSubscriptionListModal(true);
        break;
      }
      default: {
        setSubscriptionToDelete(id);
      }
    }
  };

  const handleDelete = async () => {
    try {
      const id = subscriptionToDelete;
      setSubscriptionToDelete(null);
      await EmailService.deleteSubscription(id);
      showToast({
        variant: toastVariant.SUCCESS,
        message: t('The subscription was deleted successfully.'),
      });
    } catch (error) {
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'The deleted was not successful. Please try again or contact Kizen support.'
        ),
      });
    } finally {
      updateSubscriptions();
    }
  };

  const handleNewUpdate = async (subscription, patch, toastDisplay) => {
    if (subscription) {
      return handleUpdate(subscription, patch, toastDisplay);
    }
    return handleCreate(patch, toastDisplay);
  };

  return (
    <>
      <SubscriptionListCard>
        <SettingsCardToolbar>
          <CardToolbarTitle>{t('Subscription Lists')}</CardToolbarTitle>
          <CardToolbarButton
            onClick={() => {
              setContext({
                title: t('Add Subscription List'),
                subscription: null,
              });
              setShowSubscriptionListModal(true);
            }}
          >
            {`+ ${t('Add Subscription List')}`}
          </CardToolbarButton>
        </SettingsCardToolbar>

        <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
          <ScrollContainerBlocker />
        </ScrollContainerBlockerWrapper>
        <StyledTableScrollContainer
          compactHeader
          outlineBorder
          left
          right
          bottom
          refresh={[subscriptionList]}
        >
          <SubscriptionTable>
            <SubscriptionHeader>
              {Object.values(columns(t)).map((column) => (
                <SubscriptionHeaderCell
                  key={column.id}
                  width={column.fixed ? column.width : null}
                  maxWidth={column.width}
                  fixed={column.fixed}
                >
                  <div>
                    <TextEllipsisWithTooltip weight="bold">
                      {column.label}
                    </TextEllipsisWithTooltip>
                  </div>
                </SubscriptionHeaderCell>
              ))}
            </SubscriptionHeader>

            <tbody>
              <tr>
                <td colSpan={Object.keys(columns(t)).length + 2}>
                  <DnDWrapper ref={wrapperRef}>
                    <DragAndDropLayout
                      items={subscriptionList}
                      parentRef={wrapperRef}
                      countItems={subscriptionList.length}
                      onChange={handleDragEnd}
                      itemClassName="dndItemWrapper"
                      handleClassName="itemTarget"
                      itemElement={
                        <SubscriptionList
                          onUpdate={handleUpdate}
                          onAction={handleAction}
                          uniqueNames={uniqueNames}
                          canDelete={access.subscriptionLists.remove}
                        />
                      }
                    />
                  </DnDWrapper>
                </td>
              </tr>
            </tbody>
          </SubscriptionTable>
        </StyledTableScrollContainer>
      </SubscriptionListCard>
      <ConfirmDeletionModal
        show={Boolean(subscriptionToDelete)}
        onConfirm={handleDelete}
        onHide={() => setSubscriptionToDelete(null)}
      >
        {t(
          'This will permanently delete the subscription list and remove it from filters, contacts, and broadcasts.'
        )}
      </ConfirmDeletionModal>
      {showSubscriptionListModal && (
        <>
          <SubscriptionListModal
            show
            context={context}
            uniqueNames={uniqueNames}
            onHide={onCheckConfirm}
            onSave={handleNewUpdate}
          />
          <ConfirmationModal
            heading={t('You Have Unsaved Changes')}
            buttonText={t('Discard Changes')}
            defaultLeftBtnText={t('Cancel')}
            actionBtnColor="red"
            show={showConfirmation}
            onConfirm={onHideConfirmed}
            onHide={onHideCanceled}
          >
            {t('Unsaved changes will be lost, would you like to continue?')}
          </ConfirmationModal>
        </>
      )}
    </>
  );
};

SubscriptionLists.propTypes = {
  subscriptionList: PropTypes.array.isRequired,
  setSubscriptionList: PropTypes.func.isRequired,
  updateSubscriptions: PropTypes.func.isRequired,
};

export default SubscriptionLists;
