import {
  AREA_RESPONSES,
  DISPLAY_VALUE_RESPONSES,
  REPORT_TYPES,
} from 'components/Wizards/Dashlet/types';
import usePipelineMetadata from 'pages/Dashboard/hooks/usePipelineMetadata';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Trend from '..';

const getMetricMovementWord = (isAdded, isWon, t) => {
  if (isAdded) {
    return t('Added');
  }
  if (isWon) {
    return t('Won');
  }
  return t('Lost');
};

const TrendDashletAdapter = ({ dashlet, data, ...rest }) => {
  const { t } = useTranslation();

  const isValue = dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE;
  const isAdded =
    dashlet?.config?.reportType === REPORT_TYPES.RECORDS_ADDED ||
    dashlet?.config?.reportType === REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS;
  const isWon = dashlet?.config?.reportType === REPORT_TYPES.RECORDS_WON;

  const chartData = useMemo(() => {
    if (data?.data) {
      return data.data.find((d) => d.type === 'values')?.values ?? [];
    }
    return [];
  }, [data]);

  const frequency = data?.frequency;

  const tooltipLabels = useMemo(() => {
    return (
      data?.metadata?.tooltip_label?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.date]: curr.label,
        };
      }, {}) ?? {}
    );
  }, [data]);

  const [pipeline, currencySymbol] = usePipelineMetadata(data);

  const yAxisTitle = useMemo(() => {
    const overrideTitle =
      dashlet?.config?.reportType ===
        REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS &&
      dashlet?.config?.entityType === AREA_RESPONSES.ACTIVITIES
        ? t('# Submissions')
        : undefined;

    if (overrideTitle) {
      return overrideTitle;
    }

    if (dashlet?.config?.entityType === AREA_RESPONSES.EMAILS) {
      if (dashlet.config.reportType === REPORT_TYPES.EMAILS_SENT) {
        return t('# Emails Sent');
      }
      if (dashlet.config.reportType === REPORT_TYPES.COMPLAINT_PERCENT) {
        return t('Complaint %');
      }
      if (dashlet.config.reportType === REPORT_TYPES.DELIVERY_PERCENT) {
        return t('Delivery %');
      }
      if (dashlet.config.reportType === REPORT_TYPES.OPT_OUT_PERCENT) {
        return t('Opt Out %');
      }
    }

    if (dashlet?.config?.entityType === AREA_RESPONSES.MARKETING) {
      if (dashlet.config.reportType === REPORT_TYPES.LEADS_ADDED) {
        return isValue ? `${currencySymbol} ${t('Value')}` : t('Leads Added');
      }
    }
    return `${pipeline?.entityName ?? ''} ${
      isValue ? `${currencySymbol} ${t('Value')}` : t('Records')
    } ${getMetricMovementWord(isAdded, isWon, t)}`;
  }, [dashlet, t, isAdded, isValue, isWon, pipeline, currencySymbol]);

  const isPercentage = useMemo(() => {
    return [
      REPORT_TYPES.COMPLAINT_PERCENT,
      REPORT_TYPES.DELIVERY_PERCENT,
      REPORT_TYPES.OPT_OUT_PERCENT,
    ].includes(dashlet?.config?.reportType);
  }, [dashlet]);

  return (
    <Trend
      dashlet={dashlet}
      {...rest}
      data={chartData}
      tooltipLabels={tooltipLabels}
      yAxisTitle={yAxisTitle}
      currencySymbol={currencySymbol}
      frequency={frequency}
      isPercentage={isPercentage}
      id={dashlet.id}
      isValue={isValue}
    />
  );
};

export default TrendDashletAdapter;
