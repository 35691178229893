import { forwardRef } from 'react';
import format from 'date-fns/format';

import KizenTypography from 'app/kizentypo';

import { withCellWrapper, EMPTY_VALUE } from '../helpers';

import { getDataQAForInput } from 'components/Inputs/helpers';
import { useDateTooltip } from 'components/Inputs/hooks/useDateTooltip';

const _ActualCloseDateCell = forwardRef(({ object: record }, ref) => {
  const date = record.actualCloseDate;
  const [dateTooltipProps, dateTooltip] = useDateTooltip({ date });

  return (
    <KizenTypography
      {...getDataQAForInput(`inline-record-field-actual_close_date`)}
      {...dateTooltipProps}
    >
      {dateTooltip}
      {date
        ? `${format(date, 'MM/DD/YYYY')} at ${format(date, 'h:mm A')}`
        : EMPTY_VALUE}
    </KizenTypography>
  );
});

export const ActualCloseDateCell = withCellWrapper(_ActualCloseDateCell);
