import styled from '@emotion/styled';
import { gutters } from 'app/spacing';

export const CHART_ROW_HEIGHT = 50;

export const Row = styled.div`
  display: flex;
  flex-grow: 1;
  height: ${CHART_ROW_HEIGHT}px;
  column-gap: ${({ mobile }) => (mobile ? 10 : 20)}px;
  min-height: ${CHART_ROW_HEIGHT}px;
  margin-right: ${gutters.spacing(2, 1)}px;
  margin-left: ${gutters.spacing(3)}px;

  &.last {
    margin-bottom: ${gutters.spacing(4)}px;
  }
`;
