import { NodeProps, Text, Take } from '../types';

type Props = Partial<NodeProps['Text']>;
type Children = Text['2'] | undefined;
type Args = [] | [Props] | [Children] | [Props, Children];

const destructureArgs = (...args: Args): [Props] | [Props, Children] => {
  if (args[0] === undefined) return [{}];
  if (args.length === 2) return args;
  if (typeof args[0] === 'string') return [{}, args[0]];
  return [args[0]];
};

export function text(): Take<Text, 2>;
export function text(props: Props): Take<Text, 2>;
export function text(children: Children): Text;
export function text(props: Props, children: Children): Text;
export function text(...args: Args): Text {
  const [props, children] = destructureArgs(...args);
  return typeof children === 'string'
    ? ['Text', props, children]
    : ['Text', props];
}

export const Copyright: Text = text(
  '<p style="text-align: center;">Copyright 2021 | Kizen Technologies Inc.</p>'
);

export const CaptureTheirAttention: Text = text(
  `<p><span style="font-size: 18px; color: #1067B3;">Capture Their Attention.</span></p>
   <p><span style="font-size: 24px;"><strong>Attract and engage your ideal buyers.</strong></span></p>
   <p>Seamlessly capture new leads from every touchpoint including website, offline events, ad partners, and in-store interactions. Score leads in real time and intelligently nurture with hyper-personalized communication that decreases customer acquisition cost. Easily hand off qualified leads to sales teams once ready.</p>`
);

export const KnowYourAudience: Text = text(
  `<p><span style="font-size: 18px; color: #1067B3;">Know Your Audience.</span></p>
   <p><span style="font-size: 24px;"><strong>Laser focus outreach to make interactions count.</strong></span></p>
   <p>Effortlessly slice-and-dice your data to micro-segment your contact list for each promotion. Automatically customize content and schedule broadcasts with ease to deliver omnichannel, multi-touch campaigns that hit the mark. Put upsell and cross-sell on autopilot with Zoe's nurturing, remarketing, and suppression skills.</p>`
);
