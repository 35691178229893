import { useTranslation } from 'react-i18next';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
  ActionButtonMenu,
} from 'components/Layout/ListCard';
import { TextEllipsis } from 'components/Kizen/Table';
import { gutters } from 'app/spacing';
import { CO_OBJECT_TYPE_PIPELINE } from 'components/Wizards/CustomObject/utilities';

const NO_VALUE = '—';

export const EmptyCustomObjectListItem = ListCardItem;

export function CustomObjectListItem({
  customObject,
  onSelectAction,
  ...others
}) {
  const { t } = useTranslation();
  const {
    id,
    meta,
    objectName,
    objectType,
    numberOfRecords,
    access,
    entityAccess,
  } = customObject;
  return (
    <ListCardItem
      action={
        access.remove ? (
          <ActionButtonMenu
            sizing="dense"
            above={meta.openMenuAbove}
            color={colorsButton.iconGray}
            onChange={onSelectAction}
            options={[{ value: 'delete', label: t('Delete') }]}
          >
            <Icon icon="three-dot" style={{ width: 15 }} />
          </ActionButtonMenu>
        ) : (
          <div style={{ width: 15 }} /> // empty div for better UI
        )
      }
      actionStyles={{ paddingTop: `${gutters.spacing(2)}px` }}
      {...others}
    >
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis
            type={entityAccess ? 'link' : 'text'}
            to={`/custom-objects/${id}`}
          >
            {objectName || NO_VALUE}
          </TextEllipsis>
        </ListCardCell>
      </ListCardRow>
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis as="span">
            {numberOfRecords.toLocaleString()}{' '}
            {numberOfRecords === 1 ? 'Record' : 'Records'}
          </TextEllipsis>
        </ListCardCell>
        <ListCardCell>
          <TextEllipsis as="span" textTransform="capitalize">
            {objectType === CO_OBJECT_TYPE_PIPELINE ? t('Yes') : NO_VALUE}
          </TextEllipsis>
        </ListCardCell>
      </ListCardRow>
    </ListCardItem>
  );
}

export function CustomObjectListHeader({
  items = null,
  sort = null,
  onChangeSort = null,
}) {
  return (
    <>
      <ListCardHeader compactHeader action={<div style={{ width: 15 }} />}>
        {items
          ? items.map(({ label, sortColumn }) => (
              <ListCardCell key={label}>
                <TextEllipsis as="span" weight="bold">
                  {label}
                </TextEllipsis>
                <ListCardSortButton
                  sort={sort.column === sortColumn ? sort : null}
                  onChangeSort={(direction) =>
                    onChangeSort(direction, sortColumn)
                  }
                />
              </ListCardCell>
            ))
          : null}
      </ListCardHeader>
    </>
  );
}
