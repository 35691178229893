import React from 'react';
import styled from '@emotion/styled';
import { DividerContainer } from '@kizen/page-builder/nodes/containers';

export const StyledDivider = styled.hr`
  border: unset;
  border-top: ${({ borderStyle = 'solid', color = '#A8B3BC', size = '1' }) =>
    `${size}px ${borderStyle} ${color}`};
  background-color: inherit;
  margin-left: 0;
  margin-right: 0;
  width: ${({ width = '100' }) => `${width}%`};
  margin: unset;
`;

export const ViewDivider = (props) => (
  <DividerContainer alignment={props.alignment} {...props}>
    <StyledDivider
      borderStyle={props.borderStyle}
      color={props.color}
      size={props.size}
      width={props.width}
    />
  </DividerContainer>
);
