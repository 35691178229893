import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  getDefaultContactForm,
  getDefaultCustomObjectForm,
  getDefaultThankYouPage,
} from '@kizen/page-builder/utils/build';
import { colorsSecondary } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import BasicModal from 'components/Modals/presets/BasicModal';
import TextInput from 'components/Inputs/TextInput';
import {
  createDefaultPage,
  createDefaultThankYouPage,
} from 'components/PageBuilder/utils';
import { isContactForm } from 'checks/forms';
import FormService from 'services/FormService';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import { TextEllipsis } from 'components/Kizen/Table';
import useFlashState from 'hooks/useFlashState';
import { getChosenBusiness } from 'store/authentication/selectors';
import { allowedRelationshipDropdownOptions } from 'xforms/api/customObjects';
import ModernFormImage from './images/modern.svg?react';
import SplashFormImage from './images/splash.svg?react';
import OpenFormImage from './images/open.svg?react';
import {
  PreReleaseFeature,
  usePreReleaseFeatures,
} from 'hooks/usePreReleaseFeatures';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import Dropdown from 'components/Fields/FieldInput/Dropdown';
import { FIELD_TYPES } from 'utility/constants';
import { getOriginalError } from '__services/AxiosService';

const ModalContents = styled.div`
  min-height: 220px; // Make room for open category selector
  margin-top: ${gutters.spacing(-1)}px;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: ${gutters.spacing(4, -3)}px;
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${gutters.spacing(4)}px;
  button {
    i:hover {
      color: #a8b3bc;
    }
    span:last-of-type {
      margin-top: 15px;
    }
    svg {
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
`;

const ErrorText = styled(KizenTypography)`
  color: ${colorsSecondary.red.dark};
  margin-bottom: ${gutters.spacing(2)}px;
  margin-top: ${gutters.spacing(-3)}px;
`;

const Label = styled(TextEllipsis)`
  margin: ${gutters.spacing(4)}px 0;
`;

const getOptions = (t) => [
  {
    value: 'modern',
    label: t('Modern'),
    image: <ModernFormImage />,
  },
  {
    value: 'splash',
    label: t('Splash'),
    image: <SplashFormImage />,
  },
  {
    value: 'open',
    label: t('Open'),
    image: <OpenFormImage />,
  },
];

export default function AddModal({
  models,
  show,
  onHide,
  onConfirm,
  ...props
}) {
  const { t } = useTranslation();
  const preReleaseFeature = usePreReleaseFeatures();
  const options = getOptions(t);
  const [formName, setFormName] = useState('');
  const [relatedObjectId, setRelatedObjectId] = useState(null);
  const [templateType, setTemplateType] = useState(() => {
    if (preReleaseFeature) return '';
    // The BE requires a template type to be selected. So we will pick the first
    // option on behalf of the user if the template choosing is already disabled
    return options[0].value;
  });
  const [validateMessage, setValidateMessage] = useFlashState(1500, '');
  const [error, setError] = useState(false);
  const chosenBusiness = useSelector(getChosenBusiness);

  const modelOptions = useMemo(() => {
    return models.map(allowedRelationshipDropdownOptions);
  }, [models]);

  const handleSubmit = async () => {
    setValidateMessage('');
    setError(false);
    try {
      const newForm = await FormService.create({
        name: formName,
        relatedObjectId,
        templateType,
      });
      try {
        const businessData = {
          logo: chosenBusiness?.logo?.url
            ? {
                url: chosenBusiness?.logo?.url,
                name: chosenBusiness?.logo?.name,
                id: chosenBusiness?.logo?.id,
              }
            : null,
          name: chosenBusiness?.name,
          primaryColor: chosenBusiness?.primary_color,
        };
        const preview = await FormService.getPreview(newForm.id);
        const fieldNameLookup = preview.fields.reduce((acc, field) => {
          acc[field.name] = field;
          return acc;
        }, {});
        const thankYouPage = await getDefaultThankYouPage(
          'Thank you for submitting the form.',
          businessData
        );
        const defaultForm = await (isContactForm(newForm)
          ? getDefaultContactForm(
              { ...fieldNameLookup.first_name, placeholder: t('Enter Text') },
              { ...fieldNameLookup.last_name, placeholder: t('Enter Text') },
              fieldNameLookup.email,
              businessData
            )
          : getDefaultCustomObjectForm(
              { ...fieldNameLookup.name, placeholder: t('Enter Text') },
              businessData
            ));
        const pages = [
          createDefaultPage(t('Form Page'), defaultForm),
          createDefaultThankYouPage(t('Thank You Page'), thankYouPage),
        ];
        await FormService.update(newForm.id, { formUi: { pages } });
        onConfirm({ ...newForm, formUi: { pages } });
      } catch (err) {
        setError(true);
        await FormService.remove({ id: newForm.id });
      }
    } catch (err) {
      const error = getOriginalError(err);
      setValidateMessage(error?.name?.[0]);
    }
  };

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'name' },
      {
        id: 'form-object',
      },
      ...options.map(({ value }) => ({ id: value })),
    ],
    {},
    () => true
  );

  return (
    <BasicModal
      showLoadingIndicator
      size="medium"
      heading={t('Add Form')}
      onConfirm={handleSubmit}
      onHide={onHide}
      show={show}
      disabled={!formName || !relatedObjectId || !templateType}
      {...props}
    >
      <KeyBoardContext.Provider value={{ assignFieldHandle }}>
        <ModalContents>
          {error && (
            <ErrorText>
              {t('There was an issue creating the form. Please try again.')}
            </ErrorText>
          )}
          <StyledTextInput
            label={t('Form Name')}
            placeholder={t('Enter Name')}
            value={formName}
            onChange={setFormName}
            inModal
            validate={
              validateMessage && {
                message: validateMessage,
                showMessage: true,
                inModal: true,
              }
            }
            {...getKeyListenersProps('name')}
          />
          <Dropdown
            label={t('Choose Form Object')}
            placeholder={t('Choose Object')}
            options={modelOptions}
            value={relatedObjectId}
            onChange={(value) => setRelatedObjectId(value)}
            menuInline
            menuLeftButton={null}
            menuRightButton={null}
            field={{ fieldType: FIELD_TYPES.Dropdown, options: modelOptions }}
            {...getKeyListenersProps('form-object')}
          />
          <PreReleaseFeature>
            <Label>{t('Choose Template')}</Label>
            <ButtonGrid>
              {options.map((option) => (
                <ButtonGroupImageButton
                  {...getKeyListenersProps(option.value)}
                  key={option.value}
                  selected={option.value === templateType}
                  onClick={() => setTemplateType(option.value)}
                  option={option}
                />
              ))}
            </ButtonGrid>
          </PreReleaseFeature>
        </ModalContents>
      </KeyBoardContext.Provider>
    </BasicModal>
  );
}

AddModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  models: PropTypes.array.isRequired,
};
