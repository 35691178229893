import styled from '@emotion/styled';
import { appBackground, grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { hideScrollbarCss, layers, scrollbarCss } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';

export const StyledQuickFiltersLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${grayScale.mediumLight};
  min-height: 42px;
  overflow: auto;
  & > * {
    flex-shrink: 0;
  }
  position: relative;
  top: 0;
  z-index: ${layers.content(0, 1)};
`;

export const StyledQickFiltersHeading = styled(KizenTypography)`
  font-size: 12px;
  text-transform: uppercase;
`;

export const StyledActionButton = styled(IconButton)`
  width: ${({ wd }) => wd || 12}px;
  height: ${({ wd }) => wd || 12}px;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  & > svg {
    height: initial;
    width: ${({ icon }) => (icon === 'pen' ? 14 : 12)}px;
  }
`;

export const ArrowWrapper = styled.div`
  position: sticky;
  top: 0;
  width: 0;
  height: 26px;
  ${({ right }) => (right ? 'right: 0;' : 'left: 0;')}
`;

export const ArrowButton = styled(IconButton)`
  width: 27px;
  height: 26px;
  border-radius: 0;
  ${({ show }) => (show ? '' : 'display: none;')}
`;

export const LeftArrowButton = styled(ArrowButton)`
  justify-content: flex-start;
  background-image: linear-gradient(
    to left,
    ${appBackground}00,
    ${appBackground},
    ${appBackground}
  );
`;

export const RightArrowButton = styled(ArrowButton)`
  justify-content: flex-end;
  background-image: linear-gradient(
    to right,
    ${appBackground}00,
    ${appBackground},
    ${appBackground}
  );
  position: absolute;
  right: 0;
`;

export const StyledArrowIcon = styled(StyledIcon)`
  & > svg {
    width: 9px;
  }
`;

export const StyledQuickFiltersList = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledScrollContainer = styled.div`
  position: relative;
  ${scrollbarCss}
  ${({ scrolling }) => (scrolling ? '' : hideScrollbarCss)}
  margin-bottom: ${({ compensateHeight }) => (compensateHeight ? -4 : 0)}px;
  flex-shrink: 1;
  overflow: auto;
  display: flex;
`;
