import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledCenteredNotice,
  StyledNoSQLNote,
  StyledSQLBoard,
  StyledSQLLoader,
  StyledScriptEditor,
} from './styles';
import { SmartConnectorContext } from '../../context';
import { KizenTypography } from '__app/typography';
import { UseScriptsProps } from '../../hooks/useScripts';
import { SQL_SCRIPT_STATUSES } from '__pages/SmartConnectors/constants';
import { SQLScriptStatus } from '__pages/SmartConnectors/types';
import { Editor } from '@tiptap/react';
import { toHtml } from '../../helpers';
import { StyledStepHeader } from '../styles';
import { SQLScriptsHeader } from './SQLScriptsHeader';

export const SQLScripts = ({
  handleTestExecution,
  handlePublishLiveScript,
  handleDownloadLocalDevPackage,
  executionResult,
  pollingScriptExecution,
  generatingSQLTemplate,
  publishingLiveScript,
  forceReset,
  setForceReset,
}: Omit<UseScriptsProps, 'handleChangeSourceFile'>) => {
  const { t } = useTranslation();
  const { smartConnector, stepData, setStepData } = useContext(
    SmartConnectorContext
  );

  const [view, setView] = useState<SQLScriptStatus>(SQL_SCRIPT_STATUSES.draft);

  const handleChangeUserScript = ({ editor }: { editor: Editor }) => {
    const text =
      editor
        .getJSON()
        .content?.map(
          ({ content }) =>
            content?.reduce((acc, { text }) => acc + (text || ''), '') || ''
        )
        .join('\n') || '';

    setStepData((prev) => ({
      ...prev,
      last_draft_script: { ...prev.last_draft_script, user_script: text },
    }));
  };

  const handleResetToLive = () => {
    setForceReset(forceReset + 1);
    setStepData((prev) => ({
      ...prev,
      last_draft_script: {
        ...prev.last_draft_script,
        config_metadata: smartConnector.live_script?.config_metadata,
        user_script: smartConnector.live_script?.user_script || '',
      },
    }));
  };

  const scriptToShow =
    view === SQL_SCRIPT_STATUSES.draft
      ? stepData.last_draft_script?.user_script
      : smartConnector.live_script?.user_script ||
        t('Publish SQL code for it to appear here.');

  return (
    <>
      <StyledStepHeader type="subheader">
        {t('Execute SQL to Create Output CSV')}
      </StyledStepHeader>
      {stepData.source_file ? (
        <>
          <SQLScriptsHeader
            view={view}
            onChangeView={setView}
            handleTestExecution={handleTestExecution}
            handlePublishLiveScript={handlePublishLiveScript}
            handleDownloadLocalDevPackage={handleDownloadLocalDevPackage}
            executionResult={executionResult}
            pollingScriptExecution={pollingScriptExecution}
            publishingLiveScript={publishingLiveScript}
            liveScript={smartConnector.live_script || null}
            isDraftScriptDirty={
              smartConnector.last_draft_script?.user_script !==
              stepData.last_draft_script?.user_script
            }
            handleResetToLive={handleResetToLive}
          />
          {generatingSQLTemplate ? (
            <StyledSQLBoard>
              <StyledSQLLoader loading size={32} />
              <StyledCenteredNotice>
                {t(
                  'Your upload is being processed and tables are being created. Code will appear here when it is ready for you to code.'
                )}
              </StyledCenteredNotice>
            </StyledSQLBoard>
          ) : (
            <StyledScriptEditor
              key={view + forceReset}
              enableToolbar={false}
              enableResize
              initialValue={toHtml(scriptToShow)}
              initialHeight={300}
              onChange={handleChangeUserScript}
              disabled={view === SQL_SCRIPT_STATUSES.live}
            />
          )}
        </>
      ) : (
        <StyledNoSQLNote>
          <KizenTypography>
            {t(
              'Upload file and Kizen will pre-process and create tables for you to leverage using SQL.'
            )}
          </KizenTypography>
        </StyledNoSQLNote>
      )}
    </>
  );
};
