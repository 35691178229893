import { toastVariant } from 'components/ToastProvider';

export const getToastConfig = (t, history) => {
  return {
    general: {
      error: {
        variant: toastVariant.FAILURE,
        message: t('An error occured.'),
      },
      noPermission: {
        variant: toastVariant.FAILURE,
        delay: 5000,
        message: t(
          'You do not have the appropriate credentials to view this page. Please speak with your administrator.'
        ),
        onClose: () => {
          history.push('/');
        },
      },
    },

    contact: {
      success: {
        variant: toastVariant.SUCCESS,
        message: t('The contact has been saved successfully.'),
      },
      fail: {
        variant: toastVariant.FAILURE,
        delay: 3000,
      },
    },

    customObject: {
      fail: {
        variant: toastVariant.FAILURE,
        delay: 3000,
      },
    },

    automation: {
      success: {
        variant: toastVariant.SUCCESS,
        message: t(`The automation has been started.`),
      },
      error: {
        variant: toastVariant.FAILURE,
        message: t(`The automation could not be started.`),
      },
    },
  };
};
