import {
  CUSTOMIZE_FIELDS_STEP_KEY,
  GO_BACK_KEYS,
} from 'components/Wizards/CustomObject/constants';
import { isContact } from 'utility/fieldHelpers';

export const getLinkToObjectSettings = (model, field) => {
  const isContactObject = isContact(model);
  return `/custom-objects/${model.id}/settings?from=${
    isContactObject ? '/clients' : `/custom-objects/${model.id}`
  }&fromKey=${
    isContactObject ? GO_BACK_KEYS.CONTACTS : GO_BACK_KEYS.OVERVIEW
  }&focusStepKey=${CUSTOMIZE_FIELDS_STEP_KEY}&fieldId=${field.id}`;
};

export const getValue = (value, options) => {
  if (!options) {
    return null;
  }
  return (
    options.find((option) => option.id === value || option.value === value) ||
    null
  );
};

export const getFieldOption = (value, fieldOptions) => {
  const opts = fieldOptions.reduce((accumulator, { options }) => {
    return [...accumulator, ...options];
  }, []);
  return opts.find((o) => o.value === value);
};

export const mapOptions = (options) =>
  (options || []).map(({ value, label, object }) => ({
    value,
    label,
    object,
  }));
