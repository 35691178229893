import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNodes, useReactFlow, useStoreApi, useViewport } from 'reactflow';
import {
  StyledBackgroundCanvas,
  StyledForegroundCanvas,
  StyledTitlesCanvas,
} from '../styles';
import {
  drawCurtains,
  drawSectionBackground,
  drawSectionBorders,
  sectionTitlesDrawer,
} from './draw';
import { DPR, TITLE_HEIGHT, SECTION_PADDING, TITLE_Y } from './constants';
import { green, orange } from '@kizen/kds/Colors';
import { NODE_TYPES } from '__pages/AutomationEngine/Engine/Flow/layout';
import { appBackground } from '__app/colors';
import { getFlowContentSize } from 'ts-components/AutomationEngine/helpers';
import { FLOW_PADDING } from '../../constants';

export const BackgroundCanvas = () => {
  const { t } = useTranslation();

  const bgRef = useRef<HTMLCanvasElement>(null);
  const fgRef = useRef<HTMLCanvasElement>(null);
  const titlesRef = useRef<HTMLCanvasElement>(null);

  const [{ width, height }, setDimentions] = useState({
    width: 0,
    height: 0,
  });

  const nodes = useNodes();

  const { x, y, zoom } = useViewport();
  const { setViewport } = useReactFlow();

  const store = useStoreApi();

  const [firstlineX, secondLineX, firstNodeX, secondNodeX] = useMemo(() => {
    const variableNode = nodes.find((node: any) =>
      [
        NODE_TYPES.connection_intialize_additional_variables,
        NODE_TYPES.additionalVariables,
      ].includes(node.type)
    );

    const uploadNode = nodes.find(
      (node: any) => node.type === NODE_TYPES.upload
    );

    const firstNodeX = variableNode
      ? variableNode.position.x + (variableNode.width || 0)
      : 0;

    const firstlineX = variableNode
      ? Math.round((x + (firstNodeX + SECTION_PADDING) * zoom) * DPR)
      : 0;

    const secondLineX = uploadNode
      ? Math.round((x + (uploadNode.position.x - SECTION_PADDING) * zoom) * DPR)
      : 0;

    return [firstlineX, secondLineX, firstNodeX, uploadNode?.position.x || 0];
  }, [nodes, x, zoom]);

  const drawTitles = useCallback(
    (mousePosition: { x: number; y: number } | null = null) => {
      const titlesCtx = titlesRef.current?.getContext('2d');

      if (titlesRef.current && titlesCtx) {
        titlesCtx.clearRect(
          0,
          0,
          titlesCtx.canvas.width,
          titlesCtx.canvas.height
        );

        sectionTitlesDrawer.drawSectionTitle(
          0,
          titlesCtx,
          t('Extract/Connect'),
          0,
          firstlineX,
          TITLE_Y * DPR,
          mousePosition
        );
        sectionTitlesDrawer.drawSectionTitle(
          1,
          titlesCtx,
          t('Transform'),
          firstlineX,
          secondLineX,
          TITLE_Y * DPR,
          mousePosition
        );
        sectionTitlesDrawer.drawSectionTitle(
          2,
          titlesCtx,
          t('Load'),
          secondLineX,
          titlesRef.current.width,
          TITLE_Y * DPR,
          mousePosition
        );

        if (sectionTitlesDrawer.hovers.some((state) => state)) {
          titlesRef.current.style.cursor = 'pointer';
        } else {
          titlesRef.current.style.cursor = 'auto';
        }
      }
    },
    [t, firstlineX, secondLineX]
  );

  const drawBackground = useCallback(() => {
    const canvas = bgRef.current;
    const ctx = canvas?.getContext('2d');

    if (canvas && ctx) {
      ctx.fillStyle = appBackground;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      drawSectionBackground(
        ctx,
        firstlineX,
        secondLineX - firstlineX,
        green['green-50']
      );

      drawSectionBackground(
        ctx,
        secondLineX,
        canvas.width - secondLineX,
        orange['orange-40']
      );
      drawSectionBorders(ctx, firstlineX, secondLineX);

      const curtainCtx = fgRef.current?.getContext('2d');

      if (curtainCtx && curtainCtx.canvas.width && curtainCtx.canvas.height) {
        curtainCtx.clearRect(
          0,
          0,
          curtainCtx.canvas.width,
          curtainCtx.canvas.height
        );

        drawCurtains(curtainCtx, canvas);
      }

      drawTitles();
    }
  }, [drawTitles, firstlineX, secondLineX]);

  useEffect(() => {
    const resizeCanvas = () => {
      setDimentions({
        width: (bgRef.current?.clientWidth || window.innerWidth) * DPR,
        height: (bgRef.current?.clientHeight || window.innerHeight) * DPR,
      });
    };

    resizeCanvas();

    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  useEffect(() => {
    requestAnimationFrame(drawBackground);
  }, [width, height, drawBackground]);

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = titlesRef.current;
    const rect = canvas?.getBoundingClientRect();

    if (canvas && rect) {
      const x = (e.clientX - rect.left) * DPR;
      const y = (e.clientY - rect.top) * DPR;

      drawTitles({ x, y });
    }
  };

  const handleMouseLeave = () => {
    drawTitles();
  };

  const handleClick = () => {
    const {
      translateExtent: [[minX], [maxX]],
      width: flowWidth,
    } = store.getState();

    const flowContentWidth = getFlowContentSize(minX, maxX, zoom);
    const maxScrollX = flowContentWidth - flowWidth - FLOW_PADDING;

    if (flowContentWidth >= flowWidth + 1) {
      let nextX = FLOW_PADDING;

      switch (sectionTitlesDrawer.hovers.findIndex(Boolean)) {
        case 0:
          nextX = FLOW_PADDING;
          break;
        case 1:
          nextX = -Math.min(
            -FLOW_PADDING + (firstNodeX + SECTION_PADDING) * zoom,
            maxScrollX
          );
          break;
        case 2:
          nextX = -Math.min(
            -FLOW_PADDING + (secondNodeX - SECTION_PADDING) * zoom,
            maxScrollX
          );
          break;
        default:
          break;
      }

      setViewport({
        x: nextX,
        y,
        zoom,
      });
    }
  };

  return (
    <>
      <StyledBackgroundCanvas ref={bgRef} width={width} height={height} />
      <StyledForegroundCanvas ref={fgRef} width={width} height={height} />
      <StyledTitlesCanvas
        ref={titlesRef}
        width={width}
        height={TITLE_HEIGHT + 2 * DPR}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      />
    </>
  );
};
