import { useMemo } from 'react';

export const useGroupedFields = (
  fields = [],
  categories = [],
  filterHidden = false
) => {
  const fieldsByCategory = useMemo(() => {
    return fields.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.category]: [...(acc[curr.category] ?? []), curr],
      };
    }, {});
  }, [fields]);

  const groupedOptions = useMemo(() => {
    return categories
      .reduce((collect, category) => {
        const options = (fieldsByCategory[category.id] ?? []).reduce(
          (acc, field) => {
            if (filterHidden && field.isHidden) {
              return acc;
            }
            acc.push({ value: field.id, label: field.displayName });
            return acc;
          },
          []
        );

        if (options.length > 0) {
          collect.push({
            id: category.id,
            label: category.name,
            options,
            order: category.order ?? 0,
          });
        }
        return collect;
      }, [])
      .sort(({ order: a }, { order: b }) => a - b);
  }, [categories, fieldsByCategory, filterHidden]);

  return groupedOptions;
};
