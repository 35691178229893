import { TemplatesPage, AutomationsPage, BroadcastsPage } from './pages';
export const LibraryUrlBase = '/library';

const routes = {
  templates: {
    label: (t) => t('Templates'),
    component: TemplatesPage,
    exact: true,
    icon: 'copy-light',
    path: `${LibraryUrlBase}/templates`,
  },
  broadcasts: {
    label: (t) => t('Broadcasts'),
    component: BroadcastsPage,
    exact: true,
    icon: 'calendar-alt',
    path: `${LibraryUrlBase}/broadcasts`,
  },
  automations: {
    label: (t) => t('Automations'),
    component: AutomationsPage,
    exact: true,
    icon: 'play-light',
    path: `${LibraryUrlBase}/automations`,
  },
};
export default routes;
