/**
 * Command to insert a node fragment consisting of a merge field node and a text node
 * with a single space as its content. Will apply the current editor or selection marks.
 *
 * ProseMirror docs for the Schema's node create function: Node create docs: https://prosemirror.net/docs/ref/#model.NodeType.create
 *
 * @param {{ id: string, label: string, name: string }} attrs - merge field node attributes
 */
export const insertMergeField =
  (attrs, appendMergeFieldExtraSpace) =>
  ({ tr, editor: { schema, state } }) => {
    const marks = state.storedMarks || state.selection.$from.marks();
    const mergeField = schema.nodes.mergeField.create(attrs, '', marks);
    const to = appendMergeFieldExtraSpace
      ? [mergeField, schema.text(' ', marks)]
      : [mergeField];
    tr.curSelection.replaceWith(tr, to);
    return true;
  };
