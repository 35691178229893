import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useEditor } from '@craftjs/core';

import { useBuilderContext } from '../BuilderContext';
import { TraySection, TraySubsection } from '../components/TraySection';
import FileInput from '../../Inputs/FileInput';
import styled from '@emotion/styled';
import { PUBLIC_IMAGE_FILE_UPLOAD_SOURCE } from 'hooks/uploadFiles/useUploadFile';

export const Wrapper = styled.div`
  width: 50%;
`;

export const AttachmentsSettings = ({
  node: {
    id,
    data: { props },
  },
  fileLibraryOpen = false,
}) => {
  const { t } = useTranslation();
  const {
    actions: { setProp },
  } = useEditor();
  const { clearContentSettingsTray } = useBuilderContext();
  const [attachments, setAttachments] = useState(props?.attachments);

  useEffect(() => {
    setAttachments(props?.attachments);
  }, [props]);

  const handleChange = useCallback(
    (files) => {
      setAttachments(files);
      setProp(id, (p) => {
        p.attachments = files;
      });
    },
    [id, setProp]
  );
  return (
    <TraySection
      onBackClick={clearContentSettingsTray}
      collapsable={false}
      header={t('Attachment Settings')}
    >
      <TraySubsection header={t('Attachment')}>
        <Wrapper>
          <FileInput
            label={t('Files')}
            onChange={handleChange}
            files={attachments}
            fileLibraryOpen={fileLibraryOpen}
            variant="underline"
            publicFile
            source={PUBLIC_IMAGE_FILE_UPLOAD_SOURCE}
          />
        </Wrapper>
      </TraySubsection>
    </TraySection>
  );
};

AttachmentsSettings.propTypes = {
  node: PropTypes.object.isRequired,
};
