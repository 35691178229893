import { ActionBar } from '@kizen/kds/ActionBar';
import { ActionGroup } from '@kizen/kds/ActionGroup';
import { Button } from '@kizen/kds/Button';
import { COLUMN_SIZE, Column, Grid } from '@kizen/kds/Grid';
import { Panel, VerticalTile } from '@kizen/kds/Tile';
import { Typography } from '@kizen/kds/Typography';
import MultiSelect from 'components/Inputs/MultiSelect';
import TextInput from 'components/Inputs/TextInput';
import BasicModal from 'components/Modals/presets/BasicModal';
import useModal from 'components/Modals/useModal';
import { toastVariant, useToast } from 'components/ToastProvider';
import AddLeadSourceForm from 'pages/ContactDetail/pages/Profile/LeadSources/AddLeadSourceForm';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getOriginalError } from 'services/AxiosService';
import LeadSourceService from 'services/LeadSourceServiceV2';
import { urlValidation } from 'utility/validate';
import LeadSourceDetailsTable from 'pages/ContactDetail/pages/Profile/LeadSources/LeadSourceDetailsTable';
import { useQuery, useQueryClient } from 'react-query';
import { LEAD_SOURCES, TIMELINE } from 'queries/query-keys';
import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';
import { withErrorBoundary } from 'ts-components/RecordLayout/blocks/ErrorBoundary';

const getLeadSourceTypes = (leadSourceTypes) =>
  leadSourceTypes.map((i) => ({ label: i, value: i }));

const initialLeadSourceValues = {
  entityRecordId: '',
  type: '',
  referralUrl: '',
  source: '',
  campaign: '',
  medium: '',
  term: '',
  content: '',
};

const isLeadSourceSubmitEnabled = (leadSourceValues, t) => {
  const { type, referralUrl, source, campaign, content, medium, term } =
    leadSourceValues;

  if (!type) {
    return false;
  }
  switch (type) {
    case 'site_referral':
      return referralUrl && urlValidation(t)(referralUrl) === true;

    case 'google_ads':
    case 'facebook_ads':
      return !!(campaign && content && medium && term);
    case 'custom':
    case 'utm':
      return !!source;
    // case 'organic_search':
    // case 'direct_traffic':
    // case 'paid_social':
    default:
      return true;
  }
};
const Block = ({
  leadSourceInfo,
  id,
  refetch,
  displayName,
  contact,
  customObject,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast({ yOffset: 10 });
  const queryClient = useQueryClient();

  const entityRecord = customObject || contact;

  const canEditRecord = Boolean(entityRecord?.access?.edit);

  const leadSourceOptions = useMemo(() => {
    return leadSourceInfo?.leadSourceTypes.map(({ label }) => label);
  }, [leadSourceInfo]);

  const [leadSourceValues, setLeadSourceValues] = useState({
    ...initialLeadSourceValues,
    entityRecordId: id,
  });

  const [addLeadSourceModalProps, , addLeadSourceModal] = useModal({
    handleSubmit: async () => {
      await LeadSourceService.create(leadSourceValues);
      await refetch?.();
      queryClient.invalidateQueries(LEAD_SOURCES.TABLE(id));
      queryClient.invalidateQueries(TIMELINE.RECORD(id));
      addLeadSourceModal.hide();
      showToast({
        message: t('Lead source was successfully created'),
        variant: toastVariant.SUCCESS,
      });
    },
    handleHide: () => {
      setLeadSourceValues({
        ...initialLeadSourceValues,
        entityRecordId: id,
      });
    },
    handleError: (error) => {
      const values = Object.values(getOriginalError(error) || {});
      const message = values.length
        ? values[0][0].message
        : t(
            'The lead source could not be created. Please try again or contact Kizen support.'
          );
      showToast({
        message,
        variant: toastVariant.FAILURE,
      });
    },
  });

  const { data: leadSources = [] } = useQuery({
    queryKey: LEAD_SOURCES.TABLE(id),
    queryFn: async () => {
      const leadSources = await LeadSourceService.getAll(id, {
        ordering: '-created',
      });

      return leadSources.results;
    },
  });

  const [leadSourceDetailsModalProps, , leadSourceDetailsModal] = useModal({
    handleSubmit: addLeadSourceModal.show,
    closeOnSubmit: false,
  });

  const head = (
    <ActionBar
      leftChildren={
        <Typography
          size="lg"
          variant="header"
          weight="semibold"
          color="font-primary"
        >
          {displayName || t('Lead Info')}
        </Typography>
      }
      rightChildren={
        <ActionGroup>
          <Button
            variant="text"
            size="small"
            color="secondary"
            onClick={leadSourceDetailsModal.show}
            qa={{
              action: 'view-lead-source-details',
            }}
          >
            {t('View Details')}
          </Button>
          {canEditRecord ? (
            <Button
              color="primary"
              leftIcon="action-add"
              variant="text"
              size="small"
              onClick={addLeadSourceModal.show}
              qa={{
                action: 'add-lead-source',
              }}
            >
              {t('Add Source')}
            </Button>
          ) : null}
        </ActionGroup>
      }
    />
  );

  const body = (
    <>
      <Grid
        subgrid
        config={[
          {
            id: 'lead-info',
            content: [
              <Column key="c1" gap={15}>
                <div>
                  <Typography variant="label" size="sm" weight="semibold">
                    {t('First Source')}
                  </Typography>
                  <TextInput
                    value={
                      leadSourceInfo?.firstLeadSource?.Display?.type ??
                      emptyLongDash
                    }
                    onChange={() => {}}
                    readOnly
                    shrink
                    variant="underline"
                  />
                </div>
                <div>
                  <Typography variant="label" size="sm" weight="semibold">
                    {t('Last Source')}
                  </Typography>
                  <TextInput
                    value={
                      leadSourceInfo?.lastLeadSource?.Display?.type ??
                      emptyLongDash
                    }
                    onChange={() => {}}
                    readOnly
                    shrink
                    variant="underline"
                  />
                </div>
              </Column>,
              <Column key="c2" gap={15}>
                <div>
                  <Typography variant="label" size="sm" weight="semibold">
                    {t('All Sources')}
                  </Typography>
                  <MultiSelect
                    fullWidth
                    value={getLeadSourceTypes(leadSourceOptions)}
                    onChange={() => {}}
                    variant="underline"
                    isReadOnly
                    PillComponent={null}
                    hideRemoveIcon
                    endAdornment={<i />} // here just to clear the endAdornment
                    kdsCompatability
                  />
                </div>
              </Column>,
            ],
            layout: [COLUMN_SIZE.THIRD_WIDTH, COLUMN_SIZE.TWO_THIRD_WIDTH],
          },
        ]}
        gap={15}
      />
      <BasicModal
        heading={t('Add Lead Source')}
        buttonText={t('Save')}
        disabled={!isLeadSourceSubmitEnabled(leadSourceValues, t)}
        leftBtn={null}
        {...addLeadSourceModalProps}
      >
        <AddLeadSourceForm
          values={leadSourceValues}
          onChange={(field, value) => {
            if (field === 'type') {
              setLeadSourceValues({
                ...initialLeadSourceValues,
                entityRecordId: id,
                campaign: '',
                medium: '',
                term: '',
                content: '',
                [field]: value,
              });
            } else if (field === 'campaign') {
              const result = {
                ...leadSourceValues,
                entityRecordId: id,
                [field]: value?.campaign || value,
                medium: '',
                term: '',
                content: '',
              };
              if (value.source) {
                result.source = value.source;
              }
              setLeadSourceValues(result);
            } else if (field === 'medium') {
              setLeadSourceValues({
                ...leadSourceValues,
                entityRecordId: id,
                [field]: value,
                term: '',
                content: '',
              });
            } else if (field === 'term') {
              setLeadSourceValues({
                ...leadSourceValues,
                entityRecordId: id,
                [field]: value,
                content: '',
              });
            } else {
              setLeadSourceValues({ ...leadSourceValues, [field]: value });
            }
          }}
        />
      </BasicModal>
      <BasicModal
        buttonText={t('Add Lead Source')}
        actionButton={canEditRecord}
        heading={t('Lead Source Details')}
        defaultLeftBtnText={t('Cancel')}
        size="large"
        {...leadSourceDetailsModalProps}
        displayFlex
      >
        <LeadSourceDetailsTable recordId={id} initData={leadSources} />
      </BasicModal>
    </>
  );

  return (
    <VerticalTile
      gap={0}
      padding={0}
      middle={<Panel padding={20}>{body}</Panel>}
      top={<Panel padding={[20, 20, 0, 20]}>{head}</Panel>}
    />
  );
};

export const LeadInfo = withErrorBoundary(Block);
