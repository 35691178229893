export const NULL_FREQUENCY = 'not_specified';

export const frequencyOptions = (t) => [
  { value: NULL_FREQUENCY, label: t('Not specified') },
  { value: 'daily', label: t('Daily') },
  { value: 'several_weekly', label: t('A few per week') },
  { value: 'weekly', label: t('One per week') },
  { value: 'several_monthly', label: t('A few per month') },
  { value: 'monthly', label: t('One per month') },
  { value: 'several_yearly', label: t('A few per year') },
  { value: 'yearly', label: t('One per year') },
];

export const A_FEW_PER_MONTH_INDEX = 4;

// handle null for Not Specified
export const toOption = (value) => {
  return value || NULL_FREQUENCY;
};

export const toValue = (value) => {
  return value === NULL_FREQUENCY ? null : value;
};

export const TOAST_DISPLAY = {
  NONE: 'none',
  FAIL_ONLY: 'fail_only',
  SUCCESS_ONLY: 'success_only',
  ALWAYS: 'allways',
};

export const ERROR_STATUS = {
  SUCCESS: 'success',
  DUPLICATE_NAME: 'DUPLICATE_NAME',
  GENERAL_FAIL: 'GENERAL_FAIL',
};
