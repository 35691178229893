import { isEqual } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { redSequence, neutral } from '@kizen/kds/Colors';
import { Typography } from '@kizen/kds/Typography';
import { collapseNumber, ensureCents, formatDecimal } from 'utility/numbers';
import { sanitizeTextForCharts } from '../ChartManager';
import CustomLegend from '../components/CustomLegend';
import { getPendingDefaultValue } from '../helpers';
import useRecomputingLegendWithNegatives from '../hooks/useRecomputingLegendWithNegatives';
import { ChartLayout, StyledLoader } from '../Trend/styles';
import { colors } from './DonutChartManager';
import NestedDonutChartManager from './NestedDonutChartManager';

import {
  TotalValue,
  Chart,
  ChartContainer,
  StyledExplanation,
  TotalStyled,
} from './styles';

const getSummaryValue = (
  isValue,
  currencySymbol,
  breakdownCurrencySymbol,
  forceDecimal,
  chartTotal
) => {
  const negativeSymbol = chartTotal < 0 ? '-' : '';

  if (isValue) {
    return `${negativeSymbol}${currencySymbol}${ensureCents(
      collapseNumber(Math.abs(chartTotal))
    )}`;
  }

  if (breakdownCurrencySymbol) {
    return `${negativeSymbol}${breakdownCurrencySymbol}${ensureCents(
      collapseNumber(Math.abs(chartTotal))
    )}`;
  }

  if (forceDecimal) {
    return formatDecimal(chartTotal);
  }

  return collapseNumber(chartTotal);
};

const DonutChart = ({
  isLoading,
  data = [],
  mobile,
  descriptor,
  legendRows,
  isValue,
  currencySymbol,
  small,
  noSummary = false,
  skipSorting = false,
  getLabel,
  wide,
  breakdownCurrencySymbol = '',
  forceDecimal = false,
}) => {
  const chartRef = useRef();
  const { t } = useTranslation();
  const [chartRenderKey, setChartRenderKey] = useState(() => Date.now());
  const [chartPending, setChartPending] = useState(getPendingDefaultValue);
  const [chartRendered, setChartRendered] = useState(false);

  const positiveColors = useMemo(() => {
    // if there are exactly 10 items, we don't want to create an "other" bucket. This can be easily
    // done by adding an additional color so that the 10th bucket gets labeled correctly.
    if (data?.length === 10) {
      return [...colors, colors[colors.length - 1]];
    }
    return colors;
  }, [data]);

  const negativeColors = useMemo(() => {
    return redSequence.map((colorHex) => ({
      light: colorHex,
    }));
  }, []);

  const {
    legendProps,
    chartData,
    chartTotal,
    primaryTotal,
    secondaryTotal,
    chartColors,
    secondaryChartColors,
  } = useRecomputingLegendWithNegatives(
    data,
    {
      positiveColors,
      negativeColors,
      negativeNeutralColor: neutral,
      fixedWidth: true,
      getLabel,
    },
    legendRows,
    2,
    false,
    skipSorting
  );

  const sanitizedData = useMemo(() => {
    return chartData.positiveBuckets.map((d) => {
      return {
        ...d,
        category: sanitizeTextForCharts(d.category),
        label: sanitizeTextForCharts(d.label),
      };
    });
  }, [chartData]);

  const sanitizedSecondaryData = useMemo(() => {
    return chartData.negativeBuckets.map((d) => {
      return {
        ...d,
        category: sanitizeTextForCharts(d.category),
        label: sanitizeTextForCharts(d.label),
      };
    });
  }, [chartData]);

  useEffect(() => {
    if (chartRef.current) {
      const manager = new NestedDonutChartManager(
        chartRef.current,
        t,
        sanitizedData,
        sanitizedSecondaryData,
        {
          mobile,
          computedColors: chartColors,
          secondaryColors: secondaryChartColors,
          isValue: isValue || Boolean(breakdownCurrencySymbol),
          currencySymbol: currencySymbol || breakdownCurrencySymbol,
          small,
          total: primaryTotal,
          secondaryTotal,
          getLabel,
          wide,
          forceDecimal,
          strokeWidth: 2,
        },
        {},
        () => {
          setChartRendered(true);
          setChartPending(false);
        }
      );

      manager.init();

      return manager.destroy;
    }
  }, [
    chartRenderKey,
    t,
    sanitizedData,
    sanitizedSecondaryData,
    mobile,
    chartColors,
    secondaryChartColors,
    isValue,
    currencySymbol,
    small,
    primaryTotal,
    secondaryTotal,
    chartTotal,
    getLabel,
    wide,
    breakdownCurrencySymbol,
    forceDecimal,
  ]);

  const createChart = useCallback((elem) => {
    if (elem) {
      chartRef.current = elem;
      setChartRenderKey(Date.now());
    }
  }, []);

  const hasNestedDonut = useMemo(
    () => sanitizedSecondaryData.length > 0,
    [sanitizedSecondaryData]
  );

  const total = getSummaryValue(
    isValue,
    currencySymbol,
    breakdownCurrencySymbol,
    forceDecimal,
    chartTotal
  );

  return (
    <ChartLayout>
      {/*
          This absolutely positioned loader is used to keep the chart area hidden until the calculations
          and rendering are done, as they can be quite slow for large datasets and will just
          show a blank white dashlet otherwise
        */}
      <StyledLoader loading={chartPending || isLoading} />
      <ChartContainer>
        <Chart
          data-qa-chart="donut"
          data-qa-chart-pending={chartPending || isLoading || !chartRendered}
          ref={createChart}
        />
        {!noSummary ? (
          <TotalValue data-qa="summary" topMargin={descriptor ? '10' : '0'}>
            <Typography
              size={small && hasNestedDonut ? '2xl' : '3xl'}
              wieght="bold"
            >
              {total}
            </Typography>
            {descriptor ? (
              <TotalStyled fullWidth={hasNestedDonut}>
                <StyledExplanation>
                  <Typography
                    size={small && hasNestedDonut ? 'sm' : 'lg'}
                    weight="medium"
                    truncate
                  >
                    {chartTotal === 1 ? descriptor.singular : descriptor.plural}
                  </Typography>
                </StyledExplanation>
              </TotalStyled>
            ) : null}
          </TotalValue>
        ) : null}
      </ChartContainer>
      {data.length > 0 ? (
        <CustomLegend
          verticalControls
          compact
          {...legendProps}
          mobile={mobile}
          fullWidth
        />
      ) : null}
    </ChartLayout>
  );
};

// getLabel is intentionally not included in the memoization check
// because it should not change between renders
const propsAreEqual = (prevProps, nextProps) => {
  const dataEqual = isEqual(prevProps.data, nextProps.data);
  const dashletEqual = isEqual(prevProps.dashlet, nextProps.dashlet);
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const mobileEqual = prevProps.mobile === nextProps.mobile;
  const descriptorEqual = isEqual(prevProps.descriptor, nextProps.descriptor);
  const legendRowsEqual = prevProps.legendRows === nextProps.legendRows;
  const isValueEqual = prevProps.isValue === nextProps.isValue;
  const currencySymbolEqual =
    prevProps.currencySymbol === nextProps.currencySymbol;
  const smallEqual = prevProps.small === nextProps.small;
  const breakdownCurrencySymbolEqual =
    prevProps.breakdownCurrencySymbol === nextProps.breakdownCurrencySymbol;
  const forceDecimalEqual = prevProps.forceDecimal === nextProps.forceDecimal;

  return (
    dataEqual &&
    dashletEqual &&
    isLoadingEqual &&
    mobileEqual &&
    descriptorEqual &&
    legendRowsEqual &&
    isValueEqual &&
    currencySymbolEqual &&
    smallEqual &&
    breakdownCurrencySymbolEqual &&
    forceDecimalEqual
  );
};

export default React.memo(DonutChart, propsAreEqual);
