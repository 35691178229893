import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import ColorPicker from 'components/Kizen/ColorPicker';
import Icon from 'components/Kizen/Icon';

const ICON_WIDTH = 25;

// 'mousedown' is used instead of 'click' to handle the case when the next immediate click opens a modal (configure link modal)
// As a result, the default toggle-on-click behavior needs to be overriden with the `closedViaMouseDown` ref
const clickAwayEvents = ['mousedown'];

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
`;

const StyledIcon = styled(Icon)`
  justify-content: center;
  padding-right: ${gutters.spacing()}px;
  width: ${ICON_WIDTH}px;
  margin: auto;
`;

const StyledColorPicker = styled(ColorPicker)`
  ${({ offset = 0 }) =>
    offset > 0
      ? css`
          top: -${offset}px;
          left: ${ICON_WIDTH}px;
        `
      : css`
          top: 27px;
        `}
`;

// Note: this is used by components/PageBuilder/settings/DividerToolbar.js
export const ToolbarColorPicker = ({
  color,
  icon,
  align,
  onChange,
  className,
  style,
}) => {
  const pickerRef = useRef();
  const closedViaMouseDown = useRef(false);
  const [showPicker, setShowPicker] = useState(false);
  const [localColor, setLocalColor] = useState(color);
  const [offset, setOffset] = useState(0);

  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        if (entries[0]) {
          const {
            boundingClientRect: { height, top },
            intersectionRatio,
          } = entries[0];
          if (top > 0 && intersectionRatio < 1) {
            setOffset(height - height * intersectionRatio);
          }
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1,
      }
    );
  }, []);

  const refCallback = useCallback(
    (node) => {
      if (pickerRef.current && !node) {
        observer.unobserve(pickerRef.current);
      }
      pickerRef.current = node;
      if (node) {
        observer.observe(pickerRef.current);
      }
    },
    [observer]
  );

  const toggleShowPicker = () => {
    if (!closedViaMouseDown.current) {
      setShowPicker((x) => !x);
    }
    closedViaMouseDown.current = false;
  };

  const iconProps = {
    onClick: toggleShowPicker,
    onMouseEnter: () => (closedViaMouseDown.current = false),
  };

  const handleChange = useCallback(
    (rgba) => {
      setLocalColor(rgba);
      onChange(rgba);
    },
    [onChange, setLocalColor]
  );

  const handleClose = useCallback(() => {
    closedViaMouseDown.current = true;
    setShowPicker(false);
    setLocalColor(undefined);
  }, [setLocalColor, setShowPicker]);

  useEffect(() => {
    setLocalColor(color);
  }, [color]);

  return (
    <Container className={className} style={style}>
      {typeof icon === 'string' ? (
        <StyledIcon icon={icon} color={grayScale.dark} {...iconProps} />
      ) : (
        React.cloneElement(icon, {
          ...iconProps,
          underlineColor: color,
        })
      )}
      {showPicker && (
        <StyledColorPicker
          ref={refCallback}
          align={align}
          clickAwayEvents={clickAwayEvents}
          color={localColor || color}
          onChange={handleChange}
          handleClose={handleClose}
          offset={offset}
        />
      )}
    </Container>
  );
};
