import styled from '@emotion/styled';
import { fontWeights, fontSizes } from 'app/typography';

import { grayScale } from 'app/colors';

const StyledStepperWrapper = styled.div`
  width: 100%;

  > div > div > div > div {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  // overwrite for react-stepper-horizontal-with-classes
  .stepper-circle-completed,
  .stepper-circle-unstarted {
    font-size: ${fontSizes.small};
    font-weight: ${fontWeights.bold} !important;
    line-height: 100% !important;
  }

  .stepper-title-completed,
  .stepper-title-unstarted {
    font-weight: ${fontWeights.regular} !important;
  }
  .stepper-circle-unstarted {
    color: ${grayScale.mediumDark} !important;
  }
`;

export default StyledStepperWrapper;
