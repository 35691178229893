import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useDebounce from 'react-use/lib/useDebounce';
import { DEFAULT_INPUT_DELAY } from 'utility/config';
import ValidateDomain from './sideway-address-vendor';
import TextInput from '../..';

// Default to "normal" domain e.g. example.com
const DEFAULT_DOMAIN_SEGMENTS = 2;

const applyDefaults = ({ isSubdomain, minDomainSegments }) => {
  if (!isSubdomain) {
    return {
      isSubdomain,
      minDomainSegments: Number.isInteger(minDomainSegments)
        ? minDomainSegments
        : DEFAULT_DOMAIN_SEGMENTS,
    };
  }

  return { isSubdomain, minDomainSegments };
};

export const validate = (domain, options) =>
  ValidateDomain(domain, applyDefaults(options));

export const localizeValidate = (domain, options, t) =>
  ValidateDomain(domain, applyDefaults(options), t);

// Debounced validation for usage in rendering
// ValidateDomain's regexing seems to be intensive enough
// to produce a slight jank when typing quickly
export const useDomainValidation = (
  domain,
  validationOptions = {},
  wait = DEFAULT_INPUT_DELAY
) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const optsRef = useRef(validationOptions);
  useEffect(() => {
    optsRef.current = validationOptions;
  });

  // if isSubdomain, use minsegs value
  // if not, and if no minsegs value, default to 2
  useDebounce(
    () => {
      setIsValid(!localizeValidate(domain, optsRef.current, t));
    },
    wait,
    [domain]
  );

  return isValid;
};

const DomainTextInput = React.forwardRef(function DomainTextInput(
  { isSubdomain, minDomainSegments, validate: validateExtension, ...props },
  ref
) {
  const { t } = useTranslation();
  const validateWithOptions = useMemo(
    () => ({
      full: (value) => {
        if (validateExtension?.full) {
          return (
            validateExtension.full(value) ||
            localizeValidate(value, { isSubdomain, minDomainSegments }, t)
          );
        }

        return localizeValidate(value, { isSubdomain, minDomainSegments }, t);
      },
    }),
    [isSubdomain, minDomainSegments, validateExtension, t]
  );

  // validation is specialized enough that we don't allow overriding
  return (
    <TextInput
      ref={ref}
      {...props}
      onChange={(val, ev) => {
        const errorMessage = validateWithOptions.full(val);
        props.onChange(val, ev, errorMessage ? { errorMessage } : null);
      }}
      validate={{ ...validateWithOptions, ...validateExtension }}
    />
  );
});

DomainTextInput.propTypes = {
  placeholder: PropTypes.string,
  isSubdomain: PropTypes.bool,
  minDomainSegments: PropTypes.number,
  validate: PropTypes.shape({
    full: PropTypes.func.isRequired,
  }),
};

DomainTextInput.defaultProps = {
  placeholder: 'sub.domain',
  isSubdomain: false,
  minDomainSegments: null,
  validate: null,
};

export default DomainTextInput;
