import React, { useEffect } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import { useCraftProps } from '../../PageBuilder/settings/useCraftProps';
import { useSelectMenuPlacement } from '../../PageBuilder/settings/useSelectMenuPlacement';
import { sizeOptions, styleOptions, widthOptions } from '../constants';
import {
  Container,
  StyledToolbarColorPicker,
  ToggleButton,
  ToolbarIcon,
  ToolbarSection,
  ToolbarSelect,
} from '../styles';
import { gutters } from 'app/spacing';

const WidthSelect = styled(ToolbarSelect)`
  > div > div:last-child {
    width: 15px;
    justify-content: flex-end;
  }
`;

export const DividerToolbar = ({
  target,
  modalLayer,
  scrollContainer,
  node: {
    id,
    data: { props: initialProps },
  },
}) => {
  const { t } = useTranslation();
  const [ref, menuPlacement] = useSelectMenuPlacement({
    scrollTarget: scrollContainer,
  });
  const [props, setProp, setProps] = useCraftProps(id, initialProps, {
    applyToActiveNode: true,
  });

  useEffect(() => {
    setProps(initialProps);
  }, [initialProps, setProps]);

  return (
    <Overlay
      show
      target={target}
      transition={false}
      placement="top-start"
      popperConfig={{
        modifiers: {
          preventOverflow: { enabled: false },
          computeStyle: {
            fn(data, options) {
              const { fn } =
                data.instance.constructor.Defaults.modifiers.computeStyle;
              data = fn(data, options);
              data.styles.zIndex = layers.modals(modalLayer, 10);
              data.styles.top = '-1px';
              return data;
            },
          },
        },
      }}
    >
      <div>
        <Container ref={ref} onClick={(ev) => ev.stopPropagation()}>
          <ToolbarSection
            paddingRight={gutters.spacing(4)}
            paddingLeft={gutters.spacing(4)}
          >
            <ToolbarSelect
              menuPlacement={menuPlacement}
              variant="underline"
              value={props.borderStyle}
              options={styleOptions(t)}
              onChange={(val) => setProp('borderStyle', val.value)}
            />
          </ToolbarSection>
          <ToolbarSection
            paddingLeft={gutters.spacing(4)}
            paddingRight={gutters.spacing(0)}
          >
            <ToolbarSelect
              menuPlacement={menuPlacement}
              variant="underline"
              width={47}
              value={props.size}
              options={sizeOptions}
              onChange={(val) => setProp('size', val.value)}
            />
            <WidthSelect
              menuPlacement={menuPlacement}
              variant="underline"
              width={47}
              value={props.width}
              options={widthOptions}
              onChange={(val) => setProp('width', val.value)}
            />
          </ToolbarSection>
          <ToolbarSection
            paddingRight={gutters.spacing(4)}
            paddingLeft={gutters.spacing(4)}
          >
            <ToggleButton
              title={t('Align Left')}
              active={props.alignment === 'left'}
              onClick={() => setProp('alignment', 'left')}
            >
              <ToolbarIcon icon="align-left" />
            </ToggleButton>
            <ToggleButton
              title={t('Align Center')}
              active={props.alignment === 'center'}
              onClick={() => setProp('alignment', 'center')}
            >
              <ToolbarIcon icon="align-center" />
            </ToggleButton>
            <ToggleButton
              title={t('Align Right')}
              active={props.alignment === 'right'}
              onClick={() => setProp('alignment', 'right')}
            >
              <ToolbarIcon icon="align-right" />
            </ToggleButton>
            <StyledToolbarColorPicker
              menuPlacement={menuPlacement}
              align="center"
              icon="highlighter"
              color={props.color}
              onChange={(rgba) => setProp('color', rgba)}
            />
          </ToolbarSection>
        </Container>
      </div>
    </Overlay>
  );
};
