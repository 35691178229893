import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import CustomObjectsService from 'services/CustomObjectsService';
import { useCallback, useEffect, useState } from 'react';

export interface AssociatedTeammembersParams {
  customObject: any;
  entity: any;
  closeModal?: () => void;
  enabled?: boolean;
}

type AddAssociationPayload = {
  employee_id: string;
  has_record_access: boolean;
};

export const useAssociatedTeamMembers = ({
  customObject = {},
  entity,
  closeModal,
  enabled = true,
}: AssociatedTeammembersParams) => {
  const [
    { value: associatedTeamMembers = { count: 0, values: [] } },
    fetchAssociatedTeamMembers,
  ] = useAsyncFnKeepLast(
    async (ordering = '-last_activity_timestamp', page_size = 1, page = 1) => {
      return CustomObjectsService.getAssociatedTeamMembers({
        objectId: customObject?.id,
        entityId: entity?.id,
        ordering,
        page_size,
        page,
      });
    },
    []
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }
    fetchAssociatedTeamMembers();
  }, [fetchAssociatedTeamMembers, enabled]);

  const [forceRefetchKey, setforceRefetchKey] = useState(0);

  const handleAddNewTeamAssociation = useCallback(
    async (payload: AddAssociationPayload) => {
      try {
        await CustomObjectsService.createAssociatedTeamMembers({
          objectId: customObject?.id,
          entityId: entity?.id,
          payload,
        });
        fetchAssociatedTeamMembers();
        setforceRefetchKey((prev) => prev + 1);
      } finally {
        closeModal?.();
      }
    },
    [closeModal, entity, customObject, fetchAssociatedTeamMembers]
  );

  return {
    forceRefetchKey,
    associatedTeamMembers,
    fetchAssociatedTeamMembers,
    handleAddNewTeamAssociation,
  };
};
