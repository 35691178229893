import { useMemo } from 'react';
import { useNode } from '@craftjs/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useLoadNonWebFont } from '../hooks/useLoadNonWebFont';
import { TextContainer } from '../nodes/containers';
import { useViewerContext } from '../viewer';
import { useSanitizedHtml } from '../hooks/useSanitizedHtml';

export const TextBase = styled.div`
  ${({ rootNodeFontSize = 14 }) =>
    rootNodeFontSize &&
    css`
      p,
      span,
      a {
        font-size: ${rootNodeFontSize}px;
      }
    `}

  a[href],
  a[data-script-id] {
    cursor: pointer;
  }

  p {
    margin: 0;
    line-height: 1.5em;
    // https://github.com/ueberdosis/tiptap/issues/412#issuecomment-593635371
    // titap issue with removed 'br' in html content
    // it is suggested method for rendering space for empty paragraphs
    &:empty::after {
      content: '\\00A0';
    }
    span.kzn-merge-field:empty::after {
      content: '\\00A0';
    }
  }
  p * {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

export const useFontFamiliesFromText = (editorText = '') => {
  return useMemo(() => {
    return [...editorText.matchAll(/style=".*?"/g)].reduce((acc, style) => {
      const res = /font-family:.*[;"]/.exec(style);
      if (res !== null) {
        const ff = res[0]
          .split(/(font-family:|;)/)
          .filter((s) => s !== '' && s !== ';' && s !== 'font-family:');
        acc.push(ff[0].trim());
      }
      return acc;
    }, []);
  }, [editorText]);
};

export const ViewText = (props) => {
  const { editorText } = useNode((node) => ({
    editorText: node.data.custom?.text,
  }));
  const sanitized = useSanitizedHtml(editorText, { ADD_ATTR: ['target'] });
  const rootNodeProps = useViewerContext().rootNode?.data?.props ?? {};
  const fontFamilies = useFontFamiliesFromText(editorText);
  useLoadNonWebFont(fontFamilies);

  return (
    <TextContainer linkColor={rootNodeProps.linkColor} {...props}>
      <TextBase
        {...props}
        rootNodeFontSize={rootNodeProps.fontSize}
        dangerouslySetInnerHTML={{ __html: sanitized }}
      ></TextBase>
    </TextContainer>
  );
};

export const HomepageText = (props) => {
  const { rootNode, textTransform } = useViewerContext() ?? {};
  const rootNodeProps = rootNode?.data?.props ?? {};
  const { editorText } = useNode((node) => {
    const editorText =
      typeof textTransform === 'function'
        ? textTransform(node.data.custom?.text)
        : node.data.custom?.text;
    return { editorText };
  });
  const sanitized = useSanitizedHtml(editorText, { ADD_ATTR: ['target'] });
  const fontFamilies = useFontFamiliesFromText(editorText);
  useLoadNonWebFont(fontFamilies);

  return (
    <TextContainer linkColor={rootNodeProps.linkColor} {...props}>
      <TextBase
        {...props}
        rootNodeFontSize={rootNodeProps.fontSize}
        dangerouslySetInnerHTML={{ __html: sanitized }}
      ></TextBase>
    </TextContainer>
  );
};
