import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
} from '__components/Kizen/Table/CellLineHeightStyles';
import ValidatedField from '__components/Kizen/ValidatedField';
import DateInputInline, {
  DateInputInlineSelect,
} from '__components/Inputs/inline/DateInput';
import ActionCell from '__components/Tables/ActionCell';
import {
  SmartConnectorExtended,
  SmartConnectorStats,
  SmartConnectorStatus,
} from './types';
import {
  SMART_CONNECTOR_STATUSES,
  SMARTCONNECTORS_PATHNAME,
} from './constants';
import { useHistory } from 'react-router-dom';
import { useSmartConnectorsContext } from './context';

import { red, orange, gray, green } from '@kizen/kds/Colors';
import {
  useSmartConnectorAccess,
  SmartConnectorAccess,
} from './hooks/useSmartConnectorAccess';

const NO_VALUE = '—';

type CellProperty = {
  minWidth: string;
  padding: string;
  id: string;
  by: string;
};

type StatusObject = {
  color: string;
  label: string;
};

type MenuOptions = {
  value: string;
  label: string;
};

const cellPropertyKeys: Record<string, string> = {
  name: 'name',
  primaryObject: 'primaryObject',
  connectionType: 'connectionType',
  uses: 'uses',
  lastUsedAt: 'lastUsedAt',
  status: 'status',
  actions: 'actions',
};

const cellProperties: Record<string, CellProperty> = {
  [cellPropertyKeys.name]: {
    minWidth: '260px',
    padding: '30px',
    id: 'name',
    by: 'name',
  },
  [cellPropertyKeys.primaryObject]: {
    minWidth: '220px',
    padding: '30px',
    id: 'custom_object',
    by: 'custom_object__name',
  },
  [cellPropertyKeys.connectionType]: {
    minWidth: '255px',
    padding: '30px',
    id: 'connector_type',
    by: 'connector_type',
  },
  [cellPropertyKeys.uses]: {
    minWidth: '70px',
    padding: '30px',
    id: 'stats',
    by: 'stats__used_count',
  },
  [cellPropertyKeys.lastUsedAt]: {
    minWidth: '90px',
    padding: '30px',
    id: 'last_used_at',
    by: 'last_used_at',
  },
  [cellPropertyKeys.status]: {
    minWidth: '185px',
    padding: '30px',
    id: 'status',
    by: 'status',
  },
  [cellPropertyKeys.actions]: {
    minWidth: '52px',
    padding: '20px',
    id: 'actions',
    by: 'actions',
  },
};

const SmartConnectorsActionCell = ({
  data,
  minWidth,
  padding,
  onSelectAction,
  ...others
}: {
  data?: SmartConnectorExtended;
  minWidth: string;
  padding: string;
  onSelectAction: any;
}) => {
  const { t } = useTranslation();
  const { smartConnectorAccess } = useSmartConnectorAccess(data?.owner.id!);

  return (
    <ActionCell
      menuType="absolute"
      title={t('Edit SmartConnector')}
      data={data}
      options={getActionCellOptions(data!, smartConnectorAccess, t)}
      padding={padding}
      onSelectAction={onSelectAction}
      {...others}
    />
  );
};

const SmartConnectorsNameCell = ({
  data,
  name,
  onUpdateField,
}: {
  data: SmartConnectorExtended;
  name: string;
  onUpdateField: (
    smartConnector: SmartConnectorExtended,
    patch: any
  ) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const { smartConnectorAccess } = useSmartConnectorAccess(data?.owner?.id!);

  return (
    <ValidatedField
      type={smartConnectorAccess.edit ? 'link' : 'text'}
      to={`${SMARTCONNECTORS_PATHNAME}/${data.id}/builder`}
      value={name}
      shouldFocusNext={false}
      icon={smartConnectorAccess.edit ? undefined : null}
      onSubmit={async (value: any) => {
        if (!value) {
          throw new Error(t('SmartConnector names cannot be blank.'));
        }
        if (data.name === value) {
          return null;
        }
        return await onUpdateField(data, {
          name: value,
        });
      }}
    />
  );
};

const StatusText = ({ status }: { status: string }) => {
  const { t } = useTranslation();
  const lookup: Record<string, StatusObject> = useMemo(
    () => ({
      [SMART_CONNECTOR_STATUSES.setup]: {
        label: t('Setup In Progress'),
        color: gray['gray-800'],
      },
      [SMART_CONNECTOR_STATUSES.operational]: {
        label: t('Operational'),
        color: green['green-700'],
      },
      [SMART_CONNECTOR_STATUSES.need_attention]: {
        label: t('Operational (Needs Attention)'),
        color: red['red-700'],
      },
      [SMART_CONNECTOR_STATUSES.inactive]: {
        label: t('Inactive'),
        color: orange['orange-500'],
      },
    }),
    [t]
  );

  const statusObj = lookup[status];

  return (
    <TextEllipsisWithTooltip color={statusObj ? statusObj.color : null}>
      {statusObj?.label || NO_VALUE}
    </TextEllipsisWithTooltip>
  );
};

const getActionCellOptions = (
  smartConnector: SmartConnectorExtended,
  access: SmartConnectorAccess,
  t: TFunction
): MenuOptions[] => {
  return [
    access.edit && {
      value: 'dry-run',
      label: t('Start Dry Run'),
    },
    access.edit &&
      (
        [
          SMART_CONNECTOR_STATUSES.operational,
          SMART_CONNECTOR_STATUSES.need_attention,
        ] as SmartConnectorStatus[]
      ).includes(smartConnector.status) && {
        value: 'run',
        label: t('Process File'),
      },
    access.edit && { value: 'edit', label: t('Edit SmartConnector') },
    access.edit && { value: 'duplicate', label: t('Duplicate') },
    { value: 'history', label: t('History') },
    access.remove && { value: 'delete', label: t('Delete') },
  ].filter(Boolean) as MenuOptions[];
};

export const useColumns = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    handlePatchSmartConnector,
    smartConnectorDispatch,
    historyModal,
    handleDuplicateSmartConnector,
    showRunSmartConnectorModal,
  } = useSmartConnectorsContext();

  return useMemo(() => {
    const onUpdateField = async (
      smartConnector: SmartConnectorExtended,
      patch: any
    ) => {
      return handlePatchSmartConnector?.(smartConnector?.id || '', patch);
    };
    const onSelectAction = (
      { value: action }: { value: any },
      smartConnector: SmartConnectorExtended
    ) => {
      const { id = null } = smartConnector;
      if (action === 'delete') {
        smartConnectorDispatch?.({
          type: 'setSmartConnectorIdForDeletion',
          payload: id,
        });
      }
      if (action === 'edit') {
        history.push(
          `${SMARTCONNECTORS_PATHNAME}/${smartConnector.id}/builder`
        );
      }
      if (action === 'history') {
        historyModal.show();
        smartConnectorDispatch?.({
          type: 'setHistorySmartConnector',
          payload: smartConnector,
        });
      }
      if (action === 'duplicate') {
        handleDuplicateSmartConnector(id);
      }
      if (action === 'dry-run') {
        showRunSmartConnectorModal(
          smartConnector.id!,
          true,
          smartConnector.is_diff_check_enabled
        );
      }
      if (action === 'run') {
        showRunSmartConnectorModal(
          smartConnector.id!,
          false,
          smartConnector.is_diff_check_enabled
        );
      }
    };
    return [
      {
        id: 'spacer',
        headCell: <SizedCell width="20px" />,
        cell: <SizedCell width="20px" />,
      },
      {
        id: cellPropertyKeys.name,
        headCell: (
          <SortableHeadCell
            by={cellProperties.name.by}
            label={t('SmartConnector Name')}
            width={cellProperties.name.minWidth}
            padding={cellProperties.name.padding}
          />
        ),
        cell: (
          <SizedCell
            width={cellProperties.name.minWidth}
            padding={cellProperties.name.padding}
          />
        ),
        format: (name: string, smartConnector: SmartConnectorExtended) => {
          return (
            <SmartConnectorsNameCell
              data={smartConnector}
              name={name}
              onUpdateField={onUpdateField}
            />
          );
        },
      },
      {
        id: cellProperties.primaryObject.id,
        headCell: (
          <SortableHeadCell
            by={cellProperties.primaryObject.by}
            label={t('Primary Object')}
            width={cellProperties.primaryObject.minWidth}
            padding={cellProperties.primaryObject.padding}
          />
        ),
        cell: (
          <SizedCell
            width={cellProperties.primaryObject.minWidth}
            padding={cellProperties.primaryObject.padding}
          />
        ),
        format: (primary_object: any) => {
          return (
            <TextEllipsisWithTooltip>
              {primary_object?.entity_name || NO_VALUE}
            </TextEllipsisWithTooltip>
          );
        },
      },
      {
        id: `${cellProperties.connectionType.id}_name`,
        headCell: (
          <SortableHeadCell
            by={cellProperties.connectionType.by}
            label={t('Connection Type')}
            width={cellProperties.connectionType.minWidth}
            padding={cellProperties.connectionType.padding}
          />
        ),
        cell: (
          <SizedCell
            width={cellProperties.connectionType.minWidth}
            padding={cellProperties.connectionType.padding}
          />
        ),
        format: (connectionType: string) => {
          return (
            <TextEllipsisWithTooltip>
              {connectionType || NO_VALUE}
            </TextEllipsisWithTooltip>
          );
        },
      },
      {
        id: cellProperties.uses.id,
        headCell: (
          <SortableHeadCell
            by={cellProperties.uses.by}
            numeric
            label={t('# Uses')}
            minWidth={cellProperties.uses.minWidth}
            width={cellProperties.uses.minWidth}
            padding={cellProperties.uses.padding}
          />
        ),
        cell: (
          <SizedCell
            minWidth={cellProperties.uses.minWidth}
            width={cellProperties.uses.minWidth}
            padding={cellProperties.uses.padding}
          />
        ),

        format: (stats: SmartConnectorStats) => {
          return (
            <TextEllipsisWithTooltip>
              {stats?.used_count || '0'}
            </TextEllipsisWithTooltip>
          );
        },
      },
      {
        id: cellProperties.lastUsedAt.id,
        headCell: (
          <SortableHeadCell
            numeric
            by={cellProperties.lastUsedAt.by}
            label={t('Last Used')}
            minWidth={cellProperties.lastUsedAt.minWidth}
            width={cellProperties.lastUsedAt.minWidth}
            padding={cellProperties.lastUsedAt.padding}
          />
        ),
        cell: (
          <SizedCell
            minWidth={cellProperties.lastUsedAt.minWidth}
            width={cellProperties.lastUsedAt.minWidth}
            padding={cellProperties.lastUsedAt.padding}
          />
        ),
        format: (lastUsedAt: string) => {
          return (
            <DateInputInline readOnly>
              <DateInputInlineSelect value={lastUsedAt} />
            </DateInputInline>
          );
        },
      },
      {
        id: cellProperties.status.id,
        headCell: (
          <SortableHeadCell
            by={cellProperties.status.by}
            label={t('Status')}
            minWidth={cellProperties.status.minWidth}
            width={cellProperties.status.minWidth}
            padding={cellProperties.status.padding}
          />
        ),
        cell: (
          <SizedCell
            minWidth={cellProperties.status.minWidth}
            width={cellProperties.status.minWidth}
            padding={cellProperties.status.padding}
          />
        ),
        format: (status: string) => {
          return <StatusText status={status} />;
        },
      },
      {
        id: 'actions',
        headCell: (
          <SubActionHeadCell
            minWidth={cellProperties.actions.minWidth}
            padding={cellProperties.actions.padding}
            label={t('Actions')}
          />
        ),
        cell: (
          <SmartConnectorsActionCell
            minWidth={cellProperties.actions.minWidth}
            padding={cellProperties.actions.padding}
            onSelectAction={onSelectAction}
          />
        ),
      },
    ];
  }, [
    t,
    handlePatchSmartConnector,
    smartConnectorDispatch,
    history,
    historyModal,
    handleDuplicateSmartConnector,
    showRunSmartConnectorModal,
  ]);
};
