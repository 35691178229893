import styled from '@emotion/styled';
import React from 'react';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';

const Wrapper = styled.div`
  margin-bottom: ${gutters.spacing(3, 4)}px;
`;
const Label = styled(KizenTypography)`
  margin-bottom: ${gutters.spacing(2, 1)}px;
`;

export default function Row({ children, label }) {
  return (
    <Wrapper>
      {label ? <Label>{label}</Label> : null}
      {children}
    </Wrapper>
  );
}
