import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFilePickerNote } from './styles';
import FilePicker from 'components/FilePicker';
import { useProgress } from '__hooks/uploadFiles/useProgress';
import { useUploadFile } from '__hooks/uploadFiles/useUploadFile';
import { useFiles } from '__hooks/uploadFiles/useFiles';
import {
  ALLOWED_SOURCE_FILE_EXTENSIONS,
  SMARTCONNECTORS_S3_SOURCES,
} from 'pages/SmartConnectors/constants';
import { ScriptFile } from '__pages/SmartConnectors/types';

type FileUploaderProps = {
  onChange: (file: ScriptFile) => Promise<void>;
};

export const FileUploader = ({ onChange }: FileUploaderProps) => {
  const { t } = useTranslation();

  const [progress, { clearProgress, initializeProgress, updateProgress }] =
    useProgress();

  const uploadFile = useUploadFile(
    false,
    updateProgress,
    SMARTCONNECTORS_S3_SOURCES.IMPORT
  );

  const [, { addFiles }] = useFiles({
    uploadFile,
  });

  const handleAddFiles = useCallback(
    async (updatedFiles: File[]) => {
      initializeProgress(updatedFiles);
      const createdFiles = await addFiles(updatedFiles);
      if (createdFiles.length) {
        await onChange(createdFiles[0]);
      }
      clearProgress();
    },
    [addFiles, clearProgress, onChange, initializeProgress]
  );

  const uploadStatus = Object.values(
    (progress || { progress: 0 }) as Record<string, { progress: number }>
  )[0].progress;

  return (
    <>
      <FilePicker
        onFileAdded={handleAddFiles}
        allowedFileExtensions={ALLOWED_SOURCE_FILE_EXTENSIONS}
        uploadStatus={uploadStatus}
        disabled={!!progress}
      />
      <StyledFilePickerNote>
        {t(
          '*Only spreadsheet files are supported — please make sure your file name ends with .csv, .dat, .tsv, .xls, .xlsx, .xlsm, .xlsb or .ods'
        )}
      </StyledFilePickerNote>
    </>
  );
};
