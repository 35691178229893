import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TopMenuButton } from 'components/Kizen/Menu';

export default function SelectAllButton({ onClick, select, ...props }) {
  const { t } = useTranslation();
  const options = select.options
    .flatMap((opt) => opt.options || opt) // Handle groups
    .filter(
      (opt) =>
        !opt.groupPlaceholder &&
        select.selectProps.filterOption(
          { label: opt.label, value: opt.value, data: opt },
          select.selectProps.inputValue
        )
    );
  const value = select.getValue();
  const unselectedOptions = options.filter(
    (opt) => !value || !value.some((val) => val.value === opt.value)
  );

  return (
    <TopMenuButton
      data-qa="select-all"
      onClick={(ev) => {
        select.setValue(
          [...(value || []), ...unselectedOptions],
          'select-option'
        );
        if (onClick) onClick(ev);
      }}
      {...props}
    >
      {`${t('Select All')} (${unselectedOptions.length})`}
    </TopMenuButton>
  );
}

SelectAllButton.propTypes = {
  onClick: PropTypes.func,
  select: PropTypes.object.isRequired,
};

SelectAllButton.defaultProps = {
  onClick: null,
};

SelectAllButton.enrich = {
  select: true,
};
