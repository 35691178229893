import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { usePermissionValue } from '@kizen/permissions/hooks/usePermissionValue';
import { usePermissionsContext } from '@kizen/permissions/hooks/context';
import { useUpdatePermissionValues } from '@kizen/permissions/hooks/useUpdatePermissionValues';
import { useDisableSectionPermissions } from '@kizen/permissions/hooks/useDisableSectionPermissions';
import { getStreamKey } from '@kizen/permissions/utils';
import {
  fetchContactFieldsQuery,
  useContactFieldsAndCategoriesQueries,
} from './query';
import {
  FieldPermissionRows,
  PermissionLabel,
  PermissionRow,
  Section,
  SectionContent,
  SetAllRow,
} from './components';
import { isFirstNameField, isLastNameField } from 'checks/fields';

const EMPTY_ARRAY = [];
const DEFAULT_FIELDS = 'default_fields';
const defaultFieldsOrder = {
  email: 0,
  created: 1,
  first_name: 2,
  email_status: 3,
  mobile_phone: 4,
  business_phone: 5,
  home_phone: 6,
  tags: 7,
  titles: 8,
  birthday: 9,
  timezone: 10,
};

export const CONTACTS_SECTION_KEY = 'contacts_section';
const withoutLastName = (field) => !isLastNameField(field);

const RowWithRuleTooltip = ({
  affordance,
  category,
  label,
  labelIndent,
  permission,
  section,
  tooltip,
}) => {
  const ruleTooltip = usePermissionValue(
    getStreamKey(permission, section),
    ({ tooltip }) => tooltip
  );

  return (
    <PermissionRow
      section={section}
      permission={permission}
      affordance={affordance}
      tooltip={ruleTooltip || tooltip}
      label={label}
      labelIndent={labelIndent}
      category={category}
    />
  );
};

export const ContactsSection = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const ctx = usePermissionsContext();
  const {
    contact_section: { label, key },
    existing_contact_values,
    meta_config: { contacts, default_contact_fields, contact_categories },
  } = ctx;
  const isSectionEnabled = usePermissionValue(key, ({ value }) => value);
  const initialValue = usePermissionValue(key, ({ initial }) => initial.value);
  const [
    { data: fields = EMPTY_ARRAY } = {},
    { data: categories = EMPTY_ARRAY } = {},
  ] = useContactFieldsAndCategoriesQueries(isSectionEnabled);
  const updatePermissionValues = useUpdatePermissionValues();
  const disableSectionPermissions = useDisableSectionPermissions();

  const filteredFields = useMemo(() => {
    return fields?.filter(withoutLastName) ?? EMPTY_ARRAY;
  }, [fields]);

  const categorizedFields = useMemo(() => {
    if (!filteredFields?.length || !categories) return EMPTY_ARRAY;
    const categorizedFields = filteredFields.reduce((acc, field) => {
      const category = field.isDefault ? DEFAULT_FIELDS : field.category;
      const updatedField = isFirstNameField(field)
        ? { ...field, displayName: t('Full Name') }
        : field;
      acc[category] = acc[category] || [];
      acc[category].push(updatedField);
      return acc;
    }, {});
    const defaultFieldsCategory = {
      id: DEFAULT_FIELDS,
      name: t('Default Fields'),
      fields: categorizedFields[DEFAULT_FIELDS].sort((a, b) => {
        return defaultFieldsOrder[a.name] - defaultFieldsOrder[b.name];
      }),
    };
    return [defaultFieldsCategory].concat(
      categories
        .map((category) => ({
          ...category,
          fields: categorizedFields[category.id] ?? [],
        }))
        .sort((a, b) => a.order - b.order)
    );
  }, [filteredFields, categories, t]);

  const allFieldPermissionKeys = useMemo(() => {
    return fields?.map(({ name }) => getStreamKey(name, key)) ?? EMPTY_ARRAY;
  }, [key, fields]);

  const { main, bulk, bulk_keys, default_for_new_field } = useMemo(() => {
    if (!isSectionEnabled)
      return { main: [], bulk: [], bulk_keys: [], default_for_new_field: null };
    return contacts.reduce(
      (acc, permission) => {
        if (permission.key === 'default_for_new_field') {
          acc.default_for_new_field = permission;
        } else if (permission.is_bulk_action) {
          acc.bulk.push(permission);
          acc.bulk_keys.push(getStreamKey(permission.key, key));
        } else {
          if (
            permission.category &&
            !acc.main.find(({ key }) => key === permission.category)
          ) {
            const contactCategory = contact_categories.find(
              ({ key }) => key === permission.category
            );
            contactCategory &&
              acc.main.push({ ...contactCategory, isCategory: true });
          }
          acc.main.push(permission);
        }
        return acc;
      },
      { main: [], bulk: [], bulk_keys: [], default_for_new_field: null }
    );
  }, [isSectionEnabled, contacts, contact_categories, key]);

  const handleSectionToggle = useCallback(
    async (value) => {
      if (value) {
        if (initialValue) {
          updatePermissionValues(existing_contact_values);
        } else {
          const fields = await fetchContactFieldsQuery(queryClient);
          const fieldValues = fields
            .filter(withoutLastName)
            .reduce((acc, f) => {
              const key = `${CONTACTS_SECTION_KEY}__${f.name}`;
              acc[key] = default_contact_fields?.[f.name]?.default ?? 'view';
              return acc;
            }, {});
          const permissionValues = contacts.reduce((acc, config) => {
            const key = getStreamKey(config.key, CONTACTS_SECTION_KEY);
            acc[key] = config.default;
            return acc;
          }, {});
          updatePermissionValues({ ...fieldValues, ...permissionValues });
        }
      } else {
        disableSectionPermissions(CONTACTS_SECTION_KEY);
      }
    },
    [
      initialValue,
      existing_contact_values,
      default_contact_fields,
      contacts,
      updatePermissionValues,
      disableSectionPermissions,
      queryClient,
    ]
  );

  return (
    <Section
      title={label}
      sectionId={key}
      onChange={handleSectionToggle}
      {...props}
    >
      {isSectionEnabled && (
        <SectionContent>
          {main.map(
            ({
              affordance,
              key: permission_key,
              label,
              tooltip,
              category,
              isCategory,
            }) =>
              isCategory ? (
                <PermissionLabel
                  key={permission_key}
                  label={label}
                  tooltip={tooltip}
                />
              ) : (
                <RowWithRuleTooltip
                  key={permission_key}
                  section={key}
                  permission={permission_key}
                  affordance={affordance}
                  tooltip={tooltip}
                  label={label}
                  labelIndent={category ? 40 : 0}
                  category={category}
                />
              )
          )}
          <SetAllRow
            showNone
            showRemove
            label={t('Perform Bulk Actions (Set All)')}
            permissionKeys={bulk_keys}
          />
          {bulk.map(({ affordance, key: permission_key, label, tooltip }) => (
            <RowWithRuleTooltip
              key={key}
              section={key}
              permission={permission_key}
              affordance={affordance}
              label={label}
              tooltip={tooltip}
              labelIndent={40}
              category="bulk_actions"
            />
          ))}
          {default_for_new_field && (
            <RowWithRuleTooltip
              key={default_for_new_field.key}
              section={key}
              permission={default_for_new_field.key}
              affordance={default_for_new_field.affordance}
              label={default_for_new_field.label}
              tooltip={default_for_new_field.tooltip}
            />
          )}
          <SetAllRow
            showNone
            showView
            showEdit
            label={t('Individual Contact Field Permission (Set All)')}
            permissionKeys={allFieldPermissionKeys}
          />
          {categorizedFields.map(({ id, name, fields }) => (
            <FieldPermissionRows
              key={id}
              sectionId={key}
              categoryName={name}
              fields={fields}
            />
          ))}
        </SectionContent>
      )}
    </Section>
  );
};
