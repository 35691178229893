import { useCallback } from 'react';
import { useProcessDelete } from './getters';

export const useManagedDelete = () => {
  const processDelete = useProcessDelete();

  const onDelete = useCallback(
    (id) => {
      processDelete(id);
    },
    [processDelete]
  );

  return onDelete;
};
