import { useEditor } from '@craftjs/core';
import styled from '@emotion/styled';
import { defFilter } from '@kizen/filters/filter';
import { loadFilterConfig } from '@kizen/filters/load';
import { getFilterSetErrors } from '@kizen/filters/validate';
import { DEFAULT_LINE_HEIGHT_VALUE } from '@kizen/page-builder';
import { buildEmailTextNode } from '@kizen/page-builder/utils/build';
import {
  useLoadNonWebFont,
  loadFontFamily,
} from '@kizen/page-builder/hooks/useLoadNonWebFont';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { layers } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import Modal, { ModalBody } from 'components/Modals';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useModalControl } from 'hooks/useModalControl';
import { BasicRichTextEditor } from 'components/WYSIWYG';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { useClientGroupsQuery } from '__queries/models/client';
import { fetchUrlConfigWithReactQuery } from 'ts-filters/utils';
import { useFilterVariables, useFilterSetErrors } from 'ts-components/filters';
import { useBuilderContext, useRootNodeProps } from '../BuilderContext';
import { useFontFamiliesFromText } from '../nodes/Text/useFontFamiliesFromText';
import { TraySection } from '../components/TraySection';
import {
  AddSaveRow,
  DefaultContentSettingsContainer,
  DynamicContentHeader,
  DynamicContentInstructionText,
  DynamicContentSettingsContainer,
} from '../components/DynamicContentModal/ts-components';
import { DynamicContentCard } from '../components/DynamicContentModal/ContentCard';
import {
  TextUpdateChannelEvents,
  TextUpdateChannelTypes,
  useTextUpdateBroadcastChannel,
} from '../utils';
import {
  ContentRuleDropdown,
  ContentTypeRadio,
  EditRulesButton,
  STATIC,
  DYNAMIC,
} from './ImageSettings/components';
import { useFonts } from './useFonts';
import { validate as validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';
import { getBusinessClientObject } from 'store/authentication/selectors';

/**
 * @param {*} rule1 - data from {@link defDynamicCard} where `filters` is Filter[]
 * @param {*} initial - data from {@link defDynamicCard} where `filters` are filter payloads (the result of calling Filter's `build` method)
 * @returns {boolean}
 */
const ruleEq = (rule1, initial) => {
  const { filters: filters1, groups: groups1, ...r1 } = rule1;
  const { filters: filters2, groups: groups2, ...r2 } = initial;

  const group1Ids = new Set(
    groups1.map((g) => {
      return typeof g === 'object' ? g.id : g;
    })
  );

  const group2Ids = new Set(
    groups2.map((g) => {
      return typeof g === 'object' ? g.id : g;
    })
  );

  if (group1Ids.size !== group2Ids.size) {
    return false;
  }

  if (!isEqual(r1, r2)) {
    return false;
  }

  const payloads1 = filters1.map((f) => f.filter.build());
  return payloads1.every((p, i) => isEqual(p, filters2[i]));
};

const areRulesEqual = (rules1, initialRules) => {
  if (rules1.length !== initialRules.length) {
    return false;
  }

  return rules1.every((r, i) => ruleEq(r, initialRules[i]));
};

const nodeNameLabels = (t) => ({
  CustomField: t('Custom Field'),
  FormField: t('Form Field'),
});

const opsForFilter = (filter, update) => ({
  next: (...args) => filter.next(...args),
  set: (...args) => filter.set(...args),
  update: () => update((prev) => [...prev]),
});

const defDynamicCard = () => ({
  operation: 'or',
  filterType: 'in_group',
  filters: [],
  groups: [],
  id: uuidv4(),
  text: '',
});

const getGroupFilterLabel = (type, groups, t) => {
  const groupNames = groups
    .map(({ name }) => `'${name}'`)
    .join(', ')
    .trim();
  return `${
    type === 'in_group'
      ? t('Contact In Group(s)')
      : t('Contact Not In Group(s)')
  } ${groupNames}`;
};

const descriptionForFilterSet = (filters, joinText) => {
  return filters.map(({ filter }) => filter.toString()).join(joinText);
};

const rulesFromDynamicTextNodes = async (
  nodes,
  queryClient,
  metadata,
  setter
) => {
  if (!nodes || nodes?.length === 0) return [];

  const result = [];

  for await (const {
    custom: { filter, text },
    props,
  } of nodes) {
    if (filter.type === 'custom_filter') {
      const set = await loadFilterConfig(
        (config) => fetchUrlConfigWithReactQuery(queryClient, config),
        filter.filter,
        metadata
      );

      result.push({
        operation: set.and ? 'and' : 'or',
        filters: set.filters.map((f) => ({
          filter: f,
          type: f.type,
          ops: opsForFilter(f, setter),
        })),
        filterType: filter.type,
        groups: filter.groups,
        id: props.filterId,
        text,
      });
    } else {
      result.push({
        operation: 'or',
        filters: [],
        filterType: filter.type,
        groups: filter.groups,
        id: props.filterId,
        text,
      });
    }
  }

  return result;
};

const dynamicTextNodesFromRules = (rules, t) => {
  return rules.map((rule, idx) => {
    const filter = rule.filters.length
      ? {
          and: null,
          query: [
            {
              and: rule.operation === 'and',
              filters: rule.filters.map(({ filter }) => ({
                ...filter.build(),
                view_model: [...filter.save()],
              })),
            },
          ],
        }
      : null;
    const joinText =
      rule.operation === 'and' ? ` ${t('and')} ` : ` ${t('or')} `;

    const description =
      rule.filterType === 'custom_filter'
        ? descriptionForFilterSet(rule.filters, joinText)
        : getGroupFilterLabel(rule.filterType, rule.groups, t);

    const custom = {
      filter: {
        description,
        filter,
        groups: rule.groups.map((group) => group.id),
        type: rule.filterType,
      },
    };
    const props = { filterId: rule.id, filterOrder: idx };
    return buildEmailTextNode(rule.text, props, custom);
  });
};

const buildDropdownOptions = (node, t) => {
  const options = [{ value: node, label: `1 - ${t('Default Content')}` }];

  if (Array.isArray(node.custom.dynamicText)) {
    let num = 2;
    for (const n of node.custom.dynamicText) {
      const label = `${num++} - ${n.custom.filter.description}`;
      options.push({ label, value: n });
    }
  }

  return options;
};

const Body = styled(ModalBody)`
  && {
    padding: 0 15px 0 20px;
  }
`;

const InstructionText = ({ t }) => (
  <KizenTypography fontStyle="italic">
    {t('Edit your text block on the builder')}
  </KizenTypography>
);

const DynamicContentTextEditor = styled(BasicRichTextEditor)`
  width: 100%;
`;

const StyledContentTypeRadio = styled(ContentTypeRadio)`
  margin-bottom: 10px;
`;

const TextEditor = ({ fonts, text, onChange }) => {
  const {
    enableMergeFields,
    enableTextLinks,
    mergeFields,
    modalLayer,
    multipleMergeFields,
    textLinkOptions,
  } = useBuilderContext();
  const rootNodeProps = useRootNodeProps();
  const fontFamilies = useFontFamiliesFromText(text);
  useLoadNonWebFont(fontFamilies);

  return (
    <DynamicContentTextEditor
      enableFontSize
      enableFontFamily
      enableLineHeight
      enableColor
      enableHighlight
      enableClearFormatting
      enableTextAlign
      enableMergeFields={enableMergeFields}
      enableResize={false}
      mergeFields={mergeFields}
      multipleMergeFields={multipleMergeFields}
      enableLinks={enableTextLinks}
      initialValue={text}
      textLinkOptions={textLinkOptions}
      zIndex={layers.modals(modalLayer, 10)}
      defaultFontFamily={rootNodeProps.fontFamily || 'Arial'}
      defaultFontSize={`${rootNodeProps.fontSize || 14}px`}
      defaultLineHeight={rootNodeProps.lineHeight ?? DEFAULT_LINE_HEIGHT_VALUE}
      fonts={fonts}
      onFontFamilyChange={loadFontFamily}
      initialHeight={100}
      onChange={onChange}
    />
  );
};

const DynamicTextCard = ({
  customObjectId,
  customObjectFetchUrl,
  filterErrors,
  fonts,
  isFirst,
  isLast,
  filters,
  filterType,
  groups,
  groupOptions,
  loadingGroups,
  modalLayer = 0,
  operation,
  scrollSelf = false,
  text,
  onAddFilter,
  onChange,
  onDelete,
  onDeleteFilter,
  onReorderUp,
  onReorderDown,
  onFilterTypeChange,
  onCreateFilter,
  onGroupsChange,
  onOperationChange,
}) => {
  const ref = useRef();

  const refCallback = useCallback(
    (node) => {
      if (scrollSelf && !ref.current) {
        setTimeout(() => node.scrollIntoView({ behavior: 'smooth' }));
      }
      ref.current = node;
    },
    [scrollSelf]
  );

  return (
    <DynamicContentCard
      customObjectId={customObjectId}
      customObjectFetchUrl={customObjectFetchUrl}
      filters={filters}
      filterErrors={filterErrors}
      filterType={filterType}
      ref={refCallback}
      initialGroups={groups}
      isFirst={isFirst}
      isLast={isLast}
      groupOptions={groupOptions}
      loadingGroups={loadingGroups}
      modalLayer={modalLayer}
      operation={operation}
      text={text}
      onAddFilter={onAddFilter}
      onCreateFilter={onCreateFilter}
      onDelete={onDelete}
      onDeleteFilter={onDeleteFilter}
      onReorderUp={onReorderUp}
      onReorderDown={onReorderDown}
      onGroupsChange={onGroupsChange}
      onFilterTypeChange={onFilterTypeChange}
      onOperationChange={onOperationChange}
    >
      <TextEditor fonts={fonts} text={text} onChange={onChange} />
    </DynamicContentCard>
  );
};

export const TextSettingsSection = ({ node, onTextChange, textFilterId }) => {
  const {
    data: { name },
    id,
  } = node;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enableGoogleFonts, modalLayer, clearContentSettingsTray } =
    useBuilderContext();
  const {
    actions: { setCustom },
  } = useEditor();
  const { variables } = useFilterVariables();
  const clientObject = useSelector(getBusinessClientObject);
  const metadata = useSelector(selectFilterMetadataDefinition);
  const { filters: all_filters } = metadata;
  const [contentType, setContentType] = useState(
    node.data.custom.dynamicText?.length ? DYNAMIC : STATIC
  );
  const [defaultText, setDefaultText] = useState(node.data.custom.text);
  const [rules, setRules] = useState([]);
  const [modalOpen, { showModal, hideModal }] = useModalControl(false);
  const [filterErrors, setFilterErrors] = useFilterSetErrors();
  const fonts = useFonts(enableGoogleFonts);
  const [options, setOptions] = useState(() =>
    buildDropdownOptions(node.data, t)
  );
  const [selected, setSelected] = useState(options[0]);
  const prevRuleCount = useRef(0);
  const initialRuleData = useRef(null);
  const initialDynamicText = useRef([]);
  const initialDefaultText = useRef(node.data.custom.text);
  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const { data: groupOptions = [], isLoading: loadingGroupOptions } =
    useClientGroupsQuery({
      select: (results) =>
        results.map((opt) => {
          return { label: opt.name, value: opt };
        }),
    });

  useEffect(() => {
    // when textFilterId is undefined next will resolve to the default content option
    const next = options.find((x) => x.value.props.filterId === textFilterId);
    setSelected(next);
  }, [textFilterId, options]);

  const ops = useMemo(
    () => ({
      addFilter: (index) => {
        setRules((prev) => {
          const filters = prev[index].filters.concat({ filter: null });
          return prev
            .slice(0, index)
            .concat({ ...prev[index], filters }, prev.slice(index + 1));
        });
      },
      createFilter: (ruleIndex, filterIndex, type) => {
        const filter = defFilter(all_filters[type], metadata, {
          vars: variables,
        });
        const ops = opsForFilter(filter, setRules);

        setRules((prev) => {
          const current = prev[ruleIndex].filters;
          const filters = current
            .slice(0, filterIndex)
            .concat({ filter, ops, type }, current.slice(filterIndex + 1));
          return prev
            .slice(0, ruleIndex)
            .concat({ ...prev[ruleIndex], filters }, prev.slice(ruleIndex + 1));
        });
      },
      deleteCard: (index) => {
        setRules((prev) => prev.slice(0, index).concat(prev.slice(index + 1)));
      },
      deleteFilter: (ruleIndex, filterIndex) => {
        setRules((prev) => {
          const filters = prev[ruleIndex].filters
            .slice(0, filterIndex)
            .concat(prev[ruleIndex].filters.slice(filterIndex + 1));
          return prev
            .slice(0, ruleIndex)
            .concat({ ...prev[ruleIndex], filters }, prev.slice(ruleIndex + 1));
        });
      },
      filterTypeChanged: (index, filterType) => {
        setRules((prev) => {
          if (filterType === prev[index].filterType) return prev;
          const filters =
            filterType === 'custom_filter' ? [{ filter: null }] : [];
          return prev
            .slice(0, index)
            .concat(
              { ...prev[index], filterType, filters, groups: [] },
              prev.slice(index + 1)
            );
        });
      },
      groupsChanged: (index, ids) => {
        setRules((prev) => {
          prev[index].groups = ids;
          return prev;
        });
      },
      operationChanged: (index, operation) => {
        setRules((prev) => {
          return prev
            .slice(0, index)
            .concat({ ...prev[index], operation }, prev.slice(index + 1));
        });
      },
      reorderUp: (index) => {
        setRules((prev) => {
          return [
            ...prev.slice(0, index - 1),
            prev[index],
            prev[index - 1],
            ...prev.slice(index + 1),
          ];
        });
      },
      reorderDown: (index) => {
        setRules((prev) => {
          return [
            ...prev.slice(0, index),
            prev[index + 1],
            prev[index],
            ...prev.slice(index + 2),
          ];
        });
      },
      textChanged: (index, text) => {
        setRules((prev) => {
          return prev
            .slice(0, index)
            .concat({ ...prev[index], text }, prev.slice(index + 1));
        });
      },
      setTextByFilterId: (filterId, text) => {
        setRules((prev) => {
          const index = prev.findIndex((rule) => rule.id === filterId);
          return prev
            .slice(0, index)
            .concat({ ...prev[index], text }, prev.slice(index + 1));
        });
      },
    }),
    [setRules, all_filters, metadata, variables]
  );

  const onTextUpdated = useCallback(
    ({ type, text, filterId }) => {
      if (type === TextUpdateChannelTypes.UPDATE_SETTINGS) {
        if (filterId) {
          const dynamicNode = initialDynamicText.current?.find((node) => {
            return node.props.filterId === filterId;
          });
          if (dynamicNode) {
            dynamicNode.custom.text = text;
            ops.setTextByFilterId(filterId, text);
          }
        } else {
          initialDefaultText.current = text;
          setDefaultText(text);
        }
      }
    },
    [ops]
  );
  const channel = useTextUpdateBroadcastChannel(onTextUpdated);

  useEffect(() => {
    // Setting initial rule state is done here (instead of the edit rules click handler)
    // because the text can be edited on the canvas. A broadcast channel is used to communicate
    // those changes and rule state needs to exist for `onTextUpdated` to be able to update them.
    const load = async () => {
      const rules = await rulesFromDynamicTextNodes(
        initialDynamicText.current,
        queryClient,
        metadata,
        setRules
      );
      setRules(rules);
    };

    const {
      custom: { dynamicText, text },
    } = node.data;

    initialDefaultText.current = text;
    setDefaultText(text);
    setOptions(buildDropdownOptions(node.data, t));
    setContentType(dynamicText?.length ? DYNAMIC : STATIC);

    if (dynamicText) {
      // Cloning so the node.custom.text property can later be mutated (in onTextUpdated)
      // craftjs uses immer under the hood so all of its state is readonly
      initialDynamicText.current = structuredClone(dynamicText);
      load();
    } else {
      setRules([]);
    }
  }, [queryClient, metadata, setOptions, t, node]);

  const resetModalState = () => {
    prevRuleCount.current = 0;
  };

  const handleSave = () => {
    const errors = rules.map((rule) =>
      rule.filterType === 'custom_filter'
        ? getFilterSetErrors(
            rule.filters.map((x) => x.filter),
            isValidPhoneNumber,
            validateEmail.withDomain,
            t,
            {
              hasFilterTypeStep: true,
            }
          )
        : { hasErrors: false }
    );

    if (errors.some((error) => error.hasErrors)) {
      setFilterErrors(errors);
      return;
    }

    const nodes = dynamicTextNodesFromRules(rules, t);
    initialDefaultText.current = defaultText;
    initialDynamicText.current = structuredClone(nodes);
    setCustom(id, (c) => {
      c.text = defaultText;
      c.dynamicText = nodes;
    });
    setOptions(
      buildDropdownOptions({ ...node.data, custom: { dynamicText: nodes } }, t)
    );
    resetModalState();
    hideModal();
    channel.postMessage(TextUpdateChannelEvents.updateNode(id));
  };

  const handleEditRulesClick = async () => {
    prevRuleCount.current = rules.length;
    initialRuleData.current = rules.map((rule) => {
      const { filterType, groups, id, operation, text, filters } = rule;
      return {
        filterType,
        groups,
        id,
        operation,
        text,
        filters: filters.map((f) => f.filter.build()),
      };
    });

    setRules((prev) => {
      return prev.map((rule) => {
        // `dynamicTextNodesFromRules` only saves the group id. The "runtime" Group[] is
        // the full group w/ name for building the description. Existing rules with groups
        // need to be replaced with the full option value so the dropdown populate correctly.
        if (rule.groups.length && typeof rule.groups[0] === 'string') {
          return {
            ...rule,
            groups: groupOptions
              .filter((g) => rule.groups.includes(g.value.id))
              .map((opt) => opt.value),
          };
        }

        return rule;
      });
    });

    showModal();
  };

  const handleHideModal = async () => {
    resetModalState();
    hideModal();
    setShowConfirmClose(false);

    const rules = await rulesFromDynamicTextNodes(
      initialDynamicText.current,
      queryClient,
      metadata,
      setRules
    );

    setRules(rules);
  };

  const tryHandleHideModal = () => {
    if (
      initialDefaultText.current !== defaultText ||
      !areRulesEqual(rules, initialRuleData.current)
    ) {
      setShowConfirmClose(true);
    } else {
      handleHideModal();
    }
  };

  const scrollLast = rules.length > prevRuleCount.current;
  if (scrollLast) {
    prevRuleCount.current = rules.length;
  }

  return (
    <TraySection
      onBackClick={clearContentSettingsTray}
      collapsable={false}
      header={`${nodeNameLabels(t)[name] || name} ${t('Settings')}`}
    >
      <StyledContentTypeRadio value={contentType} onChange={setContentType} />
      {contentType === STATIC && <InstructionText t={t} />}
      {contentType === DYNAMIC && (
        <div>
          <EditRulesButton onClick={handleEditRulesClick}>
            {t('Edit Rules')}
          </EditRulesButton>
          <ContentRuleDropdown
            value={selected}
            options={options}
            marginBottom={15}
            onChange={(opt) => {
              setSelected(opt);
              onTextChange?.(opt.value);
            }}
          />
          <InstructionText t={t} />
        </div>
      )}
      {modalOpen && (
        <Modal show size="large" onHide={tryHandleHideModal}>
          <DynamicContentHeader onClose={tryHandleHideModal} />
          <Body>
            <DynamicContentInstructionText />
            <DefaultContentSettingsContainer>
              <TextEditor
                text={defaultText}
                fonts={fonts}
                onChange={({ editor }) => setDefaultText(editor.getHTML())}
              />
            </DefaultContentSettingsContainer>
            <DynamicContentSettingsContainer>
              {rules.map(
                ({ id, filters, filterType, groups, operation, text }, i) => {
                  const isLast = i === rules.length - 1;
                  const isFirst = i === 0;
                  const reorderDown = !isLast
                    ? () => ops.reorderDown(i)
                    : undefined;
                  const reorderUp = !isFirst
                    ? () => ops.reorderUp(i)
                    : undefined;

                  return (
                    <DynamicTextCard
                      key={id}
                      customObjectId={clientObject.id}
                      customObjectFetchUrl="client"
                      scrollSelf={scrollLast && isLast}
                      text={text}
                      filters={filters}
                      filterErrors={filterErrors?.[i]?.errors ?? {}}
                      groups={groups}
                      groupOptions={groupOptions}
                      loadingGroups={loadingGroupOptions}
                      fonts={fonts}
                      filterType={filterType}
                      isFirst={i === 0}
                      isLast={isLast}
                      modalLayer={modalLayer + 1}
                      operation={operation}
                      onChange={({ editor }) =>
                        ops.textChanged(i, editor.getHTML())
                      }
                      onFilterTypeChange={(type) =>
                        ops.filterTypeChanged(i, type)
                      }
                      onAddFilter={() => ops.addFilter(i)}
                      onDelete={() => ops.deleteCard(i)}
                      onDeleteFilter={(filterIndex) =>
                        ops.deleteFilter(i, filterIndex)
                      }
                      onReorderDown={reorderDown}
                      onReorderUp={reorderUp}
                      onGroupsChange={(groups) => ops.groupsChanged(i, groups)}
                      onCreateFilter={(filterIndex, value) =>
                        ops.createFilter(i, filterIndex, value)
                      }
                      onOperationChange={(op) => ops.operationChanged(i, op)}
                    />
                  );
                }
              )}
            </DynamicContentSettingsContainer>
          </Body>
          <AddSaveRow
            onAdd={() => setRules((prev) => prev.concat(defDynamicCard()))}
            onSave={handleSave}
          />
          {showConfirmClose && (
            <BasicModal
              show
              size="small"
              heading={t('You Have Unsaved Changes')}
              buttonText={t('Discard Changes')}
              actionBtnColor="red"
              onHide={() => setShowConfirmClose(false)}
              onConfirm={handleHideModal}
            >
              {t('Unsaved changes will be lost, would you like to continue?')}
            </BasicModal>
          )}
        </Modal>
      )}
    </TraySection>
  );
};
