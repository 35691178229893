import { useCallback, useState } from 'react';
import { useEditor } from '@craftjs/core';
import { useSetActiveNodeProp } from '../BuilderContext';

export const useCraftProps = (
  id,
  initialProps,
  opts = { applyToActiveNode: false }
) => {
  const setActiveNodeProp = useSetActiveNodeProp();
  const [props, setProps] = useState(initialProps);
  const {
    actions: { setProp: setCraftProp },
  } = useEditor();

  const setProp = useCallback(
    (key, value, { applyToActiveNode } = { applyToActiveNode: false }) => {
      setProps((prev) => ({ ...prev, [key]: value }));
      setCraftProp(id, (p) => {
        p[key] = value;
      });
      if (opts.applyToActiveNode || applyToActiveNode) {
        setActiveNodeProp(key, value);
      }
    },
    [id, opts.applyToActiveNode, setCraftProp, setProps, setActiveNodeProp]
  );

  return [props, setProp, setProps];
};
