import { Button } from '@kizen/kds/Button';
import type { ValidIcons } from '@kizen/kds/Icon';

export const HeaderPreview = (props: any) => {
  const { columns = [] } = props;

  return (
    <div className="kds kds-expanding-scrollbar flex border-1 border-solid border-border-medium-light h-[50px] items-center gap-x-spacer-20 px-spacer-20">
      {columns.map((column: any, index: number) => {
        const chosenIcon = column.icon?.value;
        return (
          <div key={index} className="kds flex gap-x-spacer-20 items-center">
            <Button
              leftIcon={
                chosenIcon
                  ? (`custom-${chosenIcon}` as ValidIcons)
                  : 'custom-bars'
              }
              leftIconSettings={{
                className: !chosenIcon ? 'pt-[2px]' : '',
              }}
              variant="text"
              color="inherit"
              size="small"
              preserveCase
              hoverColor={column.color}
            >
              {column.label || column.displayName}
            </Button>
          </div>
        );
      })}
    </div>
  );
};
