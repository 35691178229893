export type FilterVars = {
  custom_object_id: string;
  object_type: 'client_client' | 'pipeline' | 'standard' | 'automation';
  client_tag_field_id?: string;
  access: any;
};

const isMeTeamSelector = (field_type: string, value: any) => {
  return field_type === 'team_selector' && value === 'is_me';
};

const isNumber = (x: any): x is number => {
  return x !== null && !isNaN(x);
};

const isOwnerFilter = (root: any) => {
  return root.type === 'fields_v2' && root.field === 'owner';
};

const isStageFilter = (root: any) => {
  return root.type === 'fields_v2' && root.field === 'stage';
};

const isFieldWithOptions = (field: any) => {
  return [
    'checkboxes',
    'dropdown',
    'radio',
    'status',
    'timezone',
    'yesnomaybe',
  ].includes(field.fieldType);
};

// key = type field of the payload | value = filter_type name used in metadata
const FilterType: Record<string, string> = {
  automation2: 'automations',
  fields: 'fields',
  fields_v2: 'fields',
  forms_v2: 'forms',
  interactions: 'interactions',
  lead_sources: 'lead_sources',
  activities: 'logged_activities',
  library_messages: 'messages',
  scheduled_activities: 'scheduled_activities',
  related_object: 'related_object',
  subscription_lists: 'subscription_lists',
  surveys: 'surveys',
  tags: 'tags',
  association: 'team_interactions',
};

const ObjectTypes: { [k: string]: string } = {
  client: 'client_client',
  pipeline: 'pipeline',
  standard: 'standard',
};

const FieldConditionValues = {
  contains: 'contains',
  '!contains': 'does_not_contain',
  '!ends_with': 'does_not_end_with',
  '!=': (value: any) => {
    if (typeof value === 'boolean') return 'is_not_checked';
    return 'does_not_equal';
  },
  '!starts_with': 'does_not_start_with',
  '<': (value: any, field_type: string) => {
    if (field_type === 'files') return 'less_than_number';
    if (typeof value === 'string') return 'earlier_than'; // date
    return 'less_than';
  },
  '<=': (value: any) => {
    if (typeof value === 'string') return 'earlier_than_or_on'; // date
    return 'less_than_or_equal_to';
  },
  ends_with: 'ends_with',
  '=': (value: any, field_type: string) => {
    if (isMeTeamSelector(field_type, value)) return 'is_me';
    if (typeof value === 'boolean') return 'is_checked';
    return 'equals';
  },
  has: 'has',
  has_not: '!has',
  has_any: 'has_any',
  has_not_any: '!has_any',
  has_none: '!has_any',
  is_blank: (value: any, field_type: string, field_name: string) => {
    if (field_type === 'files') return 'none';
    if (field_name === 'titles') return 'is_blank';
    return value ? 'is_blank' : 'is_not_blank';
  },
  is_not_blank: 'is_not_blank',
  '>': (value: any, field_type: string) => {
    if (field_type === 'files') return 'more_than_number';
    if (typeof value === 'string') return 'later_than'; // date
    return 'more_than';
  },
  '>=': (value: any, field_type: string) => {
    if (field_type === 'files') return 'one_or_more';
    if (typeof value === 'string') return 'later_than_or_on'; // date
    return 'more_than_or_equal_to';
  },
  starts_with: 'starts_with',
};

const NoValueConditions = [
  'is_blank',
  'is_not_blank',
  'is_checked',
  'is_not_checked',
  'none',
  'one_or_more',
];

const AutomationSubtypes = [
  'number_active',
  'number_completed',
  'number_paused',
  'name',
  'created',
  'custom_object_id',
  'status',
];

/**
 * Builds the step values for use in a Filter instance from an existing filter request.
 * This generates the same output as the Filter classes `save` iterable. This already exists
 * on filter requests built from this package and exists under the `view_model` property.
 * This utility is needed for filter requests built using the prior system.
 *
 * @param root - a single filter request object i.e. `query[0].filters[0]`
 * @param vars - object identifiers the filter request was used for (id, type)
 * @returns - an array of filter stesp as tuples [step_id, value]
 */
export const convert = (root: any, vars: FilterVars) => {
  if (
    root?.type === 'automation2' &&
    AutomationSubtypes.includes(root?.subtype)
  ) {
    return convert_automation_filter(root);
  }

  const steps: [string, any][] = [];
  const type: string = FilterType[root?.type];

  if (isOwnerFilter(root)) {
    steps.push([
      'filter_type',
      { filter_type: 'owner', vars: Object.entries(vars) },
    ]);
    return convert_owner(root, steps);
  } else if (isStageFilter(root)) {
    steps.push([
      'filter_type',
      { filter_type: 'stage', vars: Object.entries(vars) },
    ]);
    return convert_stage(root, steps);
  } else {
    steps.push([
      'filter_type',
      { filter_type: type, vars: Object.entries(vars) },
    ]);
  }

  switch (type) {
    case 'automations': {
      steps.push([
        'automation',
        {
          url: `/automation2/automations/${root.automation_id}`,
          method: 'GET',
        },
      ]);
      const isAnyOfStatus = Array.isArray(root.status);
      steps.push(['status', isAnyOfStatus ? 'status_is_any_of' : root.status]);
      if (isAnyOfStatus) {
        steps.push(['statuses', root.status]);
      }
      break;
    }
    case 'forms':
    case 'surveys':
      convert_forms(root, steps, type);
      break;
    case 'fields':
      convert_fields(root, steps, vars);
      break;
    case 'interactions':
      convert_interactions(root, steps);
      break;
    case 'lead_sources':
      convert_lead_sources(root, steps);
      break;
    case 'logged_activities':
      convert_logged_activities(root, steps);
      break;
    case 'messages':
      convert_messages(root, steps);
      break;
    case 'scheduled_activities':
      convert_scheduled_activities(root, steps);
      break;
    case 'related_object':
      convert_related_object(root, steps, vars);
      break;
    case 'subscription_lists': {
      steps.push(['condition', root.status]);
      const ids = Array.isArray(root.subscription_list_ids)
        ? root.subscription_list_ids
        : [root.subscription_list_ids];
      steps.push([
        'value',
        ids.map((id: string) => ({
          url: `/subscription-list/${id}`,
          method: 'GET',
        })),
      ]);
      break;
    }
    case 'tags':
      steps.push(['condition', root.condition]);
      if (root.ids) {
        if (Array.isArray(root.ids)) {
          const configs = root.ids.map((id: string) => ({
            url: `/client/fields/${vars.client_tag_field_id}/options/${id}`,
            method: 'GET',
          }));
          steps.push(['value', configs]);
        } else {
          steps.push([
            'value',
            [
              {
                url: `/client/fields/${vars.client_tag_field_id}/options/${root.ids}`,
                method: 'GET',
              },
            ],
          ]);
        }
      }
      break;
    case 'team_interactions':
      convert_team_interactions(root, steps);
      break;
    default:
      throw Error(`Cannot convert v1 filter type ${root.type} into new format`);
  }

  return steps;
};

// Converts the automation page filters - not the automation filter of custom objects
const convert_automation_filter = (root: any) => {
  const steps: [string, any][] = [
    ['filter_type', { filter_type: root.subtype, vars: [] }],
    ['condition', root.condition],
  ];

  if (root.subtype === 'custom_object_id') {
    steps.push([
      'value',
      { method: 'GET', url: `/custom-objects/${root.value}` },
    ]);
  } else {
    steps.push(['value', root.value]);
  }

  return steps;
};

const convert_fields = (
  root: any,
  steps: [string, any][],
  vars: FilterVars
) => {
  const field = root.meta ? JSON.parse(root.meta) : root.field;

  if (!vars || vars.object_type === 'client_client') {
    steps.push([
      'field_id',
      {
        url: `/client/fields/${field.id}`,
        method: 'GET',
      },
    ]);
  } else if (vars.object_type === 'standard') {
    steps.push([
      'field_id',
      {
        url: `/custom-objects/${vars.custom_object_id}/fields/${field.id}`,
        method: 'GET',
      },
    ]);
  } else if (vars.object_type === 'pipeline') {
    steps.push([
      'field_id',
      {
        url: `/pipelines/${vars.custom_object_id}/fields/${field.id}`,
        method: 'GET',
      },
    ]);
  }

  const cond = (FieldConditionValues as any)[root.condition] ?? root.condition;
  const condition =
    typeof cond === 'function'
      ? cond(root.value, field.fieldType, field.name)
      : cond;
  steps.push(['condition', condition]);

  if (
    !NoValueConditions.includes(condition) &&
    !isMeTeamSelector(field.fieldType, root.value)
  ) {
    if (field.fieldType === 'dynamictags') {
      const build_config = (id: string) => {
        switch (vars.object_type) {
          case 'pipeline':
            return {
              url: `/pipelines/${vars.custom_object_id}/fields/${field.id}/options/${id}`,
              method: 'GET',
            };
          case 'standard':
            return {
              url: `/custom-objects/${vars.custom_object_id}/fields/${field.id}/options/${id}`,
              method: 'GET',
            };
          default:
            return {
              url: `/client/fields/${field.id}/options/${id}`,
              method: 'GET',
            };
        }
      };
      const configs = Array.isArray(root.value)
        ? root.value.map(build_config)
        : [build_config(root.value)];
      steps.push(['value', configs]);
    } else if (field.fieldType === 'relationship') {
      if (field.relation.fetchUrl === 'client') {
        const config = {
          url: `/client/${root.value[0]}`,
          method: 'GET',
        };
        steps.push(['value', config]);
      } else if (field.relation.fetchUrl === 'standard') {
        const config = {
          url: `/custom-objects/${field.relation.relatedObject}/entity-records/${root.value[0]}`,
          method: 'GET',
        };
        steps.push(['value', config]);
      } else {
        const config = {
          url: `/pipelines/${field.relation.relatedObject}/entity-records/${root.value[0]}`,
          method: 'GET',
        };
        steps.push(['value', config]);
      }
    } else if (isFieldWithOptions(field)) {
      if (Array.isArray(root.value)) {
        steps.push([
          'value',
          field.options.filter((option: any) => {
            return root.value.includes(option.id);
          }),
        ]);
      } else {
        steps.push([
          'value',
          field.options.find((option: any) => option.id === root.value),
        ]);
      }
    } else {
      steps.push(['value', root.value]);
    }
  }
};

const convert_forms = (
  root: any,
  steps: [string, any][],
  type: 'forms' | 'surveys'
) => {
  // form_id
  steps.push([
    'form_id',
    {
      url: `/${type}/${root?.form_id}`,
      method: 'GET',
    },
  ]);

  // submitted_condition
  steps.push(['submitted_condition', root?.payload?.submitted_condition]);

  // n_days_value
  if (isNumber(root?.payload?.n_days_value)) {
    steps.push(['n_days_value', root?.payload?.n_days_value]);
  }

  // submission
  steps.push(['submission', root?.payload?.submission]);

  if (root?.payload?.submission !== 'null') {
    // field_id
    steps.push([
      'field_id',
      {
        url: `/${type}/${root.form_id}/fields/${root.payload.field_id.id}`,
        method: 'GET',
      },
    ]);

    if (
      isMeTeamSelector(
        root.payload.field_id.field_type,
        root.payload.answer.value
      )
    ) {
      steps.push(['condition', 'is_me']);
    } else {
      // condition
      steps.push(['condition', root.payload.condition]);

      if (!NoValueConditions.includes(root.payload.condition)) {
        // value
        steps.push(['value', root.payload.answer.value]);
      }
    }
  }
};

const convert_interactions = (root: any, steps: [string, any][]) => {
  const { data_condition, interaction_type_condition, data_value } = root;

  steps.push(['interaction_type', interaction_type_condition]);
  steps.push(['condition', data_condition]);

  if (data_value) {
    steps.push(['value', data_value]);
  }
};

const convert_lead_sources = (root: any, steps: [string, any][]) => {
  const { payload } = root ?? {};
  const { condition_type, condition_value, content, source_type } =
    payload ?? {};
  const condition_lookup = {
    first_lead_source_true: 'first_lead_source',
    first_lead_source_false: '!first_lead_source',
    last_lead_source_true: 'last_lead_source',
    last_lead_source_false: '!last_lead_source',
    any_lead_source_true: 'any_lead_source',
    any_lead_source_false: '!any_lead_source',
  };
  const condition_key =
    `${condition_type}_${condition_value}` as keyof typeof condition_lookup;

  steps.push(['condition', condition_lookup[condition_key]]);
  steps.push(['source_type', source_type]);

  if (source_type === 'site_referral') {
    steps.push(['site_content', content?.condition ?? 'any']);
    if (content?.value) {
      steps.push(['site_value', content.value]);
    }
  }

  if (source_type === 'google_ads') {
    if (payload.campaign !== undefined) {
      steps.push(['ad_type', 'ad_campaign']);
      steps.push(['ad_campaign', payload.campaign?.value ?? null]);
    } else if (payload.medium !== undefined) {
      steps.push(['ad_type', 'ad_group']);
      steps.push(['ad_group', payload.medium?.value ?? null]);
    } else if (payload.term !== undefined) {
      steps.push(['ad_type', 'ad']);
      steps.push(['ad', payload.term?.value ?? null]);
    } else {
      steps.push(['ad_type', 'ad_account']);
      steps.push(['ad_account', payload.source?.value ?? null]);
    }
  }

  if (source_type === 'social') {
    if (payload.source === undefined) {
      steps.push(['social_type', null]);
    } else {
      steps.push(['social_type', 'source']);
      steps.push(['social_source', payload.source.value]);
    }
  }

  if (source_type === 'utm') {
    if (payload.source !== undefined) {
      steps.push(['utm_type', 'source']);
      steps.push(['utm_source', payload.source?.value ?? null]);
    } else if (payload.campaign !== undefined) {
      steps.push(['utm_type', 'campaign']);
      const { campaign: c } = payload;
      steps.push(['utm_campaign', c.condition === 'any' ? null : c.value]);
    } else if (payload.medium !== undefined) {
      steps.push(['utm_type', 'medium']);
      steps.push(['utm_medium', payload.medium?.value ?? null]);
    } else if (payload.term !== undefined) {
      steps.push(['utm_type', 'term']);
      steps.push(['utm_term', payload.term?.value ?? null]);
    } else if (payload.content !== undefined) {
      steps.push(['utm_type', 'content']);
      steps.push(['utm_content', payload.content?.value ?? null]);
    } else {
      steps.push(['utm_type', null]);
    }
  }

  if (source_type === 'custom') {
    if (payload.source !== undefined) {
      steps.push(['custom_type', 'source']);
      steps.push(['custom_source', payload.source?.value ?? null]);
    } else if (payload.campaign !== undefined) {
      steps.push(['custom_type', 'campaign']);
      const { campaign: c } = payload;
      steps.push(['custom_campaign', c.condition === 'any' ? null : c.value]);
    } else if (payload.medium !== undefined) {
      steps.push(['custom_type', 'medium']);
      steps.push(['custom_medium', payload.medium?.value ?? null]);
    } else if (payload.term !== undefined) {
      steps.push(['custom_type', 'term']);
      steps.push(['custom_term', payload.term?.value ?? null]);
    } else if (payload.content !== undefined) {
      steps.push(['custom_type', 'content']);
      steps.push(['custom_content', payload.content?.value ?? null]);
    } else {
      steps.push(['custom_type', null]);
    }
  }
};

const convert_logged_activities = (root: any, steps: [string, any][]) => {
  const { payload: p } = root;
  const condition = root.activity_object_id ? 'specific' : 'any';
  steps.push(['condition', condition]);

  if (condition === 'specific') {
    steps.push(['activity', root.activity_object_id]);
  }

  if (p.time?.past) {
    steps.push(['time', p.submitted ? 'past_n_days' : 'not_past_n_days']);
    steps.push(['n_days_value', p.time.days]);
  } else {
    steps.push(['time', p.submitted ? 'any_time' : 'never']);
  }

  if (typeof p.by?.team_member === 'boolean') {
    if (p.by.team_member) {
      if (p.by.which === 'is_me') {
        steps.push(['assigned_condition', 'is_me']);
      } else {
        steps.push(['assigned_condition', 'specific_team_member']);
        steps.push([
          'team_member',
          { url: `/team/${p.by.which}`, method: 'GET' },
        ]);
      }
    } else {
      steps.push(['assigned_condition', 'specific_role']);
      steps.push(['role', p.by.which]);
    }
  } else {
    steps.push(['assigned_condition', 'any_team_member']);
  }
};

const convert_messages = (root: any, steps: [string, any][]) => {
  const type = !root.message_ids?.[0] ? 'any' : 'specific';
  steps.push(['message_type', type]);

  if (type === 'specific') {
    const message_id = root.message_ids[0];
    const message_type = root.meta[0].type;
    const base_url =
      message_type === 'automations'
        ? '/messages/automations'
        : '/messages/broadcasts';
    steps.push([
      'message_picker',
      { url: `${base_url}/${message_id}`, method: 'GET' },
    ]);
  }

  steps.push([
    'message_status',
    root.operator === 'has_no_matches' ? `not_${root.event}` : root.event,
  ]);

  if (typeof root.last_n_days === 'number') {
    steps.push(['sent_condition', 'past_n_days']);
    steps.push(['n_days_value', root.last_n_days]);
  } else {
    steps.push(['sent_condition', 'any_time']);
  }
};

const convert_owner = (root: any, steps: [string, any][]) => {
  if (root.value === 'is_me') {
    steps.push(['condition', 'is_me']);
  } else {
    steps.push(['condition', root.condition]);
    steps.push(['team_member', { url: `/team/${root.value}`, method: 'GET' }]);
  }

  return steps;
};

const convert_scheduled_activities = (root: any, steps: [string, any][]) => {
  steps.push(['condition', root.activity_type]);

  if (root.activity_type === 'specific') {
    steps.push([
      'activity',
      {
        url: `/activities/${root.activity_object_id}`,
        method: 'GET',
        error_message: { '404': 'The activity has been deleted' },
      },
    ]);
  }

  steps.push(['time', root.scheduled_condition]);

  if (typeof root.n_days === 'number') {
    steps.push(['n_days_value', root.n_days]);
  }

  if (root.assigned_id === 'is_me') {
    steps.push(['assigned_condition', 'is_me']);
  } else {
    steps.push(['assigned_condition', root.assigned_condition]);

    if (root.assigned_condition === 'specific_role') {
      steps.push(['role', root.assigned_id]);
    } else if (root.assigned_condition === 'specific_member') {
      steps.push([
        'team_member',
        { url: `/team/${root.assigned_id}`, method: 'GET' },
      ]);
    }
  }
};

const convert_related_object = (
  root: any,
  steps: [string, any][],
  vars: FilterVars
) => {
  if (!vars || vars.object_type === 'client_client') {
    steps.push([
      'relationshipField',
      {
        url: `/client/fields/${root.field_id}`,
        method: 'GET',
      },
    ]);
  } else if (vars.object_type === 'standard') {
    steps.push([
      'relationshipField',
      {
        url: `/custom-objects/${vars.custom_object_id}/fields/${root.field_id}`,
        method: 'GET',
      },
    ]);
  } else if (vars.object_type === 'pipeline') {
    steps.push([
      'relationshipField',
      {
        url: `/pipelines/${vars.custom_object_id}/fields/${root.field_id}`,
        method: 'GET',
      },
    ]);
  }

  steps.push(['relationshipCondition', root.condition]);

  if (root.condition === 'custom_filter') {
    const nested_root = root.value.query[0].filters[0];
    const filter_type = isOwnerFilter(nested_root)
      ? 'owner'
      : FilterType[nested_root.type];
    const vars = [
      ['custom_object_id', root.field_metadata.relation.related_object],
      ['object_type', ObjectTypes[root.field_metadata.relation.fetch_url]],
    ];
    steps.push(['relationshipFilterType', filter_type]);
    steps.push([
      'filter',
      {
        filter_type,
        is_filter: true,
        step_data: convert(
          root.value.query[0].filters[0],
          Object.fromEntries(vars)
        ),
        vars,
      },
    ]);
  } else {
    steps.push(['relationshipGroup', root.group_ids]);
  }
};

const convert_team_interactions = (root: any, steps: [string, any][]) => {
  steps.push(['interaction', root.interacted]);

  if (!root.with) {
    steps.push(['team_member_type', 'any_team_member']);
  } else if (root.with === 'is_me') {
    steps.push(['team_member_type', 'is_me']);
  } else if (root.subtype === 'role') {
    steps.push(['team_member_type', 'specific_role']);
    steps.push(['role', root.with]);
  } else {
    steps.push(['team_member_type', 'specific_team_member']);
    steps.push(['team_member', { url: `/team/${root.with}`, method: 'GET' }]);
  }

  steps.push(['submitted_condition', root.time_past]);

  if (root.time_past === 'true_true') {
    steps.push(['n_days_value', root.past_n_days]);
  }
};

const comparisonConditionDateDictionary: Record<string, string> = {
  '=': 'on',
  '!=': 'not_on',
  '<': 'earlier_than',
  '<=': 'earlier_than_or_on',
  '>': 'later_than',
  '>=': 'later_than_or_on',
};
const comparisonConditionTimeDictionary: Record<string, string> = {
  '<': 'less_than',
  '>': 'more_than',
};

const comparisonConditionDictionary: Record<string, any> = {
  entered_stage: comparisonConditionDateDictionary,
  left_stage: comparisonConditionDateDictionary,
  time_in_stage: comparisonConditionTimeDictionary,
};

const convert_stage = (root: any, steps: [string, any][]) => {
  const complexCondtionTypes = ['entered_stage', 'left_stage', 'time_in_stage'];

  steps.push(['condition', root.condition]);
  steps.push(['value', root.value]);

  if (complexCondtionTypes.includes(root.condition)) {
    steps.push([
      'comparison_condition',
      comparisonConditionDictionary[root.condition]?.[
        root.comparison_condition
      ] ?? root.comparison_condition,
    ]);
    steps.push(['comparison_value', root.comparison_value]);
    if (root.condition === 'time_in_stage') {
      steps.push(['comparison_type', root.comparison_type]);
    }
  }

  return steps;
};
