// import React from 'react';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import Card, { SettingsContentCard } from 'components/Card/Card';
import KizenErrorCard from 'components/Kizen/ErrorCard';
import { fieldMargin } from 'components/Builders/CustomFieldsBuilder/Field';

export const BuilderLoader = styled(Loader)`
  padding: ${gutters.spacing(20)}px 0 ${gutters.spacing(40)}px;
`;

export const Header = styled.div`
  width: 100%; // width set to 1372px as per design
  margin: ${gutters.spacing(4)}px 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
`;

export const HeaderSection = styled.div``;

export const PageSizing = styled.div`
  margin: 0 ${gutters.standard};
`;

export const BigCard = styled(Card)`
  padding-top: ${gutters.spacing(3)}px;
  margin-bottom: ${gutters.spacing(4)}px;
  display: flex;
  flex-direction: column;
  > div:last-of-type {
    flex: 1;
  }
  max-width: 100%;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-bottom: ${gutters.spacing(2, -4)}px;
  h3 {
    margin-top: -1px; // Design nudge
    line-height: 1; // Sidestep bootstrap
  }
`;

export const FieldLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${gutters.spacing(4) - fieldMargin}px
    ${gutters.spacing(0, -fieldMargin)}px;
`;

export const ErrorCard = styled(KizenErrorCard)`
  margin-top: -1px;
`;

export const StyledSettingsContentCard = styled(SettingsContentCard)`
  margin: ${gutters.spacing(3)}px auto ${gutters.spacing(4)}px;
`;
