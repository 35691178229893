import styled from '@emotion/styled';
import { isNotAllowedObject, isNotFoundObject } from '@kizen/filters/validate';
import type { NextArgs } from '@kizen/filters/filter';
import { Next, Option, StepData } from '@kizen/filters/types';
import {
  PropsWithChildren,
  useEffect,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';

import Overlay from 'react-bootstrap/Overlay';

import { KizenTypography } from '__app/typography';

import { Date } from './inputs/Date';
import { DateRange } from './inputs/DateRange';
import { DateTime } from './inputs/DateTime';
import { Decimal } from './inputs/Decimal';
import { Dropdown } from './inputs/Dropdown';
import { DropdownInfinite } from './inputs/DropdownInfinite';
import { EmailAddress } from './inputs/EmailAddress';
import { GroupedDropdown } from './inputs/GroupedDropdown';
import { Integer } from './inputs/Integer';
import { IntegerRange } from './inputs/IntegerRange';
import { DecimalRange } from './inputs/DecimalRange';
import { LibraryMessage } from './inputs/LibraryMessage';
import { LongText } from './inputs/LongText';
import { MultiSelect } from './inputs/MultiSelect';
import { MultiSelectInfinite } from './inputs/MultiSelectInfinite';
import { MultiSelectOne } from './inputs/MultiSelectOne';
import { PhoneNumber } from './inputs/PhoneNumber';
import { Price } from './inputs/Price';
import { PriceRange } from './inputs/PriceRange';
import { InputContainer } from './inputs/styled';
import { TeamSelector } from './inputs/TeamSelector';
import { Text } from './inputs/Text';
import { FilterContext } from './filter-context';
import { ErrorCard, ErrorCardWrapper, PrefixText } from './styled';

type DropdownPayload = {
  value: Option['value'] | Option['value'][];
  next?: Next[];
};

type FilterProps = PropsWithChildren<{
  filterType?: string | null;
  objectType?: 'client_client' | 'pipeline' | 'standard' | null;
  steps: [string, StepData][];
  errors: Record<string, string>;
  modalLayer?: number;
  translateY?: number | null;
  next(...args: NextArgs): void;
  set(key: string, value: any): void;
  onChange?(key: string, value: any): void;
  hideOutOfBoundaries?: boolean;
}>;

type FilterInputProps = StepData & {
  step: string;
  error?: string | boolean;
  modalLayer?: number;
  triggerValidation?: null | number;
  onNext: FilterProps['next'];
  onSet: FilterProps['set'];
  onChange?(key: string, value: any): void;
  translateY?: number | null;
  hideOutOfBoundaries?: boolean;
};

const STEP_GAP = 15;

export const FilterInputContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-left: ${STEP_GAP}px;
`;

const FilterInput = ({
  step,
  value,
  error,
  modalLayer,
  triggerValidation,
  onNext,
  onSet,
  onChange,
  translateY = null,
  hideOutOfBoundaries,
  ...config
}: FilterInputProps) => {
  const { filters } = useSelector(selectFilterMetadataDefinition);
  const containerRef = useRef<any>();
  const [, render] = useState(0);
  const { input_type, width, paginated, group_url } = config;
  const hasError = Boolean(error);
  const hasErrorMessage = hasError && typeof error === 'string';
  const [showError, setShowError] = useState(hasError);

  useEffect(() => {
    if (triggerValidation || hasError) {
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    } else {
      setShowError(false);
    }
  }, [triggerValidation, hasError]);

  const handleDropdownChange = useCallback(
    (step: string, { value, next: n }: DropdownPayload) => {
      onSet(step, value);
      if (n) {
        const meta = typeof value === 'string' ? filters[value] : undefined;
        onNext(step, n, meta);
      }
      onChange?.(step, value);
    },
    [filters, onChange, onNext, onSet]
  );

  const handleValueChange = useCallback(
    (step: string, value: any) => {
      onSet(step, value);
      onChange?.(step, value);
    },
    [onSet, onChange]
  );

  const ref = useCallback((node: any) => {
    containerRef.current = node;
    if (node) render((r) => r + 1);
  }, []);

  return (
    <InputContainer ref={ref} data-qa-filter-step={step} width={width}>
      {input_type === 'choices' && Boolean(group_url) ? (
        <GroupedDropdown
          {...config}
          value={value}
          group_url={group_url}
          step={step}
          error={hasError && showError}
          onChange={handleDropdownChange}
        />
      ) : input_type === 'choices' && paginated ? (
        <DropdownInfinite
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleDropdownChange}
        />
      ) : input_type === 'choices' && !paginated ? (
        <Dropdown
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleDropdownChange}
        />
      ) : input_type === 'date' ? (
        <Date
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'date_range_inclusive' ? (
        <DateRange
          {...config}
          value={value}
          step={step}
          width={width}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'datetime' ? (
        <DateTime
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'datetime_utc' ? (
        <DateTime
          {...config}
          utc
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'decimal' ? (
        <Decimal
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'decimal_range_inclusive' ? (
        <DecimalRange
          {...config}
          value={value}
          step={step}
          width={width ? width - STEP_GAP : undefined}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'dropdown' || input_type === 'timezone' ? (
        <Dropdown
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleDropdownChange}
        />
      ) : input_type === 'email' ? (
        <EmailAddress
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'integer' ? (
        <Integer
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'integer_range_inclusive' ? (
        <IntegerRange
          {...config}
          value={value}
          step={step}
          width={width ? width - STEP_GAP : undefined}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'library_message' ? (
        <LibraryMessage
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'longtext' ? (
        <LongText
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'multiselect' && paginated ? (
        <MultiSelectInfinite
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleDropdownChange}
        />
      ) : input_type === 'multiselect' && !paginated ? (
        <MultiSelect
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleDropdownChange}
        />
      ) : input_type === 'multiselect_one' ? (
        <MultiSelectOne
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleDropdownChange}
        />
      ) : input_type === 'money' ? (
        <Price
          {...config}
          currencySymbol={(config as any).currency_symbol}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'money_range_inclusive' ? (
        <PriceRange
          {...config}
          currencySymbol={(config as any).currency_symbol}
          value={value}
          step={step}
          width={width ? width - STEP_GAP : undefined}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'phonenumber' ? (
        <PhoneNumber
          {...config}
          enableExtension={(config as any).enable_extension}
          defaultCountry={(config as any).default_country}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : input_type === 'status' ? (
        <Dropdown
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          isStatus={true}
          onChange={handleDropdownChange}
        />
      ) : input_type === 'team_selector' ? (
        <TeamSelector
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      ) : (
        <Text
          {...config}
          value={value}
          step={step}
          error={hasError && showError}
          onChange={handleValueChange}
        />
      )}
      {/* @ts-expect-error - The Overlay types are wrong */}
      <Overlay
        placement="bottom-start"
        show={hasErrorMessage && showError}
        target={containerRef.current}
      >
        <ErrorCardWrapper
          modalLayer={modalLayer}
          hideOutOfBoundaries={hideOutOfBoundaries}
        >
          <ErrorCard
            show={hasErrorMessage && showError}
            duration="300ms"
            translateX={input_type === 'phonenumber' ? 85 : 0}
            translateY={translateY}
          >
            <KizenTypography>{error}</KizenTypography>
          </ErrorCard>
        </ErrorCardWrapper>
      </Overlay>
    </InputContainer>
  );
};

/**
 * @remarks Concerning errors, we only ever show the first error. Multiple UI validation errors in the same filter
 * is rare. When loading in invalid filter there will likely be errors in in the inputs that follow the first errror,
 * but those inputs will be removed/clear when a selection is made on the first error input. When showing the error
 * message on an input *from the the API* we set the value to null so the placeholder shows.
 *
 * `onChange` will only be called for dropdown inputs since those are what trigger changes in the number/type of inputs.
 *
 * Errors from the API are dismsised after 3 seconds to match the frontend validation errors (the `errors` prop is
 * configured to have 3 second timeout in the parent).
 */
export const Filter = ({
  filterType = null,
  objectType = null,
  steps = [],
  errors,
  modalLayer,
  onChange,
  next,
  set,
  translateY = null,
  hideOutOfBoundaries = false,
}: FilterProps) => {
  const errorAt = useRef(Infinity);

  const [triggerValidation, setTriggerValidation] = useState<null | number>(
    Object.keys(errors).length > 0 ? Math.random() : null
  );

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setTriggerValidation(Math.random());
    }
  }, [errors]);

  let relation = null;
  if (steps.length && steps[0][0] === 'relationshipField') {
    relation = steps[0]?.[1]?.value?.relation ?? null;
  }

  return (
    <FilterContext
      filterType={filterType}
      objectType={objectType}
      relation={relation}
    >
      {steps.map(([step, { value, input_type, ...config }], idx, arr) => {
        const key = arr
          .slice(0, idx)
          .map(([s, { value: v }]) => `${s}_${JSON.stringify(v)}`)
          .join('')
          .concat(`_${step}`);
        const { prefix, suffix } = config;
        let errorData = value ?? {};
        if (input_type === 'multiselect_one') {
          errorData = value?.[0] ?? {};
        } else if (input_type === 'multiselect' && Array.isArray(value)) {
          errorData =
            value.find(
              (opt: any) => isNotAllowedObject(opt) || isNotFoundObject(opt)
            ) ?? {};
        }
        const {
          error = false,
          not_found = false,
          not_allowed = false,
          error_message = null,
        } = errorData;
        const loadingError = error || not_found || not_allowed;

        const errorMessage =
          (loadingError && error_message) || errors[step] || loadingError;

        if (idx === 0) errorAt.current = Infinity;
        if (idx < errorAt.current && Boolean(errorMessage)) {
          errorAt.current = idx;
        }

        // `not_found` and `not_allowed` are provided by the backend and have error values we want to display
        // `error` is set in the frontend when loading (@kizen/filters/src/load) a filter and does not have
        // any other useful data for the dropdown options
        const cannotShowErrorValue = error && !not_found && !not_allowed;

        return (
          <FilterInputContainer key={key}>
            {prefix && (
              <PrefixText>
                <KizenTypography>{prefix}</KizenTypography>
              </PrefixText>
            )}
            <FilterInput
              step={step}
              value={cannotShowErrorValue ? null : value}
              error={errorAt.current === idx && errorMessage}
              modalLayer={modalLayer}
              translateY={translateY}
              triggerValidation={triggerValidation}
              onNext={next}
              onSet={set}
              onChange={onChange}
              hideOutOfBoundaries={hideOutOfBoundaries}
              input_type={input_type}
              {...config}
            />
            {suffix && (
              <PrefixText>
                <KizenTypography>{suffix}</KizenTypography>
              </PrefixText>
            )}
          </FilterInputContainer>
        );
      })}
    </FilterContext>
  );
};
