import { Extension } from '@tiptap/core';

export const FontFamily = Extension.create({
  name: 'fontFamily',

  defaultOptions: {
    types: ['textStyle'],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontFamily: {
            parseHTML: (element) =>
              element.style.fontFamily.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {};
              }

              return {
                style: `font-family: ${attributes.fontFamily}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontFamily:
        (fontFamily) =>
        ({ chain, tr }) => {
          const { attrs } =
            tr.storedMarks?.find((m) => m.type.name === 'textStyle') ?? {};
          return chain()
            .setMark('textStyle', { ...attrs, fontFamily })
            .run();
        },
      unsetFontFamily:
        () =>
        ({ chain, tr }) => {
          const { attrs } =
            tr.storedMarks?.find((m) => m.type.name === 'textStyle') ?? {};
          return chain()
            .setMark('textStyle', { ...attrs, fontFamily: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
