import { components } from 'react-select';
import Icon from 'components/Kizen/Icon';
import { StyledIcon } from 'components/MultiColumnBuilder/components/BaseOption/styles';

const IconSelectorSingleValue = ({
  children,
  innerRef,
  innerProps,
  ...rest
}) => {
  const { selectProps } = rest;
  const { displayIsDefault, displaySelectValue, lastSavedValue } = selectProps;

  return (
    <components.SingleValue {...rest} ref={innerRef}>
      {displayIsDefault ? (
        <StyledIcon
          className="ElementIcon"
          icon={lastSavedValue?.value ?? lastSavedValue}
        />
      ) : (
        <>
          {displaySelectValue.className ? (
            <i className={`${displaySelectValue.className} fa-fw`} />
          ) : (
            <Icon
              className="DefaultIcon"
              icon={lastSavedValue?.value ?? lastSavedValue}
            />
          )}
        </>
      )}
    </components.SingleValue>
  );
};

export default IconSelectorSingleValue;
