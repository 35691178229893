import { useSelector } from 'react-redux';
import {
  getUserProfile,
  getKeyAndTokenAccess,
  getApiKeysAccess,
} from 'store/authentication/selectors';
import APIKeys from '../Profile/Forms/APIKeys';
import OAuth from '../Profile/Forms/OAuth/index';
import { throwPermissionError } from 'pages/Settings/helpers';
import {
  Container,
  StyledCols as Cols,
  Sections,
  GoLiveStyledSection,
  CardContainer,
} from './../styles/common';

const ApiConnections = ({ props }) => {
  const user = useSelector(getUserProfile);
  const chosenBusinessId = useSelector(
    ({ authentication }) =>
      !!authentication.chosenBusiness && authentication.chosenBusiness.id
  );

  const keyAndTokenAccess = useSelector(getKeyAndTokenAccess);

  if (!keyAndTokenAccess) {
    throwPermissionError();
  }

  const keyAccess = useSelector(getApiKeysAccess);

  return (
    <GoLiveStyledSection>
      <Sections>
        <Container>
          <Cols columns={2} gutter={1}>
            {keyAccess ? (
              <CardContainer>
                <APIKeys
                  userId={user && user.id}
                  businessId={chosenBusinessId}
                />
              </CardContainer>
            ) : (
              false
            )}
            <CardContainer>
              <OAuth userId={user && user.id} businessId={chosenBusinessId} />
            </CardContainer>
          </Cols>
        </Container>
      </Sections>
    </GoLiveStyledSection>
  );
};

export default ApiConnections;
