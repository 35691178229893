import {
  FieldLike,
  FieldType,
  PrimaryObjectUploadStep,
  SmartConnectorFlow,
  SmartConnectorFlowLoadDTO,
} from '__pages/SmartConnectors/types';
import {
  PRIMARY_OBJECT_UPLOAD_STEP_DATA_BY_STEP,
  PRIMARY_OBJECT_UPLOAD_STEPS,
  SMART_FIELD_TYPES,
} from '__pages/SmartConnectors/constants';
import { isDefaultEmailField } from '__checks/fields';

export const getStepData = (
  data: SmartConnectorFlow,
  step: PrimaryObjectUploadStep
) => {
  const stepProps = PRIMARY_OBJECT_UPLOAD_STEP_DATA_BY_STEP[step];

  return stepProps.reduce<Partial<SmartConnectorFlow>>((acc, prop) => {
    return {
      ...acc,
      [prop]: data[prop],
    };
  }, {});
};

const propsByStep: Record<
  PrimaryObjectUploadStep,
  (keyof Omit<
    SmartConnectorFlowLoadDTO,
    'id' | 'type' | 'custom_object' | 'order'
  >)[]
> = {
  [PRIMARY_OBJECT_UPLOAD_STEPS.matching]: ['matching_rules'],
  [PRIMARY_OBJECT_UPLOAD_STEPS.mapping]: ['field_mapping_rules'],
  [PRIMARY_OBJECT_UPLOAD_STEPS.preview]: [
    'execution_variable',
    'newly_created_records_automations',
    'other_matches_records_automations',
  ],
};

export const getSmartConnectorFlowLoadsDTO = (
  data: Partial<SmartConnectorFlow>,
  loadId: string,
  step: PrimaryObjectUploadStep
) => {
  const flow: { loads?: Partial<SmartConnectorFlowLoadDTO>[] } = {};

  if (data.loads) {
    flow.loads = data.loads.map((load, i) => {
      const loadPayload: Partial<SmartConnectorFlowLoadDTO> = {
        id: load.id,
        custom_object: load.custom_object,
        order: i,
      };
      if (loadId === load.id || (loadId === 'new' && !load.id)) {
        propsByStep[step].forEach((prop) => {
          switch (prop) {
            case 'execution_variable': {
              loadPayload[prop] = load[prop];
              break;
            }
            case 'field_mapping_rules': {
              loadPayload[prop] = (load.field_mapping_rules || []).map(
                (rule, j) => ({
                  ...rule,
                  field:
                    !rule.field || rule.field.startsWith('new_')
                      ? undefined
                      : rule.field,
                  variables: rule.variables?.length
                    ? rule.variables
                    : undefined,
                  display_order: j,
                })
              );
              break;
            }
            case 'matching_rules': {
              loadPayload[prop] = (load.matching_rules || []).map(
                (rule, j) => ({
                  ...rule,
                  order: j,
                })
              );
              break;
            }
            case 'newly_created_records_automations': {
              loadPayload[prop] = (
                load.newly_created_records_automations || []
              ).map(({ id }) => id);
              break;
            }
            case 'other_matches_records_automations': {
              loadPayload[prop] = (
                load.other_matches_records_automations || []
              ).map(({ id }) => id);
              break;
            }
            default:
              break;
          }
        });
      }

      // We don't want to touch other loads, so we pass only id, custom_object and order
      // for rest of the values values to not to be touched and validated on BE
      return loadPayload;
    });
  }

  return { flow };
};

export const canFieldBeMatchedToArray = (field: FieldLike) => {
  if (isDefaultEmailField(field)) {
    return true;
  }
  if (
    field.fieldType === SMART_FIELD_TYPES.relationship &&
    ['one_to_many', 'many_to_many'].includes(field.relation?.cardinality)
  ) {
    return true;
  }
  if (
    (
      [
        SMART_FIELD_TYPES.dynamictags,
        SMART_FIELD_TYPES.checkboxes,
      ] as FieldType[]
    ).includes(field.fieldType)
  ) {
    return true;
  }

  return false;
};
