import { useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

import { TextSpan } from 'app/typography';
import {
  StyledLabelWrapper,
  StyledLabel,
  StyledFileInputWrapper,
  StyledPlaceholder,
  StyledFile,
  UploadContainer,
  PreviewImageContainer,
} from './styles';

export const UPLOAD_INPUT_TYPES = {
  AVATAR: 'AVATAR',
  LOGO: 'LOGO',
};

export const ACCEPTED_FILES = 'image/jpeg,image/png,image/gif,image/svg+xml';

const FileInputWithImage = ({
  className = '',
  label = '',
  actionLabel,
  onChange,
  onRemove = () => {},
  id = '',
  imagePreviewSource = { name: '', url: '' },
  type = UPLOAD_INPUT_TYPES.LOGO,
  file = null,
  showImage = true,
  acceptedExtensions = ACCEPTED_FILES,
  ...props
}) => {
  const [filenameProps, filenameTooltip] = useTruncationTooltip();

  const inputFile = useRef(null);

  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFile) => onChange(acceptedFile),
    [onChange]
  );

  const { getRootProps } = useDropzone({ onDrop });

  const handleFileUpload = () => inputFile.current && inputFile.current.click();

  const removeFile = ({ event }) => {
    event.stopPropagation();
    inputFile.current.value = null;
    onRemove();
  };

  return (
    <div className={className}>
      <StyledLabelWrapper htmlFor={id} {...getRootProps()}>
        {label && <StyledLabel>{label}</StyledLabel>}
        <UploadContainer>
          <input
            ref={inputFile}
            type="file"
            hidden
            onChange={(e) => onChange(e.target.files)}
            accept={acceptedExtensions}
            {...props}
          />

          {showImage ? (
            <PreviewImageContainer
              type={type}
              url={imagePreviewSource.url}
              onClick={handleFileUpload}
              style={{
                backgroundImage:
                  type === UPLOAD_INPUT_TYPES.AVATAR
                    ? `url(${imagePreviewSource.url})`
                    : '',
              }}
            >
              {!imagePreviewSource && type === UPLOAD_INPUT_TYPES.AVATAR && (
                <TextSpan>Preview</TextSpan>
              )}
              {imagePreviewSource &&
                type === UPLOAD_INPUT_TYPES.LOGO &&
                imagePreviewSource.url && (
                  <img src={imagePreviewSource.url} alt="Logo" />
                )}
            </PreviewImageContainer>
          ) : null}

          {type === UPLOAD_INPUT_TYPES.LOGO && (
            <Button variant="text" onClick={handleFileUpload}>
              {actionLabel}
            </Button>
          )}

          {type === UPLOAD_INPUT_TYPES.AVATAR && (
            <StyledFileInputWrapper
              onClick={handleFileUpload}
              showImage={showImage}
            >
              {(file || (imagePreviewSource && imagePreviewSource.url)) && (
                <StyledFile
                  created={
                    (file && file.created !== false) || imagePreviewSource
                  }
                >
                  <button
                    type="button"
                    onClick={(event) => removeFile({ event })}
                  >
                    {t('remove file')}
                  </button>
                  {filenameTooltip}
                  <TextEllipsisWithTooltip {...filenameProps}>
                    {(file && (file.name || file.path)) ||
                      imagePreviewSource.name}
                  </TextEllipsisWithTooltip>
                </StyledFile>
              )}
              <StyledPlaceholder>
                {file ? t('Change File') : t('Add File')}{' '}
                <span>{t('or drop file here')}</span>
              </StyledPlaceholder>
            </StyledFileInputWrapper>
          )}
        </UploadContainer>
      </StyledLabelWrapper>
    </div>
  );
};

export default FileInputWithImage;
