import { useEffect, useMemo, useState } from 'react';
import MultiColumnBuilder from 'components/MultiColumnBuilder';
import useBasicBuilder, {
  RIGHT_ID,
  LEFT_ID,
} from 'components/MultiColumnBuilder/useBasicBuilder';
import KizenTypography from 'app/kizentypo';
import { fontWeights } from 'app/typography';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import { useTranslation } from 'react-i18next';
import {
  StyledDropPlaceholder,
  StyledInputControl,
  StyledTextEllipsisWithTooltip,
} from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/styles';
import EditableTextEllipsisWithTooltip from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/EditableTextEllipsisWithTooltip';
import { HeaderPreview } from './HeaderPreview';
import { StyledDndColumns, StyledDragItem } from '../styles';
import { RELATED_ENTITITY_QUICK_FILTER_FIELD } from '../constants';
import IconButton from 'components/Kizen/IconButton';
import { colorsButton } from 'app/colors';
import { RelatedFilterModal } from './ReletedFilterModal';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';

const selfDisabledDropZones = [LEFT_ID];

const RightEmpty = ({ dropZone, id }) => {
  const { t } = useTranslation();
  return dropZone?.sectionId === id ? (
    <StyledDropPlaceholder>
      <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
        <KizenTypography>{t('Place Here')}</KizenTypography>
      </StyledDragItem>
    </StyledDropPlaceholder>
  ) : (
    <StyledDropPlaceholder noHover>
      <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
        <KizenTypography>
          {t('No Fields Currently Selected (Drag and Drop to Add)')}
        </KizenTypography>
      </StyledDragItem>
    </StyledDropPlaceholder>
  );
};

const Option = ({ setShowModal, ...renderProps }) => {
  const { t } = useTranslation();
  const { element, swapItemColumn, index, editable, editValue } = renderProps;

  const isDynamic = element.fieldId === RELATED_ENTITITY_QUICK_FILTER_FIELD;

  const deleteItem = () => {
    swapItemColumn(index, RIGHT_ID, true);
  };

  const onBlur = () => {
    if (element.label.trim() === '') {
      editValue(index, undefined, 'label', element.defaultLabel);
    }
  };

  const onChangeLabel = (value) => {
    editValue(index, undefined, 'label', value);
  };

  const onSelectOption = ({ value }) => {
    if (value === 'remove') {
      deleteItem();
    } else if (value === 'edit') {
      setShowModal({
        index,
        ...element,
      });
    }
  };

  const relatedFieldFilterOptions = useMemo(
    () => [
      {
        value: 'remove',
        label: t('Remove Quick Filter'),
      },
      {
        value: 'edit',
        label: t('Edit Options'),
      },
    ],
    [t]
  );

  return (
    <div>
      <StyledDragItem
        {...renderProps}
        isDropzone
        className="innerItem"
        onBlur={onBlur}
        data-qa-label={
          isDynamic
            ? t('New Related Entity Quick Filter Field (Drag to Create)')
            : element.label
        }
      >
        {editable ? (
          <>
            <EditableTextEllipsisWithTooltip
              sizing="dense"
              value={element.label}
              placeholder={t('Enter Display Name')}
              onChange={onChangeLabel}
            />
            {element.relatedObjectFieldId ? (
              <IconButtonMenu
                title={t('Edit Settings')}
                position="left"
                sizing="dense"
                color={colorsButton.iconGray}
                options={relatedFieldFilterOptions}
                onChange={onSelectOption}
                restrictCloseHandler
                overlay
              >
                <Icon icon="three-dot" />
              </IconButtonMenu>
            ) : (
              <IconButton
                sizing="dense"
                color={colorsButton.iconGray}
                onClick={deleteItem}
                title={t('Remove Quick Filter')}
              >
                <IconSizing size="16px">
                  <Icon icon="delete" />
                </IconSizing>
              </IconButton>
            )}
          </>
        ) : (
          <StyledTextEllipsisWithTooltip isDynamic={isDynamic}>
            {isDynamic
              ? t('New Related Entity Quick Filter Field (Drag to Create)')
              : element.label}
          </StyledTextEllipsisWithTooltip>
        )}
      </StyledDragItem>
    </div>
  );
};

export const DraggableColumns = ({
  leftItems,
  rightItems,
  onChange,
  isTemplate,
  fields,
}) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(null);

  const onHideModal = () => {
    if (!showModal?.relatedObjectFieldId) {
      rightColumnProps.removeItem(showModal.index);
    }
    setShowModal(null);
  };

  const {
    rightColumnItems,
    leftColumnProps: _leftColumnProps,
    setOptionsSearchTerm,
    rightColumnProps,
    ...props
  } = useBasicBuilder({
    initialLeftColumnItems: leftItems,
    initialRightColumnItems: rightItems,
    editable: true,
    showCategoryOnSearch: false,
    selfDisabledDropZones,
    immutable: LEFT_ID,
    rightColumnItemsWithContentForceDirty: false,
  });

  const leftColumnProps = useMemo(
    () => ({
      ..._leftColumnProps,
      isGroupedItem: true,
    }),
    [_leftColumnProps]
  );

  useEffect(() => {
    const relatedFilterAddedIndex = rightColumnItems.findIndex(
      ({ fieldId }) => fieldId === RELATED_ENTITITY_QUICK_FILTER_FIELD
    );
    if (relatedFilterAddedIndex >= 0) {
      setShowModal({
        index: relatedFilterAddedIndex,
      });
    } else {
      onChange(rightColumnItems);
    }
  }, [onChange, rightColumnItems]);

  return (
    <StyledDndColumns isTemplate={isTemplate}>
      <StyledInputControl label={t('Quick Filter Preview')}>
        <HeaderPreview columns={rightColumnItems} />
      </StyledInputControl>
      <MultiColumnBuilder
        {...props}
        leftColumnProps={leftColumnProps}
        rightColumnProps={rightColumnProps}
        searchable
        weight={fontWeights.regular}
        onChangeSearchTerm={setOptionsSearchTerm}
        searchPlaceholder={t('Find Fields')}
        leftHeaderText={t('Available Fields (Drag to Add)')}
        rightHeaderText={t('Active Quick Filter Fields')}
        renderRightEmpty={(rightEmptyProps) => (
          <RightEmpty {...rightEmptyProps} />
        )}
        renderOption={(renderProps) => (
          <Option setShowModal={setShowModal} {...renderProps} />
        )}
      />
      {showModal ? (
        <RelatedFilterModal
          fields={fields}
          {...showModal}
          editValue={rightColumnProps.editValue}
          setShowModal={setShowModal}
          onHide={onHideModal}
        />
      ) : null}
    </StyledDndColumns>
  );
};
