import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutomationStatsRow } from './AutomationStatsRow';
import { AutomationContainer, AutomationName, Automation } from './styles';

export const AutomationStatsList = ({ automations = [], automationStats }) => {
  const { t } = useTranslation();

  const automationList = useMemo(() => {
    return automations.map(({ id, name, deleted }) => ({
      id,
      label: `${deleted ? `[${t('DELETED')}] ` : ''}${t(
        'Automation'
      )} - ${name}`,
      stats: automationStats[id],
    }));
  }, [automations, automationStats, t]);

  return (
    <AutomationContainer>
      {automationList.map(({ id, label, stats }) => {
        return (
          <Automation key={id}>
            <AutomationName size="header">{label}</AutomationName>
            <AutomationStatsRow stats={stats} />
          </Automation>
        );
      })}
    </AutomationContainer>
  );
};
