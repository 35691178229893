import { colorsPrimary } from 'app/colors';
import UUID from 'utility/UUID';
import { CO_LAYOUT_RELATED_OBJECT_FIELDS } from 'components/Wizards/CustomObject/utilities';
import { getDefaultColumnSettings } from '../../subsections/DefaultColumns/helpers';
import { snakeToCamelCase } from 'services/helpers';
import { FIELD_TYPES } from 'utility/constants';

export const DEFAULT_RELATION_FIELDS_TABLE_HEIGHT = 370;

export const DEFAULT_COLUMN_WIDTH = '200px';

export const linkableFieldTypes = [
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Relationship.type,
];

const steps = [
  {
    relatedObjectFields: {
      id: 'related_object_fields',
      title: 'Action',
      color: colorsPrimary.green.dark,
      stats: null,
      movable: true,
      creatable: true,
      deletable: true,
      actionType: true,
      prefix: 'action',
      CardComponent: 'RelatedObjectFieldsSection',
    },
  },
];
export const DEFAULT_STATE = {
  id: null,
  visible: true,
  type: null,
  tableHeight: DEFAULT_RELATION_FIELDS_TABLE_HEIGHT,
  collapse: false,
  objects: [],
  fields: {},
  filters: {},
};
export const createWizardState = (step) => {
  if (!step) {
    return { ...DEFAULT_STATE, id: UUID.generate() };
  }
  return {
    id: step.id === steps?.action?.type ? null : step.id,
    type: step.type,
    ...step.meta,
  };
};

export const buildFieldsPayload = (fields) => {
  return Object.entries(fields).reduce((acc, [objectId, fields]) => {
    return {
      ...acc,
      [objectId]: fields.map((field) => {
        if (
          linkableFieldTypes.includes(field.fieldType) &&
          field.enableLink === undefined
        ) {
          return {
            ...field,
            enableLink: true,
          };
        } else {
          return field;
        }
      }),
    };
  }, {});
};

export const createPayload = (values, step) => {
  //filter field fields state
  const fields = Object.entries(values.fields).reduce(
    (acc, [objectId, fields]) => {
      return values.objects.map(({ id }) => id).includes(objectId)
        ? { ...acc, [objectId]: fields }
        : acc;
    },
    {}
  );

  return {
    id: values.id,
    type: values?.type || CO_LAYOUT_RELATED_OBJECT_FIELDS,
    visible: true,
    ...step,
    meta: {
      tableHeight: values.tableHeight,
      collapse: values.collapse,
      objects: values.objects.map((item) => {
        return {
          id: item.id,
          name: item.objectName,
          label: item.label,
          displayName: item.label,
          icon: item?.icon || null,
          color: item?.color || colorsPrimary.blue.dark,
          objectType: item.objectType,
          fetchUrl: item.fetchUrl,
          entityName: item.entityName,
          originalId: item.originalId,
        };
      }),
      fields: buildFieldsPayload(fields),
      filters: values?.filters || {},
      // only include relationships from certain fields on the object rather than all of them
      // See: apps/react-app/src/pages/Common/RelatedObjectFields/index.js:104
      relationshipFields: values?.relationshipFields || [],
      idDictionary: values.objects.reduce(
        (acc, item) => ({ ...acc, [item.id]: item.originalId }),
        {}
      ),
    },
  };
};

export const getFieldMetatdata = (field, item) => {
  if (
    linkableFieldTypes.includes(field?.fieldType) &&
    field?.enableLink === undefined
  ) {
    return {
      label: field?.displayName || item.label,
      id: field?.id || item.id,
      displayName: field?.displayName || item.label,
      enableLink: true,
      shouldBeDisabledInLeftColumn: !!field?.isSuppressed,
      disabled: item.disabled || !!field?.isSuppressed,
    };
  }
  return {
    label: field?.displayName || item.label,
    id: field?.id || item.id,
    displayName: field?.displayName || item.label,
    shouldBeDisabledInLeftColumn: !!field?.isSuppressed,
    disabled: item.disabled || !!field?.isSuppressed,
  };
};

export const getCustomObjectOrderedFields = (model, fields, t) => {
  return getDefaultColumnSettings(model, fields, t).map((item) => {
    const field = fields.find(({ id, name }) =>
      [id, snakeToCamelCase(name)].includes(item.id)
    );
    return {
      ...item,
      ...getFieldMetatdata(field, item),
    };
  });
};
