import { useCallback, useMemo, useState } from 'react';
import { debounce, get } from 'lodash';
import Select from '__components/Inputs/Select';
import { DropdownProps, SelectOption } from './types';
import {
  getSelectValue,
  useInfiniteFilterOptions,
} from '../hooks/useFilterOptions';
import { useMenuList, LOAD_THRESHOLD } from '../hooks/useMenuList';

const filterOptionPredicate = () => true; // don't filter options, use it straight from the API

export const DropdownInfinite = ({
  step,
  width,
  url,
  method,
  next,
  placeholder,
  options: optionsProp,
  value,
  onChange,
  result_path,
  value_id_path,
  option_filter,
  option_paths,
  body,
  params: paramsProp,
  endAdornment,
  error,
  isStatus = false,
}: DropdownProps) => {
  const [removeInvalidOptions, setRemoveInvalidOptions] = useState(false);
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({ search: '' });
  const params = useMemo(
    () => ({ ...searchParams, ...paramsProp }),
    [searchParams, paramsProp]
  );
  const {
    isLoading,
    isFetchingNextPage,
    options,
    lookup,
    fetchNextPage,
    onMenuClose,
    onMenuOpen,
  } = useInfiniteFilterOptions(
    method,
    url,
    result_path,
    value_id_path,
    option_paths,
    option_filter,
    body,
    params,
    optionsProp,
    value
  );

  const [invalidOptionId] = useState(() => {
    if (value_id_path && (value?.not_found || value?.not_allowed)) {
      return get(value, value_id_path);
    }
    return null;
  });

  const handleChange = useCallback(
    (opt: SelectOption) => {
      if (opt.value !== invalidOptionId) {
        setRemoveInvalidOptions(true);
      }
      onChange(step, {
        next: opt.next ?? next,
        value: lookup.has(opt.value) ? lookup.get(opt.value)! : opt.value,
      });
    },
    [step, next, lookup, invalidOptionId, onChange]
  );

  const updateSearchParams = useMemo(() => {
    return debounce((value: string) => setSearchParams({ search: value }), 300);
  }, []);

  const { setMenuList, ref } = useMenuList(
    isFetchingNextPage,
    fetchNextPage,
    LOAD_THRESHOLD
  );

  const v =
    value && typeof value === 'object' && value_id_path
      ? get(value, value_id_path)
      : value;

  const visibleOptions = useMemo(() => {
    if (removeInvalidOptions) {
      return options.filter((opt) => opt.value !== invalidOptionId);
    }
    return options;
  }, [options, invalidOptionId, removeInvalidOptions]);

  const handleMenuOpen = () => {
    setTimeout(() => setMenuList(ref.current?.select.menuListRef), 50);
    onMenuOpen();
  };

  const handleInputChange = (value: string) => {
    setSearch(value);
    updateSearchParams(value);
  };

  return (
    <Select
      ref={ref}
      width={width}
      value={getSelectValue(v)}
      error={error}
      inputValue={search}
      options={visibleOptions}
      isColored={isStatus}
      placeholder={placeholder}
      onChange={handleChange}
      endAdornment={endAdornment}
      loadItems={isLoading || isFetchingNextPage}
      onMenuOpen={handleMenuOpen}
      onMenuClose={onMenuClose}
      onInputChange={handleInputChange}
      filterOption={filterOptionPredicate} // don't filter options, use it straight from the API
    />
  );
};
