export const GO_BACK_KEYS = {
  CONTACTS: 'contacts',
  OVERVIEW: 'overview',
  OBJECTS: 'objects',
  COLUMNS: 'columns',
};

export const RELATED_OBJECTS_STEP_KEY = 'relatedObjects';
export const CUSTOMIZE_FIELDS_STEP_KEY = 'customizeFields';
export const PERMISSIONS_STEP_KEY = 'permissions';
export const CUSTOMIZE_LAYOUT_STEP_KEY = 'customizeLayout';
export const GENERAL_STEP_KEY = 'general';

export const ASSOCIATION_SOURCE_TYPES = {
  direct: 'direct',
  direct_and_related: 'direct_and_related',
  related: 'related',
};
