import { useCallback } from 'react';
import { usePermissionsContext } from './context';
import { updatePermissionValues } from '../src/state-ops';
import type { PermissionValue } from '../src/types';

export const useUpdatePermissionValues = () => {
  const ctx = usePermissionsContext();

  return useCallback(
    (values: Record<string, PermissionValue>) => {
      updatePermissionValues(values, ctx);
    },
    [ctx]
  );
};
