import React from 'react';
import {
  ElementContainerSettingsSection,
  DividerSettings,
} from '../../settings';

export const EmailDividerSettings = ({ node }) => (
  <>
    <DividerSettings node={node} emailMode />
    <ElementContainerSettingsSection
      node={node}
      enableBackgroundImage={false}
      enableBorder={false}
      enableMargin={false}
    />
  </>
);

export const FormDividerSettings = ({ node }) => (
  <>
    <DividerSettings node={node} />
    <ElementContainerSettingsSection node={node} />
  </>
);
