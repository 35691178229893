import { useMemo } from 'react';

// we don't really want to hunt for these conditions like this but waiting on filter updates to change the pattern
// what we are doing here is looking for is me conditions and stage conditions in related groups
// 1. Related Object {{xxx}} That is in group {{group with is me condition}}
// 2. Related Object {{xxx}} That is not in group {{group with is me condition}}
// 3. Related Object {{xxx}} That is in group {{group with entered/left stage condition}}
// this is a temporary solution

const isMeComparisonHelper = (filter) => {
  const {
    view_model,
    type,
    subtype,
    with: _with,
    field,
    value,
    payload,
    assigned_id,
  } = filter;

  if (view_model) {
    const { filter_type } = view_model[0][1];

    const keyDictionary = {
      owner: ['condition'],
      team_interactions: ['team_member_type'],
      logged_activities: ['assigned_condition'],
      scheduled_activities: ['team_member_type', 'assigned_condition'],
      fields: ['condition'],
    };

    return view_model.some(
      ([key, value]) =>
        keyDictionary[filter_type]?.includes(key) && value === 'is_me'
    );
  }

  switch (type) {
    case 'association':
      return subtype === 'team' && _with === 'is_me';

    case 'fields_v2':
      return field === 'owner' && value === 'is_me';

    case 'activities':
      return payload?.by?.team_member && payload?.by?.which === 'is_me';

    case 'scheduled_activities':
      return assigned_id === 'is_me';

    default:
      return false;
  }
};

const isStageComparisonHelper = (filter) => {
  const { type, condition, field, view_model } = filter;

  if (view_model) {
    const { filter_type } = view_model[0][1];

    const stageValues = ['entered_stage', 'left_stage'];

    const keyDictionary = {
      stage: 'condition',
      fields: 'condition',
    };

    return view_model.some(
      ([key, value]) =>
        key === keyDictionary[filter_type] && stageValues.includes(value)
    );
  }
  switch (type) {
    case 'fields_v2':
      return (
        field === 'stage' &&
        (condition === 'entered_stage' || condition === 'left_stage')
      );

    default:
      return false;
  }
};

export const useRelatedGroupsMatchHelper = (filterSets) => {
  return useMemo(() => {
    const x = filterSets
      .flatMap(([, configs]) => {
        return configs.filters;
      })
      .flatMap(({ steps }) => steps)
      .filter(([stepType]) => stepType === 'relationshipGroup')
      .reduce((acc, [, { value }]) => {
        const filters = value[0]?.config
          ? value[0].config.query.flatMap(({ filters }) => filters)
          : [];
        return [...acc, ...filters];
      }, []);

    const groupIsMe = x.some((filter) => isMeComparisonHelper(filter));
    const groupIsStage = x.some((filter) => isStageComparisonHelper(filter));

    return { groupIsMe, groupIsStage };
  }, [filterSets]);
};
