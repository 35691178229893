import { Fragment, forwardRef } from 'react';
import { KizenTypography } from 'app/typography';
import {
  StyledModalSection,
  StyledStepNumber,
  StyledProgress,
  StyledProgressLine,
  StyledStepHeader,
} from './styles';

const Section = forwardRef((props, ref) => {
  const {
    index,
    isLastIndex = false,
    header,
    headerType = 'header',
    isActive,
    className,
    children,
    onClick,
    leavePAlone,
  } = props;
  return (
    <Fragment key={index}>
      <StyledStepNumber active={isActive}>
        <div>{index + 1}</div>
      </StyledStepNumber>
      <StyledStepHeader hasMargin={headerType === 'header'}>
        <KizenTypography type={headerType}>{header}</KizenTypography>
      </StyledStepHeader>
      {isLastIndex ? (
        <div />
      ) : (
        <StyledProgress>
          <StyledProgressLine active={isActive} />
        </StyledProgress>
      )}
      <StyledModalSection
        ref={ref}
        className={className}
        onClick={onClick}
        leavePAlone={leavePAlone}
      >
        {children}
      </StyledModalSection>
    </Fragment>
  );
});

Section.displayName = 'Section';

export default Section;
