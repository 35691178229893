import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import { useCallback } from 'react';
import { ConfirmModalWithInput } from './ConfirmModalWithInput';
import WizardLoadingButton from './WizardLoadingButton';
import { CUSTOMIZE_LAYOUT_STEP_KEY } from 'components/Wizards/CustomObject/constants';
import { CUSTOM_LAYOUT_TABS } from 'components/Wizards/CustomObject/utilities';

const SaveButton = ({ onClick, saving }) => {
  const { t } = useTranslation();
  return (
    <WizardLoadingButton color="green" loading={saving} onClick={onClick}>
      {t('Save Changes')}
    </WizardLoadingButton>
  );
};

export default function ConfirmationManager({
  isCreateMode,
  modalProps: { confirmation = {}, deletion = {}, intermediate = {} },
  handleConfirm,
  handleSave,
  saving,
  model,
  handleDeleteCustomObject,
  goingStepRef,
  onHide,
  redirectOnDeleteHandler,
  handleGoToStep,
  setCustomLayoutSelectedTab,
  customLayoutSelectedTab,
  isPipeline,
  activeStep,
}) {
  const { t } = useTranslation();
  const shouldCloseWizardAfterAction =
    confirmation.modalProps.isClosingModalRef?.current;

  const deleteDeleteCustomObject = useCallback(async () => {
    await handleDeleteCustomObject(model?.id);
    if (redirectOnDeleteHandler) {
      redirectOnDeleteHandler(model?.id);
    } else {
      onHide();
    }
  }, [handleDeleteCustomObject, model?.id, onHide, redirectOnDeleteHandler]);

  const defaultLeftBtnHandler = useCallback(async () => {
    const res = await handleSave(true);
    if (
      activeStep.key === CUSTOMIZE_LAYOUT_STEP_KEY &&
      isPipeline &&
      customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT
    ) {
      setCustomLayoutSelectedTab(CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT);
    } else {
      if (res && goingStepRef.current) {
        handleGoToStep(goingStepRef.current);
      }
    }
    confirmation.modal.hide();
    if (res && shouldCloseWizardAfterAction) {
      onHide();
    }
  }, [
    handleGoToStep,
    goingStepRef,
    handleSave,
    confirmation.modal,
    shouldCloseWizardAfterAction,
    onHide,
    activeStep.key,
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    isPipeline,
  ]);

  const handleConfirmWarning = useCallback(
    (...args) => {
      handleConfirm(...args);
      if (shouldCloseWizardAfterAction) {
        onHide();
      }
    },
    [handleConfirm, shouldCloseWizardAfterAction, onHide]
  );

  const handlerForIntermediateSave = useCallback(async () => {
    const res = await handleSave(true);
    if (res && goingStepRef.current) {
      handleGoToStep(goingStepRef.current);
      goingStepRef.current = null;
    }
    intermediate.modal.hide();
  }, [intermediate.modal, handleSave, handleGoToStep, goingStepRef]);

  return isCreateMode ? (
    <ConfirmationModal
      className={`no-drag`}
      heading={t('Please Confirm Deletion')}
      buttonText={t('Confirm Delete')}
      defaultLeftBtnText={t('Cancel')}
      actionBtnColor="red"
      {...confirmation.modalProps}
      onConfirm={handleConfirmWarning}
    >
      {t(
        'Going back before saving this object will result in the current work being lost and the object being deleted.'
      )}
    </ConfirmationModal>
  ) : (
    <>
      <ConfirmationModal
        className={`no-drag`}
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Save Changes')}
        actionBtnColor="red"
        leftBtn={<SaveButton saving={saving} />}
        defaultLeftBtnHandler={defaultLeftBtnHandler}
        {...confirmation.modalProps}
        onConfirm={handleConfirmWarning}
      >
        {t(
          'You have unsaved changes. Would you like to save them or discard them before going back?'
        )}
      </ConfirmationModal>
      <ConfirmModalWithInput
        {...deletion.modalProps}
        onConfirm={deleteDeleteCustomObject}
        unblockName={model?.objectName}
        message={t(
          'Deleting this object will permanently delete all associated records and data. You cannot undo this action. Please type "{{objectName}}" below to confirm.',
          { objectName: model?.objectName }
        )}
      />
      <ConfirmationModal
        className={`no-drag`}
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Save Changes')}
        actionBtnColor="red"
        leftBtn={<SaveButton saving={saving} />}
        defaultLeftBtnHandler={handlerForIntermediateSave}
        {...intermediate.modalProps}
        onConfirm={() => {
          handleConfirmWarning();
          intermediate.modal.hide();
        }}
      >
        {t(
          'You have unsaved changes. Would you like to save them or discard them before continuing?'
        )}
      </ConfirmationModal>
    </>
  );
}
