import {
  Root,
  Section,
  Row,
  Cell,
  Content,
  Button,
  Text,
  Image,
  Divider,
  Attachments,
} from '../nodes';

export default {
  Root,
  Section,
  Row,
  Cell,
  Content,
  Button,
  Text,
  Image,
  Divider,
  Attachments,
};
