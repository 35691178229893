import { useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast, toastVariant } from 'components/ToastProvider';
import { isEmpty } from 'lodash';

const getFilterReasonMessage = (reason, t) => {
  switch (reason) {
    case 'hidden':
      return t('This group is not visible.');
    case 'deleted':
      return t('This group was deleted.');
    default:
      return t('This group is not accessible to you. Please request access.');
  }
};

export const useFilterErrorEffect = ({
  toggleMenu,
  enabled = true,
  filterGroupReason,
  hasFilterError,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const toggleRef = useRef(toggleMenu);
  const showToastRef = useRef(false);

  useEffect(() => {
    if (enabled && !showToastRef.current && hasFilterError) {
      if (hasFilterError) {
        toggleRef.current?.(true);
      }
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'A filtering error has occurred, please contact support if the problem persists.'
        ),
      });
      showToastRef.current = true;
    } else if (filterGroupReason) {
      showToast({
        variant: toastVariant.FAILURE,
        message: getFilterReasonMessage(filterGroupReason, t),
      });
    }
  }, [enabled, showToast, hasFilterError, filterGroupReason, t]);

  return useCallback(() => {
    showToastRef.current = false;
  }, []);
};

export const anyFilterErrors = (response, complexFilters = false) =>
  response?.status === 400 &&
  (complexFilters
    ? (response?.data?.errors?.errors?.query || []).some((q) =>
        q?.query.some((q) => q?.filters)
      )
    : (response?.data?.errors?.errors?.query || []).some((q) => q?.filters));

export const getFilterErrors = (responseError, complexFilters = false) => {
  const { count, results, errors } = responseError || {};
  const parsedErrors = (
    (complexFilters ? errors?.query?.[1]?.query : errors?.query) || []
  ).map((err) => (err?.filters || []).map((filter) => !isEmpty(filter)));

  const quickFiltersErrors = complexFilters
    ? (errors?.query?.[0]?.query?.[0]?.filters || []).map(
        (filter) => filter?.value?.[0]?.[0] || !isEmpty(filter)
      )
    : [];

  return {
    errors: {
      timestamp: Date.now(),
      parsedErrors: parsedErrors.some((err) => (err || []).some(Boolean))
        ? parsedErrors
        : [],
      quickFiltersErrors: quickFiltersErrors.some(Boolean)
        ? quickFiltersErrors
        : [],
    },
    count,
    results,
  };
};
