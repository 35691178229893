import { StyledIconButtonAbsoluteMenu, StyledIconMenu } from './styles';
import { colorsButton } from 'app/colors';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  canAdminTemplate,
  canDuplicateTemplate,
  canEditTemplate,
  canElevatePermissions,
  canViewTemplate,
} from 'pages/Account/pages/Toolbar/utils';

const ActionCell = ({ onChange, data, title, items }) => {
  const { t } = useTranslation();

  const openMenuAbove = useMemo(() => {
    const count = items?.results?.length ?? 0;
    const index = items?.results?.findIndex((item) => item.id === data.id);

    return Math.max(10, count) - index <= 2;
  }, [items, data.id]);

  const options = useMemo(() => {
    const result = [];

    if (canViewTemplate(data)) {
      result.push({
        value: 'apply',
        label: t('Apply Template'),
      });
    }

    if (canEditTemplate(data)) {
      result.push({
        value: 'edit',
        label: t('Edit Template'),
      });
    }

    if (canAdminTemplate(data)) {
      result.push({
        value: 'edit_sharing',
        label: t('Edit Sharing Settings'),
      });
    }

    if (canElevatePermissions(data)) {
      result.push({
        value: 'request',
        label: t('Request Access'),
      });
    }

    if (canDuplicateTemplate(data)) {
      result.push({
        value: 'duplicate',
        label: t('Duplicate'),
      });
    }

    if (canAdminTemplate(data)) {
      result.push({
        value: 'delete',
        label: t('Delete'),
      });
    }

    return result;
  }, [data, t]);

  if (!options.length) {
    return null;
  }

  return (
    <StyledIconButtonAbsoluteMenu
      position="right"
      sizing="dense"
      color={colorsButton.iconGray}
      title={title || t('Edit Template')}
      popperConfig={{
        modifiers: {
          offset: { offset: '0, 8px' }, // Touch top of text
          preventOverflow: { enabled: false },
        },
      }}
      options={options}
      onChange={(res) => onChange(res, data)}
      above={openMenuAbove}
    >
      <StyledIconMenu icon="three-dot" />
    </StyledIconButtonAbsoluteMenu>
  );
};

export default ActionCell;
