import Icon from 'components/Kizen/Icon';
import styled from '@emotion/styled';
import { SizedCell, TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { colorsButton } from 'app/colors';
import IconButtonAbsoluteMenu from 'components/Tables/Big/IconButtonAbsoluteMenu';

export const ActionHeadCell = styled(({ t, ...props }) => (
  <SizedCell {...props}>
    <TextEllipsisWithTooltip as="span" weight="bold">
      {t('Actions')}
    </TextEllipsisWithTooltip>
  </SizedCell>
))`
  text-align: right;
`;

export const ActionCell = styled(
  ({ t, options, data, onSelectAction, menuType, title, ...others }) => {
    return (
      <SizedCell data={data} {...others}>
        <IconButtonAbsoluteMenu
          title={title || t('View API Key')}
          sizing="dense"
          position="right"
          above={data.meta && data.meta.openMenuAbove}
          color={colorsButton.iconGray}
          onChange={(value) => onSelectAction(value, data)}
          options={options}
        >
          <Icon icon="three-dot" />
        </IconButtonAbsoluteMenu>
      </SizedCell>
    );
  }
)`
  > div {
    display: flex;
    justify-content: flex-end;
  }
`;
