import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useEditor, useNode } from '@craftjs/core';
import { getTypeInfo } from '@kizen/page-builder/nodes/types';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { useShowControls } from './useShowControls';
import Control from './Control';
import { useDraggingType } from './useDraggingType';

const Wrapper = styled.div`
  ${({ ignoreChildrenDrag }) =>
    ignoreChildrenDrag &&
    css`
      pointer-events: none;
    `}
`;

export default function Content(props) {
  const { t } = useTranslation();
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
  }));
  const {
    id,
    connectors: { connect },
  } = useNode((node) => ({
    id: node.id,
  }));
  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  return (
    <Control
      ref={connect}
      label={t('Content')}
      show={showControls}
      color={colorsPrimary.green.dark}
      mayHaveChildren={false}
      {...props}
    >
      <Wrapper
        ignoreChildrenDrag={getTypeInfo(draggingType)?.isContent}
        {...props}
      />
    </Control>
  );
}
