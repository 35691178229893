import TextInput from '__components/Inputs/TextInput';
import { useTranslation } from 'react-i18next';
import { useErrors } from '../../hooks/useErrors';
import { getDataQAForInput } from '__components/Inputs/helpers';

type NameInputProps = {
  value?: string;
  onChange: (value: string) => void;
};

export const NameInput = ({ value, onChange }: NameInputProps) => {
  const { t } = useTranslation();

  const { clearError, validate, error } = useErrors('name');

  const handleChange = (v: string) => {
    onChange(v);
    clearError();
  };

  return (
    <TextInput
      value={value}
      label={t('Name Your Connector')}
      placeholder={t('Enter Name')}
      error={error}
      validate={validate}
      onChange={handleChange}
      margin
      required
      {...getDataQAForInput('smart-connector-name', 'text')}
    />
  );
};
