import React from 'react';
import * as PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { TextSpan, fontSizes } from 'app/typography';
import { displayBreakpoints, isMobile, useWindowSize } from 'app/spacing';
import { colorsText, dropdownColors, grayScale } from 'app/colors';
import {
  StyledUnderlinedLabel as StyledLabel,
  StyledUnderlinedLabelWrapper as StyledLabelWrapper,
  reactSelectUnderlinedStyles as reactSelectStyles,
  StyledCustomOption,
} from './styles';
import { SingleValue } from './Outlined';
import Icon from '../Icon';
import { useTruncationTooltip } from '../Tooltip';
import { useTranslation } from 'react-i18next';

export const optionShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string.isRequired,
});

const getValue = (value, options) => {
  // if the value is a string, find that in the options and return it
  if (typeof value === 'string') {
    return options.find((option) => option.value === value) || null;
  }

  // if the value is an object, key off of the id
  if (value && 'id' in value) {
    return options.find((option) => option.value.id === value.id);
  }

  return value;
};

const UnderlinedSelect = ({
  className,
  label,
  textSize,
  size,
  style,
  styles,
  disabled,
  error,
  options,
  value,
  fullWidth,
  dropUp = false,
  components: componentsProp,
  onKeyDown,
  InputLabelProps,
  TextComponent,
  placeholder,
  ...props
}) => {
  const { width } = useWindowSize();
  const [tooltipProps, tooltip] = useTruncationTooltip();
  const { t } = useTranslation();
  const NoOptionsMessage = ({ children, ...propsNoOption }) => {
    return (
      <components.NoOptionsMessage {...propsNoOption}>
        <TextSpan size={textSize} style={{ color: colorsText.medium }}>
          {t('No Options')}
        </TextSpan>
      </components.NoOptionsMessage>
    );
  };

  const DropdownIndicator = () => (
    <Icon
      icon="down"
      size="2x"
      style={{
        textAlign: 'left',
      }}
      color={grayScale.mediumDark}
    />
  );

  if (label) {
    return (
      <div className={className}>
        <StyledLabelWrapper>
          <StyledLabel
            // unlike other inputs which may check for focused || isFilled,
            // Select should always appear in the shrink state
            shrink
            {...InputLabelProps}
            disabled={disabled}
            {...tooltipProps}
          >
            {label}
          </StyledLabel>
          <ReactSelect
            styles={{ ...reactSelectStyles, ...styles }}
            components={{
              SingleValue,
              NoOptionsMessage,
              DropdownIndicator,
              Option: StyledCustomOption,
              ...componentsProp,
            }}
            placeholder={
              //  When used Placholder component in components array click on placeholder is not triggering opening menu
              <TextSpan size={textSize} color={colorsText.medium}>
                {placeholder}
              </TextSpan>
            }
            options={options}
            // TODO for some reason react-select anticipates a value of the shape { value, label },
            // i.e. one of the values in options.
            value={getValue(value, options)}
            mobile={isMobile(width, displayBreakpoints.desktop)}
            fullWidth={fullWidth}
            onKeyDown={(ev) => {
              if (onKeyDown) {
                onKeyDown(ev, { options });
              }
            }}
            textSize={textSize}
            TextComponent={TextComponent}
            {...props}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: dropdownColors.hover,
              },
              controlHeight: 36,
            })}
            isDisabled={disabled}
            error={error}
            dropUp={dropUp}
          />
        </StyledLabelWrapper>
        {tooltip}
      </div>
    );
  }

  return (
    <div className={className}>
      <ReactSelect
        styles={{ ...reactSelectStyles, ...styles }}
        components={{
          SingleValue,
          NoOptionsMessage,
          DropdownIndicator,
          Option: StyledCustomOption,
          ...componentsProp,
        }}
        placeholder={
          //  When used Placholder component in components array click on placeholder is not triggering opening menu
          <TextSpan size={textSize} color={colorsText.medium}>
            {placeholder}
          </TextSpan>
        }
        options={options}
        // TODO for some reason react-select anticipates a value of the shape { value, label },
        // i.e. one of the values in options.
        value={getValue(value, options)}
        mobile={isMobile(width, displayBreakpoints.desktop)}
        fullWidth={fullWidth}
        onKeyDown={(ev) => {
          if (onKeyDown) {
            onKeyDown(ev, { options });
          }
        }}
        textSize={textSize}
        TextComponent={TextComponent}
        {...props}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: dropdownColors.hover,
          },
          controlHeight: 36,
        })}
        isDisabled={disabled}
        error={error}
      />
    </div>
  );
};

UnderlinedSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, optionShape]),
  options: PropTypes.arrayOf(optionShape).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
  className: PropTypes.string,
  textSize: PropTypes.oneOf(Object.keys(fontSizes)),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  styles: PropTypes.object,
  size: PropTypes.oneOf(['large', 'small']),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  components: PropTypes.object,
  onKeyDown: PropTypes.func,
  InputLabelProps: PropTypes.object,
  TextComponent: PropTypes.node,
};

UnderlinedSelect.defaultProps = {
  value: null,
  label: '',
  className: '',
  textSize: 'text',
  placeholder: '',
  styles: {},
  size: 'small',
  disabled: false,
  error: false,
  fullWidth: false,
  components: null,
  onKeyDown: null,
  InputLabelProps: null,
  TextComponent: null,
};

export default UnderlinedSelect;
