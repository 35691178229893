import { forwardRef, useCallback, useRef } from 'react';
import { useMeasure } from 'react-use';
import styled from '@emotion/styled';
import { shadow } from 'app/colors';
import { scrollbarCss } from 'app/spacing';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { useBuilderContext } from '../BuilderContext';
import { CanvasWrapper } from './CanvasWrapper';
import { canvasScrollFadeContainerStyle, Content } from './styled';

const DesktopBodyWrapper = styled(CanvasWrapper)`
  flex: 1;
  overflow: auto;
  overflow: overlay;
`;

const StyledScrollFadeContainer = styled(ScrollFadeContainer)`
  ${({ showBoxShadow }) => showBoxShadow && shadow}
  ${scrollbarCss}
  ${({ showGradient }) => {
    if (showGradient) {
      return canvasScrollFadeContainerStyle;
    }
  }}
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow: overlay;
  overflow-x: clip;
`;

export const DesktopCanvas = forwardRef(
  (
    {
      disabled = false,
      showBoxShadow = true,
      canResizeHeight = false,
      showGradient = true,
      paddingTop,
      widthDisplay,
      children,
    },
    ref
  ) => {
    const [measureRef, { width }] = useMeasure();
    const scrollRef = useRef();
    const scrolled = useEndsOfScroll(scrollRef);
    const {
      clearContentSettingsTray,
      setCanvasScrollContainer,
      setCanvasWrapper,
      setScrolling,
    } = useBuilderContext();

    const refCallback = useCallback(
      (node) => {
        scrollRef.current = node;
        measureRef(node);
        setCanvasScrollContainer(node);
        setCanvasWrapper(node);

        let timeoutId = null;

        const onscroll = (ev) => {
          const isScrolledTop = ev.target.scrollTop === 0;
          const isScrolledBottom =
            ev.target.scrollHeight ===
            ev.target.scrollTop + ev.target.clientHeight;

          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          if (!isScrolledTop && !isScrolledBottom) {
            setScrolling(true);
          }

          timeoutId = setTimeout(() => setScrolling(false), 50);
        };

        if (node) {
          node.addEventListener('scroll', onscroll);
        }

        return () => {
          scrollRef.current.removeEventListener('scroll', onscroll);
        };
      },
      [setCanvasScrollContainer, setCanvasWrapper, measureRef, setScrolling]
    );

    return (
      <DesktopBodyWrapper
        ref={ref}
        width={widthDisplay || width}
        onClick={clearContentSettingsTray}
        paddingTop={paddingTop}
      >
        <StyledScrollFadeContainer
          ref={refCallback}
          scrolled={scrolled}
          showBoxShadow={showBoxShadow}
          showGradient={showGradient}
          showScrollbar
          showShadow
          top={showGradient}
          bottom={showGradient}
        >
          <Content
            disabled={disabled}
            showBoxShadow={showBoxShadow}
            canResizeHeight={canResizeHeight}
          >
            {children}
          </Content>
        </StyledScrollFadeContainer>
      </DesktopBodyWrapper>
    );
  }
);

DesktopCanvas.displayName = 'DesktopCanvas';
