import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { PILL_TYPES } from './constants';

export const getInnerValue = (value, pillType) => {
  switch (pillType) {
    case PILL_TYPES.multiselect.type:
    case PILL_TYPES.facetMultiselect.type:
    case PILL_TYPES.filterGroupMultiselect.type:
    case PILL_TYPES.number.type:
    case PILL_TYPES.date.type:
      return Array.isArray(value) ? value : EMPTY_ARRAY;
    default:
      return value;
  }
};

export const getCounts = (value, pillType) => {
  switch (pillType) {
    case PILL_TYPES.multiselect.type:
    case PILL_TYPES.facetMultiselect.type:
    case PILL_TYPES.filterGroupMultiselect.type:
      return value?.length || 0;
    case PILL_TYPES.singleselect.type:
    case PILL_TYPES.facetSingleselect.type:
      return typeof value === 'boolean' ? 1 : Number(Boolean(value));
    case PILL_TYPES.number.type:
    case PILL_TYPES.date.type:
      return Array.isArray(value) && value.length === 2 ? 1 : 0;
    case PILL_TYPES.text.type:
      return value?.length ? 1 : 0;
    default:
      return 0;
  }
};

export const getIsInvalid = (value, pillType) => {
  switch (pillType) {
    case PILL_TYPES.singleselect.type:
    case PILL_TYPES.facetSingleselect.type:
    case PILL_TYPES.filterGroupMultiselect.type:
      return value?.error;
    case PILL_TYPES.multiselect.type:
    case PILL_TYPES.facetMultiselect.type:
      return !!value?.length && value.some((option) => option.error);
    // number and date are actually ranges of min and max values so the value should be an array with 2 elements
    case PILL_TYPES.number.type:
    case PILL_TYPES.date.type:
      return !!value?.length && value?.length !== 2;
    default:
      return false;
  }
};

export const facetResultToOption = ({
  displayName,
  id,
  color, // we populate color for status fields
  recordsCount,
}) => ({
  value: id,
  label: displayName,
  color,
  recordsCount: !isNaN(+recordsCount) && ` (${recordsCount})`,
});
