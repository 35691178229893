import { useState, useMemo, useCallback, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { KizenTypography } from 'app/typography';
import useField from 'hooks/useField';
import useSearchParam, { setSearchParams } from 'hooks/useSearchParam';
import useDebounce from 'react-use/lib/useDebounce';
import useModal from 'components/Modals/useModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { DEFAULT_INPUT_DELAY } from 'utility/config';

import CardToolbar, {
  CardToolbarTitle,
  CardToolbarSection,
  CardToolbarSearch,
} from 'components/Layout/CardToolbar';
import SmallTableCard from 'components/Layout/SmallTableCard';
import { TRow } from 'components/Kizen/Table';
import BasicTable from 'components/Tables/Basic';
import { getColumns } from './columns';

import { DELETE_OUTGOING } from '../consts';
import UUID from 'utility/UUID';
import IntegratedEmailControl from 'components/Modals/ThirdPartyAuth';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const setOrderingSearchParams = (param, history, { column, direction }) => {
  setSearchParams(history, {
    [param]: getOrderingParam({ column, direction }),
  });
};
const SORT_PARAM = 'sort_integrated_emails';

const IntegratedEmail = ({
  externalAccounts,
  saveBlockedEmails,
  blockedEmails,
  access,
  onAccountDelete,
  businessId,
  onUpdateExternalAccounts,
  externalAccountsLoading,
}) => {
  const { t } = useTranslation();

  const [stagedBlockedEmails, setStagedBlockedEmails] = useField(
    blockedEmails,
    [blockedEmails]
  );

  const history = useHistory();
  const [deleteId, setDeleteId] = useState(null);

  const ordering = useSearchParam(SORT_PARAM) || 'team_member__name';
  const sort = useMemo(() => getSortValue(ordering), [ordering]);

  const search = useSearchParam('q') || '';

  const handleChangeSearch = useCallback(
    (q) => {
      setSearchParams(history, { q }, { method: 'replace' });
    },
    [history]
  );

  const [debouncedSearch, setDebouncedSearch] = useState(search);
  useDebounce(() => setDebouncedSearch(search), DEFAULT_INPUT_DELAY, [search]);

  useEffect(() => {
    onUpdateExternalAccounts({ ordering, search: debouncedSearch });
  }, [onUpdateExternalAccounts, ordering, debouncedSearch]);

  const handleSort = useCallback(
    (order) => {
      setOrderingSearchParams(SORT_PARAM, history, order);
    },
    [history]
  );

  const handleUpdate = useCallback(
    async (value, errorMessage) => {
      const updatedblockedEmails = { ...stagedBlockedEmails, ...value };
      // optimistaclly update the stage values, after saving the current values
      const saved = stagedBlockedEmails;
      setStagedBlockedEmails(updatedblockedEmails);
      try {
        await saveBlockedEmails(updatedblockedEmails, errorMessage);
      } catch (error) {
        // undo the optimistic update
        setStagedBlockedEmails(saved);
      }
    },
    [stagedBlockedEmails, setStagedBlockedEmails, saveBlockedEmails]
  );

  const [deleteValues, setDeleteValues] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteModalProps, , deleteModal] = useModal({
    handleSubmit: () => {
      const { updatedblockedEmails, errorMessage } = deleteValues;
      handleUpdate(updatedblockedEmails, errorMessage); // handleUpdate is dealing with any errors so we don't need to worry about it here
    },
  });

  const blockedExternalAccounts = useMemo(() => {
    const blockedIds = stagedBlockedEmails.blockOutgoingEmailsFrom.reduce(
      (collect, o) => ({ ...collect, [o.id]: true }),
      {}
    );
    return externalAccounts.map((account) => ({
      ...account,
      blocked: !!blockedIds[account.id],
    }));
  }, [externalAccounts, stagedBlockedEmails]);

  const [confirmDeletionModalProps, , confirmDeletionModal] = useModal({
    handleSubmit: () => onAccountDelete(deleteId),
  });

  const columns = useMemo(() => {
    return getColumns({
      onSelectAction: ({ value: action }, integratedEmail) => {
        const { id, email } = integratedEmail;

        if (action === 'delete') {
          setDeleteId(id);
          confirmDeletionModal.show();
        }

        if (action === 'block') {
          const newState = {
            blockOutgoingEmailsFrom: [
              ...stagedBlockedEmails.blockOutgoingEmailsFrom,
              { id, email },
            ],
          };

          handleUpdate(
            newState,
            t('The outgoing emails were not successfully updated.')
          );
        }
        if (action === 'unblock') {
          const newState = {
            blockOutgoingEmailsFrom:
              stagedBlockedEmails.blockOutgoingEmailsFrom.filter(
                (outgoingEmail) => outgoingEmail.id !== id
              ),
          };

          setDeleteMessage(DELETE_OUTGOING(t));
          setDeleteValues({
            updatedblockedEmails: newState,
            errorMessage: t(
              'The outgoing emails were not successfully updated.'
            ),
          });
          deleteModal.show();
        }
      },
      t,
      access,
    });
  }, [
    stagedBlockedEmails,
    confirmDeletionModal,
    deleteModal,
    handleUpdate,
    t,
    access,
  ]);

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => handleSort({ column, direction }),
      },
    }),
    [sort, handleSort]
  );

  if (!externalAccounts) {
    return null;
  }

  return (
    <>
      <SmallTableCard>
        <CardToolbar tall>
          <CardToolbarTitle>{t('Integrated Inboxes')}</CardToolbarTitle>
          <CardToolbarSection>
            <CardToolbarSearch
              placeholder={t('Find Email')}
              value={search}
              onChange={handleChangeSearch}
              loading={externalAccountsLoading}
            />

            <IntegratedEmailControl businessId={businessId} />
          </CardToolbarSection>
        </CardToolbar>
        <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
          <ScrollContainerBlocker />
        </ScrollContainerBlockerWrapper>
        <TableScrollContainer bottom refresh={[blockedExternalAccounts]}>
          <BasicTable
            stickyHeader
            height={0}
            head={<TRow head columns={columns} data={headData} />}
          >
            {(blockedExternalAccounts || []).map((integratedEmail) => (
              <TRow
                key={`${integratedEmail.id} ${UUID.generate()}`}
                columns={columns}
                data={integratedEmail}
              />
            ))}
          </BasicTable>
        </TableScrollContainer>
      </SmallTableCard>
      <ConfirmDeletionModal {...confirmDeletionModalProps}>
        <KizenTypography>
          {t(
            'This will permanently delete the integrated email and will remove associated emails on the timeline.'
          )}
        </KizenTypography>
      </ConfirmDeletionModal>
      <ConfirmDeletionModal {...deleteModalProps}>
        {deleteMessage}
      </ConfirmDeletionModal>
    </>
  );
};

IntegratedEmail.propTypes = {
  blockedEmails: PropTypes.shape({
    blockIncomingEmailsFrom: PropTypes.array.isRequired,
    blockOutgoingEmailsFrom: PropTypes.array.isRequired,
    enableInternalEmailSync: PropTypes.bool.isRequired,
  }).isRequired,
  saveBlockedEmails: PropTypes.func.isRequired,
  externalAccounts: PropTypes.array.isRequired,
  onAccountDelete: PropTypes.func.isRequired,
  businessId: PropTypes.string.isRequired,
  onUpdateExternalAccounts: PropTypes.func.isRequired,
};

export default IntegratedEmail;
