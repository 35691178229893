import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  CHANGE_BUSINESS_ITEM,
  DEFAULT_ITEM_IDS,
  PREVIEW_FLAG_ITEM_IDS,
} from '../constants';
import { filterInvalidToolbarItems } from '../utils';
import { hasMultipleBusinesses } from 'store/authentication/helpers';

const lockedItems = [
  DEFAULT_ITEM_IDS.MY_ACCOUNT,
  DEFAULT_ITEM_IDS.MY_PROFILE,
  DEFAULT_ITEM_IDS.CHANGE_BUSINESS,
  DEFAULT_ITEM_IDS.LOGOUT,
  DEFAULT_ITEM_IDS.HELP,
  DEFAULT_ITEM_IDS.KNOWLEDGE_BASE,
  DEFAULT_ITEM_IDS.CHAT_WITH_US,
];

const mapLockedState = (items) => {
  return items.map((item) => {
    return {
      ...item,
      isLocked: lockedItems.includes(item.id),
      children: item.children ? mapLockedState(item.children) : item.children,
    };
  });
};

const useToolbarAccessFiltering = (inBuilder) => {
  const access = useSelector((s) => s.authentication.access);
  const canChooseBusiness = useSelector(
    ({ authentication }) =>
      !!authentication.user &&
      (hasMultipleBusinesses(authentication.user) ||
        authentication.user.is_superuser)
  );

  const canAddBusiness = useMemo(() => !!access?.can_add_business, [access]);

  const prereleaseFeatures = usePreReleaseFeatures();

  const itemEnabledByFeature = useCallback(
    (item) => {
      if (prereleaseFeatures) {
        return true;
      }

      return !PREVIEW_FLAG_ITEM_IDS.includes(item.id);
    },
    [prereleaseFeatures]
  );

  const check = useCallback(
    (items) => {
      const filteredItems = [
        ...filterInvalidToolbarItems(
          items,
          access,
          canChooseBusiness,
          canAddBusiness,
          inBuilder
        ),
      ]
        // Perform filtering based on preview flag status
        .filter((r) => itemEnabledByFeature(r))
        .map((r) => {
          return {
            ...r,
            children: !r.children
              ? r.children
              : r.children.filter((c) => itemEnabledByFeature(c)),
          };
        });

      // If the user can choose businesses, make sure to add the change business item back in case
      // it was previously removed or not allowed
      if (canChooseBusiness) {
        const myAccountIndex = filteredItems.findIndex(
          (item) => item.id === DEFAULT_ITEM_IDS.MY_ACCOUNT
        );

        if (myAccountIndex > -1) {
          const changeBusinessIndex = filteredItems[
            myAccountIndex
          ].children.findIndex(
            (child) => child.id === DEFAULT_ITEM_IDS.CHANGE_BUSINESS
          );

          if (changeBusinessIndex < 0) {
            filteredItems[myAccountIndex].children.splice(
              1,
              0,
              CHANGE_BUSINESS_ITEM
            );
          }
        }
      }

      return mapLockedState(filteredItems);
    },
    [access, canAddBusiness, canChooseBusiness, itemEnabledByFeature, inBuilder]
  );

  return check;
};

export default useToolbarAccessFiltering;
