import { RefObject } from 'react';
import AxiosService from 'services/AxiosService';
import { ACTIONS, RESPONSES, COMMUNICATIONS } from './constants';
import { OnShowToastFn, ShowToastOptions, StateChangePayload } from './types';
import { History } from 'history';
import {
  DataAdornment,
  FloatingFrame,
  RoutablePage,
} from 'ts-components/Plugins/PluginContext';
import { toastVariant } from 'components/ToastProvider';
import { invalidate } from '__queries/invalidate';

const isRelative = (url: string) => {
  return url.startsWith('/');
};

const allowedAllowValues = [
  'microphone',
  'speaker-selection',
  'autoplay',
  'camera',
  'display-capture',
  'hid',
];

const allowedSandboxValues = [
  'allow-popups',
  'allow-scripts',
  'allow-same-origin',
];

export class WorkerManager {
  private worker: Worker;
  private scriptUIRef?: RefObject<HTMLDivElement>;
  private onStateChange?: (state: StateChangePayload) => void;
  private done: (preserve: boolean, result?: any) => void;
  private onError?: (error: any) => void;
  private waitForFrame = false;
  private history?: History;
  private plugin?: FloatingFrame | RoutablePage;
  private executionPlugin?: DataAdornment;
  private frameId?: string;
  private onShowToast?: OnShowToastFn;
  private onClearToasts?: () => void;

  constructor(args: {
    worker: Worker;
    done: (preserve: boolean, result?: any) => void;
    scriptUIRef?: RefObject<HTMLDivElement>;
    onStateChange?: (state: StateChangePayload) => void;
    onError?: (error: any) => void;
    history?: History;
    plugin?: FloatingFrame | RoutablePage;
    executionPlugin?: DataAdornment;
    onShowToast?: OnShowToastFn;
    onClearToasts?: () => void;
  }) {
    this.scriptUIRef = args.scriptUIRef;
    this.onStateChange = args.onStateChange;
    this.done = args.done;
    this.onError = args.onError;
    this.worker = args.worker;
    this.worker.onmessage = this.handleMessage;
    this.history = args.history;
    this.plugin = args.plugin;
    this.executionPlugin = args.executionPlugin;
    this.onShowToast = args.onShowToast;
    this.onClearToasts = args.onClearToasts;
    if (this.plugin) {
      this.frameId = `kzn-integration-frame-${this.plugin.plugin_api_name}-${this.plugin.api_name}`;
    }
  }

  private handleMessage = (rawEvent: MessageEvent) => {
    const event = JSON.parse(rawEvent.data);
    const {
      action,
      id,
      method,
      url,
      payload,
      options,
      createNewTab,
      state,
      target,
      features,
      markup,
      error,
      type,
      recipient,
      args,
      allow,
      sandbox,
      message,
      toastOptions,
      entityId,
      result,
      preserve,
    } = event;
    switch (action) {
      case ACTIONS.QUERY_REQUEST:
        return this.handleQueryRequest(id, method, url, payload, options);
      case ACTIONS.UI_OUTPUT:
        return this.handleUIOutput(markup);
      case ACTIONS.IFRAME_OUTPUT:
        return this.handleIframeOutput(url, allow, sandbox, preserve);
      case ACTIONS.POSTFORMDATA_REQUEST:
        return this.handleFormPostRequest(id, url, payload, createNewTab);
      case ACTIONS.SETSTATE:
        return this.onStateChange?.(state);
      case ACTIONS.DONE:
        return this.handleDone(preserve, result);
      case RESPONSES.ERROR:
        return this.onError?.({ message: error });
      case ACTIONS.OPEN_WINDOW:
        return this.handleOpenWindow(url, target, features);
      case ACTIONS.COMMUNICATE:
        return this.handleCommunication(type, recipient, args);
      case ACTIONS.HIDE:
        return this.onStateChange?.({ hidden: true });
      case ACTIONS.SHOW:
        return this.onStateChange?.({ hidden: false });
      case ACTIONS.EXPAND:
        return this.onStateChange?.({ minimized: false });
      case ACTIONS.COLLAPSE:
        return this.onStateChange?.({ minimized: true });
      case ACTIONS.SHOW_TOAST:
        return this.showToast?.(message, toastOptions);
      case ACTIONS.CLEAR_TOASTS:
        return this.clearToasts?.();
      case ACTIONS.REFRESH_TIMELINE:
        return this.handleRefreshTimeline(entityId);
      default:
        return;
    }
  };

  private handleCommunication = (
    type: string,
    recipient: { frame: string; script: string },
    args?: Record<string, string | number>
  ) => {
    if (type === COMMUNICATIONS.SEND_MESSAGE_TO_FRAME) {
      if (this.frameId) {
        const target = document.getElementById(
          this.frameId
        ) as HTMLIFrameElement;

        if (target) {
          target.contentWindow?.postMessage(
            args?.payload ?? {},
            String(args?.path ?? '*')
          );
        }
      }
    } else {
      const pluginApiName =
        this.executionPlugin?.plugin_api_name || this.plugin?.plugin_api_name;
      const event = new CustomEvent(`integration:${type}`, {
        detail: {
          recipient: {
            ...recipient,
            plugin: pluginApiName,
          },
          args,
        },
      });
      window.dispatchEvent(event);
    }
  };

  private handleDone = (preserve: boolean, result?: any) => {
    if (!this.waitForFrame) {
      return this.done(preserve, result);
    }
  };

  private postMessage = (action: string, data: any) => {
    this.worker.postMessage(
      JSON.stringify({
        action,
        ...data,
      })
    );
  };

  private handleQueryRequest = async (
    id: string,
    method: 'get' | 'post' | 'patch',
    url: string,
    payload: any,
    options?: any
  ) => {
    const data = await AxiosService[method]?.(url, payload, options);
    this.postMessage(RESPONSES.QUERY_RESPONSE, { data, id });
  };

  private handleUIOutput = (markup: string) => {
    if (this.scriptUIRef?.current) {
      this.scriptUIRef.current.innerHTML = markup;
    }
  };

  private onLoad = (payload: {
    iframe: HTMLIFrameElement;
    preserve: boolean;
  }) => {
    if (payload.iframe) {
      this.waitForFrame = false;
      this.handleDone(payload.preserve);
    }
  };

  private handleIframeOutput = (
    url: string,
    allow: Array<string> = [],
    sandbox: Array<string> = [],
    preserve = false
  ) => {
    if (this.scriptUIRef?.current) {
      const parsedAllowList = allow.filter((a) =>
        allowedAllowValues.includes(a)
      );
      const parsedSandboxList = sandbox.filter((s) =>
        allowedSandboxValues.includes(s)
      );
      this.waitForFrame = true;
      const element = document.createElement('iframe');
      element.src = url;
      element.allow = parsedAllowList.join('; ');
      parsedSandboxList.forEach((s) => {
        element.sandbox.add(s);
      });
      element.style.border = 'none';
      element.style.width = '100%';
      element.style.height = '100%';
      element.onload = this.onLoad.bind(this, { iframe: element, preserve });
      if (this.frameId) {
        element.id = this.frameId;
      }
      this.scriptUIRef.current.replaceChildren(element);
    }
  };

  private showToast = (message: string, options: ShowToastOptions) => {
    this.onShowToast?.({
      message,
      variant: options.variant ?? toastVariant.SUCCESS,
      autohide: options.autohide ?? true,
    });
  };

  private clearToasts = () => {
    this.onClearToasts?.();
  };

  private handleRefreshTimeline = (entityId: string) => {
    invalidate.TIMELINE.RECORD(entityId);
  };

  private handleFormPostRequest = (
    id: string,
    url: string,
    payload: any,
    createNewTab: boolean
  ) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    if (createNewTab) {
      form.target = '_blank';
    }

    for (const key in payload) {
      const field = document.createElement('input');
      field.type = 'hidden';
      field.name = key;
      field.value = payload[key];
      form.appendChild(field);
    }

    document.body.appendChild(form);

    form.submit();

    if (createNewTab) {
      document.body.removeChild(form);
    }

    this.postMessage(RESPONSES.POSTFORMDATA_RESPONSE, { success: true, id });
  };

  private handleOpenWindow = (
    url: string,
    target: string,
    features: string
  ) => {
    if (!isRelative(url) || target === '_blank' || !this.history) {
      window.open(url, target, features);
    } else {
      this.history.push(url);
    }
  };

  public run = async (
    scriptBody: string,
    setup: {
      user: any;
      business: any;
      entityId?: string;
      objectId?: string;
      clientObject: any;
      isDebug: boolean;
    },
    args?: string
  ) => {
    this.postMessage(ACTIONS.RUN, {
      script: scriptBody,
      setup,
      args,
    });
  };
}
