import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SmartConnectorContext } from './context';
import {
  ChildCenterHeaderContainer,
  ChildHeaderContainer,
  HeaderContainer,
  StyledBackButton,
} from './styles';
import LoadingButton from '__components/Button/LoadingButton';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import { SMARTCONNECTORS_PATHNAME, SMART_CONNECTOR_STEPS } from '../constants';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from '__components/ToastProvider';

export const Header = ({ scrolledTop }: { scrolledTop: boolean }) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const {
    smartConnector,
    isNew,
    step,
    isValid,
    handleSave,
    loading,
    refreshing,
  } = useContext(SmartConnectorContext);

  const showError = (message: string) => {
    showToast({
      message,
      variant: toastVariant.FAILURE,
    });
  };

  const showSuccess = (message: string) => {
    showToast({
      message,
      variant: toastVariant.SUCCESS,
    });
  };

  const handleSaveWithClose = async () => {
    const saved = await handleSave(true);
    if (saved) {
      showSuccess(t('SmartConnector saved successfully'));
    } else {
      showError(
        step === SMART_CONNECTOR_STEPS.variables
          ? t('Please correct validation errors before finalizing')
          : t('Failed to save SmartConnector')
      );
    }
  };

  const handleSaveInPlace = async () => {
    const saved = await handleSave(false);
    if (saved) {
      showSuccess(t('SmartConnector saved successfully'));
    } else {
      showError(
        step === SMART_CONNECTOR_STEPS.variables
          ? t('Please correct validation errors')
          : t('Failed to save SmartConnector')
      );
    }
  };

  return (
    <HeaderContainer scrolledTop={scrolledTop}>
      <ChildHeaderContainer>
        <StyledBackButton
          to={
            isNew
              ? SMARTCONNECTORS_PATHNAME
              : `${SMARTCONNECTORS_PATHNAME}/${smartConnector.id}/builder`
          }
          variant="text"
          color="blue"
        >
          {isNew ? t('Back to SmartConnectors') : t('Back to Builder')}
        </StyledBackButton>
      </ChildHeaderContainer>
      <ChildCenterHeaderContainer>
        <TextEllipsisWithTooltip as="h2" type="header" weight="light">
          {isNew
            ? t('Adding New SmartConnector')
            : `${t('Editing SmartConnector')} - ${smartConnector.name}`}
        </TextEllipsisWithTooltip>
      </ChildCenterHeaderContainer>
      <ChildHeaderContainer>
        {isNew && step !== SMART_CONNECTOR_STEPS.variables ? null : (
          <LoadingButton
            onClick={handleSaveInPlace}
            disabled={loading || refreshing || !isValid}
            loading={refreshing}
          >
            {t('Save')}
          </LoadingButton>
        )}
        {step === SMART_CONNECTOR_STEPS.variables ? (
          <LoadingButton
            onClick={handleSaveWithClose}
            disabled={loading || refreshing || !isValid}
            loading={refreshing}
          >
            {t('Save & Finalize')}
          </LoadingButton>
        ) : (
          <LoadingButton
            onClick={isNew ? handleSaveInPlace : handleSaveWithClose}
            disabled={loading || refreshing || !isValid}
            loading={refreshing}
          >
            {isNew ? t('Save & Continue') : t('Save & Close')}
          </LoadingButton>
        )}
      </ChildHeaderContainer>
    </HeaderContainer>
  );
};
