import { useCallback, useState, MouseEvent, useRef, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { useElementSize } from '../hooks/useElementSize';
import { useClickOutside } from '../hooks/useClickOutside';

interface RequiredItemOptions {
  id: string;
}

interface MenuProps<T> {
  children: ReactNode;
  items: T[];
  renderItem: (item: T & RequiredItemOptions, onClick: () => void) => ReactNode;
  onChange: (item: T & RequiredItemOptions) => void;
}

export const Menu = <T extends RequiredItemOptions & unknown>(
  props: MenuProps<T>
) => {
  const { children, items, renderItem, onChange } = props;
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuOpen, setMenuOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const { height: triggerHeight } = useElementSize(triggerRef);
  const { width: menuWidth } = useElementSize(
    menuRef,
    String(menuOpen) + JSON.stringify(items)
  );

  const handleClickMenu = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();

    setMenuPosition({
      x: rect.left,
      y: rect.top + window.scrollY,
    });

    setMenuOpen((prev) => !prev);
  }, []);

  useClickOutside(
    menuRef,
    () => {
      setMenuOpen(false);
    },
    triggerRef
  );

  return (
    <div onClick={handleClickMenu} ref={triggerRef}>
      {children}
      {menuOpen
        ? ReactDOM.createPortal(
            <div
              className="kds kds-expanding-scrollbar flex flex-col gap-spacer-20 z-[1] absolute top-[var(--target-y)] left-[var(--target-x)] max-h-[300px] bg-background-white border-solid border-1 border-border-medium rounded-standard p-spacer-10 shadow-tile max-w-[200px]"
              style={
                {
                  '--target-x': `${menuPosition.x - menuWidth}px`,
                  '--target-y': `${menuPosition.y + triggerHeight}px`,
                } as any
              }
              ref={menuRef}
            >
              {items.map((item) => {
                return (
                  <div key={item.id}>
                    {renderItem(item, () => onChange(item))}
                  </div>
                );
              })}
            </div>,
            document.body
          )
        : null}
    </div>
  );
};
