import {
  all,
  takeLatest,
  put,
  call,
  select,
  debounce,
} from 'redux-saga/effects';
import { DEFAULT_DELAY } from 'utility/config';
import TeamMemberService from 'services/TeamMemberService';
import { addToast } from 'store/toastProvider/toastProvider.redux';
import {
  buildPage as buildPageAction,
  buildPageFail,
  buildPageComplete,
  buildPageFinish,
  getAccounts as getAccountsAction,
  deleteAccount as deleteAccountAction,
  updateAccount as updateAccountAction,
  updateAccountFail,
  deleteAccountFail,
  getAccountsFail,
  getAccountsSuccess,
  setPageConfig as setPageConfigAction,
  updatePageConfig,
  getAccountsStart,
  getAccountsFinish,
} from './adManager.redux';
import AdsService from '__services/AdvsService';
import { toastVariant } from 'components/ToastProvider';

const PAGE_CONFIG_KEY = 'ad_manager_list';

function* getAccounts() {
  try {
    yield put(getAccountsStart());
    const pageConfig = yield select(
      (s) => s.adManagerPage.pageConfig.connections
    );
    const data = {
      ordering: pageConfig.sort,
      search: pageConfig.search,
      page_number: pageConfig.page,
      page_size: pageConfig.size,
      include_archived_adaccounts: false,
    };
    const { results, count } = yield call(AdsService.getAccounts, data, false);
    yield put(
      getAccountsSuccess({
        connections: results,
        connectionsCount: count,
      })
    );
  } catch (error) {
    yield put(getAccountsFail(error));
  } finally {
    yield put(getAccountsFinish());
  }
}

function* buildPage() {
  try {
    const [pageResponse] = yield all([
      call(TeamMemberService.getPageConfig, PAGE_CONFIG_KEY),
    ]);

    yield put(
      buildPageComplete({
        pageResponse,
      })
    );

    yield getAccounts();
    yield put(buildPageFinish());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* setPageConfig({ payload }) {
  try {
    const { type, ...config } = payload;
    const pageConfig = yield select((s) => s.adManagerPage.pageConfig);
    const newConfig = {
      ...pageConfig,
      [type]: {
        ...pageConfig[type],
        ...config,
      },
    };
    const savedConfig = {
      ...newConfig,
      [type]: { ...newConfig[type], page: undefined },
    };
    yield call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, savedConfig);
    yield put(updatePageConfig(newConfig));
  } catch (error) {}
}

function* updateAccount({ payload }) {
  try {
    yield call(AdsService.updateAccount, payload);
    yield put(
      addToast({
        variant: toastVariant.SUCCESS,
        message: 'The account has been successfully updated.',
      })
    );
    yield getAccounts();
  } catch (error) {
    yield put(
      addToast({
        variant: toastVariant.FAILURE,
        message: 'The account has not been successfully updated.',
      })
    );
    yield put(updateAccountFail(error));
  }
}

function* deleteAccount({ payload }) {
  try {
    yield call(AdsService.deleteAccount, payload);
    yield put(
      addToast({
        variant: toastVariant.SUCCESS,
        message: 'The account has been successfully deleted.',
      })
    );
    yield getAccounts();
  } catch (error) {
    yield put(
      addToast({
        variant: toastVariant.FAILURE,
        message: 'The account has not been successfully deleted.',
      })
    );
    yield put(deleteAccountFail(error));
  }
}

function* adManagerPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield debounce(DEFAULT_DELAY, getAccountsAction.type, getAccounts);
  yield takeLatest(deleteAccountAction.type, deleteAccount);
  yield takeLatest(updateAccountAction.type, updateAccount);
  yield takeLatest(setPageConfigAction.type, setPageConfig);
}

export default adManagerPage;
