import { cfvChangeTypeOptionsConst } from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/ChangeFieldValue/common';

import DraggableStepCard from '../../DraggableStepCard';
import KizenTypography from 'app/kizentypo';

import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';
import { getFieldValue } from './action-helpers';
import { getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

const getFieldName = ({ fieldToModify }) => fieldToModify?.displayName;

export const getDescription = ({ step, automation, fields, t }) => {
  const { details } = step;
  const { changeType } = details;

  if (changeType?.value === cfvChangeTypeOptionsConst.SPECIFIC_VALUE) {
    return `${t('Change Field')} ‘${getValueOrDeleted(
      getFieldName(details),
      t
    )}’ ${t('to')} ‘${getFieldValue(details, t) || emptyLongDash}’`;
  }

  const { relatedObject, relatedObjectField, thisRecordField } = details;

  const relatedObjectFieldLabel =
    changeType?.value === cfvChangeTypeOptionsConst.RELATED_OBJECT_FIELD
      ? relatedObjectField?.label
      : thisRecordField?.label;

  return `${t('Change Field')} ‘${getValueOrDeleted(
    getFieldName(details),
    t
  )}’ ${t('to Value of')} ‘${getValueOrDeleted(
    relatedObject?.label,
    t
  )}: ${getValueOrDeleted(relatedObjectFieldLabel, t)}’`;
};

export default function ChangeFieldValueCard({ step, ...others }) {
  return (
    <DraggableStepCard {...others}>
      <KizenTypography as="span">{content({ step })}</KizenTypography>
    </DraggableStepCard>
  );
}
