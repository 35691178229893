import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import { ApiCard } from 'pages/Account/pages/Profile/Forms/styles';
import {
  TableTitleWrapper,
  TableBoxesWrapper,
  SearchContainer,
} from 'pages/Account/pages/Profile/Forms/tableStyles';
import ProfileInfiniteTable from 'pages/Account/pages/Profile/Forms/ProfileInfiniteTable';

import BasicModal from 'components/Modals/presets/BasicModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import ApiKeysModalBody from 'pages/Account/pages/Profile/Forms/ApiKeysModalBody';

import { PageSearchInput } from 'components/Layout/PageToolbar';
import { useApiKeys } from 'hooks/apiKeys/useApiKeys';
import { createColumns } from 'pages/Account/pages/Profile/Forms/APIKeys/columns';

import { APIKeysNotice } from 'pages/Account/pages/Profile/Forms/APIKeys/styles';

import { getManageStanadardApiKeys } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const KEY_WIDTH = '135px';
const TEAM_MEMBER_WIDTH = '220px';
const REFRESH_WIDTH = '170px';
const ACTION_WIDTH = '51px';
const PADDING_WIDTH = '20px';

const columnWidths = {
  key: KEY_WIDTH,
  employee: TEAM_MEMBER_WIDTH,
  created: REFRESH_WIDTH,
  actions: ACTION_WIDTH,
  padding: PADDING_WIDTH,
};

const _APIKeys = ({ userId, businessId, manageStanadardApiKeys }) => {
  const { t } = useTranslation();

  const {
    handleViewApiKey: onKeyView,
    apiKeys: keys,
    createdKey,
    viewedKey,
    viewEmployee,
    messageHeadData,
    messagesSort,
    setMessagesSort,
    apiKeysModalProps,
    apiKeysModalButtonProps,
    search,
    setSearch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,
    confirmDeletionModalProps,
    confirmDeletionModal,
    setToDelete,
    anyApiKeys,
  } = useApiKeys(null);

  const columns = useMemo(
    () =>
      createColumns({
        onSelectAction: ({ value }, key) => {
          const { id, employee } = key;
          if (value === 'onKeyView') {
            onKeyView(id, employee);
          }
          if (value === 'onKeyDelete') {
            setToDelete(id);
            confirmDeletionModal.show();
          }
        },
        columnWidths,
        canRemove: manageStanadardApiKeys.remove,
        t,
      }),
    [onKeyView, confirmDeletionModal, manageStanadardApiKeys, setToDelete, t]
  );

  return (
    <>
      <ApiCard as={ContentWidth}>
        <TableTitleWrapper marginBottom="11">
          <KizenTypography as="h3" type="subheader">
            {t('API Keys')}
          </KizenTypography>
          <SearchContainer>
            <PageSearchInput
              placeholder={t('Find Keys')}
              value={search}
              onChange={setSearch}
              loading={isFetchingNextPage || isFetching}
            />
          </SearchContainer>
        </TableTitleWrapper>
        <TableBoxesWrapper>
          <APIKeysNotice>
            <KizenTypography as="div">
              {t(
                '(For Techies) API Keys allow full access to accounts, simulating all permissions of a logged in user. API Keys are only necessary to connect to other tools.'
              )}
              &nbsp;
              {!anyApiKeys ? t(`You don't yet have any API Keys.`) : false}
            </KizenTypography>
          </APIKeysNotice>
        </TableBoxesWrapper>
        {keys ? (
          <>
            <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
              <ScrollContainerBlocker />
            </ScrollContainerBlockerWrapper>
            <ProfileInfiniteTable
              columns={columns}
              data={keys}
              messageHeadData={messageHeadData}
              messagesSort={messagesSort}
              setMessagesSort={setMessagesSort}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isFetching={isFetching}
              status={status}
            />
          </>
        ) : (
          false
        )}
      </ApiCard>
      <BasicModal {...apiKeysModalButtonProps} {...apiKeysModalProps}>
        <ApiKeysModalBody
          apiKey={createdKey || viewedKey}
          userId={viewEmployee.user_id ?? ''}
          businessId={businessId}
          showCreatedKeyInfo={!!createdKey}
        />
      </BasicModal>
      <ConfirmDeletionModal
        heading={t('Please Confirm Deletion')}
        {...confirmDeletionModalProps}
      >
        <KizenTypography>
          {t(
            'Deleting this API key will make all subsequent requests using it denied by Kizen.'
          )}
        </KizenTypography>
      </ConfirmDeletionModal>
    </>
  );
};

const APIKeys = ({ userId, businessId }) => {
  const manageStanadardApiKeys = useSelector(getManageStanadardApiKeys);

  return manageStanadardApiKeys?.view
    ? _APIKeys({ userId, businessId, manageStanadardApiKeys })
    : false;
};
export default APIKeys;
