import { useState } from 'react';
import * as Sentry from '@sentry/react';
import CustomObjectsService from 'services/CustomObjectsService';
import useField from 'hooks/useField';
import Switch from 'components/Kizen/Switch';
import { toastVariant, useToast } from 'components/ToastProvider';
import { getOriginalError } from 'services/AxiosService';

export const AccessSwitch = ({
  access,
  customObjectId,
  entityId,
  disabled,
  association,
  tooltip,
  tooltipPlacement,
}) => {
  const [checked, setChecked] = useField(!!access);
  const [patching, setPatching] = useState(false);
  const [showToast] = useToast();

  const handleChange = async () => {
    setPatching(true);
    setChecked(!checked);
    try {
      await CustomObjectsService.updateAssociatedTeamMember(
        {
          customObjectId,
          entityId,
          associationId: association.id,
          payload: { has_record_access: !checked },
        },
        { skipErrorBoundary: true }
      );
    } catch (err) {
      const originalError = getOriginalError(err);
      if (originalError?.has_record_access) {
        showToast({
          variant: toastVariant.FAILURE,
          message: originalError.has_record_access,
        });
      } else {
        Sentry.captureException(err);
      }
      setChecked(checked);
    } finally {
      setPatching(false);
    }
  };

  return (
    <Switch
      removeMargin
      disabled={disabled || patching}
      checked={checked}
      onChange={handleChange}
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
    />
  );
};
