import { useCallback, useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SubNavigationBar from 'components/Kizen/SubNavigationBar';
import Button from 'components/Button';
import { breakpoints, isMobile, useWindowSize } from 'app/spacing';
import MobileNavigation from '../MobileNavigation';
import DateRangePicker from 'components/Kizen/DateRangePicker';
import { StyledNav, StyledSubNavLabel } from './styles';
import EditDashboardsButton from '../EditDashboardsButton';
import TeamRoleSelector from '../TeamRoleSelector';
import { DEFAULT_DATE_RANGE, formatDate } from 'components/DashboardGrid/utils';

const SubNav = ({
  currentDashboard = null,
  allDashboards = [],
  setDashboard,
  triggerAddDashletProps,
  setDateFilter,
  teamFilter = {},
  dateFilter = null,
  handleClickEditDashboards,
  showSettings = true,
  hasEditPermission,
  onChangeGroupedValue,
  handleClickAddDashboard,
  roleOptions,
  unavailable = false,
  missing = false,
  ranges,
  todayDateInBusinessTimeZone,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const dashboardsToShow = useMemo(
    () => allDashboards.filter((dash) => !dash.hidden),
    [allDashboards]
  );

  const handleChangeDate = useCallback(
    (result, selectedIndex) => {
      const start = formatDate(result[0]);
      const end = formatDate(result[1]);
      setDateFilter({
        start,
        end,
        selectedIndex,
      });
    },
    [setDateFilter]
  );

  return isMobile(width, breakpoints.md) ? (
    <MobileNavigation
      dashboardsToShow={dashboardsToShow}
      currentDashboard={currentDashboard}
      setDashboard={setDashboard}
      triggerAddDashletProps={triggerAddDashletProps}
      teamFilter={teamFilter}
      handleChangeDate={handleChangeDate}
      dateFilter={dateFilter}
      showSettings={showSettings}
      hasEditPermission={hasEditPermission}
      roleOptions={roleOptions}
      onChangeGroupedValue={onChangeGroupedValue}
      missing={missing}
      unavailable={unavailable}
      ranges={ranges}
      todayDateInBusinessTimeZone={todayDateInBusinessTimeZone}
    />
  ) : (
    <SubNavigationBar
      label={
        dashboardsToShow.length > 0 && !missing && !unavailable ? (
          <StyledSubNavLabel
            options={dashboardsToShow}
            value={currentDashboard}
            onChange={setDashboard}
            handleClickEditDashboards={handleClickEditDashboards}
            handleClickAddDashboard={handleClickAddDashboard}
            components={
              showSettings
                ? {
                    Menu: EditDashboardsButton,
                  }
                : undefined
            }
            classNamePrefix="dashboard-menu"
            buildHref={(id) => `/dashboard/${id}`}
          />
        ) : (
          <StyledSubNavLabel
            options={dashboardsToShow}
            value={{
              name: unavailable ? t('Unavailable') : t('None'),
            }}
            onChange={setDashboard}
            handleClickEditDashboards={handleClickEditDashboards}
            handleClickAddDashboard={handleClickAddDashboard}
            components={
              showSettings
                ? {
                    Menu: EditDashboardsButton,
                  }
                : undefined
            }
            classNamePrefix="dashboard-menu"
            buildHref={(id) => `/dashboard/${id}`}
          />
        )
      }
    >
      <StyledNav className="mr-sm-auto ml-sm-0 mr-0 ml-5 d-none d-md-flex">
        {unavailable || missing ? null : (
          <>
            <DateRangePicker
              key={todayDateInBusinessTimeZone}
              label={t('Date Filter')}
              title={t('Date Filter')}
              defaultRange={DEFAULT_DATE_RANGE}
              value={
                dateFilter
                  ? [dateFilter.start, dateFilter.end]
                  : DEFAULT_DATE_RANGE
              }
              selectedIndex={dateFilter?.selectedIndex ?? -1}
              onOk={handleChangeDate}
              ranges={ranges}
              todayDate={todayDateInBusinessTimeZone}
            />
            <TeamRoleSelector
              roleOptions={roleOptions}
              onChange={onChangeGroupedValue}
              value={teamFilter}
            />
          </>
        )}
      </StyledNav>
      <Nav
        data-qa="dashboard-nav"
        data-qa-settings-visible={showSettings}
        data-qa-add-dashlet-visible={currentDashboard && hasEditPermission}
      >
        {showSettings && (
          <Button
            color="blue"
            data-qa={'dashboard-settings'}
            onClick={handleClickEditDashboards}
            maxWidth="250px"
          >
            {t('Manage Dashboards')}
          </Button>
        )}
        {currentDashboard && hasEditPermission ? (
          <Button {...triggerAddDashletProps}>{t('Add Dashlet')}</Button>
        ) : null}
      </Nav>
    </SubNavigationBar>
  );
};

export default SubNav;
