import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons/faTriangleExclamation';
import { faTriangleExclamation as faTriangleExclamationSolid } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { faMessageLines } from '@fortawesome/pro-light-svg-icons/faMessageLines';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion';
import { faSignalStream } from '@fortawesome/pro-light-svg-icons/faSignalStream';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faArrowRightToBracket } from '@fortawesome/pro-light-svg-icons/faArrowRightToBracket';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons/faAlignJustify';
import { faSquarePollVertical } from '@fortawesome/pro-light-svg-icons/faSquarePollVertical';
import {
  CompoundIconSettings,
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';
import { faTimer } from '@fortawesome/pro-light-svg-icons/faTimer';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const SEMANTIC_CATEGORY: SemanticCategory = 'message';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  comment: faMessageLines,
  'warning-triangle': faTriangleExclamation,
  'automation-email-warning': null,
  'info-circle': faCircleExclamation,
  'help-circle': faCircleQuestion,
  'event-lead-source': faSignalStream,
  'event-activity': faClipboardList,
  'event-contact-communication': faEnvelope,
  'event-automation': faArrowsRotate,
  'event-contact-add': faUserPlus,
  'event-interaction': faArrowRightToBracket,
  'event-form': faAlignJustify,
  'event-survey': faSquarePollVertical,
  'last-updated': faTimer,
};

export const labels: IconLabel = {
  comment: {
    title: (t) => '',
    description: (t) => t('Comment'),
  },
  'warning-triangle': {
    title: (t) => '',
    description: (t) => t('Warning'),
  },
  'automation-email-warning': {
    title: (t) => '',
    description: (t) => t('Warning'),
  },
  'info-circle': {
    title: (t) => '',
    description: (t) => t('Info'),
  },
  'help-circle': {
    title: (t) => '',
    description: (t) => t('Help'),
  },
  'event-lead-source': {
    title: (t) => '',
    description: (t) => t('Lead Source'),
  },
  'event-activity': {
    title: (t) => '',
    description: (t) => t('Activity'),
  },
  'event-contact-communication': {
    title: (t) => '',
    description: (t) => t('Communication'),
  },
  'event-automation': {
    title: (t) => '',
    description: (t) => t('Automation'),
  },
  'event-contact-add': {
    title: (t) => '',
    description: (t) => t('Contact Add'),
  },
  'event-interaction': {
    title: (t) => '',
    description: (t) => t('Interaction'),
  },
  'event-form': {
    title: (t) => '',
    description: (t) => t('Form'),
  },
  'event-survey': {
    title: (t) => '',
    description: (t) => t('Survey'),
  },
  'last-updated': {
    title: (t) => '',
    description: (t) => t('Last Updated'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};

export const compoundIcons: CompoundIconSettings = {
  'automation-email-warning': {
    primary: faEnvelope as IconDefinition,
    secondary: faTriangleExclamationSolid as IconDefinition,
    secondaryPosition: 'bottom-right',
    secondaryClassName: 'scale-[60%]',
  },
};
