import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FIELD_TYPES, STAGE_STATUSES } from 'utility/constants';
import Loader from 'components/Kizen/Loader';
import {
  camelToSnakeCaseKeys,
  getDirtyKeys,
  snakeToCamelCase,
  snakeToCamelCaseKeys,
} from 'services/helpers';
import FieldService from 'services/FieldService';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';
import ClientService from 'services/ClientService';
import { isPipelineObject } from 'components/Modals/utilities';
import { fieldTypeMapper } from 'components/Fields/FieldInput/helpers';
import { getFieldsForUI } from 'store/customObjectsRecordsPage/saga';
import { toastVariant, useToast } from 'components/ToastProvider';
import { getToastConfig } from 'pages/ContactDetail/toastConfig';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useAddReasonLostModal } from 'pages/CustomObjects/RecordsPage/modals/AddReasonLostModal/hooks';
import { useAddReasonDisqualifiedModal } from 'pages/CustomObjects/RecordsPage/modals/AddReasonDisqualifiedModal/hooks';
import { COLUMN_SIZE, Column, Grid } from '@kizen/kds/Grid';
import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';
import { LeadInfo } from './blocks/LeadInfo';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import {
  ACTIVITIES,
  CLIENTS,
  CUSTOM_RECORDS,
  TIMELINE,
  DETAILS_RELATED_PIPELINES,
  CUSTOM_OBJECTS,
} from 'queries/query-keys';
import { Timeline } from './blocks/Timeline';
import { Action } from 'ts-components/RecordLayout/blocks/Action';
import { useDispatch, useSelector } from 'react-redux';
import TimelineService from 'services/TimelineService';

import { TeamActivities } from 'ts-components/RecordLayout/blocks/TeamActivities';
import { FieldsBlock } from 'ts-components/RecordLayout/blocks/Fields';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import useFormValidation from 'hooks/useFormValidation';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { invalidate } from 'queries/invalidate';
import { getOriginalError } from 'services/AxiosService';
import { RelatedObjectsBlock } from 'ts-components/RecordLayout/blocks/RelatedObjects';
import CustomObjectControlBar from 'pages/CustomRecordDetails/ControlBar';
import Automation2Service from 'services/Automation2Service';
import LoggableActivityService from 'services/LoggableActivityService';
import {
  StickyNavMobileWrapper,
  StickyNavWrapper,
  StyledSubNav,
} from 'pages/Common/styles';
import getRoutes from 'pages/CustomRecordDetails/routes';
import styled from '@emotion/styled';
import { RelatedPipelinesBlock } from 'ts-components/RecordLayout/blocks/RelatedPipelines';

import {
  buildPage as buildCustomObjectPage,
  getCustomObjectConfigFetching,
  updatePageConfig as updateCustomObjectPageConfig,
  getCustomObjectConfig,
} from 'store/recordDetailPage/record.redux';
import {
  DEFAULT_FILTER_CONFIG,
  getAggregatedFilters,
  getPrimaryFilters,
} from 'components/Timeline2/useTimelineInteraction';
import { useBreakpoint } from 'app/spacing';
import ConfirmNavigationModal from 'components/Modals/presets/ConfirmNavigation';
import ContactControlBar from 'pages/ContactDetail/ControlBar';
import clientRoutes from 'pages/ContactDetail/routes';
import {
  getContacts,
  updatePageConfig as updateClientPageConfig,
  buildPage as buildClientPage,
} from 'store/contactsPage/actions';
import { RECORD_LIST_CONFIG_KEYS } from 'services/TeamMemberService';
import useModal from 'components/Modals/useModal';
import PerformActionModal from 'pages/ContactDetail/PerformActionModal';
import { performActionOptions } from 'pages/ContactDetail/PerformActionSteps/model';
import AddReasonLostModal from 'pages/CustomObjects/RecordsPage/modals/AddReasonLostModal';
import { isReasonDisqualifiedField, isReasonLostField } from 'checks/fields';
import AddReasonDisqualifiedModal from 'pages/CustomObjects/RecordsPage/modals/AddReasonDisqualifiedModal';
import { useAutomationPermissions } from 'ts-components/hooks/permissions/automations';
import { useCanSingleSendMessage } from 'ts-components/hooks/permissions/messages';
import { useCanViewTimeline } from 'ts-components/hooks/permissions/timeline';
import { useCanScheduleActivities } from 'ts-components/hooks/permissions/activities';
import { ScrollTopButton } from 'ts-components/ScrollTopButton';
import { ForceFieldRevalidationProvider } from 'ts-components/RecordLayout/blocks/Fields/FieldErrorContext';
import { TimelineEventModal } from 'ts-components/RecordLayout/blocks/Timeline/EventModal';
import { CustomContentBlock } from 'ts-components/RecordLayout/blocks/CustomContent';
import { monitoringExceptionHelper } from 'sentry/helpers';
import { RecordLayoutLogActivityContext } from 'ts-components/RecordLayout/RecordLayoutLogActivityContext';
import { RecordLayoutStartAutomationContext } from 'ts-components/RecordLayout/RecordLayoutStartAutomationContext';

const Wrapper = styled.div`
  max-width: 1412px;
  margin: 0 auto;
  padding: 0 20px;
`;

const BLOCK_TYPES = {
  FIELDS: 'fields',
  LEAD_SOURCES: 'lead_sources',
  TIMELINE: 'timeline',
  TEAM_AND_ACTIVITIES: 'team_and_activities',
  ACTION: 'action_block',
  RELATED_OBJECT_FIELDS: 'related_object_fields',
  RELATED_PIPELINES: 'related_pipelines',
};

export const fieldMapper = (field) => {
  // for each of these entries we reach into the associated object to get the value
  const valuesFromObject = new Map([
    ['entityValue', 'amount'],
    ['stage', 'id'],
  ]);

  return Object.keys(field).reduce((acc, item) => {
    const key = valuesFromObject.get(item);
    return {
      ...acc,
      [item]: key ? field[item] && field[item][key] : field[item], // make sure that field[item] exist before using it
    };
  }, {});
};

const setRecordTitle = (title, { objectName = '', entityName = '' }) =>
  (entityName && objectName ? `${entityName} - ${objectName} - ` : '') + title;

const defaultPageConfig = {
  timeline: {
    filter: DEFAULT_FILTER_CONFIG,
  },
};

const customObjectRemoveDefaultFields = (fields, isDefaultLookUp) =>
  Object.values(fields)
    .filter((f) => {
      return !isDefaultLookUp[f.id]?.isDefault;
    })
    .sort(
      (a, b) =>
        (isDefaultLookUp[a.id]?.order ?? 0) -
        (isDefaultLookUp[b.id]?.order ?? 0)
    )
    .reduce((acc, f) => ({ ...acc, ...{ [f.id]: f } }), {});

const customObjectValueReducer = (customObject) => (collect, field) => {
  const isCustom = customObject?.fields.some((item) => item.field === field.id);
  return {
    ...collect,
    [field.id]: FieldService.getFieldValue(customObject, field, isCustom),
  };
};

const getItemCategories = (item, fieldCategoryIds) => {
  if (!item.metadata) {
    return fieldCategoryIds;
  }

  if (!item.metadata?.autoInclude) {
    return item.metadata?.included ?? [];
  }

  return fieldCategoryIds.filter(
    (id) => !item.metadata?.excluded?.includes(id)
  );
};

const RecordDetailsPage = ({ title, params }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, objectId } = params;
  const qs = new URLSearchParams(history.location.search);
  const overrideLayout = qs.get('layout');
  const [noViewPermissions, setNoViewPermissions] = useState(false);
  const [showToast] = useToast();
  const toastConfig = getToastConfig(t, history);
  const dispatch = useDispatch();
  const isFetchingCustomObjectFilterConfig = useSelector(
    getCustomObjectConfigFetching
  );
  const isFetchingClientFilterConfig = useSelector(
    (s) => s.contactPage.isFetching
  );
  const clientObject = useSelector((s) => s.contactPage?.clientObject);
  const clientObjectId = clientObject?.id;

  const aggregateObjectId = objectId || clientObjectId;

  const { detailPageConfig = defaultPageConfig } = useSelector(
    getCustomObjectConfig(aggregateObjectId)
  );

  const clientPageConfig = useSelector((s) => s.contactPage.pageConfig);

  const isClient = id && !objectId;

  const isFetchingFilterConfig = isClient
    ? isFetchingClientFilterConfig
    : isFetchingCustomObjectFilterConfig;

  const hasScheduledActivityPermission = useCanScheduleActivities();

  // We set this just to trigger a new render at this level, no need
  // to handle the value itself
  const [, setFieldBlockSharedKey] = useState(() => Date.now());

  const [saveKey, setSaveKey] = useState(() => Date.now());
  const [currentFieldBlockCategories, setCurrentFieldBlockCategories] =
    useState({});
  const [selectedLayout, setSelectedLayout] = useState();
  const [handledErrors, setHandledErrors] = useState();

  const clearErrors = useCallback(() => {
    setHandledErrors(undefined);
  }, []);

  const chosenFieldCategories = useRef({});
  const setChosenFieldCategories = useCallback(
    (blockId, categoryId) =>
      (chosenFieldCategories.current = {
        ...chosenFieldCategories.current,
        [blockId]: categoryId,
      }),
    []
  );

  const loadedCategories = useRef({});
  const setLoadedCategories = useCallback((categoryId) => {
    loadedCategories.current = {
      ...loadedCategories.current,
      [categoryId]: true,
    };
  }, []);
  const clearLoadedCategories = () => (loadedCategories.current = {});

  const [loadingCategory, setLoadingCategory] = useState([]);

  const isMobile = useBreakpoint(839); // This value matches when the KDS grid breaks to the mobile layout

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search || '');
  const persistentParams = useRef();
  const eventIdParam = searchParams.get('event_id');
  const createdParam = searchParams.get('created');

  if (!persistentParams.current && eventIdParam && createdParam) {
    persistentParams.current = {
      eventId: eventIdParam,
      created: createdParam,
    };
  }

  const event_id = persistentParams.current?.eventId;
  const created = persistentParams.current?.created;

  // Strip out the params from the URL, but persist them in the component state
  // so we can use them to query the timeline, but not on subsequent reloads
  useEffect(() => {
    if (eventIdParam && createdParam) {
      history.replace(window.location.pathname);
    }
  }, [eventIdParam, createdParam, history]);

  const {
    data: {
      contact,
      fieldData: clientFieldData = [],
      leadSourceInfo: clientLeadSourceInfo,
      fieldTypes,
    } = {},
    refetch: refetchClient,
    isLoading: contactLayoutsListLoading,
    isRefetching: isClientRefetching,
  } = useQuery({
    queryKey: CLIENTS.DETAIL(id),
    queryFn: async () => {
      try {
        const cats = await FieldService.getCategorizedFields({
          for: 'contacts',
        });

        const categoryFieldsIds = [
          ...new Set(Object.values(chosenFieldCategories.current || {})),
        ];
        if (categoryFieldsIds.length === 0) {
          categoryFieldsIds.push(cats[0]?.id);
        }

        clearLoadedCategories();

        const allRequests = await Promise.all(
          categoryFieldsIds.map(async (categoryFieldsId) => {
            const recordParams = {
              params: { field_category: categoryFieldsId },
            };

            setLoadedCategories(categoryFieldsId);

            return await ClientService.getById(id, recordParams);
          })
        );

        const con = allRequests.reduce(
          (acc, { fields, ...rest }) => ({
            fields: [...acc.fields, ...fields],
            ...rest,
          }),
          { fields: [] }
        );

        const fieldData = cats.map((cat) => ({
          ...cat,
          fields: cat.fields.filter((f) => !f.isHidden),
        }));

        const fields = fieldData.flatMap((cat) => cat.fields);

        const fieldTypes = fields.reduce(
          (collect, { id, fieldType }) => ({
            ...collect,
            [id]: fieldType,
          }),
          {}
        );

        const values = (fields || []).reduce(
          (collect, field) => ({
            ...collect,
            [field.id]: FieldService.getFieldValue(con, field),
          }),
          {}
        );

        return {
          leadSourceInfo: {
            firstLeadSource: con.firstLeadSource,
            lastLeadSource: con.lastLeadSource,
            leadSourceTypes: con.leadSourceTypes,
          },
          contact: con,
          fieldData,
          fields,
          values,
          fieldTypes,
        };
      } catch (err) {
        const data = err.isAxiosError && err.response && err.response.data;
        const badPermissions = data && data.badPermissions;
        if (badPermissions) {
          setNoViewPermissions(true);
          showToast(toastConfig.general.noPermission);
        } else {
          queryClient.resetQueries({
            queryKey: CLIENTS.DETAIL(id),
          });
        }
        return {};
      }
    },
    enabled: isClient,
    // Disable the built-in refetching mechanism because we don't want unsaved data being errased
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const {
    data: {
      customObject,
      customObjectModel,
      fieldData: customObjectFieldData = [],
      leadSourceInfo: customObjectLeadSourceInfo,
      recordStatus,
      isDefaultLookUp: customObjectIsDefaultLookUp,
    } = {},
    refetch: refetchCustomObject,
    isRefetching: isCustomObjectRefetching,
    isLoading: customObjectLayoutsListLoading,
  } = useQuery({
    queryKey: CUSTOM_RECORDS.DETAIL(objectId, id),
    queryFn: async () => {
      try {
        const model = await FieldService.getModel({
          id: objectId,
        });

        const { categorizedFields: cats } =
          await CustomObjectsService.getCategorizedModelFields(model.id);

        const categoryFieldsIds = Object.values(
          chosenFieldCategories.current || {}
        );
        if (categoryFieldsIds.length === 0) {
          categoryFieldsIds.push(cats[0]?.id);
        }

        clearLoadedCategories();

        const allRequests = await Promise.all(
          categoryFieldsIds.map(async (categoryFieldsId) => {
            const recordParams = {
              params: { field_category: categoryFieldsId },
            };

            setLoadedCategories(categoryFieldsId);
            return isPipelineObject(model)
              ? await PipelineService.getPipelineRecord(
                  {
                    id,
                    objectId,
                  },
                  recordParams
                )
              : await CustomObjectsService.getCustomObjectRecord(
                  {
                    id,
                    objectId,
                  },
                  recordParams
                );
          })
        );

        let record = allRequests.reduce(
          (acc, { fields, ...rest }) => ({
            fields: { ...acc.fields, ...fields },
            ...rest,
          }),
          { fields: {} }
        );

        // first a dictionary
        let order = 0;
        const isDefaultLookUp = cats.reduce((acc, cat) => {
          return cat.fields.reduce((facc, { id, isDefault }) => {
            order++;
            return { ...facc, [id]: { isDefault, order } };
          }, acc);
        }, {});

        // second remove any default fields,
        // we alsoorder fields first to last - that way useGetActivityPredefined picks the first one
        record = {
          ...record,
          fields: customObjectRemoveDefaultFields(
            record.fields,
            isDefaultLookUp
          ),
        };

        const status = record.stage?.status;

        return {
          leadSourceInfo: {
            firstLeadSource: record?.firstLeadSource || '',
            lastLeadSource: record?.lastLeadSource || '',
            leadSourceTypes: record?.leadSourceTypes || [],
          },
          customObject: {
            ...fieldMapper(record),
            fields: getFieldsForUI(record.fields),
          },
          customObjectModel: model,
          fieldData: cats.map((cat, index) => ({
            ...cat,
            populated: index === 0,
            fields: cat.fields
              .filter((f) => !f.isHidden)
              .map((f) => ({
                ...f,
                name: snakeToCamelCase(f.name),
                // Due to pervasive snakeToCamelCase conversions, and the fact that our schema is dynamic
                // sometimes we need to convert property values in order to later use those as prop keys
                // however, `snake_to_camel(camel_to_snake(value)) !== value` sometimes, leading to weird
                // breakages for *some* field types but not always. So we are copying the `orginalSnakeCaseName`
                // here as a low-risk way to maintiain the original field name so it can be used as expected
                // when we want the proper snake_case field name
                originalSnakeCaseName: f.name,
                fieldType: fieldTypeMapper(f),
              })),
          })),
          recordStatus: status,
          isDefaultLookUp,
        };
      } catch (err) {
        monitoringExceptionHelper(err);
        const data = err.isAxiosError && err.response && err.response.data;
        const badPermissions = data && data.badPermissions;
        if (badPermissions) {
          setNoViewPermissions(true);
          showToast(toastConfig.general.noPermission);
        } else {
          queryClient.resetQueries({
            queryKey: CUSTOM_RECORDS.DETAIL(objectId, id),
          });
        }

        return {};
      }
    },
    enabled: !isClient,
    // Disable the built-in refetching mechanism because we don't want unsaved data being errased
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const layoutsListLoading = isClient
    ? contactLayoutsListLoading
    : customObjectLayoutsListLoading;

  const isRefetching = isClient ? isClientRefetching : isCustomObjectRefetching;

  const canEdit = useMemo(() => {
    if (isClient) {
      return contact?.access?.edit ?? false;
    }

    return customObject?.access?.edit ?? false;
  }, [customObject, contact, isClient]);

  const fieldData = useMemo(() => {
    return isClient ? clientFieldData : customObjectFieldData;
  }, [isClient, clientFieldData, customObjectFieldData]);

  const canViewTimeline = useCanViewTimeline({
    objectId,
    isClient,
  });

  const entityObject = contact ?? customObject;

  const { canStart } = useAutomationPermissions({
    objectId,
    isClient,
    entityObject,
  });

  const canSingleSendMessage = useCanSingleSendMessage();

  const customObjectActionBlockPermissions = useMemo(() => {
    return {
      startAutomations: canStart,
    };
  }, [canStart]);

  const clientActionBlockPermissions = useMemo(() => {
    return {
      startAutomations: canStart,
      sendMessage: canSingleSendMessage,
    };
  }, [canStart, canSingleSendMessage]);

  const actionBlockPermissions = useMemo(() => {
    return isClient
      ? clientActionBlockPermissions
      : customObjectActionBlockPermissions;
  }, [
    isClient,
    clientActionBlockPermissions,
    customObjectActionBlockPermissions,
  ]);

  const refetch = useMemo(() => {
    return isClient ? refetchClient : refetchCustomObject;
  }, [isClient, refetchClient, refetchCustomObject]);

  const leadSourceInfo = useMemo(() => {
    return isClient ? clientLeadSourceInfo : customObjectLeadSourceInfo;
  }, [isClient, clientLeadSourceInfo, customObjectLeadSourceInfo]);

  useSetTitleOnLoad(
    contact?.full_name ?? title(t),
    {
      entityName: customObject?.displayName || customObject?.name,
      objectName: customObjectModel?.objectName,
    },
    setRecordTitle
  );

  const fields = useMemo(() => {
    return fieldData ? fieldData.flatMap((cat) => cat.fields) : [];
  }, [fieldData]);

  const values = useMemo(() => {
    if (isClient) {
      return (fields || []).reduce(
        (collect, field) => ({
          ...collect,
          [field.id]: FieldService.getFieldValue(contact, field),
        }),
        {}
      );
    }

    return (fields || []).reduce(customObjectValueReducer(customObject), {});
  }, [customObject, fields, isClient, contact]);

  const stagedFormData = useRef({});
  const fieldStateOnlyUpdatesOnBlur = useRef();

  const setFormData = useCallback(
    (data, resetSyncState = false) => {
      stagedFormData.current = data;
      if (data && (!fieldStateOnlyUpdatesOnBlur.current || resetSyncState)) {
        fieldStateOnlyUpdatesOnBlur.current = structuredClone(data);
        setFieldBlockSharedKey(Date.now());
        clearErrors();
      }
    },
    [clearErrors]
  );

  const touchedFields = useRef({});
  const setTouchedFields = useCallback(
    (data) => (touchedFields.current = data),
    []
  );

  const [addReasonLostModalProps, addReasonLostModal] = useAddReasonLostModal({
    handleUpdate: ({ patch }) => handleUpdateRecord(patch),
  });
  const [addReasonDisqualifiedModalProps, addReasonDisqualifiedModal] =
    useAddReasonDisqualifiedModal({
      handleUpdate: ({ patch }) => handleUpdateRecord(patch),
    });

  const handleUpdateRecord = useCallback(
    async (patch, updateTimeline = true) => {
      if (isPipelineObject(customObjectModel)) {
        await PipelineService.updatePipelineRecord(
          id,
          objectId,
          patch,
          undefined,
          updateTimeline
        );
        const lostStages = customObjectModel?.pipeline?.stages?.filter(
          ({ status }) => status === STAGE_STATUSES.lost
        );
        const disqualifiedStages = customObjectModel?.pipeline?.stages?.filter(
          ({ status }) => status === STAGE_STATUSES.disqualified
        );
        if (
          disqualifiedStages?.length &&
          disqualifiedStages?.find((item) => item.id === patch.stageId)
        ) {
          addReasonDisqualifiedModal.show();
        }
        if (
          lostStages?.length &&
          lostStages?.find((item) => item.id === patch.stageId)
        ) {
          addReasonLostModal.show();
        }
      } else {
        await FieldService.patchCustomModelRecord(
          id,
          objectId,
          patch,
          undefined,
          updateTimeline
        );
      }
      await refetch();
    },
    [
      customObjectModel,
      refetch,
      id,
      objectId,
      addReasonDisqualifiedModal,
      addReasonLostModal,
    ]
  );

  const [initialFormData, setInitialFormData] = useState(values);
  const [touchedFormData, setTouchedFormData] = useState(false);

  const fieldsTypes = useMemo(
    () =>
      fields.reduce(
        (collect, { id, fieldType }) => ({ ...collect, [id]: fieldType }),
        {}
      ),
    [fields]
  );

  const resetAllFields = useCallback(() => {
    clearErrors();
    setTouchedFields({});
    setTouchedFormData(false);
    setFormData(structuredClone(values), true);
  }, [clearErrors, setTouchedFields, setFormData, values]);

  const {
    validateFormState,
    validationProps,
    handleInputChange,
    resetValidationState,
  } = useFormValidation({
    formState: stagedFormData.current,
    setFormState: setFormData,
    resetToDefaultOnValidateErr: true,
  });

  const getEditPayloadCO = useCallback(() => {
    const dirtyKeys = getDirtyKeys(
      touchedFields.current,
      initialFormData,
      stagedFormData.current,
      fieldsTypes
    );
    const editableFields = fields.filter(
      ({ access, id: fieldId }) => access.edit && dirtyKeys.includes(fieldId)
    );

    return {
      patch: FieldService.getCustomPayload(
        camelToSnakeCaseKeys(stagedFormData.current),
        editableFields
      ),
      editableFields,
    };
  }, [fields, fieldsTypes, initialFormData]);

  const getEditPayloadClient = useCallback(() => {
    const dirtyKeys = getDirtyKeys(
      touchedFields.current,
      initialFormData,
      stagedFormData.current,
      fieldTypes
    );
    const editableFields = fields.filter(
      ({ access, id: fieldId }) => access.edit && dirtyKeys.includes(fieldId)
    );

    return {
      patch: FieldService.getPayload(
        camelToSnakeCaseKeys(stagedFormData.current),
        editableFields
      ),
      editableFields,
    };
  }, [fields, fieldTypes, initialFormData]);

  const queryClient = useQueryClient();

  // This key should be made of things that can affect the outcome of a timeline query.
  // For this reason, fields with string or number values are skipped, and the key is only comprised of fields
  // that look like a relationship or a list of relationships, i.e. value like { id: '1234' } or [{ id: '1234' }]
  const valuesKeyPortion = useMemo(() => {
    if (saveKey) {
      return Object.keys(stagedFormData.current)
        .sort()
        .reduce((acc, curr) => {
          const field = stagedFormData.current[curr];

          if (typeof field === 'string') {
            return acc;
          }

          if (field?.id) {
            return `${acc}:${curr}>${field.id}`;
          }

          if (field?.length) {
            return `${acc}:${curr}>${field
              .map((i) => (i?.id ? i.id : ''))
              .filter(Boolean)
              .join(',')}`;
          }

          return acc;
        }, '');
    }

    return '';
  }, [saveKey]);

  const handleSubmitCO = useCallback(
    async ({ displayToast = true, updateTimeline = true } = {}) => {
      clearErrors();
      const { patch, editableFields } = getEditPayloadCO();

      // TODO Wrap this block in validation after testing, since messages
      // from API are much more helpful than silent failure :) ? Or do something to
      // communicate validation errors? or API errors?
      // if (FieldService.validate(formData, editableFields, { patch: true })) {
      try {
        if (editableFields.length > 0) {
          await handleUpdateRecord(patch, updateTimeline);
          invalidate.DETAILS_RELATED_OBJECTS.RECORD(customObjectModel.id, id);
        }
        // show the toast bassed on the flag not the saving
        if (displayToast) {
          showToast({
            message: `${customObject.name} ${t(
              'has been saved successfully.'
            )}`,
            variant: toastVariant.SUCCESS,
          });
        }
      } catch (err) {
        const orig = snakeToCamelCaseKeys(getOriginalError(err));
        // special error status
        if (orig && orig.nonFieldErrors) {
          showToast({
            variant: toastVariant.FAILURE,
            message: orig.nonFieldErrors.toString(),
          });
        } else if (orig) {
          const errState = {};
          let firstField;
          Object.keys(orig).forEach((item) => {
            const f = fields.find((i) => item === snakeToCamelCase(i.name));
            if (!f) return;
            if (!firstField) {
              firstField = f;
            }
            errState[f.id] = Array.isArray(orig[item])
              ? orig[item][0]
              : orig[item];
          });

          if (orig.fields) {
            orig.fields.forEach((field, index) => {
              const found = patch?.fields?.[index];
              if (found?.id && field?.value) {
                errState[found.id] = Array.isArray(field.value)
                  ? field.value[0]
                  : field.value;
              }
            });
          }

          setHandledErrors(errState);
        } else {
          // rest unhandled cases
          showToast({
            variant: toastVariant.FAILURE,
            message: t(
              `The Entity was not updated. Please try again or contact Kizen support.`
            ),
          });
        }
      }
    },
    [
      fields,
      handleUpdateRecord,
      showToast,
      customObject,
      t,
      customObjectModel,
      id,
      clearErrors,
      getEditPayloadCO,
    ]
  );

  const handleSubmitClient = useCallback(
    async ({ shouldShowToast = true, updateTimeline = true } = {}) => {
      if (contact.access.edit) {
        const { patch, editableFields } = getEditPayloadClient();
        // TODO Wrap this block in validation after testing, since messages
        // from API are much more helpful than silent failure :) ? Or do something to
        // communicate validation errors? or API errors?
        try {
          // if anything has changed save it
          if (editableFields.length > 0) {
            await FieldService.patchObject(
              { for: 'contacts', id },
              stagedFormData.current,
              editableFields
            );
            await refetch();
            invalidate.DETAILS_RELATED_OBJECTS.RECORD(clientObjectId, id);
          }

          if (updateTimeline) {
            invalidate.TIMELINE.ALL();
          }

          // show the toast bassed on the flag not the saving
          if (shouldShowToast) {
            showToast(toastConfig.contact.success);
          }
        } catch (err) {
          const orig = snakeToCamelCaseKeys(getOriginalError(err));

          if (orig && orig.nonFieldErrors) {
            showToast({
              variant: toastVariant.FAILURE,
              message: orig.nonFieldErrors.toString(),
            });
          } else if (orig) {
            const errState = {};
            let firstField;

            Object.keys(orig).forEach((item) => {
              const f = fields.find((i) => item === snakeToCamelCase(i.name));
              if (!f) return;
              if (!firstField) {
                firstField = f;
              }
              errState[f.id] = Array.isArray(orig[item])
                ? orig[item][0]
                : orig[item];
            });

            if (orig.fields) {
              orig.fields.forEach((field, index) => {
                const found = patch?.fields?.[index];
                if (found?.id && field?.value) {
                  errState[found.id] = Array.isArray(field.value)
                    ? field.value[0]
                    : field.value;
                }
              });
            }

            setHandledErrors(errState);
          } else {
            // rest unhandled cases
            showToast({
              variant: toastVariant.FAILURE,
              message: t(
                `The Entity was not updated. Please try again or contact Kizen support.`
              ),
            });
          }
        }
      }
    },
    [
      contact,
      fields,
      id,
      refetch,
      showToast,
      toastConfig,
      clientObjectId,
      t,
      getEditPayloadClient,
    ]
  );

  const handleSubmit = useCallback(
    async (args = {}) => {
      const { editableFields } = isClient
        ? getEditPayloadClient()
        : getEditPayloadCO();

      // Things are handled differently if we modified a relationship field,
      // because we need to completely reset the timeline cache and start from scratch
      const needsTimelineReset = editableFields.some(
        (f) => f.fieldType === FIELD_TYPES.Relationship.type
      );

      if (isClient) {
        await handleSubmitClient({
          ...args,
          updateTimeline: !needsTimelineReset,
        });
      } else {
        await handleSubmitCO({ ...args, updateTimeline: !needsTimelineReset });
      }

      queryClient.invalidateQueries(ACTIVITIES.PRIMARY_ASSOCIATIONS(id));

      queryClient.invalidateQueries({
        queryKey: DETAILS_RELATED_PIPELINES.REFRESH(id),
      });

      if (needsTimelineReset) {
        queryClient.removeQueries(TIMELINE.RECORD(id));
      }
      setSaveKey(Date.now());
    },
    [
      isClient,
      handleSubmitClient,
      handleSubmitCO,
      id,
      queryClient,
      getEditPayloadCO,
      getEditPayloadClient,
    ]
  );

  const handleDelete = useCallback(async () => {
    // we need deleted to be true to leave the page if the form is dirty
    if (isPipelineObject(customObjectModel)) {
      await PipelineService.archiveCustomObjectRecord({
        recordId: id,
        modelId: objectId,
      });
    } else {
      await FieldService.deleteCustomModelRecord({
        id,
        modelId: objectId,
      });
    }

    history.push(`/custom-objects/${customObjectModel.id}`);
  }, [customObjectModel, id, objectId, history]);

  const validationFunc = useCallback(() => {
    validateFormState(t, initialFormData);
  }, [t, initialFormData, validateFormState]);

  const updateField = useCallback(
    (nextValues, { id: fieldId }, err) => {
      clearErrors();
      handleInputChange(fieldId, nextValues[fieldId], err);
      setTouchedFields({
        ...touchedFields.current,
        [fieldId]: true,
      });
      const dirtyKeys = getDirtyKeys(
        touchedFields.current,
        initialFormData,
        stagedFormData.current,
        fieldsTypes
      );
      setTouchedFormData(!isEmpty(dirtyKeys));
    },
    [
      fieldsTypes,
      handleInputChange,
      initialFormData,
      setTouchedFields,
      clearErrors,
    ]
  );

  const handleFormFieldBlur = useCallback(() => {
    clearErrors();
    const areEqual = isEqual(
      stagedFormData.current,
      fieldStateOnlyUpdatesOnBlur.current
    );

    if (!areEqual) {
      fieldStateOnlyUpdatesOnBlur.current = structuredClone(
        stagedFormData.current
      );
      setFieldBlockSharedKey(Date.now());
    }
  }, [clearErrors]);

  const loadFieldsForCategory = useCallback(
    async (categoryId) => {
      // yoo, just need to load this once
      if (loadedCategories.current[categoryId]) {
        return;
      }

      if (!loadingCategory.includes(categoryId)) {
        // add to the array of loading categories
        setLoadingCategory((prev) => [...prev, categoryId]);
        const recordParams = {
          params: { field_category: categoryId },
        };

        let reducedValues;
        let record;
        if (isClient) {
          record = await ClientService.getById(id, recordParams);

          const fieldDataForCategory = fieldData.filter(
            (f) => f.id === categoryId
          )[0];

          reducedValues = (fieldDataForCategory.fields || []).reduce(
            (collect, field) => ({
              ...collect,
              [field.id]: FieldService.getFieldValue(record, field),
            }),
            {}
          );
        } else {
          record = isPipelineObject(customObjectModel)
            ? await PipelineService.getPipelineRecord(
                {
                  id,
                  objectId,
                },
                recordParams
              )
            : await CustomObjectsService.getCustomObjectRecord(
                {
                  id,
                  objectId,
                },
                recordParams
              );

          const fields = customObjectRemoveDefaultFields(
            record.fields,
            customObjectIsDefaultLookUp
          );

          const fieldDataForCategory = fieldData.filter(
            (f) => f.id === categoryId
          )[0];
          const uiFields = getFieldsForUI(fields);

          reducedValues = (fieldDataForCategory.fields || []).reduce(
            customObjectValueReducer({
              ...customObject,
              fields: uiFields,
            }),
            {}
          );
        }
        setLoadedCategories(categoryId);
        // TODO, remove once api data is cleaned filter out any default fields from the records (They should not be there!!!)

        const stagedForm = cloneDeep(stagedFormData.current);
        const initialForm = cloneDeep(initialFormData);

        for (const [key, value] of Object.entries(reducedValues)) {
          stagedForm[key] = value;
          initialForm[key] = value;
        }

        setInitialFormData(initialForm);
        setFormData(stagedForm);
        setLoadingCategory((prev) =>
          prev.filter((item) => item !== categoryId)
        );
        handleFormFieldBlur();
      }
    },

    [
      customObjectModel,
      id,
      objectId,
      customObjectIsDefaultLookUp,
      setFormData,
      initialFormData,
      setInitialFormData,
      setLoadingCategory,
      loadingCategory,
      setLoadedCategories,
      isClient,
      customObject,
      fieldData,
      handleFormFieldBlur,
    ]
  );

  const layoutListData = useMemo(() => {
    if (isClient) {
      return clientObject?.recordLayouts ?? [];
    }

    return customObjectModel?.recordLayouts ?? [];
  }, [customObjectModel, clientObject, isClient]);

  const baseLayoutDataObject = useMemo(() => {
    if (!selectedLayout?.value || !layoutListData?.length) {
      return;
    }

    return layoutListData.find((item) => item.id === selectedLayout.value);
  }, [layoutListData, selectedLayout]);

  const layoutDataObject = useMemo(() => {
    if (!baseLayoutDataObject) {
      return baseLayoutDataObject;
    }

    return {
      ...baseLayoutDataObject,
      config: baseLayoutDataObject.config.map((row) => {
        return {
          ...row,
          columns: row.columns.map((column) => {
            return {
              ...column,
              items: column.items.filter((item) => !item.hidden),
            };
          }),
        };
      }),
    };
  }, [baseLayoutDataObject]);

  const blockIdsInOrder = useMemo(() => {
    return (
      layoutDataObject?.config?.reduce((acc, row) => {
        return [
          ...acc,
          ...row.columns.reduce((acc, column) => {
            return [...acc, ...column.items.map((item) => item.id)];
          }, []),
        ];
      }, []) ?? []
    );
  }, [layoutDataObject]);

  const fieldCategoryIds = useMemo(() => {
    return fieldData.reduce((acc, curr) => {
      return [...acc, curr.id];
    }, []);
  }, [fieldData]);

  const fieldCategoryNames = useMemo(() => {
    return fieldData.reduce((acc, curr) => {
      return { ...acc, [curr.id]: curr.name };
    }, {});
  }, [fieldData]);

  const blockIdToFieldCategories = useMemo(() => {
    return (
      layoutDataObject?.config?.reduce((acc, row) => {
        return {
          ...acc,
          ...row.columns.reduce((acc, column) => {
            return {
              ...acc,
              ...column.items.reduce((acc, item) => {
                if (item.type === BLOCK_TYPES.FIELDS) {
                  return {
                    ...acc,
                    [item.id]: getItemCategories(item, fieldCategoryIds),
                  };
                }
                return acc;
              }, {}),
            };
          }, {}),
        };
      }, {}) ?? {}
    );
  }, [layoutDataObject, fieldCategoryIds]);

  const fieldCategoriesToBlockIds = useMemo(() => {
    return Object.keys(blockIdToFieldCategories).reduce((acc, blockId) => {
      const res = { ...acc };
      const categories = blockIdToFieldCategories[blockId];
      categories.forEach((category) => {
        if (!res[category]) {
          res[category] = [];
        }
        res[category].push(blockId);
      });

      return res;
    }, {});
  }, [blockIdToFieldCategories]);

  const fieldsVisibility = useMemo(() => {
    const visibleCategories = new Set(
      Object.keys(currentFieldBlockCategories).map(
        (key) => currentFieldBlockCategories[key].value
      )
    );
    const fieldsMap = fields.reduce((acc, curr) => {
      const blockIds = fieldCategoriesToBlockIds[curr.category] || [];
      const mapping = {
        name: curr.name,
        visible: visibleCategories.has(curr.category),
        category: curr.category,
        blockIds: blockIds.sort(
          (a, b) => blockIdsInOrder.indexOf(a) - blockIdsInOrder.indexOf(b)
        ),
      };

      return {
        ...acc,
        [curr.id]: mapping,
        [curr.name]: mapping,
      };
    }, {});

    return fieldsMap;
  }, [
    currentFieldBlockCategories,
    fields,
    fieldCategoriesToBlockIds,
    blockIdsInOrder,
  ]);

  const currentErrorBlockRequirements = useMemo(() => {
    return Object.keys(handledErrors ?? {})
      .sort()
      .map((key) => {
        return fieldsVisibility[key];
      })?.[0];
  }, [fieldsVisibility, handledErrors]);

  const [blockIdWithError, setBlockIdWithError] = useState();

  const handleNewErrorRequirement = useCallback(
    (errorBlockRequirements) => {
      if (errorBlockRequirements) {
        const firstBlockCandidates = errorBlockRequirements.blockIds;
        let selectedBlock;
        let needsVisibilityChange = false;
        for (const candidate of firstBlockCandidates) {
          if (
            currentFieldBlockCategories[candidate]?.value ===
            errorBlockRequirements.category
          ) {
            selectedBlock = candidate;
            break;
          }
        }

        if (!selectedBlock) {
          needsVisibilityChange = true;
          selectedBlock = firstBlockCandidates[0];
        }

        if (!selectedBlock) {
          // This is a state that shouldn't be possible because in order to edit a field and make it
          // invalid, it should need to be on a block somewhere. In case something goes wrong with the block selection,
          // show a toast rather than just ignore the user's action.
          showToast({
            message: t(
              'Object could not be saved, an unexpected error occurred.'
            ),
            variant: toastVariant.FAILURE,
          });
        } else {
          if (needsVisibilityChange) {
            setCurrentFieldBlockCategories((prev) => {
              return {
                ...prev,
                [selectedBlock]: {
                  value: errorBlockRequirements.category,
                  label: fieldCategoryNames[errorBlockRequirements.category],
                },
              };
            });
          }
          setBlockIdWithError(selectedBlock);
        }
      }
    },
    [fieldCategoryNames, currentFieldBlockCategories, t, showToast]
  );

  const [previousErrorBlockRequirements, setPreviousErrorBlockRequirements] =
    useState(currentErrorBlockRequirements);
  if (!isEqual(previousErrorBlockRequirements, currentErrorBlockRequirements)) {
    setPreviousErrorBlockRequirements(currentErrorBlockRequirements);
    handleNewErrorRequirement(currentErrorBlockRequirements);
  }

  const handleSetCategory = useCallback(
    (newCategory, key) => {
      clearErrors();
      setCurrentFieldBlockCategories((prev) => ({
        ...prev,
        [key]: newCategory,
      }));
    },
    [clearErrors]
  );

  const allTimelineBlocks = useMemo(() => {
    return (
      layoutDataObject?.config?.reduce((acc, row) => {
        return {
          ...acc,
          ...row.columns.reduce((acc, column) => {
            return {
              ...acc,
              ...column.items.reduce((acc, item) => {
                if (item.type === BLOCK_TYPES.TIMELINE) {
                  const {
                    objectsFilter,
                    eventsFilter,
                    excludeEventsFilter,
                    includeRelatedFilter,
                  } = getPrimaryFilters(
                    event_id,
                    created,
                    item.metadata,
                    detailPageConfig,
                    item.id
                  );

                  const aggregatedFilters = getAggregatedFilters({
                    metadata: item.metadata,
                    restFilters: {},
                    objectsFilter,
                    eventsFilter,
                    noRelatedId:
                      item.metadata?.includeRelated === false
                        ? aggregateObjectId
                        : undefined,
                    excludeEventsFilter,
                    includeRelatedFilter,
                  });

                  return {
                    ...acc,
                    [item.id]: aggregatedFilters,
                  };
                }
                return acc;
              }, {}),
            };
          }, {}),
        };
      }, {}) ?? {}
    );
  }, [
    layoutDataObject,
    event_id,
    created,
    aggregateObjectId,
    detailPageConfig,
  ]);

  const groupedTimelineQueryEnabled =
    Boolean(event_id) && Boolean(created) && Boolean(layoutDataObject);

  const timelineResults = useQueries(
    Object.keys(allTimelineBlocks).map((key) => {
      const { filtersForApi } = allTimelineBlocks[key];
      return {
        queryKey: TIMELINE.PROBE_BLOCK(
          event_id,
          created,
          key,
          JSON.stringify(filtersForApi)
        ),
        queryFn: async () => {
          const result = await TimelineService.getPagedTimelineRecords(id, {
            size: 1,
            ...filtersForApi,
            event_id,
            created,
          });

          return {
            result,
            blockId: key,
          };
        },
        enabled: groupedTimelineQueryEnabled,
      };
    })
  );

  const timelineResultsLoading = timelineResults.some(
    (result) => result.isLoading
  );

  const timelineBlocksWithEvent = useMemo(() => {
    return timelineResults?.reduce((acc, curr) => {
      const eventIdsReturned =
        curr.data?.result?.events?.map((e) => e.id) ?? [];

      if (eventIdsReturned.includes(event_id)) {
        return {
          ...acc,
          [curr.data.blockId]: true,
        };
      }

      return acc;
    }, {});
  }, [timelineResults, event_id]);

  const firstTimelineBlockWithEvent = blockIdsInOrder.find(
    (blockId) => timelineBlocksWithEvent[blockId]
  );

  const commentNotVisible =
    groupedTimelineQueryEnabled &&
    !timelineResultsLoading &&
    !firstTimelineBlockWithEvent;

  const {
    data: eventWithoutFilters,
    isLoading: eventWithoutFiltersLoading,
    refetch: refetchModalEvent,
  } = useQuery({
    queryKey: TIMELINE.PROBE_BLOCK(event_id, created, 'unfiltered'),
    queryFn: async () => {
      const result = await TimelineService.getPagedTimelineRecords(id, {
        size: 1,
        event_id,
        created,
      });

      return result;
    },
    enabled: commentNotVisible,
  });

  const eventForModal = useMemo(() => {
    return eventWithoutFilters?.events?.[0];
  }, [eventWithoutFilters]);

  const [commentModalProps, , commentModal] = useModal();

  const closeModal = useCallback(() => {
    persistentParams.current = undefined;
    commentModal.hide();
  }, [commentModal]);

  const [hasShownCommentDetail, setHasShownCommentDetail] = useState(false);

  if (
    !hasShownCommentDetail &&
    eventWithoutFilters?.events?.[0]?.id === event_id &&
    commentNotVisible
  ) {
    setHasShownCommentDetail(true);
    commentModal.show();
  }

  if (
    !hasShownCommentDetail &&
    !eventWithoutFiltersLoading &&
    eventWithoutFilters?.events?.length === 0
  ) {
    setHasShownCommentDetail(true);
    showToast({
      message: t(
        'You do not have permission to view the timeline event you were mentioned on'
      ),
      variant: toastVariant.FAILURE,
    });
  }

  if (
    !hasShownCommentDetail &&
    !eventWithoutFiltersLoading &&
    eventWithoutFilters?.events?.length === 1 &&
    eventWithoutFilters?.events?.[0]?.id !== event_id
  ) {
    setHasShownCommentDetail(true);
    showToast({
      message: t('The timeline event you were mentioned on could not be found'),
      variant: toastVariant.FAILURE,
    });
  }

  const getComponent = useCallback(
    ({ type, metadata, id: key, displayName }, width, relatedObjectById) => {
      switch (type) {
        case BLOCK_TYPES.FIELDS: {
          const componentErrors =
            key === blockIdWithError ? handledErrors : undefined;

          return (
            <FieldsBlock
              blockId={key}
              id={id}
              fieldData={fieldData}
              customObject={customObject}
              customObjectModel={customObjectModel}
              clientObject={clientObject}
              fieldState={fieldStateOnlyUpdatesOnBlur.current}
              initialFieldState={initialFormData}
              onChangeFieldState={updateField}
              validationProps={validationProps}
              recordStatus={recordStatus}
              validationFunc={validationFunc}
              isClient={isClient}
              contact={contact}
              metadata={metadata}
              loadFieldsForCategory={loadFieldsForCategory}
              loadingCategory={loadingCategory}
              setChosenFieldCategories={setChosenFieldCategories}
              handleFieldBlur={handleFormFieldBlur}
              category={currentFieldBlockCategories[key]}
              setCategory={(newCategory) => handleSetCategory(newCategory, key)}
              errors={componentErrors}
              blockType={type}
            />
          );
        }
        case BLOCK_TYPES.LEAD_SOURCES: {
          return (
            <LeadInfo
              leadSourceInfo={leadSourceInfo}
              id={id}
              refetch={refetch}
              key={key}
              displayName={displayName}
              contact={contact}
              customObject={customObject}
              blockType={type}
            />
          );
        }
        case BLOCK_TYPES.TIMELINE: {
          const eventIdValue =
            firstTimelineBlockWithEvent === key ? event_id : '';
          const createdValue =
            firstTimelineBlockWithEvent === key ? created : '';

          return (
            <Timeline
              id={id}
              customObjectId={objectId}
              isCustomObjectPage={!isClient}
              isClient={isClient}
              clientObject={clientObject}
              key={`${key}-${eventIdValue}-${createdValue}`}
              displayName={displayName}
              mobileOverride={width === COLUMN_SIZE.THIRD_WIDTH}
              blockId={key}
              metadata={metadata}
              blockType={type}
              eventId={eventIdValue}
              created={createdValue}
              isLoading={timelineResultsLoading}
              queryKeyAddition={valuesKeyPortion}
              resetOnQueryChange
            />
          );
        }
        case BLOCK_TYPES.TEAM_AND_ACTIVITIES: {
          return (
            <TeamActivities
              refetch={refetch}
              isClient={isClient}
              entity={isClient ? contact : customObject}
              customObject={isClient ? clientObject : customObjectModel}
              fieldState={stagedFormData.current}
              fieldStateDirty={touchedFormData}
              key={key}
              handleSubmit={handleSubmit}
              blockType={type}
              hasScheduledActivityPermission={hasScheduledActivityPermission}
              touchedFormData={touchedFormData}
              saveFormData={handleSubmit}
              resetAllFields={resetAllFields}
            />
          );
        }
        case BLOCK_TYPES.ACTION: {
          return (
            <Action
              isClient={isClient}
              id={id}
              customObjectId={objectId}
              refetch={refetch}
              customObject={customObject}
              customObjectModel={customObjectModel}
              clientObject={clientObject}
              permissions={actionBlockPermissions}
              metadata={metadata}
              key={key}
              hasScheduledActivityPermission={hasScheduledActivityPermission}
              hasSingleSendMessagePermission={canSingleSendMessage}
              hasEditPermission={canEdit}
              contact={contact}
              blockType={type}
            />
          );
        }
        case BLOCK_TYPES.RELATED_OBJECT_FIELDS: {
          const missingAllObjects = metadata?.objects?.every(
            (object) => !relatedObjectById?.[object.originalId]
          );

          if (missingAllObjects) {
            return null;
          }

          return (
            <RelatedObjectsBlock
              isClient={isClient}
              id={id}
              customObjectId={objectId}
              refetch={refetch}
              customObject={customObject}
              customObjectModel={customObjectModel}
              clientObject={clientObject}
              metadata={metadata}
              key={key}
              displayName={displayName}
              blockType={type}
            />
          );
        }
        case BLOCK_TYPES.RELATED_PIPELINES: {
          return (
            <RelatedPipelinesBlock
              id={id}
              customObjectId={objectId}
              refetch={refetch}
              handleUpdateRecord={handleUpdateRecord}
              customObject={customObject}
              customObjectModel={customObjectModel}
              clientObject={clientObject}
              fieldState={stagedFormData.current}
              touchedFormData={touchedFormData}
              saveFormData={handleSubmit}
              resetAllFields={resetAllFields}
              onChangeFieldState={updateField}
              canEdit={canEdit}
              key={key}
              displayName={displayName}
              blockType={type}
              metadata={metadata}
              handleFieldBlur={handleFormFieldBlur}
            />
          );
        }
        case 'custom_content': {
          return (
            <CustomContentBlock
              id={id}
              isClient={isClient}
              javascriptActions={
                isClient
                  ? clientObject.browserJsActions
                  : customObjectModel.browserJsActions
              }
              fields={fieldData.flatMap((cat) => cat.fields)}
              objectId={isClient ? clientObjectId : objectId}
              objectName={isClient ? clientObject.name : customObjectModel.name}
              objectType={isClient ? 'contact' : customObjectModel.objectType}
              blockJson={metadata?.blockJson}
            />
          );
        }
        default: {
          return null;
        }
      }
    },
    [
      id,
      refetch,
      isClient,
      customObject,
      customObjectModel,
      objectId,
      touchedFormData,
      clientObject,
      leadSourceInfo,
      fieldData,
      updateField,
      validationProps,
      recordStatus,
      validationFunc,
      contact,
      loadFieldsForCategory,
      loadingCategory,
      hasScheduledActivityPermission,
      handleUpdateRecord,
      handleSubmit,
      canEdit,
      setChosenFieldCategories,
      actionBlockPermissions,
      canSingleSendMessage,
      handleFormFieldBlur,
      currentFieldBlockCategories,
      handledErrors,
      blockIdWithError,
      handleSetCategory,
      event_id,
      created,
      firstTimelineBlockWithEvent,
      timelineResultsLoading,
      clientObjectId,
      valuesKeyPortion,
      initialFormData,
      resetAllFields,
    ]
  );

  const layoutOptions = useMemo(() => {
    return layoutListData?.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [layoutListData]);

  if (
    !isFetchingFilterConfig &&
    !selectedLayout &&
    !layoutsListLoading &&
    !overrideLayout
  ) {
    const firstOption = layoutOptions?.[0];

    if (isClient) {
      if (clientPageConfig.selectedLayout) {
        const selected = layoutOptions.find(
          (item) => item.value === clientPageConfig.selectedLayout
        );
        if (selected) {
          setSelectedLayout(selected);
        } else if (firstOption) {
          setSelectedLayout(firstOption);
        }
      } else if (firstOption) {
        setSelectedLayout(firstOption);
      }
    } else {
      if (detailPageConfig.selectedLayout) {
        const selected = layoutOptions.find(
          (item) => item.value === detailPageConfig.selectedLayout
        );
        if (selected) {
          setSelectedLayout(selected);
        } else if (firstOption) {
          setSelectedLayout(firstOption);
        }
      } else if (firstOption) {
        setSelectedLayout(firstOption);
      }
    }
  }

  const handleChangeSelectedLayout = useCallback(
    (layout) => {
      setSelectedLayout(layout);
      if (!isClient) {
        dispatch(
          updateCustomObjectPageConfig({
            customObjectId: objectId,
            detailPageConfig: {
              ...detailPageConfig,
              selectedLayout: layout.value,
            },
          })
        );
      } else {
        dispatch(
          updateClientPageConfig({
            selectedLayout: layout.value,
          })
        );
        dispatch(
          getContacts({
            updatePageConfig: true,
            updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.DETAIL_PAGE,
          })
        );
      }
    },
    [dispatch, detailPageConfig, objectId, isClient]
  );

  if (!layoutsListLoading && !isFetchingFilterConfig && overrideLayout) {
    const foundLayout = layoutOptions.find(
      (item) => item.value === overrideLayout
    );

    if (foundLayout) {
      handleChangeSelectedLayout(foundLayout);
    }

    history.replace(history.location.pathname.split('?layout=')[0]);
  }

  // first we need to load the contact
  useEffect(() => {
    if (isClient) {
      dispatch(
        buildClientPage({
          history,
          page: {},
        })
      );
    }
  }, [dispatch, isClient, history]);

  // then we can build the page
  useEffect(() => {
    if (aggregateObjectId) {
      dispatch(
        buildCustomObjectPage({
          customObjectId: aggregateObjectId,
        })
      );
    }
  }, [dispatch, aggregateObjectId]);

  const layoutPending = !layoutDataObject && layoutListData?.length > 0;

  const canShowAutomations = useMemo(() => {
    // Only if it's explicitly set to false do we not show the automations
    return layoutDataObject?.tabs?.automations !== false;
  }, [layoutDataObject]);

  const canShowMessages = useMemo(() => {
    return isClient && layoutDataObject?.tabs?.messages !== false;
  }, [layoutDataObject, isClient]);

  const relatedObjectIds = useMemo(() => {
    const objectIdSet =
      layoutDataObject?.config.reduce((acc, rows) => {
        rows.columns.forEach((column) => {
          column.items.forEach((item) => {
            if (item?.type === BLOCK_TYPES.RELATED_OBJECT_FIELDS) {
              item.metadata?.objects?.forEach(({ originalId }) => {
                acc.add(originalId);
              });
            }
          });
        });
        return acc;
      }, new Set()) ?? [];

    return [...objectIdSet];
  }, [layoutDataObject]);

  const objectQueries = useQueries(
    relatedObjectIds.map((objectId) => {
      return {
        queryKey: CUSTOM_OBJECTS.DETAILS(objectId),
        queryFn: () =>
          CustomObjectsService.getCustomObjectDetails(objectId, {
            skipErrorBoundary: true,
          }),
        enabled: Boolean(objectId),
      };
    })
  );

  const relatedObjectById = useMemo(() => {
    return objectQueries.reduce((acc, query) => {
      if (query.data) {
        return {
          ...acc,
          [query.data.id]: query.data,
        };
      }

      return acc;
    }, {});
  }, [objectQueries]);

  const layout = useMemo(() => {
    if (!layoutDataObject?.config) {
      return [];
    }

    const res = layoutDataObject.config
      .map((row) => {
        if (!row.columns.some((column) => column.items.length !== 0)) {
          return null;
        }
        return {
          id: row.id,
          content: row.columns
            .map((column) => {
              return (
                <Column key={column.id} gap={20}>
                  {column.items.map((item) => {
                    if (item.type === 'timeline' && !canViewTimeline) {
                      return null;
                    }

                    return getComponent(item, column.width, relatedObjectById);
                  })}
                </Column>
              );
            })
            .filter(Boolean),
          layout: row.columns.map((c) => c.width),
        };
      })
      .filter(Boolean);

    return res;
  }, [layoutDataObject, getComponent, canViewTimeline, relatedObjectById]);

  const customObjectLoading =
    ((!customObject && !noViewPermissions) ||
      (customObject && customObject.id !== id)) &&
    !isClient;

  const clientLoading =
    (!contact || !clientObject) && !noViewPermissions && isClient;

  const waitingForLayout = layoutsListLoading || layoutPending;

  const isLoading = customObjectLoading || clientLoading || waitingForLayout;
  useEffect(() => {
    if (!isLoading) {
      // Values changing means the underlying contact record changed,
      // meaning the form was by definition reset (I think)
      setFormData(values || null, true);
      //break reference between values and initial values
      setInitialFormData(cloneDeep(values) || null);
      setTouchedFormData(false);
      setTouchedFields(EMPTY_OBJECT);
    }
  }, [setFormData, setTouchedFields, values, isLoading]);

  const handleStartAutomation = useCallback(
    async (automationId) => {
      try {
        await Automation2Service.start({
          automationId,
          recordId: id,
        });

        showToast(toastConfig.automation.success);
      } catch (error) {
        showToast(toastConfig.automation.error);
      }
    },
    [id, showToast, toastConfig.automation]
  );

  const logActivityActions = {
    update: async (dirtyFields) => {
      if (dirtyFields) {
        await refetch();
      }
    },
    save: async () => {
      await handleSubmit({
        displayToast: true,
      });
    },
  };

  const { data: activityList } = useQuery(
    CUSTOM_RECORDS.LOGGABLE_ACTIVITY_LIST(aggregateObjectId),
    () => {
      return LoggableActivityService.getActivityFullList({
        customObjectId: aggregateObjectId,
        ordering: 'name',
        detail: 'light',
      });
    },
    {
      enabled: Boolean(aggregateObjectId),
    }
  );

  const objectRoutes = useMemo(() => {
    if (isClient) {
      return clientRoutes;
    }
    return getRoutes(t);
  }, [t, isClient]);

  const routes = useMemo(() => {
    const result = {};

    if (isClient) {
      result.profile = objectRoutes.profile;
    } else {
      result.record = objectRoutes.record;
    }

    if (canShowAutomations) {
      result.automations = objectRoutes.automations;
    }

    if (canShowMessages) {
      result.messages = objectRoutes.messages;
    }

    return result;
  }, [objectRoutes, canShowAutomations, canShowMessages, isClient]);

  const [, , performActionModal] = useModal();
  const [userActionChoice, setUserActionChoice] = useState(null);

  const onPerformActionSelected = useCallback(
    (data) => {
      setUserActionChoice(data);
      performActionModal.hide();
    },
    [performActionModal]
  );

  const onPerformActionHide = useCallback(() => {
    setUserActionChoice(null);
    performActionModal.hide();
  }, [performActionModal]);

  const step = useMemo(
    () =>
      performActionOptions.find((el) => userActionChoice?.value === el.value),
    [userActionChoice]
  );

  const SelectedActionStep = step?.component || null;

  const { reasonsDialogData, reasonLostField } = useMemo(() => {
    const reasonLostField = fields.find(isReasonLostField);

    const lostStage = customObjectModel?.pipeline?.stages?.find(
      ({ id, status }) =>
        id === customObject.stage && status === STAGE_STATUSES.lost
    );

    return {
      reasonsDialogData: lostStage
        ? [
            {
              record: customObject,
              stageId: lostStage.id,
            },
          ]
        : [],
      reasonLostField,
    };
  }, [fields, customObject, customObjectModel]);

  const { reasonsDisqualifiedDialogData, reasonDisqualifiedField } =
    useMemo(() => {
      const reasonDisqualifiedField = fields.find(isReasonDisqualifiedField);

      const disqualifiedStage = customObjectModel?.pipeline?.stages?.find(
        ({ id, status }) =>
          id === customObject.stage && status === STAGE_STATUSES.disqualified
      );

      return {
        reasonsDisqualifiedDialogData: disqualifiedStage
          ? [
              {
                record: customObject,
                stageId: disqualifiedStage.id,
              },
            ]
          : [],
        reasonDisqualifiedField,
      };
    }, [fields, customObject, customObjectModel]);

  if (isLoading || overrideLayout) {
    return <Loader loading />;
  }

  return (
    <RecordLayoutLogActivityContext
      clientObject={clientObject}
      contact={contact}
      customObject={customObject}
      customObjectId={objectId}
      customObjectModel={customObjectModel}
      entityId={id}
      refetch={refetch}
      resetAllFields={resetAllFields}
      saveFormData={handleSubmit}
      touchedFormData={touchedFormData}
    >
      <RecordLayoutStartAutomationContext entityId={id}>
        {customObjectModel ? (
          <CustomObjectControlBar
            customObject={customObject}
            customObjectModel={customObjectModel}
            params={params}
            hasUnsavedChanges={false}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            onStartAutomation={handleStartAutomation}
            logActivityActions={logActivityActions}
            fieldState={stagedFormData.current}
            activityList={activityList}
            defaultOnActivities={Boolean(
              customObjectModel?.defaultOnActivities
            )}
            routes={routes}
            layoutOptions={isMobile ? [] : layoutOptions}
            selectedLayout={selectedLayout}
            setSelectedLayout={handleChangeSelectedLayout}
            hideActions={!layoutDataObject}
            canStartAutomations={canStart}
          />
        ) : isClient ? (
          <ContactControlBar
            contact={contact}
            routes={routes}
            params={params}
            hasUnsavedChanges={false}
            onSubmit={handleSubmit}
            onStartAutomation={handleStartAutomation}
            onChange={setUserActionChoice}
            openPerformModal={() => performActionModal.show()}
            clientObject={clientObject}
            logActivityActions={logActivityActions}
            fieldState={stagedFormData.current}
            activityList={activityList}
            layoutOptions={isMobile ? [] : layoutOptions}
            selectedLayout={selectedLayout}
            setSelectedLayout={handleChangeSelectedLayout}
            hideActions={!layoutDataObject}
            canStartAutomations={canStart}
          />
        ) : (
          <Spacer mode="horizontal" size={20} />
        )}
        {(canShowAutomations || canShowMessages) && layoutDataObject ? (
          <StickyNavWrapper scrolled={false}>
            <StickyNavMobileWrapper routes={routes}>
              <StyledSubNav routes={routes} params={params} />
            </StickyNavMobileWrapper>
          </StickyNavWrapper>
        ) : null}
        {layoutDataObject ? (
          <ConfirmNavigationModal
            when={touchedFormData}
            onPreNavigation={async () => {
              await refetch();
              resetValidationState();
            }}
          />
        ) : null}
        {performActionModal.showing && (
          <PerformActionModal
            isMobile={isMobile}
            onSelect={onPerformActionSelected}
            show={true}
            onHide={onPerformActionHide}
            contact={contact}
          />
        )}
        {SelectedActionStep && (
          <SelectedActionStep
            contact={contact}
            onHide={() => {
              setUserActionChoice(null);
            }}
            isMobile={isMobile}
          />
        )}
        {addReasonLostModalProps.show &&
        isPipelineObject(customObjectModel) &&
        reasonsDialogData.length &&
        !isClient ? (
          <AddReasonLostModal
            model={customObjectModel}
            reasonLostField={reasonLostField}
            reasonsDialogData={reasonsDialogData}
            {...addReasonLostModalProps}
          />
        ) : null}
        {addReasonDisqualifiedModalProps.show &&
        isPipelineObject(customObjectModel) &&
        reasonsDisqualifiedDialogData.length &&
        !isClient ? (
          <AddReasonDisqualifiedModal
            model={customObjectModel}
            reasonDisqualifiedField={reasonDisqualifiedField}
            reasonsDialogData={reasonsDisqualifiedDialogData}
            {...addReasonDisqualifiedModalProps}
          />
        ) : null}
        {layoutDataObject ? (
          <Wrapper data-qa="details" data-qa-refetching={isRefetching}>
            <Grid config={layout} gap={20} />
            <Spacer size={30} mode="horizontal" />
          </Wrapper>
        ) : (
          <Wrapper>
            <div className="text-center">
              <Typography>
                {t(
                  'You have no accessible page layouts. Consult your Kizen Administrator to grant you access.'
                )}
              </Typography>
            </div>
          </Wrapper>
        )}
        <ScrollTopButton />
        <TimelineEventModal
          modalProps={commentModalProps}
          event={eventForModal}
          refetch={refetchModalEvent}
          onClose={closeModal}
        />
      </RecordLayoutStartAutomationContext>
    </RecordLayoutLogActivityContext>
  );
};

const Page = (props) => {
  const params = useParams();
  const { id, objectId } = params;

  return (
    <ForceFieldRevalidationProvider>
      <RecordDetailsPage key={`${objectId}-${id}`} {...props} params={params} />
    </ForceFieldRevalidationProvider>
  );
};

export default Page;
