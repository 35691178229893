export const KEY_CODES = {
  enter: {
    keyCode: 13,
    key: 'Enter',
    code: 'Enter',
    type: 'enter',
  },
  tab: {
    keyCode: 9,
    key: 'Tab',
    code: 'Tab',
    type: 'tab',
  },
  arrowUp: {
    keyCode: 38,
    key: 'ArrowUp',
    code: 'ArrowUp',
    type: 'arrowUp',
  },
  arrowRight: {
    keyCode: 39,
    key: 'ArrowRight',
    code: 'ArrowRight',
    type: 'arrowRight',
  },
  arrowDown: {
    keyCode: 40,
    key: 'ArrowDown',
    code: 'ArrowDown',
    type: 'arrowDown',
  },
  arrowLeft: {
    keyCode: 37,
    key: 'ArrowLeft',
    code: 'ArrowLeft',
    type: 'arrowLeft',
  },
  esc: {
    keyCode: 27,
    key: 'Escape',
    code: 'Escape',
    type: 'esc',
  },
  space: {
    keyCode: 32,
    key: ' ',
    code: 'Space',
    type: 'space',
  },
  letter: {
    key: /^\p{L}$/u,
    code: /^Key\p{L}$/u,
    type: 'letter',
  },
  punctuation: {
    key: /^\p{P}$/u,
    type: 'punctuation',
  },
  digit: {
    key: /^\p{Nd}$/u,
    code: /^Digit\p{Nd}$/u,
    type: 'digit',
  },
};
