import { isCustomFilter } from 'ts-filters/legacy';
import { useCallback, useState } from 'react';
import { BROADCAST_FORMDATA } from '../components/ScheduleBroadcastModal/constants';
import { EVENT_TYPES } from '../constants';

const getStepsState = (data) => {
  const date = Boolean(data[BROADCAST_FORMDATA.date]);
  const first = Boolean(data[BROADCAST_FORMDATA.type]);
  let second = false;

  if (first && data[BROADCAST_FORMDATA.action]) {
    if (data[BROADCAST_FORMDATA.type] === EVENT_TYPES.automation.type) {
      second = data[BROADCAST_FORMDATA.action].automations?.length > 0;
    } else {
      second = Boolean(data[BROADCAST_FORMDATA.action].id);
    }
  }

  const third = Boolean(
    second &&
      (isCustomFilter(data[BROADCAST_FORMDATA.filterType])
        ? true // always true for custom filters as we validate them when hitting submit
        : data[BROADCAST_FORMDATA.filterGroupsIds]?.length)
  );

  return { date, first, second, third };
};

const checkToUpdate = (previous, current) =>
  Object.entries(previous).some(([k, v]) => v !== current[k]);

export const useStepsState = (initialData) => {
  const [readySteps, setReadySteps] = useState(
    getStepsState(initialData || {})
  );

  const onUpdateStep = useCallback(
    (data) =>
      setReadySteps((prev) => {
        const currentState = getStepsState(data);
        return checkToUpdate(prev, currentState) ? currentState : prev;
      }),
    []
  );

  return { readySteps, onUpdateStep };
};
