import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import { Menu } from '../Dropdown';

export const StyledDateMenuWrapper = styled(Menu)`
  z-index: ${layers.content(
    10500,
    20
  )}; // Modal in modal on edit activity has z-index: 10519 so higher value is required so that date picker is not hidden by modal. 10520
  margin-top: 4px;
  min-width: unset;
  border-style: solid;
  overflow: hidden;
  width: 271px; // according to design
  border-radius: 4px;
  &.no-margin {
    margin: 0;
  }

  .rs-calendar {
    min-height: calc(270px - 2px); // according to design
  }
`;
