import { Icon } from '@kizen/kds/Icon';
import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';
import { ResizableSection } from '__components/Wizards/CustomObject/steps/CustomLayout/dialogs/common';
import Input from '__components/Kizen/Input';
import { useTranslation } from 'react-i18next';
import { Tooltip, useTooltip } from '@kizen/kds/Tooltip';
import { blockLabels } from '../Builder/util';

interface BlockNamesWizardProps {
  index?: number;
  type: string;
  internalName?: string;
  setInternalName?: (name: string) => void;
  displayName?: string;
  setDisplayName?: (name: string) => void;
  internalNamePlaceholder: string;
  isActive?: boolean;
  hasChosenType?: boolean;
}

const hideDisplayNameTypes = [
  'fields',
  'team_and_activities',
  'action_block',
  'custom_content',
];

export const BlockNamesWizard = (props: BlockNamesWizardProps) => {
  const {
    type,
    internalName,
    setInternalName,
    displayName,
    setDisplayName,
    internalNamePlaceholder,
    isActive,
    hasChosenType,
  } = props;
  const { t } = useTranslation();

  const displayNamePlaceholder = blockLabels[type]?.(t) ?? '';

  const showDisplayName = hasChosenType && !hideDisplayNameTypes.includes(type);

  const { triggerProps, tooltipProps, targetProps, showTooltip } =
    useTooltip(true);

  return (
    <ResizableSection header={t('Block Names')} {...props}>
      {isActive ? (
        <div className="kds grid grid-cols-2 gap-spacer-20 text-font-primary">
          <div>
            <Typography variant="label">{t('Internal Block Name')}</Typography>
            <Spacer mode="horizontal" size={15} />
            <Input
              type="text"
              placeholder={internalNamePlaceholder || t('Internal Block Name')}
              value={internalName ?? ''}
              onChange={setInternalName}
              data-qa="internal-block-name"
            />
          </div>
          {showDisplayName ? (
            <div>
              <div className="kds flex items-center gap-x-spacer-10">
                <Typography variant="label">
                  {t('Record Display Name')}
                </Typography>
                <span {...triggerProps} {...targetProps} className="h-[1em]">
                  <Icon icon="message-info-circle" size="sm" />
                  {showTooltip ? (
                    <Tooltip
                      position="right"
                      text={t(
                        'This is the text that appears in the top left of the block that defines it to your users.'
                      )}
                      modalCompatability
                      {...tooltipProps}
                    />
                  ) : null}
                </span>
              </div>
              <Spacer mode="horizontal" size={15} />
              <Input
                type="text"
                placeholder={displayNamePlaceholder}
                value={displayName}
                onChange={setDisplayName}
                data-qa="record-display-name"
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </ResizableSection>
  );
};
