import { Extension } from '@tiptap/core';

export const Color = Extension.create({
  name: 'color',

  defaultOptions: {
    types: ['textStyle'],
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            parseHTML: (element) => element.style.color,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }

              return {
                style: `color: ${attributes.color}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setColor:
        (color) =>
        ({ chain, tr }) => {
          const { attrs } =
            tr.storedMarks?.find((m) => m.type.name === 'textStyle') ?? {};
          return chain()
            .setMark('textStyle', { ...attrs, color })
            .run();
        },
      unsetColor:
        () =>
        ({ chain, tr }) => {
          const { attrs } =
            tr.storedMarks?.find((m) => m.type.name === 'textStyle') ?? {};
          return chain()
            .setMark('textStyle', { ...attrs, color: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
