export const REDUCER_TYPES = {
  setBuilderType: 'set-builder-type',
  setStep: 'set-step',
  setSubType: 'set-sub-type',
  setSimpleData: 'set-simple-data',
};

export const SIMPLE_SUB_TYPES = {
  setTargetField: 'set-target-field',
  addElement: 'add-element',
  deleteElement: 'delete-element',
  updateElement: 'update-element',
  setOperator: 'set-operator',
  addNumerator: 'add-numerator',
  deleteNumerator: 'delete-numerator',
  updateNumerator: 'update-numerator',
  addDenominator: 'add-denominator',
  deleteDenominator: 'delete-denominator',
  updateDenominator: 'update-denominator',
};

export const BUILDER_TYPES = {
  simple: 'simple_builder',
  spreadsheetStyle: 'spreadsheet-style', // [TODO] place holder not supported by the API atm
};

export const ELEMENT_TYPES = {
  constant: 'constant',
  variable: 'field',
};

export const SIMPLE_BUILDER_TYPES = {
  addSubtract: 'addition',
  multiplicationDivision: 'multiplication',
};

export const SIMPLE_BUILDER_OPERATION_TYPES = {
  add: { label: '+', value: 'regular' },
  sub: { label: '-', value: 'negative' },
  divide: { label: '/', value: 'inverse' },
};

export const MATH_OPERATOR_STEP = {
  chooseBuilder: 'choose-builder',
  chooseSimpleType: 'choose-simple-type',
  buildAddSubtract: 'build-add-subtract',
  buildMultiplacationDivision: 'build-multiplication-division',
  chooseSpreadsheetType: 'choose-spreadsheet-type',
};
