import React from 'react';
import * as PropTypes from 'prop-types';
import '@reach/slider/styles.css';
import {
  StyledSliderWrapper,
  StyledSliderInput as SliderInput,
  StyledSliderHandle as SliderHandle,
  StyledSliderTrack as SliderTrack,
  StyledSliderTrackHighlight as SliderTrackHighlight,
  StyledSliderMarker as SliderMarker,
  StyledLabel,
  StyledHandleTooltip,
} from './styles';
import { INPUT_TYPES } from '../Input/styles';

export const FORMAT_TYPES = {
  COMMAS: 'commas',
  PIXELS: 'px',
};

const numberWithCommas = (x) => x.toLocaleString();

const formatValue = (value, format) => {
  if (format === FORMAT_TYPES.COMMAS) {
    return numberWithCommas(value);
  }
  if (format === FORMAT_TYPES.PIXELS) {
    return `${value}px`;
  }
  return null;
};

const RangeInput = ({
  id,
  value,
  label,
  min,
  max,
  step,
  withSteps,
  formatMarker,
  className,
  onChange,
  onTooltipChange,
  showTooltip,
  tooltipValue = value,
  ...props
}) => {
  let sliderMarkers = null;

  if (withSteps) {
    const range = max - min;
    const steps = Array.from(Array(range / step + 1).keys());
    sliderMarkers = steps.map((key) => {
      const val = min + key * step;
      return (
        <SliderMarker key={key} value={val}>
          <span>{formatMarker ? formatValue(val, formatMarker) : val}</span>
        </SliderMarker>
      );
    });
  } else {
    sliderMarkers = (
      <>
        <SliderMarker value={min}>
          <span>{formatMarker ? formatValue(min, formatMarker) : min}</span>
        </SliderMarker>
        <SliderMarker value={max}>
          <span>{formatMarker ? formatValue(max, formatMarker) : max}</span>
        </SliderMarker>
      </>
    );
  }

  const handleSubmitTooltipChange = () => {
    if (tooltipValue < min) {
      onChange(min);
    } else if (tooltipValue > max) {
      onChange(max);
    } else {
      const result = step * Math.ceil(tooltipValue / step);
      onChange(result);
    }
  };

  return (
    <StyledSliderWrapper className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <SliderInput
        onChange={onChange}
        step={step}
        min={min}
        max={max}
        value={value}
        {...props}
      >
        <SliderTrack>
          {sliderMarkers}
          <SliderTrackHighlight />
          <SliderHandle>
            {showTooltip && (
              <StyledHandleTooltip
                type={INPUT_TYPES.WHOLE_NUMBER}
                value={tooltipValue}
                onChange={onTooltipChange}
                onBlur={handleSubmitTooltipChange}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleSubmitTooltipChange();
                  }
                }}
                // onPointerDown overrides the slider handle's activation on a mouse click event
                // https://reach.tech/slider
                // https://github.com/reach/reach-ui/pull/290/files/9cc98ac7e589a1ceefe23c1c80ac1aaded89d673#diff-d7a3d8965bf9c6bdcec93c1225916031R22
                onPointerDown={(e) => e.preventDefault()}
              />
            )}
          </SliderHandle>
        </SliderTrack>
      </SliderInput>
    </StyledSliderWrapper>
  );
};

RangeInput.propTypes = {
  id: PropTypes.string,
  // Current range value
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  withSteps: PropTypes.number,
  className: PropTypes.string,
  formatMarker: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onTooltipChange: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool,
  tooltipValue: PropTypes.number,
};

RangeInput.defaultProps = {
  id: '',
  label: '',
  className: '',
  withSteps: false,
  min: 0,
  max: 100,
  step: 1,
  formatMarker: null,
  showTooltip: true,
  tooltipValue: undefined,
};

export default RangeInput;
