import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { RowProps } from '../../types';
import { NodeType } from '../types';
import {
  DEFAULT_ELEMENT_CONTAINER_PROPS,
  ElementContainer,
} from './ElementContainer';
import { applyLegacyContainerPadding } from './utils';

type RowContainerProps = RowProps & {
  children: ReactNode;
  draggingType?: NodeType;
};

type ColumnsProps = RowContainerProps & {
  applyLegacyContainerPadding: boolean;
};

const alignSelf = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const DEFAULT_ROW_CONTAINER_PROPS = {
  ...DEFAULT_ELEMENT_CONTAINER_PROPS,
  containerPaddingTop: '10',
  containerPaddingRight: '10',
  containerPaddingBottom: '10',
  containerPaddingLeft: '10',
};

const Columns = styled(ElementContainer)<ColumnsProps>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns.map((c) => `minmax(0, ${c}fr)`).join(' ')};
  ${({ applyLegacyContainerPadding }) =>
    applyLegacyContainerPadding &&
    css`
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
    `}
  align-self: ${({ alignment = 'center' }) => alignSelf[alignment]};
  width: ${({ width = 100 }) => `${width}%`};
  max-width: ${({ maxWidth }) =>
    maxWidth &&
    css`
      ${maxWidth}px;
    `};
`;

export const RowContainer = forwardRef(
  (
    { children, draggingType, ...rest }: RowContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Columns
      ref={ref}
      applyLegacyContainerPadding={applyLegacyContainerPadding(rest)}
      ignoreChildrenDrag={draggingType === 'Row'}
      {...rest}
    >
      {children}
    </Columns>
  )
);
