import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { DropPlaceholder } from 'components/MultiColumnBuilder/components/BaseOption/styles';
import { colorsButton, grayScale } from 'app/colors';
import InputControl from 'components/Inputs/InputControl';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { layers } from 'app/spacing';
import { appBackground } from 'app/colors';
import EditableText from 'components/Kizen/EditableText';
import { WRAPPER_HEGHT } from './constants';

export const StyledDropPlaceholder = styled(DropPlaceholder)`
  & > div > div {
    border: 1px dashed ${grayScale.mediumLight};
    background: ${appBackground};
    padding-top: 14px;

    padding-bottom: 14px;
    ${({ over }) =>
      over
        ? css`
            border-style: solid;
          `
        : ''}
  }
  p {
    color: ${grayScale.mediumDark};
  }
  p:hover {
    color: ${({ noHover }) =>
      noHover ? grayScale.mediumDark : grayScale.dark};
  }
`;

export const Wrapper = styled.div`
  min-height: ${WRAPPER_HEGHT}px;
  display: flex;
  flex-direction: column;
`;

export const StyledInputControl = styled(InputControl)`
  z-index: ${layers.content(10)};
`;

export const StyledEditableText = styled(EditableText)`
  display: flex;
  flex-grow: 1;
  margin-right: 20px;
  width: 0;
`;

export const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
  flex-grow: 1;
  margin-right: 20px;
  width: 0;

  ${({ isDynamic }) =>
    isDynamic
      ? css`
          color: ${colorsButton.green.default};
        `
      : ''}
`;

export const StyledBaseOption = styled.div`
  .innerItem > div {
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${grayScale.light};
      `}
  }
`;

export const LeftColumnWrapper = styled.div`
  padding-top: 14px;
  padding-right: 20px;
  margin-left: -1px; //design nudge
`;
