import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useToggle from 'react-use/lib/useToggle';
import { useTranslation } from 'react-i18next';

import { getOriginalError } from 'services/AxiosService';
import { isMobile, useWindowSize } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import { getSearchParam, setSearchParams } from 'hooks/useSearchParam';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import Automation2Service, {
  getAutomationObjectName,
} from 'services/Automation2Service';
import { camelToSnakeCase, snakeToCamelCase } from 'services/helpers';
import {
  buildPage,
  updatePageConfig,
  updatePageConfigBySearch,
  getAutomations,
  updateAutomation,
  duplicateAutomation,
  deleteAutomation,
  cleanToast,
  resetFilters,
  setFilterName,
  createOrUpdateGroup,
  removeGroup,
  changeGroupAction,
} from 'store/automationsPage/automations.redux';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';
import NewAutomationModal from './NewAutomationModal';
import { actions } from './columns';
import { useToast } from 'components/ToastProvider';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useDebounce } from 'react-use';
import { maybeTrackSearch } from 'utility/analytics';
import { DEFAULT_DELAY } from 'utility/config';
import { getAutomationsWithMeta } from './helpers';
import { deferExecution } from 'utility/defer';
import { AutomationHistoryModal } from './AutomationHistoryModal';
import useModal from 'components/Modals/useModal';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

const defaultPageSettings = {
  pageNumber: 1,
  pageSize: 50,
};

const updateAutomationHandler = async ({ isAction, ...data }, t) => {
  try {
    return await Automation2Service.update(data);
  } catch (error) {
    const originalError = getOriginalError(error);
    if (originalError && originalError.name) {
      throw new Error(originalError.name);
    }

    if ('active' in data && !isAction) {
      throw new Error(originalError);
    }

    return null;
  }
};

const AutomationPage = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();
  const [deletedAutomation, setAutomationForDelete] = useState(null);
  const [historyAutomation, setHistoryAutomation] = useState(null);
  const [showAddModal, toggleShowAddModal] = useToggle(false);
  const [isMenuOpen, toggleMenu] = useState(false);
  const [showToast] = useToast();

  const isFetching = useSelector((s) => s.automationPage.isFetching);
  const groups = useSelector((s) => s.automationPage.groups);
  const pageConfig = useSelector((s) => s.automationPage.pageConfig);
  const automationsWithoutMeta = useSelector(
    (s) => s.automationPage.automations
  );
  const automationsCount = useSelector(
    (s) => s.automationPage.automationsCount
  );
  const models = useSelector((s) => s.automationPage.models);
  const toastData = useSelector((s) => s.automationPage.toastData);
  const filters = useSelector((s) => s.automationPage.filters);

  const automations = useMemo(() => {
    return (
      automationsWithoutMeta &&
      automationsWithoutMeta.map(getAutomationsWithMeta(models))
    );
  }, [automationsWithoutMeta, models]);

  // Track debounced changes to the search term and send to google analytics
  const { search } = pageConfig;
  useDebounce(() => maybeTrackSearch(search, 'automations'), DEFAULT_DELAY, [
    search,
  ]);

  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const group = getSearchParam(history.location, 'group');
    const search = getSearchParam(history.location, 'q');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');

    syncDispatch(
      buildPage({
        t: t,
        groups: {},
        page: {
          search: search || '',
          group,
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
        },
      })
    );
  }, [syncDispatch, history, t]);

  // memo functions ------------------------------------------------
  const sortValue = useMemo(
    () => getSortValue(snakeToCamelCase(pageConfig.sort)),
    [pageConfig.sort]
  );

  // filter handlers -----------------------------------------------
  const updateFilterName = useCallback(
    (name) => {
      dispatch(setFilterName({ name }));
    },
    [dispatch]
  );

  const applyFiltersAction = useCallback(
    (payload) => {
      dispatch(
        updatePageConfig({
          page: 1,
          filterObject: payload,
        })
      );
      dispatch(
        getAutomations({
          updatePageConfig: true,
        })
      );
    },
    [dispatch]
  );

  const saveGroupAction = useCallback(
    (data) => {
      dispatch(createOrUpdateGroup({ ...data, history }));
    },
    [dispatch, history]
  );

  const deleteGroupAction = useCallback(() => {
    dispatch(removeGroup({ history }));
    toggleMenu(false);
  }, [dispatch, history]);

  const clearFilter = useCallback(() => {
    dispatch(resetFilters());
    dispatch(
      getAutomations({
        updatePageConfig: true,
      })
    );
    deferExecution(() => {
      setSearchParams(history, {
        page: null,
        ...(pageConfig.group ? { group: null } : {}),
        ...(pageConfig.search ? { q: '' } : {}),
      });
    });
  }, [dispatch, history, pageConfig.group, pageConfig.search]);

  // table handlers ------------------------------------------------
  const handleChangeGroupId = useCallback(
    ({ value }) => {
      const data = value === 'none' ? null : value;
      dispatch(changeGroupAction({ id: value }));
      dispatch(getAutomations());
      deferExecution(() => {
        setSearchParams(history, {
          group: data,
          page: null,
        });
      });
    },
    [history, dispatch]
  );

  const handleChangeSearch = useCallback(
    (value) => {
      dispatch(
        updatePageConfigBySearch({
          search: value,
          page: defaultPageSettings.pageNumber,
        })
      );
      dispatch(
        getAutomations({
          updatePageConfig: false,
        })
      );
      deferExecution(() => {
        setSearchParams(
          history,
          {
            q: value,
            page: null,
          },
          { method: 'replace' }
        );
      });
    },
    [history, dispatch]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(updatePageConfig({ page: value }));
      dispatch(getAutomations());
      deferExecution(() => {
        setSearchParams(history, {
          page: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(updatePageConfig({ size: value }));
      dispatch(getAutomations());
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleSortTable = useCallback(
    ({ column, direction }) => {
      const data = camelToSnakeCase(column);
      const value = getOrderingParam({
        column: data,
        direction,
      });

      dispatch(updatePageConfig({ sort: value }));
      dispatch(getAutomations());
      deferExecution(() => {
        setSearchParams(history, {
          sort: value,
        });
      });
    },
    [dispatch, history]
  );

  // columns handlers
  const handleUpdateAutomation = useCallback(
    async (data) => {
      const automation = await updateAutomationHandler(data, t);

      if (automation) {
        dispatch(
          updateAutomation({
            ...automation,
            customObjectName: getAutomationObjectName(automation),
            status: automation.active,
          })
        );
      }
    },
    [dispatch, t]
  );

  const [historyModalProps, , historyModal] = useModal({
    handleHide: () => setHistoryAutomation(null),
  });

  const handleSelectAction = useCallback(
    ({ value }, automation) => {
      switch (value) {
        case actions.edit: {
          history.push(`/automation/${automation.id}/`);
          break;
        }
        case actions.activate: {
          handleUpdateAutomation({
            id: automation.id,
            active: true,
            isAction: true,
            lastRevision: automation.revision,
          });
          break;
        }
        case actions.deactivate: {
          handleUpdateAutomation({
            id: automation.id,
            active: false,
            isAction: true,
            lastRevision: automation.revision,
          });
          break;
        }
        case actions.duplicate: {
          dispatch(duplicateAutomation(automation.id));
          break;
        }
        case actions.history: {
          setHistoryAutomation(automation);
          historyModal.show();
          break;
        }
        default: {
          // delete
          setAutomationForDelete(automation.id);
        }
      }
    },
    [history, dispatch, handleUpdateAutomation, historyModal]
  );

  const handleDeleteAutomation = useCallback(() => {
    dispatch(cleanToast());
    dispatch(
      deleteAutomation({
        id: deletedAutomation,
        t,
      })
    );
    setAutomationForDelete(null);
  }, [dispatch, deletedAutomation, t]);

  const handleCleanToastData = useCallback(() => {
    dispatch(cleanToast());
  }, [dispatch]);

  useEffect(() => {
    if (toastData) {
      showToast(toastData);
      handleCleanToastData();
    }
  }, [toastData, showToast, handleCleanToastData]);

  if (isFetching) {
    return <Loader loading />;
  }

  return (
    <>
      {isMobile(width) ? (
        <MobileLayout
          automations={automations}
          groups={groups}
          groupId={pageConfig.group}
          handleChangeGroup={handleChangeGroupId}
          search={pageConfig.search}
          handleChangeSearch={handleChangeSearch}
          automationsCount={automationsCount}
          pageNumber={pageConfig.page || defaultPageSettings.pageNumber}
          pageSize={pageConfig.size || defaultPageSettings.pageSize}
          handleChangePageNumber={handleChangePageNumber}
          handleChangePageSize={handleChangePageSize}
          sort={sortValue}
          handleChangeSort={handleSortTable}
          handleSelectAction={handleSelectAction}
        />
      ) : (
        <DesktopLayout
          automations={automations}
          groups={groups}
          groupId={pageConfig.group}
          handleChangeGroup={handleChangeGroupId}
          search={pageConfig.search}
          handleChangeSearch={handleChangeSearch}
          automationsCount={automationsCount}
          pageNumber={pageConfig.page || defaultPageSettings.pageNumber}
          pageSize={pageConfig.size || defaultPageSettings.pageSize}
          handleChangePageNumber={handleChangePageNumber}
          handleChangePageSize={handleChangePageSize}
          sort={sortValue}
          handleChangeSort={handleSortTable}
          handleUpdateAutomation={handleUpdateAutomation}
          handleSelectAction={handleSelectAction}
          handleClickNewAutomation={toggleShowAddModal}
          openMenu={isMenuOpen}
          toggleMenu={toggleMenu}
          filterInfo={filters}
          applyFiltersAction={applyFiltersAction}
          saveGroupAction={saveGroupAction}
          deleteGroupAction={deleteGroupAction}
          setFilterName={updateFilterName}
          clearFilter={clearFilter}
          errorsReturned={filters.errors}
          data-qa="automations-table"
        />
      )}
      <ConfirmDeletionModal
        show={Boolean(deletedAutomation)}
        onConfirm={handleDeleteAutomation}
        onHide={() => setAutomationForDelete(null)}
      >
        {t(
          'This will permanently delete the Automation and no more associated steps will run for any contact.'
        )}
      </ConfirmDeletionModal>
      <NewAutomationModal
        models={models}
        show={showAddModal}
        onHide={toggleShowAddModal}
        handleCreate={toggleShowAddModal}
      />
      {historyAutomation ? (
        <AutomationHistoryModal
          automation={historyAutomation}
          modalProps={historyModalProps}
        />
      ) : null}
    </>
  );
};

export default AutomationPage;
