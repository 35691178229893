import AxiosService from './AxiosService';
import { snakeToCamelCaseKeys } from './helpers';

const getOptionsList = (element) => ({
  label: element.name,
  value: element.id,
});

class AdsService {
  constructor() {
    if (!AdsService.instance) {
      AdsService.instance = this;
    }
    return AdsService.instance;
  }

  endpoint = '/marketing';

  adClickEndpoint = `${this.endpoint}/ad-click`;

  getCampaigns = async (params = '') => {
    const { skipNone = false } = params;
    const { data } = await AxiosService.get(`${this.endpoint}/ad-campaigns`, {
      params,
    });
    return [
      {
        label: 'Any Campaign',
        value: 'any',
      },
      !skipNone && {
        label: 'No Campaign',
        value: 'none',
      },
      ...data.results.map(getOptionsList).filter(Boolean),
    ].filter(Boolean);
  };

  getGroups = async (params = '') => {
    const { data } = await AxiosService.get(`${this.endpoint}/ad-groups?`, {
      params,
    });
    return [
      {
        label:
          params?.ad_provider === 'FACEBOOK' ? 'Any Ad Set' : 'Any Ad Group',
        value: 'any',
      },
      ...data.results.map(getOptionsList),
    ];
  };

  getAds = async (params = '') => {
    const { data } = await AxiosService.get(`${this.endpoint}/ads`, {
      params,
    });
    return [
      {
        label: 'Any Ad',
        value: 'any',
      },
      ...data.results.map(getOptionsList),
    ];
  };

  getCampaignsForLeadSource = async (adProvider) => {
    const { data } = await AxiosService.get(`${this.endpoint}/ad-campaigns`, {
      params: {
        ad_provider: adProvider,
      },
    });
    return snakeToCamelCaseKeys(data.results);
  };

  getGroupsForLeadSource = async (adCampaignId) => {
    const { data } = await AxiosService.get(`${this.endpoint}/ad-groups`, {
      params: {
        ad_campaign_id: adCampaignId,
      },
    });
    return snakeToCamelCaseKeys(data.results);
  };

  getAdsForLeadSource = async (adGroupId) => {
    const { data } = await AxiosService.get(`${this.endpoint}/ads`, {
      params: {
        ad_group_id: adGroupId,
      },
    });
    return snakeToCamelCaseKeys(data.results);
  };

  createAdClick = async (adClickData) => {
    const { data } = await AxiosService.post(this.adClickEndpoint, adClickData);
    return data;
  };

  getAccounts = async (params = '', optionsList = true) => {
    const { data } = await AxiosService.get(`${this.endpoint}/ad-accounts`, {
      params,
    });
    return optionsList
      ? [
          {
            label: 'Any Ad Account',
            value: 'any',
          },
          ...data.results.map(getOptionsList),
        ]
      : snakeToCamelCaseKeys({
          ...data,
          count: data.results.length,
        });
  };

  // sync
  googleLogin = async () => {
    window.location = `${AxiosService.defaults.baseURL}/marketing/google/login`;
  };

  getSyncableAdAccounts = async (params) => {
    const { data } = await AxiosService.get(
      `${this.endpoint}/google/syncable-ad-accounts`,
      {
        params,
      }
    );
    return snakeToCamelCaseKeys(data);
  };

  addAccounts = async (params = [], token) => {
    const promises = params.map((account) =>
      AxiosService.post(
        `${this.endpoint}/ad-accounts`,
        {
          ...account,
          external_id: account.id,
          access_token: token,
          ad_provider: 'google',
          status: 'active',
        },
        {
          skipErrorBoundary: true,
        }
      )
    );
    return await Promise.all(promises);
  };

  deleteAccount = async (id) => {
    const { data } = await AxiosService.delete(
      `${this.endpoint}/ad-accounts/${id}`
    );
    return snakeToCamelCaseKeys(data);
  };

  updateAccount = async (payload) => {
    const { id, ...rest } = payload;
    const { data } = await AxiosService.patch(
      `${this.endpoint}/ad-accounts/${id}`,
      {
        ...rest,
      }
    );
    return snakeToCamelCaseKeys(data);
  };
}

const instance = new AdsService();
Object.freeze(instance);

export default instance;
