import { useCallback, useState, useRef, useEffect } from 'react';

// The opportunity conversion chart has some unique and tricky scroll behavior,
// so this custom hook is able to drive the desired outcome and still utilize our
// standard ScrollFadeContainer.

const useScroll = (deps = []) => {
  const ref = useRef();

  const [canScroll, setCanScroll] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [scrollVisible, setScrollVisible] = useState(true);

  const handleChildScroll = useCallback((e) => {
    if (e?.target?.scrollTop > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    if (
      e?.target?.scrollTop + e?.target?.clientHeight ===
      e?.target.scrollHeight
    ) {
      setScrollVisible(false);
    } else {
      setScrollVisible(true);
    }
  }, []);

  const calculatePosition = useCallback((e) => {
    if (e) {
      if (e.clientHeight < e.scrollHeight) {
        setCanScroll(true);
      } else {
        setCanScroll(false);
      }
    }
  }, []);
  const handleChildMount = useCallback(
    (e) => {
      ref.current = e;
      calculatePosition(e);
    },
    [calculatePosition]
  );

  useEffect(() => {
    calculatePosition(ref.current);
    // Because we are spreading external deps, we can't check for
    // exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatePosition, ...deps]);

  return {
    containerProps: {
      ref: handleChildMount,
      onScroll: handleChildScroll,
    },
    headerProps: {
      scrolled,
    },
    fadeProps: {
      bottom: canScroll && scrollVisible,
    },
  };
};

export default useScroll;
