import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckboxGroup from 'components/Inputs/CheckboxGroup';
import KizenTypography from 'app/kizentypo';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import BasicModalWithConfirmation from 'components/Modals/presets/BasicModalWithConfirmation';

const transformToSelectOptions = (options) => {
  return options
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((option) => {
      return {
        value: option.id,
        label: option.name,
      };
    });
};

const StyledCheckboxGroup = styled(CheckboxGroup)`
  text-align: left;
  padding-top: ${gutters.spacing(2)}px;
`;
export const StyledLoader = styled(Loader)`
  margin-bottom: ${gutters.spacing(2)}px;
`;
export default function SyncAdAccountsModal({
  onHide,
  accounts = [],
  setValues,
  onConfirm,
  values,
  loading,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <BasicModalWithConfirmation
      {...props}
      buttonText={accounts.length ? t('Connect') : t('Close')}
      heading={t('Select Account(s) to Connect')}
      leftBtn={null}
      onHide={onHide}
      actionBtnColor={accounts.length ? 'green' : 'blue'}
      hasOneBtnSubmit
      dirty={accounts.length}
      disabled={loading || (!values.length && accounts.length)}
      confirmationHeading={t("You Haven't Connected Any Accounts")}
      confirmationContent={t(
        'No accounts will be connected, would you like to continue?'
      )}
      onConfirm={accounts.length ? onConfirm : onHide}
    >
      <StyledLoader loading={loading}>
        <KizenTypography as="p" type="label">
          {accounts.length
            ? t(
                'Please select one or more accounts to connect from the list below'
              )
            : t(
                'You have already connected all accounts from this Google Ads user.'
              )}
        </KizenTypography>
        {accounts.length ? (
          <StyledCheckboxGroup
            options={transformToSelectOptions(accounts)}
            onChange={setValues}
            value={values}
          />
        ) : null}
      </StyledLoader>
    </BasicModalWithConfirmation>
  );
}

SyncAdAccountsModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
};

SyncAdAccountsModal.defaultProps = {
  accounts: [],
};
