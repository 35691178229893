import { useMemo } from 'react';

/*
 * A hook that given the state of a wizard, and a list of functions to determine if a step is
 * available or not, returns the current step index.
 *
 * @param {Object} state - The state of the wizard
 *
 * @param {Array} steps - An array of functions, called with the wizard state, that should return
 * true or false to indicate if a step is completed or not.
 *
 */
const useWizardStepController = (state = {}, steps = []) => {
  const mappedSteps = useMemo(() => {
    return [...steps.map((fn) => fn(state)), false];
  }, [steps, state]);

  const currentStep = useMemo(() => {
    return mappedSteps.indexOf(false);
  }, [mappedSteps]);

  return currentStep;
};

export default useWizardStepController;
