import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';
import parsePhoneNumber, {
  AsYouType,
  getCountryCallingCode,
} from 'libphonenumber-js/max';
import { gutters } from '../../../../app/spacing';
import { KizenTypography } from '../../../../app/typography';
import phoneMeta from 'libphonenumber-js/metadata.min.json';
import StylePassthrough from '../../../Kizen/StylePassthrough';
import { getDataQAForInput } from '../../helpers';
import {
  MaybeInputControl,
  getNonInputControlProps,
  hasInputControl,
} from '../../InputControl';
import Select from '../../Select';
import {
  Control as CustomControl,
  Menu as CustomMenu,
} from '../../Select/customize';
import TextInput from '../index';
import { applyRef } from '../../props';
import { isCountryCallingCode } from '../../../Fields/helpers';

// copied from apps/react-app/src/components/Fields/helpers
const suggestedCountryByPhoneCode = (number) => {
  return phoneMeta.country_calling_codes[number][0];
};

// copied hook from apps/react-app/src/hooks/usePhoneFormatter
function usePhoneFormatter(phoneNumber, country = 'US') {
  const businessCC = getCountryCallingCode(country) || '';
  const asYouTypeOptions = useMemo(
    () => ({
      defaultCallingCode: phoneNumber?.countryCallingCode || businessCC || '1',
      defaultCountry: phoneNumber?.country || country,
    }),
    [phoneNumber, businessCC, country]
  );

  const formattedValue = useMemo(() => {
    if (!phoneNumber) return '';
    const phoneNumberObj = phoneNumber?.number
      ? phoneNumber
      : parsePhoneNumber(phoneNumber.toString());
    if (phoneNumberObj?.formatNational && businessCC) {
      const format =
        +businessCC === +phoneNumberObj.countryCallingCode
          ? 'NATIONAL'
          : 'INTERNATIONAL';
      return phoneNumberObj.format(format, {
        formatExtension: (number, extension) => {
          return number + (extension ? ' x ' + extension : '');
        },
      });
    }
    return '';
  }, [businessCC, phoneNumber]);

  return {
    formattedValue,
    asYouTypeOptions,
    businessCC,
  };
}

const emptyValue = '—';

// NATIONAL — Example: "(213) 373-4253"
// INTERNATIONAL — Example: "+1 213 373 4253"
// E.164 — Example: "+12133734253"
// RFC3966 (the phone number URI) — Example: "tel:+12133734253;ext=123"
// const FORMAT = 'E.164';
const formatPhoneString = (phoneNumber) => {
  return `${
    phoneNumber.countryCallingCode ? '+' + phoneNumber.countryCallingCode : ''
  }${phoneNumber.nationalNumber}${
    phoneNumber.ext ? 'x' + phoneNumber.ext : ''
  }`;
};

export const validate = (t, phoneNumber, isRequred) => {
  return {
    character: (char) =>
      /[+\d]/.test(char) || t('No alphabetical characters are accepted'),
    full: (value) => {
      if (isRequred && !value) {
        return t('This field is required');
      }
      if (!phoneNumber.nationalNumber && !phoneNumber.ext) {
        return null;
      }

      if (phoneNumber.ext && !/^\d{1,9}$/.test(phoneNumber.ext)) {
        return t(
          'Please enter a valid phone number. The Extension can contain maximum 9 digits'
        );
      }

      if (phoneNumber?.isValid && !phoneNumber?.isValid()) {
        return t('Please enter a valid phone number');
      }
      return null;
    },
  };
};

const extensionValidate = (t) => {
  return {
    character: (char) =>
      /[0-9]/.test(char) || t('Only numeric characters are accepted.'),
  };
};

const codeOptions = [
  ...Object.keys(phoneMeta['country_calling_codes'])
    .map((code) => ({
      value: code,
      label: `+${code}`,
    }))
    .sort((a, b) => a.value - b.value),
];

const getGridLayout = (ext, isOutline) => {
  if (!ext) {
    return isOutline ? '75px minmax(115px, 1fr)' : '50px 1fr';
  }

  return isOutline ? '75px minmax(115px, 1fr) 70px' : '50px 1fr 40px';
};

const getGridColumns = (ext) => (ext ? 3 : 2);

const FieldLayout = styled.div`
  display: grid;

  ${({ width, enableExtension, variant }) => {
    if (variant === 'outline') {
      if (width >= 280) {
        return css`
          grid-template-columns: ${getGridLayout(enableExtension, true)};
          column-gap: ${gutters.spacing(2)}px;
        `;
      }
      if (width < 280 && width >= 200) {
        return css`
          grid-template-columns: ${'75px minmax(115px, 1fr)'};
          column-gap: ${gutters.spacing(2)}px;
          ${enableExtension &&
          `grid-template-rows: repeat(2, 1fr);
             row-gap: ${gutters.spacing(2)}px;
            `};
        `;
      }
      return css`
        grid-template-rows: repeat(${getGridColumns(enableExtension)}, 1fr);
        gap: ${gutters.spacing(2)}px;
      `;
    }
    if (width >= 174) {
      return css`
        grid-template-columns: ${getGridLayout(enableExtension, false)};
        column-gap: ${gutters.spacing(1)}px;
      `;
    }
    if (width < 174 && width >= 129) {
      return css`
        grid-template-columns: ${'50px minmax(83px, 1fr)'};
        column-gap: ${gutters.spacing(1)}px;
        ${enableExtension &&
        `grid-template-rows: repeat(2, 1fr);
           row-gap: ${gutters.spacing(1)}px;
          `};
      `;
    }
    return css`
      grid-template-rows: repeat(${getGridColumns(enableExtension)}, 1fr);
      gap: ${gutters.spacing(1)}px;
    `;
  }}
`;

const StyledCountryCode = styled(Select)`
  i {
    margin-left: ${gutters.spacing(1)}px;
  }
  ${({ variant }) =>
    variant === 'outline'
      ? css`
          width: 75px;
        `
      : css`
          width: 50px;
        `}
`;

const CountryCodeMenu = styled(CustomMenu)`
  ${({ selectProps: { variant } }) =>
    variant === 'underline' &&
    css`
      width: 50px;
      li {
        padding: 0 0 0 ${gutters.spacing(2, -3)}px;
      }
    `}
`;

const StyledTextInput = styled(TextInput)`
  ${({ variant }) =>
    variant === 'underline' &&
    css`
      padding-right: 0;
    `}

  input {
    width: 100%;
  }
`;

const CountryCodeControlStyles = styled(StylePassthrough)`
  ${({ selectProps: { variant } }) =>
    variant === 'underline' &&
    css`
      && {
        padding-right: ${gutters.spacing(1, -3)}px;
      }
    `}
`;

const CountryCodeControl = (props) => (
  <CountryCodeControlStyles {...props}>
    <CustomControl {...props} />
  </CountryCodeControlStyles>
);

const StyledExtension = styled(TextInput)`
  ${({ variant }) =>
    variant === 'outline'
      ? css`
          width: 70px;
          input {
            width: 49px;
          }
        `
      : css`
          width: 40px;
          input {
            width: 40px;
          }
        `}
`;
const phoneNumberObject = {
  number: '',
  countryCallingCode: '',
  nationalNumber: '',
  country: '',
  ext: '',
  carrierCode: '',
  isValid: () => {},
};

const tryPhoneNumberValidParse = (phoneData, defaultCC) => {
  const firstTry = parsePhoneNumber(phoneData.number);
  if (firstTry && firstTry.isValid()) {
    return firstTry;
  }

  const secondTry = parsePhoneNumber(`+${defaultCC}${phoneData.number}`);
  if (secondTry && secondTry.isValid()) {
    return secondTry;
  }

  const thirdTry = parsePhoneNumber(
    `+${phoneData.countryCallingCode || ''}${phoneData.number}`
  );
  if (thirdTry && thirdTry.isValid()) {
    return thirdTry;
  }

  return false;
};

const DisableUnderlineView = ({ value: val, formattedValue }) => {
  const text =
    val && (val.countryCallingCode || val.nationalNumber)
      ? formattedValue + `${val.ext ? ' x ' + val.ext : ''}`
      : emptyValue;
  return <KizenTypography>{text}</KizenTypography>;
};

DisableUnderlineView.propTypes = {
  value: PropTypes.object.isRequired,
};

const PhoneNumberTextInput = React.forwardRef((props, ref) => {
  const [fieldLayout, { width }] = useMeasure();
  const {
    value,
    onChange,
    placeholderNumber,
    placeholderExtension,
    enableExtension,
    variant,
    disabled,
    'data-field-type': qaFieldType,
    'data-field-id': qaFieldId,
    defaultCountry,
    onKeyPress,
    ...rest
  } = props;

  const {
    className = '',
    styles,
    error,
    autoFocus,
    validate: externalValidation,
    ...nonInputProps
  } = getNonInputControlProps(props);
  const { t } = useTranslation();
  const country = defaultCountry || 'US';
  const prevPhoneNumber = useRef({
    ...phoneNumberObject,
    country: country,
  });
  const [phoneData, setPhoneData] = useState(
    value
      ? parsePhoneNumber(value)
      : {
          ...phoneNumberObject,
          country,
        }
  );

  const numberRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(numberRef, el);
      applyRef(ref, el);
    },
    [ref]
  );

  const [forceValidation, setForceValidation] = useState(0);
  const [internalNationalNumber, setInternalNationalNumber] = useState(
    phoneData.nationalNumber || ''
  );
  const stagedValue = useRef(value);

  useEffect(() => {
    if (value !== stagedValue.current) {
      stagedValue.current = value;
      if (value) {
        const parsedPhoneNumber = parsePhoneNumber(value);
        if (parsedPhoneNumber) {
          setPhoneData(parsedPhoneNumber);
          setInternalNationalNumber(parsedPhoneNumber.nationalNumber);
        }
      } else {
        setPhoneData({
          ...phoneNumberObject,
          country,
        });
      }
    }
  }, [country, value]);

  const defaultCC =
    prevPhoneNumber.current.countryCallingCode ||
    getCountryCallingCode(country);

  const setChange = useCallback(
    (phoneNumber, event) => {
      const formattedValue = formatPhoneString(phoneNumber);
      // prevent infinite loop
      // if (formattedValue === value) return;
      // avoid initial render;
      if (stagedValue.current === formattedValue) {
        return;
      }

      if (
        (!phoneNumber.ext ||
          (phoneNumber.ext && /^\d{1,9}$/.test(phoneNumber.ext))) &&
        phoneNumber?.isValid &&
        phoneNumber?.isValid()
      ) {
        onChange(formattedValue, event, null);
        return;
      }
      const errorMessage = validate(t, phoneNumber, props.isRequired).full(
        phoneNumber
      );
      onChange(formattedValue, event, {
        errorMessage,
        removeOnSubmit: isCountryCallingCode(formattedValue),
      });
    },
    [onChange, props.isRequired, t]
  );

  const { formattedValue, asYouTypeOptions } = usePhoneFormatter(
    phoneData,
    country
  );

  const focusRef = useRef(null);

  const {
    value: _,
    'data-field-type': _1,
    'data-field-id': _2,
    ...forInputControl
  } = props;

  // country field
  const handleChangeCountryCode = (opt) => {
    let newData;
    if (phoneData.number) {
      newData =
        parsePhoneNumber(`+${opt.value}${phoneData.nationalNumber}`) ||
        parsePhoneNumber(`+${defaultCC}${phoneData.nationalNumber}`);

      if (phoneData.ext) {
        newData.ext = phoneData.ext;
      }
    } else {
      newData = phoneData;
      newData.countryCallingCode = opt.value;
      newData.country = suggestedCountryByPhoneCode(opt.value);
    }

    prevPhoneNumber.current = newData;
    setPhoneData(newData);
    numberRef.current.firstChild.focus();
    setChange(newData, null);
  };

  // number field
  const handleFocusNumber = (event) => {
    focusRef.current = 1;
    setForceValidation(Math.random());
    setChange(phoneData, event);
  };

  const handleChangeNumber = (number) => {
    const newData = {
      ...phoneData,
      number,
    };
    setPhoneData(newData);
    prevPhoneNumber.current = newData;
    setInternalNationalNumber(number);
  };

  const handleBlurNumber = (event) => {
    focusRef.current = null;
    let newData;

    const parsedPhoneNumber = tryPhoneNumberValidParse(phoneData, defaultCC);

    if (parsedPhoneNumber) {
      newData = parsedPhoneNumber;
      if (phoneData.ext) {
        newData.ext = phoneData.ext;
      }
    } else if (!phoneData.number) {
      newData = phoneNumberObject;
    } else {
      newData = parsePhoneNumber(
        `${
          !prevPhoneNumber.current.number.includes('+')
            ? `+${prevPhoneNumber.current.countryCallingCode || defaultCC}`
            : ''
        }${prevPhoneNumber.current.number}`
      ) || {
        ...phoneNumberObject,
        countryCallingCode: defaultCC,
        nationalNumber:
          prevPhoneNumber.current.nationalNumber || phoneData.number,
        ext: prevPhoneNumber.current.ext,
        country: suggestedCountryByPhoneCode(defaultCC),
      };

      if (phoneData.ext) {
        newData.ext = phoneData.ext;
      }
    }
    setInternalNationalNumber(newData.nationalNumber);
    setPhoneData(newData);
    prevPhoneNumber.current = newData;
    setForceValidation(Math.random());
    setChange(newData, event);
  };

  // extension field
  const handleChangeExtension = (extension, event) => {
    const newData = phoneData;
    newData.ext = extension;
    prevPhoneNumber.current = newData;
    setPhoneData(newData);
    setChange(newData, event);
    setForceValidation(Math.random());
  };

  const formattedNationalNumber = useMemo(
    () => new AsYouType(asYouTypeOptions).input(phoneData.nationalNumber || ''),
    [phoneData.nationalNumber, asYouTypeOptions]
  );

  return (
    <MaybeInputControl forInput {...forInputControl}>
      {variant === 'underline' && disabled ? (
        <DisableUnderlineView value={value} formattedValue={formattedValue} />
      ) : (
        <FieldLayout
          ref={fieldLayout}
          width={width}
          enableExtension={enableExtension}
          variant={variant}
          {...rest}
          className={hasInputControl(props) ? '' : className}
        >
          <StyledCountryCode
            isSearchable
            {...nonInputProps}
            options={codeOptions}
            placeholder={'+' + defaultCC}
            value={
              phoneData.countryCallingCode ||
              prevPhoneNumber.current.countryCallingCode
            }
            onChange={handleChangeCountryCode}
            components={{
              Control: CountryCodeControl,
              Menu: CountryCodeMenu,
              ...nonInputProps.components,
            }}
            onFocus={(...data) => {
              focusRef.current = 0;
              props.onFocus?.(...data);
            }}
          />

          <StyledTextInput
            className="national-number"
            {...nonInputProps}
            ref={mergeRef}
            styles={styles}
            placeholder={placeholderNumber || t('Phone Number')}
            value={
              focusRef.current === 1
                ? internalNationalNumber
                : formattedNationalNumber
            }
            onBlur={handleBlurNumber}
            forceValidation={forceValidation}
            onFocus={handleFocusNumber}
            onChange={handleChangeNumber}
            validate={{
              // internal validation
              ...validate(t, phoneData, forInputControl.isRequired),
              // external validation has higher priority
              ...props.validate,
            }}
            error={error}
            autoFocus={autoFocus}
            data-field-type="phonenumber"
          />
          {enableExtension && (
            <StyledExtension
              {...nonInputProps}
              placeholder={placeholderExtension || t('Ext')}
              value={phoneData.ext || ''}
              validate={extensionValidate(t)}
              onBlur={(event) => {
                setChange(phoneData, event);
              }}
              onFocus={() => {
                focusRef.current = 2;
              }}
              onChange={handleChangeExtension}
              errorPlacement="bottom-end"
              {...getDataQAForInput(qaFieldId, `${qaFieldType}-ext`)}
              data-field-type="phonenumber-ext"
            />
          )}
        </FieldLayout>
      )}
    </MaybeInputControl>
  );
});

PhoneNumberTextInput.displayName = 'PhoneNumberTextInput';
PhoneNumberTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultCountry: PropTypes.string,
  placeholderNumber: PropTypes.string,
  placeholderExtension: PropTypes.string,
  enableExtension: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
PhoneNumberTextInput.defaultProps = {
  defaultCountry: 'US',
  placeholderNumber: '',
  placeholderExtension: '',
  enableExtension: false,
  variant: 'outline',
  disabled: false,
};

export default PhoneNumberTextInput;
