import React, { useEffect, useCallback, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { STEPS, FORMDATA_FIELDS } from '../config';
import { useTranslation } from 'react-i18next';
import { StyledSection } from '../styled';
import { ActivityAssosiationFields } from '../../completeActivity/components';

const { ENTITIES } = FORMDATA_FIELDS;

const checkValidObjects = ({ associatedEntities }) =>
  Boolean(associatedEntities.length);

const ScheduleActivityObjects = ({
  assignments,
  getKeyListenersProps,
  dispatchAssignment,
  activityRelatedObjects,
  updateValidation,
  updateField,
  associatedEntities,
  isLastIndex,
  loading,
}) => {
  const { t } = useTranslation();
  const isValid = checkValidObjects({ associatedEntities });

  const initRef = useRef(true);

  useEffect(() => {
    updateValidation(STEPS.RELATED_OBJECTS, isValid);
  }, [isValid, updateValidation]);

  const handleOnChange = useCallback(
    (setterProp) => (value) => {
      initRef.current = false;
      dispatchAssignment({
        ...setterProp,
        value,
      });
    },
    [dispatchAssignment]
  );

  useEffect(() => {
    activityRelatedObjects.length &&
      updateField(
        ENTITIES,
        activityRelatedObjects
          .filter((v) => v.name !== '')
          .map((obj) => ({
            customObjectId: obj.id,
            entityId:
              assignments[obj.component.getterProp] &&
              (assignments[obj.component.getterProp].id ||
                assignments[obj.component.getterProp].value),
          }))
          .filter((v) => v.entityId),
        initRef.current
      );
  }, [assignments, activityRelatedObjects, updateField]);

  return (
    <StyledSection
      index={1}
      header={t('Set Associations')}
      isLastIndex={isLastIndex}
      isActive
      isEmpty={!activityRelatedObjects.length}
    >
      <ActivityAssosiationFields
        activityRelatedObjects={activityRelatedObjects}
        loading={loading}
        handleChange={handleOnChange}
        getKeyListenersProps={getKeyListenersProps}
        assignments={assignments}
      />
    </StyledSection>
  );
};

ScheduleActivityObjects.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  activityRelatedObjects: PropTypes.array.isRequired,
  assignments: PropTypes.object.isRequired,
  dispatchAssignment: PropTypes.func.isRequired,
  associatedEntities: PropTypes.array.isRequired,
  isLastIndex: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ScheduleActivityObjects;
