import { useTranslation } from 'react-i18next';
import NumberInput from 'react-number-format';
import * as PropTypes from 'prop-types';
import { colorsButton } from 'app/colors';
import DragItem from 'components/DragAndDropLayout/DragItem';
import EditableText from 'components/Kizen/EditableText';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { IconSizing } from 'components/Kizen/Icon';
import SelectInline from 'components/Inputs/inline/Select';
import Validation from 'components/Inputs/Validation';
import {
  CO_STAGE_STATUS_OPEN,
  CO_STAGE_STATUS_WON,
  CO_STAGE_STATUS_LOST,
  CO_STAGE_STATUS_DISQUALIFIED,
} from 'components/Wizards/CustomObject/utilities';
import styled from '@emotion/styled';
import { textCss } from 'app/typography';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { EditableTextForNumberInput } from 'components/Kizen/EditableText';
import { editableTextPaddingDense } from 'components/Kizen/EditableText/styles';
import Cols from 'components/Layout/Cols';
import { gutters, layers } from 'app/spacing';
import BaseSelect from 'components/Inputs/Select';
import KizenTypography from 'app/kizentypo';
import { useRef, useCallback } from 'react';
import { modalConstrainPopperConfig } from 'components/Inputs/Validation';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { applyRef } from 'components/Inputs/props';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const Select = styled(BaseSelect)`
  z-index: ${layers.popoverInModal};
`;

const DragItemLayoutCols = styled(Cols)`
  max-width: calc(100% - 21px); // 11px icon + 10px margin
  flex: 1;
  & > :not(.spacer) {
    display: flex;
    align-items: center;
    & > * {
      max-width: 100%;
    }
  }
  & > div:last-of-type {
    display: flex;
    justify-content: space-between;
  }
`;

const EditablePercentage = styled(EditableTextForNumberInput)`
  input {
    padding-right: ${gutters.spacing(2)}px;
    min-width: ${35 - gutters.spacing(2)}px;
  }
  &::after {
    ${textCss()}
    content: "%";
    margin-left: ${gutters.spacing(-2)}px;
  }
`;

const AlignWithEditableText = styled(StylePassthrough)`
  margin-bottom: ${editableTextPaddingDense}px;
`;
const stayInViewport = {
  modifiers: {
    preventOverflow: {
      // Allows element to appear outside of the target's
      // scrolling parent but not outside the viewport.
      boundariesElement: 'viewport',
    },
  },
};
export default function DraggableItem({
  pipeline,
  useAiToUpdatePercentage,
  element: { stage },
  includePercentageToClose,
  onClickRemove,
  onStageChange,
  handleInputChange,
  validationProps,
  index,
  ...others
}) {
  const stageNameRef = useRef(null);

  const [nameTooltipProps, nameTooltip] = useTruncationTooltip();
  const target = useRef(null);
  const targetPercentageChanceToClose = useRef(null);
  const { t } = useTranslation();
  const statusOptions = [
    { value: CO_STAGE_STATUS_OPEN, label: t('Open') },
    { value: CO_STAGE_STATUS_WON, label: t('Won') },
    { value: CO_STAGE_STATUS_LOST, label: t('Lost') },
    { value: CO_STAGE_STATUS_DISQUALIFIED, label: t('Disqualified') },
  ];
  const props = validationProps(`pipeline.stages[${index}].name`);
  const percentageChanceToCloseProps = validationProps(
    `pipeline.stages[${index}].percentageChanceToClose`
  );

  const mergeRef = useCallback(
    (el) => {
      applyRef(targetPercentageChanceToClose, el);
      applyRef(percentageChanceToCloseProps.ref, el);
    },
    [percentageChanceToCloseProps.ref]
  );

  const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();

  assignFieldHandle(`stage-name-${index}`, {
    customFocus: () => {
      return target.current?.querySelector('input')?.focus();
    },
  });

  assignFieldHandle(`stage-percentageChanceToClose-${index}`, {
    customFocus: () => {
      return targetPercentageChanceToClose.current
        ?.querySelector('input')
        ?.focus();
    },
    disabled:
      !includePercentageToClose ||
      stage.status !== CO_STAGE_STATUS_OPEN ||
      useAiToUpdatePercentage,
  });

  return (
    <DragItem {...others}>
      <DragItemLayoutCols columns={3} gutter={`${gutters.spacing(6)}px`}>
        <div ref={target} {...getKeyListenersProps?.(`stage-name-${index}`)}>
          {nameTooltip}
          <EditableText
            fieldId={`stage-name-${index}`}
            ref={stageNameRef}
            sizing="dense"
            placeholder={t('Enter Stage Name')}
            value={stage.name}
            {...props}
            onChange={(name) => onStageChange(stage, { name })}
            required
            {...nameTooltipProps}
          />
          <Validation
            inModal
            hideOutOfBoundaries
            {...props.validate}
            popperConfig={{
              modifiers: {
                offset: { offset: '0, -1px' }, // Touch top of text
                ...modalConstrainPopperConfig.modifiers,
              },
            }}
            target={target.current}
          />
        </div>
        <div>
          <AlignWithEditableText>
            <SelectInline
              submitUnchanged
              popperConfig={stayInViewport}
              hoverable={false}
              inModal
              field={{ id: 'stage-status' }}
              object={{ id: index }}
              onSubmit={async ({ value: status }) => {
                await onStageChange(stage, { status });
                await new Promise((resolve) => setTimeout(resolve));
              }}
            >
              <Select
                sizing="mobile"
                value={stage.status}
                options={statusOptions}
                onChange={({ value: status }) =>
                  onStageChange(stage, { status })
                }
                style={{ top: 11 }}
                data-qa={'stage-menu'}
              />
            </SelectInline>
          </AlignWithEditableText>
        </div>
        <div>
          {includePercentageToClose &&
            stage.status === CO_STAGE_STATUS_OPEN &&
            (useAiToUpdatePercentage ? (
              <KizenTypography fontStyle="italic">
                {stage.percentageChanceToClose || 0}% (AI)
              </KizenTypography>
            ) : (
              <div
                ref={mergeRef}
                {...getKeyListenersProps?.(
                  `stage-percentageChanceToClose-${index}`
                )}
              >
                <NumberInput
                  customInput={EditablePercentage}
                  sizing="dense"
                  placeholder="0"
                  decimalScale={0}
                  required
                  value={stage.percentageChanceToClose}
                  isAllowed={
                    ({ value, floatValue }) =>
                      (!value || (floatValue >= 0 && floatValue <= 100)) && // Basic validity rules
                      !(value.startsWith('0') && value !== '0') && // Formatting rule: no leading zeros
                      !value.startsWith('-') // Formatting rule: no negative zero
                  }
                  onValueChange={({ value: percentageChanceToClose }) => {
                    onStageChange(stage, {
                      percentageChanceToClose: percentageChanceToClose || null,
                    });
                  }}
                  {...percentageChanceToCloseProps}
                />
                <Validation
                  inModal
                  hideOutOfBoundaries
                  {...percentageChanceToCloseProps.validate}
                  popperConfig={{
                    modifiers: {
                      offset: { offset: '0, -1px' }, // Touch top of text
                      ...modalConstrainPopperConfig.modifiers,
                      autoSizing: {
                        enabled: true,
                        fn(data) {
                          return data;
                        },
                      },
                    },
                  }}
                  target={targetPercentageChanceToClose.current}
                />
              </div>
            ))}
          {includePercentageToClose && stage.status === CO_STAGE_STATUS_WON && (
            <AlignWithEditableText>
              <KizenTypography as="span">100%</KizenTypography>
            </AlignWithEditableText>
          )}
          {includePercentageToClose &&
            stage.status === CO_STAGE_STATUS_LOST && (
              <AlignWithEditableText>
                <KizenTypography as="span">0%</KizenTypography>
              </AlignWithEditableText>
            )}
          {includePercentageToClose &&
            stage.status === CO_STAGE_STATUS_DISQUALIFIED && (
              <AlignWithEditableText>
                <KizenTypography as="span">0%</KizenTypography>
              </AlignWithEditableText>
            )}
          {!includePercentageToClose && <span />}
          {pipeline.stages.length > 1 ? (
            <IconButton
              title={t('Delete')}
              sizing="dense"
              color={
                pipeline.stages.length <= 1
                  ? colorsButton.iconGray.default
                  : colorsButton.iconGray
              }
              disabled={pipeline.stages.length <= 1}
              onClick={() => onClickRemove(stage)}
            >
              <IconSizing size="15px">
                <Icon icon="delete" />
              </IconSizing>
            </IconButton>
          ) : null}
        </div>
      </DragItemLayoutCols>
    </DragItem>
  );
}

DraggableItem.propTypes = {
  pipeline: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  onClickRemove: PropTypes.func.isRequired,
  onStageChange: PropTypes.func.isRequired,
};
