import { ForwardedRef, ReactNode, forwardRef } from 'react';
import styled from '@emotion/styled';
import { HTMLBlockProps } from '../../types';
import { getTypeInfo, NodeType } from '../types';
import {
  DEFAULT_ELEMENT_CONTAINER_PROPS,
  ElementContainer,
} from './ElementContainer';

type HTMLBlockContainerProps = HTMLBlockProps & {
  children: ReactNode;
  draggingType?: NodeType;
};

export const DEFAULT_CONTAINER_PROPS = {
  ...DEFAULT_ELEMENT_CONTAINER_PROPS,
  containerBackgroundColor: 'inherit',
  containerPaddingTop: '10',
  containerPaddingRight: '10',
  containerPaddingBottom: '10',
  containerPaddingLeft: '10',
};

const HTMLBlockWrapper = styled(ElementContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
  }
`;

export const HTMLBlockContainer = forwardRef(
  (
    { children, draggingType, ...rest }: HTMLBlockContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <HTMLBlockWrapper
      ref={ref}
      ignoreChildrenDrag={getTypeInfo(draggingType)?.isContent}
      {...rest}
    >
      {children}
    </HTMLBlockWrapper>
  )
);
