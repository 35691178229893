import React, { useCallback, useRef, useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from 'app/colors';
import { fontSizes } from 'app/typography';
import { gutters } from 'app/spacing';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import BaseInput from './BaseInput';
import BaseInputLayout from './BaseInputLayout';
import Validation, { useValidation } from '../Validation';
import { applyRef, validateProp } from '../props';
import { FIELD_TYPES } from 'utility/constants';
import { useTooltip } from 'components/Kizen/Tooltip';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

export const UnderlineInputLayout = styled(BaseInputLayout, {
  shouldForwardProp: (prop) => !['disabled', 'as'].includes(prop),
})`
  height: 29px;
  padding-right: ${gutters.spacing(2)}px;
  border-left: none;
  border-right: none;
  border-top: none;

  ${({ readOnly }) =>
    readOnly &&
    css`
      border-bottom: none;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${grayScale.white};
      border-color: transparent;

      &:hover {
        border-color: transparent;
      }
    `}
`;

const Input = styled(BaseInput, {
  shouldForwardProp: (prop) => !['shrink', 'as'].includes(prop),
})`
  &::placeholder,
  &::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }

  ${({ shrink }) =>
    shrink &&
    css`
      &::placeholder,
      &::-webkit-input-placeholder {
        opacity: 1;
      }
    `}
`;

const TooltipDiv = styled.div`
  position: absolute;
  margin-top: 15px;
  padding-bottom: 25px;
  top: 0;
  left: 0;
  width: 100%;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;

const HiddenText = styled.span`
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  white-space: pre;
  font-size: ${fontSizes.text};
  letter-spacing: -0.00125em;
`;

const UnderlineTextInput = React.forwardRef((props, ref) => {
  // props was spread on MaybeInputControl previously, but that causes the event handlers to fire twice
  const {
    fieldId,
    onBlur,
    onChange,
    onFocus,
    onHover,
    onKeyPress,
    placeholder: _,
    value: _1,
    'data-field-type': _2,
    'data-field-id': _3,
    onErrorShow,
    onErrorHide,
    fieldType,
    ...inputControlProps
  } = props;
  const {
    value,
    error,
    disabled,
    startAdornment,
    endAdornment,
    label,
    placeholder,
    shrink: shrinkProp,
    className,
    Input: InputProp,
    inModal,
    errorPlacement,
    readOnly,
    style, // pull out style as we don't want it passed via others into the BaseInput
    tooltipLabel,
    tooltipMaxWdith,
    ...others
  } = props;
  const { assignFieldHandle } = useKeyBoardContext();
  const currentInput = useRef();
  const numberInputRef = useRef();
  const validationRef = useRef();
  const hiddenTextRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(validationRef, el);
      applyRef(ref, el);
    },
    [ref]
  );
  const [focused, setFocused] = useState(false);
  const [validation, validationProps] = useValidation(validationRef, props);
  const [inputWidth, setInputWidth] = useState(0);
  const [inputHeight, setInputHeight] = useState(0);
  const [tooltipProps, tooltip] = useTooltip({
    label: tooltipLabel,
    maxWidth: tooltipMaxWdith,
    popperConfig: {
      modifiers: {
        preventOverflow: {
          enabled: true,
          rootBoundary: 'viewport',
        },
      },
    },
  });

  const isNumberInput =
    fieldType === FIELD_TYPES.Integer.type ||
    fieldType === FIELD_TYPES.Decimal.type ||
    fieldType === FIELD_TYPES.Money.type;

  assignFieldHandle(fieldId, {
    customFocus: () => {
      if (
        fieldType === FIELD_TYPES.Integer.type ||
        fieldType === FIELD_TYPES.Decimal.type ||
        fieldType === FIELD_TYPES.Money.type
      ) {
        numberInputRef.current?.focus();
        return numberInputRef.current;
      } else {
        currentInput.current?.focus();
        return currentInput.current;
      }
    },
    customEnter: (e) => {
      e.preventDefault();
    },
    customEscape: () => {
      if (fieldType === FIELD_TYPES.Date.type) {
        onBlur?.();
      }
    },
    customUp: () => {
      if (fieldType === FIELD_TYPES.Date.type) {
        currentInput.current.click();
      }
    },
    customDown: () => {
      if (fieldType === FIELD_TYPES.Date.type) {
        currentInput.current.click();
      }
    },
    disabled,
    fieldType,
  });

  useEffect(() => {
    if (others.forceValidation) {
      validation.onSubmission();
    }
  }, [others.forceValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // calculate width for tooltip when input is disabled
    if (tooltipLabel && hiddenTextRef.current) {
      setInputWidth(hiddenTextRef.current.offsetWidth);
      setInputHeight(hiddenTextRef.current.scrollHeight);
    }
  }, [tooltipLabel, value]);

  const shrink =
    typeof shrinkProp === 'boolean'
      ? shrinkProp
      : Boolean(
          value ||
            value === 0 ||
            focused ||
            disabled ||
            startAdornment ||
            endAdornment ||
            !label
        );
  return (
    <MaybeInputControl
      forInput
      variant="underline"
      {...inputControlProps}
      shrink={!!shrink}
    >
      <UnderlineInputLayout
        ref={mergeRef}
        className={!hasInputControl(props) && className}
        disabled={disabled}
        error={error || validation.error}
        focused={focused}
        readOnly={readOnly}
        onClick={(e) => {
          if (typeof props.onClick === 'function' && !disabled) {
            currentInput.current?.focus();
            props.onClick(e);
          }
        }}
      >
        {tooltipLabel ? tooltip : null}
        {startAdornment &&
          React.cloneElement(startAdornment, {
            variant: 'underline',
            start: true,
          })}
        <Input
          ref={currentInput}
          getInputRef={numberInputRef}
          as={InputProp}
          value={disabled ? value ?? '—' : value ?? ''}
          shrink={shrink}
          disabled={disabled}
          // The space allows placeholder fade-in to happen
          placeholder={shrink ? placeholder : ' '}
          readOnly={readOnly}
          {...tooltipProps}
          {...others} // spread others here since the handlers are destructed separately for the inputControlProps
          onChange={(ev) => {
            if (onChange) onChange(ev.target.value, ev);
          }}
          onFocus={(ev) => {
            setFocused(true);
            if (onFocus) onFocus(ev);
            if (tooltipProps?.onFocus) tooltipProps?.onFocus(ev);
          }}
          onBlur={(ev) => {
            setFocused(false);
            if (onBlur) onBlur(ev);
            if (tooltipProps.onBlur) tooltipProps.onBlur(ev);
            if (validation.onBlur) validation.onBlur(ev);
          }}
          // eslint-disable-next-line consistent-return
          onKeyPress={(ev) => {
            if (onKeyPress) onKeyPress(ev);
            if (validation.onKeyPress) return validation.onKeyPress(ev);
          }}
          {...(isNumberInput
            ? {
                onValueChange: ({ formattedValue, floatValue }, { event }) => {
                  if (value?.toString()?.charAt(0) === '-' && floatValue >= 0) {
                    if (onChange) onChange(formattedValue, event);
                  }
                },
              }
            : {})}
        />
        {tooltipLabel ? (
          <>
            <TooltipDiv
              {...tooltipProps}
              width={disabled ? inputWidth : null}
              height={inputHeight}
            />
            <HiddenText ref={hiddenTextRef}>{value}</HiddenText>
          </>
        ) : null}
        {endAdornment &&
          !disabled &&
          React.cloneElement(endAdornment, {
            variant: 'underline',
            end: true,
          })}
      </UnderlineInputLayout>
      <Validation
        inModal={inModal}
        errorPlacement={errorPlacement}
        onErrorShow={onErrorShow}
        onErrorHide={onErrorHide}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

UnderlineTextInput.displayName = 'UnderlineTextInput';

UnderlineTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  startAdornment: PropTypes.element,
  endAdornment: PropTypes.element,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  shrink: PropTypes.bool,
  readOnly: PropTypes.bool,
  Input: PropTypes.elementType,
  validate: validateProp, // Consumed by useValidate()
  inModal: PropTypes.bool,
  errorPlacement: PropTypes.string,
  forceValidation: PropTypes.number,
  onErrorShow: PropTypes.func,
  onErrorHide: PropTypes.func,
};

UnderlineTextInput.defaultProps = {
  value: undefined,
  onChange: null,
  error: null,
  disabled: null,
  startAdornment: null,
  endAdornment: null,
  label: null,
  placeholder: null,
  onFocus: null,
  onBlur: null,
  onKeyPress: null,
  shrink: null,
  readOnly: false,
  Input: undefined,
  validate: null,
  inModal: null,
  errorPlacement: undefined,
  forceValidation: 0,
};

export default UnderlineTextInput;
