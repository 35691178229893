import styled from '@emotion/styled';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
  StyledIcon,
  StyledFolder,
} from 'components/Kizen/Table/CellLineHeightStyles';

import ActionCell from 'components/Tables/ActionCell';
import ValidatedField from 'components/Kizen/ValidatedField';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { colorsButton } from 'app/colors';
import { MESSAGE_TYPES } from 'utility/constants';
import { percentageFormatter } from 'utility/numbers';
import ShortTextDisplay from 'components/Inputs/inline/LinkText/Display';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const StyledShortTextDisplay = styled(ShortTextDisplay)`
  width: ${({ width }) => parseInt(width) - 10}px;
`;

const paddingSize = {
  basic: '30px',
  small: '22px',
};

const columnSize = {
  spacer: '20px',
  name: '254px',
  subject: '256px',
  sent: '69px',
  delivered: '110px',
  opened: '110px',
  type: '50px',
  created: '150px',
  actions: '50px',
};

export const actions = {
  view: 'view',
  stats: 'stats',
};

const dummyData = { type: null };

const TableActionCell = ({ data = dummyData, isMobile = false, ...rest }) => {
  const { type } = data;
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [
      { value: actions.view, label: t('View') },
      type === 'email' ? { value: actions.stats, label: t('Stats') } : null,
    ].filter(Boolean);
  }, [t, type]);

  return type !== 'folder' ? (
    <ActionCell
      title={t('View Message')}
      menuType="absolute"
      options={options}
      data={data}
      isMobile={isMobile}
      {...rest}
    />
  ) : (
    <SizedCell data={data} isMobile={isMobile} {...rest} />
  );
};

export const getAutomationsColumns = ({
  onUpdateAutomation,
  onSelectAction,
  handleChangeDirectory,
  parentFolder,
  messagesCount,
  t,
}) => {
  const columns = [
    {
      id: 'spacer-1',
      headCell: <SizedCell width={columnSize.spacer} />,
      cell: <SizedCell width={columnSize.spacer} />,
    },
    {
      id: 'name',
      headCell: (
        <SortableHeadCell
          label={t('Name')}
          width={columnSize.name}
          padding={paddingSize.basic}
        />
      ),
      cell: <SizedCell width={columnSize.name} padding={paddingSize.basic} />,
      format: function NameCell(value, message) {
        const handleSubmit = async (data) => {
          if (data !== value) {
            await onUpdateAutomation({
              id: message.id,
              name: data,
            });
          }
        };
        return message.type === 'folder' ? (
          <StyledFolder width={columnSize.name}>
            {message?.levelUp ? (
              <StyledIcon
                icon={'level-up'}
                rotation={270}
                color={colorsButton.blue}
                onClick={() => {
                  handleChangeDirectory(parentFolder);
                }}
              />
            ) : (
              <StyledIcon
                icon="folder"
                color={colorsButton.blue}
                onClick={() => {
                  handleChangeDirectory(message);
                }}
              />
            )}
            <StyledShortTextDisplay
              width={columnSize.name}
              type="link"
              onClick={() => {
                handleChangeDirectory(message.id ? message : parentFolder);
              }}
              value={value}
              icon={false}
            />
          </StyledFolder>
        ) : (
          <ValidatedField
            onClick={() =>
              onSelectAction({ value: actions.view, label: t('View') }, message)
            }
            type="link"
            value={value}
            onSubmit={handleSubmit}
          />
        );
      },
    },
    {
      id: 'subject',
      headCell: (
        <SortableHeadCell
          label={t('Subject Line')}
          width={columnSize.subject}
          padding={paddingSize.basic}
        />
      ),
      cell: (
        <SizedCell width={columnSize.subject} padding={paddingSize.basic} />
      ),
      format: function modelCell(value) {
        return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
      },
    },
    {
      id: 'sent',
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Sent')}
          width={columnSize.sent}
          padding={paddingSize.basic}
        />
      ),
      cell: <SizedCell width={columnSize.sent} padding={paddingSize.basic} />,
      format: function activeCell(value) {
        return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
      },
    },
    {
      id: 'delivered',
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Delivered')}
          width={columnSize.delivered}
          padding={paddingSize.basic}
        />
      ),
      cell: (
        <SizedCell width={columnSize.delivered} padding={paddingSize.basic} />
      ),
      format: function activeCell(value, { sent, type }) {
        return type !== 'folder' && value ? (
          <TextEllipsisWithTooltip>
            {value} ({percentageFormatter.format(value / (sent || Infinity))})
          </TextEllipsisWithTooltip>
        ) : (
          <TextEllipsisWithTooltip />
        );
      },
    },
    {
      id: 'opened',
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Opened')}
          width={columnSize.opened}
          padding={paddingSize.basic}
        />
      ),
      cell: <SizedCell width={columnSize.opened} padding={paddingSize.basic} />,
      format: function activeCell(value, { delivered, type }) {
        return type !== 'folder' && value ? (
          <TextEllipsisWithTooltip>
            {value} (
            {percentageFormatter.format(value / (delivered || Infinity))})
          </TextEllipsisWithTooltip>
        ) : (
          <TextEllipsisWithTooltip />
        );
      },
    },
    {
      id: 'type',
      headCell: (
        <SortableHeadCell
          label={t('Type')}
          width={columnSize.numberActive}
          padding={paddingSize.basic}
        />
      ),
      cell: <SizedCell width={columnSize.type} padding={paddingSize.basic} />,
      format: function activeCell(value) {
        return (
          <TextEllipsisWithTooltip>
            {MESSAGE_TYPES[value] ? MESSAGE_TYPES[value](t) : t('Other')}
          </TextEllipsisWithTooltip>
        );
      },
    },
    {
      id: 'created',
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Date Created')}
          width={columnSize.created}
          padding={paddingSize.small}
        />
      ),
      cell: (
        <SizedCell width={columnSize.created} padding={paddingSize.small} />
      ),
      format: (created) =>
        created ? (
          <DateTimeInputInline readOnly value={created} showDateTooltip />
        ) : (
          // Show empty value: `-` for folders, since they don't really make sense yet.
          // We will have to change this special case when we add user-controlled folders... Maybe...
          <TextEllipsisWithTooltip />
        ),
    },
    {
      id: 'actions',
      headCell: (
        <SubActionHeadCell
          label={t('Actions')}
          width={columnSize.actions}
          padding={columnSize.spacer}
        />
      ),
      cell: (
        <TableActionCell
          width={columnSize.actions}
          padding={columnSize.spacer}
          onSelectAction={onSelectAction}
          t={t}
        />
      ),
    },
  ];

  return messagesCount ? columns : columns.slice(0, -1);
};
