import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { borderRadii, gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import Icon from './Icon';

const HandleIcon = styled(Icon)`
  margin-right: ${gutters.spacing(2)}px;
  width: unset;
  svg {
    color: ${grayScale.mediumDark};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${grayScale.white};
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.standard};
  padding: ${gutters.spacing(2)}px;
  outline: none;

  &:hover,
  &:active {
    ${HandleIcon} svg {
      color: ${grayScale.dark};
    }
  }

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${grayScale.light};
          && .FieldHandle,
          && .FieldHandle:hover {
            cursor: default;
            svg {
              color: ${grayScale.medium};
            }
          }
        `
      : ''}
`;

const DraggableItem = React.forwardRef(
  ({ handleProps, children, ...others }, ref) => {
    return (
      <Wrapper ref={ref} {...others}>
        <HandleIcon icon="reorder" {...handleProps} />
        {children}
      </Wrapper>
    );
  }
);

DraggableItem.propTypes = {
  handleProps: PropTypes.object,
};

DraggableItem.defaultProps = {
  handleProps: null,
};

DraggableItem.displayName = 'DraggableItem';

export default DraggableItem;
