import {
  PrimaryObjectUploadStep,
  SmartConnectorBase,
  SmartConnectorFlow,
  SmartConnectorStep,
} from './types';

// we do not support types other than spreadsheet for now
// polling and direct_api are placeholders for future development
// and can be changed in future
export const SMART_CONNECTOR_TYPES = {
  spreadsheet: 'spreadsheet',
  polling: 'polling',
  direct_api: 'direct_api',
} as const;

export const SMART_CONNECTOR_STATUSES = {
  setup: 'setup',
  operational: 'operational',
  need_attention: 'need_attention',
  inactive: 'inactive',
} as const;

export const SMART_CONNECTOR_STEPS = {
  general: 'general',
  connection: 'connection',
  variables: 'variables',
} as const;

export const PRIMARY_OBJECT_UPLOAD_STEPS = {
  matching: 'matching',
  mapping: 'mapping',
  preview: 'preview',
} as const;

export const SMARTCONNECTORS_PATHNAME = '/smartconnectors';

export const SMARTCONNECTORS_S3_SOURCES = {
  IMPORT: 'smart_connector_import',
  EXPORT: 'smart_connector_export',
} as const;

export const SQL_SCRIPT_STATUSES = {
  draft: 'draft',
  live: 'live',
} as const;

export const SQL_SCRIPT_STATES = {
  setup: 'setup',
  queued: 'queued',
  in_progress: 'in_progress',
  failed: 'failed',
  success: 'success',
} as const;

export const STEP_DATA_BY_STEP: Record<
  SmartConnectorStep,
  (keyof SmartConnectorBase)[]
> = {
  [SMART_CONNECTOR_STEPS.general]: [
    'name',
    'connector_type',
    'custom_object',
    'error_notification_email',
    'is_diff_check_enabled',
  ],
  [SMART_CONNECTOR_STEPS.connection]: [
    'sql_required',
    'sql_enabled',
    'source_file',
    'last_draft_script',
    'live_script',
  ],
  [SMART_CONNECTOR_STEPS.variables]: ['execution_variables'],
};

export const PRIMARY_OBJECT_UPLOAD_STEP_DATA_BY_STEP: Record<
  PrimaryObjectUploadStep,
  (keyof SmartConnectorFlow)[]
> = {
  [PRIMARY_OBJECT_UPLOAD_STEPS.matching]: ['loads'],
  [PRIMARY_OBJECT_UPLOAD_STEPS.mapping]: ['loads'],
  [PRIMARY_OBJECT_UPLOAD_STEPS.preview]: ['loads'],
};

export const VARIABLE_TYPES = {
  additional: 'additional',
  related_object_upload: 'related_object_upload',
  data_source: 'data_source',
} as const;

export const VARIABLE_DATA_TYPES = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  date: 'date',
  datetime: 'datetime',
  phone_number: 'phone_number',
  uuid: 'uuid',
} as const;

export const RULE_ACTIONS = {
  create_new: 'create_new',
  next_rule: 'next_rule',
  do_not_upload: 'do_not_upload',
  update_current: 'update_current',
  next_rule_ignore_previous: 'next_rule_ignore_previous',
  unarchive_and_update: 'unarchive_and_update',
  unarchive_only: 'unarchive_only',
};

export const CONFLICT_RESOLUTIONS = {
  only_update_blank: 'only_update_blank',
  only_add_options: 'only_add_options',
  overwrite: 'overwrite',
  overwrite_skip_if_blank: 'overwrite_skip_if_blank',
} as const;

export const SMART_FIELD_TYPES = {
  checkbox: 'checkbox',
  checkboxes: 'checkboxes',
  date: 'date',
  datetime: 'datetime',
  dropdown: 'dropdown',
  dynamictags: 'dynamictags',
  email: 'email',
  decimal: 'decimal',
  integer: 'integer',
  phonenumber: 'phonenumber',
  money: 'money',
  radio: 'radio',
  rating: 'rating',
  relationship: 'relationship',
  status: 'status',
  text: 'text',
  longtext: 'longtext',
  yesnomaybe: 'yesnomaybe',
  team_selector: 'team_selector',
  timezone: 'timezone',
} as const;

export const ALLOWED_SOURCE_FILE_EXTENSIONS = [
  '.csv',
  '.tsv',
  '.xls',
  '.xlsx',
  '.xlsm',
  '.xlsb',
  '.xla',
  '.xlam',
  '.ods',
];
