import { useQuery, UseQueryOptions } from 'react-query';
import ClientService from 'services/ClientService';
import { OBJECT_GROUPS } from 'queries/query-keys';
import CustomObjectsService from 'services/CustomObjectsService';

export const useObjectGroupsQuery = (
  groupOptionsId: string,
  isForCustomObject: boolean,
  options: UseQueryOptions = {}
) => {
  const enabledOptions: UseQueryOptions = {
    ...options,
    enabled: Boolean(groupOptionsId),
  };
  return useQuery({
    queryKey: [OBJECT_GROUPS.OBJECT(groupOptionsId)],
    queryFn: async () =>
      isForCustomObject
        ? groupOptionsId
          ? await CustomObjectsService.getRecordsGroups(groupOptionsId)
          : []
        : await ClientService.groups(),
    ...enabledOptions,
  });
};
