import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import { KizenTypography } from 'app/typography';

import Icon, { IconSizing } from 'components/Kizen/Icon';

const DiscrepancyWarning = styled.div`
  position: absolute;
  left: 0;
  top: ${({ topOffset }) => (topOffset ? topOffset : gutters.spacing(9))}px;

  & > i {
    float: left;
    color: ${grayScale.mediumDark};

    &:hover {
      color: ${grayScale.mediumDark};
    }
  }

  & > p {
    margin-left: ${gutters.spacing(5, -1)}px;
    font-style: italic;
    color: ${grayScale.mediumDark};
  }
`;

export const TimezoneWarning = ({ className, topOffset, children }) => (
  <DiscrepancyWarning topOffset={topOffset} className={className}>
    <IconSizing size="15px">
      <Icon icon={['fal', 'globe']} />
    </IconSizing>
    <KizenTypography type="label-small">{children}</KizenTypography>
  </DiscrepancyWarning>
);
