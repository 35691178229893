import React from 'react';
import StyledButton from './styles';
import { buttonProps, buttonDefaultProps } from './props';
import { UnstyledTextEllipseWithTooltip } from '../UnstyledTextEllipseWithTooltip';
const isText = (prop) => typeof prop === 'string';

// Base Kizen Button Component
const Button = React.forwardRef(
  (
    {
      onClick,
      preventDefault,
      noSpace,
      variant,
      color,
      children,
      disabled,
      className,
      ...props
    },
    ref
  ) => {
    const handleOnClick = (event) => {
      if (onClick) {
        if (preventDefault) {
          // TODO why is this necessary behavior? Does it relate to avoiding form submissions?
          event.preventDefault();
        }
        onClick(event);
      }
    };

    return (
      <StyledButton
        onClick={handleOnClick}
        variant={variant}
        color={color}
        noSpace={noSpace}
        disabled={disabled}
        className={`btn ${className}`}
        {...props}
        ref={ref}
      >
        {isText(children) ? (
          <UnstyledTextEllipseWithTooltip>
            {children}
          </UnstyledTextEllipseWithTooltip>
        ) : (
          <>{children}</>
        )}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';
Button.propTypes = buttonProps;
Button.defaultProps = buttonDefaultProps;

export default Button;
