import {
  call,
  takeLatest,
  put,
  all,
  select,
  debounce,
} from 'redux-saga/effects';
import CustomObjectsService from 'services/CustomObjectsService';
import TeamMemberService from 'services/TeamMemberService';
import FormService from 'services/FormService';
import { FORCE_ALL_RECORDS_SIZE, snakeToCamelCaseKeys } from 'services/helpers';
import { DEFAULT_DELAY } from 'utility/config';
import {
  buildPage as buildPageAction,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  getForms as getFormsAction,
  getFormsSuccess,
  getFormsFail,
  duplicateForm as duplicateFormAction,
  duplicateFormSuccess,
  duplicateFormFail,
  deleteForm as deleteFormAction,
  deleteFormSuccess,
  deleteFormFail,
  setPageConfig as setPageConfigAction,
  setPageConfigSuccess,
  setPageConfigFail,
  generatePageConfig,
  getFormsStart,
  getFormsFinish,
} from './forms.redux';
import { toastVariant } from 'components/ToastProvider';

const PAGE_CONFIG_KEY = 'form_list';

function* getForms(actionData) {
  try {
    yield put(getFormsStart());
    const payload = actionData && actionData.payload;
    const cancelToken = payload && payload.cancelToken;
    const pageConfig = yield select((s) => s.formsPage.pageConfig);
    const data = {
      values: {
        search: pageConfig.search,
        ordering: pageConfig.sort,
        page: pageConfig.page,
        size: pageConfig.size,
      },
      options: { cancelToken },
    };

    const [result] = yield all([
      call(FormService.getListWithoutFields, data.values, data.options),
      payload.updatePageConfig &&
        call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig),
    ]);

    yield put(
      getFormsSuccess({
        forms: snakeToCamelCaseKeys(result.results),
        formsCount: result.count,
      })
    );
  } catch (error) {
    yield put(getFormsFail(error && error.message));
  } finally {
    yield put(getFormsFinish());
  }
}

function* buildPage() {
  try {
    const [pageResponse, modelsData] = yield all([
      call(
        TeamMemberService.getPageConfig,
        PAGE_CONFIG_KEY,
        generatePageConfig()
      ),
      call(CustomObjectsService.getCustomObjectList, {
        size: FORCE_ALL_RECORDS_SIZE,
        ordering: 'object_name',
        customOnly: false,
      }),
    ]);
    yield put(
      buildPageComplete({
        pageResponse,
        models: modelsData.results,
      })
    );
    yield call(getForms, { payload: { updatePageConfig: false } });
    yield put(buildPageFinish());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* duplicateForm({ payload }) {
  try {
    const data = {
      values: {
        id: payload,
      },
    };

    yield call(FormService.duplicate, data.values);

    yield put(duplicateFormSuccess());
    yield call(getForms, { payload: { updatePageConfig: false } });
  } catch (error) {
    yield put(duplicateFormFail(error));
  }
}

function* deleteForm({ payload }) {
  try {
    const data = {
      values: {
        id: payload,
      },
    };

    yield call(FormService.remove, data.values);

    yield put(
      deleteFormSuccess({
        variant: toastVariant.SUCCESS,
        message: 'The form has been successfully deleted.',
      })
    );
    yield call(getForms, { payload: { updatePageConfig: false } });
  } catch (error) {
    yield put(
      deleteFormFail({
        variant: toastVariant.FAILURE,
        message: 'The form has not been successfully deleted.',
      })
    );
  }
}

function* setPageConfig() {
  try {
    const pageConfig = yield select((s) => s.formsPage.pageConfig);
    yield call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig);
    yield put(setPageConfigSuccess());
  } catch (error) {
    yield put(setPageConfigFail(error));
  }
}

function* formsPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield debounce(DEFAULT_DELAY, getFormsAction.type, getForms);
  yield takeLatest(duplicateFormAction.type, duplicateForm);
  yield takeLatest(deleteFormAction.type, deleteForm);
  yield takeLatest(setPageConfigAction.type, setPageConfig);
}

export default formsPage;
