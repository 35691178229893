import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale, tableHover } from 'app/colors';
import { layers } from 'app/spacing';
import { CANVAS_PADDING } from './constants';

const checkerSize = 8;
const lightCheck = grayScale.white;
const darkCheck = tableHover;

export const pageBuilderBackgroundStyle = css`
  // CSS below defines a checkered background, plus a tint on top
  background-position:
    0px 0px,
    ${checkerSize}px ${checkerSize}px;
  background-size: ${2 * checkerSize}px ${2 * checkerSize}px;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0.02)
    ),
    linear-gradient(
      45deg,
      ${darkCheck} 25%,
      transparent 25%,
      transparent 75%,
      ${darkCheck} 75%,
      ${darkCheck} 100%
    ),
    linear-gradient(
      45deg,
      ${darkCheck} 25%,
      ${lightCheck} 25%,
      ${lightCheck} 75%,
      ${darkCheck} 75%,
      ${darkCheck} 100%
    );
  padding: ${CANVAS_PADDING}px;
  padding-bottom: 50px;
`;

export const CanvasMeasureContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ showBoxShadow }) =>
    showBoxShadow &&
    css`
      background-color: rgb(255, 255, 255, 0.5);
    `}
  ${({ disabled }) =>
    // disable the page builer this way because we cannot update props on the editor
    // https://github.com/prevwong/craft.js/issues/149
    // disabling pointer events here (rather than higher up) allows for the builder to scroll while disabled
    disabled &&
    css`
      pointer-events: none;
    `}
  ${({ canResizeHeight = false }) =>
    canResizeHeight &&
    css`
      position: relative;
      align-items: center;
    `}
`;

// Ensure the gradients appear on top of the canvas content (the top one wasn't)
export const canvasScrollFadeContainerStyle = css`
  .gradient-vertical-container > :first-child,
  .gradient-vertical-container > :last-child {
    z-index: ${layers.content(1)};
  }
`;
