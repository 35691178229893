import DraggableStepCard from '../../DraggableStepCard';
import { getSendEmailFromString, getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t, business }) => {
  const { details } = step;
  return (
    t('Send Email') +
    ` '${getValueOrDeleted(details?.email?.subject, t)}' ` +
    t('from') +
    ` '${getSendEmailFromString(details, business, t)}'`
  );
};

export default function SendEmailCard({ type, step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
