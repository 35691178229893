import { NodeProps, Row, Take } from '../types';

type Props = Partial<NodeProps['Row']>;
type Children = Row['2'] | undefined;
type Args = [] | [Props] | [Children] | [Props, Children];

const half = 0.5;
const third = 1 / 3;
const quarter = 0.25;
const fifth = 0.2;
const sixth = 1 / 6;

const destructureArgs = (...args: Args): [Props] | [Props, Children] => {
  if (args[0] === undefined) return [{}];
  if (args.length === 2) return args;
  if (Array.isArray(args[0])) return [{}, args[0]];
  return [args[0]];
};

export function row1(): Take<Row, 2>;
export function row1(props: Props): Take<Row, 2>;
export function row1(children: Children): Row;
export function row1(props: Props, children: Children): Row;
export function row1(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [1] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}

export function row2(): Take<Row, 2>;
export function row2(props: Props): Take<Row, 2>;
export function row2(children: Children): Row;
export function row2(props: Props, children: Children): Row;
export function row2(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [half, half] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}

export function row3(): Take<Row, 2>;
export function row3(props: Props): Take<Row, 2>;
export function row3(children: Children): Row;
export function row3(props: Props, children: Children): Row;
export function row3(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [third, third, third] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}

export function row4(): Take<Row, 2>;
export function row4(props: Props): Take<Row, 2>;
export function row4(children: Children): Row;
export function row4(props: Props, children: Children): Row;
export function row4(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [quarter, quarter, quarter, quarter] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}

export function row5(): Take<Row, 2>;
export function row5(props: Props): Take<Row, 2>;
export function row5(children: Children): Row;
export function row5(props: Props, children: Children): Row;
export function row5(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [fifth, fifth, fifth, fifth, fifth] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}

export function row6(): Take<Row, 2>;
export function row6(props: Props): Take<Row, 2>;
export function row6(children: Children): Row;
export function row6(props: Props, children: Children): Row;
export function row6(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [sixth, sixth, sixth, sixth, sixth, sixth] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}

export function row12(): Take<Row, 2>;
export function row12(props: Props): Take<Row, 2>;
export function row12(children: Children): Row;
export function row12(props: Props, children: Children): Row;
export function row12(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [third, third * 2] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}

export function row21(): Take<Row, 2>;
export function row21(props: Props): Take<Row, 2>;
export function row21(children: Children): Row;
export function row21(props: Props, children: Children): Row;
export function row21(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [third * 2, third] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}

export function row121(): Take<Row, 2>;
export function row121(props: Props): Take<Row, 2>;
export function row121(children: Children): Row;
export function row121(props: Props, children: Children): Row;
export function row121(...args: Args): Row {
  const [props, children] = destructureArgs(...args);
  const p = { ...props, columns: [quarter, half, quarter] };
  return Array.isArray(children) ? ['Row', p, children] : ['Row', p];
}
