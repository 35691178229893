import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Button } from '@kizen/kds/Button';
import { Typography } from '@kizen/kds/Typography';
import { merge } from '@kizen/kds/util';
import { DraggableGroup, DraggableItem } from './types';
import { Dispatch, SetStateAction } from 'react';
import IconButtonMenu from '__components/Kizen/IconButtonMenu';
import { blockInternalNames } from './util';

export interface DragItemProps {
  item: DraggableItem;
  handleEdit: (id: string, type: string) => void;
  handleRemove: (id: string) => void;
  handleSetVisibility: (id: string, hidden: boolean) => void;
  dragGroup?: DraggableGroup;
  setCanStartRowDrag: Dispatch<SetStateAction<string>>;
  isDragging: boolean;
}

export const DragItem = (props: DragItemProps) => {
  const {
    item,
    handleEdit,
    handleRemove,
    dragGroup,
    setCanStartRowDrag,
    isDragging,
    handleSetVisibility,
  } = props;
  const { t } = useTranslation();

  const handleChooseItemMenuOption = useCallback(
    ({ value }: { value: string }) => {
      switch (value) {
        case 'edit': {
          return handleEdit(item.id, item.type);
        }
        case 'remove':
          return handleRemove(item.id);
        case 'visibility':
          return handleSetVisibility(item.id, !item.hidden);
      }
    },
    [handleEdit, item, handleRemove, handleSetVisibility]
  );

  return (
    <div
      className={merge(
        'kds border-1 border-solid border-border-medium rounded-[8px] p-spacer-10 py-spacer-20 flex items-center gap-x-spacer-10 h-[42px] text-font-primary',
        item.hidden ? 'bg-background-light' : 'bg-background-white'
      )}
      data-qa={`drag-item-${item.type}`}
    >
      <div
        onMouseOver={() => {
          if (!dragGroup) {
            setCanStartRowDrag('');
          }
        }}
      >
        <Button
          rightIcon="action-drag-handle"
          variant="text"
          color="tertiary"
          rightIconSettings={{
            size: 'xl',
            tooltipEnabled: !isDragging,
          }}
          size="small"
          showTooltip={false}
        />
      </div>
      <div className="min-w-0">
        <Typography truncate modalCompatability>
          {item.internalName || blockInternalNames[item.type](t)}
        </Typography>
      </div>
      <div className="ml-auto">
        <IconButtonMenu
          options={[
            {
              value: 'edit',
              label: t('Edit'),
            },
            {
              value: 'remove',
              label: t('Delete'),
            },
            {
              value: 'visibility',
              label: item.hidden ? t('Show') : t('Hide'),
            },
          ]}
          overlay
          className="w-auto"
          onChange={handleChooseItemMenuOption}
          qa={{
            action: 'open-settings',
            type: 'icon-button',
          }}
        >
          <Button
            rightIcon="action-options-horizontal"
            variant="text"
            color="tertiary"
            rightIconSettings={{
              size: 'lg',
            }}
            qa={{
              action: 'open-settings',
              type: 'button',
            }}
          />
        </IconButtonMenu>
      </div>
    </div>
  );
};
