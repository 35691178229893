import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import Button from 'components/Button';

const getButtonColor = (color) =>
  colorsButton[color] ? colorsButton[color].hover : color;

const StyledButton = styled(Button)`
  flex: ${({ sizing }) => (!sizing ? 1 : 'none')};
  margin: 0;
  background-color: ${({ color, variant }) =>
    variant !== 'default' ? grayScale.white : getButtonColor(color)};
  border-color: ${({ color }) => getButtonColor(color)};
  border-radius: 0;
  &:disabled {
    background-color: ${({ variant, isActive }) =>
      variant !== 'default' && !isActive && grayScale.white};
  }
  &:hover {
    color: ${({ color, disabled, variant }) =>
      variant !== 'default' && !disabled && getButtonColor(color)};
    background-color: ${({ variant }) =>
      variant !== 'default' && grayScale.white};
  }
  border-width: 1px;
  ${({ onlyTopRadius, singleOption }) =>
    singleOption
      ? css`
          border-radius: ${onlyTopRadius ? '8px 8px 0 0' : '8px'};
        `
      : css`
          &:first-child {
            border-radius: ${onlyTopRadius ? `8px 0 0 0` : `8px 0 0 8px`};
          }
          &:last-child {
            border-radius: ${onlyTopRadius ? `0 8px 0 0` : `0 8px 8px 0`};
          }
          &:not(:first-child) {
            border-left-width: 0px;
          }
          &:not(:last-child) {
            border-right-width: 1px;
          }
        `};
`;

export const ToggleContainer = styled.div`
  width: ${({ sizing }) => (!sizing ? '100%' : 'auto')};
  display: inline-flex;
`;

export default StyledButton;
