import { useCallback, useRef, useState, forwardRef } from 'react';
import styled from '@emotion/styled';
import Overlay from 'react-bootstrap/Overlay';
import BootstrapTooltip from 'react-bootstrap/Tooltip';
import { grayScale, shadowLight } from 'app/colors';
import { breakpoints } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import { layers } from 'app/spacing';

export const ICON_BUTTON_DELAY = 500;

const BaseTooltip = styled(BootstrapTooltip)`
  pointer-events: none; // When it overlaps the target, don't let loss of hover cause flicker
  padding: 6px 0;
  &.show {
    opacity: 1;
    z-index: ${layers.toast};
  }

  @media (max-width: ${breakpoints.sm}px) {
    width: auto;
    max-width: 99%;
  }

  .tooltip-inner {
    padding: 10px;
    background-color: ${grayScale.dark};
    border-radius: 4px;
  }
  .arrow {
    width: 12px;
    height: 6px;
    &:before {
      border-top-color: ${grayScale.dark};
      border-width: 6px 6px 0 6px;
    }
  }
  &.bs-tooltip-top .arrow:before {
    border-top-color: ${grayScale.dark};
    border-width: 6px 6px 0 6px;
  }
  &.bs-tooltip-bottom .arrow:before {
    border-bottom-color: ${grayScale.dark};
    border-width: 0 6px 6px 6px;
  }

  // reset sizes and reset border top color
  &.bs-tooltip-right,
  &.bs-tooltip-left {
    padding: 0 6px;
    .arrow {
      width: 6px;
      height: 12px;
    }
    .arrow:before {
      border-top-color: transparent;
    }
  }

  &.bs-tooltip-left .arrow:before {
    border-left-color: ${grayScale.dark};
    border-width: 6px 0 6px 6px;
  }
  &.bs-tooltip-right .arrow:before {
    border-right-color: ${grayScale.dark};
    border-width: 6px 6px 6px 0;
  }
`;

const Tooltip = forwardRef(({ label = null, children, ...others }, ref) => {
  return (
    <BaseTooltip ref={ref} {...others}>
      {label && (
        <KizenTypography size="small" color={grayScale.white}>
          {label}
        </KizenTypography>
      )}
      {children}
    </BaseTooltip>
  );
});

Tooltip.displayName = 'Tooltip';

export default Tooltip;

const defaultPopperConfig = {
  modifiers: {
    preventOverflow: {
      enabled: true,
      boundariesElement: 'viewport',
    },
  },
};

export const OverlayTooltip = styled(Tooltip)`
  .tooltip-inner {
    max-width: ${(props) => props.maxWidth || 500}px;
    ${(props) =>
      props && props.tooltipInner && props.tooltipInner.disableMediaWidth
        ? ''
        : `
        @media (max-width: ${Math.round(500 / 0.9)}px) {
          max-width: 90%;
        }
      `}
    margin: 0 auto;
    display: flex;
    max-height: calc(4 * 1.1em + 2 * 10px); // 4 lines + padding
    ${shadowLight}
    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      white-space: pre-line;
    }
  }
`;

const defaultPredicate = () => true;

export const useTooltip = function useTooltip(
  { tooltipLabel, delay = 0, label, popperConfig = {}, ...props } = {},
  predicate = defaultPredicate
) {
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const timer = useRef(null);
  const hiddenRef = useRef(true);

  const handleOn = useCallback(
    (ev) => {
      hiddenRef.current = false;
      const { currentTarget: el } = ev;
      if (el) {
        timer.current = setTimeout(() => {
          if (!hiddenRef.current) {
            const showTooltip = predicate(el) || predicate(el.lastChild);
            if (tooltipLabel || showTooltip) {
              const labelBase = showTooltip
                ? tooltipLabel
                  ? `${tooltipLabel}: `
                  : ''
                : tooltipLabel;
              setTooltipInfo({
                target: el,
                label: `${labelBase}${
                  showTooltip ? el.value || el.textContent : ''
                }`,
              });
            }
          }
        }, delay);
      }
    },
    [delay, predicate, tooltipLabel]
  );

  const handleOff = useCallback(() => {
    hiddenRef.current = true;
    setTooltipInfo(null);
    clearTimeout(timer.current);
  }, []);

  return [
    {
      onFocus: handleOn,
      onBlur: handleOff,
      onMouseEnter: handleOn,
      onMouseLeave: handleOff,
    },
    tooltipInfo && (
      <Overlay
        show
        placement="top"
        target={tooltipInfo.target}
        {...props}
        popperConfig={{ ...defaultPopperConfig, ...popperConfig }}
      >
        <OverlayTooltip label={label || tooltipInfo.label} {...props} />
      </Overlay>
    ),
    handleOff,
  ];
};

export const truncationPredicates = {
  x: (el) => el?.offsetWidth < el?.scrollWidth,
  y: (el) => el?.offsetHeight < el?.scrollHeight,
};

export const useTruncationTooltip = function useTruncationTooltip(
  props,
  axis = 'x'
) {
  return useTooltip(props, truncationPredicates[axis]);
};
