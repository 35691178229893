import { ReactElement, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getButtonClassNames } from '@kizen/kds/Button';
import { Icon } from '@kizen/kds/Icon';
import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';
import { merge } from '@kizen/kds/util';
import { useBreakpoint, breakpoints } from 'app/spacing';
import { useTooltip } from 'components/Kizen/Tooltip';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';

type ScrollTopButtonProps = {
  scrollRef: RefObject<HTMLElement>;
  threshold?: number;
};

const defaultScrollRef = { current: document.documentElement };

export const ScrollTopButton = ({
  scrollRef = defaultScrollRef,
  threshold = 600,
}: ScrollTopButtonProps) => {
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState<boolean>(false);

  // TODO: remove this workaround once KDS tooltips are ready
  const [tooltipProps, tooltip] = useTooltip({
    label: t('Scroll to Top'),
    tooltipLabel: undefined,
    delay: 500,
    popperConfig: {
      modifiers: {
        enabled: true,
        boundariesElement: 'viewport',
      },
    },
  });

  const onClick = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (scrollRef?.current) {
      const onScroll = () => {
        if (scrollRef.current) {
          const { scrollTop } = scrollRef.current;
          setShowButton(scrollTop >= threshold);
        }
      };
      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [scrollRef, threshold]);

  const isMobile = useBreakpoint(breakpoints.md);
  const preReleaseFeatures = usePreReleaseFeatures();

  const positionClassNames = 'fixed bottom-[10px] right-[10px] z-scrollTop';
  const geometryClassNames =
    'h-[30px] w-[30px] md:w-fit md:px-[10px] flex justify-center items-center';
  const borderClassNames =
    'border-1 bg-white rounded-full md:rounded-md border-border-medium hover:border-button-secondary-hover';
  const colorClassNames = getButtonClassNames('secondary', 'text', true);

  return (
    <div
      className={merge(
        positionClassNames,
        'transition-opacity duration-500',
        preReleaseFeatures && 'bottom-[50px]',
        showButton ? 'opacity-100' : 'opacity-0 pointer-events-none'
      )}
    >
      <div
        {...tooltipProps}
        className={merge(
          geometryClassNames,
          borderClassNames,
          colorClassNames,
          'cursor-pointer transition'
        )}
        onClick={onClick}
      >
        {showButton ? (tooltip as unknown as ReactElement) : null}
        {isMobile ? null : (
          <>
            <Typography
              size="md"
              weight="regular"
              variant="body"
              className="w-full"
            >
              {t('Top')}
            </Typography>

            <Spacer size={10} mode="vertical" />
          </>
        )}
        <Icon icon="action-scroll-top" size="md" className="align-middle" />
      </div>
    </div>
  );
};
