import { isUrlConfig, isUrlConfigArray } from '@kizen/filters/checks';
import { type FilterVars, convert } from '@kizen/filters/convert';
import type { Metadata, UrlConfig } from '@kizen/filters/types';
import type { FilterSet } from '@kizen/filters/filter-sets';
import { transform } from '@kizen/filters/next';
import { type FilterConfig, loadFilterConfig } from '@kizen/filters/load';
import { get } from 'lodash';
import { QueryClient } from 'react-query';
import AxiosService from 'services/AxiosService';
import { FILTERS } from 'queries/query-keys';
import { QUICK_FILTERS_IN_GROUPS } from 'store/utilities';
import { isContactTagsField } from 'checks/fields';

const methods = { GET: 'get', POST: 'post' };

type ClientModelLike = {
  id: string;
  name: 'client_client';
  objectType: 'standard';
  undeletableFields: {
    tags: { id: string };
  };
};

type ObjectModelLike = {
  id: string;
  name: string;
  objectType: 'standard' | 'pipeline';
};

const isClientModel = (obj: any): obj is ClientModelLike => {
  return obj && typeof obj === 'object' && obj.name === 'client_client';
};

export type GetFilterVariableOpts = {
  access?: any;
  clientTagId?: string;
  fieldSettingsSearch?: boolean;
};

/**
 * @remarks opts.access should usually be treated as required. You may choose to omit it if you only
 * care to resolve description values or something you know is not dependent on permissions. If the
 * model is _not_ the contact model, `opts.clientTagId` should be provided.
 */
export function getFilterVariables(
  model: ObjectModelLike | ClientModelLike,
  opts?: GetFilterVariableOpts
): [string, any][] {
  const result: [string, any][] = [
    ['custom_object_id', model.id],
    [
      'object_type',
      model.name === 'client_client' ? 'client_client' : model.objectType,
    ],
    ['fields_settings_search', opts?.fieldSettingsSearch ?? false],
  ];

  if (opts?.access) {
    result.push(['access', opts.access]);
  }

  if (isClientModel(model)) {
    result.push(['client_tag_field_id', model.undeletableFields.tags.id]);
  } else if (opts?.clientTagId) {
    result.push(['client_tag_field_id', opts.clientTagId]);
  }

  return result;
}

export const needsConversion = (config: FilterConfig) => {
  return config.query
    .flatMap((q) => q.filters)
    .some((f) => f.view_model === undefined);
};

const convertConfig = (config: FilterConfig, variables: FilterVars) => {
  return {
    ...config,
    query: config.query.map((q) => ({
      ...q,
      filters: q.filters.map((f) => ({
        ...f,
        view_model: convert(f, variables),
      })),
    })),
  };
};

export const doFilterSetsHaveError = (
  filterSets: FilterSet | FilterSet[] | { filters: null }
) => {
  const sets = Array.isArray(filterSets) ? filterSets : [filterSets];

  return sets.reduce((acc, set) => {
    if (acc) {
      return acc;
    }
    if (set.filters === null) {
      return false;
    }
    return set.filters.some((filter) => {
      return [...filter].some(([_step, { value }]) => Boolean(value?.error));
    });
  }, false);
};

/**
 * Will convert a filter request object to FilterSet with instantiated
 * Filter from @kizen/filters.
 *
 * @param metadata - filter metadata
 * @param config - filter request object. Keys MUST be snake case.
 * @param variables
 */
export const loadSavedFilter = async (
  metadata: Metadata,
  config: FilterConfig,
  variables: FilterVars,
  queryClient?: QueryClient
) => {
  const fetcher = queryClient
    ? (urlConfig: UrlConfig) =>
        fetchUrlConfigWithReactQuery(queryClient, urlConfig)
    : fetchUrlConfigWithAxios;

  return needsConversion(config)
    ? loadFilterConfig(
        fetcher,
        convertConfig(config, variables),
        metadata,
        Object.entries(variables)
      )
    : loadFilterConfig(fetcher, config, metadata, Object.entries(variables));
};

/**
 * First constructs a filter payload object from the query data and then converts it
 * to filter step data just like a saved v1 filter payload would be.
 *
 * @param metadata - filter metadata
 * @param query - filter data from a URL query string parameter
 * @param variables - Filter class variables (object_type, custom_object_id)
 * @returns
 */
export const loadFilterFromUrlParam = async (
  metadata: Metadata,
  query: any,
  variables: FilterVars
) => {
  let filter = null;

  if (query.queryParam === 'tag') {
    filter = {
      type: 'tags',
      subtype: 'tag',
      condition: 'has',
      ids: [query.id],
    };
  } else if (query.queryParam === 'dynamictag') {
    filter = {
      type: 'fields',
      subtype: 'custom',
      meta: JSON.stringify(query.meta),
      field: `custom::${query.field}`,
      condition: 'has',
      value: [query.value],
    };
  } else if (query.queryParam === 'titles') {
    filter = {
      type: 'fields',
      subtype: 'non_custom',
      meta: JSON.stringify(query.meta),
      field: 'titles',
      condition: 'has',
      value: query.value,
    };
  } else if (['automations', 'form', 'survey'].includes(query.queryParam)) {
    filter = query;
  }

  if (filter) {
    const config: FilterConfig = {
      and: false,
      query: [{ and: false, filters: [filter], id: 'query-0' }],
    };
    return loadFilterConfig(
      fetchUrlConfigWithAxios,
      convertConfig(config, variables),
      metadata,
      Object.entries(variables)
    );
  }

  return null;
};

export const getByUrlConfig = async (
  axios: any,
  config: UrlConfig & { ignore_result_path?: boolean }
) => {
  const {
    url,
    method,
    body,
    params,
    result_path,
    result_transform,
    ignore_result_path,
  } = config;
  const { data } = await (method === 'POST'
    ? axios[methods[method!]](url, body, { params, skipErrorBoundary: true })
    : axios[methods[method!]](url, { params, skipErrorBoundary: true }));

  // Important: ignore_result_path is not a standard value set in the metadata.
  // It was added in a data migration for the filter description changes
  // https://kizen.atlassian.net/browse/KZN-9131
  if (ignore_result_path) {
    return data;
  }

  const resultPathValue =
    Array.isArray(result_path) && result_path.length
      ? get(data, result_path)
      : data;

  if (result_transform) {
    return transform(result_transform, {
      ...config,
      result_path: resultPathValue,
    });
  }

  return resultPathValue;
};

export const fetchUrlConfigWithAxios = (config: UrlConfig) => {
  return getByUrlConfig(AxiosService, config);
};

export const fetchUrlConfigWithReactQuery = (
  queryClient: QueryClient,
  config: UrlConfig
) => {
  const { method, url, body, params, result_path, result_transform } = config;
  return queryClient.fetchQuery({
    queryKey: FILTERS.QUERY({
      method,
      url,
      body,
      params,
      result_path,
      result_transform,
    }),
    queryFn: () => getByUrlConfig(AxiosService, config),
    retry: false,
  });
};

export const prefetchAllUrlConfigs = (
  queryClient: QueryClient,
  filterConfig: FilterConfig
) => {
  for (const set of filterConfig.query) {
    for (const filter of set.filters) {
      if (Array.isArray(filter.view_model)) {
        for (const step of filter.view_model) {
          if (isUrlConfig(step[1])) {
            prefetchUrlConfig(queryClient, step[1]);
          }
          if (isUrlConfigArray(step[1])) {
            for (const config of step[1]) {
              prefetchUrlConfig(queryClient, config);
            }
          }
        }
      }
    }
  }
};

export const prefetchUrlConfig = (
  queryClient: QueryClient,
  config: UrlConfig
) => {
  const { method, url, body, params, result_path, result_transform } = config;

  queryClient.prefetchQuery(
    FILTERS.QUERY({ method, url, body, params, result_path, result_transform }),
    () => {
      return getByUrlConfig(AxiosService, config);
    }
  );
};

enum FIELD_TYPES {
  checkbox = 'checkbox',
  checkboxes = 'checkboxes',
  date = 'date',
  datetime = 'datetime',
  dropdown = 'dropdown',
  dynamictags = 'dynamictags',
  email = 'email',
  files = 'files',
  longtext = 'longtext',
  decimal = 'decimal',
  integer = 'integer',
  phonenumber = 'phonenumber',
  money = 'money',
  radio = 'radio',
  rating = 'rating',
  relationship = 'relationship',
  status = 'status',
  text = 'text',
  yesnomaybe = 'yesnomaybe',
  team_selector = 'team_selector',
  timezone = 'timezone',
}

type FieldType = keyof typeof FIELD_TYPES;

type FieldLike = {
  id: string;
  name: string;
  fieldType: FieldType;
  isDefault: boolean;
};

type QuickFilterConfig = {
  id: string;
  fieldId: string;
  label: string;
  relatedObjectField?: FieldLike;
  relatedObjectFieldId?: string;
}[];

type ModelLike = {
  id?: string;
  quickFilteringEnabled?: boolean;
};

type QuickFilter = {
  fieldId: string;
  value: string | string[] | boolean;
};

type QuickFilters = { [k: string]: QuickFilter };

const getCondition = (fieldType: FieldType): string => {
  switch (fieldType) {
    case FIELD_TYPES.checkbox:
      return '=';
    case FIELD_TYPES.dynamictags:
    case FIELD_TYPES.checkboxes:
      return 'has_any';
    case FIELD_TYPES.decimal:
    case FIELD_TYPES.integer:
    case FIELD_TYPES.money:
    case FIELD_TYPES.date:
    case FIELD_TYPES.datetime:
      return 'between';
    case FIELD_TYPES.text:
      return 'contains';
    default:
      return 'is_any_of';
  }
};

const getQuickFilterPayload = (field: FieldLike, value: any) => {
  const condition = getCondition(field.fieldType);
  if (isContactTagsField(field)) {
    return {
      type: 'tags',
      subtype: 'tag',
      condition,
      ids: value,
    };
  } else {
    return {
      type: field.isDefault ? 'fields' : 'fields_v2',
      field: field.isDefault ? field.name : `custom::${field.id}`,
      value: value,
      subtype: field.isDefault ? 'non_custom' : 'custom',
      condition,
    };
  }
};

export const buildQuickFiltersQuery = (
  fields: FieldLike[] = [],
  quickFilters: QuickFilters = {},
  quickFilterSettings: QuickFilterConfig
) => {
  return (quickFilterSettings || []).reduce<any[]>(
    (collect, { fieldId, relatedObjectFieldId, relatedObjectField, id }) => {
      const field = fields.find(({ id }) => id === fieldId);
      if (field && quickFilters[id]?.value !== undefined) {
        if (relatedObjectFieldId) {
          collect.push({
            field_id: fieldId,
            type: 'related_object',
            ...(relatedObjectFieldId === QUICK_FILTERS_IN_GROUPS
              ? {
                  condition: 'in_group_ids',
                  subtype: 'related_object_group',
                  group_ids: quickFilters[id].value,
                }
              : {
                  condition: 'custom_filter',
                  subtype: 'related_object_filter',
                  next_class_key: 'fields',
                  value: {
                    and: true,
                    query: [
                      {
                        and: true,
                        filters: [
                          getQuickFilterPayload(
                            relatedObjectField!,
                            quickFilters[id].value
                          ),
                        ],
                      },
                    ],
                  },
                }),
          });
        } else {
          collect.push(getQuickFilterPayload(field, quickFilters[id].value));
        }
      }
      return collect;
    },
    []
  );
};

export const buildPageFilterQuery = (
  filterObject: FilterConfig,
  quickFilters: QuickFilters = {},
  fields: FieldLike[] = [],
  model: ModelLike = {},
  quickFilterSettings: QuickFilterConfig
) => {
  let criteria = null;
  let isComplexFilters = false;

  if (filterObject?.query) {
    criteria = filterObject;
  }

  if (
    model?.quickFilteringEnabled &&
    quickFilterSettings?.length &&
    Object.values(quickFilters).length
  ) {
    isComplexFilters = true;
    criteria = {
      and: true,
      query: [
        {
          and: null,
          query: [
            {
              and: true,
              filters: buildQuickFiltersQuery(
                fields,
                quickFilters,
                quickFilterSettings
              ),
            },
          ],
        },
        ...(criteria ? [criteria] : []),
      ],
    };
  }
  return { criteria, isComplexFilters };
};
