import useGenericAccessRequestResponse from 'components/AccessRequests/useGenericAccessRequestReponse';
import useAccessRequest from 'components/Sharing/hooks/useAccessRequest';
import { useTranslation } from 'react-i18next';

const useRespondToAccessRequest = (refetch, objectId) => {
  const { t } = useTranslation();

  const respond = useGenericAccessRequestResponse({
    refetch,
    entitySingular: t('chart group'),
    onSettled: (history) =>
      history.replace(`/custom-objects/${objectId}/charts/settings/mine`),
  });

  useAccessRequest(respond);
};

export default useRespondToAccessRequest;
