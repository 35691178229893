import React from 'react';
import {
  SubNavigationNavBar,
  SmallSubNavigationNavBar,
} from './SubNavigationNavBar';
import { AltSubNavigationNavLink } from './AltSubNavigationNavItem';
import { SubNavigationNavLink } from './SubNavigationNavItem';
import {
  AltSubNavigationNavBar,
  AltSmallSubNavigationNavBar,
} from './AltSubNavigationNavBar';
import { SubNavigationProps, SubNavigationDefaultProps } from './props';

const SubNavigation = ({ small, routes, id, params, ...props }) => {
  const SubNavBar = small ? SmallSubNavigationNavBar : SubNavigationNavBar;
  return (
    <SubNavBar id={id} {...props}>
      {Object.entries(routes).map(([key, route], index, arr) => (
        <React.Fragment key={index}>
          <SubNavigationNavLink
            numberOfLinks={arr.length}
            isLast={index === arr.length - 1}
            params={params}
            small={small}
            route={route}
            key={key}
          />
        </React.Fragment>
      ))}
    </SubNavBar>
  );
};

export const AltSubNavigation = ({ small, routes, id, params, ...props }) => {
  const SubNavBar = small
    ? AltSmallSubNavigationNavBar
    : AltSubNavigationNavBar;

  return (
    <SubNavBar className="alt" id={id} {...props}>
      {Object.entries(routes).map(([key, route], index, arr) => (
        <React.Fragment key={index}>
          <AltSubNavigationNavLink
            className={
              index === 0 && arr.length === 3 ? 'alt firstOfThree' : 'alt'
            }
            isLast={index === arr.length - 1}
            numberOfLinks={arr.length}
            key={key}
            route={route}
            small={small}
            params={params}
          />
        </React.Fragment>
      ))}
    </SubNavBar>
  );
};

export default SubNavigation;

SubNavigation.propTypes = SubNavigationProps;
AltSubNavigation.propTypes = SubNavigationProps;
SubNavigation.defaultProps = SubNavigationDefaultProps;
AltSubNavigation.defaultProps = SubNavigationDefaultProps;
