import React, { useState, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import ct from 'countries-and-timezones';
import { format, isMatch } from 'date-fns2';
import { KizenTypography } from '../../../app/typography';
import { getScheduledTimezoneAdjusted } from '../../../app/timezone';
import { borderRadii, gutters } from '../../../app/spacing';
import { colorsButton, grayScale } from '../../../app/colors';
import StylePassthrough from '../../Kizen/StylePassthrough';
import { buildTimeIntervals } from '../../../utility/datetime';
import DatePickerTextInput from '../TextInput/presets/DatePicker';
import { MenuList, Menu, MIN_DATE_LENGTH } from '../DateInput';
import useField from '../../../hooks/useField';
import Icon, { IconSizing } from '../../Kizen/Icon';
import IconAdornment from '../Adornments/IconAdornment';
import { getDataQAForInput } from '../helpers';
import {
  MaybeInputControl,
  getNonInputControlProps,
  hasInputControl,
} from '../InputControl';
import Select from '../Select';
import {
  Control as CustomControl,
  Menu as CustomMenu,
  TrackingMenuList,
} from '../Select/customize';
import { applyRef } from '../props';
import { useTranslation } from 'react-i18next';

export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';
export const DATE_DISPLAY_FORMAT_NEW = 'MM/dd/yyyy';
export const TIME_DISPLAY_FORMAT = 'h:mm a';
export const TIME_VALUE_FORMAT = 'HH:mm';
const emptyData = '—';

const split = (list, predicate) => {
  const inItems = [];
  const outItems = [];
  list.forEach((item) => {
    if (predicate(item)) {
      inItems.push(item);
    } else {
      outItems.push(item);
    }
  });
  return [inItems, outItems];
};

export const createOptionFromDatetime = (date) => ({
  value: format(date, TIME_VALUE_FORMAT),
  label: format(date, 'h:mm a'),
});

export const timeOptions = (() =>
  buildTimeIntervals(15, createOptionFromDatetime))();

export const getDateOption = (value, isDate) => {
  if (
    value &&
    typeof value === 'object' &&
    'date' in value &&
    'value' in value &&
    'label' in value
  ) {
    // It's already a Select-style option
    return value;
  }
  // we always use UTC for DateInput. Agreement with BE developers
  if (!value) {
    return {
      date: new Date(),
      value: '',
      label: '',
    };
  }

  const date = value instanceof Date ? value : new Date(value);

  if (isNaN(date.getTime())) {
    return {
      date: new Date(),
      value: '',
      label: '',
    };
  }

  return (
    date && {
      date,
      value: isDate ? date.toISOString() : format(date, TIME_VALUE_FORMAT),
      label: format(
        date,
        isDate ? DATE_DISPLAY_FORMAT_NEW : TIME_DISPLAY_FORMAT
      ),
    }
  );
};

const StyledKizenTypography = styled(KizenTypography)`
  margin-top: ${gutters.spacing(1, -1)}px;
`;

export function DateTimeReadOnly({ value, ...others }) {
  const display = useMemo(() => {
    const date = value && new Date(value);
    return (
      date &&
      `${format(date, DATE_DISPLAY_FORMAT_NEW)} at ${format(
        date,
        TIME_DISPLAY_FORMAT
      )}`
    );
  }, [value]);
  return (
    <StyledKizenTypography {...others}>
      {display || emptyData}
    </StyledKizenTypography>
  );
}

const StyledMaybeInputControl = styled(MaybeInputControl)`
  ${({ variant }) =>
    variant === 'underline' &&
    css`
      width: 173px;
    `}
  position: relative;
`;

const ControlDelimiter = styled.div`
  width: 1px;
  background: ${({ color, error }) => (error ? colorsButton.red.hover : color)};
  height: 36px;
`;

const DateTimeLayout = styled.div`
  position: relative;
  display: flex;

  ${({ variant }) =>
    variant !== 'underline'
      ? css`
          & > div:first-child,
          & > div:last-child {
            width: calc((100% - 1px) / 2);
          }
        `
      : css`
          & > div:first-child {
            width: 92px;
          }
          & > div:last-child {
            width: 82px;
          }
        `}
`;

const StyledSelect = styled(Select)`
  ${({ menuInline }) =>
    menuInline
      ? css`
          position: relative;
        `
      : css`
          position: absolute;
          left: 0;
          right: 0;
          top: 37px;
        `}
`;

const DateControlStyles = styled(StylePassthrough)`
  ${({ selectProps: { variant } }) =>
    variant !== 'underline'
      ? css`
          && {
            border-top-left-radius: ${borderRadii.small};
            border-top-right-radius: 0;
            border-bottom-left-radius: ${borderRadii.small};
            border-bottom-right-radius: 0;
            border-right: none;
          }
        `
      : css`
          && {
            padding-right: 0;
          }

          i {
            margin-left: ${gutters.spacing(2, -2)}px;

            svg {
              transform: none !important;
              width: 9px !important;
            }
          }
        `}
`;

const StyledDatePickerTextInput = styled(DatePickerTextInput)`
  && {
    ${({ isShowMenu, error, color }) =>
      isShowMenu &&
      !error &&
      css`
        border-color: ${color};
      `}
  }
`;

const DateComponent = styled.div`
  position: relative;
`;

const DateControl = (props) => {
  const {
    selectProps: { handleMouseEnter, handleMouseLeave },
  } = props;

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <DateControlStyles {...props}>
        <CustomControl {...props} isFocused={false} />
      </DateControlStyles>
    </div>
  );
};

const DateWrapper = (props) => {
  const {
    selectProps: { handleMouseEnter, handleMouseLeave, field },
    measuredRef,
    onBlur,
    value,
    onChange,
    onClick,
    placeholder,
    variant,
    disabled,
    color,
    error,
    isShowMenu,
    validate,
    errorCallback,
    validateOnFocus,
  } = props;

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <DateControlStyles {...props}>
        <StyledDatePickerTextInput
          ref={measuredRef}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          onClick={onClick}
          placeholder={placeholder}
          variant={variant}
          disabled={disabled}
          color={color}
          error={error}
          focus={false}
          isShowMenu={isShowMenu}
          validate={validate}
          errorCallback={errorCallback}
          validateOnFocus={validateOnFocus}
          {...getDataQAForInput('date-input', field?.fieldType)}
        />
      </DateControlStyles>
    </div>
  );
};

const DateMenu = styled(Menu)`
  ${({ selectProps: { menuInline } }) =>
    menuInline &&
    css`
      top: 0;
    `}
`;

const TimeControlStyles = styled(StylePassthrough)`
  ${({ selectProps: { variant } }) =>
    variant !== 'underline'
      ? css`
          && {
            border-left: none;
            border-top-left-radius: 0;
            border-top-right-radius: ${borderRadii.small};
            border-bottom-left-radius: 0;
          }
        `
      : css`
          && {
            padding-left: ${gutters.spacing(2, -2)}px;
            padding-right: 0;
          }

          div > div {
            overflow: visible;
            margin-left: ${gutters.spacing(1, -4)}px;
          }

          i {
            margin-left: ${gutters.spacing(2, -3)}px;

            svg {
              width: 10px !important;
            }
          }
        `}
}
`;

const TimeControl = (props) => {
  const {
    selectProps: { handleMouseEnter, handleMouseLeave, handleClick, field },
  } = props;

  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...getDataQAForInput(field?.id, 'datetime-time-select')}
    >
      <TimeControlStyles {...props}>
        <CustomControl {...props} isFocused={false} />
      </TimeControlStyles>
    </div>
  );
};

const DiscrepancyWarning = styled.div`
  position: absolute;
  left: 0;
  top: ${gutters.spacing(9)}px;

  & > i {
    float: left;
    color: ${grayScale.mediumDark};

    &:hover {
      color: ${grayScale.mediumDark};
    }
  }

  & > p {
    margin-left: ${gutters.spacing(5, -1)}px;
    font-style: italic;
    color: ${grayScale.mediumDark};
  }
`;

const TimeMenu = styled(CustomMenu)`
  ${({ selectProps: { variant } }) =>
    variant === 'underline'
      ? css`
          li {
            padding-left: ${gutters.spacing(2)}px;
            padding-right: ${gutters.spacing(1)}px;
          }
        `
      : css`
          width: calc(100% + 1px);
          left: -1px;
        `}

  ${({ selectProps: { menuInline } }) =>
    menuInline &&
    css`
      top: 0;
    `}

  ${({ selectProps: { menuInline, rightPosition } }) => {
    if (rightPosition && menuInline) {
      return css`
        float: right;
      `;
    }
    if (rightPosition && !menuInline) {
      return css`
        right: 0;
      `;
    }
    return '';
  }}
`;

const DateTimeInput = React.forwardRef((props, ref) => {
  const {
    className,
    value,
    placeholderDate,
    placeholderTime,
    error,
    variant,
    disabled,
    onChange,
    menuLeftButton,
    menuRightButton,
    isTimeSearchable = false,
    businessTimezoneForDiscrepancyWarning,
  } = props;

  const { t } = useTranslation();
  const date = useMemo(() => getDateOption(value, true), [value]);
  const time = useMemo(() => getDateOption(value, false), [value]);
  let isMatchBusinessAndLocalTime = true;
  if (businessTimezoneForDiscrepancyWarning) {
    const currentTimeOffset = ct.getTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    ).utcOffset;
    const businessTimeOffset = ct.getTimezone(
      businessTimezoneForDiscrepancyWarning
    ).utcOffset;

    isMatchBusinessAndLocalTime = currentTimeOffset === businessTimeOffset;
  }
  const outlineVariant = variant === 'outline';

  const [rightPosition, setRightPosition] = useState(false);
  const [color, setColor] = useState(grayScale.medium);
  const [dateColor, setDateColor] = useState(grayScale.medium);
  const [initValue, setInitValue] = useState(value || new Date());

  const [formattedValue, setFormattedValue] = useField(
    () => getDateOption(value, true),
    [value]
  );

  const [showDateMenu, setShowDateMenu] = useState(false);
  const [dateValidationError, setDateValidationError] = useState(false);

  const measuredRef = useCallback(
    (controlRef) => {
      if (controlRef) {
        const parent = document.body.getBoundingClientRect();
        const child = controlRef
          .querySelector('input')
          ?.getBoundingClientRect() || { left: 0, right: 0 };
        setRightPosition(child.left - parent.left > parent.right - child.right);
        applyRef(ref, controlRef);
      }
    },
    [ref]
  );

  const getBusinessTime = (selectedTime) => {
    if (businessTimezoneForDiscrepancyWarning && selectedTime.value) {
      return getScheduledTimezoneAdjusted(
        selectedTime.date,
        businessTimezoneForDiscrepancyWarning
      );
    }
  };

  const handleInputChange = (v) => {
    setShowDateMenu(false);
    setFormattedValue((prev) => ({ ...prev, label: v }));
  };

  const handleOnBlur = () => {
    const isValidDate =
      formattedValue?.label.length === MIN_DATE_LENGTH &&
      isMatch(formattedValue?.label, 'MM/dd/yyyy');
    setShowDateMenu(false);
    if (!isValidDate) {
      setFormattedValue(getDateOption(initValue, true));
      return;
    }
    setInitValue(formattedValue.label);
    setFormattedValue(getDateOption(formattedValue.label, true));
    onChange(new Date(`${formattedValue.label} ${time ? time.value : ''}`));
  };

  // track menuIsOpen so we can set the toggle setting
  const setWhichMenuStatus = () =>
    !props.menuIsOpen || props.disabled ? null : 'left';
  const menuIsOpenRef = useRef({
    menuIsOpen: props.menuIsOpen,
    disabled: props.disabled,
  });
  const [whichMenu, setWhichMenu] = useState(setWhichMenuStatus());
  if (
    menuIsOpenRef.current?.menuIsOpen !== props.menuIsOpen ||
    menuIsOpenRef.current?.disabled !== props.disabled
  ) {
    menuIsOpenRef.current = {
      menuIsOpen: props.menuIsOpen,
      disabled: props.disabled,
    };
    setWhichMenu(setWhichMenuStatus());
  }

  const customError = outlineVariant ? Boolean(error) : false;
  const wrapperRef = useRef(null);
  const timeProps = {
    ...getNonInputControlProps({
      ...props,
      right: rightPosition,
      error: customError,
      validate: null,
    }),
    menuIsOpen: props.menuIsOpen && !props.disabled && whichMenu !== 'left',
    handleMouseEnter: () => {
      if (!error) setColor(colorsButton.blue.hover);
    },
    handleMouseLeave: () => {
      if (!error) setColor(grayScale.medium);
    },
    handleClick: () => {
      if (whichMenu === 'left') setWhichMenu('right');
    },
  };

  const dateProps = {
    ...getNonInputControlProps({
      ...props,
      right: rightPosition,
      validate: {
        ...props.validate,
        container: wrapperRef?.current,
      },
    }),
    menuIsOpen: props.menuIsOpen && !props.disabled && whichMenu !== 'right',
    handleMouseEnter: () => {
      if ((!error || !dateValidationError) && !disabled) {
        setColor(colorsButton.blue.hover);
        setDateColor(colorsButton.blue.hover);
      }
    },
    handleMouseLeave: () => {
      if (!error || !dateValidationError) {
        setColor(grayScale.medium);
        setDateColor(grayScale.medium);
      }
    },
  };

  const handleTimeChange = (isDate) => (data) => {
    if (onChange) {
      if (isDate) {
        setFormattedValue(data);
        onChange(
          data ? new Date(`${data.label} ${time ? time.value : ''}`) : null
        );
      } else {
        onChange(
          data
            ? new Date(
                `${
                  date && date.label !== '' // if the user click time first then date.label will be empty :(
                    ? date.label
                    : format(new Date(), DATE_DISPLAY_FORMAT_NEW)
                }
              ${data.value}`
              )
            : null
        );
      }
    }
  };

  const isShowDateMenu =
    (dateProps.menuOnly && whichMenu !== 'left') || showDateMenu;

  const isShowBusinessTimezoneWarning =
    businessTimezoneForDiscrepancyWarning &&
    formattedValue.value &&
    !isMatchBusinessAndLocalTime;

  // Create a time string looks like: `{{MMMM}} {{DD}}, {{YYYY}} {{hh}}:{{mm}} {{AM/PM}}`
  let businessTimeString = '';
  // Create a time zone string looks like: `{{Timezone}} GMT{{+/-}}{{XX:XX}}`
  let businessTimeZoneString = '';

  if (isShowBusinessTimezoneWarning) {
    const businessTime = getBusinessTime(formattedValue);
    businessTimeString = format(businessTime, 'LLLL d, y p');
    const businessTimeZone = ct.getTimezone(
      businessTimezoneForDiscrepancyWarning
    );
    businessTimeZoneString = `GMT${businessTimeZone.utcOffsetStr}`;
  }

  // if the time does not fall on a 15 minute interval insert it
  const insertedTimeOptions = useMemo(() => {
    if (time?.value && !timeOptions.some(({ value }) => value === time.value)) {
      // have a valid time that doesn't match the options - add it
      const [before, after] = split(
        timeOptions,
        ({ value }) => value < time.value
      );

      return [
        ...before,
        {
          value: time.value,
          label: time.label,
        },
        ...after,
      ];
    }
    return timeOptions;
  }, [time]);

  // this should be moved out into it's own component
  const BusinessTimezoneWarning = () => (
    <DiscrepancyWarning>
      <IconSizing size="15px">
        <Icon icon={['fal', 'globe']} />
      </IconSizing>
      <KizenTypography type="label-small">
        {t(
          'Your timezone differs from the business timezone. This will be set to'
        )}{' '}
        {businessTimeString} {t('in the business timezone')} (
        {businessTimezoneForDiscrepancyWarning} {businessTimeZoneString})
      </KizenTypography>
    </DiscrepancyWarning>
  );

  return (
    <div ref={wrapperRef}>
      <StyledMaybeInputControl
        forInput
        {...props}
        {...getDataQAForInput(props.field?.id, props.field?.fieldType)}
      >
        <DateTimeLayout variant={variant}>
          {!outlineVariant && disabled ? (
            <DateTimeReadOnly {...props} />
          ) : (
            <>
              <DateComponent ref={ref}>
                {!dateProps.menuOnly && (
                  <DateWrapper
                    measuredRef={measuredRef}
                    onBlur={handleOnBlur}
                    value={formattedValue && formattedValue.label}
                    onChange={handleInputChange}
                    onClick={() => {
                      if (whichMenu === 'right') setWhichMenu('left');
                      setShowDateMenu(true);
                    }}
                    placeholder={placeholderDate}
                    variant={variant}
                    disabled={disabled}
                    selectProps={dateProps}
                    error={dateProps.error}
                    color={dateColor}
                    isShowMenu={isShowDateMenu}
                    validate={dateProps.validate}
                    errorCallback={setDateValidationError}
                    validateOnFocus
                  />
                )}
                {(isShowDateMenu || dateProps.menuIsOpen) && (
                  <StyledSelect
                    className={!hasInputControl(props) && className}
                    isSearchable={false}
                    menuInline={dateProps.menuInline}
                    endAdornment={<IconAdornment icon="calendar" />}
                    handleClose={() => setShowDateMenu(false)}
                    {...dateProps}
                    isDateTime
                    rightPosition={rightPosition}
                    placeholder={placeholderDate}
                    components={{
                      Control: DateControl,
                      Menu: DateMenu,
                      MenuList,
                      ...dateProps.components,
                    }}
                    error={customError}
                    value={formattedValue}
                    menuOnly
                    closeOnClearClick
                    closeMenuOnSelect={!(menuLeftButton || menuRightButton)}
                    onChange={(opt) => {
                      setFormattedValue(opt);
                      setShowDateMenu(false);
                      onChange(
                        opt
                          ? new Date(`${opt.label} ${time ? time.value : ''}`)
                          : null
                      );
                      setInitValue(opt?.label);
                    }}
                  />
                )}
              </DateComponent>

              {isShowBusinessTimezoneWarning && <BusinessTimezoneWarning />}

              {outlineVariant && (
                <ControlDelimiter
                  color={color}
                  error={error || dateValidationError}
                />
              )}
              <Select
                className={!hasInputControl(props) && className}
                isSearchable={isTimeSearchable}
                endAdornment={<IconAdornment icon="clock" />}
                {...timeProps}
                options={insertedTimeOptions}
                placeholder={placeholderTime}
                components={{
                  Control: TimeControl,
                  Menu: TimeMenu,
                  MenuList: TrackingMenuList,
                  ...timeProps.components,
                }}
                menuLeftButton={false}
                menuRightButton={false}
                error={customError}
                value={time && time.value}
                onChange={handleTimeChange(false)}
                menuShouldScrollIntoView
              />
            </>
          )}
        </DateTimeLayout>
      </StyledMaybeInputControl>
    </div>
  );
});

DateTimeInput.displayName = 'DateTimeInput';

DateTimeInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  placeholderDate: PropTypes.string,
  placeholderTime: PropTypes.string,
  error: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  menuLeftButton: PropTypes.bool,
  menuRightButton: PropTypes.bool,
  businessTimezoneForDiscrepancyWarning: PropTypes.string,
};

DateTimeInput.defaultProps = {
  value: null,
  onChange: null,
  placeholderDate: 'MM/DD/YYYY',
  placeholderTime: 'HH:MM AM/PM',
  error: false,
  variant: 'outline',
  disabled: false,
  menuLeftButton: null,
  menuRightButton: null,
  businessTimezoneForDiscrepancyWarning: '',
};

export default DateTimeInput;
