import { Typography } from '@kizen/kds/Typography';

export interface MetadataProps {
  title: string;
  subject: string | any;
  detail: any;
}

export const Metadata = (props: MetadataProps) => {
  const { title, subject, detail } = props;

  return (
    <div className="flex flex-col gap-y-15 min-w-0">
      <Typography truncate weight="semibold">
        {title}
      </Typography>
      {typeof subject === 'string' ? (
        <Typography truncate>{subject}</Typography>
      ) : (
        subject
      )}
      {detail}
    </div>
  );
};
