import React, { useCallback, useContext, useMemo } from 'react';
import { AREA_RESPONSES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { useTranslation } from 'react-i18next';
import Select from 'components/Inputs/Select';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { gutters } from 'app/spacing';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import { objectNameToOption } from 'services/helpers';
import usePipeline from 'components/Wizards/shared/hooks/usePipeline';
import useDataConstraint from 'components/Wizards/shared/hooks/useDataConstraint';
import PropTypes from 'prop-types';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { useSelector } from 'react-redux';

const PipelineArea = ({ active, disabled }) => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const { area, pipeline, reportType, pipelineDetail } = state;
  const { t } = useTranslation();
  const previewFlagEnabled = usePreReleaseFeatures();

  const {
    pipeline: selectedPipeline,
    pipelines: pipelinesData,
    loading: pipelinesLoading,
  } = usePipeline(pipeline?.value, true, {
    skipErrorBoundary: true,
  });

  const access = useSelector((s) => s.authentication.access);

  const pipelines = useMemo(() => {
    const objectAccess = access.custom_objects?.custom_object_entities ?? {};

    return (pipelinesData ?? [])
      .filter((o) => {
        return objectAccess[o.id]?.enabled;
      })
      .map(objectNameToOption);
  }, [pipelinesData, access]);

  useMetadataKey('pipelineDetail', selectedPipeline);

  const pipelineValue = useMemo(() => {
    if (pipeline?.value) {
      return {
        value: pipeline.value,
        label: pipelines?.find((p) => p.value === pipeline?.value)?.label,
      };
    }
    return pipeline;
  }, [pipeline, pipelines]);

  const dashletReportTypes = useMemo(() => {
    const opts = [
      {
        label: t('Opportunity Conversion'),
        value: REPORT_TYPES.OPPORTUNITY_CONVERSION,
      },
      {
        label: `${pipelineDetail?.entityName ?? t('Entity')} ${t(
          'Records Added'
        )}`,
        value: REPORT_TYPES.RECORDS_ADDED,
      },
      {
        label: `${pipelineDetail?.entityName ?? t('Entity')} ${t(
          'Records Lost'
        )}`,
        value: REPORT_TYPES.RECORDS_LOST,
      },
      {
        label: t('Sales Leaderboard with Projections'),
        value: REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS,
      },
      {
        label: `${pipelineDetail?.entityName ?? t('Entity')} ${t(
          'Records Won'
        )}`,
        value: REPORT_TYPES.RECORDS_WON,
      },
    ];

    if (pipelineDetail?.trackEntityValue) {
      opts.push(
        {
          label: t('Pipeline Value Over Time'),
          value: REPORT_TYPES.VALUE_OVER_TIME,
        },
        {
          label: t('Stages Over Time'),
          value: REPORT_TYPES.STAGES_OVER_TIME,
        }
      );
    }

    if (previewFlagEnabled) {
      opts.push({
        label: `${pipelineDetail?.entityName ?? t('Entity')} ${t(
          'Records Disqualified'
        )}`,
        value: REPORT_TYPES.RECORDS_DISQUALIFIED,
      });
    }

    return opts.sort(({ label: nameA }, { label: nameB }) => {
      return nameA.localeCompare(nameB);
    });
  }, [pipelineDetail, t, previewFlagEnabled]);

  useDataConstraint((internalState) => {
    if (
      active &&
      internalState.pipelineDetail &&
      internalState.pipelineDetail?.id === internalState?.pipeline?.value &&
      !internalState.pipelineDetail?.trackEntityValue &&
      (internalState.reportType?.value === REPORT_TYPES.VALUE_OVER_TIME ||
        internalState.reportType?.value === REPORT_TYPES.STAGES_OVER_TIME)
    ) {
      return {
        key: 'reportType',
        value: undefined,
      };
    }
  });

  useDataConstraint((internalState) => {
    if (
      active &&
      internalState.area === AREA_RESPONSES.PIPELINES &&
      Boolean(internalState.pipeline?.value) &&
      !pipelinesLoading &&
      !pipelinesData.find((p) => p.id === internalState.pipeline.value)
    ) {
      return {
        key: 'pipeline',
        value: undefined,
      };
    }
  });

  const pipelineReportTypeValue = useMemo(() => {
    if (reportType?.value) {
      return {
        value: reportType.value,
        label: dashletReportTypes.find((r) => r.value === reportType?.value)
          ?.label,
      };
    }
    return reportType;
  }, [reportType, dashletReportTypes]);

  const pipelineLabel = useMemo(() => {
    return t('Choose Pipeline/Workflow');
  }, [t]);

  const pipelineOnChange = useCallback(
    (value) => {
      // Clear filters
      setState('customFilters', undefined);
      setState('inGroupFilters', undefined);
      setState('notInGroupFilters', undefined);

      // Update selected pipeline
      setState('pipeline', value);
    },
    [setState]
  );

  const reportTypeLabel = t('Choose Report Type');

  const reportTypeOnChange = useCallback(
    (value) => {
      return setState('reportType', value);
    },
    [setState]
  );

  // Hide self if the area is not pipelines
  if (area !== AREA_RESPONSES.PIPELINES) {
    return null;
  }

  return (
    <>
      <StyledWizardField>
        <StyledWizardField top={0}>
          <KizenTypography type="subheader">{pipelineLabel}</KizenTypography>
        </StyledWizardField>
        <Select
          value={pipelineValue}
          onChange={pipelineOnChange}
          captureMenuScroll={false}
          className="modal-select pipeline"
          placeholder={pipelineLabel}
          options={pipelines}
          fullWidth
          isLoading={pipelinesLoading}
          disabled={disabled}
        />
      </StyledWizardField>
      {pipeline && (
        <StyledWizardField top={gutters.spacing(6)}>
          <KizenTypography type="subheader">{reportTypeLabel}</KizenTypography>
          <Select
            value={pipelineReportTypeValue}
            onChange={reportTypeOnChange}
            captureMenuScroll={false}
            className="modal-select report-type"
            placeholder={reportTypeLabel}
            options={dashletReportTypes}
            disabled={disabled}
          />
        </StyledWizardField>
      )}
    </>
  );
};

PipelineArea.propTypes = {
  active: PropTypes.bool,
};

PipelineArea.defaultProps = {
  active: false,
};

export default PipelineArea;
