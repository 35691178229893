import TriggerCard from 'pages/AutomationEngine/Engine/Steps/TriggerCard';
import DraggableStepCard from 'pages/AutomationEngine/Engine/Steps/DraggableStepCard';
import { RECEIVING_INBOX } from 'pages/AutomationEngine/helpers';
import { getValueOrDeleted } from '../action/action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  let type;

  const { details } = step;
  if (details.receivingInbox === RECEIVING_INBOX.any) {
    type = t('Any Integrated Inbox');
  } else if (details.receivingInbox === RECEIVING_INBOX.teamRole) {
    type = `${t('Role')} '${getValueOrDeleted(
      details.specificTeamRole?.label,
      t
    )}'`;
  } else if (details.receivingInbox === RECEIVING_INBOX.teamMember) {
    type = getValueOrDeleted(details.specificTeamMember?.label, t);
  }

  return `${t('Email Received From Contact to')} ${type}`;
};

export default function Card({ step, ...others }) {
  return step?.goalType ? (
    <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>
  ) : (
    <TriggerCard {...others}>{content({ step })}</TriggerCard>
  );
}
