import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';

export const EMPLOYEE_ACCESS = {
  OWNER: 'Owner',
  ADMIN: 'Admin',
  EDIT: 'Edit',
  VIEW: 'View',
  NONE: 'None',
};

export const DELETE_PERMISSIONS = [
  EMPLOYEE_ACCESS.ADMIN,
  EMPLOYEE_ACCESS.OWNER,
];

export const EDIT_PERMISSIONS = [...DELETE_PERMISSIONS, EMPLOYEE_ACCESS.EDIT];

export const VIEW_PERMISSIONS = [...EDIT_PERMISSIONS, EMPLOYEE_ACCESS.VIEW];

export const getIsEditable = (access) => {
  return EDIT_PERMISSIONS.includes(access);
};

export const getIsDeletable = (access) => {
  return DELETE_PERMISSIONS.includes(access);
};

// These values are returned from the backend capitalized,
// however they are meant to be treated as computer-readable values,
// and will not be translated. We need to convert them to the translated
// versions for display.
export const EMPLOYEE_ACCESS_DICTIONARY = {
  [EMPLOYEE_ACCESS.OWNER]: (t) => t('Owner'),
  [EMPLOYEE_ACCESS.ADMIN]: (t) => t('Admin'),
  [EMPLOYEE_ACCESS.EDIT]: (t) => t('Edit'),
  [EMPLOYEE_ACCESS.VIEW]: (t) => t('View'),
  [EMPLOYEE_ACCESS.NONE]: (t) => t('None'),
};

export const getTranslatedAccessText = (access, t) => {
  return (
    EMPLOYEE_ACCESS_DICTIONARY[access]?.(t) ??
    EMPLOYEE_ACCESS_DICTIONARY[EMPLOYEE_ACCESS.NONE](t)
  );
};

export const getTranslatedAccessStats = (
  { sharing_stats, sharing_settings } = {},
  t,
  hideRoles = []
) => {
  const allowedRoles = Object.values(EMPLOYEE_ACCESS).filter(
    (role) => !hideRoles.includes(role) && role !== EMPLOYEE_ACCESS.OWNER
  );
  const stats = sharing_stats || sharing_settings;
  return stats
    ? allowedRoles
        .map(
          (role) => `${stats[role] ?? 0} ${getTranslatedAccessText(role, t)}`
        )
        .join(', ')
    : emptyLongDash;
};

export const COLUMN_SIZES = {
  LARGE: 320,
  MEDIUM: 280,
  SMALL: 253,
  TINY: 80,
  TEENY: 75,
  ACTIONS: 60,
};

export const getCanView = (entity) => {
  return VIEW_PERMISSIONS.includes(entity?.employee_access);
};

export const getCanEdit = (entity) => {
  return EDIT_PERMISSIONS.includes(entity?.employee_access);
};

export const getCanAdmin = (entity) => {
  return DELETE_PERMISSIONS.includes(entity?.employee_access);
};

export const getCanElevatePermissions = (entity) => {
  return !DELETE_PERMISSIONS.includes(entity?.employee_access);
};

export const getCanChangeOwner = (entity) => {
  return entity?.employee_access === EMPLOYEE_ACCESS.OWNER;
};
