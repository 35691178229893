import { useCallback, useMemo, useState, useRef } from 'react';
import {
  GenericWizardBaseModal,
  GenericWizardModalHeader,
} from 'components/GenericWizard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Loader from 'components/Kizen/Loader';
import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';

import ObjectSelector from './ObjectSelector';
import { useQuery } from 'react-query';
import { useDefaultModelQuery } from 'queries/models/custom-objects';
import { DASHBOARD } from 'queries/query-keys';
import CustomObjectsService from 'services/CustomObjectsService';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import { getBusinessClientObject } from 'store/authentication/selectors';
import DynamicObjectFilter from './DynamicObjectFilter';

const emptyForCustomObject = {
  details: {
    type: null,
    groups: [],
    filter: {
      and: null,
      query: [],
    },
  },
};

const emptyForClient = {
  details: {
    type: null,
    groups: [],
    filter: {
      and: null,
      query: [],
    },
  },
};

const defaultOnActivities = true;

const FilterLogic = ({
  onHide,
  onConfirm,
  customObject,
  state: filterState,
  hideWarnings,
  area,
  selectedObject,
  onChangeSelectedObject,
  enableContacts = true,
  charts,
  hideGroups,
  modalHeader,
  defaultCustomFilter,
  intitialRenderRef,
}) => {
  const { t } = useTranslation();
  const businessClientData = useSelector(getBusinessClientObject);

  const [hasBeenModified, setHasBeenModified] = useState(false);
  const [internalSelectedObject, setInternalSelectedObject] = useState(
    () => selectedObject
  );

  const { data: clientObject } = useDefaultModelQuery(businessClientData.id, {
    enabled: Boolean(businessClientData.access),
  });

  const { data } = useQuery(
    [
      ...DASHBOARD.CUSTOM_OBJECT_LIST,
      { defaultOnActivities, customOnly: false },
    ],
    () =>
      CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
        defaultOnActivities,
        customOnly: false,
      }),
    {
      enabled: area === AREA_RESPONSES.ACTIVITIES,
    }
  );

  const rawInternalSelectedObject = useMemo(() => {
    if (!internalSelectedObject) {
      return null;
    }
    return (data?.results ?? []).find(
      ({ id }) =>
        id === internalSelectedObject?.value ||
        id === internalSelectedObject?.id
    );
  }, [data, internalSelectedObject]);

  const internalChangeCustomObject = useCallback((co) => {
    setHasBeenModified(true);
    setInternalSelectedObject(co);
  }, []);

  const isForCustomObject = rawInternalSelectedObject
    ? rawInternalSelectedObject?.fetchUrl !== 'client'
    : true;

  const handleConfirm = useCallback(
    (filterObject) => {
      onChangeSelectedObject?.(internalSelectedObject);
      onConfirm(filterObject, internalSelectedObject);
    },
    [onChangeSelectedObject, onConfirm, internalSelectedObject]
  );

  const [objectSelector, customObjectInUse] =
    area === AREA_RESPONSES.ACTIVITIES
      ? [
          <ObjectSelector
            key="first"
            value={rawInternalSelectedObject}
            onChange={internalChangeCustomObject}
            defaultOnActivities
          />,
          rawInternalSelectedObject,
        ]
      : [null, customObject];

  const defaultHeader = `${
    charts ? t('Edit Chart Filter(s)') : t('Edit Dashlet Filter(s)')
  }`;

  return (
    <>
      <GenericWizardModalHeader onClickClose={onHide}>
        {modalHeader ?? defaultHeader}
      </GenericWizardModalHeader>
      <DynamicObjectFilter
        onConfirm={handleConfirm}
        customObject={customObjectInUse}
        filterState={
          hasBeenModified
            ? isForCustomObject
              ? emptyForCustomObject
              : emptyForClient
            : filterState
        }
        hideWarnings={hideWarnings}
        area={area}
        selectedObject={rawInternalSelectedObject}
        onChangeSelectedObject={internalChangeCustomObject}
        enableContacts={enableContacts}
        additionalFields={objectSelector}
        isForCustomObject={isForCustomObject}
        hideGroups={hideGroups}
        clientObject={clientObject}
        defaultCustomFilter={defaultCustomFilter}
        intitialRenderRef={intitialRenderRef}
      />
    </>
  );
};

const FiltersModal = ({
  customObject,
  onConfirm,
  onHide,
  state,
  loading,
  hideWarnings,
  hideGroups,
  area,
  selectedObject,
  onChangeSelectedObject,
  isForCustomObject,
  onClear,
  charts,
  reportType,
  modalHeader,
  defaultCustomFilter,
  ...rest
}) => {
  const { t } = useTranslation();
  const intitialRenderRef = useRef(true);

  return (
    <GenericWizardBaseModal
      heading={charts ? t('Edit Chart Filter(s)') : t('Edit Dashlet Filter(s)')}
      size="large"
      onHide={onHide}
      {...rest}
    >
      {loading ? (
        <Loader loading />
      ) : (
        <FilterLogic
          onHide={onHide}
          onConfirm={onConfirm}
          customObject={customObject}
          state={state}
          hideWarnings={hideWarnings}
          hideGroups={hideGroups}
          area={area}
          selectedObject={selectedObject}
          onChangeSelectedObject={onChangeSelectedObject}
          key={selectedObject?.id}
          isForCustomObject={isForCustomObject}
          onClear={onClear}
          charts={charts}
          modalHeader={modalHeader}
          loading={loading}
          defaultCustomFilter={defaultCustomFilter}
          intitialRenderRef={intitialRenderRef}
        />
      )}
    </GenericWizardBaseModal>
  );
};

export default FiltersModal;
