import { useTranslation } from 'react-i18next';
import { StyledButton } from './styles';

const ClearFilters = ({ onClear }) => {
  const { t } = useTranslation();

  return (
    <StyledButton onClick={onClear} variant="outline" color="red">
      {t('Clear Filters')}
    </StyledButton>
  );
};

export default ClearFilters;
