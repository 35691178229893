import { defSortedMap, SortedMap } from '@thi.ng/associative/sorted-map';
import { format } from 'date-fns';
import get from 'lodash/get';
import set from 'lodash/set';
import { NO_VALUE, UNRESOLVED } from './constants';
import {
  isAbsoluteString,
  isAbsoluteRef,
  isEndStep,
  isPath,
  isRef,
  isRelativeRef,
  isRelativeString,
  isStepRef,
  isReferenceList,
  isTransform,
} from './checks';
import {
  AbsoluteRef,
  AbsoluteString,
  FilterMetadata,
  Next,
  Op,
  Option,
  Path,
  Reference,
  ReferenceList,
  RelativeRef,
  RelativeString,
  StepConfig,
  StepData,
  StepRef,
  TransformArg,
  TransformDef,
} from './types';
import {
  drop,
  first,
  getAbsoluteStringPath,
  getKeys,
  getRelativeStringPath,
  mergeEntries,
} from './utils';

type V = any;

type ResolveOpts = {
  recursiveTransformResolution?: boolean;
  unresolvedValue?: any;
};

type ResolveObjectOpts<T> = {
  recursive?: boolean;
  skipKeys?: string[];
  step?: T;
};

export type FilterOpts<T> = {
  init?: ([T, V] | [T])[];
  vars?: [string, V][];
};

export type NextArgs = Parameters<Filter['next']>;

const ArrayInputTypes: StepConfig['input_type'][] = [
  'multiselect',
  'multiselect_one',
];

const expandStepConfig = (
  config: FilterMetadata['steps'][number],
  fullConfig: object
) => {
  const c = isAbsoluteString(config)
    ? get(fullConfig, getAbsoluteStringPath(config))
    : config;
  return isAbsoluteString(c.config_ref)
    ? { ...get(fullConfig, getAbsoluteStringPath(c.config_ref)), ...c }
    : c;
};

const fillEmptyValues = <T = string>(
  ...values: ([T] | [T, any])[]
): [T, any][] => {
  return Array.from(values, (x) =>
    x[1] === undefined ? [x[0], NO_VALUE] : (x as [T, any])
  );
};

export class Filter<T extends string = string> {
  private map: SortedMap<T, V>;
  private step_configs: Map<string, StepConfig>;
  private vars: Map<string, any>;

  constructor(
    private config: FilterMetadata<T>,
    private full_config: Record<string, any>,
    opts: FilterOpts<T> = {}
  ) {
    const { init = [], vars = [] } = opts;
    const { initial_steps = [], order, steps } = this.config;

    this.vars = new Map(vars);
    this.step_configs = new Map(
      Object.entries<FilterMetadata['steps'][number]>(steps).map(
        ([key, config]) => {
          const val = expandStepConfig(config, this.full_config);
          return [key, val];
        }
      )
    );

    this.map = defSortedMap(
      mergeEntries<T, V>(fillEmptyValues(...initial_steps, ...init)),
      {
        compare: (a: T, b: T) => order[a] - order[b],
      }
    );
  }

  get type() {
    return this.map.get('filter_type' as T);
  }

  *[Symbol.iterator](): IterableIterator<[T, StepData]> {
    for (const [step, value] of this.map.entries()) {
      if (!this.shouldYieldStep(step)) {
        // Steps with order zero are not returned. They are used to configure global data
        // for the filter that can be referenced just like a step value.
      } else if (value instanceof Filter) {
        yield* value;
      } else {
        const {
          config_ref,
          ops,
          option_filter,
          option_paths,
          options: o,
          params,
          transform_options = true,
          ...rest
        } = this.step_configs.get(step)!;
        const options = isRef(o)
          ? this.resolve(o, step, { unresolvedValue: [] })
          : o;
        const paths = isRef(option_paths)
          ? this.resolve(option_paths, step)
          : option_paths;
        const mapped_options =
          transform_options && options && paths
            ? options.map((x: any) => getKeys(paths, x))
            : options;
        const data = {
          config_ref,
          ops,
          option_filter: isRef(option_filter)
            ? this.resolve(option_filter, step, {
                recursiveTransformResolution: false,
              })
            : option_filter,
          option_paths: paths,
          options: Array.isArray(mapped_options)
            ? mapped_options.map((opt: any) =>
                this.resolveObject(opt, { skipKeys: ['ops'] })
              ) // ops are resolved when calling build
            : mapped_options,
          params: isRef(params)
            ? this.resolve(params, step)
            : params && this.resolveObject(params, { step }),
          value: value === NO_VALUE ? undefined : value,
          ...this.resolveObject(rest, { step }),
        };
        yield [step, data];
      }
    }
  }

  *save({ root_filter = true } = {}): IterableIterator<any> {
    const steps = root_filter ? drop(this.map.keys()) : this.map.keys();

    if (root_filter) {
      const [[key, value]] = first(this.map.entries());
      const { variables_to_save } = this.full_config.filter_type_step ?? {};
      let vars = [...this.vars.entries()];
      if (Array.isArray(variables_to_save)) {
        vars = vars.filter((v) => variables_to_save.includes(v[0]));
      }
      yield [key, { filter_type: value, vars }];
    }

    for (const step of steps) {
      const { save_value, variables_to_save } = this.resolveObject(
        this.step_configs.get(step) ?? {}
      );

      const result_transform = save_value?.result_transform;

      const value = this.map.get(step);
      let resolved = isRef(save_value)
        ? this.resolve(save_value, step)
        : isTransform(save_value)
          ? this.transform(save_value)
          : !Array.isArray(save_value) && typeof save_value === 'object'
            ? this.resolveObject(save_value, { step })
            : save_value ?? value;

      if (result_transform) {
        resolved = {
          ...resolved,
          result_transform: isRef(result_transform)
            ? this.resolve(result_transform, step, {
                recursiveTransformResolution: false,
              })
            : result_transform,
        };
      }

      if (save_value?.error_response) {
        resolved = {
          ...resolved,
          error_response: this.resolveObject(save_value.error_response, {
            recursive: true,
          }),
        };
      }

      if (save_value?.body) {
        let body = isRef(save_value.body)
          ? this.resolve(save_value.body, step)
          : save_value.body;
        body = this.resolveObject(body, { step });
        resolved = { ...resolved, body };
      }

      if (save_value?.error_message) {
        const error_message = isRef(save_value.error_message)
          ? this.resolve(save_value.error_message, step)
          : save_value.error_message;

        resolved = { ...resolved, error_message };
      }

      let result = resolved;

      if (value instanceof Filter) {
        let vars = [...value.vars.entries()];

        if (Array.isArray(variables_to_save)) {
          vars = vars.filter((v) => variables_to_save.includes(v[0]));
        }

        result = {
          is_filter: true,
          filter_type: value.type,
          step_data: [...value.save({ root_filter: false })],
          vars,
        };
      }

      yield [step, result];
    }
  }

  *steps(): IterableIterator<T> {
    for (const step of this.map.keys()) {
      if (this.shouldYieldStep(step)) {
        const val = this.map.get(step);
        if (val instanceof Filter) {
          yield* val.steps();
        } else {
          yield step;
        }
      }
    }
  }

  private addStep(
    step: T,
    opts: { config?: FilterMetadata; step_init?: V | null } = {}
  ) {
    const config = this.step_configs.get(step);
    if (config?.is_filter) {
      if (!opts.config) {
        throw Error(
          'Received a next step to create a nested filter, but no filter metadata was supplied to create it.'
        );
      }
      const config_vars =
        config.vars?.map<[string, any]>((v) => [
          v[0],
          isRef(v[1]) ? this.resolve(v[1], step) : v[1],
        ]) ?? [];
      const vars = mergeEntries(Array.from(this.vars.entries()), config_vars);
      const filter = new Filter(opts.config, this.full_config, { vars });
      this.map.set(step, filter);
    } else {
      const v =
        opts.step_init === undefined
          ? this.getDefaultValue(step)
          : opts.step_init;
      this.map.set(step, v);
    }
  }

  private applyOps(obj: object, ops: Op[], step: T) {
    for (const op of ops) {
      const last = op[op.length - 1];
      const path = op.slice(0, op.length - 1) as Path;

      if (isStepRef(last)) {
        const v = this.resolveStep(last);
        set(obj, path, v instanceof Filter ? v.build() : v);
      } else if (isRelativeRef(last) || isAbsoluteRef(last)) {
        const v = this.resolve(last, step);
        set(obj, path, v);
      } else {
        const value = isTransform(last) ? this.transform(last) : last;
        set(obj, path, value);
      }
    }
  }

  private expandPath(
    ref:
      | Exclude<AbsoluteRef, AbsoluteString>
      | Exclude<RelativeRef, RelativeString>,
    step: T | object
  ) {
    const [src, ...path] = ref;
    const start = isAbsoluteRef(src)
      ? getAbsoluteStringPath(src)
      : getRelativeStringPath(src);
    const expanded = path.reduce<Path>((acc, x) => {
      if (isStepRef(x)) acc.push(this.resolveStep(x));
      else if (isRelativeRef(x)) acc.push(this.resolve(x, step));
      else if (isAbsoluteRef(x)) acc.push(this.resolve(x, step));
      else if (Array.isArray(x)) acc.push(...x);
      else acc.push(x);
      return acc;
    }, []);
    return [...start, ...expanded];
  }

  private findFilterWithStep(step: T): Filter<any> | null {
    if (this.isValidStep(step)) return this;

    for (const val of this.map.values()) {
      if (val instanceof Filter && val.isValidStep(step)) {
        return val;
      }
    }

    return null;
  }

  private getDefaultValue(step: T) {
    const { input_type } = this.resolveObject(this.step_configs.get(step)!);
    const type = isRef(input_type)
      ? this.resolve(input_type, step)
      : input_type;
    return ArrayInputTypes.includes(type) ? [] : NO_VALUE;
  }

  private getStepKey(step: string) {
    if (step === '$') return step;
    return (step.startsWith('$') ? step.substring(1) : step) as T;
  }

  private isValidStep(step: T) {
    return this.config.order[step] !== undefined;
  }

  // 1-arity version will *not* resolve relative refs
  private resolve(
    obj: AbsoluteRef | StepRef | (AbsoluteRef | StepRef | any)[],
    opts?: ResolveOpts
  ): any;
  private resolve(
    x: Reference | ReferenceList<any>,
    step: T | Record<string, any>,
    opts?: ResolveOpts
  ): any;
  private resolve(
    x: Reference | ReferenceList<any> | TransformDef,
    step: T | Record<string, any> = {},
    opts: ResolveOpts = {}
  ): any {
    const { recursiveTransformResolution = true, unresolvedValue = null } =
      opts;
    const resolvingStepRef = isStepRef(x);
    const src = typeof step === 'object' ? step : this.step_configs.get(step)!;

    if (isTransform(x)) {
      return this.transform(x, src);
    }

    if (isReferenceList(x)) {
      for (const ref of x) {
        const result = this.resolve(ref, step, opts);
        if (result !== undefined) {
          return result;
        }
      }
    }

    let result = UNRESOLVED;
    if (isAbsoluteString(x)) {
      result = get(this.full_config, getAbsoluteStringPath(x));
    } else if (isRelativeString(x)) {
      result = get(src, getRelativeStringPath(x));
    } else if (resolvingStepRef) {
      result = this.resolveStep(x);
    } else if (isAbsoluteRef(x)) {
      result = get(this.full_config, this.expandPath(x, step));
    } else if (isRelativeRef(x)) {
      result = get(src, this.expandPath(x, step));
    }

    if (result === UNRESOLVED) {
      return unresolvedValue || x;
    }
    if (result === NO_VALUE) {
      return undefined;
    }
    if (resolvingStepRef) {
      // step references will contain objects or user entered strings - they won't ever point
      // to other sections of the metadata. We need to return here in case the user enters a
      // string in the form of a reference (@testing or @/testing).
      return result;
    }
    if (
      isRef(result) ||
      (recursiveTransformResolution && isTransform(result))
    ) {
      return this.resolve(result, step, opts);
    }
    return result;
  }

  private resolveObject(
    obj: Record<string, any>,
    opts?: ResolveObjectOpts<T>
  ): Record<string, any> {
    const recursive = opts?.recursive ?? false;
    const skipKeys = opts?.skipKeys ?? [];
    const src = opts?.step ?? obj;

    return Object.entries(obj).reduce<Record<string, any>>(
      (acc, [key, value]) => {
        const v = skipKeys.includes(key) ? value : this.resolve(value, src);
        if (key.startsWith('xf_spread') && typeof v === 'object') {
          return { ...acc, ...this.resolveObject(v) };
        }
        if (
          recursive &&
          !Array.isArray(v) &&
          v !== null &&
          typeof v === 'object'
        ) {
          return { ...acc, [key]: this.resolveObject(v, opts) };
        }
        acc[key] = v;
        return acc;
      },
      {}
    );
  }

  private resolveStep(type: StepRef) {
    const step_var = typeof type === 'string' ? type : type[0];
    const path = typeof type === 'string' ? null : type.slice(1);
    const step = this.getStepKey(step_var);
    let step_value = this.vars.get(step);

    if (step !== '$') {
      const filter = this.findFilterWithStep(step);

      if (filter) {
        step_value = filter.map.get(step);
      }
    }

    if (!Array.isArray(path)) return step_value;
    if (isPath(path)) return get(step_value, path);

    for (const p of path[0]) {
      const val = get(step_value, p);
      if (val !== undefined) return val;
    }
  }

  private shouldYieldStep(step: T) {
    return this.config.order[step] !== undefined && this.config.order[step] > 0;
  }

  private transform(def: TransformDef, relative_src: object = {}): any {
    const [xform, ...rest] = def;
    const concat = (args: TransformArg[]) => {
      return args.reduce<any[]>((acc, x) => {
        const resolved = isRef(x)
          ? this.resolve(x, relative_src)
          : isTransform(x)
            ? this.transform(x, relative_src)
            : x;
        if (Array.isArray(resolved)) {
          acc.push(...resolved);
        } else {
          acc.push(resolved);
        }
        return acc;
      }, []);
    };
    const join = (parts: TransformArg[]): any => {
      return parts
        .map((x) => {
          const res = isRef(x)
            ? this.resolve(x, relative_src)
            : isTransform(x)
              ? this.transform(x, relative_src)
              : x;
          return isTransform(res) ? this.transform(res, relative_src) : res;
        })
        .filter(Boolean)
        .join('');
    };
    const invert = (ref: TransformArg) => {
      return !(isRef(ref) ? this.resolve(ref, relative_src) : ref);
    };
    const bool = (ref: TransformArg) => {
      return Boolean(isRef(ref) ? this.resolve(ref, {}) : ref);
    };
    const eq = (a: TransformArg, b: TransformArg, ret?: any) => {
      const x = isRef(a)
        ? this.resolve(a, relative_src)
        : isTransform(a)
          ? this.transform(a, relative_src)
          : a;
      const y = isRef(b)
        ? this.resolve(b, relative_src)
        : isTransform(b)
          ? this.transform(b, relative_src)
          : b;
      return ret === undefined ? x === y : x === y ? ret : false;
    };
    const hoist = (a: TransformArg): any => {
      const x = isRef(a)
        ? this.resolve(a, relative_src)
        : isTransform(a)
          ? this.transform(a, relative_src)
          : a;
      return Array.isArray(x) ? x[0] : x;
    };
    const map = (a: TransformArg, b: Record<string, any> | Path) => {
      const array = isRef(a)
        ? this.resolve(a, relative_src)
        : isTransform(a)
          ? this.transform(a)
          : a;
      if (!Array.isArray(array)) return [];
      const res = array.map((x: any): any => {
        this.vars.set('$', x);
        if (isTransform(b)) return this.transform(b);
        return Array.isArray(b) ? get(x, b) : this.resolveObject(b);
      });
      this.vars.delete('$');
      return res;
    };
    const pick = (a: TransformArg, b: Path): any => {
      const src = isRef(a)
        ? this.resolve(a, relative_src)
        : isTransform(a)
          ? this.transform(a)
          : a;
      return get(src, b);
    };
    const date_format = (a: TransformArg, b: string) => {
      const v = isRef(a)
        ? this.resolve(a, relative_src)
        : isStepRef(a)
          ? this.resolveStep(a)
          : a;
      const d = new Date(v);
      return isNaN(d.getTime()) ? '' : format(d, b);
    };
    const some = (a: TransformArg, b: TransformArg, ret?: any) => {
      const aValue = isRef(a) ? this.resolve(a, relative_src) : a;
      const bValue = isRef(b) ? this.resolve(b, relative_src) : b;

      if (!Array.isArray(aValue)) {
        return false;
      }

      const result = aValue.some((x) => x === bValue);
      return ret && result ? ret : result;
    };

    const or = (a: TransformArg) => {
      const aValue = isRef(a) ? this.resolve(a, relative_src) : a;

      if (!Array.isArray(aValue)) {
        return false;
      }

      return aValue.some((b: any) => {
        return isTransform(b) ? this.transform(b) : b;
      });
    };

    const and = (a: TransformArg) => {
      const aValue = isRef(a) ? this.resolve(a, relative_src) : a;

      if (!Array.isArray(aValue)) {
        return false;
      }
      return aValue.every((b: any) => {
        return isTransform(b) ? this.transform(b) : b;
      });
    };

    if (xform === 'xf_concat') return concat(rest);
    if (xform === 'xf_join') return join(rest);
    if (xform === 'xf_invert') return invert(rest[0]);
    if (xform === 'xf_bool') return bool(rest[0]);
    if (xform === 'xf_hoist') return hoist(rest[0]);
    if (xform === 'xf_map') return map(rest[0], rest[1] as any);
    if (xform === 'xf_pick') return pick(rest[0], rest[1] as any);
    if (xform === 'xf_eq') return eq(rest[0], rest[1], rest[2]);
    if (xform === 'xf_some') return some(rest[0], rest[1], rest[2]);
    if (xform === 'xf_not_eq') {
      const res = !eq(rest[0], rest[1]);
      if (!res) return false;
      return rest[2] === undefined ? true : rest[2];
    }
    if (xform === 'xf_dateformat') {
      return date_format(rest[0], rest[1] as string);
    }
    if (xform === 'xf_or') {
      return or(rest[0]);
    }
    if (xform === 'xf_and') {
      return and(rest[0]);
    }

    throw Error(`transform called with unknown operator ${xform}`);
  }

  toString() {
    const strings = [
      this.resolve(['@/filter_type_step/labels', '$filter_type']),
    ];

    for (const step of this.map.keys()) {
      const { description } = this.resolveObject(
        this.step_configs.get(step) ?? {}
      );
      const v = this.map.get(step);

      if (v instanceof Filter) {
        strings.push(v.toString());
      } else {
        if (description && v !== NO_VALUE) {
          if (typeof description === 'string') {
            strings.push(description);
          } else {
            const desc = this.resolve(description, step);
            if (typeof desc === 'string' || Array.isArray(desc)) {
              strings.push(desc);
            }
          }
        }
      }
    }

    return strings
      .map((x) => (Array.isArray(x) ? String(x).trim() : x))
      .join(' ');
  }

  isMyRecord() {
    return this.resolve(['@/is_my_records']);
  }

  isStageRecord() {
    return this.resolve(['@/is_stage_records']);
  }

  build<T extends Record<string, any>>() {
    const result: Record<string, any> = {};

    for (const step of this.map.keys()) {
      const config = this.resolveObject(this.step_configs.get(step) ?? {});
      const step_value = this.map.get(step);

      if (config && step_value !== undefined && step_value !== NO_VALUE) {
        if (config.ops) {
          const ops = isRef(config.ops)
            ? this.resolve(config.ops, step)
            : config.ops;
          if (Array.isArray(ops)) {
            this.applyOps(result, ops, step);
          }
        }

        if (config.options) {
          const options = isRef(config.options)
            ? this.resolve(config.options, step)
            : config.options;
          const option = options?.find?.(
            ({ value }: Option) => value === this.map.get(step)
          );
          const ops =
            option && isRef(option.ops)
              ? this.resolve(option.ops, option)
              : option?.ops;
          if (Array.isArray(ops)) {
            this.applyOps(result, ops, step);
          }
        }
      }
    }

    return result as T;
  }

  next(current_step: T, next_steps: Next<T>[], config?: FilterMetadata) {
    if (!this.isValidStep(current_step)) {
      const filter = this.findFilterWithStep(current_step);

      if (!(filter instanceof Filter)) {
        throw Error(
          `Calling 'next' for current step ${current_step} is invalid. There is no filter in the chain that can handle this step.`
        );
      }

      filter.next(current_step, next_steps, config);
      return;
    }

    const current_next = [...drop(this.map.keys(current_step))];
    let prev_step = current_step;

    for (const ns of next_steps) {
      const {
        step,
        default: step_init = undefined,
        unset = false,
      } = typeof ns === 'object' ? ns : { step: ns };

      if (isEndStep(step)) {
        // end specified - remove any additional existing steps
        for (const key of drop(this.map.keys(prev_step))) {
          this.map.delete(key);
        }
        break;
      } else if (current_next.includes(step)) {
        // next step already exists
        // delete any steps until we reach the next step
        while (current_next[0] !== step) {
          this.map.delete(current_next[0]);
          current_next.shift();
        }
        current_next.shift(); // remove current step from buffer
        // clear or set value for step if necessary
        if (unset) this.map.set(step, this.getDefaultValue(step));
        if (step_init !== undefined || config)
          this.addStep(step, { config, step_init });
      } else if (
        this.config.order[step] === this.config.order[current_next[0]]
      ) {
        // another step exists with the same order # - replace it
        this.map.delete(current_next[0]);
        current_next.shift();
        this.addStep(step, { config, step_init });
      } else {
        this.addStep(step, { config, step_init });
      }

      prev_step = step;
    }
  }

  set(key: T, value: V) {
    const filter = this.findFilterWithStep(key);

    if (!filter) {
      throw Error(
        `Attempted to set ${value} to ${key} step but this step does not exist.`
      );
    }

    filter.map.set(key, value);
  }
}

export const defFilter = (
  config: FilterMetadata,
  full_config: object,
  opts?: FilterOpts<string>
) => {
  return new Filter(config, full_config, opts);
};
