import { ForwardedRef, ReactNode, forwardRef } from 'react';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { TextProps, RootProps } from '../../types';
import { getTypeInfo, NodeType } from '../types';
import {
  ElementContainer,
  DEFAULT_ELEMENT_CONTAINER_PROPS,
} from './ElementContainer';

type TextContainerProps = TextProps &
  Pick<RootProps, 'linkColor'> & {
    children: ReactNode;
    draggingType?: NodeType;
    pointerEvents: boolean;
  };

export const DEFAULT_TEXT_CONTAINER_PROPS = {
  ...DEFAULT_ELEMENT_CONTAINER_PROPS,
  containerPaddingTop: '10',
  containerPaddingRight: '10',
  containerPaddingBottom: '10',
  containerPaddingLeft: '10',
};

const Container = styled(ElementContainer)<TextContainerProps>`
  a,
  a[data-script-id] {
    // root node link color applied directly to <a>.
    // data-script-id is a link with an associated "Approved JavaScript Execution" function (available on static content blocks)
    // it's !important because the data-script-id link does not have an href attribute and the reboot css would override
    color: ${({ linkColor = '#528EF9' }) => `${linkColor}`} !important;
  }
  a:hover,
  a:focus,
  a:hover *,
  a:focus * {
    text-decoration: underline;
  }
  a:hover s,
  a:focus s {
    text-decoration: underline line-through;
  }
  a *,
  span * {
    // font color is applied to a span and bold, italic, underline, and strikethrough will be applied
    // as child <strong>, <em>, <u>, and <s> elements.
    color: inherit;
    font-size: inherit;
  }

  * .ProseMirror {
    padding: unset !important;
  }

  ${({ pointerEvents = true }) =>
    !pointerEvents &&
    css`
      pointer-events: none;
    `}
`;

export const TextContainer = forwardRef(
  (
    { children, draggingType, pointerEvents, ...rest }: TextContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Container
        ref={ref}
        ignoreChildrenDrag={Boolean(getTypeInfo(draggingType)?.isContent)}
        pointerEvents={pointerEvents}
        {...rest}
      >
        {children}
      </Container>
    );
  }
);
