export const COLUMNS_SIZE = {
  spacer: '20px',
  name: '430px',
  subject: '430px',
  created: '150px',
  actions: '60px',
};

export const PADDING_SIZE = {
  basic: '20px',
  small: '12px',
};
