import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';

export function UnarchiveConflictModal({ name, ...props }) {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      buttonText={t('Unarchive')}
      heading={t('Create New or Unarchive?')}
      defaultLeftBtnText={t('Cancel')}
      additionalButtonText={t('Create New')}
      {...props}
    >
      {t('Unarchive Conflict Modal Body', { name })}
    </ConfirmationModal>
  );
}

export function UnarchiveForbiddenModal({ name, ...props }) {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      actionBtnColor="blue"
      buttonText={t('Close')}
      leftBtn={false}
      heading={t('This is an Archived Record')}
      {...props}
    >
      {t('Unarchive Forbidden Modal Body', { name })}
    </ConfirmationModal>
  );
}
