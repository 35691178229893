import { Route, Redirect, useParams, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { breakpoints, isMobile, useWindowSize, gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import SubNavigation from 'components/Navigation/SubNavigation';
import { useTranslation } from 'react-i18next';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { settingsPagePaths } from './paths';
import { throwPermissionError } from './helpers';
import { useSelector } from 'react-redux';
import { getSettingsRoutesWithAccess } from 'store/authentication/selectors';
import routes from './routes';

const MobileContainer = styled.div`
  padding: ${gutters.standard};
  text-align: center;
`;

const SettingsPage = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  const { subpage } = useParams();
  const { width } = useWindowSize();
  const mobileDisplay = isMobile(width, breakpoints.lg);

  const {
    settingsRoutesWithAccess,
    accessibleSettingsRoutes,
    firstAccesssibleSettingsRoute,
  } = useSelector((state) => getSettingsRoutesWithAccess(state, routes));

  const matchedSubpage = Object.values(settingsPagePaths).find(
    (settingsPagePath) => settingsPagePath === subpage
  );

  if (!matchedSubpage) {
    if (!firstAccesssibleSettingsRoute) {
      throwPermissionError();
    }
    return <Redirect exact to={firstAccesssibleSettingsRoute.path} />;
  }

  if (mobileDisplay) {
    return (
      <MobileContainer>
        <KizenTypography>
          This page has not been optimized for mobile. Please revisit on a
          desktop to receive the best experience.
        </KizenTypography>
      </MobileContainer>
    );
  }
  return (
    <>
      <SubNavigation routes={accessibleSettingsRoutes} />
      <Switch>
        {Object.values(settingsRoutesWithAccess).map((route, index) => {
          const Component = route.component;
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact !== false}
              render={(props) => {
                if (!route.access?.enabled) {
                  throwPermissionError();
                }
                return <Component {...props} access={route.access} />;
              }}
            />
          );
        })}
      </Switch>
    </>
  );
};

export default SettingsPage;
