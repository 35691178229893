import { layers } from 'app/spacing';

import styled from '@emotion/styled';
import TableScrollContainer from 'components/Tables/ScrollContainerV2';
import Loader from 'components/Kizen/Loader';

export const StyledTableScrollContainer = styled(TableScrollContainer)`
  & .BodyForSync {
    flex-direction: column;
  }
  z-index: ${layers.content(0, 2)};
`;

export const TableCard = styled.div`
  height: calc(100% + 15px);
  width: calc(100% + 30px);
  display: flex;
  flex-direction: column;
  margin: 0 -15px -15px -15px;

  & > div > div {
    flex-grow: 1;
  }

  td:first-child,
  th:first-child {
    padding-left: 20px;
  }

  & .gradientWrapper {
    z-index: 2;
  }
`;

export const StyledLoader = styled(Loader)`
  position: relative;
  ${(props) =>
    props.noPadding &&
    `
  padding: 20px 0 !important; // :( Sorry, Loader component sets styles inline
`}
  z-index: ${layers.toast};
`;
