import { cloneElement, useCallback, useMemo, useState } from 'react';
import { getKDSClasses, merge } from '../util';
import { ActionGroupElement, Mode } from './types';
import { useElementsForActionGroup } from './useElementsForActionGroup';
import { IconProps } from '../Icon/Icon';
import { Tray } from './Tray';
import { Button } from '../Button/Button';
import { ActionItem } from './ActionItem';
import { ActionGroupContextProvider } from './context';

export interface ActionGroupProps {
  mode?: Mode;
  children?: any;
  elements?: ActionGroupElement[];
  trayIndicator?: (settings?: Omit<IconProps, 'icon'>) => any;
  trayIndicatorSettings?: Omit<IconProps, 'icon'>;
  onClearAll?: () => void;
}

const getGapClassName = (mode: Mode) => {
  switch (mode) {
    case 'text':
      return 'gap-x-text';
    case 'icon':
      return 'gap-x-icon-mobile @lg/actionBar:gap-x-icon';
    case 'mixed':
      return 'gap-x-mixed';
  }
};

export const ActionGroup = (props: ActionGroupProps = {}) => {
  const {
    mode = 'text',
    children,
    elements = [],
    trayIndicator,
    trayIndicatorSettings,
    onClearAll,
  } = props;
  const gapClassName = getGapClassName(mode);
  const [trayOpen, setTrayOpen] = useState(false);

  const { sortedElements, topLevelElements, trayElements } =
    useElementsForActionGroup(elements);

  const trayTrigger = useMemo(() => {
    if (trayIndicator) {
      return trayIndicator?.(trayIndicatorSettings);
    }

    const color = elements?.[0]?.iconColor ?? 'secondary';

    return (
      <Button
        variant="text"
        color={color}
        size="small"
        rightIcon="action-options-alt"
        rightIconSettings={{
          size: 'xl',
          ...trayIndicatorSettings,
        }}
      />
    );
  }, [trayIndicator, elements, trayIndicatorSettings]);

  const TrayTriggerComponent = useCallback(() => {
    return cloneElement(trayTrigger, {
      onClick: () => setTrayOpen((o) => !o),
      qa: {
        action: 'tray-trigger',
      },
    });
  }, [trayTrigger]);

  const handleCloseTray = useCallback(() => {
    setTrayOpen(false);
  }, []);

  if (children && elements.length) {
    throw Error('ActionGroup cannot have both children and elements');
  }

  return (
    <ActionGroupContextProvider>
      <div
        className={merge(
          getKDSClasses('action-group'),
          'inline-flex relative',
          gapClassName
        )}
      >
        {children}
        {elements.length ? (
          <div className="flex">
            <div
              className={merge(
                trayElements.length
                  ? 'hidden @lg/actionBar:inline-flex'
                  : 'inline-flex'
              )}
              data-qa-variant="large"
            >
              <ActionGroup mode={mode}>
                {sortedElements.map((element) => {
                  return (
                    <ActionItem
                      key={`main-${element.icon}-${element.label}`}
                      element={element}
                    />
                  );
                })}
              </ActionGroup>
            </div>
            {trayElements.length ? (
              <>
                <div
                  data-qa-variant="small"
                  className={merge('inline-flex @lg/actionBar:hidden')}
                >
                  <ActionGroup mode={mode}>
                    {topLevelElements.map((element) => {
                      return (
                        <ActionItem
                          key={`mobile-${element.icon}-${element.label}`}
                          element={element}
                          collapsed
                        />
                      );
                    })}
                    <TrayTriggerComponent />
                  </ActionGroup>
                </div>
                <Tray
                  className={merge('block @lg/actionBar:hidden')}
                  open={trayOpen}
                  elements={trayElements}
                  onClose={handleCloseTray}
                  onClearAll={onClearAll}
                />
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    </ActionGroupContextProvider>
  );
};
