import KizenTypography from 'app/kizentypo';
import {
  AREA_RESPONSES,
  SALES_REPORT_TYPES,
} from 'components/Wizards/RTDV/types';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'components/Inputs/ButtonGroup';
import { VALUE_TO_DISPLAY_RESPONSES } from 'components/Wizards/Dashlet/types';
import { DATAPOINT_FREQUENCY_REPONSES } from 'components/Wizards/Dashlet/types';
import { StyledSwitch } from '../styles';
import DateInput from 'components/Inputs/DateInput';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import useCustomObjectDetails from 'components/Wizards/shared/hooks/useCustomObjectDetails';

const SalesRecordsWonReportType = ({ mobile, objectId, contacts }) => {
  const { t } = useTranslation();

  const { state, setState } = useContext(WizardStateContext.Context);
  const {
    reportType,
    value,
    datapointFrequency,
    salesReportType,
    customEndDate,
    endDate,
  } = state;

  const { data, isLoading } = useCustomObjectDetails({
    objectId,
    contacts,
    enabled: reportType?.value === AREA_RESPONSES.SALES,
  });

  const includeWeightedValue = data?.includePercentageToClose ?? false;

  const totalValueOption = useMemo(() => {
    return {
      value: VALUE_TO_DISPLAY_RESPONSES.TOTAL,
      label: t('Total Value'),
    };
  }, [t]);

  const weightedValueOption = useMemo(() => {
    return {
      value: VALUE_TO_DISPLAY_RESPONSES.WEIGHTED,
      label: t('Weighted Value'),
    };
  }, [t]);

  useEffect(() => {
    if (
      !isLoading &&
      data &&
      ![
        VALUE_TO_DISPLAY_RESPONSES.TOTAL,
        VALUE_TO_DISPLAY_RESPONSES.WEIGHTED,
      ].includes(value?.value) &&
      reportType?.value === AREA_RESPONSES.SALES
    ) {
      if (includeWeightedValue) {
        setState('value', weightedValueOption);
      } else {
        setState('value', totalValueOption);
      }
    }
  }, [
    data,
    includeWeightedValue,
    isLoading,
    setState,
    t,
    totalValueOption,
    value?.value,
    weightedValueOption,
    reportType,
  ]);

  const valueOptions = useMemo(() => {
    return [
      totalValueOption,
      includeWeightedValue && weightedValueOption,
    ].filter(Boolean);
  }, [includeWeightedValue, totalValueOption, weightedValueOption]);

  const datapointFrequencyOptions = useMemo(() => {
    return [
      {
        value: DATAPOINT_FREQUENCY_REPONSES.WEEKLY,
        label: t('Weekly'),
      },
      {
        value: DATAPOINT_FREQUENCY_REPONSES.MONTHLY,
        label: t('Monthly'),
      },
      {
        value: DATAPOINT_FREQUENCY_REPONSES.QUARTERLY,
        label: t('Quarterly'),
      },
      {
        value: DATAPOINT_FREQUENCY_REPONSES.YEARLY,
        label: t('Yearly'),
      },
    ];
  }, [t]);

  useDefaultValues(
    {
      datapointFrequency: datapointFrequencyOptions[1],
    },
    reportType?.value === AREA_RESPONSES.SALES
  );

  // Handles the toggle switch to enable custom end date
  const onChangeCustomEndDate = useCallback(
    (e) => {
      return setState('customEndDate', e.target.checked);
    },
    [setState]
  );

  // Handles selecting a date in the picker
  const onChangeEndDate = useCallback(
    (value) => {
      //   YYYY-MM-DD
      const formatted = `${value.getFullYear()}-${String(
        value.getMonth() + 1
      ).padStart(2, '0')}-${String(value.getDate()).padStart(2, '0')}`;

      setState('endDate', formatted);
    },
    [setState]
  );

  if (
    reportType?.value !== AREA_RESPONSES.SALES ||
    salesReportType?.value !==
      SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME
  ) {
    return null;
  }

  return (
    <>
      <StyledWizardField top={12}>
        <KizenTypography type="subheader">
          {t('Choose Value to Display')}
        </KizenTypography>
        <StyledWizardField top={6}>
          <ButtonGroup
            value={value}
            onChange={(v) => setState('value', v)}
            sizing={!mobile ? 'auto' : ''}
            options={valueOptions}
            className="wizard-buttons value-to-display"
            columns={mobile ? 2 : 4}
            mode="wizard"
            gutter={`${mobile ? 10 : 15}px`}
          />
        </StyledWizardField>
      </StyledWizardField>
      <StyledWizardField top={30}>
        <KizenTypography type="subheader">
          {t('Choose Datapoint Frequency')}
        </KizenTypography>
        <StyledWizardField top={6}>
          <ButtonGroup
            value={datapointFrequency}
            onChange={(v) => setState('datapointFrequency', v)}
            sizing={!mobile ? 'auto' : ''}
            options={datapointFrequencyOptions}
            className="wizard-buttons datapoint-frequency"
            columns={mobile ? 2 : 4}
            mode="wizard"
            gutter={`${mobile ? 10 : 15}px`}
          />
        </StyledWizardField>
      </StyledWizardField>
      <StyledWizardField top={30}>
        <KizenTypography type="subheader">
          {t('Custom End Date (All Time Default)')}
        </KizenTypography>
        <StyledWizardField top={6}>
          <StyledSwitch
            checked={customEndDate ?? false}
            onChange={onChangeCustomEndDate}
            className="custom-end-date-switch"
          />
        </StyledWizardField>
      </StyledWizardField>
      {customEndDate ? (
        <StyledWizardField>
          <KizenTypography>{t('End Date')}</KizenTypography>
          <DateInput
            variant="outline"
            onChange={onChangeEndDate}
            value={endDate}
            inModal
            fullWidth
          />
        </StyledWizardField>
      ) : null}
    </>
  );
};

export default SalesRecordsWonReportType;
