import { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { grayScale, colorsButton } from 'app/colors';
import { ICON_BUTTON_SIZING } from 'components/Kizen/IconButton';
import useModal from 'components/Modals/useModal';
import { ScheduleActivityModal } from 'components/Charts/ScheduledActivities/ScheduleActivityModal';
import ActivityService from 'services/ActivityService';
import {
  SCHEDULE_ACTIVITY_SUCCESS,
  SCHEDULE_ACTIVITY_FAIL,
} from 'pages/Common/Actions/activityHelpers';
import CustomActivityActions from 'pages/Common/Actions/CustomActivityActions';
import CustomAutomationActions from 'pages/Common/Actions/CustomAutomationActions';
import { toastVariant, useToast } from 'components/ToastProvider';
import { getOriginalError } from 'services/AxiosService';

const IconBar = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${({ condensed }) => (condensed ? 'flex-end' : 'center')};
  position: relative;

  // Handle the StagedSelect wrapped in a div to style the menu header/footer
  > a,
  > div > a,
  > button,
  > div > button {
    transition: box-shadow 0.5s ease-in-out;
    transition: padding 0.5s ease-in-out;
    padding: ${({ condensed }) => (condensed ? '0 10px' : '0 15px')};

    > i {
      transition-property: opacity;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }

    &:hover i {
      color: ${grayScale.dark};
    }
  }
  &.is-scrolled {
    > a,
    div > a,
    > button,
    > div > button {
      cursor: default;
      border-color: transparent;
      box-shadow: none;
      > i {
        opacity: 0 !important;
      }
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 10px;
  margin-right: 10px;
  && > :first-child {
    color: ${({ color }) =>
      typeof color === 'string' ? color : color.light || color.hover};
  }
  &&:hover > :first-child {
    color: ${({ color }) =>
      typeof color === 'string' ? color : color.dark || color.default};
  }
`;

const ActionBar = ({
  condensed = false,
  customObject,
  customObjectModel,
  scrolled,
  onStartAutomation,
  logActivityActions,
  activityList,
  defaultOnActivities,
  canStartAutomations,
  customObjectId,
  ...props
}) => {
  const { t } = useTranslation();

  const [showLogActivity, setShowLogActivity] = useState(false);
  const actionButtonRef = useRef(null);
  const [showToast] = useToast();

  const [showSelectedAutomation, setShowSelectedAutomation] = useState();
  const automationButtonRef = useRef(null);

  const [predefinedOptions, setPredefinedOptions] = useState({});

  const [isSchedulingActivity, setSchedulingActivity] = useState(false);

  const [, , scheduleActivityModal] = useModal();

  return (
    <IconBar
      className={scrolled ? 'is-scrolled' : ''}
      condensed={condensed}
      {...props}
      scrolled={scrolled}
    >
      {defaultOnActivities ? (
        <>
          {/* log activity */}
          <StyledIconButton
            sizing={ICON_BUTTON_SIZING.overlay}
            color={colorsButton.iconGray}
            borderColor={grayScale.medium}
            onClick={() => setShowLogActivity(true)}
            ref={actionButtonRef}
          >
            <Icon icon="clipboard-check" />
          </StyledIconButton>
          <CustomActivityActions
            {...props}
            showLogActivity={showLogActivity}
            target={actionButtonRef.current}
            customObject={customObject}
            customObjectModel={customObjectModel}
            onSetVisible={(val) => setShowLogActivity(val)}
            scrolled={scrolled}
            onShowScheduleActivity={() => scheduleActivityModal.show()}
            setPredefinedOptions={(val) => setPredefinedOptions(val)}
            shownScrolled={false}
            isCustom
            logActivityActions={logActivityActions}
            activityList={activityList}
          />
        </>
      ) : null}
      {/* automation */}
      {canStartAutomations && customObject?.access?.edit ? (
        <StyledIconButton
          sizing={ICON_BUTTON_SIZING.overlay}
          color={colorsButton.iconGray}
          borderColor={grayScale.medium}
          onClick={() => setShowSelectedAutomation(true)}
          ref={automationButtonRef}
        >
          <Icon icon="sync" />
        </StyledIconButton>
      ) : null}
      <CustomAutomationActions
        {...props}
        showSelectedAutomation={showSelectedAutomation}
        target={automationButtonRef.current}
        customObject={customObject}
        onSetVisible={(val) => setShowSelectedAutomation(val)}
        scrolled={scrolled}
        predefinedOptions={predefinedOptions}
        shownScrolled={false}
        scheduleActivityModal={scheduleActivityModal}
        onStartAutomation={onStartAutomation}
        customObjectId={customObjectId}
      />
      <ScheduleActivityModal
        key={scheduleActivityModal.key}
        predefinedOptions={{
          ...predefinedOptions, // may override client and company options
          objectId: customObjectModel.id,
        }}
        show={scheduleActivityModal.showing}
        onHide={scheduleActivityModal.hide}
        onConfirm={async (activityInfo) => {
          setSchedulingActivity(true);
          try {
            scheduleActivityModal.hide();
            await ActivityService.v2CreateScheduledActivities(activityInfo);
            showToast({
              variant: toastVariant.SUCCESS,
              message: SCHEDULE_ACTIVITY_SUCCESS(t),
            });
          } catch (err) {
            const orig = getOriginalError(err);
            showToast({
              variant: toastVariant.FAILURE,
              message: orig?.message || SCHEDULE_ACTIVITY_FAIL(t),
            });
          } finally {
            setSchedulingActivity(false);
          }
        }}
        disabled={isSchedulingActivity}
      />
    </IconBar>
  );
};

export default ActionBar;
