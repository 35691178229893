import DraggableStepCard from '../../DraggableStepCard';
import { getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

const getNotifyViaTextString = ({ teamMember }, t) => {
  if (!teamMember?.type) return 'no type';
  const roleLabel = getValueOrDeleted(teamMember?.role?.label, t);
  const fieldLabel = getValueOrDeleted(teamMember?.field?.label, t);
  const mapping = {
    last_active: 'Last Team Member (Any Role)',
    last_active_role: `Last Team Member (${roleLabel} Role)`,
    employee: getValueOrDeleted(teamMember?.employee?.label, t),
    owner: t('Owner'),
    team_selector_field: `${t('Custom Team Selector')} (${fieldLabel} ${t(
      'Field'
    )})`,
  };
  return mapping[teamMember.type.value];
};

export const getDescription = ({ step, t }) => {
  const { details } = step;
  return `Notify '${getNotifyViaTextString(details, t)}' via Text`;
};

export default function NotifyMemberViaTextCard({ step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
