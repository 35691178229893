import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import Button from 'components/Button';
import { StyledFooter } from './styles';

const EditDashboardsButton = ({ children, ...props }) => {
  const { t } = useTranslation();
  const {
    handleClickEditDashboards,
    handleClickAddDashboard,
    setIsOpen,
    entitySingular,
    entityPlural,
  } = props.selectProps;

  const handleClickEdit = useCallback(() => {
    handleClickEditDashboards();
    setIsOpen(false);
  }, [handleClickEditDashboards, setIsOpen]);

  const handleClickAdd = useCallback(() => {
    handleClickAddDashboard();
    setIsOpen(false);
  }, [handleClickAddDashboard, setIsOpen]);

  return (
    <components.Menu {...props}>
      {children}
      {handleClickEditDashboards || handleClickAddDashboard ? (
        <StyledFooter>
          {handleClickEditDashboards ? (
            <Button
              className="apply-button"
              variant="text"
              color="blue"
              noSpace
              onClick={handleClickEdit}
            >
              {t('Manage')} {entityPlural ?? t('Dashboards')}
            </Button>
          ) : null}
          {handleClickAddDashboard ? (
            <Button
              className="apply-button"
              variant="text"
              color="green"
              noSpace
              onClick={handleClickAdd}
            >
              {t('Add')} {entitySingular ?? t('Dashboard')}
            </Button>
          ) : null}
        </StyledFooter>
      ) : null}
    </components.Menu>
  );
};

EditDashboardsButton.propTypes = {
  children: PropTypes.array,
  handleClickEditDashboards: PropTypes.func,
};

export default EditDashboardsButton;
