import { builderFieldWithMutablePlaceholderTypes } from '@kizen/page-builder/internal/utility/constants';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';

/**
 * Checks if consumers of the builder would want to do something (currently saving)
 * with the node's field data. field.name signifies that the node is an input element
 * and not a layout element (Section, Row) or a Button.
 * @param {object} node - craftjs node (https://craft.js.org/r/docs/api/node)
 * @returns boolean
 */
export const shouldNodeBeSaved = (node) => {
  return !!node.data.props.field?.name;
};

export const isNodeCustomField = (node) => {
  return Boolean(node.data.props?.field?.customObjectField?.id);
};

export const isNodeSubmitButton = (node) => {
  return Boolean(node.data?.custom?.isSubmitButton);
};

/**
 * There are two required, default custom object fields created for new forms that should never be deleted.
 * Contact forms     -> email
 * Non-contact forms -> name
 *
 * Additionally, the submit button cannot be deleted. Submit buttons are created by default and include the
 * custom property `isSubmitButton` which is set in {@link ../buildNodes.js#buildSubmitButtonData}
 *
 * Only check the `field.name` property (not `field.customObjectField.name`). customObjectField will be the
 * same for duplicated fields.
 *
 * @param {object} node - craftjs node (https://craft.js.org/r/docs/api/node)
 * @returns - boolean
 */
export const canNodeBeDeleted = (node, isContactForm) => {
  if (isNodeSubmitButton(node)) {
    return false;
  }

  if (!isNodeCustomField(node)) {
    return true;
  }

  const { field } = node.data.props;

  if (isContactForm && field.name === 'email') {
    return false;
  }

  if (field.name === 'name') {
    return false;
  }

  return true;
};

export const isPlaceholderMutable = (fieldType) =>
  builderFieldWithMutablePlaceholderTypes.some((type) => type === fieldType);

export const canNodeBeCustomized = (node) => {
  const { props } = node.data;

  if (!isNodeCustomField(node)) {
    return true;
  }
  return !props.field?.isDefault && props.field?.isDeletable;
};

const NOT_NULLABLE_FIELD_NAMES = [
  DEFAULT_FIELD_NAMES.owner,
  DEFAULT_FIELD_NAMES.stage,
];
const NOT_NULLABLE_FIELD_TYPES = [
  FIELD_TYPES.PhoneNumber.type,
  FIELD_TYPES.Timezone.type,
];

export const isFieldNullable = ({
  allowsNulls,
  isRequired,
  name,
  fieldType,
  customObjectField,
}) => {
  return (
    customObjectField &&
    allowsNulls &&
    !isRequired &&
    !NOT_NULLABLE_FIELD_NAMES.some((n) => n === name) &&
    !NOT_NULLABLE_FIELD_TYPES.some((t) => t === fieldType)
  );
};
