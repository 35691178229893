import * as actions from './metaDataActions';
import * as reducers from './metaDataReducers';

/* -------------------- */
// Default State
/* -------------------- */
export const filterMetaDataInitial = {
  name: null,
  version: null,
  definition: {},
};

/* -------------------- */
// Reducer Mapping
/* -------------------- */
export const filterMetaDataHandlers = {
  [actions.FILTER_META_DATA_SUCCESS]: reducers.filterMetaDataSuccess,
};
