import { ISubscription, SyncTuple, sync } from '@thi.ng/rstream';
import { comp } from '@thi.ng/transducers';
import { PickRequired } from './types';
import {
  AndRuleDef,
  CombinatorRuleDef,
  OrRuleDef,
  RuleDef,
  RuleResult,
  SingleRuleDef,
  TransducerConfig,
} from './types';
import {
  getRuleSyncKey,
  isCombinatorRule,
  isAndRule,
  isOrRule,
  missingSourceStream,
} from './utils';
import {
  andTransducer,
  orTransducer,
  getCombinatorResultTransducer,
  ReturnResult,
  getSingleResultTransducer,
  CombinatorResult,
} from './xform';

const isFullyQualifiedKey = (key: string) => !key.startsWith('__');

const getCombinatorSyncSources = <V extends Partial<unknown>>(
  sources: Map<string, ISubscription<any, V>>,
  rule: CombinatorRuleDef<any, any, any>,
  xforms: TransducerConfig<V>,
  parentKey?: string
): Record<string, ISubscription<RuleResult<V>, RuleResult<V>>> => {
  return rule[1].reduce((acc: any, rule: RuleDef<any, any, any>, idx) => {
    const comb_key = getRuleSyncKey(rule, idx);
    if (isOrRule(rule)) {
      acc[comb_key] = new OrRule(rule, sources, xforms, parentKey).result;
      return acc;
    } else if (isCombinatorRule(rule) && isAndRule(rule)) {
      acc[comb_key] = new AndRule(rule, sources, xforms, parentKey).result;
      return acc;
    }

    if (!isCombinatorRule(rule)) {
      const key = getRuleSyncKey(rule, idx);
      const r = new Rule(rule, sources, xforms, parentKey);
      acc[key] = r.result;
      return acc;
    }
    return acc;
  }, {});
};

export class Rule<V> {
  source: ISubscription<any, V>;
  rule_stream: ISubscription<V, PickRequired<RuleResult<Partial<V>>, 'result'>>;

  constructor(
    private rule: SingleRuleDef<any, any, any>,
    private sources: Map<string, ISubscription<any, V>>,
    private xforms: TransducerConfig<V>,
    private parentKey?: string
  ) {
    if (!isFullyQualifiedKey(this.rule[0]) && !this.parentKey) {
      throw Error(
        'Rule cannot specify a source starting with "__" without also specifying a parent key'
      );
    }

    const source_key = isFullyQualifiedKey(rule[0])
      ? this.rule[0]
      : `${parentKey}${this.rule[0]}`;

    this.source = this.sources.get(source_key)!;
    const op_xform = this.xforms[this.rule[1]](this.rule);
    const result_xform = getSingleResultTransducer<V>(this.rule);
    const xform = result_xform ? comp(op_xform, result_xform) : op_xform;
    this.rule_stream = this.source
      ? this.source.transform(xform)
      : missingSourceStream<V>().transform(xform);
  }

  get result() {
    return this.rule_stream;
  }
}

export class AndRule<V extends Partial<unknown>> {
  result_stream:
    | ISubscription<
        SyncTuple<Record<string, ISubscription<RuleResult<V>, RuleResult<V>>>>,
        ReturnResult<V>
      >
    | ISubscription<
        SyncTuple<Record<string, ISubscription<RuleResult<V>, RuleResult<V>>>>,
        PickRequired<CombinatorResult<V>, 'result'>
      >;

  constructor(
    private rule: AndRuleDef<any, any, any>,
    private sources: Map<string, ISubscription<any, V>>,
    private xforms: TransducerConfig<V>,
    private parentKey?: string
  ) {
    const sync_sources = this.getCombinatorSyncSources();
    const synced = sync({ src: sync_sources });
    const and = andTransducer<V>();
    const xform = getCombinatorResultTransducer<V>(rule);
    this.result_stream = xform
      ? synced.transform(comp(and, xform))
      : synced.transform(and);
  }

  get result() {
    return this.result_stream;
  }

  private getCombinatorSyncSources() {
    return getCombinatorSyncSources(
      this.sources,
      this.rule,
      this.xforms,
      this.parentKey
    );
  }
}

export class OrRule<V extends Partial<unknown>> {
  result_stream:
    | ISubscription<
        SyncTuple<Record<string, ISubscription<RuleResult<V>, RuleResult<V>>>>,
        ReturnResult<V>
      >
    | ISubscription<
        SyncTuple<Record<string, ISubscription<RuleResult<V>, RuleResult<V>>>>,
        PickRequired<CombinatorResult<V>, 'result'>
      >;

  constructor(
    private rule: OrRuleDef<any, any, any>,
    private sources: Map<string, ISubscription<any, V>>,
    private xforms: TransducerConfig<V>,
    private parentKey?: string
  ) {
    const sync_sources = this.getCombinatorSyncSources();
    const synced = sync({ src: sync_sources });
    const or = orTransducer<V>();
    const xform = getCombinatorResultTransducer<V>(rule);
    this.result_stream = xform
      ? synced.transform(comp(or, xform))
      : synced.transform(or);
  }

  get result() {
    return this.result_stream;
  }

  private getCombinatorSyncSources() {
    return getCombinatorSyncSources(
      this.sources,
      this.rule,
      this.xforms,
      this.parentKey
    );
  }
}
