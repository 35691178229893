import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { getStreamKey } from '@kizen/permissions/utils';
import { usePermissionValue } from '@kizen/permissions/hooks/usePermissionValue';
import { colorsText } from 'app/colors';
import { truncateCss } from 'app/typography';
import { PermissionRangeInput } from './PermissionRangeInput';
import { Tooltip } from './Tooltip';
import { GridContainer } from './styled';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 15px;
`;

const RowLabel = styled(TextEllipsisWithTooltip)`
  ${truncateCss}
  margin-left: 40px;
  margin-bottom: 15px;
`;

const CategoryLabel = styled(TextEllipsisWithTooltip)`
  ${truncateCss}
  margin-left: 20px;
  margin-bottom: 15px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;

const PermissionLabel = ({
  permissionKey,
  label,
  isRelatedField = false,
  enableRelatedFieldRule = true,
}) => {
  const { t } = useTranslation();
  const tooltip = usePermissionValue(permissionKey, ({ tooltip }) => tooltip);
  const tt = tooltip
    ? tooltip
    : enableRelatedFieldRule && isRelatedField
      ? t(
          'This permission group has all access to the related Object, so there are no restrictions.'
        )
      : null;

  return (
    <LabelContainer>
      <RowLabel>{label}</RowLabel>
      <Tooltip text={tt} show={Boolean(tt)} />
    </LabelContainer>
  );
};

export const FieldPermissionRows = ({
  categoryName,
  sectionId,
  fields,
  enableRelatedFieldRule,
}) => (
  <GridContainer>
    <CategoryLabel color={colorsText.medium}>{categoryName}</CategoryLabel>
    <div />
    {fields.map(({ id, displayName, name, relation }) => {
      const key = getStreamKey(name, sectionId);
      return (
        <>
          <PermissionLabel
            label={displayName}
            permissionKey={key}
            isRelatedField={Boolean(relation?.relatedObject)}
            enableRelatedFieldRule={enableRelatedFieldRule}
          />
          <InputContainer>
            <PermissionRangeInput permissionKey={key} affordance="range" />
          </InputContainer>
        </>
      );
    })}
  </GridContainer>
);
