import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import DragItem from 'components/DragAndDropLayout/DragItem';

export const ListItem = styled.span`
  display: flex;
  max-width: ${({ width }) => `${width}px`};
  flex: 0 0 ${({ width }) => `${width}px`};
  justify-content: space-between;
  z-index: ${layers.content(0, 2)};
  ${({ align }) =>
    align ? `margin-${align === 'left' ? 'right' : 'left'}: auto;` : ''}

  & > div {
    margin-left: auto;
  }

  &:not(:last-child) {
    margin-right: ${({ shrinkMargin }) => (shrinkMargin ? '38px' : '40px')};
  }
`;

export const StyledDragItem = styled(DragItem)`
  ${({ hideHandle }) =>
    hideHandle
      ? css`
          & .itemTarget {
            opacity: 0;
            pointer-events: none;
          }
        `
      : ''}
`;
