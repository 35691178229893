import {
  useLayoutEffect,
  useRef,
  useState,
  ChangeEvent,
  PropsWithoutRef,
  RefObject,
} from 'react';
import { useStore, useReactFlow } from 'reactflow';
import { getFlowContentSize } from '../helpers';

type FromOffset = {
  min: number;
  zoom: number;
  size: number;
  clientSize: number;
};

type Axis = 'x' | 'y';

export const getScrollFromOffset = (
  offset: number,
  { min, zoom, size, clientSize }: FromOffset
) => {
  const scale = clientSize / size;
  return -(min * zoom + offset) * scale;
};

export const getOffsetFromScroll = (
  scroll: number,
  { min, zoom, size, clientSize }: FromOffset
) => {
  const scale = clientSize / size;
  return -(min * zoom + scroll / scale);
};

const getClientWidth = (el: HTMLElement) => el.clientWidth;

const getScrollLeft = (el: HTMLElement) => el.scrollLeft;

const setScrollLeft = (el: HTMLElement, position: number) => {
  el.scrollLeft = position;
};

const getClientHeight = (el: HTMLElement) => el.clientHeight;

const getScrollTop = (el: HTMLElement) => el.scrollTop;

const setScrollTop = (el: HTMLElement, position: number) => {
  el.scrollTop = position;
};

export const useScrollBar = (
  axis: Axis,
  setInteracting?: (interacting: boolean) => void
): [
  size: string,
  props: PropsWithoutRef<{
    onMouseDown: () => void;
    onMouseUp: () => void;
    onScroll: (ev: ChangeEvent<HTMLInputElement>) => void;
  }> & { ref: RefObject<HTMLElement> },
] => {
  const ref = useRef<HTMLElement>(null);
  const [[minX, minY], [maxX, maxY]] = useStore(
    (state) => state.translateExtent
  );
  const [tX, tY, zoom] = useStore((state) => state.transform);
  const { height, width } = useStore((store) => store);
  const { setViewport } = useReactFlow();

  const ignoreNextScrollRef = useRef<boolean | string>(false);

  const min = axis === 'x' ? minX : minY;
  const max = axis === 'x' ? maxX : maxY;
  const size = axis === 'x' ? width : height;
  const offset = axis === 'x' ? tX : tY;
  const getClientSize = axis === 'x' ? getClientWidth : getClientHeight;
  const getClientScroll = axis === 'x' ? getScrollLeft : getScrollTop;
  const setClientScroll = axis === 'x' ? setScrollLeft : setScrollTop;

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    if (ignoreNextScrollRef.current === 'transformed') {
      ignoreNextScrollRef.current = false;
      return;
    }
    ignoreNextScrollRef.current = 'scrolled'; // We don't want this to trigger the scroll handler
    setClientScroll(
      ref.current,
      getScrollFromOffset(offset, {
        min,
        zoom,
        size,
        clientSize: getClientSize(ref.current),
      })
    );
  }, [ref, offset, min, size, zoom, getClientSize, setClientScroll]);

  const flowSize = getFlowContentSize(min, max, zoom);
  const contentSizePrecomputed = `${(100 * flowSize) / size}%`;
  const [contentSize, setContentSize] = useState<string>('0');

  useLayoutEffect(() => {
    // We defer setting the scrollbar size to layout in order
    // to avoid setting it before other updates have taken effect.
    // This prevents the scrollbar from flickering on momentarily
    // when zooming in.
    setContentSize(contentSizePrecomputed);
  }, [contentSizePrecomputed]);

  const onMouseDown = () => setInteracting?.(true);
  const onMouseUp = () => setInteracting?.(false);
  const onScroll = (ev: ChangeEvent<HTMLDivElement>) => {
    if (ignoreNextScrollRef.current === 'scrolled') {
      ignoreNextScrollRef.current = false;
      return;
    }
    ignoreNextScrollRef.current = 'transformed'; // We don't want this to trigger the transform effect

    const tNext = getOffsetFromScroll(getClientScroll(ev.target), {
      min,
      zoom,
      size,
      clientSize: getClientSize(ev.target),
    });

    setViewport({
      x: axis === 'x' ? tNext : tX,
      y: axis === 'x' ? tY : tNext,
      zoom,
    });
  };

  return [
    contentSize,
    {
      ref,
      onMouseDown,
      onMouseUp,
      onScroll,
    },
  ];
};
