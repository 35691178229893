import type { Filter } from '@kizen/filters/filter';
import type { FilterSet } from '@kizen/filters/filter-sets';
import { useFilterSets } from '@kizen/filters/hooks/use-filter-sets';
import { useSelector } from 'react-redux';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { PropsWithChildren, createContext, useContext } from 'react';
import { useFilterVariables } from 'ts-components/filters';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { validate as validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';

type Props = {
  config: Filter | FilterSet | FilterSet[];
  objectId?: string | null;
};

const ctx = createContext({});

export const useFilterDropdownContext = () => useContext(ctx);

/**
 * This is needed to share the useFilterSets instance between the two definitions of
 * ClientFilterDropdown (the table and chart views each define a new component). The state
 * management for ClientFilterDropdown used to be in redux. The new logic was put in a hook
 * and we use the key prop of ClientFilterDropdown to reload the data when changing groups.
 * This causes issues when switching between the chart & table view since there is a different
 * component structure for those pages. We define the useFilterSets hook here and pass the same
 * key prop to this component.
 *
 * @param {*} props.config - the 'loaded' filter config object with Filter class instances.
 */
export const FilterDropdownContext = ({
  config,
  objectId,
  children,
}: PropsWithChildren<Props>) => {
  const metaData = useSelector(selectFilterMetadataDefinition);
  const { variables } = useFilterVariables({ objectId });
  const data = useFilterSets(
    metaData,
    variables,
    isValidPhoneNumber,
    validateEmail.withDomain,
    config
  );
  return <ctx.Provider value={data}>{children}</ctx.Provider>;
};
