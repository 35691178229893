import { useState, useMemo, useCallback, useRef } from 'react';
import { OverlayTrigger } from 'react-bootstrap2';
import cloneDeep from 'lodash/cloneDeep';

import Tooltip, { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { colorsButton, grayScale } from 'app/colors';
import { KizenTypography } from 'app/typography';
import {
  canEditOptionsForActivity,
  isDropdownField,
  isDynamicTagsField,
} from 'checks/fields';
import useField from 'hooks/useField';
import EditableText from 'components/Kizen/EditableText';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';
import useModal from 'components/Modals/useModal';
import BasicModal from 'components/Modals/presets/BasicModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import GenericWizard from 'components/GenericWizard';
import { LIST_OF_FIELDS } from 'components/GenericWizard/wizards/field/config';
import { getItemCols } from 'components/DragAndDropLayout/helpers';
import whichServiceToUse from 'services/utils';
import { FIELD_TYPES } from 'utility/constants';
import {
  MENU_ACTION_SHOW,
  MENU_ACTION_HIDE,
  MENU_ACTION_REQUIRED,
  MENU_ACTION_OPTIONAL,
  MENU_ACTION_DELETE,
  MENU_ACTION_ADD_RULE,
} from './menuActionOptions';
import { setCustomizeFieldBroadcastChannel } from 'components/PageBuilder/utils';
import { useTranslation } from 'react-i18next';
import { getOriginalError } from 'services/AxiosService';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { useDirtyState } from './hooks/useDirtyState';
import Validation, { useValidation } from '../../Inputs/Validation';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { FIELDS_WITH_OPTIONS } from './constants';
import { hasDeletedOptions } from './helpers';
import {
  FieldLayout,
  FieldWrapper,
  LeftContainer,
  NewFieldTypography,
  RequiredAsterisk,
  TextWrapper,
  VisibilityRuleIcon,
} from './styles';
import { FIELD_MARGIN } from './constants';
import { toastVariant, useToast } from '__components/ToastProvider';

export const fieldMargin = FIELD_MARGIN;

export class menuBuildOptions {
  static edit = (field, t) => {
    return canEditOptionsForActivity(field)
      ? [
          {
            value: 'edit-options',
            label: t('Edit Options'),
          },
        ]
      : [];
  };

  static visibility = (
    { isHidden, isHideable, isSuppressed },
    t,
    { showLabel, hideLabel } = {}
  ) => {
    if (isHideable && !isSuppressed) {
      return isHidden
        ? [{ value: MENU_ACTION_SHOW, label: showLabel || t('Show') }]
        : [{ value: MENU_ACTION_HIDE, label: hideLabel || t('Hide') }];
    }

    return [];
  };

  static required = ({ isHidden, isRequired }, t) => {
    if (!isHidden && !isRequired) {
      return [
        {
          value: MENU_ACTION_REQUIRED,
          label: t('Make Required'),
        },
      ];
    }
    if (isRequired) {
      return [{ value: MENU_ACTION_OPTIONAL, label: t('Make Optional') }];
    }
    return [];
  };

  static delete = ({ isDefault, isDeletable }, t) => {
    return isDefault || !isDeletable
      ? []
      : [
          {
            value: MENU_ACTION_DELETE,
            label: t('Delete'),
          },
        ];
  };

  static addRule = (f, t) => {
    if (
      isDropdownField(f) ||
      isDynamicTagsField(f) ||
      (f.fieldType === 'activity_custom_field' &&
        (isDropdownField(f.customObjectField) ||
          isDynamicTagsField(f.customObjectField)))
    ) {
      return [{ value: MENU_ACTION_ADD_RULE, label: t('Add Rule') }];
    }
    return [];
  };

  static default = (field, t) => {
    return [
      ...this.edit(field, t),
      ...this.visibility(field, t),
      ...this.delete(field, t),
    ];
  };
}

export default function BuilderField(props) {
  const {
    objectId,
    field,
    onSelectAction,
    onConfirmDelete,
    onChangeDescription,
    onClickChangeColumnWidth,
    onClickOptions,
    onCloseOptions,
    isDisabled = false,
    disableChangeColumnWidth = false,
    children,
    menuOptions = null,
    canDeleteFields,
    confirmDeleteMessage,
    isHiddenByRule = false,
    isRuleCondition = false,
    onHiddenByRuleClick,
    onRuleConditionClick,
    ...others
  } = props;

  const [tooltipProps, tooltip] = useTruncationTooltip();

  const { t } = useTranslation();

  const [confirmDeletionModalProps, , confirmDeletionModal] = useModal({
    handleSubmit: async () => {
      await onConfirmDelete(field);
    },
  });
  const [stagedDisplayName, setStagedDisplayName] = useField(
    field.displayName,
    [field]
  );

  const optionsForMenu = useMemo(
    () =>
      (menuOptions || menuBuildOptions.default(field, t)).filter((option) =>
        option?.value === 'delete' ? canDeleteFields : option
      ),
    [menuOptions, field, canDeleteFields, t]
  );

  // This is a funky little piece of state required to allow the menu to appear on top of sibling items
  const [bringToTop, setBringToTop] = useState(false);
  const [forceToTop, setForceToTop] = useState(false);

  const cols = getItemCols(field);

  const handleSelectAction = (val) => {
    if (val.value === MENU_ACTION_DELETE) {
      confirmDeletionModal.show();
    } else if (onSelectAction) {
      onSelectAction(val, field);
    }
    // Called here instead of passed to IconButtonMenu's onClose handler to workaround
    // a z-indexing issue stemming from the timing of the menu's onHide handler relative
    // to selection handling when a field's options menu hangs over a different category
    onCloseOptions();
  };

  const hasItemsInMenu = optionsForMenu.length !== 0;

  const handleOnClickOptions = useCallback(() => {
    if (onClickOptions) {
      onClickOptions();
    }
    setForceToTop(true);
  }, [onClickOptions, setForceToTop]);

  const fieldRef = useRef();
  const [validation, validationProps] = useValidation(fieldRef, {
    value: stagedDisplayName,
    validate: {
      full: (desc) => {
        if (!desc.trim()) {
          return t('This field is required');
        }
        if (onChangeDescription) {
          onChangeDescription(stagedDisplayName, field);
        }
        return true;
      },
    },
  });

  return (
    <FieldWrapper
      disabled={field.isHidden}
      bringToTop={bringToTop}
      forceToTop={forceToTop}
      onFocus={() => setBringToTop(true)}
      onBlur={() => setBringToTop(false)}
      dashedBorder={isHiddenByRule}
      {...others}
    >
      <FieldLayout>
        <LeftContainer>
          <TextWrapper
            data-qa="field-name"
            required={field.isRequired}
            asteriskColor={
              isHiddenByRule ? colorsButton.blue.hover : colorsButton.red.hover
            }
          >
            {tooltip}
            <EditableText
              ref={fieldRef}
              value={stagedDisplayName}
              onChange={setStagedDisplayName}
              disabled={isDisabled}
              {...tooltipProps}
              {...validation}
            />
            <Validation inModal {...validationProps} />
            {field.isRequired && !isHiddenByRule && (
              <RequiredAsterisk>*</RequiredAsterisk>
            )}
            {field.isRequired && isHiddenByRule && (
              <OverlayTrigger
                overlay={<Tooltip label={t('Conditionally Required')} />}
              >
                <RequiredAsterisk
                  hasTooltip
                  color={colorsButton.blue.hover}
                  onClick={onHiddenByRuleClick}
                >
                  *
                </RequiredAsterisk>
              </OverlayTrigger>
            )}
          </TextWrapper>
          {isRuleCondition && (
            <VisibilityRuleIcon
              icon="code-branch-light"
              label={t('Rule Condition')}
              onClick={onRuleConditionClick}
            />
          )}
          {isHiddenByRule && (
            <VisibilityRuleIcon
              icon="hidden"
              label={t('Hidden via Rule')}
              width={18.75}
              onClick={onHiddenByRuleClick}
            />
          )}
        </LeftContainer>
        <div>
          {hasItemsInMenu && (
            <IconButtonMenu
              overlay
              overlayOffsetY={10}
              position="right"
              sizing="dense"
              title={t('Edit Field')}
              color={colorsButton.iconGray}
              onChange={handleSelectAction}
              onOpen={handleOnClickOptions}
              onClose={() => setForceToTop(false)}
              options={optionsForMenu}
            >
              <Icon icon="three-dot" />
            </IconButtonMenu>
          )}
          {!disableChangeColumnWidth && (
            <IconButton
              disabled={field.isHidden}
              title={cols === 1 ? t('Grow Item') : t('Shrink Item')}
              sizing="dense"
              color={field.isHidden ? grayScale.medium : colorsButton.iconGray}
              onClick={() => {
                if (onClickChangeColumnWidth) {
                  onClickChangeColumnWidth(cols === 1 ? 2 : 1, field);
                }
              }}
            >
              <Icon icon={cols === 1 ? 'arrow-to-right' : 'arrow-to-left'} />
            </IconButton>
          )}
        </div>
      </FieldLayout>
      {children}
      <ConfirmDeletionModal {...confirmDeletionModalProps}>
        {confirmDeleteMessage ||
          (!field?.relation ? (
            t(
              'This will permanently delete the Field and all associated data with it from your database.'
            )
          ) : (
            <KizenTypography>
              {t(
                'This will permanently delete the Field and all associated data with it ' +
                  'from your database. Since this is a relationship field, it will also ' +
                  'delete the other related field'
              )}{' '}
              {field?.relation?.relatedName}:{' '}
              {field?.relation?.relatedObjectName} {t('and all of its data.')}
            </KizenTypography>
          ))}
      </ConfirmDeletionModal>
    </FieldWrapper>
  );
}

export function NewField({ onClick, label = '+ Add New Field' }) {
  return (
    <NewFieldTypography
      as="span"
      weight="bold"
      size="buttonLabel"
      textTransform="uppercase"
      onClick={onClick}
    >
      {label}
    </NewFieldTypography>
  );
}

export function FieldWizard({
  show = false,
  onHide,
  context = EMPTY_OBJECT,
  onConfirm,
}) {
  const [wizardData, setWizardData] = useState({});
  const [canBeSaved, setCanBeSaved] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const [showToast] = useToast();
  // set which service is in use
  const serviceInUse = useMemo(
    () => whichServiceToUse(context.model),
    [context]
  );

  const handleSave = async () => {
    setCanBeSaved(false);
    const field = LIST_OF_FIELDS.find((f) => f.type === wizardData.type);

    // Clone to allow safe, submission-specific (ok for React to ignore e.g.
    // if submission fails and user ends up further interacting with
    // field editor somehow) mutation of form data
    const preSend = cloneDeep(wizardData);

    if (field.onBeforeSave) {
      await field.onBeforeSave(preSend, field);
    }

    const {
      name,
      displayName,
      category,
      type,
      options,
      phonenumberOptions,
      moneyOptions,
      relation,
      rating,
      meta,
    } = preSend;

    const payload = {
      model: context.model.id,
      category: category.value,
      name,
      displayName,
      ...(options &&
        options.length &&
        // we don't want to send options if it's an existing dynamic tags field, as we update tags options in tag manager directly
        !(context.field && type === FIELD_TYPES.DynamicTags.type) && {
          options,
        }),

      ...(type === FIELD_TYPES.PhoneNumber.type && {
        phonenumberOptions,
      }),
      ...(type === FIELD_TYPES.Rating.type && {
        rating,
      }),
      ...(type === FIELD_TYPES.Money.type && {
        moneyOptions,
      }),
      ...(type === FIELD_TYPES.Relationship.type && {
        relation,
      }),
      ...(meta !== undefined && {
        meta,
      }),
      // data for create only
      ...(!context.field && {
        name: undefined,
        isDefault: false,
        fieldType: type,
        isRequired: false,
        allowsNulls: true,
        allowsEmpty: true,
        isReadOnly: false,
        isHidden: false,
      }),
    };

    // TODO: I think there's a helper for this
    Object.keys(payload).forEach(
      (key) => payload[key] == null && delete payload[key]
    );
    try {
      if (context.field) {
        const bc = setCustomizeFieldBroadcastChannel(context.field.id);
        const res = await serviceInUse.patchObjectField(
          context.model.id,
          context.field.id,
          payload
        );
        if (res) {
          bc.postMessage(payload);
        }
        bc.close();
      } else {
        await serviceInUse.createObjectField(context.model.id, payload);
      }
    } catch (err) {
      const orig = getOriginalError(err);
      if (!orig) throw err;
      const suppressError =
        orig?.errors?.relation?.inverse_relation_suppressed?.[0];
      if (suppressError) {
        showToast({
          message: suppressError,
          variant: toastVariant.FAILURE,
        });
        return;
      }
      setErrors({
        displayName: orig.display_name,
        name: orig.name,
      });
      return;
    }
    onConfirm();
  };

  const initialDataRef = useRef(null);

  const onChange = ({ data, isComplete }) => {
    if (!initialDataRef.current && data.category?.value) {
      //assume that inital data is ready when category is set
      initialDataRef.current = data;
    }
    setWizardData(data);
    setCanBeSaved(isComplete);
    setErrors({});
  };

  const { field } = context;

  const { showConfirmation, onCheckToHide, onHideConfirmed, onHideCanceled } =
    useDirtyState(initialDataRef.current, wizardData, onHide);

  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  const handleSaveWithConfirmation = () => {
    if (
      field &&
      FIELDS_WITH_OPTIONS.includes(field.fieldType) &&
      hasDeletedOptions(initialDataRef.current, wizardData)
    ) {
      setShowSaveConfirmation(true);
    } else {
      handleSave();
    }
  };

  return (
    <>
      <BasicModal
        disabled={!canBeSaved}
        fitContent={false}
        heading={field ? t('Edit Field') : t('Add New Field')}
        onConfirm={handleSaveWithConfirmation}
        onHide={onCheckToHide}
        show={show}
        size="medium"
        typeOfContent="wizard"
      >
        <GenericWizard
          wizard="FieldWizard"
          onChange={onChange}
          data={context}
          errors={errors}
        />
      </BasicModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        show={showConfirmation}
        onConfirm={onHideConfirmed}
        onHide={onHideCanceled}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
      <ConfirmationModal
        heading={t('Confirm Record Modification')}
        buttonText={t('Confirm')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="green"
        show={showSaveConfirmation}
        onConfirm={async () => {
          handleSave();
          setShowSaveConfirmation(false);
        }}
        onHide={() => setShowSaveConfirmation(false)}
      >
        {t(
          'You are deleting one or more options. This will result in removing all values from records with this option selected. Are you sure?'
        )}
      </ConfirmationModal>
    </>
  );
}
