import { forwardRef } from 'react';
import { Child, ChildrenContainer, IconContainer, Notice } from './styles';
import { NavLink } from 'react-router-dom';
import Icon from 'components/Kizen/Icon';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { useTranslation } from 'react-i18next';

const getLabel = (item) => {
  return item?.display_names?.records?.[0]?.display_name ?? '';
};

export const DynamicChildren = forwardRef(
  ({ items, mobile, offset, shouldShrinkToLeft, parent }, forwardedRef) => {
    const { t } = useTranslation();
    return (
      <ChildrenContainer
        collapse
        ref={forwardedRef}
        mobile={mobile}
        offset={offset}
        shouldShrinkToLeft={shouldShrinkToLeft}
      >
        {items?.map((child, index) => {
          return (
            <Child
              key={`${parent.id}-${child.path}-${index}`}
              full
              as={NavLink}
              to={child.path}
              exact
            >
              <IconContainer className={child.path}>
                <Icon icon="history" />
              </IconContainer>
              <TextEllipsisWithTooltip>
                {getLabel(child)}
              </TextEllipsisWithTooltip>
            </Child>
          );
        })}
        {!items?.length ? (
          <Notice key={`${parent.id}-empty`} full as={'div'}>
            <TextEllipsisWithTooltip>
              {t('No Recent Records')}
            </TextEllipsisWithTooltip>
          </Notice>
        ) : null}
      </ChildrenContainer>
    );
  }
);
