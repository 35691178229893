import { useEffect } from 'react';
import { useTimeZoneNotice } from 'hooks/useTimeZoneNotice';
import { useTranslation } from 'react-i18next';

export const useBroadcastTimeZoneNotice = () => {
  const { t } = useTranslation();
  const { handleShowNotice, showNotice, isLoading } =
    useTimeZoneNotice('broadcast_calendar');
  useEffect(() => {
    if (showNotice && !isLoading) {
      handleShowNotice(
        t(
          'Your timezone differs from the business timezone. All broadcasts are displayed in the business timezone.'
        )
      );
    }
  }, [showNotice, handleShowNotice, isLoading, t]);
};
