import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryObjectUploadContext } from '../../context';
import {
  OnChangeMatchingRuleHandler,
  MatchingRuleSettings,
} from './MatchingRuleSettings';
import { StyledMatchingRuleList, StyledAddRuleButton } from './styles';
import { StyledLoader } from '__pages/SmartConnectors/SmartConnector/steps/styles';
import { canFieldBeMatchedToArray } from '../../helpers';
import { SMART_FIELD_TYPES } from '__pages/SmartConnectors/constants';
import { GenericOption } from '__pages/SmartConnectors/types';

export const MatchingRulesList = () => {
  const { t } = useTranslation();
  const {
    smartConnector,
    customObject,
    loadId,
    stepData,
    setStepData,
    metaData,
    loading,
    setErrors,
    allVariables,
  } = useContext(PrimaryObjectUploadContext);

  useEffect(() => {
    const hasMatchingRules =
      loadId !== 'new' &&
      Boolean(
        smartConnector.flow.loads?.find(({ id }) => id === loadId)
          ?.matching_rules?.length
      );

    if (!hasMatchingRules) {
      const uniqueIdentifierField = customObject?.fields?.find(
        ({ name }) => name === 'name' || name === 'email'
      )?.id;

      const matchingRules = metaData.matching_rules;

      setStepData((prev) => ({
        ...prev,
        loads: (prev.loads || []).map((load) =>
          (loadId === 'new' && !load.id) || loadId === load?.id
            ? {
                ...load,
                matching_rules: [
                  {
                    id: `new_${Date.now()}`,
                    order: 0,
                    field: uniqueIdentifierField,
                    variable: null,
                    no_match_action: matchingRules.no_match_actions[0]?.value,
                    single_match_action:
                      matchingRules.single_match_actions[0]?.value,
                    multiple_match_action:
                      matchingRules.multiple_match_actions[0]?.value,
                    match_archive_action:
                      matchingRules.match_archive_actions[0]?.value,
                  },
                ],
              }
            : load
        ),
      }));
    }
  }, [setStepData, customObject, loadId, metaData, smartConnector.flow.loads]);

  const onChangeMatchingRuleSettings: OnChangeMatchingRuleHandler = ({
    id,
    prop,
    value,
  }) => {
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if ((loadId === 'new' && !load.id) || load.id === loadId) {
          const patch = { [prop]: value };

          if (prop === 'field') {
            patch.variable = null;
          }

          return {
            ...load,
            matching_rules: (load.matching_rules || []).map((rule) =>
              rule.id === id ? { ...rule, ...patch } : rule
            ),
          };
        }
        return load;
      }),
    }));

    return value;
  };

  const onDeleteMatchingRule = (id: string, index: number) => {
    let loadIndex = -1;
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load, i) => {
        if ((loadId === 'new' && !load.id) || load.id === loadId) {
          loadIndex = i;
          return {
            ...load,
            matching_rules: (load.matching_rules || []).filter(
              (rule) => rule.id !== id
            ),
          };
        }
        return load;
      }),
    }));
    setErrors((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load, i) => {
        if (i === loadIndex) {
          return {
            ...load,
            matching_rules: (load.matching_rules || []).filter(
              (_, i) => index !== i
            ),
          };
        }
        return load;
      }),
    }));
  };

  const [load, rules] = useMemo(() => {
    const load = stepData.loads?.find(
      (load) => (loadId === 'new' && !load.id) || load.id === loadId
    );
    const rules = load?.matching_rules || [];
    return [load, rules];
  }, [stepData.loads, loadId]);

  const onReorderMatchingRule = (index: number, offset: 1 | -1) => {
    const nextIndex = index + offset;
    if (!load || nextIndex < 0 || nextIndex >= load.matching_rules.length) {
      return;
    }

    let loadIndex = -1;
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load, i) => {
        if ((loadId === 'new' && !load.id) || load.id === loadId) {
          loadIndex = i;
          const matchingRules = [...load.matching_rules];
          const [currentRule] = matchingRules.splice(index, 1);
          matchingRules.splice(nextIndex, 0, currentRule);
          return {
            ...load,
            matching_rules: matchingRules,
          };
        }
        return load;
      }),
    }));

    setErrors((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load, i) => {
        if (i === loadIndex) {
          const matchingRuleErrors = [...(load?.matching_rules || [])];
          const [currentError] = matchingRuleErrors.splice(index, 1);
          matchingRuleErrors.splice(nextIndex, 0, currentError);
          return {
            ...load,
            matching_rules: matchingRuleErrors,
          };
        }
        return load;
      }),
    }));
  };

  const onAddMatchingRule = () => {
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if ((loadId === 'new' && !load.id) || load.id === loadId) {
          return {
            ...load,
            matching_rules: [
              ...(load.matching_rules || []),
              {
                id: `new_${Date.now()}`,
                order: 0,
                field: null,
                variable: null,
                no_match_action:
                  metaData.matching_rules.no_match_actions[0]?.value,
                single_match_action:
                  metaData.matching_rules.single_match_actions[0]?.value,
                multiple_match_action:
                  metaData.matching_rules.multiple_match_actions[0]?.value,
                match_archive_action:
                  metaData.matching_rules.match_archive_actions[0]?.value,
              },
            ],
          };
        }
        return load;
      }),
    }));
  };

  const fieldOptions = useMemo<GenericOption[]>(() => {
    return (customObject?.fields || [])
      .filter(
        ({ fieldType, name }) =>
          fieldType !== SMART_FIELD_TYPES.longtext &&
          name !== 'created' &&
          SMART_FIELD_TYPES[fieldType]
      )
      .map(({ id, displayName }) => ({
        value: id,
        label: displayName,
      }));
  }, [customObject?.fields]);

  const singleVariables = useMemo(
    () => allVariables.filter((variable) => !variable.is_array),
    [allVariables]
  );

  const fieldsByIsArray = useMemo(() => {
    return (customObject?.fields || [])?.reduce(
      (acc, field) => {
        if (canFieldBeMatchedToArray(field)) {
          acc[field.id] = true;
        }
        return acc;
      },
      {} as Record<string, boolean>
    );
  }, [customObject?.fields]);

  return (
    <StyledLoader loading={loading}>
      <StyledMatchingRuleList>
        {rules.map((rule, i) => (
          <MatchingRuleSettings
            key={rule.id}
            index={i}
            isLastIndex={i === rules.length - 1}
            rule={rule}
            onChange={onChangeMatchingRuleSettings}
            onDelete={onDeleteMatchingRule}
            onReorder={onReorderMatchingRule}
            fieldOptions={fieldOptions}
            variables={
              rule.field && fieldsByIsArray[rule.field]
                ? allVariables
                : singleVariables
            }
          />
        ))}
      </StyledMatchingRuleList>
      <StyledAddRuleButton variant="text" onClick={onAddMatchingRule}>
        {t('+ Add Field Match Rule')}
      </StyledAddRuleButton>
    </StyledLoader>
  );
};
