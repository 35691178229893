import { toastVariant, useToast } from 'components/ToastProvider';
import {
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  format,
} from 'date-fns2';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAsyncFn } from 'react-use';
import AuthenticationService from 'services/AuthenticationService';
import { useTranslation } from 'react-i18next';
import { WEEKDAYS_INDICES } from 'utility/datetime';
import { getOriginalError } from 'services/AxiosService';
import { monitoringExceptionHelper } from 'sentry/helpers';

const defaultHolidays = [[], {}];

const defaultSettings = {
  holiday_country: undefined,
  skip_weekdays: [],
  unskip_dates: [],
  skip_dates: [],
};

export const useCalendarData = (date) => {
  const { t } = useTranslation();
  const stagedRef = useRef({});
  const [initialSettings, setInitialSettings] = useState(defaultSettings);
  const [calendarSettings, setCalendarSettings] = useState(defaultSettings);
  const [submitting, setSubmitting] = useState(false);

  const [showToast] = useToast();

  const [
    {
      loading: loadingHolidays,
      value: [holidays, holidaysLookup] = defaultHolidays,
    },
    fetchHolidays,
  ] = useAsyncFn(async (query) => {
    if (!query?.holiday_country) {
      return defaultHolidays;
    }

    try {
      const { dates } =
        await AuthenticationService.getBusinessHolidaysByCountry(query);
      return dates
        ? [
            dates,
            dates.reduce((acc, { date, ...rest }) => {
              const d = new Date(date.replaceAll('-', '/'));

              const key = d.toDateString();
              acc[key] = acc[key] || [];
              acc[key].push({
                ...rest,
                date: d,
              });
              return acc;
            }, {}),
          ]
        : defaultHolidays;
    } catch (err) {
      const orig = getOriginalError(err);
      if (orig?.holiday_country) {
        showToast({
          variant: toastVariant.FAILURE,
          message: orig.holiday_country,
        });
      } else {
        monitoringExceptionHelper(err, { extra: { query } });
      }
      return defaultHolidays;
    }
  }, []);

  const [{ loading: loadingSettings }, fetchCalendarSettings] =
    useAsyncFn(async () => {
      try {
        const settings =
          await AuthenticationService.getBusinessCalendarSettings();

        setCalendarSettings({ ...defaultSettings, ...settings });
        setInitialSettings({ ...defaultSettings, ...settings });
      } catch (err) {
        monitoringExceptionHelper(err);
      }
    }, []);

  const skipLookups = useMemo(() => {
    const { skip_weekdays, unskip_dates, skip_dates } = calendarSettings;

    // unskip_dates take precedence over skip_dates
    const skipLookup = [skip_dates, unskip_dates].reduce((acc, dates, i) => {
      dates.forEach((date) => {
        const key = new Date(date.replaceAll('-', '/')).toDateString();
        acc[key] = i === 0;
      });
      return acc;
    }, {});

    const skipWeekdays = skip_weekdays.reduce((acc, weekdayKey) => {
      const dayIndex = WEEKDAYS_INDICES[weekdayKey];
      if (dayIndex !== undefined) {
        acc.push(dayIndex);
      }
      return acc;
    }, []);

    return { skipLookup, skipWeekdays };
  }, [calendarSettings]);

  const settingsLookups = useMemo(() => {
    return {
      ...skipLookups,
      holidaysLookup,
    };
  }, [holidaysLookup, skipLookups]);

  const query = useMemo(
    () => ({
      from_date: format(
        startOfWeek(startOfMonth(startOfYear(date))),
        'yyyy-MM-dd'
      ),
      to_date: format(endOfWeek(endOfMonth(endOfYear(date))), 'yyyy-MM-dd'),
    }),
    [date]
  );

  useEffect(() => {
    const fullQuery = {
      ...query,
      holiday_country: calendarSettings.holiday_country,
    };
    if (
      Object.entries(fullQuery).some(([k, v]) => v !== stagedRef.current[k])
    ) {
      fetchHolidays(fullQuery);
    }
    stagedRef.current = fullQuery;
  }, [query, fetchHolidays, calendarSettings.holiday_country]);

  useEffect(() => {
    fetchCalendarSettings();
  }, [fetchCalendarSettings]);

  const onSubmitSettings = useCallback(async () => {
    setSubmitting(true);
    try {
      const settings =
        await AuthenticationService.updateBusinessCalendarSettings(
          calendarSettings
        );
      setCalendarSettings({ ...defaultSettings, ...settings });
      setInitialSettings({ ...defaultSettings, ...settings });
      showToast({
        variant: toastVariant.SUCCESS,
        message: t('Business Calendar Updated'),
      });
    } catch (err) {
      const orig = getOriginalError(err);
      showToast({
        variant: toastVariant.FAILURE,
        message: orig?.holiday_country || t('Business Calendar Update Failed'),
      });
      monitoringExceptionHelper(err, { extra: { calendarSettings } });
    } finally {
      setSubmitting(false);
    }
  }, [calendarSettings, showToast, t]);

  const isDirty = useMemo(() => {
    return !isEqual(initialSettings, calendarSettings);
  }, [initialSettings, calendarSettings]);

  return {
    loadingSettings,
    loadingHolidays,
    calendarSettings,
    setCalendarSettings,
    settingsLookups,
    submitting,
    onSubmitSettings,
    holidays,
    isDirty,
  };
};
