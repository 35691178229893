import {
  useEffect,
  useRef,
  useState,
  useContext,
  useMemo,
  Fragment,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { DraggingContext } from './draggingContext';
import { useObservable } from './behaviorSubject';
import Draggable from 'react-draggable';
import useSyncScroll from 'react-use-sync-scroll';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { hideScrollbarCss, borderRadii, layers } from 'app/spacing';
import { grayScale } from 'app/colors';
import BaseColumn from 'components/Board/BaseColumn';
import { DndContext } from 'components/Board/BigBoard';
import { useVerticalScrollWhilePlacing } from 'components/Board/utils';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import { getItemDropzone } from 'components/DragAndDropLayout/helpers';
import { CardDropzone, StageDropzone } from './ObservableDropzone';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import ColumnTitle, { SecondarySubTitle, SubTitle } from '../ColumnTitle';
import Card from '../Card';
import { isStageField } from 'checks/fields';
import { getFieldAccessPermissions } from 'components/Fields/FieldInput/helpers';
import { SUMMARY_VALUES } from 'store/customObjectsRecordsPage/records.redux';
import { flushSync } from 'react-dom';

const ContentWrapper = styled.div`
  display: flex;
  height: calc(
    100% - ${({ secondarySubTitle }) => (secondarySubTitle ? 83 : 63)}px
  ); // 63 - column title height, 83 - for secondary title line
`;

const Content = styled.div`
  padding: 2.5px 1px 2.5px 10px;
  width: 100%;
  overflow-y: auto;
  ${hideScrollbarCss}
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;

  .card {
    height: 100%;
    width: 100%;
  }

  ${({ draggingDimensions }) =>
    draggingDimensions &&
    css`
      z-index: ${layers.modal * 2};
      pointer-events: none; // TODO this makes the cursor change back to normal but allows mouseover
      .card {
        position: fixed;
        ${css(draggingDimensions)}
      }
    `}
`;

const CardPlaceholder = styled.div`
  ${({ dragging, cardHeight }) =>
    dragging
      ? css`
          position: relative;
          width: 100%;
          height: ${cardHeight}px;
          margin-bottom: 10px;
        `
      : css`
          position: absolute;
        `}
  top: 5px;
  bottom: 5px;
  left: 0;
  right: 0;
  background-color: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.small};
`;

const DRAG_SHIFT = 20;

const Gradient = styled((props) => (
  <BaseGradient fadeStart={0} fadeLength={50} {...props} />
))`
  // Always pulling gradient wrapper down for this case
  top: 100%;
  // Added space for half-height(2.5px) of the drop indicator
  // on the last item in the column. We need to move the gradient on this size too.
  bottom: -2.5px;
`;

const DesktopColumn = ({
  model,
  data,
  allowEdit,
  cards,
  columns,
  settings,
  teams,
  fields,
  stages,
  cardHeight,
  getMoreCards,
  setColumns,
  setMouseCoords,
  setDraggingData,
  setCardDropzone,
  setStageDropzone,
  onChangeSelection,
  onChoseMenu,
  onClickPlus,
  onSubmitRecord,
  onMoveCard,
  onChangeStage,
  applyCardDropzone,
  getPosition,
  search,
  handleUpdateFieldOption,
  ...props
}) => {
  const [stagedCards, setStagedCards] = useState(cards);

  const scrollbarContentWrapperRef = useRef();
  const dragPositionYRef = useRef();
  const dragShiftRef = useRef();
  const { placing, togglePlacing } = useContext(DndContext);
  const draggingCtxt = useContext(DraggingContext);
  const draggingData = useObservable(draggingCtxt.draggingData);
  const searchRef = useRef(search);
  const stageField = useMemo(() => fields.find(isStageField), [fields]);

  const scrollProps = useVerticalScrollWhilePlacing(
    placing,
    cardHeight,
    (ev) => {
      if (draggingData) {
        let shift;
        if (ev.clientY === dragPositionYRef.current) {
          shift = dragShiftRef.current;
        } else if (ev.clientY > dragPositionYRef.current) {
          shift = DRAG_SHIFT + cardHeight / 2;
        } else {
          shift = DRAG_SHIFT;
        }

        setStageDropzone({ id: data.id });
        setCardDropzone(
          getItemDropzone(
            draggingData.item,
            cards,
            '.CardItemWrapper',
            () => false,
            ev,
            shift
          )
        );
        dragShiftRef.current = shift;
        dragPositionYRef.current = ev.clientY;
      }
    }
  );

  const [contentWrapperRefFn, contentWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncHeight',
    'height',
    [stagedCards]
  );
  useSyncScroll(useRef([contentWrapperRef, scrollbarContentWrapperRef]), {
    vertical: true,
  });

  const [, , bottomScrolled] = useEndsOfScroll(contentWrapperRef, [
    stagedCards,
  ]);

  useEffect(() => {
    setStagedCards(cards);
  }, [cards]);

  const cardKey = useMemo(
    () => `${cards?.length || 0}-${data?.id || 'abcd'}-${new Date().getTime()}`,
    [cards, data]
  );

  useEffect(() => {
    if (bottomScrolled && data.hasMorePages) {
      // if the search is the same safe to request more pages
      if (search === searchRef.current) {
        getMoreCards({
          stage: { id: data.id, name: data.name },
          page: data.page + 1,
        });
      } else if (data.page === 1) {
        // only reset when the data is back at the first page i.e. it's been refreshed
        searchRef.current = search;
        getMoreCards({
          stage: { id: data.id, name: data.name },
          page: data.page,
        });
      }
    }
  }, [bottomScrolled, getMoreCards, data, search]);

  const getCardClassName = (itemId) =>
    `${!draggingData ? 'InlineHoverable' : ''}${
      draggingData?.item.id === itemId ? ' card' : ''
    }`;

  const handleOnStart = (ev, item) => {
    if (document.activeElement) document.activeElement.blur();
    const board = document.querySelector('.ContentForSyncWidth');

    flushSync(() => {
      setDraggingData({
        offsetParent: ev.currentTarget.offsetParent,
        dimensions: {
          height: ev.currentTarget.offsetHeight,
          width: ev.currentTarget.offsetWidth,
        },
        item,
        cardHeight: ev.currentTarget.offsetHeight,
        mouseCoords: {
          x: ev.clientX + board.parentElement.scrollLeft,
          y:
            ev.clientY +
            ev.currentTarget.offsetParent.parentElement.parentElement.scrollTop,
        },
      });
      togglePlacing();
    });
  };

  const handleOnStop = () => {
    flushSync(() => {
      applyCardDropzone(cards, data, setStagedCards);
      setDraggingData(null);
      setCardDropzone(null);
      setStageDropzone(null);
      setMouseCoords(null);
      togglePlacing();
    });
  };

  return (
    <BaseColumn
      onClickPlus={allowEdit && onClickPlus}
      columnWidth={settings.columnWidth}
      onMouseLeave={() => setStageDropzone(null)}
      data-qa={`board-column-${data.name}`}
      {...props}
    >
      <ColumnTitle
        title={data.name}
        subTitle={<SubTitle settings={settings} data={data} fields={fields} />}
        secondarySubTitle={
          <SecondarySubTitle settings={settings} data={data} fields={fields} />
        }
        isSelected={data.isChecked}
        onChangeSelection={onChangeSelection}
        checkBoxProps={{ zIndex: layers.content(0, 1) }}
      />
      <ContentWrapper
        secondarySubTitle={
          settings.secondarySummaryMetric !== SUMMARY_VALUES.noSummary
        }
      >
        <Content ref={contentWrapperRefFn} {...scrollProps}>
          <Cards key={cardKey} className="ContentForSyncHeight">
            {!data.cards.length ? (
              <StageDropzone margin={0} id={data.id} />
            ) : null}
            {stagedCards.map((item, index) => {
              const [isStageEditable] = getFieldAccessPermissions(
                stageField,
                item
              );
              return (
                <Fragment key={item.id}>
                  <CardDropzone margin={0} id={item.id} position="before" />
                  <Wrapper
                    key={item.id}
                    className="CardItemWrapper"
                    draggingDimensions={
                      draggingData?.item.id === item.id
                        ? draggingData.dimensions
                        : null
                    }
                  >
                    <CardPlaceholder
                      dragging={draggingData?.item.id === item.id}
                      cardHeight={draggingData?.cardHeight}
                    />
                    <Draggable
                      handle=".CardHandle"
                      position={getPosition(item)}
                      offsetParent={draggingData?.offsetParent}
                      onStart={(ev) => handleOnStart(ev, item)}
                      onStop={() => handleOnStop()}
                      disabled={!isStageEditable}
                    >
                      <Card
                        key={item.id}
                        className={getCardClassName(item.id)}
                        dragging={Boolean(draggingData)}
                        isDragItem={draggingData?.item.id === item.id}
                        data={item}
                        model={model}
                        stageStatus={data.status}
                        settings={settings}
                        teams={teams}
                        fields={fields}
                        stages={stages.filter((stage) => {
                          return stage.value !== item.stage.value;
                        })}
                        onChoseMenu={onChoseMenu}
                        onSubmitRecord={(recordId, patch) =>
                          onSubmitRecord(recordId, patch, {
                            stageId: data.id,
                          })
                        }
                        onChangeStage={(card, patch) =>
                          onChangeStage(card, patch, { stageId: data.id })
                        }
                        data-qa={item.id}
                        data-qa-index={index}
                        hideDnDIcon={!isStageEditable}
                        handleUpdateFieldOption={handleUpdateFieldOption}
                        selectOverlayObserverProps={{
                          root: null,
                          rootMargin: '0px 0px -38px 0px',
                          threshold: 1,
                        }}
                      />
                    </Draggable>
                  </Wrapper>

                  <CardDropzone margin={0} id={item.id} position="after" />
                </Fragment>
              );
            })}
          </Cards>
          <Gradient position="bottom" fadeIn={!bottomScrolled} />
        </Content>
        <ScrollBarDetached
          ref={scrollbarContentWrapperRef}
          direction="vertical"
          scrollClassName="ContentForSyncHeight"
        />
      </ContentWrapper>
    </BaseColumn>
  );
};

export default DesktopColumn;
