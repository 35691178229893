import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const getElementHeight = (element) =>
  element?.getBoundingClientRect().height || 0;

const useHeightMeasurement = (cb) => {
  const ref = useRef();
  const [height, setHeight] = useState(0);

  const resizer = useMemo(() => {
    return new ResizeObserver((entries) => {
      setHeight(getElementHeight(entries?.[0]?.target));
    });
  }, []);

  const getHeight = useCallback(() => {
    if (ref.current) {
      setHeight(getElementHeight(ref.current));
    }
  }, []);

  const componentRef = useCallback(
    (element) => {
      if (ref.current && !element) {
        resizer.unobserve(ref.current);
      }
      if (element) {
        resizer.observe(element);
      }
      ref.current = element;
      getHeight();
    },
    [getHeight, resizer]
  );

  useEffect(() => {
    cb?.(height);
  }, [cb, height]);

  useEffect(() => {
    if (ref.current) {
      resizer.observe(ref.current);

      return () => {
        if (ref.current) {
          resizer.unobserve(ref.current);
        }
      };
    }
  }, [resizer]);

  return {
    ref: componentRef,
    plainRef: ref,
    height,
  };
};

export default useHeightMeasurement;
