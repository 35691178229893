import React, { useContext, useState } from 'react';

const defaultMenuState = {
  objectOpen: false,
  teamOpen: false,
  typeOpen: false,
  dateOpen: false,
};

export const TimelineContext = React.createContext({
  menuState: defaultMenuState,
  isConstrained: false,
  scrollContainerRef: { current: null },
  setMenuState: () => null,
});

export const TimelineProvider = ({
  children,
  isConstrained = false,
  scrollContainerRef,
}) => {
  const [menuState, setMenuState] = useState(defaultMenuState);
  return (
    <TimelineContext.Provider
      value={{ menuState, isConstrained, scrollContainerRef, setMenuState }}
    >
      {children}
    </TimelineContext.Provider>
  );
};

export const useMenusState = () => {
  const ctx = useContext(TimelineContext);

  return ctx;
};
