import { Redirect } from 'react-router-dom';
import config from './config';
import history from './history';
import { NOT_FOUND_PATH } from './paths';

const NotFound = () => <Redirect to={NOT_FOUND_PATH} />;

export default config;
export { NOT_FOUND_PATH, NotFound, history };
export * from './utils';
