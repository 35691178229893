import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useDefaultModelQuery,
  useModelQuery,
} from '__queries/models/custom-objects';
import { GetFilterVariableOpts, getFilterVariables } from 'ts-filters/utils';
import { getBusinessClientObject } from 'store/authentication/selectors';

type UseFilterVariableOptions = Pick<
  GetFilterVariableOpts,
  'fieldSettingsSearch'
> & {
  objectId?: string | null;
};

/**
 * When called with no arguments, will return variables for contact filters. To create variables
 * for a custom object, supply an `options.objectId` value.
 */
export const useFilterVariables = (
  options?: UseFilterVariableOptions
): { variables: [string, any][]; isLoading: boolean } => {
  const isClient = !options?.objectId;
  const access = useSelector((s: any) => s.authentication.access);
  const businessClientData = useSelector(getBusinessClientObject);
  const objectQuery = useModelQuery(options?.objectId, !isClient);
  const contactQuery = useDefaultModelQuery(businessClientData.id, {
    enabled: Boolean(businessClientData.access),
  });
  const objectModel = objectQuery.data;
  const contactModel = contactQuery.data;
  const isLoading = objectQuery.isLoading || contactQuery.isLoading;
  const { fieldSettingsSearch } = options ?? {};

  const variables = useMemo(() => {
    const opts: GetFilterVariableOpts = { access };
    const model = isClient ? contactModel : objectModel;

    if (!isClient && contactModel) {
      opts.clientTagId = contactModel.undeletableFields.tags.id;
    }

    if (fieldSettingsSearch) {
      opts.fieldSettingsSearch = true;
    }

    return model ? getFilterVariables(model, opts) : [];
  }, [contactModel, objectModel, isClient, access, fieldSettingsSearch]);

  return { variables, isLoading };
};
