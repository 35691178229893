import { useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Select from 'components/Inputs/Select';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import ButtonGroupButton from '__components/Inputs/ButtonGroup/Button';
import { SECONDARY_ACTIONS } from '../actions';

const Wrapper = styled.div`
  width: 100%;

  .select {
    margin-bottom: 19px;
    margin-top: 16px;
  }
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  padding-top: 10px;
`;

const Button = styled(ButtonGroupButton)`
  border-radius: 4px;
`;

const StartAutomation = ({
  automation,
  setAutomation,
  objectId,
  secondaryAction,
  setSecondaryAction,
}) => {
  const { t } = useTranslation();

  const skipActive = [
    SECONDARY_ACTIONS.CANCEL,
    SECONDARY_ACTIONS.PAUSE,
  ].includes(secondaryAction);

  const params = useMemo(
    () => ({
      active: skipActive ? undefined : true,
      custom_object_id: objectId,
    }),
    [objectId, skipActive]
  );

  const selectRef = useRef(null);

  const [typeaheadProps, { loading }] = useSelectTypeaheadWithScroll({
    entity: Entities.Automations,
    enabled: !!objectId,
    selectRef,
    params,
  });

  const actionLabel = useMemo(() => {
    return {
      [SECONDARY_ACTIONS.START]: t('Start'),
      [SECONDARY_ACTIONS.PAUSE]: t('Pause'),
      [SECONDARY_ACTIONS.CANCEL]: t('Cancel'),
    }[secondaryAction];
  }, [secondaryAction, t]);

  return (
    <Wrapper>
      <ButtonGrid>
        <Button
          selected={secondaryAction === SECONDARY_ACTIONS.START}
          option={{ label: t('START'), value: SECONDARY_ACTIONS.START }}
          onClick={() => setSecondaryAction(SECONDARY_ACTIONS.START)}
        />
        <Button
          selected={secondaryAction === SECONDARY_ACTIONS.PAUSE}
          option={{ label: t('PAUSE'), value: SECONDARY_ACTIONS.PAUSE }}
          onClick={() => setSecondaryAction(SECONDARY_ACTIONS.PAUSE)}
        />
        <Button
          selected={secondaryAction === SECONDARY_ACTIONS.CANCEL}
          option={{ label: t('CANCEL'), value: SECONDARY_ACTIONS.CANCEL }}
          onClick={() => setSecondaryAction(SECONDARY_ACTIONS.CANCEL)}
        />
      </ButtonGrid>
      <Select
        ref={selectRef}
        className="select"
        fullWidth
        menuInline
        label={t('Choose Automation to {{action}}', {
          action: actionLabel,
        })}
        {...typeaheadProps}
        value={automation}
        onChange={(op) => setAutomation(op)}
        placeholder={t('Choose Automation')}
        loadItems={loading}
        isLoading={loading}
      />
    </Wrapper>
  );
};

export default StartAutomation;
