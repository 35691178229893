import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { TextSpan, truncateCss } from 'app/typography';
import { gutters, layers } from 'app/spacing';
import { colorsText, grayScale, colorsButton } from 'app/colors';

export const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  margin: ${({ size }) =>
    size === 'small'
      ? `${gutters.spacing(2)}px ${gutters.spacing(2)}px ${gutters.spacing(
          2
        )}px 0`
      : '12px'};
  user-select: none;
  overflow: hidden;
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: ${layers.content(0)};
`;

export const StyledInputFacade = styled.span`
  position: relative;
  // 13px = 12px plus a 1px border
  width: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  height: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  border: 1px solid ${grayScale.mediumDark};
  border-radius: 50%;
  z-index: ${layers.content(0, 1)};

  ${StyledLabel}:hover & {
    border-color: ${(props) =>
      props.error ? colorsButton.red.hover : colorsButton.blue.hover};
  }

  ${StyledInput}:checked~& {
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 10px;
      height: 10px;
      background: ${colorsButton.blue.hover};
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }

  ${StyledInput}:disabled~& {
    border-color: ${grayScale.mediumDark};
  }

  ${StyledInput}:disabled:checked~& {
    &::after {
      background: ${grayScale.mediumDark};
    }
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}
`;

export const StyledText = styled(TextSpan)`
  margin-left: 10px;
  flex: 1;
  ${truncateCss}
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
`;
