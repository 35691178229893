import { useEffect } from 'react';
import { useTimeZoneNotice } from 'hooks/useTimeZoneNotice';
import { useTranslation } from 'react-i18next';

export const useDashboardTimeZoneNotice = () => {
  const { t } = useTranslation();
  const { handleShowNotice, showNotice, data, isLoading } =
    useTimeZoneNotice('dashboard_list');
  useEffect(() => {
    if (showNotice && !isLoading && data.currentDashboard) {
      handleShowNotice(
        t(
          'Your timezone differs from the business timezone. All dashlets are displayed in the business timezone.'
        )
      );
    }
  }, [showNotice, handleShowNotice, isLoading, data, t]);
};
