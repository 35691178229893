class AuthenticationServiceError extends Error {
  constructor(message, { status, payload }) {
    super(message);
    this.name = 'AuthenticationServiceError';
    this.status = status;
    this.payload = payload;
  }
}

export { AuthenticationServiceError };

export class KizenError extends Error {
  constructor(code, message = 'KizenError') {
    super(message);
    this.code = code;
  }
}

export class PermissionsError extends KizenError {
  constructor() {
    super('403');
  }
}

export class HttpError extends KizenError {
  constructor(code, message, url) {
    super(code, message);
    this.url = url;
  }
}

export class AppError extends Error {
  constructor(field, message = 'KizenError') {
    super(message);
    this[field] = message;
  }
}
