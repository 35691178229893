import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FIELD_TYPES } from 'utility/constants';
import { getIdentifierForOption } from 'components/Fields/helpers';
import SelectInline from 'components/Inputs/inline/Select';
import { getDataQAForInput } from 'components/Inputs/helpers';

export default function DropdownInlineField({
  value,
  onSubmit,
  field,
  object,
  children: selectEl,
  ...others
}) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return (field.options || []).map((opt) => ({
      value: getIdentifierForOption(opt, field),
      label: opt.name,
      color: opt.meta && opt.meta.color,
    }));
  }, [field]);

  return (
    <SelectInline
      onSubmit={async (opt) => {
        await onSubmit(opt && opt.value);
      }}
      field={field}
      object={object}
      {...others}
    >
      {React.cloneElement(selectEl, {
        value,
        options,
        ...getDataQAForInput(field.id, field.fieldType),
        placeholder:
          selectEl.props.placeholder ||
          FIELD_TYPES.Dropdown.localizedPlaceholder(t),
      })}
    </SelectInline>
  );
}

DropdownInlineField.propTypes = {
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

DropdownInlineField.defaultProps = {
  value: null,
};
