import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { ChartLayout, Chart, StyledLoader } from './styles';
import AreaLineChartManager, { colors } from './AreaLineChartManager';
import { useTranslation } from 'react-i18next';
import NoData from '../components/NoData';
import { StyledNoData } from '../components/NoData/styles';
import useExternalLegend from '../hooks/useExternalLegend';
import CustomLegend from '../components/CustomLegend';
import { getPendingDefaultValue } from '../helpers';
import { isEqual } from 'lodash';
import { sanitizeTextForCharts } from '../ChartManager';

const AreaLineChart = ({
  data,
  dashlet,
  isLoading,
  mobile,
  tooltipLabels,
  yAxisTitle,
  currencySymbol,
  isValue,
  isWeighted,
  frequency,
  currencyMode,
}) => {
  const chartRef = useRef();
  const { t } = useTranslation();
  const [chartRenderKey, setChartRenderKey] = useState(() => Date.now());
  const [chartPending, setChartPending] = useState(getPendingDefaultValue);
  const [chartRendered, setChartRendered] = useState(false);

  const sanitizedData = useMemo(() => {
    return data.map((d) => {
      return {
        ...d,
        id: sanitizeTextForCharts(d.id),
        category: {
          ...d.category,
          name: sanitizeTextForCharts(d.category.name),
          id: sanitizeTextForCharts(d.category.id),
        },
      };
    });
  }, [data]);

  const { withCallback, legendProps, hiddenStable } = useExternalLegend(data, {
    columnCount: 4,
    fixedWidth: true,
    prePad: true,
    colors,
  });

  const createChart = useCallback((elem) => {
    if (elem) {
      chartRef.current = elem;
      setChartRenderKey(Date.now());
    }
  }, []);

  const handleChartReady = useCallback((instance) => {
    if (instance.seriesMap) {
      if (hiddenStable.current.length) {
        hiddenStable.current.forEach((id) => {
          if (instance.seriesMap[id]) {
            instance.hideSeries(id);
          }
        });
      }
    }
    setChartPending(false);
    setChartRendered(true);
    // hiddenStable is a ref, not a state-controlled value,
    // but the linter doesn't know that so we need to disable exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sanitizedTooltipLabels = useMemo(() => {
    const res = { ...tooltipLabels };
    for (const [key, value] of Object.entries(res)) {
      res[key] = sanitizeTextForCharts(value);
    }
    return res;
  }, [tooltipLabels]);

  useEffect(() => {
    if (chartRef.current) {
      const manager = new AreaLineChartManager(
        chartRef.current,
        t,
        sanitizedData,
        {
          currencySymbol,
          isValue,
          isAdded: false,
          mobile,
          useAllBullets: true,
          isWeighted,
          frequency,
          tooltipLabels: sanitizedTooltipLabels,
          yAxisTitle,
          currencyMode,
        },
        {},
        handleChartReady
      );

      withCallback(manager.init());

      return manager.destroy;
    }
  }, [
    sanitizedData,
    currencySymbol,
    chartRenderKey,
    isLoading,
    mobile,
    isValue,
    isWeighted,
    withCallback,
    handleChartReady,
    frequency,
    sanitizedTooltipLabels,
    t,
    yAxisTitle,
    currencyMode,
  ]);

  return (
    <ChartLayout>
      {/*
          This absolutely positioned loader is used to keep the chart area hidden until the calculations
          and rendering are done, as they can be quite slow for large datasets and will just
          show a blank white dashlet otherwise
        */}
      <StyledLoader loading={chartPending || isLoading} />
      {data.length === 0 ? (
        <StyledNoData>
          <NoData data-qa-dashlet-id={dashlet.id} data-qa-no-data />
        </StyledNoData>
      ) : null}
      <Chart
        data-qa-chart="area-line"
        data-qa-chart-pending={chartPending || isLoading || !chartRendered}
        ref={createChart}
      />
      <CustomLegend {...legendProps} />
    </ChartLayout>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  const dataEqual = isEqual(prevProps.data, nextProps.data);
  const dashletEqual = isEqual(prevProps.dashlet, nextProps.dashlet);
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const mobileEqual = prevProps.mobile === nextProps.mobile;
  const yAxisTitleEqual = prevProps.yAxisTitle === nextProps.yAxisTitle;
  const currencySymbolEqual =
    prevProps.currencySymbol === nextProps.currencySymbol;
  const isValueEqual = prevProps.isValue === nextProps.isValue;
  const isWeightedEqual = prevProps.isWeighted === nextProps.isWeighted;
  const frequencyEqual = prevProps.frequency === nextProps.frequency;
  const currencyModeEqual = prevProps.currencyMode === nextProps.currencyMode;

  return (
    dataEqual &&
    dashletEqual &&
    isLoadingEqual &&
    mobileEqual &&
    yAxisTitleEqual &&
    currencySymbolEqual &&
    isValueEqual &&
    isWeightedEqual &&
    frequencyEqual &&
    currencyModeEqual
  );
};

export default React.memo(AreaLineChart, propsAreEqual);
