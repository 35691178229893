import React from 'react';

export default function TrashAltLight(props) {
  return (
    /* eslint-disable */
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 5.25H6.75V0.25C6.75 0.111875 6.63812 0 6.5 0H5.5C5.36187 0 5.25 0.111875 5.25 0.25V5.25H0.25C0.111875 5.25 0 5.36187 0 5.5V6.5C0 6.63812 0.111875 6.75 0.25 6.75H5.25V11.75C5.25 11.8881 5.36187 12 5.5 12H6.5C6.63812 12 6.75 11.8881 6.75 11.75V6.75H11.75C11.8881 6.75 12 6.63812 12 6.5V5.5C12 5.36187 11.8881 5.25 11.75 5.25Z"
        fill="currentColor"
      />
    </svg>

    /* eslint-enable */
  );
}
