import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTooltip } from 'components/Kizen/Tooltip';

import KizenTypography from 'app/kizentypo';
import { tableHover } from 'app/colors';
import { breakpoints, useBreakpoint } from 'app/spacing';
import Icon from 'components/Kizen/Icon';

import { ChartAggregatesBar, AggregatePercentBlock } from './styles';
import { collapseNumber, percentageFormatter } from 'utility/numbers';

const EmailStats = ({ stats: dirtyStats, ...props }) => {
  const isMobile = useBreakpoint(breakpoints.md);
  const { numSent = 0, numOpened = 0, numClicked = 0 } = dirtyStats;
  const stats = {
    sent: numSent,
    opened: numOpened,
    clicked: numClicked,
    openedBySent: numOpened / (numSent || Infinity),
    clickedByOpened: numClicked / (numOpened || Infinity),
  };

  const { t } = useTranslation();
  const [openRateTooltipProps, openRateTooltip] = useTooltip({
    label: t('Open Rate'),
  });
  const [clickthroughRateTooltipProps, clickthroughRateTooltip] = useTooltip({
    label: t('Clickthrough Rate'),
  });

  return (
    <ChartAggregatesBar {...props}>
      <div>
        <KizenTypography type={isMobile ? null : 'header'}>
          {collapseNumber(stats.sent)}
        </KizenTypography>
        <KizenTypography
          type={isMobile ? 'label-small' : null}
          textTransform="uppercase"
        >
          {t('Emails Sent')}
        </KizenTypography>
      </div>
      <AggregatePercentBlock>
        <KizenTypography
          {...openRateTooltipProps}
          size={isMobile ? 'micro' : 'small'}
        >
          {percentageFormatter.format(stats.openedBySent)}
        </KizenTypography>
        {openRateTooltip}
        <Icon
          icon="chevron-right-light"
          color={tableHover}
          size="8x"
          ignorePointerEvents
        />
      </AggregatePercentBlock>
      <div>
        <KizenTypography type={isMobile ? null : 'header'}>
          {collapseNumber(stats.opened)}
        </KizenTypography>
        <KizenTypography
          type={isMobile ? 'label-small' : null}
          textTransform="uppercase"
        >
          {t('Opened')}
        </KizenTypography>
      </div>
      <AggregatePercentBlock>
        <KizenTypography
          {...clickthroughRateTooltipProps}
          size={isMobile ? 'micro' : 'small'}
        >
          {percentageFormatter.format(stats.clickedByOpened)}
        </KizenTypography>
        {clickthroughRateTooltip}
        <Icon
          icon="chevron-right-light"
          color={tableHover}
          size="8x"
          ignorePointerEvents
        />
      </AggregatePercentBlock>
      <div>
        <KizenTypography
          type={isMobile ? null : 'header'}
          textTransform="uppercase"
        >
          {collapseNumber(stats.clicked)}
        </KizenTypography>
        <KizenTypography
          type={isMobile ? 'label-small' : null}
          textTransform="uppercase"
        >
          {t('Clicked')}
        </KizenTypography>
      </div>
    </ChartAggregatesBar>
  );
};

EmailStats.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default EmailStats;
