import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { breakpoints, gutters } from 'app/spacing';
import { KizenTypography } from 'app/typography';
import ValidatedField from 'components/Kizen/ValidatedField';

const LabelsRow = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  min-width: 1px;
  margin-bottom: ${gutters.spacing(2)}px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom: ${gutters.spacing(1, 4)}px;
  }
  &&:last-child {
    margin-bottom: 0;
  }
`;

const LabelsLabel = styled.div`
  flex: 0 0 80px;
  width: 100%;
`;
const LabelsChildren = styled.div`
  flex: 1;
  min-width: 1px;
  width: 100%;
`;

const Labels = ({ children, label, className }) => {
  return (
    <LabelsRow className={className}>
      {label ? (
        <LabelsLabel>
          <KizenTypography weight="bold">{label}</KizenTypography>
        </LabelsLabel>
      ) : null}
      <LabelsChildren>{children}</LabelsChildren>
    </LabelsRow>
  );
};

const NameLabels = styled(Labels)`
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom: 13px;
    @media screen and (max-width: ${breakpoints.sm}px) {
      margin-bottom: 14px;
    }
  }
`;
export const LabelsPreviewBlock = ({
  isMobile,
  info,
  disabled,
  file,
  updateFile,
  viewable,
  ext,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!isMobile || info.name ? (
        <NameLabels label={isMobile ? null : t('Name')}>
          {info.name && !disabled ? (
            <ValidatedField
              inModal
              value={info.name}
              disabled={disabled}
              onSubmit={async (value) => {
                if (!value) {
                  throw new Error(t('File names cannot be blank.'));
                }
                if (info.name === value) {
                  return null;
                }
                const count = (value.match(/\./g) || []).length;
                if (count === 0) {
                  throw new Error(
                    t('There filename must contain an extension.')
                  );
                }
                if (count !== 1) {
                  throw new Error(t('There can only be one . in a filename.'));
                }
                if (!value.toLowerCase().endsWith(ext)) {
                  // if there 1 . then we can put the extension back if changed
                  const name = value.split('.').shift();
                  value = `${name}.${ext}`;
                }
                return updateFile(file, {
                  name: value,
                });
              }}
              hasMobileView
            />
          ) : (
            <TextEllipsisWithTooltip>{info.name}</TextEllipsisWithTooltip>
          )}
        </NameLabels>
      ) : null}
      {isMobile ? null : (
        <>
          <Labels label={t('Size')}>
            <TextEllipsisWithTooltip>
              {info.sizeFormatted}
            </TextEllipsisWithTooltip>
          </Labels>
          <Labels label={t('Type')}>
            <TextEllipsisWithTooltip>
              {info.contentType}
            </TextEllipsisWithTooltip>
          </Labels>
        </>
      )}
      <Labels label={t('Date')}>
        <TextEllipsisWithTooltip>
          {isMobile && info.created ? info.created.split(' ')[0] : info.created}
        </TextEllipsisWithTooltip>
      </Labels>
      {viewable && (
        <Labels label={t('Preview')}>
          {info.url ? (
            <>
              <TextEllipsisWithTooltip
                type="anchor"
                href={`${info.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Open File')}
              </TextEllipsisWithTooltip>
              &nbsp;<TextEllipsisWithTooltip>|</TextEllipsisWithTooltip>&nbsp;
              <TextEllipsisWithTooltip
                type="anchor"
                href={`${info.url}?disposition=attachment`}
                rel="noopener noreferrer"
              >
                {t('Download')}
              </TextEllipsisWithTooltip>
            </>
          ) : null}
        </Labels>
      )}
    </>
  );
};
