import css from '@emotion/css';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Card from 'components/Card/Card';

export const Body = styled(KizenTypography)`
  padding-top: ${({ mobile }) =>
    mobile ? gutters.spacing(0, 2) : gutters.spacing(0, 2)}px;
  padding-bottom: ${({ mobile }) =>
    mobile ? gutters.spacing(2, 3) : gutters.spacing(4, 2)}px;
  text-align: center;
  margin: 0 ${gutters.spacing(2)}px;
`;

export const Header = styled(KizenTypography)`
  text-align: center;

  ${({ mobile }) =>
    mobile
      ? css`
          &&& {
            margin-bottom: ${gutters.spacing(2, 3)}px;
            padding-top: ${gutters.spacing(0, 2)}px;
          }
        `
      : ''}
`;

export const StyledCard = styled(Card)`
  padding: ${({ mobile }) =>
      mobile ? gutters.spacing(3) : gutters.spacing(5)}px
    0 ${({ mobile }) => (mobile ? gutters.spacing(3) : gutters.spacing(5))}px 0;
  margin: ${gutters.spacing(4)}px 0 0 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  && h1 {
    margin-top: 2px; //  Design nudge
    margin-bottom: ${gutters.spacing(4, 2)}px;
    line-height: 1;
  }
`;
