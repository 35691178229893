import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEditor } from '@craftjs/core';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import {
  isRelationshipField,
  isDynamicTagsField,
  isActualCloseDateField,
  isReadOnlyField,
  isTimeZoneField,
} from 'checks/fields';
import { isContactForm } from 'checks/forms';
import Button from 'components/Button';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import Switch from 'components/Kizen/Switch';
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'components/Modals';
import {
  useUnrestrictedContactFieldsAndCategoriesQuery,
  useUnrestrictedFieldsAndDetailsQuery,
} from 'queries/models/custom-objects';
import { created } from 'store/pageBuilder/pageBuilder.redux';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { compose } from 'utility/compose';
import { categorizeFields } from 'xforms/api';
import { useBuilderContext } from 'components/PageBuilder/BuilderContext';
import { useAddNewNode } from '../useAddNewNode';
import { DEFAULT_CUSTOM_FIELD_PROPS } from './props';
import { getDefaultFieldPlaceholder } from 'components/PageBuilder/utils';

const EMPTY_ARRAY = [];

const StyledModalBody = styled(ModalBody)`
  .InputControl {
    margin-bottom: ${gutters.spacing(3)}px;
  }
`;

export const filterFields = (isContactForm) => (field) => {
  if (isContactForm && isTimeZoneField(field)) {
    return field;
  }

  return !isReadOnlyField(field) &&
    !isDynamicTagsField(field) &&
    !isRelationshipField(field) &&
    !isActualCloseDateField(field) &&
    !isTimeZoneField(field)
    ? field
    : null;
};
const toOptionsModel = (field) => {
  return field ? { value: field, label: field.displayName } : field;
};
const fieldTransform = (isContactForm) => {
  return compose(toOptionsModel, filterFields(isContactForm));
};

const useCustomObjectDetails = (id, { isContact, isPipeline }) => {
  const contact = useUnrestrictedContactFieldsAndCategoriesQuery(isContact);
  const customObject = useUnrestrictedFieldsAndDetailsQuery(id, {
    enabled: !isContact,
    isPipeline,
  });
  const queries = isContact ? contact : customObject;
  if (queries[0].isLoading || queries[1].isLoading) {
    return { data: undefined };
  }
  const categories = isContact
    ? queries[1].data
    : queries[1].data.fieldCategories;
  return { data: { fields: queries[0].data, categories } };
};

const FieldChooserModal = ({
  fields,
  loading = false,
  onHide,
  onConfirm,
  ...rest
}) => {
  const { t } = useTranslation();
  const [field, setField] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  const handleConfirm = () => {
    onConfirm({ ...field.value, displayName, isRequired });
  };
  return (
    <Modal show onHide={onHide} {...rest}>
      <ModalHeader onClickClose={onHide}>
        {t('Choose Custom Field')}
      </ModalHeader>
      <StyledModalBody>
        <Select
          menuInline
          fullWidth
          label={t('Choose Field')}
          options={fields}
          value={field}
          onChange={(f) => {
            setField(f);
            setDisplayName(f?.value?.displayName ?? '');
          }}
          placeholder={t('Choose Field')}
          noOptionsMessage={loading ? () => 'Loading Options' : undefined}
        />
        {field && (
          <TextInput
            label={t('Display Name')}
            placeholder={t('Display Name')}
            value={displayName}
            onChange={setDisplayName}
            error={displayName === ''}
          />
        )}
        {field && (
          <Switch
            label={t('Field is Required')}
            textPlacement="regular-top"
            checked={isRequired}
            onChange={(ev) => setIsRequired(ev.target.checked)}
          />
        )}
      </StyledModalBody>
      <ModalFooter showBorder={false}>
        <Button onClick={handleConfirm} disabled={!field || displayName === ''}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const CreateCustomField = () => {
  const { t } = useTranslation();
  const addNewNode = useAddNewNode();
  const dispatch = useDispatch();
  const { onNodeCreate, form } = useBuilderContext();
  const { actions } = useEditor();
  const {
    relatedObject: { id, fetchUrl },
  } = form;

  const customFieldItem = useSelector((s) =>
    getCreateItemByType(s, 'CustomField')
  );
  const isContact = isContactForm(form);
  const { data, isLoading } = useCustomObjectDetails(id, {
    isContact,
    isPipeline: fetchUrl === 'pipeline',
  });

  const fieldOptions = useMemo(() => {
    if (!data) return EMPTY_ARRAY;
    const { fields, categories } = data;
    const categorized = categorizeFields(fields, fieldTransform(isContact));
    return categories
      .sort((a, b) => a.order - b.order)
      .reduce((acc, category) => {
        const options = categorized[category.id] ?? [];
        if (options.length > 0) {
          acc.push({
            label: category.name,
            options: options,
          });
        }
        return acc;
      }, []);
  }, [data, isContact]);

  const handleHide = () => {
    actions.setIndicator(null);
    dispatch(created(false));
  };

  const handleConfirm = (field) => {
    const newField = {
      ...field,
      labelText: field.displayName,
      customObjectField: { id: field.id },
      placeholder: getDefaultFieldPlaceholder(field, t),
    };
    const node = addNewNode({
      ...customFieldItem,
      ...DEFAULT_CUSTOM_FIELD_PROPS,
      field: newField,
    });
    onNodeCreate(node.id, newField);
  };

  return customFieldItem ? (
    <FieldChooserModal
      fields={fieldOptions}
      loading={isLoading}
      onHide={handleHide}
      onConfirm={handleConfirm}
    />
  ) : null;
};

export const CustomFieldCreator = React.createElement(CreateCustomField);
