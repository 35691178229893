import ButtonGroup from 'components/Inputs/ButtonGroup';
import React, { useContext, useMemo } from 'react';
import KizenTypography from 'app/kizentypo';
import { StyledWizardField } from '../../../../shared/styles';
import { gutters } from 'app/spacing';
import { useTranslation } from 'react-i18next';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import {
  INCLUDED_RECORDS_RESPONSES,
  VALUE_TO_DISPLAY_RESPONSES,
} from '../../../types';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';

const PipelineValueOverTimeReportType = ({ mobile, active }) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);

  const { includedRecords, reportType, valueToDisplay } = state;

  const openRecordsResponse = useMemo(() => {
    return {
      label: t('Open Records'),
      value: INCLUDED_RECORDS_RESPONSES.OPEN,
    };
  }, [t]);

  const totalValueOption = useMemo(() => {
    return {
      label: t('Total Value'),
      value: VALUE_TO_DISPLAY_RESPONSES.TOTAL,
    };
  }, [t]);

  const includedRecordsOptions = useMemo(() => {
    const result = [
      openRecordsResponse,
      {
        label: t('Won Records'),
        value: INCLUDED_RECORDS_RESPONSES.WON,
      },
      {
        label: t('Open & Won Records'),
        value: INCLUDED_RECORDS_RESPONSES.OPEN_AND_WON,
      },
    ];

    return result;
  }, [openRecordsResponse, t]);

  const valueToDisplayOptions = useMemo(() => {
    return [
      totalValueOption,
      {
        label: t('Weighted Value'),
        value: VALUE_TO_DISPLAY_RESPONSES.WEIGHTED,
      },
    ];
  }, [totalValueOption, t]);

  useDefaultValues(
    {
      includedRecords: openRecordsResponse,
      valueToDisplay: totalValueOption,
    },
    active && reportType?.value === REPORT_TYPES.VALUE_OVER_TIME
  );

  if (reportType?.value !== REPORT_TYPES.VALUE_OVER_TIME) {
    return null;
  }

  return (
    <>
      <StyledWizardField top={gutters.spacing(2, 2)}>
        <KizenTypography type="subheader">
          {t('Select Records to Include')}
        </KizenTypography>
        <StyledWizardField top={gutters.spacing(1, 1)}>
          <ButtonGroup
            value={includedRecords}
            onChange={(v) => setState('includedRecords', v)}
            sizing={!mobile ? 'auto' : ''}
            options={includedRecordsOptions}
            className="wizard-buttons"
            columns={mobile ? 2 : 4}
            mode="wizard"
            gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
          />
        </StyledWizardField>
      </StyledWizardField>
      <StyledWizardField top={gutters.spacing(6)}>
        <KizenTypography type="subheader">
          {t('Select Value to Display')}
        </KizenTypography>
        <StyledWizardField top={gutters.spacing(1, 1)}>
          <ButtonGroup
            value={valueToDisplay}
            onChange={(v) => setState('valueToDisplay', v)}
            sizing={!mobile ? 'auto' : ''}
            options={valueToDisplayOptions}
            className="wizard-buttons"
            columns={mobile ? 2 : 4}
            mode="wizard"
            gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
          />
        </StyledWizardField>
      </StyledWizardField>
    </>
  );
};

export default PipelineValueOverTimeReportType;
