import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import KizenTypography from '../../../../app/kizentypo';
import {
  gutters,
  borderRadii,
  dashedBorderCss,
  resetButtonCss,
} from '../../../../app/spacing';
import Icon from '../../../Kizen/Icon';
import { IconSizing } from '../../../Kizen/Icon';
import { TextEllipsisWithTooltip } from '../../../Kizen/Table';
import { grayScale, colorsButton, colorsPrimary } from '../../../../app/colors';
import PercentageBar from '../../../PercentageBar';
import { filterBadFiles, EMPTY_ARRAY } from '../defaults';

const ChooserDragAndDrop = styled.button`
  ${resetButtonCss({ keepBorder: true })}

  display: flex;
  flex: 0 0 ${({ isMobile }) => (isMobile ? 105 : 164)}px;
  flex-direction: column;
  min-width: 1px;
  width: 100%;
  border-radius: ${borderRadii.small};
  cursor: pointer;
  ${({ status, error, disabled }) =>
    status
      ? css`
          border: solid 1px ${colorsButton.blue.hover};
        `
      : css`
          && {
            ${dashedBorderCss({
              color: grayScale.medium,
              dashLength: 4,
            })}

            ${error
              ? css`
                  ${dashedBorderCss({
                    color: colorsButton.red.hover,
                    dashLength: 4,
                  })}
                `
              : !disabled
                ? css`
                    &:hover {
                      ${dashedBorderCss({
                        color: colorsButton.blue.hover,
                        dashLength: 4,
                      })}
                    }
                  `
                : css`
                  cursor: default;
                  && {
                  ${dashedBorderCss({
                    color: grayScale.mediumLight,
                    dashLength: 4,
                  })}
              `}
          }
        `}
  justify-content: center;
  align-items: center;
`;

const LoadingBar = styled.div`
  height: 43px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const CloudIcon = styled(IconSizing)`
  margin-bottom: -${(50 - 35) / 2}px; // because <SizeIcon />> is square remove a little bottom margin
`;

const SpacedTypography = styled.div`
  margin-top: ${gutters.spacing(3, 4)}px;
`;

const ErrorSpacedTypography = styled.div`
  margin-top: ${gutters.spacing(1, 2)}px;
`;
const ErrorFileTypography = styled(TextEllipsisWithTooltip)`
  margin-top: ${gutters.spacing(0, 4)}px;
`;

const FileUploader = ({
  onChange,
  allowedFileExtensions,
  uploadStatus,
  disabled,
  isMobile = false,
}) => {
  const { t } = useTranslation();

  const [fileError, setFileError] = useState(null);
  const [uploadingFiles, setUploadingFiles] = useState(EMPTY_ARRAY);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const badFiles = acceptedFiles.filter(
        filterBadFiles(allowedFileExtensions)
      );
      if (badFiles.length === 0) {
        setFileError(null);
        setUploadingFiles(acceptedFiles);
        onChange(acceptedFiles);
      } else {
        setFileError(badFiles);
      }
    },
    [allowedFileExtensions, onChange]
  );

  // aggregate all active progess
  const status = useMemo(() => {
    const keys = Object.keys(uploadStatus || {});
    return (
      uploadingFiles &&
      uploadStatus &&
      Math.min(
        100,
        Number.parseInt(
          keys.reduce((acc, key) => {
            return acc + uploadStatus[key].progress;
          }, 0) / keys.length,
          10
        )
      )
    );
  }, [uploadStatus, uploadingFiles]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <ChooserDragAndDrop
      {...{ ...(!disabled && getRootProps()) }}
      error={!!fileError}
      status={status}
      disabled={disabled}
      isMobile={isMobile}
    >
      {!fileError ? (
        <>
          {!uploadStatus ? (
            <CloudIcon size="50px">
              <Icon
                icon="cloud-arrow-up"
                color={disabled ? grayScale.medium : grayScale.mediumDark}
              />
            </CloudIcon>
          ) : (
            <LoadingBar>
              <PercentageBar
                percentage={status}
                width={isMobile ? 100 : 220}
                radius={14}
                primaryColor={colorsButton.blue.hover}
                backgroundColor={grayScale.medium}
              />
              <KizenTypography weight="bold">{status}%</KizenTypography>
            </LoadingBar>
          )}
          <SpacedTypography>
            <KizenTypography
              as="span"
              weight="bold"
              color={
                disabled ? colorsPrimary.blue.light : colorsButton.blue.default
              }
            >
              {t('Choose Files')}
              {` `}
            </KizenTypography>
            {isMobile ? null : (
              <KizenTypography
                as="span"
                color={disabled ? grayScale.medium : grayScale.mediumDark}
              >
                {t('or drag & drop files here')}
              </KizenTypography>
            )}
          </SpacedTypography>
        </>
      ) : (
        <>
          <IconSizing size="35px">
            <Icon icon={['fal', 'file-alt']} color={grayScale.mediumDark} />
          </IconSizing>
          <ErrorFileTypography color={colorsButton.red.default} weight="bold">
            {fileError[0].name}
          </ErrorFileTypography>
          <ErrorSpacedTypography>
            <KizenTypography
              as="span"
              weight="bold"
              color={colorsButton.blue.default}
            >
              {t('Change File')}
              {` `}
            </KizenTypography>
            {isMobile ? null : (
              <KizenTypography as="span" color={grayScale.mediumDark}>
                {t('or drag & drop files here')}
              </KizenTypography>
            )}
          </ErrorSpacedTypography>
        </>
      )}
      <input
        disabled={disabled}
        type="file"
        hidden
        {...getInputProps()}
        accept={allowedFileExtensions.join(',')}
      />
    </ChooserDragAndDrop>
  );
};

FileUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  allowedFileExtensions: PropTypes.array.isRequired,
  uploadStatus: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  viewable: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
};

FileUploader.defaultProps = {
  uploadStatus: null,
};
export default FileUploader;
