/*
  some snippets to make code a little cleaner and more readable
*/

export const head = (list) => list[0];

export const tail = (list) => list.slice(1);

export const clamp = (x, lower, upper = Infinity) => {
  if (x < lower) return lower;
  if (x > upper) return upper;
  return x;
};

export const sleep = async (duration) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export const groupBy = (fn, list) =>
  list.reduce(
    (prev, next) => ({
      ...prev,
      [fn(next)]: [...(prev[fn(next)] || []), next],
    }),
    {}
  );

export const collectBy = (fn, list) => Object.values(groupBy(fn, list));

export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const sumBy = (fn, list) =>
  list.reduce((prev, next) => prev + fn(next), 0);

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const isLastItemInList = (list, index) => index === list.length - 1;
