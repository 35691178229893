import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { modalSize } from 'components/Modals';

const COLLAPSE_WRAPPER_HEIGHT = 216;

const Tr = styled.tr`
  &&,
  && td {
    height: unset;
    margin: 0;
    padding: 0;
  }
`;

// this is to expand space in the table
const CollapseWrapper = styled.div`
  position: relative;
  height: 0;
  opacity: 0;
  transition:
    height 300ms 00ms,
    opacity 00ms 300ms;
  overflow: hidden;
  &.show {
    height: ${COLLAPSE_WRAPPER_HEIGHT}px;
    opacity: 1;
    transition:
      height 300ms 000ms,
      opacity 300ms 0ms;
  }

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          overflow-y: auto;
        `
      : ''}
`;

// this is to expand the absolutly positined container
const NotesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 70px;
  height: 0;
  transition: all 300ms;
  width: 100%;
  &.show {
    height: ${({ fullWidth }) =>
      fullWidth ? COLLAPSE_WRAPPER_HEIGHT - 8 : COLLAPSE_WRAPPER_HEIGHT}px;
  }
  ${({ inModal }) =>
    inModal
      ? css`
          //design nude 34 is (30 margin + borders)
          width: calc(${modalSize.medium} - 43px);
          @media (min-width: ${modalSize.large}) {
            width: calc(${modalSize.large} - 43px);
          }
        `
      : css``}
`;

const ChildrenWrapper = styled.div`
  width: 100%;
`;

const NotesTd = styled.td`
  width: 100%;
`;

const StickyTd = styled.td`
  position: sticky;
  left: 0px;
`;

export default function CollapsibleRow({
  in: visible,
  totalCols,
  children,
  hideScrollbar,
  inModal,
  endAdornment,
  ...others
}) {
  const body = (
    <CollapseWrapper
      className={`${visible ? 'show' : ''} ${
        endAdornment && !hideScrollbar ? 'kds-expanding-scrollbar' : ''
      }`}
      fullWidth={Boolean(endAdornment)}
    >
      <NotesWrapper
        className={visible ? 'show floating-notes' : 'floating-notes'}
        inModal={inModal}
        fullWidth={Boolean(endAdornment)}
      >
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </NotesWrapper>
    </CollapseWrapper>
  );

  if (endAdornment) {
    return (
      <Tr {...others}>
        <NotesTd>{body}</NotesTd>
        {endAdornment}
      </Tr>
    );
  }

  return (
    <Tr {...others}>
      <StickyTd className={visible ? 'show' : ''} colSpan={2}>
        {body}
      </StickyTd>
    </Tr>
  );
}
