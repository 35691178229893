import { DisplayNamesRequest, getDisplayNames } from '@kizen/api/records';
import { useQuery, UseQueryOptions } from 'react-query';
import AxiosService from '__services/AxiosService';
import { RECORDS } from '../query-keys';

type UseDisplayNamesResponse = Awaited<ReturnType<typeof getDisplayNames>>;

export const useDisplayNamesQuery = <TData = UseDisplayNamesResponse>(
  ids: DisplayNamesRequest,
  options: UseQueryOptions<UseDisplayNamesResponse, unknown, TData> = {}
) => {
  return useQuery<UseDisplayNamesResponse, unknown, TData>({
    queryKey: RECORDS.DISPLAY_NAMES(ids),
    queryFn: () => getDisplayNames(AxiosService, ids),
    ...options,
  });
};
