import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { SmartConnectorContext } from './context';
import Button from '__components/Button';
import ConfirmNavigationModal from '__components/Modals/presets/ConfirmNavigation';

export const ConfirmationManager = () => {
  const { t } = useTranslation();
  const {
    refreshing,
    handleSave,
    step,
    setStep,
    nextStep,
    setNextStep,
    isDirty,
  } = useContext(SmartConnectorContext);

  const onHide = () => {
    setNextStep(step);
  };
  const onConfirm = () => {
    setStep(nextStep);
  };
  const onConfirmSave = async () => {
    const success = await handleSave(false);
    if (success) {
      setStep(nextStep);
    }
  };
  return (
    <>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Save Changes')}
        actionBtnColor="red"
        leftBtn={<Button disabled={refreshing} />}
        show={step !== nextStep}
        onHide={onHide}
        onConfirm={onConfirm}
        defaultLeftBtnHandler={onConfirmSave}
      >
        {t(
          'You have unsaved changes. Would you like to save them or discard them before continuing?'
        )}
      </ConfirmationModal>
      <ConfirmNavigationModal when={isDirty} />
    </>
  );
};
