import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';

export const NoDashlet = styled.div({
  flex: 1,
  cursor: 'pointer',
  span: {
    textAlign: 'left',
    lineHeight: 1.25,
  },
  '.padded-plus': {
    position: 'absolute',
    left: '50%',
    marginLeft: '-1rem',
    top: '50%',
    marginTop: '-1rem',
    color: colorsButton.green.default,
  },
  '.padded-plus:hover': {
    color: colorsButton.green.hover,
  },
  '.padded-plus:active': {},
});
