import { ACTIVITIES } from 'queries/query-keys';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import ActivityService from 'services/ActivityService';
import { getBusinessClientObject } from 'store/authentication/selectors';

export const useEntityPredefinedOptions = ({
  currentEntity,
  currentObject,
  activity,
  existingPredefinedOptions,
}: any) => {
  const { data: relationshipData, isLoading: relationshipDataLoading } =
    useQuery(
      ACTIVITIES.PRIMARY_ASSOCIATIONS(
        currentEntity?.id,
        activity?.activityObject?.id
      ),
      () =>
        ActivityService.getPrimaryAssociations(
          activity.activityObject.id,
          currentEntity.id
        ),
      {
        enabled: Boolean(currentEntity?.id && activity?.activityObject?.id),
      }
    );

  const clientObject = useSelector(getBusinessClientObject);

  const predefinedOptions = useMemo(() => {
    if (existingPredefinedOptions) {
      return existingPredefinedOptions;
    }

    if (relationshipData && currentObject && currentEntity) {
      let clientOption;
      let companyOption;

      const defaultAssignment: Record<string, any> = Object.keys(
        relationshipData.custom_objects
      ).reduce(
        (acc, curr) => {
          if (curr === clientObject.id) {
            const object = relationshipData.custom_objects[curr];
            clientOption = {
              value: relationshipData.custom_objects[curr].entity_record_id,
              label: `${object.first_name ?? ''} ${object.last_name ?? ''} ${
                object.email ? `(${object.email})` : ''
              }`.trim(),
            };
            return acc;
          }

          return {
            ...acc,
            [curr]: {
              label:
                relationshipData.custom_objects[curr].displayName ||
                relationshipData.custom_objects[curr].display_name ||
                relationshipData.custom_objects[curr].name,
              value: relationshipData.custom_objects[curr].entity_record_id,
            },
          };
        },
        {
          objectId: currentObject.id,
        }
      );

      if (currentObject.fetchUrl === 'client') {
        clientOption = {
          value: currentEntity.id,
          label: currentEntity.display_name,
        };

        companyOption = currentEntity?.company
          ? {
              value: currentEntity.company.id,
              label: currentEntity.company.name,
            }
          : {};
      } else {
        defaultAssignment[currentObject.id] = {
          value: currentEntity.id,
          label:
            currentEntity.displayName ||
            currentEntity.display_name ||
            currentEntity.name ||
            currentEntity.full_name,
        };
      }

      return { defaultAssignment, clientOption, companyOption };
    }

    return {};
  }, [
    existingPredefinedOptions,
    relationshipData,
    currentEntity,
    currentObject,
    clientObject,
  ]);

  return {
    predefinedOptions,
    loading: relationshipDataLoading,
  };
};
