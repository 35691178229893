import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import Loader from 'components/Kizen/Loader';
import { AREA_RESPONSES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { AREA_RESPONSES as RTDV_AREA_RESPONSES } from 'components/Wizards/RTDV/types';
import SubNav from './components/SubNav';
import { useMutation, useQuery } from 'react-query';
import { BUSINESS, DASHBOARD } from 'queries/query-keys';
import { INVERSE_MAPPING } from 'components/Wizards/Dashlet/types';
import { v4 as uuidv4 } from 'uuid';
import { DashboardErrorCodeText, DashboardWarningText } from './styles';
import { DEFAULT_NAME_KEY } from './util';
import { getRoles } from './queries';
import useModal from 'components/Modals/useModal';
import DashletModal from './components/Dashlet/modal';
import { breakpoints, isMobile, useWindowSize } from 'app/spacing';
import 'react-grid-layout/css/styles.css';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import useDashboardConfig from './hooks/useDashboardConfig';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import ResettableDashlet from './components/Dashlet/Resettable';
import { useSelector } from 'react-redux';
import { useDashboardTimeZoneNotice } from './hooks/useDashboardTimeZoneNotice';
import { DashboardStateWrapper } from './context/persistentState';
import DashboardGrid from 'components/DashboardGrid';
import useDashboardGridChildren from 'components/DashboardGrid/hooks/useDashboardChildren';
import {
  DashboardGridContainerBox,
  DashboardGridPageContainer,
} from 'components/DashboardGrid/styles';
import {
  createDashboardQuery,
  fetchDashboardsQuery,
  requestAccessQuery,
} from 'components/AccessRequests/useDashboardList';
import {
  formatDate,
  getSortedDashboards,
} from 'components/DashboardGrid/utils';
import useDashboardInteraction from 'components/DashboardGrid/hooks/useDashboardInteraction';
import { getDashletDimensions } from 'components/DashboardGrid/dimensions';
import CreateLayoutModal from 'components/DashboardGrid/CreateLayoutModal';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import { ErrorMessage } from 'components/ErrorMessage';
import Button from 'components/Button';
import { toastVariant, useToast } from 'components/ToastProvider';
import RequestModal from 'components/AccessRequests/modal';
import { DataManagerWrapper } from './context/dataManager/context';
import { getBusinessClientObject } from 'store/authentication/selectors';
import getDashboardUrl from 'utility/getDashboardUrl';
import { deferExecution } from 'utility/defer';

export const getMetricType = (dashletData) => {
  if (dashletData.reportType?.value === REPORT_TYPES.VALUE_OVER_TIME) {
    return dashletData.valueToDisplay?.value;
  }
  if (dashletData.reportType?.value === REPORT_TYPES.STAGES_OVER_TIME) {
    if (dashletData.value?.value === 'records_number') {
      return dashletData.value?.value;
    } else {
      return dashletData.valueToDisplay?.value;
    }
  }

  if (
    dashletData.area === AREA_RESPONSES.ACTIVITIES &&
    dashletData.reportType?.value ===
      REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS
  ) {
    return 'records_number';
  }

  if (dashletData.area === AREA_RESPONSES.EMAILS) {
    return 'records_number';
  }
  return dashletData.value?.value;
};

export const getActivityIds = (dashletData) => {
  return dashletData?.activities?.map((activity) => activity.value) ?? [];
};

export const getFeExtraInfo = (dashletData) => {
  if (
    dashletData?.dateFilter ||
    dashletData?.scheduledActivitiesConfig ||
    dashletData?.activities?.length ||
    dashletData.reportType?.value === AREA_RESPONSES.TABLE ||
    dashletData?.reportType?.value === RTDV_AREA_RESPONSES.PIVOT_TABLE
  ) {
    const feExtraInfo = {};
    if (dashletData.reportType?.value === AREA_RESPONSES.TABLE) {
      feExtraInfo.columns = dashletData.columns ?? [];
      feExtraInfo.columnWidths = dashletData.columnWidths ?? {};
    }

    if (dashletData?.dateFilter) {
      feExtraInfo.dateFilter = {
        start: formatDate(dashletData.dateFilter?.start),
        end: formatDate(dashletData.dateFilter?.end),
        selectedIndex: dashletData.dateFilter?.selectedIndex,
      };
    }

    if (dashletData?.scheduledActivitiesConfig) {
      feExtraInfo.scheduledActivitiesConfig =
        dashletData.scheduledActivitiesConfig;
    }

    if (dashletData?.pivotTableConfig) {
      feExtraInfo.pivotTableConfig = dashletData.pivotTableConfig;
    }

    if (dashletData?.activities?.length) {
      feExtraInfo.activities = dashletData.activities.reduce((acc, curr) => {
        const res = { ...acc };
        res[curr.value] = curr.label;

        return res;
      }, {});
    }

    return feExtraInfo;
  }
};

export const getChartType = (dashletData) => {
  if (dashletData.reportType?.value === REPORT_TYPES.OPPORTUNITY_CONVERSION) {
    return 'horizontal_bar';
  }
  if (
    dashletData.reportType?.value === REPORT_TYPES.VALUE_OVER_TIME ||
    dashletData.reportType?.value === REPORT_TYPES.STAGES_OVER_TIME ||
    dashletData.reportType?.value === REPORT_TYPES.INTERACTION_STATS
  ) {
    return 'line';
  }
  if (
    dashletData.reportType?.value === REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS
  ) {
    return 'leaderboard';
  }
  return dashletData.display ?? '';
};

export const getStages = (dashletData) => {
  return dashletData.stages?.map((stage) => stage.value) ?? [];
};

export const getObjectId = (dashletData) => {
  if (dashletData.area === AREA_RESPONSES.PIPELINES) {
    return dashletData.pipeline?.value;
  }
  if (dashletData.area === AREA_RESPONSES.ACTIVITIES) {
    if (
      dashletData.reportType?.value ===
      REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS
    ) {
      return dashletData.selectedActivity?.value;
    }
  }

  if (
    dashletData.reportType?.value === REPORT_TYPES.LEADS_ADDED_BY_SOURCE ||
    dashletData.reportType?.value === REPORT_TYPES.LEADS_ADDED
  ) {
    return undefined;
  }

  // object_id needs to be any valid UUID for the API to accept, so we generate a new
  // one in the fallback
  return uuidv4();
};

const reportTypesWithLeads = [
  REPORT_TYPES.LEADS_ADDED_BY_SOURCE,
  REPORT_TYPES.LEADS_ADDED,
  REPORT_TYPES.LEAD_SOURCE_BREAKDOWN,
];

export const getObjectIds = (dashletData) => {
  if (!reportTypesWithLeads.includes(dashletData.reportType?.value)) {
    return undefined;
  }

  return (
    dashletData.objectsWithLeads?.map((o) =>
      typeof o === 'string' ? o : o.value
    ) ?? []
  );
};

const reportTypesWithLeadSources = [
  REPORT_TYPES.LEAD_SOURCE_BREAKDOWN,
  REPORT_TYPES.LEADS_ADDED_BY_SOURCE,
];

export const getLeadSources = (dashletData) => {
  if (!reportTypesWithLeadSources.includes(dashletData.reportType?.value)) {
    return undefined;
  }

  return (
    dashletData?.sourcesToBreakDown?.map((s) => {
      const { value, group } = s;

      if (group === 'custom' || group === 'utm') {
        return {
          source: group,
          value: value,
        };
      }

      return { source: value };
    }) ?? []
  );
};

const setDashboardTitle = (title, { name }) =>
  name ? `${name} - ${title}` : title;

const DashboardPage = ({ title }) => {
  const { t } = useTranslation();

  const { width } = useWindowSize();
  const mobile = isMobile(width, breakpoints.md);
  const history = useHistory();
  useDashboardTimeZoneNotice();
  const [showToast] = useToast();
  const [existingDashboard, setExistingDashboard] = useState({});

  const {
    sections: { dashboards_section },
  } = useSelector(({ authentication }) => authentication.access);

  const clientObject = useSelector(getBusinessClientObject);

  const clientObjectId = clientObject.id;

  const { isLoading: rolesLoading, data: roles } = useQuery(
    BUSINESS.ROLES,
    getRoles
  );
  const {
    isLoading: dashboardsLoading,
    data,
    refetch: refetchDashboards,
  } = useQuery([...DASHBOARD.DASHBOARDS, 'list'], () =>
    fetchDashboardsQuery(null, false, undefined, 'generic_dashboard')
  );

  const sortedDashboards = useMemo(
    () => getSortedDashboards(data ?? []),
    [data]
  );

  const hasDashboards = useMemo(() => {
    const filtered = sortedDashboards.filter((dashboard) => !dashboard.hidden);

    return filtered.length > 0;
  }, [sortedDashboards]);

  const sendRequestMutation = useMutation(
    (result) =>
      requestAccessQuery(
        result.dashboardId,
        result.adminId,
        result.accessLevel
      ),
    {
      onSettled: (data, e) => {
        if (data?.status === 'pending') {
          showToast({
            message: t('Your access request has been sent.'),
            variant: toastVariant.SUCCESS,
          });
        } else {
          showToast({
            message: t('Your access request could not be sent.'),
            variant: toastVariant.FAILURE,
          });
        }
      },
    }
  );

  const [requestModalProps, , { show: handleShowRequestModal }] = useModal({
    handleSubmit: (dashboardId, accessLevel, adminId) =>
      sendRequestMutation.mutate({ dashboardId, accessLevel, adminId }),
    handleHide: () => {
      setExistingDashboard({});
    },
  });

  const {
    loading: configLoading,
    currentDashboard,
    dateFilter,
    setDateFilter,
    teamFilter,
    setTeamFilter,
    refetchDashboard,
    dashboardQueryKey,
    currentDashboardPermission,
    setPersistentDashboardState,
    persistentDashboardState,
    afterCreate,
    dashboardError,
    unsafeDashboardId,
    ranges,
    todayDateInBusinessTimeZone,
    setCurrentDashboardId,
  } = useDashboardConfig(
    sortedDashboards,
    undefined,
    undefined,
    undefined,
    true
  );

  const isLoading = rolesLoading || dashboardsLoading || configLoading;

  useSetTitleOnLoad(title(t), currentDashboard, setDashboardTitle);

  const missingPermission = useMemo(() => {
    if (isLoading) {
      return false;
    }

    return (
      dashboardError?.response?.status === 403 && Boolean(unsafeDashboardId)
    );
  }, [isLoading, dashboardError, unsafeDashboardId]);

  const doesNotExist = useMemo(() => {
    if (isLoading) {
      return false;
    }

    // This is a bit of a hack to get around the issue when the backend
    // rejects the request with a 302 because of a malformed UUID
    if (dashboardError && !dashboardError.response) {
      return Boolean(unsafeDashboardId);
    }

    return (
      dashboardError?.response?.status === 404 && Boolean(unsafeDashboardId)
    );
  }, [isLoading, dashboardError, unsafeDashboardId]);

  const {
    breakpoint,
    setBreakpoint,
    rowHeight,
    setContainerWidth,
    editable,
    draggable,
    hasEditPermission,
    dashlets,
    resizeHandles,
    getDashletPosition,
    duplicateDashlet,
    createDashletMutation,
    updateDashletMutation,
    deleteDashlet,
    updateLayouts,
    forceShowUIElements,
  } = useDashboardInteraction({
    currentPermission: currentDashboardPermission,
    refetchDashboard,
    entityName: t('Dashlet'),
    dashboardQueryKey,
    currentDashboard,
    refetchDashboards,
    isLoading,
  });

  // TODO (keegandonley) - there are currently two implementations of this function in different places - they
  // need to be kept in sync. Once we get these features settled the goal is to go back and unify them.
  const mutateDashletForDashboardOnly = useCallback(
    async ({
      dashboardId,
      dashletData,
      isUpdate = false,
      layoutUpdate = false,
      skipOptimisticUpdate = false,
      isDuplicate = false,
    }) => {
      const oldDashlet = dashlets.find((d) => d.id === dashletData.id);
      const dimensions = getDashletDimensions(dashletData);
      const position = getDashletPosition(dimensions);
      const hasFilters =
        Boolean(dashletData.customFilters) ||
        Boolean(dashletData.inGroupFilters) ||
        Boolean(dashletData.notInGroupFilters);
      const payload = {
        config: {
          entity_type: dashletData.area,
          report_type: dashletData.reportType?.value,
          object_id: getObjectId(dashletData),
          object_ids: getObjectIds(dashletData),
          lead_sources: getLeadSources(dashletData),
          metric_type: getMetricType(dashletData),
          chart_type: getChartType(dashletData),
          historical: dashletData.compare ?? false,
          inverse: INVERSE_MAPPING[dashletData.reportType?.value],
          records_to_include: dashletData?.includedRecords?.value,
          percentage_change_over_time: dashletData?.displayPercent,
          frequency: dashletData.datapointFrequency?.value,
          pipeline_level_of_detail: dashletData.levelOfDetail?.value,
          stages: getStages(dashletData),
          activity_ids: getActivityIds(dashletData),
          fe_extra_info: getFeExtraInfo(dashletData),
          filters: hasFilters
            ? {
                custom_filters: dashletData.customFilters,
                in_group_ids: dashletData.inGroupFilters,
                not_in_group_ids: dashletData.notInGroupFilters,
                custom_object_id:
                  dashletData.customObjectId ??
                  dashletData.selectedFilterObject?.value ??
                  dashletData.selectedFilterObject?.id,
              }
            : undefined,
        },
        layout: {
          i: uuidv4(),
          ...dimensions,
          ...position,
        },
        name: dashletData.name || DEFAULT_NAME_KEY,
      };

      if (isUpdate && oldDashlet) {
        const updatePayload = {
          dashletId: dashletData.id,
          dashboardId,
          payload: {
            config: payload.config,
            name: payload.name || DEFAULT_NAME_KEY,
            layout: {
              i: oldDashlet.layout.i,
              h: oldDashlet.layout.h,
              w: oldDashlet.layout.w,
              x: oldDashlet.layout.x,
              y: oldDashlet.layout.y,
            },
          },
        };
        const { minW, minH, maxW, maxH } = dimensions;
        const { h: oldHeight, w: oldWidth } = oldDashlet.layout;
        if (minW && oldWidth < minW) {
          updatePayload.payload.layout.w = minW;
        }
        if (maxW && oldWidth > maxW) {
          updatePayload.payload.layout.w = maxW;
        }
        if (minH && oldHeight < minH) {
          updatePayload.payload.layout.h = minH;
        }
        if (maxH && oldHeight > maxH) {
          updatePayload.payload.layout.h = maxH;
        }
        updatePayload.layoutUpdate = layoutUpdate;
        updatePayload.skipOptimisticUpdate = skipOptimisticUpdate;
        await updateDashletMutation.mutateAsync(updatePayload);
      } else {
        if (isDuplicate) {
          await duplicateDashlet({
            payload,
            oldDashlet,
            dashlets,
            dashboardId,
          });
        } else {
          await createDashletMutation.mutateAsync({
            dashboardId,
            payload,
          });
        }
      }
    },
    [
      createDashletMutation,
      updateDashletMutation,
      getDashletPosition,
      dashlets,
      duplicateDashlet,
    ]
  );

  const [addDashletModalProps, addDashletModalTriggerProps] = useModal({
    handleSubmit: (dashlet) =>
      mutateDashletForDashboardOnly({
        dashboardId: currentDashboard.id,
        dashletData: dashlet,
        isUpdate: false,
      }),
  });

  const handleClickEditDashboards = useCallback(() => {
    history.push('/dashboard/settings');
  }, [history]);

  const createDashboardMutation = useMutation(
    (result) => createDashboardQuery(result, undefined, 'generic_dashboard'),
    {
      onSuccess: (data) => {
        afterCreate(data.id);
        refetchDashboards();
        deferExecution(() => {
          history.push(`/dashboard/${data.id}`);
        });
      },
    }
  );

  const handleCreateDashboard = useCallback(
    ({ name, permissions, isPrivate }) => {
      createDashboardMutation.mutate({
        name,
        sharing_settings: {
          ...buildSharingSettingsForAPI(permissions).sharing_settings,
          private: isPrivate,
        },
      });
    },
    [createDashboardMutation]
  );

  const [modalProps, , { show: handleShowModal }] = useModal({
    handleSubmit: handleCreateDashboard,
  });

  const handleClickAddDashboard = useCallback(() => {
    handleShowModal();
  }, [handleShowModal]);

  const renderChild = useCallback(
    ({ dashlet, index }) => {
      return (
        <ResettableDashlet
          deleteDashlet={deleteDashlet}
          currentDashboard={currentDashboard}
          dashlet={dashlet}
          onDeleteDashlet={deleteDashlet}
          isDashletUpdating={updateDashletMutation.isLoading}
          isDraggable={draggable && hasEditPermission}
          duplicateDashlet={(newDashlet) => {
            return mutateDashletForDashboardOnly({
              dashboardId: currentDashboard.id,
              dashletData: {
                ...newDashlet,
                id: dashlet.id,
              },
              isDuplicate: true,
            });
          }}
          editDashlet={(
            newDashlet,
            _payload,
            isScheduledActivitiesColumnUpdate
          ) => {
            return mutateDashletForDashboardOnly({
              dashboardId: currentDashboard.id,
              dashletData: { ...newDashlet, id: dashlet.id },
              isUpdate: true,
              layoutUpdate: isScheduledActivitiesColumnUpdate,
              skipOptimisticUpdate: false,
            });
          }}
          canEdit={hasEditPermission}
          dashboardId={currentDashboard.id}
          breakpoint={breakpoint}
          dateFilter={dateFilter}
          teamFilter={teamFilter}
          mobile={mobile}
          clientObjectId={clientObjectId}
          ModalComponent={DashletModal}
          index={index}
        />
      );
    },
    [
      breakpoint,
      clientObjectId,
      currentDashboard,
      dateFilter,
      deleteDashlet,
      draggable,
      hasEditPermission,
      updateDashletMutation.isLoading,
      mutateDashletForDashboardOnly,
      mobile,
      teamFilter,
    ]
  );
  const children = useDashboardGridChildren({
    renderChild,
    forceShowUIElements,
    dashlets,
    hasEditPermission,
    breakpoint,
    addDashletModalTriggerProps,
    mobile,
    dateFilter,
  });

  const requestHandler = useCallback(() => {
    setExistingDashboard({ id: unsafeDashboardId });
    handleShowRequestModal();
  }, [handleShowRequestModal, unsafeDashboardId]);

  if (currentDashboard === null && !isLoading && hasDashboards) {
    const fallbackDashboardId = sortedDashboards?.filter((d) => !d.hidden)?.[0]
      ?.id;
    if (fallbackDashboardId && fallbackDashboardId !== unsafeDashboardId) {
      setCurrentDashboardId(fallbackDashboardId);
      return <Redirect to={`/dashboard/${fallbackDashboardId}`} />;
    }
  }

  if (
    currentDashboard?.type === 'homepage' ||
    currentDashboard?.type === 'chart_group'
  ) {
    const fallbackDashboardId = sortedDashboards?.filter((d) => !d.hidden)?.[0]
      ?.id;
    setCurrentDashboardId(fallbackDashboardId);
    return (
      <Redirect
        to={getDashboardUrl(
          currentDashboard,
          currentDashboard?.customObject,
          clientObjectId
        )}
      />
    );
  }

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  return (
    <DashboardStateWrapper
      setPersistentDashboardState={setPersistentDashboardState}
      persistentDashboardState={persistentDashboardState}
    >
      <DashboardGridPageContainer isMobile={mobile}>
        {dashboards_section?.view ? (
          <SubNav
            currentDashboard={currentDashboard}
            allDashboards={sortedDashboards}
            setDashboard={(id) => history.push(`/dashboard/${id}`)}
            triggerAddDashletProps={addDashletModalTriggerProps}
            setDateFilter={setDateFilter}
            teamFilter={teamFilter}
            dateFilter={dateFilter}
            handleClickEditDashboards={handleClickEditDashboards}
            handleClickAddDashboard={handleClickAddDashboard}
            hasEditPermission={hasEditPermission}
            roleOptions={roles ?? []}
            onChangeGroupedValue={setTeamFilter}
            missing={!currentDashboard}
            unavailable={doesNotExist || missingPermission}
            ranges={ranges}
            todayDateInBusinessTimeZone={todayDateInBusinessTimeZone}
          />
        ) : null}
        <DashboardGridContainerBox>
          <Container>
            {!currentDashboard && (
              <>
                {!dashboards_section?.view ? (
                  <ErrorMessage
                    title={t('Permissions Needed')}
                    text={
                      <DashboardWarningText as="p">
                        {t(
                          'You do not have access to view dashboards. If you believe you should have these permissions, please contact your Business Administrator.'
                        )}
                      </DashboardWarningText>
                    }
                    code={
                      <DashboardErrorCodeText as="p">
                        {t('Error code')}: 403
                      </DashboardErrorCodeText>
                    }
                    dashboard
                  />
                ) : missingPermission ? (
                  <ErrorMessage
                    title={t('Permissions Needed')}
                    text={
                      <DashboardWarningText as="p">
                        {t(
                          'The dashboard you are trying to view requires additional permissions. If you believe you should have permissions to view, please request an access-level increase from a Dashboard Administrator.'
                        )}
                      </DashboardWarningText>
                    }
                    button={
                      <Button color="blue" onClick={requestHandler}>
                        {t('Request Access')}
                      </Button>
                    }
                    code={
                      <DashboardErrorCodeText as="p">
                        {t('Error code')}: 403
                      </DashboardErrorCodeText>
                    }
                    dashboard
                  />
                ) : doesNotExist ? (
                  <ErrorMessage
                    title={t('Not Found')}
                    text={
                      <DashboardWarningText as="p">
                        {t(
                          'The dashboard you are trying to view does not exist. If you believe this is in error, please contact your Business Administrator.'
                        )}
                      </DashboardWarningText>
                    }
                    code={
                      <DashboardErrorCodeText as="p">
                        {t('Error code')}: 404
                      </DashboardErrorCodeText>
                    }
                    dashboard
                  />
                ) : !hasDashboards ? (
                  <ErrorMessage
                    title={t('No Dashboards Available')}
                    text={
                      <DashboardWarningText as="p">
                        {t(
                          'There are currently no dashboards that you have access to in this business. To get started, please click the Dashboard Settings button at the top right of the screen or the Add Dashboard button below.'
                        )}
                      </DashboardWarningText>
                    }
                    button={
                      <Button color="green" onClick={handleClickAddDashboard}>
                        {t('Add Dashboard')}
                      </Button>
                    }
                    dashboard
                  />
                ) : null}
              </>
            )}
            {currentDashboard && currentDashboard.dashlets && (
              <DashboardGrid
                updateLayouts={updateLayouts}
                resizeHandles={resizeHandles}
                rowHeight={rowHeight}
                editable={editable}
                setBreakpoint={setBreakpoint}
                setContainerWidth={setContainerWidth}
              >
                {children}
              </DashboardGrid>
            )}
          </Container>
        </DashboardGridContainerBox>
        <DashletModal
          {...addDashletModalProps}
          clientObjectId={clientObjectId}
        />
      </DashboardGridPageContainer>
      <CreateLayoutModal
        {...modalProps}
        canUserEditPermissions
        entity={t('Dashboard')}
      />
      <RequestModal
        {...requestModalProps}
        dashboard={existingDashboard}
        buttonMode
      />
    </DashboardStateWrapper>
  );
};

const DashboardPageWithDataManager = ({ title }) => {
  const { dashboardId } = useParams();

  if (!dashboardId) {
    return <DashboardPage title={title} />;
  }

  return (
    <DataManagerWrapper key={dashboardId} instanceId={dashboardId}>
      <DashboardPage title={title} />
    </DataManagerWrapper>
  );
};

export default DashboardPageWithDataManager;
