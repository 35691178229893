import ChartManager from '../ChartManager';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import { colorsPrimary, grayScale } from 'app/colors';
import { collapseNumber, ensureCents } from 'utility/numbers';

const WIDTHS = {
  SMALL: 550,
  MEDIUM: 1000,
};

const TOTAL_X_AXIS_PADDING_TOP = 20;

const Y_AXIS_GRID_DISTANCE = 60;
const Y_AXIS_MAX_DEVIATION = 0;

class TrendChartManager extends ChartManager {
  constructor(
    element,
    t,
    data,
    {
      currencySymbol = '',
      mobile = false,
      chartConfig = () => {},
      useAllBullets = false,
      frequency = 'day',
      tooltipLabels = {},
      yAxisTitle = '',
      currencyMode = false,
      isPercentage = false,
    },
    rootConfig,
    ready
  ) {
    super(
      element,
      t,
      am5xy.XYChart,
      {
        panY: false,
      },
      chartConfig,
      rootConfig,
      ready
    );
    this.frequency = frequency;
    this.tooltipLabels = tooltipLabels;
    this.hasTooltipLabels = Object.keys(this.tooltipLabels).length > 0;
    this.data = data;
    this.currencySymbol = currencySymbol;
    this.mobile = mobile;
    this.useAllBullets = useAllBullets;
    this.yAxisTitle = yAxisTitle;
    this.currencyMode = currencyMode;
    this.isPercentage = isPercentage;
  }

  init = (overrideTitle) => {
    this.visibleDataPoints = this.getDataLength();
    this.yAxisTitle = overrideTitle ?? this.yAxisTitle;
    this.chart.set('dx', -15);
    this.createYAxis(
      this.isPercentage
        ? this.percentFormat
        : `${this.currencyMode ? this.currencySymbol : ''}${this.defaultFormat}`
    );
    this.createXAxis();
    this.createSeries();
    this.setData(this.data);
    this.createBullets();
    if (this.getDataLength()) {
      this.createCursor(this.mobile);
    }
    this.show();
  };

  setData = (data) => {
    this.assertRoot('data');
    this.assertSeries('data');
    this.assertYAxis('data');

    const result =
      data?.map((d) => {
        return {
          date: this.getMilliseconds(d.date),
          rawDate: d.date,
          value: d.value,
        };
      }) ?? [];
    this.labelData = result;
    this.series.data.setAll(result);
    this.xAxis.data.setAll(result);
    this.formattedData = result;

    this.configureYAxis(data);
  };

  createYAxis = (format) => {
    this.assertRoot('y-axis');
    this.assertChart('y-axis');
    this.assertYAxisTitle('y-axis');

    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {
          strokeOpacity: 1,
          minGridDistance: Y_AXIS_GRID_DISTANCE,
          stroke: grayScale.mediumLight,
        }),
        numberFormat: format,
        tooltip: this.createStyledTooltip(),
        maxDeviation: Y_AXIS_MAX_DEVIATION,
      })
    );

    this.yAxis.children.unshift(this.getYAxisLabel());

    const renderer = this.yAxis.get('renderer');

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
    });

    renderer.labels.template.setup = (target) => {
      target.set('dx', -10);
    };

    renderer.grid.template.setAll({
      strokeDasharray: [4, 4],
      stroke: grayScale.mediumLight,
      strokeOpacity: 1,
    });
  };

  getDataLength = () => {
    return this.data.length;
  };

  handleZoomChange = (start, end) => {
    const realStart = start ?? this.rangeStart ?? 0;
    const realEnd = end ?? this.rangeEnd ?? 1;

    const range = realEnd - realStart;

    const visibleDataPoints = Math.ceil(this.getDataLength() * range);
    this.visibleDataPoints = visibleDataPoints;

    const w = this.xAxis.getPrivate('width');

    this.handleXAxisWidth(w);

    this.rangeStart = realStart;
    this.rangeEnd = realEnd;
  };

  getBaseInterval = () => {
    return this.frequency === 'quarter'
      ? {
          timeUnit: 'month',
          count: 1,
        }
      : {
          timeUnit: this.frequency ?? this.DEFAULT_TIME_UNIT_FREQUENCY,
          count: this.DEFAULT_TIME_UNIT_COUNT,
        };
  };

  createXAxis = () => {
    this.assertRoot('x-axis');
    this.assertChart('x-axis');

    this.xAxis = this.chart.xAxes.push(
      am5xy.GaplessDateAxis.new(this.root, {
        baseInterval: this.getBaseInterval(),
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 0,
          strokeOpacity: 0,
        }),
        tooltip: this.createStyledTooltip(),
        markUnitChange: false,
      })
    );

    this.xAxis.on('start', (value) => this.handleZoomChange(value));
    this.xAxis.on('end', (value) => this.handleZoomChange(undefined, value));

    const renderer = this.xAxis.get('renderer');

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
    });

    renderer.labels.template.setup = (target) => {
      target.set('dy', 10);
    };

    renderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    if (this.hasTooltipLabels) {
      const tooltip = this.xAxis.get('tooltip');

      tooltip.label.adapters.add('text', (text) => {
        let dataItem;

        // Getting a series in amcharts is tricky, so for this use case we can just iterate
        // until the final one since every series will have the same timescale by definition.
        this.chart.series.each(function (series) {
          const result = series.get('tooltipDataItem') ?? series.dataItems?.[0];
          if (result) {
            dataItem = result;
          }
        });
        if (dataItem) {
          const label = dataItem.dataContext?.rawDate;
          if (label && this.tooltipLabels[label]) {
            return this.tooltipLabels[label];
          }
        }
        return text;
      });

      this.xAxis.onPrivate('width', this.handleXAxisWidth);
    }
  };

  // These label counts were determined by trial-and-error with different
  // chart widths and configurations to find the optimal counts, since we need
  // to manually choose which x-axis labels to hide and show
  getLabelCount = (width, frequency) => {
    if (width < WIDTHS.SMALL) {
      if (frequency === 'day') {
        return 4;
      }
      if (frequency === 'week') {
        return 3;
      }
      if (frequency === 'month') {
        return 4;
      }
      if (frequency === 'quarter') {
        return 4;
      }
    } else if (width < WIDTHS.MEDIUM) {
      if (frequency === 'day') {
        return 6;
      }
      if (frequency === 'week') {
        return 4;
      }
      if (frequency === 'month') {
        return 6;
      }
      if (frequency === 'quarter') {
        return 6;
      }
    } else {
      if (frequency === 'day') {
        return 8;
      }
      if (frequency === 'week') {
        return 6;
      }
      if (frequency === 'month') {
        return 8;
      }
      if (frequency === 'quarter') {
        return 8;
      }
    }

    return 5;
  };

  getManualFrequencyForWidth = (width, visibleCount) => {
    if (width < WIDTHS.SMALL) {
      if (visibleCount <= 3) {
        return 1;
      }
      return 3;
    }
    if (width < WIDTHS.MEDIUM) {
      if (visibleCount <= 2) {
        return 1;
      }
      return 3;
    }
    return 1;
  };

  handleXAxisWidth = (width) => {
    const frequency = this.frequency ?? this.DEFAULT_TIME_UNIT_FREQUENCY;
    const labels = this.getLabelCount(width, frequency);
    const interval = Math.ceil(this.getDataLength() / labels);

    // If there's only 1 data point visible, always label it no matter where it
    // is in the sequence of data
    if (this.visibleDataPoints === 1) {
      this.forceLabelAll = true;
    } else {
      this.forceLabelAll = false;
    }

    // If there are too few data points visible (but more than 1), we need to
    // do some special logic for calculating which labels to show
    if (this.visibleDataPoints <= interval) {
      this.forceLabel = true;
      this.createOrUpdateAxisLabelAdapter(this.labelData, labels, width);
    } else {
      this.forceLabel = false;
      this.createOrUpdateAxisLabelAdapter(this.labelData, labels, width);
    }
  };

  createOrUpdateAxisLabelAdapter = (data, labelCount = 5, width) => {
    if (this.hasTooltipLabels) {
      const mapping = data.reduce((acc, curr, index) => {
        return {
          ...acc,
          [curr.rawDate]: index,
        };
      }, {});

      const interval = Math.ceil(data.length / labelCount);

      const renderer = this.xAxis.get('renderer');

      renderer.labels.template.adapters.remove('text');
      renderer.labels.template.adapters.add('text', (_text, target) => {
        if (target?.dataItem?.dataContext) {
          const label = target.dataItem.dataContext.rawDate;
          const index = mapping[target.dataItem.dataContext.rawDate];

          // Manually select which labels to show and which to hide based
          // on the selected interval and how many datapoints are visible
          if (index % interval !== 0 && !this.forceLabel) {
            return undefined;
          }

          // Calculate a secondary frequency for cases when the chart is zoomed in
          const secondaryFrequency = this.getManualFrequencyForWidth(
            width,
            this.visibleDataPoints
          );

          if (
            (label &&
              this.tooltipLabels[label] &&
              (!this.forceLabel ||
                (this.forceLabel && index % secondaryFrequency === 0))) ||
            this.forceLabelAll
          ) {
            return this.tooltipLabels[label];
          }
        }
        return ' ';
      });
    }
  };

  createTotalAxis = () => {
    this.assertRoot('total axis');
    this.assertChart('total axis');

    if (this.totalAxis) {
      am5.array.each(this.totalAxis, (series) => {
        this.chart.series.remove(series);
      });
      this.chart.xAxes.removeValue(this.totalAxis);
      this.totalAxis = null;
      this.totalSeries = null;
    }

    this.totalAxis = this.chart.xAxes.push(
      am5xy.GaplessDateAxis.new(this.root, {
        baseInterval: this.getBaseInterval(),
        renderer: am5xy.AxisRendererX.new(this.root, {
          minGridDistance: 0,
          strokeOpacity: 0,
          opposite: true,
        }),
        paddingTop: TOTAL_X_AXIS_PADDING_TOP,
        markUnitChange: false,
        tooltip: this.createStyledTooltip(
          undefined,
          {
            dy: 20,
          },
          undefined,
          {
            fill: colorsPrimary.blue.dark,
            stroke: colorsPrimary.blue.dark,
          }
        ),
      })
    );

    const renderer = this.totalAxis.get('renderer');
    const tooltip = this.totalAxis.get('tooltip');

    renderer.labels.template.set('forceHidden', true);

    renderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    tooltip.adapters.remove('forceHidden');
    tooltip.adapters.add('forceHidden', () => {
      let total = -1;
      this.chart.series.each((series) => {
        const item = series.get('tooltipDataItem');
        if (item) {
          // We want to get the last series since it contains the totals
          total = item.get('valueY');
        }
      });

      return total < 0;
    });

    tooltip.label.adapters.remove('text');
    tooltip.label.adapters.add('text', () => {
      let total = -1;
      this.chart.series.each((series) => {
        const item = series.get('tooltipDataItem');
        if (item) {
          // We want to get the last series since it contains the totals
          total = item.get('valueY');
        }
      });

      return total >= 0
        ? `${this.t('Total')}: ${this.currencyMode ? this.currencySymbol : ''}${
            this.currencyMode ? ensureCents(collapseNumber(total)) : total
          }`
        : undefined;
    });
  };

  createTotalSeries = (data) => {
    this.assertRoot('total series');
    this.assertChart('total series');

    if (!this.totalSeries) {
      this.totalSeries = this.chart.series.push(
        am5xy.LineSeries.new(this.root, {
          name: 'Total',
          xAxis: this.totalAxis,
          yAxis: this.yAxis,
          valueYField: 'value',
          valueXField: 'date',
          stacked: true,
        })
      );
    }

    this.totalSeries.strokes.template.setAll({
      forceHidden: true,
    });

    this.totalSeries.data.setAll(
      data?.map((d) => ({
        date: this.getMilliseconds(d.date),
        rawDate: d.date,
        value: d.value,
      })) ?? []
    );

    const max = Math.max(...data.map((d) => d.value));

    this.yAxis.set('min', 0);
    this.yAxis.set('max', max * 1.1);
  };

  createSeries = (name = 'Unnamed Series') => {
    this.assertRoot('series');
    this.assertYAxis('series');
    this.assertXAxis('series');

    this.series = this.chart.series.push(
      am5xy.LineSeries.new(this.root, {
        name,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: colorsPrimary.blue.dark,
        fill: colorsPrimary.blue.dark,
        tooltip: this.createStyledTooltip(
          this.isPercentage
            ? `${this.valueYAccessor}%`
            : `${this.currencyMode ? this.currencySymbol : ''}${
                this.valueYAccessor
              }`
        ),
      })
    );
  };

  createBullets = () => {
    this.assertRoot('bullets');
    this.assertSeries('bullets');

    this.series.bullets.push((_root, _series, item) => {
      // Only show the bullet if the value is non-zero
      if (item.dataContext.value !== 0 || this.useAllBullets) {
        return am5.Bullet.new(this.root, {
          sprite: am5.Circle.new(this.root, {
            radius: 3,
            fill: colorsPrimary.blue.dark,
            stroke: grayScale.white,
          }),
        });
      }
    });
  };

  createCursor = (mobile = false) => {
    this.assertRoot('cursor');
    this.assertChart('cursor');

    this.cursor = am5xy.XYCursor.new(this.root, {
      // We don't want the zoom enabled on mobile because it makes it very difficult to
      // use the cursor
      behavior: mobile ? undefined : 'zoomX',
    });

    // this.cursor.lineY.setAll({
    //   dy: -6
    // });

    this.chart.set('cursor', this.cursor);
  };

  // Set the axis minimum if the data is all 0s, so that the data renders
  // along the baseline, not in the center of the chart
  configureYAxis = (data = []) => {
    this.assertYAxis('y-axis configuration');
    if (data) {
      const isZeros = data.every(({ value }) => value === 0);
      if (isZeros) {
        this.element.setAttribute('data-qa-dashlet-empty', 'true');
        this.yAxis.set('min', 0);
      } else {
        this.element.setAttribute('data-qa-dashlet-empty', 'false');
        this.yAxis.set('min', undefined);
      }
    }
  };

  getYAxisLabel = (override, ml, mr) => {
    this.assertRoot('y-axis label');

    if (override) {
      return am5.Label.new(this.root, {
        rotation: -90,
        text: override,
        y: am5.p50,
        centerX: am5.p50,
        marginLeft: ml,
        marginRight: mr,
        fill: grayScale.mediumDark,
      });
    }
    this.assertYAxisTitle('y-axis label');

    const shouldTruncate = this.yAxisTitle.length > 40;
    const displayTitle = shouldTruncate
      ? `${this.yAxisTitle.slice(0, 40)}...`
      : this.yAxisTitle;

    const label = am5.Label.new(this.root, {
      rotation: -90,
      text: displayTitle,
      y: am5.p50,
      centerX: am5.p50,
      fill: grayScale.mediumDark,
      background: am5.Rectangle.new(this.root, {
        fill: am5.color(grayScale.white),
      }),
    });

    if (shouldTruncate) {
      const tooltip = this.createStyledTooltip(
        undefined,
        {
          pointerOrientation: 'left',
          dx: 6,
        },
        {
          width: 300,
          maxWidth: 300,
          oversizedBehavior: 'wrap',
        }
      );
      label.set('tooltip', tooltip);
      label.set('tooltipText', this.yAxisTitle);
    }

    return label;
  };
}

export default TrendChartManager;
