import {
  useState,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
  cloneElement,
} from 'react';
import { defaultsDeep } from 'lodash';
import DateTextInlineInput from 'components/Inputs/inline/TextInput/presets/DatePicker';
import DateInput, {
  Menu,
  getDateOption,
  DATE_EMPTY_FORMAT,
} from '../DateInput';
import { basePopperConfig, baseModalPopperConfig } from './helpers';
import { TextInputInlineWrapper } from './TextInput/styles';
import SelectOverlay from '../SelectOverlay';
import { popperModifiers } from 'components/helpers';
import { WithTooltip } from './WithTooltip';
import { FIELD_TYPES } from 'utility/constants';
import { useKeyBoardContext } from '../../../hooks/keyboardEventHandler/useKeyBoardContext';
import { useCustomSelectMenuProps } from '../../../hooks/keyboardEventHandler/useCustomSelectMenuProps';

export const DateInputInlineSelect = forwardRef(
  ({ onChange = null, ...others }, ref) => {
    return (
      <DateInput
        ref={ref}
        menuOnly
        onChange={(val) => {
          // date -> option for compatibility with SelectInline
          return onChange?.(getDateOption(val));
        }}
        {...others}
      />
    );
  }
);

DateInputInlineSelect.displayName = 'DateInputInlineSelect';

export const nudgePopperUp = defaultsDeep({}, basePopperConfig, {
  modifiers: [
    popperModifiers.offSet(0, -2), // Align directly under TextControl text
  ],
});

// baseModalPopperConfig sets the zIndex to zIndexes.popoverInModal
export const modalNudgePopperUp = defaultsDeep({}, baseModalPopperConfig, {
  modifiers: [
    popperModifiers.offSet(0, -9), // Align directly under EditableText text
  ],
});

export default function DateInputInline({
  onSubmit = null,
  children: selectEl = <DateInputInlineSelect />,
  inModal = false,
  readOnly = false,
  autoFocus = null,
  showToolTip = false,
  tooltipLabel = '',
  popperConfig = null,
  placement = 'top',
  labelEl = null,
  className = '',
  trackExternal = true,
  placeholder = '',
  field,
  shouldFocusNext,
  object,
  ...others
}) {
  const { value: valueProp } = selectEl.props;
  const dateRef = useRef();
  const valuePropRef = useRef(valueProp);
  const [submitting, setSubmitting] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [date, setDate] = useState(() => getDateOption(valueProp));

  // need to watch value prop incase it get's updated externally
  if (trackExternal && valuePropRef.current !== valueProp) {
    setDate(getDateOption(valueProp));
    valuePropRef.current = valueProp;
  }

  const handleSubmit = async (v) => {
    try {
      setSubmitting(true);
      const newValue = v ? v.date : date.date;
      setShowDate(false);
      await onSubmit(newValue);
      if (v) setDate(v);
      return true;
    } catch (err) {
      setDate(getDateOption(valueProp));
      return false;
    } finally {
      setSubmitting(false);
    }
  };
  const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();
  const handleHide = () => {
    setShowDate(false);
    if (onSubmit) {
      handleSubmit();
    }
    if (assignFieldHandle && !shouldFocusNext) {
      dateRef.current?.querySelector('input')?.focus();
    }
  };
  useEffect(() => {
    if (autoFocus && !assignFieldHandle) {
      setShowDate(true);
    }
  }, [autoFocus, assignFieldHandle]);

  const fieldId = useMemo(() => `${field?.id}-${object?.id}`, [field, object]);

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectEl,
    {
      shouldUseKeyboardEventHandle: true,
      onBlur: (e, shouldGoNext) =>
        getKeyListenersProps?.(fieldId)?.onBlur(e, shouldGoNext),
      ...others,
    }
  );
  assignFieldHandle(fieldId, {
    customUp: () => {
      dateRef.current?.querySelector('input')?.focus({ preventScroll: true });
      setShowDate(true);
    },
    customDown: () => {
      dateRef.current?.querySelector('input')?.focus({ preventScroll: true });
      setShowDate(true);
    },
    disabled: readOnly,
    shouldFocusNext,
  });

  const onClickInput = () => {
    if (!showDate && !readOnly) {
      setShowDate(true);
    }
  };

  const onHideOverlay = (e) => {
    if (e && !dateRef.current.contains(e.target)) {
      handleHide();
    }
  };

  const onSubmitInput = (val) => {
    handleSubmit(getDateOption(val));
  };

  const onParentControl = () => {
    setShowDate(false);
  };

  return (
    <>
      <TextInputInlineWrapper ref={dateRef} button={false}>
        <WithTooltip
          tooltipLabel={tooltipLabel}
          popperConfig={popperConfig}
          placement={placement}
          showToolTip={showToolTip}
        >
          <DateTextInlineInput
            field={field}
            object={object}
            className={`${className} ViewText DateText`}
            disabled={submitting}
            onSubmit={onSubmitInput}
            isDateInput
            readOnly={readOnly}
            isDateTextInlineInput
            showToolTip={showToolTip}
            showError
            emptyPlaceholder={labelEl ? DATE_EMPTY_FORMAT : '—'}
            onClick={onClickInput}
            {...others}
            value={date ? date.label : ''}
            placeholder={placeholder || FIELD_TYPES.Date.placeholder}
            {...getKeyListenersProps?.(fieldId)}
            shouldFocusNext={shouldFocusNext}
            onParentControl={onParentControl}
          />
        </WithTooltip>
        <SelectOverlay
          show={showDate}
          target={dateRef.current}
          onHide={onHideOverlay}
          popperConfig={inModal ? modalNudgePopperUp : nudgePopperUp}
          iDoNotWantToMountTwice
        >
          {cloneElement(selectEl, {
            onChange: handleSubmit,
            value: date,
            sizing: false,
            components: { Menu },
            ...customSelectMenuProps,
          })}
        </SelectOverlay>
      </TextInputInlineWrapper>
    </>
  );
}
