import { useMemo, useCallback } from 'react';
import { convertArrayToCSV } from 'convert-array-to-csv';
import {
  isEmailStatusField,
  isEntityValueField,
  isPercentageChanceToCloseField,
} from 'checks/fields';
import { FIELD_TYPES } from 'utility/constants';
import { monitoringMessageHelper } from 'sentry/helpers';

const getRelationshipValue = (relation) => {
  if (relation.fetchUrl === 'client') return 'Support@Kizen.com';

  return 'My First Record';
};

const getPhoneNumberValue = ({ enableExtension }) =>
  enableExtension ? '1 (888) 555-0199 x123' : '1 (888) 555-0199';

const getFirstNameValue = (array, index = 0) =>
  array[index] ? `${array[index].name}` : '-';

const getStringFromArray = (arr, delimeter = ',') =>
  arr
    .slice(0, 2)
    .map((item) => item.name)
    .join(delimeter);

const getMoneyValue = (el) => `${el.moneyOptions.symbol} 1000`;

const CUSTOM_OBJECT_CASES_MAPPING = {
  name: () => 'New Entity Record',
  owner: ({ teamMember }) => teamMember.email,
  stage: ({ el }) => getFirstNameValue(el.options),
  estimated_close_date: () => `12/31/${new Date().getFullYear()}`,
  actual_close_date: () => `12/31/${new Date().getFullYear()}`,
  entity_value: ({ el }) => getMoneyValue(el),
  percentage_chance_to_close: () => '80',
};

const TEAM_MEMBERS_CASES_MAPPING = {
  first_name: () => 'Kizen',
  last_name: () => 'Support',
  email: () => 'Support@Kizen.com',
  phone: () => '+1 (888) 555-0199',
  start_date: () =>
    new Date(new Date().getFullYear(), 11, 31).toLocaleDateString('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  additional_roles: () => 'Admin',
  additional_permission_groups: () => 'Admin',
  toolbar_template: () => 'Default Template',
  track_annual_sales_goal: () => 'Yes',
  annual_sales_goal: () => '1000000.00',
};

const CONTACTS_CASES_MAPPING = {
  first_name: () => 'Kizen',
  last_name: () => 'Support',
  email: () => 'Support@Kizen.com',
  titles: ({ el }) =>
    el.options?.length ? `${getStringFromArray(el.options)}` : 'COO',
  tags: ({ el }) =>
    el.options?.length ? `${getStringFromArray(el.options)}` : 'A,B',
  birthday: () => '12/31/2015',
  email_status: () => 'Opted In',
  timezone: () => 'America/Chicago',
  business_phone: () => '+1 (888) 555-0199',
  mobile_phone: () => '+1 (888) 555-0199',
  home_phone: () => '+1 (888) 555-0199',
};

const STATIC_CASES_MAPPING = {
  pipeline: CUSTOM_OBJECT_CASES_MAPPING,
  standard: CUSTOM_OBJECT_CASES_MAPPING,
  contact: CONTACTS_CASES_MAPPING,
  teamMembers: TEAM_MEMBERS_CASES_MAPPING,
};

const FIELDS_MAPPING = {
  [FIELD_TYPES.Relationship.type]: ({ el }) =>
    getRelationshipValue(el.relation),
  [FIELD_TYPES.Text.type]: () => 'Note on record.',
  [FIELD_TYPES.Checkbox.type]: () => 'Yes',
  [FIELD_TYPES.Checkboxes.type]: ({ el }) =>
    `${getStringFromArray(el.options)}`,
  [FIELD_TYPES.Dropdown.type]: ({ el }) => getFirstNameValue(el.options),
  [FIELD_TYPES.Radio.type]: ({ el }) => getFirstNameValue(el.options),
  [FIELD_TYPES.Date.type]: () => `12/31/${new Date().getFullYear()}`,
  [FIELD_TYPES.DateTime.type]: () => `12/31/${new Date().getFullYear()} 13:00`,
  [FIELD_TYPES.DynamicTags.type]: ({ el }) =>
    el.options?.length ? `${getStringFromArray(el.options)}` : 'A,B',
  [FIELD_TYPES.Email.type]: () => 'Support@Kizen.com',
  // FILES: 'files', // we don't support this case
  [FIELD_TYPES.LongText.type]: () =>
    'This is a message in a long text box meant for uploading.',
  [FIELD_TYPES.Decimal.type]: () => '1000000.01',
  [FIELD_TYPES.Integer.type]: () => '1000000',
  [FIELD_TYPES.PhoneNumber.type]: ({ el }) =>
    getPhoneNumberValue(el.phonenumberOptions),
  [FIELD_TYPES.Money.type]: ({ el }) => getMoneyValue(el),
  [FIELD_TYPES.Rating.type]: ({ el }) => el.rating.maxValue,
  [FIELD_TYPES.Status.type]: ({ el }) => getFirstNameValue(el.options),
  [FIELD_TYPES.YesNoMaybe.type]: () => 'Yes',
  [FIELD_TYPES.TeamSelector.type]: ({ teamMember }) => teamMember.email,
  active_subscription_lists: () => 'Newsletter',
};

export const joinEmailStatusOptions =
  'Opted In, Not Opted In, Unsubscribed From All, or Suppression List';

const TYPES_MAPPING = {
  pipeline: (fields, object) => {
    return fields.reduce((acc, item) => {
      if (item.fieldType === FIELD_TYPES.Files.type) return acc; // we don't support this case

      if (isEntityValueField(item) && !object.trackEntityValue) return acc; // skip this case
      if (
        isPercentageChanceToCloseField(item) &&
        !object.pipeline.includePercentageToClose
      )
        return acc; // skip this case

      return acc.concat(item);
    }, []);
  },
  standard: (fields) =>
    fields.filter((e) => e.fieldType !== FIELD_TYPES.Files.type), // we don't support this case
  contact: (fields) =>
    fields.filter((e) => e.fieldType !== FIELD_TYPES.Files.type), // we don't support this case
  teamMembers: (fields) => fields,
};

// BOM has no meaning in UTF-8 (as for UTF-8 encoding endianness has no sence),
// but once added it is encoded itno a UTF-8 bite sequence
// that can be later used as a marker to detect UTF-8 encoding;
// see https://en.wikipedia.org/wiki/Byte_order_mark#UTF-8
export const UTF_8_BOM = '\ufeff';
export const UTF_8_BOM_DECIMAL_SEQUENCE = [239, 187, 191];

export const hasUTF8BOM = async (file) => {
  try {
    const buffer = await file.slice(0, 3).arrayBuffer();
    const view = new Uint8Array(buffer);
    return UTF_8_BOM_DECIMAL_SEQUENCE.every(
      (code, index) => code === view[index]
    );
  } catch (error) {
    monitoringMessageHelper('Error reading BOM of CSV file', {
      extra: { error: error },
    });
    return false;
  }
};

export const useCSVString = ({
  fields,
  teamMember,
  customObject,
  includeRow = true,
}) => {
  const SCVString = useMemo(() => {
    const formattedFields = TYPES_MAPPING[customObject.objectType](
      fields,
      customObject
    );
    const header = formattedFields.map((el) => `${el.canonicalDisplayName}`);

    const dataArrays = includeRow
      ? formattedFields.map((el) => {
          if (
            Object.keys(STATIC_CASES_MAPPING[customObject.objectType]).includes(
              el.name
            )
          ) {
            return STATIC_CASES_MAPPING[customObject.objectType][el.name]({
              el,
              teamMember,
            });
          }

          // other
          return FIELDS_MAPPING[el.fieldType]({
            el,
            teamMember,
          });
        })
      : [];

    const emailStatusIndex = formattedFields.findIndex(isEmailStatusField);

    if (emailStatusIndex >= 0) {
      header[emailStatusIndex] += ` (${joinEmailStatusOptions})`;
    }

    return convertArrayToCSV([dataArrays], {
      header,
    });
  }, [fields, teamMember, includeRow, customObject]);

  const downloadHandler = useCallback(() => {
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,${UTF_8_BOM}${encodeURIComponent(SCVString)}`
    );
    if (customObject.objectName === 'team_members') {
      link.setAttribute('download', 'kizen_teammember_upload.csv');
    } else {
      link.setAttribute(
        'download',
        `kizen_${customObject.objectName}_upload.csv`
      );
    }

    link.setAttribute('target', '_blank');
    link.style.display = 'none';
    document.body.appendChild(link); // Required for FF

    link.click();

    // clear element after
    link.parentNode.removeChild(link);
  }, [SCVString, customObject]);

  return {
    SCVString,
    downloadHandler,
  };
};
