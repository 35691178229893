import ChartManager from '../ChartManager';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import { colorsPrimary, colorsSecondary, grayScale } from 'app/colors';
import { ensureCents, collapseNumber } from 'utility/numbers';
import EventQueue from '../EventQueue';

const MAX_OPACITY = 1;
const MIN_OPACITY = 0.4;
const ROW_HEIGHT_PERCENT = {
  SCROLLED: 100,
  BASE: 100,
};
const USE_EXPERIMENTAL_PADDING = false;
const STROKE_DASHARRAY = [4, 4];
const SOLID_STROKE_DASHARRAY = [4, 0];
const GOAL_STROKE_DASHARRAY = [3, 3];
const MAX_VISIBLE_ITEMS = 6;
const MIN_BAR_LABEL_WIDTH = 40;
const CHART_DELTA_Y = 0;
const MAX_BAR_HEIGHT = 75;
const BAR_GAP = 15;
const LABEL_MAX_CHAR_COUNT = 17;
const ALLOWED_UI_DELAY_MS = 50;
const ANIMATION_DURATION = 200;

// Returns nulls instead of 0s so that we don't get empty bars drawn
// along the x-axis, which creates issues with labels and serves no purpose.
const getMissedValue = (goal, achieved) => {
  if (goal <= 0) {
    return null;
  }

  const missed = goal - achieved;
  if (missed <= 0) {
    return null;
  }

  return missed;
};

const getStyle = (isCompleted, index, totalCount) => {
  if (isCompleted) {
    return {
      fill: colorsPrimary.green.light,
      stroke: grayScale.white,
    };
  }

  const opacityRange = MAX_OPACITY - MIN_OPACITY;
  const opacityStops = totalCount > 0 ? opacityRange / (totalCount - 1) : 0;
  const opacity = MAX_OPACITY - opacityStops * index;
  return {
    // We need to use rgba here, not hex with transparency, so that the bars aren't see-through.
    // The following is the rgba value for our dark blue
    fill: `rgba(156,189,248,${opacity})`,
    stroke: grayScale.white,
  };
};

class LeaderboardChartManager extends ChartManager {
  constructor(
    element,
    t,
    unprocessedData,
    { currencySymbol, fixedScale, isValue },
    ready
  ) {
    const config = {
      panX: false,
    };

    if (unprocessedData.result.length > MAX_VISIBLE_ITEMS) {
      config.wheelY = 'panY';
      config.wheelStep = 2;
      config.panY = true;
    }

    super(
      element,
      t,
      am5xy.XYChart,
      config,
      (root) => ({
        layout: root.verticalLayout,
        scrollbarY: am5.Scrollbar.new(root, {
          orientation: 'vertical',
          start: 0,
          end:
            (unprocessedData?.result ?? []).length > MAX_VISIBLE_ITEMS
              ? ((unprocessedData?.result ?? []).length - MAX_VISIBLE_ITEMS) *
                  0.05 +
                0.2
              : 0,
          // End sets the final zoom stop. These values used to set the end are based on what percentage of the view
          // we want in the initial render and were most attained through trial and error
          width: 4,
        }),
      }),
      {
        // This will make leaderboards render at a higher resolution on retina devices (like smartphones)
        // This means we could end up hitting the memory limit on browsers like mobile Safari, so it's only
        // enabled on this chart type to try to prevent that.
        useSafeResolution: false,
      },
      ready
    );
    this.eventQueue = new EventQueue(ALLOWED_UI_DELAY_MS);
    this.fixedScale = fixedScale;
    this.data = unprocessedData?.result ?? [];
    this.employeeMapping = unprocessedData?.employeeMetadataByKey ?? {};
    this.seriesMap = {};
    this.formattedData = [];
    this.currencySymbol = currencySymbol;
    this.isValue = isValue;

    // handles to the label for the goal series (dotted line at the goal)
    this.goalLabelHandles = [];

    // handles to the invisible circles at the end of each bar for the total tooltip
    this.totalCircleHandles = [];

    // handles to the line at the end of the bar that shows on hover to indicate the total
    this.totalLineHandles = [];

    this.root.numberFormatter.setAll({
      numberFormat: this.isValue ? `${this.currencySymbol}0.##a` : '#.##',
      smallNumberPrefixes: [],
      /*
        The defaults (below) don't make a lot of sense in the context of currency,
        so we override the big number prefixes to only be k, M, B, T.

        Note that this formatting is only used for axis labels, as we override the
        number formatter on series labels and tooltips.
        [
          {"number":1000,"suffix":"k"},
          {"number":1000000,"suffix":"M"},
          {"number":1000000000,"suffix":"G"},
          {"number":1000000000000,"suffix":"T"},
          {"number":1000000000000000,"suffix":"P"},
          {"number":1000000000000000000,"suffix":"E"},
          {"number":1e+21,"suffix":"Z"},
          {"number":1e+24,"suffix":"Y"}
        ]
      */
      bigNumberPrefixes: [
        { number: 1e3, suffix: 'k' },
        { number: 1e6, suffix: 'M' },
        { number: 1e9, suffix: 'B' },
        { number: 1e12, suffix: 'T' },
      ],
    });
    this.chart.zoomOutButton.set('forceHidden', true);
  }

  createTheme = () => {
    this.assertRoot('create theme');
    this.theme = am5.Theme.new(this.root);

    this.theme.rule('Grid').setAll({
      strokeDasharray: STROKE_DASHARRAY,
    });

    this.theme.rule('Grid', ['base']).setAll({
      strokeDasharray: SOLID_STROKE_DASHARRAY,
      stroke: grayScale.mediumLight,
    });

    this.root.setThemes([this.theme]);
  };

  init = (setScrollHeight, setChartWidth, setTooltipVisible) => {
    this.scrollCb = setScrollHeight;
    this.widthCb = setChartWidth;
    this.tooltipCb = setTooltipVisible;

    this.chart.set('dx', -15);
    this.createYAxis();
    this.createXAxis();
    this.createData();
    if (this.isValue) {
      this.makeGoalSeries();
    }
    this.makeTotalSeries();
    this.configureScrollbar();
    this.createTheme();

    this.show();
  };

  createStyledTooltipWithHandler = (
    labelText,
    opts,
    labelOpts,
    backgroundOpts
  ) => {
    const tooltip = this.createStyledTooltip(
      labelText,
      opts,
      labelOpts,
      backgroundOpts
    );

    if (this.tooltipCb) {
      tooltip.on('visible', this.tooltipCb);
    }

    tooltip.setAll({ pointerOrientation: opts?.pointerOrientation ?? 'down' });

    return tooltip;
  };

  configureScrollbar = () => {
    this.assertChart('configure scrollbar');
    this.assertRoot('configure scrollbar');

    const scrollbar = this.chart.get('scrollbarY');

    scrollbar.startGrip.setAll({
      visible: false,
    });

    scrollbar.endGrip.setAll({
      visible: false,
    });

    scrollbar.thumb.setAll({
      width: 4,
      fill: grayScale.mediumDark,
    });

    scrollbar.get('background').setAll({
      fillOpacity: 0,
    });

    scrollbar.hide();

    if (this.data.length > MAX_VISIBLE_ITEMS) {
      this.root.dom.addEventListener('pointerover', () => {
        scrollbar.show();
      });

      this.root.dom.addEventListener('pointerout', () => {
        scrollbar.hide();
      });
    }
  };

  handleScrollbarPosition = (value) => {
    this.scrollCb?.(value);
  };

  show = () => {
    this.chart.appear(1000, 100);
  };

  // Force the padding between bars to be 15px (or within a fraction of a pixel depending on the height)
  // Amcharts doesn't provide a mechanism for setting fixed pixel padding between bars, so we need
  // to do some math to calculate it
  handleYAxisHeight = (height) => {
    const count =
      this.data.length > MAX_VISIBLE_ITEMS
        ? MAX_VISIBLE_ITEMS
        : this.data.length;

    this.yAxisHeight = height;
    const itemHeight = height / count;

    if (itemHeight < MAX_BAR_HEIGHT + 1) {
      const percent = (BAR_GAP / itemHeight) * 100;

      Object.entries(this.seriesMap).forEach(([_key, value]) => {
        value.columns.template.setAll({
          height: am5.percent(100 - percent),
        });
      });

      this.goalSeries?.setAll({
        stepWidth: am5.percent(100 - percent - 1),
      }); // Subtract 1% from the goal series for visual alignment
      this.totalSeries?.setAll({
        stepWidth: am5.percent(100 - percent - 1),
      }); // Subtract 1% from the goal series for visual alignment

      this.missedSeries.columns.template.setAll({
        height: am5.percent(100 - percent),
      });
    } else {
      // Force bars to be no higher than the maximum
      const percent = ((MAX_BAR_HEIGHT * this.data.length) / height) * 100;
      Object.entries(this.seriesMap).forEach(([_key, value]) => {
        value.columns.template.setAll({
          height: am5.percent(percent),
        });
      });

      this.goalSeries?.setAll({ stepWidth: am5.percent(percent - 1) }); // Subtract 1% from the goal series for visual alignment
      this.totalSeries?.setAll({ stepWidth: am5.percent(percent - 1) });
      this.missedSeries.columns.template.setAll({
        height: am5.percent(percent),
      });
    }

    if (USE_EXPERIMENTAL_PADDING) {
      // Account for the spacing above and below the chart
      const heightWithGap = height - BAR_GAP / 2;
      const percentAdjust = (heightWithGap / height) * 100;
      this.yAxis.setAll({
        height: am5.percent(percentAdjust),
      });
    }
  };

  createYAxis = () => {
    this.assertRoot('create y-axis');
    this.assertChart('create y-axis');

    const visibleItems =
      this.data.length > MAX_VISIBLE_ITEMS
        ? MAX_VISIBLE_ITEMS
        : this.data.length;

    this.yAxis = this.chart.yAxes.push(
      am5xy.CategoryAxis.new(this.root, {
        categoryField: 'employee_id',
        tooltip: this.createStyledTooltipWithHandler(),
        minZoomCount: visibleItems,
        maxZoomCount: visibleItems,
        renderer: am5xy.AxisRendererY.new(this.root, {
          minGridDistance: 1,
        }),
        start: 0,
        end:
          this.data.length > MAX_VISIBLE_ITEMS
            ? (this.data.length - MAX_VISIBLE_ITEMS) * 0.05 + 0.2
            : 0,
        maxDeviation: 0,
        minGridDistance: 1,
        paddingBottom: USE_EXPERIMENTAL_PADDING ? 0 : 7.5,
        zoomY: false,
      })
    );

    const renderer = this.yAxis.get('renderer');

    renderer.labels.template.adapters.add('text', (label, target, key) => {
      const name = target.dataItem?.dataContext?.employee_name ?? label ?? '';
      let displayName = name;

      if (name.length > LABEL_MAX_CHAR_COUNT) {
        displayName = `${name.slice(0, LABEL_MAX_CHAR_COUNT).trim()}...`;
      }
      return displayName;
    });

    renderer.labels.template.adapters.add('tooltipText', (_, target) => {
      const name = target.dataItem?.dataContext?.employee_name ?? '';
      if (name.length > LABEL_MAX_CHAR_COUNT) {
        return name;
      }

      return undefined;
    });

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
      dy: CHART_DELTA_Y,
    });

    renderer.grid.template.setAll({
      strokeOpacity: 0,
    });

    renderer.labels.template.setup = (target) => {
      target.setAll({
        dx: -15,
        background: am5.Rectangle.new(this.root, {
          fill: `${grayScale.white}00`,
        }),
        interactive: true,
        showTooltipOn: 'hover',
        tooltip: this.createStyledTooltipWithHandler(
          undefined,
          {
            pointerOrientation: 'up',
          },
          {
            maxWidth: 200,
            oversizedBehavior: 'wrap',
          }
        ),
        tooltipY: am5.percent(0),
      });
    };

    this.yAxis.onPrivate('height', this.handleYAxisHeight);

    this.yAxis.on('start', this.handleScrollbarPosition);

    this.yAxis.children.unshift(
      am5.Label.new(this.root, {
        rotation: -90,
        text: this.t('Team Member'),
        y: am5.p50,
        centerX: am5.p50,
        fill: grayScale.mediumDark,
      })
    );
  };

  handleXAxisWidth = (width) => {
    this.widthCb?.(width);
  };

  createXAxis = () => {
    this.assertRoot('create x-axis');
    this.assertChart('create x-axis');

    this.xAxis = this.chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        min: 0,
        renderer: am5xy.AxisRendererX.new(this.root, {
          strokeWidth: 1,
          strokeOpacity: 1,
          stroke: grayScale.mediumLight,
        }),
        zoomX: false,
      })
    );

    const renderer = this.xAxis.get('renderer');

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
      paddingTop: 14,
    });

    renderer.grid.template.setAll({
      strokeFill: grayScale.light,
    });

    this.xAxis.onPrivate('width', this.handleXAxisWidth);
  };

  hoverHandler(ev) {
    const { label, id } = this.getLabelHandleFromEvent(ev);
    const { line } = this.getLineHandleFromEvent(ev);

    // Events are debounced so that we don't get flickering of the total line
    // or tooltip when changing between segments in the same bar
    this.eventQueue.replace(() => {
      if (label) {
        // This is a bit of a hack, rather than try to do complicated math to position the tooltip,
        // just overwrite the display property to force it to be 20px from the top.
        label._display.y = 20;
        label.hover();
      }

      if (line) {
        line.animate({
          key: 'opacity',
          to: 1,
          easing: am5.ease.linear,
          duration: ANIMATION_DURATION,
        });
      }
    }, id);
  }

  unHoverHandler(ev) {
    const { label, id } = this.getLabelHandleFromEvent(ev);
    const { line } = this.getLineHandleFromEvent(ev);

    // Events are debounced so that we don't get flickering of the total line
    // or tooltip when changing between segments in the same bar
    this.eventQueue.replace(() => {
      if (label) {
        label.unhover();
      }

      if (line) {
        line.animate({
          key: 'opacity',
          to: 0,
          easing: am5.ease.linear,
          duration: ANIMATION_DURATION,
        });
      }
    }, id);
  }

  attachHoverHandlersToSeries(series) {
    series.columns.template.events.on(
      'pointerover',
      this.hoverHandler.bind(this)
    );

    series.columns.template.events.on(
      'pointerout',
      this.unHoverHandler.bind(this)
    );
  }

  attachHoverHandlersToLabel(label) {
    label.events.on('pointerover', this.hoverHandler.bind(this));
    label.events.on('pointerout', this.unHoverHandler.bind(this));
  }

  makeSeries = (name, field, index, totalCount) => {
    this.assertChart('make series');
    this.assertRoot('make series');
    this.assertSeries('make series');

    const isCompleted = field === 'completed';
    const style = getStyle(isCompleted, index, totalCount);

    const series = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name,
        stacked: true,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        baseAxis: this.yAxis,
        valueXField: field,
        categoryYField: 'employee_id',
        ...style,
      })
    );

    series.columns.template.setAll({
      strokeWidth: 0,
      height: am5.percent(
        this.data.length > MAX_VISIBLE_ITEMS
          ? ROW_HEIGHT_PERCENT.SCROLLED
          : ROW_HEIGHT_PERCENT.BASE
      ),
      dy: CHART_DELTA_Y,
      background: am5.Rectangle.new(this.root, {
        fill: `${grayScale.white}`,
      }),
    });

    series.data.setAll(this.formattedData);

    series.appear();

    series.bullets.push(() => {
      const label = am5.Label.new(this.root, {
        text: this.valueXAccessor,
        fill: grayScale.white,
        centerY: am5.p50,
        centerX: am5.p50,
        populateText: true,
        dy: CHART_DELTA_Y,
      });

      // Override the formatter because amcharts isn't smart enough to format currency
      // the way we want
      label.adapters.add('text', (_, item) => {
        const text = item?.dataItem?.dataContext?.[field] ?? 0;
        return this.isValue
          ? `${this.currencySymbol}${ensureCents(collapseNumber(text))}`
          : collapseNumber(text);
      });

      return am5.Bullet.new(this.root, {
        sprite: label,
      });
    });

    const tooltip = this.createStyledTooltipWithHandler(undefined, {
      pointerOrientation: 'up',
    });

    series.columns.template.setAll({
      tooltip: tooltip,
      tooltipText: `${name}: ${this.valueXAccessor}`,
      tooltipY: am5.percent(0),
    });

    // Override the formatter because amcharts isn't smart enough to format currency
    // the way we want
    series.columns.template.adapters.add('tooltipText', (_, item) => {
      const text = item?.dataItem?.dataContext?.[field] ?? 0;
      return `${name}: ${
        this.isValue
          ? `${this.currencySymbol}${ensureCents(collapseNumber(text))}`
          : collapseNumber(text)
      }`;
    });

    series.columns.template.onPrivate('width', (width, target) => {
      if (target.dataItem.bullets) {
        am5.array.each(target.dataItem.bullets, (bullet) => {
          if (
            width < MIN_BAR_LABEL_WIDTH ||
            target.dataItem.dataContext[field] === 0
          ) {
            bullet.get('sprite').hide();
          } else {
            bullet.get('sprite').show();
          }
        });
      }
    });

    this.attachHoverHandlersToSeries(series);

    this.seriesMap[field] = series;
  };

  getLabelHandleFromEvent = (ev) => {
    const employeeId = ev?.target?.dataItem?.dataContext?.employee_id;
    if (employeeId) {
      const label = this.totalCircleHandles.find((h) => {
        return h?.dataItem?.dataContext?.employee_id === employeeId;
      });

      return { label, id: employeeId };
    }

    return {};
  };

  getLineHandleFromEvent = (ev) => {
    const employeeId = ev?.target?.dataItem?.dataContext?.employee_id;

    if (employeeId) {
      const line = this.totalLineHandles.find((h) => {
        return h?.dataItem?.dataContext?.employee_id === employeeId;
      });

      return { line, id: employeeId };
    }

    return {};
  };

  makeTotalSeries = () => {
    this.assertChart('make total series');
    this.assertRoot('make total series');

    this.totalSeries = this.chart.series.push(
      am5xy.StepLineSeries.new(this.root, {
        name: this.t('Total'),
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueXField: '__total',
        categoryYField: 'employee_id',
        noRisers: true,
        dy: CHART_DELTA_Y,
      })
    );

    this.totalSeries.strokes.template.setAll({
      forceHidden: true,
    });

    // These bullets are what handle the rendering of the tooltip when mousing
    // over the goal line. The bullet is an empty container to act as the hit area
    this.totalSeries.bullets.push(() => {
      const label = am5.Circle.new(this.root, {
        radius: 10,
        centerX: am5.p50,
        centerY: am5.p50,
        opacity: 0,
        fillOpacity: 0,
        tooltipText: `${this.t('Total')}: ${this.valueXAccessor}}`,
        interactive: true,
        // Don't attach a handler to this tooltip or it may conflict with the primary
        // tooltip's handler on the data series itself
        tooltip: this.createStyledTooltip(
          undefined,
          {
            pointerOrientation: 'down',
            stateAnimationDuration: ANIMATION_DURATION,
            interactive: true,
            stateAnimationEasing: am5.ease.linear,
          },
          undefined,
          {
            fill: colorsPrimary.blue.dark,
            stroke: colorsPrimary.blue.dark,
          }
        ),
      });

      this.totalCircleHandles.push(label);

      // Override the formatter because amcharts isn't smart enough to format currency
      // the way we want
      label.adapters.add('tooltipText', (_, item) => {
        const text = item?.dataItem?.dataContext?.__total ?? 0;
        return `${this.t('Total')}: ${
          this.isValue
            ? `${this.currencySymbol}${ensureCents(collapseNumber(text))}`
            : collapseNumber(text)
        }`;
      });

      const bullet = am5.Bullet.new(this.root, {
        sprite: label,
        locationY: 1000,
      });

      return bullet;
    });

    this.totalSeries.bullets.push(() => {
      const label = am5.Line.new(this.root, {
        strokeWidth: 1,
        centerX: am5.p50,
        centerY: am5.p50,
        stroke: grayScale.dark,
        interactive: true,
        opacity: 0,
        draw: (display) => {
          display.moveTo(0, 0);
          // chart will auto-clip at the top and bottom, so just make the line long enough to
          // ensure it's always taller than the chart
          display.lineTo(0, 1000);
        },
        strokeDasharray: STROKE_DASHARRAY,
      });

      this.totalLineHandles.push(label);

      const bullet = am5.Bullet.new(this.root, {
        sprite: label,
      });
      return bullet;
    });

    // Force the goal label handles to be the height of the series
    // by listening to height change on the missed series
    this.missedSeries.columns.template.onPrivate('height', (height) =>
      this.totalCircleHandles.forEach((goal) => {
        goal.setAll({ height });
      })
    );

    this.totalSeries.data.setAll(this.formattedData);

    this.totalSeries.appear();
  };

  // We use a goal series to show the dotted goal line. A step line series lends itself well to
  // this task, without needing to do any hacky stuff with bullets or labels. This special series
  // is created separately from the bar series data
  makeGoalSeries = () => {
    this.assertChart('make goal series');
    this.assertRoot('make goal series');

    this.goalSeries = this.chart.series.push(
      am5xy.StepLineSeries.new(this.root, {
        name: this.t('Goal'),
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueXField: '__goal',
        categoryYField: 'employee_id',
        noRisers: true,
        stroke: grayScale.dark,
        stepWidth: am5.percent(
          this.data.length > MAX_VISIBLE_ITEMS
            ? ROW_HEIGHT_PERCENT.SCROLLED
            : ROW_HEIGHT_PERCENT.BASE
        ),
        dy: CHART_DELTA_Y,
      })
    );

    this.goalSeries.strokes.template.set(
      'strokeDasharray',
      GOAL_STROKE_DASHARRAY
    );

    // These bullets are what handle the rendering of the tooltip when mousing
    // over the goal line. The bullet is an empty container to act as the hit area
    this.goalSeries.bullets.push(() => {
      const label = am5.Label.new(this.root, {
        text: '',
        centerX: am5.p50,
        centerY: am5.p50,
        tooltipText: `${this.t('Goal')}: ${this.valueXAccessor}`,
        interactive: true,
        showTooltipOn: 'hover',
        tooltip: this.createStyledTooltipWithHandler(undefined, {
          pointerOrientation: 'up',
        }),
        // Labels need a background in order to be interactive,
        // so add a transparent one
        background: am5.Rectangle.new(this.root, {
          fill: `${grayScale.black}00`,
        }),
      });

      this.attachHoverHandlersToLabel(label);
      this.goalLabelHandles.push(label);

      // Override the formatter because amcharts isn't smart enough to format currency
      // the way we want
      label.adapters.add('tooltipText', (_, item) => {
        const text = item?.dataItem?.dataContext?.__goal ?? 0;
        return `${this.t('Goal')}: ${
          this.isValue
            ? `${this.currencySymbol}${ensureCents(collapseNumber(text))}`
            : collapseNumber(text)
        }`;
      });

      const bullet = am5.Bullet.new(this.root, {
        sprite: label,
        interactive: true,
      });

      return bullet;
    });

    // Force the goal label handles to be the height of the series
    // by listening to height change on the missed series
    this.missedSeries.columns.template.onPrivate('height', (height) =>
      this.goalLabelHandles.forEach((goal) => {
        goal.setAll({ height });
      })
    );

    this.goalSeries.data.setAll(this.formattedData);

    this.goalSeries.appear();
  };

  makeMissedSeries = () => {
    this.assertChart('make missed series');
    this.assertRoot('make missed series');

    this.missedSeries = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: this.t('Below Quota'),
        stacked: true,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        baseAxis: this.yAxis,
        valueXField: '__missed',
        categoryYField: 'employee_id',
        fill: colorsSecondary.red.light,
        stroke: grayScale.white,
      })
    );
    this.missedSeries.columns.template.setAll({
      strokeWidth: 0,
    });

    this.missedSeries.data.setAll(this.formattedData);

    this.missedSeries.appear();

    this.missedSeries.bullets.push(() => {
      const label = am5.Label.new(this.root, {
        text: this.valueXAccessor,
        fill: grayScale.white,
        centerY: am5.p50,
        centerX: am5.p50,
        populateText: true,
        dy: CHART_DELTA_Y,
      });

      // Override the formatter because amcharts isn't smart enough to format currency
      // the way we want
      label.adapters.add('text', (_, item) => {
        const text = item?.dataItem?.dataContext?.__missed ?? 0;
        return this.isValue
          ? `${this.currencySymbol}${ensureCents(collapseNumber(text))}`
          : collapseNumber(text);
      });

      return am5.Bullet.new(this.root, {
        sprite: label,
      });
    });

    // Override the formatter because amcharts isn't smart enough to format currency
    // the way we want
    this.missedSeries.columns.template.adapters.add(
      'tooltipText',
      (_, item) => {
        const text = item?.dataItem?.dataContext?.__missed ?? 0;
        return `${this.t('Below Quota')}: ${
          this.isValue
            ? `${this.currencySymbol}${ensureCents(collapseNumber(text))}`
            : collapseNumber(text)
        }`;
      }
    );

    this.missedSeries.columns.template.setAll({
      tooltip: this.createStyledTooltipWithHandler(undefined, {
        pointerOrientation: 'up',
      }),
      tooltipText: `${this.t('Below Quota')}: ${this.valueXAccessor}`,
      tooltipY: am5.percent(0),
      height: am5.percent(
        this.data.length > MAX_VISIBLE_ITEMS
          ? ROW_HEIGHT_PERCENT.SCROLLED
          : ROW_HEIGHT_PERCENT.BASE
      ),
      dy: CHART_DELTA_Y,
    });

    // When the bar width changes, determine if it's wide enough to show the label or not
    // and hide the label if the bar is too thin.
    // We can pretty safely rely on a fixed width because the number formatter will truncate
    // large numbers so they can't grow indefinitely.
    this.missedSeries.columns.template.onPrivate('width', (width, target) => {
      if (target.dataItem.bullets) {
        am5.array.each(target.dataItem.bullets, (bullet) => {
          if (width < MIN_BAR_LABEL_WIDTH) {
            bullet.get('sprite').hide();
          } else {
            bullet.get('sprite').show();
          }
        });
      }
    });

    this.attachHoverHandlersToSeries(this.missedSeries);
  };

  createData = () => {
    this.assertData('create data');
    this.assertYAxis('create data');

    const names = [this.t('Won')];
    const fields = ['completed'];
    let max = 0;

    this.formattedData = this.data.map((person, index) => {
      const completed =
        person.values.find((v) => v.type === 'completed')?.value ?? 0;
      const projections =
        person.values.find((v) => v.type === 'open_stage_projections')
          ?.values ?? [];
      const goal = person.values.find((v) => v.type === 'goal')?.value ?? 0;
      const result = {
        employee_id: person.employee_id,
        completed: completed === 0 ? null : completed,
        employee_name: person.fullName,
        __goal: goal === 0 ? null : goal,
        __total: completed,
      };

      if (goal > max) {
        max = goal;
      }

      projections.forEach((projection) => {
        result[projection.stage_id] =
          projection.value === 0 ? null : projection.value;
        result.__total += projection.value;
        if (!fields.includes(projection.stage_id)) {
          fields.push(projection.stage_id);
          names.push(projection.stage_name);
        }
      });

      result.__missed = getMissedValue(goal, result.__total);

      return result;
    });

    this.yAxis.data.setAll(this.formattedData);

    fields.forEach((field, index) => {
      this.makeSeries(names[index], field, index - 1, fields.length);
    });

    const goalCount = this.formattedData.reduce((acc, curr) => {
      if (curr.__goal) {
        return acc + 1;
      }
      return acc;
    }, 0);

    this.element.setAttribute('data-qa-goal-count', goalCount);
    this.element.setAttribute(
      'data-qa-member-count',
      this.formattedData.length
    );

    this.makeMissedSeries();
    if (this.fixedScale) {
      this.xAxis.set('max', max);
    }
  };

  assertSeries = (entity = 'entity', key) => {
    if (!this.seriesMap) {
      throw new Error(`Cannot create ${entity} without a valid series map`);
    }

    if (key) {
      if (!this.seriesMap[key]) {
        throw new Error(
          `Cannot create ${entity} without a valid series for ${key}`
        );
      }
    }
  };

  assertData = (entity = 'entity') => {
    if (!this.data) {
      throw new Error(`Cannot create ${entity} without valid data`);
    }
  };
}

export default LeaderboardChartManager;
