import { getLinkDataForTag, specialTagTypes } from 'components/Fields/helpers';
import { getOriginalError } from 'services/AxiosService';
import { isContact } from 'utility/fieldHelpers';
import ActivityService from 'services/ActivityService';
import FieldService from 'services/FieldService';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';
import { isPipelineObject } from 'components/Modals/utilities';

export const decideErrorMessage = (error, t) => {
  const orig = getOriginalError(error);
  if (!orig) throw error;
  const firstMessage = Object.keys(orig)?.[0];
  switch (firstMessage) {
    case 'code':
      return orig.code === 'duplicate_tag'
        ? t('Tag already exists.')
        : orig.message || true;
    case undefined:
      return true;
    default:
      return orig[firstMessage][0];
  }
};

export const getTagLink = (model, field, tag) => {
  return getLinkDataForTag({
    fetchUrl: isContact(model) ? 'clients' : `custom-objects/${model.id}`,
    fieldId: field.id,
    tagType: specialTagTypes[field.name] || 'dynamictag',
    field,
  }).link(tag);
};

export const getServiceToUse = (model, activityObjectId) => {
  if (activityObjectId) {
    return ActivityService;
  }
  if (isContact(model)) {
    return FieldService;
  }
  if (isPipelineObject(model)) {
    return PipelineService;
  }
  return CustomObjectsService;
};

export const checkDuplicateName = (newTag) => (tag) =>
  tag.name.toLowerCase() === newTag.name.toLowerCase();

export const simulateAxiosError = (message) => {
  return Promise.reject({
    response: {
      data: {
        message: [message],
      },
    },
  });
};
