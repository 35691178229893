import { snakeToCamelCaseKeys, camelToSnakeCaseKeys } from 'services/helpers';
import AxiosService from './AxiosService';

class BroadcastService {
  constructor() {
    if (!BroadcastService.instance) {
      BroadcastService.instance = this;
    }
    return BroadcastService.instance;
  }

  endpointUrl = 'scheduled-events';

  getByDate = async ({ dateFrom, dateTo, validateFilters }) => {
    const { data } = await AxiosService.get(`${this.endpointUrl}`, {
      params: {
        from_date: dateFrom,
        to_date: dateTo,
        validate_filters: validateFilters,
      },
    });
    return snakeToCamelCaseKeys(data);
  };

  getPending = async (params) => {
    const { data } = await AxiosService.get(`${this.endpointUrl}/future`, {
      params,
    });
    return snakeToCamelCaseKeys(data);
  };

  getProcessed = async (params) => {
    const { data } = await AxiosService.get(`${this.endpointUrl}/past`, {
      params,
    });
    return snakeToCamelCaseKeys(data);
  };

  addBroadcast = async (payload, options) => {
    return await AxiosService.post(
      this.endpointUrl,
      camelToSnakeCaseKeys(payload),
      options
    );
  };

  updateBroadcast = async (id, payload, options) => {
    return await AxiosService.put(
      this.endpointUrl + '/' + id,
      camelToSnakeCaseKeys(payload),
      options
    );
  };

  deleteBroadcast = async (id, options) => {
    return await AxiosService.delete(this.endpointUrl + '/' + id, options);
  };

  getAutomationBroadcastStats = async (id, options) => {
    const response = await AxiosService.get(
      `${this.endpointUrl}/${id}/stats`,
      options
    );
    return snakeToCamelCaseKeys(response.data.automations);
  };

  getBroadcastSummary = async (body, options) => {
    const response = await AxiosService.post(
      `${this.endpointUrl}/records-filter-summary`,
      body,
      options
    );

    return response.data;
  };
}

const instance = new BroadcastService();
Object.freeze(instance);

export default instance;
