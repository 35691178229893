import { useTranslation } from 'react-i18next';
import { gutters } from 'app/spacing';
import Cols from 'components/Layout/Cols';
import Switch from 'components/Kizen/Switch';
import Section from 'components/Wizards/CustomObject/components/Section';
import SwitchControl from 'components/Wizards/CustomObject/components/SwitchControl';
import { getDataQAForInput } from 'components/Inputs/helpers';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import React, { useCallback, useState } from 'react';
import { AIModal } from 'components/Wizards/CustomObject/components/AIModal';
import useModal from 'components/Modals/useModal';
import KizenTypography from 'app/kizentypo';
import styled from '@emotion/styled';

const AiControl = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin: 0;
  }
  & > a {
    margin-top: 1px;
    padding-left: 20px;
  }
`;
export default function WorkflowSettingsSubsection({
  values,
  onChange,
  model = null,
  updateStepField,
  ...props
}) {
  const [editing, setEditing] = useState(false);
  const [
    showIncludePercentageToCloseModel,
    setShowIncludePercentageToCloseModal,
  ] = useState(false);

  const [aiModalProps, , aiModal] = useModal({
    handleHide: () => {
      setEditing(false);
    },
    handleSubmit: (e) => {
      updateStepField('useAiToUpdatePercentage', true);
    },
  });

  const onCancelHandle = useCallback(() => {
    !editing && updateStepField('useAiToUpdatePercentage', false);
    setEditing(false);
    aiModal.hide();
  }, [editing, updateStepField, aiModal]);

  const { t } = useTranslation();
  return (
    <Section title={t('General Customization')} {...props}>
      <Cols columns={4} gutter={`${gutters.spacing(4)}px`}>
        <SwitchControl label={t('Include % Chance to Close')}>
          <Switch
            checked={values.includePercentageToClose}
            onChange={(e) => {
              if (!e.target.checked) {
                setShowIncludePercentageToCloseModal(true);
              } else {
                return updateStepField('includePercentageToClose', true);
              }
            }}
            {...getDataQAForInput('include_percentage_to_close')}
          />
        </SwitchControl>
        <ConfirmationModal
          heading={t('Please Confirm Modification')}
          show={showIncludePercentageToCloseModel}
          onHide={() => {
            setShowIncludePercentageToCloseModal(false);
          }}
          onConfirm={() => {
            setShowIncludePercentageToCloseModal(false);
            updateStepField('includePercentageToClose', false);
          }}
          buttonText={t('Confirm')}
          defaultLeftBtnText={t('Cancel')}
        >
          {t(
            'This will disable % chance to close on your records, but keep your data if you choose to reactivate. Pipeline dashlets with forecasting and estimated value will no longer function.'
          )}
        </ConfirmationModal>
        <SwitchControl label={t('Use AI to Update Stage %s')}>
          <AiControl>
            <Switch
              checked={values.useAiToUpdatePercentage}
              onChange={(e) => {
                if (e.target.checked) {
                  aiModal.show();
                } else {
                  updateStepField('useAiToUpdatePercentage', e.target.checked);
                }
              }}
              {...getDataQAForInput('use_ai_to_update_percentage')}
            />
            {values.useAiToUpdatePercentage ? (
              <KizenTypography
                textTransform={'uppercase'}
                type={'anchor'}
                href={'#'}
                weight="bold"
                onClick={(e) => {
                  e.preventDefault();
                  aiModal.show();
                  setEditing(true);
                }}
              >
                {t('Edit Controls')}
              </KizenTypography>
            ) : null}
          </AiControl>
        </SwitchControl>
        <AIModal
          editing={editing}
          {...aiModalProps}
          values={values}
          updateStepField={updateStepField}
          onCancelHandle={onCancelHandle}
        />
      </Cols>
    </Section>
  );
}
