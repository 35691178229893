import { StaticBlockRoot as Root } from '../nodes/Root';
import { SectionContainer as Section } from '../nodes/containers';
import { Cell } from '../nodes/Cell';
import { HomepageRow as Row } from '../nodes/Row';
import { Content } from '../nodes/Content';
import { HomepageText as Text } from '../nodes/Text';
import { HomepageButton as Button } from '../nodes/Button';
import { ViewImage as Image } from '../nodes/Image';
import { ViewHTMLBlock as HTMLBlock } from '../nodes/HTMLBlock';
import { ViewDivider as Divider } from '../nodes/Divider';

export default {
  Root,
  Section,
  Cell,
  Row,
  Content,
  Text,
  Button,
  Image,
  HTMLBlock,
  Divider,
};
