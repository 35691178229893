import useClearPageConfig from 'hooks/clearPageConfig';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { maybeTrackGAPageView, trackKizenPageView } from 'utility/analytics';

const PageViewWrapper = ({ children, pageLoadTs, pageLoadId }) => {
  const location = useLocation();
  const { pathname } = location;
  const authentication = useSelector((s) => s.authentication);

  const userId = authentication?.teamMember?.id;
  const businessId = authentication?.chosenBusiness?.id;

  const component = useClearPageConfig();

  useEffect(() => {
    maybeTrackGAPageView(document?.title, window.location.href, pathname);
    if (userId && businessId) {
      trackKizenPageView(pathname, pageLoadTs, pageLoadId);
    }
  }, [pathname, pageLoadId, pageLoadTs, userId, businessId]);

  return (
    <>
      {children}
      {component}
    </>
  );
};

export default PageViewWrapper;
