import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ReactSelect, { components } from 'react-select';
import { TextSpan, fontSizes } from 'app/typography';
import { colorsText, dropdownColors } from 'app/colors';
import { displayBreakpoints, isMobile, useWindowSize } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import { StyledLabel, StyledLabelWrapper, reactSelectStyles } from './styles';
import { colorsButton } from '../../../app/colors';
import { useTranslation } from 'react-i18next';
import { StyledCustomOption } from '../Select/styles';

export const optionShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string.isRequired,
});

// The way the input and selected value are overlaid,
// we have a flex layout that sizes the input to the selected value.
// The input is absolutely positioned so that they can overlap.
// When the user starts typing react-select removes the element
// selected value element, which means the flex box loses its sizing.
// In order to counteract this, once the user starts typing, we measure
// the size of the box and switch the input to a relatively positioned
// element of a fixed size.

const FrozenWidthInput = function FrozenWidthInput({
  value,
  onChange,
  ...others
}) {
  const [width, setWidth] = useState(null);
  const handleChange = useCallback(
    (ev) => {
      if (!value && ev.target.value) {
        // We use getBoundingClientRect() rather than offsetWidth
        // because if we don't capture fractional sizing, there may be
        // jitter when the user starts typing.
        const rect = ev.target.parentElement.getBoundingClientRect();
        setWidth(rect.width);
      }
      onChange(ev);
    },
    [onChange, value]
  );
  return (
    <components.Input
      style={value ? { width, position: 'relative', top: 0 } : null}
      value={value}
      onChange={handleChange}
      {...others}
    />
  );
};

FrozenWidthInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DropdownIndicatorIcon = styled(Icon)`
  && {
    width: 12px;

    svg {
      width: 12px;
      height: auto;
    }
  }
`;

const getValue = (value, options) => {
  // if the value is a string, find that in the options and return it
  if (typeof value === 'string') {
    return options.find((option) => option.value === value);
  }

  // if the value is an object, key off of the id
  if (value && 'id' in value) {
    return options.find((option) => option.value.id === value.id);
  }

  return value;
};

const MainSelector = ({
  className,
  label,
  textSize,
  size,
  style,
  styles,
  disabled,
  error,
  options,
  value,
  components: selectComponents,
  kdsCompatability,
  ...props
}) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const NoOptionsMessage = ({ children, ...propsNoOption }) => {
    return (
      <components.NoOptionsMessage {...propsNoOption}>
        <TextSpan size={textSize} style={{ color: colorsText.medium }}>
          {t('No Options')}
        </TextSpan>
      </components.NoOptionsMessage>
    );
  };

  const Placeholder = ({ children, ...propsPlaceholder }) => {
    return (
      <components.Placeholder {...propsPlaceholder}>
        <TextSpan size={textSize} color={colorsText.medium}>
          {children}
        </TextSpan>
      </components.Placeholder>
    );
  };

  const SingleValue = ({ children, ...propsSingleValue }) => {
    return (
      <components.SingleValue {...propsSingleValue}>
        <TextSpan
          size={textSize}
          style={{
            textIndent: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            margin: 0,
            fontSize: fontSizes.subheader,
            fontWeight: '400',
            marginLeft: 0,
          }}
        >
          {children}
        </TextSpan>
      </components.SingleValue>
    );
  };

  const DropdownIndicator = () => (
    <DropdownIndicatorIcon icon="down" color={colorsButton.blue.default} />
  );

  if (label) {
    return (
      <div className={className}>
        <StyledLabelWrapper>
          <StyledLabel disabled={disabled}>{label}</StyledLabel>
          <ReactSelect
            styles={{ ...reactSelectStyles, ...styles }}
            components={{
              SingleValue,
              Placeholder,
              NoOptionsMessage,
              DropdownIndicator,
              Input: FrozenWidthInput,
              Option: StyledCustomOption,
              ...selectComponents,
            }}
            options={options}
            // react-select anticipates a value of the shape { value, label },
            // i.e. one of the values in options.
            value={getValue(value, options)}
            mobile={isMobile(width, displayBreakpoints.desktop)}
            {...props}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: dropdownColors.hover,
              },
              controlHeight: 36,
            })}
            isDisabled={disabled}
            error={error}
          />
        </StyledLabelWrapper>
      </div>
    );
  }

  return (
    <div className={className}>
      <ReactSelect
        styles={{ ...reactSelectStyles, ...styles }}
        components={{
          SingleValue,
          Placeholder,
          NoOptionsMessage,
          DropdownIndicator,
          Input: FrozenWidthInput,
          Option: StyledCustomOption,
          ...selectComponents,
        }}
        options={options}
        // react-select anticipates a value of the shape { value, label },
        // i.e. one of the values in options.
        value={getValue(value, options)}
        mobile={isMobile(width, displayBreakpoints.desktop)}
        {...props}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: dropdownColors.hover,
          },
        })}
        isDisabled={disabled}
        error={error}
        menuPortalTarget={kdsCompatability ? document.body : undefined}
      />
    </div>
  );
};

MainSelector.propTypes = {
  options: PropTypes.arrayOf(optionShape).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, optionShape]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
  className: PropTypes.string,
  textSize: PropTypes.oneOf(Object.keys(fontSizes)),
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  size: PropTypes.oneOf(['large', 'small']),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  components: PropTypes.object,
};

MainSelector.defaultProps = {
  value: null,
  label: '',
  className: '',
  textSize: 'text',
  placeholder: '',
  styles: {},
  size: 'small',
  disabled: false,
  error: false,
  components: {},
};

export default MainSelector;
