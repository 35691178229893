import { useMemo } from 'react';
import { getKDSClasses, merge } from '../util';
import { ActionBarContext } from './context';

export interface ActionBarProps {
  leftChildren?: any;
  rightChildren?: any;
}

export const getActionBarId = (id: string) => `action-bar-${id}`;

export const ActionBar = (props: ActionBarProps = {}) => {
  const { leftChildren, rightChildren } = props;

  const id = useMemo(() => {
    return Math.random().toString(36).slice(2, 9);
  }, []);

  return (
    <ActionBarContext.Provider value={{ id }}>
      <div
        className={merge(
          getKDSClasses('action-bar'),
          'relative z-menu w-full @container/actionBar'
        )}
      >
        <div className={merge('flex gap-base items-center relative')}>
          {leftChildren ? (
            <div className="shrink whitespace-nowrap min-w-0">
              {leftChildren}
            </div>
          ) : null}
          <div className="shrink-0 ml-auto flex justify-end">
            {rightChildren}
          </div>
        </div>
        <div id={getActionBarId(id)} />
      </div>
    </ActionBarContext.Provider>
  );
};
