import { forwardRef } from 'react';

import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import { withCellWrapper } from './helpers';

const _DateTimeCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    return (
      <DateTimeInputInline
        ref={ref}
        {...others}
        field={field}
        object={object}
        value={value}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        onSubmit={(val) => {
          onSubmit(val?.toISOString());
        }}
        dateMenuProps={{
          menuLeftButton: field.isRequired ? null : menuLeftButton,
          menuRightButton: <ApplySelectOverlayButton />,
        }}
        timeMenuProps={{
          sizing: 'shrink',
        }}
        showDateTooltip={field?.isDefault}
      />
    );
  }
);

export const DateTimeCell = withCellWrapper(_DateTimeCell);
