import Icon from 'components/Kizen/Icon';
import { ICON_BUTTON_DELAY, useTooltip } from 'components/Kizen/Tooltip';
import { Fragment, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChildrenWrapper,
  DropPlaceholder,
  StyledColorPicker,
  StyledDragItem,
  StyledIcon,
  WidthContainer,
  DropdownWrapper,
  SwitchWrapper,
  StyledSelect,
  StyledSwitch,
} from './styles';
import {
  getRowCount,
  getHalfRowItems,
  MAX_ROWS_PER_PARENT,
} from 'pages/Account/pages/Toolbar/childDropValidator';
import { throttle } from 'lodash';
import {
  CARD_OFFSET,
  THROTTLE_INTERVAL,
} from 'components/MultiColumnBuilder/constants';
import KizenTypography from 'app/kizentypo';
import { dropzoneIs } from 'components/DragAndDropLayout/helpers';
import {
  HorizontalDropzone,
  VerticalDropzone,
} from 'components/DragAndDropLayout/Dropzone';
import { DragItemWrapper, OptionPlaceholder } from '../OptionParent/styles';
import Draggable from 'react-draggable';
import IconSelector from '../IconSelector';
import SelectInline from 'components/Inputs/inline/Select';
import { flushSync } from 'react-dom';

export const modalConstrainPopperConfig = {
  modifiers: {
    preventOverflow: {
      enabled: false,
    },
    autoSizing: {
      enabled: true,
      fn(data) {
        // Ensure the message stays within modal by capping it at the input width
        data.styles.maxWidth = data.offsets.reference.width;
        return data;
      },
    },
  },
};

const BaseOption = ({
  element,
  iconVisible,
  iconEditable,
  colorEditable,
  index,
  columnWidth,
  child,
  parent,
  editValue,
  childIndex,
  overrideIcon,
  canDelete,
  showDropdown,
  dropdownOptions,
  onDropdownChange,
  dropdownValue,
  defaultDropdownValue,
  deletedField,
  moveItemToBottom,
  canChangeSize,
  siblings = [],
  additionalActions,
  showPlaceholderChildren,
  dropFirstChild,
  setDropFirstChild,
  collapsedItems = [],
  draggingItem,
  getChildDropZone,
  childDropZone,
  editingItem,
  setDraggingItem,
  childDragFromColumn,
  endDrag,
  setEditingItem,
  editable,
  removeItem,
  swapItemColumn,
  editOptions,
  section,
  children,
  collapseItem,
  expandItem,
  subRow,
  displaySwitch,
  switchValue,
  onSwitchChange,
  switchLabel,
  switchDefaultValue,
  ...others
}) => {
  const itemRef = useRef(null);
  const intitialRenderRef = useRef(true);
  const { t } = useTranslation();

  if (intitialRenderRef.current) {
    intitialRenderRef.current = false;
    if (showDropdown && !dropdownValue) {
      onDropdownChange(defaultDropdownValue);
    }
    if (displaySwitch && switchValue === undefined) {
      onSwitchChange({ target: { checked: true } });
    }
  }

  const changeColor = useCallback(
    (value) => {
      editValue(index, childIndex, 'color', value);
    },
    [index, editValue, childIndex]
  );

  const changeWidth = useCallback(
    (width) => {
      editValue(index, childIndex, 'widthPercent', width);
    },
    [childIndex, index, editValue]
  );

  const changeIcon = useCallback(
    (icon) => {
      if (section === 'find-options') {
        editValue(-1, -1, 'icon', icon, element.id);
      } else {
        editValue(index, childIndex, 'icon', icon);
      }
    },
    [editValue, index, childIndex, section, element.id]
  );

  const [deleteIconTooltipProps, deleteIconTooltip] = useTooltip({
    label: t('Remove Item'),
    delay: ICON_BUTTON_DELAY,
  });

  const canExpand = (() => {
    if (!child) {
      return false;
    }

    if (parent.isLocked) {
      return false;
    }

    const rowCount = getRowCount(siblings);
    if (rowCount < MAX_ROWS_PER_PARENT) {
      return true;
    }

    const halfRowItems = getHalfRowItems(siblings);
    const expandable = halfRowItems.map((h) => h.after?.id);

    return expandable.includes(element.id);
  })();

  const [iconTooltipProps, iconTooltip] = useTooltip({
    label:
      canExpand && element.widthPercent === 50
        ? t('Grow Item')
        : t('Shrink Item'),
    delay: ICON_BUTTON_DELAY,
  });

  const maybeEnableFirstDrop = useCallback(
    (value) => {
      if (showPlaceholderChildren) {
        setDropFirstChild?.(value);
      }
    },
    [showPlaceholderChildren, setDropFirstChild]
  );

  const throttleMaybeEnableFirstDrop = useMemo(() => {
    return throttle((value) => {
      maybeEnableFirstDrop(value);
    }, THROTTLE_INTERVAL);
  }, [maybeEnableFirstDrop]);

  const maybeDisableFirstDrop = useCallback(() => {
    setDropFirstChild?.(null);
  }, [setDropFirstChild]);

  const throttleMaybeDisableFirstDrop = useMemo(() => {
    return throttle(() => {
      maybeDisableFirstDrop();
    }, THROTTLE_INTERVAL);
  }, [maybeDisableFirstDrop]);

  const isCollapsed = useMemo(() => {
    return (
      !child &&
      (collapsedItems.includes(element.id) || draggingItem?.id === element.id)
    );
  }, [child, collapsedItems, element, draggingItem]);

  const sendToBack = useCallback(() => {
    itemRef.current?.sendToBack?.();
  }, []);

  const bringToFront = useCallback(() => {
    itemRef.current?.bringToTop?.();
  }, []);

  const handleStartEditing = useCallback(() => {
    setEditingItem(element);
    bringToFront();
  }, [setEditingItem, element, bringToFront]);

  const handleFinishEditing = useCallback(() => {
    setEditingItem(null);
    sendToBack();
  }, [setEditingItem, sendToBack]);

  const shouldShowPlaceholderChildren = showPlaceholderChildren?.({
    element,
    editable,
    section,
    index,
    parent,
    child,
    draggingItem,
  });

  const shouldShowEmptyPlaceholderChildren =
    !shouldShowPlaceholderChildren &&
    draggingItem?.id !== element.id &&
    element.type === 'category' &&
    element.wasDynamic &&
    !element.children?.length &&
    !element.dynamicallyPopulated;

  return (
    <div>
      <StyledDragItem
        {...others}
        isDropzone
        ref={(r) => {
          itemRef.current = r;
          r?.sendToBack();
        }}
        className="innerItem"
        darken={element.isLocked || element.isLockedToColumn}
        disabled={element.isLocked}
        subRow={subRow}
        redBorder={deletedField}
      >
        {iconVisible?.({ element, editable, section, index, parent, child }) ? (
          <>
            {iconEditable?.({
              element,
              editable,
              section,
              index,
              parent,
              child,
            }) ? (
              <IconSelector
                value={element.icon ?? 'bars-light'}
                onOpenMenu={handleStartEditing}
                onCloseMenu={handleFinishEditing}
                onChange={changeIcon}
                index={index}
                safeWidth={columnWidth}
                child={child}
                isParent={!parent}
              />
            ) : (
              <StyledIcon className="ElementIcon" icon={element.icon} />
            )}
          </>
        ) : null}
        {overrideIcon?.({ element, editable, section, index, parent, child })}
        {colorEditable?.({
          element,
          editable,
          section,
          index,
          parent,
          child,
        }) ? (
          <StyledColorPicker
            color={element.color}
            onChange={changeColor}
            onOpen={handleStartEditing}
          />
        ) : null}
        {children?.({
          element,
          editable,
          section,
          index,
          childIndex,
          editValue,
          handleStartEditing,
          handleFinishEditing,
          removeItem,
          editOptions,
        })}
        <WidthContainer>
          {showDropdown ? (
            <DropdownWrapper data-qa={dropdownValue ?? defaultDropdownValue}>
              <SelectInline
                inModal
                hoverable={false}
                menuWidth={30}
                popperConfig={modalConstrainPopperConfig}
              >
                <StyledSelect
                  sizing="mobile"
                  value={dropdownValue ?? defaultDropdownValue}
                  options={dropdownOptions}
                  placeholder={t('Choose Field')}
                  onChange={(v) =>
                    onDropdownChange({ value: v.value, id: element?.id })
                  }
                />
              </SelectInline>
            </DropdownWrapper>
          ) : null}
          {displaySwitch ? (
            <SwitchWrapper>
              <StyledSwitch
                textPlacement="left"
                label={switchLabel}
                checked={switchValue}
                onChange={onSwitchChange}
                data-qa={`toggle-${element.displayName}`}
                rightAlign={element.disabled}
                defaultValue={switchDefaultValue}
              />
            </SwitchWrapper>
          ) : null}
          {canDelete?.({ element, editable, section, index, parent, child }) ? (
            <div {...deleteIconTooltipProps}>
              <Icon
                className="DeleteAction"
                icon="delete"
                onClick={() => moveItemToBottom(index, childIndex)}
              />
              {deleteIconTooltip}
            </div>
          ) : null}
          {canChangeSize?.({
            element,
            editable,
            section,
            index,
            parent,
            child,
          }) ? (
            <div {...iconTooltipProps}>
              {canExpand && element.widthPercent === 50 ? (
                <Icon
                  icon={'arrow-to-right'}
                  onClick={() => {
                    changeWidth(100);
                  }}
                />
              ) : element.widthPercent === 100 || !element.widthPercent ? (
                <Icon
                  icon={'arrow-to-left'}
                  onClick={() => {
                    changeWidth(50);
                  }}
                />
              ) : null}
              {iconTooltip}
            </div>
          ) : null}
          {additionalActions?.({
            element,
            removeItem,
            index,
            isCollapsed,
            expandItem,
            collapseItem,
            swapItemColumn,
            section,
            editValue,
            childIndex,
          })}
        </WidthContainer>
      </StyledDragItem>
      {shouldShowPlaceholderChildren ? (
        <ChildrenWrapper>
          <DropPlaceholder
            over={dropFirstChild === element.id}
            onMouseLeave={throttleMaybeDisableFirstDrop}
            onMouseEnter={() => throttleMaybeEnableFirstDrop(element.id)}
          >
            <StyledDragItem className={`MenuItemWrapper-Child-${element.id}`}>
              <KizenTypography>{t('Place Here')}</KizenTypography>
            </StyledDragItem>
          </DropPlaceholder>
        </ChildrenWrapper>
      ) : null}
      {shouldShowEmptyPlaceholderChildren ? (
        <ChildrenWrapper>
          <OptionPlaceholder dragging cardHeight={44} cardWidth={'100%'} child>
            <KizenTypography>
              {t('Add at least one sub-item to display category')}
            </KizenTypography>
          </OptionPlaceholder>
        </ChildrenWrapper>
      ) : null}
      {element.children?.length && !isCollapsed ? (
        <ChildrenWrapper
          onMouseMove={(ev) => getChildDropZone(element.id, ev, false)}
          onMouseLeave={(ev) => getChildDropZone(element.id, ev, true)}
          className="Draggable-Children"
        >
          {element.children?.map((child, childIndex) => {
            const isDragging = child.id === draggingItem?.id;
            const isEditing = child.id === editingItem?.id;
            return (
              <Fragment key={child.id}>
                {dropzoneIs(childDropZone, {
                  id: child.id,
                  position: 'before',
                  direction: 'horizontal',
                }) && <HorizontalDropzone />}
                {isDragging ? (
                  <OptionPlaceholder
                    dragging={isDragging}
                    cardHeight={draggingItem?.dimensions?.height}
                    cardWidth={child.widthPercent === 50 ? '50%' : '100%'}
                    child
                  />
                ) : null}
                <DragItemWrapper
                  dragging={isDragging}
                  draggingDimensions={draggingItem?.dimensions}
                  className={`MenuItemWrapper-Child-${element.id}`}
                  editing={isEditing}
                  width={child.widthPercent ?? 100}
                >
                  {dropzoneIs(childDropZone, {
                    id: child.id,
                    position: 'before',
                    direction: 'vertical',
                  }) && <VerticalDropzone absolute before />}
                  <Draggable
                    position={isDragging ? null : { x: 0, y: 0 }}
                    onStart={(ev) => {
                      ev.preventDefault();
                      if (document.activeElement) {
                        document.activeElement.blur();
                      }
                      const rect = ev.currentTarget.getBoundingClientRect();

                      flushSync(() => {
                        setDraggingItem({
                          ...child,
                          fromColumn: childDragFromColumn,
                          fromParent: element.id,
                          dimensions: {
                            height: ev.currentTarget.offsetHeight,
                            width: ev.currentTarget.offsetWidth,
                            top: rect.top - CARD_OFFSET,
                            left: rect.left,
                          },
                        });
                      });
                    }}
                    onStop={endDrag}
                    handle=".ToolbarDragHandle"
                    disabled={child.isLocked}
                  >
                    <BaseOption
                      element={child}
                      isScrollMode
                      handleProps={{ className: 'ToolbarDragHandle' }}
                      draggingItem={draggingItem}
                      child
                      editingItem={editingItem}
                      setEditingItem={setEditingItem}
                      editValue={editValue}
                      index={index}
                      childIndex={childIndex}
                      editable={editable}
                      removeItem={removeItem}
                      editOptions={editOptions}
                      siblings={element.children ?? []}
                      parent={element}
                      moveItemToBottom={moveItemToBottom}
                      columnWidth={columnWidth}
                      iconVisible={iconVisible}
                      iconEditable={iconEditable}
                      colorEditable={colorEditable}
                      overrideIcon={overrideIcon}
                      canDelete={canDelete}
                      canChangeSize={canChangeSize}
                      additionalActions={additionalActions}
                      showPlaceholderChildren={showPlaceholderChildren}
                      childDragFromColumn={childDragFromColumn}
                    >
                      {children}
                    </BaseOption>
                  </Draggable>
                  {dropzoneIs(childDropZone, {
                    id: child.id,
                    position: 'after',
                    direction: 'vertical',
                  }) && <VerticalDropzone absolute />}
                </DragItemWrapper>
                {dropzoneIs(childDropZone, {
                  id: child.id,
                  position: 'after',
                  direction: 'horizontal',
                }) && <HorizontalDropzone />}
              </Fragment>
            );
          })}
        </ChildrenWrapper>
      ) : null}
    </div>
  );
};

export default BaseOption;
