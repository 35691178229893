import styled from '@emotion/styled';
import { css } from '@emotion/core';
import ColorPickerInput from 'components/Kizen/ColorPickerInput';
import KizenTypography from 'app/kizentypo';
import { breakpoints } from 'app/spacing';
import { colorsSecondary, grayScale } from 'app/colors';
import SubNavigation from 'components/Navigation/SubNavigation';
import { borderRadius } from 'pages/Broadcasts/styles';
import { BusinessCalendarComponent } from './components/Calendar';
import { PageSizing } from 'components/Layout/PageContentWidth';
import Divider from 'components/Layout/Divider';

const PICKER_WIDTH = '214px'; // just big eough to contain the color picker
const LOGO_WIDTH = '190px'; // just big eough to contain the logo

const columWidthCSS = css`
  &:not(:first-of-type) {
    width: ${PICKER_WIDTH};
  }
  &&:first-of-type {
    width: ${LOGO_WIDTH};
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: row;

  .spaced-input {
    margin-top: 20px;
  }
`;

export const StyledCol = styled.div`
  display: flex;
  align-items: center;
  ${columWidthCSS}
  & > div > div {
    max-width: 100%;
  }
`;

export const StyledSubsectionTitle = styled(KizenTypography)`
  margin-top: 20px;
`;

export const StyledFormError = styled(KizenTypography)`
  margin-top: 15px;
  text-align: center;
  color: ${colorsSecondary.red.dark};
`;

export const StyledColorPickerInput = styled(ColorPickerInput)`
  margin-top: 15px;
`;

export const StyledLabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
  margin: 0; // Bootstrap adds bottom margin
  ${columWidthCSS}
`;

export const NavWrapper = styled.div`
  max-width: 600px;
  position: relative;
  margin: 0 auto;
`;

export const StyledSubNavigation = styled(SubNavigation)`
  && a {
    padding-bottom: 10px !important;
  }
`;

const VERTICAL_MARGIN = 20;

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-template-columns: minmax(25vw, 396px) auto;
  gap: 20px;
  margin: ${VERTICAL_MARGIN}px 0 0;
`;

export const LeftColumn = styled.div`
  ${borderRadius}
  background: ${grayScale.white};
  padding: 20px;
  grid-row: 1 / 3;
  height: calc(100% - ${VERTICAL_MARGIN}px);
`;

export const CalendarControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CalendarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(
    100vh - ${({ heightOffset }) => heightOffset + VERTICAL_MARGIN + 70}px
  );
  max-width: 956px;

  @media (max-width: ${breakpoints.xl}px) {
    max-width: calc(75vw - 60px);
  }
  justify-content: center;
  min-height: 676px;
`;

export const StyledBusinessCalendar = styled(BusinessCalendarComponent)`
  max-height: calc(100vh - 160px);
`;

export const StyledSwitch = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 15px;
`;

export const StyledSwitches = styled.div`
  margin: 30px 0;
`;

export const StyledPageSizing = styled(PageSizing)`
  padding-bottom: ${VERTICAL_MARGIN}px;
`;

export const StyledDivider = styled(Divider)`
  flex-shrink: 0;
`;
