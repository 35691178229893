import { useMemo, isValidElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint, breakpoints } from 'app/spacing';
import { TextSpan } from 'app/typography';
import { DateArea, IconArea, TitleArea, DetailsArea } from './styles';
import TypeIcon from './TypeIcon';
import { formatTimestamp } from './helpers';
import { useDateTooltip } from 'components/Inputs/hooks/useDateTooltip';

// if icon is null it flags an error
export default function Header({
  children: title,
  icon,
  isEmpty,
  timestamp,
  onClickDetails,
  event,
  mobileOverride,
}) {
  const { t } = useTranslation();
  const isMobile = useBreakpoint(breakpoints.md) || mobileOverride;
  const formattedTimestamp = useMemo(
    () => formatTimestamp(timestamp),
    [timestamp]
  );

  const [dateTooltipProps, dateTooltip] = useDateTooltip({
    date: timestamp,
  });

  return (
    <>
      <IconArea>
        {icon ? isValidElement(icon) ? icon : <TypeIcon icon={icon} /> : null}
      </IconArea>
      <TitleArea as="span" isEmpty={isEmpty}>
        {typeof title === 'string' ? (
          <TextSpan weight="bold" size="micro">
            {title}
          </TextSpan>
        ) : (
          title
        )}
      </TitleArea>
      {event?.hasRelationship ? (
        <DetailsArea onClick={onClickDetails}>
          {isMobile ? t('More Details') : t('Relationship Details')}
        </DetailsArea>
      ) : null}
      {icon ? (
        <DateArea>
          <span {...dateTooltipProps}>
            {dateTooltip}
            {formattedTimestamp}
          </span>
        </DateArea>
      ) : null}
    </>
  );
}
