import { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import StateController from '../components/statecontroller';
import WizardStateContext from '../context/wizardstate';
import trend from './images/trend.png';
import summary from './images/summary.png';
import summary_historical from './images/summary_historical.png';
import BottomScrollPosition from './BottomScrollPosition';

const Wizard = forwardRef((props, ref) => {
  const {
    children,
    canAutoScroll,
    setCanAutoScroll,
    modalBody,
    isEditing,
    ...rest
  } = props;

  // Preload the chart selector images so we don't get odd
  // UI state while they're loading
  useEffect(() => {
    [trend, summary, summary_historical].forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }, []);

  return (
    <WizardStateContext.Wrapper {...rest} ref={ref}>
      <StateController isEditing={isEditing}>{children}</StateController>
      <BottomScrollPosition
        canAutoScroll={canAutoScroll}
        setCanAutoScroll={setCanAutoScroll}
        modalBody={modalBody}
      />
    </WizardStateContext.Wrapper>
  );
});

Wizard.propTypes = {
  existing: PropTypes.object,
  controllers: PropTypes.arrayOf(PropTypes.func),
  setData: PropTypes.func,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  canAutoScroll: PropTypes.bool,
};

Wizard.defaultProps = {
  canAutoScroll: true,
};

export default Wizard;
