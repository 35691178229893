import { getOptionValue } from 'services/helpers';
const forApi = (step) => {
  const orgDetails = { ...step.details };
  if (orgDetails.stepKey) {
    delete orgDetails.triggerKey;
    orgDetails.stepKey = getOptionValue(orgDetails.stepKey);
  }
  if (orgDetails.triggerKey) {
    delete orgDetails.stepKey;
    orgDetails.triggerKey = getOptionValue(orgDetails.triggerKey);
  }
  return { details: orgDetails };
};
const forFlow = (apiObject, addErrorMessage) => {
  const { step, trigger, ...rest } = apiObject;

  const flow = {
    ...rest,
  };
  if (step) {
    flow.stepKey = {
      value: getOptionValue(step),
    };
  }
  if (trigger) {
    flow.triggerKey = {
      value: getOptionValue(trigger),
    };
  }
  return flow;
};
export const goToAutomationStepDTO = {
  forApi,
  forFlow,
};
