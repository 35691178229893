import { useTranslation } from 'react-i18next';
import { KizenTypography } from 'app/typography';
import { AUTOMATION_STATE } from 'services/Automation2Service';
import { colorsPrimary, colorsSecondary } from 'app/colors';
import ColorLabel from 'components/Fields/ColorLabel';

// There are specific precendence rules that are being followed by the
// order of these statements.
const getDisplayData = (status, active, deleted) => {
  if (status === AUTOMATION_STATE.COMPLETED) {
    return {
      label: (t) => t('Completed'),
      statusColor: colorsPrimary.blue.dark,
    };
  }
  if (status === AUTOMATION_STATE.CANCELLED) {
    return {
      label: (t) => t('Cancelled'),
      statusColor: colorsSecondary.red.dark,
    };
  }
  if (status === AUTOMATION_STATE.FAILED) {
    return {
      label: (t) => t('Failed'),
      statusColor: colorsSecondary.red.dark,
    };
  }
  if (deleted) {
    return {
      label: (t) => t('Automation Deleted'),
      statusColor: colorsSecondary.red.dark,
    };
  }
  if (!active || status === AUTOMATION_STATE.PAUSED_BY_AUTOMATION) {
    return {
      label: (t) => t('Inactive Automation'),
      statusColor: colorsSecondary.red.dark,
    };
  }
  if (status === AUTOMATION_STATE.ACTIVE) {
    return {
      label: (t) => t('Active'),
      statusColor: colorsPrimary.green.dark,
    };
  }
  if (status === AUTOMATION_STATE.PAUSED) {
    return {
      label: (t) => t('Paused'),
      statusColor: colorsPrimary.orange.dark,
    };
  }
  if (status === AUTOMATION_STATE.PAUSED_BY_FAILURE) {
    return {
      label: (t) => t('Paused on Failure'),
      statusColor: colorsSecondary.red.dark,
    };
  }
};

const StatusWidget = ({ automation }) => {
  const { t } = useTranslation();
  const { status, automationActive, automationDeleted } = automation;
  const chosenDisplayData = getDisplayData(
    status,
    automationActive,
    automationDeleted
  );

  return (
    <KizenTypography>
      <ColorLabel color={chosenDisplayData.statusColor}>
        {chosenDisplayData.label(t)}
      </ColorLabel>
    </KizenTypography>
  );
};

export default StatusWidget;
