import Pluralize from 'pluralize';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import { DATE_DISPLAY_FORMAT } from 'components/Inputs/DateInput';
import {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
  ActionButtonMenu,
} from 'components/Layout/ListCard';
import { TextEllipsis } from 'components/Kizen/Table';
import format from 'date-fns/format';
import { FORM_ACTIONS } from './columns';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { getCustomObjectLinkProps } from 'pages/Common/helpers/formsAndSurveys';

const NO_VALUE = '—';

const getSubmissionsText = (numberSubmissions) => {
  return `${numberSubmissions || 0} ${Pluralize(
    'Submission',
    numberSubmissions
  )}`;
};

export const EmptyFormListItem = ListCardItem;

export function FormListItem({ form, onSelectAction, ...others }) {
  const { t } = useTranslation();
  const { relatedObject } = form;

  const options = useMemo(() => {
    return [
      FORM_ACTIONS.Preview,
      relatedObject?.entityAccess && FORM_ACTIONS.Delete,
    ]
      .filter(Boolean)
      .map((item) => ({ ...item, label: item.localizedLabel(t) }));
  }, [relatedObject?.entityAccess, t]);

  return (
    <ListCardItem
      action={
        <ActionButtonMenu
          sizing="dense"
          above={form.meta.openMenuAbove}
          color={colorsButton.iconGray}
          onChange={onSelectAction}
          options={options}
        >
          <Icon icon="three-dot" style={{ width: 15 }} />
        </ActionButtonMenu>
      }
      actionStyles={{ paddingTop: '10px' }}
      {...others}
    >
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis as="span">{form.name || NO_VALUE}</TextEllipsis>
        </ListCardCell>
        <ListCardCell>
          <TextEllipsis {...getCustomObjectLinkProps(relatedObject)}>
            {relatedObject?.objectName || NO_VALUE}
          </TextEllipsis>
        </ListCardCell>
      </ListCardRow>
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis as="span">
            {getSubmissionsText(form.numberSubmissions)}
          </TextEllipsis>
        </ListCardCell>
        <ListCardCell>
          <TextEllipsis as="span">
            {format(form.created, DATE_DISPLAY_FORMAT) || NO_VALUE}
          </TextEllipsis>
        </ListCardCell>
      </ListCardRow>
    </ListCardItem>
  );
}

export function FormListHeader({
  label,
  sort = null,
  onChangeSort = null,
  ...others
}) {
  return (
    <ListCardHeader
      compactHeader
      action={<div style={{ width: 15 }} />}
      {...others}
    >
      <ListCardCell>
        <TextEllipsis as="span" weight="bold">
          {label}
        </TextEllipsis>
        <ListCardSortButton sort={sort} onChangeSort={onChangeSort} />
      </ListCardCell>
    </ListCardHeader>
  );
}
