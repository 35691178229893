import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import SmartConnectorService from 'services/SmartConnectorService';
import {
  SQLScript,
  ScriptExecutionResult,
  ScriptFile,
  SmartConnector,
} from '__pages/SmartConnectors/types';
import { SmartConnectorContext } from '../context';
import PerformActionService from 'services/PerformActionService';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from '__components/ToastProvider';
import { useTranslation } from 'react-i18next';
import { monitoringExceptionHelper } from 'sentry/helpers';

const smartConnectorEndpoint = `${import.meta.env.VITE_API_BASE_PATH}/${SmartConnectorService.serviceEndpoint}`;

const POLL_INTERVAL = 3000;

export type UseScriptsProps = {
  pollingScriptExecution: boolean;
  publishingLiveScript: boolean;
  generatingSQLTemplate: boolean;
  handleTestExecution: () => Promise<void>;
  handlePublishLiveScript: () => Promise<boolean>;
  handleChangeSourceFile: (file: ScriptFile) => Promise<void>;
  handleDownloadLocalDevPackage: () => Promise<void>;
  executionResult: ScriptExecutionResult | null;
  forceReset: number;
  setForceReset: Dispatch<SetStateAction<number>>;
};

export const useScripts = (): UseScriptsProps => {
  const [showToast] = useToast();
  const { t } = useTranslation();

  const {
    smartConnector,
    stepData,
    setStepData,
    handleUpdate,
    refetchSmartConnector,
  } = useContext(SmartConnectorContext);

  const [executionResult, setExecutionResult] =
    useState<ScriptExecutionResult | null>(null);
  const [polling, setPolling] = useState<boolean>(false);
  const [publishing, setPublishing] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const [forceReset, setForceReset] = useState<number>(0);

  const userScriptRef = useRef<SQLScript | null>(
    stepData.last_draft_script || null
  );
  userScriptRef.current = stepData.last_draft_script || null;

  const handleChangeSourceFile = useCallback(
    async (file: ScriptFile) => {
      setGenerating(true);
      try {
        const { data } = await SmartConnectorService.getScriptTemplate(
          smartConnector.id,
          file.id,
          { skipErrorBoundary: true }
        );

        if (data.sql_required) {
          showToast({
            message: t(
              'This SmartConnector has multiple sheets and will require SQL Processing prior to upload.'
            ),
            variant: toastVariant.ALERT,
          });
        }
        setStepData((prev) => ({
          ...prev,
          source_file: file,
          sql_enabled: data.sql_required ? true : prev.sql_enabled,
          sql_required: data.sql_required,
          last_draft_script: {
            ...prev.last_draft_script,
            config_metadata: data.config_metadata,
            user_script: data.user_script,
            last_execution_status_id: null,
          },
        }));
        setForceReset((prev) => prev + 1);
      } catch (err) {
        showToast({
          message: t('Failed to Generate SQL Template'),
          variant: toastVariant.FAILURE,
        });
      } finally {
        setGenerating(false);
      }
    },
    [smartConnector.id, setStepData, setForceReset, showToast, t]
  );

  const handleTestExecution = useCallback(async () => {
    setPolling(true);
    if (!smartConnector.sql_enabled) {
      const promise = new Promise<Partial<SmartConnector>>(
        (resolve, reject) => {
          SmartConnectorService.updateSmartConnector(smartConnector.id, {
            sql_enabled: true,
          }).then(
            ({ data }) => resolve({ sql_enabled: data.sql_enabled }),
            reject
          );
        }
      );

      const updated = await handleUpdate(promise);

      if (!updated) {
        setPolling(false);
        showToast({
          message: t('Failed to Enable SQL Processing'),
          variant: toastVariant.FAILURE,
        });
        return;
      }
    }

    try {
      const { data } = await SmartConnectorService.startSmartConnectorScriptRun(
        smartConnector.id,
        stepData.last_draft_script?.id,
        {
          user_script: userScriptRef.current?.user_script,
          source_file_id: stepData.source_file?.id,
          config_metadata: userScriptRef.current?.config_metadata,
        },
        { skipErrorBoundary: true }
      );

      if (data.last_execution_status_id) {
        handleUpdate(
          Promise.resolve({
            last_draft_script: {
              ...userScriptRef.current,
              user_script: userScriptRef.current?.user_script || '',
              last_execution_status_id: data.last_execution_status_id,
            },
          })
        );
      } else {
        setPolling(false);
      }
    } catch (err) {
      showToast({
        message: t('Failed to Start Test Script Execution'),
        variant: toastVariant.FAILURE,
      });
      setPolling(false);
    }
  }, [
    stepData.source_file?.id,
    smartConnector.id,
    smartConnector.sql_enabled,
    stepData.last_draft_script?.id,
    handleUpdate,
    showToast,
    t,
  ]);

  const handlePublishLiveScript = useCallback(async () => {
    setPublishing(true);

    try {
      await SmartConnectorService.publishSmartConnectorScript(
        smartConnector.id,
        smartConnector.last_draft_script?.id,
        { skipErrorBoundary: true }
      );

      await refetchSmartConnector();
      return true;
    } catch (err) {
      showToast({
        message: t('Failed to Publish Live Script'),
        variant: toastVariant.FAILURE,
      });
      return false;
    } finally {
      setPublishing(false);
    }
  }, [
    smartConnector.id,
    smartConnector.last_draft_script?.id,
    refetchSmartConnector,
    showToast,
    t,
  ]);

  const handleDownloadLocalDevPackage = async () => {
    if (smartConnector.last_draft_script?.id) {
      const promise = new Promise<Partial<SmartConnector>>(
        (resolve, reject) => {
          SmartConnectorService.updateSmartConnector(smartConnector.id, {
            source_file_id: stepData.source_file?.id,
            sql_enabled: stepData.sql_enabled,
          }).then(async ({ data }) => {
            const { last_draft_script } = stepData;
            if (
              last_draft_script?.user_script !==
              smartConnector.last_draft_script?.user_script
            ) {
              try {
                await SmartConnectorService.updateSmartConnectorScript(
                  smartConnector.id,
                  last_draft_script?.id,
                  last_draft_script?.user_script || '',
                  {
                    skipErrorBoundary: true,
                  }
                );
              } catch (err) {
                // we do not want to block the save process if script update fails
                monitoringExceptionHelper(err, {
                  extra: {
                    smartConnectorId: smartConnector.id,
                    lastDraftScriptId: last_draft_script?.id,
                    userScript: last_draft_script?.user_script,
                  },
                });
              }
            }
            resolve({
              source_file: data.source_file,
              sql_enabled: data.sql_enabled,
              sql_required: data.sql_required,
              last_draft_script: data.last_draft_script,
              live_script: data.live_script,
            });
          }, reject);
        }
      );

      const updated = await handleUpdate(promise);

      if (updated) {
        window.open(
          `${smartConnectorEndpoint}/${smartConnector.id}/sql-scripts/${smartConnector.last_draft_script.id}/connector-local-dev-package`,
          '_blank'
        );
      } else {
        showToast({
          variant: toastVariant.FAILURE,
          message: t('Failed to download local dev package'),
        });
      }
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let reset = false;
    setExecutionResult(null);

    if (stepData.last_draft_script?.last_execution_status_id) {
      setPolling(true);

      const poll = async () => {
        const scriptExecutionResult: ScriptExecutionResult =
          await PerformActionService.getBulkActionProggressById(
            stepData.last_draft_script?.last_execution_status_id
          );

        if (reset) {
          return;
        }

        if (scriptExecutionResult.status !== 'in_progress') {
          clearTimeout(timeout);
          setExecutionResult(scriptExecutionResult);
          setPolling(false);
        } else {
          timeout = setTimeout(poll, POLL_INTERVAL);
        }
      };

      poll();
    } else {
      setPolling(false);
    }

    return () => {
      clearInterval(timeout);
      setPolling(false);
      reset = true;
    };
  }, [stepData.last_draft_script?.last_execution_status_id]);

  return {
    pollingScriptExecution: polling,
    publishingLiveScript: publishing,
    generatingSQLTemplate: generating,
    handleTestExecution,
    handlePublishLiveScript,
    handleChangeSourceFile,
    handleDownloadLocalDevPackage,
    executionResult,
    forceReset,
    setForceReset,
  };
};
