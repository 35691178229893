import { useReducer } from 'react';
import { FIELD_TYPES } from 'utility/constants';
import { fieldTypeMapper } from 'components/Fields/FieldInput/helpers';
import { isOwnerField } from 'checks/fields';
import { EMPTY_ARRAY, EMPTY_OBJECT, dummyObject } from './config';
import {
  assignmentReducer,
  useGetRelationshipsForActivity,
} from 'components/Charts/ScheduledActivities/assignmentHelpers';

export const setInitialValue = ({ fieldType } = {}) => {
  switch (fieldType) {
    case FIELD_TYPES.Checkboxes.type:
      return EMPTY_ARRAY;
    case FIELD_TYPES.Checkbox.type:
      return false;
    case FIELD_TYPES.Text.type:
    case FIELD_TYPES.Email.type:
      return '';
    default:
      return null;
  }
};

export const isEmptyValue = (value) => {
  if (
    value === null ||
    value === false ||
    value === undefined ||
    (typeof value === 'string' && value.trim() === '')
  )
    return true;
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return typeof value === 'object' && !value.e164 && !value.id;
};

const getShortLabel = ({ name, email, id }) => name || email || id;

export const getFieldValueFromAPI = (field) => {
  const { fieldType, value } = field;
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      if (Array.isArray(value)) {
        return value.map((v) => ({ ...v, shortLabel: getShortLabel(v) }));
      }
      return { ...value, shortLabel: getShortLabel(value) };
    default:
      return value;
  }
};

export const fieldModifier = (field, relatedObjects) => {
  if (field && isOwnerField(field)) {
    field.isRequired = relatedObjects.some(
      ({ customObject }) =>
        customObject === field.customObjectField?.customObject?.id
    );
  }
  return field;
};

export const mapFields = (f) => {
  let { fieldType } = f;
  if (fieldType === FIELD_TYPES.ActivityCustomField.type) {
    const {
      fieldType: orgFieldType = FIELD_TYPES.Text.type,
      isDefault: orgIsDefault = false,
      name: orgName = '',
    } = f.customObjectField;
    fieldType = fieldTypeMapper({
      isDefault: orgIsDefault,
      name: orgName,
      fieldType: orgFieldType,
    });
  }
  return { ...f, fieldType, ...dummyObject };
};

export const useActivityAssociation = (
  activityObjectId,
  predefinedOptions = {},
  queryOptions = {},
  apiOptions = {},
  errorCallback = null
) => {
  const [assignments, dispatchAssignment] = useReducer(
    assignmentReducer,
    EMPTY_OBJECT
  );
  const { clientOption, companyOption, defaultAssignment } = predefinedOptions;

  const { value: activityRelatedObjects = EMPTY_ARRAY, isFetching } =
    useGetRelationshipsForActivity(
      {
        client: clientOption,
        company: companyOption,
        defaultAssignment: defaultAssignment,
        dispatchAssignment: dispatchAssignment,
      },
      {
        activityObjectId: activityObjectId,
        queryOptions: queryOptions,
        apiOptions: apiOptions,
        errorCallback: errorCallback,
      }
    );

  return {
    assignments,
    dispatchAssignment,
    activityRelatedObjects,
    isFetching,
  };
};
