import React from 'react';

export default function ThreeDotIcon(props) {
  return (
    /* eslint-disable */
    <svg
      width="20"
      height="5"
      viewBox="0 0 20 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.34784 2.15168C4.34784 3.34002 3.37454 4.30336 2.17392 4.30336C0.973296 4.30336 0 3.34002 0 2.15168C0 0.963339 0.973296 0 2.17392 0C3.37454 0 4.34784 0.963339 4.34784 2.15168Z"
        fill="currentColor"
      />
      <path
        d="M12.174 2.15168C12.174 3.34002 11.2007 4.30336 10.0001 4.30336C8.79949 4.30336 7.8262 3.34002 7.8262 2.15168C7.8262 0.963339 8.79949 0 10.0001 0C11.2007 0 12.174 0.963339 12.174 2.15168Z"
        fill="currentColor"
      />
      <path
        d="M20 2.15168C20 3.34002 19.0267 4.30336 17.8261 4.30336C16.6255 4.30336 15.6522 3.34002 15.6522 2.15168C15.6522 0.963339 16.6255 0 17.8261 0C19.0267 0 20 0.963339 20 2.15168Z"
        fill="currentColor"
      />
    </svg>
    /* eslint-enable */
  );
}
