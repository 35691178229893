import { useTranslation } from 'react-i18next';
import { StyledDndColumns } from '../styles';
import { CONTEXTS } from 'components/Wizards/utils';

const dummyFn = () => false;

export const DraggableColumns = ({
  forceKey,
  onChange,
  leftItems,
  rightItems,
  itemsOrdering,
}) => {
  const { t } = useTranslation();
  return (
    <StyledDndColumns
      key={`${CONTEXTS.fields}+${forceKey}`}
      initialLeftColumnItems={leftItems}
      initialRightColumnItems={rightItems}
      previewLabel={t('Column Preview')}
      searchPlaceholder={t('Find Options')}
      leftHeaderText={t('Available Columns (Drag to Add)')}
      rightHeaderText={t('Active Table Columns')}
      renderRightEmpty={{
        onDropLabel: t('Place Here'),
        noItems: t('No Columns Currently Selected (Drag and Drop to Add)'),
      }}
      renderLeftEmpty={{
        onDropLabel: t('Drop to Remove'),
        noItems: t('No Options Found'),
      }}
      onChange={onChange}
      iconVisible={dummyFn}
      iconEditable={dummyFn}
      colorEditable={dummyFn}
      context={CONTEXTS.fields}
      showCategoryOnSearch={false}
      grouped
      itemsOrdering={itemsOrdering}
    />
  );
};
