import { useEffect, useRef, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import Input from 'components/Kizen/Input';
import MainSelector from 'components/Inputs/MainSelector';
import { colorsButton, grayScale } from 'app/colors';
import { css } from '@emotion/core';
import { fontWeights } from 'app/typography';
import { useTranslation } from 'react-i18next';
import { useTooltip } from 'components/Kizen/Tooltip';
import { useDebounce } from 'react-use';
import { DEFAULT_INPUT_DELAY, DEFAULT_LONG_INPUT_DELAY } from 'utility/config';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

// The content should be 36px high and the padding 20px,
// but let's flex-center rather than use padding, since the
// fixed height of the element is really important for supporting
// optional scroll/sticky functionality (i.e. on Big Table layouts).
export const PAGE_TOOLBAR_HEIGHT = 76; // 36px typical content height + 2 * 20px padding
export const PAGE_BREADCRUMB_HEIGHT = 9;

export const PageBreadcrumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${PAGE_TOOLBAR_HEIGHT}px;
`;

const PageToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${PAGE_TOOLBAR_HEIGHT}px;
  > h2 {
    margin: 0 ${gutters.spacing(3)}px;
    text-align: center;
  }
`;

// Needed to assign PageToolbar for react-docgen / storybook

export default PageToolbar;

export const PageToolbarTitle = styled(
  ({ count = null, single = null, plural = null, children, ...props }) => {
    return (
      <KizenTypography as="h2" type="header" {...props}>
        {children || (
          <>
            {count.toLocaleString()} {count === 1 ? single : plural}
          </>
        )}
      </KizenTypography>
    );
  }
)`
  line-height: 1.2; // Weirdly fighting against some styles from the DateRangePicker
`;

export const PageToolbarSection = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  ${({ fixed }) =>
    fixed
      ? css`
          flex: 1 0;

          button {
            white-space: nowrap;
          }
        `
      : ''}
  && > * {
    margin: 0;
    button {
      // So text doesn't wrap outside the button
      // TODO consider porting to button component, would require some testing of the app.
      line-height: 1;
    }
  }
  &:first-child {
    justify-content: flex-start;
    & > *:not(:last-child) {
      margin-right: ${gutters.spacing(7)}px;
    }
  }
  &:last-child {
    justify-content: flex-end;
    & > *:not(:first-child) {
      margin-left: ${gutters.spacing(3)}px;
    }
  }

  button {
    white-space: nowrap;
  }
`;

export const PageMainSelector = styled(({ title, ...props }) => {
  const [tooltipProps, tooltip] = useTooltip(
    {
      label: title,
    },
    () => !!title
  );
  return (
    <div {...tooltipProps}>
      <MainSelector
        classNamePrefix="PageMainSelector"
        {...props}
        onMenuClose={tooltipProps.onBlur}
      />
      {tooltip}
    </div>
  );
})`
  .PageMainSelector__control {
    background-color: unset;
    cursor: pointer;
  }

  .PageMainSelector__menu {
    z-index: 101;
  }
`;

export const FilterGroupSelector = ({ link, ...props }) => {
  const { t } = useTranslation();
  return (
    <PageMainSelector
      {...props}
      menuLeftButton={
        <Button
          as={Link}
          to={link}
          rel="noopener noreferrer"
          preventDefault={false}
          noSpace
          variant="text"
          color="blue"
          data-qa="filter-groups-link"
        >
          {t('Manage Filter Groups')}
        </Button>
      }
    />
  );
};

export const StyledPageSearchInput = styled(Input)`
  max-width: ${({ maxWidth }) => maxWidth || 175}px;
  .input-wrapper {
    background-color: ${grayScale.white};
  }
  input {
    min-width: 0;
    padding-right: 0;
  }
`;

const SEARCH_RANGE_MIN = 0;
const SEARCH_RANGE_MAX = 3;

const useSearchInput = (
  value,
  onChange,
  delay = DEFAULT_INPUT_DELAY,
  longDelay = DEFAULT_LONG_INPUT_DELAY
) => {
  const [innerValue, setInnerValue] = useState(value);
  const stagedValue = useRef(value);

  useDebounce(
    () => {
      if (stagedValue.current !== innerValue) {
        onChange(innerValue);
      }
    },
    innerValue?.length < SEARCH_RANGE_MAX &&
      innerValue?.length > SEARCH_RANGE_MIN
      ? longDelay
      : delay,
    [innerValue]
  );

  useEffect(() => {
    if (value !== stagedValue.current) {
      setInnerValue(value);
      stagedValue.current = value;
    }
  }, [value]);

  const handleKeyDown = useCallback(
    (ev) => {
      if (ev.key === 'Enter') {
        if (
          innerValue.length < SEARCH_RANGE_MAX &&
          innerValue.length > SEARCH_RANGE_MIN &&
          innerValue !== stagedValue.current
        ) {
          onChange(innerValue);
        }
      }
    },
    [innerValue, onChange]
  );

  return {
    innerValue,
    setInnerValue,
    handleKeyDown,
  };
};

export const PageSearchInput = ({
  value,
  onChange,
  delay = DEFAULT_INPUT_DELAY,
  ...props
}) => {
  const { innerValue, setInnerValue, handleKeyDown } = useSearchInput(
    value,
    onChange,
    delay
  );

  return (
    <StyledPageSearchInput
      pill
      type={Input.INPUT_TYPES.SEARCH}
      {...props}
      onChange={setInnerValue}
      onKeyDown={handleKeyDown}
      value={innerValue}
      data-qa="page-search-input"
    />
  );
};

const FiltersIconButton = styled(IconButton)`
  padding: ${gutters.spacing(2)}px;
  margin-left: -${gutters.spacing(2)}px;
  border-radius: 8px 8px 0 0;
  position: relative;
  ${({ showBackground }) =>
    showBackground &&
    css`
      background-color: ${grayScale.white};
      // We need two box-shadows: one to extend the box down to the filter dropdown and another to
      // provide our shadowLight (see app/colors). Can't use shadowLight here because the box-shadows need
      // to appear in the same rule.
      box-shadow:
        0 18px 0 ${grayScale.white},
        0 18px 26px rgba(0, 0, 0, 0.03);
      // Clip the bottom box-shadow so it doesn't appear on top of the filters toolbar
      clip-path: inset(-8px -26px -18px -26px);
      && > :first-child {
        color: ${({ color }) => color || colorsButton.blue.default};
      }
      // Make a sort-of "negative border radius"
      &:before,
      &:after {
        content: '';
        position: absolute;
        background-color: transparent;
        height: 8px;
        width: 16px;
        bottom: -18px; // accounts for the 18px box shadow between the menu icon and menu
      }
      &:after {
        border-bottom-right-radius: 8px;
        box-shadow: 8px 0px 0 0px ${grayScale.white};
        left: -16px;
        transition: all 0.5s ease-in-out;
      }
      &:before {
        border-bottom-left-radius: 8px;
        box-shadow: -8px 0px 0 0px ${grayScale.white};
        right: -16px;
      }
    `}
`;

// The notification from app/typo didn't work for our purposes here.
// TODO properly support icon badging in style guide

const CustomNotificationText = styled(KizenTypography)`
  margin-top: 1px; // Design nudge
  font-size: 8.125px;
  font-weight: ${fontWeights.regular};
  color: ${grayScale.white};
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${({ color }) => color || colorsButton.blue.default};
  transform: translate(0, -50%);
`;

const getPageToolbarFilterButtonColor = (disabled, errors) =>
  disabled
    ? grayScale.medium
    : errors
      ? colorsButton.red.hover
      : colorsButton.blue.default;

export const PageToolbarFiltersButton = ({
  badge = null,
  disabled = null,
  showBackground = null,
  errors = false,
  ...props
}) => {
  const { t } = useTranslation();
  const colorButton = getPageToolbarFilterButtonColor(disabled, errors);

  return (
    <FiltersIconButton
      sizing="dense"
      color={colorButton}
      disabled={disabled}
      showBackground={showBackground}
      data-qa="filter"
      {...props}
      title={t('Filter Results')}
    >
      <Icon icon="filter" />
      {!!badge && (
        <BadgeContainer color={colorButton}>
          <CustomNotificationText>{badge}</CustomNotificationText>
        </BadgeContainer>
      )}
    </FiltersIconButton>
  );
};
