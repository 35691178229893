import { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useReactFlow } from 'reactflow';
import { grayScale, shadowLight, colorsButton } from 'app/colors';
import { borderRadii, layers, spaceBetweenGutters } from 'app/spacing';
import {
  PreReleaseFeature,
  usePreReleaseFeatures,
} from 'hooks/usePreReleaseFeatures';
import EditableText from 'components/Kizen/EditableText';
import Icon from 'components/Kizen/Icon';
import { Button as ButtonKDS } from '@kizen/kds/Button';
import IconButtonWithBadge from 'components/Kizen/IconButtonWithBadge';
import BaseSwitch from 'components/Kizen/Switch';
import LoadingButton from 'components/Button/LoadingButton';
import BackButton from 'components/Button/BackButton';
import { CONTENT_MAX_WIDTH } from 'components/Layout/PageContentWidth';
import StepOption from './Steps/StepOption';
import Draggable from '../Draggable';
import steps from '../steps';
import { actions } from '../store';

import {
  getAutomation,
  getSaveable,
  getNameError,
  getShowBlocker,
} from '../store/selectors';
import { useAutomationsDispatch, useAutomationsSelector } from '../store/react';
import ZoomControl from './ZoomControl';
import { MAX_AUTOMATION_NAME_LENGTH } from 'pages/Automations/constants';
import useModal from 'components/Modals/useModal';
import { AutomationEmailModal } from './AutomationEmailModal';
import { BusinessCalendarModal } from './BusinessCalendarModal';
import { getAutomationStatsConfig } from '../helpers';
import { Statistic } from 'ts-components/AutomationEngine/ControlBar/Statistic';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 59px;
  border-bottom: 1px solid ${grayScale.mediumLight};
  background: ${grayScale.white};
  padding: 0 15px;
  ${shadowLight};
  > div {
    flex: 1;
    max-width: ${CONTENT_MAX_WIDTH};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  z-index: ${layers.content(0)};
  > *:not(:last-child) {
    margin-right: 30px;
  }
  > :first-child {
    margin-right: 15px;
  }
`;

const Center = styled.div`
  max-width: 400px;
  padding: 0 20px;
  z-index: ${layers.content(0)};
  > * {
    max-width: 100%;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: ${layers.content(0)};
  flex: 1;
  > button {
    margin: 0;
  }
  > *:not(:first-child) {
    margin-left: 40px;
  }
`;

function getStepWidth({ columns }) {
  return spaceBetweenGutters({
    columns,
    gutter: '20px',
  });
}

const StepSelector = styled.div`
  height: 113px;
  width: ${({ columns }) => columns * 156 + 'px'};
  background: ${grayScale.white};
  border-bottom-left-radius: ${borderRadii.standard};
  border-bottom-right-radius: ${borderRadii.standard};
  ${shadowLight};
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  > div {
    flex: 1;
    margin-top: 58px;
    padding: 20px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    > * {
      width: ${getStepWidth};
    }
  }
  // Center absolutely so toolbar content can overlap it
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

const Switch = styled(BaseSwitch)`
  margin: 0;
  label {
    margin-bottom: 0;
  }
`;

export default function ControlBar(props) {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();
  const dispatch = useAutomationsDispatch();
  const automation = useAutomationsSelector(getAutomation);
  const saveable = useAutomationsSelector(getSaveable);
  const persisting = useAutomationsSelector(getShowBlocker);
  const showStats = useAutomationsSelector((state) => state.showStats);
  const nameError = useAutomationsSelector(getNameError);

  const handleSubmitErrorNotification = useCallback(
    ({ errorNotificationEmail, revision }) => {
      dispatch(
        actions.setAutomation({
          errorNotificationEmail,
          revision,
        })
      );
    },
    [dispatch]
  );

  const handleSubmitBusinessCalendar = useCallback(
    ({ skipNonWorkingDays, revision }) => {
      dispatch(
        actions.setAutomation({
          skipNonWorkingDays,
          revision,
        })
      );
    },
    [dispatch]
  );

  const [emailModalProps, emailButtonProps] = useModal({
    handleSubmit: handleSubmitErrorNotification,
    processHideOnSubmit: true,
  });

  const [calendarModalProps, calendarButtonProps] = useModal({
    handleSubmit: handleSubmitBusinessCalendar,
    processHideOnSubmit: true,
  });

  return (
    <Wrapper {...props}>
      <div>
        <StepSelector columns={preReleaseFeatures ? 5 : 4}>
          <div>
            <Draggable
              onStart={() =>
                dispatch(actions.placing({ type: steps.action.type }))
              }
              onStop={() => dispatch(actions.placing(false))}
              disabled={!steps.action.creatable}
            >
              <StepOption
                color={steps.action.color}
                icon={<Icon icon="bullhorn" />}
                label={t('Action')}
                disabled={!steps.action.creatable}
              />
            </Draggable>
            <Draggable
              onStart={() =>
                dispatch(actions.placing({ type: steps.condition.type }))
              }
              onStop={() => dispatch(actions.placing(false))}
              disabled={!steps.condition.creatable}
            >
              <StepOption
                color={steps.condition.color}
                icon={<Icon icon="code-branch" />}
                label={t('Condition')}
                disabled={!steps.condition.creatable}
              />
            </Draggable>
            <Draggable
              onStart={() =>
                dispatch(actions.placing({ type: steps.delay.type }))
              }
              onStop={() => dispatch(actions.placing(false))}
              disabled={!steps.delay.creatable}
            >
              <StepOption
                color={steps.delay.color}
                icon={<Icon icon="clock-heavy" />}
                label={t('Delay')}
                disabled={!steps.delay.creatable}
              />
            </Draggable>
            <Draggable
              onStart={() =>
                dispatch(actions.placing({ type: steps.goal.type }))
              }
              onStop={() => dispatch(actions.placing(false))}
              disabled={!steps.goal.creatable}
            >
              <StepOption
                color={steps.goal.color}
                icon={<Icon icon="bullseye" />}
                label={t('Goal')}
                disabled={!steps.goal.creatable}
              />
            </Draggable>
            <PreReleaseFeature>
              <Draggable
                onStart={() =>
                  dispatch(actions.placing({ type: steps.splitTest.type }))
                }
                onStop={() => dispatch(actions.placing(false))}
                disabled={!steps.splitTest.creatable}
              >
                <StepOption
                  color={steps.splitTest.color}
                  icon={<Icon icon="flask" />}
                  label={t('Split Test')}
                  disabled={!steps.splitTest.creatable}
                />
              </Draggable>
            </PreReleaseFeature>
          </div>
        </StepSelector>
        <Left>
          <BackButton to="/automations">{t('Back')}</BackButton>
          <Switch
            textPlacement="right"
            label={t('Stats')}
            checked={showStats}
            onChange={(ev) => dispatch(actions.showStats(ev.target.checked))}
            data-qa="automation-stats-toggle"
          />
          <ButtonKDS
            {...emailButtonProps}
            leftIcon="message-automation-email-warning"
            leftIconSettings={{
              size: '2xl',
              tooltipDescriptionOverride: t('Email Error Notification'),
            }}
            variant="text"
            color="secondary"
          />
          <ButtonKDS
            {...calendarButtonProps}
            leftIcon="action-apply-calendar"
            leftIconSettings={{
              size: '2xl',
              badge: !!automation.skipNonWorkingDays,
              tooltipDescriptionOverride: t('Business Calendar'),
            }}
            variant="text"
            color="secondary"
          />
          <PreReleaseFeature>
            <IconButtonWithBadge
              title={t('Comments')}
              icon="comment"
              badge={1}
            />
          </PreReleaseFeature>
        </Left>
        <Center>
          <EditableText
            placeholder={t('Automation Name')}
            value={automation.name}
            onChange={(val) => {
              dispatch(actions.setAutomation({ name: val }));
              dispatch(actions.clearNameError());
            }}
            data-qa={`automation-name`}
            error={nameError}
            maxLength={MAX_AUTOMATION_NAME_LENGTH}
          />
        </Center>
        <Right>
          <Switch
            textPlacement="left"
            label={t('Active?')}
            checked={automation.active}
            onChange={(ev) =>
              dispatch(actions.setAutomation({ active: ev.target.checked }))
            }
            removeLabelMargin
            backgroundColor={colorsButton.red.hover}
          />
          <LoadingButton
            disabled={!saveable}
            onClick={() => dispatch(actions.persistAutomation())}
            loading={persisting}
          >
            {t('Save')}
          </LoadingButton>
        </Right>
      </div>
      {emailModalProps.show ? (
        <AutomationEmailModal automation={automation} {...emailModalProps} />
      ) : null}
      {calendarModalProps.show ? (
        <BusinessCalendarModal
          automation={automation}
          {...calendarModalProps}
        />
      ) : null}
    </Wrapper>
  );
}

export const FLOW_BAR_HEIGHT = 55; // Aligns with bottom of control bar

const FlowBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: ${FLOW_BAR_HEIGHT}px;
  padding: 0 15px;
  > div {
    display: flex;
    justify-content: space-between;
    flex: 1;
    max-width: ${CONTENT_MAX_WIDTH};
  }
`;

const StatsPanel = styled.div`
  > * {
    margin-top: 18px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const ZoomPanel = styled.div`
  > * {
    margin-top: 15px;
  }
`;

export const FlowBar = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const automation = useAutomationsSelector(getAutomation);
  const stats = useAutomationsSelector((state) => state.stats);
  const showStats = useAutomationsSelector((state) => state.showStats);
  const { zoomIn, zoomOut } = useReactFlow();

  const statsActive = Boolean(showStats && stats);

  const statsConfig = useMemo(
    () => getAutomationStatsConfig(automation, statsActive, t),
    [automation, statsActive, t]
  );

  return (
    <FlowBarWrapper {...props} ref={ref}>
      <div>
        <StatsPanel data-qa={statsActive ? 'automation-stats' : undefined}>
          {statsConfig.map(({ label, tooltipLabel, value, href, status }) => (
            <Statistic
              key={status}
              label={label}
              tooltipLabel={tooltipLabel}
              value={value}
              href={href}
              status={status}
            />
          ))}
        </StatsPanel>
        <ZoomPanel>
          <ZoomControl
            onClickPlus={() =>
              zoomIn({
                duration: 0,
              })
            }
            onClickMinus={() =>
              zoomOut({
                duration: 0,
              })
            }
          />
        </ZoomPanel>
      </div>
    </FlowBarWrapper>
  );
});
