import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { availableLanguages } from 'i18n-config';
import useSearchParam from 'hooks/useSearchParam';

import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import loginCounter from './LoginCounter';
import { LoginProvider, useLoginState } from './LoginContext';
import { ChooseEmail } from './ChooseEmail';
import { Password } from './Password';

const PageChooser = () => {
  const { email } = useLoginState();

  return email ? <Password /> : <ChooseEmail />;
};

const LoginPage = ({ title }) => {
  const { t, i18n } = useTranslation();
  useSetTitleOnLoad(title(t));

  /* FOR QA PURPOSES ONLY, this should disappear after translation approach settles */
  const language = useSearchParam('language');
  useEffect(() => {
    if (availableLanguages.map(({ value }) => value).includes(language)) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);
  /** **** */

  useEffect(() => {
    loginCounter.init();
  }, []);

  return (
    <LoginProvider>
      <PageChooser />
    </LoginProvider>
  );
};

export default LoginPage;
