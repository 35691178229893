import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomObjectWizard from 'components/Wizards/CustomObject';
import FieldService from 'services/FieldService';
import CustomObjectsService from 'services/CustomObjectsService';
import {
  getToastConfig,
  isContact,
  saveStep,
} from 'components/Wizards/CustomObject/utilities';
import { useToast } from 'components/ToastProvider';
import { PermissionsError } from 'services/errors';
import { serviceRefreshAccessStart } from 'store/authentication/authenticationAction';
import { useDispatch } from 'react-redux';
import Loader from 'components/Kizen/Loader';
import { PageContent } from './styles';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { getBackButtonTitle } from './helpers';
import { useQuery } from 'react-query';
import { CUSTOM_OBJECTS } from 'queries/query-keys';

const CustomObjectSettingsPage = () => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const history = useHistory();
  const { objectId } = useParams();
  const dispatch = useDispatch();

  const [isAddingContext, setIsAddingContext] = useState(objectId === 'new');

  const [navigationState] = useState(() => {
    const params = new URLSearchParams(history.location.search);
    return {
      from: params.get('from') || history.location?.state?.from || null,
      fromKey:
        params.get('fromKey') || history.location?.state?.fromKey || null,
      focusStepKey:
        params.get('focusStepKey') ||
        history.location?.state?.focusStepKey ||
        null,
      customLayoutTabKey:
        params.get('customLayoutTabKey') ||
        history.location?.state?.customLayoutTabKey ||
        null,
      fieldId:
        params.get('fieldId') || history.location?.state?.fieldId || null,
    };
  });

  const {
    data: baseModel,
    isLoading: loading,
    refetch: refetchModel,
  } = useQuery({
    queryKey: CUSTOM_OBJECTS.MODEL(objectId),
    queryFn: async () => {
      if (!objectId || objectId === 'new') {
        return null;
      }
      const model = await FieldService.getModel({ id: objectId });
      return model;
    },
  });

  const model = useMemo(() => {
    if (!baseModel) {
      return baseModel;
    }
    const intialAssociationSourceFieldIds = (
      baseModel.associationSourceFields || []
    ).map(({ id }) => id);

    const associationSourceObjects = (
      baseModel.associationSourceFields || []
    ).reduce((prev, { relatedObject }) => {
      if (!prev?.find((o) => relatedObject.id === o.value)) {
        prev.push({
          value: relatedObject.id,
          label: relatedObject.objectName,
        });
      }
      return prev;
    }, []);

    return {
      ...baseModel,
      associationSourceObjects,
      associationSourceFields: intialAssociationSourceFieldIds,
    };
  }, [baseModel]);

  useSetTitleOnLoad(
    objectId === 'new'
      ? t('New Custom Object')
      : t('{{objectName}} Settings', model)
  );

  const onSaveButtonClick = useCallback(
    async (payload, model, activeStep) => {
      const { id } = await saveStep(payload, model, activeStep);
      if (!model) {
        history.replace({
          pathname: `/custom-objects/${id}/settings`,
          search: history.location.search,
          state: history.location.state,
        });
        setIsAddingContext(true);
      }
      if (model === null) {
        dispatch(serviceRefreshAccessStart());
      }
      showToast(getToastConfig(t, !model, isContact(model)).success);
    },
    [showToast, dispatch, t, history]
  );

  const handleDeleteCustomObject = useCallback(async () => {
    try {
      await CustomObjectsService.deleteCustomObject({ id: objectId });
      showToast(getToastConfig(t).successDeleted);
    } catch (error) {
      showToast(getToastConfig(t).errorDeleted);
    }
  }, [objectId, showToast, t]);

  const handleRedirectOnDeletion = useCallback(() => {
    history.replace(`/custom-objects`);
  }, [history]);

  useEffect(() => {
    const prereleaseFeatureOverride = new URLSearchParams(
      history.location.search
    ).get('prerelease_feature_override');
    history.replace(
      prereleaseFeatureOverride
        ? `${history.location.pathname}?prerelease_feature_override=${prereleaseFeatureOverride}`
        : history.location.pathname,
      navigationState
    );
  }, [history, navigationState]);

  const backButtonTitle = getBackButtonTitle(
    t,
    history.location.state?.fromKey
  );

  const goBackPath =
    history.location.state?.from ||
    (objectId === 'new' ? '/custom-objects' : `/custom-objects/${objectId}`);

  const handleHide = useCallback(
    () => history.push(goBackPath),
    [goBackPath, history]
  );

  if (model && !model.access.edit) {
    throw new PermissionsError();
  }

  const objectIdRef = useRef(objectId);

  return (
    <PageContent>
      <Loader loading={!model && objectId !== 'new'}>
        <CustomObjectWizard
          backButtonTitle={backButtonTitle}
          show
          isFetching={loading}
          isCreateMode={objectId === 'new'}
          goBackPath={goBackPath}
          onHide={handleHide}
          model={model}
          onConfirm={onSaveButtonClick}
          refetchCustomObject={refetchModel}
          isAddingContext={isAddingContext}
          objectIdRef={objectIdRef}
          setIsAddingContext={setIsAddingContext}
          handleDeleteCustomObject={handleDeleteCustomObject}
          redirectOnDeleteHandler={handleRedirectOnDeletion}
        />
      </Loader>
    </PageContent>
  );
};

export default CustomObjectSettingsPage;
