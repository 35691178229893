import { useTranslation } from 'react-i18next';
import { StyledCols } from '../../styles';
import { KizenTypography } from '__app/typography';

export const Titles = ({ forUpdate = false }: { forUpdate?: boolean }) => {
  const { t } = useTranslation();
  return (
    <StyledCols columns={4} gutter="20px">
      <KizenTypography weight="bold">
        {t('Kizen Field Name (API Name)')}
      </KizenTypography>
      <KizenTypography weight="bold">
        {forUpdate ? t('Variable(s)') : t('Variable')}
      </KizenTypography>
      <KizenTypography weight="bold">
        {t('Conflict Resolution')}
      </KizenTypography>
      {forUpdate ? (
        <KizenTypography weight="bold">
          {t('Create Field Option?')}
        </KizenTypography>
      ) : null}
    </StyledCols>
  );
};
