import { Icon } from '@kizen/kds/Icon';
import { Typography } from '@kizen/kds/Typography';
import {
  formatDateFns2,
  getScheduledTimezoneAdjusted,
  isTimezoneEqualToLocal,
} from '__app/timezone';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBusinessTimeZone } from 'store/authentication/selectors';
import ct from 'countries-and-timezones';

const toLocalTimezone = (date: Date | string, businessTimezone: string) =>
  getScheduledTimezoneAdjusted(new Date(date), businessTimezone, true);

export const TimezoneWarningText = ({ children }: PropsWithChildren) => (
  <div className="flex gap-10">
    <Icon icon="custom-globe" size="md" color={'gray-600'} />
    <Typography size="sm" color={'gray-600'} fontStyle="italic">
      {children}
    </Typography>
  </div>
);

export type UseTimezoneWarningReturn = {
  date: Date | string;
  businessTimezone: string;
  isBusinessTimeMatchesLocal: boolean;
  timezoneWarning: string;
  businessTimeZoneString: string;
  localDateString: string;
};

export const useTimesoneWarning = (date: Date | string) => {
  const { t } = useTranslation();

  const businessTimezone = useSelector(getBusinessTimeZone);

  return useMemo(() => {
    const isBusinessTimeMatchesLocal = Boolean(
      !date || !businessTimezone || isTimezoneEqualToLocal(businessTimezone)
    );

    const businessTimeZoneString = `GMT${
      ct.getTimezone(businessTimezone).utcOffsetStr
    }`;

    const localDateString = date
      ? formatDateFns2(toLocalTimezone(date, businessTimezone), 'LLLL d, y p')
      : '';

    const timezoneWarning = isBusinessTimeMatchesLocal
      ? ''
      : `${t(
          'Your timezone differs from the business timezone ({{ businessTimeZoneString }}). This will be scheduled at {{ localDateString }} in your timezone.',
          {
            businessTimeZoneString,
            localDateString,
          }
        )}`;

    return {
      date,
      businessTimezone,
      isBusinessTimeMatchesLocal,
      timezoneWarning,
      businessTimeZoneString,
      localDateString,
    };
  }, [t, businessTimezone, date]);
};

type TimezonwWarningProps = {
  date: Date | string;
  composeMessage?: (props: UseTimezoneWarningReturn) => string;
};

export const TimezoneWarning = ({
  date,
  composeMessage,
}: TimezonwWarningProps) => {
  const result = useTimesoneWarning(date);

  return result.isBusinessTimeMatchesLocal ? null : (
    <TimezoneWarningText>
      {composeMessage ? composeMessage(result) : result.timezoneWarning}
    </TimezoneWarningText>
  );
};
