export const MIN_COLUMN_WIDTH = '170px';

export const CONFIRMATION_TYPES = {
  SAVE: 'save',
  SAVE_NOTIFY: 'save-and-notify',
};

export const INVALID_SORT_ERROR_MESSAGE = 'Invalid ordering';

export const defaultColumnSettings = [
  {
    id: 'activityType',
    label: 'Activity Name',
    localizedLabel: (t) => t('Activity Name'),
    order: 1,
    visible: true,
    related: false,
  },
  {
    id: 'client',
    label: 'Contact',
    localizedLabel: (t) => t('Contact'),
    order: 2,
    visible: true,
    related: true,
  },
  {
    id: 'employee',
    label: 'Assignee',
    localizedLabel: (t) => t('Assignee'),
    order: 3,
    visible: true,
    related: false,
  },
  {
    id: 'dueDateMyTime',
    label: 'Due Date (My Time)',
    localizedLabel: (t) => t('Due Date (My Time)'),
    order: 4,
    visible: true,
    related: false,
  },
  {
    id: 'dueDateBusinessTime',
    label: 'Due Date (Business Time)',
    localizedLabel: (t) => t('Due Date (Business Time)'),
    order: 4,
    visible: false,
    related: false,
  },
];

export const predefinedFieldsList = [
  'activityType',
  'employee',
  'notifications',
];

export const DEFAULT_PAGE_SIZE = 50;
