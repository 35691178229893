import { useModalControl } from 'hooks/useModalControl';
import { useCallback, useState } from 'react';
import ToolbarService from 'services/ToolbarService';
import { DEFAULT_TEMPLATE_ID } from 'components/Modals/ApplyTemplateModal';
import { maybeInjectPermanentItems } from './useRightColumn';
import useToolbarAccessFiltering from './useToolbarAccessFiltering';
import { monitoringExceptionHelper } from 'sentry/helpers';

const useLoadTemplate = (setItems, setDirty, reset) => {
  const [isOpen, { showModal, hideModal }] = useModalControl();
  const [disabled, setDisabled] = useState(false);
  const [pending, setPending] = useState(false);

  const filterToolbar = useToolbarAccessFiltering(true);

  const handleConfirm = useCallback(
    async (chosenId) => {
      setDisabled(true);
      setPending(true);
      try {
        if (chosenId === DEFAULT_TEMPLATE_ID) {
          reset();
        } else {
          // await the action of replacing the template
          const template = await ToolbarService.getTemplate(chosenId);
          const toolbarForMe = filterToolbar(
            maybeInjectPermanentItems(template?.configuration_json ?? [], true)
          );
          setItems(toolbarForMe);
          setDirty(true);
        }
      } catch (err) {
        monitoringExceptionHelper(err);
      } finally {
        hideModal();
        setDisabled(false);
        setPending(false);
      }
    },
    [hideModal, setItems, setDirty, reset, filterToolbar]
  );

  return {
    modalProps: {
      isOpen,
      show: isOpen,
      onHide: hideModal,
      onConfirm: handleConfirm,
      disabled,
    },
    handleClickChooseTemplate: showModal,
    loadTemplate: handleConfirm,
    pending,
  };
};

export default useLoadTemplate;
