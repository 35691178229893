import { forwardRef, useState } from 'react';
import Wizard from '../shared/components/wizard';
import ChartTypeWizardSection from './sections/ChartType';
import DisplaySettingsWizardSection from './sections/DisplaySettings';
import ValuesAndConstraintsWizardSection from './sections/ValuesAndConstraints';
import { AREA_RESPONSES } from './types';

const isStep2Current = ({ field, reportType, salesReportType }) => {
  if (
    reportType?.value === AREA_RESPONSES.TABLE ||
    reportType?.value === AREA_RESPONSES.PIVOT_TABLE
  ) {
    return true;
  }

  if (reportType?.value === AREA_RESPONSES.SALES) {
    return Boolean(salesReportType);
  }

  return Boolean(reportType) && Boolean(field);
};

const isStep3Current = ({
  reportType,
  field,
  value,
  datapointFrequency,
  customEndDate,
  endDate,
}) => {
  if (reportType?.value === AREA_RESPONSES.SALES) {
    return (
      Boolean(value) &&
      Boolean(datapointFrequency) &&
      (customEndDate ? Boolean(endDate) : true)
    );
  }
  return Boolean(reportType) && Boolean(field);
};

const disableDisplaySettings = ({ reportType, ...rest }) => {
  return (
    reportType?.value === AREA_RESPONSES.TABLE ||
    reportType?.value === AREA_RESPONSES.PIVOT_TABLE
  );
};

const RTDVWizard = forwardRef(
  ({ objectId, contacts, clientObjectId, ...props }, ref) => {
    const { homepage } = props;
    const [selectedObjectId, setSelectedObjectId] = useState(objectId);
    const [isContacts, setIsContacts] = useState(contacts);

    return (
      <Wizard
        ref={ref}
        controllers={[isStep2Current, isStep3Current]}
        charts
        {...props}
      >
        <ChartTypeWizardSection
          objectId={selectedObjectId}
          originalObjectId={objectId}
          contacts={isContacts}
          setSelectedObjectId={setSelectedObjectId}
          setIsContacts={setIsContacts}
          clientObjectId={clientObjectId}
          homepageSelectedObjectId={selectedObjectId}
          homepage={homepage}
        />
        <ValuesAndConstraintsWizardSection
          objectId={selectedObjectId}
          contacts={isContacts}
        />
        <DisplaySettingsWizardSection
          objectId={selectedObjectId}
          contacts={isContacts}
          disabled={disableDisplaySettings}
        />
      </Wizard>
    );
  }
);

export default RTDVWizard;
