import { usePermissionsContext } from './context';
import { useReactive } from './useReactive';

type RuleResultLike = { result: boolean; value?: any } | { result: false };

export const useIsRuleApplied = (key: string) => {
  const ctx = usePermissionsContext();
  const rule = useReactive(
    ctx.rules.get(key)?.result! as any // TODO: need to do something about the { result: false } type in Rules - causes all sorts of issues.
  ) as RuleResultLike;
  return rule?.result ?? false;
};
