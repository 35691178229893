import { useCallback, useMemo } from 'react';
import { DASHBOARD } from 'queries/query-keys';
import { useQuery } from 'react-query';
import { FIELD_TYPES } from 'utility/constants';
import { getCurrencySymbol } from 'components/Fields/helpers';
import CustomObjectsService from 'services/CustomObjectsService';

const usePipelineCurrencyData = (
  pipelineDetail = {},
  opts = {},
  enabled = true
) => {
  const { id: pipelineId, trackEntityValue } = pipelineDetail;

  const fetchFields = useCallback(async () => {
    const fields = await CustomObjectsService.getCustomModelFields(
      pipelineId,
      opts
    );

    return fields;
  }, [pipelineId, opts]);

  const { isLoading: fieldsLoading, data: fieldsData } = useQuery(
    [...DASHBOARD.DASHLET_OBJECT_FIELDS, pipelineId ?? ''],
    fetchFields,
    {
      enabled: Boolean(pipelineId) && enabled,
    }
  );

  const currencyResult = useMemo(() => {
    if (!fieldsLoading && fieldsData && trackEntityValue) {
      const currencyField = fieldsData.find(
        (f) => f.fieldType === FIELD_TYPES.Money.type
      );
      if (currencyField) {
        return getCurrencySymbol(currencyField) ?? '';
      }
    }
    return '';
  }, [fieldsLoading, fieldsData, trackEntityValue]);

  return {
    currencySymbol: currencyResult,
    loading: fieldsLoading,
  };
};

export default usePipelineCurrencyData;
