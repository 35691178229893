import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getDateWithTimezone } from 'app/timezone';
import { colorsSecondary } from 'app/colors';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import LongTextEllipsis from '../LongTextEllipsis';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import {
  formatTimestamp,
  defaultEmployeeString,
  defaultAutomationString,
} from '../helpers';
import { TeamMemberBlock } from '../components/TeamMemberBlock';
import { CardHeader } from '../components/CardHeader';
import { initiatorTypes } from '../../constants';
import { RoleBlock } from '../components/RoleBlock';
import { HtmlContainer } from '../FieldsTable';
import { AssociationsBlock } from '../components/AssosiationBlock';
import { isEqual } from 'lodash';

const cardHeaderConfig = (t) => {
  const prefix = t('scheduled activity updated by');
  return {
    [initiatorTypes.employee]: {
      prefix,
      creationType: defaultEmployeeString,
    },
    [initiatorTypes.employee_api_request]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via API'),
    },
    [initiatorTypes.automation]: {
      prefix,
      creationType: defaultAutomationString,
    },
  };
};

const ScheduledActivityUpdatedEventBlock = ({ event, business, ...others }) => {
  const { t } = useTranslation();
  const changedFields = useMemo(() => {
    return Object.keys(event.data).reduce((acc, key) => {
      if (
        event.data[key]?.old &&
        event.data[key]?.new &&
        !isEqual(event.data[key].old, event.data[key].new)
      ) {
        if (
          key === 'dueDatetime' &&
          getDateWithTimezone(
            event.data[key].old,
            Intl.DateTimeFormat().resolvedOptions().timeZone
          ) ===
            getDateWithTimezone(
              event.data[key].new,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            )
        )
          return acc;
        acc[key] = event.data[key];
      }
      return acc;
    }, {});
  }, [event]);

  const withoutChangesFields = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(event.data).filter(
          ([k, v]) => !Object.hasOwn(changedFields, k)
        )
      ),
    [event, changedFields]
  );

  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsSecondary.aqua.dark}
          icon="clipboard-list"
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={event.data.activityName}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    >
      {Object.entries(changedFields).map(([key, value], i) => {
        switch (key) {
          case 'assignment':
            return (
              <>
                <TextEllipsisWithTooltip weight="bold">
                  {t('Old Assignee')}
                </TextEllipsisWithTooltip>
                {value.old.employee ? (
                  <TeamMemberBlock
                    data-qa-field="assignee-old"
                    item={{
                      value: value.old.employee,
                      type: 'selector',
                    }}
                  />
                ) : (
                  <RoleBlock
                    data-qa-field="assignee-old"
                    item={{ value: value.old.role }}
                  />
                )}
                <TextEllipsisWithTooltip weight="bold">
                  {t('New Assignee')}
                </TextEllipsisWithTooltip>
                {value.new.employee ? (
                  <TeamMemberBlock
                    data-qa-field="assignee-new"
                    item={{
                      value: value.new.employee,
                      type: 'selector',
                    }}
                  />
                ) : (
                  <RoleBlock
                    data-qa-field="assignee-new"
                    item={{ value: value.new.role }}
                  />
                )}
              </>
            );
          case 'dueDatetime': {
            const oldDate = getDateWithTimezone(
              value.old,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            const newDate = getDateWithTimezone(
              value.new,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            return (
              <>
                <TextEllipsisWithTooltip weight="bold">
                  {t('Old Due Date')}
                </TextEllipsisWithTooltip>
                <LongTextEllipsis data-qa-field="due-date-old">
                  {formatTimestamp(oldDate)}
                </LongTextEllipsis>
                <TextEllipsisWithTooltip weight="bold">
                  {t('New Due Date')}
                </TextEllipsisWithTooltip>
                <LongTextEllipsis data-qa-field="due-date-new">
                  {formatTimestamp(newDate)}
                </LongTextEllipsis>
              </>
            );
          }
          case 'associations':
            return (
              <>
                <TextEllipsisWithTooltip weight="bold">
                  {t('Old Associations')}
                </TextEllipsisWithTooltip>
                <AssociationsBlock
                  data-qa-field="old-associations"
                  associations={value.old}
                />
                <TextEllipsisWithTooltip weight="bold">
                  {t('New Associations')}
                </TextEllipsisWithTooltip>
                <AssociationsBlock
                  data-qa-field="new-associations"
                  associations={value.new}
                />
              </>
            );
          default:
            return null;
        }
      })}
      {Object.entries(withoutChangesFields).map(([key, value], i) => {
        switch (key) {
          case 'assignment':
            return (
              <>
                <TextEllipsisWithTooltip weight="bold">
                  {t('Assignee')}
                </TextEllipsisWithTooltip>
                {value.old.employee ? (
                  <TeamMemberBlock
                    data-qa-field="assignee"
                    item={{
                      value: value.old.employee,
                      type: 'selector',
                    }}
                  />
                ) : (
                  <RoleBlock
                    data-qa-field="assignee"
                    item={{ value: value.old.role }}
                  />
                )}
              </>
            );
          case 'dueDatetime': {
            const date = getDateWithTimezone(
              value.old,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            return (
              <>
                <TextEllipsisWithTooltip weight="bold">
                  {t('Due Date (My Time)')}
                </TextEllipsisWithTooltip>
                <LongTextEllipsis data-qa-field="due-date">
                  {formatTimestamp(date)}
                </LongTextEllipsis>
              </>
            );
          }
          case 'associations':
            return (
              <>
                <TextEllipsisWithTooltip weight="bold">
                  {t('Associations')}
                </TextEllipsisWithTooltip>
                <AssociationsBlock
                  data-qa-field="associations"
                  associations={value.old}
                />
              </>
            );
          default:
            return null;
        }
      })}
      {event.data?.note ? (
        <>
          <TextEllipsisWithTooltip weight="bold">
            {t('Notes')}
          </TextEllipsisWithTooltip>
          <HtmlContainer
            data-qa-field="note"
            isExpandable
            dangerouslySetInnerHTML={{ __html: event.data.note }}
            className="note"
          />
        </>
      ) : null}
    </BasicBlock>
  );
};

export default ScheduledActivityUpdatedEventBlock;
