export type Padding = 0 | 20;
export type Spacing = 0 | 10 | 15 | 20 | 30;

export const getGapClassName = (gap: Spacing) => {
  switch (gap) {
    case 10:
      return 'gap-10';
    case 15:
      return 'gap-15';
    case 20:
      return 'gap-20';
    case 30:
      return 'gap-30';
    default:
      return '';
  }
};

export const getPaddingClassName = (gap: Spacing) => {
  switch (gap) {
    case 10:
      return 'p-spacer-10';
    case 15:
      return 'p-spacer-15';
    case 20:
      return 'p-spacer-20';
    case 30:
      return 'p-spacer-30';
    default:
      return '';
  }
};
