import React from 'react';
import * as PropTypes from 'prop-types';
import {
  CloudTypes,
  pricingTableMap,
  priceTypes,
} from 'pages/Subscription/model';
import KizenTypography from 'app/kizentypo';
import { TableRow, TableCell } from '../../styles';

export const calculatePrice = (price, values, key) => {
  if (key === CloudTypes.MARKETING) {
    return (price * values.num_of_contacts) / 1000;
  }
  return price;
};

const BreakdownTable = ({ values }) => {
  return (
    <>
      {Object.keys(pricingTableMap).map((key) => {
        const cloudName = pricingTableMap[key].name;
        const { pricings } = pricingTableMap[key];

        return pricings.map((pricing, index) => {
          const { levels, type } = pricing;

          if (key === CloudTypes.BUSINESS) {
            return (
              (values.business_intelligence_included ||
                values.business_intelligence) && (
                <TableRow>
                  <TableCell className="table-cell">
                    <KizenTypography>{levels.label}</KizenTypography>
                  </TableCell>
                  <TableCell className="table-cell">
                    <KizenTypography>{levels.price}</KizenTypography>
                  </TableCell>
                </TableRow>
              )
            );
          }

          const priceLabel = priceTypes.MONTHLY.label;

          if (values[cloudName]) {
            const levelValue = levels[values.business_level];
            const { price, label } = levelValue;

            let pricingEstimate = null;
            if (type === priceTypes.MONTHLY) {
              pricingEstimate = price;
            } else {
              const users =
                key === CloudTypes.MARKETING
                  ? values.num_contacts / 1000
                  : key === CloudTypes.SALES
                    ? values.num_sales_users
                    : values.num_experience_users;

              pricingEstimate =
                type === priceTypes.MONTHLY ? price : price * users;
            }

            return (
              <TableRow key={index}>
                <TableCell className="table-cell">
                  <KizenTypography>{label}</KizenTypography>
                </TableCell>
                <TableCell className="table-cell">
                  <KizenTypography>
                    ${pricingEstimate}
                    {priceLabel}
                  </KizenTypography>
                </TableCell>
              </TableRow>
            );
          }
          return null;
        });
      })}
    </>
  );
};

BreakdownTable.propTypes = {
  values: PropTypes.object.isRequired,
};

export default BreakdownTable;
