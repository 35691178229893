import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import rootSaga from './sagas';
import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

let middlewares = [sagaMiddleware];

if (import.meta.env.DEV) {
  const logger = createLogger({ collapsed: true });
  middlewares = [...middlewares, logger];
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

export default store;

sagaMiddleware.run(rootSaga);
