import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FieldService from 'services/FieldService';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import Select from 'components/Inputs/Select';
import KizenTypography from 'app/kizentypo';
import { Overlay } from 'react-bootstrap';
import { useFlashTransition } from 'hooks/useFlashState';
import { modalConstrainPopperConfig } from 'components/Inputs/Validation';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { useAsync } from 'react-use';
import {
  ServiceByType,
  decideErrorMessage,
  getApplyTemplateDictionary,
  getApplyTemplateHandler,
  getSuccessToast,
} from './helpers';
import { ErrorCard, StyledModal, Summary } from './styles';
import { toastVariant, useToast } from 'components/ToastProvider';
import { TEMPLATES_TYPES } from '../constants';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const CustomObjectsSelect = ({ objectId, setSelectedObject }) => {
  const { t } = useTranslation();
  const clientObject = useSelector(getBusinessClientObject);
  const { value: objects = EMPTY_ARRAY, loading: loadingObjects } =
    useAsync(() => {
      return FieldService.getModelsWithPriority({
        includeCustomObjects: true,
        clientObjectId: clientObject.id,
      });
    }, []);

  return (
    <Select
      label={t('Choose Object')}
      placeholder={t('Choose Object')}
      menuInline
      loadItems={loadingObjects}
      value={objectId}
      onChange={setSelectedObject}
      options={objects}
      margin
    />
  );
};

export const ApplyTemplateModal = ({
  templateType,
  onHide,
  data,
  applyTo,
  ...props
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [selectedObject, setSelectedObject] = useState(undefined);
  const objectId = selectedObject?.value;

  const fetch = useCallback(
    ({ search = '', page = 1, objectId }, options) => {
      return ServiceByType[templateType].listTemplates(
        page,
        20,
        'name',
        search,
        ...(templateType !== TEMPLATES_TYPES.toolbars ? [objectId] : []),
        {
          ...options,
          skipErrorBoundary: true,
          params: {
            include_private: true,
          },
        }
      );
    },
    [templateType]
  );
  const selectRef = useRef(null);

  const [template, setTemplate] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, showError, flashError] = useFlashTransition();

  const handleChangeObject = (v) => {
    setSelectedObject(v);
    setTemplate(null);
  };

  const params = useMemo(
    () => ({ templateType, objectId, include_private: true }),
    [templateType, objectId]
  );

  const [typeaheadProps, typeaheadState] = useSelectTypeaheadWithScroll({
    entity: Entities.ToolbarTemplates,
    fetch,
    alwaysOpen: false,
    selectRef,
    params,
    shouldResetOptions: false,
    keepPreviousData: false,
  });

  const { heading, summary, selectLabel, selectPlaceholder } = useMemo(
    () => getApplyTemplateDictionary(templateType, data, applyTo, t),
    [templateType, data, applyTo, t]
  );

  const onConfirm = async () => {
    try {
      setSubmitting(true);
      const handler = getApplyTemplateHandler(templateType, applyTo);
      await handler(
        template?.value,
        [data.id],
        ...(templateType !== TEMPLATES_TYPES.toolbars ? [objectId] : []),
        {
          skipErrorBoundary: true,
        }
      );
      showToast({
        message: getSuccessToast(templateType, data, applyTo, template, t),
        variant: toastVariant.SUCCESS,
      });
      onHide();
    } catch (err) {
      flashError(decideErrorMessage(err));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StyledModal
      heading={heading}
      buttonText={t('Apply')}
      disabled={submitting || !template}
      onConfirm={onConfirm}
      size="small"
      onHide={onHide}
      {...props}
    >
      <Summary>{summary}</Summary>
      {templateType !== TEMPLATES_TYPES.toolbars ? (
        <CustomObjectsSelect
          objectId={objectId}
          setSelectedObject={handleChangeObject}
        />
      ) : null}
      {objectId || templateType === TEMPLATES_TYPES.toolbars ? (
        <Select
          ref={selectRef}
          label={selectLabel}
          placeholder={selectPlaceholder}
          menuInline
          {...typeaheadProps}
          loadItems={typeaheadState.loading}
          value={template}
          onChange={setTemplate}
        />
      ) : null}
      <Overlay
        transition={false}
        target={selectRef.current}
        show={error && typeof error === 'string'}
        placement="bottom-start"
        popperConfig={modalConstrainPopperConfig}
      >
        <ErrorCard light show={showError} duration="300ms">
          <KizenTypography>{error}</KizenTypography>
        </ErrorCard>
      </Overlay>
    </StyledModal>
  );
};
