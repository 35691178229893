import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from '@kizen/kds/Button';
import { Icon } from '@kizen/kds/Icon';
import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';
import { merge } from '@kizen/kds/util';
import { v4 } from 'uuid';
import { COLUMN_SIZE } from '@kizen/kds/Grid';
import useModal from '__components/Modals/useModal';
import { RowModal } from './RowModal';
import { useTranslation } from 'react-i18next';
import LayoutComponentWizard from '__components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard';
import { StyledCols } from '__components/Wizards/CustomObject/steps/CustomLayout/styles';
import { VerticalSpacer } from 'components/Wizards/CustomObject/steps/CustomLayout/styles';
import { RecordLayoutPreview } from '../Preview';
import ConfirmDeletionModal from '__components/Modals/presets/ConfirmDeletion';
import {
  IngestibleLayout,
  IngestibleLayouts,
  LayoutOption,
  LayoutState,
  CategoryOptions,
} from './types';
import { GridBuilder } from './GridBuilder';
import { HeaderPreview } from './HeaderPreview';
import Select from '__components/Inputs/Select';
import Switch from '__components/Kizen/Switch';
import Input from '__components/Kizen/Input';
import { RecordLayoutModal } from './RecordLayoutModal';
import { RecordLayoutListModal } from './RecordLayoutListModal';
import { useMutation } from 'react-query';
import CustomObjectService from 'services/CustomObjectsService';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import {
  blockLabels,
  buildSharingSettings,
  getDefaultLayout,
  getIndices,
  layoutToOptions,
} from './util';
import { DragItem, DragItemProps } from './DragItem';
import { useFlashTransition } from 'hooks/useFlashState';
import Validation from '__components/Inputs/Validation';
import { Tooltip, useTooltip } from '@kizen/kds/Tooltip';
import { PermissionSection } from './PermissionSection';
import { useSelector } from 'react-redux';

interface DataRendererProps {
  handleChange: (...args: any[]) => any;
  formData: any;
  layouts: IngestibleLayouts;
  activeLayouts: IngestibleLayouts;
  currentLayout?: LayoutOption;
  setCurrentLayout: (...args: any[]) => any;
  activeLayout?: LayoutState;
  activeLayoutName?: string;
  revision: number;
  setRevision: Dispatch<SetStateAction<number>>;
  objectId: string;
  refetchLayouts: (...args: any[]) => any;
  clearStagedChanges: (...args: any[]) => any;
  dirty: boolean;
  handleSaveLayoutChanges: () => Promise<void>;
  layoutErrors: any;
  setLayoutErrors: Dispatch<SetStateAction<any>>;
  activeLayoutShowAutomationsTab: boolean;
  canUseRelatedPipelinesBlock: boolean;
  categorizedFields: CategoryOptions;
  secondarySettingsContainer?: RefObject<HTMLDivElement>;
  activeSharingSettings?: any;
  activeLayoutIsActive: boolean;
  activeLayoutShowMessagesTab: boolean;
  isClient: boolean;
}

const typesToMungeMetadata = [
  'fields',
  'timeline',
  'related_pipelines',
  'action_block',
];

const getExisting = (editingItem: any) => {
  if (editingItem?.metadata) {
    const { metadata, type, ...rest } = editingItem;

    return typesToMungeMetadata.includes(type)
      ? {
          meta: metadata,
          ...rest,
          type: type,
        }
      : {
          ...metadata,
          type: type,
        };
  }
  return undefined;
};
export const DataRenderer = (props: DataRendererProps) => {
  const {
    handleChange,
    formData,
    layouts,
    currentLayout,
    setCurrentLayout,
    activeLayout,
    activeLayoutName,
    activeLayoutIsActive,
    revision,
    setRevision,
    objectId,
    refetchLayouts,
    clearStagedChanges,
    dirty,
    handleSaveLayoutChanges,
    layoutErrors,
    setLayoutErrors,
    activeLayoutShowAutomationsTab,
    canUseRelatedPipelinesBlock,
    categorizedFields,
    secondarySettingsContainer,
    activeSharingSettings,
    activeLayoutShowMessagesTab,
    isClient,
  } = props;

  const nameInputRef = useRef<HTMLInputElement>(null);
  const teamMember = useSelector((s: any) => s.authentication.teamMember);

  const [message, showMessage, flashErrorMessage] = useFlashTransition();

  const { t } = useTranslation();
  const [value, setValue] = useState<LayoutState>(activeLayout ?? []);
  const [addRowModalProps, , addRowModalControl] = useModal();
  const [showLayoutComponentWizard, setShowLayoutComponentWizard] =
    useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [editId, setEditId] = useState<string | undefined>();
  const [nameValue, setNameValue] = useState(activeLayoutName);
  const [showAutomations, setShowAutomations] = useState(
    activeLayoutShowAutomationsTab
  );
  const [showMessages, setShowMessages] = useState(activeLayoutShowMessagesTab);
  const [isActive, setIsActive] = useState(activeLayoutIsActive);

  const editingItem = useMemo(() => {
    if (editId) {
      const {
        row: rowIndex,
        column: columnIndex,
        item: itemIndex,
      } = getIndices(editId, value);

      if (rowIndex >= 0 && columnIndex >= 0 && itemIndex >= 0) {
        return value[rowIndex].columns[columnIndex].items[itemIndex];
      }
    }
    return undefined;
  }, [editId, value]);

  useEffect(() => {
    setNameValue(activeLayoutName);
  }, [activeLayoutName, activeLayoutShowAutomationsTab]);

  useEffect(() => {
    setShowAutomations(activeLayoutShowAutomationsTab);
  }, [activeLayoutShowAutomationsTab]);

  useEffect(() => {
    setShowMessages(activeLayoutShowMessagesTab);
  }, [activeLayoutShowMessagesTab]);

  useEffect(() => {
    setIsActive(activeLayoutIsActive);
  }, [activeLayoutIsActive]);

  const selectRef = useRef<any>(null);

  const layoutManagementOptions = useMemo(() => {
    return layoutToOptions(layouts);
  }, [layouts]);

  const handleChangeName = useCallback(
    (value: string) => {
      setNameValue(value);
      handleChange(currentLayout?.value, value, undefined);
    },
    [currentLayout, handleChange]
  );

  useEffect(() => {
    if (layoutErrors?.name) {
      flashErrorMessage(layoutErrors?.name);
    }
  }, [layoutErrors?.name, flashErrorMessage]);

  const handleAddRow = useCallback(
    (values: COLUMN_SIZE[]) => {
      addRowModalControl.hide();
      setValue((prev) => {
        const newValue = [
          ...prev,
          {
            id: v4(),
            columns: values.map((v) => {
              return {
                id: v4(),
                width: v,
                items: [],
              };
            }),
          },
        ];

        handleChange(currentLayout?.value, undefined, newValue);

        return newValue;
      });
      setRevision((prev) => prev + 1);
    },
    [addRowModalControl, handleChange, currentLayout, setRevision]
  );

  const insertionTracker = useRef<{
    rowId: string;
    columnId: string;
  } | null>(null);
  const columnEndClickHandler = useCallback(
    (rowId: string, columnId: string) => {
      return () => {
        setShowLayoutComponentWizard(true);
        insertionTracker.current = { rowId, columnId };
      };
    },
    []
  );

  const handleCreateComponent = useCallback(
    (component: any, internalName: string, displayName: string) => {
      if (editId) {
        setValue((prev) => {
          const res: LayoutState = structuredClone(prev);

          const {
            row: rowIndex,
            column: columnIndex,
            item: itemIndex,
          } = getIndices(editId, res);

          if (rowIndex >= 0 && columnIndex >= 0 && itemIndex >= 0) {
            res[rowIndex].columns[columnIndex].items[itemIndex].metadata =
              component.meta;
            res[rowIndex].columns[columnIndex].items[itemIndex].displayName =
              displayName;
            res[rowIndex].columns[columnIndex].items[itemIndex].internalName =
              internalName;
          }

          handleChange(currentLayout?.value, nameValue, res);

          return res;
        });
      } else {
        setValue((prev) => {
          const res: LayoutState = structuredClone(prev);

          const rowIndex = res.findIndex(
            (row) => row.id === insertionTracker.current?.rowId
          );
          const columnIndex = res[rowIndex].columns.findIndex(
            (column) => column.id === insertionTracker.current?.columnId
          );

          res[rowIndex].columns[columnIndex].items.push({
            id: component.id,
            label: blockLabels[component.type]?.(t) ?? component.type,
            type: component.type,
            metadata: component.meta,
            internalName,
            displayName,
          });

          handleChange(currentLayout?.value, nameValue, res);

          return res;
        });
      }
      setEditId(undefined);
      setRevision((prev) => prev + 1);
    },
    [t, handleChange, editId, currentLayout, nameValue, setRevision]
  );

  const handleEditItem: DragItemProps['handleEdit'] = useCallback((id) => {
    setEditId(id);
    setShowLayoutComponentWizard(true);
  }, []);

  const handleSetVisibility: DragItemProps['handleSetVisibility'] = useCallback(
    (itemId, hidden) => {
      setValue((prev) => {
        const res: LayoutState = structuredClone(prev);

        const {
          row: rowIndex,
          column: columnIndex,
          item: itemIndex,
        } = getIndices(itemId, res);

        if (rowIndex >= 0 && columnIndex >= 0 && itemIndex >= 0) {
          res[rowIndex].columns[columnIndex].items[itemIndex].hidden = hidden;
        }

        handleChange(currentLayout?.value, nameValue, res);

        return res;
      });
      setEditId(undefined);
      setRevision((prev) => prev + 1);
    },
    [handleChange, currentLayout, nameValue, setRevision]
  );

  const handleDeleteComponent = useCallback(
    (id?: string) => {
      if (id) {
        setValue((prev) => {
          const res: LayoutState = structuredClone(prev);

          const {
            row: rowIndex,
            column: columnIndex,
            item: itemIndex,
          } = getIndices(id, res);

          if (rowIndex >= 0 && columnIndex >= 0 && itemIndex >= 0) {
            res[rowIndex].columns[columnIndex].items.splice(itemIndex, 1);
          }

          handleChange(currentLayout?.value, nameValue, res);

          return res;
        });
        setEditId(undefined);
        setRevision((prev) => prev + 1);
      }
    },
    [handleChange, currentLayout, nameValue, setRevision]
  );

  const skipSelectingActiveLayout = useRef<boolean>(false);
  const createMutation = useMutation(
    (payload: {
      config: LayoutState;
      name: string;
      active: boolean;
      order?: number;
      layout_sharing_settings: any;
    }) => {
      return CustomObjectService.createRecordLayout(objectId, payload);
    },
    {
      onSuccess: async (data: any) => {
        const { id, name } = data;
        await refetchLayouts();
        if (!skipSelectingActiveLayout.current) {
          setLayoutErrors(undefined);
          setCurrentLayout({
            value: id,
            label: name,
          });
        }
        skipSelectingActiveLayout.current = false;
      },
    }
  );

  const [newRecordError, setNewRecordError] = useState<any>();
  const handleSubmitNewRecordLayout = useCallback(
    async (args: {
      name: string;
      order?: number;
      layout?: LayoutState;
      permissions: any;
      active?: boolean;
    }) => {
      try {
        setNewRecordError(undefined);
        await createMutation.mutateAsync({
          name: args.name,
          config: args.layout ?? getDefaultLayout(),
          active: args.active ?? true,
          order: args.order,
          layout_sharing_settings: buildSharingSettings(args.permissions, [
            teamMember?.id,
          ]),
        });
      } catch (ex) {
        setNewRecordError((ex as any).response?.data);
        throw ex;
      }
    },
    [createMutation, teamMember?.id]
  );

  const handleSubmitListOfLayouts = useCallback(() => {}, []);
  const existingLayoutRef = useRef<IngestibleLayout & { order: number }>();

  // Creating a new record layout
  const [recordLayoutModalProps, , recordLayoutModal] = useModal({
    handleSubmit: handleSubmitNewRecordLayout,
    closeOnSubmit: false,
    handleHide: () => {
      setNewRecordError(undefined);
      existingLayoutRef.current = undefined;
    },
  });

  // Editing the list of record layouts
  const [recordLayoutListModalProps, , recordLayoutListModal] = useModal({
    handleSubmit: handleSubmitListOfLayouts,
    closeOnSubmit: false,
    handleHide: () => {
      existingLayoutRef.current = undefined;
    },
  });

  const handleClickAddRecordLayout = useCallback(
    (skipActive?: boolean) => {
      skipSelectingActiveLayout.current = skipActive ?? false;
      selectRef.current?.onMenuClose?.();
      selectRef.current?.select?.blur?.();
      recordLayoutModal.show();
    },
    [recordLayoutModal]
  );

  const handleClickModifyRecordLayouts = useCallback(() => {
    selectRef.current?.onMenuClose?.();
    selectRef.current?.select?.blur?.();
    recordLayoutListModal.show();
  }, [recordLayoutListModal]);

  const [unsavedChangesModalProps, , unsavedChangesModal] = useModal({});

  const pendingLayoutChange = useRef<
    { value: string; label: string } | undefined
  >();
  const nextAction = useRef<'manage' | 'add' | undefined>();

  const handleActionAfterConfirm = useCallback(() => {
    if (pendingLayoutChange.current) {
      setLayoutErrors(undefined);
      setCurrentLayout(pendingLayoutChange.current);
      pendingLayoutChange.current = undefined;
    } else if (nextAction.current === 'manage') {
      handleClickModifyRecordLayouts();
      nextAction.current = undefined;
    } else if (nextAction.current === 'add') {
      handleClickAddRecordLayout(false);
      nextAction.current = undefined;
    }
  }, [
    setCurrentLayout,
    setLayoutErrors,
    handleClickModifyRecordLayouts,
    handleClickAddRecordLayout,
  ]);

  const maybeChangeLayout = useCallback(
    (v: any) => {
      if (dirty) {
        pendingLayoutChange.current = v;
        selectRef.current?.onMenuClose?.();
        selectRef.current?.select?.blur?.();
        unsavedChangesModal.show();
      } else {
        setLayoutErrors(undefined);
        setCurrentLayout(v);
      }
    },
    [dirty, setCurrentLayout, unsavedChangesModal, setLayoutErrors]
  );

  const maybeManageLayouts = useCallback(() => {
    if (dirty) {
      nextAction.current = 'manage';
      unsavedChangesModal.show();
    } else {
      handleClickModifyRecordLayouts();
    }
  }, [handleClickModifyRecordLayouts, dirty, unsavedChangesModal]);

  const maybeAddLayout = useCallback(() => {
    if (dirty) {
      nextAction.current = 'add';
      unsavedChangesModal.show();
    } else {
      handleClickAddRecordLayout(false);
    }
  }, [dirty, handleClickAddRecordLayout, unsavedChangesModal]);

  const { triggerProps, tooltipProps, targetProps, showTooltip } =
    useTooltip(true);

  const handleSaveAfterConfirmation = useCallback(async () => {
    await handleSaveLayoutChanges();
    unsavedChangesModal.hide();
    handleActionAfterConfirm();
  }, [handleActionAfterConfirm, unsavedChangesModal, handleSaveLayoutChanges]);

  const handleDiscardAfterConfirmation = useCallback(() => {
    clearStagedChanges();
    unsavedChangesModal.hide();
    handleActionAfterConfirm();
    setValue(activeLayout ?? []);
    setRevision((prev) => prev + 1);
  }, [
    clearStagedChanges,
    unsavedChangesModal,
    activeLayout,
    setRevision,
    handleActionAfterConfirm,
  ]);

  const handleStartDuplicating = useCallback(
    (id: string) => {
      const layoutIndex = layouts.findIndex((layout) => layout.id === id);
      if (layouts[layoutIndex]) {
        existingLayoutRef.current = {
          ...layouts[layoutIndex],
          order: layoutIndex,
        };
        recordLayoutModal.show();
      }
    },
    [layouts, recordLayoutModal]
  );

  const handleChangePermissions = useCallback(
    (permissions: any, wasDirty: boolean) => {
      if (wasDirty) {
        handleChange(
          currentLayout?.value,
          undefined,
          undefined,
          undefined,
          permissions
        );
      }
    },
    [currentLayout, handleChange]
  );

  return (
    <>
      <StyledCols columns={2} gutter="5px">
        <div className="text-font-primary">
          <Typography variant="header" weight="semibold" size="lg">
            {t('Customize Record Layout')}
          </Typography>
          <Spacer mode="horizontal" size={20} />
          <Spacer mode="horizontal" size={10} />
          <Typography weight="semibold">
            {t('Choose Layout to Modify')}
          </Typography>
          <Spacer mode="horizontal" size={15} />
          <Select
            ref={selectRef}
            value={currentLayout}
            onChange={maybeChangeLayout}
            options={layoutManagementOptions}
            placeholder={t('Choose Layout')}
            dataQa="record-layout-select"
            menuLeftButton={
              <Button
                maxWidth={false}
                variant="text"
                size="xs"
                color="secondary"
                onClick={maybeManageLayouts}
              >
                {t('Manage Record Layouts')}
              </Button>
            }
            menuRightButton={
              <Button
                maxWidth={false}
                variant="text"
                size="xs"
                color="primary"
                onClick={maybeAddLayout}
              >
                {t('Add Record Layout')}
              </Button>
            }
          />
          {currentLayout?.value ? (
            <>
              <Spacer mode="horizontal" size={20} />
              <div className="kds grid grid-cols-2 gap-x-spacer-15">
                <div>
                  <div className="kds flex items-center gap-x-spacer-10">
                    <Typography>{t('Name')}</Typography>
                    <span {...triggerProps} {...targetProps}>
                      <Icon icon="message-info-circle" size="sm" />
                      {showTooltip ? (
                        <Tooltip
                          position="right"
                          text={t(
                            'If there is more than one layout available for a user, this is the name that will display in their View selector.'
                          )}
                          modalCompatability
                          {...tooltipProps}
                        />
                      ) : null}
                    </span>
                  </div>
                  <Spacer mode="horizontal" size={15} />
                  <Input
                    value={nameValue}
                    onChange={handleChangeName}
                    type="text"
                    placeholder={t('Layout Name')}
                    ref={nameInputRef}
                    error={layoutErrors?.name}
                  />
                  <Validation
                    message={message}
                    showMessage={showMessage}
                    target={nameInputRef.current}
                  />
                </div>
                <div>
                  <Typography>{t('Layout Is Active')}</Typography>
                  <Spacer mode="horizontal" size={25} />
                  <Switch
                    removeMargin
                    checked={isActive}
                    onChange={(ev) => {
                      // Note (keegandonley) - this will be refactored to take a single argument but we have multiple people working on this
                      // right now so it will have to be in the next PR
                      handleChange(
                        currentLayout?.value,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        ev.target.checked
                      );
                      setIsActive(ev.target.checked);
                    }}
                  />
                </div>
              </div>

              <div className="kds grid grid-cols-2 gap-x-spacer-15">
                <div>
                  <Spacer mode="horizontal" size={25} />

                  <Typography>{t('Show Automations Tab')}</Typography>
                  <Spacer mode="horizontal" size={25} />
                  <Switch
                    removeMargin
                    checked={showAutomations}
                    onChange={(ev) => {
                      handleChange(
                        currentLayout?.value,
                        undefined,
                        undefined,
                        ev.target.checked,
                        undefined,
                        undefined,
                        showMessages
                      );
                      setShowAutomations(ev.target.checked);
                    }}
                  />
                </div>
                {isClient ? (
                  <div>
                    <Spacer mode="horizontal" size={25} />
                    <Typography>{t('Show Messages Tab')}</Typography>
                    <Spacer mode="horizontal" size={25} />
                    <Switch
                      removeMargin
                      checked={showMessages}
                      onChange={(ev) => {
                        handleChange(
                          currentLayout?.value,
                          undefined,
                          undefined,
                          showAutomations,
                          undefined,
                          undefined,
                          ev.target.checked
                        );
                        setShowMessages(ev.target.checked);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <Spacer mode="horizontal" size={20} />
              <GridBuilder
                draggableRows
                initial={value}
                onChange={(layout: LayoutState) => {
                  setValue(layout);
                  handleChange(currentLayout?.value, nameValue, layout);
                }}
                columnEnd={(rowId, columnId, isDropZone) => {
                  return isDropZone ? (
                    <div className="kds pl-spacer-10 flex h-[42px] items-center">
                      <Typography color="button-secondary-default">
                        {t('Place Here')}
                      </Typography>
                    </div>
                  ) : (
                    <div className="kds pl-spacer-10 flex h-[42px] items-center">
                      <Button
                        onClick={columnEndClickHandler(rowId, columnId)}
                        variant="text"
                        leftIcon="action-add"
                        size="xs"
                        maxWidth={false}
                        qa={{
                          '': 'add-layout-component-button',
                        }}
                      >
                        Add Layout Component
                      </Button>
                    </div>
                  );
                }}
                builderEnd={
                  <div className="pt-spacer-10">
                    <Button
                      variant="text"
                      leftIcon="action-add"
                      onClick={addRowModalControl.show}
                      size="xs"
                    >
                      Add Row
                    </Button>
                  </div>
                }
                revision={revision}
                displayItem={(
                  item,
                  isDragging,
                  setCanStartRowDrag,
                  dragGroup
                ) => {
                  return (
                    <DragItem
                      item={item}
                      handleEdit={handleEditItem}
                      handleSetVisibility={handleSetVisibility}
                      handleRemove={(id: string) => {
                        setEditId(id);
                        setShowDeleteConfirmation(true);
                      }}
                      dragGroup={dragGroup}
                      setCanStartRowDrag={setCanStartRowDrag}
                      isDragging={isDragging}
                    />
                  );
                }}
                columnClassName={(isDropZone, isHovered) => {
                  if (isDropZone) {
                    const base =
                      'border-1 border-border-active rounded-[4px] min-h-[44px] flex items-center';
                    if (isHovered) {
                      return merge(base, 'border-solid');
                    }
                    return merge(base, 'border-dashed');
                  }
                  return 'border-1 border-dashed border-teal-500 rounded-[4px]';
                }}
                rowClassName="border-1 border-dashed border-orange-500 rounded-[4px] grid grid-cols-6"
                placeholderClassName="kds border-1 border-dashed border-border-medium rounded-[8px] p-spacer-10 py-spacer-20 flex items-center gap-x-spacer-10 bg-background-light"
              />
            </>
          ) : null}
        </div>

        {currentLayout?.value ? (
          <PermissionSection
            secondarySettingsContainer={secondarySettingsContainer}
            handleChange={handleChangePermissions}
            existing={activeSharingSettings}
          />
        ) : null}
        <RowModal {...addRowModalProps} onChoose={handleAddRow} />
        {showLayoutComponentWizard ? (
          <LayoutComponentWizard
            show
            id={editId}
            formData={formData}
            layout={value}
            saveSingleComponent={handleCreateComponent}
            onConfirm={() => {
              setEditId(undefined);
              setShowLayoutComponentWizard(false);
            }}
            onHide={() => {
              setEditId(undefined);
              setShowLayoutComponentWizard(false);
            }}
            setDeleteConfirmation={setShowDeleteConfirmation}
            tableHeaderPreviewComponent={<HeaderPreview />}
            showAllOptions
            canUseRelatedPipelinesBlock={canUseRelatedPipelinesBlock}
            existingDisplayName={editingItem?.displayName}
            existingInternalName={editingItem?.internalName}
            existing={getExisting(editingItem)}
            enableAdditionalWizards
            categorizedFields={categorizedFields}
            objectId={objectId}
            objectName={formData.name}
            objectType={formData.objectType}
          />
        ) : null}
        <VerticalSpacer className="verticalSpacer" />
        <div>
          <Typography variant="header" weight="semibold" size="lg">
            {t('Record Preview')}
          </Typography>
          <Spacer mode="horizontal" size={20} />
          {currentLayout?.value ? (
            <RecordLayoutPreview layout={value} />
          ) : (
            <Typography fontStyle="italic">
              {t('Choose a layout to view preview')}
            </Typography>
          )}
        </div>
      </StyledCols>
      <RecordLayoutModal
        newRecordError={newRecordError}
        setNewRecordError={setNewRecordError}
        duplicateData={existingLayoutRef.current}
        {...recordLayoutModalProps}
      />
      <RecordLayoutListModal
        {...recordLayoutListModalProps}
        objectId={objectId}
        options={layoutManagementOptions}
        handleStartDuplicating={handleStartDuplicating}
        handleClickAddLayout={handleClickAddRecordLayout}
      />
      {showDeleteConfirmation && (
        <ConfirmDeletionModal
          show
          onHide={() => {
            setShowDeleteConfirmation(false);
            setEditId(undefined);
          }}
          onConfirm={async () => {
            handleDeleteComponent(editId);
            setShowDeleteConfirmation(false);
            setShowLayoutComponentWizard(false);
            setEditId(undefined);
          }}
        >
          {t(
            'Removing the block will also delete all settings associated with the customized block. The underlying data will not be affected by this change.'
          )}
        </ConfirmDeletionModal>
      )}
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        rightBtn={<Button color="warning">{t('Discard Changes')}</Button>}
        rightBtnClickHandler={handleDiscardAfterConfirmation}
        defaultLeftBtnHandler={handleSaveAfterConfirmation}
        leftBtn={<Button />}
        defaultLeftBtnText={t('Save Changes')}
        actionButton={false}
        {...unsavedChangesModalProps}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
};
