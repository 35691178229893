import BasicModal from '../presets/BasicModal';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import {
  ButtonImage,
  ProviderButton,
  ProviderButtonWrapper,
  ProviderWrapper,
  StyledDisclaimer,
  StyledLink,
} from './styles';
import GmailSvg from './img/gmail.svg?react';
import OutlookSvg from './img/outlook.svg?react';
import GoogleSignIn from './img/google_signin.png';
import GoogleSignInHover from './img/google_signin_hover.png';
import Select from 'components/Inputs/Select';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import { OutlineLabel } from 'components/Inputs/InputControl';
import KizenTypography from 'app/kizentypo';
import { PROVIDERS } from './useThirdPartyAuth';
import { useEffect, useRef } from 'react';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from '../../Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';

const IntegratedEmailModal = ({
  provider,
  teamMember,
  canSubmit,
  setTeamMember,
  setProvider,
  useCurrentTeamMember = false,
  providerReadOnly = false,
  header,
  ads = false,
  ...modalProps
}) => {
  const { t } = useTranslation();
  const selectRef = useRef();

  const [typeaheadProps] = useSelectTypeaheadWithScroll({
    selectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
  });

  // Preload the buttons so we don't get a flash when swapping to the hover
  useEffect(() => {
    [GoogleSignIn, GoogleSignInHover].forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  }, []);

  return (
    <BasicModal
      heading={
        header ??
        (providerReadOnly
          ? t('Please Refresh Email Integration')
          : t('Please Choose Email Integration'))
      }
      leftBtn={
        canSubmit ? (
          <Button variant="text" color="blue">
            {t('Cancel')}
          </Button>
        ) : null
      }
      left
      actionBtnColor={canSubmit ? 'green' : 'blue'}
      buttonText={
        canSubmit
          ? `${t('Sign in with')} ${
              provider === PROVIDERS.GOOGLE ? t('Google') : t('Outlook')
            }`
          : t('cancel')
      }
      {...modalProps}
      actionButton={canSubmit && provider === PROVIDERS.GOOGLE ? false : true}
      rightBtn={
        canSubmit && provider === PROVIDERS.GOOGLE ? (
          <ButtonImage
            standard={GoogleSignIn}
            hover={GoogleSignInHover}
            role="button"
            tabIndex={0}
            aria-label={t('Sign in with Google')}
          />
        ) : undefined
      }
    >
      {!useCurrentTeamMember && !ads && (
        <Select
          ref={selectRef}
          inModal
          menuInline
          placeholder={t('Choose Team Member')}
          label={t('Choose Team Member')}
          value={teamMember}
          onChange={(opt) => {
            setTeamMember(opt);
          }}
          menuLeftButton={<ClearSelectButton />}
          {...typeaheadProps}
        />
      )}
      {(teamMember || useCurrentTeamMember || ads) && !providerReadOnly && (
        <div>
          <ProviderWrapper compact={useCurrentTeamMember || ads}>
            <OutlineLabel>{t('Choose Provider')}</OutlineLabel>
            <ProviderButtonWrapper>
              <ProviderButton
                onClick={() => setProvider(PROVIDERS.GOOGLE)}
                active={provider === PROVIDERS.GOOGLE}
              >
                <div>
                  <GmailSvg />
                </div>
                <div>
                  <KizenTypography>{t('Gmail')}</KizenTypography>
                </div>
              </ProviderButton>
              <ProviderButton
                onClick={() => setProvider(PROVIDERS.OUTLOOK)}
                active={provider === PROVIDERS.OUTLOOK}
              >
                <div>
                  <OutlookSvg />
                </div>
                <div>
                  <KizenTypography>{t('Outlook')}</KizenTypography>
                </div>
              </ProviderButton>
            </ProviderButtonWrapper>
          </ProviderWrapper>
        </div>
      )}
      {provider === PROVIDERS.GOOGLE && canSubmit && (
        <>
          <StyledDisclaimer compact={providerReadOnly}>
            {t(
              "Kizen's use of information received from Google APIs will adhere to the"
            )}{' '}
            <StyledLink
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Google API Services User Data Policy')}
            </StyledLink>
            , {t('including the Limited Use requirements')}.
          </StyledDisclaimer>
        </>
      )}
    </BasicModal>
  );
};

export default IntegratedEmailModal;
