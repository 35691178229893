import { Route, Switch } from 'react-router-dom';
import { NavWrapper, StyledSubNavigation } from './styled';
import { useAccessibleRoutes } from './hooks/useAccessibleRoutes';

const BusinessPage = ({ access }) => {
  const accessibleSubroutes = useAccessibleRoutes(access);

  return (
    <>
      <NavWrapper className="nav-wrapper">
        <StyledSubNavigation
          small
          id="sectionnav"
          routes={accessibleSubroutes}
        />
      </NavWrapper>
      <Switch>
        {Object.entries(accessibleSubroutes).map(([key, route]) => {
          const Component = route.component;
          return (
            <Route
              key={key}
              path={route.path}
              exact={route.exact !== false}
              component={route.component}
            >
              <Component access={access} />
            </Route>
          );
        })}
      </Switch>
    </>
  );
};

export default BusinessPage;
