import styled from '@emotion/styled';
import { createPortal } from 'react-dom';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react';
import Loader from '__components/Kizen/Loader';
import css from '@emotion/css';
import Draggable from 'react-draggable';
import { Button } from '@kizen/kds/Button';
import { Icon } from '@kizen/kds/Icon';
import { Typography } from '@kizen/kds/Typography';
import { usePluginCustomHTML } from './usePluginCustomHTML';
import { useLocation } from 'react-router-dom';
import { toastVariant, useToast } from '__components/ToastProvider';
import { useTranslation } from 'react-i18next';
import {
  EmployeeConfigMap,
  FrameQuadrant,
  PluginContext,
} from './PluginContext';
import { runFrameScriptEventName } from 'ts-components/ScriptRunner/Communicate';
import { useFloatingFrameCustomScript } from 'ts-components/ScriptRunner/hooks/useFloatingFrameCustomScript';
import { useManualInteraction } from './useManualInteraction';
import { useMutation } from 'react-query';
import TeamMemberService from 'services/TeamMemberService';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const DEFAULT_POSITION_GAP = 20;
const FRAME_HEADER_SIZE = 40;

const getQuadrant = (
  topDelta: number,
  leftDelta: number,
  bottomDelta: number,
  rightDelta: number
): FrameQuadrant => {
  if (topDelta < bottomDelta) {
    // top
    if (leftDelta < rightDelta) {
      return 'top-left';
    } else {
      return 'top-right';
    }
  } else {
    // bottom
    if (leftDelta < rightDelta) {
      return 'bottom-left';
    } else {
      return 'bottom-right';
    }
  }
};

const FloatWrapper = styled.div`
  width: ${({ width }: any) => width ?? 300}px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  ${({ pending }: any) =>
    pending
      ? css`
          pointer-events: none;
          opacity: 0;
        `
      : ''}

  ${({ minimized }: any) =>
    minimized
      ? css`
          .floating-window-content {
            display: none;
          }
        `
      : ''}

    ${({ dragging, height }: any) =>
    !dragging
      ? css`
          transition: transform calc(${height ?? 600} * 0.0005s);
        `
      : css``}
`;

const AbsoluteLoader = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: white;
`;

const ContentTarget = styled.div`
  width: 100%;
  opacity: 1;

  ${({ dragging }: any) =>
    dragging
      ? css`
          pointer-events: none;
        `
      : css`
          pointer-events: auto;
        `}

  ${({ scroll }: any) =>
    scroll
      ? css`
          overflow: auto;
        `
      : ''}

  ${({ solid }: any) =>
    solid
      ? css`
          background-color: white;
        `
      : ''}
`;

const Parent = styled.div`
  position: fixed;
  top: ${({ navbarHeight }: any) => navbarHeight || 0}px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  ${({ hidden }: any) =>
    hidden
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : ''}

  ${({ dragging, offset = 0, totalOffset = 1 }) =>
    dragging
      ? css`
          pointer-events: auto;
          z-index: ${5051 + totalOffset};
          user-select: none;
        `
      : css`
          pointer-events: none;
          z-index: ${5050 + offset};
          user-select: auto;
        `}
`;

const FloatingWindowContent = styled.div`
  ${({ hiddenByModal }: any) =>
    hiddenByModal
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : css`
          opacity: 1;
          pointer-events: auto;
        `}
`;

const ScriptUITarget = styled.div`
  ${({ height }: { height?: number }) =>
    height
      ? css`
          min-height: ${height}px;
          height: ${height}px;
        `
      : css`
          height: 100%;
        `}
`;

const MinimizeController = styled.div`
  height: ${({ minimized, height }: any) => (minimized ? 0 : height ?? 600)}px;
  transition: height calc(${({ height }) => height ?? 600} * 0.0005s);
  overflow: hidden;
`;

const defaultIgnorePatterns = [
  '^/$',
  '^/login*',
  '^/settings/.*$',
  '^/dashboard$',
  '/settings$',
  '/settings/mine$',
  '/settings/others$',
  '/freetrial$',
  '^/choose-business*',
  '^/free-trial-ended$',
  '^/reset$',
  '/welcome$',
];

const FloatingWindowChild = ({ index, id }: { index: number; id: string }) => {
  const scriptUIRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [showToast] = useToast();
  const clientObject = useSelector(getBusinessClientObject);

  const additionalArgs = useMemo(() => {
    const splits = pathname.split('/');

    const first = splits[1];
    const second = splits[2];

    if (first === 'client') {
      return {
        objectId: clientObject.id,
        entityId: second,
      };
    }

    if (first === 'custom-objects') {
      return {
        objectId: second,
        entityId: splits[3],
      };
    }
  }, [clientObject, pathname]);

  // Used when actively moving the floating window, to prevent accidentally
  // losing focus to an iframe or other element
  const [dragging, setDragging] = useState(false);

  const floatingRef = useRef<HTMLDivElement>(null);

  const {
    floatingFrames,
    floatingFrameOffset,
    touchFloatingFrame,
    refetch,
    pluginAPINamesToIds,
  } = useContext(PluginContext);

  const frameOffset = floatingFrameOffset[id] ?? -1;

  const hasRunScript = useRef(false);
  const currentWindow = useMemo(() => {
    const window = floatingFrames?.[index];

    return window;
  }, [floatingFrames, index]);

  const isHiddenByPattern = useMemo(() => {
    const matchers = currentWindow?.match;
    const ignore = currentWindow?.ignore;

    if (
      matchers?.length &&
      !matchers.some((m: string) => new RegExp(m).test(pathname))
    ) {
      return true;
    }

    if (
      defaultIgnorePatterns
        .concat(ignore ?? [])
        .filter(Boolean)
        .some((m) => {
          try {
            return new RegExp(m).test(pathname);
          } catch (e) {
            return false;
          }
        })
    ) {
      return true;
    }

    return false;
  }, [currentWindow, pathname]);

  const currentPluginId = currentWindow?.plugin_api_name
    ? pluginAPINamesToIds[currentWindow.plugin_api_name]
    : '';

  const [minimized, _setMinimized] = useState(
    () => currentWindow?.employee_config?.[id]?.minimized ?? false
  );

  const script = currentWindow?.script;
  const type = currentWindow?.type;
  const args = currentWindow?.args;
  const messageHandlerScript = currentWindow?.message_handler;

  const mutateConfig = useMutation({
    mutationFn: async (value: {
      config: Record<string, EmployeeConfigMap>;
    }) => {
      const res = await TeamMemberService.updatePluginConfig(
        currentPluginId,
        value,
        {
          skipErrorBoundary: true,
        }
      );

      return res;
    },
    onSettled: () => {
      refetch();
    },
  });

  const width = currentWindow?.width ?? 0;
  const height = currentWindow?.height ?? 0;
  const defaultPosition = currentWindow?.default_position;

  const getDesiredPosition = useCallback(
    (assumeExpanded: boolean) => {
      const position = currentWindow?.employee_config?.[id]?.position;

      if (!position?.quadrant) {
        const maxAllowedLeft = window.innerWidth - width;
        let maxAllowedTop = window.innerHeight - height - FRAME_HEADER_SIZE;
        if (minimized && !assumeExpanded) {
          maxAllowedTop = window.innerHeight - FRAME_HEADER_SIZE;
        }

        if (position) {
          try {
            const coords = position;
            if (coords.left > maxAllowedLeft) {
              coords.left = maxAllowedLeft;
            }
            if (coords.top > maxAllowedTop) {
              coords.top = maxAllowedTop;
            }

            if (coords.left < 0) {
              coords.left = DEFAULT_POSITION_GAP;
            }

            if (coords.top < 0) {
              coords.top = DEFAULT_POSITION_GAP;
            }

            return coords;
          } catch (e) {
            // Ignore and move on
          }
        }

        let defaultLeft = maxAllowedLeft;
        if (defaultPosition === 'bottom-left') {
          defaultLeft = DEFAULT_POSITION_GAP;
        }

        return { left: defaultLeft, top: maxAllowedTop - DEFAULT_POSITION_GAP };
      } else if (position?.quadrant === 'top-left') {
        const left = position?.deltas?.left ?? 0;
        const top = position?.deltas?.top ?? 0;

        return { ...position, left, top };
      } else if (position?.quadrant === 'top-right') {
        const left = window.innerWidth - width - (position?.deltas?.right ?? 0);
        const top = position?.deltas?.top ?? 0;

        return { ...position, left, top };
      } else if (position?.quadrant === 'bottom-left') {
        const left = position?.deltas?.left ?? 0;
        const top =
          window.innerHeight -
          (minimized ? 0 : height) -
          (position?.deltas?.bottom ?? 0) -
          FRAME_HEADER_SIZE;

        return { ...position, left, top };
      } else if (position?.quadrant === 'bottom-right') {
        const left = window.innerWidth - width - (position?.deltas?.right ?? 0);
        const top =
          window.innerHeight -
          (minimized ? 0 : height) -
          (position?.deltas?.bottom ?? 0) -
          FRAME_HEADER_SIZE;

        return { ...position, left, top };
      }

      return { ...position, left: 0, top: 0 };
    },
    [currentWindow, id, defaultPosition, minimized, width, height]
  );

  const getPosition = useCallback(
    (assumeExpanded: boolean) => {
      const position = getDesiredPosition(assumeExpanded);

      const isBottom = ['bottom-right', 'bottom-left'].includes(
        position?.quadrant ?? ''
      );

      const calculatedHeight = isBottom && minimized ? 0 : height;

      if (position.left < 0) {
        position.left = 0;
      } else if (position.left > window.innerWidth - width) {
        position.left = window.innerWidth - width;
      }

      if (position.top < 0) {
        position.top = 0;
      } else if (position.top > window.innerHeight - calculatedHeight) {
        position.top = window.innerHeight - calculatedHeight;
      }

      return position;
    },
    [getDesiredPosition, width, height, minimized]
  );

  const [windowPosition, setWindowPosition] = useState<{
    left: number;
    top: number;
    quadrant?: FrameQuadrant;
    deltas?: {
      top: number;
      left: number;
      right: number;
      bottom: number;
    };
  } | null>(null);

  const updateEmployeeConfig = useCallback(
    (value: EmployeeConfigMap) => {
      const newPayload = {
        ...currentWindow?.employee_config?.[id],
        minimized,
        ...value,
      };

      if (windowPosition) {
        newPayload.position = {
          left: windowPosition.left,
          top: windowPosition.top,
          quadrant: windowPosition.quadrant,
          deltas: windowPosition.deltas,
        };
      }

      if (value.position) {
        newPayload.position = value.position;
      }

      const newConfig = {
        ...currentWindow?.employee_config,
        [id]: newPayload,
      };
      mutateConfig.mutateAsync({ config: newConfig });
    },
    [mutateConfig, minimized, windowPosition, id, currentWindow]
  );

  const setMinimized = (min: boolean) => {
    _setMinimized(min);
    if (
      windowPosition?.quadrant === 'bottom-left' ||
      windowPosition?.quadrant === 'bottom-right'
    ) {
      if (min) {
        setWindowPosition((p) => {
          if (!p) {
            return null;
          }

          return {
            ...p,
            top: (p?.top ?? 0) + height,
          };
        });
      } else {
        setWindowPosition((p) => {
          if (!p) {
            return null;
          }

          return {
            ...p,
            top: (p?.top ?? 0) - height,
          };
        });
      }
    }
    updateEmployeeConfig({ minimized: min });
  };

  const [execute, { pending, hidden }] = useFloatingFrameCustomScript({
    onError: (e) => {
      showToast({
        message: `${t('Plugin error')}: ${e?.message}`,
        variant: toastVariant.FAILURE,
      });
    },
    scriptUIRef,
    plugin: currentWindow,
    onChangeMinimized: (minimized: boolean) => {
      setMinimized(minimized);
    },
  });

  useEffect(() => {
    if (
      !hasRunScript.current &&
      script &&
      type === 'script' &&
      !isHiddenByPattern
    ) {
      hasRunScript.current = true;
      execute(script, { ...args });
    }
  }, [execute, script, type, args, isHiddenByPattern]);

  // This is the handler for events triggered by another piece of the integration,
  // like a full-page routable screen. It should not be confused with the click handler,
  // or the frame message handler.
  useEffect(() => {
    const handleRunFrameScriptFromInteraction = (e: any) => {
      if (e?.detail?.recipient?.frame === currentWindow?.api_name) {
        const payload = e.detail;

        if (payload.recipient.plugin !== currentWindow?.plugin_api_name) {
          return;
        }

        if (currentWindow?.event_scripts?.[payload.recipient.script]) {
          execute(currentWindow.event_scripts[payload.recipient.script], {
            ...payload.args,
            ...args,
            ...additionalArgs,
          });
        }
      }
    };

    window.addEventListener(
      runFrameScriptEventName,
      handleRunFrameScriptFromInteraction
    );

    return () => {
      window.removeEventListener(
        runFrameScriptEventName,
        handleRunFrameScriptFromInteraction
      );
    };
  }, [currentWindow, execute, args, additionalArgs]);

  const dragEnd = (_event: any, context: any) => {
    setDragging(false);
    touchFloatingFrame(id);
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (context.node) {
      const rect = context.node.getBoundingClientRect();
      const left = rect.left;
      const top = rect.top;
      const bottom = top + rect.height;
      const right = left + rect.width;

      const topDelta = top;
      const bottomDelta = windowHeight - bottom;
      const leftDelta = left;
      const rightDelta = windowWidth - right;

      const quadrant = getQuadrant(
        topDelta,
        leftDelta,
        bottomDelta,
        rightDelta
      );

      const result = {
        left,
        top,
        quadrant,
        deltas: {
          top: topDelta,
          bottom: bottomDelta,
          left: leftDelta,
          right: rightDelta,
        },
      };
      setWindowPosition(result);
      updateEmployeeConfig({ position: result });
    }
  };

  useEffect(() => {
    const handleUpdate = () => {
      setWindowPosition(getPosition(false));
    };

    window.addEventListener('resize', handleUpdate);

    return () => {
      window.removeEventListener('resize', handleUpdate);
    };
  }, [getPosition]);

  // This is the handler for click events coming from the floating frame.
  useManualInteraction(execute, currentWindow, scriptUIRef, pending, args);

  // This is the handler for frame messages coming from the iframe inside the floaing frame.
  useEffect(() => {
    const handleMessageEvent = (ev: MessageEvent) => {
      if (ev.source !== window && messageHandlerScript) {
        execute(messageHandlerScript, {
          ...args,
          eventData: ev.data,
        });
      }
    };

    window.addEventListener('message', handleMessageEvent);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [execute, args, messageHandlerScript]);

  const { outputUIRef, scopedCss, sanitizedHtml, interactableScriptRef } =
    usePluginCustomHTML(currentWindow, {});

  if (!currentWindow) {
    return null;
  }

  if (currentWindow && !windowPosition) {
    setWindowPosition(getPosition(false));
  }

  const parentClassName = `floating-window-parent--${id}`;
  const dragHandleClassName = `floating-drag-handle--${id}`;
  const contentClassName = `floating-window-content--${id}`;

  return (
    <Parent
      className={parentClassName}
      dragging={dragging}
      hidden={hidden || isHiddenByPattern}
      offset={frameOffset}
      totalOffset={floatingFrames.length}
    >
      <Draggable
        onStop={dragEnd}
        onStart={() => setDragging(true)}
        bounds={`.${parentClassName}`}
        handle={`.${dragHandleClassName}`}
        defaultPosition={{
          x: windowPosition?.left ?? 0,
          y: windowPosition?.top ?? 0,
        }}
        position={{ x: windowPosition?.left ?? 0, y: windowPosition?.top ?? 0 }}
      >
        <FloatWrapper
          className="floating-window flex flex-col"
          pending={pending}
          ref={floatingRef}
          width={width}
          dragging={dragging}
          height={height}
        >
          {pending ? <AbsoluteLoader loading /> : null}
          <div
            className="kds w-full pr-[1rem] flex items-center justify-between bg-[var(--frame-header-color)] text-[var(--frame-header-text-color)] select-none pointer-events-auto"
            style={
              {
                '--frame-header-color': currentWindow.header_color || 'white',
                '--frame-header-text-color':
                  currentWindow.header_text_color || 'black',
              } as any
            }
          >
            <div
              className={`cursor-grab ${dragHandleClassName} h-[var(--frame-header-size)] px-[1rem] flex items-center`}
              style={
                {
                  '--frame-header-size': `${FRAME_HEADER_SIZE}px`,
                } as any
              }
            >
              <Icon icon="action-drag-handle" />
            </div>
            <div className="max-w-[50%]">
              <Typography truncate>{currentWindow.title}</Typography>
            </div>
            <div>
              <Button
                rightIcon="action-minimize"
                variant="text"
                color="inherit"
                size="small"
                onClick={() => setMinimized(!minimized)}
                rightIconSettings={{
                  tooltipDescriptionOverride: minimized ? 'Expand' : 'Minimize',
                }}
              />
            </div>
          </div>
          {currentWindow.type === 'script' && script ? (
            <ContentTarget
              className={contentClassName}
              dragging={dragging}
              solid
            >
              <MinimizeController height={height} minimized={minimized}>
                <ScriptUITarget height={height} className="w-full">
                  <div className="h-full w-full" ref={scriptUIRef} />
                </ScriptUITarget>
                <style>{scopedCss}</style>
              </MinimizeController>
            </ContentTarget>
          ) : null}
          {currentWindow.type === 'html' && sanitizedHtml ? (
            <>
              <ContentTarget
                ref={interactableScriptRef}
                dragging={dragging}
                solid
                scroll
              >
                <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                <div ref={outputUIRef} />

                <style>{scopedCss}</style>
              </ContentTarget>
            </>
          ) : null}
        </FloatWrapper>
      </Draggable>
    </Parent>
  );
};

const FloatingWindowWrapper = () => {
  const { floatingFrames, hiddenByModal } = useContext(PluginContext);
  const container = document.getElementById('floating-window-root');

  if (!container) {
    return null;
  }

  return createPortal(
    <FloatingWindowContent hiddenByModal={hiddenByModal}>
      {floatingFrames.map((frame, index) => {
        const id = `${frame.plugin_api_name}-${frame.api_name}`;
        return <FloatingWindowChild key={id} index={index} id={id} />;
      })}
    </FloatingWindowContent>,
    container
  );
};

export const FloatingWindow = () => {
  const { isLoading } = useContext(PluginContext);

  if (isLoading) {
    return null;
  }

  return <FloatingWindowWrapper />;
};
