import { useContext } from 'react';
import { FlowBuilderContext } from '../context';
import { BasicEdgeCore } from '__pages/AutomationEngine/Engine/Flow/edges';

type EdgeProps = {
  source: string;
  target: string;
};

export const BasicEdge = ({ source, target, ...props }: EdgeProps) => {
  const { placing, lastPlacingStep } = useContext(FlowBuilderContext);

  return (
    <BasicEdgeCore
      source={source}
      target={target}
      placing={placing}
      lastPlacingStep={lastPlacingStep}
      {...props}
    />
  );
};
