import { RIGHT_ID } from 'components/MultiColumnBuilder/useBasicBuilder';

export const WRAPPER_HEGHT = 500; // default height of the table

export const DEFAULD_RENDER_EMPTY = {
  onDropLabel: '',
  noItems: '',
};

export const DEFAULT_CHECK_FN = () => true;

export const DEFAULT_CHECK_COLOR_EDITABLE_FN = ({ section }) =>
  section === RIGHT_ID;
