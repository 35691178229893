import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PreviewBlock from './PreviewBlock';
import FileUploader from './FileUploader';
import {
  FileLibraryModalHeaderWrapper,
  FileLibraryDetailsWrapper,
  Spacer,
  StyledModal,
  FileLibraryModalHeader,
  SearchInputWrapper,
  LeftColumn,
  RightColumn,
} from './styles';
import { useProgress } from './useProgress';
import { useFiles } from './useFiles';
import { useUploadFile } from './useUploadFile';
import { fileShape, allowedFileExtensions } from '../defaults';
import { PageSearchInput } from '../../../Layout/PageToolbar';
import { breakpoints } from '../../../../app/spacing';
import { FilesList } from './FilesList';
import { useWindowSize } from 'react-use';

const FileLibraryModal = ({
  files: filesProp = [],
  onChange = undefined,
  onHide = undefined,
  modalProps = {},
  modal = undefined,
  disabled = false,
  viewable = true,
  publicFile,
  source,
  businessId,
  show,
  className,
  style,
}) => {
  const { t } = useTranslation();
  const [progress, { clearProgress, initializeProgress, updateProgress }] =
    useProgress();

  const uploadFile = useUploadFile(
    publicFile,
    updateProgress,
    businessId,
    source
  );
  const [allFiles, { addFiles, deselectFile }] = useFiles({
    initial: filesProp,
    initializeProgress,
    uploadFile,
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [search, setSearch] = useState('');

  const files = useMemo(() => {
    if (!search) return allFiles;
    return allFiles.filter(({ name }) =>
      name.toLowerCase().includes(search.toLowerCase())
    );
  }, [allFiles, search]);

  const handleAddFiles = useCallback(
    async (updatedFiles) => {
      await addFiles([...allFiles, ...updatedFiles]);
      clearProgress();
    },
    [allFiles, addFiles, clearProgress]
  );

  const modalOpts = useMemo(() => {
    const handleHide = () => {
      if (onHide) {
        onHide();
      }
      if (modal) {
        modal.hide();
      }
    };

    const handleConfirm = async () => {
      if (onChange) {
        await onChange(files);
      }
      if (modal) {
        modal.hide();
      }
    };

    return disabled
      ? {
          onConfirm: handleHide,
          onHide: handleHide,
          actionBtnColor: 'blue',
          buttonText: 'Close',
        }
      : {
          onConfirm: handleConfirm,
          onHide: handleHide,
        };
  }, [disabled, files, modal, onChange, onHide]);

  const { height, width } = useWindowSize();
  const [showPreviewBlock, setShowPreviewBlock] = useState(true);
  const columnRef = useRef(null);
  useLayoutEffect(() => {
    setShowPreviewBlock(
      columnRef.current &&
        columnRef.current.offsetHeight === columnRef.current.scrollHeight
    );
  }, [height, width]);

  const isMobile = width <= breakpoints.sm;

  return (
    <>
      <StyledModal
        data-qa="file-library-modal"
        heading={t('File Library')}
        buttonText={t('Save')}
        size="large"
        {...modalProps}
        {...modalOpts}
        defaultLeftBtnText={t('Cancel')}
        disabled={progress}
        show={show}
        className={className}
        style={style}
      >
        <FileLibraryModalHeaderWrapper>
          <FileLibraryModalHeader type="header">
            {`${files.length} ${
              files.length === 1 ? t('Selected File') : t('Selected Files')
            }`}
          </FileLibraryModalHeader>
          <SearchInputWrapper>
            <PageSearchInput
              onChange={setSearch}
              placeholder={t('Find Files')}
              maxWidth={250}
            />
          </SearchInputWrapper>
        </FileLibraryModalHeaderWrapper>
        <FileLibraryDetailsWrapper>
          <LeftColumn
            windowHeight={height}
            isVisible={!disabled || showPreviewBlock}
            ref={columnRef}
          >
            {disabled ? null : (
              <FileUploader
                onChange={handleAddFiles}
                allowedFileExtensions={allowedFileExtensions}
                uploadStatus={progress}
                disabled={progress}
                isMobile={isMobile}
              />
            )}
            <PreviewBlock
              file={files.filter(({ id }) => id === selectedFile?.id)[0] || {}}
              isMobile={isMobile}
            />
          </LeftColumn>
          {!disabled || showPreviewBlock ? <Spacer /> : null}
          <RightColumn windowHeight={height}>
            <FilesList
              files={files}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              deselectFile={deselectFile}
              disabled={disabled}
              viewable={viewable}
            />
          </RightColumn>
        </FileLibraryDetailsWrapper>
      </StyledModal>
    </>
  );
};

FileLibraryModal.propTypes = {
  files: PropTypes.arrayOf(fileShape),
  modalProps: PropTypes.object,
  modal: PropTypes.object,
  onChange: PropTypes.func,
  onHide: PropTypes.func,
  disabled: PropTypes.bool,
  viewable: PropTypes.bool,
};

export default FileLibraryModal;
