import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageSizing } from 'components/Layout/PageContentWidth';
import Card from 'components/Card/Card';
import { KizenTypography } from 'app/typography';

const RetargetingPage = (props) => {
  const { t } = useTranslation();
  return (
    <PageSizing>
      <Card {...props}>
        <KizenTypography type="subheader">{t('Coming soon')}</KizenTypography>
      </Card>
    </PageSizing>
  );
};
export default RetargetingPage;
