import React, { useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { gutters } from '../../../app/spacing';
import { applyRef, selectedValueProp } from '../props';
import ButtonGroupButton from '../ButtonGroup/Button';
import Validation, { useValidation } from '../Validation';

const ButtonsWrapper = styled.div`
  display: flex;

  & > * {
    width: 50px;

    margin-left: ${gutters.spacing(2)}px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const OutlineYesNoMaybe = React.forwardRef(
  (
    { value: valueProp, options, error, disabled, onChange, ...others },
    ref
  ) => {
    const validationRef = useRef();
    const [, validationProps] = useValidation(validationRef, {
      value: valueProp,
      ...others,
    });
    const value =
      typeof valueProp === 'string'
        ? options.find((opt) => opt.value === valueProp) || null
        : valueProp;

    const mergeRef = useCallback(
      (node) => {
        applyRef(ref, node);
        applyRef(validationRef, node);
      },
      [ref]
    );

    const { required } = others;

    return (
      <ButtonsWrapper ref={mergeRef} {...others}>
        {options.map((opt) => {
          const selected = Boolean(value && value.value === opt.value);
          return (
            <ButtonGroupButton
              option={opt}
              key={opt.value}
              selected={selected}
              error={error}
              disabled={disabled}
              onClick={(ev) => {
                if (onChange) {
                  if (selected && !required) {
                    ev.currentTarget.blur();
                    onChange(null, ev);
                  } else {
                    onChange(opt, ev);
                  }
                }
              }}
            />
          );
        })}
        <Validation {...validationProps} />
      </ButtonsWrapper>
    );
  }
);

OutlineYesNoMaybe.displayName = 'OutlineYesNoMaybe';
OutlineYesNoMaybe.propTypes = {
  options: PropTypes.array.isRequired,
  value: selectedValueProp,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};
OutlineYesNoMaybe.defaultProps = {
  value: null,
  onChange: null,
  error: null,
  disabled: null,
};

export default OutlineYesNoMaybe;
