import DonutChartManager from './DonutChartManager';
import * as am5percent from '@amcharts/amcharts5/percent';
import * as am5 from '@amcharts/amcharts5';
import { ensureCents, formatFullDecimal } from 'utility/numbers';

class NestedDonutChartManager extends DonutChartManager {
  constructor(
    element,
    t,
    data = [],
    secondaryData = [],
    {
      mobile = false,
      computedColors,
      secondaryColors,
      isValue,
      currencySymbol,
      toggleable = true,
      small = false,
      total = 0,
      secondaryTotal = 0,
      getLabel,
      wide = false,
      forceDecimal = false,
      forceSecondaryData = false,
      strokeWidth = 2,
    },
    rootConfig,
    ready
  ) {
    super(
      element,
      t,
      data,
      {
        mobile,
        computedColors,
        isValue,
        currencySymbol,
        toggleable,
        small,
        total,
        getLabel,
        wide,
        forceDecimal,
        strokeWidth,
        wideSlices: secondaryData.length > 0,
      },
      rootConfig,
      ready
    );

    this.secondaryColors = secondaryColors;
    this.secondaryData = secondaryData.slice(0, secondaryColors.length + 1);
    this.secondaryEnabled =
      secondaryData.length > 0 || (forceSecondaryData ?? false);
    this.isValue = isValue;
    this.secondaryTotal = secondaryTotal;
  }

  init = () => {
    this.maybeCreateSecondarySeries();
    this.maybeSetSecondaryData(this.secondaryData);
    this.createSeries();
    this.setData(this.data);

    this.series.appear();
    if (this.secondaryEnabled) {
      this.secondarySeries.appear();
    }
    this.chart.appear();
  };

  maybeSetSecondaryData = (data = []) => {
    if (!this.secondaryEnabled) {
      return;
    }
    let visibleCount = 0;
    this.secondarySeries.data.setAll(
      data.map((d) => {
        const isVisible = d.value !== 0;
        visibleCount += +isVisible;
        return {
          ...d,
          sliceSettings: {
            visible: isVisible,
          },
        };
      })
    );
    this.secondaryVisibleCount = visibleCount;
    if (this.secondaryVisibleCount > 1 && this.toggleable) {
      this.secondarySeries.slices.template.set('toggleKey', undefined);
    } else {
      this.secondarySeries.slices.template.set('toggleKey', 'none');
    }

    this.configureEmptyState(data);
  };

  maybeCreateSecondarySeries = () => {
    if (!this.secondaryEnabled) {
      return;
    }
    this.assertChart('secondary series');

    const tooltip = this.createStyledTooltip(
      undefined,
      {},
      {
        maxWidth: this.wide ? 300 : 200,
        oversizedBehavior: 'wrap',
      }
    );

    // We need more complex formatting of the tooltip than amcharts provides, so have a
    // custom adapter for the text
    tooltip.label.adapters.add('text', (text, target) => {
      const category =
        this.getLabel?.(target?.dataItem?.dataContext) ??
        target?.dataItem?.dataContext?.category.toUpperCase() ??
        '';
      const value = target?.dataItem?.dataContext?.value ?? 0;
      const percent =
        this.secondaryTotal === 0 ? 0 : (value / this.secondaryTotal) * 100;
      const stringValue = Math.abs(value).toLocaleString();
      const formattedStringValue = this.isValue
        ? ensureCents(stringValue)
        : stringValue;
      const negativeSymbol = value < 0 ? '-' : '';
      const result = `${category}: ${negativeSymbol}${
        this.isValue ? this.currencySymbol : ''
      }${
        this.forceDecimal
          ? formatFullDecimal(Math.abs(value))
          : formattedStringValue
      } (${percent.toFixed(percent < 1 && percent >= 0.1 ? 1 : 0)}%)`;

      return result;
    });

    this.secondarySeries = this.chart.series.push(
      am5percent.PieSeries.new(this.root, {
        name: 'Secondary Series',
        valueField: 'value',
        categoryField: 'category',
        tooltip,
      })
    );

    this.secondarySeries.labels.template.set('visible', false);
    this.secondarySeries.ticks.template.set('visible', false);

    this.secondarySeries.get('colors').set(
      'colors',
      this.secondaryColors.map((color) => color.light)
    );

    this.secondarySeries.slices.template.setAll({
      templateField: 'sliceSettings',
      stroke: this.strokeWidth ? am5.color(0xffffff) : undefined,
      strokeWidth: this.strokeWidth ? this.strokeWidth : undefined,
    });

    this.secondarySeries.slices.template.states.create('active', {
      shiftRadius: 10,
    });
  };
}

export default NestedDonutChartManager;
