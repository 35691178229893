import { Colors, colors } from './definitions';

/**
 * This is only safe to do because our tailwind config doesn't tree-shake out standard
 * colors. In most cases, tailwind classes cannot be composed like this because they won't
 * be included in the build.
 */
export const getTailwindSafeColorClassName = (
  color?: Colors | 'font-primary',
  type?: 'text' | 'background' | 'border'
) => {
  if (!color) return '';

  const prefix = type === 'background' ? 'bg' : type;

  return `${prefix}-${color}`;
};

/**
 * Convert the hex color to rgb. We shouldn't need to use this in the app, but it's useful for
 * generating the storybook documentation.
 */
export const hexToRgb = (hex?: string, opacity?: string | number) => {
  if (!hex) {
    return null;
  }

  const stripped = hex.replace('#', '');
  const parts = stripped.match(/.{1,2}/g);

  if (!parts) {
    return null;
  }

  const rgb = parts.map((part) => parseInt(part, 16));

  return `rgb${opacity ? 'a' : ''}(${rgb.join(', ')}${
    opacity ? `, ${opacity}` : ''
  })`;
};

export const hexToRgbPartial = (hex?: string, opacity?: string | number) => {
  if (!hex) {
    return null;
  }

  const stripped = hex.replace('#', '');
  const parts = stripped.match(/.{1,2}/g);

  if (!parts) {
    return null;
  }

  const rgb = parts.map((part) => parseInt(part, 16));

  return rgb.join(', ');
};

export const getAliasesForColor = (color: Colors) => {
  const aliases = Object.entries(colors.aliases).filter(
    ([, value]) => value === `var(--color-${color})`
  );

  return aliases.map(([key]) => key);
};
