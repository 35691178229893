import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledModal, StyledModalText, StyledSelect } from './styles';
import { colorsButton } from 'app/colors';

export const ConfirmSkipStepModal = ({
  onConfirm,
  onHide,
  isBranching,
  isCondition,
  stepLabel,
}) => {
  const { t } = useTranslation();

  const [branch, setBranch] = useState();

  const handleConfirm = () => {
    onConfirm(branch?.value);
  };

  const options = useMemo(
    () => [
      {
        value: 'yes',
        label: isCondition ? t('Yes') : t('Met Goal'),
        color: colorsButton.green.hover,
      },
      {
        value: 'no',
        label: isCondition ? t('No') : t('Did Not Meet Goal'),
        color: colorsButton.red.hover,
      },
    ],
    [isCondition, t]
  );

  return (
    <StyledModal
      heading={t('Skip Step And Resume')}
      buttonText={t('Confirm')}
      defaultLeftBtnText={t('Cancel')}
      actionBtnColor="green"
      show
      onConfirm={handleConfirm}
      onHide={onHide}
      disabled={isBranching && !branch?.value}
    >
      {isBranching ? (
        <>
          <StyledModalText>
            {t(
              'This automation failed on step {{stepLabel}}. After skipping this step, which path do you want to proceed down?',
              {
                stepLabel,
              }
            )}
          </StyledModalText>
          <StyledSelect
            options={options}
            value={branch}
            onChange={setBranch}
            isColored
            isSearchable={false}
            placeholder={t('Choose Path')}
          />
        </>
      ) : (
        t(
          'This automation failed on step {{stepLabel}}. Do you want to skip this step and resume the automation?',
          {
            stepLabel,
          }
        )
      )}
    </StyledModal>
  );
};
