import { createAction, createSlice } from '@reduxjs/toolkit';
import { initialStore } from '../initialStore';

export const generatePageConfig = (fromApi = {}, fromState = {}) => {
  const defaultPageConfigWhichMustBe = initialStore.activitiesPage.pageConfig;

  return Object.keys(defaultPageConfigWhichMustBe).reduce((acc, el) => {
    const compare = (property) => {
      const propertyWhichShouldBeAlwaysFromBackEnd = [
        'page',
        'size',
        'sort',
        'search',
      ];
      // highest priority from url string
      if (fromState && fromState[property]) return fromState[property];

      // check extra fields
      if (
        propertyWhichShouldBeAlwaysFromBackEnd.includes(property) &&
        fromApi
      ) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      return defaultPageConfigWhichMustBe[property];
    };

    return { ...acc, [el]: compare(el) };
  }, {});
};

export const getActivities = createAction(
  'activitiesPage/getActivities',
  function prepare(updatePageConfig = true, refreshPage = false) {
    return {
      payload: {
        updatePageConfig,
        refreshPage,
      },
    };
  }
);
export const updateActivity = createAction('activitiesPage/updateActivity');
export const deleteActivity = createAction('activitiesPage/deleteActivity');
export const duplicateActivity = createAction(
  'activitiesPage/duplicateActivity'
);
export const setPageConfig = createAction('activitiesPage/setPageConfig');
export const setPageConfigSuccess = createAction(
  'activitiesPage/setPageConfigSuccess'
);
export const setPageConfigFail = createAction(
  'activitiesPage/setPageConfigFail'
);

// Slices
const defaultState = initialStore.activitiesPage;

const activitiesSlice = createSlice({
  name: 'activitiesPage',
  initialState: defaultState,
  reducers: {
    buildPage: (state, action) => {
      const { page } = action.payload;
      state.isFetching = true;
      state.pageConfig = {
        ...state.pageConfig,
        ...page,
      };
    },
    buildPageComplete: (state, action) => {
      const { pageResponse } = action.payload;
      state.pageConfig = generatePageConfig(pageResponse, state.pageConfig);
    },
    buildPageFinish: (state) => {
      state.isFetching = false;
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    updatePageConfig(state, action) {
      const { payload } = action;
      state.pageConfig = {
        ...state.pageConfig,
        ...payload,
      };
    },
    getActivitiesStart(state) {
      state.isFiltering = true;
    },
    getActivitiesSuccess(state, action) {
      const { activities, activitiesCount } = action.payload;
      state.activities = activities;
      state.activitiesCount = activitiesCount;
    },
    getActivitiesFail(state, action) {
      state.error = action.payload;
    },
    getActivitiesFinish(state) {
      state.isFiltering = false;
    },
    updateActivityFail(state, action) {
      state.error = action.payload;
    },
    deleteActivitySuccess(state, action) {
      state.toastMessage = action.payload;
    },
    duplicateActivitySuccess(state, action) {
      state.toastMessage = action.payload;
    },
    cleanDeleteActivityToastMessage(state) {
      state.toastMessage = null;
    },
    deleteActivityFail(state, action) {
      state.toastMessage = action.payload;
      state.error = action.payload;
    },
    duplicateActivityFail(state, action) {
      state.toastMessage = action.payload;
      state.error = action.payload;
    },
    addNewActivity(state, { payload }) {
      state.activities.unshift(payload);
      state.activitiesCount += 1;
    },
    updateActivitySuccess(state, action) {
      const { payload } = action;
      state.activities = state.activities.map((item) => {
        if (item.id === payload.id) {
          item.name = payload.name ?? item.name;
        }
        return item;
      });
    },
  },
});

export const {
  buildPage,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  updatePageConfig,
  updateActivityFail,
  updateActivitySuccess,
  deleteActivityFail,
  deleteActivitySuccess,
  duplicateActivitySuccess,
  duplicateActivityFail,
  cleanDeleteActivityToastMessage,
  getActivitiesFail,
  getActivitiesSuccess,
  addNewActivity,
  getActivitiesStart,
  getActivitiesFinish,
} = activitiesSlice.actions;

export const { reducer } = activitiesSlice;

// Selectors
export const selectActivitiesPageConfig = (state) =>
  state.activitiesPage.pageConfig;
export const selectActivities = (state) => state.activitiesPage.activities;
export const activitiesIsFetching = (state) => state.activitiesPage.isFetching;
export const selectActivitiesCount = (state) =>
  state.activitiesPage.activitiesCount;
export const selectActivitiesDeleteToastMessage = (state) =>
  state.activitiesPage.toastMessage;
export const selectActivityById = (id) => (state) => {
  return state.activitiesPage.activities.find((s) => s.id === id);
};
export const selectActivitiesIsFiltering = (state) =>
  state.activitiesPage.isFiltering;
