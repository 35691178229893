import { useCallback, useEffect, useMemo } from 'react';
import { useFlashTransition } from 'hooks/useFlashState';
import {
  AdditionalVariable,
  SmartConnectorFlowErrors,
} from 'pages/SmartConnectors/types';

const variableErrorsOrder: Partial<Record<keyof AdditionalVariable, number>> = {
  name: 0,
  required: 1,
  data_type: 2,
  data_source: 3,
  value: 4,
  output_format: 5,
};

type useErrorsProps = {
  errors: SmartConnectorFlowErrors;
  setErrors: React.Dispatch<React.SetStateAction<SmartConnectorFlowErrors>>;
  index: number;
};

export const useErrors = ({ errors, setErrors, index }: useErrorsProps) => {
  const [message, showMessage, flashErrorMessage, cancelFlash] =
    useFlashTransition();

  const { additional_variables } = errors;

  const variableError = (additional_variables || [])[index];

  const hasError = Boolean(
    variableError && Object.values(variableError).length
  );

  const isFirstVariableError =
    hasError &&
    !(additional_variables || []).some(
      (el, i) =>
        i < index && el && typeof el === 'object' && Object.values(el).length
    );

  const errorKey = useMemo<keyof AdditionalVariable | null>(() => {
    return isFirstVariableError
      ? (Object.keys(variableError) as (keyof AdditionalVariable)[]).sort(
          (a, b) => variableErrorsOrder[a]! - variableErrorsOrder[b]!
        )[0]
      : null;
  }, [isFirstVariableError, variableError]);

  useEffect(() => {
    if (errorKey && variableError[errorKey]) {
      flashErrorMessage(variableError[errorKey]);
    }
  }, [variableError, flashErrorMessage, errorKey]);

  const clearError = useCallback(
    (prop: keyof AdditionalVariable) => {
      if (hasError) {
        if (showMessage && errorKey === prop) {
          cancelFlash();
        }

        setErrors((prev) => ({
          ...prev,
          additional_variables: (prev.additional_variables || []).map(
            (el, i) => {
              if (i === index) {
                const { [prop]: _, ...rest } = el;
                return rest;
              }
              return el;
            }
          ),
        }));
      }
    },
    [showMessage, cancelFlash, hasError, setErrors, errorKey, index]
  );

  const setError = useCallback(
    (prop: keyof AdditionalVariable, errorMessage: string[]) => {
      setErrors((prev) => {
        const additionAlVariablesErrors = prev.additional_variables || [];
        additionAlVariablesErrors[index] = {
          ...additionAlVariablesErrors[index],
          [prop]: errorMessage,
        };
        return {
          ...prev,
          additional_variables: additionAlVariablesErrors,
        };
      });
    },
    [setErrors, index]
  );

  const validate = useMemo(
    () => ({ showMessage, message }),
    [showMessage, message]
  );

  return {
    hasError,
    variableError,
    errorKey,
    validate,
    clearError,
    setError,
  };
};
