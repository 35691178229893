/* eslint-disable no-bitwise */
/*
 * Generates a UUID using version 4
 * http://stackoverflow.com/a/8809472
 */
function generate() {
  let d = new Date().getTime();

  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); // use high-precision timer if available
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

/*
 * Validates UUID version 1 to 5 using the RFC4122 specification
 * https://stackoverflow.com/a/13653180
 */
function validate(uuid) {
  const pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return RegExp(pattern).test(uuid);
}

export default {
  generate,
  validate,
};
