import TriggerCard from '../../TriggerCard';

export const content = ({ step }) => step.description;

export const getDescription = ({ automation }) => {
  const { entityName } = automation.customObject;
  return `New ${entityName} Created`;
};

export default function Card({ step, ...others }) {
  return <TriggerCard {...others}>{content({ step })}</TriggerCard>;
}
