import { colorsButton } from 'app/colors';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Kizen/Icon';
import { DASHLET_ACTIONS } from 'components/AccessRequests/constants';
import { getIsDeletable, getIsEditable } from 'components/AccessRequests/utils';
import IconButtonAbsoluteMenu from 'components/Tables/Big/IconButtonAbsoluteMenu';

const ActionMenu = ({
  editHandler,
  duplicateHandler,
  showHandler,
  hideHandler,
  deleteHandler,
  requestHandler,
  onOpen,
  onClose,
  hidden,
  access,
  requestMode,
  menuPlacement,
}) => {
  const { t } = useTranslation();

  const handleChooseValue = useCallback(
    ({ value }) => {
      switch (value) {
        case 'edit':
          return editHandler?.(getIsDeletable(access));
        case 'show':
          return showHandler?.();
        case 'hide':
          return hideHandler?.();
        case 'duplicate':
          return duplicateHandler?.(getIsDeletable(access));
        case 'delete':
          return deleteHandler?.();
        case 'request':
          return requestHandler?.();
        default:
          return;
      }
    },
    [
      editHandler,
      showHandler,
      hideHandler,
      deleteHandler,
      access,
      requestHandler,
      duplicateHandler,
    ]
  );

  const hasEditPermission = useMemo(() => {
    return getIsEditable(access);
  }, [access]);

  const hasDuplicatePermission = useMemo(() => {
    return getIsEditable(access);
  }, [access]);

  const hasDeletePermission = useMemo(() => {
    return getIsDeletable(access);
  }, [access]);

  const menuOptions = useMemo(() => {
    const opts = [];
    if (requestMode) {
      return [
        {
          value: DASHLET_ACTIONS.REQUEST,
          label: t('Request Access'),
        },
      ];
    }

    if (editHandler && hasEditPermission) {
      opts.push({
        value: DASHLET_ACTIONS.EDIT,
        label: t('Edit'),
      });
    }

    if (!hasDeletePermission) {
      opts.push({
        value: DASHLET_ACTIONS.REQUEST,
        label: t('Request Access'),
      });
    }

    if (hidden && showHandler) {
      opts.push({
        value: DASHLET_ACTIONS.SHOW,
        label: t('Show'),
      });
    }

    if (!hidden && hideHandler) {
      opts.push({
        value: DASHLET_ACTIONS.HIDE,
        label: t('Hide'),
      });
    }

    if (duplicateHandler && hasDuplicatePermission) {
      opts.push({
        value: DASHLET_ACTIONS.DUPLICATE,
        label: t('Duplicate'),
      });
    }

    if (deleteHandler && hasDeletePermission) {
      opts.push({
        value: DASHLET_ACTIONS.DELETE,
        label: t('Delete'),
      });
    }

    return opts;
  }, [
    t,
    editHandler,
    showHandler,
    hideHandler,
    deleteHandler,
    hidden,
    hasEditPermission,
    hasDeletePermission,
    duplicateHandler,
    hasDuplicatePermission,
    requestMode,
  ]);

  // If there are no actions available to the user, don't show the action menu
  if (!menuOptions.length) {
    return null;
  }

  return (
    <IconButtonAbsoluteMenu
      position="right"
      sizing="dense"
      color={colorsButton.iconGray}
      onChange={handleChooseValue}
      onOpen={onOpen}
      onClose={onClose}
      options={menuOptions}
      title={requestMode ? t('Request Access') : t('Edit')}
      restrictCloseHandler
      menuPlacement={menuPlacement}
      above={menuPlacement === 'top'}
    >
      <Icon icon="three-dot" />
    </IconButtonAbsoluteMenu>
  );
};

export default ActionMenu;
