import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EditableText from 'components/Kizen/EditableText';
import IconButton from 'components/Kizen/IconButton';
import { TextSpan } from 'app/typography';
import { grayScale } from 'app/colors';
import { useAsync } from 'react-use';
import LoggableActivityService from 'services/LoggableActivityService';
import TeamMemberService from 'services/TeamMemberService';
import {
  CloseButtonWrapper,
  Container,
  NotificationWrapper,
  SmallIcon,
  EditableSelectValueOnly,
  StyledTextSpan,
} from './styled';
import { ASSIGNMENT_TYPES, EMPTY_ARRAY } from './config';
import { namedToOption } from 'services/helpers';
import { useSelector } from 'react-redux';
import { getDataQAForInput } from '../../../Inputs/helpers';
import Dropdown from 'components/Fields/FieldInput/Dropdown';
import { FIELD_TYPES } from 'utility/constants';

const typeOptions = (t) => [
  { value: 'email', label: t('An Email') },
  { value: 'text', label: t('A Text') },
];

const intervalOptions = (t) => [
  { value: 'minute', label: t('Minute(s)') },
  { value: 'hour', label: t('Hour(s)') },
  { value: 'day', label: t('Day(s)') },
];

const assignmentOptions = (t) => [
  { value: ASSIGNMENT_TYPES.TEAMMEMBER_ASSIGNMENT, label: t('Team Member') },
  { value: ASSIGNMENT_TYPES.ROLE_ASSIGNMENT, label: t('Role') },
];

const getActivityOptions = async (objectId) => {
  const result = await LoggableActivityService.getActivityFullList({
    ordering: 'name',
    customObjectId: objectId,
  });
  return result.map(namedToOption);
};

const getRoleOptions = async () => {
  const result = await TeamMemberService.getRoles({
    ordering: 'name',
  });
  return result.map(({ id, name }) => ({ value: id, label: name }));
};

export const SelectActivityType = (props) => {
  const { value: options = EMPTY_ARRAY } = useAsync(async () => {
    return await getActivityOptions(props.id);
  }, []);

  const field = useMemo(
    () => ({ fieldType: FIELD_TYPES.Dropdown, options: options }),
    [options]
  );

  return (
    <Dropdown
      menuLeftButton={null}
      menuRightButton={null}
      field={field}
      options={options}
      {...props}
    />
  );
};

export const SelectAssignmentType = (props) => {
  const { t } = useTranslation();
  const { all_records, role } = useSelector(
    (s) => s.authentication.access['scheduled_activities']
  );
  const [memberOption, roleOption] = assignmentOptions(t);
  const options = [memberOption];
  if (all_records.edit || role) {
    options.push(roleOption);
  }

  return (
    <Dropdown
      field={{ fieldType: FIELD_TYPES.Dropdown, options: options }}
      menuLeftButton={null}
      menuRightButton={null}
      options={options}
      {...props}
    />
  );
};

export const SelectRole = (props) => {
  const { value: options = EMPTY_ARRAY } = useAsync(getRoleOptions, []);
  const { all_records, role } = useSelector(
    (s) => s.authentication.access['scheduled_activities']
  );
  const teamMember = useSelector((s) => s.authentication.teamMember || {}); // current user
  let filteredRoleOptions = options;
  // remove role option if not enough permissions
  if (!all_records.edit && role && Array.isArray(filteredRoleOptions)) {
    filteredRoleOptions = filteredRoleOptions.filter((item) =>
      teamMember.roles.includes(item.value)
    );
  }

  const field = useMemo(
    () => ({ fieldType: FIELD_TYPES.Dropdown, options: filteredRoleOptions }),
    [filteredRoleOptions]
  );

  return <Dropdown field={field} options={filteredRoleOptions} {...props} />;
};

export const NotificationForm = ({
  value,
  onChange,
  onClickRemove,
  ...others
}) => {
  const { t } = useTranslation();
  return (
    <NotificationWrapper>
      <Container {...others}>
        <div>
          <TextSpan>{t('Send')}</TextSpan>
          <EditableSelectValueOnly
            value={value.type}
            options={typeOptions(t)}
            menuPlacement="top"
            onChange={({ value: type }) => onChange({ ...value, type })}
          />
        </div>
        <div>
          <EditableText
            type="number"
            placeholder="0"
            min="0"
            value={value.timeAmount || ''}
            {...getDataQAForInput('notification-delay', 'input')}
            onChange={(timeAmount) =>
              onChange({ ...value, timeAmount: +timeAmount || 0 })
            }
          />
          <EditableSelectValueOnly
            value={value.timeUnit}
            options={intervalOptions(t)}
            menuPlacement="top"
            onChange={({ value: timeUnit }) => onChange({ ...value, timeUnit })}
            className="shifted"
          />
        </div>
        <StyledTextSpan>{t('Before Activity Is Due.')}</StyledTextSpan>
      </Container>
      <CloseButtonWrapper>
        <IconButton
          title={t('Delete')}
          sizing="dense"
          color={grayScale.mediumDark}
          onClick={onClickRemove}
        >
          <SmallIcon icon="trash" />
        </IconButton>
      </CloseButtonWrapper>
    </NotificationWrapper>
  );
};
