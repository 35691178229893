import { createContext, useContext, useCallback } from 'react';

// Contents are the output of setState({}), which is what should be used to initialize FormContext
export const ViewerContext = createContext([
  {},
  () =>
    import.meta.env.DEV &&
    console.warn(
      'FormContext called setValues() without being properly initialized.'
    ),
]);

export function useViewerContext() {
  return useContext(ViewerContext);
}

export function useBuilderInput(id, xform = (x) => x) {
  const { values, setValues, sortedFields } = useViewerContext();
  const value = values.current[id] ?? null;
  const setValue = useCallback(
    (val, err) => {
      setValues((vals) => {
        const fieldIndex = sortedFields.findIndex((item) => item.id === id);
        if (err) {
          if (!vals.errors) {
            vals.errors = new Map();
          }
          const error = {
            fieldId: id,
            order: fieldIndex,
            ...err,
          };
          vals.errors.set(id, error);
          return { ...vals, [id]: xform(val) };
        }
        // reset error for the field
        if (vals?.errors?.has(id)) {
          vals.errors.delete(id);
        }
        return { ...vals, [id]: xform(val) };
      });
    },
    [id, xform, setValues, sortedFields]
  );

  return [value, setValue];
}

export function useFormSubmit() {
  const { allFormValues, values, onSubmit } = useViewerContext();
  const { errors, ...rest } = allFormValues;
  return useCallback(
    (action, goToPage, recaptchaToken) => {
      return onSubmit(
        { ...rest, ...values.current },
        recaptchaToken,
        action,
        goToPage
      );
    },
    [onSubmit, rest, values]
  );
}
