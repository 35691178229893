import { useMemo, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import KizenTypography from 'app/kizentypo';
import { colorsButton, grayScale } from 'app/colors';
import { gutters, layers } from 'app/spacing';

const Wrapper = styled.div`
  display: flex;
  justify-content: ${({ showPageSizeOptions }) =>
    showPageSizeOptions ? 'space-between' : 'center'};
`;

const PageNavWrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin: 0 ${gutters.spacing(1, -2)}px 0 ${gutters.spacing(1)}px;

    &:first-of-type {
      margin: 0 ${gutters.spacing(1)}px 0 ${gutters.spacing(1, -2)}px;
    }
  }
`;

const selectOpenUpwards = ({ classNamePrefix: select }) => css`
  .${select}__control--menu-is-open {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    z-index: ${layers.content(0, 4)}; // Above the menu
  }
  .${select}__menu {
    border: 1px solid ${grayScale.medium};
    border-bottom: 0;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    z-index: ${layers.content(0, 4)}; // Above the menu
  }
  .${select}__menu-list {
    padding: 3px 0 0 0;
  }
`;

const PageSelect = styled(Select)`
  width: 82px;
  margin: 0 5px;
  ${selectOpenUpwards}
`;

const LeftSpacer = styled.div`
  width: ${({ showPageSizeOptions }) => (showPageSizeOptions ? 140 : 0)}px;
`;

const PageSizeSelect = styled(Select)`
  width: 140px;
  ${selectOpenUpwards}
`;

const PageButton = styled(IconButton)`
  width: 20px;
  height: 20px;

  && svg {
    height: 12px;
    width: auto;
  }
`;

export default function TablePagination({
  small = false,
  page,
  perPage,
  totalCount,
  onChangePage,
  onChangePerPage,
  showPageSizeOptions = true,
  ...others
}) {
  const { t } = useTranslation();
  const onChangePageRef = useRef(onChangePage);
  onChangePageRef.current = onChangePage;
  const totalPages = totalCount ? Math.ceil(totalCount / perPage) : 1; // Setting default 1 in case totalCount is undefined which breaks the page
  const pageOptions = useMemo(() => {
    return [...Array(totalPages)].map((_, i) => ({
      value: String(i + 1),
      label: String(i + 1),
    }));
  }, [totalPages]);

  const handleChangePage = useCallback(
    ({ value }) => onChangePage(Number(value)),
    [onChangePage]
  );

  useEffect(() => {
    if (page > totalPages) {
      onChangePageRef.current(totalPages);
    }
  }, [page, totalPages]);

  // We removed the 250 per page option in https://kizen.atlassian.net/browse/KZN-7512 but don't want to do
  // a migration, so instead do it on the fly
  useEffect(() => {
    if (perPage > 100) {
      onChangePerPage(100);
    }
  }, [perPage, onChangePerPage]);

  const handleChangePerPage = useCallback(
    ({ value }) => onChangePerPage(Number(value)),
    [onChangePerPage]
  );

  const handleGoPrevPage = useCallback(
    () => page > 1 && onChangePage(page - 1),
    [page, onChangePage]
  );

  const handleGoNextPage = useCallback(
    () => page < totalPages && onChangePage(page + 1),
    [page, onChangePage, totalPages]
  );
  return (
    <Wrapper showPageSizeOptions={showPageSizeOptions} {...others}>
      {!small && <LeftSpacer showPageSizeOptions={showPageSizeOptions} />}
      <PageNavWrapper>
        <PageButton
          sizing="dense"
          tooltipDisabled={page === 1}
          color={page === 1 ? grayScale.medium : colorsButton.iconGray}
          onClick={handleGoPrevPage}
          title={t('Previous Page')}
        >
          <Icon icon="chevron-left" />
        </PageButton>
        {!small && <KizenTypography as="span">{t('Page')}</KizenTypography>}
        <PageSelect
          fullWidth
          menuPlacement="top"
          classNamePrefix="PageSelect"
          value={String(page)}
          onChange={handleChangePage}
          options={pageOptions}
        />
        <KizenTypography as="span">{`${t(
          'of'
        )} ${totalPages}`}</KizenTypography>
        <PageButton
          sizing="dense"
          tooltipDisabled={page === totalPages}
          color={page === totalPages ? grayScale.medium : colorsButton.iconGray}
          onClick={handleGoNextPage}
          title={t('Next Page')}
        >
          <Icon icon="chevron-right" />
        </PageButton>
      </PageNavWrapper>
      {showPageSizeOptions ? (
        <PageSizeSelect
          fullWidth
          menuPlacement="top"
          classNamePrefix="PageSizeSelect"
          value={String(perPage)}
          onChange={handleChangePerPage}
          options={[
            {
              value: '10',
              label: t('10 Per Page'),
            },
            {
              value: '25',
              label: t('25 Per Page'),
            },
            {
              value: '50',
              label: t('50 Per Page'),
            },
            {
              value: '100',
              label: t('100 Per Page'),
            },
          ]}
        />
      ) : null}
    </Wrapper>
  );
}
