import { FIELD_TYPES } from 'utility/constants';

export const PILL_TYPES = {
  multiselect: {
    type: 'multiselect',
  },
  singleselect: {
    type: 'singleselect',
  },
  facetMultiselect: {
    type: 'facet_multiselect',
  },
  facetSingleselect: {
    type: 'facet_singleselect',
  },
  filterGroupMultiselect: {
    type: 'filter_group_multiselect',
  },
  number: {
    type: 'number',
  },
  date: {
    type: 'date',
  },
  text: {
    type: 'text',
  },
};

export const DISPLAY_NAME_PROPS_BY_FIELD_TYPE = {
  [FIELD_TYPES.TeamSelector.type]: {
    propName: 'employeeIds',
    responsePropName: 'employees',
  },
  [FIELD_TYPES.DynamicTags.type]: {
    propName: 'tagIds',
    responsePropName: 'tags',
  },
  [FIELD_TYPES.Relationship.type]: {
    propName: 'recordIds',
    responsePropName: 'records',
  },
  default: {
    propName: 'optionIds',
    responsePropName: 'options',
  },
};

export const CHECKBOX_FACET_VALUE = {
  checked: '1',
  unchecked: '0',
};
