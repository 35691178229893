import React, { useCallback, useEffect, useRef } from 'react';
import { components } from 'react-select';

const CustomSelectContainer = ({ children, ...props }) => {
  const container = useRef(null);
  const { selectProps } = props;
  const { setIsOpen } = selectProps;

  const handleClick = useCallback(
    (event) => {
      if (container.current && !container.current?.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleClick]);

  return (
    <div ref={container}>
      <components.SelectContainer {...props}>
        {children}
      </components.SelectContainer>
    </div>
  );
};

export default CustomSelectContainer;
