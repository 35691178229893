import { FIELD_TYPES } from 'utility/constants';
import { TFunction } from 'react-i18next';
import isNumber from 'lodash/isNumber';
import {
  getDate,
  formatTimestamp,
} from 'components/Inputs/DateTimeInput/helpers';
import { getName, isValidArray } from 'components/Fields/helpers';
import { SENDER_TYPE } from 'components/MessageBuilder/components';

import { Field, FieldOption } from 'pages/AutomationEngine/types';

import {
  EmailSendOptionsTeamMember,
  NotifyMemberViaEmailTypes,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/CommonComponents/email-send-options/types';

const numberFormatter = (string: any) => Intl.NumberFormat('en').format(string);

const moneyFormatter = (string: any) =>
  Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(string);

export const checkEmptyArray = (value: any) =>
  Array.isArray(value) && value?.length === 0 ? null : value;

export const getFieldValue = (
  {
    fieldToModify,
    specificFieldValue,
  }: { fieldToModify: Field; specificFieldValue: any },
  t: TFunction
) => {
  if (!fieldToModify) {
    return null;
  }

  if (specificFieldValue?.value?.deleted) {
    return `[${t('Deleted')}]`;
  }

  const { value } = specificFieldValue || {};

  let option: FieldOption | string | undefined = fieldToModify?.options?.find(
    ({ id }) => id === value?.id
  );

  switch (fieldToModify.fieldType) {
    case FIELD_TYPES.Dropdown.type:
    case FIELD_TYPES.Radio.type:
    case FIELD_TYPES.Status.type: {
      option =
        specificFieldValue?.label ||
        value?.name ||
        getName(
          fieldToModify.options.find(
            ({ id }: { id: any }) => id === value?.id || value
          )
        );
      break;
    }
    case FIELD_TYPES.TeamSelector.type: {
      option = specificFieldValue?.label || value?.displayName || '';
      break;
    }
    case FIELD_TYPES.Checkbox.type: {
      option = value ? t('Checked') : t('Not Checked');
      break;
    }
    case FIELD_TYPES.YesNo.type:
    case FIELD_TYPES.YesNoMaybe.type:
      option =
        specificFieldValue?.label ||
        getName(
          fieldToModify.options.find(({ id }: { id: any }) => id === value)
        );
      break;
    case FIELD_TYPES.Checkboxes.type: {
      if (Array.isArray(value)) {
        option = checkEmptyArray(
          (Boolean(value.length) && value[0].label && value[0].value) ||
            (value[0]?.id && value[0]?.name)
            ? value.map(({ label, name, deleted }) =>
                deleted ? `[${t('Deleted')}]` : label || name
              )
            : null
        );
      } else {
        option =
          specificFieldValue?.label ||
          checkEmptyArray(
            (value || []).map(
              (item: any) =>
                fieldToModify.options.find(
                  ({ id }: { id: any }) => id === item.id || id === item.value
                )?.name
            )
          );
      }
      break;
    }
    case FIELD_TYPES.Money.type:
      option = !isNaN(parseInt(value))
        ? `${fieldToModify?.moneyOptions?.symbol || ''}${moneyFormatter(
            isNumber(value) ? value.toString() : value
          )}`
        : '';
      break;

    case FIELD_TYPES.DateTime.type:
      option = formatTimestamp(value);
      break;

    case FIELD_TYPES.Date.type:
      option = getDate(value);
      break;

    case FIELD_TYPES.Text.type:
    case FIELD_TYPES.LongText.type:
    case FIELD_TYPES.Email.type:
      option = value ?? '';
      break;
    case FIELD_TYPES.Decimal.type:
    case FIELD_TYPES.Integer.type:
      option = isNaN(parseInt(value)) ? '' : numberFormatter(value) ?? '';
      break;

    case FIELD_TYPES.DynamicTags.type: {
      const { tagsToRemove, tagsToAdd } =
        (specificFieldValue?.value?.tagsToAdd
          ? specificFieldValue.value
          : specificFieldValue) || {};

      option = '';
      if (isValidArray(tagsToAdd)) {
        option = `${t('add')} ‘${tagsToAdd
          .map((tag: any) =>
            tag?.deleted ? `[${t('Deleted')}]` : tag?.label || tag?.name || ''
          )
          .join(', ')}’`;
      }

      if (isValidArray(tagsToRemove)) {
        if (isValidArray(tagsToAdd)) {
          option = `${option} ${t('and')} `;
        }
        option = `${option}${t('remove')} ‘${tagsToRemove
          .map((tag: any) =>
            tag?.deleted ? `[${t('Deleted')}]` : tag?.label || tag?.name || ''
          )
          .join(', ')}’`;
      }

      break;
    }

    case FIELD_TYPES.Files.type:
      option = value
        ? value.map(({ name }: { name: any }) => name).join(', ')
        : '';
      break;

    case FIELD_TYPES.Relationship.type:
      option = '';
      if (value) {
        option = isValidArray(value)
          ? value
              .map(({ label, deleted }: { label: any; deleted: any }) =>
                deleted ? `[${t('Deleted')}]` : label
              )
              .join(', ')
          : value.label;
      }
      break;

    default: {
      option = value;
    }
  }
  // wrap the value in an object to force re render if validation keeps the number the same...
  return option;
};

export const getValueOrDeleted = (label: any, t: TFunction, message = null) =>
  label || message || t('[Deleted]');

const {
  TEAM_MEMBER,
  LAST_ROLE,
  LAST_ANY,
  BUSINESS,
  TEAM_MEMBER_FIELD,
  SPECIFIC_INTEGRATED_INBOX,
} = SENDER_TYPE;

export const getSendEmailFromString = (
  { email }: { email: any },
  business: any,
  t: TFunction
) => {
  if (!email?.sender?.type) {
    return t('Unknown');
  }

  const roleLabel = getValueOrDeleted(email.senderRole?.label, t);

  const mapping = {
    [LAST_ANY]: t('Last Team Member (Any Role)'),
    [LAST_ROLE]: t('Last Team Member') + ` (${roleLabel} ` + t('Role)'),
    [TEAM_MEMBER]: t('Specific Team Member'),
    [TEAM_MEMBER_FIELD]: t('Custom Team Selector Field'),
    [BUSINESS]: business?.name || '',
    [SPECIFIC_INTEGRATED_INBOX]:
      email?.externalAccount?.label || t('[Deleted]'),
  };
  return email.sender.customName || mapping[email.sender.type];
};

export const getNotifyMemberViaEmailFromString = (
  teamMember: EmailSendOptionsTeamMember,
  t: TFunction
) => {
  const roleLabel = getValueOrDeleted(teamMember?.role?.label, t);
  const fieldLabel = getValueOrDeleted(teamMember?.field?.label, t);
  const mapping: Record<NotifyMemberViaEmailTypes, any> = {
    last_active: t('Last Team Member (Any Role)'),
    last_active_role: `${t('Last Team Member')} (${roleLabel} ${t('Role')})`,
    employees: (teamMember?.employees || [])
      .reduce(
        (acc: any[], employee: any) => [
          ...acc,
          getValueOrDeleted(employee?.label, t),
        ],
        []
      )
      .join(', '),
    owner: t('Owner'),
    team_selector_field: `${t(`Custom Team Selector`)} (${fieldLabel} ${t(
      'Field'
    )})`,
    none: '',
  };
  return mapping[teamMember.type];
};
