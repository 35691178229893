import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faChartBar } from '@fortawesome/pro-light-svg-icons/faChartBar';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faReceipt } from '@fortawesome/pro-light-svg-icons/faReceipt';
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons/faAlignJustify';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faSquarePollVertical } from '@fortawesome/pro-light-svg-icons/faSquarePollVertical';
import { faBullseyePointer } from '@fortawesome/pro-light-svg-icons/faBullseyePointer';
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { faCircleUser } from '@fortawesome/pro-light-svg-icons/faCircleUser';
import { faRotate } from '@fortawesome/pro-light-svg-icons/faRotate';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons/faArrowRightFromBracket';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons/faCircleQuestion';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faComments } from '@fortawesome/pro-light-svg-icons/faComments';
import { faHomeBlank } from '@fortawesome/pro-light-svg-icons/faHomeBlank';

import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faGears } from '@fortawesome/pro-light-svg-icons/faGears';
import { faScrewdriverWrench } from '@fortawesome/pro-light-svg-icons/faScrewdriverWrench';
import { faCodeBranch } from '@fortawesome/pro-light-svg-icons/faCodeBranch';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons/faUserGroup';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons/faShareNodes';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faUsersGear } from '@fortawesome/pro-light-svg-icons/faUsersGear';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faCompass } from '@fortawesome/pro-light-svg-icons/faCompass';
import { faCircle0 } from '@fortawesome/pro-light-svg-icons/faCircle0';
import { faCircle1 } from '@fortawesome/pro-light-svg-icons/faCircle1';
import { faCircle2 } from '@fortawesome/pro-light-svg-icons/faCircle2';
import { faCircle3 } from '@fortawesome/pro-light-svg-icons/faCircle3';
import { faCircle4 } from '@fortawesome/pro-light-svg-icons/faCircle4';
import { faCircle5 } from '@fortawesome/pro-light-svg-icons/faCircle5';
import { faCircle6 } from '@fortawesome/pro-light-svg-icons/faCircle6';
import { faCircle7 } from '@fortawesome/pro-light-svg-icons/faCircle7';
import { faCircle8 } from '@fortawesome/pro-light-svg-icons/faCircle8';
import { faCircle9 } from '@fortawesome/pro-light-svg-icons/faCircle9';
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faTable } from '@fortawesome/pro-light-svg-icons/faTable';
import { faTableColumns } from '@fortawesome/pro-regular-svg-icons/faTableColumns';
import {
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';
import { faMessages } from '@fortawesome/pro-light-svg-icons/faMessages';

export const SEMANTIC_CATEGORY: SemanticCategory = 'nav';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  'toolbar-dashboards': faChartBar,
  'toolbar-data': faDatabase,
  'toolbar-contacts': faUser,
  'toolbar-custom-objects': faLayerGroup,
  'toolbar-marketing-costs': faReceipt,
  'toolbar-platform': faBrowser,
  'toolbar-broadcasts': faCalendarDays,
  'toolbar-forms': faAlignJustify,
  'toolbar-activities': faClipboardList,
  'toolbar-library': faBook,
  'toolbar-surveys': faSquarePollVertical,
  'toolbar-ad-manager': faBullseyePointer,
  'toolbar-automations': faPlay,
  'toolbar-settings': faGear,
  'toolbar-profile': faCircleUser,
  'toolbar-change-business': faRotate,
  'toolbar-add-business': faPlus,
  'toolbar-logout': faArrowRightFromBracket,
  'toolbar-help': faCircleQuestion,
  'toolbar-knowledge-base': faGraduationCap,
  'toolbar-chat-with-us': faComments,
  'toolbar-homepage': faHomeBlank,

  'tab-profile': faIdCard,
  'tab-messages': faEnvelope,
  'tab-messages-alt': faMessages,
  'tab-automations': faPlay,
  'tab-record': faIdCard,
  'tab-settings': faGears,
  'tab-build': faScrewdriverWrench,
  'tab-advanced-rules': faCodeBranch,
  'tab-templates': faCopy,
  'tab-broadcasts': faCalendarDays,
  'tab-overview': faBullseyePointer,
  'tab-retargeting': faUserGroup,
  'tab-connections': faShareNodes,
  'tab-business-info': faBuilding,
  'tab-permissions': faUsersGear,
  'tab-customize-fields': faIdCard,
  'tab-domain-tracking': faGlobe,
  'tab-privacy': faShieldCheck,
  'tab-manage-connections': faKey,
  'tab-my-profile': faCircleUser,
  'tab-my-toolbar': faCompass,
  'tab-api-connections': faKey,
  'tab-my-dashboards': faCircleUser,
  'tab-access-request': faShareNodes,
  'tab-step-zero': faCircle0,
  'tab-step-one': faCircle1,
  'tab-step-two': faCircle2,
  'tab-step-three': faCircle3,
  'tab-step-four': faCircle4,
  'tab-step-five': faCircle5,
  'tab-step-six': faCircle6,
  'tab-step-seven': faCircle7,
  'tab-step-eight': faCircle8,
  'tab-step-nine': faCircle9,
  'tab-chart': faChartPie,
  'tab-table': faTable,
  'tab-board': faTableColumns,
};

export const labels: IconLabel = {
  'toolbar-dashboards': {
    title: (t) => '',
    description: (t) => t('Dashboards'),
  },
  'toolbar-data': {
    title: (t) => '',
    description: (t) => t('Data'),
  },
  'toolbar-contacts': {
    title: (t) => '',
    description: (t) => t('Contacts'),
  },
  'toolbar-custom-objects': {
    title: (t) => '',
    description: (t) => t('Custom Objects'),
  },
  'toolbar-marketing-costs': {
    title: (t) => '',
    description: (t) => t('Marketing Costs'),
  },
  'toolbar-platform': {
    title: (t) => '',
    description: (t) => t('Platform'),
  },
  'toolbar-broadcasts': {
    title: (t) => '',
    description: (t) => t('Broadcasts'),
  },
  'toolbar-forms': {
    title: (t) => '',
    description: (t) => t('Forms'),
  },
  'toolbar-activities': {
    title: (t) => '',
    description: (t) => t('Activities'),
  },
  'toolbar-library': {
    title: (t) => '',
    description: (t) => t('Library'),
  },
  'toolbar-surveys': {
    title: (t) => '',
    description: (t) => t('Surveys'),
  },
  'toolbar-ad-manager': {
    title: (t) => '',
    description: (t) => t('Ad Manager'),
  },
  'toolbar-automations': {
    title: (t) => '',
    description: (t) => t('Automations'),
  },
  'toolbar-settings': {
    title: (t) => '',
    description: (t) => t('Settings'),
  },
  'toolbar-profile': {
    title: (t) => '',
    description: (t) => t('Profile'),
  },
  'toolbar-change-business': {
    title: (t) => '',
    description: (t) => t('Change Business'),
  },
  'toolbar-add-business': {
    title: (t) => '',
    description: (t) => t('Add Business'),
  },
  'toolbar-logout': {
    title: (t) => '',
    description: (t) => t('Logout'),
  },
  'toolbar-help': {
    title: (t) => '',
    description: (t) => t('Help'),
  },
  'toolbar-knowledge-base': {
    title: (t) => '',
    description: (t) => t('Knowledge Base'),
  },
  'toolbar-chat-with-us': {
    title: (t) => '',
    description: (t) => t('Chat With Us'),
  },
  'toolbar-homepage': {
    title: (t) => '',
    description: (t) => t('Homepage'),
  },
  'tab-profile': {
    title: (t) => '',
    description: (t) => t('Profile'),
  },
  'tab-messages': {
    title: (t) => '',
    description: (t) => t('Messages'),
  },
  'tab-messages-alt': {
    title: (t) => '',
    description: (t) => t('Messages'),
  },
  'tab-automations': {
    title: (t) => '',
    description: (t) => t('Automations'),
  },
  'tab-record': {
    title: (t) => '',
    description: (t) => t('Record'),
  },
  'tab-settings': {
    title: (t) => '',
    description: (t) => t('Settings'),
  },
  'tab-build': {
    title: (t) => '',
    description: (t) => t('Build'),
  },
  'tab-advanced-rules': {
    title: (t) => '',
    description: (t) => t('Advanced Rules'),
  },
  'tab-templates': {
    title: (t) => '',
    description: (t) => t('Templates'),
  },
  'tab-broadcasts': {
    title: (t) => '',
    description: (t) => t('Broadcasts'),
  },
  'tab-overview': {
    title: (t) => '',
    description: (t) => t('Overview'),
  },
  'tab-retargeting': {
    title: (t) => '',
    description: (t) => t('Retargeting'),
  },
  'tab-connections': {
    title: (t) => '',
    description: (t) => t('Connections'),
  },
  'tab-business-info': {
    title: (t) => '',
    description: (t) => t('Business Info'),
  },
  'tab-permissions': {
    title: (t) => '',
    description: (t) => t('Permissions'),
  },
  'tab-customize-fields': {
    title: (t) => '',
    description: (t) => t('Customize Fields'),
  },
  'tab-domain-tracking': {
    title: (t) => '',
    description: (t) => t('Domain Tracking'),
  },
  'tab-privacy': {
    title: (t) => '',
    description: (t) => t('Privacy'),
  },
  'tab-manage-connections': {
    title: (t) => '',
    description: (t) => t('Manage Connections'),
  },
  'tab-my-profile': {
    title: (t) => '',
    description: (t) => t('My Profile'),
  },
  'tab-my-toolbar': {
    title: (t) => '',
    description: (t) => t('My Toolbar'),
  },
  'tab-api-connections': {
    title: (t) => '',
    description: (t) => t('API Connections'),
  },
  'tab-my-dashboards': {
    title: (t) => '',
    description: (t) => t('My Dashboards'),
  },
  'tab-access-request': {
    title: (t) => '',
    description: (t) => t('Access Request'),
  },
  'tab-step-zero': {
    title: (t) => '',
    description: (t) => t('Step Zero'),
  },
  'tab-step-one': {
    title: (t) => '',
    description: (t) => t('Step One'),
  },
  'tab-step-two': {
    title: (t) => '',
    description: (t) => t('Step Two'),
  },
  'tab-step-three': {
    title: (t) => '',
    description: (t) => t('Step Three'),
  },
  'tab-step-four': {
    title: (t) => '',
    description: (t) => t('Step Four'),
  },
  'tab-step-five': {
    title: (t) => '',
    description: (t) => t('Step Five'),
  },
  'tab-step-six': {
    title: (t) => '',
    description: (t) => t('Step Six'),
  },
  'tab-step-seven': {
    title: (t) => '',
    description: (t) => t('Step Seven'),
  },
  'tab-step-eight': {
    title: (t) => '',
    description: (t) => t('Step Eight'),
  },
  'tab-step-nine': {
    title: (t) => '',
    description: (t) => t('Step Nine'),
  },
  'tab-chart': {
    title: (t) => '',
    description: (t) => t('Chart'),
  },
  'tab-table': {
    title: (t) => '',
    description: (t) => t('Table'),
  },
  'tab-board': {
    title: (t) => '',
    description: (t) => t('Board'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};
