import React from 'react';

export const INT_MAX_VALUE = 2000000001;
export const INT_MIN_VALUE = -2000000001;
export const FLOAT_MAX_VALUE = 1000000000000;
export const FLOAT_MIN_VALUE = -1000000000000;

export const toWholeNumber = (str) => {
  if (!str) return null;
  const num = Number.parseInt(str.replace(/,/g, ''), 10);
  if (Number.isNaN(num)) return null;
  return num;
};

const wholeNumberAllowed = (str) => {
  const num = toWholeNumber(str);
  if (num === null) return false;

  if (Number.isNaN(num)) return false;
  return num > INT_MIN_VALUE && num < INT_MAX_VALUE;
};

export const toWholeNumberValidated = (str, orgValue) => {
  const num = toWholeNumber(str);
  if (num === null) return null;

  if (Number.isNaN(num)) return null;
  if (num > INT_MIN_VALUE && num < INT_MAX_VALUE) return num;
  if (orgValue) return orgValue;
  return ''; // return empty string so the bad number updates
};

export const toDecimalNumber = (str) => {
  if (!str) return null;
  const num = Number.parseFloat(str.replace(/,/g, ''));
  if (Number.isNaN(num)) return null;
  return num;
};

const decimalNumberAllowed = (str) => {
  const num = toDecimalNumber(str);
  if (num === null) return false;

  if (Number.isNaN(num)) return false;
  return num > FLOAT_MIN_VALUE && num < FLOAT_MAX_VALUE;
};

export const toDecimalNumberValidated = (str, orgValue) => {
  const num = toDecimalNumber(str);
  if (num === null) return null;

  if (num > FLOAT_MIN_VALUE && num < FLOAT_MAX_VALUE) return num;
  if (orgValue) return orgValue;
  return ''; // return empty string so the bad number updates
};

export const toPriceNumberValidated = toDecimalNumberValidated;

export const checkAllowed = (
  inputObj,
  decimalOptions = null,
  isInteger = false
) => {
  const { value: inputValue, floatValue } = inputObj;

  // an empty string is valid
  if (floatValue == null) {
    return true;
  }

  if (decimalOptions) {
    return (
      (decimalOptions.maxValue
        ? parseFloat(decimalOptions.maxValue) >= floatValue
        : decimalNumberAllowed(`${floatValue}`)) &&
      (decimalOptions.minValue
        ? parseFloat(decimalOptions.minValue) <= floatValue
        : decimalNumberAllowed(`${floatValue}`))
    );
  }

  return isInteger
    ? wholeNumberAllowed(inputValue)
    : decimalNumberAllowed(`${floatValue}`);
};

export const withDecimalNumberValidation = (Component) => {
  // return the Component wrapped with our intecepted validation
  return React.forwardRef((props, ref) => {
    const onChange = (val) => {
      // call the components onChange with the number validated
      props.onChange(toDecimalNumberValidated(val, props.value));
    };

    return <Component ref={ref} {...props} onChange={onChange} />;
  });
};

export const withOnValueChangeDecimalNumberValidation = (Component) => {
  // return the Component wrapped with our intecepted validation
  return React.forwardRef((props, ref) => {
    const onValueChange = (obj) => {
      // call the components onChange with the number validated
      props.onChange(toDecimalNumberValidated(obj.value, props.value));
    };

    return <Component ref={ref} {...props} onValueChange={onValueChange} />;
  });
};

export const withWholeNumberValidation = (Component) => {
  // return the Component wrapped with our intecepted validation

  return React.forwardRef((props, ref) => {
    const onChange = (val) => {
      // call the components onChange with the number validated
      props.onChange(toWholeNumberValidated(val, props.value));
    };

    return <Component ref={ref} {...props} onChange={onChange} />;
  });
};
