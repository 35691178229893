import { useCallback, useContext } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { VALUE_RESPONSES } from 'components/Wizards/RTDV/types';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import { StyledSwitch } from '../styles';

const AverageValueType = () => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const { t } = useTranslation();

  const { value, includeBlank } = state;

  const onChangeIncludeBlank = useCallback(
    (e) => {
      return setState('includeBlank', e.target.checked);
    },
    [setState]
  );

  if (value?.value !== VALUE_RESPONSES.AVERAGE) {
    return null;
  }

  return (
    <StyledWizardField top={28}>
      <KizenTypography type="subheader">
        {t('Include Blank Values as 0 in Average')}
      </KizenTypography>
      <StyledWizardField top={6}>
        <StyledSwitch
          checked={includeBlank ?? false}
          onChange={onChangeIncludeBlank}
          className="include-blank-switch"
        />
      </StyledWizardField>
    </StyledWizardField>
  );
};

export default AverageValueType;
