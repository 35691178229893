import { forwardRef, useEffect, useRef, useState } from 'react';
import {
  StyledBadge,
  StyledDownArrow,
  StyledPill,
  StyledPillText,
} from '../styles';
import { grayScale } from 'app/colors';
import { useTooltip } from 'components/Kizen/Tooltip';

export const PillDropdownControl = forwardRef(
  ({ title, onClick, counts, isError, isOpen, isReadOnly }, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const [enabledTooltip, setEnebledTooltip] = useState(false);

    const textRef = useRef(null);

    const [{ onMouseEnter, onMouseLeave, ...tooltipProps }, tooltip] =
      useTooltip({
        label: title,
        delay: 500,
        popperConfig: {
          modifiers: {
            preventOverflow: { boundariesElement: 'viewport' },
            offset: { offset: '0px, -8px' },
          },
        },
      });

    const handleMouseEnter = (e) => {
      onMouseEnter(e);
      setIsHovered(true);
    };

    const handleMouseLeave = (e) => {
      onMouseLeave(e);
      setIsHovered(false);
    };

    useEffect(() => {
      if (textRef.current) {
        const { scrollWidth, offsetWidth } = textRef.current;
        setEnebledTooltip(scrollWidth > offsetWidth);
      }
    }, []);

    return (
      <StyledPill
        ref={ref}
        onClick={onClick}
        isError={isError}
        isOpen={isOpen}
        isReadOnly={isReadOnly}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...tooltipProps}
      >
        {enabledTooltip ? tooltip : null}
        <StyledPillText ref={textRef}>{title}</StyledPillText>
        {counts ? <StyledBadge isError={isError}>{counts}</StyledBadge> : null}
        <StyledDownArrow
          icon="chevron-down"
          color={{
            light: isOpen || isHovered ? grayScale.dark : grayScale.mediumDark,
            dark: grayScale.dark,
          }}
        />
      </StyledPill>
    );
  }
);

PillDropdownControl.displayName = 'PillDropdownControl';
