import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import Select from 'components/Kizen/Select';

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  margin-left: ${gutters.spacing(4)}px;

  button {
    flex: 1;
    margin: 0;
    height: 28px;
    padding: 0;

    i + i {
      padding: 0 0 0 ${gutters.spacing(2, -2)}px;
    }
  }
`;

export const ButtonMenu = styled(Select)`
  > :first-child {
    width: 152px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .ButtonMenu__control {
    display: none;
  }
  .ButtonMenu__menu {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    max-width: 100%;
    border: 1px solid ${grayScale.medium};
    border-radius: 4px;
  }
`;
