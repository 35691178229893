import { useMemo } from 'react';
import { Paragraph, StyledIcon, StyledMenuContainer, Wrapper } from './styles';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import HandleIcon from '../Dashlet/HandleIcon';
import DashletMenuController from '../Dashlet/MenuController';

const DashletError = ({
  onDelete,
  onRefresh,
  error,
  isDraggable,
  dashlet,
  editDashlet,
  clientObjectId,
  ModalComponent,
  model,
  override,
  modalComponentProps,
  charts,
  canEdit,
  padding,
  center,
}) => {
  const { t } = useTranslation();

  const errorValues = useMemo(() => {
    return Object.values(error?.response?.data?.errors ?? {});
  }, [error]);

  const useErrors =
    error?.response?.data?.errors &&
    typeof error.response.data.errors === 'object' &&
    errorValues.length &&
    errorValues.every((e) => typeof e === 'string');

  return (
    <Wrapper data-qa-error-status={error?.response?.status} padding={padding}>
      <StyledIcon icon="warning" />

      {canEdit && (
        <>
          {isDraggable && <HandleIcon />}
          <StyledMenuContainer>
            <DashletMenuController
              dashlet={dashlet}
              editDashlet={editDashlet}
              onDeleteDashlet={onDelete}
              clientObjectId={clientObjectId}
              ModalComponent={ModalComponent}
              model={model}
              modalComponentProps={modalComponentProps}
              entity={charts ? t('Chart') : t('Dashlet')}
            />
          </StyledMenuContainer>
        </>
      )}

      <Paragraph data-qa-id="dashlet-permission-error-text" center={center}>
        {override ? (
          override
        ) : (
          <>
            {useErrors ? (
              <>
                {Object.values(error.response.data.errors).map((error) => {
                  return error;
                })}{' '}
                {t('If the error persists, try')}
              </>
            ) : (
              t(
                'This {{entity}} is attempting to display missing or invalid data. Try',
                {
                  entity: charts ? t('chart') : t('dashlet'),
                }
              )
            )}{' '}
            <KizenTypography
              type="anchor"
              size="inherit"
              tabIndex="0" // Affects styles per bootstrap
              onClick={onRefresh}
            >
              {t('reloading the {{entity}}', {
                entity: charts ? t('chart') : t('dashlet'),
              })}
            </KizenTypography>
            {canEdit ? (
              <>
                {' '}
                {t('or')}{' '}
                <KizenTypography
                  type="anchor"
                  size="inherit"
                  tabIndex="0" // Affects styles per bootstrap
                  onClick={onDelete}
                >
                  {t('deleting the {{entity}}', {
                    entity: charts ? t('chart') : t('dashlet'),
                  })}
                </KizenTypography>
                .{' '}
              </>
            ) : (
              <>. </>
            )}
            {t(
              'If you continue to experience issues, please reach out to us at'
            )}{' '}
            <KizenTypography
              type="anchor"
              size="inherit"
              href="mailto:support@kizen.com"
            >
              Support@Kizen.com
            </KizenTypography>
            .
          </>
        )}
      </Paragraph>
    </Wrapper>
  );
};

export default DashletError;
