import React from 'react';

// Example:
//
// enrichEl(el, { some: 'state' })
//
export const enrichEl = (el, props) => {
  if (!el || !el.type || !el.type.enrich) {
    return el;
  }
  const { enrich } = el.type;
  return React.cloneElement(
    el,
    Object.entries(enrich)
      .filter(([, include]) => include)
      .reduce((collect, [prop]) => {
        if (prop in props) {
          collect[prop] = props[prop];
        }
        return collect;
      }, {})
  );
};

export const ignoreSsrWarning =
  '/* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */';

export const integerToOption = (num) => {
  if (!num && num !== 0) return null;
  return { label: num.toLocaleString(), value: num.toString() };
};

export const optionToInteger = (val) => {
  if (!val) return null;
  const num = Number.parseInt(val.value, 10);
  if (Number.isNaN(num)) return null;
  return num;
};

export const getNumberOptions = (count) =>
  [...Array(count)].map((_, i) => integerToOption(i + 1));

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const popperModifiers = {
  addStyles: (styles) => {
    return {
      name: 'addStyles',
      enabled: true,
      phase: 'beforeWrite',
      fn({ state }) {
        // Object.assign is important to keep a link to original object. we cannot use destruction here
        Object.assign(state.styles.popper, styles);
      },
    };
  },
  calcStyles: (callback) => {
    return {
      name: 'calcStyles',
      enabled: true,
      phase: 'beforeWrite',
      fn({ state }) {
        // Object.assign is important to keep a link to original object. we cannot use destruction here
        Object.assign(state.styles.popper, callback(state.styles.popper));
      },
    };
  },
  offSet: (a, b) => {
    return {
      name: 'offset',
      options: {
        offset: [a, b],
      },
    };
  },
};
