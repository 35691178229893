import React from 'react';
import * as PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import ConfirmationModal from './ConfirmationModal';

export default function ConfirmDeletionModal({
  // disabled is captured to prevent accidental overwrite
  // for this modal, we only ever want to manage disabled state internally
  disabled, // eslint-disable-line react/prop-types
  onHide,
  onConfirm,
  children,
  ...others
}) {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      buttonText={t('Confirm Delete')}
      heading={t('Please Confirm Deletion')}
      defaultLeftBtnText={t('Cancel')}
      onConfirm={onConfirm}
      onHide={onHide}
      {...others}
    >
      {children}
    </ConfirmationModal>
  );
}

ConfirmDeletionModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
