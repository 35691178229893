import { Ref, forwardRef } from 'react';
import { Spacer } from '../Spacer/Spacer';
import { Padding, Spacing } from '../spacing';
import { getKDSClasses, merge } from '../util';
import { TileContext } from './context';
import { PanelCount, TileDimensions, TileOrientation } from './types';
import { getPaddingClass } from './util';

interface SharedProps {
  padding?: Padding;
  gap?: Spacing;
  dimensions?: TileDimensions;
  enableBoxShadow?: boolean;
  transparent?: boolean;
}

export interface HorzontalTileProps extends SharedProps {
  center: any;
  left?: any;
  right?: any;
  divideEvenly?: boolean;
}

export interface VerticalTileProps extends SharedProps {
  middle: any;
  top?: any;
  bottom?: any;
}

interface TileProps {
  children: any;
  padding: Padding;
  gap: Spacing;
  dimensions?: TileDimensions;
  orientation: TileOrientation;
  panelCount: PanelCount;
  spacerCount: number;
  divideEvenly?: boolean;
  enableBoxShadow?: boolean;
  transparent?: boolean;
}

const Tile = forwardRef(
  (props: TileProps, forwardedRef: Ref<HTMLDivElement>) => {
    const {
      children,
      padding,
      dimensions,
      orientation,
      panelCount,
      spacerCount,
      gap,
      divideEvenly,
      enableBoxShadow = true,
      transparent = false,
    } = props;

    const paddedClasses = getPaddingClass(padding);
    const orientationClasses =
      orientation === 'horizontal' ? 'flex-row' : 'flex-col';

    const classes = merge(
      getKDSClasses('tile', orientation),
      'rounded-[8px] border-1 inline-flex relative @container/Tile w-full',
      enableBoxShadow && 'shadow-tile',
      transparent
        ? 'bg-background-transparent border-transparent'
        : 'bg-background-white border-border-light',
      orientationClasses,
      paddedClasses
    );

    return (
      <TileContext.Provider
        value={{
          orientation,
          panelCount,
          spacerCount,
          padding,
          gap,
          dimensions,
          divideEvenly,
        }}
      >
        <div className={classes} style={dimensions} ref={forwardedRef}>
          {children}
        </div>
      </TileContext.Provider>
    );
  }
);

export const HorizontalTile = forwardRef(
  (props: HorzontalTileProps, forwardedRef: Ref<HTMLDivElement>) => {
    const {
      padding = 20,
      gap = 20,
      dimensions,
      left,
      center,
      right,
      divideEvenly,
      enableBoxShadow,
      transparent,
    } = props;
    const showFirstSpacer = gap && left;
    const showSecondSpacer = gap && right;

    return (
      <Tile
        padding={padding}
        gap={gap}
        dimensions={dimensions}
        orientation="horizontal"
        enableBoxShadow={enableBoxShadow}
        transparent={transparent}
        panelCount={[left, center, right].filter(Boolean).length as PanelCount}
        spacerCount={[showFirstSpacer, showSecondSpacer].filter(Boolean).length}
        divideEvenly={divideEvenly}
        ref={forwardedRef}
      >
        {left}
        {showFirstSpacer ? <Spacer mode="vertical" size={gap} /> : null}
        {center}
        {showSecondSpacer ? <Spacer mode="vertical" size={gap} /> : null}
        {right}
      </Tile>
    );
  }
);

export const VerticalTile = forwardRef(
  (props: VerticalTileProps, forwardedRef: Ref<HTMLDivElement>) => {
    const {
      top,
      middle,
      bottom,
      padding = 20,
      gap = 20,
      dimensions,
      enableBoxShadow,
      transparent,
    } = props;
    const showFirstSpacer = gap && top;
    const showSecondSpacer = gap && bottom;

    return (
      <Tile
        padding={padding}
        gap={gap}
        dimensions={dimensions}
        orientation="vertical"
        enableBoxShadow={enableBoxShadow}
        transparent={transparent}
        panelCount={[top, middle, bottom].filter(Boolean).length as PanelCount}
        spacerCount={[showFirstSpacer, showSecondSpacer].filter(Boolean).length}
        ref={forwardedRef}
      >
        {top}
        {showFirstSpacer ? <Spacer mode="horizontal" size={gap} /> : null}
        {middle}
        {showSecondSpacer ? <Spacer mode="horizontal" size={gap} /> : null}
        {bottom}
      </Tile>
    );
  }
);

export * from './Panel';
