import React from 'react';
import { css, Global } from '@emotion/core';
import { range } from 'utility/iterables';

export const SUPPORTED_NESTED_MODALS = 5;

const nestedClassName = (className) => (acc, nestedLevel) => {
  if (nestedLevel === 0) return acc;
  if (nestedLevel === 1) return `.${className} ~ .${className}`;
  return `${acc} ~ .${className}`;
};

/**
 * 1st modal: backdrop = 1040; modal = 1050
 * 2nd modal: backdrop = 2040; modal = 2050
 * 3rd modal: backdrop = 3040; modal = 3050
 * ...
 */
export const zIndexModalsControl = (layer = 0, fudge = 0) => {
  return 1000 * (layer + 1) + 50 + fudge;
};

const modalCss = css`
  ${Array.from(range(1, SUPPORTED_NESTED_MODALS)).reduce((acc, nestedLevel) => {
    const iter = Array.from(range(1, nestedLevel + 1));
    const modalClass = iter.reduce(nestedClassName('modal'), '');
    const backdropClass = iter.reduce(nestedClassName('modal-backdrop'), '');
    return `
      ${acc}
      ${backdropClass} {
        z-index: ${zIndexModalsControl(nestedLevel, -10)};
      }
      ${modalClass} {
        z-index: ${zIndexModalsControl(nestedLevel)};
      }
    `;
  }, '')}
`;

export const ModalBackdrop = () => <Global styles={modalCss} />;

export default ModalBackdrop;
