import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { background } from 'app/colors';

const flexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const flexColumnCenter = css`
  ${flexCenter};
  flex-direction: column;
`;

const commonTopBlockStyles = css`
  ${flexCenter};
  background-color: ${background};
  height: 50px;
  width: 100%;
  padding: 15px 0 15px 10px;
  gap: 10px;
  p {
    margin-top: 1px;
  }
`;

const commonBottomBlockStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  height: 20px;
  width: 100%;
  border-top: 1px solid var(--color-border-medium);
  border-bottom: 1px solid var(--color-border-medium);
  p {
    margin-top: 0;
  }
`;

export const AggregateGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.18fr 1fr 0.18fr 1fr;
`;

export const Block = styled.div`
  ${flexColumnCenter};
  gap: 5px;
`;

export const TopBlockStart = styled.div`
  ${commonTopBlockStyles};
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-top-left-radius: 8px 8px 0 0;
`;

export const TopBlockCenter = styled.div`
  ${commonTopBlockStyles};
  justify-content: space-between;
  border-top-left-radius: 8px 8px 0 0;
`;

export const TopBlockEnd = styled.div`
  ${commonTopBlockStyles};
  padding-left: 30px;
  justify-content: center;
  border-top-right-radius: 8px;
  border-top-right-radius: 8px 8px 0 0;
`;

export const LeftAligned = styled.div`
  ${(props) => (props.minSize ? flexColumnCenter : flexCenter)};
  gap: ${({ minSize }) => (minSize ? '0px' : '10px')};
`;

export const RightAligned = styled.div`
  width: 50px;
`;

export const BottomBlockStart = styled.div`
  ${commonBottomBlockStyles};
  border-left: 1px solid var(--color-border-medium);
`;

export const BottomBlockCenter = styled.div`
  ${commonBottomBlockStyles};
  justify-content: end;
`;

export const BottomBlockEnd = styled.div`
  ${commonBottomBlockStyles};
  border-right: 1px solid var(--color-border-medium);
`;
