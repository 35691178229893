import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colorsButton } from 'app/colors';
import { StyledIconMenu } from '../styles';
import IconButtonAbsoluteMenu from 'components/Tables/Big/IconButtonAbsoluteMenu';
import {
  getCanAdmin,
  getCanElevatePermissions,
  getCanView,
} from 'components/AccessRequests/utils';

export const ActionCell = ({ onChange, data, items }) => {
  const { t } = useTranslation();

  const openMenuAbove = useMemo(() => {
    const count = items.length;
    const index = items.findIndex((item) => item.id === data.id);

    return Math.max(10, count) - index <= 2;
  }, [items, data.id]);

  const options = useMemo(() => {
    const result = [];

    if (getCanAdmin(data)) {
      result.push({
        value: 'edit_sharing',
        label: t('Edit'),
      });
    }

    if (getCanView(data)) {
      result.push(
        {
          value: 'edit_visibility',
          label: data.hidden ? t('Show') : t('Hide'),
        },
        {
          value: 'duplicate',
          label: t('Duplicate'),
        }
      );
    }

    if (getCanElevatePermissions(data)) {
      result.push({
        value: 'request',
        label: t('Request Access'),
      });
    }

    if (getCanAdmin(data)) {
      result.push({
        value: 'delete',
        label: t('Delete'),
      });
    }

    return result;
  }, [data, t]);

  if (!options.length) {
    return null;
  }

  return (
    <IconButtonAbsoluteMenu
      position="right"
      sizing="dense"
      color={colorsButton.iconGray}
      title={getCanView(data) ? t('Edit') : t('Request Access')}
      popperConfig={{
        modifiers: {
          offset: { offset: '0, 8px' }, // Touch top of text
          preventOverflow: { enabled: false },
        },
      }}
      screenPositionAdjust={{ top: openMenuAbove ? -10 : -15, left: 5 }}
      options={options}
      onChange={(res) => onChange(res, data)}
      above={openMenuAbove}
    >
      <StyledIconMenu icon="three-dot" />
    </IconButtonAbsoluteMenu>
  );
};
