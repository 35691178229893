import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
  ActionButtonMenu,
} from 'components/Layout/ListCard';
import ColorLabel from 'components/Fields/ColorLabel';
import { TextEllipsis } from 'components/Kizen/Table';
import { gutters } from 'app/spacing';
import {
  actions,
  adManagerStatus,
  statusColors,
  WrapperStatus,
} from './columns';
import { capitalize } from 'lodash/string';
import { useTranslation } from 'react-i18next';

const NO_VALUE = '—';

export function ConnectionsListItem({ adAccount, onSelectAction, ...others }) {
  const { t } = useTranslation();
  const { status, adProvider } = adAccount;
  const color = statusColors[status];

  const options = [
    status === 'active'
      ? { value: actions.deactivate, label: t('Deactivate') }
      : { value: actions.resync, label: t('Re-Sync') },
    { value: actions.delete, label: t('Delete') },
  ];
  const handleOnSelectAction = useCallback(
    (value) => {
      onSelectAction(value, adAccount);
    },
    [adAccount, onSelectAction]
  );

  return (
    <ListCardItem
      action={
        <ActionButtonMenu
          sizing="dense"
          color={colorsButton.iconGray}
          onChange={handleOnSelectAction}
          options={options}
        >
          <Icon icon="three-dot" style={{ width: 15 }} />
        </ActionButtonMenu>
      }
      actionStyles={{ paddingTop: `${gutters.spacing(2)}px` }}
      {...others}
    >
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis as="span">{adAccount.name || NO_VALUE}</TextEllipsis>
        </ListCardCell>
      </ListCardRow>
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis as="span">
            {adProvider === 'google' ? t('Google Ads') : t('Facebook Ads')}
          </TextEllipsis>
        </ListCardCell>
        <ListCardCell>
          <WrapperStatus>
            <ColorLabel color={color}>
              {capitalize(adManagerStatus(t)[status])}
            </ColorLabel>
          </WrapperStatus>
        </ListCardCell>
      </ListCardRow>
    </ListCardItem>
  );
}

ConnectionsListItem.propTypes = {
  adAccount: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    status: PropTypes.string,
    adProvider: PropTypes.string,
  }).isRequired,
  onSelectAction: PropTypes.func.isRequired,
};

export function ConnectionsListHeader({
  label,
  sort,
  onChangeSort,
  ...others
}) {
  return (
    <ListCardHeader
      compactHeader
      action={<div style={{ width: 15 }} />}
      {...others}
    >
      <ListCardCell>
        <TextEllipsis as="span" weight="bold">
          {label}
        </TextEllipsis>
        <ListCardSortButton sort={sort} onChangeSort={onChangeSort} />
      </ListCardCell>
    </ListCardHeader>
  );
}

ConnectionsListHeader.propTypes = {
  label: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }),
  onChangeSort: PropTypes.func,
};

ConnectionsListHeader.defaultProps = {
  sort: null,
  onChangeSort: null,
};
