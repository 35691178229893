import { TFunction } from 'react-i18next';
import { format } from 'date-fns';
import {
  SMART_CONNECTOR_TYPES,
  STEP_DATA_BY_STEP,
  VARIABLE_TYPES,
} from '../constants';
import {
  GenericOption,
  LoadVariable,
  SmartConnector,
  SmartConnectorBase,
  SmartConnectorDTO,
  SmartConnectorStep,
  SmartConnectorVariable,
} from '../types';

export const getSmartConnectorTypeOptions = (
  t: TFunction,
  preRelease: boolean
) =>
  [
    {
      value: SMART_CONNECTOR_TYPES.spreadsheet,
      label: t('Spreadsheet Style Upload (Optional SQL)'),
    },
    preRelease && {
      value: SMART_CONNECTOR_TYPES.polling,
      label: t('Polling Third Party API (GET)'),
    },
    preRelease && {
      value: SMART_CONNECTOR_TYPES.direct_api,
      label: t('Direct API Connection'),
    },
  ].filter(Boolean);

export const getScriptResultMessage = (
  executionResult: Record<string, any> | null,
  t: TFunction
): string => {
  if (!executionResult) {
    return '';
  }
  const firstLine = t('Test ID {{executionId}} ran at {{timestamp}}', {
    executionId: executionResult.id?.split('-')[0] || '',
    timestamp: format(executionResult.completed_at, 'YYYY-MM-DD hh:mm:ss'),
  });
  const secondLine =
    executionResult.meta.error ||
    t(
      'Script ran successfully and generated: {{num_columns}} columns | {{num_rows}} rows',
      executionResult.meta.stats
    );
  const thirdLine = executionResult.meta.error_details || '';

  return [firstLine, secondLine, thirdLine].filter(Boolean).join('\n');
};

export const toHtml = (value: string = '') => {
  return {
    type: 'doc',
    content: value
      .split('\n')
      .map((text) =>
        text
          ? { type: 'paragraph', content: [{ type: 'text', text }] }
          : { type: 'paragraph' }
      ),
  };
};

export const getStepData = (
  data: SmartConnector | SmartConnectorBase,
  step: SmartConnectorStep
) => {
  const stepProps = STEP_DATA_BY_STEP[step];

  return stepProps.reduce<Partial<SmartConnector>>((acc, prop) => {
    return {
      ...acc,
      [prop]: data[prop],
    };
  }, {});
};

export const getSmartConnectorDTO = (data: Partial<SmartConnector>) => {
  const {
    last_draft_script,
    live_script,
    source_file,
    execution_variables,
    ...rest
  } = data;

  const payload: SmartConnectorDTO = rest;

  if (source_file) {
    payload.source_file_id = source_file.id;
  }

  if (execution_variables) {
    payload.execution_variables = execution_variables.map((variable, i) => {
      return {
        ...variable,
        display_order: i,
      };
    });
  }

  return payload;
};

export const getUniqueName = (value: string, names: string[]) => {
  let nextValue = (value as string).trim();

  const uniqueNames = new Set(names);

  const copyRegEx = new RegExp(`^.+\\s+\\(#\\d+\\)$`);
  const copyNumberRegEx = new RegExp(`\\(#\\d+\\)$`);

  if (uniqueNames.has(nextValue)) {
    let i = 1;
    do {
      nextValue = nextValue.match(copyRegEx)
        ? nextValue.replace(copyNumberRegEx, `(#${i})`)
        : `${nextValue} (#${i})`;
      i++;
    } while (uniqueNames.has(nextValue));
  }

  return nextValue;
};

export const getSmartConnectorVariables = ({
  execution_variables,
  flow,
}: Pick<
  SmartConnector,
  'execution_variables' | 'flow'
>): SmartConnectorVariable[] => {
  const executionVariables = execution_variables || [];
  const additionalVariables = flow.additional_variables || [];
  const loadsVariables = (flow.loads || []).reduce((acc, load) => {
    if (load.execution_variable) {
      acc.push(load.execution_variable);
    }
    return acc;
  }, [] as LoadVariable[]);

  return [...executionVariables, ...additionalVariables, ...loadsVariables];
};

export const getSmartConnectorAccessibleVariables = (
  {
    execution_variables,
    flow,
  }: Pick<SmartConnector, 'execution_variables' | 'flow'>,
  loadIndex: number
): SmartConnectorVariable[] => {
  if (loadIndex !== undefined) {
    const executionVariables = execution_variables || [];
    const additionalVariables = flow.additional_variables || [];
    const loadsVariables = flow.loads.reduce(
      (acc, { execution_variable }, i) => {
        if (execution_variable && loadIndex > i) {
          acc.push(execution_variable);
        }
        return acc;
      },
      [] as LoadVariable[]
    );

    return [...executionVariables, ...additionalVariables, ...loadsVariables];
  }
  return getSmartConnectorVariables({ execution_variables, flow });
};

const groupOrder = {
  [VARIABLE_TYPES.related_object_upload]: 0,
  [VARIABLE_TYPES.data_source]: 1,
  [VARIABLE_TYPES.additional]: 2,
};

export const getVariablesGroupedOptions = (
  variables: SmartConnectorVariable[],
  t: TFunction
) => {
  return variables
    .reduce(
      (acc, variable) => {
        const index = variable.type ? groupOrder[variable.type] : -1;
        if (acc[index]) {
          acc[index].options.push({
            value: variable.id,
            label: variable.name,
          });
        }

        return acc;
      },
      [
        {
          label: t('Object Upload Variables'),
          options: [] as GenericOption[],
        },
        {
          label: t('Data Source Variables'),
          options: [] as GenericOption[],
        },
        {
          label: t('Additional Variables'),
          options: [] as GenericOption[],
        },
      ]
    )
    .filter(({ options }) => options.length > 0);
};
