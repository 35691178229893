import SendEmail from './StepsContent/SendEmail';
export const SEND_EMAIL_IDENTIFIER = 'send_email';
// export const SEND_SURVEY_IDENTIFIER = 'send_survey';

export const performActionOptions = [
  {
    value: SEND_EMAIL_IDENTIFIER,
    label: 'Send Email',
    component: SendEmail,
    permission: 'single_send_message',
  },
];
