import { useCallback, useMemo, useRef, useState } from 'react';
import { getUniqueId } from '@kizen/page-builder/utils/id';

const flattenObject = (obj) => {
  return Object.values(obj).reduce((acc, value) => ({ ...acc, ...value }), {});
};

/**
 * Prior to supporting mulitple user created pages, there was only one form page and one
 * thank you page which could not be deleted. A form formUi field is stored as JSON in the
 * backend. `isDeletable` and `isHideable` do not exist for forms prior to the release of
 * the multiple pages feature. If these properties are undefined, then the page is either
 * the default form page or the thank you page. In this case, we set these fields and save
 * them the next time the form/survey is saved.
 */
const prepareLegacyPages = (pages) => {
  return pages.map((page) => ({
    ...page,
    id: page.id || getUniqueId(),
    isDeletable: page.isDeletable === undefined ? false : page.isDeletable,
    isHideable:
      page.isHideable === undefined ? page.isFormPage : page.isHideable,
  }));
};

const useTrackFieldsByPage = () => {
  const [data, setData] = useState({});

  const setField = useCallback((pageId, nodeId, node) => {
    setData((prev) => ({
      ...prev,
      [pageId]: {
        ...prev[pageId],
        [nodeId]: node,
      },
    }));
  }, []);

  const updateField = useCallback((pageId, nodeId, updates) => {
    setData((prev) => ({
      ...prev,
      [pageId]: {
        ...prev[pageId],
        [nodeId]: { ...prev?.[pageId]?.[nodeId], ...updates },
      },
    }));
  }, []);

  const removeField = useCallback((pageId, nodeId) => {
    setData((prev) => {
      delete prev[pageId][nodeId];
      return { ...prev };
    });
  }, []);

  const reset = useCallback(() => {
    setData({});
  }, []);

  return [data, { reset, removeField, setField, updateField }];
};

export const getNodeIdToFieldIdLookupFromEdits = (edits) => {
  return edits.reduce((acc, page) => {
    for (const [nodeId, node] of Object.entries(page.pageData)) {
      if (node?.props?.field?.id) {
        acc[nodeId] = node.props.field.id;
      }
    }
    return acc;
  }, {});
};

/**
 * Manage multiple builder pages using the same builder canvas component.
 *
 * @param pages - initial builder pages
 */
export const usePageBuilderEdits = (pages = []) => {
  const editsRef = useRef(prepareLegacyPages(pages));
  const [edits, setEdits] = useState(editsRef.current);
  const [
    fieldsToCreate,
    {
      reset: resetFieldsToCreate,
      setField: addFieldToCreate,
      updateField: updateFieldToCreate,
      removeField: removeCreateField,
    },
  ] = useTrackFieldsByPage();
  const [
    fieldsToDuplicate,
    {
      reset: resetFieldsToDuplicate,
      setField: addFieldToDuplicate,
      updateField: updateFieldToDuplicate,
      removeField: removeDuplicateField,
    },
  ] = useTrackFieldsByPage();
  const [
    fieldsToDelete,
    { reset: resetFieldsToDelete, setField: setDeleteField },
  ] = useTrackFieldsByPage();
  const [
    fieldsToPatch,
    { reset: resetFieldsToPatch, updateField: updateFieldToPatch },
  ] = useTrackFieldsByPage();

  const addFieldToDelete = useCallback(
    (pageId, nodeId, node) => {
      if (fieldsToCreate?.[pageId]?.[nodeId]) {
        removeCreateField(pageId, nodeId);
      } else if (fieldsToDuplicate?.[pageId]?.[nodeId]) {
        removeDuplicateField(pageId, nodeId);
      } else {
        setDeleteField(pageId, nodeId, node);
      }
    },
    [
      fieldsToCreate,
      fieldsToDuplicate,
      removeCreateField,
      removeDuplicateField,
      setDeleteField,
    ]
  );

  const patchField = useCallback(
    (pageId, nodeId, patches) => {
      if (fieldsToCreate[pageId]?.[nodeId]) {
        updateFieldToCreate(pageId, nodeId, patches);
      } else if (fieldsToDuplicate[pageId]?.[nodeId]) {
        updateFieldToDuplicate(pageId, nodeId, patches);
      } else {
        updateFieldToPatch(pageId, nodeId, patches);
      }
    },
    [
      fieldsToCreate,
      fieldsToDuplicate,
      updateFieldToPatch,
      updateFieldToDuplicate,
      updateFieldToCreate,
    ]
  );

  const updateField = useCallback((nodeId, field) => {
    const pageForNode = editsRef.current.reduce((acc, page, idx) => {
      if (acc !== null) return acc;
      if (Object.keys(page.pageData).some((id) => nodeId === id)) return idx;
      return null;
    }, null);
    const current = editsRef.current[pageForNode].pageData[nodeId].props.field;
    const updatedField = {
      ...current,
      ...field,
      // isRequired is an API property that is reused on the builder. We never update the
      // API's isRequired field, so always use the current builder state
      isRequired: current.isRequired,
    };
    const updatedPage = {
      ...editsRef.current[pageForNode],
      pageData: {
        ...editsRef.current[pageForNode].pageData,
        [nodeId]: {
          ...editsRef.current[pageForNode].pageData[nodeId],
          props: {
            ...editsRef.current[pageForNode].pageData[nodeId].props,
            field: updatedField,
          },
        },
      },
    };
    editsRef.current = editsRef.current
      .slice(0, pageForNode)
      .concat(updatedPage)
      .concat(editsRef.current.slice(pageForNode + 1, editsRef.current.length));
  }, []);

  const setter = useCallback((value) => {
    editsRef.current = value;
    setEdits(value);
  }, []);

  const getEdits = useCallback((editor, currentPage) => {
    return !editor || isNaN(currentPage)
      ? editsRef.current
      : editsRef.current
          .slice(0, currentPage)
          .concat({
            ...editsRef.current[currentPage],
            pageData: editor?.query?.getSerializedNodes(),
          })
          .concat(
            editsRef.current.slice(currentPage + 1, editsRef.current.length)
          );
  }, []);

  const updateEdits = useCallback(
    (editor, currentPage) => {
      if (editor && !isNaN(currentPage)) {
        editsRef.current = getEdits(editor, currentPage);
      }
      setEdits(editsRef.current);
    },
    [getEdits]
  );

  const allFieldsToCreate = useMemo(
    () => flattenObject(fieldsToCreate),
    [fieldsToCreate]
  );
  const allFieldsToDelete = useMemo(
    () => flattenObject(fieldsToDelete),
    [fieldsToDelete]
  );
  const allFieldsToDuplicate = useMemo(
    () => flattenObject(fieldsToDuplicate),
    [fieldsToDuplicate]
  );
  const allFieldsToPatch = useMemo(
    () => flattenObject(fieldsToPatch),
    [fieldsToPatch]
  );

  const resetFields = useCallback(() => {
    resetFieldsToCreate();
    resetFieldsToDelete();
    resetFieldsToDuplicate();
    resetFieldsToPatch();
  }, [
    resetFieldsToCreate,
    resetFieldsToDelete,
    resetFieldsToDuplicate,
    resetFieldsToPatch,
  ]);

  return {
    edits,
    fieldsToCreate: allFieldsToCreate,
    fieldsToDelete: allFieldsToDelete,
    fieldsToDuplicate: allFieldsToDuplicate,
    fieldsToPatch: allFieldsToPatch,
    addFieldToCreate,
    addFieldToDelete,
    addFieldToDuplicate,
    patchField,
    getEdits,
    resetFields,
    setEdits: setter,
    updateEdits,
    updateField,
  };
};
