import { getOptionValue, displayNameToOptionDeleted } from 'services/helpers';

import {
  setDelayAmount,
  checkDeleted,
  displayNameDescriptor,
} from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { field, delayAmount } = orgDetails;

  return {
    stepGoal: {
      ...orgDetails,
      delayAmount: setDelayAmount(delayAmount),
      triggers: [
        {
          type: stepConfig.fieldUpdated.type,
          triggerFieldUpdated: {
            fieldId: getOptionValue(field),
          },
        },
      ],
    },
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { field: fieldObj } = firstTrigger.triggerFieldUpdated;

  checkDeleted(
    messageDictionary,
    addErrorMessage,
    fieldObj,
    displayNameDescriptor
  );

  const field = displayNameToOptionDeleted(fieldObj);
  const type = stepConfig.fieldUpdatedGoal.type;

  return { field, type };
};

export const fieldUpdatedGoalDTO = {
  forApi,
  forFlow,
};
