import { semanticNames, red, gray } from '../theme';

export const redSequence = [
  red[semanticNames['viz-seq-red-1'] as keyof typeof red],
  red[semanticNames['viz-seq-red-2'] as keyof typeof red],
  red[semanticNames['viz-seq-red-3'] as keyof typeof red],
  red[semanticNames['viz-seq-red-4'] as keyof typeof red],
  red[semanticNames['viz-seq-red-5'] as keyof typeof red],
  red[semanticNames['viz-seq-red-6'] as keyof typeof red],
  red[semanticNames['viz-seq-red-7'] as keyof typeof red],
  red[semanticNames['viz-seq-red-8'] as keyof typeof red],
  red[semanticNames['viz-seq-red-9'] as keyof typeof red],
  red[semanticNames['viz-seq-red-10'] as keyof typeof red],
];
export const neutral = gray[semanticNames['viz-neutral'] as keyof typeof gray];
