import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Switch from 'components/Kizen/Switch';
import { useTranslation } from 'react-i18next';
import { Text } from 'app/typography';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useTooltip } from 'components/Kizen/Tooltip';
import Icon from 'components/Kizen/Icon';

const Wrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

const TextWrapper = styled.div`
  display: flex;
  align-content: center;
  text-align: center;
  gap: 5px;
`;

const StyledSwitch = styled(Switch)`
  margin-left: 0;
  margin-top: 2px;
`;

const IconWrapper = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
`;

export default function TextOptions({ updateField, formData = EMPTY_OBJECT }) {
  const { t } = useTranslation();
  const [isMasked, setIsMasked] = useState(formData.meta?.isMasked ?? false);
  const [tooltipProps, tooltipLabel] = useTooltip({
    label: t(
      'Text will be obfuscated with black circles until the content is either hovered or clicked into for editing.'
    ),
    maxWidth: 300,
  });

  useEffect(() => {
    updateField('meta', { isMasked });
  }, [isMasked, updateField]);

  const handleToggle = (ev) => {
    const obscureData = ev.currentTarget.checked;
    setIsMasked(obscureData);
  };

  return (
    <Wrapper>
      <TextWrapper>
        {tooltipLabel}
        <Text>{t('Obscure Data Until Direct Interaction')}</Text>
        <IconWrapper>
          <StyledIcon icon="info-circle" size="lg" {...tooltipProps} />
        </IconWrapper>
      </TextWrapper>
      <StyledSwitch
        value=""
        checked={isMasked}
        onChange={handleToggle}
        data-qa="obscure-data"
      />
    </Wrapper>
  );
}
