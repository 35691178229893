import SettingsPage from './pages/Settings';
import TeamSharingSettings from './pages/TeamSharingSettings';
import BuildPage from './pages/Builder';
import RulesPage from './pages/Rules';

const accountUrlBase = '/activities/:id';

export default {
  settings: {
    label: (t) => t('Activity Settings'),
    component: SettingsPage,
    exact: true,
    icon: 'cogs-light',
    path: accountUrlBase,
  },
  build: {
    label: (t) => t('Build'),
    component: BuildPage,
    exact: true,
    icon: 'tools-light',
    path: `${accountUrlBase}/build`,
  },
  sharing_settings: {
    label: (t) => t('Team Sharing Settings'),
    component: TeamSharingSettings,
    exact: true,
    icon: 'share-alt',
    path: `${accountUrlBase}/team-sharing-settings`,
  },
  rules: {
    label: (t) => t('Advanced Rules'),
    component: RulesPage,
    exact: true,
    icon: 'code-branch-light',
    path: `${accountUrlBase}/rules`,
  },
};
