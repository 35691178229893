export default function ResumeIcon() {
  return (
    /* eslint-disable */
    <svg
      data-prefix="far"
      data-icon="play"
      class="svg-inline--fa fa-play fa-2x  css-1ibawvu"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
    >
      <path
        fill="currentColor"
        d="M48 432L336 256 48 80l0 352zM24.5 38.1C39.7 29.6 58.2 30 73 39L361 215c14.3 8.7 23 24.2 23 41s-8.7 32.2-23 41L73 473c-14.8 9.1-33.4 9.4-48.5 .9S0 449.4 0 432V80C0 62.6 9.4 46.6 24.5 38.1z"
      ></path>
      <path
        fill="currentColor"
        d="M0 256c0-13.3 10.7-24 24-24H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24z"
        transform="translate(700, 0) rotate(90)"
      ></path>
    </svg>

    /* eslint-enable */
  );
}
