import useAccessRequest from 'components/Sharing/hooks/useAccessRequest';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import ToolbarService from 'services/ToolbarService';

const useRespondToAccessRequest = (refetch) => {
  const [showToast] = useToast();
  const { t } = useTranslation();
  const history = useHistory();

  const { mutate: respond } = useMutation(
    ({ requestId, approved }) =>
      ToolbarService.respondToAccessRequest(requestId, approved),
    {
      onSuccess: (_data, variables) => {
        const { approved } = variables;
        if (approved) {
          // aprove the request
          showToast({
            message: t('You have successfully granted access'),
            variant: toastVariant.SUCCESS,
          });
          refetch?.();
        } else {
          // deny the request
          showToast({
            message: t('You have denied access'),
            variant: toastVariant.FAILURE,
          });
        }
      },
      onSettled: () => {
        history.replace('/account/toolbar/templates');
      },
      onError: (payload) => {
        showToast({
          message:
            payload?.response?.data?.non_field_errors?.[0] ??
            t('Could not respond to access request. An error occurred.'),
          variant: toastVariant.FAILURE,
        });
      },
    }
  );

  useAccessRequest(respond);
};

export default useRespondToAccessRequest;
