import styled from '@emotion/styled';
import { useState } from 'react';
import { KizenTypography } from 'app/typography';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { TestFixture } from 'ts-components/filters/TestFixture';
import { Block } from './styles';
import Swatches from './components/Swatches';
import IconSamples from './components/IconSamples';
import ButtonList from './components/ButtonList';
import TypographyList from './components/TypographyList';
import TextInputSample from './components/TextInputSample';
import TextInputUnderlineSample from './components/TextInputUnderlineSample';
import OtherInputsSample from './components/OtherInputSample';
import OtherInputUnderlineSample from './components/OtherInputUnderlineSample';
import OtherComponentsSample from './components/OtherComponentsSample';

const pages = {
  styleguide: 'Style Guide',
  filters: 'Filters v2',
};

const PageChooser = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0;

  && a {
    color: #528ef9;
  }
`;

const StyleGuidePage = () => {
  const [page, setPage] = useState('styleguide');
  const showTestFixtures = usePreReleaseFeatures();

  return (
    <div className="container">
      <KizenTypography type="hero" style={{ marginTop: '1em' }}>
        {pages[page]}
      </KizenTypography>

      {showTestFixtures && (
        <PageChooser>
          <KizenTypography type="anchor" onClick={() => setPage('styleguide')}>
            Style Guide
          </KizenTypography>
          <KizenTypography type="anchor" onClick={() => setPage('filters')}>
            Filters
          </KizenTypography>
        </PageChooser>
      )}

      {page === 'filters' && <TestFixture />}
      {page === 'styleguide' && (
        <>
          <Block>
            <TypographyList />
          </Block>

          <Block>
            <Swatches />
          </Block>

          <Block>
            <ButtonList />
          </Block>

          <Block>
            <IconSamples />
          </Block>

          {!showTestFixtures && (
            // These inputs auto scroll to themselves - removing when fixtures are enabled
            <Block>
              <TextInputSample />
            </Block>
          )}

          <Block>
            <OtherInputsSample />
          </Block>

          <Block>
            <TextInputUnderlineSample />
          </Block>

          <Block>
            <OtherInputUnderlineSample />
          </Block>

          <Block>
            <OtherComponentsSample />
          </Block>
        </>
      )}
    </div>
  );
};

export default StyleGuidePage;
