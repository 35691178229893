import BUILDER_ACTIONS from '../actions/builderActions';
import { defaultModel, enforceConstraints } from '../utilities';
import pick from 'lodash/pick';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

export const initial = (payload = EMPTY_ARRAY) => {
  const values = enforceConstraints(
    pick(payload?.[0], Object.keys(defaultModel)),
    payload?.[1] || []
  );
  return payload?.[0]?.id
    ? {
        ...payload?.[0],
        ...values,
      }
    : { ...defaultModel, related_objects: [] };
};

export const builderReducer = (state, { type, payload }) => {
  switch (type) {
    case BUILDER_ACTIONS.update: {
      const { field, value } = payload;
      return { ...state, [field]: value };
    }
    case BUILDER_ACTIONS.reset: {
      return initial(payload);
    }
    case BUILDER_ACTIONS.updateModel: {
      return payload;
    }
    default:
      return state;
  }
};
