import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Filter } from '../src/filter';
import { Metadata } from '../src/meta-data';
import { StepData, Next, FilterMetadata } from '../src/types';

import {
  FilterSet,
  buildFilterSet,
  isFilterSet,
  isFilterSetArray,
} from '../src/filter-sets';
import { EmailValidationFn, getError } from '../src/validate';

export type FilterOps = {
  next: (
    current_step: string,
    next_steps: Next<string>[],
    config?: FilterMetadata<string> | undefined
  ) => void;
  set: (key: string, value: any) => void;
  toString: () => string;
  isMyRecord: () => any;
  isStageRecord: () => any;
  update: () => void;
};

export type FilterData = {
  id: string;
  type: string | null;
  steps: [string, StepData][];
  ops: FilterOps;
  hasMyRecordFilter: boolean;
  hasStageRecordFilter: boolean;
};

export type FilterSetData = [string, { and: boolean; filters: FilterData[] }];

export type FilterSetsOps = {
  addFilter(setId: string): void;
  addFilterSet(): void;
  build(and: boolean): {
    and: boolean;
    query: {
      id: string;
      and: boolean;
      filters: {
        view_model: any[];
      }[];
    }[];
  };
  createFilter(type: string, setId: string, index: number): void;
  removeFilter(setId: string, index: number): void;
  reset(): void;
  setFilterSetOperation(setId: string, and: boolean): void;
  validate(): ReturnType<typeof defFilterErrors>;
};

type FilterSetsMetadata = {
  hasMyRecordFilter: boolean;
  hasStageRecordFilter: boolean;
};

const noop = () => null;

const EmptyFilterOps: FilterOps = {
  next: () => noop,
  set: noop,
  toString: () => '',
  isMyRecord: () => false,
  isStageRecord: () => false,
  update: noop,
};

const defFilterErrors = (): {
  readonly hasErrors: boolean;
  errors: Record<string, (Record<string, string> | null)[]>;
} => {
  return {
    errors: {},
    get hasErrors() {
      return Object.values(this.errors).some((x) => x.some((y) => y !== null));
    },
  };
};

const buildFilterQuery = (
  filter: Filter | Filter[],
  { and = false, index = 0 } = {}
) => {
  const filters = Array.isArray(filter) ? filter : [filter];

  return {
    ...buildFilterSet({ and, filters }),
    id: `query-${index}`,
  };
};

const withoutNulls = <T>(arr: (T | null)[]) => {
  return arr.filter((x) => x !== null) as T[];
};

const opsForFilter = (
  filter: Filter,
  update: Dispatch<SetStateAction<any>>
) => ({
  next: (...args: Parameters<Filter['next']>) => {
    filter.next(...args);
  },
  set: (...args: Parameters<Filter['set']>) => {
    filter.set(...args);
  },
  toString: () => filter.toString(),
  isMyRecord: () => filter.isMyRecord(),
  isStageRecord: () => filter.isStageRecord(),
  update: () => update((prev: any) => ({ ...prev })),
});

export const useFilterSets = (
  metadata: Metadata,
  vars: [string, string][],
  isValidPhoneNumber: (v: string) => boolean,
  isValidEmail: EmailValidationFn,
  init?: Filter | FilterSet | FilterSet[]
): [FilterSetData[], FilterSetsOps & FilterSetsMetadata] => {
  const { t } = useTranslation();
  const { filters: all_filters } = metadata;
  const [initialSetId] = useState(uuidv4());
  const [setIds, setSetIds] = useState(() => {
    if (isFilterSetArray(init)) return init.map(() => uuidv4());
    return [initialSetId];
  });
  const [filters, setFilters] = useState<Record<string, (Filter | null)[]>>(
    () => {
      if (init instanceof Filter) return { [initialSetId]: [init] };
      if (isFilterSet(init)) return { [initialSetId]: init.filters };
      if (isFilterSetArray(init)) {
        return init.reduce<Record<string, Filter[]>>((acc, fs, idx) => {
          acc[setIds[idx]] = fs.filters;
          return acc;
        }, {});
      }
      return { [initialSetId]: [null] };
    }
  );
  const [types] = useState<Map<Filter, string>>(() => {
    if (isFilterSetArray(init))
      return new Map(init.flatMap((x) => x.filters).map((f) => [f, f.type]));
    if (isFilterSet(init)) return new Map(init.filters.map((f) => [f, f.type]));
    if (init instanceof Filter) return new Map([[init, init.type]]);
    return new Map();
  });
  const [filterOps] = useState<Map<Filter, ReturnType<typeof opsForFilter>>>(
    () => {
      if (isFilterSetArray(init))
        return new Map(
          init
            .flatMap((x) => x.filters)
            .map((f) => [f, opsForFilter(f, setFilters)])
        );
      if (isFilterSet(init))
        return new Map(
          init.filters.map((f) => [f, opsForFilter(f, setFilters)])
        );
      if (init instanceof Filter)
        return new Map([[init, opsForFilter(init, setFilters)]]);
      return new Map();
    }
  );
  const [filterIds] = useState<Map<Filter, string>>(() => {
    if (isFilterSetArray(init))
      return new Map(init.flatMap((x) => x.filters).map((f) => [f, uuidv4()]));
    if (isFilterSet(init))
      return new Map(init.filters.map((f) => [f, uuidv4()]));
    if (init instanceof Filter) return new Map([[init, uuidv4()]]);
    return new Map();
  });
  const [setData] = useState(() => {
    if (isFilterSetArray(init))
      return new Map(init.map((x, idx) => [setIds[idx], { and: x.and }]));
    if (isFilterSet(init)) return new Map([[initialSetId, { and: init.and }]]);
    return new Map([[initialSetId, { and: false }]]);
  });

  const ops: Omit<FilterSetsOps, 'build' | 'validate'> = useMemo(() => {
    return {
      addFilter: (setId: string) => {
        setFilters((prev) => {
          const next = prev[setId] ? prev[setId].concat(null) : [null];
          return { ...prev, [setId]: next };
        });
      },
      addFilterSet: () => {
        const id = uuidv4();
        setSetIds((prev) => prev.concat(id));
        setFilters((prev) => ({ ...prev, [id]: [null] }));
      },
      createFilter: (type: string, setId: string, index: number) => {
        const filter = new Filter(all_filters[type], metadata, { vars });
        types.set(filter, type);
        filterOps.set(filter, opsForFilter(filter, setFilters));
        filterIds.set(filter, uuidv4());

        setFilters((prev) => {
          const next = prev[setId]
            .slice(0, index)
            .concat(filter, prev[setId].slice(index + 1));
          return { ...prev, [setId]: next };
        });
      },
      removeFilter: (setId: string, index: number) => {
        setFilters((prev) => {
          if (prev[setId].length === 1) {
            return Object.fromEntries(
              Object.entries(prev).filter(([key]) => key !== setId)
            );
          }
          return {
            ...prev,
            [setId]: prev[setId]
              .slice(0, index)
              .concat(prev[setId].slice(index + 1)),
          };
        });
      },
      reset: () => {
        setSetIds([initialSetId]);
        setFilters({ [initialSetId]: [null] });
        types.clear();
        filterOps.clear();
        setData.clear();
      },
      setFilterSetOperation: (setId: string, and: boolean) => {
        setData.set(setId, { ...setData.get(setId), and });
        setFilters((prev) => ({ ...prev }));
      },
    };
  }, [
    vars,
    initialSetId,
    types,
    setData,
    filterOps,
    filterIds,
    metadata,
    all_filters,
  ]);

  const build: FilterSetsOps['build'] = useCallback(
    (and = false) => {
      const query = Object.entries(filters).map(([setId, fs], idx) => {
        return buildFilterQuery(withoutNulls(fs), {
          and: setData.get(setId)?.and ?? false,
          index: idx,
        });
      });
      return { and, query };
    },
    [filters, setData]
  );

  const validate: FilterSetsOps['validate'] = useCallback(() => {
    const result = defFilterErrors();

    for (const [setId, fs] of Object.entries(filters)) {
      result.errors[setId] = [];

      for (const f of fs) {
        if (f === null) {
          result.errors[setId].push({
            'filter-type': t('Please finish setting up your filter.'),
          });
        } else {
          const error = getError(f, isValidPhoneNumber, isValidEmail, t);
          result.errors[setId].push(error);
        }
      }
    }

    return result;
  }, [filters, isValidPhoneNumber, isValidEmail, t]);

  const data: FilterSetData[] = useMemo(() => {
    return setIds
      .filter((id) => Boolean(filters[id]))
      .map((id) => {
        const filterData: FilterData[] = filters[id].map((filter) => {
          const steps = filter instanceof Filter ? [...filter] : [];
          const ops = filterOps.get(filter!) ?? EmptyFilterOps;
          const id = filterIds.get(filter!)!;
          const type = types.get(filter!) ?? null;
          const hasMyRecordFilter = filter?.isMyRecord();
          const hasStageRecordFilter = filter?.isStageRecord();
          return {
            id,
            type,
            steps,
            ops,
            hasMyRecordFilter,
            hasStageRecordFilter,
          };
        });

        const set_data = setData.get(id) ?? { and: false };
        return [id, { ...set_data, filters: filterData }];
      });
  }, [filters, setIds, setData, filterOps, filterIds, types]);

  const { hasMyRecordFilter, hasStageRecordFilter } = useMemo(() => {
    const hasMyRecordFilter = data
      .map(([, filter]) => filter)
      .flatMap(({ filters }: any) => filters)
      .some((f) => f.hasMyRecordFilter);
    const hasStageRecordFilter = data
      .map(([, filter]) => filter)
      .flatMap(({ filters }: any) => filters)
      .some((f) => f.hasStageRecordFilter);

    return { hasMyRecordFilter, hasStageRecordFilter };
  }, [data]);

  return [
    data,
    { ...ops, build, validate, hasMyRecordFilter, hasStageRecordFilter },
  ];
};

export type MetaFilterProps = {
  filterSets: FilterSetData[] | null;
  filterOps: (FilterSetsOps & FilterSetsMetadata) | null;
};
