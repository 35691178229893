import KizenTypography from 'app/kizentypo';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DebouncedInput from '../shared/DebouncedInput';
import {
  BreakdownDelimiter,
  BreakdownIcon,
  BreakdownInputContainer,
  BreakdownLabelInputContainer,
  BreakdownLabel,
  BreakdownRow,
  RangeContainer,
  RangeIcon,
} from '../shared/styles';
import { getDefaultLabel } from '../shared/utils';

export const VALUE_BREAKDOWN_ACTION_TYPES = {
  UPDATE: 'UPDATE',
  ADD_ROW: 'ADD_ROW',
  DELETE_ROW: 'DELETE_ROW',
  RESET: 'RESET',
};

const ValueRow = ({
  index,
  breakdown,
  min,
  max,
  label,
  dispatchBreakdown,
  validation,
  currencySymbol,
  showErrors,
  onBlur,
  field,
  compact = false,
}) => {
  const { t } = useTranslation();
  const isFirst = index === 0;
  const isLast = index === breakdown.length - 1;

  const changeMin = useCallback(
    (value) => {
      dispatchBreakdown({
        type: VALUE_BREAKDOWN_ACTION_TYPES.UPDATE,
        payload: { index, min: value },
      });
    },
    [dispatchBreakdown, index]
  );

  const changeMax = useCallback(
    (value) => {
      dispatchBreakdown({
        type: VALUE_BREAKDOWN_ACTION_TYPES.UPDATE,
        payload: { index, max: value },
      });
    },
    [dispatchBreakdown, index]
  );

  const changeLabel = useCallback(
    (value) => {
      dispatchBreakdown({
        type: VALUE_BREAKDOWN_ACTION_TYPES.UPDATE,
        payload: { index, label: value },
      });
    },
    [dispatchBreakdown, index]
  );

  const deleteRow = useCallback(() => {
    dispatchBreakdown({
      type: VALUE_BREAKDOWN_ACTION_TYPES.DELETE_ROW,
      payload: { index },
    });
  }, [dispatchBreakdown, index]);

  const minHasError =
    showErrors && (validation.min.missing || validation.min.validationMessage);
  const maxHasError =
    showErrors && (validation.max.missing || validation.max.validationMessage);

  return (
    <BreakdownRow
      key={`value-breakdown-row-${index}`}
      className="breakdown-row"
    >
      <BreakdownInputContainer compact={compact}>
        <DebouncedInput
          value={min}
          placeholder={isFirst ? t('No Min') : '0'}
          onChange={changeMin}
          onBlur={onBlur}
          error={minHasError}
          validate={
            minHasError
              ? {
                  message:
                    validation.min.validationMessage ||
                    t('You must enter a value.'),
                  showMessage: minHasError,
                }
              : undefined
          }
          field={field}
          overrideDecimalScale={4}
          className="breakdown-min"
          compact={compact}
        />
      </BreakdownInputContainer>
      <RangeContainer>
        <BreakdownIcon icon="less-than-equal" compact={compact} />
        <BreakdownLabel>Range {index + 1}</BreakdownLabel>
        <BreakdownIcon icon="less-than-equal" compact={compact} />
      </RangeContainer>
      <BreakdownInputContainer compact={compact}>
        <DebouncedInput
          value={max}
          placeholder={isLast ? t('No Max') : '0'}
          onChange={changeMax}
          onBlur={onBlur}
          error={maxHasError}
          validate={
            maxHasError
              ? {
                  message:
                    validation.max.validationMessage ||
                    t('You must enter a value.'),
                  showMessage: maxHasError,
                }
              : undefined
          }
          field={field}
          overrideDecimalScale={4}
          className="breakdown-max"
          compact={compact}
        />
      </BreakdownInputContainer>
      <BreakdownDelimiter compact={compact}>
        <KizenTypography>,</KizenTypography>
      </BreakdownDelimiter>
      <BreakdownLabelInputContainer
        compact={compact && breakdown.length > 1}
        expandInline={compact && breakdown.length === 1}
      >
        <DebouncedInput
          value={label}
          onChange={changeLabel}
          onBlur={onBlur}
          placeholder={getDefaultLabel({ min, max, currencySymbol, t })}
          className="breakdown-label"
          compact={compact}
        />
      </BreakdownLabelInputContainer>
      {breakdown.length > 1 ? (
        <RangeIcon icon="trash" onClick={deleteRow} />
      ) : null}
    </BreakdownRow>
  );
};

export default ValueRow;
