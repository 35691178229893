import { useQuery, UseQueryOptions } from 'react-query';
import ClientService from 'services/ClientService';
import { CLIENTS } from '../query-keys';

export const useClientGroupsQuery = (options: UseQueryOptions = {}) => {
  return useQuery({
    queryKey: CLIENTS.GROUPS,
    queryFn: ClientService.groups,
    ...options,
  });
};
