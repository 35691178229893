import type { Filter } from '@kizen/filters/filter';
import type { FilterSet } from '@kizen/filters/filter-sets';
import {
  type MetaFilterProps,
  useFilterSets,
} from '@kizen/filters/hooks/use-filter-sets';
import { FILTER_TYPES } from 'components/Wizards/MetaFilters/types';
import {
  useState,
  PropsWithChildren,
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';

import { useFilterErrors } from 'ts-components/filters';

import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { useSelector } from 'react-redux';
import { useFilterVariables } from 'ts-components/filters';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { validate as validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';

type UseMetaFilterProps = {
  config: Filter | FilterSet | FilterSet[];
  objectId?: string | null;
};

type ConditionTypeOptions = {
  label: string;
  value: string;
  image: any;
};

type GroupOptions = {
  label: string;
  value: string;
  group: any;
};

type FiterPropFilter = {
  and: boolean;
  config: any;
};

type FilterProps = {
  filter: FiterPropFilter;
  isForCustomObject: boolean;
  customObject: any;
  values: any;
  setValues: any;
  state: any;
  conditionTypeOptions: ConditionTypeOptions[];
  groupOptions: GroupOptions[];
  initialFilterElement: any;
  disabled: boolean;
  loading: boolean;
  valid: boolean;
  originalFilterState: any;
  setFilter(): void;
} | null;

type Props = {
  filterProps: FilterProps;
  isClient: boolean;
  objectId?: string | null;
  clientObject?: any;
};

type SetFilterHasErrorsProps = {
  errors: Record<string, ({ [k: string]: string } | null)[]>;
  hasErrors: boolean;
};
interface WizardFilterContextType {
  filterSets: MetaFilterProps['filterSets'];
  filterOps: MetaFilterProps['filterOps'];

  filterErrors: SetFilterHasErrorsProps['errors'];
  setFilterHasErrors(props: SetFilterHasErrorsProps): void;
  hasErrors?: boolean;
  setHasErrors: Dispatch<SetStateAction<boolean>>;
  operation: string | undefined | null;
  setOperation: Dispatch<SetStateAction<string>>;

  // from filterProps
  setValues(): void;
  conditionTypeOptions: ConditionTypeOptions[] | null | undefined;
  initialFilterElement: object | null;
  groupOptions: GroupOptions[] | null | undefined;
  loading: boolean;
  valid: boolean;
  values: object | null;
  filter: FiterPropFilter | null;
  isForCustomObject: boolean;
  customObject: object | null;
  originalFilterState: object | null;
  setFilter(): void;
}

export const useMetaFilters = ({ config, objectId }: UseMetaFilterProps) => {
  const metaData = useSelector(selectFilterMetadataDefinition);

  const { variables } = useFilterVariables({
    fieldSettingsSearch: true,
    objectId,
  });

  const data = useFilterSets(
    metaData,
    variables,
    isValidPhoneNumber,
    validateEmail.withDomain,
    config
  );

  return data;
};

const ctx = createContext<WizardFilterContextType>({
  filterSets: null,
  filterOps: null,
  filterErrors: {},
  setFilterHasErrors: () => null,
  hasErrors: false,
  setHasErrors: () => null,
  operation: null,
  setOperation: () => null,
  // from filterProps
  setValues: () => null,
  conditionTypeOptions: null,
  initialFilterElement: null,
  groupOptions: [],
  loading: false,
  valid: true,
  values: null,
  filter: null,
  isForCustomObject: false,
  customObject: null,
  originalFilterState: null,
  setFilter: () => null,
});

export const useWizardFilterContext = () => useContext(ctx);

export const WizardFilterContext = ({
  filterProps,
  isClient,
  objectId,
  clientObject,
  children,
}: PropsWithChildren<Props>) => {
  const [filterErrors, setFilterErrors] = useFilterErrors();
  const [hasErrors, setHasErrors] = useState(false);
  const setFilterHasErrors = useCallback(
    ({ errors, hasErrors }: SetFilterHasErrorsProps) => {
      setFilterErrors(errors);
      setHasErrors(hasErrors);
    },
    [setFilterErrors, setHasErrors]
  );

  const [operation, setOperation] = useState(
    filterProps?.filter?.and ? 'and' : 'or'
  );

  const [filterSets, filterOps] = useMetaFilters({
    config: filterProps?.filter?.config,
    objectId,
  });

  const data = {
    filterSets,
    filterOps,
    filterErrors,
    setFilterHasErrors,
    hasErrors,
    setHasErrors,
    operation,
    setOperation,
    setValues: filterProps?.setValues,
    conditionTypeOptions: filterProps?.conditionTypeOptions || [],
    initialFilterElement: filterProps?.initialFilterElement,
    groupOptions: filterProps?.groupOptions || [],
    loading: filterProps?.loading || false,
    valid:
      filterProps?.valid || filterProps?.values?.type === FILTER_TYPES.CUSTOM,
    values: filterProps?.values,
    filter: filterProps?.values?.filter,
    isForCustomObject: filterProps?.isForCustomObject || false,
    customObject: isClient ? clientObject : filterProps?.customObject,
    originalFilterState: filterProps?.originalFilterState,
    setFilter: filterProps?.setFilter || (() => null),
  };

  return <ctx.Provider value={data}>{children}</ctx.Provider>;
};
