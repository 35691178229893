import { useRef } from 'react';
import LoadingButton from 'components/Button/LoadingButton';
import { MIN_WIDTH } from 'components/Button/styles';

const WizardLoadingButton = (props) => {
  const minWidthRef = useRef(null);

  return (
    <LoadingButton
      ref={(node) => {
        if (!minWidthRef.current && node) {
          const width = node?.getBoundingClientRect().width;
          minWidthRef.current = width > MIN_WIDTH ? width : MIN_WIDTH;
        }
      }}
      minWidth={minWidthRef.current || MIN_WIDTH}
      {...props}
    />
  );
};
export default WizardLoadingButton;
