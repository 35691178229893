import { useCallback, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useRelatedContext } from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/related-context';
import { useScrollIntoView } from 'hooks/use-scroll-into-view';

import { ResizableSection } from '__components/Wizards/CustomObject/steps/CustomLayout/dialogs/common';
import { StyledInputControl, StyledSelect } from './styles';
import MultiSelect from '__components/Inputs/MultiSelect';

import { RelatedPipelineWizardProps } from './types';

export const scrollOptions = {
  behavior: 'smooth',
  block: 'start',
};

export const Fields = (props: RelatedPipelineWizardProps) => {
  const { t } = useTranslation();
  const { selectedObject, setSelectedObject } = useRelatedContext();
  const oneTimeSetSelectedObject = useRef(false);

  const { metadata, setMetadata, pipelineOptions, mainObjectFields, disabled } =
    props;

  const objects: any[] = useMemo(
    () =>
      (metadata?.relatedPipelinesIncludeAll
        ? pipelineOptions
        : metadata.relatedPipelinesIncluded) ?? [],
    [pipelineOptions, metadata]
  );

  const { scrollNext, componentRef: wrapperRef } =
    useScrollIntoView(scrollOptions);

  const valueFromObjects = useMemo(
    () =>
      (objects || []).find((obj) => obj.id === selectedObject?.id) ||
      selectedObject,
    [objects, selectedObject]
  );

  const selectedId = useRef(selectedObject?.id);
  if (disabled) {
    selectedId.current = selectedObject?.id;
  }

  const showColumns =
    !disabled &&
    selectedObject &&
    (selectedId.current === selectedObject?.id ||
      selectedId.current === selectedObject?.originalId);

  const includeRelationshipFieldsOptions = useMemo(() => {
    return mainObjectFields
      .filter(({ isSuppressed }) => !isSuppressed)
      .filter(({ relation }) => relation?.relatedObject === selectedObject?.id)
      .map(({ relation, id, displayName }) => ({
        value: id,
        label: displayName,
        relation,
      }));
  }, [mainObjectFields, selectedObject?.id]);

  const onHandleChangeReverseFields = useCallback(
    (fields: any) => {
      if (selectedObject?.id) {
        setMetadata({
          ...metadata,
          relatedPipelinesRelationshipFields: {
            ...metadata.relatedPipelinesRelationshipFields,
            [selectedObject.id]: fields,
          },
        });
      }
    },
    [setMetadata, metadata, selectedObject?.id]
  );

  const reverseFieldsMapped = useMemo(
    () =>
      metadata?.relatedPipelinesRelationshipFields?.[selectedObject?.id] || [],
    [metadata, selectedObject?.id]
  );

  if (
    !selectedObject &&
    objects.length > 0 &&
    !oneTimeSetSelectedObject.current
  ) {
    setSelectedObject(objects[0]);
    oneTimeSetSelectedObject.current = true;
  }

  return (
    <ResizableSection header={t('Choose Fields to Include')} {...props}>
      <div
        ref={wrapperRef}
        className="kds grid grid-cols-2 gap-spacer-20 text-font-primary"
      >
        <div className="col-span-2">
          <StyledInputControl margin>
            <StyledSelect
              data-qa="related-object-select"
              label={t('Choose Object to Edit Settings')}
              options={objects}
              value={valueFromObjects}
              placeholder={t('Choose Object')}
              onChange={setSelectedObject}
              isLoading={disabled}
              onMenuOpen={() => !selectedObject && scrollNext()}
            />
          </StyledInputControl>
        </div>
        {showColumns ? (
          <div className="col-span-2">
            <MultiSelect
              data-qa="related-object-fields-select"
              label={t(
                'Include the Following Relationship Fields (Leave Blank for All)'
              )}
              placeholder={t('Find Relationship Field(s)')}
              options={includeRelationshipFieldsOptions}
              value={reverseFieldsMapped}
              onChange={onHandleChangeReverseFields}
              menuInline
              margin
            />
          </div>
        ) : null}
      </div>
    </ResizableSection>
  );
};
