import React, { useState } from 'react';
import styled from '@emotion/styled';
import { KizenTypography } from 'app/typography';
import { gutters } from 'app/spacing';
import YesNoQuestion from 'components/Kizen/YesNoQuestion';

const StyledText = styled(KizenTypography)`
  margin: ${gutters.standard} 0 ${gutters.standard} 0;
`;

const YesNoSelector = () => {
  const [defaultQuestion, setDefaultQuestion] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState('yes');
  const [errorQuestion, setErrorQuestion] = useState('');

  return (
    <>
      <div>
        <StyledText size="subheader" weight="light">
          Default
        </StyledText>
        <YesNoQuestion
          label="Question Text Goes Here?"
          name="default"
          value={defaultQuestion}
          onChange={(value) => setDefaultQuestion(value)}
        />
      </div>
      <div>
        <StyledText size="subheader" weight="light">
          Selected
        </StyledText>
        <YesNoQuestion
          label="Question Text Goes Here?"
          name="selected"
          value={selectedQuestion}
          onChange={(value) => setSelectedQuestion(value)}
        />
      </div>
      <div>
        <StyledText size="subheader" weight="light">
          Error
        </StyledText>
        <YesNoQuestion
          label="Question Text Goes Here?"
          name="error"
          value={errorQuestion}
          onChange={(value) => setErrorQuestion(value)}
          error
        />
      </div>
    </>
  );
};

export default YesNoSelector;
