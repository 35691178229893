import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import BasicModal from 'components/Modals/presets/BasicModal';
import Select from 'components/Inputs/Select';
import { NoOptionsMessage } from 'components/Inputs/Select/customize';

const ModalContents = styled.div`
  height: 190px; // height to accommodate menu open w/o scrolling
`;

const ConfirmActionModal = ({
  selectionOptions,
  onHide,
  onConfirm,
  show,
  onMenuOpen,
}) => {
  const { t } = useTranslation();
  const [option, setOption] = useState(null);
  const [isDisabledNext, setIsDisabledNext] = useState(true);
  const nextAction = () => {
    if (option && option.value) {
      onConfirm(option); // this is the only change from the clients which passes option value
    }
    setOption(null);
  };

  useEffect(() => {
    if ((option && option.numberContacts === 0) || option === null) {
      setIsDisabledNext(true);
    } else {
      setIsDisabledNext(false);
    }
  }, [option]);

  return (
    <BasicModal
      buttonText={t('Next')}
      disabled={isDisabledNext}
      heading={t('Who Would You Like to Perform an Action On?')}
      leftBtn={null}
      onConfirm={nextAction}
      onHide={onHide}
      show={show}
      size="medium"
    >
      <ModalContents>
        <Select
          label={t('Choose Action Group')}
          options={selectionOptions}
          value={option}
          onChange={(op) => setOption(op)}
          placeholder={t('Choose Group')}
          onMenuOpen={onMenuOpen}
          components={{
            NoOptionsMessage,
          }}
          isOptionDisabled={(o) => o.disabled}
          noOptionsMessage={t('Loading Options')}
        />
      </ModalContents>
    </BasicModal>
  );
};

export default ConfirmActionModal;
