import { useCallback, useMemo } from 'react';
import Select from '__components/Inputs/Select';
import { DropdownProps, SelectOption } from './types';
import { useFilterOptions } from '../hooks/useFilterOptions';
import { useFilterQuery } from '../hooks/useFilterQuery';

import type { Option } from '@kizen/filters/types';
import { get } from 'lodash';

type Group = {
  label: string;
  order: number;
  options: Option[];
};

export const GroupedDropdown = ({
  step,
  width,
  url,
  method,
  next,
  placeholder,
  options: optionsProp,
  value,
  onChange,
  result_path,
  value_id_path,
  option_filter,
  option_paths,
  body,
  params,
  group_url,
  group_method,
  group_body,
  group_result_path,
  group_params,
  group_label_path,
  group_order_path,
  group_value_path,
  endAdornment,
  error,
  isStatus = false,
}: DropdownProps) => {
  const v =
    value_id_path && value && typeof value === 'object'
      ? get(value, value_id_path)
      : value;
  const {
    isLoading,
    options,
    lookup: options_lookup,
    onMenuOpen,
    onMenuClose,
  } = useFilterOptions(
    method,
    url,
    result_path,
    value_id_path,
    option_paths,
    option_filter,
    body,
    params,
    optionsProp,
    value
  );

  const { data: groupData } = useFilterQuery(
    group_method,
    group_url,
    group_body,
    group_params
  );

  const groups = useMemo<Record<string, Group> | null>(() => {
    if (!groupData) return null;

    const opts = group_result_path?.length
      ? get(groupData, group_result_path)
      : groupData;

    return opts.reduce((acc: Record<string, Group>, o: Record<string, any>) => {
      const key = group_value_path
        ? get(o, group_value_path)
        : o.group_value_path;
      acc[key] = {
        label: group_label_path ? get(o, group_label_path) : o.label,
        order: group_order_path ? get(o, group_order_path) : o.order,
        options: [],
      };
      return acc;
    }, {});
  }, [
    groupData,
    group_result_path,
    group_value_path,
    group_label_path,
    group_order_path,
  ]);

  const { grouped, lookup } = useMemo(() => {
    if (groups === null) {
      return {
        grouped: options,
        lookup: new Map(options.map((o) => [o.value, o.value])),
      };
    }

    const data = Object.entries(groups).reduce<Record<string, Group>>(
      (acc, [key, group]) => {
        acc[key] = { ...group, options: [] };
        return acc;
      },
      {}
    );

    for (const opt of options) {
      // this points to a data issue, i.e. orphaned fields in the db
      if (data[opt.group!]) {
        data[opt.group!].options.push(opt);
      }
    }

    const grouped = Object.values(data)
      .filter((g) => g.options.length > 0)
      .sort((a, b) => a.order - b.order);
    const lookup = new Map<Option['value'], Option>();
    for (const opt of grouped.flatMap((o) => o.options)) {
      lookup.set(opt.value, opt);
    }

    return { grouped, lookup };
  }, [options, groups]);

  const handleChange = useCallback(
    (opt: SelectOption) => {
      onChange(step, {
        next: opt.next ?? next,
        value: options_lookup.has(opt.value)
          ? options_lookup.get(opt.value)!
          : opt.value,
      });
    },
    [step, next, options_lookup, onChange]
  );

  return (
    <Select
      width={width}
      value={v ? lookup.get(v) : undefined}
      options={grouped}
      isColored={isStatus}
      placeholder={placeholder}
      onChange={handleChange}
      endAdornment={endAdornment}
      error={error}
      loadItems={isLoading}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
    />
  );
};
