import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import Input from 'components/Kizen/Input';
import { PageMainSelector } from '../PageToolbar';
import { DEFAULT_INPUT_DELAY } from 'utility/config';
import { useDebounce } from 'react-use';

export const BigListToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  height: 46px;
  > div {
    display: flex;
    align-items: center;
  }
`;

export const BigListToolbarSection = styled.div``;

export const BigListMainSelector = styled(PageMainSelector)`
  margin-right: ${gutters.spacing(4)}px;
  > :first-child {
    max-width: 175px;
  }
  .PageMainSelector__indicators {
    padding-left: ${gutters.spacing(1)}px;
    cursor: pointer;
  }
`;

export const BigListSearchInput = styled(
  ({ value, onChange, delay = DEFAULT_INPUT_DELAY, ...props }) => {
    const [innerValue, setInnerValue] = useState(value);
    const stagedValue = useRef(value);
    useDebounce(
      () => stagedValue.current !== innerValue && onChange(innerValue),
      delay,
      [innerValue]
    );

    useEffect(() => {
      if (value !== stagedValue.current) {
        setInnerValue(value);
        stagedValue.current = value;
      }
    }, [value]);

    return (
      <Input
        pill
        type={Input.INPUT_TYPES.SEARCH}
        {...props}
        onChange={setInnerValue}
        value={innerValue}
      />
    );
  }
)`
  height: 36px; // Just being explicit since it matters to the overall sticky toolbar layout
  .input-wrapper {
    background-color: ${grayScale.white};
  }
`;
