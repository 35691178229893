import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { fontWeights } from 'app/typography';
import { breakpoints } from 'app/spacing';
import { useContactsMergeFields } from 'hooks/useMergeFieldsBase';
import PerformAction from 'services/PerformActionService';
import LibraryService, { templateForApi } from 'services/LibraryService';
import { getPayloadFieldValue } from 'services/FieldService';
import { FIELD_TYPES } from 'utility/constants';
import {
  isContactTagsField,
  isContactTitlesField,
  isFirstNameField,
  isLastNameField,
} from 'checks/fields';
import { isTimeZoneField, isEmailStatusField } from 'checks/fields';
import { validate as isEmail } from 'components/Inputs/TextInput/presets/EmailAddress';
import { toastVariant, useToast, toastDelay } from 'components/ToastProvider';
import BasicModalWithConfirmation from 'components/Modals/presets/BasicModalWithConfirmation';
import Button from 'components/Button';
import { isClientObject } from 'components/Modals/utilities';
import {
  useSendEmailStep,
  sendEmailStepIsValid,
} from './StepsContent/SendEmail';
import ActionButtons from './ActionButtons';
import {
  SEND_TEXT_IDENTIFIER,
  CHANGE_TAGS_IDENTIFIER,
  MANAGE_TEAM_IDENTIFIER,
  MANAGE_SUBSCRIPTIONS,
  EXPORT_IDENTIFIER,
  ARCHIVE_IDENTIFIER,
  CHANGE_FIELD_IDENTIFIER,
  SEND_EMAIL_IDENTIFIER,
  SEND_SURVEY_IDENTIFIER,
  MODIFY_AUTOMATION_IDENTIFIER,
} from './model';
import SelectedActionStep from './SelectedActionStep';
import SummaryStep from './SummaryStep';
import PerformKizenStepper from './stepper';
import { useGetSummary } from './model';
import { getDefaultColumnSettings } from 'components/Wizards/CustomObject/steps/CustomLayout/subsections/DefaultColumns/helpers';
import { mergeColumnSettings } from 'pages/ColumnsPage/helpers';
import { ConfrimBulkActionModal } from './ConfirmBulkActionModal';
import { SECONDARY_ACTIONS } from './actions';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const CONFIRM_BULK_ACTION_THRESHOLD = 100000;

const StyledModalBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: auto; // Removes unnecessary scrollbar, which allows things to size/align per designs
  overflow-x: hidden;
  padding-bottom: 15px;

  > button,
  > span {
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    &:empty {
      margin-bottom: 0;
    }

    font-weight: ${fontWeights.black};
    letter-spacing: 0.5px;
    border-width: 1px;
    line-height: 35px;
    height: 35px;

    width: 141px;
    @media (min-width: ${breakpoints.lg}px) {
      width: 180px;
    }
  }
`;

const StyledModalFooter = styled.div`
  padding: 0;
  .button-box {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-end;

    > button.btn {
      margin: 0;
    }
  }
`;

const getOptionValue = (opt) => opt && opt.value;

const getValuesArray = (arr) => arr && arr.map((x) => getOptionValue(x));

const getArrayOfIds = (arr) => arr.map((e) => e.id);

const createPayload = ({ key, isContacts, objectId, action, data = {} }) => ({
  ...data,
  [isContacts ? 'clientKey' : 'entityRecordsSetKey']: key,
  ...(!isContacts ? { objectId } : {}),
  action,
});

export default function PerformActionModal({
  isMobile,
  filters,
  search = '',
  selection,
  model,
  groupId = null,
  groupOptions,
  allCount,
  records,
  recordsCount,
  selectedAction = null,
  resetSelectedAction,
  performActionOptions,
  onHide,
  onSelect,
  onResetSelection,
  savedColumnSettings,
  fields,
  ...props
}) {
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState([]);
  const [performingAction, setPerformingAction] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showToast] = useToast();
  const confirmClickedRef = useRef(false); // flag to stop getSummaryFn being called after confirm has been clicked
  // start automation
  const [automation, setAutomation] = useState('');
  const [secondaryAction, setSecondaryAction] = useState(
    SECONDARY_ACTIONS.START
  );
  const clientObject = useSelector(getBusinessClientObject);
  const clientObjectId = clientObject.id;

  // send surveys
  const [surveyValue, setSurveyValue] = useState('');

  // Send Text Action
  const [sendTextValue, setSendTextValue] = useState({
    content: '',
    htmlContent: '',
  });

  // Change Tags Action
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const tagsField = useMemo(() => fields.find(isContactTagsField), [fields]);

  // Manage Team Members Action
  const [teamMembers, setTeamMembers] = useState([]);
  const [grantMyRecordsAccess, setGrantMyRecordsAccess] = useState(true);

  // Manage Subscription Action
  const [subscriptionItem, setSubscriptionItem] = useState(null);
  const [status, setSubscriptionStatus] = useState('');

  // export
  const [selectedOption, setSelectedOption] = useState(null);
  const [includeKizenId, setIncludeKizenId] = useState(true);

  const defaultColumnSettings = useMemo(
    () => (model ? getDefaultColumnSettings(model, fields, t) : []),
    [fields, model, t]
  );
  const columnSettings = useMemo(() => {
    return mergeColumnSettings(defaultColumnSettings, savedColumnSettings);
  }, [savedColumnSettings, defaultColumnSettings]);

  // Manage Field Change
  const [field, setField] = useState(null);
  const [fieldValue, setFieldValue] = useState('');
  const [resolution, setResolutionValue] = useState(null);
  const [removeTags, setRemoveTags] = useState([]);
  const sendEmailStep = useSendEmailStep();
  const isContacts = isClientObject(model);

  const objectInfo = useMemo(() => {
    return {
      isContacts: isContacts,
      entityName: model.entityName,
      names: isContacts
        ? {
            singular: t('Contact'),
            plural: t('Contacts'),
          }
        : {
            singular: t('Record'),
            plural: t('Records'),
          },
      defaultTitleData: {
        affix: t('Perform Action on'),
        text: `${t('All')} ${isContacts ? t('Contacts') : t('Records')}`,
      },
    };
  }, [isContacts, model.entityName, t]);

  const [title, setTitle] = useState(objectInfo.defaultTitleData);

  const { categorizedFieldOptions: sendTextMergeFields } =
    useContactsMergeFields();

  // There are so many dependencies of summary fetching that we're
  // just going to always update the function, toss it in a ref, and
  // only call it actively when moving to the final step, rather than
  // reactively in an effect.
  const getSummary = useRef();
  const [{ summary, isFetching }, getSummaryFn] = useGetSummary({
    objectId: model.id,
    selection,
    records,
    recordsCount,
    groupOptions,
    groupId,
    search,
    filters,
    field,
    isContacts: objectInfo.isContacts,
    selectedAction,
    performActionOptions,
    fieldValue,
    sendEmailStep,
    status,
    subscriptionItem,
    secondaryAction,
    automation,
  });

  getSummary.current = getSummaryFn;

  const goToSelectActionStep = useCallback(() => {
    resetSelectedAction();
    setCurrentStep(1);
    setDirty(false);
    setTitle((ps) => ({ ...ps, affix: t('Perform Action on') }));
  }, [resetSelectedAction, t]);

  const goToConfigureActionStep = useCallback(() => {
    setCurrentStep(2);
    setDirty(false);
  }, []);

  const goToSummaryStep = useCallback(() => {
    // block updates after action confirmed

    if (!confirmClickedRef.current) {
      getSummary.current();
    }
    setCurrentStep(3);
    setDirty(true);
  }, []);

  useEffect(() => {
    if (selection.checkedCount === allCount) {
      setTitle(objectInfo.defaultTitleData);
      return;
    }

    const recordsStr =
      selection.checkedCount === 1
        ? objectInfo.names.singular
        : objectInfo.names.plural;
    const entityStr = objectInfo.isContacts
      ? ' '
      : ` ${objectInfo.entityName} `;

    if (selection.value === 'group' && groupId) {
      const group = groupOptions.find((el) => el.id === groupId);
      const text = `${selection.checkedCount}${entityStr}${recordsStr} ${t(
        'In Group'
      )} ${group ? `"${group.name}"` : ''}`;
      setTitle((ps) => ({ ...ps, text }));
    } else if (
      (selection.value === 'search' || selection.value === 'page') &&
      records.length
    ) {
      const text = `${selection.checkedCount} ${t(
        'Selected'
      )}${entityStr}${recordsStr}`;
      setTitle((ps) => ({ ...ps, text }));
    } else if (
      (selection.value === 'individual' && selection.checkedCount !== 0) ||
      selection.checkedCount !== recordsCount
    ) {
      const text = `${selection.checkedCount} ${t(
        'Selected'
      )}${entityStr}${recordsStr}`;
      setTitle((ps) => ({ ...ps, text }));
    }
  }, [
    groupId,
    recordsCount,
    groupOptions,
    selection,
    records.length,
    allCount,
    objectInfo,
    t,
  ]);

  useEffect(() => {
    setFieldValue('');
    if (removeTags.length) {
      setRemoveTags([]);
    }
  }, [field]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableConfigureActionStep =
    selectedAction && selectedAction.value === ARCHIVE_IDENTIFIER;

  useEffect(() => {
    if (!selectedAction) {
      goToSelectActionStep();
    } else {
      const step = performActionOptions.find(
        (el) => selectedAction.value === el.value
      );
      setSteps(step.steps);
      setTitle((ps) => ({ ...ps, affix: step.titleForModal }));
      if (disableConfigureActionStep) {
        goToSummaryStep();
      } else {
        goToConfigureActionStep();
      }
    }
  }, [
    selectedAction,
    performActionOptions,
    disableConfigureActionStep,
    goToSelectActionStep,
    goToConfigureActionStep,
    goToSummaryStep,
  ]);

  const getSummaryStrings = useMemo(() => {
    const key = summary && (summary.clientKey || summary.entityRecordsSetKey);
    const recordsCount =
      summary && (summary.clientsCount || summary.allowedCount);
    const recordsCountDisplay =
      recordsCount === 1
        ? objectInfo.names.singular.toLowerCase()
        : objectInfo.names.plural.toLowerCase();
    return [key, recordsCount, recordsCountDisplay];
  }, [objectInfo, summary]);

  const handleSendText = async () => {
    const [key] = getSummaryStrings;
    try {
      setPerformingAction(true);
      await PerformAction.saveText({
        clientKey: key,
        body: sendTextValue.content,
      });
      showToast({
        variant: toastVariant.SUCCESS,
        message: t('The text message has been successfully sent.'),
      });
    } catch (error) {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: `${t('The text message was not successfully sent.')} ${t(
          'Please try again or contact Kizen support.'
        )}`,
      });
    } finally {
      setPerformingAction(false);
      onHide();
    }
  };

  const handleSendEmail = async () => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      setPerformingAction(true);
      const { email } = sendEmailStep;
      await LibraryService.sendEmail({
        ...templateForApi(email),
        isBulk: true,
        sendTo: key,
      });
      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          'The email has been successfully queued to {{recordsCount}} {{recordsCountDisplay}}. You will receive an email once this action is complete.',
          {
            recordsCount,
            recordsCountDisplay,
          }
        ),
        delay: toastDelay.long,
      });
      onHide();
    } catch {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'The email was not successfully queued. Please try again or contact Kizen support.'
        ),
      });
    } finally {
      setPerformingAction(false);
    }
  };

  const handleAutomationAction = async (action = SECONDARY_ACTIONS.START) => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      setPerformingAction(true);
      const payload = createPayload({
        data: {
          automationId: automation.value,
        },
        key,
        objectId: objectInfo.isContacts ? clientObjectId : model.id,
        action,
      });

      await PerformAction.modifyAutomation(payload);

      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          '{{noun}} has been successfully queued on {{recordsCount}} {{recordsCountDisplay}}. You will receive an email once this action is complete.',
          {
            recordsCount,
            recordsCountDisplay,
            noun: {
              [SECONDARY_ACTIONS.START]: t('The automation'),
              [SECONDARY_ACTIONS.PAUSE]: t('Pause automation'),
              [SECONDARY_ACTIONS.CANCEL]: t('Cancel automation'),
            }[action],
          }
        ),
        delay: toastDelay.long,
      });
    } catch (error) {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'The automations were not successfully {{action}}. Please try again or contact Kizen support.',
          {
            action: {
              [SECONDARY_ACTIONS.START]: t('started'),
              [SECONDARY_ACTIONS.PAUSE]: t('paused'),
              [SECONDARY_ACTIONS.CANCEL]: t('canceled'),
            }[action],
          }
        ),
      });
    } finally {
      setPerformingAction(false);
      onHide();
    }
  };

  const handleSendSurvey = async () => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      setPerformingAction(true);
      await PerformAction.saveSendSurvey({
        clientKey: key,
        id: surveyValue.value,
      });
      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          'The survey has been successfully sent to {{recordsCount}} {{recordsCountDisplay}}.',
          {
            recordsCount,
            recordsCountDisplay,
          }
        ),
      });
    } catch (error) {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'The surveys were not successfully sent. Please try again or contact Kizen support.'
        ),
      });
    } finally {
      setPerformingAction(false);
      onHide();
    }
  };

  const handleChangeTags = async () => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      setPerformingAction(true);
      await PerformAction.changeTags({
        clientKey: key,
        tagsToAdd: tagsToAdd.map((tag) => tag.id),
        tagsToRemove: tagsToRemove.map((tag) => tag.id),
      });
      const message = t(
        '{{recordsCount}} {{recordsCountDisplay}} {{verb}} been queued for tag changes successfully. You will receive an email once this action is complete.',
        {
          recordsCount,
          recordsCountDisplay,
          verb: recordsCount === 1 ? t('has') : t('have'),
        }
      );
      showToast({
        variant: toastVariant.SUCCESS,
        message,
        delay: toastDelay.long,
      });
    } catch {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'The tags were not successfully updated. Please try again or contact Kizen support.'
        ),
      });
    } finally {
      setPerformingAction(false);
      onHide();
    }
  };

  const handleManageTeam = async () => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      setPerformingAction(true);
      const payload = createPayload({
        data: {
          teamMemberIds: getValuesArray(teamMembers),
          has_record_access: grantMyRecordsAccess,
        },
        key,
        isContacts: objectInfo.isContacts,
        objectId: model.id,
      });
      await PerformAction.manageTeam(payload);
      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          'The team {{name}} been successfully queued for associate on {{recordsCount}} {{recordsCountDisplay}}. You will receive an email once this action is complete.',
          {
            name:
              teamMembers.length === 1 ? t('member has') : t('members have'),
            recordsCount,
            recordsCountDisplay,
          }
        ),
        delay: toastDelay.long,
      });
    } catch {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'The team members were not successfully associated. Please try again or contact Kizen support.'
        ),
      });
    } finally {
      setPerformingAction(false);
      onHide();
    }
  };

  const handleManageSubscriptions = async () => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      setPerformingAction(true);
      await PerformAction.manageSubscriptions({
        clientKey: key,
        subscriptionListId: subscriptionItem.value,
        status,
      });
      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          'The subscription list has been successfully queued for update on {{recordsCount}} {{recordsCountDisplay}}. You will receive an email once this action is complete.',
          {
            recordsCount,
            recordsCountDisplay,
          }
        ),
        delay: toastDelay.long,
      });
    } catch {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'The subscription list was not successfully updated. Please try again or contact Kizen support.'
        ),
      });
    } finally {
      setPerformingAction(false);
      onHide();
    }
  };

  const handleExport = async () => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      const exportColumnSettings = [...columnSettings];
      setPerformingAction(true);
      const firstNameId = fields.find(isFirstNameField)?.id;
      const firstNameIndex = columnSettings.findIndex(
        (x) => x.fieldId === firstNameId
      );
      if (firstNameIndex !== -1) {
        const lastNameField = fields.find(isLastNameField);
        const lastNameId = lastNameField?.id;
        if (lastNameId) {
          exportColumnSettings.splice(firstNameIndex + 1, 0, {
            fieldId: lastNameId,
            visible: true,
          });
        }
      }

      const payload = createPayload({
        key,
        isContacts: objectInfo.isContacts,
        objectId: model.id,
        data: {
          includeKizenId: includeKizenId,
          fields:
            selectedOption?.value === 'visible'
              ? [
                  ...exportColumnSettings
                    .filter((x) => x.visible)
                    .map((x) => x.fieldId),
                ]
              : [],
        },
      });
      await PerformAction.exportRecords(payload);
      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          '{{recordsCount}} {{recordsCountDisplay}} {{verb}} queued for export successfully. You will be emailed a download link once complete.',
          {
            verb: recordsCount === 1 ? t('was') : t('were'),
            recordsCount,
            recordsCountDisplay,
          }
        ),
      });
    } catch {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message:
          'The export was not successful. Please try again or contact Kizen support.',
      });
    } finally {
      setPerformingAction(true);
      onHide();
    }
  };

  const handleArchive = async () => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      setPerformingAction(true);
      const payload = createPayload({
        key,
        isContacts: objectInfo.isContacts,
        objectId: model.id,
      });
      await PerformAction.archiveRecords(payload);
      const message = t(
        '{{recordsCount}} {{recordsCountDisplay}} {{verb}} been queued for archive successfully. You will receive an email once this action is complete.',
        {
          recordsCount,
          recordsCountDisplay,
          verb: recordsCount === 1 ? t('has') : t('have'),
        }
      );
      showToast({
        variant: toastVariant.SUCCESS,
        message,
        delay: toastDelay.long,
      });
      onResetSelection();
    } catch {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          'The archive was not successful. Please try again or contact Kizen support.'
        ),
      });
    } finally {
      setPerformingAction(false);
      onHide();
    }
  };

  const handleChangeFieldValue = async () => {
    const [key, recordsCount, recordsCountDisplay] = getSummaryStrings;
    try {
      setPerformingAction(true);
      const getValue = () => {
        switch (field.fieldType) {
          case FIELD_TYPES.Dropdown.type:
          case FIELD_TYPES.Radio.type:
          case FIELD_TYPES.Status.type:
            return isTimeZoneField(field) || isEmailStatusField(field)
              ? fieldValue.code
              : fieldValue.id || null;
          case FIELD_TYPES.Relationship.type:
          case FIELD_TYPES.Checkboxes.type:
          case FIELD_TYPES.Files.type:
            if (fieldValue) {
              if (Array.isArray(fieldValue) && fieldValue.length) {
                return fieldValue.map(({ id }) => id);
              }
              return fieldValue.id || null;
            }
            return null;
          case FIELD_TYPES.TeamSelector.type: {
            if (isContactTitlesField(field)) return getArrayOfIds(fieldValue);

            return fieldValue?.id || null;
          }
          case FIELD_TYPES.Decimal.type:
          case FIELD_TYPES.Choices.type:
          case FIELD_TYPES.Money.type:
            return String(fieldValue) || null;
          case FIELD_TYPES.Date.type:
          case FIELD_TYPES.DateTime.type:
            return getPayloadFieldValue(fieldValue, field) || null;
          case FIELD_TYPES.Text.type:
          case FIELD_TYPES.LongText.type:
            return fieldValue || '';
          case FIELD_TYPES.Integer.type:
            return typeof fieldValue === 'number' ? fieldValue : null;
          default: {
            return fieldValue || null;
          }
        }
      };

      const data = {
        field_id:
          field.isDefault && !isContactTitlesField(field)
            ? field.name
            : field.id,
        field_value: getValue(),
      };

      if (resolution) {
        data.field_resolution = resolution.value;
      }

      if (field.fieldType === FIELD_TYPES.DynamicTags.type) {
        delete data.field_value;
        data.tags_to_add = fieldValue ? fieldValue.map(({ id }) => id) : [];
        data.tags_to_remove = removeTags ? removeTags.map(({ id }) => id) : [];
      }

      const payload = createPayload({
        data,
        key,
        isContacts: objectInfo.isContacts,
        objectId: model.id,
      });
      await PerformAction.changeFieldValue(payload);
      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          "The '{{displayName}}' field has been successfully queued on {{recordsCount}} {{recordsCountDisplay}}. You will receive an email once this action is complete.",
          {
            displayName: field.displayName,
            recordsCount,
            recordsCountDisplay,
          }
        ),
        delay: toastDelay.long,
      });
    } catch {
      setPerformingAction(false);
      showToast({
        variant: toastVariant.FAILURE,
        message: t(
          "The '{{displayName}}' field was not successfully queued. Please try again or contact Kizen support.",
          {
            displayName: field.displayName,
          }
        ),
      });
    } finally {
      setPerformingAction(false);
      onHide();
    }
  };

  const handleSubmit = () => {
    // block additional calls to getSummaryFn after commit
    confirmClickedRef.current = true;
    // Choose submit action based on selected action
    switch (selectedAction.value) {
      case MODIFY_AUTOMATION_IDENTIFIER:
        handleAutomationAction(secondaryAction);
        break;
      case SEND_SURVEY_IDENTIFIER:
        handleSendSurvey();
        break;
      case SEND_TEXT_IDENTIFIER:
        handleSendText();
        break;
      case SEND_EMAIL_IDENTIFIER:
        handleSendEmail();
        break;
      case CHANGE_TAGS_IDENTIFIER:
        handleChangeTags();
        break;
      case MANAGE_TEAM_IDENTIFIER:
        handleManageTeam();
        break;
      case MANAGE_SUBSCRIPTIONS:
        handleManageSubscriptions();
        break;
      case EXPORT_IDENTIFIER:
        handleExport();
        break;
      case ARCHIVE_IDENTIFIER:
        handleArchive();
        break;
      case CHANGE_FIELD_IDENTIFIER:
        handleChangeFieldValue();
        break;
      default: {
        console.debug('Action not recognized');
        break;
      }
    }
  };

  const handleDisableButton = () => {
    if (selectedAction) {
      switch (selectedAction.value) {
        case SEND_SURVEY_IDENTIFIER:
          return !surveyValue;
        case MODIFY_AUTOMATION_IDENTIFIER:
          return !automation;
        case SEND_TEXT_IDENTIFIER:
          return !sendTextValue.content;
        case SEND_EMAIL_IDENTIFIER:
          return !sendEmailStepIsValid(sendEmailStep);
        case CHANGE_TAGS_IDENTIFIER:
          return !(
            (tagsToAdd && tagsToAdd.length) ||
            (tagsToRemove && tagsToRemove.length)
          );
        case MANAGE_TEAM_IDENTIFIER:
          return teamMembers && !teamMembers.length;
        case MANAGE_SUBSCRIPTIONS:
          return !subscriptionItem;
        case EXPORT_IDENTIFIER:
        case ARCHIVE_IDENTIFIER:
          return false;
        case CHANGE_FIELD_IDENTIFIER:
          if (
            field &&
            [
              FIELD_TYPES.Files.type,
              FIELD_TYPES.Relationship.type,
              FIELD_TYPES.Checkboxes.type,
            ].includes(field.fieldType)
          ) {
            return !resolution;
          }
          if (
            field &&
            field.fieldType === FIELD_TYPES.TeamSelector.type &&
            !field.isDefault
          ) {
            return false;
          }
          if (field && field.fieldType === FIELD_TYPES.DynamicTags.type) {
            return !(
              field &&
              ((fieldValue && Boolean(fieldValue.length)) ||
                (removeTags && Boolean(removeTags.length)))
            );
          }

          if (field && field.isRequired && !fieldValue) {
            return !fieldValue;
          }

          if (field && field.fieldType === FIELD_TYPES.Email.type) {
            return isEmail.full(fieldValue);
          }

          return !field;
        default: {
          return false;
        }
      }
    }
    return false;
  };

  const disableNextButton = isFetching || handleDisableButton();

  const disablePerformActionSubmit =
    !(summary && (summary.clientKey || summary.entityRecordsSetKey)) ||
    performingAction;

  const allowedRecordCount =
    (isContacts ? summary?.clientsCount : summary?.allowedCount) ?? 0;
  const isSelectionAllRecords = selection.checkedCount === allCount;

  return (
    <BasicModalWithConfirmation
      heading={`${title.affix} ${title.text}`}
      size="medium"
      onHide={onHide}
      dirty={dirty}
      hideFooter
      {...props}
    >
      <StyledModalBody>
        {currentStep === 1 && (
          <ActionButtons
            mobile={isMobile}
            onSelect={onSelect}
            performActionOptions={performActionOptions}
          />
        )}
        {!(currentStep === 1) && (
          <PerformKizenStepper currentStep={currentStep} steps={steps} />
        )}
        {currentStep === 2 && !disableConfigureActionStep && (
          <SelectedActionStep
            userAction={selectedAction}
            sendTextValue={sendTextValue.htmlContent}
            onSetSendTextValue={setSendTextValue}
            sendTextMergeFields={sendTextMergeFields}
            tagsToAdd={tagsToAdd}
            tagsToRemove={tagsToRemove}
            field={field}
            fieldValue={fieldValue}
            resolution={resolution}
            removeTags={removeTags}
            tagsField={tagsField}
            onAddTagsToAdd={setTagsToAdd}
            onAddTagsToRemove={setTagsToRemove}
            teamMembers={teamMembers}
            onAddTeamMembers={setTeamMembers}
            grantMyRecordsAccess={grantMyRecordsAccess}
            onGrantMyRecordsAccess={setGrantMyRecordsAccess}
            onSetSubscriptionItem={setSubscriptionItem}
            onSetSubscriptionStatus={setSubscriptionStatus}
            subscriptionItem={subscriptionItem}
            subscriptionStatus={status}
            setField={setField}
            setFieldValue={setFieldValue}
            setResolutionValue={setResolutionValue}
            setRemoveTags={setRemoveTags}
            sendEmailStep={sendEmailStep}
            surveyValue={surveyValue}
            setSurveyValue={setSurveyValue}
            automation={automation}
            setAutomation={setAutomation}
            model={model}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            includeKizenId={includeKizenId}
            setIncludeKizenId={setIncludeKizenId}
            secondaryAction={secondaryAction}
            setSecondaryAction={setSecondaryAction}
          />
        )}
        {currentStep === 3 && (
          <SummaryStep
            userAction={selectedAction}
            summary={summary && { ...summary, text: title.text }}
            sendTextMergeFields={sendTextMergeFields}
            sendTextValue={sendTextValue.htmlContent}
            tagsToAdd={tagsToAdd}
            tagsToRemove={tagsToRemove}
            teamMembers={teamMembers}
            subscriptionItem={subscriptionItem}
            subscriptionStatus={status}
            fieldData={{ field, fieldValue, resolution, removeTags }}
            sendEmailStep={sendEmailStep}
            surveyValue={surveyValue}
            automation={automation}
            objectInfo={objectInfo}
            secondaryAction={secondaryAction}
          />
        )}
      </StyledModalBody>
      {!(currentStep === 1) && (
        <StyledModalFooter showBorder={false}>
          <div className="button-box">
            {currentStep === 2 && (
              <>
                <Button
                  variant="text"
                  color="blue"
                  onClick={goToSelectActionStep}
                >
                  {t('Back')}
                </Button>
                <Button onClick={goToSummaryStep} disabled={disableNextButton}>
                  {t('Next')}
                </Button>
              </>
            )}
            {currentStep === 3 && (
              <>
                <Button
                  variant="text"
                  color="blue"
                  onClick={() =>
                    disableConfigureActionStep
                      ? goToSelectActionStep()
                      : goToConfigureActionStep()
                  }
                >
                  {t('Back')}
                </Button>
                <Button
                  onClick={() => {
                    if (
                      selectedAction.value !== 'bulk_export_to_csv' &&
                      (isSelectionAllRecords ||
                        selection.checkedCount > CONFIRM_BULK_ACTION_THRESHOLD)
                    ) {
                      setShowConfirmModal(true);
                    } else {
                      handleSubmit();
                    }
                  }}
                  disabled={
                    disablePerformActionSubmit ||
                    !(summary?.clientsCount || summary?.allowedCount)
                  }
                >
                  {t('Confirm')}
                </Button>
              </>
            )}
          </div>
        </StyledModalFooter>
      )}
      {showConfirmModal && (
        <ConfrimBulkActionModal
          isAllRecordsAction={allCount === allowedRecordCount}
          recordCount={allowedRecordCount}
          onHide={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowConfirmModal(false);
            handleSubmit();
          }}
        />
      )}
    </BasicModalWithConfirmation>
  );
}
