import { useSelector } from 'react-redux';

export const useCanViewTimeline = ({
  objectId,
  isClient,
}: {
  objectId?: string;
  isClient?: boolean;
}): boolean => {
  const access = useSelector((s: any) => s.authentication.access);

  if (isClient) {
    return (
      access?.contacts?.single_record_actions?.record_layout_timeline ?? false
    );
  }

  if (objectId) {
    return (
      access?.custom_objects?.custom_object_entities?.[objectId]
        ?.single_record_actions?.record_layout_timeline ?? false
    );
  }

  return false;
};
