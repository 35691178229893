import { Editor } from '@craftjs/core';
import { css, Global } from '@emotion/core';
import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Frame } from './Frame';
import { ViewerContext } from './ViewerContext';
import * as nodes from './nodes';
import ProximaNovaLight from './fonts/ProximaNova-Light.woff';
import ProximaNovaLight2 from './fonts/ProximaNova-Light.woff2';
import ProximaNova from './fonts/ProximaNova-Regular.woff';
import ProximaNova2 from './fonts/ProximaNova-Regular.woff2';
import ProximaNovaSemiBold from './fonts/ProximaNova-Semibold.woff';
import ProximaNovaSemiBold2 from './fonts/ProximaNova-Semibold.woff2';
import ProximaNovaBold from './fonts/ProximaNova-Bold.woff';
import ProximaNovaBold2 from './fonts/ProximaNova-Bold.woff2';
import { getSortedFieldsFromPageData } from '../internal/utility/fields';
import { QueryClientProvider } from 'react-query';
import { unsafeRootQueryClient } from '../queries/unsafe-query-client';

// Copied form apps/react-app/app/typography
export const baseFontSize = 10; // px
export const fontWeights = {
  light: 100,
  regular: 400,
  bold: 600,
  black: 900,
};
const Typing = () => {
  return (
    <Global
      styles={css`
        * {
          font-family: 'Proxima Nova', sans-serif;
          font-size: ${baseFontSize}px;
          line-height: 1;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src:
            url(${ProximaNovaLight}) format('woff'),
            url(${ProximaNovaLight2}) format('woff2');
          font-weight: ${fontWeights.light};
          font-style: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src:
            url(${ProximaNova}) format('woff'),
            url(${ProximaNova2}) format('woff2');
          font-weight: ${fontWeights.regular};
          font-style: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src:
            url(${ProximaNovaSemiBold}) format('woff'),
            url(${ProximaNovaSemiBold2}) format('woff2');
          font-weight: ${fontWeights.bold};
          font-style: normal;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src:
            url(${ProximaNovaBold}) format('woff'),
            url(${ProximaNovaBold2}) format('woff2');
          font-weight: ${fontWeights.black};
          font-style: normal;
        }

        :root {
          --font-family-sans-serif: 'Proxima Nova', sans-serif;
          -webkit-font-smoothing: antialiased;
        }
      `}
    />
  );
};

const ViewerEditor = ({ children, ...rest }) => (
  <Editor enabled={false} resolver={nodes} {...rest}>
    {children}
  </Editor>
);

export const PageBuilderViewer = forwardRef(
  (
    {
      data,
      businessId,
      isSubmitting,
      onSubmit,
      validate,
      allFormValues,
      onPageChange,
      removeRootBackground,
      onDeleteFile,
      onUpdateFile,
      onUploadFile,
      inputValidators,
      recaptchaSitekey,
      publicTeamMemberService,
      ...others
    },
    ref
  ) => {
    const values = useRef(allFormValues ?? {});
    const [rootWidth, setRootWidth] = useState(null);
    const sortedFields = useMemo(
      () => getSortedFieldsFromPageData(data),
      [data]
    );

    const setValues = useCallback((setValueFn) => {
      values.current = setValueFn(values.current);
    }, []);

    const ctx = useMemo(() => {
      const rootNode = { data: data.ROOT };

      return {
        businessId,
        rootNode,
        rootWidth,
        removeRootBackground,
        allFormValues,
        values,
        isSubmitting,
        onSubmit,
        validate,
        onPageChange,
        inputValidators,
        setValues,
        setRootWidth,
        onDeleteFile,
        onUpdateFile,
        onUploadFile,
        sortedFields,
        recaptchaSitekey,
        publicTeamMemberService,
      };
    }, [
      data.ROOT,
      businessId,
      rootWidth,
      removeRootBackground,
      allFormValues,
      isSubmitting,
      onSubmit,
      validate,
      onPageChange,
      inputValidators,
      setValues,
      onDeleteFile,
      onUpdateFile,
      onUploadFile,
      sortedFields,
      recaptchaSitekey,
      publicTeamMemberService,
    ]);
    return (
      <QueryClientProvider client={unsafeRootQueryClient}>
        <Typing />
        <ViewerEditor enabled={false} {...others}>
          <ViewerContext.Provider value={ctx}>
            <Frame data={data} ref={ref} />
          </ViewerContext.Provider>
        </ViewerEditor>
      </QueryClientProvider>
    );
  }
);
