import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';

const StyledKizenTypography = styled(KizenTypography)`
  line-height: 1;
  margin-bottom: ${gutters.spacing(2, 4)}px;
`;
const SectionWrapper = styled.div`
  margin-bottom: ${gutters.spacing(5, 4)}px;
  &&:last-child {
    margin-bottom: ${gutters.spacing(1)}px;
  }
  && > div:last-child {
    margin-bottom: 0;
  }
`;
const Section = forwardRef(({ label, children, ...rest }, ref) => {
  return (
    <SectionWrapper ref={ref} {...rest}>
      {label ? (
        <StyledKizenTypography as="h3" type="subheader">
          {label}
        </StyledKizenTypography>
      ) : null}
      {children}
    </SectionWrapper>
  );
});
export default Section;
