import { useMemo } from 'react';
import {
  CustomObjectLike,
  FieldLike,
  GenericOption,
  MappingRule,
} from '__pages/SmartConnectors/types';
import { SMART_FIELD_TYPES } from '__pages/SmartConnectors/constants';

export type UseCustomObjectFieldsResult = {
  fieldsById: Record<string, FieldLike>;
  fieldsByCategory: Record<string, FieldLike[]>;
  fieldForCreate: FieldLike | undefined;
  fieldOptions: { label: string; options: GenericOption[] }[];
  categoryOptions: GenericOption[];
};

export type RulesByFieldId = Record<
  string,
  { rule: MappingRule; index: number }
>;

export const useCustomObjectFields = (
  customObject: CustomObjectLike,
  rulesByFieldId: RulesByFieldId
) => {
  const { fieldsById, fieldsByCategory, fieldForCreate, categoryOptions } =
    useMemo<Omit<UseCustomObjectFieldsResult, 'fieldOptions'>>(() => {
      const fieldsById = (customObject?.fields || []).reduce(
        (acc, field) => ({ ...acc, [field.id]: field }),
        {} as Record<string, FieldLike>
      );

      const fieldForCreate = customObject?.fields?.find(
        ({ name }) => name === 'name' || name === 'email'
      );

      const fieldsByCategory = (customObject?.fields || []).reduce(
        (acc, field) => {
          if (
            field.id === fieldForCreate?.id ||
            !SMART_FIELD_TYPES[field.fieldType] ||
            field.name === 'created'
          ) {
            return acc;
          }
          if (acc[field.category]) {
            acc[field.category].push(field);
          } else {
            acc[field.category] = [field];
          }
          return acc;
        },
        {} as Record<string, FieldLike[]>
      );

      const categoryOptions = [...(customObject?.fieldCategories || [])]
        .sort(({ order: a }, { order: b }) => a - b)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        }));

      return {
        fieldsById,
        fieldsByCategory,
        fieldForCreate,
        categoryOptions,
      };
    }, [customObject?.fields, customObject?.fieldCategories]);

  const fieldOptions = useMemo(() => {
    return [...(customObject?.fieldCategories || [])]
      .sort(({ order: a }, { order: b }) => a - b)
      .reduce(
        (acc, { id, name }) => {
          const options = (fieldsByCategory[id] || []).reduce(
            (acc, { id: fieldId, displayName, name }) => {
              if (!rulesByFieldId[fieldId]) {
                acc.push({
                  value: fieldId,
                  label: `${displayName} (${name})`,
                });
              }
              return acc;
            },
            [] as GenericOption[]
          );

          if (options.length) {
            acc.push({ label: name, options });
          }

          return acc;
        },
        [] as { label: string; options: GenericOption[] }[]
      );
  }, [customObject?.fieldCategories, fieldsByCategory, rulesByFieldId]);

  return {
    fieldsById,
    fieldsByCategory,
    fieldForCreate,
    fieldOptions,
    categoryOptions,
  };
};
