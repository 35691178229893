import { useEffect, useState, useRef } from 'react';
import {
  maybeTrackDashboardLoadedUnloaded,
  maybeTrackDashboardIdleState,
} from 'utility/analytics';
import DASHBOARD_STATUS from 'pages/Dashboard/constants/status';
import DASHBOARD_EVENTS from 'constants/customEvents';

const DashboardAnalytics = () => {
  const intitialLoadRef = useRef(false);
  const intitialIdleRef = useRef(true);
  const dashboardIdRef = useRef(null);
  const [idleTimer, setIdleTimer] = useState(null);

  useEffect(() => {
    const trackDashboardEvents = (event) => {
      const { detail } = event;
      switch (detail?.status) {
        case DASHBOARD_STATUS.ACTIVE:
          // if dashboard changes with no unmount event, reset
          if (dashboardIdRef.current !== detail?.dashboardId) {
            dashboardIdRef.current = detail?.dashboardId;
            intitialLoadRef.current = false;
            intitialIdleRef.current = true;
            setIdleTimer(Date.now());
          }
          // on load start timer
          if (!intitialLoadRef.current) {
            intitialLoadRef.current = true;
            setIdleTimer(Date.now());
          }
          break;
        case DASHBOARD_STATUS.IDLE:
          // we only want to store the intitial idle state
          if (intitialLoadRef.current && intitialIdleRef.current) {
            intitialIdleRef.current = false;
            intitialLoadRef.current = false;
            const activeDuration = Date.now() - idleTimer;
            maybeTrackDashboardIdleState({
              objectId: detail?.objectId,
              dashboardId: detail?.dashboardId,
              activeDuration,
            });
          }
          setIdleTimer(Date.now());
          break;
        case DASHBOARD_STATUS.UNLOADED:
          maybeTrackDashboardLoadedUnloaded({
            objectId: detail?.objectId,
            dashboardId: detail?.dashboardId,
            unloadedCount: detail?.unloaded,
            loadedCount: detail?.loaded,
          });
          break;
        default:
          break;
      }
    };

    window.addEventListener(
      DASHBOARD_EVENTS.STATUS_UPDATE,
      trackDashboardEvents
    );

    return () => {
      window.removeEventListener(
        DASHBOARD_EVENTS.STATUS_UPDATE,
        trackDashboardEvents
      );
    };
  }, [idleTimer]);

  return null;
};
export default DashboardAnalytics;
