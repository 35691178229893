import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';
import { ensureDeserializedFormPages } from 'xforms/api';
import {
  getContactsAccess,
  getCustomObjectAccess,
} from '../authentication/selectors';
import { initialStore } from '../initialStore';

export const generatePageConfig = (fromApi = {}, fromState = {}) => {
  const defaultPageConfigWhichMustBe = initialStore.surveysPage.pageConfig;

  return Object.keys(defaultPageConfigWhichMustBe).reduce((acc, el) => {
    const compare = (property) => {
      const propertyWhichShouldBeAlwaysFromBackEnd = [
        'page',
        'size',
        'sort',
        'search',
      ];
      // highest priority from url string
      if (fromState && fromState[property]) return fromState[property];

      // check extra fields
      if (
        propertyWhichShouldBeAlwaysFromBackEnd.includes(property) &&
        fromApi
      ) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      return defaultPageConfigWhichMustBe[property];
    };

    return { ...acc, [el]: compare(el) };
  }, {});
};

export const getSurveys = createAction(
  'surveysPage/getSurveys',
  function prepare(updatePageConfig = true, refreshPage = false) {
    return {
      payload: {
        updatePageConfig,
        refreshPage,
      },
    };
  }
);
export const updateSurveyField = createAction('surveysPage/updateSurveyField');
export const duplicateSurvey = createAction('surveysPage/duplicateSurvey');
export const deleteSurvey = createAction('surveysPage/deleteSurvey');
export const duplicateSurveySuccess = createAction(
  'surveysPage/duplicateSurveySuccess'
);
export const setPageConfig = createAction('surveysPage/setPageConfig');
export const setPageConfigSuccess = createAction(
  'surveysPage/setPageConfigSuccess'
);
export const setPageConfigFail = createAction('surveysPage/setPageConfigFail');

// Slices
const defaultState = initialStore.surveysPage;

const surveysSlice = createSlice({
  name: 'surveysPage',
  initialState: defaultState,
  reducers: {
    buildPage: (state, action) => {
      const { page } = action.payload;
      state.isFetching = true;
      state.pageConfig = {
        ...state.pageConfig,
        ...page,
      };
    },
    buildPageComplete: (state, action) => {
      const { pageResponse, models } = action.payload;
      state.pageConfig = generatePageConfig(pageResponse, state.pageConfig);
      state.models = models;
    },
    buildPageFinish: (state) => {
      state.isFetching = false;
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    updatePageConfig(state, action) {
      const { payload } = action;
      state.pageConfig = {
        ...state.pageConfig,
        ...payload,
      };
    },
    getSurveysStart(state) {
      state.isFiltering = true;
    },
    getSurveysSuccess(state, action) {
      const { surveys, surveysCount } = action.payload;
      state.surveys = surveys;
      state.surveysCount = surveysCount;
    },
    getSurveysFail(state, action) {
      state.error = action.payload;
    },
    getSurveysFinish(state) {
      state.isFiltering = false;
    },
    updateSurveyFieldFail(state, action) {
      state.error = action.payload;
    },
    duplicateSurveyFail(state, action) {
      state.error = action.payload;
    },
    deleteSurveySuccess(state, action) {
      state.toastMessage = action.payload;
    },
    cleanDeleteSurveyToastMessage(state) {
      state.toastMessage = null;
    },
    deleteSurveyFail(state, action) {
      state.toastMessage = action.payload;
      state.error = action.payload;
    },
    addNewSurvey(state, { payload }) {
      state.surveys.unshift(payload);
      state.surveysCount += 1;
    },
    updateSurveyFieldSuccess(state, action) {
      const { payload } = action;
      const surveys = state.surveys.map((item) => {
        if (item.id === payload.id) {
          item.name = payload.name ?? item.name;
          item.formUi = payload.formUi ?? item.formUi;
        }
        return item;
      });
      state.surveys = surveys;
    },
    replaceSurvey(state, { payload: { survey } }) {
      const index = state.surveys.findIndex((s) => s.id === survey.id);
      const surveys = [...state.surveys];
      if (index < 0) {
        surveys.unshift(survey);
      } else {
        surveys.splice(index, 1, survey);
      }
      state.surveys = surveys;
    },
  },
});

export const {
  buildPage,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  updatePageConfig,
  getSurveysSuccess,
  getSurveysFail,
  updateSurveyFieldFail,
  duplicateSurveyFail,
  deleteSurveyFail,
  deleteSurveySuccess,
  cleanDeleteSurveyToastMessage,
  updateSurveyFieldSuccess,
  addNewSurvey,
  replaceSurvey,
  getSurveysStart,
  getSurveysFinish,
} = surveysSlice.actions;

export const { reducer } = surveysSlice;

// Selectors
export const selectSurveysIsFiltering = (state) =>
  state.surveysPage.isFiltering;
export const selectSurveysPageConfig = (state) => state.surveysPage.pageConfig;
export const selectSurveys = (state) => state.surveysPage.surveys;
export const surveysIsFetching = (state) => state.surveysPage.isFetching;
export const selectSurveysCount = (state) => state.surveysPage.surveysCount;
export const selectSurveysDeleteToastMessage = (state) =>
  state.surveysPage.toastMessage;
export const selectSurveyById = (id) => (state) => {
  const survey = state.surveysPage.surveys.find((s) => s.id === id);

  return survey
    ? {
        ...survey,
        formUi: {
          ...survey.formUi,
          pages: ensureDeserializedFormPages(survey.formUi.pages),
        },
      }
    : { formUi: { pages: [] } };
};
export const selectAddSurveyModels = createSelector(
  (state) => state.surveysPage.models,
  getContactsAccess,
  getCustomObjectAccess,
  (models, contactAccess, { customObjectEntities }) => {
    return models.filter((model) => {
      return model.name === 'client_client'
        ? contactAccess.canCreateNewContact
        : customObjectEntities[model.id]?.canCreate;
    });
  }
);
