import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

export const renderWith =
  (
    reactComponent,
    opts = {
      tippyConfig: {},
      nodeSelector: null,
      appendToSelector: null,
    }
  ) =>
  () => {
    const tippyConfig = opts.tippyConfig || {};
    const nodeSelector = opts.nodeSelector || 'span.suggestion';
    let reactRenderer;
    let popup;
    return {
      onStart: async (props) => {
        reactRenderer = new ReactRenderer(reactComponent, {
          props: { ...props, ...opts.props },
          editor: props.editor,
        });
        const decorationNode =
          props.editor.view.dom.querySelector(nodeSelector);
        const appendToNode =
          opts.appendToSelector &&
          document.querySelector(opts.appendToSelector);
        const appendTo =
          appendToNode ||
          decorationNode?.closest('tbody') ||
          decorationNode?.closest('.modal-body') ||
          document.body;
        if (decorationNode) {
          popup = tippy('body', {
            zIndex: 0,
            getReferenceClientRect: props.clientRect,
            appendTo: () => appendTo,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
            ...tippyConfig,
          });
        }
      },
      onUpdate(props) {
        reactRenderer.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },
      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return false;
        }

        if (reactRenderer?.ref) {
          const keyDownResult = reactRenderer.ref?.onKeyDown(props);
          if (keyDownResult?.close) {
            popup[0].hide();
            return false;
          }
          return keyDownResult;
        }
        return true;
      },
      onExit() {
        popup[0].destroy();
        reactRenderer.destroy();
      },
    };
  };
