import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { borderRadii } from 'app/spacing';

const Container = styled.div`
  border: 1px solid ${grayScale.medium};
  border-top-width: 0;
  border-right-width: 0;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isFirstRow }) =>
    isFirstRow &&
    css`
      border-top-right-radius: ${borderRadii.small};
    `}
  ${({ isLastRow }) =>
    isLastRow &&
    css`
      border-bottom-right-radius: ${borderRadii.small};
      border-bottom-width: 0;
    `}
`;

const DayCalendarCell = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default DayCalendarCell;

DayCalendarCell.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  time: PropTypes.string.isRequired,
  isFirstRow: PropTypes.bool.isRequired,
  isLastRow: PropTypes.bool.isRequired,
};
