import { createSelector, createSlice, createAction } from '@reduxjs/toolkit';
import { initialStore } from '../initialStore';

export const generatePageConfig = (fromApi, fromState) => {
  const defaultPageConfigWhichMustBe =
    initialStore.customObjectsPage.pageConfig;

  return Object.keys(defaultPageConfigWhichMustBe).reduce((acc, el) => {
    const compare = (property) => {
      const propertyWhichShouldBeAlwaysFromBackEnd = [
        'page',
        'size',
        'sort',
        'search',
      ];

      // highest priority from url string
      if (fromState && fromState[property]) return fromState[property];

      // check extra fields
      if (
        propertyWhichShouldBeAlwaysFromBackEnd.includes(property) &&
        fromApi
      ) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      return defaultPageConfigWhichMustBe[property];
    };

    return { ...acc, [el]: compare(el) };
  }, {});
};

export const getCustomObjects = createAction(
  'customObjectsPage/getCustomObjects',
  function prepare({ updatePageConfig = true } = {}) {
    return {
      payload: {
        updatePageConfig,
      },
    };
  }
);
export const getCustomObjectBySearch = createAction(
  'customObjectsPage/getCustomObjectBySearch'
);
export const updateCustomObjectField = createAction(
  'customObjectsPage/updateCustomObjectField'
);
export const deleteCustomObject = createAction(
  'customObjectsPage/deleteCustomObject'
);
export const setPageConfig = createAction('customObjectsPage/setPageConfig');
export const setPageConfigSuccess = createAction(
  'customObjectsPage/setPageConfigSuccess'
);
export const setPageConfigFail = createAction(
  'customObjectsPage/setPageConfigFail'
);
// createOrUpdateGroup
export const createOrUpdateGroup = createAction(
  'customObjectsPage/CREATE_OR_UPDATE_GROUP'
);
export const createOrUpdateGroupFail = createAction(
  'customObjectsPage/CREATE_OR_UPDATE_GROUP_FAIL'
);
// removeGroup
export const removeGroup = createAction('customObjectsPage/REMOVE_GROUP');
export const removeGroupFail = createAction(
  'customObjectsPage/REMOVE_GROUP_FAIL'
);

const filterCountReducer = (acc, value) => {
  const filtersWithValues = value.filters.filter(
    (item) => Object.keys(item.values).length
  );
  return acc + filtersWithValues.length;
};

// Slices
const defaultState = initialStore.customObjectsPage;

const customObjectsSlice = createSlice({
  name: 'customObjects',
  initialState: defaultState,
  reducers: {
    buildPage: (state, { payload }) => {
      const { page } = payload;
      const pageConfig = { ...state.pageConfig, ...page };
      state.pageConfig = pageConfig;
      state.isFetching = true;
    },
    buildPageComplete: (state, { payload }) => {
      const { pageResponse } = payload;
      const groups = [{ value: 'none', label: 'All Custom Objects' }];
      // after getGroups();
      // const groups = [{ value: 'none', label: 'All Custom Objects' }].concat(
      //   groupsResponse
      //     .map((el) => ({ ...el, value: el.id, label: el.name }))
      //     .sort((a, b) => a.label.localeCompare(b.label))
      // );
      const pageConfig = generatePageConfig(pageResponse, state.pageConfig);
      state.pageConfig = pageConfig;
      state.groups = groups;
    },
    buildPageSuccess: (state) => {
      state.isFetching = false;
    },
    getCustomObjectsStart: (state) => {
      state.isFetchingCustomObjects = true;
      state.isFiltering = true;
    },
    getCustomObjectsFinish: (state) => {
      state.isFiltering = false;
    },
    getCustomObjectsSuccess(state, action) {
      const { customObjects, customObjectsCount, customObjectAccess } =
        action.payload;
      state.customObjects = customObjects;
      state.customObjectsCount = customObjectsCount;
      state.customObjectAccess = customObjectAccess;
      state.isFetchingCustomObjects = false;
    },
    getCustomObjectsFail(state, action) {
      state.error = action.payload;
      state.isFetchingCustomObjects = false;
    },
    getFullListOfCustomObjects(state) {
      state.isFetchingFullListOfCustomObjects = true;
    },
    getFullListOfCustomObjectsFail(state) {
      state.isFetchingFullListOfCustomObjects = false;
    },
    getFullListOfCustomObjectsSuccess: (state, { payload }) => {
      state.fullListOfCustomObjects = payload;
      state.isFetchingFullListOfCustomObjects = false;
    },
    updateCustomObjectFieldFail(state, action) {
      state.error = action.payload;
    },
    cleanDeleteCustomObjectToastMessage(state) {
      state.toastMessage = null;
    },
    deleteCustomObjectFail(state, action) {
      state.toastMessage = action.payload;
      state.error = action.payload;
    },
    deleteCustomObjectSuccess(state, action) {
      state.toastMessage = action.payload;
    },
    updatePageConfig(state, action) {
      const { payload } = action;
      state.pageConfig = {
        ...state.pageConfig,
        ...payload,
      };
    },
    updatePageConfigBySearch(state, action) {
      const { payload } = action;
      state.pageConfig = {
        ...state.pageConfig,
        ...payload,
      };
    },
    updateCustomObjectFieldSuccess(state, action) {
      const { payload } = action;
      const customObjects = state.customObjects.map((item) =>
        item.id === payload.id ? payload : item
      );
      state.customObjects = customObjects;
    },
    setFilterSetOperation: (state, { payload }) => {
      const { id, value } = payload;
      const boolVal = value === 'all';
      const query = state.filters.config.query.map((el) =>
        el.id === id ? { ...el, and: boolVal } : el
      );
      state.filters = {
        ...state.filters,
        config: {
          ...state.filters.config,
          query,
        },
      };
    },
    setFilterGroupOperation: (state, { payload }) => {
      const and = payload.value === 'and';
      state.filters = {
        ...state.filters,
        config: {
          ...state.filters.config,
          and,
        },
      };
    },
    removeFilterRow: (state, { payload }) => {
      const { filterId, queryId } = payload;
      const newQueryArray = state.filters.config.query
        .map((el) =>
          el.id === queryId
            ? { ...el, filters: el.filters.filter((e) => e.id !== filterId) }
            : el
        )
        .filter((el) => el.filters.length !== 0);
      const numberOfFilters = newQueryArray.reduce(filterCountReducer, 0);
      state.filters = {
        ...state.filters,
        numberOfFilters,
        config: {
          ...state.filters.config,
          query: newQueryArray,
        },
      };
    },

    removeGroupSuccess: (state, { payload }) => {
      const { id } = payload;
      state.groups = state.groups.filter((el) => el.id !== id);
      state.filters = initialStore.customObjectsPage.filters;
      state.pageConfig = {
        ...state.pageConfig,
        group: null,
        page: 1,
        filterObject: {},
      };
    },
    createOrUpdateGroupSuccess: (state, { payload }) => {
      if (state.pageConfig.group) {
        state.groups = state.groups.map((el) =>
          el.id === payload.id
            ? { ...payload, value: payload.id, label: payload.name }
            : el
        );
        return;
      }

      const [allOption, ...rest] = state.groups;

      const groups = rest
        .concat({ ...payload, value: payload.id, label: payload.name })
        .sort((a, b) => a.name.localeCompare(b.name));

      state.groups = [allOption].concat(groups);
      state.pageConfig = {
        ...state.pageConfig,
        group: payload.id,
        page: 1,
        filterObject: {},
      };
      state.groupCount = state.customObjectsCount;
    },
    setFilterName: (state, { payload }) => {
      const { name } = payload;
      state.filters = {
        ...state.filters,
        name,
      };
    },
  },
});

export const {
  buildPage,
  buildPageComplete,
  buildPageSuccess,
  buildPageFail,
  getCustomObjectsSuccess,
  updatePageConfig,
  updatePageConfigBySearch,
  getCustomObjectsStart,
  getCustomObjectsFail,
  updateCustomObjectFieldFail,
  deleteCustomObjectFail,
  deleteCustomObjectSuccess,
  cleanDeleteCustomObjectToastMessage,
  updateCustomObjectFieldSuccess,
  setFilterName,
  createOrUpdateGroupSuccess,
  removeGroupSuccess,
  removeFilterRow,
  setFilterGroupOperation,
  setFilterSetOperation,
  getFullListOfCustomObjects,
  getFullListOfCustomObjectsFail,
  getFullListOfCustomObjectsSuccess,
  getCustomObjectsFinish,
} = customObjectsSlice.actions;

export const { reducer } = customObjectsSlice;

// Selectors
export const getCustomObjectsPageConfigSelector = createSelector(
  (state) => state && state.customObjectsPage.pageConfig,
  (pageConfig) => pageConfig
);

export const getCustomObjectsPageIsFetching = (state) =>
  state && state.customObjectsPage.isFetching;

export const getFullListOfCustomObjectsIsFetching = (state) =>
  state && state.customObjectsPage.isFetchingFullListOfCustomObjects;

export const getCustomObjectsSelector = createSelector(
  (state) => state && state.customObjectsPage.customObjects,
  (customObjects) => customObjects
);

export const getCustomObjectsCountSelector = createSelector(
  (state) => state.customObjectsPage.customObjectsCount,
  (customObjectsCount) => customObjectsCount
);

export const getCustomObjectDeleteToastMessageSelector = createSelector(
  (state) => state.customObjectsPage.toastMessage,
  (toastMessage) => toastMessage
);

export const getCustomObjectAccessSelector = createSelector(
  (state) => state.customObjectsPage.customObjectAccess,
  (customObjectAccess) => customObjectAccess
);

export const getFullListOfCustomObjectsSelector = createSelector(
  (state) => state.customObjectsPage.fullListOfCustomObjects,
  (fullListOfCustomObjects) => fullListOfCustomObjects
);

export const getCustomObjectsIsFetchingSelector = ({ customObjectsPage }) =>
  customObjectsPage.isFetchingCustomObjects;

export const getCustomObjectsIsFilteringSelector = ({ customObjectsPage }) =>
  customObjectsPage.isFiltering;
