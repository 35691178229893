import { DEFAULT_FORM_BUTTON_PROPS } from './props';

export const getBackwardsCompatibleButtonProps = (props) => {
  props = {
    ...DEFAULT_FORM_BUTTON_PROPS,
    ...props,
  };

  // BW-Compatible defaults
  // Initially, we had a "green" prop passed as color.
  // Now, we only support HEX colors. This is the same
  // value currently as DEFAULT_BUTTON_PROPS.color, however
  // we are going to duplicate it here, since that default
  // may change in the future and we dont want to propagate
  // those changes back to our older form users
  if (props.color === 'green') {
    props.color = '#4BC7B4';
  }

  return props;
};
