import { QuickFiltersTable } from './subpages/QuickFiltersTable';
import { MyQuickFilters } from './subpages/MyQuickFilters';
import { QuickFiltersTemplate } from './subpages/QuickFiltersTemplate';

export const SUBPAGES = {
  MINE: 'mine',
  TEMPLATES: 'templates',
};

export const SUBROUTES = {
  myQuickFilters: {
    label: (t) => t('My Quick Filters'),
    component: MyQuickFilters,
    exact: true,
    path: SUBPAGES.MINE,
    icon: 'filter',
  },
  quickFiltersTemplates: {
    label: (t) => t('Quick Filter Templates'),
    component: QuickFiltersTable,
    exact: true,
    path: SUBPAGES.TEMPLATES,
    icon: 'files-light',
  },
  quickFilterTemplate: {
    component: QuickFiltersTemplate,
    exact: true,
    path: SUBPAGES.TEMPLATES + '/:id',
  },
};

export const SUBROUTES_LINKS = {
  myQuickFilters: SUBROUTES.myQuickFilters,
  quickFiltersTemplates: SUBROUTES.quickFiltersTemplates,
};
