export const NAV_BAR_HEIGHT = 92; // 72 + 20 top padding

export const TOOLBAR_HEIGHT = 76;

export const DEFAULT_PAGINATION_SETTINGS = {
  page: 1,
  pageSize: 10,
  search: '',
  ordering: 'name',
};

export const APPLY_PARAM = 'apply_filter_group';

export const GET_FILTER_GROUPS_PATHNAMES = {
  MINE: 'mine',
  VISIBLE: 'visible',
  OTHER: 'other',
};

export const JSON_PROP = 'config';
