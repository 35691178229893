import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TopLayerSelect } from 'components/PageBuilder/components/TopLayerSelect';

export const DEFAULT = 'default';
export const CUSTOM = 'custom';

const buildOptions = (t) => [
  { value: DEFAULT, label: t('Default') },
  { value: CUSTOM, label: t('Custom') },
];

export const FromName = React.memo(({ modalLayer, ...rest }) => {
  const { t } = useTranslation();
  const options = useMemo(() => buildOptions(t), [t]);

  return (
    <TopLayerSelect
      variant="underline"
      label={t('From Name')}
      options={options}
      modalLayer={modalLayer}
      {...rest}
    />
  );
});

FromName.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
