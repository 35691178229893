import { createElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { useAddNewNode } from '../useAddNewNode';
import {
  DEFAULT_EMAIL_BUTTON_PROPS,
  DEFAULT_FORM_BUTTON_PROPS,
  DEFAULT_STATIC_PAGE_BUTTON_PROPS,
} from '@kizen/page-builder/nodes/Button/props';

const CreateButton = ({ defaultProps }) => {
  const addNewNode = useAddNewNode();
  const buttonItem = useSelector((s) => getCreateItemByType(s, 'Button'));

  useEffect(() => {
    if (buttonItem) {
      addNewNode({
        ...buttonItem,
        ...defaultProps,
      });
    }
  }, [buttonItem, defaultProps, addNewNode]);

  return null;
};

export const EmailButtonCreator = createElement(CreateButton, {
  defaultProps: DEFAULT_EMAIL_BUTTON_PROPS,
});

export const buildFormButtonCreator = (isFormPage) =>
  createElement(CreateButton, {
    defaultProps: isFormPage
      ? DEFAULT_FORM_BUTTON_PROPS
      : DEFAULT_STATIC_PAGE_BUTTON_PROPS,
  });

export const HomepageButtonCreator = createElement(CreateButton, {
  defaultProps: DEFAULT_STATIC_PAGE_BUTTON_PROPS,
});
