import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BasicModal from 'components/Modals/presets/BasicModal';

export const ConfirmationModal = React.memo(({ children, ...others }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      size="small"
      typeOfContent="text"
      heading={t('You Have Unsaved Changes')}
      buttonText={t('Discard Changes')}
      actionBtnColor="red"
      {...others}
    >
      {children ||
        t('Unsaved changes will be lost, would you like to continue?')}
    </BasicModal>
  );
});

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
