export const UNIT = {
  PERCENT: 'percent',
  PIXEL: 'pixel',
};

export const FONT_SIZES = [
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '24',
  '28',
  '36',
  '48',
  '64',
  '72',
];

export const fontSizeOptions = FONT_SIZES.map((f) => ({
  value: f,
  label: `${f}pt`,
}));

export const positionOptions = (t) => [
  { value: 'left', label: t('Left') },
  { value: 'center', label: t('Center') },
  { value: 'right', label: t('Right') },
];
export const alignmentOptions = positionOptions;

export const IMAGE_SIZES = {
  AUTO: 'auto',
  DYNAMIC: 'dynamic',
  FIXED: 'fixed',
};

export const DIMENSION = {
  WIDTH: 'width',
  HEIGHT: 'height',
};

export const imageSizeOptions = (t) => [
  { value: IMAGE_SIZES.AUTO, label: t('Auto') },
  { value: IMAGE_SIZES.DYNAMIC, label: t('Dynamic') },
  { value: IMAGE_SIZES.FIXED, label: t('Fixed') },
];

export const dimensionOptions = (t) => [
  { value: DIMENSION.WIDTH, label: t('Width') },
  { value: DIMENSION.HEIGHT, label: t('Height') },
];

export const textStyleOptions = [
  { value: 'bold', label: 'B' },
  { value: 'underline', label: 'U' },
  { value: 'italic', label: 'I' },
];

export const unitOptions = [
  { value: UNIT.PIXEL, label: 'px' },
  { value: UNIT.PERCENT, label: '%' },
];

export const fontFamilyOptions = [
  {
    label: 'Standard Web Fonts',
    options: [
      { label: 'Arial', value: 'Arial' },
      { label: 'Courier New', value: 'Courier New' },
      { label: 'Georgia', value: 'Georgia' },
      { label: 'Helvetica Neue', value: 'Helvetica Neue' },
      { label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode' },
      { label: 'Tahoma', value: 'Tahoma' },
      { label: 'Times New Roman', value: 'Times New Roman' },
      { label: 'Verdana', value: 'Verdana' },
    ],
  },
];

export const lineHeightOptions = [
  { value: 1, label: '1' },
  { value: 1.25, label: '1.25' },
  { value: 1.5, label: '1.5' },
  { value: 1.75, label: '1.75' },
  { value: 2, label: '2' },
  { value: 2.5, label: '2.5' },
  { value: 3, label: '3' },
];

export const lineHeightOptionsLookup = lineHeightOptions.reduce((acc, opt) => {
  acc[opt.value] = opt;
  return acc;
}, {});
