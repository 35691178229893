import Loader from 'components/Kizen/Loader';
import ChartModal from 'components/ObjectCharts/modal';
import { AREA_RESPONSES } from 'components/Wizards/RTDV/types';
import ResettableDashlet from 'pages/Dashboard/components/Dashlet/Resettable';
import DashletModal from 'pages/Dashboard/components/Dashlet/modal';
import {
  applyConfig,
  useHTMLConfig,
} from 'pages/Homepages/hooks/useHTMLConfig';
import { useModelQuery } from 'queries/models/custom-objects';
import { useCallback, useMemo, useState } from 'react';
import { HomepageBuilder } from 'ts-components/StaticBlockBuilder/HomepageBuilder';

const getObjectClass = (url) =>
  ['pipeline', 'standard'].includes(url) ? 'customObjects' : 'contacts';

export const IntermediateDashlet = ({
  dashlet,
  currentHomepage,
  setContentHeight,
  index,
  ...rest
}) => {
  const { editDashlet } = rest;
  const { data: model, isLoading } = useModelQuery(
    dashlet?.customObject,
    Boolean(dashlet?.customObject),
    {
      skipErrorBoundary: true,
    }
  );

  const [builderOpen, setBuilderOpen] = useState(false);

  const builtModel = useMemo(() => {
    return model
      ? {
          ...model,
          objectClass: getObjectClass(model?.fetchUrl),
        }
      : {};
  }, [model]);

  const { builderContent, htmlBlockConfig } = useHTMLConfig(dashlet);

  const isCharts = Object.values(AREA_RESPONSES).includes(
    dashlet?.config?.reportType
  );

  const handleStartEditingBuilderContent = useCallback(() => {
    setBuilderOpen(true);
  }, []);

  const handleSave = useCallback(
    (data) => {
      return editDashlet(undefined, data);
    },
    [editDashlet]
  );

  if (isLoading) {
    return <Loader loading />;
  }

  const columns = Math.ceil(dashlet.layout.w / 2);

  return (
    <>
      <ResettableDashlet
        currentDashboard={currentHomepage}
        dashboardId={currentHomepage.id}
        dashlet={dashlet}
        model={builtModel}
        charts={isCharts}
        ModalComponent={isCharts ? ChartModal : DashletModal}
        builderContent={builderContent}
        onStartEditingBuilderContent={handleStartEditingBuilderContent}
        modalExtraProps={{
          homepage: true,
        }}
        objectForCharts={builtModel}
        homepages
        showDateShortcut={!isCharts && !builderContent}
        setContentHeight={setContentHeight}
        index={index}
        {...rest}
      />
      {builderOpen && (
        <HomepageBuilder
          onCancel={() => {
            setBuilderOpen(false);
          }}
          onSave={handleSave}
          onSaveAndClose={(data) => {
            handleSave(data);
            setBuilderOpen(false);
          }}
          existing={applyConfig(
            builderContent,
            htmlBlockConfig,
            dashlet.layout
          )}
          defaultColumnSize={columns > 6 ? 6 : columns}
          allowRelativeLinks
        />
      )}
    </>
  );
};
