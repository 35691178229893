import { ChangeEvent, useCallback, useMemo, useRef } from 'react';

import TimelineService from 'services/TimelineService';

import { useQuery } from 'react-query';
import { TIMELINE } from 'queries/query-keys';

import { FORMAT_TYPES } from '__components/Kizen/RangeInput';

import { useTranslation } from 'react-i18next';
import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';
import { isEqual } from 'lodash';
import { ResizableSection } from '__components/Wizards/CustomObject/steps/CustomLayout/dialogs/common';
import { SwitchNoMargin, StyledRangeInput } from '../commonStyles';
import { TimelineColumns } from './TimelineColumns';
import Loader from '__components/Kizen/Loader';
import { DraggableItemType, EventType, EventTypes } from '../../Builder/types';

const SLIDER_PARAMS = {
  MIN: 600,
  MAX: 1000,
  STEP: 1,
};

type TimelineWizardMetadata = {
  includeAll: boolean;
  includeRelated: boolean;
  included: EventTypes | null | undefined;
  excluded: EventTypes;
  blockHeight: number;
};

interface TimelineWizardProps {
  index?: number;
  metadata: TimelineWizardMetadata;
  setMetadata: (metadata: any, setDirty?: boolean) => void;
  isActive?: boolean;
  timelineEventTypes: EventTypes;
  timelineEventsLoading: boolean;
}

const defaultChosenEventTypes = [
  `activity_logged`,
  `activity_scheduled`,
  `automation_started`,
  `entity_created`,
  `form_submitted`,
  `survey_submitted`,
];

export const defaultTimelineMetadata = {
  includeRelated: true,
  includeAll: true,
  excluded: [],
  included: null,
  blockHeight: 800,
};

const getDefaultTimelineMetadata = (timelineEventTypes: EventTypes) => {
  const included = timelineEventTypes.filter(({ value }) =>
    defaultChosenEventTypes.includes(value)
  );
  const excluded = timelineEventTypes.filter(
    ({ value }) => !defaultChosenEventTypes.includes(value)
  );
  return { ...defaultTimelineMetadata, included, excluded };
};

const TIMELINE_TYPE = 'timeline';

export const useIntialiseTimelineMeta = (
  metadata: TimelineWizardMetadata,
  blockType: DraggableItemType
) => {
  const intialSetup = useRef(false);

  const { data: timelineEventTypes, isLoading: timelineEventsLoading } =
    useQuery({
      queryKey: TIMELINE.EVENT_TYPES,
      queryFn: async () => {
        const types = await TimelineService.getTimelineTypes();
        return types.map((type: any) => ({ ...type, id: type.value }));
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: blockType === TIMELINE_TYPE,
    });

  const timelineMetadata: any = useMemo(() => {
    if (
      blockType === TIMELINE_TYPE &&
      !intialSetup.current &&
      timelineEventTypes &&
      !timelineEventsLoading
    ) {
      intialSetup.current = true; // this is a one time thing

      const eventTypeValues = timelineEventTypes.map(
        ({ value }: EventType) => value
      );

      let { includeRelated, includeAll, excluded, included } =
        typeof metadata?.includeRelated === 'boolean' &&
        !isEqual(defaultTimelineMetadata, metadata)
          ? metadata
          : getDefaultTimelineMetadata(timelineEventTypes);

      // removed any that may no longer exists
      excluded = excluded.filter((value) => !eventTypeValues.includes(value));
      included = (included || []).filter(
        (value) => !eventTypeValues.includes(value)
      );

      return {
        ...metadata,
        includeAll,
        includeRelated,
        excluded,
        included,
      };
    } else {
      if (blockType !== TIMELINE_TYPE) {
        intialSetup.current = false;
      }
      if (metadata) {
        return metadata;
      } else {
        return defaultTimelineMetadata;
      }
    }
  }, [blockType, metadata, timelineEventTypes, timelineEventsLoading]);

  return { timelineMetadata, timelineEventTypes, timelineEventsLoading };
};

export const TimelineWizard = (props: TimelineWizardProps) => {
  const { t } = useTranslation();
  const {
    isActive,
    metadata,
    setMetadata,
    timelineEventTypes,
    timelineEventsLoading,
  } = props;

  const handleIncludeAll = useCallback(
    (evt: ChangeEvent<HTMLInputElement>): void => {
      setMetadata({
        ...metadata,
        includeAll: evt.target.checked,
      });
    },
    [setMetadata, metadata]
  );

  const handleIncludeRelated = useCallback(
    (evt: ChangeEvent<HTMLInputElement>): void => {
      setMetadata({
        ...metadata,
        includeRelated: evt.target.checked,
      });
    },
    [setMetadata, metadata]
  );

  const handleBlockHeight = useCallback(
    (height: number): void => {
      setMetadata({
        ...metadata,
        blockHeight: height,
      });
    },
    [setMetadata, metadata]
  );

  const handleColumnsChange = useCallback(
    (columns: any[]) => {
      const columnValues = columns.map(({ value }: { value: string }) => value);
      const excluded = timelineEventTypes.filter(
        ({ value }: { value: string }) => !columnValues.includes(value)
      );

      setMetadata({
        ...metadata,
        included: columns,
        excluded,
      });
    },
    [setMetadata, metadata, timelineEventTypes]
  );

  return (
    <ResizableSection
      header={t('Choose Event Types to Display on Timeline')}
      {...props}
    >
      {isActive ? (
        <div className="kds grid grid-cols-2 gap-spacer-20 text-font-primary">
          <div>
            <Typography variant="label">
              {t('Include All Timeline Event Types on this Timeline')}
            </Typography>
            <Spacer mode="horizontal" size={15} />
            <div>
              <SwitchNoMargin
                checked={metadata?.includeAll}
                onChange={handleIncludeAll}
              />
            </div>
          </div>
          <div>
            <div>
              <Typography variant="label">
                {t(
                  'Include Timeline Events from Related Records on this Timeline'
                )}
              </Typography>
              <Spacer mode="horizontal" size={15} />
              <div>
                <SwitchNoMargin
                  checked={metadata?.includeRelated}
                  onChange={handleIncludeRelated}
                />
              </div>
            </div>
          </div>
          {metadata?.includeAll ? null : (
            <div className="col-span-2">
              {timelineEventsLoading ? (
                <Loader style={{ width: '100%', height: '200px' }} loading />
              ) : (
                <TimelineColumns
                  timelineEventTypes={timelineEventTypes}
                  onChange={handleColumnsChange}
                  initialRightColumnItems={metadata?.included}
                  initialLeftColumnItems={metadata?.excluded}
                />
              )}
            </div>
          )}
          <div className="col-span-2">
            <StyledRangeInput
              label={t('Block Height (About 1 Row Every 100px)')}
              value={
                metadata?.blockHeight || defaultTimelineMetadata.blockHeight
              }
              min={SLIDER_PARAMS.MIN}
              max={SLIDER_PARAMS.MAX}
              step={SLIDER_PARAMS.STEP}
              onChange={handleBlockHeight}
              tooltipValue={
                metadata?.blockHeight || defaultTimelineMetadata.blockHeight
              }
              onTooltipChange={handleBlockHeight}
              formatMarker={FORMAT_TYPES.PIXELS}
            />
          </div>
        </div>
      ) : null}
    </ResizableSection>
  );
};
