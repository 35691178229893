import styled from '@emotion/styled';
import { hideScrollbarCss } from 'app/spacing';
import Modal, { ModalBody } from 'components/Modals';
import css from '@emotion/css';

export const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 15px 7px 3px 15px;
  ${hideScrollbarCss}
`;

export const StyledModal = styled(Modal)`
  .modal-body {
    overflow-y: hidden;
    padding: 0;
  }
`;
export const StyledModalBody = styled(ModalBody)`
  ${({ isLoading }) =>
    !isLoading &&
    css`
      display: flex;
    `}
  padding: 0;
`;
