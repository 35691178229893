import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';

import Select from 'components/Inputs/Select';
import {
  useRelationshipSelect,
  useSelectTypeaheadWithScroll,
  Entities,
} from 'components/Inputs/Select/hooks';
import LinkedSelection from 'components/Inputs/Select/LinkedSelection';
import { SingleValue } from 'components/Inputs/Select/customize';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import MultiSelect from 'components/Inputs/MultiSelect';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { applyRef } from 'components/Inputs/props';
import { getTeamLabel } from 'services/helpers';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import { useQuery } from 'react-query';
import { TEAM_MEMBERS } from 'queries/query-keys';
import CustomObjectService from 'services/CustomObjectsService';

const getLink = (id) => `/team-member/${id}/timeline`;

const SelectTeamMember = forwardRef(
  (
    {
      value,
      onChange,
      field,
      fieldType,
      isMulty,
      fieldId,
      disabled,
      onMenuOpen,
      onMenuClose,
      ...props
    },
    ref
  ) => {
    const selectRef = useRef(null);
    const preReleaseFeatures = usePreReleaseFeatures();
    const { assignFieldHandle } = useKeyBoardContext();
    const [{ relationship }] = useRelationshipSelect({
      field,
      value: value,
      onChange,
    });

    const needsLookup =
      typeof value === 'string' ||
      (Array.isArray(value) && value.every((item) => typeof item === 'string'));

    const [selectProps, { allOptions }] = useSelectTypeaheadWithScroll({
      selectRef,
      onMenuOpen,
      onMenuClose,
      objectToOption: convertToTeamOption,
      entity: Entities.TeamMember,
      chosenValueIds: isMulty ? value : [],
    });

    const selectPropsOptionsMap = useMemo(() => {
      return (
        allOptions?.items?.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.item.id]: curr.item,
          };
        }, {}) ?? {}
      );
    }, [allOptions]);

    // If the typeahead result includes every item in `value`, then we can use
    // that data to rebuild the value, rather than needing to fetch the labels.
    const selectPropsSufficient = useMemo(() => {
      if (!Object.keys(selectPropsOptionsMap).length || !needsLookup) {
        return false;
      }

      if (isMulty) {
        return value.every((item) => selectPropsOptionsMap[item]);
      }

      return Boolean(selectPropsOptionsMap[value]);
    }, [isMulty, selectPropsOptionsMap, value, needsLookup]);

    const teamMemberValues = useMemo(() => {
      if (!needsLookup) {
        return [];
      }

      if (isMulty) {
        return value;
      }

      return value ? [value] : [];
    }, [isMulty, needsLookup, value]);

    const { data: labels, isLoading: isLoadingTeamMemberLabels } = useQuery({
      queryKey: TEAM_MEMBERS.GET(teamMemberValues),
      queryFn: () => {
        return CustomObjectService.getDisplayNames({
          employee_ids: teamMemberValues,
        });
      },
      enabled: teamMemberValues.length > 0 && !selectPropsSufficient,
    });

    const employeeMap = useMemo(() => {
      if (selectPropsSufficient) {
        return selectPropsOptionsMap;
      }

      return (
        labels?.results?.employees?.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: curr,
          };
        }, {}) ?? {}
      );
    }, [labels, selectPropsOptionsMap, selectPropsSufficient]);

    const displayValue = useMemo(() => {
      if (!value) return null;

      if (isMulty) {
        return value.map((item) => {
          if (needsLookup) {
            const label = getTeamLabel(employeeMap[item]);

            if (!label) {
              return null;
            }

            return {
              value: item,
              label,
              meta: {
                link: relationship?.link && preReleaseFeatures && getLink(item),
              },
            };
          }

          return {
            value: item.id,
            label: item.displayName || getTeamLabel(item),
            meta: {
              link: relationship?.link && preReleaseFeatures && getLink(item),
            },
          };
        });
      } else {
        let option;
        if (needsLookup) {
          const label = getTeamLabel(employeeMap[value]);

          if (!label) {
            return null;
          }

          option = {
            value,
            label,
            meta: {
              link: relationship?.link && preReleaseFeatures && getLink(value),
            },
          };
        } else {
          option = {
            value: value.id,
            label: value.displayName || getTeamLabel(value),
          };
        }

        return option
          ? {
              ...option,
              meta: {
                link:
                  relationship?.link &&
                  preReleaseFeatures &&
                  getLink(option.value),
              },
            }
          : null;
      }
    }, [
      value,
      isMulty,
      needsLookup,
      relationship?.link,
      preReleaseFeatures,
      employeeMap,
    ]);

    const mergeRef = useCallback(
      (el) => {
        applyRef(selectRef, el);
        applyRef(ref, el);
      },
      [ref]
    );

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, props);

    assignFieldHandle(fieldId, {
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      },
      customEscape: () => {
        setMenuIsOpen(false);
      },
      customUp: () => {
        setMenuIsOpen(true);
      },
      customDown: () => {
        setMenuIsOpen(true);
      },
      disabled,
    });

    return isMulty ? (
      <MultiSelect
        ref={mergeRef}
        value={displayValue}
        onChange={onChange}
        loadItems={isLoadingTeamMemberLabels}
        disabled={disabled}
        {...props}
        {...customSelectMenuProps}
        {...selectProps}
      />
    ) : (
      <Select
        ref={mergeRef}
        value={displayValue}
        onChange={onChange}
        loadItems={isLoadingTeamMemberLabels}
        components={{
          SingleValue: field.isDefault ? SingleValue : LinkedSelection,
        }}
        disabled={disabled}
        {...props}
        {...customSelectMenuProps}
        {...selectProps}
      />
    );
  }
);

SelectTeamMember.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectTeamMember;
