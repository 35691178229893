import { useCallback } from 'react';

// helper to pass through the clear filter and close the filters menu
export const useClearFilter = (clearFilter, toggleMenu) => {
  return useCallback(
    (...args) => {
      clearFilter?.(...args);
      toggleMenu(false);
    },
    [clearFilter, toggleMenu]
  );
};
