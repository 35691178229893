import React from 'react';
import styled from '@emotion/styled';
import Card from 'components/Card/Card';
import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import { gutters } from 'app/spacing';
import { PageContentContainer } from 'components/Layout/PageContentWidth';
import { DASHBOARD_PATH } from '../routes/paths';
import { useTranslation } from 'react-i18next';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';

const StyledCard = styled(Card)`
  padding: ${gutters.spacing(5)}px 0 ${gutters.spacing(5, 2)}px 0;
  margin: ${gutters.spacing(4)}px 0 0 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  && .not_found_long-text {
    max-width: 382px;
    margin-bottom: ${gutters.spacing(4, 2)}px;
    text-align: center;
  }

  && h1 {
    margin-top: 2px; //  Design nudge
    margin-bottom: ${gutters.spacing(4, 2)}px;
    line-height: 1;
  }

  && .not_found_button {
    margin-bottom: ${gutters.spacing(5, 4)}px;
  }

  && .support_text {
    margin-bottom: ${gutters.spacing(2, -1)}px;
  }
`;

export default function NotFound({ title }) {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  return (
    <PageContentContainer>
      <StyledCard>
        <KizenTypography as="h1" type="hero">
          Page Not Found
        </KizenTypography>

        <KizenTypography as="p" className="not_found_long-text">
          The URL was not found. Please check the URL and try again or click the
          button below to go to the dashboard.
        </KizenTypography>

        <Button
          color="blue"
          as={Link}
          to={DASHBOARD_PATH}
          preventDefault={false}
          className="not_found_button"
        >
          Dashboard
        </Button>
        <KizenTypography as="p" className="support_text">
          If you need additional help, please email{' '}
          <KizenTypography
            type="link-mail"
            size="micro"
            weight="bold"
            href="mailto:support@kizen.com"
          >
            support@kizen.com
          </KizenTypography>
          .
        </KizenTypography>
        <KizenTypography as="p">Error code: 404</KizenTypography>
      </StyledCard>
    </PageContentContainer>
  );
}
