import { useEffect, useMemo } from 'react';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import styled from '@emotion/styled';
import { Button } from '@kizen/kds/Button';
import { useTranslation } from 'react-i18next';
import FieldService from 'services/FieldService';
import { gutters } from 'app/spacing';
import CardSection from 'components/Layout/CardSection';
import SmallTableCard from 'components/Layout/SmallTableCard';
import KizenTypography from 'app/kizentypo';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import useExportFieldMetadata from 'hooks/useExportFieldMetadata';
import { CardBasic } from 'components/Card/Card';
import { PageSizing, ContentWidth } from 'components/Layout/PageContentWidth';
import CustomFieldsBuilder, {
  BuilderLoader,
} from 'components/Builders/CustomFieldsBuilder';
import { useFieldFromCategories } from './useFieldFromCategories';
import { useFieldEditingModal } from './useFieldEditingModal';
import { DynamicTagsManager } from 'components/Tables/DynamicTagsManager';
import { isContactTagsField, isContactTitlesField } from 'checks/fields';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const BigCard = styled(CardBasic)`
  padding-top: ${gutters.spacing(3)}px;
  margin-bottom: ${gutters.spacing(4)}px;
  display: flex;
  flex-direction: column;
  > div:last-of-type {
    flex: 1;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${gutters.spacing(4)}px;
  margin-bottom: ${gutters.spacing(2, -4)}px;
  h3 {
    margin-top: -1px; // Design nudge
    line-height: 1; // Sidestep bootstrap
  }
`;

const BigTitle = styled.div`
  align-items: flex-start;
  h3 {
    margin-top: ${gutters.spacing(1, -1)}px;
  }
  button {
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightHeader = styled.div`
  margin-right: 20px;
  margin-top: -4px;
  display: flex;
  justify-content: start;
`;

export default function ContactCustomFieldsPage(props) {
  const { t } = useTranslation();
  const { field_id } = useParams();
  const [editingFieldId, { onOpenEditFieldOptions, onCloseEditFieldOptions }] =
    useFieldEditingModal(field_id);

  const clientObject = useSelector(getBusinessClientObject);

  const { value: model } = useAsync(
    () => FieldService.getModel({ id: clientObject.id }),
    []
  );
  const { handleFieldExport } = useExportFieldMetadata({ model });

  const [
    { value: rawCategories = DROPDOWN_SHOULD_LOAD, loading },
    updateCategories,
  ] = useAsyncFnKeepLast(
    () =>
      FieldService.getCategorizedFields({
        for: 'contacts',
        includeVisible: true,
        settingsRequest: true,
      }),
    []
  );

  const fieldToEdit = useFieldFromCategories(editingFieldId, rawCategories);

  useEffect(() => {
    // Initial fetch on mount
    updateCategories();
  }, [updateCategories]);

  const [tagsField, titlesField] = useMemo(() => {
    if (Array.isArray(rawCategories)) {
      return rawCategories.reduce((collect, { fields }) => {
        if (collect.length === 2 && collect.every(Boolean)) return collect;
        const tagsField = collect[0] || fields.find(isContactTagsField);
        const ttitlesField = collect[1] || fields.find(isContactTitlesField);
        return [tagsField, ttitlesField];
      }, []);
    }
    return [];
  }, [rawCategories]);

  return (
    <PageSizing {...props}>
      <BigCard as={ContentWidth}>
        <Header>
          <BigTitle as={TitleWrapper}>
            <KizenTypography as="h3" type="subheader">
              {t('Contact Fields')}
            </KizenTypography>
          </BigTitle>
          <RightHeader>
            <Button
              className="pb-[10px]"
              color="secondary"
              onClick={handleFieldExport}
              variant="text"
            >
              {t('Export Field Metadata')}
            </Button>
          </RightHeader>
        </Header>
        {model && Array.isArray(rawCategories) && (
          <CustomFieldsBuilder
            model={model}
            field={fieldToEdit}
            updateCategories={updateCategories}
            rawCategories={rawCategories}
            loading={loading}
            onOpenEditFieldOptions={onOpenEditFieldOptions}
            onCloseEditFieldOptions={onCloseEditFieldOptions}
          />
        )}
        {(!model || !Array.isArray(rawCategories)) && <BuilderLoader loading />}
      </BigCard>
      <CardSection as={ContentWidth}>
        <SmallTableCard>
          <DynamicTagsManager model={model} field={tagsField} includeTitle />
        </SmallTableCard>
        <SmallTableCard>
          <DynamicTagsManager model={model} field={titlesField} includeTitle />
        </SmallTableCard>
      </CardSection>
    </PageSizing>
  );
}
