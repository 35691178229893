import React, { useState } from 'react';
import styled from '@emotion/styled';
import Checkbox from 'components/Checkbox/Checkbox';
import CheckboxesLabel from 'components/Checkbox/CheckboxesLabel';
import { gutters } from 'app/spacing';

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .Sample-stacked-checkbox + .Sample-stacked-checkbox {
    margin-top: 0;
  }
`;

const StyledCheckboxesLabel = styled(CheckboxesLabel)`
  margin-bottom: calc(${gutters.spacing(0, { baseline: true }) + 1}px);
`;

const options = [
  {
    label: 'Option A',
    value: 'option-a',
  },
  {
    label: 'Option B',
    value: 'option-b',
  },
  {
    label: 'Option C',
    value: 'option-c',
  },
];

const condensedOptions = [
  {
    label: 'Much Longer Checkbox Option Name',
    value: 'option-a',
  },
  {
    label: 'Option 2',
    value: 'option-b',
  },
  {
    label: 'Option 3',
    value: 'option-c',
  },
  {
    label: 'Checkbox Option',
    value: 'option-d',
  },
];

export function Checkboxes() {
  const [defaultUnchecked, setDefaultUnchecked] = useState(false);
  const [defaultChecked, setDefaultChecked] = useState(true);
  const [errorChecked, setErrorChecked] = useState(false);

  return (
    <StyledWrapper>
      <Checkbox
        label="Default"
        value="default"
        checked={defaultUnchecked}
        onChange={(ev) => setDefaultUnchecked(ev.target.checked)}
        className="Sample-stacked-checkbox"
      />
      <Checkbox
        label="Checked"
        value="default"
        checked={defaultChecked}
        onChange={(ev) => setDefaultChecked(ev.target.checked)}
        className="Sample-stacked-checkbox"
      />
      <Checkbox
        disabled
        checked
        label="Disabled (checked)"
        value="disabled"
        onChange={() => {}}
        className="Sample-stacked-checkbox"
      />
      <Checkbox
        disabled
        label="Disabled (not checked)"
        value="disabled"
        onChange={() => {}}
        className="Sample-stacked-checkbox"
      />
      <Checkbox
        error
        label="Error"
        value="error"
        checked={errorChecked}
        onChange={(ev) => setErrorChecked(ev.target.checked)}
        className="Sample-stacked-checkbox"
      />
      <Checkbox
        label="Indeterminate"
        value="indeterminate"
        onChange={() => {}}
        className="Sample-stacked-checkbox"
        indeterminate
      />
    </StyledWrapper>
  );
}

export function CheckboxGroup() {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const renderOptions = ({ options: opts, name }) => {
    return opts.map((option) => {
      return (
        <Checkbox
          label={option.label}
          value={option.value}
          name={name}
          key={option.value}
          checked={selectedOptions.includes(option.value)}
          onChange={() =>
            setSelectedOptions((prev) =>
              prev.includes(option.value)
                ? prev.filter((val) => val !== option.value)
                : [...prev, option.value]
            )
          }
          className="Sample-stacked-checkbox"
        />
      );
    });
  };

  return (
    <fieldset>
      <StyledWrapper>
        {renderOptions({ options, name: 'Checkbox Group' })}
      </StyledWrapper>
    </fieldset>
  );
}

export function CondensedCheckboxGroup({ label, ...props }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const renderOptions = ({ options: opts, name }) => {
    return opts.map((option) => {
      return (
        <Checkbox
          label={option.label}
          value={option.value}
          name={name}
          key={option.value}
          checked={selectedOptions.includes(option.value)}
          onChange={() =>
            setSelectedOptions((prev) =>
              prev.includes(option.value)
                ? prev.filter((val) => val !== option.value)
                : [...prev, option.value]
            )
          }
          className="Sample-stacked-checkbox"
          size="small"
          isGroupItem
        />
      );
    });
  };

  return (
    <fieldset {...props}>
      <StyledWrapper>
        {label && <StyledCheckboxesLabel>{label}</StyledCheckboxesLabel>}
        {renderOptions({ options: condensedOptions, name: 'Checkbox Group' })}
      </StyledWrapper>
    </fieldset>
  );
}
