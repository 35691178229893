import * as Sentry from '@sentry/react';

type ExceptionContext = Parameters<(typeof Sentry)['captureException']>['1'];

type MessageContext = Exclude<
  Parameters<(typeof Sentry)['captureMessage']>['1'],
  Sentry.Severity | Sentry.SeverityLevel
>;

export const monitoringMessageHelper = (
  message: string,
  context?: MessageContext
) => {
  Sentry.captureMessage(message, {
    level: 'warning',
    ...context,
  });
};

export const monitoringExceptionHelper = (
  error: any,
  context?: ExceptionContext
) => {
  Sentry.captureException(error, context);
};
