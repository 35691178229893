import styled from '@emotion/styled';
import { CardBasic } from 'components/Card/Card';
import { gutters } from 'app/spacing';

// Half width, and anticipates to include a toolbar and a table

export const TableCard = styled(CardBasic)`
  height: ${({ tableHeight }) =>
    tableHeight ? tableHeight + 36 : 330}px; // 5 rows + header
  width: 100%;
  padding: ${gutters.standard};
  position: relative;
  display: flex;
  flex-direction: column;
  > div:last-of-type {
    flex: 1;
    margin: 0 -15px -15px -15px;
  }
`;
