import * as Action from './definitions/action';
import * as Chart from './definitions/chart';
import * as Table from './definitions/table';
import * as File from './definitions/file';
import * as Messaging from './definitions/messaging';
import * as Form from './definitions/form';
import * as Text from './definitions/text';
import * as User from './definitions/user';
import * as Nav from './definitions/nav';
import * as Custom from './definitions/custom';

import { createIconSet } from './util';

export const actionIcons = createIconSet(Action);
export const chartIcons = createIconSet(Chart);
export const tableIcons = createIconSet(Table);
export const fileIcons = createIconSet(File);
export const messagingIcons = createIconSet(Messaging);
export const formIcons = createIconSet(Form);
export const textIcons = createIconSet(Text);
export const userIcons = createIconSet(User);
export const navIcons = createIconSet(Nav);
export const customIcons = createIconSet(Custom);

export const allIcons = {
  ...actionIcons,
  ...chartIcons,
  ...tableIcons,
  ...fileIcons,
  ...messagingIcons,
  ...formIcons,
  ...textIcons,
  ...userIcons,
  ...navIcons,
  ...customIcons,
};

type ActionValidIcons = `${Action.SemanticCategoryName}-${Action.ValidIcons}`;
type ChartValidIcons = `${Chart.SemanticCategoryName}-${Chart.ValidIcons}`;
type TableValidIcons = `${Table.SemanticCategoryName}-${Table.ValidIcons}`;
type FileValidIcons = `${File.SemanticCategoryName}-${File.ValidIcons}`;
type MessagingValidIcons =
  `${Messaging.SemanticCategoryName}-${Messaging.ValidIcons}`;
type FormValidIcons = `${Form.SemanticCategoryName}-${Form.ValidIcons}`;
type TextValidIcons = `${Text.SemanticCategoryName}-${Text.ValidIcons}`;
type UserValidIcons = `${User.SemanticCategoryName}-${User.ValidIcons}`;
type NavValidIcons = `${Nav.SemanticCategoryName}-${Nav.ValidIcons}`;
type CustomValidIcons = `${Custom.SemanticCategoryName}-${Custom.ValidIcons}`;

export type ValidIcons =
  | ActionValidIcons
  | ChartValidIcons
  | TableValidIcons
  | FileValidIcons
  | MessagingValidIcons
  | FormValidIcons
  | TextValidIcons
  | UserValidIcons
  | NavValidIcons
  | CustomValidIcons;
