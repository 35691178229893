import React from 'react';
import * as PropTypes from 'prop-types';

import CardToolbar, { CardToolbarTitle } from 'components/Layout/CardToolbar';

const TableHeader = ({ title }) => (
  <CardToolbar>
    <CardToolbarTitle>{title}</CardToolbarTitle>
  </CardToolbar>
);

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TableHeader;
