import styled from '@emotion/styled';
import { colorsText, grayScale, dropdownColors } from 'app/colors';
import { fontSizes, TextSpan } from 'app/typography';

export const StyledLabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledLabel = styled(TextSpan)`
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
`;

// Customization of https://react-select.com/styles
export const reactSelectStyles = {
  container: (provided, { selectProps: { maxWidth = 200 } }) => ({
    ...provided,
    display: 'flex',
    maxWidth,
  }),

  control: (provided, state) => {
    return {
      ...provided,
      border: 0,
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? grayScale.light : grayScale.white,
      minHeight: '36px',
      maxWidth: '100%',
      '> :first-child': {
        flexDirection: 'column',
        alignItems: 'stretch',
        paddingBottom: 6, // Accounts for 10px between text and underline
        paddingTop: 6, // Accounts for 10px between text and underline
        ...(state.isFocused && {
          backgroundImage: `url(/images/inputUnderline--solid.svg) !important`,
          backgroundPosition: 'left bottom !important',
          backgroundRepeat: 'repeat-x !important',
        }),
        '> input': {
          // This is a dummy input placed when isSearchable is false
          display: 'none',
        },
      },
    };
  },

  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    paddingRight: 0,
  }),

  menu: (provided, state) => ({
    ...provided,
    zIndex: 2, // Above e.g. sticky table headers
    marginTop: -2, // Align directly under underline
    'box-shadow': '0 18px 26px rgba(0, 0, 0, 0.03)',
    border: `1px solid ${grayScale.medium}`,
    width: null,
    minWidth: '100%',
    maxWidth: state.selectProps.mobile ? '263px' : '395px',
  }),

  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: '36rem',
    // TODO find a way to use our typical scrollbar styles here,
    // as they are currently wrapped in emotion's css``.
    '::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '::-webkit-scrollbar-thumb': {
      width: 2.4,
      borderRadius: '8px',
      backgroundColor: grayScale.mediumDark,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  }),

  option: (provided, state) => {
    let stateStyle = {
      color: colorsText.dark,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: dropdownColors.hover,
      },
    };
    if (state.isSelected) {
      stateStyle = {
        backgroundColor: 'unset',
        color: colorsText.medium,
      };
    }
    return {
      ...provided,
      ...stateStyle,
      padding: '0 12px',
      fontSize: fontSizes.text,
      display: 'block',
      height: '32px',
      lineHeight: '32px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    };
  },
  singleValue: (provided) => ({
    ...provided,
    position: null,
    transform: null,
    top: null,
    maxWidth: '100%',
    margin: 0,
    '> :first-child': {
      display: 'inline-block',
      maxWidth: '100%',
      textIndent: '0 !important',
    },
  }),
  input: () => ({
    '> :first-child': {
      display: 'inline-flex !important',
      position: 'absolute',
      width: '100%',
      top: 6,
    },
    // need to specify input in order to get font size correct
    // also need !important to override inline styles that are applied
    input: {
      fontSize: `${fontSizes.subheader} !important`,
      fontWeight: '400',
      width: '0 !important',
      flexGrow: 1,
      paddingBottom: '2px !important',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: grayScale.medium,
    ':hover': {
      color: grayScale.medium,
    },
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 2px 10px',
  }),
};
