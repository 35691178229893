import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/Kizen/Input';
import { getDataQAForInput } from 'components/Inputs/helpers';
import {
  StyledFormHeader,
  StyledFormRow,
  StyledFormInput,
  StyledFormActions,
  StyledFormButton,
} from './styles';
import { useFlashTransition } from '../../hooks/useFlashState';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';

export const UserInfo = ({
  title,
  values,
  user = null,
  onChange,
  onSubmit,
  errorData,
}) => {
  const { t } = useTranslation();
  // Old data can have users without first name and last name so in case of existing user the only
  // requirment for disabling the submit button is user.name
  const disableSubmit = user
    ? !values.name
    : !values.name ||
      !values.first_name ||
      !values.last_name ||
      !values.email ||
      !values.password;

  const [messageBusinessName, showMessageBusinessName, flashBusinessName] =
    useFlashTransition();
  const validateBusiness = {
    message: messageBusinessName,
    showMessage: showMessageBusinessName,
  };

  useEffect(() => {
    if (errorData?.detail) {
      if (errorData?.code === 'restricted_business_name') {
        flashBusinessName(errorData?.detail);
      }
      //The place for all next error codes to match the field.
    }
    if (errorData?.name) {
      flashBusinessName(errorData.name);
    }
  }, [errorData, flashBusinessName]);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'name' },
      {
        id: 'first_name',
      },
      { id: 'last_name' },
      { id: 'email' },
      { id: 'password' },
    ],
    {},
    () => true
  );

  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <>
        <StyledFormHeader type="header">{title}</StyledFormHeader>
        <StyledFormInput
          {...getKeyListenersProps('name')}
          className="first-row"
          placeholder={t('Enter Business Name')}
          label={t('Business Name')}
          value={values.name}
          onChange={(value) => onChange('name', value)}
          required
          validate={validateBusiness}
          {...getDataQAForInput('business-name')}
        />
        <StyledFormRow>
          <StyledFormInput
            {...getKeyListenersProps('first_name')}
            placeholder={t('Enter First Name')}
            label={t('First Name')}
            value={values.first_name}
            onChange={(value) => onChange('first_name', value)}
            required={!user}
            disabled={!!user}
            {...getDataQAForInput('first-name')}
          />
          <StyledFormInput
            {...getKeyListenersProps('last_name')}
            placeholder={t('Enter Last Name')}
            label={t('Last Name')}
            value={values.last_name}
            onChange={(value) => onChange('last_name', value)}
            required={!user}
            disabled={!!user}
            {...getDataQAForInput('last-name')}
          />
        </StyledFormRow>
        <StyledFormInput
          {...getKeyListenersProps('email')}
          placeholder={t('Enter Email')}
          label={t('Email Address')}
          value={values.email}
          onChange={(value) => onChange('email', value)}
          type={Input.INPUT_TYPES.EMAIL}
          required
          disabled={!!user}
          {...getDataQAForInput('email', 'email')}
        />

        <StyledFormInput
          {...getKeyListenersProps('password')}
          value={user ? '********' : values.password} // if we have existing user show hidden characters instead of real password
          onChange={(value) => onChange('password', value)}
          required={!user}
          label={t('Password')}
          placeholder={t('Enter Password')}
          type={Input.INPUT_TYPES.PASSWORD}
          disabled={!!user}
          {...getDataQAForInput('password', 'password')}
        />
        <StyledFormActions>
          <StyledFormButton onClick={onSubmit} disabled={disableSubmit}>
            {t('Next')}
          </StyledFormButton>
        </StyledFormActions>
      </>
    </KeyBoardContext.Provider>
  );
};

export default UserInfo;
