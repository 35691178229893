import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { grayScale } from 'app/colors';
import styled from '@emotion/styled';
import css from '@emotion/css';

const Wrapper = styled.div`
  ${({ isLoading }: { isLoading: boolean }) =>
    isLoading
      ? css`
          opacity: 0.5;
          position: relative;
        `
      : ``}
`;

const Blocker = styled.div`
  ${({ isLoading }: { isLoading: boolean }) =>
    isLoading
      ? css`
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          text-align: center;
          padding-top: 10%;
        `
      : ``}
`;

export const useFieldsLoader = (loadingCategory: string[], category: any) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const lastChosenCategory = useRef(category);

  useEffect(() => {
    let timeout: any = null;

    if (loadingCategory.includes(category?.value)) {
      timeout = setTimeout(() => setShowSpinner(true), 2000);
    } else {
      setShowSpinner(false);
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = null;
    }

    return () => {
      setShowSpinner(false);
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = null;
    };
  }, [loadingCategory, category]);

  const categoryToShow = useMemo(
    () =>
      loadingCategory.includes(category?.value)
        ? lastChosenCategory.current
        : category,
    [category, loadingCategory]
  );

  const setLastChosenCategory = (newCategory: any) =>
    (lastChosenCategory.current = newCategory);

  return { showSpinner, categoryToShow, setLastChosenCategory };
};

type FieldsLoaderProps = {
  isLoading: boolean;
  showSpinner: boolean;
};

export const FieldsLoader = (props: PropsWithChildren<FieldsLoaderProps>) => {
  const { isLoading, children, showSpinner } = props;

  return (
    <Wrapper isLoading={isLoading}>
      {children}
      <Blocker isLoading={isLoading}>
        <ClipLoader size={50} color={grayScale.dark} loading={showSpinner} />
      </Blocker>
    </Wrapper>
  );
};
