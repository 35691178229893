import React, { forwardRef, useCallback, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FIELD_TYPES } from 'utility/constants';
import { getNonInputControlProps, hasInputControl } from '../InputControl';
import { applyRef, selectedValueProp } from '../props';
import Select from '../Select';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const UnderlineYesNoMaybe = forwardRef((props, ref) => {
  const { className, fieldId } = props;
  const selectProps = getNonInputControlProps(props);
  const selectRef = useRef();
  const { assignFieldHandle } = useKeyBoardContext();

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    props
  );

  const mergeRef = useCallback(
    (el) => {
      applyRef(selectRef, el);
      applyRef(ref, el);
    },
    [ref]
  );
  assignFieldHandle(fieldId, {
    customFocus: () => {
      selectRef.current?.select?.focus();
      setMenuIsOpen(false);
      return selectRef.current?.select;
    },
    customEscape: () => {
      setMenuIsOpen(false);
    },
    customUp: () => {
      setMenuIsOpen(true);
    },
    customDown: () => {
      setMenuIsOpen(true);
    },
  });

  const { t } = useTranslation();

  return (
    <Select
      ref={mergeRef}
      {...selectProps}
      {...customSelectMenuProps}
      className={!hasInputControl(props) && className}
      isSearchable={false}
      placeholder={FIELD_TYPES.YesNoMaybe.localizedPlaceholder(t)}
      variant="underline"
      error={false}
    />
  );
});

UnderlineYesNoMaybe.propTypes = {
  value: selectedValueProp,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

UnderlineYesNoMaybe.defaultProps = {
  value: null,
  onChange: null,
  error: null,
  disabled: null,
};

export default UnderlineYesNoMaybe;
