import styled from '@emotion/styled';
import { StagedField } from 'components/Fields/Form';
import { CHECKBOX_COUNT_BREAKPOINT } from 'components/Inputs/CheckboxGroup/settings';
import { KeyBoardContext as __KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { memo, useCallback, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { FIELD_TYPES } from 'utility/constants';
import FieldService from 'services/FieldService';

const KeyBoardContext = __KeyBoardContext as any;

const Wrapper = styled.div`
  gap: 0 15px;

  .span2 {
    grid-column: span 2;
  }

  > :empty {
    display: none;
  }
`;

export const Form = memo((props: any) => {
  const {
    fields,
    object,
    onChange,
    validationProps,
    popoverMenus,
    suppressAutoScroll = false,
    handleFieldBlur,
    errors,
    adornments,
  } = props;
  const filteredFields = useMemo(
    () => fields.filter((field: any) => !field.isHidden),
    [fields]
  );

  const { onFocus, onBlur, assignFieldHandle } = useKeyListeners(
    filteredFields,
    object,
    undefined,
    suppressAutoScroll
  );

  const handleBlur = useCallback(
    (...args: any) => {
      onBlur(...args);
      handleFieldBlur?.();
    },
    [onBlur, handleFieldBlur]
  );

  const handleChange = useCallback(
    (field: any) =>
      (...args: any) => {
        onChange(...args);
        // Some fields don't have a traditional "blur", so we want to trigger it on
        // change in these situations
        if (
          [
            FIELD_TYPES.Date.type,
            FIELD_TYPES.DateTime.type,
            FIELD_TYPES.Checkbox.type,
            FIELD_TYPES.TeamSelector.type,
            FIELD_TYPES.YesNo.type,
            FIELD_TYPES.YesNoMaybe.type,
            FIELD_TYPES.Files.type,
            FIELD_TYPES.Status.type,
            FIELD_TYPES.Radio.type,
            FIELD_TYPES.Dropdown.type,
            FieldService.getAllowMultiple(field)
              ? ''
              : FIELD_TYPES.Relationship.type,
            // Checkboxes break to a dropdown with >= CHECKBOX_COUNT_BREAKPOINT items, and we only
            // treat it as special if it's not a dropdown
            field.options?.length < CHECKBOX_COUNT_BREAKPOINT
              ? FIELD_TYPES.Checkboxes.type
              : '',
          ].includes(field.fieldType)
        ) {
          handleFieldBlur?.();
        }
      },
    [onChange, handleFieldBlur]
  );

  const getValidationProps = useCallback(
    (...args: any) => {
      if (errors?.length) {
        return {};
      }

      return validationProps?.(...args) ?? {};
    },
    [validationProps, errors]
  );

  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <Wrapper className="grid @sm-tile/Tile:grid-cols-[repeat(auto-fill,minmax(180px,1fr))] grid-cols-[repeat(auto-fill,minmax(calc(40%-10px),1fr))]">
        {filteredFields.map((field: any) => (
          <StagedField
            key={field.id}
            field={field}
            {...props}
            onChange={handleChange(field)}
            onFocus={onFocus(field.id)}
            onBlur={handleBlur}
            validationProps={getValidationProps}
            portal={popoverMenus}
            errors={errors ?? []}
            validateSettings={props.validateSettings ?? {}}
            fieldsAdditionalProps={props.fieldsAdditionalProps ?? {}}
            adornments={adornments}
          />
        ))}
      </Wrapper>
    </KeyBoardContext.Provider>
  );
}, shallowEqual);
