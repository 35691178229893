import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@kizen/kds/Button';
import * as PropTypes from 'prop-types';

import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import { CardBasic } from 'components/Card/Card';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import CustomFieldsBuilder from 'components/Builders/CustomFieldsBuilder';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import useExportFieldMetadata from 'hooks/useExportFieldMetadata';

import { useTranslation } from 'react-i18next';

const BigCard = styled(CardBasic)`
  padding-top: ${gutters.spacing(3)}px;
  margin-bottom: ${gutters.spacing(4)}px;
  display: flex;
  flex-direction: column;
  > div:last-of-type {
    flex: 1;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${gutters.spacing(4)}px;
  margin-bottom: ${gutters.spacing(2, -4)}px;
  h3 {
    margin-top: -1px; // Design nudge
    line-height: 1; // Sidestep bootstrap
  }
`;

const BigTitle = styled.div`
  align-items: flex-start;
  h3 {
    margin-top: ${gutters.spacing(1, -1)}px;
  }
  button {
    margin: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RightHeader = styled.div`
  margin-right: 20px;
  margin-top: -3px;
  display: flex;
  justify-content: start;
`;

const CategoriesLayout = ({
  categories,
  model,
  handleGetCategories,
  field,
  onOpenEditFieldOptions,
  onCloseEditFieldOptions,
  ...props
}) => {
  const { t } = useTranslation();
  const { handleFieldExport } = useExportFieldMetadata({ model });

  return (
    <BigCard as={ContentWidth} {...props}>
      <Header>
        <BigTitle as={TitleWrapper}>
          <TextEllipsisWithTooltip as="h3" type="subheader">
            {`${model.description}`}
          </TextEllipsisWithTooltip>
          &nbsp;
          <KizenTypography as="h3" type="subheader">
            {t(`Fields`)}
          </KizenTypography>
        </BigTitle>
        <RightHeader>
          <Button
            className="pb-[10px]"
            color="secondary"
            onClick={handleFieldExport}
            variant="text"
          >
            {t('Export Field Metadata')}
          </Button>
        </RightHeader>
      </Header>
      <CustomFieldsBuilder
        model={model}
        field={field}
        rawCategories={categories}
        updateCategories={handleGetCategories}
        onOpenEditFieldOptions={onOpenEditFieldOptions}
        onCloseEditFieldOptions={onCloseEditFieldOptions}
      />
    </BigCard>
  );
};

CategoriesLayout.propTypes = {
  categories: PropTypes.array.isRequired,
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  handleGetCategories: PropTypes.func.isRequired,
  field: PropTypes.object,
  onOpenEditFieldOptions: PropTypes.func,
  onCloseEditFieldOptions: PropTypes.func,
};

export default CategoriesLayout;
