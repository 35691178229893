import React from 'react';
import * as PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { grayScale } from 'app/colors';

const Loader = ({
  loading,
  children,
  className,
  size,
  paddingTop = '10%',
  ...props
}) => {
  return loading ? (
    <div
      data-qa="switch-view-loading"
      style={{ textAlign: '-webkit-center', paddingTop: paddingTop }}
      className={className}
    >
      <ClipLoader
        size={size}
        color={grayScale.dark}
        loading={loading}
        {...props}
      />
    </div>
  ) : (
    <>{children}</>
  );
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
};

Loader.defaultProps = {
  className: '',
  size: 50,
};

export default Loader;
