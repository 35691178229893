import { grayScale } from 'app/colors';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { useTooltip } from 'components/Kizen/Tooltip';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';
import { useTranslation } from 'react-i18next';
import { AddButton } from './styles';

export const ScheduleBroadcastCalendarButton = ({ view, show, onClick }) => {
  const { t } = useTranslation();
  const [tooltipProps, tooltip] = useTooltip({
    label: t('Schedule Broadcast'),
  });
  return show ? (
    <AddButton view={view} {...tooltipProps} onClick={onClick}>
      {tooltip}
      <IconButton
        sizing="dense"
        color={grayScale.mediumDark}
        popperConfig={{
          modifiers: {
            offset: {
              offset: view === CALENDAR_VIEW_OPTIONS.day ? '0, 6' : '0, 4',
            },
            preventOverflow: { enabled: false },
          },
        }}
      >
        <IconSizing size="16px">
          <Icon icon="plus" />
        </IconSizing>
      </IconButton>
    </AddButton>
  ) : null;
};
