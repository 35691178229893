import { DISPLAY_VALUE_RESPONSES } from 'components/Wizards/Dashlet/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Bar from '..';
import { BarDashletAdapterWrapper } from './styles';

export const BarDashletAdapter = ({
  data,
  dashlet,
  currencySymbol,
  ...rest
}) => {
  const { t } = useTranslation();
  const chartData = useMemo(() => {
    return (
      data?.data.map((d) => {
        return {
          category: d.lead_source,
          type: d.type,
          value: d.value,
        };
      }) ?? []
    );
  }, [data]);

  const isValue = dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE;

  return (
    <BarDashletAdapterWrapper>
      <Bar
        data={chartData}
        yAxisTitle={
          isValue ? `${t('Value')} (${currencySymbol})` : t('# Leads Added')
        }
        currencySymbol={currencySymbol}
        dashlet={dashlet}
        currencyMode={isValue}
        {...rest}
      />
    </BarDashletAdapterWrapper>
  );
};
