import {
  AbsoluteRef,
  AbsoluteString,
  EndStep,
  FilterDef,
  Path,
  Reference,
  ReferenceList,
  RelativeRef,
  RelativeString,
  StepRef,
  TransformDef,
  UrlConfig,
  XF,
} from './types';

const SupportedOptionFilters: XF[] = [
  'xf_eq',
  'xf_not_eq',
  'xf_invert',
  'xf_bool',
  'xf_or',
];

export const isAbsoluteRef = (x: any): x is AbsoluteRef => {
  return isAbsoluteString(x) || (Array.isArray(x) && isAbsoluteString(x[0]));
};

export const isAbsoluteString = (x: any): x is AbsoluteString => {
  return typeof x === 'string' && x.startsWith('@/');
};

/**
 * Validates if the parameter is a ReferenceList type. ReferenceLists can supply a
 * default value as the last element of the array, so this checks that all but the last
 * element is a Reference type in array format (no string values). `'$step_id'`,
 * `'@/absolute/path'`, `'@relative_var'` references would need to be wrapped in arrays
 * to be used in a ReferenceList: `['$step_id']`, `['@/absolute/path']`, `['@relative_var']`.
 */
export const isReferenceList = (x: any): x is ReferenceList<any> => {
  return (
    Array.isArray(x) &&
    x.length > 1 &&
    x.slice(0, -1).every((y) => typeof y !== 'string' && isReference(y))
  );
};

export const isEndStep = (step: any): step is EndStep => {
  return step === '__END__';
};

export const isOptionFilter = (x: any): x is FilterDef => {
  return Array.isArray(x) && SupportedOptionFilters.includes(x[0]);
};

export const isOptionFilterList = (x: any): x is FilterDef[] => {
  return Array.isArray(x) && x.every(isOptionFilter);
};

// used to differentiate between a Path[]
export const isPath = (x: any): x is Path => {
  return Array.isArray(x) && !Array.isArray(x[0]);
};

export const isRef = (x: any): x is Reference | ReferenceList<any> => {
  return isReference(x) || isReferenceList(x);
};

/**
 * Validates if the parameter is a Reference type:
 * ```
 *   - '@/absolute_path'
 *   - '@relative_var'
 *   - '$step_id'
 *   - ['@/absolute/path', '@relative_var']
 *   - ['@relative/path', [$step_value, ['path', 'to', 'prop']]]
 *   - ['$step_id', [['lookup', 'one'], ['default']]]
 * ```
 */
export const isReference = (x: any): x is Reference => {
  return isAbsoluteRef(x) || isRelativeRef(x) || isStepRef(x);
};

export const isRelativeRef = (x: any): x is RelativeRef => {
  return isRelativeString(x) || (Array.isArray(x) && isRelativeString(x[0]));
};

export const isRelativeString = (x: any): x is RelativeString => {
  return typeof x == 'string' && x.startsWith('@') && x[1] !== '/';
};

export const isStepRef = (x: any): x is StepRef => {
  return (
    (typeof x === 'string' && x.startsWith('$')) ||
    (Array.isArray(x) && typeof x[0] === 'string' && x[0].startsWith('$'))
  );
};

export const isTransform = (x: any): x is TransformDef => {
  return Array.isArray(x) && typeof x[0] === 'string' && x[0].startsWith('xf_');
};

export const isUrlConfig = (x: any): x is UrlConfig => {
  return (
    x !== null &&
    typeof x === 'object' &&
    typeof x.url === 'string' &&
    typeof x.method === 'string'
  );
};

export const isUrlConfigArray = (x: any): x is UrlConfig[] => {
  return Array.isArray(x) && x.every(isUrlConfig);
};
