export default {
  // sunday: (t) => t('Su'),
  sunday: 'Su',
  // monday: (t) => t('Mo'),
  monday: 'Mo',
  // tuesday: (t) => t('Tu'),
  tuesday: 'Tu',
  // wednesday: (t) => t('We'),
  wednesday: 'We',
  // thursday: (t) => t('Th'),
  thursday: 'Th',
  // friday: (t) => t('Fr'),
  friday: 'Fr',
  // saturday: (t) => t('Sa'),
  saturday: 'Sa',
  ok: (t) => t('OK'),
  today: (t) => t('Today'),
  yesterday: (t) => t('Yesterday'),
  last7Days: (t) => t('Last 7 Days'),
  hours: (t) => t('Hours'),
  minutes: (t) => t('Minutes'),
  seconds: (t) => t('Seconds'),
  thisMonth: (t) => t('This Month'),
  last30Days: (t) => t('Last 30 Days'),
  thisYear: (t) => t('This Year'),
  allTime: (t) => t('All Time'),
};
