import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getStepConfig } from 'pages/AutomationEngine/steps';
import KizenTypography from 'app/kizentypo';
import { colorsSecondary, colorsButton } from 'app/colors';
import DraggableStepCard from './DraggableStepCard';
import TriggerCard from './TriggerCard';
import StepCard from './StepCard';
import MovingPlaceholder from './MovingPlaceholder';
import * as cardTypes from './type';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import styled from '@emotion/styled';
import { truncationPredicates, useTooltip } from 'components/Kizen/Tooltip';
import { useSelector } from 'react-redux';
import { canViewAutomationExecutionInsights } from 'store/authentication/selectors';

const NBSP = '\u00A0';
const NBSPDeleimiter = `${NBSP}| `;
const andDelimiter = ' & ';

const StatsWrapper = styled.div`
  text-align: center;
`;

const BoxWrapper = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const defaultStats = {
  action: { numberCompleted: 0, numberFailed: 0 },
  delay: { numberCompleted: 0, numberPending: 0 },
  trigger: { numberCompleted: 0 },
  condition: { numberCompleted: 0, numberFailed: 0, numberYes: 0, numberNo: 0 },
  goal: { numberCompleted: 0, numberPending: 0, numberMet: 0, numberNotMet: 0 },
  default: {},
  test: {
    numberCompleted: 9999,
    numberPending: 9999,
    numberPaused: 9999,
    numberFailed: 9999,
    numberYes: 9999,
    numberNo: 9999,
    numberMet: 9999,
    numberNotMet: 9999,
  },
};

const useStats = (statsProp, stepType) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const stats = statsProp || defaultStats[stepType || 'default'];
    const firstLine = [
      'numberCompleted' in stats && {
        type: 'completed',
        title:
          stepType === 'trigger'
            ? t('{{numberCompleted}} Triggered', stats)
            : t('{{numberCompleted}} Completed', stats),
      },
      'numberPending' in stats && {
        type: 'pending',
        title: t('{{numberPending}} Pending', stats),
      },
      'numberPaused' in stats && {
        type: 'paused',
        title: t('{{numberPaused}} Paused', stats),
      },
      stats.numberFailed && {
        type: 'failed',
        title: t('{{numberFailed}} Failed', stats),
      },
    ].filter(Boolean);

    const lines = [firstLine];

    let secondLine;
    switch (stepType) {
      case 'goal':
        secondLine = [
          'numberMet' in stats && {
            type: 'met',
            title: t('{{numberMet}} Met', stats),
            delimiter: andDelimiter,
          },
          'numberNotMet' in stats && {
            type: 'notMet',
            title: t('{{numberNotMet}} Not Met', stats),
            delimiter: andDelimiter,
          },
        ].filter(Boolean);
        break;
      case 'condition':
        secondLine = [
          'numberYes' in stats && {
            type: 'yes',
            title: t('{{numberYes}} Yes', stats),
            delimiter: andDelimiter,
          },
          'numberNo' in stats && {
            type: 'no',
            title: t('{{numberNo}} No', stats),
            delimiter: andDelimiter,
          },
        ].filter(Boolean);
        break;
      default:
        break;
    }

    if (secondLine) {
      lines.push(secondLine);
    }

    return lines;
  }, [statsProp, stepType, t]);
};

const overflowXOrY = (el) =>
  truncationPredicates.x(el) || truncationPredicates.y(el);

const BoxEllipsisWithTooltip = ({ children }) => {
  const [tooltipProps, tooltip] = useTooltip({}, overflowXOrY);
  return (
    <BoxWrapper {...tooltipProps}>
      {children}
      {tooltip}
    </BoxWrapper>
  );
};

const WrapOrTruncate = ({ children, wrap }) => {
  const Component = wrap ? BoxEllipsisWithTooltip : TextEllipsisWithTooltip;
  return <Component size="micro">{children}</Component>;
};

const Stats = ({ stats, stepType }) => {
  const statsLines = useStats(stats, stepType);
  return (
    <StatsWrapper>
      {statsLines.map((line, lineIndex) => (
        <WrapOrTruncate wrap={lineIndex === 0} key={lineIndex}>
          {line.map(
            ({ type, title, delimiter = NBSPDeleimiter }, spanIndex) => (
              <KizenTypography
                as="span"
                key={type}
                size="small"
                weight="bold"
                color={type === 'failed' && colorsSecondary.red.dark}
              >
                {lineIndex ? title : title.replace(/\s+/g, NBSP)}
                {spanIndex !== line.length - 1 ? delimiter : null}
              </KizenTypography>
            )
          )}
        </WrapOrTruncate>
      ))}
    </StatsWrapper>
  );
};

export default function ConfiguredStepCard({
  type,
  step,
  showStats = false,
  disableMenu = false,
  scale = null,
  onStart = null,
  onStop = null,
  showMovingPlaceholder = false,
  ...others
}) {
  const { t } = useTranslation();
  const stepType = step.type === 'goal' ? step.details.type : step.type;
  const { stats } = step;
  const stepConfig = getStepConfig(stepType, {
    isTrigger: type === 'automationTrigger',
  });

  const CardComponent =
    (showMovingPlaceholder && MovingPlaceholder) ||
    cardTypes[stepConfig.CardComponent] ||
    (type === 'automationTrigger' && TriggerCard) ||
    (stepConfig.movable && DraggableStepCard) ||
    StepCard;

  // prepare stats line
  const statsComponent = useMemo(() => {
    if (stepConfig?.goalType) {
      return <Stats stats={stats} stepType="goal" />;
    }
    if (stepConfig?.type === 'delay' || stepConfig?.type === 'condition') {
      return <Stats stats={stats} stepType={stepConfig.type} />;
    }
    if (type === 'automationStep') {
      return <Stats stats={stats} stepType="action" />;
    }
    if (type === 'automationTrigger' && stepConfig.type !== 'add_trigger') {
      return <Stats stats={stats} stepType="trigger" />;
    }
    // default card stats value
    return ``;
  }, [type, stepConfig, stats]);

  const showStepId = useSelector(canViewAutomationExecutionInsights);

  const title =
    typeof stepConfig.title === 'function'
      ? stepConfig.title(t)
      : stepConfig.title;

  return (
    <CardComponent
      title={title}
      color={step.hasError ? colorsButton.red.hover : stepConfig.color}
      borderColor={step.hasError ? colorsButton.red.hover : null}
      panel={showStats ? statsComponent : null}
      stepId={showStats && showStepId && step.id}
      menuOptions={[
        stepConfig.creatable && { label: t('Edit'), value: 'edit' },
        stepConfig.deletable && { label: t('Delete'), value: 'delete' },
      ].filter(Boolean)}
      disableMenu={
        disableMenu || (!stepConfig.creatable && !stepConfig.deletable)
      }
      {...(stepConfig.CardComponent && { type, step })}
      {...(stepConfig.movable && {
        scale,
        onStart,
        onStop,
      })}
      {...others}
    />
  );
}
