import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSizing } from '__components/Layout/PageContentWidth';
import { PrimaryObjectUploadContext } from '../../context';
import {
  StyledCard,
  StyledLoader,
  StyledStepHeader,
} from '__pages/SmartConnectors/SmartConnector/steps/styles';
import { Settings } from './Settings';
import { PRIMARY_OBJECT_UPLOAD_STEPS } from '__pages/SmartConnectors/constants';

export const PreviewStep = () => {
  const { t } = useTranslation();
  const { setIsValid, loading } = useContext(PrimaryObjectUploadContext);

  const isStepValid = true;

  useEffect(() => {
    setIsValid(isStepValid);
  }, [setIsValid, isStepValid]);

  return (
    <PageSizing>
      <StyledCard data-qa={`${PRIMARY_OBJECT_UPLOAD_STEPS.preview}-step`}>
        <StyledStepHeader type="subheader">
          {t('Finalize Settings & Preview')}
        </StyledStepHeader>
        <StyledLoader loading={loading}>
          <Settings />
        </StyledLoader>
      </StyledCard>
    </PageSizing>
  );
};
