export const CHART_TYPES = {
  FIELD_METRICS: 'field_metrics',
  TABLE_OF_RECORDS: 'table_of_records',
  SALES: 'sales_projection',
  PIVOT_TABLE: 'pivot_table',
};

export const AREA_RESPONSES = {
  METRICS: 'field_metrics',
  TABLE: 'table_of_records',
  SALES: 'sales_projection',
  PIVOT_TABLE: 'pivot_table',
};

export const chartMap = {
  [CHART_TYPES.FIELD_METRICS]: {
    icon: 'database-light',
    label: (t) => t('Field Metrics'),
    value: AREA_RESPONSES.METRICS,
    size: 'lg',
    order: 0,
    enabled: true,
  },
  [CHART_TYPES.TABLE_OF_RECORDS]: {
    icon: 'table',
    label: (t) => t('Table of Records'),
    value: AREA_RESPONSES.TABLE,
    size: 'lg',
    order: 1,
    enabled: true,
  },
  [CHART_TYPES.SALES]: {
    icon: 'circle-dollar',
    label: (t) => t('Sales'),
    value: AREA_RESPONSES.SALES,
    size: 'lg',
    order: 2,
    enabled: true,
  },
  [CHART_TYPES.PIVOT_TABLE]: {
    icon: 'pivot-table',
    label: (t) => t('Pivot Table'),
    value: AREA_RESPONSES.PIVOT_TABLE,
    size: 'lg',
    order: 3,
    enabled: true,
  },
};

export const VALUE_RESPONSES = {
  SUM: 'fields_value_sum',
  AVERAGE: 'fields_value_average',
  BREAKDOWN: 'fields_range_breakdown',
};

export const VALUE_METRICS = {
  COUNT: 'record_count',
  NUMBER_FIELD: 'number_field',
};

export const SALES_REPORT_TYPES = {
  PROJECTED_RECORDS_WON_OVER_TIME: 'projected_records_won_over_time',
};
