import { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters, breakpoints, layers } from 'app/spacing';
import { grayScale, colorsSecondary, colorsPrimary } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import FixedWidth from 'ts-components/FixedWidth';
import IconButton from 'components/Kizen/IconButton';
import { IconSizing } from 'components/Kizen/Icon';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import PercentageBar from 'components/PercentageBar';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import Select from 'components/Inputs/Select';
import BasicModal from 'components/Modals/presets/BasicModal';
import useModal from 'components/Modals/useModal';
import KizenTypography from 'app/kizentypo';
import useField from 'hooks/useField';
import {
  LEAD_STATUS,
  calcPercentageToDisplay,
  stagesSelectPopperConfig,
} from '../helpers';
import { useTranslation } from 'react-i18next';
import { moneyNumberFormatter } from 'utility/numbers';
import { Button } from '@kizen/kds/Button';
import { Typography } from '@kizen/kds/Typography';

const desktopSizes = {
  gutters: gutters.spacing(4),
  row: {
    leftColumnWidth: 220,
    rightColumnWidth: 120,
  },
  objectPrice: {
    objectWidth: 100,
    priceWidth: 80,
  },
};

const mobileSizes = {
  gutters: gutters.spacing(3),
  row: {
    leftColumnWidth: 160,
    rightColumnWidth: 120,
  },
  objectPrice: {
    objectWidth: 100,
    priceWidth: 80,
  },
};

const Details = styled.div`
  margin-right: ${mobileSizes.gutters -
  gutters.spacing(2)}px; // 10px space for fat scroll bar
  margin-left: ${mobileSizes.gutters}px;

  @media (min-width: ${breakpoints.md}px) {
    margin-right: ${desktopSizes.gutters -
    gutters.spacing(2)}px; // 10px space for fat scroll bar
    margin-left: ${desktopSizes.gutters}px;
    &:first-of-type {
      margin-top: ${gutters.spacing(1)}px;
    }
  }
  &:not(:last-of-type) {
    margin-bottom: ${gutters.spacing(3, -3)}px;
    border-bottom: 1px solid ${grayScale.mediumLight};
  }
  padding-bottom: ${gutters.spacing(3, -3)}px;
`;

const DetailsRow = styled.div`
  display: flex;
  justify-content: flex-start;

  &:not(:first-of-type) {
    margin-top: ${gutters.spacing(2)}px;
  }
  flex: 1;
  min-width: 0;

  > div {
    display: flex;
    flex: 1;
    min-width: 0;
    &:first-of-type {
      flex: 1 0 ${mobileSizes.row.leftColumnWidth}px;

      @media (min-width: ${breakpoints.sm}px) {
        flex: 1 0 ${desktopSizes.row.leftColumnWidth}px;
      }
      margin-right: ${gutters.spacing(4)}px;
    }
    &:last-of-type {
      flex: 0 0 ${desktopSizes.row.rightColumnWidth}px;
      &.percentage {
        padding-top: ${gutters.spacing(0, 2)}px;
        align-items: flex-start;
      }
    }
  }
`;

const ObjectPrice = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;
  min-width: 0;
  flex-direction: column;
  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
  > div {
    flex: 1;
    display: flex;
    min-width: 0;
    &:last-of-type {
      margin-top: ${gutters.spacing(3, -3)}px;
    }
    @media (min-width: ${breakpoints.sm}px) {
      &:first-of-type {
        flex: 1 0 ${desktopSizes.objectPrice.objectWidth}px;
        margin-right: ${gutters.spacing(4)}px;
      }
      &:last-of-type {
        margin-top: 0;
        flex: 0 0 ${desktopSizes.objectPrice.priceWidth}px;
      }
    }
  }
  ${({ margin }) =>
    margin &&
    css`
      margin-bottom: ${gutters.spacing(2)}px;
    `}
`;

export const StyledSelectMenu = styled(Select)`
  z-index: ${layers.content(0, 5)};
  width: auto !important;
  max-width: 200px;
`;

const CenterText = styled.div`
  display: flex;
  text-align: center;
`;

const calcBar = ({
  status,
  percentageChanceToClose,
  goodColor = colorsPrimary.green.dark,
  failedColor = colorsSecondary.red.dark,
}) => {
  const barData = {
    [LEAD_STATUS.WON]: { primaryColor: goodColor, percentage: 100 },
    [LEAD_STATUS.OPEN]: {
      primaryColor: goodColor,
      percentage: percentageChanceToClose,
    },
    [LEAD_STATUS.LOST]: { primaryColor: failedColor, percentage: 100 },
    [LEAD_STATUS.DISQUALIFIED]: { primaryColor: failedColor, percentage: 100 },
  };

  return barData[status];
};

const DetailsBlock = ({ record, updateStatus, responsive }) => {
  const { t } = useTranslation();

  const [stagedRecord, setStagedRecord] = useField(record, [record]);
  const {
    name,
    displayName,
    id,
    objectId,
    objectName,
    entityValue, // only if custom model is tracking entity value
    stage,
    displayPercentage,
    stageField,
    trackEntityValue,
  } = stagedRecord;

  const stagesRef = useRef();
  const stagesMobileRef = useRef();
  const canChangeStageField =
    stageField?.access?.view && stageField?.access?.edit;
  const [showStagesMenu, setShowStagesMenu] = useState(false);
  const [showStagesMobileMenu, setShowStagesMobileMenu] = useState(false);

  const [selectedStage, setSelectedStage] = useState(null);
  const [stagesOptions, setStagesOptions] = useState([]);
  const [confirmModalProps, , { show: showConfirmModal }] = useModal({
    handleSubmit: () => {
      const { percentageChanceToClose, id, name, status } = selectedStage.org;
      let nextRecord = {
        ...stagedRecord,
        stage: {
          percentageChanceToClose,
          id,
          name,
          status,
        },
      };
      nextRecord = {
        ...nextRecord,
        displayPercentage: calcPercentageToDisplay(nextRecord),
      };
      // opto update
      setStagedRecord(nextRecord);
      updateStatus(nextRecord);
    },
  });

  const handleShowStagesMenu = async (mobile) => {
    if (mobile) {
      setShowStagesMobileMenu(true);
    } else {
      setShowStagesMenu(true);
    }

    setStagesOptions(
      (stageField?.options || [])
        .map((opt) => ({ value: opt.id, label: opt.name, org: opt }))
        .sort(({ org: a }, { org: b }) => a.order - b.order)
    );
  };

  if (responsive) {
    const stageNameWidth = canChangeStageField ? '122.5px' : '120px';
    return (
      <div data-qa="related-workflows-inner-details">
        {/* Large variant */}
        <div
          style={{
            gridTemplateColumns:
              'repeat(2, minMax(0,1fr)) minMax(0, 100px) minMax(0, 90px) minMax(0, 120px)',
            gap: '20px',
            gridTemplateRows: 'auto',
          }}
          className="hidden @md-tile/Tile:grid"
        >
          <div className="w-full items-center" data-qa={id}>
            <Typography
              variant="link"
              href={`/custom-objects/${objectId}/${id}/details`}
              target="_blank"
              rel="noopener noreferrer"
              truncate
            >
              {displayName || name}
            </Typography>
          </div>
          <div className="w-full">
            <Typography truncate>{objectName}</Typography>
          </div>
          <div className="w-full text-right">
            <Typography truncate weight="semibold">
              {trackEntityValue &&
                `$${moneyNumberFormatter.format(entityValue || 0)}`}
            </Typography>
          </div>
          <div
            data-qa="related-workflows-stage-name"
            className="w-full items-center plinky"
          >
            <Typography truncate>{stage.name}</Typography>
          </div>
          <div className="kds flex items-center gap-x-spacer-10 justify-end">
            <PercentageBar
              width={canChangeStageField ? '100' : '120'}
              {...calcBar({
                status: stage.status,
                percentageChanceToClose: displayPercentage,
              })}
              responsive
            />
            {canChangeStageField ? (
              <Button
                qa={{
                  action: 'related-workflows-stages-dropdown',
                }}
                ref={stagesRef}
                rightIcon="form-input-dropdown-arrow"
                variant="text"
                onClick={() => handleShowStagesMenu()}
                color="tertiary"
                size="xs"
              />
            ) : null}
          </div>
          <SelectOverlay
            show={showStagesMenu}
            target={stagesRef.current}
            onHide={() => {
              setShowStagesMenu(false);
            }}
            popperConfig={stagesSelectPopperConfig}
          >
            <StyledSelectMenu
              value={stage.id || null}
              onChange={(newStage) => {
                if (newStage.value !== stage.id) {
                  setSelectedStage(newStage);
                  showConfirmModal();

                  setShowStagesMenu(false);
                }
              }}
              options={stagesOptions}
              placeholder={t('Select Option')}
              menuOnly
            />
          </SelectOverlay>
        </div>
        {/* Small Variant */}
        <div className="kds flex flex-col @md-tile/Tile:hidden gap-y-spacer-15">
          <div
            className="grid"
            style={{
              gridTemplateColumns: 'minMax(0,3fr) minMax(0,2fr)',
              gap: '20px',
              gridTemplateRows: 'auto',
            }}
          >
            <div className="items-center">
              <Typography
                variant="link"
                href={`/custom-objects/${objectId}/${id}/details`}
                target="_blank"
                rel="noopener noreferrer"
                truncate
              >
                {displayName || name}
              </Typography>
            </div>
            <div className="kds flex justify-end">
              <FixedWidth width={stageNameWidth}>
                <Typography truncate>{stage.name}</Typography>
              </FixedWidth>
            </div>
          </div>
          <div
            className="grid"
            style={{
              gridTemplateColumns: 'minMax(0,1fr) minMax(0,1fr) minMax(0,2fr)',
              gap: '20px',
              gridTemplateRows: 'auto',
            }}
          >
            <div className="w-full">
              <Typography truncate>{objectName}</Typography>
            </div>
            <div className="w-full text-right">
              <Typography truncate weight="semibold">
                {trackEntityValue &&
                  `$${moneyNumberFormatter.format(entityValue || 0)}`}
              </Typography>
            </div>
            <div className="kds flex items-center gap-x-spacer-10 justify-end">
              <PercentageBar
                width={canChangeStageField ? '100' : '120'}
                {...calcBar({
                  status: stage.status,
                  percentageChanceToClose: displayPercentage,
                })}
                responsive
              />
              {canChangeStageField ? (
                <Button
                  ref={stagesMobileRef}
                  rightIcon="form-input-dropdown-arrow"
                  variant="text"
                  onClick={() => handleShowStagesMenu(true)}
                  color="tertiary"
                  size="xs"
                />
              ) : null}
            </div>
          </div>

          <SelectOverlay
            show={showStagesMobileMenu}
            target={stagesMobileRef.current}
            onHide={() => {
              setShowStagesMobileMenu(false);
            }}
            popperConfig={stagesSelectPopperConfig}
          >
            <StyledSelectMenu
              value={stage.id || null}
              onChange={(newStage) => {
                if (newStage.value !== stage.id) {
                  setSelectedStage(newStage);
                  showConfirmModal();

                  setShowStagesMobileMenu(false);
                }
              }}
              options={stagesOptions}
              placeholder={t('Select Option')}
              menuOnly
            />
          </SelectOverlay>
        </div>
        <BasicModal
          data-qa="confirm-stage-change-modal"
          heading={t('Please Confirm Stage Change')}
          buttonText={t('Confirm Change')}
          defaultLeftBtnText={t('Cancel')}
          {...confirmModalProps}
        >
          <CenterText>
            <KizenTypography>
              {`${t('This will move the')} ${record?.entityName} 
            ${t('to Stage')}: ${selectedStage?.label}`}
            </KizenTypography>
          </CenterText>
        </BasicModal>
      </div>
    );
  }

  return (
    <Details className="details" data-qa={id}>
      <DetailsRow className="details-row" margin>
        <div>
          <TextEllipsisWithTooltip
            type="link"
            to={`/custom-objects/${objectId}/${id}/details`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {displayName || name}
          </TextEllipsisWithTooltip>
        </div>
        <div>
          <TextEllipsisWithTooltip size="text">
            {stage.name}
          </TextEllipsisWithTooltip>
        </div>
      </DetailsRow>
      <DetailsRow className="details-row2">
        <div>
          <ObjectPrice>
            <div>
              <TextEllipsisWithTooltip size="text">
                {objectName}
              </TextEllipsisWithTooltip>
            </div>
            <div>
              <TextEllipsisWithTooltip size="text">
                {trackEntityValue &&
                  `$${moneyNumberFormatter.format(entityValue || 0)}`}
              </TextEllipsisWithTooltip>
            </div>
          </ObjectPrice>
        </div>
        <div className="percentage">
          <PercentageBar
            width={canChangeStageField ? '100' : '120'}
            {...calcBar({
              status: stage.status,
              percentageChanceToClose: displayPercentage,
            })}
          />
          {canChangeStageField ? (
            <IconButton
              sizing="dense"
              title={t('Edit Workflow/Pipeline')}
              ref={stagesRef}
              onClick={() => handleShowStagesMenu()}
              data-qa="related-workflows-stages-dropdown"
            >
              <IconSizing size="12px">
                <Icon icon="chevron-down" />
              </IconSizing>
            </IconButton>
          ) : null}
          <SelectOverlay
            show={showStagesMenu}
            target={stagesRef.current}
            onHide={() => {
              setShowStagesMenu(false);
            }}
            popperConfig={stagesSelectPopperConfig}
          >
            <StyledSelectMenu
              value={stage.id || null}
              onChange={(newStage) => {
                if (newStage.value !== stage.id) {
                  setSelectedStage(newStage);
                  showConfirmModal();

                  setShowStagesMenu(false);
                }
              }}
              options={stagesOptions}
              placeholder={t('Select Option')}
              menuOnly
            />
          </SelectOverlay>
        </div>
      </DetailsRow>
      <BasicModal
        heading={t('Please Confirm Stage Change')}
        buttonText={t('Confirm Change')}
        defaultLeftBtnText={t('Cancel')}
        {...confirmModalProps}
      >
        <CenterText>
          <KizenTypography>
            {`${t('This will move the')} ${record?.entityName} 
            ${t('to Stage')}: ${selectedStage?.label}`}
          </KizenTypography>
        </CenterText>
      </BasicModal>
    </Details>
  );
};

export default DetailsBlock;
