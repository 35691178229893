import { useMemo } from 'react';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  StyledIcon,
  StyledFolder,
} from 'components/Kizen/Table/CellLineHeightStyles';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { colorsButton } from 'app/colors';
import {
  COLUMNS_SIZE,
  PADDING_SIZE,
} from 'components/MessageTemplatePicker/utility';
import ActionCell from 'components/Tables/ActionCell';

const TableActionCell = (props) => {
  const { data, t } = props;
  const { type } = data;

  const options = useMemo(() => {
    return [{ value: null, label: t('View') }];
  }, [t]);

  return type !== 'folder' ? (
    <ActionCell
      title={t('View Message')}
      menuType="absolute"
      options={options}
      {...props}
    />
  ) : (
    <SizedCell {...props} />
  );
};

export const getBroadcastsColumns = ({
  onSubmit,
  onActionView,
  handleChangeDirectory,
  parentFolder,
  t,
  columnSize = COLUMNS_SIZE,
}) =>
  [
    {
      id: 'spacer-1',
      headCell: <SizedCell width={columnSize.spacer} />,
      cell: <SizedCell width={columnSize.spacer} />,
    },
    {
      id: 'name',
      headCell: (
        <SortableHeadCell
          label={t('Name')}
          width={columnSize.name}
          padding={PADDING_SIZE.basic}
        />
      ),
      cell: <SizedCell width={columnSize.name} padding={PADDING_SIZE.basic} />,
      format: function NameCell(value, message) {
        return message.type === 'folder' ? (
          <StyledFolder>
            {message?.levelUp ? (
              <StyledIcon
                icon={'level-up'}
                rotation={270}
                color={colorsButton.blue}
                onClick={(event) => {
                  event.preventDefault();
                  handleChangeDirectory(parentFolder);
                }}
              />
            ) : (
              <StyledIcon
                icon="folder-alt"
                color={colorsButton.blue}
                onClick={(event) => {
                  event.preventDefault();
                  handleChangeDirectory(message);
                }}
              />
            )}
            <TextEllipsisWithTooltip
              type={'link'}
              onClick={(event) => {
                event.preventDefault();
                handleChangeDirectory(message.id ? message : parentFolder);
              }}
            >
              {value}
            </TextEllipsisWithTooltip>
          </StyledFolder>
        ) : (
          <TextEllipsisWithTooltip
            type="link"
            onClick={(event) => {
              event.preventDefault();
              onSubmit(message);
            }}
          >
            {value}
          </TextEllipsisWithTooltip>
        );
      },
    },
    {
      id: 'subject',
      headCell: (
        <SortableHeadCell
          label={t('Subject Line')}
          width={columnSize.subject}
          padding={PADDING_SIZE.basic}
        />
      ),
      cell: (
        <SizedCell width={columnSize.subject} padding={PADDING_SIZE.basic} />
      ),
      format: function modelCell(value) {
        return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
      },
    },
    {
      id: 'firstSent',
      headCell: (
        <SortableHeadCell
          numeric
          label={t('Date Sent')}
          width={columnSize.firstSent}
          padding={PADDING_SIZE.small}
          by={'first_sent'}
        />
      ),
      cell: (
        <SizedCell width={columnSize.firstSent} padding={PADDING_SIZE.small} />
      ),
      format: (firstSent, data) =>
        data?.type === 'folder' || !firstSent ? (
          // Show empty value: `-` for folders, since they don't really make sense yet.
          // We will have to change this special case when we add user-controlled folders... Maybe...
          <TextEllipsisWithTooltip />
        ) : (
          <DateTimeInputInline readOnly value={firstSent} showDateTooltip />
        ),
    },
    {
      id: 'actions',
      headCell: (
        <SizedCell width={columnSize.actions} padding={PADDING_SIZE.basic}>
          <TextEllipsisWithTooltip as="span" weight="bold">
            {t('Actions')}
          </TextEllipsisWithTooltip>
        </SizedCell>
      ),
      cell: (
        <TableActionCell
          width={columnSize.actions}
          padding={PADDING_SIZE.basic}
          title={t('View Message')}
          menuType="absolute"
          onSelectAction={onActionView}
          t={t}
        />
      ),
    },
  ].filter(Boolean);
