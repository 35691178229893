import { useState } from 'react';
import { TemplateToolBar } from '../components/ToolBar';
import { CardTitle, ContentCard } from '../styles';
import { MobileFallback } from 'pages/Common/MobileFallback';
import ColumnsService from 'services/ColumnsService';
import {
  getColumnSettings,
  getColumnsPayload,
  getColumnConfigPayload,
  getPathBack,
} from '../helpers';
import { CONFIG_KEY, NAV_BAR_HEIGHT } from '../constants';
import { useAsync } from 'react-use';
import Loader from 'components/Kizen/Loader';
import useField from 'hooks/useField';
import { useDraggableColumns } from '../hooks/useDraggableColumns';
import { DraggableColumns } from '../components/DraggableColumns';
import { CustomizeFieldsLink } from '../components/CustomizeFieldsLink';
import { getOriginalError } from 'services/AxiosService';
import { TemplateNameInput } from '../components/TemplateNameInput';
import { useHistory } from 'react-router-dom';
import { useDirtyState } from '../hooks/useDirtyState';
import ConfirmNavigationWithActionModal from 'components/Modals/presets/ConfirmNavigationWithAction';
import { useTranslation } from 'react-i18next';
import { PageSizing } from 'components/Layout/PageContentWidth';
import { toastVariant, useToast } from 'components/ToastProvider';
import { deferExecution } from 'utility/defer';

export const TemplateColumns = ({
  defaultColumnSettings,
  groupedColumns,
  disabled,
  isMobile,
  objectId,
  model,
  templateId,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const { value: template, loading: loadingTemplate } = useAsync(async () => {
    return ColumnsService.getTemplate(templateId, model?.id);
  }, [templateId]);

  const history = useHistory();

  const [name, setName] = useField(template?.name || '');
  const [templateName, setTemplateName] = useField(template?.name || '');
  const [nameError, setNameError] = useState(null);
  const [saving, setSaving] = useState(false);

  const [columnSettings, setColumnSettings] = useField(
    () => getColumnSettings(defaultColumnSettings, template?.[CONFIG_KEY]),
    [template, defaultColumnSettings]
  );

  const { canCustomizeFields, leftItems, rightItems, ...DnDProps } =
    useDraggableColumns(columnSettings, groupedColumns, defaultColumnSettings);

  const dirty = useDirtyState(columnSettings, rightItems, leftItems);

  const handleSubmit = async ({ withClose } = {}) => {
    setSaving(true);
    const nextSettings = getColumnsPayload(rightItems, leftItems);
    try {
      await ColumnsService.updateTemplate(
        templateId,
        name,
        getColumnConfigPayload(nextSettings),
        undefined,
        undefined,
        model?.id
      );

      setTemplateName(name);
      setColumnSettings(nextSettings);

      if (withClose) {
        deferExecution(() => {
          history.push(getPathBack(history.location.pathname));
        });
      }

      showToast({
        message: t("'{{name}}' column template has been saved successfully.", {
          name,
        }),
        variant: toastVariant.SUCCESS,
      });
    } catch (err) {
      const { name } = getOriginalError(err) || {};
      setNameError(name || null);
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (value) => {
    setName(value);
    setNameError(null);
  };

  return (
    <PageSizing>
      <TemplateToolBar
        onSave={handleSubmit}
        disabled={
          disabled ||
          saving ||
          loadingTemplate ||
          !(dirty || templateName !== name)
        }
        isMobile={isMobile}
      />
      <ContentCard heightOffset={NAV_BAR_HEIGHT}>
        <MobileFallback isMobile={isMobile}>
          <Loader loading={loadingTemplate}>
            <CardTitle>
              <TemplateNameInput
                value={name}
                onChange={handleChange}
                error={nameError}
              />
            </CardTitle>
            <DraggableColumns
              leftItems={leftItems}
              rightItems={rightItems}
              {...DnDProps}
            />
            {canCustomizeFields ? (
              <CustomizeFieldsLink objectId={objectId} />
            ) : null}
          </Loader>
        </MobileFallback>
      </ContentCard>
      <ConfirmNavigationWithActionModal
        when={dirty || templateName !== name}
        heading={t('Your Columns Have Unsaved Changes')}
        buttonText={t('Save')}
        actionBtnColor="green"
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        action={handleSubmit}
        preventNavigation={!!nameError}
        onAdditionalConfirm={() => setNameError(null)}
      >
        {t('All unsaved changes will be lost unless you save your columns.')}
      </ConfirmNavigationWithActionModal>
    </PageSizing>
  );
};
