import { useMemo } from 'react';
import FirstDashletButton from '../FirstDashletButton';
import { DashletCard, FirstDashletCard, OverFlowContainer } from '../styles';
import {
  defaultPosition,
  getDashletGridLayout,
  getDashletKey,
  getDashletQA,
} from '../utils';
import { getHTMLConfig } from 'pages/Homepages/hooks/useHTMLConfig';

export const MODES = {
  DASHBOARD: 'dashboard',
  HOMEPAGES: 'home',
  CHARTS: 'charts',
};

const useDashboardGridChildren = ({
  renderChild,
  forceShowUIElements,
  dashlets,
  hasEditPermission,
  breakpoint,
  addDashletModalTriggerProps,
  mobile,
  dateFilter,
  mode = MODES.DASHBOARD,
  filtersForCharts,
  rowHeightOverrides,
  columnCount,
}) => {
  const hasDashlets = dashlets.length > 0;

  const sortedDashletsByLayout = useMemo(() => {
    return (
      dashlets?.sort((a, b) => {
        if (a.layout?.y > b.layout?.y) {
          return 1;
        } else if (a.layout?.y < b.layout?.y) {
          return -1;
        } else {
          if (a.layout?.x > b.layout?.x) {
            return 1;
          } else if (a.layout?.x < b.layout?.x) {
            return -1;
          }
          return 0;
        }
      }) ?? []
    );
  }, [dashlets]);

  const children = useMemo(() => {
    if (!hasDashlets && !hasEditPermission) {
      return [];
    }
    if (!hasDashlets && mobile && mode === MODES.CHARTS) {
      return [];
    }
    if (!hasDashlets) {
      return [
        <FirstDashletCard
          data-grid={defaultPosition}
          key={`add-new:${breakpoint}`}
        >
          <FirstDashletButton
            triggerAddDashletProps={addDashletModalTriggerProps}
            mode={mode}
          />
        </FirstDashletCard>,
      ];
    }
    return sortedDashletsByLayout.map((dashlet, index) => {
      const forceShowUI = forceShowUIElements.includes(dashlet.id);
      const { htmlBlockConfig } = getHTMLConfig(dashlet);

      return (
        <DashletCard
          data-grid={getDashletGridLayout(
            dashlet,
            rowHeightOverrides,
            columnCount
          )}
          {...getDashletQA(dashlet, rowHeightOverrides)}
          key={
            getDashletKey(dashlet, breakpoint, dateFilter, filtersForCharts) +
            JSON.stringify(rowHeightOverrides)
          }
          forceShowUI={forceShowUI}
          alwaysShowMenu={mobile}
          data-qa-has-controls={hasEditPermission}
          backgroundColor={htmlBlockConfig?.backgroundColor}
          hasShadow={htmlBlockConfig?.hasShadow}
          isHTML={Boolean(htmlBlockConfig)}
        >
          <OverFlowContainer>
            {renderChild({
              dashlet,
              errorPadding: Boolean(htmlBlockConfig),
              index,
            })}
          </OverFlowContainer>
        </DashletCard>
      );
    });
  }, [
    addDashletModalTriggerProps,
    breakpoint,
    sortedDashletsByLayout,
    dateFilter,
    forceShowUIElements,
    hasDashlets,
    hasEditPermission,
    mobile,
    renderChild,
    mode,
    filtersForCharts,
    rowHeightOverrides,
    columnCount,
  ]);

  return children;
};

export default useDashboardGridChildren;
