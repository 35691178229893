import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { grayScale } from 'app/colors';
import { DEFAULT_DELAY } from 'utility/config';
import {
  StyledLabelWrapper,
  StyledLabel,
  StyledInputWrapper,
  StyledInputEndcap,
  StyledInput,
  StyledNumberInput,
  INPUT_TYPES,
  StyledLabelContainer,
  StyledCreditCardsContainer,
  StyledCreditCardIcon,
  PointedIcon,
} from './styles';
import { getInputType, validateNumber } from './helpers';
import Icon from '../Icon';
import { ClipLoader } from 'react-spinners';

const Input = React.forwardRef(
  (
    {
      error,
      className,
      label,
      value,
      placeholder,
      onChange,
      type,
      disabled,
      underline,
      pill,
      id,
      inputType,
      endcap,
      onClick,
      onFocus,
      onBlur,
      isClearable = false,
      hideLabel = false,
      hideIcon = false,
      creditCards = null,
      currencyCharacter,
      percentageCharacter,
      overrideDecimalScale,
      displayThousandsSeparator = true,
      compact = false,
      inputRef,
      onKeyDown = undefined,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [focused, setFocused] = useState(false);
    const [numberErrorClass, setNumberErrorClass] = useState('');
    const idAttr = id ? { id } : {};

    const renderInputField = () => {
      const commonInputProps = {
        value,
        onChange: (event) => {
          if (
            (type === INPUT_TYPES.NUMBER || type === INPUT_TYPES.ONLY_NUMBER) &&
            !validateNumber(event.target.value)
          ) {
            event.preventDefault();
            setNumberErrorClass('number-error');
            setTimeout(
              () => setNumberErrorClass('no-number-error'),
              DEFAULT_DELAY
            );
            const { value: val } = event.target;
            const deleteCount = type === INPUT_TYPES.ONLY_NUMBER ? 1 : 2;
            // TODO: TMP solution untill we release new components
            if (type === INPUT_TYPES.ONLY_NUMBER) {
              const numbersOnly = val.replace(/[^0-9]/g, '');
              onChange(numbersOnly);
              return;
            }
            onChange(val.substring(0, val.length - deleteCount));
          } else if (
            type === INPUT_TYPES.WHOLE_NUMBER &&
            !validateNumber(event.target.value)
          ) {
            setNumberErrorClass('number-error');
            setTimeout(
              () => setNumberErrorClass('no-number-error'),
              DEFAULT_DELAY
            );
          } else {
            onChange(event.target.value);
          }
        },
        onPaste: (event) => {
          // TODO: TMP solution untill we release new components
          if (type === INPUT_TYPES.ONLY_NUMBER) {
            event.preventDefault();
            const paste = (event.clipboardData || window.clipboardData).getData(
              'text'
            );
            const numbersOnly = paste.replace(/[^0-9]/g, '');
            onChange(numbersOnly);
          }
        },
        type: inputType || getInputType(type),
        placeholder,
        onFocus: (ev) => {
          setFocused(true);
          if (onFocus) {
            onFocus(ev);
          }
        },
        onBlur: (ev) => {
          setFocused(false);
          if (onBlur) {
            onBlur(ev);
          }
        },
        onKeyDown: (ev) => {
          onKeyDown?.(ev);
        },
        disabled,
        ref: inputRef,
        ...idAttr,
        ...others,
      };

      if (type === INPUT_TYPES.PRICE_V2) {
        const decimalScale = {
          decimalScale: 2,
          fixedDecimalScale: true,
          thousandSeparator: displayThousandsSeparator,
          allowNegative: false,
        };
        // eslint-disable-next-line no-shadow
        const { id, ...props } = commonInputProps; // remove id property from config
        return (
          <StyledNumberInput defaultValue="" {...decimalScale} {...props} />
        );
      }

      if (
        [INPUT_TYPES.PRICE, INPUT_TYPES.DECIMAL].includes(type) ||
        [INPUT_TYPES.PERCENTAGE, INPUT_TYPES.DECIMAL].includes(type)
      ) {
        const decimalScale =
          type === INPUT_TYPES.PRICE || type === INPUT_TYPES.PERCENTAGE
            ? { decimalScale: 2, fixedDecimalScale: true }
            : {
                thousandSeparator: displayThousandsSeparator,
                decimalScale: overrideDecimalScale,
              };

        return (
          <StyledNumberInput
            defaultValue=""
            {...decimalScale}
            {...commonInputProps}
          />
        );
      }

      return <StyledInput {...commonInputProps} />;
    };

    const handleClear = () => {
      onChange('');
    };

    return (
      <div ref={ref} className={className}>
        <StyledLabelWrapper htmlFor={id}>
          {type === INPUT_TYPES.CREDIT_CARD && creditCards ? (
            <StyledLabelContainer>
              <StyledLabel>{label}</StyledLabel>
              <StyledCreditCardsContainer>
                {Object.keys(creditCards).map((key) => {
                  const { icon, firstDigit, activeColor } = creditCards[key];
                  const active =
                    value &&
                    parseInt(value && value.slice(0, 1), 10) === firstDigit;
                  return (
                    <StyledCreditCardIcon
                      key={key}
                      icon={icon}
                      color={active ? activeColor : grayScale.mediumDark}
                      active={active}
                    />
                  );
                })}
              </StyledCreditCardsContainer>
            </StyledLabelContainer>
          ) : (
            <>{label && !hideLabel && <StyledLabel>{label}</StyledLabel>}</>
          )}

          <StyledInputWrapper
            focused={focused}
            error={error}
            underline={underline}
            pill={pill}
            disabled={disabled}
            className={`input-wrapper ${numberErrorClass}`}
            onClick={onClick}
          >
            {type === INPUT_TYPES.PRICE && (
              <StyledInputEndcap compact={compact}>
                {currencyCharacter}
              </StyledInputEndcap>
            )}
            {type === INPUT_TYPES.PERCENTAGE && (
              <StyledInputEndcap compact={compact}>
                {percentageCharacter}
              </StyledInputEndcap>
            )}
            {type === INPUT_TYPES.PRICE_V2 && value && (
              <StyledInputEndcap compact={compact}>$</StyledInputEndcap>
            )}
            {renderInputField()}
            {!endcap && type === INPUT_TYPES.SEARCH ? (
              <StyledInputEndcap className="search">
                {others.loading ? (
                  <div data-qa="input-loading-container">
                    <ClipLoader
                      loading
                      size={15}
                      color={grayScale.mediumDark}
                    />
                  </div>
                ) : isClearable && value ? (
                  <PointedIcon
                    icon="xmark"
                    color={{
                      default: grayScale.mediumDark,
                      hover: grayScale.dark,
                    }}
                    alt={t('Clear Search Term')}
                    onClick={handleClear}
                  />
                ) : (
                  <Icon
                    icon="search"
                    className="search-icon"
                    color={grayScale.mediumDark}
                  />
                )}
              </StyledInputEndcap>
            ) : null}
            {endcap && type === INPUT_TYPES.TEXT && !hideIcon && (
              <StyledInputEndcap>{endcap}</StyledInputEndcap>
            )}
          </StyledInputWrapper>
        </StyledLabelWrapper>
      </div>
    );
  }
);

Input.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  underline: PropTypes.bool,
  pill: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(INPUT_TYPES)),
  inputType: PropTypes.string,
  endcap: PropTypes.node,
  creditCards: PropTypes.object,
  hideLabel: PropTypes.bool,
  hideIcon: PropTypes.bool,
  currencyCharacter: PropTypes.string,
  percentageCharacter: PropTypes.string,
};

Input.defaultProps = {
  onChange: () => {},
  value: '',
  onFocus: null,
  onBlur: null,
  onClick: null,
  placeholder: '',
  id: '',
  className: '',
  label: null,
  error: false,
  disabled: false,
  underline: false,
  pill: false,
  type: INPUT_TYPES.TEXT,
  inputType: null,
  endcap: null,
  creditCards: null,
  hideLabel: false,
  hideIcon: false,
  currencyCharacter: '$',
  percentageCharacter: '%',
};

Input.INPUT_TYPES = INPUT_TYPES;

export default Input;
