import { DASHBOARD } from 'queries/query-keys';
import { useCallback, useMemo, useState } from 'react';
import DashboardService from 'services/DashboardService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useTranslation } from 'react-i18next';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import useModal from 'components/Modals/useModal';
import { useReorderDashboards } from 'components/DashboardGrid/hooks/useReorderDashboards';

export const fetchDashboardsQuery = async (
  searchTerm,
  includeSharing,
  objectId,
  type
) => {
  const res = await DashboardService.getDashboardList(
    searchTerm,
    {
      skipErrorBoundary: true,
    },
    includeSharing,
    objectId,
    type
  );
  return res;
};

export const fetchOtherDashboardsQuery = async (
  searchTerm,
  page,
  pageSize,
  objectId,
  type
) => {
  const res = await DashboardService.getOthersDashboardList(
    searchTerm,
    page,
    pageSize,
    objectId,
    type
  );
  return res;
};

export const updateDashboardQuery = (id, values) => {
  return DashboardService.updateDashboard(id, values);
};

export const createDashboardQuery = (values, objectId, type) => {
  return DashboardService.createDashboard(values, objectId, type);
};

export const duplicateDashboardQuery = (values, objectId, type) => {
  return DashboardService.duplicateDashboard(values, objectId, type);
};

export const deleteDashboardQuery = (id) => {
  return DashboardService.deleteDashboard(id);
};

export const reorderDashboardsQuery = (id, payload) => {
  return DashboardService.reorderDashboards(id, payload);
};

export const requestAccessQuery = (dashboardId, adminId, accessLevel) => {
  return DashboardService.requestAccess(dashboardId, adminId, accessLevel);
};

const INCLUDE_SHARING = true;

const useDashboardList = (
  { debouncedTerm = '', objectId = '', type = '' },
  useConfig
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(25);
  const [showToast] = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [existingDashboard, setExistingDashboard] = useState({});
  const [isDuplication, setIsDuplication] = useState(false);
  const [canUserEditPermissions, setCanUserEditPermissions] = useState(true);
  const [isDashboardUpdated, setIsDashboardUdated] = useState(false);

  const dashboardsQueryKey = debouncedTerm
    ? [...DASHBOARD.DASHBOARDS, debouncedTerm, objectId, INCLUDE_SHARING]
    : [...DASHBOARD.DASHBOARDS, objectId, INCLUDE_SHARING];

  const otherDashboardsQueryKey = debouncedTerm
    ? [
        ...DASHBOARD.DASHBOARDS_OTHERS,
        currentPage,
        currentPageSize,
        debouncedTerm,
        objectId,
      ]
    : [...DASHBOARD.DASHBOARDS_OTHERS, currentPage, currentPageSize, objectId];

  const handleFetchDashboards = useCallback(() => {
    return fetchDashboardsQuery(debouncedTerm, INCLUDE_SHARING, objectId, type);
  }, [debouncedTerm, objectId, type]);

  const handleFetchOtherDashboards = useCallback(() => {
    return fetchOtherDashboardsQuery(
      debouncedTerm,
      currentPage,
      currentPageSize,
      objectId,
      type
    );
  }, [debouncedTerm, currentPage, currentPageSize, objectId, type]);

  const {
    isLoading: myDashboardsLoading,
    data: myDashboards = [],
    isRefetching: dashboardsRefetching,
  } = useQuery(dashboardsQueryKey, handleFetchDashboards, {
    keepPreviousData: true,
  });

  const refetchDashboards = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: DASHBOARD.DASHBOARDS });
  }, [queryClient]);

  const {
    isLoading: otherDashboardsLoading,
    data: otherDashboards = {
      results: [],
      total: 0,
    },
    refetch: refetchOtherDashboards,
    isRefetching: otherDashboardsRefetching,
  } = useQuery(otherDashboardsQueryKey, handleFetchOtherDashboards, {
    keepPreviousData: true,
  });

  const data = useMemo(() => {
    return {
      mine: myDashboards,
      other: otherDashboards.results,
    };
  }, [myDashboards, otherDashboards]);

  const loading = useMemo(() => {
    return {
      mine: myDashboardsLoading,
      other: otherDashboardsLoading,
    };
  }, [myDashboardsLoading, otherDashboardsLoading]);

  const refetching = useMemo(() => {
    return {
      mine: dashboardsRefetching,
      other: otherDashboardsRefetching,
    };
  }, [dashboardsRefetching, otherDashboardsRefetching]);

  const {
    loading: configLoading,
    afterDelete,
    afterCreate,
    afterUpdate,
  } = useConfig(data.mine, true, objectId);

  const updateDashboardMutation = useMutation(
    (result) => updateDashboardQuery(result.id, result.payload),
    {
      onSuccess: (data, variables) => {
        setIsDashboardUdated(true);
        afterUpdate(data.id, variables.payload);
        queryClient.invalidateQueries([...DASHBOARD.DASHBOARD, data.id]);
        refetchDashboards();
        refetchOtherDashboards();
      },
      onError: (payload) => {
        const message =
          payload?.response?.data?.[0] ?? t('Dashboard could not be updated.');

        showToast({
          message,
          variant: toastVariant.FAILURE,
        });
      },
    }
  );

  const createDashboardMutation = useMutation(
    (result) => createDashboardQuery(result, objectId, type),
    {
      onSuccess: (data) => {
        afterCreate(data.id);
        refetchDashboards();
        refetchOtherDashboards();
      },
    }
  );

  const duplicateDashboardMutation = useMutation(
    (result) => duplicateDashboardQuery(result.id, result.payload),
    {
      onSuccess: (data) => {
        afterCreate(data.id);
        refetchDashboards();
        refetchOtherDashboards();
      },
    }
  );

  const deleteDashboardMutation = useMutation(
    (result) => deleteDashboardQuery(result),
    {
      onSuccess: (_data, id) => {
        setIsDashboardUdated(true);
        afterDelete(id);
        refetchDashboards();
        refetchOtherDashboards();
      },
    }
  );

  const sendRequestMutation = useMutation(
    (result) =>
      requestAccessQuery(
        result.dashboardId,
        result.adminId,
        result.accessLevel
      ),
    {
      onSettled: (data, e) => {
        if (data?.status === 'pending') {
          showToast({
            message: t('Your access request has been sent.'),
            variant: toastVariant.SUCCESS,
          });
        } else {
          showToast({
            message: t('Your access request could not be sent.'),
            variant: toastVariant.FAILURE,
          });
        }
      },
    }
  );

  const handleMutateDashboard = useCallback(
    (
      { name, permissions, isPrivate, owner = {} },
      isEditing,
      id,
      isDuplication
    ) => {
      const sharingSettingsPayload = permissions
        ? {
            sharing_settings: {
              ...buildSharingSettingsForAPI(permissions).sharing_settings,
              private: isPrivate,
            },
          }
        : {};
      if (isDuplication) {
        duplicateDashboardMutation.mutate({
          id,
          payload: {
            name,
            ...sharingSettingsPayload,
            ...owner,
          },
        });
      } else if (isEditing) {
        updateDashboardMutation.mutate({
          id,
          payload: {
            name,
            ...sharingSettingsPayload,
            ...owner,
          },
        });
      } else {
        createDashboardMutation.mutate({
          name,
          ...sharingSettingsPayload,
        });
      }
      setExistingDashboard({});
      setIsDuplication(false);
    },
    [
      updateDashboardMutation,
      createDashboardMutation,
      duplicateDashboardMutation,
    ]
  );

  const handleReorderDashboards = useReorderDashboards({
    onSuccess: () => {
      refetchDashboards();
      refetchOtherDashboards();
    },
    queryKey: dashboardsQueryKey,
  });

  const [modalProps, , { show: handleShowModal }] = useModal({
    handleSubmit: handleMutateDashboard,
    handleHide: () => {
      setExistingDashboard({});
      setIsDuplication(false);
      setCanUserEditPermissions(true);
    },
  });

  const [requestModalProps, , { show: handleShowRequestModal }] = useModal({
    handleSubmit: (dashboardId, accessLevel, adminId) =>
      sendRequestMutation.mutate({ dashboardId, accessLevel, adminId }),
    handleHide: () => {
      setExistingDashboard({});
      setIsDuplication(false);
      setCanUserEditPermissions(true);
    },
  });

  const handleStartEditing = useCallback(
    (dashboard, canChangePermissions = true) => {
      setExistingDashboard(dashboard);
      setCanUserEditPermissions(canChangePermissions);
      handleShowModal();
    },
    [handleShowModal]
  );

  const handleStartDuplication = useCallback(
    (dashboard, canChangePermissions = true) => {
      setExistingDashboard(dashboard);
      setIsDuplication(true);
      setCanUserEditPermissions(canChangePermissions);
      handleShowModal();
    },
    [handleShowModal]
  );

  const handleStartRequesting = useCallback(
    (dashboard) => {
      setExistingDashboard(dashboard);
      handleShowRequestModal();
    },
    [handleShowRequestModal]
  );

  const handleSettingsBackClick = useCallback(() => {
    if (isDashboardUpdated) {
      queryClient.invalidateQueries([...DASHBOARD.DASHBOARD]);
    }
  }, [isDashboardUpdated, queryClient]);

  return {
    modalProps: { ...modalProps, isDuplication: isDuplication },
    requestModalProps,
    canUserEditPermissions,
    existingDashboard,
    handleShowModal,
    handleStartEditing,
    handleStartDuplication,
    handleStartRequesting,
    handleMutateDashboard,
    handleSettingsBackClick,
    data,
    updateDashboardMutation,
    deleteDashboardMutation,
    loading,
    configLoading,
    handleReorderDashboards,
    currentPage,
    currentPageSize,
    setCurrentPage,
    setCurrentPageSize,
    paginationTotal: otherDashboards.count,
    refetchDashboards,
    refetching,
  };
};

export default useDashboardList;
