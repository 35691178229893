import React from 'react';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import KizenTypography from 'app/kizentypo';

const NewItemTypography = styled(KizenTypography)`
  color: ${colorsButton.green.default};
  &:hover {
    color: ${colorsButton.green.hover};
  }
  && {
    padding: 0;
    background: none;
    outline: none;
  }
`;

export default function NewItemButton(props) {
  return (
    <NewItemTypography
      as="button"
      weight="bold"
      size="buttonLabel"
      textTransform="uppercase"
      {...props}
    />
  );
}
