import { blue, gray } from '@kizen/kds/Colors';
import {
  DPR,
  TITLE_HEIGHT,
  TITLE_PADDING,
  LINE_DASH,
  CURTAIN_WIDTH,
  TOP_CURTAIN_OFFSET,
} from './constants';

export const drawSectionBackground = (
  ctx: CanvasRenderingContext2D,
  x: number,
  width: number,
  color: string
) => {
  ctx.fillStyle = color;
  ctx.fillRect(x, 0, width, ctx.canvas.height);
};

export const drawSectionBorders = (
  ctx: CanvasRenderingContext2D,
  x1: number,
  x2: number
) => {
  ctx.strokeStyle = gray['gray-600'];
  ctx.lineWidth = Math.round(DPR);
  ctx.setLineDash([LINE_DASH, LINE_DASH]);

  ctx.beginPath();
  ctx.moveTo(x1, 0);
  ctx.lineTo(x1, ctx.canvas.height);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(x2, 0);
  ctx.lineTo(x2, ctx.canvas.height);
  ctx.stroke();
};

const composeGradient = (
  curtainCtx: CanvasRenderingContext2D,
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  midStop = 0.2
) => {
  const gradient = curtainCtx.createLinearGradient(x0, y0, x1, y1);
  gradient.addColorStop(0, 'white');
  gradient.addColorStop(midStop, 'white');
  gradient.addColorStop(1, 'transparent');

  return gradient;
};

export const drawCurtains = (
  curtainCtx: CanvasRenderingContext2D,
  sourceCanvas: HTMLCanvasElement
) => {
  const curtainCanvas = curtainCtx.canvas;

  curtainCtx.fillStyle = composeGradient(curtainCtx, 0, 0, CURTAIN_WIDTH, 0);
  curtainCtx.fillRect(0, 0, CURTAIN_WIDTH, curtainCanvas.height);

  curtainCtx.fillStyle = composeGradient(
    curtainCtx,
    curtainCanvas.width,
    0,
    curtainCanvas.width - CURTAIN_WIDTH,
    0
  );
  curtainCtx.fillRect(
    curtainCanvas.width - CURTAIN_WIDTH,
    0,
    CURTAIN_WIDTH,
    curtainCanvas.height
  );

  curtainCtx.fillStyle = composeGradient(
    curtainCtx,
    0,
    0,
    0,
    CURTAIN_WIDTH + TOP_CURTAIN_OFFSET,
    0.5
  );
  curtainCtx.fillRect(
    0,
    0,
    curtainCanvas.width,
    CURTAIN_WIDTH + TOP_CURTAIN_OFFSET
  );

  curtainCtx.fillStyle = composeGradient(
    curtainCtx,
    0,
    curtainCanvas.height,
    0,
    curtainCanvas.height - CURTAIN_WIDTH
  );
  curtainCtx.fillRect(
    0,
    curtainCanvas.height - CURTAIN_WIDTH,
    curtainCanvas.width,
    CURTAIN_WIDTH
  );

  curtainCtx.globalCompositeOperation = 'source-atop';
  curtainCtx.drawImage(sourceCanvas, 0, 0);
  curtainCtx.globalCompositeOperation = 'source-over';
};

class SectionTitlesDrawer {
  #titles: (Path2D | null)[] = [null, null, null];
  hovers: boolean[] = [false, false, false];

  drawSectionTitle = (
    index: number,
    ctx: CanvasRenderingContext2D,
    text: string,
    leftX: number,
    rightX: number,
    rectY: number = 0,
    mousePosition: { x: number; y: number } | null
  ) => {
    const title = new Path2D();
    this.#titles[index] = title;

    const txt = text.toUpperCase();

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = `600 ${Math.round(12 * DPR)}px "Proxima Nova", sans-serif`;

    const rectWidth = ctx.measureText(txt).width + TITLE_PADDING;

    leftX = Math.min(Math.max(leftX, 0), ctx.canvas.width);
    rightX = Math.min(Math.max(rightX, 0), ctx.canvas.width);

    const rectXInit = Math.round(leftX + (rightX - leftX - rectWidth) / 2);
    let rectX = rectXInit;

    if (leftX <= 0) {
      rectX = Math.min(rectX, rightX - rectWidth - TITLE_PADDING);
    }

    if (rightX >= ctx.canvas.width) {
      rectX = Math.max(rectX, leftX + TITLE_PADDING);
    }

    ctx.lineWidth = Math.round(DPR);
    title.roundRect(rectX, rectY, rectWidth, TITLE_HEIGHT, 2 * DPR);

    const isHovered = Boolean(
      mousePosition &&
        ctx.isPointInPath(title, mousePosition.x, mousePosition.y)
    );

    this.hovers[index] = isHovered;

    ctx.strokeStyle = isHovered ? blue['blue-500'] : gray['gray-600'];
    ctx.setLineDash([]);
    ctx.stroke(title);
    ctx.fillStyle = '#fff';
    ctx.fill(title);

    ctx.fillStyle = isHovered ? blue['blue-500'] : gray['gray-800'];

    ctx.fillText(
      txt,
      Math.round(rectX + rectWidth / 2),
      Math.round(rectY + TITLE_HEIGHT / 2),
      rectWidth - TITLE_PADDING
    );
  };
}

export const sectionTitlesDrawer = new SectionTitlesDrawer();
