import {
  forwardRef,
  useCallback,
  useRef,
  useMemo,
  createContext,
  useContext,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ResizableBox } from 'react-resizable';
import { ClipLoader } from 'react-spinners';
import { useClickAway } from 'react-use';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import css from '@emotion/css';
import './editor.css';

import { EditorContent, useEditor as useTipTapEditor } from '@tiptap/react';
import TTTextStyle from '@tiptap/extension-text-style';
import TTUnderline from '@tiptap/extension-underline';
import TTStrike from '@tiptap/extension-strike';
import TTOrderedList from '@tiptap/extension-ordered-list';
import TTListItem from '@tiptap/extension-list-item';
import TTTextAlign from '@tiptap/extension-text-align';
import TTBulletList from '@tiptap/extension-bullet-list';
import TTItalic from '@tiptap/extension-italic';
import TTBold from '@tiptap/extension-bold';
import TTDocument from '@tiptap/extension-document';
import TTParagraph from '@tiptap/extension-paragraph';
import TTText from '@tiptap/extension-text';
import TTPlaceholder from '@tiptap/extension-placeholder';
import TTHistory from '@tiptap/extension-history';
import TTLink from '@tiptap/extension-link';
import TTHorizontalRule from '@tiptap/extension-horizontal-rule';

import { FontFamily } from './plugins/font-family';
import { FontSize } from './plugins/font-size';
import { Color } from './plugins/color';
import { Highlight } from './plugins/highlight';
import { LineHeight } from './plugins/line-height';

import { colorsPrimary, grayScale } from 'app/colors';
import { truncateCss } from 'app/typography';
import Button from 'components/Button';
import ColumnResizeIcon from 'components/Kizen/Icon/custom/ColumnResizeIcon';
import { MergeField, MergeFieldList } from './MergeFieldNode';
import { useWYSIWYGAIContext } from './ai-context';
import { renderWith } from './utils/suggestions';

const EditorContext = createContext({});
export const useEditor = () => useContext(EditorContext).editor;

const ExtendedLink = TTLink.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      'data-script-id': {
        default: null,
      },
    };
  },
});

const proseMirrorStyle = ({
  defaultFontFamily,
  defaultFontSize = '14px',
} = {}) => css`
  .ProseMirror {
    padding: 10px;
    outline: none;
    word-break: break-word;

    /* Reccomended layout CSS */
    > * + * {
      margin-top: 0;
    }

    /* Placeholder (at the top) */
    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: ${grayScale.medium};
      pointer-events: none;
      height: 0;
    }

    p * {
      line-height: inherit;
    }

    /* Set default font-family for now */
    font-family: ${defaultFontFamily};
    font-size: ${defaultFontSize};

    /*
    We need to make sure we inherit the font info, otherwise a
    nested <u> or <strong> will revert back the global settings we have
    */
    * {
      font-family: inherit;
      font-size: inherit;
      line-height: 1.5em;
    }

    &&& .mention {
      background: ${grayScale.white};
      position: relative;
      max-width: 250px;
      height: 24px;
      padding-left: 0px;
      padding-right: 0px;
      :focus {
        outline: none;
      }
      bottom: -2px;
      ${truncateCss}
    }
  }
`;

const StyledResizableBox = styled(ResizableBox)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ResizeIcon = styled(ColumnResizeIcon)`
  position: absolute;
  left: 50%;
  bottom: -16px;
  transform: rotate(90deg);
  cursor: ns-resize;

  &&,
  && svg {
    width: 3px;
    height: 30px;
  }
`;

const BottomBarWrapper = styled.div`
  border-top: 1px solid ${grayScale.medium};
`;

const StyledEditorContent = styled(EditorContent)`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  ${({ blurContent }) =>
    blurContent &&
    css`
      filter: blur(2px);
    `}

  ${({ enableResize, initialHeight = 200 }) =>
    !enableResize &&
    css`
      height: ${initialHeight}px;
    `}
`;

const EditorWrapper = styled.div`
  ${proseMirrorStyle}
  border: 1px solid ${grayScale.medium};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: white;

  ${({ hasLoadingState }) =>
    hasLoadingState &&
    css`
      position: relative;
      container-type: inline-size;
    `}

  .ProseMirror {
    height: 100%;

    ${({ fitToContent }) =>
      !fitToContent &&
      css`
        overflow: auto;
      `}

    ${({ isLoading }) =>
      isLoading &&
      css`
        min-height: 120px;
      `}
  }
`;

const FloatingEditorWrapper = styled.div`
  ${proseMirrorStyle}

  ${({ hasLoadingState }) =>
    hasLoadingState &&
    css`
      position: relative;
      container-type: inline-size;
    `}

  .ProseMirror {
    background: transparent;
    border-radius: 0;
    ${({ showBorder }) =>
      showBorder
        ? css`
            border: 1px solid ${colorsPrimary.blue.dark};
          `
        : css`
            border: none;
          `}

    ${({ isLoading }) =>
      isLoading &&
      css`
        min-height: 120px;
      `}
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const EditorContainer = forwardRef(
  (
    {
      floating,
      fitToContent,
      showBorder,
      initialHeight,
      enableResize,
      hasLoadingState = false,
      isLoading = false,
      children,
      ...rest
    },
    ref
  ) => {
    return floating ? (
      <FloatingEditorWrapper
        ref={ref}
        fitToContent
        showBorder={showBorder}
        hasLoadingState={hasLoadingState}
        isLoading={isLoading}
        {...rest}
      >
        {children}
      </FloatingEditorWrapper>
    ) : (
      <EditorWrapper
        ref={ref}
        fitToContent={fitToContent}
        enableResize={enableResize}
        initialHeight={initialHeight}
        hasLoadingState={hasLoadingState}
        isLoading={isLoading}
        {...rest}
      >
        {!enableResize || fitToContent ? (
          children
        ) : (
          <StyledResizableBox
            axis="y"
            resizeHandles={['n', 's']}
            height={initialHeight}
            minConstraints={[10, Math.min(initialHeight || 100, 100)]}
            handle={
              <ResizeIcon icon="column-resize" color={grayScale.mediumDark} />
            }
          >
            {children}
          </StyledResizableBox>
        )}
      </EditorWrapper>
    );
  }
);

/*
 * hook to allow an optional callback to never
 * change locally but to ref to the latest
 */
const usePropCallback = (maybeCallbackProp) => {
  const ref = useRef(maybeCallbackProp);
  ref.current = maybeCallbackProp;
  // This will never change
  return useCallback((val) => {
    if (ref.current) {
      ref.current(val);
    }
  }, []);
};

export const WYSIWYG = forwardRef(
  (
    {
      onInit,
      onChange,
      onFocus,
      onBlur,
      children,

      mentionConfig = null,

      initialValue = '',
      initialHeight = 200,

      defaultFontFamily,
      defaultFontSize,
      defaultLineHeight,

      autofocus = false,
      fitToContent = false,
      floating = false,
      showBorder = false,
      zIndex,
      bottomBarNode,

      placeholder,

      enableResize = true,
      enableAI = false,
      enableTextStyles = false,
      enableTextAlign = false,
      enableLists = false,
      enableFontFamily = false,
      enableFontSize = false,
      enableLineHeight = false,
      enableColor = false,
      enableHighlight = false,
      enableLinks = false,
      enableMergeFields = false,
      enableHorizontalRule = false,
      disabled = false,
      mergeFields = null,
    },
    ref
  ) => {
    const containerRef = useRef();
    const { t } = useTranslation();
    const onChangeCB = usePropCallback(onChange);
    const onFocusCB = usePropCallback(onFocus);
    const onBlurCB = usePropCallback(onBlur);
    const onInitCB = usePropCallback(onInit);
    const {
      isLoading: isLoadingAIContent,
      setShowDismissModal,
      cancelRequest,
    } = useWYSIWYGAIContext();
    const editorConfigDeps = [defaultLineHeight, placeholder, disabled];
    const editorConfig = useMemo(() => {
      return {
        onUpdate: onChangeCB,
        onFocus: onFocusCB,
        onBlur: onBlurCB,
        onCreate: onInitCB,
        extensions: [
          // Required defaults
          TTDocument,
          TTText,
          TTParagraph,
          TTTextStyle,
          TTHistory,

          placeholder && TTPlaceholder.configure({ placeholder }),

          enableFontFamily && FontFamily,
          enableFontSize && FontSize,
          enableLineHeight &&
            LineHeight.configure({ defaultValue: defaultLineHeight }),

          enableTextStyles && TTBold,
          enableTextStyles && TTItalic,
          enableTextStyles && TTUnderline,
          enableTextStyles && TTStrike,
          enableTextStyles && enableColor && Color,
          enableTextStyles && enableHighlight && Highlight,

          enableLists && TTListItem,
          enableLists && TTOrderedList,
          enableLists && TTBulletList,
          enableHorizontalRule && TTHorizontalRule,

          enableTextAlign && TTTextAlign.configure({ types: ['paragraph'] }),

          enableLinks &&
            ExtendedLink.configure({
              openOnClick: false,
              HTMLAttributes: {
                target: null, // default is _blank which doesn't let us turn it off in the builder - setting null here fixes that.
              },
            }),

          mentionConfig !== null &&
            mentionConfig.extension.configure(mentionConfig.configure),
          enableMergeFields &&
            MergeField.configure({
              suggestion: {
                render: renderWith(MergeFieldList, {
                  tippyConfig: { zIndex },
                  ...(mergeFields
                    ? { props: { mergeFields, listHeaders: false } }
                    : {}),
                }),
              },
            }),
        ].filter(Boolean),
        content: initialValue,
        autofocus,
        editable: !disabled,
      };
    }, editorConfigDeps); // eslint-disable-line react-hooks/exhaustive-deps

    const editor = useTipTapEditor(editorConfig, editorConfigDeps);
    const editorRef = useRef(editor);
    editorRef.current = editor;

    useImperativeHandle(
      ref,
      () => ({
        editor,
        isEmpty: () => (editor ? editor.getText().length === 0 : true),
        containerRef,
      }),
      [editor, containerRef]
    );

    useClickAway(containerRef, () => {
      if (isLoadingAIContent) {
        setShowDismissModal(true);
      }
    });

    return (
      <EditorContext.Provider value={{ editor }}>
        {children}
        <EditorContainer
          ref={containerRef}
          fitToContent={fitToContent}
          floating={floating}
          showBorder={showBorder}
          enableResize={enableResize}
          initialHeight={initialHeight}
          defaultFontFamily={defaultFontFamily}
          defaultFontSize={defaultFontSize}
          hasLoadingState={enableAI}
          isLoading={isLoadingAIContent}
        >
          <StyledEditorContent
            editor={editor}
            enableResize={enableResize}
            initialHeight={initialHeight}
            blurContent={isLoadingAIContent}
          />
          {bottomBarNode && (
            <BottomBarWrapper>{bottomBarNode}</BottomBarWrapper>
          )}
          {isLoadingAIContent && (
            <LoadingContainer className="wysiwyg-loading-container">
              <ClipLoader color={grayScale.dark} />
              <Button
                variant="light"
                color="blue"
                onClick={(ev) => {
                  ev.stopPropagation();
                  cancelRequest();
                }}
              >
                {t('Cancel AI Processing')}
              </Button>
            </LoadingContainer>
          )}
        </EditorContainer>
      </EditorContext.Provider>
    );
  }
);

WYSIWYG.displayName = 'WYSIWYG';
WYSIWYG.propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  mentionConfig: PropTypes.object,

  autofocus: PropTypes.bool,
  fitToContent: PropTypes.bool,

  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onInit: PropTypes.func,

  enableTextStyles: PropTypes.bool,
  enableTextAlign: PropTypes.bool,
  enableLists: PropTypes.bool,

  enableHorizontalRule: PropTypes.bool,
};
