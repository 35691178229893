import css from '@emotion/css';
import styled from '@emotion/styled';
import Loader from 'components/Kizen/Loader';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import { fadeIn } from 'app/animations';
import { grayScale, shadow } from 'app/colors';
import { borderRadii, hideScrollbarCss } from 'app/spacing';
import { fontWeights, KizenTypography } from 'app/typography';
import {
  ExpandableMonthCalendarCell,
  MonthCalendarCellHeader,
} from 'components/Calendar';
import IconButton from 'components/Kizen/IconButton';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import EventPill from 'components/Kizen/EventPill';
import {
  COLLAPSED_HEADER_HEIGHT,
  EXPANDED_HEADER_HEIGHT,
  TRANSITION_DURATION,
} from './constants';

export const StyledCalendarPill = styled(EventPill)`
  margin-bottom: 4px;
  min-height: 21px;
  cursor: pointer;
  background-color: ${grayScale.white};
`;

export const EventsContainer = styled.div`
  height: 100%;
  ${({ expanded, rowHeight, expansion }) =>
    expanded
      ? css`
          max-height: ${rowHeight +
          expansion[1] -
          EXPANDED_HEADER_HEIGHT +
          10}px;
          padding-bottom: 10px;
        `
      : css`
          max-height: ${rowHeight - COLLAPSED_HEADER_HEIGHT}px;
          padding-bottom: 10px;
        `}
`;

export const EventsScrollContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  overflow: auto;
  height: 99%;
  width: calc(100% - 9px);
  flex-shrink: 0;
  padding-left: 5px;

  ${({ expanded }) =>
    expanded &&
    css`
      padding-left: 10px;
    `}

  > div {
    width: 100%;
  }
`;

export const EventsScrollContainerWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const MonthCellHeader = styled(MonthCalendarCellHeader)`
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  ${({ expanded }) =>
    expanded &&
    css`
      opacity: 0;
      transition: opacity ${TRANSITION_DURATION}ms;
    `}
`;

export const SelectableDateCell = styled(ExpandableMonthCalendarCell)`
  ${({ skipped }) =>
    skipped
      ? css`
          background-image: repeating-linear-gradient(
            -35deg,
            transparent 10px,
            ${grayScale.medium} 11px,
            transparent 12px,
            transparent 20px
          );
          & > div > p {
            color: ${grayScale.mediumDark};
          }
        `
      : ''}
  ${({ expanded }) =>
    expanded &&
    css`
      ${shadow}
      border-radius: ${borderRadii.small};
    `}
`;

export const ExpandedMonthCellHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  animation: ${fadeIn} ${TRANSITION_DURATION}ms ease-in;
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const ExpandedDayOfMonth = styled(KizenTypography)`
  font-weight: ${fontWeights.bold};
  margin-top: 5px;
`;

export const StyledGradient = styled(Gradient)`
  z-index: 1;
`;

export const StyledCalendarLoader = styled(Loader)`
  margin-top: calc(-10% + 34px);
  position: absolute;
  z-index: 1;
`;
