import React, { useState } from 'react';
import MultiSelect, {
  UnderlinedMultiSelect,
} from 'components/Kizen/MultiSelect/';
import MOCK_TEAM_MEMBERS from 'services/MOCK_TEAM_MEMBERS';

const SelectList = () => {
  const [multiSelectValues, setMultiSelectValues] = useState([]);
  return (
    <MultiSelect
      fullWidth
      options={MOCK_TEAM_MEMBERS}
      values={multiSelectValues}
      onChange={setMultiSelectValues}
      placeholder="Default"
    />
  );
};

export const UnderlinedSelectList = () => {
  const [multiSelectValues, setMultiSelectValues] = useState([]);
  return (
    <UnderlinedMultiSelect
      label="MultiSelect Name"
      fullWidth
      options={MOCK_TEAM_MEMBERS}
      values={multiSelectValues}
      onChange={setMultiSelectValues}
      placeholder="Find Option"
    />
  );
};

export const UnderlinedTagList = () => {
  const [multiSelectValues, setMultiSelectValues] = useState([]);
  return (
    <UnderlinedMultiSelect
      label="Tags"
      fullWidth
      variant="tag"
      options={MOCK_TEAM_MEMBERS}
      values={multiSelectValues}
      onChange={setMultiSelectValues}
      placeholder="Find Options"
    />
  );
};

export default SelectList;
