import {
  DISPLAY_VALUE_RESPONSES,
  REPORT_NAMES,
  AREA_RESPONSES,
  REPORT_TYPES,
  RECORD_INCLUDED_NAMES,
  VALUE_TO_DISPLAY_RESPONSES,
  LEVEL_OF_DETAIL_OPTIONS,
  LEVEL_OF_DETAIL_NAMES,
  DATAPOINT_FREQUENCY_REPONSES,
} from 'components/Wizards/Dashlet/types';
import {
  AREA_RESPONSES as RTDV_AREA_RESPONSES,
  VALUE_RESPONSES as RTDV_VALUE_RESPONSES,
  SALES_REPORT_TYPES,
} from 'components/Wizards/RTDV/types';
import { FIELD_TYPES } from 'utility/constants';

export const allowedNumberFieldTypes = [
  FIELD_TYPES.Integer.type,
  FIELD_TYPES.Decimal.type,
  FIELD_TYPES.Money.type,
  FIELD_TYPES.Price.type,
];

export const canChooseDisplayValueTypes = [
  ...allowedNumberFieldTypes,
  FIELD_TYPES.Rating.type,
];

export const allowedFieldTypesWithOptions = [
  FIELD_TYPES.Dropdown.type,
  FIELD_TYPES.Radio.type,
  FIELD_TYPES.YesNo.type,
  FIELD_TYPES.YesNoMaybe.type,
  FIELD_TYPES.Rating.type,
  FIELD_TYPES.Status.type,
  FIELD_TYPES.TeamSelector.type,
  FIELD_TYPES.Checkboxes.type,
  FIELD_TYPES.DynamicTags.type,
];

export const entitiesFieldTypes = [FIELD_TYPES.Relationship.type];

export const simpleFieldTypes = [FIELD_TYPES.Checkbox.type];

// Only certain field types are allowed for now. In the future, this will probably
// make more sense as an exclusion list versus an inclusion list
export const allowedFieldTypes = [
  ...allowedNumberFieldTypes,
  ...allowedFieldTypesWithOptions,
  ...simpleFieldTypes,
  ...entitiesFieldTypes,
];

export const BUCKETING_TYPES = {
  NUMBER: 'number',
  OPTIONS: 'options',
  ENTITIES: 'entities',
  NONE: 'none',
};

export const activityReportTypes = [
  {
    label: 'Scheduled Activities',
    value: REPORT_TYPES.SCHEDULED_ACTIVITIES,
  },
];

const getValueToDisplay = (valueToDisplay, t) => {
  return valueToDisplay?.value === VALUE_TO_DISPLAY_RESPONSES.TOTAL
    ? t('Total Value')
    : t('Weighted Value');
};

const getValueText = (value, valueToDisplay, t) => {
  if (value?.value === DISPLAY_VALUE_RESPONSES.RECORDS) {
    return t('# Records');
  }
  if (valueToDisplay?.value === VALUE_TO_DISPLAY_RESPONSES.TOTAL) {
    return t('Total Value');
  }
  return t('Weighted Value');
};

const getFrequencyText = (frequency, t) => {
  if (frequency?.value === DATAPOINT_FREQUENCY_REPONSES.WEEKLY) {
    return t('Week');
  }
  if (frequency?.value === DATAPOINT_FREQUENCY_REPONSES.MONTHLY) {
    return t('Month');
  }
  if (frequency?.value === DATAPOINT_FREQUENCY_REPONSES.QUARTERLY) {
    return t('Quarter');
  }
  if (frequency?.value === DATAPOINT_FREQUENCY_REPONSES.YEARLY) {
    return t('Year');
  }
  return '';
};

export const getDefaultName = (
  {
    area,
    value,
    pipelineDetail,
    reportType,
    currencySymbol,
    isTrendDisplay,
    includedRecords,
    valueToDisplay,
    selectedActivity,
    levelOfDetail,
    field,
    aggreateFieldName,
    objectForCharts,
    salesReportType,
    datapointFrequency,
    pivotTableRows,
    pivotTableColumns,
    pivotTableRowField,
    pivotTableColumnField,
    rowCustomLabels,
    metric,
    metricField,
  },
  t
) => {
  if (reportType?.value === RTDV_AREA_RESPONSES.PIVOT_TABLE) {
    const hasRowsDefined = pivotTableRows?.[0] || pivotTableRowField;
    const hasColumnsDefined = pivotTableColumns?.[0] || pivotTableColumnField;
    const rowFieldId = pivotTableRowField?.id || pivotTableRows?.[0]?.id;
    const columnFieldLabel =
      pivotTableColumns?.[0]?.label || pivotTableColumnField?.displayName;

    let aggregateLabel =
      metric?.value !== 'record_count' && metricField?.label
        ? metricField?.label
        : '# Records';
    if (aggreateFieldName) {
      aggregateLabel = aggreateFieldName;
    }
    const rowFieldLabel =
      rowCustomLabels?.[rowFieldId] ||
      pivotTableRows?.[0]?.label ||
      pivotTableRowField?.displayName;

    return `${t('Pivot Table')}${
      hasRowsDefined && hasColumnsDefined
        ? ` - ${rowFieldLabel} ${t('vs.')} ${columnFieldLabel} ${t(
            'by'
          )} ${aggregateLabel}`
        : ''
    }`;
  }
  if (reportType?.value === RTDV_AREA_RESPONSES.SALES) {
    if (
      salesReportType?.value ===
      SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME
    ) {
      return t('Projected {{entityName}} Records Won by {{range}}', {
        entityName: objectForCharts?.entityName ?? '',
        range: getFrequencyText(datapointFrequency, t),
      });
    }
  }
  if (reportType?.value === RTDV_AREA_RESPONSES.TABLE) {
    return t('Table of {{entityName}} Records', {
      entityName: objectForCharts?.entityName ?? '',
    });
  }
  if (reportType?.value === RTDV_AREA_RESPONSES.METRICS) {
    if (value?.value === RTDV_VALUE_RESPONSES.SUM) {
      return t('Sum of {{fieldName}} Values', {
        fieldName: field?.label,
      });
    }
    if (value?.value === RTDV_VALUE_RESPONSES.AVERAGE) {
      return t('Average of {{fieldName}} Values', {
        fieldName: field?.label,
      });
    }
    if (value?.value === RTDV_VALUE_RESPONSES.BREAKDOWN) {
      return t('{{fieldName}} Value Breakdown', {
        fieldName: field?.label,
      });
    }
  }

  if (area === AREA_RESPONSES.PIPELINES) {
    if (reportType?.value === REPORT_TYPES.OPPORTUNITY_CONVERSION) {
      if (value?.value === DISPLAY_VALUE_RESPONSES.RECORDS) {
        return `${t('Opportunity Conversion')} - # ${
          pipelineDetail?.entityName
        } ${t('Records in')} ${pipelineDetail?.objectName ?? ''}`;
      }
      return `${t('Opportunity Conversion')} - ${currencySymbol ?? ''} ${t(
        'Value of'
      )} ${pipelineDetail?.objectName ?? ''}`;
    }
    if (
      reportType?.value === REPORT_TYPES.RECORDS_ADDED ||
      reportType?.value === REPORT_TYPES.RECORDS_LOST ||
      reportType?.value === REPORT_TYPES.RECORDS_WON ||
      reportType?.value === REPORT_TYPES.RECORDS_DISQUALIFIED
    ) {
      if (value?.value === DISPLAY_VALUE_RESPONSES.RECORDS) {
        return `${
          pipelineDetail?.objectName
        } - # ${pipelineDetail?.entityName ?? ''} ${t(
          'Records'
        )} ${REPORT_NAMES[reportType?.value]?.(t) ?? ''}${
          isTrendDisplay ? ` ${t('Over Time')}` : ''
        }`;
      }
      return `${pipelineDetail?.objectName} - ${currencySymbol} ${t(
        'Value'
      )} ${REPORT_NAMES[reportType?.value]?.(t) ?? ''}${
        isTrendDisplay ? ` ${t('Over Time')}` : ''
      }`;
    }
    if (reportType?.value === REPORT_TYPES.VALUE_OVER_TIME) {
      return `${pipelineDetail?.objectName ?? ''} - ${
        RECORD_INCLUDED_NAMES[includedRecords?.value]?.(t) ?? ''
      } ${getValueToDisplay(valueToDisplay, t)} ${t('Over Time')} `;
    }
    if (reportType?.value === REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS) {
      return `${pipelineDetail?.objectName} - ${
        value?.value === DISPLAY_VALUE_RESPONSES.VALUE
          ? `${currencySymbol} ${t('Value')}`
          : t('# Records')
      } ${t('Leaderboard with Projections')}`;
    }
    if (reportType?.value === REPORT_TYPES.STAGES_OVER_TIME) {
      return `${pipelineDetail?.objectName} - ${
        LEVEL_OF_DETAIL_NAMES[levelOfDetail?.value]?.(t) ?? ''
      } ${getValueText(value, valueToDisplay, t)}${
        levelOfDetail?.value === LEVEL_OF_DETAIL_OPTIONS.STAGE_BREAKDOWN
          ? ` ${t('Breakdown')}`
          : ''
      } ${t('Over Time')}`;
    }
  }
  if (area === AREA_RESPONSES.ACTIVITIES) {
    if (reportType?.value === REPORT_TYPES.SCHEDULED_ACTIVITIES) {
      return t('Scheduled Activities');
    }
    if (reportType?.value === REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS) {
      return `${selectedActivity?.label ?? ''} - ${t('Total Submissions')}${
        isTrendDisplay ? ` ${t('Over Time')}` : ''
      }`;
    }
  }
  if (area === AREA_RESPONSES.EMAILS) {
    if (reportType?.value === REPORT_TYPES.EMAILS_SENT) {
      return `${t('Emails Sent')}${isTrendDisplay ? ` ${t('Over Time')}` : ''}`;
    }
    if (reportType?.value === REPORT_TYPES.DELIVERY_PERCENT) {
      return `${t('Email Delivery %')}${
        isTrendDisplay ? ` ${t('Over Time')}` : ''
      }`;
    }
    if (reportType?.value === REPORT_TYPES.COMPLAINT_PERCENT) {
      return `${t('Email Complaint %')}${
        isTrendDisplay ? ` ${t('Over Time')}` : ''
      }`;
    }
    if (reportType?.value === REPORT_TYPES.OPT_OUT_PERCENT) {
      return `${t('Email Opt Out %')}${
        isTrendDisplay ? ` ${t('Over Time')}` : ''
      }`;
    }
    if (reportType?.value === REPORT_TYPES.INTERACTION_STATS) {
      return t('Email Interaction Stats');
    }
  }
  if (area === AREA_RESPONSES.MARKETING) {
    if (reportType?.value === REPORT_TYPES.LEADS_ADDED_BY_SOURCE) {
      return `${
        value?.value === DISPLAY_VALUE_RESPONSES.VALUE
          ? `${currencySymbol} ${t('Value')} `
          : ''
      }${t('Leads Added by Source')}${
        isTrendDisplay ? ` ${t('Over Time')}` : ''
      }`;
    }
    if (reportType?.value === REPORT_TYPES.LEADS_ADDED) {
      return `${
        value?.value === DISPLAY_VALUE_RESPONSES.VALUE
          ? `${currencySymbol} ${t('Value')} `
          : ''
      }${t('Leads Added')}${isTrendDisplay ? ` ${t('Over Time')}` : ''}`;
    }
    if (reportType?.value === REPORT_TYPES.LEAD_SOURCE_BREAKDOWN) {
      return t('Lead Sources Breakdown Over Time');
    }
  }
  return '';
};

export const CONTEXTS = {
  objects: 'objects',
  fields: 'fields',
  fieldsLayout: 'fieldsLayout',
};
