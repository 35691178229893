import React, { useState, useRef } from 'react';
import Overlay from 'react-bootstrap2/Overlay';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { grayScale } from 'app/colors';
import { StyledLabelWrapper, StyledLabel } from '../Input/styles';
import ColorPicker, { RedX } from '../ColorPicker';
import { popperModifiers } from 'components/helpers';

const SKETCH_PICKER_HEIGHT = 297;
const SWATCH_HEIGHT_MINI = 24;
const SWATCH_HEIGHT_NORMAL = 36;

const isTransparent = (rgba) =>
  typeof rgba === 'string' &&
  rgba.startsWith('rgba(') &&
  rgba[rgba.length - 2] === '0';

const StyledSwatchContainer = styled.div`
  position: relative;
`;

export const StyledSwatch = styled.div`
  display: flex;
  align-items: center;
  ${({ bgColor, mini = false }) => css`
    background-color: ${bgColor};
    height: ${mini ? SWATCH_HEIGHT_MINI : SWATCH_HEIGHT_NORMAL}px;
    width: ${mini ? SWATCH_HEIGHT_MINI : SWATCH_HEIGHT_NORMAL}px;
  `}
  border: 1px solid ${grayScale.medium};
  padding: 0;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
`;

const StyledSwatchInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OverlayContainer = ({
  enableOverlay,
  target,
  mini,
  zIndex,
  children,
}) => {
  if (!enableOverlay || !target) return children;

  const rect = target.getBoundingClientRect();
  const isOffscreen = rect.bottom + SKETCH_PICKER_HEIGHT > window.innerHeight;
  const swatchHeight = mini ? SWATCH_HEIGHT_MINI : SWATCH_HEIGHT_NORMAL;
  const translateY = isOffscreen ? -(SKETCH_PICKER_HEIGHT + swatchHeight) : 0;

  return (
    <Overlay
      show
      transition={false}
      placement="bottom-start"
      target={target}
      popperConfig={{
        modifiers: [popperModifiers.addStyles({ zIndex })],
      }}
    >
      <div>
        <div style={{ transform: `translateY(${translateY}px)` }}>
          {children}
        </div>
      </div>
    </Overlay>
  );
};

const ColorPickerInput = ({
  color,
  onChange,
  onClose,
  className,
  zIndex,
  label = null,
  id = '',
  mini = false,
  asOverlay = false,
  align = null,
  onOpen,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const ref = useRef();

  return (
    <div className={className} ref={ref}>
      <StyledLabelWrapper htmlFor={id}>
        {label && <StyledLabel>{label}</StyledLabel>}
        <StyledSwatchInputWrapper>
          <StyledSwatchContainer>
            <StyledSwatch
              className="styled-swatch"
              bgColor={color}
              onClick={() => {
                setShowColorPicker((showColorPicker) => {
                  const result = !showColorPicker;
                  if (result) {
                    onOpen?.();
                  }

                  return result;
                });
              }}
              mini={mini}
            >
              {isTransparent(color) && (
                <RedX width={36} height={36} scale={1.15} />
              )}
            </StyledSwatch>
            {showColorPicker && (
              <OverlayContainer
                enableOverlay={asOverlay}
                target={ref.current}
                zIndex={zIndex}
                mini={mini}
              >
                <ColorPicker
                  align={align}
                  color={color}
                  onChange={onChange}
                  clickAwayEvents={['mousedown']}
                  handleClose={() => {
                    if (onClose) onClose(color);
                    setShowColorPicker(false);
                  }}
                />
              </OverlayContainer>
            )}
          </StyledSwatchContainer>
        </StyledSwatchInputWrapper>
      </StyledLabelWrapper>
    </div>
  );
};

ColorPickerInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  mini: PropTypes.bool,
  align: PropTypes.oneOf(['center', 'right', null]),
  asOverlay: PropTypes.bool,
  zIndex: PropTypes.number,
};

export default ColorPickerInput;
