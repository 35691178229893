import React, {
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useRef,
} from 'react';
import Overlay from 'react-bootstrap2/Overlay';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { useBoundingRect } from 'hooks/useBoundingRect';
import { clamp } from 'utility/clamp';
import { popperModifiers } from 'components/helpers';

const GridLikeFlexContext = createContext({});

/**
 * Use within a child component of {@link GridLikeFlex} to obtain the positional props,
 * `isFirstColumn`, `isFirstRow`, and `isLastRow`.
 *
 * @remarks the DOM Rect values are rounded to make the calculation accurate when GridLikeFlex
 * is rendered in an overlay.
 */
export const useGridLikeFlexChildProps = () => {
  const { rect, borderWidth, paddingTop, paddingBottom, paddingLeft } =
    useContext(GridLikeFlexContext);
  const [ref, { top, bottom, left }] = useBoundingRect({ deps: [rect] });

  const props = useMemo(
    () => ({
      isFirstColumn:
        Math.round(rect.left) === Math.round(left) - borderWidth - paddingLeft,
      isFirstRow:
        Math.round(rect.top) === Math.round(top) - borderWidth - paddingTop,
      isLastRow:
        Math.round(rect.bottom) ===
        Math.round(bottom) + borderWidth + paddingBottom,
    }),
    [
      rect.left,
      rect.top,
      rect.bottom,
      top,
      bottom,
      left,
      borderWidth,
      paddingLeft,
      paddingTop,
      paddingBottom,
    ]
  );

  return [ref, props];
};

const useFlexContainerComponent = (MaybeComponent) =>
  useMemo(() => {
    return MaybeComponent
      ? forwardRef(({ children, ...rest }, ref) => (
          <MaybeComponent ref={ref} {...rest}>
            {children}
          </MaybeComponent>
        ))
      : forwardRef(({ children, ...rest }, ref) => (
          <div ref={ref} {...rest}>
            {children}
          </div>
        ));
  }, [MaybeComponent]);

const GridLikeFlexContainer = styled.div`
  ${({ showGridLines, isContainerAbsolute }) =>
    (showGridLines || isContainerAbsolute) &&
    css`
      position: relative;
    `}
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

const GridLinesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ paddingTop = 0, rowGap = 0 }) =>
    `translateY(${paddingTop - rowGap / 2}px)`};
  display: grid;
  grid-template-rows: ${({ rows = 1, rowHeight }) =>
    `repeat(${rows}, ${rowHeight}px)`};
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  row-gap: ${({ rowGap }) => `${rowGap}px`};
  pointer-events: none;
`;

const GridLinesChild = styled.div`
  ${({ idx, gridLineWidth, gridLineColor }) =>
    idx > 0 &&
    css`
      border-top: ${gridLineWidth}px solid ${gridLineColor};
    `}
`;

const GridLinesOverlay = ({
  width,
  height,
  rows,
  rowHeight,
  rowGap,
  paddingTop,
  gridLineWidth,
  gridLineColor,
}) => (
  <GridLinesContainer
    width={width}
    height={height}
    rows={rows}
    rowGap={rowGap}
    rowHeight={rowHeight}
    paddingTop={paddingTop}
  >
    {Array(rows)
      .fill(null)
      .map((_, idx) => (
        <GridLinesChild
          key={idx}
          idx={idx}
          gridLineWidth={gridLineWidth}
          gridLineColor={gridLineColor}
        />
      ))}
  </GridLinesContainer>
);

const MaybeOverlay = ({
  target,
  enableOverlay = false,
  zIndex = 0,
  children,
}) => {
  if (!enableOverlay) return children;

  return (
    <Overlay
      show
      transition={false}
      placement="top-start"
      target={target}
      popperConfig={{
        modifiers: [
          {
            name: 'preventOverflow',
            enabled: false,
          },
          popperModifiers.addStyles({ zIndex }),
        ],
      }}
    >
      {children}
    </Overlay>
  );
};

/**
 * A flex container with some useful grid properties. Specifically, determining whether or not a
 * child component is in the first or last row as well as the first column. Use the
 * {@link useGridLikeFlexChildProps} hook in child components to consume these grid flags.
 *
 * Horizontal grid lines can be shown when the flex items wrap by setting the `showGridLines`
 * prop. Their correct placement depends on the `rowHeight`, `rowGap`, and `paddingTop` values.
 * The grid lines width and color can be configured via the `gridLineWidth` and `gridLineColor` props.
 *
 * The flex container component can be styled by either making this a styled component
 * (`styled(GridLikeFlex)`) or by providing a component in the `Component` prop. The GridLikeFlex
 * context containing all the user supplied spacing values and the container's bounding rect will
 * be provided to the `Component` prop component in a `ctx` prop. Use the `Component` prop if you
 * need to style the container based on the current number of rows or the container bounding rect values.
 *
 * @remarks a child's position is determined by comparing the child and container's [bounding rects]
 * (https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect) of the container's.
 * Therefore, this component requires the `borderWidth`, `paddingTop`, `paddingLeft`, and `paddingRight`
 * props to make an accurate calculation (This component does NOT apply those styles to the container).
 * These values should match the container component's styles which are supplied by either making this a
 * `styled` component or by providing the container component in the `Component` prop.
 *
 * There is no flag to signify the last column. This could be done using a similar calculation as the first
 * column flag but only if all the children are set to be `flex-grow: 1`. It has been left unimplemented for now.
 *
 * @param {number} rowHeight - the height of a flex child
 * @param {number} rowGap - the pixel value the container's `row-gap` style if any (default 0)
 * @param {number} borderWidth - the container's border width (default 0)
 * @param {number} paddingTop - the container's top padding (default 0)
 * @param {number} paddingBottom - the container's bottom padding (default 0)
 * @param {number} paddingLeft - the container's left padding (default 0)
 * @param {number} gridLineWidth - the width in pixels of the grid lines (default 1)
 * @param {number} gridLineColor - the color of the gridlines (default #d8dde1)
 * @param {boolean} showGridLines - value to show or hide gridlines (default false)
 * @param {boolean} isContainerAbsolute - should be set when the wrapping component's styles have
 * `position: absolute` so a containing `position: relative` can be applied. Note: if also using
 * grid lines this prop is redundant since the gridlines are also positioned absolutely.
 * @param {ReactNode} Component - a react component to be used as the flex container. Receives
 * the GridLikeFlex context in a `ctx` prop.
 */
export const GridLikeFlex = ({
  children,
  rowHeight,
  rowGap = 0,
  borderWidth = 0,
  paddingTop = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  gridLineWidth = 1,
  gridLineColor = grayScale.medium,
  showGridLines = false,
  isContainerAbsolute = false,
  Component,
  ...rest
}) => {
  const [ref, rect] = useBoundingRect();
  const FlexContainer = useFlexContainerComponent(Component);

  const ctx = useMemo(() => {
    const rows =
      rect.height > 0
        ? Math.floor(
            clamp((rect.height + rowGap) / (rowHeight + rowGap), 0, Infinity)
          )
        : 1;

    return {
      rect,
      borderWidth,
      paddingTop,
      paddingBottom,
      paddingLeft,
      rowGap,
      rowHeight,
      rows,
    };
  }, [
    rect,
    borderWidth,
    paddingTop,
    paddingBottom,
    paddingLeft,
    rowGap,
    rowHeight,
  ]);

  return (
    <GridLikeFlexContext.Provider value={ctx}>
      <GridLikeFlexContainer
        showGridLines={showGridLines}
        isContainerAbsolute={isContainerAbsolute}
      >
        <FlexContainer ref={ref} ctx={Component ? ctx : undefined} {...rest}>
          {children}
          {showGridLines && (
            <GridLinesOverlay
              width={rect.width - borderWidth}
              height={rect.height}
              rows={ctx.rows}
              rowHeight={rowHeight}
              rowGap={rowGap}
              paddingTop={paddingTop}
              gridLineWidth={gridLineWidth}
              gridLineColor={gridLineColor}
            />
          )}
        </FlexContainer>
      </GridLikeFlexContainer>
    </GridLikeFlexContext.Provider>
  );
};

/**
 * Same as {@link GridLikeFlex} but will render inside of a popup via react-bootstrap's Overlay component.
 * Has the same props as GridLikeFlex as well as `target` (required) and `zIndex`.
 *
 * @remarks the element will be rendered 'normally' without an Overlay until bounding client rects
 * with good values are returned for both the target and flex container. At this point the number of
 * rows off the screen are calculated and the width is set (potentially larger than the target's width)
 * to accommodate a reduced number of rows if need be.
 *
 * This component's behavior is very specific to the use cases within the page builder. The Overlay
 * is expected to be rendered to the top of the target element and off-screen rows are only calculated
 * in the up direction.
 *
 * @param {*} props.target - the dom element for the Overlay to be positioned at.
 * @param {number} props.zIndex - z-index value passed to the Overlay component.
 * @param {number} props.maxRows - (optional) limit the upper bound of wrapping rows (content will overflow to the right)
 */
export const GridLikeFlexOverlay = ({
  children,
  rowHeight,
  rowGap = 0,
  borderWidth = 0,
  paddingTop = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  gridLineWidth = 1,
  gridLineColor = grayScale.medium,
  showGridLines = false,
  isContainerAbsolute = false,
  maxRows,
  Component,
  target,
  zIndex,
  dataQA,
  ...rest
}) => {
  const [ref, rect, boundingElem] = useBoundingRect();
  const adjustedWidth = useRef(null);
  const FlexContainer = useFlexContainerComponent(Component);

  const [rows, rowsOffScreen] = useMemo(() => {
    if (!target) return [1, 0];
    const targetRect = target.getBoundingClientRect();
    const total =
      rect.height > 0
        ? Math.floor(
            clamp((rect.height + rowGap) / (rowHeight + rowGap), 0, Infinity)
          )
        : 1;
    const height = total * (rowHeight + rowGap) + borderWidth * 2;
    const offscreen =
      height > targetRect.y
        ? Math.ceil((height - targetRect.y) / (rowHeight + rowGap))
        : 0;
    return [total, offscreen];
  }, [rect.height, target, rowHeight, rowGap, borderWidth]);

  const ctx = useMemo(() => {
    const availableRows = clamp(rows - rowsOffScreen, 1, Infinity);
    const usableRows =
      maxRows && availableRows > maxRows ? maxRows : availableRows;
    const requiresAdjustment =
      rowsOffScreen > 0 || usableRows !== availableRows;
    if (target && rect.height && rect.width) {
      if (requiresAdjustment && adjustedWidth.current > 0) {
        // if we have already calculated a width and there are still rows off screen, increase
        // the width by 10% until there are no rows off screen. This may happen because we are
        // not calculating the longest row. Depending on the legnth of each child and where
        // they are placed the first measure may be inaccurate.
        adjustedWidth.current = adjustedWidth.current * 1.1;
      } else if (requiresAdjustment) {
        const childrenWidth = Array.from(boundingElem.children).reduce(
          (acc, child, idx, arr) => {
            const { width } = child.getBoundingClientRect();
            return showGridLines && idx !== arr.length - 1 ? acc + width : acc;
          },
          0
        );
        const totalWidth = paddingLeft * usableRows + childrenWidth;
        adjustedWidth.current = totalWidth / usableRows;
      } else {
        adjustedWidth.current = rect.width;
      }
    }

    return {
      rect,
      borderWidth,
      paddingTop,
      paddingBottom,
      paddingLeft,
      rowGap,
      rowHeight,
      rows: usableRows,
    };
  }, [
    target,
    boundingElem,
    showGridLines,
    rect,
    borderWidth,
    paddingTop,
    paddingBottom,
    paddingLeft,
    rowGap,
    rowHeight,
    maxRows,
    rows,
    rowsOffScreen,
  ]);

  const style = useMemo(() => {
    const xtranslate = clamp(window.innerWidth - rect.right, -Infinity, 0);
    return { transform: `translateX(${xtranslate}px)` };
  }, [rect.right]);
  return (
    <GridLikeFlexContext.Provider value={ctx}>
      <MaybeOverlay
        enableOverlay={!!adjustedWidth.current}
        zIndex={zIndex}
        target={target}
      >
        <GridLikeFlexContainer
          width={adjustedWidth.current}
          showGridLines={showGridLines}
          isContainerAbsolute={isContainerAbsolute}
        >
          <FlexContainer
            // Only attach the bounding rect ref after there is a valid target element
            // for the overlay. This ensures the width/height measurements are accurate
            // when we perform the adjustedWidth calculation in the ctx useMemo. Without
            // this guard the first fiew width/height measurements are unusually small
            // when the target and GridLikeFlexOverlay are rendered at the same time.
            ref={target ? ref : undefined}
            ctx={Component ? ctx : undefined}
            style={style}
            data-qa={dataQA}
            {...rest}
          >
            {children}
            {showGridLines && (
              <GridLinesOverlay
                width={(adjustedWidth.current || ctx.rect.width) - borderWidth}
                height={ctx.rect.height}
                rows={ctx.rows}
                rowHeight={rowHeight}
                rowGap={rowGap}
                paddingTop={paddingTop}
                gridLineWidth={gridLineWidth}
                gridLineColor={gridLineColor}
              />
            )}
          </FlexContainer>
        </GridLikeFlexContainer>
      </MaybeOverlay>
    </GridLikeFlexContext.Provider>
  );
};
