export const webApplicationTrackerEmbed = (
  business,
  extra_script = ''
) => `<script>
  var config = { 
    business_id: "${business && business.id}", 
    track_clicks: true, 
    track_views: true 
  };
  
  (function(d,w,c,s,f,e,a){if(!w.KIZEN){w.KIZEN=f=function(){f.a.push([d.currentScript].concat([].slice.apply(arguments)))};f.a=[];f.c=c;e=d.createElement(s);e.async=!0;a=d.getElementsByTagName(s)[0];e.src="https://${
    import.meta.env.VITE_EMBED_FRONTEND_DOMAIN
  }/embed/kzn.js";a.parentNode.insertBefore(e,a)}})(document,window,config,"script");
${extra_script ? '\n  ' + extra_script + '\n' : ''}</script>`;
/* eslint-enable */
