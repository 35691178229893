import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import TitleField from '../../fields/title';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { gutters } from 'app/spacing';
import DatapointFrequencyField from '../../fields/datapointFrequency';
import {
  StyledHistorySwitch,
  StyledWizardField,
} from 'components/Wizards/shared/styles';
import Switch from 'components/Kizen/Switch';
import KizenTypography from 'app/kizentypo';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import { useTranslation } from 'react-i18next';

const PipelineValueOverTimeReportType = ({
  name,
  defaultName,
  onChangeName,
  top,
  mobile,
  active,
}) => {
  const { t } = useTranslation();
  const { state, setState, entityName } = useContext(
    WizardStateContext.Context
  );
  const { reportType, datapointFrequency, displayPercent } = state;

  const onChangeDatapointFrequency = useCallback(
    (value) => {
      return setState('datapointFrequency', value);
    },
    [setState]
  );

  const onChangeDisplayPercent = useCallback(
    (e) => {
      return setState('displayPercent', e.target.checked);
    },
    [setState]
  );

  useDefaultValues(
    {
      displayPercent: true,
    },
    active
  );

  // Hide self if the report type is not value over time
  if (reportType?.value !== REPORT_TYPES.VALUE_OVER_TIME) {
    return null;
  }

  return (
    <>
      <StyledHistorySwitch left={gutters.spacing(4)}>
        <StyledWizardField top={gutters.spacing(1, 4)}>
          <KizenTypography type="subheader">
            {t('Display % Change Over Time?')}
          </KizenTypography>
        </StyledWizardField>
        <Switch
          checked={displayPercent ?? false}
          onChange={onChangeDisplayPercent}
          className="history-switch"
        />
      </StyledHistorySwitch>
      <DatapointFrequencyField
        value={datapointFrequency}
        onChange={onChangeDatapointFrequency}
        mobile={mobile}
        active={active}
        top={gutters.spacing(2, 3)}
      />
      <TitleField
        name={name}
        defaultName={defaultName}
        onChangeName={onChangeName}
        top={gutters.spacing(6)}
        entityName={entityName}
      />
    </>
  );
};

PipelineValueOverTimeReportType.propTypes = {
  defaultName: PropTypes.string,
  name: PropTypes.string,
  onChangeName: PropTypes.func.isRequired,
  top: PropTypes.number,
};

PipelineValueOverTimeReportType.defaultProps = {
  defaultName: '',
  name: '',
  top: gutters.spacing(2),
};

export default PipelineValueOverTimeReportType;
