import { useCallback } from 'react';
import { usePermissionsContext } from './context';
import { addPermissionsForSection } from '../src/state-ops';
import { createFieldPermissionConfig, Field } from '../src/utils';
import type { PermissionsContext } from '../src/types';

const getFieldConfigs = (
  fields: Field[],
  customObjectId: string,
  ctx: PermissionsContext,
  opts: { enableRelatedFieldRule: boolean }
) => {
  const enableRelatedFieldRule = opts?.enableRelatedFieldRule ?? true;
  const existingValues = ctx.existing_custom_object_values?.[customObjectId];
  return fields.map((field) =>
    createFieldPermissionConfig(field, customObjectId, ctx, {
      defaultValue: existingValues?.fields?.[field.id],
      enableRelatedFieldRule,
    })
  );
};

export const useGetFieldConfigs = (enableRelatedFieldRule = true) => {
  const ctx = usePermissionsContext();

  return useCallback(
    (fields: Field[], customObjectId: string) => {
      return getFieldConfigs(fields, customObjectId, ctx, {
        enableRelatedFieldRule,
      });
    },
    [enableRelatedFieldRule, ctx]
  );
};

/**
 * Provides a useCallback hook to add contact or custom object field permissions to the permissions context.
 *
 * @remarks Rules (and default values) will be taken from the default_contact_fields and default_custom_object_fields
 * sections of the permissions context, if any. Otherwise, the default value and allowed_access is 'view' and
 * ['none', 'view', 'edit']. The related object rule will be applied to any field with `relation` data (see
 * `rule-presets.ts` in @kizen/permissions).
 */
export const useAddFieldPermissions = (enableRelatedFieldRule = true) => {
  const ctx = usePermissionsContext();

  return useCallback(
    (fields: Field[], customObjectId: string) => {
      const configs = getFieldConfigs(fields, customObjectId, ctx, {
        enableRelatedFieldRule,
      });
      addPermissionsForSection(configs, customObjectId, ctx);

      if (ctx.fields_loaded.has(customObjectId)) {
        ctx.fields_loaded.get(customObjectId)!.next(true);
      }
    },
    [enableRelatedFieldRule, ctx]
  );
};
