import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-use';
import { useSelector } from 'react-redux';

/**
 * (KZN-3249) There is a pre-release feature permission option that can be enabled for a permission group.
 *
 * PREREQUISITES:
 * 1. Be a part of a business with `business_type: internal_admin | partner` (defaults to `customer`)
 * 2. Be on permissions page URL with query param `?prerelease_features=true`
 */

const PRE_RELEASE_FEATURES_ALLOWED_BUSINESS_TYPES = [
  'internal_admin',
  'partner',
];

const useHiddenPreReleasePermission = () => {
  const { search } = useLocation();
  const hasUrlParameter = useMemo(() => {
    const params = new URLSearchParams(search || '');
    return params.get('prerelease_features') === 'true';
  }, [search]);

  const businessType = useSelector(
    (store) => store?.authentication?.chosenBusiness?.business_type
  );
  return (
    hasUrlParameter &&
    PRE_RELEASE_FEATURES_ALLOWED_BUSINESS_TYPES.includes(businessType)
  );
};

export const usePreReleaseFeaturePermissionConfig = () => {
  const { t } = useTranslation();
  const hasPreReleaseFeaturesEnabled = useHiddenPreReleasePermission();

  return useMemo(() => {
    return !hasPreReleaseFeaturesEnabled
      ? null
      : {
          affordance: 'checkbox',
          key: 'pre_release_features',
          label: t('Pre-release Features'),
          default: false,
          allowed_access: ['view'],
        };
  }, [hasPreReleaseFeaturesEnabled, t]);
};
