import { useCallback, useState } from 'react';
import { getOriginalError } from 'services/AxiosService';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useModalControl } from 'hooks/useModalControl';

export const useSharingAccessModal = (onSubmit, currentlyEditingTemplate) => {
  const [errors, setErrors] = useState(EMPTY_OBJECT);
  const [isOpen, { showModal, hideModal }] = useModalControl();

  const handleHide = useCallback(() => {
    hideModal();
    setErrors(EMPTY_OBJECT);
    currentlyEditingTemplate.current = null;
  }, [hideModal, currentlyEditingTemplate]);

  const onConfirm = useCallback(
    async (...args) => {
      try {
        setErrors(EMPTY_OBJECT);
        await onSubmit(...args);
        hideModal();
      } catch (err) {
        let errors = getOriginalError(err) || EMPTY_OBJECT;
        if (Array.isArray(errors) && typeof errors[0] === 'string') {
          errors = { toast: errors[0] };
        }
        setErrors(errors);
      }
    },
    [onSubmit, hideModal]
  );

  return {
    sharingAccessModalProps: {
      show: isOpen,
      onHide: handleHide,
      onConfirm,
      showModal,
      errors,
    },
    onShowSharingAccessModal: showModal,
  };
};
