import { PLUGINS } from 'queries/query-keys';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  MutableRefObject,
} from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import PluginService from 'services/PluginService';
import { refreshPluginAppList } from 'store/authentication/authenticationAction';

interface FloatingFrameConfig {
  api_name: string;
  css?: string;
  default_position?: 'bottom-left' | 'bottom-right';
  event_scripts?: Record<string, string>;
  header_color?: string;
  header_text_color?: string;
  height?: number;
  html?: string;
  name: string;
  script?: string;
  title: string;
  type: 'script' | 'iframe' | 'html';
  width?: number;
  match?: Array<string>;
  ignore?: Array<string>;
  message_handler?: string;
}

interface RoutablePageConfig {
  api_name: string;
  callback?: string;
  css?: string;
  event_scripts?: Record<string, string>;
  html?: string;
  iframe_url?: string;
  name: string;
  script?: string;
  type: 'script' | 'iframe' | 'html';
}

interface DataAdornmentConfig {
  field_type: 'phonenumber';
  script: string;
  config: {
    icon: string;
    color: string;
    tooltip: string;
  };
}

export type FrameQuadrant =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

export type EmployeeConfigMap = {
  minimized?: boolean;
  position?: {
    left: number;
    top: number;
    quadrant?: FrameQuadrant;
    deltas?: {
      top: number;
      left: number;
      right: number;
      bottom: number;
    };
  };
};

type EmployeeConfig = Record<string, EmployeeConfigMap>;

interface AdditionalConfigParams {
  plugin_api_name: string;
  plugin_name: string;
  employee_config: EmployeeConfig;
  args: Record<string, any>;
}

export type FloatingFrame = FloatingFrameConfig & AdditionalConfigParams;
export type RoutablePage = RoutablePageConfig & AdditionalConfigParams;
export type DataAdornment = DataAdornmentConfig & AdditionalConfigParams;

type FloatingFrames = Array<FloatingFrame>;
type RoutablePages = Array<RoutablePage>;
type DataAdornments = Array<DataAdornment>;

interface DefaultConfig {
  floatingFrames: FloatingFrames;
  routablePages: RoutablePages;
  dataAdornments: DataAdornments;
}

type PluginConfig = Record<
  string,
  {
    floating_frames: FloatingFrames;
    routable_pages: RoutablePages;
    data_adornments: DataAdornments;
    name: string;
    employee_config: EmployeeConfig;
    business_config: Record<string, any>;
  }
>;

export const PluginContext = createContext<{
  isLoading: boolean;
  pluginNames: string[];
  floatingFrames: DefaultConfig['floatingFrames'];
  routablePages: DefaultConfig['routablePages'];
  dataAdornments: DefaultConfig['dataAdornments'];
  touchFloatingFrame: (
    frame: string,
    cb?: (arg: Array<string>) => void
  ) => void;
  floatingFrameOrder: string[];
  floatingFrameOffset: Record<string, number>;
  refetch: () => void;
  fullRefetch: () => void;
  pluginAPINamesToIds: Record<string, string>;
  hiddenByModal: boolean;
  terminators: MutableRefObject<Record<string, Array<() => void>>>;
  cleanupWorkers: () => void;
  plugins: PluginConfig;
}>({
  isLoading: true,
  pluginNames: [],
  floatingFrames: [],
  routablePages: [],
  dataAdornments: [],
  floatingFrameOrder: [],
  touchFloatingFrame: () => {},
  floatingFrameOffset: {},
  refetch: () => {},
  fullRefetch: () => {},
  pluginAPINamesToIds: {},
  hiddenByModal: false,
  terminators: { current: {} },
  cleanupWorkers: () => {},
  plugins: {},
});

const defaultConfig: DefaultConfig = {
  floatingFrames: [],
  routablePages: [],
  dataAdornments: [],
};

function pluginMapper<T>(config: T, apiName: string, plugin: any) {
  return {
    ...config,
    plugin_api_name: apiName,
    plugin_name: plugin.name,
    employee_config: plugin.employee_config,
    args: plugin.business_config,
  };
}

export const PluginContextWrapper = ({ children }: { children: any }) => {
  const pluginApps: Array<Record<string, string>> = useSelector(
    (s: any) => s?.authentication?.pluginApps
  );
  const [hiddenByModal, setHiddenByModal] = useState(false);
  const terminators = useRef<Record<string, Array<() => void>>>({});
  const dispatch = useDispatch<any>();

  const cleanupWorkers = useCallback(() => {
    Object.keys(terminators.current).forEach((key) => {
      terminators.current[key]?.forEach((fn) => fn());
      terminators.current[key] = [];
    });
  }, []);

  const pluginAPINamesToIds = useMemo(() => {
    return pluginApps?.reduce?.((acc, plugin) => {
      return {
        ...acc,
        [plugin.api_name]: plugin.id,
      };
    }, {});
  }, [pluginApps]);

  const [floatingFrameOrder, setFloatingFrameOrder] = useState<Array<string>>(
    []
  );

  const {
    data = {},
    isLoading,
    refetch,
  } = useQuery<PluginConfig>({
    queryKey: PLUGINS.LIST,
    queryFn: () => PluginService.get(),
    enabled: pluginApps?.length > 0,
  });

  const pluginNames = useMemo(() => {
    return Object.keys(data ?? {});
  }, [data]);

  const { floatingFrames, routablePages, dataAdornments } = useMemo(() => {
    return pluginNames.reduce((acc, pluginApiName) => {
      const plugin = data[pluginApiName];
      return {
        ...acc,
        floatingFrames: [
          ...acc.floatingFrames,
          ...(plugin.floating_frames?.map((config) =>
            pluginMapper(config, pluginApiName, plugin)
          ) ?? []),
        ],
        routablePages: [
          ...acc.routablePages,
          ...(plugin.routable_pages?.map((config) =>
            pluginMapper(config, pluginApiName, plugin)
          ) ?? []),
        ],
        dataAdornments: [
          ...acc.dataAdornments,
          ...(plugin.data_adornments?.map((config) =>
            pluginMapper(config, pluginApiName, plugin)
          ) ?? []),
        ],
      };
    }, defaultConfig);
  }, [data, pluginNames]);

  const touchFloatingFrame = useCallback(
    (id: string, cb?: (value: Array<string>) => void) => {
      setFloatingFrameOrder((prev) => {
        const res = prev.filter((i) => i !== id);
        const done = [id, ...res];
        cb?.(done);
        return done;
      });
    },
    []
  );

  const floatingFrameOffset = useMemo(() => {
    return [...floatingFrameOrder].reverse().reduce((acc, id, index) => {
      return {
        ...acc,
        [id]: index,
      };
    }, {});
  }, [floatingFrameOrder]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const modal = document.querySelector('div[role="dialog"]');
      if (modal) {
        setHiddenByModal(true);
      } else {
        setHiddenByModal(false);
      }
    });

    observer.observe(document.body, {
      childList: true,
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    return () => {
      cleanupWorkers();
    };
  }, [cleanupWorkers]);

  const fullRefetch = useCallback(async () => {
    const res = await refetch();
    await dispatch(refreshPluginAppList());

    return res;
  }, [refetch, dispatch]);

  return (
    <PluginContext.Provider
      value={{
        isLoading,
        pluginNames,
        floatingFrames: isLoading ? [] : floatingFrames,
        routablePages,
        dataAdornments,
        floatingFrameOrder,
        floatingFrameOffset,
        touchFloatingFrame,
        refetch,
        fullRefetch,
        pluginAPINamesToIds,
        hiddenByModal,
        terminators,
        cleanupWorkers,
        plugins: data,
      }}
    >
      {children}
    </PluginContext.Provider>
  );
};
