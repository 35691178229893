import SearchState from 'components/AccessRequests/searchState';
import Controller from './Controller';

const DashboardEditPage = () => {
  return (
    <SearchState.Wrapper>
      <Controller />
    </SearchState.Wrapper>
  );
};

export default DashboardEditPage;
