import { useEffect, useState } from 'react';

type TextUpdateData = {
  text: string;
  filterId?: string;
};

export const setCustomizeFieldBroadcastChannel = (
  id: string,
  receive: (fieldData: any) => void = () => {}
) => {
  const bc = new BroadcastChannel('builder_customize_field_' + id);
  bc.onmessage = ({ data }) => receive(data);
  return bc;
};

export const useTextUpdateBroadcastChannel = (
  onmessage?: (data: TextUpdateData) => void
) => {
  const [bc] = useState(new BroadcastChannel('builder_text_updated'));

  useEffect(() => {
    if (onmessage) {
      bc.onmessage = ({ data }) => onmessage(data);
    }
  }, [bc, onmessage]);

  useEffect(() => {
    return () => {
      bc.close();
    };
  }, [bc]);

  return bc;
};

export const TextUpdateChannelTypes = {
  UPDATE_NODE: 'update_node',
  UPDATE_SETTINGS: 'update_settings',
};

export const TextUpdateChannelEvents = {
  updateNode: (id: string) => ({
    type: TextUpdateChannelTypes.UPDATE_NODE,
    id,
  }),
  updateSettings: (text: string, filterId?: string) => ({
    type: TextUpdateChannelTypes.UPDATE_SETTINGS,
    text,
    filterId,
  }),
};
