import { useParams } from 'react-router-dom';
import {
  SubNavigationNavBar,
  SubNavigationNavLink,
} from 'components/Navigation/SubNavigation';
import { SUBROUTES_LINKS } from '../routes';

export const NavBar = () => {
  const { subpage } = useParams();
  return (
    <SubNavigationNavBar>
      {Object.entries(SUBROUTES_LINKS).map(([key, route], index, arr) => {
        return (
          <SubNavigationNavLink
            key={key}
            isLast={index === arr.length - 1}
            route={route}
            to={route.path}
            isActive={() => subpage === route.path}
          />
        );
      })}
    </SubNavigationNavBar>
  );
};
