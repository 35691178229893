import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { colorsPrimary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { TextSpan } from 'app/typography';
import { defaultEmployeeString } from '../helpers';
import { eventTypes, initiatorTypes } from '../../constants';
import KizenTypography from 'app/kizentypo';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { useSelector } from 'react-redux';
import SentMessageModalQuered from 'pages/ContactDetail/pages/Messages/MessageHistoryTable/SentMessageModalQuered';

const StyledTextLink = styled(KizenTypography)`
  overflow: visible;
  margin-right: -50px;
`;

const EmailComplainedBlock = ({ t }) => {
  return (
    <>
      <TextSpan size="micro">&nbsp;{t('and is now')}&nbsp;</TextSpan>
      <TextSpan weight="bold" size="micro">
        {t('Suppressed')}
      </TextSpan>
      <TextSpan size="micro">&nbsp;{t('from Emails')}&nbsp;</TextSpan>
    </>
  );
};

const EmailOpenedDeliveredBlock = ({ t, event, initiator, sentBy }) => {
  return (
    <>
      <TextSpan size="micro">&nbsp;{t('from')}&nbsp;</TextSpan>
      <TextSpan weight="bold" size="micro">
        {event.data.fromEmailAddress}
      </TextSpan>
      {initiator && (
        <>
          <TextSpan size="micro">&nbsp;{sentBy}&nbsp;</TextSpan>
          <TextSpan weight="bold" size="micro">
            {initiator}
          </TextSpan>
        </>
      )}
    </>
  );
};

const AttachOpenedDeliveredBlock = ({ t, event, initiator, sentBy }) => {
  return (
    <>
      <TextSpan size="micro">&nbsp;{t('from')}&nbsp;</TextSpan>
      <TextSpan weight="bold" size="micro">
        {event.data.fromEmailAddress}
      </TextSpan>
      {initiator && (
        <>
          <TextSpan size="micro">&nbsp;{sentBy}&nbsp;</TextSpan>
          <TextSpan weight="bold" size="micro">
            {initiator}
          </TextSpan>
        </>
      )}
    </>
  );
};

const EmailIntegratedReceivedBlock = ({ t, event }) => {
  return (
    <>
      <TextSpan size="micro">&nbsp;{t('to')}&nbsp;</TextSpan>
      <TextSpan weight="bold" size="micro">
        {event.data.toEmailAddress}
      </TextSpan>
    </>
  );
};

const EmailBouncedBlock = ({ t }) => {
  return (
    <>
      <TextSpan size="micro">&nbsp;{t('and is now')}&nbsp;</TextSpan>
      <TextSpan weight="bold" size="micro">
        {t('Suppressed')}
      </TextSpan>
      <TextSpan size="micro">&nbsp;{t('from Emails')}&nbsp;</TextSpan>
    </>
  );
};

const cardHeaderName = (t) => {
  return {
    [eventTypes.emailDelivered]: {
      name: t('Received Email'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.integratedInboxEmailSent]: {
      name: t('Received Email'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.integratedInboxEmailReceived]: {
      name: t('Sent Email'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.emailAttachmentOpened]: {
      name: t('Opened Attachment in Email'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.emailOpened]: {
      name: t('Opened Email'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.emailComplained]: {
      name: t('Complained'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.emailHardBounced]: {
      name: t('Hard Bounced'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.emailSoftBounced]: {
      name: t('Soft Bounced'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.emailLinkClicked]: {
      name: t('Clicked Link in Email'),
      creationType: defaultEmployeeString,
    },
  };
};

const cardHeaderConfig = (t) => ({
  [initiatorTypes.employee]: {
    sentBy: t('sent by'),
    creationType: defaultEmployeeString,
  },
  [initiatorTypes.automation]: {
    sentBy: t('sent via'),
    creationType: ({ name } = {}) =>
      `${t('Automation')}${name ? ` (${name})` : ''}`,
  },
  [initiatorTypes.connected_inbox]: {
    sentBy: (
      <>
        <TextSpan size="micro">{t('sent by')}</TextSpan>
        <TextSpan weight="bold" size="micro">
          &nbsp;{t('Kizen')}&nbsp;
        </TextSpan>
        <TextSpan size="micro">{t('via')}</TextSpan>
      </>
    ),
    creationType: (initiator) =>
      initiator.type === 'connected_inbox' ? t('Integrated Inbox') : '',
  },
});

const renderEmailBlock = ({ event, initiator, t, sentBy }) => {
  const {
    emailComplained,
    emailDelivered,
    emailOpened,
    emailHardBounced,
    emailSoftBounced,
    emailLinkClicked,
    integratedInboxEmailSent,
    integratedInboxEmailReceived,
    emailAttachmentOpened,
  } = eventTypes;
  const config = cardHeaderConfig(t);
  switch (event.typeName) {
    case emailComplained:
      return <EmailComplainedBlock t={t} />;
    case integratedInboxEmailReceived:
      return <EmailIntegratedReceivedBlock t={t} event={event} />;
    case integratedInboxEmailSent:
      return (
        <EmailOpenedDeliveredBlock
          t={t}
          initiator={
            config['connected_inbox']?.creationType(
              event.initiator,
              event,
              t
            ) || ''
          }
          event={event}
          sentBy={config['connected_inbox']?.sentBy || ''}
        />
      );
    case emailLinkClicked:
    case emailDelivered:
    case emailOpened:
      return (
        <EmailOpenedDeliveredBlock
          t={t}
          initiator={initiator}
          event={event}
          sentBy={sentBy}
        />
      );
    case emailAttachmentOpened:
      return (
        <AttachOpenedDeliveredBlock
          t={t}
          initiator={initiator}
          event={event}
          sentBy={sentBy}
        />
      );
    case emailHardBounced:
      return <EmailBouncedBlock t={t} />;
    case emailSoftBounced:
    default:
      return null;
  }
};

const ContactEmailInteractionEventBlock = ({ event, ...others }) => {
  const { t } = useTranslation();

  const headerName = cardHeaderName(t);
  const config = cardHeaderConfig(t);
  const [chosenMessage, setChosenMessage] = useState(null);
  const businessName = useSelector(
    ({ authentication }) => authentication?.chosenBusiness?.name ?? ''
  );
  const [name, sentBy, initiator] = [
    headerName[event.typeName]?.name || t('subscription list'),
    config[event.initiator.type]?.sentBy || '',
    config[event.initiator.type]?.creationType(event.initiator, event, t) || '',
  ];
  const hasEmailContent = Boolean(
    event.data.sentMessageId || event.data.syncedEmailId
  );

  return (
    <>
      <BasicBlock
        comments={event.comments}
        icon={
          <TypeIcon
            color={colorsPrimary.blue.dark}
            icon="envelope"
            size="16px"
          />
        }
        isDeletable={false}
        timestamp={event.created}
        title={
          <>
            <TextSpan size="micro">{t('Contact')}&nbsp;</TextSpan>
            <TextSpan weight="bold" size="micro">
              {name}
            </TextSpan>
            {renderEmailBlock({ event, initiator, t, sentBy })}
          </>
        }
        event={event}
        {...others}
      >
        <TextEllipsisWithTooltip weight="bold">
          {t('Subject')}
        </TextEllipsisWithTooltip>
        <TextEllipsisWithTooltip>{event.data.subject}</TextEllipsisWithTooltip>
        {hasEmailContent ? (
          <StyledTextLink
            type="link"
            onClick={() =>
              setChosenMessage({
                ...event.data,
                id: event.data.sentMessageId,
                type: 'email',
                sentAt: event.created,
              })
            }
          >
            {t('View Full Email')}
          </StyledTextLink>
        ) : (
          <div /> // empty div to fill second row and maintain spacing
        )}
      </BasicBlock>
      {chosenMessage && (
        <SentMessageModalQuered
          t={t}
          businessName={businessName}
          message={chosenMessage}
          onHide={() => setChosenMessage(null)}
        />
      )}
    </>
  );
};

ContactEmailInteractionEventBlock.propTypes = {
  event: PropTypes.shape({
    data: PropTypes.shape({
      contactId: PropTypes.string,
      from_EmailAddress: PropTypes.string,
      fromName: PropTypes.string,
      sentMessageId: PropTypes.string,
      subject: PropTypes.string,
    }),
    created: PropTypes.string,
    comments: PropTypes.any,
    typeName: PropTypes.string,
  }).isRequired,
};

export default ContactEmailInteractionEventBlock;
