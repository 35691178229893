import {
  BROADCAST_FORMDATA,
  defaultFilterConfig,
} from './components/ScheduleBroadcastModal/constants';
import {
  CALENDAR_EVENTS_OPTIONS,
  EVENT_ACTIONS,
  EVENT_STATUSES,
  EVENT_TYPES,
} from './constants';

export const getCalendarEventsToggleOptions = (t) => [
  { label: t('Upcoming'), value: CALENDAR_EVENTS_OPTIONS.upcoming },
  { label: t('Past'), value: CALENDAR_EVENTS_OPTIONS.past },
];

export const getEventActionOptions = (
  t,
  { view, edit, remove } = {},
  isPast = false,
  status
) =>
  (isPast
    ? [
        view &&
          status !== EVENT_STATUSES.failed && {
            value: EVENT_ACTIONS.stats,
            label: t('View Stats'),
            icon: 'chart-column',
            size: '14px',
          },
        edit && {
          value: EVENT_ACTIONS.duplicate,
          label: t('Duplicate'),
          icon: 'copy-light',
          size: '12px',
        },
      ]
    : [
        view && { value: EVENT_ACTIONS.edit, label: t('Edit') },
        edit && {
          value: EVENT_ACTIONS.duplicate,
          label: t('Duplicate'),
        },
        remove &&
          status !== EVENT_STATUSES.prestarted && {
            value: EVENT_ACTIONS.delete,
            label: t('Delete'),
          },
      ]
  ).filter(Boolean);

export const isEventTypeAvailable =
  (preReleaseFeature, access, isEdit, chosenType) =>
  ({ type, preRelease, accessKey }) =>
    type !== EVENT_TYPES.default.type &&
    (!preRelease || preReleaseFeature) &&
    (isEdit
      ? chosenType === type
      : isEdit === false
        ? access?.[accessKey]?.edit
        : access?.[accessKey]?.view);

const isBroadcastConfigInvalid = ({
  action,
  customObject,
  filterGroups,
  filterConfig,
}) => {
  const filterGroupInvalid = filterGroups.some(
    ({ deleted, invalid }) => deleted || invalid
  );

  return (
    action.deleted ||
    customObject.deleted ||
    filterGroupInvalid ||
    filterConfig.invalid ||
    (action.automations && action.automations.length === 0)
  );
};

export const isBroadcastInvalid = (broadcast) => {
  if (broadcast.status !== EVENT_STATUSES.scheduled) {
    // for past events we only care of status
    return broadcast.status === EVENT_STATUSES.failed;
  }

  return isBroadcastConfigInvalid(broadcast);
};

export const getEventDataQA = (event = {}, invalid = false) => {
  return {
    'data-qa-id': event.id,
    'data-qa-status': event.status,
    'data-qa-type': event.type,
    'data-qa-invalid': invalid,
  };
};

export const getEventAccess = (access, eventAccess) => {
  eventAccess ??= access;
  return {
    view: access.view && eventAccess,
    edit: access.edit && eventAccess.edit,
    remove: access.remove && eventAccess.remove,
  };
};

const duplicateBrodcastAction = ({
  [BROADCAST_FORMDATA.type]: type,
  [BROADCAST_FORMDATA.action]: action,
}) => {
  switch (type) {
    case EVENT_TYPES.automation.type: {
      return {
        ...action,
        automations:
          action.automations?.filter(
            ({ deleted, active }) => !deleted && active !== false
          ) || [],
      };
    }
    default:
      return action;
  }
};

export const duplicateBrodcast = (b) => ({
  ...b,
  id: undefined,
  [BROADCAST_FORMDATA.date]: null,
  [BROADCAST_FORMDATA.rescheduleAt]: null,
  [BROADCAST_FORMDATA.status]: undefined,
  ...(b.status === EVENT_STATUSES.failed ? defaultFilterConfig : {}),
  [BROADCAST_FORMDATA.action]: duplicateBrodcastAction(b),
});
