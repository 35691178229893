import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { APPLY_TEMPLATE_PARAM } from '../constants';

export const useApplyTemplateByParams = (onApply) => {
  const history = useHistory();
  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const id = searchParams.get(APPLY_TEMPLATE_PARAM);
    if (id) {
      history.replace(history.location.pathname);
      onApply(id);
    }
  }, [history, onApply]);
};
