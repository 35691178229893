import { createContext } from 'react';

/**
 * Values in the broadcast calendar context exist so we don't have to call `buildMonthCell` to pass values
 * from the parent BroadcastCalendar, losing the cell's expanded state. The main use case is to determine
 * when an expanded cell should close on click events. The cell should close when clicking outside of itself,
 * although we don't want to close it when interacting with any of the calendar's modals (which are controlled
 * by the parent).
 *
 * Note: all click events within the cell call stopPropagation so those events never reach the "auto close"
 * listener attached to window.
 */
const BroadcastCalendarContext = createContext({
  broadcastLookup: {},
  isCalendarModalOpen: false,
});

export default BroadcastCalendarContext;
