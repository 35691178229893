import { useCallback, useEffect, useMemo, useState } from 'react';
import { PageSizing } from 'components/Layout/PageContentWidth';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import useModal from 'components/Modals/useModal';
import Section from 'components/Wizards/CustomObject/components/Section';
import Notice from 'components/Kizen/Notice';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import TeamMemberService from 'services/TeamMemberService';
import MultiSelect from 'components/Inputs/MultiSelect';
import SelectAllButton from 'components/Inputs/MultiSelect/SelectAllButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import { useSelector } from 'react-redux';
import {
  getBusinessClientObject,
  getSettingsAccess,
} from 'store/authentication/selectors';
import useField from 'hooks/useField';
import Loader from 'components/Kizen/Loader';
import CustomObjectSectionCard from './CustomObjectSectionCard';
import GroupsSection from './GroupsSection';
import { layers } from 'app/spacing';
import { useAsyncFn } from 'react-use';
import {
  MultiSelectSection,
  NoticeSection,
  StyledBuilderCard,
  StyledCols,
  StyledOutlineLabel,
} from './styled';
import { toastVariant, useToast } from 'components/ToastProvider';
import { getOriginalError } from 'services/AxiosService';
import { invalidate } from 'queries/invalidate';
import { SingleObjectPermissionsContext } from '@kizen/permissions/hooks/context';
import {
  usePermissionsMetadata,
  useObjectsQuery,
  useContactFieldsQuery,
} from 'pages/Settings/pages/Team/PermissionGroupModal_V2/query';
import CustomBuilderCard from './CustomBuilderCard';
import { useCustomObjectWizard } from 'components/Wizards/CustomObject/CustomObjectWizardContext';
import ContactSectionCard from './ContactSectionCard';

export default function Permissions({
  label,
  updateStepField,
  objectContainsWorkflow,
  formData,
  isCreateMode,
  errors,
  setDirty,
  ...props
}) {
  const { isContact } = useCustomObjectWizard();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast] = useToast();
  const { id } = formData;
  const teamMember = useSelector((s) => s.authentication.teamMember || {});
  const [values, setValues] = useField(
    () => ({
      permissionGroups: [],
    }),
    [teamMember]
  );

  const {
    team: { permissionGroups: permissionGroupsAccess },
  } = useSelector(getSettingsAccess);

  const { t } = useTranslation();
  const { data: objects, isLoading: objectsLoading } = useObjectsQuery();

  const { data: permissionsMetadata, isLoading: metadataLoading } =
    usePermissionsMetadata();

  const { data: contactFields, isLoading: contactFieldsLoading } =
    useContactFieldsQuery();

  const clientObject = useSelector(getBusinessClientObject);

  const [
    { value: permissionGroupsWithoutMeta = [], loading },
    getPermissionGroups,
  ] = useAsyncFn(() => {
    return TeamMemberService.getPermissionGroupsEnabledSection({
      annotateMembership: true,
      annotateObjectSections: [id],
    });
  }, [id]);

  useEffect(() => {
    getPermissionGroups();
  }, [getPermissionGroups]);

  const [permissionGroups, permissionGroupsForList] = useMemo(() => {
    const permissionsData = permissionGroupsWithoutMeta.map((pg) => ({
      value: pg.id,
      label: pg.name,
      objectSections: pg.objectSections,
    }));
    const permissionGroups = permissionsData
      .filter(({ objectSections }) => !objectSections[0]?.enabled)
      .filter(({ value }) => {
        if (
          (permissionGroupsAccess.edit || permissionGroupsAccess.remove) &&
          permissionGroupsAccess.view
        ) {
          return true;
        } else {
          return teamMember.permission_groups.includes(value);
        }
      });

    const permissionGroupsList = permissionsData.filter(
      ({ objectSections }) => objectSections[0]?.enabled
    );
    return [permissionGroups, permissionGroupsList];
  }, [
    permissionGroupsAccess,
    permissionGroupsWithoutMeta,
    teamMember.permission_groups,
  ]);

  const isLoading = metadataLoading || objectsLoading || contactFieldsLoading;

  const [modalProps, , modal] = useModal();

  const updateOrCreatePermissionGroup = useCallback(
    async (updates) => {
      try {
        setIsSubmitting(true);
        let payload = [];
        if (isContact) {
          payload = updates.contacts_section;
        } else {
          payload = updates.custom_objects.filter(
            ({ custom_object_id }) => custom_object_id === formData.id
          );
        }
        const data = {
          permissionGroupIds: values.permissionGroups.map(({ value }) => value),
          permissions: {
            [isContact ? 'contacts_section' : 'custom_objects']: payload,
          },
        };
        await TeamMemberService.extendPermissionGroup(data);
        invalidate.PERMISSION_GROUP.GROUPS();
        setValues({
          permissionGroups: [],
        });
        await getPermissionGroups();
        showToast({
          variant: toastVariant.SUCCESS,
          delay: 3000,
          message:
            values.length === 1
              ? t('The Permission Group was successfully updated.')
              : t('The Permission Groups were successfully updated.'),
        });
      } catch (error) {
        const message = getOriginalError(error);
        const defaultMessage = t(
          'There was an error when trying to save. Please contact Kizen support for help with resolution.'
        );

        showToast({
          variant: toastVariant.FAILURE,
          delay: 3000,
          message: message?.errors
            ? message.errors.details ||
              Object.values(message.errors)?.[0] ||
              defaultMessage
            : defaultMessage,
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      isContact,
      formData.id,
      getPermissionGroups,
      setValues,
      showToast,
      t,
      values,
    ]
  );

  useEffect(() => {
    setDirty(!!values.permissionGroups?.length);
  }, [values.permissionGroups?.length, setDirty]);

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <PageSizing>
      <ContentWidth>
        <StyledCols columns={2} gutter={`${gutters.spacing(4)}px`}>
          <SingleObjectPermissionsContext
            translationFunc={t}
            permissionsConfig={permissionsMetadata}
            customObjects={[...objects, clientObject]}
            contactObjectId={clientObject.id}
            contactFields={contactFields}
          >
            <CustomBuilderCard
              {...props}
              label={t('Add Permissions')}
              applyButton
              onClick={updateOrCreatePermissionGroup}
              saving={isSubmitting}
              disabled={!values.permissionGroups.length}
            >
              <NoticeSection>
                <Notice>
                  <KizenTypography data-qa={`notification`}>
                    {t(
                      'By default, a new object will not be accessible by any permission group except the administrator. You must explicitly apply the permission set you create to specific permission groups of your choosing.'
                    )}
                  </KizenTypography>
                </Notice>
              </NoticeSection>
              <MultiSelectSection>
                <StyledOutlineLabel>
                  {t('Apply to the Following Permission Groups')}
                </StyledOutlineLabel>
                <MultiSelect
                  fullWidth
                  className="select-wrapper"
                  placeholder={t('Choose Permission Group(s)')}
                  options={permissionGroups}
                  value={values.permissionGroups}
                  onChange={(value) => {
                    setValues((prev) => ({
                      ...prev,
                      permissionGroups: value,
                    }));
                  }}
                  loadItems={loading}
                  menuTopButton={<SelectAllButton />}
                  menuLeftButton={<ClearSelectButton />}
                  menuRightButton={<ApplySelectButton />}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      zIndex: layers.modals(3, 1),
                    }),
                  }}
                />
              </MultiSelectSection>
              <>
                <StyledOutlineLabel>
                  {t('Apply the Following Permission Set')}
                </StyledOutlineLabel>
                {isContact ? (
                  <ContactSectionCard isLoading={isLoading} />
                ) : (
                  <CustomObjectSectionCard
                    isLoading={isLoading}
                    formData={formData}
                  />
                )}
              </>
            </CustomBuilderCard>
          </SingleObjectPermissionsContext>
          <StyledBuilderCard {...props}>
            <Section>
              <GroupsSection
                loading={loading}
                groups={permissionGroupsForList}
                {...values}
              />
            </Section>
          </StyledBuilderCard>
        </StyledCols>
        <ConfirmationModal
          className={`no-drag`}
          heading={t('Please Confirm Setting')}
          buttonText={t('Confirm Setting')}
          defaultLeftBtnText={t('Cancel')}
          actionBtnColor="green"
          {...modalProps}
          onConfirm={modal.hide}
        >
          {t(
            'This setting will allow entity records to display on public forms. Please confirm this object has no sensitive data (PII, etc.) that should not be available to the public.'
          )}
        </ConfirmationModal>
      </ContentWidth>
    </PageSizing>
  );
}
