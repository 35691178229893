import styled from '@emotion/styled';

export const FilterRow = styled.div`
  display: flex;
  position: relative;
  column-gap: 20px;
`;

export const FilterSummaryColumn = styled.div`
  flex: 1;
`;

export const DateColumn = styled.div`
  flex: 1;

  .rs-picker-daterange > button > div > p {
    margin-bottom: 0;
  }
`;
