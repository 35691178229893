import { forwardRef, PropsWithoutRef } from 'react';
import styled from '@emotion/styled';
import { useReactFlow } from 'reactflow';
import ZoomControl from '__pages/AutomationEngine/ControlBar/ZoomControl';

const FlowBarWrapper = styled.div<{ ref: any }>`
  > div {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
`;

export const FlowBar = forwardRef<HTMLDivElement, PropsWithoutRef<any>>(
  (props, ref) => {
    const { zoomIn, zoomOut } = useReactFlow();

    return (
      <FlowBarWrapper ref={ref} className={props.className}>
        <div>
          <div />

          <ZoomControl
            onClickPlus={() =>
              zoomIn({
                duration: 0,
              })
            }
            onClickMinus={() =>
              zoomOut({
                duration: 0,
              })
            }
          />
        </div>
      </FlowBarWrapper>
    );
  }
);
