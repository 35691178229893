import TextInput from 'components/Inputs/TextInput';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BROADCAST_FORMDATA } from '../constants';
import { getBroadcastName } from '../helpers';

export const BroadcastName = ({
  name,
  setFormData,
  action,
  disabled,
  eventType,
}) => {
  const { t } = useTranslation();
  const placeholder = useMemo(() => {
    return getBroadcastName(
      eventType,
      { [BROADCAST_FORMDATA.action]: action },
      t
    );
  }, [eventType, action, t]);

  const [value, setValue] = useState(name);

  const onChange = (v) => {
    setFormData([BROADCAST_FORMDATA.name], v);
    setValue(v);
  };

  return (
    <TextInput
      label={t('Name Your Broadcast')}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
