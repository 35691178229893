import { useMemo } from 'react';
import { Switch } from 'react-router-dom';

import { breakpoints, isMobile, useWindowSize, gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import styled from '@emotion/styled';
import routes, { ACCESS_LOOKUP } from './routes';
import { RouteWithTitle } from 'hooks/useSetTitleOnLoad';
import SubNavigation from 'components/Navigation/SubNavigation';
import { useShouldHideNavbar } from 'routes';
import {
  getOAuthTokenAccess,
  getApiKeysAccess,
  getMyProfileSection,
} from 'store/authentication/selectors';
import { useSelector } from 'react-redux';

const MobileContainer = styled.div`
  padding: ${gutters.standard};
  text-align: center;
`;

const generateRoute = (routeKey, index) => {
  const route = routes[routeKey];
  return (
    <RouteWithTitle
      key={index}
      path={route.path}
      exact={route.exact !== false}
      component={route.component}
      title={route.title}
    />
  );
};

const routeKeys = Object.keys(routes);
const routeComponents = routeKeys.map(generateRoute);

const filteredRoutes = routeKeys
  .map((routeKey) => routes[routeKey])
  .filter((routeKey) => !routeKey.hide);

const AccountPage = () => {
  const { width } = useWindowSize();
  const mobileDisplay = isMobile(width, breakpoints.lg);
  const hideNav = useShouldHideNavbar();

  const accessOauth = useSelector(getOAuthTokenAccess);
  const accessApi = useSelector(getApiKeysAccess);
  const accessMyProfileSection = useSelector(getMyProfileSection);

  const allowedRoutes = useMemo(
    () =>
      filteredRoutes.filter(({ accessList }) => {
        switch (accessList) {
          case ACCESS_LOOKUP.MY_PROFILE_SECTION:
            return accessMyProfileSection;
          case ACCESS_LOOKUP.API_CONNECTIONS_SECTION:
            return accessOauth || accessApi;

          default:
            return false;
        }
      }),
    [accessMyProfileSection, accessOauth, accessApi]
  );

  return !mobileDisplay ? (
    <>
      {!hideNav ? <SubNavigation routes={allowedRoutes} /> : null}
      <Switch>{routeComponents}</Switch>
    </>
  ) : (
    <MobileContainer>
      <KizenTypography>
        This page has not been optimized for mobile. Please revisit on a desktop
        to receive the best experience.
      </KizenTypography>
    </MobileContainer>
  );
};

export default AccountPage;
