import { useCallback, useEffect, useRef } from 'react';

export const useAttachListener = (listener, type) => {
  const ref = useRef();

  const refCallback = useCallback(
    (node) => {
      if (ref.current && !node) {
        ref.current.removeEventListener(type, listener);
      }
      ref.current = node;
      if (node) {
        node.addEventListener(type, listener);
      }
    },
    [listener, type]
  );

  useEffect(() => {
    return () => {
      if (ref.current) {
        ref.current.removeEventListener(type, listener);
      }
    };
  }, [listener, type]);

  return refCallback;
};
