import React from 'react';
import PropTypes from 'prop-types';
import PhoneNumberTextInput from 'components/Inputs/TextInput/presets/PhoneNumber';
import { getDataQAForInput } from 'components/Inputs/helpers';

const PhoneNumber = React.forwardRef(
  ({ field, onChange, value, ...props }, ref) => {
    const {
      // instead fetch default country from redux - we have it in the field defaultRegion
      // chosenBusiness.phone_default_region === defaultRegion
      phonenumberOptions: { enableExtension, defaultRegion },
    } = field;

    return (
      <PhoneNumberTextInput
        ref={ref}
        value={value}
        onChange={onChange}
        enableExtension={enableExtension}
        defaultCountry={defaultRegion}
        {...getDataQAForInput(field.id, field.fieldType)}
        {...props}
      />
    );
  }
);

export default PhoneNumber;

PhoneNumber.displayName = 'PhoneNumber';
PhoneNumber.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
PhoneNumber.defaultProps = {
  value: '',
};
