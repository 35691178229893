import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { grayScale, shadowLight, borderTight } from 'app/colors';
import { textCss } from 'app/typography';
import { gutters, scrollbarCss } from 'app/spacing';

export const ViewWrapper = styled.div`
  // This is the size of the bottom padding on EditableText.
  // They should match so that there is no jitter switching between text and the input.
  margin-bottom: -${gutters.spacing(2, -3)}px;
  .ViewText {
    margin-top: -${gutters.spacing(1, -4)}px;
    max-width: 100%;
  }
  .ViewText input:not(:hover) {
    background-image: none;
  }
  &:hover,
  tr:hover & {
    .ViewText input:not(:hover) {
      background-image: url(/images/inputUnderline.svg);
    }
  }
  input {
    min-width: 30px;
    padding-left: 0;
  }
  &&& input[disabled] {
    background: unset;
    // A bit blunt, but we need to make sure there's no image otherwise the background repeats
    background-image: none !important;
    color: ${grayScale.mediumDark};
  }
`;

export const TextareaWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
  background: ${grayScale.white};
  margin-top: -${gutters.spacing(4)}px;
  overflow: hidden;
  ${borderTight}
  ${shadowLight}
  > textarea {
    width: 100%;
    max-width: 100%;
    outline: none;
    border: none;
    resize: none;
    ${textCss()}
    ${scrollbarCss()}
    line-height: 125%;
    padding: ${gutters.spacing(2)}px calc(2 * ${gutters.standard}) 0
      ${gutters.spacing(2, 1)}px;
  }
  && > button {
    margin: ${gutters.spacing(1)}px ${gutters.spacing(2)}px
      ${gutters.spacing(2)}px ${gutters.spacing(1)}px;
    width: 12px;
    svg {
      width: 12px;
      height: auto;
    }
  }

  ${({ width, enableResize }) =>
    !enableResize &&
    css`
      width: ${`${width || 250}px`};
    `};
`;

export const LongTextWrapper = styled.div`
  width: 100%;
  padding: ${gutters.spacing(2)}px ${gutters.spacing(2)}px
    ${gutters.spacing(0)}px ${gutters.spacing(2)}px;
`;
