import { ForwardedRef, ReactNode, forwardRef } from 'react';
import {
  ElementContainer,
  DEFAULT_ELEMENT_CONTAINER_PROPS,
} from './ElementContainer';
import { ImageProps } from '../../types';
import { getTypeInfo, NodeType } from '../types';

type ImageContainerProps = ImageProps & {
  children: ReactNode;
  draggingType?: NodeType;
};

export const DEFAULT_IMAGE_CONTAINER_PROPS = DEFAULT_ELEMENT_CONTAINER_PROPS;

export const ImageContainer = forwardRef(
  (
    { children, draggingType, ...rest }: ImageContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <ElementContainer
      ref={ref}
      ignoreChildrenDrag={getTypeInfo(draggingType)?.isContent}
      {...rest}
    >
      {children}
    </ElementContainer>
  )
);
