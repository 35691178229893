import { useCallback, useEffect, useRef } from 'react';
import { getFieldAccessPermissions } from 'components/Fields/FieldInput/helpers';
import { isEmailStatusImmutable } from 'components/Fields/helpers';
import { KEY_CODES } from './constants';
import { getEventKey } from './useCustomSelectMenuProps';
import { isMobile } from 'react-device-detect';

const nextFocus = (e, focusables, focused, handles, limit = 50) => {
  const nextFocusable = e.shiftKey
    ? focusables[focusables.indexOf(focused.current) - 1] || focusables.at(-1)
    : focusables[focusables.indexOf(focused.current) + 1] || focusables[0];
  focused.current = nextFocusable;

  if (
    nextFocusable &&
    (!handles.current[nextFocusable] ||
      handles.current[nextFocusable]?.disabled) &&
    limit
  ) {
    nextFocus(e, focusables, focused, handles, limit - 1);
  }

  handles.current[nextFocusable]?.customFocus?.(e);
};

const handleKeyEvent = (e, focusables, focused, handles) => {
  const handle = handles.current[focused.current];

  switch (getEventKey(e)) {
    case KEY_CODES.tab.type: {
      e.preventDefault();
      if (handle) {
        const shouldStayFocused = handle.customTab?.(e);
        !shouldStayFocused && nextFocus(e, focusables, focused, handles);
      }
      return;
    }
    case KEY_CODES.enter.type: {
      if (handle) {
        const shouldStayFocused = handle.customEnter?.(e);
        !shouldStayFocused && nextFocus(e, focusables, focused, handles);
      }
      return;
    }
    case KEY_CODES.arrowUp.type: {
      if (handle) {
        handle.customUp?.(e);
      }
      return;
    }
    case KEY_CODES.arrowDown.type: {
      if (handle) {
        handle.customDown?.(e);
      }
      return;
    }
    case KEY_CODES.arrowLeft.type: {
      if (handle) {
        handle.customLeft?.(e);
      }
      return;
    }
    case KEY_CODES.arrowRight.type: {
      if (handle) {
        handle.customRight?.(e);
      }
      return;
    }
    case KEY_CODES.esc.type: {
      if (handle) {
        handle.customEscape?.(e);
      }
      return;
    }
    case KEY_CODES.space.type: {
      if (handle) {
        handle.customSpace?.(e);
      }
      return;
    }
    case KEY_CODES.letter.type:
    case KEY_CODES.digit.type:
    case KEY_CODES.punctuation.type: {
      if (handle) {
        handle.customInput?.(e);
      }
      return;
    }
    default: {
      return;
    }
  }
};
const DEFAULT_VALIDATION_CALLBACK = (field, object) =>
  getFieldAccessPermissions(field, object).every(Boolean) &&
  !isEmailStatusImmutable(object, field);

export const useKeyListeners = (
  fields,
  object,
  validationCallback = DEFAULT_VALIDATION_CALLBACK,
  suppressAutoScroll = false
) => {
  const focusables = useRef([]);
  const fieldHandles = useRef({});
  //possible values: undefined ( initial ), null, field-id
  const focused = useRef(undefined);
  const onFocus = useCallback(
    (id) => () => {
      focused.current = id;
    },
    []
  );
  const onBlur = useCallback((e, shouldFocusNext) => {
    if (shouldFocusNext)
      nextFocus(e, focusables.current, focused, fieldHandles);
    else focused.current = null;
  }, []);

  const assignFieldHandle = useCallback((id, el) => {
    if (id !== undefined && !isMobile) fieldHandles.current[id] = el;
  }, []);

  useEffect(() => {
    const handler = (e) =>
      focused.current &&
      handleKeyEvent(e, focusables.current, focused, fieldHandles);
    document.addEventListener('keydown', handler, false);

    return () => document.removeEventListener('keydown', handler);
  }, []);

  useEffect(() => {
    focusables.current = fields
      .filter((field) => validationCallback(field, object))
      .map(({ id }) => id);
  }, [fields, object, validationCallback]);

  useEffect(() => {
    // prevent any custom handler for mobile devices
    if (!isMobile && !suppressAutoScroll) {
      if (focused.current === undefined) {
        focused.current = fields[0]?.id;
        if (
          !fieldHandles?.current[focused?.current] ||
          fieldHandles?.current[focused?.current]?.disabled
        ) {
          nextFocus(
            { shiftKey: false },
            focusables.current,
            focused,
            fieldHandles
          );
        }
      }
      focused.current &&
        fieldHandles?.current[focused?.current]?.customFocus?.();
    }
  });

  /**
   *
   * @type {function(*): {onBlur: function(*, *): void, shouldUseKeyboardEventHandle: boolean, onFocus: function(): void, fieldId: *}}
   */
  const getKeyListenersProps = useCallback(
    (id) => {
      return {
        fieldId: id,
        shouldUseKeyboardEventHandle: true,
        onFocus: onFocus(id),
        onBlur: onBlur,
      };
    },
    [onBlur, onFocus]
  );

  const resetFocus = () => {
    focused.current = undefined;
  };

  return {
    onFocus,
    onBlur,
    assignFieldHandle,
    getKeyListenersProps,
    resetFocus,
  };
};
