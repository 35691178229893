import { useSetTitleOnLoad } from '__hooks/useSetTitleOnLoad';
import {
  SmartConnector,
  SmartConnectorBase,
} from '__pages/SmartConnectors/types';
import { useTranslation } from 'react-i18next';

export const useSmartConnectorTitle = (
  smartConnector: SmartConnectorBase | SmartConnector
) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(
    [smartConnector.name, t('SmartConnector')].filter(Boolean).join(' - ')
  );
};
