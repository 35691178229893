import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import ErrorCardBase from 'components/Kizen/ErrorCard';
import BaseNotice from 'components/Kizen/Notice';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import {
  CardToolbarSearch,
  CardToolbarSection,
} from 'components/Layout/CardToolbar';

export const Notice = styled(BaseNotice)`
  margin-bottom: 20px;
`;

export const Underline = styled.span`
  font-size: inherit;
  text-decoration: underline;
`;

export const ErrorCard = styled(ErrorCardBase)`
  margin-top: -1px;
  z-index: ${layers.validation};
`;

export const StyledTableScrollContainer = styled(TableScrollContainer)`
  max-height: 442px;
`;

export const StyledCardToolbarSearch = styled(CardToolbarSearch)`
  &:first-child {
    margin-right: auto;
    width: 100%;
    max-width: 225px;
  }
  &:not(:first-child) {
    margin-left: auto;
  }
`;

export const StyledCardToolbarSection = styled(CardToolbarSection)`
  width: 100%;
  align-items: center;
`;
