import React, { useEffect } from 'react';

function zendeskExec(command) {
  if (window.zE) {
    window.zE('webWidget', command);
  }
}

export const HideZendescHOC = (Page) =>
  function Comp(props) {
    useEffect(() => {
      window.zendeskAllowed = false;
      zendeskExec('hide');
    }, []);

    return <Page {...props} />;
  };

const ZendeskIntegration = (Page) =>
  function Comp(props) {
    useEffect(() => {
      window.zendeskAllowed = true;
      return () => {
        window.zendeskAllowed = false;
        zendeskExec('hide');
      };
    }, []);

    return <Page {...props} />;
  };

export default ZendeskIntegration;
