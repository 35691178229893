import { createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  take,
  join,
} from 'redux-saga/effects';
import { getOriginalError } from 'services/AxiosService';
import { FIELD_TYPES } from 'utility/constants';
import FieldService from 'services/FieldService';

export class SagaCancelledError extends Error {
  constructor(message) {
    super(message || 'Saga was cancelled');
    this.name = 'SagaCancelledError';
  }
}

const commonProperties = ['name', 'message', 'stack', 'code'];

// Reworked from https://github.com/sindresorhus/serialize-error
export const serializeError = (value) => {
  if (typeof value === 'object' && value !== null) {
    const simpleError = {};
    commonProperties.forEach((property) => {
      if (typeof value[property] === 'string') {
        simpleError[property] = value[property];
      }
    });
    simpleError.original = getOriginalError(value);
    if (!simpleError.code && value.response && value.response.status) {
      simpleError.code = value.response.status;
    }
    return simpleError;
  }

  return { message: String(value) };
};

// The serializeError option isn't documented on the website, but it's legit! See: https://github.com/reduxjs/redux-toolkit/pull/812
export function createAsyncSaga(type, handler, options = { serializeError }) {
  const { pending, rejected, fulfilled, typePrefix } = createAsyncThunk(
    type,
    handler,
    options
  );
  function action(arg) {
    const requestId = nanoid();
    return pending(requestId, arg);
  }
  function* saga({ meta: { requestId, arg } }) {
    try {
      const result = yield call(handler, arg);
      yield put(fulfilled(result, requestId, arg));
    } catch (error) {
      yield put(rejected(error, requestId, arg));
    } finally {
      if (yield cancelled()) {
        yield put(rejected(new SagaCancelledError(), requestId, arg));
      }
    }
  }
  return Object.assign(action, {
    saga,
    pending,
    rejected,
    fulfilled,
    typePrefix,
    type: pending.type,
    toString() {
      return pending.type;
    },
  });
}

// takeLeading() when used with createAsyncSaga() would simply ignore
// pending() actions when there was an action running. This was an issue
// because it broke the guarantee that pending() is always followed by
// fulfilled() or rejected(). This addresses that by cancelling duplicates
// while one task is currently running.
export function takeLeadingAndCancel(patternOrChannel, saga, ...args) {
  return fork(function* run() {
    let running = false;
    while (true) {
      const action = yield take(patternOrChannel);
      const task = yield fork(saga, ...args.concat(action));
      if (!running) {
        running = true;
        // eslint-disable-next-line no-loop-func
        yield fork(function* runTask() {
          try {
            yield join(task);
          } finally {
            running = false;
          }
        });
      } else {
        yield cancel(task);
      }
    }
  });
}

export const getCurrentCustomFieldIds = (
  fields,
  columns,
  defaultColumns = []
) => {
  const columnsLookup = Object.values(columns).length
    ? columns
    : defaultColumns.reduce((collect, column) => {
        collect[column.id] = column;
        return collect;
      }, {});

  return fields.reduce((collect, field) => {
    if (!field.isDefault && columnsLookup[field.id]?.visible) {
      collect.push(field.id);
    }
    return collect;
  }, []);
};

export const unpackInitPayload = (payload) => {
  // destructure value if it has init and nextValue
  if (payload.value?.init && payload.value?.nextValue) {
    return {
      ...payload,
      value: payload.value.nextValue,
      init: true,
    };
  }
  return payload;
};

export const setQuickFiltersUpToDate = (
  model = {},
  filterSettings = [],
  quickFilters = {}
) =>
  model.quickFilteringEnabled
    ? (filterSettings || []).reduce((collect, setting) => {
        if (setting?.id && quickFilters?.[setting?.id]) {
          collect[setting.id] = quickFilters[setting.id];
        }
        return collect;
      }, {})
    : {};

export const QUICK_FILTERS_IN_GROUPS = 'in_groups';

export const enrichAndRepairQuickFilterSettings = async (
  quickFilterSettings = [],
  fields = []
) => {
  const fieldTorelatedObjectLookup = fields.reduce((acc, field) => {
    if (field.fieldType === FIELD_TYPES.Relationship.type) {
      return {
        ...acc,
        [field.id]: field.relation.relatedObject,
      };
    }
    return acc;
  }, {});

  // as we are using Related Object filter in Quick Filters and some of the fields on related object
  // could be inaccessible, we need to filter them out
  const enrichedQickFilterSettings = await Promise.all(
    quickFilterSettings.map(
      (setting) =>
        new Promise((resolve) => {
          if (
            setting.relatedObjectFieldId &&
            setting.relatedObjectFieldId !== QUICK_FILTERS_IN_GROUPS
          ) {
            FieldService.getCustomObjectField(
              fieldTorelatedObjectLookup[setting.fieldId],
              setting.relatedObjectFieldId,
              { skipErrorBoundary: true }
            ).then(
              (field) => {
                resolve(field && { ...setting, relatedObjectField: field });
              },
              () => resolve(null)
            );
          } else {
            resolve(setting);
          }
        })
    )
  );
  return enrichedQickFilterSettings.filter(Boolean);
};
