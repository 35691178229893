import { createKeyedCell, RowCells, PivotTable } from '@kizen/kds/PivotTable';
import { SizeMonitor } from '@kizen/kds/SizeMonitor';
import { randomID } from '@kizen/kds/util';
import { v4 as uuidv4 } from 'uuid';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  RefObject,
  MouseEvent,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { getValueBreakdownLabel } from '__components/Wizards/shared/components/BreakdownAndBucketing/shared/utils';
import {
  DataCell,
  DataHeaderCell,
  NameColumnCell,
  NameHeaderCell,
  TotalsColumnCell,
  TotalsRowCell,
  TypographyPivotTableCellProps,
} from './Cells';
import { FIELD_TYPES } from 'utility/constants';

type Data = {
  breakout?: any;
  id: string;
  label: string;
  tooltip?: string;
  total: number;
  values: number[];
  dynamicTagLink?: string;
  statusColor?: string;
  expandable?: boolean;
  expanded?: boolean;
  activeSearch?: boolean;
  matchedSearch?: boolean;
  subRow?: boolean;
  loading?: boolean;
};

type ColumnHeaderData = {
  label: string;
  statusColor?: string;
};

type RowData = [Data, ...number[], Data];

type RowDataLabels = [string, ...number[]];

type RowDataProps = {
  id: string | null;
  label: string;
  total: number;
  values: number[];
  subrow_ids?: number[];
};

type DataRows = RowDataProps[];
type SubRowLabel = string[];

export type PivotTableChartData = {
  columns: {
    id: string;
    label: string;
    tooltip: string;
    total: number;
    statusColor?: any;
  }[];
  grand_total: number;
  rows: DataRows;
  subrow_labels: SubRowLabel[];
};

type SubRowData = {
  loading: boolean;
  data: [];
  expanded: boolean;
  matchedSearch?: boolean;
  activeSearch?: boolean;
};

type SubRowState = Record<string, SubRowData> & {
  totals?: string[];
  fetchComplete?: boolean;
};

type PivotTableChartProps = {
  data: PivotTableChartData;
  dashlet: any;
  dashletSearch: string;
  title: string;
  frameless?: boolean;
  standardColumnSizes?: number[];
  fixedLeftColumnSizes?: number[];
  minimumFixedLeftWidth?: number;
  sort?: { index: number; direction: Direction };
  getLink(entityId: string, isSubrow: boolean): null | string;
  getDynamicTagLink(dynamicLinkOptions: any): any;
  onChangeStandardColumnSizes(sizes: number[]): void;
  onChangeFixedLeftColumnSizes(sizes: number[]): void;
  onChangeSort(value: { index: number; direction: Direction }): void;
  onExpandCollapse?: (
    e: MouseEvent<HTMLButtonElement>,
    ids: string[],
    autoExpand?: boolean
  ) => void;
  currencySymbol?: string;
  hideBottomRow?: boolean;
  fieldsById: any;
  subRowState?: SubRowState;
  resetScollPosition: () => void;
  onVerticalScroll: () => void;
  onHorizontalScroll: () => void;
  verticalScrollPosition?: RefObject<number>;
  horizontalScrollPosition?: RefObject<number>;
};

type bucketsById = { [key: string]: any };

const months: { [key: string]: number } = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
};

const monthFrequencyLabelRegex = /\b([a-zA-Z]{3}) ‘(\d{2})\b/;
const weekFrequencyLabelRegex =
  /\b([A-Za-z]{3}) (\d{2}) - [A-Za-z]{3} \d{2}, ‘(\d{2})\b/;
const quarterFrequencyLabelRegex = /\bQ(\d{1}) ‘(\d{2})\b/;

const parseWeekFrequency = (date: string): Date | null => {
  const match = date.match(weekFrequencyLabelRegex);

  if (!match) {
    return null;
  }

  const [, month, day, year] = match;

  const numericYear = parseInt(`20${year}`, 10);
  const numericMonth = months[month];
  const numericDay = parseInt(day, 10);

  if (numericMonth === undefined) {
    return null;
  }

  return new Date(numericYear, numericMonth, numericDay);
};

const parseMonthFrequency = (date: string): Date | null => {
  const match = date.match(monthFrequencyLabelRegex);

  if (!match) {
    return null;
  }

  const [, month, year] = match;

  const numericMonth = months[month];
  const numericYear = parseInt(`20${year}`, 10);

  if (numericMonth === undefined) {
    return null;
  }

  return new Date(numericYear, numericMonth);
};

const parseQuarterFrequency = (date: string): Date | null => {
  const match = date.match(quarterFrequencyLabelRegex);

  if (!match) {
    return null;
  }

  const [, quarter, year] = match;

  const numericQuarter = parseInt(quarter, 10);
  const numericYear = parseInt(`20${year}`, 10);

  if (numericQuarter === undefined) {
    return null;
  }

  return new Date(numericYear, numericQuarter - 1);
};

const sortByColumn = (index: number, desc = true) => {
  return (a: RowData, b: RowData) => {
    if (index === 0) {
      const isMonthFrequencyLabel = a[index].label.match(
        monthFrequencyLabelRegex
      );
      const isWeekFrequencyLabel = a[index].label.match(
        weekFrequencyLabelRegex
      );
      const isQuarterFrequencyLabel = a[index].label.match(
        quarterFrequencyLabelRegex
      );

      if (isWeekFrequencyLabel) {
        const aWeekDate = parseWeekFrequency(a[index].label);
        const bWeekDate = parseWeekFrequency(b[index].label);
        if (!aWeekDate || !bWeekDate) return 0;
        return desc
          ? bWeekDate.getTime() - aWeekDate.getTime()
          : aWeekDate.getTime() - bWeekDate.getTime();
      }

      if (isMonthFrequencyLabel) {
        const aMonthDate = parseMonthFrequency(a[index].label);
        const bMonthDate = parseMonthFrequency(b[index].label);
        if (!aMonthDate || !bMonthDate) return 0;
        return desc
          ? bMonthDate.getTime() - aMonthDate.getTime()
          : aMonthDate.getTime() - bMonthDate.getTime();
      }

      if (isQuarterFrequencyLabel) {
        const aQuarterDate = parseQuarterFrequency(a[index].label);
        const bQuarterDate = parseQuarterFrequency(b[index].label);
        if (!aQuarterDate || !bQuarterDate) return 0;
        return desc
          ? bQuarterDate.getTime() - aQuarterDate.getTime()
          : aQuarterDate.getTime() - bQuarterDate.getTime();
      }
      return desc
        ? b[index].label.localeCompare(a[index].label)
        : a[index].label.localeCompare(b[index].label);
    }

    if (index === a.length - 1) {
      const aTotal = a[index] as Data;
      const bTotal = b[index] as Data;

      return desc
        ? bTotal?.total - aTotal?.total
        : aTotal?.total - bTotal?.total;
    }

    const avalue = a[index] as number;
    const bvalue = b[index] as number;

    return desc ? bvalue - avalue : avalue - bvalue;
  };
};

type Direction = 'asc' | 'desc';

const useColumnSort = (
  onSort?: (index: number, direction: Direction) => void,
  defaultSortDirection?: Direction,
  defaultSortIndex?: number
) => {
  const [sortIndex, setSortIndex] = useState<null | number>(
    defaultSortIndex ?? null
  );
  const [sortDirection, setSortDirection] = useState<Direction>(
    defaultSortDirection ?? 'asc'
  );

  const setter = useCallback(
    (nextColumn: number) => {
      setSortIndex((prevIndex) => {
        setSortDirection((prevDirection) => {
          let next: Direction = defaultSortDirection ?? 'asc';
          if (prevIndex === nextColumn) {
            next = prevDirection === 'desc' ? 'asc' : 'desc';
          }
          onSort?.(nextColumn, next);
          return next;
        });

        return nextColumn;
      });
    },
    [defaultSortDirection, onSort]
  );

  return [{ sortIndex, sortDirection }, setter] as const;
};

const getRowDataWithLabel = (
  row: Data,
  dashletSearch: string,
  bucket: bucketsById,
  fieldData: any,
  t: TFunction
) => {
  let searchableLabel = row.label.toLowerCase();
  let updatedLabel = row.label;
  const rowData: Data = {
    id: row.id,
    label: row.label,
    total: row.total,
    values: row.values,
    expandable: false,
    expanded: false,
    matchedSearch: false,
    loading: false,
  };

  if (bucket && fieldData) {
    const valueBreakdownLabel = getValueBreakdownLabel(bucket, fieldData, t);
    searchableLabel = valueBreakdownLabel.toLowerCase();
    rowData.label = valueBreakdownLabel;
    updatedLabel = valueBreakdownLabel;
    rowData.matchedSearch = searchableLabel.includes(
      dashletSearch.toLowerCase()
    );
  }

  return { rowData, searchableLabel, updatedLabel };
};

const getStatusColor = (
  fieldData: {
    fieldType: string;
    options: [{ id: string; meta: { color: string } }];
  },
  rowId: string
) => {
  if (fieldData?.fieldType === FIELD_TYPES.Status.type) {
    const option = fieldData?.options?.find((option) => option.id === rowId);
    return option?.meta?.color;
  }
  return undefined;
};

const usePivotTableKey = (rows: RowCells[]) => {
  const [key, setKey] = useState(randomID());
  const prevRows = useRef(rows);

  useEffect(() => {
    const prevRowCount = prevRows.current?.length ?? 0;
    const prevColCount = prevRows.current?.[0]?.KeyedCells?.length ?? 0;

    if (
      prevRowCount !== rows.length ||
      prevColCount !== rows?.[0]?.KeyedCells?.length
    ) {
      setKey(randomID());
    }

    prevRows.current = rows;
  }, [rows]);

  return key;
};

const initializeTableData = (
  rows: RowData[],
  sort: PivotTableChartProps['sort']
) => {
  return sort
    ? [...rows].sort(sortByColumn(sort.index, sort.direction === 'desc'))
    : rows;
};

const getRowStyles = (rowData: { loading?: boolean }) => {
  let loading;
  if (rowData?.loading) {
    loading = 'border-b-1 border-button-secondary-hover';
  }
  return loading;
};

const getRowBackgroundColor = (rowData: { subRow?: boolean }) => {
  if (rowData?.subRow) {
    return 'bg-background-lighter';
  }
  return '';
};

export const PivotTableChart: FC<PivotTableChartProps> = ({
  data,
  dashlet,
  dashletSearch,
  title,
  frameless = true,
  onChangeStandardColumnSizes,
  onChangeFixedLeftColumnSizes,
  fixedLeftColumnSizes,
  standardColumnSizes,
  minimumFixedLeftWidth,
  onChangeSort,
  onExpandCollapse = () => {},
  subRowState = {},
  verticalScrollPosition,
  horizontalScrollPosition,
  sort,
  getLink,
  getDynamicTagLink,
  currencySymbol,
  hideBottomRow = false,
  fieldsById,
  onVerticalScroll,
  onHorizontalScroll,
}) => {
  const { t } = useTranslation();
  const firstRenderRef = useRef(true);
  const sortRef = useRef(sort);
  const expandableRowIndexesRef = useRef([] as number[]);
  sortRef.current = sort;

  const { columns: dataColumns = [], rows: dataRows = [] } = data ?? {};

  let columnSizes: number[];
  if (standardColumnSizes?.length === dataColumns.length) {
    columnSizes = standardColumnSizes;
  } else if (standardColumnSizes) {
    columnSizes = standardColumnSizes?.length
      ? dataColumns.map(() => standardColumnSizes[0])
      : [];
  }

  const hasSubRows = useMemo(() => {
    return dashlet?.config?.aggregation?.rows?.length > 1;
  }, [dashlet?.config?.aggregation?.rows]);

  const colConfigByValueBreakdownId = useMemo(() => {
    return (
      dashlet?.config?.aggregation?.columns?.fieldsValueBreakdown?.buckets?.reduce(
        (acc: bucketsById, bucket: { id: string; [key: string]: any }) => {
          const bucketId = bucket.id ?? '';
          acc[bucketId] = bucket;
          return acc;
        },
        {} as bucketsById
      ) ?? {}
    );
  }, [dashlet?.config?.aggregation?.columns]);

  const rowConfigByValueBreakdownId = useMemo(() => {
    return (
      dashlet?.config?.aggregation?.rows.reduce(
        (
          acc: bucketsById,
          configRow: {
            id: string;
            fieldsValueBreakdown: { buckets: [{ id: string }] };
          }
        ) => {
          configRow?.fieldsValueBreakdown?.buckets?.forEach(
            (bucket: { id: string }) => {
              acc[bucket.id] = bucket;
            }
          );
          return acc;
        },
        {} as bucketsById
      ) ?? {}
    );
  }, [dashlet?.config?.aggregation?.rows]);

  const payload: {
    headers: ColumnHeaderData[];
    rows: RowData[];
    totals: RowDataLabels;
  } = useMemo(() => {
    const titleColumn = [{ label: title }];
    return {
      headers: titleColumn.concat(
        dataColumns.map((col) => {
          const fieldId = dashlet?.config?.aggregation?.columns?.id;
          const fieldData = fieldsById[fieldId];
          const bucket = colConfigByValueBreakdownId[col.id];
          if (bucket && fieldData) {
            col.label = getValueBreakdownLabel(bucket, fieldData, t);
          } else if (fieldData?.fieldType === FIELD_TYPES.Status.type) {
            const option = fieldData?.options?.find(
              (option: { id: string }): boolean => option.id === col.id
            );
            col.statusColor = option?.meta?.color;
          }
          return col;
        }),
        t('Totals')
      ),
      rows: dataRows.reduce<RowData[]>((acc, row) => {
        const [{ id: parentRowFieldId }, { id: subRowFieldId } = { id: '' }] =
          dashlet?.config?.aggregation?.rows ?? [];

        // Stringify boolean and null ids
        if (typeof row.id === 'boolean' || row.id === null) {
          row.id = String(row.id);
        }

        const rowId = row.id ?? '';
        const fieldData = fieldsById[parentRowFieldId];
        const bucket = rowConfigByValueBreakdownId[rowId];
        const { rowData, searchableLabel, updatedLabel } = getRowDataWithLabel(
          { ...row, id: row?.id || '' },
          dashletSearch,
          bucket,
          fieldData,
          t
        );
        const subRowIds = row?.subrow_ids ?? [];
        const rowHasSubRowData = rowData.total !== 0 || subRowIds.length > 0;
        rowData.statusColor = getStatusColor(fieldData, rowId);
        rowData.activeSearch = Boolean(dashletSearch);
        rowData.matchedSearch = false;
        rowData.expandable = hasSubRows && rowHasSubRowData;
        rowData.expanded = subRowState[rowId]?.expanded;
        rowData.loading = subRowState[rowId]?.loading;
        if (
          fieldData?.fieldType === FIELD_TYPES.DynamicTags.type &&
          rowId &&
          rowId !== 'null'
        ) {
          const link = getDynamicTagLink(fieldData);
          const linkElement = link({ id: rowId, label: rowData.label });
          rowData.dynamicTagLink = linkElement;
        }
        const dashletSearchMatch = searchableLabel
          .toLowerCase()
          .includes(dashletSearch.toLowerCase());

        // Collect and check if subrow labels match search
        const subRowLabels = data?.subrow_labels;
        const labels = row?.subrow_ids?.map((index) => subRowLabels[index][1]);
        const subRowSearchMatch = labels?.some((label) => {
          const subRowFieldData = fieldsById[subRowFieldId];
          const subRowBucket = rowConfigByValueBreakdownId[label];
          const { searchableLabel: subRowSearchableLabel } =
            getRowDataWithLabel(
              { ...row, id: row?.id || '', label: label },
              dashletSearch,
              subRowBucket,
              subRowFieldData,
              t
            );
          return subRowSearchableLabel
            .toLowerCase()
            .includes(dashletSearch.toLowerCase());
        });

        if (dashletSearchMatch || subRowSearchMatch) {
          rowData.label = updatedLabel;
          rowData.matchedSearch = dashletSearchMatch;
          const rowWithBreakdownLabel: RowData = [
            rowData,
            ...rowData.values,
            rowData,
          ];
          acc.push(rowWithBreakdownLabel);
        }
        return acc;
      }, []) as RowData[],
      totals: [
        t('Totals'),
        ...dataColumns.map((col) => col.total),
        data?.grand_total,
      ],
    };
  }, [
    dataColumns,
    dataRows,
    dashletSearch,
    fieldsById,
    data?.grand_total,
    data?.subrow_labels,
    dashlet?.config?.aggregation,
    colConfigByValueBreakdownId,
    rowConfigByValueBreakdownId,
    getDynamicTagLink,
    hasSubRows,
    subRowState,
    title,
    t,
  ]);

  const [tableData, setTableData] = useState(() =>
    sort
      ? [...payload.rows].sort(
          sortByColumn(sort.index, sort.direction === 'desc')
        )
      : payload.rows
  );

  const onSort = useCallback(
    (index: number, direction: Direction) => {
      setTableData((prevTableData) => {
        onChangeSort?.({ index, direction });
        return [
          ...prevTableData.sort(sortByColumn(index, direction === 'desc')),
        ];
      });
    },
    [onChangeSort]
  );

  const [{ sortIndex, sortDirection }, setSort] = useColumnSort(
    onSort,
    sort?.direction,
    sort?.index
  );

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      setTableData(initializeTableData(payload.rows, sortRef.current));
    }
  }, [payload.rows]);

  const headers = useMemo(() => {
    return payload.headers.map((column, i, arr) => {
      const isSortActive = sortIndex === i;
      const iconColor = isSortActive ? 'button-secondary-default' : 'gray-700';
      const iconHoverClass = isSortActive
        ? 'hover:text-button-secondary-hover'
        : 'hover:text-button-tertiary-hover';

      if (i === 0) {
        return createKeyedCell((props: TypographyPivotTableCellProps) => (
          <NameHeaderCell
            data={column.label}
            data-qa="pivot-table-name-header-cell"
            iconColor={iconColor}
            iconHoverClass={iconHoverClass}
            isSortActive={isSortActive}
            sortDirection={sortDirection}
            onSort={setSort}
            {...props}
          />
        ));
      }

      if (i === arr.length - 1) {
        return createKeyedCell((props: TypographyPivotTableCellProps) => (
          <NameHeaderCell
            data={'Totals'}
            data-qa="pivot-table-name-header-cell"
            iconColor={iconColor}
            iconHoverClass={iconHoverClass}
            isSortActive={isSortActive}
            sortDirection={sortDirection}
            onSort={setSort}
            {...props}
          />
        ));
      }

      return createKeyedCell((props: TypographyPivotTableCellProps) => (
        <DataHeaderCell
          data={column.label}
          statusColor={column.statusColor}
          data-qa="pivot-table-data-header-cell"
          iconColor={iconColor}
          iconHoverClass={iconHoverClass}
          isSortActive={isSortActive}
          sortDirection={sortDirection}
          onSort={setSort}
          {...props}
        />
      ));
    });
  }, [sortIndex, sortDirection, setSort, payload.headers]);

  const getKeyedCells = useCallback(
    (unmappedRows: RowData, isSubrow: boolean) => {
      // first item has subrow data so we need flags to apply styles for rest of the row
      let hasSubRowStyle = false as boolean | undefined;
      let hasLoadingStyle = undefined as boolean | undefined;
      let hasMatchedSearchStyle = undefined as boolean | undefined;
      let hasMissedSearchStyle = undefined as boolean | undefined;
      return unmappedRows.map((data, i, arr) => {
        if (i === 0) {
          const {
            id,
            label,
            statusColor,
            expandable,
            subRow,
            matchedSearch,
            activeSearch,
            dynamicTagLink,
          } = (data as Data) ?? {};
          const link = getLink(id, isSubrow);
          const subRowMatch =
            !subRowState[id] || subRowState[id]?.matchedSearch ? true : false;
          hasMatchedSearchStyle = matchedSearch && activeSearch && subRowMatch;
          hasMissedSearchStyle = !matchedSearch && activeSearch;
          hasSubRowStyle = subRow;
          hasLoadingStyle = subRowState[id]?.loading;

          if ((link && !statusColor) || dynamicTagLink) {
            return createKeyedCell((props: TypographyPivotTableCellProps) => (
              <NameColumnCell
                id={id}
                variant="link"
                data-qa="pivot-table-name-column-cell"
                color={
                  hasMissedSearchStyle
                    ? 'font-placeholder'
                    : 'button-secondary-default'
                }
                expandable={expandable}
                expanded={subRowState[id]?.expanded}
                loading={subRowState[id]?.loading}
                onExpandCollapse={onExpandCollapse}
                subRow={hasSubRowStyle}
                data={label}
                href={link || dynamicTagLink}
                target="_blank"
                {...props}
              />
            ));
          }

          return createKeyedCell((props: TypographyPivotTableCellProps) => (
            <NameColumnCell
              id={id}
              data={label}
              color={hasMissedSearchStyle ? 'font-placeholder' : undefined}
              statusColor={statusColor}
              expandable={expandable}
              expanded={subRowState[id]?.expanded}
              loading={hasLoadingStyle}
              onExpandCollapse={onExpandCollapse}
              subRow={hasSubRowStyle}
              data-qa="pivot-table-name-column-cell"
              {...props}
            />
          ));
        }

        if (i === arr.length - 1) {
          return createKeyedCell((props: TypographyPivotTableCellProps) => (
            <TotalsColumnCell
              data={(data as Data).total}
              color={hasMissedSearchStyle ? 'font-placeholder' : undefined}
              data-qa="pivot-table-totals-column-cell"
              startAdornment={currencySymbol}
              loading={hasLoadingStyle}
              subRow={hasSubRowStyle}
              {...props}
            />
          ));
        }

        return createKeyedCell((props: TypographyPivotTableCellProps) => (
          <DataCell
            data={data as number}
            data-qa="pivot-table-data-cell"
            loading={hasLoadingStyle}
            weight={hasMatchedSearchStyle ? 'semibold' : undefined}
            color={hasMissedSearchStyle ? 'font-placeholder' : undefined}
            startAdornment={currencySymbol}
            subRow={hasSubRowStyle}
            {...props}
          />
        ));
      });
    },
    [getLink, currencySymbol, onExpandCollapse, subRowState]
  );

  const mapSubRowsToRowData = useCallback(
    (subRowData: DataRows = [], isParentSearchMatch: boolean) => {
      const matchedSearchIndexes = new Set();
      const matchedSubRowIds = new Set() as Set<string>;
      const subRow = subRowData.reduce<RowData[]>(
        (acc: RowData[], row: RowDataProps, index) => {
          const [, { id: fieldId }] = dashlet?.config?.aggregation?.rows ?? [];
          const rowId = row.id ?? '';
          const fieldData = fieldsById[fieldId];
          const bucket = rowConfigByValueBreakdownId[rowId];
          const { rowData, searchableLabel, updatedLabel } =
            getRowDataWithLabel(
              { ...row, id: row?.id || '' },
              dashletSearch,
              bucket,
              fieldData,
              t
            );
          const subRowSearchMatch = searchableLabel.includes(
            dashletSearch.toLowerCase()
          );
          rowData.statusColor = getStatusColor(fieldData, rowId);
          rowData.label = updatedLabel;
          rowData.subRow = true;
          rowData.matchedSearch = subRowSearchMatch;
          rowData.activeSearch = Boolean(dashletSearch);
          if (
            fieldData?.fieldType === FIELD_TYPES.DynamicTags.type &&
            rowId &&
            rowId !== 'null'
          ) {
            const link = getDynamicTagLink(fieldData);
            const linkElement = link({ id: rowId, label: rowData.label });
            rowData.dynamicTagLink = linkElement;
          }
          const rowWithBreakdownLabel: RowData = [
            rowData,
            ...rowData.values,
            rowData,
          ];
          if (subRowSearchMatch) {
            matchedSearchIndexes.add(index);
            matchedSubRowIds.add(rowId);
            acc.push(rowWithBreakdownLabel);
          } else if (isParentSearchMatch) {
            acc.push(rowWithBreakdownLabel);
          }
          return acc;
        },
        []
      );
      return {
        subRow,
        matchedSearchIndexes,
        matchedSubRowIds,
      };
    },
    [
      getDynamicTagLink,
      dashlet?.config?.aggregation?.rows,
      dashletSearch,
      fieldsById,
      rowConfigByValueBreakdownId,
      t,
    ]
  );

  const datacells = useMemo(() => {
    return tableData.reduce<RowCells[]>((acc, x, rowIndex) => {
      const [rowData] = x;
      const subRows = subRowState[rowData.id];
      acc.push({
        KeyedCells: getKeyedCells(x, false),
        rowId: rowData.id,
        className: getRowStyles({
          loading: rowData.loading,
        }),
        backgroundColor: getRowBackgroundColor(rowData),
      });

      if (subRows?.expanded && subRows?.data?.length > 0) {
        const parentMatch = rowData?.matchedSearch && rowData?.activeSearch;
        const { subRow: mappedSubRowData } = mapSubRowsToRowData(
          subRows?.data,
          parentMatch ?? false
        );
        mappedSubRowData
          ?.sort(sortByColumn(sortIndex ?? -1, sortDirection === 'desc'))
          .forEach((row) => {
            expandableRowIndexesRef.current.push(rowIndex);
            const [{ id = '' }] = row;
            const subRowId = id !== '' ? id : uuidv4();
            acc.push({
              KeyedCells: getKeyedCells(row, true),
              rowId: subRowId,
              backgroundColor: getRowBackgroundColor({
                subRow: true,
              }),
            });
          });
      }
      return acc;
    }, []);
  }, [
    tableData,
    subRowState,
    mapSubRowsToRowData,
    getKeyedCells,
    sortDirection,
    sortIndex,
  ]);

  const totals = useMemo(() => {
    return payload.totals.map((str) => {
      return createKeyedCell((props: TypographyPivotTableCellProps) => (
        <TotalsRowCell
          data={str}
          data-qa="pivot-table-total-row-cell"
          startAdornment={currencySymbol}
          {...props}
        />
      ));
    });
  }, [payload, currencySymbol]);

  const rows = useMemo(() => {
    const newTotal = { KeyedCells: totals };
    const newHeaders = { KeyedCells: headers };
    return hideBottomRow
      ? [newHeaders, ...datacells]
      : [newHeaders, ...datacells, newTotal];
  }, [headers, datacells, totals, hideBottomRow]);

  const key = usePivotTableKey(rows);

  return (
    <SizeMonitor>
      {({ height, width }) => {
        return (
          <PivotTable
            enableColumnResizing
            key={key}
            rows={rows}
            rowHeight={40}
            width={width}
            height={hideBottomRow ? height - 50 : height}
            fixedTop={40}
            fixedLeft={200}
            fixedRight={100}
            fixedBottom={hideBottomRow ? undefined : 50}
            onChangeStandardColumnSizes={onChangeStandardColumnSizes}
            onChangeFixedLeftColumnSizes={onChangeFixedLeftColumnSizes}
            verticalScrollPosition={verticalScrollPosition}
            horizontalScrollPosition={horizontalScrollPosition}
            onVerticalScroll={onVerticalScroll}
            onHorizontalScroll={onHorizontalScroll}
            fixedLeftColumnSizes={fixedLeftColumnSizes}
            standardColumnSizes={columnSizes}
            frameless={frameless}
            minimumFixedLeftWidth={minimumFixedLeftWidth}
          />
        );
      }}
    </SizeMonitor>
  );
};
