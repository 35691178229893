import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useDebounce } from 'react-use';
import TextInput from '../Inputs/TextInput';
import { DEFAULT_INPUT_DELAY } from '../../utility/config';
import { grayScale } from '../../app/colors';
import IconAdornment from '../Inputs/Adornments/IconAdornment';

export const StyledPageSearchInput = styled(TextInput)`
  max-width: ${({ maxWidth }) => maxWidth || 175}px;
  border-radius: 18px;
  background: ${grayScale.white};

  & input {
    width: 100%;
  }
`;

export const PageSearchInput = ({
  value,
  onChange,
  delay = DEFAULT_INPUT_DELAY,
  ...props
}) => {
  const [innerValue, setInnerValue] = useState(value);
  const stagedValue = useRef(value);
  useDebounce(
    () => stagedValue.current !== innerValue && onChange(innerValue),
    delay,
    [innerValue]
  );

  useEffect(() => {
    if (value !== stagedValue.current) {
      setInnerValue(value);
      stagedValue.current = value;
    }
  }, [value]);
  return (
    <StyledPageSearchInput
      pill
      {...props}
      onChange={setInnerValue}
      value={innerValue}
      endAdornment={<IconAdornment icon="search" />}
    />
  );
};
