import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toastVariant, useToast } from 'components/ToastProvider';

import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { ACCOUNT } from 'queries/query-keys';
import TeamMemberService from 'services/TeamMemberService';

const fetchApiKeysQuery = async (params) => {
  const res = await TeamMemberService.getApiKeys(params, {
    skipErrorBoundary: true,
  });
  // match the shape required for infinite scroll
  return res?.results
    ? res
    : {
        count: res?.length || 0,
        errors: null,
        next: null,
        previous: null,
        results: res,
      };
};

const deleteApiKeyQuery = (id) => TeamMemberService.deleteApiKey(id);
const createApiKeyQuery = () => TeamMemberService.createApiKey();

export const useApiKeysReactQuery = (
  { search, ordering, pageSize = 20, employeeId = null },
  callbacks
) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const queryClient = useQueryClient();

  const infiniteQuery = useInfiniteQuery({
    queryKey: [...ACCOUNT.API_KEYS, employeeId, pageSize, ordering, search],
    queryFn: ({ pageParam = 1 }) => {
      return fetchApiKeysQuery({
        search,
        ordering,
        pageSize,
        employeeId,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next
        ? new URL(lastPage.next).searchParams.get('page')
        : undefined;
    },
    getPreviousPageParam: (previousPage) => {
      return previousPage?.prev
        ? new URL(previousPage.prev).searchParams.get('page')
        : undefined;
    },
    keepPreviousData: true,
    retry: false,
    useErrorBoundary: false,
    staleTime: 0, // so that if the user has multiple browser windows open things stay in synch
    onError: (payload) => {
      const message =
        payload?.response?.data?.[0] ??
        t('There was a problem loading the data, please  try again.');

      showToast({
        message,
        variant: toastVariant.FAILURE,
      });
    },
  });

  const refetchApiKeysTokens = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [...ACCOUNT.API_KEYS],
    });
  }, [queryClient]);

  const deleteApiKeyMutation = useMutation(
    async (result) => {
      await deleteApiKeyQuery(result);
    },
    {
      onSuccess: () => {
        refetchApiKeysTokens();
      },
      onError: (payload) => {
        const message =
          payload?.response?.data?.[0] ??
          t(
            'The Api Key could not be deleted, please refresh your browser and try again.'
          );

        showToast({
          message,
          variant: toastVariant.FAILURE,
        });
      },
    }
  );

  const creatApiKeyMutation = useMutation(
    async (result) => {
      const { data } = await createApiKeyQuery(result);
      callbacks?.created(data);
    },
    {
      onSuccess: () => {
        refetchApiKeysTokens();
      },
      onError: (payload) => {
        const message =
          payload?.response?.data?.[0] ??
          t(
            'The Api Key could not be created, please refresh your browser and try again.'
          );

        showToast({
          message,
          variant: toastVariant.FAILURE,
        });
      },
    }
  );

  return { infiniteQuery, deleteApiKeyMutation, creatApiKeyMutation };
};
