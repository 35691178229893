import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'components/Modals';
import BaseEmbedHtml from 'components/Kizen/EmbedHtml';
import Loader from 'components/Kizen/Loader';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { InfoWrapper } from './styles';
import { MESSAGE_TYPES } from 'utility/constants';

const EmbedHtml = styled(BaseEmbedHtml)`
  margin-top: ${gutters.spacing(2)}px;
`;

const ErrorMessagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorMessage = styled(KizenTypography)`
  text-align: center;
  margin-top: ${gutters.spacing(4)}px;
  margin-bottom: ${gutters.spacing()}px;
  padding: 0 ${gutters.spacing(3)}px;
`;

const ErrorSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
`;

const getTeamMemberName = (teamMember, customFromName) => {
  const { firstName, lastName, email } = teamMember;
  return customFromName
    ? `${customFromName} (${email})`
    : [firstName, lastName, `(${email})`].filter(Boolean).join(' ');
};
const FromComponent = ({ message }) => {
  if (message.fromAddress) {
    return (
      <TextEllipsisWithTooltip>
        {message.senderTeamMember
          ? getTeamMemberName(message.senderTeamMember, message.customFromName)
          : message.fromAddress}
      </TextEllipsisWithTooltip>
    );
  }
  return null;
};
const ToComponent = ({ message }) => {
  if (message.toAddress) {
    return (
      <TextEllipsisWithTooltip>{message.toAddress}</TextEllipsisWithTooltip>
    );
  }
  return null;
};
const CarbonCopyComponent = ({ message }) => {
  if (message?.headerCcEmails?.length) {
    return (
      <TextEllipsisWithTooltip>
        {message.headerCcEmails.join(', ')}
      </TextEllipsisWithTooltip>
    );
  }
  return null;
};

export default function SentMessageModal({
  message,
  onHide,
  buttonLabel,
  isLoading,
  error = false,
  sandbox = 'allow-popups allow-popups-to-escape-sandbox',
  ...props
}) {
  const { t } = useTranslation();
  const { messageType, bodySanitized } = message ?? {};
  const isEmailType = messageType.endsWith('email');
  const [isEmailLoading, setIsEmailLoading] = useState(isEmailType);
  const [contentHeight, setContentHeight] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const infoWrapperRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height: avaiableHeight } = entry.target.getBoundingClientRect();
        if (loaded) {
          const infoWrapper = infoWrapperRef?.current;
          const { height: infoWrapperHeight = 148 } =
            infoWrapper.getBoundingClientRect() ?? {};
          const inforWrapperTotalHeight = infoWrapperHeight + 28; // 15px of top padding 10px top margin and 3px bottom padding
          setContentHeight(avaiableHeight - inforWrapperTotalHeight);
        }
      }
    });

    const iframeElement = document.querySelector('.modal-body');
    if (iframeElement) {
      resizeObserver.observe(iframeElement);
    }

    return () => {
      if (iframeElement) {
        resizeObserver.unobserve(iframeElement);
      }
    };
  }, [loaded]);

  const showLoader = (isEmailLoading || isLoading) && !error;
  const fitContent =
    (isEmailType && !isEmailLoading) || isEmailLoading || showLoader
      ? false
      : 'y';

  return (
    <Modal onHide={onHide} size="medium" fitContent={fitContent} {...props}>
      <ModalHeader onClickClose={onHide}>
        {`${t('View')} ${
          messageType === 'text'
            ? t('Text Message')
            : MESSAGE_TYPES[messageType](t)
        }`}
      </ModalHeader>
      <ModalBody>
        {isEmailType ? (
          <>
            <InfoWrapper ref={infoWrapperRef} className="info-wrapper">
              <div className="left-column">
                <KizenTypography weight="bold">{t('Date')}</KizenTypography>
                <KizenTypography weight="bold">{t('Subject')}</KizenTypography>
                {message.fromAddress ? (
                  <KizenTypography weight="bold">{t('From')}</KizenTypography>
                ) : null}
                {message?.toAddress ? (
                  <KizenTypography weight="bold">{t('To')}</KizenTypography>
                ) : null}
                {message?.headerCcEmails?.length ? (
                  <KizenTypography weight="bold">{t('CC')}</KizenTypography>
                ) : null}
              </div>
              <div className="right-column">
                <KizenTypography>
                  {format(message.created, 'MMMM D, YYYY \\a\\t h:mm A')}
                </KizenTypography>
                <TextEllipsisWithTooltip>
                  {message.message}
                </TextEllipsisWithTooltip>
                <FromComponent message={message} />
                <ToComponent message={message} />
                <CarbonCopyComponent message={message} />
              </div>
            </InfoWrapper>
            {showLoader ? (
              <LoaderWrapper>
                <Loader loading />
              </LoaderWrapper>
            ) : null}
            {bodySanitized && (
              <EmbedHtml
                sandbox={sandbox}
                onLoad={() => {
                  setIsEmailLoading(false);
                  setLoaded(true);
                }}
                contentHeight={contentHeight}
              >
                {bodySanitized}
              </EmbedHtml>
            )}
          </>
        ) : (
          <InfoWrapper>
            <div className="left-column">
              <KizenTypography weight="bold">{t('Date')}</KizenTypography>
              <KizenTypography weight="bold">{t('Message')}</KizenTypography>
            </div>
            <div className="right-column">
              <KizenTypography>
                {format(message.created, 'MMMM D, YYYY \\a\\t h:mm A')}
              </KizenTypography>
              <KizenTypography className="text-message">
                {messageType === 'text' ? message.content : message.message}
              </KizenTypography>
            </div>
          </InfoWrapper>
        )}
        {error && (
          <ErrorSection>
            <ErrorMessagWrapper>
              <ErrorMessage size="text">
                {t(
                  'The content of this message is not available and cannot be displayed.'
                )}
              </ErrorMessage>
            </ErrorMessagWrapper>
          </ErrorSection>
        )}
      </ModalBody>
      <ModalFooter border={false}>
        <Button onClick={onHide} color="blue">
          {buttonLabel || t('Close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

SentMessageModal.propTypes = {
  message: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  isLoading: PropTypes.bool,
};

SentMessageModal.defaultProps = {
  onHide: null,
  isLoading: false,
};
