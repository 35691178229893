import styled from '@emotion/styled';

import { gutters, breakpoints } from 'app/spacing';
import Notice from 'components/Kizen/Notice';

const SuppressionListMessage = styled(Notice)`
  margin: ${gutters.spacing(4)}px ${gutters.spacing(4)}px
    ${gutters.spacing(1, -3)}px;
  padding: ${gutters.spacing(2, -2)}px ${gutters.spacing(3, -3)}px
    ${gutters.spacing(2, -3)}px;

  @media (max-width: ${breakpoints.md}px) {
    margin: ${gutters.spacing(3)}px ${gutters.spacing(2)}px 0;
  }
`;

export default SuppressionListMessage;
