import PropTypes from 'prop-types';

export const NavItemProps = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
  numberOfLinks: PropTypes.number.isRequired,
  small: PropTypes.bool,
};

export const NavLinkProps = {
  route: PropTypes.shape({
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  params: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
  numberOfLinks: PropTypes.number.isRequired,
  small: PropTypes.bool,
};

export const SubNavigationProps = {
  routes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  id: PropTypes.string,
  small: PropTypes.bool,
};

export const SubNavigationDefaultProps = {
  id: 'subnavigation',
  small: false,
};
