import css from '@emotion/css';
import styled from '@emotion/styled';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CardError } from 'ts-activities/types';
import type { ConditionOps } from 'ts-activities/ActivityRulesContext';
import {
  AddFilterButton,
  DeleteRowButton,
  Filter,
  FilterOperatorText,
  FilterRow,
  FilterSetContainer,
} from 'ts-components/filters';
import { KizenTypography } from '__app/typography';
import IconAdornment from '__components/Inputs/Adornments/IconAdornment';
import MultiSelect from '__components/Inputs/MultiSelect';

import {
  CardRow,
  RuleCard,
  RuleCardProps,
  REORDER_ICON_WIDTH,
  ROW_ONE_FLEX_GAP,
} from 'ts-components/filters/RuleCard';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import useFlashState from 'hooks/useFlashState';

export type Option = { value: string; label: string };

type ActivityRuleCardProps = Omit<
  RuleCardProps,
  'condition' | 'confirmDeleteText'
> & {
  conditions: { steps: any[]; ops: ConditionOps }[];
  errors?: CardError;
  fields?: string[];
  fieldOptions: Option[];
  modalLayer?: number;
  operation: 'any' | 'all';
  scrollToSelf?: boolean;
  onAddCondition(): void;
  onDeleteCondition(index: number): void;
  onFieldsChange(ids: string[]): void;
  onOperationChange(value: 'any' | 'all'): void;
};

const EMPTY_OBJECT = {};

const IndentedRow = styled(CardRow)`
  margin-left: ${REORDER_ICON_WIDTH + ROW_ONE_FLEX_GAP}px;
`;

const CustomFiltersRow = styled(CardRow)<{ indent: boolean }>`
  flex-direction: column;
  padding: 0;

  ${({ indent }) =>
    indent &&
    css`
      margin-left: ${REORDER_ICON_WIDTH + ROW_ONE_FLEX_GAP}px;
    `}
`;

const FieldsSelector = styled(MultiSelect)`
  width: 100%;
`;

const FieldsLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const StyledIconWithTooltip = styled(IconWithTooltip)`
  margin-left: 5px;
`;

const StyledFilterSetContainer = styled(FilterSetContainer)<{
  isActive: boolean;
}>`
  ${({ isActive }) =>
    isActive &&
    css`
      margin-left: 40px;
      margin-right: 15px;
      margin-top: 18px;
    `}
`;

const ConditionContainer = styled.div<{ marginTop?: number }>`
  display: flex;
  align-items: center;
  column-gap: 1px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
`;

const StyledDeleteRowButton = styled(DeleteRowButton)`
  align-self: flex-end;
  margin-bottom: 10px;
`;

export const ActivityRuleCard = ({
  errors,
  fields: fieldsProp = [],
  fieldOptions,
  conditions,
  id,
  isFirst,
  isLast,
  operation,
  scrollToSelf = false,
  onAddCondition,
  onDeleteCondition,
  onDelete,
  onReorderDown,
  onReorderUp,
  onFieldsChange,
  onOperationChange,
}: ActivityRuleCardProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  const [fields, setFields] = useState<Option[]>(() => {
    return fieldOptions.filter((x) => fieldsProp.includes(x.value));
  });

  const [highlighted, flashHighlighted] = useFlashState(2000, false);

  const allowedOptions = useMemo<Option[]>(
    () =>
      fieldOptions.filter(
        (o) =>
          !conditions.some((c) =>
            c.steps.some(
              ([key, { value }]) => key === 'field_id' && value?.id === o.value
            )
          )
      ),
    [fieldOptions, conditions]
  );
  const multipleConditions = conditions.length > 1;
  const fieldsError = errors?.fields
    ? { showMessage: true, message: errors?.fields }
    : null;

  const flashedOnce = useRef<boolean>(false);

  useEffect(() => {
    if (scrollToSelf && ref.current) {
      !flashedOnce.current && flashHighlighted(true);
      flashedOnce.current = true;
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [flashHighlighted, scrollToSelf, errors]);

  // we need to remove field from FieldsSelector if it is selected in Filter
  useEffect(() => {
    const options = fields.filter((o) =>
      allowedOptions.some(({ value }) => value === o.value)
    );
    if (
      options.length !== fields.length ||
      options.some(({ value }) => !fields.some((o) => o.value === value))
    ) {
      setFields(options);
      onFieldsChange(options.map((o) => o.value));
    }
  }, [allowedOptions, fields, onFieldsChange]);

  return (
    <RuleCard
      data-qa="activity-rule-card"
      ref={ref}
      condition={t('When the following conditions are met')}
      conditionInfoTooltip={t(
        'Dropdown and Dynamic Tag field types are currently supported for rule conditions.'
      )}
      confirmDeleteText={t(
        'Are you sure you want to delete this Activity Rule? All hidden fields will become shown.'
      )}
      id={id}
      isFirst={isFirst}
      isLast={isLast}
      onDelete={onDelete}
      onReorderDown={onReorderDown}
      onReorderUp={onReorderUp}
      highlighted={highlighted}
    >
      <StyledFilterSetContainer
        isActive={multipleConditions}
        showBorder={multipleConditions}
        value={operation}
        onChange={onOperationChange}
      >
        <CustomFiltersRow indent={!multipleConditions}>
          {conditions.map(({ steps, ops }, idx, arr) => (
            <ConditionContainer
              key={idx}
              marginTop={multipleConditions ? 15 : 3}
            >
              {multipleConditions && (
                <StyledDeleteRowButton onClick={() => onDeleteCondition(idx)} />
              )}
              <FilterRow
                data-qa="filter-row"
                marginTop={0}
                enableLeftPadding={!multipleConditions}
              >
                <Filter
                  steps={steps}
                  next={ops.next}
                  set={ops.set}
                  onChange={ops.update}
                  errors={errors?.conditions?.errors?.[idx] ?? EMPTY_OBJECT}
                />
                {multipleConditions && idx < arr.length - 1 && (
                  <FilterOperatorText and={operation === 'all'} />
                )}
                {idx === arr.length - 1 && (
                  <AddFilterButton
                    label={t('Add Condition')}
                    onClick={onAddCondition}
                  />
                )}
              </FilterRow>
            </ConditionContainer>
          ))}
        </CustomFiltersRow>
      </StyledFilterSetContainer>
      <IndentedRow
        column
        paddingBottom={15}
        paddingTop={multipleConditions ? 15 : 5}
      >
        <FieldsLabel>
          <KizenTypography weight="bold">
            {t('Show the following field(s)')}
          </KizenTypography>
          <StyledIconWithTooltip
            label={t(
              'The following fields will be hidden by default until the above conditions are fully met. A single field may only be set to be shown in a single rule.'
            )}
          />
        </FieldsLabel>
        <FieldsSelector
          value={fields}
          options={allowedOptions}
          placeholder={t('Choose Field(s) to Show')}
          validate={fieldsError}
          endAdornment={<IconAdornment icon="search" />}
          onChange={(opts: Option[]) => {
            setFields(opts);
            onFieldsChange(opts.map((o) => o.value));
          }}
        />
      </IndentedRow>
    </RuleCard>
  );
};
