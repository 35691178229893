import { forwardRef } from 'react';

import LongTextInlineField from 'components/Inputs/inline/LongText';
import { withCellWrapper } from './helpers';

const _LongTextCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    return (
      <LongTextInlineField
        ref={ref}
        field={field}
        object={object}
        {...others}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
      />
    );
  }
);

export const LongTextCell = withCellWrapper(_LongTextCell);
