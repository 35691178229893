import { useEffect, useRef, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { TIMELINE } from 'queries/query-keys';

import { useQueryClient } from 'react-query';

import { head } from 'utility/snippets';

export const useRouteTrigger = (routes, trigger) => {
  const { pathname } = useLocation();
  const pathnameRef = useRef(null);

  useEffect(() => {
    if (pathnameRef.current !== pathname) {
      pathnameRef.current = pathname;

      if (routes.some((route) => matchPath(pathname, route))) {
        trigger();
      }
    }
  }, [pathname, trigger, routes]);
};

export const useTimelineCachePrune = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (id) => {
      const queriesFuzzyKey = TIMELINE.RECORD(id);
      const currentData = queryClient.getQueriesData(queriesFuzzyKey);

      if (currentData.length) {
        currentData.forEach((data) => {
          const cachedPages = data?.[1]?.pages?.length || 0;

          if (cachedPages > 1) {
            const [queryKey, queryData] = data;

            queryClient.setQueryData(queryKey, {
              pageParams: [head(queryData.pageParams)],
              pages: [head(queryData.pages)],
            });
          }
        });
      }
    },

    [queryClient]
  );
};
