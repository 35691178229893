import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale, colorsButton, colorsText } from 'app/colors';
import { TextSpan } from 'app/typography';
import { UPLOAD_INPUT_TYPES } from '.';

export const UploadContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
`;

export const PreviewImageContainer = styled.div`
  ${(props) =>
    props.type === UPLOAD_INPUT_TYPES.LOGO
      ? css`
          width: 60px;
          height: 60px;
          border-radius: 4px;
          background: ${props.url ? grayScale.white : grayScale.light};
          border: 1px solid ${grayScale.medium};
          & img {
            max-width: 100%;
            max-height: 100%;
          }
        `
      : css`
          width: 100px;
          min-width: 100px;
          height: 100px;
          border-radius: 50%;
          background: ${grayScale.light};
          border: 1px solid ${grayScale.mediumDark};
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          span {
            color: ${grayScale.mediumDark};
          }
        `}

  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const placeholderCommonStyles = css`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.00125em;
`;

export const StyledLabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
  outline: none;
`;

export const StyledLabel = styled(TextSpan)`
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
`;

export const StyledFileInputWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 185px;
  margin-left: ${({ showImage }) => (showImage ? 20 : 0)}px;
  border-radius: 4px;
  padding: 0 15px;
  min-height: 36px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${escape(
    grayScale.medium
  )}' stroke-width='2' stroke-dasharray='4%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  &:hover {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${escape(
      colorsButton.blue.hover
    )}' stroke-width='2' stroke-dasharray='4%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
`;

export const StyledPlaceholder = styled.span`
  ${placeholderCommonStyles};

  margin: 10px 0;
  color: ${colorsButton.blue.default};
  font-weight: 600;

  & > span {
    ${placeholderCommonStyles};

    color: ${grayScale.mediumDark};

    input[disabled] ~ & {
      color: ${grayScale.medium};
      cursor: default;
    }
  }

  input[disabled] ~ & {
    cursor: default;
    color: ${grayScale.medium};
  }
`;

export const StyledFile = styled.span`
  margin: 10px 0 0;
  display: flex;
  max-width: 100%;

  &:first-of-type {
    margin-top: 15px;
  }

  & > button {
    width: 12px;
    height: 12px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/images/remove-file.svg);
    background-color: transparent;
    overflow: hidden;
    text-indent: -999em;
    outline: none;
  }

  & > p,
  > span {
    ${placeholderCommonStyles};

    color: ${({ created }) =>
      created ? grayScale.mediumDark : grayScale.medium};
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
