import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTimeoutFn } from 'react-use';

export default function useFlashState(ms = 3000, initialState) {
  const [state, setState] = useState(initialState);
  const [, cancel, scheduleEndState] = useTimeoutFn(
    () => setState(initialState),
    ms
  );
  useEffect(() => {
    cancel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const flashState = useCallback(
    (value) => {
      setState(value);
      scheduleEndState();
    },
    [scheduleEndState]
  );
  return [state, flashState];
}

// This can be used alongside css animations
export function useFlashTransition(
  { transition = 300, stay = 3000 } = {},
  initialState
) {
  const [state, flashState] = useFlashState(
    2 * transition + stay,
    initialState
  );
  const [show, flashShow] = useFlashState(transition + stay, false);
  const flashTransition = useCallback(
    (value) => {
      flashShow(true);
      flashState(value);
    },
    [flashState, flashShow]
  );
  const cancelFlash = useCallback(() => {
    flashShow(false);
    flashState(initialState);
  }, [flashState, flashShow, initialState]);
  return [state, show, flashTransition, cancelFlash];
}

export function useFlashValidation(
  opts = { transition: 300, stay: 3000, initialState: undefined }
) {
  const [state, showMessage, flashTransition, cancelFlash] = useFlashTransition(
    { transition: opts?.transition || 300, stay: opts?.stay || 3000 },
    opts?.initialState
  );

  const handleFlash = useCallback(
    (value) => {
      if (typeof value === 'string') {
        flashTransition({ message: value });
      } else {
        flashTransition(value);
      }
    },
    [flashTransition]
  );

  const validate = useMemo(
    () => ({
      ...state,
      showMessage,
    }),
    [state, showMessage]
  );

  return [validate, handleFlash, cancelFlash];
}
