import { useBreakpoint } from 'app/spacing';
import AutomationsService from 'services/Automation2Service';
import { useTranslation } from 'react-i18next';
import {
  ComponentWrapper,
  PaginationWrapper,
  StyledModal,
  StyledTableScrollContainer,
  TableWrapper,
} from './styles';
import TablePagination from 'components/Tables/Big/TablePagination';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import { TRow } from 'components/Kizen/Table';
import { useAsync } from 'react-use';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { useColumns } from './hooks';
import BasicTable from 'components/Tables/Basic';
import { DEFAULT_PAGINATION_SETTINGS } from './constants';
import { usePaginationSettings } from 'hooks/usePaginationSettings';

export const AutomationHistoryModal = ({ automation, modalProps }) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint();

  const {
    page,
    pageSize,
    ordering,
    handleChangePage,
    handleChangePageSize,
    headData,
  } = usePaginationSettings(DEFAULT_PAGINATION_SETTINGS);

  const { value: { results = EMPTY_ARRAY, count } = {}, loading } =
    useAsync(async () => {
      return AutomationsService.getAutomationHistory(automation.id, {
        page,
        pageSize,
        ordering,
      });
    }, [page, pageSize, ordering, automation.id]);

  const columns = useColumns();

  return isMobile ? null : (
    <StyledModal
      buttonText={t('Close')}
      actionBtnColor="blue"
      leftBtn={null}
      heading={t('Automation History Log')}
      size="medium"
      {...modalProps}
      displayFlex
    >
      <ComponentWrapper>
        <TableWrapper>
          <StyledTableScrollContainer bottom top gradient={<Gradient />}>
            <BasicTable
              key={`table-${automation.id}`}
              stickyHeader
              head={<TRow head columns={columns} data={headData} />}
              data-qa="automation-history-table"
              staleData={loading}
            >
              {count ? null : <TRow />}
              {results.map((data) => (
                <TRow key={data.id} columns={columns} data={data} />
              ))}
            </BasicTable>
          </StyledTableScrollContainer>
        </TableWrapper>
        <PaginationWrapper>
          <TablePagination
            page={page}
            perPage={pageSize}
            totalCount={count}
            onChangePage={handleChangePage}
            onChangePerPage={handleChangePageSize}
          />
        </PaginationWrapper>
      </ComponentWrapper>
    </StyledModal>
  );
};
