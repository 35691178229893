import { createContext } from 'react';
import {
  DropZoneReducer,
  GridStateValue,
  LayoutAction,
  LayoutActionType,
  LayoutState,
} from './types';
import { idChainSeparator } from './util';

export const GridState = createContext<GridStateValue>({
  setDragItem: () => {},
  setDragGroup: () => {},
  setGroupDropZones: () => {},
  dropZones: {},
  groupDropZones: {},
  setDropZones: () => {},
  registerChild: () => {},
  handleDrop: () => {},
  handleDeleteRow: () => {},
  layout: [],
  setPlaceholderPosition: () => {},
});

export const childrenReducer: DropZoneReducer = (state, action) => {
  const res = state;
  if (action.type === 'register') {
    // First, clear out the old registered child
    Object.keys(res).forEach((dropZoneId) => {
      if (res[dropZoneId]?.[action.draggableId]) {
        res[dropZoneId][action.draggableId] = undefined;
      }
    });

    // Register the new child
    if (!res[action.dropZoneId]) {
      res[action.dropZoneId] = {};
    }
    if (!res[action.dropZoneId][action.draggableId]) {
      res[action.dropZoneId][action.draggableId] = action.element;
    }
  }

  return res;
};

export const layoutReducer = (state: LayoutState, action: LayoutAction) => {
  const res: LayoutState = structuredClone(state);

  if (
    action.type === LayoutActionType.DROP &&
    action.dropZone &&
    action.dragItem
  ) {
    const { id, direction } = action.dropZone;
    const { dragItem } = action;

    // First, remove the item from its current position
    {
      const [rowId, columnId] = dragItem.parent.split(idChainSeparator);
      const rowIndex = res.findIndex((row) => row.id === rowId);
      if (rowIndex > -1) {
        const colIndex = res[rowIndex].columns.findIndex(
          (col) => col.id === columnId
        );

        if (colIndex > -1) {
          const itemIndex = res[rowIndex].columns[colIndex].items.findIndex(
            (item) => item.id === dragItem.id
          );

          res[rowIndex].columns[colIndex].items.splice(itemIndex, 1);
        }
      }
    }

    // Add it to the new position
    {
      const [rowId, columnId, itemId] = id.split(idChainSeparator);

      const rowIndex = res.findIndex((row) => row.id === rowId);
      if (rowIndex > -1) {
        const colIndex = res[rowIndex].columns.findIndex(
          (col) => col.id === columnId
        );

        if (colIndex > -1) {
          const itemIndex = res[rowIndex].columns[colIndex].items.findIndex(
            (item) => item.id === itemId
          );

          const insertionIndex = direction === 'up' ? itemIndex : itemIndex + 1;

          const { parent, ...payload } = dragItem;

          res[rowIndex].columns[colIndex].items.splice(
            insertionIndex,
            0,
            structuredClone(payload)
          );
        }
      }
    }
  } else if (action.type === LayoutActionType.DROP_GROUP) {
    const { dragGroup, dropZone } = action;

    if (dragGroup && dropZone) {
      const { id, direction } = dropZone;

      const rowIndex = res.findIndex((row) => row.id === dragGroup.id);
      if (rowIndex > -1) {
        const [removed] = res.splice(rowIndex, 1);

        const dropIndex = res.findIndex((row) => row.id === id);
        if (dropIndex > -1) {
          const insertionIndex = direction === 'up' ? dropIndex : dropIndex + 1;
          res.splice(insertionIndex, 0, structuredClone(removed));
        }
      }
    }

    return res;
  } else if (action.type === LayoutActionType.REPLACE && action.initial) {
    return action.initial;
  } else if (action.type === LayoutActionType.DELETE && action.rowId) {
    const rowIndex = res.findIndex((row) => row.id === action.rowId);
    if (rowIndex > -1) {
      res.splice(rowIndex, 1);
    }
  }

  return res;
};
