import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import Select from 'components/Kizen/Select';
import Checkbox from 'components/Checkbox/Checkbox';
import useToggle from 'react-use/lib/useToggle';
import Overlay from 'react-bootstrap/Overlay';
import styled from '@emotion/styled';
import { borderTight } from 'app/colors';
import { layers } from 'app/spacing';

const CheckboxPositioned = styled(Checkbox)`
  position: relative;
  overflow: visible;
  margin: 0;
  padding: 0;
  .CheckboxText {
    display: none;
  }
  .CheckboxInputFacade {
    // In chrome the input facade's position: relative creates a new stacking
    // context, causing this element to appear on top of the page's sticky header.
    z-index: ${({ zIndex }) => (zIndex ? zIndex : layers.content(0))};
    height: 17px;
    width: 17px;
    margin-left: -1px;
  }
  input:not(:checked) + .CheckboxInputFacade {
    // The width bumps the checkmark, so we remove the border when checked
    border-width: 1.5px;
  }
`;

const Menu = styled(Select)`
  .CheckboxMenu__control {
    display: none;
  }
  .CheckboxMenu__menu {
    overflow: hidden; // Primarily for border-radius
    width: auto;
    max-width: 200px;
    ${borderTight};
  }
`;

const MenuWrapper = styled.div`
  z-index: ${layers.content(0, 1)};
`;

export default function CheckboxMenu({
  checked,
  indeterminate,
  position,
  className,
  children,
  zIndex,
  onOpenSelectionMenu,
  ...others
}) {
  const [open, toggleOpen] = useToggle(false);
  const targetRef = useRef(null);

  const handleChange = () => {
    if (!open && onOpenSelectionMenu) {
      onOpenSelectionMenu();
    }
    toggleOpen();
  };

  return (
    <>
      {indeterminate ? (
        <CheckboxPositioned
          ref={targetRef}
          indeterminate={indeterminate}
          onChange={handleChange}
          className={className}
          zIndex={zIndex}
        >
          {children}
        </CheckboxPositioned>
      ) : (
        <CheckboxPositioned
          ref={targetRef}
          checked={checked}
          onChange={handleChange}
          className={className}
          zIndex={zIndex}
        >
          {children}
        </CheckboxPositioned>
      )}
      <Overlay
        transition={false}
        target={targetRef.current}
        show={open}
        placement="bottom-start"
        rootClose
        onHide={({ target }) => {
          if (targetRef.current && targetRef.current.contains(target)) {
            return;
          }
          toggleOpen(false);
        }}
      >
        <MenuWrapper onClick={toggleOpen}>
          <Menu
            fullWidth
            position={position}
            classNamePrefix="CheckboxMenu"
            menuIsOpen
            isOptionDisabled={(o) => o.disabled}
            {...others}
          />
        </MenuWrapper>
      </Overlay>
    </>
  );
}

CheckboxMenu.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
};

CheckboxMenu.defaultProps = {
  checked: false,
  indeterminate: false,
  position: 'left',
};
