import { ElementContainerProps } from '../../types';

export const applyLegacyContainerPadding = (
  props: Partial<ElementContainerProps>
) => {
  return (
    props.containerPaddingTop === undefined &&
    props.containerPaddingRight === undefined &&
    props.containerPaddingBottom === undefined &&
    props.containerPaddingLeft === undefined
  );
};

/**
 * Initially, the default background color for sections, rows, and content was 'inherit'. This
 * does not work when background images were added, so the default has been changed to be transparent.
 * This utility needs to be called when rendering a builder or viewer node as well as in the settings
 * element that controls the background color.
 */
export const handleLegacyBackgroundColor = (backgroundColor: string) => {
  return backgroundColor === 'inherit' ? 'rgba(0,0,0,0)' : backgroundColor;
};
