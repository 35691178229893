import { OverviewPage, RetargetingPage, ConnectionsPage } from './pages';

export const AdsUrlBase = '/ads';

const routes = {
  overview: {
    label: (t) => t('Overview'),
    component: OverviewPage,
    exact: true,
    preRelease: true,
    icon: 'bullseye-pointer',
    path: `${AdsUrlBase}/overview`,
  },
  retargeting: {
    label: (t) => t('Retargeting'),
    component: RetargetingPage,
    exact: true,
    preRelease: true,
    icon: 'people',
    path: `${AdsUrlBase}/retargeting`,
  },
  connections: {
    label: (t) => t('Connections'),
    component: ConnectionsPage,
    exact: true,
    preRelease: false,
    icon: 'share-alt',
    path: `${AdsUrlBase}/connections`,
  },
};
export default routes;
