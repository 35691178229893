import PriceInput from '__components/Inputs/TextInput/presets/PriceNumber';
import { InputProps } from './types';

export type PriceProps = {
  currencySymbol: string;
};

export const parsePrice = (x: string) => {
  const float = parseFloat(x.replaceAll(',', ''));

  if (isNaN(float)) {
    return undefined;
  }

  return float % 1 === 0 ? Math.floor(float) : float;
};

export const Price = ({
  step,
  value,
  width,
  placeholder,
  currencySymbol,
  error,
  onChange,
}: InputProps & PriceProps) => (
  <PriceInput
    value={value}
    width={width}
    placeholder={placeholder}
    currencySymbol={currencySymbol}
    error={error}
    onChange={(val: string) => onChange(step, parsePrice(val))}
  />
);
