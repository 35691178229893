import { useMemo } from 'react';
import {
  ApplyMenuButton,
  ClearMenuButton,
  TopMenuButton,
} from 'components/Kizen/Menu';
import { useTranslation } from 'react-i18next';
import Select from 'components/Inputs/Select';
import { FIELD_TYPES } from 'utility/constants';
import { CHECKBOX_FACET_VALUE } from '../constants';
import useField from 'hooks/useField';
import { useInfiniteFacetedOptions } from '../hooks/useInfiniteFacetedOptions';
import { useAutoFocus } from '../hooks/useAutoFocus';

const CheckBoxSingleselect = ({
  field,
  model,
  onChange,
  error, // exclude from ...props
  onHide,
  value,
  isReadOnly,
  fullFilterObject,
  search: entitySearch,
  showFacetCounts,
  hideFacetCountsOnTimeout,
  autoFocus,
  ...props
}) => {
  const { t } = useTranslation();

  const [innerValue, setInnerValue] = useField(() => {
    const checkboxValue = value
      ? CHECKBOX_FACET_VALUE.checked
      : CHECKBOX_FACET_VALUE.unchecked;
    return typeof value === 'boolean'
      ? {
          value: checkboxValue,
          label:
            checkboxValue === CHECKBOX_FACET_VALUE.checked
              ? t('Is Checked')
              : t('Isn’t Checked'),
        }
      : value;
  }, [value]);

  const [includeFacetCount, setIncludeFacetCount] = useField(
    Boolean(showFacetCounts && !(typeof value === 'boolean') && !error)
  );

  const chosenValueIds = useMemo(
    () => (innerValue ? [innerValue.value] : []),
    [innerValue]
  );

  const { selectRef, typeaheadProps } = useInfiniteFacetedOptions({
    field,
    model,
    includeFacetCount,
    fullFilterObject,
    entitySearch,
    hideFacetCountsOnTimeout,
    chosenValueIds,
  });

  const handleClickApply = () => {
    onHide();
    onChange(
      innerValue ? innerValue.value === CHECKBOX_FACET_VALUE.checked : undefined
    );
  };

  const handleClickClear = () => {
    onHide();
    onChange();
  };

  useAutoFocus(selectRef.current?.select?.inputRef, isReadOnly || !autoFocus);

  return (
    <Select
      menuTopButton={
        includeFacetCount ? (
          <TopMenuButton onClick={() => setIncludeFacetCount(false)}>{`${t(
            'Show All Options (No Facets)'
          )}`}</TopMenuButton>
        ) : null
      }
      ref={selectRef}
      {...props}
      {...typeaheadProps}
      field={field}
      value={innerValue}
      onChange={setInnerValue}
      isReadOnly={isReadOnly}
      disabled={isReadOnly}
      menuIsOpen={!isReadOnly}
      menuLeftButton={
        !isReadOnly && (
          <ClearMenuButton onClick={handleClickClear}>
            {t('Clear & Close')}
          </ClearMenuButton>
        )
      }
      menuRightButton={
        !isReadOnly && <ApplyMenuButton onClick={handleClickApply} />
      }
      placeholder={t('Find Options')}
    />
  );
};

export const FacetFieldSingleselect = ({ field, ...props }) => {
  if (field?.fieldType === FIELD_TYPES.Checkbox.type) {
    return <CheckBoxSingleselect {...props} field={field} />;
  }

  return null;
};
