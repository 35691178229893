import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  placing: false,
  lastPlacingItem: null,
  createItemInfo: null,
};

const pageBuilderSlice = createSlice({
  name: 'pageBuilder',
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    placing: (state, { payload: item }) => {
      if (item) {
        state.placing = true;
        state.lastPlacingItem = item; // e.g. { type: 'Row', columns: 2 }
      } else {
        state.placing = false;
      }
    },
    drop: (state, { payload: dropInfo }) => {
      const { lastPlacingItem } = state;

      state.placing = false;
      state.lastPlacingItem = null;

      if (!lastPlacingItem || !dropInfo) {
        return;
      }

      const { index, parentId } = dropInfo;

      // e.g. { type: 'Row', columns: 2, placement: { index, parentId } }
      state.createItemInfo = {
        ...lastPlacingItem,
        placement: { index, parentId },
      };
    },
    created: (state) => {
      state.createItemInfo = null;
    },
  },
});

export const { reset, placing, drop, created } = pageBuilderSlice.actions;

export const { reducer } = pageBuilderSlice;
