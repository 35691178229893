import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import ImageSelector from 'components/Kizen/GridSelector';

export const SelectorGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${gutters.spacing(2)}px;

  > * {
    margin: ${gutters.spacing(2)}px;

    > svg {
      margin-top: auto;
    }

    > span {
      margin-top: ${gutters.spacing(2, -2)}px;
    }
  }
`;

export const StyledIconBlock = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const StyledImageSelector = styled(ImageSelector)`
  justify-content: space-evenly;
  width: calc(25% - ${gutters.spacing(4)}px);
  height: 80px;
  padding: 0 0 ${gutters.spacing(1, 2)}px;
`;
