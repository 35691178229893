import { getOptionValue, fieldToOptionDeleted } from 'services/helpers';

import {
  setDelayAmount,
  checkDeleted,
  displayNameDescriptor,
  dateOffsetValuesTranslated,
} from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { fieldId, period, delayAmount, ...other } = orgDetails;
  if (other.dateOffset === dateOffsetValuesTranslated((str) => str)[0].value) {
    delete other.dateOffsetDays;
  }
  return {
    stepGoal: {
      ...orgDetails,
      delayAmount: setDelayAmount(delayAmount),
      triggers: [
        {
          type: stepConfig.onOrAroundDate.type,
          triggerOnOrAroundDate: {
            ...other,
            fieldId: getOptionValue(fieldId),
            period: period.toLowerCase(),
          },
        },
      ],
    },
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { field, period, dateOffsetDays, dateOffset, time, everyYear } =
    firstTrigger.triggerOnOrAroundDate;
  checkDeleted(
    messageDictionary,
    addErrorMessage,
    field,
    displayNameDescriptor
  );
  return {
    fieldId: fieldToOptionDeleted(field),
    everyYear,
    period: period.toUpperCase(),
    dateOffsetDays: dateOffsetDays || null,
    dateOffset,
    time,
    type: stepConfig.onOrAroundDateGoal.type,
  };
};

export const onOrAroundDateDTO = {
  forApi,
  forFlow,
};
