import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import TextInput from 'components/Inputs/TextInput';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useFlashValidation } from 'hooks/useFlashState';
import { useModalControl } from 'hooks/useModalControl';
import { getUserEmail } from 'store/authentication/selectors';
import { isEmailValid } from 'utility/validate';

export const SendTestEmailButton = ({ onConfirm }) => {
  const userEmail = useSelector(getUserEmail);
  const [emailAddress, setEmailAddress] = useState(userEmail);
  const [sending, setSending] = useState(false);
  const [modalOpen, { showModal, hideModal }] = useModalControl();
  const [error, showError] = useFlashValidation();
  const { t } = useTranslation();

  const handleConfirm = async () => {
    if (sending) return;
    if (isEmailValid(emailAddress)) {
      setSending(true);
      const success = await onConfirm(emailAddress);
      if (success) {
        setEmailAddress(userEmail);
        hideModal();
      }
      setSending(false);
    } else {
      showError({ message: t('Invalid email address.') });
    }
  };

  return (
    <>
      {modalOpen && (
        <BasicModal
          show={modalOpen}
          heading={t('Send Test Email')}
          buttonText={t('Send')}
          onHide={hideModal}
          onConfirm={handleConfirm}
        >
          <TextInput
            inModal
            label={t('To Email Address')}
            value={emailAddress}
            onChange={setEmailAddress}
            validate={error}
          />
        </BasicModal>
      )}
      <Button color="blue" onClick={showModal}>
        {t('Send Test Email')}
      </Button>
    </>
  );
};
