import { grayScale, shadowLight, colorsButton } from 'app/colors';
import styled from '@emotion/styled';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { layers } from 'app/spacing';
import css from '@emotion/css';

export const ConfigurationArea = styled.div`
  display: flex;
  width: 100%;
  column-gap: 20px;
  ${({ noTopMargin }) =>
    noTopMargin
      ? css`
          margin-top: 0;
        `
      : css`
          margin-top: 20px;
        `}
  height: 0;
  ${({ fullHieght }) =>
    fullHieght
      ? css`
          height: 100%;
        `
      : css`
          height: 0;
          flex-grow: 1;
        `}
`;

export const ConfigurationPanel = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  border: 1px solid ${grayScale.medium};
  ${({ highlighted }) =>
    highlighted
      ? css`
          border: 1px dashed ${colorsButton.blue.default};
        `
      : ''}
  border-radius: 4px;
  background: white;
  overflow: hidden;
  position: relative;
`;

export const ConfigurationPanelHeader = styled.div`
  padding: ${({ grow }) =>
    grow ? '22px 20px 22px 20px' : '11px 20px 11px 20px'};
  border-bottom: 1px solid ${grayScale.medium};
  display: flex;
  align-items: center;
  ${({ scrolled }) => (scrolled ? shadowLight : '')}
  z-index: ${({ zIndex }) =>
    zIndex !== undefined ? zIndex : layers.content(9, 9)};
`;

export const ColumnSearchContainer = styled.div`
  margin-left: auto;
`;

export const ConfigurationPanelBody = styled(TableScrollContainer)`
  flex-grow: 1;
  width: 100%;
  height: 0;
  overflow-y: auto;
  ${({ minHeight }) => (minHeight ? 'overflow-y: hidden' : '')}
`;
