import React, { useCallback, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import YesNoMaybeComponent from 'components/Inputs/YesNoMaybe';

const YesNoMaybe = React.forwardRef(({ field, onChange, ...others }, ref) => {
  const options = useMemo(() => {
    return field.options.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
  }, [field]);

  const handleChange = useCallback(
    (opt) => onChange(opt ? opt.value : null),
    [onChange]
  );

  return (
    <YesNoMaybeComponent
      ref={ref}
      options={options}
      onChange={handleChange}
      {...others}
    />
  );
});

YesNoMaybe.displayName = 'YesNoMaybe';
YesNoMaybe.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default YesNoMaybe;
