// import { gutters } from 'app/spacing';
import React from 'react';
import styled from '@emotion/styled';
import ButtonWithDropdown from 'components/Button/WithDropdown';

const StyledButtonWithDropdown = styled(ButtonWithDropdown)`
  margin: 0;
  && button:first-of-type {
    padding: 0;
  }
  && button:last-of-type {
    padding-left: 5px;
    padding-right: 0;
    margin-top: -2px;
  }
`;

export const BuilderAddElementDropdown = (props) => (
  <StyledButtonWithDropdown
    {...props}
    restrictWidth={false}
    bottomOffset="15"
    clickToOpen
  />
);
