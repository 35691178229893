import searchState from 'components/AccessRequests/searchState';
import useDashboardList from 'components/AccessRequests/useDashboardList';
import { PageSizing } from 'components/Layout/PageContentWidth';
import {
  SubNavigationNavBar,
  SubNavigationNavLink,
} from 'components/Navigation/SubNavigation';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Switch, Route, useLocation } from 'react-router-dom';
import AccessRequestsChartGroupsPage from '../AccessRequests';
import MyChartGroupsPage from '../Mine';
import CreateLayoutModal from 'components/DashboardGrid/CreateLayoutModal';
import RequestModal from 'components/AccessRequests/modal';
import useClientObjectChartGroupConfig from 'pages/ContactsPage/useContactsChartGroupConfig';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const subRoutes = [
  {
    label: (t) => t('My Chart Groups'),
    path: '/clients/charts/settings/mine',
    icon: 'user-circle',
    saveLocationOnNavigate: true,
    exact: true,
    Component: MyChartGroupsPage,
    dataKey: 'mine',
  },
  {
    label: (t) => t('Chart Group Access Requests'),
    path: '/clients/charts/settings/others',
    icon: 'share-alt',
    saveLocationOnNavigate: true,
    exact: true,
    Component: AccessRequestsChartGroupsPage,
    dataKey: 'other',
  },
];

const ContactsChartGroupSettingsController = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { debouncedTerm } = useContext(searchState.Context);

  useEffect(() => {
    if (location.pathname === '/clients/charts/settings') {
      history.replace('/clients/charts/settings/mine');
    }
  }, [location, history]);

  const clientObject = useSelector(getBusinessClientObject);

  const clientObjectId = clientObject?.id;

  const {
    modalProps,
    requestModalProps,
    canUserEditPermissions,
    existingDashboard,
    data,
    loading,
    configLoading,
    refetching,
    ...routeProps
  } = useDashboardList(
    { debouncedTerm, objectId: clientObjectId, type: 'chart_group' },
    useClientObjectChartGroupConfig
  );
  return (
    <>
      <SubNavigationNavBar>
        {subRoutes.map((route, index) => {
          return (
            <SubNavigationNavLink
              key={`${route.path}-link`}
              route={{
                label: route.label(t),
                path: route.path,
                icon: route.icon,
              }}
              numberOfLinks={subRoutes.length}
              isLast={index === subRoutes.length - 1}
            />
          );
        })}
      </SubNavigationNavBar>
      <CreateLayoutModal
        {...modalProps}
        canUserEditPermissions={canUserEditPermissions}
        existing={existingDashboard}
        entity={t('Chart Group')}
      />
      <RequestModal {...requestModalProps} dashboard={existingDashboard} />
      <PageSizing>
        <Switch>
          {subRoutes.map((route) => {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                {/* These components are the page in /pages */}
                <route.Component
                  goBackPath="/clients"
                  data={data[route.dataKey] ?? []}
                  loading={loading[route.dataKey] || configLoading}
                  isRefetching={refetching[route.dataKey]}
                  {...routeProps}
                />
              </Route>
            );
          })}
        </Switch>
      </PageSizing>
    </>
  );
};

export default ContactsChartGroupSettingsController;
