/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import {
  grayScale,
  colorsButton,
  colorsText,
  colorsSecondary,
  dropdownColors,
} from 'app/colors';
import { Row, Col } from 'react-bootstrap';
import { gutters, breakpoints } from 'app/spacing';
import {
  fontSizes,
  TextSpan,
  KizenTypography,
  fontWeights,
} from 'app/typography';
import TextInput from 'components/Inputs/TextInput';
import { CardBasic } from 'components/Card/Card';
import ImageFileInputWithUploadBase from 'components/Kizen/FileInputWithImage/WithUpload';

export const CardContainer = styled.div`
  > div {
    margin-bottom: ${gutters.spacing(4)}px;
  }
`;

export const ButtonsContainer = styled.div`
  > :not(:first-child) {
    margin-left: ${gutters.spacing(4)}px;
  }
`;

export const StyledRow = styled(Row)`
  margin-bottom: ${gutters.spacing(4)}px;
  &.no-margin {
    margin-bottom: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &.margin {
    margin-bottom: ${gutters.spacing(4)}px;
  }
`;

export const StyledCol = styled(Col)`
  padding: 0 ${gutters.spacing(2)}px;
  flex-basis: 50%;
  @media (min-width: ${breakpoints.md}px) {
    flex-basis: 0;
  }
`;

export const StyledFormInput = styled(TextInput)`
  & input[type='password'] {
    -webkit-text-security: disc;
    text-security: disc;
  }
  &.credit-card div span {
    display: flex;
    align-self: flex-end;
  }
`;

export const StyledFormError = styled(KizenTypography)`
  margin-top: ${gutters.standard};
  text-align: center;
  color: ${colorsSecondary.red.dark};
`;

export const StyledInputFormError = styled(StyledFormError)`
  border: 1px solid ${colorsSecondary.red.dark};
  border-radius: 4px;
  width: auto;
  padding: ${gutters.spacing(2, { baseline: true })}px;
  font-size: ${fontSizes.text};
  text-align: center;
  color: ${grayScale.dark};
  margin-top: -1px;
  display: inline-block;
`;

export const WysiwygWrapper = styled.div`
  && {
    flex: 1;
    resize: none;
    outline: none;
    margin-top: ${gutters.spacing(3, { baseline: true })}px;
  }
`;

export const StyledLabel = styled(TextSpan)`
  color: ${colorsText.dark};
  display: flex;
`;

export const StyledAnchor = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: ${fontSizes.buttonLabel};
  font-weight: ${fontWeights.bold};
  margin: 0;
  line-height: 100%;
  letter-spacing: 0.8px;

  color: ${colorsButton.green.default};
  border: unset;
  :hover {
    text-decoration: none;
    color: ${colorsButton.green.hover};
  }
  :active {
    color: ${colorsButton.green.hover};
  }
  :focus {
    text-decoration: none;
    color: ${colorsButton.green.hover};
  }
  :disabled {
    opacity: 1;
    color: ${grayScale.medium};
  }
`;

export const ActionsIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${gutters.spacing(2)}px;
`;

export const ActionsIcon = styled.div`
  text-align: right;
  cursor: pointer;
  > i {
    font-size: 16px;
  }
`;

export const ActionsContainer = styled.div`
  position: relative;
`;

export const ActionsMenu = styled.div`
  border: 1px solid ${grayScale.medium};
  background-color: ${grayScale.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: ${gutters.spacing(14)}px;
  button {
    color: ${grayScale.dark};
    font-weight: normal;
    font-size: ${fontSizes.text};
    line-height: 17px;
    letter-spacing: -0.00125em;
    text-transform: none;
    padding: ${gutters.spacing(2)}px;
    height: auto;
    line-height: 100%;
    margin: 0;
    border-radius: 0;
  }
  button:first-of-type {
    border-bottom: 1px solid ${grayScale.medium};
  }
  button:hover {
    color: ${grayScale.dark};
    background-color: ${dropdownColors.hover};
  }
`;

export const ProfileCard = styled(CardBasic)`
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: ${gutters.spacing(4)}px;
  > div:last-of-type {
    flex: 1;
  }
`;

export const ApiCard = styled(ProfileCard)`
  min-height: 500px;
  max-height: 500px;
`;

export const ImageFileInputWithUpload = styled(ImageFileInputWithUploadBase)`
  margin-bottom: 23px;
`;
