import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, dropdownColors, grayScale } from 'app/colors';
import { gutters, layers } from 'app/spacing';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { variantProp } from '../props';

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: unset; // Undo global label style
  // These two ensure that the height of the parent doesn't
  // change when the box is checked versus unchecked. Just an inline sizing quirk.
  line-height: 0;
  vertical-align: top;
  > input {
    position: absolute;
    opacity: 0;
    z-index: ${layers.content(0)};
    & + * {
      flex-shrink: 0;
      .RadioDot {
        display: none;
      }
    }
    &:checked + * {
      border-color: ${colorsButton.blue.hover};
      background: ${grayScale.white};
      .RadioDot {
        display: initial;
      }
    }
    &:disabled + * {
      border-color: ${grayScale.mediumDark};
      background-color: ${grayScale.white};
      .RadioDot {
        background-color: ${grayScale.mediumDark};
      }
    }
    &:disabled + * + * {
      color: ${grayScale.mediumDark};
    }
    &:focus-visible + * {
      border-color: ${colorsButton.blue.hover};
      background: ${grayScale.white};
    }
    &:focus-visible:checked + * {
      background-color: ${dropdownColors.hover};
    }
  }
  &:hover > input:not(:disabled) + * {
    border-color: ${colorsButton.blue.hover};
    background: ${grayScale.white};
  }
  &:hover > input:checked:not(:disabled) + *,
  &:focus-visible > input:checked:not(:disabled) + * {
    background-color: ${dropdownColors.hover};
  }
  ${({ error }) =>
    error &&
    css`
      > input {
        & + *,
        &:checked + * {
          border-color: ${colorsButton.red.hover};
        }
      }
      &:hover > input:not(:disabled) + * {
        border-color: ${colorsButton.red.hover};
      }
    `}
`;

const VisualRadio = styled.span`
  box-sizing: content-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${grayScale.mediumDark};
  background-color: ${grayScale.white};
  border-radius: 50%;
  &:not(:last-child) {
    margin-right: ${gutters.spacing(2)}px;
  }
  width: 15px;
  height: 15px;
  .RadioDot {
    background-color: ${colorsButton.blue.hover};
    border-radius: 50%;
    height: 11px;
    width: 11px;
  }
  ${({ variant }) =>
    variant === 'underline' &&
    css`
      width: 12px;
      height: 12px;
      .RadioDot {
        height: 10px;
        width: 10px;
      }
    `}
`;

export const Radio = forwardRef(
  ({ label, variant, error, onChange, className, ...props }, ref) => {
    return (
      <Label className={className} error={error}>
        <input
          ref={ref}
          type="radio"
          aria-checked={props.checked}
          onChange={(ev) => {
            if (onChange) onChange(ev.target.checked, ev);
          }}
          {...props}
        />
        <VisualRadio variant={variant}>
          <div className="RadioDot" />
        </VisualRadio>
        {typeof label !== 'string' && label}
        {typeof label === 'string' && (
          <TextEllipsisWithTooltip as="span">{label}</TextEllipsisWithTooltip>
        )}
      </Label>
    );
  }
);

Radio.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: variantProp,
  label: PropTypes.node,
  error: PropTypes.bool,
};

Radio.defaultProps = {
  checked: undefined,
  onChange: null,
  value: null,
  variant: null,
  label: PropTypes.node,
  error: null,
};

export default Radio;
