import { useCallback, useEffect, useRef } from 'react';

import { monitoringExceptionHelper } from 'sentry/helpers';

const DEPLOYMENT_KEY = 'deploymentHash';
const FIVE_MINUTES = 5 * 60 * 1000;

export const MonitorDeploymentVersion = ({ history }) => {
  const stagedPathname = useRef(history.location.pathname);
  const needReload = useRef(false);

  const poll = useCallback(async () => {
    try {
      const hashResponse = await fetch('/meta.txt');
      const hash = await hashResponse.text();
      const storedHash = sessionStorage.getItem(DEPLOYMENT_KEY);

      if (hash && !storedHash) {
        sessionStorage.setItem(DEPLOYMENT_KEY, hash);
      } else if (hash && storedHash !== hash) {
        sessionStorage.setItem(DEPLOYMENT_KEY, hash);
        console.log(
          `%cA new deployment version detected, hash - ${hash}`,
          'color: #1f883d;'
        );
        needReload.current = true;
      }
    } catch (err) {
      monitoringExceptionHelper(err);
    }
  }, []);

  useEffect(() => {
    return history.listen(({ pathname }) => {
      if (stagedPathname.current !== pathname) {
        stagedPathname.current = pathname;
        if (needReload.current) {
          window.location.reload();
        }
      }
    });
  }, [history]);

  useEffect(() => {
    poll();
    const interval = setInterval(() => {
      poll();
    }, FIVE_MINUTES);
    return () => clearInterval(interval);
  }, [poll]);

  return null;
};
