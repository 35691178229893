import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';
import useFilters from 'components/Wizards/MetaFilters/hooks/useFilters';
import { useCallback, useMemo } from 'react';

const useDynamicFilterObject = ({
  isForCustomObject,
  area,
  selectedObject,
  customObject,
  filterState,
  onChangeSelectedObject,
  onConfirm,
  allowResetSelectedObject = true,
  clientObject,
  hideGroups,
}) => {
  const object = useMemo(() => {
    const result =
      area === AREA_RESPONSES.ACTIVITIES ? selectedObject : customObject;

    return {
      ...result,
      // objectClass will not be returned from the API on this object, so we need to manually add it
      objectClass: isForCustomObject ? 'customObjects' : 'contacts',
    };
  }, [area, customObject, selectedObject, isForCustomObject]);

  const filterProps = useFilters({
    isForCustomObject,
    customObject: object,
    state: filterState,
    clientObject,
    hideGroups,
  });

  const clearValues = useCallback(() => {
    const cleared = {
      type: null,
      groups: [],
      filter: {
        and: null,
        query: [],
      },
    };

    filterProps.setValues(cleared);
  }, [filterProps]);

  const onClear = useCallback(() => {
    clearValues();
    if (allowResetSelectedObject) {
      onChangeSelectedObject(null);
    }
  }, [clearValues, onChangeSelectedObject, allowResetSelectedObject]);

  const internalHandleConfirm = useCallback(
    (obj) => {
      onChangeSelectedObject(selectedObject);
      onConfirm(obj, selectedObject);
    },
    [onChangeSelectedObject, selectedObject, onConfirm]
  );

  const filtersAreEmpty =
    area === AREA_RESPONSES.ACTIVITIES
      ? !selectedObject && !filterProps?.values?.type
      : !filterProps?.values?.type;

  return {
    filterProps,
    onClear,
    filtersAreEmpty,
    onFilterConfirm: internalHandleConfirm,
  };
};

export default useDynamicFilterObject;
