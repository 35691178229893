import { AuthLocalStorage } from 'utility/AuthLocalStorage';

export const KIZEN_PREFIX = 'kizen-';
export const AUTH_TOKEN_KEY = `token`;

const AUTHORIZED_KEY = `auth`;
export const KIZEN_AUTHORIZED_KEY = `${KIZEN_PREFIX}${AUTHORIZED_KEY}`; // this key is used to sync browser instances of kizen !!

const FORM_BUILDER_JSON_KEY = `form-builder-json`;
const SURVEY_BUILDER_JSON_KEY = `survey-builder-json`;

export const get = (key) => {
  return window.localStorage.getItem(KIZEN_PREFIX + key);
};
export const remove = (key) =>
  window.localStorage.removeItem(KIZEN_PREFIX + key);
export const set = (key, value) =>
  window.localStorage.setItem(KIZEN_PREFIX + key, value);

export const deleteAuthToken = () => remove(AUTH_TOKEN_KEY);
export const getAuthToken = () => get(AUTH_TOKEN_KEY);
export const setAuthToken = (token) => set(AUTH_TOKEN_KEY, token);

export const deleteFormBuilderJson = (formId) =>
  remove(`${FORM_BUILDER_JSON_KEY}-${formId}`);
export const getFormBuilderJson = (formId) =>
  get(`${FORM_BUILDER_JSON_KEY}-${formId}`);
export const setFormBuilderJson = (formId, json) =>
  set(`${FORM_BUILDER_JSON_KEY}-${formId}`, json);

export const deleteSurveyBuilderJson = (surveyId) =>
  remove(`${SURVEY_BUILDER_JSON_KEY}-${surveyId}`);
export const getSurveyBuilderJson = (surveyId) =>
  get(`${SURVEY_BUILDER_JSON_KEY}-${surveyId}`);
export const setSurveyBuilderJson = (surveyId, json) =>
  set(`${SURVEY_BUILDER_JSON_KEY}-${surveyId}`, json);

export const authLocalStorage = new AuthLocalStorage(KIZEN_AUTHORIZED_KEY);
