import { useMemo, useState, useRef } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import KizenTypography from 'app/kizentypo';
import { colorsButton } from 'app/colors';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';
import { IconSizing } from 'components/Kizen/Icon';
import InlineTableFieldInput from 'components/Fields/InlineTableFieldInput';
import BubbleProfileImage from 'components/Charts/ScheduledActivities/BubbleProfileImage';
import { DragIcon, BubbleWrapper } from 'components/Board/BaseCard';
import DateInputInline from 'components/Inputs/inline/DateInput';
import {
  API_DATE_FORMAT,
  DATE_DISPLAY_FORMAT,
  DATE_EMPTY_FORMAT,
} from 'components/Inputs/DateInput';
import BasicModal from 'components/Modals/presets/BasicModal';
import useModal from 'components/Modals/useModal';
import PipelineService from 'services/PipelineService';
import { snakeToCamelCase, snakeToCamelCaseKeys } from 'services/helpers';
import { STAGE_STATUSES } from 'utility/constants';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import ArchiveModal from 'components/Modals/ArchiveModal';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import {
  getPercentageChanceToCloseProps,
  getMenuOptions,
  getPatchData,
} from './helpers';
import { isSummarizedValue } from 'components/Fields/helpers';
import {
  TitleWrapper,
  StyledValidatedField,
  StyledIconMenu,
  StyledDateWrapper,
  StyledDateInputInlineSelect,
  StyledSelect,
  StyledBaseCard,
  ContentWrapper,
  StageSelect,
  StyledSelectInline,
} from './styles';
import { TimeInStage } from './TimeInStage';
import { useDateTooltip } from 'components/Inputs/hooks/useDateTooltip';
import { isStageField } from 'checks/fields';

const specialStageStatuses = [
  STAGE_STATUSES.won,
  STAGE_STATUSES.lost,
  STAGE_STATUSES.disqualified,
];

const ActualCloseDate = ({ date }) => {
  const { t } = useTranslation();
  const [dateTooltipProps, dateTooltip] = useDateTooltip({
    date,
    predicate: t('Actual Close Date'),
  });

  return (
    <StyledDateWrapper {...dateTooltipProps}>
      {dateTooltip}
      <KizenTypography type="small">
        {date ? format(date, DATE_DISPLAY_FORMAT) : DATE_EMPTY_FORMAT}
      </KizenTypography>
    </StyledDateWrapper>
  );
};

const Card = ({
  data,
  model,
  stageStatus,
  settings,
  fields,
  stages,
  dragging,
  isDragItem,
  onChoseMenu,
  onSubmitRecord,
  onChangeStage,
  hideDnDIcon = false,
  handleUpdateFieldOption,
  selectOverlayObserverProps,
  ...props
}) => {
  const { t } = useTranslation();
  const ownerSelectRef = useRef();
  const [chosenStage, setStage] = useState(null);
  const [bringToTop, setBringToTop] = useState(false);
  const [forceToTop, setForceToTop] = useState(false);
  const disabledDragCardIds = useSelector(
    ({ recordsPage }) => recordsPage.boardData.disabledDragCardIds
  );
  const isDisabledDrag = useMemo(
    () => disabledDragCardIds.some((el) => el === data.id),
    [data.id, disabledDragCardIds]
  );

  const [selectProps] = useSelectTypeaheadWithScroll({
    selectRef: ownerSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    alwaysOpen: true,
  });

  const closeDate = useMemo(() => {
    return specialStageStatuses.includes(stageStatus)
      ? data.actualCloseDate
      : data.estimatedCloseDate;
  }, [stageStatus, data.actualCloseDate, data.estimatedCloseDate]);

  const fieldsInCard = useMemo(() => {
    return settings.fields
      .map((item) => fields.find((el) => el.id === item.value))
      .filter(Boolean);
  }, [settings.fields, fields]);

  const handleChangeStage = async () => {
    await onChangeStage(data, { stageId: chosenStage.value });
  };

  const handleDeleteRecord = () => {
    onChoseMenu('archive', data);
  };

  const [changeStageModalProps, , changeStageModal] = useModal({
    handleSubmit: handleChangeStage,
    handleHide: () => {
      setStage(null);
    },
  });
  const [archiveModalProps, , archiveModal] = useModal({
    handleSubmit: handleDeleteRecord,
  });

  const handleChoseMenuOption = (opt) => {
    switch (opt.value) {
      case 'changeStage': {
        changeStageModal.show();
        break;
      }
      case 'archive': {
        archiveModal.show();
        break;
      }
      default: {
        onChoseMenu(opt.value, data);
      }
    }
  };

  const handleUpdateFieldValue = async (patch) => {
    const [key, value] = Object.entries(patch)[0];

    const paylaod = isSummarizedValue(value)
      ? {
          addValues: (value.addValues || []).map(({ id }) => id),
          removeValues: (value.removeValues || []).map(({ id }) => id),
        }
      : { value };

    await onSubmitRecord(data.id, {
      fields: [
        {
          id: key,
          ...paylaod,
        },
      ],
    });
  };

  const defaultFieldProps = (field) => {
    const stageField = isStageField(field);
    if (field.isDefault || stageField) {
      return {
        value: data[snakeToCamelCase(field.name)],
        onSubmit: async (patch) => {
          if (stageField) {
            await onChangeStage(data, { stageId: patch.stage });
          } else {
            await onSubmitRecord(
              data.id,
              snakeToCamelCaseKeys(getPatchData(patch, field))
            );
          }
        },
        ...(stageField && { menuLeftButton: false }), // no clear for stage
      };
    }

    return {};
  };

  function getFieldNameComponent() {
    if (!data.access?.edit) {
      return (
        <TextEllipsisWithTooltip
          type="link"
          to={`/custom-objects/${model.id}/${data.id}/details`}
        >
          {data.name}
        </TextEllipsisWithTooltip>
      );
    }

    return (
      <StyledValidatedField
        type="link"
        to={`/custom-objects/${model.id}/${data.id}/details`}
        value={data.name}
        position="unset"
        inModal
        onSubmit={async (name) => {
          await onSubmitRecord(data.id, { name });
        }}
        onFocus={(ev) => ev.stopPropagation()}
        fieldId="name"
      />
    );
  }

  return (
    <>
      <StyledBaseCard
        isSelected={data.isSelected}
        bringToTop={bringToTop}
        forceToTop={forceToTop}
        isDragging={dragging}
        isDragItem={isDragItem}
        onFocus={() => setBringToTop(true)}
        onBlur={() => setBringToTop(false)}
        title={
          <>
            <TitleWrapper>
              {!hideDnDIcon && (
                <IconSizing
                  size="15px"
                  className={classNames({
                    CardHandle: !isDisabledDrag,
                  })}
                  style={{ cursor: isDisabledDrag ? 'wait' : 'grab' }}
                >
                  <DragIcon icon="reorder" />
                </IconSizing>
              )}
              {getFieldNameComponent()}
            </TitleWrapper>
            <IconButtonMenu
              position="right"
              sizing="dense"
              title={t('Edit Record')}
              onOpen={() => setForceToTop(true)}
              onClose={() => setForceToTop(false)}
              color={colorsButton.iconGray}
              onChange={handleChoseMenuOption}
              options={getMenuOptions(!hideDnDIcon, data.access.remove, t)}
            >
              <StyledIconMenu icon="three-dot" />
            </IconButtonMenu>
          </>
        }
        footer={
          (settings.estimatedCloseDateVisible ||
            settings.timeInStageVisible ||
            settings.ownerVisible) && (
            <>
              <div>
                {settings.estimatedCloseDateVisible ? (
                  specialStageStatuses.includes(stageStatus) ? (
                    <ActualCloseDate date={closeDate} />
                  ) : (
                    <DateInputInline
                      value={closeDate}
                      icon={null}
                      showToolTip
                      tooltipLabel={t('Estimated Close Date')}
                      popperConfig={{}}
                      className="labeled_element"
                      labelEl
                      onSubmit={async (value) => {
                        await onSubmitRecord(data.id, {
                          estimatedCloseDate: value
                            ? format(value, API_DATE_FORMAT)
                            : value,
                        });
                      }}
                    >
                      <StyledDateInputInlineSelect
                        value={closeDate}
                        menuRightButton={<ApplySelectOverlayButton />}
                        menuLeftButton={<ClearSelectButton />}
                      />
                    </DateInputInline>
                  )
                ) : null}
                {settings.timeInStageVisible ? (
                  <TimeInStage time={data.timeOnStage} />
                ) : null}
              </div>
              {settings.ownerVisible && data.owner && (
                <StyledSelectInline
                  icon={null}
                  labelEl={
                    <BubbleWrapper>
                      <BubbleProfileImage
                        fullName={data.owner.fullName}
                        imageURL={data.owner.picture?.thumbnailUrl}
                        showToolTip
                        popperConfig={{
                          modifiers: {
                            preventOverflow: { enabled: false },
                          },
                        }}
                      />
                    </BubbleWrapper>
                  }
                >
                  <StyledSelect
                    ref={ownerSelectRef}
                    value={data.owner}
                    onChange={(value) => {
                      onSubmitRecord(data.id, { ownerId: value.value });
                    }}
                    {...selectProps}
                  />
                </StyledSelectInline>
              )}
            </>
          )
        }
        {...props}
      >
        {Boolean(fieldsInCard.length) && (
          <ContentWrapper onFocus={(ev) => ev.stopPropagation()}>
            {fieldsInCard.map((item) => {
              const fieldDisabled = !(
                !item.isReadOnly &&
                item.access?.edit &&
                data?.access.edit
              );
              return (
                <div
                  key={item.id}
                  data-qa-field={item.id}
                  data-qa-disabled={fieldDisabled}
                >
                  <InlineTableFieldInput
                    field={item}
                    onSubmit={handleUpdateFieldValue}
                    isCustomObjects
                    object={data}
                    objectId={model.id}
                    fetchUrl={`custom-objects/${model.id}`}
                    serviceToUse={PipelineService}
                    handleUpdateFieldOption={handleUpdateFieldOption}
                    showDisplayNameTooltip
                    contentWidth={(settings.columnWidth || 270) - 44}
                    selectOverlayObserverProps={selectOverlayObserverProps}
                    {...defaultFieldProps(item)}
                    {...getPercentageChanceToCloseProps(item, fields, data, t)}
                  />
                </div>
              );
            })}
          </ContentWrapper>
        )}
      </StyledBaseCard>
      <BasicModal
        heading={t('Move Record to Stage')}
        buttonText={t('Save')}
        disabled={!chosenStage}
        leftBtn={null}
        {...changeStageModalProps}
      >
        <StageSelect
          label={t('Choose Stage')}
          placeholder={t('Choose Stage')}
          value={chosenStage}
          options={stages}
          onChange={setStage}
          menuInline
        />
      </BasicModal>
      <ArchiveModal {...archiveModalProps}>
        {t('Custom Object Archive Modal body', {
          name: data?.name || 'entity record',
        })}
      </ArchiveModal>
    </>
  );
};

export default Card;
