import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { breakpoints, isMobile, useWindowSize, gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import styled from '@emotion/styled';
// TODO - temporarily removed for go live
import SubNavigation from 'components/Navigation/SubNavigation';
import routes from './routes';
import { LibraryUrlBase } from './routes';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useTranslation } from 'react-i18next';
import { TemplatesPage } from './pages';

const MobileContainer = styled.div`
  padding: ${gutters.standard};
  text-align: center;
`;

const LibraryPage = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  const { width } = useWindowSize();
  const mobileDisplay = isMobile(width, breakpoints.lg);
  const { subpage } = useParams();
  const routeKeys = Object.keys(routes);
  const routeComponents = routeKeys.map((routeKey, index) => {
    const route = routes[routeKey];
    return (
      <Route
        key={index}
        path={route.path}
        exact={route.exact !== false}
        component={route.component}
      />
    );
  });

  routeComponents.push(
    <Route
      key={routeKeys.length}
      path={`${LibraryUrlBase}/templates/:templateId/:subpage?`}
      exact={true}
      component={TemplatesPage}
    />
  );

  if (!subpage) {
    return <Redirect exact to={`${LibraryUrlBase}/templates`} />;
  }

  if (mobileDisplay) {
    return (
      <MobileContainer>
        <KizenTypography>
          This page has not been optimized for mobile. Please revisit on a
          desktop to receive the best experience.
        </KizenTypography>
      </MobileContainer>
    );
  }

  return (
    <>
      <SubNavigation routes={routes} />
      <Switch>{routeComponents}</Switch>
    </>
  );
};

export default LibraryPage;
