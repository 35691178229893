import { range } from 'utility/snippets';

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

export const generateRatingsOptions = ({ maxValue = 5, minValue = 1 } = {}) => {
  const ratings = range(minValue, maxValue).map((value) => ({
    value: String(value),
    id: String(value),
    label: String(value),
    name: String(value),
    order: value,
  }));

  return ratings;
};

export const isContact = (object) => object?.name === 'client_client';

export const truePredicate = () => true;

export const falsePredicate = () => false;
