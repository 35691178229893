import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { colorsPrimary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { TextSpan } from 'app/typography';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import KizenTypography from 'app/kizentypo';
import { defaultEmployeeString, defaultAutomationString } from '../helpers';
import { eventTypes } from '../../constants';
import SentMessageModalQuered from 'pages/ContactDetail/pages/Messages/MessageHistoryTable/SentMessageModalQuered';

const StyledTextLink = styled(KizenTypography)`
  overflow: visible;
  margin-right: -50px;
`;

const cardHeaderConfig = (t) => {
  return {
    [eventTypes.subsectionListOptedIn]: {
      prefix: t('to'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.subsectionListOptedOut]: {
      prefix: t('of'),
      creationType: defaultEmployeeString,
    },
    [eventTypes.subsectionListUnsubscribeAll]: {
      prefix: 'from',
      creationType: (initiator, _, t) =>
        initiator.type === 'employee'
          ? defaultEmployeeString(initiator)
          : defaultAutomationString(initiator, _, t),
    },
    [eventTypes.subsectionListNotOptedIn]: {
      prefix: t('to'),
      creationType: defaultEmployeeString,
    },
  };
};

const ContactSubscriptionEventBlock = ({ event, ...others }) => {
  const [chosenMessage, setChosenMessage] = useState(null);
  const businessName = useSelector(
    ({ authentication }) => authentication?.chosenBusiness?.name ?? ''
  );
  const { t } = useTranslation();
  const config = cardHeaderConfig(t);
  const [prefix, initiator, displayName, listName, postfix] = useMemo(() => {
    const prefix = config[event.typeName]?.prefix || '';
    const initiator = event.initiator.type
      ? config[event.typeName].creationType(event.initiator, event, t)
      : '';
    const displayName =
      event.typeName === eventTypes.subsectionListUnsubscribeAll &&
      !event.initiator.type
        ? t('Unsubscribed')
        : event.typeDisplayName;
    const listName =
      event.typeName === eventTypes.subsectionListUnsubscribeAll &&
      !event.initiator.type
        ? t('All Emails')
        : event.data.subscriptionListName;
    const postfix =
      event.typeName !== eventTypes.subsectionListUnsubscribeAll
        ? t('subscription list')
        : '';

    return [prefix, initiator, displayName, listName, postfix];
  }, [config, event, t]);

  return (
    <>
      <BasicBlock
        comments={event.comments}
        icon={
          <TypeIcon
            color={colorsPrimary.blue.dark}
            icon="envelope"
            size="16px"
          />
        }
        isDeletable={true}
        timestamp={event.created}
        title={
          !initiator ? (
            <>
              <TextSpan size="micro">
                {t('Contact')}
                &nbsp;
              </TextSpan>
              <TextSpan weight="bold" size="micro">
                {displayName}
              </TextSpan>
              <TextSpan size="micro">
                &nbsp;
                {prefix}
                &nbsp;
              </TextSpan>
              <TextSpan weight="bold" size="micro">
                {listName}
              </TextSpan>
              {postfix ? (
                <TextSpan size="micro">
                  &nbsp;
                  {postfix}
                  &nbsp;
                </TextSpan>
              ) : null}
            </>
          ) : (
            <>
              <TextSpan
                data-qa-field="display-name"
                weight="bold"
                size="micro"
                data-qa="field-name"
              >
                {displayName}
              </TextSpan>
              {listName ? (
                <>
                  <TextSpan size="micro">
                    &nbsp;
                    {prefix}
                    &nbsp;
                  </TextSpan>
                  <TextSpan
                    weight="bold"
                    size="micro"
                    data-qa-field="list-name"
                  >
                    {listName}
                  </TextSpan>
                  {postfix ? (
                    <TextSpan size="micro">
                      &nbsp;
                      {postfix}
                    </TextSpan>
                  ) : null}
                </>
              ) : null}
              <TextSpan size="micro">
                &nbsp;
                {t('by')}
                &nbsp;
              </TextSpan>
              <TextSpan weight="bold" size="micro">
                {initiator}
              </TextSpan>
            </>
          )
        }
        event={event}
        {...others}
      >
        {event.data?.emailSubject ? (
          <>
            <TextEllipsisWithTooltip weight="bold">
              {t('Subject')}
            </TextEllipsisWithTooltip>
            <TextEllipsisWithTooltip>
              {event.data.emailSubject}
            </TextEllipsisWithTooltip>
            <StyledTextLink
              type="link"
              onClick={() =>
                setChosenMessage({
                  id: event.data.sentMessageId,
                  type: 'email',
                  sentAt: event.created,
                })
              }
            >
              {t('View Full Email')}
            </StyledTextLink>
          </>
        ) : null}
      </BasicBlock>
      {chosenMessage && (
        <SentMessageModalQuered
          t={t}
          businessName={businessName}
          message={chosenMessage}
          onHide={() => setChosenMessage(null)}
        />
      )}
    </>
  );
};

export default ContactSubscriptionEventBlock;
