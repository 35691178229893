import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useBuilderContext } from '../BuilderContext';
import { TraySection } from '../components/TraySection';

const nodeNameLabels = {
  CustomField: 'Custom Field',
  FormField: 'Form Field',
};

export const BlankSettingsSection = ({
  node: {
    data: { name },
  },
}) => {
  const { t } = useTranslation();
  const { clearContentSettingsTray } = useBuilderContext();

  return (
    <TraySection
      onBackClick={clearContentSettingsTray}
      collapsable={false}
      header={`${nodeNameLabels[name] || name} ${t('Settings')}`}
    />
  );
};

BlankSettingsSection.propTypes = {
  node: PropTypes.object.isRequired,
};
