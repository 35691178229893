import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import {
  StyledTableArea,
  StyledTableAreaWrapper,
  ViewFullButton,
} from './styles';
import { eventTypes } from '../constants';

const getExpandableConfig = ({ typeName, data } = {}, t) => {
  switch (typeName) {
    case eventTypes.fieldUpdated:
      return {
        isExpandable: !!data?.field?.relation?.relationType,
        maxItemsToRender: 100,
        maxHeight: 172, // lineHeight * maxLines + 2 * 16px row-gap
        maxLines: 10,
        lineHeight: 14,
        hideButtonLabel: t('Hide Full Field Update'),
        showButtonLabel: t('View Full Field Update'),
      };
    default:
      return {};
  }
};

export const TableArea = memo(
  ({ children, event }) => {
    const { width, height } = useWindowSize();
    const { t } = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflown, setIsOverflown] = useState(false);

    const areaRef = useRef();

    const {
      maxHeight,
      maxLines,
      maxItemsToRender,
      lineHeight,
      isExpandable,
      hideButtonLabel,
      showButtonLabel,
    } = useMemo(() => getExpandableConfig(event, t), [event, t]);

    useEffect(() => {
      if (isExpandable) {
        const overflown = areaRef.current.scrollHeight > maxHeight;
        setIsExpanded((prev) => prev || !overflown);
        setIsOverflown(overflown);
      }
    }, [width, height, isExpandable, maxHeight]);

    return (
      <StyledTableAreaWrapper isOverflown={isOverflown}>
        <StyledTableArea
          ref={areaRef}
          isExpanded={!isExpandable || isExpanded}
          isOverflown={isOverflown}
          maxHeight={maxHeight}
        >
          {children({
            isExpanded: !isExpandable || !isOverflown || isExpanded,
            maxLines,
            lineHeight,
            maxItemsToRender,
          })}
        </StyledTableArea>
        {isOverflown && isExpandable ? (
          <ViewFullButton
            variant="text"
            color="blue"
            onClick={() => setIsExpanded((prevExp) => !prevExp)}
          >
            {isExpanded ? hideButtonLabel : showButtonLabel}
          </ViewFullButton>
        ) : null}
      </StyledTableAreaWrapper>
    );
  },
  () => true
);
