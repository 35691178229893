import { DASHBOARD } from 'queries/query-keys';

export const getDashboardsQueryKeyForHomepages = ({
  debouncedTerm = '',
  includeSharing = true,
}) => {
  return debouncedTerm
    ? [...DASHBOARD.DASHBOARDS, debouncedTerm, 'homepage', includeSharing]
    : [...DASHBOARD.DASHBOARDS, 'homepage', includeSharing];
};

export const getHeightFromLayoutRows = (rows: number) => {
  return (rows - 1) * 115 + 105;
};

export const getLayoutRowsFromHeight = (height: number) => {
  return Math.floor((height - 105) / 115) + 1;
};
