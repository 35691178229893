import DraggableStepCard from '../../DraggableStepCard';
import { getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

const getAction = ({ t, actionType = 'start' }) => {
  const lookUp = {
    start: t('Start'),
    pause: t('Pause'),
    cancel: t('Cancel'),
  };
  return lookUp[actionType];
};

export const getDescription = ({ step: { details }, t }) => {
  const { actionType, automationIds, automationTargetRelationshipFields } =
    details;

  const action = getAction({ t, actionType });
  const automations = getValueOrDeleted(
    automationIds
      .map(({ label }) => label)
      .sort((a, b) => {
        return a.localeCompare(b, 'en', { sensitivity: 'base' });
      })
      .join(', '),
    t
  );
  const relationshipFieldNames = getValueOrDeleted(
    automationTargetRelationshipFields
      .map(({ label }) => label)
      .sort((a, b) => {
        return a.localeCompare(b, 'en', { sensitivity: 'base' });
      })
      .join(', '),
    t
  );

  return t(
    `{{action}} Automations '{{automations}}' on '{{relationshipFieldNames}}'`,
    { action, automations, relationshipFieldNames }
  );
};
export default function ModifyRelatedEntitiesAutomationCard({
  step,
  ...others
}) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
