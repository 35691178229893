// class to handle auth localstorage
import { LocalStorage } from './LocalStorage';
export class AuthLocalStorage extends LocalStorage {
  constructor(
    key = 'key',
    { serialize = JSON.stringify, deserialize = JSON.parse } = {}
  ) {
    super(key, { serialize, deserialize });
  }

  set authorized(authorized) {
    const val = { ...this.val, authorized };
    this.val = val;
  }

  get authorized() {
    const { authorized } = this.val || {};
    return authorized;
  }

  set businessId(businessId) {
    const val = { ...this.val, businessId };
    this.val = val;
  }

  get businessId() {
    const { businessId } = this.val || {};
    return businessId;
  }
}
