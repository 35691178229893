import {
  BaseBuilderNode,
  BorderAttribute,
  BuilderSectionNode,
  PaddingAttributes,
} from './types';
import { msoSupportedColor, withDefault } from './utils';

export const MJ_TEXT_CLASS_NAME = 'kizen-text-styles';

export const mapContainerPadding = (
  props: BaseBuilderNode['props'],
  fallback: string | string[] = '0'
): Omit<PaddingAttributes, 'padding'> => {
  const {
    containerPaddingTop,
    containerPaddingRight,
    containerPaddingBottom,
    containerPaddingLeft,
  } = props;
  const [t, r, b, l] = Array.isArray(fallback)
    ? fallback
    : Array(4).fill(fallback);
  return {
    'padding-top': `${withDefault(containerPaddingTop, t)}px`,
    'padding-right': `${withDefault(containerPaddingRight, r)}px`,
    'padding-bottom': `${withDefault(containerPaddingBottom, b)}px`,
    'padding-left': `${withDefault(containerPaddingLeft, l)}px`,
  };
};

export const mapBackgroundColor = (
  backgroundColor: string,
  attribute:
    | 'container-background-color'
    | 'background-color' = 'container-background-color'
) => {
  return backgroundColor === 'inherit' || backgroundColor === undefined
    ? undefined
    : { [attribute]: msoSupportedColor(backgroundColor) };
};

export const mapBorder = ({
  containerBorderColor = '#4A5660',
  containerBorderStyle = 'solid',
  containerBorderWidth = '0',
}: BuilderSectionNode['props']): undefined | BorderAttribute => {
  return parseInt(containerBorderWidth) === 0 || containerBorderStyle === 'none'
    ? undefined
    : {
        border: `${containerBorderWidth}px ${containerBorderStyle} ${containerBorderColor}`,
      };
};
