import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import Checkbox from 'components/Checkbox/Checkbox';
import useFlashState from 'hooks/useFlashState';

export const SetAllCheckbox = styled(({ disabled, onChange, ...rest }) => {
  const [checked, setChecked] = useFlashState(150, false);

  const hanldeChange = useCallback(
    (ev) => {
      onChange(ev);
      setChecked(ev.target.checked);
    },
    [setChecked, onChange]
  );

  return (
    <div {...rest}>
      <Checkbox disabled={disabled} checked={checked} onChange={hanldeChange} />
    </div>
  );
})`
  .CheckboxInputFacade {
    margin-left: 8px;
  }
  label {
    margin: 0;
  }
`;

export const SetAllNone = styled(SetAllCheckbox)`
  grid-column: 1;
`;

export const SetAllView = styled(SetAllCheckbox)`
  grid-column: 2;
`;

export const SetAllEdit = styled(SetAllCheckbox)`
  grid-column: 3;
`;

export const SetAllRemove = styled(SetAllCheckbox)`
  grid-column: 4;
`;
