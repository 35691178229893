import styled from '@emotion/styled';
import { Handle } from 'reactflow';

export default styled(Handle)`
  && {
    border: none;
    // It seems this component needs dimensions in order to render properly
    height: 1px;
    width: 1px;
    background: transparent;
  }
`;
