import { useEditor, useNode } from '@craftjs/core';
import { FieldContainer } from '@kizen/page-builder/nodes/containers';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { definePlaceholder } from '@kizen/page-builder/utils/fields';
import { useShowControls } from '../useShowControls';
import Control from '../Control';
import { useDraggingType } from '../useDraggingType';
import { StyledFieldInput } from '../styled';
import { usePhoneFieldWithBusinessRegion } from '../usePhoneFieldWithBusinessRegion';
import { useTranslation } from 'react-i18next';
import { useIsInBuilderContext } from 'components/PageBuilder/BuilderContext';
import { FORM_FIELD_VALUE_FILE_UPLOAD_SOURCE } from 'hooks/uploadFiles/useUploadFile';
import { canUpdateFieldPlaceholder } from '../../utils';

const formBuilderNodeXform = (field) => {
  return {
    ...field,
    displayName:
      typeof field.labelText === 'string' ? field.labelText : field.displayName,
  };
};

export const FormField = ({ field: fieldProp, error, ...rest }) => {
  const { t } = useTranslation();
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
    enabled: state.options.enabled,
  }));
  const {
    id,
    connectors: { connect },
  } = useNode((node) => ({
    id: node.id,
  }));
  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const isInBuilderContext = useIsInBuilderContext();
  const field = usePhoneFieldWithBusinessRegion(fieldProp);
  const placeholder = canUpdateFieldPlaceholder(field)
    ? field.placeholder
    : definePlaceholder(field, t);

  return (
    <Control
      ref={connect}
      label={t('Content')}
      show={showControls}
      color={colorsPrimary.green.dark}
      mayHaveChildren={false}
      fullWidth={true}
      {...rest}
    >
      <FieldContainer
        draggingType={draggingType}
        pointerEvents={false}
        isInBuilderContext={isInBuilderContext}
        {...rest}
      >
        <StyledFieldInput
          source={FORM_FIELD_VALUE_FILE_UPLOAD_SOURCE}
          field={formBuilderNodeXform(field)}
          object={{}}
          placeholder={placeholder}
          isRequired={field.isRequired}
        />
      </FieldContainer>
    </Control>
  );
};
