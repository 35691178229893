import { doFilterSetsHaveError } from 'ts-filters/utils';

export const getIsContactsFetching = ({ contactPage }) =>
  contactPage.isFetchingContacts;
export const getNeedToLoadCountsSelector = ({ contactPage }) =>
  contactPage.needToLoadCounts;
export const getIsContactsFiltering = ({ contactPage }) =>
  contactPage.isFiltering;
export const getIsLoadingView = ({ contactPage }) => contactPage.isLoadingView;
export const getContactsPageReady = ({ contactPage }) => contactPage.pageReady;
export const getFilterGroupReason = ({ contactPage }) =>
  contactPage.filterGroupReason;

export const getHasFilterError = (state) => {
  const filters = state.contactPage.filters.config;
  const errors = state.contactPage.filters.errors;

  if (!filters || filters.query) {
    return false;
  }

  const errorsWhenLoadingSteps = doFilterSetsHaveError(filters);
  const errorsWhenPerformingSearch = Boolean(
    errors?.parsedErrors.some(Boolean)
  );

  return errorsWhenLoadingSteps || errorsWhenPerformingSearch;
};
