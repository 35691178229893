import { NodeViewWrapper } from '@tiptap/react';
import styled from '@emotion/styled';

import { fontSizes } from 'app/typography';
import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import KizenPill from 'components/Kizen/Pill';

export const Pill = styled(KizenPill)`
  margin: 0;
  padding: ${gutters.spacing(0, 2)}px ${gutters.spacing(1, 2)}px;

  .selected-value-text {
    font-size: ${fontSizes.micro};
  }

  i {
    min-width: max-content;
  }
`;

export const PillWrapper = styled(NodeViewWrapper)`
  align-items: flex-start;
  background: ${grayScale.white};
  position: relative;
  max-width: 250px;
  height: 24px;
  padding-left: 0px;
  padding-right: 0px;
  :focus {
    outline: none;
  }
  top: 2px;
  display: inline-block;
`;
