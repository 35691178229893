import { useState } from 'react';
import { SettingsContentCard } from 'components/Card/Card';
import ConfirmNavigationModal from 'components/Modals/presets/ConfirmNavigation';
import Section from 'components/Layout/Section';
import { Sections, StyledDivider, StyledPageSizing } from '../styled';
import Loader from 'components/Kizen/Loader';
import { ExportSection } from '../components/ExportImport/ExportSection';
import { ImportSection } from '../components/ExportImport/ImportSection';

export const BusinessExportImport = () => {
  const [dirtyExport, setDirtyExport] = useState(false);
  const [dirtyImport, setDirtyImport] = useState(false);

  return (
    <>
      <StyledPageSizing>
        <SettingsContentCard>
          <Section>
            <Loader loading={false}>
              <Sections>
                <ExportSection setDirty={setDirtyExport} />
                <StyledDivider />
                <ImportSection setDirty={setDirtyImport} />
              </Sections>
            </Loader>
          </Section>
        </SettingsContentCard>
      </StyledPageSizing>
      <ConfirmNavigationModal when={dirtyExport || dirtyImport} />
    </>
  );
};
