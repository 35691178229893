import React from 'react';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { colorsButton } from 'app/colors';
import { fontWeights } from 'app/typography';
import KizenTypography from 'app/kizentypo';
import IconButton from 'components/Kizen/IconButton';
import BaseSwitch from 'components/Kizen/Switch';
import ViewDesktopSvg from '../images/view-desktop.svg?react';
import ViewTabletSvg from '../images/view-tablet.svg?react';
import ViewMobileSvg from '../images/view-mobile.svg?react';
import { useBuilderContext } from '../BuilderContext';
import { useTranslation } from 'react-i18next';

const getIconColor = (icon, active) => {
  return icon === active ? colorsButton.blue.hover : colorsButton.iconGray;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: ${gutters.spacing(4)}px;
  }
`;

const Switch = styled(BaseSwitch)`
  margin: 0;
  label {
    margin-bottom: 0;
    margin-left: ${gutters.spacing(4)}px;
  }
  p {
    white-space: nowrap; // Avoid wrapping for small (e.g. 1px) overflow, which is possible
    font-weight: ${fontWeights.bold};
  }
`;

export function ViewSettingsSection() {
  const { t } = useTranslation();
  const { gridLinesActive, view, setView, toggleGridLines } =
    useBuilderContext();

  return (
    <Wrapper>
      <KizenTypography as="h5" weight="bold">
        {t('View')}
      </KizenTypography>
      <IconButton
        title={t('Desktop View')}
        color={getIconColor('desktop', view)}
        sizing="dense"
        onClick={() => setView('desktop')}
      >
        <ViewDesktopSvg />
      </IconButton>
      <IconButton
        title={t('Tablet View')}
        color={getIconColor('tablet', view)}
        sizing="dense"
        onClick={() => setView('tablet')}
      >
        <ViewTabletSvg />
      </IconButton>
      <IconButton
        title={t('Mobile View')}
        color={getIconColor('mobile', view)}
        sizing="dense"
        onClick={() => setView('mobile')}
      >
        <ViewMobileSvg />
      </IconButton>
      <Switch
        checked={gridLinesActive}
        onChange={toggleGridLines}
        textPlacement="left"
        label={t('Grid Lines')}
      />
    </Wrapper>
  );
}
