import { useCallback, useState, useMemo, useRef } from 'react';
import BasicModal from 'components/Modals/presets/BasicModal';
import Select from 'components/Inputs/Select';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import LongText from '__components/Inputs/LongText';
import { Typography } from '@kizen/kds/Typography';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import useModal from 'components/Modals/useModal';
import { useFlashTransition } from 'hooks/useFlashState';
import { Overlay } from 'react-bootstrap';
import { modalConstrainPopperConfig } from 'components/Inputs/Validation';
import { ErrorCard } from 'components/Tables/DynamicTagsManager/styles';
import KizenTypography from 'app/kizentypo';

const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const StyledFieldText = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const LongTextWrapper = styled.div`
  height: 300px;
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  width: 100%;
`;

const COMMA_DELIMITED_VALUE = 'comma-delimited';
const NEW_LINE_VALUE = 'new-line';

const ImportFieldsOptionsModal = ({ show, formData, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const [error, showError, flashError] = useFlashTransition();
  const [importTextValue, setImportTextValue] = useState('');
  const [seperatorType, setSeperatorType] = useState(COMMA_DELIMITED_VALUE);
  const inputWrapperRef = useRef();
  const seperatorTypeOptions = useMemo(
    () => [
      { label: t('Comma-Delimited'), value: COMMA_DELIMITED_VALUE },
      { label: t('New Line'), value: NEW_LINE_VALUE },
    ],
    [t]
  );

  const handleImport = useCallback(() => {
    let parsedValues = [];
    const splitValue = seperatorType === COMMA_DELIMITED_VALUE ? ',' : '\n';
    const fieldOptionNameSet = new Set(
      formData?.options?.map(({ name } = {}) => name) ?? []
    );
    parsedValues = Array.from(
      new Set(
        importTextValue
          .split(splitValue)
          .map((name) => name.trim())
          .filter((name) => name && !fieldOptionNameSet.has(name))
      )
    ).map((name) => ({
      name,
    }));
    try {
      onConfirm(parsedValues);
    } catch (err) {
      flashError(err?.message);
    }
  }, [formData, importTextValue, onConfirm, seperatorType, flashError]);

  const [modalProps, , confirmationModal] = useModal({
    handleSubmit: handleImport,
  });

  const handleConfirm = useCallback(() => {
    const numberOfNewLineChars = importTextValue.split('\n').length;
    if (
      (seperatorType === COMMA_DELIMITED_VALUE && numberOfNewLineChars > 2) ||
      (seperatorType === NEW_LINE_VALUE && numberOfNewLineChars < 3)
    ) {
      confirmationModal.show();
    } else {
      handleImport();
    }
  }, [importTextValue, confirmationModal, seperatorType, handleImport]);

  return (
    <>
      <BasicModal
        onHide={() => {
          onClose();
        }}
        onConfirm={handleConfirm}
        show={show}
        buttonText={t('Import')}
        defaultLeftBtnText={t('Cancel')}
        heading={t('Import Field Options')}
        disabled={importTextValue === ''}
        size="medium"
      >
        <StyledField>
          <Select
            fullWidth
            label={t('Choose Field Option Seperator')}
            options={seperatorTypeOptions}
            value={seperatorType}
            onChange={({ value }) => setSeperatorType(value)}
            margin
          />
        </StyledField>
        <StyledField>
          <StyledFieldText>
            <Typography>{t('Enter Import in Chosen Format')}</Typography>
          </StyledFieldText>
          <LongTextWrapper ref={inputWrapperRef}>
            <LongText
              data-qa="field-options-import-input"
              enableResize={false}
              value={importTextValue}
              onChange={setImportTextValue}
              placeholder={t('Place Field Options Here')}
              fullHeight
              error={error}
            />
            <Overlay
              transition={true}
              target={inputWrapperRef.current}
              show={typeof error === 'string'}
              placement="bottom-start"
              popperConfig={modalConstrainPopperConfig}
            >
              <ErrorCard light show={showError} duration="300ms">
                <KizenTypography>{error}</KizenTypography>
              </ErrorCard>
            </Overlay>
          </LongTextWrapper>
        </StyledField>
      </BasicModal>

      {modalProps.show && (
        <ConfirmationModal
          heading={t('Please Confirm Import')}
          buttonText={t('Continue')}
          {...modalProps}
        >
          <ConfirmationWrapper>
            <Typography size="md">
              {t(
                "It appears your data is {{delimiter}} separated but you've selected a different separator. Are you sure?",
                {
                  delimiter:
                    seperatorType === COMMA_DELIMITED_VALUE
                      ? t('comma')
                      : t('newline'),
                }
              )}
            </Typography>
          </ConfirmationWrapper>
        </ConfirmationModal>
      )}
    </>
  );
};

export default ImportFieldsOptionsModal;
