import styled from '@emotion/styled';
import css from '@emotion/css';
import { grayScale, appBackground } from '__app/colors';
import Loader from '__components/Kizen/Loader';
import { borderRadii } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import { BasicRichTextEditor } from '__components/WYSIWYG';
import Button from '__components/Button';

export const StyledConnectionButtonsRow = styled.div`
  display: flex;
  margin: 10px 0 15px;
  & > button {
    margin: 0;
  }
`;

export const StyledFilePickerNote = styled(KizenTypography)`
  font-style: italic;
  margin: 15px 0;
  color: ${grayScale.mediumDark};
`;

export const StyledSQLBoard = styled.div`
  border-radius: ${borderRadii.small};
  border: 1px solid ${grayScale.medium};
  width: 100%;
  min-height: 100px;
  overflow: hidden;
`;

export const StyledNoSQLNote = styled(StyledSQLBoard)`
  background-color: ${grayScale.light};
  padding: 10px;
`;

export const StyledExecutionLoader = styled(Loader)`
  padding-top: 0 !important;
  padding-left: 10px !important;
`;

export const StyledSQLLoader = styled(Loader)`
  padding-top: 10px !important;
`;

export const StyledCenteredNotice = styled(KizenTypography)`
  text-align: center;
  padding: 10px;
`;

export const StyledSQLBoardHeader = styled.div`
  border: 1px solid ${grayScale.medium};
  border-top-right-radius: ${borderRadii.small};
  border-top-left-radius: ${borderRadii.small};
`;

export const StyledSQLBoardHeaderControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

export const StyledSQLBoardHeaderResults = styled(StyledSQLBoardHeaderControls)`
  background-color: ${appBackground};
  border-top: 1px solid ${grayScale.medium};
  justify-content: space-between;
  padding: 0;
`;

export const StyledSQLResultsButtons = styled.div`
  display: flex;
  align-items: flex-start;
  height: fit-content;
  align-self: flex-start;
  padding: 10px;
  padding-left: 0;
  margin-left: -10px;
  & > button {
    margin: 0;
  }
`;

export const StyledSQLContent = styled(KizenTypography)<{ disabled: boolean }>`
  padding: 10px;
  white-space: pre-wrap;

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${grayScale.light};
        `
      : css`
          user-modify: read-write;
          outline: none;
        `}
`;

export const StyledHeaderButtons = styled.div`
  margin-left: auto;
  & > button {
    margin: 0;
  }
`;

export const StyledScriptEditor = styled(BasicRichTextEditor)`
  padding-bottom: 50px;
  & > div {
    border-top: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  & .ProseMirror {
    font-family: 'Courier New', Monospace;
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${grayScale.light};
      `}
  }
`;

export const StyledSQLScriptMessage = styled(KizenTypography)`
  width: 100%;
`;

export const StyledSQLScriptResults = styled(BasicRichTextEditor)`
  width: 100%;
  & > div {
    border-radius: 0;
    border: none;
    border-right: 1px solid ${grayScale.medium};
    background-color: transparent;
  }
  & .ProseMirror * {
    line-height: 1.2em;
  }
`;

export const StyledPackageButton = styled(Button)`
  margin: 0;
  padding: 0;
`;
