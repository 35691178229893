import DOMPurify from 'dompurify';
import { useMemo } from 'react';

// copied from apps/react-app/hooks/useSanitizedHtml
export const useSanitizedHtml = (html, options) => {
  return useMemo(() => {
    try {
      return DOMPurify.sanitize(html, options);
    } catch (err) {
      return '';
    }
  }, [html, options]);
};
