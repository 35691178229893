import { toastVariant, useToast } from 'components/ToastProvider';
import { respondToAccessRequestQuery } from 'pages/Dashboard/queries';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

const useGenericAccessRequestResponse = ({
  refetch,
  entitySingular,
  onSettled,
}) => {
  const [showToast] = useToast();
  const { t } = useTranslation();
  const history = useHistory();

  const { mutate: respond } = useMutation(
    (result) => {
      return respondToAccessRequestQuery(result.requestId, result.approved);
    },
    {
      onSuccess: (_data, variables) => {
        const { approved } = variables;
        if (approved) {
          // aprove the request
          showToast({
            message: `${t(
              'You have successfully granted access to a'
            )} ${entitySingular}.`,
            variant: toastVariant.SUCCESS,
          });
          refetch?.();
        } else {
          // deny the request
          showToast({
            message: `${t('You have denied access to a')} ${entitySingular}.`,
            variant: toastVariant.FAILURE,
          });
        }
      },
      onSettled: () => onSettled?.(history),
      onError: (payload) => {
        showToast({
          message:
            payload?.response?.data?.non_field_errors?.[0] ??
            t('Could not respond to access request. An error occurred.'),
          variant: toastVariant.FAILURE,
        });
      },
    }
  );

  return respond;
};

export default useGenericAccessRequestResponse;
