import { JSONEditorWrapper } from 'pages/Marketplace/styles';
import { KeyboardEvent } from 'react';

interface JSONEditorProps {
  value: string;
  onChange: (value: string) => void;
}

export const JSONEditor = ({ value, onChange }: JSONEditorProps) => {
  const handleKeyPress = (e: KeyboardEvent<any>) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const start = e.currentTarget.selectionStart;
      const end = e.currentTarget.selectionEnd;
      if (start === end) {
        const result =
          e.currentTarget.value.substring(0, start) +
          '  ' +
          e.currentTarget.value.substring(end);
        e.currentTarget.value = result;
        e.currentTarget.selectionStart = start + 2;
        e.currentTarget.selectionEnd = start + 2;
        onChange(result);
      }
    }
  };

  return (
    <JSONEditorWrapper
      // Disable grammarly in case anyone is using it, because it's annoying when editing JSON
      data-enable-grammarly="false"
      data-gramm_editor="false"
      data-gramm="false"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={handleKeyPress}
    />
  );
};
