import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell as KizenSizedCell,
  SortableHeadCell as KizenSortableHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';

import { Text } from 'app/typography';
import styled from '@emotion/styled';
import {
  ProviderTypeCodes,
  StatusCodeTypes,
} from 'pages/Account/pages/Profile/Forms/EmailIntegration';

import BaseActionCell, {
  ActionHeadCell as BaseActionHeadCell,
} from 'components/Tables/ActionCell';
import { StatusCell } from './styles';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';

// Small alignment tweak, esp to align correctly with action button
const SizedCell = styled(KizenSizedCell)`
  > div {
    margin-top: 1px; // hack to force the th to 50px so the bottom border shows
  }
`;

// Small alignment tweak, esp to align correctly with action button
const SortableHeadCell = styled(KizenSortableHeadCell)`
  > div {
    margin-top: 1px; // hack to force the th to 50px so the bottom border shows
  }
`;

// Small alignment tweak, esp to align correctly with action button
const ActionHeadCell = styled(BaseActionHeadCell)`
  > div {
    line-height: 0;
    margin-top: 1px; // hack to force the th to 50px so the bottom border shows
  }
`;

const ActionCell = ({ data, access, ...others }) => {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();
  const showBlock = (blocked) =>
    preReleaseFeatures && access.privacy.edit && blocked;
  return (
    <BaseActionCell
      data={data}
      options={[
        access.integratedInboxes.remove && {
          value: 'delete',
          label: t('Delete'),
        },
        showBlock(!data.blocked) && {
          value: 'block',
          label: t('Block Outgoing Emails'),
        },
        showBlock(data.blocked) && {
          value: 'unblock',
          label: t('Unblock Outgoing Emails'),
        },
      ].filter(Boolean)}
      {...others}
    />
  );
};

ActionCell.propTypes = {
  data: PropTypes.object.isRequired,
};

const columnWidths = (width) => ({
  width,
  maxWidth: width,
  minWidth: width,
});

const getColumnSizings = (hasActions) => ({
  leftSpacer: { width: '20px' },
  teamMember: { ...columnWidths('140px'), padding: '40px' },
  email: { ...columnWidths(hasActions ? '140px' : '192px'), padding: '40px' },
  provider: { ...columnWidths('74px'), padding: '40px' },
  emailStatus: {
    ...columnWidths('70px'),
    padding: hasActions ? '40px' : '0px',
  },
  actions: { ...columnWidths('60px'), padding: '10px' },
});

export const getColumns = ({ onSelectAction, t, access }) => {
  const hasActions = access.privacy.edit;
  const columnSizings = getColumnSizings(hasActions);
  return [
    {
      id: 'spacer',
      headCell: <SizedCell {...columnSizings.leftSpacer} />,
      cell: <SizedCell {...columnSizings.leftSpacer} />,
    },
    {
      id: 'teamMember',
      headCell: (
        <SortableHeadCell
          by="team_member__name"
          label={t('Team Member')}
          {...columnSizings.teamMember}
        />
      ),
      cell: <SizedCell {...columnSizings.teamMember} />,
      format: (teamMember) => {
        return (
          <TextEllipsisWithTooltip>{teamMember?.name}</TextEllipsisWithTooltip>
        );
      },
    },
    {
      id: 'email',
      headCell: (
        <SortableHeadCell
          by="email"
          label={t('Integrated Email')}
          {...columnSizings.email}
        />
      ),
      cell: <SizedCell {...columnSizings.email} />,
      format: (integratedEmail) => (
        <TextEllipsisWithTooltip>{integratedEmail}</TextEllipsisWithTooltip>
      ),
    },
    {
      id: 'provider',
      headCell: (
        <SortableHeadCell label={t('Provider')} {...columnSizings.provider} />
      ),
      cell: <SizedCell {...columnSizings.provider} />,
      format: (value) => (
        <Text>
          {value === ProviderTypeCodes.GMAIL ? t('Gmail') : t('Outlook')}
        </Text>
      ),
    },
    {
      id: 'emailStatus',
      headCell: (
        <SortableHeadCell label={t('Status')} {...columnSizings.emailStatus} />
      ),
      cell: <SizedCell {...columnSizings.emailStatus} />,
      format: (emailStatus) => (
        <StatusCell statusCode={emailStatus}>
          {[StatusCodeTypes.ERROR, StatusCodeTypes.NOT_SETUP].includes(
            emailStatus
          )
            ? t('Inactive')
            : t('Active')}
        </StatusCell>
      ),
    },
    hasActions && {
      id: 'actions',
      headCell: (
        <ActionHeadCell label={t('Actions')} {...columnSizings.actions} />
      ),
      cell: (
        <ActionCell
          title={t('Edit Integrated Email')}
          {...columnSizings.actions}
          onSelectAction={onSelectAction}
          access={access}
          menuType={'observable'}
        />
      ),
    },
  ].filter(Boolean);
};
