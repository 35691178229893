export const eventTypes = {
  entityCreated: 'entity_created',
  activityLogged: 'activity_logged',
  activityScheduled: 'activity_scheduled',
  scheduledActivityUpdated: 'scheduled_activity_updated',
  scheduledActivityDeleted: 'scheduled_activity_deleted',
  fieldUpdated: 'field_updated',
  automationCreated: 'automation_created',
  automationUpdated: 'automation_updated',
  automationStarted: 'automation_started',
  automationPaused: 'automation_paused',
  automationCanceled: 'automation_cancelled',
  automationResumed: 'automation_resumed',
  emailOpened: 'email_opened',
  emailDelivered: 'email_delivered',
  emailComplained: 'email_complained',
  emailHardBounced: 'email_hard_bounced',
  emailSoftBounced: 'email_soft_bounced',
  emailLinkClicked: 'email_link_clicked',
  subsectionListOptedIn: 'subscription_list_opted_in',
  subsectionListOptedOut: 'subscription_list_opted_out',
  subsectionListUnsubscribeAll: 'subscription_list_unsubscribe_all',
  subsectionListNotOptedIn: 'subscription_list_not_opted_in',
  formSubmitted: 'form_submitted',
  entityArchived: 'entity_archived',
  entityUnArchived: 'entity_unarchived',
  surveySubmitted: 'survey_submitted',
  integratedInboxEmailReceived: 'integrated_inbox_email_received',
  integratedInboxEmailSent: 'integrated_inbox_email_sent',
  impressionInteraction: 'impression_interaction',
  clickInteraction: 'click_interaction',
  siteentryImpressionInteraction: 'siteentry_impression_interaction',
  customInteraction: 'custom_interaction',
  leadSourceCreated: 'lead_source_created',
  emailAttachmentOpened: 'email_attachment_opened',
  recordAccessUpdate: 'record_access_update',
  smartConnectorCreated: 'smart_connector_created',
  smartConnectorDeleted: 'smart_connector_deleted',
  smartConnectorScriptPublished: 'smart_connector_script_published',
  smartConnectorStatusChanged: 'smart_connector_status_changed',
  smartConnectorNewReferenceFile: 'smart_connector_new_reference_file',
  smartConnectorSqlDraftRun: 'smart_connector_sql_draft_run',
};

export const renderableEvents = new Set(Object.values(eventTypes));

export const initiatorTypes = {
  automation: 'automation',
  connected_inbox: 'connected_inbox',
  employee: 'employee',
  employee_upload: 'employee_upload',
  employee_api_request: 'employee_api_request',
  trigger: 'trigger',
  form: 'form',
  survey: 'survey',
  siteVisit: 'site_visit',
  'empty-initiator': 'empty-initiator', // special status - for case initiator=null
  adClick: 'ad_click',
  system: 'system',
  smart_connector: 'smart_connector',
};

export const PREVIOUS_PAGE_HEIGHT = 25;
