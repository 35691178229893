import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Checkbox from './Checkbox';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import SelectableOptions from '../SelectableOptions';
import { applyRef, optionProp, selectedValueProp, variantProp } from '../props';
import MultiSelect from '../MultiSelect';
import Validation, { useValidation } from '../Validation';

const CheckboxGroup = React.forwardRef((props, ref) => {
  const {
    onChange,
    options,
    value: valueProp,
    variant,
    error,
    disabled,
    className,
  } = props;
  const { t } = useTranslation();

  const validationRef = useRef();
  const [, validationProps] = useValidation(validationRef, props);
  const value = useMemo(() => {
    if (!valueProp || !options) {
      return valueProp;
    }
    return valueProp
      .map((val) =>
        typeof val === 'string' ? options.find((opt) => opt.value === val) : val
      )
      .filter(Boolean);
  }, [valueProp, options]);

  const mergeRef = useCallback(
    (node) => {
      applyRef(ref, node);
      applyRef(validationRef, node);
    },
    [ref]
  );

  if (variant === 'underline' && options.length >= 5) {
    return (
      <MultiSelect placeholder={t('Find Options')} {...props} value={value} />
    );
  }

  return (
    <MaybeInputControl {...props}>
      <SelectableOptions
        ref={mergeRef}
        className={!hasInputControl(props) && className}
      >
        {options.map((opt) => (
          <div key={opt.value}>
            <Checkbox
              label={opt.label}
              value={opt.value}
              checked={value.some((val) => val.value === opt.value)}
              error={error}
              disabled={disabled}
              variant={variant}
              onChange={(checked, ev) => {
                const nextValue = checked
                  ? [...value, opt]
                  : value.filter((val) => val.value !== opt.value);
                if (onChange) onChange(nextValue, ev);
              }}
            />
          </div>
        ))}
        <Validation {...validationProps} />
      </SelectableOptions>
    </MaybeInputControl>
  );
});

export default CheckboxGroup;

CheckboxGroup.displayName = 'CheckboxGroup';
CheckboxGroup.propTypes = {
  value: PropTypes.arrayOf(selectedValueProp),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionProp),
  variant: variantProp,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};
CheckboxGroup.defaultProps = {
  value: [],
  onChange: null,
  options: [],
  variant: null,
  error: null,
  disabled: null,
};

const singleOptions = [{ value: 'selected' }];
const emptyValue = [];

export const CheckboxSingle = React.forwardRef(
  ({ checked, onChange, ...props }, ref) => (
    <CheckboxGroup
      ref={ref}
      {...props}
      options={singleOptions}
      value={checked ? singleOptions : emptyValue}
      onChange={(val, ev) => {
        if (onChange) onChange(val.length > 0, ev);
      }}
    />
  )
);

CheckboxSingle.displayName = 'CheckboxSingle';
CheckboxSingle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
CheckboxSingle.defaultProps = {
  checked: undefined,
  onChange: null,
};
