import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { SubSectionWithHeader as SubSection } from 'components/Layout/SubSection';
import {
  StyledCheckAllWrapper,
  StyledCheckboxGroup,
  StyledRotatingIconButton,
} from './styles';
import { useAsync } from 'react-use';
import ActivityService from 'services/ActivityService';
import Automation2Service from 'services/Automation2Service';
import ConfigurationService from 'services/ConfigurationService';
import CustomObjectsService from 'services/CustomObjectsService';
import { fetchDashboardsQuery } from 'components/AccessRequests/useDashboardList';
import LibraryService from 'services/LibraryService';
import Loader from 'components/Kizen/Loader';
import TeamMemberService from 'services/TeamMemberService';
import ToolbarService from 'services/ToolbarService';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { useEffect, useState } from 'react';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { toastVariant, useToast } from 'components/ToastProvider';
import { getErrorMessage } from './helpers';
import { useSelector } from 'react-redux';
import { getChosenBusiness } from 'store/authentication/selectors';
import { FIELD_TYPES } from 'utility/constants';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import { colorsButton } from 'app/colors';
import Checkbox from 'components/Inputs/CheckboxGroup/Checkbox';

const ApplyAllCheckbox = ({ label, onCheck }) => {
  const [checked, setChecked] = useState(false);

  const onChange = () => {
    setChecked(true);
    onCheck();
    setTimeout(() => setChecked(false), 300);
  };

  return <Checkbox label={label} onChange={onChange} checked={checked} />;
};

const ExportGroup = ({
  label,
  options,
  selected,
  setSelected,
  keyListenersProps,
}) => {
  const { t } = useTranslation();
  const selectAll = () => setSelected(options);
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <>
      <StyledCheckAllWrapper>
        <ApplyAllCheckbox
          label={`${label} (${selected.length} / ${options.length})`}
          onCheck={selectAll}
        />
        <StyledRotatingIconButton
          sizing="dense"
          color={colorsButton.iconGray}
          onClick={toggleExpanded}
          expanded={expanded}
          title={expanded ? t('Collapse') : t('Expand')}
        >
          <IconSizing size="14px">
            <Icon icon="chevron-down" />
          </IconSizing>
        </StyledRotatingIconButton>
      </StyledCheckAllWrapper>
      <StyledCheckboxGroup
        value={selected}
        onChange={setSelected}
        options={options}
        {...keyListenersProps}
        fieldType={FIELD_TYPES.Checkboxes.type}
        expanded={expanded}
        maxHeight={options.length * 27}
      />
    </>
  );
};

export const ExportSection = ({ setDirty }) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const { admin_permission_role } = useSelector(getChosenBusiness);

  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedFilterGroups, setSelectedFilterGroups] = useState([]);
  const [selectedHomepages, setSelectedHomepages] = useState([]);
  const [selectedToolbarTemplates, setSelectedToolbarTemplates] = useState([]);
  const [selectedEmailTemplates, setSelectedEmailTemplates] = useState([]);
  const [selectedAutomations, setSelectedAutomations] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const isDirty =
    selectedObjects.length > 0 ||
    selectedRoles.length > 0 ||
    selectedActivities.length > 0 ||
    selectedFilterGroups.length > 0 ||
    selectedHomepages.length > 0 ||
    selectedToolbarTemplates.length > 0 ||
    selectedEmailTemplates.length > 0 ||
    selectedAutomations.length > 0;

  useEffect(() => {
    setDirty(isDirty);
  }, [setDirty, isDirty]);

  const {
    value: [
      customObjects = EMPTY_ARRAY,
      roles = EMPTY_ARRAY,
      activities = EMPTY_ARRAY,
      toolbarTemplates = EMPTY_ARRAY,
      emailTemplates = EMPTY_ARRAY,
      automations = EMPTY_ARRAY,
      homepages = EMPTY_ARRAY,
    ] = EMPTY_ARRAY,
    loading: loadingCustomObjects,
  } = useAsync(async () => {
    const [
      objects,
      roles,
      activities,
      toolbarTemplates,
      emailTemplates,
      automations,
      homepages,
    ] = await Promise.all([
      CustomObjectsService.getCustomObjectListLight(),
      TeamMemberService.getRolesOptionsList(),
      ActivityService.v2GetActivityFullList({
        page_size: FORCE_ALL_RECORDS_SIZE,
        ordering: 'name',
      }),
      ToolbarService.getTemplateFullList({
        page_size: FORCE_ALL_RECORDS_SIZE,
        ordering: 'name',
        light: true,
      }),
      LibraryService.getTemplateFullList({
        page: { size: FORCE_ALL_RECORDS_SIZE },
        ordering: 'name',
      }),
      Automation2Service.getAutomationFullList({
        page_size: FORCE_ALL_RECORDS_SIZE,
        ordering: 'name',
      }),
      fetchDashboardsQuery('', false, undefined, 'homepage'),
    ]);

    return [
      objects.map(({ id, name, objectName }) => ({
        value: id,
        label: objectName,
        data: { id, name, include_related_objects: false },
      })),
      roles
        .filter(({ value }) => value !== admin_permission_role.id)
        .map(({ value, label }) => ({
          value,
          label,
          data: { id: value, name: label },
        })),
      activities.map(({ id, name }) => ({
        value: id,
        label: name,
        data: { id, name },
      })),
      toolbarTemplates.map(({ id, name }) => ({
        value: id,
        label: name,
        data: { id, name },
      })),
      emailTemplates.map(({ id, name }) => ({
        value: id,
        label: name,
        data: { id, name },
      })),
      automations.map(({ id, apiName, name }) => ({
        value: id,
        label: name,
        data: { id, name: apiName },
      })),
      homepages.map(({ id, name }) => ({
        value: id,
        label: name,
        data: { id, name },
      })),
    ];
  }, []);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'customObjects' },
      { id: 'roles' },
      { id: 'activities' },
      { id: 'filterGroups' },
      { id: 'toolbarTemplates' },
      { id: 'emailTemplates' },
      { id: 'automations' },
      { id: 'homepages' },
    ],
    {},
    () => true,
    true
  );

  const handleSubmit = async () => {
    const data = {
      custom_objects: selectedObjects.map(({ data }) => data),
      roles: selectedRoles.map(({ data }) => data),
      activity_objects: selectedActivities.map(({ data }) => data),
      custom_object_filter_groups: selectedFilterGroups.map(({ data }) => ({
        id: data.id,
      })),
      toolbar_templates: selectedToolbarTemplates.map(({ data }) => data),
      email_templates: selectedEmailTemplates.map(({ data }) => data),
      automations: selectedAutomations.map(({ data }) => data),
      homepages: selectedHomepages.map(({ data }) => data),
    };

    setSubmitting(true);

    try {
      await ConfigurationService.exportConfiguration(data, {
        skipErrorBoundary: true,
      });

      setSelectedObjects([]);
      setSelectedRoles([]);
      setSelectedActivities([]);
      setSelectedFilterGroups([]);
      setSelectedToolbarTemplates([]);
      setSelectedEmailTemplates([]);
      setSelectedAutomations([]);
      setSelectedHomepages([]);

      showToast({
        message: t('Export queued. Check your email for download link.'),
        variant: toastVariant.SUCCESS,
      });
    } catch (err) {
      showToast({
        message:
          getErrorMessage(err) ||
          t('Export failed. Please try again or contact Kizen support.'),
        variant: toastVariant.FAILURE,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const checkboxesConfig = [
    {
      name: 'customObjects',
      label: t('Custom Objects'),
      options: customObjects,
      selected: selectedObjects,
      setSelected: setSelectedObjects,
    },
    {
      name: 'roles',
      label: t('Roles'),
      options: roles,
      selected: selectedRoles,
      setSelected: setSelectedRoles,
    },
    {
      name: 'activities',
      label: t('Activities'),
      options: activities,
      selected: selectedActivities,
      setSelected: setSelectedActivities,
    },
    {
      name: 'filterGroups',
      label: t('Filter Groups'),
      options: customObjects,
      selected: selectedFilterGroups,
      setSelected: setSelectedFilterGroups,
    },
    {
      name: 'toolbarTemplates',
      label: t('Toolbar Templates'),
      options: toolbarTemplates,
      selected: selectedToolbarTemplates,
      setSelected: setSelectedToolbarTemplates,
    },
    {
      name: 'emailTemplates',
      label: t('Email Templates'),
      options: emailTemplates,
      selected: selectedEmailTemplates,
      setSelected: setSelectedEmailTemplates,
    },
    {
      name: 'automations',
      label: t('Automations'),
      options: automations,
      selected: selectedAutomations,
      setSelected: setSelectedAutomations,
    },
    {
      name: 'homepages',
      label: t('Homepages'),
      options: homepages,
      selected: selectedHomepages,
      setSelected: setSelectedHomepages,
    },
  ];

  return (
    <SubSection
      title={t('Configuration Export')}
      action={
        <Button onClick={handleSubmit} disabled={!isDirty || submitting}>
          {t('Export')}
        </Button>
      }
    >
      <KeyBoardContext.Provider value={{ assignFieldHandle }}>
        <Loader loading={loadingCustomObjects}>
          {checkboxesConfig.map(
            ({ name, label, options, selected, setSelected }) => (
              <ExportGroup
                key={name}
                keyListenersProps={getKeyListenersProps(name)}
                label={label}
                options={options}
                selected={selected}
                setSelected={setSelected}
              />
            )
          )}
        </Loader>
      </KeyBoardContext.Provider>
    </SubSection>
  );
};
