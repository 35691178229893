import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import MultiSelect from 'components/Inputs/MultiSelect';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import useLeadSourceOptions from 'components/Wizards/Dashlet/hooks/useLeadSourceOptions';
import useObjectOptions from 'components/Wizards/Dashlet/hooks/useObjectOptions';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const LeadSourceBreakdownReportType = ({ active, mobile }) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);
  const { reportType, objectsWithLeads, sourcesToBreakDown } = state;

  const clientObject = useSelector(getBusinessClientObject);

  const clientObjectId = clientObject?.id;

  const { isLoading, ...objectsSelectProps } = useObjectOptions({
    useClient: true,
    enabled: Boolean(clientObjectId),
    clientObjectId,
  });

  const leadSourceSelectProps = useLeadSourceOptions();

  const objectsOnChange = useCallback(
    (value) => {
      setState('objectsWithLeads', value);
      setState('customFilters', undefined);
      setState('inGroupFilters', undefined);
      setState('notInGroupFilters', undefined);
    },
    [setState]
  );

  const sourcesOnChange = useCallback(
    (value) => {
      setState('sourcesToBreakDown', value);
    },
    [setState]
  );

  useDefaultValues(
    {
      display: 'line',
    },
    active &&
      reportType?.value === REPORT_TYPES.LEAD_SOURCE_BREAKDOWN &&
      objectsWithLeads
  );

  // Hide self if the report type is not lead source breakdown
  if (reportType?.value !== REPORT_TYPES.LEAD_SOURCE_BREAKDOWN) {
    return null;
  }

  return (
    <>
      <StyledWizardField top={gutters.spacing(2, 2)}>
        <KizenTypography type="subheader">
          {t('Choose Objects that Have Records Qualifying as Leads')}
        </KizenTypography>
        <MultiSelect
          placeholder={t('Find Object(s)')}
          className={`modal-multiselect objects ${
            isLoading ? 'loading' : 'ready'
          }`}
          value={objectsWithLeads}
          onChange={objectsOnChange}
          {...objectsSelectProps}
          isLoading={isLoading}
        />
      </StyledWizardField>
      <StyledWizardField top={gutters.spacing(6)}>
        <KizenTypography type="subheader">
          {t('Choose Lead Sources to Breakdown')} {t('(Leave Blank for All)')}
        </KizenTypography>
        <MultiSelect
          placeholder={t('Find Lead Source(s)')}
          className="modal-multiselect lead-sources"
          value={sourcesToBreakDown}
          onChange={sourcesOnChange}
          {...leadSourceSelectProps}
          includeGroupPlaceholder={false}
          classNamePrefix="lead-sources"
        />
      </StyledWizardField>
    </>
  );
};

export default LeadSourceBreakdownReportType;
