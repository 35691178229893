import { useSelector } from 'react-redux';

import {
  getSmartConnectorAccess,
  getAuthTeamMember,
} from 'store/authentication/selectors';

export type SmartConnectorAccess = {
  view: boolean;
  edit: boolean;
  remove: boolean;
};

export const useSmartConnectorAccess = (owner?: string) => {
  const smartConnectorAccess = useSelector(getSmartConnectorAccess);
  const authTeamMember = useSelector(getAuthTeamMember);

  const { associated_records: associatedRecords, all_records: allRecords } =
    smartConnectorAccess || {};

  const canAddSmartConnector = smartConnectorAccess?.associated_records?.edit;

  return {
    canAddSmartConnector,
    smartConnectorAccess:
      owner === authTeamMember?.id ? associatedRecords : allRecords,
  };
};
