const getLinkMarkAttrs = (node) => {
  return node.marks?.find((mark) => mark.type.name === 'link')?.attrs ?? null;
};

const reduceText = (acc, node) => {
  return node.isText
    ? acc.concat(node.text)
    : node.content.content.reduce(reduceText, acc);
};

/**
 * Reducer function to get the link attributes associated with a selection's slice of nodes iff all the text
 * nodes have a link mark with the same href attribute. Otherwise, returns null.
 *
 * @remarks The initial value of the reduction is expected to be an empty object. The accumulator will be set to null
 * any time a text node does not have a link mark or the link mark's href does not match the current href, and once null
 * the accumulator will remain null.
 *
 * @returns (string | null)
 */
const reduceLink = (acc, node) => {
  if (acc === null) return null;
  if (!node.isText) return node.content.content.reduce(reduceLink, acc);
  const attrs = getLinkMarkAttrs(node);
  return attrs ? (!acc.link || attrs.href === acc.href ? attrs : null) : null;
};

export const getLinkText = (editor) => {
  let text = '';

  editor
    .chain()
    .focus()
    .extendMarkRange('link')
    .command(({ tr }) => {
      const slice = tr.curSelection.content();
      text = slice.content.content.reduce(reduceText, '');
      return true;
    })
    .run();

  return text;
};

export const getLinkAttributes = (editor) => {
  let attrs = {};

  editor
    .chain()
    .focus()
    .extendMarkRange('link')
    .command(({ tr }) => {
      const slice = tr.curSelection.content();
      attrs = slice.content.content.reduce(reduceLink, {});
      return true;
    })
    .run();

  return attrs || {};
};
