import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// TODO add back when needed to schedule activities
import { ScheduleActivityModal } from 'components/Charts/ScheduledActivities/ScheduleActivityModal';
import ActivityService from 'services/ActivityService';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import {
  SCHEDULE_ACTIVITY_SUCCESS,
  SCHEDULE_ACTIVITY_FAIL,
} from 'pages/Common/Actions/activityHelpers';
import { toastVariant, useToast } from 'components/ToastProvider';
import { layers } from 'app/spacing';
import { StartAutomationSelect } from '../Automations/StartAutomationButton';
import { getOriginalError } from 'services/AxiosService';

const CustomAutomationActions = ({
  showSelectedAutomation,
  target,
  customObject,
  onSetVisible,
  scrolled,
  predefinedOptions,
  shownScrolled,
  scheduleActivityModal,
  onStartAutomation,
  customObjectId,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [isSchedulingActivity, setSchedulingActivity] = useState(false);

  useEffect(
    () => {
      if ((scrolled && !shownScrolled) || (!scrolled && shownScrolled)) {
        // makes sure the menus close if scrolled
        onSetVisible(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrolled, shownScrolled]
  );

  return (
    <>
      <SelectOverlay
        show={showSelectedAutomation}
        target={target}
        onHide={() => {
          onSetVisible(false);
        }}
        zIndexNudge={layers.content(0, 3)}
      >
        <StartAutomationSelect
          customObjectId={customObjectId}
          onApply={onStartAutomation}
          onCancel={() => onSetVisible(false)}
        />
      </SelectOverlay>
      <ScheduleActivityModal
        key={scheduleActivityModal.key}
        predefinedOptions={{
          ...predefinedOptions, // may override client and company options
        }}
        show={scheduleActivityModal.showing}
        onHide={scheduleActivityModal.hide}
        onConfirm={async (activityInfo) => {
          setSchedulingActivity(true);
          try {
            scheduleActivityModal.hide();
            await ActivityService.v2CreateScheduledActivities(activityInfo);
            showToast({
              variant: toastVariant.SUCCESS,
              message: SCHEDULE_ACTIVITY_SUCCESS(t),
            });
          } catch (err) {
            const orig = getOriginalError(err);
            showToast({
              variant: toastVariant.FAILURE,
              message: orig?.message || SCHEDULE_ACTIVITY_FAIL(t),
            });
          } finally {
            setSchedulingActivity(false);
          }
        }}
        disabled={isSchedulingActivity}
      />
    </>
  );
};

export default CustomAutomationActions;
