import DraggableStepCard from '../../DraggableStepCard';
import { getRelationshipFieldsContext } from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/CreateRelatedEntity/helpers';
import { getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, fields, t }) => {
  const { relatedObjectName } = getRelationshipFieldsContext(
    fields,
    step.details.contextEntityField
  );
  return `${t('Create New Entity in `{{relatedObjectName}}`', {
    relatedObjectName: getValueOrDeleted(relatedObjectName, t),
  })}`;
};

export default function CreateRelatedEntityCard({ step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
