import React from 'react';
import {
  FieldSettingsSection,
  ElementContainerSettingsSection,
} from '../../settings';

export const FormFieldSettings = ({ node }) => (
  <>
    <FieldSettingsSection node={node} key={node.id} />
    <ElementContainerSettingsSection node={node} />
  </>
);
