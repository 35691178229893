import { ActionCell } from '../columns';

export const ApiKeysActionCell = ({ t, canRemove = true, ...props }) => {
  return (
    <ActionCell
      options={[
        { value: 'onKeyView', label: t('View API Key') },
        canRemove && { value: 'onKeyDelete', label: t('Delete API Key') },
      ].filter(Boolean)}
      title={t('View API Key')}
      t={t}
      {...props}
    />
  );
};
