export const parseMentions = (text: string) => {
  const parser = new DOMParser();
  const document = parser.parseFromString(text, 'text/html');
  const selectedMentions = document.querySelectorAll('[data-mention]');
  return [
    ...new Set(
      Array.from(selectedMentions).map((item) => item.getAttribute('data-id'))
    ),
  ];
};
