import steps from '__pages/AutomationEngine/steps';
import { UPLOAD } from '__pages/AutomationEngine/steps';
import {
  SmartConnector,
  SmartConnectorFlowLoad,
} from '__pages/SmartConnectors/types';
import { FlowNode } from './types';

export const loadToUploadStep = (
  smartConnector: SmartConnector,
  load: SmartConnectorFlowLoad,
  index: number
): FlowNode => {
  const isPrimary = load.custom_object === smartConnector.custom_object;
  return {
    id: `${load.id || 'new'}.${UPLOAD}.${isPrimary ? 'primary_object' : 'related_object'}.${load.custom_object}`,
    type: isPrimary
      ? steps.uploadPrimaryObject.type
      : steps.uploadRelatedObject.type,
    details: load,
    order: index,
    prefix: 'step',
    description: '',
    hasError: false,
  };
};
