import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { gutters } from '../../app/spacing';
import { grayScale } from '../../app/colors';

export const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: ${gutters.spacing(2)}px;
  background-color: ${({ color }) => color || grayScale.mediumDark};
`;

export default function ColorLabel({ color, children }) {
  return (
    <>
      <Dot color={color} />
      {children}
    </>
  );
}

ColorLabel.propTypes = {
  color: PropTypes.string,
};

ColorLabel.defaultProps = {
  color: null,
};
