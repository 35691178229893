import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const DEFAULT_DEBOUNCE = 300;
const initialDomRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

const debounce = (fn, time = DEFAULT_DEBOUNCE) => {
  let timeoutId = null;
  return () => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, time);
  };
};

/**
 * Get an element's [bounding rect](https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect).
 * Uses [ResizeObserver](https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver) to watch for changes.
 *
 * @param {any[]} opts.deps - values that will force a recalcuation when changed.
 * @param {Element} opts.scrollTarget - a reference to the containing scroll container. If provided, the bounding
 * rect will recompute on scroll events (debounced at 300ms).
 * @param {number} opts.scrollDebounce - number of milliseconds used to debounce scroll events. Only relevent when
 * `opts.scrollTarget` is provided. Defaults to 300.
 *
 * @returns 3-tuple
 *  0 - ref callback
 *  1 - a [DOMRect](https://developer.mozilla.org/en-US/docs/Web/API/DOMRect)
 *  2 - target element
 */
export const useBoundingRect = ({
  deps = [],
  scrollTarget = null,
  scrollDebounce = DEFAULT_DEBOUNCE,
} = {}) => {
  const ref = useRef();
  const [domRect, setDomRect] = useState(initialDomRect);
  const debounceVal = scrollDebounce || DEFAULT_DEBOUNCE;

  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        if (entries[0]) {
          setDomRect(entries[0].target.getBoundingClientRect());
        }
      }),
    []
  );

  const refCallback = useCallback(
    (node) => {
      if (ref.current && !node) {
        observer.unobserve(ref.current);
      }
      ref.current = node;
      if (node) {
        observer.observe(node);
      }
    },
    [observer]
  );

  const handler = useCallback(() => {
    if (ref.current) {
      setDomRect(ref.current.getBoundingClientRect());
    }
  }, []);

  useEffect(handler, [...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scrollTarget) {
      const listener = debounce(handler, debounceVal);
      scrollTarget.addEventListener('scroll', listener);
      return () => scrollTarget.removeEventListener('scroll', listener);
    }
  }, [handler, scrollTarget, debounceVal]);

  useEffect(() => {
    const listener = debounce(handler);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [handler]);

  useEffect(() => {
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [observer]);

  return [refCallback, domRect, ref.current];
};
