import { ValidIcons } from '@kizen/kds/Icon';
import { SearchPill } from '@kizen/kds/SearchPill';
import { Spacer } from '@kizen/kds/Spacer';
import { TabConfig, TabMenu } from '@kizen/kds/Tabs';
import { Panel, VerticalTile } from '@kizen/kds/Tile';
import { Typography } from '@kizen/kds/Typography';
import { ColorType } from '@kizen/kds/Colors';
import { DEFAULT_RELATION_FIELDS_TABLE_HEIGHT } from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/utilities';
import { isEqual } from 'lodash';
import { RelatedObjectFields as _RelatedObjectFields } from 'pages/Common/RelatedObjectFields';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { withErrorBoundary } from '../ErrorBoundary';

const RelatedObjectFields = _RelatedObjectFields as any;

interface RelatedObjectsBlockProps {
  customObjectModel: any;
  id: string;
  metadata: any;
  displayName?: string;
  isClient?: boolean;
  clientObject?: any;
}

interface ControllerOptions {
  options: {
    color?: ColorType;
    icon?: any;
    label: string;
    name?: string;
    objectType: string;
    value: string;
  }[];
  value: any;
  onChange: (tab: TabConfig) => void;
  search: string;
  setSearch: (...args: any[]) => any;
  loading: boolean;
}

const propsAreEqual = (prevProps: any, nextProps: any) => {
  return (
    prevProps.id === nextProps.id &&
    isEqual(prevProps.options, nextProps.options) &&
    isEqual(prevProps.value, nextProps.value) &&
    prevProps.search === nextProps.search &&
    prevProps.loading === nextProps.loading &&
    prevProps.displayName === nextProps.displayName
  );
};

const Block = memo((props: RelatedObjectsBlockProps) => {
  const {
    customObjectModel,
    id,
    metadata = {},
    displayName,
    isClient,
    clientObject,
  } = props;
  const { t } = useTranslation();

  const body = (
    <div
      className="w-full flex flex-col h-[var(--table-height)] kds-menu-boundary"
      data-qa="related-objects-block"
      style={
        {
          '--table-height': `${
            metadata.tableHeight ?? DEFAULT_RELATION_FIELDS_TABLE_HEIGHT
          }px`,
        } as any
      }
    >
      <RelatedObjectFields
        objectModel={isClient ? clientObject : customObjectModel}
        entityId={id}
        meta={snakeToCamelCaseKeys(metadata)}
        frameless
        controller={({
          options = [],
          value,
          onChange,
          search,
          setSearch,
          loading,
        }: ControllerOptions) => {
          return (
            <div className="kds pt-spacer-15">
              <div className="flex items-center">
                <div className="flex shrink min-w-0">
                  <Spacer mode="vertical" size={20} />
                  <Typography
                    variant="header"
                    weight="semibold"
                    size="lg"
                    truncate
                    className="max-w-[250px] min-w-0"
                  >
                    {displayName || t('Related')}
                  </Typography>
                </div>
                <Spacer mode="vertical" size={20} />
                <div className="shrink grow">
                  <TabMenu
                    tabs={options.map((option) => {
                      return {
                        color: option.color,
                        icon: option.icon
                          ? (`custom-${option.icon.value}` as ValidIcons)
                          : 'custom-bars',
                        children: option.name ?? option.label,
                        id: option.value,
                        value: option.value,
                      };
                    })}
                    onChange={onChange}
                    value={value?.value}
                  />
                </div>
                <Spacer mode="vertical" size={10} />
                <div className="ml-auto flex items-center min-w-0">
                  <SearchPill
                    placeholder={t('Search')}
                    value={search}
                    onChange={(v) => setSearch(v)}
                    loading={loading}
                  />
                  <Spacer mode="vertical" size={20} />
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );

  return <VerticalTile padding={0} middle={<Panel>{body}</Panel>} />;
}, propsAreEqual);

export const RelatedObjectsBlock = withErrorBoundary(Block);
