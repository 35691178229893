import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale, colorsButton, colorsText } from 'app/colors';
import { gutters } from 'app/spacing';
import { TextSpan } from 'app/typography';

export const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  margin-top: ${gutters.spacing(3)}px;

  .insert-btn {
    margin: 0;
  }

  && .ProseMirror {
    ${({ oneLineLayout }) =>
      oneLineLayout
        ? css`
            padding: ${gutters.spacing(0, 2)}px ${gutters.spacing(2)}px
              ${gutters.spacing(0, 4)}px;
            display: flex;
            align-items: center;
          `
        : ''}
  }
`;

export const StyledLabelRowWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  margin: 12px 0 5px;
`;

export const StyledLabel = styled(TextSpan)`
  line-height: 125%;
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

export const LimitLabel = styled(StyledLabel)`
  ${({ error }) =>
    error
      ? css`
          color: ${colorsButton.red.hover};
        `
      : ''}
`;
