import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserProfile } from 'store/authentication/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import AuthenticationService from 'services/AuthenticationService';

import * as auth from 'utility/Authentication';
import {
  serviceLoginStart,
  serviceChooseBusinessStart,
} from 'store/authentication/authenticationAction';
import { BusinessSelectionChangedRedirect } from 'routes/auth';
import { isEmailValid } from 'utility/validate';
import { FreeTrialProcessSteps } from './FreeTrialProcessTypes';
import {
  StyledFormWrapper,
  StyledForm,
  StyledFormError,
  StyledHorizontalProgressWrapper,
  StyledStepNumber,
  StyledProgress,
  StyledProgressLine,
} from './styles';
import UserInfo from './UserInfo';
import UsageInfo from './UsageInfo';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { monitoringExceptionHelper } from 'sentry/helpers';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  name: '',
  marketing_cloud: false,
  sales_cloud: false,
  experience_cloud: false,
  business_intelligence: false,
  num_team_members: '',
  other_admins: [],
};

const setFormValuesFromUser = (user, query) => {
  const values = cloneDeep(initialValues);
  if (user) {
    values.first_name = user.first_name;
    values.last_name = user.last_name;
    values.email = user.email;
  } else {
    values.first_name = query.get('first_name');
    values.last_name = query.get('last_name');
    values.email = query.get('email');
    values.name = query.get('company__name');
  }
  return values;
};

const FreeTrialProcessPage = ({ title }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));

  const query = useQuery();

  // get user from redux state if logged in
  const user = useSelector(getUserProfile);

  // TODO: loading is not used anywhere so it can be removed unless there will be requirment for Loader component
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState(
    setFormValuesFromUser(user, query)
  );
  const [step, setStep] = useState(FreeTrialProcessSteps.USER_INFO);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorData, setErrorData] = useState(null);
  const [newChosenBusiness, setNewChosenBusiness] = useState();

  const handleUsageInfoDataChange = (field, value) => {
    // other_admins field has to be handled differently, in case we try to add duplicate email only one input with that
    // email will be shown
    if (field === 'other_admins') {
      const validInput = value && isEmailValid(value);

      if (validInput) {
        const admins = formValues.other_admins;
        const existingEmail = admins.indexOf(value) > -1;
        if (!existingEmail) {
          admins.push(value);
          setFormValues({ ...formValues, [field]: admins });
          !!errorMessage && setErrorMessage('');
        }
      } else {
        value && setErrorMessage(t('You did not enter valid email address!'));
      }
    } else {
      setFormValues({ ...formValues, [field]: value });
    }
  };

  const handleAdminFieldChange = (newValue, oldValue) => {
    // in case other_fields input has no value remove empty input field
    if (newValue === '') {
      const admins = formValues.other_admins;
      admins.splice(formValues.other_admins.indexOf(oldValue), 1);
      setFormValues({ ...formValues, other_admins: admins });
    } else {
      const validInput = isEmailValid(newValue);
      if (validInput) {
        const admins = formValues.other_admins;
        admins.splice(formValues.other_admins.indexOf(oldValue), 1, newValue);
        setFormValues({ ...formValues, other_admins: admins });
        !!errorMessage && setErrorMessage('');
      } else {
        setErrorMessage(t('You did not enter valid email address!'));
      }
    }
  };

  const handleUserInfoSubmit = async () => {
    // if user is not logged in we create user in first step and then go to second step with business setup
    if (!user) {
      if (!isEmailValid(formValues.email)) {
        setErrorMessage(t('Must be valid email.'));
        return;
      }

      if (auth.status.authorized) {
        setStep(FreeTrialProcessSteps.USAGE_INFO);
        return;
      }
      setLoading(true);

      const createUserData = {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        email: formValues.email,
        password: formValues.password,
      };

      await AuthenticationService.createUser(createUserData)
        .then(async (response) => {
          setLoading(false);
          setErrorMessage('');
          auth.status.authorized = true;
          setStep(FreeTrialProcessSteps.USAGE_INFO);
        })
        .catch((err) => {
          setLoading(false);
          const message =
            err.response &&
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors.error;
          setErrorMessage(message);
          monitoringExceptionHelper(err);
        });
    } else {
      // if user is logged in we immediately go to second step with business setup
      setStep(FreeTrialProcessSteps.USAGE_INFO);
    }
  };

  const handleBusinessInfoSubmit = async () => {
    setLoading(true);

    const businessInfo = {
      name: formValues.name,
      marketing_cloud: formValues.marketing_cloud,
      sales_cloud: formValues.sales_cloud,
      experience_cloud: formValues.experience_cloud,
      business_intelligence: formValues.business_intelligence,
      num_team_members: parseInt(formValues.num_team_members, 10),
      other_admins: formValues.other_admins,
    };

    // adding the skipErrorBoundary allows the 500 not to mess up the loging process
    await AuthenticationService.createBusiness(businessInfo, {
      no_cc: true,
      skipErrorBoundary: true,
    })
      .then(async (business) => {
        // if we don't have already logged user we login user that was created so
        // select business step is enabled and redirection to dashboard is enabled
        if (!user) {
          await dispatch(
            serviceLoginStart(formValues.email, formValues.password)
          );
        }

        // select created business
        const [error, chosenBusiness] = await dispatch(
          serviceChooseBusinessStart(business.id)
        );

        setLoading(false);

        if (!error) {
          return setNewChosenBusiness(chosenBusiness);
        }

        return setErrorMessage(error.message);
      })
      .catch((err) => {
        setLoading(false);
        monitoringExceptionHelper(err);
        setStep(FreeTrialProcessSteps.USER_INFO);
        setErrorMessage('');
        setErrorData(err?.response?.data?.errors);
        return;
      });
  };

  if (newChosenBusiness) {
    return <BusinessSelectionChangedRedirect />;
  }

  return (
    <StyledFormWrapper>
      <StyledHorizontalProgressWrapper>
        <StyledStepNumber active={!!step}>
          <div>1</div>
        </StyledStepNumber>
        <StyledProgress>
          <StyledProgressLine
            active={step === FreeTrialProcessSteps.USAGE_INFO}
          />
        </StyledProgress>
        <StyledStepNumber active={step === FreeTrialProcessSteps.USAGE_INFO}>
          <div>2</div>
        </StyledStepNumber>
      </StyledHorizontalProgressWrapper>

      <StyledForm>
        {step === FreeTrialProcessSteps.USER_INFO && (
          <UserInfo
            key={FreeTrialProcessSteps.USER_INFO}
            title={t('Start Your Free Trial Of Kizen')}
            values={formValues}
            user={user}
            onChange={(field, value) =>
              setFormValues({ ...formValues, [field]: value })
            }
            onSubmit={handleUserInfoSubmit}
            loading={loading}
            errorData={errorData}
          />
        )}
        {step === FreeTrialProcessSteps.USAGE_INFO && (
          <UsageInfo
            key={FreeTrialProcessSteps.USAGE_INFO}
            title={t('What Clouds Will You Use?')}
            values={formValues}
            onChange={handleUsageInfoDataChange}
            onAdminFieldChange={handleAdminFieldChange}
            onSubmit={handleBusinessInfoSubmit}
            loading={loading}
          />
        )}
        {errorMessage && <StyledFormError>{errorMessage}</StyledFormError>}
      </StyledForm>
    </StyledFormWrapper>
  );
};

export default FreeTrialProcessPage;
