import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import { border, colorsButton, grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import BaseDragAndDropLayout from 'components/DragAndDropLayout';
import DragItem from 'components/DragAndDropLayout/DragItem';
import { IconSizing } from 'components/Kizen/Icon';
import Cols from 'components/Layout/Cols';
import Select from 'components/Inputs/Select';
import Subsection from 'components/Wizards/CustomObject/components/Subsection';
import NewItemButton from 'components/Wizards/CustomObject/components/NewItemButton';
import BasicModal from 'components/Modals/presets/BasicModal';
import useModal from 'components/Modals/useModal';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { isEstimatedCloseDateField } from 'checks/fields';

const DragAndDropLayout = styled(BaseDragAndDropLayout)`
  padding: 0;
  margin-bottom: ${gutters.spacing(3) / 2}px;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  padding: ${gutters.spacing(2, 3)}px ${gutters.spacing(2)}px
    ${gutters.spacing(2, 3)}px ${gutters.spacing(6)}px;
  margin-bottom: 0;
  ${border};
`;

const VisibleField = styled(Field)`
  justify-content: space-between;
  padding: ${gutters.spacing(2, 3)}px ${gutters.spacing(2)}px;

  && i {
    height: 14px;
  }

  ${({ isHidden }) =>
    isHidden &&
    css`
      background-color: ${grayScale.light};
      span {
        color: ${grayScale.medium};
      }

      && > i,
      &&:hover > i,
      && > i:hover {
        cursor: default;
        svg {
          color: ${grayScale.medium} !important;
        }
      }
    `}
`;

const StyledButton = styled(NewItemButton)`
  margin: ${gutters.spacing(3, 1)}px 0 ${gutters.spacing(6, -1)}px;
`;

const DragItemLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
  > :last-child {
    margin-left: ${gutters.spacing(1)}px;
  }
`;

const DraggableItem = ({ t, element, onClickRemove, ...props }) => (
  <DragItem {...props} data-qa={`card-field-${element?.id}`}>
    <DragItemLayout>
      <TextEllipsisWithTooltip as="span">
        {element.field.displayName}
      </TextEllipsisWithTooltip>
      <IconButton
        sizing="dense"
        title={t('Delete')}
        data-qa-icon-name="delete"
        color={colorsButton.iconGray}
        onClick={() => onClickRemove(element.id)}
      >
        <IconSizing size="15px">
          <Icon icon="delete" />
        </IconSizing>
      </IconButton>
    </DragItemLayout>
  </DragItem>
);

DraggableItem.propTypes = {
  element: PropTypes.object.isRequired,
  onClickRemove: PropTypes.func.isRequired,
};

const CardDisplaySettingsSubsection = ({
  values,
  onClickVisibility,
  nameField,
  fieldsOptions,
  onSaveField,
  onChangeOrder,
  onDeleteField,
  fields,
  ...props
}) => {
  const { t } = useTranslation();

  const [chosenField, setField] = useState(null);
  const [addFieldModalProps, , addFieldModal] = useModal({
    handleSubmit: () => {
      onSaveField(chosenField);
    },
    handleHide: () => {
      setField(null);
    },
  });
  const estimatedCloseDateField = fields.find(isEstimatedCloseDateField);

  return (
    <>
      <Subsection title={t('Card Display Customization')} {...props}>
        <Field>
          <TextEllipsisWithTooltip as="span">
            {nameField}
          </TextEllipsisWithTooltip>
        </Field>
        <DragAndDropLayout
          items={values.fields.map((item) => ({
            id: item.value,
            ...item,
          }))}
          onChange={(options) => {
            onChangeOrder(options);
          }}
          handleClassName="itemTarget"
          itemClassName="dndItemWrapper"
          itemElement={<DraggableItem t={t} onClickRemove={onDeleteField} />}
        />
        {values.fields.length < 5 && (
          <StyledButton
            onClick={() => addFieldModal.show()}
            data-qa="add-field"
          >
            {`+ ${t('Add Field')}`}
          </StyledButton>
        )}

        <Cols columns={estimatedCloseDateField ? 3 : 2}>
          <VisibleField
            isHidden={
              !values.estimatedCloseDateVisible || !estimatedCloseDateField
            }
          >
            <KizenTypography as="span">
              {t('Estimated Close Date')}
            </KizenTypography>
            <IconButton
              title={t('Visibility')}
              sizing="dense"
              color={colorsButton.iconGray}
              onClick={() => onClickVisibility('estimatedCloseDateVisible')}
            >
              <IconSizing size="15px">
                <Icon
                  icon={values.estimatedCloseDateVisible ? 'visible' : 'hidden'}
                />
              </IconSizing>
            </IconButton>
          </VisibleField>
          <VisibleField isHidden={!values.timeInStageVisible}>
            <KizenTypography as="span">{t('Time in Stage')}</KizenTypography>
            <IconButton
              title={t('Visibility')}
              sizing="dense"
              color={colorsButton.iconGray}
              onClick={() => onClickVisibility('timeInStageVisible')}
            >
              <IconSizing size="15px">
                <Icon icon={values.timeInStageVisible ? 'visible' : 'hidden'} />
              </IconSizing>
            </IconButton>
          </VisibleField>
          <VisibleField isHidden={!values.ownerVisible}>
            <KizenTypography as="span">{t('Owner')}</KizenTypography>
            <IconButton
              title={t('Visibility')}
              sizing="dense"
              color={colorsButton.iconGray}
              onClick={() => onClickVisibility('ownerVisible')}
            >
              <IconSizing size="15px">
                <Icon icon={values.ownerVisible ? 'visible' : 'hidden'} />
              </IconSizing>
            </IconButton>
          </VisibleField>
        </Cols>
      </Subsection>
      <BasicModal
        heading={t('Add Field to Card')}
        buttonText={t('Save')}
        leftBtn={null}
        disabled={!chosenField}
        {...addFieldModalProps}
      >
        <Select
          fullWidth
          menuInline
          label={t('Choose Field')}
          placeholder={t('Choose Field')}
          options={fieldsOptions}
          value={chosenField}
          onChange={setField}
        />
      </BasicModal>
    </>
  );
};

CardDisplaySettingsSubsection.propTypes = {
  values: PropTypes.object.isRequired,
  onClickVisibility: PropTypes.func.isRequired,
  nameField: PropTypes.string.isRequired,
  fieldsOptions: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  onSaveField: PropTypes.func.isRequired,
  onChangeOrder: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
};

export default CardDisplaySettingsSubsection;
