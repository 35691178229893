import styled from '@emotion/styled';
import BasicModal from 'components/Modals/presets/BasicModal';
import AccessRequest from 'components/Sharing/AccessRequest';
import { TOOLBAR_TEMPLATES } from 'queries/query-keys';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import ToolbarService from 'services/ToolbarService';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

const StyledRequestModal = styled(BasicModal)`
  & .modal-body {
    overflow: visible;
  }
`;

export const AccessRequestModal = ({
  template,
  onConfirm,
  fetchProps = EMPTY_ARRAY,
  serviceToUse = ToolbarService,
  ...others
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState();

  const { data: grantingAdmins, isLoading: grantingAdminsLoading } = useQuery(
    [...TOOLBAR_TEMPLATES.GRANTING_ADMINS, template.id],
    () => serviceToUse.getGrantingAdmins(template.id, ...fetchProps),
    {
      enabled: Boolean(template.id),
    }
  );

  const grantingAdminOptions = useMemo(() => {
    return (grantingAdmins ?? []).map((a) => ({
      value: a.id,
      label: a.display_name,
    }));
  }, [grantingAdmins]);

  const handleConfirm = useCallback(() => {
    if (data.accessLevel && data.grantingAdmin && template?.id) {
      onConfirm?.(template.id, data.accessLevel, data.grantingAdmin);
    }
  }, [data, template, onConfirm]);

  return (
    <StyledRequestModal
      defaultLeftBtnText={t('Cancel')}
      heading={t('Request Access')}
      onConfirm={handleConfirm}
      {...others}
    >
      <AccessRequest
        grantingAdminOptions={grantingAdminOptions}
        employee_access={template?.employee_access}
        defaultAdmin={template?.owner}
        loading={grantingAdminsLoading}
        onChange={setData}
        labels={{
          view: t('View/Use'),
        }}
      />
    </StyledRequestModal>
  );
};
