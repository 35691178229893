import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DraggableStepCard from '../../DraggableStepCard';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  const { details } = step;
  let text;

  if (details.actionType === 'start') {
    text = t('Start Automations');
  } else if (details.actionType === 'pause') {
    text = t('Pause Automations');
  } else if (details.actionType === 'cancel') {
    text = t('Cancel Automations');
  }

  return `${text} '${
    details.automations.length
      ? details.automations.map((item) => item.label).join(', ')
      : t('[Deleted]')
  }'`;
};

export default function HttpRequestCard({ step, ...others }) {
  const { t } = useTranslation();
  return (
    <DraggableStepCard {...others}>{content({ step, t })}</DraggableStepCard>
  );
}

HttpRequestCard.propTypes = {
  step: PropTypes.object.isRequired,
};
