import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KizenTypography } from 'app/typography';
import Icon from 'components/Kizen/Icon';
import { NoDashlet } from './styles';
import { MODES } from '../hooks/useDashboardChildren';

const FirstDashletButton = ({
  triggerAddDashletProps,
  mode = MODES.DASHBOARD,
}) => {
  const { t } = useTranslation();

  return (
    <NoDashlet {...triggerAddDashletProps}>
      <KizenTypography as="span" type="small" weight="light">
        {mode === MODES.DASHBOARD
          ? t(
              'Please add your first dashlet below or using the "Add Dashlet" button to the top right'
            )
          : null}
        {mode === MODES.CHARTS
          ? t(
              'Please add your first chart below or using the "Add Chart" button to the top right'
            )
          : null}
        {mode === MODES.HOMEPAGES
          ? t(
              'Please add your first block below or with the "Add Block" button to the top right.'
            )
          : null}
      </KizenTypography>
      <Icon icon="add" size="3x" className="padded-plus" />
    </NoDashlet>
  );
};

FirstDashletButton.propTypes = {
  triggerAddDashletProps: PropTypes.object.isRequired,
};

export default FirstDashletButton;
