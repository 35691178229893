import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FieldService from 'services/FieldService';
import LongTextInlineField from 'components/Inputs/inline/LongText';
import TextInputInline from 'components/Inputs/inline/TextInput';

import PriceNumberInline from 'components/Inputs/inline/TextInput/presets/PriceNumber';

import WholeNumberInline from 'components/Inputs/inline/TextInput/presets/WholeNumber';

import DecimalNumberInline from 'components/Inputs/inline/TextInput/presets/DecimalNumber';
import CheckboxInlineField from 'components/Fields/InlineTableFieldInput/Checkbox';
import FilesInline from 'components/Inputs/inline/Files';
import DateInputInline, {
  DateInputInlineSelect,
} from 'components/Inputs/inline/DateInput';
import Select from 'components/Inputs/Select';
import MultiSelect from 'components/Inputs/MultiSelect';
import { ViewOnlyMultiSelect } from 'components/Inputs/MultiSelect/ViewOnlyMultiSelect';
import EmailAddressInline from 'components/Inputs/inline/TextInput/presets/EmailAddress';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { FIELD_TYPES } from 'utility/constants';
import DropdownInlineField from './Dropdown';
import RelationshipOneInline, {
  RelationshipOneSelect,
} from './RelationshipOne';
import RelationshipManyInline from './RelationshipMany';
import RelationshipOneInlineFullExperienceForm, {
  RelationshipOneSelectFullExperienceForm,
} from './RelationshipOneInlineFullExperienceForm';
import RatingInlineField from './Rating';
import DynamicTagsInlineField, { DynamicTagsSelect } from './DynamicTags';
import PhoneNumberInlineField from './PhoneNumber';
import MultiDropdownInlineField from './Checkboxes';
import TimezoneInline from './Timezone';
import {
  getCurrencySymbol,
  scrollFieldIntoView,
  isEmailStatusImmutable,
  addRelationToTeamSelector,
  isSummarizedValue,
} from '../helpers';
import {
  transformDateDownNonLegacy,
  transformDateUpNonLegacy,
  getFieldAccessPermissions,
  dummyAccessObject,
} from '../FieldInput/helpers';
import { StyledInfoWrapper } from './styles';
import { getDisplayNameTooltipProps } from 'components/Inputs/inline/WithTooltip';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import { getAdditionalFieldProps } from 'checks/fields';
import { SummarizedMultiselectInline } from 'components/Inputs/inline/SummarizedMultiselect';

export const INLINE_TABLE_DEFAULT_WIDTH = 200;
export const INLINE_TABLE_DEFAULT_WIDTH_IN_PIXELS = `${INLINE_TABLE_DEFAULT_WIDTH}px`;

export default function InlineTableFieldInput({
  field,
  object = dummyAccessObject,
  onSubmit,
  value: valueProp = null,
  isCustomObjects = false,
  objectId,
  serviceToUse,
  fetchUrl = null,
  handleUpdateFieldOption,
  handleUpdateTableRecords,
  menuLeftButton = <ClearSelectButton select={null} />,
  menuRightButton,
  placeholder,
  info,
  showDisplayNameTooltip,
  ...others
}) {
  // todo after 7195 merged:
  // As the *Cell files found inside components/Layout/BigTableLayout/InlineCells were refactored out of this file
  // We can refactor this code to use those those new files using a dictionary lookup rather than a bumch of if statements
  // Component = cellMap.find(field.filedType)
  // ...
  // }

  const { t } = useTranslation();

  const { fieldType } = field;
  const value =
    valueProp ?? FieldService.getFieldValue(object, field, isCustomObjects);
  const handleSubmit = (val) => {
    return onSubmit({
      [field.isDefault ? field.name : field.id]: val,
    });
  };

  const [editable, viewable] = useMemo(
    () => getFieldAccessPermissions(field, object),
    [field, object]
  );
  if (!viewable) return null;

  const base = (
    <x
      value={value}
      onSubmit={handleSubmit}
      // eslint-disable-next-line react/no-unknown-property
      object={object}
      // eslint-disable-next-line react/no-unknown-property
      field={field}
      // eslint-disable-next-line react/no-unknown-property
      onAutoFocus={scrollFieldIntoView} // TODO hoist up, out of this component
      {...others}
      {...getDisplayNameTooltipProps(showDisplayNameTooltip, field)}
    />
  );

  if (fieldType === FIELD_TYPES.Text.type) {
    return <TextInputInline readOnly={!editable} {...base.props} />;
  }
  if (fieldType === FIELD_TYPES.LongText.type) {
    return <LongTextInlineField readOnly={!editable} {...base.props} />;
  }
  if (fieldType === FIELD_TYPES.Email.type) {
    return (
      <EmailAddressInline
        readOnly={!editable}
        placeholder={FIELD_TYPES.Email.localizedPlaceholder(t)}
        {...base.props}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Integer.type) {
    return (
      <StyledInfoWrapper isInherited={!!info}>
        <WholeNumberInline
          {...base.props}
          onSubmit={handleSubmit}
          {...getAdditionalFieldProps(field, info, placeholder)}
          readOnly={base.props?.readOnly || !editable}
        />
        {info && editable && !base.props?.readOnly ? (
          <IconWithTooltip label={info} />
        ) : null}
      </StyledInfoWrapper>
    );
  }
  if (fieldType === FIELD_TYPES.Decimal.type) {
    return (
      <DecimalNumberInline
        readOnly={!editable}
        {...base.props}
        decimalOptions={field.decimalOptions}
        onSubmit={handleSubmit}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Money.type) {
    return (
      <PriceNumberInline
        currencySymbol={getCurrencySymbol(field)}
        readOnly={!editable}
        {...base.props}
        onSubmit={handleSubmit}
      />
    );
  }
  if (fieldType === FIELD_TYPES.PhoneNumber.type) {
    const {
      // instead fetch default country from redux - we have it in the field defaultRegion
      // chosenBusiness.phone_default_region === defaultRegion
      phonenumberOptions: { enableExtension, defaultRegion },
    } = field;
    return (
      <PhoneNumberInlineField
        readOnly={!editable}
        enableExtension={enableExtension}
        defaultCountry={defaultRegion}
        {...base.props}
        // TODO: remove it once we migrate our DB and update our API do not send object anymore.
        value={
          base.props.value?.formatted ??
          base.props.value?.formattedNationalNumber ??
          base.props.value?.formatted_national_number ??
          base.props.value?.e164 ??
          base.props.value ??
          ''
        }
      />
    );
  }
  if (fieldType === FIELD_TYPES.Date.type) {
    return (
      <DateInputInline
        {...base.props}
        readOnly={!editable}
        value={undefined} // Overriding base.props, value should be passed to DateInputInlineSelect
        onSubmit={(val) => {
          return base.props.onSubmit(transformDateUpNonLegacy(val));
        }}
      >
        <DateInputInlineSelect
          value={transformDateDownNonLegacy(base.props.value)}
          menuLeftButton={menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </DateInputInline>
    );
  }
  if (fieldType === FIELD_TYPES.DateTime.type) {
    return (
      <DateTimeInputInline
        {...base.props}
        readOnly={!editable}
        onSubmit={(val) => {
          handleSubmit(val && val.toISOString());
        }}
        dateMenuProps={{
          menuLeftButton: field.isRequired ? null : menuLeftButton,
          menuRightButton: <ApplySelectOverlayButton />,
        }}
        timeMenuProps={{
          sizing: 'shrink',
        }}
        showDateTooltip={field?.isDefault}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Rating.type) {
    return (
      <RatingInlineField field={field} readOnly={!editable} {...base.props}>
        <Select
          menuLeftButton={menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </RatingInlineField>
    );
  }
  if (
    fieldType === FIELD_TYPES.Choices.type ||
    fieldType === FIELD_TYPES.Dropdown.type ||
    fieldType === FIELD_TYPES.Radio.type ||
    fieldType === FIELD_TYPES.YesNoMaybe.type ||
    fieldType === FIELD_TYPES.Status.type
  ) {
    return (
      <DropdownInlineField
        field={field}
        readOnly={!editable || isEmailStatusImmutable(object, field)}
        {...base.props}
      >
        <Select
          isColored={fieldType === FIELD_TYPES.Status.type}
          placeholder={
            fieldType === FIELD_TYPES.Status.type &&
            FIELD_TYPES.Status.localizedPlaceholder(t)
          }
          menuLeftButton={field.isRequired ? null : menuLeftButton}
          menuRightButton={
            menuRightButton === undefined ? (
              <ApplySelectOverlayButton />
            ) : (
              menuRightButton
            )
          }
        />
      </DropdownInlineField>
    );
  }
  if (fieldType === FIELD_TYPES.Relationship.type) {
    if (isSummarizedValue(value)) {
      return (
        <SummarizedMultiselectInline
          field={field}
          entity={object}
          readOnly={!editable}
          handleUpdateTableRecords={handleUpdateTableRecords}
          viewOnlySelect={!editable}
          {...base.props}
        />
      );
    }
    if (FieldService.getAllowMultiple(field)) {
      return (
        <RelationshipManyInline
          field={field}
          readOnly={!editable}
          handleUpdateTableRecords={handleUpdateTableRecords}
          viewOnlySelect={!editable}
          {...base.props}
        />
      );
    }

    return (
      <RelationshipOneInlineFullExperienceForm
        field={field}
        readOnly={!editable}
        handleUpdateTableRecords={handleUpdateTableRecords}
        {...base.props}
      >
        <RelationshipOneSelectFullExperienceForm
          menuLeftButton={field.isRequired ? null : menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </RelationshipOneInlineFullExperienceForm>
    );
  }
  if (fieldType === FIELD_TYPES.TeamSelector.type) {
    const f = addRelationToTeamSelector(field);
    return (
      <RelationshipOneInline readOnly={!editable} {...base.props} field={f}>
        <RelationshipOneSelect
          menuLeftButton={f.isRequired ? null : menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </RelationshipOneInline>
    );
  }
  if (fieldType === FIELD_TYPES.DynamicTags.type) {
    return (
      <DynamicTagsInlineField
        readOnly={!editable}
        field={field}
        {...base.props}
      >
        <DynamicTagsSelect
          objectId={objectId}
          serviceToUse={serviceToUse}
          fetchUrl={fetchUrl}
          menuLeftButton={menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
          handleUpdateFieldOption={handleUpdateFieldOption}
        />
      </DynamicTagsInlineField>
    );
  }
  if (fieldType === FIELD_TYPES.Checkbox.type) {
    return (
      <CheckboxInlineField field={field} readOnly={!editable} {...base.props}>
        <Select menuRightButton={<ApplySelectOverlayButton />} />
      </CheckboxInlineField>
    );
  }
  if (fieldType === FIELD_TYPES.Checkboxes.type) {
    return (
      <MultiDropdownInlineField
        field={field}
        readOnly={!editable}
        viewOnlySelect={!editable}
        {...base.props}
      >
        {editable ? (
          <MultiSelect
            menuLeftButton={menuLeftButton}
            menuRightButton={<ApplySelectOverlayButton />}
          />
        ) : (
          <ViewOnlyMultiSelect value={value} />
        )}
      </MultiDropdownInlineField>
    );
  }
  if (fieldType === FIELD_TYPES.Files.type) {
    return (
      <FilesInline disabled={!editable} viewable={viewable} {...base.props} />
    );
  }
  if (fieldType === FIELD_TYPES.Timezone.type) {
    return (
      <TimezoneInline field={field} readOnly={!editable} {...base.props}>
        <Select
          placeholder={FIELD_TYPES.Timezone.localizedPlaceholder(t)}
          menuLeftButton={field.isRequired ? null : menuLeftButton}
          menuRightButton={
            menuRightButton === undefined ? (
              <ApplySelectOverlayButton />
            ) : (
              menuRightButton
            )
          }
        />
      </TimezoneInline>
    );
  }
  return (
    <TextEllipsisWithTooltip {...others}>
      {(typeof value === 'string' || typeof value === 'number') && value}
    </TextEllipsisWithTooltip>
  );
}

const configByFieldType = {
  text: { width: 200 },
  timezone: { width: 200 },
  longtext: { width: 250 },
  email: { width: 200 },
  integer: { width: 75, numeric: true },
  decimal: { width: 75, numeric: true },
  money: { width: 75, numeric: true },
  phonenumber: { width: 125, numeric: true },
  date: { width: 75, numeric: true },
  datetime: { width: 150, numeric: true },
  yesnomaybe: { width: 125 },
  rating: { width: 125, numeric: true },
  status: { width: 175 },
  choices: { width: 175 },
  dropdown: { width: 175 },
  selector: { width: 250 },
  team_selector: { width: 250 },
  relationship: { width: 250 },
  dynamictags: { width: 250 },
  checkbox: { width: 75 },
  checkboxes: { width: 200 },
  radio: { width: 175 },
  files: { width: 75, numeric: true },
  $defaults: { width: INLINE_TABLE_DEFAULT_WIDTH },
};

export const getColumnConfig = ({ fieldType }) => {
  return {
    ...(configByFieldType[fieldType] || configByFieldType.$defaults),
  };
};

export const getColumnWidth = (field) => {
  const { width } = getColumnConfig(field);
  return width;
};

export const getColumnNumeric = (field) => {
  const { numeric } = getColumnConfig(field);
  return numeric;
};

export const getColumnEditable = ({ fieldType, isReadOnly }) => {
  return fieldType in configByFieldType && !isReadOnly;
};

export const getColumnMinWidth = ({ fieldType }) => {
  const { width } = configByFieldType[fieldType];
  return width;
};
