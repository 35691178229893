import { customAlphabet } from 'nanoid';
import { useState } from 'react';
import { PageBuilderNode, PageBuilderTree } from '../types';
import { compose } from './compose';
import { isRootNode, isCellNode, isSectionNode, isRowNode } from './node-is';

type NodeType = PageBuilderNode['type']['resolvedName'];
type ReassignableNodeType = Exclude<
  NodeType,
  'Cell' | 'Root' | 'Row' | 'Section'
>;

const nanoid = customAlphabet('1234567890abcdef', 24);

export const getUniqueId = nanoid;

export const useUniqueId = () => {
  const [id] = useState(getUniqueId());
  return id;
};

/**
 * Returns a function accepting a string array that will return a new array with
 * all occurrences of `oldId` replaced with `newId`.
 * @param oldId
 * @param newId
 */
function getNewChildNodes(
  oldId: string,
  newId: string
): (nodes: string[]) => string[];
/**
 * Returns a new array of `nodes` where occurrences of `oldId` are replaced with `newId`.
 * @param oldId
 * @param newId
 * @param nodes - array of nodes
 */
function getNewChildNodes(
  oldId: string,
  newId: string,
  nodes: string[]
): string[];
function getNewChildNodes(oldId: string, newId: string, nodes?: string[]) {
  const fn = (n: string[]) => {
    return n.reduce<string[]>((acc, id) => {
      acc.push(id === oldId ? newId : id);
      return acc;
    }, []);
  };

  return Array.isArray(nodes) ? fn(nodes) : fn;
}

/**
 * Generate new ids for all content nodes of a given type.
 *
 * @remarks Cell nodes (the parents of content) can contain more than one child. This is
 * why the value of the `newParents` object is an array of functions - if the Cell with
 * reassigned ids is processed last we need to be able to update the ids of all its children.
 * Each child will register a function to update its id on its parent node if it cannot do so
 * immediately on the accumulated result.
 *
 * @param craftJson
 * @param nodeType
 */
export const reassignIds = (
  craftJson: PageBuilderTree,
  nodeType: ReassignableNodeType | ReassignableNodeType[]
) => {
  const shouldReassign = Array.isArray(nodeType) ? nodeType : [nodeType];
  const newParents: { [k: string]: ((x: string[]) => string[])[] } = {};

  return Object.entries(craftJson).reduce<PageBuilderTree>(
    (acc, [id, node]) => {
      if (isCellNode(node)) {
        acc[id] = { ...node };
        if (newParents[id]) {
          acc[id].nodes = compose(...newParents[id])(node.nodes);
        }
        return acc;
      }

      if (
        isRootNode(node) ||
        isSectionNode(node) ||
        isRowNode(node) ||
        !shouldReassign.includes(node.type.resolvedName)
      ) {
        acc[id] = { ...node };
        return acc;
      }

      const newId = getUniqueId();
      const newNodesFn = getNewChildNodes(id, newId);

      if (acc[node.parent]) {
        // already processed the parent cell node - update child ids directly
        acc[node.parent].nodes = newNodesFn(acc[node.parent].nodes);
      } else {
        // have not process the parent node - register the function to be ran later
        newParents[node.parent] = newParents[node.parent] || [];
        newParents[node.parent].push(newNodesFn);
      }

      acc[newId] = { ...node };
      return acc;
    },
    {}
  );
};
