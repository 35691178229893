import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '__components/Inputs/TextInput';
import Select from '__components/Inputs/Select';
import { VARIABLE_DATA_TYPES } from '__pages/SmartConnectors/constants';
import DecimalNumberTextInput from '__components/Inputs/TextInput/presets/DecimalNumber';
import PhoneNumberTextInput from '__components/Inputs/TextInput/presets/PhoneNumber';
import DateInput from '__components/Inputs/DateInput';
import DateTimeInput from '__components/Inputs/DateTimeInput';
import { formatDateFns2 } from '__app/timezone';
import { StyledDateWrapper } from './styles';
import { AdditionalVariable } from '__pages/SmartConnectors/types';

type ValueInputProps = {
  value: string;
  onChange: (value: string, isValid?: boolean) => void;
  error?: string[];
  setError: (prop: keyof AdditionalVariable, error: string[]) => void;
  validate?: any;
  dataType: string | null;
  inputFormat: string | null;
};

const booleanFormatLookup: Record<string, string> = {
  true_false: 'True/False',
};

const dateFormatLookup: Record<string, string> = {
  '%Y-%m-%d': 'yyyy-MM-dd',
  '%Y-%m-%d %H:%M': 'yyyy-MM-dd HH:mm',
};

const isUUIDValid = (uuid: string) => {
  return /^(([0-9a-f]{32})|([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))$/i.test(
    uuid
  );
};

const StringValueInput = ({
  value,
  onChange,
  error,
  validate,
  isUUID = false,
  setError,
}: Omit<ValueInputProps, 'dataType'> & { isUUID?: boolean }) => {
  const { t } = useTranslation();

  const [innerValue, setInnerValue] = useState<string>(value);

  const handleChange = (value: string) => {
    setInnerValue(value);
  };

  const handleBlur = () => {
    const trimmedValue = innerValue.trim();
    let isValid = true;
    if (trimmedValue.length === 0) {
      setError('value', [t('This field may not be blank.')]);
      isValid = false;
    } else if (isUUID && !isUUIDValid(trimmedValue)) {
      setError('value', [
        t(
          'A UUID value is expected in the format: XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX (dashes optional)'
        ),
      ]);
      isValid = false;
    }
    onChange(trimmedValue, isValid);
  };

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return (
    <TextInput
      value={innerValue}
      label={t('Value')}
      placeholder={
        isUUID ? 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX' : t('Enter Text')
      }
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      validate={validate}
    />
  );
};

const BooleanValueInput = ({
  value,
  onChange,
  error,
  validate,
  inputFormat,
}: Omit<ValueInputProps, 'dataType'>) => {
  const { t } = useTranslation();

  const handleChange = (option: { value: string }) => {
    onChange(option.value);
  };

  const options = useMemo(
    () =>
      inputFormat
        ? booleanFormatLookup[inputFormat]
            .split('/')
            .map((v, i) => ({ value: v, label: i === 0 ? t('Yes') : t('No') }))
        : [
            { value: 'True', label: t('Yes') },
            { value: 'False', label: t('No') },
          ],
    [t, inputFormat]
  );

  return (
    <Select
      value={value}
      label={t('Value')}
      placeholder={t('Select Value')}
      onChange={handleChange}
      options={options}
      error={error}
      validate={validate}
    />
  );
};

const NumberValueInput = ({
  value,
  onChange,
  error,
  setError,
  validate,
}: Omit<ValueInputProps, 'dataType'>) => {
  const { t } = useTranslation();

  const [innerValue, setInnerValue] = useState<string>(value);

  const handleBlur = () => {
    let isValid = true;
    if (isNaN(parseFloat(innerValue))) {
      setError('value', [t('This field may not be blank.')]);
      isValid = false;
    }
    onChange(innerValue, isValid);
  };

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return (
    <DecimalNumberTextInput
      value={innerValue}
      label={t('Value')}
      placeholder={t('0.00')}
      onChange={setInnerValue}
      onBlur={handleBlur}
      error={error}
      validate={validate}
    />
  );
};

const PhoneNumberValueInput = ({
  value,
  onChange,
  error,
  setError,
  validate,
}: Omit<ValueInputProps, 'dataType'>) => {
  const { t } = useTranslation();

  const handleChange = (
    value: string,
    e: SyntheticEvent,
    data: null | { errorMessage: string | null }
  ) => {
    let isValid = true;
    if (!value?.trim()) {
      setError('value', [t('This field may not be blank.')]);
      isValid = false;
    } else if (data?.errorMessage) {
      setError('value', [data.errorMessage]);
      isValid = false;
    }
    onChange(value, isValid);
  };

  return (
    <PhoneNumberTextInput
      label={t('Value')}
      value={value}
      placeholder={t('Enter Phone Number')}
      onChange={handleChange}
      error={!!error}
      validate={validate}
      enableExtension
    />
  );
};

const DateValueInput = ({
  value,
  onChange,
  error,
  validate,
  inputFormat,
  isDateTime = false,
}: Omit<ValueInputProps, 'dataType'> & { isDateTime?: boolean }) => {
  const { t } = useTranslation();

  const handleChange = (value: string) => {
    onChange(
      inputFormat
        ? formatDateFns2(new Date(value), dateFormatLookup[inputFormat])
        : value
    );
  };

  const Component = isDateTime ? DateTimeInput : DateInput;

  return (
    <StyledDateWrapper>
      <Component
        label={t('Value')}
        value={value ? new Date(value).toISOString() : value}
        placeholder={'MM/DD/YYYY'}
        onChange={handleChange}
        error={!!error}
        validate={validate}
        variant="outline"
      />
    </StyledDateWrapper>
  );
};

export const ValueInput = ({
  dataType = null,
  ...inputProps
}: ValueInputProps) => {
  switch (dataType) {
    case VARIABLE_DATA_TYPES.boolean:
      return <BooleanValueInput {...inputProps} />;
    case VARIABLE_DATA_TYPES.string:
      return <StringValueInput {...inputProps} />;
    case VARIABLE_DATA_TYPES.number:
      return <NumberValueInput {...inputProps} />;
    case VARIABLE_DATA_TYPES.uuid:
      return <StringValueInput {...inputProps} isUUID />;
    case VARIABLE_DATA_TYPES.phone_number:
      return <PhoneNumberValueInput {...inputProps} />;
    case VARIABLE_DATA_TYPES.date:
      return <DateValueInput {...inputProps} />;
    case VARIABLE_DATA_TYPES.datetime:
      return <DateValueInput {...inputProps} isDateTime />;
    default:
      return null;
  }
};
