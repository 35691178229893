import styled from '@emotion/styled';
import { grayScale, shadowLight } from 'app/colors';
import { pageSizingCss } from 'components/Layout/PageContentWidth';
import { gutters, breakpoints, layers } from 'app/spacing';
import { AltSubNavigation } from 'components/Navigation/SubNavigation';
import { css } from '@emotion/core';
//
// For Index.js
//

export const SCROLLED_SUB_HEADER_HEIGHT = 49;

export const StickyNavWrapper = styled.div`
  z-index: ${layers.content(
    0,
    2
  )}; // set so the rest of the page can scroll under the "stuck" nav wrapper

  position: sticky;
  top: ${SCROLLED_SUB_HEADER_HEIGHT}px;
  background-color: ${grayScale.white};
  padding: ${gutters.spacing(1)}px ${gutters.spacing(3)}px 0
    ${gutters.spacing(3)}px;

  @media (min-width: ${breakpoints.md + 1}px) {
    ${pageSizingCss}
    position: unset;
    padding: unset;
    background-color: unset;
  }
`;

// hmm
export const StickyNavMobileWrapper = styled.div`
  background: ${grayScale.white};
  border-bottom: 1px solid ${grayScale.mediumLight};
  margin-right: -${gutters.spacing(3)}px;
  margin-bottom: ${gutters.spacing(2)}px;
  margin-left: -${gutters.spacing(3)}px;

  ${({ routes }) => {
    if (routes && Object.keys(routes).length === 3) {
      return css`
        padding-right: ${gutters.spacing(6)}px;
        padding-left: ${gutters.spacing(6)}px;
      `;
    }
    if (routes && Object.keys(routes).length === 2) {
      return css`
        padding-right: 25%;
        padding-left: 25%;
      `;
    }
    return css`
      padding-right: ${gutters.spacing(3)}px;
      padding-left: ${gutters.spacing(3)}px;
    `;
  }}

  @media (min-width: ${breakpoints.md + 1}px) {
    margin: unset;
    padding-right: unset;
    padding-left: unset;
    border-bottom: unset;
    background-color: unset;
  }
`;

export const StyledSubNav = styled(AltSubNavigation)`
  ${shadowLight}
  .navbar {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: unset;
    width: 100%;
  }
`;
