import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import styled from '@emotion/styled';

import { colorsPrimary, grayScale, shadowLight } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Menu, { MenuList } from 'components/Kizen/Menu';
import CommentListItem from 'components/Kizen/List/CommentListItem';
import UUID from 'utility/UUID';
import { truncateCss, textCss } from 'app/typography';

const DefaultWidth = 200;

const StyledMenu = styled(Menu)`
  max-height: 200px;
`;

const EmptyCommentListItem = styled.div`
  line-height: 32px;
  ${truncateCss};
  text-align: center;
  padding: 0 12px;
  ${textCss()};
`;
const Loader = styled.div`
  ${shadowLight};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${DefaultWidth}px;
  height: 36px;
  margin: 1px;
  padding: 0 ${gutters.spacing(2)}px;
  background-color: ${grayScale.white};
`;

const LoadingText = styled(KizenTypography)`
  color: ${grayScale.dark};
  margin-left: ${gutters.spacing(2)}px;
`;

const CommentListHeader = styled(CommentListItem)`
  color: ${grayScale.mediumDark};
  font-weight: 700;
`;

const useMenuScrollTracker = (menuRef) => {
  const heightsRef = useRef([]);

  useEffect(() => {
    if (menuRef.current && menuRef.current?.children) {
      let heights = [];
      let top = 0;
      for (var kid of menuRef.current.children) {
        const height = kid.offsetHeight;
        const bottom = top + height;
        heights = [...heights, { height, top, bottom }];
        top = bottom;
      }
      heightsRef.current = heights;
    }
  }, [menuRef, menuRef.current?.children]);

  const trackUp = (index) => {
    if (heightsRef.current[index]) {
      const { height, top } = heightsRef.current[index];
      const topHorrizon = menuRef.current?.scrollTop + 30;
      if (top <= topHorrizon) {
        menuRef.current.scrollTop = menuRef.current.scrollTop - height;
      }
    }
  };

  const trackDown = (index) => {
    if (heightsRef.current[index]) {
      const { height, bottom } = heightsRef.current[index];
      const bottomHorrizon = menuRef.current?.offsetHeight - 30;
      if (bottom >= bottomHorrizon) {
        menuRef.current.scrollTop = menuRef.current.scrollTop + height;
      }
    }
  };
  return { trackUp, trackDown };
};

/**
 * @remarks The component will render an unordered list or a description list depending on the
 * structure of the `items` prop. In both cases, items must be an array of objects. If those
 * objects contain an `items` attribute the component will be a description list. Headers and
 * item text are both specified via the `label` attribute.
 *
 *  - ul --> items = `[{ label: 'Item 1' }, [{ label: 'Item 2' }]]`
 *  - dl --> items = `[{ label: 'Header 1', items: [{ label: 'Item 1 ' }]}]`
 */
export const SuggestionList = forwardRef(
  (
    {
      onSelect,
      items: itemsProp,
      topButton = false,
      isLoading = false,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const menuRef = useRef(null);
    const hasHeaders = Array.isArray(itemsProp?.[0]?.items);

    // Force the parent MenuList to rerender every time the list changes. Without this, the contents
    // don't update when used in a modal
    const [items, menuListKey] = useMemo(() => {
      const list = hasHeaders
        ? itemsProp.reduce((acc, section) => {
            acc.push({
              label: section.label,
              key: section.key,
              isHeader: true,
            });
            acc.push(
              ...section.items.map((item) => ({
                ...item,
                label: item.optionLabel || item.label,
                displayLabel: item.label,
              }))
            );
            return acc;
          }, [])
        : itemsProp;
      return [list, UUID.generate()];
    }, [itemsProp, hasHeaders]);

    const selectItem = (index) => {
      const item = items[index];

      // although not being highlighted, enter can still pressed on a header.
      if (item && !item.isHeader) {
        onSelect({ ...item, label: item.displayLabel || item.label });
      }
    };

    const menuScrollTracker = useMenuScrollTracker(menuRef);

    const upHandler = () => {
      if (selectedIndex > 0) {
        const index = selectedIndex - 1;
        setSelectedIndex(index);
        menuScrollTracker.trackUp(index);
      }
    };

    const downHandler = () => {
      if (selectedIndex < items.length - 1) {
        const index = selectedIndex + 1;
        setSelectedIndex(index);
        menuScrollTracker.trackDown(index);
      }
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    const onMouseOver = (index) => setSelectedIndex(index % items.length);

    useEffect(() => {
      setSelectedIndex(0);
      if (menuRef.current?.scrollTop) {
        menuRef.current.scrollTop = 0;
      }
    }, [items]);

    useImperativeHandle(ref, () => {
      return {
        onKeyDown: ({ event }) => {
          if (event.key === 'ArrowUp') {
            upHandler();
            return true;
          }

          if (event.key === 'ArrowDown') {
            downHandler();
            return true;
          }

          if (event.key === 'Enter') {
            items.length && enterHandler();
            return !!items.length || { close: true };
          }

          if (event.key === 'Tab') {
            return { close: true };
          }

          return false;
        },
      };
    });

    return (
      <StyledMenu {...rest}>
        {items.length || isLoading ? (
          <MenuList
            topButton={topButton}
            key={menuListKey}
            ref={menuRef}
            {...(hasHeaders && { as: 'dl' })}
          >
            {isLoading && (
              <Loader>
                <ClipLoader loading size={16} color={colorsPrimary.blue.dark} />
                <LoadingText>{t('Loading Options')}</LoadingText>
              </Loader>
            )}
            {!isLoading &&
              (items || []).map(
                ({ isHeader, label, key, relationship }, index) =>
                  isHeader ? (
                    <CommentListHeader as="dt" key={key || label}>
                      {label}
                    </CommentListHeader>
                  ) : (
                    <CommentListItem
                      {...(hasHeaders && { as: 'dd' })}
                      key={key || relationship}
                      focused={index === selectedIndex}
                      onClick={() => selectItem(index)}
                      onMouseOver={() => onMouseOver(index)}
                    >
                      {label}
                    </CommentListItem>
                  )
              )}
          </MenuList>
        ) : (
          <EmptyCommentListItem key={'no-options'} focused={false}>
            {t('No Options')}
          </EmptyCommentListItem>
        )}
      </StyledMenu>
    );
  }
);

export const SuggestionList200 = styled(SuggestionList)`
  width: ${DefaultWidth}px;
`;
