import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';

import ActionCell from 'components/Tables/ActionCell';
import ValidatedField from 'components/Kizen/ValidatedField';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { MESSAGE_TYPES } from 'utility/constants';
import { useTranslation } from 'react-i18next';

const paddingSize = {
  basic: '70px',
  small: '12px',
};

const columnSize = {
  spacer: '20px',
  subject: '390px',
  name: '389px',
  type: '50px',
  created: '150px',
  actions: '50px',
};

export const actions = {
  edit: 'edit',
  duplicate: 'duplicate',
  delete: 'delete',
};

const TableActionCell = (props) => {
  const { t } = useTranslation();
  return (
    <ActionCell
      menuType="absolute"
      options={[
        { value: actions.edit, label: t('Edit') },
        { value: actions.duplicate, label: t('Duplicate') },
        { value: actions.delete, label: t('Delete') },
      ]}
      {...props}
    />
  );
};

TableActionCell.defaultProps = {
  isMobile: false,
  data: {},
};

export const getTemplatesColumns = ({
  onUpdateTemplate,
  onSelectAction,
  t,
}) => [
  {
    id: 'spacer-1',
    headCell: <SizedCell width={columnSize.spacer} />,
    cell: <SizedCell width={columnSize.spacer} />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        label={t('Name')}
        width={columnSize.name}
        padding={paddingSize.basic}
      />
    ),
    cell: (
      <SizedCell
        width={columnSize.name}
        padding={paddingSize.basic}
        data-qa="Name"
      />
    ),
    format: function NameCell(value, template) {
      const handleSubmit = async (data) => {
        if (data !== value) {
          await onUpdateTemplate({
            id: template.id,
            name: data,
          });
        }
      };
      return (
        <ValidatedField
          type="link"
          value={value}
          to={`/library/templates/${template.id}/build`}
          onSubmit={handleSubmit}
        />
      );
    },
  },
  {
    id: 'subject',
    headCell: (
      <SortableHeadCell
        label={t('Subject Line')}
        width={columnSize.subject}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.subject} padding={paddingSize.basic} />,
    format: (value, template) => {
      const handleSubmit = async (data) => {
        if (data !== value) {
          await onUpdateTemplate({
            id: template.id,
            subject: data,
          });
        }
      };

      return template.type === 'email' ? (
        <ValidatedField type="text" value={value} onSubmit={handleSubmit} />
      ) : (
        <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'type',
    headCell: (
      <SortableHeadCell
        label={t('Type')}
        width={columnSize.numberActive}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.type} padding={paddingSize.basic} />,
    format: function activeCell(value) {
      return (
        <TextEllipsisWithTooltip>
          {MESSAGE_TYPES[value] ? MESSAGE_TYPES[value](t) : t('Other')}
        </TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'created',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Date Created')}
        width={columnSize.created}
        padding={paddingSize.small}
      />
    ),
    cell: <SizedCell width={columnSize.created} padding={paddingSize.small} />,
    format: (created) => (
      <DateTimeInputInline readOnly value={created} showDateTooltip />
    ),
  },
  {
    id: 'actions',
    headCell: (
      <SubActionHeadCell
        width={columnSize.actions}
        padding={columnSize.spacer}
      />
    ),
    cell: (
      <TableActionCell
        title={t('Edit Template')}
        width={columnSize.actions}
        padding={columnSize.spacer}
        onSelectAction={onSelectAction}
      />
    ),
  },
];
