import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as PropTypes from 'prop-types';

import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import useHeightMeasurement from 'hooks/useHeightMeasurement';
import useSyncScroll from 'react-use-sync-scroll';
import { useSyncSizes } from './Big/hooks';
import {
  TableScrollWrapper,
  Gradient,
  Wrapper,
  HorizontalScrollWrapper,
  VerticalScrollWrapper,
  Container,
  Child,
  ScrollbarContentWrapperVertical,
  ScrollbarContentWrapper,
  // v2
  TableScrollBucket,
  SafeGradient,
} from './ScrollContainerStyles';

const ScrollContainerContext = createContext({ containerRef: null });

export const useScrollContainerContext = () => {
  return useContext(ScrollContainerContext);
};

const TableScrollContainer = React.forwardRef(
  (
    {
      refresh,
      children,
      headHeight = 0,
      useExpandingScrollbar,
      headBorder,
      onChangeScrolled,
      onChangeScrolledVertical,
      onChangeHeight,
      fixedHeight,
      isTable,
      shadow = true,
      left = false,
      right = false,
      fitParentWidth = false,
      ...props
    },
    ref
  ) => {
    const [containerElement, setContainerElement] = useState();
    const scrollRef = useRef();
    const {
      ref: outerRef,
      plainRef: plainOuterRef,
      height: outerHeight,
    } = useHeightMeasurement();
    const { ref: innerRef, height: innerHeight } = useHeightMeasurement();
    const scrolled = useEndsOfScroll(plainOuterRef, [].concat(refresh || []));
    const refsRef = useRef([plainOuterRef, scrollRef]);
    useSyncScroll(refsRef, { vertical: true });

    const scrollbarContentWrapperRefVertical = useRef();
    const scrollbarContentWrapperRefHorizontal = useRef();
    const [tableWrapperRefFnHorizontal, tableWrapperRefHorizontal] =
      useSyncSizes(
        scrollbarContentWrapperRefHorizontal,
        '.BodyForSync',
        'width'
      );

    const [tableWrapperRefFnVertical, tableWrapperRefVertical] = useSyncSizes(
      scrollbarContentWrapperRefVertical,
      '.BodyForSync',
      'height',
      [],
      fixedHeight
    );

    const refCallback = useCallback(
      (node) => {
        setContainerElement(node);
        tableWrapperRefFnVertical(node);
        outerRef(node);
      },
      [tableWrapperRefFnVertical, outerRef]
    );

    const scrolledHorizontal = useEndsOfScroll(
      tableWrapperRefHorizontal,
      [].concat(refresh || [])
    );

    const scrolledVertical = useEndsOfScroll(
      tableWrapperRefHorizontal,
      [].concat(refresh || [])
    );

    useSyncScroll(
      useRef([tableWrapperRefHorizontal, scrollbarContentWrapperRefHorizontal]),
      {
        horizontal: true,
      }
    );
    useSyncScroll(
      useRef([tableWrapperRefVertical, scrollbarContentWrapperRefVertical]),
      {
        vertical: true,
      }
    );

    useEffect(() => {
      onChangeScrolled?.(scrolled);
    }, [onChangeScrolled, scrolled]);

    useEffect(() => {
      onChangeScrolledVertical?.(scrolledVertical);
    }, [scrolledVertical, onChangeScrolledVertical]);

    useEffect(() => {
      onChangeHeight?.(innerHeight, outerHeight);
    }, [onChangeHeight, innerHeight, outerHeight]);

    const topScrolled = scrolledVertical[0];

    if (useExpandingScrollbar) {
      return (
        <ScrollContainerContext.Provider value={{ containerElement }}>
          <TableScrollWrapper
            ref={refCallback}
            as={ScrollFadeContainer}
            scrolled={scrolled}
            useExpandingScrollbar
            isTable={isTable}
            {...props}
          >
            <Wrapper
              ref={innerRef}
              className="ScrollChildren"
              headerShadow={shadow ? !topScrolled : false}
            >
              {children}
              <Container
                ref={scrollRef}
                contentHeight={outerHeight - headHeight}
                headHeight={headHeight}
                border={headBorder}
              >
                <Child contentHeight={innerHeight} />
              </Container>
            </Wrapper>
          </TableScrollWrapper>
        </ScrollContainerContext.Provider>
      );
    }

    const [, rightScrolled, , leftScrolled] =
      scrolledHorizontal || scrolledVertical;

    return (
      <TableScrollBucket>
        <ScrollContainerContext.Provider value={{ containerElement }}>
          <HorizontalScrollWrapper fitParentWidth={fitParentWidth}>
            <VerticalScrollWrapper>
              <TableScrollWrapper
                as={ScrollFadeContainer}
                ref={(rf) => {
                  if (ref) {
                    ref.current = rf;
                  }
                  setContainerElement(rf);
                  tableWrapperRefFnVertical(rf);
                  tableWrapperRefFnHorizontal(rf);
                }}
                scrolled={scrolledHorizontal || scrolledVertical}
                data-scrollable="true"
                {...props}
              >
                <Wrapper
                  className="BodyForSync"
                  ref={innerRef}
                  headerShadow={shadow ? !topScrolled : false}
                >
                  {children}
                </Wrapper>
              </TableScrollWrapper>
              <ScrollbarContentWrapperVertical
                ref={scrollbarContentWrapperRefVertical}
                zIndex={3}
                {...props}
              >
                <div className="BodyForSync" />
              </ScrollbarContentWrapperVertical>
            </VerticalScrollWrapper>
            <ScrollbarContentWrapper
              zIndex={3}
              ref={scrollbarContentWrapperRefHorizontal}
            >
              <div className="BodyForSync" />
            </ScrollbarContentWrapper>
          </HorizontalScrollWrapper>
        </ScrollContainerContext.Provider>

        <SafeGradient active={left} position="left" show={!leftScrolled} />
        <SafeGradient active={right} position="right" show={!rightScrolled} />
      </TableScrollBucket>
    );
  }
);

TableScrollContainer.propTypes = {
  refresh: PropTypes.any,
  gradient: PropTypes.element,
};

TableScrollContainer.defaultProps = {
  refresh: null,
  gradient: <Gradient />,
};

export default TableScrollContainer;
