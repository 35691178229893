import styled from '@emotion/styled';
import { OutlineInputControl } from 'components/Inputs/InputControl';
import { appBackground } from 'app/colors';
import { KizenTypography } from 'app/typography';
import Button from 'components/Button';

export const StyledTemplateButton = styled(Button)`
  margin: 0;
  padding-left: 0;
  width: fit-content;
`;

export const StyledTemplateButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const StyledNotice = styled(KizenTypography)`
  padding: 10px;
  background-color: ${appBackground};
  margin-bottom: 15px;
`;

export const StyledOutlineInputControl = styled(OutlineInputControl)`
  & label {
    margin-bottom: 0;
  }
`;
