import { useTranslation } from 'react-i18next';
import {
  StyledRuleWarpper,
  StyledDiv,
  StyledInputControl,
  StyledRuleCard,
} from './styles';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import Select from '__components/Inputs/Select';
import Switch from '__components/Kizen/Switch';
import { FieldLike, MappingRule } from '__pages/SmartConnectors/types';
import IconButton from '__components/Kizen/IconButton';
import { IconSizing } from '__components/Kizen/Icon';
import Icon from '__components/Kizen/Icon';
import { grayScale } from '__app/colors';
import {
  OnChangeMappingRuleHandler,
  OnDeleteMappingRuleHandler,
  useMappingSettings,
} from './useMappingSettings';
import { UseCustomObjectFieldsResult } from './useCustomObjectFields';
import MultiSelect from '__components/Inputs/MultiSelect';
import { useEffect, useRef } from 'react';
import ClearSelectButton from '__components/Inputs/Select/ClearButton';

type MappingRowProps = {
  index: number;
  field?: FieldLike;
  rule: MappingRule;
  isCreateField?: boolean;
  onChange: OnChangeMappingRuleHandler;
  onDelete: OnDeleteMappingRuleHandler;
  fieldOptions: UseCustomObjectFieldsResult['fieldOptions'];
};

const buttonColor = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

export const MappingRow = ({
  index,
  field,
  rule,
  isCreateField = false,
  onChange,
  onDelete,
  fieldOptions,
}: MappingRowProps) => {
  const { t } = useTranslation();

  const { variables, can_create_field_options, conflict_resolution } = rule;

  const {
    hasError,
    ruleError,
    errorKey,
    validate,
    onDeleteRule,
    variableOptions,
    conflictResolutionOptions,
    onChangeField,
    onChangeVariable,
    onChangeVariables,
    onChangeResolution,
    onChangeCreateFieldOption,
    canCreateFieldOption,
    isMulty,
    value,
  } = useMappingSettings({
    rule,
    field,
    onChange,
    onDelete,
    index,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorKey) {
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [errorKey]);

  return (
    <StyledRuleWarpper
      ref={wrapperRef}
      data-ga-field-id={rule.field}
      data-qa-rule-index={index}
    >
      {isCreateField ? null : (
        <IconButton
          title={t('Delete')}
          sizing="dense"
          color={buttonColor}
          onClick={onDeleteRule}
        >
          <IconSizing size="15px">
            <Icon icon="trash" />
          </IconSizing>
        </IconButton>
      )}
      <StyledRuleCard hasError={hasError}>
        <StyledDiv width={303}>
          <Select
            label={t('Kizen Field Name (API Name)')}
            value={
              field && {
                value: field.id,
                label: `${field.displayName} (${field.name})`,
              }
            }
            onChange={onChangeField}
            placeholder={t('Choose Field')}
            options={fieldOptions}
            error={ruleError?.field}
            validate={errorKey === 'field' ? validate : undefined}
          />
        </StyledDiv>
        <StyledDiv width={318}>
          {isMulty ? (
            <MultiSelect
              label={t('Variable(s)')}
              value={value}
              onChange={onChangeVariables}
              placeholder={t('Choose Variable(s)')}
              options={variableOptions}
              error={ruleError?.variables}
              validate={errorKey === 'variables' ? validate : undefined}
              menuLeftButton={<ClearSelectButton />}
            />
          ) : (
            <Select
              label={t('Variable')}
              value={value}
              onChange={onChangeVariable}
              placeholder={t('Choose Variable')}
              options={variableOptions}
              error={ruleError?.variables}
              validate={errorKey === 'variables' ? validate : undefined}
            />
          )}
        </StyledDiv>
        <StyledDiv width={318}>
          {isCreateField ? (
            <StyledInputControl label={t('Conflict Resolution')}>
              <TextEllipsisWithTooltip>{t('N/A')}</TextEllipsisWithTooltip>
            </StyledInputControl>
          ) : (
            <>
              {variables?.length ? (
                <Select
                  label={t('Conflict Resolution')}
                  value={conflict_resolution}
                  onChange={onChangeResolution}
                  placeholder={t('Choose Resolution')}
                  options={conflictResolutionOptions}
                  disabled={variables.length > 1}
                  error={ruleError?.conflict_resolution}
                  validate={
                    errorKey === 'conflict_resolution' ? validate : undefined
                  }
                />
              ) : (
                <StyledInputControl label={t('Conflict Resolution')}>
                  <TextEllipsisWithTooltip>
                    {isMulty
                      ? t('Choose Variable(s) First')
                      : t('Choose Variable First')}
                  </TextEllipsisWithTooltip>
                </StyledInputControl>
              )}
            </>
          )}
        </StyledDiv>
        {isCreateField || !canCreateFieldOption ? null : (
          <StyledInputControl label={t('Create Field Option?')}>
            <Switch
              removeMargin
              checked={can_create_field_options}
              onChange={onChangeCreateFieldOption}
            />
          </StyledInputControl>
        )}
      </StyledRuleCard>
    </StyledRuleWarpper>
  );
};
