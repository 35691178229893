import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import css from '@emotion/css';

export const StyledModalStep = styled.div({
  marginTop: '15px',
  p: {},
  '.control p, label p': {},
});

export const StyledModalSection = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
  min-width: 0;
  &:last-child {
    margin-bottom: 0; // No extra padding between bottom of section and footer
  }
  ${({ leavePAlone }) =>
    !leavePAlone
      ? css`
          & p {
            margin-bottom: 10px;
          }
        `
      : null}
`;

export const StyledWizardBody = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  margin: ${gutters.standard} ${gutters.spacing(4)}px 0px;

  .modal-select,
  .modal-select > div {
    width: 100%;
    max-width: 100%;
  }
  .modal-select > div {
    margin-top: ${gutters.spacing(3)}px;
  }
  .modal-radio .entity.child {
    max-height: 100px;
  }
  .modal-frequency,
  .modal-frequency label,
  .modal-frequency .child {
    max-height: 35px;
  }
`;

const blue = colorsButton.blue.default;

export const StyledStepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(props) => (props.active ? 'white' : grayScale.mediumDark)};
  background-color: ${(props) => (props.active ? blue : grayScale.medium)};
  margin-right: 50px;
  font-size: 11px;
`;

export const StyledProgress = styled.div`
  margin-right: 50px;
  display: flex;
  justify-content: center;
`;

export const StyledProgressLine = styled.div`
  width: 1px;
  border-right: 1px solid ${(props) => (props.active ? blue : grayScale.medium)};
`;

export const StyledStepHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ hasMargin }) => (hasMargin ? 5 : 0)}px;
`;
