import { useParams } from 'react-router-dom';
import { getContacts, updatePageConfig } from 'store/contactsPage/actions';
import useReduxGroupConfig from 'components/DashboardGrid/hooks/useReduxGroupConfig';
import { useCallback } from 'react';

export const DATE_FILTER_ALL_TIME_INDEX = 6;

const useClientObjectChartGroupConfig = (
  dashboards = [],
  skipRouter = false,
  enabled = true
) => {
  const { chartId } = useParams();

  const getChartRoute = useCallback(
    (id) => `/clients/charts${id ? `/${id}` : ''}`,
    []
  );

  const {
    setCurrentDashboardId,
    refetchDashboard,
    processConfigAfterCreate,
    processConfigAfterUpdate,
    processConfigAfterDelete,
    loading,
    currentDashboard,
    dashboardQueryKey,
    currentDashboardPermission,
    config,
    setPersistentDashboardState,
  } = useReduxGroupConfig(
    {
      selector: (s) => s.contactPage,
      updatePageConfig,
      getData: getContacts,
      dashboards,
      getChartRoute,
      chartId,
      skipRouter,
    },
    enabled
  );

  return {
    setCurrentDashboardId,
    refetchDashboard,
    afterDelete: processConfigAfterDelete,
    afterUpdate: processConfigAfterUpdate,
    afterCreate: processConfigAfterCreate,
    loading,
    currentDashboard,
    dashboardQueryKey,
    currentDashboardPermission,
    persistentDashboardState: config?.dashboardState,
    setPersistentDashboardState,
    dashboards,
    contacts: true,
  };
};

export default useClientObjectChartGroupConfig;
