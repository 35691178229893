import { PAGE_CONFIGS, TOOLBAR_TEMPLATES } from 'queries/query-keys';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import TeamMemberService from 'services/TeamMemberService';
import {
  DEFAULT_INFO,
  DEFAULT_ITEM_IDS,
  DEFAULT_MY_ACCOUNT,
  DEFAULT_TOOLBAR_ITEMS,
} from '../constants';
import ToolbarService from 'services/ToolbarService';
import useToolbarAccessFiltering from './useToolbarAccessFiltering';
import { updatePageConfigQuery } from 'queries/models/teamMember';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useTranslation } from 'react-i18next';

export const maybeInjectPermanentItems = (elements, modified) => {
  const result = !modified
    ? DEFAULT_TOOLBAR_ITEMS.filter(
        // For now, the toolbar shouldn't be added to new configs by default.
        // This will allow it to still be added like normal, but not for
        // new configurations automatically.
        (item) => item.id !== DEFAULT_ITEM_IDS.HOMEPAGES
      )
    : [...elements];

  if (!result.find((e) => e.id === DEFAULT_ITEM_IDS.MY_ACCOUNT)) {
    result.push(DEFAULT_MY_ACCOUNT);
  }
  if (!result.find((e) => e.id === DEFAULT_ITEM_IDS.HELP)) {
    result.push(DEFAULT_INFO);
  }

  return result;
};

const useRightColumn = (existingId, callbacks) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [dirty, setDirty] = useState(false);
  const [templateDirty, setTemplateDirty] = useState(false);
  const dirtyRef = useRef(dirty);
  const existingIdRef = useRef(existingId);
  const user = useSelector((s) => s.authentication.user);
  const business = useSelector((s) => s.authentication.chosenBusiness);
  const qc = useQueryClient();

  const filterToolbar = useToolbarAccessFiltering(true);

  const { data, isLoading } = useQuery(
    [PAGE_CONFIGS.MY_TOOLBAR, user?.profile?.id, business?.id, 'builder'],
    async () => {
      const res = await TeamMemberService.getPageConfig('toolbar');
      return {
        ...res,
        elements: filterToolbar(
          maybeInjectPermanentItems(res?.elements ?? [], res?.modified)
        ),
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: !existingIdRef.current,
    }
  );

  const {
    data: existingData,
    refetch: refetchExistingData,
    isLoading: existingLoading,
  } = useQuery(
    [
      TOOLBAR_TEMPLATES.SINGLE,
      user?.profile?.id,
      business?.id,
      existingIdRef.current,
    ],
    async () => {
      const res = await ToolbarService.getTemplate(existingIdRef.current);

      return res;
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(existingIdRef.current),
      onError: callbacks?.onError,
    }
  );

  const [rightColumnState, setRightColumnState] = useState([]);

  useEffect(() => {
    if (!dirtyRef.current && !existingIdRef.current) {
      setRightColumnState(filterToolbar(data?.elements ?? []));
    }
  }, [data, filterToolbar]);

  useEffect(() => {
    if (!dirtyRef.current && Boolean(existingIdRef.current)) {
      setRightColumnState(
        filterToolbar(existingData?.configuration_json ?? [])
      );
    }
  }, [existingData, filterToolbar]);

  const clearLocalState = useCallback(() => {
    setDirty(false);
    if (existingIdRef.current) {
      qc.invalidateQueries([TOOLBAR_TEMPLATES.SINGLE]);
    } else {
      qc.invalidateQueries([PAGE_CONFIGS.MY_TOOLBAR]);
    }
  }, [qc]);

  useEffect(() => {
    dirtyRef.current = dirty;
  }, [dirty]);

  const rightColumnStateMap = useMemo(() => {
    return rightColumnState.reduce((acc, curr) => {
      const children =
        curr.children?.reduce((childAcc, childCurr) => {
          return {
            ...childAcc,
            [childCurr.id]: childCurr,
          };
        }, {}) ?? {};
      return {
        ...acc,
        [curr.id]: curr,
        ...children,
      };
    }, {});
  }, [rightColumnState]);

  const configMutation = useMutation(
    (result) => updatePageConfigQuery('toolbar', result),
    {
      onSuccess: () => {
        qc.invalidateQueries([PAGE_CONFIGS.MY_TOOLBAR]);
        callbacks?.onSave?.();
      },
    }
  );

  const templateMutation = useMutation(
    (payload) =>
      ToolbarService.updateTemplate(
        existingIdRef.current,
        payload.name,
        payload.config,
        payload.sharing
      ),
    {
      onSuccess: ({ data }) => {
        refetchExistingData();
        callbacks?.onSaveTemplate?.();
        showToast({
          message: t(
            "'{{name}}' toolbar template has been saved successfully.",
            data
          ),
          variant: toastVariant.SUCCESS,
        });
      },
    }
  );

  const commit = useCallback(() => {
    const filtered = rightColumnState.filter((o) => !o.ignoreMutation);
    configMutation.mutate({ elements: filtered, modified: true });
  }, [configMutation, rightColumnState]);

  const commitAsync = useCallback(() => {
    const filtered = rightColumnState.filter((o) => !o.ignoreMutation);
    return configMutation.mutateAsync({ elements: filtered, modified: true });
  }, [configMutation, rightColumnState]);

  const commitTemplate = useCallback(() => {
    const filtered = rightColumnState.filter((o) => !o.ignoreMutation);

    templateMutation.mutate({ config: filtered });
  }, [rightColumnState, templateMutation]);

  const commitTemplateAsync = useCallback(() => {
    const filtered = rightColumnState.filter((o) => !o.ignoreMutation);

    return templateMutation.mutateAsync({ config: filtered });
  }, [rightColumnState, templateMutation]);

  const reset = useCallback(() => {
    setDirty(true);
    setRightColumnState(filterToolbar(maybeInjectPermanentItems([])));
  }, [filterToolbar]);

  const setDirtyStates = useCallback((...args) => {
    setDirty(...args);
    setTemplateDirty(...args);
  }, []);

  return {
    items: rightColumnState,
    setItems: setRightColumnState,
    map: rightColumnStateMap,
    mutate: configMutation.mutate,
    pending: configMutation.isLoading,
    commit,
    commitAsync,
    loading: isLoading || existingLoading,
    reset,
    dirty,
    templateDirty,
    setDirty,
    setTemplateDirty,
    setDirtyStates,
    commitTemplate,
    commitTemplateAsync,
    clearLocalState,
  };
};

export default useRightColumn;
