const forFlow = (apiObject) => {
  const { htmlBody, htmlUrl, prettyDataPayload } = apiObject;
  return {
    ...apiObject,
    htmlBody: htmlBody ?? `<p>${prettyDataPayload}</p>`,
    htmlUrl: htmlUrl,
  };
};

const filterActionHttpRequest = (obj, filter) =>
  Object.entries(obj).reduce(
    (acc, [key, val]) =>
      filter.includes(key) && val === null ? acc : { ...acc, [key]: val },
    {}
  );

const forApi = (step) => {
  const {
    actionHttpRequest: orgActionHttpRequest = {},
    details: orgDetails = {},
  } = step;

  const actionHttpRequest = {
    ...filterActionHttpRequest(orgActionHttpRequest, ['htmlBody', 'htmlUrl']), // [todo] remove once we handle these
    ...orgDetails,
  };

  const details = {
    ...actionHttpRequest,
  };

  return { actionHttpRequest: actionHttpRequest || {}, details: details || {} };
};

export const httpRequestDTO = {
  forApi,
  forFlow,
};
