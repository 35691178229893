import AxiosService from './AxiosService';
import { camelToSnakeCaseKeys } from './helpers';

class SmartConnectorService {
  constructor() {
    if (!SmartConnectorService.instance) {
      SmartConnectorService.instance = this;
    }
    return SmartConnectorService.instance;
  }

  serviceEndpoint = 'smart-connectors';

  createSmartConnector = async (body, options) => {
    return AxiosService.post(this.serviceEndpoint, body, options);
  };

  getSmartConnector = async (id, options) => {
    return AxiosService.get(`${this.serviceEndpoint}/${id}`, options);
  };

  updateSmartConnector = async (id, body, options) => {
    return AxiosService.patch(`${this.serviceEndpoint}/${id}`, body, options);
  };

  getSmartConnectors = async (options) => {
    const response = await AxiosService.get(`${this.serviceEndpoint}`, {
      params: camelToSnakeCaseKeys(options),
    });
    return response.data;
  };

  duplicateSmartConnector = async (id, options = {}) => {
    return AxiosService.post(
      `${this.serviceEndpoint}/${id}/duplicate`,
      {},
      options
    );
  };

  getScriptTemplate = async (id, source_file_id, options) => {
    return AxiosService.post(
      `${this.serviceEndpoint}/${id}/get-file-template`,
      { source_file_id },
      options
    );
  };

  getSmartConnectorScripts = async (id, options) => {
    return AxiosService.get(
      `${this.serviceEndpoint}/${id}/sql-scripts`,
      options
    );
  };

  updateSmartConnectorScript = async (id, scriptId, user_script, options) => {
    return AxiosService.patch(
      `${this.serviceEndpoint}/${id}/sql-scripts/${scriptId}`,
      { user_script },
      options
    );
  };

  publishSmartConnectorScript = async (id, scriptId, options) => {
    return AxiosService.post(
      `${this.serviceEndpoint}/${id}/sql-scripts/${scriptId}/publish`,
      {},
      options
    );
  };

  downloadSmartConnectorScriptOutput = async (id, scriptId, type, options) => {
    return AxiosService.post(
      `${this.serviceEndpoint}/${id}/sql-scripts/${scriptId}/download-output-sample`,
      { type },
      options
    );
  };

  startSmartConnectorScriptRun = async (
    id,
    scriptId,
    { user_script, source_file_id, config_metadata },
    options
  ) => {
    return AxiosService.post(
      `${this.serviceEndpoint}/${id}/sql-scripts/${scriptId}/start`,
      { user_script, source_file_id, config_metadata },
      options
    );
  };

  generateExecutionVariables = async (id, options) => {
    return AxiosService.post(
      `${this.serviceEndpoint}/${id}/generate-execution-variables`,
      {},
      options
    );
  };

  getSmartConnectorMetaData = async (options) => {
    return AxiosService.get(`${this.serviceEndpoint}/metadata`, options);
  };

  deleteSmartConnector = async (id, options = {}) => {
    return AxiosService.delete(`${this.serviceEndpoint}/${id}`, options);
  };

  getSmartConnectorHistory = async (id, options = {}) => {
    const response = await AxiosService.get(
      `${this.serviceEndpoint}/${id}/events-history`,
      {
        params: options,
      }
    );
    return response.data;
  };

  startSmartConnectorFlow = async (
    id,
    { source_file_id, is_dry_run, disable_diff_check },
    options
  ) => {
    return AxiosService.post(
      `${this.serviceEndpoint}/${id}/start-connector-flow`,
      { source_file_id, is_dry_run, disable_diff_check },
      options
    );
  };
}

const instance = new SmartConnectorService();
Object.freeze(instance);

export default instance;
