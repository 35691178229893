import { forwardRef } from 'react';
import { HiddenWrapper } from './styles';

export const HiddenWidth = forwardRef(({ children, showContent }, ref) => {
  return showContent ? (
    <HiddenWrapper>
      <div ref={ref}>{children}</div>
    </HiddenWrapper>
  ) : (
    false
  );
});
