import { css } from '@emotion/core';
import styled from '@emotion/styled';

import ErrorCard from 'components/Kizen/ErrorCard';
import { colorsButton, grayScale } from 'app/colors';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  gutters,
  borderRadii,
  dashedBorderCss,
  resetButtonCss,
} from 'app/spacing';
import { IconSizing } from 'components/Kizen/Icon';

export const StyleErrorCard = styled(ErrorCard)`
  position: absolute;
  left: 0;
  top: 100%;
  max-width: 100%;
  width: 100%;
`;

export const StyledPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  span {
    color: ${grayScale.mediumDark};
  }

  & > span {
    margin-top: ${gutters.spacing(2, -1)}px;
    margin-bottom: ${gutters.spacing(1, -2)}px;
  }

  ${({ error }) =>
    error &&
    css`
      & > span {
        color: ${colorsButton.red.hover};
      }
    `}

  div {
    margin-top: ${gutters.spacing(2, 2)}px;

    & span:first-child {
      color: ${colorsButton.blue.default};

      &:hover {
        color: ${colorsButton.blue.hover};
      }
    }
  }
`;

export const ChooserDragAndDrop = styled.button`
  ${({ multiFileMode, isMobile }) =>
    multiFileMode
      ? css`
          ${resetButtonCss({ keepBorder: true })}
          display: flex;
          flex: 0 0 ${isMobile ? 105 : 164}px;
          flex-direction: column;
          min-width: 1px;
          width: 100%;
          border-radius: ${borderRadii.small};
          cursor: pointer;
          justify-content: center;
          align-items: center;
          position: relative;
        `
      : css`
          && {
            background-color: ${grayScale.white};
            padding: ${`${gutters.spacing(2, 3)}px ${
              gutters.standard
            } ${gutters.spacing(3, 3)}px `};
            font: inherit;
            cursor: pointer;
            outline: none;
          }
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: ${borderRadii.small};
          width: 100%;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${escape(
            grayScale.medium
          )}' stroke-width='2' stroke-dasharray='4%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        `};

  ${({ status, error, disabled, multiFileMode, focused }) =>
    status && multiFileMode
      ? css`
          border: solid 1px ${colorsButton.blue.hover};
        `
      : css`
          && {
            ${dashedBorderCss({
              color: grayScale.medium,
              dashLength: 4,
            })}
            ${error
              ? css`
                  ${dashedBorderCss({
                    color: colorsButton.red.hover,
                    dashLength: 4,
                  })}
                `
              : !disabled
                ? css`
                    &:hover {
                      ${(!multiFileMode || focused) &&
                      css`
                        && i {
                          color: ${grayScale.dark};
                        }
                      `}
                      ${dashedBorderCss({
                        color: colorsButton.blue.hover,
                        dashLength: 4,
                      })}
                    }
                  `
                : css`
                  cursor: default;
                  && {
                  ${dashedBorderCss({
                    color: grayScale.mediumLight,
                    dashLength: 4,
                  })}
              `}
          }
        `}
  ${({ focused }) =>
    focused &&
    css`
      && i {
        color: ${grayScale.dark};
      }
      && {
        ${dashedBorderCss({
          color: colorsButton.blue.hover,
          dashLength: 4,
        })}
      }
    `}
`;
export const LoadingBar = styled.div`
  height: 43px;
  ${({ multiFileMode }) =>
    !multiFileMode &&
    css`
      height: 42px;
    `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CloudIcon = styled(IconSizing)`
  margin-bottom: -${(50 - 35) / 2}px; // because <SizeIcon />> is square remove a little bottom margin
`;

export const SpacedTypography = styled.div`
  ${({ singleMode }) =>
    singleMode
      ? css`
          margin-top: ${gutters.spacing(2, 2)}px;
        `
      : css`
          margin-top: ${gutters.spacing(3, 4)}px;
        `}
`;

export const ErrorSpacedTypography = styled.div`
  margin-top: ${gutters.spacing(1, 2)}px;
`;
export const ErrorFileTypography = styled(TextEllipsisWithTooltip)`
  margin-top: ${gutters.spacing(0, 4)}px;
`;
