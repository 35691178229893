import { useCallback, useContext, useMemo } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { AREA_RESPONSES } from 'components/Wizards/RTDV/types';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import { StyledColumnWrapper } from '../styles';
import Loader from 'components/Kizen/Loader';
import TableBuilder from 'components/Wizards/shared/components/TableBuilder';
import { getDefaultName } from 'components/Wizards/utils';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import TitleField from 'components/Wizards/Dashlet/sections/DisplaySettings/fields/title';
import { useGroupedFields } from 'components/Wizards/shared/hooks/useGroupedFields';
import { ColumnsHeaderPreview } from 'components/Wizards/shared/components/TableBuilder/components/ColumnsHeaderPreview';
import useCustomObjectDetails from 'components/Wizards/shared/hooks/useCustomObjectDetails';
import {
  isEntityNameField,
  isFirstNameField,
  isLastNameField,
} from 'checks/fields';

const TableReportType = ({ objectId, contacts }) => {
  const { state, setState, model, entityName } = useContext(
    WizardStateContext.Context
  );
  const { t } = useTranslation();

  const { reportType, name, columns: stateColumns } = state;

  const { data, isLoading } = useCustomObjectDetails({
    objectId,
    contacts,
    enabled: reportType?.value === AREA_RESPONSES.TABLE,
  });

  const fieldsById = useMemo(() => {
    const fieldMap =
      data?.fields?.reduce((acc, field) => {
        acc[field.id] = field;
        return acc;
      }, {}) ?? {};

    if (contacts) {
      return {
        fullName: {
          id: 'fullName',
          displayName: t('Full Name'),
          name: 'full_name',
        },
        ...fieldMap,
      };
    }

    return fieldMap;
  }, [data, t, contacts]);

  const columns = useMemo(() => {
    return stateColumns?.filter((sc) => Boolean(fieldsById[sc.id])) ?? [];
  }, [stateColumns, fieldsById]);

  useMetadataKey('objectDetailsLoading', isLoading);

  const defaultName = useMemo(() => {
    return getDefaultName(
      {
        objectForCharts: data,
        ...state,
      },
      t
    );
  }, [state, t, data]);

  const onChangeName = useCallback(
    (value) => {
      return setState('name', value);
    },
    [setState]
  );

  const lockedFields = useMemo(() => {
    const nameField = data?.fields?.find(isEntityNameField);

    return [nameField?.id, 'fullName'];
  }, [data]);

  const groupedFields = useGroupedFields(
    data?.fields?.filter(
      // We don't want first_name or last_name for contacts because we inject the full_name field
      (f) => !isFirstNameField(f) && !isLastNameField(f)
    ),
    data?.fieldCategories
  );

  const fieldOptions = useMemo(() => {
    const options = groupedFields?.reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.options.map((o) => {
          return { label: o.label, id: o.value };
        }),
      ];
    }, []);

    if (contacts) {
      return [{ id: 'fullName', label: t('Full Name') }, ...options];
    }

    return options;
  }, [contacts, t, groupedFields]);

  const handleChangeColumns = useCallback(
    (value = []) => {
      setState(
        'columns',
        value.map((v) => {
          return {
            id: v.id,
            label: v.label,
            displayName: fieldsById[v.id]?.displayName,
          };
        })
      );
    },
    [setState, fieldsById]
  );

  if (reportType?.value !== AREA_RESPONSES.TABLE) {
    return null;
  }

  return (
    <StyledWizardField flex top={10}>
      <StyledColumnWrapper>
        <KizenTypography type="subheader">
          {t('Modify Columns Displayed')}
        </KizenTypography>
        <StyledWizardField top={15}>
          <KizenTypography>{t('Column Preview')}</KizenTypography>
        </StyledWizardField>
        <StyledWizardField flex top={12}>
          {isLoading ? (
            <Loader loading />
          ) : (
            <>
              <ColumnsHeaderPreview columns={columns} />
              <TableBuilder
                initialLeftColumnItems={fieldOptions}
                initialRightColumnItems={columns}
                onChange={handleChangeColumns}
                model={model}
                objectDetails={data}
                lockedFields={lockedFields}
                fieldsById={fieldsById}
                skipSorting
              />
            </>
          )}
        </StyledWizardField>
        <TitleField
          defaultName={defaultName}
          name={name}
          onChangeName={onChangeName}
          top={30}
          entityName={entityName}
          flex
          inputTop={17}
        />
      </StyledColumnWrapper>
    </StyledWizardField>
  );
};

export default TableReportType;
