import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import { TextEllipsis } from 'components/Kizen/Table';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { useTranslation } from 'react-i18next';

const ViewWrapper = styled.div`
  display: flex;
  && > button {
    display: none;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    svg {
      width: 12px;
      height: auto;
    }
  }
  &&:hover,
  tr:hover &&,
  .InlineHoverable:hover && {
    > button {
      display: inline-block;
    }
  }
`;

const ShortTextViewDisplay = React.forwardRef(
  (
    {
      type,
      to,
      value,
      icon,
      onClickEditButton,
      disabled,
      emptyPlaceholder,
      target,
      rel,
      onClick,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [tooltipProps, tooltip] = useTruncationTooltip();
    return (
      <ViewWrapper ref={ref} {...others}>
        <TextEllipsis
          type={type}
          onClick={onClick}
          to={to}
          color={disabled ? grayScale.mediumDark : undefined}
          disabled={disabled}
          target={target}
          rel={rel}
          {...tooltipProps}
        >
          {Array.isArray(value) && (value.length ? value : emptyPlaceholder)}
          {!Array.isArray(value) &&
            (value || value === 0 ? value : emptyPlaceholder)}
        </TextEllipsis>
        {tooltip}
        {icon && (
          <>
            <IconButton
              sizing="dense"
              title={t('Edit Cell')}
              color={
                disabled ? colorsButton.iconGray.default : colorsButton.iconGray
              }
              onClick={onClickEditButton}
            >
              <Icon icon={icon} />
            </IconButton>
          </>
        )}
      </ViewWrapper>
    );
  }
);

ShortTextViewDisplay.displayName = 'ShortTextViewDisplay';

ShortTextViewDisplay.propTypes = {
  type: PropTypes.string,
  to: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  icon: PropTypes.string,
  onClickEditButton: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  emptyPlaceholder: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

ShortTextViewDisplay.defaultProps = {
  value: null,
  type: undefined,
  to: undefined,
  icon: 'pen',
  disabled: false,
  emptyPlaceholder: '—',
  target: null,
  rel: null,
};

export default ShortTextViewDisplay;
