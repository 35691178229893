import React from 'react';

export default function ReorderIcon(props) {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2" cy="2" r="2" fill="currentColor" />
      <circle cx="2" cy="9.99983" r="2" fill="currentColor" />
      <circle cx="2" cy="18.0001" r="2" fill="currentColor" />
      <circle cx="9.00008" cy="2" r="2" fill="currentColor" />
      <circle cx="9.00008" cy="9.99983" r="2" fill="currentColor" />
      <circle cx="9.00008" cy="18.0001" r="2" fill="currentColor" />
    </svg>
  );
}
