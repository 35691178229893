import styled from '@emotion/styled';
import { Button } from '@kizen/kds/Button';
import React from 'react';
import { gutters } from 'app/spacing';
import Card from 'components/Card/Card';
import KizenTypography from 'app/kizentypo';
import Icon from 'components/Kizen/Icon';
import { useTooltip } from 'components/Kizen/Tooltip';
import { useTranslation } from 'react-i18next';
import { colorsButton } from 'app/colors';
import useExportFieldMetadata from 'hooks/useExportFieldMetadata';
import { CUSTOMIZE_FIELDS_STEP_KEY } from 'components/Wizards/CustomObject/constants';

const StyledKizenTypography = styled(KizenTypography)`
  line-height: 1;
  margin-top: ${gutters.spacing(0)}px;
  padding-bottom: ${gutters.spacing(2, 1)}px;
`;

const StyledCard = styled(Card)`
  padding: ${gutters.spacing(4)}px;
  padding-bottom: ${gutters.spacing(3)}px;
  margin-bottom: ${gutters.spacing(4)}px;
`;

const InfoIcon = styled(Icon)`
  & svg {
    width: 10px;
    height: 10px;
  }
  align: base-line;
  margin-left: 10px;
  padding-bottom: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightHeader = styled.div`
  display: flex;
  margin-right: 10px;
  margin-top: -11px;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

export default function BuilderCard({ children, label, info, model, ...rest }) {
  const { handleFieldExport } = useExportFieldMetadata({ model });
  const { t } = useTranslation();
  const [tooltipProps, tooltip] = useTooltip({
    label: info,
  });

  return (
    <StyledCard {...rest}>
      {label ? (
        <Header>
          <LeftHeader>
            <StyledKizenTypography as="h3" type="subheader">
              {label}
            </StyledKizenTypography>
            {info ? (
              <>
                <InfoIcon
                  {...tooltipProps}
                  icon="info-circle"
                  color={colorsButton.iconGray.hover}
                />
                {tooltip}
              </>
            ) : null}
          </LeftHeader>
          <RightHeader>
            {rest?.activeStep?.key === CUSTOMIZE_FIELDS_STEP_KEY ? (
              <Button
                className="pb-[10px]"
                color="secondary"
                onClick={handleFieldExport}
                variant="text"
              >
                {t('Export Field Metadata')}
              </Button>
            ) : null}
          </RightHeader>
        </Header>
      ) : null}

      {children}
    </StyledCard>
  );
}
