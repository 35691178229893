import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Switch from 'components/Kizen/Switch';
import { Text } from 'app/typography';
import { getChosenBusiness } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

const StyledSwitch = styled(Switch)`
  margin-left: 0;
`;

export default function PhoneNumberOptions({ updateField, formData }) {
  const { t } = useTranslation();
  const chosenBusiness = useSelector(getChosenBusiness);
  const enableExtensions = formData.phonenumberOptions
    ? formData.phonenumberOptions.enableExtension
    : false;
  useEffect(() => {
    updateField('phonenumberOptions', {
      defaultRegion: chosenBusiness.phone_default_region,
      enableExtension: enableExtensions,
    });
  }, [chosenBusiness, updateField, enableExtensions]);

  const handleChange = (patch) => {
    updateField('phonenumberOptions', {
      enableExtension: enableExtensions,
      defaultRegion: chosenBusiness.phone_default_region,
      ...patch,
    });
  };

  return (
    <Wrapper>
      <Text>{t('Enable Extensions')}</Text>
      <StyledSwitch
        value=""
        checked={enableExtensions}
        onChange={(event) =>
          handleChange({ enableExtension: event.currentTarget.checked })
        }
      />
    </Wrapper>
  );
}

PhoneNumberOptions.propTypes = {
  updateField: PropTypes.func.isRequired,
  formData: PropTypes.object,
};

PhoneNumberOptions.defaultProps = {
  formData: {},
};
