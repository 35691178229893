import { components } from 'react-select';

const CustomControl = ({ children, ...props }) => {
  const { selectProps } = props;
  const { setIsOpen } = selectProps;
  return (
    <div onClick={() => setIsOpen(true)}>
      <components.Control {...props}>{children}</components.Control>
    </div>
  );
};

export default CustomControl;
