import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsText, grayScale, colorsButton } from 'app/colors';
import { gutters, layers } from 'app/spacing';
import { labelCss, fontWeights } from 'app/typography';
import DateRangePicker from 'components/Kizen/DateRangePicker/DateRangePicker';
import EditableText from 'components/Kizen/EditableText';
import Icon from 'components/Kizen/Icon';
import Loader from 'components/Kizen/Loader';

export const HandleIcon = styled(Icon)`
  cursor: grab;
  position: absolute;
  left: 6px;
  top: 12px;
  height: 15px;
  z-index: 9;

  svg {
    color: ${grayScale.mediumDark};
    transition: color 0.1s;
    height: 15px;
  }

  &:hover svg {
    color: ${grayScale.dark};
  }
`;

export const DashletContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DashletHead = styled.div`
  display: flex;
  margin-left: 2px;
  column-gap: 15px;
  z-index: ${layers.content(1)};
  align-items: start;

  pointer-events: none;

  & * {
    pointer-events: all;
  }

  .title-container.static-content {
    pointer-events: none;
  }
`;

export const DashletBody = styled.div`
  flex: 1;
  ${({ bottom }) =>
    typeof bottom === 'number' ? `padding-bottom: ${bottom}px;` : ''};
  height: 0;
`;

export const DashletHeaderSection = styled.div`
  display: flex;
  flex-grow: 1;
  ${({ mobile }) => (mobile ? 'flex-direction: column;' : '')};
  margin-right: ${gutters.standard};
  overflow: hidden;

  & > div {
    text-overflow: unset;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-right: ${gutters.standard};
  overflow: hidden;

  & > div {
    text-overflow: unset;
  }

  & > div > input {
    text-overflow: ${({ showEllipsis }) =>
      showEllipsis ? 'ellipsis' : 'unset'};
    padding-bottom: 1px;
  }
`;

export const DashletHeaderWrapper = styled.div`
  display: flex;
  text-align: center;
  flex-grow: 1;
  align-items: center;
  ${({ mobile }) => (mobile ? 'display: none' : '')};
`;

export const DashletControls = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const EditableTitle = styled(EditableText)`
  margin-top: 3px;
  margin-left: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  input {
    background-image: none;
    ${labelCss('small')}
    font-weight: ${fontWeights.light};
    color: ${colorsText.dark};
    padding-left: 0;
  }

  input:disabled {
    background-color: transparent;
  }
`;

export const NoDashlet = styled.div({
  flex: 1,
  cursor: 'pointer',
  span: {
    textAlign: 'left',
    lineHeight: 1.25,
  },
  '.padded-plus': {
    position: 'absolute',
    left: '50%',
    marginLeft: '-1rem',
    top: '50%',
    marginTop: '-1rem',
    color: colorsButton.green.default,
  },
  '.padded-plus:hover': {
    color: colorsButton.green.hover,
  },
  '.padded-plus:active': {},
});

export const StyledLoader = styled(Loader)`
  position: absolute;
  background-color: ${grayScale.white};
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${layers.content(3)};
`;

export const DashletFilterShortcutButton = styled.div`
  position: relative;
  ${({ hasFilters }) =>
    hasFilters
      ? css`
          margin-right: ${gutters.spacing(2, 0.8)}px;

          && {
            opacity: 1;
          }
        `
      : css`
          margin-right: -${gutters.spacing(0, 2)}px;
        `}
  ${({ canEdit, hasFilters }) =>
    !canEdit
      ? css`
          pointer-events: none;
          ${!hasFilters
            ? css`
                &&& {
                  opacity: 0;
                }
              `
            : ''}
        `
      : ''}

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
          &:hover svg {
            color: ${grayScale.dark};
          }
        `
      : css`
          &:hover svg {
            color: ${grayScale.mediumDark};
          }
        `}
`;

export const DashletFilterCount = styled.div`
  position: absolute;
  right: -16px;
  top: -4px;
  width: 13px;
  height: 13px;
  background-color: ${grayScale.dark};
  color: white;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;

export const PillDateRangePicker = styled(DateRangePicker)`
  padding-top: 3px;

  & > button > i {
    display: none;
  }

  & > button > div {
    transform: none;
    background-color: ${grayScale.mediumDark};
  }

  ${({ dashletUnits }) =>
    dashletUnits <= 2
      ? css`
          & > button > div {
            max-width: 50px;
          }

          & > button > div > p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        `
      : ''}

  & > button:hover > div {
    background-color: ${grayScale.dark};
  }

  ${({ canEdit }) =>
    !canEdit &&
    css`
      pointer-events: none;
      & > button > div {
        background-color: ${grayScale.dark};
      }
    `}

  & > button > div {
    position: relative;
    left: unset;
    top: unset;
  }
`;

export const StyledPillContainer = styled.div`
  margin-bottom: -5px;
  margin-left: -5px;
  height: 20px;
  margin-top: 10px;

  .kds-button.kds-button-text {
    color: var(--color-font-placeholder);
  }

  .kds-button.kds-button-text:hover {
    color: var(--color-gray-800);
  }

  ${({ isAbsolute }) =>
    isAbsolute
      ? css`
          position: absolute;
          bottom: 19px;
          left: 15px;
        `
      : ''}
`;
