import React from 'react';
import * as PropTypes from 'prop-types';
import { Text, Link, UtilityLink, Label } from './typography';

// Exports KizenTypography and allows you to pass a type property
const KizenTypography = React.forwardRef((props, ref) => {
  const { type, ...others } = props;

  switch (type) {
    case 'hero':
      return <Text ref={ref} size="hero" {...others} />;

    case 'header':
      return <Text ref={ref} size="header" weight="light" {...others} />;

    case 'subheader':
      return <Text ref={ref} size="subheader" weight="bold" {...others} />;

    case 'subheader-light':
      return <Text ref={ref} size="subheader" weight="regular" {...others} />;

    case 'small':
      return <Text ref={ref} size="small" {...others} />;

    case 'micro':
      return <Text ref={ref} size="small" weight="bold" {...others} />;

    case 'label':
      return <Label ref={ref} {...others} />;

    case 'label-small':
      return <Label ref={ref} size="small" {...others} />;

    case 'label-micro':
      return <Label ref={ref} size="micro" weight="light" {...others} />;

    case 'link':
      return <Link ref={ref} {...others} />;

    case 'link-micro':
      return <Link ref={ref} size="small" weight="bold" {...others} />;

    case 'anchor':
    case 'link-mail':
    case 'external-link':
      return <UtilityLink ref={ref} {...others} />;

    default:
      return <Text ref={ref} {...others} />;
  }
});

KizenTypography.displayName = 'KizenTypography';

KizenTypography.propTypes = {
  type: PropTypes.string,
  textTransform: PropTypes.oneOf([
    'capitalize',
    'uppercase',
    'lowercase',
    'none',
    'full-width',
    'full-size-kana',
  ]),
};

KizenTypography.defaultProps = {
  type: null,
  textTransform: 'none',
};

export default KizenTypography;
