import { Button } from '@kizen/kds/Button';
import { Spacer } from '@kizen/kds/Spacer';
import { Tab, TabGroup } from '@kizen/kds/Tabs';
import { Panel, VerticalTile } from '@kizen/kds/Tile';
import { Typography } from '@kizen/kds/Typography';
import {
  NotesRichTextEditor,
  useNotesRichTextEditor,
  parseMentions,
} from '__components/Inputs/NotesRichTextEditor';
import Select from '__components/Inputs/Select';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import { toastVariant, useToast } from '__components/ToastProvider';
import { LabelPaneHeader } from 'pages/Common/styles/Profile';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivityService from 'services/ActivityService';
import LoggableActivityService from 'services/LoggableActivityService';
import { useQuery, useQueryClient } from 'react-query';
import { ACTIVITIES, CUSTOM_RECORDS } from 'queries/query-keys';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import useModal from 'components/Modals/useModal';
import {
  SCHEDULE_ACTIVITY_FAIL,
  SCHEDULE_ACTIVITY_SUCCESS,
} from 'pages/Common/Actions/activityHelpers';
import { ScheduleActivityModal } from '__components/Charts/ScheduledActivities/ScheduleActivityModal';
import {
  useWYSIWYGAIContext,
  WYSIWYGAIContext,
  TaskType,
} from 'components/WYSIWYG';
import { EmailTemplater } from '__components/Kizen/EmailTemplater';
import { useSelector } from 'react-redux';
import { getSendTextEmailTemplate } from '@kizen/page-builder/utils/build';
import {
  convertBuilderJson,
  createDefaultTextStyle,
  withRootNode,
} from '../../../../mjml/src';
import { useMjml2Html } from 'utility/email';
import { maybeTrackAIRequest } from 'utility/analytics';
import { templateForApi } from 'services/LibraryService';
import { getGenerativeAIEntitlement } from 'store/authentication/selectors';
import { getToastConfig as getEmailToastConfig } from './toastConfig';
import { useSendEmailMutation } from 'queries/models/client-messages';
import ClearSelectButton from '__components/Inputs/Select/ClearButton';
import ApplySelectButton from '__components/Inputs/Select/ApplyButton';
import { namedToOption } from 'services/helpers';
import { getOriginalError } from 'services/AxiosService';
import { withErrorBoundary } from './ErrorBoundary';
import { ConfirmationModal } from 'components/Charts/ScheduledActivities/ConfirmationModal';
import { MenuList as BaseMenuList } from '__components/Kizen/Menu';
import { useRecordLayoutLogActivityContext } from '../RecordLayoutLogActivityContext';
import { useRecordLayoutStartAutomationContext } from '../RecordLayoutStartAutomationContext';

const PANES = {
  ADD_NOTE: 'Add Note',
  SCHEDULE_ACTIVITY: 'Schedule Activity',
  LOG_ACTIVITY: 'Log Activity',
  START_AUTOMATION: 'Start Automations',
  EMAIL: 'Email',
};

const getSendEmailPayload = async (
  body: string,
  mjml2html: (mjml: any, options?: any) => any
) => {
  const craftJsContent = getSendTextEmailTemplate(body);
  const [newBody, { headAttributes, headChildren }] = convertBuilderJson(
    craftJsContent as any
  );
  const [textStyle, textAttr] = createDefaultTextStyle();
  const { html } = await mjml2html(
    withRootNode(newBody, {
      headAttributes: headAttributes.concat(textAttr),
      headChildren: headChildren.concat(textStyle),
    })
  );

  return { craftJsContent, htmlContent: html };
};

const getBody = (signature: string, activePane: string) => {
  if (activePane !== PANES.EMAIL || !signature || signature === '<p></p>') {
    return '';
  }
  return `
  <p></p>
  <p></p>
  <p></p>
  ${signature}
`;
};

const paneDict = {
  notes: PANES.ADD_NOTE,
  scheduleActivities: PANES.SCHEDULE_ACTIVITY,
  logActivities: PANES.LOG_ACTIVITY,
  startAutomation: PANES.START_AUTOMATION,
  email: PANES.EMAIL,
};

const AIConnectedTextEditorButton = ({
  disabled,
  text,
  onClick,
  qa,
}: {
  disabled: boolean;
  text: string;
  onClick(): void;
  qa?: Record<string, string>;
}) => {
  const { isLoading } = useWYSIWYGAIContext();

  return (
    <Button disabled={disabled || isLoading} onClick={onClick} qa={qa}>
      {text}
    </Button>
  );
};

const StartAutomationSelect = (props: {
  [k: string]: any;
  automationsWhitelist?: string[];
  customObjectId: string;
}) => {
  const { automationsWhitelist, customObjectId, ...rest } = props;

  const selectRef = useRef(null);

  const params = useMemo(
    () => ({
      active: true,
      custom_object_id: customObjectId,
      ids: automationsWhitelist,
    }),
    [customObjectId, automationsWhitelist]
  );

  const [typeaheadProps] = useSelectTypeaheadWithScroll({
    entity: Entities.AutomationsWithFilter,
    enabled: !!customObjectId,
    selectRef,
    params,
    alwaysOpen: true,

    fetch: undefined,
    config: undefined,
    objectToOption: undefined,
    onLoaded: undefined,
    onFocus: undefined,
    onMenuOpen: undefined,
    onMenuClose: undefined,
    fieldId: undefined,
    chosenValueIds: undefined,
    onResultsChange: undefined,
    filterOption: undefined,
    relatedObjectId: undefined,
    keepPreviousData: undefined,
    shouldResetOptions: undefined,
    shouldRefetchOnMount: undefined,
    skipErrorBoundary: undefined,
  });

  return (
    <Select
      ref={selectRef}
      {...rest}
      {...typeaheadProps}
      closeMenuOnSelect={false}
    />
  );
};

export interface ActionBlockProps {
  isClient?: boolean;
  id: string;
  customObjectId: string;
  refetch: () => void;
  customObject: any;
  customObjectModel: any;
  clientObject?: any;
  permissions?: any;
  metadata?: any;
  hasScheduledActivityPermission: boolean;
  hasEditPermission?: boolean;
  hasSingleSendMessagePermission: boolean;
  contact?: any;
  initialFieldValues?: Record<string, any>;
}

const getInitialPane = (canAddNotes: boolean, canSendEmail: boolean) => {
  if (canAddNotes) {
    return paneDict.notes;
  }

  if (canSendEmail) {
    return paneDict.email;
  }

  return null;
};

const Block = (props: ActionBlockProps) => {
  const {
    isClient = false,
    id: entityId,
    customObjectId,
    refetch,
    customObject,
    customObjectModel,
    clientObject,
    permissions,
    metadata,
    hasScheduledActivityPermission,
    hasEditPermission,
    hasSingleSendMessagePermission,
    contact,
  } = props;

  const canAddNotes = metadata?.addNotes !== false;
  const canLogActivities = metadata?.logActivities !== false;
  const canScheduleActivities =
    metadata?.scheduleActivities !== false && hasScheduledActivityPermission;
  const canSendEmails =
    metadata?.sendEmails !== false &&
    isClient &&
    hasSingleSendMessagePermission;
  const hasStartAutomationsPermission =
    permissions?.startAutomations && hasEditPermission;
  const canStartAutomations =
    hasStartAutomationsPermission && metadata?.startAutomations !== false;

  const logActivitiesFilter = useMemo(
    () => metadata?.logActivitiesList ?? [],
    [metadata]
  );

  const scheduledActivitiesFilter = useMemo(
    () => metadata?.scheduleActivitiesList ?? [],
    [metadata]
  );

  const signature = useSelector(
    (s: any) => s.authentication.teamMember.email_signature
  );
  const enableAI = useSelector(getGenerativeAIEntitlement);
  const businessId = useSelector(
    (s: any) => s.authentication.chosenBusiness.id
  );
  const { t } = useTranslation();
  const [activePane, setActivePane] = useState(() =>
    getInitialPane(canAddNotes, canSendEmails)
  );
  const [schedulingActivity, setSchedulingActivity] = useState(false);
  const [savingNote, setSavingNote] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const sendEmailMutation = useSendEmailMutation();
  const [selectedAutomation, setSelectedAutomation] = useState<any>(null);

  const queryClient = useQueryClient();

  const [showToast] = useToast();
  const activityInputRef = useRef(null);
  const emailToastConfig = getEmailToastConfig(t);
  const emailEditorRef = useRef<any>(null);
  const [inbox, setInbox] = useState<any>();
  const [subject, setSubject] = useState<any>();
  const [body, setBody] = useState(() => getBody(signature, PANES.EMAIL));
  const { notes, handleNoteChange, editorRef, clearNote } =
    useNotesRichTextEditor();

  const { openLogActivityModal } = useRecordLayoutLogActivityContext();

  const { startAutomation } = useRecordLayoutStartAutomationContext();

  const aggregateObjectId = customObjectId || clientObject?.id;

  const handleEmailChange = (field: string, val: any) => {
    if (field === 'inbox') {
      setInbox(val);
    }

    if (field === 'subject') {
      setSubject(val);
    }

    if (field === 'body') {
      setBody(val);
    }
  };

  const { data: activityListData } = useQuery(
    CUSTOM_RECORDS.LOGGABLE_ACTIVITY_LIST(aggregateObjectId),
    () => {
      return LoggableActivityService.getActivityFullList(
        {
          customObjectId: aggregateObjectId,
          ordering: 'name',
          detail: 'light',
        },
        true
      );
    },
    {
      enabled: Boolean(aggregateObjectId),
    }
  );

  const [logActivityVisible, setLogActivityVisible] = useState(false);

  const hasLogActivitiesFilter = logActivitiesFilter.length > 0;
  const hasScheduleActivitiesFilter = scheduledActivitiesFilter.length > 0;

  const [activityTypeaheadProps] = useSelectTypeaheadWithScroll({
    enabled: !hasLogActivitiesFilter,
    selectRef: activityInputRef,
    fieldId: aggregateObjectId,
    entity: Entities.ActivitiesLoggable,
    alwaysOpen: logActivityVisible,
    skipErrorBoundary: true,
  } as any);

  const onAIRequestStart = useCallback(
    (requestType: TaskType) => {
      maybeTrackAIRequest(
        window.location.pathname,
        entityId,
        businessId,
        requestType
      );
    },
    [entityId, businessId]
  );

  const handleNoteSave = async () => {
    if (!notes) return;
    setSavingNote(true);
    const payload = {
      relatedObject: {
        customObjectId: aggregateObjectId,
        entityId,
      },
      notes: `${notes}`,
      mentions: parseMentions(notes),
    };
    try {
      await ActivityService.addNote({
        ...payload,
      });
      refetch();
      showToast({
        message: t('This note has been saved.'),
        variant: toastVariant.SUCCESS,
      });
      clearNote();
    } catch (err) {
      showToast({
        message: t('This note could not be saved.'),
        variant: toastVariant.FAILURE,
      });
    } finally {
      setSavingNote(false);
    }
  };

  const handleScheduledError = (error: any) => {
    const orig = getOriginalError(error);
    if (orig?.errors?.activity_object_id) {
      showToast({
        message: t('Scheduled activity not found (completed or deleted).'),
        variant: toastVariant.FAILURE,
      });
    } else {
      showToast({
        message: orig?.message || SCHEDULE_ACTIVITY_FAIL(t),
        variant: toastVariant.FAILURE,
      });
    }
    setSchedulingActivity(false);
  };
  const handleScheduleActivity = async (activityInfo: any) => {
    setSchedulingActivity(true);
    await ActivityService.v2CreateScheduledActivities(activityInfo);
    showToast({
      message: SCHEDULE_ACTIVITY_SUCCESS(t),
      variant: toastVariant.SUCCESS,
    });
    refetch();
    queryClient.invalidateQueries(
      ACTIVITIES.CUSTOM_SCHEDULED(
        isClient ? clientObject?.id : customObjectId ?? customObjectModel?.id,
        entityId
      )
    );
    setSchedulingActivity(false);
  };

  const mjml2html = useMjml2Html();

  const clearEmailEditor = useCallback(() => {
    if (emailEditorRef?.current) {
      const { editor } = emailEditorRef.current;
      editor.commands.setContent(getBody(signature, PANES.EMAIL), true);
    }
  }, [emailEditorRef, signature]);

  const sendEmail = async () => {
    setEmailLoading(true);

    const { htmlContent } = await getSendEmailPayload(body, mjml2html);

    try {
      await sendEmailMutation.mutateAsync({
        ...templateForApi({
          subject,
          htmlContent,
          name: subject,
        }),
        externalInbox: inbox?.value,
        externalAccountId: inbox?.value,
        sendTo: entityId,
        type: 'integrated_inbox_email',
      });
      setInbox(undefined);
      setSubject(undefined);
      clearEmailEditor();
      showToast(emailToastConfig.email.success);
    } catch (err) {
      showToast(emailToastConfig.email.error);
    } finally {
      setEmailLoading(false);
    }
  };

  const [confirmSendModalProps, , { show: showConfirmSendEmailModal }] =
    useModal({
      handleSubmit: sendEmail,
    } as any);

  const handleEmailSend = async () => {
    if (contact?.email_status === 'unsubscribed') {
      (showConfirmSendEmailModal as any)();
    } else {
      await sendEmail();
    }
  };

  const [scheduleActivityModalProps, , scheduleActivityModal] = useModal({
    handleSubmit: handleScheduleActivity,
    handleError: handleScheduledError,
  } as any);

  const selectedScheduleActivities = useMemo(() => {
    if (!hasScheduleActivitiesFilter) {
      return activityListData ?? [];
    }

    return (
      activityListData?.filter((activity: any) =>
        scheduledActivitiesFilter.includes(activity.id)
      ) ?? []
    );
  }, [
    activityListData,
    scheduledActivitiesFilter,
    hasScheduleActivitiesFilter,
  ]);

  const selectedLogActivities = useMemo(() => {
    if (!hasLogActivitiesFilter) {
      return activityListData ?? [];
    }

    return (
      activityListData?.filter((activity: any) =>
        logActivitiesFilter.includes(activity.id)
      ) ?? []
    );
  }, [activityListData, logActivitiesFilter, hasLogActivitiesFilter]);

  const headElement = (
    <div className="w-full">
      <div className="kds flex flex-col gap-y-spacer-15 @md-tile/Tile:flex-row-reverse @md-tile/Tile:justify-between">
        <div className="kds flex items-center gap-spacer-15 flex-wrap justify-around">
          {canScheduleActivities ? (
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                (scheduleActivityModal as any)?.show?.();
              }}
              size="small"
              qa={{
                action: 'schedule-activity',
              }}
            >
              {t('Schedule Activity')}
            </Button>
          ) : null}
          {canLogActivities ? (
            <Button
              variant="text"
              color="secondary"
              actions
              size="small"
              qa={{
                action: 'log-activity',
              }}
              dropdown={({ trigger, isShowing, onChange, onHide }) => {
                if (isShowing !== logActivityVisible) {
                  setLogActivityVisible(isShowing);
                }

                return (
                  <SelectOverlay
                    show={isShowing}
                    target={trigger?.current}
                    onHide={onHide}
                  >
                    <Select
                      ref={activityInputRef}
                      onChange={onChange}
                      placeholder={t('Find Activity')}
                      {...(hasLogActivitiesFilter
                        ? {
                            options: selectedLogActivities.map(namedToOption),
                          }
                        : activityTypeaheadProps)}
                    />
                  </SelectOverlay>
                );
              }}
              onSelectAction={(payload: any, handleHide) => {
                const activity = activityListData?.find(
                  (act: any) => act.id === payload.value
                );
                handleHide();
                openLogActivityModal(activity || payload.value);
              }}
            >
              {t('Log Activity')}
            </Button>
          ) : null}
          {canStartAutomations ? (
            <Button
              variant="text"
              actions
              size="small"
              allowEmptyActions
              leftIconSettings={{
                className: 'hidden @md-tile/Tile:inline-flex',
              }}
              qa={{
                action: 'start-automation',
              }}
              leftIcon="action-refresh"
              color="primary"
              dropdown={({ trigger, isShowing, onChange, onHide }) => {
                if (!isShowing && selectedAutomation) {
                  setSelectedAutomation(null);
                }

                return (
                  <SelectOverlay
                    show={isShowing}
                    target={trigger?.current}
                    onHide={onHide}
                  >
                    <StartAutomationSelect
                      automationsWhitelist={metadata?.startAutomationsList}
                      customObjectId={aggregateObjectId}
                      onChange={(automation: any) =>
                        setSelectedAutomation(automation)
                      }
                      value={selectedAutomation}
                      placeholder={t('Find Automation')}
                      menuLeftButton={
                        <ClearSelectButton
                          onClick={() => {
                            setSelectedAutomation(null);
                            onHide();
                          }}
                        >
                          {t('Cancel')}
                        </ClearSelectButton>
                      }
                      menuRightButton={
                        <ApplySelectButton
                          onClick={() => onChange(selectedAutomation)}
                        >
                          {t('Start')}
                        </ApplySelectButton>
                      }
                      classNamePrefix="automation-select"
                    />
                  </SelectOverlay>
                );
              }}
              onSelectAction={({ value }: any, handleHide) => {
                startAutomation(value);
                handleHide();
              }}
            >
              {t('Start Automation')}
            </Button>
          ) : null}
        </div>
        <TabGroup expand>
          {canAddNotes ? (
            <Tab
              active={activePane === PANES.ADD_NOTE}
              onClick={() => setActivePane(PANES.ADD_NOTE)}
            >
              {t('Add Note')}
            </Tab>
          ) : null}
          {isClient && canSendEmails ? (
            <Tab
              active={activePane === PANES.EMAIL}
              onClick={() => setActivePane(PANES.EMAIL)}
            >
              {t('Send Email')}
            </Tab>
          ) : null}
        </TabGroup>
      </div>
    </div>
  );

  const disabledEmailing =
    !contact?.email || contact?.email_status === 'suppression_list';

  const bodyElement = (
    <div className="w-full">
      {canAddNotes || canSendEmails ? (
        <WYSIWYGAIContext
          businessId={businessId}
          onRequestStart={onAIRequestStart}
        >
          <div>
            <Spacer size={25} mode="horizontal" />
            {activePane === PANES.ADD_NOTE && (
              <div>
                <NotesRichTextEditor
                  enableAI={enableAI}
                  label={<LabelPaneHeader>{t('Enter Note')}</LabelPaneHeader>}
                  value={notes}
                  onChange={handleNoteChange}
                  ref={editorRef}
                />
                <Spacer size={25} mode="horizontal" />
                <AIConnectedTextEditorButton
                  text={t('Submit')}
                  disabled={!notes || savingNote}
                  onClick={handleNoteSave}
                  qa={{
                    action: 'submit-note',
                  }}
                />
              </div>
            )}
            {activePane === PANES.EMAIL && (
              <div>
                <EmailTemplater
                  ref={emailEditorRef}
                  enableAI={enableAI}
                  inbox={inbox}
                  subject={subject}
                  body={body}
                  onChange={handleEmailChange}
                  autofocus
                  disabled={disabledEmailing}
                />
                <Spacer size={25} mode="horizontal" />
                <AIConnectedTextEditorButton
                  text={t('Send')}
                  disabled={
                    emailLoading ||
                    !inbox ||
                    !subject ||
                    !body ||
                    disabledEmailing
                  }
                  onClick={handleEmailSend}
                />
                {disabledEmailing ? (
                  <>
                    <Spacer size={15} mode="horizontal" />
                    <Typography fontStyle="italic" color="gray-600">
                      {!contact?.email
                        ? t('Contact has no email address saved')
                        : t(
                            'Contact is on suppression list and cannot be emailed'
                          )}
                    </Typography>
                  </>
                ) : null}
              </div>
            )}
          </div>
        </WYSIWYGAIContext>
      ) : null}
      <ScheduleActivityModal
        currentEntity={customObject || contact}
        currentObject={customObjectModel || clientObject}
        {...scheduleActivityModalProps}
        disabled={schedulingActivity}
        scheduleActivityList={
          hasScheduleActivitiesFilter && activityListData?.length
            ? selectedScheduleActivities.map(namedToOption)
            : null
        }
      />
      <ConfirmationModal
        actionBtnColor={'green'}
        {...confirmSendModalProps}
        buttonText={t('Continue')}
        heading={t('Please Confirm This Action')}
      >
        {t(
          'This Contact is Unsubscribed from All. Do you want to send email anyway?'
        )}
      </ConfirmationModal>
    </div>
  );

  return (
    <VerticalTile
      middle={<Panel padding={[0, 20, 20, 20]}>{bodyElement}</Panel>}
      top={<Panel padding={[20, 20, 0, 20]}>{headElement}</Panel>}
      padding={0}
      gap={0}
    />
  );
};

export const Action = withErrorBoundary(Block);

export const MenuList = ({ children, innerRef, ...props }: any) => (
  <BaseMenuList ref={innerRef}>
    {Array.isArray(children)
      ? children.slice(0, props.selectProps?.maxOptions)
      : children}
  </BaseMenuList>
);
