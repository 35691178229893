import React from 'react';
import { StyledControl } from './styles';

const IconSelectorControl = ({
  children,
  innerProps,
  innerRef,
  selectProps,
  ...rest
}) => {
  return (
    <StyledControl ref={innerRef} {...innerProps} {...rest}>
      {children}
    </StyledControl>
  );
};

export default IconSelectorControl;
