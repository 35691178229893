import BasicModal from 'components/Modals/presets/BasicModal';
import { useTranslation } from 'react-i18next';
import { StyledText } from '../styles';

export const ActiveStateModal = ({ active, ...rest }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      heading={
        active ? t('Please Confirm Unpublish') : t('Please Confirm Publish')
      }
      buttonText={active ? t('Confirm Unpublish') : t('Confirm')}
      {...rest}
    >
      <StyledText>
        {active
          ? t(
              'This will hide the homepage from all team members who currently can view it.'
            )
          : t(
              'This will immediately show the homepage to all team members who are assigned to view it.'
            )}
      </StyledText>
    </BasicModal>
  );
};
