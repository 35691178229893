import { gutters, layers } from 'app/spacing';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { SettingsContentCard } from 'components/Card/Card';
import Subsection from 'components/Modals/Common/Subsection';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import KizenTypography from 'app/kizentypo';
import TablePagination from 'components/Tables/Big/TablePagination';
import { contentWidthCss } from 'components/Layout/PageContentWidth';
import BasicModal from 'components/Modals/presets/BasicModal';

export const AccessRequestsContentCard = styled(SettingsContentCard)`
  margin: ${gutters.spacing(3)}px auto ${gutters.spacing(4)}px;
  ${({ noScroll }) =>
    noScroll
      ? ''
      : css`
          padding: 0;
        `}
  ${({ shrink }) =>
    shrink
      ? ''
      : css`
          height: calc(100vh - 193px);
        `}
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

export const AccessRequestsSubsection = styled(Subsection)`
  ${({ noScroll }) =>
    noScroll
      ? ''
      : css`
          padding: 20px 20px 0 20px;
        `}

  && {
    margin-bottom: ${gutters.spacing(1)}px;
  }
  padding: 0 20px;
`;

export const AccessRequestsScrollContainer = styled(TableScrollContainer)`
  height: 100%;
`;

export const AccessRequestsTable = styled.table`
  height: 100%;
  width: 100%;
  tbody {
    tr {
      td {
        padding-left: 20px;
        > div {
          padding-right: ${({ noScroll }) => (noScroll ? 20 : 11)}px;
        }
      }
    }
  }
  margin-right: -8px;
  ${({ noScroll }) =>
    // Account for the scrollbar due to differences between the mine page and
    // access request page behavior
    noScroll
      ? css`
          margin-right: 4px;
        `
      : ''}
`;

export const AccessRequestsTHead = styled.thead`
  ${({ sticky }) =>
    sticky
      ? css`
          position: sticky;
        `
      : ''}

  top: 0;
  z-index: ${layers.content(1)};
  background: white;
  height: ${gutters.spacing(5, 2)}px;
`;

export const AccessRequestLink = styled(KizenTypography)`
  font-size: 1em;
`;

export const AccessRequestButtonContainer = styled.div`
  display: flex;
`;

export const AccessRequestListItem = styled.span`
  display: flex;
  max-width: ${({ width }) => `${width}px`};
  flex: 0 0 ${({ width }) => `${width}px`};
  justify-content: space-between;
  z-index: ${layers.content(0, 2)};
  ${({ align }) =>
    align ? `margin-${align === 'left' ? 'right' : 'left'}: auto;` : ''}

  & > div {
    margin-left: auto;
  }

  &:not(:last-child) {
    margin-right: 50px;
  }
`;

export const AccessRequestsTableHeader = styled.th`
  display: inline-block;
  font-size: 1.4em;
  margin-left: 40px;
  p {
    font-weight: bold;
  }
  &:last-child {
    float: right;
  }

  &:first-child {
    margin-left: 52px;
  }

  &:last-child {
    margin-left: 0;
    margin-right: ${({ noScroll }) => (noScroll ? 30 : 21)}px;
    text-align: right;
  }
`;

export const AccessRequestsDragAndDropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const AccessRequestsMobileContainer = styled.div`
  padding: ${gutters.standard};
  text-align: center;
`;

export const AccessRequestsPagination = styled(TablePagination)`
  ${contentWidthCss}
  margin: ${gutters.spacing(3)}px auto ${gutters.spacing(4)}px;
`;

export const StyledRequestModal = styled(BasicModal)`
  & .modal-body {
    overflow: visible;
  }
`;
