import React, { useState } from 'react';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import ToggleButton from 'components/Kizen/ToggleButton';
import Button from 'components/Button';
import AddButton from 'components/Button/AddButton';
import ButtonWithDropdown from 'components/Button/WithDropdown';
import { KizenTypography } from 'app/typography';
import Section from './helpers/Section';
import SubSection from './helpers/SubSection';
import Cols from './helpers/Cols';

const options = [
  { label: 'Secondary Action A', value: 'a' },
  { label: 'Secondary Action B', value: 'b' },
];

const useButtonWithDropdown = () => {
  const [state, setState] = useState(null);

  return [
    state,
    {
      onClick: () => setState({ type: 'click' }),
      onChange: ({ label }) => setState({ type: 'change', label }),
    },
  ];
};

const ButtonState = styled(({ type, label, ...others }) => {
  if (type === 'click') {
    return (
      <KizenTypography type="micro" {...others}>
        You clicked &quot;Peform Action&quot;
      </KizenTypography>
    );
  }

  if (type === 'change') {
    return (
      <KizenTypography type="micro" {...others}>
        You selected &quot;{label}&quot;
      </KizenTypography>
    );
  }

  return null;
})`
  margin-left: ${gutters.standard};
`;

const ButtonList = () => {
  const [buttonA, buttonAHandlers] = useButtonWithDropdown();
  const [buttonB, buttonBHandlers] = useButtonWithDropdown();
  const [buttonC, buttonCHandlers] = useButtonWithDropdown();
  const [toggleButtonA, setToggleButtonA] = useState('any');
  const [toggleButtonB, setToggleButtonB] = useState('one');
  const [toggleButtonC, setToggleButtonC] = useState('any');
  const [toggleButtonD, setToggleButtonD] = useState('any');
  const [toggleButtonTopA, setToggleButtonTopA] = useState('any');
  const [toggleButtonTopB, setToggleButtonTopB] = useState('one');
  const [toggleButtonTopC, setToggleButtonTopC] = useState('any');
  const [toggleButtonTopD, setToggleButtonTopD] = useState('any');
  const [toggleButtonFullA, setToggleButtonFullA] = useState('any');
  const [toggleButtonFullB, setToggleButtonFullB] = useState('one');
  const [toggleButtonFullC, setToggleButtonFullC] = useState('any');
  const [toggleButtonFullD, setToggleButtonFullD] = useState('any');

  return (
    <Section title="Buttons">
      <SubSection title="Solid">
        <Cols columns={4}>
          <div>
            <Button>Call to Action</Button>
          </div>
          <div>
            <Button color="blue">Call to Action</Button>
          </div>
          <div>
            <Button color="red">Call to Action</Button>
          </div>
          <div>
            <Button disabled>Call to Action</Button>
          </div>
        </Cols>
      </SubSection>
      <SubSection title="Solid w/ Dropdown">
        <Cols columns={4}>
          <div>
            <ButtonWithDropdown options={options} {...buttonAHandlers}>
              Perform Action
            </ButtonWithDropdown>
            <ButtonState {...buttonA} />
          </div>
          <div>
            <ButtonWithDropdown
              color="blue"
              options={options}
              {...buttonBHandlers}
            >
              Perform Action
            </ButtonWithDropdown>
            <ButtonState {...buttonB} />
          </div>
          <div>
            <ButtonWithDropdown
              color="red"
              options={options}
              {...buttonCHandlers}
            >
              Perform Action
            </ButtonWithDropdown>
            <ButtonState {...buttonC} />
          </div>
          <div>
            <ButtonWithDropdown disabled options={options}>
              Perform Action
            </ButtonWithDropdown>
          </div>
        </Cols>
      </SubSection>
      <SubSection title="Outline">
        <Cols columns={4}>
          <div>
            <Button variant="outline">Call to Action</Button>
          </div>
          <div>
            <Button variant="outline" color="blue">
              Call to Action
            </Button>
          </div>
          <div>
            <Button variant="outline" color="red">
              Call to Action
            </Button>
          </div>
          <div>
            <Button variant="outline" disabled>
              Call to Action
            </Button>
          </div>
        </Cols>
      </SubSection>
      <SubSection title="Text">
        <Cols columns={4}>
          <div>
            <Button variant="text">Call to Action</Button>
          </div>
          <div>
            <Button variant="text" color="blue">
              Call to Action
            </Button>
          </div>
          <div>
            <Button variant="text" color="red">
              Call to Action
            </Button>
          </div>
          <div>
            <Button variant="text" disabled>
              Call to Action
            </Button>
          </div>
        </Cols>
      </SubSection>
      <SubSection title="Toggle">
        <Cols columns={4}>
          <div>
            <ToggleButton
              color="green"
              value={toggleButtonA}
              onChange={({ value }) => setToggleButtonA(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              value={toggleButtonB}
              onChange={({ value }) => setToggleButtonB(value)}
              options={[
                { label: 'One', value: 'one' },
                { label: 'Two', value: 'two' },
                { label: 'Three', value: 'three' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              color="red"
              value={toggleButtonC}
              onChange={({ value }) => setToggleButtonC(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              value={toggleButtonD}
              disabled
              onChange={({ value }) => setToggleButtonD(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
        </Cols>
      </SubSection>
      <SubSection title="Toggle w/ Top Rounded Corners">
        <Cols columns={4}>
          <div>
            <ToggleButton
              color="green"
              onlyTopRadius
              value={toggleButtonTopA}
              onChange={({ value }) => setToggleButtonTopA(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              onlyTopRadius
              value={toggleButtonTopB}
              onChange={({ value }) => setToggleButtonTopB(value)}
              options={[
                { label: 'One', value: 'one' },
                { label: 'Two', value: 'two' },
                { label: 'Three', value: 'three' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              onlyTopRadius
              color="red"
              value={toggleButtonTopC}
              onChange={({ value }) => setToggleButtonTopC(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              onlyTopRadius
              value={toggleButtonTopD}
              disabled
              onChange={({ value }) => setToggleButtonTopD(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
        </Cols>
      </SubSection>
      <SubSection title="Toggle Full Width">
        <Cols columns={4}>
          <div>
            <ToggleButton
              color="green"
              sizing={null}
              value={toggleButtonFullA}
              onChange={({ value }) => setToggleButtonFullA(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              sizing={null}
              value={toggleButtonFullB}
              onChange={({ value }) => setToggleButtonFullB(value)}
              options={[
                { label: 'One', value: 'one' },
                { label: 'Two', value: 'two' },
                { label: 'Three', value: 'three' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              sizing={null}
              onlyTopRadius
              color="red"
              value={toggleButtonFullC}
              onChange={({ value }) => setToggleButtonFullC(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
          <div>
            <ToggleButton
              sizing={null}
              onlyTopRadius
              value={toggleButtonFullD}
              disabled
              onChange={({ value }) => setToggleButtonFullD(value)}
              options={[
                { label: 'Any', value: 'any' },
                { label: 'All', value: 'all' },
              ]}
            />
          </div>
        </Cols>
      </SubSection>
      <SubSection title="Add">
        <Cols columns={4}>
          <div>
            <AddButton>Add</AddButton>
          </div>
          <div>
            <AddButton color="blue">Add</AddButton>
          </div>
          <div>
            <AddButton color="red">Add</AddButton>
          </div>
          <div>
            <AddButton disabled>Add</AddButton>
          </div>
        </Cols>
      </SubSection>
    </Section>
  );
};

export default ButtonList;
