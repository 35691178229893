import styled from '@emotion/styled';
import { TextSpan } from 'app/typography';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

export const StageName = styled(TextEllipsisWithTooltip)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ClampedContainer = styled(TextSpan)`
  text-align: right;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: keep-all;
  width: 100%;
`;
