import AccessRequestTableOthers from 'components/AccessRequests/Tables/Others';
import { useTranslation } from 'react-i18next';
import { getColumns } from '../../util';

const AccessRequestsChartGroupsPage = (props) => {
  const { t } = useTranslation();

  return (
    <AccessRequestTableOthers
      {...props}
      getColumns={getColumns}
      entitySingular={t('Chart Group')}
      entityPlural={t('Chart Groups')}
    />
  );
};

export default AccessRequestsChartGroupsPage;
