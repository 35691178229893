import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import Button from 'components/Button';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 500px;
  flex-shrink: 0;

  ${({ invert }) =>
    invert &&
    css`
      width: 100%;
      max-width: 100%;
      height: 50%;
    `}

  & thead > tr {
    height: 50px;
    margin-top: -2px;
  }

  & thead > tr > th > div > span {
    margin-top: 0px;
  }
`;

export const ControlHeader = styled.div`
  display: flex;
  margin-top: 9px;
  align-items: flex-end;
  margin-bottom: 2px;

  ${({ mobile }) =>
    mobile
      ? css`
          padding: 5px 10px 0 10px;
          flex-direction: column;
          row-gap: 10px;

          & > div,
          & > button {
            width: 100%;
          }
        `
      : ''}
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
  margin-bottom: 0;
`;

export const NameCell = styled.div`
  cursor: pointer;
  p {
    color: ${colorsButton.blue.default};
  }

  &:hover p {
    color: ${colorsButton.blue.hover};
  }
`;

export const TableLoader = styled.tr`
  width: 90px;
`;

export const LoaderContainer = styled.tr`
  & > td > div {
    padding-top: 0 !important;
  }
`;
