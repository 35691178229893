import BasicModal from 'components/Modals/presets/BasicModal';
import { useTranslation } from 'react-i18next';
import { StyledText } from '../styles';

export const DeleteConfirmationModal = ({ active, ...rest }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      heading={t('Please Confirm Deletion')}
      buttonText={t('Confirm Delete')}
      {...rest}
    >
      <StyledText>
        {t(
          'This will delete the block and affect all team members who currently can view it.'
        )}
      </StyledText>
    </BasicModal>
  );
};
