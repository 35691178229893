import { createAction } from '@reduxjs/toolkit';
import { contactPageSlice } from './contacts.redux';

// contacts
export const getContacts = createAction(
  'contactsPage/GET_CONTACTS',
  function prepare({
    updatePageConfig = true,
    refreshPage = false,
    triggerViewLoading = false,
    onlyUpdateCounts = false,
    updatePageConfigKey,
  } = {}) {
    return {
      payload: {
        updatePageConfig,
        refreshPage,
        triggerViewLoading,
        onlyUpdateCounts,
        updatePageConfigKey,
      },
    };
  }
);

// createOrUpdateGroup
export const createOrUpdateGroup = createAction<
  any,
  'contactsPage/CREATE_OR_UPDATE_GROUP'
>('contactsPage/CREATE_OR_UPDATE_GROUP');

export const createOrUpdateGroupFail = createAction<
  any,
  'contactsPage/CREATE_OR_UPDATE_GROUP_FAIL'
>('contactsPage/CREATE_OR_UPDATE_GROUP_FAIL');

// removeGroup
export const removeGroup = createAction<any, 'contactsPage/REMOVE_GROUP'>(
  'contactsPage/REMOVE_GROUP'
);

export const changeGroup = createAction<any, 'contactsPage/CHANGE_GROUP'>(
  'contactsPage/CHANGE_GROUP'
);

export const removeGroupFail = createAction<
  any,
  'contactsPage/REMOVE_GROUP_FAIL'
>('contactsPage/REMOVE_GROUP_FAIL');

// getCounts
export const getCounts = createAction<any, 'contactsPage/GET_COUNTS'>(
  'contactsPage/GET_COUNTS'
);

export const getCountsFail = createAction<any, 'contactsPage/GET_COUNTS_FAIL'>(
  'contactsPage/GET_COUNTS_FAIL'
);

// setPageConfig
export const setPageConfigSuccess = createAction<
  any,
  'contactsPage/SET_PAGE_CONFIG_SUCCESS'
>('contactsPage/SET_PAGE_CONFIG_SUCCESS');

export const setPageConfigFail = createAction<
  any,
  'contactsPage/SET_PAGE_CONFIG_FAIL'
>('contactsPage/SET_PAGE_CONFIG_FAIL');

export const {
  archiveContactSuccess,
  buildPage,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  clearFilter,
  clearFilterErrors,
  clearFilterSearch,
  getContactsSuccess,
  getContactsStart,
  getContactsFail,
  getCountsSuccess,
  setCountsNeedToLoad,
  setSavedFilter,
  updatePageConfig,
  updatePageConfigBySearch,
  updateContact,
  addNewContact,
  setCheckedContact,
  setCheckedSelection,
  setPageFetching,
  createOrUpdateGroupSuccess,
  removeGroupSuccess,
  resetFilterGroupSuccess,
  updateContactsPageColumnWidth,
  setPageConfig,
  setFilterName,
  setNumberOfFilters,
  setGroup,
  setIsAllContactsGroup,
  getContactsFinish,
  updateClientObject,
} = contactPageSlice.actions;
