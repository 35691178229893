import { colorsPrimary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { CardHeader } from '../components/CardHeader';
import { initiatorTypes, eventTypes } from '../../constants';
import {
  defaultEmployeeString,
  defaultSmartConnectorString,
  triggerData,
} from '../helpers';
import { capitalizeAllWords } from 'components/helpers';
const getActionText = (type, t) => {
  if (type === eventTypes.automationStarted) {
    return t('automation started by');
  }
  if (type === eventTypes.automationPaused) {
    return t('automation paused by');
  }
  if (type === eventTypes.automationCanceled) {
    return t('automation canceled by');
  }
  if (type === eventTypes.automationResumed) {
    return t('automation resumed by');
  }
};

const cardHeaderConfig = (t, event) => {
  const prefix = getActionText(event.typeName, t);
  return {
    [initiatorTypes.employee]: {
      prefix,
      creationType: defaultEmployeeString,
    },
    [initiatorTypes.employee_api_request]: {
      prefix,
      creationType: defaultEmployeeString,
      postfix: t('via API'),
    },
    [initiatorTypes.automation]: {
      prefix,
      creationType: (initiator) => `${initiator.name} ${t('automation')}`,
    },
    [initiatorTypes.smart_connector]: {
      prefix,
      creationType: defaultSmartConnectorString,
    },
    [initiatorTypes.trigger]: {
      prefix,
      creationType: (initiator, event) => {
        if (!Object.keys(triggerData).includes(event.data.triggerType))
          return `${t('Trigger')} (${capitalizeAllWords(
            initiator.triggerTypeDisplayName
          )})`;
        return `${t('Trigger')} (${triggerData[event.data.triggerType](
          event,
          t
        )})`;
      },
    },
    [initiatorTypes.system]: {
      prefix,
      creationType: () => t('Kizen Administrator'),
    },
  };
};

const AutomationStartedEventBlock = ({ event, ...others }) => {
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon color={colorsPrimary.purple.dark} icon="sync" size="16px" />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={event.data.automationName}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    ></BasicBlock>
  );
};

export default AutomationStartedEventBlock;
