import React, { useState } from 'react';
import styled from '@emotion/styled';
import { gutters, breakpoints } from 'app/spacing';
import Textarea from 'components/Kizen/Textarea';
import TextTemplater from 'components/Kizen/TextTemplater';
import EditableText from 'components/Kizen/EditableText';
import Input from 'components/Kizen/Input';
import { KizenTypography } from 'app/typography';
import MOCK_TEAM_MEMBERS from 'services/MOCK_TEAM_MEMBERS';
import SubSection from './helpers/SubSection';
import Cols from './helpers/Cols';
import Section from './helpers/Section';
import { creditCards } from 'pages/Subscription/model';

const StyledInput = styled(Input)`
  margin-bottom: ${gutters.standard};
`;

const StyledTextarea = styled(Textarea)`
  margin-bottom: ${gutters.standard};
`;

const StyledTextTemplater = styled(TextTemplater)`
  margin-bottom: ${gutters.standard};
`;

const StyledEditableTextWrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

const StyledSubSection = styled(SubSection)`
  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 30px;
    hr {
      display: none;
    }
  }
`;

const LOREM = `Lorem ipsum dolor sit amet consectetur adipisicing elit.
Ullam nam saepe earum laudantium fugit, perferendis quo.
Tempora nemo aspernatur ab ipsa, laudantium ut incidunt sunt iste laboriosam quos id pariatur.`;

const TextSample = () => {
  const [text, setText] = useState('');
  const [contentText, setContentText] = useState('This input has content');
  const [errorText, setErrorText] = useState('');
  const [underlineText, setUnderlineText] = useState('');
  const [contentUnderlineText, setContentUnderlineText] = useState(
    'This input has content'
  );
  const [errorUnderlineText, setErrorUnderlineText] = useState('');
  const [growableText, setGrowableText] = useState('');
  const [contentGrowableText, setContentGrowableText] = useState(
    'This input has content'
  );
  const [inlineGrowableText, setInlineGrowableText] = useState('alongside');
  const [search, setSearch] = useState('');
  const [contentSearch, setContentSearch] = useState('Kizen FAQ');
  const [errorSearch, setErrorSearch] = useState('');
  const [price, setPrice] = useState('');
  const [contentPrice, setContentPrice] = useState('0.00');
  const [errorPrice, setErrorPrice] = useState('');
  const [decimal, setDecimal] = useState('');
  const [contentDecimal, setContentDecimal] = useState('0.00');
  const [errorDecimal, setErrorDecimal] = useState('');
  const [email, setEmail] = useState('');
  const [contentEmail, setContentEmail] = useState('JohnDoe@gmail.com');
  const [errorEmail, setErrorEmail] = useState('');
  const [longtext, setLongtext] = useState('');
  const [contentLongtext, setContentLongtext] = useState(LOREM);
  const [errorLongtext, setErrorLongtext] = useState('');
  const [longtextUnderline, setLongtextUnderline] = useState('');
  const [contentLongtextUnderline, setContentLongtextUnderline] =
    useState(LOREM);
  const [errorLongtextUnderline, setErrorLongtextUnderline] = useState('');
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [contentCreditCardNumber, setContentCreditCardNumber] =
    useState('3123456789876543');
  const [errorCardNumber, setErrorCardNumber] = useState('');
  return (
    <Section title="Text Inputs - Standard">
      <Cols columns={3} gutter="30px">
        <StyledSubSection title="Text Input">
          <StyledInput
            label="Placeholder"
            placeholder="Default"
            value={text}
            onChange={(value) => setText(value)}
          />
          <StyledInput
            label="With Content"
            value={contentText}
            onChange={(value) => setContentText(value)}
          />
          <StyledInput
            label="Error"
            placeholder="Default"
            value={errorText}
            onChange={(value) => setErrorText(value)}
            error
          />
          <StyledInput
            label="Disabled"
            placeholder="Default"
            value=""
            onChange={(value) => setText(value)}
            disabled
          />
        </StyledSubSection>

        <StyledSubSection title="Text Input (w/ Underline)">
          <StyledInput
            label="Placeholder"
            placeholder="Default"
            value={underlineText}
            onChange={(value) => setUnderlineText(value)}
            underline
          />
          <StyledInput
            label="With Content"
            value={contentUnderlineText}
            onChange={(value) => setContentUnderlineText(value)}
            underline
          />
          <StyledInput
            label="Error"
            placeholder="Default"
            value={errorUnderlineText}
            onChange={(value) => setErrorUnderlineText(value)}
            error
            underline
          />
          <StyledInput
            label="Disabled"
            placeholder="Default"
            value=""
            onChange={(value) => setText(value)}
            disabled
            underline
          />
        </StyledSubSection>

        <StyledSubSection title="Text Input (Inline, Growable)">
          <StyledEditableTextWrapper>
            <EditableText
              placeholder="Default"
              value={growableText}
              onChange={setGrowableText}
            />
          </StyledEditableTextWrapper>
          <StyledEditableTextWrapper>
            <EditableText
              placeholder="Default"
              value={contentGrowableText}
              onChange={setContentGrowableText}
            />
          </StyledEditableTextWrapper>
          <StyledEditableTextWrapper>
            <EditableText
              disabled
              label="Disabled"
              placeholder="Default"
              value=""
            />
          </StyledEditableTextWrapper>
          <StyledEditableTextWrapper>
            <EditableText
              disabled
              label="Disabled"
              value="This input is disabled with a value"
            />
          </StyledEditableTextWrapper>
          <StyledEditableTextWrapper>
            <KizenTypography as="span">This input flows </KizenTypography>
            <EditableText
              placeholder="Default"
              value={inlineGrowableText}
              onChange={setInlineGrowableText}
            />
            <KizenTypography as="span"> other text</KizenTypography>
          </StyledEditableTextWrapper>
        </StyledSubSection>

        <StyledSubSection title="Text Input (Search)">
          <StyledInput
            type={Input.INPUT_TYPES.SEARCH}
            label="Placeholder"
            value={search}
            placeholder="Default"
            onChange={(value) => setSearch(value)}
          />
          <StyledInput
            type={Input.INPUT_TYPES.SEARCH}
            label="With Content"
            value={contentSearch}
            onChange={(value) => setContentSearch(value)}
          />
          <StyledInput
            type={Input.INPUT_TYPES.SEARCH}
            label="Error"
            value={errorSearch}
            onChange={(value) => setErrorSearch(value)}
            error
          />
        </StyledSubSection>

        <StyledSubSection title="Text Input (Price)">
          <StyledInput
            label="Placeholder"
            placeholder="Default"
            value={price}
            onChange={(value) => setPrice(value)}
            type={Input.INPUT_TYPES.PRICE}
          />
          <StyledInput
            label="With Content"
            placeholder="Default"
            value={contentPrice}
            onChange={(value) => setContentPrice(value)}
            type={Input.INPUT_TYPES.PRICE}
          />
          <StyledInput
            label="Error"
            placeholder="Default"
            value={errorPrice}
            onChange={(value) => setErrorPrice(value)}
            type={Input.INPUT_TYPES.PRICE}
            error
          />
        </StyledSubSection>

        <StyledSubSection title="Text Input (Decimal)">
          <StyledInput
            type={Input.INPUT_TYPES.DECIMAL}
            label="Placeholder"
            value={decimal}
            placeholder="Default"
            onChange={(value) => setDecimal(value)}
          />
          <StyledInput
            type={Input.INPUT_TYPES.DECIMAL}
            label="With Content"
            value={contentDecimal}
            placeholder="Default"
            onChange={(value) => setContentDecimal(value)}
          />
          <StyledInput
            type={Input.INPUT_TYPES.DECIMAL}
            label="Error"
            value={errorDecimal}
            placeholder="Default"
            onChange={(value) => setErrorDecimal(value)}
            error
          />
        </StyledSubSection>

        <StyledSubSection title="Text Input (Email)">
          <StyledInput
            type={Input.INPUT_TYPES.EMAIL}
            label="Placeholder"
            value={email}
            placeholder="Default"
            onChange={(value) => setEmail(value)}
          />
          <StyledInput
            type={Input.INPUT_TYPES.EMAIL}
            label="With Content"
            value={contentEmail}
            onChange={(value) => setContentEmail(value)}
          />
          <StyledInput
            type={Input.INPUT_TYPES.EMAIL}
            label="Error"
            value={errorEmail}
            onChange={(value) => setErrorEmail(value)}
            error
          />
        </StyledSubSection>

        <StyledSubSection title="Long Text Input">
          {' '}
          <StyledTextarea
            label="Placeholder"
            placeholder="Default"
            value={longtext}
            onChange={(value) => setLongtext(value)}
          />
          <StyledTextarea
            label="With Content"
            value={contentLongtext}
            onChange={(value) => setContentLongtext(value)}
          />
          <StyledTextarea
            label="Error"
            value={errorLongtext}
            onChange={(value) => setErrorLongtext(value)}
            error
          />
          <StyledTextarea
            label="Disabled"
            placeholder="Default"
            value=""
            onChange={() => {}}
            disabled
          />
        </StyledSubSection>

        <StyledSubSection title="Long Text Input (w/ Underline)">
          <StyledTextarea
            label="Placeholder"
            placeholder="Default"
            value={longtextUnderline}
            onChange={(value) => setLongtextUnderline(value)}
            underline
          />

          <StyledTextarea
            label="With Content"
            value={contentLongtextUnderline}
            onChange={(value) => setContentLongtextUnderline(value)}
            underline
          />

          <StyledTextarea
            label="Error"
            value={errorLongtextUnderline}
            onChange={(value) => setErrorLongtextUnderline(value)}
            error
            underline
          />

          <StyledTextarea
            label="Disabled"
            placeholder="Default"
            value=""
            onChange={() => {}}
            disabled
            underline
          />
        </StyledSubSection>
        <StyledSubSection title="Text Input (Credit Card)">
          <StyledInput
            type={Input.INPUT_TYPES.CREDIT_CARD}
            label="Placeholder"
            value={creditCardNumber}
            placeholder="Default"
            onChange={(value) => setCreditCardNumber(value)}
            creditCards={creditCards}
          />
          <StyledInput
            type={Input.INPUT_TYPES.CREDIT_CARD}
            label="With Content"
            value={contentCreditCardNumber}
            onChange={(value) => setContentCreditCardNumber(value)}
            creditCards={creditCards}
          />
          <StyledInput
            type={Input.INPUT_TYPES.CREDIT_CARD}
            label="Error"
            value={errorCardNumber}
            onChange={(value) => setErrorCardNumber(value)}
            error
          />
        </StyledSubSection>

        <StyledSubSection title="TextTemplater">
          <StyledTextTemplater
            title="Text Message body"
            optionsDropdown={MOCK_TEAM_MEMBERS}
            onSave={() => {}}
          />
        </StyledSubSection>
      </Cols>
    </Section>
  );
};

export default TextSample;
