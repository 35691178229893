import EmailService from 'services/EmailService';
import LibraryService from 'services/LibraryService';
import { MESSAGE_LIBRARY } from '../query-keys';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { monitoringExceptionHelper } from 'sentry/helpers';

export const useListMessagesForContact = (id, ordering) => {
  const queryKey = [...MESSAGE_LIBRARY.CLIENT_MESSAGE, id];
  return useInfiniteQuery(
    [...queryKey, ordering],
    ({ pageParam }) =>
      LibraryService.getContactSentMessages({
        id,
        ordering,
        page: pageParam,
      }),
    {
      staleTime: 0,
      keepPreviousData: true,
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.next
          ? new URL(lastPage.next).searchParams.get('page')
          : undefined;
      },
      getPreviousPageParam: (previousPage) => {
        return previousPage?.prev
          ? new URL(previousPage.prev).searchParams.get('page')
          : undefined;
      },
    }
  );
};

export const useSentMessageById = (id, enabled = true) => {
  const queryFn = async () => {
    try {
      const res = await LibraryService.getContactSentMessageById(id);
      return res;
    } catch (error) {
      monitoringExceptionHelper(error);
      return { error: true, ...error.response };
    }
  };
  return useQuery({
    queryKey: [...MESSAGE_LIBRARY.MESSAGE, id],
    queryFn,
    enabled: Boolean(enabled && !!id),
  });
};

export const useSentMessageStats = (id) => {
  return useQuery(
    [...MESSAGE_LIBRARY.MESSAGE_STATS, id],
    () => LibraryService.getMessageStats(id),
    {
      staleTime: 0,
    }
  );
};

export const useSyncedEmailMessageContentQuery = (id) => {
  const queryFn = async () => {
    try {
      const res = await EmailService.getSyncedEmailMessageContent(id);
      return res;
    } catch (error) {
      monitoringExceptionHelper(error);
      return { error: true, ...error.response };
    }
  };
  return useQuery({
    queryKey: [...MESSAGE_LIBRARY.MESSAGE_SYNCED_EMAIL, id],
    queryFn,
    enabled: Boolean(id),
  });
};

export const useSendEmailMutation = () => useMutation(LibraryService.sendEmail);
