import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  OnChangeAdditionalVariableHandler,
  AdditionalVariableSettings,
} from './AdditionalVariableSettings';
import {
  StyledAddVariableButton,
  StyledBottomButtons,
  StyledAdditionalVariablesList,
} from './styles';
import {
  AdditionalVariable,
  GenericOption,
  SmartConnectorFlowErrors,
  SmartConnectorMetaData,
  VariableDataType,
} from '__pages/SmartConnectors/types';
import { getUniqueName } from '__pages/SmartConnectors/SmartConnector/helpers';
import {
  VARIABLE_DATA_TYPES,
  VARIABLE_TYPES,
} from '__pages/SmartConnectors/constants';

type AdditionalVariablesListProps = {
  data: AdditionalVariable[];
  setData: Dispatch<SetStateAction<AdditionalVariable[]>>;
  errors: SmartConnectorFlowErrors;
  setErrors: Dispatch<SetStateAction<SmartConnectorFlowErrors>>;
  metaData: SmartConnectorMetaData;
  uniqueNames: string[];
};

export const AdditionalVariablesList = ({
  data,
  setData,
  errors,
  setErrors,
  metaData,
  uniqueNames,
}: AdditionalVariablesListProps) => {
  const { t } = useTranslation();

  const dataSourceOptions = useMemo<GenericOption[]>(() => {
    return [{ value: 'static_value', label: t('Static Value') }];
  }, [t]);

  const onChangeVariableSettings: OnChangeAdditionalVariableHandler = ({
    id,
    prop,
    value,
  }) => {
    let result = value;
    setData((prev) =>
      (prev || []).map((variable) => {
        if (variable.id === id) {
          // sets unique name with incrementation
          if (prop === 'name') {
            const nextValue = getUniqueName(
              value as string,
              (prev || []).reduce(
                (acc, variable) => {
                  if (variable.id !== id && variable.name) {
                    acc.push(variable.name);
                  }
                  return acc;
                },
                [...uniqueNames]
              )
            );

            result = nextValue;

            return {
              ...variable,
              name: nextValue,
            };
          }

          // sets default input/output format based on data type
          if (prop === 'data_type') {
            const {
              meta: { default_output_format, default_input_format },
            } = metaData.execution_variables.variable_data_types.find(
              (dataType) => dataType.value === value
            ) || {
              meta: { default_output_format: null, default_input_format: null },
            };
            return {
              ...variable,
              data_type: value as VariableDataType,
              output_format: default_output_format,
              input_format: default_input_format,
              is_array: false,
              array_delimiter: null,
              value: value === VARIABLE_DATA_TYPES.boolean ? 'True' : '',
            };
          }

          return {
            ...variable,
            [prop]: value,
          };
        }
        return variable;
      })
    );

    return result;
  };

  const onDeleteVariable = (id: string, index: number) => {
    setData((prev) => (prev || []).filter((variable) => variable.id !== id));
    setErrors((prev) => ({
      ...prev,
      additional_variables: (prev.additional_variables || []).filter(
        (_, i) => index !== i
      ),
    }));
  };

  const onAddVariable = useCallback(() => {
    const {
      meta: { default_output_format, default_input_format },
    } = metaData.execution_variables.variable_data_types.find(
      ({ value }) =>
        value === metaData.execution_variables.default_variable_data_type
    ) || {
      meta: { default_output_format: null, default_input_format: null },
    };
    setData((prev) => [
      ...(prev || []),
      {
        id: `new_${Date.now()}`,
        name: '',
        data_type:
          metaData.execution_variables.default_variable_data_type || null,
        data_source: 'static_value',
        is_array: false,
        required: true,
        input_format: default_input_format,
        output_format: default_output_format,
        array_delimiter: null,
        display_order: 0,
        value: '',
        type: VARIABLE_TYPES.additional,
      },
    ]);
  }, [metaData, setData]);

  const hasNoVariablesRef = useRef(!data.length);

  useEffect(() => {
    if (hasNoVariablesRef.current) {
      hasNoVariablesRef.current = false;
      onAddVariable();
    }
  }, [onAddVariable]);

  const [variableIndexToScroll, setVariableIndexToScroll] = useState<
    number | null
  >(null);

  return (
    <>
      <StyledAdditionalVariablesList>
        {(data || []).map((variable, i) => {
          return (
            <AdditionalVariableSettings
              key={variable.id}
              index={i}
              variable={variable}
              dataSourceOptions={dataSourceOptions}
              onChange={onChangeVariableSettings}
              onDelete={onDeleteVariable}
              indexToScroll={variableIndexToScroll}
              setIndexToScroll={setVariableIndexToScroll}
              errors={errors}
              setErrors={setErrors}
              metaData={metaData}
            />
          );
        })}
      </StyledAdditionalVariablesList>
      <StyledBottomButtons>
        <StyledAddVariableButton variant="text" onClick={onAddVariable}>
          {t('+  Add Initialization Variable')}
        </StyledAddVariableButton>
      </StyledBottomButtons>
    </>
  );
};
