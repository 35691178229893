import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ConfirmationModal } from 'components/Charts/ScheduledActivities/ConfirmationModal';
import { CompleteActivityModal } from '__components/Modals/ActivityCompletionModal/ActivityCompletionModal';
import useModal from '__components/Modals/useModal';
import { toastVariant, useToast } from '__components/ToastProvider';
import { useModalControl } from '__hooks/useModalControl';
import {
  FAILED_COMPLETE_ACTIVITY,
  LOG_ACTIVITY_SUCCESS,
} from '__pages/Common/Actions/activityHelpers';
import { ACTIVITIES } from '__queries/query-keys';
import ActivityService from '__services/ActivityService';
import { getOriginalError } from '__services/AxiosService';

type RecordLayoutLogActivityCtx = {
  openLogActivityModal: (activityId: string) => void;
};

type Activity = {
  created: string;
  id: string;
  isEditable: boolean;
  name: string;
};

type LogActivityData = {
  activityObject: Activity | null;
  client?: any;
  company?: any;
  defaultAssignment?: any;
};

type LogActivityResult = {
  fields: any[];
  id: string;
  mentions: any[];
  notes: string;
  relatedObjects: {
    customObject: string;
    entityId: string;
  }[];
};

type LogActivityRequest = {
  activityObjectId: string;
  dirtyFields: boolean;
  payload: {
    fields: any[];
    mentions: any[];
    notes: string;
    relatedObjects: any[];
    scheduledActivityId?: string;
  };
};

type RecordLayoutLogActivityContextProps = {
  children: any;
  clientObject: any;
  contact: any;
  customObject: any;
  customObjectId: string;
  customObjectModel: any;
  entityId: string;
  refetch: () => void;
  resetAllFields: () => void;
  saveFormData: (args?: any) => void;
  touchedFormData: boolean;
};

const context = createContext<RecordLayoutLogActivityCtx>({
  openLogActivityModal: () => {},
});

export const useRecordLayoutLogActivityContext = () => {
  return useContext(context);
};

export const RecordLayoutLogActivityContext = (
  props: RecordLayoutLogActivityContextProps
) => {
  const {
    children,
    clientObject,
    contact,
    customObject,
    customObjectId,
    customObjectModel,
    entityId,
    refetch,
    resetAllFields,
    saveFormData,
    touchedFormData,
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showToast] = useToast();
  const [activityToLog, setActivityToLog] = useState<string | null>(null);
  const [data, setData] = useState<Activity | null>(null);
  const [loggingActivity, setLoggingActivity] = useState(false);

  const [
    isConfirmActivityModalOpen,
    {
      showModal: showConfirmActivityModal,
      hideModal: hideConfirmActivityModal,
    },
  ] = useModalControl();

  const logActivityActions = {
    update: async (dirtyFields: any) => {
      if (dirtyFields) {
        await refetch();
      }
    },
  };

  const handleLogActivity = async ({
    activityObjectId,
    payload,
    dirtyFields,
  }: LogActivityRequest): Promise<LogActivityResult> => {
    setLoggingActivity(true);
    const result = await ActivityService.v2CompleteActivity({
      activityObjectId,
      payload,
    });
    showToast({
      message: LOG_ACTIVITY_SUCCESS(t),
      variant: toastVariant.SUCCESS,
    });
    logActivityActions.update(dirtyFields);
    setData(null);
    queryClient.invalidateQueries(
      ACTIVITIES.CUSTOM_SCHEDULED(
        customObjectId ?? customObjectModel?.id,
        entityId
      )
    );
    setLoggingActivity(false);
    setData(null);
    setActivityToLog(null);

    return result;
  };

  const handleLogActivityError = (error: any) => {
    const orig = getOriginalError(error);
    showToast({
      message: orig?.message || FAILED_COMPLETE_ACTIVITY(t),
      variant: toastVariant.FAILURE,
    });
    setLoggingActivity(false);
  };

  const [modalProps, , { show: showModal, hide: hideModal }] = useModal({
    handleSubmit: handleLogActivity,
    handleError: handleLogActivityError,
  });

  const openLogActivityModal = useCallback(
    async (activityOrId: Activity | string) => {
      try {
        showModal();

        if (typeof activityOrId === 'object') {
          setData(activityOrId);
          return;
        }

        const res = await ActivityService.v2GetActivity(
          {
            activityObjectId: activityOrId,
          },
          { skipErrorBoundary: true }
        );

        setData(res);
      } catch (err: any) {
        if (err?.response?.status === 404) {
          hideModal();
          showToast({
            message: t('Activity was deleted'),
            variant: toastVariant.FAILURE,
          });
        }
      }
    },
    [showModal, hideModal, showToast, t]
  );

  const onOpenLogActivityModal = useCallback(
    async (id: string) => {
      if (touchedFormData) {
        setActivityToLog(id);
        showConfirmActivityModal();
      } else {
        await openLogActivityModal(id);
      }
    },
    [touchedFormData, showConfirmActivityModal, openLogActivityModal]
  );

  const activity = useMemo<LogActivityData>(() => {
    if (!data) {
      return { activityObject: null };
    }
    return {
      activityObject: data,
      client: contact,
      company: contact?.company,
      defaultAssignment: {},
    };
  }, [contact, data]);

  return (
    <context.Provider value={{ openLogActivityModal: onOpenLogActivityModal }}>
      {children}
      <CompleteActivityModal
        {...modalProps}
        activity={activity}
        disabled={loggingActivity}
        currentEntity={customObject || contact}
        currentObject={customObjectModel || clientObject}
      />
      <ConfirmationModal
        show={isConfirmActivityModalOpen}
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        actionBtnColor="green"
        buttonText={t('Save')}
        onHide={hideConfirmActivityModal}
        onConfirm={async () => {
          await saveFormData();
          hideConfirmActivityModal();
          openLogActivityModal(activityToLog!);
        }}
        onAdditionalConfirm={() => {
          resetAllFields();
          hideConfirmActivityModal();
          openLogActivityModal(activityToLog!);
        }}
      >
        {t(
          'The record has unsaved changes. Would you like to save them or discard them before logging your activity?'
        )}
      </ConfirmationModal>
    </context.Provider>
  );
};
