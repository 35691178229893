import { useTranslation } from 'react-i18next';
import {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import { StyledPageToolbar } from '../styles';
import Button from 'components/Button';
import { BackLink } from './BackLink';
import { getPathBack } from '../helpers';

export const TableToolBar = ({
  onChangeSearch,
  count = 0,
  loading,
  disabled,
  isMobile,
  onAddTemplate,
}) => {
  const { t } = useTranslation();

  return (
    <StyledPageToolbar>
      <BackLink />
      {isMobile ? null : (
        <>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={t('Find Templates')}
              onChange={onChangeSearch}
              loading={loading}
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={count}
            single={t('Column Template')}
            plural={t('Column Templates')}
          />
          <PageToolbarSection>
            <Button color="green" disabled={disabled} onClick={onAddTemplate}>
              {t('Add Template')}
            </Button>
          </PageToolbarSection>
        </>
      )}
    </StyledPageToolbar>
  );
};

export const MyColumnsToolBar = ({
  onSave,
  onSaveTemplate,
  onChooseTemplate,
  disabled,
  disabledSave,
  isMobile,
}) => {
  const { t } = useTranslation();

  return (
    <StyledPageToolbar>
      <BackLink />
      {isMobile ? null : (
        <PageToolbarSection>
          <Button
            color="green"
            onClick={onSave}
            disabled={disabled || disabledSave}
            data-qa="save-button"
          >
            {t('Save')}
          </Button>
          <Button color="green" onClick={onSaveTemplate} disabled={disabled}>
            {t('Save New Template')}
          </Button>
          <Button color="blue" onClick={onChooseTemplate} disabled={disabled}>
            {t('Choose Template')}
          </Button>
        </PageToolbarSection>
      )}
    </StyledPageToolbar>
  );
};

export const TemplateToolBar = ({ onSave, disabled, isMobile }) => {
  const { t } = useTranslation();
  const onSaveAndClose = () => {
    onSave({ withClose: true });
  };
  return (
    <StyledPageToolbar>
      <BackLink to={({ pathname }) => getPathBack(pathname)} />
      {isMobile ? null : (
        <PageToolbarSection>
          <Button color="green" onClick={onSave} disabled={disabled}>
            {t('Save')}
          </Button>
          <Button color="green" onClick={onSaveAndClose} disabled={disabled}>
            {t('Save & Close')}
          </Button>
        </PageToolbarSection>
      )}
    </StyledPageToolbar>
  );
};
