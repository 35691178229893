import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faMagnifyingGlassPlus } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlassPlus';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faHashtag } from '@fortawesome/pro-light-svg-icons/faHashtag';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import {
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';

export const SEMANTIC_CATEGORY: SemanticCategory = 'form';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  'input-dropdown-arrow': faAngleDown,
  'input-search': faMagnifyingGlass,
  'input-add-value': faMagnifyingGlassPlus,
  'input-date': faCalendarDay,
  'input-tag': faTag,
  'input-number': faHashtag,
  'input-time': faClock,
  'input-document': faEye,
  'input-currency-dollar': faDollarSign,
  'input-circle-color': faCircle,
  'input-clear': faXmark,
};

export const labels: IconLabel = {
  'input-dropdown-arrow': {
    title: (t) => '',
    description: (t) => '',
  },
  'input-search': {
    title: (t) => '',
    description: (t) => t('Search'),
  },
  'input-add-value': {
    title: (t) => '',
    description: (t) => t('Add Value'),
  },
  'input-date': {
    title: (t) => '',
    description: (t) => t('Date'),
  },
  'input-tag': {
    title: (t) => '',
    description: (t) => t('Tag'),
  },
  'input-number': {
    title: (t) => '',
    description: (t) => t('Number'),
  },
  'input-time': {
    title: (t) => '',
    description: (t) => t('Time'),
  },
  'input-document': {
    title: (t) => '',
    description: (t) => t('Document'),
  },
  'input-currency-dollar': {
    title: (t) => '',
    description: (t) => t('Dollars'),
  },
  'input-circle-color': {
    title: (t) => '',
    description: (t) => t('Circle Color'),
  },
  'input-clear': {
    title: (t) => '',
    description: (t) => t('Clear'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};
