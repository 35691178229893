import { useCallback, useContext, useEffect, useMemo } from 'react';
import { PrimaryObjectUploadContext } from '../../context';
import { useFlashTransition } from '__hooks/useFlashState';
import { MappingRule } from '__pages/SmartConnectors/types';

const ruleErrorsOrder: Partial<Record<keyof MappingRule, number>> = {
  field: 0,
  variables: 1,
  conflict_resolution: 2,
  can_create_field_options: 3,
};

export const useMappingRulesErrors = (index: number) => {
  const {
    loadId,
    stepData,
    errors: { loads = [] },
    setErrors,
  } = useContext(PrimaryObjectUploadContext);

  const [message, showMessage, flashErrorMessage, cancelFlash] =
    useFlashTransition();

  const loadIndex = useMemo(() => {
    return (stepData.loads || []).findIndex((load) => load.id === loadId);
  }, [stepData.loads, loadId]);

  const { field_mapping_rules = [] } = loads[loadIndex] || {};

  const ruleError = field_mapping_rules[index];

  const hasError = Boolean(ruleError && Object.values(ruleError).length);

  const isFirstVariableError =
    hasError &&
    !field_mapping_rules!.some(
      (el, i) =>
        i < index && el && typeof el === 'object' && Object.values(el).length
    );

  const errorKey = useMemo<keyof MappingRule | null>(() => {
    return isFirstVariableError
      ? (Object.keys(ruleError) as (keyof MappingRule)[]).sort(
          (a, b) => ruleErrorsOrder[a]! - ruleErrorsOrder[b]!
        )[0]
      : null;
  }, [isFirstVariableError, ruleError]);

  useEffect(() => {
    if (errorKey && ruleError[errorKey]) {
      flashErrorMessage(ruleError[errorKey]);
    }
  }, [ruleError, flashErrorMessage, errorKey]);

  useEffect(() => {
    cancelFlash();
  }, [index, cancelFlash]);

  const clearError = useCallback(
    (prop: keyof MappingRule) => {
      if (hasError) {
        if (showMessage && errorKey === prop) {
          cancelFlash();
        }

        setErrors((prev) => ({
          ...prev,
          loads: (prev.loads || []).map((load, i) => {
            if (i === loadIndex) {
              return {
                ...load,
                field_mapping_rules: (load.field_mapping_rules || []).map(
                  (el, i) => {
                    if (i === index) {
                      // changing field resets the whole rule
                      if (prop === 'field') {
                        return {};
                      }
                      const { [prop]: _, ...rest } = el;
                      return rest;
                    }
                    return el;
                  }
                ),
              };
            }
            return load;
          }),
        }));
      }
    },
    [showMessage, cancelFlash, hasError, setErrors, errorKey, index, loadIndex]
  );

  const validate = useMemo(
    () => ({ showMessage, message }),
    [showMessage, message]
  );

  return {
    hasError,
    ruleError,
    errorKey,
    validate,
    clearError,
  };
};
