import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, colorsPrimary, grayScale } from 'app/colors';
import { borderRadii, gutters, layers } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';

export const itemVerticalMargin = gutters.spacing(3 / 2);

export const VerticalDropzone = styled((props) => {
  return (
    <div {...props}>
      {/* Long, dashed line */}
      <div />
      {/* Decorative handle */}
      <div />
    </div>
  );
})`
  ${({ absolute, before }) =>
    absolute
      ? css`
          position: absolute;
          top: 0;
          bottom: 0;
          ${before
            ? css`
                left: -${gutters.spacing(1)}px;
              `
            : `right: -${gutters.spacing(1)}px`}
        `
      : ''}
  width: 0px;
  display: flex;
  z-index: ${layers.content(0)};
  ${({ margin }) => css`
    margin: ${margin}px 0;
  `}
  div:first-child {
    display: block;
    width: 0px;
    border-right: 1px dashed ${colorsButton.blue.default};
    flex-shrink: 0;
    transform: translateX(-50%);
  }
  div:last-child {
    display: block;
    width: 5px;
    height: 20px;
    flex-shrink: 0;
    align-self: center;
    background-color: blue;
    transform: translateX(-1px) translateX(-50%);
    border-radius: ${5 / 2}px;
    background-color: ${colorsButton.blue.default};
  }
`;

export const HorizontalDropzone = styled((props) => {
  return (
    <div {...props}>
      {/* Long, dashed line */}
      <div />
      {/* Decorative handle */}
      <div />
    </div>
  );
})`
  max-height: 0;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  z-index: ${layers.content(0)};
  ${({ margin }) => css`
    margin: 0 ${margin}px;
  `}
  div:first-child {
    display: block;
    height: 0;
    border-bottom: 1px dashed
      ${({ orange }) =>
        orange ? colorsPrimary.orange.dark : colorsButton.blue.default};
    flex-shrink: 0;
    transform: translateY(-50%);
  }
  div:last-child {
    display: block;
    height: 5px;
    width: 30px;
    flex-shrink: 0;
    align-self: center;
    transform: translateY(-1px) translateY(-50%);
    border-radius: ${5 / 2}px;
    background-color: ${({ orange }) =>
      orange ? colorsPrimary.orange.dark : colorsButton.blue.default};
  }
`;

const StyledOverallDropzone = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f5f6f7;
  z-index: ${layers.content(10)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OverallDropzone = ({ text }) => {
  const { t } = useTranslation();
  return (
    <StyledOverallDropzone>
      <KizenTypography weight="bold">
        {text ?? t('Drop here to remove')}
      </KizenTypography>
    </StyledOverallDropzone>
  );
};

export const ItemPlaceholder = styled.div`
  position: absolute;
  top: ${itemVerticalMargin}px;
  bottom: ${itemVerticalMargin}px;
  left: 0;
  right: 0;
  background-color: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.standard};
`;

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;

  ${({ width, cols }) => css`
    width: ${(100 * width) / cols}%;
  `}
  ${({ dragging }) =>
    dragging &&
    css`
      z-index: ${layers.content(0, 1)};
      pointer-events: none;
    `}
`;
