import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import { namedToOption } from 'services/helpers';
import EmailService from 'services/EmailService';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import Select from 'components/Inputs/Select';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import { getDataQAForInput } from 'components/Inputs/helpers';

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${gutters.spacing(0, 3)}px;
  button {
    width: 32%;
    margin-top: ${gutters.spacing(3, -3)}px;
    margin-bottom: ${gutters.spacing(3)}px;
  }
`;

const StyledButtonGroupImageButton = styled(ButtonGroupImageButton)`
  && span {
    text-transform: uppercase;
  }
`;
// compensation for dialog body margins
const Wrapper = styled.div`
  margin-top: ${gutters.spacing(4, -4)}px;
  margin-bottom: ${gutters.spacing(0, -3)}px;
`;

const ManageSubscriptions = ({
  onSetSubscriptionItem,
  onSetSubscriptionStatus,
  subscriptionItem,
  subscriptionStatus,
}) => {
  const { t } = useTranslation();
  const subscriptionActions = useMemo(
    () => [
      { label: t('Mark Opted In'), value: 'opted_in' },
      { label: t('Mark Not Opted In'), value: 'not_opted_in' },
      { label: t('Mark Opted Out'), value: 'opted_out' },
    ],
    [t]
  );
  const selectedStatusOption = subscriptionActions.find(
    (item) => item.value === subscriptionStatus
  );

  const [{ value: subscriptionList = [] }, fetch] =
    useAsyncFnKeepLast(async () => {
      return EmailService.getSubscriptionList();
    }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const options = useMemo(
    () => subscriptionList.map(namedToOption),
    [subscriptionList]
  );

  return (
    <Wrapper>
      <KizenTypography as="label">
        {t('Choose Subscription Action')}
      </KizenTypography>
      <ActionWrapper>
        {subscriptionActions.map((item, i) => (
          <StyledButtonGroupImageButton
            key={item.value}
            selected={item.value === subscriptionStatus}
            onClick={() => {
              onSetSubscriptionStatus(item.value);
              onSetSubscriptionItem(null);
            }}
            option={item}
          />
        ))}
      </ActionWrapper>
      {selectedStatusOption && (
        <Select
          label={
            t('Subscription List to Mark as') +
            ' ' +
            selectedStatusOption.label.slice(5)
          }
          placeholder={t('Find List to') + ' ' + selectedStatusOption.label}
          menuInline
          options={options}
          value={subscriptionItem}
          onChange={onSetSubscriptionItem}
          {...getDataQAForInput('manage-subscriptions', 'dropdown')}
        />
      )}
    </Wrapper>
  );
};

ManageSubscriptions.propTypes = {
  onChange: PropTypes.func.isRequired,
};

ManageSubscriptions.defaultProps = {};

export default ManageSubscriptions;
