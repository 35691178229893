import css from '@emotion/css';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { grayScale } from 'app/colors';
import { layers } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import Loader from 'components/Kizen/Loader';

export const DropdownRow = styled.div`
  display: flex;
  width: 100%;
  column-gap: 20px;
`;

export const DropdownContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

export const BoldTypography = styled(KizenTypography)`
  font-weight: 600;
`;

export const InfoIcon = styled(Icon)`
  & svg {
    width: 10px;
    height: 10px;
  }
  align: base-line;
  margin-left: 5px;
  color: ${grayScale.mediumDark};
  :hover {
    color: ${grayScale.dark};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalContainer = styled.div`
  position: relative;

  ${({ inline }) =>
    inline
      ? css`
          display: grid;
          grid-template-columns: repeat(3, minMax(0, 1fr));
          grid-column-gap: 20px;

          .inline-loader {
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;

            & > div {
              padding-top: 0 !important;
            }
          }
        `
      : ''}
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: ${layers.content(1)};
`;
