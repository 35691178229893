import { FIELD_TYPES as ALL_FIELD_TYPES } from 'utility/constants';

export const CHART_TYPES = {
  SUMMARY: 'numeric',
  // SUMMARY_TARGET: 'summary_target',
  TREND: 'line',
  DONUT: 'donut',
  // GLOBE: 'globe',
  BAR: 'bar',
  // FUNNEL: 'funnel',
  // LEADERBOARD: 'leaderboard'
};

export const METRIC_TYPES = {
  BREAKDOWN: 'breakdown',
  AVERAGE: 'average',
  SUM: 'sum',
  YES_NO: 'yes_no',
  TOTAL_SELECTION: 'count',
  TOTAL_SELECTION_PERCENTAGE: 'percent',
  EMAIL_DELIVERY_PERCENTAGE: 'percent_delivered',
  EMAIL_OPT_OUT_PERCENTAGE: 'percent_opt_out',
  EMAIL_COMPLAINT_PERCENTAGE: 'percent_complaint',
  PIPELINE_NUM_DEALS: 'number',
  PIPELINE_VALUE: 'value',
  PIPELINE_WEIGHTED_VALUE: 'weighted_value',
  EMAIL_OPEN_PERCENTAGE: 'percent_opened',
  EMAIL_CLICK_PERCENTAGE: 'percent_clicked',
  VALUE: 'records_value',
};

export const FIELD_TYPES = {
  RATING: ALL_FIELD_TYPES.Rating.type,
  DROPDOWN: ALL_FIELD_TYPES.Dropdown.type,
  NUMBER: 'number',
  YES_NO: 'yes_no',
  RADIO: ALL_FIELD_TYPES.Radio.type,
  CHECKBOX: ALL_FIELD_TYPES.Checkbox.type,
  CUSTOM_CHECKBOX: 'custom_checkbox',
  EMAIL_STAT: ALL_FIELD_TYPES.Email.type,
  PIPELINE_VALUES: 'pipeline',
  // odd case. Not used directly in eventual API calls, but
  // rather to enable selection of particular display of pipeline metric
  // see usage in MetricTypeSelector
  PIPELINE_DISPLAY: 'pipeline_display',
};

export const FREQUENCY_TYPES = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};
