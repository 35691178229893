import {
  INLINE_TABLE_DEFAULT_WIDTH_IN_PIXELS,
  getColumnNumeric,
} from 'components/Fields/InlineTableFieldInput';
import { getCellForFieldType } from 'components/Layout/BigTableLayout/InlineCells';
import { getBasicColumns } from 'pages/Common/RelatedObjectFields/contact/columns';
import { getObjectUrl } from 'services/FieldService';
import { snakeToCamelCase } from 'services/helpers';
import { FIELD_TYPES } from 'utility/constants';
import { SortableHeadCell } from './shared';

export const getColumnsForClient = ({
  columns,
  fields,
  fieldsById,
  onResizeColumnWidth,
  onResizeStop,
  getColumnWidth,
  narrowestWidth,
  minWidth,
  resizable,
  model,
}) => {
  const basicColumns = getBasicColumns({ fields, model });

  return [
    ...columns
      .map((c) => {
        if (basicColumns[snakeToCamelCase(c.name)]) {
          return {
            id: c.id,
            label: c.label,
            ...basicColumns[snakeToCamelCase(c.name)],
            headCell: (
              <SortableHeadCell
                column={c}
                getColumnWidth={getColumnWidth}
                onResizeColumnWidth={onResizeColumnWidth}
                onResizeStop={onResizeStop}
                narrowestWidth={narrowestWidth}
                minWidth={minWidth}
                resizable={resizable}
                numeric={
                  // Copy the numeric prop from the original headCell
                  basicColumns[snakeToCamelCase(c.name)].headCell?.props
                    ?.numeric
                }
              />
            ),
          };
        }
        const field = fieldsById?.[c.id];

        if (!field) {
          return null;
        }

        return {
          id: c.id,
          label: c.label,
          headCell: (
            <SortableHeadCell
              column={c}
              getColumnWidth={getColumnWidth}
              onResizeColumnWidth={onResizeColumnWidth}
              onResizeStop={onResizeStop}
              narrowestWidth={narrowestWidth}
              minWidth={minWidth}
              resizable={resizable}
              numeric={getColumnNumeric(field)}
              hasSorting={field.fieldType !== FIELD_TYPES.Relationship.type}
            />
          ),
          bodyCell: {
            field: { ...field, isReadOnly: true },
            fetchUrl: getObjectUrl('contacts'),
            model,
            minWidth: INLINE_TABLE_DEFAULT_WIDTH_IN_PIXELS,
            Component: getCellForFieldType(field?.fieldType),
            forceReadOnly: true,
            editable: false,
            disabled: true,
            isCustomObjects: false,
            fieldProps: {},
            dataQaPrefix: 'inline-contact-',
          },
          // Formatting happens internal to CustomFieldDataCell, but this
          // provides a hook that can be used by e.g. AddRecordTRow.
          meta: {
            editable: false,
          },
          format: (x) => x,
        };
      })
      .filter(Boolean),
  ];
};
