import { getKDSClasses, merge } from '../../util';
import { CustomIconProps } from './types';

/**
 * This is an icon that is very similar to the fontawesome icon "input-pipe", however
 * the dimensions of that icon don't quite line up with how we want it to render. Instead
 * of using the fontawesome version, we use a slightly modified custom SVG to align it correctly.
 */
export const InputPipe = (props: CustomIconProps) => {
  const { className } = props;

  return (
    <svg
      viewBox="0 -1 28 18.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={merge(getKDSClasses('custom-icon', 'input-pipe'), className)}
    >
      <path
        d="M3.0285 1.14286C2.25707 1.14286 1.4285 1.14286 1.4285 2.66667C1.4285 2.66667 1.4285 12 1.4285 13.3333C1.4285 14.6667 2.25707 14.8571 3.0285 14.8571H13.9999H24.9714C25.6999 14.8571 26.5714 14.6667 26.5714 13.3333V2.66667C26.5714 1.14286 25.6999 1.14286 24.9714 1.14286H3.0285ZM0.285645 2.66667C0.285645 1.20833 1.48564 0 3.0285 0H24.9714C26.4714 0 27.7142 1.20833 27.7142 2.66667V13.3333C27.7142 14.8333 26.4714 16 24.9714 16H3.0285C1.48564 16 0.285645 14.8333 0.285645 13.3333V2.66667ZM5.77136 4.66667V11.3333C5.77136 11.7083 5.4285 12 5.08564 12C4.69993 12 4.39993 11.7083 4.39993 11.3333V4.66667C4.39993 4.33333 4.69993 4 5.08564 4C5.4285 4 5.77136 4.33333 5.77136 4.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
