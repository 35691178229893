import { merge } from '@kizen/kds/util';
import { useCallback, useContext, useMemo } from 'react';
import Draggable from 'react-draggable';
import { ItemProps } from './types';
import { GridState } from './state';

export const Item = (props: ItemProps) => {
  const {
    item,
    columnId,
    displayItem,
    placeholderClassName,
    setCanStartRowDrag,
  } = props;
  const {
    setDragItem,
    registerChild,
    handleDrop,
    dragItem,
    setPlaceholderPosition,
    placeholderPosition,
    dropZones,
    dragGroup,
  } = useContext(GridState);

  const columnElement = dropZones[columnId];

  const itemRef = useCallback(
    (element: HTMLDivElement) => {
      registerChild(columnId, item.id, element);
    },
    [columnId, registerChild, item]
  );

  const isDragging = dragItem && dragItem.id === item.id;

  const placeholderStyle: any = useMemo(() => {
    if (isDragging && placeholderPosition) {
      return {
        '--drag-item-height': `${placeholderPosition.height}px`,
        '--drag-item-width': `${placeholderPosition.width}px`,
        '--drag-item-top': `${
          placeholderPosition.top -
          (columnElement?.getBoundingClientRect().top ?? 0)
        }px`,
      };
    }

    return undefined;
  }, [isDragging, placeholderPosition, columnElement]);

  return (
    <>
      <Draggable
        onStart={(ev: any) => {
          ev.preventDefault();
          setDragItem({
            ...item,
            id: item.id,
            parent: columnId,
          });
          if (ev.currentTarget) {
            setPlaceholderPosition({
              height: ev.currentTarget.offsetHeight,
              width: ev.currentTarget.offsetWidth,
              top: ev.currentTarget.getBoundingClientRect().top,
            });
          }
        }}
        onStop={handleDrop}
        position={isDragging ? undefined : { x: 0, y: 0 }}
        handle=".kds-icon-action-drag-handle"
        defaultClassNameDragging="kds-dragging"
      >
        <div ref={itemRef}>
          {displayItem(
            item,
            isDragging ?? false,
            setCanStartRowDrag,
            dragGroup
          )}
        </div>
      </Draggable>
      {placeholderStyle ? (
        <div
          className={merge(
            'absolute h-[var(--drag-item-height)] w-[var(--drag-item-width)] top-[var(--drag-item-top)] left-0 pointer-events-none z-[99]',
            placeholderClassName
          )}
          style={placeholderStyle}
        />
      ) : null}
    </>
  );
};
