import { CUSTOM_OBJECTS } from 'queries/query-keys';
import { useQuery } from 'react-query';
import CustomObjectService from 'services/CustomObjectsService';

const useCustomObjectDetails = ({ objectId, enabled }) => {
  const { data, isLoading } = useQuery(
    CUSTOM_OBJECTS.DETAILS(objectId),
    () => CustomObjectService.getCustomObjectDetails(objectId),
    {
      enabled: enabled && Boolean(objectId),
    }
  );

  return { data, isLoading };
};

export default useCustomObjectDetails;
