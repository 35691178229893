import { useCallback, useEffect, useMemo, useRef } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { useTranslation } from 'react-i18next';
import {
  NotesRichTextEditor,
  parseMentions,
} from 'components/Inputs/NotesRichTextEditor';
import { useAttachListener } from 'hooks/useAttachListener';
import { NotesLabel } from 'pages/Common/styles/Profile';

import { FORMDATA_FIELDS, dummyObject } from '../config';
import { ActivityFields, StyledSection } from '../styled';
import ActivityService from 'services/ActivityService';

import { isDefaultUndeletableNotesField } from 'checks/fields';
import FieldInput from 'components/Fields/FieldInput';
import { getDataQAForInput } from 'components/Inputs/helpers';
import {
  fieldModifier,
  getFieldValueFromAPI,
  isEmptyValue,
  setInitialValue,
} from '../helpers';
import { isEmailStatusField } from 'checks/fields';
import { getPercentageChanceToCloseTooltip } from 'pages/CustomObjects/RecordsPage/helpers';
import { ACTIVITY_FIELD_VALUE_FILE_UPLOAD_SOURCE } from 'hooks/uploadFiles/useUploadFile';
import { isContact } from 'utility/fieldHelpers';
import { useBreakpoint } from 'app/spacing';
import { _modalSize } from 'components/Modals';

const { NOTES, MENTIONS, FIELDS } = FORMDATA_FIELDS;

const upToParent = (el, id) => {
  while (el && el.parentNode) {
    el = el.parentNode;
    if (el?.id === id) {
      return el;
    }
  }
  // Many DOM methods return null if they don't
  // find the element they are searching for
  // It would be OK to omit the following and just
  // return undefined
  return null;
};
const CompleteActivityFields = ({
  getKeyListenersProps,
  updateField,
  relatedObjects,
  activityObject = {},
  fields,
  activityFields,
  notes,
  errors,
  showErrors,
  setShowErrors,
  isRuleActiveForField,
}) => {
  const { t } = useTranslation();
  const menuOpenRef = useRef(false);

  const errorMessage = useMemo(
    () => showErrors?.length && { message: t('This field is required.') },
    [t, showErrors]
  );

  const showErrorMessages = useMemo(
    () =>
      showErrors?.map((err) =>
        err?.value?.[0] ? { message: err?.value?.[0] } : null
      ),
    [showErrors]
  );

  const message = (i) => {
    return showErrors[i] && errors[i]
      ? errorMessage
      : showErrorMessages[i] || null;
  };

  const setField = useCallback(
    (id, value) =>
      updateField(
        FIELDS,
        (prev) =>
          prev.map((prevField) =>
            prevField.id === id ? { ...prevField, value } : prevField
          ),
        false
      ),
    [updateField]
  );

  const setValues = useCallback(
    async (objectId, recordId) => {
      const fieldsValues =
        objectId && recordId
          ? await ActivityService.getEntityRecordsFieldValues(
              activityObject.id,
              recordId
            )
          : [];
      updateField(
        FIELDS,
        (prev) =>
          prev.map((prevField) => {
            const shouldFieldBeUpdated = prevField.customObject === objectId;
            const fieldFromAPI =
              shouldFieldBeUpdated &&
              fieldsValues.find((f) => f.id === prevField.customObjectFieldId);

            if (shouldFieldBeUpdated && !fieldFromAPI) {
              return {
                ...prevField,
                value: setInitialValue(
                  activityFields.find(({ id }) => id === prevField.id)
                ),
              };
            }
            return fieldFromAPI
              ? {
                  ...fieldFromAPI,
                  id: prevField.id,
                  customObject: objectId,
                  customObjectFieldId: prevField.customObjectFieldId,
                  value: isEmptyValue(fieldFromAPI.value)
                    ? setInitialValue(
                        activityFields.find(({ id }) => id === prevField.id)
                      )
                    : getFieldValueFromAPI(fieldFromAPI),
                  fieldData: prevField.fieldData,
                }
              : prevField;
          }),
        true
      );
    },
    [activityObject, updateField, activityFields]
  );

  const setInitialValues = useCallback(() => {
    updateField(
      FIELDS,
      (prevFields) =>
        activityFields.map((activityField) => {
          const { displayName, fieldType, id, customObjectField, name } =
            activityField;
          const prevValue = prevFields.find((field) => field?.id === id)?.value;
          return {
            id,
            displayName,
            name,
            fieldType,
            customObjectFieldId: customObjectField?.id,
            customObject: customObjectField?.customObject.id,
            value: isEmptyValue(prevValue)
              ? setInitialValue({ fieldType })
              : prevValue,
            fieldData: activityField,
          };
        }),
      true
    );
  }, [activityFields, updateField]);

  useEffect(() => {
    setInitialValues();
  }, [setInitialValues]);

  const fetchedEntitiesRef = useRef({});

  // this will run whenever we change any association, it will map through all associations to decide to fetch selected entitites' field values
  // and here are the rules:
  // 1. if we did not have this entitiy before the change, we fetch it's values and replace previuosly set (or empty) fields with the fetched
  // values.
  // 2. if we had this entity before the change, we do not fetch it's values again and leave fields in previous state.
  // 3. if the association was cleared (entity removed) we leave fields in previous state.

  useDebounce(
    () => {
      if (activityFields.length) {
        if (relatedObjects.length && activityObject.id) {
          const lookup = relatedObjects.reduce((acc, { entityId }) => {
            acc[entityId] = true;
            return acc;
          }, {});

          // filter out fetched entities that are not in the current related objects
          fetchedEntitiesRef.current = Object.keys(
            fetchedEntitiesRef.current
          ).reduce((acc, key) => {
            if (lookup[key]) {
              acc[key] = fetchedEntitiesRef.current[key];
            }
            return acc;
          }, {});

          // map through related objects and fetch field values for newly added
          relatedObjects.forEach(({ customObject, entityId }) => {
            if (!fetchedEntitiesRef.current[entityId]) {
              setValues(customObject, entityId);
              fetchedEntitiesRef.current[entityId] = !!entityId;
            }
          });
        } else {
          setInitialValues();
        }
      }
    },
    500,
    [
      relatedObjects,
      activityFields.length,
      setValues,
      setInitialValues,
      activityObject,
    ]
  );

  const getEmailStatusProp = (field, fieldValue) => {
    if (isEmailStatusField(field)) {
      const value = field.options.find(({ id }) => fieldValue === id);
      return value?.code === 'suppression_list'
        ? { email_status: value?.code }
        : {};
    }
    return {};
  };

  const scrollDropdownListener = useCallback((ev) => {
    if (menuOpenRef.current && !upToParent(ev.target, 'dropdown-menu')) {
      ev.target?.parentNode?.parentNode?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      ev.target.removeEventListener('mouseup', this);
    }
  }, []);

  const listenerRef = useAttachListener(scrollDropdownListener, 'mouseup');

  const isMobile = useBreakpoint(_modalSize.medium);

  return (
    <StyledSection
      index={1}
      header={t('Complete Activity')}
      isActive
      isLastIndex
      ref={listenerRef}
    >
      <ActivityFields>
        {activityFields.map((field, i, { length }) => {
          const isStatusFieldRequired =
            isEmailStatusField(field) &&
            isContact(field.customObjectField.customObject) &&
            relatedObjects.find(
              ({ customObject }) =>
                field.customObjectField.customObject.id === customObject
            );
          if (field.isHidden || !isRuleActiveForField(field)) {
            return null;
          }
          // we have to left only custom activity field associated to entities & activity fields
          const fieldWithvalue = fields.find(
            ({ id, customObject, value }) =>
              id === field.id &&
              (!customObject ||
                relatedObjects.some(
                  (obj) => obj.customObject === customObject
                ) ||
                value)
          );
          const percentageChanceToCloseProps = {
            ...(fieldWithvalue?.valuePlaceholder &&
            !fieldWithvalue?.value &&
            fieldWithvalue?.value !== 0
              ? {
                  info: getPercentageChanceToCloseTooltip(t),
                  placeholder: fieldWithvalue?.valuePlaceholder,
                }
              : {}),
          };
          const preLast =
            !isMobile &&
            i + 2 === length &&
            field.meta.cols === 1 &&
            !activityFields[i + 1]?.isHidden &&
            isRuleActiveForField(activityFields[i + 1]) &&
            activityFields[i + 1]?.meta?.cols === 1;

          return (
            <div key={field.id} className={`span${field.meta.cols || 1}`}>
              {isDefaultUndeletableNotesField(field) ? (
                <NotesRichTextEditor
                  {...getKeyListenersProps(field.id)}
                  className="notes"
                  label={
                    <NotesLabel>
                      {t('Notes')}
                      {field.isRequired && '*'}
                    </NotesLabel>
                  }
                  value={notes}
                  onChange={({ editor }) => {
                    const text = editor.getHTML();
                    updateField(NOTES, text);
                    updateField(MENTIONS, parseMentions(text));
                  }}
                />
              ) : (
                <FieldInput
                  source={ACTIVITY_FIELD_VALUE_FILE_UPLOAD_SOURCE}
                  isActivityField
                  margin={!preLast}
                  field={fieldModifier(field, relatedObjects)}
                  fetchUrl={
                    ['pipeline', 'standard'].includes(
                      field?.customObjectField?.customObject?.fetchUrl
                    )
                      ? `custom-objects/${field.customObjectField.customObject.id}`
                      : field?.customObjectField?.customObject?.fetchUrl
                  }
                  object={{
                    ...dummyObject,
                    ...getEmailStatusProp(field, fieldWithvalue?.value),
                  }}
                  value={fieldWithvalue?.value}
                  onChange={(val) => {
                    showErrors[i] &&
                      setShowErrors(
                        showErrors.map((err, j) => (i === j ? false : err))
                      );
                    setField(field.id, val);
                  }}
                  isGetFullObject
                  underline={false}
                  error={message(i)}
                  serviceToUse={ActivityService}
                  objectId={activityObject.id}
                  isShowAsterisk={field.isRequired}
                  required={field.isRequired || isStatusFieldRequired}
                  inModal
                  {...getDataQAForInput(
                    `activity-${field.name}`,
                    field.fieldType
                  )}
                  onMenuOpen={() => (menuOpenRef.current = true)}
                  onMenuClose={() => (menuOpenRef.current = false)}
                  {...percentageChanceToCloseProps}
                  {...getKeyListenersProps(field.id)}
                />
              )}
            </div>
          );
        })}
      </ActivityFields>
    </StyledSection>
  );
};

export default CompleteActivityFields;
