import { useCallback, useState } from 'react';
import { TRow } from 'components/Kizen/Table';
import { useColumns } from '../hooks/useColumns';
import { DynamicTagsModal } from '../modals/DynamicTagsModal';
import DeleteDynamicTagsModal from '../modals/DeleteDynamicTagsModal';
import ReplaceDynamicTagsModal from '../modals/ReplaceDynamicTagsModal';
import BasicTable from 'components/Tables/Basic';
import { TAG_ACTIONS } from '../constants';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

export const DynamicTagsManagerTable = ({
  tags,
  onDeleteTag,
  onUpdateTag,
  onReplaceTag,
  tableRef,
  isFetching,
  model,
  field,
  serviceToUse,
  headData,
  showTagLink,
  dictionary = EMPTY_OBJECT,
  ...others
}) => {
  const [tag, setTag] = useState(null);
  const [action, setAction] = useState(null);

  const { tagLabel, tagLabelShort, countLabel } = dictionary;

  const onHide = () => {
    setTag(null);
    setAction(null);
  };

  const onSelectAction = useCallback(({ value }, tag) => {
    setTag(tag);
    setAction(value);
  }, []);

  const columns = useColumns({
    model,
    field,
    onSelectAction,
    tableRef,
    showTagLink,
    tagLabel: tagLabelShort,
    countLabel,
  });

  return (
    <>
      <BasicTable
        stickyHeader
        head={<TRow head columns={columns} data={headData} />}
        compactHeader
        staleData={isFetching}
        {...others}
      >
        {tags.map((tag) => (
          <TRow key={tag.id} columns={columns} data={tag} />
        ))}
        {tags.length <= 2
          ? Array.from({ length: 3 - tags.length }, (_, i) => <tr key={i} />)
          : null}
      </BasicTable>
      {tag && action === TAG_ACTIONS.edit ? (
        <DynamicTagsModal
          show
          tag={tag}
          onHide={onHide}
          onConfirm={onUpdateTag}
          serviceToUse={serviceToUse}
          model={model}
          field={field}
          tagLabel={tagLabel}
        />
      ) : null}
      {tag && action === TAG_ACTIONS.delete ? (
        <DeleteDynamicTagsModal
          show
          onHide={onHide}
          tag={tag}
          onConfirm={onDeleteTag}
          tagLabel={tagLabel}
          field={field}
        />
      ) : null}
      {tag && action === TAG_ACTIONS.replace ? (
        <ReplaceDynamicTagsModal
          show
          tag={tag}
          onHide={onHide}
          onConfirm={onReplaceTag}
          serviceToUse={serviceToUse}
          model={model}
          field={field}
          tagLabel={tagLabel}
          tagLabelShort={tagLabelShort}
        />
      ) : null}
    </>
  );
};
