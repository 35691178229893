import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  user-select: none;
`;

export const Text = styled(KizenTypography)`
  color: ${grayScale.mediumLight};
`;

export const StyledNoData = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 30px;
`;
