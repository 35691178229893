import KizenTypography from 'app/kizentypo';
import { useModalClickedState } from 'components/DashboardGrid/hooks/useModalClickedState';
import { isChartValid } from 'components/DashboardGrid/validation';
import BasicModal from 'components/Modals/presets/BasicModal';
import RTDVWizard from 'components/Wizards/RTDV';
import useWizardInModal from 'components/Wizards/shared/hooks/useWizardInModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const ChartModal = (props) => {
  const {
    existing = {},
    onConfirm,
    hideModal,
    objectId,
    contacts,
    model,
    hasAccess = true,
    homepage,
    deletedFieldsRef,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [modalSize, setModalSize] = useState('medium');

  const { modalProps, wizardProps } = useWizardInModal(
    (data) => onConfirm(data),
    (state) => isChartValid(state),
    existing
  );

  const isEditing = Object.keys(existing).length > 0;

  const clientObject = useSelector(getBusinessClientObject);

  const { clicked, handleClickConfirm } = useModalClickedState(
    modalProps?.onConfirm
  );

  return (
    <BasicModal
      defaultLeftBtnText={hasAccess ? t('Cancel') : undefined}
      leftBtn={hasAccess ? undefined : false}
      buttonText={hasAccess ? (isEditing ? t('Save') : t('Add')) : t('Close')}
      actionBtnColor={hasAccess ? 'green' : 'blue'}
      className="no-drag"
      fitContent={hasAccess ? false : 'y'}
      heading={isEditing ? t('Edit Chart') : t('Add New Chart')}
      size={hasAccess ? modalSize : undefined}
      {...modalProps}
      disabled={(hasAccess ? modalProps.disabled : false) || clicked}
      typeOfContent={hasAccess ? modalProps.typeOfContent : 'text'}
      onConfirm={hasAccess ? handleClickConfirm : hideModal}
      animateSizeChange
      {...rest}
    >
      {hasAccess ? (
        <RTDVWizard
          existing={existing}
          objectId={objectId}
          contacts={contacts}
          setModalSize={setModalSize}
          modalSize={modalSize}
          model={model}
          clientObjectId={clientObject?.id}
          homepage={homepage}
          deletedFieldsRef={deletedFieldsRef}
          isEditing={isEditing}
          {...wizardProps}
        />
      ) : (
        <KizenTypography>
          {t(
            'You must have permission to view the data for all field(s) in this chart to edit'
          )}
        </KizenTypography>
      )}
    </BasicModal>
  );
};

export default ChartModal;
