import { FIELD_TYPES } from 'utility/constants';

export const FIELDS_WITH_OPTIONS = [
  FIELD_TYPES.Dropdown.type,
  FIELD_TYPES.Checkboxes.type,
  FIELD_TYPES.Radio.type,
  FIELD_TYPES.Status.type,
  FIELD_TYPES.YesNoMaybe.type,
];

export const FIELD_MARGIN = 15 / 2;
