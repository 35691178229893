import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { AREA_RESPONSES, VALUE_METRICS } from 'components/Wizards/RTDV/types';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import useCustomObjectDetails from 'components/Wizards/shared/hooks/useCustomObjectDetails';
import { useGroupedFields } from 'components/Wizards/shared/hooks/useGroupedFields';
import {
  ButtonWrapper,
  PivotDropPlaceholder,
  StyledColumnWrapper,
  StyledSummarySelector,
  BreakDownContainer,
  RightAligned,
} from '../styles';
import TitleField from 'components/Wizards/Dashlet/sections/DisplaySettings/fields/title';
import { getDefaultName } from 'components/Wizards/utils';
import { gutters } from 'app/spacing';
import ButtonGroup from 'components/Inputs/ButtonGroup';
import Loader from 'components/Kizen/Loader';
import Select from 'components/Inputs/Select';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import { StyledDragItem } from 'components/MultiColumnBuilder/components/BaseOption/styles';
import { PivotTableBuilder } from './PivotTableBuilder';
import {
  DEFAULT_FIELD_NAMES,
  FIELD_TYPES,
  numericFieldTypeFilter,
} from 'utility/constants';
import { Typography } from '@kizen/kds/Typography';
import ValueBreakdown from 'components/Wizards/shared/components/BreakdownAndBucketing/ValueBreakdown';
import { dateFieldTypeFilter } from 'utility/constants';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { isEqual, isEmpty } from 'lodash';
import {
  isFirstNameField,
  isLastNameField,
  isPercentageChanceToCloseField,
} from 'checks/fields';

const allowedFieldTypes = [
  FIELD_TYPES.Checkbox.type,
  FIELD_TYPES.Checkboxes.type,
  FIELD_TYPES.Dropdown.type,
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Relationship.type,
  FIELD_TYPES.TeamSelector.type,
  FIELD_TYPES.Dropdown.type,
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Radio.type,
  FIELD_TYPES.YesNoMaybe.type,
  FIELD_TYPES.Status.type,
  FIELD_TYPES.Integer.type,
  FIELD_TYPES.Decimal.type,
  FIELD_TYPES.Price.type,
  FIELD_TYPES.Money.type,
  FIELD_TYPES.Date.type,
  FIELD_TYPES.DateTime.type,
];

const unsupportedNumberFieldTypes = [
  DEFAULT_FIELD_NAMES.percentageChanceToClose,
  DEFAULT_FIELD_NAMES.updated,
];

const includeFullName = false;

export const PivotTableReportType = ({
  objectId,
  contacts,
  mobile,
  active,
}) => {
  const { state, setState, model, entityName } = useContext(
    WizardStateContext.Context
  );
  const {
    reportType,
    name,
    pivotTableColumns: stateColumns,
    pivotTableRows: stateRows,
    metric,
    metricField: stateMetricField,
    rowCustomLabels,
    rowDateFrequencies = {},
    columnDateFrequency,
    pivotTableRowBreakdown,
    pivotTableColumnBreakdown,
    deletedRows: stateIsRowDeleted,
    deletedColumn: stateIsColumnDeleted,
    fieldToAggregateLabel,
  } = state;
  const { t } = useTranslation();
  const [initialRows, setInitialRows] = useState(stateRows);
  const [initialColumns, setInitialColumns] = useState(stateColumns);
  const previousSelectedRows = useRef([]);
  const intialScrollRef = useRef(true);
  const { data, isLoading } = useCustomObjectDetails({
    objectId,
    contacts,
    enabled: reportType?.value === AREA_RESPONSES.PIVOT_TABLE,
  });

  const fieldsById = useMemo(() => {
    const fieldMap =
      data?.fields?.reduce((acc, field) => {
        acc[field.id] = field;
        return acc;
      }, {}) ?? {};

    if (contacts && includeFullName) {
      return {
        fullName: {
          id: 'fullName',
          displayName: t('Full Name'),
          name: 'full_name',
        },
        ...fieldMap,
      };
    }

    return fieldMap;
  }, [data, t, contacts]);

  const deletedRows = useMemo(() => {
    return initialRows?.reduce((acc, row, index) => {
      if (!fieldsById[row?.id] && index === 0) {
        acc[0] = row;
      } else if (!fieldsById[row?.id] && index === 1) {
        acc[1] = row;
      }
      return acc;
    }, []);
  }, [fieldsById, initialRows]);

  const deletedColumn = useMemo(() => {
    return initialColumns?.reduce((acc, column) => {
      if (!fieldsById[column?.id]) {
        acc.push(column);
        return acc;
      }
      return acc;
    }, []);
  }, [fieldsById, initialColumns]);

  const combinedDeletedRows = useMemo(() => {
    return initialRows?.reduce((acc, row, index) => {
      if (!fieldsById[row?.id]) {
        acc[index] = undefined;
      } else {
        acc[index] = row;
      }
      return acc;
    }, []);
  }, [fieldsById, initialRows]);

  const combinedDeletedColumns = useMemo(() => {
    return initialColumns?.reduce((acc, column, index) => {
      if (!fieldsById[column?.id]) {
        acc[index] = undefined;
      } else {
        acc[index] = column;
      }
      return acc;
    }, []);
  }, [fieldsById, initialColumns]);

  const categoriesById = useMemo(() => {
    return data?.fieldCategories?.reduce((acc, category) => {
      acc[category.id] = category;
      return acc;
    }, {});
  }, [data]);

  const metricField = useMemo(() => {
    if (!stateMetricField?.id) {
      return undefined;
    }

    if (stateMetricField?.label) {
      return stateMetricField;
    }

    return {
      ...stateMetricField,
      label: fieldToAggregateLabel
        ? fieldToAggregateLabel
        : fieldsById[stateMetricField?.id]?.displayName ?? '',
    };
  }, [fieldToAggregateLabel, stateMetricField, fieldsById]);

  const columns = useMemo(() => {
    return (
      stateColumns
        ?.filter((sc) => Boolean(fieldsById[sc.id]))
        .map((sc) => {
          if (sc.label) {
            return sc;
          }

          return {
            ...sc,
            label: fieldsById[sc.id]?.displayName ?? '',
          };
        }) ?? []
    );
  }, [stateColumns, fieldsById]);

  const rows = useMemo(() => {
    return (
      stateRows
        ?.filter((sc) => Boolean(fieldsById[sc.id]))
        .map((sc) => {
          return {
            ...sc,
            label: fieldsById[sc.id]?.displayName ?? '',
          };
        }) ?? []
    );
  }, [stateRows, fieldsById]);

  const groupedFields = useGroupedFields(
    data?.fields?.filter(
      // We don't want first_name or last_name for contacts because we inject the full_name field
      (f) => !isFirstNameField(f) && !isLastNameField(f)
    ),
    data?.fieldCategories
  );

  // Fields need to be sorted so the internal state of the builder
  // knows where to place items that are removed
  const fieldOrders = useMemo(() => {
    return (data?.fields || []).reduce((acc, field, index) => {
      return {
        ...acc,
        [field.id]: index,
      };
    }, {});
  }, [data]);

  const fieldOptions = useMemo(() => {
    let options = groupedFields
      ?.filter(({ options }) => options.length)
      .map((group) => ({
        id: categoriesById[group.id]?.id,
        isGroupedItem: true,
        label: group.label,
        order: categoriesById[group.id]?.order,
        items: group.options.reduce((acc, item) => {
          const field = fieldsById[item?.value];
          return acc.concat({
            label: field.label || field.displayName,
            id: field.id,
            fieldId: field.id,
            icon: '',
            sortable: true,
            fieldType: field.fieldType,
            disabled: false,
          });
        }, []),
      }))
      .flatMap((group) => {
        return [{ ...group }, ...group.items];
      })
      .filter(({ fieldType, isGroupedItem, id }) => {
        return (
          isGroupedItem ||
          (fieldType &&
            allowedFieldTypes.includes(fieldType) &&
            !unsupportedNumberFieldTypes.includes(fieldsById[id]?.name))
        );
      });
    if (contacts && includeFullName) {
      options = [{ id: 'fullName', label: t('Full Name') }, ...options];
    }
    return options.sort((a, b) => {
      return fieldOrders[a.id] - fieldOrders[b.id];
    });
  }, [contacts, t, groupedFields, fieldsById, categoriesById, fieldOrders]);

  const numberFieldOptions = useMemo(() => {
    const options = groupedFields.reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.options
          .filter((o) => {
            const field = fieldsById[o.value];

            if (isPercentageChanceToCloseField(field)) {
              return false;
            }
            return numericFieldTypeFilter(field);
          })
          .map((o) => {
            return {
              label: o.label,
              id: o.value,
              value: o.value,
            };
          }),
      ];
    }, []);

    return options;
  }, [groupedFields, fieldsById]);

  const deletedMetricField = useMemo(
    () =>
      metricField && !numberFieldOptions.find((f) => f.id === metricField?.id),
    [numberFieldOptions, metricField]
  );

  useEffect(() => {
    if (deletedRows?.length > 0) {
      setState('deletedRows', true);
    }
    if (deletedColumn?.length > 0) {
      setState('deletedColumn', true);
    }
    if (deletedMetricField) {
      setState('deletedMetricField', true);
    }
  }, [deletedRows, deletedColumn, deletedMetricField, setState]);

  const handleChangeColumns = useCallback(
    (value = []) => {
      setState(
        'pivotTableColumns',
        value.map((v) => {
          return {
            id: v.id,
            label: v.label,
          };
        })
      );
      // intial columns state is seperated from context to merge deleted columns with non deleted columns
      setInitialColumns(value);

      const hasDeletedField = value.some((newRow) =>
        deletedColumn?.includes((row) => row?.id === newRow?.id)
      );
      const hasDateBreakdown = value.some((row) =>
        dateFieldTypeFilter(fieldsById[row?.fieldId || row?.id])
      );
      const hasNumericField = value.some((row) =>
        numericFieldTypeFilter(fieldsById[row?.fieldId || row?.id])
      );
      if (!hasDateBreakdown) {
        setState('columnDateFrequency', undefined);
      }
      if (!hasNumericField) {
        setState('columnValueBreakdownError', undefined);
      }
      if (!hasDeletedField) {
        setState('deletedColumn', undefined);
      } else {
        setState('deletedColumn', true);
      }
      setState('pivotTableColumnBreakdown', undefined);
    },
    [setState, deletedColumn, fieldsById]
  );

  const handleChangeRows = useCallback(
    (value = [], isItemRemoved) => {
      setState(
        'pivotTableRows',
        value.map((v) => {
          return {
            id: v.id,
            label: v.label,
          };
        })
      );

      // intial rows state is seperated from context to merge deleted rows with non deleted rows
      setInitialRows(value);

      const hasDateBreakdown = value.some((row) =>
        dateFieldTypeFilter(fieldsById[row?.fieldId || row?.id], true)
      );
      const hasDeletedField = value.some((newRow) => {
        return deletedRows?.find((row) => row?.id === newRow?.id);
      });
      let mainRowhasNumericField = false;
      let subRowhasNumericField = false;
      if (value.length >= 1) {
        mainRowhasNumericField = numericFieldTypeFilter(
          fieldsById[value[0]?.fieldId || value[0]?.id]
        );
      } else if (value.length >= 2) {
        subRowhasNumericField = numericFieldTypeFilter(
          fieldsById[value[1]?.fieldId || value[1]?.id]
        );
      }
      let updatedCustomLabels = rowCustomLabels;
      let updatedRowBreakdown = pivotTableRowBreakdown;
      const rowIds = value.map((v) => v.id);

      updatedRowBreakdown &&
        Object.keys(updatedRowBreakdown)?.forEach((key) => {
          if (!rowIds?.includes(key) && isItemRemoved) {
            delete updatedRowBreakdown[key];
          }
        });

      if (value.length === 0) {
        updatedRowBreakdown = undefined;
        updatedCustomLabels = {};
      } else {
        value.forEach((row) => {
          if (updatedRowBreakdown && !updatedRowBreakdown?.[row?.id]) {
            delete updatedRowBreakdown[row?.id];
          }
          if (
            updatedCustomLabels &&
            updatedCustomLabels?.[row?.id] === undefined
          ) {
            delete updatedCustomLabels[row?.id];
          }
        });
      }
      if (!hasDeletedField) {
        setState('deletedRows', undefined);
      } else {
        setState('deletedRows', true);
      }
      if (!hasDateBreakdown) {
        setState('rowDateFrequencies', undefined);
      }
      if (!mainRowhasNumericField && isItemRemoved) {
        setState('rowValueBreakdownError', undefined);
      }
      if ((!subRowhasNumericField, isItemRemoved)) {
        setState('subRowValueBreakdownError', undefined);
      }
      if (!isEqual(rowCustomLabels, updatedCustomLabels)) {
        setState('rowCustomLabels', updatedCustomLabels);
        setState('rowCustomLabelsError', false);
      }
      setState('pivotTableRowBreakdown', updatedRowBreakdown);
      previousSelectedRows.current = rowIds;
    },
    [setState, fieldsById, deletedRows, pivotTableRowBreakdown, rowCustomLabels]
  );

  const handleRowBreakdownChange = useCallback(
    (breakdown, isErrored, rowId, isSubRow) => {
      const updatedBreakdowns = {
        ...pivotTableRowBreakdown,
        [rowId]: breakdown,
      };
      if (!isEqual(pivotTableRowBreakdown, updatedBreakdowns)) {
        setState('pivotTableRowBreakdown', updatedBreakdowns);
      }
      if (isSubRow) {
        setState('subRowValueBreakdownError', isErrored);
      } else {
        setState('rowValueBreakdownError', isErrored);
      }
    },
    [setState, pivotTableRowBreakdown]
  );

  const handleMetricFieldChange = useCallback(
    (metricField) => {
      setState('metricField', metricField);
      setState('deletedMetricField', undefined);
      setState('fieldToAggregateLabel', undefined);
    },
    [setState]
  );

  const handleMetricChange = useCallback(
    (metric) => {
      if (metric?.value === 'record_count') {
        setState('deletedMetricField', undefined);
        setState('metricField', undefined);
        setState('fieldToAggregateLabel', undefined);
      }
      setState('metric', metric);
    },
    [setState]
  );

  const handleColumnBreakdownChange = useCallback(
    (breakdown, isErrored) => {
      setState('pivotTableColumnBreakdown', breakdown);
      setState('columnValueBreakdownError', isErrored);
    },
    [setState]
  );

  const defaultName = useMemo(() => {
    return getDefaultName(
      {
        ...state,
        metricField,
      },
      t
    );
  }, [state, metricField, t]);

  const onChangeName = useCallback(
    (value) => {
      return setState('name', value);
    },
    [setState]
  );

  const recordCountOption = useMemo(() => {
    return {
      value: VALUE_METRICS.COUNT,
      label: t('Record Count'),
    };
  }, [t]);

  const metricOptions = useMemo(() => {
    const result = [recordCountOption];

    if (numberFieldOptions.length) {
      result.push({
        value: VALUE_METRICS.NUMBER_FIELD,
        label: t('Number Field Sum'),
      });
    }

    return result;
  }, [t, recordCountOption, numberFieldOptions]);

  useDefaultValues(
    {
      metric: recordCountOption,
    },
    active
  );

  const setRowCustomLabels = useCallback(
    (id, value) => {
      setState('rowCustomLabels', {
        ...rowCustomLabels,
        [id]: value,
      });
      if (isEmpty(value) || !value.replace(/\s/g, '').length) {
        setState('rowCustomLabelsError', true);
      } else {
        setState('rowCustomLabelsError', false);
      }
    },
    [setState, rowCustomLabels]
  );

  const setRowDateFrequency = useCallback(
    ({ id, value }) => {
      const updatedFrequencies = {
        ...rowDateFrequencies,
        [id]: value,
      };
      if (!isEqual(rowDateFrequencies, updatedFrequencies)) {
        setState('rowDateFrequencies', updatedFrequencies);
      }
    },
    [setState, rowDateFrequencies]
  );

  const getCurrencySymbol = useCallback((field) => {
    return field?.moneyOptions?.symbol;
  }, []);

  const isDecimal = useCallback((field) => {
    return (
      field?.fieldType === FIELD_TYPES.Decimal.type ||
      field?.fieldType === FIELD_TYPES.Money.type
    );
  }, []);

  const isNumericFieldType = useCallback(
    (field) => numericFieldTypeFilter(field),
    []
  );

  const hasNumericField = useCallback(
    (data) => {
      return data && data?.find(({ id }) => isNumericFieldType(fieldsById[id]));
    },
    [fieldsById, isNumericFieldType]
  );

  const showRowValueBreakdown = useMemo(
    () => rows.find(({ id }) => isNumericFieldType(fieldsById[id])) ?? false,
    [rows, fieldsById, isNumericFieldType]
  );

  const showColumnValueBreakdown = useMemo(
    () => columns.find(({ id }) => isNumericFieldType(fieldsById[id])) ?? false,
    [columns, fieldsById, isNumericFieldType]
  );

  const { rowError, columnError, metricError } = useMemo(
    () => ({
      rowError: deletedRows?.length > 0 && stateIsRowDeleted,
      columnError: deletedColumn?.length > 0 && stateIsColumnDeleted,
      metricError: deletedMetricField && fieldToAggregateLabel,
    }),
    [
      deletedRows,
      deletedColumn,
      deletedMetricField,
      stateIsRowDeleted,
      stateIsColumnDeleted,
      fieldToAggregateLabel,
    ]
  );

  const scrollRowBuilderToView = useCallback(
    (el) => {
      intialScrollRef.current &&
        rowError &&
        el?.scrollIntoView({ behavior: 'smooth' });
      if (!rowError && !columnError && !metricError) {
        setTimeout(() => (intialScrollRef.current = false), 200);
      }
    },
    [rowError, columnError, metricError]
  );

  const scrollColumnBuilderToView = useCallback(
    (el) => {
      intialScrollRef.current &&
        rows.length > 0 &&
        columnError &&
        !rowError &&
        el?.scrollIntoView({ behavior: 'smooth' });
      if (!rowError && !columnError && !metricError) {
        setTimeout(() => (intialScrollRef.current = false), 200);
      }
    },
    [rows, columnError, rowError, metricError]
  );

  const scrollMetricToView = useCallback(
    (el) => {
      intialScrollRef.current &&
        columns.length > 0 &&
        metricError &&
        !columnError &&
        !rowError &&
        el?.scrollIntoView({ behavior: 'smooth' });
      if (!rowError && !columnError && !metricError) {
        setTimeout(() => (intialScrollRef.current = false), 200);
      }
    },
    [columns, metricError, columnError, rowError]
  );

  const disableRowDropZoneText = useMemo(
    () =>
      deletedRows?.length > 0
        ? t('Remove Deleted Field Before Modifying')
        : t('Maximum of 2 rows. Please remove a row before adding another.'),
    [deletedRows, t]
  );

  const disableColumnDropZoneText = useMemo(
    () =>
      deletedColumn?.length > 0
        ? t('Remove Deleted Field Before Modifying')
        : t('Maximum of 1 column. Please remove column before adding another.'),
    [deletedColumn, t]
  );

  if (reportType?.value !== AREA_RESPONSES.PIVOT_TABLE) {
    return null;
  }

  return (
    <StyledWizardField flex top={10}>
      <StyledColumnWrapper>
        <KizenTypography type="subheader">{t('Modify Pivots')}</KizenTypography>
        <StyledWizardField flex top={16} />
        <Typography ref={scrollRowBuilderToView}>
          {t('Set Rows (2 Maximum)')}
        </Typography>
        {!isLoading && (
          <>
            <PivotTableBuilder
              testId="row-builder"
              initialLeftColumnItems={fieldOptions}
              initialRightColumnItems={rows}
              deletedItems={deletedRows}
              deletedItemsOrder={combinedDeletedRows}
              onChange={handleChangeRows}
              model={model}
              contacts={contacts}
              lockedFields={[]}
              skipSorting
              rightHeaderText={t('Selected Row(s)')}
              leftHeaderText={t('Available Rows (Drag to Add)')}
              disabledDropZones={
                rows.length > 1 || stateIsRowDeleted ? ['active-columns'] : []
              }
              disabledDropZoneText={disableRowDropZoneText}
              rollBackDrop={rows.length > 1 || stateIsRowDeleted}
              renderRightEmpty={({ dropZone, id }) => {
                const over = dropZone?.sectionId === id;
                return (
                  <PivotDropPlaceholder
                    isChildDragging={false}
                    margin={0}
                    over={over}
                  >
                    <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
                      <KizenTypography>
                        {over
                          ? t('Place Here')
                          : t(
                              'No Row Currently Selected (Drag and Drop to Add)'
                            )}
                      </KizenTypography>
                    </StyledDragItem>
                  </PivotDropPlaceholder>
                );
              }}
              onChangeCustomLabel={setRowCustomLabels}
              onDateFrequencyChange={setRowDateFrequency}
              rowDateFrequencies={rowDateFrequencies}
              customLabels={rowCustomLabels}
              allowRenaming
              showCategoryOnSearch={false}
              grouped
            />
            <StyledWizardField flex top={20} />
            {showRowValueBreakdown
              ? rows?.map(({ id }, index) => {
                  const field = fieldsById[id];
                  const breakdown = pivotTableRowBreakdown?.[id];
                  if (hasNumericField([field])) {
                    return (
                      <BreakDownContainer key={id}>
                        <RightAligned>
                          <TextEllipsisWithTooltip>
                            {`${field?.displayName} ${t('Value Breakdowns')}`}
                          </TextEllipsisWithTooltip>
                          <ValueBreakdown
                            integer={!isDecimal(field)}
                            field={field}
                            currencySymbol={getCurrencySymbol(field)}
                            onChange={handleRowBreakdownChange}
                            rowId={id}
                            key={field?.id}
                            minWidth
                            initial={breakdown}
                            compact
                            testId="row-value-breakdown"
                            subRow={index === 1}
                          />
                        </RightAligned>
                      </BreakDownContainer>
                    );
                  }
                  return null;
                })
              : null}
            <StyledWizardField flex top={20} />
            <Typography ref={scrollColumnBuilderToView}>
              {t('Set Columns (1 Maximum)')}
            </Typography>
            <PivotTableBuilder
              testId="column-builder"
              initialLeftColumnItems={fieldOptions}
              initialRightColumnItems={columns}
              deletedItems={deletedColumn}
              deletedItemsOrder={combinedDeletedColumns}
              onChange={handleChangeColumns}
              model={model}
              objectDetails={data}
              contacts={contacts}
              lockedFields={[]}
              skipSorting
              showCategoryOnSearch={false}
              grouped
              rightHeaderText={t('Selected Column')}
              leftHeaderText={t('Available Columns (Drag to Add)')}
              disabledDropZones={
                columns.length > 0 || stateIsColumnDeleted
                  ? ['active-columns']
                  : []
              }
              disabledDropZoneText={disableColumnDropZoneText}
              rollBackDrop={columns.length > 0 || stateIsColumnDeleted}
              onDateFrequencyChange={(value) =>
                setState('columnDateFrequency', value)
              }
              columnDateFrequency={columnDateFrequency}
              renderRightEmpty={({ dropZone, id }) => {
                const over = dropZone?.sectionId === id;
                return (
                  <PivotDropPlaceholder
                    isChildDragging={false}
                    margin={0}
                    over={over}
                  >
                    <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
                      <KizenTypography>
                        {over
                          ? t('Place Here')
                          : t(
                              'No Column Currently Selected (Drag and Drop to Add)'
                            )}
                      </KizenTypography>
                    </StyledDragItem>
                  </PivotDropPlaceholder>
                );
              }}
            />
            <StyledWizardField flex top={20} />
            {showColumnValueBreakdown
              ? columns?.map(({ id }) => {
                  const field = fieldsById[id];
                  return (
                    <BreakDownContainer key={id}>
                      <RightAligned>
                        <TextEllipsisWithTooltip>
                          {`${field?.displayName} ${t('Value Breakdowns')}`}
                        </TextEllipsisWithTooltip>
                        <ValueBreakdown
                          integer={!isDecimal(field)}
                          field={field}
                          currencySymbol={getCurrencySymbol(field)}
                          onChange={handleColumnBreakdownChange}
                          key={field?.id}
                          initial={pivotTableColumnBreakdown}
                          compact
                          testId="column-value-breakdown"
                        />
                      </RightAligned>
                    </BreakDownContainer>
                  );
                })
              : null}
            <StyledWizardField flex top={30} data-qa="pivot-table-metric">
              <KizenTypography type="subheader">
                {t('Pivot Summary Metric')}
              </KizenTypography>
              <StyledWizardField top={16} flex>
                <StyledSummarySelector ref={scrollMetricToView}>
                  <ButtonWrapper>
                    <ButtonGroup
                      value={metric}
                      onChange={handleMetricChange}
                      sizing={!mobile ? 'auto' : ''}
                      options={metricOptions}
                      className="wizard-buttons metric-to-display"
                      columns={mobile ? 2 : 4}
                      mode="wizard"
                      gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
                    />
                  </ButtonWrapper>
                  {metric?.value === VALUE_METRICS.NUMBER_FIELD && (
                    <Select
                      className="metric-wizard-select"
                      options={numberFieldOptions}
                      onChange={handleMetricFieldChange}
                      placeholder={t('Choose Field')}
                      value={metricField}
                      error={deletedMetricField}
                    />
                  )}
                </StyledSummarySelector>
              </StyledWizardField>
            </StyledWizardField>
            <TitleField
              defaultName={defaultName}
              name={name}
              onChangeName={onChangeName}
              top={30}
              entityName={entityName}
              flex
            />
          </>
        )}
        <Loader loading={isLoading} />
      </StyledColumnWrapper>
    </StyledWizardField>
  );
};
