import { FIELD_TYPES } from '../internal/utility/constants';

type TFunction = (key: string) => string;

type DefinePlaceholderOpts = {
  fieldType: string;
  placeholder?: string;
  label?: string;
};

export const definePlaceholder = (
  { fieldType, placeholder = '', label = '' }: DefinePlaceholderOpts,
  t: TFunction
) => {
  const fieldWithPlaceholder = Object.values(FIELD_TYPES).find(
    ({ type }: any) => type === fieldType
  ) as any;

  return (
    placeholder ||
    label ||
    fieldWithPlaceholder?.localizedPlaceholder?.(t, {
      getCommonSelectPlaceholder: [FIELD_TYPES.Timezone.type].includes(
        fieldType
      ),
    }) ||
    fieldWithPlaceholder?.placeholder ||
    null
  );
};
