import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import { PageToolbarTitle } from 'components/Layout/PageToolbar';
import { TRow } from 'components/Kizen/Table';
import BasicTable from 'components/Tables/Basic';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import {
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
  TBSearchInput,
  TableCard,
  GradientZindex1,
} from '../styles';
import { getColumns } from '../columns';
import { StartAutomationButton } from '../StartAutomationButton';
import {
  ScrollContainerBlockerWrapper,
  ScrollContainerBlocker,
} from 'components/Tables/ScrollContainerStyles';
import { useAutomationPermissions } from 'ts-components/hooks/permissions/automations';

const DesktopView = ({
  executions,
  pageNameSingular,
  pageName,
  search,
  onHandleChangeSearch,
  headData,
  onUpdateAutomation,
  onStartAutomation,
  loading,
  totalCount = 0,
  customObjectId,
  entity,
  isClient,
}) => {
  const { t } = useTranslation();

  const { canStart, canManage } = useAutomationPermissions({
    objectId: customObjectId,
    entityObject: entity,
    isClient,
  });

  const scrollContainerRef = useRef(null);

  const columns = useMemo(
    () =>
      getColumns(
        {
          onUpdateAutomation,
          canManageAutomations: canManage,
          scrollContainerRef,
          canStart,
        },
        t
      ),
    [onUpdateAutomation, canStart, canManage, t]
  );

  return (
    <ContentWidth>
      <Toolbar>
        <ToolbarLeft>
          <TBSearchInput
            placeholder={`${t('Find')} ${pageName}`}
            value={search}
            onChange={onHandleChangeSearch}
            endAdornment={<IconAdornment icon="search" />}
          />
        </ToolbarLeft>

        <PageToolbarTitle
          count={totalCount}
          single={pageNameSingular}
          plural={pageName}
        />
        <ToolbarRight>
          {canStart ? (
            <StartAutomationButton
              onStartAutomation={onStartAutomation}
              alignRight
              customObjectId={customObjectId}
            />
          ) : null}
        </ToolbarRight>
      </Toolbar>
      <div>
        <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
          <ScrollContainerBlocker />
        </ScrollContainerBlockerWrapper>
        <TableCard className="table-card">
          <TableScrollContainer
            gradient={<GradientZindex1 />}
            left
            right
            bottom
            refresh={[executions]}
            ref={scrollContainerRef}
          >
            <BasicTable
              stickyHeader
              head={<TRow head columns={columns} data={headData} />}
              staleData={loading}
            >
              {!executions.length && <tr />}
              {executions.map((automation) => (
                <TRow key={automation.id} columns={columns} data={automation} />
              ))}
            </BasicTable>
          </TableScrollContainer>
        </TableCard>
      </div>
    </ContentWidth>
  );
};

export default DesktopView;
