/// The remnants of the old filters that were not deleted.

// components/Wizards/Filters/types.js
export const FILTER_TYPES = {
  CUSTOM: 'custom_filter',
  IN_GROUP: 'in_group',
  NOT_IN_GROUP: 'not_in_group',
};

export const isGroupFilter = (type: any) => {
  return [FILTER_TYPES.IN_GROUP, FILTER_TYPES.NOT_IN_GROUP].includes(type);
};

export const isCustomFilter = (type: any) => {
  return type === FILTER_TYPES.CUSTOM;
};

export const isInGroupFilter = (type: any) => {
  return type === FILTER_TYPES.IN_GROUP;
};

export const isNotInGroupFilter = (type: any) => {
  return type === FILTER_TYPES.NOT_IN_GROUP;
};

export const isGroupInvalid = (groups: any) => {
  if (!groups || groups.length === 0) {
    return true;
  }

  return groups.some(({ deleted, invalid }: any) => deleted || invalid);
};

// pages/CustomObjects/RecordsPage/filters/configs/constants.js
const STEPS = {
  TYPE: 'type',
  CONDITION: 'condition',
  FIELD: 'field',
  VALUE: 'value',

  // team interactions
  INTERACTED: 'interacted',
  SUBTYPE: 'subtype',
  TIME_PAST: 'timePast',
  PAST_DAYS: 'pastNDays',
  WITH: 'with',

  // forms
  FORMS_FORM_ID: 'formId',
  FORMS_SUBMITTED: 'submittedCondition',
  FORMS_DAYS: 'nDaysValue',
  FORMS_SUBMISSION: 'submission',
  FORMS_FIELD: 'fieldId',
  FORMS_CONDITION: 'condition',
  FORMS_VALUE: 'value',

  // activities
  ACTIVITY_TYPE: 'activityType',
  ACTIVITY_OBJECT_ID: 'activityObjectId',
  ACTIVITIES_SUBTYPE: 'activitySubtype',
  ACTIVITIES_DAYS: 'days',
  ACTIVITIES_TEAM_MEMBER: 'activityTeamMember',
  ACTIVITIES_WHICH: 'which',

  //scheduled activities
  ACTIVITIES_WHICH_ASSIGNED_ID: 'assignedId',
  SCHEDULED_CONDITION: 'scheduledCondition',
  ASSIGNED_CONDITION: 'assignedCondition',
  SCHEDULED_ACTIVITIES_DAYS: 'nDays',

  // lead sources
  SOURCE: 'sourceType',
  SOURCE_CONDITION: 'sourceCondition',
  SOURCE_VALUE: 'sourceValue',

  // related object
  RELATIONSHIP_FIELD: 'relationshipField',
  RELATIONSHIP_CONDITION: 'relationshipCondition',
  RELATIONSHIP_GROUP: 'relationshipGroup',
  RELATIONSHIP_FILTER_TYPE: 'relationshipFilterType',

  // automation
  AUTOMATION_ID: 'automationId',
  AUTOMATION_STATUS: 'status',

  // message
  MESSAGE_SEARCH_TYPE: 'messageSearchType',
  MESSAGE_IDS: 'messageIds',
  MESSAGE_STATUS: 'messageStatus',
  MESSAGE_PAST: 'past',
  MESSAGE_DAYS: 'lastNDays',

  // stage
  COMPARISON_CONDITION: 'comparisonCondition',
  COMPARISON_VALUE: 'comparisonValue',
  COMPARISON_TYPE: 'comparisonType',
};

// pages/CustomObjects/RecordsPage/filters/configs/common.js
export const initialFilterElement = {
  id: 'filter-0',
  step: STEPS.TYPE, // step must be matched with property in values object
  values: {},
  options: [],
  fetchOptions: false,
  validate: {
    error: false,
    errorMessageIdentifier: 'finishSettings',
    loadError: false,
    loadErrorMessage: null,
  },
  component: 'DefaultComponent',
  select: {
    styles: { container: {} },
    placeholderIdentifier: 'ChooseFilter',
    optionsIdentifier: 'CustomObjectEntityRecord.filterTypes',
  },
  children: [],
  dataQA: {
    id: STEPS.TYPE,
    type: 'dropdown',
  },
};

export const initialQueryElement = {
  id: 'query-0',
  and: false,
  filters: [initialFilterElement],
};
