import { getCookie } from 'utility/getCookie';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ssoLoginStart } from 'store/authentication/authenticationAction';
import * as auth from 'utility/Authentication';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { getSearchParam } from 'hooks/useSearchParam';
import AuthenticationService from 'services/AuthenticationService';
import { loginSessionStorage } from 'utility/loginSessionStorage';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { RedirectLocalStorage } from 'utility/RedirectLocalStorage';

const KIZEN_SSO = 'saml_data';

const hasValidSSORedirect = (location) => {
  const success = getSearchParam(location, 'success');
  return success === 'true';
};

const hasValidRelayState = (location) => {
  const relaystate = (
    getSearchParam(location, 'RelayState') || ''
  ).toLowerCase();
  if (relaystate && relaystate.toLowerCase().startsWith('http')) {
    const origin = location.origin.toLowerCase();
    return relaystate.startsWith(origin)
      ? relaystate.replace(origin, '')
      : null;
  }

  return relaystate && relaystate.startsWith('/') ? relaystate : null;
};

const SsoLogin = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  const history = useHistory();
  const dispatch = useDispatch();
  useAsync(async () => {
    const validRedirect = hasValidSSORedirect(window.location);
    const cookie = getCookie(KIZEN_SSO + '');

    if (cookie === '' || !validRedirect) {
      await AuthenticationService.logout();
      auth.status.clear();
      history.push('/login');
    }

    auth.status.authorized = true;

    const [error, { chosenBusiness } = {}] = await dispatch(
      ssoLoginStart(loginSessionStorage.val)
    );

    if (error) {
      history.replace('/login');
    } else {
      const hasRelayState = hasValidRelayState(window.location);
      if (hasRelayState && !chosenBusiness) {
        // multiple businesses, save the redirect path for after business selection
        const localStorage = new RedirectLocalStorage('loginRedirect');
        localStorage.path = hasRelayState;
      } else {
        // we are loged in, with a valid business and have valid redirect
        localStorage.path = '';
      }
      const hasBusinessRedirect = hasRelayState ? hasRelayState : '/';

      history.replace(
        chosenBusiness ? hasBusinessRedirect : '/choose-business'
      );
    }
  }, [history, dispatch]);
  return null;
};

export default SsoLogin;
