import styled from '@emotion/styled';
import { ROOT_NODE } from '@kizen/page-builder';
import { areTreesEqual } from '@kizen/page-builder/utils/are-equal';
import { getDefaultStaticBlockContent } from '@kizen/page-builder/utils/build';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { breakpoints, isMobile, useWindowSize } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import Switch from '__components/Kizen/Switch';
import FullscreenModal from '__components/Modals/Fullscreen';
import TextInput from '__components/Inputs/TextInput';
import {
  ConfirmExitModal,
  PageBuilderCanvas,
  PageBuilderCanvasHeaderContainer,
  PageBuilderContainer,
  PageBuilderTray,
  PageBuilderHeader,
} from '__components/PageBuilder';

import {
  staticBlockCreatorElements,
  staticBlockNodes,
  staticBlockTrayItems,
  staticBlockNodeSettings,
} from 'components/PageBuilder/presets';
import { PageSettingsSection } from 'components/PageBuilder/settings/PageSettings';
import { ScriptExecutionOption } from 'components/StandaloneToolBar/components/ConfigureLinkModal';
import { TaskType } from 'components/WYSIWYG';
import { useObjectMergeFields } from 'components/WYSIWYG/use-merge-fields';
import { useModalControl } from 'hooks/useModalControl';
import { getGenerativeAIEntitlement } from 'store/authentication/selectors';
import { maybeTrackAIRequest } from 'utility/analytics';
import { useHomepageMergeFields } from './useHomepageMergeFields';

type JavaScriptAction = {
  id: string;
  name: string;
  script: string;
};

type StaticBlockBuilderProps = {
  objectId: string;
  objectName: string;
  objectType: 'contact' | 'standard' | 'pipeline';
  modalLayer: number;
  javascriptActions: JavaScriptAction[];
  onCancel(): void;
  onSave(data: any, internalName: string): void;
  onSaveAndClose(data: any, internalName: string): void;
  existing?: any;
  existingInternalName?: string;
  allowRelativeLinks?: boolean;
};

type HeaderControlsProps = {
  dropshadow: boolean;
  onDropshadowChange(dropshadow: boolean): void;
};

const HEADER_HEIGHT = 60;

const HeaderControlsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  height: inherit;
`;

const MobileContainer = styled.div`
  margin: 20px;
  text-align: center;
`;

const CenterContentContainer = styled.div`
  margin-left: 150px;
  width: 600px;
`;

const HeaderControls = ({
  dropshadow,
  onDropshadowChange,
}: HeaderControlsProps) => {
  const { t } = useTranslation();

  return (
    <HeaderControlsContainer>
      <Switch
        checked={dropshadow}
        label={t('Dropshadow')}
        textPlacement="left"
        removeMargin={true}
        onChange={(ev) => onDropshadowChange(ev.target.checked)}
      />
    </HeaderControlsContainer>
  );
};

const RootNodeSettings = () => {
  const { t } = useTranslation();
  return <PageSettingsSection title={t('Component Settings')} />;
};

export const StaticBlockBuilder = ({
  objectId,
  objectName,
  objectType,
  modalLayer,
  onCancel,
  onSave,
  onSaveAndClose,
  existing,
  existingInternalName,
  javascriptActions,
  allowRelativeLinks = false,
}: StaticBlockBuilderProps) => {
  const { t } = useTranslation();
  const [internalName, setInternalName] = useState(existingInternalName ?? '');
  const [init] = useState(getDefaultStaticBlockContent(t));
  const canvasRef = useRef<any>();
  const editsRef = useRef<any>(existing ?? init);
  const [isConfirmModalOpen, { showModal, hideModal }] = useModalControl();
  const [dropshadow, setDropshadow] = useState(
    existing?.ROOT?.props?.hasShadow ?? true
  );
  const { width } = useWindowSize() ?? { width: 0 };
  const mobileDisplay = isMobile(width, breakpoints.lg);
  const { data: objectMergeFields } = useObjectMergeFields(
    objectId,
    objectName,
    objectType
  );
  const homepageMergeFields = useHomepageMergeFields();
  const mergeFields = useMemo(() => {
    return objectMergeFields
      ? objectMergeFields.concat(homepageMergeFields)
      : homepageMergeFields;
  }, [objectMergeFields, homepageMergeFields]);
  const businessId = useSelector(
    (s: any) => s.authentication.chosenBusiness.id
  );
  const enableAI = useSelector(getGenerativeAIEntitlement);

  const handleDropshadowChange = useCallback((value: boolean) => {
    setDropshadow(value);
    canvasRef.current.editor.actions.setProp(ROOT_NODE, (p: any) => {
      p.hasShadow = value;
    });
  }, []);

  const handleSave = () => {
    const current = canvasRef.current.editor.query.getSerializedNodes();
    onSave(current, internalName);
    editsRef.current = current;
  };

  const handleSaveAndClose = () => {
    onSaveAndClose(
      canvasRef.current.editor.query.getSerializedNodes(),
      internalName
    );
  };

  const handleGoBack = () => {
    const current = canvasRef.current.editor.query.getSerializedNodes();
    if (!areTreesEqual(current, editsRef.current)) {
      showModal();
    } else {
      onCancel();
    }
  };

  const onAIRequestStart = useCallback(
    (requestType: TaskType) => {
      maybeTrackAIRequest('homepage', '', businessId, requestType);
    },
    [businessId]
  );

  return (
    <>
      {isConfirmModalOpen && (
        <ConfirmExitModal
          heading={t('Your Content Has Unsaved Changes')}
          onDiscard={() => onCancel()}
          onCancel={hideModal}
          onSave={handleSaveAndClose}
        >
          {t('All unsaved changes will be lost unless you save your content.')}
        </ConfirmExitModal>
      )}
      <FullscreenModal
        show
        enforceFocus={false}
        header={
          <PageBuilderHeader
            onGoBack={handleGoBack}
            onSave={handleSave}
            onSaveAndClose={handleSaveAndClose}
            centerContent={
              <CenterContentContainer>
                <TextInput
                  variant="underline"
                  label={t('Internal Block Name')}
                  value={internalName}
                  onChange={(name: string) => {
                    setInternalName(name);
                  }}
                />
              </CenterContentContainer>
            }
          />
        }
      >
        {mobileDisplay ? (
          <MobileContainer>
            <KizenTypography>
              {t(
                'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
              )}
            </KizenTypography>
          </MobileContainer>
        ) : (
          <PageBuilderContainer
            enableGoogleFonts
            enableTextLinks
            enableMergeFields
            enableScriptExecution
            mergeFields={mergeFields}
            nodes={staticBlockNodes}
            creatorElements={staticBlockCreatorElements}
            staticBlock={true}
            initialGridlinesActive={true}
            modalLayer={modalLayer}
            allowRelativeLinks={allowRelativeLinks}
            customObjectId={objectId}
            {...(Boolean(javascriptActions?.length) && {
              javascriptActions,
              textLinkOptions: [ScriptExecutionOption(t)],
            })}
            {...(Boolean(enableAI) && {
              enableAI: true,
              onAIRequestStart,
            })}
          >
            <PageBuilderCanvasHeaderContainer height={HEADER_HEIGHT}>
              <HeaderControls
                dropshadow={dropshadow}
                onDropshadowChange={handleDropshadowChange}
              />
            </PageBuilderCanvasHeaderContainer>
            <PageBuilderCanvas
              showPixelHeight
              ref={canvasRef}
              data={existing ?? init}
              headerHeight={HEADER_HEIGHT}
              showBoxShadow={false}
              canResizeHeight={true}
              minHeight={50}
            />
            <PageBuilderTray
              trayItems={staticBlockTrayItems}
              nodeSettings={staticBlockNodeSettings}
              RootNodeSettings={RootNodeSettings}
              canResize={false}
            />
          </PageBuilderContainer>
        )}
      </FullscreenModal>
    </>
  );
};
