import { Spacing, getGapClassName, getPaddingClassName } from '../spacing';
import { getKDSClasses, merge } from '../util';
import {
  COLUMN_SIZE,
  GridConfig,
  OneColumn,
  OneColumnRow,
  Row,
  ThreeColumnRow,
  ThreeColumns,
  TwoColumnRow,
  TwoColumns,
} from './types';
import { getColumnClassNames } from './util';
export { Column } from './Column';

export interface GridProps {
  config: GridConfig;
  gap: Spacing;
  padding?: Spacing;
  subgrid?: boolean;
}

type GridRowProps<T extends Row> = T & {
  gap: Spacing;
  subgrid: boolean;
};

const OneColumnRowChild = (props: GridRowProps<OneColumnRow>) => {
  const { content, layout, gap, subgrid } = props;

  const { column1ClassName } = getColumnClassNames(layout, gap, false, subgrid);

  return (
    <div
      className={merge(
        getKDSClasses('grid-row', 'one-column'),
        column1ClassName,
        'relative'
      )}
    >
      {content[0]}
    </div>
  );
};

const getResponsiveClassName = (hasOneThird: boolean, subgrid: boolean) => {
  if (hasOneThird && !subgrid) {
    return '@lg-alt/grid:flex-row';
  }
  if (subgrid) {
    return '@lg-sub/grid:flex-row';
  }
  return '@lg/grid:flex-row';
};

const TwoColumnRowChild = (props: GridRowProps<TwoColumnRow>) => {
  const { content, layout, gap, subgrid } = props;
  const hasOneThird = (layout as COLUMN_SIZE[]).includes(
    COLUMN_SIZE.THIRD_WIDTH
  );

  const { column1ClassName, column2ClassName } = getColumnClassNames(
    layout,
    gap,
    hasOneThird,
    subgrid
  );

  const gapClassName = getGapClassName(gap);

  const responsiveClassName = getResponsiveClassName(hasOneThird, subgrid);

  return (
    <div
      className={merge(
        getKDSClasses('grid-row', 'two-column'),
        'flex-col flex w-full relative',
        responsiveClassName,
        gapClassName
      )}
    >
      <div className={column1ClassName}>{content[0]}</div>
      <div className={column2ClassName}>{content[1]}</div>
    </div>
  );
};

const ThreeColumnRowChild = (props: GridRowProps<ThreeColumnRow>) => {
  const { content, layout, gap, subgrid } = props;
  const hasOneThird = layout.includes(COLUMN_SIZE.THIRD_WIDTH);

  const { column1ClassName, column2ClassName, column3ClassName } =
    getColumnClassNames(layout, gap, hasOneThird, subgrid);

  const gapClassName = getGapClassName(gap);

  const responsiveClassName = getResponsiveClassName(hasOneThird, subgrid);

  return (
    <div
      className={merge(
        getKDSClasses('grid-row', 'three-column'),
        'flex-col flex w-full relative',
        responsiveClassName,
        gapClassName
      )}
    >
      <div className={column1ClassName}>{content[0]}</div>
      <div className={column2ClassName}>{content[1]}</div>
      <div className={column3ClassName}>{content[2]}</div>
    </div>
  );
};

export const Grid = (props: GridProps) => {
  const { config, gap, padding = 0, subgrid = false } = props;

  const paddingClassName = getPaddingClassName(padding);
  const gapClassName = getGapClassName(gap);

  return (
    <div
      className={merge(
        getKDSClasses('grid', subgrid ? 'subgrid' : 'parent'),
        'flex flex-col @container/grid',
        gapClassName,
        paddingClassName
      )}
    >
      {config.map((configRow) => {
        if (configRow.content.length === 1) {
          return (
            <OneColumnRowChild
              key={configRow.id}
              content={configRow.content}
              layout={configRow.layout as OneColumn}
              id={configRow.id}
              gap={gap}
              subgrid={subgrid}
            />
          );
        }
        if (configRow.content.length === 2) {
          return (
            <TwoColumnRowChild
              key={configRow.id}
              content={configRow.content}
              layout={configRow.layout as TwoColumns}
              id={configRow.id}
              gap={gap}
              subgrid={subgrid}
            />
          );
        }
        if (configRow.content.length === 3) {
          return (
            <ThreeColumnRowChild
              key={configRow.id}
              content={configRow.content}
              layout={configRow.layout as ThreeColumns}
              id={configRow.id}
              gap={gap}
              subgrid={subgrid}
            />
          );
        }
        return null;
      })}
    </div>
  );
};
