import React from 'react';
import { KizenTypography } from 'app/typography';
import Button from 'components/Button';
import styled from '@emotion/styled';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

const StyledText = styled(KizenTypography)`
  margin: 0;
  padding: 0 10px;
`;
const StyledActiveText = styled(TextEllipsisWithTooltip)`
  margin: 0;
  padding: 0;
  letter-spacing: 0.8px;
`;
const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
`;

const Breadcrumbs = ({ handleChangeDirectory, directories }) => {
  return directories.map((elem, i) => (
    <>
      {elem !== directories[0] && <StyledText>{`>`}</StyledText>}
      {directories[directories.length - 1] !== elem ? (
        <StyledButton
          variant="text"
          noMinWidth
          color="blue"
          onClick={() => {
            handleChangeDirectory(elem);
          }}
        >
          {elem.name}
        </StyledButton>
      ) : (
        <StyledActiveText
          size="buttonLabel"
          textTransform="uppercase"
          as="p"
          weight="bold"
        >
          {elem.name}
        </StyledActiveText>
      )}
    </>
  ));
};

export default Breadcrumbs;
