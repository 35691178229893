import store from 'store';
import { getChosenBusiness } from 'store/authentication/selectors';
import DraggableStepCard from '../../DraggableStepCard';
import { getSendEmailFromString, getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  const { details } = step;
  const emptyString = details?.email?.type ? '' : t('[Deleted]');
  const subject = details?.email?.subject || emptyString;

  const state = store.getState();

  const sender = details?.sendFromOwner
    ? t('Entity Record Owner')
    : getSendEmailFromString(details, getChosenBusiness(state), t);

  const contactFieldName = getValueOrDeleted(
    details?.sendToContactField?.label,
    t
  );

  return t(
    `Send Email '{{subject}}' from '{{sender}}' to '{{contactFieldName}}'`,
    { subject, sender, contactFieldName }
  );
};

export default function SendRelatedContactEmailCard({ type, step, ...others }) {
  return (
    <DraggableStepCard type={type} {...others}>
      {content({ step })}
    </DraggableStepCard>
  );
}
