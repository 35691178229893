import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { breakpoints } from 'app/spacing';
import { colorsButton, grayScale } from 'app/colors';
import { fontWeights, notificationCss, fontSizes } from 'app/typography';
import KizenTypography from 'app/kizentypo';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { useTooltip } from 'components/Kizen/Tooltip';

export const IconButtonWithBadgeStyled = styled(IconButton)`
  position: relative;
`;

const CustomNotificationText = styled(KizenTypography)`
  margin-top: 1px; // Design nudge
  font-weight: ${fontWeights.regular};
  color: ${grayScale.white};
  font-size: ${fontSizes.tiny};

  @media (max-width: ${breakpoints.md}px) {
    ${notificationCss('tiny')}
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ badgeMaxWidth }) => (badgeMaxWidth ? 'max-content' : '13px')};
  height: 13px;
  border-radius: ${({ badgeMaxWidth }) => (badgeMaxWidth ? '1rem' : '50%')};
  background-color: ${({ color = colorsButton.blue.default }) => color};
  align-self: flex-start;
  padding: ${({ badgeMaxWidth }) => badgeMaxWidth && css`0.3rem 0.65rem`};
  ${({ small }) =>
    small
      ? css`
          padding-top: 1px;
          transform: translate(-2px, -10px);
        `
      : ''}

  & > p {
    background-color: unset;
  }

  ${({ inlineBadge }) =>
    !inlineBadge &&
    css`
      position: absolute;
      left: 100%;
      top: 3px;
    `}

  ${({ emptyBadge }) =>
    emptyBadge
      ? css`
          position: absolute;
          right: -3px;
          top: -2px;
          width: 8px;
          height: 8px;
        `
      : ''}
`;

const IconButtonWithBadge = forwardRef(
  (
    {
      disabled,
      icon,
      badge,
      inlineBadge,
      badgeMaxWidth,
      label,
      placement,
      emptyBadge,
      ...rest
    },
    ref
  ) => {
    const [tooltipProps, tooltip] = useTooltip({
      label,
      placement,
      popperConfig: {
        modifiers: {
          offset: { offset: '0, 3' },
          enabled: true,
          boundariesElement: 'viewport',
        },
      },
    });
    return (
      <>
        <IconButtonWithBadgeStyled
          sizing="dense"
          color={!disabled ? colorsButton.blue : grayScale.medium}
          disabled={disabled}
          ref={ref}
          {...rest}
          {...tooltipProps}
        >
          <Icon icon={icon} />
          {!!badge && (
            <BadgeContainer
              inlineBadge={inlineBadge}
              badgeMaxWidth={badgeMaxWidth && !emptyBadge}
              data-qa={`badge-${badge}`}
              data-qa-icon={icon}
              emptyBadge={emptyBadge}
            >
              {emptyBadge ? null : (
                <CustomNotificationText size="small">
                  {badge}
                </CustomNotificationText>
              )}
            </BadgeContainer>
          )}
        </IconButtonWithBadgeStyled>
        {tooltip}
      </>
    );
  }
);

IconButtonWithBadge.displayName = 'IconButtonWithBadge';

IconButtonWithBadge.propTypes = {
  disabled: PropTypes.bool,
  inlineBadge: PropTypes.bool,
  badgeMaxWidth: PropTypes.bool,
  icon: PropTypes.string,
  badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

IconButtonWithBadge.defaultProps = {
  disabled: false,
  icon: 'filter',
  badge: 0,
  inlineBadge: true,
  badgeMaxWidth: false,
};

export default IconButtonWithBadge;
