import React from 'react';
import Switch from 'components/Kizen/Switch';

const Switches = () => {
  return (
    <>
      <Switch label="Standard" value="standard" />
      <Switch checked label="Active" value="active" />
      <Switch disabled label="Disabled - Off" value="disabledOff" />
      <Switch disabled checked label="Disabled - On" value="disabledOn" />
    </>
  );
};

export default Switches;
