import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from 'app/spacing';
import { useToast, toastVariant } from 'components/ToastProvider';
import { TRow } from 'components/Kizen/Table';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import BasicTable from 'components/Tables/Basic';
import TablePagination from 'components/Tables/Big/TablePagination';
import { usePagination } from './hooks/usePagination';
import { ComponentWrapper, PaginationWrapper, TableWrapper } from './styles';
import { useColumns } from './hooks/useColumns';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const TeamAssociations = ({
  customObject,
  entity,
  fetchAssociated = null,
  countOfMember,
  isPaginated = false,
  canAddTeamMember,
  forceRefetchKey,
  ...rest
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const isMobile = useBreakpoint();

  const tableRef = useRef(null);

  const { ordering, page, pageSize, headData, setPage, setPageSize } =
    usePagination(isPaginated);

  const [{ value: rawTeamAssociations = [], loading }, fetchTeamAssociations] =
    useAsyncFnKeepLast(async () => {
      try {
        const associations = await fetchAssociated(ordering, pageSize, page);
        return associations.values;
      } catch (error) {
        showToast({
          variant: toastVariant.FAILURE,
          message: t(
            'The team member(s) could not be loaded. Please try again or contact Kizen support.'
          ),
        });

        return [];
      }
    }, [ordering, pageSize, page]);

  useEffect(() => {
    fetchTeamAssociations();
  }, [fetchTeamAssociations, countOfMember, forceRefetchKey]);

  const columns = useColumns(
    { isMobile, customObject, entity, canAddTeamMember },
    t
  );

  return (
    <ComponentWrapper>
      <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
        <ScrollContainerBlocker top="1" />
      </ScrollContainerBlockerWrapper>
      <TableWrapper>
        <TableScrollContainer
          key={`${page}-${pageSize}-${ordering}`}
          bottom
          left
          right
          refresh={[rawTeamAssociations]}
          {...rest}
        >
          <BasicTable
            stickyHeader
            head={<TRow head columns={columns} data={headData} />}
            ref={tableRef}
            staleData={loading}
          >
            {!rawTeamAssociations.length ? <tr /> : null}
            {rawTeamAssociations.map((association) => (
              <TRow
                key={`${association.id}-${association.employeeId}`}
                columns={columns}
                data={association}
              />
            ))}
          </BasicTable>
        </TableScrollContainer>
      </TableWrapper>
      {isPaginated ? (
        <PaginationWrapper>
          <TablePagination
            small={isMobile}
            page={page}
            perPage={pageSize}
            totalCount={countOfMember}
            onChangePage={setPage}
            onChangePerPage={setPageSize}
          />
        </PaginationWrapper>
      ) : null}
    </ComponentWrapper>
  );
};

export default TeamAssociations;
