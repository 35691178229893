import React from 'react';
import styled from '@emotion/styled';
import InputControl from 'components/Inputs/InputControl';
import { gutters } from 'app/spacing';
import { useTranslation } from 'react-i18next';
import { fontWeights } from 'app/typography';

const HeadCellsWrapper = styled.div`
  display: grid;
  grid-template-columns: 235px 150px repeat(2, 210px) 218px 280px 28px;
  grid-column-gap: 0;
  padding-right: ${gutters.spacing(3)}px;
  margin-top: ${gutters.spacing(4)}px;
  label {
    font-weight: ${fontWeights.bold};
  }
`;
export const StyledInputControl = styled(InputControl)`
  padding-left: ${({ paddingLeft }) => paddingLeft || 0}px;
  padding-right: ${({ paddingRight }) => paddingRight || 0}px;
`;
export const StyledIconControl = styled(InputControl)`
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-right: 0;
`;
export const HeadCells = () => {
  const { t } = useTranslation();

  return (
    <HeadCellsWrapper>
      <StyledInputControl
        paddingLeft={gutters.spacing(3)}
        label={t('Related Object')}
      />
      <StyledInputControl
        paddingLeft={gutters.spacing(6)}
        label={t('Relationship Type')}
      />
      <StyledInputControl
        paddingLeft={gutters.spacing(6)}
        label={t('Relationship Name')}
      />
      <StyledInputControl
        paddingLeft={gutters.spacing(6)}
        label={t('Reverse Relationship Name')}
      />
      <StyledInputControl
        paddingLeft={gutters.spacing(6)}
        label={t('Share Timeline To Related?')}
        labelInfo={t(
          "Timeline entries will be shared from this object's record to the associated related object's record."
        )}
      />
      <StyledInputControl
        paddingLeft={gutters.spacing(6)}
        paddingRight={gutters.spacing(8, 2)}
        label={t('Share Timeline From Related?')}
        labelInfo={t(
          "Timeline entries will be shared from the related object's record and displayed on associated records of this object."
        )}
      />
    </HeadCellsWrapper>
  );
};
