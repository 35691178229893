import { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { FieldContainer } from '@kizen/page-builder/nodes/containers';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { definePlaceholder } from '@kizen/page-builder/utils/fields';
import { useShowControls } from '../useShowControls';
import Control from '../Control';
import { useDraggingType } from '../useDraggingType';
import { StyledFieldInput } from '../styled';
import { usePhoneFieldWithBusinessRegion } from '../usePhoneFieldWithBusinessRegion';
import { setCustomizeFieldBroadcastChannel } from 'components/PageBuilder/utils';
import { useTranslation } from 'react-i18next';
import { useIsInBuilderContext } from 'components/PageBuilder/BuilderContext';
import { canUpdateFieldPlaceholder } from '../../utils';
import { isContactTitlesField } from 'checks/fields';

// Company and Title dropdown options are not accessible from an unauthenticated endpoint
// When filling out a form, these fields are just text that are (possibly) matched to existing
// records upon submission
const formBuilderNodeXform = (field) => {
  let { fieldType } = field;

  if (isContactTitlesField(field)) {
    fieldType = 'text';
  }
  return {
    ...field,
    displayName:
      typeof field.labelText === 'string' ? field.labelText : field.displayName,
    fieldType,
  };
};

/**
 * This hook subscribes to the custom object settings page via a broadcast channel
 * so any field updates will be send to this handler and we can update update the
 * `field` prop of the custom field if needed. We need to ensure labels and options
 * that are visible (Checkboxes, Radio, Rating, Price) update without reloading the builder.
 */
const useFieldSettingsBroadcastChannel = (customFieldId, setProp) => {
  useEffect(() => {
    const handler = (payload) => {
      setProp((p) => {
        p.field = {
          ...p.field,
          ...payload,
          customObjectField: { ...p.field.customObjectField, ...payload },
        };
      });
    };
    const bc = setCustomizeFieldBroadcastChannel(customFieldId, handler);
    return () => bc.close();
  }, [customFieldId, setProp]);
};

export const CustomField = ({ field: fieldProp, ...rest }) => {
  const { t } = useTranslation();
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
    enabled: state.options.enabled,
  }));
  const {
    id,
    connectors: { connect },
    setProp,
  } = useNode((node) => ({
    id: node.id,
  }));
  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const isInBuilderContext = useIsInBuilderContext();
  const field = usePhoneFieldWithBusinessRegion(fieldProp);
  const placeholder = canUpdateFieldPlaceholder(field)
    ? field.placeholder
    : definePlaceholder(field, t);

  useFieldSettingsBroadcastChannel(field.customObjectField.id, setProp);

  return (
    <Control
      ref={connect}
      label={t('Content')}
      show={showControls}
      color={colorsPrimary.green.dark}
      mayHaveChildren={false}
      fullWidth={true}
      {...rest}
    >
      <FieldContainer
        draggingType={draggingType}
        pointerEvents={false}
        isInBuilderContext={isInBuilderContext}
        {...rest}
      >
        <StyledFieldInput
          field={formBuilderNodeXform(field)}
          placeholder={placeholder}
          object={{}}
          isRequired={field.isRequired}
          noWhiteSpace
        />
      </FieldContainer>
    </Control>
  );
};
