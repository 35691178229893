import { MessagesPage } from './pages';
import { AutomationsPage } from 'pages/Common/Automations/AutomationsPage';

import paths from './paths';

const routes = {
  profile: {
    label: (t) => t('Profile'),
    component: null,
    exact: true,
    icon: 'id-card',
    invalidateTimeline: false,
  },
  messages: {
    label: (t) => t('Messages'),
    component: MessagesPage,
    exact: true,
    icon: 'envelope',
    invalidateTimeline: true,
  },
  automations: {
    label: (t) => t('Automations'),
    component: AutomationsPage,
    exact: true,
    icon: 'play-light',
    invalidateTimeline: true,
  },
};

paths.forEach((path, routeId) => {
  routes[routeId].path = path;
});

export default routes;
