import { useCallback, useState } from 'react';
import { isEqual } from 'lodash';

export const useDirtyState = (initialData, data, onHide) => {
  const [show, setShow] = useState(false);

  const onCheckToHide = useCallback(() => {
    !initialData || isEqual(initialData, data) ? onHide() : setShow(true);
  }, [initialData, data, onHide]);

  const onHideConfirmed = useCallback(() => {
    onHide();
    setShow(false);
  }, [onHide]);

  const onHideCanceled = useCallback(() => {
    setShow(false);
  }, []);

  return {
    showConfirmation: show,
    onCheckToHide,
    onHideConfirmed,
    onHideCanceled,
  };
};
