import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditor } from '@craftjs/core';
import { getDefaultImageProps } from '@kizen/page-builder/utils/image';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { created } from 'store/pageBuilder/pageBuilder.redux';
import { ImageLibrary } from '../../components/ImageLibrary';
import { useAddNewNode } from '../useAddNewNode';
import { DEFAULT_EMAIL_IMAGE_PROPS, DEFAULT_FORM_IMAGE_PROPS } from './props';
import { PUBLIC_IMAGE_FILE_UPLOAD_SOURCE } from 'hooks/uploadFiles/useUploadFile';

const CreateImage = ({ defaultProps }) => {
  const addNewNode = useAddNewNode();
  const dispatch = useDispatch();
  const { actions } = useEditor();
  const imageItem = useSelector((s) => getCreateItemByType(s, 'Image'));

  const onHide = useCallback(() => {
    actions.setIndicator(null);
    dispatch(created(false));
  }, [actions, dispatch]);

  const onConfirm = useCallback(
    async (file) => {
      const elementProps = await getDefaultImageProps(file);
      addNewNode({
        ...imageItem,
        ...defaultProps,
        elementProps,
      });
    },
    [addNewNode, defaultProps, imageItem]
  );

  return (
    <ImageLibrary
      source={PUBLIC_IMAGE_FILE_UPLOAD_SOURCE}
      onConfirm={onConfirm}
      onHide={onHide}
      show={!!imageItem}
    />
  );
};

export const EmailImageCreator = React.createElement(CreateImage, {
  defaultProps: DEFAULT_EMAIL_IMAGE_PROPS,
});

export const FormImageCreator = React.createElement(CreateImage, {
  defaultProps: DEFAULT_FORM_IMAGE_PROPS,
});
