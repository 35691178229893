import DOMPurify from 'dompurify';
import { useMemo } from 'react';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if (node.hasAttribute('target') && !node.hasAttribute('rel')) {
    node.setAttribute('rel', 'noopener noreferrer nofollow');
  }
  return node;
});

export const useSanitizedHtml = (html) => {
  return useMemo(() => {
    try {
      return DOMPurify.sanitize(html, { ADD_ATTR: ['target'] });
    } catch (err) {
      return '';
    }
  }, [html]);
};
