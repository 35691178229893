import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TRow } from 'components/Kizen/Table';
import { getBroadcastsColumns } from './columns';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import BasicTable from 'components/Tables/Basic';
import styled from '@emotion/styled';
import TablePagination from 'components/Tables/Big/TablePagination';
import { gutters } from 'app/spacing';
import { border, grayScale } from 'app/colors';
import { getOrderingParam } from 'utility/SortingHelpers';
import LibraryService from 'services/LibraryService';
import Loader from 'components/Kizen/Loader';
import { css } from '@emotion/core';
import { useAsync } from 'react-use';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import Breadcrumbs from 'components/Breadcrumbs';
import { fontSizes } from 'app/typography';
import PropTypes from 'prop-types';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const BreadcrumbsWrapper = styled.div`
  display: flex;
  height: ${fontSizes.buttonLabel};
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${gutters.spacing(3, 2)}px;
`;
const CenteredLoader = styled(Loader)`
  position: absolute;
  top: 60%;
  left: 50%;
  padding-top: 0 !important;
  transform: translate(-50%, 0%);
`;
const TableWrapper = styled.div`
  ${border}
  border-color: ${grayScale.mediumLight};
`;

const StyledTableScrollContainer = styled(TableScrollContainer)`
  max-height: calc(100vh - 490px);

  ${({ loading }) => {
    return (
      loading &&
      css`
        min-height: calc(100vh - 490px);
        height: 100%;
      `
    );
  }}
`;
const PaginationWrapper = styled.div`
  padding: ${gutters.spacing(4)}px 0 ${gutters.spacing(0, 2)}px 0;
`;

const initialConfig = {
  ordering: {
    column: 'name',
    direction: 'asc',
  },
  page: {
    number: 1,
    size: 50,
    search: '',
  },
  loaded: false,
};
const Templates = ({
  t,
  onSubmit,
  onActionView,
  suppressedFolders = EMPTY_ARRAY,
  toolbar,
  setItemsCount,
  search,
  resetSearch,
  setCurrentFolder,
}) => {
  const [config, setConfig] = useState(initialConfig);
  const { ordering, page } = config;
  const loadedOnce = useRef(true);
  const prevData = useRef({});
  const { directories, handleChangeDirectory, parentFolder, currentFolder } =
    useBreadcrumbs({
      name: t('Broadcasts'),
    });

  useEffect(() => {
    if (!loadedOnce.current)
      setConfig((prev) => ({
        ...prev,
        page: { ...prev.page, number: 1, search },
      }));
  }, [search]);

  const { value: data = {}, loading } = useAsync(async () => {
    const ordering = getOrderingParam(config.ordering);
    const { id } = currentFolder;
    const data = await LibraryService.getAllBroadcastsInFolder({
      folderId: id,
      ...config,
      ordering,
    });
    loadedOnce.current = false;
    setItemsCount(data.count);
    return {
      ...data,
      results: data.results.filter(
        ({ type, internalName, isSystemFolder }) =>
          !(
            type === 'folder' &&
            isSystemFolder &&
            suppressedFolders.some((f) => f === internalName)
          )
      ),
    };
  }, [config, currentFolder]);

  if (!loading) prevData.current = data;
  const { results: broadcasts = [], count = 0 } = prevData.current;

  const broadcastsWithParent = useMemo(() => {
    if (!broadcasts.length) {
      return broadcasts;
    }

    const backToParent = directories.length > 1 && {
      id: parentFolder.id,
      name: `${t('Back to')} '${parentFolder.name}'`,
      type: 'folder', // can be either text or email
      levelUp: true,
    };

    return (backToParent ? [backToParent] : []).concat(broadcasts);
  }, [broadcasts, parentFolder, directories, t]);

  const handleChangeSort = useCallback(
    ({ column, direction }) =>
      setConfig((prev) => ({ ...prev, ordering: { column, direction } })),
    [setConfig]
  );

  const handleChangePageNumber = useCallback(
    (value) =>
      setConfig((prev) => ({
        ...prev,
        page: {
          ...prev.page,
          number: value,
        },
      })),
    [setConfig]
  );

  const handleChangePageSize = useCallback(
    (value) =>
      setConfig((prev) => ({
        ...prev,
        page: {
          ...prev.page,
          number: 1,
          size: value,
        },
      })),
    [setConfig]
  );

  const broadcastsHeadData = useMemo(
    () => ({
      meta: {
        sort: ordering,
        onSort: (column, direction) => handleChangeSort({ column, direction }),
      },
    }),
    [ordering, handleChangeSort]
  );

  const onChangeDirectory = useCallback(
    (folder) => {
      setConfig((prev) => ({
        ...prev,
        page: {
          ...prev.page,
          // start from 1st page to prevent flickering on table load
          number: 1,
        },
      }));
      handleChangeDirectory(folder);
      resetSearch();
      setCurrentFolder(folder);
      setItemsCount(0);
    },
    [handleChangeDirectory, resetSearch, setCurrentFolder, setItemsCount]
  );

  const broadcastsColumns = useMemo(
    () =>
      getBroadcastsColumns({
        handleChangeDirectory: onChangeDirectory,
        parentFolder,
        onSubmit,
        onActionView,
        t,
      }),
    [onChangeDirectory, onActionView, onSubmit, parentFolder, t]
  );
  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          directories={directories}
          handleChangeDirectory={onChangeDirectory}
        />
      </BreadcrumbsWrapper>
      {toolbar}
      <TableWrapper>
        <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
          <ScrollContainerBlocker />
        </ScrollContainerBlockerWrapper>
        <StyledTableScrollContainer
          loading={loadedOnce.current && loading}
          bottom
          left
          right
          refresh={[broadcastsWithParent]}
          rounded
        >
          <BasicTable
            stickyHeader
            columns={broadcastsColumns}
            head={
              <TRow
                head
                columns={broadcastsColumns}
                data={broadcastsHeadData}
              />
            }
          >
            {broadcastsWithParent &&
              broadcastsWithParent.map((item) => (
                <TRow key={item.id} columns={broadcastsColumns} data={item} />
              ))}
            {loadedOnce.current && loading && <CenteredLoader loading />}
          </BasicTable>
        </StyledTableScrollContainer>
      </TableWrapper>
      <PaginationWrapper>
        <TablePagination
          page={page.number}
          perPage={page.size}
          totalCount={count}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      </PaginationWrapper>
    </>
  );
};

Templates.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  suppressedFolders: PropTypes.array,
};

export default Templates;
