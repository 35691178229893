import React, { useCallback } from 'react';
import BasicModal from './BasicModal';
import ConfirmationModal from './ConfirmationModal';
import { useTranslation } from 'react-i18next';
import useModal from 'components/Modals/useModal';

export default function BasicModalWithConfirmation({
  children,
  dirty = false,
  onHide,
  onConfirm,
  leftBtn,
  hasOneBtnSubmit,
  confirmationContent,
  confirmationHeading,
  overWriteConfirmationProps,
  ...props
}) {
  const { t } = useTranslation();
  const [modalProps, , modal] = useModal();

  const handleClose = useCallback(() => {
    dirty ? modal.show() : onHide();
  }, [dirty, modal, onHide]);

  const handleModalConfirm = useCallback(async () => {
    await (leftBtn || hasOneBtnSubmit ? onConfirm() : handleClose());
  }, [handleClose, leftBtn, onConfirm, hasOneBtnSubmit]);

  const handleConfirmationConfirm = useCallback(() => {
    modal.hide();
    onHide();
  }, [modal, onHide]);

  return (
    <>
      <BasicModal
        {...props}
        onConfirm={handleModalConfirm}
        leftBtn={leftBtn}
        onHide={handleClose}
      >
        {children}
      </BasicModal>
      <ConfirmationModal
        className={`no-drag`}
        heading={confirmationHeading || t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        {...modalProps}
        onConfirm={handleConfirmationConfirm}
        {...overWriteConfirmationProps}
      >
        {confirmationContent ||
          t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
}
