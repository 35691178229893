import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import useToggle from 'react-use/lib/useToggle';
import { useTranslation } from 'react-i18next';

import { layers } from 'app/spacing';
import Select from 'components/Inputs/Select';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import { viewportPopperConfig2 } from 'components/Inputs/Validation';
import { DropdownWrapper, StyledAddButton } from './styles';
import { popperModifiers } from 'components/helpers';

const defaultMergeFields = (t) => [
  {
    label: t('Contact Id'),
    value: '{{ contact.id }}',
  },
  {
    label: t('Contact First Name'),
    value: '{{ contact.first_name }}',
  },
  {
    label: t('Contact Last Name'),
    value: '{{ contact.last_name }}',
  },
  {
    label: t('Contact Full Name'),
    value: '{{ contact.full_name }}',
  },
  {
    label: t('Contact Email'),
    value: '{{ contact.email }}',
  },
  {
    label: t('Contact Home Phone'),
    value: '{{ contact.home_phone }}',
  },
  {
    label: t('Contact Business Phone'),
    value: '{{ contact.business_phone }}',
  },
  {
    label: t('Contact Mobile Phone'),
    value: '{{ contact.mobile_phone }}',
  },
  {
    label: t('Contact Company Name'),
    value: '{{ contact.company.name }}',
  },
  {
    label: t('Contact Birthday'),
    value: '{{ contact.birthday }}',
  },
  {
    label: t('Contact Timezone'),
    value: '{{ contact.timezone }}',
  },
  {
    label: t('Link To Contact Record'),
    value: '{{ contact.record_url }}',
  },
  {
    label: t('Team Member Signature'),
    value: '{{ team_member.signature }}',
  },
  {
    label: t('Team Member First Name'),
    value: '{{ team_member.first_name }}',
  },
  {
    label: t('Team Member Last Name'),
    value: '{{ team_member.last_name }}',
  },
  {
    label: t('Team Member Full Name'),
    value: '{{ team_member.full_name }}',
  },
  {
    label: t('Team Member Email'),
    value: '{{ team_member.email }}',
  },
  {
    label: t('Team Member Title'),
    value: '{{ team_member.title }}',
  },
  {
    label: t('Team Member Phone'),
    value: '{{ team_member.phone }}',
  },
  {
    label: t('Team Member Mobile Phone'),
    value: '{{ team_member.mobile_phone }}',
  },
];

export default function InsertMergeField({
  mergeFields,
  onBlur,
  onClick,
  onChange,
  isCompact,
  inModal,
  setMenuOpen,
  selectStyle,
  ...rest
}) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (mergeFields.length === 0) return defaultMergeFields(t);
    return mergeFields;
  }, [mergeFields, t]);

  const [open, toggleOpen] = useToggle(false);
  useEffect(() => {
    setMenuOpen(open);
  }, [setMenuOpen, open]);

  const buttonRef = useRef(null);

  const handleInsertMergeField = (...args) => {
    toggleOpen();
    onChange(args);
  };

  const handleClick = (ev) => {
    toggleOpen();
    if (onClick) {
      onClick(ev);
    }
  };
  return (
    <DropdownWrapper {...rest}>
      <StyledAddButton
        as="span"
        color="blue"
        onClick={handleClick}
        ref={buttonRef}
        isCompact={isCompact}
      >
        {t('Insert Merge Field')}
      </StyledAddButton>
      <SelectOverlay
        show={open}
        target={buttonRef.current}
        onHide={() => {
          toggleOpen(false);
        }}
        popperConfig={{
          modifiers: [
            popperModifiers.offSet(0, -12), // Align directly under button text
            ...(inModal && viewportPopperConfig2.modifiers),
          ],
        }}
        placement={isCompact ? 'bottom-end' : undefined}
      >
        <Select
          placeholder={t('Find Field')}
          options={options}
          onChange={handleInsertMergeField}
          onBlur={onBlur}
          {...(inModal && {
            onFocus: (ev) => {
              // Very much a bandaid fix
              // As used in pages/AutomationEngine/dialogs/ActionWizard/subsections/SendText,
              // the Select element was losing focus to its containing modal on open / click
              // Not sure why yet, but this solved it. Need to determine root cause
              ev.nativeEvent.stopImmediatePropagation();
            },
            style: { zIndex: layers.popoverInModal, ...selectStyle },
          })}
        />
      </SelectOverlay>
    </DropdownWrapper>
  );
}

InsertMergeField.propTypes = {
  mergeFields: PropTypes.array,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
  inModal: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  trim: PropTypes.bool,
  selectStyle: PropTypes.object,
};

InsertMergeField.defaultProps = {
  onClick: undefined,
  onBlur: undefined,
  mergeFields: [],
  isCompact: false,
  inModal: false,
  setMenuOpen: () => {},
  trim: false,
  selectStyle: {},
};
