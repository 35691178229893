import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldService from 'services/FieldService';
import { toastVariant, useToast } from 'components/ToastProvider';
import UploadDataSection from 'components/Modals/UploadCSVData/sections/UploadData';
import MatchRecordsSection from 'components/Modals/UploadCSVData/sections/MatchRecords';
import FinalizeUploadSection from 'components/Modals/UploadCSVData/sections/FinalizeUpload';
import {
  StyledGenericWizardModalBody,
  StyledUploadDataSection,
  StyledFinalizeUploadSection,
  StyledMatchRecordsSection,
} from 'components/Modals/UploadCSVData/sections/styles';
import {
  NO_UPLOAD,
  getConflictResolution,
  getInitialFinalizeUploadData,
  changeCSVColumnType,
  subscriptionField,
  getFieldsForUpload,
  defaultFieldsOrderForUpload,
} from 'components/Modals/UploadCSVData/utilities';
import Button from 'components/Button';
import { DEFAULT_FIELD_NAMES } from 'utility/constants';
import {
  isDefaultEmailField,
  isEmailStatusField,
  isFilesField,
} from 'checks/fields';
import { joinEmailStatusOptions } from 'components/DownloadButton/useCSVString';
import BasicModalWithConfirmation from 'components/Modals/presets/BasicModalWithConfirmation';
import { useDynamicTagOptionsForCSVTemplate } from 'components/DownloadButton/useDynamicTagOptionsForCSVTemplate';
import { fetchErrorByKey, getErrorMessage } from 'hooks/useErrors';
import { useFlashTransition } from 'hooks/useFlashState';
import { StyledLoader } from './styles';

const defaultValues = {
  file: [],
  emailFieldId: '',
  firstFieldEmail: '',
  filteredFields: [],
};

const requiredFields = (t, name) => [
  {
    id: 'emailFieldId',
    name: name,
    fieldType: 'email',
    title: t('Email'),
  },
];

// We want to compare column headers case-insensitve
// and without trailing or leading whitespace
const cleanValueForComparison = (val) => {
  return val ? val.toLowerCase().trim() : val;
};

const filterNotAvailableFields = (el) =>
  !isDefaultEmailField(el) && !isFilesField(el);

const UploadContactsModal = ({ onHide, categorizedFields, ...props }) => {
  const [showToast] = useToast({
    delay: 5000,
  });
  const { t } = useTranslation();
  const [values, setValues] = useState(defaultValues);
  const [newContacts, setNewContacts] = useState({});
  const [CSVOptions, setCSVOptions] = useState([]);
  const [finalizeUploadData, setFinalizeUploadData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [emailFieldError, setEmailFieldError] = useState(null);
  const requiredFieldsWrapperRef = useRef(null);
  const [emailErrorFieldMessage, , flashEmailErrorFieldMessage, cancelFlash] =
    useFlashTransition({
      stay: 3000,
    });

  const categories = useMemo(
    () =>
      categorizedFields.reduce((acc, c) => {
        acc[c.id] = { name: c.name };
        return acc;
      }, {}),
    [categorizedFields]
  );

  const allFields = useMemo(
    () => categorizedFields.flatMap((c) => c.fields),
    [categorizedFields]
  );

  const { dynamicTagsWithOptions, loadingDynamictagsOptions } =
    useDynamicTagOptionsForCSVTemplate(allFields);

  const fields = useMemo(() => {
    const [defaultFields, customFields] = getFieldsForUpload(
      allFields,
      dynamicTagsWithOptions
    );

    const emailStatusIndex = defaultFieldsOrderForUpload.indexOf(
      DEFAULT_FIELD_NAMES.emailStatus
    );
    defaultFields.splice(emailStatusIndex + 1, 0, subscriptionField);

    return [...defaultFields, ...customFields];
  }, [allFields, dynamicTagsWithOptions]);

  const isValidSecondStep = useMemo(
    () =>
      values.file.length > 0 && values.file[0].created && CSVOptions.length > 0,
    [values.file, CSVOptions]
  );

  const isValidLastStep = useMemo(
    () => isValidSecondStep && newContacts[values.emailFieldId]?.csvColumn >= 0,
    [newContacts, values.emailFieldId, isValidSecondStep]
  );

  const handleChangeFile = (file) => {
    setValues({
      ...values,
      file,
    });
  };

  useEffect(() => {
    if (!isValidLastStep && requiredFieldsWrapperRef.current) {
      requiredFieldsWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
      });
      const message = t('This field is required.');
      setEmailFieldError(message);
      flashEmailErrorFieldMessage(message);
    }
  }, [flashEmailErrorFieldMessage, isValidLastStep, t]);

  useEffect(() => {
    if (fields.length > 0) {
      const fieldName = fields.find(isDefaultEmailField);
      const statusField = fields.find(isEmailStatusField);
      setValues((prev) => ({
        ...prev,
        emailFieldId: fieldName.id,
        emailStatusFieldId: statusField.id,
        firstFieldEmail: fieldName.displayName,
        filteredFields: fields.filter(filterNotAvailableFields),
        categories: categories,
      }));
    }
  }, [fields, categories, t]);

  useEffect(() => {
    if (!newContacts[values.emailFieldId]) {
      setNewContacts(
        values.filteredFields.reduce(
          (acc, curr) => {
            acc[curr.id] = {
              csvColumn: '',
              conflictResolution: '',
            };
            return acc;
          },
          {
            [values.emailFieldId]: {
              csvColumn: '',
              conflictResolution: '',
            },
          }
        )
      );
    }
  }, [values.filteredFields, newContacts, values.emailFieldId]);

  const handleSetCSVData = useCallback(
    (data) => {
      // check empty data from csv
      if (data && data.length > 0) {
        const options = data[0].map((item, index) => ({
          label: item,
          value: index.toString(),
        }));
        options.unshift({
          label: t('Don’t Upload'),
          value: NO_UPLOAD,
        });
        setCSVOptions(options);
        const usedIndexes = [];
        const newContactsAfterUploadData = values.filteredFields.reduce(
          (acc, curr) => {
            const matcher = isEmailStatusField(curr)
              ? `${curr.canonicalDisplayName} (${joinEmailStatusOptions})`
              : curr.canonicalDisplayName;
            const index = data[0].findIndex(
              (el) =>
                cleanValueForComparison(el) === cleanValueForComparison(matcher)
            );
            acc[curr.id] = {
              csvColumn:
                // if the field is not found in the csv, or it's already used we don't upload it
                usedIndexes.includes(index) || index < 0
                  ? NO_UPLOAD
                  : index.toString(),
              conflictResolution: getConflictResolution(curr.fieldType, curr),
            };
            if (index >= 0) {
              usedIndexes.push(index);
            }
            return acc;
          },
          {}
        );
        const emailFieldIndex = data[0].findIndex(
          (el) =>
            cleanValueForComparison(el) ===
            cleanValueForComparison(values.firstFieldEmail)
        );
        const newContactsData = {
          ...newContacts,
          ...newContactsAfterUploadData,
          [values.emailFieldId]: {
            csvColumn: `${emailFieldIndex}`,
            conflictResolution: '',
          },
        };
        setNewContacts(newContactsData);
        const slicedSCVData =
          data.length > 5 ? data.slice(1, 6) : data.slice(1);
        setFinalizeUploadData(
          slicedSCVData.map((el, i) => ({
            id: i,
            ...getInitialFinalizeUploadData(el, newContactsData),
          }))
        );
        setCsvData(slicedSCVData);
        return;
      }
      setCSVOptions([]);
      setCsvData([]);
      setFinalizeUploadData([]);
      setNewContacts({});
    },
    [
      newContacts,
      values.filteredFields,
      values.emailFieldId,
      values.firstFieldEmail,
      t,
    ]
  );

  const handleChangeMatchRecords = useCallback(
    (data, element) => {
      if (emailFieldError && values?.emailFieldId === element && data) {
        setEmailFieldError(null);
        cancelFlash();
      }
      setNewContacts((prev) => ({
        ...prev,
        [element]: {
          ...prev[element],
          csvColumn: data.value,
        },
      }));
      setFinalizeUploadData((prev) =>
        prev.map((curr, i) => ({
          ...curr,
          [element]: csvData[i][+data.value],
        }))
      );
    },
    [csvData, emailFieldError, cancelFlash, values?.emailFieldId]
  );

  const createPayload = async () => {
    const copyNewContacts = {
      ...newContacts,
    };
    delete copyNewContacts[values.emailFieldId];
    delete copyNewContacts[subscriptionField.id];
    const mappedFields = changeCSVColumnType(copyNewContacts);
    const body = {
      s3_objectId: values.file[0].id,
      emailColumn: +newContacts[values.emailFieldId].csvColumn,
      fieldMapper: mappedFields,
      email_status_column: mappedFields[values.emailStatusFieldId]?.csvColumn,
      subscriptions_column:
        newContacts[subscriptionField.id].csvColumn !== NO_UPLOAD
          ? +newContacts[subscriptionField.id].csvColumn
          : undefined,
    };
    try {
      await FieldService.customObjectUploader('contacts', body);
      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          'Your upload has been successfully started. You will be emailed once the upload is complete.'
        ),
      });
      onHide();
    } catch (err) {
      const emailColumnError = fetchErrorByKey(err, 'email_column');
      const emailColumnErrorMessage =
        emailColumnError && getErrorMessage(emailColumnError);
      if (emailColumnErrorMessage) {
        requiredFieldsWrapperRef?.current.scrollIntoView({
          behavior: 'smooth',
        });
        setEmailFieldError(emailColumnErrorMessage);
        flashEmailErrorFieldMessage(emailColumnErrorMessage);
      }
      showToast({
        variant: toastVariant.FAILURE,
        message: t('The records have not been uploaded.'),
      });
    }
  };
  const emailField = useMemo(
    () => fields.find((el) => el.id === values.emailFieldId),
    [fields, values.emailFieldId]
  );
  return (
    <BasicModalWithConfirmation
      dirty={!!values.file?.length}
      onHide={onHide}
      size="large"
      heading={t('Upload Contacts')}
      leftBtn={
        <Button variant="text" color="red" onClick={onHide}>
          {t('Cancel')}
        </Button>
      }
      buttonText={t('Upload')}
      disabled={!isValidLastStep || emailFieldError}
      onConfirm={createPayload}
      {...props}
    >
      <StyledGenericWizardModalBody>
        <StyledUploadDataSection header={t('Upload Your Data')} isActive>
          <StyledLoader
            loading={!isValidSecondStep && loadingDynamictagsOptions}
          >
            <UploadDataSection
              fields={fields}
              files={values.file}
              onChange={handleChangeFile}
              setCSVData={handleSetCSVData}
              customObject={{
                objectType: 'contact',
                objectName: 'contacts',
              }}
            />
          </StyledLoader>
        </StyledUploadDataSection>
        <StyledMatchRecordsSection
          header={t('Match Records')}
          isActive={isValidSecondStep}
        >
          {isValidSecondStep && (
            <div ref={requiredFieldsWrapperRef}>
              <MatchRecordsSection
                setNewEntities={setNewContacts}
                requiredFields={requiredFields(t, emailField?.name)}
                newEntities={newContacts}
                CSVOptions={CSVOptions}
                values={values}
                onChange={handleSetCSVData}
                setFinalizeUploadData={setFinalizeUploadData}
                handleChangeMatchRecords={handleChangeMatchRecords}
                errorFieldId="emailFieldId"
                error={emailFieldError}
                errorMessage={emailErrorFieldMessage}
                hasSkipBlanksOption
              />
            </div>
          )}
        </StyledMatchRecordsSection>
        <StyledFinalizeUploadSection
          header={t('Finalize Upload')}
          isActive={isValidLastStep}
        >
          {isValidLastStep && (
            <FinalizeUploadSection
              fields={fields}
              finalizeUploadData={finalizeUploadData}
            />
          )}
        </StyledFinalizeUploadSection>
      </StyledGenericWizardModalBody>
    </BasicModalWithConfirmation>
  );
};

export default UploadContactsModal;
