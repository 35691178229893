import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import ToggleButton from 'components/Kizen/ToggleButton';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { useTranslation } from 'react-i18next';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import {
  getCalendarEventsToggleOptions,
  isEventTypeAvailable,
} from 'pages/Broadcasts/helpers';
import { EventBlock } from './EventBlock';
import { EventsFilter } from './EventsFilter';
import {
  CALENDAR_EVENTS_OPTIONS,
  EVENTS_PER_PAGE,
  EVENT_TYPES,
} from 'pages/Broadcasts/constants';
import { useEventsSearch } from 'pages/Broadcasts/hooks/useEventsSearch';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';
import {
  EventsCell,
  EventsHeaderWrapper,
  EventsScrollContainer,
  EventsScrollWrapper,
  NoDataMessage,
  StyledScrollBarDetached,
} from './styles';
import { useMeasure } from 'react-use';
import { useSet } from 'hooks/useSet';

export const CalendarEvents = ({
  navBarHeight,
  broadcast,
  onSelectAction,
  access,
}) => {
  const { t } = useTranslation();
  const preReleaseFeature = usePreReleaseFeatures();

  const [filters, { toggle }] = useSet(
    Object.values(EVENT_TYPES)
      .filter(isEventTypeAvailable(preReleaseFeature, access))
      .map(({ type }) => type)
  );

  const [upcomingPast, setUpcomingPast] = useState(
    CALENDAR_EVENTS_OPTIONS.upcoming
  );

  const calendarEventsToggleOptions = useMemo(
    () => getCalendarEventsToggleOptions(t),
    [t]
  );

  const {
    data,
    getMoreData,
    hasMorePages,
    loading,
    initialLoading,
    ...searchProps
  } = useEventsSearch(upcomingPast, broadcast, filters, EVENTS_PER_PAGE);

  const events = useMemo(() => {
    return data.filter(({ type }) =>
      filters.some((filterType) => filterType === type)
    );
  }, [filters, data]);

  const noDataMessage = useMemo(() => {
    if (initialLoading) {
      return upcomingPast === CALENDAR_EVENTS_OPTIONS.upcoming
        ? t('Loading Upcoming Events')
        : t('Loading Past Events');
    }
    return upcomingPast === CALENDAR_EVENTS_OPTIONS.upcoming
      ? t('No Upcoming Events Found')
      : t('No Past Events Found');
  }, [t, upcomingPast, initialLoading]);

  // scroll
  const [filterHeaderRef, { height }] = useMeasure();
  const scrollbarRef = useRef();
  const [refFn, scrollRef] = useSyncSizes(
    scrollbarRef,
    '.ContentForSyncHeight',
    'height'
  );
  useSyncScroll(useRef([scrollRef, scrollbarRef]), {
    vertical: true,
  });
  const scrolled = useEndsOfScroll(scrollRef, [events.length]);
  const [scrolledTop, , scrolledBottom] = scrolled;
  const scrolledBottomRef = useRef(scrolledBottom);

  const eventLength = useRef();
  eventLength.current = events.length;

  useLayoutEffect(() => {
    const page = Math.floor(eventLength.current / EVENTS_PER_PAGE) + 1;
    if (
      hasMorePages &&
      scrolledBottom &&
      !scrolledBottomRef.current &&
      page > 1
    )
      getMoreData(page);
    scrolledBottomRef.current = scrolledBottom;
  }, [scrolledBottom, getMoreData, hasMorePages]);

  return (
    <EventsCell column navBarHeight={navBarHeight}>
      <EventsHeaderWrapper ref={filterHeaderRef}>
        <ToggleButton
          onlyTopRadius
          sizing={null}
          value={upcomingPast}
          options={calendarEventsToggleOptions}
          onChange={(opt) => setUpcomingPast(opt.value)}
        />
        <EventsFilter
          filters={filters}
          setFilters={toggle}
          searchProps={searchProps}
          scrolled={!scrolledTop}
          access={access}
        />
      </EventsHeaderWrapper>
      <EventsScrollWrapper offSet={height}>
        <EventsScrollContainer ref={refFn} scrolled={scrolled} bottom>
          <div className="ContentForSyncHeight">
            {events.length && !initialLoading ? (
              events.map((event) => {
                const { id, type, name, scheduledAt, rescheduleAt } = event;
                return (
                  <EventBlock
                    key={id}
                    date={rescheduleAt || scheduledAt}
                    type={type}
                    label={name}
                    parentCont={scrollRef}
                    access={access[EVENT_TYPES[type]?.accessKey] || {}}
                    onSelectAction={onSelectAction}
                    event={event}
                    isPastEvent={upcomingPast === CALENDAR_EVENTS_OPTIONS.past}
                  />
                );
              })
            ) : (
              <NoDataMessage>{noDataMessage}</NoDataMessage>
            )}
          </div>
        </EventsScrollContainer>
        <StyledScrollBarDetached
          ref={scrollbarRef}
          direction="vertical"
          scrollClassName="ContentForSyncHeight"
        />
      </EventsScrollWrapper>
    </EventsCell>
  );
};
