import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';

const defaultOffSet = { x: 0, y: 0 };

const CurrentTimeSvg = styled.svg`
  position: absolute;
  top: ${({ offSet }) => offSet?.y || 0}px;
  left: ${({ offSet }) => offSet?.x || 0}px;
  z-index: 1;
  pointer-events: none;
`;

const CurrentTimeLine = styled.line`
  stroke: blue;
  stroke-dasharray: ${gutters.spacing(0, 4)}px;
`;

const FloatingDashedLine = ({
  className,
  containerWidth,
  offSet = defaultOffSet,
}) => (
  <CurrentTimeSvg
    className={className}
    offSet={{ ...defaultOffSet, ...offSet }}
    viewBox={`0 0 ${containerWidth} 1`}
  >
    <CurrentTimeLine x0="0" y0="0" x1={containerWidth} y1="1" />
  </CurrentTimeSvg>
);

export default FloatingDashedLine;

FloatingDashedLine.propTypes = {
  containerWidth: PropTypes.number.isRequired,
  offset: PropTypes.number,
  className: PropTypes.string,
};

FloatingDashedLine.defaultProps = {
  offset: 0,
  className: undefined,
};
