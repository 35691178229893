import { StyledEditableText } from './styles';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';

const EditableTextEllipsisWithTooltip = (props) => {
  const [nameTooltipProps, nameTooltip] = useTruncationTooltip();
  return (
    <>
      {nameTooltip}
      <StyledEditableText {...props} {...nameTooltipProps} />
    </>
  );
};
export default EditableTextEllipsisWithTooltip;
