import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { deferExecution } from 'utility/defer';

/**
 * Ensures the route is kept in sync when opening/closing the field edit wizard modal on the Builder component.
 * The two callbacks returned must be called when opening and closing the modal. This hook is used on the root
 * page components (contacts & custom objects) where all the object & category data is stored.
 *
 *   - /settings/custom-fields/contacts/${field_id}
 *   - /settings/custom-fields/custom-objects/${object_id}
 *   - /settings/custom-fields/custom-objects/${object_id}/${field_id}
 */
export const useFieldEditingModal = () => {
  const history = useHistory();
  const location = useLocation();
  const { field_id } = useParams();
  const [fieldId, setFieldId] = useState(field_id);

  const onOpenEditFieldOptions = useCallback(
    (field) => {
      setFieldId(field.id);
      deferExecution(() => {
        history.push(`${location.pathname}/${field.id}`);
      });
    },
    [location, history]
  );

  const onCloseEditFieldOptions = useCallback(() => {
    const { pathname } = location;
    const parts = pathname.split('/');
    fieldId && parts.pop();
    history.push(parts.join('/'));
    setFieldId(null);
  }, [fieldId, history, location, setFieldId]);

  useEffect(() => {
    setFieldId(field_id);
  }, [field_id]);

  return [fieldId, { onOpenEditFieldOptions, onCloseEditFieldOptions }];
};
