import { useCallback } from 'react';
import { KEY_CODES } from './constants';

export const getEventKey = (e) => {
  return Object.keys(KEY_CODES).find((keyName) =>
    Object.entries(KEY_CODES[keyName]).some(([k, v]) => {
      return v instanceof RegExp ? v.test(e[k]) : v === e[k];
    })
  );
};

export const useCustomSelectMenuProps = (
  selectManagerRef,
  { shouldUseKeyboardEventHandle, ...props } = {}
) => {
  const setMenuIsOpen = useCallback(
    (open) =>
      open
        ? selectManagerRef.curent?.onMenuOpen()
        : selectManagerRef.curent?.onMenuClose(),
    [selectManagerRef]
  );
  return shouldUseKeyboardEventHandle
    ? {
        setMenuIsOpen,
        handleClickApply: (e) => {
          props?.onBlur?.(e, true);
        },
        tabSelectsValue: false,
        closeOnClearClick: false,
      }
    : {};
};
