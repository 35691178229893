import { COLUMN_SIZE } from '@kizen/kds/Grid';
import { Dispatch, ReactNode, Reducer, SetStateAction } from 'react';

export type ChangeHandler = (layout: LayoutState) => void;

export type DropZoneMap = Record<string, HTMLDivElement | undefined>;

export type DraggableItemType =
  | 'fields'
  | 'team_and_activities'
  | 'action_block'
  | 'lead_sources'
  | 'timeline'
  | 'related_pipelines'
  | 'related_object_fields'
  | 'row';

type CanStartRowDragFn = Dispatch<SetStateAction<string>>;

type DisplayItemFn = (
  item: DraggableItem,
  isDragging: boolean,
  setCanStartRowDrag: CanStartRowDragFn,
  dragGroup?: DraggableGroup
) => ReactNode;

export interface ItemProps {
  item: DraggableItem;
  columnId: string;
  rowId: string;
  displayItem: DisplayItemFn;
  isDragging: boolean;
  placeholderClassName?: string;
  setCanStartRowDrag: CanStartRowDragFn;
}

export interface RowProps {
  children: ReactNode;
  className?: string;
  id: string;
  draggable?: boolean;
  placeholderClassName?: string;
  setCanStartDrag: CanStartRowDragFn;
}

export interface DraggableItem {
  id: string;
  label: string;
  metadata?: any;
  type: DraggableItemType;
  displayName?: string;
  internalName?: string;
  hidden?: boolean;
}

export interface DraggableGroup {
  id: string;
}

export interface DraggableItemWithMetadata extends DraggableItem {
  parent: string;
}

export interface GridStateValue {
  dragItem?: DraggableItemWithMetadata;
  setDragItem: Dispatch<SetStateAction<DraggableItemWithMetadata | undefined>>;
  dragGroup?: DraggableGroup;
  setDragGroup: Dispatch<SetStateAction<DraggableGroup | undefined>>;
  dropZones: DropZoneMap;
  setDropZones: Dispatch<SetStateAction<DropZoneMap>>;
  groupDropZones: DropZoneMap;
  setGroupDropZones: Dispatch<SetStateAction<DropZoneMap>>;
  registerChild: (
    dropZoneId: string,
    draggableId: string,
    element: HTMLDivElement
  ) => void;
  handleDrop: () => void;
  currentDropZone?: CurrentDropZone;
  handleDeleteRow: () => void;
  layout: LayoutState;
  placeholderPosition?: PlaceholderPosition;
  setPlaceholderPosition: Dispatch<
    SetStateAction<PlaceholderPosition | undefined>
  >;
}

export type LayoutState = {
  id: string;
  columns: {
    id: string;
    width: COLUMN_SIZE;
    items: DraggableItem[];
  }[];
}[];

export type ColumnClassName =
  | string
  | ((isDropZone: boolean, isHovered: boolean) => string);

export interface ColumnProps {
  items: DraggableItem[];
  id: string;
  rowId: string;
  columnEnd?: ColumnEndRenderer;
  displayItem: DisplayItemFn;
  width: COLUMN_SIZE;
  className?: ColumnClassName;
  placeholderClassName?: string;
  setCanStartRowDrag: Dispatch<SetStateAction<string>>;
}

export type DropZoneChildrenState = Record<string, DropZoneMap>;

export enum DropZoneChildrenActionType {
  REGISTER = 'register',
}
export interface DropZoneChildrenAction {
  type: DropZoneChildrenActionType;
  dropZoneId: string;
  draggableId: string;
  element: HTMLDivElement;
}

export type DropZoneReducer = Reducer<
  DropZoneChildrenState,
  DropZoneChildrenAction
>;

export enum LayoutActionType {
  DROP = 'drop',
  REPLACE = 'replace',
  DELETE = 'delete',
  DROP_GROUP = 'drop_group',
}

export interface LayoutAction {
  type: LayoutActionType;
  dropZone?: CurrentDropZone;
  dragItem?: DraggableItemWithMetadata;
  dragGroup?: DraggableGroup;
  initial?: LayoutState;
  rowId?: string;
}

export type LayoutReducer = Reducer<LayoutState, LayoutAction>;

export enum DropZoneDirection {
  UP = 'up',
  DOWN = 'down',
}

export interface CurrentDropZone {
  id: string;
  direction: DropZoneDirection;
}

export type ColumnEndRenderer = (
  rowId: string,
  columnId: string,
  isDropZone: boolean,
  isHovered: boolean
) => ReactNode;

export interface PlaceholderPosition {
  height: number;
  width: number;
  top: number;
}

export interface LayoutBuilderProps {
  onChange?: ChangeHandler;
  initial?: LayoutState;
  columnEnd?: ColumnEndRenderer;
  builderEnd?: ReactNode;
  revision?: number;
  displayItem: DisplayItemFn;
  columnClassName?: ColumnClassName;
  rowClassName?: string;
  placeholderClassName?: string;
  draggableRows?: boolean;
}

export type LayoutChangeHandler = (
  id: string,
  name: string,
  layout: LayoutState
) => void;

export interface IngestibleLayout {
  id: string;
  name: string;
  layout: LayoutState;
  active: boolean;
  tabs?: {
    automations?: boolean;
    messages?: boolean;
  };
  sharingSettings?: any;
}

export type IngestibleLayouts = IngestibleLayout[];

export interface LayoutOption {
  value: string;
  label: string;
  active: boolean;
}

export type CategoryOption = {
  value: string;
  label: string;
};

export type CategoryOptions = CategoryOption[];

export type EventType = {
  value: string;
  label: string;
};

export type EventTypes = EventType[];
