import { DISPLAY_VALUE_RESPONSES } from 'components/Wizards/Dashlet/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Donut from '..';

export const DonutDashletAdapter = ({ data, dashlet, ...props }) => {
  const height = dashlet?.layout?.h;
  const width = dashlet?.layout?.w;
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    return (
      data?.data.map((d) => {
        return {
          category: d.lead_source,
          type: d.type,
          value: d.value,
        };
      }) ?? []
    );
  }, [data]);

  const isValue = dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE;
  const isDataValue = data?.metric_type === DISPLAY_VALUE_RESPONSES.VALUE;

  return (
    <Donut
      {...props}
      isLoading={props.isLoading || isValue !== isDataValue}
      data={chartData}
      legendRows={height === 4 ? 3 : 2}
      small={height === 3}
      descriptor={
        isDataValue ? null : { plural: t('Leads'), singular: t('Lead') }
      }
      isValue={isDataValue}
      wide={width === 4}
    />
  );
};
