import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';

const defaultIconCSS = (sizing = 10, margin = 0) => css`
  & .DefaultIcon {
    margin-top: ${margin}px;
  }

  & .DefaultIcon i,
  & i.DefaultIcon {
    width: ${sizing}px;
    height: ${sizing}px;
  }

  & .DefaultIcon svg {
    color: ${grayScale.dark};
    width: ${sizing + 4}px;
    height: ${sizing}px;
  }
`;

export const StyledControl = styled.div`
  padding-right: 10px;
  display: flex;
  cursor: pointer;

  & .IconSelector__value-container {
    padding: 0;
    flex-grow: 1;
    padding-right: 6px;
    display: flex;
    position: unset;
    width: 16px;
  }

  & .IconSelector__single-value {
    display: flex;
    justify-content: center;
    color: ${grayScale.dark};
    width: 16px;
    overflow: visible;
    padding-top: 2px;
  }

  & .IconSelector__single-value i {
    color: ${grayScale.dark};
  }

  ${({ menuIsOpen }) =>
    menuIsOpen
      ? css`
          & .IconSelector__single-value i,
          & .IconSelector__single-value svg {
            color: ${colorsButton.blue.default} !important;
          }
        `
      : ''}

  & i.ElementIcon {
    margin-right: 0;
  }

  & .IconSelector__single-value > i {
    font-size: 12px;
  }

  & .IconSelector__indicators i {
    margin-left: 7px;
  }

  &:hover .IconSelector__indicators i {
    color: ${grayScale.dark};
  }

  ${defaultIconCSS(12)}
`;

const ICON_SQUARE_SIZE = 30;

export const StyledIconSelectorMenu = styled.div`
  & .IconSelector__menu {
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;

    border: none;
    box-shadow: none;
  }

  ${({ shift }) =>
    shift
      ? css`
          & .IconSelector__menu {
            left: -${shift}px;
          }
        `
      : ''}

  & .InnerMenu {
    height: 100%;
    width: 100%;
  }

  & .IconSelector__menu ul {
    padding: 10px;
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    align-items: flex-start;
  }

  & .IconSelector__menu li {
    width: ${ICON_SQUARE_SIZE}px;
    min-height: ${ICON_SQUARE_SIZE}px;
    max-height: ${ICON_SQUARE_SIZE}px;
  }

  & .IconSelector__menu li > div {
    width: ${ICON_SQUARE_SIZE}px;
    height: ${ICON_SQUARE_SIZE}px;
    padding: 6px 12px;
  }
`;

export const StyledIconSelectorOption = styled.li`
  & > div {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 2px;
  }

  & .IconSelector__option--is-focused {
    background: unset;
    color: ${colorsButton.blue.hover};
    cursor: pointer;
  }

  && .IconSelector__option--is-focused svg {
    color: ${colorsButton.blue.hover};
  }

  & .IconSelector__option--is-selected {
    background: unset;
    color: ${colorsButton.blue.hover};
    cursor: pointer;
    border: 1px solid ${colorsButton.blue.hover};
  }

  && .IconSelector__option--is-selected svg {
    color: ${colorsButton.blue.hover};
  }

  && i {
    font-size: ${ICON_SQUARE_SIZE / 2}px;
  }

  // This icon needs to be shifted slightly because it is not centered
  && .DefaultIcon.kizen-k {
    margin-left: 3px;
    margin-top: 2px;
  }

  ${defaultIconCSS(ICON_SQUARE_SIZE / 2)}
`;
