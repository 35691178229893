import { useCallback, useState } from 'react';
import { BROADCAST_FORMDATA } from '../components/ScheduleBroadcastModal/constants';
import { isCustomFilter } from 'components/Wizards/MetaFilters/types';
import { camelToSnakeCaseKeys } from 'services/helpers';

const propsToOmit = {
  countryField: true,
};

const pathsToOmit = {
  'email.filterConfig.query[n].filters[n].state': true,
  'email.filterConfig.query[n].filters[n].country': true,
  'email.filterConfig.query[n].filters[n].stateField': true,
  'email.filterConfig.query[n].filters[n].stateCondition': true,
  'email.filterConfig.query[n].filters[n].countryCondition': true,
  'automation.action.automations[n].deleted': true,
  'automation.filterConfig.invalid': true,
  'email.filterConfig.invalid': true,
};

const areEqual = (o1, o2, prop) => {
  switch (prop) {
    default:
      return o1 === o2;
  }
};

const o1xform = (o1, prop) => {
  switch (prop) {
    case 'email.action':
      return { id: o1?.id };
    default:
      return o1;
  }
};

const checkIfEqual = (o1, o2, prop = '') => {
  if (o1 && typeof o1 === 'object') {
    if (Array.isArray(o1)) {
      return (
        o1.length === o2?.length &&
        o1.every((v, i) => checkIfEqual(v, o2?.[i], `${prop}[n]`))
      );
    } else {
      return Object.entries(o1xform(o1, prop)).every(
        ([key, value]) =>
          propsToOmit[key] || checkIfEqual(value, o2?.[key], `${prop}.${key}`)
      );
    }
  } else {
    return pathsToOmit[prop] || areEqual(o1, o2, prop);
  }
};

export const useDirtyState = (
  initialData,
  data,
  onHide,
  enabled = true,
  filterContext
) => {
  const [show, setShow] = useState(false);
  const onChangeData = useCallback(
    (val) => {
      data.current = val;
    },
    [data]
  );

  const onCheckToHide = useCallback(() => {
    let combinedIntialData = initialData;
    const {
      setFilterHasErrors,
      setHasErrors,
      operation,
      filterOps: { build, validate } = {},
      originalFilterState,
    } = filterContext;

    let state = data.current;
    if (isCustomFilter(state[BROADCAST_FORMDATA.filterType])) {
      let filterConfig = null;
      const { errors, hasErrors } = validate();
      if (hasErrors) {
        setFilterHasErrors({ errors, hasErrors });
      } else {
        setHasErrors(false);
      }
      filterConfig = build(operation === 'and');

      state = { ...state, filterConfig };
      combinedIntialData = {
        ...combinedIntialData,
        filterConfig: camelToSnakeCaseKeys(originalFilterState.filterConfig),
        filterGroups: originalFilterState.groups,
        filterType: originalFilterState.type,
      };
    }

    !enabled ||
    checkIfEqual(
      combinedIntialData,
      state,
      initialData?.[BROADCAST_FORMDATA.type]
    )
      ? onHide()
      : setShow(true);
  }, [initialData, data, onHide, enabled, filterContext]);

  const onHideConfirmed = useCallback(() => {
    onHide();
    setShow(false);
    data.current = null;
  }, [onHide, data]);

  const onHideCanceled = useCallback(() => {
    setShow(false);
  }, []);

  return {
    showConfirmation: show,
    onChangeData,
    onCheckToHide,
    onHideConfirmed,
    onHideCanceled,
    filterContext,
  };
};
