import { useMemo } from 'react';
import { getUserLabel } from 'utility/TransformToSelectOptions';

/**
 * This function is used to set the default assignment for a self-referential primary relationship
 */
export const setDefaultAssignment = ({ key, value, label, modelFetchUrl }) => ({
  [key]: { value, label, modelFetchUrl },
});

/**
 * Determine if the current field is a primary relationship to itself
 */
const isPrimaryRelation = (rO, f, objectModel) =>
  objectModel.id === rO.relatedObject &&
  rO.fieldId === f.field &&
  ['primary', 'primary_for'].includes(rO.relationType);

/**
 * This function is used to determine if the current field is a self-referential primary relationship
 */
const potentialSelfReferentialPrimaryRelationship = (
  objectModel,
  rO,
  f,
  object
) => {
  // If a record has a self-referential ( other entity in the sane object context ) primary relationship field
  // then we should prioritize current record for association.
  if (isPrimaryRelation(rO, f, objectModel)) {
    return setDefaultAssignment({
      key: objectModel.id,
      value: object.id,
      label: object.display_name || object.displayName || object.name,
      modelFetchUrl: objectModel.fetchUrl,
    });
  }

  return setDefaultAssignment({
    key: rO.relatedObject,
    value: f.value[0].id || f.value || f.field,
    label: f.value[0].label || f.display_name || f.displayName || f.name,
    modelFetchUrl: objectModel.fetchUrl,
  });
};
/**
 * This function is used to determine if the current field is a self-referential primary relationship
 */
const potentialSelfReferentialPrimaryContactRelationship = (
  objectModel,
  rO,
  f
) => {
  // If a record has a self-referential ( other entity in the sane object context ) primary relationship field
  // then we should prioritize current record for association.
  // Contact context: we should not set the clientOption assignment for the contact field,
  // default contact would be set by default
  if (isPrimaryRelation(rO, f, objectModel)) {
    return {};
  }

  return {
    clientOption: {
      value: typeof f.value === 'object' ? f.value[0].id : f.value,
      label: typeof f.value === 'object' ? getUserLabel(f.value[0]) : f.name,
    },
  };
};
const isRelatedContact = (relatedObject) =>
  relatedObject?.entityName === 'Contact';
const relatedObjectExists = (dictionary, key) => dictionary?.[key];
const clientObjectExists = (realatedObjectAcc) =>
  realatedObjectAcc?.clientOption;

const isMatchingRelationType = (relatedObject, relationType, fieldId) =>
  relatedObject.fieldId === fieldId &&
  relatedObject.relationType === relationType;

export const getActivityPredefined = (
  object,
  objectModel,
  relationType = 'primary',
  noPredefined = false
) => {
  return objectModel?.relatedObjects && object?.fields
    ? object.fields.reduce(
        (fieldsAcc, field) => ({
          ...fieldsAcc,
          ...objectModel.relatedObjects.reduce(
            (realatedObjectAcc, relatedObject) => {
              if (
                isMatchingRelationType(relatedObject, relationType, field.field)
              ) {
                if (isRelatedContact(relatedObject)) {
                  if (!clientObjectExists(realatedObjectAcc)) {
                    // only add clientOption if it doesn't already exist
                    const clientOption =
                      potentialSelfReferentialPrimaryContactRelationship(
                        objectModel,
                        relatedObject,
                        field,
                        object
                      );

                    return {
                      ...realatedObjectAcc,
                      ...clientOption,
                    };
                  }
                } else {
                  if (
                    !relatedObjectExists(
                      fieldsAcc.defaultAssignment,
                      relatedObject.relatedObject
                    )
                  ) {
                    // only add default assignment if it doesn't already exist
                    const defaultAssignment =
                      potentialSelfReferentialPrimaryRelationship(
                        objectModel,
                        relatedObject,
                        field,
                        object
                      );

                    return {
                      ...realatedObjectAcc,
                      defaultAssignment: {
                        ...fieldsAcc.defaultAssignment,
                        ...defaultAssignment,
                      },
                    };
                  }
                }
              }
              // nothing added return accumulator
              return realatedObjectAcc;
            },
            fieldsAcc
          ),
        }),
        noPredefined
          ? {}
          : {
              defaultAssignment: {
                ...setDefaultAssignment({
                  key: objectModel.id,
                  value: object.id,
                  label:
                    object.display_name || object.displayName || object.name,
                  modelFetchUrl: objectModel.fetchUrl,
                }),
              },
            }
      )
    : {};
};

/**
 * This hook is used to get the default assignment for an activity
 *
 * @param object - The record that is being used to create the activity
 * @param objectModel - The object of the record that is being used to create the activity
 * @param relationType - The type of relationship ['primary', 'additional', 'primary_for', 'additional_for']
 * @param noPredefined - If true, then the default assignment will not be set otherwise it will be set to the current record
 * @returns {*}
 */
export const useGetActivityPredefined = (
  object,
  objectModel,
  relationType = 'primary',
  noPredefined = false
) => {
  return useMemo(() => {
    return getActivityPredefined(
      object,
      objectModel,
      relationType,
      noPredefined
    );
  }, [objectModel, object, noPredefined, relationType]);
};
