import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from '../../app/colors';
import { gutters, borderRadii } from '../../app/spacing';

export default styled.div`
  position: relative;

  ${({ addBorder }) =>
    addBorder &&
    css`
      border-radius: ${borderRadii.small};
      border: 1px solid ${grayScale.medium};
    `};
  // 230 is a nice looking minimum
  ${({ enableResize, minHeight, minWidth = 0 }) =>
    enableResize &&
    css`
      height: ${minHeight ||
      gutters.spacing(
        6
      )}px; // arbitury value that looks right to stop the resize going to small
      min-height: ${minHeight ||
      gutters.spacing(
        6
      )}px; // arbitury value that looks right to stop the resize going to small
      resize: both;
      overflow-x: hidden;

      max-width: 100%;
      ${minWidth &&
      css`
        min-width: ${minWidth}px;
      `}
    `}
  ${({ error }) =>
    error &&
    css`
      &&,
      &&:hover {
        border-radius: ${borderRadii.small};
        border: 1px solid ${colorsButton.red.hover};
      }
    `}
`;
