import { useCallback, useEffect, useMemo } from 'react';

export const useManualInteraction = (
  execute: (...args: any) => void,
  currentPage?: any,
  elementRef?: any,
  pending?: boolean,
  args: Record<string, string | number> = {}
) => {
  const interactableScripts = useMemo(() => {
    return currentPage?.event_scripts ?? {};
  }, [currentPage]);

  const handleClick = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (!pending) {
        const target = e.target;
        const scriptName = target.getAttribute('data-script');
        if (scriptName && interactableScripts[scriptName]) {
          execute(interactableScripts[scriptName], { ...args });
        }
      }
    },
    [pending, execute, interactableScripts, args]
  );

  useEffect(() => {
    const e = elementRef.current;

    if (e) {
      e.addEventListener('click', handleClick);
    }

    return () => {
      if (e) {
        e.removeEventListener('click', handleClick);
      }
    };
  }, [handleClick, currentPage, elementRef]);
};
