import { takeLatest, call, put, select, takeLeading } from 'redux-saga/effects';
import TeamMemberService from 'services/TeamMemberService';
import {
  buildPage as buildPageAction,
  buildPageFail,
  buildPageComplete,
  updatePageConfig,
  getCustomObjectConfig,
} from './record.redux';

function* buildPage({ payload }) {
  try {
    const { customObjectId } = payload;
    const { results } = yield call(
      TeamMemberService.getCustomObjectRecordPageConfig,
      customObjectId
    );
    yield put(buildPageComplete({ customObjectsConfig: results }));
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* setPageConfig({ payload }) {
  try {
    const { detailPageConfig } = yield select(
      getCustomObjectConfig(payload.customObjectId)
    );
    const updatedPageConfig = yield call(
      TeamMemberService.updateCustomObjectRecordPageConfig,
      {
        customObjectId: payload.customObjectId,
        detailPageConfig: { ...detailPageConfig, ...payload.detailPageConfig },
      }
    );
    yield put(updatePageConfig(updatedPageConfig));
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* recordDetailPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield takeLeading(updatePageConfig.type, setPageConfig);
}

export default recordDetailPage;
