import { ActionBar } from '@kizen/kds/ActionBar';
import { Button } from '@kizen/kds/Button';
import { SearchPill } from '@kizen/kds/SearchPill';
import { Spacer } from '@kizen/kds/Spacer';
import { Panel, VerticalTile } from '@kizen/kds/Tile';
import { Typography } from '@kizen/kds/Typography';
import Loader from '__components/Kizen/Loader';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from 'components/ToastProvider';
import useField from 'hooks/useField';
import produce from 'immer';
import _DetailsBlock from 'pages/Common/WorkflowPipeline/DetailsBlock';
import _PipelineModal from 'pages/Common/WorkflowPipeline/PipelineModal';
import {
  MAX_CACHE_AGE,
  STAGECACHE_SET_FIELDS,
} from 'pages/Common/WorkflowPipeline/constants';
import {
  getPipelineOptions,
  stageCacheReducer,
  addDisplayPercentage,
} from 'pages/Common/WorkflowPipeline/helpers';
import { DETAILS_RELATED_PIPELINES } from 'queries/query-keys';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import PipelineService from 'services/PipelineService';
import FieldService from 'services/FieldService';
import CustomObjectsService from 'services/CustomObjectsService';
import { getCustomObjectAccess } from 'store/authentication/selectors';
import { withErrorBoundary } from '../ErrorBoundary';
import { CustomObjectModel, Stage } from 'ts-components/types/fields';
import { ConfirmationModal } from 'components/Charts/ScheduledActivities/ConfirmationModal';
import { useModalControl } from 'hooks/useModalControl';
import AddReasonLostModal from '__pages/CustomObjects/RecordsPage/modals/AddReasonLostModal';
import AddReasonDisqualifiedModal from '__pages/CustomObjects/RecordsPage/modals/AddReasonDisqualifiedModal';

import { useAddReasonLostModal } from '__pages/CustomObjects/RecordsPage/modals/AddReasonLostModal/hooks';
import { useAddReasonDisqualifiedModal } from '__pages/CustomObjects/RecordsPage/modals/AddReasonDisqualifiedModal/hooks';
import { isReasonDisqualifiedField, isReasonLostField } from '__checks/fields';

import {
  useMenuList,
  LOAD_THRESHOLD,
} from 'ts-components/filters/hooks/useMenuList';

const DetailsBlock = _DetailsBlock as any;
const PipelineModal = _PipelineModal as any;

const isNaInt = (value: any) => isNaN(parseInt(value));

interface RelatedPipelinesBlockProps {
  customObjectModel: CustomObjectModel;
  id: string;
  isClient: boolean;
  clientObject: any;
  customObjectId?: string;
  displayName?: string;
  metadata: any;
  fieldState?: any;
  onChangeFieldState?: () => void;
  handleFieldBlur?: () => void;
  handleUpdateRecord?: (patch: any) => Promise<any>;
  saveFormData: () => void;
  resetAllFields: () => void;
  refetch: () => void;
  touchedFormData: boolean;
  canEdit: boolean;
}

interface useRelatedPipelinesQueryProps {
  id: string;
  pageSize?: number;
  filters?: any;
  params?: any;
  searchTerm?: string;
  metadata?: any;
}

const useRelatedPipelinesQuery = (props: useRelatedPipelinesQueryProps) => {
  const { id, pageSize = 20, searchTerm, metadata } = props;

  const queryClient = useQueryClient();

  const queryBody = metadata?.queryBody ?? {};

  const infiniteQuery = useInfiniteQuery({
    queryKey: DETAILS_RELATED_PIPELINES.LIST(
      id,
      pageSize,
      metadata,
      searchTerm
    ),
    queryFn: async ({ pageParam = 1 }) => {
      const params = {
        search: searchTerm,
        page: pageParam,
        ordering: 'percentage_chance_to_close',
      };
      const data = await FieldService.getRelatedPipelines(
        id,
        params,
        queryBody
      );
      return data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next
        ? new URL(lastPage.next).searchParams.get('page')
        : undefined;
    },
    getPreviousPageParam: (previousPage) => {
      return previousPage?.prev
        ? new URL(previousPage.prev).searchParams.get('page')
        : undefined;
    },
    // Disable the built-in refetching mechanism because we don't want unsaved data being errased
    refetchOnWindowFocus: false,
  });

  const refetchRelatedPipelines = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: DETAILS_RELATED_PIPELINES.REFRESH(id),
    });
  }, [queryClient, id]);

  const rawPipelineData = useMemo(() => {
    return (infiniteQuery?.data?.pages || [])
      .flatMap((page) => page.results)
      .map((rp: any) => addDisplayPercentage(rp));
  }, [infiniteQuery?.data]);

  const [pipelineData, setPipelineData] = useField(rawPipelineData);

  const { setMenuList, ref } = useMenuList(
    infiniteQuery.isFetchingNextPage,
    infiniteQuery.fetchNextPage,
    LOAD_THRESHOLD,
    infiniteQuery.hasNextPage
  );

  const attachedScrollRef = useRef(false);
  if (!attachedScrollRef.current) {
    attachedScrollRef.current = true;
    setTimeout(() => setMenuList(ref.current), 50);
  }

  return {
    infiniteQuery,
    rawPipelineData,
    refetchRelatedPipelines,
    pipelineData,
    pipelineDataIsLoading: infiniteQuery.isLoading,
    setPipelineData,
    queryBody,
    ref,
  };
};

const Block = (props: RelatedPipelinesBlockProps) => {
  const {
    id,
    customObjectModel,
    clientObject,
    displayName,
    metadata,
    fieldState,
    onChangeFieldState,
    handleFieldBlur,
    handleUpdateRecord,
    saveFormData,
    resetAllFields,
    canEdit,
    touchedFormData,
    refetch,
  } = props;

  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const stageCache = useRef<any>({});
  const [showToast] = useToast();
  const [showAddModal, setShowAddModal] = useState(false);

  const aggregateId = customObjectModel?.id ?? clientObject?.id;

  const reasonsLostData = useRef<any>(null);
  const reasonsDisqualifiedData = useRef<any>(null);

  const [addReasonLostModalProps, addReasonLostModal] = useAddReasonLostModal({
    handleUpdate: async ({ patch }: { patch: any }) => {
      await PipelineService.updatePipelineRecord(
        reasonsLostData.current.reasonsDialogData[0].record.id,
        reasonsLostData.current.pipelineModel.id,
        patch,
        undefined,
        false
      );
      reasonsLostData.current = null;
    },
  });

  const [addReasonDisqualifiedModalProps, addReasonDisqualifiedModal] =
    useAddReasonDisqualifiedModal({
      handleUpdate: async ({ patch }: { patch: any }) => {
        await PipelineService.updatePipelineRecord(
          reasonsDisqualifiedData.current.reasonsDialogData[0].record.id,
          reasonsDisqualifiedData.current.pipelineModel.id,
          patch,
          undefined,
          false
        );
        reasonsDisqualifiedData.current = null;
      },
    });

  const [
    isConfirmRelatedEntityModalOpen,
    {
      showModal: showConfirmRelatedEntityModal,
      hideModal: hideConfirmRelatedEntityModal,
    },
  ] = useModalControl();

  const {
    pipelineData,
    pipelineDataIsLoading,
    setPipelineData,
    refetchRelatedPipelines,
    queryBody,
    ref,
  } = useRelatedPipelinesQuery({
    id,
    searchTerm: search,
    metadata,
  });

  const checkStage = (stages: Stage[], stageId: string) =>
    stages?.length && stages?.find((item) => item.id === stageId);

  const handleGetStages = useCallback(async (pipelineId: string) => {
    if (
      stageCache.current[pipelineId] &&
      Date.now() - stageCache.current[pipelineId].timeStamp < MAX_CACHE_AGE
    ) {
      return {
        pipelineId,
        stages: stageCache.current[pipelineId].stages,
      };
    }
    // fetch the stages and cache it
    const stages = await PipelineService.getStagesListByModel(pipelineId);
    // cache it
    stageCache.current = stageCacheReducer(stageCache, {
      type: STAGECACHE_SET_FIELDS,
      id: pipelineId,
      stages,
    });

    return { pipelineId, stages };
  }, []);

  const { customObjectEntities } = useSelector(getCustomObjectAccess);

  const pipelineOptions = useMemo(() => {
    const options = getPipelineOptions(
      customObjectModel?.relatedObjects ?? clientObject?.relatedObjects,
      customObjectEntities
    ).sort((a, b) => a.label.localeCompare(b.label));

    if (metadata?.relatedPipelinesIncludeAll !== false) {
      return options;
    }

    return options.filter((o) => (queryBody.objectIds || []).includes(o.value));
  }, [
    customObjectModel,
    clientObject,
    customObjectEntities,
    metadata?.relatedPipelinesIncludeAll,
    queryBody,
  ]);

  const updateStatus = useCallback(
    async (record: any) => {
      try {
        const { id: recordId, displayPercentage, stage, objectId } = record;

        setPipelineData((prev: any) => {
          const nextPipeline = produce(prev, (draft: any) => {
            const f = draft.find((pl: any) => pl.id === recordId);

            if (f) {
              // update the two fields & options
              f.displayPercentage = displayPercentage;
              f.stage = stage;
            }
          });
          return nextPipeline;
        });

        const [pipelineModel] = (await Promise.all<CustomObjectModel | void>([
          FieldService.getModel({
            id: objectId,
          }),

          PipelineService.updatePipelineRecord(record.id, record.objectId, {
            stageId: record.stage.id,
          }),
        ])) as [CustomObjectModel, any];

        let relatedFields;
        let entity;

        showToast({
          variant: toastVariant.SUCCESS,
          message: t('The stage was updated.'),
        });

        const lostStages = pipelineModel?.pipeline?.stages?.filter(
          ({ status }) => status === 'lost'
        );

        const disqualifiedStages = pipelineModel?.pipeline?.stages?.filter(
          ({ status }) => status === 'disqualified'
        );

        const isLostStage = checkStage(lostStages, stage.id);
        const isDisqualifiedStage = checkStage(disqualifiedStages, stage.id);

        if (isLostStage || isDisqualifiedStage) {
          [relatedFields, entity] = (await Promise.all([
            CustomObjectsService.getCustomModelFields(objectId),
            PipelineService.getPipelineRecord({
              id: record.id,
              objectId,
            }),
          ])) as [any, any];
        }

        if (isDisqualifiedStage) {
          const reasonDisqualifiedField = relatedFields?.find(
            isReasonDisqualifiedField
          );

          reasonsDisqualifiedData.current = {
            reasonsDialogData: stage
              ? [
                  {
                    record: {
                      ...entity,
                      fields: Object.values(entity.fields).map(
                        (field: any) => ({
                          ...field,
                          field: field.id,
                        })
                      ),
                    },
                    stageId: stage.id,
                  },
                ]
              : [],
            reasonDisqualifiedField,
            pipelineModel,
          };

          addReasonDisqualifiedModal.show();
        }

        if (isLostStage) {
          const reasonLostField = relatedFields?.find(isReasonLostField);
          reasonsLostData.current = {
            reasonsDialogData: stage
              ? [
                  {
                    record: {
                      ...entity,
                      fields: Object.values(entity.fields).map(
                        (field: any) => ({
                          ...field,
                          field: field.id,
                        })
                      ),
                    },
                    stageId: stage.id,
                  },
                ]
              : [],
            reasonLostField,
            pipelineModel,
          };
          addReasonLostModal.show();
        }

        refetchRelatedPipelines();
      } catch {
        showToast({
          variant: toastVariant.FAILURE,
          message: t('The stage could not be updated.'),
        });
      }
    },
    [
      refetchRelatedPipelines,
      setPipelineData,
      showToast,
      addReasonLostModal,
      addReasonDisqualifiedModal,
      t,
    ]
  );

  const handleUpdate = useCallback(async () => {
    await saveFormData();
    hideConfirmRelatedEntityModal();
    setShowAddModal(true);
  }, [saveFormData, hideConfirmRelatedEntityModal, setShowAddModal]);

  const handleDismiss = useCallback(async () => {
    resetAllFields();
    hideConfirmRelatedEntityModal();
  }, [hideConfirmRelatedEntityModal, resetAllFields]);

  const blockHeight = useMemo(
    () =>
      !isNaInt(metadata?.relatedPipelinesBlockHeight)
        ? `${metadata?.relatedPipelinesBlockHeight - 2}px`
        : '385px', // fallback for blocks with out config
    [metadata?.relatedPipelinesBlockHeight]
  );

  const blockHeightMed = useMemo(
    () =>
      !isNaInt(metadata?.relatedPipelinesBlockHeight)
        ? `${metadata?.relatedPipelinesBlockHeight - 2}px`
        : '236px', // fallback for blocks with out config
    [metadata?.relatedPipelinesBlockHeight]
  );

  const body = (
    <>
      <div
        className="w-full max-h-[var(--block-height)] h-[var(--block-height)] @md-tile/Tile:max-h-[var(--block-height-m)] @md-tile/h-[var(--block-height-m)] flex flex-col kds-menu-boundary "
        data-qa="related-workflows"
        style={
          {
            '--block-height': blockHeight,
            '--block-height-m': blockHeightMed,
          } as Record<string, string>
        }
      >
        <div className="kds px-spacer-20 pt-spacer-10">
          <ActionBar
            leftChildren={
              <Typography variant="header" weight="semibold" size="lg">
                {displayName || t('Related Pipelines')}
              </Typography>
            }
            rightChildren={
              canEdit ? (
                <div className="kds flex items-center gap-x-spacer-15">
                  <SearchPill
                    placeholder={t('Search')}
                    value={search}
                    onChange={setSearch}
                    qa="related-workflows-search"
                  />
                  <Button
                    variant="text"
                    leftIcon="action-add"
                    onClick={() => {
                      if (touchedFormData) {
                        showConfirmRelatedEntityModal();
                      } else {
                        setShowAddModal(true);
                      }
                    }}
                    qa={{
                      action: 'add-related-pipeline-record',
                    }}
                  >
                    {t('Add')}
                  </Button>
                </div>
              ) : (
                <div className="kds flex items-end gap-x-spacer-15 pt-spacer-10">
                  <SearchPill
                    placeholder={t('Search')}
                    value={search}
                    onChange={setSearch}
                    qa="related-workflows-search"
                  />
                </div>
              )
            }
          />
        </div>

        {!pipelineData || !pipelineOptions || pipelineDataIsLoading ? (
          <>
            <Loader loading style={{ minHeight: '200px' }} />
            <Spacer mode="horizontal" size={20} />
          </>
        ) : (
          <div
            ref={ref}
            className="min-h-0 kds scroll-wrapper kds-expanding-scrollbar "
          >
            <div
              className="kds flex flex-col  h-auto pl-spacer-20 pr-spacer-12"
              data-qa="related-workflows-details"
            >
              <div>
                <Spacer mode="horizontal" size={10} />
                <div className="kds flex flex-col gap-y-spacer-20">
                  {(pipelineData || []).map((record: any) => (
                    <DetailsBlock
                      key={record.id}
                      record={{ ...record }}
                      getStages={handleGetStages}
                      updateStatus={updateStatus}
                      customObjectEntities={customObjectEntities}
                      responsive
                    />
                  ))}
                </div>
                {pipelineData?.length ? (
                  <Spacer mode="horizontal" size={20} />
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <PipelineModal
        id={id}
        objectId={aggregateId}
        clientObject={clientObject}
        pipelineOptions={pipelineOptions}
        show={showAddModal}
        setShowModal={setShowAddModal}
        onAdd={refetchRelatedPipelines}
        relationshipFields={metadata?.relatedPipelinesRelationshipFields}
        fieldState={fieldState}
        onChangeFieldState={onChangeFieldState}
        handleUpdateRecord={handleUpdateRecord}
        handleFieldBlur={handleFieldBlur}
        refetch={refetch}
      />
      <ConfirmationModal
        show={isConfirmRelatedEntityModalOpen}
        onConfirm={handleUpdate}
        onHide={hideConfirmRelatedEntityModal}
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        onAdditionalConfirm={handleDismiss}
        actionBtnColor="green"
        buttonText={t('Save')}
      >
        {t(
          'The record has unsaved changes. Would you like to save them or discard them before creating the new record?'
        )}
      </ConfirmationModal>
      {addReasonLostModalProps.show &&
      reasonsLostData.current?.reasonsDialogData?.length ? (
        <AddReasonLostModal
          model={reasonsLostData.current?.pipelineModel}
          reasonLostField={reasonsLostData.current?.reasonLostField}
          reasonsDialogData={reasonsLostData.current?.reasonsDialogData}
          {...addReasonLostModalProps}
        />
      ) : null}
      {addReasonDisqualifiedModalProps.show &&
      reasonsDisqualifiedData.current?.reasonsDialogData?.length ? (
        <AddReasonDisqualifiedModal
          model={reasonsDisqualifiedData.current?.pipelineModel}
          reasonDisqualifiedField={
            reasonsDisqualifiedData.current?.reasonDisqualifiedField
          }
          reasonsDialogData={reasonsDisqualifiedData.current?.reasonsDialogData}
          {...addReasonDisqualifiedModalProps}
        />
      ) : null}
    </>
  );

  return <VerticalTile padding={0} gap={0} middle={<Panel>{body}</Panel>} />;
};

export const RelatedPipelinesBlock = withErrorBoundary(Block);
