import { useCallback } from 'react';
import { usePermissionsContext } from './context';
import { addPermissionsForSection } from '../src/state-ops';
import type { PermissionConfig } from '../src/types';

export const useAddPermissionsToSection = () => {
  const ctx = usePermissionsContext();

  return useCallback(
    (
      id: string,
      permissions: PermissionConfig[],
      { containsFieldPermissions = false } = {}
    ) => {
      addPermissionsForSection(permissions, id, ctx);

      if (containsFieldPermissions && ctx.fields_loaded.has(id)) {
        ctx.fields_loaded.get(id)!.next(true);
      }
    },
    [ctx]
  );
};
