import { doFilterSetsHaveError } from 'ts-filters/utils';

export const getReasonsDialogData = ({ recordsPage }) => {
  return recordsPage.reasonsDialogData;
};
export const getReasonsDisqualifiedDialogData = ({ recordsPage }) => {
  return recordsPage.reasonsDisqualifiedDialogData;
};
export const getRecordsIsFetchingSelector = ({ recordsPage }) =>
  recordsPage.isFetchingRecords;

export const getNeedToLoadCountsSelector = ({ recordsPage }) =>
  recordsPage.needToLoadCounts;

export const getRecordsIsFilteringSelector = ({ recordsPage }) =>
  recordsPage.isFiltering;

export const getRecordsPageReady = ({ recordsPage }) => recordsPage.pageReady;

export const getFilterGroupReason = ({ recordsPage }) =>
  recordsPage.filterGroupReason;

export const getIsLoadingView = ({ recordsPage }) => recordsPage.isLoadingView;

export const getHasFilterError = (state) => {
  const filters = state.recordsPage.filters.config;
  const errors = state.recordsPage.filters.errors;

  if (!filters || filters.query) {
    return false;
  }

  const errorsWhenLoadingSteps = doFilterSetsHaveError(filters);
  const errorsWhenPerformingSearch = Boolean(
    errors?.parsedErrors.some(Boolean)
  );

  return errorsWhenLoadingSteps || errorsWhenPerformingSearch;
};
