import get from 'lodash/get';
import { useCallback } from 'react';

/**
 * A `useCallback` hook to set a react `useState` value to a specific field of the callback's response object.
 *
 * @remarks uses lodash's get utility to obtain the object's nested value.
 *
 * @param {function} setter - a value setter from a `useState` response
 * @param {string} path - string representation of the object's nested value. See https://lodash.com/docs/4.17.15#get
 * @returns a react `useCallback` hook
 */
export const usePathSetter = (setter, path) => {
  return useCallback(
    (obj) => {
      setter(get(obj, path));
    },
    [setter, path]
  );
};
