import { useMemo } from 'react';
import styled from '@emotion/styled';

import { fontSizes } from 'app/typography';
import { colorsPrimary } from 'app/colors';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';
import ActionCell from 'components/Tables/ActionCell';
import SelectInline from 'components/Inputs/inline/Select';
import Select from 'components/Inputs/Select';
import ValidatedField from 'components/Kizen/ValidatedField';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { useTranslation } from 'react-i18next';

const paddingSize = {
  small: '20px',
  big: '40px',
};

const columnSize = {
  spacer: '20px',
  name: '300px',
  customObjectName: '150px',
  status: '90px',
  numberActive: '75px',
  numberPaused: '82px',
  numberCompleted: '102px',
  created: '145px',
  actions: '47px',
};

export const WrapperStatus = styled.p`
  font-size: ${fontSizes.text};
  margin-bottom: 0;
`;

const NoActionsCell = styled.div`
  width: 14px;
  height: 20px;
`;

export const actions = {
  edit: 'edit',
  deactivate: 'deactivate',
  activate: 'activate',
  duplicate: 'duplicate',
  history: 'history',
  delete: 'delete',
};

const deleteOption = (t) => ({ value: actions.delete, label: t('Delete') });

const TableActionCell = ({ data = {}, isMobile = false, ...props }) => {
  const { t } = useTranslation();
  const options = useMemo(
    () =>
      data.customObject.access?.delete
        ? [
            !isMobile && { value: actions.history, label: t('History') },
            !isMobile && deleteOption(t),
          ].filter(Boolean)
        : [
            !isMobile && { value: actions.edit, label: t('Edit') },
            data.active
              ? { value: actions.deactivate, label: t('Deactivate') }
              : { value: actions.activate, label: t('Activate') },
            !isMobile && { value: actions.duplicate, label: t('Duplicate') },
            !isMobile && { value: actions.history, label: t('History') },
            !isMobile && deleteOption(t),
          ].filter(Boolean),
    [isMobile, data, t]
  );
  return (data.customObject.access?.view || data.customObject.access?.delete) &&
    options.length ? (
    <ActionCell
      title={t('Edit Automation')}
      menuType="absolute"
      options={options}
      data={data}
      {...props}
    />
  ) : (
    <NoActionsCell />
  );
};

export const MobileActionCell = styled(TableActionCell)`
  i {
    width: 15px;
    svg {
      width: 15px;
      height: auto;
    }
  }
`;

export const statusOptions = (t) => [
  {
    value: 'true',
    label: t('Active'),
    color: colorsPrimary.green.dark,
  },
  {
    value: 'false',
    label: t('Inactive'),
    color: colorsPrimary.orange.dark,
  },
];

export const getAutomationsColumns = ({
  onUpdateAutomation,
  onSelectAction,
  t,
}) => [
  {
    id: 'spacer-1',
    headCell: <SizedCell width={columnSize.spacer} />,
    cell: <SizedCell width={columnSize.spacer} />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        label={t('Automation Name')}
        width={columnSize.name}
        padding={paddingSize.big}
      />
    ),
    cell: <SizedCell width={columnSize.name} padding={paddingSize.big} />,
    format: function NameCell(value, automation) {
      const { access } = automation.customObject;
      const handleSubmit = async (data) => {
        if (data !== value) {
          await onUpdateAutomation({
            id: automation.id,
            name: data,
            lastRevision: automation.revision,
          });
        }
      };

      return access?.view ? (
        <ValidatedField
          type="link"
          to={`/automation/${automation.id}/`}
          value={value}
          onSubmit={handleSubmit}
        />
      ) : (
        <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'customObjectName',
    headCell: (
      <SortableHeadCell
        label={t('Automation Object')}
        width={columnSize.customObjectName}
        padding={paddingSize.big}
      />
    ),
    cell: (
      <SizedCell
        width={columnSize.customObjectName}
        padding={paddingSize.big}
      />
    ),
    format: function modelCell(value) {
      return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
    },
  },
  {
    id: 'status',
    headCell: (
      <SortableHeadCell
        label={t('Status')}
        width={columnSize.status}
        padding={paddingSize.big}
      />
    ),
    cell: <SizedCell width={columnSize.status} padding={paddingSize.big} />,
    format: function statusCell(data, automation) {
      const { access } = automation.customObject;
      const option = statusOptions(t).find(
        ({ value }) => value === String(data)
      );
      return (
        <SelectInline
          onSubmit={async (opt) => {
            await onUpdateAutomation({
              id: automation.id,
              active: opt.value === 'true',
              lastRevision: automation.revision,
            });
          }}
          readOnly={!access?.view}
        >
          <Select
            value={option}
            options={statusOptions(t)}
            showClearButton={false}
            isColored
          />
        </SelectInline>
      );
    },
  },
  {
    id: 'numberActive',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('# Active')}
        width={columnSize.numberActive}
        padding={paddingSize.big}
      />
    ),
    cell: (
      <SizedCell width={columnSize.numberActive} padding={paddingSize.big} />
    ),
    format: function activeCell(value) {
      return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
    },
  },
  {
    id: 'numberPaused',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('# Paused')}
        width={columnSize.numberPaused}
        padding={paddingSize.big}
      />
    ),
    cell: (
      <SizedCell width={columnSize.numberPaused} padding={paddingSize.big} />
    ),
    format: function pausedCell(value) {
      return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
    },
  },
  {
    id: 'numberCompleted',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('# Completed')}
        width={columnSize.numberCompleted}
        padding={paddingSize.big}
      />
    ),
    cell: (
      <SizedCell width={columnSize.numberCompleted} padding={paddingSize.big} />
    ),
    format: function completedCell(value) {
      return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
    },
  },
  {
    id: 'created',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Date Created')}
        width={columnSize.created}
        padding={paddingSize.big}
      />
    ),
    cell: <SizedCell width={columnSize.created} padding={paddingSize.big} />,
    format: (created) => (
      <DateTimeInputInline readOnly value={created} showDateTooltip />
    ),
  },
  {
    id: 'actions',
    headCell: (
      <SubActionHeadCell
        width={columnSize.actions}
        padding={paddingSize.small}
      />
    ),
    cell: (
      <TableActionCell
        width={columnSize.actions}
        padding={paddingSize.small}
        onSelectAction={onSelectAction}
      />
    ),
  },
];
