import { StyledScrollContainer } from './styles';
import useExpandingVerticalScrollbar from 'hooks/useExpandingVericalScrollbar';
import React from 'react';

const CustomMenuList = ({ children, ...props }) => {
  const { containerProps: rightContainerProps } =
    useExpandingVerticalScrollbar();

  return (
    <StyledScrollContainer
      {...rightContainerProps}
      {...props}
      // force a re-render when the number of visible options changes so the expanding
      // scrollbar layout can be re-calculated
      key={React.Children.count(children)}
    >
      <div>{children}</div>
    </StyledScrollContainer>
  );
};

export default CustomMenuList;
