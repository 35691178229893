import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import Cols from 'components/Layout/Cols';

export const StyledButton = styled(Button)`
  padding-right: 0;
`;

export const StyledCols = styled(Cols)`
  row-gap: 30px;
`;

export const StyledSubheader = styled(KizenTypography)`
  && {
    margin-bottom: 15px;
  }
`;
