import styled from '@emotion/styled';
import Select from 'components/Inputs/Select';
import { ModalBody } from '..';

export const StyledSelect = styled(Select)`
  margin-bottom: 15px;
`;

export const StyledModalBody = styled(ModalBody)`
  > :last-child {
    margin-bottom: 0;
  }
`;

export const CustomizeFieldOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  i {
    margin-left: 5px;
  }
`;
