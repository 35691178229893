import { PageContentContainer } from 'components/Layout/PageContentWidth';
import {
  CalendarControls,
  CalendarGrid,
  CalendarWrapper,
  LeftColumn,
  StyledBusinessCalendar,
} from '../styled';
import { useNavBarState } from 'app/navBarController';
import { IncrementalDatePicker } from 'components/Calendar/components/IncrementalDatePicker';
import Button from 'components/Button';
import { useCalendar } from 'components/Calendar';
import { useTranslation } from 'react-i18next';
import { useCalendarData } from '../hooks/useCalendarData';
import { LeftColumnControls } from '../components/LeftColumnControls';
import { NAV_HEIGHT } from '../constants';
import ConfirmNavigationWithActionModal from 'components/Modals/presets/ConfirmNavigationWithAction';

export const BusinessCalendar = ({ access }) => {
  const { t } = useTranslation();

  const { height } = useNavBarState();
  const heightOffset = height + NAV_HEIGHT;
  const disabled = !access.businessHolidays?.edit;

  const [date, calendarView, { next, previous, setToday }] = useCalendar();

  const {
    loadingSettings,
    loadingHolidays,
    calendarSettings,
    setCalendarSettings,
    settingsLookups,
    submitting,
    onSubmitSettings,
    isDirty,
  } = useCalendarData(date, calendarView);

  return (
    <PageContentContainer data-qa="business-calendar">
      <CalendarGrid heightOffset={heightOffset}>
        <LeftColumn
          heightOffset={heightOffset}
          data-qa="business-calendar-controls"
        >
          <LeftColumnControls
            disabled={disabled}
            calendarSettings={calendarSettings}
            setCalendarSettings={setCalendarSettings}
          />
        </LeftColumn>
        <CalendarControls>
          <Button variant="light" color="blue" noSpace onClick={setToday}>
            {t('Today')}
          </Button>
          <IncrementalDatePicker
            view={calendarView}
            previous={previous}
            next={next}
            date={date}
          />
          <div>
            {access.businessHolidays?.edit ? (
              <Button
                color="green"
                noSpace
                onClick={onSubmitSettings}
                disabled={
                  !isDirty || submitting || loadingSettings || loadingHolidays
                }
              >
                {t('Save')}
              </Button>
            ) : null}
          </div>
        </CalendarControls>
        <CalendarWrapper
          calendarView={calendarView}
          heightOffset={heightOffset}
          data-qa="scheduled-events-calendar"
        >
          <StyledBusinessCalendar
            date={date}
            loading={loadingSettings || loadingHolidays}
            {...settingsLookups}
          />
        </CalendarWrapper>
      </CalendarGrid>
      <ConfirmNavigationWithActionModal
        when={isDirty}
        heading={t('Business Calendar Has Unsaved Changes')}
        buttonText={t('Save')}
        actionBtnColor="green"
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        action={onSubmitSettings}
      >
        {t('All unsaved changes will be lost unless you save your settings.')}
      </ConfirmNavigationWithActionModal>
    </PageContentContainer>
  );
};
