import { forwardRef } from 'react';

import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';

import { withCellWrapper } from '../helpers';

const _LastModifiedCell = forwardRef(
  ({ object: record, field, shouldFocusNext }, ref) => {
    const value = record.updated;

    return (
      <DateTimeInputInline
        field={field}
        readOnly
        value={value}
        showDateTooltip
        shouldFocusNext={shouldFocusNext}
      />
    );
  }
);

export const LastModifiedCell = withCellWrapper(_LastModifiedCell);
