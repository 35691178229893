import {
  addDays,
  subDays,
  startOfMonth,
  startOfYear,
  setYear,
  endOfMonth,
  endOfYear,
} from 'date-fns';
import { setTimingMargin } from './utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getBusinessTimeZone } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';

const deafaultRanges = {
  today: {
    label: 'today',
    value: [
      setTimingMargin(new Date()).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
    customPlaceholderLabel: 'Today',
    localizedPlaceholderLabel: (t) => t('Today'),
  },
  yesterday: {
    label: 'yesterday',
    value: [
      setTimingMargin(addDays(new Date(), -1)).toString(),
      setTimingMargin(addDays(new Date(), -1), 'right').toString(),
    ],
    closeOverlay: false,
    customPlaceholderLabel: 'Yesterday',
    localizedPlaceholderLabel: (t) => t('Yesterday'),
  },
  thisMonth: {
    label: 'thisMonth',
    value: [
      setTimingMargin(startOfMonth(new Date())).toString(),
      setTimingMargin(endOfMonth(new Date()), 'right').toString(),
    ],
    closeOverlay: false,
    default: true,
    customPlaceholderLabel: 'This Month',
    localizedPlaceholderLabel: (t) => t('This Month'),
  },
  last7Days: {
    label: 'last7Days',
    value: [
      setTimingMargin(subDays(new Date(), 6)).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
  },
  last30Days: {
    label: 'last30Days',
    value: [
      setTimingMargin(subDays(new Date(), 29)).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
  },
  last180Days: {
    label: 'last180Days',
    value: [
      setTimingMargin(subDays(new Date(), 179)).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
  },
  thisYear: {
    label: 'thisYear',
    value: [
      setTimingMargin(startOfYear(new Date())).toString(),
      setTimingMargin(endOfYear(new Date()), 'right').toString(),
    ],
    closeOverlay: false,
    customPlaceholderLabel: 'This Year',
    localizedPlaceholderLabel: (t) => t('This Year'),
  },
  allTime: {
    label: 'allTime',
    value: [
      // hard coded to Jan 1, 2000... for now, potentially a prop to pass in

      setTimingMargin(setYear(startOfYear(new Date()), 2000)).toString(),
      setTimingMargin(new Date(), 'right').toString(),
    ],
    closeOverlay: false,
    isAllTime: true,
    customPlaceholderLabel: 'All Time',
    localizedPlaceholderLabel: (t) => t('All Time'),
  },
  allTime1900to2100: {
    label: 'allTime',
    value: [
      setTimingMargin(setYear(startOfYear(new Date()), 1900)).toString(),
      setTimingMargin(setYear(endOfYear(new Date()), 2100)).toString(),
    ],
    closeOverlay: false,
    isAllTime: true,
    customPlaceholderLabel: 'All Time',
    localizedPlaceholderLabel: (t) => t('All Time'),
  },
};

export default deafaultRanges;

const MIN_TIMEZONE_DIFFERENCE = 15;
const INTERVAL = MIN_TIMEZONE_DIFFERENCE * 60 * 1000;

export const useBusinessTimezoneRanges = (enabled = false) => {
  const businessTimezone = useSelector(getBusinessTimeZone);
  const timeZone = enabled ? businessTimezone : undefined;
  const [todayDate, setTodayDate] = useState(() =>
    new Date().toLocaleDateString('en-US', {
      timeZone,
    })
  );
  const todayDateRef = useRef(todayDate);

  useEffect(() => {
    const checkTodayDate = () => {
      const today = new Date().toLocaleDateString('en-US', {
        timeZone,
      });
      if (today !== todayDateRef.current) {
        todayDateRef.current = today;
        setTodayDate(today);
      }
    };

    checkTodayDate();
    // we want to check and update the today date every 00 / 15 / 30 / 45 minutes
    // of each hour, so we calculate the first timeout to the next quarter
    // and then check it every 15 minutes
    const minutes = new Date().getMinutes();

    let interval = setInterval(
      () => {
        checkTodayDate();
        clearInterval(interval);
        interval = setInterval(() => {
          checkTodayDate();
        }, INTERVAL);
      },
      Math.ceil(minutes / MIN_TIMEZONE_DIFFERENCE) * MIN_TIMEZONE_DIFFERENCE -
        minutes
    );

    return () => clearInterval(interval);
  }, [timeZone]);

  return useMemo(() => {
    if (!enabled) {
      return {
        todayDateInBusinessTimeZone: todayDate,
        ranges: deafaultRanges,
      };
    }
    const yesterday = addDays(new Date(), -1).toLocaleDateString('en-US', {
      timeZone,
    });
    return {
      todayDateInBusinessTimeZone: todayDate,
      ranges: [
        {
          label: 'today',
          value: [todayDate, todayDate],
          closeOverlay: false,
          customPlaceholderLabel: true,
          localizedPlaceholderLabel: (t) => t('Today'),
        },
        {
          label: 'yesterday',
          value: [yesterday, yesterday],
          closeOverlay: false,
          customPlaceholderLabel: true,
          localizedPlaceholderLabel: (t) => t('Yesterday'),
        },
        {
          label: 'thisMonth',
          value: [
            setTimingMargin(startOfMonth(new Date(todayDate))).toString(),
            setTimingMargin(
              endOfMonth(new Date(todayDate)),
              'right'
            ).toString(),
          ],
          closeOverlay: false,
          customPlaceholderLabel: true,
          localizedPlaceholderLabel: (t) => t('This Month'),
        },
        {
          label: 'last7Days',
          value: [
            subDays(new Date(), 6).toLocaleDateString('en-US', {
              timeZone,
            }),
            todayDate,
          ],
          closeOverlay: false,
        },
        {
          label: 'last30Days',
          value: [
            subDays(new Date(), 29).toLocaleDateString('en-US', {
              timeZone,
            }),
            todayDate,
          ],
          closeOverlay: false,
        },
        {
          label: 'thisYear',
          value: [
            setTimingMargin(startOfYear(new Date(todayDate))).toString(),
            setTimingMargin(endOfYear(new Date(todayDate)), 'right').toString(),
          ],
          closeOverlay: false,
          customPlaceholderLabel: true,
          localizedPlaceholderLabel: (t) => t('This Year'),
        },
        {
          label: 'allTime',
          value: [
            setTimingMargin(setYear(startOfYear(new Date()), 2000)).toString(),
            todayDate,
          ],
          closeOverlay: false,
          isAllTime: true,
          default: true,
          customPlaceholderLabel: true,
          localizedPlaceholderLabel: (t) => t('All Time'),
        },
      ],
    };
  }, [timeZone, todayDate, enabled]);
};
