import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useChartData = (data) => {
  const { t } = useTranslation();

  const { overalLine, overalSummary, smartSummary } = useMemo(() => {
    const {
      stats_with_smart_sending: withSmartSending = [],
      stats_all: withoutSmartSending = [],
    } = data?.data ?? {};
    const overalLine = [];
    const overalSummary = [];
    const smartSummary = [];
    withSmartSending.forEach((curr) => {
      if (curr.type === 'summary_values') {
        smartSummary.push(curr);
      }
    });

    withoutSmartSending.forEach((curr) => {
      if (curr.type === 'line_values') {
        overalLine.push(curr);
      } else if (curr.type === 'summary_values') {
        overalSummary.push(curr);
      }
    });

    return { overalLine, overalSummary, smartSummary };
  }, [data?.data]);

  const stages = useMemo(() => {
    const summaryData = overalSummary?.[0];
    const dataMap =
      summaryData?.values?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.type]: curr,
        };
      }, {}) ?? {};

    return [
      {
        value: dataMap.delivered?.value,
        label: t('DELIVERED'),
        percentage:
          typeof dataMap.opened_percentage?.value === 'number'
            ? dataMap.opened_percentage.value / 100
            : null,
      },
      {
        value: dataMap.opened?.value,
        label: t('OPENED'),
        percentage:
          typeof dataMap.clicked_percentage?.value === 'number'
            ? dataMap.clicked_percentage.value / 100
            : null,
      },
      {
        value: dataMap.clicked?.value,
        label: t('CLICKED'),
      },
    ];
  }, [overalSummary, t]);

  const smartSendStages = useMemo(() => {
    const summaryData = smartSummary?.[0];
    const dataMap =
      summaryData?.values?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.type]: curr,
        };
      }, {}) ?? {};

    const showSmartStats =
      dataMap.delivered?.value ||
      dataMap.opened_percentage?.value ||
      dataMap.clicked_percentage?.value;

    return showSmartStats
      ? [
          {
            value: dataMap.delivered.value,
            label: t('SmartSend Delivered'),
            percentage:
              typeof dataMap.opened_percentage.value === 'number'
                ? dataMap.opened_percentage.value / 100
                : null,
          },
          {
            percentage:
              typeof dataMap.clicked_percentage.value === 'number'
                ? dataMap.clicked_percentage.value / 100
                : null,
          },
          {},
        ]
      : [];
  }, [smartSummary, t]);

  const labelTranslationKeys = useMemo(() => {
    return {
      delivered: t('DELIVERED'),
      opened: t('OPENED'),
      clicked: t('CLICKED'),
      attachment_opened: t('OPENED ATTACHMENT'),
      unsubscribed: t('UNSUBSCRIBED'),
      spam_complaint: t('COMPLAINED'),
    };
  }, [t]);

  const lineData = useMemo(() => {
    return overalLine?.map((series) => {
      return {
        name: labelTranslationKeys[series.statistic],
        data: series.values,
      };
    });
  }, [overalLine, labelTranslationKeys]);

  const tooltipLabels = useMemo(() => {
    return (
      data?.metadata?.tooltip_label?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.date]: curr.label,
        };
      }, {}) ?? {}
    );
  }, [data]);

  return { lineData, tooltipLabels, stages, smartSendStages };
};

export default useChartData;
