import { useState, useEffect, useRef, useCallback } from 'react';
import {
  getMyProfileSection,
  getKeyAndTokenAccess,
} from 'store/authentication/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import TeamMemberService from 'services/TeamMemberService';
import { toastVariant, useToast } from 'components/ToastProvider';
import {
  refreshTeamMemberSuccess,
  refreshTeamMemberFailure,
} from 'store/authentication/authenticationAction';
import useModal from 'components/Modals/useModal';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import ConfirmNavigationModal from 'components/Modals/presets/ConfirmNavigation';
import {
  changeLanguageInStorage,
  DEFAULT_LANGUAGE,
  getCurrentLanguageFromStorage,
  PSEUDO_LANGUAGE,
} from 'i18n-config';

import FileService from 'services/FileService';
import UpdatePasswordForm from './Forms/UpdatePassword';
import MyProfileForm from './Forms/MyProfile';
import {
  Container,
  StyledCols as Cols,
  Sections,
  GoLiveStyledSection,
  CardContainer,
} from '../styles/common';
import EmailIntegration from './Forms/EmailIntegration';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import useFormValidation from 'hooks/useFormValidation';
import { getSortParam } from 'utility/getSortParam';
import { Redirect } from 'react-router-dom';
import Loader from 'components/Kizen/Loader';
import Card from 'components/Card/Card';
import { monitoringExceptionHelper } from 'sentry/helpers';

const setProfileTitle = (title, { full_name }) =>
  full_name ? `${full_name} - ${title} - KIZEN` : `${title} - KIZEN`;

const passwordInitialValues = {
  current_password: '',
  password: '',
};

const defaultAccountsSort = { column: 'email', direction: 'desc' };

const _ProfilePage = ({ title }) => {
  const dispatch = useDispatch();

  const {
    t,
    i18n: { language = DEFAULT_LANGUAGE },
  } = useTranslation();

  const [showToast] = useToast();
  const defaultToastMessage = t('Your settings have been saved successfully.');
  const defaultPasswordToastMessage = t(
    'Your password was updated successfully.'
  );

  // MyProfileForm data
  // Default values are used to compare those values with current form values to check if there are any changes
  const userDataDefaultValuesRef = useRef(null);
  const [userDataFormValues, setUserDataFormValues] = useState(null);
  useSetTitleOnLoad(title(t), userDataFormValues, setProfileTitle);
  const [picture, setPicture] = useState({
    preview: {
      name:
        userDataFormValues &&
        userDataFormValues.picture &&
        userDataFormValues.picture.name,
      url:
        userDataFormValues &&
        userDataFormValues.picture &&
        FileService.getThumbnailUrl(userDataFormValues.picture.id),
    },
    raw: [],
  });

  const [pictureFile, setAvatarFile] = useState(null);
  const [userFormError, setUserFormError] = useState('');

  const [imageUploadErrorModalProps, , imageUploadErrorModal] = useModal();

  // UpdatePasswordForm data
  const [passwordDataFormValues, setPasswordDataFormValues] = useState(
    passwordInitialValues
  );
  const [passwordFormError, setPasswordFormError] = useState('');

  // External accounts data
  const [externalAccounts, setExternalAccounts] = useState(null);

  // API keys data
  const chosenBusinessId = useSelector(
    ({ authentication }) =>
      !!authentication.chosenBusiness && authentication.chosenBusiness.id
  );

  // Handling discard modal if form has any changes
  const [touchedFormData, setTouchedFormData] = useState(false);
  const { validateFormState, validationProps, handleInputChange } =
    useFormValidation({
      setFormDirty: setTouchedFormData,
      formState: userDataFormValues,
      setFormState: setUserDataFormValues,
    });

  // Set flag for form changes if user form values or password form values are not equal to initial values
  useEffect(() => {
    if (
      !isEqual(userDataFormValues, userDataDefaultValuesRef.current) ||
      !isEqual(passwordDataFormValues, passwordInitialValues)
    ) {
      setTouchedFormData(true);
    } else {
      setTouchedFormData(false);
    }
  }, [userDataFormValues, passwordDataFormValues]);

  const getExternalAccounts = useCallback(async (sort) => {
    const data = await TeamMemberService.getExternalAccounts(sort);
    setExternalAccounts(data);
  }, []);

  const getTeamMemberData = useCallback(async () => {
    const data = await TeamMemberService.getTeamMemberData();
    const initData = {
      ...data,
      picture: data.picture && data.picture.id,
      language:
        data.language ||
        (language.startsWith('en') ? DEFAULT_LANGUAGE : language.split('-')[0]),
    };
    userDataDefaultValuesRef.current = initData;
    setUserDataFormValues(initData);
    setPicture({
      preview: {
        name: data.picture && data.picture.name,
        url: data.picture && FileService.getThumbnailUrl(data.picture.id),
      },
      raw: [],
    });
  }, [language]);

  // Fetching user data for pre-filling the form
  useEffect(() => {
    if (!userDataDefaultValuesRef.current) {
      getTeamMemberData();
      getExternalAccounts({ ordering: getSortParam(defaultAccountsSort) });
    }
  }, [getTeamMemberData, getExternalAccounts]);

  const handleImageUploadInputChange = (files) => {
    if (files.error) {
      imageUploadErrorModal.show();
      return null;
    }

    if (files.length) {
      URL.revokeObjectURL(picture.preview.url);
      const [file] = files;
      if (!file.created) {
        // Only reset the picture that displays during a fresh upload
        // to avoid flicker when switching from object URL to live thumbnail URL.
        setPicture({
          preview: {
            name: file.originalObject[0] && file.originalObject[0].name,
            url: URL.createObjectURL(file.originalObject[0]),
          },
          raw: file.originalObject,
        });
      }
      setAvatarFile(file);
      setUserDataFormValues({
        ...userDataFormValues,
        picture: file.id,
      });
      setTouchedFormData(true);
    }
    return null;
  };

  const handleSubmitUserForm = async () => {
    if (!validateFormState(t)) {
      return;
    }
    await TeamMemberService.updateTeamMemberData(userDataFormValues)
      .then(async (response) => {
        const { data } = response;
        const { picture: pic } = data;
        setUserDataFormValues({
          ...data,
          picture: pic ? pic.id : null,
        });

        dispatch(refreshTeamMemberSuccess({ teamMember: data }));
        setUserFormError('');
        showToast({
          message: defaultToastMessage,
          variant: toastVariant.SUCCESS,
        });

        if (userDataFormValues.language) {
          changeLanguageInStorage(
            getCurrentLanguageFromStorage() === PSEUDO_LANGUAGE
              ? PSEUDO_LANGUAGE
              : userDataFormValues.language
          );
        }

        // On user form submit user data is no longer changed, but we still have to check if password
        // form data is changed
        if (isEqual(passwordDataFormValues, passwordInitialValues))
          setTouchedFormData(false);
      })
      .catch((err) => {
        monitoringExceptionHelper(err);
        if (err.response && err.response.data && err.response.data.errors) {
          const { errors } = err.response.data;
          if (errors.picture) {
            setUserFormError(errors.picture[0]);
            dispatch(refreshTeamMemberFailure(errors.picture[0]));
          }
          if (errors.first_name) {
            setUserFormError(errors.first_name[0]);
          }
          if (errors.last_name) {
            setUserFormError(errors.last_name[0]);
          }
          if (errors.email) {
            setUserFormError(errors.email[0]);
          }
          if (errors.phone) {
            setUserFormError(errors.phone[0]);
          }
          if (errors.email_signature) {
            setUserFormError(errors.email_signature[0]);
          }
        }
        return null;
      });
  };

  const handleResetPassword = async () => {
    await TeamMemberService.resetPassword(passwordDataFormValues)
      .then(async () => {
        setPasswordDataFormValues(passwordInitialValues);
        setPasswordFormError('');
        showToast({
          message: defaultPasswordToastMessage,
          variant: toastVariant.SUCCESS,
        });
        // On password form submit password data is no longer changed, but we still have to check if user
        // form data is changed
        if (isEqual(userDataFormValues, userDataDefaultValuesRef.current))
          setTouchedFormData(false);
      })
      .catch((err) => {
        monitoringExceptionHelper(err);
        if (err.response && err.response.data && err.response.data.errors) {
          const { errors } = err.response.data;
          if (errors.current_password) {
            setPasswordFormError({
              current_password: t('Current password is incorrect.'),
            });
          }
          if (errors.password) {
            setPasswordFormError({
              password: errors.password[0],
            });
          }
        }
        return null;
      });
  };

  const handleAccountDelete = async (id) => {
    try {
      await TeamMemberService.deleteExternalAccount(id, {
        skipErrorBoundary: true,
      });

      showToast({
        variant: toastVariant.SUCCESS,
        message: t('The integrated email was successfully deleted.'),
      });

      await getExternalAccounts({
        ordering: getSortParam(defaultAccountsSort),
      });
    } catch (error) {
      showToast({
        variant: toastVariant.FAILURE,
        message: t('The integrated email could not be deleted.'),
      });
      monitoringExceptionHelper(error);
    }
  };

  return (
    <>
      {/* TODO temporarily styled for go live */}
      <GoLiveStyledSection>
        <Sections>
          <Container>
            <Cols columns={2} gutter={1}>
              <Card>
                <Loader loading={!userDataFormValues}>
                  <MyProfileForm
                    values={userDataFormValues}
                    imagePreviewSource={picture.preview}
                    imageFile={pictureFile}
                    onImageUploadInputChange={handleImageUploadInputChange}
                    onImageRemove={() => {
                      setPicture({
                        preview: {
                          name: '',
                          url: '',
                        },
                        raw: [],
                      });
                      setAvatarFile(null);
                      setUserDataFormValues({
                        ...userDataFormValues,
                        picture: '',
                      });
                      if (
                        !isEqual(
                          userDataFormValues,
                          userDataDefaultValuesRef.current
                        )
                      )
                        setTouchedFormData(true);
                    }}
                    onSubmit={handleSubmitUserForm}
                    formError={userFormError}
                    onFieldChange={handleInputChange}
                    validationProps={validationProps}
                  />
                </Loader>
              </Card>
              <CardContainer>
                <UpdatePasswordForm
                  values={passwordDataFormValues}
                  onFieldChange={(field, value) => {
                    setPasswordDataFormValues({
                      ...passwordDataFormValues,
                      [field]: value,
                    });
                    if (
                      !isEqual(passwordDataFormValues, passwordInitialValues)
                    ) {
                      setTouchedFormData(true);
                    }
                  }}
                  onSubmit={handleResetPassword}
                  formError={passwordFormError}
                />
                <EmailIntegration
                  businessId={chosenBusinessId}
                  externalAccounts={externalAccounts}
                  onAccountDelete={handleAccountDelete}
                />
              </CardContainer>
            </Cols>
          </Container>
        </Sections>
      </GoLiveStyledSection>
      <ConfirmationModal
        buttonText={t('Okay')}
        heading={t('Invalid Extension')}
        leftBtn={null}
        {...imageUploadErrorModalProps}
      >
        {t('Valid logo extensions include jpg, jpeg, png, gif, or svg.')}
      </ConfirmationModal>
      <ConfirmNavigationModal when={touchedFormData} />
    </>
  );
};

const ProfilePage = (props) => {
  const accessMyProfileSection = useSelector(getMyProfileSection);
  const keyAndTokenAccess = useSelector(getKeyAndTokenAccess);

  return accessMyProfileSection ? (
    _ProfilePage(props)
  ) : keyAndTokenAccess ? (
    <Redirect to={'/account/api-connections'} />
  ) : (
    <Redirect to={'/'} />
  );
};

export default ProfilePage;
