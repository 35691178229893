import { FIELDS } from 'queries/query-keys';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { FIELD_TYPES } from 'utility/constants';
import CustomObjectsService from 'services/CustomObjectsService';

const useDynamicTagRepair = ({ fieldData, values = [] }) => {
  const fieldType = fieldData?.fieldType;
  const needsToFetchDynamicTags = fieldType === FIELD_TYPES.DynamicTags.type;

  const dynamicTagValues = useMemo(() => {
    // We need to fetch dynamic tags if any of the values
    // are strings, because that means they're IDs and we need to fetch the name
    return needsToFetchDynamicTags
      ? values.filter((c) => {
          return typeof c === 'string';
        }) ?? []
      : [];
  }, [needsToFetchDynamicTags, values]);

  const { data = {}, isLoading } = useQuery(
    [FIELDS.DYNAMIC_TAGS_NAMES(dynamicTagValues)],
    () =>
      CustomObjectsService.getDisplayNames(
        { tagIds: dynamicTagValues },
        { skipErrorBoundary: true }
      ),
    { enabled: needsToFetchDynamicTags }
  );

  const dynamicTagBatchLoading = needsToFetchDynamicTags && isLoading;

  const dynamicTagsWithNames = useMemo(() => {
    if (dynamicTagValues.length) {
      // Map over the data results from the query to rebuild the dynamic tag metadata
      return (
        data?.results?.tags.map((r) => {
          return {
            id: r?.id,
            displayName: r?.displayName,
          };
        }) ?? []
      );
    }

    // Map over the existing options that already have metadata, if there were no queries needed
    return values
      .filter((v) => typeof v !== 'string')
      .map((v) => {
        if (typeof v !== 'string') {
          return {
            id: v.value,
            displayName: v.label,
          };
        }
        return null;
      });
  }, [values, dynamicTagValues, data]);

  return {
    loading: dynamicTagBatchLoading,
    valuesWithNames: dynamicTagsWithNames,
    needsToFetchDynamicTags,
  };
};

export default useDynamicTagRepair;
