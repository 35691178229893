import { useMemo } from 'react';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import {
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import {
  getLink,
  getRelationshipLabel,
} from 'components/Timeline2/EventBlocks/helpers';
import { getColumnLabel, getSortBy } from '../helpers';
import { StyledDeleteButton, StyledLinkWrapper } from '../styles';

export const useColumns = (field, onRemoveValues, isMobile, t) => {
  return useMemo(
    () => [
      {
        id: 'spacer-start',
        headCell: <SizedCell width="15px" />,
        cell: <SizedCell width="15px" />,
        format: () => <span />,
      },
      {
        id: getSortBy(field),
        headCell: <SortableHeadCell label={getColumnLabel(field, t)} />,
        cell: (
          <SizedCell
            padding={isMobile ? '2px' : '9px'}
            flex
            align="space-between"
          />
        ),
        format: (_, data) => (
          <>
            <StyledLinkWrapper>
              <TextEllipsisWithTooltip
                type="anchor"
                href={getLink(data, {
                  type: field.fieldType,
                  metaInfo: field,
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getRelationshipLabel(field, data)}
              </TextEllipsisWithTooltip>
            </StyledLinkWrapper>
            {onRemoveValues ? (
              <StyledDeleteButton
                sizing="dense"
                color={colorsButton.iconGray}
                onClick={() => onRemoveValues(data.id)}
                title={t('Delete')}
              >
                <Icon icon="delete" size="xl" />
              </StyledDeleteButton>
            ) : null}
          </>
        ),
      },
    ],
    [field, onRemoveValues, isMobile, t]
  );
};
