import { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { format } from 'date-fns2';

import { getDataQAForInput } from 'components/Inputs/helpers';
import useField from 'hooks/useField';
import { gutters } from 'app/spacing';
import { colorsText } from 'app/colors';
import { TextSpan } from 'app/typography';
import EditableText from 'components/Kizen/EditableText';
import { TextEllipsis } from 'components/Kizen/Table';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import SelectOverlay from '../SelectOverlay';
import {
  DATE_DISPLAY_FORMAT_NEW,
  getDateOption,
  timeOptions,
} from '../DateTimeInput';
import Select from '../Select';
import { TextInputInlineWrapper } from './TextInput/styles';
import DateTextInlineInput from 'components/Inputs/inline/TextInput/presets/DatePicker';
import { Menu as DateMenu } from '../DateInput';
import { TrackingMenuList } from '../Select/customize';
import {
  modalNudgePopperUp,
  nudgePopperUp,
  DateInputInlineSelect,
} from './DateInput';
import { FIELD_TYPES } from 'utility/constants';
import { useDateTooltip } from '../hooks/useDateTooltip';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';

const Layout = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const ShowWithoutHover = styled(StylePassthrough)`
  ${({ show }) =>
    !show &&
    css`
      visibility: hidden;
      tr:hover &,
      .InlineHoverable:hover & {
        visibility: visible;
      }
    `}
`;

const getUpdatedDate = ({ date, time }) => {
  if (!date && !time) {
    return null;
  }
  return new Date(
    `${date?.label || format(new Date(), DATE_DISPLAY_FORMAT_NEW)} ${
      time ? time.value : ''
    }`
  );
};

const AtText = styled(TextSpan)`
  margin: 0 ${gutters.spacing(1)}px;
`;

export default function DateTimeInputInline({
  value = null,
  onChange = null,
  onSubmit = null,
  autoFocus = null,
  onAutoFocus = null,
  submitUnchanged = null,
  readOnly = false,
  inModal,
  dateMenuProps,
  timeMenuProps,
  'data-field-type': qaFieldType,
  'data-field-id': qaFieldId,
  placeholder = FIELD_TYPES.DateTime.placeholder,
  tooltipLabel,
  showDateTooltip = false,
  field,
  shouldFocusNext,
  object,
  ...others
}) {
  const fieldId = useMemo(() => `${field?.id}-${object?.id}`, [field, object]);
  // Setup state
  const dateRef = useRef();
  const timeRef = useRef();
  const timeSelectRef = useRef();
  const [editing, setEditing] = useState(true);
  const [dayPlaceholder, timePlaceholder] = placeholder.split(' ');
  const prevDate = useRef(null);
  const [showDate, setShowDate] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [stagedValue, setStagedValue] = useField(value);
  const displayValue = onSubmit ? stagedValue : value;
  const [stagedDateValue, setStagedDateValue] = useField(null);
  const [date, setDate] = useState(() => getDateOption(displayValue, true));

  useEffect(() => {
    setDate(getDateOption(displayValue, true));
  }, [displayValue]);

  const time = useMemo(
    () => getDateOption(displayValue, false),
    [displayValue]
  );
  // Support autoFocus
  const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();
  const onAutoFocusRef = useRef();
  onAutoFocusRef.current = onAutoFocus;

  useEffect(() => {
    if (autoFocus && !getKeyListenersProps) {
      setShowDate(true);
      if (dateRef.current) onAutoFocusRef.current(dateRef.current);
    }
  }, [autoFocus, getKeyListenersProps]);

  // Handlers

  const handleChange = async (val, { isDate }) => {
    const updatedDate =
      isDate && val.date === null
        ? null
        : getUpdatedDate({
            date: isDate ? val : date,
            time: isDate ? time : val,
          });
    if (onSubmit) {
      setStagedValue(updatedDate);
    } else {
      setShowDate(false);
      setShowTime(false);
    }
    if (onChange) {
      await onChange(updatedDate);
    }
  };

  const handleSubmit = async (v, useIncomeValue = false) => {
    if (submitUnchanged || Number(stagedValue) !== Number(value) || v) {
      try {
        const newValue = v
          ? getUpdatedDate({
              date: getDateOption(v, true),
              time: time,
            })
          : date.date;
        setSubmitting(true);
        prevDate.current = getDateOption(useIncomeValue ? v : newValue, true);
        setShowDate(false);
        const res = await onSubmit(useIncomeValue ? v : newValue);
        setDate(getDateOption(useIncomeValue ? v : newValue, true));
        return res;
      } catch (err) {
        setStagedValue(value);
        return false;
      } finally {
        setSubmitting(false);
        prevDate.current = null;
      }
    }
    return false;
  };

  const handleHide = async () => {
    setShowDate(false);
    setShowTime(false);
    if (onSubmit) {
      await handleSubmit();
    }
  };

  const [dateTooltipProps, dateTooltip] = useDateTooltip({
    date: showDateTooltip && date?.date,
    predicate: tooltipLabel,
  });
  const focusedRef = useRef(null);
  const selectRef = useRef(null);

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    {
      shouldUseKeyboardEventHandle: true,
      onBlur: () => {
        timeRef.current?.querySelector('input')?.focus();
        setShowTime(true);
        setShowDate(false);
        focusedRef.current = 'time';
      },
      ...others,
    }
  );

  const onParentControl = (e) => {
    if (e.code === 'Escape') {
      setEditing(false);
      setShowTime(false);
      setShowDate(false);
      return true;
    }
    setShowDate(false);
    if (e?.shiftKey) {
      if (focusedRef.current === 'time') {
        setShowTime(false);
        if (e?.code !== 'Enter') {
          dateRef.current?.querySelector('input')?.focus();
          focusedRef.current = 'date';

          if (stagedDateValue) {
            setStagedValue(
              getUpdatedDate({
                date: { label: stagedDateValue },
                time: time,
              })
            );
          }
        }

        return true;
      } else {
        focusedRef.current = null;
        setShowTime(false);
        setEditing(false);
        return false;
      }
    } else {
      if (focusedRef.current === 'date') {
        timeRef.current?.querySelector('input')?.focus();
        setShowTime(true);
        focusedRef.current = 'time';
        if (stagedDateValue) {
          setStagedValue(
            getUpdatedDate({
              date: { label: stagedDateValue },
              time: time,
            })
          );
        }
        return true;
      } else {
        focusedRef.current = null;
        setShowTime(false);
        setEditing(false);
        return false;
      }
    }
  };

  const onFocusedDate = (e) => {
    if (focusedRef.current !== 'time') {
      setEditing(true);
      setShowTime(false);
      focusedRef.current = 'date';
    }
  };

  assignFieldHandle(fieldId, {
    customUp: () => {
      if (focusedRef.current === 'date') {
        setShowDate(true);
      }
    },
    customDown: () => {
      if (focusedRef.current === 'date') {
        setShowDate(true);
      }
    },
    disabled: readOnly,
    shouldFocusNext: shouldFocusNext,
  });

  // Render and display
  if (readOnly) {
    return (
      <>
        {dateTooltip}
        <TextEllipsis {...others} {...dateTooltipProps}>
          {date && date.label}
          {date && time && ' at '}
          {time && time.label}
          {!date && !time && '—'}
        </TextEllipsis>
      </>
    );
  }

  const onClickDateInput = () => {
    setShowDate(true);
    focusedRef.current = 'date';
  };

  const onClickTimeInput = () => {
    setShowTime(true);
    focusedRef.current = 'time';
    getKeyListenersProps?.(fieldId)?.onFocus(fieldId);
  };

  const onHideDateOverlay = async (e) => {
    if (e && !dateRef.current.contains(e.target)) {
      await handleHide();
    }
  };

  return (
    <>
      <Layout
        {...getDataQAForInput(qaFieldId, qaFieldType)}
        {...others}
        {...dateTooltipProps}
      >
        <TextInputInlineWrapper ref={dateRef} button={false}>
          <DateTextInlineInput
            field={field}
            object={object}
            editing={editing}
            ref={selectRef}
            className="ViewText DateText"
            value={
              submitting ? prevDate.current?.label : date ? date.label : ''
            }
            disabled={submitting}
            onSubmit={handleSubmit}
            callback={setStagedDateValue}
            isDateInput
            showError
            onClick={onClickDateInput}
            {...getDataQAForInput('date-input', qaFieldType)}
            {...others}
            placeholder={dayPlaceholder}
            {...getKeyListenersProps?.(fieldId)}
            onFocused={onFocusedDate}
            onParentControl={onParentControl}
            shouldFocusNext={shouldFocusNext}
          />
        </TextInputInlineWrapper>
        <ShowWithoutHover show={date && time}>
          <AtText color={submitting ? colorsText.medium : colorsText.dark}>
            at
          </AtText>
        </ShowWithoutHover>
        <ShowWithoutHover show={date && time} ref={timeRef}>
          <TextInputInlineWrapper button={false}>
            <EditableText
              className="ViewText"
              value={time?.label || '—'}
              disabled={submitting}
              readOnly
              onClick={onClickTimeInput}
              {...getDataQAForInput('time-input', qaFieldType)}
            />
          </TextInputInlineWrapper>
        </ShowWithoutHover>
      </Layout>
      {dateTooltip}
      <SelectOverlay
        show={showDate}
        target={dateRef.current}
        onHide={onHideDateOverlay}
        popperConfig={inModal ? modalNudgePopperUp : nudgePopperUp}
        iDoNotWantToMountTwice
      >
        <DateInputInlineSelect
          value={date}
          onChange={(val) => handleChange(val, { isDate: true })}
          sizing={false}
          components={{ Menu: DateMenu }}
          handeClose={() => setShowDate(false)}
          {...dateMenuProps}
          {...customSelectMenuProps}
        />
      </SelectOverlay>
      <SelectOverlay
        show={showTime}
        target={timeRef.current}
        onHide={async () => {
          await handleHide();
        }}
        popperConfig={inModal ? modalNudgePopperUp : nudgePopperUp}
        {...getDataQAForInput(qaFieldId, `${qaFieldType}-time-select`)}
      >
        <Select
          ref={timeSelectRef}
          value={time?.value ? time : null}
          options={timeOptions}
          onChange={(val) => handleChange(val, { isDate: false })}
          components={{
            MenuList: TrackingMenuList,
          }}
          handeClose={() => setShowTime(false)}
          {...timeMenuProps}
          placeholder={timePlaceholder}
          {...getKeyListenersProps?.(fieldId)}
          tabSelectsValue={true}
        />
      </SelectOverlay>
    </>
  );
}
