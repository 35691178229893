import styled from '@emotion/styled';
import { gutters, borderRadii } from 'app/spacing';
import { grayScale } from 'app/colors';
import SmallTableCard from 'components/Layout/SmallTableCard';
import {
  DynamicTagAggregator,
  DynamicTagsManager,
} from 'components/Tables/DynamicTagsManager';
import { useCallback } from 'react';

const StyledSmallTableCard = styled(SmallTableCard)`
  margin-bottom: ${gutters.standard};
  padding-top: 8px;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.standard};
  box-shadow: unset;
  height: unset;
`;

export default function DynamicTagOptions({
  updateField,
  formData,
  data,
  importedOptions,
}) {
  const onFieldUpdate = useCallback(
    (tags) =>
      updateField(
        'options',
        tags.map(({ name }) => ({ name }))
      ),
    [updateField]
  );
  return (
    <StyledSmallTableCard singleColumn>
      {data.field?.id ? (
        <DynamicTagsManager {...data} importedOptions={importedOptions} />
      ) : (
        <DynamicTagAggregator
          {...data}
          field={formData}
          onChange={onFieldUpdate}
          importedOptions={importedOptions}
        />
      )}
    </StyledSmallTableCard>
  );
}
