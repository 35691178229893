import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isEqual, cloneDeep } from 'lodash';
import { KizenTypography, Text } from 'app/typography';
import { SettingsContentCard } from 'components/Card/Card';
import { PageSizing } from 'components/Layout/PageContentWidth';
import ImageFileInputWithUpload from 'components/Kizen/FileInputWithImage/WithUpload';
import Button from 'components/Button';
import TextInput from 'components/Inputs/TextInput';
import EmailAddressTextInput from 'components/Inputs/TextInput/presets/EmailAddress';
import DomainTextInput from 'components/Inputs/TextInput/presets/Domain';
import PhoneNumberTextInput from 'components/Inputs/TextInput/presets/PhoneNumber';
import Divider from 'components/Layout/Divider';
import useFormSubmit from 'hooks/useFormSubmit';
import { SubSectionWithHeader as SubSection } from 'components/Layout/SubSection';
import useModal from 'components/Modals/useModal';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import ConfirmNavigationModal from 'components/Modals/presets/ConfirmNavigation';
import Section from 'components/Layout/Section';
import Cols from 'components/Layout/Cols';
import FileService from 'services/FileService';
import { getChosenBusiness } from 'store/authentication/selectors';
import { serviceUpdateBusinessStart } from 'store/authentication/authenticationAction';
import {
  useBusinessConfigQuery,
  useBusinessCountriesQuery,
  useBusinessTextNumberQuery,
} from 'queries/models/business';
import useFormValidation from 'hooks/useFormValidation';
import {
  Sections,
  StyledCol,
  StyledColorPickerInput,
  StyledLabelWrapper,
  StyledSubsectionTitle,
} from '../styled';
import { toastVariant, useToast } from 'components/ToastProvider';
import Loader from 'components/Kizen/Loader';
import { countriesToCountryOptions, countriesToStateOptions } from '../helpers';
import { FORM_VALUES } from '../constants';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import Dropdown from 'components/Fields/FieldInput/Dropdown';
import { FIELD_TYPES } from 'utility/constants';
import { optionToNamed } from 'services/helpers';

const toOption = (item) => ({
  name: item.value,
  display_name: item.label,
});

const getTouchedFields = (initObj, obj) => {
  const difference = {};
  Object.keys(initObj).forEach((key) => {
    if (
      (typeof initObj[key] !== 'object' || initObj[key] === null) &&
      initObj[key] !== obj[key]
    ) {
      difference[key] = obj[key];
    } else if (JSON.stringify(initObj[key]) !== JSON.stringify(obj[key])) {
      difference[key] = Array.isArray(obj[key]) ? obj[key] : obj[key]?.name;
    }
  });

  return difference;
};

export const BusinessSettings = ({ access }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [formData, setFormData] = useState(FORM_VALUES);
  const [processing, setProcessing] = useState(false);
  const [initialFormData, setInitialFormData] = useState(formData);
  const [touchedFormData, setTouchedFormData] = useState(false);
  const [showToast] = useToast();
  const {
    validateFormState,
    validationProps,
    handleInputChange,
    setValidationState,
    firstFieldWithErrorRef,
  } = useFormValidation({
    setFormDirty: setTouchedFormData,
    formState: formData,
    setFormState: setFormData,
  });

  const [canEditInformation, canCustomize] = useMemo(() => {
    const { businessCustomization, businessInformation } = access;
    return [businessInformation.edit, businessCustomization.edit];
  }, [access]);

  const canViewPhoneNumber = useMemo(() => {
    const { businessInformation } = access;
    return businessInformation.view || businessInformation.edit;
  }, [access]);

  const [logo, setLogo] = useState({
    preview: {
      name: '',
      url: '',
    },
    raw: [],
    file: {},
  });

  const chosenBusiness = useSelector(getChosenBusiness);

  useEffect(() => {
    const businessForForm = cloneDeep(chosenBusiness);
    businessForForm.logo = chosenBusiness.logo?.id; // logo is null if none uploaded
    setFormData(businessForForm);
    setInitialFormData(cloneDeep(businessForForm));
    setLogo({
      preview: {
        name: chosenBusiness.logo?.name,
        url:
          chosenBusiness.logo?.id &&
          FileService.getThumbnailUrl(chosenBusiness.logo?.id),
      },
      raw: [],
      file: {},
    });
    setTouchedFormData(false);
  }, [chosenBusiness]);

  const { data: publicData, isLoading: loadingPublicData } =
    useBusinessConfigQuery(true);

  const { data: countries, isLoading: loadingCountries } =
    useBusinessCountriesQuery(true);

  const { data: textNumber, isLoading: loadingBusinessTextNumber } =
    useBusinessTextNumberQuery(canViewPhoneNumber);

  const statesList = useMemo(
    () => countriesToStateOptions(countries, formData.country),
    [countries, formData.country]
  );
  const countriesList = useMemo(
    () => countriesToCountryOptions(countries),
    [countries]
  );
  const successToastMessage = t('Your settings have been saved successfully');

  const updateField = (field) => {
    const nextValue = {
      ...formData,
      ...field,
    };
    setFormData(nextValue);

    if (!isEqual(nextValue, initialFormData)) {
      setTouchedFormData(true);
    }
  };

  const [imageUploadErrorModalProps, , imageUploadErrorModal] = useModal();

  const handleImageUploadInputChange = (files) => {
    if (files.error) {
      imageUploadErrorModal.show();
      return null;
    }

    if (files.length) {
      URL.revokeObjectURL(logo.preview.url);
      const [file] = files;
      if (!file.created) {
        // Only reset the picture that displays during a fresh upload
        // to avoid flicker when switching from object URL to live thumbnail URL.
        setLogo({
          preview: {
            name: file.originalObject[0] && file.originalObject[0].name,
            url: URL.createObjectURL(file.originalObject[0]),
          },
          raw: file.originalObject,
          file,
        });
      }
      updateField({ logo: file.id });
    }
    return null;
  };

  const [submitState, fetchSubmit] = useFormSubmit(async () => {
    if (!validateFormState(t)) {
      return;
    }

    const touchedFields = getTouchedFields(initialFormData, formData);
    const [error] = await dispatch(serviceUpdateBusinessStart(touchedFields));
    const currentError = error ? error.payload.errors : {};
    const errorMessage = Object.values(currentError).join(', ');
    if (currentError.name) {
      firstFieldWithErrorRef.current = 'name';
      setValidationState(currentError);
    } else {
      showToast({
        variant: error ? toastVariant.FAILURE : toastVariant.SUCCESS,
        message: error ? errorMessage : successToastMessage,
      });
    }
  }, [dispatch, formData]);

  const actionButton = useMemo(() => {
    return (
      <Button
        disabled={
          submitState.loading ||
          processing ||
          loadingPublicData ||
          loadingCountries
        }
        onClick={fetchSubmit}
        style={{ margin: '0', marginTop: -5 }}
      >
        {t('SAVE')}
      </Button>
    );
  }, [
    submitState.loading,
    processing,
    loadingPublicData,
    loadingCountries,
    fetchSubmit,
    t,
  ]);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'business-name' },
      { id: 'business-type' },
      { id: 'timezone' },
      { id: 'business-notification-email' },
      { id: 'business-phone-number' },
      { id: 'forward-calls' },
      { id: 'street-address' },
      { id: 'country' },
      { id: 'state-province' },
      { id: 'city' },
      { id: 'zip-postal-code' },
      { id: 'subdomain' },
      { id: 'country-code' },
    ],
    {},
    () => true
  );
  return (
    <PageSizing>
      <SettingsContentCard>
        <Section>
          <Loader
            loading={
              loadingPublicData || loadingCountries || loadingBusinessTextNumber
            }
          >
            <KeyBoardContext.Provider value={{ assignFieldHandle }}>
              <Sections>
                {canEditInformation ? (
                  <>
                    <SubSection
                      title={t('Business Information')}
                      action={canCustomize ? null : actionButton}
                    >
                      <KizenTypography weight="bold" margin="sm">
                        {t('Business Overview')}
                      </KizenTypography>
                      <Row>
                        <Col>
                          <TextInput
                            label={t('Business Name')}
                            placeholder={t('Enter Business Name')}
                            value={formData.name}
                            onChange={(value, ev, err) =>
                              handleInputChange('name', value, err)
                            }
                            {...validationProps('name')}
                            required
                            {...getKeyListenersProps('business-name')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Dropdown
                            className="spaced-input"
                            label={t('Business Type')}
                            placeholder={t('Choose Business Type')}
                            value={formData?.type?.name}
                            onChange={(value, ev, err) =>
                              handleInputChange('type', toOption(value), err)
                            }
                            {...validationProps('type')}
                            loadItems={loadingPublicData}
                            isGetFullObject
                            field={{
                              fieldType: FIELD_TYPES.Dropdown,
                              options:
                                publicData?.businessTypes.map(optionToNamed),
                            }}
                            menuLeftButton={null}
                            menuRightButton={null}
                            {...getKeyListenersProps('business-type')}
                          />
                        </Col>
                        <Col>
                          <Dropdown
                            className="spaced-input"
                            fullWidth
                            isGetFullObject
                            label={t('Timezone')}
                            placeholder={t('Choose Option')}
                            value={formData.timezone?.name}
                            loadItems={loadingPublicData}
                            onChange={(value, ev, err) =>
                              handleInputChange(
                                'timezone',
                                toOption(value),
                                err
                              )
                            }
                            {...validationProps('timezone')}
                            required
                            field={{
                              fieldType: FIELD_TYPES.Dropdown,
                              options: publicData?.timezones.map(optionToNamed),
                            }}
                            menuLeftButton={null}
                            menuRightButton={null}
                            {...getKeyListenersProps('timezone')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <EmailAddressTextInput
                            className="spaced-input"
                            label={t('Business Notification Email')}
                            placeholder={t('Enter Business Notification Email')}
                            value={formData.reply_to_email}
                            onChange={(value, ev, err) =>
                              handleInputChange('reply_to_email', value, err)
                            }
                            {...validationProps('reply_to_email')}
                            required
                            {...getKeyListenersProps(
                              'business-notification-email'
                            )}
                          />
                        </Col>
                        <Col>
                          <PhoneNumberTextInput
                            className="spaced-input"
                            label={t('Business Phone Number')}
                            placeholderNumber={t('Enter Business Phone Number')}
                            value={formData.phone}
                            defaultCountry={chosenBusiness.phone_default_region}
                            onChange={(value, ev, err) => {
                              handleInputChange('phone', value, err, {
                                forwarding_phone_number: value,
                              });
                            }}
                            {...validationProps('phone')}
                            required
                            {...getKeyListenersProps('business-phone-number')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {canViewPhoneNumber ? (
                          <Col>
                            <PhoneNumberTextInput
                              className="spaced-input"
                              label={t('Notification Text Number')}
                              value={textNumber?.text_phone_number}
                              defaultCountry={
                                chosenBusiness.phone_default_region
                              }
                              readOnly
                              labelInfo={`${t(
                                'Your team will receive text message notifications from this number.'
                              )}`}
                              labelInfoPlacement="top"
                              labelInfoMaxWidth={300}
                            />
                          </Col>
                        ) : null}
                      </Row>
                      <StyledSubsectionTitle weight="bold">
                        {t('Address')}
                      </StyledSubsectionTitle>
                      <Row>
                        <Col>
                          <TextInput
                            className="spaced-input"
                            label={t('Street Address')}
                            placeholder={t('Enter Street Address')}
                            value={formData.street_address}
                            onChange={(value, ev, err) =>
                              handleInputChange('street_address', value, err)
                            }
                            {...validationProps('street_address')}
                            required
                            {...getKeyListenersProps('street-address')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Dropdown
                            className="spaced-input"
                            label={t('Country')}
                            placeholder={t('Enter Country')}
                            options={countriesList}
                            value={formData.country}
                            menuPlacement="top"
                            onChange={(value, ev, err) => {
                              handleInputChange('country', value, err, {
                                state: null,
                              });
                            }}
                            {...validationProps('country')}
                            required
                            field={{
                              fieldType: FIELD_TYPES.Dropdown,
                              options: countriesList,
                            }}
                            menuLeftButton={null}
                            menuRightButton={null}
                            {...getKeyListenersProps('country')}
                          />
                        </Col>
                        <Col>
                          <Dropdown
                            className="spaced-input"
                            label={t('State/Province')}
                            placeholder={t('Enter State/Province')}
                            options={statesList}
                            value={formData.state}
                            menuPlacement="top"
                            disabled={!statesList.length}
                            onChange={(value, ev, err) => {
                              handleInputChange('state', value, err);
                            }}
                            {...validationProps('state')}
                            {...(statesList.length ? { required: true } : {})}
                            field={{
                              fieldType: FIELD_TYPES.Dropdown,
                              options: statesList,
                            }}
                            menuLeftButton={null}
                            menuRightButton={null}
                            {...getKeyListenersProps('state-province')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextInput
                            className="spaced-input"
                            label={t('City')}
                            placeholder={t('Enter City')}
                            value={formData.city}
                            onChange={(value, ev, err) => {
                              handleInputChange('city', value, err);
                            }}
                            {...validationProps('city')}
                            required
                            {...getKeyListenersProps('city')}
                          />
                        </Col>
                        <Col>
                          <TextInput
                            className="spaced-input"
                            label={t('Zip/Postal Code')}
                            placeholder={t('Enter/Postal Zip Code')}
                            value={formData.postal_code}
                            onChange={(value, ev, err) => {
                              handleInputChange('postal_code', value, err);
                            }}
                            required
                            {...validationProps('postal_code')}
                            {...getKeyListenersProps('zip-postal-code')}
                          />
                        </Col>
                      </Row>
                    </SubSection>
                    <Divider />
                  </>
                ) : null}
                {canCustomize ? (
                  <SubSection
                    title={t('Business Customization')}
                    action={actionButton}
                  >
                    <Row>
                      <Col>
                        <Cols columns={3} addSpacer={false}>
                          <StyledLabelWrapper>
                            <Text>{t('Logo')}</Text>
                          </StyledLabelWrapper>
                          <StyledLabelWrapper>
                            <Text>{t('Primary Color')}</Text>
                          </StyledLabelWrapper>
                          <StyledLabelWrapper>
                            <Text>{t('Secondary Color')}</Text>
                          </StyledLabelWrapper>
                        </Cols>
                        <Cols columns={3} addSpacer={false}>
                          <StyledCol>
                            <ImageFileInputWithUpload
                              actionLabel={t('UPLOAD')}
                              id="upload-button"
                              onChange={handleImageUploadInputChange}
                              imagePreviewSource={logo.preview}
                              processing={setProcessing}
                              file={logo.file}
                            />
                          </StyledCol>
                          <StyledCol>
                            <StyledColorPickerInput
                              className="color-picker-input"
                              color={formData.primary_color}
                              onChange={(value) => {
                                handleInputChange('primary_color', value);
                              }}
                            />
                          </StyledCol>
                          <StyledCol>
                            <StyledColorPickerInput
                              className="color-picker-input"
                              color={formData.secondary_color}
                              onChange={(rgba) =>
                                handleInputChange('secondary_color', rgba)
                              }
                            />
                          </StyledCol>
                        </Cols>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <DomainTextInput
                          className="spaced-input"
                          label={t('Subdomain')}
                          placeholder={t('Enter Subdomain')}
                          value={formData.subdomain}
                          isSubdomain
                          required
                          onChange={(value, ev, err) =>
                            handleInputChange('subdomain', value, err)
                          }
                          {...validationProps('subdomain')}
                          {...getKeyListenersProps('subdomain')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Dropdown
                          className="spaced-input"
                          label={t('Default Country Code for Phone Numbers')}
                          placeholder={t('Enter Default Country Code')}
                          value={formData?.phone_default_region}
                          onChange={(value, ev, err) => {
                            handleInputChange(
                              'phone_default_region',
                              value,
                              err
                            );
                          }}
                          loadItems={loadingPublicData}
                          menuPlacement="bottom"
                          field={{
                            fieldType: FIELD_TYPES.Dropdown,
                            options:
                              publicData?.phoneRegions?.map(optionToNamed),
                          }}
                          menuLeftButton={null}
                          menuRightButton={null}
                          {...getKeyListenersProps('country-code')}
                        />
                      </Col>
                    </Row>
                  </SubSection>
                ) : (
                  <SubSection />
                )}
                {canEditInformation ? null : (
                  <>
                    <Divider />
                    <SubSection />
                  </>
                )}
              </Sections>
            </KeyBoardContext.Provider>
          </Loader>
        </Section>
        <ConfirmNavigationModal when={touchedFormData} />
        <ConfirmationModal
          buttonText="Okay"
          heading={t('Invalid Extension')}
          leftBtn={null}
          {...imageUploadErrorModalProps}
        >
          {t('Valid logo extensions include jpg, jpeg, png, gif, or svg.')}
        </ConfirmationModal>
      </SettingsContentCard>
    </PageSizing>
  );
};
