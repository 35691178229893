import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Trend from '..';

const TrendChartGroupAdapter = ({ dashlet, data, model, ...rest }) => {
  const { t } = useTranslation();

  const chartData = useMemo(() => {
    if (data?.data) {
      return data.data.find((d) => d.type === 'values')?.values ?? [];
    }
    return [];
  }, [data]);

  const frequency = data?.frequency;

  const tooltipLabels = useMemo(() => {
    return (
      data?.metadata?.tooltip_label?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.date]: curr.label,
        };
      }, {}) ?? {}
    );
  }, [data]);

  return (
    <Trend
      dashlet={dashlet}
      {...rest}
      data={chartData}
      tooltipLabels={tooltipLabels}
      yAxisTitle={t('$ Value')}
      currencySymbol="$"
      frequency={frequency}
      id={dashlet.id}
      isValue
    />
  );
};

export default TrendChartGroupAdapter;
