import {
  CloseMode,
  ISubscription,
  Stream,
  StreamMerge,
  SyncTuple,
  merge,
  sync,
} from '@thi.ng/rstream';
import {
  comp,
  isReduced,
  map,
  Reduced,
  reduced,
  Transducer,
} from '@thi.ng/transducers';
import type { PickRequired, TransducerConfig, RuleResult } from './rules/types';
import {
  Access,
  AccessNumber,
  PermissionMetadata,
  PermissionResultData,
  PermissionRule,
  Section,
  StreamValue,
} from './types';
import { accessIter, getAccessNumber } from './utils';
import {
  getEqualTransducer,
  getExistsTransducer,
  getGreaterThanOrEqualTransducer,
  getLessThanTransducer,
  getMinValueTransducer,
  getNotExistsTransducer,
} from './xform';

type RuleValue = { value: Access; allowed_access: Access[]; text?: string };

type RuleResultLike = PickRequired<RuleResult<any>, 'result'>;

type RuleSyncValue = {
  source: Stream<StreamValue>;
  rule?: ISubscription<
    RuleResult<Partial<RuleValue>>,
    RuleResult<Partial<RuleValue>>
  >;
};

export const permissionsTransducers: TransducerConfig<StreamValue> = {
  '>=': getGreaterThanOrEqualTransducer,
  '<': getLessThanTransducer,
  '=': getEqualTransducer,
  'min-value': getMinValueTransducer,
  exists: getExistsTransducer,
  '!exists': getNotExistsTransducer,
};

export class Permission {
  _section_id: Section;
  _permission_id: string;
  _initial: StreamValue;
  source: Stream<StreamValue>;
  _visible: StreamMerge<RuleResultLike, boolean>;
  rule_src_synced: ISubscription<
    SyncTuple<RuleSyncValue>,
    SyncTuple<RuleSyncValue>
  >;
  result:
    | ISubscription<SyncTuple<RuleSyncValue>, PermissionResultData>
    | ISubscription<StreamValue, PermissionResultData>;

  constructor(
    private _key: string,
    private sources: Map<string, Stream<StreamValue>>,
    private metadata?: PermissionMetadata
  ) {
    if (!this.sources.has(this.key)) {
      throw Error(
        `Cannot create Permission with ${this.key} key. ${this.key} is not present is sources Map`
      );
    }

    const [sectionId, permissionId] = this._key.split('__');
    this._section_id = sectionId as Section;
    this._permission_id = permissionId ?? null;
    this.source = this.sources.get(this.key)!;
    this._initial = this.source.deref()!;

    const attachMetadataXform = map<StreamValue, PermissionResultData>(
      (values) => {
        return {
          ...values,
          ...this.metadata,
          section_id: this._section_id,
          permission_id: this._permission_id,
          initial: this._initial,
        };
      }
    );

    this._visible = merge<RuleResultLike, boolean>({
      xform: map((x) => x.result),
      closeOut: CloseMode.NEVER,
    });
    this.visible.next({ result: true });

    this.rule_src_synced = sync({
      src: { source: this.source.transform(attachMetadataXform) },
    });

    this.result = this.rule_src_synced.transform(
      comp(
        takeRuleValues,
        takeRuleText,
        checkAllowedAccessWithinInitial,
        returnIfNormalBooleanValue(this.metadata?.affordance),
        checkBooleanValueWithinConstraints,
        checkPermissionValueWithinConstraints,
        pluckResult,
        attachMetadataXform
      )
    );
  }

  public applyRule(rule: PermissionRule) {
    this.rule_src_synced.add(rule.result, 'rule');
  }

  public applyVisibilityRule(rule: PermissionRule) {
    this.visible.add(rule.result);
  }

  get value() {
    return this.result;
  }

  get visible() {
    return this._visible;
  }

  get key() {
    return this._key;
  }

  get sectionId() {
    return this._section_id;
  }

  get permissionId() {
    return this._permission_id;
  }
}

type SyncedResult =
  | {
      source: PermissionResultData;
      rule?: RuleResult<Partial<RuleValue>>;
      result?: PermissionResultData;
    }
  | Reduced<StreamValue>;
type SyncedResultTransducer = Transducer<SyncedResult, SyncedResult>;

const convertRuleValue = (rule_value: boolean | Access) => {
  return typeof rule_value === 'boolean'
    ? rule_value
    : getAccessNumber(rule_value);
};

const takeRuleValues: SyncedResultTransducer = map((v) => {
  if (isReduced(v)) return v;
  if (!v.rule?.result) return reduced(v.source);
  const { source, rule } = v;
  const { value: r_value, allowed_access: r_allowed_access } = rule.value;
  const result = {
    value: r_value === undefined ? source.value : convertRuleValue(r_value),
    allowed_access:
      r_allowed_access === undefined ? source.allowed_access : r_allowed_access,
  };
  return { source, rule, result };
});

const takeRuleText: SyncedResultTransducer = map((v) => {
  if (isReduced(v)) return v;
  if (!v.rule?.result) return reduced(v.source);
  return v.rule.text
    ? { ...v, result: { ...v.result, tooltip: v.rule.text } }
    : v;
});

const checkAllowedAccessWithinInitial: SyncedResultTransducer = map((v) => {
  if (isReduced(v)) return v;
  if (!v.result?.allowed_access || !v.source.initial) return v;
  const {
    source: { initial },
    result: { allowed_access },
  } = v;
  const minAccess = getAccessNumber(allowed_access[0]);
  const maxAccess = getAccessNumber(allowed_access[allowed_access.length - 1]);
  const min = Math.max(
    getAccessNumber(initial.allowed_access[0]),
    minAccess
  ) as AccessNumber;
  const max = Math.min(
    getAccessNumber(initial.allowed_access[initial.allowed_access.length - 1]),
    maxAccess
  ) as AccessNumber;

  return minAccess < min || maxAccess > max
    ? {
        ...v,
        result: { ...v.result, allowed_access: [...accessIter(min, max + 1)] },
      }
    : v;
});

const returnIfNormalBooleanValue: (
  affordance: PermissionMetadata['affordance']
) => SyncedResultTransducer = (affordance: PermissionMetadata['affordance']) =>
  map((v) => {
    if (isReduced(v)) return v;
    if (affordance === 'checkbox') {
      return reduced(v.result);
    }
    return v;
  });

const checkBooleanValueWithinConstraints: SyncedResultTransducer = map((v) => {
  if (isReduced(v)) return v;
  if (typeof v.result?.value === 'boolean') {
    if (v.result.allowed_access?.length === 1) {
      const value = v.result?.allowed_access[0] === 'remove';
      return reduced({ ...v.result, value });
    }
    return reduced(v.result);
  }

  return v;
});

const checkPermissionValueWithinConstraints: SyncedResultTransducer = map(
  (v) => {
    if (isReduced(v)) return v;
    const { source, rule, result } = v;
    if (!result?.allowed_access || result?.value === undefined) {
      return { source, rule, result };
    }

    const { allowed_access, value } = result;
    const min = getAccessNumber(allowed_access[0]);
    const max = getAccessNumber(allowed_access[allowed_access.length - 1]);
    const numberValue =
      typeof value === 'boolean' ? getAccessNumber(value) : value;

    if (numberValue < min)
      return { source, rule, result: { ...result, value: min } };
    if (numberValue > max)
      return { source, rule, result: { ...result, value: max } };
    return v;
  }
);

const pluckResult: Transducer<SyncedResult, PermissionResultData> = map((v) => {
  return isReduced(v) ? v.deref() : v.result!;
});
