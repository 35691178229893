import {
  BlankSettingsSection,
  ElementContainerSettingsSection,
  TextSettingsSection,
} from '../../settings';

export const EmailTextSettings = ({ node, ...rest }) => {
  return (
    <>
      <TextSettingsSection node={node} {...rest} />
      <ElementContainerSettingsSection
        node={node}
        enableBackgroundImage={false}
        enableBorder={false}
        enableMargin={false}
      />
    </>
  );
};

export const FormTextSettings = ({ node }) => (
  <>
    <BlankSettingsSection node={node} />
    <ElementContainerSettingsSection node={node} />
  </>
);
