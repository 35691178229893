import Input from 'components/Kizen/Input';
import BasicModal from 'components/Modals/presets/BasicModal';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from './styles';

const NewURLDialog = ({
  options,
  create = true,
  onConfirm,
  onHide,
  ...rest
}) => {
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState(
    options?.item?.displayName ?? ''
  );
  const [url, setUrl] = useState(options?.item?.url ?? '');

  const handleConfirm = useCallback(() => {
    onConfirm(options, displayName, url);
  }, [onConfirm, options, displayName, url]);

  const handleCancel = useCallback(() => {
    onHide(create);
  }, [onHide, create]);

  return (
    <BasicModal
      {...rest}
      heading={create ? t('Add URL Button') : t('Edit URL Button')}
      show
      onConfirm={handleConfirm}
      onHide={handleCancel}
      disabled={!url.trim() || !displayName.trim()}
      leftBtn={false}
    >
      <Input
        placeholder={t('Enter Display Name')}
        value={displayName}
        onChange={setDisplayName}
        label={t('Display Name')}
        inModal
      />
      <StyledInput
        placeholder={t('Enter URL')}
        value={url}
        onChange={setUrl}
        label={t('URL Path')}
        inModal
      />
    </BasicModal>
  );
};

export default NewURLDialog;
