import { transformNode, TransformCtx } from './parser';
import { MJ_TEXT_CLASS_NAME } from './styles';
import {
  BuilderJson,
  MjmlAttributeChild,
  MjmlBody,
  MjmlHead,
  MjmlHeadChild,
  MjmlRoot,
  MjmlRootAttributes,
  MjmlStyle,
  MjmlText,
  WithoutChildren,
} from './types';

export const createDefaultTextStyle = (): [
  MjmlStyle,
  WithoutChildren<MjmlText>,
] => {
  return [
    {
      tagName: 'mj-style',
      attributes: { inline: 'inline' },
      // We want to mimic the styles setup for the text node in the builder:
      // apps/react-app/src/components/PageBuilder/Editor/nodes/Text.js
      content: `
          .${MJ_TEXT_CLASS_NAME} p {
            margin: 0;
            line-height: 1.5em;
          }
          .${MJ_TEXT_CLASS_NAME} ul, ol {
            margin-top: 0;
            margin-bottom: 10px;
          }
          .${MJ_TEXT_CLASS_NAME} p * {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
          }
          /* https://github.com/ueberdosis/tiptap/issues/412#issuecomment-593635371
          titap issue with removed 'br' in html content
          it is suggested method for rendering space for empty paragraphs */
          .${MJ_TEXT_CLASS_NAME} p:empty {
            height: 1.5em;
          }
        `,
    },
    {
      tagName: 'mj-text',
      attributes: {
        'css-class': MJ_TEXT_CLASS_NAME,
        'font-size': '14px',
      },
    },
  ];
};

type WithRootNodeOpts = {
  attributes?: MjmlRootAttributes;
  headAttributes?: MjmlAttributeChild[];
  headChildren?: MjmlHeadChild[];
};

const buildHeadNode = ({
  attributes,
  children = [],
}: {
  children?: MjmlHeadChild[];
  attributes?: MjmlAttributeChild[];
}): MjmlHead => ({
  tagName: 'mj-head',
  children: attributes
    ? children.concat({ tagName: 'mj-attributes', children: attributes })
    : children,
});

export const convertBuilderJson = (
  allNodes: BuilderJson,
  {
    processDynamicImages,
    processDynamicText,
  }: Pick<TransformCtx, 'processDynamicImages' | 'processDynamicText'> = {}
): [
  MjmlBody,
  {
    headAttributes: MjmlAttributeChild[];
    headChildren: TransformCtx['headChildren'];
  },
] => {
  const headAttributes: MjmlAttributeChild[] = [];
  const headChildren: TransformCtx['headChildren'] = [];
  const rootNode = { ...allNodes.ROOT, id: 'ROOT' };
  const body = transformNode(rootNode, {
    allNodes,
    headAttributes,
    headChildren,
    processDynamicImages,
    processDynamicText,
    globalTextLinkStyleAdded: false,
  }) as MjmlBody;
  return [body, { headAttributes, headChildren }];
};

export const withRootNode = (
  body: MjmlBody,
  { attributes = {}, headChildren, headAttributes }: WithRootNodeOpts
): MjmlRoot => ({
  tagName: 'mjml',
  attributes,
  children:
    headAttributes || headChildren
      ? [
          buildHeadNode({ children: headChildren, attributes: headAttributes }),
          body,
        ]
      : [body],
});
