import React from 'react';
import { messageMapper } from 'pages/Library/helpers';
import {
  useSentMessageById,
  useSyncedEmailMessageContentQuery,
} from 'queries/models/client-messages';
import SentMessageModal from 'components/Modals/SentMessage';

const SentMessageModalQuered = ({
  message,
  onHide,
  businessName,
  t,
  ...props
}) => {
  const { id, sentAt, syncedEmailId } = message || {};
  const { data = {}, isLoading } = useSentMessageById(id, !syncedEmailId);
  const {
    data: { text_html, text_plain = '', from, error: syncedMessageError } = {},
    isLoading: syncedEmailLoading,
  } = useSyncedEmailMessageContentQuery(syncedEmailId);
  const content =
    text_html || data.content || text_plain.replace(/(\r\n|\r|\n)/g, '<br>');

  return (
    <SentMessageModal
      isLoading={isLoading || syncedEmailLoading}
      show
      message={messageMapper(
        { ...message, ...data, from, content, created: sentAt },
        businessName,
        t
      )}
      onHide={onHide}
      error={(syncedMessageError || data.error) ?? false}
      {...props}
    />
  );
};

export default SentMessageModalQuered;
