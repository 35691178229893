import { useMemo } from 'react';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
  StyledIcon,
  StyledFolder,
} from 'components/Kizen/Table/CellLineHeightStyles';

import ActionCell from 'components/Tables/ActionCell';
import ValidatedField from 'components/Kizen/ValidatedField';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { colorsButton } from 'app/colors';
import { KizenTypography } from 'app/typography';
import { MESSAGE_TYPES } from 'utility/constants';
import { percentageFormatter } from 'utility/numbers';

const paddingSize = {
  basic: '30px',
  small: '24px',
};

const columnSize = {
  spacer: '20px',
  name: '209px',
  subject: '208px',
  sent: '70px',
  smartSent: '90px',
  delivered: '110px',
  opened: '110px',
  type: '50px',
  firstSent: '150px',
  actions: '50px',
};

export const actions = {
  view: 'view',
  stats: 'stats',
};

const TableActionCell = (props) => {
  const { data, t } = props;
  const { type } = data;

  const options = useMemo(() => {
    return [
      { value: actions.view, label: t('View') },
      type === 'email' ? { value: actions.stats, label: t('Stats') } : null,
    ].filter(Boolean);
  }, [t, type]);

  return type !== 'folder' ? (
    <ActionCell
      title={t('View Message')}
      menuType="absolute"
      options={options}
      {...props}
    />
  ) : (
    <SizedCell {...props} />
  );
};

TableActionCell.defaultProps = {
  isMobile: false,
  data: {
    type: null,
  },
};

export const getBroadcastsColumns = ({
  onUpdateBroadcast,
  onSelectAction,
  handleChangeDirectory,
  parentFolder,
  t,
}) => [
  {
    id: 'spacer-1',
    headCell: <SizedCell width={columnSize.spacer} />,
    cell: <SizedCell width={columnSize.spacer} />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        label={t('Name')}
        width={columnSize.name}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.name} padding={paddingSize.basic} />,
    format: function NameCell(value, message) {
      const handleSubmit = async (data) => {
        if (data !== value) {
          await onUpdateBroadcast({
            id: message.id,
            name: data,
          });
        }
      };
      return message.type === 'folder' ? (
        <StyledFolder>
          {message?.levelUp ? (
            <StyledIcon
              icon={'level-up'}
              rotation={270}
              color={colorsButton.blue}
              onClick={() => {
                handleChangeDirectory(parentFolder);
              }}
            />
          ) : (
            <StyledIcon
              icon="folder"
              color={colorsButton.blue}
              onClick={() => {
                handleChangeDirectory(message);
              }}
            />
          )}
          <KizenTypography
            type={'link'}
            onClick={() => {
              handleChangeDirectory(message.id ? message : parentFolder);
            }}
          >
            {value}
          </KizenTypography>
        </StyledFolder>
      ) : (
        <ValidatedField
          onClick={() =>
            onSelectAction({ value: actions.view, label: t('View') }, message)
          }
          type="link"
          value={value}
          onSubmit={handleSubmit}
        />
      );
    },
  },
  {
    id: 'subject',
    headCell: (
      <SortableHeadCell
        label={t('Subject Line')}
        width={columnSize.subject}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.subject} padding={paddingSize.basic} />,
    format: function modelCell(value) {
      return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
    },
  },
  {
    id: 'sent',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Sent')}
        width={columnSize.sent}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.sent} padding={paddingSize.basic} />,
    format: function activeCell(value) {
      return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
    },
  },
  {
    id: 'smartSent',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Smart Sent')}
        width={columnSize.smartSent}
        padding={paddingSize.basic}
      />
    ),
    cell: (
      <SizedCell width={columnSize.smartSent} padding={paddingSize.basic} />
    ),
    format: function activeCell(value, { sent, type }) {
      return type !== 'folder' && value ? (
        <TextEllipsisWithTooltip>
          {value} ({percentageFormatter.format(value / (sent || Infinity))})
        </TextEllipsisWithTooltip>
      ) : (
        <TextEllipsisWithTooltip />
      );
    },
  },
  {
    id: 'delivered',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Delivered')}
        width={columnSize.delivered}
        padding={paddingSize.basic}
      />
    ),
    cell: (
      <SizedCell width={columnSize.delivered} padding={paddingSize.basic} />
    ),
    format: function activeCell(value, { sent, type }) {
      return type !== 'folder' && value ? (
        <TextEllipsisWithTooltip>
          {value} ({percentageFormatter.format(value / (sent || Infinity))})
        </TextEllipsisWithTooltip>
      ) : (
        <TextEllipsisWithTooltip />
      );
    },
  },
  {
    id: 'opened',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Opened')}
        width={columnSize.opened}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.opened} padding={paddingSize.basic} />,
    format: function activeCell(value, { delivered, type }) {
      return type !== 'folder' && value ? (
        <TextEllipsisWithTooltip>
          {value} ({percentageFormatter.format(value / (delivered || Infinity))}
          )
        </TextEllipsisWithTooltip>
      ) : (
        <TextEllipsisWithTooltip />
      );
    },
  },
  {
    id: 'type',
    headCell: (
      <SortableHeadCell
        label={t('Type')}
        width={columnSize.numberActive}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.type} padding={paddingSize.basic} />,
    format: function activeCell(value) {
      return (
        <TextEllipsisWithTooltip>
          {MESSAGE_TYPES[value] ? MESSAGE_TYPES[value](t) : t('Other')}
        </TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'firstSent',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Date Sent')}
        width={columnSize.firstSent}
        padding={paddingSize.small}
      />
    ),
    cell: (
      <SizedCell width={columnSize.firstSent} padding={paddingSize.small} />
    ),
    format: (firstSent, data) => {
      return data?.type === 'folder' || !firstSent ? (
        // Show empty value: `-` for folders, since they don't really make sense yet.
        // We will have to change this special case when we add user-controlled folders... Maybe...
        <TextEllipsisWithTooltip />
      ) : (
        <DateTimeInputInline readOnly value={firstSent} showDateTooltip />
      );
    },
  },
  {
    id: 'actions',
    headCell: (
      <SubActionHeadCell
        label={t('Actions')}
        width={columnSize.actions}
        padding={columnSize.spacer}
      />
    ),
    cell: (
      <TableActionCell
        width={columnSize.actions}
        padding={columnSize.spacer}
        onSelectAction={onSelectAction}
        t={t}
      />
    ),
  },
];
