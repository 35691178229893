import React from 'react';
import { grayScale } from '../../app/colors';
import IconButton from '../Kizen/IconButton';
import Icon from '../Kizen/Icon';

const closeButtonColor = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

const CloseButton = (props) => (
  <IconButton
    sizing="mobile"
    data-dismiss="modal"
    color={closeButtonColor}
    {...props}
  >
    <Icon icon="close-cross" />
  </IconButton>
);

export default CloseButton;
