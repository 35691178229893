import { useTranslation } from 'react-i18next';
import { useEditor } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import {
  ToggleButton,
  ToolbarIcon,
  TextEditorToolbarSection,
} from '../../styles';
import React from 'react';
import { grayScale } from 'app/colors';

export const ListStyleToolbarSection = () => {
  const { t } = useTranslation();
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  if (!editor) return null;

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <ToggleButton
        active={editor.isActive('orderedList')}
        title={t('Numbered List')}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <ToolbarIcon icon="list-ol" color={grayScale.dark} />
      </ToggleButton>
      <ToggleButton
        active={editor.isActive('bulletList')}
        title={t('Bullet List')}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <ToolbarIcon icon="list-ul" color={grayScale.dark} />
      </ToggleButton>
    </TextEditorToolbarSection>
  );
};
