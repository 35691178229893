import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { grayScale, colorsButton } from '__app/colors';
import { layers } from '__app/spacing';
import Button from '__components/Button';
import EditableText from '__components/Kizen/EditableText';
import KizenErrorCard from '__components/Kizen/ErrorCard';

export const FilterMenu = styled.div`
  background-color: ${grayScale.white};
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
`;

export const FilterMenuHeader = styled.div<{
  sticky?: boolean;
  hideGroup?: boolean;
}>`
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
    `}
  top: calc(80px - 10px);
  background-color: white;
  z-index: ${layers.content(0, 1)};
  display: flex;
  justify-content: ${({ hideGroup }) =>
    hideGroup ? 'flex-end' : 'space-between'};
  align-items: flex-start;
  margin-bottom: 25px;
`;

export const FilterControls = styled.div`
  margin: 0 -7.5px;
`;

export const FilterControls2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-left: 1.5rem;
  & > button {
    margin: 0;
  }
`;

export const FilterControlButton = styled(Button)`
  margin-top: 0;
  margin-bottom: 0;
`;

export const FilterNameInput = styled(EditableText)`
  margin: 0;
  margin-bottom: 4px;
  align-self: flex-end;
  & input {
    max-width: 400px;
  }
`;

export const FilterNameWrapper = styled.div`
  height: 36px;
  display: flex;
`;

export const ErrorCard = styled(KizenErrorCard)`
  z-index: ${layers.content(0, 1)};
  // This matches the color of the underline, and the default border color does not
  border-color: ${colorsButton.red.hover};
`;
