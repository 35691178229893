import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useSummaryCounts = (summary, isContactsSummary = true) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return useMemo(() => {
    const format = (num) => Intl.NumberFormat(language).format(num);
    const {
      clientsCount = 0,
      allowedCount = 0,
      nbSelected = 0,
      text = '',
      nbUnsubscribed = 0,
      nbOptedOut = 0,
      nbNotAllowed = 0,
      notAllowedCount = 0,
      nbSuppressionList = 0,
      disqualifiedCount = 0,
      lostCount = 0,
      wonCount = 0,
    } = summary || {};

    const countNum = isContactsSummary ? clientsCount : allowedCount;

    const count = format(countNum);
    const selected = isContactsSummary ? format(nbSelected) : text;
    const notAllowed = format(
      isContactsSummary ? nbNotAllowed : notAllowedCount
    );
    const unsubscribed = format(nbUnsubscribed);
    const optedOut = format(nbOptedOut);
    const suppressionList = format(nbSuppressionList);
    const notIncludedString = [
      (nbNotAllowed || notAllowedCount) &&
        `${notAllowed} ${t('Insufficient Permissions')}`,
      nbOptedOut && `${optedOut} ${t('Opted Out')}`,
      nbUnsubscribed && `${unsubscribed} ${t('Unsubscribed')}`,
      nbSuppressionList && `${suppressionList} ${t('Suppression List')}`,
      wonCount && `${wonCount} ${t('Won')}`,
      lostCount && `${lostCount} ${t('Lost')}`,
      disqualifiedCount && `${disqualifiedCount} ${t('Disqualified')}`,
    ]
      .filter(Boolean)
      .join(', ');

    return {
      countNum,
      count,
      selected,
      unsubscribed,
      optedOut,
      notAllowed,
      suppressionList,
      notIncludedString,
    };
  }, [summary, isContactsSummary, t, language]);
};
