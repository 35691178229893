import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Container, Navbar } from 'react-bootstrap';
import { grayScale, shadow } from 'app/colors';

const StyledNavbar = styled(Navbar)(
  {
    backgroundColor: grayScale.white,
  },
  shadow
);

const SubNavigationBar = ({
  className,
  expand,
  toggle,
  children,
  label,
  ...props
}) => {
  return (
    <StyledNavbar expand={expand} {...props}>
      <Container>
        <Navbar.Brand style={{ margin: 0 }}>{label}</Navbar.Brand>
        {toggle && <Navbar.Toggle />}
        <Navbar.Collapse id="sub-nav-collapse">{children}</Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

SubNavigationBar.propTypes = {
  expand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  toggle: PropTypes.bool,
  label: PropTypes.element,
};

SubNavigationBar.defaultProps = {
  expand: 'sm',
  toggle: true,
  label: <></>,
};

export default SubNavigationBar;
