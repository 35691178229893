import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { ActionHeadCell } from 'components/Tables/ActionCell';

import StatusWidget from './StatusWidget';
import ActionWidget from './ActionWidget';
import useCan from 'hooks/useCan';
import KizenTypography from 'app/kizentypo';
import { SORT_BY } from './contstants';

const COLUMN_SIZING = {
  spacer: { padding: '20px', width: '20px' },
  name: { padding: '30px', width: '510px' },
  status: { padding: '30px', width: '200px' },
  created: { padding: '30px', width: '200px' },
  completed: { padding: '30px', width: '200px' },
  actions: { padding: '12px', width: '80px' },
};

export const AutomationName = ({
  automationName,
  automationId,
  automationDeleted,
}) => {
  const canView =
    useCan({ view: 'sections', for: 'automations_section' }) &&
    automationDeleted === false;
  return canView ? (
    <TextEllipsisWithTooltip
      type="link"
      to={`/automation/${automationId}`}
      target="_blank"
      value={automationName}
    >
      {automationName}
    </TextEllipsisWithTooltip>
  ) : (
    <TextEllipsisWithTooltip>{automationName}</TextEllipsisWithTooltip>
  );
};

export const getColumns = (
  { onUpdateAutomation, canManageAutomations, scrollContainerRef, canStart },
  t
) =>
  [
    {
      id: 'spacer',
      headCell: <SizedCell width={COLUMN_SIZING.spacer.width} />,
      cell: <SizedCell width={COLUMN_SIZING.spacer.width} />,
    },
    {
      id: 'automationName',
      headCell: (
        <SortableHeadCell
          by={SORT_BY.NAME}
          label={t('Automation Name')}
          minWidth={COLUMN_SIZING.name.width}
          padding={COLUMN_SIZING.name.padding}
        />
      ),
      cell: (
        <SizedCell
          data-qa="Automation Name"
          minWidth={COLUMN_SIZING.name.width}
          padding={COLUMN_SIZING.name.padding}
        />
      ),
      format: (_, automation) => <AutomationName {...automation} />,
    },
    {
      id: 'status',
      headCell: (
        <SortableHeadCell
          by={SORT_BY.STATUS}
          label={t('Status')}
          width={COLUMN_SIZING.status.width}
          padding={COLUMN_SIZING.status.padding}
        />
      ),
      cell: (
        <SizedCell
          data-qa="Status"
          width={COLUMN_SIZING.status.width}
          padding={COLUMN_SIZING.status.padding}
        />
      ),
      format: (_, automation) => <StatusWidget automation={automation} />,
    },
    {
      id: 'startTime',
      headCell: (
        <SortableHeadCell
          by={SORT_BY.START_TIME}
          numeric
          label={t('Start Time')}
          width={COLUMN_SIZING.created.width}
          padding={COLUMN_SIZING.created.padding}
        />
      ),
      cell: (
        <SizedCell
          data-qa="Start Time"
          width={COLUMN_SIZING.created.width}
          padding={COLUMN_SIZING.created.padding}
        />
      ),
      format: (startTime) => (
        <DateTimeInputInline readOnly value={startTime} showDateTooltip />
      ),
    },
    {
      id: 'completed',
      headCell: (
        <SortableHeadCell
          by={SORT_BY.COMPLETED}
          numeric
          label={t('Date Completed')}
          width={COLUMN_SIZING.completed.width}
          padding={COLUMN_SIZING.completed.padding}
        />
      ),
      cell: (
        <SizedCell
          data-qa="Date Completed"
          width={COLUMN_SIZING.completed.width}
          padding={COLUMN_SIZING.completed.padding}
        />
      ),
      format: (completed) => {
        return completed ? (
          <DateTimeInputInline readOnly value={completed} showDateTooltip />
        ) : (
          <KizenTypography>{'—'}</KizenTypography>
        );
      },
    },
    canManageAutomations && {
      id: 'actions',
      headCell: (
        <ActionHeadCell
          t={t}
          minWidth={COLUMN_SIZING.actions.width}
          padding={COLUMN_SIZING.actions.padding}
        />
      ),
      cell: (
        <ActionWidget
          data-qa="Actions"
          minWidth={COLUMN_SIZING.actions.width}
          padding={COLUMN_SIZING.actions.padding}
          onUpdateAutomation={onUpdateAutomation}
          scrollContainerRef={scrollContainerRef}
          canStart={canStart}
        />
      ),
    },
  ].filter(Boolean);
