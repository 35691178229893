import { forwardRef, useMemo, useRef, useState } from 'react';
import SelectInline from 'components/Inputs/inline/Select';
import { useUpdateEffect } from 'react-use';
import { colorsButton } from 'app/colors';
import { useTranslation } from 'react-i18next';
import useModal from 'components/Modals/useModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { IconSizing } from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import Icon from 'components/Kizen/Icon';
import { KizenTypography } from 'app/typography';
import { mapValues } from 'lodash';
import useFlashState from 'hooks/useFlashState';
import { gutters } from 'app/spacing';
import { modalConstrainPopperConfig } from 'components/Inputs/Validation';

import EditableText from 'components/Kizen/EditableText';
import { StyledIconControl, StyledInputControl } from './HeadCells';
import { getDataQAForInput } from 'components/Inputs/helpers';
import {
  EditTextWrapper,
  LineItemWrapper,
  Select,
  TimelineSwitch,
} from './styled';
import Validation from 'components/Inputs/Validation';
import useMenuPlacement from './useMenuPlacement';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';

const stayInViewport = {
  modifiers: {
    preventOverflow: {
      // Allows element to appear outside of the target's
      // scrolling parent but not outside the viewport.
      boundariesElement: 'viewport',
    },
  },
};
export const LineItem = forwardRef(
  (
    {
      errors,
      editable,
      onConfirmDelete,
      allModelOptions,
      value,
      onChange,
      onClickRemove,
      objectFields,
      entityName: entityNameProp,
      sectionRef,
      relation,
      allRelationTypeOptions,
      placementTopStart,
      index,
      validationProps,
      ...props
    },
    ref
  ) => {
    const { ref: refCallback, isIntersecting } = useMenuPlacement();
    const isDisabled = !editable;
    const relatedObjectField = useMemo(
      () => objectFields && objectFields.find((el) => el.id === value.fieldId),
      [value, objectFields]
    );

    const dictionary = useMemo(
      () =>
        allRelationTypeOptions.find(
          ({ value: valueId }) => valueId === value.relationType
        ),
      [allRelationTypeOptions, value.relationType]
    );

    const [displayName, setDisplayName] = useState(value.field?.displayName);

    const [relatedName, setRelatedName] = useState(
      value?.field?.relation?.relatedName
    );

    const [confirmDeletionModalProps, , confirmDeletionModal] = useModal({
      handleSubmit: async () => {
        await onConfirmDelete(relatedObjectField, relation);
      },
    });

    const handleSelectAction = () => {
      confirmDeletionModal.show();
    };

    const valueRef = useRef();
    valueRef.current = value;

    const { t } = useTranslation();

    const relationTypeRef = useRef();
    const [relationTypeError, flashRelationTypeError] = useFlashState();

    useUpdateEffect(() => {
      if (errors.validation) {
        if (!valueRef.current.relationType) {
          flashRelationTypeError(true);
          const inputRef = mapValues(relationTypeRef, 'select.inputRef');
          if (inputRef.current) {
            inputRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }
      }
    }, [errors, flashRelationTypeError]);

    const [nameTooltipProps, nameTooltip] = useTruncationTooltip();
    const [reverseNameTooltipProps, reverseNameTooltip] =
      useTruncationTooltip();

    const displayNameTarget = useRef();
    const displayNameValidation = {
      ...validationProps(`${relation}.[${index}].field.displayName`),
    };
    const displayNameClone = JSON.parse(JSON.stringify(displayNameValidation));

    const reverseNameTarget = useRef();
    const reverseNameValidation = {
      ...validationProps(`${relation}.[${index}].field.relation.relatedName`),
    };
    const reverseNameClone = JSON.parse(JSON.stringify(reverseNameValidation));
    const isSuppressed =
      !!value?.field?.isSuppressed ||
      !!value?.field?.relation?.inverseRelationSuppressed;

    return (
      <LineItemWrapper ref={refCallback} editable={!isDisabled} {...props}>
        <StyledInputControl
          paddingLeft={gutters.spacing(3)}
          {...getDataQAForInput('object-name', 'dropdown')}
        >
          <SelectInline
            popperConfig={stayInViewport}
            hoverable={false}
            inModal
            readOnly={isDisabled}
            placementTopStart={!isIntersecting}
            container={sectionRef.current}
            labelInfo={
              isSuppressed
                ? t(
                    'Timeline sharing is disabled when the related field is suppressed'
                  )
                : ''
            }
          >
            <Select
              placeholder={t('Choose Object')}
              sizing="mobile"
              value={value.relatedObject}
              onChange={({ value: relatedObject, entityName, isContact }) => {
                onChange(
                  {
                    ...value,
                    relatedObject,
                    entityName,
                    isContact,
                    relationType: value.relationType,
                    field: {
                      ...value.field,
                      displayName: dictionary.name(entityName),
                      relation: {
                        ...value?.field?.relation,
                        relatedName: dictionary.reverseName(entityNameProp),
                      },
                    },
                  },
                  true
                );
              }}
              options={allModelOptions}
              style={{ top: 6 }}
            />
          </SelectInline>
        </StyledInputControl>
        <StyledInputControl
          paddingLeft={gutters.spacing(6)}
          {...getDataQAForInput('relationship', 'dropdown')}
        >
          <SelectInline
            popperConfig={stayInViewport}
            hoverable={false}
            inModal
            readOnly={isDisabled}
            placementTopStart={!isIntersecting}
            container={sectionRef.current}
          >
            <Select
              ref={relationTypeRef}
              placeholder={t('Choose Relationship')}
              sizing="mobile"
              error={relationTypeError}
              value={
                value.relationType === 'additional_for'
                  ? 'additional'
                  : value.relationType
              }
              onChange={({ value: relationType }) => {
                onChange({ ...value, relationType });
              }}
              options={
                relation === 'relatedObjectsReverse'
                  ? isDisabled
                    ? allRelationTypeOptions
                    : allRelationTypeOptions.slice(0, -1)
                  : allRelationTypeOptions
              }
              style={{ width: 280, top: 6 }}
            />
          </SelectInline>
        </StyledInputControl>
        <StyledInputControl
          paddingLeft={gutters.spacing(6)}
          {...getDataQAForInput('relationship_name')}
        >
          <EditTextWrapper ref={displayNameTarget}>
            {nameTooltip}
            <EditableText
              sizing="dense"
              placeholder={t('Enter Relationship Name')}
              value={displayName}
              onChange={setDisplayName}
              {...displayNameValidation}
              {...nameTooltipProps}
              onBlur={() => {
                onChange({
                  ...value,
                  field: {
                    modified: !displayName,
                    ...value?.field,
                    displayName: displayName,
                    name: displayName,
                  },
                });
              }}
              required
            />
            <Validation
              inModal
              hideOutOfBoundaries
              {...displayNameClone.validate}
              popperConfig={{
                modifiers: {
                  ...modalConstrainPopperConfig.modifiers,
                },
              }}
              target={displayNameTarget.current}
            />
          </EditTextWrapper>
        </StyledInputControl>
        <StyledInputControl
          paddingLeft={gutters.spacing(6)}
          {...getDataQAForInput('reverse_relationship_name')}
        >
          <EditTextWrapper ref={reverseNameTarget}>
            {reverseNameTooltip}
            <EditableText
              sizing="dense"
              placeholder={t('Enter Reverse Relationship Name')}
              value={relatedName}
              onChange={setRelatedName}
              {...reverseNameValidation}
              {...reverseNameTooltipProps}
              onBlur={() => {
                onChange({
                  ...value,
                  field: {
                    modified: !relatedName,
                    ...value?.field,
                    relation: {
                      ...value?.field?.relation,
                      relatedName: relatedName,
                    },
                  },
                });
              }}
              required
            />
            <Validation
              inModal
              hideOutOfBoundaries
              {...reverseNameClone.validate}
              popperConfig={{
                modifiers: {
                  ...modalConstrainPopperConfig.modifiers,
                },
              }}
              target={reverseNameTarget.current}
            />
          </EditTextWrapper>
        </StyledInputControl>
        <StyledInputControl
          paddingLeft={gutters.spacing(6)}
          {...getDataQAForInput('rollup-timeline')}
        >
          <TimelineSwitch
            disabled={isSuppressed}
            checked={value?.field?.relation?.inverseRelationRollupTimeline}
            onChange={(ev) => {
              onChange({
                ...value,
                field: {
                  ...value?.field,
                  relation: {
                    ...value?.field?.relation,
                    inverseRelationRollupTimeline: ev.target.checked,
                  },
                },
              });
            }}
          />
        </StyledInputControl>
        <StyledInputControl
          paddingLeft={gutters.spacing(6)}
          paddingRight={gutters.spacing(8, 2)}
          {...getDataQAForInput('inverse-relation-rollup-timeline')}
        >
          <TimelineSwitch
            disabled={isSuppressed}
            checked={value?.field?.relation?.rollupTimeline}
            onChange={(ev) => {
              onChange({
                ...value,
                field: {
                  ...value?.field,
                  relation: {
                    ...value?.field?.relation,
                    rollupTimeline: ev.target.checked,
                  },
                },
              });
            }}
          />
        </StyledInputControl>
        <StyledIconControl>
          <IconButton
            title={t('Delete')}
            sizing="dense"
            color={colorsButton.iconGray}
            onClick={!isDisabled ? onClickRemove : handleSelectAction}
          >
            <IconSizing size="14px">
              <Icon icon="delete" />
            </IconSizing>
          </IconButton>
        </StyledIconControl>
        <ConfirmDeletionModal {...confirmDeletionModalProps}>
          <KizenTypography>
            {t(
              'This will permanently delete the Field and all associated data with it ' +
                'from your database. Since this is a relationship field, it will also ' +
                'delete the other related field'
            )}{' '}
            {relatedObjectField?.relation?.relatedObjectName}:{' '}
            {relatedObjectField?.displayName} {t('and all of its data.')}{' '}
            {t('This action cannot be undone.')}
          </KizenTypography>
        </ConfirmDeletionModal>
      </LineItemWrapper>
    );
  }
);
