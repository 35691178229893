import { BigListSearchInput } from 'components/Layout/BigListLayout/Toolbar';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { useTranslation } from 'react-i18next';
import {
  ControlHeader,
  LoaderContainer,
  NameCell,
  StyledButton,
  TableWrapper,
} from './styles';
import BasicTable from 'components/Tables/Basic';
import {
  HeaderSizedCell,
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
  TRow,
} from 'components/Kizen/Table';
import React, { useCallback, useMemo, useRef } from 'react';
import KizenTypography from 'app/kizentypo';
import Loader from 'components/Kizen/Loader';
import StatsMenu from './components/StatsMenu';
import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';

const formatDate = (val) => {
  const [Y, M, D] = val.split('-');
  return `${M}/${D}/${Y}`;
};

const EmailInteractionStatsDashletList = ({
  dashboardId,
  dahlet,
  emailList,
  currentEmail,
  sort,
  searchTerm,
  setCurrentEmail,
  setSearchTerm,
  setDebouncedSearchTerm,
  setSort,
  invert,
  mobile,
  queryKey,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  emailListLoading,
}) => {
  const { t } = useTranslation();

  const allEmails = useMemo(() => {
    return (
      emailList?.pages?.reduce((acc, page) => {
        return [...acc, ...page.results];
      }, []) ?? []
    );
  }, [emailList]);

  const columns = useMemo(() => {
    return [
      {
        id: 'spacer',
        cell: <SizedCell width="30px" />,
        headCell: <SizedCell width="30px" />,
      },
      {
        id: 'name',
        cell: <SizedCell minWidth="200px" padding="30px" />,
        headCell: (
          <SortableHeadCell
            label={t('Email Name')}
            minWidth="200px"
            padding="30px"
          />
        ),
        format: (value, data) => {
          return (
            <NameCell onClick={() => setCurrentEmail(data)}>
              <TextEllipsisWithTooltip>
                {value?.trim() || emptyLongDash}
              </TextEllipsisWithTooltip>
            </NameCell>
          );
        },
      },
      {
        id: 'date',
        cell: <SizedCell width="87px" padding="28px" />,
        headCell: (
          <SortableHeadCell
            numeric
            label={t('Date')}
            tooltipLabel={t('Last Email Sent')}
            width="87px"
            padding="28px"
          />
        ),
        format: (value) => {
          return (
            <KizenTypography>{value ? formatDate(value) : '–'}</KizenTypography>
          );
        },
      },
      !mobile
        ? {
            id: 'stats',
            cell: <SizedCell width="40px" padding="10px" />,
            headCell: (
              <HeaderSizedCell width="40px" label={t('Stats')} padding="10px" />
            ),
            format: (_, data) => (
              <StatsMenu viewOnChart={() => setCurrentEmail(data)} />
            ),
          }
        : null,
    ].filter(Boolean);
  }, [setCurrentEmail, mobile, t]);

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => setSort({ column, direction }),
      },
    }),
    [sort, setSort]
  );

  const handleClearCurrentEmail = useCallback(() => {
    setCurrentEmail(null);
    setSearchTerm('');
    setDebouncedSearchTerm('');
  }, [setCurrentEmail, setSearchTerm, setDebouncedSearchTerm]);

  const timer = useRef();
  const handleScroll = useCallback(
    ([, , bottom]) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        if (hasNextPage && bottom && !isFetchingNextPage) {
          fetchNextPage();
        }
      }, 100);
    },
    [hasNextPage, isFetchingNextPage, fetchNextPage]
  );

  return (
    <TableWrapper invert={invert}>
      <ControlHeader mobile={mobile}>
        <BigListSearchInput
          placeholder={t('Find Messages')}
          value={searchTerm}
          onChange={setSearchTerm}
        />
        <StyledButton
          variant="outline"
          color="blue"
          onClick={handleClearCurrentEmail}
        >
          {t('View all email stats')}
        </StyledButton>
      </ControlHeader>
      <Loader loading={emailListLoading}>
        <TableScrollContainer
          left
          right
          bottom
          onChangeScrolledVertical={handleScroll}
          key={JSON.stringify(queryKey)}
        >
          <BasicTable
            stickyHeader
            head={<TRow head columns={columns} data={headData} />}
          >
            {allEmails.map((email) => (
              <TRow
                key={email.id}
                columns={columns}
                data={email}
                className={email.id === currentEmail?.id ? 'active' : undefined}
              />
            ))}
            {hasNextPage || isFetchingNextPage ? (
              <LoaderContainer columns={columns}>
                <td colSpan={3}>
                  <Loader loading />
                </td>
              </LoaderContainer>
            ) : null}
          </BasicTable>
        </TableScrollContainer>
      </Loader>
    </TableWrapper>
  );
};

export default EmailInteractionStatsDashletList;
