import { css } from '@emotion/core';
import styled from '@emotion/styled';
import type { Option } from '@kizen/filters/types';
import { useSelector } from 'react-redux';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';

import { PropsWithChildren, useMemo, useRef, useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import { KizenTypography } from '__app/typography';
import Select from '__components/Inputs/Select';
import { InputContainer } from './inputs/styled';
import { SelectOption } from './inputs/types';
import { DeleteRowButton } from './components';
import { ErrorCard, ErrorCardWrapper } from './styled';
import useDebounce from 'react-use/lib/useDebounce';
import {
  AutomationFilters,
  ClientFilters,
  CustomObjectFilters,
  PipelineObjectFilters,
} from './types';

const Filters = {
  automation: AutomationFilters,
  client_client: ClientFilters,
  standard: CustomObjectFilters,
  pipeline: PipelineObjectFilters,
};

type FilterTypeSelectorProps = {
  value: string | null;
  placeholder?: string;
  options: Option[];
  width: number;
  objectType?: 'automation' | 'client_client' | 'standard' | 'pipeline';
  error?: string | false;
  onChange(opt: Pick<SelectOption, 'label' | 'value'>): void;
  translateY?: number | null;
  modalLayer?: number;
  hideOutOfBoundaries?: boolean;
};

type FilterTypeSelectorContainerProps = {
  onDelete?(): void;
  showDeleteIcon?: boolean;
};

const Container = styled.div<FilterTypeSelectorContainerProps>`
  display: flex;
  gap: 15px;
  align-items: center;

  ${({ showDeleteIcon = false }) =>
    showDeleteIcon &&
    css`
      margin-left: -28px;
    `}
`;

export const FilterTypeSelectorContainer = ({
  showDeleteIcon,
  children,
  onDelete,
}: PropsWithChildren<FilterTypeSelectorContainerProps>) => (
  <Container showDeleteIcon={showDeleteIcon}>
    {showDeleteIcon && <DeleteRowButton onClick={onDelete} />}
    {children}
  </Container>
);

export const FilterTypeSelector = ({
  value,
  placeholder,
  width,
  objectType,
  options,
  error,
  translateY = null,
  hideOutOfBoundaries = false,
  modalLayer,
  onChange,
}: FilterTypeSelectorProps) => {
  // wait for the error to be set before showing it to keep the overlay in the correct place
  const [debouncedError, setDebouncedError] = useState<any>(null);
  useDebounce(() => setDebouncedError(error), 350, [error]);
  const containerRef = useRef<any>();
  const opts = useMemo(() => {
    if (!objectType) {
      return [];
    }
    const validFilters = Filters[objectType];
    return options.filter((o) => validFilters.includes(o.value));
  }, [options, objectType]);
  const showError = Boolean(debouncedError);

  return (
    <InputContainer
      ref={containerRef}
      data-qa="filter-type-selector"
      width={width}
    >
      <Select
        value={value}
        options={opts}
        placeholder={placeholder}
        error={debouncedError}
        onChange={onChange}
      />
      {/* @ts-expect-error - The Overlay types are wrong */}
      <Overlay
        placement="bottom-start"
        transition={false}
        show={showError}
        target={containerRef.current}
      >
        <ErrorCardWrapper
          modalLayer={modalLayer}
          hideOutOfBoundaries={hideOutOfBoundaries}
        >
          <ErrorCard show={showError} duration="300ms" translateY={translateY}>
            <KizenTypography>{debouncedError}</KizenTypography>
          </ErrorCard>
        </ErrorCardWrapper>
      </Overlay>
    </InputContainer>
  );
};

export const ObjectOverviewFilterTypeSelector = (
  props: Pick<
    FilterTypeSelectorProps,
    'value' | 'onChange' | 'error' | 'objectType' | 'translateY' | 'modalLayer'
  >
) => {
  const { filter_type_step } = useSelector(selectFilterMetadataDefinition);

  return (
    <FilterTypeSelector
      placeholder={filter_type_step.placeholder}
      options={filter_type_step.options}
      width={filter_type_step.width}
      {...props}
    />
  );
};

export const AutomationFilterTypeSelector = (
  props: Pick<
    FilterTypeSelectorProps,
    'value' | 'onChange' | 'error' | 'translateY' | 'modalLayer'
  >
) => {
  const { filter_type_step } = useSelector(selectFilterMetadataDefinition);

  return (
    <FilterTypeSelector
      objectType="automation"
      placeholder={filter_type_step.placeholder}
      options={filter_type_step.options}
      width={filter_type_step.width}
      {...props}
    />
  );
};
