import styled from '@emotion/styled';
import Cols from 'pages/Styleguide/components/helpers/Cols';
import { breakpoints, gutters } from 'app/spacing';
import { CONTENT_MAX_WIDTH } from 'components/Layout/PageContentWidth';
import { SubSectionWithHeader } from 'components/Layout/SubSection';
import Section from 'components/Layout/Section';

export const Sections = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSection = styled(Section)`
  padding: 0;
  margin: 0 ${gutters.spacing(4)}px;
`;

export const GoLiveStyledSection = styled(StyledSection)`
  margin-top: ${gutters.spacing(4)}px;
`;

export const StyledSubSection = styled(SubSectionWithHeader)`
  padding: 5px;
`;

export const CardContainer = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${gutters.spacing(4)}px;
  }
`;

export const Container = styled.div`
  max-width: ${CONTENT_MAX_WIDTH};
  width: 100%;
  margin: 0 auto;
  background-color: unset;
  & > div {
    margin: 0 auto;
    max-width: 100%;
  }
`;

export const StyledCols = styled(Cols)`
  flex-wrap: nowrap;
  > div {
    width: calc(50% - ${gutters.spacing(2)}px);
    margin-bottom: ${gutters.spacing(4)}px;
  }
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    > div {
      margin-right: ${gutters.spacing(2)}px;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
    }
    > div:last-of-type {
      margin-right: 0;
      margin-left: ${gutters.spacing(2)}px;
    }
  }
`;
