import { useRef, useCallback, useMemo, useState } from 'react';

export const useDraggableAsFixedProps = () => {
  const ref = useRef();
  const [handlers, setHandlers] = useState({});
  const [yOffset, setYOffset] = useState(0);
  const refCallback = useCallback((node) => {
    ref.current = node;
    setHandlers({
      onDragStart: () => {
        setYOffset(-ref.current?.scrollTop || 0);
        node.style.pointerEvents = 'none';
      },
      onDragEnd: () => (node.style.pointerEvents = 'auto'),
    });
  }, []);
  const props = useMemo(() => ({ ...handlers, yOffset }), [handlers, yOffset]);
  return [refCallback, props];
};
