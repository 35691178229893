import { memo } from 'react';
import { getErrorStepConfig } from 'pages/AutomationEngine/steps';
import { colorsButton } from 'app/colors';
import StepCard from '__pages/AutomationEngine/Engine/Steps/StepCard';
import MovingPlaceholder from '__pages/AutomationEngine/Engine/Steps/MovingPlaceholder';
import * as cardTypes from '__pages/AutomationEngine/Engine/Steps/type';

type ErrorStepCardProps = {
  step: any;
  type: string;
  showStats: boolean;
  showHandle: boolean | undefined;
  showMenu: boolean | undefined;
  onSelectMenu: any;
  disableMenu: boolean;
  scale: number;
  onStart: any;
  onStop: any;
  showMovingPlaceholder: boolean;
};

function ErrorStepCard({
  type,
  step,
  showStats,
  disableMenu,
  scale,
  onStart,
  onStop,
  showMovingPlaceholder = false,
  ...others
}: ErrorStepCardProps) {
  const stepType = step.type === 'goal' ? step.details.type : step.type;

  const stepConfig = getErrorStepConfig(stepType, {
    isTrigger: type === 'automationTrigger',
  });

  const CardComponent =
    (showMovingPlaceholder && MovingPlaceholder) ||
    cardTypes[stepConfig.CardComponent as unknown as string] ||
    StepCard;

  return (
    <CardComponent
      title={stepConfig.title}
      color={colorsButton.red.hover}
      borderColor={colorsButton.red.hover}
      panel={null}
      menuOptions={[
        stepConfig.deletable && { label: 'Delete', value: 'delete' },
      ].filter(Boolean)}
      disableMenu={
        disableMenu || (!stepConfig.creatable && !stepConfig.deletable)
      }
      {...(stepConfig.CardComponent && { type, step })}
      {...(stepConfig.movable && {
        scale,
        onStart,
        onStop,
      })}
      {...others}
    />
  );
}

export default memo(ErrorStepCard);
