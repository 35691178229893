import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useHomepageMergeFields = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        label: t('Team Member Fields'),
        items: [
          {
            label: t('Team Member First Name'),
            relationship: 'team_member.first_name',
          },
          {
            label: t('Team Member Last Name'),
            relationship: 'team_member.last_name',
          },
          {
            label: t('Team Member Email'),
            relationship: 'team_member.email',
          },
          {
            label: t('Active Role(s)'),
            relationship: 'team_member.roles',
          },
        ],
      },
      {
        label: t('Business Fields'),
        items: [
          {
            label: t('Business Name'),
            relationship: 'business.name',
          },
          {
            label: t('Business Phone Number'),
            relationship: 'business.phone',
          },
          {
            label: t('Business Street Address'),
            relationship: 'business.street_address',
          },
          {
            label: t('Business Country'),
            relationship: 'business.country',
          },
          {
            label: t('Business State/Province'),
            relationship: 'business.state',
          },
          {
            label: t('Business City'),
            relationship: 'business.city',
          },
          {
            label: t('Business Zip/Postal Code'),
            relationship: 'business.postal_code',
          },
        ],
      },
      {
        label: t('Default Fields'),
        items: [
          {
            label: t("Today's Date"),
            relationship: 'team_member.date',
          },
          {
            label: t('Current Time (User Timezone)'),
            relationship: 'team_member.time',
          },
          {
            label: t('Current Time (Business Timezone)'),
            relationship: 'business.time',
          },
        ],
      },
    ];
  }, [t]);
};
