import { useCallback, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Kizen/Loader';
import Select from 'components/Inputs/Select';
import InputControl from 'components/Inputs/InputControl';
import CustomObjectService from 'services/CustomObjectsService';
import { DndColumns } from './DnDColumns';
import { CONTEXTS } from 'components/Wizards/utils';
import { useGroupedFields } from 'components/Wizards/shared/hooks/useGroupedFields';
import FilterSummary from 'components/Wizards/shared/components/MetaFilters/Summary';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { layers, useBreakpoint } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import { grayScale } from 'app/colors';
import { dropdownColors } from '@kizen/page-builder/internal/app/colors';
import { useScrollIntoView } from 'hooks/use-scroll-into-view';
import MultiSelect from 'components/Inputs/MultiSelect';
import {
  isCustomFilter,
  isInGroupFilter,
  isNotInGroupFilter,
} from 'components/Wizards/MetaFilters/types';
import { FILTER_TYPES } from 'components/Wizards/MetaFilters/types';
import { ColumnsHeaderPreview } from 'components/Wizards/shared/components/TableBuilder/components/ColumnsHeaderPreview';
import { getDashletFilterCount } from 'pages/Dashboard/util';
import {
  getCustomObjectOrderedFields,
  linkableFieldTypes,
} from '../../utilities';
import { useAsync } from 'react-use';
import css from '@emotion/css';
import { useRelatedContext } from './related-context';
import { isFirstNameField, isLastNameField } from 'checks/fields';
import { isNonHidebleColumn } from 'components/Wizards/CustomObject/steps/CustomLayout/subsections/DefaultColumns/helpers';
import { monitoringExceptionHelper } from 'sentry/helpers';

export const scrollOptions = {
  behavior: 'smooth',
  block: 'start',
};
// default height of the text
// 500 table height 80 spacing summary and 2 pixes for borders
const WRAPPER_HEIGHT = 582;

const Wrapper = styled.div`
  min-height: ${({ objectIsSelected }) =>
    objectIsSelected ? WRAPPER_HEIGHT : 0}px;
  display: flex;
  flex-direction: column;
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
          transition: opacity 250ms linear 500ms;
        `
      : ''}
`;
const StyledInputControl = styled(InputControl)`
  z-index: ${layers.modals(3, 1)};
  margin-top: 20px;
`;

const StyledSelect = styled(Select)`
  li:hover {
    background-color: ${dropdownColors.hover};
  }
  p {
    color: ${grayScale.dark};
  }
`;

export const Component = function RelatedObjectFields({
  objects = EMPTY_ARRAY,
  fields,
  filters,
  relationshipFields,
  tableHeight,
  onChange,
  dirtyRef,
  mainObjectFields,
  setDisabled,
  disabled,
  ...rest
}) {
  const { scrollNext, componentRef: wrapperRef } =
    useScrollIntoView(scrollOptions);
  const isMobile = useBreakpoint();
  const { t } = useTranslation();
  const { selectedObject, setSelectedObject } = useRelatedContext();

  const [fieldObject, setFieldObject] = useState({});
  const [objectFields, setObjectFields] = useState([]);
  const [fieldCategories, setFieldCategories] = useState([]);
  const [clearedFilters, setClearedFilters] = useState(true);
  const [displayErrorTimeout, setDisplayErrorTimeout] = useState(true);
  const defaultFieldsRef = useRef(false);
  const relationshipFieldMultiSelectRef = useRef(null);
  const displayErrorRef = useRef(true);

  useAsync(async () => {
    if (selectedObject?.id) {
      try {
        setDisabled(true);
        defaultFieldsRef.current = false;
        const fieldObject = await CustomObjectService.getCustomObjectDetails(
          selectedObject.originalId
        );
        const { fields, fieldCategories } = fieldObject;
        setObjectFields(fields);
        setFieldCategories(fieldCategories);
        setFieldObject(fieldObject);
      } catch (err) {
        monitoringExceptionHelper(err);
      } finally {
        setDisabled(false);
        // setSelectedObject(selectedObject);
      }
    } else {
      setSelectedObject(null);
    }
  }, [selectedObject]);

  if (fields?.[selectedObject?.id] && !defaultFieldsRef.current) {
    defaultFieldsRef.current = fields[selectedObject.id];
  }

  const contacts = useMemo(
    () => selectedObject?.fetchUrl === 'client',
    [selectedObject]
  );

  const fullNameItem = useMemo(
    () => ({
      id: 'fullName',
      name: 'full_name',
      label: t('Full Name'),
      sortable: true,
      width: '200px',
      disabled: true,
      enableLink: true,
    }),
    [t]
  );

  const categoriesById = useMemo(() => {
    return fieldCategories?.reduce((acc, category) => {
      acc[category.id] = category;
      return acc;
    }, {});
  }, [fieldCategories]);

  const groupedFields = useGroupedFields(
    objectFields?.filter(
      // We don't want first_name or last_name for contacts because we inject the full_name field
      (f) => !isFirstNameField(f) && !isLastNameField(f)
    ),
    fieldCategories
  );

  const fieldOrders = useMemo(() => {
    return (objectFields || []).reduce((acc, field, index) => {
      return {
        ...acc,
        [field.id]: index,
      };
    }, {});
  }, [objectFields]);

  const fieldsById = useMemo(() => {
    const fieldMap =
      objectFields?.reduce((acc, field) => {
        acc[field.id] = field;
        return acc;
      }, {}) ?? {};

    if (contacts) {
      return {
        fullName: {
          id: 'fullName',
          displayName: t('Full Name'),
          name: 'full_name',
        },
        ...fieldMap,
      };
    }

    return fieldMap;
  }, [objectFields, t, contacts]);

  const fieldsWithCategories = useMemo(() => {
    const options = groupedFields
      ?.filter(({ options }) => options.length)
      .map((group) => ({
        id: categoriesById[group.id]?.id,
        isGroupedItem: true,
        label: group.label,
        order: categoriesById[group.id]?.order,
        items: group.options.reduce((acc, item) => {
          const field = fieldsById[item?.value];
          if (field?.id) {
            if (
              linkableFieldTypes.includes(field?.fieldType) &&
              field?.enableLink === undefined
            ) {
              return acc.concat({
                ...field,
                label: field.label || field.displayName,
                id: field.id,
                fieldId: field.id,
                icon: '',
                sortable: true,
                fieldType: field.fieldType,
                disabled: false,
                enableLink: true,
                width: '200px',
              });
            }
            return acc.concat({
              ...field,
              label: field.label || field.displayName,
              id: field.id,
              fieldId: field.id,
              icon: '',
              sortable: true,
              fieldType: field.fieldType,
              disabled: false,
              width: '200px',
            });
          }
          return acc;
        }, []),
      }))
      .flatMap((group) => {
        return [{ ...group }, ...group.items];
      });

    return options.sort((a, b) => {
      return fieldOrders[a.id] - fieldOrders[b.id];
    });
  }, [groupedFields, categoriesById, fieldsById, fieldOrders]);

  const handleResizeColumn = useCallback(
    (fieldId, width) => {
      selectedObject?.id &&
        onChange((prev) => ({
          ...prev,
          fields: {
            ...prev.fields,
            [selectedObject.id]: (prev.fields[selectedObject.id] || []).map(
              (field) => (field.id === fieldId ? { ...field, width } : field)
            ),
          },
        }));
    },
    [onChange, selectedObject?.id]
  );

  const handleChangeFilters = useCallback(
    (filters) => {
      const updatedFilters = {
        customFilters: undefined,
        inGroupFilters: undefined,
        notInGroupFilters: undefined,
      };

      if (isCustomFilter(filters?.details?.type)) {
        updatedFilters.customFilters = filters?.details?.filterConfig;
      } else if (isInGroupFilter(filters?.details?.type)) {
        updatedFilters.inGroupFilters = filters?.details?.groups?.map(
          (g) => g.id
        );
      } else if (isNotInGroupFilter(filters?.details?.type)) {
        updatedFilters.notInGroupFilters = filters?.details?.groups?.map(
          (g) => g.id
        );
      }
      onChange((prev) => {
        return {
          ...prev,
          filters: {
            ...prev.filters,
            [selectedObject?.id]: {
              ...updatedFilters,
            },
          },
        };
      });
    },
    [onChange, selectedObject]
  );

  const onHandleChangeReverseFields = useCallback(
    (fields) => {
      if (selectedObject?.id) {
        dirtyRef.current = true;
        onChange((prev) => ({
          ...prev,
          relationshipFields: {
            ...prev.relationshipFields,
            [selectedObject.id]: fields,
          },
        }));
      }
    },
    [onChange, selectedObject?.id, dirtyRef]
  );

  const mainObjectFieldsById = useMemo(() => {
    return mainObjectFields.reduce((acc, field) => {
      acc[field.id] = field;
      return acc;
    }, {});
  }, [mainObjectFields]);

  const relationshipFieldValues = useMemo(() => {
    return (
      relationshipFields?.[selectedObject?.id]?.map((relatedField) => {
        if (!mainObjectFieldsById[relatedField.value]) {
          return {
            ...relatedField,
            error: true,
          };
        }
        return relatedField;
      }) ?? []
    );
  }, [relationshipFields, mainObjectFieldsById, selectedObject?.id]);

  const orderedFields = useMemo(
    () =>
      selectedObject?.id
        ? getCustomObjectOrderedFields(fieldObject, objectFields, t)
        : [],
    [selectedObject, fieldObject, objectFields, t]
  );

  const objectFieldsMapped = useMemo(() => {
    if (fields?.[selectedObject?.id]?.length) {
      return fieldsWithCategories.filter(
        ({ id, isHidden }) =>
          !fields[selectedObject.id].some((field) => field.id === id) ||
          isHidden
      );
    }
    return fieldsWithCategories.filter(({ visible }) => !visible);
  }, [fields, fieldsWithCategories, selectedObject?.id]);

  const fieldsWithDisabledValues = useMemo(() => {
    if (selectedObject?.id) {
      const savedFields = fields?.[selectedObject.id]?.length
        ? fields[selectedObject.id]
        : orderedFields.filter(({ visible }) => visible);
      for (const field of savedFields) {
        const foundField = orderedFields.find(
          ({ fieldId, shouldBeDisabledInLeftColumn }) =>
            fieldId === field.fieldId && shouldBeDisabledInLeftColumn
        );
        if (foundField) {
          field.shouldBeDisabledInLeftColumn = true;
        }
      }
      return savedFields;
    }
    return [];
  }, [fields, orderedFields, selectedObject?.id]);

  const activeFields = useMemo(() => {
    let fullNameFound = false;

    const processedFields = fieldsWithDisabledValues
      .filter(({ id }) => {
        if (!fieldsById[id]) return false;

        // Additional check for 'full_name' items to manage duplicates
        const isFullName = fieldsById[id].name === 'full_name';
        if (isFullName) {
          if (fullNameFound) {
            return false;
          }
          fullNameFound = true;
        }
        return true;
      })
      .map((item) => ({
        ...item,
        disabled:
          item.disabled ||
          isNonHidebleColumn({ id: fieldsById[item.id].name }) ||
          isNonHidebleColumn({ id: fieldsById[item.id].id }),
      }));

    // Determine if adding fullNameItem is necessary
    const needsFullNameItem =
      contacts &&
      defaultFieldsRef.current?.length &&
      !defaultFieldsRef.current.find(({ name }) => name === 'full_name') &&
      !fullNameFound;

    if (needsFullNameItem) {
      return [fullNameItem, ...processedFields];
    }
    return processedFields;
  }, [fieldsWithDisabledValues, contacts, fieldsById, fullNameItem]);

  const filterObjectMapped = useMemo(() => {
    const activeFieldsLookup = activeFields.reduce(
      (acc, { id }) => ({ ...acc, [id]: true }),
      {}
    );

    return objectFieldsMapped.reduce((acc, field) => {
      if (!activeFieldsLookup[field.id]) {
        return acc.concat({
          ...field,
          disabled: field.isSuppressed || field.isHidden,
        });
      }
      return acc;
    }, []);
  }, [objectFieldsMapped, activeFields]);

  const filtersForSelectedObject = useMemo(() => {
    if (filters) {
      return filters[selectedObject?.id];
    }
    return undefined;
  }, [filters, selectedObject?.id]);

  const filterCount = useMemo(() => {
    return getDashletFilterCount(filtersForSelectedObject ?? {});
  }, [filtersForSelectedObject]);

  const filterState = useMemo(() => {
    if (filtersForSelectedObject?.customFilters) {
      return {
        details: {
          type: FILTER_TYPES.CUSTOM,
          groups: [],
          filterConfig: filtersForSelectedObject.customFilters,
        },
      };
    }
    if (filtersForSelectedObject?.inGroupFilters?.length > 0) {
      return {
        details: {
          type: FILTER_TYPES.IN_GROUP,
          groups: filtersForSelectedObject.inGroupFilters.map((id) => ({
            id,
          })),
        },
      };
    }
    if (filtersForSelectedObject?.notInGroupFilters?.length > 0) {
      return {
        details: {
          type: FILTER_TYPES.NOT_IN_GROUP,
          groups: filtersForSelectedObject.notInGroupFilters.map((id) => ({
            id,
          })),
        },
      };
    }
  }, [filtersForSelectedObject]);

  const linkFields = useMemo(() => {
    const disabledField = [activeFields.find(({ disabled }) => disabled)];

    const otherLinkSupportFields = objectFields.filter(
      ({ id, fieldType } = {}) =>
        activeFields.find((field) => field.id === id) &&
        linkableFieldTypes.includes(fieldType)
    );

    return [...disabledField, ...otherLinkSupportFields].reduce(
      (acc, field) => {
        return {
          ...acc,
          [field?.id]: false,
        };
      },
      {}
    );
  }, [objectFields, activeFields]);

  const onHandleChange = useCallback(
    (fields) => {
      selectedObject?.id &&
        fields.length &&
        onChange((prev) => {
          return {
            ...prev,
            fields: {
              ...prev.fields,
              [selectedObject.id]: fields,
            },
          };
        });
    },
    [onChange, selectedObject?.id]
  );

  const itemsOrdering = useMemo(
    () => fieldsWithCategories.map(({ id }) => id),
    [fieldsWithCategories]
  );

  const includeRelationshipFieldsOptions = useMemo(() => {
    return mainObjectFields
      .filter(({ isSuppressed }) => !isSuppressed)
      .filter(
        ({ relation }) =>
          relation?.relatedObject === selectedObject?.id ||
          relation?.relatedObject === selectedObject?.originalId
      )
      .map(({ relation, id, displayName }) => ({
        value: id,
        label: displayName,
        relation,
      }));
  }, [mainObjectFields, selectedObject?.id, selectedObject?.originalId]);

  const selectedId = useRef(selectedObject?.id);
  if (disabled) {
    selectedId.current = selectedObject?.id;
  }
  const showColumns =
    !disabled &&
    selectedObject &&
    (selectedId.current === selectedObject?.id ||
      selectedId.current === selectedObject?.originalId);
  // keep the selected value update with changes in the objects without forcing a re-render
  const valueFromObjects = useMemo(
    () =>
      (objects || []).find((obj) => obj.id === selectedObject?.id) ||
      selectedObject,
    [objects, selectedObject]
  );

  if (relationshipFieldValues.some(({ error }) => error)) {
    setTimeout(() => {
      setDisplayErrorTimeout(false);
      displayErrorRef.current = false;
    }, 3000);
  }

  const validationProps = useMemo(
    () =>
      relationshipFieldValues.some(({ error }) => error)
        ? {
            message:
              relationshipFieldValues.filter(({ error }) => error).length > 1
                ? t('These fields have been deleted')
                : t('The field has been deleted'),
            showMessage:
              relationshipFieldValues.some(({ error }) => error) &&
              displayErrorTimeout &&
              displayErrorRef.current,
            inModal: true,
          }
        : undefined,
    [relationshipFieldValues, displayErrorTimeout, t]
  );

  return (
    <Wrapper
      objectIsSelected={!!selectedObject}
      ref={wrapperRef}
      disabled={disabled}
      {...rest}
    >
      <KizenTypography type="subheader">
        {t('Object/Tab Column Display Settings')}
      </KizenTypography>
      <StyledInputControl margin>
        <StyledSelect
          label={t('Choose Tab to Edit Columns Displayed')}
          options={objects}
          value={valueFromObjects}
          placeholder={t('Choose Object/Tab')}
          onChange={setSelectedObject}
          isLoading={disabled}
          onMenuOpen={() => !selectedObject && scrollNext()}
        />
      </StyledInputControl>
      {disabled ? (
        <Loader loading={disabled} />
      ) : showColumns ? (
        <>
          <MultiSelect
            ref={relationshipFieldMultiSelectRef}
            label={t(
              'Include the Following Relationship Fields (Leave Blank for All)'
            )}
            placeholder={t('Find Relationship Field(s)')}
            labelInfo={t(
              'If left empty, all current and future relationships between these objects will be included.'
            )}
            options={includeRelationshipFieldsOptions}
            value={relationshipFieldValues}
            onChange={onHandleChangeReverseFields}
            validate={validationProps}
            onBlur={() => {
              displayErrorRef.current = false;
              setDisplayErrorTimeout(false);
            }}
            errorPlacement="bottom-start"
            menuInline
            margin
            inModal
          />
          <DndColumns
            data-qa="related-object-fields-builder"
            key={`${selectedObject?.id}-${CONTEXTS.fields}`}
            initialLeftColumnItems={filterObjectMapped}
            initialRightColumnItems={activeFields}
            tableHeight={tableHeight}
            previewLabel={t('Column Preview/Widths')}
            searchPlaceholder={t('Find Options')}
            leftHeaderText={t('Available Columns (Drag to Add)')}
            rightHeaderText={t('Active Table Columns')}
            renderRightEmpty={{
              onDropLabel: t('Place Here'),
              noItems: t(
                'No Columns Currently Selected (Drag and Drop to Add)'
              ),
            }}
            renderLeftEmpty={{
              onDropLabel: t('Drop to Remove'),
              noItems: t('No Options Found'),
            }}
            showCategoryOnSearch={false}
            onChange={onHandleChange}
            iconVisible={() => false}
            iconEditable={() => false}
            colorEditable={() => false}
            context={CONTEXTS.fieldsLayout}
            dirtyRef={dirtyRef}
            itemsOrdering={itemsOrdering}
            editable
            grouped
            skipSorting
            displaySwitchItems={linkFields}
            switchField="enableLink"
            switchLabel={t('Display Link?')}
            switchDefaultValue={true}
            headerPreviewComponent={
              <ColumnsHeaderPreview onResize={handleResizeColumn} />
            }
          />
          <FilterSummary
            filterCount={filterCount}
            top={20}
            gap={15}
            state={filterState}
            customObject={
              fieldObject?.fetchUrl === 'client' ? undefined : fieldObject
            }
            selectedObject={fieldObject}
            onChange={handleChangeFilters}
            mobile={isMobile}
            filterName={t('Object/Tab Filters')}
            modalHeader={t('Edit Object/Tab Filter(s)')}
            area={CONTEXTS.fieldsLayout}
            hideGroups
            onClear={() => setClearedFilters(true)}
            defaultCustomFilter={!clearedFilters}
          />
        </>
      ) : null}
    </Wrapper>
  );
};

export const validate = ({ fields = {} }) =>
  Object.keys(fields).length &&
  !Object.keys(fields).some((fieldId) =>
    fields[fieldId]?.find(
      (selectedField) => selectedField?.label?.trim() === ''
    )
  );
