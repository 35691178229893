import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import EmailService from 'services/EmailService';
import { namedToOption } from 'services/helpers';
import Select from 'components/Inputs/Select';
import MultiSelect from 'components/Inputs/MultiSelect';
import EmailPicker from 'components/Kizen/EmailPicker';

const ANY_OPTED_IN = 'any_opted_in';
const ON_SPECIFIC_LISTS = 'on_specific_lists';

const getEmailStatusOptions = (t) => [
  { label: t('Any Opted In'), value: ANY_OPTED_IN },
  { label: t('On Specific Lists'), value: ON_SPECIFIC_LISTS },
];

const Wrapper = styled.div`
  flex: 1;
  margin-top: ${gutters.spacing(3)}px;
  margin-bottom: ${gutters.spacing(3, 2)}px;
`;

const emptyArray = [];

const SendEmail = ({
  subscriptionListOptions,
  emailStatus,
  onChangeEmailStatus,
  subscriptionLists = null,
  onChangeSubscriptionLists,
  email = null,
  onChangeEmail,
  setEmail,
  clearEmail,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <Select
        label={t('Choose Contact Status')}
        margin
        menuInline
        options={getEmailStatusOptions(t)}
        value={emailStatus}
        onChange={onChangeEmailStatus}
      />
      {emailStatus === ON_SPECIFIC_LISTS && (
        <MultiSelect
          label={t('Choose Specific Subscription Lists (leave blank for any)')}
          placeholder={t('Find Subscription Lists')}
          margin
          menuInline
          options={subscriptionListOptions}
          value={subscriptionLists}
          onChange={onChangeSubscriptionLists}
        />
      )}
      <EmailPicker email={email} onChange={onChangeEmail} />
    </Wrapper>
  );
};

export default SendEmail;

const toValue = ({ value }) => value;

export function useSendEmailStep() {
  const [emailStatus, setEmailStatus] = useState(ANY_OPTED_IN);
  const [subscriptionLists, setSubscriptionLists] = useState([]);
  const [email, setEmail] = useState(null);
  const { value: subscriptionListOptions = emptyArray } = useAsync(async () => {
    const subscriptionList = await EmailService.getSubscriptionList();
    return subscriptionList.map(namedToOption);
  });

  const clearEmail = useCallback(() => {
    setEmail(null);
  }, [setEmail]);

  return {
    // These are props that can be passed into the SendEmail component
    subscriptionListOptions,
    emailStatus,
    onChangeEmailStatus: (opt) => setEmailStatus(toValue(opt)),
    subscriptionLists:
      emailStatus === ON_SPECIFIC_LISTS ? subscriptionLists : null,
    onChangeSubscriptionLists: (opts) =>
      setSubscriptionLists(opts.map(toValue)),
    email,
    onChangeEmail: setEmail,
    clearEmail,
  };
}

export function sendEmailStepIsValid({ email }) {
  return !!email;
}
