import { DEFAULT_ITEM_IDS } from 'pages/Account/pages/Toolbar/constants';
import { DEFAULT_ROUTES, getCustomObjectPath } from './paths';

export const getRoute = (item) => {
  if (item.isCustomObject) {
    return getCustomObjectPath(item.id);
  }

  if (item.wasDynamic && item.url) {
    let url = item.url;
    let isExternal = url.startsWith('http://') || url.startsWith('https://');

    if (isExternal) {
      try {
        const parsed = new URL(url);
        const currentOrigin = window.location.origin;
        if (currentOrigin === parsed.origin) {
          url = url.replace(currentOrigin, '');
          isExternal = false;
        }
      } catch (ex) {
        // URL was malformed, treat it as-is
      }
    }

    return {
      path: url,
      external: isExternal,
      exact: true,
      target: isExternal ? '__blank' : null,
      rel: isExternal ? 'noopener noreferrer' : null,
    };
  }

  // Contacts is a special case, as it has a nested route structure for charts
  // but the route is marked as exact: true, so we need to override that here
  if (item.id === DEFAULT_ITEM_IDS.CONTACTS) {
    return {
      ...DEFAULT_ROUTES[item.id],
      exact: false,
    };
  }

  return DEFAULT_ROUTES[item.id] ?? { path: '/', exact: true };
};

export const getLabel = (item, translationKeys) => {
  const translated = translationKeys[item.id];

  if (item.dynamicallyPopulated) {
    return item.displayName || translated || item.label;
  }

  return item.customLabel || item.displayName || translated || item.label;
};

export const traverseNParents = (node, count) => {
  if (count === 0) {
    return node;
  }

  return traverseNParents(node?.parentNode, count - 1);
};

export const getQAForItem = (item, route) => {
  return {
    'data-qa-id': item.id,
    'data-qa-path': route.path,
    'data-qa-color': item.color,
    'data-qa-child-count': item.children?.length ?? 0,
  };
};

export const getSplitPathName = (pathname) => {
  // Handle the special case where we're viewing the charts for a custom object and
  // strip the /charts/:id? off the end of the path
  if (
    pathname.includes(DEFAULT_ROUTES[DEFAULT_ITEM_IDS.CUSTOM_OBJECTS].path) ||
    pathname.includes(DEFAULT_ROUTES[DEFAULT_ITEM_IDS.CONTACTS].path)
  ) {
    return pathname.split('/charts')[0];
  }

  return pathname;
};
