import React, { ReactNode, createContext, useContext, useState } from 'react';
import {
  buildPermissionsContext,
  buildSingleObjectSectionContext,
} from '../src/state';
import {
  MetaConfig,
  PermissionGroup,
  PermissionsContext as TPermissionsContext,
  PreReleaseFeaturesConfig,
  TFunction,
} from '../src/types';
import { Field } from '../src/utils';

type PermissionsContextProps = {
  permissionsConfig: MetaConfig;
  preReleaseFeatureConfig: PreReleaseFeaturesConfig;
  contactObjectId: TPermissionsContext['contact_object_id'];
  contactFields: Field[];
  customObjects: any;
  permissionGroup?: PermissionGroup;
  translationFunc: TFunction;
  children?: ReactNode;
};

type SingleObjectPermissionsContextProps = {
  permissionsConfig: MetaConfig;
  customObjects: any[];
  contactObjectId: string;
  contactFields: Field[];
  translationFunc: TFunction;
  children?: ReactNode;
};

const permissionsCtx = createContext<TPermissionsContext>({
  meta_config: {
    sections: [],
    contacts: [],
    custom_objects: [],
    default_contact_fields: {} as any,
    default_custom_object_fields: {} as any,
  },
  name: null as any,
  contact_section: {} as any,
  contact_object_id: '',
  co_sections: [],
  sources: new Map(),
  rules: new Map(),
  permissions: new Map(),
  fields_loaded: new Map(),
  updates: null as any,
  payload: null as any,
} as any);

export const usePermissionsContext = () => {
  return useContext(permissionsCtx);
};

export const SingleObjectPermissionsContext: React.FC<
  SingleObjectPermissionsContextProps
> = ({
  customObjects,
  permissionsConfig,
  children,
  contactObjectId,
  contactFields,
  translationFunc,
}) => {
  const [ctx] = useState(() =>
    buildSingleObjectSectionContext(
      permissionsConfig,
      customObjects,
      contactObjectId,
      contactFields,
      translationFunc
    )
  );

  return (
    <permissionsCtx.Provider value={ctx as any}>
      {children}
    </permissionsCtx.Provider>
  );
};

export const PermissionsContext: React.FC<PermissionsContextProps> = ({
  permissionsConfig,
  preReleaseFeatureConfig,
  contactObjectId,
  contactFields,
  customObjects,
  permissionGroup,
  translationFunc,
  children,
}) => {
  const [ctx] = useState(() =>
    buildPermissionsContext({
      meta_config: permissionsConfig,
      contact_object_id: contactObjectId,
      preReleaseFeaturesConfig: preReleaseFeatureConfig,
      customObjects,
      contactFields,
      permissionGroup,
      t: translationFunc,
    })
  );

  return (
    <permissionsCtx.Provider value={ctx}>{children}</permissionsCtx.Provider>
  );
};
