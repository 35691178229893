import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  colorsPrimary,
  colorsSecondary,
  grayScale,
  shadowLight,
} from 'app/colors';
import { gutters, scrollbarCss } from 'app/spacing';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${gutters.spacing(2)}px 0;
  height: calc(100% + ${gutters.spacing(5)}px);
  margin-left: -${gutters.spacing(3)}px;
  margin-right: -${gutters.spacing(3)}px;
  user-select: none;
`;

export const Header = styled.div`
  display: flex;
  color: ${grayScale.dark};
  column-gap: ${({ mobile }) => (mobile ? 10 : 20)}px;
  padding: 4px 0 ${gutters.spacing(3, 2)}px ${gutters.spacing(3)}px;
  ${({ scrolled }) =>
    scrolled
      ? css`
          border-bottom: 1px solid ${grayScale.mediumLight};
          ${shadowLight}
        `
      : ''}
`;

export const Cell = styled.div`
  display: flex;
  flex-grow: ${({ shrink }) => (shrink ? 0 : 1)};
  max-width: ${({ widthPercent, widthPixels }) =>
    widthPercent
      ? `${widthPercent}%`
      : widthPixels
        ? `${widthPixels}px`
        : '100%'};
  min-width: ${({ widthPercent, widthPixels }) =>
    widthPercent ? `${widthPercent}%` : widthPixels ? `${widthPixels}px` : '0'};
  justify-content: ${({ textAlign }) => textAlign ?? 'left'};
  align-items: center;
  ${({ right }) =>
    typeof right === 'number'
      ? css`
          padding-right: ${right}px;
        `
      : ''}

  // This is a hack for mobile safari so the text looks correct
  ${({ textAlign }) =>
    textAlign === 'right'
      ? css`
          & p {
            margin-left: auto;
          }
        `
      : ''}

  & p.open {
    color: ${colorsPrimary.blue.dark};
  }

  & p.won {
    color: ${colorsPrimary.green.dark};
  }

  & p.lost,
  & p.disqualified {
    color: ${colorsSecondary.red.light};
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow-y: auto;
  ${scrollbarCss}
`;
