import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import ToolbarService from 'services/ToolbarService';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import { TOOLBAR_TEMPLATES } from 'queries/query-keys';
import { useModalControl } from 'hooks/useModalControl';
import { useTranslation } from 'react-i18next';
import { toastVariant, useToast } from 'components/ToastProvider';

const useCreateNewTemplate = (items = [], callbacks) => {
  const qc = useQueryClient();
  const [errors, setErrors] = useState();
  const { t } = useTranslation();
  const [showToast] = useToast();

  const [isOpen, { showModal, hideModal }] = useModalControl();

  const createTemplateMutation = useMutation(
    (result) =>
      ToolbarService.createTemplate(result.name, result.config, {
        ...buildSharingSettingsForAPI(result.permissions).sharing_settings,
        private: result.isPrivate,
      }),
    {
      onMutate: () => {
        setErrors();
      },
      onSuccess: ({ data }) => {
        qc.invalidateQueries([TOOLBAR_TEMPLATES.LIST]);
        callbacks?.onSuccess?.(data);
        showToast({
          message: t(
            "'{{name}}' toolbar template has been saved successfully.",
            data
          ),
          variant: toastVariant.SUCCESS,
        });
      },
    }
  );

  const handleHide = useCallback(() => {
    hideModal();
    setErrors();
    callbacks?.onHide();
  }, [hideModal, callbacks]);

  const updateTemplateMutation = useMutation(
    (result) =>
      ToolbarService.updateTemplate(result.id, result.name, result.config, {
        ...buildSharingSettingsForAPI(result.permissions).sharing_settings,
        private: result.isPrivate,
      }),
    {
      onMutate: () => {
        setErrors();
      },
      onSuccess: ({ data }) => {
        qc.invalidateQueries([TOOLBAR_TEMPLATES.LIST]);
        showToast({
          message: t(
            "'{{name}}' toolbar template has been saved successfully.",
            data
          ),
          variant: toastVariant.SUCCESS,
        });
      },
      onError: (payload) => {
        setErrors(
          payload?.response?.data?.[0] && {
            toast: payload?.response?.data?.[0],
          }
        );
      },
    }
  );

  const saveNewTemplate = useCallback(
    ({ name, permissions, isPrivate }, isEditing, editId, config) => {
      if (!isEditing) {
        return createTemplateMutation.mutateAsync({
          name,
          config: config ?? items,
          permissions,
          isPrivate,
        });
      } else {
        return updateTemplateMutation.mutateAsync({
          id: editId,
          name,
          permissions,
          isPrivate,
        });
      }
    },
    [items, createTemplateMutation, updateTemplateMutation]
  );

  const handleConfirm = useCallback(
    async (...args) => {
      try {
        await saveNewTemplate(...args);
        handleHide();
      } catch (e) {
        const fields = e?.response?.data;
        setErrors(fields);
      }
    },
    [saveNewTemplate, handleHide]
  );

  return {
    modalProps: {
      isOpen,
      show: isOpen,
      onHide: handleHide,
      onConfirm: handleConfirm,
      showModal,
      errors,
    },
    handleClickSaveTemplate: showModal,
    mutate: createTemplateMutation.mutate,
  };
};

export default useCreateNewTemplate;
