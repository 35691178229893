import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import useBoolean from 'react-use/lib/useBoolean';
import ErrorBoundary from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import { isSentryEnvironment, isSentryAlertEnvironment } from 'sentry';

export default function ErrorBoundaryResettable({
  FallbackComponent,
  initSentry = false,
  ...others
}) {
  const [key, toggleKey] = useBoolean();
  const updateKey = useCallback(() => toggleKey(), [toggleKey]); // Always toggles
  const FallbackComponentWithReset = useCallback(
    (props) => <FallbackComponent onReset={updateKey} {...props} />,
    [FallbackComponent, updateKey]
  );

  const Component =
    initSentry && isSentryEnvironment(import.meta.env.VITE_SENTRY_ENV)
      ? Sentry.ErrorBoundary
      : ErrorBoundary;

  return (
    <Component
      fallback={FallbackComponentWithReset}
      showDialog={isSentryAlertEnvironment(import.meta.env.VITE_SENTRY_ENV)}
      key={key}
      FallbackComponent={FallbackComponentWithReset}
      {...others}
    />
  );
}

ErrorBoundaryResettable.propTypes = {
  FallbackComponent: PropTypes.elementType.isRequired,
};
