import { ChangeEvent, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSizing } from '__components/Layout/PageContentWidth';
import { StyledConnectionButtonsRow } from './styles';
import { SmartConnectorContext } from '../../context';
import { FileUploader } from './FileUploader';
import { useAsync } from 'react-use';
import CustomObjectsService from 'services/CustomObjectsService';
import FieldService from 'services/FieldService';
import { KizenTypography } from '__app/typography';
import Switch from '__components/Kizen/Switch';
import { SQLScripts } from './SQLScripts';
import Button from '__components/Button';
import { StyledCard, StyledLoader, StyledStepHeader } from '../styles';
import { useScripts } from '../../hooks/useScripts';
import { SMART_CONNECTOR_STEPS } from '__pages/SmartConnectors/constants';
import { DownloadTemplateButton } from './DownloadTemplateButton';
import { isContact } from 'utility/fieldHelpers';
import { isEmailStatusField } from '__checks/fields';
import { isFilesField } from '__checks/fields';
import { FieldLike } from '__pages/SmartConnectors/types';

export const ConnectionStep = () => {
  const { t } = useTranslation();
  const { isNew, smartConnector, stepData, setStepData, setIsValid, loading } =
    useContext(SmartConnectorContext);

  const {
    value: { fields = [], customObject = {} } = {},
    loading: loadingObject,
  } = useAsync(async () => {
    if (!smartConnector.custom_object) return {};
    const customObject = await FieldService.getModel({
      id: smartConnector.custom_object,
    });

    const fields = await CustomObjectsService.getCustomModelFields(
      smartConnector.custom_object
    );

    return {
      fields: fields.filter(
        (field: FieldLike) => !isEmailStatusField(field) && !isFilesField(field)
      ),
      customObject: {
        ...customObject,
        objectType: isContact(customObject)
          ? 'contact'
          : customObject.objectType,
      },
    };
  }, [smartConnector.custom_object]);

  const isStepValid =
    !isNew ||
    Boolean(
      stepData.source_file && (!stepData.sql_enabled || !!stepData.live_script)
    );

  useEffect(() => {
    setIsValid(isStepValid);
  }, [setIsValid, isStepValid]);

  const { setForceReset, handleChangeSourceFile, ...useScriptsProps } =
    useScripts();

  const handleDownloadSourceFile = () => {
    if (stepData.source_file?.url) {
      window.open(stepData.source_file.url, '_blank');
    }
  };

  const handleToggleSQLProcessing = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setStepData((prev) => ({ ...prev, sql_enabled: e.target.checked }));
  };

  return (
    <PageSizing>
      <StyledCard data-qa={`${SMART_CONNECTOR_STEPS.connection}-step`}>
        <StyledStepHeader type="subheader">
          {t('Configure Connection')}
        </StyledStepHeader>
        <StyledLoader loading={loadingObject || !customObject.id || loading}>
          <KizenTypography>{t('Upload Reference Spreadsheet')}</KizenTypography>
          <StyledConnectionButtonsRow>
            <DownloadTemplateButton
              fields={fields}
              customObject={customObject}
            />
            {stepData.source_file ? (
              <Button
                variant="text"
                color="blue"
                onClick={handleDownloadSourceFile}
              >
                {t('Download Current Reference File')}
              </Button>
            ) : null}
          </StyledConnectionButtonsRow>
          <FileUploader onChange={handleChangeSourceFile} />
          <Switch
            onChange={handleToggleSQLProcessing}
            checked={stepData.sql_enabled || false}
            removeMargin
            label={t('Enable SQL Processing')}
            tooltip={
              stepData.sql_required
                ? t('The reference file provided requires SQL processing.')
                : undefined
            }
            textPlacement="regular-top"
            disabled={stepData.sql_required}
          />
          {stepData.sql_enabled ? (
            <SQLScripts setForceReset={setForceReset} {...useScriptsProps} />
          ) : null}
        </StyledLoader>
      </StyledCard>
    </PageSizing>
  );
};
