import { useCallback, useRef, useState } from 'react';
import useSyncScroll from 'react-use-sync-scroll';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { useScrollThreshold } from 'components/Kizen/LazyLoad/helpers';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { CONTEXTS } from 'components/Wizards/utils';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import {
  LeftGradient,
  PreviewItem,
  RightGradient,
  StyledIcon,
  TitlesWrapper,
  WithScrollWrapper,
  Content,
  StyledLeftIcon,
  StyledLeftI,
  StyledResizableTitle,
} from './styles';
import { ResizerLayout } from 'pages/Common/components/BigTableCells';
import { DraggableCore } from 'react-draggable';
import Icon from 'components/Kizen/Icon';
import { MIN_WIDTH, COLUMN_PADDING } from './constants';
import { throttle } from 'lodash';

const PreviewIcon = ({ icon }) => {
  return icon?.className ? (
    <StyledLeftI
      className={`${icon.className} fa-fw`}
      data-qa-icon-name={icon.value}
    />
  ) : (
    <StyledLeftIcon icon={icon?.value || 'bars-light'} />
  );
};

const PreviewTitle = ({ column, isObjectContext }) => (
  <PreviewItem key={column.id} isObjectPreview={isObjectContext}>
    {isObjectContext ? <PreviewIcon icon={column.icon} /> : null}
    <TextEllipsisWithTooltip>{column.label}</TextEllipsisWithTooltip>
    {(!isObjectContext || column.sortable) && <StyledIcon icon="no-sort" />}
  </PreviewItem>
);

const debouncedResize = throttle((x, setInnerWidth) => {
  const nextWidth = Math.max(MIN_WIDTH, x - COLUMN_PADDING / 2);
  setInnerWidth(`${nextWidth}px`);
}, 40);

const WidthAdjustmentTitle = ({
  column,
  isObjectContext,
  onResize,
  setResizing,
}) => {
  const [innerWidth, setInnerWidth] = useState(column.width);
  const [resizing, setInnerResizing] = useState(false);

  const handleStart = useCallback(() => {
    setResizing(true);
    setInnerResizing(true);
  }, [setResizing]);

  const handleResize = useCallback((ev, { x }) => {
    debouncedResize(x, setInnerWidth);
  }, []);

  const handleUpdateColumnWidth = useCallback(() => {
    setResizing(false);
    setInnerResizing(false);
    onResize(column.id, innerWidth);
  }, [column.id, onResize, innerWidth, setResizing]);

  return (
    <StyledResizableTitle width={innerWidth} resizing={resizing}>
      <PreviewTitle
        isObjectPreview={isObjectContext}
        column={column}
        width={innerWidth}
      />
      <ResizerLayout className="ResizerLayout" padding={COLUMN_PADDING}>
        <DraggableCore
          onDrag={handleResize}
          onStart={handleStart}
          onStop={handleUpdateColumnWidth}
        >
          <Icon className="Resizer" icon="column-resize" size="intrinsic" />
        </DraggableCore>
      </ResizerLayout>
    </StyledResizableTitle>
  );
};

const ContentComponent = ({ columns, isObjectContext, onResize }) => {
  const [resizing, setResizing] = useState(false);
  return (
    <Content
      className="ContentForSyncWidth"
      isObjectPreview={isObjectContext}
      hideColumnGap={!!onResize}
      resizing={resizing}
    >
      {columns?.map((column) =>
        onResize ? (
          <WidthAdjustmentTitle
            key={column.id}
            isObjectPreview={isObjectContext}
            column={column}
            onResize={onResize}
            setResizing={setResizing}
          />
        ) : (
          <PreviewTitle
            key={column.id}
            isObjectPreview={isObjectContext}
            column={column}
          />
        )
      )}
    </Content>
  );
};

export const TitlesRow = ({ columns, context = CONTEXTS.fields, onResize }) => {
  const isObjectContext = context === CONTEXTS.objects;
  const scrollbarContentWrapperRef = useRef(null);
  const [wrapperRefFn, wrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncWidth',
    'width'
  );
  useSyncScroll(useRef([wrapperRef, scrollbarContentWrapperRef]), {
    horizontal: true,
  });

  const [, fadeRight, , fadeLeft] = useEndsOfScroll(wrapperRef, [columns]);
  useScrollThreshold(wrapperRef, [columns], () => {});

  return (
    <WithScrollWrapper>
      <TitlesWrapper ref={wrapperRefFn} data-qa-id="column-titles">
        <LeftGradient position="left" fadeIn={!fadeLeft} />
        <ContentComponent
          isObjectPreview={isObjectContext}
          onResize={onResize}
          columns={columns}
        />
        <RightGradient position="right" fadeIn={!fadeRight} />
      </TitlesWrapper>
      <ScrollBarDetached
        ref={scrollbarContentWrapperRef}
        direction="horizontal"
        scrollClassName="ContentForSyncWidth"
      />
    </WithScrollWrapper>
  );
};
