import { borderTight, shadowLight } from 'app/colors';
import Overlay from 'react-bootstrap2/Overlay';
import styled from '@emotion/styled';
import PhoneNumber from 'components/Inputs/TextInput/presets/PhoneNumber';
import IconButton from 'components/Kizen/IconButton';
import { colorsButton, grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import { TextInputInlineWrapper } from 'components/Inputs/inline/TextInput/styles';
import { useCallback, useMemo, useRef, useState } from 'react';
import { popperModifiers } from 'components/helpers';
import { basePopperConfig } from 'components/Inputs/inline/helpers';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const StyledPhoneNumber = styled(PhoneNumber)`
  overflow: visible !important;
  align-items: center !important;
  > :nth-child(1) {
    min-width: 50px;
    // country calling code
  }
  > :nth-child(2) {
    max-width: 100px;
    input {
      padding-right: 0;
    }
    // phone number
  }
  > :nth-child(3) {
    padding-right: 0;
    input {
      padding-right: 0;
    }
    // extension
  }
`;

const StyledIconButton = styled(IconButton)`
  margin: 5px 0 0 0 !important;
`;

const TextInputInlineWrapperStyled = styled(TextInputInlineWrapper)`
  box-sizing: border-box;
  display: flex !important;
  align-items: center !important;
  background: ${grayScale.white} !important;
  column-gap: 15px !important;
  padding: inherit;
  margin: inherit;
  ${borderTight}
  ${shadowLight}
`;
const ContainerOverlay = ({
  editing,
  submitting,
  targetRef,
  onHide,
  isCountryCodeMenuOpen,
  children,
}) => {
  return (
    <Overlay
      placement="bottom-start"
      show={editing && !submitting}
      transition={false}
      target={targetRef.current}
      rootClose={!isCountryCodeMenuOpen}
      ref={targetRef}
      onHide={onHide}
      popperConfig={{
        modifiers: [
          ...basePopperConfig.modifiers,
          popperModifiers.offSet(0, -20), // align with long text input
          popperModifiers.addStyles({
            padding: '2px 12px 10px',
            zIndex: 2,
          }),
        ],
      }}
    >
      {children}
    </Overlay>
  );
};

const PhoneNumberWithOverlay = ({
  field,
  object,
  editing,
  submitting,
  targetRef,
  ref,
  showErrorMessage,
  error,
  stagedValue,
  inputRef,
  handleChange,
  enableExtension,
  defaultCountry,
  qaFieldType,
  qaFieldId,
  t,
  submit,
  onFocus,
  onBlur,
  ...others
}) => {
  const fieldId = useMemo(() => `${field?.id}-${object?.id}`, [field, object]);
  const { getKeyListenersProps } = useKeyBoardContext();
  const savedRef = useRef(false);
  const [isCountryCodeMenuOpen, setIsCountryCodeMenuOpen] = useState(false);
  const onSubmit = useCallback(
    async (e, data) => {
      savedRef.current = true;
      const error = await submit({ clickAway: false }, data);
      if (error) {
        savedRef.current = false;
      }
      if (!error) {
        onBlur?.(e);
      }
      return error;
    },
    [submit, onBlur]
  );

  const onOverlayHide = useCallback(() => {
    if (!submitting) {
      savedRef.current = !submit({ clickAway: true });
    }
  }, [submit, submitting]);

  return (
    <ContainerOverlay
      editing={editing}
      submitting={submitting}
      targetRef={targetRef}
      isCountryCodeMenuOpen={isCountryCodeMenuOpen}
      onHide={() => !savedRef.current && onOverlayHide()}
    >
      <TextInputInlineWrapperStyled
        ref={ref}
        button={true}
        data-qa="inline-phone-wrapper"
        {...others}
      >
        <StyledPhoneNumber
          fieldId={fieldId}
          shouldUseImperativeHandle
          validate={{ showMessage: showErrorMessage, message: error }}
          className="InlineView"
          readOnly={submitting}
          ref={inputRef}
          value={stagedValue || stagedValue === 0 ? stagedValue : ''}
          onChange={handleChange}
          enableExtension={enableExtension}
          variant="underline"
          defaultCountry={defaultCountry}
          data-field-type={qaFieldType}
          data-field-id={qaFieldId}
          setIsCountryCodeMenuOpen={setIsCountryCodeMenuOpen}
          onSubmit={onSubmit}
          onFocus={onFocus}
          autoFocus
          inLine
          submit={submit}
        />
        <StyledIconButton
          sizing="dense"
          title={t('Save')}
          disabled={submitting}
          color={
            submitting ? colorsButton.iconGray.default : colorsButton.iconGray
          }
          onClick={async () => {
            savedRef.current = true;
            const isError = await submit();
            if (isError) {
              savedRef.current = false;
            }
            if (!isError && getKeyListenersProps) {
              getKeyListenersProps?.(fieldId)?.onBlur(null, true);
            }
          }}
        >
          <Icon icon="check" />
        </StyledIconButton>
      </TextInputInlineWrapperStyled>
    </ContainerOverlay>
  );
};

export default PhoneNumberWithOverlay;
