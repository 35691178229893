import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { entityMap } from '../../types';
import { useTranslation } from 'react-i18next';
import Section from '../../../shared/components/section';
import { StyledWizardField } from '../../../shared/styles';
import KizenTypography from 'app/kizentypo';
import WizardStateContext from '../../../shared/context/wizardstate';
import { implicitSectionPropTypes } from 'components/Wizards/shared/types';
import { gutters } from 'app/spacing';
import PipelineArea from './areas/Pipeline';
import ActivitiesArea from './areas/Activities';
import AreaSelector from './components/AreaSelector';
import EmailsArea from './areas/Emails';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import MarketingArea from './areas/Marketing';

const AreaWizardSection = ({
  index,
  active,
  done,
  last,
  mobile,
  disabled,
  setModalSize,
  ...rest
}) => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const previewFlagEnabled = usePreReleaseFeatures();
  const { area } = state;

  const { t } = useTranslation();

  const title = rest.title || t('Dashlet Type');

  const entities = useMemo(() => {
    return Object.keys(entityMap)
      .map((entityName) => {
        return {
          icon: entityMap[entityName].icon,
          label: entityMap[entityName].label(t),
          value: entityMap[entityName].value,
          size: entityMap[entityName].size ?? 'intrinsic',
          order: entityMap[entityName].order ?? -1,
          enabled: previewFlagEnabled
            ? true
            : entityMap[entityName].enabled ?? false,
        };
      })
      .filter((e) => e.enabled)
      .sort((a, b) => a.order - b.order);
  }, [t, previewFlagEnabled]);

  return (
    <>
      <Section
        index={index}
        title={title}
        active={active}
        done={done}
        last={last}
      >
        <StyledWizardField top={gutters.spacing(2, 2)}>
          <KizenTypography type="subheader">{t('Choose Area')}</KizenTypography>
          <StyledWizardField top={gutters.spacing(1, 1)}>
            <AreaSelector
              options={entities}
              onChange={(v) => setState('area', v)}
              value={area}
              mobile={mobile}
              disabled={disabled}
            />
          </StyledWizardField>
        </StyledWizardField>
        {/* These "Area" components control their own rendering and will only be visible when appropriate */}
        <PipelineArea active={active || done} disabled={disabled} />
        <ActivitiesArea disabled={disabled} setModalSize={setModalSize} />
        <EmailsArea disabled={disabled} />
        <MarketingArea disabled={disabled} />
      </Section>
    </>
  );
};

AreaWizardSection.propTypes = {
  ...implicitSectionPropTypes,
  title: PropTypes.string,
};

export default AreaWizardSection;
