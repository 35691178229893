import { Button } from '@kizen/kds/Button';
import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo } from 'react';
import KizenTypography from 'app/kizentypo';
import {
  StyledWizardField,
  StyledWizardParagraph,
  MetaFilterSummaryWrapper,
} from 'components/Wizards/shared/styles';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import useModal from 'components/Modals/useModal';
import FiltersModal from './Modal';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import useFilterCustomObject from 'components/Wizards/MetaFilters/hooks/useFilterCustomObject';
import { Typography } from '@kizen/kds/Typography';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterCountWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
`;

const FilterSummary = ({
  filterCount,
  top,
  gap,
  customObject,
  onChange,
  state,
  mobile,
  area,
  selectedObject,
  onChangeSelectedObject,
  disabled,
  disabledMessage,
  charts,
  filterName,
  modalHeader,
  hideGroups,
  onClear,
  defaultCustomFilter,
}) => {
  const { t } = useTranslation();
  const { state: wizardState } = useContext(WizardStateContext.Context);

  const defaultObject = wizardState?.selectedFilterObject;
  const { objectDetailsLoading, reportType } = wizardState;

  const [modalProps, , modal] = useModal();

  const handleChange = useCallback(
    (...args) => {
      onChange?.(...args);
      modal.hide();
    },
    [onChange, modal]
  );

  const clearFilters = useCallback(() => handleChange(), [handleChange]);

  const {
    object: rawSelectedObject,
    isForCustomObject,
    clientObject,
    customObjectOptionsLoading,
  } = useFilterCustomObject(selectedObject, defaultObject, modal.showing);

  // Should always return an object, whether custom object or client
  const selectedObjectValue = useMemo(() => {
    if (charts) {
      return customObject;
    }

    return rawSelectedObject;
  }, [charts, rawSelectedObject, customObject]);

  // Should return only custom objects - if the current object is clients, return undefined
  const customObjectValue = useMemo(() => {
    if (charts) {
      const isClient = clientObject?.id === customObject?.id;
      return isClient ? undefined : customObject;
    }

    return customObject;
  }, [charts, clientObject, customObject]);

  return (
    <>
      <StyledWizardField top={top} gap={gap}>
        <HeaderWrapper>
          <KizenTypography type="subheader">{filterName}</KizenTypography>
        </HeaderWrapper>
        {disabled ? (
          <StyledWizardParagraph
            top={6}
            data-qa="edit-filter-count"
            disabled={disabled}
          >
            {disabledMessage}
          </StyledWizardParagraph>
        ) : (
          <MetaFilterSummaryWrapper>
            <FilterCountWrapper>
              <Typography size="md" qa={{ '': 'edit-filter-count' }}>
                {filterCount}{' '}
                {filterCount === 1
                  ? t('Filter Selected')
                  : t('Filters Selected')}
              </Typography>{' '}
              {filterCount > 0 && !mobile ? (
                <Button
                  variant="text"
                  color="warning"
                  size="xs"
                  onClick={clearFilters}
                >
                  {t('CLEAR FILTERS')}
                </Button>
              ) : null}
            </FilterCountWrapper>
            {!mobile ? (
              <StyledWizardField top={gap ? 0 : 7}>
                <Button
                  variant="text"
                  size="small"
                  color="secondary"
                  onClick={modal.show}
                  qa={{
                    action: 'edit-filters',
                  }}
                >
                  {t('EDIT FILTERS')}
                </Button>
              </StyledWizardField>
            ) : null}
          </MetaFilterSummaryWrapper>
        )}
      </StyledWizardField>
      <FiltersModal
        {...modalProps}
        modalHeader={modalHeader}
        customObject={customObjectValue}
        onConfirm={handleChange}
        state={state}
        loading={objectDetailsLoading || customObjectOptionsLoading}
        hideWarnings={{
          isMeWarning: true,
          timezoneWarning: Boolean(charts),
        }}
        hideGroups={hideGroups}
        area={area}
        reportType={reportType?.value}
        onChangeSelectedObject={onChangeSelectedObject}
        selectedObject={selectedObjectValue}
        isForCustomObject={isForCustomObject}
        charts={charts}
        data-qa="filters-modal"
        onClear={onClear}
        defaultCustomFilter={defaultCustomFilter}
      />
    </>
  );
};

FilterSummary.propTypes = {
  filterCount: PropTypes.number,
  top: PropTypes.number,
  customObject: PropTypes.object,
  onChange: PropTypes.func,
  state: PropTypes.object,
  mobile: PropTypes.bool,
  area: PropTypes.string,
};

export default FilterSummary;
