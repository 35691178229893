import { settingsPagePaths, settingsUrlBase } from 'pages/Settings/paths';

export const BUSINESS_FIELDS = {
  name: {
    required: true,
  },
  reply_to_email: {
    required: true,
  },
  phone: { required: true },
  street_address: { required: true },
  city: { required: true },
  country: { required: true },
  state: { required: true },
  postal_code: { required: true },
  subdomain: { required: true },
  timezone: { required: true },
  forwarding_phone_number: { required: false },
  type: { required: false },
  logo: { required: false },
  primary_color: { required: false },
  secondary_color: { required: false },
};

export const REQUIRED_FIELDS = Object.entries(BUSINESS_FIELDS)
  .filter(([, { required }]) => required)
  .map(([key]) => key);

export const FORM_VALUES = Object.keys(BUSINESS_FIELDS).reduce(
  (collect, el) => ({ ...collect, [el]: '' }),
  {}
);

export const VALID_STATE_TYPES = ['State', 'Province'];

export const BASE_BUSINESS_SETTINGS_PATH = `${settingsUrlBase}/${settingsPagePaths.business}`;

export const BUSINESS_CALENDAR_PATH = `${BASE_BUSINESS_SETTINGS_PATH}/calendar`;

export const BUSINESS_EXPORT_IMPORT_PATH = `${BASE_BUSINESS_SETTINGS_PATH}/export-import`;

export const NAV_HEIGHT = 148;
