import { ChangeEvent, useContext, useMemo } from 'react';
import {
  FieldLike,
  GenericOption,
  MappingRule,
} from '__pages/SmartConnectors/types';
import { PrimaryObjectUploadContext } from '../../context';
import FieldService from '__services/FieldService';
import { getConflictResolutionByFieldType, getFieldTypeMeta } from './helpers';
import { useMappingRulesErrors } from './useMappingRuleErrors';
import { isStageField } from '__checks/fields';
import { getVariablesGroupedOptions } from '__pages/SmartConnectors/SmartConnector/helpers';
import { useTranslation } from 'react-i18next';

export type OnChangeMappingRuleHandler = ({
  field,
  prop,
  value,
}: {
  field: MappingRule['field'];
  prop: keyof MappingRule;
  value: MappingRule[keyof MappingRule];
}) => MappingRule[keyof MappingRule];

export type OnDeleteMappingRuleHandler = (
  field: MappingRule['field'],
  index: number
) => void;

type MappingSettingsProps = {
  rule: MappingRule;
  field?: FieldLike;
  onChange: OnChangeMappingRuleHandler;
  onDelete?: OnDeleteMappingRuleHandler;
  index: number;
};

export const useMappingSettings = ({
  rule,
  field,
  onChange,
  onDelete,
  index,
}: MappingSettingsProps) => {
  const { t } = useTranslation();
  const { metaData, variablesById, allVariables } = useContext(
    PrimaryObjectUploadContext
  );

  const { hasError, ruleError, errorKey, validate, clearError } =
    useMappingRulesErrors(index);

  const onDeleteRule = () => {
    onDelete?.(rule.field, index);
  };

  const isMulty = !!field && FieldService.getAllowMultiple(field);

  const variableOptions = useMemo(() => {
    if (!field) {
      return [];
    }

    const filteredVariables = allVariables.filter((variable) => {
      const fieldTypeMeta = getFieldTypeMeta(
        metaData,
        variable,
        field.fieldType
      );

      return fieldTypeMeta && (!fieldTypeMeta.only_allow_multiple || isMulty);
    });

    return getVariablesGroupedOptions(filteredVariables, t);
  }, [metaData, field, isMulty, allVariables, t]);

  const conflictResolutionOptions = useMemo(() => {
    const options = getConflictResolutionByFieldType(metaData, field).options;

    return metaData.field_mapping_rules.conflict_resolution.filter(
      ({ value }) => options.includes(value)
    );
  }, [metaData, field]);

  const onChangeField = ({ value }: { value: string }) => {
    onChange({ field: rule.field, prop: 'field', value });
    clearError('field');
  };

  const onChangeVariable = ({ value }: { value: string }) => {
    onChange({
      field: rule.field,
      prop: 'variables',
      value: [value],
    });
    clearError('variables');
  };

  const onChangeVariables = (values: { value: string }[]) => {
    onChange({
      field: rule.field,
      prop: 'variables',
      value: values.map(({ value }) => value),
    });
    clearError('variables');
  };

  const onChangeResolution = ({ value }: { value: string }) => {
    onChange({ field: rule.field, prop: 'conflict_resolution', value });
    clearError('conflict_resolution');
  };

  const onChangeCreateFieldOption = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({
      field: rule.field,
      prop: 'can_create_field_options',
      value: !!checked,
    });
    clearError('can_create_field_options');
  };

  const canCreateFieldOption = useMemo(() => {
    return (
      !isStageField(field) &&
      (field?.fieldType
        ? metaData.field_mapping_rules.field_type_meta[field.fieldType]
            ?.can_add_option || false
        : false)
    );
  }, [metaData, field]);

  const value = useMemo(() => {
    if (!rule.variables?.length) {
      return null;
    }
    if (isMulty) {
      return rule.variables.reduce((acc, v: string) => {
        if (variablesById[v]) {
          acc.push({ value: v, label: variablesById[v].name });
        }
        return acc;
      }, [] as GenericOption[]);
    } else {
      return variablesById[rule.variables[0]]
        ? {
            value: rule.variables[0],
            label: variablesById[rule.variables[0]].name,
          }
        : null;
    }
  }, [rule.variables, variablesById, isMulty]);

  return {
    hasError,
    ruleError,
    errorKey,
    validate,
    onDeleteRule,
    variableOptions,
    conflictResolutionOptions,
    onChangeField,
    onChangeVariable,
    onChangeVariables,
    onChangeResolution,
    onChangeCreateFieldOption,
    canCreateFieldOption,
    isMulty,
    value,
  };
};
