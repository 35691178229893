import React from 'react';
import { HTMLBlockContainer } from '@kizen/page-builder/nodes/containers';
import { useSanitizedHtml } from '../hooks/useSanitizedHtml';

export const SharedViewBuilderHTMLBlock = (props) => {
  const { htmlContent, ...rest } = props;
  const cleanHtml = useSanitizedHtml(htmlContent, {
    ADD_TAGS: ['link', 'iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  });

  return (
    <div
      {...rest}
      dangerouslySetInnerHTML={{
        __html: cleanHtml,
      }}
    />
  );
};

export const ViewHTMLBlock = (props) => {
  return (
    <HTMLBlockContainer {...props}>
      <SharedViewBuilderHTMLBlock {...props} />
    </HTMLBlockContainer>
  );
};
