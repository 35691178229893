import { traverseNParents } from 'components/CustomNavigation/util';
import { useCallback, useEffect, useRef } from 'react';

const AUTO_SCROLL_DELAY = 2;
const AUTO_SCROLL_THRESHOLD_PERCENT = 15;
const AUTO_SCROLL_INCREMENT = 4;

const useAutoScroll = ({ draggingItem, leftColumn, rightColumn }) => {
  const isMovingDown = useRef(false);
  const isMovingUp = useRef(false);
  const interval = useRef();

  const clearScrollingState = useCallback(() => {
    window.clearInterval(interval.current);
    isMovingDown.current = false;
    isMovingUp.current = false;
  }, []);

  const manageAutoScroll = useCallback(
    async (id, ev, parent) => {
      if (
        draggingItem &&
        (draggingItem.fromColumn !== leftColumn || id === rightColumn)
      ) {
        const container = traverseNParents(parent, id === leftColumn ? 0 : 3);
        const { clientY } = ev;
        const { top, bottom } = container?.getBoundingClientRect() ?? {};

        const topDifference = clientY - top;
        const bottomDifference = bottom - clientY;
        const containerHeight = bottom - top;
        const topRatio = (topDifference / containerHeight) * 100;
        const bottomRatio = (bottomDifference / containerHeight) * 100;
        const scrollContainer =
          id === leftColumn
            ? container.querySelectorAll('[data-scrollable="true"]')?.[0]
            : container;

        // scroll up
        if (topRatio < AUTO_SCROLL_THRESHOLD_PERCENT) {
          if (!isMovingUp.current) {
            isMovingUp.current = true;
            isMovingDown.current = false;
            window.clearInterval(interval.current);
            interval.current = window.setInterval(() => {
              if (
                scrollContainer?.scrollTop &&
                scrollContainer.scrollTop !== 0 &&
                isMovingUp.current
              ) {
                scrollContainer.scrollTop =
                  scrollContainer.scrollTop - AUTO_SCROLL_INCREMENT;
              }
            }, AUTO_SCROLL_DELAY);
          }

          // scroll down
        } else if (bottomRatio < AUTO_SCROLL_THRESHOLD_PERCENT) {
          if (!isMovingDown.current) {
            isMovingDown.current = true;
            isMovingUp.current = false;
            window.clearInterval(interval.current);
            interval.current = window.setInterval(() => {
              if (
                scrollContainer.scrollTop +
                  containerHeight -
                  (id === leftColumn ? 8 : 0) !==
                  scrollContainer.scrollHeight &&
                isMovingDown.current
              ) {
                scrollContainer.scrollTop =
                  scrollContainer.scrollTop + AUTO_SCROLL_INCREMENT;
              }
            }, AUTO_SCROLL_DELAY);
          }
        } else {
          clearScrollingState();
        }
      }
    },
    [draggingItem, clearScrollingState, leftColumn, rightColumn]
  );

  useEffect(() => {
    if (!draggingItem) {
      clearScrollingState();
    }
  }, [draggingItem, clearScrollingState]);

  const handleMouseOut = useCallback(
    (e) => {
      clearScrollingState();
    },
    [clearScrollingState]
  );

  return {
    manageAutoScroll,
    onMouseOut: handleMouseOut,
  };
};

export default useAutoScroll;
