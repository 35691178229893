import { toastVariant } from 'components/ToastProvider';
import PipelineService from 'services/PipelineService';
import { FIELD_TYPES } from 'utility/constants';
import FieldService from 'services/FieldService';

export const CONTACT = 'contact';
export const PIPELINE = 'pipeline';
export const CUSTOM_OBJECT = 'custom_object';
export const PERMISSION_DENIED = 'permission_denied';
export const UNARCHIVE_CONFLICT = 'unarchive_conflict';
export const UNARCHIVE_PROMPT = 'prompt';
export const UNARCHIVE = 'unarchive';

export const dummyObject = {
  id: 'new',
  fields: [],
  email_status: 'opted_in',
  access: { view: true, edit: true },
};

const isObject = (v) =>
  typeof v === 'object' && v !== null && !Array.isArray(v);

export const getValueForPayload = (v) => {
  let value = v;
  if (isObject(v)) {
    value = v.id;
  } else if (Array.isArray(v)) value = v.map((e) => (e.id ? e.id : e));
  return value;
};

export const getPayload = (values, objectType) => {
  if (objectType !== CONTACT) {
    return Object.entries(values).reduce((acc, [key, v]) => {
      if ((!v && v !== false && v !== 0) || key === 'errors') return acc;

      const value = getValueForPayload(v);
      return acc.concat({ id: key, value });
    }, []);
  }
  return values;
};

export const getStageFieldValue = (field) => {
  if (!field) return {};

  const option = field.options[0];
  if (!option) return {};

  return { [field.id]: option.id };
};

export const updateErrors = (errors, field) => {
  return (
    errors
      .filter(({ fieldId }) => fieldId !== field.id)
      .map(({ fieldId }) => ({ fieldId })) || []
  );
};

export const isEmpty = (value) => {
  if (value === null || (typeof value === 'string' && value.trim() === ''))
    return true;
  else if (Array.isArray(value)) {
    return value.length === 0;
  }
  return (
    typeof value === 'object' && !value.formattedNationalNumber && !value.id
  );
};

export const hasErrors = (fields, values, t) => {
  const message = t('This field is required.');

  return fields
    .filter(
      (f) =>
        f.isRequired && (values[f.id] === undefined || isEmpty(values[f.id]))
    )
    .map((f) => ({ fieldId: f.id, message }));
};

export const toCategoryOption = (c) => ({
  value: c.id,
  label: c.name,
  original: c,
});

export const notHidden = (f) => !f.isHidden;

export const getToastConfigMessage = (objectType, t) => {
  return {
    success: {
      variant: toastVariant.SUCCESS,
      message:
        objectType === CONTACT
          ? t(`The Contact was successfully created.`)
          : t(`The Entity was successfully created.`),
    },
    error: {
      variant: toastVariant.FAILURE,
      message:
        objectType === CONTACT
          ? t(
              `The Contact was not created. Please try again or contact Kizen support.`
            )
          : t(
              `The Entity was not created. Please try again or contact Kizen support.`
            ),
    },
  };
};

export const getObjectName = (model, objectType) => {
  return objectType === CONTACT
    ? CONTACT
    : `${model?.objectName}: "${model?.entityName}"`;
};

export const saveEntity = async (
  objectType,
  values,
  fields,
  unarchive,
  objectId,
  isFullForm
) => {
  // remove fields that are not editable from values for payload
  const valuesForPayload = fields.reduce((acc, field) => {
    if (field.id in values && field.access.edit) {
      acc[field.id] = values[field.id];
    }
    return acc;
  }, {});
  const payload = getPayload(valuesForPayload, objectType);
  if (objectType === CONTACT) {
    const data = isFullForm
      ? {
          ...FieldService.getFormValues(dummyObject, fields),
          ...payload,
        }
      : payload;

    return await FieldService.createObject(
      { for: 'contacts' },
      data,
      fields,
      unarchive,
      { skipErrorBoundary: true }
    );
  }
  if (objectType === PIPELINE) {
    return await PipelineService.createPipelineRecord(
      objectId,
      {
        fields: payload,
        unarchive,
      },
      { skipErrorBoundary: true }
    );
  }
  if (objectType === CUSTOM_OBJECT) {
    return await FieldService.createCustomObjectsRecord(
      objectId,
      {
        fields: payload,
        unarchive,
      },
      { skipErrorBoundary: true }
    );
  }
};

export const getCreateEntityFormValues = (initialValues, fields) => {
  return fields.reduce((collect, field) => {
    const allowMultiple = FieldService.getAllowMultiple(field);

    if (allowMultiple) {
      return {
        ...collect,
        [field.id]: field.uiDefaultValue || [],
      };
    }

    if (field.fieldType === FIELD_TYPES.Dropdown.type) {
      const option = field.options.find(
        (opt) =>
          opt.code === initialValues[field.name] ||
          opt.id === initialValues[field.name]
      );
      if (option?.id) {
        return {
          ...collect,
          [field.id]: option.id,
        };
      }

      return {
        ...collect,
        [field.id]: field.uiDefaultValue?.id || null,
      };
    }

    if (
      field.fieldType === FIELD_TYPES.TeamSelector.type &&
      field.uiDefaultValue
    ) {
      return {
        ...collect,
        [field.id]: {
          id: field.uiDefaultValue.id,
          label: `${field.uiDefaultValue.fullName} (${field.uiDefaultValue.email})`,
        },
      };
    }

    return {
      ...collect,
      [field.id]: field.uiDefaultValue || initialValues[field.name],
    };
  }, {});
};
