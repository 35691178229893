import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Select from 'components/Inputs/Select';

/**
 * A select input that will create a new option when pressing the enter key for user input that
 * is not a current option.
 *
 * @remarks In order to work, the `valueToOption` must be defined so that a `value` can be provided that
 * is not in the `options` list. When that occurs, an additional option will be appended to the end of
 * the static `options` list.
 */
export const CreatableSelect = forwardRef(
  (
    {
      value,
      options,
      onChange,
      isInputValid,
      valueToOption,
      inputToOption = (x) => ({ value: x, label: x }),
      ...rest
    },
    ref
  ) => {
    const innerRef = useRef();

    const allOptions = useMemo(() => {
      return !valueToOption || options.some((o) => o.value === value)
        ? options
        : options.concat(valueToOption(value));
    }, [value, options, valueToOption]);

    const mergeRef = useCallback(
      (select) => {
        ref.current = select;
        innerRef.current = select;
      },
      [ref]
    );

    useEffect(() => {
      const listener = (ev) => {
        if (
          ev.key === 'Enter' &&
          innerRef.current?.state?.inputValue?.length &&
          (!isInputValid || isInputValid(innerRef.current.state.inputValue))
        ) {
          const newValue = inputToOption(innerRef.current.state.inputValue);
          onChange(newValue);
          innerRef.current.select.setValue(newValue);
          innerRef.current.blur();
        }
      };
      window.addEventListener('keypress', listener);
      return () => window.removeEventListener('keypress', listener);
    }, [onChange, isInputValid, inputToOption]);

    return (
      <Select
        ref={mergeRef}
        variant="underline"
        options={allOptions}
        value={value}
        onChange={onChange}
        {...rest}
      />
    );
  }
);
