import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';

import useFormSubmit from 'hooks/useFormSubmit';
import Loader from 'components/Kizen/Loader';
import Input from 'components/Kizen/Input';
import AuthForm from 'components/Kizen/AuthForm';

import Button from 'components/Button';
import { KizenTypography } from 'app/typography';
import {
  serviceRequestPasswordResetStart,
  serviceResetPasswordStart,
} from 'store/authentication/authenticationAction';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useTranslation } from 'react-i18next';

const ResetPasswordPage = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));

  const dispatch = useDispatch();
  const { token } = useParams();
  const isAuthenticated = useSelector(
    ({ authentication }) => !!authentication.user
  );
  const requestError = useSelector(
    ({ authentication }) => authentication.errors.requestPasswordReset
  );
  const resetError = useSelector(
    ({ authentication }) => authentication.errors.resetPassword
  );

  const [loading, setLoading] = useState(false);
  const [field, setField] = useState('');
  const [formSuccess, setFormSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const formConfig = useMemo(() => {
    if (token) {
      return {
        inputProps: {
          label: 'New Password',
          type: 'PASSWORD',
        },
        successMessage:
          'Password reset. You can now log in with your new password.',
      };
    }

    return {
      inputProps: {
        label: 'Email Address',
        type: 'EMAIL',
      },
      successMessage:
        'Password Reset Request processed! Check your email for a link to reset your password.',
    };
  }, [token]);

  const [submitState, fetchSubmit] = useFormSubmit(
    async (ev) => {
      ev.preventDefault();
      setLoading(true);

      let submitSuccess;
      if (token) {
        const [err] = await dispatch(serviceResetPasswordStart(token, field));
        submitSuccess = !err;
      } else {
        const [err] = await dispatch(serviceRequestPasswordResetStart(field));
        submitSuccess = !err;
      }
      setLoading(false);
      // ensures field state cleared, so values don't persist when linking between form versions within the form
      // (see /reset link in error message below)
      setField('');
      if (submitSuccess) {
        setFormSuccess(true);
      }
    },
    [field]
  );

  useEffect(() => {
    // no error
    if (!(requestError || resetError)) {
      return setErrorMessage('');
    }

    const defaultMessage = (
      <>
        An unknown error has occurred, please try again. If you continue to
        experience issues resetting your password, please contact our team at{' '}
        <KizenTypography
          type="link-mail"
          size="micro"
          weight="bold"
          href="mailto:support@kizen.com"
        >
          support@kizen.com
        </KizenTypography>{' '}
        for assistance.
      </>
    );

    // We check token to guarantee we display each type of error
    // on the appropriate page only; the the link to /reset in one
    // of the message cases below does NOT rerender, so we need
    // to take care to partition the error states from each other,
    // even if they co-exist in redux
    if (token) {
      // No reset error, but we're on the reset page
      if (!resetError) {
        return setErrorMessage('');
      }

      const { name, payload, status } = resetError;
      if (name !== 'AuthenticationServiceError' || !payload) {
        return setErrorMessage(defaultMessage);
      }
      const { errors } = payload;

      // could be from v1 or v2, both express the issue the same
      if (errors.password) {
        return setErrorMessage(
          'The password is too weak. All passwords must contain at least 8 characters and be a combination of letters and numbers.'
        );
      }

      // invalid reset token (invalid format or already used)
      if (status === 400 && errors.token) {
        return setErrorMessage(
          <>
            The reset token is not valid or has already been used. Please{' '}
            <KizenTypography type="link" size="micro" weight="bold" to="/reset">
              reset your password
            </KizenTypography>{' '}
            and follow the link in your email.
          </>
        );
      }

      // Any invalid payload error e.g. token missing
      if (status === 400) {
        return setErrorMessage(
          'Valid password and user token required to reset your password'
        );
      }
    } else {
      // On the request reset page, but there's no request error
      // (even though there's potentially a resetError in redux state)
      if (!requestError) {
        return setErrorMessage('');
      }

      const { name, payload, status } = requestError;
      if (name !== 'AuthenticationServiceError' || !payload) {
        return setErrorMessage(defaultMessage);
      }

      const { errors } = payload;
      // email entered doesn’t match an account on file
      if (status === 404) {
        return setErrorMessage(
          'Username does not exist. Please try again with another email address.'
        );
      }
      // username entered isn’t a valid email address
      if (errors.email) {
        return setErrorMessage(
          'Username is not valid. Please try again with your email address.'
        );
      }
    }

    return setErrorMessage(defaultMessage);
  }, [requestError, resetError, token]);

  if (isAuthenticated) {
    return <Redirect to="/business/update-credentials" />;
  }

  return (
    <AuthForm>
      <div className="Authform-wrapper">
        <Loader className="Authform-loader" loading={loading}>
          <form action="">
            <KizenTypography type="header" className="Authform-header">
              Reset Password
            </KizenTypography>

            {formSuccess && (
              <KizenTypography className="Authform-success">
                {formConfig.successMessage}
              </KizenTypography>
            )}
            {!formSuccess && (
              <Input
                value={field}
                className="Authform-input"
                onChange={setField}
                required
                {...formConfig.inputProps}
              />
            )}
            <div className="Authform-actions">
              <KizenTypography
                type="link"
                weight="bold"
                textTransform="uppercase"
                to="/login"
              >
                Back to Login
              </KizenTypography>
              {!formSuccess && (
                <Button
                  className="Authform-button"
                  onClick={fetchSubmit}
                  disabled={!field || submitState.loading}
                >
                  Submit
                </Button>
              )}
            </div>
            {errorMessage && (
              <KizenTypography className="Authform-error">
                {errorMessage}
              </KizenTypography>
            )}
          </form>
        </Loader>
      </div>
    </AuthForm>
  );
};

export default ResetPasswordPage;
