import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale, colorsButton } from 'app/colors';
import { breakpoints, gutters } from 'app/spacing';

export const navItemHoverColor = css`
  color: ${colorsButton.blue.hover};
`;

export const regularNavItemWidth = (numLinks) => css`
  @media (min-width: ${breakpoints.md + 1}px) {
    width: ${numLinks ? `calc(100% / ${numLinks})` : '100%'};
  }
`;

export const commonNavItemStyle = css`
  padding: 0 0;

  @media (min-width: ${breakpoints.md + 1}px) {
    padding: ${gutters.standard} 0 !important;
  }
  & span {
    color: ${grayScale.dark};
    font-size: 14px;
    margin-top: 8px;
    @media (max-width: ${breakpoints.md}px) {
      margin-top: 0;
      margin-left: 20px;
    }
  }
  &:hover span,
  &:hover i {
    color: ${colorsButton.blue.hover};
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoints.md}px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const SubNavigationNavItemDivider = styled.div`
  width: 1px;
  background: ${grayScale.mediumLight};
  align-self: stretch;
`;

export const AltSubNavigationNavItemDivider = styled.div`
  @media (min-width: ${breakpoints.md + 1}px) {
    width: 1px;
    background: ${grayScale.mediumLight};
    align-self: stretch;
  }
  @media (max-width: ${breakpoints.md}px) {
    flex: 1 0 ${gutters.spacing(3)}px;
  }
`;
