import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { shadowLight, grayScale, colorsButton, colorsText } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import Icon from 'components/Kizen/Icon';
import { css } from '@emotion/core';
import { gutters } from 'app/spacing';
import * as PropTypes from 'prop-types';
import { CONTENT_MAX_WIDTH } from '../../Layout/PageContentWidth';
import { useTooltip } from 'components/Kizen/Tooltip';

export const ADD_RECORD_TAB_MAX_WIDTH = '35px';
export const ADD_RECORD_TAB_WIDTH_TABLE = '108px';
const num = (px) => parseInt(px, 10);

const SHOW_QUICK_ADD_MAX_WIDTH = `${
  num(CONTENT_MAX_WIDTH) +
  2 * num(ADD_RECORD_TAB_WIDTH_TABLE) +
  num(ADD_RECORD_TAB_MAX_WIDTH)
}`;
// 68px - adding tab width
const SHOW_ADDING_MAX_WIDTH = `${
  num(CONTENT_MAX_WIDTH) + 2 * num(68) + num(ADD_RECORD_TAB_MAX_WIDTH)
}`;

const Base = styled.div`
  display: flex;
  align-items: center;
  background: ${grayScale.white};
  height: 40px;
  border-top-left-radius: ${({ scrolled }) => (scrolled ? '0px' : '20px')};
  border-bottom-left-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  clip-path: inset(0px -0.5px -44px -26px);
  // Clip shadow so that it doesn't appear on top of table
  padding-right: 10px;
  transition:
    width 0.3s ease-in-out,
    border-radius 0.3s ease-in-out;
  ${({ adding, expanded }) => {
    if (expanded) {
      return css`
        ${adding &&
        `svg {
            display: inline-block;
            color: ${colorsButton.blue.hover};
          }`}
        .Adding {
          display: none;
        }
        width: ${ADD_RECORD_TAB_MAX_WIDTH};
      `;
    }
    if (adding) {
      return css`
        svg {
          display: none;
          color: ${colorsButton.blue.hover};
        }
        @media (max-width: ${SHOW_ADDING_MAX_WIDTH}px) {
          svg {
            display: inline-block;
            color: ${colorsButton.blue.hover};
          }
          .Adding {
            display: none;
          }
          width: ${ADD_RECORD_TAB_MAX_WIDTH};
        }
        width: 68px;
      `;
    }
    return css`
      width: 35px;
      .QuickAdd {
        display: none;
      }
      &:hover {
          @media (max-width: ${SHOW_QUICK_ADD_MAX_WIDTH}px) {
            width: ${ADD_RECORD_TAB_MAX_WIDTH};
          }
          width: ${ADD_RECORD_TAB_WIDTH_TABLE};
          .QuickAdd {
            display: inline-block;
          }
        }
      }
    `;
  }}
  && i {
    margin-left: 13px;
    svg {
      width: 12px;
      height: auto;
    }
  }
  &:hover {
    svg,
    .QuickAdd {
      color: ${colorsButton.blue.hover};
    }
  }
  .QuickAdd {
    white-space: nowrap;
    margin-left: ${gutters.spacing(3)}px;
  }
  .Adding {
    white-space: nowrap;
  }
  ${shadowLight}
`;

export default function AddRecordTab({
  adding,
  expanded,
  scrolled,
  ...others
}) {
  const { t } = useTranslation();
  const [tooltipProps, tooltip] = useTooltip({
    label: t('Quick Add'),
  });
  return (
    <Base
      adding={adding}
      expanded={expanded}
      scrolled={scrolled}
      {...tooltipProps}
      {...others}
    >
      <Icon icon="plus" title={t('Quick Add')} />
      {adding && (
        <KizenTypography className="Adding" color={colorsText.medium}>
          {t('Adding')}
        </KizenTypography>
      )}
      {!adding && (
        <KizenTypography className="QuickAdd">{t('Quick Add')}</KizenTypography>
      )}
      {tooltip}
    </Base>
  );
}
AddRecordTab.propTypes = {
  adding: PropTypes.bool,
  expanded: PropTypes.bool,
  scrolled: PropTypes.bool,
};

AddRecordTab.defaultProps = {
  adding: undefined,
  expanded: undefined,
  scrolled: undefined,
};
