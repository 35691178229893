import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GenericOption,
  MatchingRule,
  SmartConnectorVariable,
} from '__pages/SmartConnectors/types';
import { useMatchingRulesErrors } from './useMatchingRuleErrors';
import { PrimaryObjectUploadContext } from '../../context';
import {
  StyledBoldSelect,
  StyledDeleteButton,
  StyledDiv,
  StyledRow,
  StyledRuleCard,
  StyledRuleWarpper,
  StyledSubheader,
} from './styles';
import Select from '__components/Inputs/Select';
import { ReorderButtons } from './ReorderButtons';
import { getFieldTypeMeta } from '../MappingStep/helpers';
import { getVariablesGroupedOptions } from '__pages/SmartConnectors/SmartConnector/helpers';

export type OnChangeMatchingRuleHandler = ({
  id,
  prop,
  value,
}: {
  id: string;
  prop: keyof MatchingRule;
  value: MatchingRule[keyof MatchingRule];
}) => MatchingRule[keyof MatchingRule];

type MatchingRuleSettingsProps = {
  rule: MatchingRule;
  index: number;
  isLastIndex: boolean;
  onChange: OnChangeMatchingRuleHandler;
  onDelete: (id: string, index: number) => void;
  onReorder: (index: number, offset: 1 | -1) => void;
  fieldOptions: GenericOption[];
  variables: SmartConnectorVariable[];
};

type ActionsConfig = {
  action: keyof Pick<
    MatchingRule,
    | 'match_archive_action'
    | 'no_match_action'
    | 'single_match_action'
    | 'multiple_match_action'
  >;
  label: string;
}[];

export const MatchingRuleSettings = ({
  rule,
  index,
  isLastIndex,
  onChange,
  onDelete,
  onReorder,
  fieldOptions,
  variables,
}: MatchingRuleSettingsProps) => {
  const { t } = useTranslation();
  const { metaData, customObject, variablesById } = useContext(
    PrimaryObjectUploadContext
  );

  const { id, field, variable } = rule;

  const { hasError, ruleError, errorKey, validate, clearError } =
    useMatchingRulesErrors(index);

  const onDeleteRule = () => {
    onDelete(id!, index);
  };

  const onChangeField = ({ value }: { value: string }) => {
    const result = onChange({ id, prop: 'field', value });
    if (result) {
      clearError('field');
    }
    return result as string;
  };

  const onChangeVariable = ({ value }: { value: string }) => {
    onChange({ id, prop: 'variable', value });
    clearError('variable');
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorKey) {
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [errorKey]);

  const actions = useMemo<ActionsConfig>(
    () => [
      { action: 'no_match_action', label: t('If No Match Found') },
      {
        action: 'single_match_action',
        label: t('If Single Match Found'),
      },
      {
        action: 'multiple_match_action',
        label: t('If Multiple Matches Found'),
      },
      {
        action: 'match_archive_action',
        label: t('If Archived Match Found'),
      },
    ],
    [t]
  );

  const variableOptions = useMemo(() => {
    const ruleField = (customObject?.fields || []).find(
      ({ id }) => id === field
    );

    const filteredVariables = variables.filter(
      (variable) =>
        ruleField?.fieldType &&
        getFieldTypeMeta(metaData, variable, ruleField.fieldType)
    );
    return getVariablesGroupedOptions(filteredVariables, t);
  }, [variables, metaData, field, customObject?.fields, t]);

  return (
    <StyledRuleWarpper
      ref={wrapperRef}
      hasError={hasError}
      data-ga-rule-id={rule.id}
      data-qa-rule-index={index}
    >
      <ReorderButtons
        index={index}
        isFirst={index === 0}
        isLast={isLastIndex}
        onReorder={onReorder}
      />
      <StyledRuleCard>
        <StyledRow>
          <StyledDiv width={303}>
            <StyledBoldSelect
              value={field}
              options={fieldOptions}
              label={t('Kizen Field for Matching')}
              placeholder={t('Choose Kizen Field')}
              onChange={onChangeField}
              error={ruleError?.field}
              validate={errorKey === 'field' ? validate : undefined}
            />
          </StyledDiv>
          {field ? (
            <StyledDiv width={257}>
              <StyledBoldSelect
                value={
                  variable &&
                  variablesById[variable] && {
                    value: variablesById[variable]?.id,
                    label: variablesById[variable]?.name,
                  }
                }
                options={variableOptions}
                label={t('Variable to Match')}
                placeholder={t('Choose Variable')}
                onChange={onChangeVariable}
                error={ruleError?.variable}
                validate={errorKey === 'variable' ? validate : undefined}
              />
            </StyledDiv>
          ) : null}
          <StyledDeleteButton color="red" variant="text" onClick={onDeleteRule}>
            {t('Delete Field Match')}
          </StyledDeleteButton>
        </StyledRow>
        <StyledSubheader weight="bold">{t('Upload Functions')}</StyledSubheader>
        {actions.map(({ action, label }) => {
          const onChangeAction = ({ value }: { value: string }) => {
            onChange({ id, prop: action, value });
            clearError(action);
          };
          return (
            <StyledDiv width={303} key={action}>
              <Select
                value={rule[action]}
                options={metaData.matching_rules[`${action}s`]}
                label={label}
                onChange={onChangeAction}
                error={ruleError?.[action]}
                validate={errorKey === action ? validate : undefined}
              />
            </StyledDiv>
          );
        })}
      </StyledRuleCard>
    </StyledRuleWarpper>
  );
};
