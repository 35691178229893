import React, { useState } from 'react';
import styled from '@emotion/styled';
import { UnderlinedSelect as Select } from 'components/Kizen/Select';
import MOCK_TEAM_MEMBERS from 'services/MOCK_TEAM_MEMBERS';
import { gutters } from 'app/spacing';

const StyledSelect = styled(Select)`
  margin-bottom: ${gutters.standard};
`;

const SingleSelect = () => {
  const [valueA, setValueA] = useState(null);

  return (
    <>
      <StyledSelect
        fullWidth
        label="Single Select Name"
        placeholder="Choose Option"
        options={MOCK_TEAM_MEMBERS}
        value={valueA}
        onChange={setValueA}
      />
    </>
  );
};

export const FilledSingleSelect = () => {
  const [valueA, setValueA] = useState('home');

  return (
    <>
      <StyledSelect
        fullWidth
        label="Single Select Name"
        placeholder="Choose Option"
        options={[
          {
            label: "Scott's Work",
            value: 'work',
          },
          {
            label: "Scott's Home",
            value: 'home',
          },
          {
            label: "Scott's Yacht",
            value: 'yacht',
          },
        ]}
        value={valueA}
        onChange={setValueA}
      />
    </>
  );
};

export default SingleSelect;
