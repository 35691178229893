import { get as getStorage, set as setStorage } from 'storage';
import { getHash } from 'utility/encode';

class LoginCounter {
  counterKey = 'login-counter';
  ALLOWED_BEFORE_WARNING = 3;
  // email: count pair
  emailMap = {};

  // primitive encoding
  emailToKey(email) {
    return getHash(email);
  }

  init() {
    this.emailMap = JSON.parse(getStorage(this.counterKey)) || {};
  }

  isAllow(email) {
    if (!email) return true;
    const key = this.emailToKey(email);
    return (
      !this.emailMap[key] || this.emailMap[key] < this.ALLOWED_BEFORE_WARNING
    );
  }

  count(email) {
    if (!email) return;
    const key = this.emailToKey(email);
    if (!this.emailMap[key]) {
      this.emailMap[key] = 0;
    }
    this.emailMap[key]++;
    setStorage(this.counterKey, JSON.stringify(this.emailMap));
  }
  reset(email) {
    if (!email) return;
    const key = this.emailToKey(email);
    this.emailMap[key] = 0;
    setStorage(this.counterKey, JSON.stringify(this.emailMap));
  }
}

const instance = new LoginCounter();
export default instance;
