import phoneCountryCodes from 'utility/phone-country-codes';
import { FIELD_TYPES } from 'utility/constants';
import { pluralizeFetchUrl } from 'services/FieldService';
import { AsYouType } from 'libphonenumber-js/max';
import phoneMeta from 'libphonenumber-js/metadata.min.json';
import { isEmailStatusField } from 'checks/fields';

export const exists = (n) => Boolean(n || n === 0);

export const integerToOption = (n) => ({
  label: n.toLocaleString(),
  value: n.toString(),
});

export const ratingOptions = (field) => {
  const { minValue, maxValue } = field.rating;
  return Array.from(Array(maxValue - minValue + 1), (_, i) => minValue + i).map(
    integerToOption
  );
};

export const getIdentifierForOption = (opt, { fieldType }) => {
  if (fieldType === FIELD_TYPES.Choices.type) {
    return opt.code;
  }
  return opt.id;
};

export const getCurrencySymbol = ({ moneyOptions }) => {
  return moneyOptions && (moneyOptions.symbol || moneyOptions.currency);
};

export const countryToCode = Object.entries(phoneCountryCodes).reduce(
  (collect, [code, countries]) => {
    countries.forEach((country) => {
      collect[country] = code;
    });
    return collect;
  },
  {}
);

// The input shows country codes, but we store countries.
// Many countries can have the same code, so some information is lost along the way.

export const lossyCodeToCountry = Object.entries(phoneCountryCodes).reduce(
  (collect, [code, countries]) => {
    const [country] = countries;
    collect[code] = country;
    return collect;
  },
  {}
);

export const formatPhoneE164 = (phone) => {
  const phoneString = new AsYouType();
  phoneString.input(phone);
  const [, ext = ''] = phone.split('x');
  return phoneString.getNumberValue() + `${ext ? 'x' + ext : ''}`;
};

export const isCountryCallingCode = (number) => {
  return !!phoneMeta.country_calling_codes[number?.replace('+', '')];
};

export const suggestedCountryByPhoneCode = (number) => {
  return phoneMeta.country_calling_codes[number][0];
};

const getXScrollParentOffset = (el, prevEl = null, offset = 0) => {
  if (!el || el.scrollWidth > el.clientWidth) {
    return [el, offset];
  }
  return getXScrollParentOffset(
    el.parentNode,
    el,
    offset + ((prevEl && prevEl.offsetLeft) || 0)
  );
};

const scrollX = (el, x) => {
  if (el.scrollTo) {
    // If there's browser support
    el.scrollTo({
      left: x,
      behavior: 'smooth',
    });
  } else {
    el.scrollLeft = x;
  }
};

export const scrollFieldIntoView = (el) => {
  if (el) {
    const [scrollEl, offset] = getXScrollParentOffset(el);
    if (scrollEl) {
      // Nudge away from gradient fade-out on most tables
      scrollX(scrollEl, offset - 75);
    }
  }
};

export const getTagLink = ({ fetchUrl, tagType, dataString }) =>
  `/${
    fetchUrl === 'client' ? pluralizeFetchUrl(fetchUrl) : fetchUrl
  }?${tagType}=${dataString}`;

export const specialTagTypes = {
  tags: 'tag',
  titles: 'titles',
  dynamictag: 'dynamictag',
};

export const isEmailStatusImmutable = (userObject, field) =>
  isEmailStatusField(field) && userObject?.email_status === 'suppression_list';

export const addRelationToTeamSelector = (field) => {
  return field.fieldType === FIELD_TYPES.TeamSelector.type &&
    field.relation === null
    ? { ...field, relation: { fetchUrl: 'team' } }
    : field;
};

export const getName = (option) => option?.name || '';

export const isValidArray = (list) => Array.isArray(list) && list.length;

export const isISO = (val) =>
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+(?:[+-][0-2]\d:[0-5]\d|Z)/.test(
    val
  );

export const getLinkDataForTag = ({ fieldId, fetchUrl, tagType, field }) => {
  return {
    link: ({ id, name }) => {
      let dataString;
      switch (tagType) {
        case specialTagTypes.tags: {
          dataString = encodeURIComponent(JSON.stringify({ id, name }));
          break;
        }

        case specialTagTypes.titles: {
          const value = {
            value: id,
            field: 'titles',
            meta: {
              id: field?.customObjectField?.id || field.id,
              category: field.category,
              fieldType: field.fieldType,
              displayName: 'Titles',
              isDefault: true,
              name: 'titles',
              properties: field.properties,
            },
            subtype: 'non_custom',
          };
          dataString = encodeURIComponent(JSON.stringify(value));
          break;
        }
        case specialTagTypes.dynamictag: {
          const value = {
            value: id,
            field: field?.customObjectField?.id || field.id,
            meta: {
              id: field?.customObjectField?.id || field.id,
              category: field.category,
              fieldType: field.fieldType,
              displayName: field.displayName,
              name: field.name.split('_')[0],
              properties: field.properties,
            },
            subtype: 'custom',
          };
          dataString = encodeURIComponent(JSON.stringify(value));
          break;
        }

        default: {
          dataString = encodeURIComponent(
            JSON.stringify({ fieldId, optionId: id })
          );
        }
      }

      return getTagLink({ fetchUrl, tagType, dataString });
    },
  };
};

export const isSummarizedValue = (value) =>
  value &&
  typeof value === 'object' &&
  Object.prototype.hasOwnProperty.call(value, 'count');
