import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { breakpoints, spaceBetweenGutters } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import Section from '../../section';
import { modalSize } from 'components/Modals';

export const StyledSection = styled(Section)`
  margin-top: 17px;
  margin-left: -2px;
  margin-bottom: 25px;
  min-width: 0;
  p {
    margin-bottom: unset;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const AssociationFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 0;
  & p {
    margin-bottom: 0;
    height: 100%;
  }
  > * {
    width: 100%;
    @media (min-width: ${modalSize.medium}) {
      width: ${spaceBetweenGutters({ columns: 2, gutter: '20px' })};
    }
    @media (min-width: ${breakpoints.lg}px) {
      width: ${spaceBetweenGutters({ columns: 3, gutter: '20px' })};
    }
  }
`;

export const ActivityFields = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 0 15px;

  & > div > .notes {
    margin-bottom: 20px;
  }
  & > div.span1:last-child > div,
  & > div.span2:last-of-type > div,
  & > div:last-child > .notes {
    margin-bottom: 2px;
  }

  .span2 {
    grid-column: span 2;
  }

  @media (max-width: calc(${modalSize.medium} - 1px)) {
    .span1 {
      grid-column: span 2;
    }
  }

  > :empty {
    display: none;
  }
`;

export const StyledLoader = styled(Loader)`
  padding: 0 !important;
`;

export const Container = styled.div`
  display: flex;
  border: 1px solid ${grayScale.medium};
  border-radius: 8px;
  padding: 6px 14px;
  margin-bottom: 15px;
`;
