import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal from 'components/Modals/presets/BasicModal';
import { isEqual } from 'lodash';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import ConfirmNavigationModal from 'components/Modals/presets/ConfirmNavigation';
import useModal from 'components/Modals/useModal';
import { StyledNotice, StyledOutlineInputControl } from '../styles';
import TextTemplater from 'components/Kizen/TextTemplater';
import { colorsButton } from 'app/colors';
import { useAsync } from 'react-use';
import CustomObjectsService from 'services/CustomObjectsService';
import Loader from '__components/Kizen/Loader';
import { FIELD_TYPES } from 'utility/constants';

export const EditNameTemplateModal = ({
  data,
  customObject,
  onConfirm,
  ...modalProps
}) => {
  const { t } = useTranslation();

  const [stagedData, setStagedData] = useState(data);

  const isDirty = useMemo(() => {
    return !isEqual(data, stagedData);
  }, [stagedData, data]);

  const [confirmationModalProps, , { show: showConfirmationModal }] = useModal({
    handleSubmit: modalProps.onHide,
  });

  const onHide = useCallback(() => {
    if (!isDirty) {
      modalProps.onHide();
    } else {
      showConfirmationModal();
    }
  }, [modalProps, isDirty, showConfirmationModal]);

  const onSubmit = useCallback(async () => {
    try {
      await CustomObjectsService.updateCustomObject({
        customObject,
        updatedCustomObjectValues: {
          entityDisplayNamePatternText: stagedData.content,
          entityDisplayNamePatternHtml: stagedData.htmlContent,
        },
      });
      onConfirm(stagedData);
    } catch (err) {}
  }, [stagedData, onConfirm, customObject]);

  const { value: mergeFields = [], loading } = useAsync(async () => {
    const { categorizedFields } =
      await CustomObjectsService.getCategorizedModelFields(customObject.id, {
        settingsSearch: true,
      });

    return categorizedFields.reduce((caregoriesAcc, { name, fields, id }) => {
      const items = fields.reduce((fieldsAcc, field) => {
        if (
          [FIELD_TYPES.Text.type, FIELD_TYPES.Email.type].includes(
            field.fieldType
          )
        ) {
          fieldsAcc.push({
            optionLabel: `${field.displayName} (${field.name})`,
            label: field.name,
            value: `{{ ${field.name} }}`,
            relationship: field.name,
            fieldType: field.fieldType,
          });
        }
        return fieldsAcc;
      }, []);

      if (items.length) {
        caregoriesAcc.push({
          key: id,
          label: name,
          items,
        });
      }
      return caregoriesAcc;
    }, []);
  }, []);

  return (
    <>
      <BasicModal
        heading={t('Edit Name Template')}
        size="medium"
        enforceFocus={false}
        disabled={false}
        {...modalProps}
        onHide={onHide}
        onConfirm={onSubmit}
      >
        <Loader loading={loading}>
          <StyledNotice>
            {t(
              'Updating the display name template will change how this record appears in related object fields, type-aheads, and timeline entries. Upon update, all records will be updated asynchronously.'
            )}
          </StyledNotice>
          <TextTemplater
            appendMergeFieldExtraSpace={false}
            title={
              <StyledOutlineInputControl label={t('Display Name Template')} />
            }
            defaultValue={stagedData.htmlContent}
            textareaOptions={{
              placeholder: t('Enter Template'),
              enableResize: false,
              borderHoverColor: colorsButton.blue.hover,
            }}
            customize={{
              mergeFieldProps: {
                inModal: true,
                mergeFields,
              },
              autoSuggestions: true,
              wrapperClassName: 'templater',
              showCountComponent: false,
            }}
            onSave={setStagedData}
            oneLineLayout
          />
          <div style={{ height: '197px' }}></div>
        </Loader>
      </BasicModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        actionBtnColor="red"
        {...confirmationModalProps}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
      <ConfirmNavigationModal when={isDirty} />
    </>
  );
};
