import { pick } from 'lodash';
import AxiosService, { getOriginalError } from './AxiosService';
import {
  camelToSnakeCaseKeys,
  isPromiseRejected,
  snakeToCamelCaseKeys,
} from './helpers';
import { FIELD_TYPES } from 'utility/constants';
import { formMapper } from 'xforms/api';

const mapOption = (element) => ({
  label: element.name,
  value: element.id,
});

const doesFieldRequireOptions = (field) => {
  return (
    field.fieldType === FIELD_TYPES.YesNo.types ||
    field.fieldType === FIELD_TYPES.YesNoMaybe.type
  );
};

const getParentWindow = (window) => {
  if (window.parent instanceof Window) {
    return window.parent;
  }
  if (window?.parent?.[0] instanceof Window) {
    return window.parent[0];
  }
  return null;
};

export class FormService {
  constructor(endpoint = '/forms') {
    this.endpoint = endpoint;
  }

  create = async (value) => {
    const { data } = await AxiosService.post(
      this.endpoint,
      camelToSnakeCaseKeys(value)
    );
    return data;
  };

  createField = async (id, field) => {
    const options =
      doesFieldRequireOptions(field) ||
      (!field.isCustomObjectField && field.options?.length > 0)
        ? field.options.map((o) => ({ ...o, id: undefined }))
        : undefined;
    const payload = {
      ...pick(field, [
        'displayName',
        'fieldType',
        'isRequired',
        'allowsNulls',
        'allowsEmpty',
        'isReadOnly',
        'isHidden',
        'meta',
      ]),
      options,
      customObjectField: field?.customObjectField?.id,
      moneyOptions: field.moneyOptions ?? undefined,
      phonenumberOptions: field.phonenumberOptions ?? undefined,
      rating: field.rating ?? undefined,
      relation: field.relation ?? undefined,
    };

    const response = await AxiosService.post(
      `${this.endpoint}/${id}/fields`,
      camelToSnakeCaseKeys(payload)
    );
    return snakeToCamelCaseKeys(response.data);
  };

  createFields = async (id, fields) => {
    const promises = fields.map((field) => this.createField(id, field));
    const results = await Promise.allSettled(promises);
    return {
      hasError: results.some(isPromiseRejected),
      results,
    };
  };

  deleteField = async (id, fieldId) => {
    await AxiosService.delete(`${this.endpoint}/${id}/fields/${fieldId}`);
    return true;
  };

  deleteFields = async (id, fieldIds) => {
    const promises = fieldIds.map((fieldId) => this.deleteField(id, fieldId));
    const results = await Promise.allSettled(promises);
    return {
      hasError: results.some(isPromiseRejected),
      results,
    };
  };

  duplicate = async ({ id }) => {
    const { data } = await AxiosService.post(
      `${this.endpoint}/${id}/duplicate`
    );
    return data;
  };

  exportResponses = async ({ id }) => {
    const { data } = await AxiosService.post(`${this.endpoint}/${id}/export`);
    return data;
  };

  getById = async (id) => {
    const { data } = await AxiosService.get(`${this.endpoint}/${id}`);
    return formMapper(snakeToCamelCaseKeys(data));
  };

  getFullJourneyOptions = async (
    { ordering = 'name', page, objectId },
    current = []
  ) => {
    const { data } = await AxiosService.get(this.endpoint, {
      params: {
        related_object_id: objectId,
        page,
        ordering,
      },
    });
    const currentIds = new Set(current.map((e) => e.id));
    const newItems =
      current.length === 0
        ? data.results
        : data.results.filter((item) => !currentIds.has(item.id));

    if (data.next)
      return await this.getFullJourneyOptions(
        {
          ordering,
          page: page + 1,
          objectId,
        },
        current.concat(newItems)
      );

    return current.concat(newItems).map(mapOption);
  };

  getList = async (options = {}) => {
    try {
      const params = { withFields: true, ...options };
      const { data } = await AxiosService.get(this.endpoint, {
        params: camelToSnakeCaseKeys(params),
      });
      return data?.results ? snakeToCamelCaseKeys(data.results) : [];
    } catch (error) {
      return [];
    }
  };

  getListWithoutFields = async (
    { search, page, size, ordering } = {},
    options
  ) => {
    const params = {
      search,
      page: page || 1,
      page_size: size || 50,
      ordering,
    };
    try {
      const { data } = await AxiosService.get(this.endpoint, {
        params,
        ...options,
      });
      return data;
    } catch (error) {
      const orig = getOriginalError(error);
      if (orig.detail) {
        throw new Error(orig.detail);
      }
      throw error;
    }
  };

  getOptions = async (params = {}) => {
    const { data } = await AxiosService.get(this.endpoint, {
      params: camelToSnakeCaseKeys(params),
    });
    return data.results.map(mapOption);
  };

  getFormFields = async (id, options) => {
    const { data } = await AxiosService.get(
      `${this.endpoint}/${id}/fields`,
      options
    );

    return data;
  };

  getPreview = async (id) => {
    const { data } = await AxiosService.get(
      `https://${import.meta.env.VITE_EMBED_API_DOMAIN}${this.endpoint}/${id}/page-view`
    );
    return snakeToCamelCaseKeys(data);
  };

  patchField = async (id, fieldId, updates) => {
    return AxiosService.patch(
      `${this.endpoint}/${id}/fields/${fieldId}`,
      camelToSnakeCaseKeys(updates)
    );
  };

  remove = async ({ id }) => {
    const { data } = await AxiosService.delete(`${this.endpoint}/${id}`);
    return data;
  };

  submit = async (
    id,
    fields,
    {
      isPartial = false,
      submissionId = null,
      recaptchaToken,
      isIframe = false,
    } = {}
  ) => {
    const parentWindow = getParentWindow(window);
    const this_page_params = new URLSearchParams(window.location.search);
    const payload = {
      fields: camelToSnakeCaseKeys(fields),
      partial_submission: isPartial,
    };
    if (recaptchaToken) {
      payload.challenge_token = recaptchaToken;
    }
    let request_querystring = '';
    if (this_page_params.has('session_id')) {
      request_querystring = `?session_id=${this_page_params.get('session_id')}`;
    } else if (isIframe && parentWindow) {
      request_querystring = `?referrer=${parentWindow.location.href}`;
    } else if (document.referrer) {
      request_querystring = `?referrer=${document.referrer}`;
    }
    if (submissionId) {
      return AxiosService.patch(
        `https://${import.meta.env.VITE_EMBED_API_DOMAIN}${this.endpoint}/${id}/submit/${submissionId}${request_querystring}`,
        payload
      );
    }

    return AxiosService.post(
      `https://${import.meta.env.VITE_EMBED_API_DOMAIN}${this.endpoint}/${id}/submit${request_querystring}`,
      payload
    );
  };

  update = async (id, updates) => {
    const patches = { ...updates };
    if (updates?.formUi?.pages) {
      const pages = updates?.formUi?.pages?.map((p) => ({
        ...p,
        pageData:
          typeof p.pageData === 'string'
            ? p.pageData
            : JSON.stringify(p.pageData),
      }));
      patches.formUi = { ...updates.formUi, pages };
    }
    const { data } = await AxiosService.patch(
      `${this.endpoint}/${id}`,
      camelToSnakeCaseKeys(patches)
    );
    return snakeToCamelCaseKeys(data);
  };
}

const instance = new FormService();
Object.freeze(instance);

export default instance;
