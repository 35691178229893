import { Editor } from '@craftjs/core';
import { useState } from 'react';
import { Frame } from './Frame';
import { ViewerContext } from './ViewerContext';

type JavaScriptAction = {
  id: string;
  name: string;
  script: string;
};

type StaticContentBlockProps = {
  data: any;
  nodes: any;
  applyMobileBreakpoints: boolean;
  borderRadius?: string;
  javascriptActions: JavaScriptAction[];
  textTransform?: (text: string) => string;
  entityId?: string;
  objectId?: string;
  handleScriptError?: (error: unknown) => void;
  onLogActivityButtonClick: (id: string) => void;
  onStartAutomationButtonClick: (id: string) => void;
};

export const StaticContentBlock = ({
  data,
  nodes,
  applyMobileBreakpoints,
  borderRadius,
  javascriptActions,
  textTransform,
  entityId,
  objectId,
  handleScriptError,
  onLogActivityButtonClick,
  onStartAutomationButtonClick,
}: StaticContentBlockProps) => {
  const [rootWidth, setRootWidth] = useState(0);

  const ctx = {
    javascriptActions,
    rootBorderRadius: borderRadius,
    fullWidth: true,
    fullHeight: true,
    applyMobileBreakpoints,
    rootNode: data.ROOT,
    rootWidth,
    setRootWidth,
    textTransform,
    entityId,
    objectId,
    handleScriptError,
    onLogActivityButtonClick,
    onStartAutomationButtonClick,
  };

  return (
    <Editor enabled={false} resolver={nodes}>
      <ViewerContext.Provider value={ctx as any}>
        <Frame data={data} />
      </ViewerContext.Provider>
    </Editor>
  );
};
