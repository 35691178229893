import { useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useWizardFilterContext } from 'components/Wizards/MetaFilters/context';

import {
  AddFilterButton,
  AddFilterSetButton,
  ObjectOverviewFilterTypeSelector,
  Filter,
  FilterOperatorText,
  FilterRow,
  FilterSetContainer,
  FilterSetOperationDropdown,
  FilterTypeSelectorContainer,
  useApiFilterErrors,
  useMappedReturnErrors,
} from 'ts-components/filters';

export const FilterSets = ({ hideOutOfBoundaries = false }) => {
  const { t } = useTranslation();

  const {
    filterErrors,
    operation,
    setOperation,
    setHasErrors,
    filterSets,
    filterOps: {
      addFilter,
      addFilterSet,
      createFilter,
      removeFilter,
      setFilterSetOperation,
    },
    isForCustomObject,
    customObject,
  } = useWizardFilterContext();

  const clearFilters = useCallback(() => {
    setHasErrors(false);
    // will need to clear api errors as well
  }, [setHasErrors]);

  const objectType = isForCustomObject
    ? customObject.objectType
    : 'client_client';
  const errorsReturned = null;

  const errorsDictionary = useMappedReturnErrors(errorsReturned, filterSets);
  const [apiErrors] = useApiFilterErrors(errorsDictionary, 3000);

  const multipleSets = filterSets.length > 1;
  return (
    <>
      {filterSets.map(([setId, { and, filters }], setIdx) => {
        const multipleFilters = filters.length > 1;
        return (
          <Fragment key={setId}>
            {setIdx > 0 && (
              <FilterSetOperationDropdown
                operation={operation}
                onChange={setOperation}
              />
            )}
            <FilterSetContainer
              value={and ? 'all' : 'any'}
              showBorder={multipleFilters || multipleSets}
              onChange={(value) =>
                setFilterSetOperation(setId, value === 'all')
              }
            >
              {filters.map(({ type, steps, ops, id }, idx, arr) => {
                const isLastFilter = idx === arr.length - 1;
                const filterTypeChosen = type !== null;
                const errors = filterErrors[setId]?.[idx] ?? {};

                return (
                  <FilterRow key={id}>
                    <FilterTypeSelectorContainer
                      showDeleteIcon={multipleFilters || multipleSets}
                      onDelete={() => {
                        removeFilter(setId, idx);
                      }}
                    >
                      <ObjectOverviewFilterTypeSelector
                        objectType={objectType}
                        value={type}
                        error={
                          errors['filter-type'] ||
                          (apiErrors[setId]?.[idx] &&
                            t('This filter is invalid.'))
                        }
                        onChange={({ value }) => {
                          createFilter(value, setId, idx);
                          clearFilters();
                        }}
                        hideOutOfBoundaries={hideOutOfBoundaries}
                        translateY={-1}
                        modalLayer={2} // must support nested modals since edit filter modal can exist in another modal
                      />
                    </FilterTypeSelectorContainer>

                    <Filter
                      filterType={type}
                      objectType={objectType}
                      steps={steps}
                      next={ops.next}
                      set={ops.set}
                      errors={errors}
                      modalLayer={2} // must support nested modals since edit filter modal can exist in another modal
                      translateY={-1}
                      hideOutOfBoundaries={hideOutOfBoundaries}
                      onChange={() => {
                        clearFilters();
                        ops.update();
                      }}
                    />
                    {arr.length > 1 && !isLastFilter && (
                      <FilterOperatorText and={and} />
                    )}
                    {filterTypeChosen && isLastFilter && (
                      <AddFilterButton onClick={() => addFilter(setId)} />
                    )}
                  </FilterRow>
                );
              })}
            </FilterSetContainer>
            {filterSets.length === 1 && multipleFilters && (
              <AddFilterSetButton
                onClick={(value) => {
                  addFilterSet();
                  setOperation(value);
                }}
              />
            )}
          </Fragment>
        );
      })}
      {multipleSets && (
        <AddFilterSetButton disableSelect onClick={addFilterSet} />
      )}
    </>
  );
};
