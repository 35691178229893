import Menu, { ApplyMenuButton, ClearMenuButton } from 'components/Kizen/Menu';
import { useTranslation } from 'react-i18next';
import useField from 'hooks/useField';
import { StyledTextInput } from '../styles';
import { useAutoFocus } from '../hooks/useAutoFocus';

export const PillTextInput = ({
  isReadOnly,
  autoFocus,
  onChange,
  onHide,
  value,
}) => {
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = useField(value || '');

  const handleClickApply = () => {
    onHide();
    onChange(innerValue || undefined);
  };

  const handleClickClear = () => {
    onHide();
    onChange();
  };

  const setInputElement = useAutoFocus(null, isReadOnly || !autoFocus);
  const getRefToInput = (el) => setInputElement(el?.querySelector('input'));

  return (
    <Menu
      leftButton={
        isReadOnly ? null : (
          <ClearMenuButton onClick={handleClickClear}>
            {t('Clear & Close')}
          </ClearMenuButton>
        )
      }
      rightButton={
        isReadOnly ? null : <ApplyMenuButton onClick={handleClickApply} />
      }
    >
      <StyledTextInput
        ref={getRefToInput}
        value={innerValue}
        placeholder={t('Enter Text')}
        onChange={setInnerValue}
        disabled={isReadOnly}
      />
    </Menu>
  );
};
