import { useRef } from 'react';
import useSyncScroll from 'react-use-sync-scroll';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { useScrollThreshold } from 'components/Kizen/LazyLoad/helpers';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import {
  LeftGradient,
  RightGradient,
  TitlesWrapper,
  WithScrollWrapper,
} from 'components/Wizards/shared/components/TableBuilder/components/ColumnsHeaderPreview/styles';
import { PillDropdownControl } from 'components/Inputs/PillDropdown/components/PillDropdownControl';
import { PreviewWrapper, StyledContent } from '../styles';

const TitlesRow = ({ columns }) => {
  const scrollbarContentWrapperRef = useRef(null);
  const [wrapperRefFn, wrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncWidth',
    'width'
  );
  useSyncScroll(useRef([wrapperRef, scrollbarContentWrapperRef]), {
    horizontal: true,
  });

  const [, fadeRight, , fadeLeft] = useEndsOfScroll(wrapperRef, [columns]);
  useScrollThreshold(wrapperRef, [columns], () => {});

  return (
    <WithScrollWrapper>
      <TitlesWrapper ref={wrapperRefFn} data-qa-id="column-titles">
        <LeftGradient position="left" fadeIn={!fadeLeft} />
        <StyledContent className="ContentForSyncWidth">
          {columns?.map((column) => (
            <PillDropdownControl
              key={column.label}
              title={column.label}
              isError={false}
            />
          ))}
        </StyledContent>
        <RightGradient position="right" fadeIn={!fadeRight} />
      </TitlesWrapper>
      <ScrollBarDetached
        ref={scrollbarContentWrapperRef}
        direction="horizontal"
        scrollClassName="ContentForSyncWidth"
      />
    </WithScrollWrapper>
  );
};

export const HeaderPreview = ({ columns }) => {
  return (
    <PreviewWrapper>
      <div>
        <TitlesRow columns={columns} />
      </div>
    </PreviewWrapper>
  );
};
