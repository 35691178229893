import {
  applyDropzone,
  getNaiveDropzone,
  unlessItem,
} from 'components/DragAndDropLayout/helpers';

export const getCols = (field) => (field.widthPercent === 50 ? 1 : 2);

const getFirstInRow = (colsPerRow, list, findRow) => {
  let x = 0;
  let row = 0;
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    const nextItem = list[i + 1];
    const cols = getCols(item);
    const nextCols = nextItem ? getCols(nextItem) : 0;
    if (row === findRow) {
      return item.id;
    }
    if (
      x + cols >= colsPerRow ||
      x + cols + nextCols > colsPerRow ||
      !nextItem
    ) {
      x = 0;
      row += 1;
    } else {
      x += cols;
    }
  }
  return null;
};

const getRow = (colsPerRow, list, id) => {
  let x = 0;
  let row = 0;
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    const nextItem = list[i + 1];
    const cols = getCols(item);
    const nextCols = nextItem ? getCols(nextItem) : 0;
    if (item.id === id) {
      return row;
    }
    if (
      x + cols >= colsPerRow ||
      x + cols + nextCols > colsPerRow ||
      !nextItem
    ) {
      x = 0;
      row += 1;
    } else {
      x += cols;
    }
  }
  return -1;
};

const getLastInRow = (colsPerRow, list, findRow) => {
  let x = 0;
  let row = 0;
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    const nextItem = list[i + 1];
    const cols = getCols(item);
    const nextCols = nextItem ? getCols(nextItem) : 0;
    if (
      x + cols >= colsPerRow ||
      x + cols + nextCols > colsPerRow ||
      !nextItem
    ) {
      x = 0;
      row += 1;
    } else {
      x += cols;
    }
    if (row === findRow + 1) {
      return item.id;
    }
  }
  return null;
};

const choosePositionForShortCol = (colsPerRow, field, fields, dropzone) => {
  const [nextFields] = applyDropzone(field, [fields], dropzone);
  const row = getRow(colsPerRow, nextFields, field.id);
  return {
    ...dropzone,
    direction:
      getFirstInRow(colsPerRow, nextFields, row) ===
      getLastInRow(colsPerRow, nextFields, row)
        ? 'horizontal'
        : 'vertical',
  };
};

export const getParentFieldDropzone = (
  field,
  toColumnId,
  toOptions,
  event,
  buildClassName,
  leftColumn
) => {
  const naiveDropzone = getNaiveDropzone({
    items: toOptions,
    skip: (i) => false,
    selector: buildClassName?.() ?? `.MenuItemWrapper-${toColumnId}`,
    event,
    colsPerRow: 2,
    draggingItemCols: getCols(field),
    elementSelector:
      toColumnId === leftColumn
        ? (index) => `[data-original-index="${index}"]`
        : undefined,
  });
  if (naiveDropzone && naiveDropzone.position === 'first') {
    naiveDropzone.sectionId = toColumnId;
  }

  if (getCols(field) === 1) {
    return (
      naiveDropzone &&
      unlessItem(
        field,
        toOptions,
        choosePositionForShortCol(2, field, toOptions, naiveDropzone)
      )
    );
  }

  if (getCols(field) === 2) {
    return (
      naiveDropzone &&
      unlessItem(field, toOptions, {
        ...naiveDropzone,
        direction: 'horizontal',
      })
    );
  }
};
