import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  colorsButton,
  colorsText,
  grayScale,
  dropdownColors,
} from '../../../app/colors';
import { fontSizes, TextSpan, fontWeights } from '../../../app/typography';
import { gutters, borderRadii, layers } from '../../../app/spacing';
import { components } from 'react-select';
import { useTruncationTooltip } from '../Tooltip';

export const StyledLabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
`;

export const StyledCustomOption = (props) => {
  const [tooltipProps, tooltip] = useTruncationTooltip();
  return (
    <>
      {tooltip}
      <div {...tooltipProps}>
        <components.Option {...props} />
      </div>
    </>
  );
};

export const StyledLabel = styled(TextSpan)`
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

// Customization of https://react-select.com/styles
export const reactSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    width:
      !state.selectProps.fullWidth &&
      (state.selectProps.mobile ? '263px' : '395px'),
  }),

  control: (provided, state) => {
    const borderColor = () => {
      if (state.isFocused) {
        return colorsButton.blue.hover;
      }
      if (state.selectProps.error) {
        return colorsButton.red.hover;
      }

      return grayScale.medium;
    };
    const borderRadius = state.menuIsOpen
      ? {
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }
      : {};

    return {
      ...provided,
      borderColor: borderColor(),
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? grayScale.light : grayScale.white,
      ':hover': {
        borderColor: colorsButton.blue.hover,
      },
      minHeight: '36px',
      ...borderRadius,
    };
  },

  valueContainer: () => ({
    paddingLeft: 12,
  }),

  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
    maxWidth: 'calc(100% - 35px)',
  }),

  menu: (provided, state) => {
    // Force dropdown menu to open upwards
    const dropUpStyles = state.selectProps.dropUp
      ? {
          top: 'auto !important',
          bottom: '100% !important',
        }
      : {};

    const inlineStyles = state.selectProps.inlineMenu && {
      position: 'relative',
      'box-shadow': 'none',
    };

    return {
      ...provided,
      // Needs to open above radio buttons and checkboxes, which have z-index of 1
      zIndex: 2,
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: '0 18px 26px rgba(0, 0, 0, 0.03)',
      border: `1px solid ${grayScale.medium}`,
      borderTop: 0,
      ...dropUpStyles,
      ...inlineStyles,
    };
  },

  menuList: (provided) => ({
    ...provided,
    padding: 0,
    // TODO find a way to use our typical scrollbar styles here,
    // as they are currently wrapped in emotion's css``.
    maxHeight: '36rem',
    '::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '::-webkit-scrollbar-thumb': {
      width: 2.4,
      borderRadius: '8px',
      backgroundColor: grayScale.mediumDark,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
  }),

  option: (provided, state) => {
    let stateStyle = {
      color: colorsText.dark,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: dropdownColors.hover,
      },
    };
    if (state.isSelected) {
      stateStyle = {
        backgroundColor: 'unset',
        color: colorsText.medium,
      };
    }
    if (state.isDisabled) {
      stateStyle = {
        color: grayScale.mediumDark,
      };
    }
    return {
      ...provided,
      ...stateStyle,
      padding: '0 12px',
      fontSize: fontSizes.text,
      display: 'block',
      height: '32px',
      lineHeight: '32px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    };
  },
  placeholder: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
  input: () => ({
    // need to specify input in order to get font size correct
    // also need !important to override inline styles that are applied
    input: {
      fontSize: `${fontSizes.text} !important`,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: grayScale.medium,
    ':hover': {
      color: grayScale.medium,
    },
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px 0 0',
    '&& svg': {
      width: 12,
      height: 'auto',
    },
  }),
};

// Underlined variant

export const StyledUnderlinedLabelWrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const shrinkStyles = () => css`
  // scaling to 78.5% will take the 14px down to the 11px we want
  // The 4px will put the label 10px above the capline of the input text
  transform: translate(0, 4px) scale(0.785);
  // Account for the scaling when computing the width of the element
  width: calc(100% / 0.785);
  font-weight: ${fontWeights.bold};
  // Undoes the pointer-events: none we set to allow the input to be focused through the label
  // this is so hover actions for truncationTooltip will work
  pointer-events: auto;
`;

const truncateStyles = () => css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledUnderlinedLabel = styled(TextSpan)`
  z-index: ${layers.content(0, 1)};
  position: absolute;
  top: 0;
  left: 0;
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.regular};
  // Allow the user to focus/hover the input with the label in the way
  pointer-events: none;
  // 22px will put the label baseline exactly 10px from the bottom border
  transform: translate(0, 22px) scale(1);
  transition:
    font-weight,
    transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transform-origin: top left;
  ${({ shrink }) => shrink && shrinkStyles};
  ${truncateStyles};
`;

// Customization of https://react-select.com/styles
export const reactSelectUnderlinedStyles = {
  ...reactSelectStyles,
  container: (provided, state) => ({
    ...reactSelectStyles.container(provided, state),
    marginTop: gutters.standard,
  }),

  control: (provided, state) => {
    const borderColor = () => {
      if (state.menuIsOpen) {
        return colorsButton.blue.hover;
      }
      if (state.selectProps.error) {
        return colorsButton.red.hover;
      }

      return grayScale.medium;
    };

    return {
      ...provided,
      border: 'none',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: borderColor(),
      boxShadow: 'none',
      borderRadius: 0,
      borderTopLeftRadius: borderRadii.small,
      borderTopRightRadius: borderRadii.small,
      backgroundColor: state.isDisabled ? grayScale.light : grayScale.white,
      ':hover': {
        borderColor: colorsButton.blue.hover,
      },
      minHeight: '29px', // 29 instead of 28 to account for border-bottom
    };
  },

  valueContainer: () => ({
    paddingLeft: 0,
  }),

  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
    maxWidth: 'calc(100% - 35px)',
  }),

  menu: (provided, state) => {
    // Force dropdown menu to open upwards
    const dropUpStyles = state.selectProps.dropUp
      ? {
          top: 'auto !important',
          bottom: '100% !important',
        }
      : {};

    // TODO the underline inlineMenu variant is not yet used: feel free to fix if necessary then remove this warning.
    const inlineStyles = state.selectProps.inlineMenu && {
      position: 'relative',
      'box-shadow': 'none',
    };

    return {
      ...provided,
      // Needs to open above radio buttons and checkboxes, which have z-index of 1
      zIndex: 2,
      margin: 0,
      'box-shadow': '0 18px 26px rgba(0, 0, 0, 0.03)',
      border: `1px solid ${grayScale.medium}`,
      borderTop: 'none',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      ...dropUpStyles,
      ...inlineStyles,
    };
  },

  option: (provided, state) => {
    let stateStyle = {
      color: colorsText.dark,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: dropdownColors.hover,
      },
    };
    if (state.isSelected) {
      stateStyle = {
        backgroundColor: 'unset',
        color: colorsText.medium,
      };
    }
    return {
      ...provided,
      ...stateStyle,
      padding: '0 10px',
      fontSize: fontSizes.text,
      display: 'block',
      height: '32px',
      lineHeight: '32px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    };
  },
  placeholder: (provided) => ({
    ...provided,
    transition: 'opacity 10ms',
    marginLeft: 0,
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px 0 0',
    '&& svg': {
      width: 10,
      height: 'auto',
    },
  }),
};
