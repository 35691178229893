import React from 'react';
import * as PropTypes from 'prop-types';

import BasicModal from './BasicModal';

const ConfirmationModal = ({
  onHide,
  onConfirm,
  heading,
  buttonText,
  children,
  ...others
}) => {
  return (
    <BasicModal
      buttonText={buttonText}
      heading={heading}
      onConfirm={onConfirm}
      onHide={onHide}
      typeOfContent="text"
      {...others}
    >
      {children}
    </BasicModal>
  );
};

ConfirmationModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  heading: PropTypes.node.isRequired,
  buttonText: PropTypes.node.isRequired,
};

export default ConfirmationModal;
