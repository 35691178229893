import { PropsWithChildren } from 'react';
import { SmartConnectorContext } from './context';
import { useSmartConnectorContext } from './hooks/useSmartConnectorContex';

export const SmartConnectorProvider = ({ children }: PropsWithChildren) => {
  const context = useSmartConnectorContext();
  return (
    <SmartConnectorContext.Provider value={context}>
      {children}
    </SmartConnectorContext.Provider>
  );
};
