import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

export const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  min-height: 129px;
`;

export const AutomationStatsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const AutomationStat = styled.div`
  width: 151px;
  padding: 0 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0 ${gutters.spacing(3)}px;
  text-align: center;
`;

export const AutomationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${gutters.spacing(1, -1)}px 0;
`;

export const AutomationName = styled(TextEllipsisWithTooltip)`
  font-weight: 300;
  margin-bottom: ${gutters.spacing(2, 3)}px;
  text-align: center;
  width: 100%;
`;

export const AutomationStatValue = styled(KizenTypography)`
  font-weight: 300;
`;

export const AutomationStatLabel = styled(KizenTypography)`
  text-transform: uppercase;
`;

export const AutomationStatPercent = styled(KizenTypography)`
  margin-top: ${gutters.spacing(2, 2)}px;
`;

export const Automation = styled.div`
  overflow-x: auto;

  &:last-child {
    padding-bottom: ${gutters.spacing(3, 2)}px;
  }

  &:nth-last-child(n + 2) {
    padding-bottom: ${gutters.spacing(4, -1)}px;
  }

  &:nth-child(n + 2) {
    padding-top: ${gutters.spacing(4)}px;
    border-top: 1px solid ${grayScale.mediumLight};
  }
`;

export const StyledLoader = styled(Loader)`
  margin-top: -5%;
`;
