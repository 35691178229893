import { Nav } from 'react-bootstrap';
import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';
import { gutters, layers } from 'app/spacing';
import css from '@emotion/css';
import { colorsButton } from 'app/colors';
import LinkNavSelector from 'components/Navigation/LinkNavSelector';
import KizenTypography from 'app/kizentypo';

export const StyledNav = styled(Nav)`
  padding-left: 25px;
  z-index: ${layers.content(10, 1)};
`;

export const StyledGearIcon = styled(Icon)`
  padding-right: ${gutters.spacing(2, 3)}px;
  cursor: pointer;

  &:hover {
    color: ${colorsButton.blue.hover};
  }
  ${({ mobile }) =>
    !mobile
      ? css`
          padding-top: ${gutters.spacing(2, 3)}px;
          padding-bottom: ${gutters.spacing(2, 3)}px;
        `
      : ''}
`;

export const StyledSubNavLabel = styled(LinkNavSelector)`
  & .dashboard-menu__menu,
  & .homepage-menu__menu {
    z-index: ${layers.content(10, 1)};
  }

  & .dashboard-menu__menu-notice--no-options {
    min-width: 200px;
  }

  & .dashboard-menu__control,
  & .homepage-menu__control {
    max-width: 250px;
  }

  .homepage-menu__menu {
    min-width: 280px;
  }

  .dashboard-menu__control--is-disabled {
    background: unset;
  }
  ${({ mobile }) =>
    mobile
      ? css`
          padding: 9px 15px 0 15px;
          margin-bottom: -6px;

          & .dashboard-menu__control,
          & .homepage-menu__control {
            max-width: 540px;
            background: transparent;
            min-height: 20px;
            margin: 0 auto;
          }

          & > div > div,
          & > div > div > div {
            width: 100%;
            max-width: 100%;
          }

          .homepage-menu__menu {
            width: 100%;
            min-width: 0;
          }

          .homepage-menu__single-value {
            font-size: 1em;
          }

          .homepage-menu__single-value > span {
            font-weight: 600 !important;
          }
        `
      : ''}
`;
export const StyledKizenTypography = styled(KizenTypography)`
  &:focus {
    outline: none;
  }
  background-color: inherit;
  color: ${colorsButton.blue.default};
  &:hover {
    color: ${colorsButton.blue.hover};
  }
`;
