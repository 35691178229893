import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { Tree } from 'components/Tree';
import Switch from 'components/Kizen/Switch';
import TextInput from 'components/Inputs/TextInput';
import { urlValidation } from 'utility/validate';
import { SubsectionHeader } from '../../components/TraySection';
import {
  FlexChild,
  FlexSelect,
  FlexUnderlineRadio,
  FlexWholeNumberInput,
} from '../components';
import {
  ImageSelector,
  SelectedFileLabel,
} from '../../components/ImageSelector';
import {
  positionOptions,
  imageSizeOptions,
  IMAGE_SIZES,
  unitOptions,
  UNIT,
  dimensionOptions,
  DIMENSION,
} from '../../options';
import {
  getMaxWidthBlurValue,
  getProportionalSizes,
  MaxWidthInput,
} from './shared';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px;`};
  padding-bottom: ${({ paddingBottom = 0 }) => `${paddingBottom}px;`};
`;

const AltTextInput = styled(TextInput)`
  margin-bottom: -7px; // lines up the input with the bottom tick of the Tree
`;

const StyledTree = styled(Tree)`
  margin-top: 20px;
`;

const HasLinkSwitch = styled(Switch)`
  min-width: 50px;
  margin-right: 20px;
`;

const ImageSelectorSection = forwardRef(
  ({ fileId, name, onImageChange, onImageDelete, onImageRemove }, ref) => (
    <div ref={ref}>
      <SubsectionHeader>
        <SelectedFileLabel fileName={name} />
      </SubsectionHeader>
      <FlexContainer paddingBottom={20}>
        <ImageSelector
          fileId={fileId}
          fileName={name}
          onImageChange={onImageChange}
          onImageDelete={onImageDelete}
          onImageRemove={onImageRemove}
        />
      </FlexContainer>
    </div>
  )
);

const ImageStylingSection = forwardRef(
  (
    {
      size,
      position,
      unit,
      width,
      height,
      maxWidth,
      maxHeight,
      dimension,
      naturalWidth,
      naturalHeight,
      containerWidth,
      onSizeChange,
      onPositionChange,
      onUnitChange,
      onWidthChange,
      onHeightChange,
      onMaxWidthChange,
      onMaxHeightChange,
      onDimensionChange,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const setDimensionDefaults = (unit, size) => {
      const data = {
        height: naturalHeight,
        width: naturalWidth,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
      };

      if (size === IMAGE_SIZES.AUTO) {
        data.height = null;
        data.width = null;
        data.maxWidth = maxWidth;
        data.maxHeight = maxHeight;
      }

      if (size === IMAGE_SIZES.DYNAMIC) {
        if (unit === UNIT.PIXEL) {
          const proportion = naturalWidth / naturalHeight;
          const {
            width: dynamicWidth,
            height: dynamicHeight,
            maxWidth: dynamicMaxWidth,
            maxHeight: dynamicMaxHeight,
          } = getProportionalSizes({
            width: naturalWidth,
            proportion,
            containerWidth,
          });
          data.height = dynamicHeight;
          data.width = dynamicWidth;
          data.maxWidth = dynamicMaxWidth;
          data.maxHeight = dynamicMaxHeight;
        } else {
          data.height = null;
          data.maxWidth = null;
          data.maxHeight = null;
          data.width = 100;
        }
      }
      onWidthChange(data.width);
      onHeightChange(data.height);
      onMaxHeightChange(data.maxHeight);
      onMaxWidthChange(data.maxWidth);
    };

    const handleChangeSize = ({ value }) => {
      onSizeChange(value);
      if (value === IMAGE_SIZES.DYNAMIC) {
        onUnitChange(UNIT.PIXEL);
        onDimensionChange(DIMENSION.WIDTH);
      }
      if (value === IMAGE_SIZES.FIXED) {
        onUnitChange(UNIT.PIXEL);
      }
      setDimensionDefaults(UNIT.PIXEL, value);
    };

    const handleChangeUnit = (value) => {
      onUnitChange(value);
      setDimensionDefaults(value, IMAGE_SIZES.DYNAMIC);
    };

    const handleChangeWidth = (value) => {
      const isPXUnit = unit === UNIT.PIXEL;
      const parsedValue = parseInt(value, 10);
      let sizes = {
        width: parsedValue,
        height,
        maxWidth: parsedValue,
        maxHeight: height,
      };

      if (isNaN(parsedValue) || parsedValue === 0) {
        if (size === IMAGE_SIZES.FIXED) {
          sizes = {
            ...sizes,
            width: null,
            maxWidth: null,
          };
        } else {
          sizes = {
            width: null,
            height: null,
            maxWidth: null,
            maxHeight: null,
          };
        }
      } else {
        if (size === IMAGE_SIZES.DYNAMIC && isPXUnit) {
          const proportion = naturalWidth / naturalHeight;
          sizes = getProportionalSizes({
            width: parsedValue,
            proportion,
            containerWidth,
            unit,
          });
        }
      }

      onWidthChange(sizes.width);
      onHeightChange(sizes.height);
      onMaxWidthChange(sizes.maxWidth);
      onMaxHeightChange(sizes.maxHeight);
    };

    const handleChangeHeight = (value) => {
      const parsedValue = parseInt(value, 10);
      let sizes = {
        width,
        height: parsedValue,
        maxWidth: width,
        maxHeight: parsedValue,
      };

      if (isNaN(parsedValue) || parsedValue === 0) {
        if (size === IMAGE_SIZES.FIXED) {
          sizes = {
            ...sizes,
            height: null,
            maxHeight: null,
          };
        } else {
          sizes = {
            width: null,
            height: null,
            maxWidth: null,
            maxHeight: null,
          };
        }
      } else {
        if (size === IMAGE_SIZES.DYNAMIC) {
          const proportion = naturalWidth / naturalHeight;
          sizes = getProportionalSizes({
            height: parsedValue,
            proportion,
            containerWidth,
          });
        }
      }

      onWidthChange(sizes.width);
      onHeightChange(sizes.height);
      onMaxWidthChange(sizes.maxWidth);
      onMaxHeightChange(sizes.maxHeight);
    };

    return (
      <div ref={ref}>
        <KizenTypography weight="bold">{t('Image Styling')}</KizenTypography>
        <FlexContainer
          marginTop={10}
          paddingBottom={size !== IMAGE_SIZES.AUTO ? 0 : 20}
        >
          <FlexSelect
            variant="underline"
            label="Size"
            value={size}
            onChange={handleChangeSize}
            options={imageSizeOptions(t)}
          />
          <FlexSelect
            variant="underline"
            label="Position"
            value={position}
            onChange={onPositionChange}
            options={positionOptions(t)}
          />
        </FlexContainer>
        {size === IMAGE_SIZES.DYNAMIC && (
          <FlexContainer marginTop={10} paddingBottom={20}>
            <FlexUnderlineRadio
              label={t('Unit')}
              flex={1 / 3}
              value={unit}
              options={unitOptions}
              onChange={handleChangeUnit}
            />
            {unit === UNIT.PIXEL ? (
              <>
                <FlexSelect
                  variant="underline"
                  label={t('Dimension')}
                  flex={1 / 3}
                  value={dimension}
                  options={dimensionOptions(t)}
                  onChange={({ value }) => onDimensionChange(value)}
                />
                {dimension === DIMENSION.WIDTH ? (
                  <FlexWholeNumberInput
                    variant="underline"
                    label={t('Max-Width')}
                    flex={1 / 3}
                    placeholder={t('Auto')}
                    value={maxWidth || width}
                    onChange={handleChangeWidth}
                    onBlur={(ev) => {
                      handleChangeWidth(
                        getMaxWidthBlurValue(ev.target.value, unit)
                      );
                    }}
                  />
                ) : (
                  <FlexWholeNumberInput
                    variant="underline"
                    label={t('Max-Height')}
                    flex={1 / 3}
                    placeholder={t('Auto')}
                    value={maxHeight || height}
                    onChange={handleChangeHeight}
                  />
                )}
              </>
            ) : (
              <>
                <MaxWidthInput
                  variant="underline"
                  label={t('Max-Width')}
                  flex={1 / 3}
                  placeholder={t('Auto')}
                  value={width}
                  onChange={handleChangeWidth}
                  onBlur={(ev) => {
                    handleChangeWidth(
                      getMaxWidthBlurValue(ev.target.value, unit)
                    );
                  }}
                />
                <FlexChild flex={1 / 3} />
              </>
            )}
          </FlexContainer>
        )}
        {size === IMAGE_SIZES.FIXED && (
          <FlexContainer marginTop={10} paddingBottom={20}>
            <FlexWholeNumberInput
              variant="underline"
              label={t('Width (px)')}
              flex={1 / 2}
              placeholder={t('Auto')}
              value={width}
              onChange={onWidthChange}
              onBlur={(ev) => {
                onWidthChange(getMaxWidthBlurValue(ev.target.value, unit));
              }}
            />
            <FlexWholeNumberInput
              variant="underline"
              label={t('Height (px)')}
              flex={1 / 2}
              placeholder={t('Auto')}
              value={height}
              onChange={onHeightChange}
            />
          </FlexContainer>
        )}
      </div>
    );
  }
);

const ImagePropertiesSection = forwardRef(
  (
    { link, alt, onLinkChange, onAltTextChange, onErrorShow, onErrorHide },
    ref
  ) => {
    const { t } = useTranslation();
    const [hasLink, setHasLink] = useState(Boolean(link));

    if (!hasLink && Boolean(link)) {
      setHasLink(true);
    }

    return (
      <div ref={ref}>
        <KizenTypography weight="bold">{t('Properties')}</KizenTypography>
        <FlexContainer direction="column">
          <FlexContainer marginTop={10}>
            <HasLinkSwitch
              label={t('Has Link')}
              checked={hasLink}
              textPlacement="top"
              onChange={(ev) => {
                setHasLink(ev.target.checked);
                if (!ev.target.checked) {
                  onLinkChange(null);
                }
              }}
            />
            {hasLink && (
              <TextInput
                variant="underline"
                value={link}
                label={t('URL')}
                onChange={onLinkChange}
                validate={{ full: urlValidation(t) }}
                onErrorShow={onErrorShow}
                onErrorHide={onErrorHide}
              />
            )}
          </FlexContainer>
          <AltTextInput
            variant="underline"
            value={alt}
            label={t('Alt Text')}
            onChange={onAltTextChange}
          />
        </FlexContainer>
      </div>
    );
  }
);

export const ImageTree = ({
  imageProps,
  onChange,
  onImageChange,
  onImageDelete,
  onImageRemove,
  onErrorShow,
  onErrorHide,
}) => {
  const {
    fileId,
    src,
    name,
    size,
    position,
    alt,
    link,
    unit,
    width,
    height,
    maxWidth,
    maxHeight,
    dimension,
    naturalWidth,
    naturalHeight,
    containerWidth,
  } = imageProps;

  return (
    <StyledTree offset={6}>
      <ImageSelectorSection
        fileId={fileId}
        src={src}
        name={name}
        onImageChange={onImageChange}
        onImageDelete={onImageDelete}
        onImageRemove={onImageRemove}
      />
      <ImageStylingSection
        size={size}
        position={position}
        unit={unit}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        dimension={dimension}
        naturalWidth={naturalWidth}
        naturalHeight={naturalHeight}
        containerWidth={containerWidth}
        onSizeChange={(value) => onChange('size', value)}
        onPositionChange={({ value }) => onChange('position', value)}
        onUnitChange={(value) => onChange('unit', value)}
        onWidthChange={(value) => onChange('width', value)}
        onHeightChange={(value) => onChange('height', value)}
        onMaxWidthChange={(value) => onChange('maxWidth', value)}
        onMaxHeightChange={(value) => onChange('maxHeight', value)}
        onDimensionChange={(value) => onChange('dimension', value)}
      />
      <ImagePropertiesSection
        key={fileId}
        link={link}
        alt={alt}
        onLinkChange={(value) => onChange('link', value)}
        onAltTextChange={(value) => onChange('alt', value)}
        onErrorShow={onErrorShow}
        onErrorHide={onErrorHide}
      />
    </StyledTree>
  );
};

export const DynamicImageTree = ({
  imageProps,
  onChange,
  onImageChange,
  onImageDelete,
  onImageRemove,
  onErrorShow,
  onErrorHide,
}) => {
  const {
    fileId,
    src,
    name,
    size,
    position,
    alt,
    link,
    unit,
    width,
    height,
    maxWidth,
    maxHeight,
    dimension,
    naturalHeight,
    naturalWidth,
    containerWidth,
  } = imageProps;

  return (
    <StyledTree offset={6}>
      <ImageSelectorSection
        fileId={fileId}
        src={src}
        name={name}
        onImageChange={onImageChange}
        onImageDelete={onImageDelete}
        onImageRemove={onImageRemove}
      />
      <ImageStylingSection
        size={size}
        position={position}
        unit={unit}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        dimension={dimension}
        naturalWidth={naturalWidth}
        naturalHeight={naturalHeight}
        containerWidth={containerWidth}
        onSizeChange={(value) => onChange('size', value)}
        onPositionChange={({ value }) => onChange('position', value)}
        onUnitChange={(value) => onChange('unit', value)}
        onWidthChange={(value) => onChange('width', value)}
        onHeightChange={(value) => onChange('height', value)}
        onMaxWidthChange={(value) => onChange('maxWidth', value)}
        onMaxHeightChange={(value) => onChange('maxHeight', value)}
        onDimensionChange={(value) => onChange('dimension', value)}
      />
      <ImagePropertiesSection
        key={fileId}
        link={link}
        alt={alt}
        onLinkChange={(value) => onChange('link', value)}
        onAltTextChange={(value) => onChange('alt', value)}
        onErrorShow={onErrorShow}
        onErrorHide={onErrorHide}
      />
    </StyledTree>
  );
};
