import styled from '@emotion/styled';
import { fontSizes, fontWeights } from 'app/typography';
import { SettingsContentCard } from 'components/Card/Card';
import EditableText from 'components/Kizen/EditableText';
import { contentWidthCss } from 'components/Layout/PageContentWidth';
import PageToolbar from 'components/Layout/PageToolbar';
import Subsection from 'components/Modals/Common/Subsection';
import { NAV_BAR_HEIGHT, TOOLBAR_HEIGHT } from './constants';
import BigTableLayout from 'components/Layout/BigTableLayout';
import { appBackground, grayScale } from 'app/colors';
import InputControl from 'components/Inputs/InputControl';
import DragItem from 'components/DragAndDropLayout/DragItem';
import { layers } from 'app/spacing';

export const StyledPageToolbar = styled(PageToolbar)`
  ${contentWidthCss};
  margin: 20px auto;
  padding: 0;
  height: 36px;
`;

export const StyledTableLayout = styled(BigTableLayout)`
  margin-top: -20px;
`;

export const Wrapper = styled.div`
  ${contentWidthCss};
  display: flex;
  flex-direction: column;
`;

export const ContentCard = styled(SettingsContentCard)`
  height: calc(
    100vh - ${NAV_BAR_HEIGHT + TOOLBAR_HEIGHT + 20}px +
      ${({ heightOffset }) => heightOffset || 0}px
  );
`;

export const CardTitle = styled(Subsection)`
  & > h3 {
    margin-bottom: 0;
  }
  && {
    margin-bottom: 25px;
  }
`;

export const StyledDndColumns = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - ${({ isTemplate }) => (isTemplate ? 46 : 100)}px);
  margin-bottom: 12px;
`;

export const TemplateName = styled(EditableText)`
  max-width: 350px;
  input {
    font-size: ${fontSizes.text};
    font-weight: ${fontWeights.bold};
  }
`;

export const PreviewWrapper = styled.div`
  height: 50px;
  display: flex;
  border: 1px solid ${grayScale.mediumLight};
  background-color: ${appBackground};
  & > div {
    width: 100%;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  & > div {
    justify-content: flex-start;
    flex: auto;
    margin-top: 11px;
    padding: 5px;
    &:first-child {
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 10px;
    }
  }

  column-gap: 10px;
`;

export const SwitchControl = styled(InputControl)`
  > label {
    margin-top: -3px;
    margin-bottom: 12px;
    & + * {
      margin: 0;
      label {
        margin: 0;
      }
    }
  }
  margin-bottom: 23px;
`;

export const StyledDragItem = styled(DragItem)`
  position: relative;
  z-index: ${layers.content(0, 4)};
`;
