import { stableSort } from 'utility/data';

export const getItemCols = (item) => {
  const cols = item.meta && item.meta.cols;
  return cols || cols === 0 ? cols : 1;
};

// Below are a bunch of helpers to determine where mouse coordinates are
// compared to a given DOM element. The coordinates used are relative to the viewport.

export const topHalfOfEl = (fieldEl, { x, y }) => {
  const { top, bottom, left, right } = fieldEl.getBoundingClientRect();
  return x >= left && x <= right && y >= top && y <= (top + bottom) / 2;
};

export const bottomHalfOfEl = (fieldEl, { x, y }) => {
  const { top, bottom, left, right } = fieldEl.getBoundingClientRect();
  return x >= left && x <= right && y <= bottom && y >= (top + bottom) / 2;
};

export const aboveFieldEl = (fieldEl, { y }) => {
  const { top } = fieldEl.getBoundingClientRect();
  return y <= top;
};

export const belowItemEl = (itemEl, { y }) => {
  const { bottom } = itemEl.getBoundingClientRect();
  return y >= bottom;
};

export const topHalfOfRightOfEl = (fieldEl, { x, y }) => {
  const { top, bottom, right } = fieldEl.getBoundingClientRect();
  return x >= right && y >= top && y <= (top + bottom) / 2;
};

export const bottomHalfOfRightOfEl = (fieldEl, { x, y }) => {
  const { top, bottom, right } = fieldEl.getBoundingClientRect();
  return x >= right && y <= bottom && y >= (top + bottom) / 2;
};

export const leftHalfOfEl = (fieldEl, { x, y }) => {
  const { top, bottom, left, right } = fieldEl.getBoundingClientRect();
  return x >= left && x <= (left + right) / 2 && y >= top && y <= bottom;
};

export const rightHalfOfEl = (fieldEl, { x, y }) => {
  const { top, bottom, left, right } = fieldEl.getBoundingClientRect();
  return x <= right && x >= (left + right) / 2 && y >= top && y <= bottom;
};

export const rightOfEl = (fieldEl, { x, y }) => {
  const { top, bottom, right } = fieldEl.getBoundingClientRect();
  return x >= right && y >= top && y <= bottom;
};

// Adjust mouse coordinates to be relative to viewport.

export const getMouseX = (ev) => {
  const scrollLeft =
    document.documentElement.scrollLeft || document.body.scrollLeft;
  return ev.pageX - scrollLeft;
};

export const getMouseY = (ev) => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;
  return ev.pageY - scrollTop;
};

// If the dropzone will not cause the dragging item to change position, return null.
export const unlessItem = (item, items, dropzone) => {
  const itemIndex = items.findIndex((it) => item.id === it.id);
  const dropzoneIndex = items.findIndex((it) => dropzone.id === it.id);
  if (itemIndex === -1) {
    return dropzone;
  }
  if (dropzoneIndex === -1) {
    return null;
  }
  if (
    itemIndex === dropzoneIndex ||
    (dropzone.position === 'after' && itemIndex === dropzoneIndex + 1) ||
    (dropzone.position === 'before' && itemIndex === dropzoneIndex - 1)
  ) {
    return null;
  }
  return dropzone;
};

// Inspect the mouse coordinates as it relates to the items in the DOM,
// and determine which item the drop is going to be before or after.
export const getNaiveDropzone = ({
  colsPerRow = 1,
  draggingItemCols = 1,
  items: unskippedItems,
  skip = () => false,
  selector,
  event,
  shift = 0,
  elementSelector,
}) => {
  const x = getMouseX(event);
  const y = getMouseY(event) + shift;

  const items = unskippedItems.filter((it) => !skip(it));

  if (items.length === 0) {
    return { position: 'first', direction: 'horizontal' };
  }

  /*
   * This routine consists of two loops:
   *
   *   1. The first (below) checks for a. overlap with other items and
   *      b. being above the first item or below the last.
   *
   *   2. The second loop checks for overlapping to the right of an element,
   *      i.e. a gap in a row.
   *
   * In both cases we care above whether the overlap is on the top, bottom, left, or
   * right half of the item in order to determine whether to place the dropzone before
   * or after that item.
   *
   */
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    let itemEl;
    if (elementSelector) {
      itemEl = event.currentTarget.querySelectorAll(elementSelector(i))?.[0];

      if (!itemEl) {
        continue;
      }
    } else {
      const itemEls = [
        ...event.currentTarget.querySelectorAll(selector),
      ].filter((_, i) => !skip(unskippedItems[i]));
      itemEl = itemEls[i];
    }

    if (i === 0 && aboveFieldEl(itemEl, { x, y })) {
      return {
        id: item.id,
        position: 'before',
      };
    }

    if (
      getItemCols(item) === colsPerRow || draggingItemCols === colsPerRow
        ? topHalfOfEl(itemEl, { x, y })
        : leftHalfOfEl(itemEl, { x, y })
    ) {
      return {
        id: item.id,
        position: 'before',
      };
    }

    if (
      getItemCols(item) === colsPerRow || draggingItemCols === colsPerRow
        ? bottomHalfOfEl(itemEl, { x, y })
        : rightHalfOfEl(itemEl, { x, y })
    ) {
      return {
        id: item.id,
        position: 'after',
      };
    }

    if (i === items.length - 1 && belowItemEl(itemEl, { x, y })) {
      return {
        id: item.id,
        position: 'after',
      };
    }
  }
  // As described above, this second loop looks for gaps on a row
  // where the item may be placed. We only check this after looking
  // for direct overlap with items.
  const reverseItems = [...items].reverse();
  for (let i = 0; i < reverseItems.length; i += 1) {
    const item = reverseItems[i];
    let itemEl;
    if (elementSelector) {
      itemEl = event.currentTarget.querySelectorAll(elementSelector(i))?.[0];

      if (!itemEl) {
        continue;
      }
    } else {
      const itemEls = [
        ...event.currentTarget.querySelectorAll(selector),
      ].filter((_, i) => !skip(unskippedItems[i]));
      const reverseItemEls = [...itemEls].reverse();
      itemEl = reverseItemEls[i];
    }

    if (
      draggingItemCols === colsPerRow &&
      topHalfOfRightOfEl(itemEl, { x, y })
    ) {
      return {
        id: item.id,
        position: 'before',
      };
    }

    if (
      draggingItemCols === colsPerRow &&
      bottomHalfOfRightOfEl(itemEl, { x, y })
    ) {
      return {
        id: item.id,
        position: 'after',
      };
    }

    if (draggingItemCols !== colsPerRow && rightOfEl(itemEl, { x, y })) {
      return {
        id: item.id,
        position: 'after',
      };
    }
  }

  return null;
};

// Take lists of source items and destination items, a dropzone, and the
// dragging item, and return the updated lists of source and destination items.
export const applyDropzone = (
  item,
  [fromItems, toItems = fromItems],
  dropzone
) => {
  const from = [...fromItems];
  const to = fromItems === toItems ? from : [...toItems];
  const itemIndex = from.findIndex((it) => item.id === it.id);
  const itemSplice = itemIndex === -1 ? [item] : from.splice(itemIndex, 1);
  const dropzoneIndex =
    dropzone.position === 'first'
      ? 0
      : to.findIndex((it) => dropzone.id === it.id) +
        (dropzone.position === 'before' ? 0 : 1);
  to.splice(dropzoneIndex, 0, ...itemSplice);
  return [from, to];
};

export const getItemDropzone = (
  draggingItem,
  items,
  selector,
  skipField,
  event,
  shift
) => {
  const naiveDropzone = getNaiveDropzone({
    items,
    skip:
      typeof skipField === 'function' ? skipField : (item) => item[skipField],
    selector,
    event,
    shift,
  });
  return naiveDropzone && unlessItem(draggingItem, items, naiveDropzone);
};

// Logic for matching a dropzone against some criteria.
export const dropzoneIs = (dropzone, criteria) => {
  if (!dropzone) {
    return false;
  }
  if (typeof criteria.id !== 'undefined' && criteria.id !== dropzone.id) {
    return false;
  }
  if (
    typeof criteria.position !== 'undefined' &&
    criteria.position !== dropzone.position
  ) {
    return false;
  }
  if (
    typeof criteria.direction !== 'undefined' &&
    criteria.direction !== dropzone.direction
  ) {
    return false;
  }
  if (
    typeof criteria.sectionId !== 'undefined' &&
    criteria.sectionId !== dropzone.sectionId
  ) {
    return false;
  }
  return true;
};

// Given a list, set the order property on each item to reflect
// the order they appear in that list.
export const setItemOrder = (items) => {
  return items.map((item, i) => ({
    ...item,
    order: i + 1,
  }));
};

// Move hidden fields to the bottom of the list, preserving their relative order.
export const hiddenItemsToBottom = (items, field) => {
  return setItemOrder(
    stableSort(items, (itemA, itemB) => {
      return Number(itemA[field]) - Number(itemB[field]);
    })
  );
};

// Given a list, set the order property on each item to reflect
// the order they appear in that list.
export const setFieldOrder = (fields) => {
  return fields.map((field, i) => ({
    ...field,
    order: i + 1,
  }));
};

// Move hidden fields to the bottom of the list, preserving their relative order.
export const hiddenFieldsToBottom = (fields) => {
  return setFieldOrder(
    stableSort(fields, (fieldA, fieldB) => {
      return Number(fieldA.isHidden) - Number(fieldB.isHidden);
    })
  );
};
