import { useState, useEffect, forwardRef } from 'react';
import { namedToOption } from 'services/helpers';
import FieldService from 'services/FieldService';
import CustomObjectsService from 'services/CustomObjectsService';
import PipelineService from 'services/PipelineService';
import { multiSelectSortValue } from 'components/Inputs/MultiSelect';
import CoreTags, { transformTagsDown } from './CoreTags';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

const transformTagsUp = (values) =>
  values.map(({ value, label }) => ({ id: value, label }));

export const DynamicTags = forwardRef(
  (
    {
      fieldId, // removes fieldId from ...others to set it internally here
      fetchUrl = null,
      field,
      value: valueProp = EMPTY_ARRAY,
      onChange,
      serviceToUse: serviceToUseProp,
      objectId: objectIdProp = null,
      handleUpdateFieldOption,
      fieldType,
      placeholder,
      ...others
    },
    ref
  ) => {
    const [serviceToUse, setServiceToUse] = useState(
      serviceToUseProp || FieldService
    );
    const [objectId, setObjectId] = useState(objectIdProp);
    const [createdId, setCreatedId] = useState(field.id);

    // we need to create tags associated with the specific custom object
    useEffect(() => {
      if (
        field?.customObjectField?.customObject?.id &&
        field?.customObjectField?.customObject?.fetchUrl
      ) {
        setObjectId(field?.customObjectField?.customObject?.id);
        setCreatedId(field?.customObjectField?.id);
      } else if (field && objectIdProp) {
        setObjectId(objectIdProp);
        setCreatedId(field.id);
      }
      switch (field?.customObjectField?.customObject?.fetchUrl || fetchUrl) {
        case 'client':
          setServiceToUse(FieldService);
          break;

        case 'standard':
          setServiceToUse(CustomObjectsService);
          break;

        case 'pipeline':
          setServiceToUse(PipelineService);
          break;

        default:
          break;
      }
    }, [field, serviceToUseProp, objectIdProp, fetchUrl]);

    const value = transformTagsDown(valueProp);
    const handleSubmit = async (tagName) => {
      // Eagerly create dynamic tag so that its pill immediately clickable
      const tag = await serviceToUse.createDynamicTag({
        fieldId: createdId,
        name: tagName,
        objectId,
      });

      if (typeof handleUpdateFieldOption === 'function')
        handleUpdateFieldOption({ id: createdId, newOption: tag });
      onChange(
        transformTagsUp(multiSelectSortValue([...value, namedToOption(tag)]))
      );
    };

    return (
      <CoreTags
        ref={null}
        field={field}
        fieldId={createdId}
        value={value}
        onChange={onChange}
        fetchUrl={fetchUrl || 'clients'}
        validate={() => true}
        serviceToUse={serviceToUse}
        objectId={objectId}
        error={false}
        handleUpdateFieldOption={() => {}}
        fieldType={fieldType}
        handleSubmit={handleSubmit}
        transformTagsUp={transformTagsUp}
        placeholder={placeholder}
        {...others}
      />
    );
  }
);

export default DynamicTags;
DynamicTags.name = 'DynamicTags';
