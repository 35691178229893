import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobile, useWindowSize } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import { getSearchParam, setSearchParams } from 'hooks/useSearchParam';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { camelToSnakeCase, snakeToCamelCase } from 'services/helpers';
import {
  buildPage,
  setPageConfig,
  getBroadcasts,
  updateBroadcastSuccess,
} from 'store/libraryPage/library.redux';
import { setOpenMenuAbove } from 'components/Tables/helpers';
import SentMessageModal from 'components/Modals/SentMessage';
import DesktopLayout from './DesktopLayout';
import { actions } from './columns';
import { useTranslation } from 'react-i18next';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import LibraryService from 'services/LibraryService';
import { messageMapper } from '../../helpers';
import EmailInteractionStatsModal from 'components/Modals/EmailInteractionStats';
import { deferExecution } from 'utility/defer';
import { getBusinessTimeZone } from 'store/authentication/selectors';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

const BroadcastsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();

  const [chosenMessage, setChosenMessage] = useState(null);
  const [statsMessage, setStatsMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isFetching = useSelector((s) => s.libraryPage.isFetching);
  const pageConfig = useSelector((s) => s.libraryPage.pageConfig.broadcasts);
  const broadcasts = useSelector((s) => s.libraryPage.broadcasts);
  const broadcastsCount = useSelector((s) => s.libraryPage.broadcastsCount);

  const businessTimezone = useSelector(getBusinessTimeZone);
  // This ref handles some wonky state management going on (should be rewritten entirely).
  // - Filter state is stored in redux and folder state comes from the useBreadcrumbs hook.
  // - Local directoryPage state exists because we never store those in the page config.
  // - The table pagination component is reused for all folder tables and has an effect that calls
  //   the handleChangePageNumber when the page number and options don't line up. When this happens
  //   the current folder state is not current since that is updated via a callback passed to in the
  //   redux action and only called at the end of the saga.
  const navigatingBackRef = useRef(false);

  const businessName = useSelector(
    ({ authentication }) => authentication?.chosenBusiness?.name ?? ''
  );

  const { directories, handleChangeDirectory, parentFolder, currentFolder } =
    useBreadcrumbs({
      name: t('Broadcasts'),
    });

  const broadcastsWithMeta = useMemo(() => {
    if (!broadcasts) {
      return broadcasts;
    }

    const backToParent = directories.length > 1 && {
      id: parentFolder.id,
      name: `${t('Back to')} '${parentFolder.name}'`,
      type: 'folder', // can be either text or email
      levelUp: true,
      dateCreated: (
        <DateTimeInputInline readOnly value={Date.now()} showDateTooltip />
      ),
    };

    return (backToParent ? [backToParent] : []).concat(
      broadcasts.map(setOpenMenuAbove)
    );
  }, [broadcasts, parentFolder, directories, t]);

  const onChangeDirectory = useCallback(
    (folder) => {
      if (folder?.type !== 'folder') {
        navigatingBackRef.current = true;
      }
      const page = folder?.type === 'folder' ? 1 : null;
      const callback = () => handleChangeDirectory(folder);
      dispatch(getBroadcasts({ ...folder, callback, page }));
    },
    [dispatch, handleChangeDirectory]
  );

  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');
    const search = getSearchParam(history.location, 'q');
    syncDispatch(
      buildPage({
        page: {
          type: 'broadcasts',
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
          search,
        },
      })
    );
  }, [syncDispatch, history]);

  // memo functions ------------------------------------------------
  const sortValue = useMemo(
    () =>
      getSortValue(
        snakeToCamelCase(
          currentFolder?.id === null
            ? pageConfig.sort
            : pageConfig.singleSendsSort
        )
      ),
    [currentFolder?.id, pageConfig.singleSendsSort, pageConfig.sort]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      if (!navigatingBackRef.current) {
        dispatch(
          setPageConfig({
            type: 'broadcasts',
            [currentFolder?.id === null ? 'page' : 'singleSendsPage']: value,
          })
        );
        dispatch(
          getBroadcasts({
            ...currentFolder,
          })
        );
        if (currentFolder?.id === null) {
          deferExecution(() => setSearchParams(history, { page: value }));
        }
      }
      navigatingBackRef.current = false;
    },
    [dispatch, currentFolder, history]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(
        setPageConfig({
          type: 'broadcasts',
          [currentFolder?.id === null ? 'size' : 'singleSendsSize']: value,
        })
      );
      dispatch(
        getBroadcasts({
          ...currentFolder,
        })
      );

      if (currentFolder?.id === null) {
        deferExecution(() => {
          setSearchParams(history, {
            size: value,
          });
        });
      }
    },
    [history, dispatch, currentFolder]
  );

  const handleChangeSearch = useCallback(
    (q) => {
      dispatch(
        setPageConfig({
          type: 'broadcasts',
          [currentFolder?.id === null ? 'search' : 'singleSendsSearch']: q,
          page: 1,
        })
      );
      dispatch(getBroadcasts(currentFolder));
      if (currentFolder?.id === null) {
        deferExecution(() => {
          setSearchParams(history, { q, page: null }, { method: 'replace' });
        });
      }
    },
    [currentFolder, dispatch, history]
  );

  const handleSortTable = useCallback(
    ({ column, direction }) => {
      const data = camelToSnakeCase(column);
      const value = getOrderingParam({ column: data, direction });

      dispatch(
        setPageConfig({
          type: 'broadcasts',
          [currentFolder?.id === null ? 'sort' : 'singleSendsSort']: value,
        })
      );
      dispatch(
        getBroadcasts({
          ...currentFolder,
        })
      );

      if (currentFolder?.id === null) {
        deferExecution(() => {
          setSearchParams(history, {
            sort: value,
          });
        });
      }
    },
    [currentFolder, dispatch, history]
  );

  const handleActionView = useCallback(
    async (message) => {
      setIsLoading(true);
      setChosenMessage({
        ...messageMapper(message, businessName, t),
        bodySanitized: true,
      });
      try {
        const data = await LibraryService.getBroadcastById({
          id: message.id,
        });
        setChosenMessage(
          messageMapper(data, businessName, t, { hideDynamicImages: true })
        );
      } catch (err) {
        setChosenMessage();
      } finally {
        setIsLoading(false);
      }
    },
    [businessName, t]
  );

  const handleActionStats = useCallback(
    async (message) => {
      setIsLoading(false);
      setStatsMessage({
        ...messageMapper(message, businessName, t),
        bodySanitized: true,
      });
    },
    [businessName, t]
  );

  const handleSelectAction = useCallback(
    async ({ value }, message) => {
      switch (value) {
        case actions.view: {
          handleActionView(message);
          break;
        }
        case actions.stats: {
          handleActionStats(message);
          break;
        }
        default: {
        }
      }
    },
    [handleActionView, handleActionStats]
  );

  const hideModal = () => {
    setChosenMessage(null);
    setStatsMessage(null);
  };

  // columns handlers
  const handleUpdateBroadcast = useCallback(
    async (patch) => {
      try {
        const updatedBroadcast = await LibraryService.updateBroadcast(patch);
        dispatch(updateBroadcastSuccess(updatedBroadcast));
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = Object.entries(err.response.data.errors);
          if (errors && errors.length) {
            // we show only 1st error
            error = new Error(errors[0][1]);
          }
        }
        throw error;
      }
    },
    [dispatch]
  );

  if (isFetching) {
    return <Loader loading />;
  }

  return (
    <>
      {isMobile(width) ? (
        <></>
      ) : (
        <>
          <DesktopLayout
            search={
              currentFolder?.id !== null
                ? pageConfig.singleSendsSearch
                : pageConfig.search
            }
            onChangeSearch={handleChangeSearch}
            parentFolder={parentFolder}
            broadcasts={broadcastsWithMeta}
            broadcastsCount={broadcastsCount}
            pageNumber={
              currentFolder?.id ? pageConfig.singleSendsPage : pageConfig.page
            }
            pageSize={
              currentFolder?.id ? pageConfig.singleSendsSize : pageConfig.size
            }
            handleChangePageNumber={handleChangePageNumber}
            handleChangePageSize={handleChangePageSize}
            sort={sortValue}
            handleChangeSort={handleSortTable}
            handleSelectAction={handleSelectAction}
            timezone={businessTimezone}
            directories={directories}
            onChangeDirectory={onChangeDirectory}
            handleUpdateBroadcast={handleUpdateBroadcast}
          />
          {chosenMessage && (
            <SentMessageModal
              show
              isLoading={isLoading}
              message={chosenMessage}
              onHide={hideModal}
            />
          )}
          {statsMessage && (
            <EmailInteractionStatsModal
              show
              isLoading={isLoading}
              message={statsMessage}
              sandbox="allow-popups"
              onHide={hideModal}
            />
          )}
        </>
      )}
    </>
  );
};
export default BroadcastsPage;
