import {
  Access,
  CustomFieldProps,
  FormFieldProps,
  ImageProps,
  RootProps,
  RowProps,
  SectionProps,
  TextProps,
  PageBuilderButton,
  PageBuilderCell,
  PageBuilderCustomField,
  PageBuilderFormField,
  PageBuilderImage,
  PageBuilderRoot,
  PageBuilderRow,
  PageBuilderSection,
  PageBuilderText,
} from '../types';
import { Node, NodeProps, NodeType } from './types';
import { ButtonNodeProps } from './nodes/button';

const DefaultAccess: Access = { edit: true, view: true, remove: false };

export const createButton = (
  props: Partial<ButtonNodeProps>
): Omit<PageBuilderButton, 'parent'> => {
  const { isSubmitButton, ...rest } = props;
  return {
    custom: { isSubmitButton: isSubmitButton ?? false },
    hidden: false,
    isCanvas: false,
    linkedNodes: {},
    nodes: [],
    props: rest,
    type: { resolvedName: 'Button' },
  };
};

export const createCell = (parent: string): PageBuilderCell => ({
  parent,
  custom: {},
  hidden: false,
  isCanvas: true,
  linkedNodes: {},
  props: {},
  type: { resolvedName: 'Cell' },
  nodes: [],
});

export const createCustomField = (
  props: Partial<CustomFieldProps>
): Omit<PageBuilderCustomField, 'parent'> => ({
  custom: {},
  hidden: false,
  isCanvas: false,
  linkedNodes: {},
  nodes: [],
  props: {
    ...props,
    field: props.field
      ? {
          ...props.field,
          fieldType: props.field?.customObjectField?.fieldType,
          access: props.field?.access || DefaultAccess,
        }
      : undefined,
  },
  type: { resolvedName: 'CustomField' },
});

export const createFormField = (
  props: Partial<FormFieldProps>
): Omit<PageBuilderFormField, 'parent'> => ({
  custom: {},
  hidden: false,
  isCanvas: false,
  linkedNodes: {},
  nodes: [],
  props: {
    ...props,
    field: props.field
      ? { ...props.field, access: props.field?.access || DefaultAccess }
      : undefined,
  },
  type: { resolvedName: 'FormField' },
});

export const createImage = (
  props: Partial<ImageProps>,
  custom: Record<string, any> = {}
): Omit<PageBuilderImage, 'parent'> => ({
  custom,
  hidden: false,
  isCanvas: false,
  linkedNodes: {},
  nodes: [],
  props,
  type: { resolvedName: 'Image' },
});

export const createRoot = (props: Partial<RootProps>): PageBuilderRoot => ({
  custom: {},
  hidden: false,
  id: 'ROOT',
  isCanvas: true,
  linkedNodes: {},
  nodes: [],
  parent: null,
  props,
  type: { resolvedName: 'Root' },
});

export const createRow = (
  props: Partial<RowProps>
): Omit<PageBuilderRow, 'parent'> => ({
  custom: {},
  hidden: false,
  isCanvas: false,
  linkedNodes: {},
  nodes: [],
  props,
  type: { resolvedName: 'Row' },
});

export const createSection = (
  props: Partial<SectionProps>
): Omit<PageBuilderSection, 'parent'> => ({
  custom: {},
  hidden: false,
  isCanvas: true,
  linkedNodes: {},
  nodes: [],
  props,
  type: { resolvedName: 'Section' },
});

export const createText = (
  text: string | undefined,
  props: Partial<TextProps>,
  custom?: Record<string, any>
): Omit<PageBuilderText, 'parent'> => ({
  custom: { text: text || '', ...custom },
  hidden: false,
  isCanvas: false,
  linkedNodes: {},
  nodes: [],
  props,
  type: { resolvedName: 'Text' },
});

export const parseNode = <T extends NodeType>(
  node: Node,
  props?: Partial<NodeProps[T]>
) => {
  switch (node[0]) {
    case 'Button':
      return createButton({ ...props, ...node[1] });
    case 'CustomField':
      return createCustomField({ ...props, ...node[1] });
    case 'FormField':
      return createFormField({ ...props, ...node[1] });
    case 'Image':
      return createImage({ ...props, ...node[1] });
    case 'Root':
      return createRoot({ ...props, ...node[1] });
    case 'Row':
      return createRow({ ...props, ...node[1] });
    case 'Section':
      return createSection({ ...props, ...node[1] });
    case 'Text':
      return createText(node[2], { ...props, ...node[1] });
    default:
      throw Error(`${node[0]} is not supported`);
  }
};
