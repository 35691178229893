import { createContext, useCallback } from 'react';
import { flushSync } from 'react-dom';
import {
  Provider as ReduxProvider,
  createSelectorHook,
  createDispatchHook,
} from 'react-redux';

// In order to use redux with react-flow, we need to use some custom context to avoid conflicts
const ReduxContext = createContext(null);

export default function AutomationsProvider(props) {
  return <ReduxProvider context={ReduxContext} {...props} />;
}

export const useAutomationsSelector = createSelectorHook(ReduxContext);
const usePlainAutomationsDispatch = createDispatchHook(ReduxContext);

// React 18 batches state updates for performance. This is generally good and gives us a
// performance boost. However, some places in the app rely on UI updates happening synchronously,
// so we need to use this special hook in those places.
export const useAutomationsDispatch = () => {
  const dispatch = usePlainAutomationsDispatch();

  const fn = useCallback(
    (...args) => {
      flushSync(() => {
        dispatch(...args);
      });
    },
    [dispatch]
  );

  return fn;
};
