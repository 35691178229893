export const TYPE_ROUND_ROBIN_ALL = 'round_robin_all';
export const TYPE_ROUND_ROBIN_ROLE = 'round_robin_role';
export const TYPE_ROUND_ROBIN_TEAM_MEMBERS = 'round_robin_team_members';
export const TYPE_TEAM_MEMBER = 'team_member';

export const assignmentTypeOptionsTranslated = (t) => [
  {
    label: t('Round Robin (Any Role)'),
    value: TYPE_ROUND_ROBIN_ALL,
  },
  {
    label: t('Round Robin (Specific Role)'),
    value: TYPE_ROUND_ROBIN_ROLE,
  },
  {
    label: t('Round Robin (Choose Team Members)'),
    value: TYPE_ROUND_ROBIN_TEAM_MEMBERS,
  },
  {
    label: t('Specific Team Member'),
    value: TYPE_TEAM_MEMBER,
  },
];

export const dictionaryAassignmentTypeOptionsTranslated = (t) =>
  assignmentTypeOptionsTranslated(t).reduce(
    (acc, { label, value }) => ({
      ...acc,
      [value]: { value, label },
    }),
    {}
  );
