import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Kizen/Loader';
import FormService from 'services/FormService';
import { setSearchParams, getSearchParam } from 'hooks/useSearchParam';
import { isMobile, useWindowSize } from 'app/spacing';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { setOpenMenuAbove } from 'components/Tables/helpers';
import useModal from 'components/Modals/useModal';
import {
  buildPage,
  getForms,
  updatePageConfig,
  updateFormFieldSuccess,
  duplicateForm,
  deleteForm,
  cleanDeleteFormToastMessage,
  selectAddFormModels,
  selectForms,
  selectFormsCount,
  selectFormsPageConfig,
  selectFormsDeleteToastMessage,
  addNewForm,
  formsIsFetching,
} from 'store/formsPage/forms.redux';
import FormsTabularPage from './TabularPage';
import FormsListPage from './ListPage';
import AddModalComponent from './AddModal';
import { useTranslation } from 'react-i18next';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useToast } from 'components/ToastProvider';
import { useDebounce } from 'react-use';
import { maybeTrackBuilderPreview, maybeTrackSearch } from 'utility/analytics';
import { DEFAULT_DELAY } from 'utility/config';
import { deferExecution } from 'utility/defer';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

export const previewForm = (...args) => {
  const preview =
    (businessUrl) =>
    (slug, windowName = '_blank') => {
      maybeTrackBuilderPreview('form', slug);
      const win = window.open(`${businessUrl}/form/${slug}`, windowName);
      win.focus();
    };

  const [url, slug, windowName] = args;
  return slug ? preview(url)(slug, windowName) : preview(url);
};

export default function FormsPage(props) {
  const { t } = useTranslation();
  useSetTitleOnLoad(props.title(t));
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();
  const [showToast] = useToast();

  // TODO: when hosting standalone forms we'll want to use the configurable subdomain from Settings -> Business Information
  const chosenBusinessUrl = `https://${import.meta.env.VITE_EMBED_FRONTEND_DOMAIN}`;
  // const chosenBusinessUrl = useSelector(
  //   ({ authentication }) =>
  //     !!authentication.chosenBusiness && authentication.chosenBusiness.url
  // );

  const models = useSelector(selectAddFormModels);
  const pageConfig = useSelector(selectFormsPageConfig);
  const formsCount = useSelector(selectFormsCount);
  const formsWithoutMeta = useSelector(selectForms);
  const isFetching = useSelector(formsIsFetching);

  // Track debounced changes to the search term and send to google analytics
  const { search } = pageConfig;
  useDebounce(() => maybeTrackSearch(search, 'forms'), DEFAULT_DELAY, [search]);

  // async effects -------------------------------------------------
  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const search = getSearchParam(history.location, 'q');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');

    syncDispatch(
      buildPage({
        page: {
          search,
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
        },
      })
    );
  }, [syncDispatch, history]);

  const forms = useMemo(() => {
    return formsWithoutMeta && formsWithoutMeta.map(setOpenMenuAbove);
  }, [formsWithoutMeta]);

  const handleChangeSearch = useCallback(
    (q) => {
      dispatch(
        updatePageConfig({
          search: q,
          page: 1,
        })
      );
      dispatch(getForms());
      deferExecution(() => {
        setSearchParams(history, { q, page: null }, { method: 'replace' });
      });
    },
    [dispatch, history]
  );

  const sortValue = useMemo(
    () => getSortValue(pageConfig.sort),
    [pageConfig.sort]
  );

  const handleSort = useCallback(
    ({ column, direction }) => {
      const value = getOrderingParam({
        column,
        direction,
      });

      dispatch(
        updatePageConfig({
          sort: value,
        })
      );
      dispatch(getForms());
      deferExecution(() => {
        setSearchParams(
          history,
          {
            sort: value,
          },
          { method: 'replace' }
        );
      });
    },
    [dispatch, history]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          page: value,
        })
      );
      dispatch(getForms());
      deferExecution(() => {
        setSearchParams(history, {
          page: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          size: value,
        })
      );
      dispatch(getForms());
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleUpdateFormField = useCallback(
    async (form, patch) => {
      try {
        const updatedForm = await FormService.update(form.id, patch);
        dispatch(updateFormFieldSuccess(updatedForm));
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = Object.entries(err.response.data.errors);
          if (errors && errors.length) {
            // we show only 1st error
            error = new Error(errors[0][1]);
          }
        }
        throw error;
      }
    },
    [dispatch]
  );

  const handleEditForm = useCallback(
    (id) => {
      history.push(`/forms/${id}`);
    },
    [history]
  );

  const handleDuplicateForm = (id) => dispatch(duplicateForm(id));

  const handleConfirmDeleteForm = (id) => dispatch(deleteForm(id));

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const handleDeleteForm = (id) => setDeleteConfirmation({ id });

  const formDeleteToastMessage = useSelector(selectFormsDeleteToastMessage);
  useEffect(() => {
    if (formDeleteToastMessage) {
      showToast({
        variant: formDeleteToastMessage.variant,
        message: formDeleteToastMessage.message,
      });
      dispatch(cleanDeleteFormToastMessage());
    }
  }, [dispatch, formDeleteToastMessage, showToast]);

  const handleCreate = useCallback(
    async (newForm) => {
      dispatch(addNewForm(newForm));
      handleEditForm(newForm.id);
    },
    [dispatch, handleEditForm]
  );

  const [addModalProps, , addModal] = useModal({
    handleSubmit: handleCreate,
  });

  if (isFetching) {
    return <Loader loading />;
  }
  return (
    <>
      {deleteConfirmation && (
        <ConfirmDeletionModal
          show
          onHide={() => setDeleteConfirmation(null)}
          onConfirm={async () => {
            const { id } = deleteConfirmation;
            try {
              await handleConfirmDeleteForm(id);
            } finally {
              setDeleteConfirmation(null);
            }
          }}
        >
          {t('This will permanently delete the Form.')}
        </ConfirmDeletionModal>
      )}
      {isMobile(width) ? (
        <FormsListPage
          forms={forms || []}
          formsCount={formsCount}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSort}
          pageNumber={pageConfig.page || 1}
          pageSize={pageConfig.size || 50}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onSelectPreviewForm={previewForm(chosenBusinessUrl)}
          onSelectDeleteForm={handleDeleteForm}
          {...props}
        />
      ) : (
        <FormsTabularPage
          forms={forms || []}
          formsCount={formsCount}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSort}
          pageNumber={pageConfig.page || 1}
          pageSize={pageConfig.size || 50}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onUpdateFormField={handleUpdateFormField}
          onSelectEditForm={handleEditForm}
          onSelectPreviewForm={previewForm(chosenBusinessUrl)}
          onSelectDuplicateForm={handleDuplicateForm}
          onSelectDeleteForm={handleDeleteForm}
          openAddModal={addModal.show}
          {...props}
        />
      )}
      {addModal.show && (
        <AddModalComponent models={models} {...addModalProps} />
      )}
    </>
  );
}
