import React, { useEffect, useState } from 'react';
import { css, Global } from '@emotion/core';
import { grayScale } from './colors';
import { baseFontSize, baselineConstant, fontSizes } from './typography';
import { SUPPORTED_NESTED_MODALS, zIndexModalsControl } from './modalBackdrop';

export const breakpoints = {
  xs: 0,
  sm: 575,
  md: 767,
  lg: 991,
  xl: 1402,
};

export const displayBreakpoints = {
  mobile: breakpoints.xs,
  desktop: breakpoints.md,
};

export const getViewportSize = () => {
  if (typeof window === 'object') {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  return null;
};

export const useWindowSize = () => {
  const isClient = typeof window === 'object';
  const [windowSize, setWindowSize] = useState(getViewportSize);

  useEffect(() => {
    if (!isClient) return false;
    const handleResize = () => {
      setWindowSize(getViewportSize());
    };
    window.addEventListener('resize', handleResize, { passive: true });
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]);

  return windowSize;
};

export const isMobile = (width, breakpoint = breakpoints.lg) =>
  width <= breakpoint;

export const useBreakpoint = (breakpoint) => {
  const { width } = useWindowSize();
  return isMobile(width, breakpoint);
};

export const Spacing = () => {
  return (
    <Global
      styles={css`
        :root {
          --breakpoint-xs: ${breakpoints.xs}px;
          --breakpoint-sm: ${breakpoints.sm}px;
          --breakpoint-md: ${breakpoints.md}px;
          --breakpoint-lg: ${breakpoints.lg}px;
          --breakpoint-xl: ${breakpoints.xl}px;
        }
        @media (min-width: ${breakpoints.xl}px) {
          .container {
            max-width: ${breakpoints.xl}px;
          }
        }
      `}
    />
  );
};

const px = (val) => {
  if (val === Number(val)) {
    return val;
  }
  if (typeof val !== 'string') {
    return 0;
  }
  if (val.endsWith('em')) {
    return parseFloat(val) * baseFontSize;
  }
  return parseFloat(val);
};

const percentage = (val) => {
  if (val === Number(val)) {
    return val;
  }
  if (typeof val !== 'string') {
    return 1;
  }
  if (val.endsWith('%')) {
    return parseFloat(val) / 100;
  }
  return parseFloat(val);
};

/*
 * Example usage
 *
 * Add spacing in 5px increments:
 *   gutters.spacing(1) === gutters.spacing() === 5
 *   gutters.spacing(3) === 15
 * Add spacing with a fudge factor (em or pixels):
 *   gutters.spacing(3, '1em') === 25
 * Add spacing, taking into account the font's baseline (usually top/bottom margins):
 *   gutters.spacing(3, { baseline: true }) === 12
 * Same as the above, but with a custom font size:
 *   gutters.spacing(3, { baseline: fontSizes.header }) === 9.857...
 * Same as the above, but with both custom font size and line height:
 *   gutters.spacing(3, { baseline: fontSizes.header, lineHeight: 1.5 }) === 3.857...
 */

const gutterSpacing = (n = 1, fudge) => {
  let fudgePx = 0;
  if (typeof fudge !== 'undefined') {
    if (fudge && typeof fudge === 'object') {
      // Calculate fudge factor to account for font baseline.
      // Typically used to adjust bottom margins and padding to maintain a baseline-ish grid.
      const { baseline = 0, lineHeight = 1 } = fudge;
      // Currently lineHeight can only be a percentage.
      fudgePx = -(
        px(baseline === true ? fontSizes.text : baseline) *
        ((percentage(lineHeight) - 1) / 2 + baselineConstant)
      );
    } else {
      fudgePx = px(fudge);
    }
  }
  return n * 5 + fudgePx;
};

export const zIndexLayersControl = (layer = 0, fudge = 0) => {
  return layer * 10 + fudge;
};

export const gutters = {
  spacing: gutterSpacing,
  standard: `${gutterSpacing(3)}px`,
  large: `${gutterSpacing(6)}px`,
};

export const FILTER_MENU_Z_INDEX = zIndexLayersControl(10);

export const layers = {
  content: zIndexLayersControl,
  modals: zIndexModalsControl,
  modal: zIndexModalsControl(),
  popoverInModal: zIndexModalsControl(1, 5), // 2055
  toast: zIndexModalsControl(SUPPORTED_NESTED_MODALS + 2),
  overlay: FILTER_MENU_Z_INDEX,
  validation: zIndexModalsControl(SUPPORTED_NESTED_MODALS + 1),
  blocker: zIndexModalsControl(SUPPORTED_NESTED_MODALS + 3),
};

// When changing this, note the funky usage in Charts/ScheduledActivities/NotesPanel
// where we need the styles injected into an iframe, taking advantage of the use of "&" here.
export const scrollbarCss = ({ track = false } = {}) => css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    margin-top: 4px;
    width: 2.4px;
    border-radius: 8px;
    background-color: ${grayScale.mediumDark};
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ${track &&
  css`
    &::-webkit-scrollbar-track {
      background-color: ${grayScale.mediumLight};
    }
  `}
`;

export const hideScrollbarCss = css`
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // Internet Explorer 10+
  &::-webkit-scrollbar {
    // WebKit
    width: 0;
    height: 0;
  }
`;

export const spaceBetweenGutters = ({ columns, gutter }) => {
  return `calc((100% - (${columns} - 1) * ${gutter}) / ${columns})`;
};
export const borderRadii = {
  tiny: '2px',
  small: '4px',
  standard: '8px',
};

// https://kovart.github.io/dashed-border-generator/
export const dashedBorderCss = ({ dashLength, color }) => css`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect
  width='100%25' height='100%25' fill='none'
  stroke='${encodeURIComponent(color)}'
  stroke-width='2' stroke-dasharray='${dashLength || 0}'
  stroke-dashoffset='1' stroke-linecap='butt'/%3e%3c/svg%3e");
`;

export const resetButtonCss = ({ keepBorder = false, padding = '0' }) => css`
  && {
    background: none;
    color: inherit;
    ${!keepBorder &&
    css`
      border: none;
    `}

    padding: ${padding};
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
`;
