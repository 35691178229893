import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

const EMPTY_OBJECT = {};
const DUMMY_FN = (n) => n;

export const useSetTitleOnLoad = (title, dinamic, parseFn = DUMMY_FN) => {
  useEffect(() => {
    document.title = parseFn(title, dinamic || EMPTY_OBJECT);
  }, [title, dinamic, parseFn]);
};

export const RouteWithTitle = ({
  exact,
  path,
  component: Component,
  title,
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => <Component {...props} title={title} />}
    />
  );
};
