import { createSlice } from '@reduxjs/toolkit';
import { initialStore } from '../initialStore';

// Slices
const defaultState = initialStore.customObjectsSettingPage;

const customObjectsSettingsSlice = createSlice({
  name: 'customObjectsSettings',
  initialState: defaultState,
  reducers: {
    clearChosenObject: (state) => {
      state.chosenObject = null;
    },
    getCustomObjects: (state) => {
      state.loadingObjects = true;
    },
    getCustomObjectsSuccess: (state, { payload }) => {
      state.objects = payload;
      state.loadingObjects = false;
    },
    getCustomObjectsFail: (state) => {
      state.objects = [];
      state.loadingObjects = false;
    },
    setChosenObject: (state, { payload }) => {
      state.chosenObject = payload;
    },
    getCategories: (state) => {
      state.loadingObjectFields = true;
    },
    getCategoriesSuccess: (state, { payload }) => {
      state.categories = payload;
      state.loadingObjectFields = false;
    },
    getCategoriesFail: (state) => {
      state.loadingObjectFields = false;
    },
  },
});

export const {
  clearChosenObject,
  getCustomObjects,
  getCustomObjectsSuccess,
  getCustomObjectsFail,
  setChosenObject,
  getCategories,
  getCategoriesSuccess,
  getCategoriesFail,
} = customObjectsSettingsSlice.actions;

export const { reducer } = customObjectsSettingsSlice;

export const selectEditableObjects = (state) => {
  const objects = state?.customObjectsSettingsPage?.objects;
  return Array.isArray(objects) ? objects.filter((obj) => obj.access.edit) : [];
};
