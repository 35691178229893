export const ACTIONS = {
  QUERY_REQUEST: 'query:request',
  UI_OUTPUT: 'outputui',
  IFRAME_OUTPUT: 'iframeoutput',
  POSTFORMDATA_REQUEST: 'postformdata:request',
  SETSTATE: 'setstate',
  DONE: 'done',
  RUN: 'run',
  OPEN_WINDOW: 'openwindow',
  COMMUNICATE: 'communicate',
  HIDE: 'hide',
  SHOW: 'show',
  EXPAND: 'expand',
  COLLAPSE: 'collapse',
  SHOW_TOAST: 'showtoast',
  CLEAR_TOASTS: 'cleartoasts',
  REFRESH_TIMELINE: 'refreshtimeline',
};

export const RESPONSES = {
  QUERY_RESPONSE: 'query:response',
  POSTFORMDATA_RESPONSE: 'postformdata:response',
  ERROR: 'error',
};

export const COMMUNICATIONS = {
  RUN_FRAME_SCRIPT: 'runframescript',
  SEND_MESSAGE_TO_FRAME: 'sendmessagetoframe',
};
