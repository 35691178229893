import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  ChartLayout,
  Chart,
  StyledLoader,
  Legend,
  LegendContent,
  LegendEntry,
  CircularMarker,
  DashedMarker,
  DashedMarkerChild,
  GradientMarker,
  GradientChild,
  ChartOverlay,
} from './styles';
import LeaderboardChartManager from './LeaderboardChartManager';
import NoData from '../components/NoData';
import { useTranslation } from 'react-i18next';
import { StyledNoData } from '../components/NoData/styles';
import { DISPLAY_VALUE_RESPONSES } from 'components/Wizards/Dashlet/types';
import usePipelineMetadata from 'pages/Dashboard/hooks/usePipelineMetadata';
import { getPendingDefaultValue } from '../helpers';
import { isEqual } from 'lodash';
import { sanitizeTextForCharts } from '../ChartManager';

const LeaderboardChart = ({ data, dashlet, isLoading, mobile }) => {
  const { t } = useTranslation();
  const chartRef = useRef();
  const overlayRef = useRef(null);
  const overlayVisibilityRef = useRef(false);
  const [chartRenderKey, setChartRenderKey] = useState(() => Date.now());
  const [chartPending, setChartPending] = useState(getPendingDefaultValue);

  const chartData = useMemo(() => {
    if (data.data) {
      const employeeMetadataByKey =
        data.metadata?.employees?.reduce((acc, curr) => {
          acc[curr.id] = {
            ...curr,
            full_name: sanitizeTextForCharts(curr.full_name),
          };
          return acc;
        }, {}) ?? {};

      const stageMetadataByKey =
        data.metadata?.stages?.reduce((acc, curr) => {
          acc[curr.id] = {
            ...curr,
            name: sanitizeTextForCharts(curr.name),
          };
          return acc;
        }, {}) ?? {};

      const result = data.data
        .map((person) => {
          return {
            employee_id: person.employee_id,
            type: person.type,
            fullName: employeeMetadataByKey[person.employee_id]?.full_name,
            values: person.values.map((v) => {
              if (v.type === 'open_stage_projections') {
                return {
                  ...v,
                  values: v.values.map((val) => {
                    return {
                      ...val,
                      stage_name: stageMetadataByKey[val.stage_id]?.name,
                    };
                  }),
                };
              }
              return v;
            }),
          };
        })
        .reverse(); // amcharts appears to draw the categories in reverse order
      return { result, employeeMetadataByKey };
    }
    return [];
  }, [data]);

  // Setting state outside the chart based on chart interaction causes problems with the
  // amcharts rendering mechanism. Since we only want to update a few styles based on the scrolling
  // state, we just update them directly on the fade container
  const handleScrollHeight = useCallback(
    (height) => {
      if (height > 0 && chartData.result.length > 6 && overlayRef.current) {
        overlayRef.current.style.visibility = 'visible';
        overlayRef.current.style.opacity = 1;
        overlayVisibilityRef.current = true;
      } else if (overlayRef.current) {
        overlayRef.current.style.visibility = 'hidden';
        overlayRef.current.style.opacity = 0;
        overlayVisibilityRef.current = false;
      }
    },
    [chartData]
  );

  // Setting state outside the chart based on chart interaction causes problems with the
  // amcharts rendering mechanism. Since we only want to update a few styles based on the
  // width of the plot, we just update them directly on the fade container
  const handleChartWidth = useCallback((width) => {
    if (overlayRef.current) {
      overlayRef.current.style.width = `${width}px`;
    }
  }, []);

  // Because the fade is outside the chart canvas, we need to hide it when tooltips are visible
  // on the chart, otherwise they'll appear behind it
  const handleTooltipVisibility = useCallback((visible) => {
    if (visible && overlayRef.current) {
      overlayRef.current.style.visibility = 'hidden';
      overlayRef.current.style.opacity = 0;

      // Only turn the fade back on if it was on before the user opened a tooltip
    } else if (overlayRef.current && overlayVisibilityRef.current) {
      overlayRef.current.style.visibility = 'visible';
      overlayRef.current.style.opacity = 1;
    }
  }, []);

  const isValue = dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE;

  const [pipeline, currencySymbol] = usePipelineMetadata(data);

  useEffect(() => {
    if (chartRef.current) {
      const manager = new LeaderboardChartManager(
        chartRef.current,
        t,
        chartData,
        {
          pipeline,
          mobile,
          currencySymbol,
          isValue,
        },
        () => setChartPending(false)
      );

      manager.init(
        handleScrollHeight,
        handleChartWidth,
        handleTooltipVisibility
      );

      return manager.destroy;
    }
  }, [
    chartData,
    pipeline,
    chartRenderKey,
    mobile,
    currencySymbol,
    isValue,
    handleScrollHeight,
    handleChartWidth,
    handleTooltipVisibility,
    t,
  ]);

  const createChart = useCallback((elem) => {
    if (elem) {
      chartRef.current = elem;
      setChartRenderKey(Date.now());
    }
  }, []);

  const isEmpty = chartData.result.length === 0;
  const isChartLoading = chartPending || isLoading;

  return (
    <ChartLayout>
      {/*
          This absolutely positioned loader is used to keep the chart area hidden until the calculations
          and rendering are done, as they can be quite slow for large datasets and will just
          show a blank white dashlet otherwise
        */}
      <StyledLoader loading={isChartLoading} />
      {chartData.result.length === 0 ? (
        <StyledNoData
          data-qa-chart="leaderboard"
          data-qa-chart-pending={isChartLoading}
          data-qa-dashlet-empty={isEmpty}
        >
          <NoData />
        </StyledNoData>
      ) : null}
      <Chart
        ref={createChart}
        data-qa-chart="leaderboard"
        data-qa-chart-pending={isChartLoading}
        data-qa-dashlet-empty={isEmpty}
      />
      <ChartOverlay ref={overlayRef} />
      <Legend>
        <LegendContent>
          <LegendEntry>
            <CircularMarker />
            {t('won')}
          </LegendEntry>
          {isValue ? (
            <LegendEntry>
              <DashedMarker>
                <DashedMarkerChild />
                <DashedMarkerChild />
                <DashedMarkerChild />
              </DashedMarker>
              {t('goal')}
            </LegendEntry>
          ) : null}
          <LegendEntry>
            <GradientMarker>
              <GradientChild />
              <GradientChild />
              <GradientChild />
            </GradientMarker>
            {t('forecast by stage')}
          </LegendEntry>
        </LegendContent>
      </Legend>
    </ChartLayout>
  );
};

LeaderboardChart.propTypes = {
  data: PropTypes.object,
  dashlet: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  mobile: PropTypes.bool,
};

LeaderboardChart.defaultProps = {
  data: { data: [] },
  isLoading: true,
  mobile: false,
};

const propsAreEqual = (prevProps, nextProps) => {
  const dataEqual = isEqual(prevProps.data, nextProps.data);
  const dashletEqual = isEqual(prevProps.dashlet, nextProps.dashlet);
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const mobileEqual = prevProps.mobile === nextProps.mobile;

  return dataEqual && dashletEqual && isLoadingEqual && mobileEqual;
};

export default React.memo(LeaderboardChart, propsAreEqual);
