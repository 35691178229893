import DraggableStepCard from '../../DraggableStepCard';
import { getNotifyMemberViaEmailFromString } from './action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  const { details } = step;
  const subject = details?.email?.subject || '';
  return `Notify ${getNotifyMemberViaEmailFromString(
    details.teamMember,
    t
  )} with Email '${subject}'`;
};

export default function NotifyMemberViaEmailCard({ step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
