import { useEffect } from 'react';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { can } from 'store/authentication/selectors';
import Loader from 'components/Kizen/Loader';
import paths from './paths';
import ZendeskIntegration, { HideZendescHOC } from '../app/ZendeskIntegration';
import { RedirectLocalStorage } from 'utility/RedirectLocalStorage';
import { oauthSessionStorage } from 'utility/_oauthSessionStorage';
import { useDispatch } from 'react-redux';
import { serviceLogoutStart } from 'store/authentication/authenticationAction';
import { getSearchParam, safeUrlStartsWith } from 'hooks/useSearchParam';
import {
  hasMultipleBusinesses,
  hasAnyBusinesses,
} from 'store/authentication/helpers';

const ALLOWED_BUSINESS_TYPES = ['internal_admin', 'partner'];

const getRedirect = () => {
  const localStorage = new RedirectLocalStorage('loginRedirect');
  return localStorage.path || '/';
};

const validateRedirect = (next) =>
  next ? next.toLowerCase().startsWith(safeUrlStartsWith) : next;

const hasValidOAuthRedirect = (location) => {
  const next = getSearchParam(location, 'next');
  return next && validateRedirect(next) ? next : null;
};

export const ForceLogOut = (Page) =>
  function Comp(props) {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(serviceLogoutStart({ skipApi: false }));
    }, [dispatch]);

    return <Page {...props} />;
  };

const ClearRedirect = (Page) =>
  function Comp(props) {
    useEffect(() => {
      const localStorage = new RedirectLocalStorage('loginRedirect');
      localStorage.clear();
    }, []);

    return <Page {...props} />;
  };
const Authenticating = (...args) => Loader({ ...args, loading: true });

const authenticatingSelector = ({ authentication }) =>
  // We check rehydrate so this component doesn't redirect on application mounting
  // , so someone with a valid token refreshing the page on a protected route,
  // e.g. the choose-business page, isn't redirected first to Login, which, when
  // auth completes, redirects them to '/' (login's redirect path)
  authentication.rehydrating || authentication.authenticating;

const authenticatedDefaults = {
  authenticatedSelector: ({ authentication }) => !!authentication.user,
  wrapperDisplayName: 'Authenticated',
};
const AuthorizedBusinessCreation = connectedRouterRedirect({
  wrapperDisplayName: 'Authorized',
  AuthenticatingComponent: Authenticating,
  redirectPath: getRedirect(),
  allowRedirectBack: false,
  authenticatingSelector,
  authenticatedSelector: ({ authentication, ...rest }) => {
    return (
      // if user is superuser or staff
      (!!authentication.user &&
        (authentication.user.is_superuser || authentication.user.is_staff)) ||
      // if business type allowed
      (!!authentication.chosenBusiness &&
        ALLOWED_BUSINESS_TYPES.includes(
          authentication?.chosenBusiness?.business_type
        ))
    );
  },
});

// for when we need to be authenticated and have a business selected
const authenticatedAndBusinessChoosenDefaults = {
  authenticatedSelector: ({ authentication }) =>
    !!authentication.user && !!authentication.chosenBusiness,
  wrapperDisplayName: 'Authenticated',
};

const Authenticated = connectedAuthWrapper(authenticatedDefaults);
const AuthenticatedWithBusiness = connectedAuthWrapper(
  authenticatedAndBusinessChoosenDefaults
);

const AuthenticatedRedirect = connectedRouterRedirect({
  ...authenticatedDefaults,
  redirectPath: paths.get('login'),
  allowRedirectBack: false,
  authenticatingSelector,
  // Redundant with Loader wrapping App on rehydration, but
  // useful on its own when a user submits the login form
  AuthenticatingComponent: Authenticating,
});

const AuthorizedRedirect = ({ for: forSection } = {}) =>
  connectedRouterRedirect({
    wrapperDisplayName: 'Authorized',
    AuthenticatingComponent: Authenticating,
    redirectPath: getRedirect(),
    allowRedirectBack: false,
    authenticatingSelector,
    authenticatedSelector: (state) => {
      return (
        !forSection ||
        can(state, {
          view: 'section',
          for: forSection,
        })
      );
    },
  });

const userLogedInWithBusiness = (authentication) =>
  !!authentication.user && !!authentication.chosenBusiness;

const NonAuthenticatedRedirect = compose(
  connectedRouterRedirect({
    authenticatedSelector: ({ authentication }) => {
      // we return true if we need to show login
      const needToLogIn =
        !authentication.user && !authentication.authenticating;

      const hasRedirect = hasValidOAuthRedirect(window.location);

      if (hasRedirect) {
        if (userLogedInWithBusiness(authentication)) {
          // we are loged in, with a valid business and have valid redirect
          oauthSessionStorage.clear();
          window.location.href = hasRedirect;
        }
        // save the url in session storage for later use
        oauthSessionStorage.val = hasRedirect;
      }

      return needToLogIn;
    },
    redirectPath: () => getRedirect(),
    allowRedirectBack: false,
    wrapperDisplayName: 'NonAuthenticated',
  }),
  HideZendescHOC
  // ClearRedirect
);

// Superusers get to choose business even if they only have 1

const HasBusinessRedirect = connectedRouterRedirect({
  authenticatedSelector: ({ authentication }) =>
    !!authentication.user &&
    (hasAnyBusinesses(authentication.user) ||
      !!authentication.user.is_superuser),
  redirectPath: paths.get('newBusiness'),
  allowRedirectBack: false,
  wrapperDisplayName: 'HasBusiness',
});

const BusinessChosenRedirect = connectedRouterRedirect({
  authenticatedSelector: ({ authentication }) => {
    // this is a catch all
    const hasRedirect = oauthSessionStorage.val;
    if (hasRedirect && userLogedInWithBusiness(authentication)) {
      // we are loged in, with a valid business and have valid redirect
      oauthSessionStorage.clear();
      window.location.href = hasRedirect;
    }
    return !!authentication.chosenBusiness;
  },
  redirectPath: paths.get('chooseBusiness'),
  allowRedirectBack: false,
  wrapperDisplayName: 'BusinessChosen',
});

const businessSubscribedDefaults = {
  authenticatedSelector: ({ authentication }) =>
    !!(
      authentication.chosenBusiness &&
      authentication.chosenBusiness.has_active_subscription
    ),
  wrapperDisplayName: 'BusinessSubscribed',
};
const BusinessSubscribedRedirect = connectedRouterRedirect({
  ...businessSubscribedDefaults,
  redirectPath: `${paths.get('newBusiness')}?purpose=billing`,
  allowRedirectBack: false,
});

// Depends on authentication (user set in state)
// Superusers select from all businesses registered in the system
const MustChooseBusinessRedirect = connectedRouterRedirect({
  authenticatedSelector: ({ authentication }) =>
    hasMultipleBusinesses(authentication.user) ||
    authentication.user.is_superuser,
  wrapperDisplayName: 'MustChooseBusiness',
  redirectPath: () => getRedirect(),
  allowRedirectBack: false,
});

// we redirect only if a single-business user
const MultiBusinessUserSession = compose(
  AuthenticatedRedirect,
  HasBusinessRedirect,
  MustChooseBusinessRedirect,
  HideZendescHOC
);

const CompleteSession = compose(
  AuthenticatedRedirect,
  HasBusinessRedirect,
  BusinessChosenRedirect,
  BusinessSubscribedRedirect,
  ZendeskIntegration,
  ClearRedirect
);

const CompleteSessionAccess = (Component, auth) =>
  compose(CompleteSession, AuthorizedRedirect(auth))(Component);

const BusinessSelectionChangedRedirect = connectedAuthWrapper({
  authenticatedSelector: businessSubscribedDefaults.authenticatedSelector,
  wrapperDisplayName: 'BusinessSelectionChanged',
  // eslint-disable-next-line react/display-name
  FailureComponent: () => (
    <Redirect to={`${paths.get('newBusiness')}?purpose=billing`} />
  ),
})(() => <Redirect to={getRedirect()} />);

export {
  Authenticated,
  AuthenticatedWithBusiness,
  AuthenticatedRedirect,
  BusinessSelectionChangedRedirect,
  CompleteSession,
  CompleteSessionAccess,
  NonAuthenticatedRedirect,
  MultiBusinessUserSession,
  AuthorizedBusinessCreation,
};
