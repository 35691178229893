import DateRangePicker, { range } from 'components/Kizen/DateRangePicker';
import { getDateString } from './Date';
import { RangeInputProps } from './types';

const ranges = [
  range.today,
  range.yesterday,
  range.thisMonth,
  range.last7Days,
  range.last30Days,
  range.thisYear,
];

export const DateRange = ({
  step,
  width = 250,
  value,
  // placeholder, // not making this configurable from the backend until needed
  error,
  onChange,
}: RangeInputProps<string | Date>) => {
  return (
    <DateRangePicker
      inputAffordance
      ranges={ranges}
      width={width}
      value={value}
      hasError={error}
      onChange={([min, max]: [Date, Date]) => {
        onChange(step, [
          isNaN(min.getTime()) ? min : getDateString(min),
          isNaN(max.getTime()) ? max : getDateString(max),
        ]);
      }}
      onOk={(range: [Date | string, Date | string]) => {
        const min = range[0] instanceof Date ? range[0] : new Date(range[0]);
        const max = range[1] instanceof Date ? range[1] : new Date(range[1]);

        onChange(step, [
          isNaN(min.getTime()) ? min : getDateString(min),
          isNaN(max.getTime()) ? max : getDateString(max),
        ]);
      }}
    />
  );
};
