import { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Kizen/Icon';
import { IconSizing } from 'components/Kizen/Icon';
import { grayScale, colorsButton, colorsPrimary } from 'app/colors';
import PercentageBar from 'components/PercentageBar';
import KizenTypography from 'app/kizentypo';
import { filterBadFiles, EMPTY_ARRAY, MAX_FILE_SIZE } from './defaults';
import { useFlashTransition } from 'hooks/useFlashState';
import {
  ChooserDragAndDrop,
  CloudIcon,
  ErrorFileTypography,
  ErrorSpacedTypography,
  LoadingBar,
  SpacedTypography,
  StyledPlaceholder,
  StyleErrorCard,
} from './styles';

const FilePlaceholder = (props) => {
  const { file } = props;
  return (
    <StyledPlaceholder {...props}>
      <IconSizing size="35px" style={{ marginTop: '7px' }}>
        <Icon icon="file" />
      </IconSizing>
      <KizenTypography as="span">{file.name}</KizenTypography>
    </StyledPlaceholder>
  );
};

FilePlaceholder.propTypes = {
  file: PropTypes.object.isRequired,
};

const FilePicker = ({
  disabled,
  onFileAdded, // [File,...]
  allowedFileExtensions,
  uploadStatus = 0, // 0 >> 100
  multiFileMode = false,
  isMobile = false,
}) => {
  const { t } = useTranslation();
  const [fileError, setFileError] = useState(null);
  const [uploadingFiles, setUploadingFiles] = useState(EMPTY_ARRAY);
  const [validateMessage, showValidateMessage, setValidateMessage] =
    useFlashTransition();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const hasExceedingLimit = acceptedFiles.find(
        (e) => e.size > MAX_FILE_SIZE
      );

      if (hasExceedingLimit) {
        setValidateMessage(
          t(
            'Your file exceeds the maximum size of 50MB.  Please try again with a smaller file.'
          )
        );
        return;
      }

      const badFiles = acceptedFiles.filter(
        filterBadFiles(allowedFileExtensions)
      );
      if (badFiles.length === 0) {
        const limitedFiles = multiFileMode
          ? acceptedFiles
          : [acceptedFiles.pop()];
        setFileError(null);
        setUploadingFiles(limitedFiles);
        onFileAdded(limitedFiles);
      } else {
        setFileError(badFiles);
      }
      setFocused(false);
    },
    [allowedFileExtensions, multiFileMode, onFileAdded, setValidateMessage, t]
  );
  const [focused, setFocused] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: () => {
      setFocused(true);
    },
    onDragLeave: () => {
      setFocused(false);
    },
  });
  return (
    <ChooserDragAndDrop
      {...{ ...(!disabled && getRootProps()) }}
      error={!!fileError || showValidateMessage}
      status={uploadStatus}
      disabled={disabled}
      multiFileMode={multiFileMode}
      showValidateMessage={showValidateMessage}
      isMobile={isMobile}
      focused={focused}
    >
      {!fileError ? (
        <>
          {!uploadStatus ? (
            !multiFileMode && uploadingFiles?.length ? (
              <FilePlaceholder
                file={
                  uploadingFiles[uploadingFiles.length - 1] ||
                  fileError[fileError.length - 1]
                }
              />
            ) : (
              <CloudIcon size="50px">
                <Icon
                  icon="cloud-arrow-up"
                  color={disabled ? grayScale.medium : grayScale.mediumDark}
                />
              </CloudIcon>
            )
          ) : (
            <LoadingBar multiFileMode={multiFileMode}>
              <PercentageBar
                percentage={uploadStatus}
                width={isMobile ? 100 : 220}
                radius={14}
                primaryColor={colorsButton.blue.hover}
                backgroundColor={grayScale.medium}
              />
              <KizenTypography weight="bold">{uploadStatus}%</KizenTypography>
            </LoadingBar>
          )}
          <SpacedTypography
            singleMode={!multiFileMode && uploadingFiles?.length}
          >
            <KizenTypography
              as="span"
              weight="bold"
              color={
                disabled ? colorsPrimary.blue.light : colorsButton.blue.default
              }
            >
              {multiFileMode
                ? t('Choose Files')
                : uploadingFiles?.length
                  ? t('Change File')
                  : t('Choose File')}
              {` `}
            </KizenTypography>
            {isMobile ? null : (
              <KizenTypography
                as="span"
                color={disabled ? grayScale.medium : grayScale.mediumDark}
              >
                {multiFileMode
                  ? t('or drag & drop files here')
                  : t('or drag & drop here')}
              </KizenTypography>
            )}
          </SpacedTypography>
        </>
      ) : (
        <>
          <IconSizing size="35px">
            <Icon icon={['fal', 'file-alt']} color={grayScale.mediumDark} />
          </IconSizing>
          <ErrorFileTypography color={colorsButton.red.default} weight="bold">
            {fileError[0].name}
          </ErrorFileTypography>
          <ErrorSpacedTypography>
            <KizenTypography
              as="span"
              weight="bold"
              color={colorsButton.blue.default}
            >
              {t('Change File')}
              {` `}
            </KizenTypography>
            {isMobile ? null : (
              <KizenTypography as="span" color={grayScale.mediumDark}>
                {t('or drag & drop files here')}
              </KizenTypography>
            )}
          </ErrorSpacedTypography>
        </>
      )}
      <input
        disabled={disabled}
        type="file"
        hidden
        {...getInputProps()}
        multiple={multiFileMode}
        accept={allowedFileExtensions.join(',')}
      />
      {validateMessage && (
        <StyleErrorCard show={showValidateMessage} duration="300ms">
          <KizenTypography>{validateMessage}</KizenTypography>
        </StyleErrorCard>
      )}
    </ChooserDragAndDrop>
  );
};

FilePicker.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onFileAdded: PropTypes.func.isRequired,
  allowedFileExtensions: PropTypes.array.isRequired,
  uploadStatus: PropTypes.number,
  multiFileMode: PropTypes.bool,
  isMobile: PropTypes.bool,
};

FilePicker.defaultProps = {
  uploadStatus: 0,
  multiFileMode: false,
};
export default FilePicker;
