export const accountUrlBase = '/client/:id';

const paths = new Map([
  ['profile', `${accountUrlBase}/details`],
  ['messages', `${accountUrlBase}/messages`],
  // TODO - temporarily removed for go live
  // ['commerce', `${accountUrlBase}/commerce`],
  ['automations', `${accountUrlBase}/automations`],
]);

export default paths;
