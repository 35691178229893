import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { isMobile, useWindowSize, breakpoints } from 'app/spacing';
import { css } from '@emotion/core';
import { colorsButton, grayScale, shadowOverlay } from 'app/colors';
import { ignoreSsrWarning } from 'components/helpers';
import { DEFAULT_ICON_COLOR } from './Icon';
import { ICON_BUTTON_DELAY, useTooltip } from './Tooltip';

export const OVERLAY_ICON_BUTTON_SIZE = 30;

export const ICON_BUTTON_SIZING = {
  normal: 'normal', // 44px by 44px
  dense: 'dense', // sized to icon (usually 20px by 20px)
  touch: 'touch', // 44px by 44px touch area, but 20px by 20px in layout
  mobile: 'mobile', // switch between touch for small screens, and dense for the rest
  overlay: 'overlay', // 30px by 30px overlay
};

// TODO - add checks for disabled to change the hover behavior when disabled
const StyledButton = styled('button', {
  shouldForwardProp: (prop) =>
    !['sizing', 'color', 'as', 'borderColor'].includes(prop),
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: ${({ sizing }) =>
    sizing === ICON_BUTTON_SIZING.touch ? '-12px' : 0};
  height: ${({ sizing }) =>
    sizing === ICON_BUTTON_SIZING.dense ? 'auto' : '44px'};
  width: ${({ sizing }) =>
    sizing === ICON_BUTTON_SIZING.dense ? 'auto' : '44px'};
  border-radius: ${({ sizing }) =>
    sizing === ICON_BUTTON_SIZING.dense ? 'initial' : '50%'};
  background: none;
  && {
    outline: none;
  }
  && > :first-child${ignoreSsrWarning} {
    color: ${({ color }) =>
      typeof color === 'string' ? color : color.dark || color.default};
  }
  &&:hover > :first-child {
    color: ${({ color }) =>
      typeof color === 'string' ? color : color.light || color.hover};
  }
`;

const MobileButton = forwardRef((props, ref) => {
  const size = useWindowSize();
  return (
    <StyledButton
      ref={ref}
      type="button"
      sizing={
        isMobile(size.width, breakpoints.sm)
          ? ICON_BUTTON_SIZING.touch
          : ICON_BUTTON_SIZING.dense
      }
      {...props}
    />
  );
});

MobileButton.displayName = 'MobileButton';

const OverlayedIconButton = styled('button', {
  shouldForwardProp: (prop) => !['as', 'borderColor'].includes(prop),
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  width: ${OVERLAY_ICON_BUTTON_SIZE}px;
  height: ${OVERLAY_ICON_BUTTON_SIZE}px;
  border-radius: 50%;

  ${({ borderColor }) => {
    return borderColor
      ? css`
          border: 1px solid ${borderColor};
        `
      : css`
          border: none;
        `;
  }};

  && {
    outline: none;
  }

  ${({ color }) => css`
    && > :first-child${ignoreSsrWarning} {
      color: ${typeof color === 'string' ? color : color.dark || color.default};
    }
    &&:hover > :first-child {
      color: ${typeof color === 'string' ? color : color.light || color.hover};
    }
  `}

  background: ${grayScale.white};
  ${shadowOverlay}

  && svg {
    // font-awesome fonts are wider than tall
    width: auto;
    height: 12px;
  }
`;

const IconButton = forwardRef(
  (
    {
      sizing = ICON_BUTTON_SIZING.normal,
      color = null,
      title,
      tooltipDisabled = false,
      popperConfig = {},
      borderColor = null,
      ...props
    },
    ref
  ) => {
    const { width } = useWindowSize();
    const [tooltipProps, tooltip] = useTooltip(
      {
        label: title,
        delay: ICON_BUTTON_DELAY,
        popperConfig,
      },
      () => !tooltipDisabled && !isMobile(width) && Boolean(title)
    );
    if (sizing === ICON_BUTTON_SIZING.mobile) {
      return (
        <MobileButton
          ref={ref}
          color={color || DEFAULT_ICON_COLOR}
          borderColor={borderColor}
          {...props}
        />
      );
    }
    if (sizing === ICON_BUTTON_SIZING.overlay) {
      return (
        <>
          <OverlayedIconButton
            ref={ref}
            type="button"
            color={color || colorsButton.iconGray}
            borderColor={borderColor}
            {...props}
            {...tooltipProps}
          />
          {tooltip}
        </>
      );
    }
    return (
      <>
        <StyledButton
          ref={ref}
          type="button"
          sizing={sizing}
          color={color || DEFAULT_ICON_COLOR}
          borderColor={borderColor}
          {...props}
          {...tooltipProps}
        />
        {tooltip}
      </>
    );
  }
);

IconButton.displayName = 'IconButton';

export default IconButton;
