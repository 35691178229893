import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useToast, toastVariant } from 'components/ToastProvider';
import ActionModal from '../ActionModal';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import LibraryService, { templateForApi } from 'services/LibraryService';
import EmailPicker from 'components/Kizen/EmailPicker';

const Wrapper = styled.div`
  flex: 1;
  margin-bottom: 17px;
`;

const DELAY = 3000;

export const checkSendEmailAvailability = (contact, t) => {
  const { access = null } = contact;
  if (access && !access.edit) {
    return {
      access: false,
      notPermittedText: t(
        'You do not have the right permissions to email this Contact. Please check with your administrator if you have further questions.'
      ),
    };
  } else if (contact?.email_status === 'suppression_list') {
    return {
      access: false,
      notPermittedText: t(
        'This Contact is on suppression list and cannot be emailed.'
      ),
    };
  } else if (!contact?.email) {
    return {
      access: false,
      notPermittedText: t(
        'This Contact has no email address saved and cannot be emailed.'
      ),
    };
  } else if (contact?.email_status === 'unsubscribed') {
    return {
      access: true,
      unsubscribed: true,
      notPermittedText: t(
        'This Contact is Unsubscribed from All. Do you want to send email anyway?'
      ),
    };
  }

  return { access: true, notPermittedText: null };
};

const SendEmail = ({ contact, onHide, isMobile, ...props }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(null);
  const [showToast] = useToast();
  const [performingAction, setPerformingAction] = useState(false);
  const {
    access,
    notPermittedText,
    unsubscribed: unsubscribedValue,
  } = checkSendEmailAvailability(contact, t);
  const [unsubscribed, setUnsubscribed] = useState(unsubscribedValue);

  const disableButton = useMemo(() => {
    return !email;
  }, [email]);

  const handleContinue = useCallback(() => {
    setUnsubscribed(false);
  }, []);

  const handleCancel = useCallback(() => {
    onHide();
  }, [onHide]);

  const handleSendEmail = async () => {
    try {
      setPerformingAction(true);
      await LibraryService.sendEmail({
        ...templateForApi(email),
        sendTo: contact.id,
      });
      onHide();
      showToast({
        message: t('The email has been successfully queued to 1 Contact'),
        variant: toastVariant.SUCCESS,
        delay: DELAY,
      });
    } catch (err) {
      showToast({
        message: t(
          'The email was not successfully queued. Please try again or contact Kizen support.'
        ),
        variant: toastVariant.FAILURE,
        delay: DELAY,
      });
    } finally {
      setPerformingAction(false);
    }
  };

  return access && !unsubscribed ? (
    <ActionModal
      disableButton={performingAction || disableButton}
      onHide={handleCancel}
      title={t('Send Email to {{fullName}}', contact)}
      show
      onSubmit={handleSendEmail}
      isMobile={isMobile}
      rightButtonTitle="Send Email"
    >
      <Wrapper {...props}>
        <EmailPicker email={email} onChange={setEmail} />
      </Wrapper>
    </ActionModal>
  ) : (
    <ConfirmationModal
      actionBtnColor={unsubscribed ? 'green' : 'blue'}
      show
      buttonText={unsubscribed ? t('Continue') : t('Close')}
      onHide={handleCancel}
      heading={
        unsubscribed
          ? t('Please Confirm This Action')
          : t('Cannot Perform This Action')
      }
      leftBtn={unsubscribed ? undefined : null}
      onConfirm={unsubscribed ? handleContinue : handleCancel}
    >
      {notPermittedText}
    </ConfirmationModal>
  );
};

export default SendEmail;
