import { useRef } from 'react';
import styled from '@emotion/styled';
import { appBackground, grayScale } from 'app/colors';
import { gutters, hideScrollbarCss } from 'app/spacing';
import { DayCalendarCell, isCellToday } from 'components/Calendar';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import CalendarEventPill from './CalendarEventPill';
import { getTimeCellLookupKey, useScheduleBroadcast } from './utils';
import { ScheduleBroadcastCalendarButton } from './ScheduleBroadcastCalendarButton';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';
import { BROADCAST_FORMDATA } from '../ScheduleBroadcastModal/constants';
import ScrollBarDetached from '../../../../components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';

const StyledDayCell = styled(DayCalendarCell)`
  position: relative;
  background-color: ${({ isToday }) =>
    isToday ? appBackground : grayScale.white};
  padding-bottom: ${gutters.spacing()}px;
  padding-top: ${gutters.spacing()}px;
`;

const StyledEventPill = styled(CalendarEventPill)`
  max-width: 125px; // translates into a max of 6 in a row at full width
`;

const EventsScrollContainerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const EventsScrollContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  overflow: auto;
  height: 100%;
  width: calc(100% - ${gutters.spacing(1, 4)}px);
`;

const ContentForSyncHeight = styled.div`
  padding-left: ${gutters.spacing()}px;
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: ${gutters.spacing()}px;
  }
`;

const StyledScrollbar = styled(ScrollBarDetached)`
  margin-right: ${gutters.spacing(1, 4)}px;
`;

const buildDayCell =
  (
    onCellClick,
    broadcastsLookup,
    openStatisticsModal,
    openBroadcastModal,
    canScheduleBroadcasts
  ) =>
  ({ date, time, ...rest }) => {
    const ref = useRef(null);
    const lookupKey = getTimeCellLookupKey(date, time);
    const broadcasts = broadcastsLookup[lookupKey] ?? [];

    const { show, onClick, ...contProps } = useScheduleBroadcast(
      onCellClick,
      date,
      lookupKey
    );

    const [refFn, scrollRef] = useSyncSizes(
      ref,
      '.ContentForSyncHeight',
      'height'
    );
    const scrolled = useEndsOfScroll(scrollRef, [broadcasts]);
    useSyncScroll(useRef([scrollRef, ref]), {
      vertical: true,
    });

    return (
      <StyledDayCell
        date={date}
        isToday={isCellToday(date)}
        {...rest}
        {...contProps}
      >
        {canScheduleBroadcasts ? (
          <ScheduleBroadcastCalendarButton
            view={CALENDAR_VIEW_OPTIONS.day}
            show={show}
            onClick={onClick}
          />
        ) : null}
        <EventsScrollContainerWrapper>
          <EventsScrollContainer ref={refFn} scrolled={scrolled}>
            <ContentForSyncHeight className="ContentForSyncHeight">
              {broadcasts.map((b) => (
                <StyledEventPill
                  key={b.id}
                  date={
                    b[BROADCAST_FORMDATA.rescheduleAt] ||
                    b[BROADCAST_FORMDATA.date]
                  }
                  name={b[BROADCAST_FORMDATA.name]}
                  type={b[BROADCAST_FORMDATA.type]}
                  broadcast={b}
                  openBroadcastModal={openBroadcastModal}
                  openStatisticsModal={openStatisticsModal}
                />
              ))}
            </ContentForSyncHeight>
          </EventsScrollContainer>
          <StyledScrollbar
            ref={ref}
            direction="vertical"
            scrollClassName="ContentForSyncHeight"
          />
        </EventsScrollContainerWrapper>
      </StyledDayCell>
    );
  };

export default buildDayCell;
