import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from 'react-bootstrap2/Overlay';
import useField from 'hooks/useField';
import { popperModifiers } from 'components/helpers';
import {
  StyledFieldEventToggleWrapper,
  StyledSwitch,
} from 'components/Timeline2/styles';

const Toggle = forwardRef(({ enabled, handleApply, style }, ref) => {
  const { t } = useTranslation();
  return (
    <StyledFieldEventToggleWrapper style={style} ref={ref}>
      <StyledSwitch
        textPlacement="left"
        label={t('Include Field Updates?')}
        checked={enabled}
        onChange={handleApply}
        data-qa-fields-filter-toggle
      />
    </StyledFieldEventToggleWrapper>
  );
});

export const FieldUpdated = ({
  onChange,
  excludeEventType,
  trigger,
  isShowing,
  setIsShowing,
}) => {
  const types = useMemo(
    () => new Set(excludeEventType.split(',').filter(Boolean)),
    [excludeEventType]
  );

  const [enabled, setEnabled] = useField(
    () => !types.has('field_updated'),
    [types]
  );

  const handleApply = () => {
    enabled ? types.add('field_updated') : types.delete('field_updated');
    setEnabled(!enabled);
    // using timeout to allow user to see toggle change before overlay closes
    setTimeout(() => {
      setIsShowing(false);
      onChange({
        excludeEventType: [...types].join(),
        excludeTypesCount: types.size,
      });
    });
  };

  return (
    <>
      <Overlay
        show={isShowing}
        target={trigger.current}
        onHide={() => setIsShowing(false)}
        placement="bottom-end"
        popperConfig={{
          modifiers: [popperModifiers.addStyles({ zIndex: 2 })],
        }}
        rootClose
        transition={false}
      >
        <Toggle enabled={enabled} handleApply={handleApply} />
      </Overlay>
    </>
  );
};
