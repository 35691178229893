import React from 'react';

export default function FormEntityIcon(props) {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.3846 0H0.461538C0.339131 0 0.221737 0.0486263 0.135181 0.135182C0.0486262 0.221737 0 0.339131 0 0.461538V1.38462C0 1.50702 0.0486262 1.62442 0.135181 1.71097C0.221737 1.79753 0.339131 1.84615 0.461538 1.84615H25.3846C25.507 1.84615 25.6244 1.79753 25.711 1.71097C25.7975 1.62442 25.8462 1.50702 25.8462 1.38462V0.461538C25.8462 0.339131 25.7975 0.221737 25.711 0.135182C25.6244 0.0486263 25.507 0 25.3846 0ZM25.3846 22.1538H0.461538C0.339131 22.1538 0.221737 22.2025 0.135181 22.289C0.0486262 22.3756 0 22.493 0 22.6154V23.5385C0 23.6609 0.0486262 23.7783 0.135181 23.8648C0.221737 23.9514 0.339131 24 0.461538 24H25.3846C25.507 24 25.6244 23.9514 25.711 23.8648C25.7975 23.7783 25.8462 23.6609 25.8462 23.5385V22.6154C25.8462 22.493 25.7975 22.3756 25.711 22.289C25.6244 22.2025 25.507 22.1538 25.3846 22.1538ZM25.3846 14.7692H0.461538C0.339131 14.7692 0.221737 14.8179 0.135181 14.9044C0.0486262 14.991 0 15.1084 0 15.2308V16.1538C0 16.2763 0.0486262 16.3936 0.135181 16.4802C0.221737 16.5668 0.339131 16.6154 0.461538 16.6154H25.3846C25.507 16.6154 25.6244 16.5668 25.711 16.4802C25.7975 16.3936 25.8462 16.2763 25.8462 16.1538V15.2308C25.8462 15.1084 25.7975 14.991 25.711 14.9044C25.6244 14.8179 25.507 14.7692 25.3846 14.7692ZM25.3846 7.38462H0.461538C0.339131 7.38462 0.221737 7.43324 0.135181 7.5198C0.0486262 7.60635 0 7.72375 0 7.84615V8.76923C0 8.89164 0.0486262 9.00903 0.135181 9.09559C0.221737 9.18214 0.339131 9.23077 0.461538 9.23077H25.3846C25.507 9.23077 25.6244 9.18214 25.711 9.09559C25.7975 9.00903 25.8462 8.89164 25.8462 8.76923V7.84615C25.8462 7.72375 25.7975 7.60635 25.711 7.5198C25.6244 7.43324 25.507 7.38462 25.3846 7.38462Z"
        fill="currentColor"
      />
    </svg>
  );
}
