import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import { useEditor } from '../../../WYSIWYG';
import { TextEditorToolbarSection } from '../../styles';
import React from 'react';
import { ClearFormatting } from '../components/ClearFormatting';

export const ClearFormattingToolbarSection = () => {
  const [ref, props] = useGridLikeFlexChildProps();
  const editor = useEditor();

  if (!editor) return null;

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <ClearFormatting
        onClick={() =>
          editor.chain().focus().clearNodes().unsetAllMarks().run()
        }
      />
    </TextEditorToolbarSection>
  );
};
