import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'components/Calendar';
import BroadcastCalendarContext from './BroadcastCalendarContext';
import buildDayCell from './buildDayCell';
import buildMonthCell from './buildMonthCell';
import buildWeekCell from './buildWeekCell';
import { buildTimeCellBroadcastLookup } from './utils';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';
import { BROADCAST_FORMDATA } from '../ScheduleBroadcastModal/constants';
import { useCalendarData } from './useCalendarData';
import { CalendarLoader } from './CalendarLoader';

const BroadcastCalendar = React.memo(
  ({
    date,
    view,
    isCalendarModalOpen,
    onShowBroadcastModal,
    onShowStatisticsModal,
    canScheduleBroadcasts,
    broadcast,
    ...rest
  }) => {
    const {
      loading,
      data: [allBroadcasts, broadcastLookup],
    } = useCalendarData(date, view, broadcast);

    const openBroadcstModal = useCallback(
      (b) => !loading && onShowBroadcastModal(b),
      [loading, onShowBroadcastModal]
    );

    const handleCellClick = useCallback(
      (date) => {
        canScheduleBroadcasts &&
          openBroadcstModal({
            [BROADCAST_FORMDATA.date]: date.toISOString(),
          });
      },
      [openBroadcstModal, canScheduleBroadcasts]
    );

    const context = useMemo(() => {
      return {
        broadcastLookup,
        isCalendarModalOpen,
      };
    }, [broadcastLookup, isCalendarModalOpen]);

    const [MonthCellComponent, WeekCellComponent, DayCellComponent] =
      useMemo(() => {
        const timeCellBroadcastLookup =
          buildTimeCellBroadcastLookup(allBroadcasts);
        return [
          buildMonthCell(
            handleCellClick,
            onShowStatisticsModal,
            openBroadcstModal,
            canScheduleBroadcasts,
            loading
          ),
          buildWeekCell(
            handleCellClick,
            timeCellBroadcastLookup,
            onShowStatisticsModal,
            openBroadcstModal,
            canScheduleBroadcasts
          ),
          buildDayCell(
            handleCellClick,
            timeCellBroadcastLookup,
            onShowStatisticsModal,
            openBroadcstModal,
            canScheduleBroadcasts
          ),
        ];
      }, [
        handleCellClick,
        onShowStatisticsModal,
        openBroadcstModal,
        canScheduleBroadcasts,
        allBroadcasts,
        loading,
      ]);

    return (
      <BroadcastCalendarContext.Provider value={context}>
        <CalendarLoader loading={loading} />
        <Calendar
          date={date}
          view={view}
          expandable
          MonthCellComponent={MonthCellComponent}
          WeekCellComponent={WeekCellComponent}
          DayCellComponent={DayCellComponent}
          loading={loading}
          {...rest}
        />
      </BroadcastCalendarContext.Provider>
    );
  }
);

BroadcastCalendar.displayName = 'BroadcastCalendar';
BroadcastCalendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  view: PropTypes.oneOf(Object.values(CALENDAR_VIEW_OPTIONS)).isRequired,
};

export default BroadcastCalendar;
