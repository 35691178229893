import React from 'react';
import { useTimeout } from 'react-use';
import styled from '@emotion/styled';
import Loader from 'components/Kizen/Loader';
import { layers } from 'app/spacing';

const StyledLoader = styled(Loader)`
  position: fixed;
  top: calc(50vh - 10%);
  left: 50%;
  z-index: ${layers.modal - 1};
`;

export const TableLoader = () => {
  const [isReady] = useTimeout(2000);
  return isReady() ? <StyledLoader loading={true} /> : null;
};
