import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';

import KizenInlineStepper from 'components/Kizen/Stepper';
import { breakpoints } from 'app/spacing';
import { fontSizes } from 'app/typography';

const StepBlock = styled.div`
  position: relative;
  height: 75px;
  width: 100%;
  margin-top: -10px;
`;

const StepWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 8px;

  @media (min-width: ${breakpoints.lg + 1}px) {
    width: 989px;
    left: -13%;
  }

  // overwrite for react-stepper-horizontal-with-classes
  .stepper-circle-completed {
    font-size: ${fontSizes.micro};
  }

  .stepper-title-completed,
  .stepper-title-unstarted {
    line-height: 125%;
  }
`;

const PerformKizenStepper = ({ currentStep, steps }) => (
  <StepBlock>
    <StepWrapper>
      <KizenInlineStepper
        steps={steps}
        activeStep={currentStep - 1}
        circleTop={11}
      />
    </StepWrapper>
  </StepBlock>
);

export default PerformKizenStepper;

PerformKizenStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.array,
};

PerformKizenStepper.defaultProps = {
  steps: [],
};
