import React, { forwardRef } from 'react';
import { ClipLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { grayScale } from 'app/colors';
import { buttonProps, buttonDefaultProps } from './props';
import Button from '.';

const LoadingButton = forwardRef(
  ({ loading, loadingColor, disabled, children, ...rest }, ref) => (
    <Button ref={ref} disabled={loading || disabled} {...rest}>
      {loading ? (
        <ClipLoader loading size={20} color={loadingColor} />
      ) : (
        children
      )}
    </Button>
  )
);

LoadingButton.displayName = 'LoadingButton';
LoadingButton.propTypes = {
  ...buttonProps,
  loading: PropTypes.bool,
  loadingColor: PropTypes.string,
};
LoadingButton.defaultProps = {
  ...buttonDefaultProps,
  loading: false,
  loadingColor: grayScale.white,
};

export default LoadingButton;
