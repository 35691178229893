/* eslint-disable react/display-name */
import React from 'react';
import * as PropTypes from 'prop-types';
import { isEmailStatusField } from 'checks/fields';
import { EmptyCell } from 'components/Kizen/Table';
import InlineTableFieldInput, {
  getColumnWidth,
  getColumnNumeric,
} from 'components/Fields/InlineTableFieldInput';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { SizedCell, SortableHeadCell } from './Table';

export const CustomFieldDataCell = ({
  field = null,
  column = null,
  data = null,
  onSubmit,
  children,
  shouldFocusNext,
  fieldProps = {},
  fetchUrl = null,
  handleUpdateTableRecords,
  ...others
}) => {
  if (!field) {
    return <EmptyCell {...others} />;
  }
  const onSubmitRecord = async (patch) => {
    await onSubmit(data.id, patch, column);
  };
  const additionalProps = isEmailStatusField(field)
    ? { menuLeftButton: null, menuRightButton: null }
    : {};

  return (
    <SizedCell
      minWidth={`${getColumnWidth(field)}px`}
      column={column}
      {...others}
    >
      {column.format(
        <InlineTableFieldInput
          field={field}
          shouldFocusNext={shouldFocusNext}
          object={data}
          onSubmit={onSubmitRecord}
          fieldProps={fieldProps}
          fetchUrl={fetchUrl}
          handleUpdateTableRecords={handleUpdateTableRecords}
          {...getDataQAForInput(
            `inline-contact-${field.name}`,
            field.fieldType
          )}
          {...additionalProps}
        />
      )}
    </SizedCell>
  );
};

CustomFieldDataCell.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
  }),
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    format: PropTypes.func,
  }),
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  fieldProps: PropTypes.object,
  fetchUrl: PropTypes.string,
};

export const CustomFieldHeadCell = ({
  field = null,
  column = null,
  ...others
}) => {
  if (!field) {
    return <EmptyCell {...others} />;
  }
  return (
    <SortableHeadCell
      by={field.id}
      numeric={getColumnNumeric(field)}
      column={column}
      {...others}
    />
  );
};

CustomFieldHeadCell.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
  }),
  column: PropTypes.shape({ id: PropTypes.string.isRequired }),
};
