// convert api object fro flow
import { fieldToOptionsDeleted } from 'services/helpers';
import {
  checkListDeleted,
  checkDeleted,
  displayNameDescriptor,
} from 'pages/AutomationEngine/helpers';
import { initElement, initState } from './index';
import {
  MATH_OPERATOR_STEP,
  SIMPLE_BUILDER_TYPES,
  ELEMENT_TYPES,
  SIMPLE_BUILDER_OPERATION_TYPES,
} from './constants';
import { monitoringMessageHelper } from 'sentry/helpers';

const checkElements = (messageDictionary, addErrorMessage, mathArgs) => {
  const fields = mathArgs
    .filter(({ argumentType }) => argumentType === ELEMENT_TYPES.variable)
    .map(({ field }) => field);

  checkListDeleted(
    messageDictionary,
    addErrorMessage,
    fields,
    displayNameDescriptor
  );
};

const getSimpleData = (
  type,
  subType,
  fieldData,
  simpleBuilderArguments,
  addErrorMessage,
  messageDictionary
) => {
  // TODO for now we only handle type === 'simple_builder'
  const { simpleData } = initState;
  checkDeleted(
    messageDictionary,
    addErrorMessage,
    fieldData,
    displayNameDescriptor
  );
  checkElements(messageDictionary, addErrorMessage, simpleBuilderArguments);

  switch (subType) {
    case SIMPLE_BUILDER_TYPES.addSubtract:
      return {
        ...simpleData,
        [SIMPLE_BUILDER_TYPES.addSubtract]: {
          targetField: fieldToOptionsDeleted(fieldData),
          elements: simpleBuilderArguments.map(
            ({ argumentType, constant, field, operationType }) =>
              initElement(
                argumentType,
                argumentType === ELEMENT_TYPES.variable
                  ? fieldToOptionsDeleted(field)
                  : constant,
                operationType
              )
          ),
        },
      };

    case SIMPLE_BUILDER_TYPES.multiplicationDivision: {
      let elements = simpleBuilderArguments.map(
        ({ argumentType, constant, field, operationType }) =>
          initElement(
            argumentType,
            argumentType === ELEMENT_TYPES.variable
              ? fieldToOptionsDeleted(field)
              : constant,
            operationType
          )
      );

      // try and find the divide element
      const index = elements.findIndex(
        ({ operationType }) =>
          operationType === SIMPLE_BUILDER_OPERATION_TYPES.divide.value
      );

      // set them all back to 'regular'
      elements = elements.map(({ operationType, ...rest }) => ({
        ...rest,
        operationType: SIMPLE_BUILDER_OPERATION_TYPES.add.value,
      }));
      if (index !== -1) {
        return {
          ...simpleData,
          [SIMPLE_BUILDER_TYPES.multiplicationDivision]: {
            targetField: fieldToOptionsDeleted(fieldData),
            numeratorElements: elements.slice(0, index),
            denominatorElements: elements.slice(index),
          },
        };
      }
      // all the data is above the line
      return {
        ...simpleData,
        [SIMPLE_BUILDER_TYPES.multiplicationDivision]: {
          targetField: fieldToOptionsDeleted(fieldData),
          numeratorElements: elements,
          denominatorElements: [],
        },
      };
    }
    default:
      monitoringMessageHelper('unknown subtype', {
        extra: {
          subType,
        },
      });
      return simpleData;
  }
};

const forFlow = (apiObject, addErrorMessage, messageDictionary) => {
  const { type, subtype, field, simpleBuilderArguments } = apiObject;

  const details = {
    ...initState,

    builderType: type,
    subType: subtype,
    step: MATH_OPERATOR_STEP.chooseSimpleType,
    simpleData: getSimpleData(
      type,
      subtype,
      field,
      simpleBuilderArguments,
      addErrorMessage,
      messageDictionary
    ),
  };

  return details;
};

const mapElementsForApi = ({ operationType, type, value }) => ({
  argumentType: type,
  operationType,
  constant: type === ELEMENT_TYPES.constant ? value : null, // we can pass null to the constant
  ...(type === ELEMENT_TYPES.variable ? { fieldId: value.value } : null), // but not the field_id
});

const forApi = (step) => {
  const details = { ...step.details };
  const { builderType: type, subType: subtype } = details;
  const field_id = details.simpleData[subtype].targetField.value;
  let simpleBuilderArguments;
  if (subtype === SIMPLE_BUILDER_TYPES.addSubtract) {
    simpleBuilderArguments =
      details.simpleData[subtype].elements.map(mapElementsForApi);
  } else if (subtype === SIMPLE_BUILDER_TYPES.multiplicationDivision) {
    // msp the numerators
    const numeratorElements =
      details.simpleData[subtype].numeratorElements.map(mapElementsForApi);

    // map the denominators
    const denominatorElements =
      details.simpleData[subtype].denominatorElements.map(mapElementsForApi);
    // set the first one to divide
    if (denominatorElements.length) {
      denominatorElements[0].operationType =
        SIMPLE_BUILDER_OPERATION_TYPES.divide.value;
    }
    simpleBuilderArguments = [...numeratorElements, ...denominatorElements];
  }
  return { details: { type, subtype, field_id, simpleBuilderArguments } };
};

export const mathOperatorDTO = {
  forApi,
  forFlow,
};
