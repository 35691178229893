import { HomepageBlock } from '@kizen/page-builder/viewer';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useBreakpoint } from 'app/spacing';
import TableScrollContainer from '__components/Tables/ScrollContainer';
import useExpandingVerticalScrollbar from 'hooks/useExpandingVericalScrollbar';
import styled from '@emotion/styled';
import ScrollFadeContainer from '__components/Kizen/ScrollFadeContainer';
import {
  useBusinessConfigQuery,
  useBusinessCountriesQuery,
} from 'queries/models/business';
import { useDisplayNamesQuery } from 'queries/models/records';
import {
  getBusinessMergeFieldLookup,
  getTeamMemberMergeFieldLookup,
  replaceMergeFields,
} from 'utility/merge-fields';
import { HTMLBlockWrapper } from './components';
import { useHomepageLogActivityContext } from '__pages/Homepages/HomepageLogActivityContext';

export const StyledScrollContainer = styled(TableScrollContainer)`
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  overflow-y: auto;
  height: 100%;

  & > div,
  & .ScrollChildren,
  & .ScrollChildren > div:first-child {
    width: 100%;
  }

  & .ScrollChildren > div:last-child {
    margin-left: -8px;
    right: 0;
    z-index: 16;
  }

  &
    .gradient-vertical-container
    .ScrollChildren
    .gradient-vertical-container
    > div:first-child
    > div {
    z-index: 10;
    pointer-events: none;
  }

  &
    .gradient-vertical-container
    .ScrollChildren
    .gradient-vertical-container
    > div:first-child {
    z-index: 10;
    pointer-events: none;
  }
`;

export const HTMLBlock = ({ builderContent, setContentHeight }: any) => {
  const [blockWidth, setBlockWidth] = useState(0);
  const { openLogActivityModal } = useHomepageLogActivityContext();

  if (!builderContent) {
    throw new Error('Invalid HTML Content for Block Viewer');
  }

  const [scrolled, setScrolled] = useState([]);

  const isMobile = useBreakpoint();

  const auth = useSelector((state: any) => state.authentication);

  const { data: roles, isLoading: loadingRoles } = useDisplayNamesQuery<
    string[]
  >(
    { role_ids: auth.teamMember.roles },
    {
      select: (res) => {
        return res.data.results.roles.map((role) => role.display_name);
      },
    }
  );

  const { data: countries, isLoading: loadingCountries } =
    useBusinessCountriesQuery(true);

  const { data: publicData, isLoading: loadingPublicData } =
    useBusinessConfigQuery(true);

  const isLoading = loadingRoles || loadingCountries || loadingPublicData;

  const textTransform = useMemo(() => {
    const lookup = {
      business: getBusinessMergeFieldLookup({
        business: auth.chosenBusiness,
        countries,
        phoneRegions: publicData?.phoneRegions,
      }),
      team_member: getTeamMemberMergeFieldLookup({
        teamMember: auth.teamMember,
        roles,
      }),
    };

    return (content: string) => {
      return replaceMergeFields(content, lookup);
    };
  }, [auth, countries, publicData, roles]);

  const wrapperRefHandler = (ref: HTMLDivElement) => {
    const rect = ref?.getBoundingClientRect();
    const width = rect?.width ?? 0;
    setBlockWidth(width);
  };

  const { containerProps } = useExpandingVerticalScrollbar();

  return (
    <HTMLBlockWrapper ref={wrapperRefHandler}>
      <StyledScrollContainer {...containerProps} onChangeScrolled={setScrolled}>
        <ScrollFadeContainer top bottom scrolled={scrolled}>
          {!isLoading && (
            <HomepageBlock
              key={JSON.stringify(builderContent) + blockWidth}
              data={builderContent}
              containerWidth={blockWidth}
              applyMobileBreakpoints={isMobile}
              setContentHeight={setContentHeight}
              textTransform={textTransform}
              onLogActivityButtonClick={(activityId: string) => {
                openLogActivityModal(activityId);
              }}
            />
          )}
        </ScrollFadeContainer>
      </StyledScrollContainer>
    </HTMLBlockWrapper>
  );
};
