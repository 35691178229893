import React, { useCallback, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import useSendInfo from 'components/MessageBuilder/useSendInfo';
import InputControl from 'components/Inputs/InputControl';
import { gutters } from 'app/spacing';
import { css } from '@emotion/core';
import { fontSizes, fontWeights } from 'app/typography';
import Button from 'components/Button';
import { useModalControl } from 'hooks/useModalControl';
import { MessageBuilder, useDefaultTemplate } from 'components/MessageBuilder';
import MessageTemplatePicker from 'components/MessageTemplatePicker';
import LibraryService from 'services/LibraryService';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useEmailFromDomainEnabledEntitlement } from 'hooks/useEmailFromDomainEnabledEntitlement';
import { SENDER_TYPE } from 'components/MessageBuilder/components';

import useCan from 'hooks/useCan';

const DELAY = 3000;

const EmailBlock = styled.div`
  display: block;
`;

const StyledTextButton = styled(Button)`
  height: ${fontSizes.small};
  margin: 0;
  margin-right: ${gutters.spacing(4)}px;
  padding: 0;
  margin-bottom: ${gutters.spacing(0, -4)}px; //Design nude
`;

const Bolded = styled.span`
  font-size: inherit;
  font-weight: ${fontWeights.bold};
`;

const StyledButton = styled(Button)`
  margin-bottom: 0;
  margin-left: 0;
  margin-right: ${gutters.spacing(4)}px;
`;

const StyledInputControl = styled(InputControl)`
  margin-bottom: 0;

  && label {
    margin-top: ${gutters.spacing(2, 2)}px;
    margin-bottom: ${gutters.spacing(4, 4)}px;
  }

  ${({ isEmailSelected }) =>
    isEmailSelected
      ? css`
          && label {
            margin-top: 0;
            margin-bottom: ${gutters.spacing(3, 1)}px;
          }
        `
      : css`
          && label {
            margin-top: 0;
            margin-bottom: ${gutters.spacing(2, 2)}px;
          }
        `}
  ${({ isLastElement, canEdit }) =>
    isLastElement &&
    css`
      && label {
        margin-top: 0;
        margin-bottom: ${canEdit ? gutters.spacing(3, 1) : 0}px;
      }
    `}
`;

const EmailPicker = React.forwardRef(
  (
    {
      email,
      onChange,
      readonlySenderInfo,
      mergeFields = null,
      isForCustomObject = false,
      multipleMergeFields = null,
      disabled = false,
      useAllIntgegratedInboxes = false,
    },
    ref
  ) => {
    const emailFromDomainEnabled = useEmailFromDomainEnabledEntitlement();

    const [backToPicker, setBackToPicker] = useState(false);
    const canViewLibrary = useCan({
      view: 'sections',
      for: 'library_section',
    });

    const { t } = useTranslation();
    const [showToast] = useToast();
    const [modalLayer, setModalLayer] = useState(1);
    const [modalOpen, { showModal, hideModal }] = useModalControl();
    const [
      messagePickerOpen,
      { showModal: showMessagePicker, hideModal: hideMessagePicker },
    ] = useModalControl();
    const { sendFromString, fromNameString } = useSendInfo(email);
    const clearEmail = useCallback(() => {
      onChange(null);
    }, [onChange]);

    const handleCreateNewEmail = useCallback(() => {
      clearEmail();
      setModalLayer(1);
      showModal();
    }, [showModal, clearEmail]);

    const handleMessagePickerOpen = useCallback(() => {
      setModalLayer(2);
      showMessagePicker();
    }, [showMessagePicker]);

    const isEmailSelected = !!email?.craftJsContent;
    const craftJsContent = useDefaultTemplate(email?.craftJsContent);
    const initialData = useMemo(
      () => ({ ...email, craftJsContent }),
      [craftJsContent, email]
    );

    const handleBuilderConfirm = useCallback(
      (messageData, shouldCloseBuilder) => {
        onChange(messageData);
        setBackToPicker(false);
        showToast({
          message: t('Message Template was successfully saved'),
          variant: toastVariant.SUCCESS,
        });
        if (shouldCloseBuilder) {
          hideMessagePicker();
          setModalLayer(1);
          hideModal();
        }
      },
      [onChange, hideMessagePicker, hideModal, showToast, t]
    );

    const handleBuilderCancel = useCallback(() => {
      if (backToPicker) {
        onChange(null);
        setBackToPicker(false);
        showMessagePicker();
      } else {
        setModalLayer(1);
        hideMessagePicker();
      }
      hideModal();
    }, [
      backToPicker,
      hideModal,
      showMessagePicker,
      hideMessagePicker,
      onChange,
    ]);

    const handleMessagePickerConfirm = useCallback(
      async (message) => {
        try {
          setBackToPicker(true);
          const messageData = await LibraryService.getTemplateByTypeId(message);
          onChange(
            emailFromDomainEnabled
              ? messageData
              : {
                  ...messageData,
                  sender: {
                    custtomName: '',
                    id: null,
                    type: SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX,
                  },
                  senderField: null,
                  senderRole: null,
                  senderTeamMember: null,
                  senderType: SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX,
                }
          );
          showModal();
        } catch {
          showToast({
            message: t(
              'Failed to load chosen message. Please try again or contact Kizen support.'
            ),
            variant: toastVariant.FAILURE,
            delay: DELAY,
          });
        }
      },
      [onChange, showModal, showToast, t, emailFromDomainEnabled]
    );

    return (
      <div ref={ref}>
        {disabled ? null : (
          <>
            {modalOpen && initialData?.craftJsContent ? (
              <MessageBuilder
                initialData={initialData}
                modalLayer={modalLayer}
                readonlySenderInfo={readonlySenderInfo}
                onConfirm={handleBuilderConfirm}
                onCancel={handleBuilderCancel}
                mergeFields={mergeFields}
                isForCustomObject={isForCustomObject}
                multipleMergeFields={multipleMergeFields}
                useAllIntgegratedInboxes={useAllIntgegratedInboxes}
              />
            ) : null}
            <MessageTemplatePicker
              show={messagePickerOpen}
              onCancel={hideMessagePicker}
              onSubmit={handleMessagePickerConfirm}
            />
          </>
        )}
        {isEmailSelected && (
          <StyledInputControl
            isEmailSelected={isEmailSelected}
            variant="outline"
            label={t('Selected Email')}
          />
        )}
        {isEmailSelected && sendFromString && (
          <StyledInputControl
            variant="outline"
            isEmailSelected
            label={
              <>
                <Bolded>{t('Send From')}:</Bolded> {sendFromString}
              </>
            }
          />
        )}
        {isEmailSelected && fromNameString && (
          <StyledInputControl
            variant="outline"
            isEmailSelected
            label={
              <>
                <Bolded>{t('From Name')}:</Bolded> {fromNameString}
              </>
            }
          />
        )}
        {!isEmailSelected && !disabled ? (
          <StyledInputControl
            isEmailSelected={isEmailSelected}
            variant="outline"
            label={t('Choose Email To Send')}
          />
        ) : null}

        <EmailBlock>
          {isEmailSelected ? (
            <StyledInputControl
              margin={1}
              variant="outline"
              isLastElement={isEmailSelected}
              canEdit={!disabled}
              label={
                <>
                  <Bolded>{t('Selected')}:</Bolded> {email.subject}
                </>
              }
            >
              {disabled ? null : (
                <div>
                  <StyledTextButton
                    variant="text"
                    color="blue"
                    onClick={showModal}
                  >
                    {t('View/Edit Email ')}
                  </StyledTextButton>
                  <StyledTextButton
                    variant="text"
                    color="red"
                    onClick={clearEmail}
                  >
                    {t('Remove Email ')}
                  </StyledTextButton>
                </div>
              )}
            </StyledInputControl>
          ) : (
            <>
              {canViewLibrary ? (
                <StyledButton
                  variant={'outline'}
                  color={'blue'}
                  onClick={handleMessagePickerOpen}
                  data-qa="email-choose"
                  disabled={disabled}
                >
                  {t('Choose Email From Library')}
                </StyledButton>
              ) : null}
              <StyledButton
                variant={'outline'}
                color={'green'}
                onClick={handleCreateNewEmail}
                data-qa="email-create"
                disabled={disabled}
              >
                {t('Create New Email')}
              </StyledButton>
            </>
          )}
        </EmailBlock>
      </div>
    );
  }
);
EmailPicker.displayName = 'EmailPicker';
EmailPicker.propTypes = {
  email: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  readonlySenderInfo: PropTypes.bool,
  disabled: PropTypes.bool,
};

EmailPicker.defaultProps = {
  readonlySenderInfo: false,
};

export default EmailPicker;
