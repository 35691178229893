import { useForceUpdate } from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/useForceUpdate';
import useField from 'hooks/useField';
import { useEffect, useMemo } from 'react';
import {
  getLeftColumnOrdering,
  getLeftItems,
  getRightItems,
  isNonHidebleColumn,
} from '../helpers';
import useCan from 'hooks/useCan';

export const useDraggableColumns = (
  columnSettings,
  groupedColumns,
  defaultColumnSettings
) => {
  const canCustomizeFields = useCan({
    view: 'sections',
    for: 'settings_section',
  });

  const [forceKey, forceUpdate] = useForceUpdate();

  const [rightItems, setRightItems] = useField(
    () => getRightItems(groupedColumns, columnSettings),
    [columnSettings]
  );

  useEffect(() => {
    forceUpdate();
  }, [columnSettings, forceUpdate]);

  const leftItems = useMemo(
    () => getLeftItems(groupedColumns, columnSettings, rightItems),
    [groupedColumns, rightItems, columnSettings]
  );

  const itemsOrdering = useMemo(
    () => getLeftColumnOrdering(groupedColumns, defaultColumnSettings),
    [groupedColumns, defaultColumnSettings]
  );

  return {
    canCustomizeFields,
    forceKey,
    leftItems,
    rightItems: rightItems.map((item) => ({
      ...item,
      disabled: isNonHidebleColumn({ id: item.id }),
    })),
    onChange: setRightItems,
    itemsOrdering,
  };
};
