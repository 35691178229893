import { Stream, merge, reactive, sync } from '@thi.ng/rstream';
import { drop, map } from '@thi.ng/transducers';
import { payloadXform } from './payload-xform';
import { Permission } from './permission';
import { getSourceStreamEntry, getRule } from './state-build';
import {
  Access,
  AccessStruct,
  ContactsSectionConfig,
  MetaConfig,
  PermissionsContext,
  PermissionResultData,
  PermissionRule,
  Section,
  SectionConfig,
  StreamValue,
  PermissionGroup,
  PermissionValue,
  BuildPermissonsContext,
  TFunction,
  PermissionConfig,
} from './types';
import {
  createCustomObjectSection,
  createFieldPermissionConfig,
  Field,
  getAccessNumber,
  getAccessValue,
  getSectionConfigMetadata,
  getStreamKey,
} from './utils';

const CONTACTS_SECTION_KEY = 'contacts_section';
const withoutLastName = (field: Field) => field.name !== 'last_name';

/**
 * Build all key/stream tuples for the provided section config objects (used
 * to populate the context's `sources` Map in {@link buildPermissionsContext}).
 *
 * @param configs - array of section config objects.
 * @returns {[string, Stream<StreamValue>][]}
 */
const getSourceStreamEntries = (configs: SectionConfig[]) => {
  return configs.reduce<[string, Stream<StreamValue>][]>((acc, config) => {
    acc.push(getSourceStreamEntry(config));

    if (Array.isArray(config.permissions)) {
      for (const perm of config.permissions) {
        // create with false/none if the parent config is disabled
        const def = !config.default
          ? typeof perm.default === 'boolean'
            ? false
            : 'none'
          : perm.default;
        acc.push(getSourceStreamEntry({ ...perm, default: def }, config.key));
      }
    }

    return acc;
  }, []);
};

/**
 * Build all key/Rule tuples for the provided section config objects (used
 * to populate the context's `rules` Map in {@link buildPermissionsContext}).
 *
 * @param sources - the context's `sources` Map.
 * @param configs - array of section config objects.
 * @returns
 */
const getRuleStreamEntries = (
  permissions: PermissionsContext['permissions'],
  configs: SectionConfig<any, any>[]
) => {
  const entries: [string, PermissionRule][] = [];

  for (const config of configs) {
    if (config.rule) {
      entries.push([
        getStreamKey(config.key),
        getRule(permissions, config.rule),
      ]);
    }
    if (Array.isArray(config.permissions)) {
      for (const perm of config.permissions) {
        if (perm.rule) {
          entries.push([
            getStreamKey(perm.key, config.key),
            getRule(permissions, perm.rule, config.key),
          ]);
        }
      }
    }
  }

  return entries;
};

const getVisibilityRuleStreamEntries = (
  permissions: PermissionsContext['permissions'],
  configs: SectionConfig<any, any>[]
) => {
  const entries: [string, PermissionRule][] = [];

  for (const config of configs) {
    if (config.visibility_rule) {
      entries.push([
        getStreamKey(config.key),
        getRule(permissions, config.visibility_rule),
      ]);
    }
    if (Array.isArray(config.permissions)) {
      for (const perm of config.permissions) {
        if (perm.visibility_rule) {
          entries.push([
            getStreamKey(perm.key, config.key),
            getRule(permissions, perm.visibility_rule, config.key),
          ]);
        }
      }
    }
  }

  return entries;
};

export const buildSingleObjectSectionContext = (
  meta_config: MetaConfig,
  customObjects: any[],
  contact_object_id: string,
  contactFields: Field[],
  t: TFunction
) => {
  const filteredContactFields = contactFields.filter(withoutLastName);

  const contactConfig: ContactsSectionConfig = {
    label: 'Contacts',
    key: CONTACTS_SECTION_KEY,
    default: true,
    allowed_access: ['none', 'view'],
    affordance: 'switch',
    permissions:
      buildContactPermissionConfigs(
        filteredContactFields,
        contact_object_id,
        meta_config
      ) ?? [],
  };

  const co_configs = customObjects.map((object: any) =>
    createCustomObjectSection(
      object,
      {
        enabled: true,
      },
      t
    )
  );
  const all_configs = co_configs.concat(contactConfig);
  const sources = new Map(getSourceStreamEntries(all_configs));
  const co_ids = new Set(co_configs.map(({ key }) => key));

  const metaDatas = contactConfig.permissions!.reduce<
    Record<string, PermissionConfig['metadata']>
  >((acc, p) => {
    if (p.metadata) {
      acc[getStreamKey(p.key, CONTACTS_SECTION_KEY)] = p.metadata;
    }
    return acc;
  }, {});

  const permissions = new Map(
    Array.from(sources.entries(), ([key]) => {
      const metadata = co_ids.has(key as Section)
        ? { custom_object_id: key }
        : metaDatas[key];
      return [key, new Permission(key, sources, metadata)];
    })
  );

  const rules = new Map(getRuleStreamEntries(permissions, all_configs));
  for (const [key, rule] of rules.entries()) {
    const permission = permissions.get(key)!;
    permission.applyRule(rule);
  }
  const visibility_rules = new Map(
    getVisibilityRuleStreamEntries(permissions, all_configs)
  );
  for (const [key, rule] of visibility_rules.entries()) {
    const permission = permissions.get(key)!;
    permission.applyVisibilityRule(rule);
  }
  const updates = merge<PermissionResultData, PermissionResultData>();
  const payload = updates.transform(payloadXform({}));
  const fields_loaded = new Map(
    all_configs
      .map<
        [string, Stream<boolean>]
      >(({ key }) => [key, reactive<boolean>(false)])
      .concat([[contactConfig.key, reactive<boolean>(true)]])
  );

  const hasPermissionEdits = merge({
    src: Array.from(sources.values(), (str) => str.transform(drop(1))),
    xform: map((x) => x !== undefined),
  });

  updates.addAll(Array.from(permissions.values(), (p) => p.value));

  return {
    meta_config,
    contact_section: contactConfig,
    co_sections: all_configs,
    fields_loaded,
    sources,
    rules,
    permissions,
    visibility_rules,
    updates,
    payload,
    hasPermissionEdits,
  };
};

/**
 * Builds stream sources and dependant {@link Rule} and {@link Permission} classes (themselves a collection of streams) for the
 * sections and their child permissions based off of the `configs` and `customObjects` parameter.
 *
 * @remarks Each permission can specify a rule (see ./rules) that describes certain constraints or explicit value requirements
 * that should be enforced as other permission values changes. Each permission must have access to all other permission values
 * to implement their rule. Each item in the context is described below in the order they are created:
 *   - `sources` - a Map of {@link https://github.com/thi-ng/umbrella/tree/develop/packages/rstream#stream streams} for each permission value.
 *   - `rules` - a Map of {@link Rule} classes with a `result` property which is a stream that emits a RuleResult indicating if the rule passed
 *       and any data associated with the rule.
 *   - `permissions` - a Map of {@link Permission} classes with a `value` property which is a stream that emits either the data from the rule
 *       (if it passed) or the original source stream data.
 *   - `updates` - a stream {@link https://github.com/thi-ng/umbrella/tree/develop/packages/rstream#unordered-merge-from-multiple-inputs-dynamic-addremove merge}
 *       of all permission value streams. Permissions are added/removed as sections are turned on and off.
 *   - `payload` - a transformation of `updates` that produces the api payload.
 *   - `fields_loaded` - a Map of boolean streams unrelated to the above topology. It simply indicates if custom object permission sections have had
 *       their field permissions created since those streams are added after the initial context creation.
 *
 * @param configs - static (not contact or custom field) permission sections (the `sections` property of the meta permissions json payload).
 * @param co_permissions - permissions that apply to all custom fields (the `custom_objects` property of the meta permissions json payload).
 * @param customObjects - all custom objects for a business
 * @param permissionGroup - existing values for all permission settings
 * @returns {Ctx}
 */
export const buildPermissionsContext = ({
  meta_config,
  preReleaseFeaturesConfig,
  customObjects,
  contact_object_id,
  contactFields,
  permissionGroup,
  t,
}: BuildPermissonsContext): PermissionsContext => {
  const isCreateNew = !permissionGroup;
  const customObjectDefaults =
    permissionGroup?.custom_objects?.reduce((acc: any, co: any) => {
      acc[co.custom_object_id] = co;
      return acc;
    }, {}) ?? {};
  const co_configs = customObjects.map((object) =>
    createCustomObjectSection(object, customObjectDefaults[object.id], t)
  );
  const filteredContactFields = contactFields.filter(withoutLastName);

  const existing_contact_values = permissionGroup?.contacts_section?.enabled
    ? createExistingContactsSectionValues(
        permissionGroup.contacts_section,
        filteredContactFields
      )
    : undefined;

  const sectionMetadatas = meta_config.sections.reduce<Record<string, object>>(
    (acc, section) => {
      for (const p of section.permissions ?? []) {
        acc[getStreamKey(p.key, section.key)] = getSectionConfigMetadata(p);
      }
      return acc;
    },
    {}
  );

  const sectionConfigs = meta_config.sections.map((section) => {
    const currentSectionValues = permissionGroup?.[section.key] as any;
    const permissions =
      section.permissions?.map((permission) => ({
        ...permission,
        default: currentSectionValues?.[permission.key] ?? permission.default,
      })) ?? [];
    return {
      ...section,
      default: currentSectionValues?.enabled ?? section.default,
      permissions:
        section.key === 'settings_section' && preReleaseFeaturesConfig
          ? permissions.concat(preReleaseFeaturesConfig)
          : permissions,
    };
  });

  const updatedMetaConfig = { ...meta_config, sections: sectionConfigs };

  const contactConfig: ContactsSectionConfig = {
    label: t('Contacts'),
    key: CONTACTS_SECTION_KEY,
    default: permissionGroup?.contacts_section?.enabled ?? false,
    allowed_access: ['none', 'view'],
    affordance: 'switch',
    permissions:
      buildContactPermissionConfigs(
        filteredContactFields,
        contact_object_id,
        updatedMetaConfig,
        existing_contact_values
      ) ?? [],
  };

  for (const config of contactConfig.permissions ?? []) {
    if (config.metadata) {
      sectionMetadatas[getStreamKey(config.key, CONTACTS_SECTION_KEY)] =
        config.metadata;
    }
  }

  const all_configs = co_configs.concat(sectionConfigs, contactConfig);
  const co_ids = new Set(co_configs.map(({ key }) => key));

  const name = reactive<string>(permissionGroup?.name ?? '');
  const sources = new Map(getSourceStreamEntries(all_configs));
  const permissions = new Map(
    Array.from(sources.entries(), ([key]) => {
      const metadata = co_ids.has(key as Section)
        ? { custom_object_id: key }
        : sectionMetadatas[key];
      return [key, new Permission(key, sources, metadata)];
    })
  );

  const rules = new Map(getRuleStreamEntries(permissions, all_configs));
  for (const [key, rule] of rules.entries()) {
    const permission = permissions.get(key)!;
    permission.applyRule(rule);
  }
  const visibility_rules = new Map(
    getVisibilityRuleStreamEntries(permissions, all_configs)
  );
  for (const [key, rule] of visibility_rules.entries()) {
    const permission = permissions.get(key)!;
    permission.applyVisibilityRule(rule);
  }

  const updates = merge<PermissionResultData, PermissionResultData>();
  const payload = sync({
    mergeOnly: true,
    src: {
      permissions_payload: updates.transform(payloadXform(permissionGroup)),
      name: isCreateNew ? name : name.transform(drop(1)),
    },
  }).map(({ permissions_payload, name }) => {
    return name ? { ...permissions_payload, name } : permissions_payload;
  });

  const fields_loaded = new Map(
    co_configs
      .map<
        [string, Stream<boolean>]
      >(({ key }) => [key, reactive<boolean>(false)])
      .concat([[contactConfig.key, reactive<boolean>(true)]])
  );

  // When subscribing one stream from another, the child subscription will only receive the last value of
  // the parent. We call addAll on updates only after setting up the payload subscription so all new
  // additions make there way to the payload result. We need this when creating a new permission group
  // to build out the entire default payload (all custom objects + meta config defaults). In the case of
  // editing a permission group we can simply transform each permission value with the `drop` transducer
  // to 'forget' the first input, meaning the payload result will be empty (no edits) when loading the modal.
  updates.addAll(
    Array.from(permissions.values(), (p) => {
      if (isCreateNew) return p.value;

      // KZN-7476: The full contact section payload needs to be sent when there are edits to any part of it.
      // Not dropping the values here means the contacts section may be sent in the request when it isn't updated
      // (depends on related fields from other objects - need to investigate).
      return p.sectionId === CONTACTS_SECTION_KEY
        ? p.value
        : p.value.transform(drop(1));
    })
  );

  const hasPermissionEdits = merge({
    src: Array.from(sources.values(), (str) => str.transform(drop(1))),
    xform: map((x) => x !== undefined),
  });

  return {
    existing_contact_values,
    existing_custom_object_values: permissionGroup
      ? createExistingCustomObjectValues(permissionGroup.custom_objects)
      : undefined,
    meta_config: updatedMetaConfig,
    contact_section: contactConfig,
    contact_object_id,
    co_sections: co_configs,
    sources,
    rules,
    permissions,
    visibility_rules,
    fields_loaded,
    updates,
    hasPermissionEdits,
    payload,
    name,
  };
};

const buildPermissionValues = (permissions: object, sectionId?: string) => {
  return Object.entries(permissions).reduce<Record<string, Access | boolean>>(
    (acc, [permission, structOrBool]: [string, AccessStruct | boolean]) => {
      const key = sectionId ? getStreamKey(permission, sectionId) : permission;
      const value =
        typeof structOrBool === 'boolean'
          ? structOrBool
          : getAccessValue(getAccessNumber(structOrBool));

      acc[key] = value;
      return acc;
    },
    {}
  );
};

const buildContactPermissionConfigs = (
  fields: Field[],
  contact_object_id: string,
  metaConfig: MetaConfig,
  existing_contact_values?: Record<string, PermissionValue>
) => {
  const contact_permissions = fields.map((f) => {
    const ctx = { meta_config: metaConfig, contact_object_id };
    if (!existing_contact_values)
      return createFieldPermissionConfig(f, CONTACTS_SECTION_KEY, ctx);

    const default_field_key = getStreamKey(f.name, CONTACTS_SECTION_KEY);
    const custom_field_key = getStreamKey(f.id, CONTACTS_SECTION_KEY);
    const def =
      default_field_key in existing_contact_values
        ? existing_contact_values[default_field_key]
        : existing_contact_values[custom_field_key];
    return createFieldPermissionConfig(
      f,
      CONTACTS_SECTION_KEY,
      ctx,
      { defaultValue: def as Access } // field permissions are never boolean values
    );
  }) as ContactsSectionConfig['permissions'];
  const contactsWithDefaults = existing_contact_values
    ? metaConfig.contacts.map((c) => {
        return {
          ...c,
          default:
            existing_contact_values[getStreamKey(c.key, CONTACTS_SECTION_KEY)],
        };
      })
    : metaConfig.contacts;

  return contact_permissions?.concat(contactsWithDefaults);
};

/**
 * Converts the contact section permission group response of AccessStruct values
 * into Access values for easy reference when creating new permission configs. The
 * `default_fields` and `custom_fields` arrays are converted into objects keyed by
 * field id.
 */
const createExistingContactsSectionValues = (
  {
    custom_fields,
    default_fields,
    enabled,
    ...rest
  }: PermissionGroup['contacts_section'],
  fields: Field[]
) => {
  const nameLookup = fields.reduce<Record<string, string>>((acc, field) => {
    acc[field.id] = field.name;
    return acc;
  }, {});
  return {
    ...(custom_fields?.reduce<Record<string, Access>>(
      (acc, { id, ...rest }) => {
        const key = getStreamKey(nameLookup[id], CONTACTS_SECTION_KEY);
        acc[key] = getAccessValue(getAccessNumber(rest));
        return acc;
      },
      {}
    ) ?? {}),
    ...(default_fields
      ? Object.entries(default_fields).reduce<Record<string, Access>>(
          (acc, [permission, struct]: [any, any]) => {
            if (permission !== 'last_name') {
              const key = getStreamKey(permission, CONTACTS_SECTION_KEY);
              acc[key] = getAccessValue(getAccessNumber(struct));
            }
            return acc;
          },
          {}
        )
      : {}),
    ...buildPermissionValues(rest, CONTACTS_SECTION_KEY),
  };
};

/**
 * Creates an object keyed by custom object id and converts AccessStruct values
 * into Access strings for easy reference when creating new permission configs.
 */
const createExistingCustomObjectValues = (
  custom_objects: PermissionGroup['custom_objects']
) => {
  return custom_objects
    .filter((co) => co.enabled)
    .reduce(
      (acc: any, { custom_object_id, fields, enabled, ...permissions }) => {
        acc[custom_object_id] = {
          ...buildPermissionValues(permissions),
          fields: fields.reduce<Record<string, Access>>(
            (a, { id, ...rest }) => {
              a[id] = getAccessValue(getAccessNumber(rest));
              return a;
            },
            {}
          ),
        };
        return acc;
      },
      {}
    );
};
