import { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { KizenTypography } from 'app/typography';
import { colorsButton } from 'app/colors';
import useField from 'hooks/useField';
import { TextEllipsis } from 'components/Kizen/Table';
import IconButton from 'components/Kizen/IconButton';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import { useTooltip, truncationPredicates } from 'components/Kizen/Tooltip';
import Handle from '../Handle';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import {
  Wrapper,
  StyledSelect,
  AnchorAsWrapper,
  ColorBar,
  Header,
  Body,
  PannelWrapper,
  StyledStepId,
  ThreeDotsMenu,
} from './styles';
import { PANEL_HEIGHT } from './constants';

const overflowXOrY = (el) =>
  truncationPredicates.x(el) || truncationPredicates.y(el);

const StepId = ({ stepId }) => {
  const [tooltipProps, tooltip] = useTooltip({
    label: stepId,
  });

  if (
    !stepId ||
    stepId.startsWith('new.') ||
    stepId.startsWith('add_trigger')
  ) {
    return false;
  }

  return (
    <StyledStepId {...tooltipProps}>
      {stepId.slice(0, 8)}
      {tooltip}
    </StyledStepId>
  );
};

export default function StepCard({
  color,
  title: titleProp,
  panel = null,
  showMenu: showMenuProp = null,
  addMenu = true,
  onSelectMenu,
  disableMenu = null,
  children,
  menuOptions = EMPTY_ARRAY,
  showMovingPlaceholder,
  cardComponentType = 'div',
  stepId = false,
  editOnly = false,
  ...props
}) {
  const buttonRef = useRef();
  const { t } = useTranslation();

  const titleTranslate = useMemo(
    () => ({
      Action: t('Action'),
      Condition: t('Condition'),
      Delay: t('Delay'),
      Goal: t('Goal'),
      Trigger: t('Trigger'),
      'Trigger (Manual)': t('Trigger (Manual)'),
      'Connection Trigger': t('Connection Trigger'),
    }),
    [t]
  );
  const title = titleTranslate[titleProp] || titleProp;
  const [showMenu, setShowMenu] = useField(
    (prev) => {
      if (Boolean(showMenuProp) === showMenuProp) {
        // Is boolean
        return showMenuProp;
      }
      if (prev === undefined) {
        return false;
      }
      return prev;
    },
    [showMenuProp]
  );
  const [tooltipProps, tooltip] = useTooltip({}, overflowXOrY);

  const WrapperComponent =
    cardComponentType === 'div' ? Wrapper : AnchorAsWrapper;

  const onButtonClick = () => {
    if (editOnly) {
      onSelectMenu?.('edit');
    } else {
      setShowMenu(true);
    }
  };

  return (
    <WrapperComponent {...props}>
      <ColorBar color={color} />
      <Header>
        <TextEllipsis weight="bold">{title}</TextEllipsis>
        <StepId stepId={stepId} />

        {addMenu ? (
          <ThreeDotsMenu>
            <IconButton
              title={t('Edit Item')}
              ref={buttonRef}
              disabled={disableMenu}
              onClick={onButtonClick}
              color={
                showMenu ? colorsButton.iconGray.hover : colorsButton.iconGray
              }
              sizing="dense"
            >
              <IconSizing size={editOnly ? '12px' : '15px'}>
                <Icon icon={editOnly ? 'pen' : 'three-dot'} />
              </IconSizing>
            </IconButton>
            {editOnly ? null : (
              <SelectOverlay
                target={buttonRef.current}
                show={showMenu}
                onHide={() => setShowMenu(false)}
                placement="bottom-end"
              >
                <StyledSelect
                  menuOnly
                  onChange={(val) => {
                    setShowMenu(false);
                    onSelectMenu?.(val);
                  }}
                  options={menuOptions}
                  data-qa="card-action-menu"
                />
              </SelectOverlay>
            )}
          </ThreeDotsMenu>
        ) : null}
      </Header>
      {children && (
        <>
          <Body {...tooltipProps} data-qa="card-body">
            {typeof children !== 'string' && children}
            {typeof children === 'string' && (
              <KizenTypography as="span">{children}</KizenTypography>
            )}
          </Body>
          {tooltip}
        </>
      )}
      {panel && <PannelWrapper>{panel}</PannelWrapper>}
      <Handle
        id="handle-left"
        type="target"
        position="left"
        style={{
          left: 0,
          top: panel ? `calc((100% - ${PANEL_HEIGHT}px) / 2)` : '50%',
        }}
      />
      <Handle
        id="handle-right"
        type="source"
        position="right"
        style={{
          right: 0,
          top: panel ? `calc((100% - ${PANEL_HEIGHT}px) / 2)` : '50%',
        }}
      />
      <Handle
        id="handle-top"
        type="target"
        position="top"
        style={{
          top: 0,
          right: '50%',
        }}
      />
      <Handle
        id="handle-bottom"
        type="source"
        position="bottom"
        style={{
          bottom: 0,
          right: '50%',
        }}
      />
    </WrapperComponent>
  );
}
