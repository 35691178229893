import AxiosService from './AxiosService';

class DomainService {
  constructor() {
    if (!DomainService.instance) {
      DomainService.instance = this;
    }
    return DomainService.instance;
  }

  createDomain = async (domain) => {
    const response = await AxiosService.post('/sending-domain', domain);
    return response.data;
  };

  getDomain = async () => {
    const response = await AxiosService.get('/sending-domain');
    return response.data;
  };

  deleteDomain = async (id) => {
    const response = await AxiosService.delete(`/sending-domain/${id}`);
    return response.data;
  };

  restartDomainVerification = async (id, verification_email) => {
    const response = await AxiosService.post(
      `/sending-domain/${id}/restart_verification`,
      {
        verification_email,
      }
    );
    return response.data;
  };

  createSubdomain = async (subdomain) => {
    const response = await AxiosService.post('/custom-domain', subdomain);
    return response.data;
  };

  getSubdomain = async () => {
    const response = await AxiosService.get('/custom-domain');
    return response.data;
  };

  deleteSubdomain = async (id) => {
    const response = await AxiosService.delete(`/custom-domain/${id}`);
    return response.data;
  };

  createSubdomainDistribution = async (id) => {
    const response = await AxiosService.post(
      `/custom-domain/${id}/create_distribution`
    );
    return response.data;
  };
}

const instance = new DomainService();
Object.freeze(instance);

export default instance;
