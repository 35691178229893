import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useDispatch } from 'react-redux';
import { serviceLogoutStart } from 'store/authentication/authenticationAction';
import { useHistory } from 'react-router-dom';

const Logout = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(serviceLogoutStart({ skipApi: false }));
    history.replace('/login');
  }, [dispatch, history]);

  return null;
};

export default Logout;
