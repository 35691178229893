import React, { useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useField from 'hooks/useField';
import { PreReleaseFeature } from 'hooks/usePreReleaseFeatures';
import CardToolbar from 'components/Layout/CardToolbar';
import { KizenTypography } from 'app/typography';
import MultiSelect from 'components/Inputs/MultiSelect';
import { validate } from 'components/Inputs/TextInput/presets/EmailAddress';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import useModal from 'components/Modals/useModal';

import { useSelectCreate } from 'components/Inputs/Select/hooks';
import Validation from 'components/Inputs/Validation';
import { VariableHeightSmallTableCard } from '../styles';
import { StyledInputsBox, Switch, Notice } from './styles';

import {
  DELETE_INCOMING_EMAIL,
  DELETE_INCOMING_DOMAIN,
  DELETE_OUTGOING,
} from '../consts';

const incomingEmailsToOption = (value) => ({
  value: value.toLowerCase(),
  label: value,
});
const incomingEmailsToValue = ({ value }) => value;

const outgoingEmailsToOption = ({ id: value, email: label }) => ({
  value,
  label,
});
const outgoingEmailsToValue = ({ value: id, label: email }) => ({
  id,
  email,
});

// tODO adjust this as need when I see the shape of the externalAccounts
const externalAccountsToOption = ({ id: value, email: label }) => ({
  value,
  label,
});

const Privacy = ({
  blockedEmails,
  saveBlockedEmails,
  externalAccounts,
  access,
}) => {
  const { t } = useTranslation();

  const [stagedBlockedEmails, setStagedBlockedEmails] = useField(
    blockedEmails,
    [blockedEmails]
  );

  const handleUpdate = async (value, errorMessage) => {
    const updatedblockedEmails = { ...stagedBlockedEmails, ...value };
    // optimistaclly update the stage values, after saving the current values
    const saved = stagedBlockedEmails;
    setStagedBlockedEmails(updatedblockedEmails);
    try {
      await saveBlockedEmails(updatedblockedEmails, errorMessage);
    } catch (error) {
      // undo the optimistic update
      setStagedBlockedEmails(saved);
    }
  };

  const [deleteValues, setDeleteValues] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteModalProps, , deleteModal] = useModal({
    handleSubmit: () => {
      const { updatedblockedEmails, errorMessage } = deleteValues;
      handleUpdate(updatedblockedEmails, errorMessage); // handleUpdate is dealing with any errors so we don't need to worry about it here
    },
  });

  const incomingOptions = stagedBlockedEmails.blockIncomingEmailsFrom.map((e) =>
    incomingEmailsToOption(e)
  );

  const validationRef = useRef();
  const [incomingSelectProps, incomingValidationProps] = useSelectCreate({
    existingOptions: incomingOptions,
    validateFunc: validate.withDomain,
    checkDuplicate: true,
    duplicateMessage: t('Emails must be unique.'),
    addNew: (opt) => {
      const updatedblockedEmails = {
        blockIncomingEmailsFrom: [
          ...stagedBlockedEmails.blockIncomingEmailsFrom,
          opt,
        ],
      };
      handleUpdate(updatedblockedEmails, t('Incoming Emails'));
    },
  });

  return (
    <VariableHeightSmallTableCard>
      <CardToolbar>
        <KizenTypography type="subheader">{t('Privacy')}</KizenTypography>
      </CardToolbar>

      <Notice>
        <KizenTypography>
          {t(
            'Set email privacy settings to block specific types of emails from appearing in your Kizen timeline.'
          )}
        </KizenTypography>
        <KizenTypography>
          <KizenTypography as="span" weight="bold">
            {`${t('Block Incoming Emails From')}:`}
          </KizenTypography>{' '}
          {t(
            'Block communications from a specific email address or entire domain from appearing in your Kizen timeline. To block all emails from a whole domain (e.g. domain.com), simply add “@domain.com” in the Block Emails From field.'
          )}
        </KizenTypography>
        <PreReleaseFeature>
          <KizenTypography>
            <KizenTypography as="span" weight="bold">
              {`${t('Block Outgoing Emails From')}:`}
            </KizenTypography>{' '}
            {t(
              'Block communications sent from an integrated inbox from appearing in your Kizen timeline.'
            )}
          </KizenTypography>
        </PreReleaseFeature>
        <KizenTypography>
          <KizenTypography as="span" weight="bold">
            {`${t('Block All Internal Emails')}:`}
          </KizenTypography>{' '}
          {t(
            'Block internal emails from appearing on your timeline for any domain with a connected inbox in your Kizen business. For instance, if you’ve connected an inbox ending in @domain.com, all internal communications from *@domain.com to *@domain.com will not appear in the timeline.'
          )}
        </KizenTypography>
      </Notice>
      <StyledInputsBox>
        <MultiSelect
          ref={validationRef}
          label={t('Block Incoming Emails From')}
          value={incomingOptions}
          onChange={(vals, { action, option }) => {
            const newState = {
              blockIncomingEmailsFrom: vals.map((e) =>
                incomingEmailsToValue(e)
              ),
            };

            if (action === 'remove-value') {
              setDeleteMessage(
                (option || '').charAt(0) === '@'
                  ? DELETE_INCOMING_DOMAIN(t)
                  : DELETE_INCOMING_EMAIL(t)
              );
              setDeleteValues({
                updatedblockedEmails: newState,
                errorMessage: t(
                  'The incoming emails were not successfully updated.'
                ),
              });
              deleteModal.show();
            } else {
              handleUpdate(
                newState,
                t('The incoming emails were not successfully updated.')
              );
            }
          }}
          {...incomingSelectProps}
          placeholder={t('Enter Emails & Press Enter')}
          endAdornment={<i />} // simple component to clear the adornmet
          error={!!incomingValidationProps.showMessage}
          margin
          menuIsOpen={false}
          hideRemoveIcon={!access.privacy.remove}
        />
        <Validation
          {...incomingValidationProps}
          target={validationRef.current}
          errorPlacement="bottom-start"
        />
        <PreReleaseFeature>
          <MultiSelect
            label={t('Block Outgoing Emails From Connected inboxes')}
            value={stagedBlockedEmails.blockOutgoingEmailsFrom.map((e) =>
              outgoingEmailsToOption(e)
            )}
            onChange={(vals, { action }) => {
              const newState = {
                blockOutgoingEmailsFrom: vals.map((e) =>
                  outgoingEmailsToValue(e)
                ),
              };
              if (action === 'remove-value') {
                setDeleteMessage(DELETE_OUTGOING(t));
                setDeleteValues({
                  updatedblockedEmails: newState,
                  errorMessage: t(
                    'The outgoing emails were not successfully updated.'
                  ),
                });
                deleteModal.show();
              } else {
                handleUpdate(
                  newState,
                  t('The outgoing emails were not successfully updated.')
                );
              }
            }}
            placeholder={t('Choose Connected Inboxes')}
            endAdornment={<i />} // simple component to clear the adornmet
            margin
            options={externalAccounts.map((ea) => externalAccountsToOption(ea))}
            menuInline
            hideRemoveIcon={!access.privacy.remove}
          />
        </PreReleaseFeature>
        <Switch
          label={t('Block All Internal Emails')}
          textPlacement="left"
          checked={!stagedBlockedEmails.enableInternalEmailSync}
          onChange={(ev) =>
            handleUpdate(
              {
                enableInternalEmailSync: !ev.target.checked,
              },
              t('All internal emails could not be updated')
            )
          }
        />
        <ConfirmDeletionModal {...deleteModalProps}>
          {deleteMessage}
        </ConfirmDeletionModal>
      </StyledInputsBox>
    </VariableHeightSmallTableCard>
  );
};

Privacy.propTypes = {
  blockedEmails: PropTypes.shape({
    blockIncomingEmailsFrom: PropTypes.array.isRequired,
    blockOutGoingEmailsFrom: PropTypes.array.isRequired,
    enableInternalEmailSync: PropTypes.bool.isRequired,
  }).isRequired,
  saveBlockedEmails: PropTypes.func.isRequired,
  externalAccounts: PropTypes.array.isRequired,
};
export default Privacy;
