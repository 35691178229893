import {
  DISPLAY_VALUE_RESPONSES,
  VALUE_TO_DISPLAY_RESPONSES,
} from 'components/Wizards/Dashlet/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AreaLine from '..';

const AreaLineDashletAdapter = ({ data, dashlet, currencySymbol, ...rest }) => {
  const { t } = useTranslation();
  const chartData = useMemo(() => {
    if (data?.data) {
      const stagesById =
        data.metadata?.stages?.reduce((acc, curr) => {
          acc[curr.id] = curr;

          return acc;
        }, {}) ?? {};
      const breakdownData = data.data
        .filter((series) => series.type === 'stages_breakdown')
        .map((series) => {
          const stage = stagesById[series.stage_id];
          return {
            id: series.stage_id,
            category: stage,
            values: series.values,
            order: stage.order,
          };
        })
        .sort((a, b) => b.order - a.order);

      return breakdownData;
    }
    return [];
  }, [data]);

  const tooltipLabels = useMemo(() => {
    return (
      data?.metadata?.tooltip_label?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.date]: curr.label,
        };
      }, {}) ?? {}
    );
  }, [data]);

  const isValue =
    dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE ||
    dashlet?.config?.metricType === VALUE_TO_DISPLAY_RESPONSES.WEIGHTED;
  const isWeighted =
    dashlet?.config?.metricType === VALUE_TO_DISPLAY_RESPONSES.WEIGHTED;

  const frequency = data?.frequency;

  return (
    <AreaLine
      data={chartData}
      dashlet={dashlet}
      tooltipLabels={tooltipLabels}
      yAxisTitle={
        isValue
          ? `${
              isWeighted ? t('Weighted Value') : t('Total Value')
            } (${currencySymbol})`
          : t('# Records')
      }
      isValue={isValue}
      isWeighted={isWeighted}
      frequency={frequency}
      currencyMode={isValue}
      currencySymbol={currencySymbol}
      {...rest}
    />
  );
};

export default AreaLineDashletAdapter;
