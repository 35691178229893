import { useCallback, useContext } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';
import LeadsAddedBySourceReportType from './reportTypes/LeadsAddedBySource';
import LeadsAddedReportType from './reportTypes/LeadsAdded';
import LeadSourceBreakdownReportType from './reportTypes/LeadSourceBreakdown';

const MarketingDisplaySettings = ({ defaultName = '', active = false }) => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const { name, area } = state;

  const onChangeName = useCallback(
    (value) => {
      return setState('name', value);
    },
    [setState]
  );

  // Hide self if area is not marketing
  if (area !== AREA_RESPONSES.MARKETING) {
    return null;
  }

  return (
    <>
      <LeadsAddedBySourceReportType
        name={name}
        defaultName={defaultName}
        handleSetName={onChangeName}
        active={active}
      />
      <LeadsAddedReportType
        name={name}
        defaultName={defaultName}
        handleSetName={onChangeName}
        active={active}
      />
      <LeadSourceBreakdownReportType
        name={name}
        defaultName={defaultName}
        handleSetName={onChangeName}
        active={active}
      />
    </>
  );
};

export default MarketingDisplaySettings;
