import styled from '@emotion/styled';
import { appBackground, colorsButton, grayScale } from '__app/colors';
import { borderRadii } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import Button from '__components/Button';
import Switch from '__components/Kizen/Switch';
import { StyledCard } from '../styles';
import css from '@emotion/css';

export const StyledVariablesInfo = styled(KizenTypography)`
  background-color: ${appBackground};
  padding: 10px;
  margin-bottom: 20px;
`;

export const StyledAddVariableButton = styled(Button)`
  margin: 0;
  margin-top: 2px;
  padding-left: 0;
`;

export const StyledVariablesCard = styled(StyledCard)`
  padding-bottom: 400px;
`;

export const StyledVariablesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledVariableWarpper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const StyledVariableCard = styled.div<{ hasError: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 15px;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? colorsButton.red.default : grayScale.medium};
  border-radius: ${borderRadii.small};
`;

export const StyledRequiredSwitch = styled(Switch)`
  height: 100%;
  & > div {
    margin-bottom: 20px;
  }
`;

export const StyledDiv = styled.div<{ width: number }>`
  ${({ width }) => css`
    width: ${width}px;
  `}
`;

export const StyledBottomButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
