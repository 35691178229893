import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import Icon from 'components/Kizen/Icon';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import TypeCreateEntity from './images/type-create-entity.svg?react';
import TypeCreateFields from './images/type-create-fields.svg?react';
import TypeCreateTeamAndActivities from './images/type-create-team-and-activities.svg?react';
import TypeCreateActionBlock from './images/type-create-action-block.svg?react';
import TypeCreateLeadSources from './images/type-create-lead-sources.svg?react';
import TypeCreateTimeline from './images/type-create-timeline.svg?react';
import TypeCreateRelatedPipelineFields from './images/type-create-related-pipeline-fields.svg?react';

const CompoundIcon = styled.div`
  display: flex;
  column-gap: 10px;

  > i {
    pointer-events: none;
  }

  height: 40px;
  margin-bottom: 8px;
`;

const getOptions = ({
  t,
  showAllOptions = false,
  canUseRelatedPipelinesBlock,
}) => {
  const defaultOptions = [
    {
      value: 'related_object_fields',
      label: t('Related Object Fields'),
      image: <TypeCreateEntity />,
    },
  ];

  if (showAllOptions) {
    return [
      {
        value: 'fields',
        label: t('Fields'),
        image: <TypeCreateFields />,
      },
      {
        value: 'team_and_activities',
        label: t('Team and Activities'),
        image: <TypeCreateTeamAndActivities />,
      },
      {
        value: 'action_block',
        label: t('Action Block'),
        image: <TypeCreateActionBlock />,
      },
      {
        value: 'lead_sources',
        label: t('Lead Sources'),
        image: <TypeCreateLeadSources />,
      },
      {
        value: 'timeline',
        label: t('Timeline'),
        image: <TypeCreateTimeline />,
      },
      canUseRelatedPipelinesBlock
        ? {
            value: 'related_pipelines',
            label: t('Related Pipelines'),
            image: <TypeCreateRelatedPipelineFields />,
          }
        : null,
      {
        value: 'related_object_fields',
        label: t('Related Object Fields'),
        image: <TypeCreateEntity />,
      },
      {
        value: 'custom_content',
        label: t('Custom Content (Builder)'),
        image: (
          <CompoundIcon>
            <Icon icon="text" />
            <Icon icon="image-landscape" />
          </CompoundIcon>
        ),
      },
    ].filter(Boolean);
  }

  return defaultOptions;
};

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: ${gutters.spacing(4)}px;
  row-gap: ${gutters.spacing(4)}px;
`;

export default function GroupedButtonGrid({
  onChange,
  value,
  allowNoChildStep,
  showAllOptions,
  canUseRelatedPipelinesBlock,
}) {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();

  const options = useMemo(
    () =>
      getOptions({
        preReleaseFeatures,
        allowNoChildStep,
        t,
        showAllOptions,
        canUseRelatedPipelinesBlock,
      }),
    [
      preReleaseFeatures,
      allowNoChildStep,
      t,
      showAllOptions,
      canUseRelatedPipelinesBlock,
    ]
  );

  return options?.length ? (
    <ButtonGrid>
      {options.map((option) => (
        <ButtonGroupImageButton
          key={option.value}
          selected={option.value === value}
          onClick={() => onChange(option)}
          option={option}
          data-qa={option.value}
        />
      ))}
    </ButtonGrid>
  ) : (
    ''
  );
}
