import React from 'react';
import FontIcon from './FontIcon';

export default function CalendarIcon({ icon, size, ...others }) {
  return (
    <FontIcon
      style={{ fontSize: 'inherit' }}
      icon={['fal', 'calendar-day']}
      {...others}
    />
  );
}
