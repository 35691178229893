import React, { useRef, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import useToggle from 'react-use/lib/useToggle';
import useClickAway from 'react-use/lib/useClickAway';
import { optionShape } from 'components/Kizen/Select';
import Icon from 'components/Kizen/Icon';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { ButtonMenu, Wrapper } from './styles';
import { ICON_BUTTON_DELAY, useTooltip } from 'components/Kizen/Tooltip';

export const SettingsButton = ({
  color,
  disabled,
  options,
  onClick,
  onChange,
  children,
  variant,
  ...others
}) => {
  const { t } = useTranslation();
  const [open, toggleOpen] = useToggle(false);
  const dropdownRef = useRef(null);
  useClickAway(dropdownRef, () => toggleOpen(false));

  const handleChange = useCallback(
    (value) => {
      toggleOpen(false);
      if (onChange) {
        onChange(value);
      }
    },
    [toggleOpen, onChange]
  );
  const [tooltipProps, tooltip] = useTooltip({
    label: t('Settings'),
    delay: ICON_BUTTON_DELAY,
  });

  return (
    <Wrapper ref={dropdownRef} {...others}>
      <Button
        variant={variant}
        color={color}
        disabled={disabled}
        onClick={toggleOpen}
        title={t('Settings')}
        {...tooltipProps}
        data-qa="custom-object-settings-button"
      >
        <Icon icon="cog" color="inherit" />
        <Icon icon="down" color="inherit" />
      </Button>
      {open && !disabled && (
        <ButtonMenu
          classNamePrefix="ButtonMenu"
          menuIsOpen
          menuPlacement="bottom"
          filterOption={null}
          hideSelectedOptions={false}
          options={options}
          onChange={handleChange}
          {...others}
        />
      )}
      {tooltip}
    </Wrapper>
  );
};

SettingsButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(optionShape).isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'text']),
};

SettingsButton.defaultProps = {
  disabled: false,
  color: 'green',
  onClick: null,
  onChange: null,
  variant: 'default',
};
