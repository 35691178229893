import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TRow } from 'components/Kizen/Table';
import { getTemplatesColumns } from './columns';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import BasicTable from 'components/Tables/Basic';
import styled from '@emotion/styled';
import TablePagination from 'components/Tables/Big/TablePagination';
import { gutters } from 'app/spacing';
import { border, grayScale } from 'app/colors';
import { getOrderingParam } from 'utility/SortingHelpers';
import LibraryService from 'services/LibraryService';
import Loader from 'components/Kizen/Loader';
import { css } from '@emotion/core';
import { useAsync } from 'react-use';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const CenteredLoader = styled(Loader)`
  position: absolute;
  top: 60%;
  left: 50%;
  padding-top: 0 !important;
  transform: translate(-50%, 0%);
`;
const TableWrapper = styled.div`
  ${border}
  border-color: ${grayScale.mediumLight};
`;

const StyledTableScrollContainer = styled(TableScrollContainer)`
  max-height: calc(100vh - 461px);

  ${({ loading }) => {
    return (
      loading &&
      css`
        min-height: calc(100vh - 461px);
        height: 100%;
      `
    );
  }}
`;
const PaginationWrapper = styled.div`
  padding: ${gutters.spacing(4)}px 0 ${gutters.spacing(0, 2)}px 0;
`;

const initialConfig = {
  ordering: {
    column: 'name',
    direction: 'asc',
  },
  page: {
    number: 1,
    size: 50,
    search: '',
  },
  loaded: false,
};
const Templates = ({
  t,
  onSubmit,
  onActionView,
  toolbar,
  setItemsCount,
  search,
}) => {
  const [config, setConfig] = useState(initialConfig);
  const { ordering, page } = config;
  const loadedOnce = useRef(true);
  const prevData = useRef({});

  useEffect(() => {
    if (!loadedOnce.current)
      setConfig((prev) => ({
        ...prev,
        page: { ...prev.page, number: 1, search },
      }));
  }, [search]);

  const { value: data = {}, loading } = useAsync(async () => {
    const ordering = getOrderingParam(config.ordering);
    const data = await LibraryService.getAllTemplates({
      ...config,
      page: {
        ...config.page,
      },
      ordering,
    });
    loadedOnce.current = false;
    setItemsCount(data.count);
    return data;
  }, [config, search]);

  if (!loading) prevData.current = data;
  const { results: templates = [], count = 0 } = prevData.current;

  const handleChangeSort = useCallback(
    ({ column, direction }) =>
      setConfig((prev) => ({ ...prev, ordering: { column, direction } })),
    [setConfig]
  );

  const handleChangePageNumber = useCallback(
    (value) =>
      setConfig((prev) => ({
        ...prev,
        page: {
          ...prev.page,
          number: value,
        },
      })),
    [setConfig]
  );

  const handleChangePageSize = useCallback(
    (value) =>
      setConfig((prev) => ({
        ...prev,
        page: {
          ...prev.page,
          number: 1,
          size: value,
        },
      })),
    [setConfig]
  );

  const templatesHeadData = useMemo(
    () => ({
      meta: {
        sort: ordering,
        onSort: (column, direction) => handleChangeSort({ column, direction }),
      },
    }),
    [ordering, handleChangeSort]
  );

  const templatesColumns = useMemo(
    () =>
      getTemplatesColumns({
        onSubmit,
        onActionView,
        t,
      }),
    [onSubmit, onActionView, t]
  );
  return (
    <>
      {toolbar}
      <TableWrapper>
        <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
          <ScrollContainerBlocker />
        </ScrollContainerBlockerWrapper>
        <StyledTableScrollContainer
          loading={loadedOnce.current && loading}
          bottom
          left
          right
          refresh={[templates]}
          rounded
        >
          <BasicTable
            stickyHeader
            columns={templatesColumns}
            head={
              <TRow head columns={templatesColumns} data={templatesHeadData} />
            }
          >
            {templates &&
              templates.map((item) => (
                <TRow key={item.id} columns={templatesColumns} data={item} />
              ))}
            {loadedOnce.current && loading && <CenteredLoader loading />}
          </BasicTable>
        </StyledTableScrollContainer>
      </TableWrapper>
      <PaginationWrapper>
        <TablePagination
          page={page.number}
          perPage={page.size}
          totalCount={count}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      </PaginationWrapper>
    </>
  );
};

Templates.propTypes = {};

export default Templates;
