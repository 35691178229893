import { useRef } from 'react';
import useAsync from 'react-use/lib/useAsync';
import FieldService, { isFieldAvailable } from '../services/FieldService';
import { useTranslation } from 'react-i18next';

const teamMemberMergeFields = (t) => [
  {
    label: t('Team Member First Name'),
    relationship: 'team_member.first_name',
  },
  {
    label: t('Team Member Last Name'),
    relationship: 'team_member.last_name',
  },
  {
    label: t('Team Member Email'),
    relationship: 'team_member.email',
  },
  {
    label: t('Team Member Signature'),
    relationship: 'team_member.signature',
  },
];

export const useMergeFieldsBase = ({
  fieldsFor,
  mergeFieldName,
  fieldsToDisplayPredicate,
  teamMemberCategoryName,
}) => {
  const { t } = useTranslation();
  const slugsToIds = useRef({});
  const { loading, value: categorizedFieldOptions = [] } =
    useAsync(async () => {
      const idSlugMap = {};

      const createSlug = (str) => str;

      const categorizedFields =
        await FieldService.getCategorizedFields(fieldsFor);

      const getMergeFieldValue = (field) => {
        if (field.isDefault) {
          return `${mergeFieldName}.${field.name}`;
        }

        const slug = createSlug(field.name);
        idSlugMap[field.id] = slug;
        slugsToIds.current[slug] = field.id;
        return `${mergeFieldName}.${slug}`;
      };

      // TODO Try re-adding city and country fields?
      return categorizedFields.map(({ name, fields, id }) => {
        const fieldsToDisplay = fields
          .filter(isFieldAvailable)
          .filter(fieldsToDisplayPredicate);

        return {
          label: name,
          key: id,
          items: fieldsToDisplay.map((field) => ({
            label: field.displayName,
            relationship: getMergeFieldValue(field),
            key: field.id,
          })),
        };
      });
    }, []);

  return {
    categorizedFieldOptions: categorizedFieldOptions.concat({
      label: teamMemberCategoryName || t('Team Member'),
      key: 'team_member_group',
      items: teamMemberMergeFields(t),
    }),
    loading,
    slugsToIds,
  };
};

const contactSetup = {
  fieldsFor: { for: 'contacts' },
  mergeFieldName: 'contact',
  fieldsToDisplayPredicate: () => true,
};

export const useContactsMergeFields = () => useMergeFieldsBase(contactSetup);

export const useMergeFields = ({
  isForCustomObject,
  customObjectClassName,
  customObject,
  mergeFieldName,
  teamMemberCategoryName,
}) =>
  useMergeFieldsBase({
    fieldsFor: {
      for: isForCustomObject ? customObject : customObjectClassName,
    },
    mergeFieldName: mergeFieldName || customObjectClassName,
    fieldsToDisplayPredicate: () => true,
    teamMemberCategoryName,
  });
