import styled from '@emotion/styled';
import {
  colorsButton,
  colorsText,
  grayScale,
  shadow,
  border,
  dropdownColors,
} from 'app/colors';
import {
  fontWeights,
  fontSizes,
  TextSpan,
  KizenTypography,
} from 'app/typography';
import { scrollbarCss, gutters, borderRadii, layers } from 'app/spacing';
import { css } from '@emotion/core';

export const StyledLabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: ${gutters.standard};
`;

export const StyledLabel = styled(TextSpan)`
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
`;

const StyledSelect = styled.div`
  position: relative;
  ${({ fullWidth, mobile }) => {
    if (fullWidth) {
      return css``;
    }
    if (mobile) {
      // Note, this prescribed sizing essentially
      // requires that the mobile version go in a page overlay
      // in order to prevent overflowing the page boundary.
      return css`
        width: 90vw;
        max-width: 260px; // 2px from border
      `;
    }
    return css`
      max-width: 387px; // 2px from border
    `;
  }}
  .option {
    color: ${colorsText.dark};
    cursor: pointer;
    :hover: {
      background-color: ${colorsButton.blue.default};
      color: #ffffff;
    }
  }

  .control {
    ${border};
    border-radius: 4px;
    ${({ open, expanded, dropUp }) =>
      (open || expanded) && !dropUp
        ? `border-radius: 4px 4px 0px 0px;`
        : `border-radius: 0px 0px 4px 4px;`};
    min-height: 36px;
    outline: none;
    position: relative;
    cursor: default;
    background-color: ${grayScale.white};

    .search-container {
      display: flex;
      margin: 0;
      height: 36px;
      align-items: center;
      .search {
        padding: 10px 12px;
        border: none;
        outline: none;
        width: 100%;
        border-radius: 4px;
        ::placeholder {
          color: ${grayScale.mediumDark};
        }
      }

      .search-icon {
        padding: 0 12px 0 0;
        svg {
          width: 12px;
          height: auto;
          color: ${(props) =>
            props.disabled ? grayScale.mediumDark : colorsText.dark};
        }
      }
    }

    .selected-values {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // Top padding comes from the items themselves, 5px of horiz padding comes from the items
      padding-left: calc(10px - 5px);
      // 4px from the permanent scrollbar
      padding-right: calc(10px - 5px - 4px);
      overflow-y: scroll;
      max-height: 126px;

      ${scrollbarCss({ track: false })}

      &:empty {
        display: none;
      }

      > * {
        flex: 1 0 calc(115px - 1px); // 1px accounts for borders, so two pills can show in mobile-sized selector
        min-width: 0;
        // Unclear why setting width in addition to min-width is needed,
        // but it fixed an odd bug where the hidden, overflowed text in the
        // pill could actually stretch .selected-values.  Weird, right?
        width: 0;
        margin: 10px 5px 0;
        &:empty {
          margin-top: 0;
        }
      }

      .selected-value {
        display: flex;
        padding: calc(7px - 1px) 7px calc(7px - 3px) 7px;
        border: 1px solid ${grayScale.medium};
        border-radius: 4px;
        border-left: 3px solid ${colorsButton.blue.default};

        .selected-value-text {
          display: inline-block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-left: 7px;
        }

        .selected-value-remove {
          cursor: pointer;
          color: ${grayScale.mediumDark};
          margin-bottom: 1px;
          font-size: 95%;
          ${({ disabled }) =>
            disabled &&
            css`
              background-color: ${grayScale.light};
              pointer-events: none;
            `};
        }
      }
    }
  }

  .menu {
    position: absolute;
    z-index: ${layers.content(1)};
    width: 100%;
    background-color: ${grayScale.white};

    ${(props) =>
      props.dropUp
        ? css`
            top: auto !important;
            bottom: 100% !important;
            ${border};
            border-bottom: 0;
            border-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          `
        : css`
            ${border};
            border-top: 0;
            border-radius: 4px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            ${shadow};
          `}

    .menu-header {
      margin-top: 4px; // in total 15px above "select all" button

      > button,
      button.select-all-button {
        margin: 0;
        padding: 0 12px;
        height: 30px;
        line-height: 32px;
        font-weight: ${fontWeights.bold};
      }
    }

    .menu-footer {
      display: flex;
      justify-content: space-between;
      padding: ${gutters.standard} 12px;
      > button {
        height: unset;
        font-size: ${fontSizes.small};
        line-height: 1;
      }
    }

    .menu-values {
      padding: 0;
      max-height: 36rem;
      overflow-y: auto;

      ${scrollbarCss({ track: false })}

      .menu-value {
        margin: 0;
        padding: 0 12px;
        display: flex;
        height: 32px;
        align-items: center;

        > * {
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.selected {
          background-color: unset;
          color: ${colorsText.medium};
          cursor: default;
        }

        &.unselected {
          color: ${colorsText.dark};
          cursor: pointer;

          :hover {
            background-color: ${dropdownColors.hover};
          }
        }
      }

      .no-options {
        padding: 1rem;
        cursor: default;
        text-align: center;
      }
    }
  }
`;

// Underlined

export const StyledUnderlinedLabelWrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const shrinkStyles = () => css`
  // scaling to 78.5% will take the 14px down to the 11px we want
  // The 4px will put the label 10px above the capline of the input text
  transform: translate(0, 4px) scale(0.785);
  // Account for the scaling when computing the width of the element
  width: calc(100% / 0.785);
  font-weight: ${fontWeights.bold};
  // Undoes the pointer-events: none we set to allow the input to be focused through the label
  // this is so hover actions for truncationTooltip will work
  pointer-events: auto;
`;

const truncateStyles = () => css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledUnderlinedLabel = styled(TextSpan)`
  z-index: ${layers.content(0, 1)};
  position: absolute;
  top: 0;
  left: 0;
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
  // Allow the user to focus/hover the input with the label in the way
  pointer-events: none;
  // 22px will put the label baseline exactly 10px from the bottom border
  transform: translate(0, 22px) scale(1);
  transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transform-origin: top left;
  ${({ shrink }) => shrink && shrinkStyles};
  ${truncateStyles};
`;

const StyledUnderlinedSelect = styled(StyledSelect)`
  .control {
    min-height: calc(38px + 1px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid ${grayScale.medium};
  }
  &&& {
    .search-container {
      height: 28px;
      border-top-left-radius: ${borderRadii.small};
      border-top-right-radius: ${borderRadii.small};
      align-items: center;
      .search {
        padding-top: 0;
      }
      .search-icon {
        padding-top: 0;
      }
      ${({ disabled }) =>
        disabled &&
        css`
          background-color: ${grayScale.light};
        `};
    }
  }
  &&& {
    .search {
      border-radius:0;
      padding: 0px;
      padding-top: ${({ hasValues }) => (hasValues ? '1px' : '10px')};
      ::placeholder {
        transition: opacity 10ms;
        opacity: ${({ shrink }) => (shrink ? 1 : 0)};
        color: ${grayScale.mediumDark};
      }
      ${({ disabled }) =>
        disabled &&
        css`
          background-color: ${grayScale.light};
          pointer-events: none;
        `};
  }
  .search-icon {
    padding: ${({ hasValues }) => (hasValues ? '1px' : '10px')} 10px 0 0;
    height: calc(100% - 2px);
    svg {
      width: 10px;
      height: auto;
      color: ${grayScale.mediumDark};
    }
  }
  && {
    .selected-values {
      padding-left: 0;
      padding-top: 8px;
      // accounts for 5px padding on individual selected values
      margin-left: -5px;
      margin-right: -5px;

      // We want to create the illusion of a margin here when the multi-select input needs scroll bars.
      // Otherwise, the chips overlap with the label
      clip-path: inset(18px 0 0 0);
      max-height: 116px; // Exactly the height of three pills and the padding between then
      &::-webkit-scrollbar-track:vertical {
        margin-top: 18px;
      }
    }
  }
  &:hover {
    .control {
      ${({ disabled }) =>
        !disabled &&
        css`
          border-color: ${colorsButton.blue.hover};
        `};
    }
    .search-icon {
      svg {
        ${({ disabled }) =>
          !disabled &&
          css`
            color: ${grayScale.dark};
          `};
      }
    }
  }
`;

export const StyledAddOptionButton = styled(KizenTypography)`
  align-items: center;
  cursor: pointer;
`;

export { StyledUnderlinedSelect, StyledSelect as default };
