import React from 'react';
import {
  Nav as BootstrapNav,
  Navbar as BootstrapNavBar,
} from 'react-bootstrap';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from 'components/Kizen/Logo';
import { shadowLight } from 'app/colors';
import { breakpoints } from 'app/spacing';
import { navColors, pageSizingStyles } from './styles';

const StyledPublicNavBar = styled.div`
  background-color: ${navColors.background};
  border-bottom: 1px solid ${navColors.border};

  position: relative;
  box-shadow: none;

  & .navbar {
    max-width: 100%;
    min-height: 50px;
  }

  @media (max-width: ${breakpoints.md}px) {
    & .navbar-brand {
      padding: 0;
    }
  }

  @media (min-width: ${breakpoints.md + 1}px) {
    ${shadowLight}
    & .navbar {
      ${pageSizingStyles};
    }
  }
`;

const PublicNavBar = ({ ...rest }) => {
  const user = useSelector(({ authentication }) => authentication.user);
  if (user) return null;
  return (
    <StyledPublicNavBar {...rest}>
      <BootstrapNavBar variant="light" expand="md">
        <BootstrapNavBar.Brand>
          <BootstrapNav.Link as={Link} to="/">
            <Logo height="30px" width="26px" />
          </BootstrapNav.Link>
        </BootstrapNavBar.Brand>
      </BootstrapNavBar>
    </StyledPublicNavBar>
  );
};

export default PublicNavBar;
