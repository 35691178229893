import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { namedToOption } from 'services/helpers';
import FieldService from 'services/FieldService';
import { getDataQAForInput } from 'components/Inputs/helpers';
import MultiSelect, {
  multiSelectSortValue,
} from 'components/Inputs/MultiSelect';
import { ViewOnlyMultiSelect } from 'components/Inputs/MultiSelect/ViewOnlyMultiSelect';
import { getSelectedValueLink } from 'components/Inputs/MultiSelect/customize';
import {
  Entities,
  useSelectEnter,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { TopMenuButton } from 'components/Kizen/Menu';
import BasicModal from 'components/Modals/presets/BasicModal';
import TextInput from 'components/Inputs/TextInput';
import MultiSelectInline from 'components/Inputs/inline/MultiSelect';
import { useAddObjectModal } from '../FieldInput/helpers';
import { getLinkDataForTag, specialTagTypes } from '../helpers';
import { applyRef } from 'components/Inputs/props';
import { usePreserveCaretPositionActive } from 'hooks/usePreserveCaretPosition';
import { DYNAMIC_TAGS_XFORM } from 'components/Tables/DynamicTagsManager/constants';

const tagToOption = ({ id, label, name }) => {
  return { value: id, label: label || name };
};

const transformTagsUp = (values) =>
  values.map(({ value, label }) => ({ id: value, label }));

const transformTagsDown = (values) => {
  return (values || []).map(tagToOption);
};

// Implementation based on FieldInput/DynamicTags
export const DynamicTagsSelect = React.forwardRef((props, ref) => {
  const {
    field,
    value,
    fetchUrl,
    onChange,
    disabled,
    onModalOpen,
    serviceToUse,
    objectId,
    handleUpdateFieldOption,
    onFocus,
    onMenuOpen,
    onMenuClose,
    selectOverlayProps,
    enableLink = true,
    ...others
  } = props;
  const { t } = useTranslation();

  const LABEL_DATA = {
    reasons_disqualified: {
      placeholder: t('Find Reasons Disqualified'),
      menuTopButton: t('Add Reason Disqualified'),
      modalHeader: t('Add Reason Disqualified'),
      modalInputPlaceholder: t('Enter Reason Disqualified'),
    },
    reasons_lost: {
      placeholder: t('Find Reasons Lost'),
      menuTopButton: t('Add Reason Lost'),
      modalHeader: t('Add Reason Lost'),
      modalInputPlaceholder: t('Enter Reason Lost'),
    },
    titles: {
      placeholder: t('Find Titles'),
      menuTopButton: t('Add Title'),
      modalHeader: t('Add Title'),
      icon: 'search',
      modalInputPlaceholder: '',
    },
    tags: {
      placeholder: t('Find Tags'),
      menuTopButton: t('Add Tag'),
      modalHeader: t('Add Tag'),
      modalInputPlaceholder: '',
    },
    default: {
      placeholder: t('Find Options'),
      menuTopButton: t('Add Tag'),
      modalHeader: t('Add Tag'),
      modalInputPlaceholder: '',
    },
  };

  const chosenValueIds = useMemo(
    () => (value || []).map((item) => item.value ?? item.id),
    [value]
  );

  const searchTags = useCallback(
    ({ search = '', page = 1 }, options) => {
      return serviceToUse.getDynamicTagsOptions(
        field.id,
        objectId,
        page,
        search,
        options
      );
    },
    [serviceToUse, field.id, objectId]
  );

  const selectRef = useRef(null);

  const mergeRef = useCallback(
    (el) => {
      applyRef(selectRef, el);
      applyRef(ref, el);
    },
    [ref]
  );

  const [typeaheadProps, typeaheadState, { refetch }] =
    useSelectTypeaheadWithScroll({
      entity: Entities.DynamicTags,
      fetch: searchTags,
      objectToOption: tagToOption,
      alwaysOpen: true,
      onFocus,
      fieldId: field.id,
      onMenuOpen,
      onMenuClose: selectOverlayProps,
      selectRef,
      chosenValueIds,
    });

  const inputProps = usePreserveCaretPositionActive({
    xform: DYNAMIC_TAGS_XFORM,
  });

  const handleSubmitModal = async () => {
    // Eagerly create dynamic tag so that its pill immediately clickable
    const tag = await serviceToUse.createDynamicTag({
      fieldId: field.id,
      name: inputProps.value.trim(),
      objectId,
    });

    if (typeof handleUpdateFieldOption === 'function')
      handleUpdateFieldOption({ id: field.id, newOption: tag });
    onChange(multiSelectSortValue([...value, namedToOption(tag)]));
    refetch();
  };

  const [modalProps, , { onOpen, modalSubmitting }, validateMessage] =
    useAddObjectModal({
      handleSubmit: handleSubmitModal,
    });

  const onStartCreate = () => {
    onOpen(true);
    inputProps.onChange(typeaheadProps.inputValue);
    typeaheadProps.onInputChange('');
  };

  const selectEnterProps = useSelectEnter({
    handlePressEnter: onStartCreate,
    options: typeaheadProps.options,
    loadingOptions: typeaheadState.loading,
    uncontrolledSearch: true,
  });

  useEffect(() => {
    onModalOpen(modalProps.show);
  }, [onModalOpen, modalProps.show]);

  const ObjectLink = useMemo(
    () =>
      enableLink &&
      fetchUrl &&
      getSelectedValueLink({
        genLink: getLinkDataForTag({
          fetchUrl,
          fieldId: field.modelField ? field.modelField.id : field.id,
          tagType: specialTagTypes[field.name] || 'dynamictag',
          field,
        }).link,
        fieldId: field.modelField ? field.modelField.id : field.id,
        fetchUrl,
        tagType: specialTagTypes[field.name] || 'dynamictag',
      }),
    [enableLink, field, fetchUrl]
  );

  return others.viewOnlySelect ? (
    <ViewOnlyMultiSelect
      ref={mergeRef}
      options={typeaheadProps.options}
      value={value}
      PillComponent={ObjectLink}
    />
  ) : (
    <>
      <MultiSelect
        ref={mergeRef}
        disabled={disabled || modalSubmitting}
        value={value}
        onChange={onChange}
        placeholder={
          LABEL_DATA[field.name]?.placeholder || LABEL_DATA.default.placeholder
        }
        menuTopButton={
          <TopMenuButton onClick={onStartCreate}>
            {LABEL_DATA[field.name]?.menuTopButton ||
              LABEL_DATA.default.menuTopButton}
          </TopMenuButton>
        }
        endAdornment={
          <IconAdornment icon={LABEL_DATA[field.name]?.icon || 'tag'} />
        }
        PillComponent={ObjectLink}
        {...selectEnterProps}
        {...others}
        {...typeaheadProps}
        isLoading={typeaheadState?.loading}
      />
      <BasicModal
        heading={
          LABEL_DATA[field.name]?.modalHeader || LABEL_DATA.default.modalHeader
        }
        buttonText={t('Save')}
        defaultLeftBtnText={t('Cancel')}
        {...modalProps}
        disabled={!inputProps.value || modalSubmitting}
      >
        <TextInput
          autoFocus
          validate={
            validateMessage && {
              message: validateMessage,
              showMessage: true,
              inModal: true,
            }
          }
          placeholder={
            LABEL_DATA[field.name]?.modalInputPlaceholder ||
            LABEL_DATA.default.modalInputPlaceholder
          }
          {...inputProps}
        />
      </BasicModal>
    </>
  );
});

DynamicTagsSelect.displayName = 'DynamicTagsSelect';

DynamicTagsSelect.propTypes = {
  field: PropTypes.object,
  fetchUrl: PropTypes.string,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onModalOpen: PropTypes.func,
  objectId: PropTypes.string,
  serviceToUse: PropTypes.object,
  handleUpdateFieldOption: PropTypes.func,
};

DynamicTagsSelect.defaultProps = {
  value: [],
  disabled: null,
  fetchUrl: null,
  serviceToUse: FieldService,
  objectId: null,
  handleUpdateFieldOption: undefined,
  field: {},
  onModalOpen: null,
  onChange: null,
};

export default function DynamicTags({
  disabled,
  field,
  value: valueProp,
  onSubmit,
  children: selectEl,
  enableLink = true,
  ...others
}) {
  const { t } = useTranslation();
  const value = useMemo(() => transformTagsDown(valueProp), [valueProp]);
  const [modalOpen, setModalOpen] = useState(false);
  const { fetchUrl } = selectEl.props;
  const relationship = useMemo(
    () =>
      fetchUrl && enableLink
        ? getLinkDataForTag({
            fetchUrl,
            fieldId: field.modelField ? field.modelField.id : field.id,
            tagType: specialTagTypes[field.name] || 'dynamictag',
            field,
          })
        : null,
    [fetchUrl, field, enableLink]
  );

  return (
    <MultiSelectInline
      icon={field.name === specialTagTypes.titles ? 'search' : 'tag'}
      rootCloseDisabled={modalOpen}
      title={`${t('Find')} ${field.displayName}`}
      onSubmit={(val) => onSubmit(transformTagsUp(val))}
      viewOnlySelect={others.readOnly}
      menuOnly
      field={field}
      enableLink={enableLink}
      {...others}
    >
      {React.cloneElement(selectEl, {
        field,
        value,
        disabled,
        enableLink,
        onModalOpen: setModalOpen,
        ...(relationship ? { relationship } : {}),
        ...getDataQAForInput(
          others['data-field-id'],
          others['data-field-type']
        ),
        viewOnlySelect: others.readOnly,
      })}
    </MultiSelectInline>
  );
}

DynamicTags.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.element,
};

DynamicTags.defaultProps = {
  value: [],
  disabled: null,
  children: <DynamicTagsSelect />,
};
