import { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useParams, useHistory } from 'react-router-dom';
import { invalidate } from 'queries/invalidate';
import { useTranslation } from 'react-i18next';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import Loader from 'components/Kizen/Loader';
import ClientService from 'services/ClientService';
import Automation2Service from 'services/Automation2Service';
import LoggableActivityService from 'services/LoggableActivityService';
import { isMobile, useWindowSize } from 'app/spacing';
import { ControlBar } from './ControlBar';
import { getToastConfig } from './toastConfig';
import { useToast } from 'components/ToastProvider';
import { useRouteTrigger, useTimelineCachePrune } from 'hooks/useRouteTrigger';
import originalRoutes from './routes';
import { performActionOptions } from './PerformActionSteps/model';
import PerformActionModal from 'pages/ContactDetail/PerformActionModal';
import useModal from 'components/Modals/useModal';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { StickyNavigation } from './StickyNavigation';
import { useCurrentLayout } from 'ts-components/RecordLayout/useCurrentLayout';
import { usePartialLayoutControl } from 'ts-components/RecordLayout/usePartialLayoutControl';
import { useAutomationPermissions } from 'ts-components/hooks/permissions/automations';
import { ScrollTopButton } from 'ts-components/ScrollTopButton';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { useQuery } from 'react-query';
import { CLIENTS, CUSTOM_RECORDS } from 'queries/query-keys';
import { useModelQuery } from 'queries/models/custom-objects';

export const SourceTypes = {
  LEAD_SOURCES: 'LEAD_SOURCES',
  ADDRESSES: 'ADDRESSES',
  ACTIVITIES: 'ACTIVITIES',
};

const TimelineInvalidator = ({ id }) => {
  const invalidateTimelineRoutes = useMemo(() => {
    return Object.values(originalRoutes).filter((r) => r.invalidateTimeline);
  }, []);

  const timelineCachePrune = useTimelineCachePrune();
  useRouteTrigger(invalidateTimelineRoutes, () => {
    timelineCachePrune(id);
    invalidate.TIMELINE.RECORD(id);
  });
  return null;
};

const setContactTitle = (title, { firstName = '', lastName = '' }) =>
  firstName || lastName
    ? `${firstName ? firstName + ' ' : ''}
    ${lastName ? lastName + ' ' : ''}- ${title}`
    : title;

const ContactDetail = ({ title }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const { width } = useWindowSize();
  const clientPageConfig = useSelector((s) => s.contactPage.pageConfig);

  const [, , performActionModal] = useModal();

  const toastConfigs = getToastConfig(t, history);
  const [showToast] = useToast();
  const [noViewPermissions, setNoViewPermissions] = useState(false);
  const [userActionChoice, setUserActionChoice] = useState(null);

  const { data: contact, refetch: refetchContact } = useQuery({
    queryKey: CLIENTS.ENTITY(id),
    queryFn: async () => {
      try {
        const result = await ClientService.getById(id);

        return result;
      } catch (err) {
        const data = err.isAxiosError && err.response && err.response.data;
        const badPermissions = data && data.badPermissions;
        if (badPermissions) {
          setNoViewPermissions(true);
          showToast(toastConfigs.general.noPermission);
        }
        return false;
      }
    },
  });

  useSetTitleOnLoad(title(t), contact, setContactTitle);

  const businessClientObject = useSelector(getBusinessClientObject);

  const { data: clientObject } = useModelQuery(
    businessClientObject?.id,
    Boolean(businessClientObject?.id)
  );

  const { data: activityList = DROPDOWN_SHOULD_LOAD } = useQuery(
    CUSTOM_RECORDS.LOGGABLE_ACTIVITY_LIST(clientObject?.id),
    () => {
      return LoggableActivityService.getActivityFullList({
        customObjectId: clientObject.id,
        ordering: 'name',
        detail: 'light',
      });
    },
    {
      enabled: Boolean(clientObject?.id),
    }
  );

  const onStartAutomation = useCallback(
    async (automationId) => {
      try {
        await Automation2Service.start({
          automationId,
          clientId: id,
        });
        showToast(toastConfigs.automation.success);
      } catch (error) {
        showToast(toastConfigs.automation.error);
      }
    },
    [id, showToast, toastConfigs.automation]
  );

  const logActivityActions = {
    update: refetchContact,
    save: refetchContact,
  };

  const step = useMemo(
    () =>
      performActionOptions.find((el) => userActionChoice?.value === el.value),
    [userActionChoice]
  );

  const SelectedActionStep = step?.component || null;

  const onPerformActionSelected = useCallback(
    (data) => {
      setUserActionChoice(data);
      performActionModal.hide();
    },
    [performActionModal]
  );

  const onPerformActionHide = useCallback(() => {
    setUserActionChoice(null);
    performActionModal.hide();
  }, [performActionModal]);

  const selectedLayout = useCurrentLayout(
    clientObject?.recordLayouts ?? [],
    clientPageConfig?.selectedLayout
  );

  const { layoutOptions, selectedLayoutOption } = usePartialLayoutControl(
    clientObject,
    clientPageConfig
  );

  const routes = useMemo(() => {
    const restrictedRoutes = {
      profile: originalRoutes.profile,
    };

    if (selectedLayout?.tabs?.automations !== false) {
      restrictedRoutes.automations = originalRoutes.automations;
    }

    if (selectedLayout?.tabs?.messages !== false) {
      restrictedRoutes.messages = originalRoutes.messages;
    }

    return restrictedRoutes;
  }, [selectedLayout]);

  const routeKeys = useMemo(() => {
    return Object.keys(routes);
  }, [routes]);

  const routeComponents = routeKeys.map((routeKey) => {
    const route = routes[routeKey];
    return (
      <Route
        key={routeKey}
        path={route.path}
        exact={route.exact !== false}
        render={() => (
          <route.component
            contact={contact} // needed for messages
            entity={contact} // needed for automations
            onStartAutomation={onStartAutomation} // needed for automations
            isContact // needed for automations
            customObjectId={clientObject?.id} // needed for automations
          />
        )}
      />
    );
  });

  const { canStart } = useAutomationPermissions({
    isClient: true,
    entityObject: contact,
  });

  if ((!contact && !noViewPermissions) || (contact && contact.id !== id)) {
    return <Loader loading />;
  }

  return (
    <>
      <ControlBar
        contact={contact}
        routes={routes}
        params={params}
        hasUnsavedChanges={false}
        // This button is a noop on any page but the detail page, at least for the time being
        onSubmit={() => null}
        onStartAutomation={onStartAutomation}
        onChange={setUserActionChoice}
        openPerformModal={() => performActionModal.show()}
        clientObject={clientObject}
        logActivityActions={logActivityActions}
        activityList={activityList}
        layoutOptions={layoutOptions}
        selectedLayout={selectedLayoutOption}
        setSelectedLayout={(newLayout) => {
          history.push(`/client/${id}/details?layout=${newLayout.value}`);
        }}
        canStartAutomations={canStart}
      />
      {performActionModal.showing && (
        <PerformActionModal
          isMobile={isMobile(width)}
          onSelect={onPerformActionSelected}
          show={true}
          onHide={onPerformActionHide}
          contact={contact}
        />
      )}
      {SelectedActionStep && (
        <SelectedActionStep
          contact={contact}
          selectedAction={userActionChoice}
          onHide={() => {
            setUserActionChoice(null);
          }}
          isMobile={isMobile(width)}
        />
      )}
      <StickyNavigation params={params} routes={routes} />
      <Switch>{routeComponents}</Switch>
      <TimelineInvalidator id={id} />
      <ScrollTopButton />
    </>
  );
};

export default ContactDetail;
