export const initialStore = {
  contactPage: {
    pageConfig: {},
    selection: {
      value: 'individual',
      checked: false,
      checkedIds: [],
      subtractIds: [],
      checkedCount: 0,
    },
    filters: {
      name: '',
      numberOfFilters: 0,
      config: null,
      errors: null,
      errorDictionary: null,
      currentErrors: null,
    },
    isFetching: false,
    isLoadingView: false,
    isFetchingContacts: false,
    categorizedFields: [],
    fields: [],
    allFields: [],
    groups: [],
    contacts: [],
    contactsCount: 0,
    contactsCountAndNew: 0, // Specifically for the table header
    groupCount: 0,
    allCount: 0,
    needToLoadCounts: true,
    isFiltering: false,
    pageReady: false,
    clientObject: {},
    filterGroupReason: null,
  },
  formsPage: {
    pageConfig: {
      search: '',
      sort: 'name',
      page: 1,
      size: 50,
    },
    isFetching: false,
    forms: [],
    formsCount: 0,
    models: [],
    toastMessage: null,
    isFiltering: false,
  },
  automationPage: {
    isFetching: false,
    automations: [],
    automationsCount: 0,
    groups: [],
    groupErrors: null,
    models: [],
    pageConfig: {},
    toastData: null,
    filters: {
      name: '',
      numberOfFilters: 0,
      errors: null,
      config: null,
      key: null,
    },
    groupCount: 0,
    isFiltering: false,
  },
  adManagerPage: {
    isFetching: false,
    connections: [],
    connectionsCount: 0,
    pageConfig: {
      connections: {
        search: '',
        sort: 'name',
        page: 1,
        size: 50,
      },
    },
    isFiltering: false,
  },
  libraryPage: {
    isFetching: false,
    isTemplateUpdating: false,
    isAutomationsFetching: false,
    isBroadcastsFetching: false,
    templates: [],
    templatesCount: 0,
    automations: [],
    automationsCount: 0,
    broadcasts: [],
    broadcastsCount: 0,
    pageConfig: {
      automations: {
        search: '',
        sort: 'name',
        page: 1,
        size: 50,
      },
      broadcasts: {
        singleSendsSearch: '',
        singleSendsSort: 'name',
        singleSendsSize: 50,
        singleSendsPage: 1,
        search: '',
        sort: 'name',
        page: 1,
        size: 50,
      },
      templates: {
        search: '',
        sort: 'name',
        page: 1,
        size: 50,
      },
    },
    isTemplatesFiltering: false,
    isAutomationsFiltering: false,
    isBroadcastsFiltering: false,
  },
  leadSourceCostsPage: {
    pageConfig: {},
    isFetching: false,
    leadSourceCosts: [],
    leadSourceCostsCount: 0,
    leadSources: [],
    campaigns: [],
    categories: [],
    groups: [],
    filters: {
      name: '',
      numberOfFilters: 0,
      config: null,
    },
  },
  activitiesPage: {
    pageConfig: {
      search: '',
      sort: 'name',
      page: 1,
      size: 50,
    },
    isFetching: false,
    activities: [],
    activitiesCount: 0,
    toastMessage: null,
    isFiltering: false,
  },
  surveysPage: {
    pageConfig: {
      search: '',
      sort: 'name',
      page: 1,
      size: 50,
    },
    models: [],
    isFetching: false,
    surveys: [],
    surveysCount: 0,
    toastMessage: null,
    isFiltering: false,
  },
  customObjectsPage: {
    pageConfig: {
      page: 1,
      size: 50,
      search: '',
      sort: 'object_name',
      filterObject: {},
      quickFilterSettings: [],
      quickFilteringFacets: false,
    },
    isFetching: false,
    isFetchingCustomObjects: false,
    customObjects: [],
    customObjectsCount: 0,
    toastMessage: null,
    customObjectAccess: {},
    fullListOfCustomObjects: [],
    isFetchingFullListOfCustomObjects: false,
    isFiltering: false,
  },
  recordsPage: {
    pageConfig: {},
    selection: {
      value: 'individual',
      checked: false,
      checkedIds: [],
      subtractIds: [],
      ignoredIds: [],
      checkedCount: 0,
    },
    filters: {
      name: '',
      numberOfFilters: 0,
      config: null,
      errors: null,
      errorDictionary: null,
      currentErrors: null,
      modelId: null,
    },
    model: {},
    isFetching: true,
    isLoadingView: false,
    isFetchingRecords: false,
    records: [],
    recordsCount: 0,
    recordsCountAndNew: 0, // Specifically for the table header
    allCount: 0,
    categorizedFields: [],
    fields: [],
    allFields: [],
    stages: [],
    toastData: null,
    leadSourceData: {
      sources: [],
      campaigns: [],
      mediums: [],
      terms: [],
      content: [],
    },
    boardData: {
      disabledDragCardIds: [],
      stages: [],
      settings: {},
    },
    fullListOfCustomObjects: [],
    reasonsDialogData: [],
    reasonsDisqualifiedDialogData: [],
    needToLoadCounts: true,
    isFiltering: false,
    pageReady: false,
    filterGroupReason: null,
  },
  recordDetailPage: {
    isFetching: true,
    customObjectsConfig: [],
  },
  customObjectsSettingPage: {
    objects: null,
    loadingObjects: true,
    chosenObject: null,
    loadingObjectFields: false,
    categories: [],
  },
  toastProvider: {
    toastData: [],
  },
};
