import React, { useCallback, useContext } from 'react';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { gutters } from 'app/spacing';
import TitleField from '../../fields/title';
import DatapointFrequencyField from '../../fields/datapointFrequency';
import ChartTypeField from '../../fields/chartType';

const allowedReportTypes = [
  REPORT_TYPES.RECORDS_ADDED,
  REPORT_TYPES.RECORDS_LOST,
  REPORT_TYPES.RECORDS_WON,
  REPORT_TYPES.RECORDS_DISQUALIFIED,
];

const RecordsAddedLostReportType = ({
  mobile = false,
  defaultName = '',
  onChangeName = false,
  active,
}) => {
  const { state, setState, entityName } = useContext(
    WizardStateContext.Context
  );
  const { display, reportType, name, isTrendDisplay, datapointFrequency } =
    state;

  const onChangeDatapointFrequency = useCallback(
    (value) => {
      return setState('datapointFrequency', value);
    },
    [setState]
  );

  // Hide self if the report type is not on the allow list
  if (!allowedReportTypes.includes(reportType?.value)) {
    return null;
  }

  return (
    <>
      <ChartTypeField mobile={mobile} />
      {display ? (
        <>
          {isTrendDisplay ? (
            <>
              <DatapointFrequencyField
                value={datapointFrequency}
                onChange={onChangeDatapointFrequency}
                mobile={mobile}
                active={active && isTrendDisplay}
              />
              {/* spacer for next step special case */}
              <StyledWizardField top={gutters.spacing(4)} />
            </>
          ) : null}
          <TitleField
            name={name}
            defaultName={defaultName}
            onChangeName={onChangeName}
            entityName={entityName}
          />
        </>
      ) : null}
    </>
  );
};

export default RecordsAddedLostReportType;
