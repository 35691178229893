import React, { useCallback, useMemo, useRef } from 'react';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';
import { getNumberOptions } from '../../helpers';
import { applyRef } from '../../Inputs/props';
import OutlineRating, { maxValueRating, minValueRating } from './OutlineRating';
import UnderlineRating from './UnderlineRating';
import { MaybeInputControl } from '../InputControl';
import Validation, { useValidation } from '../Validation';

const ONE_ROW_SIZE = 340;
const TWO_ROWS_SIZE = 165;
const MAX_WIDTH = 450;
const MIN_WIDTH = 220;
const OUTLINE_VARIANT = 'outline';
const UNDERLINE_VARIANT = 'underline';

const getComponent = (style) =>
  style === UNDERLINE_VARIANT ? UnderlineRating : OutlineRating;

const Rating = React.forwardRef(function Rating(
  { variant, allowDynamicVariant, maxRating, ...props },
  ref
) {
  const [wrapperRef, { width }] = useMeasure();
  const validationRef = useRef();
  const [, validationProps] = useValidation(validationRef, props);
  const hasSecondRow = width <= ONE_ROW_SIZE; // keep hasSecondRow = true when width < TWO_ROWS_SIZE so there are 2 rows when allowDynamicVariant = false
  const options = useMemo(() => getNumberOptions(maxRating), [maxRating]);
  const VariantRating = useMemo(() => {
    if (!allowDynamicVariant) {
      return getComponent(variant);
    }
    return getComponent(
      width < TWO_ROWS_SIZE ? UNDERLINE_VARIANT : OUTLINE_VARIANT
    );
  }, [allowDynamicVariant, variant, width]);
  const maxWidth = useMemo(() => {
    if (maxRating === maxValueRating) {
      return hasSecondRow ? MIN_WIDTH : MAX_WIDTH;
    }

    return MIN_WIDTH;
  }, [hasSecondRow, maxRating]);

  const mergeRef = useCallback(
    (node) => {
      applyRef(ref, node);
      applyRef(validationRef, node);
    },
    [ref]
  );

  return (
    <MaybeInputControl {...props} sizing={false} variant={variant} forInput>
      <div ref={wrapperRef}>
        <div style={{ maxWidth }}>
          <VariantRating
            ref={mergeRef}
            {...props}
            hasSecondRow={hasSecondRow}
            options={options}
            maxRating={maxRating}
          />
        </div>
      </div>
      <Validation {...validationProps} />
    </MaybeInputControl>
  );
});

Rating.propTypes = {
  variant: PropTypes.oneOf([OUTLINE_VARIANT, UNDERLINE_VARIANT]),
  maxRating: PropTypes.oneOf([minValueRating, maxValueRating]),
  allowDynamicVariant: PropTypes.bool,
};

Rating.defaultProps = {
  variant: OUTLINE_VARIANT,
  maxRating: maxValueRating,
  allowDynamicVariant: true,
};

export default Object.assign(Rating, {
  // eslint-disable-next-line react/forbid-foreign-prop-types
  propTypes: Rating.propTypes,
  defaultProps: Rating.defaultProps,
});
