import type { StepData } from '@kizen/filters/types';
import { getByUrlConfig } from 'ts-filters/utils';
import { useQuery, useInfiniteQuery } from 'react-query';
import { FILTERS } from 'queries/query-keys';
import AxiosService from '__services/AxiosService';

export const EMPTY_ARRAY: any = [];

type QueryKey = [
  StepData['method'],
  StepData['url'],
  StepData['body'],
  StepData['params'],
  { infinite: true },
];

export const useFilterQuery = (
  method: StepData['method'],
  url: StepData['url'],
  body: StepData['body'],
  params: StepData['params'],
  enabled: boolean = true
) => {
  try {
    return useQuery({
      queryKey: FILTERS.QUERY({ method, url, body, params }),
      queryFn: () => {
        return getByUrlConfig(AxiosService, {
          url: url!,
          method: method!,
          body,
          params,
        });
      },
      enabled: Boolean(method && url && enabled),
      retry: 1,
      useErrorBoundary: false,
    });
  } catch (error) {
    return { data: EMPTY_ARRAY, isLoading: false };
  }
};

export const useInfiniteFilterQuery = (
  method: StepData['method'],
  url: StepData['url'],
  body: StepData['body'],
  params: StepData['params'],
  enabled: boolean = true
) => {
  return useInfiniteQuery<any, any, any, QueryKey>(
    FILTERS.INFINITE_QUERY({ method, url, body, params }) as QueryKey,
    async ({ pageParam }) => {
      return getByUrlConfig(AxiosService, {
        url: url!,
        method: method!,
        body,
        params: { ...params, page: pageParam },
      });
    },
    {
      retry: 1,
      useErrorBoundary: false,
      enabled,
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.next
          ? new URL(lastPage.next).searchParams.get('page')
          : undefined;
      },
      getPreviousPageParam: (previousPage) => {
        return previousPage?.prev
          ? new URL(previousPage.prev).searchParams.get('page')
          : undefined;
      },
    }
  );
};
