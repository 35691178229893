import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { fontWeights } from 'app/typography';
import { pageBuilderBackgroundStyle } from './styled';
import { CANVAS_PADDING } from './constants';

const getWidthTopOffset = (paddingTop) => {
  const WIDTH_TEXT_HEIGHT = 14; // PixelText font-size is 1.4em
  return paddingTop > CANVAS_PADDING ? (paddingTop - WIDTH_TEXT_HEIGHT) / 2 : 4;
};

const CanvasWrapperContainer = styled.div`
  ${pageBuilderBackgroundStyle}
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  word-break: break-word;
  ${({ removePaddingBottom }) =>
    removePaddingBottom &&
    css`
      padding-bottom: 0;
    `}
  ${({ paddingTop }) =>
    paddingTop > CANVAS_PADDING &&
    css`
      padding-top: ${paddingTop}px;
    `}
`;

const PixelText = styled(KizenTypography)`
  position: absolute;
  top: ${({ paddingTop }) => getWidthTopOffset(paddingTop)}px;
  right: 20px;
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
`;

const Text = styled.span`
  font-size: 1em;
  vertical-align: middle;
`;

const WidthText = styled(Text)`
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
`;

export const CanvasWrapper = forwardRef(
  ({ children, width, paddingTop = 0, ...rest }, ref) => {
    const { t } = useTranslation();

    return (
      <CanvasWrapperContainer ref={ref} paddingTop={paddingTop} {...rest}>
        {!isNaN(width) && (
          <PixelText as="span" paddingTop={paddingTop}>
            <WidthText>{`${t('Width')}`}</WidthText>
            <Text>{`: `}</Text>
            <Text>{`${width.toFixed(0)}px`}</Text>
          </PixelText>
        )}
        {children}
      </CanvasWrapperContainer>
    );
  }
);
