import Validation from 'components/Inputs/Validation';
import Input, { INPUT_TYPES } from 'components/Kizen/Input';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { DEFAULT_INPUT_DELAY } from 'utility/config';

const DebouncedInput = ({
  placeholder,
  value,
  onChange,
  validate,
  field,
  compact,
  ...rest
}) => {
  const [_value, _setValue] = useState(value);
  const inputRef = useRef(null);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    onChange(debouncedValue);
  }, [onChange, debouncedValue]);

  useDebounce(() => setDebouncedValue(_value), DEFAULT_INPUT_DELAY, [_value]);

  const type = useMemo(() => {
    if (!field) {
      return INPUT_TYPES.TEXT;
    }
    if (field.fieldType === 'money') {
      return INPUT_TYPES.PRICE;
    }
    if (field.fieldType === 'integer') {
      return INPUT_TYPES.WHOLE_NUMBER;
    }
    return INPUT_TYPES.DECIMAL;
  }, [field]);

  return (
    <>
      <Input
        {...rest}
        value={_value}
        onChange={_setValue}
        placeholder={placeholder}
        ref={inputRef}
        type={type}
        compact={compact}
      />
      <Validation {...validate} target={inputRef.current} />
    </>
  );
};

export default DebouncedInput;
