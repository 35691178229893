import { useTranslation } from 'react-i18next';
import { getColumns } from '../../util';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toastVariant, useToast } from 'components/ToastProvider';
import { respondToAccessRequestQuery } from 'pages/Dashboard/queries';
import { useMutation, useQueryClient } from 'react-query';
import useAccessRequest from 'components/Sharing/hooks/useAccessRequest';
import { DASHBOARD } from 'queries/query-keys';
import AccessRequestTableMine from 'components/AccessRequests/Tables/Mine';

const DashboardSettingsMine = ({ refetchDashboards, ...rest }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showToast] = useToast();
  const qc = useQueryClient();

  const { mutate: respond } = useMutation(
    (result) => {
      return respondToAccessRequestQuery(result.requestId, result.approved);
    },
    {
      onSuccess: (_data, variables) => {
        const { approved } = variables;
        if (approved) {
          // aprove the request
          showToast({
            message: t('You have successfully granted access to a dashboard.'),
            variant: toastVariant.SUCCESS,
          });
          refetchDashboards?.();
        } else {
          // deny the request
          showToast({
            message: t('You have denied access to a dashboard.'),
            variant: toastVariant.FAILURE,
          });
        }
        qc.invalidateQueries([...DASHBOARD.DASHBOARD]);
      },
      onSettled: () => {
        history.replace('/dashboard/settings/mine');
      },
      onError: (payload) => {
        showToast({
          message:
            payload?.response?.data?.non_field_errors?.[0] ??
            t('Could not respond to access request. An error occurred.'),
          variant: toastVariant.FAILURE,
        });
      },
    }
  );

  useAccessRequest(respond);

  return (
    <AccessRequestTableMine
      {...rest}
      getColumns={getColumns}
      entitySingular={t('Dashboard')}
      entityPlural={t('Dashboards')}
      deleteConfirmationMessage={t(
        'Deleting this dashboard will remove it for all team members. This action is permanent and the dashboard cannot be recovered.'
      )}
    />
  );
};

DashboardSettingsMine.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  handleStartEditing: PropTypes.func.isRequired,
  handleMutateDashboard: PropTypes.func.isRequired,
};

export default DashboardSettingsMine;
