import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import SubNavigation from 'components/Navigation/SubNavigation';
import {
  selectCanEditContactObjects,
  selectCanEditSomeCustomObjects,
} from 'store/authentication/selectors';
import { setError } from 'store/errors';
import getRoutes from './routes';

const NavWrapper = styled.div`
  max-width: 600px;
  position: relative;
  margin: 0 auto;
`;

const StyledSubNavigation = styled(SubNavigation)`
  && a {
    padding-bottom: calc(
      ${gutters.spacing(2, { baseline: true })}px + 3px
    ) !important;
  }
`;

export default function CustomFieldsPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const routes = getRoutes();
  const canEditCustomObjects = useSelector(selectCanEditSomeCustomObjects);
  const canEditContactObjects = useSelector(selectCanEditContactObjects);

  const filteredRoutes = useMemo(() => {
    return Object.entries(routes).reduce((acc, [key, route]) => {
      if (key === 'contacts' && !canEditContactObjects) {
        return acc;
      }
      if (key === 'custom-objects/:id?' && !canEditCustomObjects) {
        return acc;
      }
      acc[key] = route;
      return acc;
    }, {});
  }, [routes, canEditContactObjects, canEditCustomObjects]);

  useEffect(() => {
    // treat `/settings/custom-fields` and `/settings/custom-fields/contacts` the same
    if (location.pathname === '/settings/custom-fields') {
      history.replace(`${location.pathname}/contacts`);
    } else if (
      location.pathname === '/settings/custom-fields/contacts' &&
      !canEditContactObjects
    ) {
      if (canEditCustomObjects) {
        history.replace('/settings/custom-fields/custom-objects');
      } else {
        dispatch(setError({ code: 403 }));
      }
    } else if (
      location.pathname.startsWith('/settings/custom-fields/contacts') &&
      !canEditContactObjects
    ) {
      // handling a direct link to a contact field like `/settings/custom-fields/contacts/${id}` - just show the error page
      dispatch(setError({ code: 403 }));
    } else if (
      location.pathname === '/settings/custom-fields/custom-objects' &&
      !canEditCustomObjects
    ) {
      dispatch(setError({ code: 403 }));
    }
  }, [
    canEditContactObjects,
    canEditCustomObjects,
    history,
    location,
    dispatch,
  ]);

  return (
    <>
      <NavWrapper className="nav-wrapper">
        <StyledSubNavigation small id="sectionnav" routes={filteredRoutes} />
      </NavWrapper>
      <Switch>
        {Object.entries(routes).map(([key, route]) => {
          return (
            <Route
              key={key}
              path={route.path}
              exact={route.exact !== false}
              component={route.component}
            />
          );
        })}
      </Switch>
    </>
  );
}
