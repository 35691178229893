import { useCallback } from 'react';
import FilterWizardBody from 'components/Wizards/MetaFilters';
import FilterWizardFooter from 'components/Wizards/MetaFilters/Footer';
import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';
import ClearFilters from '../ClearFilters';

import { useWizardFilterContext } from 'components/Wizards/MetaFilters/context';

export const DynamicObjectMetaFilter = ({
  onClear,
  filtersAreEmpty,
  onFilterConfirm,
  additionalFields,
  hideWarnings,
  hideGroups,
  area,
  selectedObject,
  defaultCustomFilter,
  intitialRenderRef,
}) => {
  const {
    filterOps: { reset },
    valid,
  } = useWizardFilterContext();

  const handleClear = useCallback(
    (...props) => {
      onClear(...props);
      reset();
    },
    [onClear, reset]
  );

  return (
    <>
      <FilterWizardBody
        hideWarnings={hideWarnings}
        additionalFields={additionalFields}
        disabled={area === AREA_RESPONSES.ACTIVITIES ? !selectedObject : false}
        footer={
          onClear && reset && !filtersAreEmpty ? (
            <ClearFilters onClear={handleClear} />
          ) : null
        }
        hideGroups={hideGroups}
        area={area}
        defaultCustomFilter={defaultCustomFilter}
        intitialRenderRef={intitialRenderRef}
      />
      <FilterWizardFooter
        valid={valid || filtersAreEmpty}
        onConfirm={onFilterConfirm}
      />
    </>
  );
};
