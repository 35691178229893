import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import BaseSelect from '../Select';
import * as customizeComponents from './customize';
import { MainSelectorWrapper } from './styles';

const Select = styled(BaseSelect)`
  width: fit-content;
`;

const MainSelector = (props) => {
  const { components } = props;
  return (
    <MainSelectorWrapper>
      <Select
        {...props}
        variant="underline"
        components={{ ...customizeComponents, ...components }}
        expandOnFocus
      />
    </MainSelectorWrapper>
  );
};

MainSelector.displayName = 'MainSelector';

// Also all props from Select
MainSelector.propTypes = {
  components: PropTypes.object,
};

MainSelector.defaultProps = {
  components: null,
};

export default MainSelector;
