/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import RangeInput from 'components/Kizen/RangeInput';
import Card from 'components/Card/Card';
import { FORMAT_TYPES } from 'components/Kizen/RangeInput';
import { DIVIDER_TYPE } from 'components/Layout/Divider';
import {
  BusinessLevelTypes,
  pricingTableMap,
  CloudTypes,
  priceTypes,
} from 'pages/Subscription/model';
import {
  StyledHeader as Header,
  StyledRow as Row,
  StyledCol as Col,
  Table,
  TableRow,
  TableCell,
  StyledInfoMessage,
  StyledBreakdownSum,
  StyledBreakdownSubheader as BreakdownSubheader,
  StyledDivider as Divider,
} from '../../styles';
import BreakdownTable from './BreakdownTable';

export const pricingsCalculations = (pricings, users, businessLevel) =>
  pricings.reduce((sum, price) => {
    if (price.type === priceTypes.MONTHLY) {
      return sum + price.levels[businessLevel].price;
    }
    if (price.type === priceTypes.PER_USER) {
      return sum + price.levels[businessLevel].price * users;
    }
    return 0;
  }, 0);

const CalculateInvestmentForm = ({ values, onFieldChange }) => {
  const [contactsTooltip, setContactsTooltip] = useState(values.num_contacts);
  const [salesTooltip, setSalesTooltip] = useState(values.num_sales_users);
  const [experienceTooltip, setExperienceTooltip] = useState(
    values.num_experience_users
  );

  const contactsRange =
    values.business_level === BusinessLevelTypes.ESSENTIALS
      ? { min: 500, max: 25000, step: 500 } // By default step is 1 in RangeInput, to change it we need to send it as prop
      : values.business_level === BusinessLevelTypes.PROFESSIONAL
        ? { min: 1000, max: 100000, step: 1000 }
        : { min: 5000, max: 500000, step: 5000 };

  const salesUsersRange =
    values.business_level === BusinessLevelTypes.ESSENTIALS
      ? { min: 1, max: 25 }
      : values.business_level === BusinessLevelTypes.PROFESSIONAL
        ? { min: 1, max: 100 }
        : { min: 5, max: 500 };

  const experienceUsersRange =
    values.business_level === BusinessLevelTypes.ESSENTIALS
      ? { min: 1, max: 25 }
      : values.business_level === BusinessLevelTypes.PROFESSIONAL
        ? { min: 1, max: 100 }
        : { min: 5, max: 500 };

  const estimatedSum = useMemo(() => {
    let sum = 0;
    const businessLevel = values.business_level;
    let pricings = null;
    if (!!values.marketing_cloud) {
      pricings = pricingTableMap[CloudTypes.MARKETING].pricings;
      sum += pricingsCalculations(
        pricings,
        values.num_contacts / 1000,
        businessLevel
      );
    }
    if (!!values.sales_cloud) {
      pricings = pricingTableMap[CloudTypes.SALES].pricings;
      sum += pricingsCalculations(
        pricings,
        values.num_sales_users,
        businessLevel
      );
    }
    if (!!values.experience_cloud) {
      pricings = pricingTableMap[CloudTypes.EXPERIENCE].pricings;
      sum += pricingsCalculations(
        pricings,
        values.num_experience_users,
        businessLevel
      );
    }
    return sum;
  }, [values]);

  useEffect(() => {
    if (values.num_contacts < contactsRange.min) {
      onFieldChange('num_contacts', contactsRange.min);
    } else if (values.num_contacts > contactsRange.max) {
      onFieldChange('num_contacts', contactsRange.max);
    } else {
      const step = contactsRange.step || 1;
      const result = step * Math.ceil(values.num_contacts / step);
      if (result !== contactsTooltip) {
        setContactsTooltip(result);
      }
    }

    if (values.num_sales_users < salesUsersRange.min) {
      onFieldChange('num_sales_users', salesUsersRange.min);
    } else if (values.num_sales_users > salesUsersRange.max) {
      onFieldChange('num_sales_users', salesUsersRange.max);
    } else {
      const step = salesUsersRange.step || 1;
      const result = step * Math.ceil(values.num_sales_users / step);
      if (result !== salesTooltip) {
        setSalesTooltip(result);
      }
    }

    if (values.num_experience_users < experienceUsersRange.min) {
      onFieldChange('num_experience_users', experienceUsersRange.min);
    } else if (values.num_experience_users > experienceUsersRange.max) {
      onFieldChange('num_experience_users', experienceUsersRange.max);
    } else {
      const step = experienceUsersRange.step || 1;
      const result = step * Math.ceil(values.num_experience_users / step);
      if (result !== experienceTooltip) {
        setExperienceTooltip(result);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values,
    contactsRange.min,
    contactsRange.max,
    salesUsersRange.min,
    salesUsersRange.max,
    experienceUsersRange.min,
    experienceUsersRange.max,
    onFieldChange,
    contactsRange.step,
    salesUsersRange.step,
    experienceUsersRange.step,
  ]);

  return (
    <Card>
      <Header type="header" margin="lg">
        Calculate Your Investment
      </Header>
      <Row>
        {(values.marketing_cloud ||
          values.experience_cloud ||
          values.sales_cloud) && (
          <Col>
            {(values.marketing_cloud || values.experience_cloud) && (
              <Row>
                <Col>
                  <RangeInput
                    label="Number of Kizen Contacts"
                    value={values.num_contacts}
                    min={contactsRange.min}
                    max={contactsRange.max}
                    step={contactsRange.step}
                    onChange={(value) => {
                      onFieldChange('num_contacts', value);
                      setContactsTooltip(value);
                    }}
                    tooltipValue={contactsTooltip}
                    onTooltipChange={setContactsTooltip}
                    formatMarker={FORMAT_TYPES.COMMAS}
                  />
                </Col>
              </Row>
            )}
            {values.sales_cloud && (
              <Row>
                <Col>
                  <RangeInput
                    label="Number of Kizen Sales Users"
                    value={values.num_sales_users}
                    min={salesUsersRange.min}
                    max={salesUsersRange.max}
                    onChange={(value) => {
                      onFieldChange('num_sales_users', value);
                      setSalesTooltip(value);
                    }}
                    tooltipValue={salesTooltip}
                    onTooltipChange={setSalesTooltip}
                  />
                </Col>
              </Row>
            )}
            {values.experience_cloud && (
              <Row>
                <Col>
                  <RangeInput
                    label="Number of Kizen Experience Users"
                    value={values.num_experience_users}
                    min={experienceUsersRange.min}
                    max={experienceUsersRange.max}
                    onChange={(value) => {
                      onFieldChange('num_experience_users', value);
                      setExperienceTooltip(value);
                    }}
                    tooltipValue={experienceTooltip}
                    onTooltipChange={setExperienceTooltip}
                  />
                </Col>
              </Row>
            )}
          </Col>
        )}
        <BreakdownSubheader type="subheader" margin="lg">
          Breakdown
        </BreakdownSubheader>
        <Table className="investment-table">
          <BreakdownTable values={values} />
          <TableRow>
            <Divider type={DIVIDER_TYPE.HORIZONTAL} />
          </TableRow>
          <TableRow>
            <TableCell className="table-cell estimation-cell">
              <p>Estimated Investment*</p>
            </TableCell>
            <TableCell className="table-cell estimation-cell">
              <StyledBreakdownSum>
                <span>${estimatedSum}</span>
                {priceTypes.MONTHLY.label}
              </StyledBreakdownSum>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="label-cell info-message">
              <StyledInfoMessage>
                *Your monthly charges will vary based on actual usage
              </StyledInfoMessage>
            </TableCell>
          </TableRow>
        </Table>
      </Row>
    </Card>
  );
};

CalculateInvestmentForm.propTypes = {
  values: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default CalculateInvestmentForm;
