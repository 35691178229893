/**
 * This file contains all the query keys used by react-query,
 * such they can be referenced by name. Each key is an array,
 * and subsction or variable query params are concatenated onto
 * the end of the array when used in a query
 */

export const TEAM_MEMBERS = {
  LIST: ['team_members', 'list'],
};

export const RELATION_FIELD = {
  RELATED_OBJECTS: (id) => ['related_objects', id],
};
