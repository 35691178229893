import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { format } from 'date-fns';
import Pseudo from 'i18next-pseudo';

import { LocalStorage } from 'utility/LocalStorage';
import { KIZEN_PREFIX } from 'storage';
const LANGUAGE_KEY = `${KIZEN_PREFIX}language`;
export const languageLocalStorage = new LocalStorage(LANGUAGE_KEY);

const defaultI18nKey = 'i18nextLng';
export const DEFAULT_LANGUAGE = 'en-us';
export const PSEUDO_LANGUAGE = 'en-pseudo';

export const clearI18nKeyFromStorage = () => {
  localStorage.removeItem(defaultI18nKey);
  languageLocalStorage.clear();
};

export const getCurrentLanguageFromStorage = () => i18n.language;

export const changeLanguageInStorage = (lng) => {
  i18n.changeLanguage(lng);
  languageLocalStorage.val = lng;
};

export const isPseudo = () => {
  return (
    window.location?.search &&
    window.location.search?.includes('test_language=true')
  );
};

const isPseudoLanguage = (ev) =>
  ev?.newValue?.includes(PSEUDO_LANGUAGE) ||
  ev?.oldValue?.includes(PSEUDO_LANGUAGE);

window.onstorage = (ev) => {
  // watch for change in LANGUAGE_KEY
  if (
    ev.key === LANGUAGE_KEY &&
    !document.hasFocus() &&
    !isPseudoLanguage(ev)
  ) {
    window.location.reload();
  }
};

export const availableLanguages = [
  {
    label: 'English',
    value: DEFAULT_LANGUAGE,
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Hindi',
    value: 'hi',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Japanese',
    value: 'ja',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Russian',
    value: 'ru',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
];

i18n
  .use(
    new Pseudo({
      enabled: true,
      languageToPseudo: PSEUDO_LANGUAGE,
    })
  )
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: ['en'],
    load: 'languageOnly',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, fmt) => {
        if (value instanceof Date) {
          return format(value, fmt);
        }
        return value;
      },
    },
    postProcess: ['pseudo'],
    whitelist: ['en', 'fr', 'de', 'hi', 'it', 'ja', 'pt', 'ru', 'es'],
    ns: ['translation'],
    defaultNS: 'translation', // default NS in react-i18next
    returnEmptyString: false, // ignores keys if empty strings, falling back to default language
    /* allow keys to be phrases having`:`, as we use natural language keys.
    if we will have several namespaces in future, we would need to use them as t('key', { ns: 'namespace' }), not t('namespace:key')
    */
    nsSeparator: false,
  });

export default i18n;
