import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useList } from 'react-use';

export function FormListItem({ index, onChange, children, ...others }) {
  const handleChange = useCallback(
    (value) => onChange({ index, value }),
    [index, onChange]
  );
  return React.cloneElement(children, {
    onChange: handleChange,
    ...others,
  });
}

FormListItem.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

const emptyArray = [];

export const useFormList = (
  defaultValue = {},
  initialValues = emptyArray,
  { addToBeginning } = {}
) => {
  const [values, { push, insertAt, updateAt, removeAt, set: setValues }] =
    useList(initialValues);
  const update = useCallback(
    ({ index, value }) => updateAt(index, value),
    [updateAt]
  );
  const add = useCallback(() => {
    if (addToBeginning) {
      insertAt(0, defaultValue);
    } else {
      push(defaultValue);
    }
  }, [addToBeginning, push, insertAt, defaultValue]);
  const remove = useCallback(({ index }) => removeAt(index), [removeAt]);
  return [values, update, add, remove, setValues];
};
