import * as PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { dropdownColors, colorsButton } from 'app/colors';
import styled from '@emotion/styled';
import { textCss } from 'app/typography';
import { reactSelectStyles, StyledCustomOption } from './Select/styles';

const getTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: dropdownColors.hover,
  },
});

const getValue = (value, options) => {
  // if the value is a string, find that in the options and return it
  if (typeof value === 'string') {
    return options.find((option) => option.value === value);
  }

  // if the value is an object, key off of the id
  if (value && 'id' in value) {
    return options.find((option) => option.value.id === value.id);
  }

  return value;
};

const StyledSelect = styled(ReactSelect)`
  width: 100px;
  display: inline-block;
  .Select__control {
    border: none;
    box-shadow: none;
    min-height: 0;
    padding-bottom: 1px;
    background-color: transparent;
    background-position: left bottom;
    background-repeat: repeat-x;
    background-image: url(/images/inputUnderline.svg);
    &:hover {
      background-image: url(/images/inputUnderline--hover.svg);
    }
    &--is-focused {
      background-image: url(/images/inputUnderline--solid.svg);
      &:hover {
        background-image: url(/images/inputUnderline--solid.svg);
      }
    }
  }
  .Select__indicator {
    padding: 0;
    padding-left: 8px;
    color: ${colorsButton.blue.default};
    &:hover {
      color: ${colorsButton.blue.hover};
    }
    // Align indicator icon with right side
    overflow: hidden;
    > :first-child {
      margin-right: -3px;
    }
  }
  .Select__value-container {
    // Align value with left side
    padding-left: 0;
  }

  .Select__single-value,
  .Select__input {
    ${textCss()}
  }
  .Select__menu {
    margin-bottom: 1px; // Room to see bottom border when at bottom of scroll container
  }
`;

const editableReactSelectStyles = {
  menu: reactSelectStyles.menu,
  menuList: reactSelectStyles.menuList,
  option: reactSelectStyles.option,
  indicatorSeparator: reactSelectStyles.indicatorSeparator,
};

export default function EditableSelect({ value, options, ...others }) {
  return (
    <StyledSelect
      classNamePrefix="Select"
      theme={getTheme}
      value={getValue(value, options)}
      options={options}
      styles={editableReactSelectStyles}
      components={{ Option: StyledCustomOption }}
      {...others}
    />
  );
}

EditableSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

EditableSelect.defaultProps = {
  value: null,
};
