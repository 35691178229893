import Button from 'components/Button';
import { can } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';

const ActionButtons = ({ mobile, onSelect, performActionOptions }) => {
  const authentication = useSelector((s) => s.authentication);
  return (
    <>
      {performActionOptions
        .filter(
          ({ permission }) =>
            can(
              { authentication },
              {
                for: permission,
                action: 'contacts',
              }
            ) ||
            can(
              { authentication },
              {
                for: 'single_record_actions',
                [permission]: 'contacts',
              }
            )
        )
        .map(({ value, label }) => (
          <Button
            key={value}
            color="blue"
            variant="outline"
            onClick={() => onSelect({ label, value })}
          >
            {label}
          </Button>
        ))}
      {/* These spacers allow for up to four columns of buttons */}
      {!mobile && (
        <>
          <span />
          <span />
        </>
      )}
    </>
  );
};

export default ActionButtons;
