import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints, spaceBetweenGutters, gutters } from 'app/spacing';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    width: 100%;
    @media (min-width: ${breakpoints.lg}px) {
      width: ${spaceBetweenGutters};
    }
  }
`;

export default function Cols({ columns, gutter, children, ...others }) {
  return (
    <Wrapper columns={columns} gutter={gutter} {...others}>
      {children}
      {[...Array(Math.max(0, columns - 2))].map((_, i) => (
        // Spacers so that items remain next to each other even when spaced-between
        // (e.g. when there are two items on a three-column row).
        // Not using <div /> so that it can't screw-up SubSection's last-of-type selector
        <span className="spacer" key={i} />
      ))}
    </Wrapper>
  );
}

Cols.propTypes = {
  columns: PropTypes.number.isRequired,
  gutter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Cols.defaultProps = {
  gutter: gutters.standard,
};
