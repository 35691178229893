import { useCallback, useRef, useState } from 'react';
import { DraggableColumns } from './DraggableColumns';
import { useDraggableColumns } from './hooks/useDraggableColumns';
import Subsection from 'components/Wizards/CustomObject/components/Subsection';
import { useTranslation } from 'react-i18next';

export const DefaultColumns = ({
  categorizedFields,
  model,
  updateStepField,
}) => {
  const { t } = useTranslation();
  const modelRef = useRef(model);

  const { itemsOrdering, leftItems, rightItems } = useDraggableColumns({
    model: modelRef.current,
    categorizedFields,
  });

  const [innerState, setInnerState] = useState(
    model.meta.defaultColumns ? rightItems : []
  );

  const handleChange = useCallback(
    (columns) => {
      if (
        innerState.length !== columns.length ||
        innerState.some((item, index) => item !== columns[index])
      ) {
        updateStepField(
          'meta',
          {
            ...modelRef.current.meta,
            defaultColumns: columns.map(
              ({ id, sortable, width, disabled }) => ({
                id,
                sortable,
                visible: true,
                width,
                disabled,
              })
            ),
          },
          !innerState.length
        );
        setInnerState(columns);
      }
    },
    [innerState, updateStepField]
  );

  return (
    <Subsection title={t('Set Default Columns')}>
      <DraggableColumns
        leftItems={leftItems}
        rightItems={rightItems}
        itemsOrdering={itemsOrdering}
        onChange={handleChange}
      />
    </Subsection>
  );
};
