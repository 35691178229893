import { forwardRef } from 'react';

import Select from 'components/Inputs/Select';
import RatingInlineField from 'components/Fields/InlineTableFieldInput/Rating';
import { withCellWrapper } from './helpers';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';

const _RatingCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    return (
      <RatingInlineField
        ref={ref}
        {...others}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        field={field}
        object={object}
      >
        <Select
          menuLeftButton={menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </RatingInlineField>
    );
  }
);

export const RatingCell = withCellWrapper(_RatingCell);
