import { useRef, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { layers } from 'app/spacing';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getCustomObjectsIsFetchingSelector,
  getCustomObjectsIsFilteringSelector,
} from 'store/customObjectsPage/customObjects.redux';
import Button from 'components/Button';
import { TRow } from 'components/Kizen/Table';
import BigTableLayout, {
  useBigTableLayout,
} from 'components/Layout/BigTableLayout';
import PageToolbar, {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import TablePagination from 'components/Tables/Big/TablePagination';
import BigTable from 'components/Tables/Big';
import getCustomObjectsColumns, { CUSTOM_OBJECTS_ACTIONS } from './columns';
import { getDataQAForInput } from 'components/Inputs/helpers';

export default function CustomObjectTabularPage(props) {
  const {
    customObjects,
    customObjectsCount,
    search,
    onChangeSearch,
    sort,
    onChangeSort,
    pageNumber,
    pageSize,
    onChangePageNumber,
    onChangePageSize,
    onUpdateCustomObjectField,
    onSelectEditObjectSettings,
    onSelectEditCustomFields,
    onSelectDeleteCustomObject,
    groupId,
    openMenu,
    globalCustomObjectPermission,
    ...others
  } = props;

  const { t } = useTranslation();
  const isCustomObjectsFetching = useSelector(
    getCustomObjectsIsFetchingSelector
  );
  const isFiltering = useSelector(getCustomObjectsIsFilteringSelector);

  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout();

  const { scrolledToTable } = bigTablePageProps;

  // So that we can reference props in memoized values
  const propsRef = useRef();
  propsRef.current = props;

  const columns = useMemo(
    () =>
      getCustomObjectsColumns({
        onUpdateField: (customObject, patch) =>
          propsRef.current.onUpdateCustomObjectField(customObject, patch),
        onSelectAction: ({ value }, customObject) => {
          const { id } = customObject;
          if (value === CUSTOM_OBJECTS_ACTIONS.EditObjectSettings) {
            propsRef.current.onSelectEditObjectSettings(customObject.id);
          }
          if (value === CUSTOM_OBJECTS_ACTIONS.EditCustomFields) {
            propsRef.current.onSelectEditCustomFields(id);
          }
          if (value === CUSTOM_OBJECTS_ACTIONS.Delete) {
            propsRef.current.onSelectDeleteCustomObject(id);
          }
        },
        t,
      }),
    [t]
  );

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => onChangeSort({ column, direction }),
      },
    }),
    [sort, onChangeSort]
  );

  return (
    <BigTableLayout
      filtersOpen={openMenu}
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={t('Find Objects')}
              value={search}
              onChange={onChangeSearch}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={customObjectsCount}
            single={t('Object')}
            plural={t('Objects')}
          />
          <PageToolbarSection>
            {globalCustomObjectPermission.canCreate && (
              <>
                <Button
                  onClick={() =>
                    propsRef.current.onSelectEditObjectSettings('new')
                  }
                  {...getDataQAForInput('co-wizard-open-button')}
                >
                  {t('New Object')}
                </Button>
              </>
            )}
          </PageToolbarSection>
        </PageToolbar>
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={customObjectsCount}
          onChangePage={onChangePageNumber}
          onChangePerPage={onChangePageSize}
        />
      }
      recordTabSpacing={false}
      {...bigTablePageProps}
      {...others}
    >
      <BigTable
        scrolledToTable={scrolledToTable}
        columns={columns}
        staleData={isCustomObjectsFetching || isFiltering}
        head={<TRow head columns={columns} data={headData} />}
        zIndex={layers.content(0, 0)} // needs to at zero - {todo} reafctor big table so the z-indexes work with out hacks
      >
        {/* Just a spacer when there are no results */}
        {!customObjects.length && <tr />}
        {customObjects.map((customObject) => (
          <TRow key={customObject.id} columns={columns} data={customObject} />
        ))}
      </BigTable>
    </BigTableLayout>
  );
}

CustomObjectTabularPage.propTypes = {
  groupId: PropTypes.string,
  customObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  customObjectsCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onUpdateCustomObjectField: PropTypes.func.isRequired,
  onSelectEditObjectSettings: PropTypes.func.isRequired,
  onSelectEditCustomFields: PropTypes.func.isRequired,
  onSelectDeleteCustomObject: PropTypes.func.isRequired,
  openMenu: PropTypes.bool.isRequired,
  globalCustomObjectPermission: PropTypes.object.isRequired,
};

CustomObjectTabularPage.defaultProps = {
  groupId: null,
};
