const ENVIRONMENTS = {
  LOCAL: `local-testing`,
  STAGING: 'staging',

  INTEGRATION: 'integration',
  E2EINTEGRATION: 'e2e-integration',
  PRODUCTION: 'production',
  PR_ENVIRONMENT: 'pr-environment',
  FMO: 'fmo',
};

export const sentryEnviroments = [
  ENVIRONMENTS.LOCAL,
  ENVIRONMENTS.INTEGRATION,
  ENVIRONMENTS.E2EINTEGRATION,
  ENVIRONMENTS.STAGING,
  ENVIRONMENTS.PRODUCTION,
  ENVIRONMENTS.PR_ENVIRONMENT,
  ENVIRONMENTS.FMO,
];

// re-enable these if we ever want alerts
export const alertEnviroments = [
  // ENVIRONMENTS.LOCAL,
  // ENVIRONMENTS.INTEGRATION,
  // ENVIRONMENTS.STAGING,
  // ENVIRONMENTS.PR_ENVIRONMENT
];
