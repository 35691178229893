import { ButtonActions } from '../../constants';
import {
  DEFAULT_BUTTON_CONTAINER_PROPS,
  EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
} from '../containers';

export { ButtonActions } from '../../constants';

const commonProps = {
  color: '#4BC7B4',
  label: 'Submit',
  textColor: '#FFFFFF',

  action: ButtonActions.SUBMIT,
  url: '',

  borderSize: '0',
  borderRadius: '8',
  borderColor: '#000000',

  paddingTop: '10',
  paddingRight: '20',
  paddingBottom: '10',
  paddingLeft: '20',
  fontSize: '12',
  textStyles: [],
  alignment: 'center',
  fontFamily: 'Arial',
  ...DEFAULT_BUTTON_CONTAINER_PROPS,
};

export const DEFAULT_FORM_BUTTON_PROPS = {
  ...commonProps,
  enableRecaptcha: false,
};

export const DEFAULT_STATIC_PAGE_BUTTON_PROPS = {
  ...DEFAULT_FORM_BUTTON_PROPS,
  label: 'Click Here',
  action: ButtonActions.URL,
};

export const DEFAULT_EMAIL_BUTTON_PROPS = {
  ...commonProps,
  ...EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
  label: 'Click Here',
  action: ButtonActions.URL,
};
