import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale, shadowLight } from 'app/colors';
import { gutters, borderRadii } from 'app/spacing';
import { contentWidthCss } from 'components/Layout/PageContentWidth';

const CardBasic = styled.div`
  ${shadowLight}
  background-color: ${grayScale.white};
  border-radius: ${borderRadii.standard};
`;

const Card = styled(CardBasic)`
  margin: 0 auto;
  padding: ${gutters.standard};
  width: 1372px; // width set to 1372px as per design
  max-width: 98%;
  position: relative;
  ${({ noBottomPadding }) =>
    noBottomPadding &&
    css`
      padding-bottom: 0;
    `}
`;

const SettingsContentCard = styled(CardBasic)`
  margin: 0;
  ${contentWidthCss}
  padding:${gutters.spacing(4)}px;
  position: relative;
  ${({ noBottomPadding }) =>
    noBottomPadding &&
    css`
      padding-bottom: 0;
    `}
`;

export default Card;
export { CardBasic, SettingsContentCard };
