import styled from '@emotion/styled';
import { css } from '@emotion/core';
import AddButton from 'components/Button/AddButton';
import { fontSizes } from 'app/typography';

export const DropdownWrapper = styled.div`
  margin-left: ${({ marginLeft = '1rem' }) => marginLeft};
  height: 34px;
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
  }
`;

export const StyledAddButton = styled(AddButton)`
  padding: 0;

  ${({ isCompact }) =>
    isCompact &&
    css`
      font-size: ${fontSizes.micro};
    `}
`;
