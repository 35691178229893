import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { getLink, emptyLongDash } from '../helpers';
import { getUserLabel } from 'utility/TransformToSelectOptions';

export const TeamMemberBlock = ({ item, ...others }) => {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();

  const label = useMemo(() => {
    return getUserLabel(item.value, t);
  }, [item, t]);

  if (!item.value) return <span {...others}>{emptyLongDash}</span>;

  return preReleaseFeatures ? (
    <TextEllipsisWithTooltip
      type="anchor"
      href={getLink({ id: item.value.id }, item)}
      target="_blank"
      rel="noopener noreferrer"
      {...others}
    >
      {label}
    </TextEllipsisWithTooltip>
  ) : (
    <TextEllipsisWithTooltip {...others}>{label}</TextEllipsisWithTooltip>
  );
};
