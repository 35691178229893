export const setOpenMenuAbove = (item, index, arr) => {
  // Last few data items should open their menus upwards
  return {
    ...item,
    meta: {
      ...item.meta,
      openMenuAbove: Math.max(10, arr.length) - index <= 3,
    },
  };
};
