import { useTranslation } from 'react-i18next';
import {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import { StyledPageToolbar } from '../styles';
import { BackLink } from 'pages/ColumnsPage/components/BackLink';

export const TableToolBar = ({
  onChangeSearch,
  count = 0,
  loading,
  isMobile,
}) => {
  const { t } = useTranslation();

  return (
    <StyledPageToolbar>
      <BackLink />
      {isMobile ? null : (
        <>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={t('Find Filter Groups')}
              onChange={onChangeSearch}
              loading={loading}
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={count}
            single={t('Filter Group')}
            plural={t('Filter Groups')}
          />
          <PageToolbarSection />
        </>
      )}
    </StyledPageToolbar>
  );
};
