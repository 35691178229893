import { createContext, useContext, useState, FC, ReactNode } from 'react';

interface Ctx {
  selectedObject: any;
  setSelectedObject(obj: any): void;
}

const ctx = createContext<Ctx>({
  selectedObject: null,
  setSelectedObject: () => null,
});

export const useRelatedContext = () => useContext(ctx);

interface MyComponentProps {
  children: ReactNode;
}

export const RelatedContext: FC<MyComponentProps> = ({ children }) => {
  const [selectedObject, setSelectedObject] = useState(null);

  const data = {
    selectedObject,
    setSelectedObject,
  };
  return <ctx.Provider value={data}>{children}</ctx.Provider>;
};
