import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';

import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { gutters, scrollbarCss } from 'app/spacing';
import BaseColumn from 'components/Board/BaseColumn';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import ColumnTitle, { SubTitle } from '../ColumnTitle';
import Card from '../Card';

const ContentWrapper = styled.div`
  height: calc(100% - 63px); // 63 - column title height
  padding: 0 ${gutters.spacing(1)}px 0 ${gutters.spacing(2)}px;
  overflow-y: scroll;
  ${scrollbarCss}
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
`;

const Gradient = (props) => (
  <BaseGradient fadeStart={0} fadeLength={50} {...props} />
);

const MobileColumn = ({
  model,
  data,
  cards,
  settings,
  fields,
  stages,
  getMoreCards,
  onChangeSelection,
  onChoseMenu,
  onClickPlus,
  onSubmitRecord,
  onChangeStage,
  ...props
}) => {
  const [stagedCards, setStagedCards] = useState(cards);
  const scrollbarContentWrapperRef = useRef();
  const [contentWrapperRefFn, contentWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.CardsForSyncWidth',
    'width'
  );

  const [, , bottomScrolled] = useEndsOfScroll(contentWrapperRef, [cards]);

  useEffect(() => {
    setStagedCards(cards);
  }, [cards]);

  useEffect(() => {
    if (bottomScrolled && data.hasMorePages) {
      getMoreCards({ stageId: data.id, page: data.page + 1 });
    }
  }, [bottomScrolled, getMoreCards, data.hasMorePages, data.id, data.page]);

  const allowEdit = model.access?.edit;

  return (
    <BaseColumn onClickPlus={allowEdit && onClickPlus} {...props}>
      <ColumnTitle
        title={data.name}
        subTitle={<SubTitle settings={settings} data={data} fields={fields} />}
        isSelected={data.isChecked}
        onChangeSelection={onChangeSelection}
      />
      <ContentWrapper ref={contentWrapperRefFn}>
        {stagedCards.map((item) => (
          <Card
            key={item.id}
            className="InlineHoverable"
            data={item}
            model={model}
            hideDnDIcon
            stageStatus={data.status}
            settings={settings}
            fields={fields}
            stages={stages}
            onChoseMenu={onChoseMenu}
            onSubmitRecord={(recordId, patch) =>
              onSubmitRecord(recordId, patch, { stageId: data.id })
            }
            onChangeStage={(recordId, patch) =>
              onChangeStage(recordId, patch, { stageId: data.id })
            }
          />
        ))}
        <Gradient position="bottom" fadeIn={!bottomScrolled} />
      </ContentWrapper>
    </BaseColumn>
  );
};

MobileColumn.propTypes = {
  model: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired,
  settings: PropTypes.object.isRequired,
  teams: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  getMoreCards: PropTypes.func.isRequired,
  onChangeSelection: PropTypes.func.isRequired,
  onChoseMenu: PropTypes.func.isRequired,
  onClickPlus: PropTypes.func.isRequired,
  onSubmitRecord: PropTypes.func.isRequired,
  onChangeStage: PropTypes.func.isRequired,
};

export default MobileColumn;
