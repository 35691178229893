import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '__components/Inputs/Select';
import { GenericOption } from '__pages/SmartConnectors/types';

type SourceSelectProps = {
  isNew: boolean;
  value: GenericOption | null;
  options: { options: GenericOption[]; label: string }[];
  onChange: ({ value }: { value: string }) => void;
  error?: string[];
  validate?: any;
};

export const SourceSelect = ({
  isNew,
  value,
  options,
  onChange,
  error,
  validate,
}: SourceSelectProps) => {
  const { t } = useTranslation();
  const scrolledOnce = useRef(false);
  const selectRef = useRef<{ select: any }>({ select: null });
  const [menuIsOpen, setMenuIsOpen] = useState(isNew);

  const setOpen = () => {
    setMenuIsOpen(true);
  };

  const setClose = () => {
    setMenuIsOpen(false);
  };

  const handleFocus = () => {
    setOpen();
    if (isNew && !scrolledOnce.current) {
      scrolledOnce.current = true;

      requestAnimationFrame(() => {
        selectRef.current.select.controlRef.scrollIntoView();
      });
    }
  };

  const handleBlur = () => {
    setClose();
  };

  const handleChange = ({ value }: { value: string }) => {
    setClose();
    onChange({ value });
  };

  return (
    <Select
      ref={selectRef}
      value={value}
      options={options}
      label={t('Data Source (Column Header)')}
      placeholder={t('Choose Column')}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      autoFocus={isNew}
      menuIsOpen={menuIsOpen}
      error={error}
      validate={validate}
      onMenuClose={setClose}
      onMenuOpen={setOpen}
    />
  );
};
