import { Button } from '@kizen/kds/Button';
import { ResizableSection } from '__components/Wizards/CustomObject/steps/CustomLayout/dialogs/common';
import { useTranslation } from 'react-i18next';

interface CustomContentWizardProps {
  isActive?: boolean;
  handleShowBuilder: () => void;
}

export const CustomContentWizard = (props: CustomContentWizardProps) => {
  const { handleShowBuilder } = props;
  const { t } = useTranslation();

  return (
    <ResizableSection header={t('Edit  Content')} {...props}>
      <Button
        variant="text"
        size="small"
        color="secondary"
        onClick={handleShowBuilder}
      >
        {t('Open Content Editor')}
      </Button>
    </ResizableSection>
  );
};
