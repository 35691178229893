import React, { useCallback } from 'react';
import {
  validateDecimalNumber,
  negativesValidateDecimalNumber,
} from 'components/Inputs/TextInput/NumericValidation/helpers';
import { toDecimalNumber } from 'components/Inputs/TextInput/presets/DecimalNumber';
import { useTranslation } from 'react-i18next';
import NumericInline from './Numeric';
import { useFlashTransition } from 'hooks/useFlashState';
import TextInputInline from '../index';
import { getOriginalError } from 'services/AxiosService';
import { FIELD_TYPES } from 'utility/constants';

export default function DecimalNumberInline({
  allowNegatives = true,
  onSubmit,
  placeholder,
  ...props
}) {
  const { t } = useTranslation();
  const [message, showMessage, flashErrorMessage] = useFlashTransition();
  const handleSubmit = useCallback(
    async (val) => {
      if (onSubmit) {
        try {
          return await onSubmit(toDecimalNumber(val));
        } catch (err) {
          const orig = getOriginalError(err);
          if (orig.percentage_chance_to_close?.length) {
            flashErrorMessage(orig.percentage_chance_to_close[0]);
            throw new TextInputInline.Error(null, { failed: true });
          }
          throw err;
        }
      }
    },
    [onSubmit, flashErrorMessage]
  );
  const validate = allowNegatives
    ? negativesValidateDecimalNumber(t)
    : validateDecimalNumber(t);

  return (
    <NumericInline
      numberFormat={{
        thousandSeparator: true,
        decimalScale: 4,
        isInteger: false,
        placeholder: placeholder || FIELD_TYPES.Decimal.placeholder,
      }}
      validate={{
        ...validate,
        message,
        showMessage,
      }}
      onSubmit={handleSubmit}
      placeholder={placeholder}
      {...props}
    />
  );
}
