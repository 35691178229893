/**
 * This file contains all the query keys used by react-query,
 * such they can be referenced by name. Each key is an array,
 * and subsction or variable query params are concatenated onto
 * the end of the array when used in a query
 */

export const MESSAGE_LIBRARY = {
  ALL: ['library'],
  TEMPLATES: ['library', 'templates'],
  BROADCASTS: ['library', 'broadcasts'],
  AUTOMATIONS: ['library', 'automations'],
  CLIENT_MESSAGE: ['client', 'message'],
  MESSAGE_STATS: ['library', 'message', 'stats'],
  MESSAGE: ['message'],
  MESSAGE_SYNCED_EMAIL: ['message', 'synced_email'],
};

export const TIMELINE = {
  ALL: ['timeline'],
  // Key used for all timeline fetches of a specific record Id
  RECORD: (recordId) => ['timeline', recordId],
  EVENT_TYPES: ['timeline', 'eventtypes'],
  PROBE_BLOCK: (eventId, created, blockId, filterPayload) => [
    'probe_timeline',
    blockId,
    eventId,
    created,
    filterPayload,
  ],
};

export const ACTIVITIES = {
  ALL: ['activities'],
  DEFAULT_RELATIONSHIPS: ['activities', 'default-relationships'],
  LOGGABLE: (params) => ['activities', 'loggable', params].filter(Boolean),
  ACTIVITY: (id) => ['activities', id],
  ACTIVITY_CAMEL: (id) => ['activities', id, 'camel'],
  ACTIVITY_FIELDS: (id) => ['activities', id, 'fields'],
  CUSTOM_SCHEDULED: (objectId, entityId) =>
    ['activities', 'custom-scheduled', objectId, entityId].filter(Boolean),
  PRIMARY_ASSOCIATIONS: (recordId, activityId) =>
    ['activities', 'primary-associations', recordId, activityId].filter(
      Boolean
    ),
  ACTIVITY_TABLE: (
    dashletId,
    activityIds = [],
    criteria,
    teamMemberFilter,
    roleFilter,
    filter,
    sort
  ) =>
    [
      'activity-table',
      dashletId,
      'activities',
      'fetch-activities',
      ...activityIds,
      'criteria',
      criteria,
      'teamMemberFilter',
      teamMemberFilter,
      'roleFilter',
      roleFilter,
      'filter',
      filter,
      'sort',
      sort,
    ].filter(Boolean),
};

export const FORMS = {
  ALL: ['forms'],
  FORM_RELATED_OBJECT_FIELDS: (id) => ['forms', 'fields', id],
};

export const CUSTOM_OBJECTS = {
  CATEGORIES: (id) => ['custom_objects', 'categories', id],
  CONTACT_CATEGORIES: ['client', 'categories'],
  CONTACT_FIELDS: ['client', 'fields'],
  CONTACT_FIELDS_UNRESTRICTED: ['client', 'fields', 'unrestricted'],
  CONTACT_RECORD: (id, params) => ['client', id, params],
  DETAILS: (id) => ['custom-objects', id, 'details'],
  MODEL: (id) => ['custom_object', 'model', id],
  LIST: ['custom_objects', 'list'],
  FIELDS_UNRESTRICTED: (id) => ['custom_objects', 'fields', id, 'unrestricted'],
  FIELDS_CATEGORIZED: (id) => ['custom_objects', 'fields', id, 'categorized'],
  RECORD_DETAIL_LAYOUT: (objectId) => [
    'custom_objects',
    'record_detail_layout',
    objectId,
  ],
  LIST_LAYOUTS: (objectId) => ['custom_object', 'list_layouts', objectId],
  RECORD: (objectId, entityId, params) => [
    'custom_object',
    objectId,
    'entity_records',
    entityId,
    params,
  ],
  FIELDS: (objectId, fieldId) => [
    'custom_objects',
    objectId,
    'fields',
    fieldId,
  ],
};

export const CUSTOM_RECORDS = {
  LIST: (id) => ['custom_objects', id, 'records', 'list'],
  DETAIL: (objectId, id) =>
    ['custom_records', 'detail', objectId, id].filter(Boolean),
  ACTIVITY_LIST: (id) => ['custom_records', id, 'activities', 'list'],
  LOGGABLE_ACTIVITY_LIST: (id) => [
    'custom_records',
    id,
    'activities',
    'loggable',
    'list',
  ],
};

export const AUTOMATIONS = {
  AUTOMATION: (id) => ['automations', id],
  TYPEAHEAD: ['automations', 'typeahead'],
  TYPEAHEAD_FILTERED: ['automations', 'typeahead-filtered'],
};

export const LEAD_SOURCES = {
  COMBINED_SEARCH: ['lead_sources', 'combined'],
  TABLE: (id) => ['lead_sources', 'table', id],
  TYPE_AHEAD: (id) => ['lead_sources', id],
};

export const EXTERNAL_ACCOUNT = {
  ALL: ['external-account'],
};

export const EXTERNAL_ACCOUNT_ALL = {
  ALL: ['external-account-all'],
  FILTERED: (fetchArgs) => ['external-account-all', fetchArgs],
};

export const BUSINESS = {
  ALL: ['business-config'],
  COUNTRIES: ['business-countries'],
  HOLIDAYS_COUNTRIES: ['business-holidays-countries'],
  ROLES: ['dashboard', 'roles'],
  TEXT_NUMBER: ['business-text-number'],
};

export const TEAM_MEMBERS = {
  ALL: ['team_members'],
  LIST: ['team_members', 'list'],
  INFINITE: ['team_members', 'infinite'],
  ROLES: ['team_members', 'roles'],
  TEAM_MEMBERS: ['team_members', 'team_members'],
  PERMISSION_GROUPS: ['team_members', 'permission_groups'],
  SEARCH: (search) => ['team_members', search], // search = { search, ordering, is_verified, detail }
  GET: (id) => ['team_members', 'get', id],
};

export const ROLES = {
  ALL: ['roles'],
};

export const PERMISSION_GROUP = {
  GROUPS: ['permissions.group'],
  FIELDS: ['permissions.fields'],
  OBJECTS: ['permissions.objects'],
  OLD_GROUPS: ['old.permissions.group'],
  OLD_FIELDS: ['old.permissions.fields'],
};

export const PRIVACY = {
  BLOCKED_EMAILS: ['blocked.emails'],
};

export const IMAGES = {
  ALL: ['images'],
  SORTED: (sort, search) => ['images', sort, search],
};
export const FILES = {
  ALL: ['files'],
};

export const FIELDS = {
  CLIENT: () => ['client', 'all-fields'],
  CUSTOM_OBJECTS: (type, id) => [type, 'all-fields', id],
  GET_FIELD: (id) => ['fields', 'get', id],
  DYNAMIC_TAGS: (fieldId, modelId) => ['dynamic_tags', fieldId, modelId],
  DYNAMIC_TAGS_TYPEAHEAD: (id) => ['dynamic_tags', 'typeahead', id],
  DYNAMIC_TAGS_NAMES: (ids) => ['dynamic_tags', 'names', ids],
  FACETS: (fieldId) => ['facets', fieldId],
  RELATIONSHIPS: (objectId) => ['fields', 'relationships', objectId],
};

export const CATEGORIES = {
  CLIENT: () => ['client', 'all-category'],
  CUSTOM_OBJECTS: (type, id) => [type, 'all-category', id],
};

export const DASHBOARD = {
  DASHBOARDS: ['dashboard', 'list'],
  DASHBOARDS_OTHERS: ['dashboard', 'list', 'others'],
  DASHBOARD: ['dashboard', 'single'],
  DASHBOARD_CONFIG: ['dashboard', 'config', 'page'],
  DASHLET_PIPELINES: ['dashboard', 'dashlet', 'pipelines'],
  DASHLET_PIPELINE: ['dashboard', 'dashlet', 'pipeline'],
  DASHLET_OBJECT_FIELDS: ['dashboard', 'dashlet', 'object', 'fields'],
  DASHLET_DATA: ['dashboard', 'dashlet', 'data'],
  TEAM_MEMBER_LIST: ['dashboard', 'teamMemberList'],
  ACTIVITY_TYPES: ['dashboard', 'activities'],
  DASHLET_ACTIVITY: ['dashboard', 'dashlet', 'activity'],
  PIPELINE_STAGES: ['dashboard', 'dashlet', 'pipeline', 'stages'],
  GRANTING_ADMINS: ['dashboard', 'granting_admins'],
  CUSTOM_OBJECT_LIST: ['dashboard', 'custom_object', 'list'],
  EMAIL_INTERACTION_STATS_LIST: ['dashboard', 'interaction', 'list'],
  DASHLET_SUBROWS: ['dashboard', 'dashlet', 'subrows'],
};

export const BROADCAST = {
  BROADCAST_CONFIG: ['broadcast', 'config', 'page'],
  STATS: ['broadcast', 'stats'],
};

export const CLIENTS = {
  DEFAULT_MODEL: ['client', 'default-model'],
  GROUPS: ['client', 'groups'],
  DETAIL: (id) => ['client', 'detail', id],
  ENTITY: (id) => ['client', 'entity', id],
  ADDRESSES: (id) => ['client', 'entity', 'addresses', id],
};

export const PAGE_CONFIGS = {
  MY_TOOLBAR: ['page_config', 'my_toolbar'],
  SCHEDULED_ACTIVITIES: ['page_config', 'scheduled_activities'],
};

export const TOOLBAR_TEMPLATES = {
  LIST: ['toolbar_templates', 'list'],
  SINGLE: ['toolbar_templates', 'single'],
  GRANTING_ADMINS: ['toolbar_templates', 'granting_admins'],
};

export const RECENT_ITEMS = {
  LIST: (objects = [], count) => ['recent_items', 'list', ...objects, count],
};

export const DETAILS_RELATED_OBJECTS = {
  ALL: ['details_related_objects'],
  RECORD: (modelId, entityId) => ['details_related_objects', modelId, entityId],
};

const RELATED_PIPELINES_COMMON = ['related_pipelines', 'common'];

export const DETAILS_RELATED_PIPELINES = {
  LIST: (entityId, pageSize, queryBody, searchTerm) => [
    ...RELATED_PIPELINES_COMMON,
    entityId,
    pageSize,
    queryBody,
    searchTerm,
  ],
  REFRESH: (entityId) => [...RELATED_PIPELINES_COMMON, entityId],
};

export const RELATION_FIELD = {
  RELATED_OBJECTS: (id) => ['related_objects', id],
};

export const ACCOUNT = {
  ALL: ['account'],
  API_KEYS: ['account', 'api_keys'],
  OAUTH_TOKENS: ['account', 'oauth_tokens'],
};

export const RELATIONSHIP_KEY = (value) => [`relationship`, value];

/**
 * type LoadFilterOptions = {
 *  method: string;
 *  url: string;
 *  body: any;
 *  params: any;
 *  result_path?: string[];
 *  result_transform?: any;
 * }
 */
export const FILTERS = {
  QUERY: (opts) => ['meta-filters', opts],
  INFINITE_QUERY: (opts) => ['meta-filters', opts, { infinite: true }],
};

export const OBJECT_FIELDS = {
  OBJECT: (type) => ['object_fields', type],
};

export const RECORDS = {
  DISPLAY_NAMES: (payload) => ['records', 'display-names', payload],
};

export const OBJECT_GROUPS = {
  OBJECT: (id) => ['object_groups', id],
};

export const PLUGINS = {
  LIST: ['plugins', 'list'],
  CONFIG: ['plugins', 'config', 'page'],
  GET_PLUGIN_APPS: ['plugins', 'get_plugin_apps'],
  GET_BUSINESS_PLUGIN_APPS: ['plugins', 'get_plugin_apps', 'business'],
};

export const SMART_CONNECTORS = {
  ALL: (pageNumber, pageSize, search, sort) => [
    'smart-connectors',
    pageNumber,
    pageSize,
    search,
    sort,
  ],
};
