import { useCallback, useMemo, useRef, useState } from 'react';
import { useViewerContext } from '../../../../../viewer/ViewerContext';

/*********************************************************************************/
// This was copied from apps/react-app/src/utility/UUID
/*
 * Generates a UUID using version 4
 * http://stackoverflow.com/a/8809472
 */
function UUID() {
  let d = new Date().getTime();

  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); // use high-precision timer if available
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}
/*********************************************************************************/

/**
 * Hook to provide files state and update functions for the FileInput modal
 *
 * @param {Function} opts.uploadFile - required; the asynchronous function used to upload a single file (most likely the result of {@link ./useUploadFile})
 * @param {Function} opts.initializeProgress - optional; will be called prior to uploading with the files and their temp `$id`
 * @param {array} opts.initial - optional; the inital list of files
 * @returns 2-tuple - [sortedFiles, { addFiles, deselectFile, setFiles, updateFile }]
 */
export const useFiles = ({ uploadFile, initializeProgress, initial = [] }) => {
  const newFilesRef = useRef();
  const [files, setFiles] = useState(initial);
  const { onUpdateFile } = useViewerContext();

  const addFiles = useCallback(
    async (updatedFiles) => {
      const createFiles = updatedFiles
        .filter((obj) => obj instanceof File)
        .map((obj) => {
          // Optimistically give this file an id
          obj.$id = UUID();
          return obj;
        });

      newFilesRef.current = updatedFiles.map((obj) => {
        if (obj instanceof File) {
          return {
            id: obj.$id,
            name: obj.path,
            created: false,
          };
        }
        return obj;
      });

      // Give the consumer a "tentative" file record
      setFiles(newFilesRef.current);
      if (initializeProgress) {
        initializeProgress(createFiles);
      }

      // Now reach out to the API to create the appropriate files,
      const failedIds = new Set();
      await Promise.allSettled(
        createFiles.map(async (file) => {
          try {
            const fileRecord = await uploadFile(file);
            newFilesRef.current = newFilesRef.current.map((current) => {
              return current.id === fileRecord.id ? fileRecord : current;
            });
            setFiles(newFilesRef.current);
          } catch (err) {
            failedIds.add(file.$id);
          }
        })
      );

      if (failedIds.size) {
        newFilesRef.current = newFilesRef.current.filter(
          (current) => !failedIds.has(current.id)
        );
      }

      setFiles(newFilesRef.current);
      return newFilesRef.current;
    },
    [initializeProgress, uploadFile, setFiles]
  );

  const updateFile = useCallback(
    async (newFile, params) => {
      const currentFiles = files;
      const index = currentFiles.findIndex(({ id }) => id === newFile.id);
      const fileStore = currentFiles[index];
      try {
        if (index !== -1) {
          const updated = { ...currentFiles[index], ...params };
          currentFiles.splice(index, 1, updated);
          setFiles([...currentFiles]);
          await onUpdateFile(newFile.id, params);
          return updated;
        }
      } catch (error) {
        if (index !== -1) {
          currentFiles.splice(index, 1, fileStore);
          setFiles([...currentFiles]);
        }
      }
    },
    [files, onUpdateFile, setFiles]
  );

  const deselectFile = useCallback(
    (file) => {
      setFiles((prev) => prev.filter((f) => f.id !== file.id));
    },
    [setFiles]
  );

  const sortedFiles = useMemo(() => {
    return files.filter(({ created = false }) => created).reverse();
  }, [files]);

  return [sortedFiles, { addFiles, deselectFile, setFiles, updateFile }];
};
