import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale, tableHover } from 'app/colors';
import { gutters } from 'app/spacing';
import Icon from 'components/Kizen/Icon';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  height: 100%;
  ${({ padding }) =>
    padding
      ? css`
          padding: 10px 15px;
        `
      : ''}
`;

export const Paragraph = styled.p`
  background-color: ${tableHover};
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  overflow-y: auto;
  color: ${grayScale.dark};

  ${({ center }) =>
    center
      ? css`
          text-align: center;
        `
      : ''}
`;

export const StyledIcon = styled(Icon)`
  && svg {
    height: 30px;
    width: 30px;
  }
  padding: 15px 0;

  &:hover {
    color: ${grayScale.mediumDark};
  }
`;

export const StyledMenuContainer = styled.div`
  position: absolute;
  right: ${gutters.spacing(3)}px;
`;
