import { ButtonProps } from '../../types';
import { Button } from '../types';

export type ButtonNodeProps = Partial<ButtonProps> & {
  isSubmitButton?: boolean;
};

export function button(): Button;
export function button(props: Partial<ButtonProps>): Button;
export function button(...args: [] | [Partial<ButtonProps>]): Button {
  return ['Button', { ...args[0], isSubmitButton: false }];
}

export function submitButton(): Button;
export function submitButton(props: Partial<ButtonProps>): Button;
export function submitButton(...args: [] | [Partial<ButtonProps>]): Button {
  return ['Button', { ...args[0], isSubmitButton: true }];
}
