import { useCallback, useMemo, useRef } from 'react';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { useTranslation } from 'react-i18next';
import CustomObjectService from 'services/CustomObjectsService';
import { FIELD_TYPES } from 'utility/constants';
import { facetResultToOption } from '../helpers';
import { isEmailStatusField } from 'checks/fields';
import { toastVariant, useToast } from 'components/ToastProvider';

export const useInfiniteFacetedOptions = ({
  field,
  model,
  includeFacetCount,
  fullFilterObject,
  entitySearch,
  hideFacetCountsOnTimeout,
  chosenValueIds = [],
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const selectRef = useRef(null);

  const fetch = useCallback(
    async ({
      search = '',
      page = '1',
      modelId,
      fieldId,
      filters = {},
      entitySearch = '',
      includeFacetCount = false,
    }) => {
      try {
        const res = await CustomObjectService.getFieldOptionsWithFacets(
          modelId,
          fieldId,
          { filters },
          {
            entitySearch,
            includeFacetCount,
            //simulateTimeout: includeFacetCount && page === '1', // use this for dev purposes to simulate timout behavior
            page,
            pageSize: 20,
            search,
          },
          { skipErrorBoundary: true }
        );

        if (includeFacetCount && !res.includesFacetCounts) {
          // as to BE implementation for first page of results in case of reaching timout
          // we will get success response with includesFacetCounts: false
          // so we suppress facet counts for this pill untill QuickFilters remount.
          // for other pages in case of reaching timout we will receive error response with 408 status code
          hideFacetCountsOnTimeout();
        }

        if (
          field.fieldType === FIELD_TYPES.Status.type &&
          !isEmailStatusField(field)
        ) {
          // we do not have meta.color for status field in this response
          // so we need to populate it from field.options
          const optionColorLookup = field.options.reduce(
            (collect, { id, meta }) => {
              collect[id] = meta?.color;
              return collect;
            },
            {}
          );
          res.results = res.results.map((option) => {
            option.color = optionColorLookup[option.id];
            return option;
          });
        }

        return res;
      } catch (error) {
        if (error?.response?.status === 408) {
          // we can expect request to timeout with 408 when (includeFacetCount === true && page > 1) from time to time,
          // as it is expencive for BE, so we just show toast and let us retry on next dropdown open
          showToast({
            message: t('Options failed to load'),
            variant: toastVariant.FAILURE,
          });
        }
        return { results: [] };
      }
    },
    [field, hideFacetCountsOnTimeout, showToast, t]
  );

  const params = useMemo(
    () => ({
      modelId: model?.id,
      fieldId: field?.id,
      filters: includeFacetCount ? fullFilterObject : null,
      entitySearch: includeFacetCount ? entitySearch : '',
      includeFacetCount,
    }),
    [field?.id, model?.id, includeFacetCount, fullFilterObject, entitySearch]
  );

  const [typeaheadProps, typeaheadState] = useSelectTypeaheadWithScroll({
    entity: Entities.FieldOptions,
    enabled: !!field?.id && !!model?.id,
    fetch,
    objectToOption: facetResultToOption,
    alwaysOpen: true,
    fieldId: field.id,
    selectRef,
    chosenValueIds,
    shouldRefetchOnMount: !includeFacetCount,
    shouldResetOptions: !includeFacetCount,
    params,
  });

  return {
    selectRef,
    typeaheadProps: { ...typeaheadProps, isLoading: typeaheadState.loading },
  };
};
