/**
 * Constructs a URL for navigating to different dashboards based on their type and IDs.
 *
 * @param {Object} currentDashboard - The current dashboard object containing its type and ID.
 * @param {string} customObjectId - The ID of the custom object, if applicable.
 * @param {string} clientObjectId - The ID of the client object, used to determine if the custom object ID matches the client object ID for chart groups.
 * @returns {string|null} The constructed URL for the dashboard or null if the dashboard type is unrecognized.
 */
const getDashboardUrl = (currentDashboard, customObjectId, clientObjectId) => {
  switch (currentDashboard?.type) {
    case 'generic_dashboard':
      return `/dashboard/${currentDashboard.id}`;
    case 'chart_group':
      if (customObjectId === clientObjectId) {
        return `/clients/charts/${currentDashboard.id}`;
      }
      return `/custom-objects/${customObjectId}/charts/${currentDashboard.id}`;
    case 'homepage':
      return `/home/${currentDashboard.id}`;
    default:
      return null;
  }
};

export default getDashboardUrl;
