import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from './Toast';
import { addToast, removeToast } from 'store/toastProvider/toastProvider.redux';

// here is an example of a different offset when in mobile and scolled
// const [showToast] = useToast({
//   yOffset: [
//     { breakpoint: 0, offset: scrolled ? 0 : 50 },
//     { breakpoint: breakpoints.lg, offset: 50 }
//   ]
// });
export const toastDelay = {
  short: 2000,
  default: 3000,
  long: 5000,
};

export const defaultToastData = {
  delay: toastDelay.short,
  variant: null,
  yOffset: 50,
  autohide: true,
  forceShow: true,
};
// variant types
export const toastVariant = {
  SUCCESS: 'success',
  ALERT: 'alert',
  FAILURE: 'failure',
};

export const useToast = (defaultProps) => {
  const dispatch = useDispatch();

  // new toast handler
  const showToast = useCallback(
    ({ message, variant = 'default', ...others }) => {
      variant = variant === 'default' ? toastVariant.SUCCESS : variant;
      dispatch(
        addToast({
          ...defaultToastData,
          delay:
            variant === toastVariant.FAILURE
              ? toastDelay.long
              : toastDelay.default,
          ...defaultProps,
          ...others,
          message,
          variant,
        })
      );
    },
    [defaultProps, dispatch]
  );

  const clearToasts = useCallback(() => {
    dispatch(removeToast({ forceClear: true }));
  }, [dispatch]);

  return [showToast, toastVariant, clearToasts];
};

const ToastProvider = ({ children }) => {
  const dispatch = useDispatch();
  const toast = useSelector((s) => s.toastProvider.toastData);
  const [showToast, setShowToast] = useState(false);
  const [toastCache, setToastCache] = useState(null);
  const [, setTimer] = useState(null);

  useEffect(() => {
    const initTimer = (delay, timestamp) => {
      setTimer((previousState) =>
        previousState !== null ? clearTimeout(previousState) : null
      );
      setTimer(
        setTimeout(() => {
          setShowToast(false);
          dispatch(removeToast({ timestamp }));
        }, delay)
      );
    };

    setShowToast(toast && toast.length);
    if (toast && toast.length) {
      setToastCache(toast[0]);
      const { autohide, delay, timestamp } = toast[0];
      if (autohide) {
        initTimer(delay, timestamp);
      }
    }
  }, [toast, dispatch]);

  const {
    message = '',
    timestamp = null,
    onClose = null,
    ...toastProps
  } = toastCache || {};

  const handleClose = () => {
    setTimer((previousState) => {
      if (previousState !== null) {
        clearTimeout(previousState);
      }
      return null;
    });
    setShowToast(false);

    dispatch(removeToast({ timestamp }));

    if (onClose) {
      onClose();
    }
  };
  return (
    <>
      {showToast ? (
        <Toast show={showToast} onClose={handleClose} {...toastProps}>
          {message}
        </Toast>
      ) : null}
      {children}
    </>
  );
};

export default ToastProvider;
