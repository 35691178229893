import { useCallback } from 'react';
import FileService from 'services/FileService';
export const ACTIVITY_FIELD_VALUE_FILE_UPLOAD_SOURCE = 'activity_field_value';
export const AUTOMATION_VALUE_FILE_UPLOAD_SOURCE = 'automation_value';
export const FIELD_VALUE_FILE_UPLOAD_SOURCE = 'field_value';
export const RECORD_IMPORT_FILE_UPLOAD_SOURCE = 'record_import';
export const EMPLOYEE_IMPORT_FILE_UPLOAD_SOURCE = 'employee_import';
export const FORM_FIELD_VALUE_FILE_UPLOAD_SOURCE = 'form_field_value';
export const PUBLIC_IMAGE_FILE_UPLOAD_SOURCE = 'public_image';
/**
 * @param {boolean} publicFile - flag to indicate if the uploaded file is publicly available
 * @param {function} handleProgress - function called during the upload to provide a progress update
 * @param {string} source - uploading source
 * @returns {Function}
 */
export const useUploadFile = (
  publicFile,
  handleProgress,
  source = FIELD_VALUE_FILE_UPLOAD_SOURCE
) =>
  useCallback(
    (file) => {
      return FileService.upload({
        file,
        id: file.$id,
        handleProgress,
        publicFile: !!publicFile,
        source,
      });
    },
    [handleProgress, publicFile, source]
  );
