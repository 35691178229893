// colorsPrimary and grayScale copied from apps/react-app/src/app/colors.js
const colorsPrimary = {
  blue: {
    dark: '#085bee',
    light: '#9CBDF8',
  },
  orange: {
    dark: '#f3a800',
    light: '#FADC99',
  },
  green: {
    dark: '#32c055',
    light: '#ADE6BB',
  },
  purple: {
    dark: '#4a21d1',
    light: '#B7A6ED',
  },
};
const grayScale = {
  black: '#000000',
  dark: '#4a5660',
  mediumDark: '#a8b3bc',
  medium: '#d8dde1',
  mediumLight: '#eceef0',
  light: '#f5f6f7',
  white: '#ffffff',
};

export const types = {
  Section: {
    type: 'Section',
    color: colorsPrimary.blue.dark,
    isContent: false,
  },
  Row: { type: 'Row', color: colorsPrimary.orange.dark, isContent: false },
  Cell: { type: 'Cell', color: colorsPrimary.green.dark, isContent: false },
  Content: {
    type: 'Content',
    color: colorsPrimary.green.dark,
    isContent: true,
  },
  CustomField: {
    type: 'CustomField',
    color: colorsPrimary.green.dark,
    isContent: true,
  },
  Attachments: {
    type: 'Attachments',
    color: colorsPrimary.green.dark,
    isContent: true,
  },
  FormField: {
    type: 'FormField',
    color: colorsPrimary.green.dark,
    isContent: true,
  },
  Button: {
    type: 'Button',
    color: colorsPrimary.green.dark,
    isContent: true,
  },
  HTMLBlock: {
    type: 'HTMLBlock',
    color: colorsPrimary.green.dark,
    isContent: true,
    group: 'advanced',
  },
  Text: {
    type: 'Text',
    color: colorsPrimary.green.dark,
    isContent: true,
  },
  Image: {
    type: 'Image',
    color: colorsPrimary.green.dark,
    isContent: true,
  },
  Divider: {
    type: 'Divider',
    color: colorsPrimary.green.dark,
    isContent: true,
  },
  Unknown: { type: 'Unknown', color: grayScale.dark, isContent: false },
} as const;

export type NodeType = keyof typeof types;

export const getTypeInfo = (type: NodeType | undefined) => {
  if (!type) {
    return null;
  }
  return types[type] || types.Unknown;
};
