import styled from '@emotion/styled';
import { addDays } from 'date-fns';
import { appBackground, colorsButton, grayScale } from 'app/colors';
import { borderRadii } from 'app/spacing';
import MonthCalendarCellHeader from './MonthCalendarCellHeader';
import { MonthCalendarCellProps } from './props';
import { isCellToday } from '../utils';

// 1. apply top and left border to cell
// 2. apply bottom border to last row & today
// 3. apply right border to last column & today
// 4. remove bottom top border from cell below today
// 5. remove left border from cell to the right of today
const Container = styled.div`
  position: relative;
  border: 1px solid;
  border-color: ${({ isToday }) =>
    isToday ? colorsButton.blue.default : grayScale.medium};

  border-bottom-width: ${({ index, total, isToday }) =>
    isToday || index >= total - 7 ? '1px' : 0};
  border-right-width: ${({ index, isToday }) =>
    isToday || ((index + 1) % 7 === 0 ? '1px' : 0)};
  border-top-width: ${({ removeTopBorder }) => (removeTopBorder ? 0 : '1px')};
  border-left-width: ${({ removeLeftBorder }) =>
    removeLeftBorder ? 0 : '1px'};

  border-top-left-radius: ${({ index }) =>
    index === 0 ? borderRadii.standard : 0};
  border-top-right-radius: ${({ index }) =>
    index === 6 ? borderRadii.standard : 0};
  border-bottom-left-radius: ${({ index, total }) =>
    index === total - 7 ? borderRadii.standard : 0};
  border-bottom-right-radius: ${({ index, total }) =>
    index === total - 1 ? borderRadii.standard : 0};

  background-color: ${({ isToday, isCurrentMonth }) => {
    if (isToday) {
      return appBackground;
    }
    return isCurrentMonth ? grayScale.white : grayScale.light;
  }};

  overflow: hidden;
`;

const MonthCalendarCell = ({
  date,
  calendarMonth,
  calendarYear,
  index,
  total,
  children,
  ...rest
}) => {
  const today = new Date();
  const yesterday = addDays(today, -1);
  const lastWeek = addDays(today, -7);
  const isToday = isCellToday(date);
  const isCurrentMonth = calendarMonth === date.getMonth();
  const removeTopBorder =
    lastWeek.getFullYear() === calendarYear &&
    lastWeek.getMonth() === calendarMonth &&
    lastWeek.getDate() === today.getDate();
  const removeLeftBorder =
    date.getFullYear() === calendarYear &&
    date.getMonth() === calendarMonth &&
    yesterday.getDate() === today.getDate();

  return (
    <Container
      index={index}
      total={total}
      isToday={isToday}
      isCurrentMonth={isCurrentMonth}
      removeTopBorder={removeTopBorder}
      removeLeftBorder={removeLeftBorder}
      {...rest}
    >
      {children}
    </Container>
  );
};

export const DefaultMonthCalendarCell = ({
  date,
  calendarMonth,
  children,
  ...rest
}) => (
  <MonthCalendarCell date={date} calendarMonth={calendarMonth} {...rest}>
    <MonthCalendarCellHeader date={date} calendarMonth={calendarMonth} />
    {children}
  </MonthCalendarCell>
);

export default MonthCalendarCell;

MonthCalendarCell.propTypes = MonthCalendarCellProps;
DefaultMonthCalendarCell.propTypes = MonthCalendarCellProps;
