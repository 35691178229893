import { useMemo } from 'react';
import { EVENT_TYPES } from '../../constants';
import { EmailStatisticsModal } from './EmailStatisticsModal';
import { AutomationStatisticsModal } from './AutomationStatisticsModal';

export const BroadcastStatisticsModal = ({ broadcast, ...modalProps }) => {
  const Modal = useMemo(() => {
    switch (broadcast.type) {
      case EVENT_TYPES.email.type:
        return EmailStatisticsModal;
      case EVENT_TYPES.automation.type:
        return AutomationStatisticsModal;
      default:
        return () => null;
    }
  }, [broadcast.type]);

  return <Modal broadcast={broadcast} {...modalProps} />;
};
