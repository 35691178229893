import { useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import useField from 'hooks/useField';
import { colorsButton, grayScale } from 'app/colors';
import { borderRadii } from 'app/spacing';
import { linkCss } from 'app/typography';
import BaseSelect from '__components/Inputs/Select';
import SelectOverlay from '__components/Inputs/SelectOverlay';
import Icon, { IconSizing } from '__components/Kizen/Icon';
import IconButton from '__components/Kizen/IconButton';
import { OVERLAY_ICON_BUTTON_SIZE } from '__components/Kizen/IconButton';
import Handle from '__pages/AutomationEngine/Engine/Handle';
import steps from '__pages/AutomationEngine/steps';
import { useTooltip, truncationPredicates } from '__components/Kizen/Tooltip';

const overflowXOrY = (el: HTMLElement) =>
  truncationPredicates.x(el) || truncationPredicates.y(el);

export const ADD_BUTTON_HEIGHT = 36;
export const ADD_BUTTON_WIDTH = OVERLAY_ICON_BUTTON_SIZE;

const ConsistentHeightWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${ADD_BUTTON_HEIGHT}px;
`;

const PlacementButton = styled.button<{ dropping: boolean; centered: boolean }>`
  position: relative;
  height: ${ADD_BUTTON_HEIGHT}px;
  min-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90px;
  padding: 1px 10px 0;
  ${linkCss()}
  background: ${grayScale.white};
  border-radius: ${borderRadii.small};
  &,
  &:focus {
    outline: none;
  }
  border: 1px dashed ${colorsButton.blue.default};
  ${({ dropping }) =>
    dropping &&
    css`
      border-style: solid;
    `}
  ${({ centered }) =>
    centered &&
    css`
      margin-left: calc(-50% + 30px);
    `}
`;

export const addTransformButtonOptionsTranslated = (t: TFunction) => [
  {
    value: steps.action.type,
    label: t('Add Action'),
    isDisabled: !steps.action.creatable,
  },
  {
    value: steps.condition.type,
    label: t('Add Condition'),
    isDisabled: !steps.condition.creatable,
  },
];

export const addUploadButtonOptionsTranslated = (t: TFunction) => [
  {
    value: steps.upload.type,
    label: t('Add Object Upload'),
    isDisabled: !steps.upload.creatable,
  },
];

const Select = styled(BaseSelect)`
  && {
    width: auto;
  }
`;

const DropArea = styled.span`
  position: absolute;
  top: -25px;
  bottom: -25px;
  right: -55px;
  left: -55px;
`;

interface iAddSmartConnectorButton {
  placing: boolean;
  centered: boolean;
  onClick: (value: any) => void;
  onDrop: () => void;
  showMenu: boolean;
  options: boolean;
  onSelectOption: (value: any) => void;
  disabled: boolean;
  dataQa: string;
  iconName: string;
}

export function AddSmartConnectorButton(props: iAddSmartConnectorButton) {
  const { t } = useTranslation();

  const [tooltipProps, tooltip] = useTooltip({}, overflowXOrY);
  const {
    placing,
    centered,
    onClick,
    onDrop,
    showMenu: showMenuProp,
    options,
    onSelectOption,
    disabled,
    dataQa = 'add-step',
    iconName = 'plus',
    ...others
  } = props;
  const propsRef = useRef<iAddSmartConnectorButton>(props);
  propsRef.current = props;
  const buttonRef = useRef();
  const [showMenu, setShowMenu] = useField(
    (prev: any) => {
      if (Boolean(showMenuProp) === showMenuProp) {
        return showMenuProp;
      }
      if (prev === undefined) {
        return false;
      }
      return prev;
    },
    [showMenuProp]
  );
  const [dropping, setDropping] = useState(false);

  useEffect(() => {
    if (dropping && !placing) {
      setDropping(false);
      if (propsRef.current.onDrop) propsRef.current.onDrop();
    }
    if (!dropping && !placing) {
      tooltipProps?.onBlur();
    }
  }, [placing, dropping, tooltipProps]);

  if (placing) {
    return (
      <PlacementButton
        dropping={dropping}
        centered={centered}
        onClick={onClick}
        {...others}
        {...tooltipProps}
      >
        {t('Place Here')}
        <Handle
          id="handle-left"
          type="target"
          position="left"
          style={{ left: 0 }}
        />
        <Handle
          id="handle-right"
          type="source"
          position="right"
          style={{ right: 0 }}
        />
        <DropArea
          onMouseEnter={() => setDropping(true)}
          onMouseLeave={() => setDropping(false)}
        />
        {tooltip}
      </PlacementButton>
    );
  }
  return (
    <ConsistentHeightWrapper>
      <IconButton
        title={t('Add Item')}
        ref={buttonRef}
        sizing="overlay"
        color={showMenu ? colorsButton.iconGray.hover : colorsButton.iconGray}
        onClick={(ev: HTMLElement) => {
          setShowMenu(true);
          if (onClick) {
            onClick(ev);
          }
        }}
        {...others}
        data-qa={`${dataQa}-button`}
        disabled={disabled}
      >
        <IconSizing size="16px" force={1}>
          {/* Effectively 12px wide due to icon's aspect ratio and padding */}
          <Icon icon={iconName} />
        </IconSizing>
        <Handle
          id="handle-left"
          type="target"
          position="left"
          style={{ left: 0 }}
        />
        <Handle
          id="handle-right"
          type="source"
          position="right"
          style={{ right: 0 }}
        />
      </IconButton>
      <SelectOverlay
        target={buttonRef.current}
        show={showMenu}
        onHide={() => setShowMenu(false)}
      >
        <Select
          menuOnly
          onChange={(val: any) => {
            setShowMenu(false);
            if (onSelectOption) onSelectOption(val);
          }}
          options={options}
          className={`${dataQa}-menu`} // data-qa doesn't propogate
        />
      </SelectOverlay>
    </ConsistentHeightWrapper>
  );
}
