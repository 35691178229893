import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { colorsText } from 'app/colors';
import { gutters } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import { fontSizes } from 'app/typography';

const Container = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: -1px; // matches Section's Header > h4 style
  i {
    margin-top: -1px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: ${gutters.spacing(1, 3)}px;
  font-weight: bold;
  font-size: ${fontSizes.text};
`;

export const TrayBackButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Container onClick={onClick}>
      <StyledIcon icon="chevron-left" size="1x" color={colorsText.light} />
      <KizenTypography
        as="h4"
        weight="bold"
        size="buttonLabel"
        textTransform="uppercase"
        color={colorsText.light}
      >
        {t('Build')}
      </KizenTypography>
    </Container>
  );
};
