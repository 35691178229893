import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import { ListCardCell } from 'components/Layout/ListCard';
import SmallTableCard from 'components/Layout/SmallTableCard';
import TableScrollContainer from 'components/Tables/ScrollContainer';

import pageSizingStyles from '../../styles';

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${pageSizingStyles};
  flex: 1;
  flex-direction: column;
`;

export const WrappedSmallTableCard = styled(SmallTableCard)`
  margin-bottom: ${gutters.spacing(4)}px;
`;

export const TableScrollWrapper = styled(TableScrollContainer)`
  .gradient-vertical-container {
    max-width: 100%;
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
`;

export const StyledListCardCell = styled(ListCardCell)`
  padding-right: ${gutters.spacing(1, +1)}px;
`;
