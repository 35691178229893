import { useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import LeadSourceService from 'services/LeadSourceServiceV2';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import {
  useSelectTypeaheadWithScroll,
  Entities,
} from 'components/Inputs/Select/hooks';

const DEFAULT_PAGE_SIZE = 20;

const toOption = ({ value, valueDisplay }) => ({ value, label: valueDisplay });

const translateData = (data) => {
  const results = data.results.map((result) => ({
    id: result.value,
    ...result,
  }));

  return {
    ...data,
    results,
  };
};

const LeadSourceSelect = ({ label, fieldName, values, onChange, ...rest }) => {
  const selectRef = useRef();

  // need unique fieldId for each field/entityRecordId/type combination/type
  const fieldId = useMemo(
    () => [fieldName, values?.entityRecordId, values?.type],
    [fieldName, values?.entityRecordId, values?.type]
  );

  const fetchLeadSource = useCallback(
    async ({ search, page = 1 }) => {
      const localValues = { ...values };
      delete localValues[fieldName];
      // KZN-5678: empty strings, "", should be considered a value and used to constrain the result set
      for (const prop in localValues) {
        if (localValues[prop] === '') {
          delete localValues[prop];
        }
      }
      const data = await LeadSourceService.searchOptionsTypeahead(
        fieldName,
        search,
        page,
        DEFAULT_PAGE_SIZE,
        localValues
      );
      return translateData(data);
    },
    [values, fieldName]
  );

  const { t } = useTranslation();

  const [typeaheadProps, typeaheadState] = useSelectTypeaheadWithScroll({
    selectRef,
    entity: Entities.LeadSource,
    fetch: fetchLeadSource,
    clearSearchOnBlur: false,
    objectToOption: toOption,
    fieldId,
  });

  return (
    <Select
      ref={selectRef}
      fullWidth
      label={label}
      placeholder={`${t('Choose or Create')} ${label}`}
      value={{ value: values[fieldName], label: values[fieldName] }}
      onChange={(selected) => {
        onChange(fieldName, selected ? selected.value : null);
      }}
      onInput={(event) => {
        onChange(fieldName, event.target.value);
      }}
      endAdornment={<IconAdornment icon="search" />}
      menuLeftButton={<ClearSelectButton />}
      menuInline
      inModal
      margin
      isLoading={typeaheadState?.loading}
      {...typeaheadProps}
      {...rest}
    />
  );
};

export default LeadSourceSelect;
