import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, shadowLight, grayScale } from 'app/colors';
import { Toast } from 'react-bootstrap';
import { fontWeights } from 'app/typography';
import { gutters } from 'app/spacing';
import CloseButton from 'components/CloseButton';
import { layers } from 'app/spacing';

export const StyledToastContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
  text-align: center;
  position: relative;
`;

function getBackgroundColor({ variant }) {
  switch (variant) {
    case 'alert':
      return colorsButton.blue.default;
    case 'failure':
      return colorsButton.red.default;
    default:
      // TODO ask Scott about adding this color to secondary palette
      return colorsButton.green.default;
  }
}

export const StyledToast = styled(Toast)`
  z-index: ${layers.toast};
  position: absolute;
  background-color: ${getBackgroundColor};
  border-radius: 4px;
  padding: ${gutters.spacing(2)}px;
  ${shadowLight}
  ${(props) =>
    (props.yOffset || props.yOffset === 0) &&
    css`
      top: ${props.yOffset}px;
    `}
  &:not(.show) {
    // A workaround for the toast not unmounting when hidden
    pointer-events: none;
  }
  && {
    max-width: 800px;
    border-width: 0;
  }
`;

export const StyledToastBody = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${fontWeights.bold};
  font-size: 14px;
  line-height: 12px;
  color: ${grayScale.white};
  line-height: 100%;
  max-width: 800px;
  justify-content: space-between;
  margin: -1px 0;
`;

export const StyledCloseButton = styled(CloseButton)`
  && {
    margin: 0 0 0 ${gutters.spacing(2)}px;
    border: 0;
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
`;
