import { useAsync } from 'react-use';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import FieldService from 'services/FieldService';
import PipelineService from 'services/PipelineService';
import { snakeToCamelCase } from 'services/helpers';
import { fieldTypeMapper } from 'components/Fields/FieldInput/helpers';
import { useCallback, useEffect, useState } from 'react';
import { setBoardSetting } from 'components/Wizards/CustomObject/utilities';
import CustomObjectsService from 'services/CustomObjectsService';
import { useFlashValidation } from 'hooks/useFlashState';
import { getOriginalError } from 'services/AxiosService';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { useCustomObjectWizard } from 'components/Wizards/CustomObject/CustomObjectWizardContext';
import { v4 as uuidv4 } from 'uuid';
import { SUMMARY_VALUES } from 'store/customObjectsRecordsPage/records.redux';

export function useBoardViewSettings({
  id,
  isPipeline,
  customLayoutSelectedTab,
}) {
  const [stagedValues, setStagedValues] = useState(EMPTY_OBJECT);
  const [errors, setErrors] = useFlashValidation();
  const { preSaveFn: setPreSaveCallBack } = useCustomObjectWizard();

  const { loading, value = EMPTY_OBJECT } = useAsync(async () => {
    try {
      const { categorizedFields: cats } =
        await CustomObjectsService.getCategorizedModelFields(id, {
          settingsSearch: true,
        });

      if (!isPipeline)
        return {
          categorizedFields: cats,
        };

      const [boardSettings, model] = await Promise.all([
        PipelineService.getBoardSettings(id),
        FieldService.getModel({ id }),
      ]);

      setStagedValues(
        getBoardSettingStructure(
          boardSettings,
          cats.flatMap((c) => c.fields).filter((f) => !f.isHidden)
        )
      );

      return {
        model: model,
        categorizedFields: cats,
        fieldData: cats.map((cat) => ({
          ...cat,
          fields: cat.fields
            .filter((f) => !f.isHidden)
            .map((f) => ({
              ...f,
              name: snakeToCamelCase(f.name),
              // Due to pervasive snakeToCamelCase conversions, and the fact that our schema is dynamic
              // sometimes we need to convert property values in order to later use those as prop keys
              // however, `snake_to_camel(camel_to_snake(value)) !== value` sometimes, leading to weird
              // breakages for *some* field types but not always. So we are copying the `orginalSnakeCaseName`
              // here as a low-risk way to maintiain the original field name so it can be used as expected
              // when we want the proper snake_case field name
              originalSnakeCaseName: f.name,
              fieldType: fieldTypeMapper(f),
            })),
        })),
        demoTeamMember: {
          label: 'John Doe',
          value: uuidv4(),
        },
      };
    } catch (err) {
      return EMPTY_OBJECT;
    }
    // we need customLayoutSelectedTab here for state to be properly updated after save or discard changes
  }, [id, isPipeline, customLayoutSelectedTab]);

  const preSaveCallBack = useCallback(async () => {
    if (isPipeline) {
      try {
        await setBoardSetting(id, {
          ...stagedValues,
          fields: stagedValues.fields.map((item) => item.value),
        });
      } catch (error) {
        const orig = getOriginalError(error);
        setErrors(snakeToCamelCaseKeys(orig.errors));
        throw new Error();
      }
    }
    return true;
  }, [isPipeline, id, stagedValues, setErrors]);

  useEffect(() => {
    setPreSaveCallBack(preSaveCallBack);
  }, [preSaveCallBack, setPreSaveCallBack]);

  return {
    loading,
    ...value,
    stagedValues,
    setStagedValues,
    errors,
  };
}
const getBoardSettingStructure = (boardSettings, fields) => ({
  ...boardSettings,
  fields: boardSettings.fields.length
    ? boardSettings.fields.reduce((acc, item) => {
        const field = fields.find((f) => f.id === item);

        if (field) {
          return [
            ...acc,
            {
              value: field.id,
              label: field.displayName,
              field,
            },
          ];
        }

        return acc;
      }, [])
    : [],
  summaryMetric: boardSettings.summaryMetric.name
    ? boardSettings.summaryMetric.id
    : SUMMARY_VALUES.noSummary,
  secondarySummaryMetric: boardSettings.summaryMetric.name
    ? boardSettings.secondarySummaryMetric.id
    : SUMMARY_VALUES.noSummary,
});
