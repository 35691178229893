import { useTranslation } from 'react-i18next';
import BackButton from 'components/Button/BackButton';
import { getPathBack } from '../helpers';

const toFn = ({ pathname }) => getPathBack(pathname, 2);

export const BackLink = ({ to = toFn }) => {
  const { t } = useTranslation();
  return (
    <BackButton to={to} data-qa="back-button">
      {t('back')}
    </BackButton>
  );
};
