import { fontSizes, KizenTypography } from 'app/typography';
import Switch from 'components/Kizen/Switch';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import { gutters, layers } from 'app/spacing';
import { css } from '@emotion/core';
import { breakpoints } from 'app/spacing';

export const StyledKizenTypography = styled(KizenTypography)`
  background: none;
  padding: 0;
  outline: none;
  display: inline-block;
  margin-top: 4px;
  &:focus {
    outline: none;
  }
  color: ${colorsButton.green.default};
  &:hover {
    color: ${colorsButton.green.hover};
  }
`;

export const Wrapper = styled.div`
  height: calc(100% - 34px);

  ${({ inModal }) =>
    inModal
      ? css`
          width: 100%;
        `
      : css`
          margin: 0 -${gutters.standard};
          display: flex;
          flex-direction: column;
          position: relative;
          overflow: hidden;
          height: calc(100% + 20px);
        `}

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    z-index: ${layers.content(100)};
    ${({ mobileView }) => (mobileView ? 'height: 30px' : 'height: 0')}
  }
`;

export const ActivityCountHeader = styled.div`
  ${({ inModal }) => (inModal ? 'display: none; ' : '')}
  display: flex;
  align-items: center;
  font-size: ${fontSizes.subheader};
  white-space: nowrap;
  margin-bottom: 1px;
  ${({ mobileView }) => (mobileView ? 'margin-left: 20px;' : '')}
  ${({ inModal, mobileView }) =>
    inModal && mobileView ? 'margin-left: 15px;' : ''}
  ${({ mobileView }) =>
    mobileView
      ? css`
          @media screen and (min-width: ${breakpoints.md}px) {
            margin-top: 0px;
          }
        `
      : css``}
`;

export const ActivityCountHeaderForModal = styled.div`
  display: flex;
  ${({ mobileView }) =>
    mobileView
      ? css`
          font-size: ${fontSizes.text};
          justify-content: start;
          margin
        `
      : css`
          font-size: ${fontSizes.subheader};
          justify-content: center;
        `}
  white-space: nowrap;
  justify-self: center;
  ${({ show }) =>
    show
      ? css`
          padding-bottom: 17px;
          min-width: 100px;
          min-height: 35px;
        `
      : ''}
  width: 100%;
`;

export const SearchPillWrapper = styled.div`
  position: absolute;
  ${({ mobileView }) =>
    mobileView
      ? css`
          top: 14px;
          right: 28px;
        `
      : css`
          top: 14px;
          right: 25px;
        `}
`;

export const SwitchReversedLabel = styled(Switch)`
  justify-content: space-between;
  align-items: baseline;
  margin: calc(12px - 3px) ${gutters.standard} calc(12px - 4px)
    ${gutters.standard};
  label {
    margin-left: 10px;
    margin-right: initial;
    margin-bottom: 0;
    width: 14px;
    height: 7px;
    input + span:before {
      height: 3px;
      width: 3px;
    }
    input:checked + span:before {
      transform: translateX(7px);
    }
  }
  p {
    white-space: nowrap;
    font-size: ${fontSizes.subheader};
    line-height: ${fontSizes.button};
  }
`;

export const Divider = styled.hr`
  height: 0;
  width: 120px;
  margin: 0 auto;
  border-top: 1px solid ${grayScale.mediumLight};
`;
