import styled from '@emotion/styled';

import { CardBasic } from 'components/Card/Card';
import KizenErrorCard from 'components/Kizen/ErrorCard';
import { SettingsContentCard } from 'components/Card/Card';

import { gutters } from 'app/spacing';

export const BigCard = styled(CardBasic)`
  width: calc((100% - ${gutters.spacing(4)}px) / 2);
  position: relative;
  margin-bottom: ${gutters.spacing(4)}px;
  padding: ${gutters.spacing(3)}px 0 ${gutters.spacing(4)}px;
  display: flex;
  flex-direction: column;
  > div:last-of-type {
    flex: 1;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${gutters.spacing(4)}px;
  margin-bottom: ${gutters.spacing(2, -4)}px;

  h3 {
    margin-top: -1px; // Design nudge
    line-height: 1; // Sidestep bootstrap
  }
`;

export const BigTitle = styled.div`
  align-items: flex-start;
  margin-bottom: ${gutters.spacing(5)}px;

  h3 {
    margin-top: ${gutters.spacing(1, -1)}px;
  }

  button {
    margin: 0;
  }
`;

export const CardContent = styled.div`
  padding: 0 ${gutters.spacing(4)}px;
`;

export const ErrorCard = styled(KizenErrorCard)`
  margin-top: -1px;
`;

export const StyledSettingsContentCard = styled(SettingsContentCard)`
  margin: ${gutters.spacing(3)}px auto ${gutters.spacing(4)}px;
`;

export const BottomCardsBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;
