import styled from '@emotion/styled';
import ToggleButton from 'components/Kizen/ToggleButton';
import IconButton from 'components/Kizen/IconButton';

export const StyledIconButton = styled(IconButton)`
  padding: 2px 15px 0;
`;

export const StyledToggleButton = styled(ToggleButton)`
  && > button {
    height: 28px;
    width: 44px;
    padding-top: 0;

    span {
      height: 28px;
      width: 44px;
      padding-top: 0;
    }

    i {
      width: 14px;
      height: auto;

      svg {
        width: 14px;
        height: auto;
      }
    }
  }
`;

export const SwitchWrapper = styled.div`
  display: inline-flex;
  align-items: center;

  p {
    margin-right: 10px;
  }
`;
