import styled from '@emotion/styled';
import ScrollBarDetached from '__components/Layout/ScrollBarDetached';
import css from '@emotion/css';
import { SCROLLBAR_SIZE } from '__components/Layout/ScrollBarDetached';

export const StyledDetachedScrollBar = styled(ScrollBarDetached)<{
  direction: 'vertical' | 'horizontal';
  topOffset: number;
  zedIndex: number;
}>`
  position: absolute;
  ${({ direction, topOffset = 10, zedIndex }) =>
    direction === 'vertical'
      ? css`
          top: -${topOffset - 10}px;
          bottom: ${10 + SCROLLBAR_SIZE}px;
          right: ${10}px;
          z-index: ${zedIndex};
        `
      : css`
          left: 10px;
          right: ${10 + SCROLLBAR_SIZE}px;
          bottom: 10px;
          z-index: ${zedIndex};
        `}
`;
