import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import BaseDateRangePicker from 'components/Kizen/DateRangePicker/DateRangePicker';

const StyledDateRangePicker = styled(BaseDateRangePicker)`
  z-index: ${layers.content(0, 1000)};

  .rs-picker-menu.rs-picker-daterange-menu {
    z-index: ${layers.content(0, 1000)};
  }

  .kizen-quick-selection {
    min-width: 102px;
  }
`;

export const DateRangePicker = ({ setIsShowing, ...rest }) => {
  return (
    <StyledDateRangePicker
      {...rest}
      broadcastState={(dateOpen) => setIsShowing(dateOpen)}
    />
  );
};
