import { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import useModal from 'components/Modals/useModal';
import FileLibraryModal from 'components/Inputs/FileInput/Modal';
import { cleanFiles } from 'components/Inputs/helpers';
import { WithTooltip } from './WithTooltip';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const FilesDisplay = ({ stagedValue = null }) => {
  const { t } = useTranslation();
  if (stagedValue && stagedValue.length !== 0) {
    return stagedValue.length === 1 ? (
      <>{t('1 File')}</>
    ) : (
      <>{`${stagedValue.length} ${t('Files')}`}</>
    );
  }
  return <>{t('0 Files Selected')}</>;
};

export default function FilesInlineField({
  value,
  field,
  object,
  onSubmit,
  autoFocus = false,
  onAutoFocus = null,
  disabled = false,
  viewable = true,
  tooltipLabel,
  popperConfig,
  placement,
  showToolTip,
  'data-field-id': dataFieldId,
  'data-field-type': dataFieldType,
  shouldFocusNext,
  ...others
}) {
  const fieldId = useMemo(() => `${field?.id}-${object?.id}`, [field, object]);
  const prevFocusRef = useRef(false);

  const [stagedValue, setStagedValue] = useState(
    (value && cleanFiles(value)) || []
  );
  useEffect(() => {
    setStagedValue((value && cleanFiles(value)) || []);
  }, [value]);

  const [modalProps, , modal] = useModal();

  const handleSubmit = async (next) => {
    const mapped = next.map((f) => f.id);
    await onSubmit(mapped);
  };
  const textRef = useRef();
  const onAutoFocusRef = useRef();
  onAutoFocusRef.current = onAutoFocus;

  const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();

  useEffect(() => {
    if (autoFocus && !assignFieldHandle) {
      if (prevFocusRef.current !== autoFocus) {
        modal.show();
      }
      if (onAutoFocusRef.current) {
        onAutoFocusRef.current(textRef.current);
      }
    }
    prevFocusRef.current = autoFocus;
  }, [autoFocus, modal, assignFieldHandle]);

  assignFieldHandle(fieldId, {
    customFocus: () => {
      textRef.current?.focus();
      return textRef.current;
    },
    customSpace: (ev) => {
      ev.preventDefault();
      if (viewable) {
        modal.show();
      }
    },
    customEnter: (ev) => {
      ev.preventDefault();
      if (viewable) {
        modal.show();
      }
    },
    disabled: disabled,
    shouldFocusNext: shouldFocusNext,
  });

  return (
    <>
      <WithTooltip
        tooltipLabel={tooltipLabel}
        popperConfig={popperConfig}
        placement={placement}
        showToolTip={showToolTip}
      >
        <div data-field-id={dataFieldId} data-field-type={dataFieldType}>
          <KizenTypography
            {...getKeyListenersProps?.(fieldId)}
            ref={textRef}
            type={viewable ? 'link' : ''}
            onClick={(ev) => {
              ev.preventDefault();
              if (viewable) {
                modal.show();
              }
            }}
            data-qa-file-input="open-button"
            {...others}
          >
            <FilesDisplay stagedValue={stagedValue} />
          </KizenTypography>
        </div>
      </WithTooltip>
      {modalProps.show && (
        <FileLibraryModal
          show
          files={stagedValue}
          onChange={handleSubmit}
          modalProps={modalProps}
          modal={modal}
          disabled={disabled}
          viewable={viewable}
          field={field}
          hasUnsavedConfirmation
        />
      )}
    </>
  );
}
