import styled from '@emotion/styled';
import { Text } from 'app/typography';
import { colorsPrimary } from 'app/colors';
import { StatusCodeTypes } from 'pages/Account/pages/Profile/Forms/EmailIntegration';

const colorMyStatus = (statusCode) => {
  const badColor = colorsPrimary.orange.dark;
  const goodColor = colorsPrimary.green.dark;

  const mappings = {
    [StatusCodeTypes.ERROR]: badColor,
    [StatusCodeTypes.NOT_SETUP]: badColor,
    [StatusCodeTypes.SYNCING]: goodColor,
    [StatusCodeTypes.SYNCED]: goodColor,
  };

  return mappings[statusCode] || goodColor;
};

export const StatusCell = styled(({ children, statusCode, ...props }) => (
  <Text color={colorMyStatus(statusCode)} {...props}>
    {children}
  </Text>
))`
  line-height: 100%;
`;
