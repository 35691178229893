import NumberInput from 'react-number-format';
import {
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import { SubActionHeadCell } from 'components/Kizen/Table/CellLineHeightStyles';
import ActionCell from 'components/Tables/ActionCell';
import ValidatedField from 'components/Kizen/ValidatedField';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { CO_OBJECT_TYPE_PIPELINE } from 'components/Wizards/CustomObject/utilities';
import { SUMMARY_VALUES } from 'store/customObjectsRecordsPage/records.redux';

export const CUSTOM_OBJECTS_ACTIONS = {
  EditObjectSettings: 'edit_object_settings',
  EditObjectSettingsPreview: 'edit_object_settings_preview',
  EditCustomFields: 'edit_custom_fields',
  Delete: 'delete',
};

const NO_VALUE = '—';

const getActions = (access, t) => {
  return Object.keys(access).reduce((acc, el) => {
    if (el === 'remove' && access[el]) {
      return acc.concat({
        value: CUSTOM_OBJECTS_ACTIONS.Delete,
        label: t('Delete'),
      });
    }

    if (el === 'edit' && access[el])
      return acc.concat([
        {
          value: CUSTOM_OBJECTS_ACTIONS.EditObjectSettings,
          label: t('Edit Object Settings'),
        },
        {
          value: CUSTOM_OBJECTS_ACTIONS.EditCustomFields,
          label: t('Edit Custom Fields'),
        },
      ]);

    return acc;
  }, []);
};

const textWithTruncation = (value) => (
  <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
);

const CustomObjectsActionCell = ({ data, t, ...others }) => {
  const { access = {} } = data;
  const options = getActions(access, t);
  return options.length === 0 ? (
    <div />
  ) : (
    <ActionCell
      title={t('Edit Custom Object')}
      data={data}
      options={options}
      {...others}
    />
  );
};

const getCustomObjectsColumns = ({ onUpdateField, onSelectAction, t }) => [
  {
    id: 'spacer',
    headCell: <SizedCell width="20px" />,
    cell: <SizedCell width="20px" />,
  },
  {
    id: 'objectName',
    headCell: (
      <SortableHeadCell
        label={t('Object Name')}
        by="object_name"
        minWidth="210px"
        padding="40px"
      />
    ),
    cell: <SizedCell padding="40px" minWidth="210px" />,
    format: (name, customObject) => {
      const { id, access = {}, entityAccess } = customObject;
      return (
        <ValidatedField
          type={entityAccess ? 'link' : 'text'}
          to={`/custom-objects/${id}`}
          value={name}
          shouldFocusNext={false}
          icon={access.edit ? undefined : null}
          onSubmit={(value) => {
            if (!value) {
              throw new Error('Custom Object names cannot be blank.');
            }
            if (customObject.objectName === value) {
              return null;
            }
            return onUpdateField(customObject, { objectName: value });
          }}
        />
      );
    },
  },
  {
    id: 'entityName',
    headCell: (
      <SortableHeadCell
        label={t('Entity Name')}
        by="entity_name"
        minWidth="150px"
        padding="40px"
      />
    ),
    cell: <SizedCell padding="40px" minWidth="150px" />,
    format: (name, customObject) => {
      const { id, access = {}, entityAccess } = customObject;
      return (
        <ValidatedField
          type={entityAccess ? 'link' : 'text'}
          to={`/custom-objects/${id}`}
          value={name}
          shouldFocusNext={false}
          icon={access.edit ? undefined : null}
          onSubmit={(value) => {
            if (!value) {
              throw new Error('Custom entity name cannot be blank.');
            }
            if (customObject.entityName === value) {
              return null;
            }
            return onUpdateField(customObject, { entityName: value });
          }}
        />
      );
    },
  },
  {
    id: 'objectType',
    headCell: (
      <SortableHeadCell
        by="object_type"
        label={t('Contains Workflow')}
        minWidth="150px"
        padding="40px"
      />
    ),
    cell: <SizedCell padding="40px" minWidth="150px" />,
    format: (value) => (
      <TextEllipsisWithTooltip textTransform="capitalize">
        {value === CO_OBJECT_TYPE_PIPELINE ? t('Yes') : NO_VALUE}
      </TextEllipsisWithTooltip>
    ),
  },
  {
    id: 'numberOfRecords',
    headCell: (
      <SortableHeadCell
        numeric
        by={SUMMARY_VALUES.numberOfRecords}
        label={t('# Records')}
        minWidth="90px"
        padding="40px"
      />
    ),
    cell: <SizedCell padding="40px" minWidth="90px" />,
    format: textWithTruncation,
  },
  {
    id: 'totalPipelineValue',
    headCell: (
      <SortableHeadCell
        numeric
        by="total_pipeline_value"
        label={t('Total Open Pipeline Value')}
        minWidth="173px"
        padding="40px"
      />
    ),
    cell: <SizedCell padding="40px" minWidth="173px" />,
    format: (value) => (
      <NumberInput
        shouldFocusNext={false}
        value={value}
        displayType="text"
        thousandSeparator
        prefix="$"
        fixedDecimalScale
        decimalScale={2}
        renderText={(valueProp) => (
          <TextEllipsisWithTooltip>{valueProp}</TextEllipsisWithTooltip>
        )}
      />
    ),
  },
  {
    id: 'created',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Date Created')}
        minWidth="105px"
        padding="40px"
      />
    ),
    cell: <SizedCell minWidth="105px" padding="40px" />,
    format: (created) => (
      <DateTimeInputInline
        readOnly
        shouldFocusNext={false}
        value={created}
        showDateTooltip
      />
    ),
  },
  {
    id: 'actions',
    headCell: <SubActionHeadCell width="47px" padding="20px" />,
    cell: (
      <CustomObjectsActionCell
        width="47px"
        padding="20px"
        onSelectAction={onSelectAction}
        menuType="absolute"
        t={t}
      />
    ),
  },
];

export default getCustomObjectsColumns;
