import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import CustomObjectsService from 'services/CustomObjectsService';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';
import useField from 'hooks/useField';
import { colorsButton, grayScale } from 'app/colors';
import {
  StyledActionButton,
  StyledIcon,
  StyledQickFiltersHeading,
  StyledQuickFiltersLayout,
} from './styles';
import { QuickFilterPill } from './QuickFilterPill';
import {
  getDisplayNamesPayload,
  getDisplayNamesPropNames,
  getFieldsForPills,
  getLookup,
} from './helpers';
import { MAX_FACET_RECORDS, MULTISELECT_FIELDS } from './constants';
import { ScrollOnHoverContainer } from './ScrollOnHoverContainer';
import { useScrollSubscription } from './hooks/useScrollSubscription';
import { FIELD_TYPES } from 'utility/constants';
import { useHistory } from 'react-router-dom';
import { monitoringExceptionHelper } from 'sentry/helpers';

export const QuickFilters = ({
  fields = EMPTY_ARRAY,
  quickFilters = EMPTY_OBJECT,
  quickFilterConfig = EMPTY_OBJECT,
  fullFilterObject,
  search,
  resultsCount = 0,
  onChange,
  isReadOnly,
  hasFilterErrors,
  model,
  errors = EMPTY_ARRAY,
}) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useField(quickFilters);
  const history = useHistory();

  const { quickFilterSettings, quickFilteringFacets } = quickFilterConfig;

  const pills = useMemo(
    () => getFieldsForPills(quickFilterSettings, fields, model),
    [quickFilterSettings, fields, model]
  );

  const handleChange = useCallback(
    (fieldId, pillId, nextValue) => {
      setFilters((prevQuery) => {
        let newValue;
        if (Array.isArray(nextValue)) {
          newValue = nextValue.length ? nextValue : undefined;
        } else {
          // case for other field types might need to be changed
          newValue = nextValue === null ? undefined : nextValue;
        }

        const nextQuery = { ...prevQuery };

        if (newValue === undefined) {
          delete nextQuery[pillId];
        } else {
          nextQuery[pillId] = {
            fieldId,
            value: newValue,
          };
        }

        onChange?.(nextQuery);

        return nextQuery;
      });
    },
    [setFilters, onChange]
  );

  const handleClear = () => {
    setFilters(EMPTY_OBJECT);
    onChange?.(EMPTY_OBJECT);
  };

  const pillsErrorIndices = useMemo(
    () =>
      pills.reduce(
        (collect, { key }) => {
          if (filters[key]) {
            collect[key] = collect.increment++;
          }
          return collect;
        },
        { increment: 0 }
      ),
    [pills, filters]
  );

  const { value: pillsValues = EMPTY_ARRAY } = useAsync(async () => {
    const values = Object.values(quickFilters || {});

    if (!values.length) {
      return pills.map(() => undefined);
    }
    let displayNamesData;
    const displayNamesPayload = getDisplayNamesPayload(pills, quickFilters);

    if (Object.keys(displayNamesPayload).length) {
      try {
        displayNamesData = await CustomObjectsService.getDisplayNames(
          displayNamesPayload,
          { skipErrorBoundary: true }
        );
      } catch (err) {
        monitoringExceptionHelper(err);
      }
    }

    return pills.map(({ field, key }) => {
      const value = quickFilters[key]?.value;

      if (value === undefined) {
        return value;
      }

      if (
        MULTISELECT_FIELDS.includes(field?.fieldType) &&
        Array.isArray(value)
      ) {
        const { responsePropName } = getDisplayNamesPropNames(field.fieldType);

        const {
          notAllowed = {},
          notFound = {},
          results = {},
        } = displayNamesData || {};

        const notFoundOptions = getLookup(notFound[responsePropName] || []);

        const updatedOptions = getLookup(results[responsePropName] || []);

        const notAllowedOptions = getLookup(notAllowed[responsePropName] || []);

        return value
          .reduce(
            (collect, v) => {
              let meta;
              if (field.fieldType === FIELD_TYPES.Status.type) {
                meta = field.options?.find((opt) => opt.id === v)?.meta;
              }
              if (notFoundOptions[v]) {
                collect[0].push({
                  value: v,
                  label: `[${t('Deleted')}] ${notFoundOptions[v].displayName}`,
                  error: true,
                  ...meta,
                });
              } else if (notAllowedOptions[v]) {
                collect[0].push({
                  value: v,
                  label: `[${t('No Access')}] ${
                    notAllowedOptions[v].displayName
                  }`,
                  error: true,
                  ...meta,
                });
              } else if (updatedOptions[v]) {
                collect[1].push({
                  value: v,
                  label: updatedOptions[v].displayName,
                  ...meta,
                });
              } else {
                collect[1].push(v);
              }
              return collect;
            },
            [[], []]
          )
          .flat();
      } else {
        return value;
      }
    });
  }, [t, filters]);

  const openQuickFiltersPage = () =>
    history.push(`/custom-objects/${model?.id}/quick-filters`);

  const scrollDeps = useMemo(
    () => [quickFilterConfig, filters],
    [quickFilterConfig, filters]
  );

  const [subscribeToScrollStart, unsubscribeFromScrollStart, onScrollStart] =
    useScrollSubscription();

  return (
    <StyledQuickFiltersLayout data-qa-id="quick-filter">
      <StyledQickFiltersHeading color={grayScale.mediumDark} weight="bold">
        {t('Quick Filters')}
      </StyledQickFiltersHeading>
      {pills.length ? (
        <ScrollOnHoverContainer
          scrollDeps={scrollDeps}
          onScrollStart={onScrollStart}
        >
          {pills.map(({ key, title, field, model, canBeFaceted }, i) => (
            <QuickFilterPill
              key={key}
              pillId={key}
              field={field}
              model={model}
              value={pillsValues[i]}
              title={title}
              isReadOnly={isReadOnly}
              onChange={handleChange}
              error={errors[pillsErrorIndices[key]]}
              subscribeToScrollStart={subscribeToScrollStart}
              unsubscribeFromScrollStart={unsubscribeFromScrollStart}
              fullFilterObject={fullFilterObject}
              search={search || ''}
              showFacetCounts={
                canBeFaceted &&
                !hasFilterErrors &&
                !!quickFilteringFacets &&
                resultsCount < MAX_FACET_RECORDS
              }
            />
          ))}
        </ScrollOnHoverContainer>
      ) : null}
      {Object.values(filters).filter(Boolean).length ? (
        <StyledActionButton
          title={t('Clear All Quick Filters')}
          color={colorsButton.blue}
          onClick={handleClear}
        >
          <StyledIcon icon="close-regular" />
        </StyledActionButton>
      ) : null}
      <StyledActionButton
        title={t('Edit Quick Filters')}
        color={colorsButton.blue}
        onClick={openQuickFiltersPage}
        wd={14}
      >
        <StyledIcon icon="pen" />
      </StyledActionButton>
    </StyledQuickFiltersLayout>
  );
};
