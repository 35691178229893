import { useMemo } from 'react';
import { formatTimestamp } from 'components/Timeline2/EventBlocks/helpers';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { useTranslation } from 'react-i18next';
import {
  ActionIconsContainer,
  Container,
  DateWrapper,
  MainIcon,
  RowContainer,
} from './styles';
import { EventActionsButton } from './EventActionsButton';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import { colorsButton } from 'app/colors';
import { getEventAccess, getEventDataQA } from 'pages/Broadcasts/helpers';
import { isBroadcastInvalid } from '../../helpers';
import { useDateTooltip } from 'components/Inputs/hooks/useDateTooltip';

export const EventBlock = ({
  type,
  date,
  label,
  parentCont,
  onSelectAction,
  access: accessProp,
  event,
  isPastEvent,
  ...rest
}) => {
  const { t } = useTranslation();
  const formattedTimestamp = useMemo(() => formatTimestamp(date), [date]);
  const broadcastIsInvalid = useMemo(() => isBroadcastInvalid(event), [event]);
  const failedColor = broadcastIsInvalid ? colorsButton.red.hover : undefined;

  const access = useMemo(
    () => getEventAccess(accessProp, event.access),
    [accessProp, event.access]
  );

  const { title, content, iconProps } = useMemo(() => {
    const { getTitle, getContent, icon, color } =
      EVENT_TYPES[type] || EVENT_TYPES.default;
    return {
      title: getTitle(t, event),
      content: getContent(t, event?.action),
      iconProps: {
        icon,
        color: failedColor || color,
      },
    };
  }, [t, type, event, failedColor]);

  const [dateTooltipProps, dateTooltip] = useDateTooltip({
    date,
  });

  return (
    <Container {...rest} {...getEventDataQA(event, broadcastIsInvalid)}>
      <MainIcon {...iconProps} />
      <RowContainer>
        <TextEllipsisWithTooltip
          weight="bold"
          color={failedColor}
          data-qa-title
        >
          {title}
        </TextEllipsisWithTooltip>
        {dateTooltip}
        <DateWrapper color={failedColor} {...dateTooltipProps}>
          {formattedTimestamp}
        </DateWrapper>
      </RowContainer>
      <RowContainer>
        <TextEllipsisWithTooltip color={failedColor} data-qa-content>
          {content}
        </TextEllipsisWithTooltip>
        <ActionIconsContainer>
          <EventActionsButton
            onSelectAction={onSelectAction}
            parentCont={parentCont}
            access={access}
            event={event}
            isPastEvent={isPastEvent}
          />
        </ActionIconsContainer>
      </RowContainer>
    </Container>
  );
};
