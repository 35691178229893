import { AxiosInstance } from 'axios';
import { curry1, curry2 } from './utils';

export type FileServiceConfig = {
  AWS_ACCESS_KEY_ID: string;
  AWS_REGION: string;
  S3_BUCKET: string;
  S3_URL: string;
};

type Source =
  | 'activity_export'
  | 'activity_field_value'
  | 'automation_value'
  | 'employee_export'
  | 'employee_import'
  | 'field_value'
  | 'form_submission_export'
  | 'form_field_value'
  | 'public_image'
  | 'record_export'
  | 'record_import'
  | 'survey_submission_export'
  | 'smart_connector_import';

type UploadFile = File & { $id: string };

type UploadFileOpts = {
  source?: Source;
  usePublicEndpoint?: boolean;
  publicFile?: boolean;
  handleProgress?(x: { id: string; progress: number }): void;
};

type UploadS3Opts = {
  key: string;
  signature: string;
  credential: string;
  dateString: string;
  policy: string;
  filename: string;
  mime: string;
  file: UploadFile;
  handleProgress?(opts: { id: string; progress: number }): void;
};

type SuccessOpts = {
  key: string;
  uuid: string;
  etag: string;
  filename: string;
  businessId: string;
  publicFile?: boolean;
  usePublicEndpoint?: boolean;
  source?: Source;
};

const MAX_FILE_SIZE_BYTES = 50e6; // 50mb
const EXPIRATION_LENGTH_MS = 5 * 60 * 1000; // 5min

function UUID() {
  let d = new Date().getTime();

  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); // use high-precision timer if available
  }

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

const getExpirationTime = (size: number, date = new Date()) => {
  if (size < MAX_FILE_SIZE_BYTES) {
    return new Date(date.getTime() + EXPIRATION_LENGTH_MS);
  }
  const expirationLengthMs = (size / 10000) * 60 * 1000;
  return new Date(date.getTime() + expirationLengthMs);
};

const urlEncodeForm = (obj: object) => {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    params.set(key, value);
  });
  return params.toString();
};

const getSignature = async (
  instance: AxiosInstance,
  env: FileServiceConfig,
  id: string,
  filename: string,
  mime: string,
  size: number,
  credential: string,
  dateString: string,
  usePublicEndpoint = false,
  source?: Source
) => {
  const ext = filename.split('.').slice(1).pop();
  const uuid = id || UUID();
  const key = ext ? `${uuid}.${ext}` : uuid;
  const url = usePublicEndpoint ? '/public/s3/signature' : '/s3/signature';
  const {
    data: { policy, signature },
  } = await instance.post(
    url,
    {
      expiration: getExpirationTime(size),
      conditions: [
        { acl: 'private' },
        { bucket: env.S3_BUCKET }, // checked server-side
        { 'content-type': mime },
        { success_action_status: '200' },
        { key },
        { 'x-amz-meta-qqfilename': filename },
        { 'x-amz-algorithm': 'AWS4-HMAC-SHA256' },
        { 'x-amz-credential': credential },
        { 'x-amz-date': dateString },
        [
          'content-length-range',
          '0',
          `${MAX_FILE_SIZE_BYTES * (source === 'smart_connector_import' ? 10 : 1)}`,
        ], // checked server-side (increased file size limit for smart connectors)
      ],
    },
    { params: { source } }
  );

  return {
    key,
    uuid,
    policy,
    signature,
  };
};

const uploadToS3 = async (
  instance: AxiosInstance,
  env: FileServiceConfig,
  {
    key,
    signature,
    credential,
    dateString,
    policy,
    filename,
    mime,
    file,
    handleProgress,
  }: UploadS3Opts
) => {
  if (env.AWS_ACCESS_KEY_ID === undefined) {
    console.warn(
      'Missing required environment variable VITE_AWS_ACCESS_KEY_ID for uploadToS3 call - this might fail.'
    );
  }
  if (env.AWS_REGION === undefined) {
    console.warn(
      'Missing required environment variable VITE_AWS_REGION for uploadToS3 call - this might fail.'
    );
  }
  if (env.S3_BUCKET === undefined) {
    console.warn(
      'Missing required environment variable VITE_FILE_UPLOAD_S3_BUCKET for uploadToS3 call - this might fail.'
    );
  }
  const formData = new FormData();
  formData.append('key', key);
  formData.append('content-type', mime);
  formData.append('success_action_status', '200');
  formData.append('acl', 'private');
  formData.append('x-amz-meta-qqfilename', filename);
  //formData.append('AWSAccessKeyId', env.AWS_ACCESS_KEY_ID!);
  formData.append('policy', policy);
  formData.append('x-amz-algorithm', 'AWS4-HMAC-SHA256');
  formData.append('x-amz-credential', credential);
  formData.append('x-amz-date', dateString);
  formData.append('x-amz-signature', signature);
  formData.append('file', file);

  const url = (env.S3_URL || 'https://{{bucket}}.s3.amazonaws.com/').replace(
    '{{bucket}}',
    env.S3_BUCKET!
  );

  const {
    headers: { etag },
  } = await instance.post('/', formData, {
    // Setting this tells the AxiosService to skip the v2 API auth, which isn't relevant to S3
    baseURL: url,
    timeout: undefined,
    withCredentials: false,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      if (handleProgress) {
        if (progressEvent.progress) {
          handleProgress?.({
            id: file.$id,
            progress: Math.round(progressEvent.progress * 100),
          });
        }
      }
    },
  });

  return { key, filename, mime, etag };
};

const onSuccess = async (
  instance: AxiosInstance,
  env: FileServiceConfig,
  {
    key,
    uuid,
    etag,
    filename,
    businessId,
    publicFile = false,
    usePublicEndpoint = false,
    source = 'field_value',
  }: SuccessOpts
) => {
  const url = usePublicEndpoint ? '/public/s3/success' : '/s3/success';
  const { data: file } = await instance.post(
    url,
    urlEncodeForm({
      key,
      uuid,
      name: filename,
      bucket: env.S3_BUCKET,
      etag,
      // Since this endpoint expects "form encoding" (supposedly),
      // we have to use python style "True" of "False"
      is_public: publicFile ? 'True' : 'False',
      ...(businessId && { business_id: businessId }),
    }),
    {
      params: {
        source: source, // I believe when this endpoint is hit from the frontend, "form" is the only appropriate value
      },
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
  );
  return file;
};

export const deleteFile = async (
  instance: AxiosInstance,
  bucket: string,
  id: string,
  key: string
) => {
  return instance.delete(`/files/${id}`, {
    params: { bucket, key },
  });
};

export const updateFile = async (
  instance: AxiosInstance,
  id: string,
  data: Record<string, string>
) => {
  return instance.patch(`/files/${id}`, data);
};

const getDateTimeString = (d: Date) => {
  function pad(n: number) {
    return n < 10 ? '0' + n : `${n}`;
  }
  return (
    d.getUTCFullYear() +
    pad(d.getUTCMonth() + 1) +
    pad(d.getUTCDate()) +
    'T' +
    pad(d.getUTCHours()) +
    pad(d.getUTCMinutes()) +
    pad(d.getUTCSeconds()) +
    'Z'
  );
};

const getDateString = (d: Date) => {
  function pad(n: number) {
    return n < 10 ? '0' + n : `${n}`;
  }
  return d.getUTCFullYear() + pad(d.getUTCMonth() + 1) + pad(d.getUTCDate());
};

const customMediaTypes: Record<string, string> = {
  kzn: 'application/kzn+zip',
};

export const uploadFile = async (
  instance: AxiosInstance,
  env: FileServiceConfig,
  file: UploadFile,
  id: string,
  businessId: string,
  opts: UploadFileOpts = {}
) => {
  const {
    source = 'field_value',
    usePublicEndpoint = false,
    publicFile = false,
    handleProgress,
  } = opts;
  const fileExtension = file.name.split('.').pop() || 'txt';
  const dateTimeString = getDateTimeString(new Date());
  const dateString = getDateString(new Date());
  const credential = `${env.AWS_ACCESS_KEY_ID}/${dateString}/${env.AWS_REGION}/s3/aws4_request`;
  const mediaType =
    file.type || customMediaTypes[fileExtension] || `text/${fileExtension}`; // browser in windows cannot detect mime correct
  const { key, uuid, policy, signature } = await getSignature(
    instance,
    env,
    id,
    file.name,
    mediaType,
    file.size,
    credential,
    dateTimeString,
    usePublicEndpoint,
    source
  );
  const { etag } = await uploadToS3(instance, env, {
    key,
    policy,
    signature,
    credential,
    dateString: dateTimeString,
    filename: file.name,
    mime: mediaType,
    file,
    handleProgress,
  });

  return onSuccess(instance, env, {
    key,
    uuid,
    etag,
    filename: file.name,
    businessId,
    publicFile,
    source,
    usePublicEndpoint,
  });
};

export class FileService {
  public deleteFile;
  public updateFile;
  public uploadFile;

  constructor(
    private instance: AxiosInstance,
    config: FileServiceConfig
  ) {
    this.deleteFile = curry2(deleteFile)(this.instance, config.S3_BUCKET);
    this.updateFile = curry1(updateFile)(this.instance);
    this.uploadFile = curry2(uploadFile)(this.instance, config);
  }
}
