import EmailPicker from 'components/Kizen/EmailPicker';
import { useContactsMergeFields } from 'hooks/useMergeFieldsBase';
import { useCallback, useState } from 'react';
import { StyledPicker, StyledLoader, DeletedEmailName } from '../styled';
import LibraryService, {
  broadcastForApi,
  broadcastForApp,
} from 'services/LibraryService';
import { BROADCAST_FORMDATA } from '../constants';
import { BroadcastName } from './BroadcastName';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import { TextSpan } from 'app/typography';
import { useTranslation } from 'react-i18next';
import useCan from 'hooks/useCan';
import { toastDelay, toastVariant, useToast } from 'components/ToastProvider';
import { getOriginalError } from 'services/AxiosService';
export const BroadcastEmailPicker = ({
  name,
  action,
  setFormData,
  disabled,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [message, setMessage] = useState(
    action?.id && !action.deleted ? broadcastForApp(action) : undefined
  );
  const { categorizedFieldOptions, loading } = useContactsMergeFields();
  const canViewLibrary = useCan({
    view: 'sections',
    for: 'library_section',
  });

  const handleAddEmail = useCallback(
    async (data) => {
      if (!data) {
        setMessage(null);
        setFormData(BROADCAST_FORMDATA.action, null);
        return;
      }
      if (data.id) {
        setMessage(data);
      } else {
        try {
          const res = await LibraryService.sendEmail(broadcastForApi(data));
          // don't overwrite existing smartSendTimeSplitPercentage state with what is returned from the api here (0.0).
          // this value is still editable on the form and will be committed when scheduling the event.
          const { smartSendTimeSplitPercentage, ...newMessage } =
            broadcastForApp(res);
          setMessage(newMessage);
          setFormData(BROADCAST_FORMDATA.action, newMessage);
        } catch (err) {
          const orig = getOriginalError(err);
          showToast({
            message:
              orig?.business_address ||
              t(
                'The email was not successfully queued. Please try again or contact Kizen support.'
              ),
            variant: toastVariant.FAILURE,
            delay: toastDelay.long,
          });
        }
      }
    },
    [setFormData, t, showToast]
  );

  return (
    <StyledLoader loading={loading}>
      <StyledPicker disabled={disabled}>
        {!message && action?.deleted ? (
          <>
            <DeletedEmailName>
              <TextSpan size="micro">{t('Selected Email')}:</TextSpan>
            </DeletedEmailName>
            <DeletedEmailName>
              <TextSpan size="micro" weight="bold">
                {t('Selected')}:
              </TextSpan>
              {` [${t('Deleted')}] ${action.name || action.subject}`}
            </DeletedEmailName>
          </>
        ) : null}
        <EmailPicker
          email={message}
          onChange={handleAddEmail}
          mergeFields={categorizedFieldOptions}
          disabled={disabled || !canViewLibrary}
          useAllIntgegratedInboxes
        />
      </StyledPicker>
      <BroadcastName
        setFormData={setFormData}
        name={name}
        action={message}
        disabled={disabled}
        eventType={EVENT_TYPES.email.type}
      />
    </StyledLoader>
  );
};
