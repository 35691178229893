import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

// This hook is used to overcome the issue with SelectOverlay scrolling page to top if autoFocus is set to true
// so we do not propagate autoFocus to SelectOverlay's children and instead use this hook to focus the input field directly
export const useAutoFocus = (inputElement, disabled) => {
  const [input, setInput] = useState(inputElement);

  useEffect(() => {
    if (inputElement && !input) {
      setInput(inputElement);
    }
  }, [inputElement, input]);

  useLayoutEffect(() => {
    if (!disabled && typeof input?.focus === 'function') {
      input.focus({ preventScroll: true });
    }
  }, [input, disabled]);

  return useCallback(
    (el) => {
      if (!input && el) {
        setInput(el);
      }
    },
    [input]
  );
};
