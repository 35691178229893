import { useMemo } from 'react';
import { useQuery } from 'react-query';
import CustomObjectsService from 'services/CustomObjectsService';
import { DASHBOARD } from 'queries/query-keys';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';

const useCustomObject = (customObjectId, enabled = true) => {
  const { isLoading: customObjectsLoading, data: customObjectsData } = useQuery(
    [
      ...DASHBOARD.CUSTOM_OBJECT_LIST,
      { defaultOnActivities: true, customOnly: true },
    ],
    () =>
      CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
        defaultOnActivities: true,
        customOnly: true,
      }),
    { enabled }
  );

  const customObject = useMemo(() => {
    return (
      (customObjectsData?.results ?? []).find((p) => p.id === customObjectId) ??
      {}
    );
  }, [customObjectId, customObjectsData]);

  return {
    loading: customObjectsLoading,
    customObject,
    customObjects: customObjectsData?.results ?? [],
  };
};

export default useCustomObject;
