import { useLayoutEffect, useRef } from 'react';

// This allows us to report updates in layout to inline/overlays
// so that the Select can be repositioned by popper.js. This is relevant
// when the overlay in constrained inside a scroll container then expands
// due to e.g. new options becoming available during typeahead: without
// repositioning, it could expand outside the container.  Note that the
// scheduleUpdate prop is automatically passed down by Overlay.
export default function useScheduleUpdate({ scheduleUpdate, options, value }) {
  const scheduleUpdateRef = useRef();
  scheduleUpdateRef.current = scheduleUpdate;
  useLayoutEffect(() => {
    if (scheduleUpdateRef.current) {
      scheduleUpdateRef.current();
    }
  }, [options, value]);
}
