import styled from '@emotion/styled';
import BuilderCard from '../../components/BuilderCard';
import Section from '../../components/Section';
import { fontWeights } from 'app/typography';
import { OutlineLabel } from 'components/Inputs/InputControl';
import Cols from 'components/Layout/Cols';
import { gutters } from 'app/spacing';
import Card from 'components/Card/Card';
import KizenTypography from 'app/kizentypo';
import BasicTable from 'components/Tables/Basic';
import CardToolbar from 'components/Layout/CardToolbar';
import {
  SizedCell as KizenSizedCell,
  SortableHeadCell as KizenSortableHeadCell,
} from 'components/Kizen/Table';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import WizardLoadingButton from 'components/Wizards/CustomObject/components/WizardLoadingButton';

export const StyledBuilderCard = styled(BuilderCard)`
  padding: 0;
  max-height: 496px;
`;
export const StyledCols = styled(Cols)`
  display: grid;
  column-gap: ${gutters.spacing(4)}px;
  grid-template-columns: minmax(0, 0.662fr) minmax(0, 0.338fr);

  & > div {
    width: 100%;
    max-width: 100%;
  }
`;
export const StyledSection = styled(Section)`
  margin-bottom: ${gutters.spacing(3, 9)}px;
`;
export const NoticeSection = styled(Section)`
  margin-bottom: ${gutters.spacing(2, 9)}px;
`;
export const MultiSelectSection = styled(Section)`
  margin-bottom: ${gutters.spacing(2, 9)}px;
`;
export const StyledOutlineLabel = styled(OutlineLabel)`
  margin-bottom: ${gutters.spacing(2)}px;
  font-weight: ${fontWeights.bold};
`;

export const StyledKizenTypography = styled(KizenTypography)`
  line-height: 1;
  margin-top: ${gutters.spacing(0)}px;
  padding-bottom: ${gutters.spacing(2, 1)}px;
`;

export const StyledCard = styled(Card)`
  padding: ${gutters.spacing(4)}px;
  padding-bottom: ${gutters.spacing(4)}px;
  margin-bottom: ${gutters.spacing(4)}px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledButton = styled(WizardLoadingButton)`
  margin-top: -${gutters.spacing(2)}px;
  margin-bottom: ${gutters.spacing(3, 3)}px;
  margin-right: 0;
`;

// Small alignment tweak, esp to align correctly with action button
export const SizedCell = styled(KizenSizedCell)`
  > div {
    line-height: 0;
    margin-top: 1px; // hack to force the th to 50px so the bottom border shows
  }
`;

// Small alignment tweak, esp to align correctly with action button
export const SortableHeadCell = styled(KizenSortableHeadCell)`
  > div {
    line-height: 0;
    margin-top: 1px; // hack to force the th to 50px so the bottom border shows
  }
`;
export const StyledBasicTable = styled(BasicTable)`
  thead > tr {
    height: ${gutters.spacing(8)}px;
  }
`;
export const StyledCardToolbar = styled(CardToolbar)`
  margin-bottom: ${gutters.spacing(0, 3)}px;
`;
export const StyledTableScrollContainer = styled(TableScrollContainer)`
  &::-webkit-scrollbar-track:vertical {
    margin-top: 40px;
    border-top: none;
  }
`;
