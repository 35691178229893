import { useMemo } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import SmallTableCard from 'components/Layout/SmallTableCard';
import Section from 'components/Wizards/CustomObject/components/Section';
import KizenTypography from 'app/kizentypo';
import Notice from 'components/Kizen/Notice';
import { CO_STAGE_STATUS_LOST } from 'components/Wizards/CustomObject/utilities';
import { FIELD_TYPES } from 'utility/constants';
import {
  DynamicTagAggregator,
  DynamicTagsManager,
} from 'components/Tables/DynamicTagsManager';
import { camelToSnakeCase } from 'services/helpers';

const TableCard = styled(SmallTableCard)`
  padding: ${gutters.spacing(2, -2)}px 0;
  border: 1px solid ${grayScale.medium};
  box-shadow: unset;
  ${({ prescroll }) =>
    prescroll &&
    css`
      height: unset;
    `}
`;

const StyledSection = styled(Section)`
  margin-bottom: 0;
`;

export const WorkflowReasonsSubsection = ({
  values,
  onChange,
  type = CO_STAGE_STATUS_LOST,
  objectKey,
  noItemsNotification,
  ...props
}) => {
  const lostInStage = useMemo(() => {
    return (
      !values?.pipeline?.stages ||
      values.pipeline.stages.some(({ status }) => status === type)
    );
  }, [type, values?.pipeline?.stages]);

  const field = useMemo(() => {
    return values?.undeletableFields?.[objectKey]
      ? {
          ...values.undeletableFields[objectKey],
          fieldType: FIELD_TYPES.DynamicTags.type,
          name: camelToSnakeCase(objectKey),
          properties: {
            allowOptions: true,
            allowMultiple: true,
            allowRelations: false,
            allowAdd: true,
            type: 'UUID',
            targetColumn: 'value_uuid',
          },
        }
      : {
          fieldType: FIELD_TYPES.DynamicTags.type,
          name: camelToSnakeCase(objectKey),
        };
  }, [objectKey, values?.undeletableFields]);

  return (
    <StyledSection {...props}>
      {lostInStage ? (
        <TableCard singleColumn prescroll>
          {field.id ? (
            <DynamicTagsManager
              model={values}
              field={field}
              data-qa={`reasons-${type}-table`}
            />
          ) : (
            <DynamicTagAggregator
              model={values}
              field={field}
              onChange={onChange}
              data-qa={`reasons-${type}-table`}
            />
          )}
        </TableCard>
      ) : (
        <Notice>
          <KizenTypography data-qa={`no-${type}-items-notification`}>
            {noItemsNotification}
          </KizenTypography>
        </Notice>
      )}
    </StyledSection>
  );
};
