import KizenTypography from 'app/kizentypo';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddRangeButton,
  BreakdownWrapper,
  LabelRow,
  MaxLabelContainer,
  MinLabelContainer,
} from '../shared/styles';
import ValueRow, { VALUE_BREAKDOWN_ACTION_TYPES } from './ValueRow';
import { v4 as uuidv4 } from 'uuid';
import useFlashState from 'hooks/useFlashState';
import Big from 'big.js';
import { parseValue, valueExists } from 'components/DashboardGrid/utils';
import { isPercentageChanceToCloseField } from 'checks/fields';

const handlers = {
  [VALUE_BREAKDOWN_ACTION_TYPES.UPDATE]: (state, payload) => {
    const { index, min, max, label } = payload;
    const newState = [...state];
    newState[index] = {
      ...newState[index],
      min: min ?? newState[index].min,
      max: max ?? newState[index].max,
      label: label ?? newState[index].label,
    };

    return newState;
  },
  [VALUE_BREAKDOWN_ACTION_TYPES.ADD_ROW]: (
    state,
    payload,
    { integer, fieldType }
  ) => {
    const { min, max, label } = payload;
    const newState = [...state];
    const previous = newState[newState.length - 1];
    const newRow = {
      min,
      max,
      label,
      id: uuidv4(),
    };
    const decimalPlaceholder = fieldType === 'money' ? 0.01 : 0.0001;

    if (valueExists(previous.max)) {
      const previousMax = parseValue(previous.max);

      // We need to do this calculation using arbitrary-precision decimal math
      // to avoid floating point precision errors.
      const currentMin = new Big(previousMax)
        .plus(new Big(integer ? 1 : decimalPlaceholder))
        .valueOf();

      newRow.min = currentMin;
    }
    newState.push(newRow);

    return newState;
  },
  [VALUE_BREAKDOWN_ACTION_TYPES.DELETE_ROW]: (state, payload) => {
    const { index } = payload;
    const newState = [...state];
    newState.splice(index, 1);

    return newState;
  },
};

export const getFallbackState = () => [
  {
    min: '0',
    max: '',
    id: uuidv4(),
  },
];

const buildValidationArray = (breakdown, field, t) => {
  let errorCount = 0;
  const validation = breakdown.map(
    ({ min: minString, max: maxString }, index) => {
      const isLast = index === breakdown.length - 1;
      const isFirst = index === 0;
      const hasMin = valueExists(minString);
      const hasMax = valueExists(maxString);
      const min = hasMin ? parseValue(minString) : null;
      const max = hasMax ? parseValue(maxString) : null;
      const previous = breakdown[index - 1];
      const hasPreviousMax = previous && valueExists(previous.max);
      const previousMax = hasPreviousMax ? parseValue(previous.max) : null;

      const errors = {
        errorCount: 0,
        min: {
          missing: false,
          validationMessage: '',
        },
        max: {
          missing: false,
          validationMessage: '',
        },
      };

      if (!hasMin && !hasMax) {
        errorCount += 1;
        errors.errorCount += 1;
        errors.min.missing = true;
      }

      if (!hasMin && !isFirst) {
        errorCount += 1;
        errors.errorCount += 1;
        errors.min.missing = true;
      }

      if (!hasMax && !isLast) {
        errorCount += 1;
        errors.errorCount += 1;
        errors.max.missing = true;
      }

      const hasMissingFields = errors.max.missing || errors.min.missing;

      if (hasMissingFields) {
        return errors;
      }

      if (hasMax && hasMin && max < min) {
        errorCount += 1;
        errors.errorCount += 1;
        errors.max.validationMessage = t(
          'Value must be greater than minimum value.'
        );
      }

      if (previous && min <= previousMax) {
        errorCount += 1;
        errors.errorCount += 1;
        errors.min.validationMessage = t(
          'Value must be greater than maximum value of previous range.'
        );
      }

      if (isPercentageChanceToCloseField(field)) {
        if (max > 100) {
          errorCount += 1;
          errors.errorCount += 1;
          errors.max.validationMessage = t(
            'Value must be less than or equal to 100.'
          );
        }
        if (min > 100) {
          errorCount += 1;
          errors.errorCount += 1;
          errors.min.validationMessage = t(
            'Value must be less than or equal to 100.'
          );
        }
      }

      return errors;
    }
  );

  return { validation, errorCount };
};

const ValueBreakdown = ({
  integer = true,
  initial = getFallbackState(),
  onChange,
  currencySymbol,
  field,
  compact = false,
  rowId,
  testId = 'value-breakdown',
  subRow = false,
}) => {
  const { t } = useTranslation();
  const [showErrors, setShowErrors] = useFlashState(3000, false);
  const BreakdownReducer = useCallback(
    (state, { type, payload = {}, config }) => {
      return (
        handlers[type]?.(state, payload, {
          ...config,
          integer,
        }) ?? state
      );
    },
    [integer]
  );

  const [breakdown, dispatchBreakdown] = useReducer(BreakdownReducer, initial);

  const handleClickAddRange = useCallback(() => {
    dispatchBreakdown({
      type: VALUE_BREAKDOWN_ACTION_TYPES.ADD_ROW,
      config: {
        fieldType: field?.fieldType,
      },
    });
  }, [field]);

  const { validation, errorCount } = useMemo(() => {
    return buildValidationArray(breakdown, field, t);
  }, [breakdown, t, field]);

  useEffect(() => {
    onChange?.(
      breakdown.map((row) => {
        const hasMin = valueExists(row.min);
        const hasMax = valueExists(row.max);
        return {
          ...row,
          min: hasMin ? parseValue(row.min) : null,
          max: hasMax ? parseValue(row.max) : null,
          rowId: rowId,
        };
      }),
      errorCount > 0,
      rowId,
      subRow
    );
  }, [breakdown, errorCount, onChange, rowId, subRow]);

  const handleAnyFieldBlur = useCallback(() => {
    if (errorCount > 0) {
      setShowErrors(true);
    }
  }, [setShowErrors, errorCount]);

  return (
    <BreakdownWrapper data-qa={testId}>
      <LabelRow>
        <MinLabelContainer compact={compact}>
          <KizenTypography>{t('Min')}</KizenTypography>
        </MinLabelContainer>
        <MaxLabelContainer compact={compact}>
          <KizenTypography>{t('Max')}</KizenTypography>
        </MaxLabelContainer>
        <KizenTypography>{t('Bucket Label')}</KizenTypography>
      </LabelRow>
      {breakdown.map((value, index) => {
        return (
          <ValueRow
            {...value}
            index={index}
            breakdown={breakdown}
            dispatchBreakdown={dispatchBreakdown}
            key={`value-breakdown-row-${value.id}`}
            validation={validation[index]}
            currencySymbol={currencySymbol}
            showErrors={showErrors}
            onBlur={handleAnyFieldBlur}
            field={field}
            compact={compact}
          />
        );
      })}
      {breakdown.length < 10 ? (
        <AddRangeButton
          variant="text"
          onClick={errorCount > 0 ? handleAnyFieldBlur : handleClickAddRange}
        >
          + {t('Add Range')}
        </AddRangeButton>
      ) : null}
    </BreakdownWrapper>
  );
};

export default ValueBreakdown;
