import {
  AUTOMATION_ACTION,
  AUTOMATION_STATE,
} from 'services/Automation2Service';
import { useTranslation } from 'react-i18next';
import { isExecutionImmutable } from '../AutomationsPage/helpers';
import { useSelector } from 'react-redux';
import { canViewAutomationExecutionInsights } from 'store/authentication/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StyledActionCell, StyledNoActionsCell } from './styles';

const ActionWidget = ({
  data: automation,
  onUpdateAutomation,
  scrollContainerRef,
  canStart = true,
  ...props
}) => {
  const { t } = useTranslation();
  const { automationActive } = automation;
  const scrollContainer =
    scrollContainerRef?.current || document.documentElement;

  const canDownload = useSelector(canViewAutomationExecutionInsights);

  const options = useMemo(() => {
    if (!canStart) {
      return [];
    }
    const options = [];
    if (!isExecutionImmutable(automation)) {
      switch (automation.status) {
        case AUTOMATION_STATE.PAUSED:
          automationActive &&
            options.push({
              value: AUTOMATION_ACTION.RESUME,
              label: t('Start Automation'),
            });
          break;
        case AUTOMATION_STATE.PAUSED_BY_AUTOMATION:
        case AUTOMATION_STATE.ACTIVE:
          options.push({
            value: AUTOMATION_ACTION.PAUSE,
            label: t('Pause Automation'),
          });
          break;
        case AUTOMATION_STATE.PAUSED_BY_FAILURE:
          automationActive &&
            options.push(
              {
                value: AUTOMATION_ACTION.RESUME,
                label: t('Retry and Resume'),
              },
              {
                value: AUTOMATION_ACTION.SKIP_STEP_AND_RESUME,
                label: t('Skip Step & Resume'),
              }
            );
          break;
        default:
      }
      options.push({
        value: AUTOMATION_ACTION.CANCEL,
        label: t('Cancel Automation'),
      });
    }

    if (canDownload) {
      options.push({
        value: AUTOMATION_ACTION.DOWNLOAD,
        label: t('Execution Details'),
      });
    }

    return options;
  }, [canDownload, automation, t, canStart, automationActive]);

  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSelectAction = ({ value }, data) => {
    onUpdateAutomation(data, value);
    onClose();
  };

  const onOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      scrollContainer.addEventListener('scroll', onClose, { once: true });
    } else {
      scrollContainer.removeEventListener('scroll', onClose);
    }

    return () => {
      scrollContainer.removeEventListener('scroll', onClose);
    };
  }, [open, scrollContainer, onClose]);

  return options.length ? (
    <StyledActionCell
      title={t('Edit Automation Execution')}
      options={options}
      data={automation}
      onSelectAction={onSelectAction}
      menuType="absolute"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      {...props}
    />
  ) : (
    <StyledNoActionsCell {...props} />
  );
};

export default ActionWidget;
