import RequestModal from 'components/AccessRequests/modal';
import searchState from 'components/AccessRequests/searchState';
import useDashboardList from 'components/AccessRequests/useDashboardList';
import CreateLayoutModal from 'components/DashboardGrid/CreateLayoutModal';
import { PageSizing } from 'components/Layout/PageContentWidth';
import {
  SubNavigationNavBar,
  SubNavigationNavLink,
} from 'components/Navigation/SubNavigation';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Switch, Route, useLocation } from 'react-router-dom';
import useCustomObjectChartGroupConfig from '../../hooks/useCustomObjectChartGroupConfig';
import AccessRequestsChartGroupsPage from '../AccessRequests';
import MyChartGroupsPage from '../Mine';
import { useSelector } from 'react-redux';
import { buildPage } from 'store/customObjectsRecordsPage/actions';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

const CustomObjectsChartGroupSettingsController = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { objectId } = useParams();
  const { debouncedTerm } = useContext(searchState.Context);
  const model = useSelector((s) => s.recordsPage.model);

  const modelId = model?.id;

  const syncDispatch = useSyncDispatch();

  useEffect(() => {
    if (!modelId) {
      syncDispatch(
        buildPage({
          customObjectId: objectId,
          page: {},
        })
      );
    }
  }, [syncDispatch, objectId, modelId]);

  const subRoutes = useMemo(() => {
    return [
      {
        label: (t) => t('My Chart Groups'),
        path: `/custom-objects/${objectId}/charts/settings/mine`,
        icon: 'user-circle',
        saveLocationOnNavigate: true,
        exact: true,
        Component: MyChartGroupsPage,
        dataKey: 'mine',
      },
      {
        label: (t) => t('Chart Group Access Requests'),
        path: `/custom-objects/${objectId}/charts/settings/others`,
        icon: 'share-alt',
        saveLocationOnNavigate: true,
        exact: true,
        Component: AccessRequestsChartGroupsPage,
        dataKey: 'other',
      },
    ];
  }, [objectId]);

  useEffect(() => {
    if (location.pathname === `/custom-objects/${objectId}/charts/settings`) {
      history.replace(`/custom-objects/${objectId}/charts/settings/mine`);
    }
  }, [location, history, objectId]);

  const {
    modalProps,
    requestModalProps,
    canUserEditPermissions,
    existingDashboard,
    data,
    loading,
    configLoading,
    refetching,
    ...routeProps
  } = useDashboardList(
    { debouncedTerm, objectId, type: 'chart_group' },
    useCustomObjectChartGroupConfig
  );

  return (
    <>
      <SubNavigationNavBar>
        {subRoutes.map((route, index) => {
          return (
            <SubNavigationNavLink
              key={`${route.path}-link`}
              route={{
                label: route.label(t),
                path: route.path,
                icon: route.icon,
              }}
              numberOfLinks={subRoutes.length}
              isLast={index === subRoutes.length - 1}
            />
          );
        })}
      </SubNavigationNavBar>
      <CreateLayoutModal
        {...modalProps}
        canUserEditPermissions={canUserEditPermissions}
        existing={existingDashboard}
        entity={t('Chart Group')}
      />
      <RequestModal {...requestModalProps} dashboard={existingDashboard} />
      <PageSizing>
        <Switch>
          {subRoutes.map((route) => {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                {/* These components are the page in /pages */}
                <route.Component
                  goBackPath={`/custom-objects/${objectId}`}
                  data={data[route.dataKey] ?? []}
                  loading={loading[route.dataKey] || configLoading}
                  isRefetching={refetching[route.dataKey]}
                  objectId={objectId}
                  {...routeProps}
                />
              </Route>
            );
          })}
        </Switch>
      </PageSizing>
    </>
  );
};

export default CustomObjectsChartGroupSettingsController;
