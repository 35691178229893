import styled from '@emotion/styled';
import Card from '__components/Card/Card';
import { KizenTypography } from '__app/typography';
import Loader from '__components/Kizen/Loader';
import css from '@emotion/css';

export const StyledLoader = styled(Loader)`
  height: 100%;
  ${({ paddingTop = 0 }) => css`
    padding-top: ${paddingTop} !important;
  `};
`;

export const StyledCard = styled(Card)`
  padding: 20px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1372px;
`;

export const StyledStepHeader = styled(KizenTypography)`
  margin-bottom: 20px;
  &:not(:first-of-type) {
    margin-top: 25px;
  }
`;
