import styled from '@emotion/styled';
import css from '@emotion/css';
import { appBackground, grayScale } from 'app/colors';
import { DropPlaceholder } from 'components/MultiColumnBuilder/components/BaseOption/styles';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import EditableText from 'components/Kizen/EditableText';

export const BuilderWrapper = styled.div`
  height: 420px;
  display: flex;
  flex-direction: column;
`;

export const EditableField = styled(EditableText)`
  max-width: 100%;
  padding-right: 10px;
`;

export const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
  flex-grow: 1;
  margin-right: 20px;
  width: 0;
`;

export const StyledEditableText = styled(EditableText)`
  display: flex;
  flex-grow: 1;
  margin-right: 20px;
  width: 0;
`;

export const StyledDropPlaceholder = styled(DropPlaceholder)`
  & > div > div {
    border: 1px dashed ${grayScale.mediumLight};
    background: ${appBackground};
    margin-top: ${({ margin }) => margin ?? 5}px;

    padding-bottom: 14px;
    margin-right: 18px;
    ${({ over }) =>
      over
        ? css`
            border-style: solid;
          `
        : ''}
  }
  p {
    color: ${grayScale.mediumDark};
  }
  p:hover {
    color: ${grayScale.dark};
  }
`;

export const LeftColumnWrapper = styled.div`
  padding-top: 14px;
  padding-right: 20px;
  margin-left: -1px; // design nudge
`;

export const TextWrapper = styled.div`
  max-width: 400px;
`;
