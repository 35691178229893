import { useCallback, useMemo, useState } from 'react';
import { get } from 'lodash';
import Select from '__components/Inputs/Select';
import { DropdownProps, SelectOption } from './types';
import { getSelectValue, useFilterOptions } from '../hooks/useFilterOptions';

export const Dropdown = ({
  step,
  width,
  url,
  method,
  next,
  placeholder,
  options: optionsProp,
  value,
  onChange,
  result_path,
  value_id_path,
  option_filter,
  option_paths,
  body,
  params,
  endAdornment,
  error,
  isStatus = false,
}: DropdownProps) => {
  const [removeInvalidOptions, setRemoveInvalidOptions] = useState(false);
  const v =
    value_id_path && value && typeof value === 'object'
      ? get(value, value_id_path)
      : value;

  const [invalidOptionId] = useState(() => {
    if (value_id_path && (value?.not_found || value?.not_allowed)) {
      return get(value, value_id_path);
    }
    return null;
  });

  const { isLoading, options, lookup, onMenuClose, onMenuOpen } =
    useFilterOptions(
      method,
      url,
      result_path,
      value_id_path,
      option_paths,
      option_filter,
      body,
      params,
      optionsProp,
      value
    );

  const handleChange = useCallback(
    (opt: SelectOption) => {
      if (opt.value !== invalidOptionId) {
        setRemoveInvalidOptions(true);
      }
      onChange(step, {
        next: opt.next ?? next,
        value: lookup.has(opt.value) ? lookup.get(opt.value)! : opt.value,
      });
    },
    [step, next, lookup, invalidOptionId, onChange]
  );

  const visibleOptions = useMemo(() => {
    if (removeInvalidOptions) {
      return options.filter((opt) => opt.value !== invalidOptionId);
    }

    return options;
  }, [options, invalidOptionId, removeInvalidOptions]);

  return (
    <Select
      width={width}
      value={getSelectValue(v)}
      options={visibleOptions}
      isColored={isStatus}
      placeholder={placeholder}
      onChange={handleChange}
      endAdornment={endAdornment}
      loadItems={isLoading}
      error={error}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
    />
  );
};
