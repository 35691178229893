import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import AddButton from 'components/Button/AddButton';
import { useEditor } from 'components/WYSIWYG';
import { insertMergeField } from 'components/WYSIWYG/utils/commands';

import { useMergeFields } from 'components/WYSIWYG/use-merge-fields';
import { useGridLikeFlexChildProps } from 'components/StandaloneToolBar/components/GridLikeFlex';
import { TextEditorToolbarSection } from 'components/StandaloneToolBar/styles';

///
import { useModalControl } from 'hooks/useModalControl';
import { MultiSourceMergeModal } from '../../components/MultiSourceMergeModal';

///
const InsertMergeFieldButton = styled(AddButton)`
  width: 154px;
  padding: 0;
  margin: 0 ${gutters.spacing(3)}px;
  height: unset;
  line-height: unset;
  cursor: pointer;
`;

const ToolbarSuggestionContainer = styled.div`
  position: relative;
`;

const ToolbarSuggestion = ({ onChange, onCloseModal, multipleMergeFields }) => {
  const { t } = useTranslation();

  const [modalOpen, { showModal, hideModal }] = useModalControl();
  const handleModalClose = () => {
    hideModal();
    onCloseModal();
  };

  const handleSelect = (value) => {
    hideModal();
    onChange(value);
  };

  return (
    <ToolbarSuggestionContainer>
      {modalOpen && (
        <MultiSourceMergeModal
          show={modalOpen}
          onConfirm={handleSelect} // handle select
          onHide={handleModalClose}
          multipleMergeFields={multipleMergeFields}
        />
      )}
      <InsertMergeFieldButton
        as="span"
        color="blue"
        className="insert-btn"
        onClick={showModal}
      >
        {t('Insert Merge Field')}
      </InsertMergeFieldButton>
    </ToolbarSuggestionContainer>
  );
};

export const InsertMultiSourceMergeFieldToolbarSection = ({
  multipleMergeFields,
  mergeFields,
  appendMergeFieldExtraSpace,
}) => {
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  const { data, isLoading } = useMergeFields({
    listHeaders: true,
    mergeFields,
  });

  const handleModalClose = () => {
    editor.chain().focus().run();
  };

  if (!editor) return null;
  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <ToolbarSuggestion
        options={data}
        isLoading={isLoading}
        onCloseModal={handleModalClose}
        multipleMergeFields={multipleMergeFields}
        onChange={(mergeFieldData) => {
          editor
            .chain()
            .focus()
            .command(
              insertMergeField(mergeFieldData, appendMergeFieldExtraSpace)
            )
            .run();
        }}
      />
    </TextEditorToolbarSection>
  );
};
