import StyledButton, { ToggleContainer } from './styles';

const ToggleButton = ({
  disabled = null,
  options,
  sizing = 'dense',
  onChange,
  value,
  color = 'blue',
  onlyTopRadius = false,
  ...props
}) => {
  return (
    <ToggleContainer {...props} sizing={sizing}>
      {options.map((option) => (
        <StyledButton
          key={option.value}
          noMinWidth
          onClick={() => onChange(option)}
          variant={value === option.value ? 'default' : 'outline'}
          sizing={sizing}
          onlyTopRadius={onlyTopRadius}
          color={color}
          disabled={disabled}
          isActive={value === option.value}
          data-qa={option.value}
          title={option.title}
          singleOption={options.length === 1}
        >
          {option.label}
        </StyledButton>
      ))}
    </ToggleContainer>
  );
};

ToggleButton.displayName = 'ToggleButton';

export default ToggleButton;
