import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { colorsButton, grayScale } from '../../app/colors';
import { fontSizes, fontWeights } from '../../app/typography';
import { borderRadii, gutters } from '../../app/spacing';

// Button Styling
export default styled('button', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'noSpace',
})(
  ({ variant, noMinWidth, noSpace }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontSize: fontSizes.buttonLabel,
    fontWeight: String(fontWeights.bold), // Numeric interpreted as pixels
    margin: noSpace ? '0' : '.5rem .75rem',
    padding: noSpace
      ? '2px 0 0 0'
      : `2px ${gutters.spacing(3)}px 0 ${gutters.spacing(3)}px`,
    height: '36px',
    lineHeight: '34px',
    minWidth: !noMinWidth && variant !== 'text' ? '90px' : 'auto',
    letterSpacing: '0.8px',
    borderRadius: borderRadii.standard,
    ':focus': {
      outline: 'none',
      boxShadow: 'none',
    },
    ':hover': {
      label: {
        cursor: 'pointer',
      },
    },
    ':disabled': {
      ':hover': {
        label: {
          cursor: 'default',
        },
      },
    },
    '::after': {
      marginRight: '-.8px', // To make the margin equal to the letter-spacing, which is .8px (Just based on the solution we've been referencing here https://stackoverflow.com/a/64768555)
      content: '""',
    },
  }),
  ({ variant, color }) => {
    const map = {
      default: {
        backgroundColor: colorsButton[color].default,
        borderColor: colorsButton[color].default,
        color: grayScale.white,
        ':hover': {
          backgroundColor: colorsButton[color].hover,
          borderColor: colorsButton[color].hover,
          color: grayScale.white,
        },
        ':active': {
          backgroundColor: colorsButton[color].active,
          borderColor: colorsButton[color].active,
          color: grayScale.white,
        },
        ':disabled': {
          backgroundColor: grayScale.medium,
          borderColor: grayScale.medium,
          opacity: 1,
          color: grayScale.white,

          ':hover': {
            label: {
              cursor: 'default',
            },
          },
        },
      },
      outline: {
        backgroundColor: 'unset',
        borderColor: colorsButton[color].default,
        color: colorsButton[color].default,
        border: '1px solid',
        ':hover': {
          backgroundColor: colorsButton[color].hover,
          borderColor: colorsButton[color].hover,
          color: grayScale.white,
        },
        ':active': {
          backgroundColor: colorsButton[color].active,
          borderColor: colorsButton[color].active,
          color: grayScale.white,
        },
        ':disabled': {
          backgroundColor: 'unset',
          borderColor: grayScale.medium,
          opacity: 1,
          color: grayScale.medium,
        },
      },
      light: {
        backgroundColor: grayScale.white,
        borderColor: colorsButton[color].default,
        color: colorsButton[color].default,
        border: '1px solid',
        ':hover': {
          backgroundColor: colorsButton[color].hover,
          borderColor: colorsButton[color].hover,
          color: grayScale.white,
        },
        ':active': {
          backgroundColor: colorsButton[color].active,
          borderColor: colorsButton[color].active,
          color: grayScale.white,
        },
        ':disabled': {
          backgroundColor: 'unset',
          borderColor: grayScale.medium,
          opacity: 1,
          color: grayScale.medium,
        },
      },
      text: {
        color: colorsButton[color].default,
        border: 'unset',
        ':hover': {
          color: colorsButton[color].hover,
        },
        ':active': {
          color: colorsButton[color].hover,
        },
        ':disabled': {
          opacity: 1,
          color: grayScale.medium,
        },
      },
    };
    return map[variant] || {};
  }
);
