import { useCallback, useEffect } from 'react';
import { invalidate } from 'queries/invalidate';
import { useTranslation } from 'react-i18next';
import TimelineService from 'services/TimelineService';
import KizenTypography from 'app/kizentypo';
import { Header, Title } from './styles';
import { parseMentions } from 'components/Inputs/NotesRichTextEditor';

export const useTimeLine = () => {
  useEffect(() => {
    invalidate.TIMELINE.ALL();
  }, []);

  const onComment = useCallback(
    async ({ event, newComment: text }) =>
      TimelineService.commentOnEvent(event, {
        subject_id: event.groupId ? event.groupId : event.id,
        subject_type: 'subject_type_timeline',
        subject_datetime_partition_key: event.created,
        text,
        mentions: parseMentions(text),
      }),
    []
  );

  const methods = {
    onComment,
    onDelete: () => {},
  };

  return {
    timeLineProps: {
      methods,
    },
    methods,
  };
};

export const useDateFilters = (setFilters) => {
  const filterByDateHandler = useCallback(
    ([from, to]) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        date_from: new Date(from).toISOString(),
        date_to: new Date(to).toISOString(),
      }));
    },
    [setFilters]
  );
  return { filterByDateHandler };
};

export const TimelineErrorFallback = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header>
        <Title>{t('Timeline')}</Title>
      </Header>
      <KizenTypography>
        {t('Timeline could not be loaded at this time.')}
      </KizenTypography>
    </>
  );
};
