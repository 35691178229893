import { createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompleteActivityModal } from '__components/Modals/ActivityCompletionModal/ActivityCompletionModal';
import useModal from '__components/Modals/useModal';
import { toastVariant, useToast } from '__components/ToastProvider';
import {
  FAILED_COMPLETE_ACTIVITY,
  LOG_ACTIVITY_SUCCESS,
} from '__pages/Common/Actions/activityHelpers';
import ActivityService from '__services/ActivityService';
import { getOriginalError } from '__services/AxiosService';

type HomepageLogActivityCtx = {
  openLogActivityModal: (activityId: string) => void;
};

type Activity = {
  created: string;
  id: string;
  isEditable: boolean;
  name: string;
};

type LogActivityRequest = {
  activityObjectId: string;
  dirtyFields: boolean;
  payload: {
    fields: any[];
    mentions: any[];
    notes: string;
    relatedObjects: any[];
    scheduledActivityId?: string;
  };
};

const context = createContext<HomepageLogActivityCtx>({
  openLogActivityModal: () => {},
});

export const useHomepageLogActivityContext = () => {
  return useContext(context);
};

export const HomepageLogActivityContext = ({ children }: any) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [data, setData] = useState<Activity | null>(null);
  const [loggingActivity, setLoggingActivity] = useState(false);

  const handleLogActivity = async (req: LogActivityRequest) => {
    const { activityObjectId, payload } = req;
    setLoggingActivity(true);
    await ActivityService.v2CompleteActivity({
      activityObjectId,
      payload,
    });
    showToast({
      message: LOG_ACTIVITY_SUCCESS(t),
      variant: toastVariant.SUCCESS,
    });
    setData(null);
    setLoggingActivity(false);
  };

  const handleLogActivityError = (error: any) => {
    const orig = getOriginalError(error);
    showToast({
      message: orig?.message || FAILED_COMPLETE_ACTIVITY(t),
      variant: toastVariant.FAILURE,
    });
    setData(null);
    setLoggingActivity(false);
  };

  const [modalProps, , { show: showModal, hide: hideModal }] = useModal({
    handleSubmit: handleLogActivity,
    handleError: handleLogActivityError,
  });

  const openLogActivityModal = useCallback(
    async (id: string) => {
      try {
        showModal();
        const res = await ActivityService.v2GetActivity(
          {
            activityObjectId: id,
          },
          { skipErrorBoundary: true }
        );
        setData(res);
      } catch (err: any) {
        if (err?.response?.status === 404) {
          showToast({
            message: t('Activity was deleted'),
            variant: toastVariant.FAILURE,
          });
        }
        hideModal();
      }
    },
    [showModal, hideModal, showToast, t]
  );

  return (
    <context.Provider value={{ openLogActivityModal }}>
      {children}
      <CompleteActivityModal
        {...modalProps}
        activity={{ activityObject: data }}
        disabled={loggingActivity}
      />
    </context.Provider>
  );
};
