import { toastVariant } from 'components/ToastProvider';

export const getToastConfig = (t: (x: string) => string) => {
  return {
    note: {
      success: {
        message: t('This note has been saved.'),
        variant: toastVariant.SUCCESS,
      },
      error: {
        message: t('This note could not be saved.'),
        variant: toastVariant.FAILURE,
      },
    },

    text: {
      success: {
        message: t('This text message has been sent.'),
        variant: toastVariant.SUCCESS,
      },
      error: {
        message: t('This text message could not be sent.'),
        variant: toastVariant.FAILURE,
      },
    },

    email: {
      success: {
        message: t('Email sent.'),
        variant: toastVariant.SUCCESS,
      },
      error: {
        message: t('Email could not be sent.'),
        variant: toastVariant.FAILURE,
      },
    },
  };
};
