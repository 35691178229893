import { useCallback, useState } from 'react';
import { getOriginalError } from 'services/AxiosService';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useModalControl } from 'hooks/useModalControl';
import { useTranslation } from 'react-i18next';

export const useSharingAccessModal = (onSubmit) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState(EMPTY_OBJECT);
  const [isOpen, { showModal, hideModal }] = useModalControl();
  const [submitting, setSubmitting] = useState(false);

  const handleHide = useCallback(() => {
    hideModal();
    setErrors(EMPTY_OBJECT);
  }, [hideModal]);

  const onConfirm = useCallback(
    async (...args) => {
      setSubmitting(true);
      try {
        setErrors(EMPTY_OBJECT);
        await onSubmit(...args);
        hideModal();
      } catch (err) {
        let errors = getOriginalError(err) || EMPTY_OBJECT;
        if (Array.isArray(errors) && typeof errors[0] === 'string') {
          errors = { toast: errors[0] };
        } else if (errors.name) {
          errors = { name: errors.name, toast: t('Filter Group Not Saved') };
        }
        setErrors(errors);
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, hideModal, t]
  );

  return {
    sharingAccessModalProps: {
      show: isOpen,
      onHide: handleHide,
      onConfirm,
      showModal,
      errors,
      disabled: submitting,
    },
    onShowSharingAccessModal: showModal,
  };
};
