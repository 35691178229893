import { Editor } from '@craftjs/core';
import { Frame } from './Frame';
import nodes from './homepage-nodes';
import { ViewerContext } from './ViewerContext';

type HomepageBlockProps = {
  data: any;
  containerWidth: number;
  applyMobileBreakpoints: boolean;
  onLogActivityButtonClick: (activityId: string) => void;
  textTransform: (text: string) => string;
  setContentHeight: (height: number) => void;
};

export const HomepageBlock = ({
  data,
  containerWidth,
  applyMobileBreakpoints,
  onLogActivityButtonClick,
  textTransform,
  setContentHeight,
}: HomepageBlockProps) => {
  const ctx = {
    fullWidth: true,
    fullHeight: true,
    applyMobileBreakpoints,
    rootNode: data.ROOT,
    rootWidth: containerWidth,
    onLogActivityButtonClick,
    textTransform,
    setRootWidth: () => {},
    setContentHeight,
  };

  return (
    <Editor enabled={false} resolver={nodes}>
      <ViewerContext.Provider value={ctx as any}>
        <Frame data={data} />
      </ViewerContext.Provider>
    </Editor>
  );
};
