import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDebounce } from 'react-use';
import { FORCE_ALL_RECORDS_SIZE, objectNameToOption } from 'services/helpers';

const getFetchParams = (paramFormatter, search, useClient) => {
  if (paramFormatter) {
    const result = paramFormatter(search);
    if (Array.isArray(result)) {
      return result;
    }
    return [result];
  }

  if (useClient) {
    return [{ size: FORCE_ALL_RECORDS_SIZE }];
  }

  return [{ search }];
};

const useReactQueryTypeahead = (
  fetch,
  queryKey = [],
  config = {},
  dependencies = [],
  debounceDelay = 0
) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { t } = useTranslation();

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    debounceDelay,
    [search]
  );

  const {
    optionMapper = objectNameToOption,
    paramFormatter = null,
    useClient = false,
    addOptions = [],
    enabled = true,
    allowEmpty = true,
    preserveSearch = false,
    onResultsChange,
    keepPreviousData = false,
  } = config;

  const { data, isLoading, isRefetching, isFetching } = useQuery(
    [
      ...queryKey,
      ...dependencies,
      useClient ? undefined : debouncedSearch,
    ].filter(Boolean),
    () => fetch(...getFetchParams(paramFormatter, debouncedSearch, useClient)),
    {
      enabled: (allowEmpty ? true : Boolean(debouncedSearch)) && enabled,
      keepPreviousData,
    }
  );

  const options = useMemo(() => {
    const results = data?.results || [];

    if (useClient) {
      return results
        .map(optionMapper)
        .concat(addOptions)
        .sort((a, b) => a.label.localeCompare(b.label));
    }

    return results.map(optionMapper);
  }, [data, optionMapper, addOptions, useClient]);

  useEffect(() => {
    onResultsChange?.();
  }, [options, onResultsChange]);

  const loading = isLoading || isRefetching || isFetching;

  return {
    selectProps: {
      onInputChange: (v, { action }) => {
        // We don't want to clear the search when the user selects a value
        if (action === 'set-value' && preserveSearch) {
          return;
        }
        setSearch(v);
        onResultsChange?.();
      },
      noOptionsMessage: () => {
        if (!search && !allowEmpty) {
          return t('Begin Typing to Search');
        }
        return t('No Options');
      },
      inputValue: search,
      options,
      isLoading: loading,
    },
    results: data?.results ?? [],
    isLoading: loading,
  };
};

export default useReactQueryTypeahead;
