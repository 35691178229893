import React from 'react';
import UnderlineTextInput from './Underline';
import OutlineTextInput from './Outline';
import { variantProp } from '../props';

const TextInput = React.forwardRef(({ variant, ...props }, ref) => {
  const VariantTextInput =
    variant === 'underline' ? UnderlineTextInput : OutlineTextInput;
  return <VariantTextInput ref={ref} {...props} />;
});

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
  ...UnderlineTextInput.propTypes,
  ...OutlineTextInput.propTypes,
  variant: variantProp,
};

TextInput.defaultProps = {
  ...UnderlineTextInput.defaultProps,
  ...OutlineTextInput.defaultProps,
  variant: 'outline',
};

export default TextInput;
