import { useCallback, useMemo, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryOptions, DraggableItemType } from '../Builder/types';
import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';
import { ResizableSection } from '__components/Wizards/CustomObject/steps/CustomLayout/dialogs/common';
import MultiSelect from '__components/Inputs/MultiSelect';
import { SwitchNoMargin } from './commonStyles';

type FieldSettingsMetadata = {
  chosenCategories: CategoryOptions;
  autoInclude: boolean;
  excluded: string[];
  included: string[];
};

interface FieldSettingsWizardProps {
  index?: number;
  metadata: FieldSettingsMetadata;
  setMetadata: (metadata: any, setDirty?: boolean) => void;
  isActive?: boolean;
  allCategoryOptions: CategoryOptions;
}

export const defaultFieldMetadata = {
  chosenCategories: [],
  autoInclude: true,
  excluded: [],
  included: [],
};

export const namedToOption = ({ id, name }: { id: string; name: string }) => ({
  value: id,
  label: name,
});

export const setExcludedIncluded = (
  allCategoryOptions: CategoryOptions,
  chosenCategories: CategoryOptions,
  autoInclude: boolean
) => {
  if (chosenCategories.length === 0) {
    return {
      excluded: [],
      included: [],
    };
  }
  const chosenValues = chosenCategories.map(({ value }) => value);

  const included = autoInclude ? [] : chosenValues;

  const excluded = autoInclude
    ? allCategoryOptions
        .filter(({ value }) => !chosenValues.includes(value))
        .map(({ value }) => value)
    : [];

  return {
    excluded,
    included,
  };
};

export const useIntialiseFieldMeta = (
  metadata: FieldSettingsMetadata,
  allCategoryOptions: CategoryOptions,
  blockType: DraggableItemType,
  existing: boolean
) => {
  const intialSetup = useRef(false);

  return useMemo(() => {
    if (blockType !== 'fields') {
      intialSetup.current = false;
    } else if (!intialSetup.current) {
      intialSetup.current = true; // this is a one time thing

      const categoryValues = allCategoryOptions.map(({ value }) => value);

      let { chosenCategories, excluded, included, autoInclude } =
        metadata ?? defaultFieldMetadata;

      if (existing) {
        if (autoInclude) {
          if (chosenCategories.length > 0) {
            // filter out deleted categories from excluded
            excluded = metadata.excluded.filter((value) =>
              categoryValues.includes(value)
            );
            // chosen categories is all categories minus excluded categories - we pull in name changes
            chosenCategories = allCategoryOptions.filter(
              ({ value }) => !excluded.includes(value)
            );
          } else {
            excluded = [];
          }
        } else {
          // filter out deleted categories
          included = metadata.included.filter((value) =>
            categoryValues.includes(value)
          );
          // all categories that are included - incase the name has changed
          chosenCategories = allCategoryOptions.filter(({ value }) =>
            included.includes(value)
          );
        }
      }

      return {
        ...metadata,
        chosenCategories,
        autoInclude,
        excluded,
        included,
      };
    }

    return metadata;
  }, [blockType, metadata, allCategoryOptions, existing]);
};

export const FieldSettingsWizard = (props: FieldSettingsWizardProps) => {
  const { metadata, setMetadata, isActive, allCategoryOptions } = props;
  const { t } = useTranslation();

  const showAutoinclude = metadata?.chosenCategories?.length > 0;

  const handleCategoryChange = useCallback(
    (opts: CategoryOptions): void => {
      setMetadata({
        ...metadata,
        chosenCategories: opts,
      });
    },
    [setMetadata, metadata]
  );
  const handleCategoryAutoinclude = useCallback(
    (evt: ChangeEvent<HTMLInputElement>): void => {
      setMetadata({
        ...metadata,
        autoInclude: evt.target.checked,
      });
    },
    [setMetadata, metadata]
  );

  return (
    <ResizableSection header={t('Choose Categories to Display')} {...props}>
      {isActive ? (
        <div className="kds grid grid-cols-2 gap-spacer-20 text-font-primary">
          <div>
            <Typography variant="label">
              {t('Categories to Display (Leave Blank for All)')}
            </Typography>
            <Spacer mode="horizontal" size={15} />
            <MultiSelect
              placeholder={t('Find Categories')}
              menuInline
              options={allCategoryOptions}
              value={metadata.chosenCategories || []}
              onChange={handleCategoryChange}
            />
          </div>
          {showAutoinclude ? (
            <>
              <Spacer mode="horizontal" size={15} />
              <div>
                <div className="kds flex items-center">
                  <Typography variant="label">
                    {t('Automatically Add New Categories to this Block')}
                  </Typography>
                </div>
                <Spacer mode="horizontal" size={15} />
                <div>
                  <SwitchNoMargin
                    checked={metadata.autoInclude}
                    onChange={handleCategoryAutoinclude}
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </ResizableSection>
  );
};
