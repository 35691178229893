import React from 'react';

export default function ActivityEntityIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 16.9342V2.57143C24 1.15125 22.8488 0 21.4286 0H2.57143C1.15125 0 0 1.15125 0 2.57143V21.4279C0 22.8481 1.15125 23.9994 2.57143 23.9994H16.9349C17.6169 23.9994 18.2709 23.7284 18.7532 23.2462L23.2468 18.7525C23.7291 18.2703 24 17.6162 24 16.9342ZM17.5409 22.034C17.4312 22.1438 17.2936 22.2216 17.1429 22.2588V17.1422H22.2594C22.2222 17.2929 22.1445 17.4306 22.0347 17.5403L17.5409 22.034ZM22.2857 2.57143V15.4279H16.7143C16.0042 15.4279 15.4286 16.0036 15.4286 16.7136V22.2851H2.57143C2.09802 22.2851 1.71429 21.9013 1.71429 21.4279V2.57143C1.71429 2.09802 2.09802 1.71429 2.57143 1.71429H21.4286C21.902 1.71429 22.2857 2.09802 22.2857 2.57143Z"
        fill="currentColor"
      />
    </svg>
  );
}
