import { pSBC } from '../Colors/convert';
import { Icon } from '../Icon/Icon';
import { ValidIcons } from '../Icon/library';
import { Spacer } from '../Spacer/Spacer';
import { Typography } from '../Typography/Typography';
import { isDebug } from '../debug';
import { getKDSClasses, merge } from '../util';
import { ColorType } from '../Colors/types';
import { Ref, forwardRef } from 'react';
import { useTooltip } from '../Tooltip/useTooltip';
import { Tooltip } from '../Tooltip/Tooltip';

export interface TabProps {
  children?: any;
  icon?: ValidIcons;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  color?: ColorType;
  id?: string;
  truncate?: boolean;
}

const getButtonClasses = (
  active: boolean,
  disabled: boolean,
  color?: ColorType
) => {
  if (disabled) {
    if (active) {
      return 'bg-button-disabled-default border-button-disabled-default text-font-white';
    } else {
      return 'bg-transparent border-transparent text-button-disabled-default';
    }
  }

  if (active) {
    const colorClasses =
      'text-button-secondary-default border-button-secondary-default';
    const customColorClasses = 'text-[var(--kizen-custom-color-default)]';

    const baseClasses = 'bg-none';

    if (color) {
      return merge(baseClasses, customColorClasses);
    }

    return merge(baseClasses, colorClasses);
  }

  const colorClasses =
    'hover:text-button-secondary-default active:text-button-secondary-pressed';
  const customColorClasses =
    'hover:text-[var(--kizen-custom-color-hover)] active:text-[var(--kizen-custom-color-pressed)]';
  const baseClasses = 'bg-none border-transparent ';

  if (color) {
    return merge(baseClasses, customColorClasses);
  }

  return merge(baseClasses, colorClasses);
};

export const Tab = forwardRef(
  (props: TabProps, forwardedRef: Ref<HTMLDivElement>) => {
    const {
      children,
      icon,
      active = false,
      disabled = false,
      onClick,
      color,
      id,
      truncate,
    } = props;
    const { debugClassName } = isDebug();

    const { triggerProps, targetProps, showTooltip, tooltipProps } =
      useTooltip();

    return (
      <div
        className={merge(getKDSClasses('tab'), 'relative h-[36px]')}
        style={
          {
            '--kizen-custom-color-default': color,
            '--kizen-custom-color-hover': pSBC(-0.3, color),
            '--kizen-custom-color-pressed': pSBC(-0.5, color),
          } as any
        }
        ref={forwardedRef}
        data-tab-id={id}
      >
        <button
          className={merge(
            'flex gap-x-8 items-center cursor-pointer rounded-full border-1 whitespace-nowrap h-full',
            getButtonClasses(active, disabled, color),
            active ? 'active' : '',
            debugClassName
          )}
          onClick={onClick}
          {...triggerProps}
        >
          <Spacer size={5} mode="vertical" />
          {icon ? <Icon icon={icon} /> : null}
          <Typography
            truncate={truncate}
            className={truncate ? 'max-w-[90px]' : ''}
            enableTooltip={false} // The tooltip is handled externally in this case
            {...targetProps}
          >
            {children}
          </Typography>
          {showTooltip ? (
            <Tooltip position="top" text={children} {...tooltipProps} />
          ) : null}
          <Spacer size={5} mode="vertical" />
        </button>
      </div>
    );
  }
);
