import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale, colorsButton, colorsText } from 'app/colors';
import { gutters, scrollbarCss } from 'app/spacing';
import { TextSpan, fontWeights } from 'app/typography';

export const StyledLabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled(TextSpan)`
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

export const StyledTextareaWrapper = styled.span`
  display: flex;
  align-items: stretch;
  border: 1px solid ${grayScale.medium};
  border-radius: 4px;

  ${(props) =>
    props.error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}

  ${(props) =>
    props.focused &&
    css`
      border-color: ${colorsButton.blue.hover};
    `}

    ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border-color: ${colorsButton.blue.hover};
      }
    `}

  ${(props) =>
    props.underline &&
    css`
      border: 0;
      border-radius: 0;
      background-image: url(/images/inputUnderline.svg);
      background-position: left bottom;
      background-repeat: repeat-x;
      padding-bottom: 2px;

      ${props.error &&
      css`
        background-image: url(/images/inputUnderline--error.svg);
      `}

      ${!props.disabled &&
      css`
        &:hover {
          background-image: url(/images/inputUnderline--hover.svg);
        }
      `}

      ${props.focused &&
      css`
        background-image: url(/images/inputUnderline--solid.svg);

        &:hover {
          background-image: url(/images/inputUnderline--solid.svg);
        }
      `}
    `}
`;

export const StyledTextarea = styled.textarea`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.00125em;
  color: ${colorsText.dark};
  padding: 1rem;
  flex-grow: 1;
  outline: none;
  border: none;
  border-radius: 4px;
  resize: ${(props) => props.resize};

  ${(props) =>
    props.disabled &&
    css`
      background: ${grayScale.light};
    `}
  ${scrollbarCss()}
  &::placeholder {
    color: ${grayScale.mediumDark};

    ${(props) =>
      props.disabled &&
      css`
        color: ${grayScale.medium};
      `}
  }
`;

// Underlined variant

export const truncateStyles = () => css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const shrinkStyles = () => css`
  // scaling to 78.5% will take the 14px down to the 11px we want
  // The 4px will put the label 10px above the capline of the input text
  transform: translate(0, 4px) scale(0.785);
  // Account for the scaling when computing the width of the element
  max-width: calc(100% / 0.785);
  font-weight: ${fontWeights.bold};
  // Undoes the pointer-events: none we set to allow the input to be focused through the label
  // this is so hover actions for truncationTooltip will work
  pointer-events: auto;
`;

export const StyledUnderlinedLabelWrapper = styled(StyledLabelWrapper)`
  position: relative;
  // We want a height of 100px from the bottom border to the shrunk label capline
  height: 105px;
`;

export const StyledUnderlinedLabel = styled(TextSpan)`
  position: absolute;
  top: 0;
  left: 0;
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.regular};
  // Allow the user to focus/hover the input with the label in the way
  pointer-events: none;
  transform: translate(0, 24px) scale(1);
  transition:
    font-weight,
    transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transform-origin: top left;
  max-width: 100%;
  ${truncateStyles}
  ${({ shrink }) => shrink && shrinkStyles};
`;

export const StyledUnderlinedTextareaWrapper = styled.span`
  display: flex;
  align-items: stretch;

  margin-top: ${gutters.standard};

  height: 100%;
  border-bottom: 1px solid ${grayScale.medium};

  ${(props) =>
    props.error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}

  ${(props) =>
    props.focused &&
    css`
      border-color: ${colorsButton.blue.hover};
    `}

    ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border-color: ${colorsButton.blue.hover};
      }
    `}
`;

export const StyledUnderlinedTextarea = styled(StyledTextarea)`
  line-height: 1.1;
  // equivalent-ish to 10px padding between capline/baseline and baseline/underline
  padding: 7px 0 0;

  &::placeholder {
    opacity: 0;
    transition: opacity 10ms;
  }
  &:focus {
    &::placeholder {
      opacity: 1;
    }
  }
`;
