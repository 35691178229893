import { useCallback, useRef } from 'react';
import { useAutomationsSelector } from 'pages/AutomationEngine/store/react';
import {
  getAutomation,
  getCurrenEditingStep,
} from 'pages/AutomationEngine/store/selectors';
import LibraryService, { templateForApi } from 'services/LibraryService';

export const useChangeAutomationsMessages = (values, onChange) => {
  const messageRef = useRef(null);
  const automation = useAutomationsSelector(getAutomation);
  const step = useAutomationsSelector(getCurrenEditingStep);

  const onChangeEmail = useCallback(
    async (messageData) => {
      let newMessage = messageData;
      if (messageData && !messageData.id) {
        const stepId = !step?.id || step.id.startsWith('new.') ? null : step.id;

        let payload = {
          ...messageData,
          automationStep: stepId,
          baseMessageId: messageRef.current?.baseMessageId,
        };

        if (messageRef.current?.automationStep === stepId) {
          payload = {
            ...messageData,
            automationStep: stepId,
            id: messageRef.current?.id,
            baseMessageId: null,
          };
        }

        const data = await LibraryService.createAutomationMessage(
          automation.id,
          templateForApi(payload, true)
        );

        newMessage = {
          ...messageData,
          id: data.id,
          baseMessageId: data.baseMessageId,
        };
      }
      messageRef.current = messageData;
      onChange({
        ...values,
        email: newMessage,
      });
    },
    [onChange, automation.id, step?.id, values]
  );

  return onChangeEmail;
};
