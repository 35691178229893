import { useContext } from 'react';
import { FlowBuilderContext } from '../context';
import { LayouterFlowCore } from '__pages/AutomationEngine/Engine/Flow/Layouter';
import { ReactFlowProvider } from 'reactflow';

export const Layouter = (props: any) => {
  const { showStats, lastPlacingStep } = useContext(FlowBuilderContext);
  return (
    <ReactFlowProvider>
      <LayouterFlowCore
        {...props}
        showStats={showStats}
        lastPlacingStep={lastPlacingStep}
      />
    </ReactFlowProvider>
  );
};
