import { Typography } from '@kizen/kds/Typography';
import { Tooltip, useTooltip } from '@kizen/kds/Tooltip';

export interface BlockProps {
  count: number;
  label: string;
  tooltipLabel?: string;
  metadata?: any;
  expandOnLargeOnly?: boolean;
  defaultGap?: 15 | 30;
  onClickLabel?: () => void;
  id: string;
}

export const Block = (props: BlockProps) => {
  const {
    count,
    label,
    tooltipLabel,
    metadata,
    expandOnLargeOnly = false,
    defaultGap = 15,
    onClickLabel,
    id,
  } = props;

  const { triggerProps, tooltipProps, targetProps, showTooltip } =
    useTooltip(true);

  return (
    <div
      className="child-block flex grow justify-center px-spacer-15 first:pl-0 last:pr-0 items-center min-w-0 max-w-[50%]"
      data-qa={`entity-info-block-${id}`}
    >
      <div className="flex flex-col items-center gap-y-15 min-w-[80px] shrink-0">
        <Typography variant="header" size="xl" className="block-count">
          {count}
        </Typography>
        <span {...triggerProps} {...targetProps} data-qa="label">
          <Typography
            variant="link"
            onClick={onClickLabel}
            className="block-label"
          >
            {label}
          </Typography>
          {showTooltip && tooltipLabel ? (
            <Tooltip
              position="top"
              text={tooltipLabel}
              modalCompatability
              {...tooltipProps}
            />
          ) : null}
        </span>
      </div>
      {metadata ? (
        <div
          className={`hidden min-w-0 @lg-tile/Tile:pl-spacer-30 ${
            expandOnLargeOnly ? '@xl-tile/Tile:flex' : '@md-tile/Tile:flex'
          } ${defaultGap === 15 ? 'pl-spacer-15' : 'pl-spacer-30'} `}
          data-qa="metadata"
        >
          {metadata}
        </div>
      ) : null}
    </div>
  );
};
