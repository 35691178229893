import {
  getOptionValue,
  getOptionNameWithEmail,
  namedToOptionDeleted,
} from 'services/helpers';

import {
  checkDeleted,
  checkListDeleted,
  displayNameDescriptor,
  labelDescriptor,
  notifyMemberViaEmailTypes,
} from 'pages/AutomationEngine/helpers';

import { templateForApp } from 'services/LibraryService';
import { displayNameToOptionDeleted } from 'services/helpers';

import {
  AddErrorMessage,
  MessageDictionary,
} from 'pages/AutomationEngine/types/automation';
import { FromApiStep, ForApiProps, ForFlowTeamMemberReturn } from './types';

import {
  EmailSendOptionsTeamMemberReturned,
  EmailSendOptionsTeamMember,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/CommonComponents/email-send-options/types';

export const forApiTeamMember = (teamMember: EmailSendOptionsTeamMember) => {
  const { employees, role, field, type } = teamMember;

  // we pass null for none
  return type === notifyMemberViaEmailTypes.NONE
    ? null
    : {
        type,
        ...(role && { roleId: getOptionValue(role) }),
        ...(employees && {
          employeeIds: employees.map((employee: any) =>
            getOptionValue(employee)
          ),
        }),
        ...(field && { fieldId: getOptionValue(field) }),
      };
};

const forApi = (step: ForApiProps) => {
  const orgDetails = {
    ...{ email: { id: null } },
    ...step.details,
  };

  const {
    email: { id },
    teamMember,
    ccTeamMember,
  } = {
    ...orgDetails,
  };

  const apiTeamMember = forApiTeamMember(teamMember);
  const apiCcTeamMember = ccTeamMember ? forApiTeamMember(ccTeamMember) : null;

  const api = {
    details: {
      id,
      teamMember: apiTeamMember,
      ccTeamMember: apiCcTeamMember,
    },
  };

  return api;
};

export const forFlowTeamMember = (
  teamMember: EmailSendOptionsTeamMemberReturned,
  addErrorMessage: AddErrorMessage,
  messageDictionary: MessageDictionary
): ForFlowTeamMemberReturn => {
  // the api doesn't except none as an option so set it if the teammember is null
  if (!teamMember) {
    return { type: notifyMemberViaEmailTypes.NONE };
  }

  const { employees, role, type, field } = teamMember;

  switch (type) {
    case notifyMemberViaEmailTypes.LAST_ACTIVE_ROLE:
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        role,
        labelDescriptor,
        messageDictionary.associatedRole
      );
      break;
    case notifyMemberViaEmailTypes.SPECIFIC_TEAM_MEMBERS:
      checkListDeleted(
        messageDictionary,
        addErrorMessage,
        employees,
        displayNameDescriptor
      );
      break;
    case notifyMemberViaEmailTypes.TEAM_SELECTOR_FIELD:
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        field,
        displayNameDescriptor
      );
      break;
    default:
  }

  return {
    type,
    // employees,
    ...(role && {
      role: namedToOptionDeleted(role),
    }),
    ...(field && {
      field: displayNameToOptionDeleted(field),
    }),
    ...(employees
      ? {
          employees: employees
            .filter((employee: any) => !employee?.deleted)
            .map((employee: any) => ({
              label: getOptionNameWithEmail({
                ...employee,
                fullName: `${employee?.firstName} ${employee?.lastName}`,
              }),
              value: employee.id,
            })),
        }
      : null),
  };
};

const forFlow = (
  apiObject: FromApiStep,
  addErrorMessage: AddErrorMessage,
  messageDictionary: MessageDictionary
) => {
  const teamMember = forFlowTeamMember(
    apiObject.teamMember,
    addErrorMessage,
    messageDictionary
  );
  const ccTeamMember = forFlowTeamMember(
    apiObject.ccTeamMember,
    addErrorMessage,
    messageDictionary
  );

  const flow = {
    email: templateForApp(
      apiObject,
      true // kzn-6161 we ned to maintain the baseMessageId to avoid duplicate email creation
    ),
    teamMember,
    ccTeamMember,
  };

  return flow;
};
export const notifyMemberViaEmailDTO = {
  forApi,
  forFlow,
};
