import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from 'app/colors';
import { layers } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { CardBasic } from 'components/Card/Card';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';

const ListCard = styled(CardBasic)`
  margin-bottom: 15px;
`;

// Needed to assign ListCard for react-docgen / storybook

export default ListCard;

const BaseItemLayout = styled.div`
  display: flex;
`;

const BaseItemAction = styled.div`
  padding: 0 15px;
`;

const BaseItemContent = styled.div`
  display: flex;
  flex: 1;
  min-width: 0;
  flex-direction: column;
  justify-content: center;
`;

export const ListCardRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ListCardCell = styled.div`
  display: inline-flex;
  align-items: center;

  // We have 15px space between 2 cells,
  // this calc allows us to reach equal width both of them
  width: calc(50% - 7.5px);
  &:not(:last-child) {
    padding-right: 15px;
    width: calc(50% + 7.5px);
  }
  &:only-child {
    width: 100%;
  }
`;

const HeaderLayout = styled(BaseItemLayout)`
  height: ${({ compactHeader }) => (compactHeader ? '42px' : '50px')};
  padding-right: ${({ withScrollbarPadding }) =>
    // reduce the right padding to take into account 4 pixels for the scrollbar
    withScrollbarPadding ? `6px` : `10px`};
  border-bottom: 1px solid ${grayScale.mediumLight};
  ${({ stickyHeader }) =>
    stickyHeader &&
    css`
      position: sticky;
      top: 0;
      z-index: ${layers.content(0, 1)}; // Appear above cell contents
      background-color: ${grayScale.white};
      border-bottom: 1px solid ${grayScale.mediumLight};
    `}
`;

export const HeaderAction = styled(BaseItemAction)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export function ListCardHeader({ action = null, children, ...others }) {
  return (
    <HeaderLayout {...others}>
      {action && <HeaderAction>{action}</HeaderAction>}
      <BaseItemContent>
        <ListCardRow>{children}</ListCardRow>
      </BaseItemContent>
    </HeaderLayout>
  );
}

const ItemLayout = styled(BaseItemLayout)`
  height: 64px;
  padding-right: ${({ withScrollbarPadding }) =>
    // reduce the right padding to take into account 4 pixels for the scrollbar
    withScrollbarPadding ? `6px` : `10px`};
`;

const ItemAction = styled(BaseItemAction)`
  padding-top: 12px;
`;

export function ListCardItem({
  action = null,
  actionStyles = null,
  children,
  ...others
}) {
  return (
    <ItemLayout {...others}>
      <ItemAction style={actionStyles}>{action}</ItemAction>
      <BaseItemContent>{children}</BaseItemContent>
    </ItemLayout>
  );
}

export const ListCardSortButton = styled(
  ({ sizing = 'dense', numeric, sort, onClick, onChangeSort, ...props }) => (
    <IconButton
      sizing={sizing}
      color={sort ? colorsButton.blue : colorsButton.iconGray}
      onClick={(ev) => {
        if (onChangeSort) {
          const nextDirection =
            !sort || sort.direction === 'desc' ? 'asc' : 'desc';
          onChangeSort(nextDirection, ev);
        }
        if (onClick) onClick(ev);
      }}
      {...props}
    >
      {!sort && <Icon icon="no-sort" />}
      {sort && sort.direction === 'asc' && !numeric && (
        <Icon icon="sort-alpha-asc" />
      )}
      {sort && sort.direction === 'desc' && !numeric && (
        <Icon icon="sort-alpha-desc" />
      )}
      {sort && sort.direction === 'asc' && numeric && (
        <Icon icon="sort-numeric-asc" />
      )}
      {sort && sort.direction === 'desc' && numeric && (
        <Icon icon="sort-numeric-desc" />
      )}
    </IconButton>
  )
)`
  margin-left: 5px;
  && svg {
    height: 12px;
    width: auto;
  }
`;

export const ActionButtonMenu = styled(IconButtonMenu)`
  && .IconButtonMenu__menu {
    ${({ above }) =>
      !above &&
      css`
        transform: translate(0%, -10px);
      `}
    ${({ above }) =>
      above &&
      css`
        transform: translate(0%, -100%) translateY(-15px);
      `}
  }

  ${({ overlay }) =>
    overlay &&
    css`
      position: absolute;
    `}
`;
