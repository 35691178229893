import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';

import { initialStore } from '../initialStore';

export const setPageConfigSuccess = createAction(
  'recordDetailPage/SET_PAGE_CONFIG_SUCCESS'
);
export const setPageConfigFail = createAction(
  'recordDetailPage/SET_PAGE_CONFIG_FAIL'
);

const defaultState = initialStore.recordDetailPage;

export const recordDetailPageSlice = createSlice({
  name: 'recordDetailPage',
  initialState: defaultState,
  reducers: {
    buildPage: (state) => {
      state.isFetching = true;
    },
    buildPageComplete: (state, { payload }) => {
      state.customObjectsConfig = payload.customObjectsConfig;
      state.isFetching = false;
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    updatePageConfig: (state, { payload }) => {
      const { blockId } = payload;

      if (
        (blockId && payload?.detailPageConfig?.[blockId]) ||
        payload?.detailPageConfig?.selectedLayout
      ) {
        const isExist = state.customObjectsConfig.find(
          ({ customObjectId }) => customObjectId === payload.customObjectId
        );
        if (isExist) {
          isExist.detailPageConfig = payload.detailPageConfig;
        } else {
          state.customObjectsConfig = [...state.customObjectsConfig, payload];
        }
      }
    },
  },
});

export const { buildPage, buildPageComplete, buildPageFail, updatePageConfig } =
  recordDetailPageSlice.actions;

export const { reducer } = recordDetailPageSlice;

export const getCustomObjectConfigSelector = createSelector(
  ({ recordDetailPage }, customObjectId) => ({
    customObjectsConfig: recordDetailPage.customObjectsConfig,
    customObjectId,
  }),
  ({ customObjectsConfig, customObjectId }) =>
    customObjectsConfig.find(
      (config) => config.customObjectId === customObjectId
    ) || {}
);

export const getCustomObjectConfig = (customObjectId) => (store) =>
  getCustomObjectConfigSelector(store, customObjectId);

export const getCustomObjectConfigFetching = ({ recordDetailPage }) =>
  recordDetailPage.isFetching;
