import { colorsPrimary, colorsSecondary, grayScale } from 'app/colors';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

export const CALENDAR_EVENTS_OPTIONS = {
  upcoming: 'upcoming',
  past: 'past',
};

export const EVENT_ACTIONS = {
  stats: 'stats',
  edit: 'edit',
  duplicate: 'duplicate',
  delete: 'delete',
};

export const EVENT_STATUSES = {
  failed: 'failed',
  completed: 'completed',
  scheduled: 'scheduled',
  prestarted: 'prestarted',
};

export const EVENT_TYPES = {
  email: {
    type: 'email',
    getTitle: (t, event) =>
      (event?.status === EVENT_STATUSES.failed ? `(${t('Failed')}) ` : '') +
      (event?.name || t('Email')),
    getContent: (t, action) => action?.subject || t('Subject'),
    getTooltip: (t) => `${t('Show/Hide')} ${'Emails'}`,
    icon: 'envelope',
    color: colorsPrimary.blue.dark,
    accessKey: 'scheduled_emails',
  },
  text: {
    type: 'text',
    getTitle: (t, event) => event?.name || t('Text'),
    getContent: (t, action) => action?.subject || t('Subject'),
    getTooltip: (t) => `${t('Show/Hide')} ${t('Texts')}`,
    icon: 'mobile',
    color: colorsPrimary.blue.dark,
    preRelease: true,
  },
  automation: {
    type: 'automation',
    getTitle: (t, event) =>
      (event?.status === EVENT_STATUSES.failed ? `(${t('Failed')}) ` : '') +
      (event?.name || t('Automation')),
    getContent: (t, action) =>
      action?.automations
        .map(
          ({ name, deleted, active }) =>
            `${
              deleted
                ? `[${t('DELETED')}] `
                : active === false
                  ? `[${t('INACTIVE')}] `
                  : ''
            }${name}`
        )
        .join(', '),
    getTooltip: (t) => `${t('Show/Hide')} ${t('Automations')}`,
    icon: 'sync-light',
    color: colorsPrimary.purple.dark,
    accessKey: 'scheduled_automations',
  },
  facebook: {
    type: 'facebook',
    getTitle: (t, event) => event?.name || t('Facebook Post'),
    getContent: (t, action) => action?.subject || t('Subject'),
    getTooltip: (t) => `${t('Show/Hide')} ${t('Facebook Posts')}`,
    icon: 'facebook',
    color: colorsSecondary.aqua.dark,
    preRelease: true,
  },
  twitter: {
    type: 'twitter',
    getTitle: (t, event) => event?.name || t('Twitter Post'),
    getContent: (t, action) => action?.subject || t('Subject'),
    getTooltip: (t) => `${t('Show/Hide')} ${t('Twitter Posts')}`,
    icon: 'twitter',
    color: colorsSecondary.aqua.dark,
    preRelease: true,
  },
  default: {
    type: 'default',
    getTitle: (t) => t('Unknown Event'),
    getContent: (t, action) => action?.subject || t('Subject'),
    getTooltip: (t) => t('Show/Hide'),
    icon: 'bars-light',
    color: grayScale.mediumDark,
  },
};

export const SUMMARY_ACTIONS = {
  [EVENT_TYPES.email.type]: 'bulk_send_email',
  [EVENT_TYPES.automation.type]: 'start_automation',
};

export const EVENTS_PER_PAGE = 20;

export const EVENTS_SEARCH_DATA = {
  previous: null,
  next: null,
  results: EMPTY_ARRAY,
  count: 0,
  errors: null,
};
