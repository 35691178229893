import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters, layers } from 'app/spacing';
import { grayScale, colorsButton, colorsText } from 'app/colors';
import { Text, TextSpan } from 'app/typography';

export const StyledTextLabel = styled(Text)`
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

export const StyledLabel = styled.label`
  position: relative;
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: ${layers.content(0)};
`;

export const StyledInputWrapper = styled.fieldset`
  margin: 0 0 ${gutters.standard} 0;
`;

export const StyledText = styled(TextSpan)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${gutters.standard};
  background: ${grayScale.white};
  border: 1px solid ${grayScale.medium};
  border-radius: 4px;
  color: ${colorsText.medium};
  width: 80px;
  height: 42px;
  cursor: pointer;
  user-select: none;

  :hover {
    border-color: ${colorsButton.blue.hover};
  }

  ${StyledInput}:checked ~ & {
    border-color: ${colorsButton.blue.hover};
    color: ${colorsButton.blue.hover};
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}
`;
