import { useMemo } from 'react';
import { Calendar } from 'components/Calendar';
import { BusinessCalendarContext } from './CalendarContext';
import { buildMonthCell } from './buildMonthCell';
import { CalendarLoader } from './CalendarLoader';

export const BusinessCalendarComponent = ({
  date,
  view,
  loading,
  skipLookup,
  skipWeekdays,
  holidaysLookup,
}) => {
  const context = useMemo(() => {
    return {
      holidaysLookup,
      skipLookup,
      skipWeekdays,
    };
  }, [holidaysLookup, skipLookup, skipWeekdays]);

  const [MonthCellComponent, WeekCellComponent, DayCellComponent] =
    useMemo(() => {
      // onCellClick will be implemented in future to handle skipping and unskippping of certain dates
      return [buildMonthCell({ loading, onCellClick: () => {} })];
    }, [loading]);

  return (
    <BusinessCalendarContext.Provider value={context}>
      <CalendarLoader loading={loading} />
      <Calendar
        date={date}
        view={view}
        expandable
        MonthCellComponent={MonthCellComponent}
        WeekCellComponent={WeekCellComponent}
        DayCellComponent={DayCellComponent}
        loading={loading}
      />
    </BusinessCalendarContext.Provider>
  );
};
