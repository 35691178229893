import type { FilterConfig } from '@kizen/filters/load';
import type { Filter } from '@kizen/filters/filter';
import type { FilterVars } from '@kizen/filters/convert';
import type { FilterSet } from '@kizen/filters/filter-sets';
import { PageBuilderImage } from '@kizen/page-builder';
import produce from 'immer';
import { Dispatch } from 'react';
import { v4 } from 'uuid';
import {
  MISSING_URL_PROTOCOL_MESSAGE,
  MISSING_URL_TLD_MESSAGE,
} from 'constants/errorMessages';
import { loadSavedFilter, needsConversion } from 'ts-filters/utils';
import { checkUrl } from 'utility/validate';
import { TFunction } from 'react-i18next';

type Nullable<T> = T | null | undefined;

type Position = 'left' | 'center' | 'right';
type Size = 'auto' | 'dynamic' | 'fixed';
type Unit = 'pixel' | 'percent';
type Dimension = 'width' | 'height';
type ImageSettings = {
  src: Nullable<string>;
  name: Nullable<string>;
  fileId: Nullable<string>;
  position: Position;
  size: Size;
  unit: Nullable<Unit>;
  dimension: Nullable<Dimension>;
  hasLink: boolean;
  width: Nullable<number>;
  height: Nullable<number>;
  maxWidth: Nullable<number>;
  maxHeight: Nullable<number>;
  link: Nullable<string>;
  alt: Nullable<string>;
};

export type FilterType = 'in_group' | 'not_in_group' | 'custom_filter';

type FilterData = {
  filter: Filter | null;
  ops?: any;
  type?: string;
};

export type Rule = {
  id: string;
  order: number;
  filters: FilterData[];
  groups: string[];
  operation: 'and' | 'or';
  filterType: FilterType;
};

type Action = {
  type: ActionType;
  value: any;
  id?: string;
  index?: number;
  filterIndex?: number;
  filter?: {
    filter: Filter;
    ops: any;
    type: string;
  };
  groups?: string[];
  operation?: 'and' | 'or';
};

type ActionType =
  | 'add-rule'
  | 'delete-rule'
  | 'move-rule-up'
  | 'move-rule-down'
  | 'create-filter'
  | 'set-default-image'
  | 'set-default-position'
  | 'set-default-size'
  | 'set-default-unit'
  | 'set-default-dimension'
  | 'set-default-has-link'
  | 'set-default-height'
  | 'set-default-width'
  | 'set-default-max-width'
  | 'set-default-max-height'
  | 'set-default-link'
  | 'set-default-alt-text'
  | 'set-dynamic-image'
  | 'set-dynamic-position'
  | 'set-dynamic-size'
  | 'set-dynamic-unit'
  | 'set-dynamic-width'
  | 'set-dynamic-dimension'
  | 'set-dynamic-height'
  | 'set-dynamic-max-width'
  | 'set-dynamic-max-height'
  | 'set-dynamic-has-link'
  | 'set-dynamic-link'
  | 'set-dynamic-alt-text'
  | 'set-dynamic-rule-type'
  | 'set-filter-type'
  | 'set-errors'
  | 'clear-default-content-error'
  | 'clear-dynamic-content-error'
  | 'set-state'
  | 'render-filters'
  | 'add-filter'
  | 'delete-filter'
  | 'set-groups'
  | 'set-operation';

export type State = {
  default_content: ImageSettings;
  rules: (ImageSettings & Rule)[];
  errors: {
    default_content: string | false;
    rules: (string | false)[];
  };
};

export const initialState: State = {
  default_content: {
    fileId: null,
    src: null,
    name: null,
    position: 'center',
    size: 'auto',
    unit: null,
    dimension: null,
    hasLink: false,
    width: null,
    height: null,
    maxWidth: null,
    maxHeight: null,
    link: null,
    alt: null,
  },
  rules: [],
  // only url inputs have validation
  errors: {
    default_content: false,
    rules: [],
  },
};

export const opsForFilter = (filter: Filter, dispatch: Dispatch<any>) => ({
  next: (...args: Parameters<Filter['next']>) => filter.next(...args),
  set: (...args: Parameters<Filter['set']>) => filter.set(...args),
  update: () => dispatch({ type: 'render-filters' }),
});

export const dynamicContentReducer = produce(
  (
    state: State,
    { type, value, id, index, filterIndex, filter, groups, operation }: Action
  ) => {
    let idx = -1;
    switch (type) {
      case 'add-rule':
        state.rules.push({
          id: v4(),
          order: state.rules.length,
          filters: [],
          operation: 'or',
          filterType: 'in_group',
          groups: [],
          ...state.default_content,
        });
        break;
      case 'delete-rule':
        state.rules = state.rules.reduce<State['rules']>((acc, rule) => {
          if (rule.id !== id) acc.push(rule);
          return acc;
        }, []);
        break;
      case 'move-rule-up':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx > 0) {
          state.rules[idx].order = state.rules[idx].order - 1;
          state.rules[idx - 1].order = state.rules[idx].order + 1;
          const moved = state.rules[idx - 1];
          state.rules[idx - 1] = state.rules[idx];
          state.rules[idx] = moved;
        }
        break;
      case 'move-rule-down':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0 && idx < state.rules.length - 1) {
          state.rules[idx].order = state.rules[idx].order + 1;
          state.rules[idx + 1].order = state.rules[idx].order - 1;
          const moved = state.rules[idx + 1];
          state.rules[idx + 1] = state.rules[idx];
          state.rules[idx] = moved;
        }
        break;
      case 'set-default-image':
        state.default_content.fileId = value.id;
        state.default_content.name = value.name;
        state.default_content.src = value.url;
        break;
      case 'set-default-position':
        state.default_content.position = value;
        break;
      case 'set-default-size':
        state.default_content.size = value;
        if (value === 'dynamic') {
          state.default_content.unit = 'pixel';
        }
        break;
      case 'set-default-unit':
        state.default_content.unit = value;
        if (value === 'percent') {
          state.default_content.height = null;
        }
        break;
      case 'set-default-dimension':
        state.default_content.dimension = value;
        break;
      case 'set-default-has-link':
        state.default_content.hasLink = value;
        if (!value) {
          state.default_content.alt = null;
          state.default_content.link = null;
        }
        break;
      case 'set-default-height':
        state.default_content.height = value;
        break;
      case 'set-default-width':
        state.default_content.width = value;
        break;
      case 'set-default-max-height':
        state.default_content.maxHeight = value;
        break;
      case 'set-default-max-width':
        state.default_content.maxWidth = value;
        break;
      case 'set-default-alt-text':
        state.default_content.alt = value;
        break;
      case 'set-default-link':
        state.default_content.link = value;
        break;
      case 'set-dynamic-image':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].fileId = value.id;
          state.rules[idx].name = value.name;
          state.rules[idx].src = value.url;
        }
        break;
      case 'set-dynamic-position':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].position = value;
        }
        break;
      case 'set-dynamic-size':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].size = value;
          if (value === 'dynamic') {
            state.rules[idx].unit = 'pixel';
          }
        }
        break;
      case 'set-dynamic-unit':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].unit = value;
          if (value === 'percent') {
            state.rules[idx].height = null;
          }
        }
        break;
      case 'set-dynamic-dimension':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].dimension = value;
        }
        break;
      case 'set-dynamic-has-link':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].hasLink = value;
          if (!value) {
            state.rules[idx].alt = null;
            state.rules[idx].link = null;
          }
        }
        break;
      case 'set-dynamic-height':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].height = value;
        }
        break;
      case 'set-dynamic-width':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].width = value;
        }
        break;
      case 'set-dynamic-max-height':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].maxHeight = value;
        }
        break;
      case 'set-dynamic-max-width':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].maxWidth = value;
        }
        break;
      case 'set-dynamic-alt-text':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].alt = value;
        }
        break;
      case 'set-dynamic-link':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].link = value;
        }
        break;
      case 'set-errors':
        state.errors = value;
        break;
      case 'clear-default-content-error':
        state.errors.default_content = false;
        break;
      case 'clear-dynamic-content-error':
        if (typeof index === 'number') {
          state.errors.rules[index] = false;
        }
        break;
      case 'set-state':
        return value;
      case 'set-filter-type':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].filterType = value;
          state.rules[idx].groups = [];
          if (value === 'custom_filter') {
            state.rules[idx].filters = [
              {
                filter: null,
              },
            ];
          } else {
            state.rules[idx].groups = [];
            state.rules[idx].filters = [];
          }
        }
        break;
      case 'render-filters':
        state.rules = [...state.rules];
        break;
      case 'create-filter':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          const fIndex =
            filterIndex === undefined
              ? state.rules[idx].filters.length
              : filterIndex;
          state.rules[idx].filters[fIndex] = filter!;
          break;
        }
        break;
      case 'add-filter':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].filters.push({ filter: null });
        }
        break;
      case 'delete-filter':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          const filters = state.rules[idx].filters
            .slice(0, filterIndex!)
            .concat(state.rules[idx].filters.slice(filterIndex! + 1));
          state.rules[idx].filters = filters;
        }
        break;
      case 'set-groups':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].groups = groups!;
        }
        break;
      case 'set-operation':
        idx = state.rules.findIndex((rule) => rule.id === id);
        if (idx >= 0) {
          state.rules[idx].operation = operation!;
        }
        break;
    }
  }
);

export const addRule = () => ({ type: 'add-rule' });
export const deleteRule = (id: string) => ({ type: 'delete-rule', id });
export const moveRuleUp = (id: string) => ({ type: 'move-rule-up', id });
export const moveRuleDown = (id: string) => ({ type: 'move-rule-down', id });
export const setDefaultImage = (value: File) => ({
  type: 'set-default-image',
  value,
});
export const setDefaultPosition = (value: Position) => ({
  type: 'set-default-position',
  value,
});
export const setDefaultSize = (value: Size) => ({
  type: 'set-default-size',
  value,
});
export const setDefaultUnit = (value: Unit) => ({
  type: 'set-default-unit',
  value,
});
export const setDefaultDimension = (value: Dimension) => ({
  type: 'set-default-dimension',
  value,
});
export const setDefaultWidth = (value: string) => ({
  type: 'set-default-width',
  value,
});
export const setDefaultHeight = (value: string) => ({
  type: 'set-default-height',
  value,
});
export const setDefaultMaxWidth = (value: string) => ({
  type: 'set-default-max-width',
  value,
});
export const setDefaultMaxHeight = (value: string) => ({
  type: 'set-default-max-height',
  value,
});
export const setDefaultHasLink = (value: boolean) => ({
  type: 'set-default-has-link',
  value,
});
export const setDefaultLink = (value: string) => ({
  type: 'set-default-link',
  value,
});
export const setDefaultAltText = (value: string) => ({
  type: 'set-default-alt-text',
  value,
});
export const setDynamicImage = (id: string, value: File) => ({
  type: 'set-dynamic-image',
  id,
  value,
});
export const setDynamicPosition = (id: string, value: Position) => ({
  type: 'set-dynamic-position',
  id,
  value,
});
export const setDynamicSize = (id: string, value: Size) => ({
  type: 'set-dynamic-size',
  id,
  value,
});
export const setDynamicUnit = (id: string, value: Unit) => ({
  type: 'set-dynamic-unit',
  id,
  value,
});
export const setDynamicDimension = (id: string, value: Dimension) => ({
  type: 'set-dynamic-dimension',
  id,
  value,
});
export const setDynamicHasLink = (id: string, value: boolean) => ({
  type: 'set-dynamic-has-link',
  id,
  value,
});
export const setDynamicWidth = (id: string, value: string) => ({
  type: 'set-dynamic-width',
  id,
  value,
});
export const setDynamicHeight = (id: string, value: string) => ({
  type: 'set-dynamic-height',
  id,
  value,
});
export const setDynamicMaxWidth = (id: string, value: string) => ({
  type: 'set-dynamic-max-width',
  id,
  value,
});
export const setDynamicMaxHeight = (id: string, value: string) => ({
  type: 'set-dynamic-max-height',
  id,
  value,
});
export const setDynamicLink = (id: string, value: string) => ({
  type: 'set-dynamic-link',
  id,
  value,
});
export const setDynamicAltText = (id: string, value: string) => ({
  type: 'set-dynamic-alt-text',
  id,
  value,
});
export const setErrors = (value: State['errors']) => ({
  type: 'set-errors',
  value,
});
export const clearDynamicContentError = (value: number) => ({
  type: 'clear-dynamic-content-error',
  index: value,
});
export const clearDefaultContentError = () => ({
  type: 'clear-default-content-error',
});

export const setState = (value: State) => ({
  type: 'set-state',
  value,
});
export const setFilterType = (id: string, filterType: FilterType) => ({
  type: 'set-filter-type',
  id,
  value: filterType,
});
export const createFilter = (
  id: string,
  filter: FilterData,
  filterIndex?: number
) => ({
  type: 'create-filter',
  id,
  filter,
  filterIndex,
});
export const addFilter = (id: string) => ({
  type: 'add-filter',
  id,
});
export const deleteFilter = (id: string, filterIndex: number) => ({
  type: 'delete-filter',
  id,
  filterIndex,
});
export const setGroups = (id: string, groups: string[]) => ({
  type: 'set-groups',
  id,
  groups,
});
export const setOperation = (id: string, operation: 'and' | 'or') => ({
  type: 'set-operation',
  id,
  operation,
});

export const vaidateUrls = (state: State, t: TFunction) => {
  const validate = (data: ImageSettings) => {
    const { hasLink, link } = data;
    if (hasLink && !link?.length) {
      return t('This field is required.');
    }
    if (
      hasLink &&
      !(link?.startsWith('http://') || link?.startsWith('https://'))
    ) {
      return MISSING_URL_PROTOCOL_MESSAGE(t);
    }
    if (hasLink && !checkUrl(data.link)) {
      return MISSING_URL_TLD_MESSAGE(t);
    }
    return false;
  };

  return {
    default_content: validate(state.default_content),
    rules: state.rules.map(validate),
  };
};

export const createInitialState = async (
  defaultImageProps: PageBuilderImage['props'],
  metadata: any,
  variables: any,
  dispatch: any,
  queryClient: any,
  dynamicImages?: PageBuilderImage[]
) => {
  const init = {
    ...initialState,
    default_content: {
      ...initialState.default_content,
      ...defaultImageProps,
      hasLink: Boolean(defaultImageProps.link),
    },
  };

  if (!Array.isArray(dynamicImages)) {
    return init as any;
  }

  const filters: (FilterSet | null)[] = [];

  for await (const node of dynamicImages) {
    if (node.custom.filter.type === 'custom_filter') {
      let filterPayloads: FilterConfig = node.custom.filter.filter;

      if (needsConversion(node.custom.filter.filter)) {
        filterPayloads = {
          ...node.custom.filter.filter,
          query: node.custom.filter.filter.query.map((q: any) => {
            return {
              ...q,
              filters: q.filters.map((filter: any) => {
                return filter.values;
              }),
            };
          }),
        };
      }

      const filter = await loadSavedFilter(
        metadata,
        filterPayloads,
        Object.fromEntries(variables) as FilterVars,
        queryClient
      );
      filters.push(filter as FilterSet);
    } else {
      filters.push(null);
    }
  }

  const rules: State['rules'] = dynamicImages.map((node, i) => {
    const {
      fileId,
      src,
      name,
      position,
      size,
      unit,
      dimension,
      link,
      width,
      height,
      maxWidth,
      maxHeight,
      alt,
      filterId,
      filterOrder,
      containerWidth,
      naturalHeight,
      naturalWidth,
    } = node.props;

    const fs =
      filters[i] !== null
        ? filters[i]!.filters.map((f) => {
            return {
              filter: f,
              ops: opsForFilter(f, dispatch),
              type: f.type,
            };
          })
        : null;

    const groups =
      node.custom.filter.groups?.map((g: any) => {
        if (typeof g === 'object') {
          return g.id;
        }
        return g;
      }) ?? [];

    return {
      id: filterId!,
      order: filterOrder!,
      fileId,
      src,
      name,
      position: position ?? 'center',
      size: size ?? 'auto',
      unit,
      dimension,
      hasLink: Boolean(link),
      link,
      width,
      height,
      maxWidth,
      maxHeight,
      alt,
      containerWidth,
      naturalHeight,
      naturalWidth,
      groups,
      filters: fs ?? [],
      filterType: node.custom.filter.type,
      operation: filters[i]?.and ? 'and' : 'or',
    };
  });

  return { ...init, rules };
};
