import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadSCVTemplateButton from 'components/DownloadButton/DownloadSCVTemplateButton';
import { useProgress } from 'hooks/uploadFiles/useProgress';
import {
  RECORD_IMPORT_FILE_UPLOAD_SOURCE,
  useUploadFile,
} from 'hooks/uploadFiles/useUploadFile';
import { useFiles } from 'hooks/uploadFiles/useFiles';
import FilePicker from 'components/FilePicker';
import { importFileExtensions } from 'components/FilePicker/defaults';
import styled from '@emotion/styled';
import { TextSpan, KizenTypography } from 'app/typography';
import { gutters } from 'app/spacing';
import { colorsText, grayScale, colorsButton } from 'app/colors';
import { parse } from 'csv-parse/browser/esm'; // https://csv.js.org/parse/distributions/browser_esm/
import { hasUTF8BOM } from 'components/DownloadButton/useCSVString';
import { monitoringMessageHelper } from 'sentry/helpers';

export const StyledLabel = styled(TextSpan)`
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

export const StyledInfo = styled(KizenTypography)`
  margin-top: ${gutters.spacing(2, -2)}px;
`;

const ErrorWrapper = styled.div`
  margin-top: ${gutters.spacing(2)}px;
`;

const StyledError = styled(KizenTypography)`
  color: ${colorsButton.red.hover};
`;

// We don't need all data, just several rows.
// In the third step, we show just 5 rows with data.
// So we take 1 row with titles of columns and 5 data rows
const COUNT_LINES = 6;

// Adapted from example
// https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamDefaultReader/read#example_2_-_handling_text_line_by_line
async function* readLineByLine(file, count = COUNT_LINES) {
  const utf8Decoder = new TextDecoder('utf-8');
  const reader = file.stream().getReader();
  let { value: chunk, done: readerDone } = await reader.read();

  chunk = chunk ? utf8Decoder.decode(chunk, { stream: true }) : '';

  const re = /\r\n|\n|\r/gm;
  let startIndex = 0;
  let i = 0;
  let quotesInFile = 0;

  for (;;) {
    if (i === count) {
      break;
    }
    const result = re.exec(chunk);
    if (!result) {
      if (readerDone) {
        break;
      }
      const remainder = chunk.substr(startIndex);
      ({ value: chunk, done: readerDone } = await reader.read());
      chunk =
        remainder + (chunk ? utf8Decoder.decode(chunk, { stream: true }) : '');
      startIndex = re.lastIndex = 0;
      continue;
    }
    const line = chunk.substring(startIndex, result.index);
    yield line;
    startIndex = re.lastIndex;
    quotesInFile += (line?.match(/"/g) || []).length;
    //we do not need to increment in case we have a multiline value in one record
    if (quotesInFile % 2 === 0) {
      i++;
    }
  }
  if (startIndex < chunk.length && i !== count) {
    // last line didn't end in a newline char
    yield chunk.substr(startIndex);
  }
  await reader.cancel();
  reader.releaseLock();
}

const UploadDataSection = ({
  fields,
  publicFile,
  files: filesProp,
  source = RECORD_IMPORT_FILE_UPLOAD_SOURCE,
  ...props
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [UTF8warning, setUTF8Warning] = useState(false);
  const [progress, { clearProgress, initializeProgress, updateProgress }] =
    useProgress();
  const uploadFile = useUploadFile(publicFile, updateProgress, source);

  const [, { addFiles }] = useFiles({
    initial: filesProp,
    initializeProgress,
    uploadFile,
  });
  // We put all the props behind a ref because our callback is async,
  // and we'll need to eventually react using up-to-date values.
  const propsRef = useRef();
  useEffect(() => {
    propsRef.current = props;
  });

  const handleAddFiles = useCallback(
    async (updatedFiles) => {
      setError();
      setUTF8Warning(false);
      const createdFiles = await addFiles(updatedFiles);
      clearProgress();
      if (createdFiles.length) {
        hasUTF8BOM(updatedFiles[0]).then(
          (hasBOM) => !hasBOM && setUTF8Warning(true)
        );
        const errorHandler = (message) => {
          // reset all steps
          propsRef.current.setCSVData(null);
          setError(message);
        };

        const output = [];
        const csvParser = parse();
        for await (const line of readLineByLine(updatedFiles[0])) {
          try {
            csvParser.write(`${line}\n`);
          } catch (error) {
            monitoringMessageHelper(error.message);
            errorHandler(error.message);
          }
        }
        // Use the readable stream api
        csvParser.on('readable', function () {
          let record;
          while ((record = csvParser.read())) {
            output.push(record);
          }
        });

        csvParser.on('error', function (err) {
          errorHandler(err.message);
        });

        csvParser.on('end', function () {
          propsRef.current.setCSVData(output);
        });
        // Close the readable stream
        csvParser.end();
      }
      // Give the consumer a "tentative" file record
      propsRef.current.onChange([createdFiles[createdFiles.length - 1]]);
    },
    [addFiles, clearProgress]
  );

  const status = useMemo(
    () => progress && Object.values(progress)[0].progress,
    [progress]
  );

  return (
    <>
      <DownloadSCVTemplateButton
        fields={fields}
        text={t('Download Template')}
        {...props}
      />
      <FilePicker
        onFileAdded={handleAddFiles}
        allowedFileExtensions={importFileExtensions}
        uploadStatus={status}
        {...props}
      />
      <StyledInfo>
        {t(
          '*Only CSV files are supported — please make sure your file name ends with .csv'
        )}
      </StyledInfo>
      {error || UTF8warning ? (
        <ErrorWrapper>
          {error ? (
            <>
              <StyledError>
                {t('There was an error reading the CSV file')}
                {': '}
                {error}
              </StyledError>
              <StyledError>
                {t(
                  'Please try again and contact Kizen support if the problem persists.'
                )}
              </StyledError>
            </>
          ) : (
            <StyledError>
              {t(
                'A possible non-UTF-8 file was detected. Proceed with caution, as this may cause processing issues. For best results, ensure the file is UTF-8 encoded'
              )}
            </StyledError>
          )}
        </ErrorWrapper>
      ) : null}
    </>
  );
};

export default UploadDataSection;
