import DecimalNumberInput from '__components/Inputs/TextInput/presets/DecimalNumber';
import { InputProps } from './types';

export const Decimal = ({
  step,
  value,
  width,
  placeholder,
  error,
  onChange,
}: InputProps) => {
  return (
    <DecimalNumberInput
      value={value}
      width={width}
      placeholder={placeholder}
      error={error}
      onChange={(val: string) => onChange(step, val.replaceAll(',', ''))}
    />
  );
};
