import { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters, layers } from 'app/spacing';
import { grayScale, shadowLight } from 'app/colors';
import { Text } from 'app/typography';
import { default as KizenLoader } from 'components/Kizen/Loader';

const ROW_HEIGHT = 40;

function useScroll(ref, handler) {
  useEffect(() => {
    const { current: el } = ref;

    if (el) {
      el.addEventListener('scroll', handler, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (el) {
        el.removeEventListener('scroll', handler);
      }
    };
  }, [handler, ref]);
}

export function useEndsOfYScroll(ref, deps = []) {
  const [top, setTop] = useState(false);
  const [bottom, setBottom] = useState(false);
  const handler = useCallback(() => {
    const { current: el } = ref;
    if (el) {
      setTop(el.scrollTop === 0);
      setBottom(el.scrollHeight - el.scrollTop === el.clientHeight);
    }
  }, [ref]);

  useScroll(ref, handler);

  useEffect(() => {
    handler(); // linter doesn't know how to handle spread expression in deps
  }, [handler, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  return [top, bottom];
}

export function useScrollThreshold(ref, deps = [], cb) {
  const [pastThreshold, setPastThreshold] = useState(false);
  const callId = useRef(cb);

  useEffect(() => {
    callId.current = cb;
  });

  const handler = useCallback(() => {
    const { current: el } = ref;
    if (el) {
      setPastThreshold(
        el.scrollHeight - el.scrollTop <= el.clientHeight + ROW_HEIGHT * 10 // this assumes the default page size is 50
      );
    }
  }, [ref]);

  useScroll(ref, handler);

  useEffect(() => {
    handler(); // linter doesn't know how to handle spread expression in deps
  }, [handler, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pastThreshold) {
      callId.current();
    }
  }, [pastThreshold]);
}

export const GradientBottomWrapper = styled.div`
  position: sticky;
  bottom: 0;
  height: 0;
  z-index: ${layers.content(1)};
  overflow: visible;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
  ${({ fadeIn }) => css`
    opacity: ${fadeIn ? 1 : 0};
  `}
`;

export const GradientBottomBody = styled.div`
  position: absolute;
  left: 1px;
  right: 1px;
  height: 50px;
  // Need to use "rgba(255, 255, 255, 0)" rather than "transparent" for safari's benefit
  background: linear-gradient(
    to top,
    ${grayScale.white},
    rgba(255, 255, 255, 0)
  );

  ${({ scrollable }) => css`
    bottom: ${scrollable ? 4 : 0}px;
  `}
`;

export const DropShadowTop = styled.div`
  border-bottom: 1px solid ${grayScale.mediumLight};
  height: 20px;
  margin-top: -10px;
  padding-bottom: 5px;
  ${({ fadeIn }) =>
    fadeIn
      ? css`
          ${shadowLight}
        `
      : ''}
`;
export function GradientBottom({ scrollable, ...props }) {
  return (
    <GradientBottomWrapper {...props}>
      <GradientBottomBody inModal={props.inModal} scrollable={scrollable} />
    </GradientBottomWrapper>
  );
}

export const GradientRightWrapper = styled.div`
  position: absolute;
  ${({ inModal }) =>
    inModal
      ? css`
          // position the fade correctly in the modal
          right: 15px;
          top: 68px;
          bottom: 7px;
        `
      : css`
          // position the fade correctly on the dash board
          right: 4px;
          top: 52px;
          bottom: 4px;
        `}

  width: 0;
  z-index: ${layers.content(0)};
  overflow: visible;
  pointer-events: none;
  background: linear-gradient(to left, #ffffff 38px, rgba(255, 255, 255, 0));
  ${({ fadeIn }) => css`
    opacity: ${fadeIn ? 1 : 0};
  `}
  div {
    width: 113px;
    background: linear-gradient(to left, #ffffff 63px, rgba(255, 255, 255, 0));
  }

  ${({ virtual }) =>
    virtual
      ? css`
          right: 0;
          top: 0;
          bottom: 0;
          width: 80px;
        `
      : ''}
`;

export const GradientRightBody = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 102px;
  // Need to use "rgba(255, 255, 255, 0)" rather than "transparent" for safari's benefit
  background: linear-gradient(
    to left,
    ${grayScale.white} 52px,
    rgba(255, 255, 255, 0)
  );
`;

export function GradientRight({ fadeIn, inModal }) {
  return (
    <GradientRightWrapper fadeIn={fadeIn} inModal={inModal}>
      <GradientRightBody />
    </GradientRightWrapper>
  );
}

export function GradientRightRow({ fadeIn }) {
  return (
    <GradientRightWrapper className="right-row-fade" fadeIn={fadeIn} virtual>
      <GradientRightBody />
    </GradientRightWrapper>
  );
}

export const Loader = styled(KizenLoader)`
  ${(props) =>
    props.noPadding &&
    `
  padding: 20px 0 !important; // :( Sorry, Loader component sets styles inline
`}
`;

export const TableText = styled(Text)`
  text-align: center;
  margin: ${gutters.standard};
`;
