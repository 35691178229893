import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import { breakpoints, gutters } from 'app/spacing';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import { EditArea } from './styles';
import { PreReleaseFeature } from 'hooks/usePreReleaseFeatures';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/Kizen/IconButton';

// Pared down version of https://www.joshwcomeau.com/react/modern-spacer-gif/
const IconSpacer = styled.span`
  display: block;
  width: ${gutters.spacing(4)}px;
  min-width: ${gutters.spacing(4)}px;
  @media (min-width: ${breakpoints.md}px) {
    width: ${gutters.spacing(6)}px;
    min-width: ${gutters.spacing(6)}px;
  }
`;

const color = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

export default function Actions({
  isCommenting,
  isDeletable = false,
  isEditable = false,
  onClickComment,
  onClickDelete,
  onClickEdit,
  commentsCount,
}) {
  const { t } = useTranslation();
  return (
    <EditArea>
      <IconButton
        title={t('Add Comment')}
        popperConfig={{
          modifiers: {
            offset: { offset: '0, 5px' }, // Touch top of text
            preventOverflow: { enabled: false },
          },
        }}
        sizing="dense"
        color={isCommenting ? colorsButton.blue : color}
      >
        <IconSizing size="15px" restrictWidth={false}>
          <Icon
            icon="comment"
            onClick={onClickComment}
            withBadge={commentsCount > 0}
            badgeText={commentsCount}
            badgeHeight={13}
            isStaticBadge={!isEditable && !isDeletable}
          />
        </IconSizing>
      </IconButton>
      {isEditable && (
        <>
          <IconSpacer />
          <IconButton
            popperConfig={{
              modifiers: {
                offset: { offset: '0px, 5px' }, // Touch top of text
              },
            }}
            title={t('Edit Timeline')}
            sizing="dense"
            color={color}
          >
            <IconSizing size="15px">
              <Icon icon="pen" onClick={onClickEdit} />
            </IconSizing>
          </IconButton>
        </>
      )}
      {isDeletable && (
        <PreReleaseFeature>
          <>
            <IconSpacer />
            <IconButton
              popperConfig={{
                modifiers: {
                  offset: { offset: '0px, 5px' }, // Touch top of text
                },
              }}
              title={t('Delete Timeline')}
              sizing="dense"
              color={color}
            >
              <IconSizing size="15px">
                <Icon icon="delete" onClick={onClickDelete} />
              </IconSizing>
            </IconButton>
          </>
        </PreReleaseFeature>
      )}
    </EditArea>
  );
}
