import CustomObjectsService from 'services/CustomObjectsService';
import { toastVariant } from 'components/ToastProvider';
import { capitalize } from 'lodash/string';
import produce from 'immer';
import pick from 'lodash/pick';
import partition from 'lodash/partition';
import PipelineService from 'services/PipelineService';
import { getObjectModelName } from 'services/FieldService';
import { orderBy } from 'lodash';

import { ASSOCIATION_SOURCE_TYPES } from './constants';

export async function saveStep(payload, model, activeStep) {
  if (!model) {
    return CustomObjectsService.createCustomObject(payload);
  }

  delete payload.relatedObjects;
  // this properties are updated directly in own modal and should not be passed here
  delete payload.entityDisplayNamePatternHtml;
  delete payload.entityDisplayNamePatternText;

  return CustomObjectsService.updateCustomObject({
    customObject: model,
    updatedCustomObjectValues: payload,
  });
}

export const setBoardSetting = async (id, body) =>
  PipelineService.updateBoardSettings({
    id,
    body,
  });

export const CO_OBJECT_TYPE_STANDARD = 'standard';
export const CO_OBJECT_TYPE_PIPELINE = 'pipeline';

export const CO_LAYOUT_FIELDS = 'fields';
export const CO_LAYOUT_RELATED_PIPELINES = 'related_pipelines';
export const CO_LAYOUT_LEAD_SOURCES = 'lead_sources';
export const CO_LAYOUT_ACTION_BLOCK = 'action_block';

export const CO_LAYOUT_ACTION_BLOCK_ACTIONS = {
  ADD_NOTES: 'addNotes',
  SEND_EMAILS: 'sendEmails',
  SCHEDULE_ACTIVITIES: 'scheduleActivities',
  LOG_ACTIVITIES: 'logActivities',
  START_AUTOMATIONS: 'startAutomations',
  LOG_ACTIVITIES_LIST: 'logActivitiesList',
  SCHEDULE_ACTIVITIES_LIST: 'scheduleActivitiesList',
  START_AUTOMATIONS_LIST: 'startAutomationsList',
};

export const CO_LAYOUT_TIMELINE = 'timeline';
export const CO_LAYOUT_TEAM_ACTIVITIES = 'team_and_activities';
export const CO_LAYOUT_RELATED_OBJECT_FIELDS = 'related_object_fields';

export const CO_STAGE_STATUS_OPEN = 'open';
export const CO_STAGE_STATUS_WON = 'won';
export const CO_STAGE_STATUS_LOST = 'lost';
export const CO_STAGE_STATUS_DISQUALIFIED = 'disqualified';

export const PIPELINE_CO = 'pipeline';
export const STANDARD_CO = 'standard';

export const CUSTOM_LAYOUT_TABS = {
  RECORDS_LAYOUT: 'records_layout',
  BOARD_VIEW_LAYOUT: 'board_view_layout',
  DEFAULT_COLUMNS: 'default_columns',
};

export const CO_RELATION_TYPE_PRIMARY = 'primary';
export const CO_RELATION_TYPE_ONE_TO_ONE = 'one_to_one';
// export const CO_RELATION_TYPE_ADDITIONAL = 'additional';
export const CO_RELATION_TYPE_BOTH = 'primary_additional';

export const getObjectType = (objectContainsWorkflow) =>
  objectContainsWorkflow ? PIPELINE_CO : STANDARD_CO;

export const randomBoolean = () => Math.random() < 0.5;

export const defaultModel = {
  objectName: '',
  entityName: '',
  objectType: 'pipeline',
  allowOnForms: false,
  trackEntityValue: true,
  defaultOnActivities: true,
  pipeline: null,
  reasonsLost: [],
  relatedObjects: [],
  hasCommerceData: false,
  // ai
  // TODO: probably needs to be moved to pipeline prop
  aiConfidenceThreshold: '0.90',
  useAiToUpdatePercentage: false,
  quickFilteringEnabled: false,
  quickFilteringFacets: true,
  meta: {},
  associationSource: ASSOCIATION_SOURCE_TYPES.direct,
  associationSourceFields: [],
  associationSourceObjects: [],
};

export const hiddenToBottom = (els) => orderBy(els, 'visible', 'desc');

export const getToastConfig = (t, isNew, isContact = false) => {
  return {
    success: {
      variant: toastVariant.SUCCESS,
      message: isNew
        ? t(`The {{objectName}} was successfully created.`, {
            objectName: isContact ? t('Contact') : t('Custom Object'),
          })
        : t(`The {{objectName}} was successfully updated.`, {
            objectName: isContact ? t('Contact') : t('Custom Object'),
          }),
    },
    error: {
      variant: toastVariant.FAILURE,
      message: `${
        isNew
          ? t('The {{objectName}} was not created.', {
              objectName: isContact ? t('Contact') : t('Custom Object'),
            })
          : t('The {{objectName}} was not updated.', {
              objectName: isContact ? t('Contact') : t('Custom Object'),
            })
      } ${t('Please try again or contact Kizen support.')}`,
    },
    successDeleted: {
      variant: toastVariant.SUCCESS,
      message: t('The custom object has been successfully deleted.'),
    },
    errorDeleted: {
      variant: toastVariant.FAILURE,
      message: t('The custom object has not been successfully deleted.'),
    },
  };
};

export async function deleteStageHandler(payload, { values }) {
  await CustomObjectsService.deleteStage({
    objectId: values.id,
    ...payload,
  });
}

export const enforceConstraints = produce((draft, models) => {
  // Enforce basic requirements
  draft.meta = draft?.meta || {};

  // Ensure pipeline info is available when there is a pipeline selected
  if (draft?.objectType === PIPELINE_CO && !draft?.pipeline) {
    draft.trackEntityValue = true;
    draft.includePercentageToClose = true;
    draft.pipeline = {
      stages: [
        {
          name: 'Prospect',
          status: CO_STAGE_STATUS_OPEN,
          percentageChanceToClose: '10',
          // order property is set when submitting
        },
      ],
    };
  }
});

export const setDefaultProps = (formData = {}) => {
  return formData?.objectType === 'pipeline'
    ? {
        trackEntityValue: true,
        includePercentageToClose: true,
        ...formData,
        pipeline: {
          stages: [
            {
              name: 'Stage 1',
              status: CO_STAGE_STATUS_OPEN,
              percentageChanceToClose: 10,
              order: 0,
            },
          ],
          ...formData.pipeline,
        },
        [`reasons${capitalize(CO_STAGE_STATUS_LOST)}`]: undefined,
        [`reasons${capitalize(CO_STAGE_STATUS_DISQUALIFIED)}`]: undefined,
      }
    : {};
};

export const createWizardState = (model, models = []) => {
  if (!model) {
    return {
      ...defaultModel,
      relatedObjectsReverse: [],
      relatedObjects: models
        .filter((m) => m.name === getObjectModelName('contacts'))
        .map((m) => ({
          relatedObject: m.id,
          relationType: CO_RELATION_TYPE_PRIMARY,
          rollupTimeline: true,
          inverseRelationRollupTimeline: true,
        })),
    };
  }

  let values = enforceConstraints(
    pick(model, Object.keys(defaultModel)),
    models
  );
  values = produce(values, (draft) => {
    // Separate-out related objects from reverse relations
    const [relatedObjects, relatedObjectsReverse] = partition(
      draft.relatedObjects,
      ({ relationType }) =>
        [
          CO_RELATION_TYPE_PRIMARY,
          CO_RELATION_TYPE_ONE_TO_ONE,
          // CO_RELATION_TYPE_ADDITIONAL,
          CO_RELATION_TYPE_BOTH,
        ].includes(relationType)
    );
    Object.assign(draft, {
      relatedObjects,
      relatedObjectsReverse,
    });

    if (draft.pipeline) {
      // Ensure stages are sorted by their order
      draft.pipeline.stages.sort((a, b) => (a.order || 0) - (b.order || 0));
    }
    draft.associationSource =
      draft.associationSource || ASSOCIATION_SOURCE_TYPES.direct;
    draft.associationSourceFields = draft.associationSourceFields || [];
    draft.associationSourceObjects = draft.associationSourceObjects || [];
  });

  return values;
};

export const isContact = (object) => object?.name === 'client_client';
