import { colorsPrimary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { CardHeader } from '../components/CardHeader';
import { initiatorTypes } from '../../constants';
import { defaultAutomationString, defaultEmployeeString } from '../helpers';
import { useMemo } from 'react';

const getCardHeaderConfig = (action) => (t) => {
  const prefix =
    action === 'granted'
      ? t('"My Records" access granted by')
      : t('"My Records" access revoked by');
  return {
    [initiatorTypes.employee]: {
      prefix,
      creationType: defaultEmployeeString,
    },
    [initiatorTypes.automation]: {
      prefix,
      creationType: defaultAutomationString,
    },
    [initiatorTypes['empty-initiator']]: {
      prefix,
    },
  };
};

export const RecordAccessUpdateBlock = ({ event, ...others }) => {
  const cardHeaderConfig = useMemo(
    () => getCardHeaderConfig(event.data.action),
    [event.data.action]
  );
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsPrimary.purple.dark}
          icon="id-card"
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={event.data.teamMember.displayText}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    />
  );
};
