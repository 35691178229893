import BubbleProfileImage from 'components/Charts/ScheduledActivities/BubbleProfileImage';
import BaseOption from 'components/MultiColumnBuilder/components/BaseOption';
import { useCallback } from 'react';
import { DEFAULT_ITEM_IDS } from '../../constants';
import { COLUMNS } from '../../utils';
import { StyledProfileImage } from './styles';
import { useSelector } from 'react-redux';
import AdditionalActions from './components/AdditionalActions';
import ToolbarBuilderChild from './components/Child';
import { getTeamMemberNameAndImage } from 'store/authentication/selectors';

const NO_VALUE = '—';

const ToolbarBuilderOption = (providedProps) => {
  const iconVisible = useCallback(({ element }) => {
    return !element.isDynamic && element.id !== DEFAULT_ITEM_IDS.MY_ACCOUNT;
  }, []);

  const iconEditable = useCallback(({ editable, element, section }) => {
    return (
      (editable ||
        (section === COLUMNS.FIND_OPTIONS && element.isCustomObject)) &&
      !element.isLocked &&
      (!element.isDefault ||
        (element.type === 'category' && !element.dynamicallyPopulated))
    );
  }, []);

  const colorEditable = useCallback(({ editable, element, child }) => {
    return editable && !element.isColorLocked && !child;
  }, []);

  const { userName = NO_VALUE, userImage } = useSelector(
    getTeamMemberNameAndImage
  );

  const overrideIcon = useCallback(
    ({ element }) => {
      return element.id === DEFAULT_ITEM_IDS.MY_ACCOUNT ? (
        <StyledProfileImage>
          <BubbleProfileImage
            fullName={userName}
            imageURL={userImage}
            showToolTip
            placement="bottom"
            popperConfig={{
              modifiers: {
                offset: { offset: '0, -5px' }, // Touch bottom of text
                preventOverflow: { enabled: false },
              },
            }}
          />
        </StyledProfileImage>
      ) : null;
    },
    [userImage, userName]
  );

  const canDelete = useCallback(({ element, section, child }) => {
    return (
      ((element.isDefault && element.type !== 'category') ||
        element.isCustomObject) &&
      !element.isLocked &&
      (section === COLUMNS.YOUR_TOOLBAR || child)
    );
  }, []);

  const canChangeSize = useCallback(({ child, element, parent }) => {
    return child && !element.isLocked && !parent?.isLocked;
  }, []);

  const additionalActions = useCallback(
    ({ element, removeItem, index, isCollapsed, expandItem, collapseItem }) => {
      return element.type === 'category' && !element.dynamicallyPopulated ? (
        // Sometimes AdditionalActions contains the delete action, instead of using the canDelete callback
        <AdditionalActions
          element={element}
          isCollapsed={isCollapsed}
          removeItem={removeItem}
          index={index}
          expandItem={expandItem}
          collapseItem={collapseItem}
        />
      ) : null;
    },
    []
  );

  const showPlaceholderChildren = useCallback(({ element, draggingItem }) => {
    return (
      element.children?.length === 0 &&
      draggingItem &&
      draggingItem.type !== 'category' &&
      draggingItem.id !== 'new_category'
    );
  }, []);

  return (
    <BaseOption
      {...providedProps}
      iconVisible={iconVisible}
      iconEditable={iconEditable}
      colorEditable={colorEditable}
      overrideIcon={overrideIcon}
      canDelete={canDelete}
      canChangeSize={canChangeSize}
      additionalActions={additionalActions}
      showPlaceholderChildren={showPlaceholderChildren}
      childDragFromColumn={COLUMNS.YOUR_TOOLBAR}
    >
      {(providedProps) => {
        return <ToolbarBuilderChild {...providedProps} />;
      }}
    </BaseOption>
  );
};

export default ToolbarBuilderOption;
