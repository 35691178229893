export const DEFAULT_LINE_HEIGHT_VALUE = 1.25;

export const ButtonActions = {
  URL: 'url',
  GO_TO_PAGE: 'go-to-page',
  GO_TO_PAGE_SUBMIT: 'go-to-page-submit',
  LOG_ACTIVITY: 'log-activity',
  SUBMIT: 'submit',
  SCRIPT_EXECUTION: 'script-execution',
  START_AUTOMATION: 'start-automation',
};
