import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import MultiSelect from 'components/Inputs/MultiSelect';
import RelationshipMany from 'components/Fields/FieldInput/RelationshipMany';

const styles = `
  & label {
    margin-bottom: 12px;
  }
  margin-top: 18px; // margin set to this weird value because font types caused mismatch with design, it should be 20px margin
  &.select-wrapper .menu {
    position: relative !important;
    margin-top: -${gutters.spacing(1, -1)}px;
  }
  & .control {
    border-radius: 4px !important;
  }
`;

export const StyledMultiSelect = styled(MultiSelect)`
  ${styles}
`;

export const StyledRelationshipMany = styled(RelationshipMany)`
  ${styles}
`;
