import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoData from '../components/NoData';
import { StyledNoData } from '../components/NoData/styles';
import { ChartLayout, StyledLoader } from '../Trend/styles';
import MultiTrendChartManager from './MultiTrendChartManager';
import { getPendingDefaultValue } from '../helpers';
import { Chart } from './styles';
import { sanitizeTextForCharts } from '../ChartManager';

const MultiTrendChart = ({
  id,
  isLoading,
  data,
  yAxisTitle,
  tooltipLabels,
  mobile,
  legendColumns,
}) => {
  const chartRef = useRef();
  const { t } = useTranslation();
  const [chartRenderKey, setChartRenderKey] = useState(() => Date.now());
  const [chartPending, setChartPending] = useState(getPendingDefaultValue);

  const sanitizedData = useMemo(() => {
    return data.map((d) => {
      return {
        ...d,
        name: sanitizeTextForCharts(d.name),
      };
    });
  }, [data]);

  const sanitizedTooltipLabels = useMemo(() => {
    const res = { ...tooltipLabels };
    for (const [key, value] of Object.entries(res)) {
      res[key] = sanitizeTextForCharts(value);
    }
    return res;
  }, [tooltipLabels]);

  useEffect(() => {
    if (chartRef.current) {
      const manager = new MultiTrendChartManager(
        chartRef.current,
        t,
        sanitizedData,
        {
          yAxisTitle: sanitizeTextForCharts(yAxisTitle),
          tooltipLabels: sanitizedTooltipLabels,
          mobile,
          legendColumns,
        },
        {},
        () => setChartPending(false)
      );

      manager.init();

      return manager.destroy;
    }
  }, [
    chartRenderKey,
    t,
    sanitizedData,
    yAxisTitle,
    sanitizedTooltipLabels,
    mobile,
    legendColumns,
  ]);

  const createChart = useCallback((elem) => {
    if (elem) {
      chartRef.current = elem;
      setChartRenderKey(Date.now());
    }
  }, []);

  return (
    <ChartLayout>
      {/*
          This absolutely positioned loader is used to keep the chart area hidden until the calculations
          and rendering are done, as they can be quite slow for large datasets and will just
          show a blank white dashlet otherwise
        */}
      <StyledLoader loading={chartPending || isLoading} />
      {data.length === 0 || data.every((r) => r.data.length === 0) ? (
        <StyledNoData>
          <NoData data-qa-dashlet-id={id} data-qa-no-data />
        </StyledNoData>
      ) : null}
      <Chart
        data-qa-chart="trend"
        data-qa-chart-pending={chartPending}
        ref={createChart}
      />
    </ChartLayout>
  );
};

export default MultiTrendChart;
