import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomNavigationMobileWrapper,
  CustomNavigationWrapper,
} from './styles';
import NavItem from './Item';
import { breakpoints, useBreakpoint } from 'app/spacing';
import useHeightMeasurement from 'hooks/useHeightMeasurement';
import { serviceLogoutStart } from 'store/authentication/authenticationAction';
import { DEFAULT_ITEM_IDS } from 'pages/Account/pages/Toolbar/constants';
import { hasMultipleBusinesses } from 'store/authentication/helpers';
import { useQueries } from 'react-query';
import { RECENT_ITEMS } from 'queries/query-keys';
import ToolbarService from 'services/ToolbarService';
import { getTeamMemberNameAndImage } from 'store/authentication/selectors';

const NO_VALUE = '—';

const CustomNavigation = ({
  layout,
  readOnly,
  expanded,
  parentWidth,
  loading,
}) => {
  const isMobile = useBreakpoint(breakpoints.md);

  const dynamicItemsToFetch = useMemo(() => {
    return layout.reduce((toFetch, element) => {
      if (element.dynamicallyPopulated) {
        return [
          ...toFetch,
          {
            id: element.id,
            config: element.config,
          },
        ];
      }

      return toFetch;
    }, []);
  }, [layout]);

  const dynamicItems = useQueries(
    dynamicItemsToFetch.map((item) => {
      const ids = item.config?.objects?.map((o) => o.value) ?? [];
      return {
        queryKey: RECENT_ITEMS.LIST(ids, item.config?.count),
        queryFn: async () => {
          const data = await ToolbarService.getRecentItems({
            pageSize: item.config?.count,
            objects: ids,
          });

          return {
            ...data,
            item: item.id,
          };
        },
        refetchInterval: 5000, // 5 seconds
      };
    })
  );

  const dynamicItemChildren = useMemo(() => {
    return dynamicItems?.reduce((acc, item) => {
      if (item.data) {
        const itemId = item.data.item;
        const children = item.data.data?.results;
        acc[itemId] = children;
      }

      return acc;
    }, {});
  }, [dynamicItems]);

  const { userName = NO_VALUE, userImage } = useSelector(
    getTeamMemberNameAndImage
  );

  const [openNavItem, setOpenNavItem] = useState();

  const { ref: innerRef, height: innerHeight } = useHeightMeasurement();
  const { ref: outerRef, height: outerHeight } = useHeightMeasurement();

  const chosenBusinessName = useSelector(
    ({ authentication }) =>
      !!authentication.chosenBusiness && authentication.chosenBusiness.name
  );

  const dispatch = useDispatch();
  const onClickLogout = () => dispatch(serviceLogoutStart());

  const canChooseBusiness = useSelector(
    ({ authentication }) =>
      !!authentication.user &&
      (hasMultipleBusinesses(authentication.user) ||
        authentication.user.is_superuser ||
        authentication.user.is_staff)
  );

  const filteredItems = useMemo(() => {
    return layout.map((item) => {
      return {
        ...item,
        children: item.children?.filter((child) => {
          if (child.id === DEFAULT_ITEM_IDS.CHANGE_BUSINESS) {
            return canChooseBusiness;
          }
          return true;
        }),
      };
    });
  }, [canChooseBusiness, layout]);

  return isMobile ? (
    <CustomNavigationMobileWrapper
      height={innerHeight}
      expanded={expanded}
      className="Navigation"
      ref={outerRef}
      allowOverflow={innerHeight === outerHeight}
      data-qa-id="custom-navigation"
      data-qa-loading={loading}
    >
      <div ref={innerRef}>
        {filteredItems.map((item) => {
          return (
            <NavItem
              item={item}
              key={item.id}
              userName={userName}
              userImage={userImage}
              setOpen={() =>
                setOpenNavItem((existing) =>
                  existing === item.id ? null : item.id
                )
              }
              open={openNavItem === item.id}
              close={() => setOpenNavItem(null)}
              readOnly={readOnly}
              mobile
              chosenBusinessName={chosenBusinessName}
              onClickLogout={onClickLogout}
              parentWidth={parentWidth}
              dynamicChildren={dynamicItemChildren[item.id]}
            />
          );
        })}
      </div>
    </CustomNavigationMobileWrapper>
  ) : (
    <CustomNavigationWrapper
      className="Navigation"
      data-qa-id="custom-navigation"
      data-qa-loading={loading}
    >
      {filteredItems.map((item) => {
        return (
          <NavItem
            item={item}
            key={item.id}
            userName={userName}
            userImage={userImage}
            setOpen={() =>
              setOpenNavItem((existing) =>
                existing === item.id ? null : item.id
              )
            }
            open={openNavItem === item.id}
            close={() => setOpenNavItem(null)}
            readOnly={readOnly}
            chosenBusinessName={chosenBusinessName}
            onClickLogout={onClickLogout}
            parentWidth={parentWidth}
            dynamicChildren={dynamicItemChildren[item.id]}
          />
        );
      })}
    </CustomNavigationWrapper>
  );
};

export default CustomNavigation;
