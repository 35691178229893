import React from 'react';
import PropTypes from 'prop-types';
import { KizenTypography } from 'app/typography';
import {
  StyledWizardBody,
  StyledStepNumber,
  StyledStepHeader,
  StyledProgress,
  StyledProgressLine,
  StyledModalSection,
} from '../styles';
import { implicitSectionPropTypes } from '../types';

const Section = ({ active, index, title, children, done, last }) => {
  return (
    <>
      <StyledWizardBody className="GenericWizardBody">
        <StyledStepNumber active={active || done}>
          <div>{index + 1}</div>
        </StyledStepNumber>
        <StyledStepHeader>
          <KizenTypography type="header">{title}</KizenTypography>
        </StyledStepHeader>
        {last ? (
          <div />
        ) : (
          <StyledProgress>
            <StyledProgressLine active={active || done} />
          </StyledProgress>
        )}
        <StyledModalSection last={last}>
          {active || done ? children : null}
        </StyledModalSection>
      </StyledWizardBody>
    </>
  );
};

Section.propTypes = {
  ...implicitSectionPropTypes,
  title: PropTypes.string,
  children: PropTypes.any,
};

export default Section;
