import useAsyncFn from 'react-use/lib/useAsyncFn';

const useFormSubmit = (fn, deps = [fn]) => {
  const [requestState, fetch] = useAsyncFn(async (values) => {
    const data = await fn(values);
    return data;
  }, deps);

  return [requestState, fetch];
};

export default useFormSubmit;
