import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { layers } from 'app/spacing';
import Loader from 'components/Kizen/Loader';

const CHART_BOTTOM_OFFSET = 70;
const CHART_RIGHT_OFFSET = 35;
const AXIS_LABEL_ADDS = 12;

export const Chart = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

export const ChartOverlay = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 100ms,
    opacity 100ms linear;
  width: 0;
  right: ${CHART_RIGHT_OFFSET}px;
  top: 0;
  bottom: ${({ short }) =>
    CHART_BOTTOM_OFFSET - (short ? AXIS_LABEL_ADDS : 0)}px;
  background: linear-gradient(
    to ${({ flip }) => (flip ? 'left' : 'right')},
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0) 50px
  );
  ${({ noBorder }) =>
    noBorder
      ? ''
      : css`
          border-bottom: 1px solid ${grayScale.mediumLight};
        `}
  pointer-events: none;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 0 !important;
  background-color: ${grayScale.white};
  z-index: ${layers.content(10)};

  & > div {
    margin-top: 10%;
    margin-right: 10px;
  }
`;
