import styled from '@emotion/styled';
import {
  gutters,
  borderRadii,
  breakpoints,
  hideScrollbarCss,
} from 'app/spacing';
import { grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import BasicModal from 'components/Modals/presets/BasicModal';
import { _modalSize } from 'components/Modals';

export const StyledModal = styled(BasicModal)`
  @media screen and (max-width: ${breakpoints.sm}px) {
    && .modal-body {
      overflow: hidden;
      padding-right: 0;
    }
    & .modal-footer {
      padding-top: ${gutters.spacing(2)}px;
    }
  }
`;

export const FileLibraryModalHeader = styled(KizenTypography)`
  text-align: center;
  width: 100%;
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    min-width: 100px;
    max-width: 155px;
    flex: 1;
    flex-shrink: 0;
  }
`;

export const FileLibraryModalHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  margin-bottom: ${gutters.spacing(2)}px;
  @media screen and (max-width: ${_modalSize.large - 1}px) {
    justify-content: center;
  }
`;
export const SearchInputWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 250px;
  margin-left: auto;
  flex-shrink: 0;
  @media screen and (max-width: ${_modalSize.large - 1}px) {
    position: static;
    width: 175px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    padding-right: ${gutters.spacing(3)}px;
    width: 150px;
  }
`;
export const SearchPillWrapper = styled(SearchInputWrapper)`
  width: 20px;
  margin-right: 4px;
`;

export const SearchWrapper = styled(SearchInputWrapper)`
  width: auto;
  right: 17px;
  margin-right: 16px;
  margin-left: 19px;
`;

export const FileLibraryDetailsWrapper = styled.div`
  border-radius: ${borderRadii.standard};
  border: solid 1px ${grayScale.medium};
  display: flex;
  flex-direction: row;
  padding: ${gutters.spacing(4)}px 0 ${gutters.spacing(4)}px
    ${gutters.spacing(4)}px;
  width: 100%;
  height: 100%;
  @media screen and (max-width: ${breakpoints.sm}px) {
    border-radius: 0;
    border: none;
    padding: 0;
  }
`;

const StyledColumn = styled.div`
  display: flex;
  width: 100%;
  height: calc(
    ${({ windowHeight }) => (windowHeight ? windowHeight + 'px' : '100vh')} -
      332px
  );
  @media screen and (max-width: ${breakpoints.sm}px) {
    height: calc(
      ${({ windowHeight }) => (windowHeight ? windowHeight + 'px' : '100vh')} -
        280px
    );
  }
`;

export const LeftColumn = styled(StyledColumn)`
  flex: 0 0 ${({ isVisible }) => (isVisible ? 260 : 0)}px;
  flex-direction: column;
  min-width: 1px;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.sm}px) {
    min-width: 100px;
    max-width: 155px;
    flex: initial;
  }
`;

export const Spacer = styled.div`
  display: flex;
  flex-shrink: 0;
  width: ${gutters.spacing(4)}px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: ${gutters.spacing(2)}px;
  }
`;

export const RightColumn = styled(StyledColumn)`
  min-width: 165px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    min-width: unset;
  }
`;

export const FilesScrollContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  width: calc(100% - ${gutters.spacing(1, 3)}px);
  overflow-y: auto;
  height: 100%;
`;

export const StyledFilesList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledScrollBarDetached = styled(ScrollBarDetached)``;

export const ClipLoaderWrapper = styled.div`
  position: absolute;
  top: 40%;
  right: 40%;
`;
