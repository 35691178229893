import { comp, map, reducer, scan } from '@thi.ng/transducers';
import {
  Access,
  AccessStruct,
  PermissionGroup,
  PermissionResultData,
  Section,
  StreamValue,
} from './types';

const requiresFullSectionData = (section_id: Section) => {
  return (
    section_id === 'scheduled_activities_section' ||
    section_id === 'custom_objects_section' ||
    section_id === 'smart_connectors_section'
  );
};

const getPayloadValues = (
  value: StreamValue['value'],
  allowed_access?: Access[]
) => {
  if (typeof value === 'boolean') return value;
  if (!allowed_access) return { view: false, edit: false, remove: false };
  const res: Partial<Exclude<AccessStruct, 'none'>> = {};

  if (allowed_access.includes('remove')) {
    res.remove = value > 2;
    res.edit = value > 1;
    res.view = value > 0;
    return res;
  }
  if (allowed_access.includes('edit')) {
    res.edit = value > 1;
    res.view = value > 0;
    return res;
  }
  if (allowed_access.includes('view')) res.view = value > 0;
  return res;
};

const initCustomObject = (acc: Record<string, any>, id: string) => {
  acc.custom_objects[id] = acc.custom_objects[id] || {
    custom_object_id: id,
    enabled: true,
  };
  return acc;
};

const initContactSection = (acc: Record<string, any>) => {
  acc.contacts_section = acc.contacts_section || {};
  acc.contacts_section.custom_fields = acc.contacts_section.custom_fields || {};
  acc.contacts_section.default_fields =
    acc.contacts_section.default_fields || {};
  return acc;
};

const collectContactField = (
  acc: any,
  field_id: string,
  value: PermissionResultData['value'],
  initial: PermissionResultData['initial']
) => {
  acc = initContactSection(acc);
  const values = getPayloadValues(value, initial?.allowed_access);
  acc.contacts_section.custom_fields[field_id] =
    typeof values === 'object' ? { id: field_id, ...values } : { id: field_id };
  return acc;
};

const collectDefaultContactField = (
  acc: any,
  field_name: string,
  value: PermissionResultData['value'],
  initial: PermissionResultData['initial']
) => {
  acc = initContactSection(acc);
  const payload = getPayloadValues(value, initial?.allowed_access);
  acc.contacts_section.default_fields[field_name] = payload;
  if (field_name === 'first_name') {
    // there is only one permission setting for name, but the api requires
    // both first_name and last_name in the request
    acc.contacts_section.default_fields.last_name = payload;
  }
  return acc;
};

const collectField = (
  acc: any,
  co_id: string,
  field_id: string,
  value: PermissionResultData['value'],
  initial: PermissionResultData['initial']
) => {
  acc = initCustomObject(acc, co_id);
  const values = getPayloadValues(value, initial?.allowed_access);
  acc.custom_objects[co_id].fields = acc.custom_objects[co_id].fields || {};
  acc.custom_objects[co_id].fields[field_id] =
    typeof values === 'object' ? { id: field_id, ...values } : { id: field_id };
  return acc;
};

const collectCustomObjectPermission = (
  acc: any,
  co_id: string,
  permission_id: string,
  value: PermissionResultData['value'],
  initial: PermissionResultData['initial']
) => {
  acc = initCustomObject(acc, co_id);
  acc.custom_objects[co_id][permission_id] = getPayloadValues(
    value,
    initial?.allowed_access
  );
  return acc;
};

const collectCustomObjectEnabled = (
  acc: any,
  co_id: string,
  value: PermissionResultData['value']
) => {
  initCustomObject(acc, co_id);
  if (!value) {
    acc.custom_objects[co_id] = {
      custom_object_id: co_id,
      enabled: false,
    };
  } else {
    acc.custom_objects[co_id].enabled = value;
  }
  return acc;
};

const collectSectionPermission = (
  acc: any,
  section_id: Section,
  permission_id: string,
  value: PermissionResultData['value'],
  initial: PermissionResultData['initial'],
  permissionGroup: PermissionGroup
) => {
  const init = requiresFullSectionData(section_id)
    ? { ...permissionGroup?.[section_id], enabled: true }
    : { enabled: true };
  acc[section_id] = acc[section_id] || init;
  acc[section_id][permission_id] = getPayloadValues(
    value,
    initial?.allowed_access
  );
  return acc;
};

/**
 * A @thi.ng/transducers {@link https://github.com/thi-ng/umbrella/tree/develop/packages/transducers#reducer reducer} meant
 * to be supplied to the {@link https://github.com/thi-ng/umbrella/tree/develop/packages/transducers#scan-operator scan} transducer
 * in the first processing step of the permission context's `update` merge stream. A Permission is created with (optional) metadata
 * properties (`custom_object_id`, `field_id`, `section_id`, `permission_id`, `is_default`, `name) and these values are supplied in the the Permission's
 * final `value` stream for use here. This reducer builds out an object based on those metadata properties that is (nearly) ready
 * to be sent to the api. Objects that are indexed by id here (custom objects, fields) need to be turned into arrays - see ({@link mapCollectedUpdates});
 */
export const collectUpdatesReducer = (permissionGroup: PermissionGroup) =>
  reducer<Record<string, any>, any>(
    () => ({ custom_objects: {} }),
    (acc: Record<string, any>, data: PermissionResultData) => {
      const {
        custom_object_id,
        field_id,
        section_id,
        permission_id,
        value,
        initial,
        is_default,
        name,
      } = data;

      if (custom_object_id === 'contacts_section' && field_id) {
        return is_default
          ? collectDefaultContactField(acc, name!, value, initial)
          : collectContactField(acc, field_id, value, initial);
      }

      if (custom_object_id && field_id) {
        return collectField(acc, custom_object_id, field_id, value, initial);
      }

      if (custom_object_id && permission_id) {
        return collectCustomObjectPermission(
          acc,
          custom_object_id,
          permission_id,
          value,
          initial
        );
      }

      if (custom_object_id) {
        return collectCustomObjectEnabled(acc, custom_object_id, value);
      }

      if (section_id && permission_id) {
        return collectSectionPermission(
          acc,
          section_id,
          permission_id,
          value,
          initial,
          permissionGroup
        );
      }

      if (section_id) {
        acc[section_id] = { enabled: Boolean(value) };
      }

      return acc;
    }
  );

/**
 * Takes the output of the {@link collectUpdatesReducer} and converts the following to arrays:
 *  - `contacts_section.custom_fields`
 *  - `custom_objects`
 *  - `custom_objects.fields`
 */
const mapCollectedUpdates = map((collected: Record<string, any>) => {
  const { contacts_section, custom_objects, ...rest } = collected;
  const result: any = {};

  if (Object.keys(custom_objects).length > 0) {
    result.custom_objects = Object.values(collected.custom_objects).map(
      (co: any) => {
        return co.fields ? { ...co, fields: Object.values(co.fields) } : co;
      }
    );
  }

  if (contacts_section) {
    result.contacts_section = contacts_section.custom_fields
      ? {
          ...contacts_section,
          custom_fields: Object.values(contacts_section.custom_fields),
        }
      : contacts_section;
  }

  return { ...result, ...rest };
});

/**
 * Remove any permission values for sections that are not enabled.
 */
const clearDisabledSections = map((payload: Record<string, any>) => {
  return Object.entries(payload).reduce<Record<string, any>>(
    (acc, [id, data]) => {
      if (id === 'custom_objects') {
        acc[id] = data.map((d: any) => {
          return d.enabled
            ? d
            : { custom_object_id: d.custom_object_id, enabled: false };
        });
      } else {
        acc[id] = data.enabled ? data : { enabled: false };
      }
      return acc;
    },
    {}
  );
});

/**
 * A {@link https://github.com/thi-ng/umbrella/tree/develop/packages/transducers#transducer transducer} meant to transform
 * the permission context's `updates` merge stream to produce the final payload object to be sent to to the api.
 */
export const payloadXform = (permissionGroup: any) =>
  comp(
    scan(collectUpdatesReducer(permissionGroup)),
    mapCollectedUpdates,
    clearDisabledSections
  );
