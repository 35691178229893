import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonRadio } from 'ts-components/ButtonToggle';
import { colorsButton, grayScale } from '__app/colors';
import { KizenTypography } from '__app/typography';
import AddButton from '__components/Button/AddButton';
import Icon, { IconSizing } from '__components/Kizen/Icon';
import IconButton from '__components/Kizen/IconButton';
import SelectWithActivator from '__components/Kizen/Select/WithActivator';

type AddFilterButtonProps = {
  label?: string;
  onClick(): void;
};

type FilterSetContainerProps = {
  disabled?: boolean;
  showBorder: boolean;
  value: 'any' | 'all';
  onChange(x: FilterSetContainerProps['value']): void;
};

type FilterOperationSelectorProps = {
  value: 'and' | 'or';
  onChange(value: 'and' | 'or'): void;
};

const withBorder = css`
  border: 1px solid ${grayScale.medium};
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const withoutBorder = css`
  margin-top: 0;
  padding-top: 0;
  padding-right: 0;
`;

const FilterSetWrapper = styled.div<
  Pick<FilterSetContainerProps, 'showBorder'>
>`
  position: relative;
  padding: 15px;
  transition:
    margin-top 0.3s ease-in-out,
    margin-bottom 0.3s ease-in-out;

  ${(props) => (props.showBorder ? withBorder : withoutBorder)}
`;

const ButtonToggle = styled(ButtonRadio)`
  position: absolute;
  top: -14px;
  left: calc(50% - 45px);
  letter-spacing: 0.8px;
`;

export const FilterRow = styled.div<{
  marginTop?: number;
  enableLeftPadding?: boolean;
}>`
  display: flex;
  row-gap: 15px;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-bottom: 5px;

  :last-of-type {
    padding-bottom: 0;
  }

  ${({ marginTop = 15 }) => `margin-top: ${marginTop}px;`}
  ${({ enableLeftPadding = true }) => enableLeftPadding && `margin-left: 28px;`}
`;

const TrashIcon = styled(Icon)`
  && svg {
    width: auto;
    height: 15px;
  }
`;

export const FilterSetContainer = ({
  // disabled = false,
  showBorder,
  value,
  onChange,
  children,
  ...rest
}: PropsWithChildren<FilterSetContainerProps>) => {
  const { t } = useTranslation();

  return (
    <FilterSetWrapper showBorder={showBorder} data-qa="filter-set" {...rest}>
      {showBorder && (
        <ButtonToggle
          variant="outline"
          highlightBackground={true}
          color={colorsButton.blue.hover}
          selectedColor={grayScale.white}
          fontColor={colorsButton.blue.hover}
          hoverColor={false}
          value={value}
          onChange={onChange}
          optionWidth="45px"
          height="28px"
          fontSize="buttonLabel"
          textTransform="uppercase"
          bold={true}
          borderRadius="standard"
          // disabled={disabled}
          options={[
            { label: t('Any'), value: 'any' },
            { label: t('All'), value: 'all' },
          ]}
        />
      )}
      {children}
    </FilterSetWrapper>
  );
};

export const DeleteRowButton = ({ onClick, ...rest }: { onClick?(): void }) => (
  <IconButton
    sizing="dense"
    color={colorsButton.iconGray}
    onClick={onClick}
    {...rest}
  >
    <TrashIcon icon="delete" />
  </IconButton>
);

const OperatorText = styled(KizenTypography)`
  align-self: flex-end;
  margin-left: 15px;
  margin-bottom: 11px;
`;

export const FilterOperatorText = ({ and = false }) => {
  const { t } = useTranslation();

  return (
    <OperatorText weight="bold" size="buttonLabel">
      {and ? t('AND') : t('OR')}
    </OperatorText>
  );
};

const StyledAddButton = styled(AddButton)`
  margin-left: 15px;
`;

export const AddFilterButton = ({
  label,
  onClick,
  ...rest
}: AddFilterButtonProps) => {
  const { t } = useTranslation();

  return (
    <StyledAddButton
      noSpace
      variant="text"
      color="blue"
      onClick={onClick}
      {...rest}
    >
      {label || t('Add Filter')}
    </StyledAddButton>
  );
};

const OperationSelector = styled(SelectWithActivator)`
  margin: 0;

  button {
    padding: 0;
    padding-left: 10px;
    font-size: unset;
  }

  i {
    margin-left: 5px;
  }
`;

export const FilterOperationSelector = ({
  value,
  onChange,
}: FilterOperationSelectorProps) => {
  const { t } = useTranslation();

  return (
    <OperationSelector
      value={value}
      onChange={({ value }: any) => onChange(value)}
      variant="text"
      color="iconGray"
      options={[
        { value: 'and', label: t('And') },
        { value: 'or', label: t('Or') },
      ]}
    >
      <KizenTypography
        as="span"
        type="text"
        weight="bold"
        textTransform="uppercase"
      >
        {value === 'or' ? t('OR') : t('AND')}
      </KizenTypography>
      <IconSizing size="13px">
        <Icon icon="chevron-down" color={grayScale.mediumDark} />
      </IconSizing>
    </OperationSelector>
  );
};
