import styled from '@emotion/styled';
import { layers } from '__app/spacing';
import Input from '__components/Kizen/Input';
import { gutters } from 'app/spacing';
import ErrorCard from '__components/Kizen/ErrorCard';
import TimePicker from 'components/Kizen/TimePicker';
import { grayScale } from '__app/colors';
import css from '@emotion/css';

export const StyledFormInput = styled(Input)`
  &.first-row {
    margin-top: 0;
  }
  margin-top: ${gutters.spacing(4, { baseline: true })}px;
`;

export const ModalErrorCard = styled(ErrorCard)`
  top: 18px !important;
  z-index: ${layers.modals(1, 4)};
`;

export const TimePickerMenu = styled(TimePicker)`
  > :first-child {
    width: unset;
    margin-top: 0;
  }
  .TimePicker__control {
    // By not setting this to display: none we can keep
    // the autocomplete and keyboard functionality!
    position: absolute;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
    width: 0;
    min-height: 0;
    outline: none;
    border: none;
    overflow: hidden;
  }

  #dropdown-menu {
    width: 155px;
    border-radius: 4px;
    border: 1px solid ${grayScale.medium};
    margin-top: 4px;

    ${({ direction }) =>
      direction === 'up'
        ? css`
            bottom: 100%;
            top: unset;
          `
        : ''}
  }
`;
