import { useNavBarState } from 'app/navBarController';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COLUMNS,
  getDropId,
  mutateDestinationColumn,
  mutateItem,
  mutateOriginColumn,
} from '../utils';
import useLeftColumn from './useLeftColumn';
import useRightColumn from './useRightColumn';
import { useModalControl } from 'hooks/useModalControl';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { useSelector } from 'react-redux';
import useCreateNewTemplate from './useCreateNewTemplate';
import useLoadTemplate from './useLoadTemplate';
import { useLocation, useHistory } from 'react-router-dom';
import { DEFAULT_TEMPLATE_ID } from 'components/Modals/ApplyTemplateModal';
import { deferExecution } from 'utility/defer';
import useMultiColumnBuilder from 'components/MultiColumnBuilder/useMultiColumnBuilder';
import ParentDropValidator from 'pages/Account/pages/Toolbar/parentDropValidator';
import ChildDropValidator from 'pages/Account/pages/Toolbar/childDropValidator';

const selfDisabledDropZones = [COLUMNS.FIND_OPTIONS];

const useToolbarBuilder = (existingId, onError) => {
  const { t } = useTranslation();
  const previewFlagEnabled = usePreReleaseFeatures();
  const { height: navBarHeight } = useNavBarState();
  const [optionsSearchTerm, setOptionsSearchTerm] = useState('');
  const [editingItem, setEditingItem] = useState(null);
  const [collapsedItems, setCollapsedItems] = useState([]);
  const modalOptions = useRef({});
  const modalIsCreateMode = useRef(false);
  const access = useSelector(({ authentication }) => authentication.access);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const applyingTemplate = useRef(false);

  const [urlModalOpen, { showModal: showUrlModal, hideModal: hideUrlModal }] =
    useModalControl();
  const [
    recentItemsModalOpen,
    { showModal: showRecentItemsModal, hideModal: hideRecentItemsModal },
  ] = useModalControl();

  const collapseItem = useCallback((itemId) => {
    setCollapsedItems((curr) => [...curr, itemId]);
  }, []);

  const expandItem = useCallback((itemId) => {
    setCollapsedItems((curr) => {
      const res = [...curr];
      const idx = curr.indexOf(itemId);

      if (idx >= 0) {
        res.splice(idx, 1);
      }

      return res;
    });
  }, []);

  const {
    map: rightColumnMap,
    items: rightColumnItems,
    setItems: setRightColumnItems,
    commit,
    commitAsync,
    pending,
    loading: rightColumnLoading,
    reset,
    dirty,
    setDirty,
    setDirtyStates,
    commitTemplate,
    commitTemplateAsync,
    clearLocalState,
  } = useRightColumn(existingId, {
    onSave: () => {
      setDirty(false);
    },
    onSaveTemplate: () => {
      setDirty(false);
    },
    onError,
  });

  const { modalProps: sharingModalProps, handleClickSaveTemplate } =
    useCreateNewTemplate(rightColumnItems);

  const {
    modalProps: chooseTemplateModalProps,
    handleClickChooseTemplate,
    loadTemplate,
    pending: loadTemplatePending,
  } = useLoadTemplate(setRightColumnItems, setDirty, reset);

  const applyTemplateId = useMemo(() => {
    const p = new URLSearchParams(search);
    return p.get('apply_template');
  }, [search]);

  useEffect(() => {
    if (applyTemplateId && !applyingTemplate.current) {
      if (applyTemplateId === DEFAULT_TEMPLATE_ID) {
        history.replace(pathname);
        reset();
      } else {
        applyingTemplate.current = true;
        history.replace(pathname);
        loadTemplate(applyTemplateId).finally(() => {
          applyingTemplate.current = false;
        });
      }
    }
  }, [applyTemplateId, pathname, history, loadTemplate, reset]);

  const {
    items: leftColumnItems,
    setItems: setLeftColumnItems,
    loading: leftColumnLoading,
    itemsOrdering,
  } = useLeftColumn(rightColumnMap, optionsSearchTerm);

  const editItemOptions = useCallback(
    (parentIdx, childIdx, item) => {
      modalIsCreateMode.current = false;
      modalOptions.current = { parentIdx, childIdx, item };
      if (item.dynamicallyPopulated) {
        showRecentItemsModal();
      } else {
        showUrlModal();
      }
    },
    [showUrlModal, showRecentItemsModal]
  );

  const {
    endDrag,
    throttleGetFieldDropzone,
    throttleGetChildFieldDropzone,
    leftColumnProps,
    rightColumnProps,
    leftContainerProps,
    leftHeaderProps,
    rightContainerProps,
    rightHeaderProps,
    moveItemToBottom,
    removeItem,
  } = useMultiColumnBuilder({
    leftColumn: COLUMNS.FIND_OPTIONS,
    rightColumn: COLUMNS.YOUR_TOOLBAR,
    setDirtyStates,
    mutateOriginColumn,
    setLeftColumnItems,
    setRightColumnItems,
    mutateDestinationColumn,
    getDropId,
    handlePopup: (parentIdx, childIdx, item) => {
      // Queue the callback but let the drop finish first with deferExecution
      if (item.dynamicallyPopulated) {
        deferExecution(() => {
          modalIsCreateMode.current = true;
          modalOptions.current = { parentIdx, childIdx, item };
          showRecentItemsModal();
        });
      } else {
        deferExecution(() => {
          modalIsCreateMode.current = true;
          modalOptions.current = { parentIdx, childIdx, item };
          showUrlModal();
        });
      }
    },
    leftColumnItems,
    rightColumnItems,
    ParentDropValidator,
    ChildDropValidator,
    disableLeftColumnReorder: true,
    selfDisabledDropZones,
    itemsOrdering,
  });

  const handleCancelUrlModal = useCallback(
    (createMode) => {
      hideUrlModal();
      if (createMode) {
        removeItem(
          modalOptions.current.parentIdx,
          modalOptions.current.childIdx,
          false
        );
      }
    },
    [hideUrlModal, removeItem]
  );

  const handleCancelRecentItemsModal = useCallback(
    (createMode) => {
      hideRecentItemsModal();
      if (createMode) {
        removeItem(
          modalOptions.current.parentIdx,
          modalOptions.current.childIdx,
          false
        );
      }
    },
    [hideRecentItemsModal, removeItem]
  );

  const editValue = useCallback(
    (parentIndex, childIndex, field, value) => {
      if (parentIndex > -1) {
        setDirtyStates(true);

        return mutateItem(
          setRightColumnItems,
          parentIndex,
          childIndex,
          field,
          value
        );
      }
    },
    [setRightColumnItems, setDirtyStates]
  );

  const editLeftColumnTempValue = useCallback(
    (index, childIndex, field, value, lookupId) => {
      return mutateItem(
        setLeftColumnItems,
        index,
        childIndex,
        field,
        value,
        lookupId
      );
    },
    [setLeftColumnItems]
  );

  const onConfirmUrl = useCallback(
    ({ parentIdx, childIdx }, displayName, url) => {
      editValue(parentIdx, childIdx, 'displayName', displayName);
      editValue(parentIdx, childIdx, 'url', url);
      hideUrlModal();
    },
    [hideUrlModal, editValue]
  );

  const onConfirmRecentItems = useCallback(
    ({ parentIdx, childIdx }, displayName, config) => {
      editValue(parentIdx, childIdx, 'displayName', displayName);
      editValue(parentIdx, childIdx, 'config', config);
      hideRecentItemsModal();
    },
    [hideRecentItemsModal, editValue]
  );

  return {
    newURLModalProps: {
      modalOpen: urlModalOpen,
      onHide: handleCancelUrlModal,
      onConfirm: onConfirmUrl,
      options: modalOptions.current,
      create: modalIsCreateMode.current,
    },
    recentItemsModalProps: {
      modalOpen: recentItemsModalOpen,
      onHide: handleCancelRecentItemsModal,
      onConfirm: onConfirmRecentItems,
      options: modalOptions.current,
      create: modalIsCreateMode.current,
    },
    confirmNavigationModalProps: {
      heading: t('Your Toolbar Has Unsaved Changes'),
      when: dirty,
      buttonText: t('Save'),
      actionBtnColor: 'green',
      additionalButtonText: t('Discard Changes'),
      additionalButtonColor: 'red',
      action: existingId ? commitTemplateAsync : commitAsync,
      children: t(
        'All unsaved changes will be lost unless you save your toolbar.'
      ),
      onAdditionalConfirm: clearLocalState,
    },
    navBarHeight,
    columnsLoading: leftColumnLoading || rightColumnLoading,
    previewFeatures: previewFlagEnabled,
    reset,
    pending,
    handleClickSaveTemplate,
    handleClickChooseTemplate,
    commit,
    dirty,
    builderProps: {
      rightColumnItems,
      access,
      optionsSearchTerm,
      setOptionsSearchTerm,
      leftColumnBodyProps: leftContainerProps,
      leftColumnHeaderProps: leftHeaderProps,
      leftColumnProps: {
        id: 'find-options',
        options: leftColumnItems,
        endDrag: endDrag,
        getFieldDropZone: throttleGetFieldDropzone,
        editValue: editLeftColumnTempValue,
        editingItem: editingItem,
        setEditingItem: setEditingItem,
        ...leftColumnProps,
      },
      rightColumnBodyProps: rightContainerProps,
      rightColumnHeaderProps: rightHeaderProps,
      rightColumnProps: {
        id: 'your-toolbar',
        options: rightColumnItems,
        endDrag: endDrag,
        getFieldDropZone: throttleGetFieldDropzone,
        getChildDropZone: throttleGetChildFieldDropzone,
        editValue: editValue,
        editingItem: editingItem,
        setEditingItem: setEditingItem,
        editable: true,
        removeItem: removeItem,
        editOptions: editItemOptions,
        collapseItem: collapseItem,
        expandItem: expandItem,
        collapsedItems: collapsedItems,
        moveItemToBottom: moveItemToBottom,
        ...rightColumnProps,
      },
    },
    templateToolbarProps: {
      commit: commitTemplate,
      commitAsync: commitTemplateAsync,
      dirty,
    },
    sharingModalProps,
    chooseTemplateModalProps,
    loadTemplatePending,
  };
};

export default useToolbarBuilder;
