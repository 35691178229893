import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useTranslation } from 'react-i18next';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import Input from 'components/Kizen/Input';
import { gutters } from 'app/spacing';
import { DASHBOARD } from 'queries/query-keys';
import { useQuery } from 'react-query';
import PermissionSettings from 'components/Sharing/PermissionSettings';
import { getDashboardQuery } from 'queries/dashboard';
import Validation from 'components/Inputs/Validation';
import { useFlashTransition } from 'hooks/useFlashState';
import useModal from 'components/Modals/useModal';
import Button from 'components/Button';
import Switch from 'components/Kizen/Switch';
import useField from 'hooks/useField';

const CreateLayoutModal = ({
  existing = {},
  onConfirm,
  canUserEditPermissions = true,
  entity = '',
  small,
  isDuplication,
  errors,
  blockHideIfDirty = false,
  onHide,
  showPrivateToggle = true,
  ...others
}) => {
  const { t } = useTranslation();
  const [dashboardName, setDashboardName] = useState('');
  const [permissions, setPermissions] = useState();
  const [dirty, setDirty] = useState(false);
  const isEditing = useMemo(() => {
    return Object.keys(existing).length > 0;
  }, [existing]);

  useEffect(() => {
    if (existing.name) {
      if (isDuplication) {
        setDashboardName(`${existing.name} ${t('(copy)')}`);
      } else {
        setDashboardName(existing.name);
      }
    }
  }, [existing.name, isEditing, isDuplication, t]);

  const setDashboardNameWithDirtyState = useCallback((name) => {
    setDashboardName(name);
    setDirty(true);
  }, []);

  const setDashboardPermissionsWithDirtyState = useCallback(
    (permissions, dirty) => {
      setPermissions(permissions);
      if (dirty) {
        setDirty(true);
      }
    },
    []
  );

  const dashboardQueryKey = useMemo(() => {
    return [...DASHBOARD.DASHBOARD, existing.id];
  }, [existing]);

  const handleFetchDashboard = useCallback(
    () => getDashboardQuery(existing.id),
    [existing.id]
  );

  const {
    data: dashboardData,
    isLoading: dashboardLoading,
    isRefetching: dashboardRefetching,
  } = useQuery(dashboardQueryKey, handleFetchDashboard, {
    enabled: !!existing.id,
  });

  const [isPrivate, setIsPrivate] = useField(
    dashboardData?.sharing_settings?.private ?? true
  );

  const setIsPrivateWithDirtyState = useCallback(
    (event) => {
      setIsPrivate(event.target.checked);
      setDirty(true);
    },
    [setIsPrivate]
  );

  const handleConfirm = useCallback(() => {
    if (dashboardName) {
      onConfirm?.(
        { name: dashboardName, permissions, isPrivate },
        isEditing,
        existing.id,
        isDuplication
      );
    }
  }, [
    dashboardName,
    onConfirm,
    isEditing,
    existing,
    permissions,
    isDuplication,
    isPrivate,
  ]);

  const heading = useMemo(() => {
    if (isDuplication) {
      return `${t('Duplicate')} ${entity}`;
    }
    return isEditing ? `${t('Edit')} ${entity}` : `${t('Add')} ${entity}`;
  }, [entity, t, isDuplication, isEditing]);

  const nameInputRef = useRef(null);

  const [message, showMessage, flashErrorMessage] = useFlashTransition();

  useEffect(() => {
    if (errors?.name) {
      flashErrorMessage(errors.name);
    }
  }, [errors?.name, flashErrorMessage]);

  const [confirmUnsavedModalProps, confirmUnsavedModalTriggerProps] = useModal({
    handleSubmit: handleConfirm,
  });

  const handleCloseWithDirtyState = useCallback(() => {
    if (!dirty || !blockHideIfDirty) {
      onHide();
    } else {
      confirmUnsavedModalTriggerProps.onClick();
    }
  }, [dirty, onHide, blockHideIfDirty, confirmUnsavedModalTriggerProps]);

  return (
    <>
      <BasicModal
        defaultLeftBtnText={t('Cancel')}
        heading={heading}
        size={small ? 'small' : 'medium'}
        disabled={!dashboardName?.trim()}
        buttonText={isDuplication ? t('Duplicate') : t('Save')}
        onConfirm={handleConfirm}
        onHide={handleCloseWithDirtyState}
        {...others}
      >
        <StyledWizardField top={0} flex>
          <KizenTypography>{`${entity} ${t('Name')}`}</KizenTypography>
          <StyledWizardField top={gutters.spacing(2, 2)} flex>
            <Input
              value={dashboardName}
              onChange={setDashboardNameWithDirtyState}
              placeholder={`${entity} ${t('Name')}`}
              error={errors?.name}
              ref={nameInputRef}
            />
            <Validation
              message={message}
              showMessage={showMessage}
              target={nameInputRef.current}
            />
          </StyledWizardField>
        </StyledWizardField>
        {!dashboardLoading && showPrivateToggle && canUserEditPermissions ? (
          <StyledWizardField top={20}>
            <Switch
              data-qa="make-private-toggle"
              checked={isPrivate}
              textPlacement="regular-top"
              label={t('Make Private')}
              tooltip={t(
                'Users will not be able to search for and request access to this {{instanceName}} unless granted View access or greater.',
                { instanceName: entity }
              )}
              onClick={setIsPrivateWithDirtyState}
            />
          </StyledWizardField>
        ) : null}
        {canUserEditPermissions ? (
          <PermissionSettings
            onChange={setDashboardPermissionsWithDirtyState}
            existing={dashboardData?.sharing_settings ?? {}}
            loading={dashboardLoading || dashboardRefetching}
            exclusive={false}
            owner={existing.owner}
            small={small}
            isDuplication={isDuplication}
          />
        ) : null}
      </BasicModal>
      <BasicModal
        className={`no-drag`}
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        leftBtn={<Button variant="text" color="blue" />}
        {...confirmUnsavedModalProps}
        onConfirm={() => {
          onHide();
        }}
        defaultLeftBtnHandler={confirmUnsavedModalTriggerProps.hide}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </BasicModal>
    </>
  );
};

export default CreateLayoutModal;
