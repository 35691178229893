import Select from 'components/Inputs/Select';
import { ApplyMenuButton, ClearMenuButton } from 'components/Kizen/Menu';
import useField from 'hooks/useField';
import { useTranslation } from 'react-i18next';

export const PillSingleselect = ({
  onChange,
  onHide,
  value,
  isReadOnly,
  ...props
}) => {
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = useField(value);
  const handleClickApply = () => {
    onHide();
    onChange(innerValue);
  };

  const handleClickClear = () => {
    onHide();
    onChange();
  };
  const handleChange = (val) => setInnerValue(val);

  return (
    <Select
      {...props}
      value={innerValue}
      onChange={handleChange}
      isReadOnly={isReadOnly}
      disabled={isReadOnly}
      menuIsOpen={!isReadOnly}
      menuLeftButton={
        <ClearMenuButton onClick={handleClickClear}>
          {t('Clear & Close')}
        </ClearMenuButton>
      }
      menuRightButton={<ApplyMenuButton onClick={handleClickApply} />}
      hideRemoveIcon={isReadOnly}
    />
  );
};
