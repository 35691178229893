import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import { colorsButton, grayScale, shadowLight } from 'app/colors';
import { borderRadii } from 'app/spacing';
import { fontSizes } from 'app/typography';
import Button from 'components/Button';
import BaseList from '../List/List';
import ListItem from '../List/ListItem';

const MenuWrapper = styled.div`
  display: flex;

  flex-direction: column;
  ${shadowLight}
  border-radius: ${borderRadii.small};
  border: 1px solid ${grayScale.medium};
  background-color: ${grayScale.white};
  ${({ inline }) =>
    // TODO possibly should go in separate wrapper
    inline &&
    css`
      && {
        // Boosted precedence since this is a convenience for consumers
        // adding their own default styling on top, e.g. Select.
        position: relative;
        box-shadow: none;
      }
    `}
  ${({ placement }) =>
    placement === 'top' &&
    css`
      clip-path: inset(-26px -26px 0px -26px);
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
  ${({ placement }) =>
    placement === 'bottom' &&
    css`
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
`;

export const MenuList = styled(BaseList)`
  flex: 1;
  overflow: auto;
`;

const ActionBar = styled(ListItem)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  ${({ invert }) => {
    const borderPlacement = invert ? 'bottom' : 'top';
    return css`
      border-${borderPlacement}: 1px solid ${grayScale.medium};
    `;
  }}
  cursor: default;
  font-size: inherit;
  &:hover {
    background-color: unset;
  }
`;

const Menu = React.forwardRef((props, ref) => {
  const {
    topButton,
    leftButton,
    rightButton,
    actionBar,
    inline,
    placement,
    invert: invertProp,
    children,
    ...others
  } = props;
  const invert =
    typeof invertProp === 'boolean' ? invertProp : placement === 'top';
  const hasBottomButtons = leftButton || rightButton;
  const actionBarElement = (leftButton || rightButton || actionBar) && (
    <ActionBar as="div" invert={invert}>
      {leftButton || (hasBottomButtons && <div />)}
      {actionBar}
      {rightButton || (hasBottomButtons && <div />)}
    </ActionBar>
  );
  return (
    <MenuWrapper
      id={'dropdown-menu'}
      data-qa="dropdown-menu-wrapper"
      ref={ref}
      inline={inline}
      placement={placement}
      {...others}
    >
      {invert ? actionBarElement : topButton}
      {children /* Typically but not always a <List /> */}
      {invert ? topButton : actionBarElement}
    </MenuWrapper>
  );
});

Menu.displayName = 'Menu';

Menu.propTypes = {
  topButton: PropTypes.node,
  leftButton: PropTypes.node,
  rightButton: PropTypes.node,
  actionBar: PropTypes.node,
  inline: PropTypes.bool,
  placement: PropTypes.oneOf([null, 'bottom', 'top']), // Empty is another important value
  invert: PropTypes.bool,
};

Menu.defaultProps = {
  topButton: null,
  leftButton: null,
  rightButton: null,
  actionBar: null,
  inline: false,
  placement: null,
  invert: false,
};

export default Menu;

export const BottomMenuButton = styled((props) => {
  return <Button noSpace variant="text" {...props} />;
})`
  height: unset;
  font-size: ${fontSizes.small};
  line-height: 1;
`;

export function ApplyMenuButton({ children, ...props }) {
  const { t } = useTranslation();
  return (
    <BottomMenuButton {...props}>{children || t('Apply')}</BottomMenuButton>
  );
}

export function ClearMenuButton({ children, ...props }) {
  const { t } = useTranslation();
  return (
    <BottomMenuButton color="red" {...props}>
      {children || t('Clear')}
    </BottomMenuButton>
  );
}

export const TopMenuButton = styled(ListItem)`
  display: block;
  flex-shrink: 0;
  color: ${colorsButton.blue.default};
  &:hover {
    background-color: unset;
    color: ${colorsButton.blue.hover};
  }
`;
