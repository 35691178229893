import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { colorsButton } from '__app/colors';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import { ReactNode } from 'react';
import { getBusinessClientObject } from 'store/authentication/selectors';

type GroupPillProps = {
  children?: ReactNode;
  href?: string;
  value: string;
};

type FetchUrl = 'client' | 'pipeline' | 'standard';

const PillAnchor = styled(TextEllipsisWithTooltip)`
  cursor: pointer;

  &&& {
    color: ${colorsButton.blue.hover};
  }

  &&&:hover {
    text-decoration: underline;
    color: ${colorsButton.blue.hover};
  }
`;

export const GroupPill = ({ children, href, ...rest }: GroupPillProps) => {
  if (href) {
    return (
      <PillAnchor {...rest} as="a" href={href} target="_blank">
        {children}
      </PillAnchor>
    );
  }
  return (
    <TextEllipsisWithTooltip {...rest}>{children}</TextEllipsisWithTooltip>
  );
};

export const useCanViewCustomObject = (objectId: string): boolean => {
  const clientObject = useSelector(getBusinessClientObject);
  const access = useSelector((state: any) => state.authentication.access);
  const contactId = clientObject.id;
  const canViewContacts =
    clientObject.access && access.sections.contacts_section.view;
  const canViewCustomObject =
    objectId === contactId
      ? canViewContacts
      : access.custom_objects.custom_object_entities[objectId]?.enabled;
  return canViewCustomObject;
};

export const getGroupLink = (
  groupId: string,
  fetchUrl: FetchUrl,
  objectId: string
) => {
  return fetchUrl === 'client'
    ? `/clients?group=${groupId}`
    : `/custom-objects/${objectId}?group=${groupId}`;
};
