import styled from '@emotion/styled';
import { getStaticBlockWidthFromColumns } from '@kizen/page-builder/nodes/utils';
import {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DraggableCore } from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useEditor, ROOT_NODE } from '@craftjs/core';
import { grayScale } from 'app/colors';
import { KizenTypography, fontWeights } from 'app/typography';
import Icon from 'components/Kizen/Icon';
import { clamp } from 'utility/clamp';
import { Frame } from '../components/Frame';
import { useBuilderContext } from '../BuilderContext';
import { DesktopCanvas } from './DesktopCanvas';
import { MobileCanvas } from './MobileCanvas';
import { TabletCanvas } from './TabletCanvas';

const ResizeIcon = styled(Icon)`
  position: absolute;
  top: ${({ top }) => top}px;
  cursor: ns-resize;
  transform: rotate(90deg);

  &&,
  && svg {
    height: 30px;
    width: 3px;

    &:hover,
    &:active {
      line {
        stroke: ${grayScale.dark};
      }
    }
  }
  && svg line + line {
    // update the thicker line to be 1/4 of the total instead of 1/3
    transform: scale(0.75) translateY(16.5%) translateX(0.5px);
  }
`;

const ResizeHandleText = styled(KizenTypography)`
  margin-top: 20px;
`;

const HeightText = styled(KizenTypography)`
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
  margin-top: 10px;
`;

const emptyCanvas = (props = {}) => ({
  ROOT: {
    type: { resolvedName: 'Root' },
    displayName: 'Root',
    isCanvas: true,
    parent: null,
    props,
    custom: {},
    hidden: false,
    nodes: [],
    linkedNodes: {},
  },
});

const useSetColumnsFromProp = (columns) => {
  const editor = useEditor();
  const { setRootWidth } = useBuilderContext();

  useEffect(() => {
    if (!isNaN(columns) && editor) {
      setRootWidth(getStaticBlockWidthFromColumns(columns));
      editor.actions.setProp(ROOT_NODE, (p) => {
        p.columns = columns;
      });
    }
  }, [columns, setRootWidth, editor]);
};

const useHeightResizer = ({ minHeight, step } = {}) => {
  const movement = useRef(0);
  const {
    actions: { setProp },
    query: { node },
  } = useEditor();
  const height = node(ROOT_NODE).get()?.data?.props?.height;

  const onDrag = useCallback(
    ({ movementY }) => {
      setProp(ROOT_NODE, (p) => {
        if (step) {
          movement.current += movementY;
          if (Math.abs(movement.current) > step * 0.9) {
            const next = movement.current > 0 ? step : -step;
            p.height = clamp(p.height + next, minHeight);
            movement.current = 0;
          }
        } else {
          p.height = clamp(p.height + movementY, minHeight);
        }
      });
    },
    [setProp, minHeight, step]
  );

  return [height, onDrag];
};

const parseDataProp = (data) => {
  if (typeof data === 'string') {
    return JSON.parse(data);
  }
  return Object.keys(data).length === 0 ? null : data;
};

/**
 * The main content area of the page builder where nodes are dropped and created.
 *
 * @param props.data - The initial data from the page builder.
 * @param props.key - React key prop that is used to render a different builder. This is needed when adding new,
 * blank pages where the result of `JSON.stringify(serializedNodes)` would be the same.
 * @param props.headerHeight - The height PageBuilderCanvasHeaderContainer (if used) which is absolutely positioned above the canvas.
 * @param props.defaultRootProps - props to be applied to the root node when loading a blank page.
 */
export const PageBuilderCanvas = forwardRef(
  (
    {
      data,
      defaultRootProps,
      columns,
      showBoxShadow = true,
      showPixelHeight = false,
      canResizeHeight = false,
      heightResizeStep,
      key,
      headerHeight,
      minHeight = 105,
      widthDisplay,
      children,
      ...rest
    },
    ref
  ) => {
    const clickAwayRef = useRef(null);
    const [serializedNodes, setSerializedNodes] = useState(parseDataProp(data));
    const editor = useEditor();
    const { t } = useTranslation();
    const [height, onDrag] = useHeightResizer({
      minHeight,
      step: heightResizeStep,
    });
    const {
      clearContentSettingsTray,
      setResizingHeight,
      view,
      additionalRootHeight,
    } = useBuilderContext();
    // gradients are shown on the root node for homepage/static blocks, here for email/forms/surveys
    const showGradient = !canResizeHeight;

    const Canvas = useMemo(() => {
      if (view === 'mobile') return MobileCanvas;
      if (view === 'tablet') return TabletCanvas;
      return DesktopCanvas;
    }, [view]);

    useSetColumnsFromProp(columns);

    useImperativeHandle(ref, () => ({ editor, clearContentSettingsTray }), [
      editor,
      clearContentSettingsTray,
    ]);

    useLayoutEffect(() => {
      const nodes = editor?.query?.getSerializedNodes();
      if (nodes.ROOT) {
        setSerializedNodes(nodes);
      }
    }, [view]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const d = parseDataProp(data);
      setSerializedNodes(d || emptyCanvas(defaultRootProps));
    }, [data, defaultRootProps]);

    return (
      <Canvas
        ref={clickAwayRef}
        key={key || JSON.stringify(serializedNodes)}
        paddingTop={headerHeight}
        showBoxShadow={showBoxShadow}
        canResizeHeight={canResizeHeight}
        widthDisplay={widthDisplay}
        showGradient={showGradient}
        {...rest}
      >
        <Frame data={serializedNodes}>{children}</Frame>
        {canResizeHeight && view === 'desktop' && (
          <>
            <DraggableCore
              onStart={() => setResizingHeight(true)}
              onDrag={onDrag}
              onStop={() => setResizingHeight(false)}
            >
              <ResizeIcon
                icon="column-resize"
                size="intrinsic"
                preserveAspectRatio="none"
                top={height + additionalRootHeight - 5}
              />
            </DraggableCore>
            <ResizeHandleText>{t('Modify Block Height')}</ResizeHandleText>
            {showPixelHeight && Boolean(height) && (
              <HeightText as="span">
                {`${t('HEIGHT')}: ${height.toFixed(0)}px`}
              </HeightText>
            )}
          </>
        )}
      </Canvas>
    );
  }
);

PageBuilderCanvas.displayName = 'PageBuilderCanvas';
