import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import PipelineService from 'services/PipelineService';
import { DASHBOARD } from 'queries/query-keys';

const usePipeline = (pipelineId, enabled = true, opts = {}) => {
  const fetchPipelines = useCallback(async () => {
    const res = await PipelineService.getObjects(undefined, opts);
    return res;
  }, [opts]);

  const { isLoading: pipelinesLoading, data: pipelinesData } = useQuery(
    DASHBOARD.DASHLET_PIPELINES,
    fetchPipelines,
    { enabled }
  );

  const pipeline = useMemo(() => {
    return pipelinesData?.find((p) => p.id === pipelineId) ?? {};
  }, [pipelineId, pipelinesData]);

  return {
    loading: pipelinesLoading,
    pipeline,
    pipelines: pipelinesData,
  };
};

export default usePipeline;
