import dagre from 'dagre';
import { grayScale } from 'app/colors';
import stepConfigs, {
  edgeLabels,
  TRIGGER,
  CONNECTION,
  TRANSFORM,
} from 'pages/AutomationEngine/steps';
import {
  PANEL_HEIGHT,
  STEP_CARD_WIDTH,
  STEP_CARD_MAX_HEIGHT,
} from '../Steps/constants';
import { ADD_BUTTON_HEIGHT, ADD_BUTTON_WIDTH } from '../AddButton';
import createGraph, { listGraphItems } from '../graph';

import { playX, playYTop, playYBottom } from './hooks';
import { MarkerType } from 'reactflow';
import { gray } from '@kizen/kds/Colors';

const createEdge = (
  source,
  target,
  label,
  goalType = false,
  stroke = grayScale.medium,
  sourceHandle = 'handle-right',
  targetHandle = 'handle-left'
) => {
  return {
    id: `${source}-${target}`,
    type: 'basic',
    sourceHandle,
    targetHandle,
    source,
    target,
    label: label && (goalType ? label.goalText : label.text),
    data: { ...label },
    style: { stroke },
    markerEnd: {
      type: MarkerType.Arrow,
      width: 18,
      height: 18,
      color: gray['gray-500'],
      lineCap: 'round',
    },
  };
};

export const isEdge = (obj) => 'source' in obj && 'target' in obj;

const unpositioned = { x: -1, y: -1 };

export const NODE_TYPES = {
  automationStep: 'automationStep',
  automationTrigger: 'automationTrigger',
  add: 'add',
  junction: 'junction',
  upload: 'upload',
  additionalVariables: 'additionalVariables',
  connection_intialize_additional_variables:
    'connection_intialize_additional_variables',
  connection_run_get_requests: 'connection_run_get_requests',
  transformAdd: 'transformAdd',
};

export const nodeFromStep = (step) => ({
  id: step.id,
  type: NODE_TYPES.automationStep,
  data: step,
  position: unpositioned,
  className: 'nopan',
});

export const nodeFromTrigger = (trigger) => ({
  id: trigger.id,
  type: NODE_TYPES.automationTrigger,
  data: trigger,
  position: unpositioned,
});

export const nodeForTriggerJunction = () => ({
  id: `${TRIGGER}.junction`,
  type: NODE_TYPES.junction,
  position: unpositioned,
});

export const nodeForTwoPathJunction = ({ id }, label) => ({
  id: `${id}.junction${label ? `.${label.type}` : ''}`,
  type: NODE_TYPES.junction,
  position: unpositioned,
});

export const addNodeToStep = ({ id, parentKey }, label) => ({
  id: `${parentKey || TRIGGER}.add.${id}`,
  type: NODE_TYPES.add,
  position: unpositioned,
  data: { leaf: false, branch: false, id, parentKey, label },
});

export const nodeForTriggerAdd = () => ({
  id: `${TRIGGER}.add`,
  type: NODE_TYPES.add,
  position: unpositioned,
  data: { leaf: true, branch: false, id: null, parentKey: null, label: null },
});

export const nodeForAdditionalAdd = () => {
  return {
    id: `${CONNECTION}.additional-variables`,
    type: NODE_TYPES.additionalVariables,
    position: unpositioned,
    data: { leaf: true, branch: false, id: null, parentKey: null, label: null },
  };
};
export const nodeForUpload = (index = 0, isLeaf = false) => {
  return {
    id: `new.upload.${index}`,
    type: NODE_TYPES.upload,
    position: unpositioned,
    data: {
      leaf: isLeaf,
      branch: false,
      id: null,
      parentKey: null,
      label: null,
      index,
    },
  };
};

export const nodeForTransformAdd = () => ({
  id: `${TRANSFORM}.add`,
  type: NODE_TYPES.transformAdd,
  position: unpositioned,
  data: { leaf: true, branch: false, id: null, parentKey: null, label: null },
});

export const edgeFromAddToStep = ({ id, parentKey }) =>
  createEdge(`${parentKey || TRIGGER}.add.${id}`, id);

export const edgeFromParentToAdd = ({ id, parentKey }, label, goalType) =>
  createEdge(parentKey, `${parentKey || TRIGGER}.add.${id}`, label, goalType);

export const edgeFromTriggerParentToAdd = (node, { id, parentKey }) =>
  createEdge(node.id, `${parentKey || TRIGGER}.add.${id}`);

export const edgeFromTriggerToJunction = ({ id }) =>
  createEdge(id, `${TRIGGER}.junction`);

export const edgeFromTriggerToAdd = ({ id }) =>
  createEdge(id, `${TRIGGER}.add`);

export const edgeFromTriggerToAdditionalVaribles = ({ id }) =>
  createEdge(id, `${CONNECTION}.additional-variables`);

export const edgeFromAdditionalVariblesToAdd = ({ id }) =>
  createEdge(`${CONNECTION}.additional-variables`, id);

export const edgeFromSourceToTarget = (
  { id },
  { id: targetId },
  sourceHandle = 'handle-right',
  targetHandle = 'handle-left'
) =>
  createEdge(
    id,
    targetId,
    undefined,
    undefined,
    undefined,
    sourceHandle,
    targetHandle
  );

export const edgeFromTriggerToIntializeAdditionalVaribles = ({ id }) =>
  createEdge(id, `${CONNECTION}.intialize_additional_variables`);

export const leafAddNode = ({ id }, label) => ({
  id: `${id}.add${label ? `.${label.type}` : ''}`,
  type: NODE_TYPES.add,
  position: unpositioned,
  data: { leaf: true, branch: false, id: null, parentKey: id, label },
});

export const junctionLeafAddNode = ({ id }, parentKey, label) => ({
  id: `${id}.add`,
  type: NODE_TYPES.add,
  position: unpositioned,
  data: { leaf: true, branch: false, id: null, parentKey: parentKey, label },
});

export const edgeToLeafAddNode = ({ id }, label, goalType) =>
  createEdge(id, `${id}.add${label ? `.${label.type}` : ''}`, label, goalType);

export const edgeFromStepToJunction = ({ id }, label, goalType, stroke) =>
  createEdge(
    id,
    `${id}.junction${label ? `.${label.type}` : ''}`,
    label,
    goalType,
    stroke
  );

export const leafAddNodeBranchFromStep = ({ id }) => ({
  id: `${id}.branch.add`,
  type: NODE_TYPES.add,
  position: unpositioned,
  data: { leaf: true, branch: true, id: null, parentKey: id, label: null },
});

export const edgeToLeafAddNodeBranchFromStep = ({ id }) =>
  createEdge(id, `${id}.branch.add`);

export const leafAddNodeBranchFromTrigger = () => ({
  id: `${TRIGGER}.branch.add`,
  type: NODE_TYPES.add,
  position: unpositioned,
  data: { leaf: true, branch: true, id: null, parentKey: null, label: null },
});

export const edgeToLeafAddNodeBranchFromTrigger = (node) =>
  createEdge(node.id, `${TRIGGER}.branch.add`);

export const getNodeMaxDimensions = (node) => {
  // Dimensions reported here are conservative for layout purposes.
  // We need to assume max height for steps so that branches don't need
  // to move when two become near each other vertically.
  const { type, width = null, height = null } = node;
  if (
    [
      NODE_TYPES.automationStep,
      NODE_TYPES.connection_intialize_additional_variables,
      NODE_TYPES.connection_run_get_requests,
    ].includes(type)
  ) {
    return { width: STEP_CARD_WIDTH, height: STEP_CARD_MAX_HEIGHT };
  }
  if (type === NODE_TYPES.add) {
    return { width: ADD_BUTTON_WIDTH, height: ADD_BUTTON_HEIGHT };
  }
  return { width, height };
};

export const straightEdgesAdjustment = (
  { type, height },
  layoutNode,
  { showStats } = {}
) => {
  if (
    [
      NODE_TYPES.automationStep,
      NODE_TYPES.automationTrigger,
      NODE_TYPES.connection_intialize_additional_variables,
      NODE_TYPES.connection_run_get_requests,
    ].includes(type) &&
    showStats
  ) {
    // Center relative to area aside from panel
    return (layoutNode.height - height + PANEL_HEIGHT) / 2;
  }
  // Regular centering
  return (layoutNode.height - height) / 2;
};

export const automationToNodes = ({ steps, triggers, branches }) => {
  // Setup indexes of step info for quick/convenient access later
  const leaves = {};
  const stepsById = {};
  const rootSteps = [];
  const conditionGoalJunctions = {};
  const labelsFromById = Object.keys(edgeLabels).reduce(
    (collect, labelType) => {
      collect[labelType] = {};
      return collect;
    },
    {}
  );
  // Take a single pass through steps and build the indexes
  steps.forEach((step) => {
    stepsById[step.id] = step;
    if (!step.parentKey) {
      rootSteps.push(step);
    }
    Object.keys(edgeLabels).forEach((labelType) => {
      if (step.fromLabel === labelType) {
        labelsFromById[labelType][step.parentKey] = true;
      }
    });
    if (!(step.id in leaves)) {
      leaves[step.id] = step;
    }
    if (step.parentKey) {
      leaves[step.parentKey] = false;
    }
  });

  // There are five trigger layouts:
  //   - Multiple triggers, no steps
  //     - Triggers connect to leaf add button
  //   - One trigger, no steps
  //     - Trigger connects to leaf add button
  //   - Multiple triggers, some steps
  //     - Triggers meet at junction, then junction connects to steps through an add button
  //     - Add button should connect to junction.
  //   - One trigger, some steps
  //     - Trigger connects to steps through leaf add button
  //     - Add button should connect to the trigger directly
  //   - One or multiple triggers, one root step
  //     - When there is exactly one root step no junction node is necessary
  //     - The root step's add button should connect to each trigger directly

  const triggerChildrenCount = rootSteps.length + (branches.trigger ? 1 : 0);
  const triggerAddNode = !steps.length && nodeForTriggerAdd();
  const triggerJunctionNode =
    triggerChildrenCount > 1 && triggers.length > 1 && nodeForTriggerJunction();
  const triggerNodes = [
    ...triggers.flatMap((trigger) => {
      return [
        nodeFromTrigger(trigger),
        triggerJunctionNode && edgeFromTriggerToJunction(trigger),
        triggerAddNode && edgeFromTriggerToAdd(trigger),
      ];
    }),
    triggerAddNode,
    triggerJunctionNode,
  ];

  const triggerNodeParents =
    (triggerAddNode && [triggerAddNode]) ||
    (triggerJunctionNode && [triggerJunctionNode]) ||
    triggerNodes.filter(
      (node) => node && node.type === NODE_TYPES.automationTrigger
    );

  const isYesStep = (step) => step.fromLabel === edgeLabels.yes.type;
  const hasYesStep = (step) => !!labelsFromById[edgeLabels.yes.type][step.id];
  const isNoStep = (step) => step.fromLabel === edgeLabels.no.type;
  const hasNoStep = (step) => !!labelsFromById[edgeLabels.no.type][step.id];

  // Ensure yes steps come before no steps for the same condition.
  // TODO it may make sense to eventually move this ordering up
  // into the store during move/insert/delete.
  steps = listGraphItems(createGraph(steps));
  const isParentToGoToStep = (step) =>
    steps.filter((node) => {
      return (
        step.parentKey === node.id &&
        node.type === stepConfigs.goToAutomationStep.type
      );
    });
  const stepNodes = steps.flatMap((step) => {
    let label = null;
    const befores = [];
    const afters = [];
    const parent = stepsById[step.parentKey];
    const conditionYesChildren = [];
    const conditionNoChildren = [];

    const noJunction = nodeForTwoPathJunction(step, edgeLabels.no);

    const yesJunction = nodeForTwoPathJunction(step, edgeLabels.yes);

    const branchId = Object.keys(branches).find((key) => key === step.id);

    if (step.type === stepConfigs.condition.type || step.goalType) {
      // we need to know how much condition/goal step has children
      steps.forEach((item) => {
        if (item.parentKey === step.id) {
          if (item.fromLabel === edgeLabels.yes.type) {
            conditionYesChildren.push(item);
          } else {
            conditionNoChildren.push(item);
          }
        }
      });

      if (branchId) {
        // we need this to create a leaf with an add button from an existing branch of goal(condition): parent--y(n)--[j]--add
        if (branches[branchId] === edgeLabels.yes.type) {
          conditionYesChildren.push(null);
          afters.push(
            junctionLeafAddNode(yesJunction, step.id, edgeLabels.yes),
            edgeToLeafAddNode(yesJunction)
          );
        }
        if (branches[branchId] === edgeLabels.no.type) {
          conditionNoChildren.push(null);
          befores.push(
            junctionLeafAddNode(noJunction, step.id, edgeLabels.no),
            edgeToLeafAddNode(noJunction)
          );
        }
      }
    }

    // if condition/goal has more than 1 child on "yes" or "no" path - we create a junction for them

    if (conditionYesChildren.length > 1 || conditionNoChildren.length > 1) {
      // we save id junction for each child for creating edge parent--y(n)--[j]--child in the future
      conditionYesChildren.forEach((item) => {
        item &&
          (conditionGoalJunctions[item.id] =
            `${step.id}.junction.${item.fromLabel}`);
      });

      conditionNoChildren.forEach((item) => {
        item &&
          (conditionGoalJunctions[item.id] =
            `${step.id}.junction.${item.fromLabel}`);
      });

      if (conditionYesChildren.length) {
        befores.push(
          yesJunction,
          edgeFromStepToJunction(step, edgeLabels.yes, step?.goalType)
        );
      } else if (conditionNoChildren.length > 1) {
        befores.push(
          yesJunction,
          edgeFromStepToJunction(step, edgeLabels.yes, step?.goalType),
          junctionLeafAddNode(yesJunction, step.id, edgeLabels.yes),
          edgeToLeafAddNode(yesJunction)
        );
      }

      if (conditionNoChildren.length) {
        afters.push(
          noJunction,
          edgeFromStepToJunction(step, edgeLabels.no, step?.goalType)
        );
      } else if (conditionYesChildren.length > 1) {
        afters.push(
          noJunction,
          edgeFromStepToJunction(step, edgeLabels.no, step?.goalType),
          junctionLeafAddNode(noJunction, step.id, edgeLabels.no),
          edgeToLeafAddNode(noJunction)
        );
      }
    }

    if (conditionGoalJunctions[step.id]) {
      // creating edge [j]--(+)
      befores.push(
        edgeFromTriggerParentToAdd(
          {
            id: conditionGoalJunctions[step.id],
          },
          step
        )
      );
    }
    if (isYesStep(step)) {
      label = edgeLabels.yes;
      if (parent && !hasNoStep(parent) && !conditionGoalJunctions[step.id])
        afters.push(
          leafAddNode(parent, edgeLabels.no),
          edgeToLeafAddNode(parent, edgeLabels.no, parent?.goalType)
        );
    }
    if (isNoStep(step)) {
      label = edgeLabels.no;
      if (parent && !hasYesStep(parent) && !conditionGoalJunctions[step.id])
        befores.push(
          leafAddNode(parent, edgeLabels.yes),
          edgeToLeafAddNode(parent, edgeLabels.yes, parent?.goalType)
        );
    }
    if (leaves[step.id]) {
      if (step.type === stepConfigs.condition.type || step.goalType) {
        // Condition steps leaves have two add nodes: one for yes and one for no
        befores.push(
          leafAddNode(step, edgeLabels.yes),
          edgeToLeafAddNode(step, edgeLabels.yes, step?.goalType)
        );
        afters.push(
          leafAddNode(step, edgeLabels.no),
          edgeToLeafAddNode(step, edgeLabels.no, step?.goalType)
        );
      } else if (step.type !== stepConfigs.goToAutomationStep.type) {
        afters.push(leafAddNode(step), edgeToLeafAddNode(step));
      } else afters.push(edgeToLeafAddNode(step)); // we need this edge for propper layout
    }
    return [
      ...befores,
      nodeFromStep(step),
      !isParentToGoToStep(step).length ? addNodeToStep(step, label) : null,
      edgeFromAddToStep(step),
      !conditionGoalJunctions[step.id]
        ? step.parentKey && edgeFromParentToAdd(step, label, parent?.goalType)
        : null,
      ...(step.parentKey
        ? []
        : triggerNodeParents.map((parentEntity) =>
            edgeFromTriggerParentToAdd(parentEntity, step)
          )),
      ...afters,
    ];
  });
  const branchNodes = Object.keys(branches).flatMap((parentKey) => {
    const step = stepsById[parentKey];
    if (parentKey === TRIGGER) {
      const [triggerNodeParent] = triggerNodeParents;
      if (!triggerNodeParent) {
        return [];
      }
      return [
        leafAddNodeBranchFromTrigger(),
        edgeToLeafAddNodeBranchFromTrigger(triggerNodeParent),
      ];
    }
    if (step.type === stepConfigs.condition.type || step.goalType) {
      return []; // we handle branching of condition and goal steps in stepNodes
    }
    return [
      leafAddNodeBranchFromStep(step),
      edgeToLeafAddNodeBranchFromStep(step),
    ];
  });

  return [
    // Additional --[+] for bonus branches: these go first intentionally
    ...branchNodes,
    // [trigger] or [triggers]---[junction] or [trigger(s)]---[+] (no steps)
    ...triggerNodes,
    // ---[+]---[step], opt. with additional --[+] for leaves
    ...stepNodes,
  ].reduce(
    (collect, node) => {
      if (!node) {
        return collect;
      }
      const [nodes, edges] = collect;
      return isEdge(node)
        ? [nodes, edges.concat(node)]
        : [nodes.concat(node), edges];
    },
    [[], []]
  );
};

export const layoutNodes = (nodes = [], edges = [], { showStats } = {}) => {
  const graph = new dagre.graphlib.Graph();

  graph.setGraph({
    marginx: 0,
    marginy: 0,
    rankdir: 'LR', // Tree orients itself left to right,
  });

  graph.setDefaultEdgeLabel(() => ({}));

  let trigger;
  let triggers = 0;
  nodes.forEach((node) => {
    graph.setNode(node.id, getNodeMaxDimensions(node));
    if (node.type === NODE_TYPES.automationTrigger) {
      triggers += 1;
      trigger = node;
    }
  });

  edges.forEach((edge) => {
    graph.setEdge(edge.source, edge.target);
  });

  if (trigger && triggers === 1) {
    // When there is a single trigger, lay it out so that it will
    // be aligned with the first step card added to it.  This ensures
    // there is no jarring y-transition when the first step is added.
    //
    // This is very similar but not identical to the condition in getExtent().
    // The difference is that this affects visual layout, whereas the other
    // just affects the perceived x/y extent of all the nodes.  In getExtent()
    // we want there to be room in the extent for the panel when it opens, and
    // here we only want to do so when it doesn't affect the visuals (when there
    //  is one trigger): we don't want to expand the gap between trigger nodes.
    graph.node(trigger.id).height = STEP_CARD_MAX_HEIGHT;
  }

  dagre.layout(graph);

  return nodes.map((node) => {
    const layoutNode = graph.node(node.id);
    const adjustX =
      node.type === NODE_TYPES.add && node.data.leaf && !node.data.branch
        ? -ADD_BUTTON_WIDTH // Leaf add buttons should display inset
        : 0;
    const adjustY = straightEdgesAdjustment(node, layoutNode, {
      showStats,
    });
    return {
      ...node,
      width: layoutNode.width,
      height: layoutNode.height,
      position: {
        // The position from dagre layout is the center of the node.
        // Calculating the position of the top left corner for rendering.
        x: layoutNode.x - layoutNode.width / 2 + adjustX,
        y: layoutNode.y - layoutNode.height / 2 + adjustY,
      },
    };
  });
};

export const preserveElementOrderForLayout = (rendered, ordered) => {
  // It turns out that react-flow re-orders elements internally.
  // In order to maintain layout stability, we need to control
  // the order of the input to layoutNodes() to match the order
  // of the output of automationToNodes().  This little adapter
  // will do that work for us.
  const ordering = ordered.reduce((collect, el, i) => {
    collect[el.id] = i + 1; // All greater than zero for Infinity fallback below
    return collect;
  }, {});
  return [...rendered].sort(
    (el1, el2) =>
      (ordering[el1.id] || Infinity) - (ordering[el2.id] || Infinity)
  );
};

export const nodeHasDimension = (node) => {
  const { width = null, height = null } = node;
  return width !== null && height !== null;
};

export const nodeHasPosition = (node) => {
  const {
    position: { x, y },
  } = node;
  return x !== unpositioned.x && y !== unpositioned.y;
};

export const getExtent = (els) => {
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;
  let degenerate = true;
  let trigger;

  els.forEach((node) => {
    if (!isEdge(node) && nodeHasPosition(node) && nodeHasDimension(node)) {
      const {
        position: { x, y },
      } = node;
      const { width, height } = getNodeMaxDimensions(node);
      minX = Math.min(minX, x);
      maxX = Math.max(maxX, x + width);
      minY = Math.min(minY, y);
      maxY = Math.max(maxY, y + height);
      degenerate = false;
      if (node.type === NODE_TYPES.automationTrigger) {
        trigger = node;
      }
    }
  });
  if (trigger) {
    // Always ensure bottom trigger has room to open its panel without affecting the extent.
    maxY = Math.max(maxY, trigger.position.y + STEP_CARD_MAX_HEIGHT);
  }
  if (degenerate) {
    return [
      [0, 0],
      [0, 0],
    ];
  }
  return [
    [minX, minY],
    [maxX, maxY],
  ];
};

export const focusedCenter = ({ x, y, zoom, width, scrollingY }) => {
  return {
    x:
      x <= width + STEP_CARD_MAX_HEIGHT
        ? x + playX
        : width + STEP_CARD_MAX_HEIGHT,
    y: scrollingY ? y : (playYTop + playYBottom) / 2,
    zoom: zoom.toFixed(1), // get it rounded to 1 sig fig
  };
};
