import { useTranslation } from 'react-i18next';
import { getLinkToObjectSettings } from './helpers';
import { CustomizeFieldOptionsContainer } from './styles';
import { LinkWithIconTooltip } from 'pages/Common/components/LinkWithIconTooltip';

export const CustomizeFieldOptionsLink = ({ model, field }) => {
  const { t } = useTranslation();

  return (
    <CustomizeFieldOptionsContainer>
      <LinkWithIconTooltip
        label={t(
          'This will bring you to the customize fields page in a new tab.'
        )}
        href={getLinkToObjectSettings(model, field)}
      >
        {t('Customize Field Options')}
      </LinkWithIconTooltip>
    </CustomizeFieldOptionsContainer>
  );
};
