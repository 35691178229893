import { createAction, createSlice } from '@reduxjs/toolkit';
import { initialStore } from '../initialStore';

// accounts
export const getAccounts = createAction(
  'adManager/accounts/GET_ACCOUNTS',
  function prepare({ updatePageConfig = true } = {}) {
    return {
      payload: {
        updatePageConfig,
      },
    };
  }
);
export const getAccountsFail = createAction(
  'adManager/accounts/GET_ACCOUNTS_FAIL'
);

export const deleteAccount = createAction('adManager/accounts/DELETE_ACCOUNT');
export const deleteAccountFail = createAction(
  'adManager/accounts/DELETE_ACCOUNT_FAIL'
);

export const updateAccount = createAction('adManager/accounts/UPDATE_ACCOUNT');
export const updateAccountFail = createAction(
  'adManager/accounts/UPDATE_ACCOUNT_FAIL'
);

// setPageConfig
export const setPageConfig = createAction('adManager/accounts/SET_PAGE_CONFIG');

// Slices
const defaultState = initialStore.adManagerPage;

export const adManagerPageSlice = createSlice({
  name: 'adManager/accounts',
  initialState: defaultState,
  reducers: {
    buildPage: (state) => {
      state.isFetching = true;
    },
    buildPageComplete: (state, { payload }) => {
      const { pageResponse } = payload;
      const connections = {
        ...state.pageConfig.connections,
        ...pageResponse.connections,
      };
      state.pageConfig = {
        connections,
      };
    },
    updatePageConfig: (state, { payload }) => {
      const connections = {
        ...state.pageConfig.connections,
        ...payload.connections,
      };
      state.pageConfig = {
        connections,
      };
    },
    buildPageFinish: (state) => {
      state.isFetching = false;
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    getAccountsSuccess: (state, { payload }) => {
      const { connectionsCount, connections } = payload;
      state.connectionsCount = connectionsCount;
      state.connections = connections;
    },
    getAccountsStart: (state) => {
      state.isFiltering = true;
    },
    getAccountsFinish: (state) => {
      state.isFiltering = false;
    },
  },
});

export const {
  buildPage,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  getAccountsSuccess,
  updatePageConfig,
  getAccountsFinish,
  getAccountsStart,
} = adManagerPageSlice.actions;

export const { reducer } = adManagerPageSlice;

// Selectors
export const selectAdManagerIsFiltering = (state) =>
  state.adManagerPage.isFiltering;
