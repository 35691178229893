import { colorsSecondary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { CardHeader } from '../components/CardHeader';
import { initiatorTypes } from '../../constants';
import FieldsTable from '../FieldsTable';

const cardHeaderConfig = (t) => {
  return {
    [initiatorTypes['empty-initiator']]: {
      prefix: t('form submitted'),
      creationType: () => '',
    },
  };
};

const FormSubmittedEventBlock = ({ event, ...others }) => {
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsSecondary.gray.dark}
          icon="align-justify-light"
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={event.data.formName}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    >
      <FieldsTable fields={event.data.fields} />
    </BasicBlock>
  );
};

export default FormSubmittedEventBlock;
