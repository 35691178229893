import {
  getOptionValue,
  getTeamLabel,
  namedToOptionDeleted,
  displayNameToOptionDeleted,
} from 'services/helpers';
import {
  checkDeleted,
  displayNameDescriptor,
  nameDescriptor,
  notifyMemberViaTextTypes,
} from 'pages/AutomationEngine/helpers';

const forApi = (step) => {
  const orgDetails = { ...step.details };
  const api = {
    content: orgDetails.content,
    htmlContent: orgDetails.htmlContent,
    teamMember: {
      type: getOptionValue(orgDetails.teamMember.type),
    },
  };

  if (
    orgDetails.teamMember.role &&
    orgDetails.teamMember.type.value ===
      notifyMemberViaTextTypes.LAST_ACTIVE_ROLE
  ) {
    api.teamMember.roleId = getOptionValue(orgDetails.teamMember.role);
  } else if (
    orgDetails.teamMember.employee &&
    orgDetails.teamMember.type.value ===
      notifyMemberViaTextTypes.SPECIFIC_TEAM_MEMBER
  ) {
    api.teamMember.employeeId = getOptionValue(orgDetails.teamMember.employee);
  } else if (
    orgDetails.teamMember.field &&
    orgDetails.teamMember.type.value ===
      notifyMemberViaTextTypes.TEAM_SELECTOR_FIELD
  ) {
    api.teamMember.fieldId = getOptionValue(orgDetails.teamMember.field);
  }

  return { details: api };
};

const forFlow = (apiObject, addErrorMessage, messageDictionary) => {
  if (apiObject.teamMember?.type) {
    const { type } = apiObject.teamMember;
    if (type === notifyMemberViaTextTypes.LAST_ACTIVE_ROLE) {
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        apiObject.teamMember.role,
        nameDescriptor,
        messageDictionary.associatedRole
      );
    }

    apiObject.teamMember.type =
      messageDictionary.dictionaryFromTypeOptions[type];
  }
  if (apiObject.teamMember?.employee) {
    const { employee } = apiObject.teamMember;
    checkDeleted(
      messageDictionary,
      addErrorMessage,
      employee,
      displayNameDescriptor
    );
    apiObject.teamMember.employee = !employee.deleted
      ? {
          label: getTeamLabel(employee),
          value: employee.id,
        }
      : null;
  }

  if (apiObject.teamMember?.field) {
    const { field } = apiObject.teamMember;
    checkDeleted(
      messageDictionary,
      addErrorMessage,
      field,
      displayNameDescriptor
    );

    apiObject.teamMember.field = displayNameToOptionDeleted(field);
  }

  if (apiObject.teamMember?.role) {
    const { role } = apiObject.teamMember;

    apiObject.teamMember.role = namedToOptionDeleted(role);
  }
  if (apiObject?.textBody) {
    apiObject.messageBody = apiObject.textBody;
  }

  return apiObject;
};
export const notifyMemberViaTextDTO = {
  forApi,
  forFlow,
};
