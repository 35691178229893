import { useHistory } from 'react-router-dom';
import { subroutes } from '../subroutes';
import { useEffect, useMemo } from 'react';
import { PermissionsError } from 'services/errors';
import { useSelector } from 'react-redux';
import { getChosenBusiness } from 'store/authentication/selectors';

export const useAccessibleRoutes = (access) => {
  const history = useHistory();
  const chosenBusiness = useSelector(getChosenBusiness);

  const accessibleSubroutes = useMemo(() => {
    return Object.entries(subroutes).reduce((acc, [key, route]) => {
      const enabledByEntitlement =
        !route.entitlement || chosenBusiness?.entitlements?.[route.entitlement];

      const hasAccess = Object.entries(route.accessPaths).some(
        ([accessKey, accessLevel]) => access[accessKey]?.[accessLevel]
      );

      if (enabledByEntitlement && hasAccess) {
        acc[key] = route;
      }
      return acc;
    }, {});
  }, [access, chosenBusiness?.entitlements]);

  useEffect(() => {
    if (!accessibleSubroutes[history.location.pathname]) {
      const firstAccessibleRoute = Object.values(accessibleSubroutes)[0];
      if (firstAccessibleRoute) {
        history.replace(firstAccessibleRoute.path);
      } else {
        throw new PermissionsError();
      }
    }
  }, [
    accessibleSubroutes,
    history,
    history.location.pathname,
    access.businessInformation?.edit,
    access.businessCustomization?.edit,
  ]);

  if (!access.enabled) {
    throw new PermissionsError();
  }

  return accessibleSubroutes;
};
