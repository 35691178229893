import { breakpoints, useWindowSize } from 'app/spacing';
import { useCallback, useMemo } from 'react';
const { xl } = breakpoints;

const useDashboardSettingsBreakpoint = (columns) => {
  const { width } = useWindowSize();

  const diff = xl - width;

  const delta = useMemo(() => {
    if (diff > 0) {
      const adjustableColumns = columns.filter((c) => Boolean(c.shrinkable));
      return diff / adjustableColumns.length + 1;
    }
    return 0;
  }, [columns, diff]);

  const calculateBreakpoint = useCallback(
    (originalWidth, shrinkable) => {
      if (!originalWidth) {
        return;
      }
      if (!shrinkable) {
        return originalWidth;
      }
      return originalWidth - delta;
    },
    [delta]
  );

  return calculateBreakpoint;
};

export default useDashboardSettingsBreakpoint;
