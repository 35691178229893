import { useRef, useCallback } from 'react';
import { deferExecution } from 'utility/defer';

export const useScrollIntoView = (scrollOptions: any) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const scroll = useCallback(() => {
    deferExecution(() => {
      if (componentRef.current) {
        componentRef.current?.scrollIntoView(scrollOptions);
      }
    });
  }, [scrollOptions]);

  const scrollNext = useCallback(() => {
    setTimeout(() => {
      scroll();
    }, 0);
  }, [scroll]);

  const scrollNextStop = (evt: any) => {
    evt?.stopPropagation();
    return scrollNext;
  };

  return { scroll, scrollNext, scrollNextStop, componentRef };
};
