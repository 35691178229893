export const TAG_ACTIONS = {
  edit: 'edit',
  replace: 'replace',
  delete: 'delete',
};

export const TAGS_DICTIONARY = {
  titles: {
    getDictionary: (t) => ({
      tagLabel: t('Title'),
      tagLabelShort: t('Title'),
      tagLabelPlural: t('Titles'),
    }),
  },
  reasonsDisqualified: {
    getDictionary: (t) => ({
      tagLabel: t('Reason Disqualified'),
      tagLabelShort: t('Reason'),
      tagLabelPlural: t('Reasons Disqualified'),
      countLabel: t('Count'),
    }),
  },
  reasonsLost: {
    getDictionary: (t) => ({
      tagLabel: t('Reason Lost'),
      tagLabelShort: t('Reason'),
      tagLabelPlural: t('Reasons Lost'),
      countLabel: t('Count'),
    }),
  },
  default: {
    getDictionary: (t) => ({
      tagLabel: t('Tag'),
      tagLabelShort: t('Tag'),
      tagLabelPlural: t('Tags'),
    }),
  },
};

export const DYNAMIC_TAGS_XFORM = (v) =>
  v.replace(/(^\s+)|(\s+)/g, (_, p1) => (p1 ? '' : ' '));
