export const teal = {
  'teal-300': '#4BC7B4',
  'teal-400': '#55B6A3',
  'teal-500': '#00AB93',
  'teal-600': '#459B86',
  'teal-700': '#008D75',
  'teal-800': '#357A67',
  'teal-900': '#275D4A',
};

export const blue = {
  'blue-300': '#6FB2F9',
  'blue-400': '#51A1F8',
  'blue-500': '#4090F7',
  'blue-600': '#1183FF',
  'blue-700': '#216FF7',
  'blue-800': '#285DE4',
  'blue-900': '#2E39C4',
};

export const red = {
  'red-50': '#FFC9C9',
  'red-100': '#FEADAF',
  'red-200': '#FF8A8A',
  'red-300': '#FB7373',
  'red-400': '#EC5D55',
  'red-500': '#FF3D30',
  'red-600': '#EA483D',
  'red-700': '#EB272B',
  'red-800': '#CC3630',
  'red-900': '#BD2C25',
};

export const orange = {
  'orange-40': '#FFFCF8',
  'orange-50': '#FCF1D9',
  'orange-100': '#FADC99',
  'orange-200': '#F8CE76',
  'orange-300': '#F6BF52',
  'orange-400': '#F5B83F',
  'orange-500': '#F3A800',
  'orange-600': '#E8AB3B',
  'orange-700': '#D79927',
  'orange-800': '#A57025',
  'orange-900': '#84571B',
};

export const gray = {
  'gray-50': '#FFFFFF',
  'gray-100': '#FAFBFA',
  'gray-200': '#F9FAFB',
  'gray-300': '#F5F6F7',
  'gray-400': '#ECEEF0',
  'gray-500': '#D8DDE1',
  'gray-600': '#A8B3BC',
  'gray-700': '#7E8C98',
  'gray-800': '#4A5660',
  'gray-900': '#000000',
};

export const green = {
  'green-50': '#F8FFFA',
  'green-700': '#32C055',
  'green-300': '#ADE6BB',
};

export const aliceblue = {
  'aliceblue-25': '#F8FAFF',
};

export const semanticNames = {
  'background-darker': 'gray-800',
  'background-dark': 'gray-600',
  'background-medium': 'gray-500',
  'background-light': 'gray-300',
  'background-lighter': 'gray-200',
  'background-lightest': 'gray-100',
  'background-white': 'gray-50',
  'background-default': 'aliceblue-25',
  'border-darker': 'gray-800',
  'border-dark': 'gray-600',
  'border-medium': 'gray-500',
  'border-medium-light': 'gray-400',
  'border-light': 'gray-300',
  'border-active': 'blue-500',
  'button-primary-pressed': 'teal-700',
  'button-primary-hover': 'teal-500',
  'button-primary-default': 'teal-300',
  'button-secondary-pressed': 'blue-800',
  'button-secondary-hover': 'blue-700',
  'button-secondary-default': 'blue-500',
  'button-warning-pressed': 'red-700',
  'button-warning-hover': 'red-500',
  'button-warning-default': 'red-300',
  'button-disabled-default': 'gray-500',
  'button-tertiary-pressed': 'gray-900',
  'button-tertiary-hover': 'gray-800',
  'button-tertiary-default': 'gray-600',
  'font-primary': 'gray-800',
  'font-secondary': 'gray-700',
  'font-placeholder': 'gray-600',
  'font-white': 'gray-50',
  'feedback-toast-information': 'blue-500',
  'feedback-toast-success': 'teal-300',
  'feedback-toast-warning': 'orange-300',
  'feedback-toast-error': 'red-300',
  'viz-neutral': 'gray-500',
  'viz-seq-red-1': 'red-900',
  'viz-seq-red-2': 'red-800',
  'viz-seq-red-3': 'red-700',
  'viz-seq-red-4': 'red-600',
  'viz-seq-red-5': 'red-500',
  'viz-seq-red-6': 'red-400',
  'viz-seq-red-7': 'red-300',
  'viz-seq-red-8': 'red-200',
  'viz-seq-red-9': 'red-100',
  'viz-seq-red-10': 'red-50',
};

export const aliases = Object.keys(semanticNames).reduce((acc, key) => {
  return {
    ...acc,
    [key]: `var(--color-${semanticNames[key]})`,
  };
}, {});

export const spacing = {
  'spacer-5': '0.313em',
  'spacer-8': '0.5em',
  'spacer-10': '0.625em',
  'spacer-12': '0.75em',
  'spacer-15': '0.938em',
  'spacer-20': '1.25em',
  'spacer-25': '1.563em',
  'spacer-30': '1.875em',
};

export const fontSize = {
  xs: '0.625em',
  sm: '0.75em',
  md: '0.875em',
  base: '1em',
  lg: '1.125em',
  xl: '1.25em',
  '2xl': '1.5em',
  '3xl': '2.125em',
  '4xl': '3em',
  'size-reset': '16px',
  unset: 'unset',
};

// Expanding scroll bars have a z-index of 2 currently
// in react-app, so basic menus need to start at 3,
// also we need ScrollTopButton to appear above Testing Mode badge
// but below modal backdrop
export const zIndex = {
  menu: 3,
  scrollTop: 1039,
};
