import KizenTypography from 'app/kizentypo';
import EditableName from 'components/AccessRequests/Tables/Cells/EditableName';
import OwnerDropdown from 'components/AccessRequests/Tables/Cells/OwnerDropdown';
import SharingSettingsCell from 'components/AccessRequests/Tables/Cells/SharingSettingsCell';
import {
  COLUMN_SIZES,
  getTranslatedAccessText,
} from 'components/AccessRequests/utils';
import ActionMenu from 'components/AccessRequests/Tables/Cells/ActionMenu';

export const getColumns = (t) => [
  {
    name: t('Chart Group Name'),
    id: 'name',
    width: COLUMN_SIZES.LARGE,
    accessor: (data, opts) => {
      return (
        <EditableName
          access={data.employee_access}
          id={data.id}
          name={data.name}
          {...opts}
        />
      );
    },
    truncate: true,
    qaField: 'dashboard-name',
    shrinkable: true,
  },
  {
    name: t('Creator/Owner'),
    id: 'owner',
    width: COLUMN_SIZES.MEDIUM,
    accessor: (data, opts) => (
      <OwnerDropdown id={data.id} data={data} {...opts} />
    ),
    truncate: false,
    qaField: 'dashboard-owner',
    shrinkable: true,
  },
  {
    name: t('Sharing Settings'),
    id: 'sharing',
    width: COLUMN_SIZES.SMALL,
    accessor: (data, opts) => (
      <SharingSettingsCell
        onClickEdit={opts.editHandler}
        access={data.employee_access}
        data={data}
      />
    ),
    qaField: 'dashboard-sharing-settings',
    shrinkable: true,
  },
  {
    name: t('Your Access'),
    id: 'access',
    width: COLUMN_SIZES.TINY,
    accessor: (data) => (
      <KizenTypography>
        {getTranslatedAccessText(data.employee_access, t)}
      </KizenTypography>
    ),
    qaField: 'dashboard-your-access',
  },
  {
    name: t('# Charts'),
    id: 'count',
    width: COLUMN_SIZES.TINY,
    accessor: (data) => (
      <KizenTypography>{data?.dashlets_count}</KizenTypography>
    ),
    qaField: 'dashboard-dashlets-count',
  },
  {
    name: t('Actions'),
    id: 'actions',
    accessor: (data, opts) => (
      <ActionMenu
        access={data.employee_access}
        hidden={data.hidden}
        {...opts}
      />
    ),
    align: 'right',
    qaField: 'dashboard-actions',
  },
];
