import React, { useContext } from 'react';

export const KeyBoardContext = React.createContext({
  onFocus: () => {},
  onBlur: () => {},
  assignFieldHandle: () => {},
  getKeyListenersProps: () => {},
});

export const useKeyBoardContext = () => {
  return useContext(KeyBoardContext);
};
