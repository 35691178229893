import KizenTypography from 'app/kizentypo';
import { GO_BACK_KEYS } from 'components/Wizards/CustomObject/constants';
import { CUSTOMIZE_FIELDS_STEP_KEY } from 'components/Wizards/CustomObject/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getBusinessClientObject } from 'store/authentication/selectors';

export const CustomizeFieldsLink = ({ objectId }) => {
  const { t } = useTranslation();
  const clientObject = useSelector(getBusinessClientObject);
  const history = useHistory();

  return (
    <KizenTypography
      type="link"
      data-qa="customize-fields-link"
      rel="noopener noreferrer"
      to={{
        pathname: `/custom-objects/${objectId || clientObject.id}/settings`,
        state: {
          from: history.location.pathname,
          fromKey: GO_BACK_KEYS.COLUMNS,
          focusStepKey: CUSTOMIZE_FIELDS_STEP_KEY,
        },
      }}
    >
      {t('Customize Fields')}
    </KizenTypography>
  );
};
