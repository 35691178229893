import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import {
  grayScale,
  colorsButton,
  borderTight,
  shadowOverlay,
} from 'app/colors';
import { fontSizes } from 'app/typography';
import Icon from 'components/Kizen/Icon';

export const DragIcon = styled(Icon)`
  margin-right: ${gutters.spacing(2)}px;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const DateWrapper = styled.div`
  background: ${grayScale.mediumLight};
  border-radius: 10px;
  padding: ${gutters.spacing(1, -3)}px ${gutters.spacing(1)}px;

  p {
    text-align: center;
  }
`;

export const Time = styled(KizenTypography)`
  color: ${grayScale.mediumDark};
`;

export const BubbleWrapper = styled.div`
  && .user-avatar {
    line-height: 17px;
    width: 15px;
    height: 15px;
    overflow: hidden;
    font-size: ${fontSizes.tiny};
  }
  && .user-image {
    width: 15px;
    height: 15px;
  }
`;

const CardWrapper = styled.div`
  position: relative;
  background: ${grayScale.white};
  margin-bottom: ${gutters.spacing(2)}px;
  padding: ${gutters.spacing(2)}px;
  ${shadowOverlay}
  ${borderTight}
  border-color: ${grayScale.mediumLight};
  width: 100%;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${colorsButton.blue.hover};
    `}
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    display: flex;
    width: calc(100% - 15px);
  }
`;

const SelectedBlock = styled.div`
  position: absolute;
  background: ${colorsButton.blue.hover};
  border-radius: 3px 3px 0 0;
  padding: ${gutters.spacing(1, -2)}px 0 ${gutters.spacing(1, -3)}px;
  width: 72px;
  text-align: center;
  bottom: 0;
  left: calc(50% - 36px);

  p {
    font-size: ${fontSizes.micro};
    color: ${grayScale.white};
  }
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${gutters.spacing(3, -3)}px;

  & > * {
    display: flex;
    align-items: center;
  }

  & > div:first-child {
    width: calc(100% - 15px);
  }
`;

const BaseCard = ({ isSelected, title, children, footer, ...props }) => {
  const { t } = useTranslation();
  return (
    <CardWrapper isSelected={isSelected} {...props}>
      {title && <CardTitle>{title}</CardTitle>}
      {children}
      {footer && <CardFooter>{footer}</CardFooter>}
      {isSelected && (
        <SelectedBlock>
          <KizenTypography>{t('Selected')}</KizenTypography>
        </SelectedBlock>
      )}
    </CardWrapper>
  );
};

BaseCard.propTypes = {
  isSelected: PropTypes.bool,
  title: PropTypes.node,
  footer: PropTypes.node,
};

BaseCard.defaultProps = {
  isSelected: false,
  title: null,
  footer: null,
};

export default BaseCard;
