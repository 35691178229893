import { LongTextInput } from './styled';
import { InputProps } from './types';

export const LongText = ({
  step,
  value,
  width,
  placeholder,
  error,
  onChange,
}: InputProps) => (
  <LongTextInput
    value={value}
    width={width}
    placeholder={placeholder}
    error={error}
    onChange={(val: string) => onChange(step, val)}
  />
);
