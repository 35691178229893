import { useState, useEffect } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';

// On its own useAsyncFn() will always set value to undefined
// when the call is loading.  This hook will allow us to continue
// showing the previous value while the next value is loading.
export default function useAsyncFnKeepLast(...args) {
  const [forgetfulState, callback] = useAsyncFn(...args);
  const [state, setState] = useState(forgetfulState);
  useEffect(
    () =>
      setState((prev) => {
        if (forgetfulState.loading) {
          return {
            ...prev,
            loading: true,
          };
        }
        return forgetfulState;
      }),
    [forgetfulState]
  );
  return [state, callback];
}
