import { useTranslation } from 'react-i18next';
import { StyledReorderButtons, StyledRotatedButon } from './styles';
import { IconSizing } from '__components/Kizen/Icon';
import Icon from '__components/Kizen/Icon';
import IconButton from '__components/Kizen/IconButton';
import { grayScale } from '__app/colors';

type ReorderButtonsProps = {
  index: number;
  isFirst: boolean;
  isLast: boolean;
  onReorder: (index: number, offset: 1 | -1) => void;
};

const buttonColor = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

export const ReorderButtons = ({
  index,
  isFirst,
  isLast,
  onReorder,
}: ReorderButtonsProps) => {
  const { t } = useTranslation();

  const onReorderUp = () => {
    if (!isFirst) {
      onReorder(index, -1);
    }
  };

  const onReorderDown = () => {
    if (!isLast) {
      onReorder(index, 1);
    }
  };

  return (
    <StyledReorderButtons>
      <StyledRotatedButon
        title={t('Move Up')}
        sizing="dense"
        color={isFirst ? buttonColor.default : buttonColor}
        onClick={onReorderUp}
        disabled={isFirst}
      >
        <IconSizing size="12px">
          <Icon icon="chevron-down" />
        </IconSizing>
      </StyledRotatedButon>
      <IconButton
        title={t('Move Down')}
        sizing="dense"
        color={isLast ? buttonColor.default : buttonColor}
        onClick={onReorderDown}
        disabled={isLast}
      >
        <IconSizing size="12px">
          <Icon icon="chevron-down" />
        </IconSizing>
      </IconButton>
    </StyledReorderButtons>
  );
};
