import { useCallback, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueries } from 'react-query';
import { isEmpty } from 'lodash';

import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import MultiSelect from 'components/Inputs/MultiSelect';
import { KizenTypography } from '__app/typography';
import Loader from 'components/Kizen/Loader';

import { CUSTOM_OBJECTS, PAGE_CONFIGS } from 'queries/query-keys';
import CustomObjectsService from 'services/CustomObjectsService';
import TeamMemberService from 'services/TeamMemberService';
import {
  useActivitiesQuery,
  useDefaultActivityRelationshipsQuery,
} from 'queries/models/activites';

import { mapRelatedColumns } from 'components/Charts/ScheduledActivities/helpers';
import { defaultColumnSettings } from 'components/Charts/ScheduledActivities/constants';
import { ScheduledActivitiesColumnBuilder } from './ScheduledActivitiesColumnBuilder';
import { mapColumnSettings } from 'components/Charts/ScheduledActivities/helpers.js';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';

export const ScheduledActivitiesReportType = ({
  scrollActvitiyBuilderIntoView,
}) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);
  const {
    reportType,
    activities,
    hasDeletedActivities,
    scheduledActivitiesConfig,
  } = state;
  const selectRef = useRef(null);
  const { data, isLoading } = useActivitiesQuery({
    select: (results) => {
      return results.map((activity) => {
        return {
          label: activity.name,
          value: activity.id,
        };
      });
    },
  });

  const activitiesValue = useMemo(() => {
    return (
      activities?.map(({ label, value }) => {
        if (!isLoading && !data?.find((item) => item.value === value)) {
          return {
            label: t('[Deleted] - {{label}}', { label }),
            value,
            deleted: true,
          };
        }
        return { label, value };
      }) ?? []
    );
  }, [activities, t, data, isLoading]);

  useMetadataKey(
    'hasDeletedActivities',
    activitiesValue.some((item) => item.deleted)
  );

  const activitiesOnChange = useCallback(
    (value) => {
      setState('activities', value);
    },
    [setState]
  );

  const activityPageListQuery = useQuery(
    PAGE_CONFIGS.SCHEDULED_ACTIVITIES,
    () => TeamMemberService.getActivitiesListPageConfig(),
    {
      enabled: !scheduledActivitiesConfig,
    }
  );

  const savedColumnSettings = useMemo(() => {
    if (scheduledActivitiesConfig) {
      return scheduledActivitiesConfig.columns;
    }
    return activityPageListQuery?.data?.columns;
  }, [activityPageListQuery.data, scheduledActivitiesConfig]);

  const defaultActivityRelationshipsQuery =
    useDefaultActivityRelationshipsQuery();
  const { relatedObjects = null } = useMemo(() => {
    if (defaultActivityRelationshipsQuery.isLoading) {
      return {};
    }
    const doa = defaultActivityRelationshipsQuery.data;
    return {
      relatedObjects: mapRelatedColumns(doa),
    };
  }, [
    defaultActivityRelationshipsQuery.data,
    defaultActivityRelationshipsQuery.isLoading,
  ]);

  const columnSettings = useMemo(() => {
    return mapColumnSettings(savedColumnSettings, relatedObjects, t);
  }, [savedColumnSettings, relatedObjects, t]);

  const columnAssociatedObjectFieldIds = useMemo(() => {
    return columnSettings.columns
      .filter(({ fieldId }) => fieldId)
      .map(({ fieldId, objectId }) => ({
        fieldId,
        objectId,
      }));
  }, [columnSettings]);

  const objectQueries = useQueries(
    columnAssociatedObjectFieldIds.map(({ objectId }) => {
      return {
        queryKey: CUSTOM_OBJECTS.DETAILS(objectId),
        queryFn: () => CustomObjectsService.getCustomObjectDetails(objectId),
        enabled: Boolean(objectId),
      };
    })
  );

  const objectsById = useMemo(() => {
    const result = {};
    for (const query of objectQueries) {
      if (query.data) {
        result[query.data.id] = {
          ...query.data,
          fieldsById: query.data.fields.reduce((acc, field) => {
            acc[field.id] = field;
            return acc;
          }, {}),
        };
      }
    }
    return result;
  }, [objectQueries]);

  const filteredColumnSettings = useMemo(() => {
    if (isEmpty(objectsById)) {
      return columnSettings;
    }
    const columnFilter = ({ fieldId, objectId, visible }) =>
      !fieldId || objectsById?.[objectId]?.fieldsById?.[fieldId] || !visible;
    const activeColumns = columnSettings.columns.filter(columnFilter);
    return {
      ...columnSettings,
      columns: activeColumns,
    };
  }, [columnSettings, objectsById]);

  if (reportType?.value !== REPORT_TYPES.SCHEDULED_ACTIVITIES) {
    return null;
  }

  return (
    <>
      <StyledWizardField top={12}>
        <KizenTypography type="subheader">
          {t('Choose Activities to Display (Leave Blank for All)')}
        </KizenTypography>
        <MultiSelect
          ref={selectRef}
          isLoading={isLoading}
          onChange={activitiesOnChange}
          placeholder={t('Find Activities')}
          className="modal-multiselect activity-types"
          options={data ?? []}
          value={activitiesValue}
          error={hasDeletedActivities}
        />
      </StyledWizardField>
      <StyledWizardField flex>
        {!activityPageListQuery?.isLoading && (
          <ScheduledActivitiesColumnBuilder
            scrollActvitiyBuilderIntoView={scrollActvitiyBuilderIntoView}
            columns={filteredColumnSettings.columns}
            defaultColumns={defaultColumnSettings}
            scheduledActivitiesConfig={scheduledActivitiesConfig}
          />
        )}
        <Loader loading={activityPageListQuery.isLoading} />
      </StyledWizardField>
    </>
  );
};
