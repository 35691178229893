import { useMemo } from 'react';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import { KizenTypography } from 'app/typography';
import { MonthCalendarCellHeaderProps } from './props';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  padding: ${gutters.spacing()}px;
`;

const DateNumber = styled(KizenTypography)`
  color: ${({ isCurrentMonth }) =>
    isCurrentMonth ? grayScale.dark : grayScale.mediumDark};
`;

const MonthCalendarCellHeader = ({
  date,
  calendarMonth,
  className,
  children,
  ...rest
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isCurrentMonth = date.getMonth() === calendarMonth;
  const dayOfMonth = date.getDate();
  const dateTitle = useMemo(
    () =>
      dayOfMonth === 1
        ? `${new Intl.DateTimeFormat(language, { month: 'short' })
            .format(date)
            .toUpperCase()} ${dayOfMonth}`
        : dayOfMonth,
    [dayOfMonth, date, language]
  );

  return (
    <Container className={className} {...rest}>
      <DateNumber isCurrentMonth={isCurrentMonth}>{dateTitle}</DateNumber>
      {children}
    </Container>
  );
};

export default MonthCalendarCellHeader;

MonthCalendarCellHeader.propTypes = MonthCalendarCellHeaderProps;
