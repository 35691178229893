import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from '../../../app/colors';
import { borderRadii, gutters } from '../../../app/spacing';
import { truncateCss } from '../../../app/typography';
import { TextEllipsisWithTooltip } from '../Table';
import Icon from '../Icon';

const StyledPill = styled.span`
  display: flex;
  padding: calc(7px - 1px) 7px calc(7px - 3px) 7px;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  border-left: 3px solid ${colorsButton.blue.default};
  // Note we rely on this spacing for MultiSelect. Feel free to
  // customize elsewhere using styled() for other purposes, though.
  margin-left: ${gutters.spacing(2)}px;
  margin-bottom: ${gutters.spacing(1)}px;
  background-color: ${grayScale.white};
  ${(props) =>
    props.maxWidth ? `max-width: ${props.maxWidth};` : 'max-width: 100%;'}
  ${truncateCss}
  .selected-value-text {
    display: inline-block;
    ${truncateCss}
  }
  .selected-value-remove {
    cursor: pointer;
    color: ${grayScale.mediumDark};
    margin-bottom: 1px;
    font-size: 95%;
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${grayScale.light};
        pointer-events: none;
      `};
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: ${gutters.spacing(1)}px;
`;

const Pill = React.forwardRef(function Pill(
  {
    id,
    name,
    maxWidth,
    hideRemoveIcon,
    onRemove,
    disabled,
    PillComponent,
    ...others
  },
  ref
) {
  return (
    <StyledPill ref={ref} maxWidth={maxWidth} {...others}>
      {!hideRemoveIcon && (
        <StyledIcon
          icon="close"
          size="lg"
          className="selected-value-remove"
          onClick={() => {
            if (!disabled) {
              onRemove(id);
            }
          }}
        />
      )}
      {PillComponent && (
        <PillComponent as="span" className="selected-value-text" value={id}>
          {name}
        </PillComponent>
      )}
      {!PillComponent && (
        <TextEllipsisWithTooltip as="span" className="selected-value-text">
          {name}
        </TextEllipsisWithTooltip>
      )}
    </StyledPill>
  );
});

Pill.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hideRemoveIcon: PropTypes.bool,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool,
  maxWidth: PropTypes.string,
  PillComponent: PropTypes.elementType,
};

Pill.defaultProps = {
  hideRemoveIcon: true,
  onRemove: () => {},
  disabled: false,
  maxWidth: '100%',
  PillComponent: null,
};

export default Pill;
