import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colorsButton, colorsText, grayScale, shadowOverlay } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { borderRadii, gutters } from 'app/spacing';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import { css } from '@emotion/core';
import { useTooltip, truncationPredicates } from 'components/Kizen/Tooltip';

const overflowXOrY = (el) =>
  truncationPredicates.x(el) || truncationPredicates.y(el);
const Wrapper = styled.div`
  height: 36px;
  display: flex;
  border-radius: ${borderRadii.small};
  overflow: hidden;
  ${shadowOverlay}
  > div:first-child {
    width: 3px;
    background-color: ${({ color }) => color};
  }
  > div:last-child {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 ${gutters.spacing(2, -2)}px;
    border: 1px solid ${grayScale.medium};
    border-left: none;
    border-top-right-radius: ${borderRadii.small};
    border-bottom-right-radius: ${borderRadii.small};
    background-color: ${({ backgroundColor }) =>
      backgroundColor || grayScale.white};
    .ReorderIcon {
      color: ${colorsButton.iconGray.default};
    }
    ${({ disabled }) =>
      !disabled &&
      css`
        cursor: grab;
        &:hover .ReorderIcon {
          color: ${colorsButton.iconGray.hover};
        }
      `}
  }
  & > .Handle * {
    pointer-events: none;
  }
`;

const HandleIconStyles = styled(IconSizing)`
  margin-right: ${gutters.spacing(3)}px;
`;

const IconStyles = styled(IconSizing)`
  margin-right: ${gutters.spacing(2)}px;
  color: ${colorsText.dark};
`;

const Label = styled(KizenTypography)`
  margin-top: 2px; // Design nudge
  text-overflow: ellipsis; // ensure truncation when translated
  overflow: hidden;
  white-space: nowrap;
  max-width: 65px;
`;

const StepOption = React.forwardRef(({ icon, label, ...props }, ref) => {
  const [tooltipProps, tooltip] = useTooltip({}, overflowXOrY);
  return (
    <Wrapper ref={ref} {...props}>
      <div />
      <div className="Handle">
        <HandleIconStyles size="15px">
          <Icon className="ReorderIcon" icon="reorder" />
        </HandleIconStyles>
        <IconStyles size="15px">{icon}</IconStyles>
        <Label as="span" {...tooltipProps}>
          {label}
        </Label>
        {tooltip}
      </div>
    </Wrapper>
  );
});

StepOption.displayName = 'StepOption';

StepOption.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
};

export default StepOption;
