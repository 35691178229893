// recurse down errors object to find and return the value of errorKey
export const fetchErrorByKey = (currentObj, errorKey) => {
  let result = null;

  const recurseErrors = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return;
    }

    Object.entries(obj).forEach(([key, value]) => {
      if (key === errorKey) {
        result = value;
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          recurseErrors(item);
        });
      } else if (typeof value === 'object' && value !== null) {
        recurseErrors(value);
      }
    });
  };

  recurseErrors(currentObj);
  return result;
};

export const getErrorMessage = (value) => {
  if (typeof value === 'string') {
    return value;
  }
  if (value && typeof value === 'object') {
    return Object.values(value).find(getErrorMessage) || null;
  }
  return null;
};

export const useErrorByKey = (errorsObject, errorKey) => {
  return fetchErrorByKey(errorsObject, errorKey);
};
