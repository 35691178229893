import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getChosenBusiness } from 'store/authentication/selectors';
import { SENDER_TYPE } from './components';
import { useMemo } from 'react';

const {
  LAST_ROLE,
  TEAM_MEMBER,
  BUSINESS,
  LAST_ANY,
  ENTITY_RECORD_OWNER,
  TEAM_MEMBER_FIELD,
  SPECIFIC_INTEGRATED_INBOX,
} = SENDER_TYPE;

export default function useSendInfo(email) {
  const { t } = useTranslation();
  const lastTeamMember = t('Last Team Member');
  const DEFAULT = t('Default');
  const deletedRole = t('[Deleted] Role]');
  const chosenBusiness = useSelector(getChosenBusiness);

  return useMemo(() => {
    let emailString;
    let sendFromString;
    let fromNameString;

    if (email && email.sender) {
      const {
        sender: { type, customName, subType },
        senderTeamMember,
        senderRole,
        senderField,
        externalAccount,
      } = email;

      switch (type) {
        case TEAM_MEMBER:
          if (senderTeamMember) {
            const fullName = [
              senderTeamMember.firstName,
              senderTeamMember.lastName,
            ]
              .filter(Boolean)
              .join(' ');
            emailString = senderTeamMember?.email
              ? ` (${senderTeamMember.email})`
              : '';
            sendFromString =
              senderTeamMember.displayName ||
              `${senderTeamMember?.fullName || fullName}${emailString}`;
            fromNameString = customName || senderTeamMember?.fullName;
          } else {
            sendFromString = t('[Deleted]');
            fromNameString = customName || t('[Deleted]');
          }
          break;
        case LAST_ROLE:
          sendFromString = `${lastTeamMember} (${
            senderRole?.label || deletedRole
          })`;
          fromNameString = customName || DEFAULT;
          break;
        case LAST_ANY:
          sendFromString = lastTeamMember;
          fromNameString = customName || DEFAULT;
          break;
        case BUSINESS:
          emailString = chosenBusiness.reply_to_email
            ? ` (${chosenBusiness.reply_to_email})`
            : '';
          sendFromString = `${chosenBusiness.name}${emailString}`;
          fromNameString = customName || chosenBusiness.name;
          break;
        case TEAM_MEMBER_FIELD:
          sendFromString = senderField.label || senderField.displayName;
          fromNameString = customName || DEFAULT;
          break;

        case SPECIFIC_INTEGRATED_INBOX:
          sendFromString = externalAccount?.label || t('[Deleted]');
          fromNameString = '';
          break;

        default:
          sendFromString = '';
          fromNameString = '';
      }
      if (subType === ENTITY_RECORD_OWNER) {
        sendFromString = t('Entity Record Owner');
        fromNameString = '';
      }
    }
    return {
      sendFromString,
      fromNameString,
    };
  }, [
    email,
    chosenBusiness.name,
    chosenBusiness.reply_to_email,
    lastTeamMember,
    DEFAULT,
    deletedRole,
    t,
  ]);
}
