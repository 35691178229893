import { useState, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import TeamMemberService from 'services/TeamMemberService';
import useModal from 'components/Modals/useModal';

import { getSortParam } from 'utility/getSortParam';

import { useApiKeysReactQuery } from './useApiKeysReactQuery';
const defaultApiKeysSort = { column: 'key_preview', direction: 'desc' };

export const useApiKeys = (employeeId) => {
  const { t } = useTranslation();
  const [createdKey, setCreatedKey] = useState();
  const [viewedKey, setViewedKey] = useState();
  const [viewEmployee, setViewEmployee] = useState({});
  const [search, setSearch] = useState(null);
  const [messagesSort, setMessagesSort] = useState(defaultApiKeysSort);
  const [apiKeysModalProps, , apiKeysModal] = useModal({
    handleHide: () => {
      setCreatedKey(null);
      setViewedKey(null);
    },
  });
  const anyKeyRef = useRef(false);
  const toDeleteRef = useRef(null);
  const setToDelete = useCallback(
    (id) => {
      toDeleteRef.current = id;
    },
    [toDeleteRef]
  );

  const { infiniteQuery, deleteApiKeyMutation, creatApiKeyMutation } =
    useApiKeysReactQuery(
      {
        search,
        ordering: getSortParam(messagesSort),
        pageSize: 20,
        employeeId,
      },
      {
        created: (data) => {
          setCreatedKey(data);
          apiKeysModal.show();
        },
      }
    );

  const {
    data: apiKeys,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,
  } = infiniteQuery;

  const handleViewApiKey = useCallback(
    async (id, employee) => {
      const data = await TeamMemberService.getApiKey(id);
      setViewedKey(data);
      setViewEmployee(employee);
      apiKeysModal.show();
    },
    [apiKeysModal]
  );

  const messageHeadData = useMemo(
    () => ({
      meta: {
        sort: messagesSort,
        onSort: (column, direction) =>
          setMessagesSort({
            column,
            direction,
          }),
      },
    }),
    [messagesSort, setMessagesSort]
  );

  const apiKeysModalButtonProps = useMemo(
    () =>
      createdKey
        ? {
            buttonText: t('Close'),
            heading: t('API Key Created'),
            actionBtnColor: 'blue',
            leftBtn: null,
          }
        : {
            buttonText: t('Close'),
            heading: t('Your API Key'),
            actionBtnColor: 'blue',
            leftBtn: null,
          },

    [createdKey, t]
  );

  // prevent search filtering out entries and giving a false null
  if (!search) {
    anyKeyRef.current = apiKeys?.pages && Boolean(apiKeys?.pages[0].count);
  } else if (
    !anyKeyRef.current &&
    apiKeys?.pages &&
    Boolean(apiKeys?.pages[0].count)
  ) {
    anyKeyRef.current = true;
  }

  const [confirmDeletionModalProps, , confirmDeletionModal] = useModal({
    handleSubmit: async () => {
      deleteApiKeyMutation.mutate(toDeleteRef.current);
      setToDelete(null);
    },
    handleHide: () => setToDelete(null),
  });

  return {
    handleViewApiKey,
    deleteApiKeyMutation,
    creatApiKeyMutation,

    apiKeys,
    createdKey,
    viewedKey,
    viewEmployee,
    messageHeadData,
    messagesSort,
    setMessagesSort,

    apiKeysModalProps,
    apiKeysModalButtonProps,
    search,
    setSearch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,

    confirmDeletionModalProps,
    confirmDeletionModal,
    setToDelete,

    anyApiKeys: anyKeyRef.current,
  };
};
