import { css } from '@emotion/core';
import { grayScale } from 'app/colors';
import { CONTENT_MAX_WIDTH } from 'components/Layout/PageContentWidth';
import { breakpoints, gutters } from '../../app/spacing';

const pageBreakpoint = breakpoints.lg;

export const pageSizingStyles = css`
  width: 100%;
  margin: 0 auto;
  // Desired visual width is 1372 with 20px spacing on left/right.
  // (1412 - 1372) / 2 = 20 = gutters.spacing(4)

  padding: 0 ${gutters.spacing(3)}px;
  @media (min-width: ${pageBreakpoint + 1}px) {
    max-width: calc(
      ${CONTENT_MAX_WIDTH} + 2 * ${gutters.spacing(4)}px
    ); // 1372px with 20px spacing on left and right
    padding: 0 ${gutters.spacing(4)}px;
  }
`;

export const navColors = {
  black: '#2f3131',
  blue: '#64C3F4',
  background: grayScale.white,
  border: grayScale.mediumLight,
};
