import { fork } from 'redux-saga/effects';

import contactPage from './contactsPage/saga';
import formsPage from './formsPage/saga';
import automationPage from './automationsPage/saga';
import surveysPage from './surveysPage/saga';
import customObjectsPage from './customObjectsPage/saga';
import customObjectsSettingsPage from './customObjectsSettingsPage/saga';
import recordsPage from './customObjectsRecordsPage/saga';
import libraryPage from './libraryPage/saga';
import activitiesPage from './activitiesPage/saga';
import recordDetailPage from './recordDetailPage/saga';
import adManagerPage from './adManagerPage/saga';

export default function* () {
  yield fork(contactPage);
  yield fork(formsPage);
  yield fork(automationPage);
  yield fork(surveysPage);
  yield fork(customObjectsPage);
  yield fork(customObjectsSettingsPage);
  yield fork(recordsPage);
  yield fork(libraryPage);
  yield fork(activitiesPage);
  yield fork(recordDetailPage);
  yield fork(adManagerPage);
}
