import { PropsWithChildren, createContext, useContext, Dispatch } from 'react';
import { AxiosResponse } from 'axios';
import { SmartConnector } from './types';
import { RunSmartConnectorModalProps } from './RunSmartConnectorModal';

type SmartConnectorsCtx = {
  smartConnectors: SmartConnector[];
  loadingSmartConnectors: boolean;
  totalSmartConnectors: number;
  search: string;
  pageNumber: number;
  pageSize: number;
  sort: string;
  smartConnectorDispatch: Dispatch<any>;
  handleChangePageNumber: (pageNumber: number) => void | undefined;
  smartConnectorIdForDeletion: any;

  historySmartConnector: SmartConnector | null;

  historyModalProps: any;
  historyModal: any;
  handleChangePageSize: (pageSize: number) => void | undefined;
  handleChangeSearch: (search: string) => void | undefined;
  handleChangeSort: (column: string, direction: string) => void;
  handleDeleteSmartConnector: (id: string) => void | undefined;
  handleDuplicateSmartConnector: (
    id: string | null
  ) => Promise<void> | Promise<AxiosResponse<any, any>> | void | undefined;
  handlePatchSmartConnector: (
    id: string,
    patch: any
  ) => void | Promise<void> | Promise<any>;
  runSmartConnectorModalProps: RunSmartConnectorModalProps | null;
  showRunSmartConnectorModal: (
    id: string,
    isDryRun: boolean,
    canSkipDiffCheck: boolean
  ) => void;
};

const context = createContext<SmartConnectorsCtx>({
  smartConnectors: [],
  loadingSmartConnectors: false,
  totalSmartConnectors: 0,
  search: '',
  pageSize: 10,
  pageNumber: 1,
  sort: '',
  smartConnectorDispatch: () => {},
  smartConnectorIdForDeletion: null,
  historySmartConnector: null,
  historyModalProps: null,
  historyModal: null,
  handleChangePageNumber: () => {},
  handleChangePageSize: () => {},
  handleChangeSearch: () => {},
  handleChangeSort: () => {},
  handleDeleteSmartConnector: () => {},
  handleDuplicateSmartConnector: () => {},
  handlePatchSmartConnector: () => {},
  runSmartConnectorModalProps: null,
  showRunSmartConnectorModal: () => {},
});

export const useSmartConnectorsContext = () => {
  return useContext(context);
};

export const SmartConnectorsProvider = ({
  children,
  value,
}: PropsWithChildren<{ value: SmartConnectorsCtx }>) => {
  return <context.Provider value={value}>{children}</context.Provider>;
};
