import {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
} from 'react';
import { snakeToCamelCaseKeys } from '../utility/helpers';

// The debounce function receives our function as a parameter
const requestAnimationFrameDebounce = (fn) => {
  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
      fn(...params);
    });
  };
};

const INITIAL_STYLES = {
  table: {
    wrapperHeight: 0,
    paddingTop: 0,
    topOffset: 0,
    bottomOffset: 0,
  },
  select: {
    wrapperHeight: 30,
    paddingTop: 0,
    topOffset: 0,
    bottomOffset: 0,
  },
};

const calcScrollHorizon = (el, bufferHeight, headerHeight) => {
  if (el) {
    const horizonTop = el.scrollTop - bufferHeight;
    const horizonBottom =
      el.getBoundingClientRect().height -
      headerHeight +
      bufferHeight +
      el.scrollTop;
    return {
      horizonTop,
      horizonBottom,
      topOffset: el.scrollTop,
      bottomOffset:
        el.getBoundingClientRect().height + el.scrollTop - el.scrollHeight,
    };
  }

  return {
    topHorizon: 0,
    bottomHorizon: 0,
  };
};

// We want to de-dupe the list, but can't do it at query time
// because we don't want to have data in the cache that wasn't returned from
// the API. This is a bit less effecient, but allows us to keep the cache
// clean.
const dedupeItems = (items = [], key = 'id') => {
  const itemMap = {};

  const filtered = items.filter(({ item }) => {
    if (itemMap[item[key]]) {
      itemMap[item[key]] += 1;
      return false;
    }

    itemMap[item[key]] = 1;
    return true;
  });
  return filtered;
};

export const useInfinityScroll = ({
  wrapperElement,
  data,
  fetchNextPage,
  hasNextPage,
  defaultHeight,
  itemsBuffer = 0,
  isFetchingNextPage,
  objectToOption,
  component = 'select',
  headerHeight = 0,
  shouldFetchOnMount = false,
  chosenItems = [],
}) => {
  const metaStyles = useRef({ ...INITIAL_STYLES[component] });
  const metaRef = useRef({});
  const [itemsMeta, setItemsMeta] = useState({});

  const [horizons, setHorizons] = useState({
    topHorizon: 0,
    bottomHorizon: 0,
    topOffset: 0,
    bottomOffset: 0,
  });

  const handleItemMounted = useCallback((item, { height }) => {
    const { id } = item;

    if (!metaRef.current[item.id]) {
      metaRef.current = {
        ...metaRef.current,
        [id]: {
          height,
        },
      };

      setItemsMeta(() => metaRef.current);
    }
  }, []);

  const horizonList = useMemo(() => {
    let topHorizon = 0;

    if (component === 'table') {
      metaStyles.current.wrapperHeight = 0;
    }

    const items = data
      ? data.pages.reduce((currentGroup, group, groupIndex) => {
          const itemsChunk = group.results.reduce((current, item, index) => {
            if (chosenItems.includes(item.id)) {
              return current;
            }
            const height =
              itemsMeta && itemsMeta[item.id]
                ? itemsMeta[item.id].height
                : defaultHeight;
            const bottomHorizon = topHorizon + height;
            const entry = {
              item: item,
              index,
              groupIndex,
              topHorizon,
              bottomHorizon,
            };
            topHorizon = bottomHorizon;
            current = [...current, entry];
            return current;
          }, []);
          return [...currentGroup, ...itemsChunk];
        }, [])
      : [];

    const dedupedItems = dedupeItems(items);

    if (dedupedItems.length) {
      metaStyles.current.wrapperHeight = topHorizon;
    } else {
      metaStyles.current.wrapperHeight = defaultHeight;
    }
    return { items: dedupedItems };
  }, [component, data, chosenItems, itemsMeta, defaultHeight]);

  const bufferHeight = itemsBuffer * defaultHeight;

  const calcHorizons = useCallback(() => {
    const { horizonTop, horizonBottom, topOffset, bottomOffset } =
      calcScrollHorizon(wrapperElement, bufferHeight, headerHeight);

    setHorizons(() => ({
      horizonTop,
      horizonBottom,
      topOffset,
      bottomOffset,
    }));

    return { topOffset, bottomOffset };
  }, [wrapperElement, bufferHeight, headerHeight]);

  const handleScroll = useCallback(() => {
    const { topOffset, bottomOffset } = calcHorizons();
    const needFetch = topOffset && bottomOffset + bufferHeight > 0;
    if (needFetch && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [
    calcHorizons,
    bufferHeight,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  ]);

  useEffect(() => {
    const el = wrapperElement;
    const handle = requestAnimationFrameDebounce(handleScroll);
    if (el) {
      // set up debounced listner
      el.addEventListener('scroll', handle, {
        passive: true,
      });

      shouldFetchOnMount ? handleScroll() : calcHorizons();
    }
    // clean up
    return () => {
      el?.removeEventListener('scroll', handle);
    };
  }, [calcHorizons, handleScroll, shouldFetchOnMount, wrapperElement]);

  const visibleData = useMemo(() => {
    const { horizonBottom, horizonTop } = horizons;
    const { items } = horizonList;
    let minVisible = null;
    metaStyles.current.paddingTop = 0;
    const visibleItems = items.reduce(
      (current, { item, topHorizon, bottomHorizon }) => {
        // isVisible is set to true if thre is no meta data so it get's rendered the first time
        const isVisible =
          itemsMeta === null ||
          !itemsMeta[item.id] ||
          (topHorizon < horizonBottom && horizonTop < bottomHorizon);
        // isRealVisible is if it's really between bounds
        const isRealVisible =
          topHorizon < horizonBottom && horizonTop < bottomHorizon;

        current = {
          ...current,
          [item.id]: {
            isVisible,
            isRealVisible,
          },
        };
        if (!minVisible && isVisible) {
          minVisible = item.id;
          metaStyles.current.paddingTop = topHorizon || 0;
        }
        return current;
      },
      {}
    );

    //  safe fail for if we can't display any items that have been loaded
    if (items && items.length < itemsBuffer && hasNextPage) {
      // this maybe cause an issue, i.e. if the data is fetching the first page,it could keep fetching the next page
      fetchNextPage();
    }
    const lastInView =
      items && items[items.length - 1]?.item
        ? visibleItems[items[items.length - 1]?.item.id].isRealVisible
        : false;
    return { minVisible, visibleItems, lastInView };
  }, [
    horizons,
    horizonList,
    hasNextPage,
    itemsMeta,
    fetchNextPage,
    itemsBuffer,
  ]);

  const visibleItems = useMemo(() => {
    const { items } = horizonList;
    const { visibleItems } = visibleData;
    return items.reduce((acc, el) => {
      if (visibleItems[el.item.id]?.isVisible) {
        return [
          ...acc,
          {
            ...el,
            item: objectToOption
              ? objectToOption(snakeToCamelCaseKeys(el.item))
              : el.item,
          },
        ];
      }
      return acc;
    }, []);
  }, [horizonList, objectToOption, visibleData]);

  useLayoutEffect(() => {
    // set styles for the first child element
    if (wrapperElement) {
      wrapperElement.firstChild.style.height = `${metaStyles.current.wrapperHeight}px`;
      wrapperElement.firstChild.style.paddingTop = `${metaStyles.current.paddingTop}px`;
    }
  }, [visibleItems, wrapperElement]);

  return {
    visibleData,
    horizonList,
    visibleItems,
    fetchNextPage,
    handleItemMounted,
  };
};
