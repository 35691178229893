import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import BaseDragAndDropLayout from 'components/DragAndDropLayout';
import KizenTypography from 'app/kizentypo';
import Cols from 'components/Layout/Cols';
import { CO_STAGE_STATUS_OPEN } from 'components/Wizards/CustomObject/utilities';
import Section from 'components/Wizards/CustomObject/components/Section';
import NewItemButton from 'components/Modals/Common/NewItemButton';
import StageDeletionModal from 'components/Wizards/CustomObject/components/StageDeletionModal';
import DraggableItem from 'components/Wizards/CustomObject/components/DraggableItem';
import { useToast } from 'components/ToastProvider';
import { getToastConfig } from 'components/Wizards/CustomObject/utilities';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import useModal from 'components/Modals/useModal';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListenersInline } from 'hooks/keyboardEventHandler/useKeyListenersInline';
const DEFAULT_SPACE_FOR_NEW_STAGE_ITEM = 57;

const DragAndDropLayout = styled(BaseDragAndDropLayout)`
  padding: 0;
  margin: -${gutters.spacing(3 / 2)}px 0;
  margin-bottom: ${gutters.spacing(1, 1.5)}px;
`;

const HeaderCols = styled(Cols)`
  // 2 sets of 10px padding, 11px icon, 1px border from DragItem
  margin-left: ${gutters.spacing(4, 12)}px;
  // 1 set of 10px padding, 1px border from DragItem
  margin-right: ${gutters.spacing(2, 1)}px;
  margin-bottom: ${gutters.spacing(2, 4)}px;
`;

const StyledSection = styled(Section)`
  margin-bottom: ${gutters.spacing(5, 0.5)}px;
`;
const generateUniqueStageName = (existingNames) => {
  const lastElement = existingNames[existingNames.length - 1] || '';
  const match = lastElement.match(/Stage (\d+)$/);
  let i = match?.[1] ?? existingNames.length + 1;
  let newName = `Stage ${i}`;
  while (existingNames.includes(newName)) {
    newName = `Stage ${i++}`;
  }

  return newName;
};

export default function WorkflowStagesSubsection({
  values,
  model,
  onDeleteStage,
  handleInputChange,
  validationProps,
  setStageToDelete,
  ...props
}) {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [removingStage, setRemovingStage] = useState(null);
  const [confirmationModalProps, , confirmationModal] = useModal();

  const handleAddNewStage = useCallback(async () => {
    if (values.useAiToUpdatePercentage) {
      confirmationModal.show();
      return;
    }
    const existingStageNames =
      values.pipeline?.stages?.map((stage) => stage.name.trim()) || [];

    await handleInputChange('pipeline', {
      ...values.pipeline,
      stages: [
        ...(values?.pipeline?.stages ?? []),
        {
          name: generateUniqueStageName(existingStageNames),
          status: CO_STAGE_STATUS_OPEN,
          percentageChanceToClose: null,
          order: values.pipeline?.stages?.length + 1,
        },
      ],
    });

    const modalBody = document.querySelector(
      '.modal-open .modal-content > div:last-child'
    );
    await modalBody.scroll({
      top: modalBody.scrollTop + DEFAULT_SPACE_FOR_NEW_STAGE_ITEM,
      behavior: 'smooth',
    });
  }, [
    handleInputChange,
    values.useAiToUpdatePercentage,
    values.pipeline,
    confirmationModal,
  ]);

  const onDeleteHandler = useCallback(
    async (newStageId) => {
      try {
        setStageToDelete((prev) => [
          ...prev,
          { id: removingStage.id, newStageId },
        ]);
        setRemovingStage(null);
        handleInputChange('pipeline', {
          ...values.pipeline,
          stages: values.pipeline?.stages.filter((s) => s !== removingStage),
        });
      } catch (error) {
        showToast(getToastConfig(t, false).error);
      }
    },
    [removingStage, showToast, t, handleInputChange, values, setStageToDelete]
  );
  const fieldListeners = useMemo(() => {
    return values.pipeline?.stages.flatMap((stage, i) => [
      { id: `stage-name-${i}` },
      { id: `stage-status-${i}` },
      { id: `stage-percentageChanceToClose-${i}` },
    ]);
  }, [values.pipeline?.stages]);
  const { assignFieldHandle, getKeyListenersProps } = useKeyListenersInline(
    fieldListeners || [],
    {},
    () => true,
    false
  );

  return (
    <KeyBoardContext.Provider
      value={{ assignFieldHandle, getKeyListenersProps }}
    >
      <StyledSection label={t('Stages')} {...props}>
        <HeaderCols columns={3} gutter={`${gutters.spacing(6)}px`}>
          <KizenTypography as="span" weight="bold">
            {t('Stage Name')}
          </KizenTypography>
          <KizenTypography as="span" weight="bold">
            {t('Stage Status')}
          </KizenTypography>
          {values.includePercentageToClose && (
            <KizenTypography as="span" weight="bold">
              {t('% Chance to Close')}
            </KizenTypography>
          )}
        </HeaderCols>

        <DragAndDropLayout
          items={values.pipeline?.stages.map((stage, i) => ({ id: i, stage }))}
          onChange={(stagesWithId) => {
            handleInputChange('pipeline', {
              ...values.pipeline,
              stages: stagesWithId.map(({ stage }, index) => ({
                ...stage,
                order: index,
              })),
            });
          }}
          handleClassName="itemTarget"
          itemClassName="dndItemWrapper"
          data-qa={'drag-and-drop-layout'}
          itemElement={
            <DraggableItem
              useAiToUpdatePercentage={values.useAiToUpdatePercentage}
              pipeline={values.pipeline}
              validationProps={validationProps}
              includePercentageToClose={values.includePercentageToClose}
              onStageChange={(stage, patch) => {
                handleInputChange('pipeline', {
                  ...values.pipeline,
                  stages: values.pipeline?.stages.map((s) => {
                    if (s !== stage) {
                      return s;
                    }
                    return { ...stage, ...patch };
                  }),
                });
              }}
              onClickRemove={(stage) => {
                if (stage.id) {
                  setRemovingStage(stage);
                } else {
                  handleInputChange('pipeline', {
                    ...values.pipeline,
                    stages: values.pipeline?.stages.filter((s) => s !== stage),
                  });
                }
              }}
            />
          }
        />

        <NewItemButton
          data-qa={'add-stage'}
          onClick={handleAddNewStage}
        >{`+ ${t('Add Stage')}`}</NewItemButton>
        {removingStage && (
          <StageDeletionModal
            show
            model={values}
            stage={removingStage}
            onHide={() => setRemovingStage(null)}
            onConfirm={onDeleteHandler}
          />
        )}
        <ConfirmationModal
          heading={t('Please Turn Off AI Before Adding Stage')}
          leftBtn={null}
          buttonText={t('Close')}
          actionBtnColor="blue"
          {...confirmationModalProps}
        >
          {t(
            'AI is currently calculating your % chance to close for each stage. Adding a stage will interrupt this process, so you must disable AI first.'
          )}
        </ConfirmationModal>
      </StyledSection>
    </KeyBoardContext.Provider>
  );
}

WorkflowStagesSubsection.defaultProps = {
  model: null,
};
