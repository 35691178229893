import React, { useRef } from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';

import { tableHover } from 'app/colors';
import { gutters, hideScrollbarCss, layers } from 'app/spacing';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { PAGE_TOOLBAR_HEIGHT } from '../Layout/PageToolbar';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';
import DotsScrollbar from './DotsScrollbar';
import { useNavBarState } from 'app/navBarController';

const PADDING_TO_SCROLL = gutters.spacing(6);

const BoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(
    100vh - ${PAGE_TOOLBAR_HEIGHT}px - ${({ navHeight }) => navHeight}px - 41px
  );
  width: 100%;
  margin: 0 auto;
`;

const ColumnWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  height: 100%;
  padding-bottom: ${PADDING_TO_SCROLL}px;
  scroll-snap-type: x mandatory;

  & > div:nth-child(2) {
    margin-left: ${gutters.spacing(9)}px;
  }

  & > div:nth-last-child(2) {
    margin-right: ${gutters.spacing(9)}px;
  }

  ${hideScrollbarCss}
`;

const Gradient = styled((props) => (
  <BaseGradient fadeStart={0} fadeLength={35} color={tableHover} {...props} />
))`
  height: 100%;
  z-index: ${layers.content(0, 1)};
`;

const SmallBoard = ({ columnAmount, children, ...props }) => {
  const boardWrapperRef = useRef();
  const { height } = useNavBarState();
  const [, rightScrolled, , leftScrolled] = useEndsOfScroll(boardWrapperRef);

  return (
    <BoardWrapper {...props} navHeight={height}>
      <ColumnWrapper ref={boardWrapperRef}>
        <Gradient position="left" fadeIn={!leftScrolled} />
        {children}
        <Gradient position="right" fadeIn={!rightScrolled} />
      </ColumnWrapper>
      <DotsScrollbar amount={columnAmount} target={boardWrapperRef} />
    </BoardWrapper>
  );
};

SmallBoard.propTypes = {
  columnAmount: PropTypes.number.isRequired,
};

export default SmallBoard;
