import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import UUID from 'utility/UUID';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import FieldLayout from 'components/FieldLayout';
import { gutters } from 'app/spacing';
import {
  FieldActions,
  cleanOptions,
  optionsAreValid,
  validateOptions,
  OptionsLimitWarning,
} from './common';

const Wrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

const stageOption = (option) => {
  if (Array.isArray(option)) {
    return option.map(stageOption);
  }
  return {
    id: option.id || `tmp_${UUID.generate()}`,
    ...option,
  };
};

export default function SimpleOptions({
  updateField,
  updateValidation,
  formData,
  optionsLimit,
  importedOptions,
}) {
  const { t } = useTranslation();
  const [stagedOptions, setStagedOptions] = useState(
    formData.options.length
      ? stageOption(formData.options)
      : [stageOption({ name: `${t('Option')} 1` })]
  );

  const isValid = optionsAreValid(formData.options);

  useEffect(() => {
    updateValidation(isValid);
  }, [isValid, updateValidation]);

  useEffect(() => {
    setStagedOptions((prevState) => [
      ...prevState,
      ...importedOptions.map(stageOption),
    ]);
  }, [importedOptions]);

  useEffect(() => {
    updateField('options', cleanOptions(stagedOptions));
  }, [stagedOptions, updateField]);

  const handleAddOption = () => {
    setStagedOptions([
      ...stagedOptions,
      stageOption({
        newest: true,
        name: `${t('Option')} ${stagedOptions.length + 1}`,
      }),
    ]);
  };

  const handleChangeName = (name, field) => {
    delete field.newest;
    const index = stagedOptions.findIndex((f) => f.id === field.id);
    const shallowCopy = [...stagedOptions];
    shallowCopy.splice(index, 1, { ...field, name });
    setStagedOptions(shallowCopy);
  };

  const handleRemoveOption = ({ id }) => {
    setStagedOptions(stagedOptions.filter((f) => f.id !== id));
  };

  const optionsLimitReached =
    !!optionsLimit && stagedOptions.length >= optionsLimit;

  return (
    <Wrapper>
      <FieldLayout
        fields={stagedOptions.map((f) => ({
          ...f,
          description: f.name,
          meta: { cols: 2 },
        }))}
        Actions={FieldActions}
        onFocus={({ target }) => target.select()}
        onDelete={handleRemoveOption}
        onChangeDescription={handleChangeName}
        onValidate={validateOptions(stagedOptions, t)}
        onChange={(fields) => setStagedOptions(stageOption(fields))}
      />
      <Button
        data-qa="add-option"
        variant="text"
        onClick={handleAddOption}
        disabled={optionsLimitReached}
      >
        {`+ ${t('Add Option')}`}
      </Button>
      {optionsLimitReached && <OptionsLimitWarning limit={optionsLimit} />}
    </Wrapper>
  );
}

SimpleOptions.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  formData: PropTypes.object,
};

SimpleOptions.defaultProps = {
  formData: {},
};
