import KizenTypography from 'app/kizentypo';
import ErrorCardBase from 'components/Kizen/ErrorCard';
import styled from '@emotion/styled';
import { borderRadii, layers } from 'app/spacing';
import { appBackground } from 'app/colors';
import BasicModal from 'components/Modals/presets/BasicModal';

export const ErrorCard = styled(ErrorCardBase)`
  margin-top: -1px;
  z-index: ${layers.popoverInModal};
`;

export const Summary = styled(KizenTypography)`
  background-color: ${appBackground};
  border-radius: ${borderRadii.small};
  padding: 8px;
  margin-bottom: 18px;
  display: flex;
  width: 0;
  min-width: 100%;
`;

export const StyledModal = styled(BasicModal)`
  /* to fit the longest modal heading in one line */
  .modal-dialog {
    width: 422px;
    max-width: 422px;
  }
`;
