import { MouseEvent } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useSet } from 'hooks/useSet';
import { colorsButton, grayScale } from '__app/colors';
import { BorderRadii, borderRadii, layers } from '__app/spacing';
import {
  FontSize,
  TextTransform,
  KizenTypography,
  KizenTypographyProps,
  fontSizes,
  fontWeights,
} from '__app/typography';
import Icon from '__components/Kizen/Icon';

type Option<T = string> = {
  label: string;
  value: T;
  iconName?: string;
};

type ButtonRadioProps<T = string> = {
  options: Option<T>[];
  value: T;
  onChange(value: T): void;
  height?: string;
  label?: string;
  gap?: number;
  flex?: number;
  color?: string;
  fontColor?: string;
  selectedColor?: string;
  hoverColor?: string | false;
  variant?: 'outline' | 'underline';
  optionHorizontalPadding?: number;
  optionWidth?: string;
  bold?: boolean;
  textTransform?: TextTransform;
  fontSize?: FontSize;
  borderRadius?: BorderRadii;
  /**
   * If true, the background color will be the same as the font color when selected.
   * The border color will not change when selected. Setting this to true also sets
   * the unselected background color to that of the `selectedColor` prop.
   */
  highlightBackground?: boolean;
};

type ButtonMultiSelectProps<T = string> = Omit<
  ButtonRadioProps,
  'options' | 'value' | 'onChange' | 'highlightBackground'
> & {
  options: Option<T>[];
  value: T[];
  onChange(value: T[]): void;
};

type RadioOptionProps = Pick<
  ButtonRadioProps,
  | 'color'
  | 'fontColor'
  | 'selectedColor'
  | 'hoverColor'
  | 'height'
  | 'flex'
  | 'highlightBackground'
  | 'optionWidth'
> & {
  selected: boolean;
  onClick(ev: MouseEvent<HTMLDivElement>): void;
  hasGap?: boolean;
  hideBorder?: boolean;
  horizontalPadding?: number;
  borderRadius?: string;
  fontSize?: string;
  fontWeight?: number;
  textTransform?: string;
};

const colorTransition = css`
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

const radioOptionStyles = ({
  color,
  flex = 1,
  fontSize,
  fontWeight,
  fontColor,
  hideBorder = false,
  highlightBackground = false,
  selected,
  selectedColor = colorsButton.blue.default,
  hoverColor = colorsButton.blue.hover,
  textTransform,
  optionWidth,
}: RadioOptionProps) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${fontColor};
  border: 1px solid ${color};
  border-right: none;
  flex: ${flex};
  ${colorTransition}
  ${fontSize && `font-size: ${fontSize};`}
  ${fontWeight && `font-weight: ${fontWeight};`}
  ${textTransform && `text-transform: ${textTransform};`}
  ${highlightBackground && `background-color: ${selectedColor};`}
  ${selected &&
  css`
    && {
      color: ${selectedColor};
      ${highlightBackground
        ? `background-color: ${color};`
        : `border-color: ${selectedColor};`}
    }
  `}
  ${hideBorder &&
  css`
    border: none;
    align-self: flex-end;
  `}
  ${hoverColor !== false &&
  css`
    &:hover {
      color: ${selectedColor};
      border-color: ${hoverColor || selectedColor};
      ${highlightBackground && `background-color: ${hoverColor || fontColor};`}
    }
  `}
  ${Boolean(optionWidth) &&
  css`
    width: ${optionWidth};
  `}
`;

const Container = styled.div<Pick<ButtonRadioProps, 'flex'>>`
  flex: ${({ flex = 1 }) => flex};
  display: flex;
  flex-direction: column;
`;

const Label = styled(KizenTypography)<KizenTypographyProps>`
  flex: 0.3;
  color: ${grayScale.mediumDark};
  margin-top: 4px;
`;

const RadioContainer = styled.div<Pick<ButtonRadioProps, 'gap' | 'variant'>>`
  flex: 0.7;
  display: flex;
  z-index: ${layers.content(0, 0)};

  ${({ gap }) =>
    Boolean(gap) &&
    css`
      gap: ${gap}px;
    `}

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      align-items: flex-end;
    `}
`;

const UnderlineRadioOption = styled.div<RadioOptionProps>`
  ${radioOptionStyles}
  border-left: none;
  border-top: none;
`;

const OutlineRadioOption = styled.div<RadioOptionProps>`
  ${radioOptionStyles}
  ${({ height = '24px' }) => height && `height: ${height};`}
  border: 1px solid ${({ color = grayScale.medium }) => color};
  ${({ horizontalPadding = 0 }) =>
    horizontalPadding > 0 &&
    css`
      padding-left: ${horizontalPadding}px;
      padding-right: ${horizontalPadding}px;
    `}

  ${({ hasGap, selected }) => css`
    &:hover {
      ${!hasGap &&
      css`
        & + * {
          border-left: 1px solid transparent;
        }
      `}
    }
    ${selected &&
    css`
      z-index: ${layers.content(0, 1)};
      ${!hasGap &&
      css`
        & + * {
          border-left: 1px solid transparent;
        }
      `}
    `}
  `}


    
  &:last-child {
    ${({ borderRadius = 'small' }) => css`
      border-top-right-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    `}
  }
  &:first-child {
    ${({ borderRadius = 'small' }) => css`
      border-top-left-radius: ${borderRadius};
      border-bottom-left-radius: ${borderRadius};
    `}
  }
  &:not(:last-child) {
    margin-right: -1px;
  }

  ${({ hasGap, borderRadius = 'small' }) =>
    hasGap &&
    css`
      border-radius: ${borderRadius};
    `}
`;

export const ButtonRadio = <T,>({
  options,
  value,
  onChange,
  height,
  variant = 'underline',
  label = '',
  gap = 0,
  flex = 1,
  optionHorizontalPadding = 0,
  bold = false,
  textTransform = 'none',
  fontSize = 'text',
  borderRadius = 'small',
  color = grayScale.medium,
  fontColor = grayScale.dark,
  selectedColor = colorsButton.blue.default,
  hoverColor = colorsButton.blue.hover,
  highlightBackground = false,
  optionWidth,
  ...rest
}: ButtonRadioProps<T>) => {
  const RadioOption =
    variant === 'outline' ? OutlineRadioOption : UnderlineRadioOption;

  return (
    <Container flex={flex} data-qa-button-toggle={label} {...rest}>
      {label && <Label type="micro">{label}</Label>}
      <RadioContainer variant={variant} gap={gap}>
        {options.map((opt, _, arr) => {
          const selected = opt.value === value;
          return (
            <RadioOption
              key={String(opt.value)}
              selected={selected}
              flex={1 / arr.length}
              hasGap={Boolean(gap)}
              hideBorder={!!opt.iconName}
              onClick={() => onChange(opt.value)}
              horizontalPadding={optionHorizontalPadding}
              height={height}
              borderRadius={borderRadii[borderRadius]}
              color={color}
              selectedColor={selectedColor}
              hoverColor={hoverColor}
              textTransform={textTransform}
              fontSize={fontSizes[fontSize]}
              fontWeight={fontWeights[bold ? 'bold' : 'regular']}
              fontColor={fontColor}
              highlightBackground={highlightBackground}
              optionWidth={optionWidth}
              data-qa="underline-radio-option"
            >
              {opt.iconName ? (
                <Icon
                  icon={opt.iconName}
                  color={selected ? colorsButton.blue.default : undefined}
                />
              ) : (
                opt.label
              )}
            </RadioOption>
          );
        })}
      </RadioContainer>
    </Container>
  );
};

export const ButtonMultiSelect = ({
  options,
  value,
  onChange,
  height,
  variant = 'underline',
  label = '',
  gap = 0,
  flex = 1,
  optionHorizontalPadding = 0,
  bold = false,
  textTransform = 'none',
  fontSize = 'text',
  borderRadius = 'small',
  color = grayScale.medium,
  fontColor = grayScale.dark,
  selectedColor = colorsButton.blue.default,
  hoverColor = colorsButton.blue.hover,
  optionWidth,
  ...rest
}: ButtonMultiSelectProps) => {
  const [, { has, toggle }] = useSet(value);

  const RadioOption =
    variant === 'outline' ? OutlineRadioOption : UnderlineRadioOption;

  return (
    <Container {...rest}>
      <Label type="micro">{label}</Label>
      <RadioContainer variant={variant}>
        {options.map((opt, _, arr) => (
          <RadioOption
            key={String(opt.value)}
            selected={has(opt.value)}
            flex={1 / arr.length}
            hasGap={Boolean(gap)}
            hideBorder={!!opt.iconName}
            onClick={() => onChange(toggle(opt.value))}
            horizontalPadding={optionHorizontalPadding}
            height={height}
            borderRadius={borderRadii[borderRadius]}
            color={color}
            selectedColor={selectedColor}
            hoverColor={hoverColor}
            textTransform={textTransform}
            fontSize={fontSizes[fontSize]}
            fontWeight={fontWeights[bold ? 'bold' : 'regular']}
            fontColor={fontColor}
            optionWidth={optionWidth}
          >
            {opt.label}
          </RadioOption>
        ))}
      </RadioContainer>
    </Container>
  );
};
