import { useParams } from 'react-router-dom';
import { PrimaryObjectUploadWizard } from './PrimariObjectUploadWizard';
import { FlowBuilder } from './FlowBuilder';

export const SmartConnectorBuilder = () => {
  const { subpage } = useParams<{
    id: string;
    subpage: string;
    customObjectId: string;
  }>();

  return subpage === 'upload-object' ? (
    <PrimaryObjectUploadWizard />
  ) : (
    <FlowBuilder />
  );
};
