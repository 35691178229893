import { useSelector } from 'react-redux';
import { getCustomObjectsWithBulkAccess } from 'store/authentication/selectors';
import { EVENT_TYPES } from '../constants';

const bulkAccessByType = {
  [EVENT_TYPES.email.type]: 'bulk_send_email',
  [EVENT_TYPES.automation.type]: 'bulk_start_automation',
};

export const useCanViewSummary = (type, broadcastObjectId) => {
  const objectsWithBulkAccess = useSelector((store) =>
    getCustomObjectsWithBulkAccess(store, bulkAccessByType[type])
  );
  return objectsWithBulkAccess[broadcastObjectId] ?? true;
};
