import { getOptionValue, namedToOptionDeleted } from 'services/helpers';

import {
  checkDeleted,
  nameDescriptor,
  setDelayAmount,
} from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { activity, delayAmount, type, ...details } = orgDetails;
  return {
    stepGoal: {
      ...details,
      delayAmount: setDelayAmount(delayAmount),
      triggers: [
        {
          type: stepConfig.scheduledActivityOverdue.type,
          triggerScheduledActivityOverdue: {
            activity: { id: getOptionValue(activity) },
          },
        },
      ],
    },
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { activity: activityType } =
    firstTrigger.triggerScheduledActivityOverdue;
  checkDeleted(
    messageDictionary,
    addErrorMessage,
    activityType,
    nameDescriptor
  );

  const activity = namedToOptionDeleted(activityType);
  const type = stepConfig.scheduledActivityOverdueGoal.type;

  return { activity, type };
};

export const scheduledActivityOverdueDTO = {
  forApi,
  forFlow,
};
