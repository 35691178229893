import {
  TYPE_ROUND_ROBIN_ALL,
  TYPE_ROUND_ROBIN_ROLE,
  TYPE_ROUND_ROBIN_TEAM_MEMBERS,
  TYPE_TEAM_MEMBER,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/AssignTeamMember/common';
import DraggableStepCard from '../../DraggableStepCard';
import { getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  const {
    assignTeamMember: {
      assignmentType = null,
      role = null,
      teamMember = null,
    } = {},
  } = step.details;

  const roleLabel = getValueOrDeleted(role?.label || role?.name, t);

  switch (assignmentType.id) {
    case TYPE_ROUND_ROBIN_ROLE:
      return `Assign Team Member Round Robin (${roleLabel} Role)`;

    case TYPE_TEAM_MEMBER:
      return `Assign Team Member ${getValueOrDeleted(teamMember?.name, t)}`;

    case TYPE_ROUND_ROBIN_TEAM_MEMBERS:
      return `Assign Team Member Round Robin (Selected Team Members)`;

    case TYPE_ROUND_ROBIN_ALL:
    default:
      return `Assign Team Member ${getValueOrDeleted(assignmentType?.name, t)}`;
  }
};

export default function AssignTeamMemberCard({ type, step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
