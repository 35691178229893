import AxiosService from './AxiosService';
import { checkDataExistsInFilter } from './CustomObjectsService';
import { ENTITIES_TYPEAHEAD_PAGE_SIZE } from './constants';
import { snakeToCamelCaseKeys, camelToSnakeCaseKeys } from './helpers';

export const clientForApp = (client) => ({
  ...client,
  fullName: client.full_name,
  firstName: client.first_name,
  lastName: client.last_name,
  mobilePhone: client.mobile_phone,
  homePhone: client.home_phone,
  businessPhone: client.business_phone,
  numAddresses: client.num_addresses,
  numAddressesV2: client.num_addresses_v2,
  numAssociatedTeamMembers: client.num_associated_team_members,
  numUpcomingActivities: client.num_upcoming_activities,
  // This one only exists in the case of a client detail
  // (as opposed to basic client info, which expresses custom fields differently).
  customFields: client.custom_fields,
  emailIsSuppressionList: client?.email_status === 'suppression_list',
  displayName: client.display_name,
  firstLeadSource: snakeToCamelCaseKeys(client.first_lead_source),
  lastLeadSource: snakeToCamelCaseKeys(client.last_lead_source),
  leadSourceTypes: client.lead_source_types,
});

export const clientForApi = (clientDetail) => {
  const {
    firstName,
    lastName,
    mobilePhone,
    homePhone,
    businessPhone,
    customFields,
    ...others
  } = clientDetail;
  return {
    ...others,
    first_name: firstName,
    last_name: lastName,
    mobile_phone: mobilePhone,
    home_phone: homePhone,
    business_phone: businessPhone,
    custom_fields: customFields,
  };
};

class ClientService {
  constructor() {
    if (!ClientService.instance) {
      ClientService.instance = this;
    }
    return ClientService.instance;
  }
  // because the service gets frozen, we put the abortControllers in an object so they can be updated
  abortControllers = {
    search: undefined,
  };

  clientV2 = '/client/v2';

  clientGroup = '/client-group';

  typeahead = async ({ search }, options, clientObject) => {
    const { data } = await AxiosService.get(
      `/records/${clientObject.id}/typeahead-search`,
      {
        ...options,
        params: {
          search,
          ...options?.params,
          page_size: ENTITIES_TYPEAHEAD_PAGE_SIZE,
        },
      }
    );
    return data.results.map(clientForApp);
  };

  getDynamicTagsOptions = async (fieldId, objectId, page, search, options) => {
    const { data } = await AxiosService.get(
      `/client/fields/${fieldId}/tags?ordering=name&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}`,
      options
    );
    return data;
  };

  // Same endpoint as typeahead, but less specialized and paginated
  search = async (
    {
      search,
      page = {},
      ordering,
      groupId,
      criteria = {},
      fieldIds,
      inGroupIds,
      notInGroupIds,
    },
    optionsProp,
    signal
  ) => {
    const { skipErrorBoundary = false, options, headers } = optionsProp || {};

    const params = {
      search,
      page: page.number || 1,
      pageSize: page.size || 50,
      ordering,
      groupId,
      inGroupIds,
      notInGroupIds,
      ...options,
    };

    const body = {
      ...camelToSnakeCaseKeys(criteria),
      field_ids: fieldIds || [],
    };

    const { data } = await AxiosService.post(this.clientV2, body, {
      signal,
      params: camelToSnakeCaseKeys(params),
      skipErrorBoundary,
      headers,
    });

    return {
      count: data.count,
      errors: data.errors || null,
      results: data.results.map(clientForApp),
      next: data.next,
      previous: data.previous,
    };
  };

  searchCancelable = async (props, options) => {
    // Check if there are any previous pending requests
    if (this.abortControllers.search) {
      this.abortControllers.search.abort();
    }

    // Save the abortController for the current request
    this.abortControllers.search = new AbortController();

    return this.search(props, options, this.abortControllers.search.signal);
  };
  count = async (query, options) => {
    const { count } = await this.search(
      {
        ...query,
        ordering: null,
        page: {
          number: 1,
          size: 1,
        },
      },
      options
    );
    return count;
  };

  update = async (id, patch) => {
    const { data } = await AxiosService.put(
      `/client/${id}`,
      clientForApi(patch)
    );
    return clientForApp(data);
  };

  create = async (contactDetail) => {
    const { data } = await AxiosService.post(
      `/client`,
      clientForApi(contactDetail)
    );
    return clientForApp(data);
  };

  archive = async (id) => {
    await AxiosService.post(`/client/${id}/archive`);
  };

  groups = async (options) => {
    const { data } = await AxiosService.get(this.clientGroup, options);
    return snakeToCamelCaseKeys(data).map((group) => ({
      ...group,
      isMyRecordsGroup: checkDataExistsInFilter(group.config),
    }));
  };

  getGroupOptions = async (options) => {
    const data = await this.groups(options);

    return data
      .map(({ name, id, isMyRecordsGroup }) => ({
        label: name,
        value: id,
        isMyRecordsGroup,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  createGroup = async (body, options) => {
    const { data } = await AxiosService.post(
      this.clientGroup,
      camelToSnakeCaseKeys(body),
      options
    );
    return snakeToCamelCaseKeys(data);
  };

  updateGroup = async (body, id, options) => {
    const { data } = await AxiosService.put(
      `${this.clientGroup}/${id}`,
      camelToSnakeCaseKeys(body),
      options
    );
    return snakeToCamelCaseKeys(data);
  };

  deleteGroup = async (id) => {
    await AxiosService.delete(`${this.clientGroup}/${id}`);
  };

  fields = async ({ withCategory } = {}, options) => {
    const { data } = await AxiosService.get('/client-field', {
      ...options,
      params: {
        with_category: withCategory,
        ...(options && options.params),
      },
    });
    return data;
  };

  getById = async (id, config, { expandRelationships } = {}) => {
    const { data } = await AxiosService.get(`/client/${id}`, {
      params: {
        expand_relationships: expandRelationships,
      },
      ...config,
    });
    return clientForApp(data);
  };

  getAddressesById = async (id) => {
    const { data } = await AxiosService.get(`/client/${id}/addresses`);
    return snakeToCamelCaseKeys(data);
  };

  getV2AddressesById = async (id) => {
    const { data } = await AxiosService.get(`/client/${id}/addresses-v2`);
    return snakeToCamelCaseKeys(data);
  };

  createAddress = async (newAddress) => {
    const address = camelToSnakeCaseKeys(newAddress);
    address.street_address_2 = address.street_address2;
    const { data } = await AxiosService.post(
      `/client/${newAddress.clientId}/addresses`,
      address
    );
    return snakeToCamelCaseKeys(data);
  };

  createV2Address = async (newAddress) => {
    const address = camelToSnakeCaseKeys(newAddress);
    address.street_address_2 = address.street_address2;
    const { data } = await AxiosService.post(
      `/client/${newAddress.clientId}/addresses-v2`,
      address
    );
    return snakeToCamelCaseKeys(data);
  };

  updateAddress = async (address) => {
    const { id } = address;
    const updatedAddress = camelToSnakeCaseKeys(address);
    updatedAddress.street_address_2 = updatedAddress.street_address2;
    const { data } = await AxiosService.put(
      `/client/${address.clientId}/addresses/${id}`,
      updatedAddress
    );
    return snakeToCamelCaseKeys(data);
  };

  updateV2Address = async (address) => {
    const { id } = address;
    const updatedAddress = camelToSnakeCaseKeys(address);
    updatedAddress.street_address_2 = updatedAddress.street_address2;
    const { data } = await AxiosService.put(
      `/client/${address.clientId}/addresses-v2/${id}`,
      updatedAddress
    );
    return snakeToCamelCaseKeys(data);
  };

  deleteAddress = async (clientId, id) => {
    const { data } = await AxiosService.delete(
      `/client/${clientId}/addresses/${id}`
    );
    return data;
  };

  deleteV2Address = async (clientId, id) => {
    const { data } = await AxiosService.delete(
      `/client/${clientId}/addresses-v2/${id}`
    );
    return data;
  };

  typeaheadAddressTags = async ({ search }, options) => {
    const { data } = await AxiosService.get('/address-tag', {
      params: { search },
      ...options,
    });
    return data.results;
  };

  getTeamAssociationsById = async ({ clientId, ordering, ...others }) => {
    const { data } = await AxiosService.get('/team-association', {
      params: {
        client_id: clientId,
        ordering,
        ...others,
      },
    });
    return snakeToCamelCaseKeys(data);
  };

  addTeamMember = async (clientId, employeeId) => {
    const { data } = await AxiosService.post('/team-association', {
      client_id: clientId,
      employee_id: employeeId,
    });
    return data;
  };

  createAddressTag = async (addressTagName) => {
    const { data } = await AxiosService.post('/address-tag', {
      name: addressTagName,
    });
    return data;
  };

  getObjectRelatedPipelines = async (
    id,
    search,
    _, // This needs to match the signature of the same function in the custom objects service
    skipErrorBoundary = false
  ) => {
    const { data } = await AxiosService.get(`/client/${id}/related-pipelines`, {
      params: { search },
      skipErrorBoundary,
    });
    return snakeToCamelCaseKeys(data);
  };
}

const instance = new ClientService();
Object.freeze(instance);

export default instance;
