/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { useParams } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import styled from '@emotion/styled';
import Card from 'components/Card/Card';
import TimelineService from 'services/TimelineService';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import PublicNavBar from 'components/Navigation/PublicNavBar';

import { PageContentContainer } from 'components/Layout/PageContentWidth';
import { useTranslation } from 'react-i18next';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';

const StyledCard = styled(Card)`
  padding: ${gutters.spacing(5)}px 0 ${gutters.spacing(5, 2)}px 0;
  margin: ${gutters.spacing(4)}px 0 0 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  && .long-text {
    margin-bottom: ${gutters.spacing(1, 3)}px;
  }

  && h1 {
    margin-top: 2px; //  Design nudge
    margin-bottom: ${gutters.spacing(2, 2)}px;
    line-height: 1;
  }

  && .support_text {
    margin-bottom: ${gutters.spacing(0)}px;
  }
`;

const setPageTitle = (title, { entityName = '' }) =>
  entityName ? `${entityName} - ${title}` : title;

export default function CommentThreadUnsubscribe({ title }) {
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  useAsync(async () => {
    await TimelineService.commentUnsubscribe(id);
  }, [id]);

  useSetTitleOnLoad(title(t), {}, setPageTitle);

  return (
    <>
      <PublicNavBar />
      <PageContentContainer>
        <StyledCard>
          <KizenTypography as="h1" type="hero">
            {t('Success')}
          </KizenTypography>

          <KizenTypography as="p" className="long-text">
            {t('You have muted this comment string.')} <br />
            {t(
              'If you are mentioned in the thread again you`ll start receiving notifications again.'
            )}
          </KizenTypography>
          <KizenTypography as="p" className="support_text">
            {t('If you need additional help, please email')}{' '}
            <KizenTypography
              type="link-mail"
              size="micro"
              weight="bold"
              href="mailto:support@kizen.com"
            >
              support@kizen.com
            </KizenTypography>
            .
          </KizenTypography>
        </StyledCard>
      </PageContentContainer>
    </>
  );
}
