import ButtonGroup from '__components/Inputs/ButtonGroup';
import { SmartConnectorType } from 'pages/SmartConnectors/types';
import { useTranslation } from 'react-i18next';
import { getSmartConnectorTypeOptions } from '../../helpers';
import { ButtonGroupImageButton } from '__components/Inputs/ButtonGroup/Button';
import InputControl from 'components/Inputs/InputControl';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';

type SmartConnectorTypeButtonsProps = {
  value: SmartConnectorType;
  onChange: ({ value }: { value: SmartConnectorType }) => void;
};

export const SmartConnectorTypeButtons = ({
  value,
  onChange,
}: SmartConnectorTypeButtonsProps) => {
  const { t } = useTranslation();
  const preRelease = usePreReleaseFeatures();

  const options = getSmartConnectorTypeOptions(t, preRelease);

  return (
    <InputControl
      label={t('Choose Connector Type')}
      variant="outline"
      className=""
      margin
    >
      <ButtonGroup
        value={value}
        onChange={onChange}
        options={options}
        columns={4}
        mode="wizard"
        button={<ButtonGroupImageButton />}
        gutter={'20px'}
      />
    </InputControl>
  );
};
