import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { urlValidation } from 'utility/validate';
import { getProportionalSizes } from '../../settings/ImageSettings/shared';
import {
  DIMENSION,
  dimensionOptions,
  IMAGE_SIZES,
  imageSizeOptions,
  positionOptions,
  UNIT,
  unitOptions,
} from '../../options';
import {
  Card,
  CardRow,
  ChooseImageSection,
  DefaultContentSettingsContainer,
  Dropdown,
  HasLinkSwitch,
  Text,
  WholeNumberInput,
} from './ts-components';
import {
  setDefaultPosition,
  setDefaultSize,
  setDefaultUnit,
  setDefaultDimension,
  setDefaultHasLink,
  setDefaultWidth,
  setDefaultHeight,
  setDefaultMaxWidth,
  setDefaultMaxHeight,
  setDefaultLink,
  setDefaultAltText,
  setDefaultImage,
  clearDefaultContentError,
} from './state';

export const ImageSettingsRowOne = ({
  dispatch,
  position,
  size,
  unit,
  dimension,
  width,
  height,
  maxWidth,
  maxHeight,
  fileId,
  name,
  src,
  containerWidth,
  naturalHeight,
  naturalWidth,
  onImageChange,
  onPositionChange,
  onSizeChange,
  onUnitChange,
  onDimensionChange,
  onWidthChange,
  onHeightChange,
  onMaxHeightChange,
  onMaxWidthChange,
  Row = CardRow,
  ...rest
}) => {
  const { t } = useTranslation();

  const setDimensionDefaults = (unit, size) => {
    const data = {
      height: naturalHeight,
      width: naturalWidth,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
    };

    if (size === IMAGE_SIZES.AUTO) {
      data.height = null;
      data.width = null;
      data.maxWidth = maxWidth;
      data.maxHeight = maxHeight;
    }

    if (size === IMAGE_SIZES.DYNAMIC) {
      if (unit === UNIT.PIXEL) {
        const proportion = naturalWidth / naturalHeight;
        const {
          width: dynamicWidth,
          height: dynamicHeight,
          maxWidth: dynamicMaxWidth,
          maxHeight: dynamicMaxHeight,
        } = getProportionalSizes({
          width: naturalWidth,
          proportion,
          containerWidth,
        });
        data.height = dynamicHeight;
        data.width = dynamicWidth;
        data.maxWidth = dynamicMaxWidth;
        data.maxHeight = dynamicMaxHeight;
      } else {
        data.height = null;
        data.maxWidth = null;
        data.maxHeight = null;
        data.width = 100;
      }
    }
    onWidthChange(data.width);
    onHeightChange(data.height);
    onMaxHeightChange(data.maxHeight);
    onMaxWidthChange(data.maxWidth);
  };

  const handleChangeSize = ({ value }) => {
    onSizeChange(value);
    if (value === IMAGE_SIZES.DYNAMIC) {
      onUnitChange(UNIT.PIXEL);
      onDimensionChange(DIMENSION.WIDTH);
    }
    setDimensionDefaults(UNIT.PIXEL, value);
  };

  const handleChangeUnit = ({ value }) => {
    onUnitChange(value);
    setDimensionDefaults(value, IMAGE_SIZES.DYNAMIC);
  };

  const handleChangeWidth = (value) => {
    const parsedValue = parseInt(value.replaceAll(',', ''), 10);
    let sizes = {
      width: parsedValue,
      height,
      maxWidth: parsedValue,
      maxHeight: height,
    };

    if (isNaN(parsedValue)) {
      if (size === IMAGE_SIZES.FIXED) {
        sizes = {
          ...sizes,
          width: null,
          maxWidth: null,
        };
      } else {
        sizes = {
          width: null,
          height: null,
          maxWidth: null,
          maxHeight: null,
        };
      }
    } else {
      if (size === IMAGE_SIZES.DYNAMIC) {
        const proportion = naturalWidth / naturalHeight;
        sizes = getProportionalSizes({
          width: parsedValue,
          proportion,
          containerWidth,
          unit,
        });
      }
    }

    onWidthChange(sizes.width);
    onHeightChange(sizes.height);
    onMaxWidthChange(sizes.maxWidth);
    onMaxHeightChange(sizes.maxHeight);
  };

  const handleChangeHeight = (value) => {
    const parsedValue = parseInt(value.replaceAll(',', ''), 10);
    let sizes = {
      width,
      height: parsedValue,
      maxWidth: width,
      maxHeight: parsedValue,
    };

    if (isNaN(parsedValue)) {
      if (size === IMAGE_SIZES.FIXED) {
        sizes = {
          ...sizes,
          height: null,
          maxHeight: null,
        };
      } else {
        sizes = {
          width: null,
          height: null,
          maxWidth: null,
          maxHeight: null,
        };
      }
    } else {
      if (size === IMAGE_SIZES.DYNAMIC) {
        const proportion = naturalWidth / naturalHeight;
        sizes = getProportionalSizes({
          height: parsedValue,
          proportion,
          containerWidth,
        });
      }
    }

    onWidthChange(sizes.width);
    onHeightChange(sizes.height);
    onMaxWidthChange(sizes.maxWidth);
    onMaxHeightChange(sizes.maxHeight);
  };

  return (
    <Row {...rest}>
      <ChooseImageSection
        fileId={fileId}
        fileName={name}
        onImageChange={onImageChange}
      />
      <Dropdown
        width={200}
        label={t('Position')}
        value={position}
        options={positionOptions(t)}
        onChange={onPositionChange}
      />
      <Dropdown
        width={200}
        label={t('Size')}
        value={size}
        options={imageSizeOptions(t)}
        onChange={handleChangeSize}
      />
      {size === IMAGE_SIZES.DYNAMIC && (
        <>
          <Dropdown
            label={t('Unit')}
            options={unitOptions}
            value={unit}
            onChange={handleChangeUnit}
          />
          {unit === UNIT.PIXEL ? (
            <>
              <Dropdown
                label={t('Dimension')}
                options={dimensionOptions(t)}
                value={dimension}
                width={150}
                onChange={({ value }) => onDimensionChange(value)}
              />
              {dimension === DIMENSION.WIDTH ? (
                <WholeNumberInput
                  label={t('Max-Width')}
                  placeholder={t('Auto')}
                  width={150}
                  value={maxWidth || width}
                  onChange={handleChangeWidth}
                />
              ) : (
                <WholeNumberInput
                  label={t('Max-Height')}
                  placeholder={t('Auto')}
                  width={150}
                  value={maxHeight || height}
                  onChange={handleChangeHeight}
                />
              )}
            </>
          ) : (
            <WholeNumberInput
              label={t('Max-Width')}
              placeholder={t('Auto')}
              width={150}
              value={width}
              onChange={handleChangeWidth}
            />
          )}
        </>
      )}
      {size === IMAGE_SIZES.FIXED && (
        <>
          <WholeNumberInput
            label={t('Width (px)')}
            placeholder={t('Auto')}
            width={150}
            value={width}
            onChange={onWidthChange}
          />
          <WholeNumberInput
            label={t('Height (px)')}
            placeholder={t('Auto')}
            width={150}
            value={height}
            onChange={onHeightChange}
          />
        </>
      )}
    </Row>
  );
};

export const ImageSettingsRowTwo = ({
  link,
  alt,
  linkError,
  showErrors,
  onLinkChange,
  onHasLinkChange,
  onAltTextChange,
  Row = CardRow,
  ...rest
}) => {
  const { t } = useTranslation();
  const [hasLink, setHasLink] = useState(!!link);
  const error = linkError
    ? { message: linkError, showMessage: showErrors }
    : null;

  const handleHasLinkChanged = (ev) => {
    if (typeof ev.target.checked === 'boolean') {
      setHasLink(ev.target.checked);
      onHasLinkChange(ev.target.checked);
    }
  };

  return (
    <Row style={{ minHeight: '92px' }} {...rest}>
      <HasLinkSwitch
        checked={hasLink}
        label={t('Has Link?')}
        textPlacement="top"
        onClick={handleHasLinkChanged}
      />
      {hasLink && (
        <>
          <Text
            label={t('URL')}
            placeholder={t('URL')}
            width={200}
            value={link}
            validate={error || { full: urlValidation(t) }}
            onChange={onLinkChange}
          />
          <Text
            label={t('Alt Text')}
            placeholder={t('Alt Text')}
            width={200}
            value={alt}
            onChange={onAltTextChange}
          />
        </>
      )}
    </Row>
  );
};

export const DefaultContentSettings = ({
  dispatch,
  position,
  size,
  unit,
  width,
  height,
  maxWidth,
  maxHeight,
  link,
  alt,
  fileId,
  name,
  linkError,
  showErrors,
  dimension,
  containerWidth,
  naturalHeight,
  naturalWidth,
}) => (
  <DefaultContentSettingsContainer>
    <Card>
      <ImageSettingsRowOne
        position={position}
        size={size}
        unit={unit}
        width={width}
        height={height}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        fileId={fileId}
        name={name}
        dimension={dimension}
        containerWidth={containerWidth}
        naturalHeight={naturalHeight}
        naturalWidth={naturalWidth}
        onImageChange={({ id, name, url }) =>
          dispatch(setDefaultImage({ id, name, url }))
        }
        onPositionChange={({ value }) => dispatch(setDefaultPosition(value))}
        onSizeChange={(value) => dispatch(setDefaultSize(value))}
        onUnitChange={(value) => dispatch(setDefaultUnit(value))}
        onWidthChange={(value) => dispatch(setDefaultWidth(value))}
        onHeightChange={(value) => dispatch(setDefaultHeight(value))}
        onMaxWidthChange={(value) => dispatch(setDefaultMaxWidth(value))}
        onMaxHeightChange={(value) => dispatch(setDefaultMaxHeight(value))}
        onDimensionChange={(value) => dispatch(setDefaultDimension(value))}
      />
      <ImageSettingsRowTwo
        link={link}
        alt={alt}
        linkError={linkError}
        showErrors={showErrors}
        onHasLinkChange={(value) => dispatch(setDefaultHasLink(value))}
        onLinkChange={(value) => {
          dispatch(setDefaultLink(value));
          dispatch(clearDefaultContentError());
        }}
        onAltTextChange={(value) => dispatch(setDefaultAltText(value))}
      />
    </Card>
  </DefaultContentSettingsContainer>
);
