import React, { useState } from 'react';
import styled from '@emotion/styled';
import Notice from './Notice';

const Iframe = styled.iframe`
  display: block;
  width: 100%;
  border: none;
`;

const EmbedHtml = ({
  children = null,
  onLoad = null,
  sandbox = null,
  contentHeight,
  ...props
}) => {
  const [height, setHeight] = useState(0);
  return (
    <Iframe
      data-qa="embed-html"
      srcDoc={children}
      // Needs allow-same-origin in order to access body height
      // Note that this sandbox list disallows scripts from being run by default.
      sandbox={`allow-same-origin ${sandbox || ''}`}
      style={{ height: contentHeight ? contentHeight : height }}
      onLoad={(ev) => {
        const doc = ev.target.contentWindow.document;
        doc.body.style.margin = 0; // Remove body margin, generally defaults set by browser
        const scrollingEl = doc.scrollingElement || doc.body;
        if (onLoad) onLoad(ev);
        // scrollHeight is a whole number but actual document height could have decimal part,
        //so we asure that iframe height is not less than embed document height by adding 1px
        setHeight(scrollingEl.scrollHeight + 1);
      }}
      {...props}
    >
      <Notice>Your browser does not support iframes.</Notice>
    </Iframe>
  );
};

export default EmbedHtml;
