import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Button from 'components/Button';
import {
  breakpoints,
  gutters,
  borderRadii,
  hideScrollbarCss,
} from 'app/spacing';
import { colorsButton, colorsText, grayScale, tableHover } from 'app/colors';
import {
  fontSizes,
  fontWeights,
  TextSpan,
  KizenTypography,
} from 'app/typography';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import { useSanitizedHtml } from 'hooks/useSanitizedHtml';
import LongTextEllipsis from './LongTextEllipsis';

export const EditorOutput = styled(KizenTypography)`
  * {
    overflow-wrap: break-word;
  }

  /* elements output by our WYSIWYG i.e. editor contract  */

  p {
    margin-bottom: 1em;
  }

  u {
    text-decoration: underline;
  }

  s {
    text-decoration: line-through;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: ${fontWeights.bold};
  }
`;

export default function WysiwygTypography({ html, ...props }) {
  const sanitized = useSanitizedHtml(html);

  return (
    <EditorOutput {...props} dangerouslySetInnerHTML={{ __html: sanitized }} />
  );
}

export const TimeLineWrapper = styled.div`
  ${({ isDisplayingComments }) =>
    isDisplayingComments &&
    css`
      // half of comment block arrow icon height (15px) + desired gutter separating event blocksgutter (30px)
      // margin-bottom: ${gutters.spacing(3) + gutters.spacing(6)}px;
      border-bottom: 0px;
    `}
`;

export const TimelineErrorWrapper = styled(TimeLineWrapper)`
  min-height: 97px;
`;

export const TableBlockWithHeader = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  column-gap: ${gutters.spacing(4)}px;
  grid-template-columns: max-content ${(isExplanation) =>
      isExplanation ? `1fr` : ''} 1fr max-content;
  grid-template-areas:
    'iconfield datefield datefield'
    'iconfield detailsfield editfield'
    'titlefield titlefield titlefield'
    'explanationfield explanationfield explanationfield'
    'tablefield tablefield tablefield';
  @media (min-width: ${breakpoints.md}px) {
    grid-template-columns:
      max-content ${(isExplanation) =>
        isExplanation ? 'minmax(0px, 3fr)' : 'minmax(0px, 2fr)'} auto
      max-content;
    grid-template-areas:
      'iconfield titlefield detailsfield datefield'
      'iconfield explanationfield explanationfield editfield'
      'iconfield tablefield tablefield editfield';
  }
`;

export const ExplanationEvent = styled.div`
  grid-area: explanationfield;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '19' : '0')}px;
`;

export const StyledTableAreaWrapper = styled.div`
  position: relative;
  grid-area: tablefield;
  padding-bottom: ${({ isOverflown }) => (isOverflown ? 31 : 0)}px;
`;

export const StyledTableArea = styled.div`
  position: relative;
  display: grid;
  max-width: 580px;
  column-gap: ${gutters.spacing(4)}px;
  row-gap: ${gutters.spacing(4, { baseline: true }) - 1}px;
  grid-template-columns: fit-content(120px) 2fr;
  @media (min-width: ${breakpoints.md}px) {
    grid-template-columns: fit-content(200px) 2fr;
  }

  * {
    margin: 0;
  }

  // Prevent grid-children from collapsing beneath content width
  > * {
    min-width: ${gutters.spacing(12)}px;
  }

  overflow: hidden;
  max-height: ${({ isExpanded, maxHeight }) =>
    isExpanded ? 'fit-content' : maxHeight + 'px'};
`;

export const IconArea = styled.div`
  align-self: start;
  grid-area: iconfield;
`;

export const TitleArea = styled(TextEllipsisWithTooltip)`
  grid-area: titlefield;
  max-width: 580px;
  // margin-top = 30px (desired gutter) - 1px (linebox gap of title text)
  // margin-bottom = 30px (desired gutter) - 3px (baseline of title text) - 1px (linebox gap of body)
  margin: ${gutters.spacing(6) - 1}px 0
    ${gutters.spacing(3, {
      baseline: true,
    }) - 1}px
    0;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      margin-bottom: 0px;
      @media (min-width: ${breakpoints.md}px) {
        margin-bottom: ${gutters.spacing(6, { baseline: true })}px;
      }
    `} @media(min-width: ${breakpoints.md}px) {
    padding: ${gutters.spacing(2, 1)}px 0 0 0; // synced with DetailsArea and DateArea
    margin-top: 0;
  }
`;

export const DetailsArea = styled(TextSpan)`
  grid-area: detailsfield;
  color: ${colorsButton.blue.default};
  font-size: ${fontSizes.text};
  cursor: pointer;

  &:hover {
    color: ${colorsButton.blue.hover};
  }

  justify-self: end;
  align-self: flex-end;

  padding-top: 0;
  @media (min-width: ${breakpoints.md}px) {
    align-self: flex-start;
    padding-top: ${gutters.spacing(
      2,
      1
    )}px; // synced with TitleArea and DateArea
  }
`;

export const DateArea = styled(TextSpan)`
  grid-area: datefield;
  align-self: flex-start;
  font-size: ${fontSizes.small};

  & > span {
    font-weight: ${fontWeights.bold};
    font-size: 1em;
    color: ${grayScale.mediumDark};
  }
  justify-self: end;
  padding-top: 0;
  margin-bottom: ${gutters.spacing(-1, 1)}px;
  @media (min-width: ${breakpoints.md}px) {
    padding-top: ${gutters.spacing(3, {
      baseline: fontSizes.small,
    })}px; // synced with TitleArea and DetailsArea, different modifiers due to smaller font-size
  }
`;

export const EditArea = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-self: flex-end;
  grid-area: editfield;
  padding-top: 0;

  @media (min-width: ${breakpoints.md}px) {
    align-self: start;
  }

  i {
    cursor: pointer;
  }
`;

// CommentSection styles

export const MessageBlockWrapper = styled.div`
  position: relative;
  background-color: ${tableHover};
  padding: ${gutters.spacing(1)}px 0px ${gutters.spacing(2, -2)}px
    ${gutters.spacing(2)}px;
  margin-left: 0;
  margin-top: ${gutters.spacing(6, { baseline: true })}px;
  border-radius: ${borderRadii.small};
  border: 1px solid ${grayScale.medium};

  @media (min-width: ${breakpoints.md}px) {
    padding: ${gutters.spacing(4)}px 0px ${gutters.spacing(4, -2)}px
      ${gutters.spacing(4)}px;
  }
`;

export const MessageExpanderGradient = styled(Gradient)`
  position: absolute;
  width: calc(
    100% + 2px
  ); // 2px, for 1px border on each side of MessageBlockWrapper
  bottom: -1px; // Cover bottom border
  left: -1px; // cinch back 1px so 2px added to width covers left and right borders exactly
`;

export const MessageItem = styled.div`
  width: 100%;
  display: grid;
  align-items: flex-start;
  grid-template-columns: max-content 1fr;
  grid-template-areas:
    'date date'
    'image message';

  margin-bottom: ${gutters.spacing(3)}px;
  padding-right: ${gutters.spacing(1, -4)}px;
  @media (min-width: ${breakpoints.md}px) {
    padding-right: ${gutters.spacing(2, 1)}px;
    margin-bottom: ${gutters.spacing(4)}px;
    grid-template-columns: repeat(1, max-content) 1fr;
    grid-template-areas: 'image message date';
  }

  // Make last item in the list flush with the end of the MessageList container,
  // whose margin-bottom rule is responsible for setting the gutter between the list
  // and the ensuing editor
  ${({ isLast }) =>
    isLast &&
    css`
      margin-bottom: 0px;
      @media (min-width: ${breakpoints.md}px) {
        margin-bottom: 0px;
      }
    `}
  .user-image, .user-avatar {
    grid-area: image;
    margin-top: ${gutters.spacing(1, -1)}px;

    @media (min-width: ${breakpoints.md}px) {
      margin-top: initial;
    }
  }
`;

export const MESSAGE_LIST_HEIGHT = 300;

export const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const Message = styled(WysiwygTypography)`
  max-width: 620px;
  // arbitrary value, but allows grid to calculate Message width to contain it within MesssageList
  // without this rule, certain content e.g. mutli-100-character long URLs, were overflowing and horizontally scrolling the container
  min-width: ${gutters.spacing(12)}px;
  padding-top: ${gutters.spacing(2, -3)}px;
  padding-left: ${gutters.spacing(2)}px;
  grid-area: message;

  * {
    color: ${colorsText.dark};
    font-size: 1em;
    line-height: 17px;
  }

  > *:last-child {
    // offset bottom-most margin so text content baseline doesn't
    // throw-off spacing from rules on MessageItem and MessageList
    margin-bottom: ${gutters.spacing(-1)}px;
    padding-bottom: 0px;
  }

  .mention {
    color: ${colorsButton.blue.default};
    padding: 0;
    background: transparent;
    cursor: default;

    &:focus {
      outline: none;
    }
  }

  @media (min-width: ${breakpoints.md}px) {
    padding-top: ${gutters.spacing(1, -3)}px;
    padding-left: ${gutters.spacing(4)}px;
  }
`;

export const DateSpan = styled(TextSpan)`
  grid-area: date;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.bold};
  color: ${grayScale.mediumDark};
  align-self: start;
  justify-self: start;
  margin-left: 0px;
  margin-top: -1px;
  @media (min-width: ${breakpoints.md}px) {
    justify-self: end;
    margin-left: ${gutters.spacing(4)}px;
  }
`;

export const EditorWrapper = styled.div`
  padding-left: 0;
  padding-right: ${gutters.spacing(2)}px;
  margin-top: ${gutters.spacing(1, -3)}px;
  position: relative;
  @media (min-width: ${breakpoints.md}px) {
    padding-right: ${gutters.spacing(4)}px;
    padding-left: ${gutters.spacing(8)}px;
  }
`;

export const SendButton = styled(Button)`
  font-size: ${fontSizes.text};
  padding: 0;
  margin: ${gutters.spacing(2, -2)}px 0 ${gutters.spacing(2, -3)}px;
  @media (min-width: ${breakpoints.md}px) {
    margin: ${gutters.spacing(2, -2)}px 0 ${gutters.spacing(4, -3)}px;
  }
  height: 15px;
  line-height: 100%;
  justify-content: flex-start;
`;

export const ExpandButton = styled(Button)`
  padding: 0;
  margin: 0;
  text-transform: capitalize;
  font-size: 14px;
  height: 14px;
  line-height: 14px;
`;

export const ViewFullButton = styled(ExpandButton)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const CollapsedButton = styled(ExpandButton)`
  position: relative;
  top: -14px;
`;

export const StatusLongTextElipsis = styled(LongTextEllipsis)`
  display: flex;
  align-items: center;
  &:before {
    content: ${({ showStatusCircle }) => (showStatusCircle ? '""' : null)};
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: ${gutters.spacing(2)}px;
    background-color: ${({ circleColor }) => circleColor};
  }
`;

export const CommentsWrapper = styled.div`
  display: flex;
  max-height: ${MESSAGE_LIST_HEIGHT}px;

  // CommentsWrapper's margin-bottom must be>= 7px
  // some element within the editor sets a margin-top of 7px, which is
  // collapsing up into the list, nudging up our gutter if MessageList's
  // margin-bottom doesn't subsume it
  margin-bottom: ${({ isEmpty }) =>
    gutters.spacing(isEmpty ? 0 : 3, { baseline: true })}px;
  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: ${({ isEmpty }) => gutters.spacing(isEmpty ? 0 : 6)}px;
  }
`;

// if allowScrolling then there is an ScrollBarDetached that takes up space.
// When not displayed we need to add a littlee margin so it doesn't "pop"
export const Comments = styled.div`
  width: 100%;
  ${({ allowScrolling }) =>
    allowScrolling
      ? css`
          overflow-y: auto;
          margin-right: 0;
        `
      : css`
          overflow-y: hidden;
          margin-right: 9px;
        `}

  ${hideScrollbarCss}
`;
