import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';

export const isStageField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.stage ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.stage
  );
};

export const isEntityValueField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.entityValue ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.entityValue
  );
};

export const isEntityNameField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.name ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.name
  );
};

export const isFirstNameField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.firstName ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.firstName
  );
};

export const isLastNameField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.lastName ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.lastName
  );
};

export const isTimeZoneField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.timezone ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.timezone
  );
};

export const isEmailStatusField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.emailStatus ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.emailStatus
  );
};

export const isDefaultEmailField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.email ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.email
  );
};

export const isOwnerField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.owner ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.owner
  );
};

export const isContactTagsField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.tags ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.tags
  );
};

export const isActualCloseDateField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.actualCloseDate ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.actualCloseDate
  );
};

export const isEstimatedCloseDateField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.estimatedCloseDate ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.estimatedCloseDate
  );
};

export const isContactTitlesField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.titles ||
    field?.customObjectField?.name === DEFAULT_FIELD_NAMES.titles
  );
};

export const isReasonLostField = (field) => {
  return [field?.name, field?.originalSnakeCaseName].includes(
    DEFAULT_FIELD_NAMES.reasonsLost
  );
};

export const isReasonDisqualifiedField = (field) => {
  return [field?.name, field?.originalSnakeCaseName].includes(
    DEFAULT_FIELD_NAMES.reasonsDisqualified
  );
};

export const isPercentageChanceToCloseField = (field) => {
  return (
    field?.name === DEFAULT_FIELD_NAMES.percentageChanceToClose ||
    field?.customObjectField?.name ===
      DEFAULT_FIELD_NAMES.percentageChanceToClose
  );
};

export const isDefaultUndeletableNotesField = (field) =>
  (field?.fieldType === 'wysiwyg' || field?.name === 'notes') &&
  field.isDefault &&
  !field.isDeletable;

export const hasTeamMemberOptions = (field) => {
  return isTeamSelectorField(field) || isOwnerField(field);
};

export const isEmailField = (field) => {
  return (
    field?.fieldType === FIELD_TYPES.Email.type ||
    field?.customObjectField?.fieldType === FIELD_TYPES.Email.type
  );
};

export const isCheckboxesField = (field) => {
  return field?.fieldType === FIELD_TYPES.Checkboxes.type;
};

export const isDropdownField = (field) => {
  return field?.fieldType === FIELD_TYPES.Dropdown.type;
};

export const isDynamicTagsField = (field) => {
  return field?.fieldType === FIELD_TYPES.DynamicTags.type;
};

export const isFilesField = (field) => {
  return field?.fieldType === FIELD_TYPES.Files.type;
};

export const isRelationshipField = (field) => {
  return field?.fieldType === FIELD_TYPES.Relationship.type;
};

export const isReadOnlyField = (field) => {
  return field?.isReadOnly;
};

export const isTeamSelectorField = (field) => {
  return field?.fieldType === FIELD_TYPES.TeamSelector.type;
};

export const canEditOptionsForActivity = ({ isDefault, isDeletable }) =>
  !isDefault && isDeletable;

export const getAdditionalFieldProps = (field, info, placeholder) => {
  let percentageChanceToCloseProps = {};
  if (isPercentageChanceToCloseField(field)) {
    const isInherited = Boolean(info);
    percentageChanceToCloseProps = {
      shrink: isInherited || undefined,
      labelInfo: isInherited ? info : null,
      minValue: 0,
      maxValue: 100,
      allowNegatives: false,
      isInherited,
      placeholder,
    };
  }

  return percentageChanceToCloseProps;
};
