import { useCallback, useEffect, useRef, useState } from 'react';
import { isValid } from 'date-fns2';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from 'utility/getCookie';
import {
  ExpiredModal,
  EXPIRED_MODAL_TYPES,
} from 'components/Modals/ExpiredModal';

import {
  serviceLogoutStart,
  serviceRefreshAccessStart,
  showInactivityMessage,
  showTimeoutMessage,
} from 'store/authentication/authenticationAction';
import { useIdleTimer } from 'react-idle-timer';
import { keepAliveLastActivitySessionStorage } from 'utility/inactivitySessionStorage';

const SESSION_ID_EXPIRES = 'sessionid_expires';
const SESSION_ID_INACTIVITY_EXPIRES = 'sessionid_inactivity_expires';
const WARNING_TIME_OUT = 60_000;
const EVENTS_THROTTLE_TIME_OUT = 30_000;
const KEEP_ALIVE_TIME_OUT = 30_0000;
const TICKER_TIME_OUT = 1000;

const validateDate = (expiresString = '') => {
  if (expiresString !== '') {
    const expires = new Date(expiresString);
    if (isValid(expires)) {
      return Math.max(0, expires - Date.now());
    }
  }
  return 0;
};
const getRemainingTime = (cookieName = SESSION_ID_EXPIRES) => {
  const expiresString = getCookie(cookieName);
  return validateDate(expiresString);
};
const MonitorSessionTimeout = () => {
  const dispatch = useDispatch();
  const business = useSelector((s) => s.authentication.chosenBusiness);
  const [tick, setTick] = useState(0);
  const secureHided = useRef(false);
  const [loading, setLoading] = useState(false);
  //ticker
  useEffect(() => {
    const interval = setInterval(() => {
      setTick((prev) => prev + 1);
    }, TICKER_TIME_OUT);
    return () => clearInterval(interval);
  }, []);

  const logOut = useCallback(
    (messageFn = showTimeoutMessage) => {
      messageFn && dispatch(messageFn());
      dispatch(serviceLogoutStart({ skipApi: false }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (business?.id) {
      keepAliveLastActivitySessionStorage.val = Math.max(
        0,
        getRemainingTime(SESSION_ID_INACTIVITY_EXPIRES)
      );
    }
  }, [business?.id]);

  const handleAction = useCallback(
    async (_, idleTimer, force = false) => {
      if (
        (business?.id &&
          Math.max(0, keepAliveLastActivitySessionStorage.val) <=
            KEEP_ALIVE_TIME_OUT) ||
        force
      ) {
        setLoading(true);
        await dispatch(serviceRefreshAccessStart());
        setLoading(false);
      }
      keepAliveLastActivitySessionStorage.val = Math.max(
        0,
        getRemainingTime(SESSION_ID_INACTIVITY_EXPIRES)
      );
      idleTimer.message(
        {
          action: 'set_inactivity_call_date',
          val: getRemainingTime(SESSION_ID_INACTIVITY_EXPIRES),
        },
        false
      );
    },
    [business?.id, dispatch]
  );

  const idleTimer = useIdleTimer({
    onAction: handleAction,
    timeout: 0,
    promptBeforeIdle: -1,
    stopOnIdle: false,
    eventsThrottle: EVENTS_THROTTLE_TIME_OUT,
    crossTab: true,
    leaderElection: false,
    syncTimers: 200,
    events: ['keydown', 'mousedown', 'touchstart', 'scroll'],
    disabled: getRemainingTime(SESSION_ID_INACTIVITY_EXPIRES) === 0,
    onMessage: ({ action, val }) => {
      if (action === 'set_inactivity_call_date') {
        keepAliveLastActivitySessionStorage.val = val;
      }
    },
  });

  useEffect(() => {
    const checkExpiredAndLogOut = (
      remaining,
      messageFn = showTimeoutMessage
    ) => {
      if (remaining === 0 || remaining === null) {
        logOut(messageFn);
      }
      return remaining;
    };
    checkExpiredAndLogOut(
      getRemainingTime(SESSION_ID_EXPIRES),
      showTimeoutMessage
    );
    checkExpiredAndLogOut(
      getRemainingTime(SESSION_ID_INACTIVITY_EXPIRES),
      showInactivityMessage
    );
  }, [dispatch, tick, logOut]);

  return (
    <>
      {getRemainingTime(SESSION_ID_EXPIRES) !== 0 &&
      getRemainingTime(SESSION_ID_EXPIRES) <= WARNING_TIME_OUT &&
      !secureHided.current ? (
        <ExpiredModal
          type={EXPIRED_MODAL_TYPES.SECURITY}
          handleSubmit={() => {
            logOut(null);
          }}
          remaining={getRemainingTime(SESSION_ID_EXPIRES)}
          loading={loading}
          logOut={logOut}
          onHide={() => {
            secureHided.current = true;
          }}
        />
      ) : null}
      {getRemainingTime(SESSION_ID_INACTIVITY_EXPIRES) !== 0 &&
      getRemainingTime(SESSION_ID_INACTIVITY_EXPIRES) <= WARNING_TIME_OUT &&
      getRemainingTime(SESSION_ID_EXPIRES) > WARNING_TIME_OUT ? (
        <ExpiredModal
          type={EXPIRED_MODAL_TYPES.INACTIVITY}
          handleSubmit={async () => {
            await handleAction(null, idleTimer, true);
          }}
          handleCancel={() => {
            logOut(showInactivityMessage);
          }}
          remaining={getRemainingTime(SESSION_ID_INACTIVITY_EXPIRES)}
          logOut={logOut}
          loading={loading}
          onHide={async () => {
            await handleAction(null, idleTimer, true);
          }}
        />
      ) : null}
    </>
  );
};

export default MonitorSessionTimeout;
