import { grayScale } from 'app/colors';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { EVENT_STATUSES } from 'pages/Broadcasts/constants';
import { getEventActionOptions } from 'pages/Broadcasts/helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventActionsSelect, StyledActionsButton } from './styles';

export const EventActionsButton = ({
  onSelectAction,
  parentCont,
  access,
  event,
  isPastEvent,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [placement, setPlacement] = useState('bottom-end');
  const ref = useRef(null);
  const isPast =
    ![EVENT_STATUSES.scheduled, EVENT_STATUSES.prestarted].includes(
      event.status
    ) ||
    //weird but BE can return just started events with scheduled status
    (event.status === EVENT_STATUSES.scheduled && isPastEvent);

  const options = useMemo(
    () => getEventActionOptions(t, access, isPast, event.status),
    [t, access, isPast, event.status]
  );
  const handleSelect = ({ value }) => {
    onSelectAction(value, event);
    setShow(false);
  };

  useEffect(() => {
    const scrollParent = parentCont.current;
    const closeOnScroll = () => setShow(false);

    if (show) {
      scrollParent?.addEventListener('scroll', closeOnScroll);
      setPlacement(
        window.innerHeight - ref.current.getBoundingClientRect().bottom < 100
          ? 'top-end'
          : 'bottom-end'
      );
    }

    return () => scrollParent?.removeEventListener('scroll', closeOnScroll);
  }, [show, parentCont]);

  return options.length ? (
    isPast ? (
      options.map(({ label, value, icon, size }) => (
        <StyledActionsButton
          key={label}
          sizing="dense"
          title={label}
          onClick={() => onSelectAction(value, event)}
          color={{ light: grayScale.dark, dark: grayScale.mediumDark }}
        >
          <IconSizing size={size}>
            <Icon icon={icon} />
          </IconSizing>
        </StyledActionsButton>
      ))
    ) : (
      <>
        <IconButton
          sizing="dense"
          title={t('Select Action')}
          ref={ref}
          onClick={() => setShow(true)}
          color={{ light: grayScale.dark, dark: grayScale.mediumDark }}
          style={{ margin: '-4px 0' }}
        >
          <IconSizing>
            <Icon icon="three-dot" />
          </IconSizing>
        </IconButton>
        <SelectOverlay
          show={show}
          target={ref.current}
          onHide={() => setShow(false)}
          placement={placement}
        >
          <EventActionsSelect
            onChange={handleSelect}
            options={options}
            menuOnly
            data-qa-event-action={event.id}
          />
        </SelectOverlay>
      </>
    )
  ) : null;
};
