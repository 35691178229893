import { getDataQAForInput } from 'components/Inputs/helpers';
import FieldInput from 'components/Fields/FieldInput';
import { FieldLayout } from './styles';
import { getPercentageChanceToCloseProps } from 'pages/CustomObjects/RecordsPage/helpers';
import { CONTACT, dummyObject } from './helpers';
import { isStageField } from 'checks/fields';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { useMemo } from 'react';
import FieldService from 'services/FieldService';
import { isPipelineObject } from '../utilities';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';
import { useTranslation } from 'react-i18next';

export const Fields = ({
  selectedFields,
  handleChangeField,
  validationProps,
  handleUpdateFieldOption,
  details,
  objectType,
  values,
  objectId,
}) => {
  const { t } = useTranslation();
  const serviceToUse = useMemo(() => {
    if (details.name === 'client_client') return FieldService;
    return isPipelineObject(details) ? PipelineService : CustomObjectsService;
  }, [details]);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    selectedFields,
    objectType === CONTACT ? dummyObject : details
  );
  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <FieldLayout>
        {selectedFields?.map((field, i) => {
          const stageField = selectedFields?.find(isStageField);
          return (
            <div className={`span${field.meta.cols || 1}`} key={field.id}>
              <FieldInput
                margin
                variant="underline"
                field={field}
                object={objectType === CONTACT ? dummyObject : details}
                value={values[field.id]}
                onChange={(val, event, err) =>
                  handleChangeField(val, field, err)
                }
                isGetFullObject // to ensure the whole fileObject is returned not just the id
                serviceToUse={serviceToUse}
                objectId={objectId}
                {...getDataQAForInput(
                  `full-experience-form-${field.name}`,
                  field.fieldType
                )}
                handleUpdateFieldOption={handleUpdateFieldOption}
                {...getPercentageChanceToCloseProps(
                  field,
                  selectedFields,
                  {
                    stage: { value: values[stageField?.id] },
                    percentageChanceToClose: values[field.id],
                  },
                  t
                )}
                {...validationProps(field.id)}
                {...getKeyListenersProps(field.id)}
                fetchUrl={
                  objectType === CONTACT
                    ? 'clients'
                    : `custom-objects/${objectId}`
                }
              />
            </div>
          );
        })}
      </FieldLayout>
    </KeyBoardContext.Provider>
  );
};
