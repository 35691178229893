import ButtonGroup from 'components/Inputs/ButtonGroup';
import React, { useCallback, useContext, useMemo } from 'react';
import KizenTypography from 'app/kizentypo';
import { StyledWizardField } from '../../../../shared/styles';
import { gutters } from 'app/spacing';
import { useTranslation } from 'react-i18next';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import {
  DISPLAY_VALUE_RESPONSES,
  VALUE_TO_DISPLAY_RESPONSES,
} from '../../../types';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import PipelineService from 'services/PipelineService';
import { useQuery } from 'react-query';
import { DASHBOARD } from 'queries/query-keys';
import MultiSelect from 'components/Inputs/MultiSelect';
import useDataConstraint from 'components/Wizards/shared/hooks/useDataConstraint';

const StagesOverTimeReportType = ({ mobile, active }) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);

  const {
    reportType,
    valueToDisplay,
    currencySymbol,
    stages,
    pipeline,
    value,
  } = state;

  const fetchStagesQuery = useCallback(async () => {
    try {
      const res = await PipelineService.getStagesListByModel(pipeline?.value, {
        skipErrorBoundary: true,
      });

      return res;

      // react-query should never be made aware of this error in case it fails, to
      // allow the dashlet editor to recover from a deleted pipeline object
    } catch (_ex) {
      return undefined;
    }
  }, [pipeline]);

  const { data: stagesData, isLoading: stagesLoading } = useQuery(
    [...DASHBOARD.PIPELINE_STAGES, pipeline?.value],
    fetchStagesQuery,
    {
      enabled:
        Boolean(pipeline?.value) &&
        reportType?.value === REPORT_TYPES.STAGES_OVER_TIME,
    }
  );

  const stagesOptions = useMemo(() => {
    return (
      stagesData?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? []
    );
  }, [stagesData]);

  const numberRecordsResponse = useMemo(() => {
    return {
      label: t('# Records'),
      value: DISPLAY_VALUE_RESPONSES.RECORDS,
    };
  }, [t]);

  const totalValueOption = useMemo(() => {
    return {
      label: t('Total Value'),
      value: VALUE_TO_DISPLAY_RESPONSES.TOTAL,
    };
  }, [t]);

  const valueOptions = useMemo(() => {
    return [
      numberRecordsResponse,
      {
        label: `${currencySymbol} ${t('Value')}`,
        value: DISPLAY_VALUE_RESPONSES.VALUE,
      },
    ];
  }, [numberRecordsResponse, t, currencySymbol]);

  const valueToDisplayOptions = useMemo(() => {
    return [
      totalValueOption,
      {
        label: t('Weighted Value'),
        value: VALUE_TO_DISPLAY_RESPONSES.WEIGHTED,
      },
    ];
  }, [totalValueOption, t]);

  useDefaultValues(
    {
      value: numberRecordsResponse,
      valueToDisplay: totalValueOption,
    },
    active && reportType?.value === REPORT_TYPES.STAGES_OVER_TIME
  );

  useDataConstraint((internalState) => {
    if (
      internalState.valueToDisplay?.value !==
        VALUE_TO_DISPLAY_RESPONSES.WEIGHTED &&
      internalState.valueToDisplay?.value !== VALUE_TO_DISPLAY_RESPONSES.TOTAL
    ) {
      return {
        key: 'valueToDisplay',
        value: totalValueOption,
      };
    }
  });

  const stagesValue = useMemo(() => {
    return (
      stages?.map?.((stage) => {
        if (stage.value && stage.label) {
          return stage;
        }
        const found = stagesOptions.find((o) => o.value === stage.value);
        if (found) {
          return found;
        }
        return stages;
      }) ?? []
    );
  }, [stages, stagesOptions]);

  const stagesOnChange = useCallback(
    (value) => {
      setState('stages', value);
    },
    [setState]
  );

  // Hide self if the report type is not stages over time
  if (reportType?.value !== REPORT_TYPES.STAGES_OVER_TIME) {
    return null;
  }

  return (
    <>
      <StyledWizardField top={gutters.spacing(2, 2)}>
        <KizenTypography type="subheader">
          {t('Choose Stages')} {t('(Leave Blank for All)')}
        </KizenTypography>
        <MultiSelect
          value={stagesValue}
          options={stagesOptions}
          onChange={stagesOnChange}
          placeholder={t('Find Stages')}
          isLoading={stagesLoading}
          className="modal-multiselect stages"
        />
      </StyledWizardField>
      <StyledWizardField top={gutters.spacing(6)}>
        <KizenTypography type="subheader">
          {t('Select Metric of Interest')}
        </KizenTypography>
        <StyledWizardField top={gutters.spacing(1, 1)}>
          <ButtonGroup
            value={value}
            onChange={(v) => setState('value', v)}
            sizing={!mobile ? 'auto' : ''}
            options={valueOptions}
            className="wizard-buttons metric-of-interest"
            columns={mobile ? 2 : 4}
            mode="wizard"
            gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
          />
        </StyledWizardField>
      </StyledWizardField>
      {value?.value === DISPLAY_VALUE_RESPONSES.VALUE ? (
        <StyledWizardField top={gutters.spacing(6)}>
          <KizenTypography type="subheader">
            {t('Select Value to Display')}
          </KizenTypography>
          <StyledWizardField top={gutters.spacing(1, 1)}>
            <ButtonGroup
              value={valueToDisplay}
              onChange={(v) => setState('valueToDisplay', v)}
              sizing={!mobile ? 'auto' : ''}
              options={valueToDisplayOptions}
              className="wizard-buttons value-to-display"
              columns={mobile ? 2 : 4}
              mode="wizard"
              gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
            />
          </StyledWizardField>
        </StyledWizardField>
      ) : null}
    </>
  );
};

export default StagesOverTimeReportType;
