import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import Input from 'components/Kizen/Input';
import Button from 'components/Button';
import CardSection from 'components/Layout/CardSection';
import SmallTableCard from 'components/Layout/SmallTableCard';
import BasicModal from 'components/Modals/presets/BasicModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import BasicTable from 'components/Tables/Basic';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { ScrollContainerBlocker } from 'components/Tables/ScrollContainerStyles';
import CardToolbar, {
  CardToolbarButton,
  CardToolbarTitle,
} from 'components/Layout/CardToolbar';
import { TRow } from 'components/Kizen/Table';
import { ContentWidth, PageSizing } from 'components/Layout/PageContentWidth';
import ConfirmNavigationModal from 'components/Modals/presets/ConfirmNavigation';
import { toastVariant, useToast } from 'components/ToastProvider';
import { serviceRefreshAccessStart } from 'store/authentication/authenticationAction';
import {
  getPermissionGroupColumns,
  getRoleColumns,
  getTeamColumns,
} from './columns';
import TeamMemberForm from './TeamMemberForm';
import RoleForm, { validateRoleForm } from './RoleForm';
import { PermissionGroupModal as V2PermissionGroupModal } from './PermissionGroupModal_V2';
import BigTable from 'components/Tables/Big';
import { layers } from 'app/spacing';
import TablePagination from 'components/Tables/Big/TablePagination';
import BigTableLayout from 'components/Layout/BigTableLayout';
import { gutters } from 'app/spacing';
import { getOriginalError } from 'services/AxiosService';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { getChosenBusiness } from 'store/authentication/selectors';
import useFormValidation from 'hooks/useFormValidation';
import useSearchParam from 'hooks/useSearchParam';
import { useHistory } from 'react-router-dom';
import { setSearchParams } from 'hooks/useSearchParam';
import { ApplyTemplateModal } from './ApplyTemplateModal';
import { APPLY_TEMPLATES_TYPES, TEMPLATES_TYPES } from './constants';
import TeamMemberService from 'services/TeamMemberService';
import { StyledButtonWithDropdown, Wrapper } from './styles';
import UploadTeamMembersModal from './UploadTeamMembersModal';
import { DEFAULT_TEMPLATE_ID } from 'components/Modals/ApplyTemplateModal';
import { monitoringExceptionHelper } from 'sentry/helpers';
const CARDTOOLBAR_HEIGHT = 43; // 20px margin + 17px height + 6px padding

const getLocalizedModalType = (t) => {
  return {
    ADD: t('Add'),
    EDIT: t('Edit'),
    DELETE: t('Delete'),
    UPLOAD: t('Upload'),
  };
};

export const errorData = (message) => ({
  variant: toastVariant.FAILURE,
  delay: 3000,
  message,
});

const StyledBigTableLayout = styled(BigTableLayout)`
  min-height: auto;

  && > div {
    padding: 0;
  }

  && > div:last-child > div:last-child {
    margin-bottom: ${gutters.spacing(5)}px;
  }
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin: 20px 0;
`;

const TBSearchInput = styled(Input)`
  max-width: 200px;

  label {
    margin: 0;
  }

  .input-wrapper {
    margin: 0;
    background-color: ${grayScale.white};
  }

  input {
    min-width: 0;
    padding-right: 0;
  }
`;

const TBRightButton = styled(Button)`
  margin: 0;
  margin-right: 15px;
`;

const setOpenMenuAbove = (item, index, arr) => {
  // Last few data items should open their menus upwards
  return {
    ...item,
    meta: {
      ...item.meta,
      openMenuAbove: Math.max(10, arr.length) - index <= 3,
    },
  };
};

const today = new Date().toISOString();
const initialTeamMemberValues = (roles) => {
  const defaultRole = roles.filter((role) => role.defaultForNewUsers);
  return {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    startDate: today,
    roles: defaultRole || [],
    permissionGroups: [],
    trackAnnualSalesGoal: false,
    toolbarTemplate: DEFAULT_TEMPLATE_ID,
    annualSalesGoal: '', // Required field, defaulted during submission
  };
};

const initialRoleValues = {
  name: '',
  permissionGroups: [],
  defaultForNewUsers: false,
};

// API requires only array of IDs for roles and permission groups
// This helper is used for transforming array of objects to array of IDs
const arrayOfObjectsToArrayOfIds = (array) => array.map((item) => item.id);

const teamMemberToFormValues = ({
  firstName,
  lastName,
  email,
  phone,
  startDate,
  roles,
  permissionGroups,
  annualSalesGoal,
  trackAnnualSalesGoal,
}) => {
  return {
    firstName,
    lastName,
    email,
    phone,
    startDate,
    roles,
    permissionGroups,
    annualSalesGoal,
    trackAnnualSalesGoal,
  };
};

const teamMemberValuesToApiValues = ({
  permissionGroups,
  roles,
  annualSalesGoal,
  phone,
  toolbarTemplate,
  ...others
}) => {
  return {
    ...others,
    phone: phone || '',
    roles: arrayOfObjectsToArrayOfIds(roles),
    permissionGroups: arrayOfObjectsToArrayOfIds(permissionGroups),
    annualSalesGoal: annualSalesGoal || '0',
    ...(toolbarTemplate?.value !== DEFAULT_TEMPLATE_ID
      ? { toolbarTemplate }
      : null),
  };
};

const roleValuesToApiValues = ({
  name,
  permissionGroups,
  defaultForNewUsers = false,
}) => {
  return {
    name,
    permissionGroups: arrayOfObjectsToArrayOfIds(permissionGroups),
    defaultForNewUsers: defaultForNewUsers,
  };
};

const PermissionType = {
  TEAM_MEMBER: 'teamMember',
  UPLOAD: 'upload',
  ROLE: 'role',
  PERMISSION_GROUP: 'permissionGroup',
};

const getLocalizedPermissionTypeTitles = (t) => {
  return {
    teamMember: t('Team Member'),
    role: t('Role'),
    permissionGroup: t('Permission Group'),
  };
};

export default function TeamTabularPage(props) {
  const {
    userId,
    team: teamWithoutMeta,
    roles: rolesWithoutMeta,
    permissionGroups: permissionGroupsWithoutMeta,
    search,
    onChangeSearch,
    teamSort,
    onChangeTeamSort,
    roleSort,
    onChangeRoleSort,
    permissionGroupSort,
    onChangePermissionGroupSort,
    onUpdateTeamMember,
    onConfirmDeleteTeamMember,
    onConfirmDeleteRole,
    onConfirmDeletePermissionGroup,
    onAddTeamMember,
    onAddRole,
    onUpdateRole,
    onAddPermissionGroup,
    onUpdatePermissionGroup,
    // destructured here solely so they're not spread on PageSizing,
    // which was triggering invalid event handler warnings
    onSelectResendVerification,
    onSelectResetPassword,
    onSelectDuplicateRole,
    onSelectDuplicatePermissionGroup,
    updatePermissionGroups,

    setPerPage,
    perPage,
    setPageNumber,
    pageNumber,
    access,
    ...others
  } = props;
  const { count: teamMembersCount } = teamWithoutMeta;
  // So that we can reference props in memoized values
  const propsRef = useRef();
  propsRef.current = props;
  const preReleaseFeature = usePreReleaseFeatures();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [formSave, setFormSave] = useState(null);
  const [roleErrors, setRoleErrors] = useState(null);
  const [formDirty, setFormDirty] = useState(false); // role & team member modal
  const [confirmFormDiscard, setConfirmFormDiscard] = useState(false);
  const [showToast] = useToast();
  const pg = useSearchParam('pg');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chosenBusiness = useSelector(getChosenBusiness);
  const [templateModal, setTemplateModal] = useState({});

  const history = useHistory();
  const {
    admin_permission_group: adminPermissionGroup,
    admin_permission_role: adminPermissionRole,
  } = chosenBusiness;

  useEffect(() => {
    if (pg) {
      setFormSave({
        formType: PermissionType.PERMISSION_GROUP,
        modalType: getLocalizedModalType(t).EDIT,
        record: { id: pg },
      });
      setSearchParams(history, { pg: null });
    }
  }, [history, pg, t]);

  const [teamMemberValues, setTeamMemberValues] = useState({
    ...initialTeamMemberValues(rolesWithoutMeta),
  });

  const {
    validateFormState,
    validationProps,
    handleInputChange,
    resetValidationState,
    setValidationState,
    firstFieldWithErrorRef,
  } = useFormValidation({
    setFormDirty,
    formState: teamMemberValues,
    setFormState: setTeamMemberValues,
  });

  const team = useMemo(() => {
    return teamWithoutMeta.results.map(setOpenMenuAbove);
  }, [teamWithoutMeta]);

  const newTeamMemberOptions = useMemo(() => {
    return [
      {
        value: 'new_team_member',
        label: t('New Team Member'),
      },
      {
        value: 'upload_team_members',
        label: t('Upload Team Members'),
      },
    ];
  }, [t]);

  const handleClickMenu = ({ value }) => {
    if (value === 'new_team_member') {
      setFormSave({
        formType: PermissionType.TEAM_MEMBER,
        modalType: getLocalizedModalType(t).ADD,
      });
    }
    if (value === 'upload_team_members') {
      if (chosenBusiness?.is_trial) {
        showToast({
          message: t(
            'Team Member upload is not available during trial period.'
          ),
          variant: toastVariant.FAILURE,
        });
      } else {
        setFormSave({
          formType: PermissionType.UPLOAD,
          modalType: getLocalizedModalType(t).UPLOAD,
        });
      }
    }
  };

  const roles = useMemo(() => {
    return rolesWithoutMeta.map(setOpenMenuAbove).map((role) => ({
      ...role,
      permissionGroups: role.permissionGroups
        .map((pg) => {
          if (pg && typeof pg === 'object') {
            return pg;
          }
          // This is an exception to deal with the current status of the API
          // The should move from sending us pg ids to pg { id, name } so
          // we don't need to do the lookup ourselves.
          return permissionGroupsWithoutMeta.find(({ id }) => id === pg);
        })
        .filter(Boolean),
    }));
  }, [rolesWithoutMeta, permissionGroupsWithoutMeta]);

  const permissionGroups = useMemo(() => {
    return permissionGroupsWithoutMeta.map(setOpenMenuAbove);
  }, [permissionGroupsWithoutMeta]);

  const [roleValues, setRoleValues] = useState(initialRoleValues);
  const teamColumns = useMemo(
    () =>
      getTeamColumns(
        {
          onUpdateField: (member, patch) =>
            propsRef.current.onUpdateTeamMember(member, patch),
          onSelectAction: ({ value }, member) => {
            const { id, email } = member;
            if (value === 'delete') {
              setDeleteConfirmation({ type: PermissionType.TEAM_MEMBER, id });
            }
            if (value === 'resend-verification') {
              propsRef.current.onSelectResendVerification(id);
            }
            if (value === 'reset-password') {
              propsRef.current.onSelectResetPassword(email);
            }
            if (value === 'edit') {
              setTeamMemberValues(teamMemberToFormValues(member));
              setFormSave({
                formType: PermissionType.TEAM_MEMBER,
                modalType: getLocalizedModalType(t).EDIT,
                record: member,
              });
            }
            if (value === 'apply-toolbar') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.toolbars,
                data: member,
                applyTo: APPLY_TEMPLATES_TYPES.teamMembers,
              });
            }
            if (value === 'apply-columns') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.columns,
                data: member,
                applyTo: APPLY_TEMPLATES_TYPES.teamMembers,
              });
            }
            if (value === 'apply-quick-filters') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.quickFilters,
                data: member,
                applyTo: APPLY_TEMPLATES_TYPES.teamMembers,
              });
            }
          },
        },
        t,
        preReleaseFeature,
        showToast,
        userId,
        access,
        chosenBusiness
      ),
    [t, preReleaseFeature, showToast, userId, access, chosenBusiness]
  );

  const roleColumns = useMemo(
    () =>
      getRoleColumns(
        {
          onClickName: (role) => {
            setRoleValues(role);
            setFormSave({
              formType: PermissionType.ROLE,
              modalType: getLocalizedModalType(t).EDIT,
              record: role,
            });
          },
          onSelectAction: ({ value }, role) => {
            const { id } = role;
            if (value === 'delete') {
              setDeleteConfirmation({ type: 'role', id });
            }
            if (value === 'duplicate') {
              propsRef.current.onSelectDuplicateRole(id);
            }
            if (value === 'edit') {
              setRoleValues(role);
              setFormSave({
                formType: PermissionType.ROLE,
                modalType: getLocalizedModalType(t).EDIT,
                record: role,
              });
            }
            if (value === 'apply-toolbar') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.toolbars,
                data: role,
                applyTo: APPLY_TEMPLATES_TYPES.roles,
              });
            }
            if (value === 'apply-columns') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.columns,
                data: role,
                applyTo: APPLY_TEMPLATES_TYPES.roles,
              });
            }
            if (value === 'apply-quick-filters') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.quickFilters,
                data: role,
                applyTo: APPLY_TEMPLATES_TYPES.roles,
              });
            }
          },
        },
        t,
        access,
        adminPermissionRole
      ),
    [t, access, adminPermissionRole]
  );

  const permissionGroupColumns = useMemo(
    () =>
      getPermissionGroupColumns(
        {
          onClickName: (pg) => {
            setFormSave({
              formType: PermissionType.PERMISSION_GROUP,
              modalType: getLocalizedModalType(t).EDIT,
              record: pg,
            });
          },
          onSelectAction: ({ value }, pg) => {
            const { id } = pg;
            if (value === 'delete') {
              setDeleteConfirmation({ type: 'permissionGroup', id });
            }
            if (value === 'duplicate') {
              propsRef.current.onSelectDuplicatePermissionGroup(id);
            }
            if (value === 'edit') {
              setFormSave({
                formType: PermissionType.PERMISSION_GROUP,
                modalType: getLocalizedModalType(t).EDIT,
                record: pg,
              });
            }
            if (value === 'apply-toolbar') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.toolbars,
                data: pg,
                applyTo: APPLY_TEMPLATES_TYPES.permissionGroups,
              });
            }
            if (value === 'apply-columns') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.columns,
                data: pg,
                applyTo: APPLY_TEMPLATES_TYPES.permissionGroups,
              });
            }
            if (value === 'apply-quick-filters') {
              setTemplateModal({
                templateType: TEMPLATES_TYPES.quickFilters,
                data: pg,
                applyTo: APPLY_TEMPLATES_TYPES.permissionGroups,
              });
            }
          },
        },
        t,
        access,
        adminPermissionGroup
      ),
    [t, access, adminPermissionGroup]
  );

  const teamHeadData = useMemo(
    () => ({
      meta: {
        sort: teamSort,
        onSort: (column, direction) => onChangeTeamSort({ column, direction }),
      },
    }),
    [teamSort, onChangeTeamSort]
  );
  const roleHeadData = useMemo(
    () => ({
      meta: {
        sort: roleSort,
        onSort: (column, direction) => onChangeRoleSort({ column, direction }),
      },
    }),
    [roleSort, onChangeRoleSort]
  );
  const permissionGroupHeadData = useMemo(
    () => ({
      meta: {
        sort: permissionGroupSort,
        onSort: (column, direction) =>
          onChangePermissionGroupSort({ column, direction }),
      },
    }),
    [permissionGroupSort, onChangePermissionGroupSort]
  );

  const handleHideForm = useCallback(() => {
    resetValidationState();
    setFormSave(null);
    setTeamMemberValues({ ...initialTeamMemberValues(rolesWithoutMeta) });
    setRoleValues(initialRoleValues);
    setRoleErrors(null);
  }, [resetValidationState, rolesWithoutMeta]);

  const onClickExportTeam = async () => {
    try {
      await TeamMemberService.exportTeam({}, {}, { skipErrorBoundary: true });
      showToast({
        message: t(
          'Team Members were queued for export successfully. You will be emailed a download link once complete.'
        ),
        variant: toastVariant.SUCCESS,
      });
    } catch (err) {
      monitoringExceptionHelper(err);
      showToast({
        message: t(
          'Failed to export Team Members. Please try again or contact Kizen support.'
        ),
        variant: toastVariant.FAILURE,
      });
    }
  };

  return (
    <PageSizing {...others}>
      {deleteConfirmation && (
        <ConfirmDeletionModal
          show
          onHide={() => setDeleteConfirmation(null)}
          onConfirm={async () => {
            const { type, id } = deleteConfirmation;
            try {
              if (type === PermissionType.TEAM_MEMBER) {
                await onConfirmDeleteTeamMember(id);
              }
              if (type === PermissionType.ROLE) {
                await onConfirmDeleteRole(id);
              }
              if (type === PermissionType.PERMISSION_GROUP) {
                await onConfirmDeletePermissionGroup(id);
              }
            } catch (err) {
              const { data } = err.response;
              if (data.statusCode === 400) {
                const [error] = data.errors;
                showToast(errorData(error));
              }
            } finally {
              setDeleteConfirmation(null);
            }
          }}
          typeOfContent="text"
          defaultLeftBtnText={t('Cancel')}
        >
          <KizenTypography>
            {deleteConfirmation.type === PermissionType.TEAM_MEMBER &&
              t('This will permanently delete the Team Member')}
            {deleteConfirmation.type === PermissionType.ROLE &&
              t('This will permanently delete the Role')}
            {deleteConfirmation.type === PermissionType.PERMISSION_GROUP &&
              t('This will permanently delete the Permission Group')}
          </KizenTypography>
        </ConfirmDeletionModal>
      )}
      <ConfirmNavigationModal
        when={formDirty}
        show={confirmFormDiscard}
        onHide={() => {
          setConfirmFormDiscard(false);
        }}
        onConfirm={() => {
          handleHideForm();
          setConfirmFormDiscard(false);
          setFormDirty(false);
        }}
      />

      {formSave && formSave.formType !== PermissionType.PERMISSION_GROUP && (
        <BasicModal
          show
          heading={`${formSave.modalType} ${
            getLocalizedPermissionTypeTitles(t)[formSave.formType]
          }`}
          buttonText={t('Save')}
          disabled={
            formSave.formType === PermissionType.ROLE &&
            !validateRoleForm(roleValues)
          }
          leftBtn={null}
          onHide={() => {
            if (formDirty) {
              setConfirmFormDiscard(true);
            } else {
              handleHideForm();
            }
          }}
          onConfirm={async () => {
            const { formType, modalType, record } = formSave;
            if (formType === PermissionType.TEAM_MEMBER) {
              if (!validateFormState(t)) {
                return;
              }
              if (modalType === getLocalizedModalType(t).ADD) {
                try {
                  await onAddTeamMember(
                    teamMemberValuesToApiValues(teamMemberValues)
                  );
                } catch (err) {
                  const orig = getOriginalError(err);
                  if (err?.response?.status === 400 && orig?.errors) {
                    firstFieldWithErrorRef.current = Object.keys(
                      orig.errors
                    )[0];
                    setValidationState(orig.errors);
                    return;
                  }
                }
              } else {
                try {
                  await onUpdateTeamMember(
                    record,
                    teamMemberValuesToApiValues(teamMemberValues)
                  );
                } catch (err) {
                  const data = err.response?.data;
                  if (data?.statusCode === 400) {
                    const [error] = data.errors;
                    showToast(errorData(error));
                  }
                }
              }
            }
            if (formType === PermissionType.ROLE) {
              try {
                if (modalType === getLocalizedModalType(t).ADD) {
                  await onAddRole(roleValuesToApiValues(roleValues));
                } else {
                  await onUpdateRole(record, roleValuesToApiValues(roleValues));
                }
                setRoleErrors(null);
              } catch (err) {
                const { data, status } = err.response;
                if (status === 400) {
                  const originalError = snakeToCamelCaseKeys(
                    getOriginalError(err)
                  );
                  if (originalError) {
                    setRoleErrors(originalError);
                    return;
                  }
                  showToast(errorData(data.errors.detail));
                  return;
                }
              }
            }
            setFormSave(null);
            setFormDirty(false);
            setTeamMemberValues({ ...initialTeamMemberValues(roles) });
            resetValidationState();
            setRoleValues(initialRoleValues);
          }}
        >
          {formSave && formSave.formType === PermissionType.TEAM_MEMBER && (
            <TeamMemberForm
              isEdit={formSave.modalType === getLocalizedModalType(t).EDIT}
              roles={roles}
              permissionGroups={permissionGroups}
              values={teamMemberValues}
              validationProps={validationProps}
              disableEmail={
                formSave.modalType === getLocalizedModalType(t).EDIT
              }
              onChange={handleInputChange}
            />
          )}
          {formSave && formSave.formType === PermissionType.ROLE && (
            <RoleForm
              permissionGroups={permissionGroups}
              values={roleValues}
              fieldErrors={roleErrors}
              onChange={(field, value) => {
                setRoleValues({ ...roleValues, [field]: value });
                setFormDirty(true);
                if (field === 'name' && value?.trim()?.length > 0) {
                  setRoleErrors(null);
                }
              }}
            />
          )}
        </BasicModal>
      )}
      {formSave && formSave.formType === PermissionType.PERMISSION_GROUP && (
        <V2PermissionGroupModal
          id={formSave?.record?.id}
          onHide={() => setFormSave(null)}
          onConfirm={() => {
            updatePermissionGroups();
            dispatch(serviceRefreshAccessStart());
          }}
          scrollToSection
        />
      )}
      {formSave && formSave.formType === PermissionType.UPLOAD && (
        <UploadTeamMembersModal
          show
          onHide={() => setFormSave(null)}
          categorizedFields={[]}
        />
      )}
      {access.teamMembers.edit ? (
        <>
          <ContentWidth>
            <Toolbar>
              <TBSearchInput
                pill
                type={Input.INPUT_TYPES.SEARCH}
                placeholder={t('Find Team Members')}
                value={search}
                onChange={onChangeSearch}
              />
              <KizenTypography as="h2" type="header">
                {teamMembersCount.toLocaleString()}{' '}
                {teamMembersCount === 1 ? t('Team Member') : t('Team Members')}
              </KizenTypography>
              <Wrapper>
                <TBRightButton onClick={onClickExportTeam} color="blue">
                  {t('Export to CSV')}
                </TBRightButton>
                <StyledButtonWithDropdown
                  onChange={handleClickMenu}
                  onClick={() =>
                    setFormSave({
                      formType: PermissionType.TEAM_MEMBER,
                      modalType: getLocalizedModalType(t).ADD,
                    })
                  }
                  options={newTeamMemberOptions}
                >
                  {t('New Team Member')}
                </StyledButtonWithDropdown>
              </Wrapper>
            </Toolbar>
          </ContentWidth>
          <StyledBigTableLayout
            hideCurtainWrapper={true}
            pagination={
              <TablePagination
                page={pageNumber}
                perPage={perPage}
                totalCount={teamMembersCount}
                onChangePage={setPageNumber}
                onChangePerPage={setPerPage}
                showPageSizeOptions={false}
              />
            }
          >
            <BigTable
              headerPosition="unset"
              columns={teamColumns}
              head={<TRow head columns={teamColumns} data={teamHeadData} />}
              zIndex={layers.content(0, 0)} // needs to at zero - {todo} reafctor big table so the z-indexes work with out hacks
              scrolledToTable={false}
            >
              {team.map((teamMember) => (
                <TRow
                  key={teamMember.id}
                  columns={teamColumns}
                  data={teamMember}
                />
              ))}
            </BigTable>
          </StyledBigTableLayout>
        </>
      ) : null}
      <CardSection as={ContentWidth}>
        {access.roles.edit ? (
          <SmallTableCard relative>
            {/* This is a hack to get the scroll container to work with the card toolbar,
              the TableScrollWarpper needs to be Flex 100% to work so it's always just less than 1px too narrow
              setting flex-shrink to 0 breaks the expandang tool bars
             */}
            <ScrollContainerBlocker top={CARDTOOLBAR_HEIGHT} />
            <CardToolbar>
              <CardToolbarTitle>{t('Roles')}</CardToolbarTitle>
              <CardToolbarButton
                onClick={() =>
                  setFormSave({
                    formType: PermissionType.ROLE,
                    modalType: getLocalizedModalType(t).ADD,
                  })
                }
                data-qa="add-role-button"
              >
                + {t('Add Role')}
              </CardToolbarButton>
            </CardToolbar>
            <TableScrollContainer bottom>
              <BasicTable
                stickyHeader
                head={<TRow head columns={roleColumns} data={roleHeadData} />}
                data-qa="role-table"
              >
                {roles.map((role) => (
                  <TRow key={role.id} columns={roleColumns} data={role} />
                ))}
              </BasicTable>
            </TableScrollContainer>
          </SmallTableCard>
        ) : null}
        {access.permissionGroups.edit ? (
          <SmallTableCard relative>
            {/* This is a hack to get the scroll container to work with the card toolbar,
              the TableScrollWarpper needs to be Flex 100% to work so it's always just less than 1px too narrow
              setting flex-shrink to 0 breaks the expandang tool bars
             */}
            <ScrollContainerBlocker top={CARDTOOLBAR_HEIGHT} />
            <CardToolbar>
              <CardToolbarTitle>{t('Permission Groups')}</CardToolbarTitle>
              <CardToolbarButton
                data-qa="add-permission-group-button"
                onClick={() =>
                  setFormSave({
                    formType: PermissionType.PERMISSION_GROUP,
                    modalType: getLocalizedModalType(t).ADD,
                  })
                }
              >
                + {t('Add Permission Group')}
              </CardToolbarButton>
            </CardToolbar>
            <TableScrollContainer bottom>
              <BasicTable
                stickyHeader
                head={
                  <TRow
                    head
                    columns={permissionGroupColumns}
                    data={permissionGroupHeadData}
                  />
                }
                data-qa="permission-group-table"
              >
                {permissionGroups.map((permissionGroup) => (
                  <TRow
                    key={permissionGroup.id}
                    columns={permissionGroupColumns}
                    data={permissionGroup}
                  />
                ))}
              </BasicTable>
            </TableScrollContainer>
          </SmallTableCard>
        ) : null}
      </CardSection>
      {templateModal.data ? (
        <ApplyTemplateModal
          {...templateModal}
          show
          onHide={() => setTemplateModal({})}
        />
      ) : null}
    </PageSizing>
  );
}
