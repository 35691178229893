import { ForwardedRef, ReactNode, forwardRef } from 'react';
import styled from '@emotion/styled';
import {
  ElementContainer,
  DEFAULT_ELEMENT_CONTAINER_PROPS,
} from './ElementContainer';
import { DividerProps } from '../../types';
import { getTypeInfo, NodeType } from '../types';

type DividerContainerProps = DividerProps & {
  children: ReactNode;
  draggingType?: NodeType;
};

export const DEFAULT_DIVIDER_CONTAINER_PROPS = {
  ...DEFAULT_ELEMENT_CONTAINER_PROPS,
  containerBorderRadius: false,
  containerPaddingTop: '10',
  containerPaddingRight: '10',
  containerPaddingBottom: '10',
  containerPaddingLeft: '10',
};

const justify = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const Container = styled(ElementContainer)<DividerContainerProps>`
  display: flex;
  justify-content: ${({ alignment = 'center' }) => justify[alignment]};
`;

export const DividerContainer = forwardRef(
  (
    { children, alignment, draggingType, ...rest }: DividerContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Container
      ref={ref}
      ignoreChildrenDrag={getTypeInfo(draggingType)?.isContent}
      alignment={alignment}
      {...rest}
    >
      {children}
    </Container>
  )
);
