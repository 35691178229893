import PriceInput from '__components/Inputs/TextInput/presets/PriceNumber';
import { PriceProps } from './Price';
import { RangeInputContainer } from './styled';
import { NumberInputHandlerParams, RangeInputProps } from './types';

export const PriceRange = ({
  step,
  value,
  width,
  placeholder,
  currencySymbol,
  error,
  onChange,
}: RangeInputProps<number | null> & PriceProps) => {
  const min = value?.[0];
  const max = value?.[1];

  return (
    <RangeInputContainer width={width}>
      <PriceInput
        value={min}
        width={width}
        placeholder={placeholder}
        currencySymbol={currencySymbol}
        error={error}
        onValueChange={({ floatValue }: NumberInputHandlerParams) => {
          onChange(step, [floatValue === undefined ? null : floatValue, max]);
        }}
      />
      <PriceInput
        value={max}
        width={width}
        placeholder={placeholder}
        currencySymbol={currencySymbol}
        error={error}
        onValueChange={({ floatValue }: NumberInputHandlerParams) => {
          onChange(step, [min, floatValue === undefined ? null : floatValue]);
        }}
      />
    </RangeInputContainer>
  );
};
