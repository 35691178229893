import Icon from 'components/Kizen/Icon';
import { ICON_BUTTON_DELAY, useTooltip } from 'components/Kizen/Tooltip';
import { useTranslation } from 'react-i18next';

const AdditionalActions = ({
  isCollapsed,
  element,
  removeItem,
  index,
  expandItem,
  collapseItem,
}) => {
  const { t } = useTranslation();

  const [expandIconTooltipProps, expandIconTooltip] = useTooltip({
    label: isCollapsed ? t('Expand') : t('Collapse'),
    delay: ICON_BUTTON_DELAY,
  });

  return !element.children?.length ? (
    <div>
      <Icon
        className="DeleteAction"
        icon="delete"
        onClick={() => removeItem(index, -1)}
      />
    </div>
  ) : (
    <div {...expandIconTooltipProps}>
      {isCollapsed ? (
        <Icon icon="chevron-right" onClick={() => expandItem(element.id)} />
      ) : (
        <Icon icon="chevron-down" onClick={() => collapseItem(element.id)} />
      )}
      {expandIconTooltip}
    </div>
  );
};

export default AdditionalActions;
