import useReactQueryTypeaheadInfinite from 'components/Inputs/Select/useReactQueryTypeaheadInfinite';
import { TEAM_MEMBERS } from 'queries/query-keys';
import { getTeamLabel } from 'services/helpers';
import TeamMemberService from 'services/TeamMemberService';
import LoadingMessage from './../useTeamMemberTypeahead/components/LoadingMessage';

const convertOption = (option) => {
  const label = getTeamLabel(option);
  return {
    value: option.id,
    label,
    data: {
      ...option,
      displayName: label,
    },
  };
};

const fetchData = async (search, pageSize, page, orderBy = 'first_name') => {
  const res = await TeamMemberService.getTeamMemberTypeahead({
    search,
    ordering: orderBy,
    page_size: pageSize,
    page,
  });
  if (res.results) {
    return res;
  }
  return { results: res };
};

const useTeamMemberTypeaheadInfinite = (onResultsChange, options = {}) => {
  const {
    debouncePeriod = 150,
    pageSize = 20,
    keepPreviousData = true,
    filterOption = () => true,
    enabled = true,
  } = options;
  const { selectProps } = useReactQueryTypeaheadInfinite(
    ({ search, page }) => fetchData(search, pageSize, page), // fetch function

    [...TEAM_MEMBERS.INFINITE, 'typeahead'], // query key
    {
      optionMapper: convertOption,
      onResultsChange,
      keepPreviousData,
      enabled,
    }, // config
    [pageSize], // dependencies (auto-injected to query key)
    debouncePeriod
  );

  return {
    ...selectProps,
    classNamePrefix: 'team-member-typeahead',
    components: {
      LoadingMessage,
      LoadingIndicator: null,
    },
    menuTopButton: null,
    filterOption,
  };
};

export default useTeamMemberTypeaheadInfinite;
