// Sorting while preserving relative ordering of elements in the case of a tie.
export const stableSort = (arr, compare) =>
  arr
    .map((item, index) => ({ item, index }))
    .sort((a, b) => compare(a.item, b.item) || a.index - b.index)
    .map(({ item }) => item);

export const getDataForActivityFilter = (
  teamMember,
  activityWhich,
  isMeKey
) => {
  if (teamMember === isMeKey) {
    return {
      teamMember: true,
      which: teamMember,
    };
  }

  return {
    teamMember: teamMember === 'null' ? null : teamMember === 'member',
    which: teamMember === 'null' ? null : activityWhich,
  };
};
