import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledEditableText } from '../../styles';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';

const CustomObjectItem = ({ item, editable, index, childIndex, editValue }) => {
  const { t } = useTranslation();

  const onChangeDisplayName = useCallback(
    (value) => {
      editValue(index, childIndex, 'displayName', value);
    },
    [editValue, index, childIndex]
  );
  const [tooltipProps, tooltip] = useTruncationTooltip({
    label: item.displayName,
  });

  return (
    <>
      {editable && (
        <>
          <StyledEditableText
            value={item.displayName ?? item.label}
            placeholder={t('Display Name')}
            onChange={onChangeDisplayName}
            {...tooltipProps}
          />
          {tooltip}
        </>
      )}
    </>
  );
};

export default CustomObjectItem;
