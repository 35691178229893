import { css } from '@emotion/core';
import styled from '@emotion/styled';
import LibraryMessage from '__components/Inputs/LibraryMessageInput';
import LongText from '__components/Inputs/LongText';

// '__components/Inputs/DateTimeInput' does not pass className to container element
// Needs to create a stacking context because DateTimeInput uses z-index values. Without
// the stacking context validation messages won't display over these inputs.
// We're using a z-index value of 2 because of the z-indexes used on the overview pages
export const DateTimeContainer = styled.div<{ width: number }>`
  position: relative;
  z-index: 2;

  ${({ width }) =>
    !isNaN(width) &&
    css`
      > div > div > div:last-of-type {
        width: ${width / 2}px;
      }
    `}
`;

export const InputContainer = styled.div<{ width?: number }>`
  ${({ width }) => width && `width: ${width}px;`}
`;

export const RangeInputContainer = styled.div<{ width?: number }>`
  display: flex;
  gap: 15px;

  > div {
    ${({ width }) => width && `width: ${width / 2}px;`}
  }
`;

export const LibraryMessageInput = styled(LibraryMessage)`
  margin-bottom: 0;
`;

export const LongTextInput = styled(LongText)`
  div {
    min-width: ${({ width }) => width}px;
  }
`;
