import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { KizenTypography } from 'app/typography';
import { grayScale, shadowLight } from 'app/colors';
import { borderRadii, gutters } from 'app/spacing';
import Handle from '../Handle';

export const PLACEHOLDER_CARD_WIDTH = 220;
export const PLACEHOLDER_CARD_HEIGHT = 69;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${PLACEHOLDER_CARD_WIDTH}px;
  height: ${PLACEHOLDER_CARD_HEIGHT}px;
  ${shadowLight};
  background: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.small};
`;

const Body = styled.div`
  padding: 0 ${gutters.spacing(2)}px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

const MovingPlaceholder = (props) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <Body>
        <KizenTypography as="span">
          {t('Child steps will have movement options upon parent placement.')}
        </KizenTypography>
      </Body>
      <Handle
        id="handle-left"
        type="target"
        position="left"
        style={{
          left: 0,
          top: '50%',
        }}
      />
      <Handle
        id="handle-right"
        type="source"
        position="right"
        style={{
          right: 0,
          top: '50%',
        }}
      />
    </Wrapper>
  );
};

export default MovingPlaceholder;
