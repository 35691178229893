import { useState, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import Collapse from 'react-bootstrap/Collapse';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';

import { layers } from 'app/spacing';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';

import {
  getRecordsIsFetchingSelector,
  getRecordsIsFilteringSelector,
  getHasFilterError,
} from 'store/customObjectsRecordsPage/selectors';
import { gutters } from 'app/spacing';
import { getDataQAForInput } from 'components/Inputs/helpers';
import ButtonWithDropdown from 'components/Button/WithDropdown';
import { BigTableWithLayoutConsumer } from 'components/Tables/Big';
import TablePagination from 'components/Tables/Big/TablePagination';
import AddRecordTab from 'components/Tables/Big/AddRecordTab';
import ExpandTableTab from 'components/Tables/Big/ExpandTableTab';
import { HoverableRow } from 'components/Tables/Big/NewRowMenuCell';
import { SizedCell as NewSizedCell } from 'pages/CustomObjects/RecordsPage/Table';

import {
  TRow,
  EmptyCell as TCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import {
  BigTableLayoutContext,
  BigTableLayoutWithProvider,
} from 'components/Layout/BigTableLayout';
import PageToolbar, {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarFiltersButton,
  FilterGroupSelector,
} from 'components/Layout/PageToolbar';
import { shadowLight } from 'app/colors';
import { getColumns, shallowEqualList } from 'pages/Common/helpers/table';
import BaseClientFilterDropdown from 'pages/Common/components/ClientFilterDropdown2';
import ArchiveModal from 'components/Modals/ArchiveModal';
import { getRecordsColumns } from '../columns';
import { newRecordOptions } from '../index';

import AddRecordTRow from 'pages/Common/components/NewAddRecordTRow';
import { useNavBarState } from 'app/navBarController';
import { PageToolbarTitle } from './PageToolbarTitle';
import { mergeColumnSettings } from 'pages/ColumnsPage/helpers';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { getDefaultNewRecord } from 'pages/Common/components/AddRecordTRow/helpers';
import { useKeyListenersInline } from 'hooks/keyboardEventHandler/useKeyListenersInline';
import Loader from 'components/Kizen/Loader';
import { QuickFilters } from 'components/Filters/QuickFilters';
import { getDefaultColumnSettings } from 'components/Wizards/CustomObject/steps/CustomLayout/subsections/DefaultColumns/helpers';
import { useClearFilter } from 'hooks/useClearFilter';
import { getBusinessTimeZone } from 'store/authentication/selectors';

const FilterDropdown = styled(BaseClientFilterDropdown)`
  ${shadowLight}
`;

// Special for testing KZN-4208
export const StyledButtonWithDropdown = styled(ButtonWithDropdown)`
  button:first-child {
    max-width: 161px;
    overflow: hidden;
  }

  .ButtonMenu__menu {
    max-width: max-content;
  }
`;

export const NewRecordButtonText = styled(TextEllipsisWithTooltip)`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  max-width: inherit;
`;

const scrollToTop = () => {
  if (window.scrollTo) {
    // If there's browser support
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
    window.scrollTop = 0;
  }
};

const CONTROL_BAR_HEIGHT = 43;

const StyledPageToolbar = styled(PageToolbar)`
  margin-top: -${gutters.spacing(1, 1)}px;
`;

const StyledBigTableLayoutWithProvider = styled(BigTableLayoutWithProvider)`
  > div:last-of-type {
    min-height: calc(
      100vh - ${({ heightNav }) => heightNav}px - ${CONTROL_BAR_HEIGHT}px
    );
  }
`;

const FilterButtonWithLayoutConsumer = ({
  badge,
  menuIsOpen,
  scrollToTop,
  toggleMenu,
  errors,
}) => {
  const { scrollTransitioned, scrolledToTable } = useContext(
    BigTableLayoutContext
  );

  const handleClick = () => {
    if (scrolledToTable) {
      scrollToTop();
      toggleMenu(true);
    } else {
      toggleMenu((ps) => !ps);
    }
  };

  return (
    <PageToolbarFiltersButton
      badge={badge}
      showBackground={menuIsOpen && !scrollTransitioned}
      onClick={handleClick}
      errors={errors}
    />
  );
};

const FilterDropdowntWithLayoutConsumer = ({ openMenu, ...props }) => {
  const { filterDropdownRef } = useContext(BigTableLayoutContext);

  return (
    <Collapse mountOnEnter in={openMenu}>
      <FilterDropdown ref={filterDropdownRef} {...props} />
    </Collapse>
  );
};

const DesktopTable = ({
  model,
  createAccess,
  records,
  recordsCount,
  recordsCountAndNew,
  fields,
  allFields,
  search = '',
  categorizedFields,
  sort,
  pageNumber,
  pageSize,
  selection,
  selectionOptions,
  stages,
  addingId,
  setAddingId,
  onSubmitRecord,
  onChangeSearch,
  onChangeSort,
  onChangePageSize,
  onChangePageNumber,
  onChecked,
  onCheckedSelection,
  onSelectViewRecord,
  onSelectArchiveRecord,
  onChoseNewRecord,
  onCreateNewRecord,
  groupId = null,
  filterGroup,
  groups,
  openMenu,
  toggleMenu,
  filterInfo,
  quickFilters,
  quickFilterConfig,
  fullFilterObject,
  applyFiltersAction,
  applyQuickFilters,
  setFilterName,
  clearFilter,
  saveGroupAction,
  deleteGroupAction,
  handleUpdateFieldOption,
  handleUpdateTableRecords,
  handleChangeGroup,
  performActionOptions = null,
  choiceAction,
  openPerformModal,
  hasUploadButton,
  onOpenSelectionMenu,
  updateFilterCount,
  errorsReturned,
  isLoadingView,
  ...others
}) => {
  const isRecordsFetching = useSelector(getRecordsIsFetchingSelector);
  const isFiltering = useSelector(getRecordsIsFilteringSelector);
  const hasFilterError = useSelector(getHasFilterError);

  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();
  const { height } = useNavBarState();

  const [archiving, setArchiving] = useState(null);

  const savedColumnSettings = useSelector(
    (s) => getColumns(s.recordsPage.pageConfig),
    shallowEqualList
  );
  const hasBulkActions = performActionOptions && !!performActionOptions.length;
  const defaultColumnSettings = useMemo(
    () => (model ? getDefaultColumnSettings(model, fields, t) : []),
    [fields, model, t]
  );

  const columnSettings = useMemo(() => {
    return mergeColumnSettings(defaultColumnSettings, savedColumnSettings);
  }, [savedColumnSettings, defaultColumnSettings]);

  const businessTimezone = useSelector(getBusinessTimeZone);
  const columns = useMemo(
    () =>
      getRecordsColumns({
        businessTimezone,
        model,
        fields,
        columnSettings,
        onSubmitRecord,
        onSelectAction: ({ value }, record) => {
          if (value === 'view') {
            onSelectViewRecord(record.id);
          }
          if (value === 'archive') {
            setArchiving(record);
          }
        },
        handleUpdateFieldOption,
        handleUpdateTableRecords,
        preReleaseFeatures,
        t,
      }),
    [
      model,
      fields,
      columnSettings,
      onSubmitRecord,
      onSelectViewRecord,
      handleUpdateFieldOption,
      businessTimezone,
      preReleaseFeatures,
      handleUpdateTableRecords,
      t,
    ]
  );

  const checkableRecords = useMemo(() => {
    return records.map((row, index, arr) => ({
      ...row,
      meta: {
        ...row.meta,
        id: row.id,
        checked: row.checked,
        onChecked,
        // Last few data items should open their menus upwards
        openMenuAbove: Math.max(10, arr.length) - index <= 3,
      },
    }));
  }, [records, onChecked]);

  const headData = useMemo(
    () => ({
      meta: {
        id: 'header',
        checked: selection.checked,
        onSelectionChanged: onCheckedSelection,
        selectionOptions,
        sort,
        onSort: (column, direction) => onChangeSort({ column, direction }),
        onOpenSelectionMenu,
      },
    }),
    [
      selection,
      selectionOptions,
      onCheckedSelection,
      sort,
      onChangeSort,
      onOpenSelectionMenu,
    ]
  );

  const fieldListeners = useMemo(() => {
    const id = addingId === 'new' ? 'new' : addingId;
    const updatedRecords = [
      addingId ? getDefaultNewRecord(undefined, id) : null,
      ...records,
    ].filter(Boolean);
    return updatedRecords.flatMap(({ id }) =>
      columns
        .filter((column) => {
          const { bodyCell } = column;
          return bodyCell?.field?.id;
        })
        .map((column) => {
          const { bodyCell } = column;
          return {
            id: `${bodyCell?.field?.id}-${id}`,
          };
        })
    );
  }, [records, columns, addingId]);

  const { assignFieldHandle, getKeyListenersProps, resetFocus } =
    useKeyListenersInline(fieldListeners, model, () => true, !!addingId);

  const handleClearFilter = useClearFilter(clearFilter, toggleMenu);

  return (
    <StyledBigTableLayoutWithProvider
      filtersOpen={openMenu}
      heightNav={height}
      toolbar={
        <StyledPageToolbar>
          <PageToolbarSection>
            <FilterGroupSelector
              value={groupId || 'none'}
              options={groups}
              onChange={handleChangeGroup}
              title={t('Change Group')}
              link={`/custom-objects/${model?.id}/filter-groups`}
            />
            <FilterButtonWithLayoutConsumer
              badge={filterInfo.numberOfFilters}
              menuIsOpen={openMenu}
              scrollToTop={scrollToTop}
              toggleMenu={toggleMenu}
              errors={hasFilterError}
            />
            <PageSearchInput
              placeholder={`${t('Find')} ${model.entityName} ${t('Records')}`}
              value={search}
              onChange={onChangeSearch}
              {...getDataQAForInput('search-co-records', 'search')}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            data-qa="header-record-count"
            count={recordsCountAndNew}
            single={`${model.entityName} ${t('Record')}`}
            plural={`${model.entityName} ${t('Records')}`}
          />
          <PageToolbarSection>
            {hasBulkActions && (
              <ButtonWithDropdown
                color="blue"
                onChange={choiceAction}
                onClick={openPerformModal}
                options={performActionOptions}
              >
                {t('Perform Action')}
              </ButtonWithDropdown>
            )}
            {createAccess && (
              <StyledButtonWithDropdown
                data-qa="new-record"
                color="green"
                onChange={onChoseNewRecord}
                onClick={() => onChoseNewRecord({ value: 'newRecord' })}
                options={newRecordOptions(t, hasUploadButton)}
              >
                <NewRecordButtonText>{`${t('New')} ${
                  model.entityName
                }`}</NewRecordButtonText>
              </StyledButtonWithDropdown>
            )}
          </PageToolbarSection>
        </StyledPageToolbar>
      }
      pagination={
        isLoadingView ? null : (
          <TablePagination
            page={pageNumber}
            perPage={pageSize}
            totalCount={recordsCount}
            onChangePage={onChangePageNumber}
            onChangePerPage={onChangePageSize}
          />
        )
      }
      filters={
        <FilterDropdowntWithLayoutConsumer
          openMenu={openMenu}
          key={
            // the truthy value of isAllContactsGroup is a UUID
            filterInfo.isAllContactsGroup || filterInfo.key
          }
          filterData={filterInfo.config}
          and={filterInfo.and}
          applyFiltersAction={applyFiltersAction}
          saveGroupAction={saveGroupAction}
          deleteGroupAction={deleteGroupAction}
          filterName={filterInfo.name}
          setFilterName={setFilterName}
          updateFilterCount={updateFilterCount}
          numberOfFilters={filterInfo.numberOfFilters}
          groupId={groupId}
          filterGroup={filterGroup}
          clearFilter={handleClearFilter}
          betaPreview={preReleaseFeatures}
          objectType={model?.objectType} // this is going to be pipline or standard
          customObjectId={model?.id}
          errorsReturned={errorsReturned}
          noSticky
        />
      }
      quickFiltersPills={
        quickFilters && (
          <QuickFilters
            quickFilters={quickFilters}
            quickFilterConfig={quickFilterConfig}
            fullFilterObject={fullFilterObject}
            search={search}
            resultsCount={recordsCountAndNew}
            model={model}
            fields={allFields}
            onChange={applyQuickFilters}
            errors={filterInfo?.errors?.quickFiltersErrors}
            hasFilterErrors={filterInfo?.errors}
          />
        )
      }
      {...others}
    >
      <KeyBoardContext.Provider
        value={{ assignFieldHandle, getKeyListenersProps }}
      >
        <Loader loading={isLoadingView}>
          <BigTableWithLayoutConsumer
            columns={columns}
            head={
              <TRow head cell={<TCell />} columns={columns} data={headData} />
            }
            addRecordTab={
              createAccess ? (
                <AddRecordTab
                  adding={Boolean(addingId)}
                  onClick={() => {
                    resetFocus();
                    setAddingId('new');
                  }}
                />
              ) : null
            }
            staleData={isRecordsFetching || isFiltering}
            expandTableTab={<ExpandTableTab />}
            zIndex={layers.content(0, 0)} // needs to at zero - {todo} reafctor big table so the z-indexes work with out hacks
          >
            {!checkableRecords.length && !addingId && <tr />}
            {Boolean(addingId) && (
              <AddRecordTRow
                fields={fields}
                columnSettings={columnSettings}
                getColumns={getRecordsColumns}
                model={model}
                stages={stages}
                // It's best if onAddingId doesn't change function references over time
                onAddingId={setAddingId}
                onSubmit={onCreateNewRecord}
                handleUpdateTableRecords={handleUpdateTableRecords}
              />
            )}
            {checkableRecords.map(
              (record) =>
                addingId !== record.id && (
                  <HoverableRow
                    key={record.id}
                    columns={columns}
                    data={record}
                    model={model}
                    NewSizedCell={NewSizedCell}
                  />
                )
            )}
          </BigTableWithLayoutConsumer>
        </Loader>
      </KeyBoardContext.Provider>
      <ArchiveModal
        show={!!archiving}
        onHide={() => setArchiving(null)}
        onConfirm={() => {
          onSelectArchiveRecord({ id: archiving?.id });
          setArchiving(null);
        }}
      >
        <KizenTypography>
          {t(
            'This will archive the {{name}} record and all associated information, so that it can no longer be accessed.',
            { name: archiving?.name || 'entity' }
          )}
        </KizenTypography>
      </ArchiveModal>
    </StyledBigTableLayoutWithProvider>
  );
};

export default DesktopTable;
