import { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Switch from 'components/Kizen/Switch';
import { useTranslation } from 'react-i18next';
import { Text } from 'app/typography';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

const Wrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

const StyledSwitch = styled(Switch)`
  margin-left: 0;
`;

const CODES = { yes: 'yes', no: 'no', maybe: 'maybe' };

const getBaseOptions = (t) => [
  { code: CODES.yes, name: t('Yes') },
  { code: CODES.no, name: t('No') },
];

const getMaybeOption = (t) => ({ code: CODES.maybe, name: t('Maybe') });

export default function YesNoOptions({ updateField, formData = EMPTY_OBJECT }) {
  const { t } = useTranslation();
  const [stagedOptions, setStagedOptions] = useState(
    formData.options.length ? formData.options : getBaseOptions(t)
  );

  const initialMaybeOption = useRef(
    formData.options?.find((opt) => opt.code === CODES.maybe) ||
      getMaybeOption(t)
  );

  useEffect(() => {
    updateField('options', stagedOptions);
  }, [stagedOptions, updateField]);

  const handleToggle = (ev) => {
    const allowMaybe = ev.currentTarget.checked;
    if (allowMaybe) {
      setStagedOptions([...stagedOptions, initialMaybeOption.current]);
    } else {
      setStagedOptions(stagedOptions.filter((opt) => opt.code !== CODES.maybe));
    }
  };

  return (
    <Wrapper>
      <Text>{t('Enable "Maybe"')}</Text>
      <StyledSwitch
        value=""
        checked={
          !!stagedOptions.length &&
          stagedOptions.some((opt) => opt.code === CODES.maybe)
        }
        onChange={handleToggle}
        data-qa="enable-maybe"
      />
    </Wrapper>
  );
}
