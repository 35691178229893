import React from 'react';
import { grayScale } from 'app/colors';

export default function ColumnResizeIcon({ color, ...rest }) {
  return (
    <svg
      width="3"
      height="24"
      viewBox="0 0 3 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <line
        x1="1.5"
        y1="0"
        x2="1.5"
        y2="24"
        stroke={color || grayScale.medium}
      />
      <line
        x1="1.5"
        y1="8"
        x2="1.5"
        y2="16"
        stroke={color || grayScale.mediumDark}
        strokeWidth="3"
      />
    </svg>
  );
}
