import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import BasicModal from 'components/Modals/presets/BasicModal';
import MultiColumnBuilder from 'components/MultiColumnBuilder';
import {
  useEditColumnModalBuilder,
  CUSTOM_FIELD_ITEM_ID,
} from './hooks/useEditColumnModalBuilder';
import { StyledInputControl } from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/styles';
import { ColumnsHeaderPreview } from 'components/Wizards/shared/components/TableBuilder/components/ColumnsHeaderPreview';
import { fontWeights } from 'app/typography';
import BaseOption from 'components/MultiColumnBuilder/components/BaseOption';
import Icon from 'components/Kizen/Icon';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { colorsButton } from 'app/colors';
import { CustomFieldModal } from './CustomFieldModal';
import EditableText from 'components/Kizen/EditableText';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';
import { RIGHT_ID } from 'components/MultiColumnBuilder/useBasicBuilder';
import { v4 as uuidv4 } from 'uuid';

export const TextWrapper = styled.div`
  max-width: ${({ showDropdown }) => (showDropdown ? '290' : '400')}px;
`;

export const MultiColumnBuilderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 400px);
`;

export const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
  flex-grow: 1;
  margin-right: 20px;
  width: 0;

  ${({ isDynamic }) =>
    isDynamic
      ? css`
          color: ${colorsButton.green.default};
        `
      : ''}
`;

export const EditableField = styled(EditableText)`
  max-width: 100%;
  padding-right: 10px;
`;

const getFalse = () => false;

export default function EditColumnsModal({
  onHide,
  onConfirm,
  settings,
  show,
  defaultColumns,
  ...props
}) {
  const { t } = useTranslation();

  const {
    builderProps,
    handleSubmit,
    showModal,
    setShowModal,
    customFieldItem,
  } = useEditColumnModalBuilder({
    settings: settings,
    onConfirm,
    defaultColumns,
    t,
  });

  const leftColumnProps = useMemo(
    () => ({
      ...builderProps.leftColumnProps,
      options: [customFieldItem, ...builderProps.leftColumnProps.options]
        .filter(({ fieldId }) => {
          return !fieldId || fieldId === CUSTOM_FIELD_ITEM_ID;
        })
        .map((option) => ({ ...option, label: option.displayName })),
    }),
    [customFieldItem, builderProps.leftColumnProps]
  );

  const { setOptionsSearchTerm, rightColumnProps } = useMemo(
    () => builderProps,
    [builderProps]
  );

  const onHideModal = useCallback(
    ({ reset = false } = {}) => {
      if (reset) {
        rightColumnProps.removeItem(showModal.index);
      }
      setShowModal(null);
    },
    [rightColumnProps, setShowModal, showModal]
  );

  const handleCustomFieldModalConfirm = useCallback(
    async ({ index, label, fieldId, objectId, displayName }) => {
      const { editValue } = rightColumnProps;
      editValue(index, undefined, 'id', uuidv4());
      editValue(index, undefined, 'label', label);
      editValue(index, undefined, 'objectId', objectId);
      editValue(index, undefined, 'fieldId', fieldId);
      editValue(index, undefined, 'displayName', displayName);
    },
    [rightColumnProps]
  );

  const onSelectOption = useCallback(
    ({ value }, swapItemColumn, element, index) => {
      if (value === 'remove') {
        swapItemColumn(index, RIGHT_ID);
      } else if (value === 'edit') {
        setShowModal({
          index,
          ...element,
        });
      }
    },
    [setShowModal]
  );

  const customFieldMenuOptions = useMemo(
    () => [
      {
        value: 'remove',
        label: t('Remove Column'),
      },
      {
        value: 'edit',
        label: t('Edit Options'),
      },
    ],
    [t]
  );

  const additionalActions = useCallback(
    ({ swapItemColumn, index, element, editValue, childIndex, section }) => {
      const showEdit = section === 'right-column' && element?.fieldId;
      const showDelete =
        section === 'right-column' && !showEdit && !element?.disabled;
      return (
        <>
          {!element?.disabled &&
            swapItemColumn &&
            !element.restricted &&
            showDelete && (
              <div>
                <Icon
                  className="DeleteAction"
                  icon="delete"
                  onClick={() => {
                    editValue(index, childIndex, 'label', element.displayName);
                    swapItemColumn(index, section);
                  }}
                />
              </div>
            )}
          {showEdit && (
            <IconButtonMenu
              title={t('Edit Settings')}
              position="left"
              sizing="dense"
              color={colorsButton.iconGray}
              options={customFieldMenuOptions}
              onChange={(selected) => {
                onSelectOption(
                  { value: selected.value },
                  swapItemColumn,
                  element,
                  index
                );
              }}
              restrictCloseHandler
              overlay
            >
              <Icon icon="three-dot" />
            </IconButtonMenu>
          )}
        </>
      );
    },
    [customFieldMenuOptions, onSelectOption, t]
  );

  return (
    <>
      <BasicModal
        heading={t('Edit Columns')}
        buttonText={t('Save')}
        onConfirm={handleSubmit}
        onHide={onHide}
        show={show}
        size="large"
        disabled={rightColumnProps?.options?.some(
          (o) => o?.label?.trim() === ''
        )}
        data-qa="edit-columns-modal"
        {...props}
      >
        <StyledInputControl label={t('Column Preview')}>
          <ColumnsHeaderPreview columns={rightColumnProps.options} />
        </StyledInputControl>
        <MultiColumnBuilderWrapper>
          <MultiColumnBuilder
            {...builderProps}
            searchable
            // fullHieght
            testId="edit-columns-builder"
            weight={fontWeights.regular}
            leftColumnProps={leftColumnProps}
            leftHeaderText={t('Available Columns (Drag to Add)')}
            rightHeaderText={t('Active Table Columns')}
            searchPlaceholder={t('Find Options')}
            onChangeSearchTerm={setOptionsSearchTerm}
            disabledDropZoneText={false}
            rollBackDrop={false}
            renderRightEmpty={{
              onDropLabel: t('Place Here'),
              noItems: t(
                'No Columns Currently Selected (Drag and Drop to Add)'
              ),
            }}
            renderLeftEmpty={{
              onDropLabel: t('Drop to Remove'),
              noItems: t('No Options Found'),
            }}
            renderOption={({ element, section, ...providedProps }) => {
              return (
                <BaseOption
                  data-qa={element?.label}
                  iconVisible={getFalse}
                  iconEditable={getFalse}
                  colorEditable={getFalse}
                  element={{
                    ...element,
                    isLocked: element.disabled,
                  }}
                  section={section}
                  additionalActions={additionalActions}
                  setShowModal={setShowModal}
                  {...providedProps}
                >
                  {({ element, editValue, index, childIndex }) => {
                    const isDynamic = element?.fieldId === CUSTOM_FIELD_ITEM_ID;
                    const canEdit = section === 'right-column';
                    return canEdit ? (
                      <TextWrapper>
                        <EditableField
                          value={element.label}
                          onChange={(v) => {
                            editValue(index, childIndex, 'label', v);
                          }}
                          placeholder={t('Enter Display Name')}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              editValue(
                                index,
                                childIndex,
                                'label',
                                element.displayName
                              );
                            }
                          }}
                        />
                      </TextWrapper>
                    ) : (
                      <StyledTextEllipsisWithTooltip isDynamic={isDynamic}>
                        {canEdit ? element.label : element.displayName}
                      </StyledTextEllipsisWithTooltip>
                    );
                  }}
                </BaseOption>
              );
            }}
          />
        </MultiColumnBuilderWrapper>
      </BasicModal>
      {showModal ? (
        <CustomFieldModal
          {...showModal}
          onConfirm={handleCustomFieldModalConfirm}
          onHide={onHideModal}
          editValue={rightColumnProps.editValue}
        />
      ) : null}
    </>
  );
}
