import Loader from 'components/Kizen/Loader';
import { CustomObjectSection } from 'pages/Settings/pages/Team/PermissionGroupModal_V2/CustomObjectSection';
import { usePermissionSetter } from '@kizen/permissions/hooks/usePermissionSetter';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const CustomObjectSectionCard = ({ formData, isLoading }) => {
  const { t } = useTranslation();
  const setPermission = usePermissionSetter(formData.id);

  useEffect(() => {
    setPermission(true);
  }, [setPermission]);

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <CustomObjectSection
      key={formData.id}
      section={{
        key: formData.id,
        label: `${t('Custom Object')} - ${formData.objectName}`,
        metadata: {
          object_type: formData.objectType,
          object_name: formData.objectName,
        },
        showArrowBtn: false,
        initialExpanderState: 'open',
        showSwitchToggle: false,
        marginTop: 0,
        marginBottom: 0,
        enableRelatedFieldRule: false,
      }}
    />
  );
};
export default CustomObjectSectionCard;
