import { useMergeFieldsBase } from 'hooks/useMergeFieldsBase';
import { isFilesField } from 'checks/fields';

// used by:
// NotifyMemberViaEmail
//

export const useNotifyMemberViaTextMergeFields = ({
  isForCustomObject,
  customObjectClassName,
  customObject,
  mergeFieldName,
  teamMemberCategoryName,
}) =>
  useMergeFieldsBase({
    fieldsFor: {
      for: isForCustomObject ? customObject : customObjectClassName,
    },
    mergeFieldName: mergeFieldName || customObjectClassName,
    fieldsToDisplayPredicate: (field) => !isFilesField(field),
    teamMemberCategoryName,
  });
