import { useEffect, useMemo, useRef, useState } from 'react';
import { enableExpandingScrollbarLogging } from '../debug';

class Listener {
  container: HTMLElement;
  enableLogging: boolean;

  constructor(element: HTMLElement, enableLogging = false) {
    this.container = element;
    this.enableLogging = enableLogging;
    element.addEventListener('mousemove', this, {
      passive: true,
    });
    element.addEventListener('mouseleave', this, {
      passive: true,
    });
  }

  private debugLog = (message: string, element?: HTMLElement) => {
    if (this.enableLogging) {
      console.log(`[Expanding Scrollbar] - ${message}`, element);
    }
  };

  handleEvent = (e: MouseEvent) => {
    switch (e.type) {
      case 'mousemove': {
        const rect = this.container.getBoundingClientRect();
        const left = e.clientX - rect.left;
        const top = e.clientY - rect.top;
        const height = this.container.offsetHeight;
        const width = this.container.offsetWidth;

        const { scrollWidth, clientWidth, scrollHeight, clientHeight } =
          this.container;

        const hasHorizontalScrollbar = scrollWidth > clientWidth;
        const hasVerticalScrollbar = scrollHeight > clientHeight;

        if (hasHorizontalScrollbar) {
          this.container.classList.add('kds-expanding-scrollbar--horizontal');
        } else {
          this.container.classList.remove(
            'kds-expanding-scrollbar--horizontal'
          );
        }

        // If we are within the scroll gutter on the right, mark the
        // container as hovered on the vertical scrollbar
        if (width - left <= 8 && hasVerticalScrollbar) {
          this.debugLog('hovering right', this.container);

          this.container.classList.add('kds-expanding-scrollbar--hover-right');
        } else {
          this.debugLog('unhovering right', this.container);

          this.container.classList.remove(
            'kds-expanding-scrollbar--hover-right'
          );
        }

        if (hasVerticalScrollbar) {
          this.debugLog('hovering bottom', this.container);

          this.container.classList.add('kds-expanding-scrollbar--vertical');
        } else {
          this.debugLog('unhovering bottom', this.container);

          this.container.classList.remove('kds-expanding-scrollbar--vertical');
        }

        // If we are within the scroll gutter on the bottom, mark the
        // container as hovered on the horizontal scrollbar
        if (height - top <= 8 && hasHorizontalScrollbar) {
          this.container.classList.add('kds-expanding-scrollbar--hover-bottom');
        } else {
          this.container.classList.remove(
            'kds-expanding-scrollbar--hover-bottom'
          );
        }

        break;
      }
      case 'mouseleave': {
        this.container.classList.remove(
          'kds-expanding-scrollbar--hover-right',
          'kds-expanding-scrollbar--hover-bottom'
        );

        break;
      }
    }
  };

  removeListener = () => {
    this.container.removeEventListener('mousemove', this);
    this.container.removeEventListener('mouseleave', this);
    this.container.removeAttribute('data-kds-scrollbar-tagged');
  };
}

export const useScrollbarInteraction = () => {
  const [renderId, setRenderId] = useState(() => Date.now());
  const allListeners = useRef<Listener[]>([]);

  const enableLogging = useMemo(() => {
    return enableExpandingScrollbarLogging();
  }, []);

  // This effect runs on mount to catch the initial scroll containers,
  // and is called on an interval to catch any new scroll containers that appear in the DOM.
  // It only attaches listeners to scroll containers that haven't been "tagged" yet.
  useEffect(() => {
    const allContainers = document.querySelectorAll<HTMLElement>(
      '.kds-expanding-scrollbar'
    );

    if (enableLogging) {
      console.log(
        '[Expanding Scrollbar] - found',
        allContainers.length,
        'containers'
      );
    }

    allContainers.forEach((container) => {
      if (
        !container.hasAttribute('data-kds-scrollbar-tagged') &&
        !container.classList.contains('kds-hide-scrollbars')
      ) {
        if (enableLogging) {
          console.log('[Expanding Scrollbar] - tagging container', container);
        }
        container.setAttribute('data-kds-scrollbar-tagged', 'true');
        const listener = new Listener(container, enableLogging);
        allListeners.current.push(listener);
      }
    });
  }, [renderId, enableLogging]);

  // When we unmount, disconnect all listeners that have been created
  //  over time.
  useEffect(() => {
    const listeners = allListeners.current;

    return () => {
      listeners.forEach((listener) => {
        listener.removeListener();
      });
    };
  }, []);

  // Trigger our scroll container detection on an interval.
  useEffect(() => {
    const interval = setInterval(() => {
      setRenderId(Date.now());
    }, 1000); // We can probably wait (in the worst case) 1 second here because even without this, the scrollbars will still mostly work.

    return () => clearInterval(interval);
  }, []);
};
