import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import Select from 'components/Inputs/Select';
import Switch from 'components/Kizen/Switch';

const Wrapper = styled.div`
  width: 100%;

  .select {
    margin-bottom: 20px;
    margin-top: ${gutters.spacing(4, -4)}px;
  }
`;

const ChooseColumnsToExport = ({
  selectedOption,
  setSelectedOption,
  includeKizenId,
  setIncludeKizenId,
}) => {
  const { t } = useTranslation();
  const options = [
    { value: 'all', label: t('All Columns') },
    { value: 'visible', label: t('Visible Columns on Table') },
  ];

  return (
    <Wrapper>
      <Select
        className="select"
        fullWidth
        menuInline
        label={t('Choose Columns to Export')}
        options={options || []}
        value={selectedOption || options[0]}
        onChange={setSelectedOption}
        placeholder={t('Visible Columns on Table')}
      />
      {selectedOption?.value === 'visible' ? (
        <Switch
          removeMargin
          label={t('Include Kizen ID Column')}
          checked={includeKizenId}
          onChange={(e) => setIncludeKizenId(e.target.checked)}
          textPlacement="left"
        />
      ) : null}
    </Wrapper>
  );
};
export default ChooseColumnsToExport;
