import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DROPDOWN_SHOULD_LOAD } from '../../../utility/constants';
import { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';
import BaseReactSelect from './BaseReactSelect';
import * as customizeComponents from './customize';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import { applyRef, groupableOptionProp, selectedValueProp } from '../props';
import Validation, { useValidation } from '../Validation';
import { SingleValueEllipse } from './customize';

const ellipseComponents = {
  SingleValue: SingleValueEllipse,
};

const SelectOutline = React.forwardRef(function SelectOutline(props, ref) {
  const {
    value: valueProp,
    onChange,
    options: optionsProp,
    placeholder,
    isColored,
    isReadOnly,
    isSearchable: isSearchableProp,
    menuPlacement,
    disabled,
    error,
    endAdornment,
    menuOnly,
    menuIsOpen: menuIsOpenProp,
    menuInline: menuInlineProp,
    menuTopButton,
    menuLeftButton,
    menuRightButton,
    components: componentsProp,
    filterOption = createFilter,
    className,
    inModal,
    errorPlacement,
    loadItems,
    ...others
  } = props;

  const { t } = useTranslation();

  const selectRef = useRef();
  const validationRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(selectRef, el);
      applyRef(validationRef, el);
      applyRef(ref, el);
    },
    [ref]
  );
  const [validation, validationProps] = useValidation(validationRef, props);
  const [computedMenuPlacement, setComputedMenuPlacement] = useState(
    menuPlacement !== 'auto' ? menuPlacement : null
  );

  const options = useMemo(
    () =>
      (
        (optionsProp !== DROPDOWN_SHOULD_LOAD &&
          Array.isArray(optionsProp) &&
          optionsProp) ||
        []
      ).map((opt) => {
        if (opt.options) {
          // In order to make group headings persistent, we need a placeholder
          // option that is never filtered away. In ./customize we simply hide these items.
          return {
            ...opt,
            options: [...opt.options, { groupPlaceholder: true }],
          };
        }
        return opt;
      }),
    [optionsProp]
  );

  const value =
    typeof valueProp === 'string'
      ? options.find((opt) => opt.value === valueProp) || null
      : valueProp;
  let menuIsOpen;
  if (typeof menuIsOpenProp === 'boolean') {
    menuIsOpen = menuIsOpenProp;
  } else if (menuOnly) {
    menuIsOpen = true;
  } else if (isReadOnly) {
    menuIsOpen = false;
  }
  const isSearchable =
    typeof isSearchableProp === 'boolean' ? isSearchableProp : !isReadOnly;
  const menuInline =
    typeof menuInlineProp === 'boolean' ? menuInlineProp : menuOnly;

  return (
    <MaybeInputControl variant="outline" {...props}>
      <BaseReactSelect
        ref={mergeRef}
        selectManager={selectRef.current}
        className={!hasInputControl(props) && className}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
        isSearchable={isSearchable}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        noOptionsMessage={() =>
          optionsProp === DROPDOWN_SHOULD_LOAD ||
          !optionsProp ||
          (loadItems && options.length === 0)
            ? t('Loading Options')
            : t('No Options')
        }
        filterOption={(opt, inputValue) => {
          return (
            opt.data.groupPlaceholder ||
            !filterOption ||
            filterOption(opt, inputValue)
          );
        }}
        components={{
          ...customizeComponents,
          ...ellipseComponents,
          ...componentsProp,
        }}
        // Below are custom
        variant="outline"
        isColored={isColored}
        isReadOnly={isReadOnly}
        error={error || validation.error}
        endAdornment={endAdornment}
        menuOnly={menuOnly}
        menuInline={menuInline}
        menuTopButton={menuTopButton}
        menuLeftButton={menuLeftButton}
        menuRightButton={menuRightButton}
        computedMenuPlacement={computedMenuPlacement}
        setComputedMenuPlacement={setComputedMenuPlacement}
        {...others}
      />
      <Validation
        inModal={inModal}
        errorPlacement={errorPlacement}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

SelectOutline.propTypes = {
  value: selectedValueProp,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(groupableOptionProp),
  placeholder: PropTypes.string,
  isColored: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isSearchable: PropTypes.bool,
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  errorPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  endAdornment: PropTypes.element,
  menuOnly: PropTypes.bool,
  menuInline: PropTypes.bool,
  menuTopButton: PropTypes.node,
  menuLeftButton: PropTypes.node,
  menuRightButton: PropTypes.node,
  components: PropTypes.object,
  loadItems: PropTypes.bool,
  filterOption: PropTypes.func,
};

SelectOutline.defaultProps = {
  value: null,
  onChange: null,
  options: undefined,
  placeholder: null,
  isColored: null,
  isReadOnly: null,
  isSearchable: null,
  menuPlacement: undefined, // react-select doesn't love null
  errorPlacement: undefined,
  disabled: false,
  error: null,
  endAdornment: null,
  menuOnly: null,
  menuInline: null,
  menuTopButton: null,
  menuLeftButton: null,
  menuRightButton: null,
  components: null,
  loadItems: null,
  filterOption: createFilter(),
};

export default Object.assign(SelectOutline, {
  // eslint-disable-next-line react/forbid-foreign-prop-types
  propTypes: SelectOutline.propTypes,
  defaultProps: SelectOutline.defaultProps,
});
