import { gutters, layers } from 'app/spacing';
import styled from '@emotion/styled';
import Loader from 'components/Kizen/Loader';
import { colorsPrimary, grayScale } from 'app/colors';

export const ChartLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Chart = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  padding-top: ${gutters.spacing(1)}px;
  margin-left: -${gutters.spacing(3)}px;
  margin-right: -${gutters.spacing(12)}px;
  margin-bottom: -${gutters.spacing(2)}px;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 0;
  left: -${gutters.spacing(3)}px;
  right: -${gutters.spacing(3)}px;
  bottom: -${gutters.spacing(1)}px;
  padding-top: 0 !important;
  background-color: ${grayScale.white};
  z-index: ${layers.content(10)};

  & > div {
    margin-top: 10%;
    margin-right: ${gutters.spacing(2)}px;
  }
`;

export const Legend = styled.div`
  display: flex;
  padding-bottom: 3px;
  padding-top: 9px;
  color: ${grayScale.dark};
`;

export const LegendContent = styled.div`
  margin: 0 auto;
  display: flex;
`;

export const LegendEntry = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export const CircularMarker = styled.div`
  border-radius: 8px;
  width: 8px;
  height: 8px;
  background: ${colorsPrimary.green.light};
  margin-right: ${gutters.spacing(1)}px;
`;

export const DashedMarker = styled.div`
  width: 8px;
  margin-right: ${gutters.spacing(1)}px;
  display: flex;
  column-gap: 1px;
`;

export const DashedMarkerChild = styled.div`
  width: 2px;
  height: 1px;
  background: ${grayScale.dark};
  padding-top: 1px;
  border-radius: 3px;
`;

export const GradientMarker = styled.div`
  width: 8px;
  height: 8px;
  display: flex;
  column-gap: 1px;
  margin-right: ${gutters.spacing(1)}px;

  & > div:first-child {
    background-color: ${colorsPrimary.blue.light};
  }

  & > div:nth-child(2) {
    background-color: ${`${colorsPrimary.blue.light}${(70).toString(16)}`};
  }

  & > div:last-child {
    background-color: ${`${colorsPrimary.blue.light}${(40).toString(16)}`};
  }
`;

export const GradientChild = styled.div`
  width: 2px;
  height: 100%;
`;

export const ChartOverlay = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 100ms,
    opacity 100ms linear;
  width: 0;
  right: 14px;
  top: 0;
  bottom: 50px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0) 50px
  );
  border-bottom: 1px solid ${grayScale.mediumLight};
  pointer-events: none;
`;
