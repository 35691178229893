import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { SearchPill } from '@kizen/kds/SearchPill';
import {
  allowedFileExtensions,
  imageFileExtensions,
} from 'components/FilePicker/defaults';
import { useProgress } from 'hooks/uploadFiles/useProgress';
import { useUploadFile } from 'hooks/uploadFiles/useUploadFile';
import { useFiles } from 'hooks/uploadFiles/useFiles';
import FilePicker from 'components/FilePicker';
import PreviewBlock from './PreviewBlock';
import {
  FileLibraryModalHeaderWrapper,
  FileLibraryDetailsWrapper,
  Spacer,
  ClipLoaderWrapper,
  StyledModal,
  FileLibraryModalHeader,
  LeftColumn,
  RightColumn,
  SearchWrapper,
  SearchPillWrapper,
} from './styles';
import { invalidate } from 'queries/invalidate';
import ConfirmImageDeletionModal from 'components/Modals/presets/ConfirmImageDeletion';
import useModal from 'components/Modals/useModal';
import imageService from 'services/ImageService';
import { FilesList } from './FilesList';
import { useModalConfiguration } from './useModalConfiguration';
import { Button } from '@kizen/kds/Button';
import { Menu } from 'components/Kizen/IconButtonMenu';

export const SingleFilePicker = forwardRef(
  (
    {
      files: filesProp = [],
      onConfirm,
      onHide,
      onDelete,
      show,
      selectedFileId,
      disabled = false,
      viewable = true,
      publicFile = false,
      loading = false,
      fetching = false,
      onlyImages = false,
      className,
      style,
      source,
      search,
      setSearch,
      setSort,
      sort,
      sortOptions,
      enableFileBlockHoverOptions = false,
    },
    forwardRef
  ) => {
    const buttonRef = useRef(null);
    const { t } = useTranslation();
    const [progress, { clearProgress, updateProgress }] = useProgress();
    const uploadFile = useUploadFile(publicFile, updateProgress, source);
    const filesPropMap = useMemo(
      () => filesProp.map(({ item }) => item),
      [filesProp]
    );
    const [allFiles, { addFiles, updateFile, deleteFile, deselectFile }] =
      useFiles({
        uploadFile,
        initial: filesPropMap,
        isInfinityScrollable: true,
        deps: [search, sort],
      });

    const [fileToDelete, setFileToDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);

    const {
      files,
      filePreview,
      selectedFile,
      setSelectedFile,
      showPreviewBlock,
      windowHeight,
      isMobile,
      columnRef,
    } = useModalConfiguration(allFiles, selectedFileId);

    const handleSearch = useCallback(
      (value) => {
        setSearch(value);
        invalidate.IMAGES.SORTED(sort?.id || sort?.value, search);
      },
      [setSearch, sort, search]
    );

    const handleAddFiles = useCallback(
      async (updatedFiles) => {
        const addedFiles = await addFiles([...updatedFiles, ...allFiles]);
        setSelectedFile(addedFiles[0]);
        clearProgress();
        invalidate.IMAGES.ALL();
      },
      [allFiles, addFiles, setSelectedFile, clearProgress]
    );

    const handleConfirm = useCallback(async () => {
      await onConfirm(selectedFile);
    }, [selectedFile, onConfirm]);

    const handleUpdateFile = useCallback(
      async (newFile, params) => {
        const updatedFile = await updateFile(newFile, params);
        if (updatedFile) {
          setSelectedFile(updatedFile);
        }
      },
      [setSelectedFile, updateFile]
    );
    const status = useMemo(
      () => progress && Object.values(progress)[0].progress,
      [progress]
    );
    const [deleteModalProps, , deleteModal] = useModal({
      handleHide: () => {
        setFileToDelete(null);
      },
      handleSubmit: async () => {
        try {
          setDeleting(true);
          await imageService.delete(fileToDelete);
          deselectFile(fileToDelete);
          onDelete && onDelete({ ...fileToDelete, url: '' });
          setSelectedFile(null);
          deleteFile(fileToDelete);
          invalidate.IMAGES.ALL();
        } catch (err) {
        } finally {
          setDeleting(false);
        }
        setFileToDelete(null);
        deleteModal.hide();
      },
    });

    const handleDeleteFile = useCallback(
      (file) => {
        setFileToDelete(file);
        deleteModal.show();
      },
      [deleteModal]
    );

    return (
      <StyledModal
        heading={t('Image Library')}
        buttonText={disabled ? t('Close') : t('Save')}
        size="large"
        actionBtnColor={disabled ? 'blue' : undefined}
        defaultLeftBtnText={t('Cancel')}
        disabled={!filePreview?.id || deleting || progress}
        onConfirm={handleConfirm}
        onHide={onHide}
        show={show}
        className={className}
        style={style}
      >
        <FileLibraryModalHeaderWrapper>
          <FileLibraryModalHeader type="header">
            {filePreview?.id ? t('Image Selected') : t('No Image Selected')}
          </FileLibraryModalHeader>
          <SearchWrapper>
            <Button
              ref={buttonRef}
              actions={sortOptions}
              color="tertiary"
              onSelectAction={(option, hide) => {
                setSort(option);
                hide();
              }}
              variant="text"
              dropdown={({ isShowing, onChange, onHide, options, menuRef }) => {
                return (
                  <Menu
                    ref={(r) => (menuRef.current = r?.select?.menuListRef)}
                    fullWidth
                    classNamePrefix="IconButtonMenu"
                    menuIsOpen={isShowing}
                    onChange={onChange}
                    options={options}
                    onHide={onHide}
                  />
                );
              }}
            >
              {sort?.label}
            </Button>
          </SearchWrapper>
          <SearchPillWrapper>
            <SearchPill
              onChange={handleSearch}
              value={search}
              placeholder={t('Find Files')}
              boundaryClassName="timeline-menu"
              autoFocus
              loading={fetching || loading}
            />
          </SearchPillWrapper>
        </FileLibraryModalHeaderWrapper>
        <FileLibraryDetailsWrapper>
          <LeftColumn
            ref={columnRef}
            isVisible={!disabled || showPreviewBlock}
            windowHeight={windowHeight}
          >
            {disabled ? null : (
              <FilePicker
                multiFileMode={true}
                onFileAdded={handleAddFiles}
                allowedFileExtensions={
                  onlyImages ? imageFileExtensions : allowedFileExtensions
                }
                uploadStatus={status}
                disabled={disabled || deleting || progress}
                isMobile={isMobile}
              />
            )}
            <PreviewBlock
              file={filePreview}
              disabled={disabled}
              viewable={viewable}
              updateFile={handleUpdateFile}
              isMobile={isMobile}
            />
          </LeftColumn>
          {!disabled || showPreviewBlock ? <Spacer /> : null}
          <RightColumn windowHeight={windowHeight}>
            {loading ? (
              <ClipLoaderWrapper>
                <ClipLoader size={56} loading />
              </ClipLoaderWrapper>
            ) : (
              <FilesList
                ref={forwardRef}
                files={files}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                deleteFile={handleDeleteFile}
                disabled={disabled}
                viewable={viewable}
                highlightSelectedOnly
                showPreviewOption={false}
                enableFileBlockHoverOptions={enableFileBlockHoverOptions}
              />
            )}
          </RightColumn>
        </FileLibraryDetailsWrapper>
        <ConfirmImageDeletionModal
          {...deleteModalProps}
          fileToDelete={fileToDelete}
        />
      </StyledModal>
    );
  }
);
