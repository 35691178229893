import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { PAGE_CONFIGS } from 'queries/query-keys';
import { maybeInjectPermanentItems } from 'pages/Account/pages/Toolbar/hooks/useRightColumn';
import useToolbarAccessFiltering from 'pages/Account/pages/Toolbar/hooks/useToolbarAccessFiltering';
import TeamMemberService from 'services/TeamMemberService';
import { Redirect } from 'react-router-dom';
import { getFirstInternalNavItem } from 'app/index';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  DashboardErrorCodeText,
  DashboardWarningText,
} from 'pages/Dashboard/styles';

export default () => {
  const { t } = useTranslation();
  const user = useSelector((s) => s.authentication.user);
  const business = useSelector((s) => s.authentication.chosenBusiness);
  const access = useSelector((s) => s.authentication.access);
  const anyAccess = Object.values(access.sections).some(
    (section) => section.view
  );

  const toolbarEnabled = Boolean(user?.profile?.id) && Boolean(business?.id);

  const filterToolbar = useToolbarAccessFiltering();

  const { data: toolbarData, isLoading: toolbarLoading } = useQuery(
    [PAGE_CONFIGS.MY_TOOLBAR, user?.profile?.id, business?.id],
    async () => {
      const res = await TeamMemberService.getPageConfig('toolbar');
      return {
        ...res,
        elements: filterToolbar(
          maybeInjectPermanentItems(res?.elements ?? [], res?.modified)
        ),
      };
    },
    {
      enabled: toolbarEnabled,
    }
  );

  const firstNavItem = getFirstInternalNavItem(toolbarData?.elements);
  // Check if the firstNavItem is not equal to '/choose-business' or '/logout'
  // to avoid circle redirect if user doesn't have any Role
  // n.b. logout is allowed in navigation even if user doesn't have access \o/
  const skipRedirects = ['/logout', '/choose-business'];

  const redirectPath =
    !skipRedirects.includes(firstNavItem) && firstNavItem !== null
      ? firstNavItem
      : '/dashboard';

  const canRedirect =
    anyAccess &&
    (redirectPath !== '/dashboard' ||
      (redirectPath === '/dashboard' &&
        access?.sections.dashboards_section?.view === true));

  return toolbarLoading ? null : canRedirect ? (
    <Redirect to={redirectPath} />
  ) : (
    <ErrorMessage
      title={t('Permissions Needed')}
      text={
        <DashboardWarningText as="p">
          {t(
            'The page you are trying to view requires special permissions or is no longer active. If you believe you should have permissions to view, please contact your Business Administrator.'
          )}
        </DashboardWarningText>
      }
      code={
        <DashboardErrorCodeText as="p">
          {t('Error code')}: 403
        </DashboardErrorCodeText>
      }
      dashboard
    />
  );
};
