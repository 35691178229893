import {
  checkListDeleted,
  nameDescriptor,
} from 'pages/AutomationEngine/helpers';
import { namedToOptionError } from 'services/helpers';
const forApi = (step) => {
  const { automations, resumePausedAutomations, actionType } = {
    ...step.details,
  };
  return {
    details: {
      actionType,
      automationIds: automations.map((item) => item.value),
      ...(actionType === 'start' ? { resumePausedAutomations } : {}),
    },
  };
};

const forFlow = (data, addErrorMessage, messageDictionary) => {
  const { automationIds, automations } = { ...data };
  checkListDeleted(
    messageDictionary,
    addErrorMessage,
    automations,
    nameDescriptor,
    messageDictionary.automation,
    'automations',
    namedToOptionError,
    true
  );
  data.automations = automationIds.reduce((acc, item) => {
    const { id, name, deleted } = automations.find(({ id }) => id === item);
    return deleted
      ? acc
      : [
          ...acc,
          {
            value: id,
            label: name,
          },
        ];
  }, []);
  delete data.automationIds;

  return data;
};

export const modifyAutomationDTO = {
  forApi,
  forFlow,
};
