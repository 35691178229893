import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import paths from 'routes/paths';
import ConfirmationModal from './ConfirmationModal';

const applyNavigation = (history, { location, action }) => {
  if (action === 'REPLACE') {
    history.replace(location);
  } else {
    // Unfortunately we don't have enough info to perfectly
    // preserve POPs, so PUSH and POP both will be pushed.
    history.push(location);
  }
};

export const allowNavigation = (location, action) => {
  // We don't want to block navigation during logout
  return action === 'REPLACE' && location.pathname === paths.get('login');
};

export default function ConfirmNavigationModal({
  when,
  whenCondition,
  onPreNavigation,
  onHide,
  onConfirm,
  children,
  show = false,
  ...others
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [navigation, setNavigation] = useState(null);
  const [processingNavigation, setProcessingNavigation] = useState(null);
  const onPreNavFn = useRef(onPreNavigation);

  useEffect(() => {
    onPreNavFn.current = onPreNavigation;
  });

  useEffect(() => {
    // We process applying navigation statefully in an effect in order to synchronize Prompt's "when" prop.
    // Otherwise if we handle it in onConfirm we either a. get caught again by Prompt or
    // b. can navigate without confirmation when Prompt is showing.
    if (processingNavigation) {
      setProcessingNavigation(null);
      if (onPreNavFn.current) {
        onPreNavFn.current();
      }
      applyNavigation(history, processingNavigation);
    }
  }, [processingNavigation, history]);
  return (
    <>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        show={show || !!navigation}
        onConfirm={() => {
          setNavigation(null);
          setProcessingNavigation(navigation);
          onConfirm?.();
        }}
        onHide={() => {
          setNavigation(null);
          onHide?.();
        }}
        {...others}
      >
        {children ||
          t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
      <Prompt
        when={when && !processingNavigation}
        message={(location, action) => {
          if (allowNavigation(location, action)) {
            return true;
          }
          if (
            when &&
            typeof whenCondition === 'function' &&
            !whenCondition({ location, action })
          ) {
            return true;
          }
          setNavigation({ location, action });
          return false;
        }}
      />
    </>
  );
}

ConfirmNavigationModal.propTypes = {
  when: PropTypes.bool,
  whenCondition: PropTypes.func,
  onPreNavigation: PropTypes.func,
};

ConfirmNavigationModal.defaultProps = {
  when: null,
  onPreNavigation: null,
};
