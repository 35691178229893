import { useCallback } from 'react';
import { useToast } from 'components/ToastProvider';
import { omitBy, isNil } from 'lodash';
import { snakeToCamelCase } from 'services/helpers';
import { getToastConfig, setDefaultProps } from './utilities';
import { getOriginalError } from 'services/AxiosService';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { CUSTOM_RECORDS } from 'queries/query-keys';
import { deferExecution } from 'utility/defer';

export function useWizardSave({
  onHide,
  onConfirm,
  refetchCustomObject,
  model,
  routesProp,
  builderProps,
  resetDirty,
  setSaving,
  activeStep,
  validateFormState,
  preSave,
  isLastStep,
  setValidationState,
  firstFieldWithErrorRef,
  handleNext,
  fieldsRef,
  resetValidationState,
}) {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const queryClient = useQueryClient();

  const handleWizardSave = useCallback(
    async (skipNavigation = false) => {
      setSaving();

      if (!validateFormState(t)) {
        setSaving();
        return;
      }

      const { formData } = builderProps;
      delete formData.associationSourceObjects;
      const associationSourceFields = (
        formData.associationSourceFields || []
      ).map((id) => ({ id }));

      try {
        // This pre-save hook is able to run and asynchronously determine whether or
        // not the save should actually be allowed to continue. If it returns null,
        // the save will be cancelled, and no other state changes will take place.
        // If it returns false, the save will be cancelled, but the form will still
        // have its dirty state reset and the object will be refetched. If it returns
        // true, the save will continue as normal, including the dirty state reset and refetch.
        let continueSaving = true;
        if (preSave.current && typeof preSave.current === 'function') {
          continueSaving = await preSave.current();
        }

        preSave.current = null;
        if (continueSaving === null) {
          return false;
        }

        if (continueSaving) {
          const data = {
            ...formData,
            ...setDefaultProps(formData),
            associationSourceFields,
          };
          await onConfirm?.(
            omitBy(
              {
                ...data,
                relatedObjects: data.relatedObjects.filter(
                  ({ isPopulatedContact }) => !isPopulatedContact
                ),
              },
              isNil
            ),
            model,
            activeStep
          );
        }

        resetDirty();

        if (!skipNavigation) {
          isLastStep(routesProp, activeStep) ? onHide() : handleNext();
        }

        // Always refetch at the end of an update cycle so that discarding changes doesn't throw the
        // user back to an old version of the object. TODO: this would make more sense as an optimistic
        // update, but outside of react-query that's not a common pattern for the application at the time
        // of writing.
        await refetchCustomObject(model, activeStep);
      } catch (error) {
        const ori = getOriginalError(error);

        const errState = {};

        let firstField;

        Object.keys(ori.errors).forEach((item) => {
          const id = snakeToCamelCase(item);

          if (!fieldsRef.current.has(id)) {
            return;
          }

          if (!firstField) {
            firstField = id;
          }

          errState[id] = Array.isArray(ori[item]) ? ori[item][0] : ori[item];
        });

        setValidationState(errState);

        firstFieldWithErrorRef.current = firstField;
        if (!Object.keys(errState).length) {
          showToast(getToastConfig(t, !model).error);
        }

        return false;
      } finally {
        setSaving();

        deferExecution(() => {
          resetValidationState();
        });

        if (model?.id) {
          // Remove the query instead of invalidate, because we don't want the stale-while-revalidate
          // behavior when navigating back to the object detail
          queryClient.removeQueries(CUSTOM_RECORDS.DETAIL(model.id));
        }
      }

      return true;
    },
    [
      setSaving,
      validateFormState,
      t,
      builderProps,
      onConfirm,
      model,
      activeStep,
      refetchCustomObject,
      resetDirty,
      isLastStep,
      routesProp,
      onHide,
      handleNext,
      setValidationState,
      firstFieldWithErrorRef,
      fieldsRef,
      showToast,
      resetValidationState,
      preSave,
      queryClient,
    ]
  );

  return handleWizardSave;
}
