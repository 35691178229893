import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
} from 'pages/Common/styles/ControlBar';
import { SettingsButton } from 'pages/Common/components/CustomObjectSettingsButton';
import { StyledToolbarWrapper } from './styles';
import {
  getContactsAccess,
  getCustomObjectSettinsAccess,
} from 'store/authentication/selectors';
import { getContactSettingOptions } from './helpers';
import { useMemo } from 'react';
import ViewVariant from 'components/ViewVariant';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import GroupSelector from 'components/DashboardGrid/GroupSelector';

const ControlBar = ({
  onChoseSettings,
  disabled,
  viewVariant,
  isChartView,
  chartGroupConfigProps,
  ...props
}) => {
  const { t } = useTranslation();
  const { objectSettings } = useSelector(getContactsAccess);

  const settingsOptions = useMemo(
    () => getContactSettingOptions(t, objectSettings),
    [t, objectSettings]
  );
  const { contacts } = useSelector(getCustomObjectSettinsAccess);

  return (
    <StyledToolbarWrapper className="toolbar-wrapper" {...props}>
      <Toolbar className="toolbar">
        <ToolbarLeft></ToolbarLeft>
        <ToolbarRight>
          {isChartView ? <GroupSelector {...chartGroupConfigProps} /> : null}
          <ViewVariant hide={VIEW_VARIANTS.BOARD} />
          {contacts.edit ? (
            <SettingsButton
              options={settingsOptions}
              onChange={onChoseSettings}
              variant="text"
              color="blue"
              disabled={disabled}
            />
          ) : null}
        </ToolbarRight>
      </Toolbar>
    </StyledToolbarWrapper>
  );
};

export default ControlBar;
