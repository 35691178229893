import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from 'app/colors';
import { useEditor } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import { useTextStyleValue } from '../../../WYSIWYG/useTextStyleValue';
import {
  TextEditorToolbarSection,
  ToggleButton,
  ToolbarIcon,
  ToolbarSelectWysiwyg,
  ToolbarIconMenuContainer,
  ToolbarIconMenu,
  ToolbarIconMenuList,
  ToolbarIconMenuListItem,
} from '../../styles';

const DEFAULT = 'default';

const buildOptions = (defaultLabel) => [
  { value: DEFAULT, label: defaultLabel },
  { value: 1, label: '1' },
  { value: 1.25, label: '1.25' },
  { value: 1.5, label: '1.5' },
  { value: 1.75, label: '1.75' },
  { value: 2, label: '2' },
  { value: 2.5, label: '2.5' },
  { value: 3, label: '3' },
];

const FontFamilyDropdown = styled(ToolbarSelectWysiwyg)`
  ${({ enableLineHeight }) =>
    enableLineHeight &&
    css`
      > div {
        padding-right: 15px;
      }
    `}
`;

export const LineHeight = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const editor = useEditor();
  const [menuOpen, setMenuOpen] = useState(false);
  const options = useMemo(() => buildOptions(t('Default')), [t]);
  useClickAway(ref, () => setMenuOpen(false));

  if (!editor) return null;

  const lineHeight = editor.getAttributes('paragraph')?.lineHeight ?? DEFAULT;

  return (
    <ToolbarIconMenuContainer ref={ref}>
      <ToggleButton
        title={t('Line Height')}
        onClick={() => setMenuOpen((x) => !x)}
      >
        <ToolbarIcon icon="line-height" color={grayScale.dark} />
      </ToggleButton>
      {menuOpen && (
        <ToolbarIconMenu>
          <ToolbarIconMenuList>
            {options.map(({ label, value }) => (
              <ToolbarIconMenuListItem
                selected={value === lineHeight}
                key={value}
                onClick={() => {
                  setMenuOpen(false);
                  editor.chain().focus().setLineHeight(value).run();
                }}
              >
                {label}
              </ToolbarIconMenuListItem>
            ))}
          </ToolbarIconMenuList>
        </ToolbarIconMenu>
      )}
    </ToolbarIconMenuContainer>
  );
};

export const FontFamilyToolbarSection = ({
  enableLineHeight,
  fonts,
  defaultFontFamily,
  onChange,
}) => {
  const selectRef = useRef();
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  const fontFamily = useTextStyleValue('fontFamily');
  const fontObjectLookup = useMemo(() => {
    const all = fonts[0]?.options ? fonts.flatMap((f) => f.options) : fonts;
    return all.reduce((acc, font) => {
      acc[font.value] = font;
      return acc;
    }, {});
  }, [fonts]);

  if (!editor) return null;

  const selectValue = selectRef.current?.state?.value?.value;
  const activeFontFamily =
    selectValue && editor.isActive({ fontFamily: selectValue })
      ? selectValue
      : fontFamily;

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <FontFamilyDropdown
        ref={selectRef}
        variant="underline"
        enableLineHeight={enableLineHeight}
        options={fonts}
        value={fontObjectLookup[activeFontFamily || defaultFontFamily]}
        onChange={({ value }) => {
          onChange?.(value);
          editor.chain().focus().setFontFamily(value).run();
        }}
      />
      {enableLineHeight && <LineHeight />}
    </TextEditorToolbarSection>
  );
};
