import React, { useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { colorsButton } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import useModal from 'components/Modals/useModal';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { resetButtonCss } from 'app/spacing';
import { OutlineInputLayout } from 'components/Inputs/TextInput/Outline';
import { MaybeInputControl } from '../InputControl';
import Validation, { useValidation } from '../Validation';
import MessageTemplatePicker from 'components/MessageTemplatePicker';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInputLayout = styled(OutlineInputLayout)`
  padding: 0;
`;

const MessageInputWrapper = styled.button`
  ${resetButtonCss}
  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}

  display: flex;
  flex: 1;
  width: 100%;

  > div {
    height: 100%;
  }

  > div:first-of-type {
    display: flex;
    flex: 1;
    align-content: center;
  }

  ${({ showVisibleIcon }) =>
    showVisibleIcon &&
    css`
      > div:last-of-type {
        display: flex;
        flex: 0 0 20px;

        align-content: center;
        justify-content: flex-end;
      }
    `}
`;

const OutlineLibraryMessageInput = (props) => {
  const { t } = useTranslation();
  const validationRef = useRef();
  const [validation, validationProps] = useValidation(validationRef, props);
  const {
    onChange,
    inModal,
    errorPlacement,
    placeholder,
    error,
    readonly,
    showVisibleIcon,
    message,
    suppressedRoutes,
    suppressedFolders,
    ...inputControlProps
  } = props;
  const { disabled, sizing, viewable } = props;
  const [modalProps, , modal] = useModal();

  const handleChange = useCallback(
    (message) => {
      modal.hide();
      onChange && onChange(message);
    },
    [modal, onChange]
  );

  return (
    <>
      <MaybeInputControl
        forInput
        variant="outline"
        {...{
          ...inputControlProps,
          error: true,
          disabled: false,
          shrink: true,
        }}
        margin
        sizing={sizing}
      >
        <StyledInputLayout
          ref={validationRef}
          disabled={disabled}
          viewable={viewable}
          error={error || validation.error}
        >
          <MessageInputWrapper
            data-qa-file-input="open-button"
            onClick={() => !disabled && modal.show()}
            disabled={disabled}
            showVisibleIcon={showVisibleIcon}
            padding="0 12px"
          >
            <div>
              <StyledPlaceholder>
                <KizenTypography
                  as="span"
                  color={!disabled && colorsButton.blue.default}
                >
                  {message
                    ? message.name || message.subject
                    : placeholder ?? t('Choose Message from Library')}
                </KizenTypography>
              </StyledPlaceholder>
            </div>
            {showVisibleIcon && (
              <div>{!disabled && <IconAdornment icon="visible" />}</div>
            )}
          </MessageInputWrapper>
        </StyledInputLayout>
        <Validation
          inModal={inModal}
          errorPlacement={errorPlacement}
          {...validationProps}
        />
      </MaybeInputControl>
      <MessageTemplatePicker
        show={modalProps.show}
        onCancel={modal.hide}
        onSubmit={handleChange}
        suppressedRoutes={suppressedRoutes}
        suppressedFolders={suppressedFolders}
      />
    </>
  );
};

OutlineLibraryMessageInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  message: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        subject: PropTypes.string.isRequired,
      }),
    ])
  ).isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  shrink: PropTypes.bool,
  sizing: PropTypes.string,
  viewable: PropTypes.bool,
  errorPlacement: PropTypes.string,
  showVisibleIcon: PropTypes.bool,
  suppressedRoutes: PropTypes.arrayOf(PropTypes.string),
  suppressedFolders: PropTypes.arrayOf(PropTypes.string),
};

OutlineLibraryMessageInput.defaultProps = {
  label: null,
  placeholder: undefined,
  id: '',
  message: null,
  className: '',
  error: null,
  disabled: false,
  shrink: null,
  sizing: null,
  viewable: true,
  errorPlacement: undefined,
  showVisibleIcon: true,
  suppressedRoutes: EMPTY_ARRAY,
  suppressedFolders: EMPTY_ARRAY,
};
export default OutlineLibraryMessageInput;
