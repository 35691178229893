import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useColumns } from '../hooks/useColumns';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import { DEFAULT_TEMPLATE_ID } from 'components/Modals/ApplyTemplateModal';
import ColumnsService from 'services/ColumnsService';
import { useBigTableLayout } from 'components/Layout/BigTableLayout';
import BigTable from 'components/Tables/Big';
import { TRow } from 'components/Kizen/Table';
import TablePagination from 'components/Tables/Big/TablePagination';
import { TableToolBar } from '../components/ToolBar';
import { MobileFallback } from 'pages/Common/MobileFallback';
import useModal from 'components/Modals/useModal';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import { SharingAccessModal } from 'components/Modals/SharingAccessModal';
import { getColumnConfigPayload, getPathBack } from '../helpers';
import Loader from 'components/Kizen/Loader';
import {
  APPLY_TEMPLATE_PARAM,
  CONFIG_KEY,
  DEFAULT_PAGINATION_SETTINGS,
  NAV_BAR_HEIGHT,
} from '../constants';
import { SUBPAGES } from '../routes';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import KizenTypography from 'app/kizentypo';
import { getOriginalError } from 'services/AxiosService';
import { useRespondToAccessRequest } from '../hooks/useRespondToAccessRequest';
import { toastVariant, useToast } from 'components/ToastProvider';
import { AccessRequestModal } from 'components/Modals/AccessRequestModal';
import { useSharingAccessModal } from '../hooks/useSharingAccessModal';
import { StyledTableLayout } from '../styles';
import { usePaginationSettings } from 'hooks/usePaginationSettings';

export const TemplatesTable = ({
  defaultColumnSettings,
  model,
  disabled,
  isMobile,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const {
    page,
    pageSize,
    search,
    ordering,
    handleChangePage,
    handleChangeSearch,
    handleChangePageSize,
    headData,
  } = usePaginationSettings(DEFAULT_PAGINATION_SETTINGS);

  const history = useHistory();
  const templateActionId = useRef('');
  const requestingAccessState = useRef(null);
  const currentlyEditingTemplate = useRef(null);
  const [errors, setErrors] = useState({});

  const [{ value: responseData, loading: loadingData }, refetch] =
    useAsyncFnKeepLast(
      async () =>
        ColumnsService.listTemplates(
          page,
          pageSize,
          ordering,
          search,
          model?.id,
          {
            skipErrorBoundary: true,
          }
        ),
      [page, pageSize, search, ordering, model?.id]
    );

  useRespondToAccessRequest(refetch, model?.id);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const data = useMemo(() => {
    if (responseData && page === 1) {
      return {
        ...responseData,
        results: [
          {
            id: DEFAULT_TEMPLATE_ID,
            employee_access: 'View',
            name: t('Default Template'),
            owner: {
              display_name: t('Kizen Administrator'),
              static: true,
            },
          },
          ...responseData.results,
        ],
      };
    }

    return responseData;
  }, [responseData, t, page]);

  const onConfirmDelete = useCallback(async () => {
    await ColumnsService.deleteTemplate(templateActionId.current, model?.id);
    templateActionId.current = null;
    refetch();
  }, [refetch, model?.id]);

  const sendRequestAccess = useCallback(
    async (templateId, accessLevel, grantingAdmin) => {
      const data = await ColumnsService.requestAccess(
        templateId,
        grantingAdmin,
        accessLevel,
        model?.id
      );
      if (data?.status === 'pending') {
        showToast({
          message: t('Your access request has been sent.'),
          variant: toastVariant.SUCCESS,
        });
      } else {
        showToast({
          message: t('Your access request could not be sent.'),
          variant: toastVariant.FAILURE,
        });
      }
    },
    [model?.id, showToast, t]
  );

  const [confirmDeletionModalProps, { onClick: showConfirmDeletionModal }] =
    useModal({
      handleSubmit: onConfirmDelete,
    });

  const [accessRequestModalProps, { onClick: showAccessRequestModal }] =
    useModal({
      handleSubmit: sendRequestAccess,
    });

  const onSubmitSharingAccessModal = useCallback(
    async ({ name, permissions, isPrivate }) => {
      if (currentlyEditingTemplate.current?.id) {
        await ColumnsService.updateTemplate(
          currentlyEditingTemplate.current.id,
          name,
          undefined,
          {
            ...buildSharingSettingsForAPI(permissions).sharing_settings,
            private: isPrivate,
          },
          undefined,
          model?.id
        );

        currentlyEditingTemplate.current = null;
        refetch();
      } else {
        const { data } = await ColumnsService.createTemplate(
          name,
          currentlyEditingTemplate.current?.[CONFIG_KEY] ||
            getColumnConfigPayload(defaultColumnSettings),
          {
            ...buildSharingSettingsForAPI(permissions).sharing_settings,
            private: isPrivate,
          },
          model?.id
        );

        history.push(history.location.pathname + '/' + data.id);
      }

      showToast({
        message: t("'{{name}}' column template has been saved successfully.", {
          name,
        }),
        variant: toastVariant.SUCCESS,
      });
    },
    [defaultColumnSettings, history, model?.id, refetch, showToast, t]
  );

  const { sharingAccessModalProps, onShowSharingAccessModal } =
    useSharingAccessModal(onSubmitSharingAccessModal, currentlyEditingTemplate);

  const handleDuplicateTemplate = useCallback(
    async (id) => {
      const res = await ColumnsService.getTemplate(id, model?.id);
      if (res) {
        currentlyEditingTemplate.current = {
          name: `${res.name} ${t('Copy')}`,
          sharing_settings: res.sharing_settings,
          [CONFIG_KEY]: res[CONFIG_KEY],
        };
        onShowSharingAccessModal();
      }
    },
    [onShowSharingAccessModal, model?.id, t]
  );

  const handleActionMenu = useCallback(
    ({ value }, data) => {
      const { id } = data;

      if (value === 'edit') {
        history.push(`${history.location.pathname}/${id}`);
      } else if (value === 'delete') {
        templateActionId.current = id;
        showConfirmDeletionModal();
      } else if (value === 'request') {
        requestingAccessState.current = data;
        showAccessRequestModal();
      } else if (value === 'edit_sharing') {
        currentlyEditingTemplate.current = data;
        onShowSharingAccessModal();
      } else if (value === 'duplicate') {
        handleDuplicateTemplate(id);
      } else if (value === 'apply') {
        history.push(
          `${getPathBack(history.location.pathname)}/${
            SUBPAGES.MINE
          }?${APPLY_TEMPLATE_PARAM}=${id}`
        );
      }
    },
    [
      history,
      showConfirmDeletionModal,
      handleDuplicateTemplate,
      showAccessRequestModal,
      onShowSharingAccessModal,
    ]
  );

  const handleChangeName = useCallback(
    async (value, id) => {
      setErrors({});

      try {
        await ColumnsService.updateTemplate(
          id,
          value,
          undefined,
          undefined,
          undefined,
          model?.id
        );
        refetch();
      } catch (err) {
        const { name } = getOriginalError(err) || {};
        if (name) {
          setErrors({ [id]: { name } });
        }
      }
    },
    [model?.id, refetch]
  );

  const handleChangeOwner = useCallback(
    async ({ value }, id) => {
      await ColumnsService.updateTemplate(
        id,
        undefined,
        undefined,
        undefined,
        value,
        model?.id
      );
      refetch();
    },
    [model?.id, refetch]
  );

  const handleClickEditSharingSettings = useCallback(
    (data) => {
      currentlyEditingTemplate.current = data;
      onShowSharingAccessModal();
    },
    [onShowSharingAccessModal]
  );

  const columns = useColumns(
    handleActionMenu,
    handleChangeName,
    handleClickEditSharingSettings,
    handleChangeOwner,
    errors,
    data,
    defaultColumnSettings
  );

  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout(NAV_BAR_HEIGHT);
  const { scrolledToTable } = bigTablePageProps;

  const fetchProps = useMemo(() => [model?.id], [model?.id]);

  return (
    <>
      <Loader loading={loadingData && !data}>
        <StyledTableLayout
          toolbar={
            <TableToolBar
              onChangeSearch={handleChangeSearch}
              count={(data?.count || 0) + 1}
              loading={loadingData}
              disabled={disabled}
              isMobile={isMobile}
              onAddTemplate={onShowSharingAccessModal}
            />
          }
          pagination={
            isMobile ? null : (
              <TablePagination
                totalCount={data?.count}
                page={page}
                perPage={pageSize}
                onChangePerPage={handleChangePageSize}
                onChangePage={handleChangePage}
              />
            )
          }
          {...bigTablePageProps}
        >
          <MobileFallback isMobile={isMobile}>
            <BigTable
              scrolledToTable={scrolledToTable}
              columns={columns}
              head={<TRow head columns={columns} data={headData} />}
              staleData={loadingData}
            >
              {data?.results.map((result) => {
                return <TRow key={result.id} columns={columns} data={result} />;
              }) ?? []}
            </BigTable>
          </MobileFallback>
        </StyledTableLayout>
      </Loader>
      {sharingAccessModalProps.show ? (
        <SharingAccessModal
          {...sharingAccessModalProps}
          existing={currentlyEditingTemplate.current}
          isEditing={Boolean(currentlyEditingTemplate.current?.id)}
          disabled={disabled}
          instanceName={t('Column')}
          showPrivateToggle
        />
      ) : null}
      {accessRequestModalProps.show ? (
        <AccessRequestModal
          template={requestingAccessState.current}
          instanceName={t('Column')}
          serviceToUse={ColumnsService}
          fetchProps={fetchProps}
          {...accessRequestModalProps}
        />
      ) : null}
      <ConfirmDeletionModal {...confirmDeletionModalProps}>
        <KizenTypography>
          {t('This will permanently delete the Template.')}
        </KizenTypography>
      </ConfirmDeletionModal>
    </>
  );
};
