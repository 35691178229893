import TwoColumnContainer from './components/TwoColumnContainer';

const MultiColumnBuilder = ({
  iconVisible,
  iconEditable,
  colorEditable,
  ...props
}) => {
  return <TwoColumnContainer {...props} />;
};

export default MultiColumnBuilder;
