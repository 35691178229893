import { createElement } from 'react';
import {
  EmailButtonSettings,
  FormButtonSettings,
  HomepageButtonSettings,
  StaticBlockButtonSettings,
} from '../nodes/Button/ButtonSettings';
import { CustomFieldSettings } from '../nodes/CustomField/CustomFieldSettings';
import { AttachmentsSettings } from '../nodes/Attachments/AttachmentsSettings';
import {
  EmailImageSettings,
  FormImageSettings,
} from '../nodes/Image/ImageSettings';
import { FormFieldSettings } from '../nodes/FormField/FormFieldSettings';
import { EmailRowSettings, FormRowSettings } from '../nodes/Row/RowSettings';
import {
  EmailSectionSettings,
  FormSectionSettings,
} from '../nodes/Section/SectionSettings';
import {
  EmailTextSettings,
  FormTextSettings,
} from '../nodes/Text/TextSettings';
import { FormHTMLBlockSettings } from '../nodes/HTMLBlock/HTMLBlockSettings';
import { EmailDividerSettings, FormDividerSettings } from '../nodes/Divider';

export const emailNodeSettings = {
  Button: EmailButtonSettings,
  Divider: EmailDividerSettings,
  Image: EmailImageSettings,
  Row: EmailRowSettings,
  Section: EmailSectionSettings,
  Text: EmailTextSettings,
  Attachments: AttachmentsSettings,
};

export const formNodeSettings = (isFormPage) => ({
  Button: (props) =>
    createElement(FormButtonSettings, { isFormPage, ...props }),
  CustomField: CustomFieldSettings,
  Divider: FormDividerSettings,
  Image: FormImageSettings,
  FormField: FormFieldSettings,
  Row: FormRowSettings,
  Section: FormSectionSettings,
  Text: FormTextSettings,
  HTMLBlock: FormHTMLBlockSettings,
});

export const homePageNodeSettings = {
  Button: HomepageButtonSettings,
  Divider: FormDividerSettings,
  Image: FormImageSettings,
  Row: FormRowSettings,
  Section: FormSectionSettings,
  Text: FormTextSettings,
  HTMLBlock: FormHTMLBlockSettings,
};

export const staticBlockNodeSettings = {
  Button: StaticBlockButtonSettings,
  Divider: FormDividerSettings,
  Image: FormImageSettings,
  Row: FormRowSettings,
  Section: FormSectionSettings,
  Text: FormTextSettings,
  HTMLBlock: FormHTMLBlockSettings,
};
