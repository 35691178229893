import { forwardRef } from 'react';
import Underline from './Underline';
import OutLine from './Outline';

const SummarizedMultiSelect = forwardRef(({ variant, ...props }, ref) => {
  const Variant = variant === 'underline' ? Underline : OutLine;
  return <Variant ref={ref} {...props} />;
});

export default SummarizedMultiSelect;
