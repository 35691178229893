import {
  useCallback,
  useMemo,
  useRef,
  useState,
  memo,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactive } from '@kizen/permissions/hooks/useReactive';
import {
  PermissionsContext,
  usePermissionsContext,
} from '@kizen/permissions/hooks/context';
import { useHasEdits } from '@kizen/permissions/hooks/useHasEdits';
import { useIsPermissionVisible } from '@kizen/permissions/hooks/useIsPermissionVisible';
import Button from 'components/Button';
import Loader from 'components/Kizen/Loader';
import { ModalHeader, ModalFooter } from 'components/Modals';
import ConfirmNavigationModal from 'components/Modals/presets/ConfirmNavigation';
import { toastVariant, useToast } from 'components/ToastProvider';
import { invalidate } from 'queries/invalidate';
import TeamMemberService from 'services/TeamMemberService';
import { StyledModal, Content, StyledModalBody } from './styled';
import {
  useContactFieldsQuery,
  useObjectsQuery,
  usePermissionGroupQuery,
  usePermissionsMetadata,
} from './query';
import { usePreReleaseFeaturePermissionConfig } from './useHiddenPreReleasePermission';
import { AppAreaSection } from './AppAreaSection';
import { ContactsSection } from './ContactsSection';
import { CustomObjectSection } from './CustomObjectSection';
import { PermissionGroupName } from './components';
import { getOriginalError } from 'services/AxiosService';
import { REGISTERED_ROLE_AND_GROUP_NAME } from 'pages/Settings/helpers';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';

export const scrollOptions = {
  behavior: 'smooth',
  block: 'start',
};
const SectionVisibilityWrapper = ({ children, permissionKey }) => {
  const isVisible = useIsPermissionVisible(permissionKey);

  return isVisible ? children : null;
};

const PermissionModalContent = memo(
  forwardRef(({ isEdit, nameError }, ref) => {
    const {
      meta_config: { sections, order },
      co_sections,
    } = usePermissionsContext();

    const allSections = useMemo(() => {
      const combinedSections = [
        ...sections,
        { key: 'contacts_section' }, // Placeholder for ContactsSection
        { key: 'custom_object_entities' }, // Placeholder for Custom Objects Entities Section
      ];

      const orderDict = order.reduce((acc, key, index) => {
        acc[key] = index;
        return acc;
      }, {});

      return combinedSections
        .filter((section) => orderDict[section.key] !== undefined)
        .sort((a, b) => orderDict[a.key] - orderDict[b.key]);
    }, [sections, order]);

    return (
      <Content ref={ref}>
        <div className="ContentForSyncHeight">
          <PermissionGroupName isEdit={isEdit} nameError={nameError} />
          {allSections.map((section) =>
            section.key === 'contacts_section' ? (
              <SectionVisibilityWrapper
                key={section.key}
                permissionKey={section.key}
              >
                <ContactsSection />
              </SectionVisibilityWrapper>
            ) : section.key === 'custom_object_entities' ? (
              co_sections.map((co_section) => (
                <SectionVisibilityWrapper
                  key={co_section.key}
                  permissionKey={co_section.key}
                >
                  <CustomObjectSection
                    key={co_section.key}
                    section={co_section}
                  />
                </SectionVisibilityWrapper>
              ))
            ) : (
              <SectionVisibilityWrapper
                key={section.key}
                permissionKey={section.key}
              >
                <AppAreaSection key={section.key} section={section} />
              </SectionVisibilityWrapper>
            )
          )}
        </div>
      </Content>
    );
  })
);

const PermissionModal = ({
  id,
  onConfirm,
  onHide,
  isLoading = false,
  show = true,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmDiscard, setConfirmDiscard] = useState(false);
  const { payload, name } = usePermissionsContext();
  const [nameError, setNameError] = useState(null);
  const value = useReactive(name);
  const initialValue = useRef(value);
  const permissionNameInvalid = useReactive(
    name,
    (x) =>
      x?.length === 0 ||
      (x === REGISTERED_ROLE_AND_GROUP_NAME && !id) ||
      (x === REGISTERED_ROLE_AND_GROUP_NAME &&
        id &&
        initialValue.current !== REGISTERED_ROLE_AND_GROUP_NAME)
  );

  const hasEdits = useHasEdits();

  const handleHide = useCallback(() => {
    if (hasEdits) {
      setConfirmDiscard(true);
    } else {
      onHide();
    }
  }, [hasEdits, onHide]);

  const updateOrCreatePermissionGroup = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const updates = payload?.deref();
      if (updates) {
        if (id) {
          await TeamMemberService.updatePermissionGroup(id, updates);
        } else {
          await TeamMemberService.createPermissionGroup(updates);
        }
        invalidate.PERMISSION_GROUP.GROUPS();
      }
      await onConfirm?.();
      onHide?.();
    } catch (error) {
      const { data, status } = error.response;
      // handle name error separately
      if (status === 400 && data?.errors?.name) {
        setNameError(data.errors.name);
        return;
      }
      const message = getOriginalError(error);
      const defaultMessage = t(
        'There was an error when trying to save. Please contact Kizen support for help with resolution.'
      );
      const errorMessage = message?.errors
        ? message.errors.details ||
          Object.values(message.errors)?.[0] ||
          defaultMessage
        : defaultMessage;

      showToast({
        variant: toastVariant.FAILURE,
        delay: 3000,
        message:
          typeof errorMessage === 'string' ? errorMessage : defaultMessage,
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [id, payload, onConfirm, onHide, showToast, t]);

  // scrollbar
  const scrollbarContentWrapperRef = useRef();
  const [contentWrapperRefFn, contentWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncHeight',
    'height'
  );
  useSyncScroll(useRef([contentWrapperRef, scrollbarContentWrapperRef]), {
    vertical: true,
  });

  return (
    <StyledModal show={show} size="medium" onHide={handleHide}>
      <ModalHeader onClickClose={handleHide}>
        {id ? t('Edit Permission Group') : t('Add Permission Group')}
      </ModalHeader>
      <StyledModalBody isLoading={isLoading}>
        <Loader loading={isLoading}>
          <PermissionModalContent
            ref={contentWrapperRefFn}
            isEdit={
              Boolean(id) &&
              initialValue.current === REGISTERED_ROLE_AND_GROUP_NAME
            }
            nameError={nameError}
          />
        </Loader>
        <ScrollBarDetached
          ref={scrollbarContentWrapperRef}
          direction="vertical"
          scrollClassName="ContentForSyncHeight"
        />
      </StyledModalBody>
      <ModalFooter>
        <Button variant="text" color="blue" onClick={handleHide}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={updateOrCreatePermissionGroup}
          disabled={isLoading || isSubmitting || permissionNameInvalid}
          color="green"
        >
          {t('Save')}
        </Button>
        <ConfirmNavigationModal
          when={hasEdits}
          show={confirmDiscard}
          onHide={() => setConfirmDiscard(false)}
          onConfirm={onHide}
        />
      </ModalFooter>
    </StyledModal>
  );
};

export const PermissionGroupModal = ({
  id,
  onConfirm,
  onHide,
  show = true,
}) => {
  const { t } = useTranslation();
  const { data: objects, isLoading: objectsLoading } = useObjectsQuery();
  const contactObject = useSelector(getBusinessClientObject);
  const { data: contactFields, isLoading: contactFieldsLoading } =
    useContactFieldsQuery();
  const { data: permissionGroup, isLoading: pgLoading } =
    usePermissionGroupQuery(id);
  const { data: permissionsMetadata, isLoading: metadataLoading } =
    usePermissionsMetadata();
  const preReleaseFeaturesConfig = usePreReleaseFeaturePermissionConfig();
  const preReleaseConfig =
    pgLoading || !preReleaseFeaturesConfig
      ? preReleaseFeaturesConfig
      : {
          ...preReleaseFeaturesConfig,
          default:
            permissionGroup?.settings_section?.pre_release_features ??
            preReleaseFeaturesConfig.default,
        };
  const isLoading =
    pgLoading || objectsLoading || contactFieldsLoading || metadataLoading;

  if (isLoading) {
    return <PermissionModal isLoading id={id} />;
  }

  return (
    <PermissionsContext
      translationFunc={t}
      permissionsConfig={permissionsMetadata}
      preReleaseFeatureConfig={preReleaseConfig}
      customObjects={objects}
      contactObjectId={contactObject.id}
      contactFields={contactFields}
      permissionGroup={permissionGroup}
    >
      <PermissionModal
        id={id}
        show={show}
        onConfirm={onConfirm}
        onHide={onHide}
      />
    </PermissionsContext>
  );
};
