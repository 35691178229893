import React, { useCallback, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FIELD_TYPES } from 'utility/constants';
import Radio from './Radio';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import SelectableOptions from '../SelectableOptions';
import { applyRef, optionProp, selectedValueProp, variantProp } from '../props';
import Select from '../Select';
import Validation, { useValidation } from '../Validation';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const RadioGroup = React.forwardRef((props, ref) => {
  const {
    fieldId,
    onChange,
    options,
    value: valueProp,
    variant,
    error,
    disabled,
    className,
    required,
  } = props;

  const { assignFieldHandle } = useKeyBoardContext();

  const { t } = useTranslation();

  const validationRef = useRef();
  const [, validationProps] = useValidation(validationRef, props);
  const value =
    typeof valueProp === 'string'
      ? options.find((opt) => opt.value === valueProp) || null
      : valueProp;

  const mergeRef = useCallback(
    (node) => {
      applyRef(ref, node);
      applyRef(validationRef, node);
    },
    [ref]
  );
  const selectedElem = useMemo(() => {
    const index = options.map((i) => i.value).indexOf(value?.value);
    return index >= 0 ? index : 0;
  }, [options, value]);

  const refs = useRef([]);
  const selectRef = useRef(null);
  const focused = useRef(selectedElem);

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    props
  );
  assignFieldHandle(fieldId, {
    customFocus: () => {
      if (variant === 'underline' && options.length >= 5) {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      } else {
        refs.current[selectedElem]?.focus();
        focused.current = selectedElem;
        return refs.current[selectedElem];
      }
    },
    customSpace: (e) => {
      refs.current[focused.current]?.click();
      refs.current[focused.current]?.focus();
    },
    customEscape: () => {
      setMenuIsOpen(false);
    },
    customUp: (e) => {
      e.preventDefault();
      if (variant === 'underline' && options.length >= 5) {
        setMenuIsOpen(true);
      } else {
        if (refs.current.length > 1) {
          if (focused.current === 0) {
            focused.current = refs.current.length - 1;
          } else focused.current -= 1;
          refs.current[focused.current]?.focus();
        }
      }
    },
    customDown: (e) => {
      e.preventDefault();
      if (variant === 'underline' && options.length >= 5) {
        setMenuIsOpen(true);
      } else {
        if (refs.current.length > 1) {
          if (focused.current === refs.current.length - 1) {
            focused.current = 0;
          } else focused.current += 1;
          refs.current[focused.current]?.focus();
        }
      }
    },
    disabled,
  });

  if (variant === 'underline' && options.length >= 5) {
    return (
      <Select
        ref={selectRef}
        placeholder={FIELD_TYPES.Radio.localizedPlaceholder(t)}
        {...props}
        {...customSelectMenuProps}
        value={value}
      />
    );
  }

  const { value: _, ...forInputControl } = props;

  return (
    <MaybeInputControl {...forInputControl}>
      <SelectableOptions
        ref={mergeRef}
        className={!hasInputControl(props) && className}
      >
        {options.map((opt, i) => {
          const checked = Boolean(value && value.value === opt.value);
          return (
            <div key={opt.value}>
              <Radio
                ref={(el) => (refs.current[i] = el)}
                label={opt.label}
                value={opt.value}
                checked={checked}
                error={error}
                disabled={disabled}
                variant={variant}
                onClick={(ev) => {
                  if (onChange && checked && !required) {
                    ev.currentTarget.blur();
                    onChange(null, ev);
                  }
                }}
                onChange={(_checked, ev) => {
                  focused.current = i;
                  if (onChange) onChange(opt, ev);
                }}
              />
            </div>
          );
        })}
        <Validation {...validationProps} />
      </SelectableOptions>
    </MaybeInputControl>
  );
});

export default RadioGroup;

RadioGroup.displayName = 'RadioGroup';
RadioGroup.propTypes = {
  value: selectedValueProp,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionProp),
  variant: variantProp,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};
RadioGroup.defaultProps = {
  value: null,
  onChange: null,
  options: [],
  variant: null,
  error: null,
  disabled: null,
};
