import { css } from '@emotion/core';
import styled from '@emotion/styled';
import ReactSelect from 'react-select';

export default styled(ReactSelect)`
  ${({ menuInline, computedMenuPlacement }) =>
    menuInline &&
    computedMenuPlacement === 'top' &&
    css`
      display: flex;
      flex-direction: column-reverse;
    `}
`;
