import styled from '@emotion/styled';
import { FLOW_PADDING } from '../constants';

export const StyledFlowWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: ${FLOW_PADDING}px;
`;

export const StyledBackgroundCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledForegroundCanvas = styled(StyledBackgroundCanvas)`
  z-index: 4;
  pointer-events: none;
`;

export const StyledTitlesCanvas = styled(StyledBackgroundCanvas)`
  top: 70px;
  z-index: 4;
  cursor: auto;
  height: 25px;
`;
