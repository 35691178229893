import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import UUID from 'utility/UUID';
import { ButtonGrid } from './ButtonGrid';
import { SimpleBuilderButtons } from './SimpleBuilderButtons';
import { SimpleBuilder } from './SimpleBuilder';

import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { isFieldAccessible } from 'services/FieldService';

import SimpleBuilderSVG from './images/simple-builder.svg?react';
import SpreadsheetStyleBuilderSVG from './images/spreadsheet-style-builder.svg?react';

import { useAutomationsSelector } from 'pages/AutomationEngine/store/react';
import { useScrollHeightTracker } from 'hooks/use-scroll-height-tracker';
import {
  getAutomationCustomObjectCategories,
  getAutomationCustomObjectFields,
} from 'pages/AutomationEngine/store/selectors';

import { numericFieldTypeFilter } from 'utility/constants';

import {
  REDUCER_TYPES,
  SIMPLE_SUB_TYPES,
  BUILDER_TYPES,
  ELEMENT_TYPES,
  SIMPLE_BUILDER_TYPES,
  SIMPLE_BUILDER_OPERATION_TYPES,
  MATH_OPERATOR_STEP,
} from './constants';
import produce from 'immer';

export const initElement = (
  type,
  value,
  operationType = SIMPLE_BUILDER_OPERATION_TYPES.add.value,
  id = `ele_${UUID.generate()}`
) => ({
  type,
  value,
  operationType,
  id,
});

export const initState = {
  step: MATH_OPERATOR_STEP.chooseBuilder,
  builderType: null,
  subType: null,
  simpleData: {
    [SIMPLE_BUILDER_TYPES.addSubtract]: {
      targetField: null,
      elements: [],
    },
    [SIMPLE_BUILDER_TYPES.multiplicationDivision]: {
      targetField: null,
      numeratorElements: [],
      denominatorElements: [],
    },
  },
};

const valueReducer = (state, action) => {
  const { type, value, subType, elementId } = action;
  switch (type) {
    case REDUCER_TYPES.setBuilderType:
      return { ...state, builderType: value };

    case REDUCER_TYPES.setStep:
      return { ...state, step: value };

    case REDUCER_TYPES.setSubType:
      return { ...state, subType: value };

    case REDUCER_TYPES.setSimpleData:
      switch (subType) {
        case REDUCER_TYPES.setSubType:
          return { ...state, subType: value };

        case SIMPLE_SUB_TYPES.setTargetField:
          return produce(state, (draft) => {
            draft.simpleData[state.subType].targetField = value;
          });

        // Add / Subtraction reducers
        case SIMPLE_SUB_TYPES.addElement:
          return produce(state, (draft) => {
            draft.simpleData[state.subType].elements.push(
              initElement(value.value, null)
            );
          });

        case SIMPLE_SUB_TYPES.deleteElement:
          return produce(state, (draft) => {
            const index = draft.simpleData[state.subType].elements.findIndex(
              (element) => element.id === elementId
            );

            if (index !== -1) {
              draft.simpleData[state.subType].elements.splice(index, 1);
            }
          });

        case SIMPLE_SUB_TYPES.updateElement:
          return produce(state, (draft) => {
            const index = draft.simpleData[state.subType].elements.findIndex(
              (element) => element.id === elementId
            );

            if (index !== -1) {
              draft.simpleData[state.subType].elements[index].value = value;
            }
          });

        case SIMPLE_SUB_TYPES.setOperator:
          return produce(state, (draft) => {
            const index = draft.simpleData[state.subType].elements.findIndex(
              (element) => element.id === elementId
            );

            if (index !== -1) {
              draft.simpleData[state.subType].elements[index].operationType =
                value;
            }
          });

        // Mult / Divide - Numerators
        case SIMPLE_SUB_TYPES.addNumerator:
          return produce(state, (draft) => {
            draft.simpleData[state.subType].numeratorElements.push(
              initElement(value.value, null)
            );
          });

        case SIMPLE_SUB_TYPES.deleteNumerator:
          return produce(state, (draft) => {
            const index = draft.simpleData[
              state.subType
            ].numeratorElements.findIndex(
              (element) => element.id === elementId
            );

            if (index !== -1) {
              draft.simpleData[state.subType].numeratorElements.splice(
                index,
                1
              );
            }
          });

        case SIMPLE_SUB_TYPES.updateNumerator:
          return produce(state, (draft) => {
            const index = draft.simpleData[
              state.subType
            ].numeratorElements.findIndex(
              (element) => element.id === elementId
            );

            if (index !== -1) {
              draft.simpleData[state.subType].numeratorElements[index].value =
                value;
            }
          });

        // Mult / Divide - Numerators
        case SIMPLE_SUB_TYPES.addDenominator:
          return produce(state, (draft) => {
            draft.simpleData[state.subType].denominatorElements.push(
              initElement(value.value, null)
            );
          });

        case SIMPLE_SUB_TYPES.deleteDenominator:
          return produce(state, (draft) => {
            const index = draft.simpleData[
              state.subType
            ].denominatorElements.findIndex(
              (element) => element.id === elementId
            );

            if (index !== -1) {
              draft.simpleData[state.subType].denominatorElements.splice(
                index,
                1
              );
            }
          });

        case SIMPLE_SUB_TYPES.updateDenominator:
          return produce(state, (draft) => {
            const index = draft.simpleData[
              state.subType
            ].denominatorElements.findIndex(
              (element) => element.id === elementId
            );

            if (index !== -1) {
              draft.simpleData[state.subType].denominatorElements[index].value =
                value;
            }
          });

        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export const Component = function MathOperators({
  values: valuesProp = null,
  onChange,
}) {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();
  const values = useMemo(
    () => ({
      ...initState,
      ...valuesProp,
    }),
    [valuesProp]
  );

  const automationContextFields = useAutomationsSelector(
    getAutomationCustomObjectFields
  );

  const automationContextCategories = useAutomationsSelector(
    getAutomationCustomObjectCategories
  );

  const options = useMemo(() => {
    const numericFields = automationContextFields
      .filter(
        (field) =>
          isFieldAccessible({ field, allowHidden: true }) &&
          numericFieldTypeFilter(field)
      )
      .sort((a, b) => a.displayName.localeCompare(b.displayName));

    return automationContextCategories.reduce((categoryAcc, category) => {
      const options = numericFields.reduce(
        (fieldAcc, field) =>
          field.category === category.id
            ? [
                ...fieldAcc,
                {
                  label: field.displayName,
                  value: field.id,
                  fieldData: field,
                },
              ]
            : fieldAcc,
        []
      );

      if (options.length) {
        return [
          ...categoryAcc,
          {
            label: category.name,
            options,
          },
        ];
      }

      return categoryAcc;
    }, []);
  }, [automationContextFields, automationContextCategories]);

  const section = {
    label: t('Choose Your Equation Type'),
    options: [
      {
        value: BUILDER_TYPES.simple,
        label: t('Simple Builder'),
        image: <SimpleBuilderSVG />,
        toolTip: '',
      },
      preReleaseFeatures && {
        value: BUILDER_TYPES.spreadsheetStyle,
        label: t('Spreadsheet-Style Formula'),
        image: <SpreadsheetStyleBuilderSVG />,
        toolTip: null,
      },
    ].filter(Boolean),
  };

  const simpleOptions = [
    {
      value: SIMPLE_BUILDER_TYPES.addSubtract,
      label: t('ADDITION/SUBTRACTION'),
    },
    {
      value: SIMPLE_BUILDER_TYPES.multiplicationDivision,
      label: t('MULTIPLICATION/DIVISION'),
    },
  ];

  const addFieldOptions = [
    { label: t('Number (Constant)'), value: ELEMENT_TYPES.constant },
    { label: t('Custom Field (Variable)'), value: ELEMENT_TYPES.variable },
  ];

  const onChangeBuilderType = useCallback(
    (option) => {
      // two chnages so keep the temp state local
      const tempState = valueReducer(values, {
        type: REDUCER_TYPES.setBuilderType,
        value: option.value,
      });

      // and use the temp state rather than values
      onChange(
        valueReducer(tempState, {
          type: REDUCER_TYPES.setStep,
          value:
            option.value === BUILDER_TYPES.simple
              ? MATH_OPERATOR_STEP.chooseSimpleType
              : MATH_OPERATOR_STEP.chooseSpreadsheetType,
        })
      );
    },
    [values, onChange]
  );

  const onChangeSimpleType = useCallback(
    (option) => {
      onChange(
        valueReducer(values, {
          type: REDUCER_TYPES.setSubType,
          value: option.value,
        })
      );
    },
    [values, onChange]
  );

  const onUpdateState = useCallback(
    (updated) => {
      onChange(
        valueReducer(values, {
          type: REDUCER_TYPES.setSimpleData,
          ...updated,
        })
      );
    },
    [values, onChange]
  );

  const trackerValues = useMemo(
    () => ({ builderType: values.builderType, subType: values.subType }),
    [values.builderType, values.subType]
  );
  const { componentRef } = useScrollHeightTracker(trackerValues);

  return (
    <div>
      <ButtonGrid
        section={section}
        value={values.builderType}
        onChange={onChangeBuilderType}
      />

      {values.step === MATH_OPERATOR_STEP.chooseSimpleType ? (
        <>
          <SimpleBuilderButtons
            options={simpleOptions}
            value={values.subType}
            onChange={onChangeSimpleType}
          />
          <div ref={componentRef} />
          <>
            {values.subType ? (
              <SimpleBuilder
                type={values.subType}
                data={values.simpleData[values.subType]}
                onUpdateState={onUpdateState}
                fieldOptions={options}
                addFieldOptions={addFieldOptions}
                clickNotify={() => {}}
              />
            ) : null}
          </>
        </>
      ) : null}
    </div>
  );
};

// make sure there are elements and none are empty
const validateElements = (elements) =>
  elements.length !== 0 && !elements.some((element) => element.value === null);

// allow no elements
const validateMutiplicationElements = (elements) =>
  elements.length === 0 || validateElements(elements);

export const validate = (values) => {
  if (!values.builderType) {
    return false;
  }
  if (values.builderType === BUILDER_TYPES.spreadsheetStyle) {
    // [TODO] add spreadsheet style validation
    return false;
  }
  // validate simple builder
  if (!values.subType) {
    return false;
  }

  // add subtract validation
  if (values.subType === SIMPLE_BUILDER_TYPES.addSubtract) {
    return (
      values.simpleData[SIMPLE_BUILDER_TYPES.addSubtract].targetField !==
        null &&
      validateElements(
        values.simpleData[SIMPLE_BUILDER_TYPES.addSubtract].elements
      )
    );
  }

  // multiplication division validation
  if (values.subType === SIMPLE_BUILDER_TYPES.multiplicationDivision) {
    return (
      values.simpleData[SIMPLE_BUILDER_TYPES.multiplicationDivision]
        .targetField !== null &&
      validateMutiplicationElements(
        values.simpleData[SIMPLE_BUILDER_TYPES.multiplicationDivision]
          .numeratorElements
      ) &&
      validateMutiplicationElements(
        values.simpleData[SIMPLE_BUILDER_TYPES.multiplicationDivision]
          .denominatorElements
      )
    );
  }

  return false;
};
