import { forwardRef } from 'react';
import format from 'date-fns/format';
import { API_DATE_FORMAT } from 'components/Inputs/DateInput';

import DateInputInline, {
  DateInputInlineSelect,
} from 'components/Inputs/inline/DateInput';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import { withCellWrapper } from '../helpers';

import { getDataQAForInput } from 'components/Inputs/helpers';

const _EstimatedCloseDateCell = forwardRef(
  (
    {
      field,
      object: record,
      onSubmit,
      value: valueProp, // remove from orhers as we get value from record
      objectId: modelId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      editable,
      onAutoFocus,
      meta,
      ...others
    },
    ref
  ) => {
    const value = record.estimatedCloseDate;

    return (
      <DateInputInline
        ref={ref}
        {...others}
        field={field}
        object={record}
        value={undefined} // Overriding base.props, value should be passed to DateInputInlineSelect
        onSubmit={async (data) => {
          await onSubmit(
            record.id,
            { estimatedCloseDate: data ? format(data, API_DATE_FORMAT) : data },
            column
          );
        }}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        {...getDataQAForInput(
          `inline-record-field-${field.name}`,
          field.fieldType
        )}
      >
        <DateInputInlineSelect
          value={value}
          menuLeftButton={menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </DateInputInline>
    );
  }
);

export const EstimatedCloseDateCell = withCellWrapper(_EstimatedCloseDateCell);
