import { grayScale } from 'app/colors';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const OTHER_CATEGORY_NAME = 'other';
const OTHER_LABEL_NAME = 'Other';

const MAX_NUMBER_OF_BUCKETS = 10;

const useRecomputingLegend = (
  data = [],
  opts = {},
  rows = 1,
  columns = 4,
  disableEmptySeriesByDefault = false,
  skipSorting = false
) => {
  const {
    columnCount = columns * rows,
    fixedWidth = false,
    prePad = false,
    positiveColors = [],
    negativeColors = [],
    negativeNeutralColor,
    getLabel = (d) => d.category.toUpperCase(),
    getId = (d) => d.category,
  } = opts;

  const [hidden, setHidden] = useState([]);
  const hiddenRef = useRef([]);
  const [legendRange, setLegendRange] = useState([0, columnCount]);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current && data.length && disableEmptySeriesByDefault) {
      initialized.current = true;
      setHidden(data.filter((d) => d.value === 0).map((d) => d.category));
    }
  }, [data, disableEmptySeriesByDefault]);

  // Separate out the special "other" category from the remaining buckets
  const { other, rest } = useMemo(() => {
    const sorted = skipSorting ? data : data.sort((a, b) => b.value - a.value);

    return sorted.reduce(
      (acc, curr) => {
        if (curr.category === OTHER_CATEGORY_NAME) {
          acc.other?.push(curr);
        } else {
          acc.rest?.push(curr);
        }

        return acc;
      },
      { other: [], rest: [] }
    );
  }, [data, skipSorting]);

  // Get the buckets that will be displayed in the chart. There can be a maximum of
  // 9 buckets, and the remaining categories will be grouped into the "other" bucket
  const chartData = useMemo(() => {
    const { visibleItems } = rest.reduce(
      (acc, curr) => {
        if (hidden.includes(curr.category)) {
          acc.hiddenItems?.push(curr);
        } else {
          acc.visibleItems?.push(curr);
        }
        return acc;
      },
      { hiddenItems: [], visibleItems: [] }
    );

    const {
      positiveBuckets,
      negativeBuckets,
      positiveLeftOvers,
      negativeLeftOvers,
    } = visibleItems.reduce(
      (acc, curr) => {
        // if we reach the max number of buckets group the rest into leftovers
        if (curr.value >= 0) {
          if (acc.positiveBuckets.length === MAX_NUMBER_OF_BUCKETS) {
            acc.positiveOther?.push(curr);
          } else if (acc.positiveBuckets.length < positiveColors.length) {
            acc.positiveBuckets?.push(curr);
          }
        } else {
          if (acc.negativeBuckets.length === MAX_NUMBER_OF_BUCKETS) {
            acc.negativeOther?.push(curr);
          } else if (acc.negativeBuckets.length < negativeColors.length) {
            acc.negativeBuckets?.push(curr);
          }
        }

        return acc;
      },
      {
        positiveBuckets: [],
        negativeBuckets: [],
        positiveLeftOvers: [],
        negativeLeftOvers: [],
      }
    );

    // if other bucket total is negative, then add it with the negative leftovers and vice versa
    const isOtherNegative =
      other.reduce((acc, curr) => {
        return acc + curr.value;
      }, 0) < 0;
    let combinedPositiveOtherBucket = [];
    let combinedNegativeOtherBucket = [];
    if (isOtherNegative) {
      combinedPositiveOtherBucket = [...other, ...positiveLeftOvers];
      combinedNegativeOtherBucket = negativeLeftOvers;
    } else {
      combinedPositiveOtherBucket = positiveLeftOvers;
      combinedNegativeOtherBucket = [...other, ...negativeLeftOvers];
    }
    const combinedPositiveOtherBucketValue = combinedPositiveOtherBucket.reduce(
      (acc, curr) => {
        return acc + curr.value;
      },
      0
    );
    const combinedNegativeOtherBucketValue = combinedNegativeOtherBucket.reduce(
      (acc, curr) => {
        return acc + curr.value;
      },
      0
    );

    if (combinedPositiveOtherBucketValue > 0) {
      return {
        positiveBuckets: [
          ...positiveBuckets,
          {
            category: OTHER_CATEGORY_NAME,
            value: combinedPositiveOtherBucketValue,
          },
        ],
        negativeBuckets,
      };
    }

    if (combinedNegativeOtherBucketValue < 0) {
      return {
        positiveBuckets,
        negativeBuckets: [
          ...negativeBuckets,
          {
            category: OTHER_CATEGORY_NAME,
            value: combinedNegativeOtherBucketValue,
          },
        ],
      };
    }

    return { positiveBuckets, negativeBuckets };
  }, [positiveColors, negativeColors, other, rest, hidden]);

  const chartTotal = useMemo(() => {
    return (
      chartData.positiveBuckets.reduce((acc, curr) => acc + curr.value, 0) +
      chartData.negativeBuckets.reduce((acc, curr) => acc + curr.value, 0)
    );
  }, [chartData]);

  const primaryTotal = useMemo(
    () => chartData.positiveBuckets.reduce((acc, curr) => acc + curr.value, 0),
    [chartData]
  );

  const secondaryTotal = useMemo(
    () => chartData.negativeBuckets.reduce((acc, curr) => acc + curr.value, 0),
    [chartData]
  );

  // Get the data for the legend (will include the visible items) as well as
  // any disabled items)
  const legendData = useMemo(() => {
    const { buckets, other: otherBuckets } = rest.reduce(
      (acc, curr, currIndex) => {
        if (currIndex < MAX_NUMBER_OF_BUCKETS + hidden.length) {
          acc.buckets.push(curr);
        } else {
          acc.other.push(curr);
        }

        return acc;
      },
      { buckets: [], other: [] }
    );

    const others = [...other, ...otherBuckets];
    const otherValue = others.reduce((acc, curr) => {
      return acc + curr.value;
    }, 0);

    if (others.length > 0) {
      return [...buckets, { category: OTHER_CATEGORY_NAME, value: otherValue }];
    }
    return buckets;
  }, [other, rest, hidden]);

  useEffect(() => {
    // This that we don't get into a situation where a hidden item is still in the state
    // but would not be rendered even if it wasn't hidden. This state can be reached if items are turned off,
    // and then turned back on in a way that would cause more than 9 discrete buckets to be rendered.
    if (hidden.length === 1) {
      if (!legendData.find((l) => l.category === hidden[0])) {
        setHidden([]);
      }
    }
  }, [hidden, legendData]);

  const handleClick = useCallback(
    (id) => {
      if (id !== OTHER_CATEGORY_NAME) {
        if (hidden.includes(id)) {
          setHidden((prev) => {
            const idx = prev.indexOf(id);
            const res = [...prev];
            res.splice(idx, 1);
            hiddenRef.current = res;
            return res;
          });
        } else {
          setHidden((prev) => {
            const res = [...prev, id];
            hiddenRef.current = res;
            return res;
          });
        }
      }
    },
    [hidden]
  );

  const hasMoreRows = useMemo(() => {
    return legendData.length > legendRange[1];
  }, [legendData, legendRange]);

  const hasPreviousRows = useMemo(() => {
    return legendRange[0] > 0;
  }, [legendRange]);

  const rotateRowDown = useCallback(() => {
    if (hasMoreRows) {
      setLegendRange((prev) => [prev[0] + columnCount, prev[1] + columnCount]);
    }
  }, [hasMoreRows, columnCount]);

  const rotateRowUp = useCallback(() => {
    if (hasPreviousRows) {
      setLegendRange((prev) => {
        const newStart = prev[0] - columnCount;
        if (newStart < 0) {
          return [0, columnCount];
        }
        return [newStart, prev[1] - columnCount];
      });
    }
  }, [hasPreviousRows, columnCount]);

  const { legendColors, chartColors, secondaryChartColors } = useMemo(() => {
    const legendColors = [];
    const chartColors = [];
    const secondaryChartColors = [];
    // we slice positive colors because we have an extra color for the "other" category
    const availableColors = [...positiveColors].slice(
      0,
      positiveColors.length - 1
    );
    const availableNegativeColors = [...negativeColors];

    for (let i = 0; i < legendData.length; i++) {
      const item = legendData[i];
      if (hidden.includes(item.category)) {
        legendColors.push({ light: grayScale.light });
        const removed = availableColors.splice(i, 1, [
          { light: grayScale.light },
        ]);
        availableColors.push(...removed);
      } else if (item.value >= 0) {
        legendColors.push(availableColors[i]);
        chartColors.push(availableColors[i]);
      } else if (item.value < 0) {
        // override to use the neutral color for the negative "other" values
        if (
          item.category === OTHER_CATEGORY_NAME ||
          item.label === OTHER_LABEL_NAME
        ) {
          legendColors.push({ light: negativeNeutralColor });
          secondaryChartColors.push({ light: negativeNeutralColor });
        } else {
          legendColors.push(availableNegativeColors[i]);
          secondaryChartColors.push(availableNegativeColors[i]);
        }
      }
    }

    if (
      legendData.find((l) => l.category === OTHER_CATEGORY_NAME && l.value >= 0)
    ) {
      chartColors[chartColors.length - 1] =
        positiveColors[positiveColors.length - 1];
      legendColors[legendColors.length - 1] =
        positiveColors[positiveColors.length - 1];
    }

    return {
      legendColors: legendColors.map((c) => c ?? { light: grayScale.light }),
      chartColors: chartColors.map((c) => c ?? { light: grayScale.light }),
      secondaryChartColors: secondaryChartColors.map(
        (c) => c ?? { light: grayScale.light }
      ),
    };
  }, [
    positiveColors,
    negativeColors,
    negativeNeutralColor,
    hidden,
    legendData,
  ]);

  return {
    legendProps: {
      handleClick,
      rotateRowDown,
      rotateRowUp,
      hasMoreRows,
      hasPreviousRows,
      hidden,
      legendRange,
      columnCount: columnCount > data.length ? data.length : columnCount,
      fixedWidth,
      prePad,
      chartData: legendData,
      colors: legendColors,
      getLabel,
      getId,
      rows: data.length < 3 ? 1 : rows,
      chartTotal,
      secondaryTotalOptions: {
        displaySecondaryData: true,
        isSecondaryData: (d) => d.value < 0,
        primaryTotal,
        secondaryTotal,
      },
      columns,
    },
    hiddenStable: hiddenRef,
    chartData,
    chartTotal,
    secondaryTotal,
    primaryTotal,
    chartColors,
    secondaryChartColors,
  };
};

export default useRecomputingLegend;
