import { merge } from './util';

export const isDebug = (enableDebug = true) => {
  const enabled = localStorage.getItem('kds-debug') === 'true' && enableDebug;
  const level = parseInt(localStorage.getItem('kds-debug-level') || '1');
  const enabledClassName = enabled ? 'kds-debug' : '';
  const levelClassName = enabled ? `kds-debug-level-${level}` : '';

  return {
    enabled,
    level,
    debugClassName: merge(enabledClassName, levelClassName),
  };
};

export const disableAnimations = () => {
  const disabled = localStorage.getItem('kds-disable-animations') === 'true';

  return disabled;
};

export const enableExpandingScrollbarLogging = () => {
  const enabled =
    localStorage.getItem('kds-expanding-scrollbar-logging') === 'true';

  return enabled;
};
