import { useRef } from 'react';
import * as PropTypes from 'prop-types';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { TRow } from 'components/Kizen/Table';
import { StyledTableScrollContainer } from './tableStyles';
import BasicTable from 'components/Tables/Basic';

export default function ProfileTable({
  columns,
  data,
  messageHeadData = {},
  ...others
}) {
  const roleTableWrapperRef = useRef();
  const roleTableScrolled = useEndsOfScroll(roleTableWrapperRef);

  return (
    <StyledTableScrollContainer
      ref={roleTableWrapperRef}
      scrolled={roleTableScrolled}
      bottom
      isTable
      refresh={[data]}
    >
      <BasicTable
        className="BasicTable"
        stickyHeader
        head={
          <TRow head columns={columns} {...others} data={messageHeadData} />
        }
      >
        {data.map((d) => (
          <TRow key={d.id} columns={columns} data={d} />
        ))}
      </BasicTable>
    </StyledTableScrollContainer>
  );
}

ProfileTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
};
ProfileTable.defaultProps = {
  data: undefined,
};
