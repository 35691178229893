import {
  DEFAULT_TEXT_CONTAINER_PROPS,
  EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
} from '@kizen/page-builder/nodes/containers';

export const DEFAULT_EMAIL_TEXT_PROPS = {
  ...DEFAULT_TEXT_CONTAINER_PROPS,
  ...EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
};

export const DEFAULT_FORM_TEXT_PROPS = DEFAULT_TEXT_CONTAINER_PROPS;
