import * as PropTypes from 'prop-types';

import TriggerCard from '../TriggerCard';

export const content = ({ t }) => {
  return t('Trigger (Manual)');
};

export default function ManualTriggerCard({ type, step, ...others }) {
  return (
    <TriggerCard
      {...others}
      addMenu={false} // never show a menu
    />
  );
}

ManualTriggerCard.propTypes = {
  type: PropTypes.string.isRequired,
  step: PropTypes.object.isRequired,
};
