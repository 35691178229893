import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FileService from 'services/FileService';
import { isEqual } from 'lodash';
/**
 * Hook to provide files state and update functions for the FileInput modal
 *
 * @param {Function} opts.uploadFile - required; the asynchronous function used to upload a single file (most likely the result of {@link ./useUploadFile})
 * @param {Function} opts.initializeProgress - optional; will be called prior to uploading with the files and their temp `$id`
 * @param {array} opts.initial - optional; the inital list of files
 * @returns 2-tuple - [sortedFiles, { addFiles, deselectFile, setFiles, updateFile, deleteFile }]
 */
export const useFiles = ({
  uploadFile,
  initializeProgress,
  initial = [],
  isInfinityScrollable = false,
  deps = [],
}) => {
  const newFilesRef = useRef();
  const [files, setFiles] = useState(initial);
  const depsRef = useRef(deps);
  useEffect(() => {
    if (isInfinityScrollable) {
      // If the initial files change, we want to update the files state
      // with the difference between the initial and the current files
      setFiles((prev) => {
        if (!isEqual(initial, prev)) {
          const newFiles = isEqual(deps, depsRef.current)
            ? prev.filter((file) => file?.new)
            : [];
          return [
            ...newFiles,
            ...initial.filter(({ id }) => !newFiles.some((f) => f.id === id)),
          ];
        }
        return prev;
      });
      depsRef.current = deps;
    }
  }, [initial, isInfinityScrollable, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteFile = useCallback((fileToDelete) => {
    setFiles((prev) => prev.filter((fi) => fi.id !== fileToDelete.id));
  }, []);

  const addFiles = useCallback(
    async (updatedFiles) => {
      const createFiles = updatedFiles
        .filter((obj) => obj instanceof File)
        .map((obj) => {
          // Optimistically give this file an id
          obj.$id = FileService.createId();
          return obj;
        });
      newFilesRef.current = updatedFiles.map((obj) => {
        if (obj instanceof File) {
          return {
            id: obj.$id,
            name: obj.path,
            created: false,
            new: true,
          };
        }
        return obj;
      });

      // Give the consumer a "tentative" file record
      setFiles(newFilesRef.current);
      if (initializeProgress) {
        initializeProgress(createFiles);
      }
      // Now reach out to the API to create the appropriate files,
      const failedIds = new Set();
      await Promise.allSettled(
        createFiles.map(async (file) => {
          try {
            const fileRecord = await uploadFile(file);
            newFilesRef.current = newFilesRef.current.map((current) => {
              return current.id === fileRecord.id
                ? { ...fileRecord, new: true }
                : current;
            });
            setFiles(newFilesRef.current);
          } catch (err) {
            failedIds.add(file.$id);
          }
        })
      );

      if (failedIds.size) {
        newFilesRef.current = newFilesRef.current.filter(
          (current) => !failedIds.has(current.id)
        );
      }

      setFiles(newFilesRef.current);
      return newFilesRef.current;
    },
    [initializeProgress, uploadFile, setFiles]
  );

  const updateFile = useCallback(
    async (newFile, params) => {
      const currentFiles = files;
      const index = currentFiles.findIndex(({ id }) => id === newFile.id);
      const fileStore = currentFiles[index];
      try {
        if (index !== -1) {
          const updated = { ...currentFiles[index], ...params };
          currentFiles.splice(index, 1, updated);
          setFiles([...currentFiles]);
          await FileService.update({ id: newFile.id, params });
          return updated;
        }
      } catch (error) {
        if (index !== -1) {
          currentFiles.splice(index, 1, fileStore);
          setFiles([...currentFiles]);
        }
      }
    },
    [files, setFiles]
  );

  const deselectFile = useCallback(
    (file) => {
      setFiles((prev) => prev.filter((f) => f.id !== file.id));
    },
    [setFiles]
  );

  const sortedFiles = useMemo(() => {
    // If the files are infinity scrollable, we want to show the new files first
    // If not, we want to show the new files last
    // Note: reverse was used for some old logic, but we should consider using the
    // infinity scrollable flag to determine the order of the files
    const filteredFiles = files.filter(({ created }) => created);
    return isInfinityScrollable
      ? filteredFiles
      : filteredFiles.sort(
          ({ created: a }, { created: b }) => Date.parse(b) - Date.parse(a)
        );
  }, [files, isInfinityScrollable]);

  return [
    sortedFiles,
    { addFiles, deselectFile, setFiles, updateFile, deleteFile },
  ];
};
