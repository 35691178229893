import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';

export const Container = styled.div`
  display: flex;
  height: 100%;
  margin: 0 5px;

  ${({ invert }) =>
    invert &&
    css`
      flex-direction: column;
    `}

  ${({ mobile }) =>
    mobile
      ? css`
          margin-left: -15px;
          margin-right: -15px;
        `
      : ''}
`;

export const RightColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  overflow: hidden;
  ${({ invert }) =>
    invert &&
    css`
      height: 50%;
      width: 100%;
    `}

  ${({ mobile }) =>
    mobile
      ? css`
          padding: 0 15px;
          margin-bottom: -15px;
        `
      : ''}
`;

export const ChartArea = styled.div`
  width: calc(100% + 45px);
  flex-grow: 1;
  margin-left: -10px;
`;

export const Divider = styled.div`
  padding: 0 0 0 20px;
  height: 100%;
  display: flex;
  i,
  i:hover {
    color: ${colorsButton.blue.default};
  }
  ${({ invert }) =>
    invert &&
    css`
      height: unset;
      padding: 10px 0 20px 0;
      & i {
        margin: 0 auto;
      }
    `}

  ${({ mobile }) =>
    mobile
      ? css`
          padding-top: 7px;
          padding-bottom: 15px;
        `
      : ''}
`;

export const HeaderText = styled.div`
  text-align: center;
  padding-bottom: 20px;
  padding: 0 20px 13px 20px;

  ${({ mobile }) =>
    mobile
      ? css`
          padding-bottom: 9px;
        `
      : ''}
`;

export const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;
