import { SyntheticEvent, useContext } from 'react';
import {
  StyledSubNavigationNavBar,
  StyledSubNavigationNavLink,
} from './styles';
import { SMART_CONNECTOR_STEPS } from '../constants';
import { steps } from './steps';
import { SmartConnectorContext } from './context';

export const SubNavigation = () => {
  const { handleGoToStep, step, isNew, smartConnector } = useContext(
    SmartConnectorContext
  );

  return (
    <StyledSubNavigationNavBar>
      {Object.values(SMART_CONNECTOR_STEPS).map((_step, idx) => {
        const onClick = (event: SyntheticEvent) => {
          event.preventDefault();
          handleGoToStep(_step);
        };

        return (
          <StyledSubNavigationNavLink
            key={_step + idx}
            disabled={
              (_step !== step && isNew) ||
              (_step === SMART_CONNECTOR_STEPS.variables &&
                (!smartConnector.source_file ||
                  (smartConnector.sql_enabled && !smartConnector.live_script)))
            }
            icon={`circle-${idx + 1}`}
            route={steps[_step]}
            onClick={onClick}
            active={_step === step}
          />
        );
      })}
    </StyledSubNavigationNavBar>
  );
};
