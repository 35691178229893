import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { ChartLayout, Chart, StyledLoader } from '../Trend/styles';
import TrendWithPercentChartManager from './TrendWithPercentChartManager';
import {
  DISPLAY_VALUE_RESPONSES,
  REPORT_TYPES,
  VALUE_TO_DISPLAY_RESPONSES,
} from 'components/Wizards/Dashlet/types';
import { useTranslation } from 'react-i18next';
import NoData from '../components/NoData';
import { StyledNoData } from '../components/NoData/styles';
import usePipelineMetadata from 'pages/Dashboard/hooks/usePipelineMetadata';
import { getPendingDefaultValue } from '../helpers';
import { isEqual } from 'lodash';
import { sanitizeTextForCharts } from '../ChartManager';

const TrendChartWithPercent = ({ data, dashlet, isLoading, mobile }) => {
  const { t } = useTranslation();
  const chartRef = useRef();

  const [chartRenderKey, setChartRenderKey] = useState(() => Date.now());
  const [chartPending, setChartPending] = useState(getPendingDefaultValue);
  const [chartRendered, setChartRendered] = useState(false);
  const chartData = useMemo(() => {
    if (data.data) {
      const result = data.data.find(
        (d) => d.type !== 'percentage_change_over_time'
      )?.values;
      return result ?? [];
    }
    return [];
  }, [data]);

  const secondaryData = useMemo(() => {
    if (data.data) {
      const result = data.data.find(
        (d) => d.type === 'percentage_change_over_time'
      )?.values;

      return result ?? [];
    }
    return [];
  }, [data]);

  const frequency = data?.frequency;

  const sanitizedTooltipLabels = useMemo(() => {
    return (
      data?.metadata?.tooltip_label?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.date]: sanitizeTextForCharts(curr.label),
        };
      }, {}) ?? {}
    );
  }, [data]);

  const [pipeline, currencySymbol] = usePipelineMetadata(data);

  const isAdded = dashlet?.config?.reportType === REPORT_TYPES.RECORDS_ADDED;
  const isWeighted = dashlet?.config?.metricType?.includes('weighted');
  const isValue =
    dashlet?.config?.metricType === DISPLAY_VALUE_RESPONSES.VALUE ||
    dashlet?.config?.metricType === VALUE_TO_DISPLAY_RESPONSES.WEIGHTED;

  useEffect(() => {
    if (chartRef.current) {
      const manager = new TrendWithPercentChartManager(
        chartRef.current,
        t,
        chartData,
        secondaryData,
        {
          currencySymbol,
          isValue,
          isAdded,
          pipeline,
          isWeighted,
          mobile,
          useAllBullets: true,
          frequency,
          tooltipLabels: sanitizedTooltipLabels,
          currencyMode: isValue,
        },
        () => {
          setChartPending(false);
          setChartRendered(true);
        }
      );

      manager.init();

      return manager.destroy;
    }
  }, [
    chartData,
    secondaryData,
    currencySymbol,
    isAdded,
    pipeline,
    chartRenderKey,
    isLoading,
    mobile,
    isWeighted,
    isValue,
    frequency,
    sanitizedTooltipLabels,
    t,
  ]);

  const createChart = useCallback((elem) => {
    if (elem) {
      chartRef.current = elem;
      setChartRenderKey(Date.now());
    }
  }, []);

  return (
    <ChartLayout>
      {/*
          This absolutely positioned loader is used to keep the chart area hidden until the calculations
          and rendering are done, as they can be quite slow for large datasets and will just
          show a blank white dashlet otherwise
        */}
      <StyledLoader loading={chartPending || isLoading} />
      {chartData.length === 0 ? (
        <StyledNoData>
          <NoData data-qa-dashlet-id={dashlet.id} data-qa-no-data />
        </StyledNoData>
      ) : null}
      <Chart
        ref={createChart}
        data-qa-chart="trend-with-percent"
        data-qa-chart-pending={chartPending || isLoading || !chartRendered}
      />
    </ChartLayout>
  );
};

TrendChartWithPercent.propTypes = {
  data: PropTypes.object,
  dashlet: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  mobile: PropTypes.bool,
};

TrendChartWithPercent.defaultProps = {
  data: { data: [] },
  isLoading: true,
  mobile: false,
};

const propsAreEqual = (prevProps, nextProps) => {
  const dataEqual = isEqual(prevProps.data, nextProps.data);
  const dashletEqual = isEqual(prevProps.dashlet, nextProps.dashlet);
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const mobileEqual = prevProps.mobile === nextProps.mobile;

  return dataEqual && dashletEqual && isLoadingEqual && mobileEqual;
};

export default React.memo(TrendChartWithPercent, propsAreEqual);
