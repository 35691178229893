import React from 'react';
import FileLibraryModal from 'components/Inputs/FileInput/Modal';
import { PUBLIC_IMAGE_FILE_UPLOAD_SOURCE } from 'hooks/uploadFiles/useUploadFile';

export const FileLibrary = ({ show, onConfirm, onHide }) => {
  if (!show) {
    return null;
  }

  return (
    <FileLibraryModal
      key="real-picker"
      publicFile={true}
      show
      onChange={onConfirm}
      onHide={onHide}
      source={PUBLIC_IMAGE_FILE_UPLOAD_SOURCE}
    />
  );
};
