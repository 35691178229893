import { useMemo } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import Tooltip from 'components/Kizen/Tooltip';
import { Time } from 'components/Board/BaseCard';
import { convertTime } from './helpers';

const popperConfig = {
  modifiers: {
    preventOverflow: {
      enabled: true,
      boundariesElement: 'viewport',
    },
  },
};

export const TimeInStage = ({ time = 0 }) => {
  const { t } = useTranslation();
  const [timeShort, timeLong] = useMemo(() => {
    return convertTime(time, t);
  }, [time, t]);
  return (
    <OverlayTrigger
      overlay={
        <Tooltip
          label={
            <>
              <KizenTypography size="small" color="white">
                {t('Time in Stage')}
              </KizenTypography>
              <KizenTypography size="small" color="white">
                {timeLong}
              </KizenTypography>
            </>
          }
        />
      }
      placement="top"
      popperConfig={popperConfig}
    >
      <Time type="small" as="span">
        {timeShort}
      </Time>
    </OverlayTrigger>
  );
};
