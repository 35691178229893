import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useToggle } from 'react-use';
import { useActivityRulesContext } from 'ts-activities/ActivityRulesContext';

import useModal from 'components/Modals/useModal';
import ActivityBuilderPreview from 'components/Modals/ActivityBuilderPreview';
import { DesktopOnlyButton } from 'pages/Common/styles/ControlBar';
import { BackBreadcrumbToolbar } from 'components/BackBreadcrumbToolbar';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import ConfirmNavigationModal from 'components/Modals/presets/ConfirmNavigation';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import ActivityService from 'services/ActivityService';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useActivitySharingContext } from 'ts-activities/ActivitySharingContext';
import { getOriginalError } from 'services/AxiosService';
const ToolbarButtonsContainer = styled.div`
  display: flex;
`;

const RightButton = styled(DesktopOnlyButton)`
  margin-right: 0;
`;

export const NAVIGATE_AWAY_MESSAGE = (t) =>
  t('Please name your activity prior to leaving this page.');

export const NAVIGATE_AWAY_URL_MESSAGE = (t) =>
  t('Please enter a valid URL prior to leaving this page.');
export const ControlBarControl = ({ activity, setErrorMessages, name }) => {
  const { subpage } = useParams();
  if (subpage === 'rules') {
    return <RulesControlBar />;
  }
  if (subpage === 'team-sharing-settings') {
    return <TeamSharingSettingsControlBar />;
  }
  return (
    <ControlBar
      name={name}
      setErrorMessages={setErrorMessages}
      activity={activity}
    />
  );
};
export const ControlBar = ({ activity, setErrorMessages }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [showToast] = useToast();
  const [showPreview, togglePreview] = useToggle(false);

  const [confirmDeleteModalProps, , confirmDeleteModal] = useModal({
    handleSubmit: async () => {
      await ActivityService.v2DeleteActivity({
        activityObjectId: activity.id,
      });
      setErrorMessages({});
      history.replace('/activities');
    },
  });

  const handleExportResponses = async ({ id: activityObjectId, name }) => {
    try {
      await ActivityService.v2ExportActivities({
        activityObjectId,
      });
      const message = t(
        `Responses were queued for export successfully. You will be emailed a download link once complete`
      );
      showToast({
        variant: toastVariant.SUCCESS,
        message,
      });
    } catch (error) {
      const orig = getOriginalError(error);
      const message =
        orig?.message ||
        t(
          'The export was not successful. Please try again or contact Kizen support.'
        );
      showToast({
        variant: toastVariant.FAILURE,
        message,
      });
    }
  };

  return (
    <>
      <ContentWidth></ContentWidth>
      <BackBreadcrumbToolbar
        backLabel={t('back to activities')}
        goBackPath={'/activities'}
      >
        <ToolbarButtonsContainer>
          <DesktopOnlyButton onClick={confirmDeleteModal.show} color="red">
            {t('Delete')}
          </DesktopOnlyButton>
          <DesktopOnlyButton color="blue" onClick={togglePreview}>
            {t('Preview')}
          </DesktopOnlyButton>
          <RightButton
            color="blue"
            onClick={() => handleExportResponses(activity)}
          >
            {t('Export Responses')}
          </RightButton>
        </ToolbarButtonsContainer>
      </BackBreadcrumbToolbar>
      <ConfirmDeletionModal {...confirmDeleteModalProps}>
        {t('This will permanently delete the Activity.')}
      </ConfirmDeletionModal>
      {showPreview && (
        <ActivityBuilderPreview
          activity={activity}
          onHide={togglePreview}
          actionBtnColor="blue"
        />
      )}
    </>
  );
};

export const RulesControlBar = () => {
  const { t } = useTranslation();
  const { activity, hasEdits, handleSave } = useActivityRulesContext();
  const [showPreview, togglePreview] = useToggle(false);

  const handleSaveAndPreview = async () => {
    if (await handleSave()) {
      togglePreview();
    }
  };

  return (
    <div>
      <BackBreadcrumbToolbar
        backLabel={t('back to activities')}
        goBackPath={'/activities'}
      >
        <ToolbarButtonsContainer>
          <DesktopOnlyButton color="green" onClick={handleSave}>
            {t('Save')}
          </DesktopOnlyButton>
          <RightButton color="blue" onClick={handleSaveAndPreview}>{`${t(
            'Save'
          )} & ${t('Preview')}`}</RightButton>
        </ToolbarButtonsContainer>
      </BackBreadcrumbToolbar>
      <ConfirmNavigationModal
        when={hasEdits}
        whenCondition={({ location }) => !location.pathname.endsWith('rules')}
      />
      {showPreview && (
        <ActivityBuilderPreview
          activity={activity}
          onHide={togglePreview}
          actionBtnColor="blue"
        />
      )}
    </div>
  );
};
export const TeamSharingSettingsControlBar = () => {
  const { t } = useTranslation();
  const { activity, hasEdits, handleSave, saving } =
    useActivitySharingContext();
  const [showPreview, togglePreview] = useToggle(false);
  const [showToast] = useToast();

  const handleExportResponses = async ({ id: activityObjectId, name }) => {
    try {
      await ActivityService.v2ExportActivities({
        activityObjectId,
      });
      const message = t(
        `Responses were queued for export successfully. You will be emailed a download link once complete`
      );
      showToast({
        variant: toastVariant.SUCCESS,
        message,
      });
    } catch (error) {
      const orig = getOriginalError(error);
      const message =
        orig?.message ||
        t(
          'The export was not successful. Please try again or contact Kizen support.'
        );
      showToast({
        variant: toastVariant.FAILURE,
        message,
      });
    }
  };
  return (
    <div>
      <BackBreadcrumbToolbar
        backLabel={t('back to activities')}
        goBackPath={'/activities'}
      >
        <ToolbarButtonsContainer>
          <DesktopOnlyButton
            color="green"
            onClick={handleSave}
            loading={saving}
          >
            {t('Save')}
          </DesktopOnlyButton>
          <DesktopOnlyButton color="blue" onClick={togglePreview}>
            {t('Preview')}
          </DesktopOnlyButton>
          <RightButton
            color="blue"
            onClick={() => handleExportResponses(activity)}
          >
            {t('Export Responses')}
          </RightButton>
        </ToolbarButtonsContainer>
      </BackBreadcrumbToolbar>
      <ConfirmNavigationModal
        when={hasEdits}
        whenCondition={({ location }) =>
          !location.pathname.endsWith('team-sharing-settings')
        }
      />
      {showPreview && (
        <ActivityBuilderPreview
          activity={activity}
          onHide={togglePreview}
          actionBtnColor="blue"
        />
      )}
    </div>
  );
};
