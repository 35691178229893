import { Panel, VerticalTile } from '@kizen/kds/Tile';
import __DashletError from 'pages/Dashboard/components/DashletError';
import { ComponentType } from 'react';
import __ErrorBoundary from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

const ErrorBoundary = __ErrorBoundary as any;
const DashletError = __DashletError as any;

// For now, not all of our blocks have fixed height, so we need to infer some values
// based on the block type.
const heightsByType: Record<string, number> = {
  lead_sources: 164,
  team_and_activities: 140,
  action_block: 367,
  related_pipelines: 150,
};

interface FallbackProps {
  metadata?: { tableHeight?: number; blockHeight?: number };
  blockType: string;
}

const Fallback = ({ metadata, blockType }: FallbackProps) => {
  const height =
    metadata?.tableHeight ??
    metadata?.blockHeight ??
    heightsByType[blockType] ??
    200;

  const { t } = useTranslation();

  return (
    <VerticalTile
      padding={0}
      gap={0}
      middle={
        <Panel>
          <div
            className="kds h-[var(--block-chosen-height)] px-spacer-20"
            style={
              {
                '--block-chosen-height': `${height}px`,
              } as Record<string, string>
            }
          >
            <DashletError
              override={t(
                'There was an error loading this block. Please reload the page or contact support if the problem persists.'
              )}
            />
          </div>
        </Panel>
      }
    />
  );
};

export const withErrorBoundary = (Component: ComponentType<any>) => {
  return (props: any) => {
    return (
      <ErrorBoundary
        FallbackComponent={() => (
          <Fallback metadata={props.metadata} blockType={props.blockType} />
        )}
      >
        <Component {...props} />
      </ErrorBoundary>
    );
  };
};
