import React, { forwardRef, useImperativeHandle } from 'react';
import { Element, Frame as FrameBase, useEditor } from '@craftjs/core';
import { Root } from './nodes';

export const Frame = forwardRef(({ children, data }, ref) => {
  const editor = useEditor();
  useImperativeHandle(ref, () => editor, [editor]);

  return (
    <FrameBase data={data}>
      <Element canvas is={Root}>
        {children}
      </Element>
    </FrameBase>
  );
});
