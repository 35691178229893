export const FILTER_TYPES = {
  CUSTOM: 'custom_filter',
  IN_GROUP: 'in_group',
  NOT_IN_GROUP: 'not_in_group',
};

export const isGroupFilter = (type) =>
  [FILTER_TYPES.IN_GROUP, FILTER_TYPES.NOT_IN_GROUP].includes(type);

export const isCustomFilter = (type) => type === FILTER_TYPES.CUSTOM;
export const isInGroupFilter = (type) => type === FILTER_TYPES.IN_GROUP;
export const isNotInGroupFilter = (type) => type === FILTER_TYPES.NOT_IN_GROUP;

export const isGroupInvalid = (groups) => {
  if (!groups || groups.length === 0) {
    return true;
  }

  return groups.some(({ deleted, invalid }) => deleted || invalid);
};
