import {
  isOwnerField,
  isPercentageChanceToCloseField,
  isStageField,
} from 'checks/fields';

export const getPercentageChanceToCloseTooltip = (t) =>
  t(
    'This field autocalculates based on the stage of the record. Editing this will override the inherited value.'
  );

const getStage = (stageField, entity) => {
  return stageField?.options?.find((o) => o.id === entity.stage?.value);
};

const getChanceToCloseFromStage = (stage) => {
  return stage?.percentageChanceToClose;
};

const getShouldShowPlaceholder = (field, entity) => {
  const isPercentageField = isPercentageChanceToCloseField(field);

  return (
    isPercentageField &&
    !entity?.percentageChanceToClose &&
    entity?.percentageChanceToClose !== 0
  );
};

const readOnlyValues = ['won', 'lost', 'disqualified'];

const isReadOnly = (recordStatus, stage) => {
  return (
    readOnlyValues.includes(recordStatus) ||
    readOnlyValues.includes(stage?.status)
  );
};

const isWon = (recordStatus, stage) => {
  return recordStatus === 'won' || stage?.status === 'won';
};

export const getPercentageChanceToCloseProps = (
  field,
  fields,
  entity,
  t,
  recordStatus
) => {
  const stageField = fields?.find(isStageField);
  const stage = getStage(stageField, entity);
  const shouldShowPlaceholder = getShouldShowPlaceholder(field, entity);

  if (!isPercentageChanceToCloseField(field)) {
    return {};
  }

  const percentChanceToClosePlaceholder = shouldShowPlaceholder
    ? getChanceToCloseFromStage(stage)
    : null;

  if (isReadOnly(recordStatus, stage)) {
    return {
      placeholder: percentChanceToClosePlaceholder,
      readOnly: true,
      value: isWon(recordStatus, stage) ? 100 : 0,
    };
  }

  return {
    placeholder: percentChanceToClosePlaceholder,
    info: shouldShowPlaceholder ? getPercentageChanceToCloseTooltip(t) : null,
    readOnly: false,
  };
};
const MILLISECONDS_IN_MINUTE = 60000;
const MINUTES_IN_HOUR = 60;
const MILLISECONDS_IN_HOUR = 3600000;
const HOURS_IN_DAY = 24;
const DAYS_IN_MONTH = 30;
const MONTHS_IN_YEAR = 12;
const ROUND_TO_DECIMAL_PLACES = 1;

const roundToFixed = (num, decimalPlaces = ROUND_TO_DECIMAL_PLACES) =>
  Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces;

// We need to show the time in the stage in a short format (e.g. 1.5d,  2hr, 5.2mo)
// where only the most significant unit (greater then 1) is shown and is rounded to 1 decimal place
// and a long format (e.g. 1d 2hr, 1yr 2mo 3d 4.5hr)
// where all units that are greater then 1 are shown and only hours are rounded to 1 decimal place

export const convertTimeToMinutes = (time = 0, t) => {
  const minutes = time / MILLISECONDS_IN_MINUTE;
  const roundMonutes = roundToFixed(minutes, 0);
  if (roundMonutes < MINUTES_IN_HOUR) {
    const fallbackMinutes = t('{{minutes}}min', {
      minutes: minutes < 1 ? '< 1' : roundMonutes,
    });
    return [fallbackMinutes, fallbackMinutes];
  }

  return convertTime(time, t);
};

export const convertTime = (time = 0, t) => {
  const hours = time / MILLISECONDS_IN_HOUR;
  const roundHours = roundToFixed(hours);
  if (roundHours < HOURS_IN_DAY) {
    const fallbackHours = t('{{hours}}hr', {
      hours: hours < 0.1 ? '< 0.1' : roundHours,
    });
    return [fallbackHours, fallbackHours];
  }
  let periodLong = t('{{hours}}hr', {
    hours: Math.floor((hours % HOURS_IN_DAY) * 10) / 10,
  });

  const days = hours / HOURS_IN_DAY;
  const roundDays = roundToFixed(days);
  if (roundDays < DAYS_IN_MONTH) {
    return [
      t('{{days}}d', { days: roundDays }),
      days >= 1
        ? `${t('{{days}}d', {
            days: Math.floor(days),
          })} ${periodLong}`
        : periodLong,
    ];
  }
  const floorDays = Math.floor(days % DAYS_IN_MONTH);
  if (floorDays >= 1) {
    periodLong = `${t('{{days}}d', { days: floorDays })} ${periodLong}`;
  }

  const months = days / DAYS_IN_MONTH;
  const roundMonth = roundToFixed(months);
  if (roundMonth < MONTHS_IN_YEAR * 2) {
    return [
      t('{{months}}mo', { months: roundMonth }),
      months >= 1
        ? `${t('{{months}}mo', { months: Math.floor(months) })} ${periodLong}`
        : periodLong,
    ];
  }
  const floorMonth = Math.floor(months % MONTHS_IN_YEAR);
  if (floorMonth >= 1) {
    periodLong = `${t('{{months}}mo', { months: floorMonth })} ${periodLong}`;
  }

  const years = months / MONTHS_IN_YEAR;
  const roundYears = roundToFixed(years);
  return [
    t('{{years}}yr', { years: roundYears }),
    years >= 1
      ? `${t('{{years}}yr', { years: Math.floor(years) })} ${periodLong}`
      : periodLong,
  ];
};

export const getMenuOptions = (canChange, canArchive, t) => {
  return [
    canChange && { value: 'changeStage', label: t('Change Stage') },
    { value: 'moveToTop', label: t('Move To Top') },
    { value: 'moveToBottom', label: t('Move To Bottom') },
    canArchive && { value: 'archive', label: t('Archive') },
  ].filter(Boolean);
};

export const getPatchData = (patch, field) => {
  const value = Object.values(patch)[0];

  if (isOwnerField(field)) {
    return { ownerId: value.id };
  }

  if (isStageField(field)) {
    return { stageId: value };
  }

  return patch;
};
