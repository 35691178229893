import { Dispatch, SetStateAction, createContext } from 'react';
import {
  SmartConnector,
  SmartConnectorBase,
  SmartConnectorFlow,
  SmartConnectorMetaData,
  PrimaryObjectUploadStep,
  CustomObjectLike,
  SmartConnectorFlowErrors,
  SmartConnectorVariable,
} from '__pages/SmartConnectors/types';
import {
  PRIMARY_OBJECT_UPLOAD_STEPS,
  VARIABLE_DATA_TYPES,
} from '__pages/SmartConnectors/constants';
import { defaultSmartConnector } from '../../context';

export const defaultSmartConnectorFlow: SmartConnectorFlow = {
  additional_variables: [],
  loads: [],
};

export type PrimaryObjectUploadContextType = {
  smartConnector: SmartConnectorBase | SmartConnector;
  customObject?: CustomObjectLike;
  loadId: string;
  loading: boolean;
  refreshing: boolean;
  isNew: boolean;
  step: PrimaryObjectUploadStep;
  setStep: Dispatch<SetStateAction<PrimaryObjectUploadStep>>;
  nextStep: PrimaryObjectUploadStep;
  setNextStep: Dispatch<SetStateAction<PrimaryObjectUploadStep>>;
  handleGoToStep: (step: PrimaryObjectUploadStep) => void;
  handleSave: (withClose: boolean) => Promise<boolean>;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isValid: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  stepData: Partial<SmartConnectorFlow>;
  setStepData: Dispatch<SetStateAction<Partial<SmartConnectorFlow>>>;
  errors: SmartConnectorFlowErrors;
  setErrors: Dispatch<SetStateAction<SmartConnectorFlowErrors>>;
  metaData: SmartConnectorMetaData;
  allVariables: SmartConnectorVariable[];
  variablesById: Record<string, SmartConnectorVariable>;
};

export const defaultPrimaryObjectUploadContext: PrimaryObjectUploadContextType =
  {
    smartConnector: defaultSmartConnector,
    loadId: 'new',
    loading: true,
    refreshing: false,
    step: PRIMARY_OBJECT_UPLOAD_STEPS.matching,
    isNew: true,
    setStep: () => {},
    nextStep: PRIMARY_OBJECT_UPLOAD_STEPS.matching,
    setNextStep: () => {},
    handleGoToStep: () => {},
    handleSave: () => Promise.resolve(true),
    isDirty: false,
    setIsDirty: () => {},
    stepData: {},
    setStepData: () => {},
    errors: {},
    setErrors: () => {},
    isValid: true,
    setIsValid: () => {},
    metaData: {
      execution_variables: {
        variable_data_types: [],
        array_delimiter: [],
        default_array_delimiter: ',',
        default_variable_data_type: VARIABLE_DATA_TYPES.string,
      },
      field_mapping_rules: {
        conflict_resolution: [],
        field_type_meta: {},
        variable_to_field_matching: {
          string: {
            array: {},
            single: {},
          },
        },
      },
      matching_rules: {
        match_archive_actions: [],
        multiple_match_actions: [],
        no_match_actions: [],
        single_match_actions: [],
      },
    },
    allVariables: [],
    variablesById: {},
  };

export const PrimaryObjectUploadContext =
  createContext<PrimaryObjectUploadContextType>(
    defaultPrimaryObjectUploadContext
  );
