import AxiosService from './AxiosService';
import {
  snakeToCamelCaseKeys,
  camelToSnakeCaseKeys,
  getOptionValue,
  namedToOption,
} from './helpers';
import { SENDER_TYPE } from 'components/MessageBuilder/components';

const addKznFiltersToCraftJson = (craftJson, kznFilters) => {
  return {
    ...craftJson,
    ...(Object.keys(kznFilters).length > 0 && {
      kzn_filters: kznFilters,
    }),
  };
};

// unify template sending object
export const templateForApi = (template, preserveAutomationStep = false) => {
  return {
    isBulk: false,
    // This needs to be omitted during update / PATCH
    baseMessageId: template.baseMessageId || null,
    senderRoleId:
      template?.sender?.type === SENDER_TYPE.LAST_ROLE
        ? template.sender.id
        : undefined,
    senderTeamMemberId:
      template?.sender?.type === SENDER_TYPE.TEAM_MEMBER
        ? template.sender.id
        : undefined,
    senderFieldId:
      template?.sender?.type === SENDER_TYPE.TEAM_MEMBER_FIELD
        ? template.senderField.value
        : undefined,
    externalAccountId:
      template?.sender?.type === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX
        ? template.sender.id
        : undefined,
    senderType: template?.sender?.type,
    fromNameType: template?.sender?.customName ? 'custom' : 'default',
    customFromName: template?.sender?.customName || '',
    content: template.htmlContent,
    craftJson: template.craftJsContent,
    kznFilters: template.kznFilters,
    type:
      template?.sender?.type === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX
        ? 'integrated_inbox_email'
        : 'email',
    name: template.name,
    subject: template.subject,
    id: template.id,
    automationStep: preserveAutomationStep
      ? template.automationStep
      : undefined,
  };
};

export const broadcastForApi = (template) => {
  return {
    baseMessageId: template.baseMessageId || null,
    senderRoleId:
      template?.sender?.type === SENDER_TYPE.LAST_ROLE
        ? template.sender.id
        : undefined,
    senderTeamMemberId:
      template?.sender?.type === SENDER_TYPE.TEAM_MEMBER
        ? template.sender.id
        : undefined,
    senderFieldId:
      template?.sender?.type === SENDER_TYPE.TEAM_MEMBER_FIELD
        ? template.senderField.value
        : undefined,
    externalAccountId:
      template?.sender?.type === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX
        ? template.sender.id
        : undefined,
    senderType: template?.sender?.type,
    fromNameType: template?.sender?.customName ? 'custom' : 'default',
    customFromName: template?.sender?.customName || '',
    content: template.htmlContent,
    craftJson: template.craftJsContent,
    kznFilters: template.kznFilters,
    type:
      template?.sender?.type === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX
        ? 'integrated_inbox_email'
        : 'email',
    name: template.name,
    subject: template.subject,
    id: template.id,
    scheduleType: 'scheduled',
  };
};

export const templateForApp = (template, preserveBaseMessageId = false) => {
  return {
    ...template,
    baseMessageId: preserveBaseMessageId ? template.baseMessageId : template.id,
    craftJsContent: template.craftJson,
    htmlContent: template.content,
    sender: {
      id:
        (template.senderRole && getOptionValue(template.senderRole)) ||
        (template.senderTeamMember &&
          getOptionValue(template.senderTeamMember)) ||
        (template.externalAccount &&
          getOptionValue(template.externalAccount)) ||
        null,
      type: template.senderType,
      customName: template.customFromName,
    },
  };
};

export const broadcastForApp = (template) => {
  const { kzn_filters, ...restJson } = template.craftJson || {};
  return {
    ...template,
    craftJsContent: restJson,
    htmlContent: template.content,
    kznFilters: kzn_filters,
    sender: {
      id:
        (template.senderRole && getOptionValue(template.senderRole)) ||
        (template.senderTeamMember &&
          getOptionValue(template.senderTeamMember)) ||
        null,
      type: template.senderType,
      customName: template.customFromName,
    },
    ...(template.senderTeamMember
      ? {
          senderTeamMember: {
            ...template.senderTeamMember,
            fullName: [
              template.senderTeamMember.firstName,
              template.senderTeamMember.lastName,
            ]
              .filter(Boolean)
              .join(' '),
          },
        }
      : {}),
    ...(template.senderRole
      ? {
          senderRole: namedToOption(template.senderRole),
        }
      : {}),
  };
};

class LibraryService {
  constructor() {
    if (!LibraryService.instance) {
      LibraryService.instance = this;
    }
    return LibraryService.instance;
  }

  endpoint = '/messages';

  getAllTemplates = async ({ page = {}, ordering, type }) => {
    const params = {
      ordering,
      page: page.number || 1,
      page_size: page.size || 50,
      type,
      search: page.search,
    };
    const { data } = await AxiosService.get(`${this.endpoint}/templates`, {
      params,
    });
    return snakeToCamelCaseKeys(data);
  };

  getTemplateFullList = async (params) => {
    const getData = async (params) => {
      const data = await this.getAllTemplates(params);
      return data.next
        ? [
            ...data.results,
            ...(await getData({
              ...params,
              page: { ...params.page, number: params.page.number + 1 },
            })),
          ]
        : data.results;
    };

    return await getData({ ...params, page: { ...params.page, number: 1 } });
  };

  getAllBroadcastsInFolder = async ({
    folderId = null,
    page = {},
    ordering,
    type,
  }) => {
    const params = {
      folder_id: folderId,
      ordering,
      page: page.number || 1,
      page_size: page.size || 50,
      type,
      search: page.search,
    };
    const { data } = await AxiosService.get(`${this.endpoint}/broadcasts`, {
      params,
    });
    return snakeToCamelCaseKeys(data);
  };

  getBroadcastById = async ({ id }) => {
    const { data } = await AxiosService.get(
      `${this.endpoint}/broadcasts/${id}`
    );
    return snakeToCamelCaseKeys(data);
  };

  getScheduledBroadcastById = async ({ id }) => {
    const { data } = await AxiosService.get(
      `${this.endpoint}/broadcasts/${id}`,
      { skipErrorBoundary: true }
    );

    const externalAccount = data?.external_account
      ? {
          ...data?.external_account,
          value: data?.external_account.id,
        }
      : {};

    return {
      ...snakeToCamelCaseKeys(data),
      craftJson: data.craft_json,
      externalAccount,
    };
  };

  getAllAutomations = async ({ page = {}, ordering }) => {
    const params = {
      ordering,
      page: page.number || 1,
      page_size: page.size || 50,
      search: page.search,
    };
    const { data } = await AxiosService.get(`${this.endpoint}/automations`, {
      params,
    });
    //result should be returned as folder
    return snakeToCamelCaseKeys({
      ...data,
      results: data.results.map((e) => ({ ...e, type: 'folder' })),
    });
  };

  getAllAutomationsInFolder = async ({ id = null, page = {}, ordering }) => {
    const params = {
      ordering,
      search: page.search,
      page: page.number || 1,
      page_size: page.size || 50,
    };
    const { data } = await AxiosService.get(
      `${this.endpoint}/automations/automation/${id}`,
      {
        params,
      }
    );
    return snakeToCamelCaseKeys(data);
  };

  createAutomationMessage = async (
    automationId,
    { craftJson, kznFilters, ...body },
    options
  ) => {
    const { data } = await AxiosService.post(
      `${this.endpoint}/automations/automation/${automationId}`,
      {
        ...camelToSnakeCaseKeys(body),
        craft_json: addKznFiltersToCraftJson(craftJson, kznFilters),
      },
      options
    );
    const { craft_json, ...rest } = data;
    return { ...snakeToCamelCaseKeys(rest), craftJson: craft_json };
  };

  createTemplateMessage = async (template) => {
    const { craftJson } = template;
    const { data } = await AxiosService.post(`${this.endpoint}/templates`, {
      ...camelToSnakeCaseKeys(template),
      // needs to be avoided from snakeToCamelCaseKeys/camelToSnakeCaseKeys conversion
      // because this object contain randomly generated keys
      craft_json: addKznFiltersToCraftJson(craftJson, template.kznFilters),
    });

    return {
      ...snakeToCamelCaseKeys(data),
      // needs to be avoided from snakeToCamelCaseKeys/camelToSnakeCaseKeys conversion
      // because this object contain randomly generated keys
      craftJson: data.craft_json,
    };
  };

  getTemplateById = async ({ id }) => {
    const { data } = await AxiosService.get(`${this.endpoint}/templates/${id}`);
    const { craft_json: craftJson } = data;
    return templateForApp({
      ...snakeToCamelCaseKeys(data),
      // needs to be avoided from snakeToCamelCaseKeys/camelToSnakeCaseKeys conversion
      // because this object contain randomly generated keys
      craftJson,
    });
  };

  updateTemplate = async (params) => {
    params = { ...params };
    // MUST be not present during PATCH
    delete params.baseMessageId;
    const { craftJson } = params;
    const { data } = await AxiosService.patch(
      `${this.endpoint}/templates/${params.id}`,
      {
        ...camelToSnakeCaseKeys(params),
        // needs to be avoided from snakeToCamelCaseKeys/camelToSnakeCaseKeys conversion
        // because this object contain randomly generated keys
        craft_json:
          craftJson && addKznFiltersToCraftJson(craftJson, params?.kznFilters),
      }
    );
    return snakeToCamelCaseKeys(data);
  };

  updateBroadcast = async ({ id, ...rest }) => {
    const { data } = await AxiosService.patch(
      `${this.endpoint}/broadcasts/${id}`,
      camelToSnakeCaseKeys(rest)
    );
    return snakeToCamelCaseKeys({ id, ...data });
  };

  updateAutomationName = async ({ id, ...rest }) => {
    const { data } = await AxiosService.patch(
      `${this.endpoint}/automations/${id}`,
      camelToSnakeCaseKeys(rest)
    );
    return snakeToCamelCaseKeys({ id, ...data });
  };

  delete = async (id) => {
    const { data } = await AxiosService.delete(
      `${this.endpoint}/templates/${id}`
    );
    return snakeToCamelCaseKeys(data);
  };

  duplicate = async ({ id }) => {
    const { data } = await AxiosService.post(
      `${this.endpoint}/templates/${id}/duplicate`
    );
    return snakeToCamelCaseKeys(data);
  };

  getTemplateByTypeId = async ({ type, id }) => {
    const { data } = await AxiosService.get(`${this.endpoint}/${type}/${id}`, {
      skipErrorBoundary: true,
    });
    let { craft_json: craftJson } = data;
    // Workaround BE bug on 3445 where the craft is sometimes
    // returned as string instead of object
    if (craftJson && typeof craftJson === 'string') {
      craftJson = JSON.parse(craftJson);
    }
    return templateForApp({
      ...snakeToCamelCaseKeys(data),
      // needs to be avoided from snakeToCamelCaseKeys/camelToSnakeCaseKeys conversion
      // because this object contain randomly generated keys
      craftJson,
    });
  };

  getAutomationById = async ({ id }) => {
    const { data } = await AxiosService.get(
      `${this.endpoint}/automations/${id}`
    );
    return snakeToCamelCaseKeys(data);
  };

  getContactSentMessages = async (props, skipErrorBoundary = false) => {
    const { id, ordering, page, search } = props;
    const { data } = await AxiosService.get(`/client/${id}/sent-messages`, {
      params: { ordering, page_size: 50, page, search },
      skipErrorBoundary,
    });
    return snakeToCamelCaseKeys(data);
  };

  getContactSentMessageById = async (id) => {
    const { data } = await AxiosService.get(`/messages/sent/${id}/content`, {
      skipErrorBoundary: true,
    });
    return snakeToCamelCaseKeys(data);
  };

  //Emailing
  sendEmail = async (options) => {
    const { craftJson, kznFilters = {}, ...rest } = options;
    const { data } = await AxiosService.post(
      `${this.endpoint}/broadcasts`,
      {
        ...camelToSnakeCaseKeys({
          ...rest,
        }),
        // needs to be avoided from snakeToCamelCaseKeys/camelToSnakeCaseKeys conversion
        // because this object contain randomly generated keys
        craft_json: addKznFiltersToCraftJson(craftJson, kznFilters),
      },
      {
        skipErrorBoundary: true,
      }
    );
    return { ...snakeToCamelCaseKeys(data), craftJson };
  };

  sendTestEmail = async (subject, sendTo, content) => {
    return AxiosService.post(`${this.endpoint}/send-test-email`, {
      subject,
      send_to: sendTo,
      content,
    });
  };

  getMessageStats = async (id) => {
    const { data } = await AxiosService.get(`/client/${id}/sent-email-stats`);
    return snakeToCamelCaseKeys(data);
  };
}

const instance = new LibraryService();
Object.freeze(instance);

export default instance;
