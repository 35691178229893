import { useParams, useHistory, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_PAGE_CONFIG } from './constants';
import { SUBPAGES, SUBROUTES } from './routes';
import { isFieldAvailable } from 'services/FieldService';
import CustomObjectsService from 'services/CustomObjectsService';
import TeamMemberService from 'services/TeamMemberService';
import { useAsync } from 'react-use';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';
import Loader from 'components/Kizen/Loader';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { setRecordTitle } from 'pages/CustomObjects/RecordsPage';
import { useBreakpoint } from 'app/spacing';
import { NavBar } from './components/NavBar';
import {
  getColumnSettings,
  getColumnConfigPayload,
  getPathBack,
} from './helpers';
import { getDefaultColumnSettings } from 'components/Wizards/CustomObject/steps/CustomLayout/subsections/DefaultColumns/helpers';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { monitoringExceptionHelper } from 'sentry/helpers';
const Columns = ({
  model,
  fields,
  pageConfig: pageConfigProp = DEFAULT_PAGE_CONFIG,
  categories,
}) => {
  const { t } = useTranslation();
  const { objectId, templateId } = useParams();

  const groupedColumns = useMemo(
    () =>
      categories.map(({ name, id }) => {
        return {
          label: name,
          id,
          isGroupedItem: true,
          items: fields.filter((field) => field.category === id),
        };
      }),
    [categories, fields]
  );

  const [pageConfig, setPageConfig] = useState(pageConfigProp);

  const [settingPageConfig, setSettingPageConfig] = useState(false);

  const defaultColumnSettings = useMemo(
    () => (model ? getDefaultColumnSettings(model, fields, t) : []),
    [fields, model, t]
  );

  const columnSettings = useMemo(
    () => getColumnSettings(defaultColumnSettings, pageConfig),
    [pageConfig, defaultColumnSettings]
  );

  const onSubmitColumns = useCallback(
    async (settings) => {
      const payload = getColumnConfigPayload(settings, pageConfig);
      setSettingPageConfig(true);
      try {
        await TeamMemberService.updateCustomObjectRecordsListPageConfig(
          model?.id,
          payload
        );
        setPageConfig(payload);
      } catch (err) {
        monitoringExceptionHelper(err);
      } finally {
        setSettingPageConfig(false);
      }
    },
    [model, pageConfig, setPageConfig]
  );

  const isMobile = useBreakpoint();

  return (
    <Switch>
      {Object.entries(SUBROUTES).map(([key, route]) => {
        const Component = route.component;
        return (
          <Route
            key={key}
            path={'*/columns/' + route.path}
            exact={route.exact !== false}
          >
            <Component
              defaultColumnSettings={defaultColumnSettings}
              columnSettings={columnSettings}
              groupedColumns={groupedColumns}
              onSubmitColumns={onSubmitColumns}
              disabled={settingPageConfig}
              isMobile={isMobile}
              objectId={objectId}
              templateId={templateId}
              model={model}
            />
          </Route>
        );
      })}
    </Switch>
  );
};

export const ColumnsPage = ({ title }) => {
  const { t } = useTranslation();
  const { objectId, subpage, templateId } = useParams();
  const history = useHistory();
  const clientObject = useSelector(getBusinessClientObject);

  const {
    value: {
      categories = EMPTY_ARRAY,
      fields = EMPTY_ARRAY,
      model,
      pageConfig,
    } = EMPTY_OBJECT,
    loading,
  } = useAsync(async () => {
    const modelId = objectId || clientObject.id;
    const [{ fields, fieldCategories, ...model }, pageConfig] =
      await Promise.all([
        CustomObjectsService.getCustomObjectDetails(modelId),
        TeamMemberService.getCustomObjectRecordsListPageConfig(
          modelId,
          DEFAULT_PAGE_CONFIG
        ),
      ]);

    return {
      fields: fields.filter(isFieldAvailable),
      categories: fieldCategories,
      model,
      pageConfig,
    };
  }, [objectId, clientObject.id]);

  useSetTitleOnLoad(title(t), objectId ? model : null, setRecordTitle);

  useEffect(() => {
    if (!subpage) {
      history.replace(history.location.pathname + '/' + SUBPAGES.MINE);
    }
    if (subpage === SUBPAGES.MINE && templateId) {
      history.replace(getPathBack(history.location.pathname));
    }
  }, [history, subpage, templateId]);

  return (
    <>
      {templateId ? null : <NavBar routes={SUBROUTES} />}
      <Loader loading={loading || !model}>
        <Columns
          model={model}
          fields={fields}
          categories={categories}
          pageConfig={pageConfig}
        />
      </Loader>
    </>
  );
};
