import { useMemo } from 'react';

import { getChosenBusiness } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';

export const useEmailFromDomainEnabledEntitlement = () => {
  const chosenBusiness = useSelector(getChosenBusiness);
  return useMemo(() => {
    return chosenBusiness?.entitlements?.email_from_domain_enabled || false;
  }, [chosenBusiness]);
};
