import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useEditor, useNode } from '@craftjs/core';
import { types } from '@kizen/page-builder/nodes/types';
import { gutters } from '@kizen/page-builder/internal/app/spacing';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { useShowControls } from '../useShowControls';
import Control, { AddRemoveColumnControl } from '../Control';
import EmptyNode, { EmptyOutline } from '../Empty';

const CellWrapper = styled.div`
  min-height: ${gutters.spacing(2)}px;
`;

export const Cell = ({ children, onRemove, ...props }) => {
  const { t } = useTranslation();
  const {
    id,
    connectors: { connect },
  } = useNode((node) => ({
    id: node.id,
  }));
  const { query, hoveredId, enabled } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
    enabled: state.options.enabled,
  }));
  const showControls = useShowControls(id, hoveredId, query);

  if (!enabled) {
    return <div {...props}>{children}</div>;
  }

  return (
    <CellWrapper ref={connect} {...props}>
      {children || (
        <Control
          label={t('Content')}
          show={showControls}
          color={colorsPrimary.green.dark}
          mayHaveChildren={false}
          draggable={false}
          canClone={false}
          canDelete={false}
          customControls={
            onRemove ? (
              <AddRemoveColumnControl removeColumn onClick={onRemove} />
            ) : null
          }
          {...props}
        >
          <EmptyOutline>
            <EmptyNode dropType="content">
              {t('Drag and drop')}{' '}
              <strong>
                <u>{t('Content')}</u>
              </strong>{' '}
              {t('here')}.
            </EmptyNode>
          </EmptyOutline>
        </Control>
      )}
    </CellWrapper>
  );
};

const valid = new Set([
  types.Button.type,
  types.Content.type,
  types.CustomField.type,
  types.Attachments.type,
  types.FormField.type,
  types.Text.type,
  types.Image.type,
  types.HTMLBlock.type,
  types.Divider.type,
]);

Cell.craft = {
  rules: {
    canMoveIn: (incomingNodes) => {
      return incomingNodes.every((node) => valid.has(node.data.name));
    },
  },
};
