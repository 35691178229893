import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { grayScale } from 'app/colors';
import FileLibraryModal from 'components/Inputs/FileInput/Modal';
import useModal from 'components/Modals/useModal';
import { cleanFiles } from 'components/Inputs/helpers';
import useField from 'hooks/useField';

import {
  StyledLabelWrapper,
  StyledLabel,
  StyledSmallLabel,
  StyledFileInputWrapper,
  StyledUnderlinedFileInputWrapper,
  StyledPlaceholder,
  StyledFile,
} from './styles';
import { StyledUnderlinedInputEndcap } from '../Input/styles';
import Icon from '../Icon';

const DefaultPlaceholder = (props) => {
  return (
    <StyledPlaceholder {...props}>
      Add Files <span>or drop files here</span>
    </StyledPlaceholder>
  );
};

const FileInput = ({
  className,
  label,
  placeholder,
  onChange,
  error,
  disabled,
  id,
  files: filesProp,
  variant,
}) => {
  const [files] = useField(() => {
    return cleanFiles(filesProp);
  }, [filesProp]);

  const idAttr = id ? { id } : {};
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange([...files, ...acceptedFiles]);
    },
    [files, onChange]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const removeFile = ({ event, fileIndex }) => {
    event.stopPropagation();
    const nextFiles = [...files];
    nextFiles.splice(fileIndex, 1);
    onChange(nextFiles);
  };

  const filesList = files.map((file, fileIndex) => (
    <StyledFile key={file.id || file.path} created={file.created !== false}>
      <button
        type="button"
        disabled={disabled}
        onClick={(event) => removeFile({ event, fileIndex })}
      >
        remove file
      </button>
      <span>{file.name || file.path}</span>
    </StyledFile>
  ));

  const FilledPlaceholder = (props) => {
    return (
      <StyledPlaceholder {...props}>
        {files.length} File{files.length !== 1 ? 's' : ''} Selected
      </StyledPlaceholder>
    );
  };

  const [modalProps, , modal] = useModal();

  if (variant === 'underlined') {
    return (
      <div className={className}>
        <StyledLabelWrapper htmlFor={id}>
          {label && (
            <StyledSmallLabel disabled={disabled}>{label}</StyledSmallLabel>
          )}
          <StyledUnderlinedFileInputWrapper
            error={error}
            disabled={disabled}
            onClick={() => modal.show()}
          >
            <input hidden disabled={disabled} {...idAttr} />
            {files.length > 0 ? (
              <FilledPlaceholder size="small" />
            ) : (
              <DefaultPlaceholder size="small" />
            )}
            {files.length > 0 && (
              <StyledUnderlinedInputEndcap className="search">
                <Icon
                  icon="visible"
                  className="search-icon"
                  color={grayScale.mediumDark}
                />
              </StyledUnderlinedInputEndcap>
            )}
          </StyledUnderlinedFileInputWrapper>
        </StyledLabelWrapper>

        {modalProps.show && (
          <FileLibraryModal
            files={files}
            onChange={onChange}
            modalProps={modalProps}
            modal={modal}
          />
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <StyledLabelWrapper htmlFor={id} {...getRootProps()}>
        {label && <StyledLabel disabled={disabled}>{label}</StyledLabel>}
        <StyledFileInputWrapper error={error} disabled={disabled}>
          <input disabled={disabled} {...idAttr} {...getInputProps()} />
          {filesList}
          {placeholder}
        </StyledFileInputWrapper>
      </StyledLabelWrapper>
    </div>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        created: PropTypes.oneOfType([
          PropTypes.oneOf([false]),
          PropTypes.string,
        ]).isRequired,
      }),
    ])
  ).isRequired,
  placeholder: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'underlined']),
};

FileInput.defaultProps = {
  label: null,
  placeholder: <DefaultPlaceholder />,
  id: '',
  className: '',
  error: false,
  disabled: false,
  variant: 'standard',
};

export default FileInput;
