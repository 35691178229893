import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Overlay from 'react-bootstrap2/Overlay';
import { shadowLight } from 'app/colors';

import StylePassthrough from 'components/Kizen/StylePassthrough';

const WithShadow = styled(StylePassthrough)`
  ${shadowLight}
`;

const TextInputOverlay = (props) => {
  const { show, horizontalCentered, children, ...others } = props;

  const { placement } = props;
  const { popperConfig } = props;

  // Had to introduce some state here because if autoFocus
  // is true when the overlay opens, the browser will auto-scroll
  // to the top of the page. I believe this is because the overlay isn't
  // positioned anywhere yet.
  const [autoFocus, setAutoFocus] = useState(false);
  const desiredAutoFocus =
    typeof children.props.autoFocus === 'boolean'
      ? children.props.autoFocus
      : true;
  useEffect(() => {
    requestAnimationFrame(() => setAutoFocus(show && desiredAutoFocus));
  }, [show, desiredAutoFocus]);

  return (
    <Overlay
      show={show}
      transition={false}
      rootClose
      placement={placement}
      popperConfig={popperConfig}
      {...others}
    >
      {/*wrap in div to fix flickering on scroll*/}
      <div>
        <WithShadow passthrough={['scheduleUpdate']}>
          {React.cloneElement(children, {
            // react-select only autofocuses on mount, so we use a key to force a remount when this changes
            key: autoFocus,
            sizing:
              children.props.sizing === false
                ? false
                : children.props.sizing || 'auto',
            autoFocus,

            components: {
              ...children.props.components,
            },
            style: {},
          })}
        </WithShadow>
      </div>
    </Overlay>
  );
};

TextInputOverlay.propTypes = {
  show: PropTypes.bool,
  placement: PropTypes.string,
  popperConfig: PropTypes.object,
  horizontalCentered: PropTypes.bool,
};

TextInputOverlay.defaultProps = {
  show: null,
  placement: 'bottom-start',
  popperConfig: {},
  horizontalCentered: null,
};
export default TextInputOverlay;
