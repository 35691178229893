import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { range } from 'utility/iterables';
import { useBuilderContext } from '../BuilderContext';
import { TraySection, TraySubsection } from '../components/TraySection';
import { FlexSelect, FlexWrapper, FlexColorPicker } from './components';
import { useCraftProps } from './useCraftProps';

const upperCaseFirstChar = (x) => `${x[0].toUpperCase()}${x.slice(1)}`;

export const alignmentOptions = ['left', 'center', 'right'].map((x) => ({
  label: upperCaseFirstChar(x),
  value: x,
}));
export const sizeOptions = [...range(1, 13), 14, 16].map((x) => ({
  label: `${x}px`,
  value: x.toString(),
}));
export const styleOptions = (t) => [
  {
    label: t('Solid'),
    value: 'solid',
  },
  {
    label: t('Dashed'),
    value: 'dashed',
  },
  {
    label: t('Dotted'),
    value: 'dotted',
  },
];
export const widthOptions = [
  '10',
  '20',
  '25',
  '30',
  '40',
  '50',
  '60',
  '70',
  '75',
  '80',
  '90',
  '100',
].map((x) => ({ label: `${x}%`, value: x }));

export const DividerSettings = ({
  node: {
    id,
    data: { props: initialProps },
  },
}) => {
  const traySectionRef = useRef();
  const { t } = useTranslation();
  const { clearContentSettingsTray } = useBuilderContext();
  const [props, setProp, setProps] = useCraftProps(id, initialProps, {
    applyToActiveNode: true,
  });

  useEffect(() => {
    setProps(initialProps);
  }, [initialProps, setProps]);

  return (
    <TraySection
      ref={traySectionRef}
      onBackClick={clearContentSettingsTray}
      collapsable={false}
      header={t('Divider Settings')}
    >
      <TraySubsection header={t('Styling')}>
        <FlexWrapper>
          <FlexSelect
            variant="underline"
            value={props.borderStyle}
            label={t('Style')}
            flex={1 / 3}
            options={styleOptions(t)}
            scrollContainer={traySectionRef.current}
            onChange={(val) => setProp('borderStyle', val.value)}
          />
          <FlexColorPicker
            color={props.color}
            label={t('Line Color')}
            align="right"
            flex={1 / 3}
            onChange={(rgba) => setProp('color', rgba)}
          />
        </FlexWrapper>
      </TraySubsection>
      <TraySubsection header={t('Sizing')}>
        <FlexWrapper>
          <FlexSelect
            variant="underline"
            value={props.size}
            label={t('Thickness')}
            options={sizeOptions}
            scrollContainer={traySectionRef.current}
            onChange={(val) => setProp('size', val.value)}
          />
          <FlexSelect
            variant="underline"
            value={props.width}
            label={`${t('Width')} (%)`}
            options={widthOptions}
            scrollContainer={traySectionRef.current}
            onChange={(val) => setProp('width', val.value)}
          />
          <FlexSelect
            variant="underline"
            value={props.alignment}
            label={t('Alignment')}
            options={alignmentOptions}
            scrollContainer={traySectionRef.current}
            onChange={(val) => setProp('alignment', val.value)}
          />
        </FlexWrapper>
      </TraySubsection>
    </TraySection>
  );
};
