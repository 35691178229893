import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { gutters } from 'app/spacing';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import TitleField from '../../fields/title';
import DatapointFrequencyField from '../../fields/datapointFrequency';
import { StyledWizardField } from 'components/Wizards/shared/styles';

const EmailInteractionStatsReportType = ({
  defaultName,
  handleSetName,
  mobile,
  active,
}) => {
  const { state, setState, entityName } = useContext(
    WizardStateContext.Context
  );
  const { name, reportType, datapointFrequency } = state;
  const onChangeDatapointFrequency = useCallback(
    (value) => {
      return setState('datapointFrequency', value);
    },
    [setState]
  );

  // Hide self if not email interaction stats
  if (reportType?.value !== REPORT_TYPES.INTERACTION_STATS) {
    return null;
  }

  return (
    <>
      <DatapointFrequencyField
        value={datapointFrequency}
        onChange={onChangeDatapointFrequency}
        mobile={mobile}
        active={active}
        top={gutters.spacing(2, 2)}
      />
      {/* spacer for next step special case */}
      <StyledWizardField top={gutters.spacing(4)} />
      <TitleField
        defaultName={defaultName}
        name={name}
        onChangeName={handleSetName}
        top={gutters.spacing(2)}
        entityName={entityName}
      />
    </>
  );
};

EmailInteractionStatsReportType.propTypes = {
  defaultName: PropTypes.string,
  handleSetName: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
};

EmailInteractionStatsReportType.defaultProps = {
  defaultName: '',
  mobile: false,
};

export default EmailInteractionStatsReportType;
