import { useEffect, useMemo, useRef } from 'react';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { grayScale, shadowLight } from 'app/colors';
import { borderRadii, hideScrollbarCss } from 'app/spacing';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import DayCalendarCell from './components/DayCalendarCell';
import useCurrentTimePosition from './useCurrentTimePosition';
import { getCalendarTimeIntervals, getDateTitle, isCellToday } from './utils';
import FloatingDashedLine from './components/FloatingDashedLine';
import {
  BoldCellText,
  CalendarScrollGradient,
  Cell,
  Container,
  DateTitle,
  TimeIntervalCell,
} from './components/styled';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import useSyncScroll from 'react-use-sync-scroll';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import { useTranslation } from 'react-i18next';
import { CALENDAR_VIEW_OPTIONS } from './constants';

const TIME_CELL_HEIGHT = 42;
const COLUMN_ONE_WIDTH = 70;
const HEADER_ROW_HEIGTH = 34;

const getScrollPositionForTime = (times, time) => {
  return times.indexOf(time) * TIME_CELL_HEIGHT;
};

const GridWrapper = styled.div`
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${COLUMN_ONE_WIDTH}px 1fr;
  grid-auto-rows: 1fr;
  height: 100%;
`;

const CalendarWrapper = styled.div`
  display: flex;
  flex: 1;
  height: calc(100% - ${HEADER_ROW_HEIGTH}px);
  ${shadowLight}
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.standard};
  overflow: hidden;
  background-color: #fff;
`;

const StyledScrollFadeContainer = styled(ScrollFadeContainer)`
  width: 100%;
  flex-shrink: 0;

  ${hideScrollbarCss}
  overflow-y: auto;
`;

const StyledScrollBarDetached = styled(ScrollBarDetached)`
  position: relative;
  left: -9px;
  flex-shrink: 0;
  z-index: 1;
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: ${COLUMN_ONE_WIDTH}px 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: ${HEADER_ROW_HEIGTH}px;
`;

/**
 * Calendar component to view times for a single day
 *
 * @param props.date - the reference date for the calendar used to show the current day.
 * @param props.times - an array of time intervals used to render each row of the calendar. Defaults to 12:00 AM - 11:30 PM using 30 minute intervals
 * @param props.initialTopTime - the time used as the inital top position. Defaults 8:00 AM.
 * @param props.CellComponent - the cell component to be used when rendering each time. Defaults to {@link WeekCalendarCell}
 */
const DayCalendar = ({
  date,
  times,
  initialTopTime,
  CellComponent,
  ...rest
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [measureRef, { width }] = useMeasure();
  const showCurrentTimeBar = isCellToday(date);
  const gridRef = useRef(null);
  const currentTimePosition = useCurrentTimePosition(
    gridRef.current ? gridRef.current.scrollHeight : 0
  );

  const scrollbarRef = useRef();
  const [refFn, scrollRef] = useSyncSizes(
    scrollbarRef,
    '.ContentForSyncHeight',
    'height'
  );
  useSyncScroll(useRef([scrollRef, scrollbarRef]), {
    vertical: true,
  });
  const scrolled = useEndsOfScroll(scrollRef, [date]);

  useEffect(() => {
    scrollRef.current.scrollTo({
      top: getScrollPositionForTime(times, initialTopTime),
      behavior: 'smooth',
    });
  }, [times, scrollRef, initialTopTime]);

  const dateTitle = useMemo(
    () => getDateTitle(date, language, CALENDAR_VIEW_OPTIONS.day),
    [date, language]
  );

  return (
    <Container {...rest}>
      <HeaderRow>
        <div />
        <Cell>
          <DateTitle size="buttonLabel">{dateTitle}</DateTitle>
        </Cell>
      </HeaderRow>
      <CalendarWrapper ref={measureRef}>
        <StyledScrollFadeContainer
          ref={refFn}
          scrolled={scrolled}
          gradient={<CalendarScrollGradient />}
          bottom
          top
        >
          <GridWrapper ref={gridRef} className="ContentForSyncHeight">
            {showCurrentTimeBar && (
              <FloatingDashedLine
                offSet={{ y: currentTimePosition }}
                containerWidth={width}
              />
            )}
            <Grid>
              {times.map((t, idx, arr) => (
                <TimeIntervalCell
                  key={t}
                  isFirstRow={idx === 0}
                  isLastRow={idx === arr.length - 1}
                >
                  <BoldCellText size="buttonLabel">{t}</BoldCellText>
                </TimeIntervalCell>
              ))}
              {times.map((t, idx, arr) => (
                <CellComponent
                  key={t}
                  date={date}
                  time={t}
                  isFirstRow={idx === 0}
                  isLastRow={idx === arr.length - 1}
                  style={{
                    gridColumn: 2,
                    gridRow: idx + 1,
                  }}
                />
              ))}
            </Grid>
          </GridWrapper>
        </StyledScrollFadeContainer>
        <StyledScrollBarDetached
          ref={scrollbarRef}
          direction="vertical"
          scrollClassName="ContentForSyncHeight"
        />
      </CalendarWrapper>
    </Container>
  );
};

export default DayCalendar;

DayCalendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  times: PropTypes.arrayOf(PropTypes.string),
  initialTopTime: PropTypes.string,
  CellComponent: PropTypes.elementType,
};

DayCalendar.defaultProps = {
  times: getCalendarTimeIntervals(),
  initialTopTime: '8:00 AM',
  CellComponent: DayCalendarCell,
};
