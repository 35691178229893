import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cols from 'components/Layout/Cols';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import { STEPS, LIST_OF_FIELDS as allFieldTypes } from '../config';
import FieldType from './type';
import Section from '../../../section';
import { useFlashTransition } from 'hooks/useFlashState';

const exceptions = ['category', 'name', 'displayName', 'type'];

const FieldSettings = ({
  updateField,
  resetField,
  updateValidation,
  formData,
  categories,
  data,
  errors,
}) => {
  const { t } = useTranslation();

  const isEdit = !!data.field;
  const isValid = Boolean(
    formData.displayName.trim() &&
      formData.category &&
      (!isEdit || formData.name.trim())
  );

  // custom objects has RelationshipOptions
  const fieldTypes = allFieldTypes.filter(
    (f) => !f.shownOn || f.shownOn.includes('FieldWizard')
  );

  useEffect(() => {
    updateValidation(STEPS.FIELD_SETTINGS, isValid);
  }, [isValid, updateValidation]);

  const [error, showError, flashError] = useFlashTransition();

  useEffect(() => {
    if (errors?.name || errors?.displayName) {
      flashError(errors);
    }
  }, [errors, flashError]);

  return (
    <>
      <Section index={0} header={t('Field Settings')} isActive>
        <Cols columns={2} gutter="20px">
          <TextInput
            label={t('Field Name')}
            value={formData.displayName}
            placeholder={t('Enter Field Name')}
            onChange={(value) => updateField('displayName', value)}
            validate={{
              message: error?.displayName,
              showMessage: error?.displayName && showError,
            }}
            margin={isEdit}
          />
          <Select
            fullWidth
            label={t('Category')}
            options={
              Array.isArray(categories)
                ? categories.map((cat) => ({
                    value: cat.id,
                    label: cat.name,
                  }))
                : categories
            }
            value={formData.category}
            onChange={(value) => updateField('category', value)}
            margin={isEdit}
          />
        </Cols>
        {isEdit ? (
          <Cols columns={2} gutter="20px">
            <TextInput
              label={t('Field API Name')}
              value={formData.name}
              placeholder={t('Enter Field API Name')}
              onChange={(value) =>
                updateField('name', (value || '').toLowerCase())
              }
              labelInfo={t(
                'Warning: Changing the API Name will break integrations using this name, as well as email merge fields referencing this field name. Only letters, numbers, and _ are allowed.'
              )}
              validate={{
                message: error?.name,
                showMessage: error?.name && showError,
              }}
              error={errors?.name}
            />
            <TextInput label={t('Field ID')} value={data.field.id} disabled />
          </Cols>
        ) : null}
      </Section>
      <FieldType
        formData={formData}
        updateField={updateField}
        resetField={resetField}
        updateValidation={updateValidation}
        index={1}
        isActive
        data={data}
        fieldTypes={fieldTypes}
        exceptions={exceptions}
      />
    </>
  );
};

export default FieldSettings;
