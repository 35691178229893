import { isSubmitActionButton } from '@kizen/page-builder/utils/node-is';
import { hasTeamMemberOptions } from 'checks/fields';

/**
 * Updates fields Meta
 *
 * @param nodes
 * @param fields
 * @returns {}
 */
export const updateFieldMeta = (nodes, fields) => {
  if (!fields?.length) return nodes;

  const fieldLookup = fields.reduce((acc, field) => {
    acc[field.id] = field;
    return acc;
  }, {});

  return {
    ...nodes,
    ...Object.keys(nodes)
      .filter((key) => {
        return (
          ['CustomField', 'FormField'].includes(nodes[key].type.resolvedName) &&
          !!nodes[key].props.field
        );
      })
      .reduce((obj, key) => {
        const objectField =
          fieldLookup[
            nodes[key].props.field?.customObjectField?.id ||
              nodes[key].props.field.id
          ];
        const isCustomObjectField =
          !!nodes[key].props.field?.customObjectField?.id;
        obj[key] = {
          ...nodes[key],
          props: {
            ...nodes[key].props,
            field: {
              ...nodes[key].props.field,
              ...(objectField && {
                options: objectField.options,
                displayName: objectField.displayName,
                ...(objectField?.phonenumberOptions && {
                  phonenumberOptions: objectField.phonenumberOptions,
                }),
                ...(isCustomObjectField && { customObjectField: objectField }),
              }),
            },
          },
        };
        return obj;
      }, {}),
  };
};

const addFieldOptionsToNode = (node, options) => {
  return {
    ...node,
    props: {
      ...node.props,
      field: {
        ...node.props.field,
        options,
      },
    },
  };
};

/**
 *
 * @param nodes
 * @param teamMembers
 * @returns {{[p: string]: any} | any}
 */
export const withTeamMemberOptions = (nodes, teamMembers) => {
  const teamMemberOptions = teamMembers.map((teamMember) => ({
    value: teamMember.id,
    label: teamMember.full_name,
  }));

  const newEntries = Object.entries(nodes).map(([key, node]) => {
    return hasTeamMemberOptions(node?.props?.field)
      ? [key, addFieldOptionsToNode(node, teamMemberOptions)]
      : [key, node];
  });
  return Object.fromEntries(newEntries);
};

export const parseDataProp = (data) =>
  typeof data === 'string' ? JSON.parse(data) : data;

export const hasButtonWithRecaptcha = (pageData) => {
  return Object.values(pageData).some((node) => {
    return node.type.resolvedName === 'Button' && node.props.enableRecaptcha;
  });
};

export const enableRecaptchaOnPageData = (pageData, enableRecaptcha) => {
  const nodes = Object.entries(pageData).map(([key, node]) => {
    return isSubmitActionButton(node)
      ? [key, { ...node, props: { ...node.props, enableRecaptcha } }]
      : [key, node];
  });
  return Object.fromEntries(nodes);
};

export const enableRecaptchaOnAllSubmitButtons = (pages, enableRecaptcha) => {
  return pages.map((page) => {
    if (!page.isFormPage) return page;

    return {
      ...page,
      pageData: enableRecaptchaOnPageData(page.pageData, enableRecaptcha),
    };
  });
};
