import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import { NewTRow as BaseTRow } from 'components/Kizen/Table';
import BaseErrorCard from 'components/Kizen/ErrorCard';

export const TRow = styled(BaseTRow)`
  &&:hover {
    background-color: ${grayScale.white};
  }
`;

export const ErrorCard = styled(BaseErrorCard)`
  margin-top: -${gutters.spacing(2, -2)}px;
`;
