import { SENDER_TYPE } from 'components/MessageBuilder/components';

const getSendFrom = (
  { senderType, senderTeamMember, senderRole, sentFrom, from },
  businessName,
  t
) => {
  switch (senderType) {
    case SENDER_TYPE.TEAM_MEMBER:
      return senderTeamMember.email;

    case SENDER_TYPE.BUSINESS:
      return businessName;

    case SENDER_TYPE.LAST_ANY:
      return t('Last Team Member');

    case SENDER_TYPE.LAST_ROLE:
      return `${t('Last Team Member')} (${senderRole.name})`;

    default:
      return sentFrom || from || '';
  }
};

/**
 * Email HTML generated by the MJML converter lists all dynamic images (the backend removes all but one prior to sending).
 * For previews, we do not know which image was used, so we show the default content in all cases. This css style
 * hides all dynamic images and shows the default image.
 *
 * @remarks An image's css class is placed on a <td> element when ran through the MJML converter. All dynamic images have a `kzn-filter`
 * class as well as a class of the format `kzn-filter_{node_id}_{dynamic_image_order}_{filter_id} where the filter_id is "default" for
 * the default image. We hide all dynamic images and re-show the default image.
 *
 * @param {*} html - The email HTML
 */
const applyHideDynamicImageStyle = (html) => {
  const style = `
    <style>
      td.kzn-filter {
        display: none;
      }
      td.kzn-filter[class$="_default"] {
        display: table-cell;
      }
    </style>`;
  const [x, y] = html?.split('</head>') ?? [];
  return [x, style, '</head>', y].join('');
};

export const messageMapper = (
  data,
  businessName,
  t,
  { hideDynamicImages = false } = {}
) => {
  const bodySanitized = hideDynamicImages
    ? applyHideDynamicImageStyle(data.content)
    : data.content;
  return {
    ...data,
    message: data.subject,
    bodySanitized,
    messageType: data.type,
    fromAddress: getSendFrom(data, businessName, t),
  };
};

export const SUB_NAVIGATION_HEIGHT = 72 + 2 * 20; // 72 is the height of the sub navigation bar, 20 is the padding
