import { useState } from 'react';
import { format, parse } from 'date-fns';
import { API_DATE_FORMAT } from 'services/helpers';
import useFlashState from 'hooks/useFlashState';
import { FIELD_TYPES } from 'utility/constants';

export const FIELD_TYPE_STAGE_DROPDOWN = 'stage-dropdown';

export const transformDateUp = (date) =>
  date ? format(date, API_DATE_FORMAT) : null;

export const transformDateDown = (yyyymmdd) =>
  yyyymmdd ? parse(yyyymmdd, API_DATE_FORMAT, new Date()) : null;

// TODO in 2156 / PR548 these were introduced, and ultimately should be kept. The ones above will soon disappear.
export const transformDateUpNonLegacy = (date) =>
  date ? format(date, API_DATE_FORMAT) : null;

export const transformDateDownNonLegacy = (yyyymmdd) =>
  yyyymmdd ? parse(yyyymmdd, API_DATE_FORMAT, new Date()) : null;

export function useAddObjectModal({ handleSubmit, trimValue = false }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [stagedName, setStagedName] = useState('');
  const [modalSubmitting, setModalSubmitting] = useState(false);
  const [validateMessage, setValidateMessage] = useFlashState(1500, '');
  const [validateError, setValidateError] = useState(false);

  const onChange = (val) => {
    setStagedName(trimValue ? (val || '').replace(/^\s+/, '') : val);
    setValidateError(false);
  };

  const onHide = () => {
    setStagedName('');
    setModalOpen(false);
    setValidateError(false);
  };

  const onConfirm = async () => {
    if (stagedName) {
      setModalSubmitting(true);
      setValidateError(false);
      try {
        await handleSubmit(trimValue ? stagedName.trim() : stagedName);
        onHide();
      } catch (err) {
        const { data } = err.response;
        const errorMesssage = data.errors?.name || data.errors?.message;
        if (errorMesssage) {
          setValidateError(true);
          setValidateMessage(errorMesssage);
        }
      } finally {
        setModalSubmitting(false);
      }
    }
  };

  const onOpen = (name) => {
    setModalOpen(true);
    setStagedName(trimValue ? (name || '').trim() : name);
  };

  return [
    {
      disabled: !stagedName || modalSubmitting,
      onHide,
      onConfirm,
      show: isModalOpen,
    },
    {
      value: stagedName,
      onChange,
    },
    {
      onOpen,
      modalSubmitting,
    },
    validateMessage,
    validateError,
  ];
}

export const fieldTypeMapper = (field) => {
  const mapper = {
    stage: FIELD_TYPE_STAGE_DROPDOWN,
  };

  if (field.isDefault) {
    return mapper[field.name] || field.fieldType;
  }

  return field.fieldType;
};

export const getFieldAccessPermissions = (
  { isReadOnly, access: fieldAccess } = {},
  { access: entityAccess } = {}
) => {
  const canEdit = Boolean(
    !isReadOnly && fieldAccess?.edit && entityAccess?.edit
  );
  const canView = Boolean(canEdit || (fieldAccess?.view && entityAccess?.view));

  return [canEdit, canView];
};

export const dummyAccessObject = { access: { edit: true } };

export const isDefaultDynamicTag = ({ isDefault, fieldType }) =>
  isDefault && fieldType === FIELD_TYPES.DynamicTags.type;

export const isTeamSelector = ({ isDefault, fieldType }) =>
  !isDefault && fieldType === FIELD_TYPES.TeamSelector.type;
