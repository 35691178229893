import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useUniqueId } from '../utils/id';

const MAX_RETRY_MS = 3000;
const RETRY_INTERVAL = 100;

// render may not be callable even when window.grecaptcha is loaded, see https://stackoverflow.com/a/50224757/4375387
const waitRenderValid = async () => {
  return new Promise((resolve) => {
    let count = 0;
    const check = () => {
      if (window.grecaptcha && typeof window.grecaptcha.render === 'function') {
        resolve(true);
      } else if (count < MAX_RETRY_MS) {
        setTimeout(check, RETRY_INTERVAL);
        count += RETRY_INTERVAL;
      } else {
        resolve(false);
      }
    };

    check();
  });
};

export const useRecaptchaScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?render=explicit'
    );
    document.body.appendChild(script);
  }, []);
};

/**
 * Implements the invisible reCAPTCHA (https://developers.google.com/recaptcha/docs/invisible).
 * This hook will only work if `useRecaptchaScript` has been used somewhere on the page.
 *
 * @remarks grecaptcha.render will only be called once (additional calls to `render` will throw
 * an error). The callback the recaptcha widget is rendered with will not change. Any data used by
 * the callback should be stored in a ref and kept up to date.
 *
 * @param sitekey - the key registered with google for a specific domain. This is an environment variable (see https://cloud.google.com/recaptcha-enterprise/docs/create-key)
 * @param callback - function to be called following a successful recaptcha test
 * @returns [ReactNode, execute]
 */
export const useRecaptchaWidget = (sitekey, callback) => {
  const id = useUniqueId();
  const [recaptchaId, setRecaptchaId] = useState(null);
  const renderCalled = useRef(false);

  useEffect(() => {
    const init = async () => {
      if (recaptchaId === null) {
        if (await waitRenderValid()) {
          if (!renderCalled.current) {
            const rId = window.grecaptcha.render(id, {
              sitekey,
              callback,
            });
            renderCalled.current = true;
            setRecaptchaId(rId);
          }
        }
      }
    };
    init();
  }, [id, recaptchaId, sitekey, callback]);

  const execute = useCallback(async () => {
    if (recaptchaId === null) {
      if (await waitRenderValid()) {
        const rId = window.grecaptcha.render(id, {
          sitekey,
          callback,
        });
        setRecaptchaId(rId);
        window.grecaptcha.execute(rId);
      }
    } else {
      window.grecaptcha.execute(recaptchaId);
    }
  }, [recaptchaId, id, sitekey, callback]);

  const widget = useMemo(
    () => () => (
      <div
        id={id}
        className="g-recaptcha"
        data-sitekey={sitekey}
        data-size="invisible"
      />
    ),
    [id, sitekey]
  );

  return [widget, execute];
};
