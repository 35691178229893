import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { gutters, useWindowSize, isMobile } from 'app/spacing';
import BaseModal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'components/Modals';
import { StyledWizardBody } from './styles';
import Wizards from './wizards';
import Section from './section';
import css from '@emotion/css';

const GenericWizard = (props) => {
  const { wizard } = props;
  const Wizard = Wizards[wizard];

  return (
    <StyledWizardBody className="GenericWizardBody">
      <Wizard isActive {...props} />
    </StyledWizardBody>
  );
};

GenericWizard.propTypes = {
  wizard: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  data: PropTypes.any,
};

GenericWizard.defaultProps = {
  onChange: null,
  data: null,
};

export default GenericWizard;

// Example wizard toolkit structure/usage:
//
//    <GenericWizardBaseModal>
//      <GenericWizardModalHeader>Heading</GenericWizardModalHeader>
//      <GenericWizardModalBody>
//        <GenericWizardSection />
//        <GenericWizardSection />
//        <GenericWizardSection />
//      </GenericWizardModalBody>
//      <GenericWizardModalFooter>
//        <Button>Save</Button>
//      </GenericWizardModalFooter>
//    </GenericWizardBaseModal>;
//

export function GenericWizardBody({ className, children, ...props }) {
  return (
    <StyledWizardBody
      className={`GenericWizardBody ${className || ''}`}
      {...props}
    >
      {React.Children.toArray(children)
        .filter(React.isValidElement) // Ignore null, false, etc.
        .map((child, index, arr) =>
          // Automatically set GenericWizardSection index and isLastIndex props
          React.cloneElement(child, {
            index,
            isLastIndex: index === arr.length - 1,
          })
        )}
    </StyledWizardBody>
  );
}

export const GenericWizardSection = styled(Section)`
  margin-top: ${gutters.spacing(6, -13)}px;
  margin-bottom: ${gutters.spacing(8, -3)}px;
  min-width: 0; // Helps ensures inline contents don't get go wider than 100%, e.g. inline select menu options
  ${({ leavePAlone }) =>
    leavePAlone
      ? null
      : css`
          p {
            margin-bottom: unset; // Undo an overreaching style from Section
          }
        `}

  &:last-child {
    margin-bottom: 0; // No extra padding between bottom of section and footer
  }
`;

// Note that this doesn't use the BasicBaseModal, a bit of an outlier currently.
// Also not that the dashlet wizard and field builder modal don't use these components at the time of writing,
// in case you are in charge of changing all "standard wizard" styles.

export function GenericWizardBaseModal(props) {
  return <BaseModal size="medium" fitContent={false} {...props} />;
}

export const GenericWizardModalHeader = ModalHeader;

export function GenericWizardModalBody({ children, ...props }) {
  const { width } = useWindowSize();

  return (
    <ModalBody
      typeOfContent={isMobile(width) ? 'wizard-mobile' : 'wizard'}
      {...props}
    >
      <GenericWizardBody>{children}</GenericWizardBody>
    </ModalBody>
  );
}

// In the future this may have showBorders={false}, factored out here for control in the future.

export const GenericWizardModalFooter = ModalFooter;
