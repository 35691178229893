import { useCallback } from 'react';
import DateInput from '__components/Inputs/DateInput';
import { InputProps } from './types';

export const getDateString = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const Date = ({
  step,
  width,
  value,
  placeholder,
  error,
  onChange,
}: InputProps) => {
  const handleChange = useCallback(
    (date: Date) => {
      onChange(step, getDateString(date));
    },
    [step, onChange]
  );

  return (
    <DateInput
      value={value}
      width={width}
      placeholder={placeholder}
      error={error}
      onChange={handleChange}
      variant="outline"
    />
  );
};
