import { MouseEvent as ReactMouseEvent } from 'react';
import {
  CurrentDropZone,
  DropZoneDirection,
  DropZoneMap,
  LayoutState,
} from './types';
import { COLUMN_SIZE } from '@kizen/kds/Grid';
import { v4 } from 'uuid';

export const idChainSeparator = '::';

export const getCurrentDropZone = (
  ev: ReactMouseEvent<HTMLDivElement, MouseEvent>,
  dropZones: DropZoneMap,
  ignoreId?: string
) => {
  const scrollLeft =
    document.documentElement.scrollLeft || document.body.scrollLeft;
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;

  const xPos = ev.pageX - scrollLeft;
  const yPos = ev.pageY - scrollTop;

  const overDropZone = Object.keys(dropZones).find((dropZoneId) => {
    const candidate = dropZones[dropZoneId];
    if (candidate && dropZoneId !== ignoreId) {
      const rect = candidate.getBoundingClientRect();
      const { left, right, top, bottom } = rect;
      const isInside =
        xPos >= left && xPos <= right && yPos >= top && yPos <= bottom;

      return isInside;
    }

    return false;
  });

  return overDropZone;
};

export const getPreciseDropZone = (
  ev: ReactMouseEvent<HTMLDivElement, MouseEvent>,
  children: DropZoneMap,
  draggingItemId: string
): CurrentDropZone => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;

  const yPos = ev.pageY - scrollTop;

  let closestVerticalItem = '';
  let closestDistanceVertical: number = Infinity;
  let closestDistanceVerticalRelative: number = Infinity;

  Object.keys(children).forEach((childId) => {
    if (childId !== draggingItemId) {
      const child = children[childId];
      if (child) {
        const rect = child.getBoundingClientRect();
        const { top, bottom } = rect;
        const relativeDistance = yPos - (top + bottom) / 2;
        const absoluiteDistance = Math.abs(relativeDistance);

        if (absoluiteDistance < closestDistanceVertical) {
          closestDistanceVertical = absoluiteDistance;
          closestVerticalItem = childId;
          closestDistanceVerticalRelative = relativeDistance;
        }
      }
    }
  });

  return {
    id: closestVerticalItem,
    direction:
      closestDistanceVerticalRelative > 0
        ? DropZoneDirection.DOWN
        : DropZoneDirection.UP,
  };
};

export const isDropZoneDifferentFromDraggable = (
  dropItemId: string,
  dragItemId: string,
  rowId: string,
  columnId: string,
  layout: LayoutState,
  direction: DropZoneDirection
) => {
  const rowIndex = layout.findIndex((row) => row.id === rowId);
  if (rowIndex > -1) {
    const colIndex = layout[rowIndex].columns.findIndex(
      (col) => col.id === columnId
    );

    if (colIndex > -1) {
      const dropItemIndex = layout[rowIndex].columns[colIndex].items.findIndex(
        (item) => item.id === dropItemId
      );
      const previousDropItem =
        layout[rowIndex].columns[colIndex].items[dropItemIndex - 1];
      const nextDropItem =
        layout[rowIndex].columns[colIndex].items[dropItemIndex + 1];

      if (
        direction === DropZoneDirection.UP &&
        previousDropItem?.id === dragItemId
      ) {
        return false;
      }

      if (
        direction === DropZoneDirection.DOWN &&
        nextDropItem?.id === dragItemId
      ) {
        return false;
      }
    }
  }
  return true;
};

export const getWidthClassName = (width: COLUMN_SIZE) => {
  switch (width) {
    case COLUMN_SIZE.THIRD_WIDTH:
      return 'col-span-2';
    case COLUMN_SIZE.TWO_THIRD_WIDTH:
      return 'col-span-4';
    case COLUMN_SIZE.HALF_WIDTH:
      return 'col-span-3';
    case COLUMN_SIZE.FULL_WIDTH:
      return 'col-span-6';
    default:
      return '';
  }
};

export const layoutToOptions = (
  layouts: { id: string; name: string; active: boolean }[]
) => {
  return layouts.map((layout) => ({
    value: layout.id,
    label: layout.name,
    active: layout.active,
  }));
};

export const blockLabels: Record<string, (t: (x: string) => string) => string> =
  {
    fields: (t) => t('Fields'),
    lead_sources: (t) => t('Lead Sources'),
    timeline: (t) => t('Timeline'),
    team_and_activities: (t) => t('Team & Activities'),
    action_block: (t) => t('Action Block'),
    related_object_fields: (t) => t('Related'),
    related_pipelines: (t) => t('Related Pipelines'),
    custom_content: (t) => t('Custom Content'),
  };

export const blockInternalNames: Record<
  string,
  (t: (x: string) => string) => string
> = {
  fields: (t) => t('Fields'),
  lead_sources: (t) => t('Lead Sources'),
  timeline: (t) => t('Timeline'),
  team_and_activities: (t) => t('Team & Activities'),
  action_block: (t) => t('Action Block'),
  related_object_fields: (t) => t('Related Object Fields'),
  related_pipelines: (t) => t('Related Pipelines'),
  custom_content: (t) => t('Custom Content'),
};

export const getIndices = (id: string, layout: LayoutState) => {
  for (let i = 0; i < layout.length; i++) {
    for (let j = 0; j < layout[i].columns.length; j++) {
      for (let k = 0; k < layout[i].columns[j].items.length; k++) {
        if (layout[i].columns[j].items[k].id === id) {
          return { row: i, column: j, item: k };
        }
      }
    }
  }

  return { row: -1, column: -1, item: -1 };
};

export const buildSharingSettings = (
  permissions: any,
  defaultViewUsers?: string[]
) => {
  if (!permissions) {
    return undefined;
  }

  return {
    all_team_members: permissions.allTeamMembersPermission,
    roles: {
      view: permissions.viewRoles?.map((role: any) => role.value) ?? [],
      edit: [],
      admin: [],
    },
    team_members: {
      view: Array.from(
        new Set([
          ...(permissions.viewTeamMembers?.map(
            (teamMember: any) => teamMember.value
          ) ?? []),
          ...(defaultViewUsers ?? []),
        ])
      ),
      edit: [],
      admin: [],
    },
  };
};

export const getDefaultLayout = (): LayoutState => {
  return [
    {
      id: v4(),
      columns: [
        {
          id: v4(),
          width: COLUMN_SIZE.THIRD_WIDTH,
          items: [
            {
              id: v4(),
              label: 'Fields',
              type: 'fields',
              metadata: {
                chosenCategories: [],
                autoInclude: true,
                excluded: [],
                included: [],
              },
              internalName: '',
              displayName: '',
            },
          ],
        },
        {
          id: v4(),
          width: COLUMN_SIZE.TWO_THIRD_WIDTH,
          items: [
            {
              id: v4(),
              label: 'Action Block',
              type: 'action_block',
              internalName: '',
              displayName: '',
            },
            {
              id: v4(),
              label: 'Timeline',
              type: 'timeline',
              metadata: {
                includeAll: true,
                includeRelated: true,
                excluded: [],
                included: [],
              },
              internalName: '',
              displayName: '',
            },
          ],
        },
      ],
    },
  ];
};
