import css from '@emotion/css';
import styled from '@emotion/styled';
import { appBackground, grayScale } from 'app/colors';
import EditableText from 'components/Kizen/EditableText';
import Switch from 'components/Kizen/Switch';
import { DropPlaceholder } from 'components/MultiColumnBuilder/components/BaseOption/styles';

export const StyledSwitch = styled(Switch)`
  margin: 0;
`;

// Always a fixed width. This is not used on small screens
export const StyledColumnWrapper = styled.div`
  width: 1020px;
  p {
    margin-bottom: 0;
  }
`;

export const StyledSummarySelector = styled.div`
  display: flex;
  column-gap: 15px;

  .metric-wizard-select {
    width: calc(70% - 15px);
  }
`;

export const ButtonWrapper = styled.div`
  width: 30%;

  && button {
    max-width: 50%;
  }
`;

export const PivotDropPlaceholder = styled(DropPlaceholder)`
  p,
  p:hover {
    color: ${grayScale.mediumDark};
  }

  & > div > div {
    border: 1px dashed ${grayScale.mediumLight};
    background: ${appBackground};
    margin-top: ${({ margin }) => margin ?? 5}px;

    padding-bottom: 14px;
    margin-right: 18px;
    ${({ over }) =>
      over
        ? css`
            border-style: solid;
          `
        : ''}
  }
`;

export const BuilderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ minHeight }) =>
    minHeight
      ? css`
          height: 270px;
        `
      : css`
          height: 420px;
        `}
`;

export const StyledDropPlaceholder = styled(DropPlaceholder)`
  & > div > div {
    border: 1px dashed ${grayScale.mediumLight};
    background: ${appBackground};
    margin-top: ${({ margin }) => margin ?? 5}px;

    padding-bottom: 14px;
    margin-right: 18px;
    ${({ over }) =>
      over
        ? css`
            border-style: solid;
          `
        : ''}
  }
  p {
    color: ${grayScale.mediumDark};
  }
  p:hover {
    color: ${grayScale.dark};
  }
`;

export const BreakDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
`;

export const RightAligned = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
`;

export const LeftColumnWrapper = styled.div`
  padding-top: 14px;
  padding-right: 20px;
  margin-left: -1px; // design nudge
`;

export const TextWrapper = styled.div`
  max-width: ${({ showDropdown }) => (showDropdown ? '290' : '400')}px;
`;

export const EditableField = styled(EditableText)`
  max-width: 100%;
  padding-right: 10px;
`;
