import styled from '@emotion/styled';
import TextInput from 'components/Inputs/TextInput';
import PageToolbar from 'components/Layout/PageToolbar';
import TableScrollContainer from 'components/Tables/ScrollContainer';

export const MobileToolbar = styled(PageToolbar)`
  /* height of search + spacing + title - leading of title font */
  height: ${36 + 20 + (21 - 10)}px;
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MobileTBSearchInput = styled(TextInput)`
  border-radius: 18px;
`;

export const TableScrollWrapper = styled(TableScrollContainer)`
  .gradient-vertical-container {
    max-width: 100%;
  }
`;

export const StyledWrapper = styled.div`
  width: 100%;
`;
