import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SelectInline from 'components/Inputs/inline/Select';
import { FIELD_TYPES } from 'utility/constants';

const getOptionValue = (val, opt) => {
  const [yes, no] = opt;
  return val ? yes : no;
};

const getFieldValue = (opt) => {
  const val = opt && opt.value;
  return val === 'yes';
};

export default function CheckboxInlineField({
  value,
  onSubmit,
  field,
  children: selectEl,
  ...others
}) {
  const { t } = useTranslation();
  const options = [
    { value: 'yes', label: t('Yes') },
    { value: 'no', label: t('No') },
  ];

  return (
    <SelectInline
      onSubmit={(opt) => onSubmit(getFieldValue(opt))}
      {...others}
      field={field}
    >
      {React.cloneElement(selectEl, {
        value: getOptionValue(value, options),
        options,
        placeholder: FIELD_TYPES.Checkbox.localizedPlaceholder(t),
      })}
    </SelectInline>
  );
}

CheckboxInlineField.propTypes = {
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

CheckboxInlineField.defaultProps = {
  value: null,
};
