import { AREA_RESPONSES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { CHART_TYPES } from 'components/Wizards/shared/components/chart/types';
import { debugLog } from 'utility/debug';
import {
  AREA_RESPONSES as RTDV_AREA_RESPONSES,
  SALES_REPORT_TYPES,
} from 'components/Wizards/RTDV/types';

/*
 * Get the constraints for the dashlets. This controls the max and minimum
 * dimensions for each dashlet type, so we aren't reliant on them being
 * stored in the database
 */
export const getDashletConstraints = (dashletData) => {
  // Special case for static content
  if (dashletData.area === 'static_content') {
    return {
      minW: 2,
      maxW: 12,
      minH: 1,
      maxH: 99, // effectively unlimited
    };
  }
  if (dashletData.reportType?.value === RTDV_AREA_RESPONSES.PIVOT_TABLE) {
    return {
      minW: 6,
      minH: 3,
      maxW: 12,
      maxH: 7,
    };
  }
  if (dashletData.reportType?.value === RTDV_AREA_RESPONSES.TABLE) {
    return {
      minW: 6,
      minH: 3,
      maxW: 12,
      maxH: 7,
    };
  }
  if (dashletData.reportType?.value === RTDV_AREA_RESPONSES.SALES) {
    if (
      dashletData.salesReportType?.value ===
      SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME
    ) {
      return {
        minW: 4,
        minH: 3,
        maxW: 12,
        maxH: 5,
      };
    }
  }
  if (dashletData.area === AREA_RESPONSES.PIPELINES) {
    if (dashletData.reportType?.value === REPORT_TYPES.OPPORTUNITY_CONVERSION) {
      return {
        minW: 4,
        minH: 3,
        maxW: 12,
        maxH: 5,
      };
    }
    if (dashletData.reportType?.value === REPORT_TYPES.VALUE_OVER_TIME) {
      return {
        minW: 4,
        minH: 3,
        maxW: 12,
        maxH: 5,
      };
    }
    if (
      dashletData.reportType?.value ===
      REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS
    ) {
      return {
        minW: 4,
        minH: 3,
        maxW: 12,
        maxH: 5,
      };
    }
    if (dashletData.reportType?.value === REPORT_TYPES.STAGES_OVER_TIME) {
      return {
        minW: 4,
        minH: 3,
        maxW: 12,
        maxH: 5,
      };
    }
    if (dashletData.display === CHART_TYPES.SUMMARY) {
      if (dashletData.compare) {
        return {
          minW: 3,
          minH: 2,
          maxW: 4,
          maxH: 2,
        };
      }
      return {
        minW: 2,
        minH: 1,
        maxW: 3,
        maxH: 3,
      };
    } else if (dashletData.display === CHART_TYPES.TREND) {
      return {
        minW: 4,
        minH: 3,
        maxW: 12,
        maxH: 5,
      };
    }
  } else if (dashletData.area === AREA_RESPONSES.ACTIVITIES) {
    if (dashletData.reportType?.value === REPORT_TYPES.SCHEDULED_ACTIVITIES) {
      return {
        minW: 6,
        minH: 3,
        maxW: 12,
        maxH: 7,
      };
    }
    if (
      dashletData.reportType?.value ===
      REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS
    ) {
      if (dashletData.display === CHART_TYPES.SUMMARY) {
        if (dashletData.compare) {
          return {
            minW: 3,
            minH: 2,
            maxW: 4,
            maxH: 2,
          };
        }
        return {
          minW: 2,
          minH: 1,
          maxW: 3,
          maxH: 3,
        };
      }
      if (dashletData.display === CHART_TYPES.TREND) {
        return {
          minW: 4,
          minH: 3,
          maxW: 12,
          maxH: 5,
        };
      }
    }
  } else if (dashletData.area === AREA_RESPONSES.EMAILS) {
    if (dashletData.reportType?.value === REPORT_TYPES.INTERACTION_STATS) {
      return {
        minW: 10,
        minH: 4,
        maxW: 12,
        maxH: 7,
      };
    } else if (dashletData.display === CHART_TYPES.SUMMARY) {
      if (dashletData.compare) {
        return {
          minW: 3,
          minH: 2,
          maxW: 4,
          maxH: 2,
        };
      }
      return {
        minW: 2,
        minH: 1,
        maxW: 3,
        maxH: 3,
      };
    } else if (dashletData.display === CHART_TYPES.TREND) {
      return {
        minW: 4,
        minH: 3,
        maxW: 12,
        maxH: 5,
      };
    }
  } else if (dashletData.area === AREA_RESPONSES.MARKETING) {
    if (dashletData.reportType?.value === REPORT_TYPES.LEADS_ADDED_BY_SOURCE) {
      if (dashletData.display === CHART_TYPES.BAR) {
        return {
          minW: 4,
          minH: 3,
          maxW: 12,
          maxH: 5,
        };
      } else if (dashletData.display === CHART_TYPES.DONUT) {
        return {
          minW: 3,
          minH: 3,
          maxW: 4,
          maxH: 4,
        };
      } else if (dashletData.display === CHART_TYPES.TREND) {
        return {
          minW: 4,
          minH: 3,
          maxW: 12,
          maxH: 5,
        };
      }
    } else if (dashletData.reportType?.value === REPORT_TYPES.LEADS_ADDED) {
      if (dashletData.display === CHART_TYPES.SUMMARY) {
        if (dashletData.compare) {
          return {
            minW: 3,
            minH: 2,
            maxW: 4,
            maxH: 2,
          };
        }
        return {
          minW: 2,
          minH: 1,
          maxW: 3,
          maxH: 3,
        };
      }
      if (dashletData.display === CHART_TYPES.TREND) {
        return {
          minW: 4,
          minH: 3,
          maxW: 12,
          maxH: 5,
        };
      }
    } else if (
      dashletData.reportType?.value === REPORT_TYPES.LEAD_SOURCE_BREAKDOWN
    ) {
      return {
        minW: 4,
        minH: 3,
        maxW: 12,
        maxH: 5,
      };
    }
  } else {
    if (dashletData.reportType?.value === RTDV_AREA_RESPONSES.METRICS) {
      if (dashletData.display === CHART_TYPES.SUMMARY) {
        return {
          minW: 2,
          minH: 1,
          maxW: 3,
          maxH: 3,
        };
      }
      if (dashletData.display === CHART_TYPES.DONUT) {
        return {
          minW: 3,
          minH: 3,
          maxW: 4,
          maxH: 4,
        };
      }
      if (dashletData.display === CHART_TYPES.BAR) {
        return {
          minW: 4,
          minH: 3,
          maxW: 12,
          maxH: 5,
        };
      }
    }
  }
  debugLog('Unknown dashlet config for constraints', dashletData);
  return {};
};

/*
 * These are the dimensions that are created by default on a dashlet
 * that react-grid-layout can understand as width and height constraints
 */
export const getDashletDimensions = (dashletData) => {
  const constraints = getDashletConstraints(dashletData);
  if (dashletData.reportType?.value === RTDV_AREA_RESPONSES.PIVOT_TABLE) {
    return {
      w: 12,
      h: 5,
      ...constraints,
    };
  }
  if (dashletData.reportType?.value === RTDV_AREA_RESPONSES.TABLE) {
    return {
      w: 12,
      h: 5,
      ...constraints,
    };
  }
  if (dashletData.reportType?.value === RTDV_AREA_RESPONSES.SALES) {
    if (
      dashletData.salesReportType?.value ===
      SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME
    ) {
      return {
        w: 6,
        h: 3,
        ...constraints,
      };
    }
  }
  if (dashletData.area === AREA_RESPONSES.PIPELINES) {
    if (dashletData.reportType?.value === REPORT_TYPES.OPPORTUNITY_CONVERSION) {
      return {
        w: 6,
        h: 3,
        ...constraints,
      };
    }
    if (dashletData.reportType?.value === REPORT_TYPES.VALUE_OVER_TIME) {
      return {
        w: 6,
        h: 3,
        ...constraints,
      };
    }
    if (
      dashletData.reportType?.value ===
      REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS
    ) {
      return {
        w: 6,
        h: 3,
        ...constraints,
      };
    }
    if (dashletData.reportType?.value === REPORT_TYPES.STAGES_OVER_TIME) {
      return {
        w: 6,
        h: 3,
        ...constraints,
      };
    }
    if (dashletData.display === CHART_TYPES.SUMMARY) {
      if (dashletData.compare) {
        return {
          w: 3,
          h: 2,
          ...constraints,
        };
      }
      return {
        w: 2,
        h: 2,
        ...constraints,
      };
    } else if (dashletData.display === CHART_TYPES.TREND) {
      return {
        w: 6,
        h: 3,
        ...constraints,
      };
    }
  } else if (dashletData.area === AREA_RESPONSES.ACTIVITIES) {
    if (dashletData.reportType?.value === REPORT_TYPES.SCHEDULED_ACTIVITIES) {
      return {
        w: 12,
        h: 5,
        ...constraints,
      };
    }
    if (
      dashletData.reportType?.value ===
      REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS
    ) {
      if (dashletData.display === CHART_TYPES.SUMMARY) {
        if (dashletData.compare) {
          return {
            w: 3,
            h: 2,
            ...constraints,
          };
        }
        return {
          w: 2,
          h: 2,
          ...constraints,
        };
      }
      if (dashletData.display === CHART_TYPES.TREND) {
        return {
          w: 6,
          h: 3,
          ...constraints,
        };
      }
    }
  } else if (dashletData.area === AREA_RESPONSES.EMAILS) {
    if (dashletData.reportType?.value === REPORT_TYPES.INTERACTION_STATS) {
      return {
        w: 12,
        h: 4,
        ...constraints,
      };
    } else if (dashletData.display === CHART_TYPES.SUMMARY) {
      if (dashletData.compare) {
        return {
          w: 3,
          h: 2,
          ...constraints,
        };
      }
      return {
        w: 2,
        h: 2,
        ...constraints,
      };
    } else if (dashletData.display === CHART_TYPES.TREND) {
      return {
        w: 6,
        h: 3,
        ...constraints,
      };
    }
  } else if (dashletData.area === AREA_RESPONSES.MARKETING) {
    if (dashletData.reportType?.value === REPORT_TYPES.LEADS_ADDED_BY_SOURCE) {
      if (dashletData.display === CHART_TYPES.BAR) {
        return {
          w: 6,
          h: 3,
          ...constraints,
        };
      } else if (dashletData.display === CHART_TYPES.DONUT) {
        return {
          w: 3,
          h: 3,
          ...constraints,
        };
      } else if (dashletData.display === CHART_TYPES.TREND) {
        return {
          w: 6,
          h: 3,
          ...constraints,
        };
      }
    } else if (dashletData.reportType?.value === REPORT_TYPES.LEADS_ADDED) {
      if (dashletData.display === CHART_TYPES.SUMMARY) {
        if (dashletData.compare) {
          return {
            w: 3,
            h: 2,
            ...constraints,
          };
        }
        return {
          w: 2,
          h: 2,
          ...constraints,
        };
      }
      if (dashletData.display === CHART_TYPES.TREND) {
        return {
          w: 6,
          h: 3,
          ...constraints,
        };
      }
    } else if (
      dashletData.reportType?.value === REPORT_TYPES.LEAD_SOURCE_BREAKDOWN
    ) {
      return {
        w: 6,
        h: 3,
        ...constraints,
      };
    }
  } else {
    if (dashletData.reportType?.value === RTDV_AREA_RESPONSES.METRICS) {
      if (dashletData.display === CHART_TYPES.SUMMARY) {
        return {
          w: 2,
          h: 2,
          ...constraints,
        };
      }
      if (dashletData.display === CHART_TYPES.DONUT) {
        return {
          w: 3,
          h: 3,
          ...constraints,
        };
      }
      if (dashletData.display === CHART_TYPES.BAR) {
        return {
          w: 6,
          h: 3,
          ...constraints,
        };
      }
    }
  }
  debugLog('Unknown dashlet config for dimensions', dashletData);
  return {};
};
