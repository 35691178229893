import { useCallback } from 'react';
import { flushSync } from 'react-dom';
import { useDispatch } from 'react-redux';

// React 18 batches state updates for performance. This is generally good and gives us a
// performance boost. However, some places in the app rely on UI updates happening synchronously,
// so we need to use this special hook in those places.
export const useSyncDispatch = () => {
  const dispatch = useDispatch();

  const syncDispatch = useCallback(
    (action: any) => {
      flushSync(() => {
        dispatch(action);
      });
    },
    [dispatch]
  );

  return syncDispatch;
};
