import { ImageProps } from '../types';

type File = {
  url: string;
  name: string;
  id: string;
};

export const getImageDimensions = (src: string): Promise<[number, number]> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve([img.naturalWidth, img.naturalHeight]);
    };
    img.onerror = () => {
      reject('Image failed to load.');
    };
    img.src = src;
  });
};

export const getDefaultImageProps = async (
  file: File
): Promise<Partial<ImageProps>> => {
  let naturalWidth, naturalHeight;

  try {
    const [width, height] = await getImageDimensions(file.url);
    naturalWidth = width;
    naturalHeight = height;
  } catch {}

  return {
    src: file.url,
    name: file.name,
    fileId: file.id,
    naturalHeight,
    naturalWidth,
    size: 'auto',
    unit: 'pixel',
    height: null,
    width: null,
    display: 'flex',
    position: 'center',
    alt: '',
    link: '',
  };
};
