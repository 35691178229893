import { useCallback, useMemo, useRef } from 'react';

import {
  EmailSendOptionsHook,
  EmailSendOptionsHookReturn,
  EmailSendOptionsProps,
  EmailSendOptionsTeamMember,
} from './types';

import { SelectOption } from 'pages/AutomationEngine/types';

import { useTranslation } from 'react-i18next';
import useAsync from 'react-use/lib/useAsync';

import {
  fromTypeOptionsTranslated,
  notifyMemberViaEmailTypes,
} from '__pages/AutomationEngine/helpers';

import TeamMemberService from 'services/TeamMemberService';

import Select from '__components/Inputs/Select';
import MultiSelect from '__components/Inputs/MultiSelect';
import ClearSelectButton from '__components/Inputs/Select/ClearButton';
import ApplySelectButton from '__components/Inputs/Select/ApplyButton';
import { TrackingWarning, TrackingWrapper } from './styles';
import { useAutomationsSelector } from 'pages/AutomationEngine/store/react';
import { getIsForCustomObject } from 'pages/AutomationEngine/store/selectors';
import { getAutomationCategorizedTeamMemberFields } from 'pages/AutomationEngine/store/selectors';
import { getDataQAForInput } from 'components/Inputs/helpers';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from '__components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import { SENDER_TYPE } from 'components/MessageBuilder/components';

const defaultTeamMember = (
  addPrefixNone: boolean
): EmailSendOptionsTeamMember => ({
  type: addPrefixNone
    ? notifyMemberViaEmailTypes.NONE
    : notifyMemberViaEmailTypes.LAST_ACTIVE,
  role: null,
  employees: [],
  field: null,
});

export const useEmailSendOptions = (
  props: EmailSendOptionsHook
): EmailSendOptionsHookReturn => {
  const { values, onChange, whichTeamMember, t, teamSelectRef, addPrefixNone } =
    props;

  const contextIsCustomObject = useAutomationsSelector(getIsForCustomObject);
  const fromTypeOptions: SelectOption[] = useMemo(
    () => fromTypeOptionsTranslated(t, contextIsCustomObject, addPrefixNone),
    [contextIsCustomObject, t, addPrefixNone]
  );

  const teamFields = useAutomationsSelector(
    getAutomationCategorizedTeamMemberFields
  );

  const teamMember =
    (whichTeamMember === 'teamMember'
      ? values.teamMember
      : values.ccTeamMember) || defaultTeamMember(addPrefixNone);

  const { type, role, employees, field } = teamMember;

  const teamMemberType = fromTypeOptions.find(({ value }) => value === type);

  const { value: roleOptions = [], loading } = useAsync(
    async () => await TeamMemberService.getRolesOptionsList(),
    []
  );

  const chosenValueIds = useMemo(
    () => (employees || []).map((item: any) => item.value ?? item.id),
    [employees]
  );

  const [teamSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: teamSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds,
  });

  const handleTypeChange = useCallback(
    (opt: SelectOption) =>
      onChange({
        ...values,
        [whichTeamMember]: {
          type: opt.value,
        },
      }),
    [onChange, values, whichTeamMember]
  );

  const handleRoleChange = useCallback(
    (opt: Pick<EmailSendOptionsTeamMember, 'role'>) =>
      onChange({
        ...values,
        [whichTeamMember]: {
          type: type,
          role: opt,
        },
      }),
    [onChange, values, type, whichTeamMember]
  );

  const handleTeamMemberChange = useCallback(
    (opt: Pick<EmailSendOptionsTeamMember, 'employees'>) =>
      onChange({
        ...values,
        [whichTeamMember]: {
          type: type,
          employees: opt,
        },
      }),
    [onChange, values, type, whichTeamMember]
  );

  const handleTeamMemberFieldChange = useCallback(
    (opt: Pick<EmailSendOptionsTeamMember, 'field'>) =>
      onChange({
        ...values,
        [whichTeamMember]: {
          type: type,
          field: opt,
        },
      }),
    [onChange, values, type, whichTeamMember]
  );

  return {
    type,
    role,
    employees,
    field,
    loading,
    roleOptions,
    teamSelectProps,
    teamFields,
    teamMemberType,
    handleTypeChange,
    handleRoleChange,
    handleTeamMemberChange,
    handleTeamMemberFieldChange,
  };
};

export const EmailSendOptions = (props: EmailSendOptionsProps) => {
  const { t } = useTranslation();

  const {
    values,
    onChange,
    transxHook,
    whichTeamMember,
    label = t('Team Member to Notify'),
    addPrefixNone = false,
    margin = true,
    allowTrackingWarning = false,
  } = props;

  const teamSelectRef = useRef();
  const contextIsCustomObject = useAutomationsSelector(getIsForCustomObject);
  const fromTypeOptions = useMemo(
    () => fromTypeOptionsTranslated(t, contextIsCustomObject, addPrefixNone),
    [contextIsCustomObject, t, addPrefixNone]
  );
  const {
    type,
    role,
    employees,
    field,
    loading,
    roleOptions,
    teamSelectProps,
    teamFields,
    teamMemberType,
    handleTypeChange,
    handleRoleChange,
    handleTeamMemberChange,
    handleTeamMemberFieldChange,
  } = transxHook({
    values,
    onChange,
    whichTeamMember,
    t,
    teamSelectRef,
    addPrefixNone,
  });

  const showTrackingWarning = useMemo(
    () =>
      allowTrackingWarning &&
      (values?.email?.sender?.type === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX ||
        values?.email?.senderType === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX) &&
      teamMemberType.value !== notifyMemberViaEmailTypes.NONE,
    [
      allowTrackingWarning,
      teamMemberType,
      values?.email?.senderType,
      values?.email?.sender?.type,
    ]
  );

  return (
    <>
      <div>
        <Select
          label={label}
          placeholder={t('Choose Who to Notify')}
          menuInline
          margin={margin}
          options={fromTypeOptions}
          value={teamMemberType}
          onChange={handleTypeChange}
          {...getDataQAForInput('who-to-notify', 'dropdown')}
        />
        {showTrackingWarning ? (
          <TrackingWrapper>
            <TrackingWarning>
              {t(
                `Open and Click tracking will be affected by CC'd team members`
              )}
            </TrackingWarning>
          </TrackingWrapper>
        ) : null}
      </div>
      {type === notifyMemberViaEmailTypes.LAST_ACTIVE_ROLE ? (
        <Select
          label={t('Role')}
          placeholder={t('Choose Role')}
          menuInline
          margin={margin}
          loadItems={loading}
          options={roleOptions}
          value={role}
          onChange={handleRoleChange}
          {...getDataQAForInput('role', 'dropdown')}
        />
      ) : null}
      {type === notifyMemberViaEmailTypes.SPECIFIC_TEAM_MEMBERS ? (
        <MultiSelect
          ref={teamSelectRef}
          label={t('Team Members')}
          placeholder={t('Choose Team Members')}
          menuInline
          margin={margin}
          loadItems={loading}
          value={employees}
          onChange={handleTeamMemberChange}
          menuLeftButton={<ClearSelectButton />}
          menuRightButton={<ApplySelectButton />}
          {...teamSelectProps}
          {...getDataQAForInput('team-member', 'dropdown')}
        />
      ) : null}
      {type === notifyMemberViaEmailTypes.TEAM_SELECTOR_FIELD && (
        <Select
          label={t('Team Selector Field')}
          menuInline
          loadItems={loading}
          margin={margin}
          placeholder={t('Choose Team Selector Field')}
          labelInfo={t(
            'In the event there is no selected team member in the chosen field, this notification will go to the last active team member on the record.'
          )}
          labelInfoPlacement="top"
          options={teamFields}
          value={field}
          onChange={handleTeamMemberFieldChange}
          menuLeftButton={<ClearSelectButton />}
          menuRightButton={<ApplySelectButton />}
          {...getDataQAForInput('team-selector-field', 'dropdown')}
        />
      )}
    </>
  );
};
