import { NodeProps, Section, Take } from '../types';

type Props = Partial<NodeProps['Section']>;
type Children = Section['2'] | undefined;
type Args = [] | [Props] | [Children] | [Props, Children];

const destructureArgs = (...args: Args): [Props] | [Props, Children] => {
  if (args[0] === undefined) return [{}];
  if (args.length === 2) return args;
  if (Array.isArray(args[0])) return [{}, args[0]];
  return [args[0]];
};

export function section(): Take<Section, 2>;
export function section(props: Props): Take<Section, 2>;
export function section(children: Children): Section;
export function section(props: Props, children: Children): Section;
export function section(...args: Args): Section {
  const [props, children] = destructureArgs(...args);
  return Array.isArray(children)
    ? ['Section', props, children]
    : ['Section', props];
}
