export const SORT_BY = {
  NAME: 'automation_name',
  STATUS: 'status',
  START_TIME: 'start_time',
  COMPLETED: 'completed',
};

export const DEFAULT_PAGINATION_SETTINGS = {
  search: '',
  page: 1,
  pageSize: 50,
  ordering: SORT_BY.START_TIME,
};
