import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { borderRadii, gutters } from 'app/spacing';
import WholeNumberTextInput from 'components/Inputs/TextInput/presets/WholeNumber';

const MarginPaddingSettingsContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(9, 1fr);
`;

const PaddingSettingsContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  border: 1px solid ${grayScale.mediumDark};
  border-radius: ${borderRadii.standard};
  overflow: hidden;
`;

const StyledNumberInput = styled(WholeNumberTextInput)`
  justify-content: center;
  height: auto;
  border: none;
  border-bottom: 1px solid ${grayScale.mediumDark};
  border-radius: 0;
  max-width: 25px;

  input {
    text-align: center;
    padding-bottom: ${gutters.spacing(1, 3)}px;
  }
`;

const NumberInputContainer = styled.div`
  grid-row: ${({ row }) => row};
  grid-column: ${({ column }) => column};
  display: flex;
  justify-content: ${({ justify = 'center' }) => justify};
  align-items: center;
  overflow: hidden;
  margin: ${gutters.spacing()}px;
`;

const ensureNumber = (value, fallback = '0') => {
  return isNaN(Number(value)) ? fallback : value;
};

const useBlur = () =>
  useCallback((cb) => {
    return (ev) => cb(ensureNumber(ev.target.value));
  }, []);

export const PaddingSettings = ({
  top,
  right,
  bottom,
  left,
  onTopChange,
  onRightChange,
  onBottomChange,
  onLeftChange,
  className,
  style,
}) => {
  const handleBlur = useBlur();

  return (
    <PaddingSettingsContainer className={className} style={style}>
      <NumberInputContainer row="1 / 3" column={2}>
        <StyledNumberInput
          value={top}
          onChange={onTopChange}
          onBlur={handleBlur(onTopChange)}
          thousandSeparator={false}
        />
      </NumberInputContainer>
      <NumberInputContainer row="2 / 5" column={3} justify="flex-end">
        <StyledNumberInput
          value={right}
          onChange={onRightChange}
          onBlur={handleBlur(onRightChange)}
          thousandSeparator={false}
        />
      </NumberInputContainer>
      <NumberInputContainer row="4 / 6" column={2}>
        <StyledNumberInput
          value={bottom}
          onChange={onBottomChange}
          onBlur={handleBlur(onBottomChange)}
          thousandSeparator={false}
        />
      </NumberInputContainer>
      <NumberInputContainer row="2 / 5" column={1} justify="flex-start">
        <StyledNumberInput
          value={left}
          onChange={onLeftChange}
          onBlur={handleBlur(onLeftChange)}
          thousandSeparator={false}
        />
      </NumberInputContainer>
    </PaddingSettingsContainer>
  );
};

const PaddingSettingsInMargin = styled(PaddingSettings)`
  grid-area: 3 / 2 / 8 / 5;
`;

const MarginRightContainer = styled(NumberInputContainer)`
  margin-bottom: ${gutters.spacing(1, 1)}px;
`;
const MarginLeftContainer = MarginRightContainer;

export const MarginAndPaddingSettings = ({
  topMargin,
  rightMargin,
  bottomMargin,
  leftMargin,
  topPadding,
  rightPadding,
  bottomPadding,
  leftPadding,
  onTopMarginChange,
  onRightMarginChange,
  onBottomMarginChange,
  onLeftMarginChange,
  onTopPaddingChange,
  onRightPaddingChange,
  onBottomPaddingChange,
  onLeftPaddingChange,
  className,
  style,
}) => {
  const handleBlur = useBlur();

  return (
    <MarginPaddingSettingsContainer className={className} style={style}>
      <NumberInputContainer row="1 / 3" column={3}>
        <StyledNumberInput
          value={topMargin}
          onChange={onTopMarginChange}
          onBlur={handleBlur(onTopMarginChange)}
          thousandSeparator={false}
          allowNegatives={true}
        />
      </NumberInputContainer>
      <MarginRightContainer row="4 / 7" column={5} justify="flex-start">
        <StyledNumberInput
          value={rightMargin}
          onChange={onRightMarginChange}
          onBlur={handleBlur(onRightMarginChange)}
          thousandSeparator={false}
          allowNegatives={true}
        />
      </MarginRightContainer>
      <NumberInputContainer row="8 / 10" column={3}>
        <StyledNumberInput
          value={bottomMargin}
          onChange={onBottomMarginChange}
          onBlur={handleBlur(onBottomMarginChange)}
          thousandSeparator={false}
          allowNegatives={true}
        />
      </NumberInputContainer>
      <MarginLeftContainer row="4 / 7" column={1} justify="flex-end">
        <StyledNumberInput
          value={leftMargin}
          onChange={onLeftMarginChange}
          onBlur={handleBlur(onLeftMarginChange)}
          thousandSeparator={false}
          allowNegatives={true}
        />
      </MarginLeftContainer>
      <PaddingSettingsInMargin
        top={topPadding}
        right={rightPadding}
        bottom={bottomPadding}
        left={leftPadding}
        onTopChange={onTopPaddingChange}
        onRightChange={onRightPaddingChange}
        onBottomChange={onBottomPaddingChange}
        onLeftChange={onLeftPaddingChange}
      />
    </MarginPaddingSettingsContainer>
  );
};

PaddingSettings.propTypes = {
  top: PropTypes.number.isRequired,
  right: PropTypes.number.isRequired,
  bottom: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  onTopChange: PropTypes.func.isRequired,
  onRightChange: PropTypes.func.isRequired,
  onBottomChange: PropTypes.func.isRequired,
  onLeftChange: PropTypes.func.isRequired,
};

MarginAndPaddingSettings.propTypes = {
  topPadding: PropTypes.number.isRequired,
  rightPadding: PropTypes.number.isRequired,
  bottomPadding: PropTypes.number.isRequired,
  leftPadding: PropTypes.number.isRequired,
  onTopPaddingChange: PropTypes.func.isRequired,
  onRightPaddingChange: PropTypes.func.isRequired,
  onBottomPaddingChange: PropTypes.func.isRequired,
  onLeftPaddingChange: PropTypes.func.isRequired,
  topMargin: PropTypes.number.isRequired,
  rightMargin: PropTypes.number.isRequired,
  bottomMargin: PropTypes.number.isRequired,
  leftMargin: PropTypes.number.isRequired,
  onTopMarginChange: PropTypes.func.isRequired,
  onRightMarginChange: PropTypes.func.isRequired,
  onBottomMarginChange: PropTypes.func.isRequired,
  onLeftMarginChange: PropTypes.func.isRequired,
};
