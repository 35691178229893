import { getColumnsForClient } from './client';
import { getColumnsForObject } from './object';

export const getSmallColumns = ({
  columns = [],
  fieldsById = {},
  preReleaseFeatures,
  model,
  fields,
  t,
  contacts,
  onResizeColumnWidth,
  onResizeStop,
  getColumnWidth,
  narrowestWidth,
  minWidth,
  resizable,
}) => {
  if (contacts) {
    return getColumnsForClient({
      columns,
      fields,
      fieldsById,
      onResizeColumnWidth,
      onResizeStop,
      getColumnWidth,
      narrowestWidth,
      minWidth,
      resizable,
      model,
    });
  }

  return getColumnsForObject(
    {
      columns,
      fields,
      model,
      preReleaseFeatures,
      fieldsById,
      onResizeColumnWidth,
      onResizeStop,
      getColumnWidth,
      narrowestWidth,
      minWidth,
      resizable,
    },
    t
  );
};
