import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import SmallBoard from 'components/Board/SmallBoard';
import MobileColumn from './MobileColumn';

const Wrapper = styled.div`
  margin: ${gutters.standard} -${gutters.standard} 0;
`;

const StyledColumn = styled(MobileColumn)`
  min-width: calc(100vw - 90px); // 45px*2 padding-left/right
  scroll-snap-align: center;
  margin-right: ${gutters.spacing(2)}px;
`;

const MobileBoard = ({
  boardData,
  model,
  fields,
  stages,
  getMoreCards,
  onSelectStageColumn,
  onChoseCardMenu,
  onChoseNewRecord,
  onSubmitRecord,
  onChangeStage,
}) => {
  const handleChangeSelection = (data, stageId) => {
    if (data.value === 'all') {
      onSelectStageColumn({ selection: true, stageId });
    } else {
      onSelectStageColumn({ selection: false, stageId });
    }
  };

  return (
    <Wrapper>
      <SmallBoard columnAmount={boardData.stages.length}>
        {boardData.stages.map((item) => (
          <StyledColumn
            key={item.id}
            className="column"
            data={item}
            cards={item.cards}
            model={model}
            settings={boardData.settings}
            fields={fields}
            stages={stages}
            getMoreCards={getMoreCards}
            onSubmitRecord={onSubmitRecord}
            onChangeStage={onChangeStage}
            onChangeSelection={(data) => handleChangeSelection(data, item.id)}
            onChoseMenu={(data, card) => onChoseCardMenu(data, card, item.id)}
            onClickPlus={() =>
              onChoseNewRecord({ value: 'newRecord', stageId: item.id })
            }
          />
        ))}
      </SmallBoard>
    </Wrapper>
  );
};

MobileBoard.propTypes = {
  boardData: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  stages: PropTypes.array.isRequired,
  getMoreCards: PropTypes.func.isRequired,
  onSelectStageColumn: PropTypes.func.isRequired,
  onChoseCardMenu: PropTypes.func.isRequired,
  onChoseNewRecord: PropTypes.func.isRequired,
  onSubmitRecord: PropTypes.func.isRequired,
  onChangeStage: PropTypes.func.isRequired,
};

export default MobileBoard;
