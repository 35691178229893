import { forwardRef } from 'react';

import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import MultiSelect from 'components/Inputs/MultiSelect';
import { ViewOnlyMultiSelect } from 'components/Inputs/MultiSelect/ViewOnlyMultiSelect';

import MultiDropdownInlineField from 'components/Fields/InlineTableFieldInput/Checkboxes';
import { withCellWrapper } from './helpers';

const _CheckboxesCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    return (
      <MultiDropdownInlineField
        ref={ref}
        {...others}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        viewOnlySelect={readOnly}
        onAutoFocus={onAutoFocus}
        field={field}
        object={object}
      >
        {readOnly ? (
          <ViewOnlyMultiSelect value={value} />
        ) : (
          <MultiSelect
            menuLeftButton={menuLeftButton}
            menuRightButton={<ApplySelectOverlayButton />}
          />
        )}
      </MultiDropdownInlineField>
    );
  }
);

export const CheckboxesCell = withCellWrapper(_CheckboxesCell);
