import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { debounce, defaultsDeep } from 'lodash';
import useField from 'hooks/useField';
import { grayScale } from 'app/colors';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { Dot } from 'components/Fields/ColorLabel';
import { getDataQAForInput } from '../helpers';
import SelectOverlay from '../SelectOverlay';
import { applyRef } from '../props';
import TextControl from './TextControl';
import { basePopperConfig, baseModalPopperConfig } from './helpers';
import { popperModifiers } from 'components/helpers';
import { usePrevious } from 'react-use';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { RelationshipConfirmationModal } from 'components/Modals/RelationshipConfirmationModal';
import { useRelationshipSubmit } from './useRelationshipSubmit';

const LabelWithTooltip = styled(TextEllipsisWithTooltip)`
  // Ensures alignment is correct when parent is displaying inline, i.e. in automation step card
  display: inline;
`;

export function SelectInlineLabel({
  value = null,
  to = null,
  type = null,
  disabled = null,
  children,
  isColored = null,
  ...props
}) {
  const label = children || (value && value.label);
  const color = isColored && value && value.color;
  return (
    <LabelWithTooltip
      to={!value ? null : to}
      type={!value || (disabled && type === 'link') ? null : type}
      disabled={disabled}
      color={disabled ? grayScale.mediumDark : undefined}
      {...props}
    >
      {!children && label && color && <Dot color={color} />}
      {label || '—'}
    </LabelWithTooltip>
  );
}

// baseModalPopperConfig sets the zIndex to zIndexes.popoverInModal
const modalNudgePopperUp = defaultsDeep({}, baseModalPopperConfig, {
  modifiers: [
    popperModifiers.offSet(0, -9), // Align directly under EditableText text
  ],
});

const nudgePopperUp = defaultsDeep({}, basePopperConfig, {
  modifiers: [
    popperModifiers.offSet(0, -3), // Align directly under TextControl text
  ],
});

export const defaultOffsets = {
  modifiers: [
    popperModifiers.offSet(0, -3), // Touch top of text
    {
      name: 'preventOverflow',
      enabled: false,
    },
  ],
};

export default function SelectInline({
  icon = 'chevron-down',
  field,
  object,
  hoverable = true,
  onSubmit = null,
  autoFocus = null,
  onAutoFocus = null,
  submitUnchanged = null,
  readOnly = false,
  className,
  children: selectEl,
  labelEl = <SelectInlineLabel isColored={selectEl.props.isColored} />,
  showToolTip = false,
  placement = 'top',
  tooltipLabel = '',
  popperConfig = null,
  inModal = false,
  onHide,
  onClick,
  onModalOpen,
  setResetKeyListeners,
  menuWidth,
  shouldFocusNext,
  labelInfo,
  labelInfoPlacement,
  handleUpdateTableRecords,
  ...others
}) {
  const fieldId = useMemo(() => `${field?.id}-${object?.id}`, [field, object]);
  const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();
  // Setup state
  const selectRef = useRef();
  const layoutRef = useRef();
  const [show, setShow] = useState(false);
  const prevShow = usePrevious(show);
  const [submitting, setSubmitting] = useState(false);
  const { value: valueProp, options, onChange } = selectEl.props;
  const value =
    typeof valueProp === 'string'
      ? options.find((opt) => opt.value === valueProp) || null
      : valueProp;

  const [stagedValue, setStagedValue] = useField(value);
  const displayValue = onSubmit ? stagedValue : value;

  // Support autoFocus

  const onAutoFocusRef = useRef();
  onAutoFocusRef.current = onAutoFocus;

  useEffect(() => {
    if (!assignFieldHandle) {
      if (autoFocus) {
        if (onAutoFocusRef.current) onAutoFocusRef.current(layoutRef.current);
      }
      setShow(!!autoFocus);
    }
  }, [autoFocus, assignFieldHandle]);

  useEffect(() => {
    if (onHide && prevShow && !show) {
      onHide();
    }
  }, [show, onHide, prevShow]);

  // Handlers

  const handleChange = useCallback(
    async (val) => {
      if (onSubmit) {
        setStagedValue(val);
      } else {
        setShow(false);
      }
      if (onChange) onChange(val);
    },
    [onChange, onSubmit, setStagedValue]
  );

  const handleSubmit = async (forceSubmit = false, forceValue = null) => {
    if (submitUnchanged || forceSubmit || stagedValue !== value) {
      try {
        setSubmitting(true);
        await onSubmit(forceSubmit && forceValue ? forceValue : stagedValue);
        return true;
      } catch (err) {
        setStagedValue(value);
        return false;
      } finally {
        setSubmitting(false);
      }
    }
    return true;
  };

  const labelDataQa = getDataQAForInput(
    others['data-field-id'],
    `${others['data-field-type']}-value`
  );

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    {
      shouldUseKeyboardEventHandle: true,
      onBlur: (e, shouldGoNext) =>
        getKeyListenersProps?.(fieldId)?.onBlur(
          e,
          shouldGoNext && shouldFocusNext
        ),
      ...others,
    }
  );

  assignFieldHandle(fieldId, {
    customFocus: () => {
      onModalOpen?.(true);
      setShow(true);
      if (selectRef.current?.select) {
        selectRef.current.select.getNextFocusedOption = () => null;
      }
      selectRef.current?.select?.focus();
      getKeyListenersProps?.(fieldId)?.onFocus();
      return layoutRef.current;
    },
    customTab: async () => {
      onModalOpen?.(false);
      setShow(false);
      return !(await handleSubmit(true));
    },
    customEscape: () => {
      onModalOpen?.(false);
      setShow(false);
    },
    customEnter: async () => {
      onModalOpen?.(false);
      setShow(false);
      return !(await handleSubmit(true));
    },
    disabled: readOnly,
    shouldFocusNext: shouldFocusNext,
  });

  const {
    handleHide,
    handleConfirm,
    handleHideConfirm,
    handleChangeWithConfirmation,
    modal,
  } = useRelationshipSubmit({
    onSubmit: onSubmit && handleSubmit,
    field,
    stagedValue,
    value,
    setStagedValue,
    handleUpdateTableRecords,
    setShow,
    displayValue,
    handleChange,
  });
  // Render and display
  if (readOnly) {
    return (
      <TextControl
        className={className}
        icon={false}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            setShow(false);
          }
        }}
        labelInfo={labelInfo}
        labelInfoPlacement={labelInfoPlacement}
      >
        {cloneElement(labelEl, {
          value: displayValue,
          disabled: submitting,
          tooltipLabel,
          popperConfig,
          ...labelDataQa,
        })}
      </TextControl>
    );
  }

  const clickProps = {
    [icon ? 'onSelect' : 'onClick']: () => {
      setShow(true);
      getKeyListenersProps?.(fieldId)?.onFocus(fieldId);
      if (onClick) {
        onClick();
      }
    },
  };
  return (
    <>
      <TextControl
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            setShow(false);
          }
        }}
        ref={layoutRef}
        className={className}
        hoverable={hoverable}
        disabled={submitting}
        selected={show}
        icon={icon}
        {...clickProps}
      >
        {cloneElement(labelEl, {
          value: displayValue,
          disabled: submitting,
          onModalOpen: onModalOpen,
          tooltipLabel,
          popperConfig,
          ...labelDataQa,
        })}
      </TextControl>
      <SelectOverlay
        show={show}
        target={layoutRef.current}
        onHide={handleHide}
        popperConfig={inModal ? modalNudgePopperUp : nudgePopperUp}
        menuWidth={menuWidth}
        {...others}
      >
        {cloneElement(selectEl, {
          ...selectEl.props,
          onSubmit: handleSubmit,
          fieldId,
          ref: (el) => {
            applyRef(selectRef, el);
            applyRef(selectEl.ref, el);
          },
          value: displayValue,
          onChange: handleChangeWithConfirmation,
          setShown: setShow,
          ...getKeyListenersProps?.(fieldId),
          onBlur: (e) => {
            debounce(() => {
              if (getKeyListenersProps?.(fieldId)) {
                handleHide();
                getKeyListenersProps?.(fieldId)?.onBlur(e, shouldFocusNext);
              }
            }, 0);
          },
          ...customSelectMenuProps,
          tabSelectsValue: true,
        })}
      </SelectOverlay>
      <RelationshipConfirmationModal
        show={modal}
        onConfirm={handleConfirm}
        onHide={handleHideConfirm}
        className="rel-confirm-modal"
      />
    </>
  );
}
