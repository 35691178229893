import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { dropdownColors, grayScale } from 'app/colors';
import { gutters, layers, hideScrollbarCss } from 'app/spacing';
import Select from 'components/Inputs/Select';
import Icon from 'components/Kizen/Icon';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';
import { COLUMN_PADDING } from './constants';

const PREVIEW_HEIGHT = 50;

export const FitWrapper = styled.div`
  width: 100%;
  ${({ fitContent }) =>
    fitContent &&
    css`
      width: fit-content;
    `}
`;

export const HiddenWrapper = styled.div`
  height: 0;
  overflow: hidden;
  position: absolute; // to not affect the width of the parent
`;

export const PreviewWrapper = styled.div`
  display: flex;
  border: 1px solid ${grayScale.mediumLight};
  height: ${PREVIEW_HEIGHT}px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  padding: ${gutters.spacing(1, -2)}px ${gutters.spacing(6)}px;
`;

export const StyledSelect = styled(Select)`
  width: 243px;
  margin-top: ${gutters.spacing(0, 3)}px;
  p {
    color: ${grayScale.dark};
  }
  li:hover {
    background-color: ${dropdownColors.hover};
  }
`;

export const PreviewItem = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${({ width, isObjectPreview }) =>
    width || isObjectPreview ? '' : 'max-width: 150px;'}
  ${({ width, isObjectPreview }) =>
    width
      ? css`
          max-width: ${isObjectPreview ? 200 : 150}px;
        `
      : css`
          width: ${width};
        `}

  p {
    font-weight: 600;
  }

  svg {
    color: ${grayScale.mediumDark};
    font-size: 12px;
  }
`;

export const LeftGradient = styled((props) => (
  <BaseGradient fadeStart={0} fadeLength={70} {...props} />
))`
  z-index: ${layers.content(0, 1)};
`;

export const RightGradient = styled((props) => (
  <BaseGradient fadeStart={0} fadeLength={77} {...props} />
))`
  z-index: ${layers.content(0, 1)};
`;

export const StyledLeftIcon = styled(Icon)`
  margin-right: 8px;

  svg {
    color: ${grayScale.dark};
    font-size: 14px;
  }
`;

export const StyledLeftI = styled.i`
  margin-right: 8px;
  font-size: 14px;

  :before {
    color: ${grayScale.dark};
  }
`;

export const StyledIcon = styled(Icon)`
  padding-left: 8px;
`;

export const TitlesWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow-x: auto;
  ${hideScrollbarCss}
`;

export const WithScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;

  & > div {
    padding: ${gutters.spacing(2, -2)}px 0 0;
    justify-content: flex-start;
    flex: auto;

    &:first-child {
      margin-left: ${gutters.spacing(6)}px;
    }

    &:last-child {
      margin-right: ${gutters.spacing(6)}px;
    }
  }

  column-gap: ${({ isObjectPreview, hideColumnGap = true }) =>
    hideColumnGap ? 0 : isObjectPreview ? 40 : 30}px;

  ${({ resizing }) => (resizing ? 'cursor: ew-resize;' : '')}
`;

export const StyledResizableTitle = styled.div`
  position: relative;
  display: flex;
  & > div:first-of-type {
    max-width: 100%;
  }
  ${({ width }) => css`
    width: calc(${width} + ${COLUMN_PADDING}px);
  `};
  && {
    padding-right: ${COLUMN_PADDING}px;
  }
  &&& .ResizerLayout {
    display: flex;
  }
  ${({ resizing }) =>
    resizing
      ? css`
          svg line {
            stroke: ${grayScale.dark};
          }
        `
      : ''}
`;
