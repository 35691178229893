import React, { useCallback, useMemo, useState } from 'react';
import Icon from 'components/Kizen/Icon';
import {
  DEFAULT_ITEM_IDS,
  getTranslationKeyMapping,
} from 'pages/Account/pages/Toolbar/constants';
import { IconContainer, ItemClickContainer, TopLevelItem } from './styles';
import BubbleProfileImage from 'components/Charts/ScheduledActivities/BubbleProfileImage';
import { useClickAway } from 'react-use';
import { NavLink, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { useTranslation } from 'react-i18next';
import {
  getLabel,
  getQAForItem,
  getRoute,
  getSplitPathName,
  traverseNParents,
} from './util';
import NavigationChildren from './Children';
import useWidthMeasurement from 'hooks/useWidthMeasurement';
import { useWindowSize } from 'app/spacing';
import { useTooltip } from 'components/Kizen/Tooltip';
import { DynamicChildren } from './DynamicChildren';

const SHRINK_LEFT_DISTANCE = 100;

const NavItem = ({
  item,
  userName,
  userImage,
  setOpen,
  close,
  open,
  readOnly,
  mobile,
  chosenBusinessName,
  onClickLogout,
  parentWidth: parentContainerWidth,
  dynamicChildren,
}) => {
  const [shift, setShift] = useState(0);
  const [parentWidth, setParentWidth] = useState(0);
  const { width: windowWidth } = useWindowSize();

  const calculateShift = useCallback((element) => {
    const parent = traverseNParents(element, 1);
    setShift(parent?.offsetLeft ?? 0);
    setParentWidth(parent?.offsetWidth ?? 0);
  }, []);

  const { t } = useTranslation();
  const {
    ref: widthRef,
    plainRef: childrenRef,
    width: childrenWidth,
  } = useWidthMeasurement();

  const childrenOffset = useMemo(() => {
    const horizontalSpaceNeeded = childrenWidth;
    const horizontalSpaceAvailable = shift + parentWidth;

    if (horizontalSpaceNeeded > horizontalSpaceAvailable) {
      return horizontalSpaceAvailable - horizontalSpaceNeeded;
    }

    return 0;
  }, [shift, childrenWidth, parentWidth]);

  const shouldShrinkToLeft = useMemo(() => {
    const difference = (parentContainerWidth ?? windowWidth) - shift;

    return difference - parentWidth < SHRINK_LEFT_DISTANCE;
  }, [windowWidth, shift, parentContainerWidth, parentWidth]);

  const handleClick = useCallback(
    (e) => {
      if (item.children?.length || item.dynamicallyPopulated) {
        e.stopPropagation();
        setOpen();
        return false;
      } else {
        close();
      }
    },
    [setOpen, item, close]
  );

  useClickAway(childrenRef, close);

  const location = useLocation();

  const isChildActive = useMemo(() => {
    return (
      item.children
        ?.map((child) => {
          return getRoute(child);
        })
        .some((route) => {
          const res = route?.exact
            ? getSplitPathName(location.pathname) === route.path
            : matchPath(location.pathname, route?.path);

          return res;
        }) ?? false
    );
  }, [item, location.pathname]);

  const route = getRoute(item);

  const translationKeys = useMemo(() => {
    return getTranslationKeyMapping(t);
  }, [t]);

  const [tooltipProps, tooltip] = useTooltip(
    {
      label: translationKeys[item.id],
    },
    () => item.id === DEFAULT_ITEM_IDS.HELP
  );

  const content = (
    <ItemClickContainer
      tabIndex={readOnly ? -1 : 0}
      // This click handles opening the sub-menu, so should not be disabled in read-only mode
      onClick={handleClick}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }}
      color={item.color}
      open={open}
      childActive={isChildActive}
      as={
        readOnly || item.children?.length || item.dynamicallyPopulated
          ? 'div'
          : route.external
            ? 'a'
            : NavLink
      }
      readOnly={readOnly}
      to={route.external ? null : route.path}
      href={route.external ? route.path : null}
      exact={route.exact}
      target={route.target}
      rel={route.rel}
      mobile={mobile}
      ref={calculateShift}
      // The layout needs to be recomputed when the window width changes
      key={`${item.id}-${windowWidth}`}
      {...getQAForItem(item, route)}
      id={item.id === DEFAULT_ITEM_IDS.MY_ACCOUNT ? 'account-dropdown' : null}
    >
      {item.id !== DEFAULT_ITEM_IDS.MY_ACCOUNT && !item?.sortable ? (
        <IconContainer className={item.id} {...tooltipProps}>
          {typeof item.icon === 'string' ? (
            <Icon icon={item.icon} size={'1x'} className="fa-w-16" />
          ) : item.icon?.className ? (
            <i className={`${item.icon.className} CustomIcon`} />
          ) : (
            <Icon
              icon={item.icon?.value ?? 'bars-light'}
              className={`${item.icon?.value ?? 'bars-light'} fa-w-16`}
              size={'1x'}
            />
          )}
          {tooltip}
        </IconContainer>
      ) : null}
      {item.id === DEFAULT_ITEM_IDS.MY_ACCOUNT ? (
        <BubbleProfileImage
          fullName={userName}
          imageURL={userImage}
          showToolTip
          placement="bottom"
          popperConfig={{
            modifiers: {
              offset: { offset: '0, -5px' }, // Touch bottom of text
              preventOverflow: { enabled: false },
            },
          }}
        />
      ) : item.id === DEFAULT_ITEM_IDS.HELP ? null : (
        <TextEllipsisWithTooltip>
          {getLabel(item, translationKeys)}
        </TextEllipsisWithTooltip>
      )}
      {item?.sortable ? <Icon icon="no-sort" className="SortableIcon" /> : null}
      {item.children?.length || item.dynamicallyPopulated ? (
        <Icon icon="chevron-down" className="ExpandIcon" />
      ) : null}
      {open && item.children?.length ? (
        <NavigationChildren
          ref={widthRef}
          item={item}
          mobile={mobile}
          chosenBusinessName={chosenBusinessName}
          readOnly={readOnly}
          onClickLogout={onClickLogout}
          translationKeys={translationKeys}
          offset={childrenOffset}
          shouldShrinkToLeft={shouldShrinkToLeft}
        />
      ) : null}
      {open && item.dynamicallyPopulated ? (
        <DynamicChildren
          mobile={mobile}
          ref={widthRef}
          items={dynamicChildren}
          offset={childrenOffset}
          shouldShrinkToLeft={shouldShrinkToLeft}
          parent={item}
        />
      ) : null}
    </ItemClickContainer>
  );

  return (
    <TopLevelItem key={item.id} mobile={mobile}>
      {content}
    </TopLevelItem>
  );
};

export default NavItem;
