import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useEditor } from '@craftjs/core';
import { ButtonActions } from '@kizen/page-builder/constants';
import { getDefaultImageProps } from '@kizen/page-builder/utils/image';
import { buildEmailImageNode } from '@kizen/page-builder/utils/build';
import { prefetchAllUrlConfigs } from 'ts-filters/utils';
import { loadSavedFilter } from 'ts-filters/utils';
import { useFilterVariables } from 'ts-components/filters';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { clamp } from 'utility/clamp';
import { urlValidation } from 'utility/validate';
import { useClientGroupsQuery } from '__queries/models/client';
import { useBuilderContext } from '../../BuilderContext';
import { DynamicContentModal } from '../../components/DynamicContentModal/DynamicContentModal';
import { TraySection, TraySubsection } from '../../components/TraySection';
import {
  positionOptions,
  unitOptions,
  UNIT,
  DIMENSION,
  IMAGE_SIZES,
  imageSizeOptions,
  dimensionOptions,
} from '../../options';
import {
  FlexUnderlineRadio,
  FlexSelect,
  FlexSwitch,
  FlexTextInput,
  FlexWholeNumberInput,
  FlexWrapper,
  FlexChild,
} from '../components';
import { SelectImageSubsection } from '../SelectImageSubsection';
import { useModalControl } from 'hooks/useModalControl';
import {
  ContentRuleDropdown,
  ContentTypeRadio,
  EditRulesButton,
  STATIC,
  DYNAMIC,
} from './components';
import { DynamicImageTree, ImageTree } from './ImageTree';
import { getProportionalSizes } from './shared';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { GoToUrlOption, ScriptExecutionOption } from '../ButtonSettings';

const shouldUpdateLinkValue = (value, allowRelativeLinks) => {
  return typeof urlValidation((x) => x, allowRelativeLinks)(value) !== 'string';
};

const descriptionForFilterSet = (filters, joinText) => {
  return filters.map(({ filter }) => filter.toString()).join(joinText);
};

const getGroupFilterLabel = (filter, t) => {
  const groupNames = filter.groups
    .map(({ name }) => `'${name}'`)
    .join(', ')
    .trim();
  return `${
    filter.type === 'in_group'
      ? t('Contact In Group(s)')
      : t('Contact Not In Group(s)')
  } ${groupNames}`;
};

const getDescriptionForLegacyPayload = async (
  node,
  { metadata, vars, queryClient, t }
) => {
  try {
    const convertedFilterSet = {
      ...node.custom.filter.filter,
      query: node.custom.filter.filter.query.map((q) => {
        return {
          ...q,
          filters: q.filters.map((filter) => {
            return filter.values;
          }),
        };
      }),
    };
    const filterSet = await loadSavedFilter(
      metadata,
      convertedFilterSet,
      vars,
      queryClient
    );
    const joinText = filterSet.and ? ` ${t('and')} ` : ` ${t('or')} `;
    const label = filterSet.filters
      .map((filter) => filter.toString())
      .join(joinText);
    return label;
  } catch (error) {
    return '';
  }
};

const translateOptionLabel = (t) => (opt) => {
  return {
    ...opt,
    label: typeof opt.label === 'function' ? opt.label(t) : opt.label,
  };
};

const getDynamicImageNodes = (rules, t) => {
  return rules.map((rule) => {
    const {
      id: filterId,
      order: filterOrder,
      filters,
      operation,
      ...rest
    } = rule;
    const filter = filters.length
      ? {
          and: null,
          query: [
            {
              and: operation === 'and',
              filters: filters.map(({ filter }) => ({
                ...filter.build(),
                view_model: [...filter.save()],
              })),
            },
          ],
        }
      : null;
    const joinText = operation === 'and' ? ` ${t('and')} ` : ` ${t('or')} `;
    const custom = {
      filter: {
        description:
          rule.filterType === 'custom_filter'
            ? descriptionForFilterSet(filters, joinText)
            : getGroupFilterLabel(
                { groups: rule.groups, type: rule.filterType },
                t
              ),
        filter,
        groups: rule.groups,
        type: rule.filterType,
      },
    };
    return buildEmailImageNode(
      { filterId: rule.id, filterOrder: rule.order, ...rest },
      custom
    );
  });
};

const StyledEditRulesButton = styled(EditRulesButton)`
  margin-top: 9px;
`;

const StaticContentContainer = styled.div`
  margin-top: 10px;
`;

/**
 * This is getting wrapped up in a separate hook because need to wait for the groups
 * before building the options. This is because the group names are not saved on the
 * dynamic image nodes (just the ids).
 */
const useRuleOptions = (node, defaultImageProps, dynamicImages) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const metadata = useSelector(selectFilterMetadataDefinition);
  const { variables } = useFilterVariables();
  const [data, setData] = useState({ options: [], lookup: {} });
  const clientObject = useSelector(getBusinessClientObject);

  const { data: groupsById = [], isLoading } = useClientGroupsQuery({
    select: (results) =>
      results.reduce((acc, opt) => {
        acc[opt.id] = opt;
        return acc;
      }, {}),
  });

  useEffect(() => {
    const buildOptionsFromNodes = async () => {
      const opts = [
        {
          value: { ...node.data, props: defaultImageProps },
          label: `1 - ${t('Default Content')}`,
        },
      ];
      if (Array.isArray(dynamicImages)) {
        const dynamicOptions = await Promise.all(
          dynamicImages.map(async (image, idx) => {
            const filterConfig = image.custom.filter;
            let label = '';

            if (filterConfig.type === 'custom_filter') {
              if (!image.custom.filter.description) {
                label = await getDescriptionForLegacyPayload(image, {
                  metadata,
                  variables,
                  queryClient,
                  t,
                });
              } else {
                label = image.custom.filter.description;
              }
            } else {
              label = getGroupFilterLabel(
                {
                  type: filterConfig.filterType,
                  groups: filterConfig.groups.map((group) => {
                    return typeof group === 'string'
                      ? groupsById[group]
                      : group;
                  }),
                },
                t
              );
            }

            return {
              value: image,
              label: `${idx + 2} - ${label}`,
            };
          })
        );
        opts.push(...dynamicOptions);
      }
      const lookup = opts.reduce((acc, opt) => {
        if (opt.value.props.filterId) {
          acc[opt.value.props.filterId] = opt;
        }
        return acc;
      }, {});
      setData({ options: opts, optionLookup: lookup });
    };

    if (!isLoading) {
      buildOptionsFromNodes();
    }
  }, [
    isLoading,
    groupsById,
    node,
    dynamicImages,
    defaultImageProps,
    clientObject,
    queryClient,
    metadata,
    variables,
    t,
  ]);

  return data;
};

/**
 * @remarks A bunch of extra (duplicate) state has been added here to support dynamic images.
 * - `defaultImageProps` - the original state for the static content form
 * - `dynamicImages` - all the dynamic images for this image node - the main state (along with `defaultImageProps`) for the rules and settings modal
 * - `currentImageData` - the current image selected from the dropdown.
 *  Whenever a field is updated more than one of these state values needs to be updated so the current data
 *  is available in all the settings areas (trees/modal). See handleRulesSave or the onChange handlers of the tree components.
 *
 * The `filterId` is used as the unique identifier for dynamic images since they do not have a craftjs id. Also, the `imageFilterId`
 * props is set from the Image node on click to know which image should initially be selected. If it is undefined, then we know
 * the default image was clicked. The same inference is used here to determine the currently selected option in the dropdown.
 */
export const ImageSettingsSection = ({
  node,
  supportPercentages,
  imageFilterId,
  onImageChange, // this is set from the Image node to update its state when changing the image here
  imageLibraryOpen = false,
  allowRelativeLinks = false,
}) => {
  const {
    id,
    data: {
      props,
      custom: { dynamicImages: dynamicImagesProp },
    },
  } = node;
  const [previousId, setPreviousId] = useState(id);
  const isDynamicImage = dynamicImagesProp?.length > 0;
  const traySectionRef = useRef();
  const saveDynamicContentRef = useRef(false); // this flag is used to prevent some effects from running after closing the dynamic content modal
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dimensions = useRef({
    width: props.width,
    height: props.height,
    proportion: props.naturalWidth / props.naturalHeight,
    containerWidth: props.containerWidth,
    maxWidth: props.maxWidth || props.naturalWidth,
    maxHeight: props.maxHeight || props.naturalHeight,
  });
  const {
    actions: { setProp, setCustom },
  } = useEditor();
  const {
    enableDynamicImages,
    enableScriptExecution,
    javascriptActions,
    modalLayer,
    clearContentSettingsTray,
    setPreventCloseSettingsTray,
  } = useBuilderContext();
  const [defaultImageProps, setDefaultImageProps] = useState(() => {
    const { naturalWidth, naturalHeight, maxHeight, maxWidth } =
      node.data.props;

    return {
      ...node.data.props,
      maxWidth: maxWidth || naturalWidth,
      maxHeight: maxHeight || naturalHeight,
    };
  });
  const [dynamicImages, setDynamicImages] = useState(dynamicImagesProp);
  const [hasLink, setHasLink] = useState(!!defaultImageProps?.link);
  const [isClickable, setIsClickable] = useState(
    !!defaultImageProps.scriptId || !!defaultImageProps.link
  );
  const [contentType, setContentType] = useState(
    isDynamicImage ? DYNAMIC : STATIC
  );
  const [key, setKey] = useState(Date.now()); // this is used to re-render the forms with accurate data when closing the dynamic images modal or switching between image nodes
  const [modalOpen, { showModal, hideModal }] = useModalControl(false);
  const dynamicImageIndexes = useMemo(() => {
    if (!Array.isArray(dynamicImages)) return {};
    return dynamicImages.reduce((acc, image, idx) => {
      acc[image.props.filterId] = idx;
      return acc;
    }, {});
  }, [dynamicImages]);
  const dynamicImageIndex = dynamicImageIndexes[imageFilterId];
  const isCurrentImageDefault = dynamicImageIndex === undefined;
  const [currentImageData, setCurrentImageData] = useState(
    dynamicImages?.[dynamicImageIndexes?.[imageFilterId]] ?? node.data
  );

  const javascriptActionOptions = Array.isArray(javascriptActions)
    ? javascriptActions.map((action) => {
        return {
          label: action.name,
          value: action.id,
        };
      })
    : [];

  useEffect(() => {
    dimensions.current.containerWidth = props.containerWidth;
  }, [props.containerWidth]);

  useEffect(() => {
    if (Array.isArray(dynamicImagesProp)) {
      for (const node of dynamicImagesProp) {
        if (node.custom.filter.filter) {
          prefetchAllUrlConfigs(queryClient, node.custom.filter.filter);
        }
      }
    }
  }, [queryClient, dynamicImagesProp]);

  const { options, optionLookup } = useRuleOptions(
    node,
    defaultImageProps,
    dynamicImages
  );

  const currentOption =
    optionLookup?.[currentImageData?.props?.filterId] ?? options[0];

  // update state when clicking amongst image nodes in the canvas since this component
  // is already rendered with the prior node's data.
  if (id !== previousId) {
    const {
      width,
      height,
      size,
      unit,
      naturalWidth,
      naturalHeight,
      containerWidth,
      maxWidth,
      maxHeight,
    } = node.data.props;
    const proportion = naturalWidth / naturalHeight;
    dimensions.current = {
      width,
      height,
      proportion,
      containerWidth,
      maxWidth: maxWidth || naturalWidth,
      maxHeight: maxHeight || naturalHeight,
    };
    setContentType(isDynamicImage ? DYNAMIC : STATIC);
    setDynamicImages(dynamicImagesProp);

    let sizes = {};

    if (size === IMAGE_SIZES.DYNAMIC) {
      if (unit === UNIT.PIXEL) {
        sizes = getProportionalSizes({
          width: maxWidth || width,
          proportion,
          containerWidth,
        });
      }
    }

    setDefaultImageProps({ ...node.data.props, ...sizes });
    setCurrentImageData(
      dynamicImages?.[dynamicImageIndexes?.[imageFilterId]] ?? node.data
    );
    setKey(Date.now());
    setPreviousId(id);
  }

  // ************************************************************************************************
  // Here are the two effects that rely on the `saveDynamicContentRef` flag. We want to perform these
  // effects when the `node` prop changes except when saving dynamic images from the modal. In that case,
  // we have already set the current (accurate) data and the node data from the prop will not yet be current.
  useEffect(() => {
    // This effect keeps the Static Content tree and the Dynamic Content tree (for the Default Content option) in sync.
    if (!imageFilterId && !saveDynamicContentRef.current) {
      setCurrentImageData({ ...node.data, props: defaultImageProps });
    }
  }, [node, defaultImageProps, imageFilterId]);
  useEffect(() => {
    // This effect udpates the dropdown state when the carousel is active
    // or if the next/previous carousel buttons are clicked (imageFilterId
    // is reset using setTrayProps from the Image node).
    if (!saveDynamicContentRef.current) {
      const idx = dynamicImageIndexes[imageFilterId];
      setCurrentImageData(
        dynamicImages?.[idx] ?? {
          ...node.data,
          props: defaultImageProps,
        }
      );
    }
  }, [
    imageFilterId,
    node,
    dynamicImages,
    defaultImageProps,
    dynamicImageIndexes,
  ]);
  // ************************************************************************************************

  const setDimensionDefaults = useCallback(
    (unit, size) => {
      const data = {
        height: dimensions.current.height,
        width: dimensions.current.width,
        maxWidth: dimensions.current.maxWidth,
        maxHeight: dimensions.current.maxHeight,
      };

      if (size === IMAGE_SIZES.DYNAMIC) {
        if (unit === UNIT.PIXEL) {
          const { proportion, containerWidth, maxWidth } = dimensions.current;
          const {
            width: dynamicWidth,
            height: dynamicHeight,
            maxWidth: dynamicMaxWidth,
            maxHeight: dynamicMaxHeight,
          } = getProportionalSizes({
            width: maxWidth || containerWidth,
            proportion,
            containerWidth,
          });
          data.height = dynamicHeight;
          data.width = dynamicWidth;
          data.maxWidth = dynamicMaxWidth;
          data.maxHeight = dynamicMaxHeight;
        } else {
          data.height = null;
          data.maxWidth = null;
          data.maxHeight = null;
          data.width = 100;
        }
      }
      setProp(id, (p) => {
        p.height = data.height;
        p.width = data.width;
        p.maxWidth = data.maxWidth;
        p.maxHeight = data.maxHeight;
      });
      setDefaultImageProps((prev) => ({
        ...prev,
        ...data,
      }));
    },
    [id, setDefaultImageProps, setProp]
  );

  const handleSizeChange = useCallback(
    ({ value }) => {
      setDefaultImageProps((prev) => ({
        ...prev,
        size: value,
        unit: UNIT.PIXEL,
        dimension: DIMENSION.WIDTH,
      }));
      setDimensionDefaults(UNIT.PIXEL, value);
      setProp(id, (p) => {
        p.size = value;
        p.unit = UNIT.PIXEL;
        p.dimension = DIMENSION.WIDTH;
        if (value === IMAGE_SIZES.AUTO) {
          p.width = null;
          p.height = null;
          p.maxWidth = null;
          p.maxHeight = null;
        }
      });
    },
    [id, setDefaultImageProps, setDimensionDefaults, setProp]
  );
  const handlePositionChange = useCallback(
    ({ value }) => {
      setDefaultImageProps((prev) => ({ ...prev, position: value }));
      setProp(id, (p) => {
        p.position = value;
      });
    },
    [id, setDefaultImageProps, setProp]
  );
  const handleDimensionChange = useCallback(
    ({ value }) => {
      setDefaultImageProps((prev) => ({
        ...prev,
        dimension: value,
      }));
      setProp(id, (p) => {
        p.dimension = value;
      });
    },
    [id, setDefaultImageProps, setProp]
  );
  const handleHeightChange = useCallback(
    (value) => {
      const parsedValue = parseInt(value, 10);

      let sizes = {
        width: defaultImageProps.width,
        height: parsedValue,
        maxWidth: defaultImageProps.width,
        maxHeight: parsedValue,
      };

      if (isNaN(parsedValue) || parsedValue === 0) {
        if (defaultImageProps.size === IMAGE_SIZES.FIXED) {
          sizes = {
            ...sizes,
            height: null,
            maxHeight: null,
          };
        } else {
          sizes = {
            width: null,
            height: null,
            maxWidth: null,
            maxHeight: null,
          };
        }
      } else {
        if (defaultImageProps.size === IMAGE_SIZES.DYNAMIC) {
          const { proportion, containerWidth } = dimensions.current;
          sizes = getProportionalSizes({
            height: parsedValue,
            proportion,
            containerWidth,
          });
        }
      }
      setDefaultImageProps((prev) => ({
        ...prev,
        ...sizes,
      }));
      setProp(id, (p) => {
        p.width = sizes.width;
        p.height = sizes.height;
        p.maxWidth = sizes.maxWidth;
        p.maxHeight = sizes.maxHeight;
      });
    },
    [
      id,
      setDefaultImageProps,
      setProp,
      defaultImageProps.width,
      defaultImageProps.size,
    ]
  );
  const handleWidthChange = useCallback(
    (value) => {
      const isPXUnit = defaultImageProps.unit === UNIT.PIXEL;
      const parsedValue = parseInt(value, 10);
      let sizes = {
        width: parsedValue,
        height: defaultImageProps.height,
        maxWidth: parsedValue,
        maxHeight: defaultImageProps.height,
      };

      if (isNaN(parsedValue) || parsedValue === 0) {
        if (defaultImageProps.size === IMAGE_SIZES.FIXED) {
          sizes = {
            ...sizes,
            width: null,
            maxWidth: null,
          };
        } else {
          sizes = {
            width: null,
            height: null,
            maxWidth: null,
            maxHeight: null,
          };
        }
      } else {
        if (defaultImageProps.size === IMAGE_SIZES.DYNAMIC && isPXUnit) {
          const { proportion, containerWidth } = dimensions.current;
          sizes = getProportionalSizes({
            width: parsedValue,
            proportion,
            containerWidth,
            unit: defaultImageProps.unit,
          });
        }
      }
      setDefaultImageProps((prev) => ({
        ...prev,
        ...sizes,
      }));
      setProp(id, (p) => {
        p.width = sizes.width;
        p.height = sizes.height;
        p.maxWidth = sizes.maxWidth;
        p.maxHeight = sizes.maxHeight;
      });
    },
    [
      id,
      setDefaultImageProps,
      setProp,
      defaultImageProps.unit,
      defaultImageProps.height,
      defaultImageProps.size,
    ]
  );
  const handleWidthBlur = useCallback(
    ({ target: { value } }) => {
      const maybeNum = parseInt(value, 10);
      const isPXUnit = defaultImageProps.unit === UNIT.PIXEL;
      const num = isNaN(maybeNum)
        ? null
        : isPXUnit
          ? maybeNum
          : clamp(maybeNum, 0, 100);

      let sizes = {
        width: num,
        height: isPXUnit ? defaultImageProps.height : null,
        maxWidth: isPXUnit ? num : null,
        maxHeight: isPXUnit ? defaultImageProps.height : null,
      };

      if (isNaN(maybeNum)) {
        if (defaultImageProps.size === IMAGE_SIZES.FIXED) {
          sizes = {
            ...sizes,
            width: null,
            maxWidth: null,
          };
        } else {
          sizes = {
            width: null,
            height: null,
            maxWidth: null,
            maxHeight: null,
          };
        }
      } else {
        if (defaultImageProps.size === IMAGE_SIZES.DYNAMIC && isPXUnit) {
          const { proportion, containerWidth } = dimensions.current;
          sizes = getProportionalSizes({
            width: num,
            proportion,
            containerWidth,
            unit: defaultImageProps.unit,
          });
        }
      }

      setDefaultImageProps((prev) => ({ ...prev, ...sizes }));
      setProp(id, (p) => {
        p.width = sizes.width;
        p.height = sizes.height;
        p.maxWidth = sizes.maxWidth;
        p.maxHeight = sizes.maxHeight;
      });
    },
    [
      defaultImageProps.unit,
      defaultImageProps.height,
      defaultImageProps.size,
      setProp,
      id,
    ]
  );
  const handleLinkChange = useCallback(
    (value) => {
      setDefaultImageProps((prev) => ({ ...prev, link: value }));
      if (shouldUpdateLinkValue(value, allowRelativeLinks)) {
        setProp(id, (p) => {
          p.link = value;
        });
      }
    },
    [id, setDefaultImageProps, setProp, allowRelativeLinks]
  );
  const handleAltChange = useCallback(
    (value) => {
      setDefaultImageProps((prev) => ({ ...prev, alt: value }));
      setProp(id, (p) => {
        p.alt = value;
      });
    },
    [id, setDefaultImageProps, setProp]
  );
  const handleUnitChange = useCallback(
    (value) => {
      if (defaultImageProps.unit !== value) {
        setDefaultImageProps((prev) => ({ ...prev, unit: value }));
        setDimensionDefaults(value, IMAGE_SIZES.DYNAMIC);
        setProp(id, (p) => {
          p.unit = value;
        });
      }
    },
    [
      id,
      defaultImageProps.unit,
      setDefaultImageProps,
      setDimensionDefaults,
      setProp,
    ]
  );
  const handleHasLinkChange = useCallback(
    ({ target: { checked } }) => {
      setHasLink(checked);
      if (!checked) {
        setDefaultImageProps((prev) => ({ ...prev, link: '' }));
        setProp(id, (p) => {
          p.link = '';
        });
      }
    },
    [id, setProp, setDefaultImageProps, setHasLink]
  );

  const handleImageChange = useCallback(
    async (file) => {
      if (file) {
        const { name, src, naturalHeight, naturalWidth } =
          await getDefaultImageProps(file);

        dimensions.current = {
          width: naturalWidth,
          height: naturalHeight,
          proportion: naturalWidth / naturalHeight,
          containerWidth: props.containerWidth,
        };
        setProp(id, (p) => {
          p.name = name;
          p.src = src;
          p.fileId = file.id;
          p.naturalHeight = naturalHeight;
          p.naturalWidth = naturalWidth;
          if (defaultImageProps.size === 'auto') {
            p.width = null;
            p.height = null;
          }
        });
        setDefaultImageProps((prev) => {
          return {
            ...prev,
            name,
            src,
            fileId: file.id,
            naturalHeight,
            naturalWidth,
            width: prev.size === 'auto' ? null : prev.width,
            height: prev.size === 'auto' ? null : prev.height,
          };
        });
        if (defaultImageProps.size === IMAGE_SIZES.DYNAMIC) {
          setDimensionDefaults(defaultImageProps.unit, IMAGE_SIZES.DYNAMIC);
        }
      }
    },
    [
      id,
      defaultImageProps.size,
      defaultImageProps.unit,
      setProp,
      setDimensionDefaults,
      props.containerWidth,
    ]
  );

  const handleDynamicImageChange = useCallback(
    async (file) => {
      const { name, src, naturalHeight, naturalWidth } = file
        ? await getDefaultImageProps(file)
        : {};
      const updates = {
        name,
        src,
        fileId: file?.id,
        naturalHeight,
        naturalWidth,
        ...(Boolean(file) && {
          width: undefined,
          height: undefined,
        }),
      };

      setCurrentImageData((prev) => ({
        ...prev,
        props: { ...prev.props, ...updates },
      }));
      setDynamicImages((prev) => {
        return prev.slice(0, dynamicImageIndex).concat(
          {
            ...prev[dynamicImageIndex],
            props: {
              ...prev[dynamicImageIndex].props,
              ...updates,
            },
          },
          prev.slice(dynamicImageIndex + 1)
        );
      });
      setCustom(id, (c) => {
        c.dynamicImages[dynamicImageIndex].props.name = name;
        c.dynamicImages[dynamicImageIndex].props.src = src;
        c.dynamicImages[dynamicImageIndex].props.fileId = file?.id;
        c.dynamicImages[dynamicImageIndex].props.naturalHeight = naturalHeight;
        c.dynamicImages[dynamicImageIndex].props.naturalWidth = naturalWidth;

        if (!file) {
          c.dynamicImages[dynamicImageIndex].props.width = undefined;
          c.dynamicImages[dynamicImageIndex].props.height = undefined;
        }
      });
    },
    [id, dynamicImageIndex, setCustom]
  );

  const handleImageDelete = useCallback(
    async (file) => {
      if (file && file.id === defaultImageProps.fileId) {
        setProp(id, (p) => {
          p.name = undefined;
          p.src = undefined;
          p.fileId = undefined;
          p.width = undefined;
          p.height = undefined;
        });
        setDefaultImageProps((prev) => {
          return {
            ...prev,
            name: undefined,
            src: undefined,
            fileId: undefined,
            width: undefined,
            height: undefined,
          };
        });
        if (defaultImageProps.size === IMAGE_SIZES.DYNAMIC) {
          setDimensionDefaults(defaultImageProps.unit, IMAGE_SIZES.DYNAMIC);
        }
      } else if (
        file &&
        dynamicImages.some((di) => di.props.fileId === file.id)
      ) {
        handleDynamicImageChange(/* called without args clears image data */);
      }
    },
    [
      id,
      defaultImageProps.size,
      defaultImageProps.unit,
      defaultImageProps.fileId,
      dynamicImages,
      setProp,
      setDimensionDefaults,
      handleDynamicImageChange,
    ]
  );

  const handleRulesSave = useCallback(
    (data) => {
      saveDynamicContentRef.current = true;
      const { default_content, rules } = data;
      const nodes = getDynamicImageNodes(rules, t);
      setDefaultImageProps(default_content);
      setDynamicImages(nodes);
      setCurrentImageData(
        (prev) =>
          nodes.find((n) => n.props.filterId === prev.props.filterId) ?? {
            ...prev,
            props: { ...prev.props, ...default_content },
          }
      );
      setCustom(id, (custom) => {
        custom.dynamicImages = nodes;
      });
      setProp(id, (p) => {
        p.fileId = default_content.fileId;
        p.src = default_content.src;
        p.name = default_content.name;
        p.position = default_content.position;
        p.size = default_content.size;
        p.unit = default_content.unit;
        p.width = default_content.width;
        p.height = default_content.height;
        p.link = default_content.link;
        p.alt = default_content.alt;
      });
      setKey(Date.now());
      saveDynamicContentRef.current = false;
    },
    [id, setCustom, setProp, t]
  );

  const handleContentTypeChange = (value) => {
    setContentType(value);
    if (value === DYNAMIC) {
      setKey(Date.now());
    } else {
      setHasLink(Boolean(defaultImageProps?.link));
    }
  };

  const clickableActionOptions = javascriptActions?.length
    ? [GoToUrlOption, ScriptExecutionOption].map(translateOptionLabel(t))
    : [translateOptionLabel(t)(GoToUrlOption)];

  if (enableDynamicImages) {
    return (
      <TraySection
        onBackClick={clearContentSettingsTray}
        collapsable={false}
        header={t('Image Settings')}
      >
        <div>
          <ContentTypeRadio
            value={contentType}
            onChange={handleContentTypeChange}
          />
        </div>
        {contentType === DYNAMIC && (
          <>
            <StyledEditRulesButton onClick={showModal}>
              {t('Edit Rules')}
            </StyledEditRulesButton>
            <ContentRuleDropdown
              value={currentOption}
              options={options}
              onChange={({ value }) => {
                setCurrentImageData(value);
                onImageChange(value);
              }}
            />
            {isCurrentImageDefault ? (
              <ImageTree
                key={`${id}_${key}`}
                imageProps={currentImageData.props}
                onImageDelete={handleImageDelete}
                onImageChange={handleImageChange}
                onErrorShow={() => setPreventCloseSettingsTray(true)}
                onErrorHide={() => setPreventCloseSettingsTray(false)}
                onChange={(prop, value) => {
                  setDefaultImageProps((prev) => ({
                    ...prev,
                    [prop]: value,
                  }));
                  setCurrentImageData((prev) => ({
                    ...prev,
                    props: { ...prev.props, [prop]: value },
                  }));
                  if (
                    prop !== 'link' ||
                    shouldUpdateLinkValue(value, allowRelativeLinks)
                  ) {
                    setProp(id, (p) => {
                      p[prop] = value;
                    });
                  }
                }}
              />
            ) : (
              <DynamicImageTree
                key={`${id}+${key + 1}`}
                imageProps={currentImageData?.props}
                onImageChange={handleDynamicImageChange}
                onImageDelete={handleImageDelete}
                onErrorShow={() => setPreventCloseSettingsTray(true)}
                onErrorHide={() => setPreventCloseSettingsTray(false)}
                onChange={(prop, value) => {
                  setCurrentImageData((prev) => ({
                    ...prev,
                    props: { ...prev.props, [prop]: value },
                  }));
                  if (
                    prop !== 'link' ||
                    shouldUpdateLinkValue(value, allowRelativeLinks)
                  ) {
                    setCustom(id, (c) => {
                      c.dynamicImages[dynamicImageIndex].props[prop] = value;
                    });
                    setDynamicImages((prev) => {
                      return prev.slice(0, dynamicImageIndex).concat(
                        {
                          ...prev[dynamicImageIndex],
                          props: {
                            ...prev[dynamicImageIndex].props,
                            [prop]: value,
                          },
                        },
                        prev.slice(dynamicImageIndex + 1)
                      );
                    });
                  }
                }}
              />
            )}
            {modalOpen && (
              <DynamicContentModal
                defaultImageProps={defaultImageProps}
                images={dynamicImages}
                modalLayer={modalLayer}
                onClose={hideModal}
                onSave={handleRulesSave}
              />
            )}
          </>
        )}
        {contentType === STATIC && (
          <StaticContentContainer key={key}>
            <SelectImageSubsection
              fileId={defaultImageProps.fileId}
              fileName={defaultImageProps.name}
              imageLibraryOpen={imageLibraryOpen}
              onImageDelete={handleImageDelete}
              onImageChange={handleImageChange}
            />
            <TraySubsection header={t('Image Styling')}>
              <FlexWrapper>
                <FlexSelect
                  variant="underline"
                  label={t('Size')}
                  value={defaultImageProps.size}
                  scrollContainer={traySectionRef.current}
                  options={imageSizeOptions(t)}
                  onChange={handleSizeChange}
                />
                <FlexSelect
                  variant="underline"
                  label={t('Position')}
                  value={defaultImageProps.position}
                  scrollContainer={traySectionRef.current}
                  options={positionOptions(t)}
                  onChange={handlePositionChange}
                />
              </FlexWrapper>
              {defaultImageProps.size === IMAGE_SIZES.DYNAMIC && (
                <FlexWrapper>
                  {supportPercentages && (
                    <FlexUnderlineRadio
                      label={t('Unit')}
                      flex={1 / 3}
                      value={defaultImageProps.unit}
                      options={unitOptions}
                      onChange={handleUnitChange}
                    />
                  )}
                  {defaultImageProps.unit === UNIT.PIXEL ? (
                    <>
                      <FlexSelect
                        variant="underline"
                        label={t('Dimension')}
                        flex={1 / 3}
                        value={defaultImageProps.dimension}
                        scrollContainer={traySectionRef.current}
                        options={dimensionOptions(t)}
                        onChange={handleDimensionChange}
                      />
                      {defaultImageProps.dimension === DIMENSION.WIDTH ? (
                        <FlexWholeNumberInput
                          variant="underline"
                          label={t('Max-Width')}
                          flex={1 / 3}
                          placeholder={!defaultImageProps.width && t('Auto')}
                          value={
                            defaultImageProps.maxWidth ||
                            defaultImageProps.width
                          }
                          onChange={handleWidthChange}
                          onBlur={handleWidthBlur}
                        />
                      ) : (
                        <FlexWholeNumberInput
                          variant="underline"
                          label={t('Max-Height')}
                          flex={1 / 3}
                          placeholder={!defaultImageProps.height && t('Auto')}
                          value={
                            defaultImageProps.maxHeight ||
                            defaultImageProps.height
                          }
                          onChange={handleHeightChange}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <FlexWholeNumberInput
                        variant="underline"
                        label={t('Max-Width')}
                        flex={1 / 3}
                        placeholder={!defaultImageProps.width && t('Auto')}
                        value={defaultImageProps.width}
                        onChange={handleWidthChange}
                        onBlur={handleWidthBlur}
                      />
                      <FlexChild flex={1 / 3} />
                    </>
                  )}
                </FlexWrapper>
              )}
              {defaultImageProps.size === IMAGE_SIZES.FIXED && (
                <FlexWrapper>
                  <FlexWholeNumberInput
                    variant="underline"
                    label={t('Width (px)')}
                    flex={1 / 2}
                    placeholder={!defaultImageProps.width && t('Auto')}
                    value={defaultImageProps.width}
                    onChange={handleWidthChange}
                    onBlur={handleWidthBlur}
                  />
                  <FlexWholeNumberInput
                    variant="underline"
                    label={t('Height (px)')}
                    flex={1 / 2}
                    placeholder={!defaultImageProps.height && t('Auto')}
                    value={defaultImageProps.height}
                    onChange={handleHeightChange}
                  />
                </FlexWrapper>
              )}
            </TraySubsection>
            <TraySubsection header={t('Properties')}>
              <FlexWrapper>
                <FlexSwitch
                  checked={hasLink}
                  label={t('Has Link')}
                  flex={1}
                  textPlacement="top"
                  onChange={handleHasLinkChange}
                />
                {hasLink && (
                  <FlexTextInput
                    variant="underline"
                    label={t('URL')}
                    flex={2}
                    value={defaultImageProps.link}
                    onChange={handleLinkChange}
                    validate={{
                      full: urlValidation(t, allowRelativeLinks),
                    }}
                    onErrorShow={() => setPreventCloseSettingsTray(true)}
                    onErrorHide={() => setPreventCloseSettingsTray(false)}
                    key={id}
                  />
                )}
              </FlexWrapper>
              <FlexWrapper>
                <FlexTextInput
                  variant="underline"
                  label={t('Alt Text')}
                  value={defaultImageProps.alt}
                  onChange={handleAltChange}
                />
              </FlexWrapper>
            </TraySubsection>
          </StaticContentContainer>
        )}
      </TraySection>
    );
  }

  return (
    <TraySection
      key={key}
      onBackClick={clearContentSettingsTray}
      collapsable={false}
      header={t('Image Settings')}
    >
      <SelectImageSubsection
        fileId={defaultImageProps.fileId}
        fileName={defaultImageProps.name}
        imageLibraryOpen={imageLibraryOpen}
        onImageDelete={handleImageDelete}
        onImageChange={handleImageChange}
      />
      <TraySubsection header={t('Image Styling')}>
        <FlexWrapper>
          <FlexSelect
            variant="underline"
            label={t('Size')}
            value={defaultImageProps.size}
            scrollContainer={traySectionRef.current}
            options={imageSizeOptions(t)}
            onChange={handleSizeChange}
          />
          <FlexSelect
            variant="underline"
            label={t('Position')}
            value={defaultImageProps.position}
            scrollContainer={traySectionRef.current}
            options={positionOptions(t)}
            onChange={handlePositionChange}
          />
        </FlexWrapper>
        {defaultImageProps.size === IMAGE_SIZES.DYNAMIC && (
          <FlexWrapper>
            {supportPercentages && (
              <FlexUnderlineRadio
                label={t('Unit')}
                flex={1 / 3}
                value={defaultImageProps.unit}
                options={unitOptions}
                onChange={handleUnitChange}
              />
            )}
            {defaultImageProps.unit === UNIT.PIXEL ? (
              <>
                <FlexSelect
                  variant="underline"
                  label={t('Dimension')}
                  flex={1 / 3}
                  value={defaultImageProps.dimension}
                  scrollContainer={traySectionRef.current}
                  options={dimensionOptions(t)}
                  onChange={handleDimensionChange}
                />
                {defaultImageProps.dimension === DIMENSION.WIDTH ? (
                  <FlexWholeNumberInput
                    variant="underline"
                    label={t('Max-Width')}
                    flex={1 / 3}
                    placeholder={!defaultImageProps.width && t('Auto')}
                    value={
                      defaultImageProps.maxWidth || defaultImageProps.width
                    }
                    onChange={handleWidthChange}
                    onBlur={handleWidthBlur}
                  />
                ) : (
                  <FlexWholeNumberInput
                    variant="underline"
                    label={t('Max-Height')}
                    flex={1 / 3}
                    placeholder={!defaultImageProps.height && t('Auto')}
                    value={
                      defaultImageProps.maxHeight || defaultImageProps.height
                    }
                    onChange={handleHeightChange}
                  />
                )}
              </>
            ) : (
              <>
                <FlexWholeNumberInput
                  variant="underline"
                  label={t('Max-Width')}
                  flex={1 / 3}
                  placeholder={!defaultImageProps.width && t('Auto')}
                  value={defaultImageProps.width}
                  onChange={handleWidthChange}
                  onBlur={handleWidthBlur}
                />
                <FlexChild flex={1 / 3} />
              </>
            )}
          </FlexWrapper>
        )}
        {defaultImageProps.size === IMAGE_SIZES.FIXED && (
          <FlexWrapper>
            <FlexWholeNumberInput
              variant="underline"
              label={t('Width (px)')}
              flex={1 / 2}
              placeholder={!defaultImageProps.width && t('Auto')}
              value={defaultImageProps.width}
              onChange={handleWidthChange}
              onBlur={handleWidthBlur}
            />
            <FlexWholeNumberInput
              variant="underline"
              label={t('Height (px)')}
              flex={1 / 2}
              placeholder={!defaultImageProps.height && t('Auto')}
              value={defaultImageProps.height}
              onChange={handleHeightChange}
            />
          </FlexWrapper>
        )}
      </TraySubsection>
      <TraySubsection header={t('Properties')}>
        <FlexWrapper>
          {enableScriptExecution ? (
            <FlexSwitch
              checked={isClickable}
              label={t('Is Clickable')}
              flex={1}
              textPlacement="top"
              onChange={({ target: { checked } }) => {
                setIsClickable(checked);
                if (checked) {
                  setDefaultImageProps((prev) => ({
                    ...prev,
                    action: ButtonActions.URL,
                  }));
                  setProp(id, (p) => {
                    p.action = ButtonActions.URL;
                  });
                } else {
                  setDefaultImageProps((prev) => ({
                    ...prev,
                    action: undefined,
                    link: '',
                    scriptId: undefined,
                  }));
                  setProp(id, (p) => {
                    p.action = undefined;
                    p.link = '';
                    p.scriptId = undefined;
                  });
                }
              }}
            />
          ) : (
            <>
              <FlexSwitch
                checked={hasLink}
                label={t('Has Link')}
                flex={1}
                textPlacement="top"
                onChange={handleHasLinkChange}
              />
              {hasLink && (
                <FlexTextInput
                  variant="underline"
                  label={t('URL')}
                  flex={2}
                  value={defaultImageProps.link}
                  onChange={handleLinkChange}
                  validate={{ full: urlValidation(t, allowRelativeLinks) }}
                  onErrorShow={() => setPreventCloseSettingsTray(true)}
                  onErrorHide={() => setPreventCloseSettingsTray(false)}
                  key={id}
                />
              )}
            </>
          )}
        </FlexWrapper>
        {enableScriptExecution && isClickable && (
          <>
            <FlexWrapper>
              <FlexSelect
                variant="underline"
                label={t('Action')}
                value={defaultImageProps.action}
                disabled={clickableActionOptions.length === 1}
                options={clickableActionOptions}
                onChange={({ value }) => {
                  setDefaultImageProps((prev) => ({
                    ...prev,
                    action: value,
                    link:
                      value === ButtonActions.SCRIPT_EXECUTION ? '' : prev.link,
                  }));
                  setProp(id, (p) => {
                    p.action = value;
                    if (value === ButtonActions.SCRIPT_EXECUTION) {
                      p.link = '';
                    }
                  });
                }}
              />
            </FlexWrapper>
            <FlexWrapper>
              {defaultImageProps.action === ButtonActions.URL && (
                <FlexTextInput
                  variant="underline"
                  label={t('URL')}
                  value={defaultImageProps.link}
                  onChange={(value) => {
                    setDefaultImageProps((prev) => ({
                      ...prev,
                      link: value,
                    }));
                    if (urlValidation(t, allowRelativeLinks)(value)) {
                      setProp(id, (p) => {
                        p.link = value;
                      });
                    }
                  }}
                  validate={{ full: urlValidation(t, allowRelativeLinks) }}
                  onErrorShow={() => setPreventCloseSettingsTray(true)}
                  onErrorHide={() => setPreventCloseSettingsTray(false)}
                  key={id}
                />
              )}
              {defaultImageProps.action === ButtonActions.SCRIPT_EXECUTION && (
                <FlexSelect
                  variant="underline"
                  label={t('JavaScript to Execute')}
                  value={defaultImageProps.scriptId}
                  options={javascriptActionOptions}
                  onChange={({ value }) => {
                    setDefaultImageProps((prev) => ({
                      ...prev,
                      scriptId: value,
                    }));
                    setProp(id, (p) => {
                      p.scriptId = value;
                    });
                  }}
                />
              )}
            </FlexWrapper>
          </>
        )}
        <FlexWrapper>
          <FlexTextInput
            variant="underline"
            label={t('Alt Text')}
            value={defaultImageProps.alt}
            onChange={handleAltChange}
          />
        </FlexWrapper>
      </TraySubsection>
    </TraySection>
  );
};

ImageSettingsSection.propTypes = {
  node: PropTypes.object.isRequired,
};
