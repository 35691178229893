import {
  defaultContactFieldRule,
  defaultCustomObjectFieldRule,
  getFieldRelationRuleDef,
} from './rule-presets';
import type { RuleDef } from './rules/types';
import {
  Access,
  AccessNumber,
  AccessStruct,
  PermissionConfig,
  PermissionsContext,
  SectionConfig,
  TFunction,
} from './types';

export type Field<Name = string, Default = boolean> = {
  access: { view: boolean; edit: boolean; remove: boolean };
  allowsEmpty: boolean;
  customObjectField?: any;
  allowNulls: boolean;
  category: string;
  displayName: string;
  fieldType: string;
  id: string;
  isDefault: Default;
  isHidden: boolean;
  isHideable: boolean;
  isReadOnly: boolean;
  isRequired: boolean;
  name: Name;
  order: number;
  relation?: { relatedObject: string };
};

type CreateFieldPermissionConfigOpts = {
  defaultValue?: Access;
  enableRelatedFieldRule?: boolean;
};

const CONTACTS_SECTION = 'contacts_section';

const AccessNumbers: Record<Access, AccessNumber> = {
  none: 0,
  view: 1,
  edit: 2,
  remove: 3,
};

const FieldTypeAllowedAccesses: Record<string, Access[]> = {
  files: ['none', 'view', 'edit', 'remove'],
};

export const getStreamKey = (permission: string, section?: string) => {
  return section ? `${section}__${permission}` : permission;
};

export const isPermissionOfSection = (key: string, sectionId: string) => {
  return key.startsWith(`${sectionId}__`);
};

export const isAccessString = (value: string): value is Access => {
  return (
    value === 'none' ||
    value === 'view' ||
    value === 'edit' ||
    value === 'remove'
  );
};

/**
 * Produces the maximum allowed access number for various Access types:
 *  - boolean: `true` --> `0` / `false` --> `3`
 *  - string: `none`, `view`, `edit`, `remove` --> 0, 1, 2, 3
 *  - object: `{ view: true, edit: true, remvoe: false }` --> returns the number
 *     of highest entry with `true`, otherwise `0`
 */
export function getAccessNumber(access: boolean): AccessNumber;
export function getAccessNumber(access: Access): AccessNumber;
export function getAccessNumber(access: Partial<AccessStruct>): AccessNumber;
export function getAccessNumber(arg: any): AccessNumber {
  if (typeof arg === 'boolean') return arg ? 3 : 0;
  if (isAccessString(arg)) return AccessNumbers[arg];
  if (!arg) return 0;
  if (arg.remove) return 3;
  if (arg.edit) return 2;
  if (arg.view) return 1;
  return 0;
}

/**
 * Converts an Acess number or boolean value to its string representation.
 *
 * @param num - number or boolean Access value
 * @returns - string Access value
 */
export function getAccessValue(num: AccessNumber | boolean): Access {
  if (num === true) return 'remove';
  if (num === false) return 'none';
  if (num === 0) return 'none';
  if (num === 1) return 'view';
  if (num === 2) return 'edit';
  if (num === 3) return 'remove';
  throw Error(`${num} is not a valid AccessNumber`);
}

/**
 * Iterable to produce an `allowed_access` array from a given minimum value.
 *
 * @remarks the optional `end` parameter is non-inclusive.
 *
 * @example [...accessIter(0)]      // ['none', 'view', 'edit', 'remove']
 * @example [...accessIter(1)]      // ['view', 'edit', 'remvoe']
 * @example [...accessIter(2)]      // ['edit', 'remove']
 * @example [...accessIter(3)]      // ['remove']
 * @example [...accessIter(1, 3)]   // ['view', 'edit']
 * @example [...accessIter('view')] // ['view', 'edit', 'remove']
 * @example [...accessIter(4)]      // []
 *
 * @param start - the minimum allowed_access value.
 */
export function* accessIter(
  start: Access | AccessNumber,
  end: number | AccessNumber = 4
) {
  let s = typeof start === 'string' ? getAccessNumber(start) : start;
  const e = typeof end === 'string' ? getAccessNumber(end) : end;

  while (s < e) {
    yield getAccessValue(s);
    s++;
  }
}

/**
 * Creates an 'or' rule from the rules provided. The returned rule will
 * use the 'take-first' result collector to return the value of the first
 * passing rule.
 *
 * @param rules - n-rules to be or'd together into one rule.
 * @returns - OrRule with 'take-first' result transducer.
 */
export const chainRules = (...rules: RuleDef<any, any, any>[]) => [
  'or',
  rules,
  'take-first',
];

/**
 * Returns a config object for a custom object for use in the permission context's
 * stream creator utilities.
 *
 * @param object - custom object
 * @param defaults - the initial permission values for the custom object
 * @param t - the translation function
 */
export const createCustomObjectSection = (
  object: any,
  defaults: any,
  t: TFunction
): SectionConfig => {
  const { id, objectName, objectType = 'standard' } = object;

  return {
    label: `${t('Custom Object')} - ${objectName}`,
    key: id,
    default: defaults?.enabled ?? false,
    allowed_access: ['none', 'view'],
    affordance: 'switch',
    metadata: {
      custom_object_id: id,
      object_name: objectName,
      object_type: objectType,
    },
  };
};

export const createFieldPermissionConfig = (
  field: Field,
  customObjectId: string,
  ctx: Pick<PermissionsContext, 'meta_config' | 'contact_object_id'>,
  opts?: CreateFieldPermissionConfigOpts
): PermissionConfig => {
  const { defaultValue, enableRelatedFieldRule = true } = opts ?? {};
  const { id, displayName, name, isDefault, fieldType, relation } = field;
  const {
    meta_config: { default_contact_fields, default_custom_object_fields },
    contact_object_id,
  } = ctx;
  const defaultRule =
    customObjectId === CONTACTS_SECTION
      ? defaultContactFieldRule
      : defaultCustomObjectFieldRule;
  const relatedSectionId =
    relation?.relatedObject === contact_object_id
      ? CONTACTS_SECTION
      : relation?.relatedObject;
  const field_config =
    customObjectId === CONTACTS_SECTION
      ? (default_contact_fields as any)[name]
      : (default_custom_object_fields as any)[name];
  const {
    default: def,
    allowed_access,
    rule,
  } = field_config ?? {
    default: 'view',
    allowed_access: FieldTypeAllowedAccesses[fieldType] ?? [
      'none',
      'view',
      'edit',
    ],
    rule:
      enableRelatedFieldRule && relatedSectionId
        ? chainRules(getFieldRelationRuleDef(relatedSectionId), defaultRule)
        : defaultRule,
  };

  return {
    label: displayName,
    key: name,
    default: defaultValue ?? def ?? 'view',
    allowed_access,
    affordance: 'range',
    rule,
    metadata: {
      custom_object_id: customObjectId,
      field_id: id,
      is_default: isDefault,
      name,
    },
  };
};

/**
 * This is used for an 'app area' section (one under the 'sections' field of
 * the meta config). We need to provide the affordance and tooltip as metadata
 * to the permission class for showing the tooltips and processing boolean
 * values (booleans of type checkbox are treated different that type range).
 */
export const getSectionConfigMetadata = (permission: PermissionConfig) => {
  const { affordance, tooltip } = permission;
  return tooltip ? { affordance, tooltip } : { affordance };
};
