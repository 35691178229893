import React from 'react';
import {
  Nav as BootstrapNav,
  Navbar as BootstrapNavBar,
} from 'react-bootstrap';
import styled from '@emotion/styled';
import { grayScale, shadowLight } from 'app/colors';
import { breakpoints, gutters, borderRadii } from 'app/spacing';
import {
  pageSizingCss,
  contentWidthCss,
} from 'components/Layout/PageContentWidth';

export const BaseSubNavigationNavBar = ({ children, ...rest }) => {
  return (
    <div {...rest}>
      <BootstrapNavBar variant="light" expand="md">
        <BootstrapNav className="ml-auto" data-qa="subnavigation">
          {children}
        </BootstrapNav>
      </BootstrapNavBar>
    </div>
  );
};

export const SubNavigationNavBar = styled(BaseSubNavigationNavBar)`
  ${pageSizingCss}
  & .navbar {
    position: relative;
    ${contentWidthCss}
    margin: ${gutters.spacing(4)}px auto;
    border-radius: ${borderRadii.standard};
    ${shadowLight}
    background: ${grayScale.white};
    padding: 0;
  }
  & .navbar-nav {
    flex: 1;
    justify-content: space-evenly;
    flex-direction: row !important;
  }
  @media (max-width: ${breakpoints.md}px) {
    & .navbar {
      margin: ${gutters.spacing(2)}px auto;
    }
    & .navbar-nav {
      align-items: flex-start;
    }
    & .navbar {
      border-radius: 0;
      width: 100%;
    }
    & .navbar-toggler {
      margin: ${gutters.spacing(2)}px;
    }
  }
`;

export const SmallSubNavigationNavBar = styled(({ children, ...props }) => (
  <div {...props}>
    <nav>{children}</nav>
  </div>
))`
  display: flex;
  justify-content: center;
  margin-bottom: ${gutters.spacing(4)}px;
  > nav {
    display: flex;
    background-color: ${grayScale.white};
    ${shadowLight}
  }
`;
