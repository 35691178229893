import { useMemo, useState, useEffect, useCallback } from 'react';
import { isNonHidebleColumn } from '../helpers';
import useBasicBuilder, {
  LEFT_ID,
} from 'components/MultiColumnBuilder/useBasicBuilder';
import { v4 as uuidv4 } from 'uuid';

export const CUSTOM_FIELD_ITEM_ID = 'custom_field';

const selfDisabledDropZones = [LEFT_ID];

export const useEditColumnModalBuilder = ({
  settings = [],
  defaultColumns = [],
  onConfirm,
  t,
}) => {
  const [showModal, setShowModal] = useState(null);
  const [rightItems, setRightItems] = useState(() => {
    return settings.reduce((acc, { label, id, visible, fieldId, objectId }) => {
      if (visible) {
        return acc.concat({
          label,
          id,
          icon: '',
          sortable: true,
          disabled: isNonHidebleColumn({ id }),
          displayName: label,
          fieldId,
          objectId,
        });
      }
      return acc;
    }, []);
  });

  const rightItemsById = useMemo(() => {
    return rightItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [rightItems]);

  const handleChange = useCallback((rightItems) => {
    setRightItems(
      rightItems.map((item) => {
        return {
          ...item,
          disabled: isNonHidebleColumn(item.id),
        };
      })
    );
  }, []);

  const onCustomFieldModalConfirm = useCallback((element) => {
    setShowModal({ ...element });
  }, []);

  const customFieldItem = useMemo(() => {
    return {
      label: t('New Custom Field (Drag to Create)'),
      displayName: t('New Custom Field (Drag to Create)'),
      id: uuidv4(),
      fieldId: CUSTOM_FIELD_ITEM_ID,
      isLocked: false,
    };
  }, [t]);

  const leftItems = useMemo(() => {
    return settings.reduce(
      (acc, { id, label, disabled, fieldId, objectId }) => {
        if (!rightItemsById?.[id]) {
          return acc.concat({
            label,
            displayName: label,
            id,
            icon: '',
            sortable: true,
            disabled,
            fieldId,
            objectId,
          });
        }
        return acc;
      },
      []
    );
  }, [rightItemsById, settings]);

  const leftSettingItemsById = useMemo(() => {
    return leftItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [leftItems]);

  const leftWithDefaultColumns = useMemo(() => {
    return defaultColumns.reverse().reduce((acc, { id, label }) => {
      if (!rightItemsById?.[id] && !leftSettingItemsById?.[id]) {
        acc.unshift({
          label,
          displayName: label,
          id,
          icon: '',
          sortable: true,
          disabled: isNonHidebleColumn({ id }),
        });
      }
      return acc;
    }, leftItems);
  }, [defaultColumns, leftItems, leftSettingItemsById, rightItemsById]);

  const handleSubmit = () => {
    onConfirm([
      ...rightItems.map(({ id, label, fieldId, objectId }) => ({
        id,
        label,
        objectId,
        fieldId,
        visible: true,
      })),
    ]);
  };

  const builderProps = useBasicBuilder({
    initialLeftColumnItems: leftWithDefaultColumns,
    initialRightColumnItems: rightItems,
    editable: true,
    selfDisabledDropZones,
    rightColumnItemsWithContentForceDirty: false,
  });

  useEffect(() => {
    const customFieldIndex = builderProps?.rightColumnItems.findIndex(
      ({ fieldId }) => fieldId === CUSTOM_FIELD_ITEM_ID
    );
    if (customFieldIndex >= 0) {
      setShowModal({
        index: customFieldIndex,
      });
    } else {
      handleChange(builderProps?.rightColumnItems);
    }
  }, [builderProps?.rightColumnItems, handleChange]);

  return {
    builderProps: {
      ...builderProps,
    },
    handleSubmit,
    handleChange,
    showModal,
    setShowModal,
    onCustomFieldModalConfirm,
    customFieldItem,
  };
};
