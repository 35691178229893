import AxiosService from './AxiosService';
import { camelToSnakeCaseKeys, FORCE_ALL_RECORDS_SIZE } from './helpers';
import { monitoringExceptionHelper } from 'sentry/helpers';

class QuickFiltersService {
  constructor() {
    if (!QuickFiltersService.instance) {
      QuickFiltersService.instance = this;
    }
    return QuickFiltersService.instance;
  }

  quickFiltersEndpoint = '/quick-filters';
  customObjectPath = '/custom-objects';

  generateEndpoint = (objectId, id) =>
    `${this.customObjectPath}/${objectId}${this.quickFiltersEndpoint}${
      id ? `/${id}` : ''
    }`;

  createTemplate = async (name, config, sharingSettings, objectId, options) => {
    return AxiosService.post(
      this.generateEndpoint(objectId),
      {
        name,
        filters: config,
        filter_count: config?.quick_filter_settings?.length,
        sharing_settings: camelToSnakeCaseKeys(sharingSettings),
      },
      { skipErrorBoundary: true, ...options }
    );
  };

  listTemplates = async (
    page = 1,
    pageSize = FORCE_ALL_RECORDS_SIZE,
    ordering,
    search,
    objectId,
    options
  ) => {
    const { data } = await AxiosService.get(this.generateEndpoint(objectId), {
      ...options,
      params: {
        page,
        page_size: pageSize,
        ordering,
        search,
        ...options?.params,
      },
    });

    return data;
  };

  getTemplate = async (id, objectId, options) => {
    const { data } = await AxiosService.get(
      this.generateEndpoint(objectId, id),
      options
    );
    return data;
  };

  deleteTemplate = async (id, objectId, options) => {
    const { data } = await AxiosService.delete(
      this.generateEndpoint(objectId, id),
      options
    );
    return data;
  };

  updateTemplate = (
    id,
    name,
    config,
    sharingSettings,
    owner,
    objectId,
    options
  ) => {
    return AxiosService.patch(
      this.generateEndpoint(objectId, id),
      {
        name,
        filters: config,
        filter_count: config?.quick_filter_settings?.length,
        sharing_settings: sharingSettings
          ? camelToSnakeCaseKeys(sharingSettings)
          : undefined,
        owner,
      },
      { skipErrorBoundary: true, ...options }
    );
  };

  applyTemplateToTeamMembers = (id, teamMemberIds, objectId, options) => {
    return AxiosService.post(
      `${this.generateEndpoint(objectId, id)}/apply-to-users`,
      {
        team_member_ids: teamMemberIds,
      },
      options
    );
  };

  applyTemplateToRoles = (id, roleIds, objectId, options) => {
    return AxiosService.post(
      `${this.generateEndpoint(objectId, id)}/apply-to-roles`,
      {
        role_ids: roleIds,
      },
      options
    );
  };

  applyTemplateToPermissionGroups = (id, groupIds, objectId, options) => {
    return AxiosService.post(
      `${this.generateEndpoint(objectId, id)}/apply-to-permission-groups`,
      {
        permission_group_ids: groupIds,
      },
      options
    );
  };

  getGrantingAdmins = async (id, objectId, options) => {
    const { data } = await AxiosService.get(
      `${this.generateEndpoint(objectId, id)}/admins`,
      options
    );

    return data;
  };

  requestAccess = async (id, adminId, accessLevel, objectId, options) => {
    try {
      const response = await AxiosService.post(
        `${this.generateEndpoint(objectId)}/request-access`,
        {
          entity_type: 'quick_filter_template',
          entity_id: id,
          admin_responder: adminId,
          access_level_requested: accessLevel,
        },
        options
      );
      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      return false;
    }
  };

  respondToAccessRequest = async (requestId, approved, objectId, options) => {
    const { data } = await AxiosService.put(
      `${this.generateEndpoint(objectId)}/${requestId}/request-access-response`,
      {
        approved,
      },
      options
    );
    return data;
  };
}

const instance = new QuickFiltersService();
Object.freeze(instance);

export default instance;
