import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useDebouncedPastScrollThreshold } from 'hooks/useDebouncedWindowScroll';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { performActionOptions } from './PerformActionSteps/model';
import { FILTER_MENU_Z_INDEX, layers, useWindowSize } from 'app/spacing';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import useModal from 'components/Modals/useModal';
import ActivityActions from 'pages/Common/Actions/ActivityActions';
import AutomationActions from 'pages/Common/Actions/AutomationActions';
import { StyledSelectMenu } from 'pages/Common/Actions/styles';
import Icon from 'components/Kizen/Icon';
import BackButton from 'components/Button/BackButton';
import {
  centerNameBreak,
  ToolbarWrapper,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
  SaveButton,
  DesktopContent,
  DesktopContactContent,
  DesktopOnlyButton,
  DesktopOnlyButtonWithDropdown,
  MobileContent,
  Header,
  NameWrapper,
  Name,
  ActionsWrapper,
  ThreeDots,
  FlyingDot,
} from 'pages/Common/styles/ControlBar';
import ClientService from 'services/ClientService';
import ArchiveModal from 'components/Modals/ArchiveModal';
import {
  MissingContactInformationModal,
  CONTACT_TYPE_PHONE,
  CONTACT_TYPE_EMAIL,
} from './MissingContactInformationModal';
import ActionBar from './ActionBar';
import { can } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Typography } from '@kizen/kds/Typography';
import { ViewSelect } from './styles';
import { checkSendEmailAvailability } from './PerformActionSteps/StepsContent/SendEmail';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

const DEFAULT_NAME = 'Contact';
const NAME_BREAKPOINT = centerNameBreak;

const StyledToolbarWrapper = styled(ToolbarWrapper)`
  z-index: ${FILTER_MENU_Z_INDEX +
  1}; // high z-index to be above the filter dropdowns on the constrained timeline
`;

export const ControlBar = ({
  routes,
  params,
  contact,
  onSubmit,
  onChange,
  onStartAutomation,
  openPerformModal,
  logActivityActions,
  fieldState,
  activityList,
  clientObject,
  layoutOptions,
  selectedLayout,
  setSelectedLayout,
  hideActions = false,
  canStartAutomations,
  ...props
}) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const [missingContactType, setMissingContactType] = useState('');
  const [showLogActivity, setShowLogActivity] = useState(false);
  const [showSelectedAutomation, setShowSelectedAutomation] = useState();
  const [missingInfoModalProps, , missingInfoModal] = useModal({
    closeOnSubmit: true,
  });
  const authentication = useSelector((s) => s.authentication);

  const { fullName, access } = contact || {};

  const {
    access: sendEmailAccess,
    notPermittedText,
    unsubscribed,
  } = checkSendEmailAvailability(contact, t);

  const sendEmail = useCallback(() => {
    const a = document.createElement('a');
    a.href = `mailto:${contact.email}`;
    a.click();
  }, [contact.email]);

  const sendEmailUnsubscribed = () => {
    sendEmail();
    missingInfoModal.hide();
  };

  const onClickEmail = useCallback(() => {
    if (!sendEmailAccess || unsubscribed) {
      setMissingContactType(CONTACT_TYPE_EMAIL);
      missingInfoModal.show();
    } else {
      sendEmail();
    }
  }, [sendEmailAccess, unsubscribed, missingInfoModal, sendEmail]);

  const onClickPhone = useCallback(() => {
    const phoneNumber =
      contact.businessPhone || contact.mobilePhone || contact.homePhone || null;
    if (!phoneNumber) {
      setMissingContactType(CONTACT_TYPE_PHONE);
      missingInfoModal.show();
    } else {
      const a = document.createElement('a');
      a.href = `tel:${phoneNumber}`;
      a.click();
    }
  }, [
    contact.businessPhone,
    contact.homePhone,
    contact.mobilePhone,
    missingInfoModal,
  ]);

  const actionOptions = useMemo(
    () =>
      [
        {
          value: 'phone',
          label: t('Call'),
          onClick: onClickPhone,
        },
        {
          value: 'email',
          label: t('Send Email'),
          onClick: onClickEmail,
        },
        {
          value: 'activity',
          label: t('Log Activity'),
          onClick: () => setShowLogActivity(true),
        },
        canStartAutomations && {
          value: 'automations',
          label: t('Start Automation'),
          onClick: () => setShowSelectedAutomation(true),
        },
      ].filter(Boolean),
    [t, canStartAutomations, onClickPhone, onClickEmail]
  );

  const history = useHistory();
  const scrolled = useDebouncedPastScrollThreshold();

  const [tooltipProps, tooltip] = useTruncationTooltip();
  const { width: screenWidth } = useWindowSize();

  const [showActionMenu, setShowActionMenu] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const actionButtonRef = useRef(null);

  const [predefinedOptions, setPredefinedOptions] = useState({});

  const titleRef = useRef(null);

  useEffect(
    () => {
      if (!scrolled) {
        // makes sure the menus close if scrolled
        setShowActionMenu(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrolled]
  );

  const handleSave = async () => {
    setSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setSubmitting(false);
    }
  };

  const [confirmDeletionModalProps, confirmDeletionModalTriggerProps] =
    useModal();
  const [, , scheduleActivityModal] = useModal();

  const handleArchiveContact = useCallback(async () => {
    const { id } = contact;
    await ClientService.archive(id);
    history.push('/clients');
  }, [contact, history]);

  const optionsWithPermissions = performActionOptions.filter(
    ({ permission }) =>
      can(
        { authentication },
        {
          for: permission,
          action: 'contacts',
        }
      ) ||
      can(
        { authentication },
        {
          for: 'single_record_actions',
          [permission]: 'contacts',
        }
      )
  );

  return (
    <>
      <StyledToolbarWrapper
        className="toolbar-wrapper"
        scrolled={scrolled}
        {...props}
      >
        <Toolbar className="toolbar" scrolled={scrolled}>
          <ToolbarLeft nameBreakpoint={NAME_BREAKPOINT}>
            <BackButton to="/clients">
              <DesktopContent as="span">{t('back')}</DesktopContent>
            </BackButton>
            {layoutOptions?.length > 1 ? (
              <div className="kds flex items-center gap-x-spacer-10 ml-[-20px]">
                <Typography>{t('View')}</Typography>
                <ViewSelect
                  options={layoutOptions}
                  onChange={setSelectedLayout}
                  value={selectedLayout}
                />
              </div>
            ) : null}
          </ToolbarLeft>
          <DesktopContactContent nameBreakpoint={NAME_BREAKPOINT}>
            <NameWrapper>
              <Name {...tooltipProps} type="header" data-qa="contact-full-name">
                {fullName || DEFAULT_NAME}
              </Name>
            </NameWrapper>
          </DesktopContactContent>
          <MobileContent>
            <Header ref={titleRef} {...tooltipProps} type="subheader">
              {fullName || DEFAULT_NAME}
            </Header>
          </MobileContent>
          {!hideActions ? (
            <ToolbarRight nameBreakpoint={NAME_BREAKPOINT}>
              {access.remove && (
                <DesktopOnlyButton
                  {...confirmDeletionModalTriggerProps}
                  color="red"
                >
                  {t('Archive')}
                </DesktopOnlyButton>
              )}
              {optionsWithPermissions.length ? (
                <DesktopOnlyButtonWithDropdown
                  color="blue"
                  onChange={onChange}
                  onClick={openPerformModal}
                  options={optionsWithPermissions}
                  data-qa="perform-action-button"
                >
                  {t('Perform Action')}
                </DesktopOnlyButtonWithDropdown>
              ) : null}
              {access.edit && (
                <DesktopOnlyButton
                  disabled={submitting}
                  onClick={handleSave}
                  color="green"
                >
                  {t('Save')}
                </DesktopOnlyButton>
              )}
              <MobileContent>
                <ThreeDots
                  className={`three-dots ${scrolled ? 'is-scrolled' : ''}`}
                  disabled={!scrolled}
                  screenWidth={screenWidth}
                  ref={actionButtonRef}
                  onClick={() => scrolled && setShowActionMenu(true)}
                >
                  <Icon icon="three-dot" />
                  {actionOptions.length > 2 ? (
                    <FlyingDot screenWidth={screenWidth} className="a" />
                  ) : null}
                  <FlyingDot screenWidth={screenWidth} className="b" />
                  <FlyingDot screenWidth={screenWidth} className="c" />
                  {actionOptions.length > 3 ? (
                    <FlyingDot screenWidth={screenWidth} className="d" />
                  ) : null}
                </ThreeDots>
                <SelectOverlay
                  show={showActionMenu}
                  target={titleRef.current}
                  onHide={() => {
                    setShowActionMenu(false);
                  }}
                  zIndexNudge={layers.content(0, 3)}
                >
                  <StyledSelectMenu
                    value={selectedAction || null}
                    onChange={(value) => {
                      setSelectedAction(null);
                      setShowActionMenu(false);
                      value.onClick();
                    }}
                    options={actionOptions}
                    autoFocus={false}
                    placeholder={t('Select Option')}
                    menuOnly
                  />
                </SelectOverlay>
                <ActivityActions
                  showLogActivity={showLogActivity}
                  target={titleRef.current}
                  onSetVisible={(val) => setShowLogActivity(val)}
                  scrolled={scrolled}
                  onShowScheduleActivity={() => scheduleActivityModal.show()}
                  setPredefinedOptions={(val) => setPredefinedOptions(val)}
                  shownScrolled
                  contact={contact}
                  logActivityActions={logActivityActions}
                  fieldState={fieldState}
                  activityList={activityList}
                />
                <AutomationActions
                  showSelectedAutomation={showSelectedAutomation}
                  target={titleRef.current}
                  contact={contact}
                  onSetVisible={(val) => setShowSelectedAutomation(val)}
                  scrolled={scrolled}
                  predefinedOptions={predefinedOptions}
                  shownScrolled
                  scheduleActivityModal={scheduleActivityModal}
                  onStartAutomation={onStartAutomation}
                  customObjectId={clientObject?.id}
                />
              </MobileContent>
              <MobileContent>
                {access.edit && (
                  <SaveButton
                    variant="text"
                    disabled={submitting}
                    onClick={handleSave}
                  >
                    {t('save')}
                  </SaveButton>
                )}
              </MobileContent>
            </ToolbarRight>
          ) : (
            <ToolbarRight nameBreakpoint={NAME_BREAKPOINT} />
          )}
        </Toolbar>
      </StyledToolbarWrapper>
      <MobileContent>
        <ActionsWrapper className="ActionsWrapper" scrolled={false}>
          <ActionBar
            condensed={false}
            contact={contact}
            clientObject={clientObject}
            scrolled={scrolled}
            onStartAutomation={onStartAutomation}
            logActivityActions={logActivityActions}
            fieldState={fieldState}
            canStartAutomations={canStartAutomations}
            activityList={activityList}
            onClickEmail={onClickEmail}
            onClickPhone={onClickPhone}
          />
        </ActionsWrapper>
      </MobileContent>
      <ArchiveModal
        {...confirmDeletionModalProps}
        onConfirm={handleArchiveContact}
      >
        {t(
          'This will archive the Contact and all associated information, so that it can no longer be accessed.'
        )}
      </ArchiveModal>
      {missingContactType === CONTACT_TYPE_EMAIL ? (
        <ConfirmationModal
          actionBtnColor={unsubscribed ? 'green' : 'blue'}
          {...missingInfoModalProps}
          buttonText={unsubscribed ? t('Continue') : t('Close')}
          heading={
            unsubscribed
              ? t('Please Confirm This Action')
              : t('Cannot Perform This Action')
          }
          leftBtn={unsubscribed ? undefined : null}
          onConfirm={
            unsubscribed ? sendEmailUnsubscribed : missingInfoModalProps.onHide
          }
        >
          {notPermittedText}
        </ConfirmationModal>
      ) : null}
      {missingContactType === CONTACT_TYPE_PHONE ? (
        <MissingContactInformationModal
          missingContactType={missingContactType}
          {...missingInfoModalProps}
        />
      ) : null}

      {tooltip}
    </>
  );
};

export default ControlBar;
