import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { addYears } from 'date-fns';
import { grayScale } from '../../../app/colors';
import { fontSizes } from '../../../app/typography';
import {
  DatePicker as KizenDatePicker,
  IntlProvider,
  defaultLocale,
} from '../DateRangePicker';
import Button from '../../Button';

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${grayScale.medium};
  padding: 13px 15px;
  display: flex;
  justify-content: ${({ showClearButton }) =>
    showClearButton ? 'space-between' : 'flex-end'};
  > button {
    height: unset;
    font-size: ${fontSizes.small};
    line-height: 1;
  }
`;

function getCalendarDate([value]) {
  const currentDate = value || new Date();
  return [currentDate, addYears(new Date(), 100)];
}

const DatePicker = ({
  value,
  locale,
  onSelect,
  onHide,
  showClearButton = true,
  showApplyButton = true,
}) => {
  const { t } = useTranslation();
  const calendarValue = useMemo(() => (value ? [value] : []), [value]);

  const [calendarDate, setCalendarDate] = useState(() =>
    getCalendarDate(calendarValue)
  );
  const handleChangeCalendarDate = useCallback(
    (index, date) => {
      const nextCalendarDate = [...calendarDate];
      nextCalendarDate[index] = date;
      setCalendarDate(nextCalendarDate);
    },
    [calendarDate]
  );

  return (
    <>
      <IntlProvider locale={locale || defaultLocale}>
        <KizenDatePicker
          value={calendarValue}
          calendarDate={calendarDate}
          onChangeCalendarDate={handleChangeCalendarDate}
          onSelect={onSelect}
        />
      </IntlProvider>
      {(showClearButton || showApplyButton) && (
        <ButtonsWrapper showClearButton={showClearButton}>
          {showClearButton && (
            <Button
              noSpace
              variant="text"
              color="red"
              onClick={() => onSelect(null)}
            >
              {t('Clear')}
            </Button>
          )}
          {showApplyButton && (
            <Button noSpace variant="text" onClick={onHide}>
              {t('Apply')}
            </Button>
          )}
        </ButtonsWrapper>
      )}
    </>
  );
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  locale: PropTypes.objectOf(PropTypes.string),
};

DatePicker.defaultProps = {
  value: null,
  locale: null,
};

export default DatePicker;
