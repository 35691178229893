import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import MonthCalendarCell from './MonthCalendarCell';
import { ExpandableMonthCalendarCellProps } from './props';

const BORDER_WIDTH = 1;

const Container = styled(MonthCalendarCell)`
  ${({
    expanded,
    expansion,
    translation,
    rowWidth,
    rowHeight,
    pinBottom,
    pinTop,
    pinLeft,
    pinRight,
  }) => {
    const translate =
      Array.isArray(translation) && translation.length === 2
        ? translation
        : [-expansion[0] / 2, -expansion[1] / 2];

    if (pinTop) {
      translate[1] = 0;
    } else if (pinRight) {
      translate[0] = -expansion[0] + BORDER_WIDTH;
    } else if (pinBottom) {
      translate[1] = -expansion[1] + BORDER_WIDTH;
    } else if (pinLeft) {
      translate[0] = 0;
    }

    return expanded
      ? css`
          transform: translate(${translate[0]}px, ${translate[1]}px);
          width: ${rowWidth + expansion[0]}px;
          height: ${rowHeight + expansion[1]}px;
          transition: all 0.6s;
          z-index: ${layers.content(10)};
          border-width: ${BORDER_WIDTH}px;
          border-style: solid;
        `
      : css`
          width: ${rowWidth}px;
          height: ${rowHeight}px;
        `;
  }}
`;

/**
 * Extension of {@link DateCell} component. Meant to be used in conjunction with {@link ExpandableCalendar}. The wrapping calendar
 * component provides the `rowWidth` and `rowHeight` props which allows the width and height of the this component
 * to be transitioned when adding the user supplied `expansion` prop.
 *
 * @remarks By default the cell will be translated by the inverse of half the expansion value in the x and y
 * direction such that the cell will be centered over its original position. This can be overridden by providing
 * the `translation` prop to explicitly state the translation values for x and y.
 *
 * @param props.expanded - boolean to trigger application of additional height & width
 * @param props.rowWidth - the initial width of the cells row (provided by {@link ExpandableCalendar})
 * @param props.rowHeight - the initial height of the cells row (provided by {@link ExpandableCalendar})
 * @param props.expansion - [x, y] 2-tuple to provide the additional height and width of the cell
 * @param props.translation - [x, y] 2-tuple to provide the translation in the x and y direction. Defaults to [-expansion[0], -expansion[1]]
 * @param props.pinTop - all extra content will expand downwards - no negative y translation.
 * @param props.pinRight - all extra content will expand to the left - no positive x translation.
 * @param props.pinBottom - all extra content will expand upwards - no positive y translation.
 * @param props.pinLeft - all extra content will expand to the right - no negative x translation.
 * @returns - react component
 */
const ExpandableMonthCalendarCell = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default ExpandableMonthCalendarCell;

ExpandableMonthCalendarCell.propTypes = ExpandableMonthCalendarCellProps;
