import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters } from 'app/spacing';
import { colorsButton } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import Icon from '../Icon';
import IconButton from '../IconButton';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import StylePassthrough from '../StylePassthrough';

import { getDataQAForInput } from 'components/Inputs/helpers';

export const NO_VALUE = '—';

export const getFormattedData = (column, data) => {
  if (!data) {
    return data;
  }

  const value = data[column.id];

  if (column.format) {
    return column.format(value, data, column);
  }

  return value;
};

export const TextEllipsis = styled(KizenTypography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
`;

TextEllipsis.displayName = 'TextEllipsis';

export const TextEllipsisWithTooltip = forwardRef(
  (
    { children, tooltipLabel, showLabel, label, popperConfig, ...others },
    forwardedRef
  ) => {
    const [tooltipProps, tooltip] = useTruncationTooltip({
      label: showLabel ? label : undefined,
      tooltipLabel: !showLabel ? tooltipLabel : label,
      popperConfig,
    });
    children = children || children === 0 ? children : NO_VALUE;
    return (
      <>
        {tooltip}
        <TextEllipsis {...tooltipProps} {...others} ref={forwardedRef}>
          {children}
        </TextEllipsis>
      </>
    );
  }
);

export const SizedCell = styled(
  ({
    text,
    column,
    head,
    data,
    children,
    showNotesId,
    onClickNotes,
    onClickComplete,
    minWidth,
    maxWidth,
    flex,
    ...others
  }) => {
    const BaseCell = head ? 'th' : 'td';
    const ContentWrapper = text ? TextEllipsis : React.Fragment;

    return (
      <BaseCell className="basecell " {...others}>
        <div>
          <ContentWrapper>
            {children || getFormattedData(column, data)}
          </ContentWrapper>
        </div>
      </BaseCell>
    );
  }
)`
  ${({ width, padding = '0px' }) =>
    width
      ? css`
          width: ${padding === '0px' ? width : `calc(${width} + ${padding})`};
          min-width: ${padding === '0px'
            ? width
            : `calc(${width} + ${padding})`};
          max-width: ${padding === '0px'
            ? width
            : `calc(${width} + ${padding})`};
        `
      : css`
          max-width: 400px;
        `};
  ${({ minWidth, maxWidth, padding = '0px' }) =>
    minWidth
      ? css`
          min-width: calc(${minWidth} + ${padding});
          max-width: calc(${maxWidth || minWidth} + ${padding});
        `
      : ''};
  > div {
    max-width: 100%;
    ${({ padding = '0px' }) =>
      padding
        ? css`
            padding-right: ${padding};
          `
        : ''}
    ${({ flex }) =>
      flex
        ? css`
            display: flex;
            align-items: center;
          `
        : ''};
    ${({ flex, align }) =>
      align
        ? css`
            ${flex ? 'justify-content' : 'text-align'}: ${align};
          `
        : ''};
  }
`;

export const HeaderSizedCell = styled(
  ({ label, text, column, head, data, children, ...others }) => {
    const BaseCell = head ? 'th' : 'td';
    return (
      <BaseCell {...others}>
        <div>
          <TextEllipsisWithTooltip as="span" weight="bold">
            {label || column.label}
          </TextEllipsisWithTooltip>
        </div>
      </BaseCell>
    );
  }
)`
  ${({ minWidth, padding = '0px' }) =>
    minWidth
      ? css`
          min-width: calc(${minWidth} + ${padding});
          max-width: calc(${minWidth} + ${padding});
        `
      : ''};
  ${({ maxWidth, padding = '0px' }) =>
    maxWidth
      ? css`
          max-width: calc(${maxWidth} + ${padding});
        `
      : ''};
  ${({ width, padding = '0px' }) =>
    width
      ? css`
          width: calc(${width} + ${padding});
          min-width: calc(${width} + ${padding});
          max-width: calc(${width} + ${padding});
        `
      : ''};
  > div {
    max-width: 100%;
    ${({ padding = '0px' }) =>
      padding
        ? css`
            padding-right: ${padding};
          `
        : ''}
    ${({ flex }) =>
      flex
        ? css`
            display: flex;
            align-items: center;
          `
        : ''};
    ${({ flex, align }) =>
      align
        ? css`
            ${flex ? 'justify-contents' : 'text-align'}: ${align};
          `
        : ''};
  }
`;

const SortButton = styled(IconButton)`
  margin-left: ${gutters.spacing(2)}px;
  && svg {
    height: 12px;
    width: auto;
  }
`;

// Ensure the no-sort icon has the same width as other icons
// so that it doesn't cause jitter by changing content size in
// table when toggled.
const NoSortSizing = styled(StylePassthrough)`
  width: 10.5px;
`;

export const SortableHeadCell = ({
  column,
  label,
  tooltipLabel,
  numeric,
  data: { meta = {} },
  by,
  children,
  hasSorting,
  ...others
}) => {
  const { t } = useTranslation();
  const direction = meta.sort && meta.sort.direction;
  const sortId = by || column.id;
  const sort = meta.sort && meta.sort.column === sortId;

  return (
    <SizedCell flex data-qa={`cell-head-${column.id}`} {...others}>
      <TextEllipsisWithTooltip
        as="span"
        weight="bold"
        tooltipLabel={tooltipLabel}
      >
        {label || column.label}
      </TextEllipsisWithTooltip>
      {hasSorting && (
        <SortButton
          sizing="dense"
          title={t('Sort')}
          color={sort ? colorsButton.blue : colorsButton.iconGray}
          onClick={() => {
            if (meta.onSort) {
              const nextDirection =
                !sort || direction === 'desc' ? 'asc' : 'desc';
              meta.onSort(sortId, nextDirection);
            }
          }}
        >
          {!sort && (
            <NoSortSizing>
              <Icon icon="no-sort" />
            </NoSortSizing>
          )}
          {sort && direction === 'asc' && !numeric && (
            <Icon icon="sort-alpha-asc" />
          )}
          {sort && direction === 'desc' && !numeric && (
            <Icon icon="sort-alpha-desc" />
          )}
          {sort && direction === 'asc' && numeric && (
            <Icon icon="sort-numeric-asc" />
          )}
          {sort && direction === 'desc' && numeric && (
            <Icon icon="sort-numeric-desc" />
          )}
        </SortButton>
      )}
      {children}
    </SizedCell>
  );
};

SortableHeadCell.defaultProps = {
  label: null,
  numeric: false,
  by: null,
  data: null,
  column: null,
  hasSorting: true,
};

export const TRow = React.memo(
  React.forwardRef(
    ({ cell, head, columns, data, children, ...others }, ref) => {
      return (
        <tr ref={ref} data-row-id={data?.id} {...others}>
          {children}
          {columns &&
            columns.map((column) => {
              const columnCell = (head ? column.headCell : column.cell) || cell;
              return React.cloneElement(columnCell, {
                key: column.id,
                head,
                column,
                data,
              });
            })}
        </tr>
      );
    }
  )
);

TRow.displayName = 'TRow';

TRow.defaultProps = {
  cell: <SizedCell text />,
  head: false,
  columns: null,
  data: null,
};

export const NewTRow = React.memo(
  React.forwardRef(
    (
      {
        cell,
        head,
        columns,
        data,
        children,
        autoFocusField,
        submitUnchangedList = [],
        fixWidthStyle,
        isCustomObjects,
        ...others
      },
      ref
    ) => {
      return (
        <tr ref={ref} data-row-id={data?.id} {...others}>
          {children}
          {columns &&
            columns.map((column, index) => {
              const columnCell = (head ? column.headCell : column.cell) || cell;

              if (column.bodyCell) {
                const {
                  field,
                  onSubmit,
                  serviceToUse,
                  model,
                  handleUpdateFieldOption,
                  handleUpdateTableRecords,
                  minWidth,
                  Component,
                  meta,
                  isMenuCell,
                  isCustomObjects,
                  ...rest
                } = column.bodyCell;
                if (isMenuCell) {
                  return (
                    <SizedCell
                      key={index}
                      column={column}
                      minWidth={minWidth}
                    />
                  );
                }

                return (
                  <SizedCell
                    key={index}
                    column={column}
                    minWidth={minWidth}
                    onFocus={column.onFocus}
                    onClick={column.onClick}
                    {...fixWidthStyle}
                    data-qa={column.label}
                  >
                    <Component
                      {...rest}
                      key={column.id}
                      field={field}
                      object={data}
                      onSubmit={onSubmit}
                      isCustomObjects={isCustomObjects}
                      objectId={model?.id}
                      serviceToUse={serviceToUse}
                      column={column}
                      handleUpdateFieldOption={handleUpdateFieldOption}
                      handleUpdateTableRecords={handleUpdateTableRecords}
                      meta={meta}
                      emptyPlaceholder=""
                      autoFocus={autoFocusField === column.id}
                      submitUnchanged={submitUnchangedList.some(
                        (el) => column.id === el
                      )}
                      {...getDataQAForInput(
                        `inline-record-field-${field?.name}`,
                        field?.fieldType || 'missing'
                      )}
                    />
                  </SizedCell>
                );
              }
              return React.cloneElement(columnCell, {
                key: column.id,
                head,
                column,
                data,
              });
            })}
        </tr>
      );
    }
  )
);

NewTRow.displayName = 'NewTRow';

NewTRow.defaultProps = {
  cell: <SizedCell text />,
  head: false,
  columns: null,
  data: null,
};

export const Table = React.forwardRef(
  ({ head, children, tableBodyRef, ...others }, ref) => {
    return (
      <table ref={ref} {...others}>
        {head && <thead>{head}</thead>}
        {children && <tbody ref={tableBodyRef}>{children}</tbody>}
      </table>
    );
  }
);

Table.displayName = 'Table';

Table.defaultProps = {
  head: null,
};

export const EmptyCell = ({
  head = false,
  column = undefined,
  data = undefined,
  ...others
}) => {
  const BaseCell = head ? 'th' : 'td';
  return <BaseCell {...others} />;
};
