import { ReactPortal, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Typography } from '../Typography/Typography';
import { merge } from '../util';
import { Position } from './types';
import { getTooltipLocation } from './util';

export type TooltipProps = {
  position?: Position;
  target: HTMLElement | null;
  text: string;
  title?: string;
  modalCompatability?: boolean;
};

export type TooltipContentProps = {
  position: Position;
  text: string;
  title?: string;
};

const getTooltipContentMargin = (position: Position) => {
  if (position === 'bottom') {
    return 'mt-[-1px]';
  }
  if (position === 'top') {
    return 'mb-[-1px]';
  }
  if (position === 'right') {
    return 'mr-[-1px]';
  }
  if (position === 'left') {
    return 'ml-[-1px]';
  }
};

// transform-origin is a valid svg attribute but v17 of react doesn't recognize it.
const transformOriginCenter = { 'transform-origin': 'center' };

const Triangle = ({ transform }: { transform?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      overflow="visible"
    >
      <path
        d="M5.5 0L11 7L0 7L5.5 0Z"
        fill="var(--color-background-darker)"
        transform={transform}
        {...(transform ? transformOriginCenter : null)}
      />
    </svg>
  );
};

const TooltipContent = (props: TooltipContentProps) => {
  const { position, text, title } = props;

  return (
    <div
      className={merge(
        'kds flex flex-col items-center justify-center gap-10 bg-background-darker rounded-[3px] p-spacer-10 max-w-[500px]',
        getTooltipContentMargin(position)
      )}
    >
      {title && (
        <Typography
          truncate
          color="font-white"
          size="sm"
          className="text-center max-w-[480px]"
        >
          {title}
        </Typography>
      )}
      <Typography color="font-white" size="sm" className="text-center">
        {text}
      </Typography>
    </div>
  );
};

// const BottomLeft = ({ position, text, title }: TooltipContentProps) => {
//   return (
//     <div className="flex flex-col w-fit">
//       <Triangle transform="translate(10)" />
//       <TooltipContent position={position} text={text} title={title} />
//     </div>
//   );
// };

const BottomMiddle = ({ position, text, title }: TooltipContentProps) => {
  return (
    <div className="flex flex-col w-fit items-center">
      <Triangle />
      <TooltipContent position={position} text={text} title={title} />
    </div>
  );
};

// const BottomRight = ({ position, text, title }: TooltipContentProps) => {
//   return (
//     <div className="flex flex-col w-fit items-end">
//       <Triangle transform="translate(-10)" />
//       <TooltipContent position={position} text={text} title={title} />
//     </div>
//   );
// };

// const TopLeft = ({ position, text, title }: TooltipContentProps) => {
//   return (
//     <div className="flex flex-col w-fit">
//       <TooltipContent position={position} text={text} title={title} />
//       <Triangle transform="translate(10) rotate(180)" />
//     </div>
//   );
// };

const TopMiddle = ({ position, text, title }: TooltipContentProps) => {
  return (
    <div className="flex flex-col w-fit items-center">
      <TooltipContent position={position} text={text} title={title} />
      <Triangle transform="rotate(180)" />
    </div>
  );
};

// const TopRight = ({ position, text, title }: TooltipContentProps) => {
//   return (
//     <div className="flex flex-col w-fit items-end">
//       <TooltipContent position={position} text={text} title={title} />
//       <Triangle transform="translate(-10) rotate(180)" />
//     </div>
//   );
// };

// const RightTop = ({ position, text, title }: TooltipContentProps) => {
//   return (
//     <div className="flex w-fit">
//       <Triangle transform="translate(2 7) rotate(270)" />
//       <TooltipContent position={position} text={text} title={title} />
//     </div>
//   );
// };

const RightMiddle = ({ position, text, title }: TooltipContentProps) => {
  return (
    <div className="flex w-fit items-center">
      <Triangle transform="translate(2) rotate(270)" />
      <TooltipContent position={position} text={text} title={title} />
    </div>
  );
};

// const RightBottom = ({ position, text, title }: TooltipContentProps) => {
//   return (
//     <div className="flex w-fit items-end">
//       <Triangle transform="translate(2 -7) rotate(270)" />
//       <TooltipContent position={position} text={text} title={title} />
//     </div>
//   );
// };

// const LeftTop = ({ position, text, title }: TooltipContentProps) => {
//   return (
//     <div className="flex w-fit">
//       <TooltipContent position={position} text={text} title={title} />
//       <Triangle transform="translate(-2 7) rotate(90)" />
//     </div>
//   );
// };

const LeftMiddle = ({ position, text, title }: TooltipContentProps) => {
  return (
    <div className="flex w-fit items-center">
      <TooltipContent position={position} text={text} title={title} />
      <Triangle transform="translate(-2) rotate(90)" />
    </div>
  );
};

// const LeftBottom = ({ position, text, title }: TooltipContentProps) => {
//   return (
//     <div className="flex w-fit items-end">
//       <TooltipContent position={position} text={text} title={title} />
//       <Triangle transform="translate(-2 -7) rotate(90)" />
//     </div>
//   );
// };

export const Tooltip = (props: TooltipProps): ReactPortal => {
  const {
    position = 'bottom',
    target,
    text,
    title,
    modalCompatability,
  } = props;
  const tooltip = useRef<HTMLDivElement>(null);
  const [location, setLocation] = useState({ top: -1, left: -1 });

  useLayoutEffect(() => {
    const tooltipRect = tooltip.current?.getBoundingClientRect();
    const targetRect = target?.getBoundingClientRect();

    if (!tooltipRect || !targetRect) {
      return;
    }

    const { top, left } = getTooltipLocation(position, tooltipRect, targetRect);

    setLocation({
      top: top + window.scrollY,
      left: left + window.scrollX,
    });
  }, [position, target]);

  const style =
    location.top === -1 && location.left === -1
      ? { transform: 'translate(-10000px, -10000px)' }
      : location;

  return createPortal(
    // This needs a high z-index becase of the modal backdrop. The app supports
    // up to 5 nested modals - see react-app/src/app/modalBackdrop
    <div
      ref={tooltip}
      style={style}
      className={merge('absolute', modalCompatability ? 'z-[5051]' : 'z-[2]')}
    >
      {position === 'bottom' && (
        <BottomMiddle position={position} text={text} title={title} />
      )}
      {position === 'top' && (
        <TopMiddle position={position} text={text} title={title} />
      )}
      {position === 'right' && (
        <RightMiddle position={position} text={text} title={title} />
      )}
      {position === 'left' && (
        <LeftMiddle position={position} text={text} title={title} />
      )}
    </div>,
    document.body
  );
};
