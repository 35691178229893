import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsPrimary, grayScale } from '../../app/colors';

const Wrapper = styled.div`
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  display: flex;
  flex: 1;
`;

const Bar = styled.div`
  ${({ width, height, color, radius }) =>
    width &&
    css`
      width: ${width}px;
      height: ${height}px;
      background-color: ${color};
      border-radius: ${radius / 2}px;
    `}
`;

const calcBarWidth = (width, radius, percentage) => {
  const w = parseInt(width, 10);
  const r = parseInt(radius, 10);
  const p = Math.max(0, Math.min(100, parseInt(percentage, 10)));
  return ((w - r) * p) / 100 + r;
};

const PercentageBar = ({
  percentage,
  width,
  radius,
  primaryColor,
  backgroundColor,
}) => {
  const barWidth = calcBarWidth(width, radius, percentage);
  return (
    <Wrapper width={width}>
      <Bar
        width={width}
        height={radius}
        color={backgroundColor}
        radius={radius}
      >
        <Bar
          width={barWidth}
          height={radius}
          color={primaryColor}
          radius={radius}
        />
      </Bar>
    </Wrapper>
  );
};

PercentageBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  width: PropTypes.number,
  radius: PropTypes.number,
  primaryColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

PercentageBar.defaultProps = {
  width: 100,
  radius: 10,
  primaryColor: colorsPrimary.green.dark,
  backgroundColor: grayScale.mediumLight,
};

export default PercentageBar;
