import React, { useState } from 'react';
import styled from '@emotion/styled';
import { gutters, breakpoints } from 'app/spacing';
import { UnderlinedTextarea as Textarea } from 'components/Kizen/Textarea';
import Input, { UnderlinedInput } from 'components/Kizen/Input';
import { KizenTypography } from 'app/typography';
import SubSection from './helpers/SubSection';
import Cols from './helpers/Cols';
import Section from './helpers/Section';

const StyledInput = styled(UnderlinedInput)`
  margin-bottom: ${gutters.standard};
`;

const StyledTextarea = styled(Textarea)`
  margin-bottom: ${gutters.standard};
`;

const StyledSubSection = styled(SubSection)`
  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 30px;
    hr {
      display: none;
    }
  }
`;

const LOREM = `Text Typed
1
2
3
4`;

const StyledInputLabel = styled(KizenTypography)`
  margin: 25px 0 25px;
`;

const TextSampleUnderline = () => {
  const [text, setText] = useState('');
  const [contentText, setContentText] = useState('Scott');
  const [price, setPrice] = useState('');
  const [contentPrice, setContentPrice] = useState('1000.00');
  const [decimal, setDecimal] = useState('');
  const [contentDecimal, setContentDecimal] = useState('0.00');
  const [email, setEmail] = useState('');
  const [contentEmail, setContentEmail] = useState('Scott@Kizen.com');
  const [longtext, setLongtext] = useState('');
  const [contentLongtext, setContentLongtext] = useState(LOREM);
  return (
    <Section title="Text Inputs - Underline">
      <Cols columns={3} gutter="30px">
        <StyledSubSection title="Text Input">
          <StyledInputLabel weight="bold">Placeholder</StyledInputLabel>
          <StyledInput
            label="Text Placeholder"
            placeholder="Enter Text"
            value={text}
            onChange={(value) => setText(value)}
          />
          <StyledInputLabel weight="bold">With Content</StyledInputLabel>
          <StyledInput
            label="Text Name"
            value={contentText}
            onChange={(value) => setContentText(value)}
          />
        </StyledSubSection>

        <StyledSubSection title="Text Input (Email)">
          <StyledInputLabel weight="bold">Placeholder</StyledInputLabel>
          <StyledInput
            type={Input.INPUT_TYPES.EMAIL}
            label="Email Placeholder"
            value={email}
            placeholder="Enter Text"
            onChange={(value) => setEmail(value)}
          />
          <StyledInputLabel weight="bold">With Content</StyledInputLabel>
          <StyledInput
            type={Input.INPUT_TYPES.EMAIL}
            label="Text (Email) Name"
            value={contentEmail}
            onChange={(value) => setContentEmail(value)}
          />
        </StyledSubSection>

        <StyledSubSection title="Text Input (Price)">
          <StyledInputLabel weight="bold">Placeholder</StyledInputLabel>
          <StyledInput
            label="Price Placeholder"
            placeholder="0.00"
            value={price}
            onChange={(value) => setPrice(value)}
            type={Input.INPUT_TYPES.PRICE}
          />
          <StyledInputLabel weight="bold">With Content</StyledInputLabel>
          <StyledInput
            label="Text (Price) Name"
            placeholder="Default"
            value={contentPrice}
            onChange={(value) => setContentPrice(value)}
            type={Input.INPUT_TYPES.PRICE}
          />
        </StyledSubSection>

        <StyledSubSection title="Text Input (Decimal)">
          <StyledInputLabel weight="bold">Placeholder</StyledInputLabel>
          <StyledInput
            type={Input.INPUT_TYPES.DECIMAL}
            label="Decimal Placeholder"
            value={decimal}
            placeholder="Default"
            onChange={(value) => setDecimal(value)}
          />
          <StyledInputLabel weight="bold">With Content</StyledInputLabel>
          <StyledInput
            type={Input.INPUT_TYPES.DECIMAL}
            label="Text (Decimal) Name"
            value={contentDecimal}
            placeholder="Default"
            onChange={(value) => setContentDecimal(value)}
          />
        </StyledSubSection>

        <StyledSubSection title="Long Text">
          <StyledInputLabel weight="bold">Placeholder</StyledInputLabel>
          <StyledTextarea
            label="Long Text Name"
            placeholder="Enter Text"
            value={longtext}
            onChange={(value) => setLongtext(value)}
          />
          <StyledInputLabel weight="bold">With Content</StyledInputLabel>
          <StyledTextarea
            label="Long Text Name"
            value={contentLongtext}
            onChange={(value) => setContentLongtext(value)}
          />
        </StyledSubSection>
      </Cols>
    </Section>
  );
};

export default TextSampleUnderline;
