import DraggableStepCard from '../../DraggableStepCard';

import {
  SIMPLE_BUILDER_TYPES,
  BUILDER_TYPES,
  ELEMENT_TYPES,
  SIMPLE_BUILDER_OPERATION_TYPES,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/MathOperators/constants';
import { getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

const orDeleted = (val, t) => getValueOrDeleted(val, t, t('Deleted'));
const equation = (elements, separator, t) => {
  return (
    elements
      .reduce((result, element, index) => {
        if (index > 0) {
          result = `${result} ${separator(element.operationType)} `;
        }

        if (element.type === ELEMENT_TYPES.constant) {
          return `${result} ${Intl.NumberFormat('en', {
            maximumFractionDigits: 4,
          }).format(element?.value)}`;
        }
        return `${result} [${orDeleted(element?.value?.label, t)}]`;
      }, '')
      .trim() || '1'
  );
};

const getFields = (details, t) => {
  if (!details?.builderType) {
    return '';
  }
  if (details.builderType === BUILDER_TYPES.simple) {
    // hand addition / subtract
    if (details.subType === SIMPLE_BUILDER_TYPES.addSubtract) {
      const { targetField, elements } = details.simpleData[details.subType];
      return `[${orDeleted(targetField?.label, t)}] = ${equation(
        elements,
        (operationType) =>
          operationType === SIMPLE_BUILDER_OPERATION_TYPES.add.value
            ? SIMPLE_BUILDER_OPERATION_TYPES.add.label
            : SIMPLE_BUILDER_OPERATION_TYPES.sub.label,
        t
      )}`;
    }
    // handle multiply / divide
    const { targetField, numeratorElements, denominatorElements } =
      details.simpleData[details.subType];
    return `[${orDeleted(targetField?.label, t)}] = (${equation(
      numeratorElements,
      () => 'X',
      t
    )}) / (${equation(denominatorElements, () => 'X', t)})`;
  }
  // TODO add type spread-sheet type
  return '';
};

export const getDescription = ({ step, t }) => {
  const { details } = step;
  return `${t('Math')}: ${getFields(details, t)}`;
};
export default function MathOperatorCard({ type, step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
