import React from 'react';

import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { getLink } from '../helpers';
import styled from '@emotion/styled';
import { FIELD_TYPES } from 'utility/constants';
const RowWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;
const StyledLink = styled(TextEllipsisWithTooltip)`
  word-break: break-all;
  display: inline;
`;

export const AssociationsBlock = ({ associations, ...others }) => {
  if (!associations) {
    return null;
  }
  return (
    <RowWrapper {...others}>
      {Object.entries(associations || {}).map(([key, value], i) => (
        <>
          {i > 0 && <TextEllipsisWithTooltip>,&nbsp;</TextEllipsisWithTooltip>}
          <StyledLink
            key={key}
            type="anchor"
            href={getLink(
              { id: value.entityId, name: value.entityName },
              {
                type: FIELD_TYPES.Relationship.type,
                ...(!value.isContact
                  ? {
                      metaInfo: {
                        relation: { fetchUrl: 'standard', relatedObject: key },
                      },
                    }
                  : {}),
              }
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value.entityName}
          </StyledLink>
        </>
      ))}
    </RowWrapper>
  );
};
