import { useTranslation } from 'react-i18next';
import { getColumns } from '../../util';
import AccessRequestTableMine from 'components/AccessRequests/Tables/Mine';
import useRespondToAccessRequest from '../../hooks/useRespondToAccessRequest';

const MyChartGroupsPage = (props) => {
  const { t } = useTranslation();

  useRespondToAccessRequest(props.refetchDashboards, props.objectId);

  return (
    <AccessRequestTableMine
      {...props}
      getColumns={getColumns}
      entitySingular={t('Chart Group')}
      entityPlural={t('Chart Groups')}
      deleteConfirmationMessage={t(
        'Deleting this chart group will remove it for all team members. This action is permanent and the chart group cannot be recovered.'
      )}
    />
  );
};

export default MyChartGroupsPage;
