import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';

export const TrackingWarning = styled(KizenTypography)`
  font-style: italic;
  margin-bottom: 15px;
  color: ${grayScale.mediumDark};
`;
export const TrackingWrapper = styled.div`
  margin-top: -5px;
  margin-bottom: 20px;
`;
