import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/Inputs/TextInput';

export const InternalName = memo(({ errorMessage, showError, ...rest }) => {
  const { t } = useTranslation();

  return (
    <TextInput
      variant="underline"
      label={t('Internal Name (Defaults to Subject Line)')}
      validate={{ message: errorMessage, showMessage: showError }}
      {...rest}
    />
  );
});
