import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters } from 'app/spacing';
import { TextSpan } from 'app/typography';

const TextSpanWithText = ({ text, children = text, ...props }) => (
  <TextSpan {...props}>{children}</TextSpan>
);

TextSpanWithText.propTypes = {
  text: PropTypes.node,
};

TextSpanWithText.defaultProps = {
  text: null,
};

export default styled(TextSpanWithText)`
  display: inline-flex;
  align-items: center;
  ${({ variant, start, end }) => {
    if (!start && !end) {
      return css``;
    }
    const side = start ? 'right' : 'left';
    const otherSide = side === 'left' ? 'right' : 'left';
    return css`
    margin-${side}: ${gutters.spacing(2, -2)}px;
    ${variant !== 'underline' && css`margin-${otherSide}: -2px;`}
    `;
  }}
`;
