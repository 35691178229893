import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { CloudTypes } from 'pages/FreeTrialProcess/FreeTrialProcessTypes';
import AuthenticationService from 'services/AuthenticationService';
import Icon from 'components/Kizen/Icon';
import { grayScale } from 'app/colors';
import {
  Container,
  StyledCols as Cols,
  StyledHeader as Header,
  StyledInfoBox as InfoBox,
  StyledParagraph,
  StyledIconsContainer,
  StyledContactIcon,
  StyledCard as Card,
} from './styles';
import CreateSubscriptionForm from './components/Forms/CreateSubscriptionForm';
import CompleteSubscriptionForm from './components/Forms/CompleteSubscriptionForm';
import CalculateInvestmentForm from './components/Forms/CalculateInvestmentForm';
import { BusinessLevelTypes, cloudsMap } from './model';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';

const initialValues = {
  marketing_cloud: false,
  sales_cloud: false,
  experience_cloud: false,
  business_intelligence: false,
  business_intelligence_included: false,
  business_level: BusinessLevelTypes.ESSENTIALS,
  other_admins: [],
  card_name: '',
  card_number: null,
  expiration_date: null,
  cvv_cvc: null,
  num_contacts: 0,
  num_sales_users: 0,
  num_experience_users: 0,
  num_team_members: 0,
};

const SubscriptionPage = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  const [formValues, setFormValues] = useState(initialValues);

  // TODO: this is temporary flag for hiding payment wall page
  // credit card payment is not yet hooked up so we want to show trial ended page all the time
  // Logic behind this should be changed once more information is provided
  const connectedCreditCard = useSelector(
    ({ authentication }) =>
      !!authentication.chosenBusiness &&
      authentication.chosenBusiness.default_payment_gateway
  );
  const hasActiveSubscription = useSelector(
    ({ authentication }) =>
      !!authentication.chosenBusiness &&
      authentication.chosenBusiness.has_active_subscription
  );

  useEffect(() => {
    async function getBusinessInformation() {
      const data = await AuthenticationService.getBusinessInformation();
      setFormValues(
        produce((draft) => {
          draft.marketing_cloud = data.marketing_cloud;
          draft.sales_cloud = data.sales_cloud;
          draft.experience_cloud = data.experience_cloud;
          draft.business_level = data.business_level;
          draft.business_intelligence = data.business_intelligence;
          draft.num_contacts = data.num_contacts;
          draft.num_sales_users = data.num_team_members;
          draft.num_experience_users = data.num_team_members;
          draft.num_team_members = data.num_team_members;
        })
      );
    }
    getBusinessInformation();
  }, []);

  const handleFormSubmit = () => {
    console.debug('Now we submit the form ', formValues);
  };

  return (
    <Container>
      {!(connectedCreditCard && hasActiveSubscription) ? (
        <Card>
          <Header type="header" margin="lg">
            {t('Your Free Trial Has Ended')}
          </Header>
          <InfoBox>
            <StyledParagraph>
              {t(
                'Your 14 day free trial has ended - we hope you’ve been loving Kizen and appreciate the attention to detail we put into every feature. To continue with your account, please contact us via phone or email and we’ll walk you through our product plans to create your perfect Kizen experience.'
              )}
            </StyledParagraph>
          </InfoBox>
          <StyledIconsContainer>
            <StyledContactIcon href="tel:5123875846">
              <Icon icon="phone" color={grayScale.dark} />
              <span>{t('(512) 387-5846')}</span>
            </StyledContactIcon>
            <StyledContactIcon href="mailto:Support@Kizen.com">
              <Icon icon="envelope" color={grayScale.dark} />
              <span>{t('Support@Kizen.com')}</span>
            </StyledContactIcon>
          </StyledIconsContainer>
        </Card>
      ) : (
        <>
          <CreateSubscriptionForm
            values={formValues}
            onFieldChange={(field, value) =>
              setFormValues({ ...formValues, [field]: value })
            }
            toggleBusinessBox={(field, value) => {
              // If Marketing Cloud box, Sales Cloud box or Experience Cloud box gets selected
              // Business Intelligence box should also be automatically selected and
              // Inlcuded label below should be displayed
              // User can unselect Business Intelligence, in that case the label should also be removed
              // In case Marketing Cloud box, Sales Cloud box and Experience Cloud are unselected
              // Intelligence Box should also be automatically unselected

              setFormValues(
                produce((draft) => {
                  if (value) {
                    draft.business_intelligence_included = value;
                    draft.business_intelligence = value;
                  } else {
                    if (field === cloudsMap[CloudTypes.BUSINESS].name) {
                      draft.business_intelligence_included = value;
                    } else {
                      const businessIncluded = Object.keys(cloudsMap).filter(
                        (key) => {
                          const cloudName = cloudsMap[key].name;
                          return (
                            cloudName !== field &&
                            cloudName !== cloudsMap[CloudTypes.BUSINESS].name &&
                            !!formValues[cloudName]
                          );
                        }
                      );
                      if (!businessIncluded.length) {
                        draft.business_intelligence_included = value;
                        draft.business_intelligence = value;
                      }
                    }
                  }
                })
              );
            }}
          />
          <Cols columns={2} gutter={1}>
            <CalculateInvestmentForm
              values={formValues}
              onFieldChange={(field, value) =>
                setFormValues({ ...formValues, [field]: value })
              }
            />
            <CompleteSubscriptionForm
              values={formValues}
              onFieldChange={(field, value) =>
                setFormValues({ ...formValues, [field]: value })
              }
              onSubmit={handleFormSubmit}
            />
          </Cols>
        </>
      )}
    </Container>
  );
};

export default SubscriptionPage;
