import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from 'app/colors';
import { fontSizes } from 'app/typography';
import { gutters, scrollbarCss } from 'app/spacing';
import { useTooltip } from 'components/Kizen/Tooltip';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import BaseInput from '../TextInput/BaseInput';
import BaseInputLayout from '../TextInput/BaseInputLayout';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import Validation, { useValidation } from '../Validation';
import { applyRef } from '../props';

const UnderlineTextarea = styled(BaseInput, {
  shouldForwardProp: (prop) => !['shrink', 'as'].includes(prop),
})`
  ${scrollbarCss}
  // Makes top spacing similar to the natural vertical flow of UnderlineTextInput
  margin-top: ${gutters.spacing(2, { baseline: true, lineHeight: 1.15 })}px;
  height: 82px;
  line-height: 1.15;
  resize: none;
  &::placeholder {
    opacity: 0;
    transition: opacity 10ms;
  }
  ${({ shrink }) =>
    shrink &&
    css`
      &::placeholder {
        opacity: 1;
      }
    `}
`;

export const UnderlineLayout = styled(BaseInputLayout, {
  shouldForwardProp: (prop) => !['disabled', 'as'].includes(prop),
})`
  border-left: none;
  border-right: none;
  border-top: none;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${grayScale.white};
      border-color: transparent;
      &:hover {
        border-color: transparent;
      }
    `}
`;

const TooltipDiv = styled.div`
  position: absolute;
  margin-top: 15px;
  padding-bottom: 25px;
  top: 0;
  left: 0;
  width: 100%;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}
`;

const HiddenText = styled.span`
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
  white-space: pre;
  font-size: ${fontSizes.text};
  letter-spacing: -0.00125em;
  height: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      height: 20px;
    `}
`;

export const UnderlineLongText = forwardRef((props, ref) => {
  const {
    fieldId,
    className,
    value,
    label,
    placeholder,
    disabled,
    error,
    shrink: shrinkProp,
    onChange,
    onFocus,
    onBlur,
    fieldType,
    tooltipLabel,
    tooltipMaxWdith,
    ...others
  } = props;
  const hiddenTextRef = useRef();
  const [inputWidth, setInputWidth] = useState(0);
  const [inputHeight, setInputHeight] = useState(0);
  const [tooltipProps, tooltip] = useTooltip({
    label: tooltipLabel,
    maxWidth: tooltipMaxWdith,
    popperConfig: {
      modifiers: {
        preventOverflow: {
          enabled: true,
          rootBoundary: 'viewport',
        },
      },
    },
  });

  const { assignFieldHandle } = useKeyBoardContext();

  const validationRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(validationRef, el);
      applyRef(ref, el);
    },
    [ref]
  );
  const [validation, validationProps] = useValidation(validationRef, props);

  const [focused, setFocused] = useState(false);
  const shrink =
    typeof shrinkProp === 'boolean'
      ? shrinkProp
      : Boolean(value || focused || disabled || !label);

  const {
    'data-field-type': _1,
    'data-field-id': _2,
    ...forInputControl
  } = props;

  const inputRef = useRef(null);
  const editRef = useRef(false);
  assignFieldHandle(fieldId, {
    customTab: () => {
      setFocused(false);
    },
    customFocus: () => {
      if (!editRef.current) {
        inputRef.current?.focus();
        inputRef.current?.blur();
        setFocused(true);
        onFocus();
      }
      return inputRef.current;
    },
    customEnter: (e) => {
      if (!editRef.current) {
        setFocused(false);
        return false;
      } else {
        setFocused(!(e.ctrlKey + e.metaKey));
        return !(e.ctrlKey + e.metaKey);
      }
    },
    customUp: (e) => {
      if (!editRef.current) {
        inputRef.current.focus();
      }
    },
    customDown: (e) => {
      if (!editRef.current) {
        inputRef.current.focus();
      }
    },
    customSpace: () => {
      inputRef.current.focus();
    },
    customInput: () => {
      if (!editRef.current) {
        inputRef.current.focus();
        const caretPosition = inputRef.current.value?.length || 0;
        inputRef.current.setSelectionRange(caretPosition, caretPosition);
      }
    },
    disabled,
  });

  useEffect(() => {
    if (others.forceValidation) {
      validation.onSubmission?.();
    }
  }, [others.forceValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // calculate width for tooltip when input is disabled
    if (tooltipLabel && hiddenTextRef.current) {
      setInputWidth(hiddenTextRef.current.offsetWidth);
      setInputHeight(hiddenTextRef.current.scrollHeight);
    }
  }, [tooltipLabel, value]);

  return (
    <MaybeInputControl
      variant="underline"
      forInput
      {...forInputControl}
      shrink={shrink}
    >
      <UnderlineLayout
        ref={mergeRef}
        className={!hasInputControl(props) && className}
        focused={focused}
        disabled={disabled}
        error={error || validation.error}
        onClick={() => {
          if (!disabled) {
            inputRef.current?.focus();
          }
        }}
      >
        {tooltipLabel ? tooltip : null}
        <UnderlineTextarea
          ref={inputRef}
          as="textarea"
          value={disabled ? value || '—' : value || ''}
          onChange={(ev) => {
            if (onChange) onChange(ev.target.value, ev);
          }}
          placeholder={shrink && placeholder}
          shrink={shrink}
          disabled={disabled}
          {...tooltipProps}
          onFocus={(ev) => {
            setFocused(true);
            editRef.current = true;
            if (onFocus) onFocus(ev);
            if (tooltipProps?.onFocus) tooltipProps.onFocus(ev);
          }}
          onBlur={(ev) => {
            setFocused(false);
            editRef.current = false;
            onBlur?.(ev);
            if (tooltipProps?.onBlur) tooltipProps.onBlur(ev);
            validation.onBlur?.(ev);
          }}
          {...others}
        />
        {tooltipLabel ? (
          <>
            <TooltipDiv
              {...tooltipProps}
              width={disabled ? inputWidth : null}
              height={disabled ? 20 : inputHeight}
            />
            <HiddenText ref={hiddenTextRef} disabled={disabled}>
              {value}
            </HiddenText>
          </>
        ) : null}
      </UnderlineLayout>
      <Validation
        inModal={props.inModal}
        errorPlacement={props.errorPlacement}
        onErrorShow={props.onErrorShow}
        onErrorHide={props.onErrorHide}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

export default UnderlineLongText;
