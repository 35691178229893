import React, { useCallback, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Checkbox from './Checkbox';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import SelectableOptions from '../SelectableOptions';
import { applyRef, optionProp, selectedValueProp, variantProp } from '../props';
import MultiSelect from '../MultiSelect';
import Validation, { useValidation } from '../Validation';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { FIELD_TYPES } from 'utility/constants';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { CHECKBOX_COUNT_BREAKPOINT } from './settings';

const CheckboxGroup = React.forwardRef((props, ref) => {
  const {
    fieldId,
    onChange,
    options,
    value: valueProp,
    variant,
    error,
    disabled,
    className,
    inModal,
    errorPlacement,
    fieldType,
    required,
    ...rest
  } = props;
  const { assignFieldHandle } = useKeyBoardContext();
  const { t } = useTranslation();
  const validationRef = useRef();
  const [validation, validationProps] = useValidation(validationRef, props);
  const value = useMemo(() => {
    if (!valueProp || !options) {
      return valueProp;
    }
    return valueProp
      .map((val) =>
        typeof val === 'string' ? options.find((opt) => opt.value === val) : val
      )
      .filter(Boolean);
  }, [valueProp, options]);

  const mergeRef = useCallback(
    (node) => {
      applyRef(ref, node);
      applyRef(validationRef, node);
    },
    [ref]
  );
  const lastChanged = useRef(null);
  const refs = useRef([]);
  const selectRef = useRef(null);
  const selectedElem = useMemo(() => {
    const index =
      lastChanged.current !== null
        ? lastChanged.current
        : options.map((i) => i.value).indexOf(value[0]?.value);
    return index >= 0 ? index : 0;
  }, [options, value]);

  const focused = useRef(selectedElem);
  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    props
  );

  assignFieldHandle(fieldId, {
    customTab: (e) => {
      lastChanged.current = null;
      if (
        (variant === 'underline' && options.length >= 5) ||
        options.length === 1
      ) {
        return false;
      }
      if (e.shiftKey) {
        if (focused.current === 0) {
          return false;
        }
        focused.current -= 1;
      } else {
        if (focused.current === refs.current?.length - 1) {
          focused.current = 0;
          return false;
        }
        focused.current += 1;
      }
      refs.current[focused.current]?.focus();
      return true;
    },
    customFocus: () => {
      if (variant === 'underline' && options.length >= 5) {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      } else {
        refs.current[selectedElem]?.focus();
        focused.current = selectedElem;
        return refs.current[selectedElem];
      }
    },
    customEnter: () => {
      if (variant === 'underline' && options.length >= 5) {
        return selectRef.current.state.menuIsOpen;
      } else {
        return false;
      }
    },
    customEscape: () => {
      if (variant === 'underline' && options.length >= 5) {
        setMenuIsOpen(false);
      }
    },
    customUp: (e) => {
      lastChanged.current = null;
      if (fieldType === FIELD_TYPES.Checkboxes.type) {
        e.preventDefault();
      }
      if (variant === 'underline' && options.length >= 5) {
        setMenuIsOpen(true);
      } else {
        if (refs.current.length > 1) {
          if (focused.current === 0) {
            focused.current = refs.current.length - 1;
          } else focused.current -= 1;
          refs.current[focused.current]?.focus();
        }
      }
    },
    customDown: (e) => {
      lastChanged.current = null;
      if (fieldType === FIELD_TYPES.Checkboxes.type) {
        e.preventDefault();
      }
      if (variant === 'underline' && options.length >= 5) {
        setMenuIsOpen(true);
      } else {
        if (refs.current.length > 1) {
          if (focused.current === refs.current.length - 1) {
            focused.current = 0;
          } else focused.current += 1;
          refs.current[focused.current]?.focus();
        }
      }
    },
    disabled,
  });

  if (variant === 'underline' && options.length >= CHECKBOX_COUNT_BREAKPOINT) {
    return (
      <MultiSelect
        ref={selectRef}
        placeholder={t('Find Options')}
        {...props}
        value={value}
        {...customSelectMenuProps}
      />
    );
  }

  const { value: _, ...forInputControl } = props;

  return (
    <MaybeInputControl {...forInputControl}>
      <SelectableOptions
        ref={mergeRef}
        className={!hasInputControl(props) && className}
        required={required}
        {...rest}
      >
        {options.map((opt, i) => (
          <div key={opt.value} required={required}>
            <Checkbox
              ref={(el) => (refs.current[i] = el)}
              label={opt.label}
              value={opt.value}
              checked={value.some((val) => val.value === opt.value)}
              error={error || validation.error}
              disabled={disabled}
              variant={variant}
              onChange={(checked, ev) => {
                focused.current = i;
                lastChanged.current = i;
                const nextValue = checked
                  ? [...value, opt]
                  : value.filter((val) => val.value !== opt.value);
                if (onChange) onChange(nextValue, ev);
              }}
            />
          </div>
        ))}
      </SelectableOptions>
      <Validation
        inModal={inModal}
        errorPlacement={errorPlacement}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

export default CheckboxGroup;

CheckboxGroup.displayName = 'CheckboxGroup';
CheckboxGroup.propTypes = {
  value: PropTypes.arrayOf(selectedValueProp),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(optionProp),
  variant: variantProp,
  error: PropTypes.object,
  disabled: PropTypes.bool,
};
CheckboxGroup.defaultProps = {
  value: [],
  onChange: null,
  options: [],
  variant: null,
  error: null,
  disabled: null,
};

const singleOptions = [{ value: 'selected' }];
const emptyValue = [];

export const CheckboxSingle = React.forwardRef(
  ({ checked, onChange, ...props }, ref) => (
    <CheckboxGroup
      ref={ref}
      {...props}
      options={singleOptions}
      value={checked ? singleOptions : emptyValue}
      onChange={(val, ev) => {
        if (onChange) onChange(val.length > 0, ev);
      }}
    />
  )
);

CheckboxSingle.displayName = 'CheckboxSingle';
CheckboxSingle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  errorPlacement: PropTypes.string,
};
CheckboxSingle.defaultProps = {
  checked: undefined,
  onChange: null,
  errorPlacement: undefined,
};
