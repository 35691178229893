import { unsafeRootQueryClient } from './unsafe-query-client';
import * as QUERY_KEYS from './query-keys';

const createInvalidatorAliases = (keys) =>
  Object.entries(keys).reduce((acc, [keyName, keyVal]) => {
    return {
      ...acc,
      [keyName]: (...args) =>
        unsafeRootQueryClient.invalidateQueries(
          typeof keyVal === 'function' ? keyVal(...args) : keyVal
        ),
    };
  }, {});

/**
 * Helpers to enable the invalidation of react-query queries.
 * This enables legacy parts of our application to remove cached
 * items from react-query's cache without full access to queryclient.
 */

export const invalidate = {
  // Invalidate everything, as needed
  ALL: () => {
    unsafeRootQueryClient.cancelQueries();
    unsafeRootQueryClient.clear();
  },

  /**
   * Invalidate message library or sub-sections
   */
  MESSAGE_LIBRARY: createInvalidatorAliases(QUERY_KEYS.MESSAGE_LIBRARY),

  /**
   * Invalidate timeline
   */
  TIMELINE: createInvalidatorAliases(QUERY_KEYS.TIMELINE),

  /**
   * Invalidate activities
   */
  ACTIVITIES: createInvalidatorAliases(QUERY_KEYS.ACTIVITIES),

  /**
   * Invalidate custom objects
   */
  CUSTOM_OBJECTS: createInvalidatorAliases(QUERY_KEYS.CUSTOM_OBJECTS),

  /**
   * Invalidate permission groups
   */
  PERMISSION_GROUP: createInvalidatorAliases(QUERY_KEYS.PERMISSION_GROUP),

  /**
   * Invalidate images objects
   */
  IMAGES: createInvalidatorAliases(QUERY_KEYS.IMAGES),

  /**
   * Invalidate form builder CO fields
   */
  FORMS: createInvalidatorAliases(QUERY_KEYS.FORMS),

  /**
   * Invalidate team_member list
   */
  TEAM_MEMBERS: createInvalidatorAliases(QUERY_KEYS.TEAM_MEMBERS),

  /**
   * Invalidate team_member list
   */
  ROLES: createInvalidatorAliases(QUERY_KEYS.ROLES),

  /**
   * Invalidate team_member list
   */
  DETAILS_RELATED_OBJECTS: createInvalidatorAliases(
    QUERY_KEYS.DETAILS_RELATED_OBJECTS
  ),

  /** Invalidate related_objects list
   */
  RELATION_FIELD: createInvalidatorAliases(QUERY_KEYS.RELATION_FIELD),

  /**
   * Invalidate queries used within metadata driven filters (see ts-components/filters/hooks/useFilterQuery)
   */
  FILTERS: {
    ALL: () => unsafeRootQueryClient.invalidateQueries(['meta-filters']),
  },

  FIELDS: createInvalidatorAliases(QUERY_KEYS.FIELDS),
};
