import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from 'app/colors';
import Button from 'components/Button';

import { borderRadii } from 'app/spacing';

import { truncateCss } from 'app/typography';
// export const AnchorButton = styled.a`
export const AnchorButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadii.small};
  width: 100%;
  background-color: ${grayScale.white};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${grayScale.dark};
    background-color: ${grayScale.white};
  }
  height: 36px;
  lineheight: 34px;
  margin: 10px 0 5px 0;
  letter-spacing: unset;
  ${({ selected }) =>
    selected
      ? css`
          border: solid 1px ${colorsButton.blue.hover};
          color: ${grayScale.dark};
          background-color: ${grayScale.white};
          > span {
            cursor: default;
          }
        `
      : css`
          border: solid 1px ${grayScale.medium};
          color: ${grayScale.dark};
        `}
  &:hover {
    border: solid 1px ${colorsButton.blue.hover};
    color: ${grayScale.dark};
    background-color: ${grayScale.white};
  }
  > span {
    margin: 0 10px;
    font-size: 14px;
    text-decoration: none;
    text-transform: none;
    font-weight: 400;
    ${truncateCss};
  }
`;
