import styled from '@emotion/styled';
import { grayScale, shadowLight } from 'app/colors';
import { KizenTypography } from 'app/typography';
import { CheckboxSingle } from 'components/Inputs/CheckboxGroup';

export const VerifyAccountWrapper = styled.div`
  background-color: ${grayScale.white};
  border-radius: 8px;
  box-shadow: 0px 18px 26px rgba(0, 0, 0, 0.03);
  padding: 20px;
  padding-bottom: 15px;
  padding-top: 17px;
  margin: 16vh auto;
  width: 400px;
  border: 1px solid ${grayScale.mediumLight};
  ${shadowLight}
  .InputControl {
    margin-bottom: 17px;
    label {
      margin-bottom: 15px; // KDS requirements
    }
  }
`;
export const StyledKizenTypography = styled(KizenTypography)`
  padding-bottom: 16px;
`;
export const Terms = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr;
  margin-top: -2px;
  padding-bottom: 2px;
  label {
    align-items: flex-start;
    margin: 0;
  }
  p,
  a {
    line-height: 130%;
  }
`;
export const Links = styled.div`
  padding-top: 8px;
  a {
    color: #4090f7; // KDS requirements
  }
`;
export const ButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  align-items: center;
  padding-top: 12px;
`;
export const StyledCheckboxSingle = styled(CheckboxSingle)`
  height: 17px;
`;
