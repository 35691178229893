import React from 'react';

export default function PipelineEntityIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.4985 0H1.50178C0.169358 0 -0.502924 1.61658 0.441092 2.56064L9 11.1213V19.875C8.99999 20.0888 9.04569 20.3001 9.13404 20.4948C9.22239 20.6895 9.35134 20.8631 9.51225 21.0038L12.5123 23.628C13.4703 24.4663 15 23.8006 15 22.4992V11.1213L23.5591 2.56064C24.5013 1.6185 23.8336 0 22.4985 0ZM13.5 10.5V22.5L10.5 19.875V10.5L1.5 1.5H22.5L13.5 10.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
