import { useMemo } from 'react';
import { getHeightFromLayoutRows } from '../utils';

export const getHTMLConfig = (dashlet) => {
  const content = dashlet?.config?.content;

  if (!content) {
    return {};
  }

  const builderContent = {
    ...content,
    ROOT: {
      ...content.ROOT,
      props: {
        ...content.ROOT.props,
        height: undefined,
        maxWidth: undefined,
        hasShadow: false,
        backgroundColor: 'transparent',
      },
    },
  };

  const htmlBlockConfig = {
    height: content.ROOT.props.height,
    maxWidth: content.ROOT.props.maxWidth,
    hasShadow: content.ROOT.props.hasShadow,
    backgroundColor: content.ROOT.props.backgroundColor,
    columns: content.ROOT.props.columns,
  };

  return { builderContent, htmlBlockConfig };
};

export const applyConfig = (builderContent, blockConfig, layout) => {
  return {
    ...builderContent,
    ROOT: {
      ...builderContent.ROOT,
      props: {
        ...builderContent.ROOT.props,
        height: layout?.h
          ? getHeightFromLayoutRows(layout.h)
          : blockConfig.height,
        maxWidth: blockConfig.maxWidth,
        hasShadow: blockConfig.hasShadow,
        backgroundColor: blockConfig.backgroundColor,
      },
    },
  };
};

export const useHTMLConfig = (dashlet) => {
  const res = useMemo(() => {
    return getHTMLConfig(dashlet);
  }, [dashlet]);

  return res;
};
