import { useMemo } from 'react';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';

import ActionCell from 'components/Tables/ActionCell';
import ValidatedField from 'components/Kizen/ValidatedField';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { useTranslation } from 'react-i18next';
import {
  getCustomObjectLinkProps,
  getQueryFilterLinkProps,
} from 'pages/Common/helpers/formsAndSurveys';

const emptyLongDash = '—';

export const FORM_ACTIONS = {
  Edit: { value: 'edit', localizedLabel: (t) => t('Edit') },
  Preview: { value: 'preview', localizedLabel: (t) => t('Preview') },
  Duplicate: { value: 'duplicate', localizedLabel: (t) => t('Duplicate') },
  Delete: { value: 'delete', localizedLabel: (t) => t('Delete') },
};

const FormsActionCell = ({ data, ...others }) => {
  const { t } = useTranslation();
  const options = useMemo(
    () =>
      (data.relatedObject?.entityAccess
        ? [
            FORM_ACTIONS.Edit,
            FORM_ACTIONS.Preview,
            FORM_ACTIONS.Duplicate,
            FORM_ACTIONS.Delete,
          ]
        : [FORM_ACTIONS.Preview]
      ).map((item) => ({ ...item, label: item.localizedLabel(t) })),
    [data.relatedObject?.entityAccess, t]
  );

  return <ActionCell data={data} options={options} {...others} />;
};

const getFormsColumns = ({ onUpdateField, onSelectAction, t }) => [
  {
    id: 'spacer',
    headCell: <SizedCell width="20px" />,
    cell: <SizedCell width="20px" />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        by="name"
        label={t('Form Name')}
        minWidth="302px"
        padding="60px"
      />
    ),
    cell: <SizedCell minWidth="302px" padding="60px" />,
    format: (name, form) => {
      const { id } = form;
      return form.relatedObject?.entityAccess ? (
        <ValidatedField
          type="link"
          to={`/forms/${id}`}
          value={name}
          onSubmit={(value) => {
            if (!value) {
              throw new Error('Form name cannot be blank.');
            }
            if (form.name === value) {
              return null;
            }
            return onUpdateField(form, { name: value });
          }}
        />
      ) : (
        <TextEllipsisWithTooltip>{name}</TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'objectName',
    headCell: (
      <SortableHeadCell
        by="related_object__object_name"
        label={t('Form Object')}
        minWidth="200px"
        padding="30px"
      />
    ),
    cell: <SizedCell minWidth="200px" padding="30px" />,
    format: (_, { relatedObject }) => (
      <TextEllipsisWithTooltip
        {...getCustomObjectLinkProps(relatedObject)}
        data-qa="form-object"
      >
        {relatedObject?.objectName || emptyLongDash}
      </TextEllipsisWithTooltip>
    ),
  },
  {
    id: 'numberSubmissions',
    headCell: (
      <SortableHeadCell
        numeric
        by="number_submissions"
        label={t('# Submissions')}
        minWidth="125px"
        padding="250px"
      />
    ),
    cell: <SizedCell minWidth="125px" padding="250px" />,
    format: (value, form) => (
      <TextEllipsisWithTooltip
        {...getQueryFilterLinkProps(form)}
        data-qa="number-submissions"
      >
        {value || 0}
      </TextEllipsisWithTooltip>
    ),
  },
  {
    id: 'created',
    headCell: (
      <SortableHeadCell
        numeric
        by="created"
        label={t('Date Created')}
        width="150px"
        padding="60px"
      />
    ),
    cell: <SizedCell minWidth="150px" padding="60px" />,
    format: (created) => (
      <DateTimeInputInline readOnly value={created} showDateTooltip />
    ),
  },
  {
    id: 'actions',
    headCell: <SubActionHeadCell width="90px" padding="20px" />,
    cell: (
      <FormsActionCell
        width="90px"
        title={t('Edit Form')}
        padding="20px"
        onSelectAction={onSelectAction}
        menuType="absolute"
      />
    ),
  },
];

export default getFormsColumns;
