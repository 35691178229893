import React from 'react';
import * as PropTypes from 'prop-types';
import { getOriginalError } from 'services/AxiosService';
import { useFlashTransition } from 'hooks/useFlashState';
import { validate } from 'components/Inputs/TextInput/presets/EmailAddress';
import TextInputInline from '..';

export default function EmailAddressInline({ onSubmit, ...others }) {
  const [message, showMessage, flashErrorMessage] = useFlashTransition();
  return (
    <TextInputInline
      validate={{
        ...validate,
        message,
        showMessage,
      }}
      onSubmit={
        onSubmit &&
        (async (val) => {
          try {
            return await onSubmit(val);
          } catch (err) {
            const orig = getOriginalError(err);
            if (JSON.stringify(orig).includes('already exists')) {
              flashErrorMessage(
                'This email address already exists. Please choose another or update the existing record.'
              );
              throw new TextInputInline.Error(null, { failed: true });
            }
            if (orig.email?.length) {
              flashErrorMessage(orig.email[0]);
              throw new TextInputInline.Error(null, { failed: true });
            }
            throw err;
          }
        })
      }
      {...others}
    />
  );
}

EmailAddressInline.propTypes = {
  onSubmit: PropTypes.func,
};

EmailAddressInline.defaultProps = {
  onSubmit: null,
};
