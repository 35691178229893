import { drop } from '@thi.ng/transducers';
import { Permission } from './permission';
import { getSourceStreamEntry, getRule } from './state-build';
import {
  PermissionsContext,
  PermissionConfig,
  Section,
  PermissionValue,
} from './types';
import { getAccessNumber, getStreamKey, isPermissionOfSection } from './utils';

/**
 * Update multiple source stream values at once.
 *
 * @param values - object of values keyed by the same lookup key as the `sources` Map.
 * @param ctx - current context containing the `sources` Map.
 */
export const updatePermissionValues = (
  values: Record<string, PermissionValue>,
  ctx: PermissionsContext
) => {
  const { sources } = ctx;

  for (const [key, value] of Array.from(Object.entries(values))) {
    if (!sources.has(key)) {
      throw Error(
        `Attempted to update source stream ${key} with its default value but this source stream does not exist.`
      );
    }

    const stream = sources.get(key)!;
    const { allowed_access } = stream.deref()!;
    const next = typeof value === 'boolean' ? value : getAccessNumber(value);
    stream.next({ allowed_access, value: next });
  }
};

/**
 * Build source streams, Rules, and Permissions for each provided permission config under a given section in an existing context.
 *
 * @remarks This follows the same process outlined in {@link buildPermissionsContext}, adding new streams,
 * Rules, and Permissions to the existing `sources`, `rules`, and `permission` Maps in the context. Additionally,
 * the new Permission value streams will be added to the `updates` sync stream _without_ the `drop(1)` transducer
 * that is used in {@link buildPermissionsContext} so these new permission values are immediately available.
 *
 * @param configs - permission config objects to provide to {@link getSourceStreamEntry}.
 * @param sectionId - the existing section to create permissions under.
 * @param ctx - the existing permission context.
 * @returns {Permission[]} - the new Permission classes
 */
export const addPermissionsForSection = <T extends '' | Section = Section>(
  configs: PermissionConfig<T>[],
  sectionId: string,
  ctx: PermissionsContext
) => {
  const newPermissionStreams: Permission['value'][] = [];
  const {
    sources,
    rules,
    visibility_rules,
    permissions,
    updates,
    hasPermissionEdits,
  } = ctx;
  const metadatas = configs.reduce((acc: any, c) => {
    if (c.metadata) {
      acc[getStreamKey(c.key, sectionId)] = c.metadata;
    }
    return acc;
  }, {});

  const newEntries = configs.map((config) =>
    getSourceStreamEntry(config, sectionId)
  );

  for (const [key, src] of newEntries) {
    if (sources.has(key)) {
      sources.get(key)!.done();
    }
    sources.set(key, src);
  }

  for (const [key] of newEntries) {
    const permission = new Permission(key, sources, metadatas[key]);
    newPermissionStreams.push(permission.value);
    permissions.set(key, permission);
  }

  for (const config of configs) {
    const key = getStreamKey(config.key, sectionId);

    if (config.rule) {
      const rule = getRule(permissions, config.rule, sectionId);
      rules.set(key, rule);
      permissions.get(key)?.applyRule(rule);
    }

    if (config.visibility_rule) {
      const rule = getRule(permissions, config.visibility_rule, sectionId);
      visibility_rules.set(key, rule);
      permissions.get(key)?.applyVisibilityRule(rule);
    }
  }

  updates.addAll(newPermissionStreams);
  hasPermissionEdits.addAll(
    newEntries.map(([_, str]) => str.transform(drop(1)))
  );
};

/**
 * Remove all child permissions of a section.
 *
 * @remarks This utility does not close the permission streams or remove them from the Map (yet?).
 * The main purpose of this function is to {@link https://github.com/thi-ng/umbrella/blob/develop/packages/rstream/src/sync.ts#L236 remove all}
 * on the `updates` sync stream so those child permission values do not make their way into the final edits payload.
 *
 * @param sectionId - the section permission id.
 * @param ctx - the permission context to remove from.
 */
export const removeAllPermissionsForSection = (
  sectionId: string,
  ctx: PermissionsContext
) => {
  const { permissions, updates } = ctx;

  const permission_streams: Permission['value'][] = [];
  for (const key of permissions.keys()) {
    if (isPermissionOfSection(key, sectionId)) {
      permission_streams.push(permissions.get(key)!.value);
    }
  }

  updates.removeAll(permission_streams);
};

export const disablePermissionsForSection = (
  sectionId: string,
  ctx: PermissionsContext
) => {
  for (const [key, stream] of ctx.sources.entries()) {
    if (isPermissionOfSection(key, sectionId)) {
      const current = stream.deref()!;
      const next = typeof current.value === 'boolean' ? false : 0;
      stream.next({ ...current, value: next });
    }
  }
};
