import { colorsButton } from 'app/colors';
import IconButtonAbsoluteMenu from 'components/Tables/Big/IconButtonAbsoluteMenu';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsMenuWrapper, StyledIconMenu } from './styles';

const OPTIONS = {
  VIEW_CHART: 'view_on_chart',
};

const StatsMenu = ({ viewOnChart }) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [
      {
        value: OPTIONS.VIEW_CHART,
        label: t('View on Chart'),
      },
    ];
  }, [t]);

  const handleChange = useCallback(
    ({ value }) => {
      if (value === OPTIONS.VIEW_CHART) {
        viewOnChart();
      }
    },
    [viewOnChart]
  );

  return (
    <StatsMenuWrapper>
      <IconButtonAbsoluteMenu
        position="right"
        sizing="dense"
        color={colorsButton.iconGray}
        options={options}
        onChange={handleChange}
        title={t('Email Stats')}
      >
        <StyledIconMenu icon="three-dot" />
      </IconButtonAbsoluteMenu>
    </StatsMenuWrapper>
  );
};

export default StatsMenu;
