import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFlashState from '__hooks/useFlashState';
import TextInput from '__components/Inputs/TextInput';
import BasicModal from '__components/Modals/presets/BasicModal';
import SmartConnectorService from '__services/SmartConnectorService';
import { getOriginalError } from '__services/AxiosService';
import { useToast, toastVariant } from '__components/ToastProvider';
import { SmartConnector } from '__pages/SmartConnectors/types';

type EmailModalProps = {
  smartConnector: SmartConnector;
  onConfirm: (smartConnector: SmartConnector) => void;
};

export const EmailModal = ({
  smartConnector,
  onConfirm,
  ...others
}: EmailModalProps) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(smartConnector.error_notification_email);
  const [errors, setErrors] = useFlashState(1500, {});
  const [showToast] = useToast();

  const handleConfirm = async () => {
    try {
      const { data: updatedSmartConnector } =
        await SmartConnectorService.updateSmartConnector(
          smartConnector.id,
          { error_notification_email: email },
          { skipErrorVoundary: true }
        );
      onConfirm(updatedSmartConnector);
    } catch (error) {
      const originalError = getOriginalError(error);
      if (originalError) {
        setErrors(originalError);
      } else {
        showToast({
          variant: toastVariant.FAILURE,
          message: t('Error Notification Settings Update Failed'),
        });
      }
    }
  };

  return (
    <BasicModal
      heading={t('Error Notification Settings')}
      onConfirm={handleConfirm}
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      {...others}
    >
      <TextInput
        label={t('Additional Error Notification Email')}
        labelInfo={t(
          'Error emails will always be sent to the uploading user by default, but you may also add another email recipient (such as a distribution group)'
        )}
        labelInfoPlacement="top"
        labelInfoMaxWidth={255}
        placeholder={t('Email')}
        value={email}
        onChange={setEmail}
        inModal
        validate={
          errors.error_notification_email && {
            message: errors.error_notification_email,
            showMessage: true,
          }
        }
      />
    </BasicModal>
  );
};
