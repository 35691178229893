import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileService from 'services/FileService';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import useModal from 'components/Modals/useModal';
import PreviewBlock from './PreviewBlock';
import { useProgress } from 'hooks/uploadFiles/useProgress';
import { useUploadFile } from 'hooks/uploadFiles/useUploadFile';
import { useFiles } from 'hooks/uploadFiles/useFiles';
import FilePicker from 'components/FilePicker';
import {
  FileLibraryModalHeaderWrapper,
  FileLibraryDetailsWrapper,
  Spacer,
  FileLibraryModalHeader,
  SearchInputWrapper,
  StyledModal,
  LeftColumn,
  RightColumn,
} from './styles';
import { allowedFileExtensions } from 'components/FilePicker/defaults';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import { FilesList } from './FilesList';
import { useModalConfiguration } from './useModalConfiguration';
import { EMPTY_OBJECT, EMPTY_ARRAY } from 'utility/fieldHelpers';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

const FileLibraryModal = ({
  files: filesProp = EMPTY_ARRAY,
  initialFiles,
  onChange,
  onHide,
  modalProps = EMPTY_OBJECT,
  modal,
  disabled = false,
  viewable = true,
  publicFile,
  source,
  show,
  className,
  style,
  field,
  hasUnsavedConfirmation,
}) => {
  const { t } = useTranslation();
  const [progress, { clearProgress, initializeProgress, updateProgress }] =
    useProgress();
  const uploadFile = useUploadFile(publicFile, updateProgress, source);
  const [allFiles, { addFiles, updateFile, deselectFile, setFiles }] = useFiles(
    {
      initial: filesProp,
      initializeProgress,
      uploadFile,
    }
  );
  //track initial files to renew the field value on cancel changes
  const [initial, setInitial] = useState(filesProp);
  const [deleting, setDeleting] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const initialFilesById = useMemo(() => {
    if (!initialFiles) {
      return null;
    }

    return initialFiles.reduce((acc, file) => {
      acc[file.id] = file;
      return acc;
    }, {});
  }, [initialFiles]);

  const {
    files,
    filePreview,
    selectedFile,
    setSearch,
    setSelectedFile,
    showPreviewBlock,
    windowHeight,
    isMobile,
    columnRef,
  } = useModalConfiguration(allFiles);

  const [deleteModalProps, , deleteModal] = useModal({
    handleHide: () => {
      setFileToDelete(null);
    },
    handleSubmit: async () => {
      // delete file from the Files
      const nextFiles = allFiles.filter((fi) => fi.id !== fileToDelete.id);
      setFiles(nextFiles);
      //remove deleted file from tracking changes as it is irreversible
      setInitial((prev) => prev.filter((file) => file.id !== fileToDelete.id));

      try {
        setDeleting(true);
        await FileService.delete({
          id: fileToDelete.id,
          key: fileToDelete.key,
        });
      } finally {
        setDeleting(false);
      }

      setFileToDelete(null);
    },
  });

  const handleAddFiles = useCallback(
    async (updatedFile) => {
      await addFiles([...updatedFile, ...allFiles]);
      clearProgress();
    },
    [addFiles, clearProgress, allFiles]
  );

  const deleteFile = (file) => {
    setFileToDelete(file);
    deleteModal.show();
  };

  const onHideConfirmed = useCallback(() => {
    onHide?.();
    modal?.hide();
    // changing file name is saved immediately on BE so we want to update the form state
    // even if Save button is not clicked, all other changes are guarded by ConfirmationModal
    if (hasUnsavedConfirmation) {
      onChange?.(
        initial.map((file) => ({
          ...file,
          name: allFiles.find((f) => f.id === file.id)?.name || file.name,
        }))
      );
    }
  }, [onHide, modal, onChange, initial, allFiles, hasUnsavedConfirmation]);

  const [confirmationModalProps, , confirmationModal] = useModal({
    handleSubmit: onHideConfirmed,
  });

  const modalOpts = useMemo(() => {
    const handleHide = () => {
      if (hasUnsavedConfirmation) {
        const filesById = allFiles.reduce((acc, file) => {
          acc[file.id] = true;
          return acc;
        }, {});

        if (
          allFiles?.length !== initial?.length ||
          initial.some((file) => !filesById[file.id])
        ) {
          return confirmationModal.show();
        }
      }
      onHideConfirmed();
    };

    const handleConfirm = async () => {
      if (onChange) {
        await onChange(allFiles);
      }

      if (modal) {
        modal.hide();
      }
    };

    return disabled
      ? {
          onConfirm: handleHide,
          onHide: handleHide,
          actionBtnColor: 'blue',
          buttonText: 'Close',
        }
      : {
          onConfirm: handleConfirm,
          onHide: handleHide,
        };
  }, [
    disabled,
    allFiles,
    initial,
    modal,
    onChange,
    confirmationModal,
    onHideConfirmed,
    hasUnsavedConfirmation,
  ]);

  // aggregate all active progress
  const status = useMemo(() => {
    const keys = Object.keys(progress || {});
    return allFiles && progress
      ? Math.min(
          100,
          Number.parseInt(
            keys.reduce((acc, key) => {
              return acc + progress[key].progress;
            }, 0) / keys.length,
            10
          )
        )
      : 0;
  }, [progress, allFiles]);

  const canDeleteFile = useCallback(
    (fileId) => {
      if (!filesProp.some((file) => file.id === fileId)) {
        // newly uploaded files can always be deleted
        return true;
      }
      if (initialFilesById && !initialFilesById[fileId]) {
        // on the record details pages the field is not saved when closing the modal.
        // a newly uploade file can be deleted if the modal is opeed again before saving the record.
        return true;
      }
      return field?.access.remove ?? false;
    },
    [field, initialFilesById, filesProp]
  );

  const canEditFile = useCallback(
    (fileId) => {
      if (!filesProp.some((file) => file.id === fileId)) {
        return true;
      }
      if (initialFilesById && !initialFilesById[fileId]) {
        return true;
      }
      return field?.access.edit ?? false;
    },
    [field, initialFilesById, filesProp]
  );

  return (
    <>
      <StyledModal
        heading={t('File Library')}
        buttonText={t('Save')}
        size="large"
        {...modalProps}
        {...modalOpts}
        defaultLeftBtnText={t('Cancel')}
        disabled={progress || deleting}
        show={show}
        className={className}
        style={style}
      >
        <FileLibraryModalHeaderWrapper>
          <FileLibraryModalHeader type="header">
            {`${files.length} ${
              files.length === 1 ? t('Selected File') : t('Selected Files')
            }`}
          </FileLibraryModalHeader>
          <SearchInputWrapper>
            <PageSearchInput
              onChange={setSearch}
              placeholder={t('Find Files')}
              maxWidth={250}
            />
          </SearchInputWrapper>
        </FileLibraryModalHeaderWrapper>
        <FileLibraryDetailsWrapper>
          <LeftColumn
            ref={columnRef}
            isVisible={!disabled || showPreviewBlock}
            windowHeight={windowHeight}
          >
            {disabled ? null : (
              <FilePicker
                onFileAdded={handleAddFiles}
                allowedFileExtensions={allowedFileExtensions}
                uploadStatus={status}
                disabled={progress || deleting}
                multiFileMode={true}
                isMobile={isMobile}
              />
            )}
            <PreviewBlock
              file={filePreview}
              updateFile={updateFile}
              disabled={disabled}
              viewable={viewable}
              isMobile={isMobile}
            />
          </LeftColumn>
          {!disabled || showPreviewBlock ? <Spacer /> : null}
          <RightColumn windowHeight={windowHeight}>
            <FilesList
              files={files}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              deselectFile={deselectFile}
              deleteFile={deleteFile}
              disabled={disabled}
              viewable={viewable}
              showPreviewOption={showPreviewBlock}
              canDeleteFile={canDeleteFile}
              canEditFile={canEditFile}
            />
          </RightColumn>
        </FileLibraryDetailsWrapper>
      </StyledModal>
      <ConfirmDeletionModal {...deleteModalProps}>
        {t('This will permanently delete the file.')}
      </ConfirmDeletionModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        {...confirmationModalProps}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
};

export default FileLibraryModal;
