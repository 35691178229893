import { isEqual } from 'lodash';
import { useEffect, useContext } from 'react';
import WizardStateContext from '../../shared/context/wizardstate';

/*
 * A hook for setting up data constraints, for example, "if value A is 0 and Value B is 1,
 * set value A to 1".
 *
 * @param {Function} fn - A function, called with the current state, that should return
 * undefined if no changes are needed, or an object of { key, value } that should be updated
 * into the state
 */
const useDataConstraint = (fn) => {
  const { state, setState } = useContext(WizardStateContext.Context);

  useEffect(() => {
    if (fn) {
      const res = fn(state);
      if (res) {
        const { key, value = undefined } = res;
        if (key && !isEqual(state[key], value)) {
          setState(key, value);
        }
      }
    }
  }, [state, setState, fn]);
};

export default useDataConstraint;
