import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import type { Next, Option } from '@kizen/filters/types';
import { buildOptions } from '@kizen/filters/utils';
import { debounce, get } from 'lodash';
import SelectAllButton from '__components/Inputs/MultiSelect/SelectAllButton';
import Multi from '__components/Inputs/MultiSelect';
import { OnChangeProp, Props, SelectOption } from './types';
import {
  getSelectValue,
  useInfiniteFilterOptions,
} from '../hooks/useFilterOptions';
import { useMenuList, LOAD_THRESHOLD } from '../hooks/useMenuList';
import ClearSelectButton from '__components/Inputs/Select/ClearButton';
import ApplySelectButton from '__components/Inputs/Select/ApplyButton';

type MultiSelectProps = Omit<Props, 'value'> &
  OnChangeProp<{ value: Option['value'][]; next?: Next[] }> & {
    value?: any[];
  };

const REMAINING_THRESHOLD = 10;

const belowRemainingThreshold = (options: SelectOption[], values: Option[]) => {
  return options.length - values.length < REMAINING_THRESHOLD;
};

const getValuesFromOptions = (
  options: SelectOption[],
  value?: Option[],
  value_id_path?: MultiSelectProps['value_id_path']
) => {
  if (!value?.length) return [];
  const selected_ids = new Set(
    value.map((x) => (value_id_path ? get(x, value_id_path) : x.value))
  );
  return options.filter((opt) => selected_ids.has(opt.value));
};

export const MultiSelectInfinite = ({
  step,
  value = [],
  width,
  method,
  next,
  placeholder,
  url,
  result_path,
  value_id_path,
  option_filter,
  option_paths,
  body,
  params: paramsProp,
  options: optionsProp,
  error,
  onChange,
}: MultiSelectProps) => {
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useState({ search: '' });
  const [removeInvalidOptions, setRemoveInvalidOptions] = useState(false);
  const params = useMemo(
    () => ({ ...searchParams, ...paramsProp }),
    [searchParams, paramsProp]
  );
  const {
    isLoading,
    isFetchingNextPage,
    options,
    lookup,
    fetchNextPage,
    morePages,
    onMenuClose,
    onMenuOpen,
  } = useInfiniteFilterOptions(
    method,
    url,
    result_path,
    value_id_path,
    option_paths,
    option_filter,
    body,
    params,
    optionsProp,
    value
  );
  const priorSelections = useRef<WeakMap<Option, Option['value']>>(
    new WeakMap()
  );

  const [[invalidOptions, invalidOptionsLookup]] = useState<
    [Option[], Map<string, Option>]
  >(() => {
    const [opts, lookup] = buildOptions(
      value?.filter((opt) => opt.not_found || opt.not_allowed) ?? [],
      { value_id_path, option_paths }
    );
    return [opts.map((opt) => ({ ...opt, error: true })), lookup];
  });

  const allOptions = useMemo(() => {
    const invalidIds = new Set(invalidOptions.map((opt) => opt.value));
    return invalidOptions.concat(
      options.filter((opt) => !invalidIds.has(opt.value))
    );
  }, [invalidOptions, options]);

  const [prevOptions, setPrevOptions] = useState<SelectOption[]>(options);
  const [values, setValues] = useState<Option[]>(() => {
    return getValuesFromOptions(allOptions, value, value_id_path);
  });

  if (options?.length && !prevOptions?.length) {
    setValues(getValuesFromOptions(allOptions, value, value_id_path));
    setPrevOptions(options);
  }

  const handleChange = useCallback(
    (opts: Option[]) => {
      setRemoveInvalidOptions(true);
      setValues(opts);

      const nextValue: any[] = [];
      for (const opt of opts) {
        const v =
          lookup.get(getSelectValue(opt.value)) ??
          invalidOptionsLookup.get(getSelectValue(opt.value)) ??
          priorSelections.current.get(opt);
        nextValue.push(v);
        priorSelections.current.set(opt, v);
      }

      onChange(step, { next, value: nextValue });
    },
    [step, next, lookup, invalidOptionsLookup, onChange]
  );

  const visibleOptions = useMemo(() => {
    if (removeInvalidOptions) {
      const invalidIds = invalidOptions.map((opt) => opt.value);
      return options.filter((x) => !invalidIds.includes(x.value));
    }
    return options;
  }, [options, removeInvalidOptions, invalidOptions]);

  const handleClear = () => {
    setValues([]);
    onChange(step, {
      next,
      value: [],
    });
  };

  const updateSearchParams = useMemo(() => {
    return debounce((value: string) => setSearchParams({ search: value }), 300);
  }, []);

  const { setMenuList, ref } = useMenuList(
    isFetchingNextPage,
    fetchNextPage,
    LOAD_THRESHOLD
  );

  useEffect(() => {
    if (
      morePages &&
      !isFetchingNextPage &&
      belowRemainingThreshold(options, values)
    ) {
      fetchNextPage();
    }
  }, [morePages, isFetchingNextPage, fetchNextPage, values, options]);

  const handleMenuOpen = () => {
    setTimeout(() => setMenuList(ref.current?.select.menuListRef), 50);
    onMenuOpen();
  };

  const handleInputChange = (value: string) => {
    setSearch(value);
    updateSearchParams(value);
  };

  return (
    <Multi
      ref={ref}
      value={values}
      inputValue={search}
      filterOption={false}
      options={visibleOptions}
      placeholder={placeholder}
      width={width}
      onChange={handleChange}
      loadItems={isLoading || isFetchingNextPage}
      error={error}
      menuTopButton={<SelectAllButton />}
      onMenuOpen={handleMenuOpen}
      onInputChange={handleInputChange}
      onMenuClose={onMenuClose}
      menuLeftButton={<ClearSelectButton onClick={handleClear} />}
      menuRightButton={<ApplySelectButton />}
    />
  );
};
