import styled from '@emotion/styled';
import BasicModal from 'components/Modals/presets/BasicModal';

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  height: 80px;

  span {
    width: 100%;
    margin-top: auto;
  }

  button {
    height: 100%;
    display: flex;
    padding-top: 18px;
  }

  i {
    font-size: 12.5px;
    pointer-events: none;
  }
`;

export const CompoundIcon = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const BlockTypeModal = styled(BasicModal)`
  & .modal-body {
    padding-left: 11px;
  }
`;
