import { useState } from 'react';
import CardToolbar, {
  CardToolbarButton,
  CardToolbarTitle,
} from 'components/Layout/CardToolbar';
import { DynamicTagsModal } from '../modals/DynamicTagsModal';
import { useTranslation } from 'react-i18next';
import { StyledCardToolbarSearch, StyledCardToolbarSection } from '../styles';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

export const DynamicTagsManagerToolbar = ({
  includeTitle = false,
  search,
  onChangeSearch,
  onCreateTag,
  dictionary = EMPTY_OBJECT,
  model,
  field,
  ...others
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { tagLabelShort, tagLabelPlural, tagLabel } = dictionary;

  return (
    <>
      <CardToolbar tall {...others}>
        <StyledCardToolbarSection>
          {includeTitle ? (
            <CardToolbarTitle>
              {t('{{objectName}} {{displayName}} Manager', {
                objectName: model?.objectName,
                displayName: field?.displayName || t('Tag'),
              })}
            </CardToolbarTitle>
          ) : null}
          <StyledCardToolbarSearch
            placeholder={t('Find {{options}}', {
              options: tagLabelPlural || t('Options'),
            })}
            value={search}
            onChange={onChangeSearch}
          />
          <CardToolbarButton onClick={() => setShowModal(true)}>
            + {t('Add {{tagLabel}}', { tagLabel: tagLabelShort || t('Tag') })}
          </CardToolbarButton>
        </StyledCardToolbarSection>
      </CardToolbar>
      {showModal && (
        <DynamicTagsModal
          show
          onHide={() => setShowModal(false)}
          onConfirm={onCreateTag}
          tagLabel={tagLabel}
        />
      )}
    </>
  );
};
