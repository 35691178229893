import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useBuilderContext } from '../BuilderContext';
import { TraySection, TraySubsection } from '../components/TraySection';
import { useTranslation } from 'react-i18next';
import { FlexWrapper } from './components';
import { useCraftProps } from './useCraftProps';
import { fontSizes, fontWeights } from 'app/typography';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import { colorsButton, grayScale } from 'app/colors';
import styled from '@emotion/styled';
import { gutters, borderRadii } from 'app/spacing';
import { OverlayTooltip } from 'components/Kizen/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
import KizenTypography from 'app/kizentypo';
import { useMeasure } from 'react-use';

const InfoIconStyles = styled(IconSizing)`
  margin-left: ${gutters.spacing(2)}px;
  margin-top: ${gutters.spacing(1, -3)}px;
  vertical-align: middle;
`;

const SubsectionHeader = styled(KizenTypography)`
  display: flex;
  font-size: ${fontSizes.text};
  font-weight: ${fontWeights.bold};
  line-height: 1;
  margin-top: -1px;
  margin-bottom: ${gutters.spacing(3)}px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: ${({ blockHeight }) => `${blockHeight}px`};
  box-sizing: border-box;
  font-family: 'Lucida Console', monospace;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  resize: none;
  &:focus {
    outline: none;
    border: 1px solid ${colorsButton.blue.hover};
  }
`;

const CustomHeader = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const target = useRef(null);

  return (
    <SubsectionHeader>
      {t('Custom HTML Code')}
      <InfoIconStyles size="10px" ref={target}>
        <Icon
          icon="info-circle"
          color={colorsButton.iconGray.hover}
          onMouseEnter={(ev) => {
            target.current = ev.currentTarget;
            setShow(true);
          }}
          onMouseLeave={() => setShow(false)}
        />
        <Overlay
          show={show}
          placement="bottom"
          target={target.current}
          rootClose={true}
          rootCloseEvent="click"
        >
          <OverlayTooltip>
            {t('Place HTML here and Kizen will render it')} <br />
            {t('as the user will see it in a block on the builder.')} <br />
            {t('This does not support Script tags.')}
          </OverlayTooltip>
        </Overlay>
      </InfoIconStyles>
    </SubsectionHeader>
  );
};

export const HTMLBlockSettingsSection = ({
  node: {
    id,
    data: { props: initialProps },
  },
}) => {
  const { t } = useTranslation();
  const [measureRef, { height }] = useMeasure();
  const { clearContentSettingsTray } = useBuilderContext();
  const [props, setProp, setProps] = useCraftProps(id, initialProps);
  const [htmlContent, setHtmlContent] = useState(props.htmlContent);

  useEffect(() => {
    setProps(initialProps);
    setHtmlContent(initialProps.htmlContent);
  }, [initialProps, setProps]);

  const handleChange = useMemo(() => {
    let timeoutId = null;

    return (ev) => {
      const val = ev.target.value;
      setHtmlContent(val);
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setProp('htmlContent', val), 300);
    };
  }, [setProp]);

  return (
    <TraySection
      onBackClick={clearContentSettingsTray}
      collapsable={false}
      header={t('HTML Block Settings')}
      ref={measureRef}
    >
      <TraySubsection CustomHeader={CustomHeader}>
        <FlexWrapper>
          <StyledTextArea
            onChange={handleChange}
            blockHeight={height - 105}
            value={htmlContent}
          />
        </FlexWrapper>
      </TraySubsection>
    </TraySection>
  );
};
