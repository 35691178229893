import React from 'react';

export default function CycleHamburger(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.5 18C35.5 27.665 27.665 35.5 18 35.5C8.33502 35.5 0.5 27.665 0.5 18C0.5 8.33502 8.33502 0.5 18 0.5C27.665 0.5 35.5 8.33502 35.5 18Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M25.7143 11H10.2857C10.2099 11 10.1373 11.0301 10.0837 11.0837C10.0301 11.1373 10 11.2099 10 11.2857V11.8571C10 11.9329 10.0301 12.0056 10.0837 12.0592C10.1373 12.1128 10.2099 12.1429 10.2857 12.1429H25.7143C25.7901 12.1429 25.8627 12.1128 25.9163 12.0592C25.9699 12.0056 26 11.9329 26 11.8571V11.2857C26 11.2099 25.9699 11.1373 25.9163 11.0837C25.8627 11.0301 25.7901 11 25.7143 11ZM25.7143 24.7143H10.2857C10.2099 24.7143 10.1373 24.7444 10.0837 24.798C10.0301 24.8516 10 24.9242 10 25V25.5714C10 25.6472 10.0301 25.7199 10.0837 25.7735C10.1373 25.827 10.2099 25.8571 10.2857 25.8571H25.7143C25.7901 25.8571 25.8627 25.827 25.9163 25.7735C25.9699 25.7199 26 25.6472 26 25.5714V25C26 24.9242 25.9699 24.8516 25.9163 24.798C25.8627 24.7444 25.7901 24.7143 25.7143 24.7143ZM25.7143 20.1429H10.2857C10.2099 20.1429 10.1373 20.173 10.0837 20.2265C10.0301 20.2801 10 20.3528 10 20.4286V21C10 21.0758 10.0301 21.1484 10.0837 21.202C10.1373 21.2556 10.2099 21.2857 10.2857 21.2857H25.7143C25.7901 21.2857 25.8627 21.2556 25.9163 21.202C25.9699 21.1484 26 21.0758 26 21V20.4286C26 20.3528 25.9699 20.2801 25.9163 20.2265C25.8627 20.173 25.7901 20.1429 25.7143 20.1429ZM25.7143 15.5714H10.2857C10.2099 15.5714 10.1373 15.6015 10.0837 15.6551C10.0301 15.7087 10 15.7814 10 15.8571V16.4286C10 16.5043 10.0301 16.577 10.0837 16.6306C10.1373 16.6842 10.2099 16.7143 10.2857 16.7143H25.7143C25.7901 16.7143 25.8627 16.6842 25.9163 16.6306C25.9699 16.577 26 16.5043 26 16.4286V15.8571C26 15.7814 25.9699 15.7087 25.9163 15.6551C25.8627 15.6015 25.7901 15.5714 25.7143 15.5714Z"
        fill="currentColor"
      />
    </svg>
  );
}
