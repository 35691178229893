import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '..';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validate = {
  full: (value, t) => {
    if (value && !value.includes('@') && !value.includes('.')) {
      return typeof t === 'function'
        ? t('Please include "@" and "." in the email address.')
        : 'Please include "@" and "." in the email address.';
    }

    if (value && !value.includes('@')) {
      return typeof t === 'function'
        ? t('Please include "@" in the email address.')
        : 'Please include "@" in the email address.';
    }

    if (value && !value.includes('.')) {
      return typeof t === 'function'
        ? t('Please include "." in the email address.')
        : 'Please include "." in the email address.';
    }

    if (value && !validateEmail(value)) {
      return typeof t === 'function'
        ? t('Please enter a valid email address.')
        : 'Please enter a valid email address.';
    }

    return null;
  },
};

const EmailAddressTextInput = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <TextInput
      ref={ref}
      {...props}
      validate={{ ...validate, ...props.validate }}
      onChange={(val, ev) => {
        const errorMessage = validate.full(val, t);
        props.onChange(val, ev, errorMessage ? { errorMessage } : null);
      }}
      placeholder={props.placeholder || t('Email@Domain.com')}
    />
  );
});

export default EmailAddressTextInput;

EmailAddressTextInput.displayName = 'EmailAddressTextInput';
EmailAddressTextInput.defaultProps = {
  validate,
};
