import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

/*
 * We need to use getComputedStyle() here and parse out the result because
 * fraction values can't be rounded for this to work correctly. If the width is 3.3 pixels we need to
 * ensure it isn't rounded down to 3.
 */
const getElementWidth = (element) => {
  if (element) {
    return parseFloat(
      (getComputedStyle(element)?.width ?? '0').replace('px', '')
    );
  }
  return 0;
};

const useWidthMeasurement = (cb) => {
  const ref = useRef();
  const [width, setWidth] = useState(0);

  const resizer = useMemo(() => {
    return new ResizeObserver((entries) => {
      setWidth(getElementWidth(entries?.[0]?.target));
    });
  }, []);

  const getWidth = useCallback(() => {
    if (ref.current) {
      setWidth(getElementWidth(ref.current));
    }
  }, []);

  const componentRef = useCallback(
    (element) => {
      if (ref.current && !element) {
        resizer.unobserve(ref.current);
      }
      ref.current = element;
      getWidth();
    },
    [getWidth, resizer]
  );

  useEffect(() => {
    cb?.(width);
  }, [cb, width]);

  useEffect(() => {
    if (ref.current) {
      resizer.observe(ref.current);

      return () => {
        if (ref.current) {
          resizer.unobserve(ref.current);
        }
      };
    }
  }, [resizer]);

  return {
    ref: componentRef,
    plainRef: ref,
    width: Math.round(width),
  };
};

export default useWidthMeasurement;
