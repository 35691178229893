import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import NumberInput from 'react-number-format';
import EditableText from 'components/Kizen/EditableText';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { checkAllowed } from 'components/Inputs/TextInput/NumericValidation';

import TextInputInline from '..';
// .00 is considered empty so that we can clear-out
// price inputs that have two fixed decimal points.
// 0.00 would be considered "zero" in that case.
const EMPTY_VALUE = '.00';

const createTruncatedText = (value, { className } = {}) => {
  return (
    <TextEllipsisWithTooltip className={className}>
      {value}
    </TextEllipsisWithTooltip>
  );
};

export const BaseNumberInput = React.forwardRef(
  (
    {
      value,
      onChange,
      onValueChange,
      editing,
      fixedDecimalScale,
      isInteger,
      decimalOptions,
      ignoreEmptyValue,
      ...others
    },
    ref
  ) => {
    // This allows TextInputInline to bring focus to the number input
    const refFn = useCallback(
      (el) => {
        if (!ref) {
          return;
        }
        if (typeof ref === 'function') {
          ref(el);
        } else {
          ref.current = el;
        }
      },
      [ref]
    );

    return (
      <NumberInput
        inputRef={refFn}
        renderText={createTruncatedText}
        editing={editing}
        // Always show prefix when editing, and do not
        // show prefix when not editing if the value is empty.
        allowEmptyFormatting={editing}
        thousandSeparator
        isAllowed={(inputObj) =>
          checkAllowed(inputObj, decimalOptions, isInteger)
        }
        fixedDecimalScale={
          // Allows focusing an empty price without displaying ".00"
          (!value && value !== 0) || value === EMPTY_VALUE
            ? false
            : fixedDecimalScale
        }
        onValueChange={(vals) => {
          if (onChange) onChange(vals.value);
          if (onValueChange) onValueChange(vals);
        }}
        value={value === EMPTY_VALUE && ignoreEmptyValue ? '' : value}
        {...others}
      />
    );
  }
);

BaseNumberInput.displayName = 'BaseNumberInput';

BaseNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  editing: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  decimalOptions: PropTypes.object,
  ignoreEmptyValue: PropTypes.bool,
};

BaseNumberInput.defaultProps = {
  value: undefined,
  onChange: undefined,
  onValueChange: undefined,
  fixedDecimalScale: undefined,
  editing: undefined,
  decimalOptions: null,
  ignoreEmptyValue: false,
};

export function EditableTextForNumberInput({
  onChange,
  value,
  editing,
  emptyPlaceholder,
  ...others
}) {
  return (
    <EditableText
      onChange={(_, event) => {
        onChange(event);
      }}
      // Now this this is controlled by NumberInput, we need to
      // work to show the empty value, since it's not numeric.
      value={editing || value ? value : emptyPlaceholder}
      {...others}
    />
  );
}

EditableTextForNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
  emptyPlaceholder: PropTypes.string.isRequired,
};

export default function NumericInline({
  value,
  onSubmit,
  numberFormat,
  emptyPlaceholder,
  decimalOptions,
  placeholder,
  ...others
}) {
  return (
    <TextInputInline
      input={
        <BaseNumberInput
          customInput={EditableTextForNumberInput}
          emptyPlaceholder={placeholder ?? emptyPlaceholder}
          decimalOptions={decimalOptions}
          {...numberFormat}
        />
      }
      isReadOnlyNumeric={!others.editable}
      value={value || value === 0 ? value : ''}
      onSubmit={
        onSubmit &&
        ((val) => {
          if (!val || val === EMPTY_VALUE) {
            return onSubmit(null);
          }
          return onSubmit(val);
        })
      }
      {...others}
    />
  );
}

NumericInline.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSubmit: PropTypes.func,
  numberFormat: PropTypes.object,
  emptyPlaceholder: PropTypes.string,
  readOnly: PropTypes.bool,
  decimalOptions: PropTypes.object,
};

NumericInline.defaultProps = {
  value: null,
  onSubmit: null,
  numberFormat: {},
  emptyPlaceholder: '—',
  readOnly: null,
  decimalOptions: null,
};
