import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowBuilderContext } from '../context';
import EditableText from '__components/Kizen/EditableText';
import { MAX_AUTOMATION_NAME_LENGTH } from '__pages/Automations/constants';
import useField from '__hooks/useField';

export const NameInput = () => {
  const { t } = useTranslation();

  const { name, nameError, setName, setNameError } =
    useContext(FlowBuilderContext);

  const [stagedName, setStagedName] = useField(name);

  const handleChange = (val: string) => {
    setStagedName(val);
    setNameError(false);
  };

  const handleBlur = () => {
    setName(stagedName.trim());
  };

  return (
    <EditableText
      placeholder={t('SmartConnector Name')}
      value={stagedName}
      onChange={handleChange}
      onBlur={handleBlur}
      data-qa={`smartconnector-name`}
      error={nameError}
      maxLength={MAX_AUTOMATION_NAME_LENGTH}
    />
  );
};
