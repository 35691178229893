import styled from '@emotion/styled';
import { colorsText } from 'app/colors';
import { fontSizes } from 'app/typography';

export default styled.input`
  flex: 1;
  font-size: ${fontSizes.text};
  letter-spacing: -0.00125em;
  color: ${colorsText.dark};
  background-color: transparent; // No default grey when disabled
  outline: none;
  border: none;
  padding: 0;
  &::placeholder {
    color: ${colorsText.medium};
  }
`;
