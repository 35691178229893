import styled from '@emotion/styled';
import { PageToolbarTitle } from '__components/Layout/PageToolbar';

export const Title = styled(PageToolbarTitle)`
  margin: 0 auto;
  padding: 15px;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  width: 100%;
  padding: 15px 0;
`;

export const JSONEditorWrapper = styled.textarea`
  width: 100%;
  min-height: 500px;
  font-size: 16px;
  line-height: 1.5;
  font-family: monospace;
`;

export const PanelSizer = styled.div`
  min-height: 160px;
  display: flex;
  flex-direction: column;
`;

export const ThumbnailSizer = styled.div`
  height: 35px;
`;

export const Thumbnail = styled.img`
  height: 100%;
`;

export const PanelFooter = styled.div`
  margin-top: auto;
`;

export const ValidationSizer = styled.div`
  height: 20px;
`;
