import styled from '@emotion/styled';
import ReactFlowBase from 'reactflow';

// custom styles
export const ReactFlow = styled(ReactFlowBase)`
  .react-flow__node,
  .react-flow__handle.connectable {
    cursor: initial;
    min-width: unset;
    min-height: unset;
  }
`;

export const ReactFlowWrapper = styled.div`
  height: 0;
  overflow: hidden;
  visibility: hidden;
`;
