import { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import KizenTypography from 'app/kizentypo';
import Cols from 'components/Layout/Cols';
import Select from 'components/Inputs/Select';
import Subsection from 'components/Modals/Common/Subsection';
import {
  ONLY_UPDATE_BLANK,
  ONLY_ADD_OPTIONS,
  NO_UPLOAD,
  isCheckOnlyAddOptions,
  subscriptionField,
  OVERWRITE,
  OVERWRITE_NOT_BLANK,
} from '../utilities';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { grayScale } from 'app/colors';
import {
  CategoryWrapper,
  SpanName,
} from 'components/Modals/UploadCSVData/sections/styles';

const Wrapper = styled.div`
  margin-top: 2px;
`;
const StyledKizenTypography = styled(KizenTypography)`
  margin-top: 0;
`;
const StyledSubsection = styled(Subsection)`
  h3 {
    margin-bottom: 15px;
  }

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  &:last-child {
    margin-bottom: 2px;
  }
`;

const Title = styled(KizenTypography)`
  margin: 0;
`;

const conflictResolutionOptions = (t, hasSkipBlanksOption = false) =>
  [
    {
      value: ONLY_UPDATE_BLANK,
      label: t('Only Update Blank'),
    },
    hasSkipBlanksOption && {
      value: OVERWRITE_NOT_BLANK,
      label: t('Overwrite (skip blanks)'),
    },
    {
      value: OVERWRITE,
      label: hasSkipBlanksOption
        ? t('Overwrite (clear field if blank)')
        : t('Overwrite Fields'),
    },
  ].filter(Boolean);

const getOnlyAddOption = (t) => [
  { value: ONLY_ADD_OPTIONS, label: t('Only Add Options') },
];

const fullConflictResolutionOptions = (t, hasSkipBlanksOption) =>
  conflictResolutionOptions(t, hasSkipBlanksOption).concat(getOnlyAddOption(t));

const TitleRow = styled(({ t, ...props }) => (
  <Cols columns={3} {...props}>
    <Title weight="bold">{t('Kizen Field (API Name)')}</Title>
    <Title weight="bold">{t('CSV Field')}</Title>
    <Title weight="bold">{t('Conflict Resolution')}</Title>
  </Cols>
))`
  padding-bottom: 2px;
`;

const ConflictResolutionOptions = ({
  fieldType,
  csvColumnValue,
  onChange,
  value,
  field = null,
  hasSkipBlanksOption,
}) => {
  const { t } = useTranslation();

  const defaultConflictResolutionOptions = useMemo(() => {
    switch (field?.id) {
      case 'additional_permission_groups':
        return conflictResolutionOptions(t, hasSkipBlanksOption).concat({
          value: ONLY_ADD_OPTIONS,
          label: t('Only Add Permissions'),
        });
      case 'additional_roles':
        return conflictResolutionOptions(t, hasSkipBlanksOption).concat({
          value: ONLY_ADD_OPTIONS,
          label: t('Only Add Roles'),
        });
      default:
        return conflictResolutionOptions(t, hasSkipBlanksOption);
    }
  }, [field, hasSkipBlanksOption, t]);

  if (!csvColumnValue || csvColumnValue === NO_UPLOAD) {
    return <KizenTypography>{t('Select CSV Field First')}</KizenTypography>;
  }

  if (isCheckOnlyAddOptions(fieldType, field)) {
    return (
      <Select
        options={
          field.id === subscriptionField.id
            ? getOnlyAddOption(t)
            : fullConflictResolutionOptions(t, hasSkipBlanksOption)
        }
        placeholder={t('Choose Resolution')}
        value={value}
        onChange={onChange}
      />
    );
  }
  return (
    <Select
      options={defaultConflictResolutionOptions}
      placeholder={t('Choose Resolution')}
      value={value}
      onChange={onChange}
    />
  );
};

const FieldRow = memo(styled(
  ({
    isEntityName = false,
    title,
    name,
    fieldType,
    value,
    CSVOptions,
    onChange,
    resolutionValue,
    setNewEntities,
    field = null,
    id,
    t,
    hasSkipBlanksOption,
    error,
    errorMessage,
    ...props
  }) => {
    const onHandleConflictResolution = (data) => {
      setNewEntities((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          conflictResolution: data.value,
        },
      }));
    };
    return (
      <Cols columns={3} {...props}>
        <TextEllipsisWithTooltip>
          {title} {name ? <SpanName>({name})</SpanName> : null}
        </TextEllipsisWithTooltip>
        <Select
          options={CSVOptions}
          placeholder={t('Choose Field')}
          value={value}
          onChange={(data) => onChange(data, id)}
          error={error}
          validate={{ showMessage: errorMessage, message: errorMessage }}
          inModal
        />
        {field?.id === 'toolbar_template' ? (
          <StyledKizenTypography>
            {t('Apply New Custom Toolbar')}
          </StyledKizenTypography>
        ) : (
          <>
            {isEntityName && <KizenTypography>{t('N/A')}</KizenTypography>}
            {!isEntityName && (
              <ConflictResolutionOptions
                csvColumnValue={value}
                value={resolutionValue}
                fieldType={fieldType}
                onChange={onHandleConflictResolution}
                field={field}
                hasSkipBlanksOption={hasSkipBlanksOption}
              />
            )}
          </>
        )}
      </Cols>
    );
  }
)`
  align-items: center;
  margin-top: 15px;
`);

const MatchRecordsSection = ({
  CSVOptions,
  onChange,
  values,
  newEntities,
  setNewEntities,
  setFinalizeUploadData,
  handleChangeMatchRecords,
  requiredFields,
  hasSkipBlanksOption,
  errorFieldId,
  error,
  errorMessage,
  ...props
}) => {
  const { t } = useTranslation();
  const filteredFields = values.filteredFields;
  const categories = values?.categories;
  const usedCategories = new Set();
  if (isEmpty(newEntities)) {
    return null;
  }
  return (
    <Wrapper>
      <StyledSubsection title={t('Required Field(s)')} {...props}>
        <TitleRow t={t} />
        {requiredFields.map((item) => (
          <FieldRow
            name={item.name}
            key={item.id}
            t={t}
            isEntityName={item?.isEntityName ?? true}
            title={item.title}
            resolutionValue={newEntities[values[item.id]].conflictResolution}
            fieldType={item.fieldType}
            setNewEntities={setNewEntities}
            CSVOptions={CSVOptions.slice(1)}
            id={values[item.id]}
            value={newEntities[values[item.id]].csvColumn}
            onChange={handleChangeMatchRecords}
            hasSkipBlanksOption={hasSkipBlanksOption}
            error={errorFieldId === item.id && error}
            errorMessage={errorFieldId === item.id && errorMessage}
          />
        ))}
      </StyledSubsection>
      <StyledSubsection title={t('Other Field(s)')} {...props}>
        <TitleRow t={t} />
        {filteredFields
          .filter(
            (el) => !requiredFields.some((field) => field.name === el.name)
          )
          .map((el) => {
            const showCategoryTitle =
              categories?.[el.category] && !usedCategories.has(el.category);

            if (showCategoryTitle) {
              usedCategories.add(el.category);
            }
            return (
              <>
                {showCategoryTitle && (
                  <CategoryWrapper>
                    <Cols columns={3}>
                      <TextEllipsisWithTooltip
                        color={grayScale.mediumDark}
                        weight="bold"
                      >
                        {categories[el.category].name}
                      </TextEllipsisWithTooltip>
                    </Cols>
                  </CategoryWrapper>
                )}
                <FieldRow
                  t={t}
                  key={el.id}
                  name={el.name}
                  resolutionValue={newEntities[el.id].conflictResolution}
                  setNewEntities={setNewEntities}
                  fieldType={el.fieldType}
                  title={el.canonicalDisplayName}
                  id={el.id}
                  CSVOptions={CSVOptions}
                  value={newEntities[el.id].csvColumn}
                  onChange={handleChangeMatchRecords}
                  field={el}
                  hasSkipBlanksOption={hasSkipBlanksOption}
                />
              </>
            );
          })}
      </StyledSubsection>
    </Wrapper>
  );
};

export default MatchRecordsSection;
