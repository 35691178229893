import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { borderRadii } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import { TextEllipsis } from 'components/Kizen/Table';
import TextInput from 'components/Inputs/TextInput';
import DateRangePicker from 'components/Kizen/DateRangePicker';

export const StyledPill = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 26px;
  width: fit-content;
  border: 1px solid
    ${({ isError, isOpen }) =>
      isError ? '#FF3D30' : isOpen ? grayScale.mediumDark : grayScale.light};
  &:hover {
    border-color: ${({ isError }) =>
      isError ? '#FF3D30' : grayScale.mediumDark};
  }
  border-radius: ${borderRadii.small};
  background-color: ${({ isReadOnly }) =>
    isReadOnly ? grayScale.light : '#fff'};
  cursor: pointer;
`;

export const StyledPillText = styled(TextEllipsis)`
  line-height: 1em;
  max-width: 150px;
`;

export const StyledBadge = styled.div`
  height: 10px;
  min-width: 10px;
  border-radius: 5px;
  flex-shrink: 0;
  background-color: ${({ isError }) =>
    isError ? '#FF3D30' : grayScale.mediumDark};
  color: #fff;
  font-size: 6px;
  line-height: 1em;
  text-align: center;
  padding: 2px 3px;
  margin-left: 2px;
`;

export const StyledDownArrow = styled(Icon)`
  margin-left: 10px;
  & > svg {
    width: 10px;
  }
`;

export const StyledNumberRangeRow = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px;
`;

export const StyledTextInput = styled(TextInput)`
  margin: -1px;
  width: 387px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  z-index: 1;
`;

export const StyledDateRangePicker = styled(DateRangePicker)`
  display: block;
  width: 635px;
`;
