import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { colorsPrimary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { CardHeader } from '../components/CardHeader';
import { eventTypes, initiatorTypes } from '../../constants';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import { TextSpan } from 'app/typography';
import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';

const LEAD_SOURCE_PREFIX = 'kznjs:';
const LEAD_SOURCE_URL_KEY = LEAD_SOURCE_PREFIX + 'url';

const iconsByType = {
  10: {
    entrance: 'sign-in',
    default: 'bullseye-light',
  },
  20: {
    default: 'bullseye-pointer',
  },
  30: {
    default: 'bullseye-pointer',
  },
};

const DEFAULT_SUBTYPE = 'default';
const DEFAULT_ICON = 'bullseye-pointer';

const cardHeaderConfig = (t) => {
  return {
    [initiatorTypes.siteVisit]: {
      prefix: t('interaction logged'),
      creationType: () => ' ',
    },
  };
};

const dataHeadings = (t) => ({
  [eventTypes.impressionInteraction]: t('URL'),
  [eventTypes.clickInteraction]: t('Item Clicked'),
  [eventTypes.siteentryImpressionInteraction]: t('URL'),
  [eventTypes.customInteraction]: t('Interaction Type'),
});

const Heading = ({ text }) => (
  <TextEllipsisWithTooltip>
    <TextSpan weight="bold" size="micro">
      {text}
    </TextSpan>
  </TextEllipsisWithTooltip>
);

const URLData = ({ data = {} }) => (
  <>
    <TextEllipsisWithTooltip>
      {data[LEAD_SOURCE_URL_KEY] ? (
        <KizenTypography
          type="anchor"
          href={data[LEAD_SOURCE_URL_KEY]}
          target="_blank"
          size="micro"
        >
          {data[LEAD_SOURCE_URL_KEY]}
        </KizenTypography>
      ) : (
        <TextSpan size="micro">{emptyLongDash}</TextSpan>
      )}
    </TextEllipsisWithTooltip>
  </>
);

const ClickData = ({ data = {} }) => (
  <>
    <TextEllipsisWithTooltip>
      <TextSpan size="micro">{data?.targetType || ''}:</TextSpan>&nbsp;
      <TextSpan size="micro">{data?.targetText || ''}</TextSpan>
    </TextEllipsisWithTooltip>
  </>
);

const CustomData = ({ data = {} }) => {
  const customData = useMemo(
    () =>
      Object.entries(data?.data || {}).filter(
        ([key]) => !key.includes(LEAD_SOURCE_PREFIX)
      ),
    [data]
  );

  return (
    <>
      <TextEllipsisWithTooltip>
        <TextSpan size="micro">
          {typeof data?.Display?.interactionSubtype === 'string'
            ? data.Display.interactionSubtype
            : JSON.stringify(data?.Display?.interactionSubtype || '')}
        </TextSpan>
      </TextEllipsisWithTooltip>
      {customData.map(([key, value]) => (
        <React.Fragment key={key}>
          <Heading text={key} />
          <TextEllipsisWithTooltip>
            <TextSpan size="micro">{value || emptyLongDash}</TextSpan>
          </TextEllipsisWithTooltip>
        </React.Fragment>
      ))}
    </>
  );
};

const LeadSourceDataBlock = ({ event }) => {
  switch (event.typeName) {
    case eventTypes.impressionInteraction:
    case eventTypes.siteentryImpressionInteraction: {
      return <URLData data={event.data?.data} />;
    }
    case eventTypes.clickInteraction: {
      return <ClickData data={event.data?.data} />;
    }
    case eventTypes.customInteraction: {
      return <CustomData data={event.data} />;
    }
    default:
      return null;
  }
};

const getIconByType = ({ interactionType, interactionSubtype }) => {
  return (
    iconsByType[interactionType]?.[interactionSubtype || DEFAULT_SUBTYPE] ||
    DEFAULT_ICON
  );
};

const getInteractionName = ({ data }) => {
  const type = data?.Display?.interactionType;
  const subtype =
    typeof data?.Display?.interactionSubtype === 'string'
      ? data.Display.interactionSubtype
      : '';
  return data?.interactionType === 30
    ? type
    : [...new Set([subtype, type])].join(' ');
};

const InteractionEventBlock = ({ event, ...others }) => {
  const { t } = useTranslation();
  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsPrimary.orange.dark}
          icon={getIconByType(event.data)}
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <CardHeader
          event={event}
          name={getInteractionName(event)}
          config={cardHeaderConfig}
        />
      }
      event={event}
      {...others}
    >
      <Heading text={dataHeadings(t)[event.typeName]} />
      <LeadSourceDataBlock event={event} />
    </BasicBlock>
  );
};

InteractionEventBlock.propTypes = {
  event: PropTypes.shape({
    data: PropTypes.object.isRequired,
    created: PropTypes.string,
    comments: PropTypes.any,
  }).isRequired,
};

export default InteractionEventBlock;
