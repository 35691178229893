import { forwardRef, useCallback, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { hideScrollbarCss } from 'app/spacing';
import { fontSizes, Link } from 'app/typography';
import { grayScale } from 'app/colors';
import { components } from 'react-select';
import MainSelector from 'components/Kizen/MainSelector';
import Button from 'components/Button';
import useCan from 'hooks/useCan';
import { MenuList as BaseMenuList } from 'components/Kizen/Menu';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import { applyRef } from 'components/Inputs/props';
import useSyncScroll from 'react-use-sync-scroll';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import css from '@emotion/css';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';
import {
  GO_BACK_KEYS,
  CUSTOMIZE_FIELDS_STEP_KEY,
} from 'components/Wizards/CustomObject/constants';

const StyledScrollBarDetached = styled(ScrollBarDetached)`
  position: absolute;
  top: 0;
  right: 0;
  ${({ height }) => css`
    height: ${height}px;
  `}
  ${({ top }) => css`
    top: ${top}px;
  `}
`;

const StyledMainSelector = styled(MainSelector)`
  .menu-footer {
    border-top: 1px solid ${grayScale.medium};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    > .apply-button {
      height: unset;
      font-size: ${fontSizes.small};
      line-height: 1;
    }
  }
`;
export const Content = styled.div`
  & > div:first-of-type {
    max-height: 36rem;
  }
`;
export const SmallContent = styled.div`
  max-height: 192px;
  overflow-y: auto;
  ${hideScrollbarCss}
`;
const LinkOption = styled(Link)`
  display: block;
  padding: 0 12px;
  height: 32px;
  line-height: 32px;
  &,
  &:hover {
    text-decoration: none;
  }
`;

// TODO (keegandonley) - This is copied from the styles for the  category selector to ensure we can use the portalled
// version of the menu. Eventually, once we use the new RLB for clients too, we should delete the
// old styles and have them live here, without a conditional check.
const StyledMenuFooter = styled.div`
  ${({ usesPortal }) =>
    usesPortal
      ? css`
          min-width: 150px;
          border-top: 1px solid ${grayScale.medium};
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 32px;
          padding-left: 12px;
          padding-right: 12px;
          > .apply-button {
            height: unset;
            font-size: ${fontSizes.small};
            line-height: 1;
          }
        `
      : ''}
`;

const useFieldSettingsLink = (objectId) => {
  const clientObject = useSelector(getBusinessClientObject);

  const settingsAccess = useCan({
    view: 'sections',
    for: 'settings_section',
  });

  const objectCustomizationFieldsAccess = useCan(
    objectId
      ? {
          edit: 'custom_objects',
          for: `object_settings[${objectId}].customize_fields`,
        }
      : { edit: 'contacts', for: 'object_settings.customize_fields' }
  );

  return (
    objectCustomizationFieldsAccess &&
    settingsAccess &&
    `/custom-objects/${objectId || clientObject.id}/settings?from=${
      objectId ? `/custom-objects/${objectId}` : '/clients'
    }&fromKey=${
      objectId ? GO_BACK_KEYS.OVERVIEW : GO_BACK_KEYS.CONTACTS
    }&focusStepKey=${CUSTOMIZE_FIELDS_STEP_KEY}`
  );
};

const Menu = forwardRef(({ children, ...props }, ref) => {
  const { t } = useTranslation();
  const { menuPortalTarget } = props.selectProps;

  const link = useFieldSettingsLink(props.selectProps.objectId);

  return (
    <>
      <components.Menu {...props}>
        <Content>{children}</Content>
        {link ? (
          <StyledMenuFooter
            usesPortal={Boolean(menuPortalTarget)}
            className="menu-footer"
          >
            <Button
              as={Link}
              to={link}
              target="_blank"
              rel="noopener noreferrer"
              preventDefault={false}
              noSpace
              variant="text"
              className="apply-button"
              color="blue"
              data-qa="edit-categories-link"
            >
              {t('Edit Categories')}
            </Button>
          </StyledMenuFooter>
        ) : null}
      </components.Menu>
    </>
  );
});

const SmallMenuList = forwardRef(({ children, topButton, ...props }, ref) => {
  const { t } = useTranslation();

  const link = useFieldSettingsLink(props.selectProps.objectId);

  const [height, setHeight] = useState();
  const [top, setTop] = useState(0);

  // scrollbar
  const scrollbarContentWrapperRef = useRef();
  const [contentWrapperRefFn, contentWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncHeight',
    'height',
    [props],
    true
  );

  const contentRefFn = useCallback(
    (node) => {
      if (node) {
        if (node.previousElementSibling) {
          setTop(node.previousElementSibling.getBoundingClientRect().height);
        }
        setHeight(node.getBoundingClientRect().height);
      }
      contentWrapperRefFn(node);
    },
    [contentWrapperRefFn]
  );

  const mergeRef = useCallback(
    (el) => {
      applyRef(ref, el);
      applyRef(contentRefFn, el);
    },
    [ref, contentRefFn]
  );

  useSyncScroll(useRef([contentWrapperRef, scrollbarContentWrapperRef]), {
    vertical: true,
  });

  return (
    <>
      <SmallContent ref={mergeRef}>
        <div className="ContentForSyncHeight">
          {link ? (
            <LinkOption
              to={link}
              target="_blank"
              rel="noopener noreferrer"
              data-qa="edit-categories-link"
            >
              {t('Edit Categories')}
            </LinkOption>
          ) : null}
          {children}
        </div>
      </SmallContent>
      <StyledScrollBarDetached
        height={height}
        top={top}
        ref={scrollbarContentWrapperRef}
        direction="vertical"
        scrollClassName="ContentForSyncHeight"
      />
    </>
  );
});

export default function CategorySelect({ small = false, ...others }) {
  return (
    <StyledMainSelector
      {...others}
      components={
        small
          ? {
              ...others.components,
              MenuList: SmallMenuList,
            }
          : { ...others.components, Menu, MenuList: BaseMenuList }
      }
    />
  );
}
