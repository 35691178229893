import { dashboardRoot } from 'components/Navigation/config';
import { editToolbarPath } from 'pages/Account/paths';

export const DEFAULT_PATHS = {
  CHOOSE_BUSINESS: '/choose-business',
  CONTACTS: '/clients',
  CONTACT: '/client',
  AUTOMATIONS: '/automations',
  BROADCASTS: '/broadcasts',
  FORMS: '/forms',
  LIBRARY: '/library',
  ADS: '/ads',
  SURVEYS: '/surveys',
  SETTINGS: '/settings',
  DASHBOARD: '/dashboard',
  FREE_TRIAL: '/freetrial',
  CUSTOM_OBJECTS: '/custom-objects',
  MARKETING_COSTS: '/marketing-costs',
  ACTIVITIES: '/activities',
  ACCOUNT: '/account',
  PERMISSIONS_NEEDED: '/permissions-needed',
  HOMEPAGES: '/home',
  SMART_CONNECTORS: '/smartconnectors',
  MARKETPLACE: '/marketplace',
};

const customObjectSettings = 'customObjectSettings';
const contactChartGroupSettingsKey = 'contactChartGroupSettings';
const cutomObjectsChartGroupSettingsKey = 'customObjectsChartGroupSettings';
const customObjectsOverviewKey = 'customOverview';
const homePagesSettingsKey = 'homepagesSettings';
const contactsColumns = 'contactsColumns';
const customObjectsColumns = 'customObjectsColumns';
const contactsFilterGroups = 'contactsFilterGroups';
const customObjectsFilterGroups = 'customObjectsFilterGroups';
const customObjectsQuickFilters = 'customObjectsQuickFilters';
const recordDetail = 'recordDetail';
const compatRecordDetail = 'compatRecordDetail';
const contactRecordDetail = 'contactRecordDetail';

// These paths need to be moved first in the list when being rendered so they are matched
// early. This is because some paths are sub-paths of others, and we want to match the
// more specific path first.
export const priorityPaths = [
  contactRecordDetail,
  recordDetail,
  customObjectSettings,
  contactsColumns,
  customObjectsColumns,
  contactsFilterGroups,
  customObjectsFilterGroups,
  customObjectsQuickFilters,
  contactChartGroupSettingsKey,
  cutomObjectsChartGroupSettingsKey,
  customObjectsOverviewKey,
  homePagesSettingsKey,
];

const paths = new Map([
  ['login', '/login'],
  // ['newAccount', '/register'],
  ['verifyAccount', '/verify'],
  ['resetPassword', '/reset/:token?'],
  ['chooseBusiness', DEFAULT_PATHS.CHOOSE_BUSINESS],
  [contactChartGroupSettingsKey, `${DEFAULT_PATHS.CONTACTS}/charts/settings`],
  [contactsColumns, `${DEFAULT_PATHS.CONTACTS}/columns/:subpage?/:templateId?`],
  [contactsFilterGroups, `${DEFAULT_PATHS.CONTACTS}/filter-groups/:subpage?`],
  ['contacts', `${DEFAULT_PATHS.CONTACTS}/:subpage?/:chartId?`],
  ['contactOverview', `${DEFAULT_PATHS.CONTACT}/:id/:subpage`],
  [contactRecordDetail, `${DEFAULT_PATHS.CONTACT}/:id/details`],
  ['automations', DEFAULT_PATHS.AUTOMATIONS],
  ['broadcasts', DEFAULT_PATHS.BROADCASTS],
  ['newBusiness', '/setup-business-subscription'], // TODO: temporary path, change after v1 /setup-business is no longer used
  ['forms', DEFAULT_PATHS.FORMS],
  ['libraryBuild', `${DEFAULT_PATHS.LIBRARY}/templates/:templateId/:subpage?`],
  ['library', `${DEFAULT_PATHS.LIBRARY}/:subpage?`],
  ['formSettings', `${DEFAULT_PATHS.FORMS}/:id/:subpage?`],
  ['adManager', `${DEFAULT_PATHS.ADS}/:subpage?`],
  ['submitTicket', 'https://support.kizen.com'],
  ['helpDocs', 'https://support.kizen.com'],
  ['surveys', DEFAULT_PATHS.SURVEYS],
  ['surveySettings', `${DEFAULT_PATHS.SURVEYS}/:id/:subpage?`],
  ['settings', `${DEFAULT_PATHS.SETTINGS}/:subpage?/:section?`],
  ['styleGuide', '/styleguide'],
  ['dashboardSettings', `${DEFAULT_PATHS.DASHBOARD}/settings`],
  ['dashboard', `${DEFAULT_PATHS.DASHBOARD}/:dashboardId?`],
  ['freeTrialProcess', DEFAULT_PATHS.FREE_TRIAL],
  [
    customObjectsColumns,
    `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/columns/:subpage?/:templateId?`,
  ],
  [
    customObjectsFilterGroups,
    `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/filter-groups/:subpage?`,
  ],
  [
    customObjectsQuickFilters,
    `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/quick-filters/:subpage?/:templateId?`,
  ],
  [
    cutomObjectsChartGroupSettingsKey,
    `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/charts/settings`,
  ],
  ['customObjects', DEFAULT_PATHS.CUSTOM_OBJECTS],
  [
    customObjectsOverviewKey,
    // Multiple paths can point to the same page, though this should only be done when they differ only
    // by the last route segment, as the logic for verifying permissions will only be checked against
    // the first path in the array.
    [
      `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/:id/deprecated-details`,
      `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/:id/automations`,
      `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/:id/commerce`,
    ],
  ],
  [recordDetail, `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/:id/details`],
  [
    compatRecordDetail,
    `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/:id/details/preview`,
  ],
  ['clientOverviewKDS', `${DEFAULT_PATHS.CONTACT}/:id/details/kds`],
  [customObjectSettings, `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/settings`],
  [
    'customObjectRecords',
    `${DEFAULT_PATHS.CUSTOM_OBJECTS}/:objectId/:subpage?/:chartId?`,
  ],
  ['marketingCosts', DEFAULT_PATHS.MARKETING_COSTS],
  ['account', `${DEFAULT_PATHS.ACCOUNT}/:subpage?`],
  ['automationEngine', '/automation/:id'],
  ['notFound', '/not-found'],
  ['translation', '/translation'], // TODO this route should disappear after translation approach settles
  ['commentThreadUnsubscribe', '/comment-thread-unsubscribe/:id'],
  ['freeTrialEnded', '/free-trial-ended'],
  ['subscriptionManagement', '/manage-subscriptions/:contactId/:messageId'],
  ['activities', DEFAULT_PATHS.ACTIVITIES],
  ['activitySettings', `${DEFAULT_PATHS.ACTIVITIES}/:id/:subpage?`],
  ['downloadFile', '/file/:id/download'],
  ['logout', '/logout'],
  ['permissionsNeeded', DEFAULT_PATHS.PERMISSIONS_NEEDED],
  ['ssoLogin', '/sso'],
  editToolbarPath,
  ['homepages', `${DEFAULT_PATHS.HOMEPAGES}/:id?`],
  [homePagesSettingsKey, `${DEFAULT_PATHS.HOMEPAGES}/settings`],
  ['pluginCallback', '/plugins/callback'],
  ['fullPagePlugin', '/plugins/:appAPIName/:pageAPIName'],
  ['smartConnectors', DEFAULT_PATHS.SMART_CONNECTORS],
  ['smartConnectorSettings', `${DEFAULT_PATHS.SMART_CONNECTORS}/:id`],
  [
    'smartConnectorBuilder',
    `${DEFAULT_PATHS.SMART_CONNECTORS}/:id/builder/:subpage?/:customObjectId?/:loadId?`,
  ],
  ['loginRedirect', `/welcome`],
  ['marketplace', '/marketplace'],
]);

export const NOT_FOUND_PATH = paths.get('notFound');
export const DASHBOARD_PATH = dashboardRoot;

export default paths;
