import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashletShape } from 'utility/CommonPropTypes';
import { CHART_TYPES } from 'components/Wizards/shared/components/chart/types';
import {
  LEVEL_OF_DETAIL_OPTIONS,
  REPORT_TYPES,
} from 'components/Wizards/Dashlet/types';
import ScheduledActivities from 'components/Charts/ScheduledActivities';
import Summary from './Summary';
import SummaryHistorical from './SummaryHistorical';
import OpportunityConversion from './OpportunityConversion';
import TrendWithPercent from './TrendWithPercent';
import Leaderboard from './Leaderboard';
import { DonutDashletAdapter } from './Donut/adapters/DonutDashletAdapter';
import { BarDashletAdapter } from './Bar/adapters/BarDashletAdapter';
import AreaLineDashletAdapter from './AreaLine/adapters/AreaLineDashletAdapter';
import LeadsAddedBySourceAreaLineAdapter from './AreaLine/adapters/LeadsAddedBySourceAreaLineAdapter';
import TrendDashletAdapter from './Trend/adapters/TrendDashletAdapter';
import EmailInteractionStatsDashletAdapter from './EmailInteractionStats/adapters/DashletAdapter';
import { DonutChartGroupAdapter } from './Donut/adapters/DonutChartGroupAdapter';
import { BarChartGroupAdapter } from './Bar/adapters/BarChartGroupAdapter';
import { AREA_RESPONSES } from 'components/Wizards/RTDV/types';
import Table from './Table';
import TrendChartGroupAdapter from './Trend/adapters/TrendChartGroupAdapter';
import { HTMLBlock } from './HTML';
import { PivotTableChart } from './PivotTable';

const DataDashlet = ({ dashlet }) => {
  const { t } = useTranslation();

  const { layout, uuid } = dashlet;
  return (
    <>
      <span>
        {t('WIDTH')}: {layout.w} {t('HEIGHT')}: {layout.h}
      </span>
      <br />
      <span>
        {t('COL')}: {layout.x} {t('ROW')}: {layout.y}
      </span>
      <br />
      <span>
        {t('ID')}: {uuid}
      </span>
    </>
  );
};

DataDashlet.propTypes = {
  dashlet: DashletShape.isRequired,
};

const chartMap = {
  default: DataDashlet,
  [CHART_TYPES.SUMMARY]: Summary,
  [CHART_TYPES.TREND]: TrendDashletAdapter,
  [CHART_TYPES.DONUT]: DonutChartGroupAdapter,
  [CHART_TYPES.BAR]: BarChartGroupAdapter,
  html: HTMLBlock,
};

export const getChartComponent = (config) => {
  const { chartType, reportType, historical, pipelineLevelOfDetail } = config;
  if (reportType === AREA_RESPONSES.PIVOT_TABLE) {
    return PivotTableChart;
  }
  if (reportType === REPORT_TYPES.SCHEDULED_ACTIVITIES) {
    return ScheduledActivities;
  }
  if (chartType === CHART_TYPES.SUMMARY && historical) {
    return SummaryHistorical;
  }
  if (reportType === REPORT_TYPES.OPPORTUNITY_CONVERSION) {
    return OpportunityConversion;
  }
  if (reportType === REPORT_TYPES.VALUE_OVER_TIME) {
    return TrendWithPercent;
  }
  if (reportType === REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS) {
    return Leaderboard;
  }

  if (reportType === REPORT_TYPES.STAGES_OVER_TIME) {
    if (pipelineLevelOfDetail === LEVEL_OF_DETAIL_OPTIONS.STAGE_BREAKDOWN) {
      return AreaLineDashletAdapter;
    }
    return TrendWithPercent;
  }

  if (reportType === REPORT_TYPES.INTERACTION_STATS) {
    return EmailInteractionStatsDashletAdapter;
  }

  if (reportType === REPORT_TYPES.LEADS_ADDED_BY_SOURCE) {
    return (
      {
        [CHART_TYPES.DONUT]: DonutDashletAdapter,
        [CHART_TYPES.BAR]: BarDashletAdapter,
        [CHART_TYPES.TREND]: LeadsAddedBySourceAreaLineAdapter,
      }[chartType] || chartMap.default
    );
  }

  if (reportType === REPORT_TYPES.LEAD_SOURCE_BREAKDOWN) {
    return LeadsAddedBySourceAreaLineAdapter;
  }

  if (reportType === AREA_RESPONSES.TABLE) {
    return Table;
  }

  if (reportType === AREA_RESPONSES.SALES) {
    if (chartType === CHART_TYPES.TREND) {
      return TrendChartGroupAdapter;
    }
  }

  return chartMap[chartType] || chartMap.default;
};
