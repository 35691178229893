import * as Icons from 'components/GenericWizard/wizards/field/icons';

export const STAGE_STATUSES = {
  open: 'open',
  won: 'won',
  lost: 'lost',
  disqualified: 'disqualified',
};

export const FIELD_TYPES = {
  ActivityCustomField: {
    label: 'Activity Custom Field',
    type: 'activity_custom_field',
  },
  Checkbox: {
    label: 'Checkbox',
    localizedLabel: (t) => t('Checkbox'),
    type: 'checkbox',
    icon: Icons.checkbox,
    placeholder: 'Choose Option',
    localizedPlaceholder: (t) => t('Choose Option'),
  },
  Checkboxes: {
    label: 'Checkboxes',
    localizedLabel: (t) => t('Checkboxes'),
    type: 'checkboxes',
    icon: Icons.checkboxes,
    localizedPlaceholder: (t) => t('Find Options'),
  },
  Choices: {
    label: 'Choices',
    localizedLabel: (t) => t('Choices'),
    type: 'choices',
  },
  Date: {
    label: 'Date',
    localizedLabel: (t) => t('Date'),
    type: 'date',
    icon: Icons.date,
    placeholder: 'MM/DD/YYYY',
  },
  DateTime: {
    label: 'DateTime',
    localizedLabel: (t) => t('DateTime'),
    type: 'datetime',
    icon: Icons.date,
    placeholder: 'MM/DD/YYYY HH:MM AM/PM',
  },
  DealStageSelector: {
    label: 'Deal Stage Selector',
    localizedLabel: (t) => t('Deal Stage Selector'),
    type: 'deal_stage_selector',
  },
  Dropdown: {
    label: 'Dropdown',
    localizedLabel: (t) => t('Dropdown'),
    type: 'dropdown',
    icon: Icons.dropdown,
    placeholder: 'Choose Option',
    localizedPlaceholder: (t) => t('Choose Option'),
  },
  DynamicTags: {
    label: 'Dynamic Tags',
    localizedLabel: (t) => t('Dynamic Tags'),
    type: 'dynamictags',
    icon: Icons.dynamicTags,
    placeholder: 'Choose Tag',
  },
  Email: {
    label: 'Email Address',
    localizedLabel: (t) => t('Email Address'),
    type: 'email',
    icon: Icons.emailAddress,
    localizedPlaceholder: (t) => t('Email@Domain.com'),
  },
  Files: {
    label: 'Files',
    localizedLabel: (t) => t('Files'),
    type: 'files',
    icon: Icons.files,
  },
  LibraryMessage: {
    label: 'Library Message',
    localizedLabel: (t) => t('Library Message'),
    type: 'library_message',
    icon: Icons.files,
  },
  FileUploader: {
    label: 'File Uploader',
    localizedLabel: (t) => t('File Uploader'),
    type: 'file_uploader',
  },
  LongText: {
    label: 'Long Text',
    localizedLabel: (t) => t('Long Text'),
    type: 'longtext',
    icon: Icons.longText,
    localizedPlaceholder: (t) => t('Enter Text'),
  },
  Decimal: {
    label: 'Number (Decimal)',
    localizedLabel: (t) => t('Number (Decimal)'),
    type: 'decimal',
    icon: Icons.numberDecimal,
    placeholder: '0.00',
  },
  Integer: {
    label: 'Number (Whole)',
    localizedLabel: (t) => t('Number (Whole)'),
    type: 'integer',
    icon: Icons.numberWhole,
    placeholder: '0',
  },
  Number: {
    label: 'Number',
    localizedLabel: (t) => t('Number'),
    type: 'number',
  },
  PhoneNumber: {
    label: 'Phone Number',
    localizedLabel: (t) => t('Phone Number'),
    type: 'phonenumber',
    icon: Icons.phoneNumber,
    localizedPlaceholder: (t) => t('Phone Number'),
  },
  Money: {
    label: 'Price',
    localizedLabel: (t) => t('Price'),
    type: 'money',
    icon: Icons.price,
    placeholder: '0.00',
  },
  Phone: {
    label: 'Phone',
    localizedLabel: (t) => t('Phone'),
    type: 'phone',
  },
  Price: {
    label: 'Price',
    localizedLabel: (t) => t('Price'),
    type: 'price',
  },
  Radio: {
    label: 'Radio Buttons',
    localizedLabel: (t) => t('Radio Buttons'),
    type: 'radio',
    icon: Icons.radioButtons,
    placeholder: 'Choose Option',
    localizedPlaceholder: (t) => t('Choose Option'),
  },
  Rating: {
    label: 'Rating',
    localizedLabel: (t) => t('Rating'),
    type: 'rating',
    icon: Icons.rating,
    placeholder: 'Choose Value',
    localizedPlaceholder: (t) => t('Choose Rating'),
  },
  Relationship: {
    label: 'Relationship',
    localizedLabel: (t) => t('Relationship'),
    type: 'relationship',
    icon: Icons.relationship,
  },
  Status: {
    label: 'Status',
    localizedLabel: (t) => t('Status'),
    type: 'status',
    icon: Icons.status,
    placeholder: 'Choose Status',
    localizedPlaceholder: (t) => t('Choose Status'),
  },
  Selector: {
    label: 'Team Selector',
    localizedLabel: (t) => t('Team Selector'),
    type: 'selector',
    icon: Icons.teamSelector,
    placeholder: 'Find Team Member',
  },
  SurveyCheckbox: {
    label: 'Survey Checkbox',
    localizedLabel: (t) => t('Survey Checkbox'),
  },
  SurveyDropdown: {
    label: 'Survey Dropdown',
    localizedLabel: (t) => t('Survey Dropdown'),
    type: 'survey_dropdown',
  },
  SurveyNumber: {
    label: 'Survey Number',
    localizedLabel: (t) => t('Survey Number'),
    type: 'survey_number',
  },
  SurveyRating: {
    label: 'Survey Rating',
    localizedLabel: (t) => t('Survey Rating'),
    type: 'survey_rating',
  },
  SurveyTextArea: {
    label: 'Survey Textarea',
    localizedLabel: (t) => t('Survey Textarea'),
    type: 'survey_textarea',
  },
  SurveyTextBox: {
    label: 'Survey Text Box',
    localizedLabel: (t) => t('Survey Text Box'),
    type: 'survey_textbox',
  },
  SurveyYesNo: {
    label: 'Survey Yes No',
    localizedLabel: (t) => t('Survey Yes No'),
    type: 'survey_yes_no',
  },
  TextArea: {
    label: 'Textarea',
    localizedLabel: (t) => t('Textarea'),
    type: 'textarea',
  },
  Text: {
    label: 'Text',
    localizedLabel: (t) => t('Text'),
    type: 'text',
    icon: Icons.text,
    localizedPlaceholder: (t) => t('Enter Text'),
  },
  TextBox: {
    label: 'Text Box',
    localizedLabel: (t) => t('Text Box'),
    type: 'textbox',
  },
  YesNo: {
    label: 'Yes No',
    localizedLabel: (t) => t('Yes No'),
    type: 'yes_no',
  },
  YesNoMaybe: {
    label: 'Yes/No Question',
    localizedLabel: (t) => t('Yes/No Question'),
    type: 'yesnomaybe',
    icon: Icons.yesNoQuestion,
    placeholder: 'Choose Option',
    localizedPlaceholder: (t) => t('Choose Option'),
  },
  TeamSelector: {
    label: 'Team Selector',
    localizedLabel: (t) => t('Team Selector'),
    type: 'team_selector',
    icon: Icons.teamSelector,
    placeholder: 'Find Team Member',
    localizedPlaceholder: (t) => t('Find Team Member'),
  },
  Timezone: {
    label: 'Timezone',
    localizedLabel: (t) => t('Timezone'),
    type: 'timezone',
    placeholder: 'Choose Option',
    localizedPlaceholder: (t) => t('Choose Option'),
    localizedCommonPlaceholder: (t) => t('Choose Option'),
  },
};

export const DEFAULT_FIELD_NAMES = {
  name: 'name',
  owner: 'owner',
  entityValue: 'entity_value',
  percentageChanceToClose: 'percentage_chance_to_close',
  actualCloseDate: 'actual_close_date',
  estimatedCloseDate: 'estimated_close_date',
  stage: 'stage',
  reasonsLost: 'reasons_lost',
  reasonsDisqualified: 'reasons_disqualified',
  email: 'email',
  emailStatus: 'email_status',
  firstName: 'first_name',
  lastName: 'last_name',
  businessPhone: 'business_phone',
  homePhone: 'home_phone',
  mobilePhone: 'mobile_phone',
  birthday: 'birthday',
  timezone: 'timezone',
  tags: 'tags',
  titles: 'titles',
  created: 'created',
  updated: 'updated',
};

export const DROPDOWN_SHOULD_LOAD = Symbol('dropdown_should_load');

export const MESSAGE_TYPES = {
  text: (t) => t('Text'),
  email: (t) => t('Email'),
  integrated_inbox_email: (t) => t('IntegratedEmail'),
  folder: (t) => t('Folder'),
};

export const numericFieldTypes = [
  FIELD_TYPES.Decimal.type,
  FIELD_TYPES.Integer.type,
  FIELD_TYPES.Number.type,
  FIELD_TYPES.Price.type,
  FIELD_TYPES.Money.type,
];

export const dateFieldType = [FIELD_TYPES.Date.type, FIELD_TYPES.DateTime.type];

export const numericFieldTypeFilter = ({ fieldType = '' } = {}) => {
  return numericFieldTypes.includes(fieldType);
};

export const dateFieldTypeFilter = ({ fieldType = '' } = {}) => {
  return dateFieldType.includes(fieldType);
};
