import { useMemo, useRef, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import useAsync from 'react-use/lib/useAsync';
import { namedToOption } from 'services/helpers';
import useField from 'hooks/useField';

import TypeGroupInSvg from '../images/type-group-in.svg?react';
import TypeGroupNotInSvg from '../images/type-group-not-in.svg?react';
import TypeFiltersSvg from '../images/type-filters.svg?react';
import { createWizardState } from '../utilities';
import { useTranslation } from 'react-i18next';
import { FILTER_TYPES } from '../types';

import { useSelector } from 'react-redux';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { getFilterVariables } from 'ts-filters/utils';

import { useObjectGroupsQuery } from 'queries/models/object-groups';

const initialFilterElement = {
  and: null,
  query: [],
};

const areValuesValid = (values, highlightInvalid) => {
  switch (values.type) {
    case FILTER_TYPES.IN_GROUP:
    case FILTER_TYPES.NOT_IN_GROUP: {
      return highlightInvalid
        ? !!values.groups.length &&
            !values.groups.some(({ deleted, invalid }) => deleted || invalid)
        : !!values.groups.length;
    }
    default:
      return false;
  }
};

const getConditionTypeOptions = (t) => [
  {
    value: FILTER_TYPES.IN_GROUP,
    label: t('Is In Group(s)'),
    image: <TypeGroupInSvg />,
  },
  {
    value: FILTER_TYPES.NOT_IN_GROUP,
    label: t('Is Not In Group(s)'),
    image: <TypeGroupNotInSvg />,
  },
  {
    value: FILTER_TYPES.CUSTOM,
    label: t('Custom Filters'),
    image: <TypeFiltersSvg />,
  },
];
// we may have invalid or deleted filter groups that were previously selected. We will never receive them in get groups API call,
// so to be able to still show them as selected options and highligh invalid state use highlightInvalid prop
const useFilters = ({
  isForCustomObject = false,
  state,
  customObject,
  clientObject,
  highlightInvalid = false, // if true - will add invalid label to deleted or invalid filter groups and will leave them in a list if they were selected (otherwise groups that are not present in groupOptionsFromAPI will be omited)
}) => {
  // for dirty check
  const originalFilterState = useRef(null);
  if (!originalFilterState.current && state?.details) {
    originalFilterState.current = state.details;
  }
  const metaData = useSelector(selectFilterMetadataDefinition);
  const access = useSelector((s) => s.authentication.access);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [values, setValues] = useField(
    { type: undefined, groups: [], filter: { and: null, query: [] } },
    [state, isForCustomObject]
  );

  const processedGroups = useRef(false);

  const setFilter = useCallback(
    (filter) => setValues((prev) => ({ ...prev, filter })),
    [setValues]
  );

  const conditionTypeOptions = useMemo(() => getConditionTypeOptions(t), [t]);

  const { loading: wizardLoading } = useAsync(async () => {
    if (clientObject) {
      const filterVariablesObject = Object.fromEntries(
        getFilterVariables(isForCustomObject ? customObject : clientObject, {
          access,
          fieldsSettingsSearch: true,
          clientTagId: clientObject.undeletableFields.tags.id,
        })
      );

      // set up the filters etc
      const wizardState = await createWizardState(
        state,
        metaData,
        queryClient,
        filterVariablesObject
      );

      setValues((prev) => ({
        ...prev,
        ...wizardState,
      }));
    }
  }, [state, isForCustomObject, customObject, clientObject, metaData, access]);

  const groupOptionsId = useMemo(
    () => (isForCustomObject ? customObject?.id : clientObject?.id),
    [isForCustomObject, customObject, clientObject]
  );

  const { data: objectGroups, isLoading: isLoadingObjectGroups } =
    useObjectGroupsQuery(groupOptionsId, isForCustomObject, {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    });

  useEffect(() => {
    if (
      !processedGroups.current &&
      groupOptionsId &&
      objectGroups &&
      !isLoadingObjectGroups &&
      values?.groups?.length
    ) {
      processedGroups.current = true;

      setValues((prev) => ({
        ...prev,
        groups: prev.groups.reduce((collect, g) => {
          const group = objectGroups.find(
            ({ id }) => id === g.id && (!highlightInvalid || !g.invalid)
          );
          return group
            ? [...collect, group]
            : highlightInvalid && (g.deleted || g.invalid) // leave invalid or deleted groups to be shown if they were previously selected
              ? [
                  ...collect,
                  {
                    ...g,
                    name:
                      g.name + ` [${g.deleted ? t('Deleted') : t('Invalid')}]`,
                  },
                ]
              : collect;
        }, []),
      }));
    }
  }, [
    objectGroups,
    isLoadingObjectGroups,
    groupOptionsId,
    highlightInvalid,
    setValues,
    values.groups,
    t,
  ]);

  const groupOptions = useMemo(() => {
    if (isLoadingObjectGroups || !objectGroups) {
      return DROPDOWN_SHOULD_LOAD;
    }

    const groupOptionsFromAPI = objectGroups
      .filter(
        // remove groups that are considered to be invalid (deleted ones will not be present in response)
        // so not valid groups will only be present in values.groups untill unselected and will never be selectable
        ({ id }) =>
          !highlightInvalid ||
          !values.groups.find((g) => g.id === id && g.invalid)
      )
      .map((group) => ({
        ...namedToOption(group),
        group,
      }));

    if (!highlightInvalid) {
      return groupOptionsFromAPI;
    }
    const selectedInvalid = values.groups
      .filter(({ deleted, invalid }) => deleted || invalid)
      .map((group) => ({
        ...namedToOption(group),
        error: group?.deleted,
        group,
      }));
    if (!selectedInvalid.length) {
      return groupOptionsFromAPI;
    }
    return [...selectedInvalid, ...groupOptionsFromAPI];
  }, [values.groups, isLoadingObjectGroups, objectGroups, highlightInvalid]);

  const valid = useMemo(
    () => areValuesValid(values, highlightInvalid),
    [values, highlightInvalid]
  );

  return {
    setValues,
    setFilter,
    conditionTypeOptions,
    initialFilterElement,
    groupOptions,
    loading: isLoadingObjectGroups || wizardLoading,
    valid,
    values,
    filter: values.filter,
    isForCustomObject,
    customObject,
    originalFilterState: originalFilterState.current,
  };
};

export default useFilters;
