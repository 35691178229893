import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditor } from '@craftjs/core';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { created } from 'store/pageBuilder/pageBuilder.redux';
import { useAddNewNode } from '../useAddNewNode';
import { DEFAULT_ATTACHMENTS_PROPS } from './props';
import { FileLibrary } from '../../components/FileLibrary';

const CreateAttachments = ({ defaultProps }) => {
  const addNewNode = useAddNewNode();
  const dispatch = useDispatch();
  const { actions } = useEditor();
  const attachmentsItem = useSelector((s) =>
    getCreateItemByType(s, 'Attachments')
  );

  const onHide = useCallback(() => {
    actions.setIndicator(null);
    dispatch(created(false));
  }, [actions, dispatch]);

  const onConfirm = useCallback(
    async (attachments) => {
      addNewNode({
        ...attachmentsItem,
        ...defaultProps,
        attachments: attachments.map((attachment) => {
          const url = new URL(attachment.url);
          url.searchParams.set('disposition', 'attachment');
          return {
            ...attachment,
            url: url.toString(),
          };
        }),
      });
    },
    [attachmentsItem, addNewNode, defaultProps]
  );

  return (
    <FileLibrary
      onConfirm={onConfirm}
      onHide={onHide}
      show={!!attachmentsItem}
      onlyImages={false}
    />
  );
};

export const AttachmentsCreator = React.createElement(CreateAttachments, {
  defaultProps: DEFAULT_ATTACHMENTS_PROPS,
});
