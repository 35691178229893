import { useMemo } from 'react';
import { useAsync } from 'react-use';
import { fontFamilyOptions } from '../options';

async function loadGoogleFontOptions() {
  const googleFontsModule = await import('app/googleFontsOptions.js');
  return [
    {
      label: 'Google Fonts',
      options: googleFontsModule.default,
    },
  ];
}

export const useFonts = (enableGoogleFonts = false) => {
  const { value: fontOptions = [] } = useAsync(async () => {
    if (!enableGoogleFonts) {
      return fontFamilyOptions[0].options;
    }
    const googleFontOptions = await loadGoogleFontOptions();
    return [...fontFamilyOptions, ...googleFontOptions];
  }, []);
  return fontOptions;
};

export const useFontOptionLookup = (fonts = []) =>
  useMemo(() => {
    const all = fonts[0]?.options
      ? fonts.flatMap(({ options }) => options)
      : fonts;
    return all.reduce((acc, opt) => {
      acc[opt.value] = opt;
      return acc;
    }, {});
  }, [fonts]);
