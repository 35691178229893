import React from 'react';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import ButtonGroupButton from './Button';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import { optionProp, selectedValueProp } from '../props';

const ButtonLayout = styled.div`
  display: flex;

  &.wizard-button-group {
    flex-wrap: wrap;
    ${({ columns, gutter }) =>
      columns
        ? css`
            row-gap: ${gutter};
          `
        : ''}
  }

  & > * {
    flex: 1;
    flex-basis: 50px;
    min-width: 25px;
  }

  &.wizard-button-group > * {
    ${({ columns, gutter }) =>
      columns
        ? css`
            max-width: calc(${100 / columns}% - ${gutter});
            min-width: calc(${100 / columns}% - ${gutter});
          `
        : ''}
  }

  ${({ gutter }) => css`
    margin: 0 calc(-${gutter} / 2);
    & > * {
      margin: 0 calc(${gutter} / 2);
    }
  `}
`;

export default function ButtonGroup(props) {
  const {
    value: valueProp,
    options,
    onChange,
    error,
    disabled,
    button,
    gutter,
    className,
    columns,
    mode,
  } = props;
  const value =
    typeof valueProp === 'string'
      ? options.find((opt) => opt.value === valueProp) || null
      : valueProp;
  return (
    <MaybeInputControl {...props}>
      <ButtonLayout
        gutter={gutter}
        className={`${!hasInputControl(props) && className} ${
          mode === 'wizard' ? 'wizard-button-group' : ''
        }`}
        columns={columns}
        data-qa-value={value?.value}
      >
        {options.map((opt) =>
          React.cloneElement(button, {
            key: opt.value,
            option: opt,
            selected: Boolean(value && value.value === opt.value),
            error,
            disabled,
            onClick: (ev) => {
              if (onChange) onChange(opt, ev);
            },
          })
        )}
      </ButtonLayout>
    </MaybeInputControl>
  );
}

ButtonGroup.propTypes = {
  value: selectedValueProp,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(optionProp).isRequired,
  button: PropTypes.element,
  gutter: PropTypes.string,
  columns: PropTypes.number,
  mode: PropTypes.oneOf(['default', 'wizard']),
};

ButtonGroup.defaultProps = {
  value: null,
  onChange: null,
  error: null,
  disabled: null,
  button: <ButtonGroupButton />,
  gutter: `${gutters.spacing(2)}px`,
  mode: 'default',
};
