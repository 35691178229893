import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { TextSpan, fontWeights, fontSizes, truncateCss } from 'app/typography';
import { gutters, layers } from 'app/spacing';
import { colorsText, grayScale, colorsButton } from 'app/colors';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFieldsetLabel = styled(TextSpan)`
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.small};
  // Put the label exactly 10px above the checkbox. Equivalent to 2px
  margin-bottom: ${gutters.spacing(0, { baseline: fontSizes.small })}px;
  max-width: 100%;
  ${truncateCss}
  ${({ standaloneLabel }) =>
    standaloneLabel &&
    css`
      margin-bottom: 8px;
      margin-top: 5px;
    `}
`;

export const StyledFieldsetOutlineLabel = styled(TextSpan)`
  color: ${colorsText.dark};
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

// when displaying a single checkbox, we want the label to appear as if it were a fieldset label
// While also being able to toggle the checkbox on interaction
const standaloneLabelStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 8px;
`;

export const StyledLabel = styled.label`
  display: inline-flex;
  align-items: center;
  margin: ${({ size }) => (size === 'small' ? '10px 10px 10px 0' : '12px')};

  user-select: none;
  overflow: hidden;
  position: relative;

  ${({ standaloneLabel }) => standaloneLabel && standaloneLabelStyles}
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: ${layers.content(0)};
`;

export const StyledInputFacade = styled.span`
  position: relative;
  width: ${({ size, underline }) =>
    size === 'small' && underline ? '14px' : '16px'};
  height: ${({ size, underline }) =>
    size === 'small' && underline ? '14px' : '16px'};
  border: 1px solid ${grayScale.mediumDark};
  border-radius: ${({ size, underline }) =>
    size === 'small' && underline ? '1px' : '3px'};

  ${StyledLabel}:hover & {
    border-color: ${(props) =>
      props.error ? colorsButton.red.hover : colorsButton.blue.hover};
  }

  ${StyledInput}:checked~& {
    background: ${colorsButton.blue.hover};
    border-color: ${colorsButton.blue.hover};

    &::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border-style: solid;
      border-color: ${grayScale.white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      ${({ size, underline }) =>
        size === 'small' &&
        underline &&
        css`
          left: 4px;
          top: 0px;
          transform: rotate(45deg) scale(0.875);
        `}
    }
  }

  ${(props) =>
    props.indeterminate &&
    css`
      ${StyledInput}~& {
        background: ${colorsButton.blue.hover};
        border-color: ${colorsButton.blue.hover} !important;

        &::after {
          content: '';
          position: absolute;
          left: 6px;
          top: 2px;
          width: 0px;
          height: 10px;
          border-style: solid;
          border-color: ${grayScale.white};
          border-width: 0 2px 0 0;
          transform: rotate(90deg);
        }
      }
      ${StyledInput}~& {
        background: ${colorsButton.blue.hover};
        border-color: ${colorsButton.blue.hover};

        &::after {
          content: '';
          position: absolute;
          left: 6px;
          top: 2px;
          width: 0px;
          height: 10px;
          border-style: solid;
          border-color: ${grayScale.white};
          border-width: 0 2px 0 0;
          transform: rotate(90deg);
        }
      }
    `}

  ${StyledInput}:disabled~& {
    border-color: ${grayScale.medium};
    background-color: ${grayScale.light};
    &::after {
      border-color: ${grayScale.mediumDark};
    }
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}
`;

const shrinkStyles = css`
  // scaling to 78.5% will take the 14px down to the 11px we want
  // The 4px will put the label 10px above the capline of the input text
  transform: translate(0, 4px) scale(0.785);
  position: absolute;
  transform-origin: top left;
`;

export const StyledText = styled(TextSpan)`
  margin-left: 10px;
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
  ${({ shrink }) => shrink && shrinkStyles};

  // Truncate long labels.
  max-width: 170px;
  flex: 1; // When too wide, don't shrink checkbox itself
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
