import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { toastVariant, useToast } from 'components/ToastProvider';
import { buildFallbackQueryKey } from './util';

const useTimelineComment = ({
  onComment,
  id,
  size = 20,
  filters,
  params,
  searchTerm,
  queryKey: queryKeyOverride,
}) => {
  const queryClient = useQueryClient();
  const [showToast] = useToast();
  const { t } = useTranslation();

  // Do not change this query key structure without also changing it in useTimelineQuery
  const queryKey =
    queryKeyOverride ||
    buildFallbackQueryKey({
      id,
      searchTerm,
      size,
      filters,
      params,
    });

  const mutateOne = useMutation(onComment, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const previousTimeline = queryClient.getQueryData(queryKey);

      // Return a context with the previousTimeline
      return { previousTimeline };
    },

    // If the mutation fails, use the context we returned above
    onError: (_err, _newData, context) => {
      showToast({
        message: t('Failed to save comment'),
        variant: toastVariant.FAILURE,
      });
      queryClient.setQueryData(queryKey, context.previousTimeline);
    },

    onSuccess: (comments, _vars, context) => {
      const { previousTimeline } = context;
      const pages = previousTimeline.pages.map((page) => {
        const items = page.items.map((item) =>
          item.id === comments.id ? comments : item
        );
        return { ...page, items };
      });
      const next = { ...previousTimeline, pages };

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, next);
    },

    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const handleComment = useCallback(
    async (event, newComment) => {
      mutateOne.mutate({ event, newComment });
    },
    [mutateOne]
  );

  return {
    handleComment,
    mutateOne,
  };
};

export default useTimelineComment;
