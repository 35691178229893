import { useCallback, useRef, useState } from 'react';

/**
 * Hook to control the progress bar of the FileInput modal during the upload process.
 *
 * @returns 2-tuple - [progress, { clearProgress, initializeProgress, updateProgress }]
 */
export const useProgress = () => {
  const ref = useRef();
  const [progress, setProgress] = useState();

  const updateProgress = useCallback(
    ({ id, progress: newProgress }) => {
      ref.current = {
        ...ref.current,
        [id]: { progress: newProgress },
      };
      setProgress(ref.current);
    },
    [setProgress]
  );

  const clearProgress = useCallback(() => {
    setProgress(null);
  }, [setProgress]);

  const initializeProgress = useCallback(
    (createFiles) => {
      setProgress(null);
      ref.current = createFiles.reduce((acc, obj) => {
        if (obj.$id) {
          acc[obj.$id] = { progress: 0, id: obj.$id };
        }
        return acc;
      }, {});
    },
    [setProgress]
  );

  return [progress, { clearProgress, initializeProgress, updateProgress }];
};
