import { createContext, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale, shadowLight } from 'app/colors';
import {
  ContentWidth,
  contentWidthCss,
} from 'components/Layout/PageContentWidth';
import { layers } from 'app/spacing';
import { useMeasure, useWindowScroll } from 'react-use';
import { useDebouncedPastScrollThreshold } from 'hooks/useDebouncedWindowScroll';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { ADD_RECORD_TAB_MAX_WIDTH } from 'components/Tables/Big/AddRecordTab';
import { TABLE_HEADER_HEIGHT } from 'components/Tables/Basic';
import { PAGE_BREADCRUMB_HEIGHT, PAGE_TOOLBAR_HEIGHT } from '../PageToolbar';
import { useNavBarState } from 'app/navBarController';

const HEADER_SCROLL_PLAY = 10; // The table header can scroll up a bit after sticking
const FILTERS_OPEN_ADJUSTMENT = 10;

export const Wrapper = styled.div`
  position: relative;
`;

export const ScrollCurtainWrapper = styled.div`
  position: sticky;
  top: ${({ filtersOpen }) =>
    filtersOpen
      ? css`calc(-${HEADER_SCROLL_PLAY}px - ${FILTERS_OPEN_ADJUSTMENT}px)`
      : css`-${HEADER_SCROLL_PLAY}px`};
  height: 0;
  width: 100%;
  z-index: ${layers.content(0, 1)};
  overflow: visible;
  display: none;
  @supports (position: sticky) {
    display: block;
  }
`;

export const ScrollCurtain = styled.div`
  width: 100%;
  height: ${({ filtersOpen }) =>
    filtersOpen
      ? css`calc(${PAGE_TOOLBAR_HEIGHT}px + ${TABLE_HEADER_HEIGHT}px + ${FILTERS_OPEN_ADJUSTMENT}px)`
      : css`calc(${PAGE_TOOLBAR_HEIGHT}px + ${TABLE_HEADER_HEIGHT}px)`};
  box-sizing: content-box; // So border pokes out from under...
  background-clip: padding-box; // ...but the background does not display when transparent
  background-color: rgba(
    255,
    255,
    255,
    0
  ); // Needs to display on top of filters toolbar
  border-bottom: 1px solid transparent;
  transition: 0.2s ease-in-out;
  transition-property: box-shadow, background-color, border;
  ${({ scrolled }) =>
    scrolled
      ? css`
          ${shadowLight};
          background-color: ${grayScale.white};
          border-color: ${grayScale.mediumLight};
        `
      : null};
  ${({ scrolled, filtersOpen }) =>
    !scrolled && filtersOpen
      ? css`
          pointer-events: none;
        `
      : null};
`;

const PageSizing = styled.div`
  padding: ${({ recordTabSpacing }) =>
    recordTabSpacing
      ? css`0 calc(20px + ${ADD_RECORD_TAB_MAX_WIDTH});`
      : css`0 20px;`};
  display: flex;
  flex-direction: column;

  min-height: ${({ hideCurtainWrapper, additionalNavHeight = 0 }) =>
    hideCurtainWrapper
      ? `auto`
      : css`calc(100vh - ${({ navHeight }) =>
          navHeight}px - ${additionalNavHeight}px)`}; // Everything except header
`;

const ToolbarWrapper = styled.div`
  ${({ isSticky }) =>
    isSticky
      ? css`
          position: sticky;
          top: 0;
          z-index: ${layers.content(
            0,
            3
          )}; // Ensure contents incl menus appear on top of table header
        `
      : ''}
`;
const BreadcrumbStyles = styled(StylePassthrough)`
  ${contentWidthCss}
  height: ${PAGE_BREADCRUMB_HEIGHT}px;
  ${({ scrolled }) =>
    scrolled &&
    css`
      && > * {
        color: ${grayScale.white};
      }
    `};
`;

const ToolbarStyles = styled(StylePassthrough)`
  ${contentWidthCss}
  // We want the height to add up to PAGE_TOOLBAR_HEIGHT,
  // but we don't want the background to extend to the bottom of its height so
  // that the contact filters can appear correctly then safely scroll behind it.
  // So we replace some of the bottom with a margin. The margin is minimal so
  // that there is no gap for non-sticky content to scroll under.
  height: ${PAGE_TOOLBAR_HEIGHT - 6}px;
  margin-bottom: 6px;
  padding-top: 6px; // This doesn't affect height but offsets the margin to keep things centered
  transition: 0.2s ease-in-out;
  ${({ scrolled }) =>
    scrolled &&
    css`
      background-color: ${grayScale.white};
    `};
  transition-property: background-color;
`;

const PaginationWrapper = styled.div`
  margin-bottom: 50px;
`;

export default function BigTableLayout({
  breadcrumb,
  toolbar,
  stickyToolbar = true,
  filters = null,
  filtersOpen = null,
  quickFiltersPills,
  pagination = null,
  children: table,
  // Below provided by useBigTableLayout()
  scrolled,
  scrolledToTable,
  onCurtainTransitionEnd,
  recordTabSpacing = null,
  hideCurtainWrapper = false,
  additionalNavHeight,
  ...props
}) {
  const { height } = useNavBarState();

  return (
    <Wrapper {...props}>
      {!hideCurtainWrapper ? (
        <ScrollCurtainWrapper
          filtersOpen={filtersOpen}
          onTransitionEnd={onCurtainTransitionEnd}
        >
          <ScrollCurtain
            data-qa="scroll-curtain"
            scrolled={scrolled}
            filtersOpen={filtersOpen}
          />
        </ScrollCurtainWrapper>
      ) : null}
      <PageSizing
        recordTabSpacing={recordTabSpacing}
        hideCurtainWrapper={hideCurtainWrapper}
        additionalNavHeight={additionalNavHeight}
        navHeight={height}
      >
        <ToolbarWrapper isSticky={stickyToolbar}>
          {breadcrumb && (
            <BreadcrumbStyles scrolled={scrolled}>
              {breadcrumb}
            </BreadcrumbStyles>
          )}
          <ToolbarStyles scrolled={scrolled}>{toolbar}</ToolbarStyles>
        </ToolbarWrapper>
        {filters && <ContentWidth>{filters}</ContentWidth>}
        {quickFiltersPills && <ContentWidth>{quickFiltersPills}</ContentWidth>}
        {table}
        {pagination && (
          <PaginationWrapper as={ContentWidth}>{pagination}</PaginationWrapper>
        )}
      </PageSizing>
    </Wrapper>
  );
}

export function useBigTableLayout(heightDefault) {
  const { y } = useWindowScroll();
  const { height } = useNavBarState();
  const [filterDropdownRef, filterDropdown] = useMeasure();
  const scrolled = y > (heightDefault || height);

  const scrolledToTable =
    y >
    (heightDefault || height) +
      (filterDropdown.height ? filterDropdown.height + 10 : 0); // Include filterDropdown bottom margin
  const [scrollTransitioned, setScrollTransitioned] = useState(scrolled);
  useEffect(() => {
    setScrollTransitioned(false);
  }, [scrolled]);
  return {
    filterDropdownRef,
    scrollTransitioned,
    // Spread below into BigTableLayout
    scrolled,
    scrolledToTable,
    onCurtainTransitionEnd: () => {
      if (scrolled) {
        setScrollTransitioned(scrolled);
      }
    },
  };
}

export function useBigTableLayoutNew(heightDefault, thresholdDefault = null) {
  const { height } = useNavBarState();
  const [filterDropdownRef, filterDropdown] = useMeasure();
  const scrolled = useDebouncedPastScrollThreshold(thresholdDefault);

  const scrolledToTable = useDebouncedPastScrollThreshold(
    (heightDefault || height) +
      (filterDropdown.height ? filterDropdown.height + 10 : 0)
  ); // Include filterDropdown bottom margin
  const [scrollTransitioned, setScrollTransitioned] = useState(scrolled);
  useEffect(() => {
    setScrollTransitioned(false);
  }, [scrolled]);
  return {
    filterDropdownRef,
    scrollTransitioned,
    // Spread below into BigTableLayout
    scrolled,
    scrolledToTable,
    onCurtainTransitionEnd: () => {
      if (scrolled) {
        setScrollTransitioned(scrolled);
      }
    },
  };
}

// we are to refactor our Overview Pages to be more stable and performant in future
// for now we can at least encompass layout state produced by useBigTableLayoutNew hook
// in Provider and consume it only where needed

export const BigTableLayoutContext = createContext({});

export const BigTableLayoutProvider = ({
  children,
  heightDefault,
  thresholdDefault,
}) => {
  const context = useBigTableLayoutNew(heightDefault, thresholdDefault);
  return (
    <BigTableLayoutContext.Provider value={context}>
      {children}
    </BigTableLayoutContext.Provider>
  );
};

const ScrollCurtainWrapperWithLayoutConsumer = ({ show, filtersOpen }) => {
  const { scrolled, onCurtainTransitionEnd } = useContext(
    BigTableLayoutContext
  );
  return show ? (
    <ScrollCurtainWrapper
      filtersOpen={filtersOpen}
      onTransitionEnd={onCurtainTransitionEnd}
    >
      <ScrollCurtain
        data-qa="scroll-curtain"
        scrolled={scrolled}
        filtersOpen={filtersOpen}
      />
    </ScrollCurtainWrapper>
  ) : null;
};

const ToolbarWithLayoutConsumer = ({ isSticky, breadcrumb, toolbar }) => {
  const { scrolled } = useContext(BigTableLayoutContext);
  return (
    <ToolbarWrapper isSticky={isSticky}>
      {breadcrumb && (
        <BreadcrumbStyles scrolled={scrolled}>{breadcrumb}</BreadcrumbStyles>
      )}
      <ToolbarStyles scrolled={scrolled}>{toolbar}</ToolbarStyles>
    </ToolbarWrapper>
  );
};

export const BigTableLayoutWithProvider = ({
  breadcrumb,
  toolbar,
  stickyToolbar = true,
  filters = null,
  filtersOpen = null,
  quickFiltersPills,
  pagination = null,
  children: table,
  recordTabSpacing = null,
  hideCurtainWrapper = false,
  additionalNavHeight,
  ...props
}) => {
  const { height } = useNavBarState();
  return (
    <BigTableLayoutProvider {...props}>
      <Wrapper {...props}>
        <ScrollCurtainWrapperWithLayoutConsumer
          show={!hideCurtainWrapper}
          filtersOpen={filtersOpen}
        />
        <PageSizing
          recordTabSpacing={recordTabSpacing}
          hideCurtainWrapper={hideCurtainWrapper}
          additionalNavHeight={additionalNavHeight}
          navHeight={height}
        >
          <ToolbarWithLayoutConsumer
            isSticky={stickyToolbar}
            breadcrumb={breadcrumb}
            toolbar={toolbar}
          />
          {filters && <ContentWidth>{filters}</ContentWidth>}
          {quickFiltersPills && (
            <ContentWidth>{quickFiltersPills}</ContentWidth>
          )}
          {table}
          {pagination && (
            <PaginationWrapper as={ContentWidth}>
              {pagination}
            </PaginationWrapper>
          )}
        </PageSizing>
      </Wrapper>
    </BigTableLayoutProvider>
  );
};
