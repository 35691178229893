import styled from '@emotion/styled';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  > div {
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
    text-align: center;
  }
`;
