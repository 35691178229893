import React, { useCallback, useContext } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';
import ScheduledActivitiesReportType from './reportTypes/ScheduledActivities';
import NumberOfActivitySubmissionsReportType from './reportTypes/NumberOfActivitySubmissions';

const ActivitiesAreaDisplaySettings = ({
  defaultName = '',
  mobile = false,
  active,
}) => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const { area } = state;

  const handleSetName = useCallback(
    (value) => {
      return setState('name', value);
    },
    [setState]
  );

  // Hide self if area is not activities
  if (area !== AREA_RESPONSES.ACTIVITIES) {
    return null;
  }

  return (
    <>
      <ScheduledActivitiesReportType
        defaultName={defaultName}
        handleSetName={handleSetName}
      />
      <NumberOfActivitySubmissionsReportType
        defaultName={defaultName}
        handleSetName={handleSetName}
        mobile={mobile}
        active={active}
      />
    </>
  );
};

export default ActivitiesAreaDisplaySettings;
