import React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { gutters } from 'app/spacing';
import CheckboxMenu from 'pages/Common/components/CheckboxMenu';

const MENU_SPACE = 32;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${gutters.spacing(3)}px ${gutters.standard}
    ${gutters.spacing(2, -3)}px;
`;

const TitleInfo = styled.div`
  p + p {
    margin-top: ${gutters.spacing(1, 1)}px;
  }
  ${({ hasCheckboxData }) =>
    hasCheckboxData
      ? css`
          width: calc(100% - ${MENU_SPACE}px);
        `
      : css`
          width: 100%;
        `};
`;

const StyledCheckboxMenu = styled(CheckboxMenu)`
  margin-left: ${gutters.standard};
`;

const BaseColumnTitle = ({ checkBoxData, children, ...props }) => (
  <TitleWrapper {...props}>
    <TitleInfo hasCheckboxData={Boolean(checkBoxData)}>{children}</TitleInfo>
    {checkBoxData && (
      <StyledCheckboxMenu
        checked={checkBoxData.isSelected}
        options={checkBoxData.checkboxOptions}
        onChange={checkBoxData.onChangeSelection}
        zIndex={checkBoxData?.checkBoxProps?.zIndex}
      />
    )}
  </TitleWrapper>
);

BaseColumnTitle.propTypes = {
  checkBoxData: PropTypes.object,
};

BaseColumnTitle.defaultProps = {
  checkBoxData: null,
};

export default BaseColumnTitle;
