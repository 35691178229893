import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import { FlowBuilderContext } from '../context';
import debounce from 'lodash/debounce';
import { useReactFlow } from 'reactflow';
import { StepNode } from './nodes';
import { UploadNode, AdditionalVariableNode, TransformAddNode } from './nodes';
import { smartConnectorToNodes } from './layout';
import { useTranslateExtent } from '__pages/AutomationEngine/Engine/Flow/hooks';
import { ReactFlow } from '__pages/AutomationEngine/Engine/Flow/styles';
import { StyledFlowWrapper } from './styles';
import { BackgroundCanvas } from './BacgroundCanvas';
import { useScrollFlowWhilePlacing } from '../hooks/useScrollFlowWhilePlacing';
import { BasicEdge } from './edges';
import { Layouter } from './Layouter';

const MIN_ZOOM = 0.7;
const MAX_ZOOM = 1.3;

const nodesAndEdges = {
  nodeTypes: {
    connection_run_get_requests: StepNode,
    connection_intialize_additional_variables: StepNode,
    automationStep: StepNode,
    automationTrigger: StepNode,
    upload: UploadNode,
    additionalVariables: AdditionalVariableNode,
    transformAdd: TransformAddNode,
  },
  edgeTypes: {
    basic: BasicEdge,
  },
};

type StageNode = {
  id: string;
  position: { x: number; y: number };
};

export const Flow = () => {
  const { setInteracting, steps, triggers, connections, uploads } =
    useContext(FlowBuilderContext);

  const [nodes, edges] = useMemo(
    () =>
      smartConnectorToNodes({
        steps,
        triggers,
        connections,
        uploads,
      }),
    [steps, triggers, connections, uploads]
  );

  const [[stagedNodes, stagedEdges], setLayoutElements] = useState<
    [StageNode[], any[]]
  >([[], []]);

  const movingRef = useRef(false);
  const fitViewportRef = useRef(false);
  const { setViewport } = useReactFlow();

  const onMove = useCallback(() => {
    if (!movingRef.current) {
      // onMoveStart wouldn't reliably fire, so we track
      // moving state locally and check for changes.
      movingRef.current = true;
      setInteracting(true);
    }
  }, [setInteracting]);

  // Debounced to avoid triggering interacting off
  // very often while panning using trackpad, as
  // interacting causes re-renders of each step.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMoveEnd = useCallback(
    debounce(() => {
      movingRef.current = false;
      setInteracting(false);
    }, 250),
    [setInteracting]
  );

  const scroll = useScrollFlowWhilePlacing();
  const [translateExtent, scrollingX, scrollingY, viewportFit] =
    useTranslateExtent(MIN_ZOOM);

  useEffect(() => {
    if (!fitViewportRef.current && viewportFit && (scrollingX || scrollingY)) {
      fitViewportRef.current = true;

      setViewport(viewportFit);
    }
  }, [setViewport, viewportFit, scrollingX, scrollingY]);

  // we need to place connection steps vertically aligned, but we cannot do this with means of dagre layouter
  const stagedNodesAdjusted = useMemo(() => {
    const additionalVariablesNode = stagedNodes.find(({ id }) =>
      id.includes('intialize_additional_variables')
    );

    return stagedNodes.map((node) => {
      if (node.id.includes('run_get_requests')) {
        return {
          ...node,
          position: {
            x: additionalVariablesNode?.position.x || node.position.x,
            y: node.position.y,
          },
        };
      }

      if (node.id.includes('trigger')) {
        return {
          ...node,
          position: {
            x: node.position.x,
            y: additionalVariablesNode?.position.y || node.position.y,
          },
        };
      }

      return node;
    });
  }, [stagedNodes]);

  return (
    <>
      <StyledFlowWrapper>
        <BackgroundCanvas />
        <ReactFlow
          data-qa="react-flow"
          nodes={stagedNodesAdjusted}
          edges={stagedEdges}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          zoomOnScroll={false}
          panOnScroll={scrollingX || scrollingY}
          paneMoveable={scrollingX || scrollingY}
          translateExtent={translateExtent}
          nodesDraggable={false}
          selectNodesOnDrag={true}
          nodesConnectable={false}
          // This is important so that when you are dragging an element
          // and its placeholder scrolls off the page, the el doesn't disappear
          // mid-drag. When that happens it is actually very awkward
          // because you don't get a final onStop() call by react-draggable
          // (the el disappears so there's nothing to drop!)
          onlyRenderVisibleElements={false}
          onMove={onMove}
          onMoveEnd={onMoveEnd}
          {...nodesAndEdges}
          {...scroll}
        />
      </StyledFlowWrapper>
      <Layouter
        nodes={nodes}
        edges={edges}
        onLayout={setLayoutElements}
        {...nodesAndEdges}
      />
    </>
  );
};
