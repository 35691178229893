import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import WizardStateContext from '../context/wizardstate';
import { breakpoints, isMobile, useWindowSize } from 'app/spacing';

/*
 * The state controller takes wizard steps as childredn, and adds props to them
 * to represent their positining in the flow, and manages the active step.
 */
const StateController = ({ children, isEditing }) => {
  const { currentStep, state, homepage } = useContext(
    WizardStateContext.Context
  );
  const { width } = useWindowSize();

  const mobile = useMemo(() => {
    return isMobile(width, breakpoints.md);
  }, [width]);

  const childrenArray = useMemo(() => {
    return React.Children.toArray(children).filter((x) => x);
  }, [children]);

  const filteredChildren = useMemo(() => {
    return childrenArray.filter((child) => {
      const disabled = child.props.disabled?.(state, homepage) ?? false;
      if (disabled) {
        return false;
      }
      return true;
    });
  }, [childrenArray, state, homepage]);

  return filteredChildren.map((child, index) => {
    return React.cloneElement(child, {
      index,
      active: index === currentStep,
      done: currentStep > index,
      last: index === filteredChildren.length - 1,
      mobile,
      disabled: isEditing && index === 0,
    });
  });
};

StateController.propTypes = {
  children: PropTypes.any,
  activeStep: PropTypes.number,
};

export default StateController;
