import React, { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import useToggle from 'react-use/lib/useToggle';
import { breakpoints } from 'app/spacing';
import { KizenTypography } from 'app/typography';
import DateInput from 'components/Kizen/DateInput';
import Switch from 'components/Kizen/Switch';
import FileInput from 'components/Kizen/FileInput';
import Checkbox from 'components/Checkbox/Checkbox';
import { UnderlinedSelectList, UnderlinedTagList } from './MultiSelect';
import { RadioButtonGroup } from './RadioButton';
import Section from './helpers/Section';
import SubSection from './helpers/SubSection';
import Cols from './helpers/Cols';
import SingleSelect, { FilledSingleSelect } from './UnderlinedSingleSelect';
import { CondensedCheckboxGroup } from './Checkbox';

const StyledSubSection = styled(SubSection)`
  @media (min-width: ${breakpoints.lg}px) {
    margin-bottom: 30px;
    & > hr {
      display: none;
    }
  }
`;

const StyledInputLabel = styled(KizenTypography)`
  margin: 25px 0 25px;
  ${({ condensed }) =>
    condensed &&
    css`
      margin-bottom: 7px;
    `};
`;

export default function OtherInputsSample() {
  const [date, setDate] = useState(null);
  const [date2, setDate2] = useState('12/01/1989');
  const [checkbox1Value, toggleCheckbox1] = useToggle(false);
  const [checkbox2Value, toggleCheckbox2] = useToggle(true);
  const [switch2Value, toggleSwitch2] = useToggle(true);
  const [files, setFiles] = useState([]);

  return (
    <Section title="Other Inputs - Underline">
      <Cols columns={3} gutter="30px">
        <StyledSubSection title="Single Select">
          <StyledInputLabel weight="bold">Placeholder</StyledInputLabel>
          <SingleSelect />
          <StyledInputLabel weight="bold">With Content</StyledInputLabel>
          <FilledSingleSelect />
        </StyledSubSection>
        <StyledSubSection title="Multi Select">
          <UnderlinedSelectList />
        </StyledSubSection>
        <StyledSubSection title="Tags">
          <UnderlinedTagList />
        </StyledSubSection>
        <StyledSubSection title="Selector (Yes/No)">
          <StyledInputLabel condensed weight="bold">
            Off (No)
          </StyledInputLabel>
          <Switch
            textPlacement="top"
            label="Selector Name"
            value="selectorName"
          />
          <StyledInputLabel condensed weight="bold">
            On (Yes)
          </StyledInputLabel>
          <Switch
            textPlacement="top"
            checked={switch2Value}
            onChange={toggleSwitch2}
            label="Selector Name"
            value="selectorName"
          />
        </StyledSubSection>
        <StyledSubSection title="Radio Button">
          <RadioButtonGroup size="small" label="Radio Buttons Name" />
        </StyledSubSection>
        <StyledSubSection title="Checkbox">
          <StyledInputLabel condensed weight="bold">
            Unchecked
          </StyledInputLabel>
          <Checkbox
            label="Checkbox Name"
            value="default"
            checked={checkbox1Value}
            onChange={toggleCheckbox1}
            size="small"
          />
          <StyledInputLabel condensed weight="bold">
            Checked
          </StyledInputLabel>
          <Checkbox
            label="Checkbox Name"
            value="default"
            checked={checkbox2Value}
            onChange={toggleCheckbox2}
            size="small"
          />
        </StyledSubSection>
        <StyledSubSection title="Checkbox Group">
          <CondensedCheckboxGroup label="Checkboxes Name" />
        </StyledSubSection>
        <StyledSubSection title="File Input">
          <StyledInputLabel condensed weight="bold">
            No Files
          </StyledInputLabel>
          <FileInput
            label="File Input"
            files={files}
            onChange={(nextFiles) => setFiles(nextFiles)}
            variant="underlined"
          />
          <StyledInputLabel condensed weight="bold">
            With Files
          </StyledInputLabel>
          <FileInput
            label="File Input"
            files={['a', 'file', 'array']}
            onChange={(nextFiles) => setFiles(nextFiles)}
            variant="underlined"
          />
        </StyledSubSection>
        <StyledSubSection title="Single Date Picker">
          <StyledInputLabel condensed weight="bold">
            Placeholder
          </StyledInputLabel>
          <DateInput
            placeholder="MM/DD/YYYY"
            value={date}
            onChange={setDate}
            format="MM/DD/YYYY"
            label="Birthday"
            variant="underlined"
          />
          <StyledInputLabel condensed weight="bold">
            With Content
          </StyledInputLabel>
          <DateInput
            placeholder="MM/DD/YYYY"
            value={date2}
            onChange={setDate2}
            format="MM/DD/YYYY"
            label="Birthday"
            variant="underlined"
          />
        </StyledSubSection>
      </Cols>
    </Section>
  );
}
