import React, { useEffect, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';
import useAsync from 'react-use/lib/useAsync';
import useField from 'hooks/useField';
import { isPipelineObject } from 'components/Modals/utilities';
import FieldService from 'services/FieldService';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';

import Select from 'components/Inputs/Select';
import Input from 'components/Kizen/Input';
import { gutters } from 'app/spacing';
import { Text } from 'app/typography';
import { useTranslation } from 'react-i18next';
import InputControl from 'components/Inputs/InputControl';
import Switch from 'components/Kizen/Switch';
import KizenTypography from 'app/kizentypo';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

const Wrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

export const SwitchControl = styled(InputControl)`
  > label {
    line-height: 1.2;
    margin-top: ${gutters.spacing(0, -3)}px;
    margin-bottom: ${gutters.spacing(2)}px;
    & + * {
      margin: 0;
      label {
        // Incidentally part of <Switch />
        margin: 0;
      }
    }
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  top: ${gutters.spacing(0, 4)}px;
  padding-bottom: ${gutters.spacing(1, 1)}px;
  p + p {
    margin-top: 0;
  }
`;
const SuppressTitleWrapper = styled.div`
  padding-bottom: 8px;
`;

const SuppressStyledRow = styled(Row)`
  margin-bottom: 19px;
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ extraPadding }) =>
    extraPadding ? gutters.spacing(5) : gutters.spacing(4)}px !important;
  :last-child {
    margin-bottom: 0 !important;
    p {
      margin-bottom: 0;
    }
  }
`;
const StyledEntityRow = styled(Row)`
  margin-bottom: ${gutters.spacing(5, 4)}px;
`;
const objectToOption = (object) => ({
  value: object.id,
  label: 'objectName' in object ? object.objectName : object.name,
  name: object.name,
});

const categoryToOption = (category) => ({
  value: category.id,
  label: category.name,
});

const relationTypes = (t) => [
  {
    value: 'one_to_one',
    label: t('One-to-One'),
  },
  {
    value: 'primary_for',
    label: t('One-to-Many'),
  },
  {
    value: 'primary',
    label: t('Many-to-One'),
  },
  {
    value: 'additional',
    label: t('Many-to-Many'),
  },
  // just for supporting old cardinality,
  // only show in disabled dropdown
  {
    value: 'additional_for',
    label: t('Many-to-Many'),
  },
];
const EMPTY_ARRAY = [];

export default function RelationshipOptions({
  updateField,
  updateValidation,
  formData,
  data,
  ...others
}) {
  const { t } = useTranslation();
  const currentRelation = (formData && formData.relation) || {};
  const {
    id: relationId,
    relatedObject,
    relatedCategory,
    relatedName,
    relationType: relationTypeProp,
    rollupTimeline: rollupTimelineProp,
    inverseRelationRollupTimeline: inverseRelationRollupTimelineProp,
    inverseRelationSuppressed: inverseRelationSuppressedProp,
  } = currentRelation;

  const originInverseRelationSuppressedProp = useRef(
    inverseRelationSuppressedProp
  );
  const {
    model: { rollupRelatedLeadsources, objectName },
  } = data;

  const [relatedEntityId, setRelatedEntityId] = useField(relatedObject);
  const [relationType, setRelationType] = useField(
    relationTypeProp || 'additional'
  );
  const [categoryId, setCategoryId] = useField(relatedCategory);
  const [fieldName, setFieldName] = useField(relatedName);

  const [rollupTimeline, setRollupTimeline] = useField(rollupTimelineProp);
  const [inverseRelationSuppressed, setInverseRelationSuppressed] = useField(
    inverseRelationSuppressedProp
  );

  const [inverseRelationRollupTimeline, setInverseRelationRollupTimeline] =
    useField(inverseRelationRollupTimelineProp);

  const isValid = Boolean(relatedEntityId && fieldName?.trim() && categoryId);

  // Load objects on mount; account-specific, independent of formData,
  // shouldn't need to be reloaded
  const { loading, value: objects = EMPTY_ARRAY } = useAsync(async () => {
    return CustomObjectsService.getAllowedRelations();
  }, []);

  const selectedModel = objects.find((model) => model.id === relatedEntityId);

  const { value: categories = EMPTY_ARRAY } = useAsync(async () => {
    try {
      if (relatedEntityId) {
        if (selectedModel.name === 'client_client') {
          return await FieldService.getObjectCategories();
        }
        return isPipelineObject(selectedModel)
          ? await PipelineService.getObjectCategories(relatedEntityId)
          : await CustomObjectsService.getObjectCategories(relatedEntityId);
      }
      return [];
    } catch {
      return [];
    }
  }, [objects, relatedEntityId]);

  useEffect(() => {
    updateValidation(isValid);
  }, [isValid, updateValidation]);

  useEffect(() => {
    updateField('relation', {
      relatedObject: relatedEntityId,
      relatedCategory: categoryId,
      relatedName: fieldName,
      relationType: relationType,
      rollupTimeline: rollupTimeline,
      inverseRelationRollupTimeline: inverseRelationRollupTimeline,
      inverseRelationSuppressed: inverseRelationSuppressed,
      ...(data.field?.id
        ? { cardinality: data.field.relation?.cardinality }
        : { cardinality: 'many_to_many' }),
      ...(relationId && {
        id: relationId,
      }),
    });
  }, [
    relationId,
    rollupTimeline,
    inverseRelationRollupTimeline,
    relatedEntityId,
    categoryId,
    relationType,
    fieldName,
    updateField,
    data.field,
    inverseRelationSuppressed,
  ]);
  const isRelationSuppressed = useMemo(() => {
    return (
      inverseRelationSuppressed || originInverseRelationSuppressedProp.current
    );
  }, [inverseRelationSuppressed]);

  if (loading) {
    return null;
  }

  const selectedCategory = categories.find((cat) => cat.id === categoryId);
  return (
    <Wrapper data-qa="related_object_subsection">
      <TitleWrapper>
        <TextEllipsisWithTooltip weight="bold">
          {`${objectName}`}
        </TextEllipsisWithTooltip>
        &nbsp;
        <Text weight="bold">{t('Field')}</Text>
      </TitleWrapper>
      <StyledEntityRow>
        <Col>
          <InputControl
            label={t('Related Object')}
            {...getDataQAForInput('related_object', 'dropdown')}
          >
            <Select
              fullWidth
              placeholder={t('Choose Related Object')}
              value={selectedModel ? objectToOption(selectedModel) : null}
              options={objects.map(objectToOption)}
              onChange={(opt) => {
                setRelatedEntityId(opt.value);
                setCategoryId(null); // Reset category selection
              }}
              disabled={!!data.field}
            />
          </InputControl>
        </Col>
        <Col>
          {relatedEntityId ? (
            <InputControl
              label={t('Relationship Type')}
              {...getDataQAForInput('relationship_type', 'dropdown')}
            >
              <Select
                fullWidth
                value={relationType}
                options={
                  data.field ? relationTypes(t) : relationTypes(t).slice(0, -1)
                }
                onChange={(opt) => {
                  setRelationType(opt.value);
                }}
                disabled={!!data.field}
              />
            </InputControl>
          ) : null}
        </Col>
      </StyledEntityRow>
      {relatedEntityId && (
        <>
          <SuppressTitleWrapper>
            <Text weight="bold">{t('Related Field')}</Text>
          </SuppressTitleWrapper>
          <SuppressStyledRow>
            <Col>
              <SwitchControl
                label={t('Suppress Related Field')}
                labelInfo={t(
                  "When suppressed, the related object will not have a relationship field visible, nor will updates to this relationship appear in that object's record timelines. This is not reversible."
                )}
                {...getDataQAForInput('inverse_relation_suppressed')}
              >
                <Switch
                  checked={inverseRelationSuppressed}
                  onChange={(e) => {
                    setInverseRelationSuppressed(e.target.checked);
                    if (e.target.checked) {
                      setRollupTimeline(false);
                      setInverseRelationRollupTimeline(false);
                    }
                  }}
                  disabled={
                    originInverseRelationSuppressedProp.current ||
                    !!data.field?.isSuppressed
                  }
                />
              </SwitchControl>
            </Col>
          </SuppressStyledRow>
          <StyledRow>
            <Col>
              <InputControl label={t('Field Name')}>
                <Input
                  placeholder={t('Enter Field Name')}
                  value={fieldName}
                  onChange={setFieldName}
                  {...getDataQAForInput('related_field_name', 'text')}
                />
              </InputControl>
            </Col>
            <Col>
              <InputControl
                label={t('Category to Create Related Field')}
                {...getDataQAForInput('related_field_category', 'dropdown')}
              >
                <Select
                  fullWidth
                  placeholder={t('Choose Category')}
                  value={
                    selectedCategory ? categoryToOption(selectedCategory) : null
                  }
                  options={categories.map(categoryToOption)}
                  onChange={(opt) => setCategoryId(opt.value)}
                />
              </InputControl>
            </Col>
          </StyledRow>
          <StyledRow extraPadding>
            <Col>
              <SwitchControl
                label={t('Share Timeline To Related?')}
                labelInfo={t(
                  "Timeline entries will be shared from this object's record to the associated related object's record."
                )}
                {...getDataQAForInput('share_timeline_to_related')}
              >
                <Switch
                  checked={inverseRelationRollupTimeline}
                  disabled={isRelationSuppressed || !!data.field?.isSuppressed}
                  onChange={(e) =>
                    setInverseRelationRollupTimeline(e.target.checked)
                  }
                />
              </SwitchControl>
            </Col>
            <Col>
              <SwitchControl
                label={t('Share Timeline From Related?')}
                labelInfo={t(
                  "Timeline entries will be shared from the related object's record and displayed on associated records of this object."
                )}
                {...getDataQAForInput('share_timeline_from_related')}
              >
                <Switch
                  checked={rollupTimeline}
                  disabled={isRelationSuppressed || !!data.field?.isSuppressed}
                  onChange={(e) => setRollupTimeline(e.target.checked)}
                />
              </SwitchControl>
            </Col>
          </StyledRow>
          <StyledRow>
            <Col>
              {inverseRelationSuppressed ||
              originInverseRelationSuppressedProp.current ? (
                <KizenTypography data-qa="inverse_relation_suppressed_text">
                  {t('Note')}:{' '}
                  {t(
                    'Lead source sharing is disabled when the related field is suppressed.'
                  )}
                </KizenTypography>
              ) : (
                <KizenTypography data-qa="lead_source_sharing_text">
                  {t('Note')}: {t('Lead source sharing is turned')}{' '}
                  {
                    <KizenTypography size="micro" weight="bold" as="span">
                      {rollupRelatedLeadsources ? t('on') : t('off')}
                    </KizenTypography>
                  }{' '}
                  {t('for this object, so this relationship')}{' '}
                  {
                    <KizenTypography size="micro" weight="bold" as="span">
                      {rollupRelatedLeadsources ? t('will') : t('will not')}
                    </KizenTypography>
                  }{' '}
                  {t(' share lead sources between related entities.')}
                </KizenTypography>
              )}
            </Col>
          </StyledRow>
        </>
      )}
    </Wrapper>
  );
}

RelationshipOptions.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  formData: PropTypes.object,
  data: PropTypes.object,
};

RelationshipOptions.defaultProps = {
  formData: {},
  data: {},
};
