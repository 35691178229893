import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowBuilderContext } from '../context';
import { flushUiChangesHack } from '__pages/AutomationEngine/Engine/utilities';
import Handle from '__pages/AutomationEngine/Engine/Handle';
import { Button as ButtonKDS } from '@kizen/kds/Button';
import {
  ADD_BUTTON_HEIGHT,
  AddSmartConnectorButton,
  addTransformButtonOptionsTranslated,
  addUploadButtonOptionsTranslated,
} from 'pages/SmartConnectors/SmartConnector/SmartConnectorBuilder/FlowBuilder/Steps/AddTransformButtom';
import { FlowStep } from '../types';
import { defaultConnectionStep } from '__pages/AutomationEngine/steps';
import { useStore } from 'reactflow';
import ConfiguredStepCard from '__pages/AutomationEngine/Engine/Steps/ConfiguredStepCard';
import ErrorStepCard from '../Steps/ErrorStepCard';
import ErrorBoundaryResettable from 'components/Kizen/ErrorBoundaryResettable';
import { defaultConnectionGetRequestsStep } from '__pages/AutomationEngine/steps';
import { JunctionNode } from '__pages/AutomationEngine/Engine/Flow/nodes';

const transformTypes = ['action', 'condition'];

const isPlacingTransformStepAddNode = (
  placing: boolean,
  lastPlacingStep: FlowStep | null
) =>
  placing && lastPlacingStep && transformTypes.includes(lastPlacingStep?.type);

const isPlacingUploadStepAddNode = (
  placing: boolean,
  lastPlacingStep: FlowStep | null
) =>
  placing && lastPlacingStep && !transformTypes.includes(lastPlacingStep?.type);

type NodeProps = {
  id: string;
  data: any;
};

export const UploadNode = ({ id, data, ...props }: NodeProps) => {
  const { t } = useTranslation();

  const { placing, interacting, lastPlacingStep, dropStep } =
    useContext(FlowBuilderContext);

  const placingUploadStep = isPlacingUploadStepAddNode(
    placing,
    lastPlacingStep
  );

  const isPlacingAllowed =
    !placing ||
    !placingUploadStep ||
    (data.index !== lastPlacingStep?.order &&
      data.index - 1 !== lastPlacingStep?.order);

  const handleOnDrop = async () => {
    if (isPlacingAllowed) {
      // TODO this is abnoxious, but it is necessary so that
      // during layout after the drop that react-flow doesn't
      // measure the visible "Place Here" button before it
      // disappears. Essentially gives time for the button
      // to go back to its initial state then get measured.
      await flushUiChangesHack();
      dropStep({
        step: lastPlacingStep,
        ...data,
      });
    }
  };

  const handleSelectOption = ({ value }: { value: string }) => {
    dropStep({
      step: { type: value },
      ...data,
    });
  };

  return isPlacingAllowed ? (
    <AddSmartConnectorButton
      centered={false}
      placing={placingUploadStep}
      disabled={isPlacingTransformStepAddNode(placing, lastPlacingStep)}
      onDrop={handleOnDrop}
      showMenu={interacting ? false : undefined}
      options={addUploadButtonOptionsTranslated(t)}
      onSelectOption={handleSelectOption}
      dataQa="add-upload-step"
      iconName="cloud-upload"
      {...props}
      {...data}
    />
  ) : (
    <JunctionNode height={ADD_BUTTON_HEIGHT} />
  );
};

export const AdditionalVariableNode = () => {
  const { t } = useTranslation();
  const { setConnections, preRelease, dropStep } =
    useContext(FlowBuilderContext);
  return (
    <div>
      <ButtonKDS
        color="secondary"
        onClick={() => {
          setConnections(
            [
              defaultConnectionStep,
              preRelease && defaultConnectionGetRequestsStep,
            ].filter(Boolean)
          );
          dropStep({
            step: defaultConnectionStep,
            branch: false,
            leaf: false,
            label: '',
            id: '',
            parentKey: null,
          });
        }}
        rightIcon="action-show-variables"
        variant="standard"
      >
        {t('Additional Variables')}
      </ButtonKDS>
      <Handle
        id="handle-left"
        type="target"
        position="left"
        style={{ left: 0, opacity: 0 }}
      />
      <Handle
        id="handle-right"
        type="source"
        position="right"
        style={{ right: 0 }}
      />
    </div>
  );
};

export const TransformAddNode = ({ id, data, ...props }: NodeProps) => {
  const { t } = useTranslation();

  const { preRelease, placing, interacting, lastPlacingStep, dropStep } =
    useContext(FlowBuilderContext);

  return (
    <AddSmartConnectorButton
      centered={!data.leaf || data.branch}
      placing={isPlacingTransformStepAddNode(placing, lastPlacingStep)}
      disabled={
        !preRelease || isPlacingUploadStepAddNode(placing, lastPlacingStep)
      }
      onDrop={async () => {
        // TODO this is abnoxious, but it is necessary so that
        // during layout after the drop that react-flow doesn't
        // measure the visible "Place Here" button before it
        // disappears. Essentially gives time for the button
        // to go back to its initial state then get measured.
        await flushUiChangesHack();
        dropStep({
          step: lastPlacingStep,
          ...data,
        });
      }}
      showMenu={interacting ? false : undefined}
      options={preRelease ? addTransformButtonOptionsTranslated(t) : []}
      onSelectOption={({ value }: { value: string }) => {
        dropStep({
          step: { type: value },
          ...data,
        });
      }}
      dataQa="add-transform-step"
      iconName="plus"
      {...props}
      {...data}
    />
  );
};

type StepNodeProps = {
  type: string;
  data: any;
  disableMenu: boolean;
};

export const StepNode = ({
  type,
  data,
  disableMenu = false,
  ...props
}: StepNodeProps) => {
  const { placing, interacting, showStats, placeStep } =
    useContext(FlowBuilderContext);

  const zoom = useStore((state) => state.transform[2]);

  const handleOnStart = useCallback(() => {
    placeStep(data);
  }, [placeStep, data]);

  const handleOnStop = useCallback(() => placeStep(false), [placeStep]);

  const handleOnSelectMenu = useCallback(({ value }: { value: string }) => {
    if (value === 'delete') {
    }
  }, []);

  const ErrorCard = () => (
    <ErrorStepCard
      {...props}
      type={type}
      step={data}
      showMovingPlaceholder={false}
      disableMenu={disableMenu}
      showStats={showStats}
      showHandle={placing ? false : undefined}
      showMenu={interacting ? false : undefined}
      onSelectMenu={handleOnSelectMenu}
      // Drag-related props below, may not be used (yet) depending on the type
      scale={zoom}
      onStart={handleOnStart}
      onStop={handleOnStop}
    />
  );

  return (
    <ErrorBoundaryResettable FallbackComponent={ErrorCard}>
      <ConfiguredStepCard
        {...props}
        type={type}
        step={data}
        showMovingPlaceholder={false}
        showStats={showStats}
        showHandle={placing ? false : undefined}
        showMenu={interacting ? false : undefined}
        onSelectMenu={handleOnSelectMenu}
        // Drag-related props below, may not be used (yet) depending on the type
        scale={zoom}
        onStart={handleOnStart}
        onStop={handleOnStop}
      />
    </ErrorBoundaryResettable>
  );
};
