import styled from '@emotion/styled';
import { css } from '@emotion/core';
import AutosizeInput from 'react-input-autosize';
import { gutters } from 'app/spacing';
import { grayScale, colorsText } from 'app/colors';

export const editableTextPaddingNormal = gutters.spacing(1, 2);
export const editableTextPaddingDense = gutters.spacing(1);

export const StyledInput = styled(AutosizeInput)`
  & > input {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: -0.00125em;
    color: ${colorsText.dark};
    min-width: 40px;
    flex-grow: 1;
    outline: none;
    border: none;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    background-image: url(/images/inputUnderline.svg);
    background-position: left bottom;
    background-repeat: repeat-x;
    padding-bottom: ${editableTextPaddingNormal}px;

    ${({ sizing }) =>
      sizing === 'dense' &&
      css`
        padding: 0;
        padding-bottom: ${editableTextPaddingDense}px;
      `}

    // Naturally truncate if the container is sized
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover:not([disabled]) {
      background-image: url(/images/inputUnderline--hover.svg);

      ${(props) =>
        props.showLighter &&
        css`
          background-image: url(/images/inputUnderline.svg);
        `}
    }

    ${(props) =>
      props.showLighter &&
      css`
        color: ${grayScale.medium};
      `}

    ${(props) =>
      props.error &&
      css`
        &:not(:focus),
        &:hover:not([disabled]) {
          background-image: url(/images/inputUnderline--error.svg);
        }
      `}

    &:focus, &:focus:hover {
      background-image: url(/images/inputUnderline--solid.svg);

      ${(props) =>
        props.showLighter &&
        css`
          background-image: url(/images/inputUnderline.svg);
        `}
    }

    &[disabled] {
      background-color: ${grayScale.light};
      background-image: none;
    }

    &::placeholder {
      color: ${grayScale.mediumDark};

      &[disabled] {
        color: ${grayScale.medium};
      }
    }

    // prevent bug in Chrome.
    // When we have an empty input in the drop list and we drag an element over it
    ${(props) =>
      !props.value &&
      css`
        color: ${grayScale.mediumDark};
      `}
  }
`;
