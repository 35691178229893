import React from 'react';
import { mapValues } from 'lodash';
import { css, Global } from '@emotion/core';
import Color from 'color';

export const appBackground = '#F8FAFF';

// Primary Chart Colors
export const colorsPrimary = {
  blue: {
    dark: '#085bee',
    light: '#9CBDF8',
    sky: '#4090F7',
  },
  orange: {
    dark: '#f3a800',
    light: '#FADC99',
  },
  green: {
    dark: '#32c055',
    light: '#ADE6BB',
  },
  purple: {
    dark: '#4a21d1',
    light: '#B7A6ED',
  },
};

// Secondary Chart Colors
export const colorsSecondary = {
  tangerine: {
    dark: '#f55f04',
    light: '#FAAF81',
  },
  gray: {
    dark: '#7a7a7a',
    light: '#BCBCBC',
  },
  brown: {
    dark: '#ae764a',
    light: '#D6BAA4',
  },
  magenta: {
    dark: '#c132b1',
    light: '#E098D8',
  },
  aqua: {
    dark: '#38b3b8',
    light: '#9BD9DB',
  },
  red: {
    dark: '#dc1422',
    light: '#ED8990',
  },
};

// Kizen Colors
export const grayScale = {
  black: '#000000',
  dark: '#4a5660',
  mediumDark: '#a8b3bc',
  medium: '#d8dde1',
  mediumLight: '#eceef0',
  light: '#f5f6f7',
  white: '#ffffff',
  paleGray: '#f4f5f7',
};

export const colorsButton = {
  blue: {
    hover: '#216ef7',
    default: '#528ef9',
    focus: '#e9f4ff',
  },
  green: {
    hover: '#35aa98',
    default: '#4bc7b4',
  },
  red: {
    hover: '#fa4141',
    default: '#fb7373',
  },
  lightRed: {
    hover: '#dc1422',
    default: '#ED8990',
  },
  iconGray: {
    hover: grayScale.dark,
    default: grayScale.mediumDark,
  },
  // to show broken buttons in #F00
  brokenRed: {
    hover: '#dc1422',
    default: '#f00',
  },
  iconWhite: {
    hover: grayScale.white,
    default: grayScale.light,
  },
};

export const colorsText = {
  dark: grayScale.dark,
  medium: grayScale.mediumDark,
  soft: grayScale.medium,
  light: grayScale.mediumLight,
};

export const background = grayScale.light;
export const tableHover = appBackground;

export const Coloring = () => {
  return (
    <Global
      styles={css`
        :root {
          --blue: ${colorsPrimary.blue.dark};
          --indigo: unset;
          --purple: unset;
          --pink: unset;
          --red: ${colorsSecondary.red.dark};
          --orange: ${colorsPrimary.orange.dark};
          --yellow: unset;
          --green: ${colorsPrimary.green.dark};
          --teal: unset;
          --cyan: unset;
          --white: ${grayScale.white};
          --gray: ${grayScale.medium};
          --gray-dark: ${grayScale.mediumDark};
          --primary: ${colorsButton.green.default};
          --secondary: ${colorsButton.blue.default};
          --success: ${colorsButton.green.default};
          --info: ${colorsButton.blue.default};
          --warning: ${colorsButton.red.default};
          --danger: ${colorsButton.red.default};
          --light: ${grayScale.light};
          --dark: ${grayScale.dark};
        }
        body {
          background-color: ${appBackground};
        }
      `}
    />
  );
};

export const opaquePrimary = mapValues(colorsPrimary, (element) => ({
  dark: Color(element.dark).opaquer(0.6),
  light: Color(element.light).opaquer(0.6),
}));

export const opaqueSecondary = mapValues(colorsSecondary, (element) => ({
  dark: Color(element.dark).opaquer(0.6),
  light: Color(element.light).opaquer(0.6),
}));

export const shadow = css`
  box-shadow: 0 18px 26px rgba(0, 0, 0, 0.06);
`;

export const shadowLight = css`
  box-shadow: 0 18px 26px rgba(0, 0, 0, 0.03);
`;

export const shadowOverlay = css`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

export const border = css`
  border-width: 1px;
  border-color: ${grayScale.medium};
  border-style: solid;
  border-radius: 8px;
`;

export const borderTight = css`
  border-width: 1px;
  border-color: ${grayScale.medium};
  border-style: solid;
  border-radius: 4px;
`;

export const dropdownColors = {
  hover: '#CEDEFC',
};
