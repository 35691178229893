import isEqual from 'lodash/isEqual';
import { useEffect, useContext } from 'react';
import WizardStateContext from '../../shared/context/wizardstate';

/*
 * A hook for watching for changes in a value and keeping it up to date in the state
 *
 * @param {String} key - The key at which to insert the value in the state
 *
 * @param {any} value - The value to watch for changes and put into the state at the
 * respective key
 */
const useMetadataKey = (key, value) => {
  const { setState, state } = useContext(WizardStateContext.Context);

  useEffect(() => {
    if (key && typeof value !== 'undefined' && !isEqual(value, state[key])) {
      setState(key, value);
    }
  }, [key, value, setState, state]);
};

export default useMetadataKey;
