import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import KizenTooltip from 'components/Kizen/Tooltip';

const InfoIcon = styled(Icon)`
  margin-top: 2px;

  & svg {
    width: 10px;
    height: 10px;
  }
`;

const InfoTooltip = styled(KizenTooltip)`
  width: 300px;
  .tooltip-inner {
    max-width: 100%;
    text-align: left;
    position: relative;
    left: -5px;
  }
  .arrow {
    left: -5px;
  }
  .arrow:before {
    border-width: 6px 6px 6px 0px;
    border-right-color: ${grayScale.dark};
    border-top-color: transparent;
  }
`;

export const Tooltip = ({ show, text }) => {
  return show ? (
    <OverlayTrigger
      placement="right"
      overlay={<InfoTooltip show label={text} />}
    >
      <InfoIcon icon="info-circle" />
    </OverlayTrigger>
  ) : null;
};
