import AccessRequest from 'components/Sharing/AccessRequest';
import { DASHBOARD } from 'queries/query-keys';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getGrantingAdminsForDashboardQuery } from './queries';
import { StyledRequestModal } from './styles';

const RequestModal = ({
  dashboard = {},
  onConfirm,
  buttonMode = false, // Is this triggered from an explicit button instead of a context menu?
  ...others
}) => {
  const { t } = useTranslation();
  const { employee_access } = dashboard;
  const [data, setData] = useState();

  const handleFetchGrantingAdmins = useCallback(
    () => getGrantingAdminsForDashboardQuery(dashboard.id),
    [dashboard.id]
  );

  const { data: grantingAdmins, isLoading: grantingAdminsLoading } = useQuery(
    [...DASHBOARD.GRANTING_ADMINS, dashboard.id],
    handleFetchGrantingAdmins,
    {
      enabled: !!dashboard.id,
    }
  );

  const grantingAdminOptions = useMemo(() => {
    return (grantingAdmins ?? []).map((a) => ({
      value: a.id,
      label: a.display_name,
    }));
  }, [grantingAdmins]);

  const handleConfirm = useCallback(() => {
    if (data.accessLevel && data.grantingAdmin && dashboard.id) {
      onConfirm?.(dashboard.id, data.accessLevel, data.grantingAdmin);
    }
  }, [data, dashboard, onConfirm]);

  return (
    <StyledRequestModal
      defaultLeftBtnText={t('Cancel')}
      heading={t('Request Access')}
      onConfirm={handleConfirm}
      {...others}
    >
      <AccessRequest
        grantingAdminOptions={grantingAdminOptions}
        employee_access={employee_access}
        defaultAdmin={dashboard?.owner}
        loading={grantingAdminsLoading}
        onChange={setData}
        useFirstAdmin={buttonMode}
      />
    </StyledRequestModal>
  );
};

export default RequestModal;
