import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { paths } from 'schema';
import { curry1 } from './utils';

export const getAutomation = async (
  instance: AxiosInstance,
  id: string,
  options?: AxiosRequestConfig & { skipErrorBoundary?: any }
): Promise<
  AxiosResponse<
    paths['/api/automation2/automations/{id}']['get']['responses']['200']['content']['application/json']
  >
> => {
  return instance.get(`/automation2/automations/${id}`, options);
};

export class AutomationService {
  public getAutomation;

  constructor(private instance: AxiosInstance) {
    this.getAutomation = curry1(getAutomation)(this.instance);
  }
}
