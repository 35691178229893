import KizenTypography from 'app/kizentypo';

export const LinkButton = ({ route, text }) => {
  return (
    <KizenTypography
      className="link_button"
      type="link"
      color="blue"
      to={route}
    >
      {text}
    </KizenTypography>
  );
};
