import { useCallback, useMemo } from 'react';
import { createTeleporter } from 'react-teleporter';
import DashletMenu from '../DashletMenu';
import { editableDashlet } from '../../util';
import useModal from 'components/Modals/useModal';
import { useTranslation } from 'react-i18next';

const DashletMenuController = ({
  dashlet,
  editDashlet,
  onDeleteDashlet,
  onRenameHandler,
  onDuplicateHandler,
  clientObjectId,
  ModalComponent,
  model,
  modalComponentProps,
  entity,
  overrideEditHandler,
  overrideShowDashletMenu,
  setOverrideShowDashletMenu,
}) => {
  const { t } = useTranslation();
  const DashletMenuContents = useMemo(createTeleporter, []);
  const DashletAction = useMemo(createTeleporter, []);
  const isForContacts = model?.fetchUrl === 'client';
  const [modalProps, , modal] = useModal({
    handleSubmit: (dashlet) => editDashlet?.(dashlet, true),
    override: overrideShowDashletMenu,
    setOverride: setOverrideShowDashletMenu,
  });

  const onDeleteHandler = useCallback(() => {
    return onDeleteDashlet(dashlet.dashboard, dashlet.id);
  }, [dashlet, onDeleteDashlet]);

  return (
    <>
      <DashletAction.Target className="dashlet-action" />

      <DashletMenu
        onDeleteHandler={onDeleteHandler}
        onEditHandler={
          overrideEditHandler ?? (editDashlet ? modal.show : undefined)
        }
        onDuplicateHandler={onDuplicateHandler}
        onRenameHandler={onRenameHandler}
        id={dashlet.id}
        entity={entity}
      >
        <DashletMenuContents.Target />
      </DashletMenu>
      <ModalComponent
        {...modalProps}
        {...modalComponentProps}
        existing={editableDashlet(dashlet, t)}
        scrollActvitiyBuilderIntoView={overrideShowDashletMenu}
        hideModal={modal.hide}
        clientObjectId={clientObjectId}
        objectId={model?.id}
        contacts={isForContacts}
      />
    </>
  );
};

export default DashletMenuController;
