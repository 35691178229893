import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import FullscreenModal from 'components/Modals/Fullscreen';
import { DEFAULT_FORM_ROOT_PROPS } from '@kizen/page-builder/nodes/Root';
import { areTreesEqual } from '@kizen/page-builder/utils/are-equal';
import { isSubmitActionButton } from '@kizen/page-builder/utils/node-is';
import {
  ConfirmExitModal,
  PageBuilderCanvas,
  PageBuilderHeader,
  PageBuilderContainer,
  PageBuilderTray,
  PageBuilderPageSelector,
  getNodeIdToFieldIdLookupFromEdits,
  usePageBuilderEdits,
} from 'components/PageBuilder';
import {
  TextTrayItem,
  ImageTrayItem,
  ButtonTrayItem,
  HTMLBlockTrayItem,
} from 'components/PageBuilder/tray-items';
import {
  formCreatorElements,
  formNodes,
  formNodeSettings,
  surveyTrayItems,
} from 'components/PageBuilder/presets';
import { useToast, toastVariant } from 'components/ToastProvider';
import { useModalControl } from 'hooks/useModalControl';
import { isPromiseFulfilled } from 'services/helpers';
import CustomObjectsService from 'services/CustomObjectsService';
import SurveyService from 'services/SurveyService';
import {
  selectSurveyById,
  updateSurveyFieldSuccess,
} from 'store/surveysPage/surveys.redux';
import { getGenerativeAIEntitlement } from 'store/authentication/selectors';
import { previewSurvey } from '../../Surveys';
import { SaveAndPreviewButton } from './SaveAndPreviewButton';
import {
  hasButtonWithRecaptcha,
  updateFieldMeta,
  parseDataProp,
  enableRecaptchaOnAllSubmitButtons,
  enableRecaptchaOnPageData,
} from 'pages/FormSettings/Builder/utilities';
import { maybeTrackBuilderSave, maybeTrackAIRequest } from 'utility/analytics';

const SAVE_SUCCESS_MESSAGE = (t) =>
  t('Your survey has been saved successfully.');
const SAVE_ERROR_MESSAGE = (t) =>
  t(
    'Your survey has not been saved successfully. Please try again. If this problem persists, please contact Kizen support.'
  );

const StyledPageBuilderContainer = styled(PageBuilderContainer)`
  min-height: calc(100vh - 56px); // 56 = header height
`;

const thankYouPageTrayItems = [
  TextTrayItem,
  ImageTrayItem,
  ButtonTrayItem,
  HTMLBlockTrayItem,
];

const Builder = ({ surveyId, surveySlug, onGoBack }) => {
  const { t } = useTranslation();
  const canvasRef = useRef();
  const dispatch = useDispatch();
  const survey = useSelector(selectSurveyById(surveyId));
  const businessId = useSelector((s) => s.authentication.chosenBusiness.id);
  const {
    formUi: { pages },
  } = survey;
  const defaultPhoneRegion = useSelector(
    (state) => state.authentication.chosenBusiness.phone_default_region
  );
  const [showToast] = useToast();
  const [isConfirmModalOpen, { showModal, hideModal }] = useModalControl();
  const [isSaving, setIsSaving] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const enableRecaptchaRef = useRef(
    pages.some((page) => hasButtonWithRecaptcha(page.pageData))
  );
  const enableAI = useSelector(getGenerativeAIEntitlement);

  const {
    edits,
    fieldsToCreate,
    fieldsToDuplicate,
    fieldsToPatch,
    addFieldToCreate,
    addFieldToDelete,
    addFieldToDuplicate,
    patchField,
    getEdits,
    resetFields,
    setEdits,
    updateEdits,
    updateField,
  } = usePageBuilderEdits(pages);
  const {
    isFormPage,
    pageData,
    id: currentPageId,
  } = edits?.[currentPage] ?? {};
  const currentPageData = pageData || pages[currentPage]?.pageData;

  const handleNodeCreate = useCallback(
    (nodeId, node) => {
      addFieldToCreate(currentPageId, nodeId, node);
    },
    [currentPageId, addFieldToCreate]
  );

  const handleNodeDelete = useCallback(
    (nodeId, node) => {
      addFieldToDelete(currentPageId, nodeId, node);
    },
    [currentPageId, addFieldToDelete]
  );

  const handleNodeDuplicate = useCallback(
    (nodeId, node) => {
      addFieldToDuplicate(currentPageId, nodeId, node);
    },
    [currentPageId, addFieldToDuplicate]
  );

  const handleNodeUpdate = useCallback(
    (nodeId, patches) => {
      patchField(currentPageId, nodeId, patches);
    },
    [currentPageId, patchField]
  );

  const handlePropChange = useCallback((node) => {
    if (isSubmitActionButton(node)) {
      enableRecaptchaRef.current = node.props.enableRecaptcha;
    }
  }, []);

  const showErrorToast = useCallback(() => {
    showToast({
      message: SAVE_ERROR_MESSAGE(t),
      variant: toastVariant.FAILURE,
    });
  }, [showToast, t]);

  const showSuccessToast = useCallback(() => {
    showToast({ message: SAVE_SUCCESS_MESSAGE(t) });
  }, [showToast, t]);
  const { relatedObject } = survey;

  const { loading, value: objectFields = [] } = useAsync(async () => {
    return CustomObjectsService.getCustomModelFields(relatedObject.id);
  }, [relatedObject.id]);

  const handleGoBack = useCallback(() => {
    const hasChanges = getEdits(canvasRef.current.editor, currentPage).reduce(
      (acc, page, idx) =>
        acc || !areTreesEqual(page.pageData, pages[idx].pageData),
      false
    );
    (hasChanges ? showModal : onGoBack)();
  }, [pages, currentPage, getEdits, showModal, onGoBack]);

  /**
   * If there was an error creating any one of the fields, delete the fields that
   * were successfully created and return.
   * If successful, set the craft field prop for each field so the API generated
   * id can be serialized.
   * @param response - { hasError: boolean, results: Promise[] }
   * @param createdEntries - [string, object][] ([craftId, field data])
   */
  const processCreateResponse = useCallback(
    async (response, createdEntries) => {
      if (response.hasError) {
        const createdIds = response.results
          .filter(isPromiseFulfilled)
          .map(({ value }) => value.id);
        if (createdIds.length > 0) {
          await SurveyService.deleteFields(surveyId, createdIds);
        }
        return false;
      }

      response.results.forEach(({ value }, idx) => {
        const craftId = createdEntries[idx][0];
        updateField(craftId, value);
      });
      const currentEdits = getEdits(); // call without args so we don't call getSerializedNodes and overwrite the fields we just updated
      const pages = enableRecaptchaOnAllSubmitButtons(
        currentEdits,
        enableRecaptchaRef.current
      );

      await SurveyService.update(surveyId, {
        challenge_token_required: enableRecaptchaRef.current,
        formUi: { pages },
      });
      dispatch(updateSurveyFieldSuccess({ id: surveyId, formUi: { pages } }));
      return true;
    },
    [surveyId, dispatch, getEdits, updateField]
  );

  const onSave = useCallback(async () => {
    try {
      setIsSaving(true);
      updateEdits(canvasRef.current.editor, currentPage);

      const creates = Object.entries(fieldsToCreate).concat(
        Object.entries(fieldsToDuplicate)
      );
      const createResponse = await SurveyService.createFields(
        surveyId,
        creates.map(([, field]) => {
          const isCustomObjectField = Boolean(field?.customObjectField?.id);
          return { ...field, isCustomObjectField };
        })
      );
      await processCreateResponse(createResponse, creates);
      if (createResponse.hasError) {
        showErrorToast();
        return;
      }

      const updates = Object.entries(fieldsToPatch);
      if (updates.length) {
        const fieldIdLookup = getNodeIdToFieldIdLookupFromEdits(edits);
        await Promise.all(
          Object.entries(fieldsToPatch).map(([id, patches]) =>
            SurveyService.patchField(surveyId, fieldIdLookup[id], patches)
          )
        );
      }

      // call without args to update the craft builder state with the correct form field ids
      // we just updated in processCreateResponse so subsequent calls to getSerializedNodes
      // nodes returns fields with their ids.
      updateEdits();
      resetFields();
      showSuccessToast();
      return true;
    } catch (err) {
      showErrorToast();
    } finally {
      setIsSaving(false);
    }
  }, [
    edits,
    surveyId,
    fieldsToCreate,
    fieldsToDuplicate,
    fieldsToPatch,
    currentPage,
    updateEdits,
    resetFields,
    setIsSaving,
    processCreateResponse,
    showSuccessToast,
    showErrorToast,
  ]);

  const onShowPreview = useCallback(async () => {
    const success = await onSave();
    if (success) {
      maybeTrackBuilderSave('survey');
      previewSurvey(
        `https://${import.meta.env.VITE_EMBED_FRONTEND_DOMAIN}`,
        surveySlug,
        'kizenSurveyPreviewTab'
      );
    }
  }, [surveySlug, onSave]);

  const handleSaveAndClose = useCallback(async () => {
    if (!isSaving) {
      await onSave();
      onGoBack();
    }
  }, [onSave, onGoBack, isSaving]);

  const onAIRequestStart = useCallback(
    (requestType) => {
      maybeTrackAIRequest('survey', survey.id, businessId, requestType);
    },
    [survey.id, businessId]
  );

  const pageSelectorComponent = useMemo(() => {
    const handlePageChange = ({ value }) => {
      if (value !== currentPage.toString()) {
        updateEdits(canvasRef.current.editor, currentPage);
        setCurrentPage(parseInt(value));
        canvasRef.current.clearContentSettingsTray();
      }
    };
    return (
      <PageBuilderPageSelector
        value={currentPage.toString()}
        pages={edits}
        onOpen={() => {
          canvasRef.current.clearContentSettingsTray();
          updateEdits(canvasRef.current.editor, currentPage);
        }}
        onChange={handlePageChange}
        onPagesEdit={async (updatedPages) => {
          const res = await SurveyService.update(surveyId, {
            formUi: { pages: updatedPages },
          });
          dispatch(
            updateSurveyFieldSuccess({
              id: surveyId,
              formUi: { pages: res.formUi.pages },
            })
          );
          setEdits(updatedPages);
          const currentPageIndex = updatedPages.findIndex(
            (page) => page.id === edits[currentPage]?.id
          );
          const newCurrentPage =
            currentPageIndex === -1 || updatedPages[currentPageIndex].hidden
              ? 0
              : currentPageIndex;
          setCurrentPage(newCurrentPage);
        }}
      />
    );
  }, [edits, surveyId, currentPage, setEdits, updateEdits, dispatch]);

  const headerComponent = useMemo(() => {
    const handleSave = () => {
      if (!isSaving) {
        onSave();
      }
    };

    return (
      <PageBuilderHeader
        centerContent={pageSelectorComponent}
        rightActionButton={
          <SaveAndPreviewButton
            onClick={onShowPreview}
            disabled={!isConfirmModalOpen && isSaving}
          />
        }
        saving={!isConfirmModalOpen && isSaving}
        onSave={handleSave}
        onSaveAndClose={handleSaveAndClose}
        onGoBack={handleGoBack}
      />
    );
  }, [
    isConfirmModalOpen,
    isSaving,
    pageSelectorComponent,
    handleGoBack,
    onSave,
    onShowPreview,
    handleSaveAndClose,
  ]);

  const [nodeSettings, creatorElements] = useMemo(() => {
    return [
      formNodeSettings(isFormPage),
      formCreatorElements(isFormPage, t('Add Survey Field')),
    ];
  }, [isFormPage, t]);

  const data = useMemo(() => {
    if (!currentPageData?.ROOT) return {};
    return updateFieldMeta(parseDataProp(currentPageData), objectFields);
  }, [currentPageData, objectFields]);

  // /***************************************************************/
  // // When switching pages, take the button recaptcha state from the
  // // previous page and apply it to any buttons on the new page.
  const finalData = useMemo(() => {
    return enableRecaptchaOnPageData(data, enableRecaptchaRef.current);
  }, [data]);
  // /***************************************************************/

  if (loading) return null;

  return (
    <>
      {isConfirmModalOpen && (
        <ConfirmExitModal
          heading={t('Your Survey Has Unsaved Changes')}
          isSaving={isSaving}
          onDiscard={onGoBack}
          onCancel={hideModal}
          onSave={handleSaveAndClose}
        >
          {t('All unsaved changes will be lost unless you save your survey.')}
        </ConfirmExitModal>
      )}
      <FullscreenModal show header={headerComponent} enforceFocus={false}>
        {survey && (
          <StyledPageBuilderContainer
            enableGoogleFonts
            enableTextLinks
            nodes={formNodes}
            creatorElements={creatorElements}
            onNodeCreate={handleNodeCreate}
            onNodeDelete={handleNodeDelete}
            onNodeDuplicate={handleNodeDuplicate}
            onNodeUpdate={handleNodeUpdate}
            onNodePropChange={handlePropChange}
            form={survey}
            currentPage={currentPage}
            defaultPhoneRegion={defaultPhoneRegion}
            {...(enableAI && {
              enableAI: true,
              onAIRequestStart,
            })}
          >
            <PageBuilderCanvas
              ref={canvasRef}
              data={finalData}
              defaultRootProps={DEFAULT_FORM_ROOT_PROPS}
              key={edits[currentPage]?.id}
            />
            <PageBuilderTray
              trayItems={isFormPage ? surveyTrayItems : thankYouPageTrayItems}
              nodeSettings={nodeSettings}
            />
          </StyledPageBuilderContainer>
        )}
      </FullscreenModal>
    </>
  );
};

export default Builder;

Builder.propTypes = {
  surveyId: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
};
