import { useSelector } from 'react-redux';
import ct from 'countries-and-timezones';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TeamMemberService from 'services/TeamMemberService';
import { DASHBOARD, BROADCAST } from 'queries/query-keys';
import { useCallback, useRef } from 'react';
import { useToast, toastVariant } from 'components/ToastProvider';
import useDelayedMutation from './useDelayedMutation';
import { fetchAndRepairDashboardConfig } from 'components/DashboardGrid/utils';

const queryKeys = {
  dashboard_list: [...DASHBOARD.DASHBOARD_CONFIG, null], // the "type" is undefined for dashboards but is included in the query key
  broadcast_calendar: BROADCAST.BROADCAST_CONFIG,
};

const getters = {
  dashboard_list: fetchAndRepairDashboardConfig,
  broadcast_calendar: TeamMemberService.getPageConfig,
};

export const useTimeZoneNotice = (pageConfigKey = 'dashboard_list') => {
  const authentication = useSelector((s) => s.authentication);
  const noticeShown = useRef(false);
  const [showToast] = useToast();
  const queryClient = useQueryClient();

  const businessTz = authentication?.chosenBusiness?.timezone.name;
  const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const currentTimeOffset = ct.getTimezone(userTz)?.utcOffset;
  const businessTimeOffset = ct.getTimezone(businessTz)?.utcOffset;

  const { data, isLoading } = useQuery(
    queryKeys[pageConfigKey],
    () => getters[pageConfigKey](pageConfigKey),
    {
      refetchOnWindowFocus: false,
    }
  );

  const configMutation = useMutation(
    (result) => TeamMemberService.setPageConfig(pageConfigKey, result),
    {
      onMutate: async (updated) => {
        await queryClient.cancelQueries(queryKeys[pageConfigKey]);
        const previousData = queryClient.getQueryData(queryKeys[pageConfigKey]);

        queryClient.setQueryData(queryKeys[pageConfigKey], updated);

        return { previousData };
      },
      onError: (_err, _updated, context) => {
        queryClient.setQueryData(
          queryKeys[pageConfigKey],
          context.previousData
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys[pageConfigKey]);
      },
    }
  );

  const timeZonesDiffer = currentTimeOffset !== businessTimeOffset;
  const lastShown = data?.timeZoneNotice?.lastShown ?? 0;
  const elapsedMsSinceLastShown = Date.now() - lastShown;
  const oneDay = 1000 * 60 * 60 * 24;

  const showNotice = timeZonesDiffer && elapsedMsSinceLastShown > oneDay;

  const delayedMutation = useDelayedMutation(configMutation.mutate);

  const handleShowNotice = useCallback(
    async (message) => {
      if (!noticeShown.current) {
        noticeShown.current = true;
        showToast({
          message,
          variant: toastVariant.ALERT,
          delay: 3000,
        });

        delayedMutation({
          ...data,
          timeZoneNotice: {
            lastShown: Date.now(),
          },
        });
      }
    },
    [showToast, delayedMutation, data]
  );

  return {
    handleShowNotice,
    showNotice,
    data,
    isLoading,
  };
};
