import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import { getDataQAForInput } from 'components/Inputs/helpers';
import { toastVariant, useToast } from 'components/ToastProvider';
import useField from 'hooks/useField';
import { isReasonLostField, isStageField } from 'checks/fields';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { getOriginalError } from 'services/AxiosService';
import CustomObjectsService from 'services/CustomObjectsService';
import FieldInput from 'components/Fields/FieldInput';
import PipelineService from 'services/PipelineService';
import Loader from 'components/Kizen/Loader';
import { useFieldsQuery, useModelQuery } from 'queries/models/custom-objects';
import BasicModalWithConfirmation from '../presets/BasicModalWithConfirmation';
import { isPipelineObject } from '../utilities';
import {
  UnarchiveConflictModal,
  UnarchiveForbiddenModal,
} from '../UnarchiveModal';
import {
  CONTACT,
  PIPELINE,
  CUSTOM_OBJECT,
  dummyObject,
  updateErrors,
  hasErrors,
  getToastConfigMessage,
  getObjectName,
  saveEntity,
  getCreateEntityFormValues,
  PERMISSION_DENIED,
  UNARCHIVE_CONFLICT,
  UNARCHIVE_PROMPT,
  UNARCHIVE,
} from './helpers';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

export { CONTACT, PIPELINE, CUSTOM_OBJECT };

const EMPTY_OBJECT = {};

// for better understanding how initialValues works please check "FieldService.getFormValues" function
const CreateEntityModalShort = ({
  objectType,
  objectId,
  onCreated,
  onCancel,
  show,
  initialValues,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();

  const [dirty, setDirty] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errors, setErrors] = useState([]);

  const toastMessage = useRef(getToastConfigMessage(objectType, t));
  const anchorRef = useRef(null);
  const [isUnarchiveForbidden, setIsUnarchiveForbidden] = useState(false);
  const [isUnarchiveConflict, setIsUnarchiveConflict] = useState(false);

  useLayoutEffect(() => {
    if (objectType === CONTACT) {
      // special case for contacts. always email field are last field (in short form) and in this case validation message shows incorrectly
      // for handle that - we must to change overflow property for our modal body
      // I didn't found a better way then I have here 🤷‍♂️
      anchorRef.current?.parentElement?.setAttribute(
        'style',
        'overflow-y: visible'
      );
    }
  }, [objectType]);

  const { data: allFields, isLoading: isLoadingFields } = useFieldsQuery(
    { objectType, id: objectId },
    !!objectType && show
  );

  const { data: modelRaw = EMPTY_OBJECT, isLoading: isLoadingModel } =
    useModelQuery(objectId, !!objectId && show);

  const model = useMemo(() => {
    return {
      ...modelRaw,
      access: { ...(modelRaw.access || {}), edit: modelRaw.entityAccess },
    };
  }, [modelRaw]);

  const fields = useMemo(
    () =>
      (allFields || [])
        .filter((f) => f.includeInShortForm || isReasonLostField(f))
        .sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10)),
    [allFields]
  );

  const currentName = useMemo(
    () => getObjectName(model, objectType),
    [model, objectType]
  );

  const serviceToUse = useMemo(() => {
    if (!model) return null;
    return isPipelineObject(model) ? PipelineService : CustomObjectsService;
  }, [model]);

  const [values, setValues] = useField(() => {
    if (objectType === CONTACT) {
      return getCreateEntityFormValues(
        { ...dummyObject, ...initialValues },
        fields
      );
    }

    if (!model) return {};

    const { name, owner, created, ...rest } = model; // exclude name, owner and created from CO
    const reasonLostField = fields.find(isReasonLostField);
    const reasonLostFieldData = reasonLostField
      ? { ...reasonLostField, value: [] }
      : null;
    return getCreateEntityFormValues(
      { ...rest, ...initialValues, fields: [reasonLostFieldData] },
      fields
    );
  }, [fields, model, show]);

  const { assignFieldHandle, getKeyListenersProps, resetFocus } =
    useKeyListeners(fields, objectType === CONTACT ? dummyObject : model);

  const handleClose = useCallback(() => {
    setErrors([]);
    setValues({});
    onCancel();
    setDirty(false);
    resetFocus();
  }, [onCancel, resetFocus, setValues]);

  const handleChangeField = useCallback(
    (val, field) => {
      setDirty(true);
      setErrors(updateErrors(errors, field));
      let oldValues = cloneDeep(values);

      if (isStageField(field)) {
        const stage = field.options.find((opt) => opt.id === val);
        const stageDetails = model?.pipeline?.stages?.find(
          (s) => s.id === stage.id
        );
        if (stageDetails?.status !== 'lost') {
          const reasonLostField = fields.find(isReasonLostField);
          if (reasonLostField) {
            oldValues = { ...oldValues, [reasonLostField.id]: [] };
          }
        }
      }

      setValues({ ...oldValues, [field.id]: val });
    },
    [errors, values, setValues, fields, model]
  );

  const handleSubmit = useCallback(
    async (unarchive = UNARCHIVE_PROMPT) => {
      setDisableSubmit(true);

      const errors = hasErrors(fields, values, t);
      if (errors.length !== 0) {
        setDisableSubmit(false);
        setErrors(errors);
        return;
      }

      try {
        const newRecord = await saveEntity(
          objectType,
          values,
          fields,
          unarchive,
          objectId
        );
        onCreated(newRecord);
        showToast(
          unarchive === UNARCHIVE
            ? {
                variant: toastVariant.SUCCESS,
                message: `${t(`EntityName was successfully unarchived`, {
                  entityName: currentName,
                })}`,
              }
            : toastMessage.current.success
        );
        handleClose();
      } catch (error) {
        const orig = snakeToCamelCaseKeys(getOriginalError(error));
        if (orig && orig.code) {
          if (orig.code === PERMISSION_DENIED) {
            setIsUnarchiveForbidden(true);
          }
          if (orig.code === UNARCHIVE_CONFLICT) {
            setIsUnarchiveConflict(true);
          }
        }
        // special error status
        if (orig && orig.nonFieldErrors) {
          showToast({
            ...toastMessage.current.error,
            message: orig.nonFieldErrors.toString(),
          });
        } else if (orig) {
          const errorFields = Object.keys(orig).reduce((acc, item) => {
            const f = fields.find((i) => item === i.name);
            if (!f) return acc;
            return acc.concat({ fieldId: f.id, message: orig[item] });
          }, []);
          setErrors(errorFields);
        } else {
          // rest unhandled cases
          showToast(toastMessage.current.error);
        }
      } finally {
        setDisableSubmit(false);
      }
    },
    [
      currentName,
      fields,
      handleClose,
      objectId,
      objectType,
      onCreated,
      showToast,
      t,
      values,
    ]
  );

  const handleUnarchive = useCallback(
    (unarchive) => {
      handleSubmit(unarchive);
      setIsUnarchiveConflict(false);
    },
    [handleSubmit]
  );

  return (
    <BasicModalWithConfirmation
      dirty={dirty}
      className={`no-drag`}
      show={!!objectType && show}
      onHide={handleClose}
      onConfirm={handleSubmit}
      heading={
        objectType === CONTACT
          ? t(`Add Contact`)
          : `${t('Add')} ${model?.entityName || ''}`
      }
      disabled={disableSubmit}
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      hasOneBtnSubmit
    >
      <KeyBoardContext.Provider value={{ assignFieldHandle }}>
        <Loader loading={isLoadingFields || isLoadingModel}>
          <UnarchiveForbiddenModal
            show={isUnarchiveForbidden}
            onConfirm={() => setIsUnarchiveForbidden(false)}
            onHide={() => setIsUnarchiveForbidden(false)}
            name={currentName}
          />
          <UnarchiveConflictModal
            show={isUnarchiveConflict}
            onConfirm={() => handleUnarchive(UNARCHIVE)}
            onAdditionalConfirm={() => handleUnarchive('overwrite')}
            onHide={() => setIsUnarchiveConflict(false)}
            name={currentName}
          />

          {fields?.map((field) => {
            const error = errors.find((item) => item.fieldId === field.id);

            return (
              <div className={`span${field.meta.cols || 1}`} key={field.id}>
                <FieldInput
                  margin
                  variant="outline"
                  field={field}
                  object={objectType === CONTACT ? dummyObject : model}
                  value={values[field.id]}
                  onChange={(val) => handleChangeField(val, field)}
                  isGetFullObject // to ensure the whole fileObject is returned not just the id
                  menuInline
                  error={error ? { message: error.message } : null}
                  serviceToUse={serviceToUse}
                  objectId={objectId}
                  {...getDataQAForInput(
                    `short-form-${field.name}`,
                    field.fieldType
                  )}
                  {...getKeyListenersProps(field.id)}
                />
              </div>
            );
          })}
        </Loader>
      </KeyBoardContext.Provider>
      <div ref={anchorRef} />
    </BasicModalWithConfirmation>
  );
};

CreateEntityModalShort.propTypes = {
  objectType: PropTypes.oneOf([CONTACT, PIPELINE, CUSTOM_OBJECT]).isRequired,
  objectId: PropTypes.string,
  onCreated: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  show: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
};

CreateEntityModalShort.defaultProps = {
  object: '',
  onCancel: () => {},
  initialValues: {},
};

export default CreateEntityModalShort;
