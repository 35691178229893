import AxiosService from './AxiosService';
import {
  camelToSnakeCaseKeys,
  snakeToCamelCase,
  snakeToCamelCaseKeys,
} from './helpers';

const mapKey = (key) => {
  // The backend appends _searchable to keys to support searching
  // the corresponding values in elasticsearch
  // We remove that suffix to hide that detail from timeline-related components,
  // hopefully making event objects / keying into them easier to read / rememember
  return snakeToCamelCase(key.replace(/_searchable$/, ''));
};

const mapKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(mapKeys);
  }
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  return Object.entries(obj).reduce(
    (collect, [key, value]) => ({
      ...collect,
      [mapKey(key)]: mapKeys(value),
    }),
    {}
  );
};

const eventForApp = ({ timestamp, comments, ...rest }) =>
  mapKeys({
    ...rest,
    timestamp: Math.round(timestamp * 1000),
    comments: (comments || []).map((c) => ({
      ...c,
      timestamp: Math.round(c.timestamp * 1000),
    })),
  });

const preparePayload = (params) => {
  function getParamData(prop) {
    return params[prop]
      ? { [snakeToCamelCase(prop)]: params[prop].split(',') }
      : {
          [snakeToCamelCase(prop)]: [],
        };
  }

  return camelToSnakeCaseKeys({
    ...params,
    ...getParamData('eventType'),
    ...getParamData('excludeEventType'),
    ...getParamData('team_member'),
    ...getParamData('roleIds'),
    ...getParamData('customObjectIds'),
  });
};

class TimelineService {
  constructor() {
    if (!TimelineService.instance) {
      TimelineService.instance = this;
    }
    return TimelineService.instance;
  }

  // forms identifier required to lookup an event in elasticsearch
  static getEventURI({ id, created }, { resource = '' } = {}) {
    return `/timeline/${id}${resource ? `/${resource}` : ''}?date=${created}`;
  }

  getTimelineRelationships = async (id) => {
    const { data } = await AxiosService.get(
      `/timeline/events/${id}/relationships`
    );

    return snakeToCamelCaseKeys(data);
  };

  getTimelineRecords = async (id, params = {}) => {
    const {
      data: { events },
    } = await AxiosService.get(`records/${id}/timeline`, { params });

    return events.map(snakeToCamelCaseKeys);
  };

  getPagedTimelineRecords = async (
    id,
    params = {},
    skipErrorBoundary = false,
    searchTerm
  ) => {
    const {
      previous,
      next,
      size,
      cursor,
      event_id,
      created,
      objectsCount,
      typesCount,
      excludeTypesCount,
      teamMemberCount,
      roleCount,
      ...body
    } = params;
    const { data } = await AxiosService.post(
      `records/${id}/timeline`,
      preparePayload(body),
      {
        params: {
          previous,
          next,
          size,
          cursor,
          event_id,
          created,
          search: searchTerm,
        },
        skipErrorBoundary,
      }
    );
    const events = data.events.map(snakeToCamelCaseKeys);
    return { ...data, events };
  };

  getTimelineTypes = async () => {
    const { data } = await AxiosService.get('/timeline/entry-types');

    return data.types?.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );
  };

  deleteEvent = (event) =>
    AxiosService.delete(TimelineService.getEventURI(event));

  commentOnEvent = async (event, body) => {
    const { data } = await AxiosService.post('/comment', body);
    return eventForApp({ ...event, comments: [...event.comments, data] });
  };

  commentUnsubscribe = async (id) => {
    const { data } = await AxiosService.post(
      `/public/comments/unsubscribe/${id}`
    );
    return data;
  };
}

const instance = new TimelineService();
Object.freeze(instance);

export default instance;
