import KizenTypography from 'app/kizentypo';

export const getSupportEmail = (t) => {
  return (
    <KizenTypography as="p" className="support_text">
      {t('If you need additional help, please email')}{' '}
      <KizenTypography
        type="link-mail"
        size="micro"
        weight="bold"
        href="mailto:support@kizen.com"
      >
        support@kizen.com
      </KizenTypography>
      .
    </KizenTypography>
  );
};
