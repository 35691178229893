import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters, layers } from 'app/spacing';
import { colorsButton, grayScale } from 'app/colors';
import { SizedCell as KizenSizedCell } from 'components/Kizen/Table';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import Checkbox from 'components/Checkbox/Checkbox';
import CheckboxMenu from './CheckboxMenu';
import { useTranslation } from 'react-i18next';
import { useTooltip } from 'components/Kizen/Tooltip';
import { NoOptionsMessage } from 'pages/Common/components/NoOptionsMessage';
import { IconWithTooltip } from './LinkWithIconTooltip/IconWithTooltip';

export const PADDING = 60; // Right cell padding in pixels
export const PADDING_IN_PIXELS = `${PADDING}px`;

export const getSizing = (width, defaultWidth) => {
  if (width) {
    return { width };
  }
  return { minWidth: defaultWidth };
};

export const getColumnWidthFromConfig = (s, columnId) => {
  if (
    Object.keys(s.pageConfig).length === 0 ||
    !s.pageConfig.columns ||
    Object.keys(s.pageConfig.columns).length === 0
  ) {
    return null;
  }
  const { columns } = s.pageConfig;
  return (columns[columnId] && columns[columnId].width) || null;
};

export const SpacerCell = styled(({ column, width, ...others }) => {
  return <KizenSizedCell column={column} width={width} {...others} />;
})`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
  }
`;

export const ResizerLayout = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 0;
  justify-content: flex-end;
  > * {
    cursor: ew-resize;
    &:hover svg line {
      stroke: ${grayScale.dark};
    }
    // Avoiding relative positioning, transforms, etc.
    // to avoid messing with the column resizer's z-index.
    // Below accounts for half the padding and half the resize icon's width
    // in order to center the resize icon in the middle of the column padding.
    ${({ padding = PADDING }) => css`
      margin-right: calc(-${padding}px / 2 + -3px / 2);
    `}
  }
`;

export const CheckboxCell = styled(
  ({ head, column, data: { meta = {} }, ...others }) => {
    const BaseCell = head ? 'th' : 'td';
    return (
      <BaseCell {...others}>
        <Checkbox
          id={meta.id}
          checked={Boolean(meta.checked)}
          onChange={(ev) => {
            if (meta.onChecked) {
              meta.onChecked(meta.id, ev.target.checked);
            }
          }}
        />
      </BaseCell>
    );
  }
)`
  position: sticky;
  left: 0;
  width: 45px; // So that it doesn't expand even when there's room
  line-height: 0;
  label {
    margin: 0 ${gutters.standard};
    // Change hover color to dark grey
    &:hover input[type='checkbox']:not(:checked) + .CheckboxInputFacade {
      border-color: ${colorsButton.iconGray.hover};
    }
  }
  .CheckboxInputFacade {
    // In chrome the input facade's position: relative creates a new stacking
    // context, causing this element to appear on top of the page's sticky header.
    z-index: ${layers.content(0, -1)};
    background-color: ${grayScale.white};
  }
  .CheckboxText {
    display: none;
  }
`;

export const CheckboxMenuCell = styled(
  ({ head, column, data: { meta = {} }, indeterminate, ...others }) => {
    const BaseCell = head ? 'th' : 'td';

    return (
      <BaseCell {...others}>
        <CheckboxMenu
          checked={Boolean(meta.checked)}
          onChange={meta.onSelectionChanged}
          options={meta.selectionOptions || []}
          indeterminate={indeterminate}
          onOpenSelectionMenu={meta.onOpenSelectionMenu}
          components={{
            NoOptionsMessage,
          }}
        />
      </BaseCell>
    );
  }
)`
  position: sticky;
  left: 0;
  width: 45px; // So that it doesn't expand even when there's room
  line-height: 0;
  label {
    margin: 0 ${gutters.standard};
    // Change hover color to dark grey
    &:hover input[type='checkbox']:not(:checked) + .CheckboxInputFacade {
      border-color: ${colorsButton.iconGray.hover};
    }
  }
  .CheckboxInputFacade {
    // In chrome the input facade's position: relative creates a new stacking
    // context, causing this element to appear on top of the page's sticky header.
    z-index: ${layers.content(0, -1)};
    background-color: ${grayScale.white};
  }
`;

const columnsStyles = ({ right = 0, zIndex = null, minWidth = null }) => css`
  position: sticky;
  right: 0;
  margin-right: ${right}px;
  && {
    z-index: ${zIndex};
  }
  ${minWidth
    ? css`
        min-width: ${minWidth}px;
      `
    : ''}
  text-align: right;
  && > * {
    /* This icon has a very interesting aspect ratio to match for the designs */
    margin-right: calc(12px * (56 / 49));
    svg {
      width: calc(12px * (56 / 49));
      height: auto;
    }
  }
`;

export const ColumnsIconCell = styled(
  ({ head, column, data, canEdit = true, noScrollBars, ...others }) => {
    const { t } = useTranslation();
    const BaseCell = head ? 'th' : 'td';
    const [tooltipProps, tooltip] = useTooltip({
      label: t('Edit Columns'),
    });

    const columnButton = canEdit && (
      <IconButton
        color={colorsButton.iconGray}
        sizing="dense"
        onClick={data.meta.onClickColumns}
        title={t('Edit Columns')}
        data-qa="edit-columns"
        {...tooltipProps}
      >
        <Icon icon="table-columns" />
      </IconButton>
    );

    return (
      <BaseCell {...others}>
        {columnButton}
        {tooltip}
      </BaseCell>
    );
  }
)`
  ${columnsStyles}
`;

const getToColumnsPath = ({ pathname }) => pathname + '/columns';

export const ColumnsIconLinkCell = styled(
  ({
    head,
    column,
    data,
    canEdit = true,
    to = getToColumnsPath,
    ...others
  }) => {
    const { t } = useTranslation();
    const BaseCell = head ? 'th' : 'td';

    return (
      <BaseCell {...others}>
        {canEdit ? (
          <Link to={to}>
            <IconWithTooltip icon="table-columns" label={t('Edit Columns')} />
          </Link>
        ) : null}
      </BaseCell>
    );
  }
)`
  ${columnsStyles}
`;
