import {
  takeLatest,
  takeEvery,
  all,
  call,
  put,
  select,
  takeLeading,
  debounce,
} from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import i18nInstance from 'i18n-config';

import { buildFilterSets } from '@kizen/filters/filter-sets';

import FieldService from 'services/FieldService';
import TeamMemberService, {
  RECORD_LIST_CONFIG_KEYS,
  collectRecordListPageConfig,
} from 'services/TeamMemberService';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';
import FilterGroupsService from 'services/FilterGroupsService';
import LeadSourceService from 'services/LeadSourceService';
import { camelToSnakeCase, getTeamLabel } from 'services/helpers';
import { camelToSnakeCaseKeys } from 'services/helpers';
import { isPipelineObject } from 'components/Modals/utilities';
import { STAGE_STATUSES, FIELD_TYPES } from 'utility/constants';
import { setSearchParams } from 'hooks/useSearchParam';
import { DEFAULT_INPUT_DELAY, SET_CONFIG_DELAY } from 'utility/config';
import {
  buildPage as buildPageAction,
  buildPageFail,
  buildPageComplete,
  buildPageFinish,
  getRecordsStart,
  getRecords as getRecordsAction,
  getRecordsFail,
  getRecordsSuccess,
  updateRecord,
  addNewRecord,
  setPageConfig as setPageConfigAction,
  setPageConfigSuccess,
  setPageConfigFail,
  archiveRecord as archiveRecordAction,
  archiveRecordSuccess,
  archiveRecordFail,
  setToast,
  getLeadSourceAdditionalData,
  getLeadSourceAdditionalDataFail,
  setLeadSourceAdditionalData,
  getLeadSourceEntities as getLeadSourceEntitiesAction,
  getLeadSourceEntitiesFail,
  setLeadSourceDataByKey,
  getBoardData as getBoardDataAction,
  getBoardDataFail,
  getBoardDataSuccess,
  getRecordsInStage as getRecordsInStageAction,
  getRecordsInStageSuccess,
  getRecordsInStageFail,
  moveRecord as moveRecordInStage,
  moveRecordFail,
  moveRecordSuccess,
  defaultRecordsConfig,
  createOrUpdateGroup as createOrUpdateGroupAction,
  createOrUpdateGroupFail,
  createOrUpdateGroupSuccess,
  changeGroup as changeGroupAction,
  removeGroup as removeGroupAction,
  setSavedFilter,
  removeGroupFail,
  removeGroupSuccess,
  resetFilterGroupSuccess,
  getFullListOfCustomObjects as getFullListOfCustomObjectsAction,
  getFullListOfCustomObjectsFail,
  getFullListOfCustomObjectsSuccess,
  moveRecordStart,
  getCounts as getCountsAction,
  getCountsSuccess,
  getCountsFail,
  setCountsNeedToLoad,
  getRecordsBySearch,
  getBoardDataBySearch,
  getRecordsFinish,
  updateRecords,
  updateRelatedRecordsFail,
  archiveRecordFromBoard,
  updateBoardMetrics,
  setGroup,
  updatePageConfig,
  getBoardDataStart,
  clearFilterSearch,
} from './actions';
import { anyFilterErrors, getFilterErrors } from 'hooks/useFilterErrorEffect';
import { toastVariant } from 'components/ToastProvider';

import {
  enrichAndRepairQuickFilterSettings,
  getCurrentCustomFieldIds,
} from 'store/utilities';
import {
  buildPageFilterQuery,
  getFilterVariables,
  loadFilterFromUrlParam,
  loadSavedFilter,
} from 'ts-filters/utils';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import { GET_FILTER_GROUPS_PATHNAMES } from 'pages/FilterGroupsPage/constants';
import { TIMEZONE_OFFSET_HEADER } from 'services/constants';
import { getTimezoneOffset } from 'services/utils';
import {
  SUMMARY_VALUES,
  getMetricValue,
  getSecondaryMetricValue,
} from './records.redux';

const getPayloadProperties = (index, stagedCards, recordId, moveToPosition) => {
  if (moveToPosition) {
    // this means we moved this card to some position ('top'||'bottom') from card menu(not DnD)
    return { moveToPosition };
  }

  const currentElementIndex = stagedCards.findIndex((e) => e.id === recordId);

  if (index === 0) {
    // we drag item to the top
    const moveBeforeRecordId =
      currentElementIndex === -1 || !stagedCards[currentElementIndex + 1]
        ? null // null means this card only alone inside this column
        : stagedCards[currentElementIndex + 1].id;

    return { moveBeforeRecordId };
  }

  const moveAfterRecordId =
    currentElementIndex === -1 || !stagedCards[currentElementIndex - 1]
      ? null
      : stagedCards[currentElementIndex - 1].id;

  return { moveAfterRecordId };
};

export const getValueForUI = (fieldType, fieldValue) => {
  switch (fieldType) {
    case FIELD_TYPES.DynamicTags.type: {
      return {
        value: fieldValue.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
        })),
      };
    }
    case FIELD_TYPES.Dropdown.type:
    case FIELD_TYPES.Radio.type:
    case FIELD_TYPES.Status.type:
    case FIELD_TYPES.YesNoMaybe.type: {
      return fieldValue
        ? {
            name: fieldValue.name,
            value: fieldValue.id,
          }
        : {
            name: '',
            value: null,
          };
    }
    case FIELD_TYPES.TeamSelector.type:
    case FIELD_TYPES.Relationship.type: {
      if (Array.isArray(fieldValue)) {
        return {
          value: fieldValue.map((item) => ({
            ...item,
            label: item.name || getTeamLabel(item),
          })),
        };
      }

      return {
        value: {
          ...fieldValue,
          label: fieldValue.name || getTeamLabel(fieldValue),
        },
      };
    }
    case FIELD_TYPES.Files.type: {
      return {
        name: fieldValue,
        value: fieldValue.id,
      };
    }
    default: {
      return { value: fieldValue };
    }
  }
};

export const getFieldsForUI = (fields) =>
  Array.isArray(fields)
    ? fields.reduce((acc, item) => {
        if (item.valueSummary) {
          return [
            ...acc,
            {
              ...item,
              field: item.id,
            },
          ];
        }
        if (item.fieldType === FIELD_TYPES.Files.type) {
          return [
            ...acc,
            ...item.value.map((file) => ({
              ...item,
              field: item.id,
              ...getValueForUI(item.fieldType, file),
            })),
          ];
        }

        return [
          ...acc,
          {
            ...item,
            field: item.id,
            ...getValueForUI(item.fieldType, item.value),
          },
        ];
      }, [])
    : Object.entries(fields).reduce(
        (acc, [key, { displayName, fieldType, value, valueSummary }]) => {
          if (valueSummary) {
            return [
              ...acc,
              {
                field: key,
                description: displayName,
                fieldType,
                valueSummary,
              },
            ];
          }
          if (fieldType === FIELD_TYPES.Files.type) {
            return [
              ...acc,
              ...value.map((file) => ({
                field: key,
                description: displayName,
                fieldType,
                ...getValueForUI(fieldType, file),
              })),
            ];
          }
          return [
            ...acc,
            {
              field: key,
              description: displayName,
              fieldType,
              ...getValueForUI(fieldType, value),
            },
          ];
        },
        []
      );

export const getValueForApi = (value, field) => {
  if (field.fieldType === FIELD_TYPES.DynamicTags.type) {
    return value.map((item) => item.id || item);
  }
  if (
    field.fieldType === FIELD_TYPES.Relationship.type ||
    field.fieldType === FIELD_TYPES.TeamSelector.type
  ) {
    if (Array.isArray(value)) {
      return value.map((item) => item.id || item);
    }
    return value ? [value.id || value] : null;
  }
  return value;
};

export const ownerToOption = (owner) => ({
  value: owner.id,
  label: `${owner.fullName || `${owner.firstName} ${owner.lastName}`}${
    owner.email ? ` (${owner.email})` : ''
  }`,
  ...owner,
});

const getRelationFieldsWithRelationToCurrentObject = (objectId, fields) =>
  fields.filter(
    (f) =>
      f.fieldType === FIELD_TYPES.Relationship.type &&
      f.relation.relatedObject === objectId
  );

function* getRecords({ payload }) {
  let hasQuickFilters = false;
  let fetchedCriteria = null;

  try {
    yield put(getRecordsStart(payload));

    const { onlyUpdateCounts } = payload;

    const pageConfig = yield select((s) => s.recordsPage.pageConfig);

    const model = payload.model
      ? payload.model
      : yield select((s) => s.recordsPage.model);

    // grab the custom fieldIds
    const fields = yield select((s) => s.recordsPage.fields);
    const allFields = yield select((s) => s.recordsPage.allFields);

    const fieldIds = getCurrentCustomFieldIds(
      fields,
      pageConfig.columns,
      model.meta?.defaultColumns
    );

    const { criteria, isComplexFilters } = buildPageFilterQuery(
      pageConfig.filterObject,
      pageConfig.quickFilters,
      allFields,
      model,
      pageConfig.quickFilterSettings
    );

    fetchedCriteria = criteria;

    hasQuickFilters = isComplexFilters;

    const body = {
      search: pageConfig.search,
      ordering: camelToSnakeCase(pageConfig?.sort),
      number: onlyUpdateCounts ? 1 : pageConfig.page,
      size: onlyUpdateCounts ? 1 : pageConfig.size,
      modelName: model.name,
      criteria,
      fieldIds: onlyUpdateCounts ? [] : fieldIds,
    };

    const params = {
      search: pageConfig.search,
      ordering: camelToSnakeCase(pageConfig?.sort),
      page: onlyUpdateCounts ? 1 : pageConfig.page,
      pageSize: onlyUpdateCounts ? 1 : pageConfig.size,
      groupId:
        !pageConfig?.filterObject?.query && pageConfig?.group
          ? pageConfig.group
          : null,
    };

    // TODO: it can be removed once backend add objectClass field
    const updatedObject = { ...model, objectClass: 'customObjects' };

    const [result] = yield all([
      call(
        FieldService.getModelRecordsCancelable,
        {
          model: updatedObject,
          params,
          body,
        },
        criteria
          ? {
              headers: {
                [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
              },
            }
          : {}
      ),
      payload.updatePageConfig &&
        call(
          TeamMemberService.updateCustomObjectRecordsListPageConfig,
          model.id,
          collectRecordListPageConfig(pageConfig, payload.updatePageConfigKey)
        ),
    ]);

    const records = onlyUpdateCounts
      ? []
      : result.results.map((item) => {
          const fields = getFieldsForUI(item.fields);
          return {
            ...item,
            owner: item.owner ? ownerToOption(item.owner) : null,
            stage: item.stage
              ? {
                  value: item.stage.id,
                  label: item.stage.name,
                }
              : null,
            fields,
          };
        });

    const hasNonFilterErrors = Boolean(result?.errors?.length);

    yield put(
      getRecordsSuccess({
        records: hasNonFilterErrors ? [] : records,
        recordsCount: hasNonFilterErrors ? 0 : result.count,
        refreshPage: payload.refreshPage,
        errors: null,
        fetchedCriteria,
      })
    );
  } catch (error) {
    if (anyFilterErrors(error?.response, hasQuickFilters)) {
      const { count, results, errors } = getFilterErrors(
        error?.response?.data?.errors,
        hasQuickFilters
      );

      yield put(
        getRecordsSuccess({
          records: results,
          recordsCount: count,
          refreshPage: payload.refreshPage,
          errors,
          fetchedCriteria,
        })
      );
    } else {
      yield put(getRecordsFail(error));
    }
  } finally {
    yield put(getRecordsFinish());
  }
}

function* getBoardData({ payload }) {
  let boardSettings;
  let hasQuickFilters = false;

  try {
    yield put(getBoardDataStart(payload));
    const model = yield select((s) => s.recordsPage.model);
    const pageConfig = yield select((s) => s.recordsPage.pageConfig);

    // grab the custom fieldIds
    const fields = yield select((s) => s.recordsPage.fields);
    const allFields = yield select((s) => s.recordsPage.allFields);
    const fieldIds = getCurrentCustomFieldIds(
      fields,
      pageConfig.columns,
      model.meta?.defaultColumns
    );

    const params = {
      search: pageConfig.search,
    };

    const { criteria, isComplexFilters } = buildPageFilterQuery(
      pageConfig.filterObject,
      pageConfig.quickFilters,
      allFields,
      model,
      pageConfig.quickFilterSettings
    );

    hasQuickFilters = isComplexFilters;

    const body = {
      criteria,
      fieldIds,
    };

    [boardSettings] = yield all([
      call(PipelineService.getBoardSettings, payload.id),
    ]);

    const bodyForCounts = {
      search: pageConfig.search,
      ordering: camelToSnakeCase(pageConfig?.sort),
      number: 1,
      size: 1,
      modelName: model.name,
      criteria,
      fieldIds: [],
    };

    const paramsForCounts = {
      search: pageConfig.search,
      ordering: camelToSnakeCase(pageConfig?.sort),
      page: 1,
      pageSize: 1,
      groupId:
        !pageConfig?.filterObject?.query && pageConfig?.group
          ? pageConfig.group
          : null,
    };

    // TODO: it can be removed once backend add objectClass field
    const updatedObject = {
      ...model,
      ...payload?.externalModal,
      objectClass: 'customObjects',
    };

    const options = criteria
      ? {
          headers: {
            [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
          },
        }
      : {};

    const [boardData, resultForCounts] = yield all([
      call(
        PipelineService.getBoardDataCancelable,
        {
          id: payload.id,
          params,
          body,
        },
        options
      ),
      call(
        FieldService.getModelRecordsCancelable,
        {
          model: updatedObject,
          params: paramsForCounts,
          body: bodyForCounts,
        },
        options
      ),
    ]);

    const hasNonFilterErrors = Boolean(boardData?.errors?.length);

    if (payload.updatePageConfig) {
      yield call(
        TeamMemberService.updateCustomObjectRecordsListPageConfig,
        model.id,
        collectRecordListPageConfig(pageConfig, payload.updatePageConfigKey)
      );
    }

    yield put(
      getBoardDataSuccess({
        boardData: boardData.stages.map((stage) => ({
          id: stage.id,
          name: stage.name,
          hasMorePages: hasNonFilterErrors ? null : Boolean(stage.next),
          commerceValue: stage.commerceValue,
          page: 1,
          isChecked: false,
          summary: hasNonFilterErrors ? 0 : stage.summary,
          secondarySummary: hasNonFilterErrors ? null : stage.secondarySummary,
          status: hasNonFilterErrors ? null : stage.status,
          cards: hasNonFilterErrors
            ? []
            : stage.results.map((card) => ({
                ...card,
                owner: card.owner ? ownerToOption(card.owner) : null,
                isSelected: false,
                fields: getFieldsForUI(card.fields),
                entityValue: card.entityValue?.amount,
                stage: {
                  value: stage.id,
                  label: stage.name,
                },
              })),
        })),
        boardSettings,
        recordsCount: hasNonFilterErrors ? 0 : resultForCounts.count,
      })
    );
  } catch (error) {
    if (boardSettings && anyFilterErrors(error?.response, hasQuickFilters)) {
      const errorBoardDataStages = error.response.data?.stages || [];
      const { errors, count } = getFilterErrors(
        error?.response?.data?.errors,
        hasQuickFilters
      );

      yield put(
        getBoardDataSuccess({
          boardData: errorBoardDataStages.map((stage) => ({
            id: stage.id,
            name: stage.name,
            hasMorePages: stage.next,
            commerceValue: stage.commerceValue,
            page: 1,
            isChecked: false,
            summary: stage.summary,
            secondarySummary: stage.secondarySummary,
            status: stage.status,
            cards: stage.results, // empty array
          })),
          boardSettings,
          recordsCount: count || 0,
          errors,
        })
      );
    } else {
      yield put(getBoardDataFail(error));
    }
  }
}

function* resetFilterGroup({ payload }) {
  const { groupId, history } = payload;

  const model = yield select((s) => s.recordsPage.model);
  const pageConfig = yield select((s) => s.recordsPage.pageConfig);

  setSearchParams(
    history,
    {
      group: null,
    },
    { method: 'replace' }
  );

  let reason;

  try {
    yield call(FilterGroupsService.getFilterGroup, model.id, groupId);
    reason = 'hidden';
  } catch (error) {
    reason = error?.response?.status === 404 ? 'deleted' : 'not_allowed';
  }
  yield all([
    put(resetFilterGroupSuccess({ reason })),
    call(
      TeamMemberService.updateCustomObjectRecordsListPageConfig,
      model.id,
      collectRecordListPageConfig(
        { ...pageConfig, group: null, filterObject: {} },
        RECORD_LIST_CONFIG_KEYS.LAYOUT
      )
    ),
  ]);
}

function* buildPage({ payload }) {
  try {
    const { customObjectId: id, page, history } = payload;

    const [model, groupsResponse] = yield all([
      call(FieldService.getModel, { id }),
      call(
        FilterGroupsService.getFilterGroups,
        id,
        GET_FILTER_GROUPS_PATHNAMES.VISIBLE
      ),
    ]);

    const [{ categorizedFields, fields }, pageResponse] = yield all([
      call(CustomObjectsService.getCategorizedModelFields, model.id),
      call(
        TeamMemberService.getCustomObjectRecordsListPageConfig,
        model.id,
        defaultRecordsConfig
      ),
    ]);

    const quickFilterSettings = yield call(
      enrichAndRepairQuickFilterSettings,
      pageResponse.quickFilterSettings,
      fields
    );

    yield put(
      buildPageComplete({
        model,
        categorizedFields,
        fields,
        groupsResponse,
        pageResponse: {
          ...pageResponse,
          quickFilterSettings: quickFilterSettings.filter(Boolean),
        },
      })
    );

    const access = yield select((s) => s.authentication.access);
    const vars = Object.fromEntries(getFilterVariables(model, { access }));

    const filterMetaData = yield select(selectFilterMetadataDefinition);

    if (page.queryFilter) {
      const data = yield call(
        loadFilterFromUrlParam,
        filterMetaData,
        page.queryFilter,
        vars
      );
      yield put(clearFilterSearch());
      yield put(
        setSavedFilter({ config: data, and: false, modelId: model?.id })
      );
      yield put(
        updatePageConfig({
          filterObject: { and: false, query: buildFilterSets(data) },
          page: 1,
        })
      );
    } else if (page.group) {
      const group = groupsResponse.find((g) => g.id === page.group);
      if (group) {
        const data = yield call(
          loadSavedFilter,
          filterMetaData,
          camelToSnakeCaseKeys(group.config),
          vars
        );
        yield put(
          updatePageConfig({
            filterObject: {
              and: group.config.and,
              query: buildFilterSets(data),
            },
          })
        );
        yield put(
          setSavedFilter({
            config: data,
            and: group.config.and,
            modelId: model?.id,
          })
        );
      } else {
        // filter group can be deleted or hidden so we need to clear it from filter and page config
        yield resetFilterGroup({
          payload: { groupId: pageResponse.group, history },
        });
      }
    } else if (!isEmpty(pageResponse.filterObject)) {
      const group =
        pageResponse.group &&
        groupsResponse.find((g) => g.id === pageResponse.group);
      if ((pageResponse.group && group) || !pageResponse.group) {
        const data = yield call(
          loadSavedFilter,
          filterMetaData,
          camelToSnakeCaseKeys(pageResponse.filterObject),
          vars
        );
        yield put(
          setSavedFilter({
            config: data,
            and: pageResponse.filterObject.and,
            modelId: model?.id,
          })
        );
      } else {
        // filter group can be deleted or hidden so we need to clear it from filter and page config
        yield resetFilterGroup({
          payload: { groupId: page.group, history },
        });
      }
    } else {
      yield put(
        setSavedFilter({
          config: { filters: null },
          and: null,
          modelId: model?.id,
        })
      );
    }

    const pageConfig = yield select((s) => s.recordsPage.pageConfig);

    if (page.queryFilter) {
      if (pageConfig.viewVariant === VIEW_VARIANTS.TABLE) {
        yield getRecords({
          payload: { updatePageConfig: false },
        });
      }

      if (pageConfig.viewVariant === VIEW_VARIANTS.BOARD) {
        yield getBoardData({
          payload: {
            id: model.id,
            updatePageConfig: true,
            updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
          },
        });
      }
    }
    yield put(buildPageFinish());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* setPageConfig({ payload }) {
  try {
    const model = yield select((s) => s.recordsPage.model);
    const pageConfig = yield select((s) => s.recordsPage.pageConfig);
    yield call(
      TeamMemberService.updateCustomObjectRecordsListPageConfig,
      model.id,
      collectRecordListPageConfig(pageConfig, payload?.updatePageConfigKey)
    );
    yield put(setPageConfigSuccess());
  } catch (error) {
    yield put(setPageConfigFail(error));
  }
}

function* updateRelatedRecords({ payload: { record, patch, fields } }) {
  // In the creation or updating process, we should update all records
  // which have a relation to the current record
  const model = yield select((s) => s.recordsPage.model);
  const boardData = yield select((s) => s.recordsPage.boardData);
  const tableRecords = yield select((s) => s.recordsPage.records);
  const pageConfig = yield select((s) => s.recordsPage.pageConfig);

  // get relation fields that have relation to the current CO
  const relationFieldsWithRelationToCurrentObject =
    getRelationFieldsWithRelationToCurrentObject(model.id, fields);

  // if the current CO has relationship fields with relation to itself
  // we should update the related records
  if (relationFieldsWithRelationToCurrentObject.length) {
    const isUpdate = Boolean(patch);
    const relationFieldsIds = relationFieldsWithRelationToCurrentObject.map(
      ({ id }) => id
    );
    const hasUpdatedRelationFields =
      isUpdate &&
      !!patch.fields &&
      patch.fields.some((item) => relationFieldsIds.includes(item.id));

    if (!isUpdate || (isUpdate && hasUpdatedRelationFields)) {
      // we collect all relation records from current record values
      const relationValues = relationFieldsIds.reduce((acc, id) => {
        const currentValues =
          record.fields.find((f) => f.field === id)?.value || [];

        if (currentValues.length) {
          return [...acc, ...currentValues.map(({ id }) => id)];
        }

        return acc;
      }, []);

      // additionally, we should collect related records which have relation
      // to the current record in the table and board.
      // We need them because we can remove relation or remove the current record
      // as a result we need to update values where we had a relation to the current record
      let relatedRecords = tableRecords.reduce((acc, item) => {
        const hasRelationsWithTargetRecord = item.fields.some((el) => {
          const hasRelationField = relationFieldsIds.includes(el.field);
          return hasRelationField
            ? el.value.some((v) => v.id === record.id)
            : false;
        });

        if (hasRelationsWithTargetRecord) {
          return [...acc, item.id];
        }

        return acc;
      }, []);

      if (isPipelineObject(model)) {
        const relatedRecordsOnBoard = boardData.stages.reduce((acc, item) => {
          item.cards.forEach((card) => {
            if (Array.isArray(card.fields) && card.fields.length) {
              const hasCurrentRecordAsValue = card.fields.some((el) => {
                const hasRelationField = relationFieldsIds.includes(el.field);

                return hasRelationField
                  ? el.value.some((v) => v.id === record.id)
                  : false;
              });

              if (hasCurrentRecordAsValue) {
                acc = [...acc, card.id];
              }
            }
          });

          return acc;
        }, []);

        relatedRecords = [...relatedRecords, ...relatedRecordsOnBoard];
      }

      // if we have duplicates of ID, we get rid of them
      const recordsIdsNeedUpdate = [
        ...new Set([...relationValues, ...relatedRecords]),
      ];

      // if we have records that we need to update, we make a request
      if (recordsIdsNeedUpdate.length) {
        try {
          const updatedRecords = yield all(
            recordsIdsNeedUpdate.map((id) =>
              call(
                isPipelineObject(model)
                  ? PipelineService.getPipelineRecord
                  : CustomObjectsService.getCustomObjectRecord,
                {
                  id,
                  objectId: model.id,
                }
              )
            )
          );
          yield put(updateRecords({ records: updatedRecords }));
        } catch (error) {
          yield put(
            setToast({
              variant: toastVariant.FAILURE,
              message: i18nInstance.t(
                'The related entity records have not been successfully updated.'
              ),
            })
          );
          yield put(updateRelatedRecordsFail(error));
        }
      }
    }
  }

  if (
    pageConfig.viewVariant === VIEW_VARIANTS.BOARD &&
    isPipelineObject(model)
  ) {
    // we need to update the data of the stages

    const fields = yield select((s) => s.recordsPage.fields);
    const fieldIds = getCurrentCustomFieldIds(
      fields,
      pageConfig.columns,
      model.meta?.defaultColumns
    );

    const params = {
      search: pageConfig.search,
    };

    const { criteria } = buildPageFilterQuery(
      pageConfig.filterObject,
      pageConfig.quickFilters,
      fields,
      model,
      pageConfig.quickFilterSettings
    );

    const body = {
      criteria,
      fieldIds,
    };

    const boardData = yield call(
      PipelineService.getBoardDataCancelable,
      {
        id: model.id,
        params,
        body,
      },
      criteria
        ? {
            headers: {
              [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
            },
          }
        : {}
    );

    const haveFilterErrors = Boolean(boardData?.errors?.length);

    // if record is created and record's stage is outside filters or search
    // we need to temporary show the record in column and update metrics
    const settings = yield select((s) => s.recordsPage.boardData.settings);
    const tempMetrics =
      !patch &&
      record.stage?.value &&
      !haveFilterErrors &&
      !boardData.stages.find((stage) => stage.id === record.stage.value)
        ?.results?.length
        ? {
            [record.stage.value]: {
              id: record.stage.value,
              commerceValue: getMetricValue({
                item: record,
                settings: { summaryMetric: SUMMARY_VALUES.totalValue },
              }),
              summary: getMetricValue({
                item: record,
                settings,
              }),
              secondarySummary: getSecondaryMetricValue({
                item: record,
                settings,
              }),
            },
          }
        : {};

    yield put(
      updateBoardMetrics({
        boardStagesMetrics: boardData.stages.map(
          (stage) =>
            tempMetrics[stage.id] || {
              id: stage.id,
              commerceValue: stage.commerceValue,
              summary: haveFilterErrors ? 0 : stage.summary,
              secondarySummary: haveFilterErrors
                ? null
                : stage.secondarySummary,
            }
        ),
      })
    );
  }
}

function* archiveRecord({ payload }) {
  const model = yield select((s) => s.recordsPage.model);
  const pageConfig = yield select((s) => s.recordsPage.pageConfig);
  try {
    yield call(FieldService.archiveCustomObjectRecord, payload, { for: model });
    yield put(
      setToast({
        variant: toastVariant.SUCCESS,
        message: i18nInstance.t(
          'The entity record has been successfully archived.'
        ),
      })
    );

    yield put(setCountsNeedToLoad());

    if (pageConfig.viewVariant === VIEW_VARIANTS.TABLE) {
      yield getRecords({
        payload: { updatePageConfig: false, model },
      });
    }

    if (
      pageConfig.viewVariant === VIEW_VARIANTS.BOARD &&
      isPipelineObject(model)
    ) {
      const fields = yield select((s) => s.recordsPage.fields);

      yield put(
        archiveRecordFromBoard({
          recordId: payload,
        })
      );

      yield updateRelatedRecords({
        payload: { record: { id: payload, fields: [] }, fields },
      });
    }

    yield put(archiveRecordSuccess({ id: payload }));
  } catch (error) {
    yield put(
      setToast({
        variant: toastVariant.FAILURE,
        message: i18nInstance.t(
          'The entity record has not been successfully archived.'
        ),
      })
    );
    yield put(archiveRecordFail(error));
  }
}

function* getLeadSourceData({ payload }) {
  try {
    const params = { type: payload };
    const [sources, campaigns, mediums, terms, content] = yield all([
      call(LeadSourceService.getLeadSourceSources, params),
      call(LeadSourceService.getLeadSourceCampaigns, params),
      call(LeadSourceService.getLeadSourceMediums, params),
      call(LeadSourceService.getLeadSourceTerms, params),
      call(LeadSourceService.getLeadSourceContents, params),
    ]);

    yield put(
      setLeadSourceAdditionalData({
        sources,
        campaigns,
        mediums,
        terms,
        content,
      })
    );
  } catch (error) {
    yield put(getLeadSourceAdditionalDataFail(error));
  }
}

const requestsMatch = {
  sources: LeadSourceService.getLeadSourceSources,
  campaigns: LeadSourceService.getLeadSourceCampaigns,
  mediums: LeadSourceService.getLeadSourceMediums,
  terms: LeadSourceService.getLeadSourceTerms,
  content: LeadSourceService.getLeadSourceContents,
};

function* getLeadSourceEntities({ payload }) {
  try {
    const { type, key } = payload;
    const data = yield call(requestsMatch[key], { type });
    yield put(setLeadSourceDataByKey({ key, data }));
  } catch (error) {
    yield put(getLeadSourceEntitiesFail(error));
  }
}

function* getRecordsInStage({ payload }) {
  const model = yield select((s) => s.recordsPage.model);
  const pageConfig = yield select((s) => s.recordsPage.pageConfig);
  const fields = yield select((s) => s.recordsPage.fields);
  const { stage, page } = payload;

  const { criteria } = buildPageFilterQuery(
    pageConfig.filterObject,
    pageConfig.quickFilters,
    fields,
    model,
    pageConfig.quickFilterSettings
  );

  const data = {
    stageId: stage.id,
    modelId: model.id,
    params: {
      page,
      search: pageConfig.search,
      skipErrorBoundary: true, // we can silently fail if we get a 404
    },
    body: {
      criteria,
    },
  };

  try {
    const result = yield call(
      PipelineService.getRecordsInStage,
      data,
      criteria
        ? {
            headers: {
              [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
            },
          }
        : {}
    );

    yield put(
      getRecordsInStageSuccess({
        stage: {
          id: result.id,
          hasMorePages: Boolean(result.next),
          page,
          cards: result.results.map((card) => ({
            ...card,
            isSelected: false,
            fields: getFieldsForUI(card.fields),
            entityValue: card.entityValue?.amount,
            stage: {
              value: stage.id,
              label: stage.name,
            },
            closeDate: [
              STAGE_STATUSES.won,
              STAGE_STATUSES.lost,
              STAGE_STATUSES.disqualified,
            ].includes(result.status)
              ? card.actualCloseDate
              : card.estimatedCloseDate,
          })),
        },
      })
    );
  } catch (error) {
    yield put(getRecordsInStageFail(error));
  }
}

function* createOrUpdateGroup({ payload }) {
  try {
    const { history, filterGroup, isPatch } = payload;
    const pageConfig = yield select((s) => s.recordsPage.pageConfig);
    const model = yield select((s) => s.recordsPage.model);

    yield setSearchParams(history, {
      group: filterGroup.id,
      page: null,
    });

    yield put(
      createOrUpdateGroupSuccess({
        filterGroup,
        isPatch,
      })
    );
    if (pageConfig.viewVariant === VIEW_VARIANTS.TABLE) {
      yield getRecords({
        payload: {
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        },
      });
    }

    if (
      pageConfig.viewVariant === VIEW_VARIANTS.BOARD &&
      isPipelineObject(model)
    ) {
      yield getBoardData({
        payload: {
          id: model.id,
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        },
      });
    }

    if (pageConfig.viewVariant === VIEW_VARIANTS.CHARTS) {
      yield getRecords({
        payload: {
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
          onlyUpdateCounts: true,
        },
      });
    }
  } catch (error) {
    yield put(createOrUpdateGroupFail(error));
  }
}

function* removeGroup({ payload }) {
  const { history, objectId } = payload;
  try {
    const pageConfig = yield select((s) => s.recordsPage.pageConfig);
    const model = yield select((s) => s.recordsPage.model);
    yield call(
      FilterGroupsService.deleteFilterGroup,
      objectId,
      pageConfig.group
    );
    yield setSearchParams(history, {
      group: null,
      page: null,
    });
    yield put(removeGroupSuccess({ id: pageConfig.group }));

    if (pageConfig.viewVariant === VIEW_VARIANTS.TABLE) {
      yield getRecords({
        payload: {
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        },
      });
    }

    if (
      pageConfig.viewVariant === VIEW_VARIANTS.BOARD &&
      isPipelineObject(model)
    ) {
      yield getBoardData({
        payload: {
          id: model.id,
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        },
      });
    }

    if (pageConfig.viewVariant === VIEW_VARIANTS.CHARTS) {
      yield getRecords({
        payload: {
          updatePageConfig: true,
          onlyUpdateCounts: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        },
      });
    }
  } catch (error) {
    yield put(removeGroupFail(error));
  }
}

function* changeGroup({ payload }) {
  const { id } = payload;
  const groups = yield select((s) => s.recordsPage.groups);
  const model = yield select((s) => s.recordsPage.model);
  const pageConfig = yield select((s) => s.recordsPage.pageConfig);
  const group = groups.find((g) => g.id === id);
  const access = yield select((s) => s.authentication.access);
  const vars = Object.fromEntries(getFilterVariables(model, { access }));
  yield put(setGroup(group ?? {}));
  yield put(updatePageConfig({ group: id, page: 1 }));
  yield put(getRecordsStart());
  if (group && group.id) {
    const filterMetaData = yield select(selectFilterMetadataDefinition);

    const data = yield call(
      loadSavedFilter,
      filterMetaData,
      camelToSnakeCaseKeys(group.config),
      vars
    );
    yield put(
      setSavedFilter({
        config: data,
        and: group.config.and,
        modelId: model?.id,
      })
    );
  } else {
    yield put(setSavedFilter({ config: null, and: false, modelId: model?.id }));
    yield put(updatePageConfig({ filterObject: {} }));
  }

  if (pageConfig.viewVariant === VIEW_VARIANTS.TABLE) {
    yield getRecords({
      payload: {
        updatePageConfig: true,
        updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
      },
    });
  }

  if (
    pageConfig.viewVariant === VIEW_VARIANTS.BOARD &&
    isPipelineObject(model)
  ) {
    yield getBoardData({
      payload: {
        id: model.id,
        updatePageConfig: true,
        updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
      },
    });
  }

  if (pageConfig.viewVariant === VIEW_VARIANTS.CHARTS) {
    yield getRecords({
      payload: {
        updatePageConfig: true,
        onlyUpdateCounts: true,
        updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
      },
    });
  }
}

function* moveRecord({ payload }) {
  const model = yield select((s) => s.recordsPage.model);
  const boardData = yield select((s) => s.recordsPage.boardData);
  const pageConfig = yield select((s) => s.recordsPage.pageConfig);
  let recordMatchesFilters = true;
  let movedRecord = null;
  try {
    const {
      stagedCards,
      stageId,
      recordIds,
      index,
      card,
      fromStageId,
      skipRequest,
      updatedColumns,
      moveToPosition,
      changeStage,
    } = payload;

    const [recordId] = recordIds;
    yield put(moveRecordStart({ id: card.id, updatedColumns }));
    let moveResponse = null;

    if (!skipRequest) {
      const fields = yield select((s) => s.recordsPage.fields);

      const { criteria } = buildPageFilterQuery(
        pageConfig.filterObject,
        pageConfig.quickFilters,
        fields,
        model,
        pageConfig.quickFilterSettings
      );
      moveResponse = yield call(
        PipelineService.moveRecord,
        {
          id: model.id,
          payload: {
            stageId,
            recordIds,
            ...(changeStage
              ? null
              : getPayloadProperties(
                  index,
                  stagedCards,
                  recordId,
                  moveToPosition
                )),
            ui: {
              groupId: pageConfig.group,
              search: pageConfig.search,
              filters: criteria,
            },
          },
        },
        criteria
          ? {
              headers: {
                [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
              },
            }
          : {}
      );
    }

    let updatedStages;
    // move to top and dnd in the same stage
    if (Number.isInteger(index) && !fromStageId) {
      updatedStages = boardData.stages.map((item) => {
        if (stageId === item.id) {
          const updatedCards = item.cards.filter((el) => el.id !== recordId);
          updatedCards.splice(index, 0, card);
          return {
            ...item,
            cards: updatedCards,
          };
        }

        return item;
      });

      yield put(
        moveRecordSuccess({
          updatedStages,
          id: card.id,
          useOldState: !!updatedColumns,
          fromStageId,
          stageId,
        })
      );
    } else if (!index && !fromStageId) {
      // move to bottom

      updatedStages = boardData.stages.map((item) => {
        if (stageId === item.id) {
          const updatedCards = item.cards.filter((el) => el.id !== recordId);

          // add on the end if there is no more cards to load
          return {
            ...item,
            cards: item.hasMorePages ? updatedCards : [...updatedCards, card],
          };
        }

        return item;
      });
    } else if (fromStageId) {
      // dnd in another stage or change stage

      const movedCard = moveResponse.records.find(({ id }) => id === card.id);

      updatedStages = boardData.stages.map((stage) => {
        if (fromStageId === stage.id) {
          // remove the card from the old stage and update the stage

          const updatedStage = moveResponse.updatedStages.find(
            ({ id }) => id === stage.id
          );

          return {
            ...stage,
            ...updatedStage,
            cards: stage.cards.filter((el) => el.id !== recordId),
          };
        }

        if (stageId === stage.id) {
          const updatedStage = moveResponse.updatedStages.find(
            ({ id }) => id === stage.id
          );
          const updatedCards = [...stage.cards];
          movedRecord = {
            ...card,
            stage: {
              value: stage.id,
              label: stage.name,
            },
            timeOnStage: movedCard?.timeOnStage,
            actualCloseDate: movedCard?.actualCloseDate,
          };

          recordMatchesFilters = movedCard?.recordMatchesFilters;
          if (movedCard && recordMatchesFilters) {
            if (index !== undefined) {
              // add it in place
              updatedCards.splice(index, 0, movedRecord);
            } else if (!stage.hasMorePages) {
              // add to the bottom if there are no more pages
              updatedCards.push(movedRecord);
            }
          }

          return {
            ...stage,
            ...updatedStage,
            cards: updatedCards,
          };
        }

        return stage;
      });
    }

    yield put(
      moveRecordSuccess({
        updatedStages,
        id: card.id,
        useOldState: !!updatedColumns,
        fromStageId,
        stageId,
        recordMatchesFilters,
        movedRecord,
      })
    );
  } catch (error) {
    yield put(moveRecordFail({ id: payload.card.id, error }));
  }
}

function* getFullListOfCustomObjects() {
  try {
    const data = yield call(CustomObjectsService.getCustomObjectList, {
      search: '',
      ordering: '',
      size: 10000,
      customOnly: false,
    });
    yield put(getFullListOfCustomObjectsSuccess(data.results));
  } catch (error) {
    yield put(getFullListOfCustomObjectsFail(error));
  }
}

function* getCounts() {
  try {
    const pageConfig = yield select((s) => s.recordsPage.pageConfig);
    const model = yield select((s) => s.recordsPage.model);

    // TODO: it can be removed once backend add objectClass field
    const updatedObject = { ...model, objectClass: 'customObjects' };
    const [allCount, groupCount] = yield all([
      call(FieldService.getModelRecordsCount, {
        model: updatedObject,
        params: {},
        body: { modelName: model.name },
      }),
      call(
        FieldService.getModelRecordsCount,
        {
          model: updatedObject,
          params: {
            groupId: pageConfig?.group,
          },
          body: {
            modelName: model.name,
          },
        },
        pageConfig.filterObject.query
          ? {
              headers: {
                [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
              },
            }
          : {}
      ),
    ]);

    yield put(
      getCountsSuccess({
        allCount,
        groupCount: pageConfig.group ? groupCount : allCount,
      })
    );
  } catch (error) {
    yield put(getCountsFail(error));
  }
}

function* recordsPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield takeLatest(getRecordsAction.type, getRecords);
  yield debounce(DEFAULT_INPUT_DELAY, getRecordsBySearch.type, getRecords);
  yield takeLatest(getCountsAction.type, getCounts);
  yield debounce(SET_CONFIG_DELAY, setPageConfigAction.type, setPageConfig);
  yield takeLeading(updateRecord.type, updateRelatedRecords);
  yield takeLeading(addNewRecord.type, updateRelatedRecords);
  yield takeLatest(archiveRecordAction.type, archiveRecord);
  yield takeLatest(getLeadSourceAdditionalData.type, getLeadSourceData);
  yield takeLatest(getLeadSourceEntitiesAction.type, getLeadSourceEntities);
  yield takeLatest(getRecordsInStageAction.type, getRecordsInStage);
  yield takeLatest(getBoardDataAction.type, getBoardData);
  yield debounce(DEFAULT_INPUT_DELAY, getBoardDataBySearch.type, getBoardData);
  yield takeLatest(createOrUpdateGroupAction.type, createOrUpdateGroup);
  yield takeLatest(removeGroupAction.type, removeGroup);
  yield takeEvery(moveRecordInStage.type, moveRecord);
  yield takeLatest(
    getFullListOfCustomObjectsAction.type,
    getFullListOfCustomObjects
  );
  yield takeLatest(changeGroupAction.type, changeGroup);
}

export default recordsPage;
