import styled from '@emotion/styled';
import FileInputWithImage from '__components/Kizen/FileInputWithImage';
import { KizenTypography } from '__app/typography';
import { appBackground } from '__app/colors';
import Switch from '__components/Kizen/Switch';

export const StyledTestExplanation = styled(KizenTypography)`
  background-color: ${appBackground};
  padding: 10px;
  & strong {
    font-size: inherit;
  }
`;

export const StyledFilePicker = styled(FileInputWithImage)`
  text-align: start;
  & label {
    margin-top: 15px;
  }
  & input[type='file'] + span {
    width: 100%;
  }
`;

export const StyledSwitch = styled(Switch)`
  margin-top: 15px;
`;
