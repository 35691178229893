import React, {
  useState,
  useRef,
  useCallback,
  forwardRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { BasicRichTextEditor, defaultFonts } from 'components/WYSIWYG';
import PropTypes from 'prop-types';

import { MaybeInputControl } from '../InputControl';
import { useQueryClient } from 'react-query';
import teammembers from 'components/WYSIWYG/CommentEditor/teammembers';
import { CommentMention } from 'components/WYSIWYG/CommentEditor/extensions/commentMention';

export { parseMentions } from './utils';

export const useNotesRichTextEditor = (intialState = '') => {
  const editorRef = useRef(null);
  const [state, setState] = useState(intialState);

  const handleNoteChange = useCallback(
    ({ editor }) => {
      setState(editorRef.current.isEmpty() ? '' : editor.getHTML());
    },
    [setState]
  );

  const clearNote = useCallback(() => {
    setState('');
    if (editorRef?.current) {
      const { editor } = editorRef.current;
      editor.commands.clearContent();
    }
  }, [setState]);

  return { notes: state, handleNoteChange, clearNote, editorRef };
};

export const NotesRichTextEditor = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const defaultPlaceholder = t('Enter Note');
  const {
    value,
    onChange,
    autoFocus,
    placeholder: placeHolderProp,
    mergeFields,
    fitToContent,
    enableTextAlign,
    enableResize,
    enableAI,
    bottomBarNode,
    initialHeight = 129,
    onFocus,
    onBlur,
  } = props;

  const placeholder = placeHolderProp ? placeHolderProp : defaultPlaceholder;
  const queryClient = useQueryClient();

  const mentionConfig = useMemo(
    () => ({
      configure: {
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: teammembers(queryClient),
      },
      extension: CommentMention,
    }),
    [queryClient]
  );

  return (
    <MaybeInputControl variant="outline" forInput {...props}>
      <BasicRichTextEditor
        autofocus={autoFocus}
        initialValue={value}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        fitToContent={fitToContent}
        enableAI={enableAI}
        enableMergeFields={mergeFields}
        enableTextAlign={enableTextAlign}
        initialHeight={initialHeight}
        enableResize={enableResize}
        defaultFonts={defaultFonts}
        bottomBarNode={bottomBarNode}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        mentionConfig={mentionConfig}
        {...props}
      />
    </MaybeInputControl>
  );
});

NotesRichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  mergeFields: PropTypes.bool,
  enableTextAlign: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

NotesRichTextEditor.defaultProps = {
  placeholder: null,
  mergeFields: false,
  enableTextAlign: false,
  autoFocus: false,
};
