import React from 'react';
import * as PropTypes from 'prop-types';
import KizenTypography from 'app/kizentypo';
// import { useAutomationsDispatch } from 'pages/AutomationEngine/store/react';
// import { actions } from 'pages/AutomationEngine/store';
import StepCard from '../StepCard';

export const content = () => {
  return 'Unknown';
};

export default function ErrorCard({ type, step, ...others }) {
  // const dispatch = useAutomationsDispatch();
  return (
    <StepCard {...others}>
      <KizenTypography as="span">{content({ step })}</KizenTypography>
    </StepCard>
  );
}

ErrorCard.propTypes = {
  type: PropTypes.string.isRequired,
  step: PropTypes.object.isRequired,
};
