import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton, grayScale, shadowLight } from 'app/colors';
import { breakpoints, FILTER_MENU_Z_INDEX, gutters } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import { CONTENT_MAX_WIDTH } from 'components/Layout/PageContentWidth';
import { navColors } from 'components/Navigation/styles';
import { DEFAULT_ITEM_IDS } from 'pages/Account/pages/Toolbar/constants';

export const CustomNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding-right: 20px;
`;

export const ItemClickContainer = styled.div`
  display: flex;
  cursor: pointer;
  max-width: 200px;

  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  ${({ color, open, childActive }) => css`
    & i {
      color: ${grayScale.dark};
    }
    &:hover > div > i,
    &:hover > p,
    &:focus > div > i,
    &:focus > p {
      color: ${color};
    }

    &.active div > i,
    &.active > p {
      color: ${color};
    }

    ${open || childActive
      ? css`
          & > div > i,
          & > p {
            color: ${color};
          }
        `
      : ''}
  `}
`;

export const CustomNavigationMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0;
  transition: height 0.2s;
  overflow: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'hidden')};
  ${({ expanded, height }) =>
    expanded
      ? css`
          height: ${height}px;
        `
      : css`
          height: 0;
        `}
`;

export const TopLevelItem = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: ${({ mobile }) => (mobile ? 10 : 15)}px 0;
  text-decoration: none;
  max-width: 200px;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
    text-decoration: none;
  }

  & .${DEFAULT_ITEM_IDS.HELP} svg {
    height: 20px;
    width: 20px;
  }

  & i {
    display: flex;
  }

  & i svg {
    margin-left: auto;
  }

  & i.kizen-k {
    width: 12px;
  }

  & i,
  & svg {
    font-size: 14px;
  }

  & .CustomIcon {
    font-size: 14px;
  }

  & .ExpandIcon {
    margin-left: 10px;
    color: ${colorsButton.blue.default};
  }

  &:hover .ExpandIcon {
    color: ${colorsButton.blue.default};
  }

  & .ExpandIcon svg {
    width: 10px;
  }
`;

export const IconContainer = styled.div`
  margin-right: 10px;

  &.${DEFAULT_ITEM_IDS.HELP} {
    padding: 0;
    margin-right: 0;
  }
`;

export const ChildrenContainer = styled.div`
  position: absolute;
  cursor: default;

  ${shadowLight}
  ${({ mobile, offset, shouldShrinkToLeft }) =>
    mobile
      ? css`
          border-top: 1px solid ${grayScale.mediumLight};
          left: 0;
        `
      : offset < 0
        ? css`
            left: -20px;
          `
        : css`
            right: ${shouldShrinkToLeft ? 0 : -20}px;
          `}
  top: 100%;
  width: ${({ collapse }) => (collapse ? 200 : 400)}px;
  background: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 20px;
  row-gap: 10px;
  z-index: 999;
  padding: 10px 15px;
  border-bottom: 1px solid ${grayScale.mediumLight};
  border-left: 1px solid ${grayScale.mediumLight};
  border-right: 1px solid ${grayScale.mediumLight};
  border-radius: 0px 0px 4px 4px;
`;

export const Notice = styled.div`
  padding: 10px 0;
  display: flex;

  ${({ full }) =>
    full
      ? css`
          flex: 0 0 100%;
          max-width: 100%;
        `
      : css`
          flex: 0 0 calc(50% - 10px);
          max-width: calc(50% - 10px);
        `}
`;

export const Child = styled.div`
  padding: 10px 0;
  display: flex;
  cursor: pointer;

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  ${({ full }) =>
    full
      ? css`
          flex: 0 0 100%;
          max-width: 100%;
        `
      : css`
          flex: 0 0 calc(50% - 10px);
          max-width: calc(50% - 10px);
        `}

  &:hover i,
  &:hover p,
  &.active i,
  &.active p {
    color: ${colorsButton.blue.hover};
  }
`;

const pageBreakpoint = breakpoints.lg;

export const Wrapper = styled.div`
  background: white;
  width: 100%;
  border-bottom: 1px solid ${navColors.border};
  & .Navigation {
    margin-left: auto;
    padding-right: 0;
    padding-left: 20px;
    z-index: ${FILTER_MENU_Z_INDEX +
    2}; // high z-index to be above the sticky toolbars
  }

  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    // Desired visual width is 1372 with 20px spacing on left/right.
    // (1412 - 1372) / 2 = 20 = gutters.spacing(4)

    padding: 0 ${gutters.spacing(3)}px 0 ${gutters.spacing(3)}px;
    @media (min-width: ${pageBreakpoint + 1}px) {
      max-width: calc(
        ${CONTENT_MAX_WIDTH} + 2 * ${gutters.spacing(4)}px
      ); // 1372px with 20px spacing on left and right
      padding: 0 ${gutters.spacing(4)}px;
    }
  }
`;

export const MobileWrapper = styled.div`
  background: white;
  width: 100%;
  border-bottom: 1px solid ${navColors.border};

  & .Navigation {
    margin-left: 10px;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;

export const ButtonIcon = styled(Icon)`
  margin-left: auto;
  cursor: pointer;

  font-size: 1.3em;
  color: ${grayScale.mediumDark};

  &:hover {
    color: ${grayScale.mediumDark};
  }
`;

export const LogoWrapper = styled.div`
  width: 30px;
  cursor: pointer;
`;
