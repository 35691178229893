import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toastVariant, useToast } from 'components/ToastProvider';
import UploadDataSection from 'components/Modals/UploadCSVData/sections/UploadData';
import MatchRecordsSection from 'components/Modals/UploadCSVData/sections/MatchRecords';
import FinalizeUploadSection from 'components/Modals/UploadCSVData/sections/FinalizeUpload';
import {
  StyledGenericWizardModalBody,
  StyledUploadDataSection,
  StyledFinalizeUploadSection,
  StyledMatchRecordsSection,
} from 'components/Modals/UploadCSVData/sections/styles';
import {
  NO_UPLOAD,
  getConflictResolution,
  getInitialFinalizeUploadData,
  changeCSVColumnType,
  subscriptionField,
  OVERWRITE,
} from 'components/Modals/UploadCSVData/utilities';
import Button from 'components/Button';
import { FIELD_TYPES } from 'utility/constants';
import BasicModalWithConfirmation from 'components/Modals/presets/BasicModalWithConfirmation';
import styled from '@emotion/styled';
import Loader from 'components/Kizen/Loader';
import TeamMemberService from 'services/TeamMemberService';
import { EMPLOYEE_IMPORT_FILE_UPLOAD_SOURCE } from 'hooks/uploadFiles/useUploadFile';
export const StyledLoader = styled(Loader)`
  margin-top: -10%;
`;

const defaultValues = {
  file: [],
  emailFieldId: '',
  firstFieldEmail: '',
  filteredFields: [],
};

const REQUIRED_FIELDS = (t) => [
  {
    id: 'firstNameFieldId',
    name: 'first_name',
    fieldType: FIELD_TYPES.Text.type,
    isEntityName: false,
    title: t('First Name'),
  },
  {
    id: 'lastNameFieldId',
    name: 'last_name',
    fieldType: FIELD_TYPES.Text.type,
    isEntityName: false,
    title: t('Last Name'),
  },
  {
    id: 'emailFieldId',
    name: 'email',
    fieldType: FIELD_TYPES.Email.type,
    title: t('Email'),
  },
];

// We want to compare column headers case-insensitve
// and without trailing or leading whitespace
const cleanValueForComparison = (val) => {
  return val ? val.toLowerCase().trim() : val;
};

const FIELDS = [
  {
    id: 'first_name',
    name: 'first_name',
    canonicalDisplayName: 'First Name',
    displayName: 'First Name',
    fieldType: FIELD_TYPES.Text.type,
  },
  {
    id: 'last_name',
    name: 'last_name',
    canonicalDisplayName: 'Last Name',
    displayName: 'Last Name',
    fieldType: FIELD_TYPES.Text.type,
  },
  {
    id: 'email',
    name: 'email',
    canonicalDisplayName: 'Email (Username)',
    displayName: 'Email (Username)',
    fieldType: FIELD_TYPES.Email.type,
  },
  {
    id: 'phone',
    name: 'phone',
    canonicalDisplayName: 'Phone Number',
    displayName: 'Phone Number',
    fieldType: FIELD_TYPES.PhoneNumber.type,
  },
  {
    id: 'start_date',
    name: 'start_date',
    canonicalDisplayName: 'Start Date',
    displayName: 'Start Date',
    fieldType: FIELD_TYPES.Date.type,
  },
  {
    id: 'additional_roles',
    name: 'additional_roles',
    canonicalDisplayName: 'Role(s)',
    displayName: 'Role(s)',
    fieldType: FIELD_TYPES.Dropdown.type,
  },
  {
    id: 'additional_permission_groups',
    name: 'additional_permission_groups',
    canonicalDisplayName: 'Additional Permission Group(s)',
    displayName: 'Additional Permission Group(s)',
    fieldType: FIELD_TYPES.Dropdown.type,
  },
  {
    id: 'toolbar_template',
    name: 'toolbar_template',
    canonicalDisplayName: 'Custom Toolbar Template',
    displayName: 'Custom Toolbar Template',
    fieldType: FIELD_TYPES.Text.type,
  },
  {
    id: 'track_annual_sales_goal',
    name: 'track_annual_sales_goal',
    canonicalDisplayName: 'Track Quota (Yes/No)',
    displayName: 'Track Quota (Yes/No)',
    fieldType: FIELD_TYPES.YesNoMaybe.type,
  },
  {
    id: 'annual_sales_goal',
    name: 'annual_sales_goal',
    canonicalDisplayName: 'Annual Quota',
    displayName: 'Annual Quota',
    fieldType: FIELD_TYPES.Decimal.type,
  },
];

const UploadTeamMembersModal = ({ onHide, categorizedFields, ...props }) => {
  const [showToast] = useToast({
    delay: 5000,
  });
  const { t } = useTranslation();
  const [values, setValues] = useState(defaultValues);
  const [newTeamMembers, setNewTeamMembers] = useState({});
  const [CSVOptions, setCSVOptions] = useState([]);
  const [finalizeUploadData, setFinalizeUploadData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const isValidSecondStep = useMemo(
    () =>
      values.file.length > 0 && values.file[0].created && CSVOptions.length > 0,
    [values.file, CSVOptions]
  );

  const isValidLastStep = useMemo(
    () =>
      isValidSecondStep &&
      newTeamMembers?.[values.emailFieldId]?.csvColumn &&
      REQUIRED_FIELDS(t)
        .filter((el) => el.name !== values.emailFieldId)
        .filter((el) => newTeamMembers[el.name]?.csvColumn === NO_UPLOAD)
        .length === 0,
    [newTeamMembers, values.emailFieldId, isValidSecondStep, t]
  );
  const handleChangeFile = (file) => {
    setValues({
      ...values,
      file,
    });
  };

  useEffect(() => {
    const fieldName = FIELDS.find((el) => el.name === 'email');
    const firstNameFieldName = FIELDS.find((el) => el.name === 'first_name');
    const lastNameFieldName = FIELDS.find((el) => el.name === 'last_name');
    setValues((prev) => ({
      ...prev,
      emailFieldId: fieldName.id,
      firstNameFieldId: firstNameFieldName.id,
      lastNameFieldId: lastNameFieldName.id,
      firstFieldEmail: fieldName.displayName,
      filteredFields: FIELDS.filter((el) => !['email'].includes(el.name)),
    }));
  }, [t]);

  useEffect(() => {
    if (!newTeamMembers[values.emailFieldId]) {
      setNewTeamMembers(
        values.filteredFields.reduce(
          (acc, curr) => {
            acc[curr.id] = {
              csvColumn: '',
              conflictResolution: '',
            };
            return acc;
          },
          {
            [values.emailFieldId]: {
              csvColumn: '',
              conflictResolution: '',
            },
          }
        )
      );
    }
  }, [values.filteredFields, newTeamMembers, values.emailFieldId]);

  const handleSetCSVData = useCallback(
    (data) => {
      // check empty data from csv
      if (data && data.length > 0) {
        const options = data[0].map((item, index) => ({
          label: item,
          value: index.toString(),
        }));
        options.unshift({
          label: t('Don’t Upload'),
          value: NO_UPLOAD,
        });
        setCSVOptions(options);
        const newTeamMembersAfterUploadData = values.filteredFields.reduce(
          (acc, curr) => {
            const matcher = curr.canonicalDisplayName;
            const index = data[0].findIndex(
              (el) =>
                cleanValueForComparison(el) === cleanValueForComparison(matcher)
            );
            acc[curr.id] = {
              csvColumn: index < 0 ? NO_UPLOAD : index.toString(),
              conflictResolution: [
                'first_name',
                'last_name',
                'toolbar_template',
              ].includes(curr.name)
                ? OVERWRITE
                : getConflictResolution(curr.fieldType, curr),
            };
            return acc;
          },
          {}
        );
        const emailFieldIndex = data[0].findIndex(
          (el) =>
            cleanValueForComparison(el) ===
            cleanValueForComparison(values.firstFieldEmail)
        );
        const newTeamMembersData = {
          ...newTeamMembers,
          ...newTeamMembersAfterUploadData,
          [values.emailFieldId]: {
            csvColumn: `${emailFieldIndex}`,
            conflictResolution: '',
          },
        };
        setNewTeamMembers(newTeamMembersData);
        const slicedSCVData =
          data.length > 5 ? data.slice(1, 6) : data.slice(1);
        setFinalizeUploadData(
          slicedSCVData.map((el, i) => ({
            id: i,
            ...getInitialFinalizeUploadData(el, newTeamMembersData),
          }))
        );
        setCsvData(slicedSCVData);
        return;
      }
      setCSVOptions([]);
      setCsvData([]);
      setFinalizeUploadData([]);
      setNewTeamMembers({});
    },
    [
      newTeamMembers,
      values.filteredFields,
      values.emailFieldId,
      values.firstFieldEmail,
      t,
    ]
  );

  const handleChangeMatchRecords = useCallback(
    (data, element) => {
      setNewTeamMembers((prev) => ({
        ...prev,
        [element]: {
          ...prev[element],
          csvColumn: data.value,
        },
      }));
      setFinalizeUploadData((prev) =>
        prev.map((curr, i) => ({
          ...curr,
          [element]: csvData[i][+data.value],
        }))
      );
    },
    [csvData]
  );

  const createPayload = async () => {
    const copyNewContacts = {
      ...newTeamMembers,
    };
    delete copyNewContacts[values.emailFieldId];
    delete copyNewContacts[subscriptionField.id];
    const mappedFields = changeCSVColumnType(copyNewContacts);
    const body = {
      s3_objectId: values.file[0].id,
      emailColumn: +newTeamMembers[values.emailFieldId].csvColumn,
      fieldMapper: mappedFields,
    };
    try {
      await TeamMemberService.teamUploader(body);
      showToast({
        variant: toastVariant.SUCCESS,
        message: t(
          'Your upload has been successfully started. You will be emailed once the upload is complete.'
        ),
      });
      onHide();
    } catch (err) {
      showToast({
        variant: toastVariant.FAILURE,
        message: t('The records have not been uploaded.'),
      });
    }
  };

  return (
    <BasicModalWithConfirmation
      dirty={!!values.file?.length}
      onHide={onHide}
      size="large"
      heading={t('Upload Team Members')}
      leftBtn={
        <Button variant="text" color="red" onClick={onHide}>
          {t('Cancel')}
        </Button>
      }
      buttonText={t('Upload')}
      disabled={!isValidLastStep}
      onConfirm={createPayload}
      {...props}
    >
      <StyledGenericWizardModalBody>
        <StyledUploadDataSection header={t('Upload Your Data')} isActive>
          <StyledLoader>
            <UploadDataSection
              fields={FIELDS}
              files={values.file}
              onChange={handleChangeFile}
              setCSVData={handleSetCSVData}
              source={EMPLOYEE_IMPORT_FILE_UPLOAD_SOURCE}
              customObject={{
                objectType: 'teamMembers',
                objectName: 'team_members',
              }}
            />
          </StyledLoader>
        </StyledUploadDataSection>
        <StyledMatchRecordsSection
          header={t('Match Records')}
          isActive={isValidSecondStep}
        >
          {isValidSecondStep && (
            <MatchRecordsSection
              setNewEntities={setNewTeamMembers}
              requiredFields={REQUIRED_FIELDS(t)}
              newEntities={newTeamMembers}
              CSVOptions={CSVOptions}
              values={values}
              onChange={handleSetCSVData}
              setFinalizeUploadData={setFinalizeUploadData}
              handleChangeMatchRecords={handleChangeMatchRecords}
            />
          )}
        </StyledMatchRecordsSection>
        <StyledFinalizeUploadSection
          header={t('Finalize Upload')}
          isActive={isValidLastStep}
        >
          {isValidLastStep && (
            <FinalizeUploadSection
              fields={FIELDS}
              finalizeUploadData={finalizeUploadData}
            />
          )}
        </StyledFinalizeUploadSection>
      </StyledGenericWizardModalBody>
    </BasicModalWithConfirmation>
  );
};

export default UploadTeamMembersModal;
