import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, shadowLight, grayScale } from 'app/colors';
import { fontWeights } from 'app/typography';
import { gutters } from 'app/spacing';
import CloseButton from 'components/CloseButton';
import { layers } from 'app/spacing';

export const StyledToastContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0;
  text-align: center;
  position: fixed;
  z-index: ${({ zIndex }) => zIndex ?? layers.toast};
  left: 0;
  top: 0;
  right: 0;
`;

function getBackgroundColor({ variant }) {
  switch (variant) {
    case 'alert':
      return colorsButton.blue.default;
    case 'failure':
      return colorsButton.red.default;
    default:
      // TODO ask Scott about adding this color to secondary palette
      return colorsButton.green.default;
  }
}

export const StyledToast = styled.div`
  z-index: ${({ zIndex }) => zIndex ?? layers.toast};
  position: absolute;
  background-color: ${getBackgroundColor};
  border-radius: 4px;
  padding: ${gutters.spacing(2)}px;
  ${shadowLight}
  ${(props) =>
    Array.isArray(props?.yOffset)
      ? props.yOffset.map(({ breakpoint, offset }) =>
          breakpoint === 0
            ? css`
                top: ${offset}px;
              `
            : css`
                @media (min-width: ${breakpoint}px) {
                  top: ${offset}px;
                }
              `
        )
      : (props.yOffset || props.yOffset === 0) &&
        css`
          top: ${props.yOffset}px;
        `};
  && {
    max-width: 800px;
    border-width: 0;
  }

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          && {
            max-width: 100%;
          }
        `
      : ''}
`;

export const StyledToastBody = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${fontWeights.bold};
  font-size: 14px;
  color: ${grayScale.white};
  line-height: 100%;
  max-width: 800px;
  justify-content: space-between;
  margin: -1px 0;

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          && {
            max-width: 100%;
          }
        `
      : ''}
`;

export const StyledCloseButton = styled(CloseButton)`
  && {
    margin: 0 0 0 ${gutters.spacing(2)}px;
    border: 0;
    width: 12px;
    height: 12px;
    font-size: 12px;
  }
`;
