import { createSlice } from '@reduxjs/toolkit';

// Slices
const defaultState = {
  keyboard: true,
};

export const modalControlSlice = createSlice({
  name: 'modalControl',
  initialState: defaultState,
  reducers: {
    keyboard: (state, { payload }) => {
      if (state.keyboard !== payload) state.keyboard = payload;
    },
  },
});

export const { keyboard } = modalControlSlice.actions;

export const { reducer } = modalControlSlice;
