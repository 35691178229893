export const compose = (...fns: any[]): any => {
  const [a, b, c, d, e] = fns;
  switch (fns.length) {
    case 0:
      return new Error('illegal arity');
    case 1:
      return a;
    case 2:
      return (...args: any[]) => a(b(...args));
    case 3:
      return (...args: any[]) => a(b(c(...args)));
    case 4:
      return (...args: any[]) => a(b(c(d(...args))));
    default: {
      const fn = (...args: any[]) => a(b(c(d(e(...args)))));
      return fns.length === 5 ? fn : compose(fn, ...fns.slice(5));
    }
  }
};
