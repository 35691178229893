import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import BasicModal from 'components/Modals/presets/BasicModal';
import Input from 'components/Kizen/Input';
import Select from 'components/Inputs/Select';
import ObjectSelector from 'components/Wizards/shared/components/MetaFilters/ObjectSelector';
import KizenTypography from 'app/kizentypo';
import { useGroupedFields } from 'components/Wizards/shared/hooks/useGroupedFields';
import useCustomObjectDetails from 'components/Wizards/shared/hooks/useCustomObjectDetails';

const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

export const CustomFieldModal = ({
  onConfirm,
  onHide,
  index,
  editValue,
  ...values
}) => {
  const { t } = useTranslation();
  const [label, setLabel] = useState(values.label ?? '');
  const [objectId, setObjectId] = useState(values.objectId ?? '');
  const [selectedField, setSelectedField] = useState();

  const isEditing = useMemo(
    () => Boolean(values.fieldId && values.objectId && values.label),
    [values.fieldId, values.objectId, values.label]
  );

  const { data, isLoading } = useCustomObjectDetails({
    objectId,
    enabled: Boolean(objectId),
  });

  const groupedOptions = useGroupedFields(
    data?.fields,
    data?.fieldCategories,
    true
  );

  const fieldsById = useMemo(
    () =>
      data?.fields.reduce((acc, field) => {
        acc[field.id] = field;
        return acc;
      }, {}),
    [data?.fields]
  );

  const handleConfirm = useCallback(() => {
    onConfirm({
      index,
      label,
      objectId,
      fieldId: selectedField?.value,
      displayName: fieldsById?.[selectedField?.value].displayName,
    });
    onHide();
  }, [onHide, onConfirm, index, label, objectId, selectedField, fieldsById]);

  useEffect(() => {
    if (values.fieldId && fieldsById) {
      const fieldData = fieldsById[values.fieldId];
      if (fieldData) {
        setSelectedField({
          value: values.fieldId,
          label: fieldData.displayName,
        });
      }
    }
  }, [values.fieldId, fieldsById]);

  return (
    <BasicModal
      heading={
        isEditing ? t('Edit Custom Field Column') : t('Add Custom Field Column')
      }
      onConfirm={handleConfirm}
      size="small"
      show
      onHide={() => onHide({ reset: !isEditing })}
      data-qa="related-entity-quick-filter-modal"
      disabled={label === '' || objectId === '' || selectedField === ''}
    >
      <StyledInput
        placeholder={t('Enter Display Name')}
        value={label}
        onChange={setLabel}
        label={t('Display Name')}
        inModal
      />
      <ObjectSelector
        onChange={(object) => {
          setObjectId(object.value);
          setSelectedField('');
        }}
        value={objectId ? { id: objectId } : undefined}
        inModal
        menuInline
        label={
          <KizenTypography>{t('Choose Object to Display')}</KizenTypography>
        }
      />
      <StyledField>
        <KizenTypography>{t('Choose Custom Field to Display')}</KizenTypography>
        <Select
          value={selectedField}
          onChange={setSelectedField}
          captureMenuScroll={false}
          className="modal-select field-metrics"
          placeholder={t('Choose Field')}
          options={groupedOptions}
          fullWidth
          isLoading={isLoading}
          disabled={!objectId}
          menuInline
        />
      </StyledField>
    </BasicModal>
  );
};
