import { createContext } from 'react';
import { useDataManager } from './useDataManager';

export const DataManager = createContext({});

export const DataManagerWrapper = ({ children, instanceId, parentId }) => {
  const dataManager = useDataManager(instanceId, parentId);

  return (
    <DataManager.Provider value={dataManager}>{children}</DataManager.Provider>
  );
};
