import React, { useMemo } from 'react';
import MyToolbarPage from './pages/MyToolbar';
import styled from '@emotion/styled';
import SubNavigation from 'components/Navigation/SubNavigation';
import { gutters } from 'app/spacing';
import { Route, Switch } from 'react-router-dom';
import ToolbarTemplatesPage from './pages/ToolbarTemplates';
import { useTranslation } from 'react-i18next';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useSelector } from 'react-redux';
import { getUserProfile } from 'store/authentication/selectors';

const NavWrapper = styled.div`
  max-width: 600px;
  position: relative;
  margin: 0 auto;
`;

const StyledSubNavigation = styled(SubNavigation)`
  & nav {
    width: 300px;
  }

  && a {
    padding: ${gutters.spacing(1, 3)}px 0 ${gutters.spacing(1, 2)}px 0 !important;
    width: 50%;
    text-align: center;
  }
`;

const ToolbarPage = () => {
  const { t } = useTranslation();
  const user = useSelector(getUserProfile);
  const routes = useMemo(() => {
    return {
      myToolbar: {
        label: t('My Toolbar'),
        component: MyToolbarPage,
        exact: true,
        path: '/account/toolbar',
      },
      toolbarTemplates: {
        label: t('Toolbar Templates'),
        component: ToolbarTemplatesPage,
        exact: true,
        path: '/account/toolbar/templates',
      },
    };
  }, [t]);

  useSetTitleOnLoad(
    user?.full_name
      ? `${user.full_name} - ${t('Toolbar')} - KIZEN`
      : `${t('Toolbar')} - KIZEN`
  );

  return (
    <>
      <NavWrapper>
        <StyledSubNavigation small routes={routes} />
      </NavWrapper>
      <Switch>
        {Object.entries(routes).map(([key, route]) => {
          return (
            <Route
              key={key}
              path={route.path}
              exact={route.exact !== false}
              component={route.component}
            />
          );
        })}
      </Switch>
    </>
  );
};

export default ToolbarPage;
