import React, { useMemo } from 'react';
import { ClampedContainer, StageName } from './styles';
import PropTypes from 'prop-types';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import useWidthMeasurement from 'hooks/useWidthMeasurement';

const StageNameComponent = ({ name, onWidthChange, mobile }) => {
  const { ref } = useWidthMeasurement(onWidthChange);
  const [tooltipProps, tooltip] = useTruncationTooltip();
  const isOneWord = useMemo(() => name.split(' ').length < 2, [name]);
  // We have to use line-clamping to have multi-line text truncated with an ellipsis, but
  // because it's a webkit proprietary property, we only want to do it on mobile and stick to
  // more vanilla CSS in other cases whenever possible
  return mobile && !isOneWord ? (
    <>
      <ClampedContainer ref={ref} {...tooltipProps}>
        {name}
      </ClampedContainer>
      {tooltip}
    </>
  ) : (
    <StageName ref={ref}>{name}</StageName>
  );
};

StageNameComponent.propTypes = {
  name: PropTypes.string,
  onWidthChange: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
};

StageNameComponent.defaultProps = {
  name: '',
  mobile: false,
};

export default StageNameComponent;
