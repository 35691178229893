import { Children, cloneElement } from 'react';
import { useMeasure } from 'react-use';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';

const Container = styled.div`
  position: relative;
  border-left: 1px solid ${grayScale.mediumDark};
  height: ${({ height }) => `${height}px;`};
  padding-left: ${({ padding }) => `${padding}px;`};
`;

const Tick = styled.div`
  position: absolute;
  top: ${({ offset }) => `${offset}px;`};
  left: -1px;
  width: ${({ tickLength }) => `${tickLength}px;`};
  height: 1px;
  background-color: ${grayScale.mediumDark};
`;

/**
 * Container component to create a vertical bar on the left-hand
 * side of its children with "ticks" marking the start of each child.
 *
 * @remarks Each child must be wrapped in `forwardRef`. This component
 * clones and attaches a new ref to each child associated with an instance
 * of the useMeasure hook so it can calculate where to put each tick. This
 * means a child of tree cannot place a ref on the outtermost element - refs
 * would need to be placed on a separate "inner container" element.
 *
 * The `padding` prop refers to just the left padding of the container, i.e.
 * the space between the vertical bar and its children. A `tickLength` greater
 * than `padding` would mean the ticks overlap the child elements.
 */
export const Tree = ({
  children,
  offset = 0,
  padding = 20,
  tickLength = 10,
  showBottomTick = true,
  showTopTick = false,
  ...rest
}) => {
  const childs = Children.toArray(children);
  const measures = childs.map(useMeasure);
  const ticks = measures.reduce(
    (acc, [_, { height }]) => {
      acc.push(acc[acc.length - 1] + height);
      return acc;
    },
    showTopTick && offset !== 0 ? [0, offset] : [offset]
  );

  return (
    <Container height={ticks[ticks.length - 1]} padding={padding} {...rest}>
      {childs.map((section, idx) =>
        cloneElement(section, { ref: measures[idx][0] })
      )}
      {ticks.map((height, idx, arr) =>
        !showBottomTick && idx === arr.length - 1 ? null : (
          <Tick key={idx} offset={height} tickLength={tickLength} />
        )
      )}
    </Container>
  );
};
