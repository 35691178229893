import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';

export const MobileFallback = ({ isMobile, text, children }) => {
  const { t } = useTranslation();
  return isMobile ? (
    <KizenTypography>
      {text ||
        t(
          'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
        )}
    </KizenTypography>
  ) : (
    children
  );
};
