import { CONFLICT_RESOLUTIONS } from '__pages/SmartConnectors/constants';
import {
  ConflictResolutionMeta,
  FieldLike,
  FieldType,
  SmartConnectorMetaData,
  SmartConnectorVariable,
} from '__pages/SmartConnectors/types';
import { TFunction } from 'react-i18next';
import FieldService from '__services/FieldService';

export const MAPPING_VIEW_OPTIONS = {
  mapped_fields: 'mapped_fields',
  fields_by_category: 'fields_by_category',
} as const;

export type MappingViewOption = keyof typeof MAPPING_VIEW_OPTIONS;

export const getMappingViewOptions = (
  t: TFunction
): { value: MappingViewOption; label: string }[] => [
  { value: MAPPING_VIEW_OPTIONS.mapped_fields, label: t('Mapped Fields') },
  {
    value: MAPPING_VIEW_OPTIONS.fields_by_category,
    label: t('Fields by Category'),
  },
];

const fallbackConflictResolution = {
  default: CONFLICT_RESOLUTIONS.only_update_blank,
  options: [CONFLICT_RESOLUTIONS.only_update_blank],
};

export const getConflictResolutionByFieldType = (
  metaData: SmartConnectorMetaData,
  field?: FieldLike
): ConflictResolutionMeta => {
  if (!field?.fieldType) {
    return fallbackConflictResolution;
  }

  const conflictResolutionMeta =
    metaData.field_mapping_rules.field_type_meta[field.fieldType]
      ?.conflict_resolution;

  if (
    conflictResolutionMeta?.allow_multiple &&
    FieldService.getAllowMultiple(field)
  ) {
    return conflictResolutionMeta.allow_multiple;
  }
  return conflictResolutionMeta || fallbackConflictResolution;
};

export const getFieldTypeMeta = (
  metaData: SmartConnectorMetaData,
  variable?: SmartConnectorVariable,
  fieldType?: FieldType
) => {
  return (
    variable?.data_type &&
    fieldType &&
    metaData.field_mapping_rules.variable_to_field_matching[
      variable.data_type
    ]?.[variable.is_array ? 'array' : 'single']?.[fieldType]
  );
};
