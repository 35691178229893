import { TIMELINE } from 'queries/query-keys';

export const buildFallbackQueryKey = ({
  id,
  searchTerm,
  size,
  filters,
  params,
}) => {
  return [...TIMELINE.RECORD(id), searchTerm, size, filters, params];
};
