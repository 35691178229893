import { format } from 'date-fns';
import { TIME_DISPLAY_FORMAT } from 'components/Inputs/DateTimeInput';

export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';

export const emptyLongDash = '—';

export const getDate = (date) => {
  if (!date) return emptyLongDash;
  return `${format(date, DATE_DISPLAY_FORMAT)}`;
};

export const formatTimestamp = (timestamp) => {
  if (!timestamp) return emptyLongDash;
  const date = new Date(timestamp);
  // yields 2/8/2021 at 12:00 PM
  return `${getDate(date)} at ${format(date, TIME_DISPLAY_FORMAT)}`;
};

export const formatSplitTimestamp = (timestamp) => {
  if (!timestamp)
    return {
      day: emptyLongDash,
      time: emptyLongDash,
    };

  const date = new Date(timestamp);

  return {
    day: getDate(date),
    time: format(date, TIME_DISPLAY_FORMAT),
  };
};
