import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import BasicModal from 'components/Modals/presets/BasicModal';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from 'app/colors';
import { layers } from 'app/spacing';
import { useMemo } from 'react';
import { RangeInput } from './RangeInput';
import { fontWeights } from '__app/typography';

const SIZE = 14;

const StyledKizenTypography = styled(KizenTypography)`
  padding-bottom: 18px;
  text-align: center;
`;

const RangeInputContainer = styled.div`
  width: 100%;
  ${({ min, max }) => css`
    padding-left: calc(50% / (${max + 2} - ${min + 1}) - ${SIZE / 2}px + 1px);
    padding-right: calc(50% / (${max + 2} - ${min + 1}) - ${SIZE / 2}px - 1px);
    grid-column: ${min + 1} / ${max + 2};
  `}
`;

const StyledRangeInput = styled(RangeInput)`
  &&& {
    display: block;
    min-height: 57px;
    [data-reach-slider-input] {
      margin: 0;
      width: 100%;
      max-width: none;
      height: initial;
      cursor: pointer;
      border-radius: ${SIZE / 2}px;
      background-color: ${grayScale.medium};
    }
    [data-reach-slider-handle] {
      border-width: 2px;
      border-color: ${colorsButton.blue.hover};
      z-index: ${layers.content(
        0
      )}; // Appear beneath the Expander gradient (see PermissionSection)
    }
    [data-reach-slider-track] {
      height: ${SIZE}px;
      margin: 0 ${SIZE / 2}px;
      background: initial;
    }
    [data-reach-slider-track-highlight] {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: ${colorsButton.blue.hover};
    }
    [data-reach-slider-marker] {
      border-radius: 50%;
      &:first-child {
        > * {
          top: 39px;
        }
      }
      > * {
        text-align: center;
        font-size: 14px;
        font-weight: ${fontWeights.regular};
        top: 34px;
        left: 50%;
        width: 100%;
        min-width: 180px;
      }
      // These styles are only for the first marker
      height: ${SIZE}px;
      width: ${SIZE}px;
      background-color: ${colorsButton.blue.hover};
      ~ [data-reach-slider-marker] {
        // And these styles are for all other markers
        height: 4px;
        width: 4px;
        background-color: ${grayScale.white};
      }
    }
    ${({ min, max }) => {
      // Special case for a degenerate slider with no steps
      return (
        min === max &&
        css`
          width: ${SIZE}px;
          [data-reach-slider-marker] {
            left: 0;
          }
          [data-reach-slider-handle] {
            left: -${SIZE / 2}px;
          }
        `
      );
    }}
  }
`;

const STEP_LABELS = (t) => [
  {
    value: '0.80',
    label: t('I want AI earlier, but I know it will be a little less accurate'),
  },
  { value: '0.90', label: t("I'd like a balance") },
  {
    value: '0.95',
    label: t('I want my AI to be more accurate, but a little later'),
  },
];
export const AIModal = ({
  show = true,
  editing,
  onConfirm,
  onHide,
  values,
  updateStepField,
  onCancelHandle,
  ...others
}) => {
  const { t } = useTranslation();

  const activeValue = useMemo(() => {
    return STEP_LABELS(t).findIndex(
      (item) => item.value === values?.aiConfidenceThreshold?.toString()
    );
  }, [values, t]);

  return (
    <BasicModal
      show={show}
      buttonText={editing ? t('Save') : t('Confirm')}
      heading={
        editing
          ? t('Edit AI % Chance to Close Controls')
          : t('Please Confirm Use of AI in % Chance to Close')
      }
      defaultLeftBtnText={t('Cancel')}
      defaultLeftBtnHandler={onCancelHandle}
      onConfirm={onConfirm}
      size={'medium'}
      onHide={onHide}
      modalBodyProps={{
        style: {
          paddingTop: '9px',
        },
      }}
      {...others}
    >
      <StyledKizenTypography>
        {t(
          'Turning this on will override your set values and use Kizen’s AI to monitor your pipeline and automatically set % chances to close based on your team’s historical performance. Simply set your desired AI speed and accuracy and get started!'
        )}
      </StyledKizenTypography>
      <RangeInputContainer min={0} max={2}>
        <StyledRangeInput
          withSteps
          showTooltip={false}
          onChange={(value) => {
            updateStepField(
              'aiConfidenceThreshold',
              STEP_LABELS(t)[value].value.toString()
            );
          }}
          value={activeValue}
          min={0}
          max={2}
          step={1}
          stepsLabels={STEP_LABELS(t)}
        />
      </RangeInputContainer>
    </BasicModal>
  );
};
