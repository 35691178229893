import { FIELD_TYPES } from 'utility/constants';
import { DEFAULT_PAGINATION_SETTINGS } from './constants';

//making this helpers to use switch ... case for future support of other field types

export const getItemText = ({ fieldType }, count, t) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return count === 1 ? t('Record') : t('Records');
    default:
      return count === 1 ? t('Record') : t('Records');
  }
};

export const getColumnLabel = ({ fieldType }, t) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return t('Related Record');
    default:
      return '';
  }
};

export const getTooltipText = ({ fieldType }, t) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return t('View & Edit Records');
    default:
      return '';
  }
};

export const getAddButtonText = ({ fieldType, relation }, isMobile, t) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return t('Add {{entityName}} Records', {
        entityName: isMobile ? undefined : relation.relatedEntityName,
      });
    default:
      return '';
  }
};

export const getAddValuesPlaceholder = ({ fieldType, relation }, t) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return t('Find {{entityName}} Records to Add', {
        entityName: relation.relatedEntityName,
      });
    default:
      return '';
  }
};

export const getModalHeading = ({ displayName, fieldType }, t) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return `${displayName} ${t('Values')}`;
    default:
      return '';
  }
};

export const getSortBy = ({ fieldType, relation }) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type: {
      return relation.fetchUrl === 'client' ? 'full_name' : 'name';
    }
    default:
      return 'name';
  }
};

export const getPaginationSettings = ({ fieldType, relation }) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return {
        ...DEFAULT_PAGINATION_SETTINGS,
        ordering: getSortBy({ fieldType, relation }),
      };
    default:
      return DEFAULT_PAGINATION_SETTINGS;
  }
};

export const getModalSubHeading = ({ fieldType }, count, t) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return t('{{totalCount}} Related Records', { totalCount: count });
    default:
      return '';
  }
};

export const getSummaryText = (
  { fieldType },
  addValues,
  removeValues,
  count,
  t
) => {
  switch (fieldType) {
    case FIELD_TYPES.Relationship.type:
      return t(
        '{{totalCount}} Total {{item}}, {{add}} {{itemAdd}} Added, {{remove}} {{itemRemove}} Removed',
        {
          totalCount: count,
          item: getItemText({ fieldType }, count, t),
          add: addValues.length,
          itemAdd: getItemText({ fieldType }, addValues.length, t),
          remove: removeValues.length,
          itemRemove: getItemText({ fieldType }, removeValues.length, t),
        }
      );
    default:
      return '';
  }
};
