import { ButtonActions } from '../constants';
import {
  PageBuilderButton,
  PageBuilderCell,
  PageBuilderCustomField,
  PageBuilderFormField,
  PageBuilderImage,
  PageBuilderNode,
  PageBuilderRoot,
  PageBuilderRow,
  PageBuilderSection,
  PageBuilderText,
} from '../types';

export const isCellNode = (node: PageBuilderNode): node is PageBuilderCell => {
  return node.type.resolvedName === 'Cell';
};

export const isButtonNode = (
  node: PageBuilderNode
): node is PageBuilderButton => {
  return node.type.resolvedName === 'Button';
};

export const isCustomFieldNode = (
  node: PageBuilderNode
): node is PageBuilderCustomField => {
  return node.type.resolvedName === 'CustomField';
};

export const isFormFieldNode = (
  node: PageBuilderNode
): node is PageBuilderFormField => {
  return node.type.resolvedName === 'FormField';
};

export const isImageNode = (
  node: PageBuilderNode
): node is PageBuilderImage => {
  return node.type.resolvedName === 'Image';
};

export const isRootNode = (node: PageBuilderNode): node is PageBuilderRoot => {
  return node.type.resolvedName === 'Root';
};

export const isRowNode = (node: PageBuilderNode): node is PageBuilderRow => {
  return node.type.resolvedName === 'Row';
};

export const isSectionNode = (
  node: PageBuilderNode
): node is PageBuilderSection => {
  return node.type.resolvedName === 'Section';
};

export const isTextNode = (node: PageBuilderNode): node is PageBuilderText => {
  return node.type.resolvedName === 'Text';
};

export const isSubmitActionButton = (
  node: PageBuilderNode
): node is PageBuilderButton => {
  return (
    isButtonNode(node) &&
    (node.props.action === ButtonActions.SUBMIT ||
      node.props.action === ButtonActions.GO_TO_PAGE_SUBMIT)
  );
};
