import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import { breakpoints, gutters } from 'app/spacing';
import { Nav } from 'react-bootstrap';
import { NavLink, generatePath, matchPath } from 'react-router-dom';
import {
  commonNavItemStyle,
  navItemHoverColor,
  regularNavItemWidth,
  AltSubNavigationNavItemDivider,
} from './styles';
import { NavItemProps, NavLinkProps } from './props';
import NavItemContent from './NavItemContent';

// becasue we have 3 options and the center one needs to be centered add some margin to the 1st link
// to match the with of the last link
const paddingToCenter = 33; // 77 - 44
const altNavStyles = css`
  text-align: center;
  &.active {
    span,
    i {
      ${navItemHoverColor}
    }
    @media (max-width: ${breakpoints.md}px) {
      box-shadow: inset ${colorsButton.blue.hover} 0 -2px 0;
    }
  }
  // Equivalent of 10px above capline and below baseline
  && {
    padding: 0 0 calc(${gutters.spacing(2, { baseline: true })}px) 0;
  }
  justify-content: center;
  span {
    margin: 0;
  }
  &.firstOfThree {
    margin-right: ${paddingToCenter}px;
    @media (min-width: ${breakpoints.md + 1}px) {
      margin-right: 0;
    }
  }
  @media (min-width: ${breakpoints.md + 1}px) {
    padding: ${gutters.spacing(3)}px 0;
    justify-content: unset;
    > span {
      margin-top: ${gutters.spacing(1, 3)}px;
    }
  }
`;

const altSmallNavStyles = css`
  background: blue;
  && {
    padding: ${gutters.spacing(2, -2)}px ${gutters.spacing(8)}px
      ${gutters.spacing(2, { baseline: true })}px !important;
    &.active span {
      ${navItemHoverColor}
    }
    border-bottom: 1px solid transparent;
    &.active {
      border-bottom: 1px solid ${colorsButton.blue.hover};
    }
    > span {
      margin: 0;
    }
    > i {
      display: none;
    }
  }
`;

const StyledAltSubNavigationNavLink = styled(Nav.Link)`
  ${commonNavItemStyle}

  ${({ small }) => (small ? altSmallNavStyles : altNavStyles)}
  ${({ small, numberOfLinks }) => !small && regularNavItemWidth(numberOfLinks)}
`;

const StyledAltSubNavigationNavItem = styled.div`
  ${commonNavItemStyle}
  ${({ small }) => (small ? altSmallNavStyles : altNavStyles)}
  ${({ small, numberOfLinks }) => !small && regularNavItemWidth(numberOfLinks)}
  cursor: pointer;
`;

export const AltSubNavigationNavLink = ({
  route,
  isLast,
  params = {},
  ...rest
}) => (
  <>
    <StyledAltSubNavigationNavLink
      as={NavLink}
      to={generatePath(route.path, params)}
      // There are routes that have active class as parent components although they shouldn't
      // show as active, this way we override that react router feature and add active class only
      // when route path is matched with current path
      isActive={(_, { pathname }) => matchPath(pathname, route)}
      {...rest}
    >
      <NavItemContent icon={route.icon} label={route.label} />
    </StyledAltSubNavigationNavLink>
    {!isLast && <AltSubNavigationNavItemDivider />}
  </>
);

export const AltSubNavigationNavItem = ({ icon, label, isLast, ...rest }) => (
  <>
    <StyledAltSubNavigationNavItem {...rest}>
      <NavItemContent icon={icon} label={label} />
    </StyledAltSubNavigationNavItem>
    {!isLast && <AltSubNavigationNavItemDivider />}
  </>
);

AltSubNavigationNavItem.propTypes = NavItemProps;
AltSubNavigationNavLink.propTypes = NavLinkProps;
