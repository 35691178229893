import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import ReactFlow, { ReactFlowProvider, useNodes, useEdges } from 'reactflow';
import { useAutomationsSelector } from '../../store/react';
import {
  layoutNodes,
  nodeHasDimension,
  preserveElementOrderForLayout,
} from './layout';
import { ReactFlowWrapper } from './styles';

let token = 0;

// This is our tracer for react-flow. It helps us tell if it has rendered and layout'ed the latest data
const updateTokenEl = () => {
  token = (token + 1) % 1000;
  return {
    id: 'update.token',
    type: 'none',
    // We need an object with a token in it, since this data object changes.
    // It looks like someone (react-flow?) is merging an empty object into it.
    data: { token },
    isHidden: true,
    position: { x: 0, y: 0 },
  };
};

const isUpdateToken = (n) => n.id === 'update.token';

const renderedIsLatest = (renderedEls, latestEls) => {
  const [updateToken] = latestEls;
  return renderedEls.some(
    (n) => isUpdateToken(n) && n.data.token === updateToken.data.token
  );
};

// Running the dagre layout routine requires that all nodes have dimensions.
// The dimensions often come from actually drawing the node in the DOM then
// taking a measurement (react-flow-renderer does this).  So here we have a
// flow which isn't visible, and is used specifically for layout purposes.
// Having two flows allows us to stage visual changes without showing awkward
// intermediate states in the moment between e.g. elements changes and the
// elements are able to be measured then layouted.  The main Flow is downstream
// from the LayouterFlow, and always receives a stable/complete layout.

export function LayouterFlowCore({
  nodes,
  edges,
  onLayout,
  showStats,
  lastPlacingStep,
  ...props
}) {
  const renderedNodes = useNodes();
  const renderedEdges = useEdges();

  const tokenizesNodes = useMemo(() => [updateTokenEl(), ...nodes], [nodes]);
  const detokenizedNodes = useMemo(
    () =>
      renderedNodes
        .filter((el) => !isUpdateToken(el))
        .map((el) =>
          el.id === lastPlacingStep?.id ? { ...el, selected: true } : el
        ),
    [renderedNodes, lastPlacingStep?.id]
  );

  // Many things may change renderedElements, but we still want to control when a re-layout actually occurs.
  // Here we cancel relayouts originating from showStats being toggled on (altering all step/trigger heights).

  const layoutCanceledRef = useRef(false);
  useLayoutEffect(() => {
    if (showStats) {
      layoutCanceledRef.current = true;
    }
  }, [showStats]);

  const elementsRef = useRef();
  elementsRef.current = tokenizesNodes;

  const layoutParamsRef = useRef();
  layoutParamsRef.current = { showStats };

  const onLayoutRef = useRef();
  onLayoutRef.current = onLayout;

  useEffect(() => {
    if (
      renderedIsLatest(renderedNodes, elementsRef.current) &&
      detokenizedNodes.every(nodeHasDimension) // The dimensions don't appear immediately
    ) {
      if (layoutCanceledRef.current) {
        layoutCanceledRef.current = false;
      }
      onLayoutRef.current([
        layoutNodes(
          preserveElementOrderForLayout(detokenizedNodes, elementsRef.current),
          renderedEdges,
          layoutParamsRef.current
        ),
        renderedEdges,
      ]);
    }
  }, [detokenizedNodes, renderedEdges, renderedNodes]);

  return (
    <ReactFlowWrapper>
      <ReactFlow
        // When there is no layout, render the elements so that they can be measured then layout'ed
        nodes={tokenizesNodes}
        edges={edges}
        zoomOnScroll={false}
        panOnScroll={false}
        paneMoveable={false}
        nodesDraggable={false}
        nodesConnectable={false}
        onlyRenderVisibleElements={false}
        {...props}
      />
    </ReactFlowWrapper>
  );
}

function LayouterFlow({ nodes, edges, onLayout, ...props }) {
  const showStats = useAutomationsSelector((state) => state.showStats);
  const lastPlacingStep = useAutomationsSelector(
    (state) => state.lastPlacingStep
  );
  return LayouterFlowCore({
    nodes,
    edges,
    onLayout,
    showStats,
    lastPlacingStep,
    ...props,
  });
}

export default function Layouter(props) {
  return (
    <ReactFlowProvider>
      <LayouterFlow {...props} />
    </ReactFlowProvider>
  );
}
