import KizenTypography from 'app/kizentypo';

export default function SupportLink({ text = '', prefix = '', postfix = '' }) {
  return (
    <>
      {prefix}{' '}
      <KizenTypography
        type="link-mail"
        size="micro"
        weight="bold"
        href="mailto:support@kizen.com"
      >
        {text || 'Support@Kizen.com'}
      </KizenTypography>{' '}
      {postfix}
    </>
  );
}
