import type { Next, Option } from '@kizen/filters/types';
import { buildOptions } from '@kizen/filters/utils';
import { get } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import SelectAllButton from '__components/Inputs/MultiSelect/SelectAllButton';
import Multi from '__components/Inputs/MultiSelect';
import { getSelectValue, useFilterOptions } from '../hooks/useFilterOptions';
import { GroupPill } from '../GroupPill';
import { useFilterContext } from '../filter-context';
import { OnChangeProp, Props, SelectOption } from './types';
import ClearSelectButton from '__components/Inputs/Select/ClearButton';
import ApplySelectButton from '__components/Inputs/Select/ApplyButton';

type MultiSelectProps = Omit<Props, 'value'> &
  OnChangeProp<{ value: Option['value'][]; next?: Next[] }> & {
    value?: Option['value'][];
    field_type?: string;
  };

const opt_filter = (value: MultiSelectProps['value'], valuePath?: string[]) => {
  const v = valuePath ? value?.map((x) => get(x, valuePath)) : value;
  return (x: SelectOption) => v?.includes(x.value);
};

export const MultiSelect = ({
  step,
  value = [],
  width,
  method,
  next,
  placeholder,
  url,
  result_path,
  value_id_path,
  option_filter,
  option_paths,
  body,
  params,
  options: optionsProp,
  error,
  onChange,
  field_type,
}: MultiSelectProps) => {
  const { filterType, relation, getGroupLink } = useFilterContext();
  const [removeInvalidOptions, setRemoveInvalidOptions] = useState(false);
  const { isLoading, options, lookup, onMenuClose, onMenuOpen } =
    useFilterOptions(
      method,
      url,
      result_path,
      value_id_path,
      option_paths,
      option_filter,
      body,
      params,
      optionsProp,
      value
    );
  const [[invalidOptions, invalidOptionsLookup]] = useState<
    [Option[], Map<string, Option>]
  >(() => {
    const [opts, lookup] = buildOptions(
      value.filter((opt) => opt.not_found || opt.not_allowed),
      { value_id_path, option_paths }
    );
    return [opts.map((opt) => ({ ...opt, error: true })), lookup];
  });

  const allOptions = useMemo(() => {
    const invalidIds = new Set(invalidOptions.map((opt) => opt.value));
    return invalidOptions.concat(
      options.filter((opt) => !invalidIds.has(opt.value))
    );
  }, [invalidOptions, options]);

  const [prevOptions, setPrevOptions] = useState<SelectOption[]>(options);
  const [values, setValues] = useState<Option[]>(() => {
    return allOptions.filter(opt_filter(value, value_id_path));
  });

  if (prevOptions !== options) {
    setValues(allOptions.filter(opt_filter(value, value_id_path)));
    setPrevOptions(options);
  }

  const handleChange = useCallback(
    (opts: Option[]) => {
      setRemoveInvalidOptions(true);
      setValues(opts);
      onChange(step, {
        next,
        value: opts.map(
          (x) =>
            lookup.get(getSelectValue(x.value)) ??
            invalidOptionsLookup.get(getSelectValue(x.value))
        ),
      });
    },
    [step, next, lookup, invalidOptionsLookup, onChange]
  );

  const handleClear = () => {
    setValues([]);
    onChange(step, {
      next,
      value: [],
    });
  };

  const visibleOptions = useMemo(() => {
    if (removeInvalidOptions) {
      const invalidIds = invalidOptions.map((opt) => opt.value);
      return options.filter((x) => !invalidIds.includes(x.value));
    }
    return options;
  }, [options, removeInvalidOptions, invalidOptions]);

  const PillComponent = useMemo(() => {
    if (
      step === 'relationshipGroup' &&
      filterType === 'related_object' &&
      relation
    ) {
      return ({ value, ...rest }: any) => {
        return <GroupPill {...rest} value={value} href={getGroupLink(value)} />;
      };
    }
  }, [step, filterType, relation, getGroupLink]);

  return (
    <Multi
      value={values}
      options={visibleOptions}
      placeholder={placeholder}
      width={width}
      onChange={handleChange}
      loadItems={isLoading}
      error={error}
      menuTopButton={<SelectAllButton />}
      isColored={field_type === 'status'}
      showColorIndicatorInPill={field_type === 'status'}
      PillComponent={PillComponent}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      menuLeftButton={<ClearSelectButton onClick={handleClear} />}
      menuRightButton={<ApplySelectButton />}
    />
  );
};
