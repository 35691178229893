import React from 'react';
import PropTypes from 'prop-types';
import UnderlineSelect from './Underline';
import OutlineSelect from './Outline';
import useScheduleUpdate from './useScheduleUpdate';

const Select = React.forwardRef(function Select({ variant, ...props }, ref) {
  // This is for inline/overlays: see more info in implementation
  useScheduleUpdate(props);
  const VariantSelect =
    variant === 'underline' ? UnderlineSelect : OutlineSelect;
  return <VariantSelect ref={ref} {...props} />;
});

Select.propTypes = {
  variant: PropTypes.oneOf(['outline', 'underline']),
};

Select.defaultProps = {
  variant: 'outline',
};

export default Select;
