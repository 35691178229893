import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useEditor } from '@craftjs/core';
import { definePlaceholder } from '@kizen/page-builder/utils/fields';
import KizenTypography from 'app/kizentypo';
import { gutters, layers } from 'app/spacing';
import { isContact } from 'utility/fieldHelpers';
import { useModalControl } from 'hooks/useModalControl';
import { useBuilderContext } from '../BuilderContext';
import { TraySection, TraySubsection } from '../components/TraySection';
import {
  FlexSelect,
  FlexTextInput,
  FlexUnderlineMultiSelect,
  FlexColorPicker,
  FlexSwitch,
  FlexWrapper,
} from './components';

import Icon, { IconSizing } from 'components/Kizen/Icon';
import { LIST_OF_FIELDS } from 'components/GenericWizard/wizards/field/config';
import { useToggle } from 'react-use';
import { OverlayTooltip } from 'components/Kizen/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
import {
  getCustomObjectsSettingsAccess,
  getContactsAccess,
} from 'store/authentication/selectors';
import { EditFormField } from '../nodes/FormField/EditFormField';
import { useFonts, useFontOptionLookup } from './useFonts';
import {
  alignmentOptions,
  fontSizeOptions,
  textStyleOptions,
} from '../options';
import {
  isNodeCustomField,
  isPlaceholderMutable,
  canNodeBeDeleted,
  canNodeBeCustomized,
  setCustomizeFieldBroadcastChannel,
  isFieldNullable,
} from '../utils';
import {
  CUSTOMIZE_FIELDS_STEP_KEY,
  GO_BACK_KEYS,
} from 'components/Wizards/CustomObject/constants';
import { FIELD_TYPES } from 'utility/constants';

const inheritPageStylesProps = {
  useOwnStyles: false,
  fontFamily: null,
  fontSize: null,
  fontColor: null,
};

const displaNames = Object.values(LIST_OF_FIELDS).reduce((acc, field) => {
  if (field.type) {
    acc[field.type] = field.label;
  }
  return acc;
}, {});

const CustomizeFieldOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${gutters.spacing(2)}px;

  i {
    margin-left: ${gutters.spacing()}px;
  }
`;

const FontSelect = styled(FlexSelect)`
  // above all z-index values used in PageBuilder/components/UnderlineRadio (font style input)
  z-index: ${layers.content(2)};
`;

export const getLinkToObjectSettings = (model, field) => {
  const isContactObject = isContact(model);
  return `/custom-objects/${model.id}/settings?from=${
    isContactObject ? '/clients' : `/custom-objects/${model.id}`
  }&fromKey=${
    isContactObject ? GO_BACK_KEYS.CONTACTS : GO_BACK_KEYS.OVERVIEW
  }&focusStepKey=${CUSTOMIZE_FIELDS_STEP_KEY}&fieldId=${
    field.customObjectField.id
  }`;
};

const DecideLinkOrButton = ({ model, field, onOpenModal, children }) => {
  const props = {
    target: model ? '_blank' : undefined,
    href: model ? getLinkToObjectSettings(model, field) : '',
    onClick: model ? undefined : onOpenModal,
  };
  return (
    <KizenTypography type="anchor" {...props}>
      {children}
    </KizenTypography>
  );
};

const CustomizeFieldOptionsLink = (props) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [showTooltip, toggleTooltip] = useToggle(false);

  return (
    <CustomizeFieldOptionsContainer>
      <DecideLinkOrButton {...props}>
        {t('Customize Field Options')}
      </DecideLinkOrButton>
      <IconSizing size="10px">
        <Icon
          icon="info-circle"
          onMouseEnter={(ev) => {
            ref.current = ev.currentTarget;
            toggleTooltip();
          }}
          onMouseLeave={toggleTooltip}
        />
      </IconSizing>
      <Overlay show={showTooltip} target={ref.current}>
        <OverlayTooltip>
          {props.model
            ? t(
                'This will bring you to the customize fields page in a new tab.'
              )
            : t('This will allow you to customize the field.')}
        </OverlayTooltip>
      </Overlay>
    </CustomizeFieldOptionsContainer>
  );
};

const defaultValues = {
  font: 'Arial',
  size: '14',
  align: 'left',
  color: 'rgba(74,86,96,1)',
};

export const FieldSettingsSection = () => {
  const { t } = useTranslation();
  const contactsAccess = useSelector(getContactsAccess);
  const customObjectsSettingsAccess = useSelector(
    getCustomObjectsSettingsAccess
  );

  const { activeNode, form, onNodeUpdate } = useBuilderContext();

  const {
    id,
    data: { props },
  } = activeNode;
  const isCustomObjectField = Boolean(props.customObjectField);

  const {
    actions: { setProp },
  } = useEditor();

  const [showEditFieldModal, { showModal, hideModal }] = useModalControl();

  const { isContactForm, clearContentSettingsTray } = useBuilderContext();

  const [field, setField] = useState(props.field);

  const fontOptions = useFonts();
  const fontOptionLookup = useFontOptionLookup(fontOptions);

  const handleChangePlaceholderText = useCallback(
    (placeholder) => {
      setField((prev) => ({ ...prev, placeholder }));
      setProp(id, (p) => {
        p.field.placeholder = placeholder;
      });
    },
    [id, setProp, setField]
  );

  const handleChangeFontFamily = useCallback(
    ({ value }) => {
      setField((prev) => ({ ...prev, fontFamily: value }));
      setProp(id, (p) => {
        p.field.fontFamily = value === defaultValues.font ? null : value;
      });
    },
    [id, setProp, setField]
  );

  const handleChangeFontSize = useCallback(
    ({ value }) => {
      setField((prev) => ({ ...prev, fontSize: value }));
      setProp(id, (p) => {
        p.field.fontSize = value === defaultValues.size ? null : value;
      });
    },
    [id, setProp, setField]
  );

  const handleChangeFontStyle = useCallback(
    (value) => {
      setField((prev) => ({ ...prev, fontStyle: value }));
      setProp(id, (p) => {
        p.field.fontStyle = value;
      });
    },
    [id, setProp, setField]
  );

  const handleChangeAlignment = useCallback(
    ({ value }) => {
      setField((prev) => ({ ...prev, alignment: value }));
      setProp(id, (p) => {
        p.field.alignment = value === defaultValues.align ? null : value;
      });
    },
    [id, setProp, setField]
  );

  const handleChangeColor = useCallback(
    (value) => {
      setField((prev) => ({ ...prev, fontColor: value }));
      setProp(id, (p) => {
        p.field.fontColor = value === defaultValues.color ? null : value;
      });
    },
    [id, setProp, setField]
  );

  const handleChangeLabelText = useCallback(
    (labelText) => {
      onNodeUpdate(id, { displayName: labelText });
      setField((prev) => ({ ...prev, labelText }));
      setProp(id, (p) => {
        p.field.labelText = labelText;
      });
    },
    [id, setProp, setField, onNodeUpdate]
  );

  const handleChangeRequired = useCallback(
    ({ target: { checked } }) => {
      setField((prev) => ({
        ...prev,
        isRequired: checked,
        overwriteIfBlank: false,
      }));
      setProp(id, (p) => {
        p.field.isRequired = checked;
        p.field.overwriteIfBlank = false;
      });
    },
    [id, setProp, setField]
  );
  const handleChangeOverwrite = useCallback(
    ({ target: { checked } }) => {
      setField((prev) => ({ ...prev, overwriteIfBlank: checked }));
      setProp(id, (p) => {
        p.field.overwriteIfBlank = checked;
      });
    },
    [id, setProp, setField]
  );

  const handleChangeInheritPageStyles = useCallback(
    ({ target: { checked } }) => {
      setField((prev) => ({
        ...prev,
        ...(!checked ? { useOwnStyles: true } : inheritPageStylesProps),
      }));
      setProp(id, (p) => {
        !checked
          ? (p.field.useOwnStyles = true)
          : (p.field = { ...p.field, ...inheritPageStylesProps });
      });
    },
    [id, setProp, setField]
  );

  const handleFieldUpdate = useCallback(
    async (value) => {
      // We don't need to call setProp to update the CustomField node because that is being
      // handled in the builder node (nodes/CustomField/CustomField)
      if (!isCustomObjectField) {
        setProp(id, (p) => {
          p.field = { ...p.field, ...value };
        });
        const { options, meta } = value;
        if (meta) {
          onNodeUpdate(id, { meta });
        } else if (
          Array.isArray(options) &&
          options.length > 0 &&
          value.fieldType !== FIELD_TYPES.Text.type &&
          value.fieldType !== FIELD_TYPES.LongText.type
        ) {
          onNodeUpdate(id, { options });
        }
      }

      setField((prev) => ({
        ...prev,
        ...value,
        ...(prev.customObjectField
          ? { customObjectField: { ...prev.customObjectField, ...value } }
          : {}),
      }));
    },
    [isCustomObjectField, id, setField, onNodeUpdate, setProp]
  );

  useEffect(() => {
    if (props.field.customObjectField?.id) {
      const bc = setCustomizeFieldBroadcastChannel(
        props.field.customObjectField?.id,
        handleFieldUpdate
      );
      return () => bc.close();
    }
  }, [handleFieldUpdate, props.field.customObjectField?.id]);

  const ref = useRef(null);

  const canEditFieldOptions = useMemo(() => {
    if (isContact(form.relatedObject)) {
      return contactsAccess.objectSettings.customizeFields.edit;
    } else {
      return customObjectsSettingsAccess[form.relatedObject.id].customizeFields
        .edit;
    }
  }, [contactsAccess, customObjectsSettingsAccess, form.relatedObject]);

  return (
    <>
      <TraySection
        onBackClick={clearContentSettingsTray}
        flex
        collapsable={false}
        header={`${displaNames[field.fieldType] ?? field.fieldType} ${t(
          'Settings'
        )}`}
        ref={ref}
      >
        {canNodeBeCustomized(activeNode) && canEditFieldOptions && (
          <TraySubsection>
            <CustomizeFieldOptionsLink
              field={props.field}
              model={isNodeCustomField(activeNode) ? form.relatedObject : null}
              onOpenModal={(e) => {
                e.preventDefault();
                showModal();
              }}
            />
          </TraySubsection>
        )}
        <TraySubsection header={t('Properties')}>
          {canNodeBeDeleted(activeNode, isContactForm) ? (
            <FlexWrapper>
              <FlexSwitch
                checked={field.isRequired}
                label={t('Required')}
                flex={1}
                textPlacement="top"
                onChange={handleChangeRequired}
              />
              {isFieldNullable(field) ? (
                <FlexSwitch
                  checked={field.overwriteIfBlank}
                  label={t('Overwrite With Blank')}
                  flex={1}
                  textPlacement="top"
                  onChange={handleChangeOverwrite}
                  tooltip={t(
                    'If this field is blank/empty when submitted, it will overwrite any previous value with a blank value'
                  )}
                />
              ) : null}
            </FlexWrapper>
          ) : null}
          <FlexWrapper>
            <FlexTextInput
              label={t('Label text')}
              variant="underline"
              flex={1}
              value={
                typeof field.labelText === 'string'
                  ? field.labelText
                  : field.displayName
              }
              onChange={handleChangeLabelText}
              onBlur={() => {
                if (field.labelText === '') {
                  handleChangeLabelText(field.displayName);
                }
              }}
            />
            {isPlaceholderMutable(props.field.fieldType) ? (
              <FlexTextInput
                label={t('Placeholder text')}
                variant="underline"
                flex={1}
                value={
                  typeof field.placeholder === 'string'
                    ? field.placeholder
                    : definePlaceholder(field, t)
                }
                onChange={handleChangePlaceholderText}
                onBlur={() => {
                  if (field.placeholder === '') {
                    handleChangePlaceholderText(definePlaceholder(field, t));
                  }
                }}
              />
            ) : null}
          </FlexWrapper>
          <FlexWrapper>
            <FlexSwitch
              checked={!field.useOwnStyles}
              label={t('Inherit Page Styles')}
              flex={1}
              textPlacement="top"
              onChange={handleChangeInheritPageStyles}
              tooltip={t(
                'Use the same font styles and colors as defined in the PAGE SETTINGS'
              )}
            />
          </FlexWrapper>
        </TraySubsection>
        <TraySubsection header={t('Label')}>
          <FlexWrapper>
            <FlexUnderlineMultiSelect
              label={t('Style')}
              variant="outline"
              value={field.fontStyle}
              options={textStyleOptions}
              onChange={handleChangeFontStyle}
            />
            <FlexSelect
              variant="underline"
              label={t('Alignment')}
              value={field.alignment || defaultValues.align}
              options={alignmentOptions(t)}
              onChange={handleChangeAlignment}
              scrollContainer={ref.current}
            />
          </FlexWrapper>
          {field.useOwnStyles ? (
            <>
              <FlexWrapper>
                <FontSelect
                  variant="underline"
                  label={t('Font')}
                  value={
                    fontOptionLookup[field.fontFamily] ||
                    fontOptionLookup[defaultValues.font]
                  }
                  options={fontOptions}
                  onChange={handleChangeFontFamily}
                  scrollContainer={ref.current}
                />
                <FlexSelect
                  variant="underline"
                  label={t('Size')}
                  value={field.fontSize || defaultValues.size}
                  options={fontSizeOptions}
                  onChange={handleChangeFontSize}
                  scrollContainer={ref.current}
                />
              </FlexWrapper>
              <FlexWrapper>
                <FlexColorPicker
                  color={field.fontColor || defaultValues.color}
                  label={t('Label Color')}
                  onChange={handleChangeColor}
                />
              </FlexWrapper>
            </>
          ) : null}
        </TraySubsection>
      </TraySection>
      {showEditFieldModal ? (
        <EditFormField
          formFieldHeading={t('Edit Field')}
          data={{ field }}
          show={showEditFieldModal}
          onHide={hideModal}
          setProp={handleFieldUpdate}
        />
      ) : null}
    </>
  );
};
