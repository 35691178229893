import { PropsWithChildren } from 'react';
import { PrimaryObjectUploadContext } from './context';
import { usePrimaryObjectUploadContext } from './hooks/usePrimaryObjectUploadContext';

export const PrimaryObjectUploadProvider = ({
  children,
}: PropsWithChildren) => {
  const context = usePrimaryObjectUploadContext();
  return (
    <PrimaryObjectUploadContext.Provider value={context}>
      {children}
    </PrimaryObjectUploadContext.Provider>
  );
};
