import {
  DEFAULT_DIVIDER_CONTAINER_PROPS,
  EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
} from '@kizen/page-builder/nodes/containers';

export const DEFAULT_FORM_DIVIDER_PROPS = {
  ...DEFAULT_DIVIDER_CONTAINER_PROPS,
  alignment: 'center',
  borderStyle: 'solid',
  color: '#A8B3BC',
  size: '1',
  width: '100',
};

export const DEFAULT_EMAIL_DIVIDER_PROPS = {
  ...DEFAULT_FORM_DIVIDER_PROPS,
  ...EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
};
