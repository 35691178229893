import {
  cloneElement,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import {
  useRelationshipSelect,
  useSelectEnter,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { TopMenuButton } from 'components/Kizen/Menu';
import { applyRef } from 'components/Inputs/props';
import MultiSelect, {
  multiSelectSortValue,
} from 'components/Inputs/MultiSelect';
import { ViewOnlyMultiSelect } from 'components/Inputs/MultiSelect/ViewOnlyMultiSelect';
import CreateEntityModalFull from 'components/Modals/CreateEntity/FullForm';
import { useEntityCreationModal } from 'components/Modals/CreateEntity/hook';
import MultiSelectInline from 'components/Inputs/inline/MultiSelect';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import { getSelectedValueLink } from '../../Inputs/MultiSelect/customize';
import { RELATION_FIELD } from 'queries/query-keys';
import { useQueryClient } from 'react-query';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';

// Implementation based on FieldInput/RelationshipMany
export const RelationshipManySelect = forwardRef(
  (
    {
      field,
      object,
      value = EMPTY_ARRAY,
      relationship = EMPTY_OBJECT,
      disabled = false,
      onChange = null,
      onModalOpen = null,
      modalOpen = false,
      categorizedFields = EMPTY_ARRAY,
      onFocus,
      onMenuOpen,
      onMenuClose,
      endAdornmentIcon,
      selectOverlayProps,
      enableLink = true,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const selectRef = useRef(null);
    const queryClient = useQueryClient();
    const {
      initialValues,
      setInitialValues,
      parseTypeAhead,
      objectType,
      hasAbilityToAdd,
    } = useEntityCreationModal(field);

    const isContact = field.relation?.fetchUrl === 'client';

    //TODO: we should unify options. right now item can have value or id
    const chosenValueIds = useMemo(
      () => (value || []).map((item) => item.value ?? item.id),
      [value]
    );

    const [typeaheadProps, typeaheadState, { refetch }] =
      useSelectTypeaheadWithScroll({
        alwaysOpen: true, // The select mounts with the menu open
        fetch: relationship.search,
        objectToOption: relationship.toOption,
        fieldId: field.id,
        onFocus,
        onMenuOpen,
        onMenuClose: selectOverlayProps,
        selectRef,
        chosenValueIds,
        relatedObjectId: field.relation?.relatedObject,
      });

    const entityCreatedHandler = useCallback(
      async (newEntity) => {
        onChange(
          multiSelectSortValue([...value, relationship.toOption(newEntity)])
        );
        await refetch();
        // need to invalidate all the fields that are related to this object
        await queryClient.invalidateQueries({
          queryKey: [
            ...RELATION_FIELD.RELATED_OBJECTS(field.relation.relatedObject),
          ],
        });
        setInitialValues({});
      },
      [
        onChange,
        value,
        relationship,
        refetch,
        queryClient,
        field.relation.relatedObject,
        setInitialValues,
      ]
    );

    const onStartCreate = () => {
      if (typeaheadProps.inputValue) {
        parseTypeAhead(typeaheadProps.inputValue, isContact);
      }
      onModalOpen(true);
      typeaheadProps.onInputChange('');
    };

    const selectEnterProps = useSelectEnter({
      handlePressEnter: onStartCreate,
      options: typeaheadProps.options,
      loadingOptions: typeaheadState.loading,
    });

    const ObjectLink = useMemo(
      () =>
        relationship.link && enableLink
          ? getSelectedValueLink({
              genLink: relationship.link,
            })
          : null,
      [relationship.link, enableLink]
    );

    const mergeRef = useCallback(
      (el) => {
        applyRef(selectRef, el);
        applyRef(ref, el);
      },
      [ref]
    );
    const fieldId = useMemo(
      () => `${field?.id}-${object?.id}`,
      [field, object]
    );
    return (
      <>
        <MultiSelect
          fieldId={fieldId}
          ref={mergeRef}
          value={value}
          disabled={disabled}
          placeholder={`${t('Find')} ${relationship.name.plural}`}
          menuTopButton={
            hasAbilityToAdd && (
              <TopMenuButton onClick={onStartCreate}>
                {`${t('Add')} ${relationship.name.single}`}
              </TopMenuButton>
            )
          }
          onChange={onChange}
          PillComponent={ObjectLink}
          {...selectEnterProps}
          {...props}
          endAdornment={endAdornmentIcon}
          isLoading={typeaheadState?.loading}
          {...typeaheadProps}
        />

        {modalOpen && (
          <CreateEntityModalFull
            objectType={objectType}
            objectId={isContact ? null : field.relation?.relatedObject}
            show={modalOpen}
            onCreated={entityCreatedHandler}
            onCancel={() => {
              onModalOpen(false);
              setInitialValues({});
            }}
            initialValues={initialValues}
          />
        )}
      </>
    );
  }
);

RelationshipManySelect.displayName = 'RelationshipManySelect';

export default function RelationshipManyInline({
  field,
  value: relationshipValue = null,
  onSubmit,
  className,
  disabled = null,
  children: selectEl = <RelationshipManySelect />,
  fieldProps = EMPTY_OBJECT,
  handleUpdateTableRecords,
  viewOnlySelect,
  enableLink = true,
  ...others
}) {
  const [{ displayValue: value, handleChange: handleSubmit, relationship }] =
    useRelationshipSelect({
      isMulti: true,
      field,
      value: relationshipValue,
      onChange: onSubmit,
    });

  const ObjectLink = useMemo(
    () =>
      relationship.link && enableLink
        ? getSelectedValueLink({
            genLink: relationship.link,
          })
        : null,
    [relationship.link, enableLink]
  );
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <MultiSelectInline
        icon={'search'}
        onSubmit={handleSubmit}
        rootCloseDisabled={modalOpen}
        handleUpdateTableRecords={handleUpdateTableRecords}
        viewOnlySelect={viewOnlySelect}
        field={field}
        enableLink={enableLink}
        {...others}
      >
        {!viewOnlySelect ? (
          cloneElement(selectEl, {
            value,
            disabled,
            modalOpen,
            onModalOpen: setModalOpen,
            field,
            relationship,
            menuLeftButton: <ClearSelectButton />,
            menuRightButton: <ApplySelectOverlayButton />,
            endAdornment: <IconAdornment icon={'search'} />,
            ...fieldProps,
          })
        ) : (
          <ViewOnlyMultiSelect
            value={value}
            field={field}
            {...fieldProps}
            relationship={relationship}
            PillComponent={ObjectLink}
          />
        )}
      </MultiSelectInline>
    </>
  );
}
