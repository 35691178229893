import AuthenticationService from 'services/AuthenticationService';
import TeamMemberService from 'services/TeamMemberService';
import * as auth from 'utility/Authentication';
import { clearI18nKeyFromStorage } from 'i18n-config';
import { invalidate } from 'queries/invalidate';
import { RedirectLocalStorage } from 'utility/RedirectLocalStorage';
import loginCounter from 'pages/Login/LoginCounter';
import { filterMetaDataSuccess } from 'store/filterMetaData/metaDataActions';

/* -------------------- */
// Login Actions
/* -------------------- */

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const UPDATE_PLUGIN_LIST_SUCCESS = 'UPDATE_PLUGIN_LIST_SUCCESS';

/* -------------------- */
// Logout Actions
/* -------------------- */
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

/* -------------------- */
// Choose Business Actions
/* -------------------- */
export const CHOOSE_BUSINESS_START = 'CHOOSE_BUSINESS_START';
export const CHOOSE_BUSINESS_SUCCESS = 'CHOOSE_BUSINESS_SUCCESS';
export const CHOOSE_BUSINESS_FAILURE = 'CHOOSE_BUSINESS_FAILURE';

/* -------------------- */
// Update Business Actions
/* -------------------- */
export const UPDATE_BUSINESS_START = 'UPDATE_BUSINESS_START';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE';

/* -------------------- */
// Rehydrate Actions
/* -------------------- */
export const REHYDRATE_START = 'REHYDRATE_START';
export const REHYDRATE_SUCCESS = 'REHYDRATE_SUCCESS';
export const REHYDRATE_FAILURE = 'REHYDRATE_FAILURE';

/* -------------------- */
// Request Password Reset Actions
/* -------------------- */

export const REQUEST_PASSWORD_RESET_START = 'REQUEST_PASSWORD_RESET_START';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';

/* -------------------- */
// Reset Password Actions
/* -------------------- */

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

/* -------------------- */
// Verify Account Actions
/* -------------------- */

export const VERIFY_ACCOUNT_START = 'VERIFY_ACCOUNT_START';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAILURE = 'VERIFY_ACCOUNT_FAILURE';

/* -------------------- */
// Refresh Access Actions
/* -------------------- */

export const REFRESH_ACCESS_START = 'REFRESH_ACCESS_START';
export const REFRESH_ACCESS_SUCCESS = 'REFRESH_ACCESS_SUCCESS';
export const REFRESH_ACCESS_FAILURE = 'REFRESH_ACCESS_FAILURE';

/* -------------------- */
// Refresh Picture Actions
/* -------------------- */

export const REFRESH_TEAM_MEMBER_SUCCESS = 'REFRESH_TEAM_MEMBER_SUCCESS';
export const REFRESH_TEAM_MEMBER_FAILURE = 'REFRESH_TEAM_MEMBER_FAILURE';

/* -------------------- */
// Show logout message
/* -------------------- */

export const SHOW_INACTIVITY_MESSAGE = 'SHOW_INACTIVITY_MESSAGE';
export const SHOW_TIMEOUT_MESSAGE = 'SHOW_TIMEOUT_MESSAGE';

/* -------------------- */
// Authentication Action Creators
/* -------------------- */

export const showInactivityMessage = () => ({
  type: SHOW_INACTIVITY_MESSAGE,
});
export const showTimeoutMessage = () => ({
  type: SHOW_TIMEOUT_MESSAGE,
});

export const chooseBusinessStart = (businessId) => ({
  type: CHOOSE_BUSINESS_START,
  businessId,
});

export const chooseBusinessSuccess = ({
  business,
  access,
  teamMember,
  user,
  externalApps,
  pluginApps,
}) => ({
  type: CHOOSE_BUSINESS_SUCCESS,
  business,
  access,
  teamMember,
  user,
  externalApps,
  pluginApps,
});

export const chooseBusinessFailure = (error) => ({
  type: CHOOSE_BUSINESS_FAILURE,
  error,
});

export const serviceChooseBusinessStart =
  (businessId) => async (dispatch, getState) => {
    dispatch(chooseBusinessStart());

    try {
      auth.status.businessId = businessId;
      invalidate.ALL();

      const {
        user,
        access,
        teamMember,
        business,
        filterMetaData,
        externalApps,
        pluginApps,
      } = await AuthenticationService.bootstrapSession();

      dispatch(
        chooseBusinessSuccess({
          business,
          access,
          teamMember,
          user,
          externalApps,
          pluginApps,
        })
      );
      dispatch(filterMetaDataSuccess(filterMetaData));
      const { authentication } = getState();
      return [null, authentication.chosenBusiness];
    } catch (error) {
      dispatch(chooseBusinessFailure(error));
      invalidate.ALL();
      return [error, null];
    }
  };

export const updateBusinessStart = () => ({
  type: UPDATE_BUSINESS_START,
});

export const updateBusinessSuccess = ({ business }) => ({
  type: UPDATE_BUSINESS_SUCCESS,
  business,
});

export const updateBusinessFailure = (error) => ({
  type: UPDATE_BUSINESS_FAILURE,
  error,
});

export const serviceUpdateBusinessStart =
  (patch) => async (dispatch, getState) => {
    try {
      dispatch(updateBusinessStart());
      const updatedBusiness =
        await AuthenticationService.updateBusinessInformation(patch);
      dispatch(updateBusinessSuccess({ business: updatedBusiness }));
      const { authentication } = getState();
      return [null, authentication.chosenBusiness];
    } catch (error) {
      dispatch(updateBusinessFailure(error));
      return [error, null];
    }
  };

export const loginStart = () => ({
  type: LOGIN_START,
});

export const loginSuccess = ({ user, access, teamMember }) => ({
  type: LOGIN_SUCCESS,
  user,
  access,
  teamMember,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  error,
});

export const updatePluginAppsListSuccess = (pluginApps) => ({
  type: UPDATE_PLUGIN_LIST_SUCCESS,
  pluginApps,
});

export const serviceLoginStart =
  (username, password) => async (dispatch, getState) => {
    dispatch(loginStart());

    try {
      let user;

      try {
        const result = await AuthenticationService.login(username, password);
        user = result.user;
        auth.status.authorized = true;
        invalidate.ALL();
      } catch (error) {
        // Clear any token on failed login (or rehydrate attempt)
        // This only applies to login() because safari was canceling
        // fetchAccess() due to a page reload but still able to trigger logout.

        await AuthenticationService.logout();
        auth.status.clear();
        throw error;
      }
      // Fetch access if the user already has a chosen business.
      // NOTE coupled to reducer logic for detecting a chosen business.
      const access = user.first_business
        ? await AuthenticationService.fetchAccess()
        : null;

      // if a business is set get the users teamMember
      const teamMember = user.first_business
        ? await TeamMemberService.getTeamMemberData()
        : null;

      dispatch(loginSuccess({ user, access, teamMember }));
      loginCounter.reset(username);
      const { authentication } = getState();
      return [
        null,
        {
          user: authentication.user,
          chosenBusiness: authentication.chosenBusiness,
        },
      ];
    } catch (error) {
      dispatch(loginFailure(error));
      invalidate.ALL();
      return [error, null];
    }
  };

export const ssoLoginStart = (username) => async (dispatch, getState) => {
  dispatch(loginStart());

  try {
    let user;
    let access;
    let teamMember;
    let business;
    let filterMetaData;
    let externalApps;
    let pluginApps;

    try {
      ({
        user,
        access,
        teamMember,
        business,
        filterMetaData,
        externalApps,
        pluginApps,
      } = await AuthenticationService.bootstrapSession());

      auth.status.authorized = true;

      invalidate.ALL();
    } catch (error) {
      // Clear any token on failed login (or rehydrate attempt)
      // This only applies to login() because safari was canceling
      // fetchAccess() due to a page reload but still able to trigger logout.
      await AuthenticationService.logout();
      auth.status.clear();
      throw error;
    }

    if (business) {
      dispatch(
        chooseBusinessSuccess({
          business,
          access,
          teamMember,
          user,
          externalApps,
          pluginApps,
        })
      );
      dispatch(filterMetaDataSuccess(filterMetaData));
      auth.status.businessId = business.id;
    } else {
      dispatch(
        loginSuccess({
          user,
          access: null,
          teamMember: null,
          filterMetaData,
          externalApps,
          pluginApps,
        })
      );
      dispatch(filterMetaDataSuccess(filterMetaData));
    }

    loginCounter.reset(username);
    const { authentication } = getState();

    return [
      null,
      {
        user: authentication.user,
        chosenBusiness: business ? authentication.chosenBusiness : null,
      },
    ];
  } catch (error) {
    dispatch(loginFailure(error));
    invalidate.ALL();
    return [error, null];
  }
};

export const refreshPluginAppList = () => async (dispatch) => {
  try {
    const { pluginApps } = await AuthenticationService.bootstrapSession();
    dispatch(updatePluginAppsListSuccess(pluginApps));
    return [null, pluginApps];
  } catch (error) {
    return [error, null];
  }
};

export const logoutStart = () => ({
  type: LOGOUT_START,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutFailure = (error) => ({
  type: LOGOUT_FAILURE,
  error,
});

export const serviceLogoutStart =
  ({ skipApi = false, redirect = null } = {}) =>
  async (dispatch) => {
    try {
      const localStorage = new RedirectLocalStorage('loginRedirect');
      localStorage.path = redirect;

      dispatch(logoutStart());

      if (!skipApi) {
        try {
          // if we get an error with logout it means we don't have a connection, so carry on to avoid infinite loop
          await AuthenticationService.logout();
        } catch {
          // do nothing
        }
      }
      dispatch(logoutSuccess());
      return [null, true];
    } catch (error) {
      dispatch(logoutFailure(error));
      return [error, null];
    } finally {
      // we delete the token from localStorage regardless of result
      // so, even if logout failed server-side, we prevent the user from continuing
      // to login / use the site (if the bug resulted in the token
      // NOT being deleted) unless they copied the token elsewhere
      auth.status.clear();
      invalidate.ALL();

      // clear i18n property from localStorage
      clearI18nKeyFromStorage();
    }
  };

export const rehydrateStart = () => ({
  type: REHYDRATE_START,
});

// business is optional, set only in the case of
// rehydrating a session and used by the reducer only
// in the case of a multi-business user's session
export const rehydrateSuccess = ({
  user,
  access,
  business,
  teamMember,
  externalApps,
  pluginApps,
}) => ({
  type: REHYDRATE_SUCCESS,
  user,
  access,
  business,
  teamMember,
  externalApps,
  pluginApps,
});

export const rehydrateFailure = (error) => ({
  type: REHYDRATE_FAILURE,
  error,
});

export const serviceRehydrateStart =
  (skipAccessFetch = false) =>
  async (dispatch) => {
    try {
      dispatch(rehydrateStart());
      if (skipAccessFetch) return dispatch(rehydrateSuccess({}));

      const {
        user,
        business,
        access,
        teamMember,
        filterMetaData,
        externalApps,
        pluginApps,
      } = await AuthenticationService.bootstrapSession();

      if (!business && !user) {
        // bootstrapSession won't throw an error when going direct to wapapp api like hapi did
        // so set the loginRedirect
        const localStorage = new RedirectLocalStorage('loginRedirect');
        localStorage.path = window.location.pathname;
      }

      invalidate.ALL();

      dispatch(
        rehydrateSuccess({
          user,
          access,
          business,
          teamMember,
          externalApps,
          pluginApps,
        })
      );
      dispatch(filterMetaDataSuccess(filterMetaData));

      return [null, { user, business }];
    } catch (error) {
      // Clear any token on failed login (or rehydrate attempt)
      auth.status.clear();
      invalidate.ALL();
      dispatch(rehydrateFailure(error));
      return [error, null];
    }
  };

export const requestPasswordResetStart = () => ({
  type: REQUEST_PASSWORD_RESET_START,
});

export const requestPasswordResetSuccess = () => ({
  type: REQUEST_PASSWORD_RESET_SUCCESS,
});

export const requestPasswordResetFailure = (error) => ({
  type: REQUEST_PASSWORD_RESET_FAILURE,
  error,
});

export const serviceRequestPasswordResetStart = (email) => async (dispatch) => {
  try {
    dispatch(requestPasswordResetStart());
    await AuthenticationService.requestPasswordReset(email);
    dispatch(requestPasswordResetSuccess());
    loginCounter.reset(email);
    return [null, true];
  } catch (error) {
    dispatch(requestPasswordResetFailure(error));
    return [error, null];
  }
};

export const resetPasswordStart = () => ({
  type: RESET_PASSWORD_START,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  error,
});

export const serviceResetPasswordStart =
  (token, password) => async (dispatch) => {
    try {
      dispatch(resetPasswordStart());
      await AuthenticationService.resetPassword(token, password);
      dispatch(resetPasswordSuccess());
      return [null, true];
    } catch (error) {
      dispatch(resetPasswordFailure(error));
      return [error, null];
    }
  };

export const refreshAccessStart = () => ({
  type: REFRESH_ACCESS_START,
});

export const refreshAccessSuccess = ({ access }) => ({
  type: REFRESH_ACCESS_SUCCESS,
  access,
});

export const refreshAccessFailure = (error) => ({
  type: REFRESH_ACCESS_FAILURE,
  error,
});

export const serviceRefreshAccessStart = () => async (dispatch) => {
  dispatch(refreshAccessStart());
  try {
    const access = await AuthenticationService.fetchAccess();
    dispatch(refreshAccessSuccess({ access }));
    return [null, access];
  } catch (error) {
    dispatch(refreshAccessFailure(error));
    return [error, null];
  }
};

export const refreshTeamMemberSuccess = ({ teamMember }) => ({
  type: REFRESH_TEAM_MEMBER_SUCCESS,
  teamMember,
});

export const refreshTeamMemberFailure = (error) => ({
  type: REFRESH_TEAM_MEMBER_FAILURE,
  error,
});
