import { ACTIONS, COMMUNICATIONS } from './constants';

export const runFrameScriptEventName = `integration:${COMMUNICATIONS.RUN_FRAME_SCRIPT}`;

export class Communicate {
  private instance: any;

  constructor(instance: any) {
    this.instance = instance;
  }

  public runFrameScript(
    frameAPIName: string,
    scriptId: string,
    args?: Record<string, string | number>
  ) {
    this.instance.postMessage(
      JSON.stringify({
        action: ACTIONS.COMMUNICATE,
        type: COMMUNICATIONS.RUN_FRAME_SCRIPT,
        eventName: runFrameScriptEventName,
        recipient: {
          frame: frameAPIName,
          script: scriptId,
        },
        args,
      })
    );
  }

  public sendMessageToOwnFrame(payload: any, path: string) {
    this.instance.postMessage(
      JSON.stringify({
        action: ACTIONS.COMMUNICATE,
        type: COMMUNICATIONS.SEND_MESSAGE_TO_FRAME,
        args: {
          payload,
          path,
        },
      })
    );
  }
}
