import { useCallback, useEffect, useState } from 'react';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import { useTranslation } from 'react-i18next';
import { BROADCAST_FORMDATA, defaultBroadcastFormData } from './constants';
import {
  FiltersButtonGrid,
  StyledGenericWizardSection,
  StyledLastSection,
  StyledLoader,
} from './styled';
import { isCustomFilter, isGroupFilter } from 'ts-filters/legacy';

import { MyRecordsFilterWarning } from 'components/Wizards/MetaFilters';
import { FilterSummary } from './FilterSummary';
import { useCanViewSummary } from 'pages/Broadcasts/hooks/useCanViewSummary';
import TimeZoneFilterWarning from 'components/Wizards/MetaFilters/TimeZoneFilterWarning';
import { FilterGroupsSelect } from './FilterGroupsSelect';
import { isContact } from 'utility/fieldHelpers';
import { FilterSets } from 'components/Wizards/MetaFilters/FilterSets';
import { useWizardFilterContext } from 'components/Wizards/MetaFilters/context';
import { useRelatedGroupsMatchHelper } from 'components/Wizards/MetaFilters/hooks/useRelatedGroupsMatchHelper';

export const BroadcastFilters = ({
  setFormData,
  disabled,
  customObject,
  filterConfig,
  broadcastType,
  isActive,
  isEdit,
  usingCache,
}) => {
  const { t } = useTranslation();

  const filterContext = useWizardFilterContext();

  const [showSummary, setShowSummary] = useState(isEdit);

  const {
    setFilter: _setFilter,
    filterSets,
    filterOps: { hasMyRecordFilter, hasStageRecordFilter },
    setValues,
    conditionTypeOptions,

    groupOptions,
    loading,
    valid,
    values,
  } = filterContext;

  const { groupIsMe = false, groupIsStage = false } =
    useRelatedGroupsMatchHelper(filterSets);

  const setFilter = useCallback(
    ({ updateValidity, ...filter }, ...args) => {
      _setFilter(
        {
          ...filter,
          invalid: updateValidity ? filter.invalid : undefined,
        },
        ...args
      );
      setShowSummary(false);
    },
    [_setFilter, setShowSummary]
  );

  useEffect(() => {
    const { type, groups, filter } = values;
    setFormData(BROADCAST_FORMDATA.filterType, type);
    if (isCustomFilter(type)) {
      setFormData(BROADCAST_FORMDATA.filterGroupsIds, []);
      setFormData(
        BROADCAST_FORMDATA.filterConfig,
        valid
          ? filter
          : defaultBroadcastFormData[BROADCAST_FORMDATA.filterConfig]
      );
    } else {
      setFormData(
        BROADCAST_FORMDATA.filterGroupsIds,
        valid ? groups.map(({ id }) => id) : []
      );
      setFormData(
        BROADCAST_FORMDATA.filterConfig,
        defaultBroadcastFormData[BROADCAST_FORMDATA.filterConfig]
      );
    }
  }, [values, valid, setFormData]);

  const canViewSammary =
    useCanViewSummary(broadcastType, customObject?.id) && !usingCache;

  return (
    <>
      <StyledGenericWizardSection
        header={
          isContact(customObject)
            ? t('Broadcast To Contacts')
            : t('Broadcast To Records')
        }
        isActive={isActive}
        index={2}
        isLastIndex={!(canViewSammary && isActive && !loading)}
        leavePAlone
      >
        {isActive ? (
          <StyledLoader loading={loading}>
            <div
              onClick={() => {
                if (!disabled) {
                  const modal = document.querySelector('.modal-body');
                  modal.scrollBy({
                    top: modal.scrollHeight - modal.offsetHeight,
                  });
                }
              }}
              data-qa-filter-invalid={
                isCustomFilter(values.type) && (!valid || filterConfig?.invalid)
              }
              data-qa-group-invalid={isGroupFilter(values.type) && !valid}
            >
              <FiltersButtonGrid>
                {conditionTypeOptions.map((option) => (
                  <ButtonGroupImageButton
                    key={option.value}
                    selected={option.value === values.type}
                    onClick={() => {
                      if (option.value !== values.type) {
                        setValues({
                          ...values,
                          type: option.value,
                        });
                        setShowSummary(false);
                      }
                    }}
                    option={option}
                    disabled={disabled}
                    data-qa-filter-type={option.value}
                  />
                ))}
              </FiltersButtonGrid>
              {isCustomFilter(values.type) ? (
                <FilterSets hideOutOfBoundaries />
              ) : (
                <FilterGroupsSelect
                  values={values}
                  customObjectId={customObject?.id}
                  setValues={setValues}
                  groupOptions={groupOptions}
                  disabled={disabled}
                  setShowSummary={setShowSummary}
                />
              )}
              <MyRecordsFilterWarning
                isMyRecordsResultToZero
                hasMyRecordFilter={hasMyRecordFilter || groupIsMe}
                {...values}
              />
              <TimeZoneFilterWarning
                hasStageRecordFilter={hasStageRecordFilter || groupIsStage}
                {...values}
              />
            </div>
          </StyledLoader>
        ) : null}
      </StyledGenericWizardSection>
      {canViewSammary && isActive && !loading ? (
        <StyledLastSection
          header={t('Preview Send')}
          isActive
          index={3}
          isLastIndex
        >
          <FilterSummary
            values={values}
            valid={valid}
            setFilter={setFilter}
            customObject={customObject}
            broadcastType={broadcastType}
            showSummary={showSummary}
            setShowSummary={setShowSummary}
          />
        </StyledLastSection>
      ) : null}
    </>
  );
};
