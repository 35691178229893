import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { breakpoints, useBreakpoint } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import BasicTable from 'components/Tables/Basic';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import {
  TRow,
  TextEllipsis,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
} from 'components/Layout/ListCard';
import ColorLabel from 'components/Fields/ColorLabel';
import TableHeader from '../TableHeader';
import {
  getSubscriptionColumns,
  MobileSubscriptionActionCell,
  subscriptionStatusColors,
  subscriptionStatusLabel,
  WrapperStatus,
  EmptyActionCell,
} from '../columns';
import {
  TableScrollWrapper,
  StyledListCardCell,
  StyledWrapper,
} from '../styles';
import SuppressionListMessage from './styles';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';
import { useCanManageSubscriptions } from 'ts-components/hooks/permissions/subscriptions';

export const ClientSubscriptionTable = ({
  subscriptions,
  subscriptionsSort,
  handleSortSubscriptions,
  emailIsSuppressionList,
  handleChangeSubStatus,
  isClient,
  entityObject,
}) => {
  const { t } = useTranslation();

  const isMobile = useBreakpoint(breakpoints.md);

  const canManageSubscriptions = useCanManageSubscriptions({
    isClient,
    entityObject,
  });

  const subscriptionsColumns = useMemo(
    () =>
      getSubscriptionColumns(
        {
          emailIsSuppressionList,
          onSelectAction: handleChangeSubStatus,
          editSubscriptionsAccess: canManageSubscriptions,
        },
        t
      ),
    [emailIsSuppressionList, handleChangeSubStatus, canManageSubscriptions, t]
  );

  const subscriptionsHeadData = useMemo(
    () => ({
      meta: {
        sort: subscriptionsSort,
        onSort: (column, direction) =>
          handleSortSubscriptions({ column, direction }),
      },
    }),
    [subscriptionsSort, handleSortSubscriptions]
  );

  if (isMobile) {
    return (
      <>
        {emailIsSuppressionList && (
          <SuppressionListMessage>
            <KizenTypography>
              {t(
                'This contact is suppressed from receiving messages from Kizen due to a spam complaint or too many bounces. Please reach out to resolve this before attempting to send more messages.'
              )}
            </KizenTypography>
          </SuppressionListMessage>
        )}
        <TableScrollWrapper compactHeader shadow bottom>
          <StyledWrapper>
            <ListCardHeader
              compactHeader
              stickyHeader
              action={<div style={{ width: 15 }} />}
              withScrollbarPadding
              className="sticky-header-with-shadow"
            >
              <ListCardCell style={{ minHeight: '42px' }}>
                <TextEllipsis as="span" weight="bold">
                  {t('Subscription List Name')}
                </TextEllipsis>
                <ListCardSortButton
                  sort={subscriptionsSort}
                  onChangeSort={(direction) =>
                    handleSortSubscriptions({ column: 'name', direction })
                  }
                />
              </ListCardCell>
            </ListCardHeader>
            {subscriptions.map((sub) => (
              <ListCardItem
                key={sub.id}
                style={{ minHeight: '64px' }}
                action={
                  emailIsSuppressionList || !canManageSubscriptions ? (
                    <EmptyActionCell width="15px" />
                  ) : (
                    <MobileSubscriptionActionCell
                      data={sub}
                      onSelectAction={(val) => handleChangeSubStatus(val, sub)}
                      position="left"
                      t={t}
                    />
                  )
                }
                withScrollbarPadding
              >
                <ListCardRow>
                  <StyledListCardCell>
                    <TextEllipsisWithTooltip>
                      {sub.name}
                    </TextEllipsisWithTooltip>
                  </StyledListCardCell>
                </ListCardRow>
                <ListCardRow>
                  <ListCardCell>
                    <WrapperStatus>
                      <ColorLabel
                        color={
                          subscriptionStatusColors[
                            emailIsSuppressionList
                              ? 'suppression_list'
                              : sub.status
                          ]
                        }
                      >
                        {subscriptionStatusLabel[
                          emailIsSuppressionList
                            ? 'suppression_list'
                            : sub.status
                        ](t)}
                      </ColorLabel>
                    </WrapperStatus>
                  </ListCardCell>
                  <ListCardCell />
                </ListCardRow>
              </ListCardItem>
            ))}
          </StyledWrapper>
        </TableScrollWrapper>
      </>
    );
  }

  return (
    <>
      <TableHeader title={t('Subscription Lists')} />
      {emailIsSuppressionList && (
        <SuppressionListMessage>
          <KizenTypography>
            {t(
              'This contact is suppressed from receiving messages from Kizen due to a spam complaint or too many bounces. Please reach out to resolve this before attempting to send more messages.'
            )}
          </KizenTypography>
        </SuppressionListMessage>
      )}
      <ScrollContainerBlockerWrapper>
        <ScrollContainerBlocker className="scroll-container-blocker" />
      </ScrollContainerBlockerWrapper>
      <TableScrollContainer bottom data-qa-table="subscription-lists">
        <BasicTable
          stickyHeader
          head={
            <TRow
              head
              columns={subscriptionsColumns}
              data={subscriptionsHeadData}
            />
          }
        >
          {subscriptions.map((sub) => (
            <TRow key={sub.id} columns={subscriptionsColumns} data={sub} />
          ))}
        </BasicTable>
      </TableScrollContainer>
    </>
  );
};
