import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'components/Kizen/Tooltip';

export const inlineTableModifiers = {
  modifiers: {
    offset: { offset: '0, -3' },
    preventOverflow: { boundariesElement: 'viewport' },
  },
};

export const getDisplayNameTooltipProps = (
  showDisplayNameTooltip,
  { displayName }
) =>
  showDisplayNameTooltip
    ? {
        showToolTip: true,
        tooltipLabel: displayName,
        placement: 'top',
        popperConfig: inlineTableModifiers,
      }
    : {};

export const WithTooltip = ({
  children,
  showToolTip,
  placement,
  tooltipLabel,
  popperConfig,
}) =>
  showToolTip ? (
    <OverlayTrigger
      overlay={<Tooltip label={tooltipLabel} />}
      placement={placement}
      popperConfig={popperConfig || inlineTableModifiers}
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  );

WithTooltip.propTypes = {
  showToolTip: PropTypes.bool,
  placement: PropTypes.string,
  tooltipLabel: PropTypes.string,
  popperConfig: PropTypes.object,
};

WithTooltip.defaultProps = {
  showToolTip: false,
  placement: 'top',
  tooltipLabel: '',
  popperConfig: null,
};
