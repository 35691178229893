import { useCallback, useRef, useState } from 'react';
import Select from 'components/Kizen/Select';
import IconButton, { ICON_BUTTON_SIZING } from 'components/Kizen/IconButton';
import useClickAway from 'react-use/lib/useClickAway';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { borderTight } from 'app/colors';
import ReactDOM from 'react-dom';
import { gutters, layers } from 'app/spacing';

const ContainerPosition = styled.div`
  position: relative;
`;

const getAboveTranslate = (above) =>
  above ? '-100%' : `${gutters.spacing(3, 2)}px`;

const Menu = styled(Select)`
  .IconButtonMenu__control {
    display: none;
  }
  .IconButtonMenu__menu {
    position: relative;
    overflow: hidden; // Primarily for border-radius
    width: auto;
    max-width: 200px;
    z-index: ${layers.popoverInModal}; // should be more then modal has
    ${borderTight}
  }
  & {
    absolute: absolute;
  }
  ${({ position, above }) =>
    position === 'right'
      ? css`
          top: 0px;
          right: calc(0px + ${gutters.spacing(4)}px);
          z-index: ${layers.popoverInModal}; // should be more then modal has
          .IconButtonMenu__menu {
            transform: translateX(0) translateY(${getAboveTranslate(above)});
          }
        `
      : css`
          // position: absolute;
          top: 0px;
          right: 0px;
          .IconButtonMenu__menu {
            transform: translateX(0) translateY(${getAboveTranslate(above)});
          }
        `}
`;
const MenuWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  ${({ top, left }) => css`
    position: absolute;
    top: ${top}px;
    left: ${left}px;
  `} > div {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export default function IconButtonAbsoluteMenu({
  sizing = ICON_BUTTON_SIZING.normal,
  color,
  className,
  children,
  onOpen = null,
  onClose = null,
  position = 'left',
  title,
  open,
  above = false,
  onChange,
  screenPositionAdjust,
  ...others
}) {
  const onOpenRef = useRef(onOpen);
  onOpenRef.current = onOpen;
  const targetRef = useRef(null);
  const menuContainerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(
    typeof open === 'boolean' ? open : false
  );
  const [screenPosition, setScreenPosition] = useState({
    top: 0,
    left: 0,
  });
  const onClickHandler = useCallback(() => {
    if (onOpenRef.current) {
      onOpenRef.current(isOpen);
    }
    setIsOpen(typeof open === 'boolean' && isOpen ? open : !isOpen);
    const dimensions = targetRef.current.getBoundingClientRect(); // where on the screen is the target

    setScreenPosition({
      top: dimensions.top + window.scrollY + (screenPositionAdjust?.top || 0),
      left:
        dimensions.left + window.scrollX + (screenPositionAdjust?.left || 0),
    });
  }, [open, isOpen, screenPositionAdjust?.top, screenPositionAdjust?.left]);

  useClickAway(targetRef, (ev) => {
    // we need to check if click was handled to wrapper component
    // we don't need to continue here.
    if (
      !isOpen ||
      (menuContainerRef.current && menuContainerRef.current.contains(ev.target))
    ) {
      return;
    }
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  });

  const handleChange = useCallback(
    (...value) => {
      setIsOpen(false);
      onChange(...value);
    },
    [onChange]
  );

  return (
    <ContainerPosition>
      <IconButton
        ref={targetRef}
        sizing={sizing}
        color={isOpen && color ? color.light || color.hover : color}
        className={className}
        title={title}
        onClick={onClickHandler}
        data-qa-action-menu-trigger=""
        {...others}
      >
        {children}
      </IconButton>
      {(typeof open === 'boolean' ? open : isOpen) &&
        ReactDOM.createPortal(
          <div
            ref={menuContainerRef}
            data-qa-action-menu=""
            className="portal-menu"
          >
            <MenuWrapper {...screenPosition}>
              <div>
                <Menu
                  fullWidth
                  position={position}
                  classNamePrefix="IconButtonMenu"
                  menuIsOpen
                  above={above}
                  onChange={handleChange}
                  {...screenPosition}
                  {...others}
                />
              </div>
            </MenuWrapper>
          </div>,
          document.body
        )}
    </ContainerPosition>
  );
}
