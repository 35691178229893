import styled from '@emotion/styled';

import { SortableHeadCell as KizenSortableHeadCell } from 'components/Kizen/Table';
import { PADDING_IN_PIXELS } from 'pages/Common/components/BigTableCells';

export const SortableHeadCell = styled(
  ({ column, minWidth, children, className, ...others }) => {
    return (
      <KizenSortableHeadCell
        className={`${className}`}
        column={column}
        padding={PADDING_IN_PIXELS}
        minWidth={minWidth}
        {...others}
      >
        {children}
      </KizenSortableHeadCell>
    );
  }
)`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
    margin-top: 1px;
    button {
      margin-left: 10px;
    }
  }
`;
