import { useBreakpoint } from 'app/spacing';
import FieldService from 'services/FieldService';
import { useTranslation } from 'react-i18next';
import {
  ComponentWrapper,
  PaginationWrapper,
  StyledModal,
  StyledModalHeader,
  StyledModalHeaderWrapper,
  StyledSearchInputWrapper,
  StyledSummary,
  StyledTableScrollContainer,
  TableWrapper,
} from '../styles';
import TablePagination from 'components/Tables/Big/TablePagination';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import { TRow } from 'components/Kizen/Table';
import useField from 'hooks/useField';
import { useAsync } from 'react-use';
import { useCallback, useMemo, useRef } from 'react';
import {
  getModalHeading,
  getModalSubHeading,
  getPaginationSettings,
  getSummaryText,
} from '../helpers';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { useColumns } from '../hooks/useColumns';
import { AddValuesButton } from '../AddValuesButton';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import { TextSpan } from 'app/typography';
import BasicTable from 'components/Tables/Basic';
import { useDirtyState } from './useDirtyState';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { usePaginationSettings } from 'hooks/usePaginationSettings';

export const SummarizedMultiSelectModal = ({
  entity,
  field,
  value,
  readOnly = true,
  onChange,
  modalProps,
}) => {
  const { t } = useTranslation();

  const [{ addValues = [], removeValues = [] }, setInnerValue] = useField(
    {
      addValues: value.addValues || [],
      removeValues: value.removeValues || [],
    },
    [value]
  );

  const initialCount = value.count;
  const count = initialCount + addValues.length - removeValues.length;

  const onAddValues = useCallback(
    (addValues) => {
      setInnerValue((prev) => ({
        ...prev,
        addValues,
      }));
    },
    [setInnerValue]
  );

  const onRemoveValues = useCallback(
    (id) => {
      setInnerValue((prev) => {
        return {
          ...prev,
          removeValues: [...prev.removeValues, { id }],
        };
      });
    },
    [setInnerValue]
  );

  const onConfirm = useCallback(() => {
    !readOnly &&
      onChange({
        count: value.count,
        addValues,
        removeValues,
      });
    modalProps.onHide();
  }, [readOnly, value.count, addValues, removeValues, onChange, modalProps]);

  const isMobile = useBreakpoint();

  const {
    page,
    pageSize,
    search,
    ordering,
    handleChangePage,
    handleChangeSearch,
    handleChangePageSize,
    headData,
  } = usePaginationSettings(getPaginationSettings(field));

  const {
    value: { results = EMPTY_ARRAY, count: total = count } = {},
    loading,
  } = useAsync(async () => {
    return FieldService.getFieldValues(entity.id, field.id, {
      page,
      pageSize,
      search,
      ordering,
    });
  }, [page, pageSize, search, ordering, entity.id, field.id]);

  const fieldValues = useMemo(
    () =>
      results.filter(
        ({ id }) => !removeValues.find((value) => value.id === id)
      ),
    [results, removeValues]
  );

  const columns = useColumns(field, !readOnly && onRemoveValues, isMobile, t);
  const heading = useMemo(() => getModalHeading(field, t), [field, t]);
  const subHeading = useMemo(
    () => getModalSubHeading(field, count, t),
    [field, count, t]
  );
  const summaryText = useMemo(
    () => getSummaryText(field, addValues, removeValues, count, t),
    [field, addValues, removeValues, count, t]
  );

  const initialDataRef = useRef({
    addValues,
    removeValues,
    count,
  });

  const { showConfirmation, onCheckToHide, onHideConfirmed, onHideCanceled } =
    useDirtyState(
      initialDataRef.current,
      {
        addValues,
        removeValues,
        count,
      },
      modalProps.onHide
    );

  return (
    <>
      <StyledModal
        buttonText={readOnly ? t('Close') : t('Save')}
        actionBtnColor={readOnly ? 'blue' : 'green'}
        heading={heading}
        leftBtn={readOnly ? null : undefined}
        size="medium"
        {...modalProps}
        onHide={onCheckToHide}
        onConfirm={onConfirm}
        displayFlex
      >
        <ComponentWrapper>
          <StyledModalHeaderWrapper>
            <StyledSearchInputWrapper>
              <PageSearchInput
                onChange={handleChangeSearch}
                placeholder={t('Find Records')}
                maxWidth={250}
              />
            </StyledSearchInputWrapper>
            <StyledModalHeader type="header">{subHeading}</StyledModalHeader>
          </StyledModalHeaderWrapper>
          {readOnly ? null : (
            <AddValuesButton
              field={field}
              entity={entity}
              addValues={addValues}
              onAddValues={onAddValues}
              isMobile={isMobile}
            />
          )}
          <TableWrapper>
            <StyledTableScrollContainer
              className={isMobile ? 'mobile' : ''}
              bottom
              top
              isMobile={isMobile}
              gradient={<Gradient />}
            >
              <BasicTable
                key={`table-${field.id}-${isMobile}`}
                stickyHeader
                className={isMobile ? 'mobile' : ''}
                head={<TRow head columns={columns} data={headData} />}
                data-qa="summarized-table"
                staleData={loading}
              >
                {count ? null : <TRow />}
                {fieldValues.map((data) => (
                  <TRow key={data.id} columns={columns} data={data} />
                ))}
              </BasicTable>
            </StyledTableScrollContainer>
          </TableWrapper>
          <PaginationWrapper>
            <TablePagination
              small={isMobile}
              page={page}
              perPage={pageSize}
              totalCount={total}
              onChangePage={handleChangePage}
              onChangePerPage={handleChangePageSize}
            />
          </PaginationWrapper>
          {addValues.length || removeValues.length ? (
            <StyledSummary>
              <TextSpan weight="bold" size="micro">
                {t('Changes to be made')}:
              </TextSpan>
              &nbsp;
              <TextSpan size="micro">{summaryText}</TextSpan>
            </StyledSummary>
          ) : null}
        </ComponentWrapper>
      </StyledModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        show={showConfirmation}
        onConfirm={onHideConfirmed}
        onHide={onHideCanceled}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
};
