import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

export const DashboardState = React.createContext({
  state: {},
  localState: {},
  dispatch: () => {},
  localDispatch: () => {},
});

const getStorageKey = (teamMember) => `dashboardConfig:${teamMember?.id}`;

// This can be used either backed using the external persistent storage (page config for dashboards)
// or an interal persistent config using localStorage, depending on if the session data should span
// all of a user's devices, or just the current one.
export const DashboardStateWrapper = ({
  children,
  useLocalStorage = false,
  setPersistentDashboardState,
  persistentDashboardState,
}) => {
  const teamMember = useSelector((s) => s.authentication.teamMember || {});

  const persistentReducer = useCallback((internalState, data) => {
    const res = {
      ...internalState,
      ...data,
    };

    return res;
  }, []);

  const localReducer = useCallback((internalState, data) => {
    return {
      ...internalState,
      ...data,
    };
  }, []);

  const [state, dispatch] = useReducer(
    persistentReducer,
    (() => {
      if (useLocalStorage) {
        const key = getStorageKey(teamMember);
        const data = localStorage.getItem(key);
        if (data) {
          try {
            return JSON.parse(data);
          } catch (ex) {
            return {};
          }
        }
      } else if (persistentDashboardState) {
        return persistentDashboardState;
      }
      return {};
    })()
  );

  const [localState, localDispatch] = useReducer(localReducer, {});

  const storageKey = useLocalStorage && getStorageKey(teamMember);
  const mutationRef = useRef(setPersistentDashboardState);
  const mountedRef = useRef(false);
  mutationRef.current = setPersistentDashboardState;

  useEffect(() => {
    if (state && storageKey) {
      localStorage.setItem(storageKey, JSON.stringify(state));
    } else if (state && mutationRef.current) {
      mountedRef.current && mutationRef.current(state);
      mountedRef.current = true;
    }
  }, [state, storageKey]);

  return (
    <DashboardState.Provider
      value={{ state, localState, dispatch, localDispatch }}
    >
      {children}
    </DashboardState.Provider>
  );
};

export default {
  Wrapper: DashboardStateWrapper,
  Context: DashboardState,
};
