import { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CONTACT, PIPELINE, CUSTOM_OBJECT } from './helpers';
import { createSelector } from '@reduxjs/toolkit';

import {
  getContactsAccess,
  getCustomObjectEntitiesAccess,
} from 'store/authentication/selectors';

const getEntityCreationAccess = createSelector(
  getCustomObjectEntitiesAccess,
  getContactsAccess,
  (customObjectEntitiesAccess, contactsAccess) => {
    return [customObjectEntitiesAccess, contactsAccess?.canCreateNewContact];
  }
);

export const useEntityCreationModal = (field = {}) => {
  const [initialValues, setInitialValues] = useState({});
  const [globalCOAccess, canCreateNewContact] = useSelector(
    getEntityCreationAccess
  );
  const parseTypeAhead = useCallback((val, isContact) => {
    if (!isContact) {
      setInitialValues({ name: val });
    } else {
      const isEmail = val.includes('@');
      if (isEmail) {
        setInitialValues({ email: val, first_name: val.split('@')[0] });
      } else {
        const stringArr = val.split(' ');
        setInitialValues(
          stringArr.length > 1
            ? { first_name: stringArr[0], last_name: stringArr[1] }
            : { first_name: stringArr[0] }
        );
      }
    }
  }, []);

  const objectType = useMemo(() => {
    if (field.relation?.fetchUrl === 'pipeline') return PIPELINE;
    if (field.relation?.fetchUrl === 'standard') return CUSTOM_OBJECT;

    return CONTACT;
  }, [field]);

  const hasAbilityToAdd = useMemo(() => {
    if (field.relation?.fetchUrl === 'client') {
      return canCreateNewContact;
    }
    return globalCOAccess[field.relation?.relatedObject]?.canCreate;
  }, [field, canCreateNewContact, globalCOAccess]);

  return {
    initialValues,
    setInitialValues,
    parseTypeAhead,
    objectType,
    hasAbilityToAdd,
  };
};
