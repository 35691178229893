import React, { forwardRef, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import teammembers from './teammembers';
import { CommentMention } from './extensions/commentMention';

import {
  ClearFormattingToolbarSection,
  ListStyleToolbarSection,
  TextAlignToolbarSection,
  TextStyleToolbarSection,
} from '../../StandaloneToolBar/sections/wysiwyg';
import { WYSIWYG } from '../editor';
import { ToolbarWrapper } from '../../StandaloneToolBar/styles';

/**
 * Custom editor for the comments.
 */

export const CommentEditor = forwardRef(
  (
    {
      onChange,
      onBlur,
      onFocus,
      autofocus,
      placeholder,
      initialValue,
      suggestionAppendToSelector,
      children,
    },
    ref
  ) => {
    const queryClient = useQueryClient();

    // reduce the rendering
    const mentionConfig = useMemo(
      () => ({
        configure: {
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion: teammembers(queryClient, {
            appendToSelector: suggestionAppendToSelector,
          }),
        },
        extension: CommentMention,
      }),
      [queryClient, suggestionAppendToSelector]
    );
    return (
      <WYSIWYG
        ref={ref}
        fitToContent={false}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        autofocus={autofocus}
        placeholder={placeholder}
        initialValue={initialValue}
        enableLists
        enableTextAlign
        enableTextStyles
        mentionConfig={mentionConfig}
      >
        <ToolbarWrapper>
          <TextStyleToolbarSection />
          <TextAlignToolbarSection />
          <ListStyleToolbarSection />
          <ClearFormattingToolbarSection />
        </ToolbarWrapper>
        {children}
      </WYSIWYG>
    );
  }
);
