import {
  AUTOMATION_ACTION,
  AUTOMATION_STATE,
} from 'services/Automation2Service';

export const getToastMessage = (action, t) => {
  switch (action) {
    case AUTOMATION_ACTION.PAUSE:
      return {
        success: t('This automation has been paused'),
        failure: t('The automation could not be paused'),
      };
    case AUTOMATION_ACTION.RESUME:
      return {
        success: t('This automation has been started'),
        failure: t('The automation could not be started'),
      };
    case AUTOMATION_ACTION.CANCEL:
      return {
        success: t('The automation has been canceled'),
        failure: t('The automation could not be cancelled'),
      };
    case AUTOMATION_ACTION.SKIP_STEP_AND_RESUME:
      return {
        success: t('Automation resumed successfully'),
        failure: t('Error Occurred - Could not resume automation'),
      };
    case AUTOMATION_ACTION.DOWNLOAD:
      return {
        success: t(
          'Execution details export queued. Check your email for download link.'
        ),
        failure: t('There was an error requesting the details export'),
      };
    default:
      return {};
  }
};

export const isExecutionImmutable = ({ status }) =>
  [
    AUTOMATION_STATE.COMPLETED,
    AUTOMATION_STATE.CANCELLED,
    AUTOMATION_STATE.FAILED,
  ].includes(status);
