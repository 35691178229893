import { useRef } from 'react';
import { useElementSize } from '../hooks/useElementSize';
import { getKDSClasses, merge } from '../util';

export interface SizeMonitorProps {
  children: (dimensions: { width: number; height: number }) => any;
}

export const SizeMonitor = (props: SizeMonitorProps) => {
  const { children } = props;

  const parent = useRef(null);

  const { width, height } = useElementSize(parent);

  return (
    <div
      className={merge(getKDSClasses('size-monitor'), 'h-full')}
      ref={parent}
    >
      {children({ width, height })}
    </div>
  );
};
