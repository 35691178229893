import { Element } from '@craftjs/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useViewerContext } from '../../viewer';
import { RowContainer } from '../containers';
import { Cell } from '../Cell';

export const getFiveColumnElementGridArea = (idx) => {
  if (idx === 0) return '1 / 1 / 1 / 3'; // row 1; column 1-2
  if (idx === 1) return '1 / 3 / 1 / 5'; // row 1; column 3-4
  if (idx === 2) return '1 / 5 / 1 / 7'; // row 1; column 5-6
  if (idx === 3) return '2 / 1 / 2 / 4'; // row 2; column 1-3
  if (idx === 4) return '2 / 4 / 2 / 7'; // row 2; column 4-6
};

/**
 * Existing columns are described using whole numbers [1, 1, 1, 1] or [2, 1, 2] to represent the columns
 * relative size. When resizing, we save these sizes as percentages like [0.25, 0.25, 0.25, 0.25] or [0.4, 0.2, 0.4].
 * This function determines if the columns prop has already been saved as a percentage and, if not, transforms the
 * whole number representation into percentages.
 * @param {number[]} columns - array of columns' relative size
 * @returns {number[]} - array of percentages represented as decimals
 */
export const transformColumnsProp = (columns) => {
  const hasSavedWidths = Array.isArray(columns) && columns.every((x) => x < 1);
  const totalParts = columns.reduce((acc, x) => acc + x, 0);
  return hasSavedWidths ? columns : columns.map((size) => size / totalParts);
};

export const getBreakpointFlags = (rootNode, rootWidth) => {
  const { tabletBreak = 0, mobileBreak = 0 } = rootNode?.data?.props ?? {};
  const isMobile = rootWidth > 0 && rootWidth <= parseInt(mobileBreak);
  const isTablet = !isMobile && rootWidth <= parseInt(tabletBreak);
  return { isMobile, isTablet };
};

const useBreakpointFlags = () => {
  const { rootNode, rootWidth } = useViewerContext();
  const { isMobile, isTablet } = getBreakpointFlags(rootNode, rootWidth);
  return useMemo(() => ({ isMobile, isTablet }), [isMobile, isTablet]);
};

export const ColumnContainer = styled.div`
  position: relative;
  ${({ gridArea }) => css`
    grid-area: ${gridArea};
  `};
`;

export const StyledRowContainer = styled(RowContainer)`
  ${({ columns }) => css`
    grid-template-columns: ${columns
      .map((percent) => `minmax(0, ${percent}fr)`)
      .join(' ')};
  `}
  ${({ isTablet, columns }) =>
    isTablet &&
    columns.length === 5 &&
    css`
      grid-template-rows: repeat(2, minmax(0, 1fr));
      grid-template-columns: repeat(6, minmax(0, 1fr));
    `}
${({ isTablet, columns }) =>
    isTablet &&
    columns.length === 6 &&
    css`
      grid-template-rows: repeat(2, minmax(0, 1fr));
      grid-template-columns: repeat(3, minmax(0, 1fr));
    `}
${({ isMobile }) =>
    isMobile &&
    css`
      grid-template-columns: minmax(0, 1fr);
    `}
`;

export const ViewRow = ({ columns, children, ...rest }) => {
  const { isMobile, isTablet } = useBreakpointFlags();
  const widths = useMemo(() => transformColumnsProp(columns), [columns]);

  return (
    <StyledRowContainer
      columns={widths}
      isTablet={isTablet}
      isMobile={isMobile}
      {...rest}
    >
      {widths.map((_, i, arr) => {
        const columnId = `column-${i + 1}`;
        return (
          <ColumnContainer
            key={columnId}
            gridArea={
              isTablet && arr.length === 5
                ? getFiveColumnElementGridArea(i)
                : null
            }
          >
            <Element canvas is={Cell} id={`column-${i + 1}`} />
          </ColumnContainer>
        );
      })}
    </StyledRowContainer>
  );
};

export const HomepageRow = ({ columns, children, ...rest }) => {
  const widths = useMemo(() => transformColumnsProp(columns), [columns]);
  const { rootNode, rootWidth, applyMobileBreakpoints } = useViewerContext();

  const { isTablet, isMobile } = useMemo(() => {
    const { isMobile, isTablet } = getBreakpointFlags(
      { data: rootNode },
      rootWidth
    );
    return applyMobileBreakpoints
      ? { isMobile, isTablet }
      : { isMobile: false, isTablet: false };
  }, [rootNode, rootWidth, applyMobileBreakpoints]);

  return (
    <StyledRowContainer
      columns={widths}
      isTablet={isTablet}
      isMobile={isMobile}
      {...rest}
    >
      {widths.map((_, i, arr) => {
        const columnId = `column-${i + 1}`;
        return (
          <ColumnContainer
            key={columnId}
            gridArea={
              isTablet && arr.length === 5
                ? getFiveColumnElementGridArea(i)
                : null
            }
          >
            <Element canvas is={Cell} id={columnId} />
          </ColumnContainer>
        );
      })}
    </StyledRowContainer>
  );
};
