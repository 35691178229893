import { useCallback, useContext } from 'react';
import ErrorBoundaryResettable from 'components/Kizen/ErrorBoundaryResettable';
import Dashlet from './index';
import DashletError from '../DashletError';
import { useQueryClient } from 'react-query';
import { ENTITY_TYPES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { editableDashlet, getDashletQueryKey } from 'pages/Dashboard/util';
import { DASHBOARD } from 'queries/query-keys';
import PersistentState from 'pages/Dashboard/context/persistentState';
import { useManagedQueryRefetch } from 'pages/Dashboard/context/dataManager/useManagedQueryRefetch';

const ResettableDashlet = ({
  currentDashboard,
  dashlet,
  deleteDashlet,
  clientObjectId,
  charts,
  errorPadding,
  homepages,
  setContentHeight,
  index,
  ...rest
}) => {
  const {
    teamFilter,
    isDraggable,
    editDashlet,
    ModalComponent,
    model,
    canEdit,
    modalExtraProps,
    mobile,
  } = rest;
  let { dateFilter } = rest;

  if (!dateFilter && dashlet?.config?.feExtraInfo?.dateFilter) {
    dateFilter = dashlet.config.feExtraInfo.dateFilter;
  }
  const { dispatch, state } = useContext(PersistentState.Context);
  const currentEmail = state?.[dashlet.id];
  const managedRefetch = useManagedQueryRefetch();

  const queryClient = useQueryClient();

  const handleRefreshDashlet = useCallback(
    (bustCache = false, isError = true) => {
      const isEmailInteractionStats =
        dashlet?.config?.reportType === REPORT_TYPES.INTERACTION_STATS;

      // Need to reset any saved state for this dashlet in order to do a fresh
      // refetch of the data and avoid whatever error was encountered, if we're in
      // an error state
      if (isError) {
        dispatch({ [dashlet.id]: null });
      }

      let queryKey;

      if (isEmailInteractionStats) {
        queryKey = getDashletQueryKey(
          {
            dashletData: dashlet,
            dateFilter,
            teamFilter,
            currentEmail: currentEmail?.id,
          },
          {
            ignoreObjectId: homepages,
            ignoreFilters: homepages,
          }
        );
      } else if (
        dashlet?.config?.entityType !== ENTITY_TYPES.ACTIVITY ||
        dashlet?.config?.reportType ===
          REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS
      ) {
        queryKey = getDashletQueryKey(
          { dashletData: dashlet, dateFilter, teamFilter },
          {
            ignoreObjectId: homepages,
            ignoreFilters: homepages,
          }
        );
      } else if (
        dashlet?.config?.entityType === 'activity' &&
        dashlet?.config?.reportType !== REPORT_TYPES.SCHEDULED_ACTIVITIES
      ) {
        queryKey = [...DASHBOARD.DASHLET_ACTIVITY, dashlet?.config?.objectId];
      }

      if (
        dashlet?.config?.entityType === ENTITY_TYPES.ACTIVITY &&
        dashlet?.config?.reportType !== REPORT_TYPES.SCHEDULED_ACTIVITIES
      ) {
        queryClient.refetchQueries([
          ...DASHBOARD.DASHLET_ACTIVITY,
          dashlet?.config?.objectId,
        ]);
      }

      if (queryKey) {
        managedRefetch({ dashletId: dashlet.id, bustCache, queryKey });

        if (!bustCache) {
          queryClient.refetchQueries(queryKey);
        }
      }
    },
    [
      dashlet,
      queryClient,
      dateFilter,
      teamFilter,
      dispatch,
      homepages,
      managedRefetch,
      currentEmail,
    ]
  );

  const handleBustCache = useCallback(() => {
    return handleRefreshDashlet(true, false);
  }, [handleRefreshDashlet]);

  const handleChangeColumnWidths = useCallback(
    (widths) => {
      editDashlet(
        editableDashlet({
          ...dashlet,
          config: {
            ...dashlet.config,
            feExtraInfo: {
              ...dashlet.config.feExtraInfo,
              columnWidths: widths,
            },
          },
        })
      );
    },
    [dashlet, editDashlet]
  );

  return (
    <ErrorBoundaryResettable
      FallbackComponent={({ error, onReset }) => {
        return (
          <DashletError
            error={error}
            onDelete={() => deleteDashlet(currentDashboard.id, dashlet.id)}
            onRefresh={() => {
              onReset();
              handleRefreshDashlet();
            }}
            isDraggable={isDraggable}
            dashlet={dashlet}
            editDashlet={async (...args) => {
              await editDashlet(...args);
              onReset();
              handleRefreshDashlet();
            }}
            clientObjectId={clientObjectId}
            ModalComponent={ModalComponent}
            model={model}
            charts={charts}
            canEdit={canEdit && (homepages ? !mobile : true)}
            modalComponentProps={modalExtraProps}
            padding={errorPadding}
          />
        );
      }}
    >
      <Dashlet
        dashboardId={currentDashboard.id}
        dashlet={dashlet}
        charts={charts}
        onChangeColumnWidths={handleChangeColumnWidths}
        {...rest}
        dateFilter={dateFilter}
        homepages={homepages}
        setContentHeight={setContentHeight}
        index={index}
        handleBustCache={handleBustCache}
      />
    </ErrorBoundaryResettable>
  );
};

export default ResettableDashlet;
