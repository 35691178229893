export const DASHBOARD_BREAKPOINT_VALUES = {
  XL: 1366,
  LG: 1129,
  MD: 909,
  SM: 681,
  XS: 453,
  XXS: 0,
};

export const DASHBOARD_BREAKPOINT_NAMES = {
  XL: 'xl',
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
  XXS: 'xxs',
};

export const DASHBOARD_BREAKPOINTS = {
  [DASHBOARD_BREAKPOINT_NAMES.XL]: DASHBOARD_BREAKPOINT_VALUES.XL,
  [DASHBOARD_BREAKPOINT_NAMES.LG]: DASHBOARD_BREAKPOINT_VALUES.LG,
  [DASHBOARD_BREAKPOINT_NAMES.MD]: DASHBOARD_BREAKPOINT_VALUES.MD,
  [DASHBOARD_BREAKPOINT_NAMES.SM]: DASHBOARD_BREAKPOINT_VALUES.SM,
  [DASHBOARD_BREAKPOINT_NAMES.XS]: DASHBOARD_BREAKPOINT_VALUES.XS,
  [DASHBOARD_BREAKPOINT_NAMES.XXS]: DASHBOARD_BREAKPOINT_VALUES.XXS,
};

export const DASHBOARD_COLUMNS = {
  [DASHBOARD_BREAKPOINT_NAMES.XL]: 12,
  [DASHBOARD_BREAKPOINT_NAMES.LG]: 10,
  [DASHBOARD_BREAKPOINT_NAMES.MD]: 8,
  [DASHBOARD_BREAKPOINT_NAMES.SM]: 6,
  [DASHBOARD_BREAKPOINT_NAMES.XS]: 2,
  [DASHBOARD_BREAKPOINT_NAMES.XXS]: 2,
};

export const MOBILE_DASHLET_HEIGHT = 100;

// This initial width can essentially be anything, so it's set to the largest possible value at
// the largest breakpoint. It gets updated with the real value on first render.
export const INITIAL_WIDTH = 1372;
