import React from 'react';
import PropTypes from 'prop-types';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { emptyLongDash } from '../helpers';
import { useTranslation } from 'react-i18next';

export const RoleBlock = ({ item }) => {
  const { t } = useTranslation();
  if (!item.value) return emptyLongDash;
  return (
    <TextEllipsisWithTooltip>
      {`${t('Role')}: ${item.value?.name}`}
    </TextEllipsisWithTooltip>
  );
};

RoleBlock.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.object,
  }).isRequired,
};
