import {
  DATA_MANAGER_SESSION_ACTIONS,
  useDataManagerSession,
} from 'app/dataManagerSession';
import { FLAGS } from 'debug/identifiers';
import { isFlagEnabled } from 'debug/flags';
import DASHBOARD_EVENTS from 'constants/customEvents';
import DASHBOARD_STATUS from '../../constants/status';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

// This hook manages the query state for a series of react-query-backed data blocks.
// It is used to coordinate the acquisition of data blocks to ensure that we don't
// flood the backend with too many requests at once. The following variables can be used
// to tune the behavior of the data manager and make trade-offs between performance and
// responsiveness

// How frequently to consider data stale (5 minutes)
export const DASHLET_STALE_TIME = 5 * 60 * 1000;
// How frequently to check for acquisition of a new dashlet (20 ms)
export const DASHLET_ACQUISITION_TIME = 20;
// How many dashlets can be fetched at once (5)
export const MAX_CONCURRENCY = 5;
// How long to wait after the last change to sync the state to the session (500 ms)
export const SYNC_IDLE_TIME = 500;
// How long to wait for certain expensive actions to be repeated, so they can be batched
// into a single state update
const DUPLICATE_ACTION_QUEUE_TIME = 500;

export const debugMode = isFlagEnabled(FLAGS.DATA_MANAGER);

const dataStateReducer = (state, action) => {
  const { id, ids, index, type, visible, changes } = action;
  if (debugMode) {
    console.log(
      `%c[DATA MANAGER] - executed ${type} on ${id ?? ids ?? '-'}`,
      'color: #a92fc4;'
    );
  }

  switch (type) {
    case 'ACQUIRE': {
      if (ids.every((i) => state.acquiredIds.get(i))) {
        return state;
      }

      const next = {
        ...state,
        acquiredIds: new Map(state.acquiredIds),
      };

      ids.forEach((i) => {
        next.acquiredIds.set(i, true);
      });

      return next;
    }

    case 'RELEASE': {
      const next = {
        ...state,
        acquiredIds: new Map(state.acquiredIds),
      };

      next.acquiredIds.delete(id);

      return next;
    }

    case 'REGISTER': {
      const previousAvailableIds = new Map(state.availableIds);

      previousAvailableIds.set(id, index);

      const next = {
        ...state,
        availableIds: new Map(
          [...previousAvailableIds].sort((a, b) => a[1] - b[1])
        ),
      };

      return next;
    }

    case 'SET_VISIBILITY': {
      const next = {
        ...state,
        candidates: new Map(state.candidates),
      };

      if (visible) {
        next.candidates.set(id, true);
      } else {
        next.candidates.delete(id);
      }

      return next;
    }

    case 'SET_VISIBILITY_BATCH': {
      const next = {
        ...state,
        candidates: new Map(state.candidates),
      };

      changes?.forEach(({ id, visible }) => {
        if (visible) {
          next.candidates.set(id, true);
        } else {
          next.candidates.delete(id);
        }
      });

      if (debugMode) {
        console.log(
          `%c[DATA MANAGER] - synced a batch of ${
            changes?.length ?? 0
          } visibility updates`,
          'background: #00bf23; color: white;'
        );
      }

      return next;
    }

    case 'MARK_EXECUTION': {
      const next = {
        ...state,
        executed: new Map(state.executed),
        cacheBustRequests: new Map(state.cacheBustRequests),
      };

      next.executed.set(id, Date.now());
      next.cacheBustRequests.delete(id);

      return next;
    }

    case 'TRIGGER_REFETCH': {
      const next = {
        ...state,
        candidates: new Map(state.candidates),
        executed: new Map(state.executed),
      };

      next.candidates.set(id, true);
      next.executed.delete(id);
      return next;
    }

    case 'BUST_CACHE': {
      const next = {
        ...state,
        cacheBustRequests: new Map(state.cacheBustRequests),
      };

      next.cacheBustRequests.set(id, true);

      if (debugMode) {
        console.log(
          `%c[DATA MANAGER] - busting cache for ${id}`,
          'background: #bf0023; color: white;'
        );
      }

      return next;
    }

    case 'PROCESS_DELETE': {
      const next = {
        ...state,
        candidates: new Map(state.candidates),
        acquiredIds: new Map(state.acquiredIds),
        executed: new Map(state.executed),
        availableIds: new Map(state.availableIds),
      };

      next.candidates.delete(id);
      next.acquiredIds.delete(id);
      next.executed.delete(id);
      next.availableIds.delete(id);

      return next;
    }

    case 'RESET_STALE': {
      const next = {
        ...state,
        executed: new Map(state.executed),
      };

      const now = Date.now();

      for (const [id, timestamp] of next.executed.entries()) {
        if (now - timestamp > DASHLET_STALE_TIME) {
          next.executed.delete(id);
        }
      }

      return next;
    }

    case 'RESET_ALL': {
      const next = {
        ...state,
        executed: new Map(),
      };
      return next;
    }

    default:
      throw new Error();
  }
};

export const useDataManager = (instanceId, parentId) => {
  const ticker = useRef(0);
  const stateTracker = useRef({});
  const { dispatchSessionState, sessionState } = useDataManagerSession();

  const sessionData = sessionState?.[instanceId];

  const [dataState, dispatchDataState] = useReducer(
    dataStateReducer,
    sessionData,
    (restoredData) => {
      if (restoredData && debugMode) {
        console.log(
          `%c[DATA MANAGER] - restoring state from context for ${instanceId}`,
          'background: #2d702f; color: white;'
        );
      } else if (debugMode) {
        console.log(
          `%c[DATA MANAGER] - initializing state for ${instanceId} because one wasn't found in context`,
          'background: #ad9926; color: white;'
        );
      }
      return (
        restoredData ?? {
          availableIds: new Map(),
          acquiredIds: new Map(),
          candidates: new Map(),
          executed: new Map(),
          cacheBustRequests: new Map(),
        }
      );
    }
  );
  const [unregisteredVisibility, setUnregisteredVisibility] = useState(
    new Set()
  );
  const [
    persistentUnregisteredVisibility,
    setPersistentUnregisteredVisibility,
  ] = useState(new Set());

  const setSessionState = useCallback(() => {
    if (instanceId) {
      if (debugMode) {
        console.log('%c[DATA MANAGER] - syncing state', 'color: #303fc7;');
      }

      dispatchSessionState({
        id: instanceId,
        payload: dataState,
        parentId,
        type: DATA_MANAGER_SESSION_ACTIONS.UPDATE,
      });
    }
  }, [dataState, dispatchSessionState, instanceId, parentId]);

  const throttleTimer = useRef(null);

  useEffect(() => {
    if (throttleTimer.current) {
      clearTimeout(throttleTimer.current);
    }

    throttleTimer.current = setTimeout(() => {
      setSessionState();
    }, SYNC_IDLE_TIME);

    return () => {
      if (throttleTimer.current) {
        clearTimeout(throttleTimer.current);
      }
    };
  }, [setSessionState]);

  // dispatch only one dashboard active event and idle state
  const [isActive, setIsActive] = useState(true);
  const idleTimerRef = useRef(null);

  useEffect(() => {
    // dashboard idle state analytics

    const { acquiredIds, executed } = dataState;
    if (acquiredIds?.size === 0 && executed?.size > 0 && !isActive) {
      setIsActive(true);
      idleTimerRef.current = setTimeout(() => {
        const idleEvent = new CustomEvent(DASHBOARD_EVENTS.STATUS_UPDATE, {
          detail: {
            objectId: parentId,
            dashboardId: instanceId,
            status: DASHBOARD_STATUS.IDLE,
          },
        });
        window.dispatchEvent(idleEvent);
      }, 500);
    } else if (acquiredIds?.size > 0 && isActive) {
      setIsActive(false);
      clearTimeout(idleTimerRef.current);
      const activeEvent = new CustomEvent(DASHBOARD_EVENTS.STATUS_UPDATE, {
        detail: {
          objectId: parentId,
          dashboardId: instanceId,
          status: DASHBOARD_STATUS.ACTIVE,
        },
      });
      window.dispatchEvent(activeEvent);
    }
  }, [dataState, parentId, instanceId, isActive]);

  // Refs to store the latest values, needed to support unmount status
  const latestDataState = useRef(dataState);
  const latestParentId = useRef(parentId);
  const latestInstanceId = useRef(instanceId);

  // Update refs on each render
  latestDataState.current = dataState;
  latestParentId.current = parentId;
  latestInstanceId.current = instanceId;

  useEffect(() => {
    return () => {
      const { executed, availableIds } = latestDataState.current;
      const unloaded = availableIds.size - executed.size;
      const loaded = executed.size;
      const loadedUnloadedDashletEvent = new CustomEvent(
        DASHBOARD_EVENTS.STATUS_UPDATE,
        {
          detail: {
            objectId: latestParentId.current,
            dashboardId: latestInstanceId.current,
            status: DASHBOARD_STATUS.UNLOADED,
            unloaded: unloaded ?? 0,
            loaded: loaded ?? 0,
          },
        }
      );
      if (idleTimerRef.current) {
        clearTimeout(idleTimerRef.current);
      }
      window.dispatchEvent(loadedUnloadedDashletEvent);
    };
  }, []);

  if (debugMode) {
    console.log('[DATA MANAGER]', dataState);
  }

  stateTracker.current = dataState;

  const acquire = useCallback((ids) => {
    dispatchDataState({ type: 'ACQUIRE', ids });
  }, []);

  const release = useCallback((id) => {
    dispatchDataState({ type: 'RELEASE', id });
  }, []);

  const register = useCallback((id, index) => {
    dispatchDataState({ type: 'REGISTER', id, index });
  }, []);

  const visibilityQueue = useRef([]);
  const visibilityQueueTimer = useRef(null);
  const setVisibility = useCallback((id, visible, queryCount) => {
    if (visibilityQueueTimer.current) {
      clearTimeout(visibilityQueueTimer.current);
    }

    visibilityQueue.current.push({ id, visible, queryCount });

    visibilityQueueTimer.current = setTimeout(() => {
      dispatchDataState({
        type: 'SET_VISIBILITY_BATCH',
        changes: visibilityQueue.current,
      });
      visibilityQueue.current = [];
    }, DUPLICATE_ACTION_QUEUE_TIME);
  }, []);

  const markExecution = useCallback((id) => {
    dispatchDataState({ type: 'MARK_EXECUTION', id });
  }, []);

  const triggerRefetch = useCallback((id) => {
    dispatchDataState({ type: 'TRIGGER_REFETCH', id });
  }, []);

  const processDelete = useCallback((id) => {
    dispatchDataState({ type: 'PROCESS_DELETE', id });
  }, []);

  const resetStale = useCallback(() => {
    dispatchDataState({ type: 'RESET_STALE' });
  }, []);

  const bustCache = useCallback((id) => {
    dispatchDataState({ type: 'BUST_CACHE', id });
  }, []);

  const resetAll = useCallback(
    (ids = []) => {
      dispatchDataState({ type: 'RESET_ALL' });

      // Immediately sync to the global state in this instance so that
      // this can be called safely before a transition to a new query
      dispatchSessionState({
        id: instanceId,
        ids,
        type: DATA_MANAGER_SESSION_ACTIONS.CLEAR_EXECUTION,
      });
    },
    [dispatchSessionState, instanceId]
  );

  useEffect(() => {
    const handleFocus = () => {
      resetStale();
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [resetStale]);

  useEffect(() => {
    if (ticker.current) {
      clearInterval(ticker.current);
    }

    ticker.current = setInterval(() => {
      const count = stateTracker.current.acquiredIds.size;

      // First, make sure we have an acquisition slot available
      if (count >= MAX_CONCURRENCY) {
        return;
      }

      // Next, make sure we have candidates to acquire
      if (stateTracker.current.candidates.size === 0) {
        return;
      }

      // Get an array of candidates for aquiring, and sort them by their visual index
      const candidateKeys = Array.from(
        stateTracker.current.candidates.keys()
      ).sort(
        (a, b) =>
          stateTracker.current.availableIds.get(a) -
          stateTracker.current.availableIds.get(b)
      );

      // Get the candidates that have not been executed or acquired a slot yet
      const unreleasedCandidates = candidateKeys.filter(
        (key) =>
          !stateTracker.current.executed.has(key) &&
          !stateTracker.current.acquiredIds.has(key)
      );

      // Acquire ther first item in the ordered list
      if (unreleasedCandidates.length) {
        const slotCount = MAX_CONCURRENCY - count;
        acquire(unreleasedCandidates.slice(0, slotCount));
      }
    }, DASHLET_ACQUISITION_TIME);

    return () => {
      clearInterval(ticker.current);
    };
  }, [acquire]);

  const executed = useMemo(() => {
    return dataState.executed;
  }, [dataState.executed]);

  const acquiredIds = useMemo(() => {
    return dataState.acquiredIds;
  }, [dataState.acquiredIds]);

  const availableIds = useMemo(() => {
    return dataState.availableIds;
  }, [dataState.availableIds]);

  const candidates = useMemo(() => {
    return dataState.candidates;
  }, [dataState.candidates]);

  const cacheBustRequests = useMemo(() => {
    return dataState.cacheBustRequests;
  }, [dataState.cacheBustRequests]);

  const unregisteredVisibilityQueue = useRef([]);
  const unregisteredVisibilityQueueTimer = useRef(null);
  const handleSetUnregisteredVisibility = useCallback(
    (id, visible) => {
      if (unregisteredVisibilityQueueTimer.current) {
        clearTimeout(unregisteredVisibilityQueueTimer.current);
      }

      unregisteredVisibilityQueue.current.push({ id, visible });

      unregisteredVisibilityQueueTimer.current = setTimeout(() => {
        setUnregisteredVisibility((prev) => {
          const res = new Set(prev);

          unregisteredVisibilityQueue.current?.forEach(({ id, visible }) => {
            if (visible) {
              res.add(id);
            } else {
              res.delete(id);
            }
          });

          return res;
        });

        setPersistentUnregisteredVisibility((prev) => {
          const res = new Set(prev);

          unregisteredVisibilityQueue.current?.forEach(({ id, visible }) => {
            if (visible) {
              res.add(id);
            }
          });

          return res;
        });
      }, DUPLICATE_ACTION_QUEUE_TIME);
    },
    [setUnregisteredVisibility]
  );

  return {
    executed,
    acquiredIds,
    availableIds,
    candidates,
    cacheBustRequests,
    acquire,
    release,
    register,
    setVisibility,
    markExecution,
    triggerRefetch,
    processDelete,
    resetAll,
    bustCache,
    instanceId,
    unregisteredVisibility,
    persistentUnregisteredVisibility,
    handleSetUnregisteredVisibility,
  };
};
