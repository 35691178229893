import React, { useCallback, useContext } from 'react';
import {
  ChartSelectionWrapper,
  StyledWizardField,
  StyledHistorySwitch,
} from 'components/Wizards/shared/styles';
import ChartTypeSelector from 'components/Wizards/shared/components/chart/selector';
import { CHART_TYPES } from 'components/Wizards/shared/components/chart/types';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { gutters } from 'app/spacing';
import InputControl from 'components/Inputs/InputControl';
import KizenTypography from 'app/kizentypo';
import Switch from 'components/Kizen/Switch';
import { useTranslation } from 'react-i18next';
import useDataConstraint from 'components/Wizards/shared/hooks/useDataConstraint';

const DEFAULT_TOP_GUTTER = gutters.spacing(2, 2);

const ChartTypeField = ({
  mobile = false,
  top = DEFAULT_TOP_GUTTER,
  only = [CHART_TYPES.SUMMARY, CHART_TYPES.TREND],
}) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);

  const { compare, reportType, display, isSummaryDisplay, includeSummary } =
    state;

  const onChangeDisplay = useCallback(
    (value) => {
      if (value !== CHART_TYPES.DONUT && includeSummary) {
        setState('includeSummary', false);
      }
      return setState('display', value);
    },
    [includeSummary, setState]
  );

  const onChangeCompare = useCallback(
    (e) => {
      return setState('compare', e.target.checked);
    },
    [setState]
  );

  useDataConstraint((internalState) => {
    if (internalState.display && !only.includes(display)) {
      return {
        key: 'display',
        value: undefined,
      };
    }
  });

  return (
    <>
      <StyledWizardField top={top}>
        <InputControl sizing={mobile ? '' : 'wizard'}>
          <ChartSelectionWrapper
            mobile={mobile}
            className="chart-type-selector"
            data-qa-value={display}
          >
            <ChartTypeSelector
              historical={compare}
              reportType={reportType?.value}
              includeSummary={includeSummary}
              value={display}
              onChange={onChangeDisplay}
              only={only}
              mode="wizard"
            />
          </ChartSelectionWrapper>
        </InputControl>
      </StyledWizardField>
      {display && isSummaryDisplay ? (
        <StyledHistorySwitch>
          <StyledWizardField top={gutters.spacing(1)}>
            <KizenTypography>{t('Compare History')}</KizenTypography>
          </StyledWizardField>
          <Switch
            checked={compare ?? false}
            onChange={onChangeCompare}
            className="history-switch"
          />
        </StyledHistorySwitch>
      ) : null}
    </>
  );
};

export default ChartTypeField;
