import { isContact } from 'components/Wizards/CustomObject/utilities';
import { NON_HIDABLE_COLUMNS } from './constants';
import { snakeToCamelCase } from 'services/helpers';
import { DEFAULT_FIELD_NAMES } from 'utility/constants';

const defaultFieldNameExceptions = {
  fullName: DEFAULT_FIELD_NAMES.firstName,
  mobilePhone: DEFAULT_FIELD_NAMES.mobilePhone,
  entityValue: DEFAULT_FIELD_NAMES.entityValue,
  estimatedCloseDate: DEFAULT_FIELD_NAMES.estimatedCloseDate,
  actualCloseDate: DEFAULT_FIELD_NAMES.actualCloseDate,
  percentageChanceToClose: DEFAULT_FIELD_NAMES.percentageChanceToClose,
};

const fieldNamesToSkip = {
  [DEFAULT_FIELD_NAMES.firstName]: true,
  [DEFAULT_FIELD_NAMES.lastName]: true,
};

export const getField = (nameOrId, fields) => {
  return fields.find(
    (f) =>
      f.name === (defaultFieldNameExceptions[nameOrId] || nameOrId) ||
      f.id === nameOrId
  );
};

const defaultContactColumnIds = [
  'fullName',
  DEFAULT_FIELD_NAMES.email,
  snakeToCamelCase(DEFAULT_FIELD_NAMES.mobilePhone),
  DEFAULT_FIELD_NAMES.titles,
  DEFAULT_FIELD_NAMES.tags,
  DEFAULT_FIELD_NAMES.created,
  DEFAULT_FIELD_NAMES.updated,
];

const defaultCustomObjectColumnIds = [
  DEFAULT_FIELD_NAMES.name,
  DEFAULT_FIELD_NAMES.owner,
  DEFAULT_FIELD_NAMES.created,
  DEFAULT_FIELD_NAMES.updated,
  snakeToCamelCase(DEFAULT_FIELD_NAMES.entityValue),
];

const defaultPipelineObjectColumnIds = [
  DEFAULT_FIELD_NAMES.stage,
  snakeToCamelCase(DEFAULT_FIELD_NAMES.estimatedCloseDate),
  snakeToCamelCase(DEFAULT_FIELD_NAMES.actualCloseDate),
  snakeToCamelCase(DEFAULT_FIELD_NAMES.percentageChanceToClose),
];

const defaultIdsLookup = defaultContactColumnIds
  .concat(defaultCustomObjectColumnIds, defaultPipelineObjectColumnIds)
  .reduce((collect, id) => ({ ...collect, [id]: true }), {});

export const isNonHidebleColumn = ({ id }) => NON_HIDABLE_COLUMNS.includes(id);

const getDefaultColumnIds = (model) => {
  if (isContact(model)) {
    return defaultContactColumnIds;
  }
  if (model.objectType === 'standard') {
    return defaultCustomObjectColumnIds;
  }
  return defaultCustomObjectColumnIds.concat(defaultPipelineObjectColumnIds);
};

export const getDefaultColumnSettings = (model, fields, t) => {
  const defaultColumnIds = getDefaultColumnIds(model);
  const hasMetaDefaults = !!model?.meta?.defaultColumns?.length;
  const metaDeafultColumnsLookup = hasMetaDefaults
    ? model.meta.defaultColumns.reduce((collect, column) => {
        collect[column.id] = column;
        return collect;
      }, {})
    : {};

  const defaultColumnIdsSorted = hasMetaDefaults
    ? model.meta.defaultColumns
        .map(({ id }) => id)
        .concat(defaultColumnIds.filter((id) => !metaDeafultColumnsLookup[id]))
    : defaultColumnIds;

  return defaultColumnIdsSorted
    .reduce((collect, id) => {
      const field = getField(id, fields);
      const { name } = field || {};
      if (field)
        collect.push({
          id,
          fieldId: field.id,
          visible: !hasMetaDefaults,
          sortable: true,
          width: '200px',
          disabled:
            isNonHidebleColumn({ id }) || isNonHidebleColumn({ id: name }),
          ...metaDeafultColumnsLookup[id],
          label: id === 'fullName' ? t('Full Name') : field.displayName,
        });
      return collect;
    }, [])
    .concat(
      fields.reduce((collect, { id, name, displayName }) => {
        if (
          !metaDeafultColumnsLookup[id] &&
          !defaultIdsLookup[snakeToCamelCase(name)] &&
          !fieldNamesToSkip[name]
        ) {
          collect.push({
            id,
            fieldId: id,
            visible: false,
            sortable: true,
            width: '200px',
            disabled: false,
            label: displayName,
          });
        }
        return collect;
      }, [])
    );
};

export const getLeftItems = (
  categorizedFields,
  defaultColumnSettings,
  rightItems
) =>
  categorizedFields.flatMap((category) => {
    const columns = category.fields.reduce((acc, { name, id, isHidden }) => {
      if (isHidden) return acc;

      const settingsItem = defaultColumnSettings.find(
        (el) => el.id === id || el.id === snakeToCamelCase(name)
      );
      const rightItem = rightItems.find(
        (el) => el.id === id || el.id === snakeToCamelCase(name)
      );

      if (settingsItem && !rightItem) {
        return acc.concat(settingsItem);
      }
      return acc;
    }, []);
    return [
      {
        id: category.id,
        label: category.name,
        isGroupedItem: true,
      },
      ...columns,
    ];
  });

export const getLeftColumnOrdering = (
  categorizedFields,
  defaultColumnSettings
) =>
  categorizedFields.flatMap(({ fields, id }) => {
    const groupItems = fields.reduce((acc, { isHidden, id, name }) => {
      const settingsItem =
        !isHidden &&
        defaultColumnSettings.find(
          (el) => el.id === id || el.id === snakeToCamelCase(name)
        );
      if (settingsItem) {
        acc.push(settingsItem.id);
      }
      return acc;
    }, []);
    return [id, ...groupItems];
  });
