import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Element, useEditor } from '@craftjs/core';
import { created } from 'store/pageBuilder/pageBuilder.redux';
import { useBuilderContext } from '../BuilderContext';

export const useAddNewNode = () => {
  const dispatchRef = useRef();
  const editorRef = useRef();
  const setActiveNodeRef = useRef();

  dispatchRef.current = useDispatch();
  editorRef.current = useEditor((state) => ({
    resolver: state.options.resolver,
  }));
  setActiveNodeRef.current = useBuilderContext().setActiveNode;

  return useCallback((newItem) => {
    const { query, actions, resolver } = editorRef.current;
    const { placement, type, custom, ...itemOpts } = newItem;
    const { elementProps, ...props } = itemOpts;
    const newNodeTree = query
      .parseReactElement(
        <Element is={resolver[type]} {...props} {...elementProps} />
      )
      .toNodeTree();

    if (custom) {
      const node = newNodeTree.nodes[newNodeTree.rootNodeId];
      node.data.custom = custom;
    }

    actions.addNodeTree(newNodeTree, placement.parentId, placement.index);
    const node = newNodeTree.nodes[newNodeTree.rootNodeId];
    setActiveNodeRef.current(node);
    actions.setIndicator(null);
    dispatchRef.current(created(true));
    return node;
  }, []);
};
