import styled from '@emotion/styled';
import BaseModal, { ModalBody, ModalHeader } from 'components/Modals';
import { breakpoints } from 'app/spacing';
import { fontWeights } from 'app/typography';
import ActionButtons from './PerformActionSteps/ActionButtons';
import { performActionOptions } from './PerformActionSteps/model';
import { useTranslation } from 'react-i18next';

const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: auto; // Removes unnecessary scrollbar, which allows things to size/align per designs
  overflow-x: hidden;

  > button,
  > span {
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
    &:empty {
      margin-bottom: 0;
    }

    font-weight: ${fontWeights.black};
    letter-spacing: 0.5px;
    border-width: 1px;
    line-height: 35px;
    height: 35px;

    width: 141px;
    @media (min-width: ${breakpoints.lg}px) {
      width: 180px;
    }
  }
`;

export default function PerformActionModal({
  onHide,
  isMobile,
  onSelect,
  contact,
  ...props
}) {
  const { fullName } = contact || {};
  const { t } = useTranslation();
  return (
    <BaseModal size="medium" onHide={onHide} {...props}>
      <ModalHeader onClickClose={onHide}>
        {`${t('Perform Action on')} ${fullName}`}
      </ModalHeader>
      <StyledModalBody>
        <ActionButtons
          mobile={isMobile}
          onSelect={onSelect}
          performActionOptions={performActionOptions}
        />
      </StyledModalBody>
    </BaseModal>
  );
}
