import AxiosService from './AxiosService';

class PluginService {
  /*
   * Singleton ES6 pattern
   * https://www.sitepoint.com/javascript-design-patterns-singleton/
   */
  constructor() {
    if (!PluginService.instance) {
      PluginService.instance = this;
    }
    return PluginService.instance;
  }

  endpoint = '/external-integrations';

  async get(options) {
    const { data } = await AxiosService.get(
      `${this.endpoint}/bootstrap`,
      options
    );

    return data;
  }

  async getPluginApps(options) {
    const { data } = await AxiosService.get(
      `${this.endpoint}/plugin-apps`,
      options
    );

    return data;
  }

  async getBusinessPluginApps(options) {
    const { data } = await AxiosService.get(
      `${this.endpoint}/business-plugin-apps`,
      options
    );

    return data;
  }

  async updateBusinessPluginApp(apiName, payload) {
    const { data } = await AxiosService.patch(
      `${this.endpoint}/business-plugin-apps/${apiName}`,
      payload
    );

    return data;
  }

  async createBusinessPluginApp(payload) {
    const { data } = await AxiosService.post(
      `${this.endpoint}/business-plugin-apps`,
      payload
    );

    return data;
  }
}

const instance = new PluginService();
Object.freeze(instance);

export default instance;
