import EntityCreatedEventBlock from 'components/Timeline2/EventBlocks/View/EntityCreated';
import FieldUpdatedEventBlock, {
  getFieldUpdatedEvent,
} from 'components/Timeline2/EventBlocks/View/FieldUpdated';
import ActivityLoggedEventBlock, {
  getActivityLoggedEvent,
} from 'components/Timeline2/EventBlocks/View/ActivityLogged';
import ContactEmailInteractionEventBlock from 'components/Timeline2/EventBlocks/View/ContactEmailInteractionEventBlock';
import ContactSubscriptionEventBlock from 'components/Timeline2/EventBlocks/View/SubscriptionList';
import FormSubmittedEventBlock from 'components/Timeline2/EventBlocks/View/FormSubmitted';
import ActivityScheduledEventBlock from 'components/Timeline2/EventBlocks/View/ActivityScheduled';
import AutomationActionEventBlock from 'components/Timeline2/EventBlocks/View/AutomationAction';
import ArchivedUnArchivedEventBlock from 'components/Timeline2/EventBlocks/View/ArchivedUnArchived';

import { eventTypes } from '../constants';
import { applyEventDefaults, getFieldsSubmittedEvent } from './helpers';
import SurveySubmittedEventBlock from './View/SurveySubmitted';
import InteractionEventBlock from './View/InteractionEventBlock';
import LeadSourceEventBlock from './View/LeadSourceEventBlock';
import ScheduledActivityUpdatedEventBlock from './View/ScheduledActivityUpdated';
import ScheduledActivityDeletedEventBlock from './View/ScheduledActivityDeleted';
import { RecordAccessUpdateBlock } from './View/RecordAccessUpdate';

export const timeLineConfig = {
  [eventTypes.entityCreated]: {
    component: EntityCreatedEventBlock,
  },
  [eventTypes.fieldUpdated]: {
    component: FieldUpdatedEventBlock,
  },
  [eventTypes.recordAccessUpdate]: {
    component: RecordAccessUpdateBlock,
  },
  [eventTypes.activityLogged]: {
    component: ActivityLoggedEventBlock,
  },
  [eventTypes.activityScheduled]: {
    component: ActivityScheduledEventBlock,
  },
  [eventTypes.scheduledActivityUpdated]: {
    component: ScheduledActivityUpdatedEventBlock,
  },
  [eventTypes.scheduledActivityDeleted]: {
    component: ScheduledActivityDeletedEventBlock,
  },
  [eventTypes.automationStarted]: {
    component: AutomationActionEventBlock,
  },
  [eventTypes.automationPaused]: {
    component: AutomationActionEventBlock,
  },
  [eventTypes.automationCanceled]: {
    component: AutomationActionEventBlock,
  },
  [eventTypes.automationResumed]: {
    component: AutomationActionEventBlock,
  },
  [eventTypes.emailAttachmentOpened]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.emailOpened]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.emailDelivered]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.emailComplained]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.emailHardBounced]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.emailSoftBounced]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.emailLinkClicked]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.subsectionListOptedIn]: {
    component: ContactSubscriptionEventBlock,
  },
  [eventTypes.subsectionListOptedOut]: {
    component: ContactSubscriptionEventBlock,
  },
  [eventTypes.subsectionListUnsubscribeAll]: {
    component: ContactSubscriptionEventBlock,
  },
  [eventTypes.subsectionListNotOptedIn]: {
    component: ContactSubscriptionEventBlock,
  },
  [eventTypes.formSubmitted]: {
    component: FormSubmittedEventBlock,
  },
  [eventTypes.entityArchived]: {
    component: ArchivedUnArchivedEventBlock,
  },
  [eventTypes.entityUnArchived]: {
    component: ArchivedUnArchivedEventBlock,
  },
  [eventTypes.surveySubmitted]: {
    component: SurveySubmittedEventBlock,
  },
  [eventTypes.integratedInboxEmailReceived]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.integratedInboxEmailSent]: {
    component: ContactEmailInteractionEventBlock,
  },
  [eventTypes.impressionInteraction]: {
    component: InteractionEventBlock,
  },
  [eventTypes.clickInteraction]: {
    component: InteractionEventBlock,
  },
  [eventTypes.siteentryImpressionInteraction]: {
    component: InteractionEventBlock,
  },
  [eventTypes.customInteraction]: {
    component: InteractionEventBlock,
  },
  [eventTypes.leadSourceCreated]: {
    component: LeadSourceEventBlock,
  },
};

export const timelineEventsMapper = (event, t) => {
  const eventsWithDefaultFields = applyEventDefaults(event);
  switch (eventsWithDefaultFields.typeName) {
    case eventTypes.fieldUpdated: {
      return getFieldUpdatedEvent(eventsWithDefaultFields, t);
    }
    case eventTypes.activityLogged:
      return getActivityLoggedEvent(eventsWithDefaultFields, t);
    case eventTypes.formSubmitted:
    case eventTypes.surveySubmitted: {
      return getFieldsSubmittedEvent(eventsWithDefaultFields, t);
    }
    case eventTypes.recordAccessUpdate: {
      return eventsWithDefaultFields;
    }
    default: {
      return eventsWithDefaultFields;
    }
  }
};
