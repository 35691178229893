import styled from '@emotion/styled';
import TextInput from 'components/Inputs/TextInput';
import { CardBasic } from 'components/Card/Card';
import { layers } from 'app/spacing';
import PageToolbar from 'components/Layout/PageToolbar';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';

export const Toolbar = styled(PageToolbar)`
  height: 36px;
  margin: 20px 0;
  width: 100%;
  display: flex;
`;

export const ToolbarLeft = styled.div`
  display: flex;
  flex: 1;
`;

export const ToolbarRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const TBSearchInput = styled(TextInput)`
  max-width: 200px;
  border-radius: 18px;
`;

export const TableCard = styled(CardBasic)`
  margin-bottom: 25px;
  height: 462px; // 10 rows (40)+ header
  display: flex;
  flex-direction: column;
  overflow: hidden; // Ensure nothing sneaks outside the border-radius
  > * {
    flex: 1;
  }
`;

export const MobileTableCard = styled(TableCard)`
  margin-bottom: 20px;
  height: 708px; // 10 rows (65) + header
  display: flex;
  flex-direction: column;
  overflow: hidden; // Ensure nothing sneaks outside the border-radius
  > * {
    flex: 1;
  }
`;

export const GradientZindex1 = styled(BaseGradient)`
  z-index: ${layers.content(
    0,
    1
  )}; // Appear on top of table head cells, on top of menus
`;
