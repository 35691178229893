import { useCallback } from 'react';
import { usePermissionsContext } from './context';
import { removeAllPermissionsForSection } from '../src/state-ops';

export const useRemoveSectionPermissions = () => {
  const ctx = usePermissionsContext();

  return useCallback(
    (sectionId: string) => {
      removeAllPermissionsForSection(sectionId, ctx);

      if (ctx.fields_loaded.has(sectionId)) {
        ctx.fields_loaded.get(sectionId)!.next(false);
      }
    },
    [ctx]
  );
};
