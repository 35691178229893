import React from 'react';
import * as PropTypes from 'prop-types';
import NumberInput from 'react-number-format';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import ColorLabel from 'components/Fields/ColorLabel';
import { colorsButton } from 'app/colors';
import { FIELD_TYPES } from 'utility/constants';

const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
  display: block;
`;

const FieldPreview = ({ field, teamMember, ...props }) => {
  const { t } = useTranslation();

  const { fieldType } = field;
  if (
    fieldType === FIELD_TYPES.Checkbox.type ||
    fieldType === FIELD_TYPES.YesNoMaybe.type
  ) {
    return <KizenTypography {...props}>{t('Yes')}</KizenTypography>;
  }
  if (
    fieldType === FIELD_TYPES.Checkboxes.type ||
    fieldType === FIELD_TYPES.Dropdown.type ||
    fieldType === FIELD_TYPES.Radio.type
  ) {
    const [firstOption] = field.options;
    return (
      <StyledTextEllipsisWithTooltip {...props}>
        {firstOption.name}
      </StyledTextEllipsisWithTooltip>
    );
  }
  if (fieldType === FIELD_TYPES.DynamicTags.type) {
    const [firstOption] = field.options;
    return (
      <StyledTextEllipsisWithTooltip
        color={colorsButton.blue.default}
        {...props}
      >
        {firstOption?.name || t('Tag')}
      </StyledTextEllipsisWithTooltip>
    );
  }
  if (fieldType === FIELD_TYPES.Rating.type) {
    return (
      <KizenTypography {...props}>{field.rating.maxValue}</KizenTypography>
    );
  }
  if (fieldType === FIELD_TYPES.Relationship.type) {
    const { fetchUrl } = field.relation;
    if (fetchUrl === 'company') {
      return (
        <KizenTypography color={colorsButton.blue.default} {...props}>
          {t('Kizen Technologies, Inc.')}
        </KizenTypography>
      );
    }
    if (fetchUrl === 'client') {
      return (
        <StyledTextEllipsisWithTooltip
          color={colorsButton.blue.default}
          {...props}
        >
          {t('Scott Turner (Scott@Kizen.com)')}
        </StyledTextEllipsisWithTooltip>
      );
    }
  }
  if (fieldType === FIELD_TYPES.TeamSelector.type) {
    return (
      <StyledTextEllipsisWithTooltip
        color={colorsButton.blue.default}
        {...props}
      >
        {teamMember.label}
      </StyledTextEllipsisWithTooltip>
    );
  }
  if (fieldType === FIELD_TYPES.Date.type) {
    return (
      <KizenTypography
        {...props}
      >{`12/31/${new Date().getFullYear()}`}</KizenTypography>
    );
  }
  if (fieldType === FIELD_TYPES.DateTime.type) {
    return (
      <KizenTypography
        {...props}
      >{`12/31/${new Date().getFullYear()} at 6:00 PM`}</KizenTypography>
    );
  }
  if (
    fieldType === FIELD_TYPES.Decimal.type ||
    fieldType === FIELD_TYPES.Integer.type ||
    fieldType === FIELD_TYPES.Money.type
  ) {
    const showDecimalPart =
      fieldType === FIELD_TYPES.Money.type ||
      fieldType === FIELD_TYPES.Decimal.type;
    return (
      <NumberInput
        value={1000}
        displayType="text"
        thousandSeparator
        prefix={fieldType === FIELD_TYPES.Money.type ? '$' : null}
        fixedDecimalScale={showDecimalPart}
        decimalScale={showDecimalPart ? 2 : null}
        renderText={(value) => <KizenTypography>{value}</KizenTypography>}
        {...props}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Email.type) {
    return (
      <KizenTypography {...props}>{t('Email@Domain.com')}</KizenTypography>
    );
  }
  if (fieldType === FIELD_TYPES.Files.type) {
    return (
      <KizenTypography color={colorsButton.blue.default} {...props}>
        {t('1 File')}
      </KizenTypography>
    );
  }
  if (fieldType === FIELD_TYPES.Text.type) {
    return <KizenTypography {...props}>{t('Note on record.')}</KizenTypography>;
  }
  if (fieldType === FIELD_TYPES.LongText.type) {
    return (
      <StyledTextEllipsisWithTooltip {...props}>
        {t(
          'A particularly long note on this record that will eventually cut off.'
        )}
      </StyledTextEllipsisWithTooltip>
    );
  }
  if (fieldType === FIELD_TYPES.PhoneNumber.type) {
    return <KizenTypography {...props}>(888) 555-0199</KizenTypography>;
  }
  if (fieldType === FIELD_TYPES.Status.type) {
    const [firstOption] = field.options;
    return (
      <KizenTypography {...props}>
        <ColorLabel color={firstOption.meta.color}>
          {firstOption.name}
        </ColorLabel>
      </KizenTypography>
    );
  }
  return null;
};

FieldPreview.propTypes = {
  field: PropTypes.object.isRequired,
  teamMember: PropTypes.object.isRequired,
};

export default FieldPreview;
