import { useMemo, forwardRef, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { namedToOption } from 'services/helpers';
import MultiSelect from 'components/Inputs/MultiSelect';
import {
  Entities,
  useSelectEnter,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { getSelectedValueLink } from 'components/Inputs/MultiSelect/customize';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { TopMenuButton } from 'components/Kizen/Menu';
import BasicModal from 'components/Modals/presets/BasicModal';
import TextInput from 'components/Inputs/TextInput';
import FieldService from 'services/FieldService';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { getLinkDataForTag, specialTagTypes } from '../../helpers';
import { useAddObjectModal } from '../helpers';
import {
  getEventKey,
  useCustomSelectMenuProps,
} from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { KEY_CODES } from 'hooks/keyboardEventHandler/constants';
import {
  isContactTagsField,
  isReasonDisqualifiedField,
  isReasonLostField,
} from 'checks/fields';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';
import { usePreserveCaretPositionActive } from 'hooks/usePreserveCaretPosition';
import { DYNAMIC_TAGS_XFORM } from 'components/Tables/DynamicTagsManager/constants';

const tagToOption = ({ id, label, name }) => {
  return { value: id, label: label || name };
};

export const transformTagsDown = (values) => (values || []).map(tagToOption);

export const transformTagOptions = (options) => options.map(namedToOption);

export const getTranslatedLabelData = (t) => ({
  reasons_disqualified: {
    placeholder: t('Find Reasons Disqualified'),
    menuTopButton: t('Add Reason Disqualified'),
    modalHeader: t('Add Reason Disqualified'),
    modalInputPlaceholder: t('Enter Reason Disqualified'),
    qaAttr: 'add-reason-disqualified',
  },
  reasons_lost: {
    placeholder: t('Find Reasons Lost'),
    menuTopButton: t('Add Reason Lost'),
    modalHeader: t('Add Reason Lost'),
    modalInputPlaceholder: t('Enter Reason Lost'),
    qaAttr: 'add-reason-lost',
  },
  titles: {
    placeholder: t('Find Titles'),
    menuTopButton: t('Add Title'),
    modalHeader: t('Add Title'),
    icon: 'search',
    modalInputPlaceholder: '',
    qaAttr: 'add-title',
  },
  tags: {
    placeholder: t('Find Tags'),
    menuTopButton: t('Add Tag'),
    modalHeader: t('Add Tag'),
    modalInputPlaceholder: '',
    qaAttr: 'add-tags',
  },
  default: {
    placeholder: t('Find Options'),
    menuTopButton: t('Add Tag'),
    modalHeader: t('Add Tag'),
    modalInputPlaceholder: '',
    qaAttr: 'add-tags',
  },
});

const getFieldName = (field) =>
  field?.isDefault ||
  isReasonLostField(field) ||
  isReasonDisqualifiedField(field)
    ? field.originalSnakeCaseName || field.name
    : field?.fieldType;

export const CoreTags = forwardRef(
  (
    {
      fieldId,
      disabled,
      fetchUrl = null,
      field = EMPTY_OBJECT,
      value = EMPTY_ARRAY,
      onChange,
      serviceToUse = FieldService,
      objectId = null,
      handleUpdateFieldOption,
      fieldType,
      placeholder: placeholderProp,
      handleSubmit,
      transformTagsUp,
      loading,
      onFocus,
      onMenuOpen,
      onMenuClose,
      keepPreviousData = true,
      isActivityField,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();

    const fieldName = useMemo(
      () => getFieldName(field?.customObjectField || field),
      [field]
    );

    const { assignFieldHandle } = useKeyBoardContext();

    const LABEL_DATA = useMemo(() => getTranslatedLabelData(t), [t]);

    const selectRef = useRef(null);

    const chosenValueIds = useMemo(
      () => (value || []).map((item) => item.value ?? item.id),
      [value]
    );

    const searchTags = useCallback(
      ({ search = '', page = 1 }, options) => {
        const params = [fieldId || field.id, objectId, page, search];
        return isActivityField
          ? serviceToUse.getDynamicTagsOptionsOrdered(
              ...params,
              'name',
              options
            )
          : serviceToUse.getDynamicTagsOptions(...params, options);
      },
      [serviceToUse, fieldId, field, objectId, isActivityField]
    );

    const [typeaheadProps, typeaheadState, { refetch }] =
      useSelectTypeaheadWithScroll({
        entity: Entities.DynamicTags,
        fetch: searchTags,
        objectToOption: tagToOption,
        alwaysOpen: false,
        onFocus,
        fieldId: fieldId || field.id,
        onMenuOpen,
        onMenuClose,
        selectRef,
        chosenValueIds,
        keepPreviousData,
      });

    const inputProps = usePreserveCaretPositionActive({
      xform: DYNAMIC_TAGS_XFORM,
    });

    const [
      modalProps,
      ,
      { onOpen, modalSubmitting },
      validateMessage,
      validateError,
    ] = useAddObjectModal({
      handleSubmit: async () => {
        await handleSubmit(inputProps.value.trim());
        refetch();
      },
    });

    const onStartCreate = () => {
      onOpen(true);
      inputProps.onChange(typeaheadProps.inputValue);
      typeaheadProps.onInputChange('');
    };

    const selectEnterProps = useSelectEnter({
      handlePressEnter: onStartCreate,
      options: typeaheadProps.options,
      loadingOptions: typeaheadState.loading,
      uncontrolledSearch: true,
    });

    // activities have a modelField if the record is a custom field
    // in this case we need to use the modelField.id rather than the fields.id
    const ObjectLink = useMemo(
      () =>
        fetchUrl &&
        (!isActivityField || field?.customObjectField?.id) &&
        getSelectedValueLink({
          genLink: getLinkDataForTag({
            fetchUrl,
            fieldId: field.modelField ? field.modelField.id : field.id,
            tagType:
              specialTagTypes[
                isContactTagsField(field?.customObjectField)
                  ? 'tags'
                  : field.name
              ] || 'dynamictag',
            field,
          }).link,
          fieldId: field.modelField ? field.modelField.id : field.id,
          fetchUrl,
          tagType: specialTagTypes[field.name] || 'dynamictag',
        }),
      [fetchUrl, field, isActivityField]
    );

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, { ...others, ...typeaheadProps });

    assignFieldHandle(fieldId, {
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      },
      customEnter: () => {
        return selectRef.current.state.menuIsOpen;
      },
      customEscape: () => {
        setMenuIsOpen(false);
      },
      customUp: () => {
        setMenuIsOpen(true);
      },
      customDown: () => {
        setMenuIsOpen(true);
      },
      disabled,
    });

    const placeholder = useMemo(
      () =>
        placeholderProp ||
        LABEL_DATA[fieldName]?.placeholder ||
        LABEL_DATA.default.placeholder,
      [LABEL_DATA, fieldName, placeholderProp]
    );

    return (
      <>
        <MultiSelect
          ref={selectRef}
          disabled={disabled || modalSubmitting}
          value={value}
          onChange={(val) => onChange(transformTagsUp(val))}
          placeholder={placeholder}
          menuTopButton={
            <TopMenuButton onClick={onStartCreate}>
              {LABEL_DATA[fieldName]?.menuTopButton ||
                LABEL_DATA.default.menuTopButton}
            </TopMenuButton>
          }
          menuLeftButton={<ClearSelectButton />}
          menuRightButton={<ApplySelectButton />}
          endAdornment={
            <IconAdornment icon={LABEL_DATA[fieldName]?.icon || 'tag'} />
          }
          PillComponent={ObjectLink}
          {...selectEnterProps}
          {...typeaheadProps}
          {...others}
          {...customSelectMenuProps}
          isLoading={typeaheadState?.loading}
        />
        <BasicModal
          heading={
            LABEL_DATA[fieldName]?.modalHeader || LABEL_DATA.default.modalHeader
          }
          buttonText={t('Save')}
          defaultLeftBtnText={t('Cancel')}
          {...modalProps}
          disabled={!inputProps.value || modalSubmitting}
        >
          <TextInput
            autoFocus
            placeholder={
              LABEL_DATA[fieldName]?.modalInputPlaceholder ||
              LABEL_DATA.default.modalInputPlaceholder
            }
            {...inputProps}
            {...getDataQAForInput(
              LABEL_DATA[fieldName]
                ? LABEL_DATA[fieldName].qaAttr
                : LABEL_DATA.default.qaAttr
            )}
            onKeyDown={(e) => {
              if (getEventKey(e) === KEY_CODES.enter.type) {
                modalProps.onConfirm();
                selectRef.current.select.focus();
              }
            }}
            validate={
              validateMessage && {
                message: validateMessage,
                showMessage: true,
                inModal: true,
              }
            }
            error={validateError}
          />
        </BasicModal>
      </>
    );
  }
);

export default CoreTags;
