import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import KizenTypography from 'app/kizentypo';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useTranslation } from 'react-i18next';
import { DATAPOINT_FREQUENCY_REPONSES } from 'components/Wizards/Dashlet/types';
import { gutters } from 'app/spacing';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import ButtonGroup from 'components/Inputs/ButtonGroup';

const DatapointFrequencyField = ({
  value,
  onChange,
  mobile,
  active,
  top = gutters.spacing(5),
}) => {
  const { t } = useTranslation();

  const dataPointDailyOption = useMemo(() => {
    return {
      label: t('Daily'),
      value: DATAPOINT_FREQUENCY_REPONSES.DAILY,
    };
  }, [t]);

  const dataPointWeeklyOption = useMemo(() => {
    return {
      label: t('Weekly'),
      value: DATAPOINT_FREQUENCY_REPONSES.WEEKLY,
    };
  }, [t]);

  const dataPointMonthlyOption = useMemo(() => {
    return {
      label: t('Monthly'),
      value: DATAPOINT_FREQUENCY_REPONSES.MONTHLY,
    };
  }, [t]);

  const datapointFrequencyOptions = [
    dataPointDailyOption,
    dataPointWeeklyOption,
    dataPointMonthlyOption,
  ];

  useDefaultValues(
    {
      datapointFrequency: dataPointDailyOption,
    },
    active
  );

  return (
    <StyledWizardField top={top}>
      <KizenTypography type="subheader">
        {t('Choose Datapoint Frequency')}
      </KizenTypography>
      <StyledWizardField top={gutters.spacing(1, 1)}>
        <ButtonGroup
          value={value}
          onChange={onChange}
          sizing={!mobile ? 'auto' : ''}
          options={datapointFrequencyOptions}
          columns={mobile ? 2 : 4}
          className="wizard-buttons datapoint-frequency"
          mode="wizard"
          gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
        />
      </StyledWizardField>
    </StyledWizardField>
  );
};

DatapointFrequencyField.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
  active: PropTypes.bool,
  top: PropTypes.number,
};

DatapointFrequencyField.defaultProps = {
  value: {},
  mobile: false,
  active: false,
  top: gutters.spacing(5),
};

export default DatapointFrequencyField;
