import { memo } from 'react';
import { getErrorStepConfig } from 'pages/AutomationEngine/steps';
import { colorsButton } from 'app/colors';

import {
  useAutomationsDispatch,
  useAutomationsSelector,
} from 'pages/AutomationEngine/store/react';
import { actions } from 'pages/AutomationEngine/store';
import { ERROR_COUNT_ACTIONS } from 'pages/AutomationEngine/store/consts';
import { getError } from 'pages/AutomationEngine/store/selectors';
import StepCard from './StepCard';
import MovingPlaceholder from './MovingPlaceholder';
import * as cardTypes from './type';

function ErrorStepCard({
  type,
  step,
  showStats,
  disableMenu,
  scale,
  onStart,
  onStop,
  showMovingPlaceholder,
  ...others
}) {
  const dispatch = useAutomationsDispatch();

  const stepType = step.type === 'goal' ? step.details.type : step.type;
  const errorSteps = useAutomationsSelector(getError);

  // log the id in the error list
  if (!errorSteps.some((id) => id === step.id)) {
    dispatch(
      actions.updateErrors({ id: step.id, action: ERROR_COUNT_ACTIONS.add })
    );
  }
  const stepConfig = getErrorStepConfig(stepType, {
    isTrigger: type === 'automationTrigger',
  });

  const CardComponent =
    (showMovingPlaceholder && MovingPlaceholder) ||
    cardTypes[stepConfig.CardComponent] ||
    StepCard;

  return (
    <CardComponent
      title={stepConfig.title}
      color={colorsButton.red.hover}
      borderColor={colorsButton.red.hover}
      panel={null}
      menuOptions={[
        stepConfig.deletable && { label: 'Delete', value: 'delete' },
      ].filter(Boolean)}
      disableMenu={
        disableMenu || (!stepConfig.creatable && !stepConfig.deletable)
      }
      {...(stepConfig.CardComponent && { type, step })}
      {...(stepConfig.movable && {
        scale,
        onStart,
        onStop,
      })}
      {...others}
    />
  );
}

export default memo(ErrorStepCard);
