import FieldWizard from './field';
import FormFieldWizard from './form';
import ActivityFieldWizard from './activity';
import CompleteActivitytWizard from './completeActivity';
import ScheduleActivitytWizard from './scheduleActivity';

export default {
  FieldWizard,
  FormFieldWizard,
  ActivityFieldWizard,
  CompleteActivitytWizard,
  ScheduleActivitytWizard,
};
