import AxiosService from './AxiosService';
import {
  FORCE_ALL_RECORDS_SIZE,
  camelToSnakeCaseKeys,
  snakeToCamelCaseKeys,
} from './helpers';
import { monitoringExceptionHelper } from 'sentry/helpers';

class ToolbarService {
  constructor() {
    if (!ToolbarService.instance) {
      ToolbarService.instance = this;
    }
    return ToolbarService.instance;
  }

  templateEndpoint = '/toolbar-templates';

  createTemplate = async (name, config, sharingSettings, queryConfig) => {
    return AxiosService.post(
      this.templateEndpoint,
      {
        name,
        configuration_json: config,
        sharing_settings: camelToSnakeCaseKeys(sharingSettings),
      },
      { skipErrorBoundary: true, ...queryConfig }
    );
  };

  listTemplates = async (
    page = 1,
    page_size = FORCE_ALL_RECORDS_SIZE,
    ordering,
    search,
    config
  ) => {
    const { data } = await AxiosService.get(this.templateEndpoint, {
      ...config,
      params: {
        page,
        page_size,
        ordering,
        search,
        ...config?.params,
      },
    });

    return data;
  };

  getTemplateFullList = async (params) => {
    const getData = async (params) => {
      const data = await this.listTemplates(
        params.page,
        params.page_size,
        params.ordering,
        params.search,
        { params }
      );
      return data.next
        ? [
            ...snakeToCamelCaseKeys(data.results),
            ...(await getData({ ...params, page: params.page + 1 })),
          ]
        : snakeToCamelCaseKeys(data.results);
    };

    return await getData({ ...params, page: 1 });
  };

  getTemplate = async (id, config) => {
    const { data } = await AxiosService.get(
      `${this.templateEndpoint}/${id}`,
      config
    );

    return data;
  };

  deleteTemplate = async (id, config) => {
    const { data } = await AxiosService.delete(
      `${this.templateEndpoint}/${id}`,
      config
    );

    return data;
  };

  updateTemplate = (id, name, config, sharingSettings, owner, queryConfig) => {
    return AxiosService.patch(
      `${this.templateEndpoint}/${id}`,
      {
        name,
        configuration_json: config,
        sharing_settings: sharingSettings
          ? camelToSnakeCaseKeys(sharingSettings)
          : undefined,
        owner,
      },
      { skipErrorBoundary: true, ...queryConfig }
    );
  };

  applyTemplateToTeamMembers = (id, teamMemberIds) => {
    return AxiosService.post(`${this.templateEndpoint}/${id}/apply-to-users`, {
      team_member_ids: teamMemberIds,
    });
  };

  applyTemplateToRoles = (id, roleIds) => {
    return AxiosService.post(`${this.templateEndpoint}/${id}/apply-to-roles`, {
      role_ids: roleIds,
    });
  };

  applyTemplateToPermissionGroups = (id, groupIds, options) => {
    return AxiosService.post(
      `${this.templateEndpoint}/${id}/apply-to-permission-groups`,
      {
        permission_group_ids: groupIds,
      },
      options
    );
  };

  getGrantingAdmins = async (id, config) => {
    const { data } = await AxiosService.get(
      `${this.templateEndpoint}/${id}/admins`,
      config
    );

    return data;
  };

  requestAccess = async (templateId, adminId, accessLevel) => {
    try {
      const url = `${this.templateEndpoint}/request-access`;
      const response = await AxiosService.post(url, {
        entity_type: 'toolbar_template',
        entity_id: templateId,
        admin_responder: adminId,
        access_level_requested: accessLevel,
      });

      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      return false;
    }
  };

  respondToAccessRequest = async (requestId, response) => {
    const url = `${this.templateEndpoint}/${requestId}/request-access-response`;
    const res = await AxiosService.put(url, {
      approved: response,
    });

    return res.data;
  };

  trackPageView = async ({ path, pageloadTimestamp, pageloadId }) => {
    const res = await AxiosService.post(
      '/ui-pageview',
      {
        path,
        browser_timestamp: new Date().toISOString(),
        browser_pageload_timestamp: pageloadTimestamp,
        browser_pageload_id: pageloadId,
      },
      {
        skipErrorBoundary: true,
      }
    );

    return res.data;
  };

  getRecentItems = async ({ pageSize = 10, objects = [] } = {}) => {
    const res = await AxiosService.get('/ui-pageview', {
      params: {
        page_size: pageSize,
        custom_object_id: objects,
      },
      skipErrorBoundary: true,
    });

    return res;
  };
}

const instance = new ToolbarService();
Object.freeze(instance);

export default instance;
