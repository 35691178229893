import { useRef, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import Button from 'components/Button';
import { TRow } from 'components/Kizen/Table';
import BigTableLayout, {
  useBigTableLayout,
} from 'components/Layout/BigTableLayout';
import PageToolbar, {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import TablePagination from 'components/Tables/Big/TablePagination';
import BigTable from 'components/Tables/Big';
import getFormsColumns, { FORM_ACTIONS } from './columns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectFormsPageIsFiltering } from 'store/formsPage/forms.redux';
export default function FormsTabularPage(props) {
  const {
    forms,
    formsCount,
    search,
    onChangeSearch,
    sort,
    onChangeSort,
    pageNumber,
    pageSize,
    onChangePageNumber,
    onChangePageSize,
    onUpdateFormField,
    onSelectPreviewForm,
    onSelectDuplicateForm,
    onSelectDeleteForm,
    openAddModal,
    ...others
  } = props;
  const { t } = useTranslation();
  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout();
  const isFiltering = useSelector(selectFormsPageIsFiltering);

  const { scrolledToTable } = bigTablePageProps;

  // So that we can reference props in memoized values
  const propsRef = useRef();
  propsRef.current = props;

  const columns = useMemo(
    () =>
      getFormsColumns({
        onUpdateField: (form, patch) =>
          propsRef.current.onUpdateFormField(form, patch),
        onSelectAction: ({ value }, form) => {
          const { id, slug } = form;
          if (value === FORM_ACTIONS.Edit.value) {
            propsRef.current.onSelectEditForm(id);
          }
          if (value === FORM_ACTIONS.Preview.value) {
            propsRef.current.onSelectPreviewForm(slug);
          }
          if (value === FORM_ACTIONS.Duplicate.value) {
            propsRef.current.onSelectDuplicateForm(id);
          }
          if (value === FORM_ACTIONS.Delete.value) {
            propsRef.current.onSelectDeleteForm(id);
          }
        },
        t,
      }),
    [t]
  );

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => onChangeSort({ column, direction }),
      },
    }),
    [sort, onChangeSort]
  );

  return (
    <BigTableLayout
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={t('Find Forms')}
              value={search}
              onChange={onChangeSearch}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={formsCount}
            single={t('Form')}
            plural={t('Forms')}
          />
          <PageToolbarSection>
            <Button onClick={openAddModal}>{t('New Form')}</Button>
          </PageToolbarSection>
        </PageToolbar>
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={formsCount}
          onChangePage={onChangePageNumber}
          onChangePerPage={onChangePageSize}
        />
      }
      recordTabSpacing={false}
      {...bigTablePageProps}
      {...others}
    >
      <BigTable
        scrolledToTable={scrolledToTable}
        columns={columns}
        head={<TRow head columns={columns} data={headData} />}
        staleData={isFiltering}
      >
        {/* Just a spacer when there are no results */}
        {!forms.length && <tr />}
        {forms.map((form) => (
          <TRow key={form.id} columns={columns} data={form} />
        ))}
      </BigTable>
    </BigTableLayout>
  );
}

FormsTabularPage.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  formsCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onUpdateFormField: PropTypes.func.isRequired,
  onSelectEditForm: PropTypes.func.isRequired,
  onSelectPreviewForm: PropTypes.func.isRequired,
  onSelectDuplicateForm: PropTypes.func.isRequired,
  onSelectDeleteForm: PropTypes.func.isRequired,
  openAddModal: PropTypes.func.isRequired,
};
