import React, { memo, useEffect, useMemo, useState } from 'react';

const CHUNK_SIZE = 12;

const Delayed = memo(({ children }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  return isShown ? children : null;
});

/**
 *
 * This component divide children's array to chunks and renders them separately
 *
 * @param children [Component, ..., Component]
 * @param chunkSize [number]
 * @returns {*}
 * @constructor
 */
const ChunkRender = ({ children, chunkSize = CHUNK_SIZE }) => {
  const chunks = useMemo(() => {
    return React.Children.toArray(children).reduce(
      (resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      },
      []
    );
  }, [children, chunkSize]);

  return chunks.map((chunk, index) => {
    // first chuck needs to be rendered immediately and without any delays
    // because in most cases we need to get data from a query or redux and
    // then cached data would be used to render the next chunks
    return index > 0 ? <Delayed>{chunk}</Delayed> : chunk;
  });
};

export default ChunkRender;
