import { FIELD_TYPES } from 'utility/constants';

export const getSortedFieldsFromPageData = (pageData) => {
  const parser = (pageData, nodeId = 'ROOT') => {
    const nodes = pageData[nodeId].nodes.length
      ? pageData[nodeId].nodes
      : Object.values(pageData[nodeId].linkedNodes || {});
    return nodes.length
      ? nodes.map((id) => parser(pageData, id)).flat()
      : pageData[nodeId].props?.field || null;
  };
  return pageData ? parser(pageData).filter(Boolean) : [];
};

export const getCustomizedFields = (pageData = {}) =>
  Object.values(pageData).reduce(
    (acc, { props: { field = {} } }) => {
      if (field?.overwriteIfBlank && !field?.isRequired)
        acc.fieldsToOverwrite[field.id] = '';
      if (field?.isRequired) acc.requiredFields[field.id] = true;
      return acc;
    },
    { fieldsToOverwrite: {}, requiredFields: {} }
  );

export const canUpdateFieldPlaceholder = ({ fieldType = '' } = {}) => {
  return ['text', 'longtext', 'decimal', 'integer', 'price'].includes(
    fieldType
  );
};

export const getDefaultFieldPlaceholder = (field, t) => {
  const fieldData = Object.values(FIELD_TYPES).find(
    (x) => x.type === field.fieldType
  );

  if (typeof fieldData?.localizedPlaceholder === 'function') {
    return fieldData.localizedPlaceholder(t);
  }

  if (fieldData?.placeholder) {
    return fieldData.placeholder;
  }

  return '';
};
