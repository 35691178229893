import Mention, { MentionPluginKey } from '@tiptap/extension-mention';
import { mergeAttributes } from '@tiptap/core';
import Pill from '../react/Pill';
import { ReactNodeViewRenderer } from '@tiptap/react';

export const CommentMention = Mention.extend({
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
      },
      suggestion: {
        char: '@',
        allowSpaces: true,
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith('  ');

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();
        },
        allow: ({ editor, range }) => {
          const $from = editor.state.doc.resolve(range.from);
          const type = editor.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);

          return allow;
        },
      },
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      stamp: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-stamp'),
        renderHTML: (attributes) => {
          if (!attributes.stamp) {
            return {};
          }
          return {
            'data-stamp': attributes.stamp,
          };
        },
      },
    };
  },
  renderHTML({ node, HTMLAttributes }) {
    const label = node.attrs.label || '';
    return [
      'span',
      mergeAttributes(
        { 'data-mention': '' },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      label.trim(),
    ];
  },

  renderText({ node }) {
    const user = node.attrs.id;

    return `${this.options.suggestion.char}${user.label}`;
  },

  addNodeView() {
    return ReactNodeViewRenderer(Pill);
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true;
              tr.insertText(
                this.options.suggestion.char || '',
                pos,
                pos + node.nodeSize
              );
              return false;
            }
          });

          return isMention;
        }),
    };
  },
});
