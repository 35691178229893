import StepCard from './StepCard';

export default function TriggerCard({
  panel = null,
  addMenu = true,
  onSelectMenu = null,
  style,
  className,
  // Below props relate to dragging and we want to just ignore them
  scale = null,
  position = null,
  onStart = null,
  onStop = null,
  ...props
}) {
  return (
    <StepCard
      panel={panel}
      onSelectMenu={onSelectMenu}
      addMenu={addMenu}
      {...props}
    />
  );
}
