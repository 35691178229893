import { FIELD_TYPES } from 'utility/constants';

export const cfvChangeTypeOptionsConst = {
  SPECIFIC_VALUE: 'specific_value',
  RELATED_OBJECT_FIELD: 'related_object_field',
  THIS_RECORD_FIELD: 'this_record_field',
};
export const fieldTypesMeta = {
  [FIELD_TYPES.Decimal.type]: {
    type: FIELD_TYPES.Decimal.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Integer.type]: {
    type: FIELD_TYPES.Integer.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Number.type]: {
    type: FIELD_TYPES.Number.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Price.type]: {
    type: FIELD_TYPES.Price.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Money.type]: {
    type: FIELD_TYPES.Money.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  // text
  [FIELD_TYPES.Email.type]: {
    type: FIELD_TYPES.Email.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.LongText.type]: {
    type: FIELD_TYPES.LongText.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Text.type]: {
    type: FIELD_TYPES.Text.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  // 5628
  [FIELD_TYPES.Checkbox.type]: {
    type: FIELD_TYPES.Checkbox.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.Date.type]: {
    type: FIELD_TYPES.Date.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.DateTime.type]: {
    type: FIELD_TYPES.DateTime.type,
    showFieldResolution: true,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
  // 5629
  [FIELD_TYPES.Dropdown.type]: {
    type: FIELD_TYPES.Dropdown.type,
    showFieldResolution: false,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.Radio.type]: {
    type: FIELD_TYPES.Radio.type,
    showFieldResolution: false,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.Rating.type]: {
    type: FIELD_TYPES.Rating.type,
    showFieldResolution: false,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.Status.type]: {
    type: FIELD_TYPES.Status.type,
    showFieldResolution: false,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.TeamSelector.type]: {
    type: FIELD_TYPES.TeamSelector.type,
    showFieldResolution: false,
    fieldRequiresWizard: false,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.YesNoMaybe.type]: {
    type: FIELD_TYPES.YesNoMaybe.type,
    showFieldResolution: false,
    fieldRequiresWizard: true,
    specifiedIsOption: true,
  },
  [FIELD_TYPES.Checkboxes.type]: {
    type: FIELD_TYPES.Checkboxes.type,
    showFieldResolution: false,
    fieldRequiresWizard: true,
    specifiedIsOption: false,
  },
  [FIELD_TYPES.DynamicTags.type]: {
    type: FIELD_TYPES.DynamicTags.type,
    showFieldResolution: false,
    fieldRequiresWizard: false,
    specifiedIsOption: false,
  },
};

export const showFieldResolution = (type) =>
  fieldTypesMeta[type].showFieldResolution || false;

export const fieldRequiresWizard = (type) =>
  type ? fieldTypesMeta[type]?.fieldRequiresWizard || false : false;

export const specifiedIsOption = (type) =>
  type ? fieldTypesMeta[type].specifiedIsOption || false : false;

export const isValueTypeRelated = (ftm) =>
  ftm?.value === cfvChangeTypeOptionsConst.RELATED_OBJECT_FIELD;

export const isValueTypeThisRecord = (ftm) =>
  ftm?.value === cfvChangeTypeOptionsConst.THIS_RECORD_FIELD;

export const numberFieldTypes = [
  FIELD_TYPES.Integer.type,
  FIELD_TYPES.Decimal.type,
  FIELD_TYPES.Number.type,
];

export const hasOptionsWizard = (
  fieldToModify,
  changeType,
  relatedObjectField
) => {
  return (
    fieldRequiresWizard(fieldToModify?.fieldType) &&
    !(changeType.value === cfvChangeTypeOptionsConst.SPECIFIC_VALUE) &&
    relatedObjectField
  );
};
