import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal from '__components/Modals/presets/BasicModal';
import { useHistory } from 'react-router-dom';
import { SMARTCONNECTORS_PATHNAME } from '__pages/SmartConnectors/constants';
import { FlowBuilderContext } from './context';
import Select from '__components/Inputs/Select';
import { GenericOption } from '__pages/SmartConnectors/types';

export const UploadRelatedObjectModal = ({ index = 0 }: { index?: number }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { smartConnector, customObject, customObjects, dropStep } =
    useContext(FlowBuilderContext);

  const [selectedObject, setSelectedObject] = useState<GenericOption | null>(
    null
  );

  const handleConfirm = async () => {
    dropStep(null);
    history.push(
      `${SMARTCONNECTORS_PATHNAME}/${smartConnector.id}/builder/upload-object/${selectedObject?.value}/new`,
      { index }
    );
  };

  const handleChange = (value: GenericOption) => {
    setSelectedObject(value);
  };

  const options = useMemo(() => {
    const relatedById = customObject
      ? (customObject?.relatedObjects || []).reduce(
          (acc, { relatedObject, objectName }) => {
            if (!acc[relatedObject] && relatedObject !== customObject.id) {
              acc[relatedObject] = {
                value: relatedObject,
                label: objectName,
              };
            }
            return acc;
          },
          {} as Record<string, { value: string; label: string }>
        )
      : {};

    const restObjects = customObjects.reduce((acc, { id, objectName }) => {
      if (!relatedById[id] && id !== customObject?.id) {
        acc.push({
          value: id,
          label: objectName,
        });
      }
      return acc;
    }, [] as GenericOption[]);

    return [
      {
        label: t('Primary Object'),
        options: [
          {
            value: customObject?.id,
            label: customObject?.objectName,
          },
        ],
      },
      { label: t('Related Objects'), options: Object.values(relatedById) },
      { label: t('Other Objects'), options: restObjects },
    ];
  }, [customObject, customObjects, t]);

  return (
    <BasicModal
      heading={t('Add New Object Upload')}
      onConfirm={handleConfirm}
      onHide={() => dropStep(null)}
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      show
      disabled={!selectedObject}
    >
      <Select
        label={t('Object to Upload Records to')}
        placeholder={t('Choose Object')}
        value={selectedObject}
        onChange={handleChange}
        options={options}
        menuInline
      />
    </BasicModal>
  );
};
