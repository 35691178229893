import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { colorsButton } from '__app/colors';
import { KizenTypography } from '__app/typography';
import AddButton from '__components/Button/AddButton';
import Icon from '__components/Kizen/Icon';
import SelectWithActivator from '__components/Kizen/Select/WithActivator';

type Option = { value: 'or' | 'and'; label: string };

type AddFilterSetButtonProps = {
  onClick(value: Option['value']): void;
  disableSelect?: boolean;
};

type FilterSetOperationDropdownProps = {
  disabled?: boolean;
  operation: Option['value'];
  onChange(x: Option['value']): void;
};

const Container = styled.div<{ marginTop?: number; marginBottom?: number }>`
  display: flex;
  justify-content: center;

  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px;`}


  button[color='iconGray'] {
    font-size: 1em;
  }
`;

const ScaledIcon = styled(Icon)`
  margin-right: 10px;
  // Extra specificity to prevent color from changing on icon hover
  && {
    color: inherit;
  }
  // This transform positions the icon to match the design perfectly
  transform: translateY(-1px);
  && {
    svg {
      width: 12px;
      height: auto;
    }
  }
`;

const StyledChevron = styled(Icon)`
  && {
    margin-left: 10px;
    margin-top: -2px; // Just a design nudge
    width: 12px;
    svg {
      width: 12px;
      height: auto;
    }
  }
`;

const StyledAddButton = styled(AddButton)`
  // 15px margin above it from FilterSet, 20px actual icon height, 20px desired spacing
  margin: calc(-15px - 20px / 2 + 20px) 0;
`;

export const AddFilterSetButton = ({
  onClick,
  disableSelect = false,
  ...rest
}: AddFilterSetButtonProps) => {
  const { t } = useTranslation();

  if (disableSelect) {
    return (
      <Container marginTop={15} {...rest}>
        <StyledAddButton noSpace color="blue" onClick={onClick}>
          {t('Add Filter Set')}
        </StyledAddButton>
      </Container>
    );
  }

  return (
    <Container marginBottom={-10} {...rest}>
      <SelectWithActivator
        variant="text"
        color="blue"
        onChange={({ value }: Option) => onClick(value)}
        options={[
          { value: 'or', label: t('OR Filter Set') },
          { value: 'and', label: t('AND Filter Set') },
        ]}
      >
        <ScaledIcon icon="plus" />
        {t('Add Filter Set')}
        <StyledChevron icon="chevron-down" color="inherit" />
      </SelectWithActivator>
    </Container>
  );
};

export const FilterSetOperationDropdown = ({
  operation,
  disabled = false,
  onChange,
}: FilterSetOperationDropdownProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SelectWithActivator
        variant="text"
        color="iconGray"
        value={operation}
        onChange={({ value }: Option) => onChange(value)}
        disabled={disabled}
        options={[
          { value: 'or', label: t('Set all to OR') },
          { value: 'and', label: t('Set all to AND') },
        ]}
      >
        <KizenTypography as="span" type="subheader" textTransform="uppercase">
          {operation === 'or' ? t('OR') : t('AND')}
        </KizenTypography>
        {disabled ? null : (
          <StyledChevron
            icon="chevron-down"
            color={colorsButton.iconGray.default}
          />
        )}
      </SelectWithActivator>
    </Container>
  );
};
