import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import { fontSizes } from 'app/typography';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 50%;
  &:last-of-type > hr {
    display: none;
  }
`;

const Divider = styled.hr`
  width: 80%;
  margin: ${gutters.spacing(6)}px auto;
  border: none;
  height: 1px;
  background-color: ${grayScale.medium};
`;

const Content = styled.div`
  flex-direction: column;
  width: 100%;
  & .row {
    margin-right: -${gutters.spacing(2)}px;
    margin-left: -${gutters.spacing(2)}px;
  }
  & .col {
    padding-left: ${gutters.spacing(2)}px;
    padding-right: ${gutters.spacing(2)}px;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-content: flex-start;
  & > p {
    margin-bottom: ${gutters.spacing(6, { baseline: fontSizes.subheader })}px;
  }
  & > button {
    margin-top: ${gutters.spacing(-1)}px;
  }
`;

const SubSection = ({ title, children, ...others }) => {
  return (
    <Wrapper {...others}>
      {title && (
        <KizenTypography type="subheader" margin="md">
          {title}
        </KizenTypography>
      )}
      {children}
      <Divider />
    </Wrapper>
  );
};

SubSection.propTypes = {
  title: PropTypes.node,
};

SubSection.defaultProps = {
  title: null,
};

const SubSectionWithHeader = ({ title, action, children, ...others }) => {
  return (
    <Wrapper {...others}>
      <Content>
        <Header>
          {title && (
            <KizenTypography type="subheader" margin="md">
              {title}
            </KizenTypography>
          )}
          {action}
        </Header>

        {children}
      </Content>
    </Wrapper>
  );
};

SubSectionWithHeader.propTypes = {
  title: PropTypes.node,
  action: PropTypes.func,
};

SubSectionWithHeader.defaultProps = {
  title: null,
  action: null,
};

export default SubSection;
export { SubSectionWithHeader };
