import { createSelector } from '@reduxjs/toolkit';
import { getObjectModelName } from 'services/FieldService';
import { compose } from 'redux';
import { isOwnerField } from 'checks/fields';

export const getLoading = ({ loading }) => loading > 0;

export const getDirty = ({ dirty }) => dirty;

export const getPlacing = ({ placing }) => placing;

export const getLastPlacingStep = ({ lastPlacingStep }) => lastPlacingStep;

export const lastDroppingStep = ({ lastDroppingStep: step }) => step;
export const getPrevSteps = ({ prevSteps }) => prevSteps;

export const getSteps = ({ steps }) => steps;
export const getTriggers = ({ triggers }) => triggers;

export const getStepById = ({ steps }, id) =>
  steps.filter((step) => step.id === id);

export const getSaveable = ({
  loading,
  persisting,
  dirty,
  errorSteps,
  nameError,
}) =>
  loading === 0 &&
  persisting === 0 &&
  dirty &&
  (errorSteps || []).length === 0 &&
  !nameError;

export const getAutomationCustomObjectClass = ({ automation }) =>
  automation.customObject.objectClass;

export const getAutomationCustomObject = ({ automation }) =>
  automation.customObject;

export const getAutomation = ({ automation }) => automation;
export const getAutomationCustomObjectFields = ({ fields }) => fields;

export const getAutomationCustomObjectCategories = ({ categories }) =>
  categories;
export const getAutomationModelName = ({ automation }) =>
  automation.customObject.name;

export const getAutomationModelId = ({ automation }) =>
  automation.customObject.id;

export const getAutomationId = ({ automation }) => automation.id;

export const getDescriptionQues = ({
  conditionDescriptionQue,
  goalDescriptionQue,
  triggerDescriptionQue,
  actionDescriptionQue,
  delayDescriptionQue,
}) => ({
  conditionDescriptionQue,
  goalDescriptionQue,
  triggerDescriptionQue,
  actionDescriptionQue,
  delayDescriptionQue,
});

export const getIsForCustomObject = ({ automation }) =>
  !!automation.customObject &&
  automation.customObject.name.toLowerCase() !== getObjectModelName('contacts');

export const getEditStep = ({ editStepInfo }) =>
  editStepInfo && editStepInfo.step;

export const getCreateStepInfo = ({ createStepInfo }) => createStepInfo;
export const getInterrupting = ({ interrupting }) => interrupting;
export const getStepToDelete = ({ stepToDelete }) => stepToDelete;

export const getToast = ({ toast }) => toast;

export const getGroupedOptionsSelector = (excludedTypes = []) =>
  createSelector(
    (state) => state.steps,
    (state) => state.triggers,
    (stepsCollection, triggers) => {
      return {
        ...Object.values(stepsCollection).reduce((collect, action) => {
          const key = action.prefix === 'step' ? action.type : action.prefix;
          if (excludedTypes.includes(action.type)) {
            return collect;
          }
          collect = {
            ...collect,
            [key]: collect[key]
              ? [...collect[key], { ...action }]
              : [{ ...action }],
          };
          return collect;
        }, {}),
        triggers: [...triggers],
      };
    }
  );

export const getDeleteModal = ({ deleteModal }) => deleteModal;

export const getStepsInMovingBranch = ({ stepsInMovingBranch }) =>
  stepsInMovingBranch;

export const getMovingModalState = ({ movingConditionModal }) =>
  movingConditionModal.show;

export const getDropOnLastInBranch = ({ movingConditionModal }) =>
  movingConditionModal.isDropOnLastInBranch;

export const getMovingModalType = ({ movingConditionModal }) =>
  movingConditionModal.type;

export const getValidationState = ({ validationState }) => validationState;

export const getMergeArgs = ({ automation }) => {
  const { customObject } = automation;
  return {
    isForCustomObject:
      !!customObject &&
      customObject.name.toLowerCase() !== getObjectModelName('contacts'),
    customObjectClassName: customObject.objectClass,
    customObject,
    entityName: customObject.entityName,
  };
};

export const getErrorCount = ({ errorSteps }) => (errorSteps || []).length > 0;

export const getErrorDebug = ({ errorSteps, deletedSteps }) =>
  `${errorSteps.length} [${(errorSteps || []).join(',')}] [${(
    deletedSteps || []
  ).join(',')}]`;

export const getError = ({ errorSteps }) => errorSteps;

export const getAllowNoChildStep = ({ allowNoChildStep }) => allowNoChildStep;

export const getNewGotoStep = ({ newGotoStep }) => newGotoStep;

export const getCurrenEditingStep = ({ editStepInfo }) => editStepInfo?.step;

export const getNameError = ({ nameError }) => nameError;

/**
 * Compose all mappers and filters
 *
 * @param fields
 * @param categories
 * @returns [... , {...}]
 */
export const getAutomationCategorizedTeamMemberFields = ({
  fields,
  categories,
}) => {
  return compose(
    (categories) => categories.filter(({ options = [] }) => options.length),
    (categories) =>
      categories.map((cat) => {
        return {
          label: cat.name,
          options: cat.fields.map((item) => {
            return {
              label: item.displayName,
              value: item.id,
              item,
            };
          }),
        };
      }),
    (categories) =>
      categories.map((category) => {
        return {
          ...category,
          fields: fields.filter(
            (f) =>
              f.category === category.id &&
              f.fieldType === 'team_selector' &&
              !isOwnerField(f)
          ),
        };
      })
  )(categories);
};

export const getShowBlocker = ({ persisting }) => persisting !== 0;

// this should only be used by flow/index.js
export const getTriggersWithAddMore = ({ triggers }) => {
  // pass the triggers and add the add_trigger on the end
  return [
    ...triggers,
    {
      detais: {},
      hasError: false,
      id: 'add_trigger',
      prefix: 'trigger',
      stats: { numberComplete: 0 },
      order: triggers.length,
      type: 'add_trigger',
    },
  ];
};
