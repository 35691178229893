import * as PropTypes from 'prop-types';

import CloseButton from '../CloseButton';
import { TextEllipsis } from 'components/Kizen/Table';
import {
  StyledModalBody,
  StyledModalHeader,
  StyledModalFooter,
  StyledModal,
} from './styles';

export const _modalSize = {
  small: 400,
  medium: 900,
  large: 1200,
};

export const modalSize = {
  small: _modalSize.small + 'px',
  medium: _modalSize.medium + 'px',
  large: _modalSize.large + 'px',
};

const Modal = ({ className, size, ...props }) => (
  <StyledModal
    // This class is used by robert to identify new modal components
    className={`StandardModal ${className || ''}`}
    centered
    size="lg" // for resolving size conflict between react-bootstrap and our custom size
    animation={false}
    width={modalSize[size]}
    {...props}
  />
);

Modal.propTypes = {
  fitContent: PropTypes.oneOf(['y', false]),
  fitContentWidth: PropTypes.string,
  size: PropTypes.string,
};

Modal.defaultProps = {
  fitContent: 'y',
  fitContentWidth: 'fit-content',
  size: 'small',
};

export const ModalHeader = ({
  children,
  className,
  onClickClose,
  ...others
}) => (
  <StyledModalHeader className={`modal-header ${className || ''}`} {...others}>
    <TextEllipsis type="subheader" className="modal-title">
      {children}
    </TextEllipsis>
    <CloseButton onClick={onClickClose} />
  </StyledModalHeader>
);

ModalHeader.propTypes = {
  onClickClose: PropTypes.func.isRequired,
};

export const ModalBody = StyledModalBody;
export const ModalFooter = StyledModalFooter;

export default Modal;
