import React, { forwardRef, useCallback, useMemo } from 'react';
import Select from '../../Inputs/Select';

const Timezone = forwardRef(({ field, onChange, ...others }, ref) => {
  const options = useMemo(() => {
    return field.options.map((opt) => ({
      value: opt.code,
      label: opt.name,
    }));
  }, [field]);

  const handleChange = useCallback(
    (opt) => onChange(opt ? opt.value : null),
    [onChange]
  );

  return (
    <Select ref={ref} options={options} onChange={handleChange} {...others} />
  );
});
export default Timezone;
