import { NodeProps, Root, Take } from '../types';

type Props = Partial<NodeProps['Root']>;
type Children = Root['2'] | undefined;
type Args = [] | [Props] | [Children] | [Props, Children];

const destructureArgs = (...args: Args): [Props] | [Props, Children] => {
  if (args[0] === undefined) return [{}];
  if (args.length === 2) return args;
  if (Array.isArray(args[0])) return [{}, args[0]];
  return [args[0]];
};

export function root(): Take<Root, 2>;
export function root(props: Props): Take<Root, 2>;
export function root(children: Children): Root;
export function root(props: Props, children: Children): Root;
export function root(...args: Args): Root {
  const [props, children] = destructureArgs(...args);
  return Array.isArray(children) ? ['Root', props, children] : ['Root', props];
}
