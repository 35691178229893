import {
  Name,
  NameWrapper,
  ToolbarRight,
} from 'pages/Common/styles/ControlBar';
import { useCallback, useContext } from 'react';
import { StyledToolbar, StyledToolbarLeft } from './styles';
import Button from 'components/Button';
import BackButton from 'components/Button/BackButton';
import { BigListSearchInput } from 'components/Layout/BigListLayout/Toolbar';
import { useTranslation } from 'react-i18next';
import searchState from 'components/AccessRequests/searchState';

const AccessRequestsToolbar = ({
  goBackPath,
  onBackClick,
  count = 0,
  canAdd = false,
  handleShowModal,
  afterChangeSearchTerm,
  mobile = false,
  loading = false,
  entitySingular = '',
  entityPlural = '',
}) => {
  const { t } = useTranslation();
  const { searchTerm, setSearchTerm } = useContext(searchState.Context);

  const changeSearchTerm = useCallback(
    (...args) => {
      setSearchTerm(...args);
      afterChangeSearchTerm?.();
    },
    [setSearchTerm, afterChangeSearchTerm]
  );

  return (
    <StyledToolbar>
      <StyledToolbarLeft>
        <BackButton onClick={onBackClick} to={goBackPath}>
          {t('back')}
        </BackButton>
        {!mobile ? (
          <BigListSearchInput
            value={searchTerm}
            onChange={changeSearchTerm}
            placeholder={`${t('Find')} ${entitySingular}`}
            loading={loading}
          />
        ) : null}
      </StyledToolbarLeft>
      {!mobile ? (
        <>
          <NameWrapper>
            <Name type="header">
              {count} {count === 1 ? entitySingular : entityPlural}
            </Name>
          </NameWrapper>
          <ToolbarRight>
            {canAdd ? (
              <Button onClick={handleShowModal}>{`${t(
                'Add'
              )} ${entitySingular}`}</Button>
            ) : null}
          </ToolbarRight>
        </>
      ) : null}
    </StyledToolbar>
  );
};

export default AccessRequestsToolbar;
