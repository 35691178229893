import { useInfiniteQuery } from 'react-query';

export const useInfinityFetch = ({
  fetch,
  enabled,
  onLoaded,
  queryKey,
  params,
  keepPreviousData = true,
}) => {
  return useInfiniteQuery(
    [...queryKey, params],
    async ({ pageParam }) => {
      const data = await fetch({ page: pageParam, ...params });
      onLoaded?.();
      return data;
    },
    {
      keepPreviousData,
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.next
          ? new URL(lastPage.next).searchParams.get('page')
          : undefined;
      },
      getPreviousPageParam: (previousPage) => {
        return previousPage?.prev
          ? new URL(previousPage.prev).searchParams.get('page')
          : undefined;
      },
      enabled,
    }
  );
};
