export const InputsModifyRelatedEntitiesAutomations = {
  OBJECT_TO_MODIFY: 'objectToModify',
  AUTOMATION_TARGET_RELATIONSHIP_FIELDS: 'automationTargetRelationshipFields',
  ACTION_TYPE: 'actionType',
  RESUME_PAUSED_AUTOMATIONS: 'resumePausedAutomations',
  AUTOMATION_IDS: 'automationIds',
};

export const ModifyRelatedEntitiesAutomationsActionTypes = {
  START: 'start',
  PAUSE: 'pause',
  CANCEL: 'cancel',
};

export const getActionOptions = (t) => [
  {
    value: ModifyRelatedEntitiesAutomationsActionTypes.START,
    label: t('START'),
  },
  {
    value: ModifyRelatedEntitiesAutomationsActionTypes.PAUSE,
    label: t('PAUSE'),
  },
  {
    value: ModifyRelatedEntitiesAutomationsActionTypes.CANCEL,
    label: t('CANCEL'),
  },
];
