import { useMemo } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import {
  GenericWizardModalBody,
  GenericWizardSection,
} from 'components/GenericWizard';
import MultiSelect from 'components/Inputs/MultiSelect';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import { CONTEXTS } from 'components/Wizards/utils';
import { useTranslation } from 'react-i18next';
import { colorsSecondary } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { isGroupFilter, isCustomFilter } from './types';

import TimeZoneFilterWarning from 'components/Wizards/MetaFilters/TimeZoneFilterWarning';
import { useWizardFilterContext } from 'components/Wizards/MetaFilters/context';
import {
  GroupPill,
  useCanViewCustomObject,
  getGroupLink,
} from 'ts-components/filters/GroupPill';
import { FilterSets } from './FilterSets';
import { useRelatedGroupsMatchHelper } from 'components/Wizards/MetaFilters/hooks/useRelatedGroupsMatchHelper';

const FilterWrapper = styled.div`
  margin-top: -15px;
  ${({ clipBottom }) =>
    clipBottom &&
    css`
      & > :last-child:not(:only-child) {
        // TODO struggled with pulling the bottom of the filters down to achieve our
        // standard modal padding, so we end-up with this hack to make the bottom
        // button a bit lower.
        overflow: hidden;
        height: 20px;
      }
    `}
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
`;

const GroupSelect = styled(MultiSelect)`
  margin-bottom: 15px;
`;

export const MyRecordsFilterWarning = ({
  type,
  groups,
  filter,
  hasMyRecordFilter,
  hideWarnings = false,
  isMyRecordsResultToZero = false,
}) => {
  const { t } = useTranslation();
  const isCustomFilterType = isCustomFilter(type);

  const showWarning = useMemo(() => {
    const show =
      !hideWarnings &&
      (isCustomFilterType
        ? hasMyRecordFilter
        : groups.some(({ isMyRecordsGroup }) => isMyRecordsGroup));
    return show
      ? {
          result: isMyRecordsResultToZero
            ? t('return zero results')
            : t('be ignored'),
          filterType: isCustomFilterType ? t('filters') : t('groups'),
        }
      : null;
  }, [
    isCustomFilterType,
    groups,
    hasMyRecordFilter,
    hideWarnings,
    isMyRecordsResultToZero,
    t,
  ]);

  return showWarning ? (
    <KizenTypography fontStyle="italic" color={colorsSecondary.red.dark}>
      <br />*
      {t(
        'One of the {{filterType}} contains a "My Records" filter that will {{result}}.',
        showWarning
      )}
    </KizenTypography>
  ) : null;
};

const FilterWizardBody = ({
  hideWarnings,
  area,
  additionalFields,
  disabled,
  footer,
  hideGroups = false,
  isMyRecordsResultToZero,
  defaultCustomFilter,
  intitialRenderRef,
}) => {
  const { t } = useTranslation();
  const {
    filterSets,
    filterOps: { hasMyRecordFilter, hasStageRecordFilter },
    conditionTypeOptions,
    groupOptions,
    loading,
    setValues,
    values,
    customObject,
  } = useWizardFilterContext();

  const { id: customObjectId, fetchUrl } = customObject || {};
  const canViewCustomObject = useCanViewCustomObject(customObjectId);

  const groupError = useMemo(
    () => (values?.groups || []).some(({ deleted }) => deleted),
    [values]
  );

  const showAddFilters =
    filterSets.length === 1 ? filterSets[0][1]?.filters?.length > 1 : true;

  const { groupIsMe, groupIsStage } = useRelatedGroupsMatchHelper(filterSets);

  const PillComponent = useMemo(() => {
    if (canViewCustomObject) {
      return ({ value, ...rest }) => {
        const href = getGroupLink(value, fetchUrl, customObjectId);
        return <GroupPill {...rest} value={value.id} href={href} />;
      };
    }
  }, [canViewCustomObject, customObjectId, fetchUrl]);

  const filteredOptions = useMemo(() => {
    return conditionTypeOptions.filter(({ value }) => {
      if (isGroupFilter(value)) {
        return !hideGroups;
      }
      return true;
    });
  }, [conditionTypeOptions, hideGroups]);

  if (
    (defaultCustomFilter || !values.type) &&
    area === CONTEXTS.fieldsLayout &&
    intitialRenderRef.current &&
    filteredOptions.length === 1 &&
    !values.type &&
    !loading
  ) {
    setValues({ ...values, type: filteredOptions[0].value });
    intitialRenderRef.current = false;
  }

  if (loading) {
    return null;
  }

  return (
    <GenericWizardModalBody>
      <GenericWizardSection header={t('Choose Condition Type')} isActive>
        {additionalFields}
        <ButtonGrid>
          {filteredOptions.map((option) => (
            <ButtonGroupImageButton
              key={option.value}
              selected={option.value === values.type}
              onClick={() => {
                setValues({ ...values, type: option.value });
              }}
              option={option}
              disabled={disabled}
              data-qa={`condition-type-${option.value}`}
            />
          ))}
        </ButtonGrid>
      </GenericWizardSection>
      <GenericWizardSection
        header={t('Condition Settings')}
        isActive={values.type}
        leavePAlone
      >
        {values.type ? (
          <>
            {isGroupFilter(values.type) && (
              <GroupSelect
                label={
                  values.type === 'in_group'
                    ? t('Is In Group(s)')
                    : t('Is Not In Group(s)')
                }
                placeholder={t('Choose Group(s)')}
                menuInline
                options={groupOptions}
                value={(values.groups || []).map((g) => g.id)}
                PillComponent={PillComponent}
                onChange={(opts) =>
                  setValues({
                    ...values,
                    groups: opts.map((opt) => opt.group),
                  })
                }
                error={groupError}
              />
            )}
            {isCustomFilter(values.type) && (
              // A bit of a hack, but: do not clip the bottom when the "add filter set" button with dropdown may appear
              <FilterWrapper clipBottom={!showAddFilters}>
                <FilterSets />
              </FilterWrapper>
            )}
          </>
        ) : null}

        <MyRecordsFilterWarning
          hideWarnings={hideWarnings?.isMeWarning}
          isMyRecordsResultToZero={isMyRecordsResultToZero}
          hasMyRecordFilter={hasMyRecordFilter || groupIsMe}
          {...values}
        />
        <TimeZoneFilterWarning
          hideWarnings={hideWarnings?.timezoneWarning}
          {...values}
          hasStageRecordFilter={hasStageRecordFilter || groupIsStage}
        />
        {footer}
      </GenericWizardSection>
    </GenericWizardModalBody>
  );
};

export default FilterWizardBody;
