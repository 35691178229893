import React from 'react';

import { getDataQAForInput } from 'components/Inputs/helpers';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import {
  StyledFieldsetLabel,
  StyledLabel,
  StyledInput,
  StyledInputFacade,
  StyledText,
  StyledFieldsetOutlineLabel,
} from './styles';

const Checkbox = React.forwardRef(
  (
    {
      error,
      disabled,
      checked,
      indeterminate,
      onChange,
      value,
      label,
      children,
      size,
      underline,
      isGroupItem,
      id,
      ...others
    },
    ref
  ) => {
    const [labelTooltipProps, labelTooltip] = useTruncationTooltip();
    const [optionTooltipProps, optionTooltip] = useTruncationTooltip();
    const LabelComponent = underline
      ? StyledFieldsetLabel
      : StyledFieldsetOutlineLabel;
    const renderCheckbox = () => {
      return (
        <>
          <StyledInput
            type="checkbox"
            {...getDataQAForInput(`client-${id}`, 'checkbox')}
            error={error}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            value={value}
          />
          <StyledInputFacade
            size={size}
            className="CheckboxInputFacade"
            error={error}
            disabled={disabled}
            checked={checked}
            indeterminate={indeterminate}
          />
          {(isGroupItem || size !== 'small') && underline && (
            <StyledText
              shrink={size === 'small' && !isGroupItem}
              className="CheckboxText"
              disabled={disabled}
              error={error}
              {...optionTooltipProps}
            >
              {label}
            </StyledText>
          )}
          {children}
          {optionTooltip}
        </>
      );
    };

    if ((size === 'small' && !isGroupItem) || !underline) {
      return (
        <StyledLabel standaloneLabel size={size} ref={ref} {...others}>
          {labelTooltip}
          <LabelComponent standaloneLabel {...labelTooltipProps}>
            {label}
          </LabelComponent>
          {renderCheckbox()}
        </StyledLabel>
      );
    }

    return (
      <StyledLabel size={size} ref={ref} {...others}>
        {renderCheckbox()}
      </StyledLabel>
    );
  }
);

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
  error: false,
  disabled: false,
  checked: false,
  indeterminate: false,
  size: 'medium',
  isGroupItem: false,
  underline: true,
  value: null,
  label: undefined,
};

export default Checkbox;
