import {
  checkDeleted,
  displayNameDescriptor,
  nameDescriptor,
  checkListDeleted,
} from 'pages/AutomationEngine/helpers';
import { ownerSubtypes } from './constants';
import { namedToOption } from 'services/helpers';
const forApi = (step) => ({
  details: {
    ...step.details,
    automationsToStart: (step.details.automationsToStart || []).map(
      ({ value }) => value
    ),
  },
});

const forFlow = (apiObject, addErrorMessage, messageDictionary) => {
  const {
    contextEntityField,
    newEntityStage,
    newEntityOwnerRole,
    newEntityOwnerEmployees,
    automationsToStart,
    newEntityOwnerSubType,
    ...rest
  } = apiObject;

  checkDeleted(
    messageDictionary,
    addErrorMessage,
    contextEntityField,
    displayNameDescriptor
  );
  checkDeleted(
    messageDictionary,
    addErrorMessage,
    newEntityStage,
    nameDescriptor
  );
  if (newEntityOwnerSubType === ownerSubtypes.ROUND_ROBIN_SPECIFIC_ROLE) {
    checkDeleted(
      messageDictionary,
      addErrorMessage,
      newEntityOwnerRole,
      nameDescriptor,
      messageDictionary.deletedRole
    );
  }
  if (
    [
      ownerSubtypes.SPECIFIC_TEAM_MEMBER,
      ownerSubtypes.ROUND_ROBIN_CHOOSE_TEAM_MEMBERS,
    ].includes(newEntityOwnerSubType)
  ) {
    checkListDeleted(
      messageDictionary,
      addErrorMessage,
      newEntityOwnerEmployees,
      displayNameDescriptor,
      messageDictionary.teamMember
    );
  }
  checkListDeleted(
    messageDictionary,
    addErrorMessage,
    automationsToStart,
    nameDescriptor
  );

  return {
    ...rest,
    contextEntityField: contextEntityField?.deleted
      ? null
      : contextEntityField.id,
    newEntityStage: newEntityStage?.id || null,
    newEntityOwnerRole: newEntityOwnerRole?.id || null,
    newEntityOwnerEmployees: newEntityOwnerEmployees.map(({ id }) => id),
    automationsToStart: automationsToStart.map(namedToOption),
    newEntityOwnerSubType,
  };
};

export const createRelatedEntityDTO = {
  forApi,
  forFlow,
};
