import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from 'app/colors';
import { TextSpan, fontWeights, fontSizes } from 'app/typography';
import Icon from 'components/Kizen/Icon';

export const StyledInfoIcon = styled(Icon)`
  & svg {
    width: 10px;
    height: 10px;
  }

  ${({ tooltipPlacement }) =>
    tooltipPlacement === 'right'
      ? css`
          margin-left: 5px;
        `
      : null};
`;

export const StyledDiv = styled.div`
  display: flex;
  ${({ removeMargin }) =>
    !removeMargin &&
    css`
      margin: 12px;
    `}

  ${({ textPlacement }) =>
    (textPlacement === 'top' || textPlacement === 'regular-top') &&
    css`
      flex-direction: column;
      margin: 0;
    `}
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  ${({ removeLabelMargin }) =>
    !removeLabelMargin &&
    css`
      margin-bottom: 12px;
    `}
`;

export const StyledSmallLabel = styled(TextSpan)`
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.small};
`;

export const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
  flex-shrink: 0;

  ${({ removeMargin }) =>
    removeMargin &&
    css`
      margin-bottom: 0;
    `}

  ${({ label, textPlacement }) =>
    label &&
    css`
      margin-left: ${textPlacement === 'left' ? '10px' : '0px'};
      margin-right: ${textPlacement === 'right' ? '10px' : '0px'};
    `}
`;

export const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  ${({ backgroundColor }) =>
    backgroundColor
      ? css`
          :not(:disabled) + span {
            background-color: ${backgroundColor};
          }
          :not(:checked):focus + span {
            box-shadow: 0 0 2px ${backgroundColor};
          }
          :checked:focus + span {
            box-shadow: 0 0 2px ${colorsButton.blue.hover};
          }
        `
      : css`
          :focus + span {
            box-shadow: 0 0 2px ${colorsButton.blue.hover};
          }
        `}
  &:checked + span {
    background-color: ${colorsButton.blue.hover};
  }

  &:checked + span:before {
    transform: translateX(14px);
  }

  &:disabled + span {
    background-color: ${grayScale.medium};
  }
`;

export const StyledSpan = styled.span`
  position: absolute;
  cursor: pointer;
  &[disabled] {
    cursor: auto;
  }
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 16px;

  :before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    left: 2px;
    top: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;
