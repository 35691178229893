import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Templates from './Templates';
import Broadcasts from './Broadcasts';
import Automations from './Automations';
import {
  Nav as BootstrapNav,
  Navbar as BootstrapNavBar,
} from 'react-bootstrap';
import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';
import { grayScale } from 'app/colors';
import { breakpoints } from 'app/spacing';
import { Label } from 'app/typography';
import { contentWidthCss } from 'components/Layout/PageContentWidth';
import { gutters } from 'app/spacing';
import { border, colorsButton } from 'app/colors';
import { useTranslation } from 'react-i18next';
import BasicModal from 'components/Modals/presets/BasicModal';
import * as PropTypes from 'prop-types';
import CardToolbar, {
  CardToolbarSearch,
  CardToolbarSection,
} from 'components/Layout/CardToolbar';
import useDebounce from 'react-use/lib/useDebounce';
import { DEFAULT_INPUT_DELAY } from 'utility/config';
import { PageToolbarTitle } from 'components/Layout/PageToolbar';
import { useSelector } from 'react-redux';
import SentMessageModal from 'components/Modals/SentMessage';
import { messageMapper } from 'pages/Library/helpers';
import LibraryService from 'services/LibraryService';

const StyledIcon = styled(Icon)`
  display: none;
  @media (min-width: ${breakpoints.md + 1}px) {
    display: flex;
  }
`;
const SubNavigationNavItemDivider = styled.div`
  width: 1px;
  background: ${grayScale.mediumLight};
  align-self: stretch;
`;
const SubNavigationNavBar = styled.div`
  & .navbar {
    cursor: pointer;
    position: relative;
    ${contentWidthCss}
    margin: 0 auto ${gutters.spacing(3, 4)}px auto;
    ${border}
    border-color: ${grayScale.mediumLight};
    background: ${grayScale.white};
    padding: 0;
  }

  & .navbar-nav {
    flex: 1;
    justify-content: space-evenly;
    flex-direction: row !important;
  }

  @media (max-width: ${breakpoints.md}px) {
    & .navbar {
      margin: ${gutters.spacing(2)}px auto;
    }

    & .navbar-nav {
      align-items: flex-start;
    }

    & .navbar {
      border-radius: 0;
      width: 100%;
    }

    & .navbar-toggler {
      margin: ${gutters.spacing(2)}px;
    }
  }
`;
export const CommonNavItemStyle = styled.div`
  padding: 0 0;
  width: 100%;
  @media (min-width: ${breakpoints.md + 1}px) {
    padding: ${gutters.standard} 0 !important;
  }

  & span {
    color: ${grayScale.dark};
    font-size: 14px;
    margin-top: 8px;
    @media (max-width: ${breakpoints.md}px) {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  ${({ active }) => {
    return (
      active &&
      `
      & span,
      & i {
        color: ${colorsButton.blue.hover};
      }
    `
    );
  }};

  &:hover span,
  &:hover i {
    color: ${colorsButton.blue.hover};
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoints.md}px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

const libraryRoutes = {
  templates: {
    label: (t) => t('Templates'),
    titles: (t) => ({
      common: {
        single: t('Template'),
        plural: t('Templates'),
      },
    }),
    placeholder: (t) => ({ common: t('Find Templates') }),
    component: Templates,
    icon: 'copy-light',
    type: 'templates',
    category: 'template',
    fetchFn: LibraryService.getTemplateById,
  },
  broadcasts: {
    label: (t) => t('Broadcasts'),
    titles: (t) => ({
      common: {
        single: t('Message'),
        plural: t('Messages'),
      },
      inFolder: {
        single: t('Message'),
        plural: t('Messages'),
      },
    }),
    placeholder: (t) => ({
      common: t('Find Messages'),
      inFolder: t('Find Messages'),
    }),
    component: Broadcasts,
    icon: 'calendar-alt',
    type: 'broadcasts',
    category: 'broadcast',
    fetchFn: LibraryService.getBroadcastById,
  },
  automations: {
    label: (t) => t('Automations'),
    titles: (t) => ({
      common: {
        single: t('Automation'),
        plural: t('Automations'),
      },
      inFolder: {
        single: t('Message'),
        plural: t('Messages'),
      },
    }),
    placeholder: (t) => ({
      common: t('Find Automations'),
      inFolder: t('Find Messages'),
    }),
    component: Automations,
    icon: 'play-light',
    type: 'automations',
    category: 'automation',
    fetchFn: LibraryService.getAutomationById,
  },
};

// like interface for returning object
const unify = (data, type, category) => {
  return {
    // type should follow url part
    type, //'templates' | 'broadcasts' | 'automations';
    category, //'template' | 'broadcast' | 'automation';
    id: data.id, //string;
    name: data.name, // string;
    subject: data.subject, // string;
  };
};

const EMPTY_ARRAY = [];

export const SYSTEM_FOLDERS = {
  singleSends: 'Single Sends',
  scheduledBroadcasts: 'Scheduled Broadcasts',
  failedBroadcasts: 'Failed Broadcasts',
};

const MessageTemplatePicker = ({
  onCancel,
  show = false,
  onSubmit,
  suppressedRoutes = EMPTY_ARRAY,
  suppressedFolders = EMPTY_ARRAY,
  heading,
  ...others
}) => {
  const businessName = useSelector(
    ({ authentication }) => authentication?.chosenBusiness?.name ?? ''
  );

  const [chosenMessage, setChosenMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  useDebounce(() => setDebouncedSearch(search), DEFAULT_INPUT_DELAY, [search]);

  const [itemsCount, setItemsCount] = useState(0);
  const routes = useMemo(() => {
    return suppressedRoutes.length
      ? Object.entries(libraryRoutes).reduce(
          (collect, [key, value]) =>
            suppressedRoutes.some((type) => type === key)
              ? collect
              : { ...collect, [key]: value },
          {}
        )
      : libraryRoutes;
  }, [suppressedRoutes]);

  const [tabId, setTabId] = useState(Object.keys(routes)[0]);

  const handleTabId = useCallback(
    (key) => {
      setTabId(key);
      setSearch('');
      setCurrentFolder(null);
      if (tabId !== key) setItemsCount(0);
    },
    [tabId]
  );

  const Component = useMemo(() => {
    return routes[tabId].component;
  }, [tabId, routes]);

  const handleCancel = useCallback(() => {
    onCancel();
    setSearch('');
  }, [onCancel]);

  const { type, category } = useMemo(() => {
    const { type, category } = routes[tabId];
    return { type, category };
  }, [tabId, routes]);

  const handleOnSubmit = useCallback(
    (data) => {
      const petrifiedData = unify(data, type, category);
      onSubmit(petrifiedData);
    },
    [type, category, onSubmit]
  );
  const { t } = useTranslation();

  const searchPlaceholder = useMemo(() => {
    return currentFolder?.id
      ? routes[tabId].placeholder(t).inFolder
      : routes[tabId].placeholder(t).common;
  }, [currentFolder?.id, routes, t, tabId]);

  const titles = useMemo(() => {
    return currentFolder?.id
      ? routes[tabId].titles(t).inFolder
      : routes[tabId].titles(t).common;
  }, [currentFolder?.id, routes, t, tabId]);

  const handleActionView = useCallback(
    async (_, message) => {
      setIsLoading(true);
      setChosenMessage({
        ...messageMapper(message, businessName, t),
        bodySanitized: true,
      });
      try {
        const data = await routes[tabId].fetchFn({
          id: message.id,
        });
        setChosenMessage(
          messageMapper(data, businessName, t, { hideDynamicImages: true })
        );
      } catch (err) {
        setChosenMessage();
      } finally {
        setIsLoading(false);
      }
    },
    [businessName, routes, t, tabId]
  );

  useEffect(() => {
    !show && setSearch('');
  }, [show]);

  return (
    <>
      <BasicModal
        onHide={handleCancel}
        onConfirm={handleCancel}
        size="large"
        typeOfContent={'default'}
        show={show}
        fitContent={'y'}
        buttonText={t('Close')}
        actionBtnColor="blue"
        leftBtn={false}
        heading={heading || t('Choose Email Template')}
        {...others}
      >
        <SubNavigationNavBar>
          <BootstrapNavBar variant="light" expand="md">
            <BootstrapNav className="ml-auto">
              {Object.entries(routes).map(([key, route], index, arr) => (
                <React.Fragment key={key}>
                  <CommonNavItemStyle
                    key={key}
                    active={key === tabId}
                    onClick={() => handleTabId(key)}
                  >
                    <StyledIcon icon={route.icon} color={grayScale.dark} />
                    <Label as="span" textTransform="none">
                      {typeof route.label === 'function'
                        ? route.label(t)
                        : route.label}
                    </Label>
                  </CommonNavItemStyle>
                  {index !== arr.length - 1 && <SubNavigationNavItemDivider />}
                </React.Fragment>
              ))}
            </BootstrapNav>
          </BootstrapNavBar>
        </SubNavigationNavBar>
        <div>
          <Component
            toolbar={
              <MessageTemplatePickerToolbar
                includeSearch
                placeholder={searchPlaceholder}
                onChangeSearch={setSearch}
                search={search}
                count={itemsCount}
                {...titles}
              />
            }
            t={t}
            setCurrentFolder={setCurrentFolder}
            search={debouncedSearch}
            onSubmit={handleOnSubmit}
            onActionView={handleActionView}
            resetSearch={() => setSearch('')}
            setItemsCount={setItemsCount}
            suppressedFolders={suppressedFolders}
          />
        </div>
      </BasicModal>
      {chosenMessage && (
        <SentMessageModal
          show
          isLoading={isLoading}
          message={chosenMessage}
          buttonLabel={t('Back')}
          onHide={() => setChosenMessage(null)}
        />
      )}
    </>
  );
};
export default MessageTemplatePicker;

MessageTemplatePicker.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
  suppressedRoutes: PropTypes.array,
  suppressedFolders: PropTypes.array,
};

const StyledCardToolbar = styled(CardToolbar)`
  margin-bottom: 20px;
  padding: 0;
`;
const CardToolbarSectionStart = styled(CardToolbarSection)`
  justify-content: flex-start;
  flex: 1;
`;
const CardToolbarSectionEnd = styled(CardToolbarSection)`
  justify-content: flex-end;
  flex: 1;
`;

export function MessageTemplatePickerToolbar({
  includeSearch,
  search,
  onChangeSearch,
  single,
  plural,
  count,
  placeholder,
  ...others
}) {
  return (
    <StyledCardToolbar tall={includeSearch} {...others}>
      <CardToolbarSectionStart>
        {includeSearch && (
          <CardToolbarSearch
            placeholder={placeholder}
            value={search}
            onChange={onChangeSearch}
          />
        )}
      </CardToolbarSectionStart>
      <PageToolbarTitle single={single} plural={plural} count={count} />
      <CardToolbarSectionEnd />
    </StyledCardToolbar>
  );
}

MessageTemplatePickerToolbar.propTypes = {
  includeSearch: PropTypes.bool,
  search: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
};

MessageTemplatePickerToolbar.defaultProps = {
  includeSearch: false,
};
