import { ClearMenuButton } from 'components/Kizen/Menu';

export default function ClearSelectButton({
  onClick = null,
  select,
  ...props
}) {
  const clickHandler = (event) => {
    if (select.selectProps.closeOnClearClick) {
      select.selectProps.selectManager.onMenuClose();
    }
    if (select.selectProps.closeOnClearClick === false) {
      select.clearValue();
    } else {
      select.setValue(select.isMulti ? [] : null, 'clear');
    }
    if (onClick) {
      onClick(event);
    }
  };
  return <ClearMenuButton onClick={clickHandler} {...props} />;
}

ClearSelectButton.enrich = {
  select: true,
};
