import React from 'react';
import * as PropTypes from 'prop-types';
import { TableCell, StyledRadioGroup } from 'pages/Subscription/styles';
import {
  businessLevelsMap,
  BusinessLevelTypes,
} from 'pages/Subscription/model';
import BusinessBox from '.';

const RadioGroupBox = ({ values, onChange }) => {
  return (
    <StyledRadioGroup
      onChange={onChange}
      value={values.business_level}
      customClassName={''}
      className="select-box"
      variant="select-box"
    >
      {Object.keys(businessLevelsMap).map((key) => {
        const { icon, label, name, value } = businessLevelsMap[key];
        const selected = values.business_level === key;
        const textLabel =
          key === BusinessLevelTypes.PROFESSIONAL
            ? 'Essentials Plus...'
            : key === BusinessLevelTypes.ENTERPRISE
              ? 'Professional Plus...'
              : null;
        return (
          <TableCell key={key} value={value} className="box-cell">
            <BusinessBox
              className={selected ? 'selected' : ''}
              icon={icon}
              label={label}
              name={name}
              selected={selected}
              value={value}
              key={key}
              additionalLabel={textLabel}
            />
          </TableCell>
        );
      })}
    </StyledRadioGroup>
  );
};

RadioGroupBox.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioGroupBox;
