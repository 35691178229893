import TriggerCard from 'pages/AutomationEngine/Engine/Steps/TriggerCard';
import DraggableStepCard from 'pages/AutomationEngine/Engine/Steps/DraggableStepCard';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  const title = step.details.message.name || step.details.message.subject;
  return `${t('Link Clicked in Email')} '${title}'`;
};

export default function Card({ step, ...others }) {
  return step?.goalType ? (
    <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>
  ) : (
    <TriggerCard {...others}>{content({ step })}</TriggerCard>
  );
}
