import { useTranslation } from 'react-i18next';
import { getColumns } from '../../util';
import AccessRequestTableOthers from 'components/AccessRequests/Tables/Others';

const AccessRequestsChartGroupsPage = (props) => {
  const { t } = useTranslation();

  return (
    <AccessRequestTableOthers
      {...props}
      getColumns={getColumns}
      entitySingular={t('Chart Group')}
      entityPlural={t('Chart Groups')}
    />
  );
};

export default AccessRequestsChartGroupsPage;
