import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ButtonProps } from '../../types';
import { getTypeInfo, NodeType } from '../types';
import {
  ElementContainer,
  DEFAULT_ELEMENT_CONTAINER_PROPS,
} from './ElementContainer';
import { applyLegacyContainerPadding } from './utils';

type ButtonContainerProps = ButtonProps & {
  children: ReactNode;
  backgroundColor: string;
  draggingType?: NodeType;
};

type ButtonWrapperProps = ButtonContainerProps & {
  applyLegacyContainerPadding: boolean;
};

export const DEFAULT_BUTTON_CONTAINER_PROPS = {
  ...DEFAULT_ELEMENT_CONTAINER_PROPS,
  containerPaddingTop: '10',
  containerPaddingRight: '0',
  containerPaddingBottom: '10',
  containerPaddingLeft: '0',
};

const justifyContent = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const ButtonWrapper = styled(ElementContainer)<ButtonWrapperProps>`
  display: flex;
  justify-content: ${({ alignment }) => justifyContent[alignment] || 'center'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ applyLegacyContainerPadding }) =>
    applyLegacyContainerPadding &&
    css`
      padding: 5px 7.5px;
    `}
`;

export const ButtonContainer = forwardRef(
  (
    { children, draggingType, ...rest }: ButtonContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <ButtonWrapper
      ref={ref}
      ignoreChildrenDrag={getTypeInfo(draggingType)?.isContent}
      applyLegacyContainerPadding={applyLegacyContainerPadding(rest)}
      {...rest}
    >
      {children}
    </ButtonWrapper>
  )
);
