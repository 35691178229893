import { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import Cols from 'components/Layout/Cols';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import {
  StyledFormInput,
  ButtonsContainer,
  ApiCard,
} from 'pages/Account/pages/Profile/Forms/styles';
import {
  TableTitleWrapper,
  TableBoxesWrapper,
} from 'pages/Account/pages/Profile/Forms/tableStyles';
import ProfileInfiniteTable from 'pages/Account/pages/Profile/Forms/ProfileInfiniteTable';

import BasicModal from 'components/Modals/presets/BasicModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import ApiKeysModalBody from 'pages/Account/pages/Profile/Forms/ApiKeysModalBody';

import { getApiKeysAccess } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';

import { useApiKeys } from 'hooks/apiKeys/useApiKeys';
import { createColumns } from 'pages/Account/pages/Profile/Forms/APIKeys/columns';

import {
  APIKeysNotice,
  LastTableBoxesWrapper,
} from 'pages/Account/pages/Profile/Forms/APIKeys/styles';

import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const KEY_WIDTH = '300px';
const REFRESH_WIDTH = '205px';
const ACTION_WIDTH = '51px';
const PADDING_WIDTH = '40px';

const columnWidths = {
  key: KEY_WIDTH,
  created: REFRESH_WIDTH,
  actions: ACTION_WIDTH,
  padding: PADDING_WIDTH,
};

const _APIKeys = ({ userId, businessId }) => {
  const { t } = useTranslation();
  const teamMember = useSelector((s) => s.authentication.teamMember || {}); // current user
  const {
    handleViewApiKey: onKeyView,
    creatApiKeyMutation,
    apiKeys: keys,
    createdKey,
    viewedKey,
    messageHeadData,
    messagesSort,
    setMessagesSort,
    apiKeysModalProps,
    apiKeysModalButtonProps,

    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,
    confirmDeletionModalProps,
    confirmDeletionModal,
    setToDelete,
    anyApiKeys,
  } = useApiKeys(teamMember?.id);

  const columns = useMemo(
    () =>
      createColumns({
        onSelectAction: ({ value }, key) => {
          const { id } = key;
          if (value === 'onKeyView') {
            onKeyView(id);
          }
          if (value === 'onKeyDelete') {
            setToDelete(id);
            confirmDeletionModal.show();
          }
        },
        columnWidths,
        canRemove: true,
        t,
      }),
    [onKeyView, setToDelete, confirmDeletionModal, t]
  );

  return (
    <>
      <ApiCard as={ContentWidth}>
        <TableTitleWrapper>
          <KizenTypography as="h3" type="subheader">
            {t('API Keys')}
          </KizenTypography>
          <ButtonsContainer>
            <Button
              disabled={false}
              onClick={() => creatApiKeyMutation.mutate()}
              variant="text"
              style={{ height: 'auto', lineHeight: '100%' }}
            >
              {`+ ${t('Add API Key')}`}
            </Button>
          </ButtonsContainer>
        </TableTitleWrapper>
        <TableBoxesWrapper>
          <APIKeysNotice>
            <KizenTypography as="div">
              {t(
                '(For Techies) API Keys allow full access to your account, just like your password. API Keys are only necessary to connect to other tools, so make sure to store your information securely.'
              )}
              &nbsp;
              {!anyApiKeys
                ? t(
                    'You don’t yet have any API Keys. Please add an API Key using the button above.'
                  )
                : false}
            </KizenTypography>
          </APIKeysNotice>
        </TableBoxesWrapper>
        <LastTableBoxesWrapper
          as={Cols}
          columns={2}
          gutter={`${gutters.spacing(4)}px`}
          noBottomMargin={keys && !!keys.length}
        >
          <StyledFormInput
            label={t('User ID')}
            placeholder={t('User ID')}
            value={userId}
            disabled
          />
          <StyledFormInput
            label={t('Business ID')}
            placeholder={t('Business ID')}
            value={businessId}
            disabled
          />
        </LastTableBoxesWrapper>
        {keys ? (
          <>
            <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
              <ScrollContainerBlocker />
            </ScrollContainerBlockerWrapper>
            <ProfileInfiniteTable
              columns={columns}
              data={keys}
              messageHeadData={messageHeadData}
              messagesSort={messagesSort}
              setMessagesSort={setMessagesSort}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isFetching={isFetching}
              status={status}
            />
          </>
        ) : (
          false
        )}
      </ApiCard>
      <BasicModal {...apiKeysModalButtonProps} {...apiKeysModalProps}>
        <ApiKeysModalBody
          apiKey={createdKey || viewedKey}
          userId={userId}
          businessId={businessId}
          showCreatedKeyInfo={!!createdKey}
        />
      </BasicModal>
      <ConfirmDeletionModal
        heading={t('Please Confirm Deletion')}
        {...confirmDeletionModalProps}
      >
        <KizenTypography>
          {t(
            'Deleting this API key will make all subsequent requests using it denied by Kizen.'
          )}
        </KizenTypography>
      </ConfirmDeletionModal>
    </>
  );
};

_APIKeys.propTypes = {
  userId: PropTypes.string.isRequired,
  businessId: PropTypes.string.isRequired,
};

const APIKeys = (props) => {
  const access = useSelector(getApiKeysAccess);
  return access ? _APIKeys(props) : false;
};
export default APIKeys;
