import { useState, useEffect, useRef } from 'react';

export const LOAD_THRESHOLD = 0.75;

export const useMenuList = (
  isFetchingNextPage: any,
  fetchNextPage: any,
  fetchThreshold = LOAD_THRESHOLD,
  hasNextPage = true
) => {
  const ref = useRef<any>();
  const [menuList, setMenuList] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (menuList) {
      const listener = (ev: any) => {
        if (ev.target) {
          const { scrollTop, clientHeight, scrollHeight } = ev.target;
          if (
            !isFetchingNextPage &&
            hasNextPage &&
            (scrollTop + clientHeight) / scrollHeight > fetchThreshold
          ) {
            fetchNextPage();
          }
        }
      };
      menuList.addEventListener('scroll', listener, { passive: true });
      return () => menuList.removeEventListener('scroll', listener);
    }
  }, [
    menuList,
    isFetchingNextPage,
    fetchNextPage,
    fetchThreshold,
    hasNextPage,
  ]);

  return { setMenuList, ref };
};
