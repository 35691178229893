import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import InputControl from 'components/Inputs/InputControl';

export default styled(InputControl)`
  > label {
    margin-bottom: ${gutters.spacing(4)}px;
    & + * {
      margin: 0;
      label {
        // Incidentally part of <Switch />
        margin: 0;
      }
    }
  }
`;
