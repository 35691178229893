import { merge } from '@kizen/kds/util';
import { HorizontalDropzone } from 'components/DragAndDropLayout/Dropzone';
import { useCallback, useContext } from 'react';
import { ColumnProps } from './types';
import { GridState } from './state';
import { getWidthClassName, idChainSeparator } from './util';
import { Item } from './Item';

export const Column = (props: ColumnProps) => {
  const {
    items,
    id,
    rowId,
    columnEnd,
    displayItem,
    width,
    className,
    placeholderClassName,
    setCanStartRowDrag,
  } = props;

  const { setDropZones, currentDropZone, dragItem } = useContext(GridState);

  const dropZoneSplits = currentDropZone?.id.split(idChainSeparator) ?? [];
  const idSplits = id.split(idChainSeparator);
  const dropZoneItemId = dropZoneSplits[2];
  const isParentDropZone =
    !dropZoneItemId &&
    currentDropZone &&
    dropZoneSplits[0] === idSplits[0] &&
    dropZoneSplits[1] === idSplits[1] &&
    items.length === 0;

  const columnRef = useCallback(
    (element: HTMLDivElement) => {
      setDropZones((dropZones) => {
        return { ...dropZones, [id]: element };
      });
    },
    [id, setDropZones]
  );

  const splits = id.split(idChainSeparator);

  const widthClassName = getWidthClassName(width);

  return (
    <div
      className={merge(
        'kds grow flex px-spacer-5 first-of-type:pl-0 last-of-type:pr-0',
        widthClassName
      )}
    >
      <div
        className={merge(
          'min-w-0 w-full relative',
          typeof className === 'string'
            ? className
            : className?.(
                items.length === 0 && Boolean(dragItem),
                Boolean(isParentDropZone)
              )
        )}
        ref={columnRef}
        data-drop-id={id}
        data-item-count={items.length}
      >
        {items.map((item) => {
          const isDragItem = Boolean(dragItem && dragItem.id === item.id);
          const dropZoneItemId = dropZoneSplits[2];
          const isDropZoneItem = dropZoneItemId === item.id;
          const isAbove = currentDropZone?.direction === 'up';
          const topDropZoneVisible = isDropZoneItem && isAbove;
          const bottomDropZoneVisible = isDropZoneItem && !isAbove;
          return (
            <>
              {topDropZoneVisible ? <HorizontalDropzone /> : null}
              <span
                className={merge(
                  'kds block py-spacer-5 first-of-type:pt-0 last-of-type:pb-0'
                )}
              >
                <Item
                  key={item.id}
                  item={item}
                  columnId={id}
                  rowId={rowId}
                  displayItem={displayItem}
                  isDragging={isDragItem}
                  placeholderClassName={placeholderClassName}
                  setCanStartRowDrag={setCanStartRowDrag}
                />
              </span>
              {bottomDropZoneVisible ? <HorizontalDropzone /> : null}
            </>
          );
        })}
        {columnEnd ? (
          <div className="kds flex flex-col block py-spacer-5 py-0">
            {columnEnd(
              splits[0],
              splits[1],
              Boolean(dragItem) && !items.length,
              Boolean(isParentDropZone)
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
