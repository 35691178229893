import { gutters } from 'app/spacing';
import ChartTypeField from 'components/Wizards/Dashlet/sections/DisplaySettings/fields/chartType';
import { CHART_TYPES } from 'components/Wizards/shared/components/chart/types';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import React, { useCallback, useContext, useMemo } from 'react';
import Section from 'components/Wizards/shared/components/section';
import {
  AREA_RESPONSES,
  VALUE_METRICS,
  VALUE_RESPONSES,
} from 'components/Wizards/RTDV/types';
import TitleField from 'components/Wizards/Dashlet/sections/DisplaySettings/fields/title';
import { getDefaultName } from 'components/Wizards/utils';
import { useTranslation } from 'react-i18next';
import useDataConstraint from 'components/Wizards/shared/hooks/useDataConstraint';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import useCustomObjectDetails from 'components/Wizards/shared/hooks/useCustomObjectDetails';
import { Metrics } from './Metrics';

const DisplaySettingsWizardSection = ({
  index,
  active,
  done,
  last,
  mobile,
  objectId,
  contacts,
  ...rest
}) => {
  const { state, setState, entityName } = useContext(
    WizardStateContext.Context
  );
  const {
    value,
    display,
    name,
    canChooseDisplayValue,
    reportType,
    fieldObject,
    metric,
    metricField,
    includeSummary,
    summaryExplanation,
  } = state;
  const { t } = useTranslation();

  const title = rest.title || t('Display Settings');

  const { data, isLoading } = useCustomObjectDetails({
    objectId,
    contacts,
    enabled: reportType?.value === AREA_RESPONSES.SALES,
  });

  useMetadataKey('objectDetailsLoading', isLoading);

  const defaultName = useMemo(() => {
    return getDefaultName(
      {
        objectForCharts: data,
        ...state,
      },
      t
    );
  }, [state, t, data]);

  const onChangeName = useCallback(
    (value) => {
      return setState('name', value);
    },
    [setState]
  );

  const handleChangeMetrics = useCallback(
    (value) => {
      if (value === VALUE_METRICS.COUNT) {
        setState('metricField', null);
        setState('summaryExplanation', '');
      }
      setState('metric', value);
    },
    [setState]
  );

  const handleChangeMetricField = useCallback(
    (value) => {
      setState('metricField', value);
    },
    [setState]
  );

  const handleChangeSummaryToggle = useCallback(
    (value) => {
      setState('includeSummary', value);
      setState('summaryExplanation', '');
    },
    [setState]
  );

  const handleChangeSummaryExplanation = useCallback(
    (value) => {
      setState('summaryExplanation', value);
    },
    [setState]
  );

  const isFieldsBreakdown = value?.value === VALUE_RESPONSES.BREAKDOWN;

  useDataConstraint((internalState) => {
    if (active) {
      if (
        (internalState?.value?.value === VALUE_RESPONSES.BREAKDOWN ||
          !canChooseDisplayValue) &&
        display === CHART_TYPES.SUMMARY
      ) {
        return {
          key: 'display',
          value: undefined,
        };
      }
      if (
        internalState?.value?.value !== VALUE_RESPONSES.BREAKDOWN &&
        canChooseDisplayValue &&
        display !== CHART_TYPES.SUMMARY
      ) {
        return {
          key: 'display',
          value: CHART_TYPES.SUMMARY,
        };
      }
    }
  });

  const chartTypeOptions = useMemo(() => {
    if (value?.value === VALUE_RESPONSES.BREAKDOWN) {
      return [CHART_TYPES.DONUT, CHART_TYPES.BAR];
    }

    if (!canChooseDisplayValue) {
      return [CHART_TYPES.DONUT, CHART_TYPES.BAR];
    }

    return [CHART_TYPES.SUMMARY];
  }, [canChooseDisplayValue, value]);

  // TODO (keegandonley) - this is only temporary and when we add more report types there will need
  //  to be more complex decision making here
  const needsDisplay = reportType?.value !== AREA_RESPONSES.SALES;

  return (
    <>
      <Section
        index={index}
        title={title}
        active={active}
        done={done}
        last={last}
      >
        {needsDisplay ? (
          <ChartTypeField
            mobile={mobile}
            top={gutters.spacing(2, 2)}
            only={chartTypeOptions}
          />
        ) : null}
        {display && isFieldsBreakdown && (
          <Metrics
            display={display}
            mobile={mobile}
            objectFields={fieldObject?.fields || []}
            metric={metric}
            metricField={metricField}
            includeSummary={includeSummary}
            summaryExplanation={summaryExplanation}
            onChangeMetrics={handleChangeMetrics}
            onChangeMetricField={handleChangeMetricField}
            onChangeSummaryToggle={handleChangeSummaryToggle}
            onChangeSummaryExplanation={handleChangeSummaryExplanation}
          />
        )}
        {display || !needsDisplay ? (
          <TitleField
            defaultName={defaultName}
            name={name}
            onChangeName={onChangeName}
            top={needsDisplay ? 20 : 12}
            entityName={entityName}
          />
        ) : null}
      </Section>
    </>
  );
};

export default DisplaySettingsWizardSection;
