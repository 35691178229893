import { useTranslation } from 'react-i18next';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import Select from '__components/Inputs/Select';
import Switch from '__components/Kizen/Switch';
import { FieldLike, MappingRule } from '__pages/SmartConnectors/types';
import {
  OnChangeMappingRuleHandler,
  useMappingSettings,
} from './useMappingSettings';
import MultiSelect from '__components/Inputs/MultiSelect';
import { getConflictResolutionByFieldType } from './helpers';
import { useContext, useEffect, useRef } from 'react';
import { PrimaryObjectUploadContext } from '../../context';
import { StyledCols } from '../../styles';
import ClearSelectButton from '__components/Inputs/Select/ClearButton';

type FieldRowProps = {
  index: number;
  field?: FieldLike;
  rule: MappingRule;
  isCreateField?: boolean;
  onChange: OnChangeMappingRuleHandler;
  onAdd: (init: Partial<MappingRule>) => void;
};

export const FieldRow = ({
  index,
  field,
  rule,
  isCreateField = false,
  onChange,
  onAdd,
}: FieldRowProps) => {
  const { t } = useTranslation();

  const { metaData } = useContext(PrimaryObjectUploadContext);

  const { variables, can_create_field_options, conflict_resolution } = rule;

  const {
    ruleError,
    errorKey,
    validate,
    variableOptions,
    conflictResolutionOptions,
    onChangeVariable,
    onChangeVariables,
    onChangeResolution,
    onChangeCreateFieldOption,
    canCreateFieldOption,
    isMulty,
    value,
  } = useMappingSettings({
    rule,
    field,
    onChange,
    index,
  });

  const addRuleOrChangeVariables = (
    values: { value: string } | { value: string }[]
  ) => {
    if (!rule.field || rule.field.startsWith('new_')) {
      onAdd({
        field: field?.id,
        variables: Array.isArray(values)
          ? values.map(({ value }) => value)
          : [values.value],
        conflict_resolution: getConflictResolutionByFieldType(metaData, field)
          .default,
      });
    } else {
      if (Array.isArray(values)) {
        onChangeVariables(values);
      } else {
        onChangeVariable(values);
      }
    }
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorKey) {
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [errorKey]);

  return (
    <StyledCols
      columns={4}
      gutter="20px"
      ref={wrapperRef}
      data-ga-field-id={rule.field}
      data-qa-rule-index={index}
    >
      <TextEllipsisWithTooltip>{`${field?.displayName} (${field?.name})`}</TextEllipsisWithTooltip>

      {isMulty ? (
        <MultiSelect
          value={value}
          onChange={addRuleOrChangeVariables}
          placeholder={t('Choose Variable(s)')}
          options={variableOptions}
          error={ruleError?.variables}
          validate={errorKey === 'variables' ? validate : undefined}
          menuLeftButton={<ClearSelectButton />}
        />
      ) : (
        <Select
          value={value}
          onChange={addRuleOrChangeVariables}
          placeholder={t('Choose Variable')}
          options={variableOptions}
          error={ruleError?.variables}
          validate={errorKey === 'variables' ? validate : undefined}
        />
      )}
      {isCreateField ? (
        <TextEllipsisWithTooltip>{t('N/A')}</TextEllipsisWithTooltip>
      ) : (
        <>
          {variables?.length ? (
            <Select
              value={conflict_resolution}
              onChange={onChangeResolution}
              placeholder={t('Choose Resolution')}
              options={conflictResolutionOptions}
              disabled={variables.length > 1}
              error={ruleError?.conflict_resolution}
              validate={
                errorKey === 'conflict_resolution' ? validate : undefined
              }
            />
          ) : (
            <TextEllipsisWithTooltip>
              {isMulty
                ? t('Choose Variable(s) First')
                : t('Choose Variable First')}
            </TextEllipsisWithTooltip>
          )}
          {variables?.length && canCreateFieldOption ? (
            <Switch
              removeMargin
              checked={can_create_field_options}
              onChange={onChangeCreateFieldOption}
            />
          ) : null}
        </>
      )}
    </StyledCols>
  );
};
