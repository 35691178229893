import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import useFlashState from 'hooks/useFlashState';
import { gutters } from 'app/spacing';
import TextInput from 'components/Inputs/TextInput';
import BasicModal from 'components/Modals/presets/BasicModal';
import { getOriginalError } from 'services/AxiosService';
import ActivityService from 'services/ActivityService';

const StyledTextInput = styled(TextInput)`
  margin-bottom: ${gutters.spacing(4, -3)}px;
`;

const NewActivityModal = ({ onHide, handleCreate, show, ...others }) => {
  const [name, setName] = useState('');
  const history = useHistory();
  const [errorText, setError] = useFlashState(1500, '');

  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      setName('');
    }
  }, [show]);

  const handleClick = async () => {
    try {
      const activity = await ActivityService.v2CreateActivity({
        name: name.trim(),
      });
      history.push(`/activities/${activity.id}`);
      handleCreate();
      setName('');
    } catch (error) {
      const originalError = getOriginalError(error);
      if (originalError && originalError.name) {
        setError(originalError.name[0]);
      }
    }
  };

  return (
    <BasicModal
      onHide={onHide}
      show={show}
      heading={t('Add Activity')}
      disabled={!name.trim()}
      onConfirm={handleClick}
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      {...others}
    >
      <StyledTextInput
        label={t('Activity Name')}
        placeholder={t('Enter Name')}
        value={name}
        onChange={setName}
        validate={
          errorText && {
            message: errorText,
            showMessage: true,
          }
        }
        inModal
      />
    </BasicModal>
  );
};

NewActivityModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  handleCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default NewActivityModal;
