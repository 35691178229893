import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import { useWindowScroll } from 'react-use';
import { getDataQAForInput } from 'components/Inputs/helpers';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import { ControlledMenuWrapper, StyledSelect } from './styles';
import { popperModifiers } from 'components/helpers';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import { Button } from '@kizen/kds/Button';
const nudgePopper = {
  modifiers: [
    popperModifiers.offSet(4, -11),
    popperModifiers.addStyles({ zIndex: 1 }),
  ],
};

export const StartAutomationSelect = ({
  customObjectId,
  onApply,
  onCancel,
  ...rest
}) => {
  const { t } = useTranslation();
  const [selectedAutomation, setSelectedAutomation] = useState(null);

  const selectRef = useRef(null);

  const params = useMemo(
    () => ({
      active: true,
      custom_object_id: customObjectId,
    }),
    [customObjectId]
  );

  const [typeaheadProps, { loading }] = useSelectTypeaheadWithScroll({
    entity: Entities.Automations,
    enabled: !!customObjectId,
    selectRef,
    params,
    alwaysOpen: true,
  });

  const onClickApply = () => {
    onApply(selectedAutomation.value);
  };

  return (
    <StyledSelect
      ref={selectRef}
      sizing="mobile"
      value={selectedAutomation}
      onChange={setSelectedAutomation}
      autoFocus
      placeholder={t('Select Option')}
      {...rest}
      {...typeaheadProps}
      loadItems={loading}
      isLoading={loading}
      menuLeftButton={
        <ClearSelectButton onClick={onCancel}>{t('cancel')}</ClearSelectButton>
      }
      menuRightButton={
        <ApplySelectButton
          disabled={!selectedAutomation}
          onClick={onClickApply}
        >
          {t('start')}
        </ApplySelectButton>
      }
      onFocus={(ev) => {
        ev.nativeEvent.stopImmediatePropagation();
      }}
    />
  );
};

export const StartAutomationButton = ({
  alignRight,
  onStartAutomation,
  customObjectId,
  ...rest
}) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const titleRef = useRef(null);
  const { y } = useWindowScroll();

  // close the menu on scroll
  useEffect(() => {
    setVisible(false);
    // setSelectedAutomation(null);
  }, [y]);

  const handleApply = useCallback(
    (selectedAutomation) => {
      setVisible(false);
      onStartAutomation(selectedAutomation);
    },
    [onStartAutomation]
  );

  const handleCancel = useCallback(() => {
    // setSelectedAutomation(null);
    setVisible(false);
  }, []);

  return (
    <ControlledMenuWrapper {...rest}>
      <Button
        onClick={() => setVisible(true)}
        ref={titleRef}
        variant="text"
        color="primary"
        data-qa="start-automation"
        leftIcon="action-refresh"
        alwaysShowDropdown
        qa={{
          action: 'start-automation',
        }}
      >
        {t('Start Automation')}
      </Button>
      <SelectOverlay
        show={visible}
        target={titleRef.current}
        onHide={() => {
          setVisible(false);
          // setSelectedAutomation(null);
        }}
        placement="bottom-end"
        popperConfig={nudgePopper}
        {...getDataQAForInput('start-automation-select', 'dropdown')}
      >
        <StartAutomationSelect
          customObjectId={customObjectId}
          onApply={handleApply}
          onCancel={handleCancel}
          classNamePrefix="automation-select"
        />
      </SelectOverlay>
    </ControlledMenuWrapper>
  );
};
