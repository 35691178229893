import Loader from 'components/Kizen/Loader';
import { AREA_RESPONSES } from 'components/Wizards/Dashlet/types';
import useDynamicFilterObject from '../useDynamicFilterObject';

import { DynamicObjectMetaFilter } from '../DynamicObjectMetaFilter';
import { WizardFilterContext } from 'components/Wizards/MetaFilters/context';

const disallowedResetSelectedObjects = [AREA_RESPONSES.CUSTOM_OBJECT];

const DynamicObjectFilter = ({
  additionalFields,
  area,
  selectedObject,
  filterState,
  customObject,
  onChangeSelectedObject,
  hideWarnings,
  onConfirm,
  isForCustomObject,
  hideGroups,
  clientObject,
  defaultCustomFilter,
  intitialRenderRef,
}) => {
  const { filterProps, onClear, filtersAreEmpty, onFilterConfirm } =
    useDynamicFilterObject({
      isForCustomObject,
      area,
      selectedObject,
      customObject,
      filterState,
      onChangeSelectedObject,
      onConfirm,
      allowResetSelectedObject: !disallowedResetSelectedObjects.includes(area),
      clientObject,
    });

  if (filterProps.loading) {
    return <Loader loading />;
  }

  return (
    <WizardFilterContext
      filterProps={filterProps}
      isClient={!isForCustomObject}
      objectId={customObject?.id}
      clientObject={clientObject}
    >
      <DynamicObjectMetaFilter
        onClear={onClear}
        filtersAreEmpty={filtersAreEmpty}
        onFilterConfirm={onFilterConfirm}
        additionalFields={additionalFields}
        hideWarnings={hideWarnings}
        hideGroups={hideGroups}
        area={area}
        selectedObject={selectedObject}
        defaultCustomFilter={defaultCustomFilter}
        intitialRenderRef={intitialRenderRef}
      />
    </WizardFilterContext>
  );
};

export default DynamicObjectFilter;
