import { isEqual } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { sanitizeTextForCharts } from '../ChartManager';
import NoData from '../components/NoData';
import { StyledNoData } from '../components/NoData/styles';
import { getPendingDefaultValue } from '../helpers';
import { ChartLayout } from '../Trend/styles';
import BarChartManager, { OTHER_CATEGORY_NAME } from './BarChartManager';
import { Chart, ChartOverlay, StyledLoader } from './styles';

const BarChart = ({
  id,
  isLoading,
  data = [],
  mobile,
  yAxisTitle,
  xAxisTitle,
  currencySymbol,
  currencyMode,
  skipSorting = false,
  showXAxisLabel = true,
  getLabel,
  breakdownCurrencySymbol = '',
  forceDecimal = false,
}) => {
  const chartRef = useRef();
  const leftOverlayRef = useRef(null);
  const rightOverlayRef = useRef(null);
  const enabledBy = useRef('initial');
  const tooltipsVisible = useRef(false);
  const scrollPercent = useRef(0);

  const { t } = useTranslation();
  const [chartRenderKey, setChartRenderKey] = useState(() => Date.now());
  const [chartPending, setChartPending] = useState(getPendingDefaultValue);
  const [chartRendered, setChartRendered] = useState(false);

  const { other, rest } = useMemo(() => {
    const sorted = skipSorting ? data : data.sort((a, b) => b.value - a.value);

    return sorted.reduce(
      (acc, curr) => {
        if (
          curr.id === OTHER_CATEGORY_NAME ||
          curr.category === OTHER_CATEGORY_NAME
        ) {
          acc.other.push(curr);
        } else {
          acc.rest.push(curr);
        }

        return acc;
      },
      { other: [], rest: [] }
    );
  }, [data, skipSorting]);

  const makeLeftFadeVisible = useCallback(() => {
    if (leftOverlayRef.current) {
      leftOverlayRef.current.style.visibility = 'visible';
      leftOverlayRef.current.style.opacity = 1;
    }
  }, []);

  const makeRightFadeVisible = useCallback(() => {
    if (rightOverlayRef.current) {
      rightOverlayRef.current.style.visibility = 'visible';
      rightOverlayRef.current.style.opacity = 1;
    }
  }, []);

  const makeLeftFadeHidden = useCallback(() => {
    if (leftOverlayRef.current) {
      leftOverlayRef.current.style.visibility = 'hidden';
      leftOverlayRef.current.style.opacity = 0;
    }
  }, []);

  const makeRightFadeHidden = useCallback(() => {
    if (rightOverlayRef.current) {
      rightOverlayRef.current.style.visibility = 'hidden';
      rightOverlayRef.current.style.opacity = 0;
    }
  }, []);

  const handleComputeVisibility = useCallback(() => {
    const tooltipsActive = tooltipsVisible.current;
    const scrollPosition = scrollPercent.current;

    if (tooltipsActive) {
      makeLeftFadeHidden();
      makeRightFadeHidden();
    } else {
      if (scrollPosition === 0) {
        makeLeftFadeHidden();
      } else {
        makeLeftFadeVisible();
      }

      if (scrollPosition === 1) {
        makeRightFadeHidden();
      } else if (data.length > 7) {
        makeRightFadeVisible();
      }
    }
  }, [
    makeLeftFadeHidden,
    makeRightFadeHidden,
    makeLeftFadeVisible,
    data.length,
    makeRightFadeVisible,
  ]);

  // Setting state outside the chart based on chart interaction causes problems with the
  // amcharts rendering mechanism. Since we only want to update a few styles based on the scrolling
  // state, we just update them directly on the fade container
  const handleScrollWidth = useCallback(
    (percent) => {
      scrollPercent.current = percent;
      handleComputeVisibility();
    },
    [handleComputeVisibility]
  );

  // Setting state outside the chart based on chart interaction causes problems with the
  // amcharts rendering mechanism. Since we only want to update a few styles based on the
  // width of the plot, we just update them directly on the fade container
  const handleChartWidth = useCallback((width) => {
    if (leftOverlayRef.current) {
      leftOverlayRef.current.style.width = `${width + 1}px`;
    }
    if (rightOverlayRef.current) {
      rightOverlayRef.current.style.width = `${width + 1}px`;
    }
  }, []);

  // Because the fade is outside the chart canvas, we need to hide it when tooltips are visible
  // on the chart, otherwise they'll appear behind it
  const handleTooltipVisibility = useCallback(
    (visible, visibilityId) => {
      if (visible) {
        tooltipsVisible.current = true;
        enabledBy.current = visibilityId;
      } else if (enabledBy.current === visibilityId) {
        tooltipsVisible.current = false;
      }
      handleComputeVisibility();
    },
    [handleComputeVisibility]
  );

  const sanitizedData = useMemo(() => {
    const data = [...rest, ...other];
    return data.map((d) => {
      return {
        ...d,
        category: sanitizeTextForCharts(d.category),
        label: sanitizeTextForCharts(d.label),
      };
    });
  }, [rest, other]);

  useEffect(() => {
    if (chartRef.current) {
      const manager = new BarChartManager(
        chartRef.current,
        t,
        sanitizedData,
        {
          mobile,
          yAxisTitle: sanitizeTextForCharts(yAxisTitle),
          xAxisTitle: sanitizeTextForCharts(xAxisTitle),
          currencySymbol: currencySymbol || breakdownCurrencySymbol,
          currencyMode,
          showXAxisLabel,
          getLabel,
          isValue: Boolean(breakdownCurrencySymbol),
          forceDecimal,
        },
        {},
        () => {
          setChartRendered(true);
          setChartPending(false);
          handleComputeVisibility();
        }
      );

      manager.init(
        handleScrollWidth,
        handleChartWidth,
        handleTooltipVisibility
      );

      return manager.destroy;
    }
  }, [
    chartRenderKey,
    t,
    mobile,
    sanitizedData,
    handleScrollWidth,
    handleChartWidth,
    handleTooltipVisibility,
    yAxisTitle,
    xAxisTitle,
    handleComputeVisibility,
    currencySymbol,
    currencyMode,
    showXAxisLabel,
    getLabel,
    breakdownCurrencySymbol,
    forceDecimal,
  ]);

  const createChart = useCallback((elem) => {
    if (elem) {
      chartRef.current = elem;
      setChartRenderKey(Date.now());
    }
  }, []);

  return (
    <ChartLayout>
      {/*
          This absolutely positioned loader is used to keep the chart area hidden until the calculations
          and rendering are done, as they can be quite slow for large datasets and will just
          show a blank white dashlet otherwise
        */}
      <StyledLoader loading={chartPending || isLoading} />
      {data.length === 0 ? (
        <StyledNoData>
          <NoData data-qa-dashlet-id={id} data-qa-no-data />
        </StyledNoData>
      ) : null}
      <Chart
        data-qa-chart="bar"
        data-qa-chart-pending={chartPending || isLoading || !chartRendered}
        ref={createChart}
      />
      <ChartOverlay ref={leftOverlayRef} noBorder short={!showXAxisLabel} />
      <ChartOverlay
        ref={rightOverlayRef}
        flip
        style={data.length > 7 ? { visibility: 'visible', opacity: 1 } : {}}
        noBorder
        short={!showXAxisLabel}
      />
    </ChartLayout>
  );
};

// getLabel is intentionally not included in the memoization check
// because it should not change between renders
const propsAreEqual = (prevProps, nextProps) => {
  const dataEqual = isEqual(prevProps.data, nextProps.data);
  const dashletEqual = isEqual(prevProps.dashlet, nextProps.dashlet);
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const mobileEqual = prevProps.mobile === nextProps.mobile;
  const isCurrencyModeEqual = prevProps.currencyMode === nextProps.currencyMode;
  const currencySymbolEqual =
    prevProps.currencySymbol === nextProps.currencySymbol;
  const yAxisTitleEqual = prevProps.yAxisTitle === nextProps.yAxisTitle;
  const breakdownCurrencySymbolEqual =
    prevProps.breakdownCurrencySymbol === nextProps.breakdownCurrencySymbol;
  const forceDecimalEqual = prevProps.forceDecimal === nextProps.forceDecimal;

  return (
    dataEqual &&
    dashletEqual &&
    isLoadingEqual &&
    mobileEqual &&
    isCurrencyModeEqual &&
    currencySymbolEqual &&
    yAxisTitleEqual &&
    breakdownCurrencySymbolEqual &&
    forceDecimalEqual
  );
};

export default React.memo(BarChart, propsAreEqual);
