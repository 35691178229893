import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from 'app/colors';
import { TextSpan, fontWeights } from 'app/typography';
import Input from 'components/Kizen/Input';
import {
  Slider,
  SliderInput,
  SliderHandle,
  SliderMarker,
  SliderTrack,
  SliderTrackHighlight,
} from '@reach/slider';
import '@reach/slider/styles.css';
import { StyledLabelWrapper } from '../Input/styles';

export const StyledSliderWrapper = styled(StyledLabelWrapper)({
  display: 'flex',
  justifyContent: 'center',
  '[data-reach-slider-track][data-orientation="horizontal"]': {
    backgroundColor: grayScale.mediumLight,
    height: 6,
  },
  pointerEvents: 'none',
});

export const StyledSlider = styled(Slider)`
  &[data-reach-slider-input][data-orientation='horizontal'] {
    display: flex;
    align-self: center;
    height: 6px;
    padding: 0px;
    margin: 30px 0;
    max-width: 275px;
  }
  & [data-reach-slider-handle] {
    width: 14px;
    height: 14px;
    background: ${grayScale.white};
    border: 1px solid ${colorsButton.blue.default};
    &:focus {
      outline: none;
    }
  }
  & [data-reach-slider-marker] {
    font-size: 14px;
    border: none;
    width: 6px;
    height: 6px;
    background-color: ${colorsButton.blue.default};
    > span {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  & [data-reach-slider-track-highlight] {
    background-color: ${colorsButton.blue.default};
  }

  ${(props) => css`
    [data-reach-slider-marker][data-state='under-value'] {
      background-color: ${props.value > props.min
        ? colorsButton.blue.default
        : grayScale.mediumLight};
      & > span {
        left: -20px;
      }
    }
    [data-reach-slider-marker][data-value='${props.min
        ? props.min.toString()
        : 0}'] {
      & > span {
        left: -20px;
      }
    }
    [data-reach-slider-marker][data-state='over-value'] {
      background-color: ${grayScale.mediumLight};
      left: auto;
      right: 0;
      & > span {
        left: 20px;
      }
    }
    [data-reach-slider-marker][data-value='${props.max
        ? props.max.toString()
        : 100}'] {
      left: auto;
      right: 0;
      & > span {
        right: 20px;
        left: 20px;
      }
    }
  `}
`;

export const StyledSliderInput = styled(SliderInput)`
  &[data-reach-slider-input][data-orientation='horizontal'] {
    display: flex;
    align-self: center;
    height: 6px;
    padding: 0px;
    margin: 30px 0;
    margin-left: -25px;
    margin-bottom: calc(14px - 6px - 4px - 8px);
    max-width: 275px;
    pointer-events: all;
  }
  & [data-reach-slider-handle] {
    width: 14px;
    height: 14px;
    background: ${grayScale.white};
    border: 1px solid ${colorsButton.blue.default};
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    &:focus {
      outline: none;
    }
  }
  & [data-reach-slider-marker] {
    font-size: 14px;
    border: none;
    width: 6px;
    height: 6px;
    background-color: ${colorsButton.blue.default};
    > span {
      font-size: 11px;
      font-weight: ${fontWeights.bold};
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  & [data-reach-slider-track-highlight] {
    background-color: ${colorsButton.blue.default};
    pointer-events: all;
  }

  ${(props) => css`
    [data-reach-slider-marker][data-state='under-value'] {
      background-color: ${props.value > props.min
        ? colorsButton.blue.default
        : grayScale.mediumLight};
      & > span {
        left: -20px;
      }
    }
    [data-reach-slider-marker][data-value='${props.min
        ? props.min.toString()
        : 0}'] {
      & > span {
        left: -20px;
      }
    }
    [data-reach-slider-marker][data-state='over-value'] {
      background-color: ${grayScale.mediumLight};
      left: auto;
      right: 0;
      & > span {
        left: 20px;
      }
    }
    [data-reach-slider-marker][data-state='over-value'][data-value='${props.value ===
      props.min
        ? props.value?.toString()
        : props.value?.toString()}'] {
      & > span {
        left: -20px;
      }
    }
    [data-reach-slider-marker][data-value='${props.max
        ? props.max.toString()
        : 100}'] {
      left: auto;
      right: 0;
      & > span {
        right: 20px;
        left: 20px;
      }
    }
  `}
`;

export const StyledHandleTooltip = styled(Input)({
  width: 60,
  padding: 0,
  textAlign: 'center',
  transform: 'translateY(-100%)',
  boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.03)',
  '& label': {
    margin: 0,
  },
  '& .input-wrapper': {
    margin: 0,
  },
  '& input': {
    width: 60,
    height: 'auto',
    padding: '5px 10px',
    textAlign: 'center',
    fontSize: 11,
    fontWeight: fontWeights.bold,
    pointerEvents: 'all',
    '&:focus': {
      borderColor: grayScale.medium,
    },
  },
  '& input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none !important',
    margin: 0,
  },
  '& input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none !important',
    margin: 0,
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
});

export const StyledSliderHandle = styled(SliderHandle)({});

export const StyledSliderTrack = styled(SliderTrack)({});

export const StyledSliderMarker = styled(SliderMarker)({});

export const StyledSliderTrackHighlight = styled(SliderTrackHighlight)({});

export const StyledLabel = styled(TextSpan)({
  marginBottom: 12,
});
