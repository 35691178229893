import React, { useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import WizardStateContext from '../context/wizardstate';
import { AREA_RESPONSES } from 'components/Wizards/RTDV/types';

const disallowedReportTypes = [AREA_RESPONSES.PIVOT_TABLE];

const BottomScrollPosition = ({
  canAutoScroll,
  modalBody,
  setCanAutoScroll,
}) => {
  const bottomScrollerRef = useRef(null);
  const { currentStep, state } = useContext(WizardStateContext.Context);

  const disallow = disallowedReportTypes.includes(state.reportType?.value);

  useEffect(() => {
    if (canAutoScroll && !disallow) {
      bottomScrollerRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
    }
  }, [currentStep, state, canAutoScroll, disallow]);

  useEffect(() => {
    if (modalBody.current?.scrollHeight === modalBody.current?.clientHeight) {
      setCanAutoScroll(true);
    }
  }, [currentStep, state, modalBody, setCanAutoScroll]);

  return <div ref={bottomScrollerRef} />;
};

BottomScrollPosition.propTypes = {
  canAutoScroll: PropTypes.bool,
  modalBody: PropTypes.object,
  setCanAutoScroll: PropTypes.func.isRequired,
};

BottomScrollPosition.defaultProps = {
  canAutoScroll: false,
  modalBody: null,
};

export default BottomScrollPosition;
