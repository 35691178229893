import { useMemo } from 'react';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useTranslation } from 'react-i18next';
import { gutters } from 'app/spacing';
import Select from 'components/Inputs/Select';
import { useQuery } from 'react-query';
import { DASHBOARD } from 'queries/query-keys';
import CustomObjectsService from 'services/CustomObjectsService';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import { ShortLabel } from './styles';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const ObjectSelector = ({
  value,
  onChange,
  label,
  disabled,
  defaultOnActivities,
  menuInline,
}) => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    [
      ...DASHBOARD.CUSTOM_OBJECT_LIST,
      { defaultOnActivities, customOnly: true },
    ],
    () =>
      CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
        defaultOnActivities,
        customOnly: true,
      })
  );

  const access = useSelector((s) => s.authentication.access);
  const canViewContacts = access.sections.contacts_section.view;

  const clientObject = useSelector(getBusinessClientObject);

  const options = useMemo(() => {
    const objectAccess = access.custom_objects?.custom_object_entities ?? {};

    const objects =
      data?.results
        ?.filter((o) => {
          return objectAccess[o.id]?.enabled;
        })
        .map(({ id, objectName }) => ({
          value: id,
          label: objectName,
        })) ?? [];

    if (canViewContacts) {
      return [
        {
          value: clientObject.id,
          label: clientObject.objectName,
        },
        ...objects,
      ];
    }
    return objects;
  }, [data, clientObject, access, canViewContacts]);

  const loadableValue = useMemo(() => {
    return value
      ? {
          value: value.id,
          label:
            value.objectName ??
            (clientObject.id === value.id
              ? clientObject.objectName
              : data?.results?.find((o) => o.id === value.id)?.objectName),
        }
      : null;
  }, [value, data, clientObject]);

  return (
    <StyledWizardField
      top={gutters.spacing(0)}
      flex
      data-qa-objects-loading={isLoading}
    >
      {label ?? <ShortLabel>{t('Select Object to Filter On')}</ShortLabel>}
      <StyledWizardField top={0} flex>
        <Select
          menuInline={menuInline}
          value={loadableValue}
          onChange={onChange}
          captureMenuScroll={false}
          className="modal-select object-to-filter"
          placeholder={t('Choose Object')}
          options={options}
          fullWidth
          isLoading={isLoading}
          classNamePrefix="filter-object-select"
          disabled={disabled}
        />
      </StyledWizardField>
      <StyledWizardField top={gutters.spacing(4)} />
    </StyledWizardField>
  );
};

export default ObjectSelector;
