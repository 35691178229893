import { useCallback, useEffect } from 'react';

let mjmlbrowser = null;

const loadMjmlBrowser = async () => {
  if (mjmlbrowser) {
    return mjmlbrowser;
  }

  mjmlbrowser = import('mjml-browser').catch(() => {
    mjmlbrowser = null;
    loadMjmlBrowser();
  });

  return mjmlbrowser;
};

const useLoadMjmlBrowser = () => {
  useEffect(() => {
    if (!mjmlbrowser) {
      loadMjmlBrowser();
    }
  }, []);
};

export const useMjml2Html = () => {
  useLoadMjmlBrowser();

  return useCallback(async (mjml, options) => {
    const mod = await loadMjmlBrowser();
    return mod.default(mjml, options);
  }, []);
};
