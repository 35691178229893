import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import { FIELD_TYPES } from 'utility/constants';
import { getIdentifierForOption } from '../helpers';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { applyRef } from 'components/Inputs/props';

const Dropdown = forwardRef(
  (
    { field, menuLeftButton, onChange, fieldId, isGetFullObject, ...others },
    ref
  ) => {
    const { t } = useTranslation();
    const { fieldType } = field;
    const { disabled, value, variant } = others;
    const isColored =
      fieldType === FIELD_TYPES.Status.type &&
      !(variant === 'underline' && disabled && value === null);

    const { assignFieldHandle } = useKeyBoardContext();

    const options = useMemo(() => {
      return field.options.map((opt) => ({
        value: getIdentifierForOption(opt, field),
        label: opt.name,
        color: opt.meta && opt.meta.color,
      }));
    }, [field]);

    const handleChange = useCallback(
      (opt) => {
        if (opt) {
          onChange(isGetFullObject ? opt : opt.value);
        } else {
          onChange(null);
        }
      },
      [isGetFullObject, onChange]
    );

    const selectRef = useRef();

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, others);

    assignFieldHandle(fieldId, {
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      },
      customEscape: () => {
        setMenuIsOpen(false);
      },
      customUp: () => {
        setMenuIsOpen(true);
      },
      customDown: () => {
        setMenuIsOpen(true);
      },
      disabled,
    });

    const mergeRef = useCallback(
      (el) => {
        applyRef(selectRef, el);
        applyRef(ref, el);
      },
      [ref]
    );

    return (
      <Select
        ref={mergeRef}
        placeholder={
          fieldType === FIELD_TYPES.Status.type
            ? FIELD_TYPES.Status.localizedPlaceholder(t)
            : FIELD_TYPES.Dropdown.localizedPlaceholder(t)
        }
        options={options}
        isColored={isColored}
        onChange={handleChange}
        menuLeftButton={menuLeftButton}
        menuRightButton={<ApplySelectButton />}
        {...others}
        {...customSelectMenuProps}
      />
    );
  }
);

Dropdown.propTypes = {
  field: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  menuLeftButton: PropTypes.bool,
};

Dropdown.defaultProps = {
  menuLeftButton: <ClearSelectButton />,
};

export default Dropdown;
