import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EditableWrapper } from './styles';
import {
  getIsDeletable,
  getTranslatedAccessStats,
} from 'components/AccessRequests/utils';

const SharingSettingsCell = ({ data, access, onClickEdit, hideRoles = [] }) => {
  const { t } = useTranslation();

  const editable = useMemo(() => getIsDeletable(access), [access]);

  const content = useMemo(
    () => getTranslatedAccessStats(data, t, hideRoles),
    [hideRoles, t, data]
  );

  return (
    <>
      <TextEllipsisWithTooltip>{content}</TextEllipsisWithTooltip>
      {editable ? (
        <EditableWrapper>
          <IconButton
            sizing="dense"
            color={colorsButton.iconGray}
            onClick={onClickEdit}
            title={t('Edit Sharing Settings')}
          >
            <Icon className="permission-edit-icon" icon="pen" />
          </IconButton>
        </EditableWrapper>
      ) : null}
    </>
  );
};

export default SharingSettingsCell;
