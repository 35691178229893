import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import { TimelineErrorWrapper } from '../styles';

const UnknownTypeBlock = ({ event }) => {
  return (
    <TimelineErrorWrapper isDisplayingComments={false} data-qa={event.typeName}>
      <Header
        icon={null}
        isEmpty={true}
        timestamp={event.created}
        onClickDetails={() => {}}
        isUnknown
      >
        {`New event coming soon: ${event?.typeDisplayName}`}
      </Header>
    </TimelineErrorWrapper>
  );
};

UnknownTypeBlock.propTypes = {
  event: PropTypes.shape({
    data: PropTypes.shape({
      automationName: PropTypes.string,
    }),
    created: PropTypes.string,
    comments: PropTypes.any,
  }).isRequired,
};

export default UnknownTypeBlock;

// export const getAutomationPausedEventBlock = (event) => {};
