import styled from '@emotion/styled';

import Card from 'components/Card/Card';

import { gutters } from 'app/spacing';
import css from '@emotion/css';

export const StyledCard = styled(Card)`
  padding: ${gutters.spacing(5)}px 0
    ${({ dashboard }) => (dashboard ? 18 : gutters.spacing(5, 2))}px 0;
  margin: ${gutters.spacing(4)}px 0 0 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  && .not_found_long-text {
    max-width: 382px;
    margin-bottom: ${gutters.spacing(4, 2)}px;
    text-align: center;
  }
  && h1 {
    margin-top: 2px; //  Design nudge
    margin-bottom: ${gutters.spacing(4, 2)}px;
    line-height: 1;
  }
  && .not_found_button {
    margin-bottom: ${gutters.spacing(5, 4)}px;
  }
  && .support_text {
    margin-bottom: ${gutters.spacing(2, -2)}px;
  }
  && .link_button {
    margin-bottom: ${gutters.spacing(5, 4)}px;
  }

  ${({ dashboard, code }) =>
    dashboard
      ? css`
          & button {
            margin-top: 28px;
            margin-bottom: ${code ? '11px' : '1px'};
          }
        `
      : ''}
`;
