import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FIELD_TYPES } from 'utility/constants';

import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import Select from 'components/Inputs/Select';
import TimezoneInline from 'components/Fields/InlineTableFieldInput/Timezone';
import { withCellWrapper } from './helpers';

// this is untested as kzn-7195 focuses on custom objects which don't feature timezones
const _TimezoneCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <TimezoneInline
        ref={ref}
        {...others}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        field={field}
        object={object}
      >
        <Select
          placeholder={FIELD_TYPES.Timezone.localizedPlaceholder(t)}
          menuLeftButton={field.isRequired ? null : menuLeftButton}
          menuRightButton={
            menuRightButton === undefined ? (
              <ApplySelectOverlayButton />
            ) : (
              menuRightButton
            )
          }
        />
      </TimezoneInline>
    );
  }
);

export const TimezoneCell = withCellWrapper(_TimezoneCell);
