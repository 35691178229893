import PropTypes from 'prop-types';

export const optionProp = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

export const groupableOptionProp = PropTypes.oneOfType([
  optionProp,
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(optionProp),
  }),
]);

export const selectedValueProp = PropTypes.oneOfType([
  optionProp,
  PropTypes.string,
]);

export const variantProp = PropTypes.oneOf(['outline', 'underline']);

export const validateProp = PropTypes.shape({
  full: PropTypes.func,
  character: PropTypes.func,
  // These two below are meant to control validation UI on TextInput.
  // They are compatible with useFlashTransition()'s output for
  // consistent validation animations.
  message: PropTypes.string,
  showMessage: PropTypes.bool,
});

export const applyRef = (ref, el) => {
  if (ref) {
    if (typeof ref === 'function') {
      ref(el);
    } else {
      ref.current = el;
    }
  }
  return ref;
};
