import { useEffect, useContext } from 'react';
import WizardStateContext from '../../shared/context/wizardstate';

/*
 * A hook for setting default values in the state, if they don't already exist
 *
 * @param {Object} defaults - An mapping of keys to their respective values that will be put
 * into the state if they don't already exist
 *
 * @param {Boolean} active - Whether the default should be actively applied or not
 */
const useDefaultValues = (defaults, active) => {
  const { state, setState } = useContext(WizardStateContext.Context);

  useEffect(() => {
    if (active) {
      const defaultKeys = Object.keys(defaults ?? {});
      defaultKeys.forEach((key) => {
        const defaultValue = defaults[key];
        if (
          typeof state?.[key] === 'undefined' ||
          (state?.[key] &&
            typeof state?.[key] === 'object' &&
            typeof state[key].value === 'undefined')
        ) {
          setState(key, defaultValue);
        }
      });
    }
  }, [defaults, active, state, setState]);
};

export default useDefaultValues;
