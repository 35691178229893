import { omit } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from 'react-use/lib/useDebounce';
import { useQueryClient } from 'react-query';
import { useInfinityFetch } from './useInfinityFetch';
import { DROPDOWN_SHOULD_LOAD } from '../utility/constants';
import { components } from 'react-select';
import { ClipLoader } from 'react-spinners';
import { grayScale } from '../app/colors';
import styled from '@emotion/styled';
import { useInfinityScroll } from './useInfinityScroll';
import { useViewerContext } from '../../viewer';
import { RELATION_FIELD, TEAM_MEMBERS } from '../../queries/query-keys';

export const Entities = {
  RelatedObjects: 'relatedObjects',
  TeamMember: 'teamMember',
};
const namedToOption = ({ id, name }) => ({ value: id, label: name });
const LOADER_SIZE = 15;

const LoadingMessageWrapper = styled.div`
  font-size: 1.4em;
  color: ${grayScale.dark};
`;

const LoadingMessage = forwardRef(({ children, ...others }, ref) => {
  return (
    <components.LoadingMessage {...others} ref={ref}>
      <LoadingMessageWrapper>{children}</LoadingMessageWrapper>
    </components.LoadingMessage>
  );
});

const IndicatorsContainer = forwardRef(({ children, ...others }, ref) => {
  const { isLoading } = others.selectProps;

  return (
    <components.IndicatorsContainer {...others} ref={ref}>
      {isLoading ? (
        <div data-qa-icon-name="clip-loader">
          <ClipLoader loading size={LOADER_SIZE} color={grayScale.mediumDark} />
        </div>
      ) : (
        children
      )}
    </components.IndicatorsContainer>
  );
});

export function useSelectTypeaheadWithScroll({
  fetch,
  entity = Entities.RelatedObjects,
  config = {},
  objectToOption = namedToOption, // Translates full entity objects to options format where value is usually an entity id
  onLoaded = null,
  onFocus,
  onMenuOpen,
  onMenuClose,
  selectRef,
  fieldId,
  chosenValueIds = [],
  alwaysOpen = false,
  onResultsChange,
  filterOption,
  params,
  keepPreviousData = true,
  isViewerCtx,
}) {
  const {
    allowEmpty = true,
    stayOpenOnBlur = false,
    clearSearchOnBlur = true,
    preserveSearch = false,
  } = config;
  const { businessId, publicTeamMemberService } = useViewerContext();
  const queryParams = omit(params, ['page_size', 'ordering']);
  const [search, setSearch] = useState('');
  const [force, setForce] = useState(0);
  const [wrapperElement, setWrapperElement] = useState(null);
  const updatedOnce = useRef(false);
  const { t } = useTranslation();
  const [debouncedValue, setDebouncedValue] = useState('');
  const entityQueryInfo = useMemo(() => {
    if (entity === Entities.TeamMember) {
      return {
        queryKey: [...TEAM_MEMBERS.LIST, 'typeahead'],
        fetch: async (args) => {
          const { data } = await publicTeamMemberService.getPublicTeamMembers(
            businessId,
            {
              ordering: 'first_name',
              page_size: 20,
              ...args,
            }
          );
          return data;
        },
      };
    }
    if (entity === Entities.RelatedObjects) {
      return {
        queryKey: RELATION_FIELD.RELATED_OBJECTS(fieldId),
        fetch,
      };
    }
    return {};
  }, [entity, fetch, fieldId, businessId, publicTeamMemberService]);

  useDebounce(
    () => {
      setDebouncedValue(search);
    },
    300,
    [search]
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfinityFetch({
    enabled: Boolean(wrapperElement) && isViewerCtx,
    onLoaded,
    params: {
      ...queryParams,
      search: debouncedValue,
    },
    ...entityQueryInfo,
    keepPreviousData,
  });

  const { visibleItems: options, handleItemMounted } = useInfinityScroll({
    wrapperElement,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    objectToOption,
    chosenItems: chosenValueIds,
    defaultHeight: 32,
    itemsBuffer: 10,
  });

  // https://tanstack.com/query/v4/docs/react/guides/infinite-queries#what-if-i-want-to-manually-update-the-infinite-query
  const queryKey = useMemo(() => {
    return [
      ...entityQueryInfo.queryKey,
      {
        ...queryParams,
        search: debouncedValue,
      },
    ];
  }, [entityQueryInfo.queryKey, queryParams, debouncedValue]);

  const queryClient = useQueryClient();
  const resetOptions = useCallback(() => {
    if (options.length) {
      // we clear all accept 1st page on unmount
      queryClient.setQueryData(queryKey, (data) => ({
        pages: data.pages.slice(0, 1),
        pageParams: data.pageParams.slice(0, 1),
      }));
    }
  }, [queryClient, queryKey, options]);

  useEffect(() => {
    onResultsChange?.();
  }, [options, onResultsChange]);

  useDebounce(
    () => {
      // Flipped to true on first render, prevents searching on mount
      updatedOnce.current = false;
    },
    300,
    [search, force, alwaysOpen]
  );

  // Provide default action so consumer can call manually without specifying
  // e.g. to clear the input. action is an implementation detail of react-select
  const handleSearchChange = (newSearch, { action } = {}) => {
    if (entity === Entities.TeamMember) {
      // We don't want to clear the search when the user selects a value
      if (action === 'set-value' && preserveSearch) {
        return;
      }
      setSearch(newSearch);
    } else if (entity === Entities.RelatedObjects) {
      if (action !== 'input-blur' && action !== 'menu-close') {
        setSearch(newSearch);
      }
      if (action === 'input-blur') {
        if (clearSearchOnBlur) {
          // clear the search text on blur so the prevoius value shows
          setSearch('');
        }
      }
    }
  };

  const handleFocus = (...data) => {
    if (!(alwaysOpen || (stayOpenOnBlur && options !== DROPDOWN_SHOULD_LOAD))) {
      // force fetching of options, even if search hasn't changed,
      // skip if alwaysOpen or stayOpenOnBlur and no options, so loads on first focus only
      // to counteract resetting the options on blur (see handleSearchChange)
      setForce((f) => f + 1);
      onFocus?.(...data);
    }
  };

  const handleForceSearch = () => {
    setSearch('');
  };

  const handleMenuOpen = () => {
    onMenuOpen?.();
    // any suggestion to get it in a better way? setTimeout -I do not like it here too
    setTimeout(() => {
      setWrapperElement(selectRef.current?.select?.menuListRef);
    });
  };

  const handleMenuClose = () => {
    setWrapperElement(null);
    onMenuClose?.();
    resetOptions();
  };

  if (alwaysOpen) {
    // any suggestion to get it in a better way? setTimeout -I do not like it here too
    setTimeout(() => {
      setWrapperElement(selectRef.current?.select?.menuListRef);
    });
  }

  const getSelectProps = () => {
    let props = {
      inputValue: search,
      onInputChange: handleSearchChange,
      options: options.map(({ item }) => item),
      filterOption: filterOption || (() => true),
      onMountOption: handleItemMounted,
      onMenuOpen: handleMenuOpen,
      onMenuClose: handleMenuClose,
    };

    if (entity === Entities.RelatedObjects) {
      props = {
        ...props,
        onFocus: handleFocus,
        noOptionsMessage: () => {
          if (isLoading && !options.length) {
            return t('Loading Options');
          }
          return t('No Options');
        },
        components: {
          IndicatorsContainer,
        },
      };
    } else if (entity === Entities.TeamMember) {
      props = {
        ...props,
        noOptionsMessage: () => {
          if (!search && !allowEmpty) {
            return t('Begin Typing to Search');
          }
          return t('No Options');
        },
        components: {
          LoadingMessage,
          LoadingIndicator: null,
          IndicatorsContainer,
        },
        classNamePrefix: 'team-member-typeahead',
        menuTopButton: null,
        isLoading: isFetching && selectRef?.current?.state.menuIsOpen,
      };
    }

    return props;
  };

  return [
    getSelectProps(),
    {
      loading: isFetching && selectRef?.current?.state.menuIsOpen,
      onOptionsReady: Array.isArray(options) && !isLoading,
    },
    {
      forceSearch: handleForceSearch,
      queryKey,
      refetch,
    },
  ];
}
