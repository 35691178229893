import type { FilterSetData } from '@kizen/filters/hooks/use-filter-sets';
import { useMemo } from 'react';

export type ErrorsReturned = {
  timestamp: string | null;
  parsedErrors: boolean[][];
};

const isValueInError = (v: any) => {
  if (v !== null && typeof v === 'object') {
    return v.error || v.not_found || v.not_allowed;
  }
  return false;
};

export const useMappedReturnErrors = (
  errorsReturned: ErrorsReturned | null,
  filterSets: FilterSetData[] = []
): Record<string, boolean[]> => {
  return useMemo(() => {
    return filterSets.reduce((acc, [id, query], setIndx) => {
      // we only return errors when there was one from the api and none already in the filter
      const uniqueErrors = query.filters.map((filter, filterIndx) => {
        const steps = filter.steps || [];
        const anyErrors = steps.some(([, { value }]) => {
          if (Array.isArray(value)) {
            return value.some(isValueInError);
          }
          return isValueInError(value);
        });
        return (
          Boolean(errorsReturned?.parsedErrors?.[setIndx]?.[filterIndx]) &&
          !anyErrors
        );
      });
      return { ...acc, [id]: uniqueErrors };
    }, {});
  }, [filterSets, errorsReturned]);
};
