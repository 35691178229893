import DashboardService from 'services/DashboardService';
import DashletService from 'services/DashletService';
import TeamMemberService from 'services/TeamMemberService';

export const createDashletQuery = async (dashboardId, payload) => {
  const res = await DashletService.addDashlet(dashboardId, payload);
  return res;
};

export const updateDashletQuery = async (
  dashletId,
  dashboardId,
  payload,
  layoutUpdate
) => {
  const res = await DashletService.updateDashlet(
    {
      id: dashletId,
      dashboard: dashboardId,
      ...payload,
    },
    layoutUpdate
  );

  return res;
};

export const deleteDashletQuery = async (dashletId, dashboardId) => {
  const res = await DashletService.deleteDashlet(dashboardId, dashletId);
  return res;
};

export const getSummaryData = async (payload) => {
  const res = await DashletService.getDashletSummary(payload);
  return res;
};

export const getPivotTableSubRows = async (payload) => {
  const res = await DashletService.getPivotTableSubRows(payload);
  return res;
};

export const getTeamMembers = () => {
  return TeamMemberService.getTeamMemberList();
};

export const getRoles = () => {
  return TeamMemberService.getRolesOptionsList();
};

export const getPageConfigQuery = (key, config = {}) => {
  return TeamMemberService.getPageConfig(key, config);
};

export const respondToAccessRequestQuery = (requestId, approved) => {
  return DashboardService.respondToAccessRequest(requestId, approved);
};
