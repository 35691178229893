import { useEffect, useRef } from 'react';
import Validation, { useValidation } from 'components/Inputs/Validation';
import { TemplateName } from '../styles';

export const TemplateNameInput = ({ value, onChange, onSubmit, error }) => {
  const nameRef = useRef();
  const [validation, validationProps, , { flashErrorMessage }] = useValidation(
    nameRef,
    {
      value,
      validate: {
        full: () => {
          onSubmit?.();
          return true;
        },
      },
    }
  );

  useEffect(() => {
    if (error) {
      flashErrorMessage(error);
    }
  }, [error, flashErrorMessage]);

  return (
    <>
      <TemplateName
        ref={nameRef}
        value={value}
        onChange={onChange}
        {...validation}
      />
      <Validation {...validationProps} />
    </>
  );
};
