/**
 * Yields a sort function to sort an array of objects by one of the object's string fields.
 *
 * @remarks string comparison is done using [localeCompare](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare)
 *
 * @param {function} fieldSelector - funciton to select the object field used for sorting
 * @param {boolean} descending - (optional) set sort to descending. Default false.
 * @returns {function} [sort function](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
 */
export const sortByStringField =
  (fieldSelector, descending = false) =>
  (a, b) => {
    const aField = fieldSelector(a);
    const bField = fieldSelector(b);
    return descending
      ? bField.localeCompare(aField)
      : aField.localeCompare(bField);
  };
