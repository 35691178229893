import { Colors } from '../Colors/definitions';
import { getTailwindSafeColorClassName } from '../Colors/utils';
import { isDebug } from '../debug';
import { getKDSClasses, merge } from '../util';

type SpacerSize = 5 | 8 | 10 | 15 | 20 | 25 | 30;
type SpacerMode = 'square' | 'horizontal' | 'vertical';

export interface SpacerProps {
  size: SpacerSize;
  mode?: SpacerMode;
  color?: Colors;
}

const getSpacerSizeClassNames = (size: SpacerSize) => {
  switch (size) {
    case 5:
      return {
        height: 'h-spacer-5 min-h-spacer-5',
        width: 'w-spacer-5 min-w-spacer-5',
      };
    case 8:
      return {
        height: 'h-spacer-8 min-h-spacer-8',
        width: 'w-spacer-8 min-w-spacer-8',
      };
    case 10:
      return {
        height: 'h-spacer-10 min-h-spacer-10',
        width: 'w-spacer-10 min-w-spacer-10',
      };
    case 15:
      return {
        height: 'h-spacer-15 min-h-spacer-15',
        width: 'w-spacer-15 min-w-spacer-15',
      };
    case 20:
      return {
        height: 'h-spacer-20 min-h-spacer-20',
        width: 'w-spacer-20 min-w-spacer-20',
      };
    case 25:
      return {
        height: 'h-spacer-25 min-h-spacer-25',
        width: 'w-spacer-25 min-w-spacer-25',
      };
    case 30:
      return {
        height: 'h-spacer-30 min-h-spacer-30',
        width: 'w-spacer-30 min-w-spacer-30',
      };
  }
};

const getSpacerClassNames = (mode: SpacerMode, size: SpacerSize) => {
  const classNames = getSpacerSizeClassNames(size);

  if (!classNames) {
    return [];
  }

  if (mode === 'square') {
    return [classNames.height, classNames.width];
  }

  if (mode === 'horizontal') {
    return [classNames.height, 'w-full'];
  }

  if (mode === 'vertical') {
    return [classNames.width, 'h-full'];
  }

  return [];
};

export const Spacer = (props: SpacerProps) => {
  const { size, mode = 'square', color } = props;

  const classNames = getSpacerClassNames(mode, size);
  const { debugClassName } = isDebug();

  return (
    <div
      className={merge(
        getKDSClasses('spacer', String(size)),
        'relative',
        getTailwindSafeColorClassName(color, 'background'),
        debugClassName,
        ...classNames
      )}
    />
  );
};
