import { useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import { usePreserveCaretPositionActive } from 'hooks/usePreserveCaretPosition';
import KizenTypography from 'app/kizentypo';
import Input from 'components/Kizen/Input';
import { useFlashTransition } from 'hooks/useFlashState';
import { modalConstrainPopperConfig } from 'components/Inputs/Validation';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useTranslation } from 'react-i18next';
import { decideErrorMessage } from '../helpers';
import { ErrorCard } from '../styles';
import { DYNAMIC_TAGS_XFORM } from '../constants';

export const DynamicTagsModal = ({
  tag = null,
  onHide,
  onConfirm,
  tagLabel,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  const inputWrapperRef = useRef();
  const [error, showError, flashError] = useFlashTransition();
  const { t } = useTranslation();

  const inputProps = usePreserveCaretPositionActive({
    initialValue: tag?.name || '',
    xform: DYNAMIC_TAGS_XFORM,
    enabled: true,
  });

  return (
    <BasicModal
      heading={t('{{action}} {{tagLabel}}', {
        action: tag ? t('Edit') : t('Add'),
        tagLabel: tagLabel || t('Tag'),
      })}
      disabled={submitting || !inputProps.value}
      onConfirm={async () => {
        try {
          setSubmitting(true);
          if (!tag || inputProps.value.trim() !== tag.name) {
            await onConfirm(inputProps.value.trim(), tag);
          }
          onHide();
        } catch (err) {
          flashError(decideErrorMessage(err, t));
        } finally {
          setSubmitting(false);
        }
      }}
      onHide={onHide}
      {...props}
    >
      <Input
        ref={inputWrapperRef}
        autoFocus={!tag}
        label={t('{{tagLabel}} Name', { tagLabel: tagLabel || t('Tag') })}
        placeholder={t('Enter {{tagLabel}} Name', {
          tagLabel: tagLabel || t('Tag'),
        })}
        {...inputProps}
        error={!!error}
      />
      <Overlay
        transition={false}
        target={inputWrapperRef.current}
        show={typeof error === 'string'}
        placement="bottom-start"
        popperConfig={modalConstrainPopperConfig}
      >
        <ErrorCard light show={showError} duration="300ms">
          <KizenTypography>{error}</KizenTypography>
        </ErrorCard>
      </Overlay>
    </BasicModal>
  );
};
