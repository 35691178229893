import { useState } from 'react';
import { useNode } from '@craftjs/core';
import { useBuilderInput, useViewerContext } from '../viewer';
import { FieldContainer } from '../nodes/containers';
import { isContactTitlesField } from '../internal/checks/fields';
import { StyledFieldInput } from './styled';
import { definePlaceholder } from '../utils/fields';
import { useTranslation } from 'react-i18next';

// Company and Title dropdown options are not accessible from an unauthenticated endpoint
// When filling out a form, these fields are just text that are (possibly) matched to existing
// records upon submission
const formBuilderNodeXform = (field) => {
  let { fieldType } = field;
  if (isContactTitlesField(field)) {
    fieldType = 'text';
  }
  return {
    ...field,
    displayName: field.labelText || field.displayName,
    fieldType,
  };
};

const builderInputXform = (field) => (value) => {
  if (field.fieldType === 'team_selector') {
    // We need to add the field_type so the form page can recognize these values
    // and convert them when submitting to the API.
    return { ...value, field_type: 'team_selector' };
  }
  if (field.fieldType === 'decimal') {
    return value !== 0 && !value ? value : value.toString();
  }

  return value;
};

export const ViewCustomField = ({ field, error, ...rest }) => {
  const { t } = useTranslation();
  const { setProp } = useNode();
  const { inputValidators } = useViewerContext();
  const [value, setValue] = useBuilderInput(field.id, builderInputXform(field));
  const [innerValue, setInnerValue] = useState(value);
  const handleChange = (value, event, err) => {
    if (err) {
      setValue(value, err);
      setInnerValue(value);
      return;
    }
    const validator =
      inputValidators[field?.fieldType] ?? inputValidators.default ?? Boolean;
    if (validator(value)) {
      setProp((p) => {
        p.error = undefined;
      });
    }
    setValue(value);
    setInnerValue(value);
  };

  return (
    <FieldContainer {...rest}>
      <StyledFieldInput
        object={{}}
        field={formBuilderNodeXform(field)}
        value={innerValue ?? undefined}
        onChange={handleChange}
        error={error}
        placeholder={definePlaceholder(field, t)}
        isRequired={field.isRequired}
      />
    </FieldContainer>
  );
};
