import styled from '@emotion/styled';
import { colorsText } from '../../../app/colors';
import { fontWeights } from '../../../app/typography';
import ListItem from './ListItem';

export default styled(ListItem)`
  cursor: default;
  color: ${colorsText.medium};
  font-weight: ${fontWeights.bold};
  &:hover {
    background-color: unset; // Override ListItem hover style
  }
`;
