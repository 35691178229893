import { getUniqueId } from '../utils/id';
import { PageBuilderCell, PageBuilderNode, PageBuilderTree } from '../types';
import { parseNode, createCell, createRoot } from './builder-nodes';
import {
  DefaultEmailProps,
  DefaultFormProps,
  DefaultHomepageProps,
  DefaultProps,
  DefaultStaticBlockProps,
} from './defaults';
import { Node, NodeProps, PartialValues, Root, Row } from './types';

const ROOT_ID = 'ROOT';

const isRoot = (node: Node): node is Root => node[0] === 'Root';
const isRow = (node: Node): node is Row => node[0] === 'Row';

/**
 * Get the data necessary to transform a Row node into a Page Builder Row node.
 * Rows differ from other page builder nodes by specifying their children (Cells) in
 * the `linkedNodes` object instead of the `nodes` array. This is where we create and
 * associate Page Builder Cell nodes since Cells do not exist in this module's AST.
 *
 * @param parent - id of parent node
 * @param cells - the children of a Row node
 * @returns 2-tupel [[string, Cell][], Record<string, string>]
 *  0 - an array of object entries where item 0 is the node id item 2 is the Cell node
 *  1 - a linkedNodes object where keys are column-1, column-2, etc and values are node ids
 */
const getRowNodeData = (
  parent: string,
  cells?: Iterable<Node | null>
): [[string, PageBuilderCell][], Record<string, string>] => {
  // default the row to have 1 cell if none are provided
  const iter = Array.isArray(cells) && cells.length > 0 ? cells : [null];
  const cellEntries: [string, PageBuilderCell][] = [];
  const linkedNodes: PageBuilderNode['linkedNodes'] = {};
  for (let i = 1; i <= iter.length; i++) {
    const id = getUniqueId();
    cellEntries.push([id, createCell(parent)]);
    linkedNodes[`column-${i}`] = id;
  }
  return [cellEntries, linkedNodes];
};

/**
 * Returns a reducing function to transform a Node tree into a PageBuilderTree.
 *
 * @remarks the `parent` argument is a string[] when transforming a Row's children.
 * A PageBuilderTree has separate Cell nodes for each column in the row, so we need to
 * lookup the correct cell id when assigning a Row's child parent id instead of just
 * assigning the row id.
 *
 * @param parent - the parent id of the tree being transformed.
 * @param defaultProps - default props to be applied to each node during transformation.
 * @returns PageBuilderTree
 */
const reduceTree =
  (
    parent: string | string[],
    defaultProps: Partial<PartialValues<NodeProps>>
  ) =>
  (acc: PageBuilderTree, node: Node | null, idx: number): PageBuilderTree => {
    if (node === null) return acc;
    const id = isRoot(node) ? ROOT_ID : getUniqueId();
    const p = Array.isArray(parent) ? parent[idx] : parent;
    const newNode = {
      parent: p,
      ...parseNode(node, defaultProps[node[0]]),
    };
    const [cellEntries, linkedNodes] = isRow(node)
      ? getRowNodeData(id, node[2])
      : [[], null];

    if (linkedNodes) {
      newNode.linkedNodes = linkedNodes;
    }

    const newParent = isRow(node) ? cellEntries.map(([id]) => id) : id;
    const childNodes = Array.isArray(node?.[2])
      ? [...node[2]].reduce(reduceTree(newParent, defaultProps), {})
      : {};

    return {
      ...acc,
      ...childNodes,
      ...Object.fromEntries(cellEntries),
      [id]: newNode,
    };
  };

/**
 * Iterate over a PageBuilderTree and collect child nodes for each parent.
 *
 * @remarks Row nodes are skipped since their children are defined in linkedNodes
 */
const getChildren = (tree: PageBuilderTree) =>
  Object.entries(tree).reduce((acc: Record<string, string[]>, [id, node]) => {
    if (node.parent) {
      acc[node.parent] = acc[node.parent] || [];
      if (tree[node.parent]?.type?.resolvedName !== 'Row') {
        acc[node.parent].push(id);
      }
    }
    return acc;
  }, {});

/**
 * HOF to return a compile function with a given default props context. The default props
 * allows the same props to be applied to all nodes of a certain type, reducing the size of
 * page-builder ASTs.
 *
 * @param defaultProps - props to be applied to each node when building a PageBuilderTree
 */
export const compile = (defaultProps: DefaultProps) => (root: Root) => {
  const rootNode = {
    [ROOT_ID]: createRoot({ ...defaultProps.Root, ...root[1] }),
  };

  if (!Array.isArray(root[2])) return rootNode;

  const tree = root[2].reduce(reduceTree(ROOT_ID, defaultProps), {});
  const children = getChildren(tree);
  const result: PageBuilderTree = { ...rootNode, ...tree };
  for (const [parent, nodes] of Object.entries(children)) {
    result[parent].nodes = nodes ?? [];
  }
  return result;
};

export const compileForm = compile(DefaultFormProps);
export const compileSurvey = compileForm;
export const compileEmail = compile(DefaultEmailProps);
export const compileHomepage = compile(DefaultHomepageProps);
export const compileStaticBlock = compile(DefaultStaticBlockProps);
