import { useMemo } from 'react';
import {
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import {
  getLink,
  getRelationshipLabel,
} from 'components/Timeline2/EventBlocks/helpers';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { FIELD_TYPES } from 'utility/constants';
import { useTranslation } from 'react-i18next';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { eventTypes } from 'components/Timeline2/constants';

export const useColumns = () => {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();
  return useMemo(
    () => [
      {
        id: 'spacer-start',
        headCell: <SizedCell width="15px" />,
        cell: <SizedCell width="15px" />,
        format: () => <span />,
      },
      {
        id: 'team_member',
        headCell: (
          <SortableHeadCell
            hasSorting={false}
            label={t('Team Member')}
            width="200px"
            padding="60px"
          />
        ),
        cell: <SizedCell width="200px" padding="60px" />,
        format: (_, data) => {
          return (
            <TextEllipsisWithTooltip
              {...(preReleaseFeatures
                ? {
                    type: 'anchor',
                    href: getLink(data.initiator, {
                      type: FIELD_TYPES.TeamSelector.type,
                    }),
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  }
                : {})}
            >
              {getRelationshipLabel(null, data.initiator)}
            </TextEllipsisWithTooltip>
          );
        },
      },
      {
        id: 'date_modified',
        headCell: (
          <SortableHeadCell
            by="created"
            numeric
            label={t('Modified Date')}
            width="150px"
            padding="60px"
          />
        ),
        cell: <SizedCell width="150px" padding="60px" />,
        format: (_, data) => {
          return data?.initiator?.type === 'employee' ? (
            <DateTimeInputInline
              readOnly
              value={data.created}
              showDateTooltip
            />
          ) : null;
        },
      },
      {
        id: 'changes',
        headCell: (
          <SortableHeadCell
            hasSorting={false}
            label={t('Change(s) Made')}
            minWidth="120px"
            padding="60px"
          />
        ),
        cell: <SizedCell minWidth="120px" padding="60px" />,
        format: (_, data) => {
          return (
            <TextEllipsisWithTooltip>
              {data.typeName === eventTypes.automationCreated
                ? t('Created')
                : t('Modified')}
            </TextEllipsisWithTooltip>
          );
        },
      },
    ],
    [preReleaseFeatures, t]
  );
};
