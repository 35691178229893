import { useState } from 'react';
import GenericWizard from 'components/GenericWizard';
import BasicModal from 'components/Modals/presets/BasicModal';
import { FIELD_TYPES } from 'utility/constants';

const updateField = (data) => {
  const {
    displayName,
    isRequired,
    type,
    options,
    phonenumberOptions,
    moneyOptions,
    relations,
    rating,
    meta,
  } = data;
  return {
    name: displayName,
    options,
    displayName,
    description: displayName,
    isRequired: !!isRequired,
    fieldType: type,
    ...(meta !== undefined && {
      meta,
    }),
    ...(type === FIELD_TYPES.PhoneNumber.type && {
      phonenumberOptions: { ...phonenumberOptions },
    }),
    ...(type === FIELD_TYPES.Rating.type && { rating }),
    ...(type === FIELD_TYPES.Money.type && { moneyOptions }),
    ...(type === FIELD_TYPES.Relationship.type && { relations }),
  };
};

export const EditFormField = ({
  formFieldHeading,
  data,
  show,
  onHide,
  setProp,
}) => {
  const [{ data: formData, isComplete }, setWizardData] = useState({
    data,
    isComplete: true,
  });

  const onChange = ({ data, isComplete }) => {
    setWizardData({ data, isComplete });
  };
  return (
    <BasicModal
      key={data.field.id}
      show={show}
      typeOfContent="wizard"
      heading={formFieldHeading}
      disabled={!isComplete}
      size="medium"
      fitContent={false}
      onConfirm={() => {
        setProp(updateField(formData));
        onHide();
        setWizardData({ data: {}, isComplete: false });
      }}
      onHide={() => {
        onHide();
        setWizardData({ data: {}, isComplete: false });
      }}
    >
      <GenericWizard wizard="FormFieldWizard" onChange={onChange} data={data} />
    </BasicModal>
  );
};
