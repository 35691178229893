import { usePermissionSetAll } from '@kizen/permissions/hooks/usePermissionSetAll';
import KizenTypography from 'app/kizentypo';
import {
  SetAllNone,
  SetAllView,
  SetAllEdit,
  SetAllRemove,
} from './SetAllCheckbox';
import { GridContainer, HeaderToggles } from './styled';

export const SetAllRow = ({
  permissionKeys,
  label,
  showNone = false,
  showView = false,
  showEdit = false,
  showRemove = false,
}) => {
  const { setNone, setView, setEdit, setRemove } = usePermissionSetAll();

  return (
    <GridContainer marginBottom="17">
      <KizenTypography>{label}</KizenTypography>
      <HeaderToggles>
        {showNone && <SetAllNone onChange={() => setNone(permissionKeys)} />}
        {showView && <SetAllView onChange={() => setView(permissionKeys)} />}
        {showEdit && <SetAllEdit onChange={() => setEdit(permissionKeys)} />}
        {showRemove && (
          <SetAllRemove onChange={() => setRemove(permissionKeys)} />
        )}
      </HeaderToggles>
    </GridContainer>
  );
};
