import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';

import { ContentWidth } from 'components/Layout/PageContentWidth';
import { ApiCard } from 'pages/Account/pages/Profile/Forms/styles';
import {
  TableTitleWrapper,
  TableBoxesWrapper,
  SearchContainer,
} from 'pages/Account/pages/Profile/Forms/tableStyles';

import ProfileInfiniteTable from 'pages/Account/pages/Profile/Forms/ProfileInfiniteTable';

import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import { useAccessTokens } from 'hooks/accessTokens/useAccessTokens';

import { createColumns } from 'pages/Account/pages/Profile/Forms/OAuth/columns';
import { APIKeysNotice } from 'pages/Account/pages/Profile/Forms/OAuth/APIKeysNotice';

import { getManageOauthAuthorizations } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const APPLICATION_WIDTH = '160px';
const TEAM_MEMBER_WIDTH = '155px';
const STATUS_WIDTH = '65px';
const REFRESH_WIDTH = '120px';
const ACTION_WIDTH = '51px';
const PADDING_WIDTH = '20px';

const columnWidths = {
  application: APPLICATION_WIDTH,
  employee: TEAM_MEMBER_WIDTH,
  status: STATUS_WIDTH,
  created: REFRESH_WIDTH,
  actions: ACTION_WIDTH,
  padding: PADDING_WIDTH,
};

const _OAuth = ({ manageOauthAuthorizations }) => {
  const { t } = useTranslation();

  const {
    handleTokenRevoke: onTokenRevoke,
    accessTokens,
    oauthTokensModalProps,
    messageHeadData,
    messagesSort,
    setMessagesSort,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,
    search,
    setSearch,
    anyAccessTokens,
  } = useAccessTokens(null);

  const columns = useMemo(
    () =>
      createColumns({
        onSelectAction: ({ value }, key) => {
          const { id } = key;
          if (value === 'onTokenRevoke') {
            onTokenRevoke(id);
          }
        },
        columnWidths,
        canRevoke: manageOauthAuthorizations.remove,
        t,
      }),
    [onTokenRevoke, manageOauthAuthorizations, t]
  );

  return (
    <>
      <ApiCard as={ContentWidth}>
        <TableTitleWrapper marginBottom="11">
          <KizenTypography as="h3" type="subheader">
            {t('OAuth Authorizations')}
          </KizenTypography>
          <SearchContainer>
            <PageSearchInput
              placeholder={t('Find Authorizations')}
              value={search}
              onChange={setSearch}
              loading={isFetchingNextPage || isFetching}
            />
          </SearchContainer>
        </TableTitleWrapper>
        <TableBoxesWrapper>
          <APIKeysNotice>
            <KizenTypography as="div">
              {t(
                `(For Techies) A list of OAuth authorizations established for all team members in this business are enumerated below. You can revoke tokens, based on your permission level.`
              )}
              {!anyAccessTokens
                ? t(` There are not yet any authorizations.`)
                : null}
            </KizenTypography>
          </APIKeysNotice>
        </TableBoxesWrapper>
        {accessTokens ? (
          <>
            <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
              <ScrollContainerBlocker />
            </ScrollContainerBlockerWrapper>
            <ProfileInfiniteTable
              columns={columns}
              data={accessTokens}
              messageHeadData={messageHeadData}
              messagesSort={messagesSort}
              setMessagesSort={setMessagesSort}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isFetching={isFetching}
              status={status}
            />
          </>
        ) : (
          false
        )}
      </ApiCard>
      <ConfirmationModal
        buttonText={t('Confirm')}
        heading={t('Please Confirm Revocation')}
        {...oauthTokensModalProps}
      >
        {t(
          'Revoking this authorization will make all subsequent requests using the token invalid and denied by Kizen.'
        )}
      </ConfirmationModal>
    </>
  );
};

const OAuth = () => {
  const manageOauthAuthorizations = useSelector(getManageOauthAuthorizations);

  return manageOauthAuthorizations?.view
    ? _OAuth({ manageOauthAuthorizations })
    : false;
};

export default OAuth;
