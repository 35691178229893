import React, { createContext, ReactNode, useContext } from 'react';

interface TranslationContextType {
  t: (value: string) => string;
}

interface TranslationProviderProps {
  children: ReactNode;
  t?: TranslationContextType['t'];
}

const defaultTranslationFunction: TranslationContextType['t'] = (
  translation: string
) => translation;

export const TranslationContext = createContext<TranslationContextType>({
  t: defaultTranslationFunction,
});

export const useTranslationContext = () => {
  return useContext(TranslationContext);
};

export const TranslationContextProvider: React.FC<TranslationProviderProps> = ({
  children,
  t = defaultTranslationFunction,
}) => {
  return (
    <TranslationContext.Provider value={{ t }}>
      {children}
    </TranslationContext.Provider>
  );
};
