import React from 'react';
import * as PropTypes from 'prop-types';
import {
  StyledInputWrapper,
  StyledLabel,
  StyledInput,
  StyledText,
  StyledTextLabel,
} from './styles';

const YesNoQuestion = ({
  label,
  name,
  value,
  onChange,
  error,
  includeMaybe,
  ...others
}) => {
  return (
    <div {...others}>
      {label && <StyledTextLabel>{label}</StyledTextLabel>}
      <StyledInputWrapper>
        <StyledLabel>
          <StyledInput
            type="radio"
            name={name}
            value="yes"
            checked={value === 'yes'}
            onChange={() => onChange('yes')}
          />
          <StyledText error={error}>YES</StyledText>
        </StyledLabel>
        {includeMaybe && (
          <StyledLabel>
            <StyledInput
              type="radio"
              name={name}
              value="maybe"
              checked={value === 'maybe'}
              onChange={() => onChange('maybe')}
            />
            <StyledText error={error}>MAYBE</StyledText>
          </StyledLabel>
        )}
        <StyledLabel>
          <StyledInput
            type="radio"
            name={name}
            value="no"
            checked={value === 'no'}
            onChange={() => onChange('no')}
          />
          <StyledText error={error}>NO</StyledText>
        </StyledLabel>
      </StyledInputWrapper>
    </div>
  );
};

YesNoQuestion.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOf(['yes', 'no', '']).isRequired,
  error: PropTypes.bool,
  includeMaybe: PropTypes.bool,
};

YesNoQuestion.defaultProps = {
  error: false,
  includeMaybe: false,
};

export default YesNoQuestion;
