import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import LinkTextInline from 'components/Inputs/inline/LinkText';
import Validation from 'components/Inputs/Validation';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { getOriginalError } from 'services/AxiosService';

const ValidatedField = ({
  onSubmit,
  position = 'relative',
  inModal,
  fieldId = '',
  ...others
}) => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const overlayTarget = useRef(null);

  const handleSubmit = async (value) => {
    try {
      const res = await onSubmit(value);
      return res;
    } catch (error) {
      const orig = snakeToCamelCaseKeys(getOriginalError(error));
      setErrorMessage(orig?.[fieldId] ?? error.message);
      setShowError(true);
      setTimeout(() => setShowError(false), 1500);
      return false;
    }
  };

  return (
    <div style={{ position }} ref={overlayTarget}>
      <LinkTextInline onSubmit={handleSubmit} {...others} />
      <Validation
        showMessage={showError}
        message={errorMessage}
        target={overlayTarget.current}
        inModal={inModal}
      />
    </div>
  );
};

ValidatedField.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldId: PropTypes.string,
};

export default ValidatedField;
