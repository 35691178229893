import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TopLayerSelect } from 'components/PageBuilder/components/TopLayerSelect';

export const SendFromTeamMemberField = React.memo(
  ({ errorMessage, showError, modalLayer, ...rest }) => {
    const { t } = useTranslation();
    return (
      <TopLayerSelect
        variant="underline"
        label={t('Send From (Team Selector Field)')}
        validate={{ message: errorMessage, showMessage: showError }}
        modalLayer={modalLayer}
        placeholder={t('Choose Team Selector Field')}
        labelInfo={t(
          'In the event there is no selected team member in the chosen field, this email will come from the last active team member on the record.'
        )}
        {...rest}
      />
    );
  }
);

SendFromTeamMemberField.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.objectOf({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};
