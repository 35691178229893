import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectTeamMember from 'components/Fields/FieldInput/SelectTeamMember';
import {
  NotesRichTextEditor,
  parseMentions,
} from 'components/Inputs/NotesRichTextEditor';
import { NotesLabel } from 'pages/Common/styles/Profile';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import { useParams } from 'react-router-dom';
import format from 'date-fns2/format';
import { SelectAssignmentType, SelectRole } from '../components';
import {
  ASSIGNMENT_TYPES,
  DATE_TIME_FORMAT_WITH_TIME_ZONE,
  FORMDATA_FIELDS,
  STEPS,
} from '../config';
import { SettingsFields, StyledSection } from '../styled';
import DateTimeInput from 'components/Inputs/DateTimeInput';
import KizenTypography from 'app/kizentypo';
import Icon from 'components/Kizen/Icon';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import ct from 'countries-and-timezones';
import { getDateOption } from '../../../../Inputs/DateTimeInput';
import {
  businessLocalTimezoneDstOffset,
  getDateWithTimezone,
} from 'app/timezone';
import Select from 'components/Inputs/Select';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { namedToOption } from 'services/helpers';

const { ACTIVITY_ID, NOTE, DATETIME, EMPLOYEE, ROLE, MENTIONS } =
  FORMDATA_FIELDS;
const { TEAMMEMBER_ASSIGNMENT, ROLE_ASSIGNMENT } = ASSIGNMENT_TYPES;

const StyledDueDateExplanation = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 9px;
  }
  margin-top: 9px;
  margin-bottom: 20px;
`;
const checkValidSettings = ({
  activityObjectId,
  employeeId,
  roleId,
  dueDatetime,
}) => Boolean(activityObjectId && (employeeId || roleId) && dueDatetime);

const ScheduleActivitySettings = ({
  updateField,
  updateAssignmentType,
  getKeyListenersProps,
  updateValidation,
  activityObjectId,
  dueDatetime,
  employeeId,
  roleId,
  note,
  objectId = null,
  activityError,
  setActivityError,
  businessTimezone,
  activity: activityData,
  scheduleActivityList,
  setChosenActivity,
}) => {
  const dateRef = useRef();
  const selectRef = useRef();
  const [activity, setActivity] = useState(
    activityData?.activityObject
      ? namedToOption(activityData.activityObject)
      : null
  );

  const { t } = useTranslation();
  const scheduled_activities = useSelector(
    (s) => s.authentication.access['scheduled_activities']
  );
  const params = useParams();
  const [assignmentType, setAssignmentType] = useState(
    roleId ? ROLE_ASSIGNMENT : TEAMMEMBER_ASSIGNMENT
  );
  const teamMember = useSelector((s) => s.authentication.teamMember || {});

  const isValid = checkValidSettings({
    activityObjectId,
    dueDatetime,
    employeeId,
    roleId,
    note,
  });

  useEffect(() => {
    updateValidation(STEPS.ACTIVITY_SETTINGS, isValid);
  }, [isValid, updateValidation]);
  const businessTZ = ct.getTimezone(businessTimezone);
  const dueDatetimeValue = useMemo(
    () =>
      dueDatetime
        ? getDateWithTimezone(
            new Date(`${dueDatetime}`),
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )
        : null,
    [dueDatetime]
  );

  const dateWithTimezone = useMemo(() => {
    const diff = businessLocalTimezoneDstOffset(businessTimezone);
    if (dueDatetime && diff) {
      return getDateWithTimezone(dueDatetime, businessTimezone);
    }
    return null;
  }, [businessTimezone, dueDatetime]);

  const [typeaheadProps] = useSelectTypeaheadWithScroll({
    enabled: !scheduleActivityList,
    selectRef,
    fieldId: objectId || params.objectId,
    entity: Entities.ActivitiesLoggable,
  });

  return (
    <StyledSection index={0} header={t('Activity Settings')} isActive>
      <SettingsFields margin>
        <Select
          fullWidth
          ref={selectRef}
          {...getKeyListenersProps(ACTIVITY_ID)}
          label={t('Choose Activity')}
          placeholder={t('Find Activity')}
          value={activity || activityObjectId}
          onChange={(option) => {
            updateField(ACTIVITY_ID, option.value);
            setActivity(option);
            setChosenActivity?.(option);
            if (activityError && option) {
              setActivityError(false);
            }
          }}
          disabled={!!activityData?.id}
          error={activityError}
          menuLeftButton={null}
          menuRightButton={null}
          {...(scheduleActivityList
            ? { options: scheduleActivityList }
            : typeaheadProps)}
          margin
        />
        <SelectAssignmentType
          {...getKeyListenersProps(TEAMMEMBER_ASSIGNMENT)}
          fullWidth
          label={t('Assignment Type')}
          value={assignmentType}
          onChange={(value) => {
            setAssignmentType(value);
            updateAssignmentType(
              value,
              value === TEAMMEMBER_ASSIGNMENT ? teamMember.id : null
            );
          }}
          margin
        />
        {assignmentType === TEAMMEMBER_ASSIGNMENT ? (
          <SelectTeamMember
            {...getKeyListenersProps(`assignmentType`)}
            isDisabled={!scheduled_activities.all_records.edit}
            fullWidth
            label={t('Assign Team Member')}
            placeholder={t('Choose Team Member')}
            value={employeeId}
            onChange={(v) => {
              updateField(EMPLOYEE, v.data.id);
            }}
            menuRightButton={<ApplySelectButton />}
            field={{
              isDefault: true,
            }}
            margin
          />
        ) : (
          <SelectRole
            {...getKeyListenersProps(`assignmentType`)}
            fullWidth
            label={t('Assign Role')}
            placeholder={t('Find Role')}
            value={roleId}
            onChange={(value) => {
              updateField(ROLE, value);
            }}
            margin
          />
        )}
        <DateTimeInput
          ref={dateRef}
          {...getKeyListenersProps(DATETIME)}
          label={t('Due Date & Time')}
          value={dueDatetimeValue}
          onChange={(v) => {
            updateField(
              DATETIME,
              `${format(v, DATE_TIME_FORMAT_WITH_TIME_ZONE)}`
            );
          }}
          // Unlike most places in the app, here we don't want to set any z-index on the
          // date picker, because it needs to always be behind the mention plugin for the notes field
          layer={false}
          margin={!dateWithTimezone}
        />
        {dateWithTimezone ? (
          <StyledDueDateExplanation>
            <Icon icon="globe" size="2x" color={grayScale.mediumDark} />
            <KizenTypography fontStyle="italic" color={grayScale.mediumDark}>
              {t(
                `This will be scheduled at {{date}} in business timezone ({{timeZone}} GMT{{offset}})`,
                {
                  date: getDateOption(dateWithTimezone, false).label,
                  timeZone: businessTZ.name,
                  offset: businessTZ.dstOffsetStr,
                }
              )}
            </KizenTypography>
          </StyledDueDateExplanation>
        ) : null}
        <NotesRichTextEditor
          {...getKeyListenersProps(NOTE)}
          label={<NotesLabel as="span">{t('Notes')}</NotesLabel>}
          value={note}
          onChange={({ editor }) => {
            const text = editor.getHTML();
            updateField(NOTE, text);
            updateField(MENTIONS, parseMentions(text));
          }}
        />
      </SettingsFields>
    </StyledSection>
  );
};

ScheduleActivitySettings.defaultProps = {
  activityObjectId: null,
  dueDatetime: null,
  employeeId: null,
  roleId: null,
  activityError: false,
};

export default ScheduleActivitySettings;
