import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getStreamKey } from '@kizen/permissions/utils';
import { useGetCustomObjectConfigs } from '@kizen/permissions/hooks/useAddCustomObjectPermissions';
import { useGetFieldConfigs } from '@kizen/permissions/hooks/useAddFieldPermissions';
import { useAddPermissionsToSection } from '@kizen/permissions/hooks/useAddPermissionsToSection';
import { useAreFieldsLoaded } from '@kizen/permissions/hooks/useAreFieldsLoaded';
import { usePermissionValue } from '@kizen/permissions/hooks/usePermissionValue';
import { usePermissionsContext } from '@kizen/permissions/hooks/context';
import { useRemoveSectionPermissions } from '@kizen/permissions/hooks/useRemoveSectionPermissions';
import {
  FieldPermissionRows,
  PermissionLabel,
  PermissionRow,
  Section,
  SectionContent,
  SetAllRow,
} from './components';
import {
  fetchCategorizedFieldsQuery,
  prefetchCategorizedFieldsQuery,
  useCategorizedFieldsQuery,
} from './query';
import { formatString } from './util';

const EMPTY_ARRAY = [];
const DEFAULT_FIELDS = 'default_fields';
const defaultFieldsOrder = {
  name: 0,
  created: 1,
  stage: 2,
  owner: 3,
  entity_value: 4,
  estimated_close_date: 5,
  actual_close_date: 6,
  percentage_chance_to_close_low: 7,
  percentage_chance_to_close_high: 8,
};

const RowWithRuleTooltip = ({
  affordance,
  category,
  label,
  labelIndent,
  permission,
  section,
  tooltip,
  objectName,
}) => {
  const ruleTooltip = usePermissionValue(
    getStreamKey(permission, section),
    ({ tooltip }) => formatString(tooltip, objectName)
  );

  return (
    <PermissionRow
      section={section}
      permission={permission}
      affordance={affordance}
      tooltip={ruleTooltip || tooltip}
      label={label}
      labelIndent={labelIndent}
      category={category}
    />
  );
};

export const CustomObjectSection = ({
  section: {
    key,
    label: section_label,
    metadata: { object_type, object_name },
    enableRelatedFieldRule = true,
    ...rest
  },
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [hasPrefetched, setHasPrefetched] = useState(false);
  const {
    meta_config: { custom_objects, custom_object_categories },
  } = usePermissionsContext();
  const isSectionEnabled = usePermissionValue(key, ({ value }) => value);
  const areFieldsLoaded = useAreFieldsLoaded(key);
  const removeSectionPermissions = useRemoveSectionPermissions();
  const getCustomObjectConfigs = useGetCustomObjectConfigs();
  const getFieldConfigs = useGetFieldConfigs(enableRelatedFieldRule);
  const addPermissionsToSection = useAddPermissionsToSection();
  const { data: categorizedFields } = useCategorizedFieldsQuery(
    key,
    object_type,
    { enabled: Boolean(isSectionEnabled && areFieldsLoaded) }
  );
  const addingPermissions = useRef(false);

  const withDefaultFieldsCategory = useMemo(() => {
    if (!categorizedFields) return null;
    const defaultCategoryIndex = categorizedFields?.findIndex((cat) =>
      cat.fields.some((x) => x.isDefault)
    );

    if (defaultCategoryIndex < 0) {
      return categorizedFields;
    }

    const defaultCategory = categorizedFields[defaultCategoryIndex];
    const init = [
      { id: DEFAULT_FIELDS, name: t('Default Fields'), fields: [] },
      { id: defaultCategory.id, name: defaultCategory.name, fields: [] },
    ];
    const defaultCategories = categorizedFields[
      defaultCategoryIndex
    ].fields.reduce((acc, field) => {
      if (field.isDefault) acc[0].fields.push(field);
      else acc[1].fields.push(field);
      return acc;
    }, init);
    const sortedDefaultCategory = {
      ...defaultCategories[0],
      fields: defaultCategories[0].fields.sort((a, b) => {
        return defaultFieldsOrder[a.name] - defaultFieldsOrder[b.name];
      }),
    };
    return [sortedDefaultCategory]
      .concat(defaultCategories[1])
      .concat(
        categorizedFields
          .slice(0, defaultCategoryIndex)
          .concat(
            categorizedFields.slice(
              defaultCategoryIndex + 1,
              categorizedFields.length
            )
          )
      );
  }, [categorizedFields, t]);

  const allFieldPermissionKeys = useMemo(() => {
    return (
      categorizedFields?.reduce((acc, { fields }) => {
        acc.push(...fields.map(({ name }) => getStreamKey(name, key)));
        return acc;
      }, []) ?? EMPTY_ARRAY
    );
  }, [key, categorizedFields]);

  const { main, bulk, bulk_keys, default_for_new_field } = useMemo(() => {
    if (!isSectionEnabled || !areFieldsLoaded)
      return {
        main: [],
        bulk: [],
        bulk_keys: [],
        default_for_new_field: null,
      };
    return custom_objects.reduce(
      (acc, permission) => {
        if (permission.key === 'default_for_new_field') {
          acc.default_for_new_field = permission;
        } else if (permission.is_bulk_action) {
          acc.bulk.push(permission);
          acc.bulk_keys.push(getStreamKey(permission.key, key));
        } else {
          if (
            permission.category &&
            !acc.main.find(({ key }) => key === permission.category)
          ) {
            const customObjectsCategory = custom_object_categories.find(
              ({ key }) => key === permission.category
            );
            customObjectsCategory &&
              acc.main.push({ ...customObjectsCategory, isCategory: true });
          }
          acc.main.push(permission);
        }
        return acc;
      },
      { main: [], bulk: [], bulk_keys: [], default_for_new_field: null }
    );
  }, [
    isSectionEnabled,
    areFieldsLoaded,
    custom_objects,
    custom_object_categories,
    key,
  ]);

  const addPermissionsToContext = useCallback(async () => {
    if (!areFieldsLoaded && !addingPermissions.current) {
      addingPermissions.current = true;
      const data = await fetchCategorizedFieldsQuery(
        key,
        object_type,
        queryClient
      );
      const staticPermissions = getCustomObjectConfigs(key);
      const fieldPermissions = getFieldConfigs(
        data.flatMap((x) => x.fields),
        key
      );
      addPermissionsToSection(
        key,
        [...staticPermissions, ...fieldPermissions],
        { containsFieldPermissions: true }
      );
      addingPermissions.current = false;
    }
  }, [
    key,
    object_type,
    areFieldsLoaded,
    getCustomObjectConfigs,
    addPermissionsToSection,
    getFieldConfigs,
    queryClient,
  ]);

  const onSectionToggle = useCallback(
    (value) => {
      if (!value) {
        removeSectionPermissions(key);
      } else {
        addPermissionsToContext();
      }
    },
    [key, removeSectionPermissions, addPermissionsToContext]
  );

  const handleExpanderHover = useCallback(() => {
    if (!hasPrefetched & !areFieldsLoaded) {
      prefetchCategorizedFieldsQuery(key, object_type, queryClient);
      setHasPrefetched(true);
    }
  }, [hasPrefetched, areFieldsLoaded, key, object_type, queryClient]);

  return (
    <Section
      title={section_label}
      sectionId={key}
      onChange={onSectionToggle}
      onExpand={addPermissionsToContext}
      onExpanderHover={handleExpanderHover}
      loading={!areFieldsLoaded}
      {...rest}
    >
      {isSectionEnabled && (
        <SectionContent>
          {main.map(
            ({
              affordance,
              key: permission_key,
              label,
              tooltip,
              category,
              isCategory,
            }) => {
              return isCategory ? (
                <PermissionLabel label={label} tooltip={tooltip} />
              ) : (
                <RowWithRuleTooltip
                  key={permission_key}
                  section={key}
                  permission={permission_key}
                  affordance={affordance}
                  label={formatString(label, object_name)}
                  objectName={object_name}
                  labelIndent={category ? 40 : 0}
                  tooltip={formatString(tooltip, object_name)}
                />
              );
            }
          )}
          <SetAllRow
            showNone
            showRemove
            label={t('Perform Bulk Actions (Set All)')}
            permissionKeys={bulk_keys}
          />
          {bulk.map(({ affordance, key: permission_key, label, tooltip }) => {
            return (
              <RowWithRuleTooltip
                key={permission_key}
                section={key}
                permission={permission_key}
                affordance={affordance}
                label={label}
                tooltip={formatString(tooltip, object_name)}
                objectName={object_name}
                labelIndent={40}
              />
            );
          })}
          {default_for_new_field && (
            <RowWithRuleTooltip
              key={default_for_new_field.key}
              section={key}
              permission={default_for_new_field.key}
              affordance={default_for_new_field.affordance}
              label={default_for_new_field.label}
              objectName={object_name}
            />
          )}
          <SetAllRow
            showNone
            showView
            showEdit
            label={t('Individual {{ObjectName}} Field Permission (Set All)', {
              ObjectName: object_name,
            })}
            permissionKeys={allFieldPermissionKeys}
          />
          {areFieldsLoaded &&
            withDefaultFieldsCategory.map(({ id, fields, name }) => {
              return (
                <FieldPermissionRows
                  key={id}
                  sectionId={key}
                  categoryName={name}
                  fields={fields}
                  enableRelatedFieldRule={enableRelatedFieldRule}
                />
              );
            })}
        </SectionContent>
      )}
    </Section>
  );
};
