import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { grayScale } from 'app/colors';

am5.addLicense('AM5C196653899');

export const valueYAccessor = '{valueY}';
export const valueXAccessor = '{valueX}';
export const categoryAccessor = '{category}';
// Don't remove these values during text sanitization
export const replacementValues = [
  valueXAccessor,
  valueYAccessor,
  categoryAccessor,
];

export const sanitizeTextForCharts = (text) => {
  if (!text) {
    return text;
  }
  // Replace [  with [[ and ] with ]]
  // Replace {  with {{ and } with }}
  let sanitized = String(text)
    .replace(/\[/g, '[[')
    .replace(/\]/g, ']]')
    .replace(/\{/g, '{{')
    .replace(/\}/g, '}}');

  replacementValues.forEach((value) => {
    sanitized = sanitized.replace(`{${value}}`, value);
  });

  return sanitized;
};

class ChartManager {
  DEFAULT_TIME_UNIT_COUNT = 1;
  DEFAULT_TIME_UNIT_FREQUENCY = 'day';
  THROTTLE_DELAY = 200;

  constructor(element, t, type, config, chartConfig, rootConfig, cb) {
    this.t = t;
    this.element = element;
    this.defaultFormat = '#,###';
    this.percentFormat = "#,###'%'";
    this.root = am5.Root.new(element, rootConfig);
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.processedConfig = chartConfig?.(this.root);
    this.valueYAccessor = valueYAccessor;
    this.valueXAccessor = valueXAccessor;
    this.categoryAccessor = categoryAccessor;
    this.replacementValues = replacementValues;
    this.chart = this.root.container.children.push(
      type.new(this.root, {
        ...(config ?? {}),
        ...(this.processedConfig ?? {}),
      })
    );

    if (cb) {
      this.handler = () => {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.root.events.off('frameended', this.handler);
          cb(this);
        }, 100);
      };

      this.root.events.on('frameended', this.handler);
    }
  }

  setNumberFormat = (template) => {
    this.root.numberFormatter.set(
      'numberFormat',
      template ?? this.defaultFormat
    );
  };

  configureExpandingScrollbarHorizontal = (enabled = true) => {
    const scrollbar = this.chart.get('scrollbarX');

    scrollbar.startGrip.setAll({
      visible: false,
    });

    scrollbar.endGrip.setAll({
      visible: false,
    });

    scrollbar.thumb.setAll({
      width: 4,
      fill: grayScale.mediumDark,
      height: 4,
      maxHeight: 4,
    });

    scrollbar.get('background').setAll({
      fillOpacity: 0,
    });

    scrollbar.show();
    scrollbar.thumb.hide();

    scrollbar.events.on('pointerover', () => {
      scrollbar.thumb.setAll({
        height: 8,
        maxHeight: 8,
      });
    });

    scrollbar.thumb.events.on('dragstart', () => {
      this.isHorizontalScrollbarDragging = true;
    });

    scrollbar.thumb.events.on('dragstop', () => {
      this.isHorizontalScrollbarDragging = false;
      if (!this.isHorizontalScrollbarHovered) {
        scrollbar.thumb.setAll({
          height: 4,
          maxHeight: 4,
        });
        scrollbar.thumb.hide();
      }
    });

    scrollbar.events.on('pointerout', () => {
      if (!this.isHorizontalScrollbarDragging) {
        scrollbar.thumb.setAll({
          height: 4,
          maxHeight: 4,
        });
      }
    });

    if (enabled) {
      this.root.dom.addEventListener('pointerover', () => {
        this.isHorizontalScrollbarHovered = true;
        scrollbar.thumb.show();
      });

      this.root.dom.addEventListener('pointerout', () => {
        this.isHorizontalScrollbarHovered = false;
        if (!this.isHorizontalScrollbarDragging) {
          scrollbar.thumb.hide();
        }
      });
    }
  };

  createStyledTooltip = (
    labelText,
    opts = {},
    labelOpts = {},
    backgroundOpts = {}
  ) => {
    const tooltip = am5.Tooltip.new(this.root, {
      labelText: labelText,
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      ...opts,
    });

    tooltip.get('background').setAll({
      fill: grayScale.dark,
      fillOpacity: 1,
      cornerRadius: 4,
      stroke: grayScale.dark,
      strokeOpacity: 0,
      strokeWidth: 1,
      ...backgroundOpts,
    });

    tooltip.label.setAll({
      fill: grayScale.white,
      ...labelOpts,
    });

    return tooltip;
  };

  getMilliseconds = (date) => {
    const [year, month, day] = date.split('-');
    const time = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day),
      0,
      0,
      0,
      0
    );
    return time.getTime();
  };

  show = () => {
    this.assertSeries('visibility');
    this.assertChart('visibility');

    this.series.appear(1000, 100);
    this.chart.appear(1000, 100);
  };

  destroy = () => {
    return this.root.dispose();
  };

  assertRoot = (entity = 'entity') => {
    if (!this.root) {
      throw new Error(`Cannot create ${entity} without a valid root`);
    }
  };

  assertChart = (entity = 'entity') => {
    if (!this.chart) {
      throw new Error(`Cannot create ${entity} without a valid chart`);
    }
  };

  assertSeries = (entity = 'entity') => {
    if (!this.series) {
      throw new Error(`Cannot create ${entity} without a valid series`);
    }
  };

  assertYAxis = (entity = 'entity') => {
    if (!this.yAxis) {
      throw new Error(`Cannot create ${entity} without a valid y-axis`);
    }
  };

  assertXAxis = (entity = 'entity') => {
    if (!this.xAxis) {
      throw new Error(`Cannot create ${entity} without a valid x-axis`);
    }
  };

  assertYAxisTitle = (entity = 'entity') => {
    if (!this.assertYAxisTitle) {
      throw new Error(`Cannot create ${entity} without a valid y-axis title`);
    }
  };
}

export default ChartManager;
