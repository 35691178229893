import { useCallback, useRef } from 'react';

export const useSummaryScroll = (showSummary) => {
  const scrollRef = useRef(showSummary);

  if (!showSummary) {
    scrollRef.current = false;
  }

  return useCallback((el) => {
    if (!scrollRef.current && el && 'scrollIntoView' in el) {
      el.scrollIntoView();
      scrollRef.current = true;
    }
  }, []);
};
