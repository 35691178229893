import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FIELD_TYPES } from 'utility/constants';

import EmailAddressInline from 'components/Inputs/inline/TextInput/presets/EmailAddress';
import { withCellWrapper } from './helpers';

const _EmailCell = forwardRef(
  (
    {
      field,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <EmailAddressInline
        ref={ref}
        {...others}
        field={field}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        placeholder={FIELD_TYPES.Email.localizedPlaceholder(t)}
      />
    );
  }
);

export const EmailCell = withCellWrapper(_EmailCell);
