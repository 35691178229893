export default [
  {
    name: 'phone',
    className: 'fa-light fa-phone',
  },
  {
    name: 'envelope',
    className: 'fa-light fa-envelope',
  },
  {
    name: 'pen-nib',
    className: 'fa-light fa-pen-nib',
  },
  {
    name: 'calendar-days',
    className: 'fa-light fa-calendar-days',
  },
  {
    name: 'paperclip',
    className: 'fa-light fa-paperclip',
  },
  {
    name: 'file',
    className: 'fa-light fa-file',
  },
  {
    name: 'clipboard',
    className: 'fa-light fa-clipboard',
  },
  {
    name: 'pen',
    className: 'fa-light fa-pen',
  },
  {
    name: 'tag',
    className: 'fa-light fa-tag',
  },
  {
    name: 'book',
    className: 'fa-light fa-book',
  },
  {
    name: 'print',
    className: 'fa-light fa-print',
  },
  {
    name: 'pen-to-square',
    className: 'fa-light fa-pen-to-square',
  },
  {
    name: 'folder',
    className: 'fa-light fa-folder',
  },
  {
    name: 'folder-open',
    className: 'fa-light fa-folder-open',
  },
  {
    name: 'thumbtack',
    className: 'fa-light fa-thumbtack',
  },
  {
    name: 'globe',
    className: 'fa-light fa-globe',
  },
  {
    name: 'city',
    className: 'fa-light fa-city',
  },
  {
    name: 'briefcase',
    className: 'fa-light fa-briefcase',
  },
  {
    name: 'compass',
    className: 'fa-light fa-compass',
  },
  {
    name: 'address-book',
    className: 'fa-light fa-address-book',
  },
  {
    name: 'business-time',
    className: 'fa-light fa-business-time',
  },
  {
    name: 'computer-classic',
    className: 'fa-light fa-computer-classic',
  },
  {
    name: 'pen-fancy',
    className: 'fa-light fa-pen-fancy',
  },
  {
    name: 'badge-check',
    className: 'fa-light fa-badge-check',
  },
  {
    name: 'mug-saucer',
    className: 'fa-light fa-mug-saucer',
  },
  {
    name: 'landmark',
    className: 'fa-light fa-landmark',
  },
  {
    name: 'chart-simple',
    className: 'fa-light fa-chart-simple',
  },
  {
    name: 'envelopes',
    className: 'fa-light fa-envelopes',
  },
  {
    name: 'diagram-venn',
    className: 'fa-light fa-diagram-venn',
  },
  {
    name: 'calendar',
    className: 'fa-light fa-calendar',
  },
  {
    name: 'pencil',
    className: 'fa-light fa-pencil',
  },
  {
    name: 'phone-volume',
    className: 'fa-light fa-phone-volume',
  },
  {
    name: 'copy',
    className: 'fa-light fa-copy',
  },
  {
    name: 'table',
    className: 'fa-light fa-table',
  },
  {
    name: 'building',
    className: 'fa-light fa-building',
  },
  {
    name: 'network-wired',
    className: 'fa-light fa-network-wired',
  },
  {
    name: 'address-card',
    className: 'fa-light fa-address-card',
  },
  {
    name: 'tags',
    className: 'fa-light fa-tags',
  },
  {
    name: 'registered',
    className: 'fa-light fa-registered',
  },
  {
    name: 'signature',
    className: 'fa-light fa-signature',
  },
  {
    name: 'phone-office',
    className: 'fa-light fa-phone-office',
  },
  {
    name: 'projector',
    className: 'fa-light fa-projector',
  },
  {
    name: 'sitemap',
    className: 'fa-light fa-sitemap',
  },
  {
    name: 'marker',
    className: 'fa-light fa-marker',
  },
  {
    name: 'badge',
    className: 'fa-light fa-badge',
  },
  {
    name: 'wallet',
    className: 'fa-light fa-wallet',
  },
  {
    name: 'copyright',
    className: 'fa-light fa-copyright',
  },
  {
    name: 'bullseye',
    className: 'fa-light fa-bullseye',
  },
  {
    name: 'calculator',
    className: 'fa-light fa-calculator',
  },
  {
    name: 'certificate',
    className: 'fa-light fa-certificate',
  },
  {
    name: 'percent',
    className: 'fa-light fa-percent',
  },
  {
    name: 'eraser',
    className: 'fa-light fa-eraser',
  },
  {
    name: 'file-spreadsheet',
    className: 'fa-light fa-file-spreadsheet',
  },
  {
    name: 'glasses',
    className: 'fa-light fa-glasses',
  },
  {
    name: 'industry',
    className: 'fa-light fa-industry',
  },
  {
    name: 'vault',
    className: 'fa-light fa-vault',
  },
  {
    name: 'user-tie-hair-long',
    className: 'fa-light fa-user-tie-hair-long',
  },
  {
    name: 'user-tie-hair',
    className: 'fa-light fa-user-tie-hair',
  },
  {
    name: 'user-hair-mullet',
    className: 'fa-light fa-user-hair-mullet',
  },
  {
    name: 'trademark',
    className: 'fa-light fa-trademark',
  },
  {
    name: 'timeline-arrow',
    className: 'fa-light fa-timeline-arrow',
  },
  {
    name: 'timeline',
    className: 'fa-light fa-timeline',
  },
  {
    name: 'table-tree',
    className: 'fa-light fa-table-tree',
  },
  {
    name: 'table-rows',
    className: 'fa-light fa-table-rows',
  },
  {
    name: 'table-pivot',
    className: 'fa-light fa-table-pivot',
  },
  {
    name: 'table-layout',
    className: 'fa-light fa-table-layout',
  },
  {
    name: 'table-columns',
    className: 'fa-light fa-table-columns',
  },
  {
    name: 'stapler',
    className: 'fa-light fa-stapler',
  },
  {
    name: 'square-poll-vertical',
    className: 'fa-light fa-square-poll-vertical',
  },
  {
    name: 'square-poll-horizontal',
    className: 'fa-light fa-square-poll-horizontal',
  },
  {
    name: 'square-phone-flip',
    className: 'fa-light fa-square-phone-flip',
  },
  {
    name: 'square-phone',
    className: 'fa-light fa-square-phone',
  },
  {
    name: 'square-pen',
    className: 'fa-light fa-square-pen',
  },
  {
    name: 'square-kanban',
    className: 'fa-light fa-square-kanban',
  },
  {
    name: 'square-envelope',
    className: 'fa-light fa-square-envelope',
  },
  {
    name: 'socks',
    className: 'fa-light fa-socks',
  },
  {
    name: 'slot-machine',
    className: 'fa-light fa-slot-machine',
  },
  {
    name: 'signature-slash',
    className: 'fa-light fa-signature-slash',
  },
  {
    name: 'signature-lock',
    className: 'fa-light fa-signature-lock',
  },
  {
    name: 'shredder',
    className: 'fa-light fa-shredder',
  },
  {
    name: 'scissors',
    className: 'fa-light fa-scissors',
  },
  {
    name: 'scanner-image',
    className: 'fa-light fa-scanner-image',
  },
  {
    name: 'scale-unbalanced-flip',
    className: 'fa-light fa-scale-unbalanced-flip',
  },
  {
    name: 'scale-unbalanced',
    className: 'fa-light fa-scale-unbalanced',
  },
  {
    name: 'scale-balanced',
    className: 'fa-light fa-scale-balanced',
  },
  {
    name: 'router',
    className: 'fa-light fa-router',
  },
  {
    name: 'rectangle-pro',
    className: 'fa-light fa-rectangle-pro',
  },
  {
    name: 'print-slash',
    className: 'fa-light fa-print-slash',
  },
  {
    name: 'print-magnifying-glass',
    className: 'fa-light fa-print-magnifying-glass',
  },
  {
    name: 'presentation-screen',
    className: 'fa-light fa-presentation-screen',
  },
  {
    name: 'podium',
    className: 'fa-light fa-podium',
  },
  {
    name: 'phone-slash',
    className: 'fa-light fa-phone-slash',
  },
  {
    name: 'phone-intercom',
    className: 'fa-light fa-phone-intercom',
  },
  {
    name: 'phone-flip',
    className: 'fa-light fa-phone-flip',
  },
  {
    name: 'person-chalkboard',
    className: 'fa-light fa-person-chalkboard',
  },
  {
    name: 'pen-nib-slash',
    className: 'fa-light fa-pen-nib-slash',
  },
  {
    name: 'pen-clip',
    className: 'fa-light fa-pen-clip',
  },
  {
    name: 'paste',
    className: 'fa-light fa-paste',
  },
  {
    name: 'paperclip-vertical',
    className: 'fa-light fa-paperclip-vertical',
  },
  {
    name: 'notebook',
    className: 'fa-light fa-notebook',
  },
  {
    name: 'note-sticky',
    className: 'fa-light fa-note-sticky',
  },
  {
    name: 'money-check-pen',
    className: 'fa-light fa-money-check-pen',
  },
  {
    name: 'money-check-dollar-pen',
    className: 'fa-light fa-money-check-dollar-pen',
  },
  {
    name: 'magnifying-glass-chart',
    className: 'fa-light fa-magnifying-glass-chart',
  },
  {
    name: 'magnifying-glass-arrow-right',
    className: 'fa-light fa-magnifying-glass-arrow-right',
  },
  {
    name: 'list-tree',
    className: 'fa-light fa-list-tree',
  },
  {
    name: 'list-timeline',
    className: 'fa-light fa-list-timeline',
  },
  {
    name: 'list-radio',
    className: 'fa-light fa-list-radio',
  },
  {
    name: 'list-dropdown',
    className: 'fa-light fa-list-dropdown',
  },
  {
    name: 'list-check',
    className: 'fa-light fa-list-check',
  },
  {
    name: 'laptop-file',
    className: 'fa-light fa-laptop-file',
  },
  {
    name: 'lamp-desk',
    className: 'fa-light fa-lamp-desk',
  },
  {
    name: 'keynote',
    className: 'fa-light fa-keynote',
  },
  {
    name: 'industry-windows',
    className: 'fa-light fa-industry-windows',
  },
  {
    name: 'inboxes',
    className: 'fa-light fa-inboxes',
  },
  {
    name: 'inbox-full',
    className: 'fa-light fa-inbox-full',
  },
  {
    name: 'house-laptop',
    className: 'fa-light fa-house-laptop',
  },
  {
    name: 'highlighter',
    className: 'fa-light fa-highlighter',
  },
  {
    name: 'folders',
    className: 'fa-light fa-folders',
  },
  {
    name: 'folder-xmark',
    className: 'fa-light fa-folder-xmark',
  },
  {
    name: 'folder-tree',
    className: 'fa-light fa-folder-tree',
  },
  {
    name: 'folder-plus',
    className: 'fa-light fa-folder-plus',
  },
  {
    name: 'folder-minus',
    className: 'fa-light fa-folder-minus',
  },
  {
    name: 'folder-arrow-up',
    className: 'fa-light fa-folder-arrow-up',
  },
  {
    name: 'folder-arrow-down',
    className: 'fa-light fa-folder-arrow-down',
  },
  {
    name: 'floppy-disk-circle-xmark',
    className: 'fa-light fa-floppy-disk-circle-xmark',
  },
  {
    name: 'floppy-disk-circle-arrow-right',
    className: 'fa-light fa-floppy-disk-circle-arrow-right',
  },
  {
    name: 'floppy-disk',
    className: 'fa-light fa-floppy-disk',
  },
  {
    name: 'file-user',
    className: 'fa-light fa-file-user',
  },
  {
    name: 'file-lines',
    className: 'fa-light fa-file-lines',
  },
  {
    name: 'file-circle-plus',
    className: 'fa-light fa-file-circle-plus',
  },
  {
    name: 'file-circle-info',
    className: 'fa-light fa-file-circle-info',
  },
  {
    name: 'file-chart-pie',
    className: 'fa-light fa-file-chart-pie',
  },
  {
    name: 'file-chart-column',
    className: 'fa-light fa-file-chart-column',
  },
  {
    name: 'fax',
    className: 'fa-light fa-fax',
  },
  {
    name: 'envelope-open',
    className: 'fa-light fa-envelope-open',
  },
  {
    name: 'envelope-dot',
    className: 'fa-light fa-envelope-dot',
  },
  {
    name: 'envelope-circle-check',
    className: 'fa-light fa-envelope-circle-check',
  },
  {
    name: 'coffee-pot',
    className: 'fa-light fa-coffee-pot',
  },
  {
    name: 'cloud-word',
    className: 'fa-light fa-cloud-word',
  },
  {
    name: 'clipboard-question',
    className: 'fa-light fa-clipboard-question',
  },
  {
    name: 'chart-user',
    className: 'fa-light fa-chart-user',
  },
  {
    name: 'chart-tree-map',
    className: 'fa-light fa-chart-tree-map',
  },
  {
    name: 'chart-simple-horizontal',
    className: 'fa-light fa-chart-simple-horizontal',
  },
  {
    name: 'chart-pyramid',
    className: 'fa-light fa-chart-pyramid',
  },
  {
    name: 'chart-pie-simple',
    className: 'fa-light fa-chart-pie-simple',
  },
  {
    name: 'chart-pie',
    className: 'fa-light fa-chart-pie',
  },
  {
    name: 'chart-line-up',
    className: 'fa-light fa-chart-line-up',
  },
  {
    name: 'chart-line-down',
    className: 'fa-light fa-chart-line-down',
  },
  {
    name: 'chart-line',
    className: 'fa-light fa-chart-line',
  },
  {
    name: 'cake-candles',
    className: 'fa-light fa-cake-candles',
  },
  {
    name: 'cabinet-filing',
    className: 'fa-light fa-cabinet-filing',
  },
  {
    name: 'bullhorn',
    className: 'fa-light fa-bullhorn',
  },
  {
    name: 'briefcase-blank',
    className: 'fa-light fa-briefcase-blank',
  },
  {
    name: 'briefcase-arrow-right',
    className: 'fa-light fa-briefcase-arrow-right',
  },
  {
    name: 'brain-arrow-curved-right',
    className: 'fa-light fa-brain-arrow-curved-right',
  },
  {
    name: 'boxes-packing',
    className: 'fa-light fa-boxes-packing',
  },
  {
    name: 'box-archive',
    className: 'fa-light fa-box-archive',
  },
  {
    name: 'book-section',
    className: 'fa-light fa-book-section',
  },
  {
    name: 'bars-staggered',
    className: 'fa-light fa-bars-staggered',
  },
  {
    name: 'bars-progress',
    className: 'fa-light fa-bars-progress',
  },
  {
    name: 'badge-percent',
    className: 'fa-light fa-badge-percent',
  },
  {
    name: 'badge-dollar',
    className: 'fa-light fa-badge-dollar',
  },
  {
    name: 'arrows-to-eye',
    className: 'fa-light fa-arrows-to-eye',
  },
  {
    name: 'arrows-to-dot',
    className: 'fa-light fa-arrows-to-dot',
  },
  {
    name: 'arrows-spin',
    className: 'fa-light fa-arrows-spin',
  },
  {
    name: 'sparkles',
    className: 'fa-light fa-sparkles',
  },
  {
    name: 'megaphone',
    className: 'fa-light fa-megaphone',
  },
  {
    name: 'block-question',
    className: 'fa-light fa-block-question',
  },
  {
    name: 'lightbulb-slash',
    className: 'fa-light fa-lightbulb-slash',
  },
  {
    name: 'comments-dollar',
    className: 'fa-light fa-comments-dollar',
  },
  {
    name: 'comments',
    className: 'fa-light fa-comments',
  },
  {
    name: 'bullseye-pointer',
    className: 'fa-light fa-bullseye-pointer',
  },
  {
    name: 'lightbulb',
    className: 'fa-light fa-lightbulb',
  },
  {
    name: 'user-group-crown',
    className: 'fa-light fa-user-group-crown',
  },
  {
    name: 'user-crown',
    className: 'fa-light fa-user-crown',
  },
  {
    name: 'signal-stream-slash',
    className: 'fa-light fa-signal-stream-slash',
  },
  {
    name: 'signal-stream',
    className: 'fa-light fa-signal-stream',
  },
  {
    name: 'rectangle-ad',
    className: 'fa-light fa-rectangle-ad',
  },
  {
    name: 'ranking-star',
    className: 'fa-light fa-ranking-star',
  },
  {
    name: 'person-rays',
    className: 'fa-light fa-person-rays',
  },
  {
    name: 'people-group',
    className: 'fa-light fa-people-group',
  },
  {
    name: 'messages-dollar',
    className: 'fa-light fa-messages-dollar',
  },
  {
    name: 'message-dollar',
    className: 'fa-light fa-message-dollar',
  },
  {
    name: 'magnifying-glass-location',
    className: 'fa-light fa-magnifying-glass-location',
  },
  {
    name: 'magnifying-glass-dollar',
    className: 'fa-light fa-magnifying-glass-dollar',
  },
  {
    name: 'lightbulb-on',
    className: 'fa-light fa-lightbulb-on',
  },
  {
    name: 'lightbulb-exclamation',
    className: 'fa-light fa-lightbulb-exclamation',
  },
  {
    name: 'lightbulb-dollar',
    className: 'fa-light fa-lightbulb-dollar',
  },
  {
    name: 'lightbulb-cfl-on',
    className: 'fa-light fa-lightbulb-cfl-on',
  },
  {
    name: 'lightbulb-cfl',
    className: 'fa-light fa-lightbulb-cfl',
  },
  {
    name: 'hundred-points',
    className: 'fa-light fa-hundred-points',
  },
  {
    name: 'group-arrows-rotate',
    className: 'fa-light fa-group-arrows-rotate',
  },
  {
    name: 'gift-card',
    className: 'fa-light fa-gift-card',
  },
  {
    name: 'filter-circle-dollar',
    className: 'fa-light fa-filter-circle-dollar',
  },
  {
    name: 'envelopes-bulk',
    className: 'fa-light fa-envelopes-bulk',
  },
  {
    name: 'envelope-open-text',
    className: 'fa-light fa-envelope-open-text',
  },
  {
    name: 'envelope-open-dollar',
    className: 'fa-light fa-envelope-open-dollar',
  },
  {
    name: 'comment-dollar',
    className: 'fa-light fa-comment-dollar',
  },
  {
    name: 'chart-mixed',
    className: 'fa-light fa-chart-mixed',
  },
  {
    name: 'bullseye-arrow',
    className: 'fa-light fa-bullseye-arrow',
  },
  {
    name: 'star',
    className: 'fa-light fa-star',
  },
  {
    name: 'heart',
    className: 'fa-light fa-heart',
  },
  {
    name: 'camera-retro',
    className: 'fa-light fa-camera-retro',
  },
  {
    name: 'truck-fast',
    className: 'fa-light fa-truck-fast',
  },
  {
    name: 'bell',
    className: 'fa-light fa-bell',
  },
  {
    name: 'cart-shopping',
    className: 'fa-light fa-cart-shopping',
  },
  {
    name: 'gift',
    className: 'fa-light fa-gift',
  },
  {
    name: 'barcode',
    className: 'fa-light fa-barcode',
  },
  {
    name: 'bookmark',
    className: 'fa-light fa-bookmark',
  },
  {
    name: 'camera',
    className: 'fa-light fa-camera',
  },
  {
    name: 'thumbs-up',
    className: 'fa-light fa-thumbs-up',
  },
  {
    name: 'thumbs-down',
    className: 'fa-light fa-thumbs-down',
  },
  {
    name: 'key',
    className: 'fa-light fa-key',
  },
  {
    name: 'truck',
    className: 'fa-light fa-truck',
  },
  {
    name: 'balloons',
    className: 'fa-light fa-balloons',
  },
  {
    name: 'handshake',
    className: 'fa-light fa-handshake',
  },
  {
    name: 'shop',
    className: 'fa-light fa-shop',
  },
  {
    name: 'shirt',
    className: 'fa-light fa-shirt',
  },
  {
    name: 'bag-shopping',
    className: 'fa-light fa-bag-shopping',
  },
  {
    name: 'moped',
    className: 'fa-light fa-moped',
  },
  {
    name: 'cart-plus',
    className: 'fa-light fa-cart-plus',
  },
  {
    name: 'credit-card',
    className: 'fa-light fa-credit-card',
  },
  {
    name: 'store',
    className: 'fa-light fa-store',
  },
  {
    name: 'cash-register',
    className: 'fa-light fa-cash-register',
  },
  {
    name: 'booth-curtain',
    className: 'fa-light fa-booth-curtain',
  },
  {
    name: 'trophy',
    className: 'fa-light fa-trophy',
  },
  {
    name: 'receipt',
    className: 'fa-light fa-receipt',
  },
  {
    name: 'truck-container',
    className: 'fa-light fa-truck-container',
  },
  {
    name: 'truck-clock',
    className: 'fa-light fa-truck-clock',
  },
  {
    name: 'truck-bolt',
    className: 'fa-light fa-truck-bolt',
  },
  {
    name: 'trophy-star',
    className: 'fa-light fa-trophy-star',
  },
  {
    name: 'store-slash',
    className: 'fa-light fa-store-slash',
  },
  {
    name: 'store-lock',
    className: 'fa-light fa-store-lock',
  },
  {
    name: 'star-exclamation',
    className: 'fa-light fa-star-exclamation',
  },
  {
    name: 'shop-slash',
    className: 'fa-light fa-shop-slash',
  },
  {
    name: 'shop-lock',
    className: 'fa-light fa-shop-lock',
  },
  {
    name: 'rectangle-barcode',
    className: 'fa-light fa-rectangle-barcode',
  },
  {
    name: 'person-booth',
    className: 'fa-light fa-person-booth',
  },
  {
    name: 'nfc-trash',
    className: 'fa-light fa-nfc-trash',
  },
  {
    name: 'nfc-symbol',
    className: 'fa-light fa-nfc-symbol',
  },
  {
    name: 'nfc-slash',
    className: 'fa-light fa-nfc-slash',
  },
  {
    name: 'nfc-signal',
    className: 'fa-light fa-nfc-signal',
  },
  {
    name: 'nfc-pen',
    className: 'fa-light fa-nfc-pen',
  },
  {
    name: 'nfc-magnifying-glass',
    className: 'fa-light fa-nfc-magnifying-glass',
  },
  {
    name: 'nfc-lock',
    className: 'fa-light fa-nfc-lock',
  },
  {
    name: 'nfc',
    className: 'fa-light fa-nfc',
  },
  {
    name: 'money-check-dollar',
    className: 'fa-light fa-money-check-dollar',
  },
  {
    name: 'money-check',
    className: 'fa-light fa-money-check',
  },
  {
    name: 'mobile-signal-out',
    className: 'fa-light fa-mobile-signal-out',
  },
  {
    name: 'mobile-signal',
    className: 'fa-light fa-mobile-signal',
  },
  {
    name: 'hexagon-vertical-nft-slanted',
    className: 'fa-light fa-hexagon-vertical-nft-slanted',
  },
  {
    name: 'hexagon-vertical-nft',
    className: 'fa-light fa-hexagon-vertical-nft',
  },
  {
    name: 'hexagon-image',
    className: 'fa-light fa-hexagon-image',
  },
  {
    name: 'hand-holding-box',
    className: 'fa-light fa-hand-holding-box',
  },
  {
    name: 'gifts',
    className: 'fa-light fa-gifts',
  },
  {
    name: 'gem',
    className: 'fa-light fa-gem',
  },
  {
    name: 'credit-card-front',
    className: 'fa-light fa-credit-card-front',
  },
  {
    name: 'credit-card-blank',
    className: 'fa-light fa-credit-card-blank',
  },
  {
    name: 'cart-xmark',
    className: 'fa-light fa-cart-xmark',
  },
  {
    name: 'cart-shopping-fast',
    className: 'fa-light fa-cart-shopping-fast',
  },
  {
    name: 'cart-minus',
    className: 'fa-light fa-cart-minus',
  },
  {
    name: 'cart-circle-xmark',
    className: 'fa-light fa-cart-circle-xmark',
  },
  {
    name: 'cart-circle-plus',
    className: 'fa-light fa-cart-circle-plus',
  },
  {
    name: 'cart-circle-exclamation',
    className: 'fa-light fa-cart-circle-exclamation',
  },
  {
    name: 'cart-circle-check',
    className: 'fa-light fa-cart-circle-check',
  },
  {
    name: 'cart-circle-arrow-up',
    className: 'fa-light fa-cart-circle-arrow-up',
  },
  {
    name: 'cart-circle-arrow-down',
    className: 'fa-light fa-cart-circle-arrow-down',
  },
  {
    name: 'cart-arrow-up',
    className: 'fa-light fa-cart-arrow-up',
  },
  {
    name: 'cart-arrow-down',
    className: 'fa-light fa-cart-arrow-down',
  },
  {
    name: 'box-open-full',
    className: 'fa-light fa-box-open-full',
  },
  {
    name: 'basket-shopping-simple',
    className: 'fa-light fa-basket-shopping-simple',
  },
  {
    name: 'basket-shopping',
    className: 'fa-light fa-basket-shopping',
  },
  {
    name: 'barcode-scan',
    className: 'fa-light fa-barcode-scan',
  },
  {
    name: 'barcode-read',
    className: 'fa-light fa-barcode-read',
  },
  {
    name: 'balloon',
    className: 'fa-light fa-balloon',
  },
  {
    name: 'bags-shopping',
    className: 'fa-light fa-bags-shopping',
  },
  {
    name: 'arrows-to-circle',
    className: 'fa-light fa-arrows-to-circle',
  },
];
