import styled from '@emotion/styled';
import { fontWeights } from 'app/typography';
import KizenTypography from 'app/kizentypo';
import { gutters, breakpoints, layers } from 'app/spacing';
import { css } from '@emotion/core';
import Button from 'components/Button';
import ButtonWithDropdown from 'components/Button/WithDropdown';
import { colorsButton, grayScale, shadowLight } from 'app/colors';

import {
  pageSizingCss,
  contentWidthCss,
} from 'components/Layout/PageContentWidth';
import LoadingButton from 'components/Button/LoadingButton';

export const centerNameBreak = 1086;

export const contentWidthStyles = css`
  width: 100%;
  padding: 0 ${gutters.standard};

  @media (min-width: ${breakpoints.md + 1}px) {
    ${contentWidthCss}
  }
`;

export const ToolbarWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: ${layers.content(
    0,
    2
  )}; // Ensure contents incl menus appear on top of table header
  ${pageSizingCss}
  margin: 0!important;
  background-color: ${grayScale.white};
  padding: ${gutters.spacing(1)}px 0 ${gutters.spacing(1, -2)}px 0; // the button forces the div to be 46px high, the category name is 18px high -4 for leadingand the requirement is 20px above and below 54-46/2

  @media (min-width: ${breakpoints.md + 1}px) {
    background-color: unset;
    padding: ${gutters.spacing(4)}px;
    ${({ scrolled }) =>
      scrolled &&
      css`
        background-color: ${grayScale.white};
        border-bottom: 1px solid ${grayScale.mediumLight};
        ${shadowLight};
      `}
  }

  transition: 0.5s ease-in-out;
  transition-property: background-color;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${contentWidthStyles};

  ${({ scrolled }) =>
    scrolled &&
    css`
      background-color: ${grayScale.white};
    `}
  transition: 0.5s ease-in-out;
  transition-property: background-color;
  > p {
    margin: 0 ${gutters.standard};
    text-align: center;
  }

  @media (min-width: ${breakpoints.md + 1}px) {
    padding: 0;
  }
`;

export const ToolbarLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  > * {
    &:not(:last-child) {
      margin-right: ${gutters.spacing(7)}px;
    }
  }
  @media (min-width: ${breakpoints.md + 1}px) {
    display: flex;
    flex: 0 0 51px;
    > button {
      margin-right: 0;
      padding-right: 0;
    }
  }
  ${({ nameBreakpoint }) => {
    return css`
      @media (min-width: ${nameBreakpoint + 1}px) {
        flex: 1;
      }
    `;
  }}
`;

export const ToolbarRight = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: ${breakpoints.md + 1}px) {
    justify-content: flex-end;
    flex: 0 1 350px;
  }
  ${({ nameBreakpoint }) => {
    return css`
      @media (min-width: ${nameBreakpoint + 1}px) {
        justify-content: flex-end;
        flex: 1;
      }
    `;
  }}

  flex: 1;
  && > * {
    button {
      // So text doesn't wrap outside the button
      line-height: 1;
    }
  }
`;

export const SaveButton = styled(Button)`
  margin-right: 0;
  padding-right: 0;
  justify-content: 'flex-end';
`;

export const DesktopContent = styled.div`
  display: none;
  font-size: inherit;

  @media (min-width: ${breakpoints.md + 1}px) {
    display: block;
  }
`;

export const DesktopContactContent = styled.div`
  display: none;
  min-width: 1px; // enable cross browser text-overflow on child
  @media (min-width: ${breakpoints.md + 1}px) {
    display: flex;
    justify-content: center;
    flex: 1;
  }
  ${({ nameBreakpoint }) => {
    return css`
      @media (min-width: ${nameBreakpoint + 1}px) {
        display: block;
        flex: unset;
      }
    `;
  }}
`;

export const desktopOnlyButtonCSS = css`
  display: none;
  @media (min-width: ${breakpoints.md + 1}px) {
    display: flex;
  }
`;

export const DesktopOnlyButton = styled(LoadingButton)`
  ${desktopOnlyButtonCSS}
`;

export const DesktopOnlyButtonWithDropdown = styled(ButtonWithDropdown)`
  ${desktopOnlyButtonCSS}
`;

export const MobileContent = styled.div`
  @media (min-width: ${breakpoints.md + 1}px) {
    display: none;
  }
`;

export const Header = styled(KizenTypography)`
  text-align: center;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${fontWeights.regular};
`;

// The rest of these styles were added for this page
export const NameWrapper = styled.div`
  min-width: 1px; // enable cross browser text-overflow on child
  margin: 0 ${gutters.standard};
  display: flex;
  flex: 0 1 300px;
  justify-content: center;
`;

export const Name = styled(KizenTypography)`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${grayScale.white};

  ${contentWidthStyles};

  padding-top: 0;
  padding-bottom: ${gutters.spacing(2)}px;

  @media (min-width: ${breakpoints.md + 1}px) {
    padding-top: ${gutters.standard};
    padding-bottom: ${gutters.standard};
  }
`;

// This animation fades out the action bar & makes 4 dots "fly" to the 3 dot menu once the page is scrolled
export const DOTS_START_Y = 38;
export const A_XOFFSET = 14 - 150 + 50;
export const B_XOFFSET = 14 - 100;
export const C_XOFFSET = 14 - 50;
export const D_XOFFSET = 14;
export const ThreeDots = styled.div`
  padding-left: ${gutters.spacing(3)}px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 100%;
  width: 20px;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  transition: opacity 0.6s ease-in-out;

  > i {
    flex: 1;
  }

  > div {
    // flying dots
    left: 10px;
    top: 17px;
    &.a {
      transform: translate3d(${A_XOFFSET}px, ${DOTS_START_Y}px, 0px);
    }
    &.b {
      transform: translate3d(${B_XOFFSET}px, ${DOTS_START_Y}px, 0px);
    }
    &.c {
      transform: translate3d(${C_XOFFSET}px, ${DOTS_START_Y}px, 0px);
    }
    &.d {
      transform: translate3d(${D_XOFFSET}px, ${DOTS_START_Y}px, 0px);
    }
  }
  &.is-scrolled {
    opacity: 1;
    cursor: pointer;
    > div {
      background-color: transparent;
      width: 4px;
      height: 4px;
      transform: translate3d(${0}px, ${0}px, 0px);
    }
  }
`;

export const FlyingDot = styled.div`
  position: absolute;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: ${colorsButton.iconGray.default};

  transition:
    width 0.6s,
    height 0.6s,
    transform 0.6s,
    background-color 0.6s;
  transition-function: ease-in-out;
`;
