import {
  PrimaryObjectUploadContextType,
  defaultPrimaryObjectUploadContext,
  defaultSmartConnectorFlow,
} from '../context';
import { useAsync, useAsyncFn } from 'react-use';
import { useHistory, useParams } from 'react-router-dom';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  PrimaryObjectUploadStep,
  SmartConnector,
  SmartConnectorBase,
  SmartConnectorFlow,
  SmartConnectorFlowErrors,
  SmartConnectorMetaData,
  SmartConnectorVariable,
} from 'pages/SmartConnectors/types';
import {
  PRIMARY_OBJECT_UPLOAD_STEPS,
  PRIMARY_OBJECT_UPLOAD_STEP_DATA_BY_STEP,
  SMARTCONNECTORS_PATHNAME,
} from 'pages/SmartConnectors/constants';
import SmartConnectorService from 'services/SmartConnectorService';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { getSmartConnectorFlowLoadsDTO, getStepData } from '../helpers';
import CustomObjectsService from 'services/CustomObjectsService';
import { defaultSmartConnector } from '__pages/SmartConnectors/SmartConnector/context';
import { KizenError } from '__services/errors';
import { getSmartConnectorVariables } from '__pages/SmartConnectors/SmartConnector/helpers';
import { useSmartConnectorTitle } from '__pages/SmartConnectors/SmartConnector/hooks/useSmartConnectorTitle';

export const usePrimaryObjectUploadContext =
  (): PrimaryObjectUploadContextType => {
    const { id, customObjectId, loadId } = useParams<{
      id: string;
      customObjectId: string;
      loadId: string;
    }>();
    const history = useHistory<{ index: number }>();

    if (!loadId) {
      throw new KizenError(404);
    }

    // state //////////////////////////////////////////////////////////

    const [smartConnector, setSmartConnector] = useState<
      SmartConnectorBase | SmartConnector
    >(defaultSmartConnector);

    const [step, setStep] = useState<PrimaryObjectUploadStep>(
      PRIMARY_OBJECT_UPLOAD_STEPS.matching
    );

    const [nextStep, setNextStep] = useState<PrimaryObjectUploadStep>(
      PRIMARY_OBJECT_UPLOAD_STEPS.matching
    );

    const [isDirty, setIsDirty] = useState<boolean>(false);

    const [isValid, setIsValid] = useState<boolean>(true);

    const [refreshing, setRefreshing] = useState<boolean>(false);

    const [stepData, setStepData] = useState<Partial<SmartConnectorFlow>>({});

    const [errors, setErrors] = useState<SmartConnectorFlowErrors>({});

    // refs //////////////////////////////////////////////////////////

    const isNew = useRef<boolean>(id === 'new');

    const smartConnectorRef = useRef(smartConnector);
    smartConnectorRef.current = smartConnector;

    const stepRef = useRef(step);
    stepRef.current = step;

    const stepDataRef = useRef(stepData);
    stepDataRef.current = stepData;

    // effects /////////////////////////////////////////////////////////

    useSmartConnectorTitle(smartConnector);

    const [{ loading }, fetchSmartConnector] = useAsyncFn(
      async () => {
        const { data } = await SmartConnectorService.getSmartConnector(id);
        const smartConnector = {
          ...data,
          flow: data.flow || defaultSmartConnectorFlow,
        };

        isNew.current = loadId === 'new';
        setSmartConnector(smartConnector);

        const loads = [...(smartConnector.flow.loads || [])];

        if (isNew.current) {
          loads.splice(history.location.state?.index || 0, 0, {
            custom_object: customObjectId,
            type: 'csv_load',
            order: 0,
            field_mapping_rules: [],
            matching_rules: [],
          });
        }

        setStepData(
          getStepData(
            {
              ...smartConnector.flow,
              loads,
            },
            stepRef.current
          )
        );
        return data;
      },
      [id, customObjectId],
      { loading: true }
    );

    const {
      value: metaData = defaultPrimaryObjectUploadContext.metaData,
      loading: loadingMetaData,
    } = useAsync<() => Promise<SmartConnectorMetaData>>(async () => {
      const { data } = await SmartConnectorService.getSmartConnectorMetaData();
      return data;
    });

    const { value: customObject, loading: loadingObject } =
      useAsync(async () => {
        return CustomObjectsService.getCustomObjectDetails(customObjectId);
      }, [customObjectId]);

    useEffect(() => {
      fetchSmartConnector();
    }, [fetchSmartConnector]);

    useLayoutEffect(() => {
      setNextStep(step);
      //initiates stepData and resets errors for each step
      setStepData(getStepData(smartConnectorRef.current.flow, step));
      setErrors({});
    }, [step]);

    useEffect(() => {
      const fields = PRIMARY_OBJECT_UPLOAD_STEP_DATA_BY_STEP[step];
      const isDirty = fields.some((field: keyof SmartConnectorFlow) => {
        return !isEqual(smartConnector.flow[field], stepData[field]);
      });
      setIsDirty(isDirty);
    }, [smartConnector, step, stepData]);

    // handlers //////////////////////////////////////////////////////////

    const handleGoToStep = useCallback(
      (step: PrimaryObjectUploadStep) => {
        if (isDirty) {
          setNextStep(step);
        } else {
          setStep(step);
        }
      },
      [isDirty]
    );

    const handleSave = useCallback(
      async (withClose: boolean): Promise<boolean> => {
        try {
          setRefreshing(true);
          setErrors({});

          const { data } = await SmartConnectorService.updateSmartConnector(
            id,
            getSmartConnectorFlowLoadsDTO(stepDataRef.current, loadId, step),
            { skipErrorBoundary: true }
          );
          setSmartConnector(data);
          setStepData(getStepData(data.flow, step));
          setIsDirty(false);
          if (isNew.current && !withClose) {
            if (step === PRIMARY_OBJECT_UPLOAD_STEPS.matching) {
              setStep(PRIMARY_OBJECT_UPLOAD_STEPS.mapping);

              if (loadId === 'new') {
                setTimeout(() =>
                  history.replace(
                    `${SMARTCONNECTORS_PATHNAME}/${id}/builder/upload-object/${customObjectId}/${data.flow.loads[history.location.state?.index || 0].id}`
                  )
                );
              }
            }
            if (step === PRIMARY_OBJECT_UPLOAD_STEPS.mapping) {
              setStep(PRIMARY_OBJECT_UPLOAD_STEPS.preview);
            }
          }

          if (withClose) {
            setTimeout(() =>
              history.push(`${SMARTCONNECTORS_PATHNAME}/${id}/builder`)
            );
          }

          return true;
        } catch (error) {
          if (error instanceof AxiosError) {
            const axiosError = (
              error as AxiosError<{ flow: SmartConnectorFlowErrors }>
            ).response?.data;

            const errors: SmartConnectorFlowErrors = {};

            if (axiosError?.flow?.additional_variables) {
              errors.additional_variables =
                axiosError.flow.additional_variables;
            }

            if (axiosError?.flow?.loads) {
              errors.loads = axiosError.flow.loads;
            }

            setErrors(errors);
          }
          return false;
        } finally {
          setRefreshing(false);
        }
      },
      [id, step, history, customObjectId, loadId]
    );

    const [allVariables, variablesById] = useMemo(() => {
      const allVariables = getSmartConnectorVariables({
        execution_variables: smartConnector.execution_variables!,
        flow: smartConnector.flow,
      });
      return [
        allVariables,
        allVariables.reduce(
          (acc, variable) => {
            acc[variable.id] = variable;
            return acc;
          },
          {} as Record<string, SmartConnectorVariable>
        ),
      ];
    }, [smartConnector]);

    return {
      smartConnector,
      customObject,
      loadId,
      metaData,
      loading: loading || loadingMetaData || loadingObject,
      refreshing,
      handleGoToStep,
      step,
      setStep,
      nextStep,
      setNextStep,
      isNew: isNew.current,
      isDirty,
      setIsDirty,
      handleSave,
      stepData,
      setStepData,
      errors,
      setErrors,
      isValid,
      setIsValid,
      allVariables,
      variablesById,
    };
  };
