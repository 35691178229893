import styled from '@emotion/styled';
import { appBackground } from '__app/colors';
import { borderRadii } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import Cols from '__components/Layout/Cols';

export const StyledSubtitle = styled(KizenTypography)`
  margin-bottom: 16px;
`;

export const StyledCols = styled(Cols)`
  & p + p {
    margin: 0;
  }
`;

export const StyledNotice = styled(KizenTypography)`
  padding: 10px;
  background-color: ${appBackground};
  margin-bottom: 15px;
  border-radius: ${borderRadii.small};
`;
