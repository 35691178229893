import {
  StaticBlockRoot,
  Section,
  Row,
  Cell,
  Content,
  Button,
  Text,
  Image,
  Divider,
  HTMLBlock,
} from '../nodes';

export default {
  Root: StaticBlockRoot,
  Section,
  Row,
  Cell,
  Content,
  Button,
  Text,
  Image,
  Divider,
  HTMLBlock,
};
