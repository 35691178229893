import { getOptionValue } from 'services/helpers';

const forApi = (step) => {
  const orgDetails = { ...step.details };
  return {
    details: {
      tagsToAdd:
        orgDetails?.tagsToAdd?.map((i) => {
          return {
            id: getOptionValue(i),
          };
        }) ?? [],
      tagsToRemove: orgDetails?.tagsToRemove?.map(getOptionValue) ?? [],
    },
  };
};
const forFlow = (apiObject, addErrorMessage, messageDictionary) => {
  if (!apiObject?.tagsToAdd?.length && !apiObject?.tagsToRemove?.length) {
    addErrorMessage(messageDictionary.thereWasanErrorLoadingTheAssociatedTag);
  }
  return apiObject;
};
export const changeTagsDTO = {
  forApi,
  forFlow,
};
