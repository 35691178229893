import { useMemo } from 'react';
import FieldService from 'services/FieldService';
import CustomObjectsService from 'services/CustomObjectsService';
import PipelineService from 'services/PipelineService';
import { FIELD_TYPES } from 'utility/constants';
import { useQuery } from 'react-query';
import { isPipelineObject } from 'components/Modals/utilities';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

import { monitoringMessageHelper } from 'sentry/helpers';

export const useDynamicTagOptionsForCSVTemplate = (fields, model) => {
  const dynamicTagsFieldsIds = useMemo(() => {
    return fields
      .filter((el) => el.fieldType === FIELD_TYPES.DynamicTags.type)
      .map((el) => el.id);
  }, [fields]);

  const {
    data: dynamicTagsWithOptions = EMPTY_OBJECT,
    isLoading: loadingDynamictagsOptions,
  } = useQuery(dynamicTagsFieldsIds, async () => {
    const Service = model
      ? isPipelineObject(model)
        ? PipelineService
        : CustomObjectsService
      : FieldService;
    const options = await Promise.all(
      dynamicTagsFieldsIds.map(
        (id) =>
          // this should probably be fixed instead of ignored
          // eslint-disable-next-line no-async-promise-executor
          new Promise(async (resolve) => {
            try {
              const { results } = await Service.getDynamicTagsOptions(
                id,
                model?.id,
                1,
                '',
                {
                  params: { page_size: 2 },
                }
              );
              resolve({ id, results });
            } catch (error) {
              monitoringMessageHelper('Getting dynamic_tags for CSV template', {
                extra: { error },
              });
              resolve({ id, results: [] });
            }
          })
      )
    );
    return options.reduce(
      (acc, el) => ({ ...acc, [el.id]: el.results || [] }),
      {}
    );
  });

  return { dynamicTagsWithOptions, loadingDynamictagsOptions };
};
