import { AutomationsPage } from 'pages/Common/Automations/AutomationsPage';

import paths from './paths';

const getRoutes = (t) => {
  const routes = {
    record: {
      label: t('Record'),
      component: null,
      exact: true,
      icon: 'id-card',
      invalidateTimeline: false,
    },
    automations: {
      label: t('Automations'),
      component: AutomationsPage,
      exact: true,
      icon: 'play-light',
      invalidateTimeline: true,
    },
  };

  paths.forEach((path, routeId) => {
    routes[routeId].path = path;
  });

  return routes;
};

export default getRoutes;
