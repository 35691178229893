import * as PropTypes from 'prop-types';

// Use when the prop type is another component
export const ChildShape = PropTypes.oneOfType([PropTypes.func, PropTypes.node]);

const LayoutShape = PropTypes.shape({
  h: PropTypes.number.isRequired,
  minH: PropTypes.number.isRequired,
  minW: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
});

export const DashletShape = PropTypes.shape({
  config: PropTypes.shape({
    chartType: PropTypes.string,
    frequency: PropTypes.string,
    entityId: PropTypes.string,
    entityName: PropTypes.string,
    entityType: PropTypes.string,
    multi: PropTypes.bool,
    teamMemberComparison: PropTypes.bool,
    type: PropTypes.string,
    reportType: PropTypes.string.isRequired,
  }),
  layout: LayoutShape,
  name: PropTypes.string,
  id: PropTypes.string,
});

export const SurveyShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  created: PropTypes.string,
});
