import {
  Fragment,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

import { useFlashTransition } from 'hooks/useFlashState';
import useModal from 'components/Modals/useModal';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { useFilterDropdownContext } from 'pages/Common/components/filterDropdownContext';
import { clearFilterErrors as clearContactFilterErrors } from 'store/contactsPage/actions';
import { clearFilterErrors as clearCOFilterErrors } from 'store/customObjectsRecordsPage/actions';

import Overlay from 'react-bootstrap/Overlay';
import KizenTypography from 'app/kizentypo';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';

import {
  AddFilterButton,
  AddFilterSetButton,
  ObjectOverviewFilterTypeSelector,
  Filter,
  FilterOperatorText,
  FilterRow,
  FilterSetContainer,
  FilterSetOperationDropdown,
  FilterTypeSelectorContainer,
  useFilterErrors,
  useApiFilterErrors,
  useMappedReturnErrors,
} from 'ts-components/filters';

import {
  FilterMenu,
  FilterMenuHeader,
  FilterControlButton,
  FilterNameInput,
  ErrorCard,
  FilterControls2,
  FilterNameWrapper,
} from './filterDropdownComponents';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useSharingAccessModal } from 'pages/FilterGroupsPage/hooks/useSharingAccessModal';
import { SharingAccessModal } from 'components/Modals/SharingAccessModal';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import { getCanAdmin, getCanEdit } from 'components/AccessRequests/utils';
import { getOriginalError } from 'services/AxiosService';
import FilterGroupsService from 'services/FilterGroupsService';
import { toastVariant, useToast } from 'components/ToastProvider';

const countFilters = (acc, [, { filters }]) => {
  // filters are counted not after adding a row but once the first dropdown (type) is selected
  return acc + filters.filter(({ type }) => type !== null).length;
};

const ClientFilterDropdown = forwardRef(function ClientFilterDropdown(
  {
    filterData,
    and,
    className,
    applyFiltersAction,
    updateFilterCount,
    saveGroupAction,
    deleteGroupAction,
    setFilterName,
    filterName,
    filterGroup = EMPTY_OBJECT,
    clearFilter,
    numberOfFilters,
    betaPreview = false,
    noSticky,
    objectType = 'client_client',
    errorsReturned,
    customObjectId,
    ...others
  },
  ref
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterNameRef = useRef();
  const [groupError, showGroupError, flashGroupError] = useFlashTransition();
  const [showToast] = useToast();

  const [
    filterSets,
    {
      addFilter,
      addFilterSet,
      build,
      createFilter,
      validate,
      removeFilter,
      reset,
      setFilterSetOperation,
    },
  ] = useFilterDropdownContext();

  const errorsDictionary = useMappedReturnErrors(errorsReturned, filterSets);
  const [apiErrors, setApiErrors] = useApiFilterErrors(
    errorsDictionary,
    errorsReturned?.timestamp,
    3000
  );

  const [filterErrors, setFilterErrors] = useFilterErrors();
  const [operation, setOperation] = useState(and ? 'and' : 'or');
  const [isDeleteAction, setDeleteAction] = useState(false);
  const multipleSets = filterSets.length > 1;

  const handleClearFilter = useCallback(() => {
    reset();
    clearFilter();
    setApiErrors({});
  }, [clearFilter, reset, setApiErrors]);

  const handleApplyFilter = () => {
    const { errors, hasErrors } = validate();
    setApiErrors({});
    if (hasErrors) {
      setFilterErrors(errors);
    } else {
      applyFiltersAction(build(operation === 'and'));
    }
  };

  const [confirmationModalProps, , confirmationModal] = useModal({
    handleSubmit: isDeleteAction ? deleteGroupAction : handleClearFilter,
    ...(isDeleteAction ? { handleHide: () => setDeleteAction(false) } : {}),
  });

  const incrementFilterCount = () => {
    updateFilterCount(filterSets.reduce(countFilters, 1));
  };

  const decrementFilterCount = () => {
    updateFilterCount(filterSets.reduce(countFilters, -1));
  };

  const clearFilters = useCallback(() => {
    setApiErrors({});
    if (errorsReturned?.parsedErrors?.length) {
      dispatch(
        objectType === 'client_client'
          ? clearContactFilterErrors()
          : clearCOFilterErrors()
      );
    }
  }, [
    objectType,
    dispatch,
    errorsReturned?.parsedErrors?.length,
    setApiErrors,
  ]);

  const handleSaveFilterGroup = async () => {
    if (!filterName) {
      flashGroupError(t('Please type a group name before saving.'));
    } else {
      const { errors, hasErrors } = validate();
      setApiErrors({});
      if (hasErrors) {
        setFilterErrors(errors);
      } else {
        try {
          const filterGroupUpdated =
            await FilterGroupsService.updateFilterGroup(
              customObjectId,
              filterGroup.id,
              {
                name: filterName,
                config: build(operation === 'and'),
              }
            );
          saveGroupAction({
            filterGroup: filterGroupUpdated,
            isPatch: true,
          });
        } catch (error) {
          const orig = getOriginalError(error);
          showToast({
            message: t('Filter Group Not Saved'),
            variant: toastVariant.FAILURE,
          });
          if (orig.name) {
            flashGroupError(orig.name);
          }
        }
      }
    }
  };

  const createFilterGroup = useCallback(
    async ({ name, permissions, isPrivate }) => {
      const filterGroupCreated = await FilterGroupsService.createFilterGroup(
        customObjectId,
        {
          name,
          config: build(operation === 'and'),
          sharingSettings: {
            ...buildSharingSettingsForAPI(permissions).sharing_settings,
            private: isPrivate,
          },
        }
      );
      setFilterName(name);
      saveGroupAction({
        filterGroup: filterGroupCreated,
        isPatch: false,
      });
    },
    [saveGroupAction, setFilterName, build, operation, customObjectId]
  );

  const { sharingAccessModalProps, onShowSharingAccessModal } =
    useSharingAccessModal(createFilterGroup);

  const handleCreateFilterGroup = async () => {
    const { errors, hasErrors } = validate();
    setApiErrors({});
    if (hasErrors) {
      setFilterErrors(errors);
    } else {
      onShowSharingAccessModal();
    }
  };

  // There's CSS that prevents the error underline from displaying when the input is focused
  // We want to focus the input and also flash an error, so this will only focus after hasFilterError
  // has changed. This won't run on the first render thanks to the behavior of react-use's useUpdateEffect
  useUpdateEffect(() => {
    if (!groupError && filterNameRef.current) {
      filterNameRef.current.focus();
    }
  }, [groupError]);

  const confirmMessage = isDeleteAction
    ? t('This will permanently delete the Filter Group.')
    : t('Your filters are unsaved, this will clear your work.');

  const [nameTooltipProps, nameTooltip] = useTruncationTooltip({
    label: filterName,
  });

  const [canAdmin, canEdit] = useMemo(
    () => [getCanAdmin(filterGroup), getCanEdit(filterGroup)],
    [filterGroup]
  );

  return (
    <>
      <div ref={ref} className={className}>
        <FilterMenu {...others}>
          <FilterMenuHeader sticky={!noSticky}>
            <FilterNameWrapper>
              <FilterNameInput
                ref={filterNameRef}
                placeholder={t('Enter Filter Group Name')}
                value={filterName}
                onChange={setFilterName}
                error={!!groupError}
                debounce
                {...nameTooltipProps}
              />
            </FilterNameWrapper>
            {filterName ? nameTooltip : null}
            <Overlay
              transition={false}
              target={filterNameRef.current}
              show={groupError}
              placement="bottom-start"
            >
              <ErrorCard show={showGroupError} duration="300ms">
                <KizenTypography>{groupError}</KizenTypography>
              </ErrorCard>
            </Overlay>
            <FilterControls2>
              <FilterControlButton
                variant="outline"
                color="blue"
                onClick={handleApplyFilter}
                data-qa="apply-filter"
              >
                {t('Apply Filter')}
              </FilterControlButton>
              {filterGroup?.id && canEdit ? (
                <FilterControlButton
                  variant="outline"
                  color="green"
                  onClick={handleSaveFilterGroup}
                  data-qa="save-group"
                >
                  {t('Save Group')}
                </FilterControlButton>
              ) : null}
              <FilterControlButton
                variant="outline"
                color="green"
                onClick={handleCreateFilterGroup}
                data-qa="create-group"
              >
                {t('Create New Group')}
              </FilterControlButton>
              {filterGroup?.id && canAdmin ? (
                <FilterControlButton
                  variant="outline"
                  color="red"
                  onClick={() => {
                    setDeleteAction(true);
                    confirmationModal.show();
                  }}
                >
                  {t('Delete Group')}
                </FilterControlButton>
              ) : null}
              <FilterControlButton
                variant="outline"
                color="red"
                onClick={() => {
                  confirmationModal.show();
                }}
                data-qa="clear-filter"
              >
                {t('Clear All Filters')}
              </FilterControlButton>
            </FilterControls2>
          </FilterMenuHeader>
          {filterSets.map(([setId, { and, filters }], setIdx) => {
            const multipleFilters = filters.length > 1;
            return (
              <Fragment key={setId}>
                {setIdx > 0 && (
                  <FilterSetOperationDropdown
                    operation={operation}
                    onChange={setOperation}
                  />
                )}
                <FilterSetContainer
                  value={and ? 'all' : 'any'}
                  showBorder={multipleFilters || multipleSets}
                  onChange={(value) =>
                    setFilterSetOperation(setId, value === 'all')
                  }
                >
                  {filters.map(({ type, steps, ops, id }, idx, arr) => {
                    const isLastFilter = idx === arr.length - 1;
                    const filterTypeChosen = type !== null;
                    const errors = filterErrors[setId]?.[idx] ?? {};
                    return (
                      <FilterRow key={id} data-qa="filter-row">
                        <FilterTypeSelectorContainer
                          showDeleteIcon={multipleFilters || multipleSets}
                          onDelete={() => {
                            decrementFilterCount();
                            removeFilter(setId, idx);
                          }}
                        >
                          <ObjectOverviewFilterTypeSelector
                            objectType={objectType}
                            value={type}
                            error={
                              errors['filter-type'] ||
                              (apiErrors[setId]?.[idx] &&
                                t('This filter is invalid.'))
                            }
                            onChange={({ value }) => {
                              if (steps.length === 0) {
                                incrementFilterCount();
                              }
                              createFilter(value, setId, idx);
                              clearFilters();
                            }}
                          />
                        </FilterTypeSelectorContainer>

                        <Filter
                          objectType={objectType}
                          filterType={type}
                          steps={steps}
                          next={ops.next}
                          set={ops.set}
                          errors={errors}
                          onChange={() => {
                            clearFilters();
                            ops.update();
                          }}
                        />
                        {arr.length > 1 && !isLastFilter && (
                          <FilterOperatorText and={and} />
                        )}
                        {filterTypeChosen && isLastFilter && (
                          <AddFilterButton onClick={() => addFilter(setId)} />
                        )}
                      </FilterRow>
                    );
                  })}
                </FilterSetContainer>
                {filterSets.length === 1 && multipleFilters && (
                  <AddFilterSetButton
                    onClick={(value) => {
                      addFilterSet();
                      setOperation(value);
                    }}
                  />
                )}
              </Fragment>
            );
          })}
          {multipleSets && (
            <AddFilterSetButton disableSelect onClick={addFilterSet} />
          )}
        </FilterMenu>
      </div>
      <ConfirmationModal
        buttonText={isDeleteAction ? t('Confirm Delete') : t('Confirm Clear')}
        heading={
          isDeleteAction ? t('Please Confirm Deletion') : t('Please Confirm')
        }
        {...confirmationModalProps}
        data-qa="clear-confirm-modal"
      >
        {confirmMessage}
      </ConfirmationModal>
      {sharingAccessModalProps.show ? (
        <SharingAccessModal
          showPrivateToggle
          existing={{ name: filterName }}
          {...sharingAccessModalProps}
          instanceName={t('Filter Group')}
          isTemplate={false}
        />
      ) : null}
    </>
  );
});

export default ClientFilterDropdown;
