import styled from '@emotion/styled';
import css from '@emotion/css';
import { borderRadii } from 'app/spacing';
import { grayScale } from 'app/colors';
import CheckboxGroup from 'components/Inputs/CheckboxGroup';
import Button from 'components/Button';
import IconButton from 'components/Kizen/IconButton';

export const StyledCheckboxGroup = styled(CheckboxGroup)`
  height: auto;
  ${({ maxHeight, expanded }) =>
    expanded
      ? css`
          max-height: ${maxHeight}px;
        `
      : css`
          max-height: 0;
          pointer-events: none;
        `};
  transition: max-height 0.3s ease;
  overflow: hidden;
  margin-bottom: 15px;
  margin-left: 27px;
  /*  In case of long names we need to restrict the width to have enough space for right column tables */
  max-width: min(calc(50vw - 143px), calc(100vw - 728px), 604px);
`;

export const StyledCheckAllWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

export const StyledRotatingIconButton = styled(IconButton)`
  transition: transform 0.3s ease;
  ${({ expanded }) => css`
    transform: rotate(${expanded ? 0 : 90}deg);
  `};
`;

export const StyledLinkWrapper = styled.div`
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
`;

export const StyledTableWrapper = styled.div`
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  overflow-y: hidden;
  height: 496px;
  > div {
    height: 100%;
  }
`;

export const StyledPaginationWrapper = styled.div`
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  margin: 0;
`;

export const StyledUploadButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
`;
