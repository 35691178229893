import { defmulti, DEFAULT } from '@thi.ng/defmulti';
import {
  mapBackgroundColor,
  mapBorder,
  mapContainerPadding,
  MJ_TEXT_CLASS_NAME,
} from './styles';
import {
  BuilderButtonNode,
  BuilderJson,
  BuilderNode,
  BuilderNodeTextStyle,
  MjmlHeadChild,
  MjmlNode,
  MjmlAttribute,
  MjmlAttributeChild,
  BuilderSectionNode,
  BuilderImageNode,
  BuilderRowNode,
  MjmlImage,
  MjmlSection,
  DynamicImageNode,
  DynamicTextNode,
  MjmlText,
  BuilderTextNode,
} from './types';
import {
  collect,
  getContainerSizingStyle,
  getImageWidthHeight,
  msoSupportedColor,
  processText,
} from './utils';

const isDynamicImageNode = (
  node: BuilderNode | DynamicImageNode | DynamicTextNode
): node is DynamicImageNode => {
  return !!node.props.filterId && node.type.resolvedName === 'Image';
};

const isDynamicTextNode = (
  node: BuilderNode | DynamicImageNode | DynamicTextNode
): node is DynamicTextNode => {
  return !!node.props.filterId && node.type.resolvedName === 'Text';
};

const getImageFilterClassname = (node: DynamicImageNode | BuilderImageNode) => {
  const { id, props, custom } = node;

  return isDynamicImageNode(node)
    ? `kzn-filter_${id}_${props.filterOrder}_${props.filterId}`
    : `kzn-filter_${id}_${custom?.dynamicImages?.length ?? 99}_default`;
};

const getTextFilterClassname = (node: DynamicTextNode | BuilderTextNode) => {
  const { id, props, custom } = node;

  return isDynamicTextNode(node)
    ? `kzn-filter_${id}_${props.filterOrder}_${props.filterId}`
    : `kzn-filter_${id}_${custom?.dynamicText?.length ?? 99}_default`;
};

export type TransformCtx = {
  allNodes: BuilderJson;
  headAttributes: MjmlAttributeChild[];
  headChildren: Exclude<MjmlHeadChild, MjmlAttribute>[];
  globalTextLinkStyleAdded: boolean;
  /**
   * Default is true. If false, only the default image content will be converted to MJML.
   */
  processDynamicImages?: boolean;
  /**
   * Default is true. If false, only the default text content will be converted to MJML.
   */
  processDynamicText?: boolean;
};

const attachmentsCustomHtml = (
  iconUrl: string,
  attachment: { url: string; name: string }
) => {
  return `<div style="display: flex;">
            <span style="margin-right: 5px;">
                <img src="${iconUrl}" width="9px" height="12px" alt="" >
            </span>
            <a data-attachment="true" target="_blank" href="${attachment.url}">${attachment.name}</a>
          </div>`;
};

const parseAttachments = (props: any): [MjmlSection] => {
  const { attachments, attachmentIconUrl } = props;

  return [
    {
      tagName: 'mj-section',
      attributes: {
        padding: `10px`,
      },
      children: attachments?.reduce(
        (acc: MjmlNode[], attachment: { url: any; name: any }) => {
          return [
            ...acc,
            {
              tagName: 'mj-column',
              attributes: {
                border: `1px solid #eceef0`,
                height: `25px`,
                'css-class': 'fixed-layout',
                'background-color': '#f5f6f7',
                // mj-column elements are normally used for positioning content horizontally. Setting width: '100%' is what forces them to stack on top of each other on desktop.
                width: '100%',
              },
              children: [
                {
                  tagName: 'mj-text',
                  attributes: {
                    padding: '6px 5px',
                    'font-size': `12px`,
                    'font-weight': '600',
                    'line-height': `12px`,
                  },
                  content: attachmentsCustomHtml(attachmentIconUrl, attachment),
                },
              ],
            },
            {
              tagName: 'mj-spacer',
              attributes: {
                height: '5px',
              },
            },
          ];
        },
        []
      ),
    },
  ];
};

const parseChildren = (childIds: string[], ctx: TransformCtx) => {
  return childIds
    .reduce(
      collect<string, MjmlNode | MjmlImage[] | MjmlText[] | undefined>(
        (id: string) => {
          const node = { ...ctx.allNodes[id], id };
          return transformNode(node, ctx);
        }
      ),
      []
    )
    .flat() as MjmlNode[];
};

export const transformNode = defmulti<
  BuilderNode | DynamicImageNode | DynamicTextNode,
  TransformCtx,
  MjmlNode | MjmlImage[] | MjmlText[] | undefined
>((node, _ctx: TransformCtx) => {
  return node.type.resolvedName;
});

transformNode.add('Root', (node, ctx: TransformCtx) => {
  const { alignment, backgroundColor, maxWidth, mobileBreak } = node.props;
  const addAlignmentStyle = alignment === 'left' || alignment === 'right';
  const classname = 'body-style';
  if (addAlignmentStyle) {
    ctx.headChildren.push({
      tagName: 'mj-style',
      content: `
        .${classname} {
          float: ${alignment};
        }
      `,
    });
  }
  if (mobileBreak) {
    ctx.headChildren.push({
      tagName: 'mj-breakpoint',
      attributes: { width: `${mobileBreak}px` },
    });
  }
  return {
    tagName: 'mj-body',
    attributes: {
      ...(addAlignmentStyle && { 'css-class': classname }),
      ...mapBackgroundColor(backgroundColor, 'background-color'),
      width: isNaN(parseInt(maxWidth)) ? '600px' : `${maxWidth}px`,
    },
    children: parseChildren(node.nodes, ctx),
  };
});
transformNode.add('Section', (node, ctx: TransformCtx) => {
  const { props } = node as BuilderSectionNode;
  const [classname, sizingStyle] = getContainerSizingStyle(node.id, props);

  ctx.headChildren.push({
    tagName: 'mj-style',
    content: sizingStyle,
  });

  return {
    tagName: 'mj-wrapper',
    attributes: {
      ...mapContainerPadding(props, '10'),
      ...mapBackgroundColor(props.containerBackgroundColor, 'background-color'),
      ...mapBorder(props),
      'background-repeat': props.containerBackgroundRepeat,
      'background-size': props.containerBackgroundSize,
      'background-url': props.containerBackgroundImageSrc,
      'background-position-x': props.containerBackgroundPositionX,
      'background-position-y': props.containerBackgroundPositionY,
      'css-class': classname,
    },
    children: parseChildren(node.nodes, ctx),
  };
});
transformNode.add('Row', (node, ctx: TransformCtx) => {
  const { props } = node as BuilderRowNode;
  const { containerBackgroundColor, columns } = props;
  const [classname, sizingStyle] = getContainerSizingStyle(node.id, props);

  ctx.headChildren.push({
    tagName: 'mj-style',
    content: sizingStyle,
  });

  // In the page builder, Rows control the size of columns via CSS Grid, but in MJML it is controlled with the column's `width` attribute
  for (const [column, cellId] of Object.entries(node.linkedNodes).slice(
    0,
    columns.length
  )) {
    const cellNumber = parseInt(column.split('-').pop() ?? ''); // linkedNodes are keyed by 'column-1', 'column-2', ...
    if (!isNaN(cellNumber)) {
      ctx.allNodes[cellId].props.__width = columns[cellNumber - 1];
    }
  }

  return {
    tagName: 'mj-section',
    attributes: {
      ...mapContainerPadding(props, '10'),
      ...mapBackgroundColor(containerBackgroundColor, 'background-color'),
      'css-class': classname,
    },
    children: parseChildren(Object.values(node.linkedNodes), ctx),
  };
});
transformNode.add('Cell', (node, ctx: TransformCtx) => {
  const width =
    node.props.__width < 1 ? `${node.props.__width * 100}%` : '100%';

  return {
    tagName: 'mj-column',
    attributes: { width },
    children: parseChildren(node.nodes, ctx),
  };
});
transformNode.add('Text', (node, ctx: TransformCtx) => {
  const { custom, id, props } = node;
  const { containerBackgroundColor } = props;
  const {
    color,
    fontFamily,
    fontSize,
    linkColor = '#528EF9',
  } = ctx.allNodes.ROOT.props;
  const classnames = [MJ_TEXT_CLASS_NAME];

  if (!ctx.globalTextLinkStyleAdded) {
    ctx.globalTextLinkStyleAdded = true;
    ctx.headChildren.push({
      tagName: 'mj-style',
      content: `
          .fixed-layout  table {
            table-layout: fixed;
          }
          .fixed-layout wbr {
            display:none;
          }  
          .fixed-layout a {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
          }
          .${MJ_TEXT_CLASS_NAME} a {
            color: ${msoSupportedColor(linkColor)};
            text-decoration: none;
          }
          .${MJ_TEXT_CLASS_NAME} a *,
          .${MJ_TEXT_CLASS_NAME} span * {
              color: inherit;
              font-size: inherit;
          }
          .${MJ_TEXT_CLASS_NAME} a:hover,
          .${MJ_TEXT_CLASS_NAME} a:focus,
          .${MJ_TEXT_CLASS_NAME} a:hover *,
          .${MJ_TEXT_CLASS_NAME} a:focus * {
              text-decoration: underline;
          }
          .${MJ_TEXT_CLASS_NAME} a:hover s,
          .${MJ_TEXT_CLASS_NAME} a:focus s {
              text-decoration: underline line-through;
          }
          .${MJ_TEXT_CLASS_NAME} p * {
            line-height: inherit;
          }
        `,
    });
  }
  // .ProseMirror dimension
  // In ProseMirror we have paddings for text in the WYSIWYG editor. These paddings don't apply in html of email.
  // That's why we set up paddings for TextContainer 10px
  const paddings = {
    containerPaddingTop: String(+props.containerPaddingTop + 10),
    containerPaddingRight: String(+props.containerPaddingRight + 10),
    containerPaddingBottom: String(+props.containerPaddingBottom + 10),
    containerPaddingLeft: String(+props.containerPaddingLeft + 10),
  };

  if (isDynamicTextNode(node) || Array.isArray(custom.dynamicText)) {
    classnames.push(
      'kzn-filter',
      getTextFilterClassname(node as BuilderTextNode)
    );
  }

  const text: MjmlText = {
    tagName: 'mj-text',
    attributes: {
      color: msoSupportedColor(color) || '#575757',
      'css-class': classnames.join(' ').trim(),
      'font-family': fontFamily,
      ...(fontSize ? { 'font-size': `${fontSize}px` } : undefined),
      ...mapBackgroundColor(containerBackgroundColor),
      ...mapContainerPadding({ ...props, ...paddings }),
    },
    content: processText(custom.text ?? ''),
  };

  if (
    isDynamicTextNode(node) ||
    !Array.isArray(custom.dynamicText) ||
    ctx.processDynamicText === false
  ) {
    return text;
  }

  const dynamic = custom.dynamicText.map((n: Omit<DynamicImageNode, 'id'>) => {
    return transformNode({ id, ...n }, ctx);
  }) as MjmlText[];

  return dynamic.concat(text);
});

const DEFAULT_BUTTON_PROPS = {
  color: '#4BC7B4',
  label: 'Submit',
  textColor: '#FFFFFF',
  letterSpacing: '0.8px',

  action: 'submit',
  url: '',

  borderSize: '0',
  borderRadius: '8',
  borderColor: '#000000',
};

transformNode.add('Button', ({ props }) => {
  const allProps = {
    ...DEFAULT_BUTTON_PROPS,
    ...props,
  } as BuilderButtonNode['props'];

  let { color } = allProps;

  const {
    textColor,
    borderRadius,
    borderColor,
    borderSize,
    label,
    action,
    url,
    alignment,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    textStyles,
    fontSize,
    containerBackgroundColor,
    fontFamily: fontValue,
    letterSpacing,
  } = allProps;

  // BW-Compatible defaults
  if (color === 'green') {
    // hardcode the value here, as this will always be the prev default value
    color = '#4BC7B4';
  }

  const [bold, underline, italic] = textStyles?.reduce(
    (acc: [boolean, boolean, boolean], style: BuilderNodeTextStyle) => {
      if (style === 'bold') acc[0] = true;
      else if (style === 'underline') acc[1] = true;
      else if (style === 'italic') acc[2] = true;
      return acc;
    },
    [false, false, false]
  ) ?? [false, false, false];

  const padding = [
    `${paddingTop || 0}px`,
    `${paddingRight || 0}px`,
    `${paddingBottom || 0}px`,
    `${paddingLeft || 0}px`,
  ].join(' ');

  return {
    tagName: 'mj-button',
    attributes: {
      'background-color': msoSupportedColor(color),
      color: msoSupportedColor(textColor),
      'border-radius': `${borderRadius || 0}px`,
      border: `${borderSize || 0}px solid ${msoSupportedColor(borderColor)}`,
      href: (action === 'url' && url) || undefined,
      'font-family': fontValue || 'Ubuntu, Helvetica, Arial, sans-serif',
      'font-weight': bold ? 'bold' : 'normal',
      'font-size': fontSize ? `${fontSize}px` : '12px',
      'inner-padding': padding,
      align: alignment || 'center',
      'letter-spacing': letterSpacing,
      ...(underline && { 'text-decoration': 'underline' }),
      ...(italic && { 'font-style': 'italic' }),
      ...mapBackgroundColor(containerBackgroundColor),
      ...mapContainerPadding(props),
    },
    content: label ?? '',
  };
});
transformNode.add('Image', (node, ctx: TransformCtx) => {
  const { id, props, custom } = node;
  const { mobileBreak } = ctx.allNodes.ROOT.props;

  const classnames: string[] = [];
  let sizeAttributes: MjmlImage['attributes'] = {};
  if (props.unit === 'percent') {
    const classname = `image-${id}`;
    ctx.headChildren.push({
      tagName: 'mj-style',
      content: `.${classname} > table {
        width: ${props.width}%;
      }`,
    });
    // We need to set the width of the image container to the width of the image
    sizeAttributes = {
      // For Microsoft Outlook, where percentage-based widths may not work reliably,
      // set a fixed pixel width based on the container width and specified percentage
      width: `${Math.round(props.containerWidth * (props.width / 100))}px`,
    };
    classnames.push(classname);
  } else if (props.size === 'auto') {
    const classname = `image-${id}-auto`;
    ctx.headChildren.push({
      tagName: 'mj-style',
      content: `.${classname} > table td {
        width: 100% !important;
        max-width: ${props.naturalWidth}px;
      }`,
    });
    classnames.push(classname);
  } else if (props.size === 'dynamic') {
    sizeAttributes = {
      width: `${props.width}px`,
    };
  } else if (props.size === 'fixed') {
    const [width, height] = getImageWidthHeight(
      props as BuilderImageNode['props']
    );

    if (mobileBreak) {
      const classname = `image-${id}-fixed`;
      ctx.headChildren.push({
        tagName: 'mj-style',
        content: `@media only screen and (max-width: ${mobileBreak}px) {
          .${classname} > table td {
            width: ${
              props.width > mobileBreak ? '100%' : props.width
            } !important;
          }
        }`,
      });
      classnames.push(classname);
    }

    sizeAttributes = {
      height: `${height}px`,
      width: `${width}px`,
    };
  }

  if (isDynamicImageNode(node) || Array.isArray(custom.dynamicImages)) {
    classnames.push(
      'kzn-filter',
      getImageFilterClassname(node as BuilderImageNode)
    );
  }

  const image: MjmlImage = {
    tagName: 'mj-image',
    attributes: {
      src: props.src,
      align: props.position,
      href: props.link?.length ? props.link : undefined,
      alt: props.alt?.length ? props.alt : undefined,
      'css-class': classnames.join(' ').trim(),
      ...mapBackgroundColor(props.containerBackgroundColor),
      ...mapContainerPadding(props),
      ...sizeAttributes,
    },
  };

  if (
    isDynamicImageNode(node) ||
    !Array.isArray(custom.dynamicImages) ||
    ctx.processDynamicImages === false
  ) {
    return image;
  }

  const images = custom.dynamicImages.map((n: Omit<DynamicImageNode, 'id'>) => {
    // The dynamic images do not have a craft id assigned - we need to attach the id
    // of the main image node here so we can add it to the dynamic image class name
    return transformNode({ id, ...n }, ctx);
  }) as MjmlImage[];
  return images.concat(image);
});
transformNode.add('Divider', ({ props }) => ({
  tagName: 'mj-divider',
  attributes: {
    align: props.alignment,
    'border-color': msoSupportedColor(props.color),
    'border-style': props.borderStyle,
    'border-width': `${props.size}px`,
    width: `${props.width}%`,
    ...mapBackgroundColor(props.containerBackgroundColor),
    ...mapContainerPadding(props),
  },
}));

transformNode.add('Attachments', (node, ctx: TransformCtx) => {
  const { id, props } = node;
  const { containerBackgroundColor } = props;
  const classname = `attachments-${id}`;
  ctx.headChildren.push({
    tagName: 'mj-style',
    content: `.${classname} {
        width: 100%;
        max-width: unset !important;
      } .${classname} td > div {
        width: 100%;
        max-width: unset !important;
      }`,
  });
  return {
    tagName: 'mj-wrapper',
    attributes: {
      ...mapContainerPadding(props, '0'),
      ...mapBackgroundColor(containerBackgroundColor, 'background-color'),
      'css-class': classname,
    },
    children: parseAttachments(props),
  };
});

transformNode.add(DEFAULT, () => undefined);
