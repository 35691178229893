import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { gutters } from 'app/spacing';
import Button from 'components/Button';
import { GenericWizardSection } from 'components/GenericWizard';

const oneSectionHeight = 110;
const oneSectionMargin = 18;

export const getMaxHeight = (count) =>
  count * oneSectionHeight + (count - 1) * oneSectionMargin;

export const ChangeBtn = styled(Button)`
  margin: ${gutters.spacing(4, -1)}px 0 0;
  padding: 0;
  height: 12px;
`;

export const ResizableSection = styled(GenericWizardSection)`
  margin-top: 0;
  padding-top: ${gutters.spacing(4, -3)}px;
  ${({ isShortMode, isEdit, maxHeight }) =>
    isEdit
      ? isShortMode
        ? css`
            max-height: 142px;
            margin: ${gutters.spacing(4, -1)}px 0 ${gutters.spacing(6, -2)}px;
          `
        : css`
            transition: max-height linear 0.7s;
            max-height: ${maxHeight}px;
          `
      : ''}
`;
