import { useSelector } from 'react-redux';
import { ASSOCIATION_SOURCE_TYPES } from 'components/Wizards/CustomObject/constants';

export const useCanAddTeamAssociation = ({
  entity,
  objectId,
  isClient,
  associationSource,
}: {
  entity?: {
    access: { view: boolean; edit: boolean; remove: boolean };
  };
  objectId?: string;
  isClient?: boolean;
  associationSource?: string;
}) => {
  const access = useSelector((s: any) => s.authentication.access);
  const hasEditAccessToEntity = Boolean(entity?.access?.edit);

  const canAddContactTeamAssociation = Boolean(
    access?.contacts?.single_record_actions?.team_associations?.edit
  );

  const canAddCustomObjectTeamAssociation = Boolean(
    objectId &&
      access?.custom_objects?.custom_object_entities?.[objectId]
        ?.single_record_actions?.team_associations?.edit
  );

  const hasAddTeamAssociationPermission = isClient
    ? canAddContactTeamAssociation
    : canAddCustomObjectTeamAssociation;

  return (
    hasAddTeamAssociationPermission &&
    hasEditAccessToEntity &&
    associationSource !== ASSOCIATION_SOURCE_TYPES.related
  );
};

export const useCanViewTeamAssociation = ({
  objectId,
  isClient,
}: {
  objectId?: string;
  isClient?: boolean;
}) => {
  const access = useSelector((s: any) => s.authentication.access);

  const canAddContactTeamAssociation = Boolean(
    access?.contacts?.single_record_actions?.team_associations?.view ?? false
  );

  const canAddCustomObjectTeamAssociation = Boolean(
    objectId &&
      access?.custom_objects?.custom_object_entities?.[objectId]
        ?.single_record_actions?.team_associations?.view
  );

  const hasAddTeamAssociationPermission = isClient
    ? canAddContactTeamAssociation
    : canAddCustomObjectTeamAssociation;

  return hasAddTeamAssociationPermission;
};
