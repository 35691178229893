import React, { useRef, useCallback } from 'react';
import { ClipLoader } from 'react-spinners';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import useToggle from 'react-use/lib/useToggle';
import useClickAway from 'react-use/lib/useClickAway';
import { colorsButton, grayScale } from 'app/colors';
import Select, { optionShape } from 'components/Kizen/Select';
import Icon from 'components/Kizen/Icon';
import Button from '.';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  margin: 0.5rem 0.75rem;
  button:first-of-type {
    flex: 1;
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button:last-of-type {
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const VerticalDivider = styled.div`
  width: 1px;
  ${({ color, disabled }) => {
    if (disabled) {
      return css`
        background-image: linear-gradient(
          to bottom,
          ${grayScale.mediumDark} 0%,
          ${grayScale.medium} 100%
        );
      `;
    }
    const colors = colorsButton[color];
    if (!colors) {
      return css``;
    }
    return css`
      background-image: linear-gradient(
        to bottom,
        ${colors.hover} 0%,
        ${colors.default} 100%
      );
    `;
  }}
`;

const ButtonMenu = styled(Select)`
  > :first-child {
    ${({ restrictWidth }) =>
      restrictWidth
        ? css`
            width: 100%;
          `
        : null}

    position: absolute;

    ${({ bottomOffset }) =>
      bottomOffset
        ? css`
            bottom: ${bottomOffset}px;
          `
        : css`
            bottom: 0;
          `}
    right: 0;
  }
  .ButtonMenu__control {
    display: none;
  }
  .ButtonMenu__menu {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    max-width: 100%;
    border: 1px solid ${grayScale.medium};
    border-radius: 4px;
  }
`;

export default function ButtonWithDropdown({
  color,
  disabled,
  options,
  onClick,
  onChange,
  children,
  variant,
  loading = false,
  loadingColor = grayScale.white,
  restrictWidth = true,
  clickToOpen = false,
  clickNotify = null,
  bottomOffset = '0',
  ...others
}) {
  const [open, toggleOpen] = useToggle(false);
  const dropdownRef = useRef(null);
  useClickAway(dropdownRef, () => toggleOpen(false));
  const isDisabled = disabled || loading;

  const handleClick = useCallback(
    (ev) => {
      if (clickToOpen) {
        toggleOpen();
      } else {
        toggleOpen(false);
        if (!open && onClick) {
          onClick(ev);
        }
      }
      if (clickNotify) clickNotify();
    },
    [open, toggleOpen, onClick, clickToOpen, clickNotify]
  );

  const handleChange = useCallback(
    (value) => {
      toggleOpen(false);
      if (onChange) {
        onChange(value);
      }
      if (clickNotify) clickNotify();
    },
    [toggleOpen, onChange, clickNotify]
  );

  const handleToggleOpen = useCallback(() => {
    toggleOpen();
    if (clickNotify) clickNotify();
  }, [toggleOpen, clickNotify]);

  return (
    <Wrapper ref={dropdownRef} {...others}>
      <Button
        variant={variant}
        color={color}
        disabled={isDisabled}
        onClick={handleClick}
      >
        {loading && <ClipLoader loading size={20} color={loadingColor} />}
        {!loading && children}
      </Button>
      {variant === 'default' && (
        <VerticalDivider color={color} disabled={isDisabled} />
      )}
      <Button
        noMinWidth
        variant={variant}
        color={color}
        disabled={isDisabled}
        onClick={handleToggleOpen}
      >
        <Icon icon="down" color="inherit" />
      </Button>
      {open && !isDisabled && (
        <ButtonMenu
          menuInLine
          classNamePrefix="ButtonMenu"
          menuIsOpen
          menuPlacement="bottom"
          filterOption={null}
          hideSelectedOptions={false}
          options={options}
          onChange={handleChange}
          restrictWidth={restrictWidth}
          bottomOffset={bottomOffset}
          {...others}
        />
      )}
    </Wrapper>
  );
}

ButtonWithDropdown.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(optionShape).isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'text']),
  loading: PropTypes.bool,
  loadingColor: PropTypes.string,
  restrictWidth: PropTypes.bool,
  clickToOpen: PropTypes.bool,
  clickNotify: PropTypes.func,
};

ButtonWithDropdown.defaultProps = {
  disabled: false,
  color: 'green',
  onClick: null,
  onChange: null,
  variant: 'default',
};
