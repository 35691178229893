import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';

export default function ArchiveModal({ children, ...props }) {
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      buttonText={t('Confirm Archival')}
      heading={t('Please Confirm Archival')}
      defaultLeftBtnText={t('Cancel')}
      {...props}
    >
      {children}
    </ConfirmationModal>
  );
}
