import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { TextEllipsis } from 'components/Kizen/Table';
import { gutters } from 'app/spacing';
import { colorsButton, colorsText, grayScale, shadowOverlay } from 'app/colors';
import { fontSizes } from 'app/typography';

const TrayButtonWrapper = styled.button`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  grid-column: span ${({ cols }) => cols};
  background: ${grayScale.white};
  border: 1px solid ${grayScale.medium};
  ${shadowOverlay}
  color: ${colorsText.dark};
  ${({ hoverColor }) => css`
    &:hover {
      color: ${hoverColor};
      border-color: ${hoverColor};
    }
  `}
  &:focus {
    outline: none;
  }
  > :first-child {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > :last-child {
    margin-bottom: ${gutters.spacing(1, { baseline: fontSizes.small })}px;
  }
`;

export const TrayButton = ({
  image,
  label,
  hoverColor = colorsButton.blue.hover,
  cols = 1,
  ...others
}) => {
  return (
    <TrayButtonWrapper hoverColor={hoverColor} cols={cols} {...others}>
      <span>{image}</span>
      <TextEllipsis as="span" size="small" weight="bold" color="inherit">
        {label}
      </TextEllipsis>
    </TrayButtonWrapper>
  );
};

TrayButton.propTypes = {
  image: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  cols: PropTypes.number,
};
