import { VIEW_VARIANTS, View } from 'components/ViewVariant/types';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

type UseRecordsPageRoutingOpts = {
  viewVariantsAccess: Record<View, boolean>;
  isContact: boolean;
  queryParams: Record<string, string>;
};

export const useRecordsPageRouting = (
  pageReady: boolean,
  storeViewVariant: (view: View) => void,
  storedViewVariant: View,
  opts: UseRecordsPageRoutingOpts
) => {
  const { viewVariantsAccess, isContact } = opts;
  const history = useHistory();
  const { subpage, objectId } = useParams<{
    subpage: View;
    objectId: string;
  }>();

  const viewVariant = useMemo(() => {
    const viewVariants = Object.values(VIEW_VARIANTS).filter(
      (v) => viewVariantsAccess[v]
    );
    const variant = !subpage
      ? storedViewVariant || VIEW_VARIANTS.TABLE
      : subpage;
    if (!viewVariants.includes(variant)) {
      return VIEW_VARIANTS.TABLE;
    }
    return variant;
  }, [storedViewVariant, subpage, viewVariantsAccess]);

  useEffect(() => {
    if (pageReady && storedViewVariant !== viewVariant) {
      storeViewVariant(viewVariant);
    }
  }, [pageReady, viewVariant, storeViewVariant, storedViewVariant]);

  const basePath = isContact ? 'clients' : `custom-objects/${objectId}`;
  useEffect(() => {
    if (
      pageReady &&
      ((!subpage && viewVariant !== VIEW_VARIANTS.TABLE) ||
        subpage !== viewVariant ||
        subpage === VIEW_VARIANTS.TABLE)
    ) {
      history.replace(
        `/${
          basePath +
          (viewVariant === VIEW_VARIANTS.TABLE ? '' : `/${viewVariant}`)
        }`
      );
    }
  }, [pageReady, viewVariant, subpage, history, basePath]);

  const changeChartGroup = useCallback(
    (id: string) => {
      history.replace(`/${basePath}/${VIEW_VARIANTS.CHARTS}/${id}`);
    },
    [history, basePath]
  );

  return {
    viewVariant,
    changeChartGroup,
  };
};
