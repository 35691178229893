import { HorizontalDropzone } from 'components/DragAndDropLayout/Dropzone';
import { dropzoneIs } from 'components/DragAndDropLayout/helpers';
import { CARD_OFFSET } from 'components/MultiColumnBuilder/constants';
import { useMemo } from 'react';
import Draggable from 'react-draggable';
import { DragItemWrapper, OptionPlaceholder, StyledGroupTitle } from './styles';
import KizenTypography from 'app/kizentypo';
import { grayScale } from 'app/colors';
import { flushSync } from 'react-dom';

const OptionParent = ({
  dropZone,
  option,
  nextOption,
  childDropZone,
  dropFirstChild,
  draggingItem,
  editingItem,
  setDraggingItem,
  endDrag,
  getChildDropZone,
  id,
  index,
  editValue,
  setEditingItem,
  editable,
  removeItem,
  swapItemColumn,
  editOptions,
  collapseItem,
  expandItem,
  collapsedItems,
  setDropFirstChild,
  moveItemToBottom,
  columnWidth,
  renderOption,
  renderEmpty,
}) => {
  const isDragging = option.id === draggingItem?.id;
  const isEditing = option.id === editingItem?.id;

  const isChildDragging = useMemo(() => {
    return Boolean(option.children?.find((c) => c.id === draggingItem?.id));
  }, [option, draggingItem]);

  const isChildEditing = useMemo(() => {
    return Boolean(option.children?.find((c) => c.id === editingItem?.id));
  }, [option, editingItem]);

  return (
    <>
      {dropzoneIs(dropZone, {
        id: option.id,
        position: 'before',
        direction: 'horizontal',
      }) &&
      !childDropZone &&
      !dropFirstChild ? (
        <HorizontalDropzone />
      ) : null}
      {isDragging && (
        <OptionPlaceholder
          dragging={isDragging}
          cardHeight={draggingItem?.dimensions?.height}
        />
      )}
      <DragItemWrapper
        key={option.id}
        dragging={isDragging}
        draggingDimensions={draggingItem?.dimensions}
        className={`MenuItemWrapper-${id}`}
        editing={isEditing || isChildEditing}
        isChildDragging={isChildDragging}
        draggingPosition={draggingItem?.mouse}
      >
        {option?.isGroupedItem ? (
          <StyledGroupTitle index={index}>
            <KizenTypography color={grayScale.mediumDark} weight="bold">
              {option.label}
            </KizenTypography>
            {!nextOption || nextOption?.isGroupedItem
              ? renderEmpty({ id: null, dropZone })
              : null}
          </StyledGroupTitle>
        ) : (
          <Draggable
            position={isDragging ? null : { x: 0, y: 0 }}
            onStart={(ev) => {
              ev.preventDefault();
              if (document.activeElement) {
                document.activeElement.blur();
              }
              const rect = ev.currentTarget.getBoundingClientRect();

              flushSync(() => {
                setDraggingItem({
                  ...option,
                  fromColumn: id,
                  dimensions: {
                    height: ev.currentTarget.offsetHeight,
                    width: ev.currentTarget.offsetWidth,
                    top: rect.top - CARD_OFFSET,
                  },
                });
              });
            }}
            onStop={endDrag}
            handle=".ToolbarDragHandle"
            disabled={option.isLocked}
          >
            {renderOption({
              element: option,
              section: id,
              isScrollMode: true,
              handleProps: { className: 'ToolbarDragHandle' },
              draggingItem,
              getChildDropZone,
              childDropZone,
              setDraggingItem,
              endDrag,
              index,
              editValue,
              setEditingItem,
              editingItem,
              editable,
              removeItem,
              editOptions,
              collapseItem,
              expandItem,
              collapsedItems,
              dropZone,
              setDropFirstChild,
              dropFirstChild,
              moveItemToBottom,
              columnWidth,
              swapItemColumn,
            })}
          </Draggable>
        )}
      </DragItemWrapper>
      {dropzoneIs(dropZone, {
        id: option.id,
        position: 'after',
        direction: 'horizontal',
      }) &&
      !childDropZone &&
      !dropFirstChild ? (
        <HorizontalDropzone />
      ) : null}
    </>
  );
};

export default OptionParent;
