import { usePermissionsContext } from './context';
import { useReactive } from './useReactive';

export const useAreFieldsLoaded = (key: string) => {
  const { fields_loaded } = usePermissionsContext();

  if (!fields_loaded.has(key)) {
    throw Error(
      `No entry found in the permissions context's fields_loaded Map for section with key ${key}.`
    );
  }

  return useReactive(fields_loaded.get(key)!);
};
