import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import { scrollOptions } from 'hooks/use-scroll-height-tracker';
import { useScrollIntoView } from 'hooks/use-scroll-into-view';
import { LabelWithTooltip } from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/CommonComponents/LabelWithToolTip';

import Cols from '__components/Layout/Cols';
import Select from '__components/Inputs/Select';
import EmailPicker from '__components/Kizen/EmailPicker';
import { useAutomationsSelector } from 'pages/AutomationEngine/store/react';
import {
  getMergeArgs,
  getAutomation,
  getAutomationCustomObjectFields,
} from 'pages/AutomationEngine/store/selectors';

import { useNotifyMemberViaTextMergeFields } from '../hooks/useNotifyMemberViaTextMergeFields';
import { useMergeFields } from 'hooks/useMergeFieldsBase';
import { notifyMemberViaEmailTypes } from 'pages/AutomationEngine/helpers';
import { useChangeAutomationsMessages } from '../hooks/useChangeAutomationsMessages';
import Switch from '__components/Kizen/Switch';
import SwitchControl from './SwitchControl';
import { getIsForCustomObject } from 'pages/AutomationEngine/store/selectors';
import { SENDER_TYPE } from 'components/MessageBuilder/components';
import { cleanEmailData } from 'pages/AutomationEngine/dialogs/ActionWizard/utilities';
import { EmailSendOptionsTeamMember } from '../CommonComponents/email-send-options/types';
import { validIntegratedInbox } from '../CommonComponents/helpers';

import {
  EmailSendOptions,
  useEmailSendOptions,
} from '../CommonComponents/email-send-options';

import { Relation, RelatedField } from 'pages/AutomationEngine/types';

const ClickScrollLimit = 150;

export const initState = {
  sendToContactField: null,
  email: null,
  teamMember: {
    type: notifyMemberViaEmailTypes.LAST_ACTIVE,
    role: null,
    employee: null,
  },
  ccTeamMember: null,
};

interface NotifyMemberViaEmailProps {
  values: any;
  onChange: (...args: any[]) => void;
}

interface ValidateProps {
  email: any;
  teamMember: EmailSendOptionsTeamMember;
  sendToContactField: any;
}

export const Component = function NotifyMemberViaEmail(
  props: NotifyMemberViaEmailProps
) {
  const { values, onChange } = props;

  const { t } = useTranslation();
  const { sendFromOwner = false } = values;
  const contextIsCustomObject = useAutomationsSelector(getIsForCustomObject);

  const automation = useAutomationsSelector(getAutomation);

  const automationContextFields = useAutomationsSelector(
    getAutomationCustomObjectFields
  );

  const mergeArgs = useAutomationsSelector(getMergeArgs);

  const { categorizedFieldOptions: mergeFields } =
    useNotifyMemberViaTextMergeFields({
      ...mergeArgs,
      mergeFieldName: mergeArgs.isForCustomObject ? 'entity_record' : 'contact',
      teamMemberCategoryName: t('Team Member'),
    });

  const { isForCustomObject } = mergeArgs;

  // extending first category with 'Link to Record' option
  mergeFields[0] = {
    ...mergeFields[0],
    items: [
      {
        label: t('Link to Record'),
        relationship: `${
          mergeArgs.isForCustomObject ? 'entity_record' : 'contact'
        }.link_url`,
      },
      ...(mergeFields[0]?.items ?? []),
    ],
  };

  const { categorizedFieldOptions: contactMergeFields } = useMergeFields({
    isForCustomObject: false,
    customObjectClassName: 'contacts',
    customObject: null,

    mergeFieldName: 'contact',
    teamMemberCategoryName: t('Team Member'),
  });

  const multipleMergeFields = useMemo(() => {
    if (isForCustomObject) {
      return [
        { label: 'Contacts', fields: contactMergeFields },
        { label: mergeArgs?.customObject?.description, fields: mergeFields },
      ];
    }
    return null;
  }, [isForCustomObject, mergeArgs, mergeFields, contactMergeFields]);

  const { scrollNextStop, componentRef } = useScrollIntoView(scrollOptions);

  const onChangeEmail = useChangeAutomationsMessages(values, onChange);

  const emailData = cleanEmailData(values?.email);

  const { objectToModifyOptions } = useMemo(() => {
    if (automation?.customObject && automationContextFields) {
      const seen: Record<string, boolean> = {};
      const automationRelatedFields = automationContextFields.filter(
        (f: any) => f?.relation && f?.relation?.relatedObjectName === 'Contacts'
      );

      const objectToModifyOptions = automationRelatedFields
        .filter((f: RelatedField) => {
          const { relatedObject } = f.relation;
          return Object.prototype.hasOwnProperty.call(seen, relatedObject)
            ? false
            : (seen[relatedObject] = true);
        })
        .reduce((acc: any[], f: RelatedField) => {
          const fieldsToDisplay = automationRelatedFields
            .filter(
              ({ relation }: { relation: Relation }) =>
                relation.relatedObject === f.relation.relatedObject
            )
            .map((f: RelatedField) => ({
              value: f.id, // what should this be
              label: f.displayName,
              relation: f.relation,
            }));

          return fieldsToDisplay.length
            ? [
                ...acc,
                {
                  key: f.relation.relatedObject,
                  label: f.relation.relatedObjectName,
                  entityName: f.relation.relatedEntityName,
                  relation: f.relation,
                  options: fieldsToDisplay,
                },
              ]
            : acc;
        }, [])
        .sort((a: any, b: any) => {
          return a.label.localeCompare(b.label, 'en', {
            sensitivity: 'base',
          });
        });

      return { automationRelatedFields, objectToModifyOptions };
    }

    return { automationRelatedFields: [], objectToModifyOptions: [] };
  }, [automation, automationContextFields]);

  return (
    <div
      ref={componentRef}
      onClick={(evt) => {
        const { nativeEvent } = evt;
        if ((nativeEvent?.offsetY || 0) < ClickScrollLimit) {
          scrollNextStop(evt)();
        }
      }}
    >
      <Cols columns={2} gutter={`${gutters.spacing(4)}px`}>
        <div>
          <LabelWithTooltip
            label={t(`Send Email to Related Contact(s) in Field`)}
            tooltipText={t(
              'This email will be sent individually to all contacts within the selected relationship field.'
            )}
            placement="top"
          />
          <Select
            placeholder={t('Choose Related Contact')}
            menuInline
            margin
            options={objectToModifyOptions}
            value={values.sendToContactField}
            onChange={(val: any) =>
              onChange({ ...values, sendToContactField: val })
            }
            data-qa="mra-choose-object-field"
          />
        </div>
        {contextIsCustomObject ? (
          <div>
            <SwitchControl
              label={t('Always Send Email from Owner')}
              labelInfo={t(
                'All contact(s) within the selected field will receive the chosen email from the owner of the entity that initiated this automation, regardless of email settings.'
              )}
              labelInfoPlacement={'top'}
            >
              <Switch
                checked={sendFromOwner}
                onChange={({ target }) => {
                  onChange({ ...values, sendFromOwner: target?.checked });
                }}
                data-qa="always-send-email-from-owner"
              />
            </SwitchControl>
          </div>
        ) : null}
      </Cols>
      <Cols columns={2} gutter={`20px`}>
        <EmailSendOptions
          values={values}
          onChange={onChange}
          transxHook={useEmailSendOptions}
          whichTeamMember={'ccTeamMember'}
          label={t('CC Team Members')}
          addPrefixNone
          allowTrackingWarning
        />
      </Cols>
      <EmailPicker
        email={{
          ...emailData,
          sender: {
            ...emailData?.sender,
            subType: sendFromOwner && SENDER_TYPE.ENTITY_RECORD_OWNER,
          },
        }}
        onChange={onChangeEmail}
        readonlySenderInfo={false}
        mergeFields={mergeFields}
        isForCustomObject={isForCustomObject}
        multipleMergeFields={multipleMergeFields}
        useAllIntgegratedInboxes
      />
    </div>
  );
};

export const validate = ({ email, sendToContactField }: ValidateProps) =>
  email && sendToContactField && validIntegratedInbox(email);
