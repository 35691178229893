import { useCallback, useMemo } from 'react';
import { usePermissionsContext } from './context';
import type { AccessNumber } from '../src/types';
import { getAccessNumber } from '../src/utils';

/**
 * Sets the permission of all provided keys. The permission value will be set to the
 * closest permission value in its `allowed_access` range if the access parameter is
 * not in the permission's `allowed_access`.
 */
export const usePermissionSetAll = () => {
  const ctx = usePermissionsContext();

  const setter = useCallback(
    (keys: string[], access: AccessNumber) => {
      for (const key of keys) {
        if (ctx.permissions.has(key) && ctx.sources.has(key)) {
          const source = ctx.sources.get(key)!;
          const result = ctx.permissions.get(key)!.value;
          const { allowed_access, value: current } = result.deref()!;

          if (allowed_access.length < 2) {
            continue;
          }

          const min = getAccessNumber(allowed_access[0]);
          const max = getAccessNumber(
            allowed_access[allowed_access.length - 1]
          );
          const value = typeof current === 'boolean' ? Boolean(access) : access;
          const newValue =
            typeof value === 'boolean'
              ? value
              : value < min
                ? min
                : value > max
                  ? max
                  : value;

          source.next({
            value: newValue,
            allowed_access: source.deref()!.allowed_access,
          });
        }
      }
    },
    [ctx]
  );

  return useMemo(
    () => ({
      setNone: (keys: string[]) => setter(keys, 0),
      setView: (keys: string[]) => setter(keys, 1),
      setEdit: (keys: string[]) => setter(keys, 2),
      setRemove: (keys: string[]) => setter(keys, 3),
    }),
    [setter]
  );
};
