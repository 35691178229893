import React, { useMemo, useRef, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import Select from '../Select';
import * as customizeComponents from './customize';
import { groupableOptionProp, selectedValueProp } from '../props';
import IconAdornment from '../Adornments/IconAdornment';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { applyRef } from 'components/Inputs/props';

export function multiSelectSortValue(value) {
  if (!value) {
    return value;
  }
  return [...value].sort((a, b) => {
    if (b.error && !a.error) {
      return 1;
    }
    return String(a.label).localeCompare(String(b.label));
  });
}

export function useMultiSelectValue({ value, options }) {
  return useMemo(() => {
    if (!value || !options || options === DROPDOWN_SHOULD_LOAD) {
      return value;
    }
    return multiSelectSortValue(
      value
        .map((val) =>
          typeof val === 'string'
            ? options.find((opt) => opt.value === val)
            : val
        )
        // do not allow to select group placeholder
        .filter((item) => !item?.groupPlaceholder)
        .filter(Boolean)
    );
  }, [value, options]);
}

const MultiSelect = React.forwardRef(
  (
    {
      fieldId,
      value: valueProp,
      onChange,
      options,
      endAdornment,
      components: componentsProp,
      setShown,
      handleSubmit,
      kdsCompatability,
      ...props
    },
    ref
  ) => {
    const { assignFieldHandle } = useKeyBoardContext();
    const value = useMultiSelectValue({ value: valueProp, options });
    const handleChange =
      onChange &&
      ((val, ...args) => {
        return onChange(multiSelectSortValue(val), ...args);
      });
    const selectRef = useRef();

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, props);

    assignFieldHandle(fieldId, {
      customTab: (e) => {
        e.preventDefault();
        setShown?.(false);
        handleSubmit?.();
      },
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        setShown?.(false);
        return selectRef.current?.select;
      },
      customEscape: () => {
        setMenuIsOpen(false);
        setShown?.(false);
      },
      customEnter: (e) => {
        setShown?.(true);
        return selectRef.current.state.menuIsOpen;
      },
      customUp: () => {
        setShown?.(true);
        setMenuIsOpen(true);
      },
      customDown: () => {
        setShown?.(true);
        setMenuIsOpen(true);
      },
    });

    const mergeRef = useCallback(
      (el) => {
        applyRef(selectRef, el);
        applyRef(ref, el);
      },
      [ref]
    );

    return (
      <Select
        ref={mergeRef}
        isMulti
        value={value}
        onChange={handleChange}
        options={options}
        components={{ ...customizeComponents, ...componentsProp }}
        closeMenuOnSelect={false}
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        endAdornment={endAdornment || <IconAdornment icon="search" />}
        kdsCompatability={kdsCompatability}
        {...props}
        {...customSelectMenuProps}
      />
    );
  }
);

MultiSelect.displayName = 'MultiSelect';

// Also all props from Select
MultiSelect.propTypes = {
  value: PropTypes.arrayOf(selectedValueProp),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(groupableOptionProp),
  components: PropTypes.object,
  PillComponent: PropTypes.elementType,
  endAdornment: PropTypes.elementType,
};

MultiSelect.defaultProps = {
  value: [],
  onChange: null,
  options: [],
  components: null,
  PillComponent: null,
  endAdornment: null,
};

export default MultiSelect;
