import { useMemo } from 'react';
import NumberInput from 'react-number-format';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';
import { layers } from 'app/spacing';
import { colorsButton, grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import Avatar from 'components/Kizen/images/scott-avatar.svg?react';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';
import BaseColumn from 'components/Board/BaseColumn';
import BaseColumnTitle from 'components/Board/BaseColumnTitle';
import { SUMMARY_VALUES } from 'store/customObjectsRecordsPage/records.redux';
import BaseCard, {
  DragIcon,
  TitleWrapper,
  DateWrapper,
  Time,
} from 'components/Board/BaseCard';
import { FIELD_TYPES } from 'utility/constants';
import FakeCard, { StyledIconWrapper } from './FakeCard';
import FieldPreview from './FieldPreview';
import Subsection from 'components/Modals/Common/Subsection';
import { fontSizes } from 'app/typography';

const StyledSubsection = styled(Subsection)`
  margin-top: -1px; //design nudge
`;
const FADED_COLUMN_WIDTH = 92;
const COLUMN_MARGIN = 10;
const ADDITIONAL_DESKTOP_WIDTH = FADED_COLUMN_WIDTH * 2 + COLUMN_MARGIN * 2;
const MOBILE_COLUMN_WIDTH = 255;
const MIN_TABLE_WIDTH = 200;

const ContentWrapper = styled.div`
  display: flex;
  margin: 3px auto 17px;
  ${({ columnWidth, isMobile }) => css`
    width: ${isMobile
      ? MOBILE_COLUMN_WIDTH
      : columnWidth + ADDITIONAL_DESKTOP_WIDTH}px;

    ${!isMobile &&
    css`
      & > div:nth-last-child(2) {
        margin-right: 0;
      }
    `}
  `}
`;

const Column = styled(BaseColumn)`
  margin-right: 10px;
`;

const FakeColumn = styled(Column)`
  box-shadow: none;
  overflow: hidden;
`;

const StyledColumnTitle = styled(BaseColumnTitle)`
  padding: 19px 19px 7px 20px;
  && label:hover > span:first-of-type {
    border-color: ${grayScale.mediumDark};
  }
`;

const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
  display: block;
`;

const StyledSecondaryTextEllipsisWithTooltip = styled(
  StyledTextEllipsisWithTooltip
)`
  font-size: ${fontSizes.small};
`;
const CardsWrapper = styled.div`
  padding: 0 15px 10px;
`;

const StyledDateWrapper = styled(DateWrapper)`
  margin-right: 10px;
`;

const StyledAvatar = styled(Avatar)`
  border-radius: 50%;
`;

const FieldsWrapper = styled.div`
  & > * {
    margin-top: 11px;
  }

  & > *:first-child {
    margin-top: 10px;
  }

  a {
    display: block;
  }
`;

const LeftGradient = styled((props) => (
  <BaseGradient fadeStart={40} fadeLength={70} {...props} />
))`
  z-index: ${layers.content(0, 1)};
`;

const RightGradient = styled((props) => (
  <BaseGradient fadeStart={47} fadeLength={77} {...props} />
))`
  z-index: ${layers.content(0, 1)};
`;

const Preview = ({
  values,
  fields,
  stageName,
  teamMember,
  isMobile,
  ...props
}) => {
  const { t } = useTranslation();
  const summaryFieldType = useMemo(() => {
    if (values.summaryMetric !== SUMMARY_VALUES.noSummary) {
      if (values.summaryMetric === SUMMARY_VALUES.numberOfRecords) {
        return { type: FIELD_TYPES.Integer.type };
      }
      if (values.summaryMetric === SUMMARY_VALUES.totalValue) {
        return { type: FIELD_TYPES.Money.type };
      }
      const field = fields.find((item) => item.id === values.summaryMetric);
      if (!field) return null;
      if (field.fieldType === FIELD_TYPES.Money.type) {
        return {
          type: FIELD_TYPES.Money.type,
          symbol: field.moneyOptions.symbol,
        };
      }
      return { type: field.fieldType };
    }
    return null;
  }, [fields, values.summaryMetric]);

  const secondarySummaryFieldType = useMemo(() => {
    if (values.secondarySummaryMetric !== SUMMARY_VALUES.noSummary) {
      if (values.secondarySummaryMetric === SUMMARY_VALUES.numberOfRecords) {
        return { type: FIELD_TYPES.Integer.type };
      }
      if (values.secondarySummaryMetric === SUMMARY_VALUES.totalValue) {
        return { type: FIELD_TYPES.Money.type };
      }
      const field = fields.find(
        (item) => item.id === values.secondarySummaryMetric
      );
      if (!field) return null;
      if (field.fieldType === FIELD_TYPES.Money.type) {
        return {
          type: FIELD_TYPES.Money.type,
          symbol: field.moneyOptions.symbol,
        };
      }
      return { type: field.fieldType };
    }
    return null;
  }, [fields, values.secondarySummaryMetric]);

  const hasValueMetric =
    values.summaryMetric !== SUMMARY_VALUES.noSummary && !!summaryFieldType;

  const hasSecondaryValueMetric =
    values.secondarySummaryMetric !== SUMMARY_VALUES.noSummary &&
    !!secondarySummaryFieldType;

  const showDecimalPart =
    summaryFieldType &&
    (summaryFieldType.type === FIELD_TYPES.Money.type ||
      summaryFieldType.type === FIELD_TYPES.Decimal.type);

  const showSecondaryDecimalPart =
    secondarySummaryFieldType &&
    (secondarySummaryFieldType.type === FIELD_TYPES.Money.type ||
      secondarySummaryFieldType.type === FIELD_TYPES.Decimal.type);

  const columnWidth = useMemo(
    () =>
      Number(values.columnWidth) <= MIN_TABLE_WIDTH
        ? MIN_TABLE_WIDTH
        : Number(values.columnWidth),
    [values.columnWidth]
  );

  return (
    <StyledSubsection title={t('Board View Preview')} {...props}>
      <ContentWrapper columnWidth={columnWidth} isMobile={isMobile} {...props}>
        {!isMobile && (
          <>
            <LeftGradient position="left" fadeIn />
            <FakeColumn columnWidth={FADED_COLUMN_WIDTH}>
              <StyledColumnTitle
                checkBoxData={{
                  isSelected: false,
                  checkboxOptions: [],
                  onChangeSelection: () => {},
                }}
              >
                <TextEllipsisWithTooltip weight="bold" />
                {hasValueMetric && (
                  <NumberInput
                    value={1}
                    displayType="text"
                    renderText={(value) => (
                      <KizenTypography>{value}</KizenTypography>
                    )}
                  />
                )}
                {hasSecondaryValueMetric && (
                  <NumberInput
                    value={1}
                    displayType="text"
                    renderText={(value) => (
                      <StyledSecondaryTextEllipsisWithTooltip>
                        {value}
                      </StyledSecondaryTextEllipsisWithTooltip>
                    )}
                  />
                )}
              </StyledColumnTitle>
              <CardsWrapper>
                <FakeCard
                  fieldsAmount={values.fields.length}
                  showOwner={values.ownerVisible}
                  showESTDate={false}
                  showTitle={false}
                  hide
                  showTime={
                    values.estimatedCloseDateVisible ||
                    values.timeInStageVisible
                  }
                />
                <FakeCard
                  fieldsAmount={values.fields.length}
                  showOwner={values.ownerVisible}
                  showESTDate={false}
                  showTitle={false}
                  hide
                  showTime={
                    values.estimatedCloseDateVisible ||
                    values.timeInStageVisible
                  }
                />
              </CardsWrapper>
            </FakeColumn>
          </>
        )}
        <Column
          onClickPlus={() => {}}
          columnWidth={isMobile ? MOBILE_COLUMN_WIDTH : columnWidth}
        >
          <StyledColumnTitle
            checkBoxData={{
              isSelected: false,
              checkboxOptions: [],
              onChangeSelection: () => {},
            }}
          >
            <TextEllipsisWithTooltip weight="bold">
              {stageName}
            </TextEllipsisWithTooltip>
            {hasValueMetric && (
              <NumberInput
                value={1000000}
                displayType="text"
                thousandSeparator
                prefix={
                  summaryFieldType &&
                  summaryFieldType.type === FIELD_TYPES.Money.type
                    ? summaryFieldType.symbol || '$'
                    : null
                }
                fixedDecimalScale={showDecimalPart}
                decimalScale={showDecimalPart ? 2 : null}
                renderText={(value) => (
                  <StyledTextEllipsisWithTooltip>
                    {`${value} ${values.summaryExplanation}`}
                  </StyledTextEllipsisWithTooltip>
                )}
              />
            )}
            {hasSecondaryValueMetric && (
              <NumberInput
                value={1000000}
                displayType="text"
                thousandSeparator
                prefix={
                  secondarySummaryFieldType &&
                  secondarySummaryFieldType.type === FIELD_TYPES.Money.type
                    ? secondarySummaryFieldType.symbol || '$'
                    : null
                }
                fixedDecimalScale={showSecondaryDecimalPart}
                decimalScale={showSecondaryDecimalPart ? 2 : null}
                renderText={(value) => (
                  <StyledSecondaryTextEllipsisWithTooltip>
                    {`${value} ${values.secondarySummaryExplanation}`}
                  </StyledSecondaryTextEllipsisWithTooltip>
                )}
              />
            )}
          </StyledColumnTitle>
          <CardsWrapper>
            <BaseCard
              title={
                <>
                  <TitleWrapper>
                    <StyledIconWrapper size="15px">
                      <DragIcon icon="reorder" />
                    </StyledIconWrapper>
                    <KizenTypography color={colorsButton.blue.default}>
                      {t('Sample Record')}
                    </KizenTypography>
                  </TitleWrapper>
                  <StyledIconWrapper size="15px">
                    <Icon icon="three-dot" />
                  </StyledIconWrapper>
                </>
              }
              footer={
                (values.estimatedCloseDateVisible ||
                  values.timeInStageVisible ||
                  values.ownerVisible) && (
                  <>
                    <div>
                      {values.estimatedCloseDateVisible && (
                        <StyledDateWrapper>
                          <KizenTypography type="small">
                            {`12/31/${new Date().getFullYear()}`}
                          </KizenTypography>
                        </StyledDateWrapper>
                      )}
                      {values.timeInStageVisible && (
                        <Time type="small" as="span">
                          {t('1d')}
                        </Time>
                      )}
                    </div>
                    {values.ownerVisible && <StyledAvatar />}
                  </>
                )
              }
            >
              <FieldsWrapper>
                {values.fields.map((item) => (
                  <FieldPreview
                    key={item.value}
                    field={item.field}
                    teamMember={teamMember}
                  />
                ))}
              </FieldsWrapper>
            </BaseCard>
            <FakeCard
              fieldsAmount={values.fields.length}
              showESTDate={values.estimatedCloseDateVisible}
              showTime={values.timeInStageVisible}
              showOwner={values.ownerVisible}
            />
          </CardsWrapper>
        </Column>
        {!isMobile && (
          <>
            <FakeColumn columnWidth={FADED_COLUMN_WIDTH}>
              <StyledColumnTitle>
                <TextEllipsisWithTooltip weight="bold">
                  {stageName}
                </TextEllipsisWithTooltip>
                {hasValueMetric && (
                  <NumberInput
                    value={1000000}
                    displayType="text"
                    thousandSeparator
                    prefix={
                      summaryFieldType === FIELD_TYPES.Money.type ? '$' : null
                    }
                    renderText={(value) => (
                      <KizenTypography>{value}</KizenTypography>
                    )}
                  />
                )}
                {hasSecondaryValueMetric && (
                  <NumberInput
                    value={1000000}
                    displayType="text"
                    thousandSeparator
                    prefix={
                      summaryFieldType === FIELD_TYPES.Money.type ? '$' : null
                    }
                    renderText={(value) => (
                      <StyledSecondaryTextEllipsisWithTooltip>
                        {value}
                      </StyledSecondaryTextEllipsisWithTooltip>
                    )}
                  />
                )}
              </StyledColumnTitle>
              <CardsWrapper>
                <FakeCard
                  fieldsAmount={values.fields.length}
                  showESTDate={values.estimatedCloseDateVisible}
                  showTime={
                    !values.estimatedCloseDateVisible &&
                    values.timeInStageVisible
                  }
                  showOwner={values.ownerVisible}
                />
                <FakeCard
                  fieldsAmount={values.fields.length}
                  showESTDate={values.estimatedCloseDateVisible}
                  showTime={
                    !values.estimatedCloseDateVisible &&
                    values.timeInStageVisible
                  }
                  showOwner={values.ownerVisible}
                />
              </CardsWrapper>
            </FakeColumn>
            <RightGradient position="right" fadeIn />
          </>
        )}
      </ContentWrapper>
    </StyledSubsection>
  );
};

export default Preview;
