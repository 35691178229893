import styled from '@emotion/styled';
import Select from 'components/Inputs/Select';
import { layers } from 'app/spacing';

export const StyledSelect = styled(Select)`
  z-index: ${layers.content(0, 5)};
`;

export const StyledSelectMenu = styled(Select)`
  z-index: ${layers.content(0, 5)};
  width: auto !important;
`;
