import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

import { grayScale } from 'app/colors';
import {
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
  TRow,
} from 'components/Kizen/Table';
import { gutters, useBreakpoint, borderRadii } from 'app/spacing';
import LeadSourceService from 'services/LeadSourceServiceV2';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';

import BasicTable from 'components/Tables/Basic';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { Gradient as BaseGradient } from 'components/Kizen/ScrollFadeContainer';
import TablePagination from 'components/Tables/Big/TablePagination';

import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';

const defaultPageSettings = {
  pageNumber: 1,
  pageSize: 250,
};

// max-height: 3000px. We need a max-height to get the table scroll working in the modal
// and setting it this big means the modal can expand as it normally does.

const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: 3000px;
  position: relative;
`;
const TableWrapper = styled.div`
  width: 100%;
  flex: 1;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  overflow-y: hidden;
  flex-direction: column;
  position: relative;
  height: 100%;
  > div {
    position: relative;
    height: 100%;
  }
  min-height: 135px; // create some height for the pagination menu
`;
const PaginationWrapper = styled.div`
  width: 100%;

  flex: 0 0 45px;
  padding-top: ${gutters.spacing(3)}px;
`;

const StyledTableScrollContainer = styled(TableScrollContainer)`
  height: 100%;
  &.mobile .gradient-vertical-container {
    max-width: 100%;
    width: 100%;
  }
`;

export const StyledBasicTable = styled(BasicTable)`
  &.mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    thead {
      border-bottom: 1px solid ${grayScale.mediumLight};
      padding: 0 ${gutters.spacing(3)}px;
      position: sticky;
      top: 0;
      background-color: ${grayScale.white};
    }
    thead > tr {
      display: flex;
      width: 100%;
      align-items: center;
      th {
        border-bottom: none;
        max-width: 50%;
        width: 100%;
      }
    }
    tbody {
      padding: 0 ${gutters.spacing(3)}px;
    }
    tbody > tr {
      display: flex;
      width: 100%;
      align-items: center;
      td {
        max-width: 50%;
        width: 100%;
      }
    }
  }
`;

const createColumns = (isMobile, t) => {
  const colPadding = '25px';
  const columns = isMobile
    ? [
        {
          id: 'Display',
          headCell: (
            <SortableHeadCell
              by="source"
              label={t('Source')}
              minWidth="145px"
              padding="20px"
            />
          ),
          cell: <SizedCell minWidth="145px" padding="20px" />,
          format: ({ source }) => (
            <TextEllipsisWithTooltip>{source}</TextEllipsisWithTooltip>
          ),
        },
        {
          id: 'created',
          headCell: (
            <SortableHeadCell
              label={t('Date Added')}
              minWidth="100px"
              numeric
            />
          ),
          cell: <SizedCell minWidth="100px" />,
          format: (created) => (
            <TextEllipsisWithTooltip>
              {created && format(created, 'MM/DD/YYYY')}
            </TextEllipsisWithTooltip>
          ),
        },
      ]
    : [
        {
          id: 'spacer-start',
          headCell: <SizedCell width="15px" />,
          cell: <SizedCell width="15px" />,
          format: () => <span />,
        },
        {
          id: 'Display',
          headCell: (
            <SortableHeadCell
              by="type"
              label={t('Type')}
              width="100px"
              padding={colPadding}
            />
          ),
          cell: <SizedCell width="100px" padding={colPadding} />,
          format: ({ type }) => {
            return <TextEllipsisWithTooltip>{type}</TextEllipsisWithTooltip>;
          },
        },
        {
          id: 'Display',
          headCell: (
            <SortableHeadCell
              by="source"
              label={t('Source')}
              width="145px"
              padding={colPadding}
            />
          ),
          cell: <SizedCell width="145px" padding={colPadding} />,
          format: ({ source }) => (
            <TextEllipsisWithTooltip>{source}</TextEllipsisWithTooltip>
          ),
        },
        {
          id: 'Display',
          headCell: (
            <SortableHeadCell
              by="campaign"
              label={t('Campaign')}
              width="145px"
              padding={colPadding}
            />
          ),
          cell: <SizedCell width="145px" padding={colPadding} />,
          format: ({ campaign }) => (
            <TextEllipsisWithTooltip>{campaign}</TextEllipsisWithTooltip>
          ),
        },
        {
          id: 'Display',
          headCell: (
            <SortableHeadCell
              by="medium"
              label={t('Medium')}
              width="145px"
              padding={colPadding}
            />
          ),
          cell: <SizedCell width="145px" padding={colPadding} />,
          format: ({ medium }) => (
            <TextEllipsisWithTooltip>{medium}</TextEllipsisWithTooltip>
          ),
        },
        {
          id: 'Display',
          headCell: (
            <SortableHeadCell
              by="term"
              label={t('Term')}
              width="145px"
              padding={colPadding}
            />
          ),
          cell: <SizedCell width="145px" padding={colPadding} />,
          format: ({ term }) => (
            <TextEllipsisWithTooltip>{term}</TextEllipsisWithTooltip>
          ),
        },
        {
          id: 'content',
          headCell: (
            <SortableHeadCell
              by="content"
              label={t('Content')}
              width="145px"
              padding={colPadding}
            />
          ),
          cell: <SizedCell width="145px" padding={colPadding} />,
          format: (content) => (
            <TextEllipsisWithTooltip>{content}</TextEllipsisWithTooltip>
          ),
        },
        {
          id: 'created',
          headCell: (
            <SortableHeadCell label={t('Date Added')} width="158px" numeric />
          ),
          cell: <SizedCell width="158px" />,
          format: (created) => (
            <DateTimeInputInline value={created} readOnly showDateTooltip />
          ),
        },
        {
          id: 'spacer-end',
          // Leave room for persistent scroll bar
          headCell: <SizedCell minWidth={`${15 - 4}px`} />,
          cell: <SizedCell minWidth={`${15 - 4}px`} />,
          format: () => <span />,
        },
      ];

  return [...columns];
};

const LeadSourceDetailsTable = ({ recordId, initData }) => {
  const { t } = useTranslation();

  const [leadSources, setLeadSources] = useState(initData);
  const [leadSourcesCount, setLeadSourcesCount] = useState(initData.length);
  const [page, setPage] = useState(defaultPageSettings.pageNumber);
  const [pageSize, setPageSize] = useState(defaultPageSettings.pageSize);
  const isMobile = useBreakpoint();

  const [ordering, setOrdering] = useState('-created');
  const sortValue = useMemo(() => getSortValue(ordering), [ordering]);

  const handleSort = useCallback(
    (orderingValues) => {
      setOrdering(getOrderingParam(orderingValues));
    },
    [setOrdering]
  );

  useEffect(() => {
    (async () => {
      if (recordId) {
        const { results, count } = await LeadSourceService.getAll(recordId, {
          ordering,
          page,
          pageSize,
        });
        setLeadSources(results);
        setLeadSourcesCount(count);
      }
    })();
  }, [recordId, ordering, page, pageSize, initData.length]);

  const headData = useMemo(
    () => ({
      meta: {
        sort: sortValue,
        onSort: (column, direction) => handleSort({ column, direction }),
      },
    }),
    [sortValue, handleSort]
  );

  const handleChangePageSize = (size) => {
    setPage(defaultPageSettings.pageNumber);
    setPageSize(size);
  };

  const columns = useMemo(() => createColumns(isMobile, t), [isMobile, t]);

  return (
    <ComponentWrapper>
      <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
        <ScrollContainerBlocker top="1" />
      </ScrollContainerBlockerWrapper>
      <TableWrapper>
        <StyledTableScrollContainer
          className={isMobile ? 'mobile' : ''}
          bottom
          top
          isMobile={isMobile}
          gradient={<BaseGradient />}
          refresh={[leadSources]}
        >
          <StyledBasicTable
            key={`table-${recordId}-${isMobile}`}
            stickyHeader
            className={isMobile ? 'mobile' : ''}
            head={<TRow head columns={columns} data={headData} />}
            data-qa="leadSource-details-table"
          >
            {/* Just a spacer when there are no results */}
            {(!leadSources || !leadSources.length) && <TRow />}
            {(leadSources || []).map((leadSource) => (
              <TRow key={leadSource.id} columns={columns} data={leadSource} />
            ))}
          </StyledBasicTable>
        </StyledTableScrollContainer>
      </TableWrapper>

      <PaginationWrapper>
        <TablePagination
          small={isMobile}
          page={page}
          perPage={pageSize}
          totalCount={leadSourcesCount}
          onChangePage={setPage}
          onChangePerPage={handleChangePageSize}
        />
      </PaginationWrapper>
    </ComponentWrapper>
  );
};

export default LeadSourceDetailsTable;
