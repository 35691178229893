import {
  DEFAULT_ATTACHMENTS_CONTAINER_PROPS,
  EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
} from '@kizen/page-builder/nodes/containers';

export const DEFAULT_ATTACHMENTS_PROPS = {
  ...DEFAULT_ATTACHMENTS_CONTAINER_PROPS,
  ...EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
  attachmentIconUrl: `${window.location.origin}/images/emails/file-arrow-down.png`,
};
