import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TRow } from 'components/Kizen/Table';
import { getAutomationsColumns } from './columns';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import BasicTable from 'components/Tables/Basic';
import styled from '@emotion/styled';
import TablePagination from 'components/Tables/Big/TablePagination';
import { gutters } from 'app/spacing';
import { border, grayScale } from 'app/colors';
import { getOrderingParam } from 'utility/SortingHelpers';
import LibraryService from 'services/LibraryService';
import Loader from 'components/Kizen/Loader';
import { css } from '@emotion/core';
import { useAsync } from 'react-use';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import Breadcrumbs from 'components/Breadcrumbs';
import { fontSizes } from 'app/typography';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const BreadcrumbsWrapper = styled.div`
  display: flex;
  height: ${fontSizes.buttonLabel};
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${gutters.spacing(3, 2)}px;
`;

const CenteredLoader = styled(Loader)`
  position: absolute;
  top: 60%;
  left: 50%;
  padding-top: 0 !important;
  transform: translate(-50%, 0%);
`;

const TableWrapper = styled.div`
  ${border}
  border-color: ${grayScale.mediumLight};
`;

const StyledTableScrollContainer = styled(TableScrollContainer)`
  max-height: calc(100vh - 490px);

  ${({ loading }) => {
    return (
      loading &&
      css`
        min-height: calc(100vh - 490px);
        height: 100%;
      `
    );
  }}
`;

const PaginationWrapper = styled.div`
  padding: ${gutters.spacing(4)}px 0 ${gutters.spacing(0, 2)}px 0;
`;

const initialConfig = {
  ordering: {
    column: 'name',
    direction: 'asc',
  },
  page: {
    number: 1,
    size: 50,
    search: '',
  },
  loaded: false,
};

const Automations = ({
  t,
  onSubmit,
  onActionView,
  toolbar,
  setItemsCount,
  search,
  resetSearch,
  setCurrentFolder,
}) => {
  const [config, setConfig] = useState(initialConfig);
  const { ordering, page } = config;
  const loadedOnce = useRef(true);
  const prevData = useRef({});
  const { directories, handleChangeDirectory, parentFolder, currentFolder } =
    useBreadcrumbs({
      name: t('Automations'),
    });

  useEffect(() => {
    if (!loadedOnce.current)
      setConfig((prev) => ({
        ...prev,
        page: { ...prev.page, number: 1, search },
      }));
  }, [search]);

  const { value: data = {}, loading } = useAsync(async () => {
    const ordering = getOrderingParam(config.ordering);
    const { id } = currentFolder;
    const data = id
      ? await LibraryService.getAllAutomationsInFolder({
          id: id,
          ...config,
          ordering,
        })
      : await LibraryService.getAllAutomations({
          ...config,
          ordering,
        });
    loadedOnce.current = false;
    const resultsWithType = data.results.map((el) => ({
      ...el,
      type: 'folder',
    }));
    setItemsCount(data.count);
    return id ? data : { ...data, results: resultsWithType };
  }, [config, currentFolder]);

  if (!loading) prevData.current = data;
  const { results: automations = [], count = 0 } = prevData.current;

  const automationsWithParent = useMemo(() => {
    if (!automations.length) {
      return automations;
    }
    const backToParent = directories.length > 1 && {
      id: parentFolder.id,
      name: `${t('Back to')} '${parentFolder.name}'`,
      type: 'folder',
      levelUp: true,
      created: null,
    };

    return backToParent ? [backToParent, ...automations] : automations;
  }, [automations, parentFolder, directories, t]);

  const handleChangeSort = useCallback(
    ({ column, direction }) =>
      setConfig((prev) => ({ ...prev, ordering: { column, direction } })),
    [setConfig]
  );

  const handleChangePageNumber = useCallback(
    (value) =>
      setConfig((prev) => ({
        ...prev,
        page: {
          ...prev.page,
          number: value,
        },
      })),
    [setConfig]
  );

  const handleChangePageSize = useCallback(
    (value) =>
      setConfig((prev) => ({
        ...prev,
        page: {
          ...prev.page,
          number: 1,
          size: value,
        },
      })),
    [setConfig]
  );

  const automationsHeadData = useMemo(
    () => ({
      meta: {
        sort: ordering,
        onSort: (column, direction) => handleChangeSort({ column, direction }),
      },
    }),

    [ordering, handleChangeSort]
  );

  const onChangeDirectory = useCallback(
    (folder) => {
      setConfig((prev) => ({
        ...prev,
        page: {
          ...prev.page,
          // start from 1st page to prevent flickering on table load
          number: 1,
        },
      }));
      handleChangeDirectory(folder);
      resetSearch();
      setItemsCount(0);
      setCurrentFolder(folder);
    },

    [handleChangeDirectory, resetSearch, setCurrentFolder, setItemsCount]
  );

  const messagesCount = automations.filter(
    ({ type }) => type && type !== 'folder'
  ).length;

  const automationsColumns = useMemo(
    () =>
      getAutomationsColumns({
        handleChangeDirectory: onChangeDirectory,
        parentFolder,
        onSubmit,
        onActionView,
        messagesCount,
        t,
      }),
    [onChangeDirectory, onActionView, onSubmit, parentFolder, messagesCount, t]
  );

  return (
    <>
      <BreadcrumbsWrapper>
        <Breadcrumbs
          directories={directories}
          handleChangeDirectory={onChangeDirectory}
        />
      </BreadcrumbsWrapper>
      {toolbar}
      <TableWrapper>
        <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
          <ScrollContainerBlocker />
        </ScrollContainerBlockerWrapper>
        <StyledTableScrollContainer
          loading={loadedOnce.current && loading}
          bottom
          left
          right
          refresh={[automationsWithParent]}
          rounded
        >
          <BasicTable
            stickyHeader
            columns={automationsColumns}
            head={
              <TRow
                head
                columns={automationsColumns}
                data={automationsHeadData}
              />
            }
          >
            {automationsWithParent &&
              automationsWithParent.map((item) => (
                <TRow key={item.id} columns={automationsColumns} data={item} />
              ))}
            {loadedOnce.current && loading && <CenteredLoader loading />}
          </BasicTable>
        </StyledTableScrollContainer>
      </TableWrapper>
      <PaginationWrapper>
        <TablePagination
          page={page.number}
          perPage={page.size}
          totalCount={count}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      </PaginationWrapper>
    </>
  );
};

Automations.propTypes = {};

export default Automations;
