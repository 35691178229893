import css from '@emotion/css';
import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import OverlayTrigger from 'react-bootstrap2/OverlayTrigger';
import { useTranslation } from 'react-i18next';
import { appBackground, grayScale } from 'app/colors';
import { borderRadii } from 'app/spacing';
import { fontSizes, fontWeights, KizenTypography } from '__app/typography';
import Button from '__components/Button';
import AddButton from '__components/Button/AddButton';
import Icon from '__components/Kizen/Icon';
import { ModalFooter } from 'components/Modals';
import Switch from 'components/Kizen/Switch';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import KizenTooltip from '__components/Kizen/Tooltip';
import WholeNumberTextInput from 'components/Inputs/TextInput/presets/WholeNumber';
import { CONTENT_MAX_WIDTH } from 'components/Layout/PageContentWidth';
import { ImageSelector, SelectedFileLabel } from '../ImageSelector';
import { SubsectionHeader } from '../TraySection';

type AddSaveRowProps = {
  onAdd(): void;
  onSave(): void;
};

type Image = {
  id: string;
  name: string;
  url: string;
};

type ChooseImageSectionProps = {
  onImageChange(image: Image): void;
  onImageDelete(image: Image): void;
  onImageRemove(): void;
  imageLibraryOpen?: boolean;
  fileId?: string | null;
  fileName?: string;
};

const inputStyle = (width: number) => css`
  min-width: 80px;
  margin-left: 20px;

  ${width &&
  css`
    width: ${width}px;
  `}
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  min-height: 100px;
`;

export const CardRow = styled.div<{ verticalPadding?: number }>`
  display: flex;
  align-items: flex-start;
  ${({ verticalPadding = 15 }) => css`
    padding: ${verticalPadding}px 15px;
  `}
`;

export const CardRowNoPadding = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Dropdown = styled(Select)<{ width: number }>`
  ${({ width }) => inputStyle(width)}
`;

export const Text = styled(TextInput)<{ width: number }>`
  ${({ width }) => inputStyle(width)}
`;

export const WholeNumberInput = styled(WholeNumberTextInput)<{ width: number }>`
  ${({ width }) => inputStyle(width)}
`;

export const HasLinkSwitch = styled(Switch)`
  min-width: 200px;

  span {
    font-size: ${fontSizes.text};
    font-weight: ${fontWeights.regular};
    color: ${grayScale.dark};
  }
`;

export const InfoIcon = styled(Icon)`
  margin-left: 5px;

  svg {
    width: 10px;
    height: 10px;
    color: ${grayScale.dark};
  }
`;

export const Tooltip = styled(KizenTooltip)`
  width: 285px;

  .tooltip-inner {
    max-width: 100%;
  }
`;

export const SettingsContainer = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SettingsTitle = styled.div`
  display: flex;
  align-items: center;
`;

const ChooseImageSectionContainer = styled.div`
  max-width: 200px;
`;

const StyledImageSelector = styled(ImageSelector)`
  min-width: 200px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-right: -12px;
`;

export const DynamicContentHeader = ({ onClose }: { onClose(): void }) => {
  const { t } = useTranslation();

  return (
    <Header>
      <KizenTypography type="subheader">
        {t('Dynamic Content Rules')}
      </KizenTypography>
      <CloseIcon icon="close-cross" onClick={onClose} />
    </Header>
  );
};

const InstructionText = styled(KizenTypography)`
  max-width: ${CONTENT_MAX_WIDTH};
  background-color: ${appBackground};
  border-radius: ${borderRadii.small};
  line-height: 15.4px;
  padding: 10px;
`;

export const DynamicContentInstructionText = () => {
  const { t } = useTranslation();

  return (
    <InstructionText size="text" weight="regular">
      {t(
        'Based on the rules below, the same block within an email can be sent with content variants to target different contacts/cohorts. The rules will be processed in order from top to bottom, and in the case of a contact meeting multiple rulesets, the first ruleset that the contact meets will be the content sent in the email. If no rules are met, the default content will be sent.'
      )}
    </InstructionText>
  );
};

const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
  padding-bottom: 0;
`;

export const AddSaveRow = ({ onAdd, onSave, ...rest }: AddSaveRowProps) => {
  const { t } = useTranslation();

  return (
    <StyledModalFooter {...rest}>
      <AddButton noSpace onClick={onAdd}>
        {t('Add Content Rule')}
      </AddButton>
      <Button noSpace onClick={onSave}>
        {t('Save')}
      </Button>
    </StyledModalFooter>
  );
};

export const ChooseImageSection = ({
  onImageChange,
  onImageDelete,
  onImageRemove,
  imageLibraryOpen = false,
  fileId = null,
  fileName = '',
  ...rest
}: ChooseImageSectionProps) => (
  <ChooseImageSectionContainer {...rest}>
    <SubsectionHeader>
      <SelectedFileLabel fileName={fileName} />
    </SubsectionHeader>
    <StyledImageSelector
      onImageChange={onImageChange}
      onImageDelete={onImageDelete}
      onImageRemove={onImageRemove}
      imageLibraryOpen={imageLibraryOpen}
      fileId={fileId as any}
      fileName={fileName}
    />
  </ChooseImageSectionContainer>
);

export const DefaultContentSettingsContainer = ({
  children,
}: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <SettingsContainer>
      <SettingsTitle>
        <KizenTypography type="subheader">
          {t('Default Content Settings')}
        </KizenTypography>
        <OverlayTrigger
          overlay={
            <Tooltip
              label={t(
                'The default content that will be shown if the end-user does not meet any of the below dynamic content rules.'
              )}
            />
          }
        >
          <InfoIcon icon="info-circle" />
        </OverlayTrigger>
      </SettingsTitle>
      {children}
    </SettingsContainer>
  );
};

export const DynamicContentSettingsContainer = ({
  children,
}: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <SettingsContainer>
      <SettingsTitle>
        <KizenTypography type="subheader">
          {t('Dynamic Content Rules & Settings')}
        </KizenTypography>
        <OverlayTrigger
          overlay={
            <Tooltip
              label={t(
                'Create and display customized content based on your contact data and cohorts.'
              )}
            />
          }
        >
          <InfoIcon icon="info-circle" />
        </OverlayTrigger>
      </SettingsTitle>
      {children}
    </SettingsContainer>
  );
};
