import SearchState from 'components/AccessRequests/searchState';
import CustomObjectsChartGroupSettingsController from './Controller';

const CustomObjectsChartGroupSettingsPage = () => {
  return (
    <SearchState.Wrapper>
      <CustomObjectsChartGroupSettingsController />
    </SearchState.Wrapper>
  );
};

export default CustomObjectsChartGroupSettingsPage;
