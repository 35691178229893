import { colorsPrimary, colorsSecondary } from 'app/colors';

const oneThousand = 1000;
const oneMillione = oneThousand * 1000;

export const getPercent = (all, part) => {
  if (all === 0 || part === 0) {
    return '(0.00%)';
  }

  let percent = (part / all) * 100;

  if (!Number.isInteger(percent)) {
    percent = percent.toFixed(2);
  }

  return `(${percent}%)`;
};

export const getColor = (stat) => {
  switch (stat) {
    case 'completed':
      return colorsPrimary.green.dark;
    case 'paused':
      return colorsPrimary.orange.dark;
    case 'failed':
      return colorsSecondary.red.dark;
    default:
      return;
  }
};

export const trimNumber = (num) => {
  if (num < oneThousand) {
    return num;
  }

  if (num < oneMillione) {
    return `${(num / oneThousand).toFixed(2)}K`;
  }

  return `${(num / oneMillione).toFixed(2)}M`;
};

export const getStatsTitle = (stat, t) => {
  switch (stat) {
    case 'started':
      return t('Started');
    case 'active':
      return t('Active');
    case 'completed':
      return t('Completed');
    case 'paused':
      return t('Paused');
    case 'failed':
      return t('Failed');
    default:
      return null;
  }
};
