import styled from '@emotion/styled';
import { borderRadii } from 'app/spacing';
import { grayScale } from 'app/colors';

export const TableWrapper = styled.div`
  width: 100%;
  flex: 1;
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  overflow-y: hidden;
  flex-direction: column;
  position: relative;
  height: 100%;
  > div {
    position: relative;
    height: 100%;
  }
`;

export const ComponentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: calc(100vh - 244px);
  position: relative;
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  flex: 0 0 35px;
  margin-top: 15px;
`;
