import Loader from 'components/Kizen/Loader';
import { AREA_RESPONSES, CHART_TYPES } from 'components/Wizards/RTDV/types';
import DashletError from 'pages/Dashboard/components/DashletError';
import { FIELDS } from 'queries/query-keys';
import { useTranslation } from 'react-i18next';
import { useQueries, useQuery } from 'react-query';
import FieldService from 'services/FieldService';

const ChartPermission = ({
  children,
  dashlet,
  model,
  ModalComponent,
  isDraggable,
  onDelete,
  canEdit,
  editDashlet,
}) => {
  const fieldId = dashlet?.config?.field;
  const { t } = useTranslation();

  const columnAggregationFieldId = dashlet?.config?.aggregation?.columns?.id;
  const rowPrimaryAggregationFieldId =
    dashlet?.config?.aggregation?.rows?.[0]?.id;
  const rowSecondaryAggregationFieldId =
    dashlet?.config?.aggregation?.rows?.[1]?.id;
  const fieldMetricAggregationFieldId =
    dashlet?.config?.aggregation?.fieldToAggregate;
  const needsPermission = Boolean(fieldId) && Boolean(model);

  const isClient = model?.fetchUrl === 'client';
  const { data, isLoading: isLoadingSelectedField } = useQuery(
    FIELDS.GET_FIELD(fieldId),
    async () => {
      try {
        if (isClient) {
          const res = await FieldService.getClientField(fieldId, {
            skipErrorBoundary: true,
          });

          return res;
        }
        const res = await FieldService.getField(fieldId, model, {
          skipErrorBoundary: true,
        });

        return res;
      } catch (ex) {
        return { error: ex.response.status };
      }
    },
    {
      enabled: needsPermission && Boolean(model),
    }
  );

  const valueFieldId = model?.undeletableFields?.entityValue?.id;
  const estimatedCloseDateFieldId =
    model?.undeletableFields?.estimatedCloseDate?.id;

  const needsSalesPermission =
    dashlet?.config?.reportType === CHART_TYPES.SALES;

  const needPivotTablePermission =
    dashlet?.config?.reportType === AREA_RESPONSES.PIVOT_TABLE;

  const fields = useQueries([
    {
      queryKey: FIELDS.GET_FIELD(valueFieldId),
      queryFn: () =>
        isClient
          ? FieldService.getClientField(valueFieldId, {
              skipErrorBoundary: true,
            })
          : FieldService.getField(valueFieldId, model, {
              skipErrorBoundary: true,
            }),
      enabled: needsSalesPermission && Boolean(valueFieldId) && Boolean(model),
    },
    {
      queryKey: FIELDS.GET_FIELD(estimatedCloseDateFieldId),
      queryFn: () =>
        isClient
          ? FieldService.getClientField(estimatedCloseDateFieldId, {
              skipErrorBoundary: true,
            })
          : FieldService.getField(estimatedCloseDateFieldId, model, {
              skipErrorBoundary: true,
            }),
      enabled:
        needsSalesPermission &&
        Boolean(estimatedCloseDateFieldId) &&
        Boolean(model),
    },
    {
      queryKey: FIELDS.GET_FIELD(columnAggregationFieldId),
      queryFn: () =>
        isClient
          ? FieldService.getClientField(columnAggregationFieldId, {
              skipErrorBoundary: true,
            })
          : FieldService.getField(columnAggregationFieldId, model, {
              skipErrorBoundary: true,
            }),
      enabled: needPivotTablePermission && Boolean(columnAggregationFieldId),
    },
    {
      queryKey: FIELDS.GET_FIELD(rowPrimaryAggregationFieldId),
      queryFn: () =>
        isClient
          ? FieldService.getClientField(rowPrimaryAggregationFieldId, {
              skipErrorBoundary: true,
            })
          : FieldService.getField(rowPrimaryAggregationFieldId, model, {
              skipErrorBoundary: true,
            }),
      enabled:
        needPivotTablePermission && Boolean(rowPrimaryAggregationFieldId),
    },
    {
      queryKey: FIELDS.GET_FIELD(rowSecondaryAggregationFieldId),
      queryFn: () =>
        isClient
          ? FieldService.getClientField(rowSecondaryAggregationFieldId, {
              skipErrorBoundary: true,
            })
          : FieldService.getField(rowSecondaryAggregationFieldId, model, {
              skipErrorBoundary: true,
            }),
      enabled:
        needPivotTablePermission && Boolean(rowSecondaryAggregationFieldId),
    },
    {
      queryKey: FIELDS.GET_FIELD(fieldMetricAggregationFieldId),
      queryFn: () =>
        isClient
          ? FieldService.getClientField(fieldMetricAggregationFieldId, {
              skipErrorBoundary: true,
            })
          : FieldService.getField(fieldMetricAggregationFieldId, model, {
              skipErrorBoundary: true,
            }),
      enabled:
        needPivotTablePermission && Boolean(fieldMetricAggregationFieldId),
    },
  ]);

  const isDeletedField = fields.some(
    (field) => field?.error?.response?.status === 404
  );

  const missingValuePermission =
    (!fields?.[0]?.data && needsSalesPermission) ||
    fields?.[0]?.data?.access?.view === false;
  const missingCloseDatePermission =
    (!fields?.[1]?.data && needsSalesPermission) ||
    fields?.[1]?.data?.access?.view === false;
  const missingSelectedFieldPermission =
    (!data && needsPermission) || data?.access?.view === false;
  const missingColumnAggregationPermission =
    (!fields?.[2]?.data && needPivotTablePermission) ||
    fields?.[2]?.data?.access?.view === false;
  const missingRowPrimaryAggregationPermission =
    (!isDeletedField && !fields?.[3]?.data && needPivotTablePermission) ||
    fields?.[3]?.data?.access?.view === false;
  const missingRowSecondaryAggregationPermission =
    (rowSecondaryAggregationFieldId &&
      !isDeletedField &&
      !fields?.[4]?.data &&
      needPivotTablePermission) ||
    fields?.[4]?.data?.access?.view === false;
  const missingFieldMetricAggregationPermission =
    (fieldMetricAggregationFieldId &&
      !isDeletedField &&
      !fields?.[5]?.data &&
      needPivotTablePermission) ||
    fields?.[5]?.data?.access?.view === false;

  const missingPermission =
    missingCloseDatePermission ||
    missingValuePermission ||
    missingSelectedFieldPermission ||
    missingColumnAggregationPermission ||
    missingRowPrimaryAggregationPermission ||
    missingRowSecondaryAggregationPermission ||
    missingFieldMetricAggregationPermission;

  if (
    isLoadingSelectedField ||
    fields?.[0]?.isLoading ||
    fields?.[1]?.isLoading ||
    fields?.[2]?.isLoading ||
    fields?.[3]?.isLoading ||
    fields?.[4]?.isLoading ||
    fields?.[5]?.isLoading
  ) {
    return <Loader loading />;
  }

  if (isDeletedField) {
    return (
      <DashletError
        override={t(
          'This chart contains fields that have been deleted. Please edit the dashlet to remove these fields.'
        )}
        dashlet={dashlet}
        charts
        center
        model={model}
        ModalComponent={ModalComponent}
        isDraggable={isDraggable}
        // Will be passed to the ModalComponent as default props
        modalComponentProps={{ hasAccess: true }}
        editDashlet={editDashlet}
        onDelete={onDelete}
        canEdit={canEdit}
      />
    );
  }

  if (missingPermission) {
    return (
      <DashletError
        override={t(
          'You do not have permissions to view the field(s) in this chart. Please contact your administrator to increase your access level.'
        )}
        dashlet={dashlet}
        charts
        center
        model={model}
        ModalComponent={ModalComponent}
        isDraggable={isDraggable}
        // Will be passed to the ModalComponent as default props
        modalComponentProps={{ hasAccess: false }}
        // The dashlet can't be edited, but we need to pass an edit handler
        // to enable the "edit" option, in order to show the error message.
        // A no-op function is sufficient here.
        editDashlet={() => {}}
        onDelete={onDelete}
        canEdit={canEdit}
      />
    );
  }

  return children;
};

export default ChartPermission;
