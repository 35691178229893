/**
 * Formats a string containing placeholders with the provided arguments.
 *
 * @example ```js
 *  formatString("Hello, {0}. I'm {1}", 'Frank', 'Sally')
 *  // "Hello, Frank. I'm Sally"
 * ```
 *
 * @param {string} str - string with placeholders
 * @param  {...string} rest - replacement parameters
 * @returns {string}
 */
export const formatString = (str, ...rest) => {
  if (typeof str !== 'string' || str.length === 0) return '';
  return str.replace(/{([0-9]+)}/g, (match, index) => {
    return rest[index] === undefined ? match : rest[index];
  });
};
