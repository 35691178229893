import styled from '@emotion/styled';
import css from '@emotion/css';
import React, { useCallback, useRef, useState, forwardRef } from 'react';
import useSyncScroll from 'react-use-sync-scroll';
import { hideScrollbarCss } from '../../../app/spacing';
import ScrollBarDetached from '../../../components/Layout/ScrollBarDetached';
import { useSyncSizes } from '../../../hooks/useSyncSizes';
import { applyRef } from '../../Inputs/props';

const DEFAULT_OPTION_HEIGHT = 37;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
export const Content = styled.div`
  width: 100%;
  overflow: auto;
  ${hideScrollbarCss}
`;
const StyledScrollBarDetached = styled(ScrollBarDetached)`
  position: absolute;
  top: 0;
  right: 0;
  ${({ height }) => css`
    height: ${height}px;
  `}
  ${({ top }) => css`
    top: ${top}px;
  `}
`;
export default forwardRef(({ children, topButton, ...props }, ref) => {
  const [height, setHeight] = useState();
  const [top, setTop] = useState(topButton ? DEFAULT_OPTION_HEIGHT : 0);

  // scrollbar
  const scrollbarContentWrapperRef = useRef();
  const [contentWrapperRefFn, contentWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncHeight',
    'height',
    [props],
    true
  );

  const contentRefFn = useCallback(
    (node) => {
      if (node) {
        if (node.previousElementSibling) {
          setTop(node.previousElementSibling.getBoundingClientRect().height);
        }
        setHeight(node.getBoundingClientRect().height);
      }
      contentWrapperRefFn(node);
    },
    [contentWrapperRefFn]
  );

  const mergeRef = useCallback(
    (el) => {
      applyRef(ref, el);
      applyRef(contentRefFn, el);
    },
    [ref, contentRefFn]
  );

  useSyncScroll(useRef([contentWrapperRef, scrollbarContentWrapperRef]), {
    vertical: true,
  });

  return (
    <>
      <Content ref={mergeRef}>
        <div className="ContentForSyncHeight">
          <List {...props}>{children}</List>
        </div>
      </Content>
      <StyledScrollBarDetached
        height={height}
        top={top}
        ref={scrollbarContentWrapperRef}
        direction="vertical"
        scrollClassName="ContentForSyncHeight"
      />
    </>
  );
});
