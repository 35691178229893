import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import useEvent from 'react-use/lib/useEvent';
import useKeyPressEvent from 'react-use/lib/useKeyPressEvent';
import { useTranslation } from 'react-i18next';

import { gutters, layers } from 'app/spacing';
import ActionCell from 'components/Tables/ActionCell';
import { EmptyCell, SizedCell } from 'components/Kizen/Table';
import { getDataQAForInput } from 'components/Inputs/helpers';

const StyledActionCell = styled(ActionCell)`
  position: sticky;
  right: 0;
  z-index: ${({ open }) =>
    open ? layers.content(0, 3) : layers.content(0, 2)};
  ${({ hideMenu }) =>
    hideMenu &&
    css`
      display: none;
    `}

  > div {
    margin-right: ${gutters.spacing(2)}px;
  }
`;

const StyledEmptyCell = styled(EmptyCell)`
  // fix hover row jumping
  // StyledActionCell has 30px width and we should set the same width for empty cell
  // width property doesn't work, so we use padding for set 30px width
  padding: 0 ${gutters.standard};
  ${({ hideMenu }) =>
    !hideMenu &&
    css`
      display: none;
    `}
`;

const getOptions = (access, t) => {
  return Object.keys(access).reduce(
    (acc, el) => {
      if (el === 'remove' && access[el])
        return acc.concat({ value: 'archive', label: t('Archive') });

      return acc;
    },
    [{ value: 'view', label: t('View Record') }]
  );
};

const RowMenuCell = React.forwardRef(({ data, onSelect, ...others }, ref) => {
  const [rowIsHovered, setRowIsHovered] = useState(false);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelect = useCallback(
    (...data) => {
      handleClose();
      onSelect(...data);
    },
    [handleClose, onSelect]
  );

  useEffect(() => {
    // ref is null for inline record adding row e.g. "Quick Add"-ing new Contact
    if (ref) {
      ref.current = {
        enter: () => setRowIsHovered(true),
        leave: () => setRowIsHovered(false),
      };
    }
  }, [ref]);

  // Close the menu on any sort of scrolling. Not for UX purposes, but to prevent z-indexing
  // bugs: the open menu ends up on top of the table toolbar when the table's scrolled
  useEvent('scroll', handleClose, window);
  useEvent('wheel', handleClose, window);
  // horizontal arrows fire scroll events on the table wrapper, but since
  // we don't have access to that element here, we fallback to listening for
  // the window's keyboard events to handle that scrolling case
  useKeyPressEvent(
    ({ key }) => ['ArrowLeft', 'ArrowRight'].includes(key),
    handleClose // keydown handler
  );

  // Used to toggle display: none on our 2 cells, active (StyledCell) and inactive (StyledEmptyCell)
  // We saw that if you scrolled the table all the way to right, then moved your mouse very quickly over the
  // row menu column, some cells wouldn't mistakenly keep displaying, their onMouseLeave handler was, for
  // some reason, not being fired. This apparently has to do somehow with conditiionally removing
  // the cell from the DOM, so we handled hover state with styles instead
  const hideMenu = !rowIsHovered && !open;

  return (
    <>
      <StyledEmptyCell hideMenu={hideMenu} />
      <StyledActionCell
        menuType="absolute"
        hideMenu={hideMenu}
        data={data}
        onSelectAction={handleSelect}
        options={getOptions(data.access || {}, t)}
        open={open}
        onOpen={handleToggle}
        onClose={handleClose}
        {...others}
      />
    </>
  );
});

RowMenuCell.displayName = 'RowMenuCell';

export default RowMenuCell;

RowMenuCell.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    format: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      rowIsHovered: PropTypes.bool,
    }),
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

RowMenuCell.defaultProps = {
  column: null,
};

// Basically a duplicate of TRow (from components/Kizen/Table),
// but designed to handle row hovering statefully, internally to the RowMenuCell
// As in, this component should be interchangeable with TRow in cases where no column cell's use RowMenuCell,
// but required when any do
export const HoverableRow = React.memo(
  React.forwardRef(
    (
      { cell, head, columns, data, children, model, NewSizedCell, ...others },
      ref
    ) => {
      const rowMenu = useRef();

      return (
        <tr
          ref={ref}
          {...others}
          onMouseEnter={() => rowMenu.current && rowMenu.current.enter()}
          onMouseLeave={() => rowMenu.current && rowMenu.current.leave()}
          data-row-id={data.id}
        >
          {children}
          {columns &&
            columns.map((column) => {
              const columnCell = (head ? column.headCell : column.cell) || cell;
              // localize hover state changes, so they only rerender the row menu,
              // not all row children i.e. cells, which proved to be costly enough
              // to be visibly janky
              if (columnCell.type === RowMenuCell) {
                return React.cloneElement(columnCell, {
                  key: column.id,
                  head,
                  column,
                  data,
                  ref: rowMenu,
                });
              }
              if (!head && column.bodyCell) {
                const {
                  field,
                  onSubmit,
                  serviceToUse,
                  model,
                  handleUpdateFieldOption,
                  handleUpdateTableRecords,
                  minWidth,
                  Component,
                  meta,
                  isMenuCell,
                  title,
                  onSelect,
                  isCustomObjects,
                  dataQaPrefix = 'inline-record-field-',
                  ...rest
                } = column.bodyCell;
                if (isMenuCell) {
                  return (
                    <Component
                      ref={rowMenu}
                      key={column.id}
                      head={head}
                      column={column}
                      data={data}
                      onSelect={onSelect}
                      title={title}
                      isCustomObjects={isCustomObjects}
                    />
                  );
                }
                return (
                  <NewSizedCell
                    key={column.id}
                    column={column}
                    minWidth={minWidth}
                  >
                    <Component
                      {...rest}
                      key={column.id}
                      field={field}
                      object={data}
                      onSubmit={onSubmit}
                      isCustomObjects={isCustomObjects}
                      objectId={model?.id}
                      readOnly={false}
                      serviceToUse={serviceToUse}
                      column={column}
                      handleUpdateFieldOption={handleUpdateFieldOption}
                      handleUpdateTableRecords={handleUpdateTableRecords}
                      meta={meta}
                      {...getDataQAForInput(
                        `${dataQaPrefix}${field.name}`,
                        field.fieldType
                      )}
                    />
                  </NewSizedCell>
                );
              }
              return React.cloneElement(columnCell, {
                key: column.id,
                head,
                column,
                data,
              });
            })}
        </tr>
      );
    }
  )
);

HoverableRow.propTypes = {
  cell: PropTypes.element,
  head: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any.isRequired })),
  data: PropTypes.object,
};

HoverableRow.defaultProps = {
  cell: <SizedCell text />,
  head: false,
  columns: null,
  data: null,
};

HoverableRow.displayName = 'BetterHover';
