import { useTranslation } from 'react-i18next';
import { useEditor } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import {
  ToggleButton,
  ToolbarIcon,
  TextEditorToolbarSection,
} from '../../styles';
import React from 'react';
import { grayScale } from 'app/colors';

export const TextStyleToolbarSection = () => {
  const { t } = useTranslation();
  const editor = useEditor();
  const [ref, props] = useGridLikeFlexChildProps();
  if (!editor) return null;

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <ToggleButton
        active={editor.isActive('bold')}
        title={t('Bold')}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <ToolbarIcon icon="bold" color={grayScale.dark} />
      </ToggleButton>
      <ToggleButton
        active={editor.isActive('italic')}
        title={t('Italic')}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <ToolbarIcon icon="italic" color={grayScale.dark} />
      </ToggleButton>
      <ToggleButton
        active={editor.isActive('underline')}
        title={t('Underline')}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <ToolbarIcon icon="underline" color={grayScale.dark} />
      </ToggleButton>
      <ToggleButton
        active={editor.isActive('strike')}
        title={t('Strikethrough')}
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <ToolbarIcon icon="strikethrough" color={grayScale.dark} />
      </ToggleButton>
    </TextEditorToolbarSection>
  );
};
