import { forwardRef } from 'react';

import PhoneNumberInlineField from 'components/Fields/InlineTableFieldInput/PhoneNumber';
import { withCellWrapper } from './helpers';

const _PhoneNumberCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      orgValue,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    const {
      // instead fetch default country from redux - we have it in the field defaultRegion
      // chosenBusiness.phone_default_region === defaultRegion
      phonenumberOptions: { enableExtension, defaultRegion },
    } = field;
    return (
      <PhoneNumberInlineField
        ref={ref}
        {...others}
        value={
          value?.formatted ||
          value?.formattedNationalNumber ||
          value?.formatted_national_number ||
          value?.e164 ||
          value ||
          ''
        }
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        enableExtension={enableExtension}
        defaultCountry={defaultRegion}
        field={field}
        object={object}
      />
    );
  }
);

export const PhoneNumberCell = withCellWrapper(_PhoneNumberCell);
