import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';

export const BarWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;

  & p {
    color: ${grayScale.white};
    padding-left: ${gutters.spacing(2)}px;

    &.darken {
      color: ${grayScale.dark};
    }
  }
`;

export const BarInner = styled.div`
  width: ${({ widthPercent }) => widthPercent ?? 100}%;
  height: 100%;
  background: red;
  ${({ color }) => css`
    background-color: ${color.color};
    opacity: ${color.opacity};
  `}
  display: flex;
  align-items: center;
`;
