import styled from '@emotion/styled';
import { grayScale, colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import { KizenTypography, fontSizes, fontWeights } from 'app/typography';

export const StyledBusinessBox = styled.div(() => ({
  backgroundColor: grayScale.white,
}));

export const StyledSelectBox = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const StyledTextLabel = styled.div({
  color: grayScale.dark,
  fontStyle: 'italic',
  fontWeight: fontWeights.bold,
  fontSize: 14,
  marginBottom: 'calc(20px - 2px)',
  display: 'flex',
  alignSelf: 'flex-start',
});

export const StyledIcon = styled(Icon)(({ selected }) => ({
  marginBottom: 10,
  color: selected ? colorsButton.blue.default : grayScale.dark,
}));

export const StyledLabel = styled(KizenTypography)(() => ({
  marginBottom: 12,
  input: {
    display: 'none',
  },
}));

export const StyledActionButton = styled.div(() => ({
  width: 92,
  height: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid',
  borderColor: colorsButton.blue.default,
  borderRadius: '8px',
  letterSpacing: '0.03em',
  fontWeight: fontWeights.bold,
  textTransform: 'uppercase',
  fontSize: fontSizes.buttonLabel,
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: 20,
}));
