import React, { useContext, useState, useCallback } from 'react';
import useHeightMeasurement from 'hooks/useHeightMeasurement';

const DEFAULT_HEIGHT = 51;

const NavBarContext = React.createContext({
  height: 0,
  setHeight: () => null,
  navBarRef: null,
});

export const NavBarProvider = ({ children }) => {
  const [height, setHeight] = useState(0);

  const handleSetHeight = useCallback(
    (newHeight) => {
      // If the new height is NaN, set the height to the default height
      setHeight(isNaN(newHeight) ? DEFAULT_HEIGHT : newHeight);
    },
    [setHeight]
  );

  const { ref: navBarRef } = useHeightMeasurement(handleSetHeight);

  return (
    <NavBarContext.Provider value={{ height, setHeight, navBarRef }}>
      {children}
    </NavBarContext.Provider>
  );
};

export const useNavBarState = () => {
  const ctx = useContext(NavBarContext);

  return ctx;
};
