import styled from '@emotion/styled';

export const KDSLoadMoreWrapper = styled.div`
  text-align: center;
`;

export const ScrollStopMarker = styled.div`
  height: 1px;
  width: 1px;
  background: transparent;
  pointer-events: none;
  display: block;
  transform: translateY(-${({ threshold }) => threshold}px);
  &&& {
    padding: 0;
  }
`;
