import { FIELD_TYPES } from 'utility/constants';
import {
  getOptionValue,
  namedToOption,
  teammembersMapper,
  namedToOptionWithCode,
  relatedfieldIdToOption,
  getObjectToModifyDeleted,
  relatedfieldIdToOptionDeleted,
  displayNameToOption,
  displayNameToOptionError,
} from 'services/helpers';

import UUID from 'utility/UUID';

import { fieldValueMappingsErrorsForFlow } from '../CommonComponents/valueMappingsCommon';
import {
  mreChangeTypeOptionsConst,
  fieldToModifyfieldType,
  namedToLabel,
  fieldResolutionOptionsConst,
  newFieldValueMappings,
} from './common';

import {
  checkNestedDeleted,
  checkDeleted,
  displayNameDescriptor,
  descriptionDescriptor,
  _checkListDeleted,
} from 'pages/AutomationEngine/helpers';

export const initialState = (fieldToModify = null) => ({
  type: 'modify_related_entities',
  objectToModify: null,
  automationTargetRelationshipFields: [],
  fieldsToModify: [fieldToModify],
});

const isNaInt = (value) => isNaN(parseInt(value));
const parseNumberOrNull = (value) =>
  isNaInt(value) ? null : parseFloat(value);
const parseIntOrNull = (value) => (isNaInt(value) ? null : parseInt(value));

const setSpecificValueForFlow = (field, value, specificFieldValue) => {
  if (!specificFieldValue || specificFieldValue?.deleted) {
    return null;
  }

  switch (field?.fieldType) {
    case FIELD_TYPES.DateTime.type:
      return { value };
    case FIELD_TYPES.Dropdown.type:
    case FIELD_TYPES.Radio.type:
    case FIELD_TYPES.Status.type:
    case FIELD_TYPES.YesNoMaybe.type:
    case FIELD_TYPES.YesNo.type:
      return namedToOption(specificFieldValue);

    case FIELD_TYPES.Checkboxes.type:
      return {
        value: (specificFieldValue || []).map((opt) => {
          return namedToOptionWithCode(opt);
        }),
      };

    case FIELD_TYPES.TeamSelector.type:
      return teammembersMapper(specificFieldValue);

    case FIELD_TYPES.Rating.type:
      return { value: String(specificFieldValue.value) };

    case FIELD_TYPES.DynamicTags.type: {
      return {
        value: {
          tagsToAdd: specificFieldValue.tagsToAdd.map(namedToLabel),
          tagsToRemove: specificFieldValue.tagsToRemove.map(namedToLabel),
        },
      };
    }

    case FIELD_TYPES.Files.type:
      return { value: specificFieldValue };

    case FIELD_TYPES.Relationship.type: {
      return {
        value: Array.isArray(specificFieldValue)
          ? specificFieldValue.map(namedToLabel)
          : namedToLabel(specificFieldValue),
      };
    }

    default:
      return specificFieldValue;
  }
};

const fieldResolutionForFlow = (fieldResolution) => {
  // these will get translated if the user opens the edit wizard
  switch (fieldResolution) {
    case fieldResolutionOptionsConst.ONLY_ADD_OPTIONS:
      return {
        value: fieldResolution,
        label: 'Only Add Options',
      };

    case fieldResolutionOptionsConst.ONLY_UPDATE_BLANK:
      return {
        value: fieldResolution,
        label: 'Only Update Blank',
      };

    default:
      return {
        value: fieldResolutionOptionsConst.OVERWRITE,
        label: 'Overwrite Field',
      };
  }
};

const fieldValueMappingsForFlow = (fieldValueMappings) => {
  if (fieldValueMappings) {
    const mapped = fieldValueMappings.map(
      ({ sourceValues, targetValue, targetValues, ...rest }) => {
        return {
          key: UUID.generate(),
          sourceValues: sourceValues.map((sourceValue) =>
            sourceValue?.value
              ? { ...sourceValue, value: String(sourceValue?.value) }
              : sourceValue?.id
                ? { ...sourceValue, value: String(sourceValue?.id) }
                : sourceValue !== null
                  ? String(sourceValue)
                  : null
          ),
          targetValues: targetValues.map((targetValue) => {
            return targetValue?.value
              ? { ...targetValue, value: String(targetValue?.value) }
              : targetValue?.id
                ? { ...targetValue, value: String(targetValue?.id) }
                : targetValue !== null
                  ? String(targetValue)
                  : null;
          }),
          ...rest,
        };
      }
    );
    return mapped;
  }

  return [newFieldValueMappings(0)];
};

const forFlow = (apiObject, addErrorMessage, messageDictionary) => {
  try {
    const { id, objectToModify, fieldsToModify } = apiObject;

    // filter if an array of fields is passed in
    const automationTargetRelationshipFields = Array.isArray(
      apiObject.automationTargetRelationshipFields
    )
      ? apiObject.automationTargetRelationshipFields.filter((f) => !f.deleted)
      : apiObject.automationTargetRelationshipFields;

    checkDeleted(
      messageDictionary,
      addErrorMessage,
      objectToModify,
      descriptionDescriptor
    );

    checkNestedDeleted(
      messageDictionary,
      addErrorMessage,
      fieldsToModify,
      'contextEntityField',
      displayNameDescriptor
    );

    if (fieldsToModify?.length > 0) {
      checkNestedDeleted(
        messageDictionary,
        addErrorMessage,
        fieldsToModify,
        'fieldToModify',
        displayNameDescriptor
      );
    } else {
      addErrorMessage(messageDictionary.automationFailedToLoad);
    }

    _checkListDeleted({
      messageDictionary,
      addErrorMessage,
      parentObject: automationTargetRelationshipFields,
      descriptorCallback: displayNameDescriptor,
      itemName: messageDictionary.targetRelationshipFields,
      objectPath: 'automationTargetRelationshipFields',
      objectErrorTrans: displayNameToOptionError,
    });

    const flow = {
      id,
      objectToModify: getObjectToModifyDeleted(objectToModify),
      automationTargetRelationshipFields: (
        automationTargetRelationshipFields || []
      ).map((f) => relatedfieldIdToOption(f)),
      fieldsToModify: fieldsToModify.map(
        ({
          fieldToModify,
          specificFieldValue,
          contextEntityField,
          valueType,
          fieldResolution,
          fieldValueMappings: _fieldValueMappings,
        }) => {
          const fieldValueMappings =
            fieldValueMappingsForFlow(_fieldValueMappings);
          return {
            key: UUID.generate(),
            fieldToModify: relatedfieldIdToOptionDeleted(fieldToModify),
            specificFieldValue: setSpecificValueForFlow(
              fieldToModify,
              specificFieldValue?.value || specificFieldValue?.id,
              specificFieldValue
            ),
            valueType:
              valueType === mreChangeTypeOptionsConst.SPECIFIC_VALUE
                ? {
                    value: mreChangeTypeOptionsConst.SPECIFIC_VALUE,
                    label: 'Specific Value', // will get translated when needed
                  }
                : {
                    value: mreChangeTypeOptionsConst.RELATED_OBJECT_FIELD,
                    label: '{{entityName}} Field Value (Variable)', // will get translated when needed
                  },

            fieldResolution: fieldResolutionForFlow(fieldResolution),
            ...(contextEntityField && !contextEntityField?.deleted
              ? {
                  contextEntityField: displayNameToOption(contextEntityField),
                }
              : null),
            fieldValueMappings,
            fieldValueMappingsErrors:
              fieldValueMappingsErrorsForFlow(fieldValueMappings),
            fieldValueMappingsValidation:
              fieldValueMappingsErrorsForFlow(fieldValueMappings),
            duplicateError: false,
          };
        }
      ),
      fixMe: true,
    };
    return flow;
  } catch (error) {
    addErrorMessage(messageDictionary.automationFailedToLoad);
    return initialState;
  }
};

const getValueForApi = (field, specificFieldValue) => {
  if (!specificFieldValue) {
    return null;
  }
  const { value } = specificFieldValue;

  switch (field.fieldType) {
    case FIELD_TYPES.Text.type:
    case FIELD_TYPES.LongText.type:
      return value || '';

    case FIELD_TYPES.Decimal.type:
    case FIELD_TYPES.Money.type:
    case FIELD_TYPES.Integer.type:
      return parseNumberOrNull(value);

    case FIELD_TYPES.DateTime.type:
      return value || null;

    case FIELD_TYPES.Dropdown.type:
    case FIELD_TYPES.Radio.type:
    case FIELD_TYPES.Status.type:
    case FIELD_TYPES.TeamSelector.type:
      return value?.value ?? value;

    case FIELD_TYPES.Rating.type:
      return parseIntOrNull(value);

    case FIELD_TYPES.Checkboxes.type:
      return (value || []).map(({ value: id }) => id);

    case FIELD_TYPES.DynamicTags.type: {
      return {
        tagsToAdd: value?.tagsToAdd?.map(({ id }) => id) || [],
        tagsToRemove: value?.tagsToRemove?.map(({ id }) => id) || [],
      };
    }

    case FIELD_TYPES.Files.type:
      return (value || []).map(({ id }) => id);

    case FIELD_TYPES.Relationship.type:
      return Array.isArray(value) ? value.map(({ id }) => id) : value?.id;

    default:
      return value || null;
  }
};

const getfieldValueMapping = (value, fieldType) =>
  fieldType === FIELD_TYPES.Rating.type ? parseIntOrNull(value) : value;

const getfieldValueMappings = (fieldValueMappings, fieldType) => {
  const mapped = fieldValueMappings.map(
    ({ order, sourceValues, targetValues }) => ({
      order,
      sourceValues: (sourceValues || []).map((sourceValue) =>
        getfieldValueMapping(sourceValue?.value || sourceValue, fieldType)
      ),
      targetValues: (targetValues || [])
        .filter(Boolean)
        .map((targetValue) =>
          getfieldValueMapping(targetValue?.value || targetValue, fieldType)
        ),
    })
  );
  return mapped;
};

const forApi = (step) => {
  const details = { ...step.details };
  const { objectToModify, automationTargetRelationshipFields, fieldsToModify } =
    details;

  const api = {
    details: {
      objectToModify: getOptionValue(objectToModify),
      automationTargetRelationshipFields:
        automationTargetRelationshipFields.map((f) => getOptionValue(f)),
      //
      fieldsToModify: fieldsToModify.map(
        ({
          fieldToModify,
          specificFieldValue,
          contextEntityField,
          valueType,
          fieldResolution,
          fieldValueMappings,
        }) => ({
          fieldToModify: getOptionValue(fieldToModify),
          specificFieldValue: getValueForApi(
            fieldToModify.fieldData,
            specificFieldValue
          ),
          valueType: getOptionValue(valueType),
          ...(contextEntityField && {
            contextEntityField: getOptionValue(contextEntityField),
          }),
          fieldResolution: getOptionValue(fieldResolution),
          fieldValueMappings: getfieldValueMappings(
            fieldValueMappings,
            fieldToModifyfieldType(fieldToModify)
          ),
        })
      ),
    },
  };
  return api;
};

export const modifyRelatedEntitiesDTO = {
  forApi,
  forFlow,
};
