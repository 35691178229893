import { faFileLines } from '@fortawesome/pro-light-svg-icons/faFileLines';
import { faFiles } from '@fortawesome/pro-light-svg-icons/faFiles';
import {
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';

export const SEMANTIC_CATEGORY: SemanticCategory = 'file';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  files: faFiles,
  lines: faFileLines,
};

export const labels: IconLabel = {
  files: {
    title: (t) => '',
    description: (t) => t('Documents'),
  },
  lines: {
    title: (t) => '',
    description: (t) => t('Document'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};
