import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { StyledMultiSelect } from '../styles';
import Switch from 'components/Kizen/Switch';

const ManageTeam = ({
  teamMembers,
  onAddTeamMembers,
  onGrantMyRecordsAccess,
  grantMyRecordsAccess,
}) => {
  const { t } = useTranslation();
  const selectRef = useRef();
  const chosenValueIds = useMemo(
    () => teamMembers.map(({ value }) => value),
    [teamMembers]
  );

  const [selectProps] = useSelectTypeaheadWithScroll({
    selectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds,
  });

  const onToggle = (e) => {
    onGrantMyRecordsAccess(e.target.checked);
  };

  return (
    <>
      <StyledMultiSelect
        ref={selectRef}
        className="select-wrapper"
        label={t('Choose Team Member(s) to Associate')}
        placeholder={t('Find Team Members')}
        menuInline
        fullWidth
        value={teamMembers}
        onChange={onAddTeamMembers}
        menuLeftButton={<ClearSelectButton />}
        menuRightButton={<ApplySelectButton />}
        {...selectProps}
        margin
      />
      <Switch
        removeMargin
        label={t('Grant "My Records" Access')}
        checked={grantMyRecordsAccess}
        onChange={onToggle}
        textPlacement="left"
      />
    </>
  );
};

export default ManageTeam;
