import { useTranslation } from 'react-i18next';
import {
  StyledExecutionLoader,
  StyledHeaderButtons,
  StyledPackageButton,
  StyledSQLBoardHeader,
  StyledSQLBoardHeaderControls,
  StyledSQLBoardHeaderResults,
  StyledSQLResultsButtons,
  StyledSQLScriptMessage,
  StyledSQLScriptResults,
} from './styles';
import { KizenTypography } from '__app/typography';
import { SQL_SCRIPT_STATUSES } from '__pages/SmartConnectors/constants';
import {
  SQLScript,
  SQLScriptStatus,
  ScriptExecutionResult,
} from '__pages/SmartConnectors/types';
import ToggleButton from '__components/Kizen/ToggleButton';
import Button from '__components/Button';
import ConfirmationModal from '__components/Modals/ConfirmationModal';
import useModal from '__components/Modals/useModal';
import { getScriptResultMessage, toHtml } from '../../helpers';
import { useContext } from 'react';
import { SmartConnectorContext } from '../../context';

type SQLBoardHeaderProps = {
  view: SQLScriptStatus;
  onChangeView: (view: SQLScriptStatus) => void;
  handleTestExecution: () => Promise<void>;
  handlePublishLiveScript: () => Promise<boolean>;
  handleDownloadLocalDevPackage: () => Promise<void>;
  executionResult: ScriptExecutionResult | null;
  pollingScriptExecution: boolean;
  publishingLiveScript: boolean;
  liveScript: SQLScript | null;
  isDraftScriptDirty: boolean;
  handleResetToLive: () => void;
};

export const SQLScriptsHeader = ({
  view,
  onChangeView,
  handleTestExecution,
  handlePublishLiveScript,
  handleDownloadLocalDevPackage,
  executionResult,
  pollingScriptExecution,
  publishingLiveScript,
  liveScript,
  isDraftScriptDirty,
  handleResetToLive,
}: SQLBoardHeaderProps) => {
  const { t } = useTranslation();

  const { stepData } = useContext(SmartConnectorContext);

  const options = [
    { value: SQL_SCRIPT_STATUSES.draft, label: t('Draft') },
    { value: SQL_SCRIPT_STATUSES.live, label: t('Live') },
  ];

  const handleChangeView = ({ value }: { value: SQLScriptStatus }) =>
    onChangeView(value);

  const handleDownloadLiveOutput = () => {
    if (liveScript?.output_csv_file?.url) {
      window.open(liveScript?.output_csv_file?.url, '_blank');
    }
  };

  const handleDownloadDraftOutput = () => {
    if (executionResult?.export_file?.url) {
      window.open(executionResult?.export_file?.url, '_blank');
    }
  };

  const resultMessage = getScriptResultMessage(executionResult, t);

  const [
    publishConfirmationModalProps,
    ,
    { show: handleShowPublishConfirmationModal },
  ] = useModal({
    handleSubmit: async () => {
      if (!isDraftScriptDirty) {
        const published = await handlePublishLiveScript();
        if (published) {
          onChangeView(SQL_SCRIPT_STATUSES.live);
        }
      }
    },
  });

  const [
    resetConfirmationModalProps,
    ,
    { show: handleShowResetConfirmationModal },
  ] = useModal({
    handleSubmit: handleResetToLive,
  });

  const executionError =
    !!executionResult?.meta && 'error' in executionResult.meta;

  return (
    <>
      <StyledSQLBoardHeader>
        <StyledSQLBoardHeaderControls>
          <KizenTypography>{t('View')}</KizenTypography>
          <ToggleButton
            options={options}
            value={view}
            onChange={handleChangeView}
          />
          {stepData.sql_enabled && stepData.source_file ? (
            <StyledPackageButton
              variant="text"
              color="blue"
              onClick={handleDownloadLocalDevPackage}
            >
              {t('Download Dev Package')}
            </StyledPackageButton>
          ) : null}
          <StyledHeaderButtons>
            {view === SQL_SCRIPT_STATUSES.draft ? (
              <>
                {liveScript ? (
                  <Button
                    onClick={handleShowResetConfirmationModal}
                    color="red"
                    variant="text"
                  >
                    {t('Reset to Live')}
                  </Button>
                ) : null}
                <Button
                  onClick={handleTestExecution}
                  color="blue"
                  disabled={pollingScriptExecution || publishingLiveScript}
                >
                  {t('Test Execution')}
                </Button>
              </>
            ) : null}
            {view === SQL_SCRIPT_STATUSES.live && liveScript ? (
              <Button
                onClick={handleDownloadLiveOutput}
                color="blue"
                variant="text"
              >
                {t('Download Sample Output')}
              </Button>
            ) : null}
          </StyledHeaderButtons>
        </StyledSQLBoardHeaderControls>
        {(executionResult || pollingScriptExecution) &&
        view === SQL_SCRIPT_STATUSES.draft ? (
          <StyledSQLBoardHeaderResults>
            <StyledExecutionLoader loading={pollingScriptExecution} size={32} />
            {pollingScriptExecution ? (
              <StyledSQLScriptMessage>
                {t(
                  'Your SQL is being executed and output table generated. A status will appear here when execution has completed.'
                )}
              </StyledSQLScriptMessage>
            ) : (
              <StyledSQLScriptResults
                key={resultMessage}
                enableToolbar={false}
                enableResize
                initialValue={toHtml(resultMessage)}
                initialHeight={55}
                disabled
              />
            )}
            <StyledSQLResultsButtons>
              <Button
                variant="text"
                color="blue"
                onClick={handleDownloadDraftOutput}
                disabled={pollingScriptExecution || executionError}
              >
                {t('Download Sample Output')}
              </Button>
              <Button
                onClick={handleShowPublishConfirmationModal}
                disabled={
                  pollingScriptExecution ||
                  executionError ||
                  publishingLiveScript
                }
              >
                {t('Publish Live')}
              </Button>
            </StyledSQLResultsButtons>
          </StyledSQLBoardHeaderResults>
        ) : null}
      </StyledSQLBoardHeader>
      <ConfirmationModal
        heading={
          isDraftScriptDirty ? t('Cannot Publish') : t('Confirm Publish')
        }
        {...publishConfirmationModalProps}
        leftBtn={isDraftScriptDirty ? null : undefined}
        actionBtnColor={isDraftScriptDirty ? 'blue' : 'green'}
        buttonText={isDraftScriptDirty ? t('Close') : t('Confirm')}
      >
        {isDraftScriptDirty
          ? t(
              'The current draft state has not been successfully tested. You must test the execution before publishing.'
            )
          : t(
              'Publishing your changes will overwright your existing SmartConnector processing. Do you wish to continue?'
            )}
      </ConfirmationModal>
      <ConfirmationModal
        heading={t('You Have Unpublished Changes')}
        {...resetConfirmationModalProps}
        actionBtnColor="red"
        buttonText={t('Discard Changes')}
      >
        {t('Unpublished changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
};
