import { useTranslation } from 'react-i18next';
import StepCard from '__pages/AutomationEngine/Engine/Steps/StepCard';
import { useContext } from 'react';
import { FlowBuilderContext } from '../../context';
import { FlowStep } from '../../types';
import { AdditionalVariable } from '__pages/SmartConnectors/types';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';

type StepProps = {
  step: FlowStep & { details: { additional_variables: AdditionalVariable[] } };
  color: string;
  title: string;
};

export default function InitializeAdditionalVariablesCard({
  step,
  color,
  title,
  ...others
}: StepProps) {
  const { t } = useTranslation();
  const { dropStep } = useContext(FlowBuilderContext);
  const { additional_variables } = step.details;
  return (
    <StepCard
      {...others}
      panel={null}
      color={color}
      showMovingPlaceholder={false}
      title={title}
      onSelectMenu={() => {
        dropStep({ step, ...(others as any) });
      }}
      editOnly
    >
      <TextEllipsisWithTooltip>
        {t('Set Values for {{amount}} Variable(s)', {
          amount: additional_variables?.length || 0,
        })}
      </TextEllipsisWithTooltip>
    </StepCard>
  );
}
