import { getTimeIntervalForDate, isCellFuture } from 'components/Calendar';
import { endOfDay } from 'date-fns';
import { useToggle } from 'react-use';
import { isPast } from 'utility/datetime';
import { BROADCAST_FORMDATA } from '../ScheduleBroadcastModal/constants';

export const shouldOpenScheduleBroadcastModal = (d) => {
  return !isPast(endOfDay(d));
};

export const getTimeCellLookupKey = (...args) => {
  const [date, timeString] = args;

  if (timeString !== undefined) {
    return `${date.toDateString()} ${timeString}`;
  }

  return `${date.toDateString()} ${getTimeIntervalForDate(date)}`;
};

export const buildTimeCellBroadcastLookup = (broadcasts) =>
  broadcasts.reduce((acc, b) => {
    const d = new Date(b[BROADCAST_FORMDATA.date]);
    const rescheduleAt = b[BROADCAST_FORMDATA.rescheduleAt]
      ? new Date(b[BROADCAST_FORMDATA.rescheduleAt])
      : null;
    const key = getTimeCellLookupKey(rescheduleAt || d);
    acc[key] = acc[key] ?? [];
    acc[key].push({
      ...b,
      [BROADCAST_FORMDATA.date]: d,
      [BROADCAST_FORMDATA.rescheduleAt]: rescheduleAt,
    });
    return acc;
  }, {});

export const useScheduleBroadcast = (handleClick, date, time = 0) => {
  const isFuture = isCellFuture(date, time);
  const [showAddButton, setShowAddButton] = useToggle(false);
  const onClick = (ev) => {
    if (isFuture) {
      // Only stop propagation when the add broadcast modal will open. This allows for
      // closing of expanded cells when clicking on past dates within the calendar
      ev.stopPropagation();
      handleClick(time ? new Date(time) : date);
    }
  };

  const onMouseMove = () => setShowAddButton(true);
  const onMouseLeave = () => setShowAddButton(false);

  return {
    onMouseMove,
    onMouseLeave,
    onClick,
    show: isFuture && showAddButton,
  };
};
