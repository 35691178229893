import { gutters, layers } from 'app/spacing';
import styled from '@emotion/styled';
import Loader from 'components/Kizen/Loader';
import { grayScale } from 'app/colors';

export const ChartLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Chart = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  margin-top: ${gutters.spacing(1)}px;
  margin-left: -${gutters.spacing(3)}px;
  margin-right: -${gutters.spacing(6, 4)}px;
  margin-bottom: -${gutters.spacing(2)}px;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 0;
  left: -${gutters.spacing(3)}px;
  right: -${gutters.spacing(3)}px;
  bottom: -${gutters.spacing(1)}px;
  padding-top: 0 !important;
  background-color: ${grayScale.white};
  z-index: ${layers.content(10)};

  & > div {
    margin-top: 10%;
    margin-right: ${gutters.spacing(2)}px;
  }
`;
