import { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import BigTableLayout, {
  useBigTableLayoutNew,
} from 'components/Layout/BigTableLayout';
import BigTable from 'components/Tables/Big';
import { TRow } from 'components/Kizen/Table';
import TablePagination from 'components/Tables/Big/TablePagination';
import PageToolbar, {
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import Button from 'components/Button';
import { getTemplatesColumns } from './columns';
import { useTranslation } from 'react-i18next';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import { useNavBarState } from 'app/navBarController';
import { useSelector } from 'react-redux';
import { selectLibraryTemplatesFiltering } from 'store/libraryPage/library.redux';
import { SUB_NAVIGATION_HEIGHT } from 'pages/Library/helpers';

const DesktopLayout = ({
  search,
  onChangeSearch,
  templates,
  handleChangeSearch,
  templatesWithoutMetaCount,
  pageNumber,
  pageSize,
  handleChangePageNumber,
  handleChangePageSize,
  sort,
  handleChangeSort,
  handleSelectAction,
  handleClickNewTemplate,
  handleUpdateTemplate,
  timezone,
  ...others
}) => {
  const { height } = useNavBarState();
  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayoutNew(height, height + SUB_NAVIGATION_HEIGHT);
  const { t } = useTranslation();

  const { scrolledToTable } = bigTablePageProps;
  const templatesHeadData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => handleChangeSort({ column, direction }),
      },
    }),
    [sort, handleChangeSort]
  );
  const templatesColumns = useMemo(
    () =>
      getTemplatesColumns({
        onUpdateTemplate: handleUpdateTemplate,
        onSelectAction: handleSelectAction,
        timezone,
        t,
      }),
    [handleSelectAction, handleUpdateTemplate, timezone, t]
  );
  const isFiltering = useSelector(selectLibraryTemplatesFiltering);

  return (
    <BigTableLayout
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={t('Find Templates')}
              value={search}
              onChange={onChangeSearch}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={templatesWithoutMetaCount}
            single={t('Template')}
            plural={t('Templates')}
          />
          <PageToolbarSection>
            <Button onClick={handleClickNewTemplate}>
              {t('New Template')}
            </Button>
          </PageToolbarSection>
        </PageToolbar>
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={templatesWithoutMetaCount}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      }
      {...bigTablePageProps}
      {...others}
    >
      <BigTable
        scrolledToTable={scrolledToTable}
        columns={templatesColumns}
        head={<TRow head columns={templatesColumns} data={templatesHeadData} />}
        staleData={isFiltering}
      >
        {templates.map((item) => (
          <TRow key={item.id} columns={templatesColumns} data={item} />
        ))}
      </BigTable>
    </BigTableLayout>
  );
};

DesktopLayout.propTypes = {
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChangeSearch: PropTypes.func.isRequired,
  templatesWithoutMetaCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleChangePageNumber: PropTypes.func.isRequired,
  handleChangePageSize: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleSelectAction: PropTypes.func.isRequired,
  handleClickNewTemplate: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default DesktopLayout;
