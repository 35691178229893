import { Transducer } from '@thi.ng/transducers';

/**
 * Used as the stream value when needing to "run" or "wire up" a rule when the
 * source stream does not exist (rules can be defined to check for the existence
 * of a permission value).
 */
export const MISSING_SOURCE = Symbol('kizen_permissions::missing_source');

export type PickPartial<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export type PickRequired<T, K extends keyof T> = Partial<Omit<T, K>> &
  Required<Pick<T, K>>;

export type RuleResult<T> = {
  value: T;
  result: boolean;
  text?: string;
};

export type TakeXformDef = { take: number; text?: string };

export type ReturnXformDef<T> = {
  return: Partial<T>;
  text?: string;
};
export type MapKeysXformDef = {
  map_keys: Record<string, number>;
  text?: string;
};
export type TakeFirstXformDef = 'take-first';
export type RuleResultXform<T> =
  | TakeXformDef
  | ReturnXformDef<T>
  | MapKeysXformDef
  | TakeFirstXformDef;

type Ops = '>=' | '<' | '=' | 'min-value' | 'exists' | '!exists';
type CombinatorOp = 'and' | 'or';

export type GreaterThanOrEqualRule<A, B extends string, C = undefined> = [
  A,
  '>=',
  B,
  RuleResultXform<C>?,
];

export type LessThanRule<A, B extends string, C = undefined> = [
  A,
  '<',
  B,
  RuleResultXform<C>?,
];

export type SingleRuleDef<A, B extends string, C> =
  | GreaterThanOrEqualRule<A, B, C>
  | LessThanRule<A, B, C>
  | [A, 'min-value']
  | [A, 'exists']
  | [A, '!exists']
  | [A, '=', B, RuleResultXform<C>?];

export type CombinatorRuleDef<A, B extends string, C> = [
  CombinatorOp,
  (SingleRuleDef<A, B, C> | CombinatorRuleDef<A, B, C>)[],
  RuleResultXform<C>?,
];

export type RuleDef<A, B extends string, C> =
  | SingleRuleDef<A, B, C>
  | CombinatorRuleDef<A, B, C>;

export type OrRuleDef<A, B extends string, C> = [
  'or',
  CombinatorRuleDef<A, B, C>['1'],
  CombinatorRuleDef<A, B, C>['2']?,
];

export type AndRuleDef<A, B extends string, C> = [
  'and',
  CombinatorRuleDef<A, B, C>['1'],
  CombinatorRuleDef<A, B, C>['2']?,
];

export type TransducerConfig<T> = Record<
  Ops,
  (
    rule: SingleRuleDef<any, any, any>
  ) => Transducer<T | typeof MISSING_SOURCE, RuleResult<Partial<T>>>
>;
