import styled from '@emotion/styled';
import { Row, Col } from 'react-bootstrap';

import { grayScale, colorsButton, tableHover } from 'app/colors';
import { gutters, breakpoints } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import { fontWeights, fontSizes } from 'app/typography';
import RadioGroup from 'components/Kizen/RadioGroup';
import DateInput from 'components/Kizen/DateInput';
import Input from 'components/Kizen/Input';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import Divider from 'components/Layout/Divider';
import Cols from 'pages/Styleguide/components/helpers/Cols';

export const Container = styled.div`
  max-width: 820px;
  width: auto;
  margin: 10px auto;
  background-color: unset;
  & > div {
    margin: 20px auto;
    max-width: 100%;
  }
`;

export const StyledHeader = styled(KizenTypography)`
  text-align: center;
  width: 100%;
  margin: 0;
  margin-top: calc(5px - 2px);
  margin-bottom: calc(2 * ${gutters.standard} - 7px);
`;
export const StyledSubHeader = styled(KizenTypography)`
  text-align: left;
  width: 100%;
  padding: 0 15px;
  margin-bottom: calc(15px - 2px);
`;
export const StyledBreakdownSubheader = styled(KizenTypography)`
  text-align: left;
  width: 100%;
  padding: 0 15px;
  margin-bottom: calc(20px - 2px);
  margin-top: ${gutters.large};
`;

export const StyledMicroText = styled(KizenTypography)`
  color: ${colorsButton.green.hover};
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-style: normal;
  font-weight: ${fontWeights.bold};
  margin-bottom: -8px;
`;

export const StyledCols = styled(Cols)`
  flex-wrap: nowrap;
  > div {
    width: 100%;
    margin-bottom: 20px;
  }
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    > div {
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
    }
    > div:last-of-type {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;

export const StyledRow = styled(Row)`
  margin-bottom: 20px;
  &.no-margin {
    margin-bottom: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledCol = styled(Col)`
  padding: 0 10px;
  flex-basis: 50%;
  @media (min-width: ${breakpoints.md}px) {
    flex-basis: 0;
    &:first-of-type {
      padding-left: ${gutters.standard};
    }
    &:last-of-type {
      padding-right: ${gutters.standard};
    }
  }
`;

export const StyledSubheader = styled(KizenTypography);

export const Table = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  &.investment-table .table-cell {
    justify-content: center;
    padding: 0 0 15px 0;
  }
  &.investment-table .estimation-cell {
    padding: 10px 0;
  }
  &.investment-table .label-cell {
    padding: 5px 0 0 0;
  }
`;

export const TableRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 ${gutters.standard};
  &:first-of-type .table-border {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  &:last-of-type .table-border {
    border-bottom: 1px solid ${grayScale.mediumLight};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  flex-direction: column;
  .empty-cell {
    display: none;
  }
  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    .empty-cell {
      display: flex;
    }
  }
`;

export const TableTitle = styled(KizenTypography)``;

export const TableData = styled.div`
  width: auto;
  min-width: 50%;
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  overflow: hidden;
  &.table-border {
    border-color: ${grayScale.mediumLight};
    border-bottom: none;
  }
  > .box-cell {
    width: 100%;
    border-bottom: 1px solid ${grayScale.mediumLight};
  }
  flex-direction: column;
  @media (min-width: ${breakpoints.md}px) {
    width: 638px;
    min-width: 638px;
    flex-direction: row;
    > .box-cell {
      width: 33.3333%;
      border-bottom: none;
    }
  }
`;

export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  &.table-cell.cell-border {
    border-right: 1px solid ${grayScale.mediumLight};
  }
  &.table-cell.cell-border:last-of-type {
    border-right: none;
  }
  &.table-cell > p {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 14px;
    font-weight: normal;
    line-height: 100%;
    margin-top: 0;
    margin-bottom: 10px;
  }
  &.table-cell > p:last-of-type {
    margin-bottom: 0;
  }
  & .checkmark {
    margin-right: 10px;
    font-size: 11px;
  }
  &.label-cell {
    padding-left: 0;
    display: flex;
    align-self: flex-start;
  }
`;

export const StyledFormInput = styled(Input)`
  &.first-row {
    margin-top: 0;
  }
  & label {
    margin-bottom: 0;
  }
  margin-top: calc(20px - 6px);
  &.credit-card div span {
    display: flex;
    align-self: flex-end;
    margin-bottom: 0;
  }
`;

export const StyledFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  > div {
    flex-basis: 50%;
  }
`;

export const StyledDateInput = styled(DateInput)`
  margin-top: calc(20px - 6px);
  margin-right: ${gutters.standard};
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledFormButton = styled(Button)`
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100px;
  margin-top: 20px;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  justify-content: space-between;
  margin: 0;
  flex: 1;
  & label {
    margin: 0;
    display: flex;
    flex-basis: 0;
    justify-content: center;
    > input {
      display: none;
    }
    & div.child {
      border: none;
      display: flex;
      flex: 1;
      justify-content: center;
      & .box-cell {
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
      }
    }
  }
  flex-direction: column;
  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    & label {
      flex-basis: 33.3333%;
    }
  }
`;

export const StyledInfoMessage = styled.span`
  font-weight: ${fontWeights.bold};
  font-size: 11px;
  color: ${grayScale.mediumDark};
`;

export const StyledBreakdownSum = styled.span`
  & > span {
    font-weight: ${fontWeights.bold};
    font-size: 18px;
    color: ${grayScale.dark};
  }
`;

export const StyledDivider = styled(Divider)`
  margin-top: 3px;
`;

export const StyledCard = styled(Card)`
  padding: ${gutters.spacing(4)}px;
`;

export const StyledInfoBox = styled.div`
  padding: ${gutters.spacing(2, -2)}px ${gutters.spacing(3, -3)}px;
  border-radius: 4px;
  background-color: ${tableHover};
`;

export const StyledParagraph = styled.p`
  margin: 0;
  font-size: ${fontSizes.text};
  line-height: 110%;
  color: ${grayScale.dark};
`;

export const StyledIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: ${gutters.spacing(4)}px;
`;

export const StyledContactIcon = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :hover {
    text-decoration: none;
  }
  > span {
    margin-top: ${gutters.spacing(2)}px;
    font-style: normal;
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.subheader};
    line-height: 100%;
    color: ${grayScale.dark};
  }
`;
