import React, { useEffect, useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { shadowLight } from '../../app/colors';
import { enrichEl } from '../helpers';
import { ApplyMenuButton } from '../Kizen/Menu';
import StylePassthrough from '../Kizen/StylePassthrough';
import { Menu as BaseMenu } from './Select/customize';

const WithShadow = styled(StylePassthrough)`
  ${shadowLight}
`;

const Menu = styled(BaseMenu)`
  max-height: 200px;
`;

const popperCenterConfig = {
  modifiers: {
    computeStyle: {
      fn(data) {
        /* eslint-disable-next-line no-param-reassign */
        data.styles = {
          ...data.styles,
          position: 'absolute',
          top: '0px',
          left: '50%',
          transform: `translate3d(-50%, ${data.popper.top}px, 0px)`,
          willChange: 'transform',
        };
        return data;
      },
    },
  },
};

export default function SelectOverlay(props) {
  const { show, horizontalCentered, children, ...others } = props;

  let { placement } = props;
  const { popperConfig } = props;
  // Had to introduce some state here because if autoFocus
  // is true when the overlay opens, the browser will auto-scroll
  // to the top of the page. I believe this is because the overlay isn't
  // positioned anywhere yet.
  const [autoFocus, setAutoFocus] = useState(false);
  const desiredAutoFocus =
    typeof children.props.autoFocus === 'boolean'
      ? children.props.autoFocus
      : true;
  useEffect(() => {
    requestAnimationFrame(() => setAutoFocus(show && desiredAutoFocus));
  }, [show, desiredAutoFocus]);

  // override placement and merge popperConfig if horizontal centered is set
  if (horizontalCentered) {
    popperConfig.modifiers = {
      ...popperCenterConfig.modifiers,
      ...popperConfig.modifiers,
    };
    placement = 'bottom';
  }

  return (
    <Overlay
      show={show}
      transition={false}
      rootClose
      placement={placement}
      popperConfig={popperConfig}
      {...others}
    >
      <WithShadow passthrough={['scheduleUpdate']}>
        {React.cloneElement(children, {
          // react-select only autofocuses on mount, so we use a key to force a remount when this changes
          key: autoFocus,
          sizing:
            children.props.sizing === false
              ? false
              : children.props.sizing || 'auto',
          autoFocus,
          menuInline: true,
          menuIsOpen: true,
          menuRightButton: enrichEl(children.props.menuRightButton, {
            selectOverlay: props,
          }),
          components: {
            Menu,
            ...children.props.components,
          },
        })}
      </WithShadow>
    </Overlay>
  );
}

SelectOverlay.propTypes = {
  show: PropTypes.bool,
  placement: PropTypes.string,
  popperConfig: PropTypes.object,
  horizontalCentered: PropTypes.bool,
};

SelectOverlay.defaultProps = {
  show: null,
  placement: 'bottom-start',
  popperConfig: {},
  horizontalCentered: null,
};

export function ApplySelectOverlayButton({ onClick, selectOverlay, ...props }) {
  return (
    <ApplyMenuButton
      onClick={(ev) => {
        selectOverlay.onHide();
        if (onClick) onClick(ev);
      }}
      {...props}
    />
  );
}

ApplySelectOverlayButton.propTypes = {
  onClick: PropTypes.func,
  selectOverlay: PropTypes.object.isRequired,
};

ApplySelectOverlayButton.defaultProps = {
  onClick: null,
};

ApplySelectOverlayButton.enrich = {
  selectOverlay: true,
};
