import { forwardRef } from 'react';

import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import RelationshipOneInline, {
  RelationshipOneSelect,
} from 'components/Fields/InlineTableFieldInput/RelationshipOne';

import { addRelationToTeamSelector } from 'components/Fields/helpers';
import { withCellWrapper } from './helpers';

const _TeamSelectorCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      shouldFocusNext,
      ...others
    },
    ref
  ) => {
    const f = addRelationToTeamSelector(field);

    return (
      <RelationshipOneInline
        ref={ref}
        {...others}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
        field={f}
        object={object}
        shouldFocusNext={shouldFocusNext}
      >
        <RelationshipOneSelect
          shouldFocusNext={shouldFocusNext}
          menuLeftButton={f.isRequired ? null : menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </RelationshipOneInline>
    );
  }
);

export const TeamSelectorCell = withCellWrapper(_TeamSelectorCell);
