import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import { SizedCell, SortableHeadCell } from 'components/Kizen/Table';
import TemplateNameCell from 'pages/Account/pages/Toolbar/pages/ToolbarTemplates/components/NameCell';
import ActionCell from 'pages/Account/pages/Toolbar/pages/ToolbarTemplates/components/ActionCell';
import SharingStatsCell from 'pages/Account/pages/Toolbar/pages/ToolbarTemplates/components/SharingStatsCell';
import { StyledCell } from 'pages/Account/pages/Toolbar/pages/ToolbarTemplates/styles';
import OwnerCell from 'pages/Account/pages/Toolbar/pages/ToolbarTemplates/components/OwnerCell';
import {
  EMPLOYEE_ACCESS,
  EMPLOYEE_ACCESS_DICTIONARY,
  getCanView,
} from 'components/AccessRequests/utils';

const paddingSize = {
  basic: '50px',
  small: '20px',
};
const columnSize = {
  spacer: '20px',
  name: '220px',
  owner: '220px',
  sharingSettings: '266px',
  yourAccess: '120px',
  count: '110px',
  actions: '47px',
};

export const useColumns = (
  handleActionMenu,
  handleChangeName,
  handleClickEditSharingSettings,
  handleChangeOwner,
  errors,
  data
) => {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        id: 'spacer-1',
        headCell: <SizedCell width={columnSize.spacer} />,
        cell: <SizedCell width={columnSize.spacer} />,
      },
      {
        id: 'name',
        headCell: (
          <SortableHeadCell
            label={t('Template Name')}
            width={columnSize.name}
            padding={paddingSize.basic}
          />
        ),
        cell: <SizedCell width={columnSize.name} padding={paddingSize.basic} />,
        format: (value, data) => (
          <TemplateNameCell
            value={value}
            data={data}
            onChange={handleChangeName}
            error={errors[data.id]?.name}
          />
        ),
      },
      {
        id: 'owner',
        headCell: (
          <SortableHeadCell
            label={t('Creator/Owner')}
            width={columnSize.owner}
            padding={paddingSize.basic}
          />
        ),
        cell: (
          <SizedCell width={columnSize.owner} padding={paddingSize.basic} />
        ),
        format: (value, innerData) => {
          return (
            <OwnerCell
              value={value}
              data={innerData}
              items={data}
              onChange={(owner) => handleChangeOwner(owner, innerData.id)}
            />
          );
        },
      },
      {
        id: 'sharing_stats',
        headCell: (
          <SortableHeadCell
            label={t('Sharing Settings')}
            width={columnSize.sharingSettings}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell
            width={columnSize.sharingSettings}
            padding={paddingSize.basic}
          />
        ),
        format: (value, data) => (
          <SharingStatsCell
            value={value}
            data={data}
            onClickEdit={() => handleClickEditSharingSettings(data)}
          />
        ),
      },
      {
        id: 'employee_access',
        headCell: (
          <SortableHeadCell
            label={t('Your Access')}
            width={columnSize.yourAccess}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell
            width={columnSize.yourAccess}
            padding={paddingSize.basic}
          />
        ),
        format: (value) => (
          <KizenTypography>
            {EMPLOYEE_ACCESS_DICTIONARY[value]?.(t) ||
              EMPLOYEE_ACCESS_DICTIONARY[EMPLOYEE_ACCESS.NONE](t)}
          </KizenTypography>
        ),
      },
      {
        id: 'filter_count',
        headCell: (
          <SortableHeadCell
            label={t('# Active Filters')}
            width={columnSize.count}
            padding={paddingSize.basic}
            hasSorting={false}
          />
        ),
        cell: (
          <SizedCell width={columnSize.count} padding={paddingSize.basic} />
        ),
        format: (value, { filters }) => {
          return (
            <KizenTypography>
              {value || filters?.quick_filter_settings?.length || 0}
            </KizenTypography>
          );
        },
      },
      {
        id: 'actions',
        headCell: (
          <SortableHeadCell
            label={t('Actions')}
            width={columnSize.actions}
            padding={paddingSize.small}
            hasSorting={false}
          />
        ),
        cell: <StyledCell padding={paddingSize.small} />,
        format: (_value, innerData) => {
          return (
            <ActionCell
              title={getCanView(innerData) ? t('Edit') : t('Request Access')}
              onChange={handleActionMenu}
              data={innerData}
              items={data}
              openMenuAbove
            />
          );
        },
      },
    ];
  }, [
    t,
    handleActionMenu,
    handleChangeName,
    handleClickEditSharingSettings,
    handleChangeOwner,
    errors,
    data,
  ]);
};
