import { useCallback, useRef } from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

export interface ScrollIntoViewOptions {
  behavior?: 'auto' | 'smooth';
  block?: 'start' | 'center' | 'end' | 'nearest';
  inline?: 'start' | 'center' | 'end' | 'nearest';
}

export const scrollOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'start',
};

export const useScrollHeightTracker = (
  values: any,
  scrollOptions: ScrollIntoViewOptions
) => {
  const componentRef = useRef<HTMLDivElement>(null);
  useUpdateEffect(() => {
    if (values) {
      if (componentRef.current) {
        componentRef.current.scrollIntoView(scrollOptions);
      }
    }
  }, [values]);

  const scroll = useCallback(() => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView(scrollOptions);
    }
  }, [scrollOptions]);

  const scrollUnfocused = useCallback(() => {
    if (
      componentRef.current &&
      !componentRef.current.contains(document.activeElement)
    ) {
      componentRef.current.scrollIntoView(scrollOptions);
    }
  }, [scrollOptions]);

  const clickNotify = useCallback(() => {
    // small delay for belated components
    setTimeout(() => {
      scroll();
    }, 0);
  }, [scroll]);
  return { scroll, clickNotify, scrollUnfocused, componentRef };
};
