import styled from '@emotion/styled';
import { grayScale } from 'app/colors';

export const PivotTableChartWrapper = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  height: calc(100% + 15px);
  position: relative;
`;

export const PivotTableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: ${grayScale.light};
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;
