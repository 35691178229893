import React from 'react';

export default function ExperienceEntityIcon(props) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 0C5.59476 0 0 5.59476 0 12.5C0 19.4052 5.59476 25 12.5 25C19.4052 25 25 19.4052 25 12.5C25 5.59476 19.4052 0 12.5 0ZM12.5 23.3871C6.49698 23.3871 1.6129 18.503 1.6129 12.5C1.6129 6.49698 6.49698 1.6129 12.5 1.6129C18.503 1.6129 23.3871 6.49698 23.3871 12.5C23.3871 18.503 18.503 23.3871 12.5 23.3871ZM17.0464 16.0181C15.9173 17.369 14.2591 18.1452 12.5 18.1452C10.7409 18.1452 9.08266 17.369 7.95363 16.0181C7.66633 15.6754 7.15726 15.63 6.81956 15.9173C6.47681 16.2046 6.43145 16.7087 6.71875 17.0514C8.1502 18.7702 10.2621 19.7581 12.5 19.7581C14.7379 19.7581 16.8498 18.7702 18.2863 17.0464C18.5736 16.7036 18.5282 16.1946 18.1855 15.9123C17.8427 15.63 17.3337 15.6754 17.0464 16.0181ZM8.46774 11.6935C9.35988 11.6935 10.0806 10.9728 10.0806 10.0806C10.0806 9.18851 9.35988 8.46774 8.46774 8.46774C7.5756 8.46774 6.85484 9.18851 6.85484 10.0806C6.85484 10.9728 7.5756 11.6935 8.46774 11.6935ZM16.5323 11.6935C17.4244 11.6935 18.1452 10.9728 18.1452 10.0806C18.1452 9.18851 17.4244 8.46774 16.5323 8.46774C15.6401 8.46774 14.9194 9.18851 14.9194 10.0806C14.9194 10.9728 15.6401 11.6935 16.5323 11.6935Z"
        fill="currentColor"
      />
    </svg>
  );
}
