import { useMemo, useState, useEffect, useCallback } from 'react';
import useBasicBuilder, {
  LEFT_ID,
} from 'components/MultiColumnBuilder/useBasicBuilder';
import { v4 as uuidv4 } from 'uuid';

export const CUSTOM_FIELD_ITEM_ID = 'custom_field';

const selfDisabledDropZones = [LEFT_ID];

const nonHidableColumns = ['fullName', 'name', 'activityType'];

export const isNonHidebleColumn = ({ id }) => {
  return nonHidableColumns.some((columnId) => id === columnId);
};

export const useScheduledActivitiesBuilder = ({
  settings,
  defaultColumns,
  handleChange,
  t,
}) => {
  const [showModal, setShowModal] = useState(null);
  const rightItems = useMemo(() => {
    return settings.reduce((acc, { label, id, visible, fieldId, objectId }) => {
      if (visible) {
        return acc.concat({
          label,
          id,
          sortable: true,
          disabled: isNonHidebleColumn({ id }),
          displayName: label,
          fieldId,
          objectId,
          visible: true,
        });
      }
      return acc;
    }, []);
  }, [settings]);

  const rightItemsById = useMemo(() => {
    return rightItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [rightItems]);

  const onCustomFieldModalConfirm = useCallback((element) => {
    setShowModal({ ...element });
  }, []);

  const customFieldItem = useMemo(() => {
    return {
      label: t('New Custom Field (Drag to Create)'),
      displayName: t('New Custom Field (Drag to Create)'),
      id: uuidv4(),
      fieldId: CUSTOM_FIELD_ITEM_ID,
      isLocked: false,
    };
  }, [t]);

  const leftItems = useMemo(() => {
    return settings.reduce(
      (acc, { id, label, disabled, fieldId, objectId }) => {
        if (!rightItemsById?.[id]) {
          return acc.concat({
            label,
            displayName: label,
            id,
            icon: '',
            sortable: true,
            disabled,
            fieldId,
            objectId,
          });
        }
        return acc;
      },
      []
    );
  }, [rightItemsById, settings]);

  const leftSettingItemsById = useMemo(() => {
    return leftItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [leftItems]);

  const leftWithDefaultColumns = useMemo(() => {
    return defaultColumns.reverse().reduce((acc, { id, label }) => {
      if (!rightItemsById?.[id] && !leftSettingItemsById?.[id]) {
        acc.unshift({
          label,
          displayName: label,
          id,
          icon: '',
          sortable: true,
          disabled: isNonHidebleColumn({ id }),
        });
      }
      return acc;
    }, leftItems);
  }, [defaultColumns, leftItems, leftSettingItemsById, rightItemsById]);

  const builderProps = useBasicBuilder({
    initialLeftColumnItems: leftWithDefaultColumns,
    initialRightColumnItems: rightItems,
    editable: true,
    selfDisabledDropZones,
    rightColumnItemsWithContentForceDirty: false,
  });

  useEffect(() => {
    const customFieldIndex = builderProps?.rightColumnItems.findIndex(
      ({ fieldId }) => fieldId === CUSTOM_FIELD_ITEM_ID
    );
    if (customFieldIndex >= 0) {
      setShowModal({
        index: customFieldIndex,
      });
    }
  }, [builderProps?.rightColumnItems, settings, handleChange]);

  return {
    builderProps: {
      ...builderProps,
      rightColumnItems: builderProps.rightColumnItems.map((item) => ({
        ...item,
        visible: true,
        width: item.width ? item.width : '100px',
      })),
      leftColumnHeaderProps: {
        ...builderProps.leftColumnHeaderProps,
        zIndex: 0,
      },
      rightColumnHeaderProps: {
        ...builderProps.rightColumnHeaderProps,
        zIndex: 0,
      },
    },
    showModal,
    setShowModal,
    onCustomFieldModalConfirm,
    customFieldItem,
  };
};
