import { useRef, useState, useCallback, useMemo } from 'react';
import { sanitizeTextForCharts } from '../ChartManager';

const useExternalLegend = (data = [], opts = {}) => {
  const {
    columnCount = 4,
    fixedWidth = false,
    prePad = false,
    colors = [],
  } = opts;
  const cbRef = useRef();
  const [hidden, setHidden] = useState([]);
  const hiddenRef = useRef([]);
  const [legendRange, setLegendRange] = useState([0, columnCount]);

  const handleClick = useCallback(
    (id) => {
      if (cbRef.current) {
        if (hidden.includes(id)) {
          setHidden((prev) => {
            const idx = prev.indexOf(id);
            const res = [...prev];
            res.splice(idx, 1);
            hiddenRef.current = res;
            return res;
          });
          return cbRef.current?.(sanitizeTextForCharts(id), false);
        } else {
          setHidden((prev) => {
            const res = [...prev, id];
            hiddenRef.current = res;
            return res;
          });
          return cbRef.current?.(sanitizeTextForCharts(id), true);
        }
      }
    },
    [hidden]
  );

  const hasMoreRows = useMemo(() => {
    return data.length > legendRange[1];
  }, [data, legendRange]);

  const hasPreviousRows = useMemo(() => {
    return legendRange[0] > 0;
  }, [legendRange]);

  const rotateRowDown = useCallback(() => {
    if (hasMoreRows) {
      setLegendRange((prev) => [prev[0] + columnCount, prev[1] + columnCount]);
    }
  }, [hasMoreRows, columnCount]);

  const rotateRowUp = useCallback(() => {
    if (hasPreviousRows) {
      setLegendRange((prev) => {
        const newStart = prev[0] - columnCount;
        if (newStart < 0) {
          return [0, columnCount];
        }
        return [newStart, prev[1] - columnCount];
      });
    }
  }, [hasPreviousRows, columnCount]);

  const withCallback = useCallback((func) => {
    cbRef.current = func;
  }, []);

  return {
    withCallback,
    legendProps: {
      handleClick,
      rotateRowDown,
      rotateRowUp,
      hasMoreRows,
      hasPreviousRows,
      hidden,
      legendRange,
      columnCount,
      fixedWidth,
      prePad,
      chartData: data,
      colors,
    },
    hiddenStable: hiddenRef,
  };
};

export default useExternalLegend;
