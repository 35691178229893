import { PillDropdown } from 'components/Inputs/PillDropdown';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getPillType } from './helpers';

export const QuickFilterPill = ({
  field,
  model,
  pillId,
  value,
  onChange,
  error,
  subscribeToScrollStart,
  unsubscribeFromScrollStart,
  fullFilterObject,
  search,
  showFacetCounts,
  ...rest
}) => {
  // this should be used by PillDropdown Component to be able to suppress facet counts
  // with no regards to other conditions that allow or dissallow facet counts dinamicly
  const [isFacetCountsHidden, setFacetCountsHidden] = useState(false);
  const hideFacetCountsOnTimeout = useCallback(() => {
    setFacetCountsHidden(true);
  }, []);

  const onOpen = useCallback(
    (onHide) => {
      subscribeToScrollStart(pillId, onHide);
    },
    [subscribeToScrollStart, pillId]
  );
  const onClose = useCallback(() => {
    unsubscribeFromScrollStart(pillId);
  }, [unsubscribeFromScrollStart, pillId]);

  useEffect(
    () => () => unsubscribeFromScrollStart(pillId),
    [pillId, unsubscribeFromScrollStart]
  );

  const handleChange = useCallback(
    (value) => {
      field?.id && onChange(field.id, pillId, value);
    },
    [onChange, field?.id, pillId]
  );

  const pillType = useMemo(() => getPillType(field), [field]);

  return (
    <PillDropdown
      value={value}
      onChange={handleChange}
      pillType={pillType}
      isInvalid={!!error}
      error={error}
      onOpen={onOpen}
      onClose={onClose}
      pillDropdownProps={{
        field,
        model,
        fullFilterObject,
        search,
        showFacetCounts: Boolean(
          showFacetCounts && !value?.length && !error && !isFacetCountsHidden
        ),
        hideFacetCountsOnTimeout,
      }}
      {...rest}
    />
  );
};
