import * as actions from './authenticationAction';
import * as reducers from './authenticationReducer';

/* -------------------- */
// Default State
/* -------------------- */
export const authenticationInitial = {
  authenticating: false,
  // Initialized to true, so app-wide UI shows Loader
  // during session rehydration AND so, if you start a
  // session on an authenticated route (wrapped with our Authenticed
  // redux-auth-wrapper HOC), you're not redirected away from that
  // page, then potentially redirected to the dashboard from Login
  // if your session rehydrates and you re-authenticate
  rehydrating: true,
  user: null,
  access: null,
  chosenBusiness: null,
  teamMember: null,
  errors: {
    login: null,
    requestPasswordReset: null,
    resetPassword: null,
    verifyAccount: null,
    refreshTeamMember: null,
  },
  isShowInactivityMessage: false,
  isShowTimeoutMessage: false,
};

/* -------------------- */
// Reducer Mapping
/* -------------------- */
export const authenticationHandlers = {
  [actions.LOGIN_START]: reducers.loginStart,
  [actions.LOGIN_SUCCESS]: reducers.loginSuccess,
  [actions.LOGIN_FAILURE]: reducers.loginFailure,

  [actions.UPDATE_PLUGIN_LIST_SUCCESS]: reducers.updatePluginAppsListSuccess,

  [actions.LOGOUT_START]: reducers.logoutStart,
  [actions.LOGOUT_SUCCESS]: reducers.logoutSuccess,
  [actions.LOGOUT_FAILURE]: reducers.logoutFailure,

  [actions.CHOOSE_BUSINESS_START]: reducers.chooseBusinessStart,
  [actions.CHOOSE_BUSINESS_SUCCESS]: reducers.chooseBusinessSuccess,
  [actions.CHOOSE_BUSINESS_FAILURE]: reducers.chooseBusinessFailure,

  [actions.UPDATE_BUSINESS_START]: reducers.updateBusinessStart,
  [actions.UPDATE_BUSINESS_SUCCESS]: reducers.updateBusinessSuccess,
  [actions.UPDATE_BUSINESS_FAILURE]: reducers.updateBusinessFailure,

  [actions.REHYDRATE_START]: reducers.rehydrateStart,
  [actions.REHYDRATE_SUCCESS]: reducers.rehydrateSuccess,
  [actions.REHYDRATE_FAILURE]: reducers.rehydrateFailure,

  [actions.REQUEST_PASSWORD_RESET_START]: reducers.requestPasswordResetStart,
  [actions.REQUEST_PASSWORD_RESET_SUCCESS]:
    reducers.requestPasswordResetSuccess,
  [actions.REQUEST_PASSWORD_RESET_FAILURE]:
    reducers.requestPasswordResetFailure,

  [actions.RESET_PASSWORD_START]: reducers.resetPasswordStart,
  [actions.RESET_PASSWORD_SUCCESS]: reducers.resetPasswordSuccess,
  [actions.RESET_PASSWORD_FAILURE]: reducers.resetPasswordFailure,

  [actions.REFRESH_ACCESS_SUCCESS]: reducers.refreshAccessSuccess,
  [actions.REFRESH_TEAM_MEMBER_SUCCESS]: reducers.refreshTeamMemberSuccess,
  [actions.REFRESH_TEAM_MEMBER_FAILURE]: reducers.refreshTeamMemberFailure,

  [actions.SHOW_INACTIVITY_MESSAGE]: reducers.showInactivityMessage,
  [actions.SHOW_TIMEOUT_MESSAGE]: reducers.showTimeoutMessage,
};
