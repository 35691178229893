import styled from '@emotion/styled';
import {
  SubNavigationNavLink,
  SubNavigationNavBar,
} from '__components/Navigation/SubNavigation';
import css from '@emotion/css';
import { grayScale, shadowOverlay, appBackground } from '__app/colors';
import { breakpoints, layers } from '__app/spacing';
import FullscreenModal from '__components/Modals/Fullscreen';
import BackButton from '__components/Button/BackButton';

export const HeaderContainer = styled.div<{ scrolledTop: boolean }>`
  background-color: ${({ scrolledTop }) =>
    scrolledTop ? appBackground : grayScale.white} !important;
  display: flex;
`;

export const ChildHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  & > button:last-child {
    margin-right: 0;
  }
`;

export const ChildCenterHeaderContainer = styled(ChildHeaderContainer)`
  flex: 1;
  padding-left: 18px;
  padding-right: 8px;
`;

export const StyledBackButton = styled(BackButton)`
  padding-right: 0;
`;

export const StyledFullscreenModal = styled(FullscreenModal)`
  min-width: ${breakpoints.lg}px;
  .modal-content {
    background-color: ${({ scrolledTop }) =>
      scrolledTop ? appBackground : grayScale.white};
    & > div:first-of-type {
      margin: 0;
      padding: 0 20px;
      @media (max-width: ${breakpoints.md}px) {
        margin: 0;
        padding: 0 15px;
      }
      z-index: ${layers.modals(1, 6)};
      height: 96px;
      background-color: ${({ scrolledTop }) =>
        scrolledTop
          ? appBackground
          : css`
              ${grayScale.white};
              ${shadowOverlay}
            `};
    }
    & > div:last-of-type {
      background-color: ${appBackground};
      overflow-y: auto;
    }
  }
`;

export const StyledSubNavigationNavBar = styled(SubNavigationNavBar)`
  & > .navbar {
    margin-top: 0;
  }
`;

export const StyledSubNavigationNavLink = styled(SubNavigationNavLink)`
  ${({ disabled }) =>
    disabled &&
    css`
      & > i,
      & > span {
        color: ${grayScale.mediumDark};
      }
      background-color: ${grayScale.light};
    `}
`;

export const MobileContainer = styled.div`
  padding: 15px;
  text-align: center;
`;
