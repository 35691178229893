import { useCallback, useReducer, useState } from 'react';
import {
  CurrentDropZone,
  DraggableGroup,
  DraggableItemWithMetadata,
  DropZoneChildrenActionType,
  DropZoneDirection,
  DropZoneMap,
  DropZoneReducer,
  LayoutActionType,
  LayoutBuilderProps,
  LayoutReducer,
  PlaceholderPosition,
} from './types';
import { GridState, childrenReducer, layoutReducer } from './state';
import {
  getCurrentDropZone,
  getPreciseDropZone,
  idChainSeparator,
  isDropZoneDifferentFromDraggable,
} from './util';
import { Row } from './Row';
import { Column } from './Column';

export const GridBuilder = (props: LayoutBuilderProps) => {
  const {
    onChange,
    initial = [],
    columnEnd,
    builderEnd,
    revision = 0,
    displayItem,
    columnClassName,
    rowClassName,
    placeholderClassName,
    draggableRows,
  } = props;
  const [canStartRowDrag, setCanStartRowDrag] = useState('');
  const [internalDirty, setInternalDirty] = useState(false);
  const [internalRevision, setInternalRevision] = useState(revision);
  const [dragItem, setDragItem] = useState<DraggableItemWithMetadata>();
  const [dragGroup, setDragGroup] = useState<DraggableGroup>();
  const [dropZones, setDropZones] = useState<DropZoneMap>({});
  const [groupDropZones, setGroupDropZones] = useState<DropZoneMap>({});
  const [dropZoneChildren, dispatchDropZoneChildren] =
    useReducer<DropZoneReducer>(childrenReducer, {});
  const [currentDropZone, setCurrentDropZone] = useState<CurrentDropZone>();
  const [layout, dispatchLayout] = useReducer<LayoutReducer>(
    layoutReducer,
    initial
  );
  const [placeholderPosition, setPlaceholderPosition] = useState<
    PlaceholderPosition | undefined
  >();

  const registerChild = useCallback(
    (dropZoneId: string, draggableId: string, element: HTMLDivElement) => {
      dispatchDropZoneChildren({
        type: DropZoneChildrenActionType.REGISTER,
        dropZoneId,
        draggableId,
        element,
      });
    },
    []
  );

  if (internalDirty) {
    setInternalDirty(false);
    onChange?.([...layout]);
  }

  if (internalRevision !== revision) {
    setInternalRevision(revision);
    dispatchLayout({
      type: LayoutActionType.REPLACE,
      initial,
    });
  }

  const handleDrop = useCallback(() => {
    setCanStartRowDrag('');
    if (dragItem) {
      dispatchLayout({
        type: LayoutActionType.DROP,
        dropZone: currentDropZone,
        dragItem: dragItem,
      });
      setInternalDirty(true);
    } else if (dragGroup) {
      dispatchLayout({
        type: LayoutActionType.DROP_GROUP,
        dropZone: currentDropZone,
        dragGroup: dragGroup,
      });
      setInternalDirty(true);
    }
    setCurrentDropZone(undefined);
    setDragItem(undefined);
    setDragGroup(undefined);
  }, [currentDropZone, dragItem, dragGroup]);

  const handleDeleteRow = useCallback(function (this: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const rowId = this;
    dispatchLayout({
      type: LayoutActionType.DELETE,
      rowId,
    });
    setInternalDirty(true);
  }, []);

  return (
    <GridState.Provider
      value={{
        dragItem,
        setDragItem,
        dragGroup,
        setDragGroup,
        dropZones,
        setDropZones,
        groupDropZones,
        setGroupDropZones,
        registerChild,
        handleDrop,
        currentDropZone,
        handleDeleteRow,
        layout,
        placeholderPosition,
        setPlaceholderPosition,
      }}
    >
      <div
        onMouseMove={(ev) => {
          if (dragItem) {
            const dropZoneId = getCurrentDropZone(ev, dropZones);

            if (dropZoneId) {
              const [rowId, columnId] = dropZoneId.split(idChainSeparator);
              const children = dropZoneChildren[dropZoneId];

              if (children) {
                const anyValidChildren = Object.keys(children).some((child) =>
                  Boolean(children[child])
                );

                if (anyValidChildren) {
                  const target = getPreciseDropZone(ev, children, dragItem.id);
                  const itemId = target.id;
                  if (
                    isDropZoneDifferentFromDraggable(
                      itemId,
                      dragItem.id,
                      rowId,
                      columnId,
                      layout,
                      target.direction
                    )
                  ) {
                    return setCurrentDropZone((existing) => {
                      const targetId = `${dropZoneId}${idChainSeparator}${target.id}`;
                      if (
                        existing &&
                        existing.id === targetId &&
                        existing.direction === target.direction
                      ) {
                        return existing;
                      }
                      return {
                        ...target,
                        id: targetId,
                      };
                    });
                  } else {
                    return setCurrentDropZone(undefined);
                  }
                }
              }

              return setCurrentDropZone((existing) => {
                const targetId = `${dropZoneId}${idChainSeparator}`;

                if (existing && existing.id === targetId) {
                  return existing;
                }

                return {
                  id: targetId,
                  direction: DropZoneDirection.DOWN,
                };
              });
            }
          } else if (dragGroup) {
            const dropZoneId = getCurrentDropZone(
              ev,
              groupDropZones,
              dragGroup.id
            );

            if (dropZoneId) {
              const prec = getPreciseDropZone(ev, groupDropZones, dragGroup.id);

              return setCurrentDropZone((existing) => {
                if (
                  existing &&
                  existing.id === dropZoneId &&
                  existing.direction === prec.direction
                ) {
                  return existing;
                }

                return {
                  id: prec.id,
                  direction: prec.direction,
                };
              });
            }
          }

          setCurrentDropZone(undefined);
        }}
        className="flex flex-col"
      >
        {layout.map((row) => {
          return (
            <Row
              key={row.id}
              id={row.id}
              className={rowClassName}
              draggable={draggableRows && canStartRowDrag === row.id}
              placeholderClassName={placeholderClassName}
              setCanStartDrag={setCanStartRowDrag}
            >
              {row.columns.map((column) => {
                return (
                  <Column
                    key={column.id}
                    id={`${row.id}${idChainSeparator}${column.id}`}
                    items={column.items}
                    rowId={row.id}
                    columnEnd={columnEnd}
                    displayItem={displayItem}
                    width={column.width}
                    className={columnClassName}
                    placeholderClassName={placeholderClassName}
                    setCanStartRowDrag={setCanStartRowDrag}
                  />
                );
              })}
            </Row>
          );
        })}
        {builderEnd}
      </div>
    </GridState.Provider>
  );
};
