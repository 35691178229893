import React from 'react';
import {
  HTMLBlockSettingsSection,
  ElementContainerSettingsSection,
} from '../../settings';

export const FormHTMLBlockSettings = ({ node }) => (
  <>
    <HTMLBlockSettingsSection node={node} />
    <ElementContainerSettingsSection node={node} />
  </>
);
