import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(50%, 1fr) 1fr;
  align-items: center;
  ${({ marginBottom = 0 }) => css`
    margin-bottom: ${marginBottom}px;
  `}
`;

export const HeaderToggles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
`;
