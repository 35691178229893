import { SettingsContentCard } from '__components/Card/Card';
import { ContentWidth } from '__components/Layout/PageContentWidth';
import { PageSizing } from '__components/Layout/PageContentWidth';
import { Header, Title } from './styles';
import { SearchPill } from '@kizen/kds/SearchPill';
import { useTranslation } from 'react-i18next';
import { COLUMN_SIZE, Grid } from '@kizen/kds/Grid';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Tile } from './components/Tile';
import { useQuery } from 'react-query';
import PluginService from 'services/PluginService';
import { PLUGINS } from 'queries/query-keys';
import { MarketplacePlugin } from './types';
import { PluginContext } from 'ts-components/Plugins/PluginContext';
import { monitoringExceptionHelper } from 'sentry/helpers';
import Loader from '__components/Kizen/Loader';
import { useSelector } from 'react-redux';
import { Typography } from '@kizen/kds/Typography';

const MarketplacePage = () => {
  const { t } = useTranslation();
  const [pendingChange, setPendingChange] = useState({
    apiName: '',
    enabled: false,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const auth = useSelector((s: any) => s.authentication);
  const business = auth.chosenBusiness;
  const adminRoleId = business?.admin_permission_role?.id;

  const isAdmin = auth.teamMember?.roles?.includes(adminRoleId);

  const { data: plugins, isLoading } = useQuery<MarketplacePlugin[]>({
    queryFn: () => PluginService.getPluginApps(),
    queryKey: PLUGINS.GET_PLUGIN_APPS,
  });

  const allPluginsByApiName = useMemo(() => {
    return (
      plugins?.reduce((acc: any, curr: any) => {
        return {
          ...acc,
          [curr.api_name]: curr,
        };
      }, {}) ?? {}
    );
  }, [plugins]);

  const { data: businessPluginApps, refetch: refetchBusinessPluginApps } =
    useQuery({
      queryFn: () => PluginService.getBusinessPluginApps(),
      queryKey: PLUGINS.GET_BUSINESS_PLUGIN_APPS,
    });

  const businessPluginAppsByApiName = useMemo(() => {
    return (
      businessPluginApps?.results?.reduce((acc: any, curr: any) => {
        return {
          ...acc,
          [curr.plugin_app.api_name]: curr,
        };
      }, {}) ?? {}
    );
  }, [businessPluginApps]);

  const { plugins: installedPlugins, fullRefetch } = useContext(PluginContext);

  const onChangeEnabled = useCallback(
    async (pluginApiName: string, enabled: boolean) => {
      try {
        setPendingChange({ apiName: pluginApiName, enabled });
        const existingConfig = businessPluginAppsByApiName[pluginApiName];

        if (!existingConfig) {
          const template =
            allPluginsByApiName[pluginApiName]?.config_template ?? {};

          await PluginService.createBusinessPluginApp({
            disabled: false,
            plugin_app: {
              api_name: pluginApiName,
            },
            config: template,
          });
        } else {
          await PluginService.updateBusinessPluginApp(pluginApiName, {
            disabled: !enabled,
          });
        }

        await Promise.all([fullRefetch(), refetchBusinessPluginApps()]);
      } catch (ex) {
        monitoringExceptionHelper(ex);
      } finally {
        setPendingChange({ apiName: '', enabled: false });
      }
    },
    [
      fullRefetch,
      businessPluginAppsByApiName,
      refetchBusinessPluginApps,
      allPluginsByApiName,
    ]
  );

  const onChangeConfig = useCallback(
    async (pluginApiName: string, config: any) => {
      try {
        await PluginService.updateBusinessPluginApp(pluginApiName, {
          config,
        });
        await Promise.all([fullRefetch(), refetchBusinessPluginApps()]);
      } catch (ex) {
        monitoringExceptionHelper(ex);
      }
    },
    [refetchBusinessPluginApps, fullRefetch]
  );

  const gridContent = useMemo(() => {
    if (!plugins) {
      return [];
    }

    const res = plugins
      .filter((p) =>
        searchTerm
          ? p.name.toLowerCase().includes(searchTerm.toLowerCase())
          : true
      )
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .reduce((acc, curr) => {
        const res = [...acc];
        const last = res[res.length - 1];

        if (!last || last.content.length === 3) {
          res.push({
            id: `row-${res.length}`,
            content: [],
            layout: [],
          });
        }

        const installation = installedPlugins?.[curr.api_name];
        const existingBusinessPluginApp =
          businessPluginAppsByApiName[curr.api_name];

        res[res.length - 1].layout.push(COLUMN_SIZE.THIRD_WIDTH);
        res[res.length - 1].content.push(
          <Tile
            key={curr.id}
            plugin={curr}
            installation={installation}
            onChangeEnabled={onChangeEnabled}
            onChangeConfig={onChangeConfig}
            overrideCheckedState={
              pendingChange.apiName === curr.api_name
                ? pendingChange.enabled
                : undefined
            }
            hasBusinessPluginApp={Boolean(existingBusinessPluginApp)}
            currentConfig={existingBusinessPluginApp?.config}
            isAdmin={isAdmin}
          />
        );

        return res;
      }, [] as any);

    return res;
  }, [
    plugins,
    installedPlugins,
    onChangeEnabled,
    pendingChange,
    businessPluginAppsByApiName,
    searchTerm,
    onChangeConfig,
    isAdmin,
  ]);

  return (
    <PageSizing>
      <ContentWidth>
        <Header>
          <SearchPill
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder={t('Search Marketplace')}
            inline
          />
          <Title>{t('Marketplace')}</Title>
        </Header>
      </ContentWidth>
      <SettingsContentCard>
        {isLoading ? (
          <Loader loading />
        ) : (
          <>
            {gridContent.length > 0 ? (
              <Grid gap={20} config={gridContent} />
            ) : (
              <Typography className="text-center">
                {t('No Apps Found')}
              </Typography>
            )}
          </>
        )}
      </SettingsContentCard>
    </PageSizing>
  );
};

export default MarketplacePage;
