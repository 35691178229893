import React from 'react';
import * as PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ELEMENT_HEIGHT } from './constants';

export const CenterVerticalElementMargin = {
  small: 0,
  large: 18,
};

const Wrapper = styled.div`
  height: ${ELEMENT_HEIGHT}px;
  display: flex;
  align-items: center;
  ${({ margin }) =>
    margin === CenterVerticalElementMargin.large
      ? css`
          margin-right: ${CenterVerticalElementMargin.large}px;
        `
      : css`
          margin-right: ${CenterVerticalElementMargin.small}px;
        `}
  &.narrow: {
    max-width: 8px;
  }
`;

export const CenterVerticalElement = ({
  children,
  margin = CenterVerticalElementMargin.small,
}) => {
  return <Wrapper margin={margin}>{children}</Wrapper>;
};

Wrapper.propTypes = {
  margin: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
