export const FreeTrialProcessSteps = {
  USER_INFO: 'user_info',
  USAGE_INFO: 'usage_info',
};

export const CloudTypes = {
  MARKETING: 'marketing',
  SALES: 'sales',
  EXPERIENCE: 'experience',
  BUSINESS: 'business',
};
