import KizenTypography from 'app/kizentypo';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectorWrapper, StyledGroupSelector } from './styles';
import { useHistory } from 'react-router-dom';
import EditChartGroupsButton from './EditChartGroupsButton';

const GroupSelector = ({
  mobile = false,
  dashboards,
  currentDashboard,
  setCurrentDashboardId,
  contacts,
  objectId,
  handleClickAddDashboard,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickChartGroupSettings = useCallback(() => {
    if (contacts) {
      history.push('/clients/charts/settings');
    } else {
      history.push(`/custom-objects/${objectId}/charts/settings`);
    }
  }, [history, contacts, objectId]);

  const dashboardsToShow = useMemo(
    () => dashboards.filter((dash) => !dash.hidden),
    [dashboards]
  );

  return (
    <SelectorWrapper mobile={mobile}>
      {!mobile ? <KizenTypography>{t('Chart Group')}</KizenTypography> : null}
      <StyledGroupSelector
        classNamePrefix="chart-group"
        options={dashboardsToShow}
        value={dashboardsToShow.length > 0 ? currentDashboard : null}
        placeholder={t('Chart Group')}
        onChange={(id) => setCurrentDashboardId(id)}
        buildHref={(id) => {
          return contacts
            ? `/clients/charts/${id}`
            : `/custom-objects/${objectId}/charts/${id}`;
        }}
        handleClickEditDashboards={
          !mobile ? handleClickChartGroupSettings : undefined
        }
        handleClickAddDashboard={handleClickAddDashboard}
        components={{
          Menu: EditChartGroupsButton,
        }}
      />
    </SelectorWrapper>
  );
};

export default GroupSelector;
