import { useState } from 'react';
import { TemplateToolBar } from '../components/ToolBar';
import { CardTitle, ContentCard, SwitchControl } from '../styles';
import { MobileFallback } from 'pages/Common/MobileFallback';
import QuickFiltersService from 'services/QuickFiltersService';
import {
  getPathBack,
  getQuickFiltersPayload,
  repairSettings,
} from '../helpers';
import { CONFIG_KEY, NAV_BAR_HEIGHT } from '../constants';
import { useAsync } from 'react-use';
import Loader from 'components/Kizen/Loader';
import useField from 'hooks/useField';
import { useDraggableColumns } from '../hooks/useDraggableColumns';
import { DraggableColumns } from '../components/DraggableColumns';
import { getOriginalError } from 'services/AxiosService';
import { TemplateNameInput } from '../components/TemplateNameInput';
import { useHistory } from 'react-router-dom';
import { useDirtyState } from '../hooks/useDirtyState';
import ConfirmNavigationWithActionModal from 'components/Modals/presets/ConfirmNavigationWithAction';
import { useTranslation } from 'react-i18next';
import { PageSizing } from 'components/Layout/PageContentWidth';
import { toastVariant, useToast } from 'components/ToastProvider';
import Switch from 'components/Kizen/Switch';
import { enrichAndRepairQuickFilterSettings } from 'store/utilities';
import { deferExecution } from 'utility/defer';

export const QuickFiltersTemplate = ({
  categorizedFields,
  fields,
  disabled,
  isMobile,
  model,
  templateId,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const { value: template, loading: loadingTemplate = true } =
    useAsync(async () => {
      const template = await QuickFiltersService.getTemplate(
        templateId,
        model?.id
      );
      const enrichedSettings = await enrichAndRepairQuickFilterSettings(
        template?.[CONFIG_KEY]?.quick_filter_settings,
        fields
      );
      return {
        ...template,
        [CONFIG_KEY]: {
          ...template[CONFIG_KEY],
          quick_filter_settings: enrichedSettings,
        },
      };
    }, [templateId, fields]);

  const history = useHistory();

  const [name, setName] = useField(template?.name || '');
  const [templateName, setTemplateName] = useField(template?.name || '');
  const [nameError, setNameError] = useState(null);
  const [saving, setSaving] = useState(false);

  const [settings, setSettings] = useField(
    repairSettings(template?.[CONFIG_KEY]?.quick_filter_settings),
    [template]
  );

  const [facets, setFacets] = useField(
    template?.[CONFIG_KEY]?.quick_filtering_facets ?? false
  );
  const [templateFacets, setTemplateFacets] = useField(
    template?.[CONFIG_KEY]?.quick_filtering_facets ?? false
  );

  const setQuickFilteringFacets = (e) => {
    setFacets(e.target.checked);
  };

  const { leftItems, rightItems, ...DnDProps } = useDraggableColumns(
    settings,
    categorizedFields,
    fields
  );

  const dirtySettings = useDirtyState(settings, rightItems);
  const dirty = dirtySettings || facets !== templateFacets;

  const handleSubmit = async ({ withClose } = {}) => {
    setSaving(true);
    const nextSettings = getQuickFiltersPayload(rightItems);
    try {
      await QuickFiltersService.updateTemplate(
        templateId,
        name,
        { quick_filter_settings: nextSettings, quick_filtering_facets: facets },
        undefined,
        undefined,
        model?.id
      );

      setTemplateName(name);
      setSettings(
        nextSettings.map((setting, i) => {
          if (rightItems[i].relatedObjectField) {
            return {
              ...setting,
              relatedObjectField: rightItems[i].relatedObjectField,
            };
          }
          return setting;
        })
      );
      setTemplateFacets(facets);

      if (withClose) {
        deferExecution(() => {
          history.push(getPathBack(history.location.pathname));
        });
      }

      showToast({
        message: t(
          "'{{name}}' Quick Filter template has been saved successfully.",
          {
            name,
          }
        ),
        variant: toastVariant.SUCCESS,
      });
    } catch (err) {
      const { name } = getOriginalError(err) || {};
      setNameError(name || null);
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (value) => {
    setName(value);
    setNameError(null);
  };

  return (
    <PageSizing>
      <TemplateToolBar
        onSave={handleSubmit}
        disabled={
          disabled ||
          saving ||
          loadingTemplate ||
          !(dirty || templateName !== name)
        }
        isMobile={isMobile}
      />
      <ContentCard heightOffset={NAV_BAR_HEIGHT}>
        <MobileFallback isMobile={isMobile}>
          <Loader loading={loadingTemplate}>
            <CardTitle>
              <TemplateNameInput
                value={name}
                onChange={handleChange}
                error={nameError}
              />
            </CardTitle>
            <SwitchControl
              label={t('Make Search Faceted')}
              labelInfo={t(
                'Faceting your search will reduce the selectable options to only those that contain results. You will also be able to preview how many results will be returned for each facet.'
              )}
            >
              <Switch checked={facets} onChange={setQuickFilteringFacets} />
            </SwitchControl>
            <DraggableColumns
              leftItems={leftItems}
              rightItems={rightItems}
              fields={fields}
              {...DnDProps}
            />
          </Loader>
        </MobileFallback>
      </ContentCard>
      <ConfirmNavigationWithActionModal
        when={dirty || templateName !== name}
        heading={t('Your Template Has Unsaved Changes')}
        buttonText={t('Save')}
        actionBtnColor="green"
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        action={handleSubmit}
        preventNavigation={!!nameError}
        onAdditionalConfirm={() => setNameError(null)}
      >
        {t('All unsaved changes will be lost unless you save your filters.')}
      </ConfirmNavigationWithActionModal>
    </PageSizing>
  );
};
