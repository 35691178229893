import { useMemo, useState } from 'react';
import { FilterMetadata, StepData } from '../src/types';
import { Filter, FilterOpts } from '../src/filter';
import { Metadata } from '../src/meta-data';
import { getContactVariables } from '../src/utils';

type V = any;

const getErrorFn = (fn: string) => () => {
  throw Error(
    `'${fn}' was called prior to creating the filter. 'create' needs to be called prior to any other operations.`
  );
};

export const useFilter = <T extends string>(
  id: string,
  isContact: boolean,
  isPipeline: boolean
) => {
  const [filter, setFilter] = useState<Filter<T>>();
  const [steps, setSteps] = useState<[T, StepData<V>][]>([]);

  const ops = useMemo(() => {
    const create = (
      config: FilterMetadata<T>,
      metadata: Metadata,
      { init, vars: v }: FilterOpts<T> = {}
    ) => {
      const vars = v ?? getContactVariables(id, isContact, isPipeline);
      const filt = new Filter<T>(config, metadata, { init, vars });
      setFilter(filt);
      setSteps([...filt]);
    };

    if (!(filter instanceof Filter)) {
      return {
        create,
        build: () => ({}),
        next: getErrorFn('next'),
        save: getErrorFn('save'),
        set: getErrorFn('set'),
        toString: (): string => '',
        update: () => {},
        isMyRecord: (): any => null,
        isStageRecord: (): any => null,
      };
    }

    return {
      create,
      build: () => filter.build(),
      next: (...args: Parameters<Filter<T>['next']>) => {
        filter.next(...args);
      },
      save: () => filter.save(),
      set: (...args: Parameters<Filter<T>['set']>) => {
        filter.set(...args);
      },
      toString: () => filter.toString(),
      isMyRecord: () => filter.isMyRecord(),
      isStageRecord: () => filter.isStageRecord(),
      update: () => setSteps([...filter]),
    };
  }, [id, isContact, isPipeline, filter]);

  return [steps, ops] as const;
};
