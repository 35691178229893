import { useMemo } from 'react';

const usePipelineMetadata = (data) => {
  const pipeline = useMemo(() => {
    return {
      entityName: data?.metadata?.pipeline_info?.entity_name ?? '',
      objectName: data?.metadata?.pipeline_info?.object_name ?? '',
    };
  }, [data]);

  const currencySymbol = useMemo(() => {
    return data?.metadata?.pipeline_info?.currency_symbol ?? '$';
  }, [data]);

  return [pipeline, currencySymbol];
};

export default usePipelineMetadata;
