import { PageSizing } from 'components/Layout/PageContentWidth';
import {
  SubNavigationNavBar,
  SubNavigationNavLink,
} from 'components/Navigation/SubNavigation';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { subRoutes } from './util';
import searchState from 'components/AccessRequests/searchState';
import useDashboardList from 'components/AccessRequests/useDashboardList';
import CreateLayoutModal from 'components/DashboardGrid/CreateLayoutModal';
import RequestModal from 'components/AccessRequests/modal';
import useDashboardConfig from '../hooks/useDashboardConfig';

const DashboardSettingsController = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { debouncedTerm } = useContext(searchState.Context);

  const {
    modalProps,
    requestModalProps,
    canUserEditPermissions,
    existingDashboard,
    data,
    loading,
    configLoading,
    refetching,
    ...routeProps
  } = useDashboardList(
    { debouncedTerm, type: 'generic_dashboard' },
    useDashboardConfig
  );

  useEffect(() => {
    if (location.pathname === '/dashboard/settings') {
      history.push('/dashboard/settings/mine');
    }
  }, [location, history]);

  const routes = useMemo(() => {
    return subRoutes(true);
  }, []);

  return (
    <>
      <SubNavigationNavBar>
        {routes.map((route, index) => {
          return (
            <SubNavigationNavLink
              key={`${route.path}-link`}
              route={{
                label: route.label(t),
                path: route.path,
                icon: route.icon,
              }}
              numberOfLinks={routes.length}
              isLast={index === routes.length - 1}
            />
          );
        })}
      </SubNavigationNavBar>
      <CreateLayoutModal
        {...modalProps}
        canUserEditPermissions={canUserEditPermissions}
        existing={existingDashboard}
        entity={t('Dashboard')}
      />
      <RequestModal {...requestModalProps} dashboard={existingDashboard} />
      <PageSizing>
        <Switch>
          {routes.map((route) => {
            return (
              <Route key={route.path} exact={route.exact} path={route.path}>
                {/* These components are the page in /pages */}
                <route.Component
                  goBackPath="/dashboard"
                  data={data[route.dataKey] ?? []}
                  loading={loading[route.dataKey] || configLoading}
                  isRefetching={refetching[route.dataKey]}
                  {...routeProps}
                />
              </Route>
            );
          })}
        </Switch>
      </PageSizing>
    </>
  );
};

export default DashboardSettingsController;
