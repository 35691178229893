import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { getAccessNumber } from '@kizen/permissions/utils';
import { useIsRuleApplied } from '@kizen/permissions/hooks/useIsRuleApplied';
import { usePermissionSetter } from '@kizen/permissions/hooks/usePermissionSetter';
import { usePermissionValue } from '@kizen/permissions/hooks/usePermissionValue';
import { grayScale, colorsButton } from 'app/colors';
import { layers } from 'app/spacing';
import Checkbox from 'components/Checkbox/Checkbox';
import { KizenRangeInput } from './KizenRangeInput';

const SIZE = 14;

const StyledCheckbox = styled(Checkbox)`
  cursor: pointer;
  justify-self: center;
  margin: 0;
  margin-left: 9px;

  ${({ min }) => css`
    grid-column: ${min + 1};
  `}
`;

const RangeInputContainer = styled.div`
  width: 100%;
  ${({ min, max }) => css`
    padding-left: calc(50% / (${max + 2} - ${min + 1}) - ${SIZE / 2}px + 1px);
    padding-right: calc(50% / (${max + 2} - ${min + 1}) - ${SIZE / 2}px - 1px);
    grid-column: ${min + 1} / ${max + 2};
  `}
`;

const RangeInput = styled(KizenRangeInput)`
  &&& {
    display: block;
    [data-reach-slider-input] {
      margin: 0;
      width: 100%;
      max-width: none;
      height: initial;
      cursor: pointer;
      border-radius: ${SIZE / 2}px;
      background-color: ${grayScale.medium};
    }
    [data-reach-slider-handle] {
      border-width: 2px;
      border-color: ${colorsButton.blue.hover};
      z-index: ${layers.content(
        0
      )}; // Appear beneath the Expander gradient (see PermissionSection)
    }
    [data-reach-slider-track] {
      height: ${SIZE}px;
      margin: 0 ${SIZE / 2}px;
      background: initial;
    }
    [data-reach-slider-track-highlight] {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: ${colorsButton.blue.hover};
    }
    [data-reach-slider-marker] {
      border-radius: 50%;
      > * {
        // Don't show step number
        display: none;
      }
      // These styles are only for the first marker
      height: ${SIZE}px;
      width: ${SIZE}px;
      background-color: ${colorsButton.blue.hover};
      ~ [data-reach-slider-marker] {
        // And these styles are for all other markers
        height: 4px;
        width: 4px;
        background-color: ${grayScale.white};
      }
    }
    ${({ min, max }) => {
      // Special case for a degenerate slider with no steps
      return (
        min === max &&
        css`
          width: ${SIZE}px;
          [data-reach-slider-marker] {
            left: 0;
          }
          [data-reach-slider-handle] {
            left: -${SIZE / 2}px;
          }
        `
      );
    }}
  }
`;

export const PermissionRangeInput = ({
  permissionKey,
  category,
  affordance = 'range',
  step = 1,
  onChange,
  ...rest
}) => {
  const setter = usePermissionSetter(permissionKey);
  const isRuleApplied = useIsRuleApplied(permissionKey);
  const value = usePermissionValue(permissionKey, ({ value }) => value);
  const min = usePermissionValue(permissionKey, ({ allowed_access }) =>
    getAccessNumber(allowed_access[0])
  );
  const max = usePermissionValue(permissionKey, ({ allowed_access }) =>
    getAccessNumber(allowed_access[allowed_access.length - 1])
  );
  const isBooleanPermission = usePermissionValue(
    permissionKey,
    ({ initial }) => typeof initial?.value === 'boolean'
  );

  if (affordance === 'checkbox') {
    return (
      <StyledCheckbox
        data-qa-permission-input={permissionKey}
        data-qa-min={min}
        data-qa-max={max}
        type="checkbox"
        checked={value}
        min={min}
        max={max}
        disabled={isRuleApplied}
        onChange={(ev) => setter(ev.target.checked)}
        {...rest}
      />
    );
  }

  return (
    <RangeInputContainer min={min} max={max}>
      <RangeInput
        data-qa-permission-input={permissionKey}
        data-qa-min={min}
        data-qa-max={max}
        withSteps
        value={value}
        min={isBooleanPermission ? 0 : min}
        max={isBooleanPermission ? Math.min(max, 1) : max}
        step={step}
        showTooltip={false}
        onChange={(val) => setter(isBooleanPermission ? Boolean(val) : val)}
        {...rest}
      />
    </RangeInputContainer>
  );
};
