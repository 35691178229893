import NumberInput from 'react-number-format';
import styled from '@emotion/styled';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import BaseColumnTitle from 'components/Board/BaseColumnTitle';
import { SUMMARY_VALUES } from 'store/customObjectsRecordsPage/records.redux';
import { FIELD_TYPES } from 'utility/constants';
import { fontSizes } from 'app/typography';

const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
  display: block;
`;
const StyledSecondaryTextEllipsisWithTooltip = styled(
  StyledTextEllipsisWithTooltip
)`
  font-size: ${fontSizes.small};
`;

export const SecondarySubTitle = ({ settings, data, fields }) => {
  if (settings.secondarySummaryMetric !== SUMMARY_VALUES.noSummary) {
    if (settings.secondarySummaryMetric === SUMMARY_VALUES.numberOfRecords) {
      return (
        <NumberInput
          value={data.secondarySummary}
          displayType="text"
          thousandSeparator
          prefix={null}
          decimalScale={0}
          renderText={(value) => (
            <StyledSecondaryTextEllipsisWithTooltip>
              {`${value} ${settings.secondarySummaryExplanation}`}
            </StyledSecondaryTextEllipsisWithTooltip>
          )}
        />
      );
    }

    if (settings.secondarySummaryMetric === SUMMARY_VALUES.totalValue) {
      return (
        <NumberInput
          value={data.secondarySummary || 0}
          displayType="text"
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          prefix={data.commerceValue?.symbol || '$'}
          renderText={(value) => (
            <StyledSecondaryTextEllipsisWithTooltip>
              {value} {settings.secondarySummaryExplanation}
            </StyledSecondaryTextEllipsisWithTooltip>
          )}
        />
      );
    }

    const field = fields.find(
      ({ id }) => id === settings.secondarySummaryMetric
    );
    if (!field) return null;
    const showDecimalPart =
      field.fieldType === FIELD_TYPES.Money.type ||
      field.fieldType === FIELD_TYPES.Decimal.type;

    return (
      <NumberInput
        value={data.secondarySummary || '0'}
        displayType="text"
        thousandSeparator
        prefix={
          field.fieldType === FIELD_TYPES.Money.type
            ? field.moneyOptions.symbol || '$'
            : null
        }
        fixedDecimalScale={showDecimalPart}
        decimalScale={showDecimalPart ? 2 : null}
        renderText={(value) => (
          <StyledSecondaryTextEllipsisWithTooltip>
            {`${value} ${settings.secondarySummaryExplanation}`}
          </StyledSecondaryTextEllipsisWithTooltip>
        )}
      />
    );
  }

  return null;
};

export const SubTitle = ({ settings, data, fields }) => {
  if (settings.summaryMetric !== SUMMARY_VALUES.noSummary) {
    if (settings.summaryMetric === SUMMARY_VALUES.numberOfRecords) {
      return (
        <NumberInput
          value={data.summary}
          displayType="text"
          thousandSeparator
          prefix={null}
          decimalScale={0}
          renderText={(value) => (
            <StyledTextEllipsisWithTooltip>
              {`${value} ${settings.summaryExplanation}`}
            </StyledTextEllipsisWithTooltip>
          )}
        />
      );
    }

    if (settings.summaryMetric === SUMMARY_VALUES.totalValue) {
      return (
        <NumberInput
          value={data.summary || 0}
          displayType="text"
          thousandSeparator
          fixedDecimalScale
          decimalScale={2}
          prefix={data.commerceValue?.symbol || '$'}
          renderText={(value) => (
            <StyledTextEllipsisWithTooltip>
              {value} {settings.summaryExplanation}
            </StyledTextEllipsisWithTooltip>
          )}
        />
      );
    }

    const field = fields.find(({ id }) => id === settings.summaryMetric);
    if (!field) return null;
    const showDecimalPart =
      field.fieldType === FIELD_TYPES.Money.type ||
      field.fieldType === FIELD_TYPES.Decimal.type;

    return (
      <NumberInput
        value={data.summary || '0'}
        displayType="text"
        thousandSeparator
        prefix={
          field.fieldType === FIELD_TYPES.Money.type
            ? field.moneyOptions.symbol || '$'
            : null
        }
        fixedDecimalScale={showDecimalPart}
        decimalScale={showDecimalPart ? 2 : null}
        renderText={(value) => (
          <StyledTextEllipsisWithTooltip>
            {`${value} ${settings.summaryExplanation}`}
          </StyledTextEllipsisWithTooltip>
        )}
      />
    );
  }

  return null;
};

const ColumnTitle = ({
  title,
  subTitle = null,
  secondarySubTitle,
  isSelected,
  onChangeSelection,
  checkBoxProps,
  ...props
}) => {
  return (
    <BaseColumnTitle {...props}>
      <TextEllipsisWithTooltip weight="bold">{title}</TextEllipsisWithTooltip>
      {subTitle}
      {secondarySubTitle}
    </BaseColumnTitle>
  );
};

export default ColumnTitle;
