import { reorderDashboardsQuery } from 'components/AccessRequests/useDashboardList';
import { DASHBOARD } from 'queries/query-keys';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

export const useReorderDashboards = ({ onSuccess, queryKey }) => {
  const queryClient = useQueryClient();

  const reorderDashboardsMutation = useMutation(
    (result) => reorderDashboardsQuery(result.id, result.payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(DASHBOARD.DASHBOARDS);
        onSuccess?.();
      },
      onError: (_err, _data, context) => {
        queryClient.setQueryData(queryKey, context.previous);
      },
      onMutate: (vars) => {
        const previous = queryClient.getQueryData(queryKey);

        const { id, payload } = vars;

        queryClient.setQueryData(queryKey, (prev) => {
          const result = [...prev];
          const originalIndex = result.findIndex((i) => i.id === id);
          const [removed] = result.splice(originalIndex, 1);
          if (payload.move_after_dashboard_id) {
            const targetIndex = result.findIndex(
              (i) => i.id === payload.move_after_dashboard_id
            );
            result.splice(targetIndex + 1, 0, removed);
          } else {
            const targetIndex = result.findIndex(
              (i) => i.id === payload.move_before_dashboard_id
            );
            result.splice(targetIndex, 0, removed);
          }

          return result;
        });

        return { previous };
      },
    }
  );

  const handleReorderDashboards = useCallback(
    (payload, id) => {
      reorderDashboardsMutation.mutate({ id, payload });
    },
    [reorderDashboardsMutation]
  );

  return handleReorderDashboards;
};
