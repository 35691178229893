import React from 'react';
import { useTranslation } from 'react-i18next';
import { TopLayerSelect } from '__components/PageBuilder/components/TopLayerSelect';
import { NumberFormat } from 'libphonenumber-js';

interface IntegratedInboxProps {
  modalLayer: NumberFormat;
  errorMessage: string;
  showError: boolean;
  isLoadingAccounts: boolean;
  onChange: any;
  accountSelectOptions: any[];
}

export const IntegratedInbox = React.memo<IntegratedInboxProps>(
  ({
    modalLayer,
    errorMessage,
    showError,
    isLoadingAccounts,
    accountSelectOptions,
    ...props
  }) => {
    const { t } = useTranslation();

    return (
      <TopLayerSelect
        modalLayer={modalLayer}
        variant="underline"
        label={t('Integrated Inbox')}
        placeholder={t('Choose Integrated Inbox')}
        options={accountSelectOptions}
        isLoading={isLoadingAccounts}
        validate={{ message: errorMessage, showMessage: showError }}
        {...props}
      />
    );
  }
);
