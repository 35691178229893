/* eslint-disable react/display-name */
import { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import useToggle from 'react-use/lib/useToggle';
import { DraggableCore } from 'react-draggable';
import { gutters, layers } from 'app/spacing';
import { colorsButton, tableHover, grayScale } from 'app/colors';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import { addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
  getDateWithTimezone,
  getScheduledTimezoneAdjusted,
  businessLocalTimezoneDstOffset,
} from 'app/timezone';

import { getColumnNumeric } from 'components/Fields/InlineTableFieldInput';
import { getCellForFieldType } from 'components/Layout/BigTableLayout/InlineCells';
import { isStandardObject } from 'components/Modals/utilities';
import {
  Table,
  SizedCell as KizenSizedCell,
  TextEllipsis,
  SortableHeadCell as KizenSortableHeadCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import { getRelatedParam } from 'components/Charts/ScheduledActivities/helpers';
import Tooltip from 'components/Kizen/Tooltip';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import {
  ColumnsIconCell,
  getSizing,
  ResizerLayout,
} from 'pages/Common/components/BigTableCells';

import BubbleProfileImage from 'components/Charts/ScheduledActivities/BubbleProfileImage';
import IconButtonAbsoluteMenu from 'components/Tables/Big/IconButtonAbsoluteMenu';
import { GradientRightRow } from 'components/Kizen/LazyLoad/helpers';

import CustomObjectsService from 'services/CustomObjectsService';
import PipelineService from 'services/PipelineService';
import { getObjectUrl } from 'services/FieldService';
import { FIELD_TYPES } from 'utility/constants';

const DateTimeInputWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NO_VALUE = '—';
const PADDING = 30; // Right cell padding in pixels
export const SortableHeadCell = styled(
  ({
    column,
    minWidth,
    children,
    className,
    onResizeColumnWidth,
    onResizeStop,
    getColumnWidth,
    narrowistWidth = 75,
    resizable = true,
    fieldId,
    objectId,
    objectsById,
    ...others
  }) => {
    const width = getColumnWidth(column.id);
    const [resizing, toggleResizing] = useToggle(false);
    const handleResize = useCallback(
      (ev, { x }) => {
        const nextWidth = Math.max(narrowistWidth, x - PADDING / 2);
        onResizeColumnWidth({
          id: column.id,
          label: column.label,
          width: `${nextWidth}px`,
          visible: true,
        });
      },
      [column.id, column.label, onResizeColumnWidth, narrowistWidth]
    );

    const onResizeColumnStop = useCallback(() => {
      onResizeStop();
    }, [onResizeStop]);

    let field = null;
    let hasSorting = true;
    if (fieldId && objectId) {
      field = objectsById?.[objectId]?.fieldsById?.[fieldId];
      hasSorting =
        field?.fieldType !== FIELD_TYPES.Relationship.type ||
        field.relation.cardinality === 'many_to_one' ||
        field.relation.cardinality === 'one_to_one';
    }

    return (
      <KizenSortableHeadCell
        by={fieldId ? fieldId : undefined}
        numeric={field ? getColumnNumeric(field) : undefined}
        hasSorting={hasSorting}
        className={`${className} ${resizing && 'Resizing'}`}
        column={column}
        padding={`${PADDING}px`}
        {...getSizing(width, minWidth)}
        {...others}
      >
        {children}
        {resizable && (
          <ResizerLayout className="ResizerLayout" padding={PADDING}>
            <DraggableCore
              onDrag={handleResize}
              onStart={toggleResizing}
              onStop={() => {
                toggleResizing();
                onResizeColumnStop();
              }}
            >
              <Icon className="Resizer" icon="column-resize" size="intrinsic" />
            </DraggableCore>
          </ResizerLayout>
        )}
      </KizenSortableHeadCell>
    );
  }
)`
  &.Resizing {
    cursor: ew-resize;
    svg line {
      stroke: ${grayScale.dark};
    }
  }
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
    margin-top: 1px;
    button {
      margin-left: 10px;
    }
  }
  button {
    margin-left: ${gutters.spacing(1)}px;
  }
`;

export default styled(Table)`
  border-collapse: separate; // Ensures bottom border on sticky th doesn't scroll away
  tr:not(.collapsible) {
    height: 40px;
  }
  && thead {
    tr {
      height: 31px;
      th {
        vertical-align: top;
        border-bottom: 1px solid ${grayScale.mediumLight};
      }
    }
  }
  tbody {
    tr:hover,
    tr.selected,
    tr.collapsible {
      background-color: ${tableHover};
    }
  }
`;
export const SizedCell = styled(
  ({ column, minWidth, getColumnWidth, ...others }) => {
    const width = getColumnWidth && getColumnWidth(column.id);

    return (
      <KizenSizedCell
        column={column}
        padding={`${PADDING}px`}
        {...getSizing(width, minWidth)}
        {...others}
      />
    );
  }
)`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
    margin-top: 1px;
  }
`;

export const SCHEDULED_ACTIVITIES_ACTIONS = {
  Complete: {
    value: 'complete',
    label: (t) => t('Complete'),
  },
  ViewNotes: {
    value: 'viewNotes',
    label: (t) => t('View Notes'),
  },
  HideNotes: {
    value: 'hideNotes',
    label: (t) => t('Hide Notes'),
  },
  Edit: {
    value: 'edit',
    label: (t) => t('Edit'),
  },
  Delete: { value: 'delete', label: (t) => t('Delete') },
};

export const getActions = (access, showNotes, t) => {
  const defaultActions = [
    {
      ...SCHEDULED_ACTIVITIES_ACTIONS.Complete,
      label: SCHEDULED_ACTIVITIES_ACTIONS.Complete.label(t),
    },
    showNotes
      ? {
          ...SCHEDULED_ACTIVITIES_ACTIONS.HideNotes,
          label: SCHEDULED_ACTIVITIES_ACTIONS.HideNotes.label(t),
        }
      : {
          ...SCHEDULED_ACTIVITIES_ACTIONS.ViewNotes,
          label: SCHEDULED_ACTIVITIES_ACTIONS.ViewNotes.label(t),
        },
  ];

  return Object.keys(access).reduce((acc, el) => {
    if (el === 'remove' && access[el])
      return acc.concat({
        ...SCHEDULED_ACTIVITIES_ACTIONS.Delete,
        label: SCHEDULED_ACTIVITIES_ACTIONS.Delete.label(t),
      });

    if (el === 'edit' && access[el])
      return acc.concat({
        ...SCHEDULED_ACTIVITIES_ACTIONS.Edit,
        label: SCHEDULED_ACTIVITIES_ACTIONS.Edit.label(t),
      });

    return acc;
  }, defaultActions);
};
const formatTeamSelectorLabel = (value) =>
  `${value.firstName} ${value.lastName} (${value.email})`;

const formatRelationshipLabel = (val) => ({ ...val, label: val.name });

export const getFieldDataValue = (fieldData, field) => {
  const { value } = fieldData;

  if (value?.name && !value?.label) {
    return { ...value, label: value.name };
  }

  if (!value) {
    return null;
  }

  switch (field?.fieldType) {
    case FIELD_TYPES.TeamSelector.type:
      return value?.label
        ? value
        : { ...value, label: formatTeamSelectorLabel(value) };
    case FIELD_TYPES.Money.type:
      return value?.amount;
    case FIELD_TYPES.Checkboxes.type:
      return value.map((val) => val.id);
    case FIELD_TYPES.Relationship.type:
      if (value?.length > 1) {
        return value.map(formatRelationshipLabel);
      } else if (
        (field.relation.cardinality === 'one_to_one' ||
          field.relation.cardinality === 'many_to_one') &&
        value?.length === 1
      ) {
        return formatRelationshipLabel(value[0]);
      }
      break;
    default:
      break;
  }

  return value;
};

// The total width of the table is 1024px due to
// the size of this dashlet. These columns sizes are designed
// to match precisely, never permitting scrolling.
// TODO handle shrunken dashlet, in between desktop and mobile:
// currently it will scroll rather than scale down.
const columnSizes = {
  overdue: {
    default: {
      width: '1%',
      minWidth: '40px',
    },
    wide: {
      width: '1%',
      minWidth: '40px',
    },
  },
  activityType: {
    default: {
      minWidth: '185px',
      padding: '30px',
    },
    wide: {
      minWidth: '220px',
      padding: '30px',
    },
  },
  client: {
    default: {
      minWidth: '115px',
      padding: '30px',
    },
    wide: {
      minWidth: '140px',
      padding: '30px',
    },
  },
  related: {
    default: {
      minWidth: '115px',
      padding: '30px',
    },
    wide: {
      minWidth: '140px',
      padding: '30px',
    },
  },
  employee: {
    default: {
      minWidth: '80px',
      padding: '30px',
    },
  },
  scheduled: {
    default: {
      minWidth: '153px',
      padding: '30px',
    },
    wide: {
      minWidth: '153px',
      padding: '30px',
    },
  },
  controls: {
    default: {
      width: '50px',
      padding: '11px', // 4px (of total 15px) moved into OptionalSpacer to account for whether scrollbar is appearing
    },
    wide: {
      width: '50px',
      padding: '11px',
    },
  },
  spacer: {
    // Collapses when scroll bar is present
    default: {
      width: '4px',
    },
    wide: {
      width: '4px',
    },
  },
};

export const StyledIconButton = styled(IconButton)`
  margin-right: ${gutters.spacing(2)}px;

  && svg {
    width: ${gutters.spacing(3)}px;
    height: ${gutters.spacing(3)}px;
  }

  ${({ zIndex }) =>
    typeof zIndex === 'number'
      ? css`
          z-index: ${zIndex};
        `
      : ''}
`;

const Hoverable = styled.span`
  cursor: pointer;
`;

const ZIndexActionMenuWrapper = styled.div`
  z-index: 1;
`;

const columnSize = (column, wide) => {
  return columnSizes[column] && columnSizes[column][wide ? 'wide' : 'default'];
};

const ControlsCell = styled((props) => {
  const { t } = useTranslation();
  const { onSelectAction, showNotesId, data: activity } = props;
  const { access = {} } = activity;
  const options = getActions(access, showNotesId === activity.id, t);

  const actionMenu = (
    <IconButtonAbsoluteMenu
      sizing="dense"
      title={t('Edit Activity')}
      position="right"
      color={colorsButton.iconGray}
      options={options}
      onChange={(option) => onSelectAction(option, activity)}
    >
      <Icon icon="three-dot" />
    </IconButtonAbsoluteMenu>
  );

  return (
    <SizedCell {...props}>
      <StyledIconButton
        sizing="dense"
        color={colorsButton.iconGray}
        title={t('Complete Activity')}
        onClick={() => onSelectAction(options[0], activity)}
        zIndex={props.virtualized ? 1 : undefined}
      >
        <Icon icon="check" />
      </StyledIconButton>
      {props.virtualized ? (
        <ZIndexActionMenuWrapper>{actionMenu}</ZIndexActionMenuWrapper>
      ) : (
        actionMenu
      )}
      {/* When virtualizing rows, we need to handle the fade on a per-row basis */}
      {props.virtualized ? <GradientRightRow fadeIn /> : null}
    </SizedCell>
  );
})`
  position: sticky;
  right: 0;
  z-index: ${layers.content(0, 1)};
  > div {
    margin-left: ${gutters.spacing(0, -1)}px;
    display: flex;
    justify-content: flex-end;
  }
`;

const getDefaultColumns = ({
  showMyActivities,
  onUpdateActivity,
  onResizeColumnWidth,
  onResizeStop,
  getColumnWidth,
  contactSortBy,
  t,
  canEdit = true,
  columnSettings,
}) => {
  const customLabels = columnSettings?.columns?.reduce((acc, column) => {
    if (
      column.id === 'activityType' ||
      column.id === 'client' ||
      column.id === 'employee' ||
      column.id === 'dueDateMyTime'
    ) {
      acc[column.id] = column.label;
      return acc;
    }
    return acc;
  }, {});

  const getLabel = (id, defaultLabel) => {
    return customLabels?.[id] || defaultLabel;
  };
  return {
    activityType: {
      headCell: (
        <SortableHeadCell
          {...columnSize('activityType', showMyActivities)}
          label={getLabel('activityType', t('Activity Type'))}
          onResizeColumnWidth={onResizeColumnWidth}
          onResizeStop={onResizeStop}
          getColumnWidth={getColumnWidth}
          resizable={canEdit}
        />
      ),
      cell: (
        <SizedCell
          getColumnWidth={getColumnWidth}
          {...columnSize('activityType', showMyActivities)}
        />
      ),
      format: (_, { activityObject }) => {
        return (
          <TextEllipsisWithTooltip data-qa={activityObject?.name || NO_VALUE}>
            {activityObject?.name || NO_VALUE}
          </TextEllipsisWithTooltip>
        );
      },
    },
    client: {
      headCell: (
        <SortableHeadCell
          {...columnSize('client', showMyActivities)}
          label={getLabel('client', t('Client'))}
          by={contactSortBy}
          onResizeColumnWidth={onResizeColumnWidth}
          onResizeStop={onResizeStop}
          getColumnWidth={getColumnWidth}
          resizable={canEdit}
        />
      ),
      cell: (
        <SizedCell
          getColumnWidth={getColumnWidth}
          {...columnSize('client', showMyActivities)}
        />
      ),
      format: (value) => {
        if (!value) {
          return <TextEllipsis>{NO_VALUE}</TextEllipsis>;
        }
        return (
          <TextEllipsisWithTooltip
            type="link"
            to={`/client/${value.id}/details`}
            target="_blank"
          >
            {value.fullName || NO_VALUE}
          </TextEllipsisWithTooltip>
        );
      },
    },
    employee: {
      headCell: (
        <SortableHeadCell
          {...columnSize('employee')}
          onResizeColumnWidth={onResizeColumnWidth}
          onResizeStop={onResizeStop}
          getColumnWidth={getColumnWidth}
          label={getLabel('employee', t('Assignee'))}
          resizable={canEdit}
        />
      ),
      cell: (
        <SizedCell
          getColumnWidth={getColumnWidth}
          align="center"
          {...columnSize('employee')}
        />
      ),
      format: (value, activity) => {
        const fullName = activity.role
          ? activity.role?.name
          : value?.displayName;
        const imageURL = value?.picture?.thumbnailUrl || null;
        if (!fullName) {
          return NO_VALUE;
        }

        return (
          <>
            <Hoverable>
              <BubbleProfileImage
                fullName={fullName}
                imageURL={imageURL}
                showToolTip
                popperConfig={{
                  modifiers: {
                    offset: { offset: '0, -5px' }, // Touch top of text
                    preventOverflow: { enabled: false },
                  },
                }}
                value={activity.role || value || {}}
                activity={activity}
                onUpdateActivity={onUpdateActivity}
              />
            </Hoverable>
          </>
        );
      },
    },
    dueDateMyTime: {
      headCell: (
        <SortableHeadCell
          by="due_datetime_my_time"
          numeric
          {...columnSize('scheduled', showMyActivities)}
          label={getLabel('dueDateMyTime', t('Due Date (My Time)'))}
          onResizeColumnWidth={onResizeColumnWidth}
          onResizeStop={onResizeStop}
          getColumnWidth={getColumnWidth}
          narrowistWidth="165"
          resizable={canEdit}
        />
      ),
      cell: (
        <SizedCell
          getColumnWidth={getColumnWidth}
          {...columnSize('scheduled', showMyActivities)}
        />
      ),
      format: (_, activity) => {
        const { dueDatetime, access } = activity;
        const dateWithTimezone = getDateWithTimezone(
          dueDatetime,
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        if (!dueDatetime) {
          return <TextEllipsis>{NO_VALUE}</TextEllipsis>;
        }
        return (
          <DateTimeInputWrapper>
            <DateTimeInputInline
              inModal
              readOnly={!(access && access.edit)}
              value={dateWithTimezone}
              onSubmit={(val) => {
                onUpdateActivity(activity, { dueDatetime: val.toISOString() });
              }}
              dateMenuProps={{
                menuRightButton: <ApplySelectOverlayButton />,
              }}
              timeMenuProps={{
                menuRightButton: <ApplySelectOverlayButton />,
              }}
              showDateTooltip
            />
          </DateTimeInputWrapper>
        );
      },
    },
  };
};
const getDueDateBusinessTime = ({
  showMyActivities,
  onUpdateActivity,
  onResizeColumnWidth,
  onResizeStop,
  getColumnWidth,
  timezone,
  t,
  canEdit = true,
}) => ({
  headCell: (
    <SortableHeadCell
      by="due_datetime"
      numeric
      {...columnSize('scheduled', showMyActivities)}
      label={t('Due Date (Business Time)')}
      onResizeColumnWidth={onResizeColumnWidth}
      onResizeStop={onResizeStop}
      getColumnWidth={getColumnWidth}
      narrowistWidth="165"
      withTooltip
      resizable={canEdit}
    />
  ),
  cell: (
    <SizedCell
      getColumnWidth={getColumnWidth}
      {...columnSize('scheduled', showMyActivities)}
    />
  ),
  format: (_, activity) => {
    const { dueDatetime, access } = activity;
    const dateWithTimezone = getDateWithTimezone(dueDatetime, timezone);
    if (!dueDatetime) {
      return <TextEllipsis>{NO_VALUE}</TextEllipsis>;
    }
    return (
      <DateTimeInputWrapper>
        <DateTimeInputInline
          inModal
          readOnly={!(access && access.edit)}
          value={dateWithTimezone}
          onSubmit={(val) => {
            // because the date is local we need to subtract the tzo to get back to the correct time
            const diff = businessLocalTimezoneDstOffset(timezone);
            const adjustedVAl = addMinutes(val, 0 - diff);
            const iso = adjustedVAl.toISOString();
            onUpdateActivity(activity, { dueDatetime: iso });
          }}
          dateMenuProps={{
            menuRightButton: <ApplySelectOverlayButton />,
          }}
          timeMenuProps={{
            menuRightButton: <ApplySelectOverlayButton />,
          }}
          showDateTooltip
        />
      </DateTimeInputWrapper>
    );
  },
});

export const createColumns = (
  {
    showMyActivities,
    showNotesId,
    onSelectAction,
    onUpdateActivity,
    now,
    columnSettings,
    objectsById,
    onResizeColumnWidth,
    onResizeStop,
    getColumnWidth,
    contactSortBy,
    timezone,
    canEdit = true,
    searchTerm,
    setSearchTerm,
    noScrollBars,
    handlePersistSearch,
    persistentSearchValue,
    virtualized = false,
  },
  t
) => {
  const defaultColumns = getDefaultColumns({
    showMyActivities,
    onUpdateActivity,
    onResizeColumnWidth,
    onResizeStop,
    getColumnWidth,
    contactSortBy,
    timezone,
    t,
    canEdit,
    handlePersistSearch,
    persistentSearchValue,
    columnSettings,
  });
  return [
    {
      id: 'overdue',
      headCell: (
        <SizedCell {...columnSize('overdue', showMyActivities)} padding="0px">
          <span />
        </SizedCell>
      ),
      cell: (
        <SizedCell
          align="center"
          {...columnSize('overdue', showMyActivities)}
          padding="0px"
        />
      ),
      format: (_, { dueDatetime }) => {
        const scheduledDate = new Date(
          getScheduledTimezoneAdjusted(dueDatetime, timezone)
        );

        if (scheduledDate >= now) {
          return null;
        }
        return (
          <OverlayTrigger
            overlay={<Tooltip label={t('Past Due')} />}
            popperConfig={{
              modifiers: {
                offset: { offset: '0, -50%' }, // Center on top of target
                preventOverflow: { enabled: false },
              },
            }}
          >
            <Hoverable
              as={Icon}
              icon="circle"
              size="1x"
              color={colorsButton.red.default}
              aria-label="Overdue"
            />
          </OverlayTrigger>
        );
      },
    },
    ...((columnSettings && columnSettings.columns) || [])
      .filter(({ visible }) => visible)
      .map(({ id, label, fieldId, objectId }) => {
        if (defaultColumns[id]) {
          return {
            id,
            label,
            ...defaultColumns[id],
          };
          // this field is not in the default columns,
          // so we need to handle it differently
        } else if (id === 'dueDateBusinessTime') {
          return {
            id,
            label,
            ...getDueDateBusinessTime({
              showMyActivities,
              onUpdateActivity,
              onResizeColumnWidth,
              onResizeStop,
              getColumnWidth,
              timezone,
              t,
            }),
          };
        }
        return {
          id,
          label,
          headCell: (
            <SortableHeadCell
              {...columnSize('related', showMyActivities)}
              label={label}
              by={getRelatedParam(id, fieldId)}
              onResizeColumnWidth={onResizeColumnWidth}
              onResizeStop={onResizeStop}
              getColumnWidth={getColumnWidth}
              resizable={canEdit}
              fieldId={fieldId}
              objectId={objectId}
              objectsById={objectsById}
            />
          ),
          cell: (
            <SizedCell
              getColumnWidth={getColumnWidth}
              {...columnSize('related', showMyActivities)}
            />
          ),
          format: (_, activity) => {
            const val = activity.associatedEntities.find(
              (ae) => ae.customObjectId === id || ae.entityId === fieldId
            );
            const fieldData = activity.associatedFields.find(
              (af) => af.fieldId === fieldId
            );

            if (fieldData) {
              const object = objectsById?.[objectId];
              const { fieldsById = {} } = object || {};
              const field = fieldsById[fieldId];
              const Cell = getCellForFieldType(field?.fieldType);
              const isClient = object?.fetchUrl === 'client';

              const fetchUrl = isClient
                ? getObjectUrl('contacts')
                : `custom-objects/${objectId}`;

              const serviceToUse = isStandardObject(object ?? {})
                ? CustomObjectsService
                : PipelineService;

              const fieldProps =
                field?.fieldType === FIELD_TYPES.Relationship.type &&
                field.relation.fetchUrl === 'client'
                  ? { categorizedFields: [] }
                  : {};

              const value = getFieldDataValue(fieldData, field);

              if (!object || !value) {
                return <TextEllipsis>{NO_VALUE}</TextEllipsis>;
              }

              return (
                <Cell
                  value={value}
                  onSubmit={() => {}}
                  object={object}
                  model={object}
                  field={{ ...field, isReadyOnly: true }}
                  fetchUrl={fetchUrl}
                  serviceToUse={serviceToUse}
                  disabled
                  forceReadOnly
                  isCustomObjects={!isClient}
                  fieldProps={fieldProps}
                />
              );
            }

            if (!val) {
              return <TextEllipsis>{NO_VALUE}</TextEllipsis>;
            }

            return (
              <TextEllipsisWithTooltip
                type="link"
                to={`/custom-objects/${val.customObjectId}/${val.entityId}/details`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {val.displayName || NO_VALUE}
              </TextEllipsisWithTooltip>
            );
          },
        };
      }),

    {
      id: 'controls',
      headCell: (
        <ColumnsIconCell
          {...columnSize('controls', showMyActivities)}
          zIndex="2"
          minWidth="56"
          right={-2}
          canEdit={canEdit}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          noScrollBars={noScrollBars}
          data-qa="icon-cell"
        />
      ),
      cell: (
        <ControlsCell
          {...columnSize('controls', showMyActivities)}
          showNotesId={showNotesId}
          onSelectAction={onSelectAction}
          virtualized={virtualized}
        />
      ),
    },
  ].filter(Boolean);
};

export function useEndsOfYScroll(ref, deps = []) {
  const [top, setTop] = useState(false);
  const [bottom, setBottom] = useState(false);

  useEffect(() => {
    const { current: el } = ref;

    const handler = () => {
      if (el) {
        setTop(el.scrollTop === 0);
        setBottom(el.scrollHeight - el.scrollTop === el.clientHeight);
      }
    };

    handler();

    if (el) {
      el.addEventListener('scroll', handler, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (el) {
        el.removeEventListener('scroll', handler);
      }
    };
  }, [
    ref, // The linter doesn't know what to do with these deps, so we need to disable the lint for that line
    ...deps, // eslint-disable-line react-hooks/exhaustive-deps
  ]);

  return [top, bottom];
}

const GradientBottomWrapper = styled.div`
  position: sticky;
  bottom: 0;
  height: 0;
  z-index: ${layers.content(0)};
  overflow: visible;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
  ${({ fadeIn }) => css`
    opacity: ${fadeIn ? 1 : 0};
  `}
`;

const GradientBottomBody = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  // Need to use "rgba(255, 255, 255, 0)" rather than "transparent" for safari's benefit
  background: linear-gradient(
    to top,
    ${grayScale.white},
    rgba(255, 255, 255, 0)
  );
`;

export function GradientBottom(props) {
  return (
    <GradientBottomWrapper {...props}>
      <GradientBottomBody />
    </GradientBottomWrapper>
  );
}
