import { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BigTableLayout, {
  useBigTableLayout,
} from 'components/Layout/BigTableLayout';
import BigTable from 'components/Tables/Big';
import { TRow } from 'components/Kizen/Table';
import TablePagination from 'components/Tables/Big/TablePagination';
import PageToolbar, {
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import { gutters } from 'app/spacing';
import { getAccountColumns } from './columns';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Button from 'components/Button';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import AdsService from '__services/AdvsService';
import { PreReleaseFeature } from 'hooks/usePreReleaseFeatures';
import { useNavBarState } from 'app/navBarController';
import { selectAdManagerIsFiltering } from 'store/adManagerPage/adManager.redux';
import useThirdPartyAuth, {
  PROVIDERS,
} from 'components/Modals/ThirdPartyAuth/useThirdPartyAuth';
import IntegratedEmailModal from 'components/Modals/ThirdPartyAuth/modal';

const StyledBigTableLayout = styled(BigTableLayout)`
  margin-top: ${gutters.spacing(-4)}px;
`;

const DesktopLayout = ({
  connections,
  connectionsCount,
  pageNumber,
  pageSize,
  handleChangePageNumber,
  handleChangePageSize,
  sort,
  search,
  handleChangeSearch,
  handleChangeSort,
  handleSelectAction,
  businessId,
  ...others
}) => {
  const { t } = useTranslation();
  const isFetching = useSelector((s) => s.adManagerPage.isFetching);
  const isFiltering = useSelector(selectAdManagerIsFiltering);
  const { height } = useNavBarState();
  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout(height);
  const { scrolledToTable } = bigTablePageProps;

  const templatesHeadData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => handleChangeSort({ column, direction }),
      },
    }),
    [sort, handleChangeSort]
  );

  const accountsColumns = useMemo(
    () =>
      getAccountColumns({
        t,
        handleSelectAction,
      }),
    [handleSelectAction, t]
  );

  const { modalProps, openModal } = useThirdPartyAuth({
    ads: true,
    provider: PROVIDERS.GOOGLE,
    header: t('Confirm to Continue'),
    providerReadOnly: true,
    callback: (provider) => {
      if (provider === PROVIDERS.GOOGLE) {
        return AdsService.googleLogin();
      }
    },
  });

  return (
    <StyledBigTableLayout
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={t('Find Accounts')}
              value={search}
              onChange={handleChangeSearch}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={connectionsCount}
            single={t('Connected Account')}
            plural={t('Connected Accounts')}
          />
          <PageToolbarSection>
            <Button onClick={openModal}>{t('Add Google Ads')}</Button>
            <IntegratedEmailModal {...modalProps} />
            <PreReleaseFeature>
              <Button onClick={() => {}}>{t('Add Facebook Ads')}</Button>
            </PreReleaseFeature>
          </PageToolbarSection>
        </PageToolbar>
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={connectionsCount}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      }
      {...bigTablePageProps}
      {...others}
    >
      <BigTable
        scrolledToTable={scrolledToTable}
        columns={connections}
        head={<TRow head columns={accountsColumns} data={templatesHeadData} />}
        staleData={isFetching || isFiltering}
      >
        {connections.map((item) => (
          <TRow key={item.id} columns={accountsColumns} data={item} />
        ))}
      </BigTable>
    </StyledBigTableLayout>
  );
};
DesktopLayout.propTypes = {
  connections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChangeSearch: PropTypes.func.isRequired,
  connectionsCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleChangePageNumber: PropTypes.func.isRequired,
  handleChangePageSize: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleSelectAction: PropTypes.func.isRequired,
};
export default DesktopLayout;
