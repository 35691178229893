import React from 'react';
import { StyledLink } from './styles';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';

const CustomOption = ({
  children,
  selectOption,
  isDisabled,
  isSelected,
  ...props
}) => {
  const [tooltipProps, tooltip] = useTruncationTooltip();

  const { selectProps } = props;
  const { buildHref, setIsOpen, closeOnSelect, preventHrefDefaultBehavior } =
    selectProps;

  return (
    <>
      {tooltip}
      <StyledLink
        {...tooltipProps}
        disabled={isDisabled}
        selected={isSelected}
        href={buildHref?.(props.data.value)}
        onClick={(e) => {
          // If control or Meta key (command on Mac) are pressed, we still want
          // to open the result in a new tab
          const modifier = e.metaKey || e.getModifierState('Control');
          const skip = preventHrefDefaultBehavior && !modifier;

          // Don't follow the href if the item is disabled, already selected, or
          // hrefs are disabled and there's no relevant modifier key pressed
          if (skip || isDisabled || isSelected) {
            e.preventDefault();
          }

          // If there was no modifier key pressed, and the current item is selectable,
          // select it
          if (!modifier && !isDisabled && !isSelected) {
            selectOption(props.data);
          }

          if (closeOnSelect) {
            setIsOpen(false);
          }

          return false;
        }}
      >
        {children}
      </StyledLink>
    </>
  );
};

export default CustomOption;
