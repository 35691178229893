import { useState, useEffect } from 'react';

export const createBehaviorSubject = (defaultVal = null) => {
  let listeners = [];
  let lastValue = defaultVal;

  return {
    subscribe(fn) {
      listeners.push(fn);
      fn(lastValue);
    },
    unsubscribe(fn) {
      listeners = listeners.filter((_fn) => fn !== _fn);
    },
    next(val) {
      lastValue = val;
      listeners.forEach((fn) => {
        fn(val);
      });
    },
    getValue() {
      return lastValue;
    },
  };
};

export const useObservable = (observable) => {
  const [value, setValue] = useState(null);
  useEffect(() => {
    const fn = (val) => {
      setValue(val);
    };
    observable.subscribe(fn);
    return () => {
      observable.unsubscribe(fn);
    };
  }, [observable, setValue]);
  return value;
};
