import styled from '@emotion/styled';
import { grayScale, colorsPrimary } from 'app/colors';
import { fontWeights, fontSizes } from 'app/typography';
import { gutters } from 'app/spacing';
import Table from 'react-bootstrap/Table';
import { STATUS_TYPES } from './DomainSteps';

export const StyledTable = styled(Table)`
  font-size: ${fontSizes.small};
  margin-bottom: 0;
  margin-top: ${gutters.spacing(2, { baseline: true })}px;
`;

export const TableBody = styled.tbody`
  border-top: 1px solid ${grayScale.mediumLight};
  border-bottom: 1px solid ${grayScale.mediumLight};
`;

export const TableHeader = styled.thead`
  font-size: ${fontSizes.text};
  font-weight: ${fontWeights.bold};
  color: ${grayScale.dark};
  text-transform: none;
`;

export const TableHeaderCell = styled.th`
  font-size: ${fontSizes.text};
  font-weight: ${fontWeights.bold};
  color: ${grayScale.dark};
  && {
    border: none;
    padding: 0;
    padding-bottom: ${gutters.spacing(4, { baseline: true })}px;
  }
`;

export const TableRow = styled.tr`
  && {
    border: none;
  }
  &:last-of-type > td {
    padding: ${gutters.spacing(3, { baseline: true })}px 0;
  }
`;

export const TableCell = styled.td`
  * {
    font-size: 11px;
  }
  && {
    border: none;
    padding: ${gutters.spacing(3, { baseline: true })}px 0 ${gutters.standard} 0;
  }
  & span.name-cell {
    display: inline-block;
    word-wrap: break-word;
    width: 110px;
  }
  & span.value-cell {
    display: inline-block;
    word-wrap: break-word;
    width: 400px;
  }
`;

export const StatusCell = styled.div`
  font-size: ${fontSizes.small};
  color: ${(props) =>
    props.type === STATUS_TYPES.SUCCESS
      ? colorsPrimary.green.dark
      : colorsPrimary.orange.dark};
`;
