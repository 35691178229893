import styled from '@emotion/styled';

export const EditableWrapper = styled.div`
  margin-right: auto;

  && {
    margin-left: 6px;
  }

  && > button {
    width: 12px;
    svg {
      width: 12px;
      height: auto;
    }
  }
`;
