import React from 'react';
import * as PropTypes from 'prop-types';
import MainSelector from 'components/Kizen/MainSelector';
import { namedToOption } from 'services/helpers';

const SUBNAV_MAX_WIDTH = 250;

const SubNavLabel = ({
  options,
  value,
  onChange,
  maxWidth = SUBNAV_MAX_WIDTH,
  ...others
}) => {
  return (
    <MainSelector
      maxWidth={maxWidth}
      value={{
        label: value ? value.name : '',
        value: value ? value.id : '',
      }}
      options={options.map(namedToOption)}
      onChange={(option) => {
        onChange(option.value);
      }}
      {...others}
    />
  );
};

SubNavLabel.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
};

SubNavLabel.defaultProps = {
  maxWidth: SUBNAV_MAX_WIDTH,
};

export default SubNavLabel;
