import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';

const MAX_WIDTH = 85;
const FONT_SIZE = 14;

export const DateInput = styled.input`
  appearance: none;
  outline: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: ${FONT_SIZE}px;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;

  ::placeholder {
    color: ${grayScale.mediumDark};
  }
`;

export const DateInputContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  max-width: ${MAX_WIDTH}px;
  height: 100%;

  ${({ enableDynamicWidth }) =>
    !enableDynamicWidth &&
    css`
      width: ${MAX_WIDTH}px;
    `}
`;

export const DateInputSizingSpan = styled.span`
  user-select: none;
  visibility: hidden;
  white-space: nowrap;
  display: inline-block;
  min-width: 2px;
  font-size: ${FONT_SIZE}px;
`;

export const DateRangeInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 15px;
  align-items: center;
  cursor: pointer;
  height: 34px;
  border: 1px solid ${grayScale.medium};
  border-radius: 4px;
  background-color: white;
  padding: 0 12px;

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: ${colorsButton.red.hover};
    `}

  ${({ width = 250 }) => css`
    width: ${width}px;
  `}
`;

export const DateInputsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  height: 100%;

  > code {
    color: ${grayScale.mediumDark};
  }
`;
