import parse from 'date-fns/parse';
import { pickBy, isEqual, isNumber } from 'lodash';
import { FIELD_TYPES as ALL_FIELD_TYPES } from 'utility/constants';
import { PROMISE_STATUS } from './constants';

const areDatesEqual = (d1, d2) => {
  return Date.parse(d1) === Date.parse(d2);
};

export const snakeToCamelCase = (str) => {
  return str && str.replace(/_./g, ([, ch]) => ch.toUpperCase());
};

export const snakeToCamelCaseKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(snakeToCamelCaseKeys);
  }
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  return Object.entries(obj).reduce(
    (collect, [key, value]) => ({
      ...collect,
      [snakeToCamelCase(key)]: snakeToCamelCaseKeys(value),
    }),
    {}
  );
};

export const camelToSnakeCase = (str) => {
  // specific case for handle specific strings like pastNDays or lastNDays
  // added here to prevent breaking the Team Interact filter
  // do not remove :)
  if (str && str === 'lastNDays') return 'last_n_days';
  if (str && str === 'pastNDays') return 'past_n_days';
  return (
    str && str.replace(/[a-z][A-Z]/g, ([a, b]) => `${a}_${b.toLowerCase()}`)
  );
};

export const camelToSnakeCaseKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(camelToSnakeCaseKeys);
  }
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  return Object.entries(obj).reduce(
    (collect, [key, value]) => ({
      ...collect,
      [camelToSnakeCase(key)]: camelToSnakeCaseKeys(value),
    }),
    {}
  );
};

// Deals, companies, tags, titles, and some others all share this shape
export const deletedWrapper = (field, translate) =>
  field?.deleted ? null : translate(field);

export const namedToOption = ({ id, name }) => ({ value: id, label: name });
export const namedToOptionDeleted = (field) =>
  deletedWrapper(field, namedToOption);
export const namedToOptionError = ({ name }) => name;

export const namedToOptionWithCode = ({ id, name, code = '', meta }) => ({
  value: id,
  label: name,
  code,
  color: meta?.color,
});
export const displayNameToOption = ({ id, displayName }) => ({
  value: id,
  label: displayName,
});
export const displayNameToOptionDeleted = (field) =>
  deletedWrapper(field, displayNameToOption);
export const displayNameToOptionError = ({ displayName }) => displayName;

export const objectNameToOption = ({ id, objectName }) => ({
  value: id,
  label: objectName,
});

export const optionToNamed = ({ value, label }) => ({ id: value, name: label });

export const getOptionName = ({ name = '', label = '' }) => name || label;
export const getOptionNameWithEmail = ({ email, fullName }) =>
  `${fullName} (${email})`;
export const getOptionValue = ({ id = '', value = '' }) => id || value;
export const descriptionToOption = (object) => ({
  value: object.id,
  label: object.description,
  object,
});

export const filterOption = (searchTerm, stringToSearch) => {
  // Manual filtering, e.g. when unsupported by the api.
  return stringToSearch.toLowerCase().includes(searchTerm.toLowerCase());
};

export const getIdFullName = ({ id, fullName: name }) => ({ id, name });
export const getIdFullNameWithEmail = ({ id, fullName, email }) => ({
  id,
  name: getOptionNameWithEmail({ fullName, email }),
});
export const getIdFullNameError = ({ fullName }) => fullName;

export const getIdFullNameWithEmailDeleted = (field) =>
  deletedWrapper(field, getIdFullNameWithEmail);

export const getValueFullNameWithEmail = ({ id, fullName, email }) => ({
  value: id,
  label: getOptionNameWithEmail({ fullName, email }),
});

export const fullNameToOption = ({ id, fullName: name }) => ({
  value: id,
  label: name,
});
export const fullNameToOptionDeleted = (field) =>
  deletedWrapper(field, fullNameToOption);
export const fullNameToOptionError = ({ fullName }) => fullName;

export const fieldToOption = (field) => ({
  label: field.displayName,
  value: field.id,
  fieldData: field,
});
export const fieldToOptionDeleted = (field) =>
  deletedWrapper(field, fieldToOption);

export const getIdName = ({ id, name }) => ({ id, name });
export const getIdNameDeleted = (field) => getIdName(field, fieldToOption);
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const parseDateFromApi = (str) =>
  parse(str, API_DATE_FORMAT, new Date());

// We use this as a page size when all objects need to be returned.
// Eventually we'll want to address areas where this needs to be handled.
export const FORCE_ALL_RECORDS_SIZE = 10000;

export const fieldForApp = (field) => {
  field = snakeToCamelCaseKeys(field);
  return field;
};

export const categoryForApp = (category) => {
  category = snakeToCamelCaseKeys(category);
  return category;
};

export const isPromisePending = ({ status }) =>
  status === PROMISE_STATUS.PENDING;
export const isPromiseRejected = ({ status }) =>
  status === PROMISE_STATUS.REJECTED;
export const isPromiseFulfilled = ({ status }) =>
  status === PROMISE_STATUS.FULFILLED;

const isDateLike = (value) =>
  [ALL_FIELD_TYPES.Date.type, ALL_FIELD_TYPES.DateTime.type].includes(value);
// return an array of all the keys that are dirty
export const getDirtyKeys = (
  touchedFields,
  initialFormData,
  formData,
  fieldsTypes
) => {
  // all fields that have changed
  return Object.keys(
    pickBy(formData, (val, key) => {
      // Need separate date equal function because some date strings have milliseconds, some don't
      const eq =
        isDateLike(fieldsTypes[key]) && !isNumber(val)
          ? areDatesEqual
          : isEqual;

      return key in touchedFields && !eq(val, initialFormData[key]);
    })
  );
};

export const getTeamLabel = (data) => {
  if (!data) {
    return;
  }

  if (data.label) {
    return data.label;
  }

  if (data.displayName) {
    return data.displayName;
  }

  return [data.firstName, data.lastName, data.email && `(${data.email})`]
    .filter(Boolean)
    .join(' ');
};

export const teammembersMapper = (tm) => ({
  label: getTeamLabel(tm),
  value: tm.id,
});

export const formatValueForFieldInput = (fieldValue) => {
  const { fieldType, value } = fieldValue;
  let result;
  switch (fieldType) {
    case ALL_FIELD_TYPES.Money.type:
      result = value?.amount ?? value;
      break;
    case ALL_FIELD_TYPES.Dropdown.type:
    case ALL_FIELD_TYPES.Status.type:
    case ALL_FIELD_TYPES.Choices.type:
    case ALL_FIELD_TYPES.Radio.type:
    case ALL_FIELD_TYPES.YesNoMaybe.type:
      result = value?.id || value;
      break;
    case ALL_FIELD_TYPES.Checkboxes.type:
      result = value?.map((item) => item.id) || value;
      break;
    case ALL_FIELD_TYPES.DynamicTags.type:
      result =
        value?.map((item) => {
          return {
            id: item.id,
            label: item.name,
          };
        }) || value;
      break;
    case ALL_FIELD_TYPES.TeamSelector.type:
    case ALL_FIELD_TYPES.Relationship.type:
      if (Array.isArray(value)) {
        result = value.map((item) => ({
          ...item,
          label: item.name || getTeamLabel(item),
        }));
      } else {
        result = value
          ? {
              ...value,
              label: value.name || getTeamLabel(value),
              // special hack for owner field
              email: value.email || getTeamLabel(value),
            }
          : value;
      }
      break;
    default:
      result = value;
  }
  fieldValue.value = result;

  return fieldValue;
};

export const relatedIdToOption = ({ id, name, displayName, ...rest }) => ({
  id,
  label: displayName || name || getTeamLabel(rest),
});

export const relatedfieldIdToOption = (field) => ({
  label: field.displayName ?? field.name,
  value: field.id,
  fieldData: {
    ...field,
    access: { edit: true },
    options: [],
  },
});

export const relatedfieldIdToOptionDeleted = (field) =>
  deletedWrapper(field, relatedfieldIdToOption);

export const specificTeamMemberToOption = ({ id, email, name }) => ({
  value: id,
  label: `${name} (${email})`,
});

export const specificTeamMemberToOptionDeleted = (field) =>
  deletedWrapper(field, specificTeamMemberToOption);

export const fieldToOptions = (field) => ({
  label: field.displayName,
  value: field.id,
  field,
});

export const fieldToOptionsDeleted = (field) =>
  deletedWrapper(field, fieldToOptions);

export const getObjectToModify = (field) => ({
  value: field.id,
  label: field.objectName,
  entityName: field.entityName,
  relation: { ...field, relatedObject: field.id },
});

export const getObjectToModifyDeleted = (field) =>
  deletedWrapper(field, getObjectToModify);

export const getObjectToModifyError = (field) => field.objectName;
