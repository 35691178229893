import React from 'react';
import * as PropTypes from 'prop-types';
import TablePagination from 'components/Tables/Big/TablePagination';
import ListCard from 'components/Layout/ListCard';
import BigListLayout, { BigListTitle } from 'components/Layout/BigListLayout';
import { BigListSearchInput } from 'components/Layout/BigListLayout/Toolbar';
import { FormListItem, FormListHeader, EmptyFormListItem } from './ListItem';
import { FORM_ACTIONS } from './columns';
import { useSelector } from 'react-redux';
import { selectFormsPageIsFiltering } from 'store/formsPage/forms.redux';

export default function FormsListPage({
  forms,
  formsCount,
  search,
  onChangeSearch,
  sort,
  onChangeSort,
  onSelectPreviewForm,
  onSelectDeleteForm,
  pageNumber,
  pageSize,
  onChangePageNumber,
  onChangePageSize,
  ...others
}) {
  const handleCheckedSelection = (selection, form) => {
    if (selection.value === FORM_ACTIONS.Preview.value) {
      onSelectPreviewForm(form.slug);
    }
    if (selection.value === FORM_ACTIONS.Delete.value) {
      onSelectDeleteForm(form.id);
    }
  };
  const isFiltering = useSelector(selectFormsPageIsFiltering);

  return (
    <BigListLayout
      search={
        <BigListSearchInput
          placeholder="Find Forms"
          value={search}
          onChange={onChangeSearch}
          loading={isFiltering}
          isClearable
        />
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={formsCount}
          onChangePage={onChangePageNumber}
          onChangePerPage={onChangePageSize}
        />
      }
      {...others}
    >
      <BigListTitle count={formsCount} single="Form" plural="Forms" />
      <ListCard>
        <FormListHeader
          label="Form Name"
          sort={sort}
          onChangeSort={(direction) =>
            onChangeSort({ column: 'name', direction })
          }
        />
        {!forms.length && <EmptyFormListItem />}
        {forms.map((form) => (
          <FormListItem
            key={form.id}
            form={form}
            onSelectAction={(selection) =>
              handleCheckedSelection(selection, form)
            }
          />
        ))}
      </ListCard>
    </BigListLayout>
  );
}

FormsListPage.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  formsCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onSelectPreviewForm: PropTypes.func.isRequired,
  onSelectDeleteForm: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
};
