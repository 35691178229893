import { useMemo } from 'react';

export const useCurrentLayout = (
  layoutListData: any[],
  selectedLayout?: string
) => {
  const selectedLayoutId = selectedLayout || layoutListData?.[0]?.id;

  const layoutData = useMemo(() => {
    return layoutListData?.find((layout) => layout.id === selectedLayoutId);
  }, [layoutListData, selectedLayoutId]);

  return layoutData;
};
