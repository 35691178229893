import { useReducer } from 'react';

export const START_FETCH = 'START_FETCH';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const COLLAPSE = 'COLLAPSE';
export const BATCH_ACTIONS = 'BATCH_ACTIONS';
export const CLEAR_STATE = 'CLEAR_STATE';

const batchDispatch = (dispatch, actions) => {
  dispatch({ type: BATCH_ACTIONS, payload: actions });
};

export const useSubRowReducer = () => {
  const subRowReducer = (state, action) => {
    let currentTotals = state.totals ?? [];
    let newTotals = [];
    switch (action.type) {
      case CLEAR_STATE:
        return {};
      case START_FETCH:
        return {
          ...state,
          [action.rowId]: { loading: true, data: null, expanded: false },
        };
      case FETCH_SUCCESS: {
        // initialize totals
        if (currentTotals.length === 0 && action.data.length > 0) {
          newTotals = new Array(action.data[0].values.length + 1).fill(0);
        } else {
          newTotals = currentTotals.map((total) => total || 0);
        }

        // caculate new totals after expand
        action.data.forEach((row) => {
          row.values.forEach((total, index) => {
            const newTotal = newTotals[index] + total;
            newTotals[index] = newTotal;
          });
        });

        // caculate grand total
        const totalSum = newTotals
          .slice(0, -1)
          .reduce((acc, total) => acc + total, 0);
        newTotals[newTotals.length - 1] = totalSum;
        return {
          ...state,
          [action.rowId]: {
            loading: false,
            data: action.data,
            expanded: true,
            collapsedManually: false,
          },
          totals: newTotals,
          fetchComplete: true,
          isAnyRowExpanded: true,
        };
      }
      case COLLAPSE: {
        const rowsToHide = state[action.rowId]?.data || [];
        currentTotals = state.totals;
        newTotals = [...currentTotals];

        const numberOfColumns = rowsToHide[0]?.values.length;

        // calculate values to subtract
        const valuesToSubtract = Array(numberOfColumns).fill(0);
        rowsToHide.forEach((row) => {
          row.values.forEach((total, index) => {
            valuesToSubtract[index] += total;
          });
        });

        // subtract values
        valuesToSubtract.forEach((valueToSubtract, index) => {
          newTotals[index] -= valueToSubtract;
        });

        // caculate grand total
        const collapseTotalSum = newTotals
          .slice(0, -1)
          .reduce((acc, total) => acc + total, 0);
        newTotals[newTotals.length - 1] = collapseTotalSum;

        const isAnyRowExpanded = Object.keys(state).some(
          (rowId) => state[rowId].expanded
        );

        return {
          ...state,
          [action.rowId]: {
            ...state[action.rowId],
            expanded: false,
            collapsedManually: action.collapsedManually ?? false,
          },
          totals: newTotals,
          isAnyRowExpanded,
        };
      }
      case FETCH_ERROR:
        return {
          ...state,
          [action.rowId]: { loading: false, data: null, expanded: false },
        };
      case BATCH_ACTIONS:
        return action.payload.reduce(subRowReducer, state);
      default:
        return state;
    }
  };
  const [subRowState, dispatch] = useReducer(subRowReducer, {});
  return [subRowState, dispatch, batchDispatch];
};
