import styled from '@emotion/styled';
import { gutters } from '../../app/spacing';

// This is a common wrapper for lists of radios and checkboxes

export default styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: ${gutters.spacing(2)}px;
  }
`;
