import { setOpenMenuAbove } from 'components/Tables/helpers';

// if the custom object has been deleted set a fake delete access
const setMissingAccess = (item) =>
  item?.customObject?.deleted ? { data: { access: { delete: true } } } : {};

export const getAutomationsWithMeta = (models) => (item, index, arr) => {
  const model =
    models.find(({ value }) => value === item.customObject?.id) ||
    setMissingAccess(item);

  return setOpenMenuAbove(
    {
      ...item,
      customObject: {
        ...item.customObject,
        access:
          model.data?.fetchUrl === 'client' // we need to force view permission for Contacts until KZN-5934
            ? { view: true }
            : model.data?.access,
      },
    },
    index,
    arr
  );
};
