import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import Overlay from 'react-bootstrap2/Overlay';

import { shadowLight } from 'app/colors';
import { layers } from 'app/spacing';
import { enrichEl, popperModifiers } from 'components/helpers';
import { ApplyMenuButton } from 'components/Kizen/Menu';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { Menu as BaseMenu } from './Select/customize';
import { css } from '@emotion/core';

const WithShadow = styled(StylePassthrough)`
  ${shadowLight}
  ${({ zIndex }) =>
    typeof zIndex === 'number'
      ? css`
          z-index: ${zIndex};
        `
      : ''}
`;

const Menu = styled(BaseMenu)`
  max-height: 200px;
`;

const popperCenterConfig = {
  modifiers: [
    popperModifiers.calcStyles((styles) => {
      return {
        position: 'absolute',
        top: '0px',
        left: '50%',
        transform: `translate3d(-50%, ${styles.top}px, 0px)`,
        willChange: 'transform',
      };
    }),
  ],
};

export default function SelectOverlay(props) {
  const {
    show,
    horizontalCentered,
    placementTopStart,
    children,
    zIndex,
    placement,
    popperConfig,
    style,
    target,
    selectOverlayObserverProps, // { root: null, rootMargin: '0px', threshold: 1}
    onHide,
    zIndexNudge = 0, // used to nudge the z-index of the overlay up or down from the default
    iDoNotWantToMountTwice, // old workaround with autoFocus forces children to remount each time it changes (this prop disables this behavior)
    ...others
  } = props;

  // Had to introduce some state here because if autoFocus
  // is true when the overlay opens, the browser will auto-scroll
  // to the top of the page. I believe this is because the overlay isn't
  // positioned anywhere yet.
  const [autoFocus, setAutoFocus] = useState(
    iDoNotWantToMountTwice ? children.props.autoFocus : false
  );
  const desiredAutoFocus =
    typeof children.props.autoFocus === 'boolean'
      ? children.props.autoFocus
      : true;
  useEffect(() => {
    !iDoNotWantToMountTwice &&
      requestAnimationFrame(() => {
        setAutoFocus(show && desiredAutoFocus);
      });
  }, [show, desiredAutoFocus, iDoNotWantToMountTwice]);

  const derivedPlacement = useMemo(() => {
    if (horizontalCentered) {
      return 'bottom';
    } else if (placementTopStart) {
      return 'top-start';
    }
    return placement;
  }, [horizontalCentered, placement, placementTopStart]);

  const derivedPopperConfig = useMemo(() => {
    const modifiers = [
      popperModifiers.addStyles({ zIndex: layers.overlay + zIndexNudge }),
      ...(popperConfig.modifiers || []),
    ];
    // override placement and merge popperConfig if horizontal centered is set
    if (horizontalCentered) {
      return {
        ...popperConfig,
        modifiers: [...popperCenterConfig.modifiers, ...modifiers],
      };
    }
    return { ...popperConfig, modifiers };
  }, [popperConfig, horizontalCentered, zIndexNudge]);

  const wrapperRef = useRef();
  const observer = useMemo(() => {
    return new IntersectionObserver((entries) => {
      const entre = entries.pop();
      if (entre && show) {
        const { isIntersecting } = entre;
        !isIntersecting && onHide();
      }
    }, selectOverlayObserverProps || {});
  }, [selectOverlayObserverProps, onHide, show]);

  useEffect(() => {
    if (selectOverlayObserverProps) {
      if (wrapperRef.current && !target) {
        observer.unobserve(wrapperRef.current);
      }
      wrapperRef.current = target;
      if (target) {
        observer.observe(wrapperRef.current);
      }
    }
    return () =>
      selectOverlayObserverProps &&
      wrapperRef.current &&
      observer.unobserve(wrapperRef.current);
  }, [observer, selectOverlayObserverProps, target]);

  if (!target) {
    return null;
  }

  return (
    <Overlay
      show={show}
      transition={false}
      rootClose
      placement={derivedPlacement}
      popperConfig={derivedPopperConfig}
      target={target}
      onHide={onHide}
      {...others}
    >
      {/*wrap in div to fix flickering on scroll*/}
      <div>
        <WithShadow
          style={style}
          passthrough={['scheduleUpdate']}
          zIndex={zIndex}
        >
          {React.cloneElement(children, {
            // react-select only autofocuses on mount, so we use a key to force a remount when this changes
            key: iDoNotWantToMountTwice ? undefined : autoFocus,
            sizing:
              children.props.sizing === false
                ? false
                : children.props.sizing || 'auto',
            autoFocus,
            menuInline: true,
            menuIsOpen: true,
            menuRightButton: enrichEl(children.props.menuRightButton, {
              selectOverlay: props,
            }),
            components: {
              Menu,
              ...children.props.components,
            },
            ...getDataQAForInput(
              props['data-field-id'],
              props['data-field-type']
            ),
          })}
        </WithShadow>
      </div>
    </Overlay>
  );
}

SelectOverlay.defaultProps = {
  show: null,
  placement: 'bottom-start',
  popperConfig: {},
  horizontalCentered: null,
  zIndex: null,
};

export function ApplySelectOverlayButton({ onClick, selectOverlay, ...props }) {
  return (
    <ApplyMenuButton
      onClick={(ev) => {
        selectOverlay.onHide();
        if (onClick) onClick(ev);
        selectOverlay?.children?.props?.handleClickApply?.(ev);
      }}
      {...props}
    />
  );
}

ApplySelectOverlayButton.defaultProps = {
  onClick: null,
};

ApplySelectOverlayButton.enrich = {
  selectOverlay: true,
};
