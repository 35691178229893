import React from 'react';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { layers } from 'app/spacing';
import { fontSizes, fontWeights, KizenTypography } from 'app/typography';
import ColorPickerInput from 'components/Kizen/ColorPickerInput';
import { useBuilderContext } from '../BuilderContext';

const ColorLabel = styled(KizenTypography)`
  display: flex;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.bold};
  color: ${grayScale.mediumDark};
  margin: 4px 0 6px;
`;

export const ColorPicker = ({
  color,
  label,
  align,
  onChange,
  className,
  style,
}) => {
  const { modalLayer } = useBuilderContext();

  return (
    <div className={className} style={style}>
      <ColorLabel>{label}</ColorLabel>
      <ColorPickerInput
        mini
        asOverlay
        zIndex={layers.modals(modalLayer, 10)}
        align={align}
        color={color}
        onChange={onChange}
      />
    </div>
  );
};
