import React, { forwardRef, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import { TextSpan, fontSizes } from 'app/typography';
import { displayBreakpoints, isMobile, useWindowSize } from 'app/spacing';
import { colorsText, dropdownColors, grayScale } from 'app/colors';
import {
  StyledLabel,
  StyledLabelWrapper,
  reactSelectStyles,
  StyledCustomOption,
} from './styles';
import Icon from '../Icon';
import { useTranslation } from 'react-i18next';

export const optionShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string.isRequired,
});

const getValue = (value, options) => {
  // if the value is a string, find that in the options and return it
  if (typeof value === 'string') {
    return options.find((option) => option.value === value) || null;
  }

  // if the value is an object, key off of the id
  if (value && 'id' in value) {
    return options.find((option) => option.value.id === value.id);
  }

  return value;
};

export const SingleValue = ({ children, ...propsSingleValue }) => {
  const Text = propsSingleValue.selectProps.TextComponent || TextSpan;
  return (
    <components.SingleValue {...propsSingleValue}>
      <Text
        value={propsSingleValue.selectProps.value}
        size={propsSingleValue.selectProps.textSize}
        style={{
          textIndent: '2px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          margin: 0,
        }}
      >
        {children}
      </Text>
    </components.SingleValue>
  );
};

const Select = forwardRef(
  (
    {
      className,
      label,
      textSize,
      size,
      style,
      styles,
      disabled,
      error,
      options,
      value,
      fullWidth,
      dropUp = false,
      components: componentsProp,
      onKeyDown,
      dropdownIcon,
      placeholder,
      isDisabledOption = false,
      wrapperDataQa,
      ...props
    },
    forwardedRef
  ) => {
    const { width } = useWindowSize();
    const { t } = useTranslation();
    const NoOptionsMessage = ({ children, ...propsNoOption }) => {
      return (
        <components.NoOptionsMessage {...propsNoOption}>
          <TextSpan size={textSize} style={{ color: colorsText.medium }}>
            {t('No Options')}
          </TextSpan>
        </components.NoOptionsMessage>
      );
    };

    const DropdownIndicator = () => (
      <Icon
        icon={dropdownIcon}
        size="2x"
        style={{
          textAlign: 'left',
          padding: '0 8px !important',
        }}
        color={grayScale.mediumDark}
      />
    );

    const updateOptions = useMemo(
      () =>
        isDisabledOption
          ? options.map((el) => ({
              ...el,
              isDisabled: el.value === value.value,
            }))
          : options,
      [isDisabledOption, options, value]
    );

    if (label) {
      return (
        <div
          className={className}
          data-qa-value={value?.value ?? value}
          data-qa-label={value?.label}
          data-qa={wrapperDataQa}
        >
          <StyledLabelWrapper>
            <StyledLabel disabled={disabled}>{label}</StyledLabel>
            <ReactSelect
              ref={forwardedRef}
              styles={{ ...reactSelectStyles, ...styles }}
              components={{
                SingleValue,
                NoOptionsMessage,
                DropdownIndicator,
                Option: StyledCustomOption,
                ...componentsProp,
              }}
              placeholder={
                //  When used Placholder component in components array click on placeholder is not triggering opening menu
                <TextSpan size={textSize} color={colorsText.medium}>
                  {placeholder}
                </TextSpan>
              }
              options={updateOptions}
              // TODO for some reason react-select anticipates a value of the shape { value, label },
              // i.e. one of the values in options.
              value={getValue(value, options)}
              mobile={isMobile(width, displayBreakpoints.desktop)}
              fullWidth={fullWidth}
              onKeyDown={(ev) => {
                if (onKeyDown) {
                  onKeyDown(ev, { options });
                }
              }}
              textSize={textSize}
              {...props}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: dropdownColors.hover,
                },
                controlHeight: 36,
              })}
              isDisabled={disabled}
              error={error}
              dropUp={dropUp}
            />
          </StyledLabelWrapper>
        </div>
      );
    }

    return (
      <div
        className={className}
        data-qa-value={value?.value ?? value}
        data-qa-label={value?.label}
        data-qa={wrapperDataQa}
      >
        <ReactSelect
          ref={forwardedRef}
          styles={{ ...reactSelectStyles, ...styles }}
          components={{
            SingleValue,
            NoOptionsMessage,
            DropdownIndicator,
            Option: StyledCustomOption,
            ...componentsProp,
          }}
          placeholder={
            //  When used Placholder component in components array click on placeholder is not triggering opening menu
            <TextSpan size={textSize} color={colorsText.medium}>
              {placeholder}
            </TextSpan>
          }
          options={updateOptions}
          // TODO for some reason react-select anticipates a value of the shape { value, label },
          // i.e. one of the values in options.
          value={getValue(value, options)}
          mobile={isMobile(width, displayBreakpoints.desktop)}
          fullWidth={fullWidth}
          onKeyDown={(ev) => {
            if (onKeyDown) {
              onKeyDown(ev, { options });
            }
          }}
          textSize={textSize}
          {...props}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: dropdownColors.hover,
            },
            controlHeight: 36,
          })}
          isDisabled={disabled}
          error={error}
        />
      </div>
    );
  }
);

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, optionShape]),
  options: PropTypes.arrayOf(optionShape),
  onChange: PropTypes.func,
  label: PropTypes.node,
  className: PropTypes.string,
  textSize: PropTypes.oneOf(Object.keys(fontSizes)),
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  size: PropTypes.oneOf(['large', 'small']),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inlineMenu: PropTypes.bool,
  components: PropTypes.object,
  onKeyDown: PropTypes.func,
  dropdownIcon: PropTypes.string,
  isDisabledOption: PropTypes.bool,
};

Select.defaultProps = {
  value: null,
  label: '',
  onChange: () => {},
  options: null,
  className: '',
  textSize: 'text',
  placeholder: '',
  styles: {},
  size: 'small',
  disabled: false,
  error: false,
  fullWidth: false,
  inlineMenu: false,
  components: null,
  onKeyDown: null,
  dropdownIcon: 'down',
};

export default Select;
