import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSizing } from '__components/Layout/PageContentWidth';
import { PrimaryObjectUploadContext } from '../../context';
import { StyledLoader } from '__pages/SmartConnectors/SmartConnector/steps/styles';
import { MatchingRulesList } from './MatchingRulesList';
import { IconWithTooltip } from '__pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import { StyledMatchingCard, StyledMatchingStepHeader } from './styles';
import { PRIMARY_OBJECT_UPLOAD_STEPS } from '__pages/SmartConnectors/constants';

export const MatchingStep = () => {
  const { t } = useTranslation();
  const { isNew, loadId, stepData, setIsValid, loading } = useContext(
    PrimaryObjectUploadContext
  );

  const isStepValid =
    !isNew ||
    Boolean(
      stepData.loads?.find(
        ({ id }) => (loadId === 'new' && !id) || id === loadId
      )
    );

  useEffect(() => {
    setIsValid(true);
  }, [setIsValid, isStepValid]);

  return (
    <PageSizing>
      <StyledMatchingCard
        data-qa={`${PRIMARY_OBJECT_UPLOAD_STEPS.matching}-step`}
      >
        <StyledMatchingStepHeader type="subheader">
          {t('Set Up Unique Identifier(s) Matching')}{' '}
          <IconWithTooltip
            label={t(
              'Kizen will evaluate and match existing records according to the rules and order you specify below. Matches must be exact.'
            )}
          />
        </StyledMatchingStepHeader>
        <StyledLoader loading={loading}>
          <MatchingRulesList />
        </StyledLoader>
      </StyledMatchingCard>
    </PageSizing>
  );
};
