import React from 'react';
import FontIcon from './FontIcon';

export default function FilterIcon() {
  return (
    <>
      <FontIcon
        icon={['fal', 'horizontal-rule']}
        transform="up-5"
        style={{
          transform: 'translateX(-2.5px) scale(0.8, 1)',
          fontSize: 'inherit',
        }}
      />
      <FontIcon
        icon={['fal', 'horizontal-rule']}
        style={{
          transform: 'translateX(-2.5px) scale(0.55, 1)',
          fontSize: 'inherit',
        }}
      />
      <FontIcon
        icon={['fal', 'horizontal-rule']}
        transform="down-5"
        style={{
          transform: 'translateX(-2.5px) scale(0.3, 1)',
          fontSize: 'inherit',
        }}
      />
    </>
  );
}
