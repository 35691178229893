import { useMemo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'components/Inputs/TextInput';
import EmailAddressTextInput from 'components/Inputs/TextInput/presets/EmailAddress';
import PhoneNumberTextInput from 'components/Inputs/TextInput/presets/PhoneNumber';
import DateInput from 'components/Inputs/DateInput';
import MultiSelect from 'components/Inputs/MultiSelect';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import SelectAllButton from 'components/Inputs/MultiSelect/SelectAllButton';
import PriceNumberTextInput, {
  toPriceNumber,
} from 'components/Inputs/TextInput/presets/PriceNumber';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { StyledFormRow, StyledFormRowLast, SwitchControl } from './styles';
import { useSelector } from 'react-redux';
import { getChosenBusiness } from 'store/authentication/selectors';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { FIELD_TYPES } from 'utility/constants';
import Cols from 'components/Layout/Cols';
import Switch from 'components/Kizen/Switch';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import ToolbarService from 'services/ToolbarService';
import { DEFAULT_TEMPLATE_ID } from 'components/Modals/ApplyTemplateModal';
import Dropdown from 'components/Fields/FieldInput/Dropdown';

const valueToSelectOption = (values) =>
  values.map((value) => ({
    value: value.id,
    label: value.name,
  }));

const selectOptionToValues = (options) =>
  options.map((option) => ({
    id: option.value,
    name: option.label,
  }));

const TeamMemberFormRenderFunc = ({
  isEdit = false,
  values,
  onChange,
  roles,
  permissionGroups,
  disableEmail,
  validationProps,
}) => {
  const phoneRef = useRef(null);
  const dateRef = useRef(null);
  const rowRef = useRef(null);
  const selectRef = useRef(null);
  const roleOptions = useMemo(
    () => roles && valueToSelectOption(roles),
    [roles]
  );
  const { t } = useTranslation();
  const chosenBusiness = useSelector(getChosenBusiness);

  const fetch = useCallback(
    async ({ search = '', page = 1 }, options) => {
      const data = await ToolbarService.listTemplates(
        page,
        20,
        'name',
        search,
        {
          ...options,
          skipErrorBoundary: true,
        }
      );
      if (page === 1) {
        return {
          ...data,
          results: [
            {
              id: DEFAULT_TEMPLATE_ID,
              employee_access: 'View',
              name: t('Default Template'),
              owner: {
                display_name: t('Kizen Administrator'),
                static: true,
              },
            },
            ...data.results,
          ],
        };
      }
      return data;
    },
    [t]
  );

  const [typeaheadProps, typeaheadState] = useSelectTypeaheadWithScroll({
    entity: Entities.ToolbarTemplates,
    fetch,
    selectRef,
    shouldRefetchOnMount: false,
    shouldResetOptions: false,
    keepPreviousData: true,
  });

  const options = useMemo(() => {
    return Array.isArray(typeaheadProps.options) &&
      typeaheadProps.options.length !== 0
      ? typeaheadProps.options
      : [
          {
            value: DEFAULT_TEMPLATE_ID,
            label: t('Default Template'),
          },
        ];
  }, [typeaheadProps.options, t]);

  const permissionGroupOptions = useMemo(
    () =>
      permissionGroups.map((pg) => ({
        value: pg.id,
        label: pg.name,
      })),
    [permissionGroups]
  );
  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'team-member-first-name' },
      { id: 'team-member-last-name' },
      { id: 'team-member-email' },
      { id: 'team-member-phone-number' },
      { id: 'start-date' },
      { id: 'team-roles' },
      { id: 'permission-groups' },
      !isEdit ? { id: 'toolbar-templates' } : null,
      { id: 'track-annual-sales-goal' },
      values && values.trackAnnualSalesGoal
        ? { id: 'annual-sales-goal' }
        : null,
    ].filter(Boolean),
    {},
    () => true
  );

  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <>
        <StyledFormRow>
          <TextInput
            required
            label={t('First Name')}
            placeholder={t('Enter First Name')}
            value={values.firstName}
            onChange={(value, ev, err) => onChange('firstName', value, err)}
            {...validationProps('firstName')}
            {...getDataQAForInput('team-member-first-name')}
            {...getKeyListenersProps('team-member-first-name')}
          />
          <TextInput
            required
            label={t('Last Name')}
            placeholder={t('Enter Last Name')}
            value={values.lastName}
            onChange={(value, ev, err) => onChange('lastName', value, err)}
            {...validationProps('lastName')}
            {...getDataQAForInput('team-member-last-name')}
            {...getKeyListenersProps('team-member-last-name')}
          />
        </StyledFormRow>
        <StyledFormRow fullWidth>
          <EmailAddressTextInput
            label={t('Email (Username)')}
            disabled={disableEmail}
            placeholder={t('Email@Domain.com')}
            value={values.email}
            required
            onChange={(value, ev, err) => onChange('email', value, err)}
            {...validationProps('email')}
            {...getDataQAForInput('team-member-email', 'email')}
            {...getKeyListenersProps('team-member-email')}
          />
        </StyledFormRow>
        <StyledFormRow fullWidth>
          <PhoneNumberTextInput
            ref={phoneRef}
            label={t('Phone Number')}
            placeholderNumber={t('Enter Phone Number')}
            value={values.phone}
            inModal
            onChange={(value, ev, err) => onChange('phone', value, err)}
            {...validationProps('phone')}
            defaultCountry={chosenBusiness.phone_default_region}
            {...getDataQAForInput('team-member-phone-number', 'phonenumber')}
            {...getKeyListenersProps('team-member-phone-number')}
          />
        </StyledFormRow>
        <StyledFormRow fullWidth>
          <DateInput
            ref={dateRef}
            variant="outline"
            labelInfo={t(
              'The user will be invited immediately, but this will be used for the start of quota calculation.'
            )}
            fieldType={FIELD_TYPES.Date.type}
            label={t('Start Date')}
            placeholder={t('Choose Date')}
            value={values.startDate}
            onChange={(value, err) => onChange('startDate', value, err)}
            {...validationProps('startDate')}
            classNamePrefix="DateSelector"
            {...getKeyListenersProps('start-date')}
          />
        </StyledFormRow>
        <StyledFormRow fullWidth>
          <MultiSelect
            label={t('Set Role(s)')}
            placeholder={t('Choose Role(s)')}
            options={roleOptions}
            value={valueToSelectOption(values.roles)}
            menuInline
            menuTopButton={<SelectAllButton />}
            menuLeftButton={<ClearSelectButton />}
            menuRightButton={<ApplySelectButton />}
            onChange={(value) => onChange('roles', selectOptionToValues(value))}
            {...validationProps('roles')}
            {...getDataQAForInput('team-roles', 'selector')}
            {...getKeyListenersProps('team-roles')}
          />
        </StyledFormRow>
        <StyledFormRow fullWidth>
          <MultiSelect
            label={t('Add Additional Permission Group(s)')}
            placeholder={t('Choose Additional Permission Group(s)')}
            options={permissionGroupOptions}
            value={valueToSelectOption(values.permissionGroups)}
            menuInline
            menuTopButton={<SelectAllButton />}
            menuLeftButton={<ClearSelectButton />}
            menuRightButton={<ApplySelectButton />}
            onChange={(value) =>
              onChange('permissionGroups', selectOptionToValues(value))
            }
            {...validationProps('permissionGroups')}
            {...getKeyListenersProps('permission-groups')}
          />
        </StyledFormRow>
        {isEdit ? null : (
          <StyledFormRow fullWidth>
            <Dropdown
              menuLeftButton={null}
              menuRightButton={null}
              field={{
                fieldType: FIELD_TYPES.Dropdown.type,
                options: options,
              }}
              isGetFullObject
              ref={selectRef}
              label={t('Custom Toolbar Template')}
              value={values.toolbarTemplate}
              menuInline
              onChange={(option) => {
                onChange('toolbarTemplate', option);
              }}
              {...typeaheadProps}
              options={options}
              isLoading={typeaheadState.loading}
              {...getKeyListenersProps('toolbar-templates')}
              {...getDataQAForInput('toolbar-templates', 'selector')}
              onFocus={(ev) => {
                typeaheadProps.onFocus(ev);
                getKeyListenersProps?.('toolbar-templates')?.onFocus(
                  'toolbar-templates'
                );
              }}
            />
          </StyledFormRow>
        )}
        {/* This is actually required, even though not represented in validateTeamMemberForm.
          We default to 0 when submitting. */}
        <StyledFormRowLast
          fullWidth
          ref={rowRef}
          checked={values.trackAnnualSalesGoal}
        >
          <Cols columns={2}>
            <SwitchControl
              label={t('Track Annual Quota')}
              {...getDataQAForInput('track-annual-sales-goal')}
            >
              <Switch
                checked={values.trackAnnualSalesGoal}
                onChange={() => {
                  onChange(
                    'trackAnnualSalesGoal',
                    !values.trackAnnualSalesGoal
                  );
                  if (values.trackAnnualSalesGoal) {
                    setTimeout(() => {
                      rowRef.current.parentNode.scrollTo({
                        top: rowRef.current.parentNode.scrollHeight,
                        behavior: 'smooth',
                        block: 'center',
                      });
                    }, 0);
                  }
                }}
                {...validationProps('trackAnnualSalesGoal')}
                {...getKeyListenersProps('track-annual-sales-goal')}
              />
            </SwitchControl>
            {values.trackAnnualSalesGoal ? (
              <PriceNumberTextInput
                label={t('Annual Quota')}
                placeholder={t('0.00')}
                value={
                  values.annualSalesGoal || values.annualSalesGoal === 0
                    ? values.annualSalesGoal
                    : ''
                }
                inModal
                required
                onChange={(value) =>
                  onChange('annualSalesGoal', toPriceNumber(value))
                }
                {...validationProps('annualSalesGoal')}
                fieldType={FIELD_TYPES.Money.type}
                {...getKeyListenersProps('annual-sales-goal')}
              />
            ) : null}
          </Cols>
        </StyledFormRowLast>
      </>
    </KeyBoardContext.Provider>
  );
};

export default TeamMemberFormRenderFunc;
