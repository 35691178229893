import { faChartColumn } from '@fortawesome/pro-light-svg-icons/faChartColumn';
import { faChartBar } from '@fortawesome/pro-light-svg-icons/faChartBar';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faSquarePollVertical } from '@fortawesome/pro-light-svg-icons/faSquarePollVertical';
import {
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';

export const SEMANTIC_CATEGORY: SemanticCategory = 'chart';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  'bar-vertical': faChartColumn,
  'bar-horizontal': faChartBar,
  line: faChartLine,
  pie: faChartPie,
  'square-poll-vertical': faSquarePollVertical,
};

export const labels: IconLabel = {
  'bar-vertical': {
    title: (t) => '',
    description: (t) => t('Bar'),
  },
  'bar-horizontal': {
    title: (t) => '',
    description: (t) => t('Bar'),
  },
  line: {
    title: (t) => '',
    description: (t) => t('Line'),
  },
  pie: {
    title: (t) => '',
    description: (t) => t('Pie'),
  },
  'square-poll-vertical': {
    title: (t) => '',
    description: (t) => t('Square Poll'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};
