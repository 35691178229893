import { isPipelineObject } from 'components/Modals/utilities';
import FieldService from './FieldService';
import PipelineService from './PipelineService';
import CustomObjectsService from './CustomObjectsService';
import { format } from 'date-fns2';

export const getTimezoneOffset = () => format(new Date(), `xxx`);

// oh no a second helper file, I added this to prevent circular redundancy
const whichServiceToUse = (model) => {
  if (!model) return CustomObjectsService;
  if (!model.isCustom) {
    return FieldService;
  }
  return isPipelineObject(model) ? PipelineService : CustomObjectsService;
};

export default whichServiceToUse;
