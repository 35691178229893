import styled from '@emotion/styled';
import { css } from '@emotion/core';
import NumberInput from 'react-number-format';

import { grayScale, colorsButton, colorsText } from 'app/colors';
import { gutters, borderRadii } from 'app/spacing';
import { TextSpan, fontWeights } from 'app/typography';

import Icon from '../Icon';

export const INPUT_TYPES = {
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  NUMBER: 'NUMBER',
  DECIMAL: 'DECIMAL',
  PRICE: 'PRICE',
  PERCENTAGE: 'PERCENTAGE',
  PRICE_V2: 'PRICE_V2',
  SEARCH: 'SEARCH',
  CREDIT_CARD: 'CREDIT_CARD',
  WHOLE_NUMBER: 'WHOLE_NUMBER',
  ONLY_NUMBER: 'ONLY_NUMBER',
};

const inputCommonStyles = css`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.00125em;
  color: ${colorsText.dark};
  padding: 0 12px;
  height: 34px;
`;

export const StyledLabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
  margin: 0; // Bootstrap adds bottom margin
`;

export const StyledLabel = styled(TextSpan)`
  color: ${colorsText.dark};
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

export const StyledInputWrapper = styled.span`
  display: flex;
  align-items: stretch;
  border: 1px solid ${grayScale.medium};
  overflow: hidden;
  border-radius: 4px;

  &.number-error {
    transition: border-color 0.3s ease-in-out;
    border-color: ${colorsButton.red.default};
  }
  &.no-number-error {
    transition: border-color 0.3s ease-in-out;
    border-color: ${grayScale.medium};
  }

  ${({ pill }) =>
    pill &&
    css`
      border-radius: 18px;
    `}

  ${(props) =>
    props.error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}

  ${(props) =>
    props.focused &&
    css`
      border-color: ${colorsButton.blue.hover};
    `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border-color: ${colorsButton.blue.hover};
      }
    `}

  ${(props) =>
    props.underline &&
    css`
      border: 0;
      border-radius: 0;
      background-image: url(/images/inputUnderline.svg);
      background-position: left bottom;
      background-repeat: repeat-x;
      padding-bottom: 2px;

      ${props.error &&
      css`
        background-image: url(/images/inputUnderline--error.svg);
      `}

      ${!props.disabled &&
      css`
        &:hover {
          background-image: url(/images/inputUnderline--hover.svg);
        }
      `}

      ${props.focused &&
      css`
        background-image: url(/images/inputUnderline--solid.svg);

        &:hover {
          background-image: url(/images/inputUnderline--solid.svg);
        }
      `}
    `}
`;

export const StyledInputEndcap = styled.span`
  ${inputCommonStyles};

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;

  ${(props) =>
    props.compact &&
    css`
      padding: 0 5px;
      min-width: 22px;
    `}

  && > * {
    width: 12px;
    svg {
      width: 12px;
      height: auto;
      // By default calendar icon is transformed to be smaller for some reason
      // Don't want to mess with that at the moment, but do want to override for KZN-1369
      transform: none !important;
    }
  }
`;

const inputFieldStyles = (props) => css`
  ${inputCommonStyles};

  flex-grow: 1;
  outline: none;
  border: none;
  border-radius: 4px;

  &[type='search'] {
    box-sizing: border-box;
  }

  // This will be overridden in underlined inputs
  ${props.disabled &&
  css`
    background: ${grayScale.light};
  `}

  &::placeholder {
    color: ${grayScale.mediumDark};

    ${props.disabled &&
    css`
      color: ${grayScale.medium};
    `}
  }

  ${StyledInputEndcap} + & {
    border-left: 0;
    padding-left: 0;
  }
`;

export const StyledInput = styled.input`
  ${inputFieldStyles};
`;

export const StyledNumberInput = styled(NumberInput)`
  ${inputFieldStyles};
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

export const StyledCreditCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledCreditCardIcon = styled(Icon)`
  margin-left: 10px;
`;

// Underlined

export const StyledUnderlinedInputEndcap = styled(StyledInputEndcap)`
  height: 28px;
  ${({ position }) =>
    position === 'front' &&
    css`
      min-width: unset;
      padding-left: 0;
      padding-right: 8px;
    `};
  ${({ position }) =>
    position !== 'front' &&
    css`
      padding: 0 10px;
    `};

  && > * {
    width: 10px;
    font-size: 10px;
    svg {
      width: 10px;
      max-height: 10px;
    }
  }
`;

export const StyledUnderlinedInput = styled(StyledInput)`
  // Allow input wrapper to provide any background so it's e.g. behind endcaps
  background: none;
  // TODO - we may want to add these styles conditionally in inputFieldStyles or inputCommonStyles
  padding: 0;
  // We want a total height of 39px including the border
  // BUT, we want to control the spacing around the text,
  // 10px from baseline to border
  // 10px from capline to baseline of shrunk label
  height: 28px;
  &::placeholder {
    opacity: 0;
    transition: opacity 10ms;
  }
  // We'll do this on shrink instead of on ::focus for cases like explicitly setting shrink to true
  ${({ shrink }) =>
    shrink &&
    css`
      &::placeholder {
        opacity: 1;
        // The underlined variant should always be medium-dark, even when disabled
        color: ${grayScale.mediumDark};
      }
    `}
`;

export const StyledUnderlinedNumberInput = styled(NumberInput)`
  ${inputFieldStyles};

  // Allow input wrapper to provide any background so it's e.g. behind endcaps
  background: none;

  padding-left: 0;
  padding-right: 10px;

  // Copied inputFieldStyles overrides from above
  height: 28px;
  &::placeholder {
    opacity: 0;
    transition: opacity 10ms;
  }
  // We'll do this on shrink instead of on ::focus for cases like explicitly setting shrink to true
  ${({ shrink }) =>
    shrink &&
    css`
      &::placeholder {
        opacity: 1;
      }
    `}
`;

const shrinkStyles = () => css`
  // scaling to 78.5% will take the 14px down to the 11px we want
  // The 4px will put the label 10px above the capline of the input text
  transform: translate(0, 4px) scale(0.785);
  // Account for the scaling when computing the width of the element
  max-width: calc(100% / 0.785);
  font-weight: ${fontWeights.bold};
  + ${StyledUnderlinedNumberInput} {
    &::placeholder {
      opacity: 1;
    }
  }
  + ${StyledUnderlinedInput} {
    &::placeholder {
      opacity: 1;
    }
  }
  // Undoes the pointer-events: none we set to allow the input to be focused through the label
  // this is so hover actions for truncationTooltip will work
  pointer-events: auto;
`;

const truncateStyles = () => css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledUnderlinedLabelWrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const StyledUnderlinedLabel = styled(TextSpan)`
  position: absolute;
  top: 0;
  left: 0;
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.regular};
  // Allow the user to focus/hover the input with the label in the way
  pointer-events: none;
  // 22px will put the label baseline exactly 10px from the bottom border
  transform: translate(0, 22px) scale(1);
  transition:
    font-weight,
    transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transform-origin: top left;
  max-width: 100%;
  ${truncateStyles};
  ${({ shrink }) => shrink && shrinkStyles};
`;

export const StyledUnderlinedInputWrapper = styled.span`
  display: flex;
  align-items: stretch;
  margin-top: ${gutters.standard};
  border-bottom: 1px solid ${grayScale.medium};
  overflow: hidden;

  &.number-error {
    transition: border-color 0.3s ease-in-out;
    border-color: ${colorsButton.red.default};
  }
  &.no-number-error {
    transition: border-color 0.3s ease-in-out;
    border-color: ${grayScale.medium};
  }
  &.email-error {
    transition: border-color 0.3s ease-in-out;
    border-color: ${colorsButton.red.default};
  }
  &.no-email-error {
    transition: border-color 0.3s ease-in-out;
    border-color: ${grayScale.medium};
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${colorsButton.red.hover};
    `}

  ${(props) =>
    props.focused &&
    css`
      border-color: ${colorsButton.blue.hover};
    `}

  border-top-left-radius: ${borderRadii.small};
  border-top-right-radius: ${borderRadii.small};

  ${(props) =>
    props.disabled &&
    css`
      background: ${grayScale.light};
    `}

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        border-color: ${colorsButton.blue.hover};
      }
    `}
`;

export const PointedIcon = styled(Icon)`
  cursor: pointer;
`;
