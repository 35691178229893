import styled from '@emotion/styled';
import { appBackground } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { breakpoints } from 'app/spacing';
import Button from 'components/Button';
import { GenericWizardSection } from 'components/GenericWizard';
import { TimezoneWarning } from 'components/Inputs/DateTimeInput/TimezoneWarning';
import Icon from 'components/Kizen/Icon';
import Loader from 'components/Kizen/Loader';
import Switch from 'components/Kizen/Switch';

export const DateSection = styled.div`
  max-width: 612px;
  margin-bottom: 17px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

const ButtonGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const TypesButtonGrid = styled(ButtonGrid)`
  & > button {
    width: 138px;
    height: 80px;
  }
`;

export const FiltersButtonGrid = styled(ButtonGrid)`
  & > button {
    width: 100%;
  }
  @media screen and (min-width: ${breakpoints.lg + 1}px) {
    flex-wrap: nowrap;
  }
  margin-bottom: 32px;
`;

export const EventButtonIcon = styled(Icon)`
  margin-bottom: 14px;
`;

export const SubHeader = styled(KizenTypography)`
  line-height: 16px;
  && {
    margin-bottom: 17px;
  }
`;

export const BroadcastTimezoneWarning = styled(TimezoneWarning)`
  position: static;
  margin-top: 10px;
`;

export const StyledLoader = styled(Loader)`
  margin-top: -10%;
`;

export const StyledPicker = styled.div`
  margin-bottom: ${({ disabled }) => (disabled ? 16 : 20)}px;
`;

export const ClearFilterButton = styled(Button)`
  margin: 20px 0 0;
`;

export const StyledFilterSummary = styled(KizenTypography)`
  background-color: ${appBackground};
  border-radius: 6px;
  padding: 20px;
  margin: 0;
  & > p {
    display: block;
  }
`;

export const DeletedEmailName = styled(KizenTypography)`
  && {
    margin-bottom: 1.6rem;
  }
`;

export const StyledSwitch = styled(Switch)`
  margin: 0;

  label {
    margin: 0;
  }
`;

export const StyledGenericWizardSection = styled(GenericWizardSection)`
  margin-bottom: 27px;
`;

export const StyledLastSection = styled(GenericWizardSection)`
  &&& {
    margin-top: 20px;
    margin-bottom: 12px;
  }
`;

export const StyledPreviewButton = styled(Button)`
  margin: 0;
`;

export const StyledSaveButton = styled(Button)`
  margin-left: 10px !important;
`;
