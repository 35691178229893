import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { useAsync } from 'react-use';
import { grayScale, tableHover } from 'app/colors';
import { KizenTypography } from 'app/typography';
import { TextEllipsis } from 'components/Kizen/Table';
import ImageService from 'services/ImageService';
import ConfirmationModal from './ConfirmationModal';

export const InfoWrapper = styled.div`
  background: ${tableHover};
  border-radius: 4px;
  padding: 10px 10px 7px 10px;
  margin-bottom: 18px;
  margin-top: 17px;
`;

export const TextWrapper = styled.div`
  text-align: left;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  span {
    line-height: 110%;
  }
`;

const ClipLoaderWrapper = styled.div`
  padding: 20px 0;
`;

export default function ConfirmImageDeletionModal({
  // disabled is captured to prevent accidental overwrite
  // for this modal, we only ever want to manage disabled state internally
  disabled,
  onHide,
  onConfirm,
  children,
  fileToDelete,
  ...others
}) {
  const { t } = useTranslation();
  const { value, loading } = useAsync(async () => {
    if (fileToDelete) {
      return ImageService.getImageReferences(fileToDelete);
    }
    return {
      forms: [],
      homepages: [],
      messages: [],
      surveys: [],
    };
  }, [fileToDelete]);

  const homepages = useMemo(() => {
    if (!value?.homepages || value.homepages.length === 0) {
      return t('None');
    }

    const counts = value?.homepages.reduce((acc, { name }) => {
      acc[name] = (acc[name] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(counts)
      .map(([name, count]) => (count > 1 ? `${name} (${count})` : name))
      .join(', ');
  }, [value?.homepages, t]);

  return (
    <ConfirmationModal
      size="medium"
      buttonText={t('Confirm Delete')}
      heading={t('Please Confirm Deletion')}
      defaultLeftBtnText={t('Cancel')}
      onConfirm={onConfirm}
      onHide={onHide}
      {...others}
    >
      <KizenTypography weight="bold" as="span">
        {t('This image is currently being used in')}:
      </KizenTypography>
      <InfoWrapper>
        {loading ? (
          <ClipLoaderWrapper>
            <ClipLoader color={grayScale.dark} loading={true} />
          </ClipLoaderWrapper>
        ) : (
          <>
            <TextWrapper>
              <KizenTypography weight="bold" as="span">
                {t('Survey(s)')}:&nbsp;
              </KizenTypography>
              <TextEllipsis as="span">
                {value?.surveys.map((tag) => tag.name).join(', ') || t('None')}
              </TextEllipsis>
            </TextWrapper>
            <TextWrapper>
              <KizenTypography weight="bold" as="span">
                {t('Form(s)')}:&nbsp;
              </KizenTypography>
              <TextEllipsis as="span">
                {value?.forms.map((tag) => tag.name).join(', ') || t('None')}
              </TextEllipsis>
            </TextWrapper>
            <TextWrapper>
              <KizenTypography weight="bold" as="span">
                {t('Message(s)')}:&nbsp;
              </KizenTypography>
              <TextEllipsis as="span">
                {value?.messages.map((tag) => tag.name).join(', ') || t('None')}
              </TextEllipsis>
            </TextWrapper>
            <TextWrapper>
              <KizenTypography weight="bold" as="span">
                {t('Homepage(s)')}:&nbsp;
              </KizenTypography>
              <TextEllipsis as="span">{homepages}</TextEllipsis>
            </TextWrapper>
          </>
        )}
      </InfoWrapper>
      <KizenTypography as="span" weight="bold">
        {t('Are you sure you want to permanently delete this image?')}{' '}
      </KizenTypography>{' '}
      <KizenTypography as="span">
        {t('It will be removed from everywhere it is currently being used.')}
      </KizenTypography>
    </ConfirmationModal>
  );
}
