import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'reactflow';

import {
  useAutomationsDispatch,
  useAutomationsSelector,
} from 'pages/AutomationEngine/store/react';
import { TRIGGER } from 'pages/AutomationEngine/steps';
import {
  getSteps,
  getStepsInMovingBranch,
} from 'pages/AutomationEngine/store/selectors';
import AddButton, {
  addButtonOptionsTranslated,
  ADD_BUTTON_HEIGHT,
} from '../AddButton';
import Handle from '../Handle';
import { actions } from '../../store';
import { flushUiChangesHack } from '../utilities';
import ConfiguredStepCard from '../Steps/ConfiguredStepCard';
import ErrorStepCard from '../Steps/ErrorStepCard';

import ErrorBoundaryResettable from 'components/Kizen/ErrorBoundaryResettable';
import steps from '../../steps';

export const StepNode = ({ type, data, ...props }) => {
  const dispatch = useAutomationsDispatch();
  const placing = useAutomationsSelector((state) => state.placing);
  const interacting = useAutomationsSelector((state) => state.interacting);
  const showStats = useAutomationsSelector((state) => state.showStats);
  const stepsInMovingBranch = useAutomationsSelector(getStepsInMovingBranch);
  const onlyFirstManualTrigger = useAutomationsSelector(
    (state) => state.triggers.length <= 1 && state.triggers[0].type === 'manual'
  );
  const zoom = useStore((state) => state.transform[2]);
  const steps = useAutomationsSelector(getSteps);

  const stepHasChildren = useCallback(
    (stepId) => Boolean(steps.some(({ parentKey }) => stepId === parentKey)),
    [steps]
  );
  // if the step has children then dispatch the action i.e. show a modal
  const deleteStep = useCallback(
    (id, type = null) =>
      stepHasChildren(id) && type
        ? dispatch(actions.deleteStepWithModal({ id, type }))
        : dispatch(actions.deleteStep(id)),
    [dispatch, stepHasChildren]
  );
  const handleOnStart = useCallback(() => {
    dispatch(actions.placing(data));
  }, [dispatch, data]);

  const handleOnStop = useCallback(() => {
    dispatch(actions.placing(false));
  }, [dispatch]);

  const handleOnSelectMenu = useCallback(
    ({ value }) => {
      if (value === 'delete') {
        if (type === 'automationTrigger') {
          dispatch(actions.deleteTrigger(data.id));
        } else if (['condition', 'goal'].includes(data.type)) {
          // we may need to show a modal
          deleteStep(data.id, data.type);
        } else {
          deleteStep(data.id);
        }
      }
      if (value === 'create-trigger') {
        dispatch(actions.createTrigger());
      }
      if (value === 'edit') {
        dispatch(actions.editStep({ begin: data }));
      }
    },
    [dispatch, data, deleteStep, type]
  );

  const ErrorCard = () => (
    <ErrorStepCard
      {...props}
      type={type}
      step={data}
      showMovingPlaceholder={stepsInMovingBranch.includes(data.id)}
      showStats={showStats}
      showHandle={placing ? false : undefined}
      showMenu={interacting ? false : undefined}
      disableMenu={type === 'automationTrigger' && onlyFirstManualTrigger}
      onSelectMenu={handleOnSelectMenu}
      // Drag-related props below, may not be used (yet) depending on the type
      scale={zoom}
      onStart={handleOnStart}
      onStop={handleOnStop}
    />
  );

  return (
    <ErrorBoundaryResettable FallbackComponent={ErrorCard}>
      <ConfiguredStepCard
        {...props}
        type={type}
        step={data}
        showMovingPlaceholder={stepsInMovingBranch.includes(data.id)}
        showStats={showStats}
        showHandle={placing ? false : undefined}
        showMenu={interacting ? false : undefined}
        disableMenu={type === 'automationTrigger' && onlyFirstManualTrigger}
        onSelectMenu={handleOnSelectMenu}
        // Drag-related props below, may not be used (yet) depending on the type
        scale={zoom}
        onStart={handleOnStart}
        onStop={handleOnStop}
      />
    </ErrorBoundaryResettable>
  );
};

// When placing a step, hide the add buttons on either side
const isPlacingStepAddNode = (
  placing,
  lastPlacingStep,
  data,
  stepsInMovingBranch
) =>
  placing &&
  lastPlacingStep?.id &&
  (lastPlacingStep.id === data.id ||
    lastPlacingStep.id === data.parentKey ||
    stepsInMovingBranch.includes(data.parentKey));

// when placing an go_to_automation step don't allow it to be dropped on a condition that has a child step
const isConditionAddNode = (placing, lastPlacingStep, data, id) =>
  placing &&
  lastPlacingStep?.type === steps.goToAutomationStep.type &&
  data?.label &&
  !(id.endsWith('.yes') || id.endsWith('.no'));

export const AddNode = ({ id, data, ...props }) => {
  const { t } = useTranslation();
  const addButtonOptionsNoBranch = useMemo(
    () =>
      addButtonOptionsTranslated(t).filter(
        ({ value }) => value !== 'create-branch'
      ),
    [t]
  );
  const dispatch = useAutomationsDispatch();
  const { placing, lastPlacingStep, interacting, branches } =
    useAutomationsSelector((x) => x);
  const stepsInMovingBranch = useAutomationsSelector(getStepsInMovingBranch);

  if (
    isPlacingStepAddNode(placing, lastPlacingStep, data, stepsInMovingBranch) ||
    isConditionAddNode(placing, lastPlacingStep, data, id)
  ) {
    // When placing a step, hide the add buttons on either side ||
    // when placing an go_to_automation step don't allow it to be dropped on a condition
    // by replacing them with a junction (just an edge connection).
    return <JunctionNode height={ADD_BUTTON_HEIGHT} />;
  }

  return (
    <AddButton
      centered={!data.leaf || data.branch}
      placing={placing}
      onDrop={async () => {
        // TODO this is abnoxious, but it is necessary so that
        // during layout after the drop that react-flow doesn't
        // measure the visible "Place Here" button before it
        // disappears. Essentially gives time for the button
        // to go back to its initial state then get measured.
        await flushUiChangesHack();
        dispatch(
          actions.drop({
            step: lastPlacingStep,
            ...data,
          })
        );
      }}
      showMenu={interacting ? false : undefined}
      options={
        !data.id || branches[data.parentKey || TRIGGER]
          ? addButtonOptionsNoBranch
          : addButtonOptionsTranslated(t)
      }
      onSelectOption={({ value }) => {
        if (value !== 'create-branch') {
          dispatch(
            actions.drop({
              step: { type: value },
              ...data,
            })
          );
        }
        if (value === 'create-branch') {
          dispatch(actions.createBranch(data));
        }
      }}
      {...props}
      {...data}
    />
  );
};

export const JunctionNode = ({ height = 1 }) => (
  <div style={{ height, width: 1 }}>
    <Handle
      id="handle-left"
      type="target"
      position="left"
      style={{ position: 'absolute', top: height / 2, left: 0 }}
    />
    <Handle
      id="handle-right"
      type="source"
      position="right"
      style={{ position: 'absolute', top: height / 2, left: -1 }}
    />
  </div>
);

export const NoneNode = () => null;
