import { isCustomFilter, isGroupFilter } from './types';
import { camelToSnakeCaseKeys } from 'services/helpers';
import { loadSavedFilter } from 'ts-filters/utils';

export const createWizardState = async (
  state,
  metaData,
  queryClient,
  filterVariablesObject
) => {
  const filterConfig = { and: null, query: [] };

  if (!state) {
    return {
      type: null,
      groups: [],
      filter: filterConfig,
    };
  }

  if (state.details.filterConfig) {
    const data = await loadSavedFilter(
      metaData,
      camelToSnakeCaseKeys(state.details.filterConfig),
      filterVariablesObject,
      queryClient
    );

    return {
      type: state.details.type,
      groups: state.details.groups || [],
      filter: {
        config: data,
        and: state.details.filterConfig.and,
      },
    };
  }

  return {
    type: state.details.type,
    groups: state.details.groups || [],
    filter: filterConfig,
  };
};

export const createPayload = (values, state = {}, filterConfig) => {
  return {
    ...state,
    details: {
      type: values.type,
      groups: (isGroupFilter(values.type) && values.groups) || [],
      filterConfig: isCustomFilter(values.type) ? filterConfig : null,
    },
  };
};
