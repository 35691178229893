import React, { useContext } from 'react';
import { CUSTOM_LAYOUT_TABS } from './utilities';

const CustomObjectWizardContext = React.createContext({
  preSave: null,
  isAddingContext: false,
  isContactNeedsToBeAdded: false,
  isCreateMode: true,
  isContact: false,
  isDirty: false,
  modalProps: null,
  bodyRef: null,
  startedCreateMode: true,
  setIsAddingContext: () => {},
  setIsContactNeedsToBeAdded: () => {},
  refetchCustomObject: () => {},
  customLayoutSelectedTab: CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT,
  setCustomLayoutSelectedTab: () => {},
});

export const CustomObjectWizardProvider = ({ preSave, children, ...rest }) => {
  return (
    <CustomObjectWizardContext.Provider value={{ preSave, ...rest }}>
      {children}
    </CustomObjectWizardContext.Provider>
  );
};

export const useCustomObjectWizardContext = () => {
  return useContext(CustomObjectWizardContext);
};

export const useCustomObjectWizard = () => {
  const {
    preSave,
    isAddingContext,
    setIsAddingContext,
    isContactNeedsToBeAdded,
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    setIsContactNeedsToBeAdded,
    bodyRef,
    isCreateMode,
    isContact,
    isDirty,
    modalProps,
    refetchCustomObject,
  } = useCustomObjectWizardContext();
  return {
    preSaveFn: (fn) => {
      if (preSave) {
        preSave.current = typeof fn === 'function' ? fn : null;
      }
    },
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    isAddingContext,
    isContactNeedsToBeAdded,
    isCreateMode,
    setIsAddingContext,
    setIsContactNeedsToBeAdded,
    bodyRef,
    isContact,
    isDirty,
    modalProps,
    refetchCustomObject,
  };
};
