import React, { useMemo } from 'react';
import { AreaSelectionWrapper, DashletArea, DashletAreaText } from './styles';
import Icon from 'components/Kizen/Icon';
import PropTypes from 'prop-types';

const AreaSelector = ({
  options,
  onChange,
  value,
  mobile,
  charts,
  disabled,
}) => {
  const filteredOptions = useMemo(() => {
    if (disabled && value) {
      const selectedOption = options.find((option) => option.value === value);
      return [selectedOption];
    }

    return options;
  }, [options, disabled, value]);

  return (
    <AreaSelectionWrapper
      className="area-selector"
      data-qa-value={value}
      charts={charts}
    >
      {filteredOptions.map((entity) => {
        return (
          <DashletArea
            key={entity.value}
            onClick={() => onChange(entity.value)}
            selected={value === entity.value}
            mobile={mobile}
            data-qa-type={entity.value}
            charts={charts}
            disabled={disabled}
          >
            <Icon icon={entity.icon} size={entity.size || 'intrinsic'} />
            <DashletAreaText>{entity.label}</DashletAreaText>
          </DashletArea>
        );
      })}
    </AreaSelectionWrapper>
  );
};

AreaSelector.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  mobile: PropTypes.bool,
};

AreaSelector.defaultProps = {
  options: [],
  value: '',
  mobile: false,
};

export default AreaSelector;
