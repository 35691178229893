import { createElement } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { icons } from '@kizen/icons';
import { colorsButton, grayScale } from 'app/colors';
import FilterIcon from './custom/FilterIcon';
import CalendarIcon from './custom/CalendarIcon';
import ThreeDotIcon from './custom/ThreeDotIcon';
import ReorderIcon from './custom/ReorderIcon';
import TrendCaretIcon from './custom/TrendCaretIcon';
import FontIcon from './custom/FontIcon';
import SurveyEntityIcon from './custom/SurveyEntityIcon';
import FormEntityIcon from './custom/FormEntityIcon';
import ActivityEntityIcon from './custom/ActivityEntityIcon';
import PipelineEntityIcon from './custom/PipelineEntityIcon';
import CommerceEntityIcon from './custom/CommerceEntityIcon';
import MarketingEntityIcon from './custom/MarketingEntityIcon';
import ColumnResizeIcon from './custom/ColumnResizeIcon';
import ExperienceEntityIcon from './custom/ExperienceEntityIcon';
import CloseIcon from './custom/CloseIcon';
import StylePassthrough from '../StylePassthrough';
import CycleHamburger from './custom/CycleHamburger';
import EqualsLight from './custom/EqualsLight';
import TrashAltLight from './custom/TrashAltLight';
import PlusLight from './custom/PlusLight';
import MinusLight from './custom/MinusLight';
import StageIcon from './custom/StageIcon';
import Kizen from './custom/Kizen';
import ResumeIcon from './custom/ResumeIcon';

const customs = {
  filter: FilterIcon,
  calendar: CalendarIcon,
  'three-dot': ThreeDotIcon,
  reorder: ReorderIcon,
  'trend-caret': TrendCaretIcon,
  'survey-entity': SurveyEntityIcon,
  'form-entity': FormEntityIcon,
  'activity-entity': ActivityEntityIcon,
  'pipeline-entity': PipelineEntityIcon,
  'commerce-entity': CommerceEntityIcon,
  'marketing-entity': MarketingEntityIcon,
  'column-resize': ColumnResizeIcon,
  'experience-entity': ExperienceEntityIcon,
  'close-cross': CloseIcon,
  'cycle-hamburger': CycleHamburger,
  equals: EqualsLight,
  trash: TrashAltLight,
  'plus-light': PlusLight,
  'minus-light': MinusLight,
  'stage-icon': StageIcon,
  'kizen-k': Kizen,
  'skip-and-resume': ResumeIcon,
};

export const iconList = [...Object.keys(icons), ...Object.keys(customs)].sort();

export const DEFAULT_ICON_COLOR = {
  light: grayScale.mediumDark,
  dark: grayScale.dark,
};

const getBadgeStyles = ({
  withBadge = false,
  badgeText,
  badgeHeight,
  isStaticBadge,
  badgeColor: badgeColorProp = colorsButton.blue,
  color,
}) => {
  if (!withBadge) return '';
  const badgeColor = badgeColorProp || color;
  const afterCSS = !badgeText
    ? css`
        content: '""';
        position: absolute;
        right: -3px;
        top: -3px;
        width: 7px;
        height: 7px;
        border-radius: 3.5px;
      `
    : css`
        content: '${badgeText}';
        ${isStaticBadge
          ? css`
              margin-left: ${badgeHeight / 4}px;
            `
          : css`
              position: absolute;
              left: ${badgeHeight * 1.25}px;
              top: 0;
            `}
        font-style: normal;
        font-size: ${badgeHeight - 5}px;
        line-height: 1em;
        color: #fff;
        text-align: center;
        padding: 2.5px 4px;
        min-width: ${badgeHeight}px;
        height: ${badgeHeight}px;
        border-radius: ${badgeHeight / 2}px;
        align-self: flex-start;
      `;

  return css`
    position: relative;

    &:after {
      ${afterCSS}
      background-color: ${badgeColor.default || badgeColor.light || badgeColor};
    }
    &:hover:after {
      background-color: ${badgeColor.hover || badgeColor.dark || badgeColor};
    }
  `;
};

const StyledIconSpan = styled.i`
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;

  ${getBadgeStyles}

  ${({ color, ignorePointerEvents }) => css`
    color: ${color?.light || color?.default || color};
    &:hover {
      color: ${color?.dark || color?.hover || color};
    }
    ${ignorePointerEvents ? 'pointer-events: none;' : ''}
  `}
`;

const Icon = ({
  icon = 'three-dot',
  rotation = null,
  size = '2x',
  color = DEFAULT_ICON_COLOR,
  className,
  style,
  children,
  noCustom = false,
  ignorePointerEvents = false,
  withBadge = false,
  badgeText,
  badgeHeight,
  isStaticBadge,
  alt,
  onClick,
  ...props
}) => {
  return customs[icon] && !noCustom ? (
    <StyledIconSpan
      className={`${
        size === 'intrinsic' ? '' : `fa-layers fa-${size}`
      } ${className || ''}`}
      color={color}
      style={style}
      data-qa-icon-name={icon}
      ignorePointerEvents={ignorePointerEvents}
      withBadge={withBadge}
      badgeText={badgeText}
      badgeHeight={badgeHeight}
      isStaticBadge={isStaticBadge}
      onClick={onClick}
    >
      {createElement(customs[icon], { icon, rotation, size, ...props })}
      {children}
    </StyledIconSpan>
  ) : (
    <StyledIconSpan
      className={`${className || ''}`}
      color={color}
      style={style}
      data-qa-icon-name={icon}
      ignorePointerEvents={ignorePointerEvents}
      alt={alt}
      withBadge={withBadge}
      badgeText={badgeText}
      badgeHeight={badgeHeight}
      isStaticBadge={isStaticBadge}
      onClick={onClick}
    >
      <FontIcon
        icon={icons[icon] || icon}
        rotation={rotation}
        size={size}
        {...props}
      />
    </StyledIconSpan>
  );
};

export default Icon;

// The force trick here is a hack to intentionally clobber other sizing styles.
// For example, the overlay-type button enforces a specific size icon, and this
// can be used to circumvent that.
export const IconSizing = styled(StylePassthrough)`
  ${({ size, force = 0, restrictWidth = true }) => css`
    ${'&'.repeat(force)}&,
    ${'&'.repeat(force)}&& svg {
      height: ${size};
      max-width: ${restrictWidth ? size : 'unset'};
      width: auto;
      transform: none !important; // Override e.g. transform on custom calendar icon
    }
  `}
`;
