import { BuilderImageNode, BuilderSectionNode } from './types';

const clamp = (x: number, lower: number, upper: number) => {
  if (x < lower) return lower;
  if (x > upper) return upper;
  return x;
};

/**
 * Provides a reducing function to act as a mapper while discarding certain values. The optional
 * second parameter can be supplied to control which values will be added to the resulting array.
 * Defaults to adding everything except null & undefined.
 *
 * @param mapper - the mapping function
 * @param collectPred - predicate function to determine which values to include in result array
 * @returns a reducer function to provide to Array.reduce
 */
export const collect = <A, B>(
  mapper: (x: A) => B,
  collectPred: (x: B) => boolean = (x: B) => x !== undefined && x !== null
) => {
  return (acc: B[], next: A) => {
    if (next) {
      const item = mapper(next);
      if (collectPred(item)) {
        acc.push(item);
      }
    }
    return acc;
  };
};

/**
 * Checks whether or not to use a default value for a given builder node prop and returns
 * either the prop value or the provided default.
 *
 * @param value - incoming value
 * @param fallback - value to use when isEmpty function return true
 * @param isEmpty - (optional) - function used to determine if `value` is empty/invalid.
 * @returns - value to be used in an mjml style tag
 */
export const withDefault = (
  value: any,
  fallback: string,
  isEmpty: (x: any) => boolean = (x) => x === undefined
) => {
  return isEmpty(value) ? fallback : value;
};

/**
 * We don't want to accept the MJML defaults for images when width or height is unspecified.
 * The naturalWidth & naturalHeight will be used to ensure the resulting width/height in the
 * email is the same that was used in the browser.
 *
 * @param props - full props object from the builder image node
 */
export const getImageWidthHeight = (
  props: BuilderImageNode['props']
): [number, number] => {
  const { width, height, naturalWidth, naturalHeight, containerWidth } = props;
  if (height && !width) {
    const w = height * (naturalWidth / naturalHeight);
    return [w, height];
  }

  const clampedWidth =
    containerWidth === undefined
      ? width || naturalWidth
      : clamp(width || naturalWidth, 0, containerWidth);

  if (!height) {
    return [clampedWidth, (naturalHeight / naturalWidth) * clampedWidth];
  }

  return [clampedWidth, height];
};

/**
 * Get the css styles to implement the width, max-width, and alignment node properties.
 *
 * @remarks mj-wrapper and mj-section do not have any width, max-width, or alignment attribures.
 * Also, the max-width css property has no effect on these MJML elements. We determine whether or
 * not to apply the max-width constraint and either set the width as a perecent or pixel unit.
 *
 * @param id - unique node id
 * @param props - node sizing props
 * @returns [string, string]
 */
export const getContainerSizingStyle = (
  id: string,
  {
    alignment,
    containerWidth,
    maxWidth,
    width,
  }: Pick<
    BuilderSectionNode['props'],
    'alignment' | 'containerWidth' | 'maxWidth' | 'width'
  >
) => {
  const max = parseInt(maxWidth);
  const widthPercent = parseInt(width) / 100;
  const widthPixels = widthPercent * parseInt(containerWidth);
  const w = widthPixels > max ? `${max}px` : `${width}%`;
  const classname = `section-${id}`;
  const style =
    alignment === 'center'
      ? `.${classname} { width: ${w}; }`
      : `
      .${classname} {
        width: ${w};
        float: ${alignment};
      }
    `;
  return [classname, style];
};

export const msoSupportedColor = (color: string): string => {
  const regexList = [
    { regex: /^#?([a-f\d]{8})$/i, format: 'hex8' }, // HEX(8) format
    { regex: /^#?([a-f\d]{6}|[a-f\d]{3})$/i, format: 'hex' }, // HEX format (3 or 6 digits)
    { regex: /rgb\((\d+),(\d+),(\d+)\)/, format: 'rgb' }, // RGB format (3 digits)
    { regex: /rgba\((\d+),(\d+),(\d+),(\d+(\.\d+)?)\)/, format: 'rgba' }, // RGBA format (4 digits)
  ];

  let format: string | null = null;
  let matches: RegExpMatchArray | null = null;

  for (const { regex, format: currentFormat } of regexList) {
    if ((matches = color.match(regex))) {
      format = currentFormat;
      break;
    }
  }
  if (format && matches) {
    switch (format) {
      case 'hex8': {
        const alphaHex8 = matches?.[1]?.slice(6, 8); // Extract the alpha channel in HEX format
        const alpha8 = parseInt(alphaHex8 || 'ff', 16) / 255;
        return alpha8 === 1 ? `#${matches[1].slice(0, 6)}` : `#${matches[1]}`;
      }
      case 'hex':
        return `#${matches[1]}`;

      case 'rgb': {
        const red = parseInt(matches?.[1] || '0', 10);
        const green = parseInt(matches?.[2] || '0', 10);
        const blue = parseInt(matches?.[3] || '0', 10);
        return `#${red.toString(16).padStart(2, '0')}${green
          .toString(16)
          .padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
      }
      case 'rgba': {
        const alphaRGBA = parseFloat(matches?.[4] || '1');

        if (alphaRGBA === 1) {
          const red = parseInt(matches?.[1] || '0', 10);
          const green = parseInt(matches?.[2] || '0', 10);
          const blue = parseInt(matches?.[3] || '0', 10);
          return `#${red.toString(16).padStart(2, '0')}${green
            .toString(16)
            .padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
        } else {
          return color;
        }
      }
    }
  }
  return color;
};
export const processText = (text: string): string => {
  const styleRegex = /style="(.*?)"/g;
  const colorRegex = /color:\s*(.*?)(?:;|$)/;
  const bgColorRegex = /background-color:\s*(.*?)(?:;|$)/;

  return text.replace(styleRegex, (match, style) => {
    const textColorMatch = style.match(colorRegex);
    const bgColorMatch = style.match(bgColorRegex);
    let newStyle = style;

    if (textColorMatch) {
      const textColor = textColorMatch[1];
      newStyle = newStyle.replace(
        textColor,
        msoSupportedColor(textColor.replace(/\s/g, ''))
      );
    }

    if (bgColorMatch) {
      const bgColor = bgColorMatch[1];
      newStyle = newStyle.replace(
        bgColor,
        msoSupportedColor(bgColor.replace(/\s/g, ''))
      );
    }

    return `style="${newStyle}"`;
  });
};
