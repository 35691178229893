import { RequestAccess } from './subpages/RequestAccess';
import { MyFilterGroups } from './subpages/MyFilterGroups';

export const SUBPAGES = {
  MINE: 'mine',
  REQUEST_ACCESS: 'others',
};

export const SUBROUTES = {
  [SUBPAGES.MINE]: {
    label: (t) => t('My Filter Groups'),
    component: MyFilterGroups,
    exact: true,
    path: SUBPAGES.MINE,
    icon: 'user-circle',
  },
  [SUBPAGES.REQUEST_ACCESS]: {
    label: (t) => t('Filter Group Access Requests'),
    component: RequestAccess,
    exact: true,
    path: SUBPAGES.REQUEST_ACCESS,
    icon: 'share-alt',
  },
};
