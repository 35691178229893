import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Section from 'components/Wizards/shared/components/section';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import AreaSelector from 'components/Wizards/Dashlet/sections/DashletType/components/AreaSelector';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { AREA_RESPONSES, SALES_REPORT_TYPES, chartMap } from '../../types';
import FieldMetricsArea from './areas/FieldMetrics';
import Loader from 'components/Kizen/Loader';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import ObjectSelector from 'components/Wizards/shared/components/MetaFilters/ObjectSelector';
import SalesArea from './areas/Sales';
import useCustomObjectDetails from 'components/Wizards/shared/hooks/useCustomObjectDetails';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import { isEntityValueField, isEstimatedCloseDateField } from 'checks/fields';

// To avoid a "flash" of incorrect content while loading, try to determine
// whether the sales chart type is available or not
const likelyShouldShowSales = ({
  model,
  isLoading,
  reportType,
  fetchUrl,
  entity,
  salesReportTypes = [],
}) => {
  const initialCheck = entity.enabled && !entity.forceHide;

  // If there are no sales report types available, don't show the sales
  if (!salesReportTypes.length) {
    return false;
  }

  // We have the model if creating a new chart, so rely on that first
  // because it doesn't need to load
  if (model?.fetchUrl === 'pipeline') {
    return initialCheck;
  }

  // If we are editing and the metadata is still loading, show the sales
  // type if this was previously a sales chart
  if (isLoading && reportType?.value === AREA_RESPONSES.SALES) {
    return initialCheck;
  }

  // Once we have the metadata, we can determine for sure whether the
  // sales chart type is allowed or not
  if (fetchUrl !== 'pipeline') {
    return false;
  }

  return initialCheck;
};

const ChartTypeWizardSection = ({
  index,
  active,
  done,
  last,
  mobile,
  objectId,
  originalObjectId,
  setSelectedObjectId,
  setIsContacts,
  clientObjectId,
  homepageSelectedObjectId,
  homepage,
  disabled,
  ...rest
}) => {
  const { state, setState, modalSize, setModalSize, model } = useContext(
    WizardStateContext.Context
  );

  useMetadataKey('homepageSelectedObjectId', homepageSelectedObjectId);

  const previewFlagEnabled = usePreReleaseFeatures();
  const { reportType } = state;
  const { t } = useTranslation();

  const { data, isLoading } = useCustomObjectDetails({
    objectId,
    enabled: Boolean(objectId), // we don't use active we depend on objectId being set,
  });

  const { valueField, estimatedCloseDateField } = useMemo(() => {
    return (
      data?.fields.reduce((acc, curr) => {
        if (isEntityValueField(curr)) {
          acc.valueField = curr;
        }
        if (isEstimatedCloseDateField(curr)) {
          acc.estimatedCloseDateField = curr;
        }
        return acc;
      }, {}) ?? {}
    );
  }, [data]);

  // You need view permission for entity value and estimated close date in order to create the
  // records won over time report
  const isRecordsWonOverTimeAllowed =
    Boolean(valueField?.access?.view) &&
    Boolean(estimatedCloseDateField?.access?.view);

  // These report types are build here and passed down to the "Area" component
  // Because the number of options affects which areas are available to be chosen
  const salesReportTypes = useMemo(() => {
    const opts = isLoading
      ? []
      : [
          isRecordsWonOverTimeAllowed && {
            label: t('Projected {{entityName}} Records Won Over Time', {
              entityName: data?.entityName,
            }),
            value: SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME,
          },
        ].filter(Boolean);

    return opts;
  }, [t, data, isLoading, isRecordsWonOverTimeAllowed]);

  const title = rest.title || t('Chart Type');

  const entities = useMemo(() => {
    return Object.keys(chartMap)
      .map((entityName) => {
        return {
          icon: chartMap[entityName].icon,
          label: chartMap[entityName].label(t),
          value: chartMap[entityName].value,
          size: chartMap[entityName].size ?? 'intrinsic',
          order: chartMap[entityName].order ?? -1,
          enabled: previewFlagEnabled
            ? true
            : chartMap[entityName].enabled ?? false,
          forceHide: chartMap[entityName].forceHide ?? false,
        };
      })
      .filter((e) => {
        if (e.value === AREA_RESPONSES.SALES) {
          return likelyShouldShowSales({
            model,
            isLoading,
            reportType,
            fetchUrl: data?.fetchUrl,
            entity: e,
            salesReportTypes,
          });
        }
        return e.enabled && !e.forceHide;
      })
      .sort((a, b) => a.order - b.order);
  }, [
    t,
    previewFlagEnabled,
    data,
    model,
    reportType,
    isLoading,
    salesReportTypes,
  ]);

  useDefaultValues(
    {
      reportType: { value: entities[0]?.value },
    },
    active && (objectId || originalObjectId)
  );

  useEffect(() => {
    const isTable = reportType?.value === AREA_RESPONSES.TABLE;
    const isPivotTable = reportType?.value === AREA_RESPONSES.PIVOT_TABLE;
    const isLargeModal = isTable || isPivotTable;
    if (isLargeModal && modalSize !== 'large') {
      setModalSize('large');
    } else if (!isLargeModal && modalSize !== 'medium') {
      setModalSize('medium');
    }
  }, [modalSize, reportType, setModalSize]);

  return (
    <>
      <Section
        index={index}
        title={title}
        active={active}
        done={done}
        last={last}
      >
        {homepage && (
          <StyledWizardField>
            <ObjectSelector
              onChange={(obj) => {
                if (obj.value === clientObjectId) {
                  setIsContacts(true);
                } else {
                  setIsContacts(false);
                }
                setState('field', undefined);
                setState('reportType', undefined);
                setSelectedObjectId(obj.value);
              }}
              value={objectId ? { id: objectId } : undefined}
              label={
                <KizenTypography type="subheader">
                  {t('Select Object to Chart On')}
                </KizenTypography>
              }
              disabled={disabled}
            />
          </StyledWizardField>
        )}
        {!originalObjectId && !homepageSelectedObjectId ? null : (
          <StyledWizardField top={gutters.spacing(2, 2)}>
            <KizenTypography type="subheader">
              {t('Choose Type')}
            </KizenTypography>
            <StyledWizardField top={gutters.spacing(1, 1)}>
              {isLoading ? (
                <Loader loading />
              ) : (
                <AreaSelector
                  options={entities}
                  onChange={(v) => {
                    setState('field', undefined);
                    setState('reportType', { value: v });
                  }}
                  value={reportType?.value}
                  mobile={mobile}
                  charts
                  disabled={(!originalObjectId && !objectId) || disabled}
                />
              )}
            </StyledWizardField>
          </StyledWizardField>
        )}
        {!originalObjectId && !objectId ? null : (
          <>
            {/* These "Area" components control their own rendering and will only be visible when appropriate */}
            <FieldMetricsArea
              data={data}
              isLoading={isLoading}
              disabled={disabled}
            />
            <SalesArea
              reportTypes={salesReportTypes}
              reportTypesLoading={isLoading}
              disabled={disabled}
            />
          </>
        )}
      </Section>
    </>
  );
};

export default ChartTypeWizardSection;
