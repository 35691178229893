import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import useToggle from 'react-use/lib/useToggle';
import { DraggableCore } from 'react-draggable';
import { grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import {
  updateContactsPageColumnWidth,
  setPageConfig,
} from 'store/contactsPage/actions';
import {
  SizedCell as KizenSizedCell,
  SortableHeadCell as KizenSortableHeadCell,
  TextEllipsis,
} from 'components/Kizen/Table';
import { getColumns, shallowEqualList } from 'pages/Common/helpers/table';

import {
  ResizerLayout,
  PADDING,
  getSizing,
  getColumnWidthFromConfig,
} from 'pages/Common/components/BigTableCells';
import { RECORD_LIST_CONFIG_KEYS } from 'services/TeamMemberService';

export const SizedCell = styled(({ column, minWidth, ...others }) => {
  // We pull column widths from redux not just because it is persistent/stateful,
  // but also because it greatly improves the performance versus managing this state
  // and piping it down from the TabularPage, since the whole table may re-render
  // rather than just the specific columns that were resized.
  const columnSettings = useSelector(
    (s) => s.contactPage.pageConfig.columns[column.id]
  );

  return (
    <KizenSizedCell
      column={column}
      data-qa={column.label}
      data-qa-disabled={!column.meta.editable}
      padding={`${PADDING}px`}
      {...getSizing(columnSettings && columnSettings.width, minWidth)}
      {...others}
    />
  );
})`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
  }
`;

export const SpacerCell = styled(({ column, width, ...others }) => {
  return <KizenSizedCell column={column} width={width} {...others} />;
})`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
  }
`;

export const SortableHeadCell = styled(
  ({ column, minWidth, children, className, columnSettings, ...others }) => {
    const dispatch = useDispatch();
    const width = useSelector((s) =>
      getColumnWidthFromConfig(s.contactPage, column.id)
    );
    const savedColumnSettings = useSelector(
      (s) => getColumns(s.contactPage.pageConfig),
      shallowEqualList
    );

    const [resizing, toggleResizing] = useToggle(false);
    const handleResize = useCallback(
      (ev, { x }) => {
        const nextWidth = Math.max(75, x - PADDING / 2);
        dispatch(
          // !important - set or reset all properties in object
          updateContactsPageColumnWidth({
            id: column.id,
            label: column.label,
            width: `${nextWidth}px`,
            visible: true,
          })
        );
      },
      [dispatch, column.id, column.label]
    );

    const handleUpdateColumnWidth = useCallback(() => {
      if (!savedColumnSettings) {
        const updatedColumnSettings = columnSettings.map((c) => {
          if (c.id === column.id) {
            return {
              ...c,
              width: `${width}px`,
            };
          }
          return c;
        });
        dispatch(
          setPageConfig({
            settings: updatedColumnSettings,
          })
        );
      }
      dispatch(
        setPageConfig({ updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.COLUMNS })
      );
    }, [dispatch, savedColumnSettings, columnSettings, column, width]);

    return (
      <KizenSortableHeadCell
        className={`${className} ${resizing && 'Resizing'}`}
        column={column}
        padding={`${PADDING}px`}
        {...getSizing(width, minWidth)}
        {...others}
      >
        {children}
        <ResizerLayout className="ResizerLayout">
          <DraggableCore
            onDrag={handleResize}
            onStart={toggleResizing}
            onStop={() => {
              toggleResizing();
              handleUpdateColumnWidth();
            }}
          >
            <Icon className="Resizer" icon="column-resize" size="intrinsic" />
          </DraggableCore>
        </ResizerLayout>
      </KizenSortableHeadCell>
    );
  }
)`
  &.Resizing {
    cursor: ew-resize;
    svg line {
      stroke: ${grayScale.dark};
    }
  }
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
    margin-top: 1px;
    button {
      margin-left: 10px;
    }
  }
`;

const NO_VALUE = '—';

export const MaybeLinkText = ({
  label = null,
  to = null,
  emptyPlaceholder = NO_VALUE,
  ...others
}) => {
  const hasValue = Boolean(label || label === 0);
  return (
    <TextEllipsis as={!to && 'span'} type={to && 'link'} to={to} {...others}>
      {hasValue && label}
      {!hasValue && emptyPlaceholder}
    </TextEllipsis>
  );
};
