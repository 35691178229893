import Input from 'components/Kizen/Input';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'components/Inputs/MultiSelect';
import Select from 'components/Inputs/Select';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';
import useObjectOptions from 'components/Wizards/Dashlet/hooks/useObjectOptions';
import { Spacer } from '@kizen/kds/Spacer';

const NewRecentItemsDialog = ({
  options,
  create = true,
  onConfirm,
  onHide,
  ...rest
}) => {
  const { t } = useTranslation();
  const [displayName, setDisplayName] = useState(
    options?.item?.displayName ?? t('Recent Records')
  );

  const [count, setCount] = useState(options?.item?.config?.count ?? 10);
  const [chosenObjects, setChosenObjects] = useState(
    options?.item?.config?.objects ?? []
  );

  const handleConfirm = useCallback(() => {
    onConfirm(options, displayName, {
      count,
      objects: chosenObjects,
    });
  }, [onConfirm, options, displayName, count, chosenObjects]);

  const handleCancel = useCallback(() => {
    onHide(create);
  }, [onHide, create]);

  const clientObject = useSelector(getBusinessClientObject);

  const clientObjectId = clientObject?.id;

  const { selectedObjectsMetadata, ...objectsSelectProps } = useObjectOptions({
    useClient: true,
    enabled: Boolean(clientObjectId),
    clientObjectId,
  });

  return (
    <BasicModal
      {...rest}
      heading={
        create
          ? t('Add Recent Records Category')
          : t('Edit Recent Records Category')
      }
      show
      onConfirm={handleConfirm}
      onHide={handleCancel}
      disabled={!displayName.trim() || !count}
      leftBtn={false}
    >
      <Input
        placeholder={t('Enter Display Name')}
        value={displayName}
        onChange={setDisplayName}
        label={t('Display Name')}
        inModal
      />
      <Spacer size={15} />
      <Select
        label={t('Number of Recent Records')}
        value={{ value: count, label: String(count) }}
        options={[
          {
            value: 10,
            label: '10',
          },
          {
            value: 20,
            label: '20',
          },
          {
            value: 30,
            label: '30',
          },
        ]}
        onChange={(c) => setCount(c.value)}
        menuInline
      />
      <Spacer size={15} />
      <MultiSelect
        placeholder={t('Choose Objects to Include (Leave Blank for All)')}
        label={t('Choose Objects')}
        className={`modal-multiselect objects ${
          objectsSelectProps.isLoading ? 'loading' : 'ready'
        }`}
        value={chosenObjects}
        onChange={setChosenObjects}
        {...objectsSelectProps}
        menuInline
      />
    </BasicModal>
  );
};

export default NewRecentItemsDialog;
