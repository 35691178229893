import DraggableStepCard from '../../DraggableStepCard';

export const content = ({ step }) => step.description;

const getFieldName = ({ value, type }, automation, fields) => {
  if (type === 'merge_field') {
    const requiredField = fields.find((field) => {
      return field.id === value || field.name === value;
    });
    return `{{ ${[
      automation.customObject.objectClass,
      value === 'id' ? 'id' : requiredField?.name,
    ].join('.')} }}`;
  }
  return value;
};

export const getDescription = ({ step, automation, fields }) => {
  const data =
    step?.details && Object.keys(step?.details).length !== 0
      ? step.details
      : step.actionHttpRequest;
  const url = data?.urlParts
    ? data.urlParts
        .map((item) => getFieldName(item, automation, fields))
        .join('')
    : data.url;

  return `${data.method} Request to '${url}'`;
};

export default function HttpRequestCard({ step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
