import { useEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  addDays,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  getDay,
  setDay,
} from 'date-fns';
import { debounce } from 'lodash';
import { shadowLight } from 'app/colors';
import { borderRadii } from 'app/spacing';
import { DefaultMonthCalendarCell } from './components/MonthCalendarCell';
import { Container, DateTitle, WeekdayHeaderCell } from './components/styled';
import { useTranslation } from 'react-i18next';
import { CALENDAR_VIEW_OPTIONS } from './constants';
import { getDateTitle } from './utils';

const DAYS_IN_WEEK = 7;
const MIN_CALENDAR_HEIGHT = 676;

const useRemeasureOnResize = (debounceValue = 100) => {
  const [remeasure, setRemeasure] = useState(false);

  useEffect(() => {
    const listener = debounce(() => {
      setRemeasure(true);
      setTimeout(() => setRemeasure(false));
    }, debounceValue);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [debounceValue, setRemeasure]);

  return remeasure;
};

const useVisibleDates = (date) =>
  useMemo(() => {
    const monthStart = startOfMonth(date);
    const monthEnd = endOfMonth(date);
    const start = addDays(monthStart, -getDay(monthStart));
    const end = addDays(monthEnd, 6 - getDay(monthEnd));
    return eachDayOfInterval({ start, end });
  }, [date]);

const Grid = styled.div`
  ${shadowLight}
  border-radius: ${borderRadii.standard};
  display: grid;
  grid-template-columns: repeat(
    ${DAYS_IN_WEEK},
    minmax(calc(600px / ${DAYS_IN_WEEK}), 1fr)
  );
  grid-auto-rows: ${({ rowHeight }) => (rowHeight ? `${rowHeight}px` : '1fr')};
  height: calc(100% - 54px);
  // max-height is only necessary for Firefox where setting grid-auto-rows from 1fr to fixed pixels
  // causes the useMeasure hook to be called continually, increasing the row size by a small amount until
  // the container max-height is reached.
  max-height: ${({ rowHeight, numRows }) =>
    rowHeight ? `${rowHeight * numRows}px` : ''};
`;

const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: repeat(${DAYS_IN_WEEK}, 1fr);
  grid-template-rows: 1fr;
  width: 100%;
  height: 34px;
`;

const TopRowText = styled(DateTitle)`
  margin-top: 2px;
`;

const TopRow = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const weekdays = useMemo(
    () =>
      Array.from({ length: DAYS_IN_WEEK }, (_, i) =>
        getDateTitle(
          setDay(new Date(), i),
          language,
          CALENDAR_VIEW_OPTIONS.month
        )
      ),
    [language]
  );
  return (
    <HeaderRow>
      {weekdays.map((day) => (
        <WeekdayHeaderCell key={day}>
          <TopRowText size="buttonLabel">{day}</TopRowText>
        </WeekdayHeaderCell>
      ))}
    </HeaderRow>
  );
};

const MonthCells = ({ dates, calendarDate, Component, ...rest }) =>
  dates.map((d, idx, arr) => (
    <Component
      key={d.getTime()}
      date={d}
      calendarMonth={calendarDate.getMonth()}
      calendarYear={calendarDate.getFullYear()}
      index={idx}
      total={arr.length}
      {...rest}
    />
  ));

/**
 * This separate component exists in order to contain the useMeasure hook. The parent component
 * (ExpandableCalendar) removes and reattaches this componnent on resize events so the initial
 * process of determining row width/height can be done. Specifically, setting initially grid-auto-rows
 * to "1fr" on initial load and then changing it to a fixed pixel value after useMeasure returns dimensions.
 */
const MeasuredGrid = ({ dates, calendarDate, view, numRows, Component }) => {
  const [ref, { height, width }] = useMeasure();

  // Safari does not measure the total grid height properly with `grid-auto-rows: 1fr`
  // This ternary condition is for handling Safari returning a height of 10 and forcing
  // the calendar to be it's minimum size. Alternatively, the grid could be wrapped in a
  // div with `display: grid` which causes the measurement to be accurate See:
  // https://stackoverflow.com/questions/55347359/why-is-css-grid-row-height-different-in-safari#answer-63197330
  const rowHeight =
    height > 0 && height < MIN_CALENDAR_HEIGHT / 2
      ? MIN_CALENDAR_HEIGHT / numRows
      : height / numRows;

  return (
    <Grid ref={ref} view={view} numRows={numRows} rowHeight={rowHeight}>
      <MonthCells
        dates={dates}
        calendarDate={calendarDate}
        Component={Component}
        rowWidth={width / DAYS_IN_WEEK}
        rowHeight={rowHeight}
      />
    </Grid>
  );
};

/**
 * Calendar component to view dates for a single month.
 *
 * @param props.date - the date for the calendar used to show the month
 * @param props.CellComponent - the cell component to be used when rendering each day. Defaults to {@link DefaultDateCell}
 */
const Calendar = ({ date, CellComponent, ...rest }) => {
  const visibleDates = useVisibleDates(date);

  return (
    <Container {...rest}>
      <TopRow />
      <Grid>
        <MonthCells
          dates={visibleDates}
          calendarDate={date}
          Component={CellComponent}
        />
      </Grid>
    </Container>
  );
};

/**
 * Calendar component to be used when providing {@link ExpandableDateCell} as the `CellComponent` prop.
 * This component will measure the container grid's width and height and pass along the appropriate width
 * and height values to each cell.
 *
 * @remarks `grid-auto-rows` is initially set to 1fr so the heights of the cells can be dynamic based on the cell's
 * contents and the container element's rules. Once an automatic height has been obtained the rowHeight is calculated
 * based on the number of weeks in the month and explicitly set to that number of pixels so when a cell is expanded
 * it's larger size does not cause the entire row change with it. This is also why there are separate Grid wrapping components
 * for month and week/day views. We do not want to reuse the height of the other views when switching back to the month view.
 *
 * Note: Firefox will resize multiple times after setting `grid-auto-rows` to a pixel value. See comment in Grid about setting
 * of `max-height` value.
 *
 * @param props.date - the date for the calendar used to show the current month
 * @param props.CellComponent - the cell component to be used when rendering each day. Defaults to {@link DefaultDateCell}
 */
export const ExpandableCalendar = ({ date, CellComponent, ...rest }) => {
  const visibleDates = useVisibleDates(date);
  const remeasure = useRemeasureOnResize(350);
  const numRows = Math.ceil(visibleDates.length / DAYS_IN_WEEK);

  return (
    <Container {...rest}>
      <TopRow dates={visibleDates} />
      {remeasure && (
        <Grid numRows={numRows}>
          <MonthCells
            dates={visibleDates}
            calendarDate={date}
            Component={CellComponent}
            rowWidth={0}
            rowHeight={0}
          />
        </Grid>
      )}
      {!remeasure && (
        <MeasuredGrid
          numRows={numRows}
          dates={visibleDates}
          calendarDate={date}
          Component={CellComponent}
        />
      )}
    </Container>
  );
};

const MonthCalendar = ({ expandable = false, ...rest }) => {
  return expandable ? <ExpandableCalendar {...rest} /> : <Calendar {...rest} />;
};

export default MonthCalendar;

MeasuredGrid.displayName = 'MeasuredGrid';
MeasuredGrid.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  calendarDate: PropTypes.instanceOf(Date).isRequired,
  view: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  numRows: PropTypes.number.isRequired,
  Component: PropTypes.elementType.isRequired,
};
Calendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  CellComponent: PropTypes.elementType,
};
Calendar.defaultProps = {
  CellComponent: DefaultMonthCalendarCell,
};

ExpandableCalendar.propTypes = {
  ...Calendar.propTypes,
  expandable: PropTypes.bool,
};
ExpandableCalendar.defaultProps = {
  ...Calendar.defaultProps,
  expandable: false,
};

MonthCalendar.propTypes = ExpandableCalendar.propTypes;
MonthCalendar.defaultProps = ExpandableCalendar.defaultProps;
