import { useCallback, useRef } from 'react';
import PhoneNumberInput from '__components/Inputs/TextInput/presets/PhoneNumber';
import { InputProps } from './types';

type PhoneNumberProps = InputProps & {
  enableExtension: boolean;
  defaultCountry: string;
};

export const PhoneNumber = ({
  step,
  width,
  value,
  placeholder,
  onChange,
  error,
  enableExtension,
  defaultCountry,
}: PhoneNumberProps) => {
  // the PhoneNumberInput component is very chatty always calling onChange, so we need to debounce it
  const valueRef = useRef(value);
  const handleChange = useCallback(
    (val: string) => {
      if (val !== valueRef.current) {
        valueRef.current = val;
        onChange(step, val);
      }
    },
    [onChange, step]
  );

  return (
    <PhoneNumberInput
      value={value}
      width={width}
      defaultCountry={defaultCountry}
      setDefaultCountryCode
      placeholderNumber={placeholder}
      error={error}
      onChange={handleChange}
      enableExtension={enableExtension}
    />
  );
};
