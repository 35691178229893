import { useBreakpoint } from 'app/spacing';
import useDashboardSettingsBreakpoint from 'components/DashboardGrid/hooks/useDashboardSettingsBreakpint';
import DragAndDropLayout from 'components/DragAndDropLayout';
import Loader from 'components/Kizen/Loader';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DASHLET_ACTIONS } from '../constants';
import {
  AccessRequestsContentCard,
  AccessRequestsDragAndDropWrapper,
  AccessRequestsMobileContainer,
  AccessRequestsPagination,
  AccessRequestsSubsection,
  AccessRequestsTable,
  AccessRequestsTableHeader,
  AccessRequestsTHead,
} from '../styles';
import AccessRequestsToolbar from '../Toolbar';
import DraggableItem from 'components/AccessRequests/DraggableItem';
import KizenTypography from 'app/kizentypo';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

const AccessRequestTableOthers = ({
  goBackPath,
  data = [],
  handleShowModal,
  loading,
  currentPage,
  currentPageSize,
  setCurrentPage,
  setCurrentPageSize,
  paginationTotal,
  handleStartRequesting,
  isRefetching,
  entitySingular,
  entityPlural,
  getColumns,
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint();
  const parentRef = useRef(null);

  const columns = useMemo(() => getColumns(t), [t, getColumns]);

  const calculateWidth = useDashboardSettingsBreakpoint(columns);

  const handleAction = useCallback(
    (action, _id, dashboard) => {
      if (action === DASHLET_ACTIONS.REQUEST) {
        handleStartRequesting(dashboard);
      }
    },
    [handleStartRequesting]
  );
  return (
    <>
      <AccessRequestsToolbar
        goBackPath={goBackPath}
        count={data.length}
        handleShowModal={handleShowModal}
        afterChangeSearchTerm={() => setCurrentPage(1)}
        mobile={isMobile}
        loading={isRefetching}
        entitySingular={entitySingular}
        entityPlural={entityPlural}
      />
      <AccessRequestsContentCard shrink noScroll>
        {loading ? (
          <Loader loading />
        ) : (
          <>
            <AccessRequestsSubsection
              title={`${t('Other Team Member')} ${entityPlural}`}
              noScroll
            ></AccessRequestsSubsection>
            {!isMobile ? (
              <AccessRequestsTable noScroll>
                <AccessRequestsTHead>
                  <tr>
                    {columns.map((col) => {
                      return (
                        <AccessRequestsTableHeader
                          width={calculateWidth(col.width, col.shrinkable)}
                          key={col.id}
                          noScroll
                        >
                          <div>
                            <TextEllipsisWithTooltip>
                              {col.name}
                            </TextEllipsisWithTooltip>
                          </div>
                        </AccessRequestsTableHeader>
                      );
                    })}
                  </tr>
                </AccessRequestsTHead>
                <tbody>
                  <tr>
                    <td colSpan={columns.length}>
                      <AccessRequestsDragAndDropWrapper ref={parentRef}>
                        <DragAndDropLayout
                          items={data}
                          countItems={data.length}
                          itemElement={
                            <DraggableItem
                              handleAction={handleAction}
                              requestMode
                              calculateWidth={calculateWidth}
                              columns={columns}
                            />
                          }
                          checkDisableDrag={() => true}
                          parentRef={parentRef}
                          itemClassName="dndItemWrapper"
                          handleClassName="itemTarget"
                        />
                      </AccessRequestsDragAndDropWrapper>
                    </td>
                  </tr>
                </tbody>
              </AccessRequestsTable>
            ) : (
              <AccessRequestsMobileContainer>
                <KizenTypography>
                  {t(
                    'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
                  )}
                </KizenTypography>
              </AccessRequestsMobileContainer>
            )}
          </>
        )}
      </AccessRequestsContentCard>
      <AccessRequestsPagination
        page={currentPage}
        perPage={currentPageSize}
        onChangePage={setCurrentPage}
        onChangePerPage={setCurrentPageSize}
        totalCount={paginationTotal}
      />
    </>
  );
};

export default AccessRequestTableOthers;
