import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmationModal from '__components/Modals/ConfirmationModal';
import { UPLOAD_INPUT_TYPES } from '__components/Kizen/FileInputWithImage';
import {
  StyledFilePicker,
  StyledSwitch,
  StyledTestExplanation,
} from './styles';
import { IconWithTooltip } from '__pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import { ALLOWED_SOURCE_FILE_EXTENSIONS } from '../constants';

export type RunSmartConnectorModalProps = {
  show: boolean;
  skipDiffCheck: boolean;
  setSkipDiffCheck: Dispatch<SetStateAction<boolean>>;
  canSkipDiffCheck: boolean;
  onConfirm: () => Promise<void>;
  onHide: () => void;
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  isDryRun: boolean;
};

export const RunSmartConnectorModal = ({
  isDryRun = false,
  file,
  setFile,
  skipDiffCheck,
  setSkipDiffCheck,
  canSkipDiffCheck,
  ...modalProps
}: RunSmartConnectorModalProps) => {
  const { t } = useTranslation();
  const onChange = (files: File[]) => {
    if (files.length) {
      setFile(files[0]);
    }
    return null;
  };

  const handleChangeDiffChecking = (ev: ChangeEvent<HTMLInputElement>) =>
    setSkipDiffCheck(ev.target.checked);

  return (
    <ConfirmationModal
      heading={isDryRun ? t('Start Dry Run') : t('Process File')}
      buttonText={t('Start')}
      disabled={!isDryRun && !file}
      {...modalProps}
    >
      <StyledTestExplanation>
        {isDryRun ? (
          t(
            'This will execute all the steps in your SmartConnector and generate a workbook of changes and errors, if any. No changes will be uploaded to your data in Kizen.'
          )
        ) : (
          <Trans i18nKey="This will execute all the steps in your SmartConnector and insert or update <strong>live data</strong> in Kizen." />
        )}
      </StyledTestExplanation>
      <StyledFilePicker
        label={
          <>
            {isDryRun ? (
              <>
                {t('Upload test file')}{' '}
                <IconWithTooltip
                  label={t(
                    'Will use the SmartConnector input file reference if not provided.'
                  )}
                />
              </>
            ) : (
              t('Upload file')
            )}
          </>
        }
        onChange={onChange}
        onRemove={() => setFile(null)}
        file={file}
        showImage={false}
        type={UPLOAD_INPUT_TYPES.AVATAR}
        acceptedExtensions={ALLOWED_SOURCE_FILE_EXTENSIONS.toString()}
        id="upload-test-file"
      />
      {canSkipDiffCheck ? (
        <StyledSwitch
          onChange={handleChangeDiffChecking}
          checked={skipDiffCheck}
          removeMargin
          label={t('Skip Diff Check and Process All Rows')}
          textPlacement="regular-top"
        />
      ) : null}
    </ConfirmationModal>
  );
};
