import React, { useCallback, useRef, useMemo, useState } from 'react';
import { useScrollContainerContext } from 'components/Tables/ScrollContainer';
import { TABLE_HEADER_HEIGHT } from 'components/Tables/Basic';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';

export default function IconButtonMenuWithObserver({
  observableRootMargin,
  ...props
}) {
  const { containerElement } = useScrollContainerContext();
  const [entities, setEntities] = useState();
  const targetRef = useRef(null);

  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        if (entries[0]) {
          setEntities(entries[0]);
        }
      },
      {
        root: containerElement,
        rootMargin:
          observableRootMargin || `${TABLE_HEADER_HEIGHT}px 0px -40% 0px`,
        threshold: 1,
      }
    );
    // deps should also include container Element height when the container Element has a dynamic size
  }, [containerElement, containerElement?.clientHeight, observableRootMargin]); // eslint-disable-line react-hooks/exhaustive-deps

  const refCallback = useCallback(
    (node) => {
      if (containerElement) {
        if (targetRef.current && !node) {
          observer.unobserve(targetRef.current);
        }
        targetRef.current = node;
        if (node) {
          observer.observe(targetRef.current);
        }
      } else {
        targetRef.current = node;
      }
    },
    [containerElement, observer]
  );
  return (
    <IconButtonMenu
      ref={refCallback}
      isTopHalf={entities?.isIntersecting ?? true}
      {...props}
    />
  );
}
