import { ContentWidth, PageSizing } from 'components/Layout/PageContentWidth';
import { useAsync } from 'react-use';
import FieldService from 'services/FieldService';
import BuilderCard from '../../components/BuilderCard';
import CustomFieldsBuilderNoMemo from './Builder';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useCustomObjectWizard } from 'components/Wizards/CustomObject/CustomObjectWizardContext';
import SmallTableCard from 'components/Layout/SmallTableCard';
import CardSection from 'components/Layout/CardSection';
import { DynamicTagsManager } from 'components/Tables/DynamicTagsManager';
import { isContactTagsField, isContactTitlesField } from 'checks/fields';

const StyledBuilderCard = styled(BuilderCard)`
  padding-left: ${gutters.spacing(2)}px;
  padding-right: ${gutters.spacing(2)}px;
  padding-bottom: ${gutters.spacing(2)}px;
  h3 {
    padding-left: ${gutters.spacing(2)}px;
    padding-bottom: ${gutters.spacing(3, 1)}px;
  }
`;
export default function CustomizeFields({
  label,
  updateStepField,
  objectContainsWorkflow,
  formData,
  isCreateMode,
  errors,
  ...props
}) {
  const { isContact } = useCustomObjectWizard();
  const [showToast] = useToast();
  const { id } = formData;
  const { value: entityModel = {}, loading: modelLoading } =
    useAsync(async () => {
      try {
        return id && FieldService.getModel({ id });
      } catch (error) {
        showToast({
          variant: toastVariant.FAILURE,
          message: error?.message,
        });
      }
    }, [id, isContact]);

  const [{ value: rawCategories = [], loading }, updateFields] =
    useAsyncFnKeepLast(async () => {
      return isContact
        ? FieldService.getCategorizedFields({
            for: 'contacts',
            includeVisible: true,
            settingsRequest: true,
          })
        : id &&
            FieldService.getCategorizedFields({
              for: {
                ...{ ...formData, id },
                objectClass: 'custom_objects',
              },
              includeVisible: true,
              settingsRequest: true,
            });
    }, [formData, id, isContact]);

  useEffect(() => {
    updateFields();
  }, [updateFields]);

  const [tagsField, titlesField] = useMemo(() => {
    if (Array.isArray(rawCategories)) {
      return rawCategories.reduce((collect, { fields }) => {
        if (collect.length === 2 && collect.every(Boolean)) return collect;
        const tagsField = collect[0] || fields.find(isContactTagsField);
        const titlesField = collect[1] || fields.find(isContactTitlesField);
        return [tagsField, titlesField];
      }, []);
    }
    return [];
  }, [rawCategories]);

  return (
    <PageSizing>
      <StyledBuilderCard {...props} label={label} model={entityModel}>
        <CustomFieldsBuilderNoMemo
          model={entityModel}
          updateCategories={updateFields}
          rawCategories={rawCategories}
          loading={loading || modelLoading}
        />
      </StyledBuilderCard>
      {isContact ? (
        <CardSection as={ContentWidth}>
          <SmallTableCard>
            <DynamicTagsManager
              model={entityModel}
              field={tagsField}
              includeTitle
            />
          </SmallTableCard>
          <SmallTableCard>
            <DynamicTagsManager
              model={entityModel}
              field={titlesField}
              includeTitle
            />
          </SmallTableCard>
        </CardSection>
      ) : null}
    </PageSizing>
  );
}
