import React, { useCallback, useContext, useMemo } from 'react';
import { AREA_RESPONSES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { useTranslation } from 'react-i18next';
import Select from 'components/Inputs/Select';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';

const MarketingArea = ({ disabled }) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);
  const { area, reportType } = state;
  const preReleaseFeatures = usePreReleaseFeatures();

  const dashletReportTypes = useMemo(() => {
    const opts = [
      {
        label: t('Leads Added by Source'),
        value: REPORT_TYPES.LEADS_ADDED_BY_SOURCE,
        enabled: true,
      },
      {
        label: t('Leads Added'),
        value: REPORT_TYPES.LEADS_ADDED,
        enabled: true,
      },
      {
        label: t('Lead Source Breakdown Over Time'),
        value: REPORT_TYPES.LEAD_SOURCE_BREAKDOWN,
        enabled: true,
      },
    ];

    return opts
      .filter((opt) => opt.enabled || preReleaseFeatures)
      .sort(({ label: nameA }, { label: nameB }) => {
        return nameA.localeCompare(nameB);
      });
  }, [t, preReleaseFeatures]);

  const reportTypeValue = useMemo(() => {
    if (reportType?.value) {
      return {
        value: reportType.value,
        label: dashletReportTypes.find((r) => r.value === reportType?.value)
          ?.label,
      };
    }
    return reportType;
  }, [reportType, dashletReportTypes]);

  const reportTypeLabel = t('Choose Report Type');

  const reportTypeOnChange = useCallback(
    (value) => {
      return setState('reportType', value);
    },
    [setState]
  );

  // Hide self if the area is not marketing
  if (area !== AREA_RESPONSES.MARKETING) {
    return null;
  }

  return (
    <StyledWizardField>
      <KizenTypography type="subheader">{reportTypeLabel}</KizenTypography>
      <Select
        value={reportTypeValue}
        onChange={reportTypeOnChange}
        captureMenuScroll={false}
        className="modal-select report-type"
        placeholder={reportTypeLabel}
        options={dashletReportTypes}
        disabled={disabled}
      />
    </StyledWizardField>
  );
};

export default MarketingArea;
