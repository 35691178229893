import { FIELD_TYPES } from 'utility/constants';
import { invalidate } from 'queries/invalidate';
import AxiosService from './AxiosService';
import {
  FORCE_ALL_RECORDS_SIZE,
  camelToSnakeCaseKeys,
  snakeToCamelCaseKeys,
  fieldForApp,
  formatValueForFieldInput,
} from './helpers';
import i18n from 'i18n-config';

export const ACTIVITY_NOTIFICATION_EMAIL = 'email';
export const ACTIVITY_NOTIFICATION_SMS = 'text';

const stripNulls = (obj) => {
  obj = { ...obj };
  Object.entries(obj).forEach(([key, value]) => {
    if (value === null) {
      delete obj[key];
    }
  });
  return obj;
};

class ActivityService {
  /*
   * Singleton ES6 pattern
   * https://www.sitepoint.com/javascript-design-patterns-singleton/
   */
  constructor() {
    if (!ActivityService.instance) {
      ActivityService.instance = this;
    }
    return ActivityService.instance;
  }

  objectEndpoint = 'activities';
  notFoundError = (error) => {
    return error.response?.status === 404
      ? {
          ...error,
          response: {
            ...error.response,
            data: {
              ...error.response.data,
              message: i18n.t(
                'Scheduled activity not found (completed or deleted).'
              ),
            },
          },
        }
      : error;
  };

  getActivities = async ({ objectId } = {}) => {
    const {
      data: { results },
    } = await AxiosService.get(`/activities`, {
      params: {
        custom_object_id: objectId,
        details: 'light',
        ordering: 'name',
        page_size: FORCE_ALL_RECORDS_SIZE, // Despite the endpoint being paginated, we'd like to fetch all automations at once
      },
    });
    return results;
  };

  addNote = async (params) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/add-note`,
      camelToSnakeCaseKeys(params)
    );
    invalidate.TIMELINE.ALL();
    return data;
  };

  schedule = async ({ activityType, assignedTo, notifications, ...others }) => {
    const { data } = await AxiosService.post(
      '/scheduled-activity',
      stripNulls({
        assigned_to: assignedTo,
        activity_type: activityType,
        notifications: notifications.map((n) => ({
          notification_type: n.notificationType,
          delay_amount: n.delayAmount,
          delay_interval: n.delayInterval,
        })),
        ...others,
      })
    );
    return data;
  };

  exportFields = async (activityId) => {
    await AxiosService.post(
      `/${this.objectEndpoint}/${activityId}/export/fields`
    );
  };

  v2CreateActivity = async ({ name = null, defaultField = null } = {}) => {
    const { data: activity } = await AxiosService.post(
      `/${this.objectEndpoint}`,
      {
        ...(name && { name }),
      }
    );

    invalidate.ACTIVITIES.ALL();

    if (defaultField) {
      const { id: activityObjectId } = activity;
      const field = await ActivityService.instance.v2CreateField({
        activityObjectId,
        ...defaultField,
      });
      activity.fields = [field];
    }
    return snakeToCamelCaseKeys(activity);
  };

  v2DeleteActivity = async ({ activityObjectId }) => {
    try {
      const { data } = await AxiosService.delete(
        `/${this.objectEndpoint}/${activityObjectId}`,
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      invalidate.ACTIVITIES.ALL();
      return snakeToCamelCaseKeys(data);
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  v2GetActivityList = async ({ search, page, size, ordering } = {}) => {
    const params = {
      search,
      page: page || 1,
      page_size: size || 50,
      ordering,
    };
    const { data } = await AxiosService.get(`/${this.objectEndpoint}`, {
      params: camelToSnakeCaseKeys(params),
    });
    return data;
  };

  v2GetActivityFullList = async (params) => {
    const getData = async (params) => {
      const { data } = await AxiosService.get(`/${this.objectEndpoint}`, {
        params: camelToSnakeCaseKeys(params),
      });
      return data.next
        ? [
            ...snakeToCamelCaseKeys(data.results),
            ...(await getData({ ...params, page: params.page + 1 })),
          ]
        : snakeToCamelCaseKeys(data.results);
    };

    return await getData({ ...params, page: 1 });
  };

  v2GetActivities = async (options) => {
    const { data } = await AxiosService.get(`/${this.objectEndpoint}`, options);
    return snakeToCamelCaseKeys(data);
  };

  v2GetActivityAndFields = async (...args) => {
    const [fields, activity] = await Promise.all([
      ActivityService.instance.getObjectFields(...args),
      ActivityService.instance.v2GetActivity(...args),
    ]);

    return {
      ...activity,
      fields,
    };
  };

  v2GetActivity = async ({ activityObjectId }, opts) => {
    if (!activityObjectId) {
      // TODO (keegandonley): Why is there no ID here sometimes? react-query is calling this with no ID
      // even when the query is disabled
      return {};
    }

    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${activityObjectId}`,
      opts
    );
    return {
      ...snakeToCamelCaseKeys(data),
      loggableSharingSettings: { ...data.loggable_sharing_settings },
    };
  };

  getObjectFields = async ({ activityObjectId }, ops) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${activityObjectId}/fields`,
      ops
    );
    const fields = snakeToCamelCaseKeys(
      data.sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10))
    );

    return snakeToCamelCaseKeys(
      fields.map(({ fieldType, options, ...field }) => {
        if (fieldType === FIELD_TYPES.DynamicTags.type) {
          return {
            ...field,
            fieldType,
            options: options.map(({ code, ...opt }) => ({
              ...opt,
              ...(code !== '' && { code }),
            })),
          };
        }
        return { ...field, fieldType, options };
      })
    );
  };

  updateObjectStyles = async ({ id: activityObjectId, ...rest }) => {
    const { fields } = camelToSnakeCaseKeys(rest);

    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${activityObjectId}/update-styles`,
      {
        fields,
      }
    );
    return snakeToCamelCaseKeys(data);
  };

  v2UpdateActivity = async ({ id, ...rest }) => {
    try {
      const { data } = await AxiosService.patch(
        `/${this.objectEndpoint}/${id}`,
        camelToSnakeCaseKeys({
          id,
          ...rest,
        }),
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );

      return {
        ...snakeToCamelCaseKeys({
          ...data,
        }),
        loggableSharingSettings: { ...data.loggable_sharing_settings },
      };
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  v2CreateField = async ({ activityObjectId, ...rest }) => {
    try {
      const { data } = await AxiosService.post(
        `/${this.objectEndpoint}/${activityObjectId}/fields`,
        camelToSnakeCaseKeys(rest),
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      invalidate.ACTIVITIES.ACTIVITY_FIELDS(activityObjectId);
      return snakeToCamelCaseKeys(data);
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  v2UpdateField = async ({ activityObjectId, id, ...rest }) => {
    try {
      const { data } = await AxiosService.patch(
        `/${this.objectEndpoint}/${activityObjectId}/fields/${id}`,
        camelToSnakeCaseKeys(rest),
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      invalidate.ACTIVITIES.ACTIVITY_FIELDS(activityObjectId);
      return snakeToCamelCaseKeys(data);
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  v2DeleteField = async ({ activityObjectId, id }) => {
    try {
      const data = await AxiosService.delete(
        `/${this.objectEndpoint}/${activityObjectId}/fields/${id}`,
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      invalidate.ACTIVITIES.ACTIVITY_FIELDS(activityObjectId);
      return snakeToCamelCaseKeys(data);
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  /**
   * Use me only on Activity Settings Page
   */
  v2GetRelatedDefaultOnActivities = async () => {
    const { data } = await AxiosService.get(
      '/custom-objects/settings-search?default_on_activities=true'
    );
    return snakeToCamelCaseKeys(data);
  };

  v2CompleteActivity = async ({ activityObjectId, payload }) => {
    try {
      const { data } = await AxiosService.post(
        `/${this.objectEndpoint}/${activityObjectId}/log-activity`,
        camelToSnakeCaseKeys(payload),
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      invalidate.TIMELINE.ALL();
      return snakeToCamelCaseKeys(data);
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  v2ExportActivities = async ({ activityObjectId }) => {
    try {
      const { data } = await AxiosService.post(
        `/${this.objectEndpoint}/${activityObjectId}/export-responses`,
        {},
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  v2DuplicateActivity = async ({ activityObjectId }) => {
    try {
      const { data } = await AxiosService.post(
        `/${this.objectEndpoint}/${activityObjectId}/duplicate`,
        {},
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };
  v2GetActivitySubscribers = async ({ activityObjectId }) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${activityObjectId}/subscribers`
    );
    return snakeToCamelCaseKeys(data);
  };

  v2CreateActivitySubscribers = async ({
    activityObjectId,
    newSubscribers,
  }) => {
    try {
      const { data } = await AxiosService.post(
        `/${this.objectEndpoint}/${activityObjectId}/subscribers`,
        camelToSnakeCaseKeys(newSubscribers),
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      return snakeToCamelCaseKeys(data);
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  v2DeleteActivitySubscriber = async ({ activityObjectId, subscriptionId }) => {
    try {
      const { data } = await AxiosService.delete(
        `/${this.objectEndpoint}/${activityObjectId}/subscribers/${subscriptionId}`,
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      return snakeToCamelCaseKeys(data);
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  handleClientinAssociated = (data) => {
    // we search through the associatedEntities to see if there is a "Contact"
    // if there is one add it to the object so we don't constantly need to do this
    const ret = data.map((sa) => {
      const clients = sa.associatedEntities.filter(
        (ae) => ae.objectName === 'Contacts'
      );
      if (clients && clients.length) {
        const { entityId: id, displayName: fullName } = clients[0];
        return { ...sa, client: { id, fullName } };
      }
      return sa;
    });
    return ret;
  };

  v2GetScheduledActivities = async (
    { params },
    teamFilters,
    roleFilters,
    dashletId,
    config
  ) => {
    const {
      assignedToMe,
      completed,
      fromDate,
      toDate,
      ordering = '-due_datetime', // defaulted sorting value
      page,
      pageSize,
      search,
    } = params;

    const url = `/activities/scheduled-activity/search?completed=${Boolean(
      completed
    )}${ordering ? `&ordering=${ordering}` : ''}${page ? `&page=${page}` : ''}${
      pageSize ? `&page_size=${pageSize}` : ''
    }&assigned_to_me=${Boolean(assignedToMe)}${
      dashletId ? `&dashlet_id=${dashletId}` : ''
    }${search ? `&search=${encodeURIComponent(search)}` : ''}`;

    const {
      data: { results, next, previous, count },
    } = await AxiosService.post(
      url,
      {
        from_date: fromDate,
        to_date: toDate,
        employee_ids:
          teamFilters && teamFilters.length > 0 ? teamFilters : undefined,
        role_ids: roleFilters && roleFilters.length ? roleFilters : undefined,
      },
      config
    );
    const data = snakeToCamelCaseKeys(results);

    return {
      results: this.handleClientinAssociated(data),
      next,
      previous,
      count,
    };
  };

  v2GetContactScheduledActivities = async ({
    params: paramsProp,
    skipErrorBoundary = false,
  }) => {
    const { clientId, ...params } = paramsProp;
    const {
      data: { results, count, next, previous },
    } = await AxiosService.get(`/client/${clientId}/scheduled-activities`, {
      params: camelToSnakeCaseKeys(params),
      skipErrorBoundary,
    });
    const data = snakeToCamelCaseKeys(results);

    return {
      results: this.handleClientinAssociated(data),
      next,
      previous,
      count,
    };
  };

  v2GetCustomScheduledActivities = async ({
    params: paramsProp,
    skipErrorBoundary = false,
  }) => {
    const { objectId, entityId, ...params } = paramsProp;

    const {
      data: { results, count, next, previous },
    } = await AxiosService.get(
      `/custom-objects/${objectId}/entity-records/${entityId}/scheduled-activities`,
      {
        params: camelToSnakeCaseKeys(params),
        skipErrorBoundary,
      }
    );
    const data = snakeToCamelCaseKeys(results);
    return {
      results: this.handleClientinAssociated(data),
      count,
      next,
      previous,
    };
  };

  v2CreateScheduledActivities = async (payload) => {
    delete payload.dueTime;
    delete payload.dueDate;
    const { data } = await AxiosService.post(
      `/activities/scheduled-activity`,
      camelToSnakeCaseKeys(payload)
    );
    invalidate.TIMELINE.ALL();
    return snakeToCamelCaseKeys(data);
  };

  v2UpdateScheduledActivities = async ({ id, ...payload }) => {
    try {
      const { data } = await AxiosService.patch(
        `/activities/scheduled-activity/${id}`,
        camelToSnakeCaseKeys(payload),
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      invalidate.TIMELINE.ALL();
      return this.handleClientinAssociated([snakeToCamelCaseKeys(data)]);
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  v2DeleteScheduledActivities = async (id) => {
    try {
      const { data } = await AxiosService.delete(
        `/activities/scheduled-activity/${id}`,
        {
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      );
      invalidate.TIMELINE.ALL();
      return data;
    } catch (error) {
      return Promise.reject(this.notFoundError(error));
    }
  };

  getObjectField = async (id, objectId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/fields/${id}`
    );
    return fieldForApp(data);
  };

  createDynamicTag = async ({ fieldId, name, objectId }, options) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options`,
      {
        name,
      },
      options
    );
    return data;
  };

  deleteDynamicTag = async ({ fieldId, tagId, objectId }, options) => {
    await AxiosService.delete(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${tagId}`,
      options
    );
  };

  updateDynamicTag = async ({ fieldId, tagId, objectId, name }, options) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${tagId}`,
      {
        name,
      },
      options
    );
    return data;
  };

  replaceDynamicTag = async (
    origTagId,
    replacementTagId,
    fieldId,
    objectId
  ) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${origTagId}/replace`,
      camelToSnakeCaseKeys({ optionId: replacementTagId })
    );
    return data;
  };

  getDynamicTagsOptions = async (fieldId, objectId, page, search, options) => {
    const { data } = await AxiosService.get(
      `/${
        this.objectEndpoint
      }/${objectId}/fields/${fieldId}/tags?ordering=name&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}`,
      options
    );
    return data;
  };

  getDynamicTagsOptionsOrdered = async (
    fieldId,
    objectId,
    page,
    search,
    ordering = 'name',
    options
  ) => {
    const { data } = await AxiosService.get(
      `/${
        this.objectEndpoint
      }/${objectId}/fields/${fieldId}/options?ordering=${ordering}&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}&include_entity_count=true`,
      options
    );
    return data;
  };

  getEntityRecordsFieldValues = async (objectId, recordId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/entity-field-values/${
        typeof recordId === 'object' ? recordId[0].id : recordId
      }`
    );
    return snakeToCamelCaseKeys(data)?.map(formatValueForFieldInput);
  };

  getPrimaryAssociations = async (activityId, recordId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${activityId}/entity-primary-associations/${recordId}`
    );

    return data;
  };
}

const instance = new ActivityService();

export default instance;
