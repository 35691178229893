import { useCallback } from 'react';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { canEditTemplate } from 'pages/Account/pages/Toolbar/utils';
import TextInputInline from 'components/Inputs/inline/TextInput';
import { useDynamicError } from 'hooks/useDynamicError';

const TemplateNameCell = ({ value, data, onChange, error }) => {
  const editable = canEditTemplate(data);
  const errorProps = useDynamicError(error?.[0]);

  const handleSubmit = useCallback(
    (val) => {
      onChange(val, data.id);
    },
    [onChange, data.id]
  );

  if (editable) {
    return (
      <TextInputInline value={value} onSubmit={handleSubmit} {...errorProps} />
    );
  }
  return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
};

export default TemplateNameCell;
