import { createContext, useCallback, useState } from 'react';

interface ActionGroupContextType {
  openMenu: string;
  setOpenMenu: (menu: string) => void;
}

export const ActionGroupContext = createContext<ActionGroupContextType>({
  openMenu: '',
  setOpenMenu: () => null,
});

export interface ActionGroupContextProviderProps {
  children: any;
}

export const ActionGroupContextProvider = (
  props: ActionGroupContextProviderProps
) => {
  const { children } = props;
  const [openMenu, setOpenMenu] = useState('');

  const handleSetOpenMenu = useCallback((menu: string) => {
    setOpenMenu(menu);
  }, []);

  return (
    <>
      <ActionGroupContext.Provider
        value={{
          openMenu,
          setOpenMenu: handleSetOpenMenu,
        }}
      >
        {children}
      </ActionGroupContext.Provider>
    </>
  );
};
