import { ForwardedRef, Ref } from 'react';

export const applyRef = <T extends HTMLElement = HTMLElement>(
  ref: ForwardedRef<T>,
  node: T
) => {
  if (ref) {
    if (typeof ref === 'function') ref(node);
    else ref.current = node;
  }
};

export const findAncestorByClassname = (
  element: Element | null,
  className: string
): Element | Document | null => {
  let currentElement = element;

  while (currentElement) {
    if (currentElement.classList.contains(className)) {
      return currentElement;
    }

    // If the element's parent is null, it means we've reached the top of the document
    if (!currentElement.parentElement) {
      return document;
    }

    currentElement = currentElement.parentElement;
  }

  return null;
};

export const getKDSClasses = (name: string, subName?: string) => {
  return merge('kds', `kds-${name}`, subName && `kds-${name}-${subName}`);
};

export const isDescendant = (
  parent: HTMLElement | null,
  child: EventTarget | null
): boolean => {
  let node = child as Node | null;
  while (node) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export const merge = (...classList: (string | boolean | undefined)[]) => {
  return classList
    .filter((x) => typeof x === 'string' && x.length > 0)
    .join(' ');
};

export const randomID = (
  len = 12,
  prefix = '',
  syms = 'abcdefghijklmnopqrstuvwxyz'
) => {
  for (; len-- > 0; ) {
    const rand = (Math.random() * 0x1_0000_0000) /* 2**32 */ >>> 0;
    prefix += syms[rand % syms.length];
  }
  return prefix;
};

export const mergeRefs = (...refs: Ref<any>[]) => {
  return (node: any) => {
    refs.forEach((ref) => {
      applyRef(ref, node);
    });
  };
};

export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func(...args), wait);
  };
};

export const getQaAttributes = (qa?: Record<string, any>) => {
  if (!qa) return {};

  return Object.keys(qa).reduce((acc, key) => {
    return {
      ...acc,
      [`data-qa${key !== '' ? '-' : ''}${key}`]: qa[key],
    };
  }, {});
};
