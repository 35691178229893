import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TablePagination from 'components/Tables/Big/TablePagination';
import ListCard from 'components/Layout/ListCard';
import BigListLayout, { BigListTitle } from 'components/Layout/BigListLayout';
import { BigListSearchInput } from 'components/Layout/BigListLayout/Toolbar';
import {
  CustomObjectListItem,
  CustomObjectListHeader,
  EmptyCustomObjectListItem,
} from './ListItem';
import { CUSTOM_OBJECTS_ACTIONS } from './columns';
import { getCustomObjectsIsFilteringSelector } from 'store/customObjectsPage/customObjects.redux';

export default function CustomObjectListPage({
  customObjects,
  customObjectsCount,
  search,
  onChangeSearch,
  sort,
  onChangeSort,
  onSelectDeleteCustomObject,
  pageNumber,
  pageSize,
  onChangePageNumber,
  onChangePageSize,
  ...others
}) {
  const { t } = useTranslation();
  const isFiltering = useSelector(getCustomObjectsIsFilteringSelector);
  const handleCheckedSelection = (selection, customObject) => {
    if (selection.value === CUSTOM_OBJECTS_ACTIONS.Delete) {
      onSelectDeleteCustomObject(customObject.id);
    }
  };
  return (
    <BigListLayout
      search={
        <BigListSearchInput
          placeholder={t('Find Objects')}
          value={search}
          onChange={onChangeSearch}
          loading={isFiltering}
          isClearable
        />
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={customObjectsCount}
          onChangePage={onChangePageNumber}
          onChangePerPage={onChangePageSize}
        />
      }
      {...others}
    >
      <BigListTitle
        count={customObjectsCount}
        single={t('Object')}
        plural={t('Objects')}
      />
      <ListCard>
        <CustomObjectListHeader
          items={[
            {
              label: t('Object Name'),
              sortColumn: 'model_name',
            },
            { label: t('Contains Workflow'), sortColumn: 'object_type' },
          ]}
          sort={sort}
          onChangeSort={(direction, sortColumn) =>
            onChangeSort({ column: sortColumn, direction })
          }
        />
        {!customObjects.length && <EmptyCustomObjectListItem />}
        {customObjects.map((customObject) => (
          <CustomObjectListItem
            key={customObject.id}
            customObject={customObject}
            onSelectAction={(selection) =>
              handleCheckedSelection(selection, customObject)
            }
          />
        ))}
      </ListCard>
    </BigListLayout>
  );
}

CustomObjectListPage.propTypes = {
  customObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  customObjectsCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onSelectDeleteCustomObject: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
};
