import { paths } from 'schema';

export type TextAugmentationRequest =
  paths['/api/ml/text-augmentation']['post']['requestBody']['content']['application/json'];

type TextAugmentationOptions = {
  signal?: AbortController['signal'];
};

const curry = <R, T extends any[] = any[]>(
  fn: (baseUrl: string, ...rest: T) => Promise<R>
) => {
  return (baseUrl: string) => {
    return (...rest: T) => {
      return fn(baseUrl, ...rest);
    };
  };
};

export const textAugmentation = async (
  baseUrl: string,
  businessId: string,
  request: TextAugmentationRequest,
  opts?: TextAugmentationOptions
): Promise<AsyncIterable<string>> => {
  const response = await fetch(`${baseUrl}/ml/text-augmentation`, {
    method: 'POST',
    body: JSON.stringify(request),
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
      'x-business-id': businessId,
    },
    signal: opts?.signal,
  });

  return {
    async *[Symbol.asyncIterator]() {
      if (!request.is_streaming) {
        const json = await response.json();
        yield json;
        return;
      }

      if (!response.body) {
        return;
      }

      const reader = await response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const next = await reader.read();
        done = next.done;

        if (next.value) {
          const text = decoder.decode(next.value);
          yield text;
        }
      }
    },
  };
};

export class MLService {
  public textAugmentation;

  constructor(baseUrl: string) {
    this.textAugmentation = curry(textAugmentation)(baseUrl);
  }
}
