import { useEffect, useRef } from 'react';
import { useMeasure } from 'react-use';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useViewerContext } from '../viewer/ViewerContext';
import { useLoadNonWebFont } from '../hooks/useLoadNonWebFont';
import { shadow } from '../internal/app/colors';
import { scrollbarCss } from '../internal/app/spacing';
import { getStaticBlockWidthFromColumns } from './utils';

export const DEFAULT_BACKGROUND = '#FFFFFF';
const DEFAULT_WIDTH = '100';
const DEFAULT_MAX_WIDTH = '900';
const DEFAULT_ALIGNMENT = 'center';
export const DEFAULT_EMAIL_ROOT_PROPS = {
  backgroundColor: DEFAULT_BACKGROUND,
  width: DEFAULT_WIDTH,
  maxWidth: DEFAULT_MAX_WIDTH,
  alignment: DEFAULT_ALIGNMENT,
  mobileBreak: '414',
  color: '#4A5660',
  fontFamily: 'Arial',
  fontSize: '14',
  linkColor: '#528EF9',
};
export const DEFAULT_FORM_ROOT_PROPS = {
  ...DEFAULT_EMAIL_ROOT_PROPS,
  tabletBreak: '768',
};

const alignSelf = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const RootWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
  }
  width: ${({ width = DEFAULT_WIDTH }) => `${width}%`};
  max-width: ${({ maxWidth = DEFAULT_MAX_WIDTH }) => `${maxWidth}px`};
  word-break: break-word;
  ${({ removeRootBackground, backgroundColor = DEFAULT_BACKGROUND }) =>
    removeRootBackground !== true &&
    css`
      background-color: ${backgroundColor};
    `}
  align-self: ${({ alignment = DEFAULT_ALIGNMENT }) => alignSelf[alignment]};
  p,
  .InputControl > label,
  :not(a) > span, // do not override text links
  label span, // radio/checkbox options
  button span {
    // rating, yes/no text
    color: ${({ color }) => color};
    font-family: ${({ fontFamily }) => fontFamily};
  }
  li::marker {
    color: ${({ color }) => color};
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: ${({ fontSize = 14 }) => `${fontSize}px`};
  }
  .InputControl > label {
    font-size: ${({ fontSize = 14 }) => `${fontSize}px`};
    overflow: visible;
  }
  font-size: ${({ fontSize = 10 }) => `${fontSize}px`};
  ${({ hasChildren }) =>
    !hasChildren &&
    css`
      padding: 10px;
    `};
  .file-input-label {
    ${({ linkColor }) =>
      linkColor &&
      css`
        color: ${linkColor};
      `}
  }
  ${({ borderRadius }) =>
    Boolean(borderRadius) &&
    css`
      border-radius: ${borderRadius};
    `}
`;

export const StaticBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
  ${({ canResize = false }) => canResize && scrollbarCss}
  ${({ hasShadow = false }) => hasShadow && shadow}
  ${({ canResize = false, height, fullHeight = false }) =>
    fullHeight
      ? 'height: 100%;'
      : canResize
        ? css`
            height: ${height}px;
          `
        : css`
            min-height: 100%;
          `}
  max-width: ${({ columns = 6, fullWidth = false }) =>
    !fullWidth && `${getStaticBlockWidthFromColumns(columns)}px`};
  ${({ borderRadius }) =>
    Boolean(borderRadius) &&
    css`
      border-radius: ${borderRadius};
    `}
`;

/**
 * A static block root node can be thought of as having two root nodes - the standard
 * root node where page settings are set and an additional outer div for the "block"
 * component. `backgroundColor` is the only "page setting" that is now set on the
 * block component. New static block specific props like `columns` and `hasShadow` are
 * set on the block component.
 */
export const StaticBlockRoot = ({
  children,
  columns,
  hasShadow,
  height,
  fullHeight = true,
  fullWidth = true,
  ...others
}) => {
  const [measureRef, { width }] = useMeasure();
  const ctx = useViewerContext();
  const contentRef = useRef(null);
  const { setRootWidth, rootBorderRadius, rootNode, setContentHeight } = ctx;

  useLoadNonWebFont(others.fontFamily);

  useEffect(() => {
    setRootWidth(width);
  }, [width, setRootWidth]);

  useEffect(() => {
    const elem = contentRef.current;
    const observer = new ResizeObserver((entries) => {
      setContentHeight?.(entries[0].target.getBoundingClientRect().height);
    });

    observer.observe(elem);

    return () => {
      observer.unobserve(elem);
    };
  }, [setContentHeight]);

  return (
    <StaticBlock
      ref={measureRef}
      backgroundColor={rootNode?.props?.backgroundColor}
      canResize={true} // can't actually resize the view component - just want the same styles applied
      hasShadow={false} // static block box shadows are added on the parent Tile components used on the record details page.
      height={height}
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      columns={columns}
      borderRadius={rootBorderRadius}
    >
      <RootWrapper
        hasChildren
        removeRootBackground
        linkColor={rootNode?.data?.props?.linkColor}
        ref={contentRef}
        borderRadius={rootBorderRadius}
        {...others}
      >
        {children}
      </RootWrapper>
    </StaticBlock>
  );
};

export const RootView = ({ children, ...others }) => {
  const [measureRef, { width }] = useMeasure();
  const { setRootWidth, removeRootBackground, rootNode } = useViewerContext();

  useLoadNonWebFont(others.fontFamily);

  useEffect(() => {
    setRootWidth(width);
  }, [width, setRootWidth]);

  return (
    <RootWrapper
      hasChildren
      ref={measureRef}
      backgroundColor={rootNode?.data?.props?.backgroundColor}
      linkColor={rootNode?.data?.props?.linkColor}
      removeRootBackground={removeRootBackground}
      {...others}
    >
      {children}
    </RootWrapper>
  );
};
