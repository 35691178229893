import useReactQueryTypeahead from 'components/Inputs/Select/useReactQueryTypeahead';
import { CUSTOM_OBJECTS } from 'queries/query-keys';
import { useContext, useMemo } from 'react';
import CustomObjectsService from 'services/CustomObjectsService';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import { useSelector } from 'react-redux';

const useObjectOptions = (options = {}) => {
  const { enabled = true, useClient = false, clientObjectId = '' } = options;
  const { state } = useContext(WizardStateContext.Context);
  const { objectsWithLeads } = state;
  const access = useSelector((s) => s.authentication.access);

  const {
    selectProps: objectSelectProps,
    results,
    isLoading,
  } = useReactQueryTypeahead(
    (args) =>
      CustomObjectsService.getCustomObjectList({
        ...args,
        customOnly: false,
      }),
    CUSTOM_OBJECTS.LIST,
    {
      useClient,
      enabled,
    }
  );

  const permissionOptions = useMemo(() => {
    const objectAccess = access.custom_objects?.custom_object_entities ?? {};
    const contactAccess = access.sections?.contacts_section?.view ?? false;
    return (objectSelectProps.options ?? []).filter((o) => {
      if (o.value === clientObjectId) {
        return contactAccess;
      }
      return objectAccess[o.value]?.enabled;
    });
  }, [objectSelectProps.options, access, clientObjectId]);

  useMetadataKey('objectsLoading', isLoading);

  const selectedObjectsMetadata = useMemo(() => {
    const ids =
      objectsWithLeads?.map((o) => (typeof o === 'string' ? o : o.value)) ?? [];
    const toEvaluate = results.filter(({ id }) => ids.includes(id));

    return toEvaluate;
  }, [objectsWithLeads, results]);

  useMetadataKey('selectedObjectsMetadata', selectedObjectsMetadata);

  return {
    ...objectSelectProps,
    options: permissionOptions,
    isLoading,
    selectedObjectsMetadata,
  };
};

export default useObjectOptions;
