import React, { useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useField from 'hooks/useField';
import * as PropTypes from 'prop-types';
import { useFlashTransition } from 'hooks/useFlashState';
import Overlay from 'react-bootstrap/Overlay';

import KizenErrorCard from 'components/Kizen/ErrorCard';
import styled from '@emotion/styled';
import { KizenTypography } from 'app/typography';

import SelectInline from 'components/Inputs/inline/Select';
import Select from 'components/Inputs/Select';
import ActionCell from 'components/Tables/ActionCell';

import { layers } from 'app/spacing';
import {
  ItemWrapper,
  ItemCell,
  StyledSwitch,
  EditableTextWrapper,
  EditableText,
  PaddedItemCell,
} from '../styles';
import { frequencyOptions, toOption, toValue } from './helpers';
import columns from './columns';
import WYSIWYGTextInline from 'components/Inputs/inline/WYSIWYG';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';

export const subscriptionActions = (t) => ({
  edit: { value: 'edit', label: t('Edit') },
  delete: { value: 'delete', label: t('Delete') },
});

const ErrorCard = styled(KizenErrorCard)`
  z-index: ${layers.content(0, 2)};
  margin-top: 3px;
`;

const ListItem = ({
  element,
  onUpdate,
  onAction,
  setForceToTop,
  uniqueNames,
  canDelete,
}) => {
  const { t } = useTranslation();

  const headerRef = useRef();
  const [apiError, showApiError, flashApiError] = useFlashTransition();
  const [staged, setStaged] = useField(() => element, [element]);
  const [nameError, setNameError] = useState(false);
  const onSelectAction = ({ value }) => onAction({ value, element });

  const localizedColumns = useMemo(() => columns(t), [t]);

  const validateName = (name) => {
    if (name === '') {
      flashApiError(t('The List Name is required.'));
      return true;
    }

    if (element.name !== name && uniqueNames.includes(name)) {
      flashApiError(t('The List Name must be unique.'));
      return true;
    }

    return false;
  };

  const handleNameChange = (name) => {
    setStaged({ ...staged, name });
    setNameError(validateName(name));
  };

  const actions = useMemo(() => {
    return Object.values(subscriptionActions(t)).filter(
      ({ value }) => value !== 'delete' || canDelete
    );
  }, [t, canDelete]);

  const [{ onBlur: onNameBlur, ...nameTooltipProps }, nameTooltip] =
    useTruncationTooltip();
  const [descriptionTooltipProps, descriptionTooltip] = useTruncationTooltip();

  const handleNameBlur = () => {
    onNameBlur?.();
    const error = validateName(staged.name);
    if (!error) {
      onUpdate(element, { name: staged.name });
    } else {
      setStaged({ ...staged, name: element.name });
      setNameError(false);
    }
  };

  return (
    <ItemWrapper>
      <ItemCell
        width={localizedColumns.name.width}
        fixed={localizedColumns.name.fixed}
      >
        <EditableTextWrapper ref={headerRef}>
          {nameTooltip}
          <EditableText
            sizing="dense"
            placeholder={t('Enter List Name')}
            value={staged.name}
            error={nameError}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            {...nameTooltipProps}
          />
        </EditableTextWrapper>
        <Overlay
          transition={false}
          target={headerRef.current}
          show={!!apiError}
          placement="bottom-start"
          popperConfig={{
            modifiers: {
              offset: { offset: '0, -5px' }, // Touch top of text
              preventOverflow: { enabled: false },
            },
          }}
        >
          <ErrorCard show={showApiError} duration="300ms">
            <KizenTypography>{apiError}</KizenTypography>
          </ErrorCard>
        </Overlay>
      </ItemCell>
      <ItemCell
        width={localizedColumns.description.width}
        fixed={localizedColumns.description.fixed}
      >
        {descriptionTooltip}
        <WYSIWYGTextInline
          value={element.description}
          onSubmit={(description) => {
            onUpdate(element, { description });
          }}
          emptyPlaceholder=""
          {...descriptionTooltipProps}
        />
      </ItemCell>
      <ItemCell
        width={localizedColumns.frequency.width}
        fixed={localizedColumns.frequency.fixed}
      >
        <SelectInline hoverable={false}>
          <Select
            value={toOption(element.frequency)}
            options={frequencyOptions(t)}
            placeholder={t('Choose Frequency')}
            onChange={({ value: frequency, label: frequencyDisplay }) => {
              return onUpdate(element, {
                frequency: toValue(frequency),
                frequencyDisplay,
              });
            }}
          />
        </SelectInline>
      </ItemCell>
      <ItemCell
        width={localizedColumns.autoSubscribe.width}
        fixed={localizedColumns.autoSubscribe.fixed}
      >
        <StyledSwitch
          checked={element.autoSubscribe}
          onChange={(e) => {
            return onUpdate(element, { autoSubscribe: e.target.checked });
          }}
        />
      </ItemCell>
      <PaddedItemCell
        width={localizedColumns.actions.width}
        fixed={localizedColumns.actions.fixed}
      >
        <ActionCell
          title={t('Edit Subscription List')}
          options={actions}
          onSelectAction={onSelectAction}
          overlay
          menuType={'observable'}
          data={element}
          onOpen={() => {
            setForceToTop(true);
          }}
          onClose={() => {
            setForceToTop(false);
          }}
        />
      </PaddedItemCell>
    </ItemWrapper>
  );
};

ListItem.propTypes = {
  element: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  setForceToTop: PropTypes.func.isRequired,
  uniqueNames: PropTypes.array.isRequired,
};

export default ListItem;
