import { reactive } from '@thi.ng/rstream';
import {
  MISSING_SOURCE,
  AndRuleDef,
  CombinatorRuleDef,
  MapKeysXformDef,
  OrRuleDef,
  RuleDef,
  ReturnXformDef,
  TakeXformDef,
  TakeFirstXformDef,
} from './types';

/**
 * key tagged with index of source array location as the first character
 * @param rule
 * @param index
 * @returns
 */
export const getRuleSyncKey = (
  rule: RuleDef<any, any, any>,
  index?: number
): string => {
  const base = index === undefined ? rule[0] : `${index}__${rule[0]}`;

  if (isCombinatorRule(rule)) {
    return rule[1].reduce<string>((acc, r) => {
      if (isCombinatorRule(r)) {
        return acc.concat(...r[1].map((v) => getRuleSyncKey(v)));
      }
      return acc.length ? `${acc}__${r[0]}` : r[0];
    }, base);
  }

  return base;
};

export const isCombinatorRule = (
  rule: RuleDef<any, any, any>
): rule is CombinatorRuleDef<any, any, any> => {
  return (rule[0] === 'and' || rule[0] === 'or') && Array.isArray(rule?.[1]);
};

export const isOrRule = (rule: any[]): rule is OrRuleDef<any, any, any> => {
  return rule[0] === 'or' && Array.isArray(rule?.[1]);
};

export const isAndRule = (rule: any[]): rule is AndRuleDef<any, any, any> => {
  return rule[0] === 'and' && Array.isArray(rule?.[1]);
};

export const isTakeXformDef = (xform: any): xform is TakeXformDef => {
  return xform !== null && typeof xform === 'object' && !isNaN(xform.take);
};

export const isTakeFirstXformDef = (xform: any): xform is TakeFirstXformDef => {
  return xform === 'take-first';
};

export const isReturnXformDef = <T>(xform: any): xform is ReturnXformDef<T> => {
  return (
    xform !== null &&
    typeof xform === 'object' &&
    typeof xform.return === 'object'
  );
};

export const isMapKeysXformDef = (xform: any): xform is MapKeysXformDef => {
  return (
    xform !== null &&
    typeof xform === 'object' &&
    typeof xform.map_keys === 'object'
  );
};

/**
 * Stream used to attach the rule transducer when the source stream does not exist.
 */
export const missingSourceStream = <T = unknown>() => {
  return reactive<T | typeof MISSING_SOURCE>(MISSING_SOURCE);
};
