import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TitleField from '../../fields/title';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { gutters } from 'app/spacing';
import { StyledWizardField } from 'components/Wizards/shared/styles';

const SalesLeaderboardWithProjectionsReportType = ({
  name,
  defaultName,
  onChangeName,
  top,
}) => {
  const { state, entityName } = useContext(WizardStateContext.Context);
  const { reportType } = state;

  // Hide self if the report type is not opportunity conversion
  if (reportType?.value !== REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS) {
    return null;
  }

  return (
    <StyledWizardField top={gutters.spacing(0, 2)}>
      <TitleField
        name={name}
        defaultName={defaultName}
        onChangeName={onChangeName}
        top={top}
        entityName={entityName}
      />
    </StyledWizardField>
  );
};

SalesLeaderboardWithProjectionsReportType.propTypes = {
  defaultName: PropTypes.string,
  name: PropTypes.string,
  onChangeName: PropTypes.func.isRequired,
  top: PropTypes.number,
};

SalesLeaderboardWithProjectionsReportType.defaultProps = {
  defaultName: '',
  name: '',
  top: gutters.spacing(2),
};

export default SalesLeaderboardWithProjectionsReportType;
