import { React } from '__app/typography';

interface FixedWidthProps {
  width?: number;
  children?: any;
}

const FixedWidth = ({ width, children }: FixedWidthProps) => {
  return (
    <div
      className={'w-[var(--fixed-width)]'}
      style={
        {
          '--fixed-width': `${width}`,
        } as React.CSSProperties
      }
    >
      {children}
    </div>
  );
};

export default FixedWidth;
