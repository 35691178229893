import React, { useEffect, useState, useRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Draggable from 'react-draggable';

import { layers } from 'app/spacing';
import { HorizontalDropzone } from '../DragAndDropLayout/Dropzone';
import {
  dropzoneIs,
  getItemDropzone,
  applyDropzone,
} from '../DragAndDropLayout/helpers';
import BuilderField, { fieldMargin, FieldPlaceholder } from './Field';
import { flushSync } from 'react-dom';

/*

This component displays a list of items (fields) of uniform
width, arranged in a single column, drag-and-droppable only vertically,
not horizontally.

helpers.js relies on these assumptions to ensure field movement / reordering
on drag and drop

In other words, this is a heavily pared down version of components/Builders/CustomFieldsBuilder
(this started as a copy-and-paste from that)

*/

const ItemWrapper = styled.div`
  position: relative;
  display: flex;

  ${({ width, cols }) => css`
    width: ${(100 * width) / cols}%;
  `}
  ${({ dragging, draggingFrom, optioning }) =>
    (dragging || draggingFrom || optioning) &&
    css`
      z-index: ${layers.content(0, 1)};
    `}
  ${({ dragging }) =>
    dragging &&
    css`
      pointer-events: none;
    `}
`;

export default function FieldLayout({
  fields,
  FieldBeforeText,
  Actions,
  onChange,
  ...others
}) {
  const [fieldDragging, setFieldDragging] = useState(null);
  const [fieldDropzone, setFieldDropzone] = useState(null); // { id }
  const [fieldHeight, setFieldHeight] = useState(0);
  const containerRef = useRef();

  const applyFieldDropzone = async () => {
    if (!fieldDragging || !fieldDropzone) {
      return;
    }

    const [, toFields] = applyDropzone(fieldDragging, [fields], fieldDropzone);

    onChange(toFields);
  };

  const itemsAfter = (i, arr) => arr.length - i - 1;
  useEffect(() => {
    if (containerRef.current) {
      // we assume all fields are the same height
      const fieldEl = containerRef.current.querySelector('.FieldItemWrapper');
      setFieldHeight(fieldEl ? fieldEl.offsetHeight : 0);
    }
  }, [fields.length]);

  return (
    <div
      onMouseMove={(ev) => {
        if (fieldDragging) {
          setFieldDropzone(
            getItemDropzone(
              fieldDragging,
              fields,
              '.FieldItemWrapper',
              'isHidden',
              ev
            )
          );
        }
      }}
      ref={containerRef}
    >
      {fields.map((f, i, arr) => {
        return (
          <React.Fragment key={f.id}>
            {dropzoneIs(fieldDropzone, {
              id: f.id,
              position: 'before',
            }) && <HorizontalDropzone margin={fieldMargin} />}
            <ItemWrapper
              key={f.id}
              className="FieldItemWrapper"
              cols={2}
              width={2}
              dragging={fieldDragging && fieldDragging.id === f.id}
            >
              <FieldPlaceholder />
              <Draggable
                bounds={{
                  top: -(fieldHeight * i),
                  bottom: fieldHeight * itemsAfter(i, arr),
                  right: 0,
                  left: 0,
                }}
                disabled={f.isHidden}
                onStart={() => flushSync(() => setFieldDragging(f))}
                onStop={() => {
                  flushSync(() => {
                    applyFieldDropzone();
                    setFieldDragging(null);
                    setFieldDropzone(null);
                  });
                }}
                position={fieldDragging ? null : { x: 0, y: 0 }}
                handle=".FieldHandle"
              >
                <BuilderField
                  field={f}
                  handleProps={{ className: 'FieldHandle' }}
                  dragging={fieldDragging && fieldDragging.id === f.id}
                  FieldBeforeText={FieldBeforeText}
                  Actions={Actions}
                  {...others}
                />
              </Draggable>
            </ItemWrapper>
            {dropzoneIs(fieldDropzone, {
              id: f.id,
              position: 'after',
            }) && <HorizontalDropzone margin={fieldMargin} />}
          </React.Fragment>
        );
      })}
    </div>
  );
}

FieldLayout.propTypes = {
  fields: PropTypes.array,
  FieldBeforeText: PropTypes.object,
  Actions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

FieldLayout.defaultProps = {
  fields: [],
  FieldBeforeText: null,
  Actions: null,
};
