import {
  addDays,
  endOfWeek,
  isWithinInterval,
  format,
  startOfWeek,
  isAfter,
} from 'date-fns';
import { CALENDAR_VIEW_OPTIONS } from './constants';
import { buildTimeIntervals } from 'utility/datetime';

export const isCellToday = (date) => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

export const isCellFuture = (date, time = 0) => {
  return isAfter(
    time || date,
    new Date().setHours(
      time ? new Date().getHours() : 0,
      time ? new Date().getMinutes() : 0,
      0,
      -1
    )
  );
};

export const isCellTomorrow = (date) => {
  const tomorrow = addDays(new Date(), 1);
  return (
    date.getFullYear() === tomorrow.getFullYear() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getDate() === tomorrow.getDate()
  );
};

export const isTodaySaturday = () => {
  return new Date().getDay() === 6;
};

export const isThisWeek = (calendarDate) => {
  const today = new Date();
  return isWithinInterval(calendarDate, {
    start: startOfWeek(today),
    end: endOfWeek(today),
  });
};

export const getWeekNumberOfTheMonth = (date) =>
  Math.ceil(endOfWeek(date).getDate() / 7);

// only relevant for month view
export const isCellInLastRow = (index, total, daysInWeek = 7) => {
  return index >= total - daysInWeek;
};

export const getCalendarTimeIntervals = (interval = 30) => {
  return buildTimeIntervals(interval, (d) => format(d, 'h:mm A'));
};

export const getDateTitle = (dateToFormat, language, view) => {
  const date = new Date(dateToFormat);
  switch (view) {
    case CALENDAR_VIEW_OPTIONS.day:
      return new Intl.DateTimeFormat(language, {
        weekday: 'long',
        month: '2-digit',
        day: '2-digit',
      })
        .format(date)
        .replace(',', '');
    case CALENDAR_VIEW_OPTIONS.week:
      return new Intl.DateTimeFormat(language, {
        weekday: 'short',
        month: '2-digit',
        day: '2-digit',
      })
        .format(date)
        .replace(',', '');
    case CALENDAR_VIEW_OPTIONS.month:
      return new Intl.DateTimeFormat(language, {
        weekday: 'long',
      }).format(date);
    default:
      return date.toLocaleDateString(language);
  }
};

export const getCalendarViewToggleOptions = (t) => [
  { value: CALENDAR_VIEW_OPTIONS.day, label: t('day') },
  { value: CALENDAR_VIEW_OPTIONS.week, label: t('week') },
  { value: CALENDAR_VIEW_OPTIONS.month, label: t('month') },
];

/**
 * This assumes an interval of 30 minutes to match the intervals on week/day views.
 * Dates with a minute value of 0-29 return `{hour}:00 {AM/PM}`.
 * Dates with a minute value of 30-59 return `{hour}:30 {AM/PM}`.
 * Hours are converted to 12 hour time [1-12].
 *
 * @param {Date} d
 * @returns string
 */
export const getTimeIntervalForDate = (date) => {
  const d = new Date(date);
  const minutes = d.getMinutes() >= 30 ? '30' : '00';
  const ampm = d.getHours() >= 12 ? 'PM' : 'AM';
  let hours = d.getHours();
  if (hours === 0) {
    hours = 12;
  } else if (hours > 12) {
    hours -= 12;
  }
  return `${hours}:${minutes} ${ampm}`;
};
