export const DEFAULT_PAGE_CONFIG = {
  quickFilteringFacets: false,
  quickFilterSettings: [],
};

export const CONFIG_KEY = 'filters';

export const NAV_BAR_HEIGHT = 92; // 72 + 20 top padding

export const TOOLBAR_HEIGHT = 76;

export const DEFAULT_PAGINATION_SETTINGS = {
  page: 1,
  pageSize: 10,
  search: '',
  ordering: 'name',
};

export const APPLY_TEMPLATE_PARAM = 'apply_template';

export const RELATED_ENTITITY_QUICK_FILTER_FIELD =
  'related_entity_quick_filter_field';
