import React from 'react';
import * as PropTypes from 'prop-types';
import Stepper from 'components/ReactStepper';

import { colorsButton, grayScale } from 'app/colors';

import StyledStepperWrapper from './styles';

export default function KizenInlineStepper({ size, steps, ...rest }) {
  return (
    <StyledStepperWrapper>
      <Stepper size={size} steps={steps} {...rest} />
    </StyledStepperWrapper>
  );
}

KizenInlineStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeStep: PropTypes.number.isRequired,
  size: PropTypes.number,
  activeColor: PropTypes.string,
  completeColor: PropTypes.string,
  defaultColor: PropTypes.string,
  activeTitleColor: PropTypes.string,
  completeTitleColor: PropTypes.string,
  defaultTitleColor: PropTypes.string,
  circleFontSize: PropTypes.number,
  titleFontSize: PropTypes.number,
  titleTop: PropTypes.number,
  completeBarColor: PropTypes.string,
  defaultBarColor: PropTypes.string,
  lineMarginOffset: PropTypes.number,
};

KizenInlineStepper.defaultProps = {
  size: 31,
  activeColor: colorsButton.blue.default,
  completeColor: colorsButton.blue.default,
  defaultColor: grayScale.medium,
  activeTitleColor: colorsButton.blue.default,
  completeTitleColor: colorsButton.blue.default,
  defaultTitleColor: grayScale.mediumDark,
  circleFontSize: 10,
  titleFontSize: 14,
  titleTop: 8,
  completeBarColor: colorsButton.blue.default,
  defaultBarColor: grayScale.medium,
  lineMarginOffset: 0,
};
