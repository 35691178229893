import { useParams, useHistory } from 'react-router-dom';
import {
  getRecords,
  updatePageConfig,
} from 'store/customObjectsRecordsPage/actions';
import useReduxGroupConfig from 'components/DashboardGrid/hooks/useReduxGroupConfig';
import { useCallback, useEffect } from 'react';

export const CONFIG_KEY = 'custom-objects';
export const DATE_FILTER_ALL_TIME_INDEX = 6;

const useCustomObjectChartGroupConfig = (
  dashboards = [],
  skipRouter = false,
  objectId,
  enabled = true,
  isSettled = false
) => {
  const { chartId } = useParams();
  const history = useHistory();

  const getChartRoute = useCallback(
    (id) => `/custom-objects/${objectId}/charts${id ? `/${id}` : ''}`,
    [objectId]
  );

  const {
    setCurrentDashboardId,
    refetchDashboard,
    processConfigAfterCreate,
    processConfigAfterUpdate,
    processConfigAfterDelete,
    loading,
    currentDashboard,
    dashboardQueryKey,
    currentDashboardPermission,
    config,
    setPersistentDashboardState,
    modelId,
  } = useReduxGroupConfig(
    {
      selector: (s) => s.recordsPage,
      updatePageConfig,
      getData: getRecords,
      dashboards,
      getChartRoute,
      chartId,
      skipRouter,
      objectId,
    },
    enabled
  );

  useEffect(() => {
    if (
      objectId &&
      currentDashboard?.customObject !== objectId &&
      !loading &&
      objectId === modelId &&
      isSettled &&
      enabled
    ) {
      if (dashboards.length) {
        history.replace(
          `/custom-objects/${objectId}/charts/${dashboards[0].id}`
        );
      }
    }
  }, [
    currentDashboard,
    objectId,
    loading,
    modelId,
    dashboards,
    history,
    isSettled,
    enabled,
  ]);

  return {
    setCurrentDashboardId,
    refetchDashboard,
    afterDelete: processConfigAfterDelete,
    afterUpdate: processConfigAfterUpdate,
    afterCreate: processConfigAfterCreate,
    loading,
    currentDashboard,
    dashboardQueryKey,
    currentDashboardPermission,
    persistentDashboardState: config?.dashboardState,
    setPersistentDashboardState,
    dashboards,
    contacts: false,
    objectId,
  };
};

export default useCustomObjectChartGroupConfig;
