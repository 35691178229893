import { ProfilePage } from './pages';
import { ToolbarPage } from './pages';
import { ApiConnections } from './pages';
import EditTemplatePage from './pages/Toolbar/pages/Edit';
import paths from './paths';
export const ACCESS_LOOKUP = {
  API_CONNECTIONS_SECTION: 'api_connections_section',
  MY_PROFILE_SECTION: 'my_profile_section',
};

export const editToolbarPathObject = {
  editToolbarTemplate: {
    title: (t) => t('Edit Toolbar Template'),
    label: 'Edit Template',
    component: EditTemplatePage,
    hideNavigation: true,
    exact: true,
    private: true,
    hide: true,
  },
};

const routes = {
  profile: {
    label: (t) => t('My Profile'),
    title: (t) => t('Profile'),
    component: ProfilePage,
    exact: true,
    icon: 'user-circle',
    accessList: ACCESS_LOOKUP.MY_PROFILE_SECTION,
  },
  ...editToolbarPathObject,
  toolbar: {
    label: (t) => t('My Toolbar'),
    title: (t) => t('Toolbar'),
    component: ToolbarPage,
    exact: false,
    icon: 'compass',
    accessList: ACCESS_LOOKUP.MY_PROFILE_SECTION,
  },
  editConnections: {
    label: (t) => t('API Connections'),
    title: (t) => t('API Connections'),
    component: ApiConnections,
    exact: true,
    icon: 'key',
    accessList: ACCESS_LOOKUP.API_CONNECTIONS_SECTION,
  },
};

paths.forEach((path, routeId) => {
  routes[routeId].path = path;
});

export default routes;
