import React from 'react';
import {
  ElementContainerSettingsSection,
  AttachmentsSettings as AttachmentsSettingsComponent,
} from '../../settings';

export const AttachmentsSettings = ({
  node = false,
  fileLibraryOpen = false,
}) => (
  <>
    <AttachmentsSettingsComponent
      node={node}
      fileLibraryOpen={fileLibraryOpen}
    />
    <ElementContainerSettingsSection
      node={node}
      enableBackgroundImage={false}
      enableBorder={false}
      enableMargin={false}
    />
  </>
);
