import React from 'react';

import { grayScale } from 'app/colors';
import IconButton from 'components/Kizen/IconButton';
import Icon from 'components/Kizen/Icon';
import { useTranslation } from 'react-i18next';

const closeButtonColor = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

const CloseButton = (props) => {
  const { t } = useTranslation();
  return (
    <IconButton
      title={t('Close')}
      sizing="mobile"
      data-dismiss="modal"
      color={closeButtonColor}
      {...props}
    >
      <Icon icon="close-cross" />
    </IconButton>
  );
};

export default CloseButton;
