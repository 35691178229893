import { createElement, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditor } from '@craftjs/core';
import GenericWizard from 'components/GenericWizard';
import BasicModal from 'components/Modals/presets/BasicModal';
import { created } from 'store/pageBuilder/pageBuilder.redux';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { FIELD_TYPES } from 'utility/constants';
import { useBuilderContext } from 'components/PageBuilder/BuilderContext';
import { useAddNewNode } from '../useAddNewNode';
import { DEFAULT_FORM_FIELD_PROPS } from './props';
import { useDirtyState } from 'components/Builders/CustomFieldsBuilder/hooks/useDirtyState';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { getDefaultFieldPlaceholder } from 'components/PageBuilder/utils';

const EMPTY_OBJECT = {};
const access = { edit: true, view: true };

const createField = (data) => {
  // Based on logic from pages/Settings/pages/Activities/Builder/Field
  const {
    displayName,
    isRequired,
    type,
    options,
    phonenumberOptions,
    moneyOptions,
    relations,
    rating,
    meta,
  } = data;
  return {
    name: displayName,
    options,
    displayName,
    description: displayName,
    isRequired: !!isRequired,
    fieldType: type,
    isDefault: false,
    allowsNulls: true,
    allowsEmpty: true,
    isReadOnly: false,
    isHidden: false,
    access,
    ...(meta !== undefined && {
      meta,
    }),
    ...(type === FIELD_TYPES.PhoneNumber.type && {
      phonenumberOptions: { ...phonenumberOptions },
    }),
    ...(type === FIELD_TYPES.Rating.type && { rating }),
    ...(type === FIELD_TYPES.Money.type && { moneyOptions }),
    ...(type === FIELD_TYPES.Relationship.type && { relations }),
  };
};

const CreateFormField = ({ formFieldHeading }) => {
  const { t } = useTranslation();
  const addNewNode = useAddNewNode();
  const dispatch = useDispatch();
  const { onNodeCreate } = useBuilderContext();
  const { actions } = useEditor();
  const [{ data, isComplete }, setWizardData] = useState({
    data: {},
    isComplete: false,
  });
  const formFieldItem = useSelector((s) => getCreateItemByType(s, 'FormField'));

  const onHide = useCallback(() => {
    setWizardData({ data: {}, isComplete: false });
    actions.setIndicator(null);
    dispatch(created(false));
  }, [actions, dispatch]);

  const onChange = useCallback((wizardData) => {
    if (!initialDataRef.current) {
      initialDataRef.current = wizardData?.data;
    }
    setWizardData(wizardData);
  }, []);

  const initialDataRef = useRef(null);

  const { showConfirmation, onCheckToHide, onHideConfirmed, onHideCanceled } =
    useDirtyState(initialDataRef.current, data, onHide);

  return (
    <>
      <BasicModal
        key={!!formFieldItem}
        show={!!formFieldItem}
        typeOfContent="wizard"
        heading={formFieldHeading}
        disabled={!isComplete}
        size="medium"
        fitContent={false}
        onConfirm={async () => {
          const createdField = createField(data);
          const node = addNewNode({
            ...formFieldItem,
            ...DEFAULT_FORM_FIELD_PROPS,
            field: {
              ...createdField,
              access,
              placeholder: getDefaultFieldPlaceholder(createdField, t),
            },
          });
          onNodeCreate(node.id, createdField);
          setWizardData({ data: {}, isComplete: false });
        }}
        onHide={onCheckToHide}
      >
        <GenericWizard
          wizard="FormFieldWizard"
          onChange={onChange}
          data={EMPTY_OBJECT}
        />
      </BasicModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Cancel')}
        actionBtnColor="red"
        show={showConfirmation}
        onConfirm={onHideConfirmed}
        onHide={onHideCanceled}
      >
        {t('Unsaved changes will be lost, would you like to continue?')}
      </ConfirmationModal>
    </>
  );
};

export const buildFormFieldCreator = (formFieldHeading) =>
  createElement(CreateFormField, { formFieldHeading });
