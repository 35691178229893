import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditor, ROOT_NODE } from '@craftjs/core';
import styled from '@emotion/styled';
import { DEFAULT_LINE_HEIGHT_VALUE } from '@kizen/page-builder';
import { useLoadNonWebFont } from '@kizen/page-builder/hooks/useLoadNonWebFont';
import {
  DEFAULT_EMAIL_ROOT_PROPS,
  DEFAULT_FORM_ROOT_PROPS,
} from '@kizen/page-builder/nodes/Root';
import { useFlashValidation } from 'hooks/useFlashState';
import { ButtonRadio } from 'ts-components/ButtonToggle';
import { clamp } from 'utility/clamp';
import { TraySection, TraySubsection } from '../components/TraySection';
import { useBuilderContext } from '../BuilderContext';
import {
  FlexColorPicker,
  FlexSelect,
  FlexWholeNumberInput,
  FlexWrapper,
} from './components';
import {
  fontSizeOptions,
  lineHeightOptions,
  lineHeightOptionsLookup,
} from '../options';
import { useFontOptionLookup, useFonts } from './useFonts';

export const DEFAULT_MAX_WIDTH = 900;
// for email perspective, this values should be less 480
//  @media only screen and (min-width:480px) {
//   .mj-column-per-100 { width:100% !important; max-width: 100%; }
//   .mj-column-per-50 { width:50% !important; max-width: 50%; }
//  }
const DEFAULT_MOBILE_BREAK = 414;
const DEFAULT_TABLET_BREAK = 768;
const alignmentOptions = [
  { value: 'left', iconName: 'align-left' },
  { value: 'center', iconName: 'align-center' },
  { value: 'right', iconName: 'align-right' },
];

const getPopperOffsetConfig = ({ x, y } = {}) => ({
  modifiers: {
    offset: { offset: `${x || '0'}, ${y || '0'}` },
    preventOverflow: { enabled: false },
  },
});

const getMaxWidthError = (value, { min, emailMode, t }) => {
  if (!t) return isNaN(value) || (!isNaN(min) && value < min);
  if (isNaN(value))
    return {
      message: t('This field is required.'),
      popperConfig: getPopperOffsetConfig(),
    };
  if (!isNaN(min) && value < min) {
    const message = emailMode
      ? t('Max width must be greater than the mobile break.')
      : t('Max width must be greater than the tablet break.');
    return {
      message,
      popperConfig: getPopperOffsetConfig({ x: emailMode ? '-30' : '-125' }),
    };
  }
  return false;
};

const getTabletBreakError = (value, { max, min, t }) => {
  if (!t)
    return (
      isNaN(value) ||
      (!isNaN(min) && value < min) ||
      (!isNaN(max) && value > max)
    );
  if (isNaN(value))
    return {
      message: t('This field is required.'),
      popperConfig: getPopperOffsetConfig(),
    };
  if (!isNaN(min) && value < min)
    return {
      message: t('Tablet break must be greater than the mobile break.'),
      popperConfig: getPopperOffsetConfig({ x: '-40' }),
    };
  if (!isNaN(max) && value > max)
    return {
      message: t('Tablet break must be less than the max width.'),
      popperConfig: getPopperOffsetConfig({ x: '-20' }),
    };
  return false;
};

const getMobileBreakError = (value, { max, emailMode, t }) => {
  if (!t) return isNaN(value) || value === 0 || (!isNaN(max) && value > max);
  if (isNaN(value))
    return {
      message: t('This field is required.'),
      popperConfig: getPopperOffsetConfig(),
    };
  if (value === 0)
    return {
      message: t('Mobile break must be greater than zero.'),
      popperConfig: getPopperOffsetConfig({ x: emailMode ? '0' : '-70' }),
    };
  if (typeof max === 'number' && value > max) {
    const message = emailMode
      ? t('Mobile break must be less than the max width.')
      : t('Mobile break must be less than the tablet break.');
    return {
      message,
      popperConfig: getPopperOffsetConfig({ x: emailMode ? '-10' : '-120' }),
    };
  }
  return false;
};

const LineHeight = styled(FlexSelect)`
  flex: 0.5;
  margin-right: 10px;
`;

export const PageSettingsSection = ({ emailMode = false, title }) => {
  const traySectionRef = useRef();
  const {
    rootNode,
    enableTextLinks,
    isPageSettingsOpen,
    setIsPageSettingsOpen,
  } = useBuilderContext();
  const props = {
    ...(emailMode ? DEFAULT_EMAIL_ROOT_PROPS : DEFAULT_FORM_ROOT_PROPS),
    ...rootNode.data.props,
  };
  const [tabletBreak, setTabletBreak] = useState(props.tabletBreak);
  const [mobileBreak, setMobileBreak] = useState(props.mobileBreak);
  const [maxWidth, setMaxWidth] = useState(props.maxWidth);
  const [tabletBreakError, setTabletBreakError] = useFlashValidation();
  const [mobileBreakError, setMobileBreakError] = useFlashValidation();
  const [maxWidthError, setMaxWidthError] = useFlashValidation();
  const { t } = useTranslation();
  const {
    actions: { setProp: setCraftProp },
  } = useEditor();
  const setProp = useCallback(
    (key, value) => {
      setCraftProp(ROOT_NODE, (p) => {
        p[key] = value;
      });
    },
    [setCraftProp]
  );
  const fonts = useFonts(emailMode);
  const fontOptionLookup = useFontOptionLookup(fonts);
  useLoadNonWebFont(props.fontFamily);

  const handleMaxWidthChange = useCallback(
    (value) => {
      setMaxWidth(value);
      const min = parseInt(emailMode ? mobileBreak : tabletBreak) + 1;
      const error = getMaxWidthError(parseInt(value), { min, emailMode, t });
      if (error) {
        setMaxWidthError(error);
      } else {
        setProp('maxWidth', value);
      }
    },
    [
      tabletBreak,
      mobileBreak,
      emailMode,
      setMaxWidth,
      setMaxWidthError,
      setProp,
      t,
    ]
  );

  const handleMaxWidthBlur = useCallback(
    (ev) => {
      const value = parseInt(ev?.target?.value);
      const min = parseInt(emailMode ? mobileBreak : tabletBreak) + 1;
      if (getMaxWidthError(value, { min })) {
        const v = isNaN(value) ? DEFAULT_MAX_WIDTH : min;
        setProp('maxWidth', v.toString());
        setMaxWidth(v);
      }
    },
    [mobileBreak, tabletBreak, emailMode, setProp, setMaxWidth]
  );

  const handleTabletBreakChange = useCallback(
    (value) => {
      setTabletBreak(value);
      const error = getTabletBreakError(parseInt(value), {
        min: parseInt(mobileBreak) + 1,
        max: parseInt(props.maxWidth) - 1,
        t,
      });
      if (error) {
        setTabletBreakError(error);
      } else {
        setProp('tabletBreak', value);
      }
    },
    [
      mobileBreak,
      props.maxWidth,
      setTabletBreakError,
      setTabletBreak,
      setProp,
      t,
    ]
  );

  const hanldeMobileBreakChange = useCallback(
    (value) => {
      setMobileBreak(value);
      const getErrorOpts = {
        max: parseInt(emailMode ? props.maxWidth : tabletBreak) - 1,
        emailMode,
        t,
      };
      const error = getMobileBreakError(parseInt(value), getErrorOpts);
      if (error) {
        setMobileBreakError(error);
      } else {
        setProp('mobileBreak', value);
      }
    },
    [
      tabletBreak,
      emailMode,
      props.maxWidth,
      setMobileBreakError,
      setMobileBreak,
      setProp,
      t,
    ]
  );

  const handleTabletBreakBlur = useCallback(
    (ev) => {
      const value = parseInt(ev?.target?.value);
      const min = parseInt(mobileBreak) + 1;
      const max = parseInt(props.maxWidth) - 1;
      if (getTabletBreakError(value, { min, max })) {
        const v = isNaN(value) ? DEFAULT_TABLET_BREAK : clamp(value, min, max);
        setProp('tabletBreak', v.toString());
        setTabletBreak(v);
      }
    },
    [mobileBreak, props.maxWidth, setTabletBreak, setProp]
  );

  const handleMobileBreakBlur = useCallback(
    (ev) => {
      const value = parseInt(ev?.target?.value);
      const max = parseInt(emailMode ? props.maxWidth : tabletBreak) - 1;
      if (getMobileBreakError(value, { max, emailMode })) {
        const v = isNaN(value) ? DEFAULT_MOBILE_BREAK : clamp(value, 1, max);
        setProp('mobileBreak', v.toString());
        setMobileBreak(v);
      }
    },
    [tabletBreak, props.maxWidth, emailMode, setMobileBreak, setProp]
  );

  return (
    <TraySection
      ref={traySectionRef}
      header={title || t('Page Settings')}
      open={isPageSettingsOpen}
      onCollapse={() => {
        setIsPageSettingsOpen((x) => !x);
      }}
    >
      <TraySubsection header={t('Colors')}>
        <FlexWrapper>
          <FlexColorPicker
            flex={1 / 3}
            color={props.backgroundColor || '#FFFFFF'}
            label={t('Background')}
            onChange={(rgba) => setProp('backgroundColor', rgba)}
          />
          <FlexColorPicker
            flex={1 / 3}
            align="center"
            color={props.color || '#4A5660'}
            label={t('Body Text')}
            onChange={(rgba) => setProp('color', rgba)}
          />
          {enableTextLinks && (
            <FlexColorPicker
              flex={1 / 3}
              align="right"
              color={props.linkColor || '#528EF9'}
              label={t('Links')}
              onChange={(rgba) => setProp('linkColor', rgba)}
            />
          )}
        </FlexWrapper>
      </TraySubsection>
      <TraySubsection header={t('Fonts')}>
        <FlexWrapper>
          <FlexSelect
            variant="underline"
            label={t('Font Face')}
            value={fontOptionLookup[props.fontFamily]}
            scrollContainer={traySectionRef.current}
            options={fonts}
            onChange={({ value }) => setProp('fontFamily', value)}
          />
          <FlexSelect
            variant="underline"
            label={t('Font Size')}
            value={props.fontSize}
            scrollContainer={traySectionRef.current}
            options={fontSizeOptions}
            onChange={({ value }) => setProp('fontSize', value)}
          />
        </FlexWrapper>
        <FlexWrapper>
          <LineHeight
            variant="underline"
            label={t('Line Height')}
            value={
              lineHeightOptionsLookup[
                props.lineHeight || DEFAULT_LINE_HEIGHT_VALUE
              ]
            }
            scrollContainer={traySectionRef.current}
            options={lineHeightOptions}
            onChange={({ value }) => setProp('lineHeight', value)}
          />
        </FlexWrapper>
      </TraySubsection>
      <TraySubsection header={t('Sizing')}>
        <FlexWrapper>
          {!emailMode && (
            <FlexWholeNumberInput
              shrink
              flex={1 / 3}
              variant="underline"
              maxValue={100}
              label={t('Width (%)')}
              value={props.width}
              onChange={(v) => setProp('width', v)}
              onBlur={(ev) => {
                const v = parseInt(ev?.target?.value);
                setProp(
                  'width',
                  isNaN(v) ? '100' : clamp(v, 1, 100).toString()
                );
              }}
            />
          )}
          <FlexWholeNumberInput
            shrink
            flex={1 / 3}
            variant="underline"
            maxDigits={4}
            label={t('Max Width (px)')}
            value={maxWidth}
            onChange={handleMaxWidthChange}
            onBlur={handleMaxWidthBlur}
            validate={maxWidthError}
          />
          <ButtonRadio
            flex={1 / 3}
            label={t('Alignment')}
            value={props.alignment}
            options={alignmentOptions}
            onChange={(v) => setProp('alignment', v)}
          />
        </FlexWrapper>
        <FlexWrapper>
          {!emailMode && (
            <FlexWholeNumberInput
              shrink
              variant="underline"
              flex={1 / 3}
              maxDigits={4}
              label={t('Tablet Break (px)')}
              value={tabletBreak}
              onChange={handleTabletBreakChange}
              onBlur={handleTabletBreakBlur}
              validate={tabletBreakError}
            />
          )}
          <FlexWholeNumberInput
            shrink
            variant="underline"
            flex={1 / 3}
            maxDigits={4}
            label={t('Mobile Break (px)')}
            value={mobileBreak}
            onChange={hanldeMobileBreakChange}
            onBlur={handleMobileBreakBlur}
            validate={mobileBreakError}
          />
        </FlexWrapper>
      </TraySubsection>
    </TraySection>
  );
};
