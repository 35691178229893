import {
  nodeFromTrigger,
  nodeForTransformAdd,
  nodeForAdditionalAdd,
  nodeForUpload,
  edgeFromTriggerToAdditionalVaribles,
  edgeFromAdditionalVariblesToAdd,
  edgeFromSourceToTarget,
  isEdge,
} from '__pages/AutomationEngine/Engine/Flow/layout';
import { FlowStep, FlowTrigger } from '../types';

export const nodeFromStep = (step: FlowStep) => ({
  id: step.id,
  type: 'automationStep',
  data: step,
  position: { x: -1, y: -1 },
  className: 'nopan',
});

const nodeFromConnectionStep = (step: FlowStep) => ({
  id: step.id,
  type: step.type,
  data: step,
  position: { x: -1, y: -1 },
  className: 'nopan',
});

interface iSmartConnectorToNode {
  steps: FlowStep[];
  triggers: FlowTrigger[];
  connections: FlowStep[];
  uploads: FlowStep[];
}

type OutputNode = {
  id: string;
  data?: FlowStep;
  position?: { x: number; y: number };
  type: string;
};

export const smartConnectorToNodes = ({
  steps,
  triggers,
  connections,
  uploads,
}: iSmartConnectorToNode) => {
  const addAdditionVariables = nodeForAdditionalAdd();
  const addTransformNode = nodeForTransformAdd();
  const triggerNodes = triggers.map(nodeFromTrigger);

  const connectionNodes = connections.length
    ? connections.flatMap((step, index) => {
        return [
          index === 0 && edgeFromSourceToTarget(triggerNodes[0], step),
          nodeFromConnectionStep(step),
          index === 0
            ? edgeFromSourceToTarget(step, addTransformNode)
            : edgeFromSourceToTarget(
                step,
                connections[0],
                'handle-bottom',
                'handle-top'
              ),
        ];
      })
    : [
        edgeFromTriggerToAdditionalVaribles(triggers[0]),
        addAdditionVariables,
        edgeFromAdditionalVariblesToAdd(addTransformNode),
      ];

  const firstAddUploadNode = nodeForUpload(0);

  const transfromNodes = [
    addTransformNode,
    edgeFromSourceToTarget(addTransformNode, firstAddUploadNode),
  ];

  const uploadNodes = [
    firstAddUploadNode,
    uploads.length && edgeFromSourceToTarget(firstAddUploadNode, uploads[0]),
    ...uploads.flatMap((step, i) => {
      const addUploadNode = nodeForUpload(i + 1, i === uploads.length - 1);

      return [
        nodeFromStep(step),
        edgeFromSourceToTarget(step, addUploadNode),
        addUploadNode,
        i < uploads.length - 1 &&
          edgeFromSourceToTarget(addUploadNode, uploads[i + 1]),
      ];
    }),
  ];

  return [
    ...connectionNodes,
    ...triggerNodes,
    ...transfromNodes,
    ...uploadNodes,
  ].reduce(
    (collect, node) => {
      if (node) {
        if (isEdge(node)) {
          collect[1].push(node);
        } else {
          collect[0].push(node);
        }
      }

      return collect;
    },
    [[], []] as [OutputNode[], OutputNode[]]
  );
};
