import React from 'react';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Input from 'components/Kizen/Input';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getDataQAForInput } from 'components/Inputs/helpers';

const TitleField = ({
  defaultName,
  name,
  onChangeName,
  top,
  entityName,
  flex,
  inputTop = 6,
}) => {
  const { t } = useTranslation();

  return (
    <StyledWizardField top={top} flex={flex}>
      <KizenTypography type="subheader">
        {t('Name Your {{entityName}}', { entityName })}
      </KizenTypography>
      <StyledWizardField top={inputTop} flex={flex}>
        <Input
          placeholder={defaultName}
          value={name}
          onChange={onChangeName}
          {...getDataQAForInput('dashlet-name')}
        />
      </StyledWizardField>
    </StyledWizardField>
  );
};

TitleField.propTypes = {
  defaultName: PropTypes.string,
  name: PropTypes.string,
  onChangeName: PropTypes.func.isRequired,
  top: PropTypes.number,
};

TitleField.defaultProps = {
  defaultName: '',
  name: '',
  top: gutters.spacing(2),
};

export default TitleField;
