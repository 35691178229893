import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FIELD_TYPES } from 'utility/constants';
import { getIdentifierForOption } from 'components/Fields/helpers';
import MultiSelectInline from 'components/Inputs/inline/MultiSelect';

export default function MultiDropdownInlineField({
  value,
  onSubmit,
  field,
  object,
  children: selectEl,
  ...others
}) {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return (field.options || []).map((opt) => ({
      value: getIdentifierForOption(opt, field),
      label: opt.name,
    }));
  }, [field]);

  return (
    <MultiSelectInline
      onSubmit={(opt) => onSubmit(opt.map((val) => val.value))}
      {...others}
      field={field}
      object={object}
    >
      {React.cloneElement(selectEl, {
        value,
        options,
        field,
        object,
        placeholder:
          selectEl.props.placeholder ||
          FIELD_TYPES.Checkboxes.localizedPlaceholder(t),
      })}
    </MultiSelectInline>
  );
}

MultiDropdownInlineField.propTypes = {
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

MultiDropdownInlineField.defaultProps = {
  value: null,
};
