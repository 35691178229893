import { getIsEditable } from 'components/AccessRequests/utils';
import { toastVariant, useToast } from 'components/ToastProvider';
import {
  createDashletQuery,
  deleteDashletQuery,
  updateDashletQuery,
} from 'pages/Dashboard/queries';
import {
  calculateNewDashletPosition,
  calculateNextDashletPosition,
  DEFAULT_NAME_KEY,
  editableDashlet,
  getConditionalLayout,
  GRID_WIDTH,
} from 'pages/Dashboard/util';
import { DASHBOARD } from 'queries/query-keys';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import useDashboardGridBreakpoint from './useDashboardGridBreakpoint';
import { v4 as uuidv4 } from 'uuid';
import {
  AREA_RESPONSES,
  VALUE_METRICS,
  VALUE_RESPONSES,
} from 'components/Wizards/RTDV/types';
import { getDashletConstraints, getDashletDimensions } from '../dimensions';
import { DEFAULT_LABEL_INDICATOR, valueExists } from '../utils';
import { BUCKETING_TYPES } from 'components/Wizards/utils';
import { SALES_REPORT_TYPES } from 'components/Wizards/RTDV/types';
import { useManagedQueryRefetch } from 'pages/Dashboard/context/dataManager/useManagedQueryRefetch';
import { useManagedDelete } from 'pages/Dashboard/context/dataManager/useManagedDelete';
import { getFeExtraInfo } from 'pages/Dashboard';

import {
  moveElement,
  compact,
  correctBounds,
} from 'react-grid-layout-current/build/utils';
const getRanges = ({ valueBreakdown }) => {
  return (
    valueBreakdown?.map((v) => {
      return {
        id: v.id,
        bucket_label: v.label || DEFAULT_LABEL_INDICATOR,
        min: valueExists(v.min)
          ? {
              operator: '>=',
              value: v.min,
            }
          : null,
        max: valueExists(v.max)
          ? {
              operator: '<=',
              value: v.max,
            }
          : null,
      };
    }) ?? []
  );
};

const getBuckets = ({ valueBreakdown }) => {
  return valueBreakdown
    ?.filter(({ stale }) => !stale)
    .map((v) => {
      return {
        id: v.id,
        bucket_label: v.label || DEFAULT_LABEL_INDICATOR,
        values: v.values.map((result) =>
          typeof result === 'string' || typeof result === 'number'
            ? result
            : result?.value
        ),
      };
    });
};

const getImpliedBucketingType = (oldDashlet) => {
  const fieldsValueBreakdown =
    oldDashlet?.config?.metricTypeExtraInfo?.fieldsValueBreakdown;
  if (fieldsValueBreakdown) {
    if (fieldsValueBreakdown.type === 'entity_records') {
      return BUCKETING_TYPES.ENTITIES;
    }
    return BUCKETING_TYPES.OPTIONS;
  }
  if (oldDashlet?.config?.metricTypeExtraInfo?.fieldsRangeBreakdown) {
    return BUCKETING_TYPES.NUMBER;
  }
  return BUCKETING_TYPES.NONE;
};

const getMetricExtraInfo = (dashletData, oldDashlet) => {
  const { value, includeBlank, reportType } = dashletData;

  if (reportType?.value === AREA_RESPONSES.TABLE) {
    return undefined;
  }

  let { bucketingType } = dashletData;

  if (value.value === VALUE_RESPONSES.AVERAGE) {
    return {
      fields_value_average: {
        include_blank_values_as_zero: includeBlank,
      },
    };
  }
  if (value.value === VALUE_RESPONSES.BREAKDOWN) {
    // If we don't have a bucketingType, it means this is a mutation not a creation,
    // so we can infer the type based on what's already been saved in the dashlet
    if (!bucketingType) {
      bucketingType = getImpliedBucketingType(oldDashlet);
    }

    const { metric, metricField, includeSummary, summaryExplanation } =
      dashletData;

    let metricData = {
      include_summary: includeSummary,
    };

    if (metric === VALUE_METRICS.NUMBER_FIELD && metricField) {
      metricData = {
        ...metricData,
        field_to_analyze: metricField,
        summary_explanation: summaryExplanation,
      };
    }

    if (bucketingType === BUCKETING_TYPES.NUMBER) {
      return {
        ...metricData,
        fields_range_breakdown: {
          type: 'custom_range',
          buckets: getRanges(dashletData),
        },
      };
    }

    if (bucketingType === BUCKETING_TYPES.OPTIONS) {
      return {
        ...metricData,
        fields_value_breakdown: {
          type: 'custom_values',
          consider_null_values: dashletData.bucketBlank ?? false,
          buckets: getBuckets(dashletData),
        },
      };
    }

    if (bucketingType === BUCKETING_TYPES.ENTITIES) {
      return {
        ...metricData,
        fields_value_breakdown: {
          type: 'entity_records',
          consider_null_values: dashletData.bucketBlank ?? false,
          buckets: dashletData.valueBreakdown.map((item) => item.value || item),
        },
      };
    }

    // If we make it here with a breakdown but no bucketing type, it means we're
    // dealing with a chart that doesn't currently have support for building custom breakdowns,
    // so the default breakdown configuration is sent instead.
    if (bucketingType === BUCKETING_TYPES.NONE) {
      return {
        ...metricData,
        fields_value_breakdown: {
          type: 'custom_values',
          consider_null_values: true,
          buckets: [],
        },
      };
    }
  }
};

const getAggregation = (dashletData) => {
  const {
    reportType,
    pivotTableColumns,
    rowDateFrequencies,
    columnDateFrequency,
    pivotTableColumnBreakdown,
    pivotTableRowBreakdown,
    pivotTableRows,
    metric,
    metricField,
    rowCustomLabels,
  } = dashletData;

  if (reportType?.value !== AREA_RESPONSES.PIVOT_TABLE) {
    return undefined;
  }

  const id = pivotTableColumns?.[0]?.id;
  return {
    columns: {
      id,
      label: pivotTableColumns?.[0]?.label ?? '',
      fields_value_breakdown: getPivotColumnValueBreakdown(
        pivotTableColumnBreakdown,
        columnDateFrequency,
        id
      ),
    },
    rows: pivotTableRows?.map((row) => {
      return {
        id: row.id,
        label: rowCustomLabels?.[row.id] ?? row.label ?? '',
        fields_value_breakdown: getPivotRowValueBreakdown(
          pivotTableRowBreakdown,
          rowDateFrequencies,
          row?.id
        ),
      };
    }),
    field_to_aggregate:
      metric?.value === 'record_count' ? null : metricField?.id,
    field_to_aggregate_label: metricField?.label,
  };
};

const getPivotColumnValueBreakdown = (valueBreakdown, dateFrequency) => {
  if (valueBreakdown) {
    return {
      type: 'custom',
      buckets: getRanges({ valueBreakdown }),
    };
  }
  if (dateFrequency) {
    return {
      type: 'automatic',
      frequency: dateFrequency.value ?? dateFrequency,
    };
  }
  return undefined;
};

const getPivotRowValueBreakdown = (valueBreakdown, dateFrequencies, rowId) => {
  if (valueBreakdown && valueBreakdown[rowId]?.length > 0) {
    return {
      type: 'custom',
      buckets: getRanges({ valueBreakdown: valueBreakdown[rowId] }),
    };
  }
  if (dateFrequencies && dateFrequencies[rowId]) {
    return {
      type: 'automatic',
      frequency: dateFrequencies[rowId],
    };
  }
  return undefined;
};

const getMetricType = (dashletData, oldDashlet) => {
  const { value, reportType, metric } = dashletData;

  if (reportType?.value === AREA_RESPONSES.TABLE) {
    return 'records_number';
  }

  if (reportType?.value === AREA_RESPONSES.PIVOT_TABLE) {
    return metric?.value === 'record_count'
      ? 'records_number'
      : 'fields_value_sum';
  }

  if (reportType?.value === AREA_RESPONSES.SALES) {
    return value?.value;
  }

  let { bucketingType } = dashletData;

  // If we don't have a bucketingType, it means this is a mutation not a creation,
  // so we can infer the type based on what's already been saved in the dashlet
  if (!bucketingType) {
    bucketingType = getImpliedBucketingType(oldDashlet);
  }

  if (
    bucketingType === BUCKETING_TYPES.OPTIONS &&
    [VALUE_RESPONSES.AVERAGE, VALUE_RESPONSES.SUM].includes(value?.value)
  ) {
    return value?.value;
  }

  // There is some weirdness in the backend API where the metric_type needs to change based
  // on the field type if it's a breakdown
  if (
    bucketingType === BUCKETING_TYPES.OPTIONS ||
    bucketingType === BUCKETING_TYPES.ENTITIES ||
    (bucketingType === BUCKETING_TYPES.NONE &&
      value?.value === 'fields_range_breakdown')
  ) {
    return 'fields_value_breakdown';
  }
  return value?.value;
};

const getChartType = (dashletData) => {
  if (
    dashletData.reportType?.value === AREA_RESPONSES.TABLE ||
    dashletData.reportType?.value === AREA_RESPONSES.PIVOT_TABLE
  ) {
    return 'table';
  }
  if (dashletData.reportType?.value === AREA_RESPONSES.SALES) {
    if (
      dashletData.salesReportType?.value ===
      SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME
    ) {
      return 'line';
    }

    // More sales report types in the future
  }
  return dashletData.display;
};

const getFrequency = (dashletData) => {
  if (dashletData.reportType?.value === AREA_RESPONSES.SALES) {
    if (
      dashletData?.salesReportType?.value ===
      SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME
    ) {
      return dashletData.datapointFrequency?.value;
    }

    // More sales report types in the future
  }
};

const getCustomEndDate = (dashletData) => {
  if (
    dashletData.reportType?.value === AREA_RESPONSES.SALES &&
    dashletData.customEndDate
  ) {
    if (
      dashletData?.salesReportType?.value ===
      SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME
    ) {
      return dashletData.endDate;
    }

    // More sales report types in the future
  }
};

const getActivityIds = (dashletData) => {
  return dashletData?.activities?.map((activity) => activity.value) ?? [];
};

const useDashboardInteraction = ({
  currentPermission,
  refetchDashboard,
  entityName,
  dashboardQueryKey,
  currentDashboard,
  refetchDashboards,
  isLoading = false,
  rowHeightOverrides,
}) => {
  const [forceShowUIElements, setForceShowUIElements] = useState([]);
  const [showToast] = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // We want to show the UI elements for dragging when a dashlet is first added,
  // and hide them after 1 second
  useEffect(() => {
    if (forceShowUIElements.length) {
      const timer = window.setTimeout(() => {
        setForceShowUIElements([]);
      }, 1000);

      return () => {
        window.clearInterval(timer);
      };
    }
  }, [forceShowUIElements]);

  const {
    breakpoint,
    setBreakpoint,
    rowHeight,
    setContainerWidth,
    editable,
    draggable,
    columnCount,
  } = useDashboardGridBreakpoint(isLoading);

  const dashlets = useMemo(() => {
    return (currentDashboard?.dashlets ?? []).map((dashlet) => {
      const constraints = getDashletConstraints(editableDashlet(dashlet, t));
      const result = {
        ...dashlet,
        layout: {
          ...dashlet.layout,
          ...constraints,
        },
      };

      return getConditionalLayout(result, breakpoint);
    });
  }, [currentDashboard, breakpoint, t]);

  const hasEditPermission = useMemo(() => {
    return getIsEditable(currentPermission);
  }, [currentPermission]);

  const hasDashlets = dashlets.length > 0;

  const resizeHandles = useMemo(() => {
    return editable && hasDashlets && hasEditPermission ? ['se'] : [];
  }, [editable, hasDashlets, hasEditPermission]);

  const createDashletMutation = useMutation(
    (result) => {
      return createDashletQuery(result.dashboardId, result.payload);
    },
    {
      onSuccess: (data) => {
        const { id } = data;
        setForceShowUIElements((prev) => [...prev, id]);
        refetchDashboard?.();
        refetchDashboards?.();
        managedRefetch({ dashletId: id });
      },
      onError: () => {
        showToast({
          variant: toastVariant.FAILURE,
          message: t('{{entityName}} was not successfully created.', {
            entityName,
          }),
        });
      },
    }
  );

  const getDashletPosition = useCallback(
    (dimensions) => {
      return calculateNewDashletPosition(
        { w: dimensions.w, h: dimensions.h },
        currentDashboard.dashlets ?? [],
        true
      );
    },
    [currentDashboard]
  );

  const getNextDashletPosition = useCallback(
    (dashlet = null) => {
      return calculateNextDashletPosition(
        dashlet,
        currentDashboard.dashlets ?? []
      );
    },
    [currentDashboard]
  );

  const managedRefetch = useManagedQueryRefetch();

  const updateDashletMutation = useMutation(
    (result) => {
      return updateDashletQuery(
        result.dashletId,
        result.dashboardId,
        result.payload,
        result.layoutUpdate
      );
    },
    {
      onSettled: (_d, _e, vars) => {
        const { dashletId, layoutUpdate } = vars;
        if (!layoutUpdate) {
          queryClient.invalidateQueries(dashboardQueryKey);
          managedRefetch({
            dashletId,
            queryKey: [...DASHBOARD.DASHLET_DATA, dashletId],
          });
        }
      },
      onError: (_err, _data, context) => {
        queryClient.setQueryData(dashboardQueryKey, context.previous);
        showToast({
          variant: toastVariant.FAILURE,
          message: t('{{entityName}} was not successfully updated.', {
            entityName,
          }),
        });
      },
      onMutate: async (vars) => {
        await queryClient.cancelQueries(dashboardQueryKey);
        const previous = queryClient.getQueryData(dashboardQueryKey);

        const {
          dashboardId,
          dashletId,
          payload,
          skipOptimisticUpdate = false,
        } = vars;

        if (!skipOptimisticUpdate) {
          queryClient.setQueryData(dashboardQueryKey, (prev) => {
            if (prev.id === dashboardId) {
              const result = JSON.parse(JSON.stringify(prev));
              const oldDashlets = result.dashlets;
              const foundIndex = oldDashlets.findIndex(
                (d) => d.id === dashletId
              );
              if (foundIndex >= 0) {
                if (payload.config) {
                  result.dashlets[foundIndex].config = payload.config;
                }

                if (payload.customObject) {
                  result.dashlets[foundIndex].customObject =
                    payload.customObject;
                }

                if (payload.layout) {
                  result.dashlets[foundIndex].layout = payload.layout;
                }

                if (payload.name) {
                  result.dashlets[foundIndex].name = payload.name;
                }

                return result;
              }
            }

            return prev;
          });
        }

        return { previous };
      },
    }
  );

  const managedDelete = useManagedDelete();

  const deleteDashletMutation = useMutation(
    (result) => {
      return deleteDashletQuery(result.dashletId, result.dashboardId);
    },
    {
      onSuccess: (_data, variables) => {
        refetchDashboard?.();
        refetchDashboards?.();
        managedDelete(variables.dashletId);
      },
    }
  );

  const deleteDashlet = useCallback(
    (dashboardId, dashletId) => {
      deleteDashletMutation.mutate({ dashboardId, dashletId });
    },
    [deleteDashletMutation]
  );

  const updateLayouts = useCallback(
    async (layout) => {
      if (editable) {
        await Promise.all(
          layout.map((point) => {
            const foundDashlet = currentDashboard.dashlets.find((d) => {
              const [id] = point.i.split(':');
              return d.id === id;
            });
            if (foundDashlet) {
              // Only update dashlets where the layout differs in one or more
              // properties, so we don't need to update every dashlet for every change
              const needsUpdate =
                foundDashlet.layout.h !== point.h ||
                foundDashlet.layout.w !== point.w ||
                foundDashlet.layout.x !== point.x ||
                foundDashlet.layout.y !== point.y;
              if (needsUpdate) {
                return updateDashletMutation.mutate({
                  dashletId: foundDashlet.id,
                  dashboardId: currentDashboard.id,
                  payload: {
                    layout: {
                      i: foundDashlet.layout.i,
                      h: point.h,
                      w: point.w,
                      x: point.x,
                      y: point.y,
                    },
                  },
                  layoutUpdate: true,
                });
              }
            }
            return undefined;
          })
        );
      }
    },
    [editable, currentDashboard, updateDashletMutation]
  );

  const duplicateDashlet = useCallback(
    async ({ payload, oldDashlet, dashlets, dashboardId }) => {
      // in some cases the old dashlet is not correct
      // like if some dashlets were deleted
      const potentialPosition = getNextDashletPosition(oldDashlet);
      const { layouts, layoutLookup } = dashlets.reduce(
        (acc, dashlet) => {
          acc.layouts.push({
            ...dashlet.layout,
          });
          acc.layoutLookup[dashlet.layout.i] = dashlet.layout;
          return acc;
        },
        { layouts: [], layoutLookup: {} }
      );
      // placing the new dashlet somewhere at the board
      layouts.push({
        ...payload.layout,
        ...oldDashlet.layout,
        i: payload.layout.i,
        x: potentialPosition.x,
        y: potentialPosition.y,
      });
      // correcting the bounds of the layout
      const correctedLayout = correctBounds(layouts, {
        cols: GRID_WIDTH,
      });
      // compacting the layout to get the new positions
      const compactLayout = compact(correctedLayout, 'vertical', GRID_WIDTH);
      const newItemLayout = compactLayout.find(
        ({ i }) => i === payload.layout.i
      );

      const newLayout = moveElement(
        compactLayout,
        newItemLayout,
        potentialPosition.x,
        potentialPosition.y,
        true,
        false,
        'vertical',
        GRID_WIDTH,
        false
      );

      await Promise.all(
        compact(newLayout, 'vertical', GRID_WIDTH)
          .sort((a, b) => a?.layout?.y - b?.layout?.y)
          .filter((point) => {
            const oldLayout = layoutLookup[point.i];
            const moved =
              oldLayout?.y !== point?.y || oldLayout?.x !== point?.x;
            return moved || point.i === payload.layout.i;
          })
          .map((point) => {
            const foundDashlet = dashlets.find(({ layout }) => {
              return point.i === layout.i;
            });
            return (
              foundDashlet &&
              updateDashletMutation.mutate({
                dashletId: foundDashlet.id,
                dashboardId: currentDashboard.id,
                payload: {
                  layout: {
                    i: foundDashlet.layout.i,
                    h: point.h,
                    w: point.w,
                    x: point.x,
                    y: point.y,
                  },
                },
                layoutUpdate: true,
              })
            );
          })
      );
      await createDashletMutation.mutateAsync({
        dashboardId: dashboardId,
        payload: {
          ...payload,
          layout: {
            ...payload.layout,
            ...oldDashlet.layout,
            i: payload.layout.i,
            x: newItemLayout.x,
            y: newItemLayout.y,
          },
        },
      });
    },
    [
      currentDashboard,
      createDashletMutation,
      getNextDashletPosition,
      updateDashletMutation,
    ]
  );
  const mutateDashlet = useCallback(
    async ({
      dashletData,
      isUpdate = false,
      customObject,
      skipOptimisticUpdate = false,
      layoutUpdate,
      isDuplicate = false,
    }) => {
      const dimensions = getDashletDimensions(dashletData);
      const position = getDashletPosition(dimensions);
      const hasFilters =
        Boolean(dashletData.customFilters) ||
        Boolean(dashletData.inGroupFilters) ||
        Boolean(dashletData.notInGroupFilters);
      const oldDashlet = dashlets.find((d) => d.id === dashletData.id);

      const payload = {
        config: {
          entity_type: 'custom_object',
          aggregation: getAggregation(dashletData),
          report_type: dashletData.reportType?.value,
          metric_type: getMetricType(dashletData, oldDashlet),
          chart_type: getChartType(dashletData),
          field: dashletData.field?.value,
          metric_type_extra_info: getMetricExtraInfo(dashletData, oldDashlet),
          frequency: getFrequency(dashletData),
          custom_end_date: getCustomEndDate(dashletData),
          filters: hasFilters
            ? {
                custom_filters: dashletData.customFilters,
                in_group_ids: dashletData.inGroupFilters,
                not_in_group_ids: dashletData.notInGroupFilters,
                custom_object_id:
                  dashletData.customObjectId ??
                  dashletData.selectedFilterObject?.value ??
                  dashletData.selectedFilterObject?.id,
              }
            : undefined,
          fe_extra_info: getFeExtraInfo(dashletData),
          activity_ids: getActivityIds(dashletData),
        },
        custom_object: customObject,
        layout: {
          i: uuidv4(),
          ...dimensions,
          ...position,
        },
        name: dashletData.name || DEFAULT_NAME_KEY,
      };

      if (isUpdate && oldDashlet) {
        const updatePayload = {
          dashletId: dashletData.id,
          dashboardId: currentDashboard.id,
          payload: {
            config: payload.config,
            customObject: payload.custom_object,
            name: payload.name || DEFAULT_NAME_KEY,
            layout: {
              i: oldDashlet.layout.i,
              h: oldDashlet.layout.h,
              w: oldDashlet.layout.w,
              x: oldDashlet.layout.x,
              y: oldDashlet.layout.y,
            },
          },
        };
        const { minW, minH, maxW, maxH } = dimensions;
        const { h: oldHeight, w: oldWidth } = oldDashlet.layout;
        if (minW && oldWidth < minW) {
          updatePayload.payload.layout.w = minW;
        }
        if (maxW && oldWidth > maxW) {
          updatePayload.payload.layout.w = maxW;
        }
        if (minH && oldHeight < minH) {
          updatePayload.payload.layout.h = minH;
        }
        if (maxH && oldHeight > maxH) {
          updatePayload.payload.layout.h = maxH;
        }
        updatePayload.layoutUpdate = layoutUpdate;
        updatePayload.skipOptimisticUpdate = skipOptimisticUpdate;
        await updateDashletMutation.mutateAsync(updatePayload);
      } else {
        if (isDuplicate) {
          await duplicateDashlet({
            payload,
            oldDashlet,
            dashlets,
            dashboardId: currentDashboard.id,
          });
        } else {
          await createDashletMutation.mutateAsync({
            dashboardId: currentDashboard.id,
            payload,
          });
        }
      }
      if (dashletData?.reportType?.value === AREA_RESPONSES.PIVOT_TABLE) {
        queryClient.invalidateQueries([
          'dashboard',
          'dashlet',
          'subrows',
          dashletData.dashletId,
        ]);
      }
    },
    [
      duplicateDashlet,
      getDashletPosition,
      currentDashboard,
      createDashletMutation,
      dashlets,
      updateDashletMutation,
      queryClient,
    ]
  );

  return {
    breakpoint,
    setBreakpoint,
    rowHeight,
    setContainerWidth,
    editable,
    draggable,
    hasEditPermission,
    dashlets,
    resizeHandles,
    getDashletPosition,
    getNextDashletPosition,
    duplicateDashlet,
    createDashletMutation,
    updateDashletMutation,
    deleteDashlet,
    updateLayouts,
    forceShowUIElements,
    setForceShowUIElements,
    mutateDashlet,
    columnCount,
  };
};

export default useDashboardInteraction;
