import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  canEditOptionsForActivity,
  isActualCloseDateField,
} from 'checks/fields';
import useField from 'hooks/useField';
import Switch from 'components/Kizen/Switch';
import Button from 'components/Button';
import { useAsync } from 'react-use';
import TextInput from 'components/Inputs/TextInput';
import { isPipelineObject, isClientObject } from 'components/Modals/utilities';
import { isOwnerField } from 'checks/fields';
import {
  getCustomObjectsSettingsAccess,
  getContactsAccess,
} from 'store/authentication/selectors';
import Modal, { ModalHeader, ModalFooter } from '..';
import { useSelector } from 'react-redux';
import { CustomizeFieldOptionsLink } from './CustomizeFieldOptionsLink';
import { getFieldOption, getValue, mapOptions } from './helpers';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';
import { StyledModalBody, StyledSelect } from './styles';

export default function CustomFieldChooserModal({
  onHide,
  onSave,
  disabled,
  children,
  context = EMPTY_OBJECT,
  modelsOptions = EMPTY_ARRAY,
  onGetRawFieldOptions,
  objectId = null,
  loading,
  activityFields,
  ...others
}) {
  const {
    field,
    title,
    displayNamePrefix,
    activityCustomObjects = [],
  } = context;
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [modelsVal, setModelsVal] = useState(null);
  const [fieldVal, setFieldVal] = useState(null);
  const [fieldName, setFieldName] = useField(
    () => (fieldVal ? fieldVal.label : ''),
    [fieldVal]
  );
  const [fieldRequired, setFieldRequired] = useState(false);
  const contactsAccess = useSelector(getContactsAccess);
  const customObjectsSettingsAccess = useSelector(
    getCustomObjectsSettingsAccess
  );

  useEffect(() => {
    if (objectId && modelsOptions) {
      setModelsVal(modelsOptions.find(({ value }) => value === objectId));
    }
  }, [objectId, modelsOptions, setModelsVal]);

  useEffect(() => {
    if (field) {
      setModelsVal(
        getValue(
          field.customObjectField.customObject.id,
          mapOptions(modelsOptions)
        )
      );
    }
  }, [field, modelsOptions]);

  const { value: fieldOptions = EMPTY_ARRAY, loading: fieldsLoading } =
    useAsync(async () => {
      let options = EMPTY_ARRAY;
      if (modelsVal && modelsOptions) {
        // getting the raw options moved to buider so options can be cached between differen modal openings
        options = await onGetRawFieldOptions(modelsVal, modelsOptions, field);

        if (isPipelineObject(modelsVal.object)) {
          options = options.reduce((acc, category) => {
            const optionsWithoutActualCloseDate = category.options.filter(
              (f) => !isActualCloseDateField(f.item)
            );

            if (optionsWithoutActualCloseDate.length) {
              acc.push({ ...category, options: optionsWithoutActualCloseDate });
            }

            return acc;
          }, []);
        }

        if (field) {
          // when creating it's field.id but is customObjectField after creation
          setFieldVal(getFieldOption(field.customObjectField.id, options));
          setFieldRequired(field.isRequired);
          setFieldName(field.displayName);
        }
      }
      return options;
    }, [modelsVal, modelsOptions, field]);

  const handleSave = async () => {
    try {
      setSubmitting(true);
      let { item: newField } = fieldVal;
      const { meta } = newField;
      // currently not supporting is_masked field activities
      if (meta && 'isMasked' in meta) {
        delete meta.isMasked;
      }
      const object = getValue(modelsVal.value, modelsOptions);
      newField = {
        ...newField,
        meta,
        displayName: fieldName,
        name: fieldName.toLowerCase().trim().replace(/\W/g, '_'),
        isDefault: true,
        isRequired: fieldRequired,
        object: object.id,
        orgField: field || null, // pass the original field back so we can correctly update it
      };
      await onSave(newField);
      onHide();
    } finally {
      setSubmitting(false);
    }
  };
  const categorizedModelsOptions = useMemo(() => {
    return (
      modelsOptions?.reduce(
        (result, item) => {
          const key = activityCustomObjects.length
            ? activityCustomObjects.find((x) => x.id === item.value)
            : 1;
          return result[+!key].options.push(item) && result;
        },
        [
          {
            label: t('Associated Objects'),
            options: [],
          },
          {
            label: t('Other Objects'),
            options: [],
          },
        ]
      ) ?? []
    ).filter((item) => item.options.length);
  }, [activityCustomObjects, modelsOptions, t]);

  const handleModelsOnChange = (option) => {
    setModelsVal(option);
    setFieldVal(null);
  };

  const handleFieldOnChange = (option) => {
    setFieldVal(option);
  };

  const handleFieldNameOnChange = (val) => {
    setFieldName(val);
  };

  const handleFieldRequiredChange = (e) => {
    setFieldRequired(e.target.checked);
  };

  const handleExit = () => {
    onHide();
  };

  const canEditFieldOptions = useMemo(() => {
    if (modelsVal) {
      if (isClientObject(modelsVal.object)) {
        return contactsAccess.objectSettings.customizeFields.edit;
      } else {
        return customObjectsSettingsAccess[modelsVal.object.id].customizeFields
          .edit;
      }
    } else {
      return true;
    }
  }, [contactsAccess, customObjectsSettingsAccess, modelsVal]);

  return (
    <Modal onHide={handleExit} {...others}>
      <ModalHeader onClickClose={handleExit}>
        {title || t('Choose Custom Field')}
      </ModalHeader>
      <StyledModalBody>
        {!objectId && (
          <StyledSelect
            label={t('Choose Object')}
            options={categorizedModelsOptions}
            value={modelsVal}
            onChange={handleModelsOnChange}
            menuInline
            fullWidth
            placeholder={t('Choose Object')}
            disabled={!!field}
            loadItems={loading}
          />
        )}
        <>
          {modelsVal && (
            <StyledSelect
              label={t('Choose Field')}
              options={fieldsLoading ? [] : fieldOptions}
              value={fieldVal}
              onChange={handleFieldOnChange}
              menuInline
              fullWidth
              placeholder={t('Choose Field')}
              disabled={!!field}
              loadItems={fieldsLoading}
            />
          )}

          {fieldVal && (
            <TextInput
              label={[displayNamePrefix, t('Display Name')]
                .filter(Boolean)
                .join(' ')}
              placeholder={[displayNamePrefix, t('Display Name')]
                .filter(Boolean)
                .join(' ')}
              value={fieldName}
              onChange={handleFieldNameOnChange}
              error={fieldName === ''}
              margin={3}
            />
          )}

          {fieldVal && !isOwnerField(fieldVal?.item) && (
            <Switch
              label={t('Field is Required')}
              textPlacement="regular-top"
              checked={fieldRequired}
              value="true"
              onChange={handleFieldRequiredChange}
              disabled={field && field.isHidden}
            />
          )}

          {Boolean(modelsVal && fieldVal) &&
            canEditOptionsForActivity(fieldVal.item) &&
            canEditFieldOptions && (
              <CustomizeFieldOptionsLink
                model={modelsVal.object}
                field={fieldVal.item}
              />
            )}
        </>
      </StyledModalBody>
      <ModalFooter showBorder={false}>
        <Button
          onClick={handleSave}
          disabled={submitting || !(fieldVal && fieldName !== '')}
        >
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

CustomFieldChooserModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  context: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  modelsOptions: PropTypes.array.isRequired,
  onGetRawFieldOptions: PropTypes.func.isRequired,
  objectId: PropTypes.object,
};
