import { snakeToCamelCase } from 'services/helpers';
import { getColumns } from '../Common/helpers/table';
import { DEFAULT_PAGE_CONFIG } from './constants';
import { NON_HIDABLE_COLUMNS } from 'components/Wizards/CustomObject/steps/CustomLayout/subsections/DefaultColumns/constants';

export const getColumnsPayload = (rightItems, leftItems) => [
  ...rightItems.map(({ id, label }) => ({
    id,
    label,
    visible: true,
  })),
  ...leftItems.reduce((acc, { id, label, isGroupedItem }) => {
    if (!isGroupedItem) {
      return acc.concat({
        id,
        label,
        visible: false,
      });
    }
    return acc;
  }, []),
];

export const isNonHidebleColumn = ({ id }) =>
  NON_HIDABLE_COLUMNS.some((columnId) => id === columnId);

export const getRightItems = (groupedColumns, columnSettings) => {
  const suppressedFields = groupedColumns.flatMap((group) =>
    group.items.filter((el) => !!el?.isSuppressed).map(({ id }) => id)
  );
  return columnSettings.reduce((acc, { label, id, visible }) => {
    if (visible) {
      return acc.concat({
        label,
        id,
        icon: '',
        sortable: true,
        disabled: isNonHidebleColumn({ id }),
        shouldBeDisabledInLeftColumn: suppressedFields.includes(id),
      });
    }
    return acc;
  }, []);
};

export const getLeftItems = (groupedColumns, columnSettings, rightItems) =>
  groupedColumns.flatMap((group) => {
    const complexGroup = {
      ...group,
      items: group.items.reduce((acc, column) => {
        const settingsItem = columnSettings.find(
          (el) => el.id === column.id || el.id === snakeToCamelCase(column.name)
        );
        const rightItem = rightItems.find(
          (el) => el.id === column.id || el.id === snakeToCamelCase(column.name)
        );
        if (settingsItem && !rightItem) {
          return acc.concat({
            label: settingsItem.label,
            id: settingsItem.id,
            icon: '',
            sortable: true,
            disabled: !!column?.isSuppressed,
          });
        }
        return acc;
      }, []),
    };
    return [{ ...complexGroup }, ...complexGroup.items];
  });

export const getLeftColumnOrdering = (groupedColumns, defaultColumnSettings) =>
  groupedColumns.flatMap(({ items, id }) => {
    const groupItems = items.reduce((acc, column) => {
      const settingsItem = defaultColumnSettings.find(
        (el) => el.id === column.id || el.id === snakeToCamelCase(column.name)
      );
      if (settingsItem) {
        acc.push(settingsItem.id);
      }
      return acc;
    }, []);
    return [id, ...groupItems];
  });

export const mergeColumnSettings = (
  defaultColumnSettings,
  savedColumnSettings
) => {
  if (!savedColumnSettings) {
    return defaultColumnSettings;
  }
  const defaultColumnIds = defaultColumnSettings.reduce(
    (acc, item) => ({
      ...acc,
      [item.id]: {
        label: item.label,
        fieldId: item?.fieldId,
      },
    }),
    {}
  );
  const savedColumnIds = savedColumnSettings.reduce(
    (collect, { id }) => ({
      ...collect,
      [id]: true,
    }),
    {}
  );

  return [
    ...savedColumnSettings.reduce((acc, item) => {
      if (defaultColumnIds[item.id]) {
        return [
          ...acc,
          {
            ...item,
            label: defaultColumnIds[item.id].label,
            fieldId: defaultColumnIds[item.id].fieldId,
          },
        ];
      }
      return acc;
    }, []),
    ...defaultColumnSettings
      .filter(({ id }) => !savedColumnIds[id])
      .map((el) => ({
        ...el,
        visible: NON_HIDABLE_COLUMNS.includes(el.id),
      })),
  ];
};

export const getColumnSettings = (defaultColumnSettings, pageConfig = {}) => {
  return mergeColumnSettings(defaultColumnSettings, getColumns(pageConfig));
};

export const getColumnConfigPayload = (
  columnSettings,
  pageConfig = DEFAULT_PAGE_CONFIG
) => {
  const [columns, columnOrder] = columnSettings.reduce(
    (acc, el) => {
      if (el.visible) {
        acc[0][el.id] = { ...pageConfig.columns[el.id], ...el };
        acc[1].push(el.id);
      }
      return acc;
    },
    [{}, []]
  );
  return {
    columnOrder,
    columns,
  };
};

export const getPathBack = (path, backSteps = 1) =>
  path.split('/').slice(0, -backSteps).join('/');

export const countVisibleColumns = (columns = []) =>
  columns.reduce((acc, { visible }) => (acc += visible ? 1 : 0), 0);
