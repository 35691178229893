import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import useAsync from 'react-use/lib/useAsync';
import useField from 'hooks/useField';
import FieldService from 'services/FieldService';
import { Text } from 'app/typography';
import Select from 'components/Kizen/Select';
import { EMPTY_OBJECT, EMPTY_ARRAY } from 'utility/fieldHelpers';

const Wrapper = styled.div`
  margin-bottom: 15px;
`;

export default function PriceOptions({
  updateField,
  formData = EMPTY_OBJECT,
  data = EMPTY_OBJECT,
}) {
  const { t } = useTranslation();
  const { loading, value: currencies = EMPTY_ARRAY } = useAsync(async () => {
    const c = await FieldService.getCurrencies();
    return c.map(([code, description, symbol]) => ({
      value: code,
      // There are currencies returned from the API that don't have symbols
      label: `${symbol || code} - ${description} - ${code}`,
      meta: { code, description, symbol },
    }));
  }, []);

  const currencyCode = formData.moneyOptions?.currency || 'USD';

  const [currency, setCurrency] = useField(() => {
    return currencies.find((opt) => opt.value === currencyCode) || null;
  }, [currencyCode, currencies]);

  useEffect(() => {
    if (currency) {
      updateField('moneyOptions', {
        id: formData.moneyOptions?.id,
        currency: currency.value || formData.moneyOptions?.currency,
        symbol: currency.meta?.symbol || formData.moneyOptions?.symbol,
      });
    }
  }, [
    currency,
    formData.moneyOptions?.id,
    formData.moneyOptions?.currency,
    formData.moneyOptions?.symbol,
    updateField,
  ]);

  if (loading) {
    return null;
  }

  return (
    <Wrapper>
      <Text>{t('Choose Currency')}</Text>
      <Select
        menuPlacement={data.field ? 'auto' : 'top'}
        value={currency}
        options={currencies}
        onChange={setCurrency}
        disabled={!currencies.length}
      />
    </Wrapper>
  );
}
