import { namedToOption } from 'services/helpers';
import _set from 'lodash/set';
import _isEmpty from 'lodash/isEmpty';
import { AUTOMATION_STATE } from 'services/Automation2Service';
import { isContact } from 'utility/fieldHelpers';
// default values for dateOffset dropdown

export const dateOffsetValuesTranslated = (t) => [
  {
    label: t('On Date'),
    value: 'on_day',
  },
  {
    label: t('Day(s) Before'),
    value: 'days_before',
  },
  {
    label: t('Day(s) After'),
    value: 'days_after',
  },
];

export const unitDisplayTranslated = (t) => ({
  minutes: {
    single: t('Minute'),
    plural: t('Minutes'),
  },
  hours: {
    single: t('Hour'),
    plural: t('Hours'),
  },
  days: {
    single: t('Day'),
    plural: t('Days'),
  },
});

export const dateDueTypeOptionsTranslated = (t) => [
  {
    label: t('Immediately'),
    value: 'immediately',
  },
  {
    label: t('With Delay'),
    value: 'withDelay',
  },
  {
    label: t('On or Around Date'),
    value: 'onOrAroundDate',
  },
];

export const dictionaryDateDueTypeOptionsTranslated = (t) =>
  dateDueTypeOptionsTranslated(t).reduce(
    (acc, { label, value }) => ({
      ...acc,
      [value]: { value, label },
    }),
    {}
  );
/*
 ** These are recipient values
 */
export const notifyMemberViaEmailTypes = {
  NONE: 'none',
  LAST_ACTIVE: 'last_active',
  LAST_ACTIVE_ROLE: 'last_active_role',
  OWNER: 'owner',
  SPECIFIC_TEAM_MEMBERS: 'employees',
  TEAM_SELECTOR_FIELD: 'team_selector_field',
};

export const notifyMemberViaTextTypes = {
  NONE: 'none',
  LAST_ACTIVE: 'last_active',
  LAST_ACTIVE_ROLE: 'last_active_role',
  OWNER: 'owner',
  SPECIFIC_TEAM_MEMBER: 'employee',
  TEAM_SELECTOR_FIELD: 'team_selector_field',
};

export const fromTypeOptionsTranslated = (
  t,
  contextIsCustomObject = true,
  addPrefixNone = false,
  isEmail = true
) => {
  return [
    addPrefixNone && {
      label: t('None'),
      value: notifyMemberViaEmailTypes.NONE,
    },
    {
      label: t('Last Active Team Member (Any Role)'),
      value: notifyMemberViaEmailTypes.LAST_ACTIVE,
    },
    {
      label: t('Last Active Team Member (Specific Role)'),
      value: notifyMemberViaEmailTypes.LAST_ACTIVE_ROLE,
    },
    contextIsCustomObject && {
      label: t('Owner'),
      value: notifyMemberViaEmailTypes.OWNER,
    },
    {
      label: isEmail ? t('Specific Team Members') : t('Specific Team Member'),
      value: isEmail
        ? notifyMemberViaEmailTypes.SPECIFIC_TEAM_MEMBERS
        : notifyMemberViaTextTypes.SPECIFIC_TEAM_MEMBER,
    },
    {
      label: t('Custom Team Selector Field'),
      value: notifyMemberViaEmailTypes.TEAM_SELECTOR_FIELD,
    },
  ].filter(Boolean);
};

export const dictionaryFromTypeOptionsTranslated = (
  t,
  contextIsCustomObject,
  addPrefixNone,
  isEmail
) =>
  fromTypeOptionsTranslated(
    t,
    contextIsCustomObject,
    addPrefixNone,
    isEmail
  ).reduce(
    (acc, { label, value }) => ({
      ...acc,
      [value]: { value, label },
    }),
    {}
  );

/*
 ** These are email sender values
 */
export const sendRelatedContactEmailTypes = {
  LAST_ACTIVE: 'last_active',
  LAST_ACTIVE_ROLE: 'last_active_role',
  SPECIFIC_TEAM_MEMBER: 'employee',
};

export const RECEIVING_INBOX = {
  any: 'any',
  teamRole: 'specific_team_role',
  teamMember: 'specific_team_member',
};

export const CRITERIA = {
  any: 'any_team_member',
  last: 'last_team_member',
};

export const nameDescriptor = ({ name }) => name;
export const labelDescriptor = ({ label }) => label;
export const displayNameDescriptor = ({ displayName }) => displayName;
export const descriptionDescriptor = ({ description }) => description;

export const checkDeleted = (
  messageDictionary,
  addErrorMessage,
  objectToCheck,
  descriptorCallback,
  itemName,
  objectPath,
  objectErrorTrans = (o) => o
) => {
  const errors = {};
  if (!objectToCheck && itemName) {
    addErrorMessage(
      `${messageDictionary.thereWasanErrorLoadingThe} '${itemName}'`
    );
    _set(errors, objectPath, itemName);
  }

  if (objectToCheck?.deleted) {
    const descriptorItem = descriptorCallback(objectToCheck);
    _set(errors, objectPath, objectErrorTrans(objectToCheck));
    addErrorMessage(
      `${messageDictionary.thereWasanErrorLoadingThe} '${
        descriptorItem ? descriptorItem : messageDictionary.associatedItem
      }'`
    );
  }
  return errors;
};

// eventualy this will replace checkDeleted
export const _checkDeleted = ({
  messageDictionary,
  addErrorMessage,
  objectToCheck,
  descriptorCallback,
  itemName = undefined,
  objectPath = 'placeholder',
  objectErrorTrans = (o) => o,
}) =>
  checkDeleted(
    messageDictionary,
    addErrorMessage,
    objectToCheck,
    descriptorCallback,
    itemName,
    objectPath,
    objectErrorTrans
  );

export const checkNestedDeleted = (
  messageDictionary,
  addErrorMessage,
  parentObject,
  objectToCheckPath,
  descriptorCallback
) =>
  (parentObject || []).forEach((object) => {
    checkDeleted(
      messageDictionary,
      addErrorMessage,
      object[objectToCheckPath],
      descriptorCallback
    );
  });

export const checkListDeleted = (
  messageDictionary,
  addErrorMessage,
  parentObject,
  descriptorCallback,
  itemName = false,
  objectPath,
  objectErrorTrans = (o) => o,
  checkChildren = true
) => {
  const errors = {};

  if (itemName && !parentObject?.length) {
    addErrorMessage(
      `${messageDictionary.thereWasanErrorLoadingThe} '${itemName}'`
    );
    _set(errors, objectPath, itemName);
    return errors;
  }
  if (checkChildren) {
    const errorList = (parentObject || []).reduce((acc, object) => {
      const error = _checkDeleted({
        messageDictionary,
        addErrorMessage,
        objectToCheck: object,
        descriptorCallback,
        objectPath: 'name',
        objectErrorTrans,
      });
      if (!_isEmpty(error)) {
        acc.push(error);
      }
      return acc;
    }, []);
    if (errorList.length) {
      _set(
        errors,
        objectPath,
        errorList.flatMap(({ name }) => name)
      );
    }
  }

  return errors;
};

// eventualy this will replace checkListDeleted
export const _checkListDeleted = ({
  messageDictionary,
  addErrorMessage,
  parentObject,
  descriptorCallback,
  itemName = false,
  objectPath,
  objectErrorTrans = (o) => o,
  checkChildren = true,
}) =>
  checkListDeleted(
    messageDictionary,
    addErrorMessage,
    parentObject,
    descriptorCallback,
    itemName,
    objectPath,
    objectErrorTrans,
    checkChildren
  );
export const setDelayAmount = (delayAmount) => delayAmount || 0;

export const validStageId = (id, model) =>
  id ? model?.pipeline?.stages.some((stage) => stage.id === id) : true;

export const getStageName = (id, model, messageDictionary) => {
  if (id) {
    if (model?.pipeline?.stages) {
      const stage = model?.pipeline?.stages.find((stage) => stage.id === id);
      return namedToOption({
        id,
        name: stage?.name || messageDictionary.deleted,
      });
    }
  }
  return namedToOption({
    id,
    name: messageDictionary.anyStage,
  });
};

// this is only used for the first option
export const goalOptions = [
  {
    label: 'Never',
    value: 'never',
  },

  {
    label: 'After Delay',
    value: 'delay',
  },

  {
    label: 'On Specific Date',
    value: 'specific_datetime',
  },
];

export const getRelatedObjectField = (
  relatedObjectField,
  primaryRelatedObjectField
) => {
  let field = relatedObjectField || primaryRelatedObjectField;
  if (field?.deleted) {
    field = null;
  }
  return {
    label: field?.displayName,
    value: field?.id,
  };
};
export const getThisRecordField = (thisRecordField) => {
  let field = thisRecordField;
  if (field?.deleted) {
    field = null;
  }
  return {
    label: field?.displayName,
    value: field?.id,
    fieldData: field,
  };
};

export const getAutomationTargetRelationshipField = (
  automationTargetRelationshipField
) => {
  if (automationTargetRelationshipField?.deleted) {
    return null;
  }
  return {
    label: automationTargetRelationshipField?.displayName,
    value: automationTargetRelationshipField?.id,
  };
};

export const getRelatedObject = (relatedObject) => {
  if (relatedObject?.deleted) {
    return null;
  }

  return {
    value: relatedObject?.id,
    label: relatedObject?.objectName,
    relation: {
      ...relatedObject,
      relatedObject: relatedObject?.id,
    },
  };
};

export const allowChange = (step) =>
  step ? (step?.id || '').startsWith('new') : true;

const statusLookupForQueryFilter = {
  [AUTOMATION_STATE.ACTIVE]: AUTOMATION_STATE.ACTIVE,
  [AUTOMATION_STATE.COMPLETED]: [
    AUTOMATION_STATE.CANCELLED,
    AUTOMATION_STATE.COMPLETED,
  ],
  [AUTOMATION_STATE.PAUSED]: [
    'inactive',
    AUTOMATION_STATE.PAUSED,
    AUTOMATION_STATE.PAUSED_BY_FAILURE,
  ],
};

const getQueryFilterLink = ({ id, customObject }, status) => {
  const baseLink = isContact(customObject)
    ? '/clients'
    : `/custom-objects/${customObject.id}`;

  return `${baseLink}?${new URLSearchParams({
    automations: JSON.stringify({
      type: 'automation2',
      subtype: 'automation',
      automation_id: id,
      status: statusLookupForQueryFilter[status],
    }),
  })}`;
};

export const getAutomationStatsConfig = (automation, enabled, t) => {
  return enabled
    ? [
        {
          label: t('Active'),
          value: automation.numberActive,
          href: getQueryFilterLink(automation, AUTOMATION_STATE.ACTIVE),
          tooltipLabel: t('View {{ status }} Records', {
            status: t('Active'),
          }),
          status: AUTOMATION_STATE.ACTIVE,
        },
        {
          label: t('Completed'),
          value: automation.numberCompleted,
          href: getQueryFilterLink(automation, AUTOMATION_STATE.COMPLETED),
          tooltipLabel: t('View {{ status }} Records', {
            status: t('Completed'),
          }),
          status: AUTOMATION_STATE.COMPLETED,
        },
        {
          label: t('Paused'),
          value: automation.numberPaused,
          href: getQueryFilterLink(automation, AUTOMATION_STATE.PAUSED),
          tooltipLabel: t('View {{ status }} Records', {
            status: t('Paused'),
          }),
          status: AUTOMATION_STATE.PAUSED,
        },
      ]
    : [];
};
