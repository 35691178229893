import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';

export const StyledIconMenu = styled(Icon)`
  && {
    width: 20px;
    height: 5px;
    margin-left: 2px;

    svg {
      width: 20px;
      height: 5px;
      margin-bottom: 10px;
    }
  }
`;

export const StatsMenuWrapper = styled.div`
  text-align: right;
`;
