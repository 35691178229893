import { pick } from 'lodash';
import React from 'react';

/* This is a utility for styling components via a wrapper,
 * which makes it simple to apply some style exceptions to multiple
 * elements at once.  This utility does not create any wrapper elements.
 *
 * Example:
 *
 * const NoMargin = styled(StylePassthrough)`
 *   margin: 0;
 * `;
 *
 * <NoMargin>
 *   <h2>Some Header</h2>
 * </NoMargin>
 *
 * <NoMargin>
 *   <p>Some Text</p>
 *   <p>Some Text</p>
 * </NoMargin>
 *
 */

const StylePassthrough = React.forwardRef(function StylePassthrough(
  { className, style, children, passthrough, ...props },
  ref
) {
  const passthroughProps = pick(props, passthrough || []);
  return React.Children.map(children, (el) => {
    return React.cloneElement(el, {
      ref, // TODO this does steal the ref if el already has one, hmm!
      className: [el.props.className, className].filter(Boolean).join(' '),
      style: { ...style, ...el.props.style },
      ...passthroughProps,
    });
  });
});

export default StylePassthrough;
