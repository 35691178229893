import { useRef } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Avatar from 'components/Charts/ScheduledActivities/Avatar';
import Tooltip from 'components/Kizen/Tooltip';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { EmployeeDropdown } from '../EmployeeDropdown';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

const UserImage = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-flex;
  vertical-align: middle;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  ${({ imageURL }) =>
    imageURL &&
    css`
      background-image: url(${imageURL});
    `}

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const BubbleWrapper = ({ imageURL }) => {
  return <UserImage imageURL={imageURL} className="user-image" />;
};

const AvatarWrapper = ({ fullName, isRole = false }) => {
  fullName = isRole ? fullName.split(/\s+/g)[0] : fullName;
  return <Avatar className="user-avatar" fullName={fullName} isRole={isRole} />;
};

const Hoverable = styled.span`
  cursor: pointer;
`;

const defaultOffsets = {
  modifiers: {
    offset: { offset: '0, -3' }, // Touch top of text
    preventOverflow: { enabled: false },
  },
};

const BubbleProfileImage = ({
  fullName,
  imageURL = null,
  showToolTip = false,
  popperConfig,
  placement = 'top',
  value,
  activity,
  onUpdateActivity,
}) => {
  const isRole = activity?.role;
  const Variant = !!imageURL && !isRole ? BubbleWrapper : AvatarWrapper;
  const [show, setShow] = useToggle(false);
  const ref = useRef(null);
  const { t } = useTranslation();
  return (
    <>
      {showToolTip ? (
        <OverlayTrigger
          overlay={
            <Tooltip
              label={(activity?.role ? t('Role') + ': ' : '') + fullName}
            />
          }
          placement={placement}
          popperConfig={popperConfig || defaultOffsets || {}}
        >
          <Hoverable ref={ref} onClick={setShow}>
            <Variant imageURL={imageURL} fullName={fullName} isRole={isRole} />
          </Hoverable>
        </OverlayTrigger>
      ) : (
        <Variant imageURL={imageURL} fullName={fullName} isRole={isRole} />
      )}
      {activity && show && activity.access.edit ? (
        <EmployeeDropdown
          clickRef={ref}
          setShow={setShow}
          activity={activity}
          value={value || {}}
          onUpdateActivity={onUpdateActivity}
        />
      ) : null}
    </>
  );
};

export default BubbleProfileImage;
