import { useMemo } from 'react';
import MultiSelect from 'components/Inputs/MultiSelect';
import {
  ApplyMenuButton,
  ClearMenuButton,
  TopMenuButton,
} from 'components/Kizen/Menu';
import useField from 'hooks/useField';
import { useTranslation } from 'react-i18next';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { FIELD_TYPES } from 'utility/constants';
import { useInfiniteFacetedOptions } from '../hooks/useInfiniteFacetedOptions';
import { useAutoFocus } from '../hooks/useAutoFocus';

export const FacetFieldMultiselect = ({
  field,
  model,
  onChange,
  error, // exclude from ...props
  onHide,
  value,
  isReadOnly,
  isLoading,
  fullFilterObject,
  search: entitySearch,
  showFacetCounts,
  hideFacetCountsOnTimeout,
  autoFocus,
  ...props
}) => {
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = useField(value);
  const [includeFacetCount, setIncludeFacetCount] = useField(showFacetCounts);

  const chosenValueIds = useMemo(
    () => (innerValue || []).map(({ value }) => value),
    [innerValue]
  );

  const { selectRef, typeaheadProps } = useInfiniteFacetedOptions({
    field,
    model,
    includeFacetCount,
    fullFilterObject,
    entitySearch,
    hideFacetCountsOnTimeout,
    chosenValueIds,
  });

  useAutoFocus(selectRef.current?.select?.inputRef, isReadOnly || !autoFocus);

  const handleClickApply = () => {
    onHide();
    onChange(innerValue.map(({ value }) => value));
  };

  const handleClickClear = () => {
    onHide();
    onChange(EMPTY_ARRAY);
  };

  return (
    <MultiSelect
      key={includeFacetCount}
      menuTopButton={
        includeFacetCount ? (
          <TopMenuButton
            onClick={() => {
              setIncludeFacetCount(false);
            }}
          >{`${t('Show All Options (No Facets)')}`}</TopMenuButton>
        ) : null
      }
      ref={selectRef}
      {...props}
      {...typeaheadProps}
      field={field}
      value={innerValue}
      onChange={setInnerValue}
      isReadOnly={isReadOnly}
      disabled={isReadOnly}
      menuIsOpen={!isReadOnly}
      menuLeftButton={
        !isReadOnly && (
          <ClearMenuButton onClick={handleClickClear}>
            {t('Clear & Close')}
          </ClearMenuButton>
        )
      }
      menuRightButton={
        !isReadOnly && <ApplyMenuButton onClick={handleClickApply} />
      }
      hideRemoveIcon={isReadOnly}
      placeholder={t('Find Options')}
      isColored={field?.fieldType === FIELD_TYPES.Status.type}
      showColorIndicatorInPill={field?.fieldType === FIELD_TYPES.Status.type}
    />
  );
};
