import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import { layers } from 'app/spacing';
import { useBuilderContext } from '../BuilderContext';
import { CANVAS_PADDING } from './constants';

const Container = styled.div`
  position: absolute;
  z-index: ${layers.content(1)};
  margin-left: ${CANVAS_PADDING}px;
  height: ${({ height }) => (height > 0 ? `${height}px` : '100%')};
  width: ${({ width }) => (width > 0 ? `${width}px` : '100%')};
  min-height: ${CANVAS_PADDING}px;
`;

export const PageBuilderCanvasHeaderContainer = ({
  height = CANVAS_PADDING,
  children,
  ...rest
}) => {
  const { canvasWrapper } = useBuilderContext();
  const [width, setWidth] = useState(
    canvasWrapper?.getBoundingClientRect()?.width ?? 0
  );

  const observer = useMemo(() => {
    return new ResizeObserver((entries) => {
      if (entries[0]?.target) {
        setWidth(entries[0].target.getBoundingClientRect().width);
      }
    });
  }, []);

  useEffect(() => {
    if (canvasWrapper) {
      observer.observe(canvasWrapper);
      return () => observer.unobserve(canvasWrapper);
    }
  }, [observer, canvasWrapper]);

  return (
    <Container height={height} width={width} {...rest}>
      {children}
    </Container>
  );
};
