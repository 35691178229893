import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import { getDefaultEmailTemplate } from '@kizen/page-builder/utils/build';
import FileService from 'services/FileService';
import { getChosenBusiness } from 'store/authentication/selectors';

const useBusinessData = () => {
  const chosenBusiness = useSelector(getChosenBusiness);
  return useMemo(
    () => ({
      logo: chosenBusiness?.logo?.url
        ? {
            url: chosenBusiness?.logo?.url,
            name: chosenBusiness?.logo?.name,
            id: chosenBusiness?.logo?.id,
          }
        : null,
      name: chosenBusiness?.name,
      primaryColor: chosenBusiness?.primary_color,
    }),
    [chosenBusiness]
  );
};

export const useDefaultTemplate = (currentTemplate) => {
  const { t } = useTranslation();
  const businessData = useBusinessData();

  const state = useAsync(async () => {
    if (currentTemplate) return currentTemplate;

    try {
      const [attractAndEngageImage, laserFocusImage] = await Promise.all([
        FileService.getImageByCommonKey(
          'img-attract-and-engage-your-ideal-buyers.png'
        ),
        FileService.getImageByCommonKey(
          'img-laser-focus-outreach-to-make-interactions-count.png'
        ),
      ]);
      return getDefaultEmailTemplate(
        businessData,
        attractAndEngageImage,
        laserFocusImage,
        t
      );
    } catch (err) {
      return null;
    }
  }, [currentTemplate, businessData]);
  return state.value;
};
