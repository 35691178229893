import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal from 'components/Modals/presets/BasicModal';
import Automation2Service from 'services/Automation2Service';
import KizenTypography from 'app/kizentypo';
import Switch from 'components/Kizen/Switch';
import { StyledModalText, StyledSwitchWrapper } from './styled';
import { grayScale } from 'app/colors';
import { useAutomationsDispatch } from '../store/react';
import { actions } from '../store';
import { toastVariant } from 'components/ToastProvider';

export const BusinessCalendarModal = ({ automation, onConfirm, ...others }) => {
  const { t } = useTranslation();
  const [skipNonWorkingDays, setSkipNonWorkingDays] = useState(
    automation.skipNonWorkingDays
  );

  const dispatch = useAutomationsDispatch();

  const handleConfirm = async () => {
    try {
      const updatedAutomation = await Automation2Service.update({
        id: automation.id,
        skipNonWorkingDays,
        lastRevision: automation.revision,
      });
      dispatch(
        actions.toast({
          variant: toastVariant.SUCCESS,
          message: t('Business Calendar Settings Updated'),
        })
      );
      onConfirm(updatedAutomation);
    } catch (error) {
      dispatch(
        actions.toast({
          variant: toastVariant.FAILURE,
          message: t('Business Calendar Settings Update Failed'),
        })
      );
    }
  };

  const handleToggle = ({ target: { checked } }) => {
    setSkipNonWorkingDays(checked);
  };

  return (
    <BasicModal
      heading={t('Business Calendar Settings')}
      onConfirm={handleConfirm}
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      {...others}
    >
      <StyledSwitchWrapper>
        <Switch
          onClick={handleToggle}
          checked={skipNonWorkingDays}
          removeMargin
        />
        <KizenTypography>{t('Skip Non-Business Days')}</KizenTypography>
      </StyledSwitchWrapper>
      <StyledModalText fontStyle="italic" color={grayScale.mediumDark}>
        {t(
          'Any automation delay will skip over non-business days. Examples of effected steps are delays, goals, and scheduled activities.'
        )}
      </StyledModalText>
      <KizenTypography
        type="anchor"
        target="_blank"
        href={'/settings/business/calendar'}
      >
        {t('View Business Calendar')}
      </KizenTypography>
    </BasicModal>
  );
};
