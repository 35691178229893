import { useEffect, useState } from 'react';
import GenericWizard from 'components/GenericWizard';
import BasicModalWithConfirmation from 'components/Modals/presets/BasicModalWithConfirmation';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { _modalSize } from 'components/Modals';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { FIELD_TYPES } from 'utility/constants';
import { isEmailStatusField } from 'checks/fields';
import Loader from 'components/Kizen/Loader';
import { useEntityPredefinedOptions } from 'ts-components/hooks/activities/useEntityPredefinedOptions';
import { generateRedirtectURL } from 'components/Inputs/RedirectUrlInput';

const flatValue = (value) => {
  if (Array.isArray(value)) {
    return value.map((v) => flatValue(v));
  }
  if (value && typeof value === 'object' && 'id' in value) return value.id;
  if (value && typeof value === 'object' && 'e164' in value) return value.e164;
  return value;
};
const isFieldShouldBePresent = (field, relatedObjects) => {
  return !(
    isEmailStatusField(field) &&
    !field.value &&
    !relatedObjects.find(({ isContact }) => isContact)
  );
};

export const CompleteActivityModal = ({
  predefinedOptions: existingPredefinedOptions,
  networkError,
  activity = {},
  onConfirm,
  disabled,
  isLoading,
  currentEntity,
  currentObject,
  ...modalProps
}) => {
  const { fields: activityFieldsError = [] } = networkError;
  const [blockSave, setBlockSave] = useState(false);
  const [{ data, isComplete, isDirty, errors = [] }, setWizardData] = useState({
    data: activity,
    isComplete: false,
  });

  const { predefinedOptions, loading: predefinedOptionsLoading } =
    useEntityPredefinedOptions({
      currentEntity,
      currentObject,
      activity,
      existingPredefinedOptions,
    });

  const { t } = useTranslation();

  const [showErrors, setShowErrors] = useState(activityFieldsError);

  useEffect(() => {
    activityFieldsError.length && setShowErrors(activityFieldsError);
  }, [activityFieldsError]);

  const { width } = useWindowSize();

  if (!modalProps.show || !data) {
    return null;
  }

  return (
    <BasicModalWithConfirmation
      dirty={isDirty}
      typeOfContent={width >= _modalSize.medium ? 'wizard' : 'wizard-mobile'}
      buttonText={t('Complete')}
      disabled={disabled || !isComplete || blockSave}
      leftBtn={null}
      hasOneBtnSubmit
      heading={
        activity?.activityObject
          ? `${t('Completing')} ${activity?.activityObject.name} ${t(
              'Activity'
            )}`
          : t('Log Activity')
      }
      size="medium"
      {...modalProps}
      onConfirm={async () => {
        if (errors.some(Boolean)) setShowErrors(errors);
        else {
          const {
            passVariablesOnRedirect,
            redirectUrl,
            submissionAction,
            redirectParameterFields,
            ...rest
          } = data;
          const result = await onConfirm({
            activityObjectId: activity.activityObject.id,
            payload: {
              ...rest,
              fields: rest.fields
                .filter((field) =>
                  isFieldShouldBePresent(field, rest.relatedObjects)
                )
                .map(({ id, displayName, fieldType, value }) => {
                  if (
                    value &&
                    typeof value === 'string' && // we do not convert old object phone numbers
                    fieldType === FIELD_TYPES.PhoneNumber.type &&
                    !isValidPhoneNumber(value)
                  ) {
                    value = null;
                  }
                  return {
                    id,
                    displayName,
                    fieldType,
                    value: flatValue(value),
                  };
                }),
            },
            dirtyFields: isDirty,
          });

          if (result?.success) {
            setWizardData({ isComplete: true });
            if (submissionAction === 'redirect' && redirectUrl) {
              const url = generateRedirtectURL(data);

              if (result?.mutationResult?.id) {
                url.searchParams.set(
                  'logged_activity_id',
                  result.mutationResult.id
                );
              }

              window.open(url.toString(), '_blank');
            }
          }
        }
      }}
    >
      {isLoading || predefinedOptionsLoading ? (
        <Loader loading />
      ) : (
        <GenericWizard
          wizard="CompleteActivitytWizard"
          onChange={setWizardData}
          data={activity || {}}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          predefinedOptions={predefinedOptions}
          setBlockSave={setBlockSave}
        />
      )}
    </BasicModalWithConfirmation>
  );
};
