import { useTranslation } from 'react-i18next';
import DynamicTags from 'components/Fields/FieldInput/DynamicTags';

const ChangeTags = ({
  tagsToAdd,
  tagsToRemove,
  onAddTagsToAdd,
  onAddTagsToRemove,
  field,
  ...others
}) => {
  const { t } = useTranslation();

  return (
    <>
      <DynamicTags
        ref={null}
        value={tagsToAdd}
        onChange={onAddTagsToAdd}
        validate={() => true}
        objectId={null}
        error={false}
        handleUpdateFieldOption={() => {}}
        fieldType={field?.fieldType}
        field={field}
        label={t('Choose Tags to Add')}
        placeholder={t('Find Options to Add')}
        margin
        inModal
        menuInline
        {...others}
      />

      <DynamicTags
        ref={null}
        value={tagsToRemove}
        onChange={onAddTagsToRemove}
        validate={() => true}
        objectId={null}
        error={false}
        handleUpdateFieldOption={() => {}}
        fieldType={field?.fieldType}
        field={field}
        label={t('Choose Tags to Remove')}
        placeholder={t('Find Options to Remove')}
        inModal
        menuInline
        {...others}
      />
    </>
  );
};

export default ChangeTags;
