import FieldInput from 'components/Fields/FieldInput';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FIELD_TYPES } from 'utility/constants';
import { BROADCAST_FORMDATA } from './constants';
import { DateSection } from './styled';
import {
  isTodayDate,
  toBusinessTimezone,
  toLocalTimezone,
  validateDate,
} from './helpers';
import { TimezoneWarning } from 'ts-components/TimezoneWarning';

const field = {
  access: { edit: true, view: true },
  fieldType: FIELD_TYPES.DateTime.type,
};

export const BroadcastDate = ({
  date: dateProp,
  setFormData,
  isValidDate,
  isTodaySmartDate,
  setIsTodaySmartDate,
  setIsValidDate,
  businessTimezone,
  smartSendTimeSplitPercentage,
  disabled,
}) => {
  const { t } = useTranslation();

  const [date, setDate] = useState(() =>
    dateProp ? toBusinessTimezone(dateProp, businessTimezone) : null
  );
  const onChange = useCallback(
    (d) => {
      setFormData(
        BROADCAST_FORMDATA.date,
        toLocalTimezone(d, businessTimezone).toISOString()
      );
      if (smartSendTimeSplitPercentage) {
        setFormData(
          BROADCAST_FORMDATA.rescheduleAt,
          toLocalTimezone(d, businessTimezone).toISOString()
        );
        setIsTodaySmartDate(isTodayDate(d, businessTimezone));
      } else {
        setIsTodaySmartDate(false);
      }
      setIsValidDate(validateDate(d, businessTimezone));
      setDate(d);
    },
    [
      setFormData,
      businessTimezone,
      smartSendTimeSplitPercentage,
      setIsValidDate,
      setIsTodaySmartDate,
    ]
  );

  const dateError = useMemo(() => {
    if (disabled) return null;
    return isValidDate
      ? isTodaySmartDate
        ? {
            message: t(
              'Smart sending must be enabled at least one day in advance'
            ),
          }
        : null
      : { message: t('Events cannot be scheduled in the past') };
  }, [isValidDate, t, isTodaySmartDate, disabled]);

  const objectProp = useMemo(() => {
    return {
      access: { edit: !disabled, view: true },
    };
  }, [disabled]);

  return (
    <DateSection>
      <FieldInput
        field={field}
        inModal
        label={t('Scheduled Date and Time')}
        object={objectProp}
        underline={false}
        value={date}
        onChange={onChange}
        error={dateError}
        data-field-id="date"
        data-field-type={FIELD_TYPES.DateTime.type}
        isTimeSearchable
      />
      <TimezoneWarning date={date} />
    </DateSection>
  );
};
