import { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { useActivityRules } from 'ts-activities/useActivityRules';
import { useAsync } from 'react-use';
import {
  NotesRichTextEditor as OriginNotesRichTextEditor,
  useNotesRichTextEditor,
} from 'components/Inputs/NotesRichTextEditor';
import { useAttachListener } from 'hooks/useAttachListener';
import useDebounce from 'react-use/lib/useDebounce';
import { NotesLabel } from 'pages/Common/styles/Profile';

import ActivityService from 'services/ActivityService';
import { fieldTypeMapper } from 'components/Fields/FieldInput/helpers';
import FieldInput from 'components/Fields/FieldInput';
import { isDefaultUndeletableNotesField, isOwnerField } from 'checks/fields';
import { FIELD_TYPES } from 'utility/constants';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { KizenTypography } from 'app/typography';
import { gutters, breakpoints } from 'app/spacing';
import { getDataQAForInput } from './Inputs/helpers';
import { DEFAULT_DELAY } from 'utility/config';
import { BottomBarNode } from './Charts/ScheduledActivities/NotesPanel';

export const Section = styled.div`
  margin-bottom: calc(
    ${gutters.spacing(6, -3)}px
  ); // Acounting for room above text in SectionHeader
  &:first-of-type {
    margin-top: ${gutters.spacing(0, -3)}px; // Accounting for descenders
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const NotesRichTextEditor = styled(OriginNotesRichTextEditor)`
  margin-bottom: 20px;
`;

export const FieldLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); // Force equal col widths
  gap: 0px ${gutters.spacing(3)}px;
  margin-bottom: -${gutters.spacing(2)}px; // Account for bottom margin on fields. See components/Fields/FieldInput/index.js

  // half-width components need no styles,
  // full-width components need this class:
  .span2 {
    grid-column: span 2;
  }

  @media (max-width: ${breakpoints.sm}px) {
    // Make all items full-width on small screen sizes
    .span1 {
      grid-column: span 2;
    }
  }

  // Don't display unsupported fields
  > :empty {
    display: none;
  }
`;
const upToParent = (el, id) => {
  while (el && el.parentNode) {
    el = el.parentNode;
    if (el?.id === id) {
      return el;
    }
  }
  // Many DOM methods return null if they don't
  // find the element they are searching for
  // It would be OK to omit the following and just
  // return undefined
  return null;
};

export const SectionHeader = styled(KizenTypography)`
  margin-top: 5px;
  margin-bottom: ${gutters.spacing(3, -2)}px; // Descenders
`;

const dummyObject = {
  access: {
    edit: true,
  },
};

const setIntialValue = ({ fieldType }) => {
  switch (fieldType) {
    case FIELD_TYPES.Checkboxes.type:
    case FIELD_TYPES.Dropdown.type:
      return [];
    default:
      return null;
  }
};

const updateValues = ({ values, field, val }) => ({
  ...values,
  [field.id]: val,
});

const updateErrors = (errors, field) => {
  return (
    errors
      .filter(({ fieldId }) => fieldId !== field.id)
      .map(({ fieldId }) => ({ fieldId })) || []
  );
};

// Array values for the form state are stored as an array of options - rules need an array of strings.
const getValuesForRules = (values) => {
  if (!values) return {};
  return Object.entries(values ?? {}).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && typeof value[0] === 'object') {
      acc[key] = value.map((item) => item.id);
    } else if (value?.id) {
      acc[key] = [value.id];
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
};

const ActivityForm = ({
  activity,
  headerText,
  note = '',
  initValues = null,
  setDirty = () => {},
  setFields = () => {},
  emailStatus = null,
  onChangeFormValue = ({ notes, values, errorValues }) => {},
  parentErrors = [],
  selectedRelated = [],
  saveNote = () => {},
  noteSaving = false,
  withSaveButton = false,
  dataQA = 'save',
}) => {
  const { t } = useTranslation();
  const menuOpenRef = useRef(false);
  const { id: activityObjectId, visibilityRules } = activity;
  const [values, setValues] = useState(initValues);
  const [errors, setErrors] = useState([]);
  const [initFields, setInitFields] = useState([]);
  const { notes, handleNoteChange, editorRef } = useNotesRichTextEditor(note);

  useDebounce(
    () => {
      onChangeFormValue({ values, notes, errorValues: errors });
    },
    DEFAULT_DELAY,
    [values, notes, errors]
  );

  const fieldModifier = useCallback(
    function (field, selectedRelated) {
      function isObjectFieldSelected({ customObjectField }, selectedRelated) {
        return selectedRelated?.length
          ? !!selectedRelated.find(
              ({ id }) => id === customObjectField?.customObject?.id
            )
          : false;
      }

      if (field && isOwnerField(field)) {
        if (isObjectFieldSelected(field, selectedRelated)) {
          field.isRequired = !!values[field.id];
        } else {
          const initFieldObj = initFields.find(({ id }) => id === field.id);
          field.isRequired = initFieldObj.isRequired;
        }
      }

      return field;
    },
    [values, initFields]
  );

  useEffect(() => {
    if (initValues) {
      setValues(initValues);
    }
  }, [initValues]);

  useEffect(() => {
    if (parentErrors.length) {
      setErrors(parentErrors);
    }
  }, [parentErrors]);

  const scrollDropdownListener = useCallback((ev) => {
    if (menuOpenRef.current && !upToParent(ev.target, 'dropdown-menu')) {
      ev.target?.parentNode?.parentNode?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      ev.target.removeEventListener('mouseup', this);
    }
  }, []);

  // callback for select with dynamically loaded options
  const optionsReadyCallback = useCallback((controlRef) => {
    if (menuOpenRef.current) {
      controlRef.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  const listenerRef = useAttachListener(scrollDropdownListener, 'mouseup');

  const handleChangeField = useCallback(
    (val, field) => {
      setDirty(true);
      setErrors(updateErrors(errors, field));
      setValues(
        updateValues({
          values,
          field,
          val,
        })
      );
    },
    [errors, values, setDirty, setErrors]
  );

  const { value: fields } = useAsync(async () => {
    let rawFields = await ActivityService.getObjectFields({
      activityObjectId,
    });
    // faking access & and munging the fieldType for custom fields
    rawFields = rawFields.reduce((collect, f) => {
      let { fieldType } = f;

      // if custom field try and modelfield
      if (fieldType === FIELD_TYPES.ActivityCustomField.type) {
        const {
          fieldType: orgFieldType = FIELD_TYPES.Text.type,
          isDefault: orgIsDefault = false,
          name: orgName = '',
        } = f.customObjectField;
        fieldType = fieldTypeMapper({
          isDefault: orgIsDefault,
          name: orgName,
          fieldType: orgFieldType,
        });
      }
      collect = [...collect, { ...f, fieldType, ...dummyObject }];

      return collect;
    }, []);
    // initialize some values for each field
    const initialValues = rawFields.reduce(
      (acc, f) => ({
        ...acc,
        [f.id]: setIntialValue(f),
      }),
      {}
    );
    setValues(initialValues); // one for tracking
    setInitFields([...rawFields]);

    return rawFields;
  }, [activityObjectId]);

  const errorsMap = useMemo(() => {
    const errorsMap = {};
    if (fields?.length) {
      fields.forEach((field) => {
        const error = errors.find((item) => item.fieldId === field.id);
        errorsMap[field.id] = error?.message
          ? { message: error.message }
          : null;
      });
    }
    return errorsMap;
  }, [fields, errors]);

  if (Array.isArray(fields)) {
    // setFields used to be called after the `return null` conditional below (without
    // any conditional check). Moved here to be able to use the useActivityRules hook.
    setFields(fields);
  }

  const { isRuleActiveForField } = useActivityRules(
    visibilityRules,
    getValuesForRules(values, fields)
  );

  if (!fields?.length) {
    return null;
  }

  return (
    <Section ref={listenerRef}>
      <SectionHeader type="header">{headerText}</SectionHeader>
      <FieldLayout>
        {fields
          .filter((field) => {
            return !field.isHidden && isRuleActiveForField(field);
          })
          .map((field) => {
            return (
              <div key={field.id} className={`span${field.meta.cols || 1}`}>
                {isDefaultUndeletableNotesField(field) ? (
                  <NotesRichTextEditor
                    ref={editorRef}
                    className="span2"
                    label={
                      <NotesLabel className="span2">
                        {t('Notes')}
                        {field.isRequired && '*'}
                      </NotesLabel>
                    }
                    value={notes}
                    onChange={(data) => {
                      setDirty(true);
                      handleNoteChange(data);
                    }}
                    bottomBarNode={
                      withSaveButton ? (
                        <BottomBarNode
                          loading={noteSaving}
                          onClick={saveNote}
                          dataQA={dataQA}
                        />
                      ) : null
                    }
                  />
                ) : (
                  <FieldInput
                    margin
                    field={fieldModifier(field, selectedRelated)}
                    fetchUrl="field-model-custom"
                    object={{
                      ...dummyObject,
                      email_status: emailStatus,
                    }}
                    value={values[field.id]}
                    onChange={(val) => handleChangeField(val, field)}
                    isGetFullObject
                    underline={false}
                    error={errorsMap[field.id]}
                    serviceToUse={ActivityService}
                    objectId={activityObjectId}
                    required={field.isRequired}
                    isShowAsterisk={field.isRequired}
                    inModal
                    {...getDataQAForInput(
                      `activity-${field.name}`,
                      field.fieldType
                    )}
                    onMenuOpen={() => (menuOpenRef.current = true)}
                    onMenuClose={() => (menuOpenRef.current = false)}
                    optionsReadyCallback={optionsReadyCallback}
                  />
                )}
              </div>
            );
          })}
      </FieldLayout>
    </Section>
  );
};

export default ActivityForm;
