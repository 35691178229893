import React from 'react';

import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';

import IconButton from 'components/Kizen/IconButton';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import { ELEMENT_HEIGHT, ELEMENT_WIDTH } from './constants';

const ElementWrapper = styled.div`
  margin: 0;
  display: flex;
  height: ${ELEMENT_HEIGHT}px;
`;

const ElementTrash = styled.div`
  flex: 0 0 21px; // so that there is 10px to the right
  display: flex;
  align-items: center;
`;

const ElementControl = styled.div`
  min-width: ${ELEMENT_WIDTH}px;
  margin-right: 5px;
`;

const ElementTrashButton = styled(IconButton)`
  width: 15px;
  height: 15px;
`;

export const Element = ({
  isFirst,
  children,
  onDeleteElement,
  prefix = null,
  index,
}) => {
  return (
    <ElementWrapper data-qa={`element-index-${index}`} isFirst={isFirst}>
      {!isFirst && prefix ? prefix : null}
      <ElementTrash>
        <ElementTrashButton
          color={colorsButton.iconGray}
          onClick={onDeleteElement}
        >
          <IconSizing size="15px">
            <Icon icon="trash" />
          </IconSizing>
        </ElementTrashButton>
      </ElementTrash>

      <ElementControl>{children}</ElementControl>
    </ElementWrapper>
  );
};
