import { useCallback, useState, useRef } from 'react';
import InlineOwnerDropdown from 'components/Sharing/InlineOwnerDropdown';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import { EMPLOYEE_ACCESS } from 'components/AccessRequests/utils';

const OwnerDropdown = ({
  id,
  data,
  onOpen,
  onClose,
  handleChangeOwner,
  menuPlacement,
}) => {
  const selectRef = useRef();
  const [teamMember, setTeamMember] = useState({
    value: data?.owner?.id,
    label: data?.owner?.display_name,
  });

  const onChange = useCallback(
    (owner) => {
      setTeamMember(owner);
      handleChangeOwner(id, { owner: owner.value });
    },
    [handleChangeOwner, id]
  );

  const isOwner = data.employee_access === EMPLOYEE_ACCESS.OWNER;

  const [selectProps] = useSelectTypeaheadWithScroll({
    selectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
  });

  return (
    <InlineOwnerDropdown
      ref={selectRef}
      editable={isOwner}
      value={teamMember}
      onChange={onChange}
      placeholder="Choose an Owner"
      onBlur={onClose}
      onFocus={onOpen}
      {...selectProps}
      menuPlacement={menuPlacement}
    />
  );
};

export default OwnerDropdown;
