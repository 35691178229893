import {
  MouseEventHandler,
  useCallback,
  MouseEvent,
  forwardRef,
  ForwardedRef,
  useRef,
  useState,
  RefObject,
} from 'react';
import { ButtonColors } from '../Colors/definitions';
import { Spacer } from '../Spacer/Spacer';
import { ButtonText } from '../Typography/Typography';
import { getKDSClasses, getQaAttributes, merge } from '../util';
import { Icon, IconProps } from '../Icon/Icon';
import { isDebug } from '../debug';
import { ValidIcons } from '../Icon/library';
import { ColorType } from '../Colors/types';
import { pSBC } from '../Colors/convert';
import { Tooltip } from '../Tooltip/Tooltip';
import { useTooltip } from '../Tooltip/useTooltip';
import { useClickOutside } from '../hooks/useClickOutside';

type ButtonVariant = 'standard' | 'text';

export interface Action {
  label: string;
  id: string;
}

export type IconSettings = Omit<IconProps, 'icon'>;

export type HoverColor = ColorType | 'primary' | 'secondary' | 'warning';

export interface ButtonProps {
  children?: any;
  variant?: ButtonVariant;
  color?: ButtonColors;
  outline?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onActionButtonClick?: MouseEventHandler<HTMLButtonElement>;
  onSelectAction?: (payload: unknown, handleHide: () => void) => void;
  actions?: Action[] | boolean;
  rightIcon?: ValidIcons;
  leftIcon?: ValidIcons;
  disabled?: boolean;
  size?: 'xs' | 'small' | 'medium';
  rightIconSettings?: IconSettings;
  leftIconSettings?: IconSettings;
  dropdown?: (args: {
    trigger: RefObject<HTMLDivElement>;
    isShowing: boolean;
    setIsShowing: (value: boolean) => void;
    onChange: (value: string) => void;
    onHide: () => void;
    options: { value: string; label: string }[];
    menuRef: RefObject<any>;
  }) => any; // This prop is temporary while we don't have a KDS dropdown component
  allowEmptyActions?: boolean;
  hoverColor?: HoverColor;
  preserveCase?: boolean;
  maxWidth?: boolean;
  qa?: Record<string, any>;
  alwaysShowDropdown?: boolean;
  expand?: boolean;
  showTooltip?: boolean;
}

const getActionButtonClassNames = (
  color?: ButtonColors,
  variant?: ButtonVariant,
  outline?: boolean
) => {
  const baseClasses = getButtonClassNames(color, variant, outline);
  const sharedClasses = 'border-l disabled:border-border-dark';

  switch (color) {
    case 'primary':
      return merge(baseClasses, sharedClasses, 'border-button-primary-hover');
    case 'secondary':
      return merge(baseClasses, sharedClasses, 'border-button-secondary-hover');
    case 'warning':
      return merge(baseClasses, sharedClasses, 'border-button-warning-hover');
    case 'tertiary':
      return merge(baseClasses, sharedClasses, 'border-button-tertiary-hover');
  }
};

const getTextButtonIconGroupHoverClassNames = (
  color?: ButtonColors,
  hoverColor?: HoverColor
) => {
  if (hoverColor && color && ['tertiary', 'inherit'].includes(color)) {
    return 'group-hover/button:text-[var(--kizen-custom-color-hover)] group-active/button:text-[var(--kizen-custom-color-pressed)]';
  }
  switch (color) {
    case 'primary':
      return 'group-hover/button:text-button-primary-hover group-active/button:text-button-primary-pressed';
    case 'secondary':
      return 'group-hover/button:text-button-secondary-hover group-active/button:text-button-secondary-pressed';
    case 'warning':
      return 'group-hover/button:text-button-warning-hover group-active/button:text-button-warning-pressed';
    case 'tertiary':
      return 'group-hover/button:text-button-tertiary-hover group-active/button:text-button-tertiary-pressed';
  }
};

export const getButtonClassNames = (
  color?: ButtonColors,
  variant?: ButtonVariant,
  outline?: boolean,
  hoverColor?: HoverColor
) => {
  if (variant === 'text') {
    const sharedTextStyles = 'disabled:text-button-disabled-default';
    switch (color) {
      case 'primary':
        return merge(
          'text-button-primary-default hover:text-button-primary-hover active:text-button-primary-pressed',
          sharedTextStyles
        );
      case 'secondary':
        return merge(
          'text-button-secondary-default hover:text-button-secondary-hover active:text-button-secondary-pressed',
          sharedTextStyles
        );
      case 'warning':
        return merge(
          'text-button-warning-default hover:text-button-warning-hover active:text-button-warning-pressed',
          sharedTextStyles
        );
      case 'tertiary': {
        let customColorClasses =
          'hover:text-[var(--kizen-custom-color-hover)] active:text-[var(--kizen-custom-color-pressed)]';
        const defaultClasses =
          'hover:text-button-tertiary-hover active:text-button-tertiary-pressed';

        if (hoverColor === 'primary') {
          customColorClasses =
            'hover:text-button-primary-hover active:text-button-primary-pressed';
        } else if (hoverColor === 'secondary') {
          customColorClasses =
            'hover:text-button-secondary-hover active:text-button-secondary-pressed';
        } else if (hoverColor === 'warning') {
          customColorClasses =
            'hover:text-button-warning-hover active:text-button-warning-pressed';
        }

        return merge(
          'text-button-tertiary-default',
          hoverColor ? customColorClasses : defaultClasses,
          sharedTextStyles
        );
      }
      case 'white':
        return merge('text-font-white', sharedTextStyles);
      case 'inherit':
        return 'hover:text-[var(--kizen-custom-color-hover)] active:text-[var(--kizen-custom-color-pressed)]';
    }

    return '';
  }

  const sharedStandardStyles = 'disabled:bg-button-disabled-default';
  const sharedOutlineStyles =
    'disabled:border-button-disabled-default disabled:bg-button-disabled-default disabled:text-font-white hover:text-font-white disabled:hover:bg-button-disabled-default';

  switch (color) {
    case 'primary': {
      const baseStyles =
        'bg-button-primary-default hover:bg-button-primary-hover active:bg-button-primary-pressed disabled:bg-button-disabled-default';
      const outlineStyles =
        'border border-button-primary-default text-button-primary-default hover:bg-button-primary-hover active:bg-button-primary-pressed hover:border-button-primary-hover';

      return outline
        ? merge(outlineStyles, sharedOutlineStyles)
        : merge(sharedStandardStyles, baseStyles, 'text-font-white');
    }
    case 'secondary': {
      const baseStyles =
        'bg-button-secondary-default hover:bg-button-secondary-hover active:bg-button-secondary-pressed';
      const outlineStyles =
        'border border-button-secondary-default text-button-secondary-default hover:bg-button-secondary-hover active:bg-button-secondary-pressed hover:border-button-secondary-hover';

      return outline
        ? merge(outlineStyles, sharedOutlineStyles)
        : merge(sharedStandardStyles, baseStyles, 'text-font-white');
    }
    case 'warning': {
      const baseStyles =
        'bg-button-warning-default hover:bg-button-warning-hover active:bg-button-warning-pressed';
      const outlineStyles =
        'border border-button-warning-default text-button-warning-default hover:bg-button-warning-hover active:bg-button-warning-pressed hover:border-button-warning-hover';

      return outline
        ? merge(outlineStyles, sharedOutlineStyles)
        : merge(sharedStandardStyles, baseStyles, 'text-font-white');
    }
    case 'tertiary': {
      const baseStyles =
        'bg-button-tertiary-default hover:bg-button-tertiary-hover active:bg-button-tertiary-pressed';
      const outlineStyles =
        'border border-button-tertiary-default text-button-tertiary-default hover:bg-button-tertiary-hover active:bg-button-tertiary-pressed hover:border-button-tertiary-hover';

      return outline
        ? merge(outlineStyles, sharedOutlineStyles)
        : merge(sharedStandardStyles, baseStyles, 'text-font-white');
    }
  }
};

const getHasMultipleActions = (actions?: Action[] | boolean) => {
  if (typeof actions === 'boolean') {
    return actions;
  }

  if (!actions) {
    return false;
  }

  return actions.length > 1;
};

const getHasSingleAction = (actions?: Action[] | boolean) => {
  if (typeof actions === 'boolean' || !actions) {
    return false;
  }

  return actions.length === 1;
};

const getTextButtonSizeClassName = (size?: 'xs' | 'small' | 'medium') => {
  if (size === 'xs') {
    return 'h-[8px]';
  }

  if (size === 'small') {
    return 'h-[14px]';
  }

  return 'h-[36px]';
};

export const Button = forwardRef(
  (props: ButtonProps, forwardedRef: ForwardedRef<any>) => {
    const { triggerProps, targetProps, showTooltip, tooltipProps } =
      useTooltip();

    const {
      children,
      variant = 'standard',
      color = 'primary',
      outline,
      onClick,
      actions,
      rightIcon,
      leftIcon,
      disabled,
      onActionButtonClick,
      size = 'medium',
      rightIconSettings,
      leftIconSettings,
      dropdown,
      onSelectAction,
      allowEmptyActions = false,
      hoverColor,
      preserveCase = false,
      maxWidth = true,
      qa,
      alwaysShowDropdown = false,
      expand = false,
    } = props;

    const colorClassNames = getButtonClassNames(
      color,
      variant,
      outline,
      hoverColor
    );
    const hasMultipleActions = getHasMultipleActions(actions);
    const hasSingleAction = getHasSingleAction(actions);
    const hasAnyActions = hasMultipleActions || hasSingleAction;
    const dropdownTrigger = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const classNames = merge(
      colorClassNames,
      children ? 'min-w-[90px]' : '',
      hasMultipleActions ? 'rounded-r-none' : ''
    );
    const customDropdownRef = useRef(null);

    const handleDropdownClick = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onActionButtonClick?.(e);
      },
      [onActionButtonClick]
    );

    const handleButtonClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (hasAnyActions || (actions && allowEmptyActions)) {
          setIsMenuOpen(true);
          return onActionButtonClick?.(event);
        }

        return onClick?.(event);
      },
      [hasAnyActions, onClick, onActionButtonClick, actions, allowEmptyActions]
    );

    useClickOutside(
      customDropdownRef,
      () => {
        if (dropdown) {
          setTimeout(() => setIsMenuOpen(false));
        }
      },
      dropdownTrigger,
      {
        enabled: Boolean(dropdown),
      }
    );

    const { debugClassName } = isDebug();

    const mergeRef = (el: any) => {
      dropdownTrigger.current = el;
      if (typeof forwardedRef === 'function') {
        forwardedRef(el);
      } else if (forwardedRef) {
        forwardedRef.current = el;
      }
    };

    const handleHide = useCallback(() => {
      return setIsMenuOpen(false);
    }, []);

    const externalDropdown =
      hasAnyActions || (actions && allowEmptyActions)
        ? dropdown?.({
            trigger: dropdownTrigger,
            isShowing: isMenuOpen,
            setIsShowing: setIsMenuOpen,
            onChange: (payload) => onSelectAction?.(payload, handleHide),
            onHide: handleHide,
            options:
              typeof actions === 'boolean'
                ? []
                : actions?.map(({ id, label }) => {
                    return {
                      value: id,
                      label,
                    };
                  }) ?? [],
            menuRef: customDropdownRef,
          })
        : null;
    const leftIconTooltipEnabled = !children && !rightIcon;
    const rightIconTooltipEnabled = !children && !leftIcon;

    if (variant === 'standard') {
      return (
        <div
          className={merge(
            getKDSClasses('button', 'standard'),
            'inline-flex relative',
            debugClassName
          )}
          ref={mergeRef}
          {...getQaAttributes(qa)}
        >
          <button
            className={`h-[36px] inline-flex rounded-[8px] px-spacer-15 items-center ${classNames}`}
            onClick={handleButtonClick}
            disabled={disabled}
            {...triggerProps}
          >
            {/* The primary button content */}
            <div className="flex flex-col w-full">
              <div className="flex items-center w-full flex-grow">
                {/* Left icon */}
                {leftIcon ? (
                  <>
                    <Icon
                      tooltipEnabled={leftIconTooltipEnabled}
                      {...leftIconSettings}
                      icon={leftIcon}
                      className={merge(
                        'align-middle',
                        leftIconSettings?.className
                      )}
                    />
                    {children && <Spacer size={10} mode="vertical" />}
                  </>
                ) : null}

                {/* Text content */}
                {children && (
                  <span className="flex flex-grow text-center max-w-[150px]">
                    <ButtonText {...targetProps}>{children}</ButtonText>
                    {showTooltip ? (
                      <Tooltip
                        position="top"
                        text={children}
                        {...tooltipProps}
                      />
                    ) : null}
                  </span>
                )}

                {/* Right icon */}
                {rightIcon ? (
                  <>
                    {children && <Spacer size={10} mode="vertical" />}
                    <Icon
                      tooltipEnabled={rightIconTooltipEnabled}
                      {...rightIconSettings}
                      icon={rightIcon || 'form-input-dropdown-arrow'}
                      className={merge(
                        'align-middle',
                        rightIconSettings?.className
                      )}
                    />
                  </>
                ) : null}
                {hasSingleAction || (alwaysShowDropdown && !rightIcon) ? (
                  <>
                    <Spacer mode="vertical" size={8} />

                    <div>
                      <Icon icon="form-input-dropdown-arrow" />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </button>

          {/* The secondary button for opening the action menu */}
          {hasMultipleActions ? (
            <button
              className={merge(
                'h-[36px] inline-flex rounded-[8px] px-spacer-15 items-center',
                getActionButtonClassNames(color, variant, outline),
                'rounded-l-none',
                outline ? 'border-l-0' : 'border-l'
              )}
              onClick={handleDropdownClick}
              disabled={disabled}
            >
              <span>
                <Icon icon={'form-input-dropdown-arrow'} />
              </span>
            </button>
          ) : null}
          {externalDropdown}
        </div>
      );
    }

    const textButtonHeightClassName = getTextButtonSizeClassName(size);
    return (
      <div
        className={merge(
          getKDSClasses('button', 'text'),
          'inline-flex items-center max-w-full',
          !maxWidth ? 'w-fit' : '',
          expand ? 'w-full' : ''
        )}
        ref={mergeRef}
        {...getQaAttributes(qa)}
      >
        <button
          className={merge(
            'inline-flex items-center content-center m-0 p-0 relative outline-0 focus:outline-0 group/button max-w-full',
            colorClassNames,
            debugClassName,
            textButtonHeightClassName,
            preserveCase ? 'max-w-full' : '',
            !maxWidth ? 'flex flex-grow' : '',
            expand ? 'w-full' : ''
          )}
          onClick={handleButtonClick}
          disabled={disabled}
          style={
            {
              '--kizen-custom-color-hover': pSBC(0, hoverColor),
              '--kizen-custom-color-pressed': pSBC(-0.3, hoverColor),
            } as any
          }
          {...triggerProps}
        >
          {/* Left icon */}
          {leftIcon ? (
            <>
              <Icon
                tooltipEnabled={leftIconTooltipEnabled}
                {...leftIconSettings}
                icon={leftIcon}
                className={merge(
                  leftIconSettings?.className,
                  children ? 'pr-spacer-8' : ''
                )}
                __internalFontAwesomeClassName={getTextButtonIconGroupHoverClassNames(
                  color,
                  hoverColor
                )}
              />
            </>
          ) : null}

          {/* Text content */}
          <span
            className={
              !maxWidth
                ? leftIcon || rightIcon
                  ? 'max-w-[calc(100%-20px)]'
                  : 'max-w-full'
                : 'max-w-[150px]'
            }
          >
            <ButtonText preserveCase={preserveCase} {...targetProps}>
              {children}
            </ButtonText>
            {showTooltip ? (
              <Tooltip position="top" text={children} {...tooltipProps} />
            ) : null}
          </span>

          {/* Right icon */}
          {rightIcon ? (
            <>
              <Icon
                tooltipEnabled={rightIconTooltipEnabled}
                {...rightIconSettings}
                icon={rightIcon || 'form-input-dropdown-arrow'}
                className={merge(
                  rightIconSettings?.className,
                  children ? 'pl-spacer-8' : ''
                )}
                __internalFontAwesomeClassName={getTextButtonIconGroupHoverClassNames(
                  color
                )}
              />
            </>
          ) : null}
          {hasAnyActions ||
          (actions && allowEmptyActions) ||
          (alwaysShowDropdown && !rightIcon) ? (
            <>
              <Spacer mode="vertical" size={8} />

              <div>
                <Icon icon="form-input-dropdown-arrow" size="lg" />
              </div>
            </>
          ) : null}
        </button>
        {externalDropdown}
      </div>
    );
  }
);
