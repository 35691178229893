import { useTranslation } from 'react-i18next';

import { getOptionName } from 'services/helpers';
import DraggableStepCard from '../../DraggableStepCard';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  const { tagsToAdd = [], tagsToRemove = [] } = step.details;
  const tagsToAddString = tagsToAdd.map(getOptionName).join(', ');
  const tagsToRemoveString = tagsToRemove.map(getOptionName).join(', ');
  let result = tagsToAddString ? `Add Tags '${tagsToAddString}'` : '';
  result += result && tagsToRemoveString ? ', ' : '';
  result += tagsToRemoveString ? `Remove Tags '${tagsToRemoveString}'` : '';
  return result || t(`All Tags were [Deleted]`);
};

export default function ChangeTagsCard({ type, step, ...others }) {
  const { t } = useTranslation();
  return (
    <DraggableStepCard {...others}>{content({ step, t })}</DraggableStepCard>
  );
}
