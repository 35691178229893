import SelectOverlay from 'components/Inputs/SelectOverlay';
import { PILL_TYPES } from './constants';
import { PillDropdownComponent } from './components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import useField from 'hooks/useField';
import { PillDropdownControl } from './components/PillDropdownControl';
import { getCounts, getInnerValue, getIsInvalid } from './helpers';

export const PillDropdown = ({
  pillType = PILL_TYPES.multiselect.type,
  value,
  title,
  options = EMPTY_ARRAY,
  onChange,
  isReadOnly = false,
  isInvalid = false,
  pillDropdownProps = {},
  onOpen,
  onClose,
  error = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const pillRef = useRef(null);
  const [innerValue, setInnerValue] = useField(
    () => getInnerValue(value, pillType),
    [value, pillType]
  );

  const handleChange = (value) => {
    setInnerValue(value);
    onChange?.(value);
  };

  const handleHide = () => {
    onClose?.();
    setIsOpen(false);
  };

  const handleOpen = () => {
    onOpen?.(handleHide);
    setIsOpen(true);
  };

  const [counts, hasErrors] = useMemo(
    () => [getCounts(innerValue, pillType), getIsInvalid(innerValue, pillType)],
    [pillType, innerValue]
  );

  const isError = Boolean(isInvalid || hasErrors);

  useEffect(() => {
    if (isError && error) {
      pillRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }, [isError, error]);

  return (
    <>
      <PillDropdownControl
        ref={pillRef}
        onClick={handleOpen}
        title={title}
        counts={counts}
        isError={isError}
        isOpen={isOpen}
        isReadOnly={isReadOnly}
      />
      <SelectOverlay
        show={isOpen}
        onHide={handleHide}
        target={pillRef.current}
        iDoNotWantToMountTwice
      >
        <PillDropdownComponent
          pillType={pillType}
          value={innerValue}
          options={options}
          onChange={handleChange}
          onHide={handleHide}
          isReadOnly={isReadOnly}
          autoFocus
          error={error}
          {...pillDropdownProps}
        />
      </SelectOverlay>
    </>
  );
};
