import React, { useContext, useCallback } from 'react';
import { gutters } from 'app/spacing';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import ChartTypeField from '../../fields/chartType';
import TitleField from '../../fields/title';
import DatapointFrequencyField from '../../fields/datapointFrequency';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { CHART_TYPES } from 'components/Wizards/shared/components/chart/types';

const LeadSourceBreakdownReportType = ({
  defaultName = '',
  handleSetName,
  mobile = false,
  active,
}) => {
  const { state, setState, entityName } = useContext(
    WizardStateContext.Context
  );
  const { name, reportType, display, isTrendDisplay, datapointFrequency } =
    state;
  const onChangeDatapointFrequency = useCallback(
    (value) => {
      return setState('datapointFrequency', value);
    },
    [setState]
  );

  // Hide self if not lead source breakdown
  if (reportType?.value !== REPORT_TYPES.LEAD_SOURCE_BREAKDOWN) {
    return null;
  }

  return (
    <>
      <ChartTypeField
        mobile={mobile}
        top={gutters.spacing(2, 2)}
        only={[CHART_TYPES.TREND]}
      />
      {isTrendDisplay ? (
        <>
          <DatapointFrequencyField
            value={datapointFrequency}
            onChange={onChangeDatapointFrequency}
            mobile={mobile}
            active={active && isTrendDisplay}
          />
          {/* spacer for next step special case */}
          <StyledWizardField top={gutters.spacing(4)} />
        </>
      ) : null}
      {display ? (
        <TitleField
          defaultName={defaultName}
          name={name}
          onChangeName={handleSetName}
          top={gutters.spacing(isTrendDisplay ? 2 : 4)}
          entityName={entityName}
        />
      ) : null}
    </>
  );
};

export default LeadSourceBreakdownReportType;
