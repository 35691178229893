import React, { useEffect, useState, useRef } from 'react';
import * as PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import { applyDropzone, getItemDropzone, dropzoneIs } from './helpers';
import { ItemPlaceholder, ItemWrapper, HorizontalDropzone } from './Dropzone';
import { flushSync } from 'react-dom';

const DnDWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${gutters.spacing(1, -3.5)}px 0;
  width: 100%;
`;

const DragAndDropLayout = ({
  items,
  onChange,
  itemClassName,
  handleClassName,
  disabledField,
  itemElement,
  PlaceholderElement,
  cols,
  width,
  indicatorMargin,
  skipField,
  checkDisableDrag,
  maxHeight,
  draggableProps = {},
  dataQaPrefix = '',
  ...others
}) => {
  const [itemDragging, setItemDragging] = useState(null);
  const [itemDropzone, setItemDropzone] = useState(null);
  const [itemHeight, setItemHeight] = useState(0);
  const [isScrollMode, setScrollMode] = useState(false);
  const [shift, setShift] = useState(null);
  const containerRef = useRef();

  const applyItemDropzone = async () => {
    if (!itemDragging || !itemDropzone) {
      return;
    }

    const [, toItems] = applyDropzone(itemDragging, [items], itemDropzone);
    onChange(toItems, itemDragging);
  };

  const calcShift = ({ currentTarget, clientY }) => {
    const { top, bottom } = currentTarget.getBoundingClientRect();
    setShift((top + bottom) / 2 - clientY);
  };

  const nextItemsCount = (i, arr) => arr.length - i - 1;

  useEffect(() => {
    if (containerRef.current) {
      // we assume all fields are the same height
      const itemEl = containerRef.current.querySelector(`.${itemClassName}`);

      if (itemEl) {
        if (
          maxHeight &&
          containerRef.current.offsetHeight > parseInt(maxHeight, 10)
        ) {
          setScrollMode(true);
        } else {
          setScrollMode(false);
        }
        setItemHeight(itemEl.offsetHeight);
      } else {
        setItemHeight(0);
      }
    }
  }, [items.length, itemClassName, maxHeight, containerRef]);

  return (
    <DnDWrapper
      onMouseMove={(ev) => {
        if (itemDragging) {
          setItemDropzone(
            getItemDropzone(
              itemDragging,
              items,
              `.${itemClassName}`,
              skipField,
              ev,
              shift
            )
          );
        }
      }}
      ref={containerRef}
      {...others}
    >
      {items.map((item, i, arr) => (
        <React.Fragment key={item.id || i}>
          {dropzoneIs(itemDropzone, {
            id: item.id,
            position: 'before',
          }) && <HorizontalDropzone margin={indicatorMargin} />}
          <ItemWrapper
            key={item.id}
            className={itemClassName}
            cols={cols}
            width={width}
            dragging={itemDragging && itemDragging.id === item.id}
            {...(dataQaPrefix
              ? { 'data-qa': `${dataQaPrefix}-${item.id}` }
              : {})}
          >
            <PlaceholderElement />
            <Draggable
              bounds={{
                top: -(itemHeight * i),
                bottom: itemHeight * nextItemsCount(i, arr),
                right: 0,
                left: 0,
              }}
              disabled={checkDisableDrag(item) || false}
              onStart={(ev) => {
                ev.preventDefault();
                if (document.activeElement) document.activeElement.blur();
                flushSync(() => {
                  calcShift(ev);
                  setItemDragging(item);
                });
              }}
              onStop={() => {
                flushSync(() => {
                  applyItemDropzone();
                  setItemDragging(null);
                  setItemDropzone(null);
                });
              }}
              position={itemDragging ? null : { x: 0, y: 0 }}
              handle={`.${handleClassName}`}
              {...draggableProps}
            >
              {itemElement &&
                React.cloneElement(itemElement, {
                  isScrollMode,
                  element: item,
                  index: i,
                  dragging: itemDragging && itemDragging.id === item.id,
                  isDropzone: itemDropzone && itemDropzone.id === item.id,
                  handleProps: { className: handleClassName },
                })}
            </Draggable>
          </ItemWrapper>
          {dropzoneIs(itemDropzone, {
            id: item.id,
            position: 'after',
          }) && <HorizontalDropzone margin={indicatorMargin} />}
        </React.Fragment>
      ))}
    </DnDWrapper>
  );
};

DragAndDropLayout.propTypes = {
  items: PropTypes.array,
  disabledField: PropTypes.string,
  PlaceholderElement: PropTypes.element,
  cols: PropTypes.number,
  width: PropTypes.number,
  indicatorMargin: PropTypes.number,
  skipField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  checkDisableDrag: PropTypes.func,
  maxHeight: PropTypes.string,
  itemElement: PropTypes.element.isRequired,
  onChange: PropTypes.func.isRequired,
  itemClassName: PropTypes.string.isRequired,
  handleClassName: PropTypes.string.isRequired,
  draggableProps: PropTypes.object,
};

DragAndDropLayout.defaultProps = {
  items: [],
  disabledField: '',
  PlaceholderElement: ItemPlaceholder,
  cols: 1,
  width: 1,
  indicatorMargin: 0,
  skipField: '',
  checkDisableDrag: () => false,
  maxHeight: '560px',
};

export default DragAndDropLayout;
