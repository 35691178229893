import { ChangeEvent, useCallback, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useRelatedContext } from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/related-context';

import { useSelector } from 'react-redux';
import { getCustomObjectAccess } from 'store/authentication/selectors';

import FieldService from 'services/FieldService';

import { Spacer } from '@kizen/kds/Spacer';
import { Typography } from '@kizen/kds/Typography';

import { FORMAT_TYPES } from '__components/Kizen/RangeInput';
import { DraggableColumns } from './DraggableColumns';

import { getPipelineOptions } from 'pages/Common/WorkflowPipeline/helpers';

import { BuilderWrapper } from './styles';
import { Fields } from './Fields';

import { ResizableSection } from '__components/Wizards/CustomObject/steps/CustomLayout/dialogs/common';
import { SwitchNoMargin, StyledRangeInput } from '../commonStyles';
import { DraggableItemType } from '../../Builder/types';
import { isEqual } from 'lodash';
import {
  RelatedPipelineWizardMetadata,
  RelatedPipelineWizardProps,
  useRelatedPipelineWizardFieldProps,
} from './types';
import { useQuery } from 'react-query';
import { OBJECT_FIELDS } from 'queries/query-keys';
import { useCustomObjectWizard } from 'components/Wizards/CustomObject/CustomObjectWizardContext';

export const defaultRelatedPipelineMetadata = {
  relatedPipelinesIncludeAll: true,
  relatedPipelinesIncluded: null,
  relatedPipelinesBlockHeight: 370,
  relatedPipelinesRelationshipFields: {},
};

const SLIDER_PARAMS = {
  MIN: 300,
  MAX: 600,
  STEP: 1,
};

const RELATEDPIPELINES_TYPE = 'related_pipelines';

export const useIntialiseRelatedPipelineMeta = (
  metadata: RelatedPipelineWizardMetadata,
  blockType: DraggableItemType,
  formData: any
) => {
  const intialSetup = useRef(false);

  const { customObjectEntities } = useSelector(getCustomObjectAccess);

  const pipelineOptions = useMemo(() => {
    const options = getPipelineOptions(
      formData?.relatedObjects,
      customObjectEntities,
      true
    );

    return options.sort((a, b) => a.label.localeCompare(b.label));
  }, [formData, customObjectEntities]);

  const relatedpipelineMetadata: any = useMemo(() => {
    if (blockType === RELATEDPIPELINES_TYPE && !intialSetup.current) {
      intialSetup.current = true;

      // filter out deleted objects and there fields
      const pipelineOptionsIds = pipelineOptions.map(({ id }) => id);
      const relatedPipelinesIncluded = (
        metadata?.relatedPipelinesIncluded || []
      ).filter(({ id }) => pipelineOptionsIds.includes(id));

      const relatedPipelinesRelationshipFields = Object.entries(
        metadata?.relatedPipelinesRelationshipFields || {}
      )
        .filter(([key]) => pipelineOptionsIds.includes(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      const filteredMetadata = {
        ...metadata,
        relatedPipelinesIncluded,
        relatedPipelinesRelationshipFields,
      };

      return metadata &&
        Object.prototype.hasOwnProperty.call(
          metadata,
          'relatedPipelinesIncludeAll'
        )
        ? filteredMetadata
        : {
            ...filteredMetadata,
            ...defaultRelatedPipelineMetadata,
          };
    } else {
      if (blockType !== RELATEDPIPELINES_TYPE) {
        intialSetup.current = false;
      }

      return metadata ? metadata : defaultRelatedPipelineMetadata;
    }
  }, [metadata, blockType, pipelineOptions]);

  const relatedpipelineValid = useMemo(() => {
    return (
      blockType === RELATEDPIPELINES_TYPE &&
      (relatedpipelineMetadata?.relatedPipelinesIncludeAll ||
        (relatedpipelineMetadata?.relatedPipelinesIncluded &&
          relatedpipelineMetadata?.relatedPipelinesIncluded.length > 0))
    );
  }, [relatedpipelineMetadata, blockType]);

  return { relatedpipelineMetadata, pipelineOptions, relatedpipelineValid };
};

export const Objects = (props: RelatedPipelineWizardProps) => {
  const { t } = useTranslation();

  const { metadata, setMetadata, isActive, pipelineOptions = [] } = props;

  const { selectedObject, setSelectedObject } = useRelatedContext();

  const { leftItems, rightItems } = useMemo(() => {
    const rightItems = metadata?.relatedPipelinesIncluded || [];
    const rightItemValues = rightItems.map((item: any) => item.value);
    const leftItems = pipelineOptions.filter(
      ({ value }: { value: any }) => !rightItemValues.includes(value)
    );

    return { leftItems, rightItems };
  }, [pipelineOptions, metadata]);

  const handleIncludeAll = useCallback(
    (evt: ChangeEvent<HTMLInputElement>): void => {
      setMetadata({
        ...metadata,
        relatedPipelinesIncludeAll: evt.target.checked,
      });
    },
    [setMetadata, metadata]
  );

  const handleBlockHeight = useCallback(
    (height: number): void => {
      setMetadata({
        ...metadata,
        relatedPipelinesBlockHeight: height,
      });
    },
    [setMetadata, metadata]
  );

  const handleColumnsChange = useCallback(
    (columns: any[] = []): boolean => {
      if (!isEqual(metadata?.relatedPipelinesIncluded, columns)) {
        const columnIds = columns.map(({ id }) => id);
        const oldColumnIds = (metadata?.relatedPipelinesIncluded || []).map(
          ({ id }) => id
        );

        if (columnIds.length > oldColumnIds.length) {
          // add a column
          const newColumn = columns.find(
            ({ id }) => !oldColumnIds.includes(id)
          );

          setSelectedObject(newColumn);
        } else if (columnIds.length < oldColumnIds.length) {
          // remove a column
          const removedColumn = metadata?.relatedPipelinesIncluded.find(
            ({ id }) => !columnIds.includes(id)
          );
          if (removedColumn?.id === selectedObject?.id) {
            setSelectedObject(null);
          }
        }

        // filter out any relationship fields that are not in the selected columns
        const relatedPipelinesRelationshipFields = (
          Object.entries(metadata?.relatedPipelinesRelationshipFields || {}) ||
          []
        ).reduce((acc, [key, value]) => {
          if (columnIds.includes(key)) {
            acc = { ...acc, [key]: value };
          }
          return acc;
        }, {});

        setMetadata({
          ...metadata,
          relatedPipelinesIncluded: columns,
          relatedPipelinesRelationshipFields,
        });
      }

      return true;
    },
    [setMetadata, metadata, setSelectedObject, selectedObject]
  );

  return (
    <ResizableSection
      header={t('Choose Objects to Include on Block')}
      {...props}
    >
      {isActive ? (
        <div className="kds grid grid-cols-2 gap-spacer-20 text-font-primary">
          <div>
            <Typography variant="label">
              {t('Include all objects on block')}
            </Typography>
            <Spacer mode="horizontal" size={15} />
            <div>
              <SwitchNoMargin
                checked={metadata?.relatedPipelinesIncludeAll}
                onChange={handleIncludeAll}
                data-qa="related-pipelines-include-all"
              />
            </div>
          </div>
          {metadata.relatedPipelinesIncludeAll ? null : (
            <div className="col-span-2">
              <BuilderWrapper>
                <DraggableColumns
                  leftItems={leftItems}
                  rightItems={rightItems}
                  fields={[]}
                  isTemplate={false}
                  onChange={handleColumnsChange}
                />
              </BuilderWrapper>
            </div>
          )}
          <div className="col-span-2">
            <StyledRangeInput
              label={t('Block Height (About 1 Row Every 60px)')}
              value={
                metadata?.relatedPipelinesBlockHeight ||
                defaultRelatedPipelineMetadata.relatedPipelinesBlockHeight
              }
              min={SLIDER_PARAMS.MIN}
              max={SLIDER_PARAMS.MAX}
              step={SLIDER_PARAMS.STEP}
              onChange={handleBlockHeight}
              tooltipValue={
                metadata?.relatedPipelinesBlockHeight ||
                defaultRelatedPipelineMetadata.relatedPipelinesBlockHeight
              }
              onTooltipChange={handleBlockHeight}
              formatMarker={FORMAT_TYPES.PIXELS}
            />
          </div>
        </div>
      ) : null}
    </ResizableSection>
  );
};

const useRelatedPipelineWizardFields = (
  props: useRelatedPipelineWizardFieldProps
) => {
  const { enableAdditionalWizards, type, formData } = props;
  const active = enableAdditionalWizards && type === RELATEDPIPELINES_TYPE;
  const { isContact: isWizardContactContext } = useCustomObjectWizard();

  const { data: mainObjectFields = [], isLoading: loadingFields } = useQuery({
    queryKey: OBJECT_FIELDS.OBJECT(
      isWizardContactContext
        ? 'contacts'
        : {
            id: formData?.id,
            objectClass: 'custom_objects',
          }
    ),
    queryFn: async () => {
      return FieldService.getFields({
        for: isWizardContactContext
          ? 'contacts'
          : {
              ...formData,
              objectClass: 'custom_objects',
            },
        settingsRequest: true,
      });
    },
    enabled: active,
    // Disable the built-in refetching mechanism because we don't want unsaved data being errased
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return { mainObjectFields, loadingFields, active };
};

export const useRelatedPipelineWizard = (props: RelatedPipelineWizardProps) => {
  const { enableAdditionalWizards, type, formData, metadata, pipelineOptions } =
    props;

  const { mainObjectFields, loadingFields, active } =
    useRelatedPipelineWizardFields({
      enableAdditionalWizards,
      type,
      formData,
    });

  // this is a work around
  // the api was designed to be frugal with be resources as it expenseive when working with related objects, but:
  // this means if the user choses any field we need to send all field ids for all aother objects they have selected,
  // or if they want to show all objects then everything available

  const buildQueryBody = useCallback(() => {
    const relatedPipelinesRelationshipFields =
      metadata?.relatedPipelinesRelationshipFields || {};

    const selectedFieldIds = Object.values(
      metadata?.relatedPipelinesRelationshipFields || {}
    );

    let fieldIds: string[] = [];

    // if we have field id's we need to send all of them in the body
    if (selectedFieldIds.length) {
      const options =
        (metadata?.relatedPipelinesIncludeAll
          ? pipelineOptions
          : metadata?.relatedPipelinesIncluded) ||
        [].map(({ id }: { id: string }) => id);

      fieldIds = options.reduce(
        (acc: string[], { id: objectId }: { id: string }) => {
          if (
            relatedPipelinesRelationshipFields[objectId] &&
            relatedPipelinesRelationshipFields[objectId].length
          ) {
            return [
              ...acc,
              ...relatedPipelinesRelationshipFields[objectId].map(
                ({ value }: { value: string }) => value
              ),
            ];
          }

          const allOfObjectsFields = mainObjectFields
            .filter(
              ({
                isSuppressed,
                relation,
              }: {
                isSuppressed: boolean;
                relation: any;
              }) => !isSuppressed && relation?.relatedObject === objectId
            )
            .map(({ id }: { id: string }) => id);

          return [...acc, ...allOfObjectsFields];
        },
        []
      );
    }

    return {
      ...(!metadata?.relatedPipelinesIncludeAll && {
        objectIds: (metadata?.relatedPipelinesIncluded || []).map(
          ({ id }: { id: string }) => id
        ),
      }),
      ...(fieldIds?.length && { fieldIds: fieldIds }),
    };
  }, [metadata, pipelineOptions, mainObjectFields]);

  return {
    relatedPipelineChildren: [
      active ? (
        <Objects {...props} mainObjectFields={mainObjectFields} />
      ) : null,
      active && !loadingFields ? (
        <Fields {...props} mainObjectFields={mainObjectFields} />
      ) : null,
    ].filter(Boolean),
    buildQueryBody,
  };
};
