import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSizing } from '__components/Layout/PageContentWidth';
import { StyledStepHeader } from '../styles';
import { SmartConnectorContext } from '../../context';
import { StyledVariablesCard, StyledVariablesInfo } from './styles';
import { VariablesList } from './VariablesList';
import { SMART_CONNECTOR_STEPS } from '__pages/SmartConnectors/constants';

export const VariablesStep = () => {
  const { t } = useTranslation();
  const { setIsValid } = useContext(SmartConnectorContext);

  useEffect(() => {
    setIsValid(true);
  }, [setIsValid]);

  return (
    <PageSizing>
      <StyledVariablesCard data-qa={`${SMART_CONNECTOR_STEPS.variables}-step`}>
        <StyledStepHeader type="subheader">
          {t('Initialize Variables')}
        </StyledStepHeader>
        <StyledVariablesInfo>
          {t(
            'Each of the variables specified below will be created and initialized to their respective values for each single contextual row of the spreadsheet. If any variable is marked as required and the variable is initialized to a missing or null value, the entire row will fail to process and be skipped.'
          )}
        </StyledVariablesInfo>
        <VariablesList />
      </StyledVariablesCard>
    </PageSizing>
  );
};
