import React, {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import MultiColumnBuilder from 'components/MultiColumnBuilder';
import useBasicBuilder, {
  RIGHT_ID,
  LEFT_ID,
} from 'components/MultiColumnBuilder/useBasicBuilder';
import KizenTypography from 'app/kizentypo';
import { StyledDragItem } from 'components/MultiColumnBuilder/components/BaseOption/styles';
import { fontWeights } from 'app/typography';
import BaseOption from 'components/MultiColumnBuilder/components/BaseOption';
import Icon from 'components/Kizen/Icon';
import { useTranslation } from 'react-i18next';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { CONTEXTS } from 'components/Wizards/utils';
import { ColumnsHeaderPreview } from 'components/Wizards/shared/components/TableBuilder/components/ColumnsHeaderPreview';
import {
  StyledDropPlaceholder,
  StyledInputControl,
  Wrapper,
  StyledTextEllipsisWithTooltip,
  StyledBaseOption,
  LeftColumnWrapper,
} from './styles';
import {
  DEFAULD_RENDER_EMPTY,
  DEFAULT_CHECK_COLOR_EDITABLE_FN,
  DEFAULT_CHECK_FN,
} from './constants';
import EditableTextEllipsisWithTooltip from './EditableTextEllipsisWithTooltip';

const selfDisabledDropZones = [LEFT_ID];

export const DndColumns = ({
  initialLeftColumnItems,
  initialRightColumnItems,
  tableHeight,
  previewLabel,
  searchPlaceholder,
  leftHeaderText,
  rightHeaderText,
  renderRightEmpty = DEFAULD_RENDER_EMPTY,
  renderLeftEmpty = DEFAULD_RENDER_EMPTY,
  iconVisible = DEFAULT_CHECK_FN,
  iconEditable = DEFAULT_CHECK_FN,
  colorEditable = DEFAULT_CHECK_COLOR_EDITABLE_FN,
  onChange,
  onChangeCollapse,
  context,
  collapse,
  editable,
  editLabelField = 'label',
  dirtyRef,
  showCategoryOnSearch = true,
  grouped = false,
  itemsOrdering,
  headerPreviewComponent = null,
  immutable,
  fallbackToDefault = false, // fallback to default label if label is empty `defaultLabel` prop is required
  onDeletionConfirmed,
  displaySwitchItems,
  switchLabel,
  switchField,
  switchDefaultValue,
  resetLabelsOnDrop = false,
  resetLabelField = 'displayName',
  ...rest
}) => {
  const { t } = useTranslation();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  // parentIndex > 0 sets dirty true in useBasicBuilder, we prevent this if rightColumnItems are not changed
  const refRightColumns = useRef(initialRightColumnItems.length);
  const preventParentIndexDirty =
    refRightColumns.current === initialRightColumnItems.length;

  if (!preventParentIndexDirty) {
    // if theres a genuine change in rightColumnItems, reset refRightColumns
    refRightColumns.current = -1;
  }

  const { rightColumnItems, dirty, leftColumnProps, ...props } =
    useBasicBuilder({
      initialLeftColumnItems,
      initialRightColumnItems,
      editable,
      showCategoryOnSearch,
      selfDisabledDropZones,
      itemsOrdering,
      immutable,
      rightColumnItemsWithContentForceDirty: false,
      preventParentIndexDirty,
    });

  const initialized = useRef(0);

  useEffect(() => {
    if (dirtyRef && !dirtyRef.current) {
      dirtyRef.current = dirty;
    }

    if (context === CONTEXTS.fieldsLayout) {
      if (dirtyRef && !dirtyRef.current) {
        dirtyRef.current = dirty;
      }
      // !!! UNSAFE NESTED OBJECT MUTATION !!!
      // As this useEffect approach leads to all outer state changes been wiped out on each effect run
      // we unsafely mutate array's elements 'width' property here for it to be preserved.
      // In future we might need to get rid of this useEffect with such an ammount of deps.
      // It would be better to have useBasicBuilder hook dependent on initialRightColumnItems updates
      if (initialized.current < 2) {
        // Initially rightColumnItems are brouhgt here directly from useReducer that takes model from redux so it is frozen.
        // the nested field object will be unfrozen on any builder update, but we need here to unfroze all of them at first render.
        // Also as several deps change on each mount and effect runs twice we do need to skip second effect run.
        initialized.current === 0 &&
          onChange(
            rightColumnItems.map((field) => ({
              ...field,
            }))
          );
        initialized.current += 1;
      } else {
        const fieldsLookup = initialRightColumnItems.reduce(
          (collect, field) => {
            collect[field.id] = field.width;
            return collect;
          },
          {}
        );

        rightColumnItems.forEach((field) => {
          if (!Object.isFrozen(field)) {
            field.width = fieldsLookup[field.id] || field.width;
          }
        }, []);

        onChange(rightColumnItems);
      }
    } else {
      onChange(rightColumnItems);
    }
  }, [
    context,
    dirty,
    dirtyRef,
    initialRightColumnItems,
    onChange,
    rightColumnItems,
  ]);

  const additionalActions = useCallback(
    ({ swapItemColumn, index, element, childIndex, editValue }) => {
      return !element?.disabled && swapItemColumn ? (
        <div>
          <Icon
            className="DeleteAction"
            icon="delete"
            onClick={async () => {
              editValue(
                index,
                childIndex,
                editLabelField,
                element?.displayName ?? element?.label,
                true // updates disabled to shouldBeDisabledInLeftColumn
              );
              if (context === CONTEXTS.objects) {
                setDeleteConfirmation({ index, swapItemColumn, element });
              } else {
                swapItemColumn(index, RIGHT_ID);
              }
            }}
          />
        </div>
      ) : null;
    },
    [context, editLabelField]
  );

  const leftColumnOptionsWithResetedLabels = useMemo(() => {
    return leftColumnProps.options.map((option) => ({
      ...option,
      label: option?.[resetLabelField] ?? option?.label,
    }));
  }, [resetLabelField, leftColumnProps.options]);

  return (
    <Wrapper {...rest}>
      <StyledInputControl label={previewLabel}>
        {headerPreviewComponent ? (
          cloneElement(headerPreviewComponent, {
            columns: rightColumnItems,
            collapse: collapse,
            context: context,
            onChangeCollapse: onChangeCollapse,
          })
        ) : (
          <ColumnsHeaderPreview
            columns={rightColumnItems}
            collapse={collapse}
            context={context}
            onChangeCollapse={onChangeCollapse}
          />
        )}
      </StyledInputControl>
      <MultiColumnBuilder
        {...props}
        leftColumnProps={{
          ...leftColumnProps,
          isGroupedItem: grouped || context === CONTEXTS.objects,
          showCategoryOnSearch,
          options: resetLabelsOnDrop
            ? leftColumnOptionsWithResetedLabels
            : leftColumnProps.options,
        }}
        searchable={true}
        weight={fontWeights.regular}
        onChangeSearchTerm={props.setOptionsSearchTerm}
        searchPlaceholder={searchPlaceholder}
        iconVisible={iconVisible}
        iconEditable={iconEditable}
        colorEditable={colorEditable}
        leftHeaderText={leftHeaderText}
        rightHeaderText={rightHeaderText}
        renderRightEmpty={({ dropZone, id }) => {
          if (dropZone?.sectionId === id) {
            return (
              <StyledDropPlaceholder isChildDragging={false}>
                <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
                  <KizenTypography>
                    {renderRightEmpty.onDropLabel}
                  </KizenTypography>
                </StyledDragItem>
              </StyledDropPlaceholder>
            );
          }
          return (
            <StyledDropPlaceholder noHover isChildDragging={false}>
              <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
                <KizenTypography>{renderRightEmpty.noItems}</KizenTypography>
              </StyledDragItem>
            </StyledDropPlaceholder>
          );
        }}
        renderLeftEmpty={({ dropZone, id }) => {
          if (dropZone?.sectionId === id) {
            return (
              <StyledDropPlaceholder isChildDragging={false}>
                <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
                  <KizenTypography>
                    {renderLeftEmpty.onDropLabel}
                  </KizenTypography>
                </StyledDragItem>
              </StyledDropPlaceholder>
            );
          }
          return (
            <LeftColumnWrapper>
              <KizenTypography>{renderLeftEmpty.noItems}</KizenTypography>
            </LeftColumnWrapper>
          );
        }}
        renderOption={({ element, section, ...providedProps }) => {
          const displaySwitch =
            section === 'right-column' &&
            displaySwitchItems &&
            element?.id in displaySwitchItems;
          return (
            <StyledBaseOption {...element} section={section}>
              <BaseOption
                iconVisible={() => section !== LEFT_ID && iconVisible()}
                iconEditable={iconEditable}
                colorEditable={colorEditable}
                additionalActions={additionalActions}
                element={element}
                section={section}
                onBlur={(e) => {
                  fallbackToDefault &&
                    element?.label.trim() === '' &&
                    providedProps?.editValue(
                      providedProps.index,
                      providedProps?.childIndex,
                      'label',
                      element?.defaultLabel
                    );
                  rest?.onBlur?.(e);
                }}
                displaySwitch={displaySwitch}
                switchValue={element?.[switchField]}
                switchLabel={switchLabel}
                onSwitchChange={(ev) => {
                  providedProps?.editValue(
                    providedProps.index,
                    providedProps?.childIndex,
                    switchField,
                    ev.target.checked
                  );
                }}
                switchDefaultValue={switchDefaultValue}
                {...providedProps}
              >
                {({
                  element,
                  editable,
                  editValue,
                  childIndex,
                  index,
                  ...rest
                }) => {
                  return editable ? (
                    <EditableTextEllipsisWithTooltip
                      data-qa={`editable-field-${
                        element?.displayName ?? element?.entityName
                      }`}
                      sizing="dense"
                      value={element?.[editLabelField]}
                      placeholder={t('Enter Display Name')}
                      onChange={(value) => {
                        editValue(index, childIndex, editLabelField, value);
                      }}
                      {...rest}
                    />
                  ) : (
                    <StyledTextEllipsisWithTooltip>
                      {element?.label}
                    </StyledTextEllipsisWithTooltip>
                  );
                }}
              </BaseOption>
            </StyledBaseOption>
          );
        }}
      />
      {deleteConfirmation ? (
        <ConfirmDeletionModal
          show
          onHide={() => setDeleteConfirmation(null)}
          onConfirm={async () => {
            const { index, swapItemColumn, element } = deleteConfirmation;
            swapItemColumn(index, RIGHT_ID, true);
            setDeleteConfirmation(null);
            onDeletionConfirmed(element?.id);
          }}
        >
          {t(
            'Removing this object from the Related Object Fields table and saving will also remove any saved column settings for that object on the respective table.'
          )}
        </ConfirmDeletionModal>
      ) : null}
    </Wrapper>
  );
};
