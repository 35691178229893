import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';

const Wrapper = styled.div``;

export default function Section({ title, children, ...others }) {
  return (
    <Wrapper {...others}>
      {title && (
        <KizenTypography type="header" margin="lg">
          {title}
        </KizenTypography>
      )}
      {children}
    </Wrapper>
  );
}

Section.propTypes = {
  title: PropTypes.node,
};

Section.defaultProps = {
  title: null,
};
