import styled from '@emotion/styled';
import { grayScale, shadowLight } from 'app/colors';
import { borderRadii, layers, spaceBetweenGutters } from 'app/spacing';
import BaseSwitch from '__components/Kizen/Switch';
import { CONTENT_MAX_WIDTH } from '__components/Layout/PageContentWidth';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 59px;
  border-bottom: 1px solid ${grayScale.mediumLight};
  background: ${grayScale.white};
  padding: 0 15px;
  ${shadowLight};
  > div {
    flex: 1;
    max-width: ${CONTENT_MAX_WIDTH};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  z-index: ${layers.content(0)};
  > *:not(:last-child) {
    margin-right: 30px;
  }
  > :first-child {
    margin-right: 15px;
  }
`;

export const Center = styled.div`
  max-width: 400px;
  padding: 0 20px;
  z-index: ${layers.content(0)};
  > * {
    max-width: 100%;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: ${layers.content(0)};
  flex: 1;
  > button {
    margin: 0;
  }
  > *:not(:first-child) {
    margin-left: 30px;
  }
`;

function getStepWidth({ columns }: { columns: number }) {
  return spaceBetweenGutters({
    columns,
    gutter: '20px',
  });
}

export const StepSelector = styled.div`
  height: 113px;
  width: ${({ columns }) => columns * 156 + 'px'};
  background: ${grayScale.white};
  border-bottom-left-radius: ${borderRadii.standard};
  border-bottom-right-radius: ${borderRadii.standard};
  ${shadowLight};
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  > div {
    flex: 1;
    margin-top: 58px;
    padding: 20px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    > * {
      width: ${getStepWidth};
    }
  }
  // Center absolutely so toolbar content can overlap it
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const Switch = styled(BaseSwitch)`
  margin: 0;
  & label,
  p,
  div {
    margin-bottom: 0;
  }
`;
