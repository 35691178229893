import { useState } from 'react';
import ReactDraggable from 'react-draggable';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { borderRadii, layers } from 'app/spacing';
import { css } from '@emotion/core';

const Wrapper = styled.div`
  position: relative;
  ${({ dragging }) =>
    dragging &&
    css`
      z-index: ${layers.content(0, 1)};
      pointer-events: none; // TODO this makes the cursor change back to normal but allows mouseover
    `}
`;

export const Placeholder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.small};
`;

export default function Draggable({
  position,
  onStart,
  onStop,
  style,
  ...props
}) {
  const [dragging, setDragging] = useState(false);
  return (
    <Wrapper dragging={dragging} style={style}>
      <Placeholder />
      <ReactDraggable
        handle=".Handle"
        position={{ x: 0, y: 0 }}
        onStart={(ev) => {
          setDragging(true);
          if (onStart) onStart(ev);
        }}
        onStop={(ev) => {
          setDragging(false);
          if (onStop) onStop(ev);
        }}
        {...props}
      />
    </Wrapper>
  );
}
