import React from 'react';
import styled from '@emotion/styled';
import Icon from '../Kizen/Icon';
import { gutters } from '../../app/spacing';
import { buttonProps, buttonDefaultProps } from './props';
import Button from '.';

const ScaledIcon = styled(Icon)`
  margin-right: ${gutters.spacing(2)}px;
  // Extra specificity to prevent color from changing on icon hover
  && {
    color: inherit;
  }
  // This transform positions the icon to match the design perfectly

  margin-bottom: ${gutters.spacing(
    1,
    -3
  )}px; // to match the design (transform was breaking dragable showing)
  && {
    svg {
      width: 12px;
      height: auto;
    }
  }
`;

// Base Kizen Button Component with a plus icon
const AddButton = React.forwardRef(({ children, variant, ...props }, ref) => {
  return (
    <Button variant={variant} {...props} ref={ref}>
      <ScaledIcon icon="plus" />
      {children}
    </Button>
  );
});

AddButton.displayName = 'AddButton';
AddButton.propTypes = buttonProps;
AddButton.defaultProps = {
  ...buttonDefaultProps,
  variant: 'text',
};

export default AddButton;
