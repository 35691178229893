import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { cloneElement } from 'react';
import { forwardRef } from 'react';

const TR = styled.tr`
  &:hover {
    background-color: #fff !important;
  }
  & td > div {
    height: 42px;
    margin: 7.5px 0;
    border-bottom: 1px solid ${grayScale.medium};
    border-top: 1px solid ${grayScale.medium};
    display: flex;
    align-items: center;
    background-color: ${({ isHidden }) =>
      isHidden ? grayScale.light : '#fff'};
  }
  & td:first-of-type > div {
    border-left: 1px solid ${grayScale.medium};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  & td:last-of-type > div {
    border-right: 1px solid ${grayScale.medium};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    justify-content: flex-end;
  }
  &:first-of-type > td > div {
    margin-top: 0;
  }
`;

export const TablePillRow = forwardRef(({ columns, data, isHidden }, ref) => {
  return (
    <TR ref={ref} data-row-id={data?.id} isHidden={isHidden}>
      {columns.map((column) => {
        const columnCell = column.cell;
        return cloneElement(columnCell, {
          key: column.id,
          column,
          data,
        });
      })}
    </TR>
  );
});
