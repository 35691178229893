import React, { useState } from 'react';
import SubNavLabel from 'pages/Dashboard/components/SubNavLabel';
import PropTypes from 'prop-types';
import CustomOption from './CustomOption';
import CustomControl from './CustomControl';
import CustomSelectContainer from './CustomSelectContainer';
import CustomMenuList from './CustomMenuList';
import { monitoringMessageHelper } from 'sentry/helpers';

const requiredComponents = {
  Option: CustomOption,
  Control: CustomControl,
  SelectContainer: CustomSelectContainer,
  MenuList: CustomMenuList,
};

const warnForIgnoredComponents = (passedComponents) => {
  if (import.meta.env.DEV) {
    const ignoredKeys = Object.keys(requiredComponents ?? {});
    Object.keys(passedComponents ?? {}).forEach((passedKey) => {
      if (ignoredKeys.includes(passedKey)) {
        monitoringMessageHelper(
          `Component passed to LinkNavSelector "${passedKey}" is not supported and will be ignored. This message will be removed in production.`
        );
      }
    });
  }
};

// This is a special dropdown designed for use with routing/navigation. It behaves like the other dropdowns
// in the app, but instead of taking in normal options, it takes a route name and a route ID as an option,
// and is made of <a> tags, so that the user can use the secondary click to open a dropdown item
// in a new tab.

const LinkNavSelector = ({
  defaultMenuIsOpen = false,
  closeOnSelect = true,
  preventHrefDefaultBehavior = true,
  buildHref,
  components,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(defaultMenuIsOpen);
  warnForIgnoredComponents(components);

  return (
    <SubNavLabel
      {...rest}
      menuIsOpen={isOpen}
      components={{
        ...(components ?? {}),
        ...requiredComponents,
      }}
      setIsOpen={setIsOpen}
      buildHref={buildHref}
      closeOnSelect={closeOnSelect}
      preventHrefDefaultBehavior={preventHrefDefaultBehavior}
    />
  );
};

LinkNavSelector.propTypes = {
  defaultMenuIsOpen: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  preventHrefDefaultBehavior: PropTypes.bool,
  buildHref: PropTypes.func,
  components: PropTypes.object,
};

export default LinkNavSelector;
