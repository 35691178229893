import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import Button from 'components/Button';
import { TRow } from 'components/Kizen/Table';
import BigTableLayout, {
  useBigTableLayout,
} from 'components/Layout/BigTableLayout';
import PageToolbar, {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import ActivityBuilderPreview from 'components/Modals/ActivityBuilderPreview';
import TablePagination from 'components/Tables/Big/TablePagination';
import BigTable from 'components/Tables/Big';
import { getColumns } from './columns';
import { useSelector } from 'react-redux';
import { selectActivitiesIsFiltering } from 'store/activitiesPage/activities.redux';

export default function ActivityTabularPage(props) {
  const {
    activities,
    activitiesCount,
    search,
    onChangeSearch,
    sort,
    onChangeSort,
    pageNumber,
    pageSize,
    onChangePageNumber,
    onChangePageSize,
    onUpdateActivity,
    onDeleteActivity,
    onExportResponses,
    openAddModal,
    ...others
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [activityForPreview, setActivityForPreview] = useState(null);
  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout();
  const { scrolledToTable } = bigTablePageProps;

  const propsRef = useRef();
  propsRef.current = props;

  const isFiltering = useSelector(selectActivitiesIsFiltering);

  const columns = useMemo(
    () =>
      getColumns({
        onUpdateField: (activity, patch) =>
          propsRef.current.onUpdateActivity(activity, patch),
        onSelectAction: ({ value: action }, activity) => {
          const { id } = activity;
          if (action === 'delete') {
            propsRef.current.onDeleteActivity({ id });
          }
          if (action === 'preview') {
            setActivityForPreview(
              activities.find((act) => activity.id === act.id)
            );
          }
          if (action === 'edit') {
            history.push(`/activities/${activity.id}`);
          }
          if (action === 'export') {
            propsRef.current.onExportResponses(activity);
          }
          if (action === 'duplicate') {
            propsRef.current.onDuplicateActivity({ id });
          }
        },
        t,
      }),
    [activities, history, t]
  );

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => onChangeSort({ column, direction }),
      },
    }),
    [sort, onChangeSort]
  );

  return (
    <>
      <BigTableLayout
        toolbar={
          <PageToolbar>
            <PageToolbarSection>
              <PageSearchInput
                placeholder={t('Find Activities')}
                value={search}
                onChange={onChangeSearch}
                loading={isFiltering}
                isClearable
              />
            </PageToolbarSection>
            <PageToolbarTitle
              count={activitiesCount}
              single={t('Activity')}
              plural={t('Activities')}
            />
            <PageToolbarSection>
              <Button onClick={openAddModal} data-qa={'new-activity'}>
                {t('New Activity')}
              </Button>
            </PageToolbarSection>
          </PageToolbar>
        }
        pagination={
          <TablePagination
            page={pageNumber}
            perPage={pageSize}
            totalCount={activitiesCount}
            onChangePage={onChangePageNumber}
            onChangePerPage={onChangePageSize}
          />
        }
        recordTabSpacing={false}
        {...bigTablePageProps}
        {...others}
      >
        <BigTable
          scrolledToTable={scrolledToTable}
          columns={columns}
          head={<TRow head columns={columns} data={headData} />}
          staleData={isFiltering}
        >
          {!activities.length && <tr />}
          {activities.map((activity) => (
            <TRow
              key={activity.id}
              columns={columns}
              data={activity}
              data-qa-activity={activity.id}
            />
          ))}
        </BigTable>
      </BigTableLayout>
      {activityForPreview && (
        <ActivityBuilderPreview
          actionBtnColor="blue"
          activity={activityForPreview}
          onHide={() => setActivityForPreview(null)}
        />
      )}
    </>
  );
}

ActivityTabularPage.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object).isRequired,
  activitiesCount: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onUpdateActivity: PropTypes.func.isRequired,
  onDeleteActivity: PropTypes.func.isRequired,
  onExportResponses: PropTypes.func.isRequired,
  openAddModa: PropTypes.func.isRequired,
};
