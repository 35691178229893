import { CLIENTS } from 'queries/query-keys';
import { useQuery, useQueries } from 'react-query';
import CustomObjectsService from 'services/CustomObjectsService';
import FieldService from 'services/FieldService';
import TeamMemberService from 'services/TeamMemberService';
import { sortByStringField } from 'xforms/sorting';

export const KEYS = {
  GROUP: (id) => ['permissions.group', id],
  FIELDS: (id) => ['permissions.fields', id],
  CONTACT_OBJECT: CLIENTS.DEFAULT_MODEL,
  CONTACT_FIELDS: 'permissions.contact.fields',
  CONTACT_CATEGORIES: 'permissions.contact.categories',
  OBJECTS: 'permissions.objects',
  METADATA: 'permissions.metadata',
};

const getCustomObjects = async () => {
  const data = await CustomObjectsService.getObjects();

  return data.sort(sortByStringField((x) => x.objectName));
};

const getCategorizedFieldsByObject = async (id, objectType) => {
  const fields = await FieldService.getCategorizedFields({
    for: { id, objectType, objectClass: 'customObjects' },
    includeVisible: true,
    settingsRequest: true,
  });

  return fields;
};

const getContactCategories = async () => {
  return FieldService.getCategories({ for: 'contacts' });
};

const getContactFields = async () => {
  return FieldService.getFields({
    for: 'contacts',
    includeVisible: true,
    settingsRequest: true,
  });
};

export const fetchCategorizedFieldsQuery = async (
  objectId,
  objectType,
  queryClient
) => {
  return queryClient.fetchQuery(KEYS.FIELDS(objectId), () =>
    getCategorizedFieldsByObject(objectId, objectType)
  );
};

export const fetchContactFieldsQuery = (queryClient) => {
  return queryClient.fetchQuery(KEYS.CONTACT_FIELDS, getContactFields);
};

export const prefetchCategorizedFieldsQuery = async (
  objectId,
  objectType,
  queryClient
) => {
  return queryClient.prefetchQuery(KEYS.FIELDS(objectId), () =>
    getCategorizedFieldsByObject(objectId, objectType)
  );
};

export const prefetchContactFieldsQuery = (queryClient) => {
  return queryClient.prefetchQuery(KEYS.CONTACT_FIELDS, getContactFields);
};

export const prefetchContactCategoriesQuery = (queryClient) => {
  return queryClient.prefetchQuery(
    KEYS.CONTACT_CATEGORIES,
    getContactCategories
  );
};

export const useObjectsQuery = () => {
  return useQuery(KEYS.OBJECTS, getCustomObjects);
};

export const useCategorizedFieldsQuery = (
  objectId,
  objectType,
  { enabled = false } = {}
) => {
  return useQuery({
    queryKey: KEYS.FIELDS(objectId),
    queryFn: () => getCategorizedFieldsByObject(objectId, objectType),
    enabled,
  });
};

export const useContactFieldsQuery = () => {
  return useQuery({
    queryKey: KEYS.CONTACT_FIELDS,
    queryFn: getContactFields,
  });
};

export const useContactFieldsAndCategoriesQueries = (enabled = true) => {
  return useQueries([
    {
      queryKey: KEYS.CONTACT_FIELDS,
      queryFn: getContactFields,
      enabled,
    },
    {
      queryKey: KEYS.CONTACT_CATEGORIES,
      queryFn: getContactCategories,
      enabled,
    },
  ]);
};

export const usePermissionGroupQuery = (id) => {
  return useQuery({
    queryKey: KEYS.GROUP(id),
    queryFn: async () => TeamMemberService.getPermissionGroup(id),
    enabled: Boolean(id),
  });
};

export const usePermissionsMetadata = () => {
  return useQuery({
    queryKey: KEYS.METADATA,
    queryFn: TeamMemberService.getPermissionsMetadata,
  });
};
