import { getDataQAForInput } from 'components/Inputs/helpers';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CUSTOMIZE_FIELDS_STEP_KEY,
  GENERAL_STEP_KEY,
  PERMISSIONS_STEP_KEY,
} from '../constants';
import { useCustomObjectWizard } from '../CustomObjectWizardContext';
import WizardLoadingButton from './WizardLoadingButton';
import { isContact } from '../utilities';

export const RightActionButton = ({
  saving,
  activeStep,
  handleSave,
  handleDelete,
  model,
  onHide,
  isCreateMode,
  isDirty,
  isLastStep,
  routesProp,
  handleNext,
  handleGoBack,
}) => {
  const { isAddingContext } = useCustomObjectWizard();
  const { t } = useTranslation();
  const handleSaveAndClose = useCallback(async () => {
    const res = await handleSave();

    if (res) {
      await onHide();
    }
  }, [handleSave, onHide]);

  const handleHide = useCallback(() => {
    if (isDirty) {
      handleSaveAndClose();
    } else {
      onHide();
    }
  }, [onHide, handleSaveAndClose, isDirty]);

  const handleBack = useCallback(() => {
    handleGoBack();
  }, [handleGoBack]);

  const handleSaveAndContinue = useCallback(async () => {
    if (activeStep?.hideOnClick) {
      onHide();
    } else {
      if (!isDirty && model && !isLastStep(routesProp, activeStep)) {
        handleNext();
      } else {
        handleSave();
      }
    }
  }, [
    handleSave,
    isDirty,
    model,
    onHide,
    activeStep,
    handleNext,
    isLastStep,
    routesProp,
  ]);

  const buttonsDictionary = {
    delete: () => (
      <WizardLoadingButton
        color="red"
        onClick={handleDelete}
        disabled={saving}
        loading={saving}
        {...getDataQAForInput('delete')}
      >
        {t('Delete')}
      </WizardLoadingButton>
    ),
    back: () => (
      <WizardLoadingButton
        color="blue"
        onClick={handleBack}
        disabled={saving}
        loading={saving}
        {...getDataQAForInput('back')}
      >
        {t('Back')}
      </WizardLoadingButton>
    ),
    close: () => (
      <WizardLoadingButton
        onClick={handleHide}
        disabled={saving}
        loading={saving}
        color="blue"
        {...getDataQAForInput('close')}
      >
        {isDirty ? t('Save & Close') : t('Close')}
      </WizardLoadingButton>
    ),
    saveAndContinue: () => (
      <WizardLoadingButton
        onClick={handleSaveAndContinue}
        disabled={saving}
        loading={saving}
        {...getDataQAForInput('save_continue')}
      >
        {t('Save & Continue')}
      </WizardLoadingButton>
    ),
    save: () => (
      <WizardLoadingButton
        onClick={handleSave}
        disabled={saving}
        loading={saving}
        {...getDataQAForInput('save')}
      >
        {t('Save')}
      </WizardLoadingButton>
    ),
    saveAndClose: () => (
      <WizardLoadingButton
        onClick={handleSaveAndClose}
        disabled={saving}
        loading={saving}
        {...getDataQAForInput('save_close')}
      >
        {t('Save & Close')}
      </WizardLoadingButton>
    ),
    next: () => (
      <WizardLoadingButton
        onClick={isLastStep(routesProp, activeStep) ? handleHide : handleNext}
        disabled={saving}
        loading={saving}
        {...getDataQAForInput('continue')}
      >
        {activeStep?.nextButtonTitle(t)}
      </WizardLoadingButton>
    ),
  };

  return (
    <>
      {/* Left Button */}
      {!isCreateMode &&
      model?.access?.remove &&
      !isAddingContext &&
      !isContact(model)
        ? buttonsDictionary.delete()
        : null}
      {/* Right Button */}
      {[PERMISSIONS_STEP_KEY, CUSTOMIZE_FIELDS_STEP_KEY].includes(
        activeStep.key
      ) ? (
        <>
          {isAddingContext && activeStep.key !== GENERAL_STEP_KEY
            ? buttonsDictionary.back()
            : buttonsDictionary.close()}
          {isAddingContext || isCreateMode ? buttonsDictionary.next() : null}
        </>
      ) : (
        <>
          {!isCreateMode &&
          isAddingContext &&
          activeStep.key !== GENERAL_STEP_KEY
            ? buttonsDictionary.back()
            : null}
          {isAddingContext || isCreateMode ? (
            buttonsDictionary.saveAndContinue()
          ) : (
            <>
              {buttonsDictionary.save()}
              {buttonsDictionary.saveAndClose()}
            </>
          )}
        </>
      )}
    </>
  );
};
