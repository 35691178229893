import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters } from '../../../app/spacing';
import { colorsButton } from '../../../app/colors';
import KizenTypography from '../../../app/kizentypo';
import Icon from '../Icon';
import IconButton from '../IconButton';
import { useTruncationTooltip } from '../Tooltip';
import StylePassthrough from '../StylePassthrough';

export const NO_VALUE = '—';

export const getFormattedData = (column, data) => {
  if (!data) {
    return data;
  }

  const value = data[column.id];

  if (column.format) {
    return column.format(value, data, column);
  }

  return value;
};

export const TextEllipsis = styled(KizenTypography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
`;

TextEllipsis.displayName = 'TextEllipsis';

export function TextEllipsisWithTooltip({ children, ...others }) {
  const [tooltipProps, tooltip] = useTruncationTooltip();
  children = children || children === 0 ? children : NO_VALUE;
  return (
    <>
      {tooltip}
      <TextEllipsis {...tooltipProps} {...others}>
        {children}
      </TextEllipsis>
    </>
  );
}

export const SizedCell = styled(
  ({
    text,
    column,
    head,
    data,
    children,
    showNotesId,
    onClickNotes,
    onClickComplete,
    minWidth,
    flex,
    ...others
  }) => {
    const BaseCell = head ? 'th' : 'td';
    const ContentWrapper = text ? TextEllipsis : React.Fragment;
    return (
      <BaseCell {...others}>
        <div>
          <ContentWrapper>
            {children || getFormattedData(column, data)}
          </ContentWrapper>
        </div>
      </BaseCell>
    );
  }
)`
  // I realize this says "max-width" and that is intentional: table sizing is extremely hard to understand.
  ${({ width, padding = '0px' }) =>
    width &&
    // Need to use 'width' without calc function regarding
    // unexpected behaviours in Safari and some Chrome versions
    css`
      width: ${padding === '0px' ? width : `calc(${width} + ${padding})`};
      min-width: ${padding === '0px' ? width : `calc(${width} + ${padding})`};
      max-width: ${padding === '0px' ? width : `calc(${width} + ${padding})`};
    `};
  ${({ minWidth, padding = '0px' }) =>
    minWidth &&
    css`
      min-width: calc(${minWidth} + ${padding});
      max-width: calc(${minWidth} + ${padding});
    `};
  > div {
    max-width: 100%;
    ${({ padding = '0px' }) =>
      padding &&
      css`
        padding-right: ${padding};
      `}
    ${({ flex }) =>
      flex &&
      css`
        display: flex;
        align-items: center;
      `};
    ${({ flex, align }) =>
      align &&
      css`
        ${flex ? 'justify-contents' : 'text-align'}: ${align};
      `};
  }
`;

export const HeaderSizedCell = styled(
  ({ label, text, column, head, data, children, ...others }) => {
    const BaseCell = head ? 'th' : 'td';
    return (
      <BaseCell {...others}>
        <div>
          <TextEllipsis as="span" weight="bold">
            {label || column.label}
          </TextEllipsis>
        </div>
      </BaseCell>
    );
  }
)`
  // I realize this says "max-width" and that is intentional: table sizing is extremely hard to understand.
  ${({ minWidth, padding = '0px' }) =>
    minWidth &&
    css`
      min-width: calc(${minWidth} + ${padding});
      max-width: calc(${minWidth} + ${padding});
    `};
  ${({ maxWidth, padding = '0px' }) =>
    maxWidth &&
    css`
      max-width: calc(${maxWidth} + ${padding});
    `};
  ${({ width, padding = '0px' }) =>
    width &&
    css`
      width: calc(${width} + ${padding});
      min-width: calc(${width} + ${padding});
      max-width: calc(${width} + ${padding});
    `};
  > div {
    max-width: 100%;
    ${({ padding = '0px' }) =>
      padding &&
      css`
        padding-right: ${padding};
      `}
    ${({ flex }) =>
      flex &&
      css`
        display: flex;
        align-items: center;
      `};
    ${({ flex, align }) =>
      align &&
      css`
        ${flex ? 'justify-contents' : 'text-align'}: ${align};
      `};
  }
`;

const SortButton = styled(IconButton)`
  margin-left: ${gutters.spacing(2)}px;
  && svg {
    height: 12px;
    width: auto;
  }
`;

// Ensure the no-sort icon has the same width as other icons
// so that it doesn't cause jitter by changing content size in
// table when toggled.
const NoSortSizing = styled(StylePassthrough)`
  width: 10.5px;
`;

export const SortableHeadCell = ({
  column,
  label,
  numeric,
  data: { meta = {} },
  by,
  children,
  hasSorting,
  ...others
}) => {
  const sortId = by || column.id;
  const sort = meta.sort && meta.sort.column === sortId;
  const direction = meta.sort && meta.sort.direction;
  return (
    <SizedCell flex {...others}>
      <TextEllipsis as="span" weight="bold">
        {label || column.label}
      </TextEllipsis>
      {hasSorting && (
        <SortButton
          sizing="dense"
          color={sort ? colorsButton.blue : colorsButton.iconGray}
          onClick={() => {
            if (meta.onSort) {
              const nextDirection =
                !sort || direction === 'desc' ? 'asc' : 'desc';
              meta.onSort(sortId, nextDirection);
            }
          }}
        >
          {!sort && (
            <NoSortSizing>
              <Icon icon="no-sort" />
            </NoSortSizing>
          )}
          {sort && direction === 'asc' && !numeric && (
            <Icon icon="sort-alpha-asc" />
          )}
          {sort && direction === 'desc' && !numeric && (
            <Icon icon="sort-alpha-desc" />
          )}
          {sort && direction === 'asc' && numeric && (
            <Icon icon="sort-numeric-asc" />
          )}
          {sort && direction === 'desc' && numeric && (
            <Icon icon="sort-numeric-desc" />
          )}
        </SortButton>
      )}
      {children}
    </SizedCell>
  );
};

SortableHeadCell.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.any.isRequired,
    label: PropTypes.string,
  }),
  label: PropTypes.string,
  numeric: PropTypes.bool,
  data: PropTypes.object,
  by: PropTypes.string,
  hasSorting: PropTypes.bool,
};

SortableHeadCell.defaultProps = {
  label: null,
  numeric: false,
  by: null,
  data: null,
  column: null,
  hasSorting: true,
};

export const TRow = React.memo(
  React.forwardRef(
    ({ cell, head, columns, data, children, ...others }, ref) => {
      return (
        <tr ref={ref} {...others}>
          {children}
          {columns &&
            columns.map((column) => {
              const columnCell = (head ? column.headCell : column.cell) || cell;
              return React.cloneElement(columnCell, {
                key: column.id,
                head,
                column,
                data,
              });
            })}
        </tr>
      );
    }
  )
);

TRow.displayName = 'TRow';

TRow.propTypes = {
  cell: PropTypes.element,
  head: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any.isRequired })),
  data: PropTypes.object,
};

TRow.defaultProps = {
  cell: <SizedCell text />,
  head: false,
  columns: null,
  data: null,
};

export const Table = React.forwardRef(({ head, children, ...others }, ref) => {
  return (
    <table ref={ref} {...others}>
      {head && <thead>{head}</thead>}
      {children && <tbody>{children}</tbody>}
    </table>
  );
});

Table.displayName = 'Table';

Table.propTypes = {
  head: PropTypes.node,
};

Table.defaultProps = {
  head: null,
};

export const EmptyCell = ({ head, column, data, ...others }) => {
  const BaseCell = head ? 'th' : 'td';
  return <BaseCell {...others} />;
};

EmptyCell.propTypes = {
  head: PropTypes.bool,
  column: PropTypes.object,
  data: PropTypes.object,
};

EmptyCell.defaultProps = {
  head: false,
  column: undefined,
  data: undefined,
};
