import { SettingsPage, MessagesPage, RulesPage } from './pages';

const accountUrlBase = '/surveys/:id';

export default {
  settings: {
    label: (t) => t('Survey Settings'),
    component: SettingsPage,
    exact: true,
    icon: 'cogs-light',
    path: accountUrlBase,
  },
  build: {
    label: (t) => t('Build'),
    exact: true,
    path: `${accountUrlBase}/build`,
    icon: 'tools-light',
  },
  messages: {
    label: (t) => t('Messages'),
    component: MessagesPage,
    exact: true,
    icon: 'comments-alt-light',
    path: `${accountUrlBase}/messages`,
  },
  rules: {
    label: (t) => t('Advanced Rules'),
    component: RulesPage,
    exact: true,
    icon: 'code-branch-light',
    path: `${accountUrlBase}/rules`,
  },
};
