import { useParams, useHistory, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { SUBPAGES, SUBROUTES } from './routes';
import FieldService from 'services/FieldService';
import { useAsync } from 'react-use';
import Loader from 'components/Kizen/Loader';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { setRecordTitle } from 'pages/CustomObjects/RecordsPage';
import { useBreakpoint } from 'app/spacing';
import {
  SubNavigationNavBar,
  SubNavigationNavLink,
} from 'components/Navigation/SubNavigation';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

export const FilterGroupsPage = ({ title }) => {
  const { t } = useTranslation();
  const { objectId, subpage } = useParams();
  const history = useHistory();

  const clientObject = useSelector(getBusinessClientObject);

  const { value: model, loading: loadingModel } = useAsync(async () => {
    return FieldService.getModel({ id: objectId || clientObject.id });
  }, [objectId]);

  useSetTitleOnLoad(title(t), objectId ? model : null, setRecordTitle);

  useEffect(() => {
    if (!subpage || !Object.values(SUBPAGES).includes(subpage)) {
      history.replace(history.location.pathname + '/' + SUBPAGES.MINE);
    }
  }, [history, subpage]);

  const isMobile = useBreakpoint();

  return (
    <>
      <SubNavigationNavBar>
        {Object.entries(SUBROUTES).map(([key, route], index, arr) => {
          return (
            <SubNavigationNavLink
              key={key}
              isLast={index === arr.length - 1}
              route={route}
              to={route.path}
              isActive={() => subpage === route.path}
            />
          );
        })}
      </SubNavigationNavBar>
      <Loader loading={loadingModel}>
        <Switch>
          {Object.entries(SUBROUTES).map(([key, route]) => {
            const Component = route.component;
            return (
              <Route
                key={key}
                path={'*/filter-groups/' + route.path}
                exact={route.exact !== false}
              >
                <Component
                  isMobile={isMobile}
                  objectId={objectId}
                  model={model}
                />
              </Route>
            );
          })}
        </Switch>
      </Loader>
    </>
  );
};
