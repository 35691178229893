import { forwardRef } from 'react';

import DateInputInline, {
  DateInputInlineSelect,
} from 'components/Inputs/inline/DateInput';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import { withCellWrapper } from './helpers';

import {
  transformDateDownNonLegacy,
  transformDateUpNonLegacy,
} from 'components/Fields/FieldInput/helpers';

const _DateCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    return (
      <DateInputInline
        ref={ref}
        {...others}
        field={field}
        object={object}
        value={undefined} // Overriding base.props, value should be passed to DateInputInlineSelect
        onSubmit={(val) => {
          return onSubmit(transformDateUpNonLegacy(val));
        }}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
      >
        <DateInputInlineSelect
          value={transformDateDownNonLegacy(value)}
          menuLeftButton={menuLeftButton}
          menuRightButton={<ApplySelectOverlayButton />}
        />
      </DateInputInline>
    );
  }
);

export const DateCell = withCellWrapper(_DateCell);
