import { format as formatOld } from 'date-fns';

export const cleanFiles = (files) => files.filter((f) => f !== null);

const DATE_TIME_FORMAT_PARSE_STRING = 'YYYY-MM-DDTHH:mm:ss.000Z';

export const dateTimeFormatParseString = (value) =>
  value && new Date(formatOld(value, DATE_TIME_FORMAT_PARSE_STRING));

export const getDataQAForInput = (id, type) => {
  return {
    'data-field-type': type || 'text',
    'data-field-id': id,
  };
};

export const getMaskedValue = (str = '') => {
  if (typeof str === 'string') {
    const truncatedStr = str.substring(0, 12);
    return truncatedStr.replace(/[\s\S]/g, '\u25CF');
  }
  return str;
};
