import { getOptionValue, namedToOptionDeleted } from 'services/helpers';

import {
  setDelayAmount,
  checkDeleted,
  nameDescriptor,
} from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { surveyId, delayAmount } = orgDetails;
  return {
    stepGoal: {
      ...orgDetails,
      delayAmount: setDelayAmount(delayAmount),
      triggers: [
        {
          type: stepConfig.surveySubmitted.type,
          triggerSurveySubmitted: {
            surveyId: getOptionValue(surveyId),
          },
        },
      ],
    },
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { survey } = firstTrigger.triggerSurveySubmitted;
  const surveyId = namedToOptionDeleted(survey);
  const type = stepConfig.surveySubmittedGoal.type;

  checkDeleted(messageDictionary, addErrorMessage, survey, nameDescriptor);

  return { surveyId, type };
};

export const surveySubmittedGoalDTO = {
  forApi,
  forFlow,
};
