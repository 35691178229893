import styled from '@emotion/styled';
import { Toolbar, ToolbarLeft } from 'pages/Common/styles/ControlBar';

export const StyledToolbarLeft = styled(ToolbarLeft)`
  & {
    flex: 0 0 350px;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  height: 36px;
  & {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
