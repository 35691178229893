import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { paths } from 'schema';
import { curry1 } from './utils';

export const getActivity = async (
  instance: AxiosInstance,
  id: string,
  options?: AxiosRequestConfig & { skipErrorBoundary: any }
): Promise<
  AxiosResponse<
    paths['/api/activities/{id}']['get']['responses']['200']['content']['application/json']
  >
> => {
  return instance.get(`/activities/${id}`, options);
};

export class ActivitiesService {
  public getActivity;

  constructor(private instance: AxiosInstance) {
    this.getActivity = curry1(getActivity)(this.instance);
  }
}
