import AxiosService from './AxiosService';

class ImageService {
  constructor() {
    if (!ImageService.instance) {
      ImageService.instance = this;
    }
    return ImageService.instance;
  }

  async getPublicImages({ ordering = 'created_at', search, ...params }) {
    const { data } = await AxiosService.get(`/images`, {
      params: {
        ...params,
        is_public: true,
        search: search,
        ordering: ordering,
        page_size: 50,
      },
    });
    return data;
  }

  async getImageReferences({ id }) {
    const { data } = await AxiosService.get(`/images/${id}/references`);
    return data;
  }
  async delete({ id }) {
    const { data } = await AxiosService.delete(`/images/${id}`);
    return data;
  }
}

const instance = new ImageService();
Object.freeze(instance);

export default instance;
