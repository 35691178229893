import styled from '@emotion/styled';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { snakeToCamelCaseKeys } from '../../../../utility/helpers';
import useField from '../../../../hooks/useField';
import { TextEllipsisWithTooltip } from '../../../Kizen/Table';
import { breakpoints, gutters } from '../../../../app/spacing';
import { grayScale } from '../../../../app/colors';
import { KizenTypography } from '../../../../app/typography';
import { fileShape } from '../defaults';
import css from '@emotion/css';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100% - 184px); /* 164px D&D button + 20px top margin */

  margin-top: ${gutters.spacing(4)}px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-top: ${gutters.spacing(3)}px;
    max-height: calc(100% - 120px); /* 105px D&D button + 15px top margin */
  }
  ${({ isVisible }) =>
    isVisible
      ? ''
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`;

export const PreviewImageContainer = styled.div`
  max-width: 230px;
  max-height: 230px;
  min-width: 150px;
  min-height: 150px;
  aspect-ratio: 1;
  height: 100%;
  width: 100%;
  margin: ${gutters.spacing(3)}px;
  flex-shrink: 2;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin: 0 0 ${gutters.spacing(2, 4)}px;
    max-width: 120px;
    max-height: 120px;
    min-width: 80px;
    min-height: 80px;
  }
`;

const LabelsRow = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  min-width: 1px;
  margin-bottom: ${gutters.spacing(2)}px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-bottom: ${gutters.spacing(1, 4)}px;
  }
  &&:last-child {
    margin-bottom: 0;
  }
`;

const LabelsLabel = styled.div`
  flex: 0 0 80px;
  width: 100%;
`;
const LabelsChildren = styled.div`
  flex: 1;
  min-width: 1px;
  width: 100%;
`;

const Labels = ({ children, label }) => {
  return (
    <LabelsRow>
      {label ? (
        <LabelsLabel>
          <KizenTypography weight="bold">{label}</KizenTypography>
        </LabelsLabel>
      ) : null}
      <LabelsChildren>{children}</LabelsChildren>
    </LabelsRow>
  );
};

const NoImage = styled.div`
  display: flex;
  height: 100%;
  aspect-ratio: 1;
  background: ${grayScale.light};
  justify-content: center;
  align-items: center;
  > p {
    font-size: 32px;
  }
`;

const defaultPreview = {
  name: '',
  url: null,
  sizeFormatted: '',
  contentType: '',
  created: '',
};
const PreviewBlock = ({ file, isMobile = false, isVisible = true }) => {
  const { t } = useTranslation();

  const ext = file && (file?.name || '').split('.').pop();
  const created =
    (file &&
      file.created &&
      format(new Date(file.created), 'M/DD/YYYY \\a\\t h:mm A')) ||
    '';
  const [info] = useField(() => {
    return {
      ...defaultPreview,
      ...snakeToCamelCaseKeys(file),
      created,
    };
  }, [file, file.name]);

  return (
    <Wrapper isVisible={isVisible}>
      {isMobile ? null : (
        <KizenTypography wight="bold">{t('Preview/Info')}</KizenTypography>
      )}
      <PreviewImageContainer>
        <NoImage>
          {Boolean(ext) && (
            <KizenTypography type="header" weight="bold">
              {`.${ext.toUpperCase()}`}
            </KizenTypography>
          )}
        </NoImage>
      </PreviewImageContainer>
      {!isMobile || info.name ? (
        <Labels label={isMobile ? null : t('Name')}>
          <TextEllipsisWithTooltip>{info.name}</TextEllipsisWithTooltip>
        </Labels>
      ) : null}
      {isMobile ? null : (
        <>
          <Labels label={t('Size')}>
            <TextEllipsisWithTooltip>
              {info.sizeFormatted}
            </TextEllipsisWithTooltip>
          </Labels>
          <Labels label={t('Type')}>
            <TextEllipsisWithTooltip>
              {info.contentType}
            </TextEllipsisWithTooltip>
          </Labels>
        </>
      )}
      <Labels label={t('Date')}>
        <TextEllipsisWithTooltip>
          {isMobile && info.created ? info.created.split(' ')[0] : info.created}
        </TextEllipsisWithTooltip>
      </Labels>
    </Wrapper>
  );
};

PreviewBlock.propTypes = {
  file: PropTypes.objectOf(fileShape).isRequired,
  isMobile: PropTypes.bool,
};

export default PreviewBlock;
