import BasicModal from '__components/Modals/presets/BasicModal';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LayoutOption } from './types';
import DragAndDropLayout from '__components/DragAndDropLayout';
import DragItem from '__components/DragAndDropLayout/DragItem';
import { Button } from '@kizen/kds/Button';
import { Typography } from '@kizen/kds/Typography';
import IconButtonMenu from '__components/Kizen/IconButtonMenu';
import CustomObjectService from 'services/CustomObjectsService';
import { useQueryClient } from 'react-query';
import { CUSTOM_OBJECTS } from 'queries/query-keys';
import useModal from '__components/Modals/useModal';
import EditableText from '__components/Kizen/EditableText';
import { useFlashTransition } from 'hooks/useFlashState';
import Validation from '__components/Inputs/Validation';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import { monitoringExceptionHelper } from 'sentry/helpers';

type Element = LayoutOption & { id: string; active: boolean; order: number };

interface DraggableLayoutProps {
  element?: Element;
  handleDeleteLayout?: (id: string) => void;
  handleHideLayout?: (id: string) => void;
  handleShowLayout?: (id: string) => void;
  handleStartDuplicating?: (id: string) => void;
  handleChangeName?: (id: string, name: string) => void;
  bulkErrors?: any[];
  index?: number;
}

const DraggbleLayout = (props: DraggableLayoutProps) => {
  const {
    element,
    handleDeleteLayout,
    handleHideLayout,
    handleShowLayout,
    handleStartDuplicating,
    handleChangeName,
    bulkErrors,
    ...rest
  } = props;
  const { t } = useTranslation();
  const deletingElementId = useRef<string>('');
  const nameInputRef = useRef<HTMLInputElement>(null);

  const currentError = useMemo(() => {
    if (element) {
      return bulkErrors?.[element.order];
    }
  }, [bulkErrors, element]);

  const [message, showMessage, flashErrorMessage] = useFlashTransition();

  useEffect(() => {
    if (currentError?.name) {
      flashErrorMessage(currentError?.name);
    }
  }, [currentError?.name, flashErrorMessage]);

  const [deleModalProps, , deleteModal] = useModal({
    handleSubmit: () => {
      handleDeleteLayout?.(deletingElementId.current);
      deletingElementId.current = '';
    },
    handleHide: () => {
      deletingElementId.current = '';
    },
  });

  const handleSelectOption = useCallback(
    ({ value }: { value: string; label: string }) => {
      if (element?.id) {
        switch (value) {
          case 'delete': {
            deletingElementId.current = element.id;
            return deleteModal.show();
          }
          case 'hide': {
            return handleHideLayout?.(element.id);
          }
          case 'show': {
            return handleShowLayout?.(element.id);
          }
          case 'duplicate': {
            return handleStartDuplicating?.(element.id);
          }
        }
      }
    },
    [
      element,
      handleHideLayout,
      handleShowLayout,
      handleStartDuplicating,
      deleteModal,
    ]
  );

  if (!element) {
    return null;
  }

  return (
    <DragItem {...rest} isHidden={!element.active} disabled={!element.active}>
      <div className="kds flex items-center w-full">
        <Typography>
          <div ref={nameInputRef}>
            <EditableText
              value={element.label}
              placeholder={t('Enter Name')}
              onChange={(val: string) => handleChangeName?.(element.id, val)}
            />
          </div>
          <Validation
            message={message}
            showMessage={showMessage}
            target={nameInputRef.current}
          />
        </Typography>
        <div className="ml-auto">
          <IconButtonMenu
            options={[
              {
                value: 'duplicate',
                label: t('Duplicate'),
              },
              element.active
                ? {
                    value: 'hide',
                    label: t('Deactivate'),
                  }
                : {
                    value: 'show',
                    label: t('Activate'),
                  },
              {
                value: 'delete',
                label: t('Delete'),
              },
            ].filter(Boolean)}
            overlay
            className="w-auto h-0"
            onChange={handleSelectOption}
          >
            <Button
              rightIcon="action-options-horizontal"
              variant="text"
              color="tertiary"
              rightIconSettings={{
                size: 'lg',
              }}
              size="small"
            />
          </IconButtonMenu>
        </div>
      </div>
      <BasicModal
        heading={t('Please Confirm Deletion')}
        buttonText={t('Confirm Delete')}
        {...deleModalProps}
      >
        <div className="min-h-[20px] text-center">
          <Typography>
            {t('Are you sure you want to delete this record layout?')}
          </Typography>
        </div>
      </BasicModal>
    </DragItem>
  );
};

interface RecordLayoutListModalProps {
  onConfirm?: (data: any) => void;
  options: LayoutOption[];
  objectId: string;
  handleStartDuplicating?: (id: string) => void;
  handleClickAddLayout?: (fromListModal?: boolean) => void;
  onHide?: () => void;
}

export const RecordLayoutListModal = (props: RecordLayoutListModalProps) => {
  const {
    onConfirm,
    options = [],
    objectId,
    handleStartDuplicating,
    handleClickAddLayout,
    onHide,
    ...modalProps
  } = props;
  const { t } = useTranslation();
  const dragParentRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const draggableItems = useMemo(() => {
    return options.map((item, index) => ({
      id: item.value,
      order: index,
      ...item,
    }));
  }, [options]);

  const [draggableState, setDraggableState] = useState(draggableItems);
  const [isDirty, setIsDirty] = useState(false);
  const [bulkErrors, setBulkErrors] = useState<any[]>([]);
  const [unsavedChangesModalProps, , unsavedChangesModal] = useModal();

  useEffect(() => {
    setDraggableState(draggableItems);
  }, [draggableItems]);

  const handleChangeDraggableState = useCallback((state: any) => {
    setDraggableState(state);
    setIsDirty(true);
  }, []);

  const handleUpdateAfterChange = useCallback(async () => {
    try {
      setBulkErrors([]);
      await CustomObjectService.bulkUpdateLayouts(
        objectId,
        draggableState.map((item, index) => {
          return {
            id: item.id,
            name: item.label,
            order: index,
            active: item.active,
          };
        })
      );
      return true;
    } catch (ex) {
      setBulkErrors((ex as any).response?.data?.errors);
      return false;
    }
  }, [draggableState, objectId]);

  const endEditing = useCallback(() => {
    onHide?.();
    return onConfirm?.({});
  }, [onConfirm, onHide]);

  const handleConfirm = useCallback(async () => {
    if (isDirty) {
      const success = await handleUpdateAfterChange();
      if (success) {
        queryClient.refetchQueries(CUSTOM_OBJECTS.LIST_LAYOUTS(objectId));

        endEditing();
      }
    } else {
      endEditing();
    }
  }, [handleUpdateAfterChange, objectId, queryClient, isDirty, endEditing]);

  const handleHide = useCallback(() => {
    if (isDirty) {
      unsavedChangesModal.show();
    } else {
      onHide?.();
    }
  }, [unsavedChangesModal, onHide, isDirty]);

  const handleDeleteLayout = useCallback(
    async (id: string) => {
      try {
        await CustomObjectService.deleteLayout(objectId, id);
        queryClient.refetchQueries(CUSTOM_OBJECTS.LIST_LAYOUTS(objectId));
      } catch (ex) {
        monitoringExceptionHelper(ex);
      }
    },
    [objectId, queryClient]
  );

  const handleHideLayout = useCallback((id: string) => {
    setIsDirty(true);
    setDraggableState((prev) => {
      const result: typeof draggableState = structuredClone(prev);
      const index = result.findIndex((item) => item.id === id);
      if (index > -1) {
        const [removed] = result.splice(index, 1);
        result.push({
          ...removed,
          active: false,
        });
      }
      return result;
    });
  }, []);

  const visibleItems = useMemo(() => {
    return draggableState.filter((item) => item.active);
  }, [draggableState]);

  const hiddenItems = useMemo(() => {
    return draggableState.filter((item) => !item.active);
  }, [draggableState]);

  const handleShowLayout = useCallback(
    async (id: string) => {
      setIsDirty(true);
      setDraggableState((prev) => {
        const result: typeof draggableState = structuredClone(prev);
        const index = result.findIndex((item) => item.id === id);
        if (index > -1) {
          const [removed] = result.splice(index, 1);
          result.splice(visibleItems.length, 0, {
            ...removed,
            active: true,
          });
        }
        return result;
      });
    },
    [visibleItems]
  );

  const orderedItems = useMemo(() => {
    return [...visibleItems, ...hiddenItems];
  }, [visibleItems, hiddenItems]);

  const handleChangeName = useCallback((id: string, name: string) => {
    setIsDirty(true);
    setDraggableState((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            label: name,
          };
        }

        return item;
      });
    });
  }, []);

  return (
    <>
      <BasicModal
        heading={t('Manage Record Layouts')}
        defaultLeftBtnText={t('Add Layout')}
        leftBtn={<Button variant="text" leftIcon="action-add" />}
        leftBtnAlign="left"
        defaultLeftBtnHandler={() => handleClickAddLayout?.(true)}
        {...modalProps}
        onConfirm={handleConfirm}
        onHide={handleHide}
      >
        <div ref={dragParentRef} className="flex flex-col h-full">
          <DragAndDropLayout
            items={orderedItems}
            countItems={orderedItems.length}
            itemElement={
              <DraggbleLayout
                handleDeleteLayout={handleDeleteLayout}
                handleHideLayout={handleHideLayout}
                handleShowLayout={handleShowLayout}
                handleStartDuplicating={handleStartDuplicating}
                handleChangeName={handleChangeName}
                bulkErrors={bulkErrors}
              />
            }
            parentRef={dragParentRef}
            itemClassName="dndItemWrapper"
            handleClassName="itemTarget"
            checkDisableDrag={(el: Element) => !el.active}
            onChange={handleChangeDraggableState}
          />
        </div>
      </BasicModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        rightBtn={<Button color="warning">{t('Discard Changes')}</Button>}
        rightBtnClickHandler={onHide}
        defaultLeftBtnHandler={() => {
          unsavedChangesModal.hide();
          handleConfirm();
        }}
        leftBtn={<Button />}
        defaultLeftBtnText={t('Save Changes')}
        actionButton={false}
        {...unsavedChangesModalProps}
      >
        {t(
          'You have unsaved changes. Would you like to save them or discard them before continuing?'
        )}
      </ConfirmationModal>
    </>
  );
};
