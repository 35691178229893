import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as PropTypes from 'prop-types';

import { camelToSnakeCase, snakeToCamelCase } from 'services/helpers';
import EmailService from 'services/EmailService';

import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import useSearchParam, { setSearchParams } from 'hooks/useSearchParam';
import Loader from 'components/Kizen/Loader';
import CardSection from 'components/Layout/CardSection';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import EmailStats from './EmailStats';
import MessageHistoryTable from './MessageHistoryTable';
import { ClientSubscriptionTable } from './SubscriptionTable';
import { PageWrapper, WrappedSmallTableCard } from './styles';
import { useSentMessageStats } from 'queries/models/client-messages';
import { useListMessagesForContact } from 'queries/models/client-messages';
import { useUnmountQueryReset } from 'queries/helpers';
import { MESSAGE_LIBRARY } from 'queries/query-keys';
import { useCanViewSubscriptions } from 'ts-components/hooks/permissions/subscriptions';

const setOrderingSearchParams = (param, history, { column, direction }) => {
  setSearchParams(history, {
    [param]: getOrderingParam({ column, direction }),
  });
};

const emptyArray = [];

const setOpenMenuAbove = (item, index, arr) => ({
  ...item,
  meta: {
    ...item.meta,
    openMenuAbove: Math.max(10, arr.length) - index <= 3,
  },
});

const MessagesPage = ({ contact }) => {
  const { id: clientId } = useParams();
  const history = useHistory();
  // messages
  const messageOrdering =
    snakeToCamelCase(useSearchParam('sort_messages')) || '-sentAt';
  const messagesSort = useMemo(
    () => getSortValue(messageOrdering),
    [messageOrdering]
  );

  const handleSortMessages = useCallback(
    (ordering) => {
      setOrderingSearchParams('sort_messages', history, ordering);
    },
    [history]
  );

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useListMessagesForContact(clientId, camelToSnakeCase(messageOrdering));

  useUnmountQueryReset([...MESSAGE_LIBRARY.CLIENT_MESSAGE, clientId]);

  // subscriptions
  const subscriptionOrdering = useSearchParam('sort_subscription') || '';
  const subscriptionsSort = useMemo(
    () => getSortValue(subscriptionOrdering),
    [subscriptionOrdering]
  );
  const handleSortSubscriptions = useCallback(
    (ordering) =>
      setOrderingSearchParams('sort_subscription', history, ordering),
    [history]
  );

  const [{ value: subscriptions = emptyArray }, updateSubscriptions] =
    useAsyncFnKeepLast(
      async (ordering) => {
        const subData = await EmailService.getSubscriptionData({
          clientId,
          ordering: ordering || subscriptionOrdering,
        });

        return subData.map(setOpenMenuAbove);
      },
      [subscriptionOrdering, clientId]
    );

  useEffect(() => {
    updateSubscriptions();
  }, [updateSubscriptions]);

  const handleChangeSubStatus = async (val, sub) => {
    await EmailService.setSubscriptionStatus({
      clientId,
      subId: sub.id,
      status: val.value,
    });
    updateSubscriptions(subscriptionOrdering);
  };

  const { data: stats, isFetching: loading } = useSentMessageStats(clientId);

  const canViewSubscriptions = useCanViewSubscriptions({ isClient: true });

  return (
    <PageWrapper data-qa="messages">
      <Loader loading={loading}>
        <EmailStats stats={stats} />
        <CardSection as={ContentWidth} data-qa="messages-blocks">
          <WrappedSmallTableCard
            className="WrappedSmallTableCard"
            singleColumn={!canViewSubscriptions}
          >
            <MessageHistoryTable
              data={data}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              status={status}
              messagesSort={messagesSort}
              handleSortMessages={handleSortMessages}
            />
          </WrappedSmallTableCard>
          {canViewSubscriptions ? (
            <WrappedSmallTableCard className="WrappedSmallTableCard">
              <ClientSubscriptionTable
                subscriptions={subscriptions}
                subscriptionsSort={subscriptionsSort}
                handleSortSubscriptions={handleSortSubscriptions}
                emailIsSuppressionList={contact.emailIsSuppressionList}
                handleChangeSubStatus={handleChangeSubStatus}
                isClient
                entityObject={contact}
              />
            </WrappedSmallTableCard>
          ) : null}
        </CardSection>
      </Loader>
    </PageWrapper>
  );
};

MessagesPage.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default MessagesPage;
