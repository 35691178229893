import ToolbarService from 'services/ToolbarService';

const debugLog = (msg, ...args) => {
  if (import.meta.env.DEV) {
    console.groupCollapsed('[GTAG]', msg);
    console.log(...args);
    console.groupEnd();
  }
};

export const GTAG_EVENTS = {
  WYSIWYG_AI_REQUEST: 'wysiwyg_ai_request',
  PAGEVIEW: 'page_view',
  SEARCH: 'search',
  BUILDER_PREVIEW: 'builder_view',
  BUILDER_SAVE: 'builder_save',
  USER_IDENTIFIED: 'user_identified',
  DASHBOARD_UNLOADED: 'dashboard_unloaded',
  DASHBOARD_IDLE: 'dashboard_idle',
};

export const GTAG_PROPERTIES = {
  USER_ID: 'user_id',
  USER_PROPERTIES: 'user_properties',
};

export const maybeSendEvent = (ev, payload) => {
  return window?.gtag?.('event', ev, payload);
};

export const maybeSetProperty = (property, payload) => {
  return window?.gtag?.('set', property, payload);
};

export const maybeIdentifyUser = (profile, business) => {
  if (profile?.id) {
    debugLog('Identifying User', { profile, business });
    maybeSetProperty(GTAG_PROPERTIES.USER_ID, profile?.id);
    maybeSetProperty(GTAG_PROPERTIES.USER_PROPERTIES, {
      userID: profile?.id,
      businessID: business?.id,
      businessType: business?.business_type,
      trial: business?.is_trial,
    });
    maybeSendEvent(GTAG_EVENTS.USER_IDENTIFIED);
  }
};

export const maybeTrackGAPageView = (title, location, path) => {
  debugLog('Tracking page view', { title, location, path });
  return maybeSendEvent(GTAG_EVENTS.PAGEVIEW, {
    page_title: title,
    page_location: location,
    page_path: path,
  });
};

export const maybeTrackDashboardLoadedUnloaded = (loadedUnloadedData) => {
  debugLog(`Sending event ${GTAG_EVENTS.DASHBOARD_UNLOADED}`);
  return maybeSendEvent(GTAG_EVENTS.DASHBOARD_UNLOADED, loadedUnloadedData);
};

export const maybeTrackDashboardIdleState = (idleStateData) => {
  debugLog(`Sending event ${GTAG_EVENTS.DASHBOARD_IDLE}`);
  return maybeSendEvent(GTAG_EVENTS.DASHBOARD_IDLE, idleStateData);
};

export const maybeTrackSearch = (_term, location) => {
  debugLog('Tracking search', { location });
  return maybeSendEvent(GTAG_EVENTS.SEARCH, {
    location,
  });
};

export const maybeTrackBuilderSave = (location, template) => {
  debugLog('Tracking builder save', { location, template });
  return maybeSendEvent(GTAG_EVENTS.BUILDER_SAVE, {
    location,
    templateId: template?.id,
  });
};

export const maybeTrackBuilderPreview = (location, slug) => {
  debugLog('Tracking builder preview', { location, slug });
  return maybeSendEvent(GTAG_EVENTS.BUILDER_PREVIEW, {
    location,
    slug,
  });
};

export const maybeTrackAIRequest = (location, id, businessId, requestType) => {
  debugLog('Tracking AI request', { location, id, businessId, requestType });
  return maybeSendEvent(GTAG_EVENTS.WYSIWYG_AI_REQUEST, {
    location,
    id,
    businessId,
    requestType,
  });
};

export const trackKizenPageView = (path, pageloadTimestamp, pageloadId) => {
  ToolbarService.trackPageView({ path, pageloadTimestamp, pageloadId });
};
