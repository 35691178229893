import { Virtualizer, VirtualItem } from '@tanstack/react-virtual';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react';

export type PivotTableContext = {
  hoveredRowIndex: number | null;
  setHoveredRowIndex: Dispatch<SetStateAction<number | null>>;
};

export type PivotTableDraggingContext = {
  addVirtualizedRow(virtualizer: Virtualizer<HTMLDivElement, Element>): void;
  removeVirtualizedRow(virtualizer: Virtualizer<HTMLDivElement, Element>): void;
  isResizingStaticColumn: boolean;
  onColumnDragStart(
    columnIndex: number,
    clientX: number,
    virtualItem: VirtualItem
  ): void;
  onColumnDrag(
    columnIndex: number,
    diffOrNewSize: number,
    virtualIndex?: number
  ): void;
  onColumnDragEnd(columnIndex: number, size?: number): void;
};

const ctx = createContext<PivotTableContext>({
  hoveredRowIndex: null,
  setHoveredRowIndex: () => null,
});

const draggingContext = createContext<PivotTableDraggingContext>({
  isResizingStaticColumn: false,
  onColumnDragStart: () => null,
  onColumnDrag: () => null,
  onColumnDragEnd: () => null,
  addVirtualizedRow: () => {},
  removeVirtualizedRow: () => {},
});

export const PivotTableContextProvider = ({
  children,
  value,
}: PropsWithChildren<{ value: PivotTableContext }>) => {
  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export const PivotTableDraggingContextProvider = ({
  children,
  value,
}: PropsWithChildren<{ value: PivotTableDraggingContext }>) => {
  return (
    <draggingContext.Provider value={value}>
      {children}
    </draggingContext.Provider>
  );
};

export const usePivotTableContext = () => useContext(ctx);
export const usePivotTableDraggingContext = () => useContext(draggingContext);
