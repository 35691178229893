import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from 'react-number-format';
import PropTypes from 'prop-types';
import { checkAllowed } from '../../../Inputs/TextInput/NumericValidation';
import { FIELD_TYPES } from '../../../../utility/constants';
import TextInput from '..';

import {
  validateDecimalNumber,
  negativesValidateDecimalNumber,
} from '../NumericValidation/helpers';
import { withOnValueChangeDecimalNumberValidation } from '../NumericValidation';

const DecimalNumberTextInput = React.forwardRef((props, ref) => {
  const { allowNegatives = true, value, disabled, decimalOptions } = props;
  const { t } = useTranslation();

  return (
    <TextInput
      ref={ref}
      // The empty, disabled state needs to allow "—" to appear, so we disabled the NumericInput
      Input={disabled && !value ? undefined : NumberInput}
      thousandSeparator
      decimalScale={4}
      isAllowed={(inputObj) => checkAllowed(inputObj, decimalOptions, false)}
      validate={{
        ...(allowNegatives
          ? negativesValidateDecimalNumber(t)
          : validateDecimalNumber(t)),
        ...props.validate,
      }}
      {...props}
    />
  );
});

export default DecimalNumberTextInput;

DecimalNumberTextInput.displayName = 'DecimalNumberTextInput';
DecimalNumberTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  decimalOptions: PropTypes.object,
  allowNegatives: PropTypes.bool,
};
DecimalNumberTextInput.defaultProps = {
  disabled: false,
  placeholder: FIELD_TYPES.Decimal.placeholder,
  decimalOptions: null,
  allowNegatives: true,
};

export const toDecimalNumber = (str) => {
  if (!str) return null;
  const num = Number.parseFloat(str.replace(/,/g, ''));
  if (Number.isNaN(num)) return null;
  return num;
};

export const ValidatedDecimalNumberTextInput =
  withOnValueChangeDecimalNumberValidation(DecimalNumberTextInput);
