import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';
import Loader from 'components/Kizen/Loader';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MultiTrendChart from '../MultiTrend';
import EmailInteractionStatsAggregate from './components/Aggregate';
import { ChartArea, HeaderText } from './styles';

const EmailInteractionStatsChart = ({
  isMobile,
  summaryDataLoading,
  currentEmail,
  stages,
  smartSendStages,
  id,
  lineData,
  tooltipLabels,
  legendColumns,
  emailNameKey = 'name',
}) => {
  const { t } = useTranslation();

  return (
    <Loader loading={summaryDataLoading}>
      <HeaderText mobile={isMobile}>
        <TextEllipsisWithTooltip type={isMobile ? 'subheader' : 'header'}>
          {currentEmail
            ? currentEmail[emailNameKey] || emptyLongDash
            : t('All Emails In Date Range')}
        </TextEllipsisWithTooltip>
      </HeaderText>
      <EmailInteractionStatsAggregate
        stages={stages}
        smartSendStages={smartSendStages}
        tooltips={[t('Open Rate'), t('Click Rate')]}
      />
      <ChartArea>
        <MultiTrendChart
          id={id}
          data={lineData}
          yAxisTitle={t('Count')}
          tooltipLabels={tooltipLabels}
          mobile={isMobile}
          legendColumns={legendColumns}
        />
      </ChartArea>
    </Loader>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  const smartSentStagesEqual = isEqual(
    prevProps.smartSendStages,
    nextProps.smartSendStages
  );
  const isMobileEqual = prevProps.isMobile === nextProps.isMobile;
  const summaryDataLoadingEqual =
    prevProps.summaryDataLoading === nextProps.summaryDataLoading;
  const currentEmailEqual = isEqual(
    prevProps.currentEmail,
    nextProps.currentEmail
  );
  const stagesEqual = isEqual(prevProps.stages, nextProps.stages);
  const idEqual = prevProps.id === nextProps.id;
  const lineDataEqual = isEqual(prevProps.lineData, nextProps.lineData);
  const tooltipLabelsEqual = isEqual(
    prevProps.tooltipLabels,
    nextProps.tooltipLabels
  );
  const legendColumnsEqual =
    prevProps.legendColumns === nextProps.legendColumns;
  const emailNameKeyEqual = prevProps.emailNameKey === nextProps.emailNameKey;

  return (
    isMobileEqual &&
    summaryDataLoadingEqual &&
    currentEmailEqual &&
    stagesEqual &&
    idEqual &&
    lineDataEqual &&
    tooltipLabelsEqual &&
    legendColumnsEqual &&
    emailNameKeyEqual &&
    smartSentStagesEqual
  );
};

export default React.memo(EmailInteractionStatsChart, propsAreEqual);
