import { getOriginalError } from 'services/AxiosService';
import { APPLY_TEMPLATES_TYPES, TEMPLATES_TYPES } from '../constants';
import ColumnsService from 'services/ColumnsService';
import QuickFiltersService from 'services/QuickFiltersService';
import ToolbarService from 'services/ToolbarService';

export const ServiceByType = {
  [TEMPLATES_TYPES.columns]: ColumnsService,
  [TEMPLATES_TYPES.quickFilters]: QuickFiltersService,
  [TEMPLATES_TYPES.toolbars]: ToolbarService,
};

export const getApplyTemplateHandler = (templateType, applyTo) => {
  const service = ServiceByType[templateType];
  switch (applyTo) {
    case APPLY_TEMPLATES_TYPES.roles:
      return service.applyTemplateToRoles;
    case APPLY_TEMPLATES_TYPES.teamMembers:
      return service.applyTemplateToTeamMembers;
    case APPLY_TEMPLATES_TYPES.permissionGroups:
      return service.applyTemplateToPermissionGroups;
    default:
      return null;
  }
};

const getApplyToInstance = (applyTo, t) => {
  switch (applyTo) {
    case APPLY_TEMPLATES_TYPES.roles:
      return t('Role');
    case APPLY_TEMPLATES_TYPES.teamMembers:
      return t('Team Member');
    case APPLY_TEMPLATES_TYPES.permissionGroups:
      return t('Permission Group');
    default:
      return '';
  }
};

const getTemplateTypeName = (templateType, t, capitalized = false) => {
  switch (templateType) {
    case TEMPLATES_TYPES.columns:
      return capitalized ? t('Column') : t('column');
    case TEMPLATES_TYPES.quickFilters:
      return capitalized ? t('Quick Filter') : t('quick filter');
    case TEMPLATES_TYPES.toolbars:
      return capitalized ? t('Toolbar') : t('toolbar');
    default:
      return '';
  }
};

const getApplyToSummary = (templateType, data, applyTo, t) => {
  const templateTypeName = getTemplateTypeName(templateType, t);
  switch (applyTo) {
    case APPLY_TEMPLATES_TYPES.roles:
      return t(
        'All members of the “{{name}}” role will have their applied {{templateTypeName}} settings replaced with the selection below.',
        { name: data.name, templateTypeName }
      );
    case APPLY_TEMPLATES_TYPES.teamMembers:
      return t(
        '{{email}} will have their applied {{templateTypeName}} settings replaced with the selection below.',
        { email: data.email, templateTypeName }
      );
    case APPLY_TEMPLATES_TYPES.permissionGroups:
      return t(
        'All members of the “{{name}}” permission group will have their applied {{templateTypeName}} layout replaced with the selection below.',
        { name: data.name, templateTypeName }
      );
    default:
      return '';
  }
};

export const getSuccessToast = (templateType, data, applyTo, template, t) => {
  const templateTypeName = getTemplateTypeName(templateType, t);
  switch (applyTo) {
    case APPLY_TEMPLATES_TYPES.roles:
      return t(
        "'{{templateName}}' {{templateTypeName}} template has been successfully applied to the '{{name}}' role.",
        { name: data.name, templateName: template.label, templateTypeName }
      );
    case APPLY_TEMPLATES_TYPES.teamMembers:
      return t(
        "'{{templateName}}' {{templateTypeName}} template has been successfully applied",
        { templateName: template.label, templateTypeName }
      );
    case APPLY_TEMPLATES_TYPES.permissionGroups:
      return t(
        "'{{templateName}}' {{templateTypeName}} template has been successfully applied to the '{{name}}' permission group.",
        { name: data.name, templateName: template.label, templateTypeName }
      );
    default:
      return '';
  }
};

export const getApplyTemplateDictionary = (templateType, data, applyTo, t) => {
  const templateTypeName = getTemplateTypeName(templateType, t, true);
  return {
    heading: t('Apply {{templateTypeName}} Template to {{instance}}', {
      instance: getApplyToInstance(applyTo, t),
      templateTypeName,
    }),
    summary: getApplyToSummary(templateType, data, applyTo, t),
    selectLabel: t('{{templateTypeName}} Template', {
      templateTypeName,
    }),
    selectPlaceholder: t('Choose {{templateTypeName}} Template', {
      templateTypeName,
    }),
  };
};

export const decideErrorMessage = (error) => {
  const orig = getOriginalError(error);
  if (!orig) return null;
  const firstMessage = Object.keys(orig)?.[0];
  return orig?.[firstMessage] || null;
};
