import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import UUID from 'utility/UUID';
import Button from 'components/Button';
import FieldLayout from 'components/FieldLayout';
import { gutters } from 'app/spacing';
import {
  FieldActions,
  ControlledColorPicker,
  cleanOptions,
  optionsAreValid,
  validateOptions,
  OptionsLimitWarning,
} from './common';

const DEFAULT_COLOR = 'rgba(8,91,238,1)';

const Wrapper = styled.div`
  margin-bottom: ${gutters.standard};
`;

const stageOption = (t) => (patch) => {
  if (Array.isArray(patch)) {
    return patch.map(stageOption(t));
  }

  return {
    id: `tmp_${UUID.generate()}`,
    name: `${t('Option')} 1`,
    meta: { color: DEFAULT_COLOR },
    ...patch,
  };
};

export default function StatusOptions({
  updateField,
  updateValidation,
  formData,
  optionsLimit,
  importedOptions,
}) {
  const { t } = useTranslation();
  const [stagedOptions, setStagedOptions] = useState(
    formData.options.length
      ? stageOption(t)(formData.options)
      : [stageOption(t)()]
  );

  const isValid = optionsAreValid(formData.options);

  useEffect(() => {
    updateValidation(isValid);
  }, [isValid, updateValidation]);

  useEffect(() => {
    setStagedOptions((prevState) => [
      ...prevState,
      ...importedOptions.map(stageOption(() => {})),
    ]);
  }, [importedOptions]);

  useEffect(() => {
    updateField('options', cleanOptions(stagedOptions, { preserveMeta: true }));
  }, [stagedOptions, updateField]);

  const handleAddOption = () => {
    setStagedOptions([
      ...stagedOptions,
      stageOption(t)({
        newest: true,
        name: `${t('Option')} ${formData.options.length + 1}`,
      }),
    ]);
  };

  const handleChangeProp = (field, prop) => {
    delete field.newest;
    const index = stagedOptions.findIndex((f) => f.id === field.id);
    const shallowCopy = [...stagedOptions];
    shallowCopy.splice(index, 1, { ...field, ...prop });
    setStagedOptions(shallowCopy);
  };

  const handleRemoveOption = ({ id }) => {
    setStagedOptions(stagedOptions.filter((f) => f.id !== id));
  };

  const optionsLimitReached =
    !!optionsLimit && stagedOptions.length >= optionsLimit;

  return (
    <Wrapper>
      <FieldLayout
        fields={stagedOptions.map((f) => ({
          ...f,
          description: f.name,
        }))}
        Actions={FieldActions}
        onFocus={({ target }) => target.select()}
        FieldBeforeText={ControlledColorPicker}
        onDelete={handleRemoveOption}
        onChangeDescription={(name, field) => handleChangeProp(field, { name })}
        onColorChange={(field, rgba) =>
          handleChangeProp(field, { meta: { color: rgba } })
        }
        onValidate={validateOptions(stagedOptions, t)}
        onChange={(fields) => setStagedOptions(stageOption(t)(fields))}
      />
      <Button
        variant="text"
        onClick={handleAddOption}
        disabled={optionsLimitReached}
      >
        {`+ ${t('Add Option')}`}
      </Button>
      {optionsLimitReached && <OptionsLimitWarning limit={optionsLimit} />}
    </Wrapper>
  );
}

StatusOptions.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  formData: PropTypes.object,
};

StatusOptions.defaultProps = {
  formData: {},
};
