import { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useDebounce from 'react-use/lib/useDebounce';

import { useQuery } from 'react-query';
import { TEAM_MEMBERS } from 'queries/query-keys';

import {
  refreshTeamMemberSuccess,
  serviceRefreshAccessStart,
} from 'store/authentication/authenticationAction';
import TeamMemberService from 'services/TeamMemberService';
import AuthenticationService from 'services/AuthenticationService';
import Loader from 'components/Kizen/Loader';
import useSearchParam, { setSearchParams } from 'hooks/useSearchParam';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { DEFAULT_INPUT_DELAY } from 'utility/config';
import TeamTabularPage from './TabularPage';
import { invalidate } from 'queries/invalidate';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from 'store/authentication/selectors';
import { getOriginalError } from 'services/AxiosService';

const setOrderingSearchParams = (param, history, { column, direction }) => {
  setSearchParams(history, {
    [param]: getOrderingParam({ column, direction }),
  });
};

export default function TeamPage(props) {
  const dispatch = useDispatch();
  const { id: userId } = useSelector(getUserProfile);
  const currentTeamMemberId = useSelector(
    (state) => state.authentication.teamMember?.id
  );
  const history = useHistory();
  const [showToast] = useToast();
  const { t } = useTranslation();
  const search = useSearchParam('q') || '';
  const handleChangeSearch = useCallback(
    (q) => {
      setSearchParams(history, { q }, { method: 'replace' });
      setPageNumber(1);
    },
    [history]
  );

  const teamOrdering = useSearchParam('sort') || 'full_name';
  const roleOrdering = useSearchParam('sort_roles') || 'name';
  const permissionGroupOrdering =
    useSearchParam('sort_permission_groups') || 'name';

  const teamSort = useMemo(() => getSortValue(teamOrdering), [teamOrdering]);
  const roleSort = useMemo(() => getSortValue(roleOrdering), [roleOrdering]);
  const permissionGroupSort = useMemo(
    () => getSortValue(permissionGroupOrdering),
    [permissionGroupOrdering]
  );

  const handleSortTeam = useCallback(
    (ordering) => setOrderingSearchParams('sort', history, ordering),
    [history]
  );
  const handleSortRoles = useCallback(
    (ordering) => setOrderingSearchParams('sort_roles', history, ordering),
    [history]
  );
  const handleSortPermissionGroups = useCallback(
    (ordering) =>
      setOrderingSearchParams('sort_permission_groups', history, ordering),
    [history]
  );

  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [perPage, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  useDebounce(() => setDebouncedSearch(search), DEFAULT_INPUT_DELAY, [search]);

  const { data: team, isLoading: teamIsLoading } = useQuery({
    queryKey: [
      ...TEAM_MEMBERS.LIST,
      debouncedSearch,
      teamOrdering,
      perPage,
      pageNumber,
    ],
    queryFn: async () => {
      return TeamMemberService.getTeamMemberList({
        search: debouncedSearch,
        ordering: teamOrdering,
        page_size: perPage,
        page: pageNumber,
      });
    },
    keepPreviousData: true,
  });

  const { data: roles, isLoading: rolesIsLoading } = useQuery({
    queryKey: [...TEAM_MEMBERS.ROLES, roleOrdering],
    queryFn: async () => {
      const results = await TeamMemberService.getRoles({
        ordering: roleOrdering,
      });
      return { results };
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  const { data: permissionGroups, isLoading: permissionGroupsIsLoading } =
    useQuery({
      queryKey: [...TEAM_MEMBERS.PERMISSION_GROUPS, permissionGroupOrdering],
      queryFn: async () => {
        const results = await TeamMemberService.getPermissionGroups({
          ordering: permissionGroupOrdering,
          includeSummary: true,
        });
        return { results };
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    });

  const handleSelectResendVerification = async (id) => {
    await TeamMemberService.resendVerification(id);
    showToast({
      variant: toastVariant.SUCCESS,
      delay: 3000,
      message: t('The verification email has been sent successfully.'),
    });
  };
  const handleSelectResetPassword = async (email) => {
    await AuthenticationService.requestPasswordReset(email);
  };
  const handleAddTeamMember = async (member) => {
    try {
      await TeamMemberService.add(member);
    } finally {
      invalidate.TEAM_MEMBERS.LIST();
    }
  };
  const handleUpdateTeamMember = async (member, patch) => {
    let updated;
    try {
      updated = await TeamMemberService.update(member, patch);
    } catch (error) {
      const message = getOriginalError(error);
      if (message?.errors?.[0])
        showToast({
          variant: toastVariant.FAILURE,
          delay: 3000,
          message: message.errors?.[0],
        });
      throw error;
    } finally {
      if (updated?.id === currentTeamMemberId) {
        dispatch(
          refreshTeamMemberSuccess({
            teamMember: updated,
          })
        );
      }
      // In the background, refresh access any time settings change that might impact the current user's perms
      dispatch(serviceRefreshAccessStart());
      invalidate.TEAM_MEMBERS.LIST();
    }
  };
  const handleConfirmDeleteTeamMember = async (id) => {
    try {
      await TeamMemberService.delete(id);
    } finally {
      invalidate.TEAM_MEMBERS.LIST();
    }
  };
  const handleSelectDuplicateRole = async (id) => {
    try {
      await TeamMemberService.duplicateRole(id);
    } finally {
      invalidate.ROLES.ALL();
      invalidate.TEAM_MEMBERS.ROLES();
    }
  };
  const handleConfirmDeleteRole = async (id) => {
    try {
      await TeamMemberService.deleteRole(id);
    } finally {
      invalidate.ROLES.ALL();
      invalidate.TEAM_MEMBERS.LIST();
      invalidate.TEAM_MEMBERS.ROLES();
      dispatch(serviceRefreshAccessStart());
    }
  };
  const handleAddPermissionGroup = async (pg) => {
    try {
      await TeamMemberService.createPermissionGroup(pg);
    } finally {
      invalidate.TEAM_MEMBERS.PERMISSION_GROUPS();
      // invalidate.TEAM_MEMBERS.PERMISSION_GROUPS();
    }
  };
  const handleUpdatePermissionGroup = async (id, pg) => {
    try {
      await TeamMemberService.updatePermissionGroup(id, pg, {
        skipErrorBoundary: true,
      });
    } finally {
      dispatch(serviceRefreshAccessStart());
      invalidate.TEAM_MEMBERS.PERMISSION_GROUPS();
    }
  };
  const handleSelectDuplicatePermissionGroup = async (id) => {
    try {
      await TeamMemberService.duplicatePermissionGroup(id);
    } finally {
      invalidate.TEAM_MEMBERS.PERMISSION_GROUPS();
    }
  };
  const handleConfirmDeletePermissionGroup = async (id) => {
    try {
      await TeamMemberService.deletePermissionGroup(id);
    } finally {
      invalidate.TEAM_MEMBERS.LIST();
      invalidate.TEAM_MEMBERS.ROLES();
      invalidate.TEAM_MEMBERS.PERMISSION_GROUPS();
      dispatch(serviceRefreshAccessStart());
    }
  };
  const handleAddRole = async (role) => {
    try {
      await TeamMemberService.addRole(role);
    } finally {
      invalidate.ROLES.ALL();
      invalidate.TEAM_MEMBERS.ROLES();
    }
  };
  const handleUpdateRole = async (role, patch) => {
    try {
      await TeamMemberService.updateRole(role, patch);
    } finally {
      dispatch(serviceRefreshAccessStart());
      invalidate.TEAM_MEMBERS.ROLES();
      invalidate.TEAM_MEMBERS.LIST();
      invalidate.ROLES.ALL();
    }
  };

  if (teamIsLoading || rolesIsLoading || permissionGroupsIsLoading) {
    return <Loader loading />;
  }

  return (
    <TeamTabularPage
      userId={userId}
      team={team}
      roles={roles.results}
      permissionGroups={permissionGroups.results}
      search={search}
      onChangeSearch={handleChangeSearch}
      teamSort={teamSort}
      onChangeTeamSort={handleSortTeam}
      roleSort={roleSort}
      onChangeRoleSort={handleSortRoles}
      permissionGroupSort={permissionGroupSort}
      onChangePermissionGroupSort={handleSortPermissionGroups}
      onSelectResendVerification={handleSelectResendVerification}
      onSelectResetPassword={handleSelectResetPassword}
      onConfirmDeleteTeamMember={handleConfirmDeleteTeamMember}
      onSelectDuplicateRole={handleSelectDuplicateRole}
      onConfirmDeleteRole={handleConfirmDeleteRole}
      onSelectDuplicatePermissionGroup={handleSelectDuplicatePermissionGroup}
      onConfirmDeletePermissionGroup={handleConfirmDeletePermissionGroup}
      onAddTeamMember={handleAddTeamMember}
      onUpdateTeamMember={handleUpdateTeamMember}
      onAddRole={handleAddRole}
      setPerPage={setPerPage}
      perPage={perPage}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      onUpdateRole={handleUpdateRole}
      onAddPermissionGroup={handleAddPermissionGroup}
      onUpdatePermissionGroup={handleUpdatePermissionGroup}
      updatePermissionGroups={invalidate.TEAM_MEMBERS.PERMISSION_GROUPS}
      {...props}
    />
  );
}
