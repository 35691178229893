import { updateDashboardQuery } from 'components/AccessRequests/useDashboardList';
import { toastVariant, useToast } from 'components/ToastProvider';
import { DASHBOARD } from 'queries/query-keys';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { getDashboardsQueryKeyForHomepages } from '../utils';

export const useHomepageOptimisticUpdate = ({
  afterUpdate,
  refetchDashboards,
  includeSharing = true,
  debouncedTerm = '',
  onSuccess,
  onError,
}) => {
  const queryClient = useQueryClient();
  const [showToast] = useToast();
  const { t } = useTranslation();

  const dashboardsQueryKey = getDashboardsQueryKeyForHomepages({
    debouncedTerm,
    includeSharing,
  });

  const updateDashboardMutation = useMutation(
    (result) => updateDashboardQuery(result.id, result.payload),
    {
      onSuccess: (data, variables) => {
        onSuccess?.();
        afterUpdate?.(data.id, variables.payload);
        queryClient.invalidateQueries([...DASHBOARD.DASHBOARD, data.id]);
        refetchDashboards?.();
      },
      onError: (payload, data, { previousList, previousSingle }) => {
        onError?.(payload?.response?.data?.errors ?? {}, data);
        const singleDashboardQueryKey = [...DASHBOARD.DASHBOARD, data.id];

        queryClient.setQueryData(dashboardsQueryKey, previousList);
        queryClient.setQueryData(singleDashboardQueryKey, previousSingle);

        const message =
          payload?.response?.data?.[0] ?? t('Homepage could not be updated.');

        showToast({
          message,
          variant: toastVariant.FAILURE,
        });
      },
      onMutate: (vars) => {
        const { id, payload } = vars;
        const singleDashboardQueryKey = [...DASHBOARD.DASHBOARD, id];

        const previousList = queryClient.getQueryData(dashboardsQueryKey);
        const previousSingle = queryClient.getQueryData(
          singleDashboardQueryKey
        );

        const updatingPublished = 'published' in payload;

        queryClient.setQueryData(singleDashboardQueryKey, (prev) => {
          if (updatingPublished) {
            return { ...prev, published: payload.published };
          }
          return prev;
        });

        queryClient.setQueryData(dashboardsQueryKey, (prev) => {
          const result = [...prev];
          const originalIndex = result.findIndex((i) => i.id === id);
          if (updatingPublished) {
            result[originalIndex].published = payload.published;
          }

          return result;
        });

        return { previousList, previousSingle };
      },
    }
  );

  return updateDashboardMutation;
};
