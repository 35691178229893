import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';

// Consumer will likely want to choose a height, padding, and border customizations for themselves

export default styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  border: 1px solid ${grayScale.medium};
  background-color: ${grayScale.white};
  ${({ focused }) =>
    focused &&
    css`
      border-color: ${colorsButton.blue.hover};
    `}
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: ${colorsButton.blue.hover};
      }
    `}

  ${({ readOnly }) =>
    readOnly &&
    css`
      input {
        cursor: default !important;
      }

      border-color: ${grayScale.medium} !important;
    `}

  ${({ error }) =>
    error &&
    css`
      &&,
      &&:hover {
        // Ensure to trump disabled hover state
        border-color: ${colorsButton.red.hover};
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${grayScale.light};

      &:hover {
        // Explicitness here helps combat default styles from elsewhere e.g. react-select
        border-color: ${grayScale.medium};
      }
    `}
`;
