import { Redirect, useLocation } from 'react-router-dom';

// To preserve old links to /details/preview, we need to redirect to the new
// location. This component does that now that the entitlement has been removed
export const RecordDetailCompatRedirect = () => {
  const location = useLocation();

  if (location) {
    const { pathname } = location;

    const destination = pathname.replace('/preview', '');

    return <Redirect to={destination} />;
  }

  return null;
};
