import { FIELD_TYPES } from 'utility/constants';
import steps from 'pages/AutomationEngine/steps';
import {
  CONTENT_TYPE_HEADER,
  CUSTOM_CONTENT_TYPE,
  HTTP_METHODS,
} from './constants';

const isTagList = (tagList) => tagList && Array.isArray(tagList);
const tagMapLabel = (tag) => ({
  ...tag,
  name: tag.name || tag.label,
  id: tag.id || tag.value,
});

const getMessageBody = (values, property = 'messageText') => {
  // TODO Relocate to components/TextTemplater if this approach
  // becomes our standard way for handling merge fields
  // based on / copied start from: https://stackoverflow.com/a/45502134
  const unstructuredMessage = values[property] || '';
  const messageBody = [];
  const contactMergeFieldRx =
    /{{ (?:contacts?|custom_objects?|automation_history?)\.([A-z0-9\-_]+) ?}}/g;
  let searchIndex = 0;
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = contactMergeFieldRx.exec(unstructuredMessage))) {
    if (match.index > searchIndex) {
      messageBody.push({
        type: 'text',
        value: unstructuredMessage.substring(searchIndex, match.index),
      });
    }
    const [, fieldName] = match;
    if (fieldName) {
      const { slugsToIds } = values?.meta || {};
      messageBody.push({
        type: 'merge_field',
        value:
          slugsToIds?.[fieldName] && fieldName !== 'id'
            ? slugsToIds?.[fieldName]
            : fieldName,
      });
    }
    searchIndex = contactMergeFieldRx.lastIndex;
  }
  if (searchIndex < unstructuredMessage.length) {
    messageBody.push({
      type: 'text',
      value: unstructuredMessage.substring(searchIndex),
    });
  }
  return messageBody;
};

export const teamMemberOptionsListMapper = (data) =>
  data.map((tm) => ({
    label: `${tm.full_name} (${tm.email})`,
    value: tm.id,
  }));

export const createWizardState = (step) => {
  if (!step) {
    return {
      type: null,
    };
  }

  switch (step.type) {
    case steps.httpRequest.type: {
      const data =
        Object.keys(step.details).length !== 0
          ? step.details
          : step.actionHttpRequest;

      const contentTypeInHeaders =
        data.headers &&
        Object.keys(data.headers).find(
          (key) => key.toLowerCase() === CONTENT_TYPE_HEADER
        );

      const customContentType =
        contentTypeInHeaders && data.headers[contentTypeInHeaders];

      const isMethodWithContent = data.method !== HTTP_METHODS.GET;

      return {
        ...step,
        ...data,
        contentType: isMethodWithContent
          ? data.contentType ??
            (customContentType
              ? CUSTOM_CONTENT_TYPE.custom
              : CUSTOM_CONTENT_TYPE.omit)
          : null,
        contentTypeText: isMethodWithContent ? customContentType : null,
        headers: data.headers
          ? Object.keys(data.headers).reduce(
              (acc, el) =>
                isMethodWithContent && el.toLowerCase() === CONTENT_TYPE_HEADER
                  ? acc
                  : acc.concat({
                      key: el,
                      value: data.headers[el],
                      id: Math.random().toString(),
                    }),
              []
            )
          : [],
      };
    }
    default:
      return {
        type: step.type === steps.action.type ? null : step.type,
        ...step.details,
      };
  }
};

export const createPayload = (values, step) => {
  if ([steps.notifyMemberViaText.type].includes(values.type)) {
    return {
      ...step,
      type: values.type,
      details: {
        ...values,
        messageBody: getMessageBody(values),
      },
    };
  }

  if (steps.goToAutomationStep.type === values.type) {
    // In some cases label goes as react.element,
    // we can't save it to store, so simply remove label from payload
    delete values.triggerKey?.label;
    delete values.stepKey?.label;
    return {
      ...step,
      type: values.type,
      details: {
        ...values,
      },
    };
  }
  if (steps.httpRequest.type === values.type) {
    return {
      ...step,
      type: values.type,
      details: {
        method: values.method,
        url: values.url,
        body: values.body,
        data_payload: getMessageBody(values, 'body'),
        htmlBody: values?.htmlBody,
        htmlUrl: values?.htmlUrl,
        url_parts: getMessageBody(values, 'url'),
        headers: values.headers.reduce(
          (acc, el) => ({ ...acc, [el.key]: el.value }),
          values.contentType === CUSTOM_CONTENT_TYPE.custom
            ? {
                [CONTENT_TYPE_HEADER]: values.contentTypeText,
              }
            : {}
        ),
        contentType: [
          CUSTOM_CONTENT_TYPE.custom,
          CUSTOM_CONTENT_TYPE.omit,
        ].includes(values.contentType)
          ? null
          : values.contentType,
        encodeMergeFieldsInBody: values.encodeMergeFieldsInBody,
      },
    };
  }
  if (steps.changeFieldValue.type === values.type) {
    // tag coming back as label but we need name
    const { specificFieldValue } = values;
    return {
      ...step,
      type: values.type,
      details: {
        ...values,
        specificFieldValue: {
          ...specificFieldValue,
          ...(isTagList(specificFieldValue?.tagsToAdd)
            ? {
                tagsToAdd: specificFieldValue?.tagsToAdd.map(tagMapLabel),
              }
            : null),
          ...(isTagList(specificFieldValue?.tagsToRemove)
            ? {
                tagsToRemove: specificFieldValue?.tagsToRemove.map(tagMapLabel),
              }
            : null),
        },
      },
    };
  }
  return {
    ...step,
    type: values.type,
    details: { ...values },
  };
};

const cleanCraftJsContent = (craftJsContent) => {
  const { kzn_filters, ...rest } = craftJsContent ?? {};
  return rest;
};

export const cleanEmailData = (email) => {
  return {
    ...email,
    ...{
      ...(email?.craftJsContent && {
        craftJsContent: cleanCraftJsContent(email.craftJsContent),
      }),
    },
  };
};

export const ACTIONS_ON_FAILURE = {
  NOTIFY_CONTINUE: 'notify_continue',
  NOTIFY_PAUSE: 'notify_pause',
  SILENT_CONTINUE: 'silent_continue',
};

export const getActionOnFailureOptions = (t) => [
  {
    label: t('Continue and Notify on Failure'),
    value: ACTIONS_ON_FAILURE.NOTIFY_CONTINUE,
  },
  {
    label: t('Pause and Notify on Failure'),
    value: ACTIONS_ON_FAILURE.NOTIFY_PAUSE,
  },
  {
    label: t('Continue on Failure and Do Not Notify'),
    value: ACTIONS_ON_FAILURE.SILENT_CONTINUE,
  },
];

export const getDefaultActionOnFailure = (stepType) => {
  if (
    [
      steps.httpRequest.type,
      steps.createRelatedEntity.type,
      steps.assignTeamMember.type,
      steps.mathOperator.type,
      steps.scheduleActivity.type,
    ].includes(stepType)
  ) {
    return ACTIONS_ON_FAILURE.NOTIFY_PAUSE;
  }

  return ACTIONS_ON_FAILURE.NOTIFY_CONTINUE;
};

export const getActionOnFailureNote = (stepType, t) => {
  switch (stepType) {
    case steps.modifyAutomation.type:
    case steps.modifyRelatedEntitiesAutomation.type:
    case steps.changeTags.type:
    case steps.modifyRelatedEntities.type:
      return t(
        'This step has multiple components, so it will only fail if ALL components fail.'
      );
    case steps.httpRequest.type:
      return t(
        'Anything other than 200- and 300-class responses will be considered an error.'
      );
    case steps.sendEmail.type:
      return t(
        'Emails will be marked as successful if there is an existing email that is not on the suppression list.'
      );
    case steps.createRelatedEntity.type:
      return t(
        'This will be marked as a failure if either the creation or automation execution fails, but will ignore field update failures.'
      );
    case steps.sendRelatedContactEmail.type:
      return t(
        'Emails will be marked as successful if there is at least one existing email that is not on the suppression list.'
      );
    default:
      return null;
  }
};

// check for checkboxes based on orgFieldType
export const isOrgCheckboxesType = (f) =>
  f?.orgFieldType === FIELD_TYPES.Checkboxes.type;
