import { LibraryMessageInput } from './styled';
import { InputProps } from './types';

type LibraryMessageData = {
  id: string;
  name: string;
  subject: string;
  type: 'broadcasts' | 'automations';
};

export const LibraryMessage = ({
  step,
  value,
  width,
  placeholder,
  error,
  readonly,
  onChange,
}: InputProps) => {
  return (
    <LibraryMessageInput
      disabled={readonly}
      heading="Choose Email to Filter on"
      suppressedRoutes={['templates']}
      suppressedFolders={[
        'Single Sends',
        'Scheduled Broadcasts',
        'Failed Broadcasts',
      ]}
      message={value}
      placeholder={placeholder}
      width={width}
      error={error}
      onChange={(data: LibraryMessageData) => onChange(step, data)}
    />
  );
};
