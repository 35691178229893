import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { gutters, breakpoints, layers } from 'app/spacing';

const STICKY_NAV_BAR_HEIGHT = 52;

const getMobileStickyNavBar = (s, d) => {
  if (s || (!s && d)) return `height: auto;`;

  return `height: ${STICKY_NAV_BAR_HEIGHT}px;`;
};

const getMobileBottomBlock = (s, d) => {
  if (s || (!s && d))
    return `
  opacity: 1;
  @media (max-width: ${breakpoints.md}px) {
    padding-top: ${gutters.spacing(3, +1)}px;
  }`;

  return `
    opacity: 0;
    pointer-events: none;
  `;
};

export const MobileStickyNavBar = styled.div`
  position: sticky;
  top: 0;
  z-index: ${layers.content(10, 2)};
  background-color: ${grayScale.white};
  ${({ show, disabled }) => getMobileStickyNavBar(show, disabled)}
  transition: all 0.6s;
  border-bottom: 1px solid #eceef0;
`;

export const MobileNavBarWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: ${gutters.standard};

  ${({ missing, unavailable }) =>
    missing || unavailable
      ? css`
          padding-bottom: 0;
        `
      : ''}
`;

export const MobileTopBlock = styled.div`
  display: flex;
  padding-bottom: ${gutters.standard};
  justify-content: space-between;
  > div > div > div {
    min-height: 0 !important;
    > div {
      &:first-child {
        padding: 0;
      }
    }
  }
`;

export const MobileBottomBlock = styled.div`
  .selected-values {
    max-height: 80px !important;
  }

  .menu-values {
    max-height: 30rem !important;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: 0;
  padding: 0 30px;
  position: relative;

  & .rs-picker-toggle-wrapper {
    position: unset;
  }

  & > button {
    margin-left: 0;
  }

  ${({ show, disabled }) => getMobileBottomBlock(show, disabled)}

  transition: all 0.6s;
`;

export const MobileTopBlockActions = styled.div`
  display: flex;
  align-items: center;

  .mobile-bottom-nav-icon {
    margin-right: ${gutters.standard};
  }
`;
