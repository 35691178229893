import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { gutters } from 'app/spacing';
import BasicModal from 'components/Modals/presets/BasicModal';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import Row1 from 'components/RowPicker/images/Row-1.svg?react';
import Row11 from 'components/RowPicker/images/Row-1-1.svg?react';
import Row111 from 'components/RowPicker/images/Row-1-1-1.svg?react';
import Row1111 from 'components/RowPicker/images/Row-1-1-1-1.svg?react';
import Row11111 from 'components/RowPicker/images/Row-1-1-1-1-1.svg?react';
import Row111111 from 'components/RowPicker/images/Row-1-1-1-1-1-1.svg?react';
import Row12 from 'components/RowPicker/images/Row-1-2.svg?react';
import Row21 from 'components/RowPicker/images/Row-2-1.svg?react';
import Row121 from 'components/RowPicker/images/Row-1-2-1.svg?react';

const getOptions = (t) => [
  {
    label: t('1 Column'),
    value: [1],
    image: <Row1 />,
  },
  {
    label: t('2 Columns'),
    value: [1, 1],
    image: <Row11 />,
  },
  {
    label: t('3 Columns'),
    value: [1, 1, 1],
    image: <Row111 />,
  },
  {
    label: t('4 Columns'),
    value: [1, 1, 1, 1],
    image: <Row1111 />,
  },
  {
    label: t('5 Columns'),
    value: [1, 1, 1, 1, 1],
    image: <Row11111 />,
  },
  {
    label: t('6 Columns'),
    value: [1, 1, 1, 1, 1, 1],
    image: <Row111111 />,
  },
  {
    label: t('2 Columns (1/3 and 2/3)'),
    value: [1, 2],
    image: <Row12 />,
  },
  {
    label: t('2 Columns (2/3 and 1/3)'),
    value: [2, 1],
    image: <Row21 />,
  },
  {
    label: t('3 Columns (gutters)'),
    value: [1, 2, 1],
    image: <Row121 />,
  },
];

const ButtonGrid = styled.div`
  display: grid;
  gap: ${gutters.spacing(4)}px;
  grid-template-columns: repeat(5, 1fr);
`;

export const RowModal = ({ onHide, onConfirm, ...props }) => {
  const { t } = useTranslation();
  return (
    <BasicModal
      heading={t('Choose Column Layout')}
      buttonText={t('Cancel')}
      actionBtnColor="blue"
      leftBtn={false}
      onHide={onHide}
      onConfirm={onHide} // Looks odd, but this is correct: the primary button is to cancel.
      size="large"
      {...props}
    >
      <ButtonGrid>
        {getOptions(t).map((opt) => (
          <ButtonGroupImageButton
            key={opt.label}
            option={opt}
            onClick={() => onConfirm(opt.value)}
          />
        ))}
      </ButtonGrid>
    </BasicModal>
  );
};

RowModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
