import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';
import parsePhoneNumber, {
  AsYouType,
  getCountryCallingCode,
} from 'libphonenumber-js/max';
import { gutters } from 'app/spacing';
import phoneMeta from 'libphonenumber-js/metadata.min.json';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { getDataQAForInput } from 'components/Inputs/helpers';
import {
  MaybeInputControl,
  getNonInputControlProps,
  hasInputControl,
} from '../../InputControl';
import Select from '../../Select';
import {
  Control as CustomControl,
  Menu as CustomMenu,
} from '../../Select/customize';
import TextInput from '../index';
import usePhoneFormatter from 'hooks/usePhoneFormatter';
import { grayScale } from 'app/colors';
import { suggestedCountryByPhoneCode } from 'components/Fields/helpers';
import { applyRef } from 'components/Inputs/props';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { isCountryCallingCode } from 'components/Fields/helpers';
import { ForceFieldRevalidationContext } from 'ts-components/RecordLayout/blocks/Fields/FieldErrorContext';
import { Button } from '@kizen/kds/Button';
import { useRecordDetailCustomScript } from 'ts-components/ScriptRunner/hooks/useRecordDetailCustomScript';
import { toastVariant, useToast } from 'components/ToastProvider';

const MIN_WIDTH = 175;

// NATIONAL — Example: "(213) 373-4253"
// INTERNATIONAL — Example: "+1 213 373 4253"
// E.164 — Example: "+12133734253"
// RFC3966 (the phone number URI) — Example: "tel:+12133734253;ext=123"
// const FORMAT = 'E.164';
const formatPhoneString = (phoneNumber) => {
  return `${
    phoneNumber.countryCallingCode ? '+' + phoneNumber.countryCallingCode : ''
  }${phoneNumber.nationalNumber}${
    phoneNumber.ext ? 'x' + phoneNumber.ext : ''
  }`;
};

export const validate = (
  t,
  phoneNumber,
  isRequired,
  allowInvalid,
  resetToDefaultOnValidateErr
) => {
  return {
    character: (char) =>
      /[+\d]/.test(char) || t('No alphabetical characters are accepted'),
    full: () => {
      if (allowInvalid) return null;
      if (!phoneNumber.nationalNumber && !phoneNumber.ext) {
        return null;
      }
      if (phoneNumber.ext && !/^\d{1,9}$/.test(phoneNumber.ext)) {
        return t(
          'Please enter a valid phone number. The Extension can contain maximum 9 digits'
        );
      }
      if (phoneNumber?.isValid && !phoneNumber?.isValid()) {
        return resetToDefaultOnValidateErr
          ? t(
              'Please enter a valid phone number. If saving with this value, the field will revert to its previous state (potentially clearing your entry).'
            )
          : t('Please enter a valid phone number');
      }
      return null;
    },
  };
};

const extensionValidate = (t) => {
  return {
    character: (char) =>
      /[0-9]/.test(char) || t('Only numeric characters are accepted.'),
  };
};

const codeOptions = [
  ...Object.keys(phoneMeta['country_calling_codes'])
    .map((code) => ({
      value: code,
      label: `+${code}`,
    }))
    .sort((a, b) => a.value - b.value),
];

const getGridLayout = (ext, isOutline) => {
  if (!ext) {
    return isOutline ? '75px minmax(115px, 1fr)' : '50px 1fr';
  }

  return isOutline ? '75px minmax(115px, 1fr) 70px' : '50px 1fr 40px';
};

const getGridColumns = (ext) => (ext ? 3 : 2);

const FieldLayout = styled.div`
  display: grid;

  ${({ width, enableExtension, variant }) => {
    if (variant === 'outline') {
      if (width >= 280) {
        return css`
          grid-template-columns: ${getGridLayout(enableExtension, true)};
          column-gap: ${gutters.spacing(2)}px;
        `;
      }

      if (width < 280 && width >= 200) {
        return css`
          grid-template-columns: ${'75px minmax(115px, 1fr)'};
          column-gap: ${gutters.spacing(2)}px;
          ${enableExtension &&
          `grid-template-rows: repeat(2, 1fr);
             row-gap: ${gutters.spacing(2)}px;
            `};
        `;
      }

      return css`
        grid-template-rows: repeat(${getGridColumns(enableExtension)}, 1fr);
        gap: ${gutters.spacing(2)}px;
      `;
    }

    if (width >= 174) {
      return css`
        grid-template-columns: ${getGridLayout(enableExtension, false)};
        column-gap: ${gutters.spacing(1)}px;
      `;
    }

    if (width < 174 && width >= 129) {
      return css`
        grid-template-columns: ${'50px minmax(83px, 1fr)'};
        column-gap: ${gutters.spacing(1)}px;
        ${enableExtension &&
        `grid-template-rows: repeat(2, 1fr);
           row-gap: ${gutters.spacing(1)}px;
          `};
      `;
    }

    return css`
      grid-template-rows: repeat(${getGridColumns(enableExtension)}, 1fr);
      gap: ${gutters.spacing(1)}px;
    `;
  }}
`;

const StyledCountryCode = styled(Select)`
  i {
    margin-left: ${gutters.spacing(1)}px;
  }

  ${({ variant }) =>
    variant === 'outline'
      ? css`
          width: 75px;
        `
      : css`
          width: 50px;
        `}
`;

const CountryCodeMenu = styled(CustomMenu)`
  ${({ selectProps: { variant } }) =>
    variant === 'underline' &&
    css`
      width: 50px;
      li {
        padding: 0 0 0 ${gutters.spacing(2, -3)}px;
      }
    `}
`;

const StyledTextInput = styled(TextInput)`
  ${({ variant }) =>
    variant === 'underline' &&
    css`
      padding-right: 0;
    `}

  input {
    width: 100%;
  }
`;

const CountryCodeControlStyles = styled(StylePassthrough)`
  ${({ selectProps: { variant } }) =>
    variant === 'underline' &&
    css`
      && {
        padding-right: ${gutters.spacing(1, -3)}px;
        background-color: ${grayScale.white}};
      }
    `}
`;

const AdornmentContainer = styled.div`
  color: var(--adornment-color);
`;

const CountryCodeControl = (props) => {
  const {
    selectProps: { menuIsOpen = false, setIsCountryCodeMenuOpen = undefined },
  } = props;

  useEffect(() => {
    if (setIsCountryCodeMenuOpen) {
      setIsCountryCodeMenuOpen(menuIsOpen);
    }
  }, [menuIsOpen, setIsCountryCodeMenuOpen]);

  return (
    <CountryCodeControlStyles {...props}>
      <CustomControl {...props} />
    </CountryCodeControlStyles>
  );
};

const AdornmentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Adornments = styled.div`
  padding-left: 5px;
`;

const StyledExtension = styled(TextInput)`
  ${({ variant }) =>
    variant === 'outline'
      ? css`
          width: 70px;

          input {
            width: 49px;
          }
        `
      : css`
          width: 40px;

          input {
            width: 40px;
          }
        `}
`;

const tryPhoneNumberValidParse = (phoneData, defaultCC) => {
  const firstTry = parsePhoneNumber(phoneData.number);
  if (firstTry && firstTry.isValid()) {
    return firstTry;
  }

  const secondTry = parsePhoneNumber(`+${defaultCC}${phoneData.number}`);
  if (secondTry && secondTry.isValid()) {
    return secondTry;
  }

  const thirdTry = parsePhoneNumber(
    `+${phoneData.countryCallingCode || ''}${phoneData.number}`
  );
  if (thirdTry && thirdTry.isValid()) {
    return thirdTry;
  }

  return false;
};

const getDataForSubmit = (phoneData, defaultCC, stagedValue, allowInvalid) => {
  let newData = {};

  const parsedPhoneNumber = tryPhoneNumberValidParse(phoneData, defaultCC);

  if (parsedPhoneNumber) {
    newData = parsedPhoneNumber;
    if (phoneData.ext) {
      newData.ext = phoneData.ext;
    }
  } else if (!phoneData.number) {
    newData = phoneNumberObject;
  }

  const formattedValue = formatPhoneString(newData);

  if (stagedValue === formattedValue) {
    return;
  }

  if (allowInvalid && !newData.nationalNumber && !newData.ext) {
    return null;
  }
  if (
    (!newData.ext || (newData.ext && /^\d{1,9}$/.test(newData.ext))) &&
    newData?.isValid &&
    newData?.isValid()
  ) {
    return formattedValue;
  }
};

const phoneNumberObject = {
  number: '',
  countryCallingCode: '',
  nationalNumber: '',
  country: '',
  ext: '',
  isValid: () => {},
};

const PhoneNumberTextInput = React.forwardRef((props, ref) => {
  const [fieldLayout, { width }] = useMeasure();
  const {
    fieldId,
    value,
    onChange = () => {},
    placeholderNumber,
    placeholderExtension,
    enableExtension,
    variant,
    disabled,
    readOnly, // readOnly is styled slightly different from disabled
    'data-field-type': qaFieldType,
    'data-field-id': qaFieldId,
    defaultCountry,
    onKeyPress,
    fieldType,
    setIsCountryCodeMenuOpen,
    setDefaultCountryCode = false,
    onSubmit,
    inLine,
    adornments = [],
    object,
    objectId,
    ...rest
  } = props;

  const {
    fieldId: _fieldId,
    className = '',
    styles,
    error,
    autoFocus,
    validate: externalValidation,
    allowInvalid = false, // this will call onChange with an invalid value _and_ disable validation (setting returnInvalid would be redundant)
    ...nonInputProps
  } = getNonInputControlProps(props);
  const { assignFieldHandle } = useKeyBoardContext();
  const { t } = useTranslation();
  const country = defaultCountry || 'US';
  const prevPhoneNumber = useRef({
    ...phoneNumberObject,
    country: country,
  });

  const hasAdornment = adornments.length > 0 && value;

  const maybeFieldContext = useContext(ForceFieldRevalidationContext);

  const [phoneData, setPhoneData] = useState(() => {
    const parsedPhoneNumber = value && parsePhoneNumber(value);
    const targetValue = parsedPhoneNumber
      ? parsedPhoneNumber
      : {
          ...phoneNumberObject,
          countryCallingCode: setDefaultCountryCode
            ? getCountryCallingCode(country)
            : '',
          country,
        };

    // If the phone number component changes the value that was passed as the initial state, we should
    // update whoever passed that value with the new value so it stays in sync. This is probably
    // safe to always do, but for now it's guarded by the flag for the record detail page data
    // revalidating logic and only used on the detail page fields block.
    if (value && !parsedPhoneNumber && maybeFieldContext?.alwaysRevalidate) {
      onChange(formatPhoneString(targetValue));
    }

    return targetValue;
  });

  const numberRef = useRef();
  const inputRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(numberRef, el);
      applyRef(ref, el);
      applyRef(inputRef, el);
    },
    [ref]
  );

  const [forceValidation, setForceValidation] = useState(false);
  const [internalNationalNumber, setInternalNationalNumber] = useState(
    phoneData.nationalNumber || ''
  );
  const stagedValue = useRef(value);

  useEffect(() => {
    if (value !== stagedValue.current) {
      stagedValue.current = value;
      if (value) {
        const parsedPhoneNumber = parsePhoneNumber(value);
        if (parsedPhoneNumber) {
          setPhoneData(parsedPhoneNumber);
          setInternalNationalNumber(parsedPhoneNumber.nationalNumber);
        }
      } else {
        setPhoneData({
          ...phoneNumberObject,
          country,
        });
      }
    }
  }, [country, value]);

  const defaultCC =
    prevPhoneNumber.current.countryCallingCode ||
    getCountryCallingCode(country);

  const setChange = useCallback(
    (phoneNumber, event) => {
      const formattedValue = formatPhoneString(phoneNumber);
      // prevent infinite loop
      // if (formattedValue === value) return;
      // avoid initial render;
      if (stagedValue.current === formattedValue) {
        return;
      }

      // fix for inline components to submit null on empty/invalid value
      if (allowInvalid && !phoneNumber.nationalNumber && !phoneNumber.ext) {
        onChange(null);
        return;
      }
      if (
        (!phoneNumber.ext ||
          (phoneNumber.ext && /^\d{1,9}$/.test(phoneNumber.ext))) &&
        phoneNumber?.isValid &&
        phoneNumber?.isValid()
      ) {
        onChange(formattedValue, event, null);
        return;
      }
      const errorMessage = validate(
        t,
        phoneNumber,
        props.required,
        allowInvalid,
        props.resetToDefaultOnValidateErr
      ).full(phoneNumber);
      onChange(
        formattedValue,
        event,
        errorMessage
          ? {
              errorMessage,
              removeOnSubmit: isCountryCallingCode(formattedValue),
            }
          : null
      );
    },
    [
      onChange,
      props.required,
      t,
      props.resetToDefaultOnValidateErr,
      allowInvalid,
    ]
  );

  const { formattedValue, asYouTypeOptions } = usePhoneFormatter(
    phoneData,
    country
  );

  const selectRef = useRef(null);
  const extensionRef = useRef(null);
  const focusRef = useRef(null);

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    props
  );

  assignFieldHandle(fieldId, {
    ...(inLine
      ? {
          customTab: async (e) => {
            const shouldStayInField = e.shiftKey
              ? focusRef.current !== 0
              : (enableExtension && focusRef.current < 2) ||
                focusRef.current === 0;
            if (e.shiftKey) {
              if (focusRef.current === 2) {
                inputRef.current?.querySelector('input')?.focus();
              }
              if (focusRef.current === 1) {
                selectRef.current.select.focus();
                focusRef.current = 0;
                setMenuIsOpen?.(false);
              }
            } else {
              if (focusRef.current === 0) {
                inputRef.current?.querySelector('input')?.focus();
              } else if (focusRef.current === 1 && enableExtension) {
                extensionRef.current?.querySelector('input')?.focus();
              } else if (focusRef.current === 1) {
                await inputRef?.current?.querySelector('input')?.blur();
              }
            }
            if (!shouldStayInField) {
              focusRef.current = null;
              const data = getDataForSubmit(
                phoneData,
                defaultCC,
                stagedValue,
                allowInvalid
              );

              if (data || !phoneData.number) {
                return await onSubmit?.(e, data || '');
              } else {
                inputRef.current?.querySelector('input')?.focus();
                return true;
              }
            }
            return shouldStayInField;
          },
          customEnter: async (e) => {
            const shouldStayInField =
              (enableExtension && focusRef.current < 2) ||
              focusRef.current === 0;
            if (focusRef.current === 0) {
              inputRef?.current?.querySelector('input')?.focus();
            } else if (focusRef.current === 1 && enableExtension) {
              extensionRef?.current?.querySelector('input')?.focus();
            } else if (focusRef.current === 1) {
              await inputRef?.current?.querySelector('input')?.blur();
            }
            if (!shouldStayInField) {
              const data = getDataForSubmit(
                phoneData,
                defaultCC,
                stagedValue,
                allowInvalid
              );

              if (data || !phoneData.number) {
                return await onSubmit?.(e, data || '');
              } else {
                inputRef.current?.querySelector('input')?.focus();
                return true;
              }
            }
            return shouldStayInField;
          },
        }
      : {
          customTab: (e) => {
            const shouldStayInField = e.shiftKey
              ? focusRef.current !== 0
              : (enableExtension && focusRef.current < 2) ||
                focusRef.current === 0;
            if (e.shiftKey) {
              if (focusRef.current === 2) {
                inputRef.current?.querySelector('input')?.focus();
                focusRef.current = 1;
              } else {
                if (focusRef.current === 1) {
                  selectRef.current.select.focus();
                  focusRef.current = 0;
                  setMenuIsOpen?.(false);
                }
              }
            } else {
              if (focusRef.current === 0) {
                inputRef.current?.querySelector('input')?.focus();
              } else if (focusRef.current === 1 && enableExtension) {
                extensionRef.current.querySelector('input').focus();
              }
            }
            if (!shouldStayInField) {
              focusRef.current = null;
            }
            return shouldStayInField;
          },
          customEnter: () => {
            const shouldStayInField =
              (enableExtension && focusRef.current < 2) ||
              focusRef.current === 0;
            if (focusRef.current === 0) {
              inputRef.current?.querySelector('input')?.focus();
            } else if (focusRef.current === 1 && enableExtension) {
              extensionRef.current.querySelector('input').focus();
            }
            return shouldStayInField;
          },
        }),
    customFocus: () => {
      if (focusRef.current === null) {
        inputRef.current?.querySelector('input')?.focus();
        focusRef.current = 1;
      }
      return inputRef.current?.querySelector('input');
    },
    customEscape: () => {
      if (focusRef.current === 0) {
        setMenuIsOpen?.(false);
      }
    },
    customUp: () => {
      focusRef.current === 0 && setMenuIsOpen?.(true);
    },
    customDown: () => {
      focusRef.current === 0 && setMenuIsOpen?.(true);
    },
    disabled,
  });

  const {
    value: _,
    'data-field-type': _1,
    'data-field-id': _2,
    ...forInputControl
  } = props;

  // country field
  const handleChangeCountryCode = (opt) => {
    let newData;
    if (phoneData.number) {
      newData =
        parsePhoneNumber(`+${opt.value}${phoneData.nationalNumber}`) ||
        parsePhoneNumber(`+${defaultCC}${phoneData.nationalNumber}`);

      if (phoneData.ext) {
        newData.ext = phoneData.ext;
      }
    } else {
      newData = phoneData;
      newData.countryCallingCode = opt.value;
      newData.country = suggestedCountryByPhoneCode(opt.value);
    }

    prevPhoneNumber.current = newData;
    setPhoneData(newData);
    numberRef.current.firstChild.focus();
    setChange(newData, null);
  };

  // number field
  const handleFocusNumber = (event) => {
    focusRef.current = 1;
    setForceValidation(Math.random());
    setChange(phoneData, event);
  };

  const handleChangeNumber = (number) => {
    const newData = {
      ...phoneData,
      number,
    };
    setPhoneData(newData);
    prevPhoneNumber.current = newData;
    setInternalNationalNumber(number);
  };

  const handleBlurNumber = (event) => {
    focusRef.current = null;
    let newData;

    const parsedPhoneNumber = tryPhoneNumberValidParse(phoneData, defaultCC);

    if (parsedPhoneNumber) {
      newData = parsedPhoneNumber;
      if (phoneData.ext) {
        newData.ext = phoneData.ext;
      }
    } else if (!phoneData.number) {
      newData = { ...phoneNumberObject };
    } else {
      newData = parsePhoneNumber(
        `${
          !prevPhoneNumber.current.number.includes('+')
            ? `+${prevPhoneNumber.current.countryCallingCode || defaultCC}`
            : ''
        }${prevPhoneNumber.current.number}`
      ) || {
        ...phoneNumberObject,
        countryCallingCode: defaultCC,
        nationalNumber:
          prevPhoneNumber.current.nationalNumber || phoneData.number,
        ext: prevPhoneNumber.current.ext,
        country: suggestedCountryByPhoneCode(defaultCC),
      };

      if (phoneData.ext) {
        newData.ext = phoneData.ext;
      }
    }
    setInternalNationalNumber(newData.nationalNumber);
    setPhoneData(newData);
    prevPhoneNumber.current = newData;
    setForceValidation(Math.random());
    setChange(newData, event);
  };

  // extension field
  const handleChangeExtension = (extension, event) => {
    const newData = phoneData;
    newData.ext = extension;
    prevPhoneNumber.current = newData;
    setPhoneData(newData);
    setChange(newData, event);
    setForceValidation(Math.random());
  };

  const formattedNationalNumber = useMemo(
    () => new AsYouType(asYouTypeOptions).input(phoneData.nationalNumber || ''),
    [phoneData.nationalNumber, asYouTypeOptions]
  );

  const input = (
    <MaybeInputControl
      forInput
      {...forInputControl}
      isShowAsterisk={forInputControl.required}
    >
      {variant === 'underline' && disabled ? (
        <TextInput disabled variant="underline" value={formattedValue} />
      ) : (
        <FieldLayout
          ref={fieldLayout}
          width={width || MIN_WIDTH}
          enableExtension={enableExtension}
          variant={variant}
          {...rest}
          className={hasInputControl(props) ? '' : className}
        >
          <StyledCountryCode
            ref={selectRef}
            isSearchable
            {...nonInputProps}
            disabled={nonInputProps.disabled || nonInputProps.readOnly}
            options={codeOptions}
            placeholder={'+' + defaultCC}
            className="country-calling-code"
            value={
              phoneData.countryCallingCode ||
              prevPhoneNumber.current.countryCallingCode
            }
            onChange={handleChangeCountryCode}
            components={{
              Control: CountryCodeControl,
              Menu: CountryCodeMenu,
              ...nonInputProps.components,
            }}
            {...customSelectMenuProps}
            onFocus={(...data) => {
              focusRef.current = 0;
              props.onFocus?.(...data);
            }}
          />
          <StyledTextInput
            className="national-number"
            {...nonInputProps}
            ref={mergeRef}
            disabled={nonInputProps.disabled || nonInputProps.readOnly}
            styles={styles}
            placeholder={placeholderNumber || t('Phone Number')}
            value={
              focusRef.current === 1
                ? internalNationalNumber
                : formattedNationalNumber
            }
            onBlur={handleBlurNumber}
            onFocus={handleFocusNumber}
            onChange={handleChangeNumber}
            validate={{
              // internal validation
              ...validate(
                t,
                phoneData,
                forInputControl.required,
                allowInvalid,
                props.resetToDefaultOnValidateErr
              ),
              // external validation has higher priority
              ...props.validate,
            }}
            error={error}
            autoFocus={autoFocus}
            data-field-type="phonenumber"
            forceValidation={forceValidation}
          />
          {enableExtension && (
            <StyledExtension
              {...nonInputProps}
              ref={extensionRef}
              placeholder={placeholderExtension || t('Ext')}
              value={phoneData.ext || ''}
              validate={extensionValidate(t)}
              onBlur={(event) => {
                setChange(phoneData, event);
              }}
              onFocus={() => {
                focusRef.current = 2;
              }}
              onChange={handleChangeExtension}
              errorPlacement="bottom-end"
              {...getDataQAForInput(qaFieldId, `${qaFieldType}-ext`)}
              data-field-type="phonenumber-ext"
            />
          )}
        </FieldLayout>
      )}
    </MaybeInputControl>
  );

  const [showToast] = useToast();

  const [executeScript, internalState] = useRecordDetailCustomScript({
    entityId: object?.id,
    objectId,
    onError: (e) => {
      showToast({
        message: `${t('Action could not run')}: ${e?.message}`,
        variant: toastVariant.FAILURE,
      });
    },
  });

  if (hasAdornment) {
    return (
      <AdornmentWrapper>
        {input}
        <Adornments>
          {adornments.map((adornment) => {
            return (
              <AdornmentContainer
                key={adornment.id}
                style={{
                  '--adornment-color': adornment.config?.color || '#000',
                }}
              >
                <Button
                  rightIcon={`custom-${adornment.config?.icon}`}
                  variant="text"
                  color="inherit"
                  rightIconSettings={{
                    tooltipDescriptionOverride: adornment?.config?.tooltip,
                  }}
                  onClick={() =>
                    executeScript(
                      adornment.script,
                      {
                        value,
                        fieldId,
                        fieldType,
                      },
                      adornment
                    )
                  }
                  disabled={internalState.pending}
                />
              </AdornmentContainer>
            );
          })}
        </Adornments>
      </AdornmentWrapper>
    );
  }

  return input;
});

PhoneNumberTextInput.displayName = 'PhoneNumberTextInput';
PhoneNumberTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultCountry: PropTypes.string,
  placeholderNumber: PropTypes.string,
  placeholderExtension: PropTypes.string,
  enableExtension: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
PhoneNumberTextInput.defaultProps = {
  defaultCountry: 'US',
  placeholderNumber: '',
  placeholderExtension: '',
  enableExtension: false,
  variant: 'outline',
  disabled: false,
};

export default PhoneNumberTextInput;
