import styled from '@emotion/styled';
import Image from 'react-image-resizer';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import useField from 'hooks/useField';
import { breakpoints, gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import { KizenTypography } from 'app/typography';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { previewFileExtensions } from 'components/FilePicker/defaults';
import { useRef, useState } from 'react';
import css from '@emotion/css';
import { useDebounce, useWindowSize } from 'react-use';
import { LabelsPreviewBlock } from './LabelsPreviewBlock';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100% - 184px); /* 164px D&D button + 20px top margin */

  &:not(:only-child) {
    margin-top: ${gutters.spacing(4)}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    &:not(:only-child) {
      margin-top: ${gutters.spacing(3)}px;
      max-height: calc(100% - 120px); /* 105px D&D button + 15px top margin */
    }
  }
  ${({ isVisible }) =>
    isVisible
      ? ''
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`;

export const PreviewImageContainer = styled.div`
  max-width: 230px;
  max-height: 230px;
  min-width: 150px;
  min-height: 0;
  aspect-ratio: 1;
  height: 100%;
  width: 100%;
  margin: 0 15px 15px 15px;
  flex-shrink: 2;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin: 0 0 14px;
    max-width: 120px;
    max-height: 120px;
    min-width: 80px;
    min-height: 0;
  }
  ${({ size }) =>
    size <= 150
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : ''}
`;

const NoImage = styled.div`
  display: flex;
  height: 100%;
  aspect-ratio: 1;
  background: ${grayScale.light};
  justify-content: center;
  align-items: center;
  > p {
    font-size: 32px;
  }
`;

const StyledKizenTypography = styled(KizenTypography)`
  margin-bottom: 15px;
`;

const defaultPreview = {
  name: '',
  url: null,
  sizeFormatted: '',
  contentType: '',
  created: '',
};
const PreviewBlock = ({
  file,
  updateFile,
  disabled,
  viewable,
  isMobile = false,
}) => {
  const { t } = useTranslation();

  const ext = file && (file?.name || '').split('.').pop();
  const isImage = previewFileExtensions.includes(`.${ext.toLowerCase()}`);
  const created =
    (file &&
      file.created &&
      format(new Date(file.created), 'M/DD/YYYY \\a\\t h:mm A')) ||
    '';
  const [info] = useField(() => {
    return {
      ...defaultPreview,
      ...snakeToCamelCaseKeys(file),
      created,
    };
  }, [file, file.name]);
  const [size, setSize] = useState(0);
  const containerRef = useRef(null);
  const wrapperRef = useRef(null);
  const { height } = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);
  useDebounce(
    () => {
      setSize(containerRef.current?.offsetHeight || 0);
      setIsVisible(
        wrapperRef.current?.offsetHeight === wrapperRef.current?.scrollHeight
      );
    },
    0,
    [height, info]
  );

  return (
    <Wrapper isVisible={isVisible} ref={wrapperRef}>
      {isMobile ? null : (
        <StyledKizenTypography wight="bold">
          {t('Preview/Info')}
        </StyledKizenTypography>
      )}
      {containerRef.current && size <= 150 ? (
        <LabelsPreviewBlock
          isMobile={isMobile}
          info={info}
          disabled={disabled}
          file={file}
          updateFile={updateFile}
          viewable={viewable}
          ext={ext}
        />
      ) : null}
      <PreviewImageContainer ref={containerRef} size={size}>
        {info.url && isImage ? (
          <Image
            key={info.url + size}
            src={info.url}
            height={size}
            width={size}
          />
        ) : (
          <NoImage>
            {ext && (
              <KizenTypography type="header" weight="bold">
                {!isImage && `.${ext.toUpperCase()}`}
              </KizenTypography>
            )}
          </NoImage>
        )}
      </PreviewImageContainer>
      {containerRef.current && size > 150 ? (
        <LabelsPreviewBlock
          isMobile={isMobile}
          info={info}
          disabled={disabled}
          file={file}
          updateFile={updateFile}
          viewable={viewable}
          ext={ext}
        />
      ) : null}
    </Wrapper>
  );
};
export default PreviewBlock;
