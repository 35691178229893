import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { breakpoints, gutters } from 'app/spacing';

export const CONTENT_MAX_WIDTH = '1372px';

export const pageSizingCss = css`
  margin: 0 ${gutters.spacing(3)}px;

  @media (min-width: ${breakpoints.md + 1}px) {
    margin: 0 ${gutters.spacing(4)}px;
  }
`;

export const PageSizing = styled.div`
  position: relative;
  ${pageSizingCss};
`;

export const contentWidthCss = css`
  max-width: ${CONTENT_MAX_WIDTH};
  width: 100%;
  margin: 0 auto;
`;

export const ContentWidth = styled.div`
  ${contentWidthCss}

  ${({ contentWidth }) =>
    contentWidth &&
    css`
      max-width: ${contentWidth};
    `}
`;

export const PageContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: calc(${CONTENT_MAX_WIDTH} + 40px);
  padding: 0 ${gutters.spacing(4)}px;

  @media (max-width: ${breakpoints.lg}px) {
    max-width: calc(${CONTENT_MAX_WIDTH} + 30px);
    padding: 0 ${gutters.spacing(3)}px;
  }

  @media (max-width: ${breakpoints.md}px) {
    max-width: calc(${CONTENT_MAX_WIDTH} + 2rem);
    padding: 0 1rem;
  }
`;
