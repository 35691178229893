import {
  call,
  takeLatest,
  put,
  all,
  select,
  debounce,
} from 'redux-saga/effects';
import i18nInstance from 'i18n-config';

import { snakeToCamelCaseKeys } from 'services/helpers';
import { DEFAULT_INPUT_DELAY, SET_CONFIG_DELAY } from 'utility/config';
import CustomObjectsService from 'services/CustomObjectsService';
import TeamMemberService from 'services/TeamMemberService';

import {
  buildPage as buildPageAction,
  buildPageComplete,
  buildPageSuccess,
  buildPageFail,
  getCustomObjectsStart,
  getCustomObjects as getCustomObjectsAction,
  getCustomObjectsSuccess,
  getCustomObjectsFail,
  updateCustomObjectField as updateCustomObjectFieldAction,
  updateCustomObjectFieldSuccess,
  updateCustomObjectFieldFail,
  deleteCustomObject as deleteCustomObjectAction,
  deleteCustomObjectSuccess,
  deleteCustomObjectFail,
  setPageConfig as setPageConfigAction,
  updatePageConfigBySearch,
  setPageConfigSuccess,
  setPageConfigFail,
  generatePageConfig,
  getFullListOfCustomObjects as getFullListOfCustomObjectsAction,
  getFullListOfCustomObjectsFail,
  getFullListOfCustomObjectsSuccess,
  getCustomObjectBySearch,
  getCustomObjectsFinish,
} from './customObjects.redux';
import { toastVariant } from 'components/ToastProvider';

const PAGE_CONFIG_KEY = 'custom_object_list';

function* getCustomObjects(actionData) {
  try {
    yield put(getCustomObjectsStart());
    const payload = actionData && actionData.payload;
    const cancelToken = payload && payload.cancelToken;
    const pageConfig = yield select((s) => s.customObjectsPage.pageConfig);
    const data = {
      values: {
        search: pageConfig.search,
        ordering: pageConfig.sort,
        page: pageConfig.page,
        size: pageConfig.size,
        criteria: !pageConfig.filterObject.query
          ? null
          : pageConfig.filterObject,
      },
      options: { cancelToken },
    };

    const [result] = yield all([
      call(CustomObjectsService.getCustomObjectList, data.values, data.options),
      payload?.updatePageConfig &&
        call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig),
    ]);

    yield put(
      getCustomObjectsSuccess({
        customObjects: snakeToCamelCaseKeys(result.results),
        customObjectsCount: result.count,
        customObjectAccess: result.access,
      })
    );
  } catch (error) {
    yield put(getCustomObjectsFail(error && error.message));
  } finally {
    yield put(getCustomObjectsFinish());
  }
}

function* buildPage() {
  try {
    // CustomObjectsService.getGroups()
    const pageResponse = yield call(
      TeamMemberService.getPageConfig,
      PAGE_CONFIG_KEY,
      generatePageConfig()
    );

    yield put(
      buildPageComplete({
        pageResponse,
      })
    );

    yield all([getCustomObjects({ payload: { updatePageConfig: false } })]);

    yield put(buildPageSuccess());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* updateCustomObjectField({ payload }) {
  try {
    yield put(updateCustomObjectFieldSuccess(payload));
  } catch (error) {
    yield put(updateCustomObjectFieldFail(error));
  }
}

function* deleteCustomObject({ payload }) {
  try {
    const data = {
      values: {
        id: payload,
      },
    };
    yield call(CustomObjectsService.deleteCustomObject, data.values);
    yield put(
      deleteCustomObjectSuccess({
        variant: toastVariant.SUCCESS,
        message: i18nInstance.t(
          'The custom object has been successfully deleted.'
        ),
      })
    );
    yield all([getCustomObjects()]);
  } catch (error) {
    yield put(
      deleteCustomObjectFail({
        variant: toastVariant.FAILURE,
        message: i18nInstance.t(
          'The custom object has not been successfully deleted.'
        ),
      })
    );
  }
}

function* setPageConfig() {
  try {
    const pageConfig = yield select((s) => s.customObjectsPage.pageConfig);
    yield call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig);

    yield put(setPageConfigSuccess());
  } catch (error) {
    yield put(setPageConfigFail(error));
  }
}

function* getFullListOfCustomObjects() {
  try {
    const data = yield call(CustomObjectsService.getCustomObjectListLight);
    yield put(getFullListOfCustomObjectsSuccess(data));
  } catch (error) {
    yield put(getFullListOfCustomObjectsFail(error));
  }
}

function* CustomObjectsPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield debounce(
    DEFAULT_INPUT_DELAY,
    getCustomObjectsAction.type,
    getCustomObjects
  );
  yield debounce(
    DEFAULT_INPUT_DELAY,
    getCustomObjectBySearch.type,
    getCustomObjects
  );
  yield takeLatest(updateCustomObjectFieldAction.type, updateCustomObjectField);
  yield takeLatest(deleteCustomObjectAction.type, deleteCustomObject);
  yield takeLatest(setPageConfigAction, setPageConfig);
  yield debounce(
    SET_CONFIG_DELAY,
    updatePageConfigBySearch.type,
    setPageConfig
  );
  yield takeLatest(
    getFullListOfCustomObjectsAction.type,
    getFullListOfCustomObjects
  );
}

export default CustomObjectsPage;
