import { createElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_CONTAINER_PROPS } from '@kizen/page-builder/nodes/containers';
import { types } from '@kizen/page-builder/nodes/types';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { useAddNewNode } from '../useAddNewNode';

const CreateHTMLBlock = ({ defaultProps }) => {
  const addNewNode = useAddNewNode();
  const item = useSelector((s) => getCreateItemByType(s, types.HTMLBlock.type));

  useEffect(() => {
    if (item) {
      addNewNode({
        ...item,
        ...defaultProps,
      });
    }
  }, [item, defaultProps, addNewNode]);

  return null;
};

export const FormHTMLBlockCreator = createElement(CreateHTMLBlock, {
  defaultProps: {
    ...DEFAULT_CONTAINER_PROPS,
    htmlContent: '<div style="height:10px;"></div>',
  },
});
