import Icon from 'components/Kizen/Icon';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledEditableText } from '../../styles';
import { URLItemWrapper, URLToolsWrapper } from './styles';

const RecentItem = ({
  item,
  editable,
  index,
  childIndex,
  editValue,
  onOpenMenu,
  onCloseMenu,
  editOptions,
  removeItem,
}) => {
  const { t } = useTranslation();

  const onChangeDisplayName = useCallback(
    (value) => {
      editValue(index, childIndex, 'displayName', value);
    },
    [editValue, index, childIndex]
  );

  const options = useMemo(() => {
    return [
      {
        value: 'remove',
        label: t('Remove from Toolbar'),
      },
      {
        value: 'edit',
        label: t('Edit Options'),
      },
    ];
  }, [t]);

  const handleChooseValue = useCallback(
    ({ value }) => {
      switch (value) {
        case 'remove':
          return removeItem(index, childIndex);
        case 'edit':
          return editOptions(index, childIndex, item);
        default:
          break;
      }
    },
    [removeItem, editOptions, childIndex, index, item]
  );

  const [tooltipProps, tooltip] = useTruncationTooltip({
    label: item.displayName,
  });

  return (
    <URLItemWrapper>
      {editable ? (
        <>
          <StyledEditableText
            value={item.displayName}
            placeholder={t('Display Name')}
            onChange={onChangeDisplayName}
            {...tooltipProps}
          />
          {tooltip}
        </>
      ) : null}
      <URLToolsWrapper child={childIndex > -1}>
        <IconButtonMenu
          title={t('Edit Options')}
          position="right"
          sizing="dense"
          options={options}
          onOpen={onOpenMenu}
          onClose={onCloseMenu}
          onChange={handleChooseValue}
          restrictCloseHandler
        >
          <Icon icon="three-dot" />
        </IconButtonMenu>
      </URLToolsWrapper>
    </URLItemWrapper>
  );
};

export default RecentItem;
