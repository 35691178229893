import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import css from '@emotion/css';

export const SearchWrapper = styled.div`
  position: absolute;
  width: 28px;
  top: 50%;
  transform: translateY(-50%);
  right: ${gutters.spacing(1)}px;

  ${({ inline }) =>
    inline
      ? css`
          position: unset;
          top: unset;
          right: unset;
          transform: unset;
          width: unset;
        `
      : ''}
`;

export const CustomBadgeText = styled(KizenTypography)`
  color: ${grayScale.white};
`;
