export const settingsUrlBase = '/settings';

export const settingsPagePaths = {
  business: 'business',
  team: 'team',
  customFields: 'custom-fields',
  domainAndTracking: 'domain-tracking',
  privacySettings: 'privacy-settings',
  manageConnections: 'manage-connections',
};

const paths = new Map(
  Object.entries(settingsPagePaths).map(([key, value]) => [
    key,
    `${settingsUrlBase}/${value}`,
  ])
);

export default paths;
