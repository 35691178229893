import React from 'react';

export default function TrashAltLight(props) {
  return (
    /* eslint-disable */
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 11.75 5.5 H 0.25 C 0.1119 5.5 0 5.6119 0 5.75 V 6.75 C 0 6.8881 0.1119 7 0.25 7 H 11.75 C 11.8881 7 12 6.8881 12 6.75 V 5.75 C 12 5.6119 11.8881 5.5 11.75 5.5 Z"
        fill="currentColor"
      />
    </svg>

    /* eslint-enable */
  );
}
