import React from 'react';

export default function EqualsLight(props) {
  return (
    /* eslint-disable */
    <svg
      width="25"
      height="13"
      viewBox="0 0 25 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4792 9.375H0.520833C0.233073 9.375 0 9.60807 0 9.89583V11.9792C0 12.2669 0.233073 12.5 0.520833 12.5H24.4792C24.7669 12.5 25 12.2669 25 11.9792V9.89583C25 9.60807 24.7669 9.375 24.4792 9.375ZM24.4792 0H0.520833C0.233073 0 0 0.233073 0 0.520833V2.60417C0 2.89193 0.233073 3.125 0.520833 3.125H24.4792C24.7669 3.125 25 2.89193 25 2.60417V0.520833C25 0.233073 24.7669 0 24.4792 0Z"
        fill="#4A5660"
      />
    </svg>

    /* eslint-enable */
  );
}
