import { forwardRef, useEffect, useState } from 'react';
import { TEAM_MEMBERS } from 'queries/query-keys';
import TeamMemberService from 'services/TeamMemberService';
import { getTeamLabel } from 'services/helpers';
import { renderWith } from '../utils/suggestions';
import { SuggestionList } from '../components/SuggestionList';

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_ORDER = 'first_name';

export const TeamMemberList = forwardRef(({ command, items, ...rest }, ref) => {
  const [iterableData, setIterableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isIterable = Boolean(items[Symbol.asyncIterator]);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = [];
        setIsLoading(true);

        for await (const item of items) {
          data.push(item);
        }

        setIterableData(data);
      } finally {
        setIsLoading(false);
      }
    };

    if (isIterable) {
      getData();
    }
  }, [isIterable, items]);

  return (
    <SuggestionList
      ref={ref}
      isLoading={isLoading}
      items={isIterable ? iterableData : items}
      onSelect={(item) =>
        command({ id: item.id, label: item.label, stamp: Date.now() })
      }
      {...rest}
    />
  );
});

/**
 * A debounced function that gets the items for the suggestion list. react-query is used
 * to make the make api call via the queryClient. If the current query is present in the
 * cache, it will clear the current timeout and return the cached value. This cancels out
 * function call until a new query value is provided that does not have a cache value.
 *
 * @remarks A result needs to be returned immediately so characters are not dropped.
 * An async iterable is returned when the query is empty to achieve this. The iterable
 * also allows {@link TeamMemberList} to create a loading state for the initial request.
 *
 * @param {*} queryClient - https://react-query.tanstack.com/reference/QueryClient
 * @param {number} debounceValue - number of milliseconds to debounce (default 300)
 * @returns async function where the first parameter is the query string. This function
 * corresponds to the @tiptap/suggestion `items` property (https://github.com/ueberdosis/tiptap/blob/main/packages/suggestion/src/suggestion.ts)
 */
const fetchTeamMembers = (
  queryClient,
  debounceValue = 300,
  pageSize = DEFAULT_PAGE_SIZE
) => {
  let timeoutId = null;

  /* {editor: Editor, query: ''} */
  return async ({ query }) => {
    if (timeoutId) clearTimeout(timeoutId);

    const mapper = (tm) => ({ label: getTeamLabel(tm), id: tm.id });

    const searchParams = {
      search: query.toLowerCase(),
      ordering: DEFAULT_ORDER,
      page_size: pageSize,
    };

    const queryKey = [
      ...TEAM_MEMBERS.LIST,
      'typeahead',
      pageSize,
      query.toLowerCase(),
    ];

    const cachedData = queryClient.getQueryData(queryKey)?.results;
    if (cachedData) {
      return Promise.resolve(cachedData.map(mapper));
    }

    if (!query) {
      return {
        async *[Symbol.asyncIterator]() {
          const data = await queryClient.fetchQuery(queryKey, () =>
            TeamMemberService.getTeamMemberTypeahead(searchParams)
          );
          if (Array.isArray(data?.results)) {
            for (const item of data.results) {
              yield mapper(item);
            }
          }
        },
      };
    }

    return new Promise((resolve) => {
      timeoutId = setTimeout(async () => {
        const data = await queryClient.fetchQuery(queryKey, () =>
          TeamMemberService.getTeamMemberTypeahead(searchParams)
        );
        timeoutId = null;

        resolve((data?.results ?? []).map(mapper));
      }, debounceValue);
    });
  };
};

export default (queryClient, { appendToSelector } = {}) => {
  return {
    items: fetchTeamMembers(queryClient),
    render: renderWith(TeamMemberList, {
      appendToSelector,
      tippyConfig: {
        offset: [0, -2],
      },
    }),
  };
};
