import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { getStreamKey } from '@kizen/permissions/utils';
import { useIsPermissionVisible } from '@kizen/permissions/hooks/useIsPermissionVisible';
import KizenTypography from 'app/kizentypo';
import { truncateCss } from 'app/typography';
import { PermissionRangeInput } from './PermissionRangeInput';
import { Tooltip } from './Tooltip';
import { GridContainer } from './styled';

const PermissionInputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 15px;
`;

const Label = styled(KizenTypography)`
  ${truncateCss}
  margin-bottom: 15px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  ${({ indent }) =>
    indent &&
    css`
      padding-left: ${indent}px;
    `}
`;

export const PermissionLabel = ({ label, labelIndent, tooltip }) => (
  <LabelContainer indent={labelIndent}>
    <Label>{label}</Label>
    <Tooltip text={tooltip} show={Boolean(tooltip)} />
  </LabelContainer>
);

export const PermissionRow = ({
  section,
  permission,
  affordance,
  label,
  tooltip,
  onChange,
  category,
  labelIndent = 0,
  disabledSteps = [],
}) => {
  const key = getStreamKey(permission, section);
  const isVisible = useIsPermissionVisible(key);

  if (!isVisible) {
    return null;
  }

  return (
    <GridContainer>
      <PermissionLabel
        label={label}
        tooltip={tooltip}
        labelIndent={labelIndent}
      />
      <PermissionInputContainer>
        <PermissionRangeInput
          permissionKey={key}
          affordance={affordance}
          onChange={onChange}
          category={category}
          disabledSteps={disabledSteps}
        />
      </PermissionInputContainer>
    </GridContainer>
  );
};
