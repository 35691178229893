import MultiColumnBuilder from 'components/MultiColumnBuilder';
import useBasicBuilder from 'components/MultiColumnBuilder/useBasicBuilder';
import { useTranslation } from 'react-i18next';
import BaseOption from 'components/MultiColumnBuilder/components/BaseOption';
import {
  BuilderWrapper,
  LeftColumnWrapper,
  StyledDropPlaceholder,
  StyledTextEllipsisWithTooltip,
  EditableField,
  TextWrapper,
} from './styles';
import { KizenTypography, fontWeights } from 'app/typography';
import { useCallback, useEffect, useMemo } from 'react';
import Icon from 'components/Kizen/Icon';
import { snakeToCamelCase } from 'services/helpers';
import { StyledDragItem } from 'components/MultiColumnBuilder/components/BaseOption/styles';
import { getDefaultColumnSettings } from 'components/Wizards/CustomObject/steps/CustomLayout/subsections/DefaultColumns/helpers';

const LEFT_COLUMN_ID = 'available-columns';
const RIGHT_COLUMN_ID = 'active-columns';
const selfDisabledDropZones = [LEFT_COLUMN_ID];

const getFalse = () => false;

const TableBuilder = ({
  initialLeftColumnItems = [],
  initialRightColumnItems = [],
  fieldsById,
  onChange,
  model,
  objectDetails,
  lockedFields = [],
  skipSorting = false,
}) => {
  const { t } = useTranslation();

  const defaultFields = useMemo(() => {
    // Model won't be defined if we're editing an existing chart, but that's okay
    // because we only need the default fields for new charts. Existing ones will by
    // definition have right column items already configured.
    if (model) {
      return getDefaultColumnSettings(
        model,
        objectDetails?.fields ?? [],
        t
      ).filter((f) => f.visible);
    }

    return [];
  }, [model, t, objectDetails]);

  const fieldNameToIdsMap = useMemo(() => {
    return (
      objectDetails?.fields?.reduce((acc, curr) => {
        acc[snakeToCamelCase(curr.name)] = curr.id;
        return acc;
      }, {}) ?? {}
    );
  }, [objectDetails]);

  const defaultRightColumnItems = useMemo(() => {
    if (initialRightColumnItems.length) {
      return initialRightColumnItems.map((item) => {
        const field = fieldsById[item.id];
        return {
          ...item,
          displayName: field?.displayName,
        };
      });
    }
    return defaultFields.map((field) => {
      const id = fieldNameToIdsMap[field.id] ?? field.id;
      const { displayName } = fieldsById[field.id] ?? {};
      return {
        label: field.label,
        displayName,
        id,
      };
    });
  }, [defaultFields, initialRightColumnItems, fieldNameToIdsMap, fieldsById]);

  const itemsOrdering = useMemo(
    () => (objectDetails?.fields || []).map(({ id }) => id),
    [objectDetails?.fields]
  );

  const rightItemsMap = useMemo(() => {
    return defaultRightColumnItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [defaultRightColumnItems]);

  const filteredInitialLeftColumnItems = useMemo(() => {
    return initialLeftColumnItems
      .map((item) => {
        const field = fieldsById[item.id];
        return {
          ...item,
          displayName: field?.displayName,
        };
      })
      .filter(({ id }) => {
        return !rightItemsMap[id];
      })
      .sort(({ displayName: displayNameA }, { displayName: displayNameB }) => {
        return skipSorting ? 0 : displayNameA.localeCompare(displayNameB);
      });
  }, [initialLeftColumnItems, fieldsById, rightItemsMap, skipSorting]);

  const props = useBasicBuilder({
    initialLeftColumnItems: filteredInitialLeftColumnItems,
    initialRightColumnItems: defaultRightColumnItems.map((item) => {
      const disable = lockedFields.includes(item.id);
      return {
        ...item,
        restricted: disable,
        disabled: disable,
        isLockedToColumn: disable,
      };
    }),
    leftColumnId: LEFT_COLUMN_ID,
    rightColumnId: RIGHT_COLUMN_ID,
    selfDisabledDropZones,
    itemsOrdering,
    editable: true,
  });

  useEffect(() => {
    onChange?.(props.rightColumnItems);
  }, [onChange, props.rightColumnItems]);

  const additionalActions = useCallback(
    ({ swapItemColumn, index, element, section, editValue, childIndex }) => {
      const field = fieldsById[element?.id];
      return !element?.disabled && swapItemColumn && !element.restricted ? (
        <div>
          <Icon
            className="DeleteAction"
            icon="delete"
            onClick={() => {
              editValue(index, childIndex, 'label', field?.displayName);
              swapItemColumn(index, section);
            }}
          />
        </div>
      ) : null;
    },
    [fieldsById]
  );

  const resetLeftItems = useMemo(() => {
    return props.leftColumnProps.options.map((option) => {
      const field = fieldsById[option.id];
      return {
        ...option,
        label: field?.displayName,
      };
    });
  }, [props.leftColumnProps.options, fieldsById]);

  return (
    <BuilderWrapper>
      <MultiColumnBuilder
        {...props}
        leftColumnProps={{
          ...props.leftColumnProps,
          options: resetLeftItems,
        }}
        fullHeight
        testId="table-builder"
        leftHeaderText={t('Available Columns (Drag to Add)')}
        rightHeaderText={t('Active Columns')}
        weight={fontWeights.regular}
        searchPlaceholder={t('Find Options')}
        onChangeSearchTerm={props.setOptionsSearchTerm}
        renderLeftEmpty={({ dropZone, id }) => {
          if (dropZone?.sectionId === id) {
            return (
              <StyledDropPlaceholder isChildDragging={false}>
                <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
                  <KizenTypography>{t('Drop to Remove')}</KizenTypography>
                </StyledDragItem>
              </StyledDropPlaceholder>
            );
          }
          return (
            <LeftColumnWrapper>
              <KizenTypography>{t('No Options Found')}</KizenTypography>
            </LeftColumnWrapper>
          );
        }}
        renderOption={({ element, section, ...providedProps }) => {
          return (
            // eslint-disable-next-line react/no-unknown-property
            <div {...element} section={section}>
              <BaseOption
                iconVisible={getFalse}
                iconEditable={getFalse}
                colorEditable={getFalse}
                element={element}
                section={section}
                additionalActions={additionalActions}
                {...providedProps}
              >
                {({ element, editValue, childIndex, index, ...rest }) => {
                  const field = fieldsById[element?.id];
                  const isEditable = section === 'active-columns';
                  return isEditable ? (
                    <TextWrapper>
                      <EditableField
                        data-qa={`editable-field-${
                          element?.displayName ?? element?.entityName
                        }`}
                        sizing="dense"
                        value={element?.label}
                        placeholder={t('Enter Display Name')}
                        onChange={(value) => {
                          editValue(index, childIndex, 'label', value);
                        }}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            editValue(
                              index,
                              childIndex,
                              'label',
                              field?.displayName
                            );
                          }
                        }}
                        {...rest}
                      />
                    </TextWrapper>
                  ) : (
                    <StyledTextEllipsisWithTooltip>
                      {field?.displayName ?? element?.label}
                    </StyledTextEllipsisWithTooltip>
                  );
                }}
              </BaseOption>
            </div>
          );
        }}
      />
    </BuilderWrapper>
  );
};

export default TableBuilder;
