import React from 'react';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';

// This scrollbar is intended to be used in situations where it is detacted from its content.
// This situation happens in automations and horizontal scrolling on big table pages too.
// The hover features of this scrollbar don't work naturally when used for regular content boxes.

export const SCROLLBAR_SIZE = 5;
export const SCROLLBAR_SIZE_MAX = 8;

const customScrollbarCss = css`
  &::-webkit-scrollbar {
    width: ${SCROLLBAR_SIZE}px;
    height: ${SCROLLBAR_SIZE}px;
  }
  &:hover::-webkit-scrollbar {
    width: ${SCROLLBAR_SIZE_MAX}px;
    height: ${SCROLLBAR_SIZE_MAX}px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${SCROLLBAR_SIZE}px;
    background-color: ${grayScale.medium};
  }
  &:hover::-webkit-scrollbar-thumb {
    border-radius: ${SCROLLBAR_SIZE_MAX / 2}px;
    background-color: ${grayScale.mediumDark};
  }
  &:hover::-webkit-scrollbar-corner {
    width: ${SCROLLBAR_SIZE_MAX}px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const ScrollbarXWrapper = styled.div`
  overflow-x: auto;
  ${customScrollbarCss}
  // Must be greater than SCROLLBAR_SIZE_MAX to avoid changing width, seems to be a browser quirk.
  height: ${SCROLLBAR_SIZE_MAX + 1}px;
  > div {
    // We do need some height here in order for any scrollbars to show,
    // but we don't really want to take-up any space. It seems smaller
    // than .25px doesn't take-up space, at least on chrome.
    height: 0.1px;
  }
`;

const ScrollbarYWrapper = styled.div`
  overflow-y: auto;
  ${customScrollbarCss}
  width: ${SCROLLBAR_SIZE_MAX + 1}px;
  > div {
    width: 0.1px;
  }
`;

const ScrollBarDetached = React.forwardRef(
  ({ direction, size, scrollClassName, ...props }, ref) => {
    const ScrollbarWrapper =
      direction === 'vertical' ? ScrollbarYWrapper : ScrollbarXWrapper;
    const sizeDirection = direction === 'vertical' ? 'height' : 'width';
    return (
      <ScrollbarWrapper ref={ref} {...props}>
        <div className={scrollClassName} style={{ [sizeDirection]: size }} />
      </ScrollbarWrapper>
    );
  }
);

ScrollBarDetached.displayName = 'ScrollBarDetached';

ScrollBarDetached.propTypes = {
  direction: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scrollClassName: PropTypes.string,
};

ScrollBarDetached.defaultProps = {
  size: 0,
  scrollClassName: '',
};

export default ScrollBarDetached;
