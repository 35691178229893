import { colorsButton, grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import { VIEW_VARIANTS } from './types';
import { StyledIconButton } from './styles';

export const getPathnames = (basePath) => ({
  [VIEW_VARIANTS.TABLE]: `${basePath}/${VIEW_VARIANTS.TABLE}`,
  [VIEW_VARIANTS.CHARTS]: `${basePath}/${VIEW_VARIANTS.CHARTS}`,
  [VIEW_VARIANTS.BOARD]: `${basePath}/${VIEW_VARIANTS.BOARD}`,
});

export const getViewOptions = (value, t, hide = []) =>
  [
    {
      value: VIEW_VARIANTS.CHARTS,
      label: (
        <StyledIconButton
          as="span"
          title={t('Chart')}
          color={
            value === VIEW_VARIANTS.CHARTS ? grayScale.white : colorsButton.blue
          }
        >
          <Icon icon="chart-pie" />
        </StyledIconButton>
      ),
    },
    {
      value: VIEW_VARIANTS.TABLE,
      label: (
        <StyledIconButton
          as="span"
          title={t('Table')}
          color={
            value === VIEW_VARIANTS.TABLE ? grayScale.white : colorsButton.blue
          }
        >
          <Icon icon="table" />
        </StyledIconButton>
      ),
    },
    {
      value: VIEW_VARIANTS.BOARD,
      label: (
        <StyledIconButton
          as="span"
          title={t('Board')}
          color={
            value === VIEW_VARIANTS.BOARD ? grayScale.white : colorsButton.blue
          }
        >
          <Icon icon="table-columns" />
        </StyledIconButton>
      ),
    },
  ].filter((item) => !hide.includes(item.value));
