import { ReactPortal, useContext, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ActionBarContext } from '../ActionBar/context';
import { getActionBarId } from '../ActionBar/ActionBar';
import { getKDSClasses, merge } from '../util';
import { ActionGroupElement } from './types';
import { useElementSize } from '../hooks/useElementSize';
import { Button } from '../Button/Button';
import { Spacer } from '../Spacer/Spacer';
import { ActionItem } from './ActionItem';

export interface TrayProps {
  open: boolean;
  className?: string;
  elements?: ActionGroupElement[];
  onClose: () => void;
  onClearAll?: () => void;
}

const trayGap = 15;

const TrayChild = (props: TrayProps) => {
  const { open, className, elements = [], onClose, onClearAll } = props;
  const innerRef = useRef<HTMLDivElement>(null);
  const { height } = useElementSize(innerRef);

  const heightWithBorder = height + 2;
  const heightWithPadding = heightWithBorder + trayGap;

  return (
    <div
      className={merge(
        getKDSClasses('tray-child'),
        'overflow-hidden transition-height duration-400',
        className
      )}
      style={{ height: open ? `${heightWithPadding}px` : '0px' }}
    >
      <Spacer size={trayGap} mode="horizontal" />
      <div
        className="border border-border-medium rounded-md text-font-primary"
        ref={innerRef}
      >
        <Spacer size={15} />
        <div className="flex">
          <Spacer size={15} mode="vertical" />
          <div className="flex flex-wrap gap-15 w-full">
            {elements.map((element) => {
              return (
                <div
                  className="flex-grow w-[calc(50%-10px)] flex-shrink-0"
                  key={`tray-${element.icon}-${element.label}`}
                >
                  <ActionItem element={element} insideTray />
                </div>
              );
            })}
          </div>
          <Spacer size={15} mode="vertical" />
        </div>
        <Spacer size={15} />
        <div className="border-t border-border-medium flex">
          <Spacer size={15} mode="vertical" />
          <div className="mr-auto">
            {onClearAll ? (
              <Button variant="text" color="warning" onClick={onClearAll}>
                Clear All
              </Button>
            ) : null}
          </div>
          <div className="ml-auto">
            <Button variant="text" color="secondary" onClick={onClose}>
              Close
            </Button>
          </div>
          <Spacer size={15} mode="vertical" />
        </div>
      </div>
    </div>
  );
};

export const Tray: (props: TrayProps) => ReactPortal | null = (
  props: TrayProps
) => {
  const { id } = useContext(ActionBarContext);

  const target = document.getElementById(getActionBarId(id));

  if (!target) {
    return null;
  }

  return createPortal(<TrayChild {...props} />, target);
};
