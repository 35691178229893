import {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  forwardRef,
} from 'react';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';
import BaseReactSelect from './BaseReactSelect';
import * as customizeComponents from './customize';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import { applyRef } from '../props';
import Validation, { useValidation } from '../Validation';
import { useEllipses } from './useEllipses';
import { layers } from 'app/spacing';
import { TabsButton } from './TabsButton';
import useField from 'hooks/useField';
import { debounce } from 'lodash';

export const getMessageProps = (
  noOptionsMessageProp,
  optionsProp,
  loadItems,
  options,
  t
) => {
  const message = () => {
    if (
      optionsProp === DROPDOWN_SHOULD_LOAD ||
      !optionsProp ||
      (loadItems && options.length === 0)
    ) {
      return t('Loading Options');
    }

    if (typeof noOptionsMessageProp === 'function') {
      return noOptionsMessageProp();
    }

    if (typeof noOptionsMessageProp === 'string') {
      return noOptionsMessageProp;
    }

    return t('No Options');
  };

  return {
    noOptionsMessage: message,
    loadingMessage: message,
    isLoading: loadItems,
  };
};

export const getAllOptions = (options, includeGroupPlaceholder) => {
  const isGroupedOptions = options.some((item) => !!item.options);
  let allOptions = options;
  if (isGroupedOptions) {
    allOptions = options.flatMap(({ options }) => options);
    if (includeGroupPlaceholder) {
      // remove the last element { groupPlaceholder: true }
      allOptions.pop();
    }
  }
  return allOptions;
};

const SelectOutline = forwardRef(function SelectOutline(props, ref) {
  const {
    value: valueProp,
    onChange,
    options: optionsProp,
    placeholder,
    placeholderIdentifier,
    isColored,
    isReadOnly,
    isSearchable: isSearchableProp,
    menuPlacement,
    disabled,
    error,
    endAdornment,
    menuOnly,
    menuIsOpen: menuIsOpenProp,
    menuInline: menuInlineProp,
    menuTopButton,
    menuLeftButton,
    menuRightButton,
    components: componentsProp,
    filterOption = createFilter,
    className,
    inModal,
    errorPlacement,
    loadItems,
    noOptionsMessage: noOptionsMessageProp,
    includeGroupPlaceholder = true,
    onMenuOpen,
    onMenuClose,
    onKeyDown,
    portal,
    styles,
    showTabs,
    onTabChange,
    selectedTab: selectedTabProp,
    dataQa,
    hasInfinityScroll,
    maxOptions,
    isLoading,
    ...others
  } = props;

  const { t } = useTranslation();
  const [nextFocusedOption, setNextFocusedOption] = useState(null);

  const [selectedTab, setSelectedTab] = useField(
    selectedTabProp ||
      ((optionsProp !== DROPDOWN_SHOULD_LOAD &&
        Array.isArray(optionsProp) &&
        optionsProp) ||
        [])[0]?.tabKey
  );

  const selectRef = useRef();
  const menuOpened = useRef(Boolean(menuIsOpenProp));
  const validationRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(selectRef, el);
      applyRef(validationRef, el);
      applyRef(ref, el);
    },
    [ref]
  );

  const handleSelectTab = useCallback(
    (tab) => {
      setSelectedTab(tab.tabKey);
      onTabChange?.(tab);
      if (selectRef.current.select.menuListRef) {
        selectRef.current.select.menuListRef.scrollTop = 0;
      }
    },
    [onTabChange, setSelectedTab]
  );

  const [validation, validationProps] = useValidation(validationRef, props);

  useEffect(() => {
    if (others.forceValidation) {
      validation.onSubmission();
    }
  }, [others.forceValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  const [computedMenuPlacement, setComputedMenuPlacement] = useState(
    menuPlacement !== 'auto' ? menuPlacement : null
  );

  const options = useMemo(() => {
    const options =
      (optionsProp !== DROPDOWN_SHOULD_LOAD &&
        Array.isArray(optionsProp) &&
        optionsProp) ||
      [];
    const currentOptions =
      showTabs && selectedTab
        ? options.find((opt) => opt.tabKey === selectedTab)?.options || options
        : options;
    const result = (
      currentOptions === DROPDOWN_SHOULD_LOAD ? [] : currentOptions
    ).map((opt, i) => {
      if (opt.options) {
        // In order to make group headings persistent, we need a placeholder
        // option that is never filtered away. In ./customize we simply hide these items.
        return {
          ...opt,
          options: includeGroupPlaceholder
            ? [...opt.options, { groupPlaceholder: true, value: i }]
            : opt.options,
        };
      }
      return opt;
    });
    return maxOptions ? result.slice(0, maxOptions) : result;
  }, [optionsProp, showTabs, selectedTab, maxOptions, includeGroupPlaceholder]);

  useEffect(() => {
    if (selectRef.current && nextFocusedOption && hasInfinityScroll) {
      const { select } = selectRef.current;
      const allOptions = getAllOptions(options, includeGroupPlaceholder);
      const focusedOption = allOptions.find(
        ({ value }) => value === nextFocusedOption.value
      );
      select.getNextFocusedOption = () => focusedOption;
      select.setState({
        focusedOption,
      });
    }
  }, [hasInfinityScroll, includeGroupPlaceholder, nextFocusedOption, options]);

  const value =
    typeof valueProp === 'string'
      ? options.find((opt) => opt.value === valueProp) || null
      : valueProp;
  let menuIsOpen;
  if (typeof menuIsOpenProp === 'boolean') {
    menuIsOpen = menuIsOpenProp;
  } else if (menuOnly) {
    menuIsOpen = true;
  } else if (isReadOnly) {
    menuIsOpen = false;
  }

  const isSearchable =
    typeof isSearchableProp === 'boolean' ? isSearchableProp : !isReadOnly;
  const menuInline =
    typeof menuInlineProp === 'boolean' ? menuInlineProp : menuOnly;

  const { value: _, placeholder: _1, ...forInputControl } = props;

  const messageProps = useMemo(
    () =>
      getMessageProps(
        noOptionsMessageProp,
        optionsProp,
        loadItems || isLoading,
        options,
        t
      ),
    [noOptionsMessageProp, optionsProp, loadItems, isLoading, options, t]
  );

  const ellipseComponents = useEllipses(props);

  const handleMenuOpen = useCallback(() => {
    onMenuOpen?.();
    menuOpened.current = true;
  }, [onMenuOpen]);

  const handleMenuClose = useCallback(() => {
    onMenuClose?.();
    menuOpened.current = false;
  }, [onMenuClose]);

  const handleOnKeyDown = useCallback(
    (e) => {
      onKeyDown?.(e);
      if (e.key === 'Escape' && menuOpened.current) {
        e.stopPropagation();
        menuOpened.current = false;
      }
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        const isArrowDown = e.key === 'ArrowDown';
        const { select } = selectRef.current;
        if (select.state.focusedOption) {
          const allOptions = getAllOptions(options, includeGroupPlaceholder);
          const focusedOptionIndex = allOptions.findIndex(
            ({ value }) => value === select.state.focusedOption.value
          );
          const nextIndex = focusedOptionIndex + (isArrowDown ? 1 : -1);
          if (isArrowDown ? nextIndex === allOptions.length : nextIndex < 0) {
            // To prevent jumping from the last to the first element
            e.preventDefault();
            return;
          }
          if (hasInfinityScroll) {
            const nextFocusedOption = allOptions[nextIndex];
            setNextFocusedOption(nextFocusedOption);
          }
        }
      }
    },
    [hasInfinityScroll, onKeyDown, options, includeGroupPlaceholder]
  );

  return (
    <MaybeInputControl
      variant="outline"
      {...forInputControl}
      isShowAsterisk={forInputControl.required}
    >
      <div
        className={!hasInputControl(props) && className}
        data-qa-label={value?.label}
        data-qa-value={value?.value ?? value}
        data-qa={dataQa}
      >
        <BaseReactSelect
          ref={mergeRef}
          aria-label={placeholder}
          selectManager={selectRef.current}
          value={value}
          //TODO: KZN-9029 remove debounce when we have a better solution. it should be fixed in KZN-8928 check dev notes
          onChange={debounce(onChange)}
          options={options}
          placeholder={placeholder}
          isDisabled={disabled}
          isSearchable={isSearchable}
          menuIsOpen={menuIsOpen}
          menuPlacement={menuPlacement}
          {...messageProps}
          menuPortalTarget={portal ? document.body : undefined}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: layers.content(9) }),
            ...styles,
          }}
          filterOption={(opt, inputValue) => {
            return (
              !filterOption ||
              filterOption(
                {
                  label: opt.label,
                  // also search in additional value passed to option
                  extraValue: opt.data?.extraValue || '',
                  rawOption: opt,
                },
                inputValue
              )
            );
          }}
          components={{
            ...customizeComponents,
            ...ellipseComponents,
            ...componentsProp,
          }}
          // Below are custom
          variant="outline"
          isColored={isColored}
          isReadOnly={isReadOnly}
          error={error || validation.error}
          endAdornment={endAdornment}
          menuOnly={menuOnly}
          menuInline={menuInline}
          menuTopButton={
            showTabs ? (
              <div>
                <TabsButton
                  onSelectTab={handleSelectTab}
                  options={optionsProp}
                  selectedTab={selectedTab}
                />
                {menuTopButton}
              </div>
            ) : (
              menuTopButton
            )
          }
          menuLeftButton={menuLeftButton}
          menuRightButton={menuRightButton}
          computedMenuPlacement={computedMenuPlacement}
          setComputedMenuPlacement={setComputedMenuPlacement}
          {...others}
          formatGroupLable={true}
          onMenuOpen={handleMenuOpen}
          onMenuClose={handleMenuClose}
          onKeyDown={handleOnKeyDown}
        />
      </div>
      <Validation
        inModal={inModal}
        errorPlacement={errorPlacement}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

SelectOutline.defaultProps = {
  value: null,
  onChange: null,
  options: undefined,
  placeholder: null,
  isColored: null,
  isReadOnly: null,
  isSearchable: null,
  menuPlacement: undefined, // react-select doesn't love null
  errorPlacement: undefined,
  disabled: false,
  error: null,
  endAdornment: null,
  menuOnly: null,
  menuInline: null,
  menuTopButton: null,
  menuLeftButton: null,
  menuRightButton: null,
  components: null,
  loadItems: null,
  filterOption: createFilter(),
  forceValidation: false,
  noOptionsMessage: null,
  portal: false,
};

export default SelectOutline;
