import css from '@emotion/css';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { borderRadii, gutters, layers } from 'app/spacing';
import { CARD_PADDING } from 'pages/Account/pages/Toolbar/constants';

export const OptionPlaceholder = styled.div`
  height: ${({ cardHeight }) => cardHeight}px;
  ${({ cardWidth }) =>
    cardWidth
      ? css`
          width: calc(${cardWidth} - ${cardWidth === '100%' ? 0 : 5}px);
        `
      : ''}
  margin: ${gutters.spacing(CARD_PADDING)}px 0 ${gutters.spacing(
    CARD_PADDING
  )}px 0;

  background-color: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.standard};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const DragItemWrapper = styled.div`
  position: relative;
  ${({ width }) => {
    return width === 100 || !width
      ? css`
          width: 100%;
        `
      : css`
          flex: 0 0 calc(${width}% - 5px);
        `;
  }}
  ${({ dragging, draggingFrom, optioning, editing }) =>
    dragging || draggingFrom || optioning || editing
      ? css`
          z-index: ${layers.content(0, 4)};
        `
      : css`
          z-index: 0;
        `}
  ${({ dragging, draggingDimensions }) =>
    dragging &&
    draggingDimensions &&
    css`
      position: fixed;
      pointer-events: none;
      z-index: ${layers.content(99)};
      & * {
        pointer-events: none;
      }

      ${css(draggingDimensions)}
    `}

  ${({ isChildDragging }) =>
    isChildDragging
      ? css`
          z-index: ${layers.content(99)};
        `
      : ''}

      & .innerItem {
    height: 44px;
  }
`;

export const StyledGroupTitle = styled.div`
  margin-top: ${({ index }) => (index ? 6 : 8)}px;
  margin-left: -1px;
  > div {
    padding-top: 12px;
    padding-left: 1px;
  }
`;
