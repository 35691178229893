export const accountUrlBase = '/account';

export const editToolbarPath = [
  'editToolbarTemplate',
  `${accountUrlBase}/toolbar/templates/edit/:id`,
];

const paths = new Map([
  ['profile', `${accountUrlBase}`],
  editToolbarPath,
  ['toolbar', `${accountUrlBase}/toolbar`],
  ['editConnections', `${accountUrlBase}/api-connections`],
]);

export default paths;
