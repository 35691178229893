import React from 'react';
import { useTranslation } from 'react-i18next';

import { AddSubtractBuilder } from './AddSubtractBuilder';
import { MultDivideBuilder } from './MultDivideBuilder';
import { SIMPLE_BUILDER_TYPES } from '../constants';

export const SimpleBuilder = ({
  type,
  data,
  onUpdateState,
  fieldOptions,
  addFieldOptions,
  clickNotify,
}) => {
  const { t } = useTranslation();

  const tooltipText = t(
    'The simple builder only takes the numeric portions of each field into account when performing calculations (currencies will be ignored). Final decimal values will round if a whole number field is chosen.'
  );
  const label = t('Create Your Field Equation');

  const Builder =
    type === SIMPLE_BUILDER_TYPES.addSubtract
      ? AddSubtractBuilder
      : MultDivideBuilder;
  return (
    <Builder
      type={type}
      data={data}
      onUpdateState={onUpdateState}
      fieldOptions={fieldOptions}
      addFieldOptions={addFieldOptions}
      label={label}
      tooltipText={tooltipText}
      clickNotify={clickNotify}
    />
  );
};
