import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import IconButtonWithBadge from 'components/Kizen/IconButtonWithBadge';

export const TeamIconButton = styled(IconButtonWithBadge)`
  grid-area: ${({ gridArea }) => gridArea};
  padding: 0 !important;
  margin-left: ${gutters.spacing(6)}px;
  margin-right: 0;
  position: relative;
`;
