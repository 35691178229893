import React from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from 'react-number-format';
import PropTypes from 'prop-types';
import { FIELD_TYPES } from 'utility/constants';
import TextInput from '..';

import {
  validateWholeNumber,
  negativesValidateWholeNumber,
} from 'components/Inputs/TextInput/NumericValidation/helpers';
import {
  checkRange,
  withWholeNumberValidation,
} from 'components/Inputs/TextInput/NumericValidation';
import TextAdornment from 'components/Inputs/Adornments/TextAdornment';

const WholeNumberTextInput = React.forwardRef((props, ref) => {
  const {
    allowNegatives = true,
    value,
    disabled,
    minValue,
    maxValue,
    symbol,
  } = props;
  const { t } = useTranslation();
  return (
    <TextInput
      ref={ref}
      // The empty, disabled state needs to allow "—" to appear, so we disabled the NumericInput
      Input={disabled && isNaN(parseInt(value)) ? undefined : NumberInput}
      thousandSeparator
      decimalScale={0}
      isAllowed={(inputObj) => checkRange(inputObj, minValue, maxValue)}
      startAdornment={symbol ? <TextAdornment text={symbol} /> : null}
      {...props}
      validate={{
        ...(allowNegatives
          ? negativesValidateWholeNumber(t)
          : validateWholeNumber(t)),
        ...props.validate,
      }}
    />
  );
});

export default WholeNumberTextInput;

WholeNumberTextInput.displayName = 'WholeNumberTextInput';
WholeNumberTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  allowNegatives: PropTypes.bool,
};
WholeNumberTextInput.defaultProps = {
  disabled: false,
  placeholder: FIELD_TYPES.Integer.placeholder,
  allowNegatives: true,
};

export const toWholeNumber = (str) => {
  if (!str) return null;
  const num = Number.parseInt(str.replace(/,/g, ''), 10);
  if (Number.isNaN(num)) return null;
  return num;
};

export const ValidatedWholeNumberTextInput =
  withWholeNumberValidation(WholeNumberTextInput);
