import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldSettings } from 'components/GenericWizard/wizards/field/';
import FieldSettingsBasic from '../FieldSettingsBasic';

const FormFieldWizard = ({ onChange, data, ...rest }) => {
  const { formData, updateField, resetField, updateValidation, isComplete } =
    useFieldSettings(data);

  // Fires whenever local form state changes, with the onChange handler
  // allowing the parent to see and react to those changes
  useEffect(() => {
    onChange({ data: formData, isComplete });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isComplete]);

  // This is the initial step of your wizard
  // Subsequent steps will be defined within each step
  return (
    <FieldSettingsBasic
      shownOn="FormFieldWizard"
      updateField={updateField}
      resetField={resetField}
      updateValidation={updateValidation}
      formData={formData}
      data={data}
      {...rest}
    />
  );
};

FormFieldWizard.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

FormFieldWizard.defaultProps = {
  data: {},
};

export default FormFieldWizard;
