import React from 'react';
import * as PropTypes from 'prop-types';

import Icon from 'components/Kizen/Icon';
import Card from 'components/Card/Card';
import { colorsButton } from 'app/colors';
import {
  StyledHeader as Header,
  StyledSubHeader as Subheader,
  StyledRow as Row,
  StyledCol as Col,
  StyledMicroText as MicroText,
  Table,
  TableRow,
  TableTitle,
  TableData,
  TableCell,
} from '../../styles';
import AreaBox from '../AreaBox';
import { CloudTypes, cloudsMap, cloudsOptionsMap } from '../../model';
import RadioGroupBox from '../BusinessBox/RadioGroupBox';

const CreateSubscriptionForm = ({
  values,
  onFieldChange,
  toggleBusinessBox,
}) => {
  const renderOption = (entity) => {
    entity.selected = !!values[entity.name];

    return (
      <>
        <AreaBox
          entity={entity}
          onChange={(field, value) => {
            // If Marketing Cloud box, Sales Cloud box or Experience Cloud box gets selected
            // Business Intelligence box should also be automatically selected and
            // Inlcuded label below should be displayed
            // User can unselect Business Intelligence, in that case the label should also be removed
            // In case Marketing Cloud box, Sales Cloud box and Experience Cloud are unselected
            // Intelligence Box should also be automatically unselected
            if (
              field === cloudsMap[CloudTypes.MARKETING].name ||
              field === cloudsMap[CloudTypes.SALES].name ||
              field === cloudsMap[CloudTypes.EXPERIENCE].name
            ) {
              onFieldChange(field, value);
              toggleBusinessBox(field, value);
            } else {
              onFieldChange(field, value);
              if (values.business_intelligence_included && !value)
                toggleBusinessBox(field, value);
            }
          }}
        />
        {entity.name === cloudsMap[CloudTypes.BUSINESS].name &&
          values.business_intelligence_included && (
            <MicroText>Included</MicroText>
          )}
      </>
    );
  };

  return (
    <Card>
      <Header type="header" margin="lg">
        Create Your Subscription
      </Header>
      <Row>
        <Subheader type="subheader" margin="lg">
          Areas of Use
        </Subheader>
        {Object.keys(cloudsMap).map((key, index) => (
          <Col key={key} style={{ marginTop: 5 }}>
            {renderOption(cloudsMap[key], index)}
          </Col>
        ))}
      </Row>
      <Row className="no-margin">
        <Subheader type="subheader" margin="lg">
          Choose Your Business Level
        </Subheader>
        <Table>
          <TableRow>
            {/* First cell is empty to match design where in bottom rows we have title in first cell */}
            <TableCell className="empty-cell" />
            <TableData>
              {/* TODO: Change to radio button group */}
              <RadioGroupBox
                values={values}
                onChange={(value) => onFieldChange('business_level', value)}
              />
            </TableData>
          </TableRow>

          {Object.keys(cloudsMap).map((key) => {
            const { label } = cloudsMap[key];

            const cloudTypeLevels = cloudsOptionsMap[key];

            return (
              <TableRow key={key}>
                <TableCell key={key} className="label-cell">
                  <TableTitle weight="bold">{label}</TableTitle>
                </TableCell>
                <TableData className="table-border">
                  {Object.keys(cloudTypeLevels).map((key) => {
                    const options = cloudTypeLevels[key];
                    return (
                      <TableCell
                        key={key}
                        className="box-cell table-cell cell-border"
                      >
                        {options.map((option, index) => {
                          return (
                            <p key={index}>
                              <Icon
                                className="checkmark"
                                icon="check"
                                color={colorsButton.green.hover}
                                size="1x"
                              />
                              {option}
                            </p>
                          );
                        })}
                      </TableCell>
                    );
                  })}
                </TableData>
              </TableRow>
            );
          })}
        </Table>
      </Row>
    </Card>
  );
};

CreateSubscriptionForm.propTypes = {
  values: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  toggleBusinessBox: PropTypes.func.isRequired,
};

export default CreateSubscriptionForm;
