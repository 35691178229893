import { Container } from 'react-bootstrap';
import { PageContentContainer } from 'components/Layout/PageContentWidth';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters } from 'app/spacing';
import { BigListTitle } from 'components/Layout/BigListLayout';

export const StyledContainer = styled(Container)`
  ${({ mobile }) =>
    mobile
      ? css`
          padding-left: 0px;
          padding-right: 0px;
        `
      : ''}
`;
export const StyledPageContainer = styled(PageContentContainer)`
  & {
    padding-top: ${({ mobile }) =>
      gutters.spacing(mobile ? 2 : 0, mobile ? -1 : 0)}px;
    ${({ mobile }) =>
      mobile
        ? css`
            padding-left: 0;
            padding-right: 0;
          `
        : ''}
  }
`;

export const StyledBigListTitle = styled(BigListTitle)`
  margin-top: 10px;
`;

export const FilterContainer = styled.div`
  z-index: 3;
  position: relative;
`;
