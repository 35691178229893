import { ElementType, MouseEventHandler, useCallback, useState } from 'react';
import { DraggableCore, DraggableEvent } from 'react-draggable';
import { merge } from '../util';
import { usePivotTableContext, usePivotTableDraggingContext } from './context';
import { PivotTableCellProps } from './types';

const coreCellClasses = 'flex items-center h-full';

const ColumnResizeIcon = ({ className, ...rest }: { className?: string }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <svg
      width="3"
      height="24"
      viewBox="0 0 3 24"
      xmlns="http://www.w3.org/2000/svg"
      className={merge('cursor-ew-resize', className)}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...rest}
    >
      <line
        x1="1.5"
        y1="0"
        x2="1.5"
        y2="24"
        className={
          hovered ? 'stroke-button-tertiary-hover' : 'stroke-border-medium'
        }
      />
      <line
        x1="1.5"
        y1="8"
        x2="1.5"
        y2="16"
        className={
          hovered
            ? 'stroke-button-tertiary-hover'
            : 'stroke-button-tertiary-default'
        }
        strokeWidth="3"
      />
    </svg>
  );
};

export const PivotTableCell = <T extends ElementType = 'div'>({
  as,
  children,
  className,
  column,
  hasVerticalScrollbar = false,
  isFirstColumn,
  isLastColumn,
  row,
  virtualItem,
  ...rest
}: PivotTableCellProps<T>) => {
  const As = as || 'div';

  return (
    <As className={merge(coreCellClasses, className)} {...rest}>
      {children}
    </As>
  );
};

export const PivotTableDataCell = <T extends ElementType = 'div'>({
  as,
  children,
  className,
  column,
  hasVerticalScrollbar = false,
  isFirstColumn,
  isLastColumn,
  row,
  onMouseOver,
  backgroundColor,
  virtualItem,
  ...rest
}: PivotTableCellProps<T>) => {
  const As = as || 'div';

  const { hoveredRowIndex, setHoveredRowIndex } = usePivotTableContext();
  const isRowHovered = hoveredRowIndex === row;
  let classes = '';
  if (isRowHovered) {
    classes = merge(coreCellClasses, 'bg-background-default', className);
  } else {
    classes = merge(coreCellClasses, backgroundColor, className);
  }

  const handleMouseOver = useCallback<MouseEventHandler<unknown>>(
    (ev) => {
      setHoveredRowIndex(row);

      if (onMouseOver) {
        onMouseOver(ev);
      }
    },
    [row, setHoveredRowIndex, onMouseOver]
  );

  return (
    <As className={classes} onMouseOver={handleMouseOver} {...rest}>
      {children}
    </As>
  );
};

export const PivotTableResizableCell = <T extends ElementType = 'div'>({
  as,
  children,
  className,
  column,
  hasVerticalScrollbar = false,
  isFirstColumn,
  isLastColumn,
  onMouseOver,
  row,
  virtualItem,
  ...rest
}: PivotTableCellProps<T>) => {
  const As = as || 'div';
  const { hoveredRowIndex, setHoveredRowIndex } = usePivotTableContext();
  const { onColumnDragStart, onColumnDrag, onColumnDragEnd } =
    usePivotTableDraggingContext();

  const handleMouseOver = useCallback<MouseEventHandler<unknown>>(
    (ev) => {
      setHoveredRowIndex(row);

      if (onMouseOver) {
        onMouseOver(ev);
      }
    },
    [row, setHoveredRowIndex, onMouseOver]
  );

  return (
    <As
      className={merge(coreCellClasses, 'flex flex-row relative', className)}
      onMouseOver={handleMouseOver}
      {...rest}
    >
      {children}
      {hoveredRowIndex === row && !isLastColumn && (
        <DraggableCore
          onDrag={(ev: DraggableEvent) => {
            if (isFirstColumn) {
              onColumnDrag(column, (ev as MouseEvent).movementX);
            } else if (virtualItem) {
              const newSize = virtualItem.size + (ev as MouseEvent).movementX;
              onColumnDrag(column, newSize, virtualItem.index);
            }
          }}
          onStart={(ev: DraggableEvent) => {
            onColumnDragStart(column, (ev as MouseEvent).clientX, virtualItem!);
          }}
          onStop={(ev) => {
            if (isFirstColumn) {
              onColumnDragEnd(column);
            } else if (virtualItem) {
              const nextSize = virtualItem.size + (ev as MouseEvent).movementX;
              onColumnDragEnd(column, nextSize);
            }
          }}
        >
          <ColumnResizeIcon className="absolute z-20 right-0" />
        </DraggableCore>
      )}
    </As>
  );
};

PivotTableCell.displayName = 'PivotTableCell';
PivotTableDataCell.displayName = 'PivotTableDataCell';
PivotTableResizableCell.displayName = 'PivotTableResizableCell';
