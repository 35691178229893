import * as PropTypes from 'prop-types';
import format from 'date-fns/format';
import styled from '@emotion/styled';

import KizenTypography from 'app/kizentypo';
import {
  ListCardCell,
  ListCardItem,
  ListCardRow,
} from 'components/Layout/ListCard';
import { DATE_DISPLAY_FORMAT } from 'components/Inputs/DateInput';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { gutters } from 'app/spacing';

const NO_VALUE = '—';

const StyledListCard = styled(ListCardItem)`
  height: 40px;
  padding-left: ${gutters.spacing(4)}px;
`;

const StandardListItem = ({ onChecked, checked, record, model, ...others }) => {
  return (
    <StyledListCard {...others}>
      <ListCardRow>
        <ListCardCell>
          <TextEllipsisWithTooltip
            type="link"
            to={`${model.id}/${record.id}/details`}
          >
            {record.name}
          </TextEllipsisWithTooltip>
        </ListCardCell>
        <ListCardCell>
          <KizenTypography>
            {record.created
              ? format(record.created, DATE_DISPLAY_FORMAT)
              : NO_VALUE}
          </KizenTypography>
        </ListCardCell>
      </ListCardRow>
    </StyledListCard>
  );
};

StandardListItem.propTypes = {
  onChecked: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    created: PropTypes.object,
  }).isRequired,
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default StandardListItem;
