export const SA_TYPE_ROUND_ROBIN_ALL = 'round_robin_all';
export const SA_TYPE_ROUND_ROBIN_ROLE = 'round_robin_role';
export const SA_TYPE_ROUND_ROBIN_TEAM_MEMBERS = 'round_robin_team_members';
export const SA_TYPE_TEAM_MEMBER = 'team_member';
export const SA_TYPE_TEAM_MEMBER_FIELD = 'team_selector_field';
export const SA_TYPE_LAST_ACTIVE_TEAM_MEMBER = 'last_active';
export const SA_TYPE_LAST_ACTIVE_TEAM_MEMBER_SPEC_ROLE = 'last_active_role';
export const SA_TYPE_SPEC_ROLE = 'specific_role';
export const SA_TYPE_OWNER = 'owner';

export const assignToTypeOptionsTranslated = (
  t,
  contextIsCustomObject = true
) =>
  [
    {
      label: t('Round Robin (Team Member of Any Role)'),
      value: SA_TYPE_ROUND_ROBIN_ALL,
    },
    {
      label: t('Round Robin (Team Member of Specific Role)'),
      value: SA_TYPE_ROUND_ROBIN_ROLE,
    },
    {
      label: t('Round Robin (Select Team Members)'),
      value: SA_TYPE_ROUND_ROBIN_TEAM_MEMBERS,
    },
    contextIsCustomObject && {
      label: t('Owner'),
      value: SA_TYPE_OWNER,
    },
    {
      label: t('Specific Team Member'),
      value: SA_TYPE_TEAM_MEMBER,
    },
    {
      label: t('Custom Team Selector Field'),
      value: SA_TYPE_TEAM_MEMBER_FIELD,
    },
    {
      label: t('Last Active Team Member (Any Role)'),
      value: SA_TYPE_LAST_ACTIVE_TEAM_MEMBER,
    },
    {
      label: t('Last Active Team Member (Specific Role)'),
      value: SA_TYPE_LAST_ACTIVE_TEAM_MEMBER_SPEC_ROLE,
    },
    {
      label: t('Specific Role'),
      value: SA_TYPE_SPEC_ROLE,
    },
  ].filter(Boolean);

export const dictionaryAssignToTypeOptionsTranslated = (t) =>
  assignToTypeOptionsTranslated(t).reduce(
    (acc, { label, value }) => ({
      ...acc,
      [value]: { value, label },
    }),
    {}
  );
