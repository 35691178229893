import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useMeasure } from 'react-use';
import useSyncScroll from 'react-use-sync-scroll';
import { useEditor, useNode } from '@craftjs/core';
import styled from '@emotion/styled';
import { useLoadNonWebFont } from '@kizen/page-builder/hooks/useLoadNonWebFont';
import {
  RootWrapper,
  StaticBlock,
  DEFAULT_BACKGROUND,
} from '@kizen/page-builder/nodes/Root';
import { useViewerContext } from '@kizen/page-builder/viewer';
import { hideScrollbarCss } from 'app/spacing';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { getCreating } from 'store/pageBuilder/selectors';
import { Section } from './Section';
import EmptyNode from './Empty';
import { useBuilderContext, useRootWidth } from '../BuilderContext';

const StyledStaticBlock = styled(StaticBlock)`
  background-color: ${({ backgroundColor = DEFAULT_BACKGROUND }) =>
    `${backgroundColor}`};
`;

const useClearHover = () => {
  const creating = useSelector(getCreating);
  const { actions, indicator } = useEditor((state) => ({
    indicator: state.indicator,
  }));

  return () => {
    actions.clearEvents();
    if (creating) {
      // Preserve indicator during creation, i.e. behind creation modal.
      actions.setIndicator(indicator);
    }
  };
};

const EmptyRootNode = () => (
  <EmptyNode dropType="section">
    Drag and drop{' '}
    <strong>
      <u>Section</u>
    </strong>{' '}
    here.
  </EmptyNode>
);

const ScrollbarDetachedContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ height }) => height && `height: ${height}px;`}
`;

const StyledScrollFadeContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  overflow-y: auto;
  ${({ height }) => height && `height: ${height}px;`}
`;

const StyledScrollBarDetached = styled(ScrollBarDetached)`
  transform: translateX(-9px);
`;

const getAdditionalHeight = (node) => {
  if (!node || node.id === 'ROOT') {
    return 0;
  }

  if (node.data.name === 'Section') {
    return 15;
  }

  if (node.data.name === 'Row') {
    return 30;
  }

  return 45;
};

const StaticBlockMobileRoot = ({
  children,
  columns,
  height,
  hasShadow,
  ...rest
}) => {
  const { setRootNode, rootWidth, fullWidth, fullHeight } = useBuilderContext();
  const { enabled, query } = useEditor((state) => ({
    enabled: state.options.enabled,
    query: state.query,
  }));
  const {
    node,
    connectors: { connect },
  } = useNode((node) => ({ node }));
  const clearHover = useClearHover();
  const nodeCount = query ? Object.keys(query.getSerializedNodes()).length : 0;
  const { backgroundColor } = node?.data?.props ?? {};
  const scrollRef = useRef();
  const scrolled = useEndsOfScroll(scrollRef, [height, rootWidth, nodeCount]);

  const refCallback = useCallback(
    (node) => {
      scrollRef.current = node;
      connect(node);
    },
    [connect]
  );

  useEffect(() => {
    setRootNode(node);
  }, [node, setRootNode]);

  return (
    <StyledStaticBlock
      ref={refCallback}
      onMouseLeave={clearHover}
      backgroundColor={backgroundColor}
      canResize={true}
      columns={columns}
      fullHeight={fullHeight}
      fullWidth={fullWidth}
      hasShadow={hasShadow}
      height={height}
    >
      <RootWrapper hasChildren={!!children} {...rest}>
        <ScrollFadeContainer scrolled={scrolled} top bottom>
          {children || (enabled && <EmptyRootNode />)}
        </ScrollFadeContainer>
      </RootWrapper>
    </StyledStaticBlock>
  );
};

const StaticBlockDesktopRoot = ({
  children,
  columns,
  height,
  hasShadow,
  ...rest
}) => {
  const {
    setRootNode,
    setAdditionalRootHeight,
    setRootWidth,
    measureRootWidth,
    rootWidth,
    fullWidth,
    fullHeight,
    showCanvasGradient,
    resizingHeight,
    activeNode,
  } = useBuilderContext();
  const [measureRef, { width }] = useMeasure();
  const { enabled, query, hoveredNode } = useEditor((state) => {
    const hoveredId = [...state.events.hovered.values()][0];
    return {
      enabled: state.options.enabled,
      query: state.query,
      hoveredNode: state.nodes[hoveredId],
    };
  });
  const additionalSelectedNodeHeight = getAdditionalHeight(activeNode);
  const additionalHoveredNodeHeight = getAdditionalHeight(hoveredNode);
  const {
    node,
    connectors: { connect },
  } = useNode((node) => ({ node }));
  const clearHover = useClearHover();
  const nodeCount = query ? Object.keys(query.getSerializedNodes()).length : 0;
  const { backgroundColor } = node?.data?.props ?? {};
  const applyHeightAdjustment =
    height < 200 && !(resizingHeight && !activeNode && hoveredNode);
  const additionalHeight = Math.max(
    additionalHoveredNodeHeight,
    additionalSelectedNodeHeight
  );
  const totalHeight = applyHeightAdjustment
    ? height + additionalHeight
    : height;

  useEffect(() => {
    if (applyHeightAdjustment) {
      setAdditionalRootHeight(additionalHeight);
    } else {
      setAdditionalRootHeight(0);
    }
  }, [applyHeightAdjustment, additionalHeight, setAdditionalRootHeight]);

  const scrollbarRef = useRef();
  const [refFn, scrollRef] = useSyncSizes(
    scrollbarRef,
    '.ContentForSyncHeight',
    'height'
  );
  useSyncScroll(useRef([scrollRef, scrollbarRef]), { vertical: true });
  const scrolled = useEndsOfScroll(scrollRef, [height, rootWidth, nodeCount]);

  useEffect(() => {
    setRootNode(node);
  }, [node, setRootNode]);

  useEffect(() => {
    if (measureRootWidth) {
      setRootWidth(width);
    }
  }, [width, measureRootWidth, setRootWidth]);

  return (
    <ScrollbarDetachedContainer ref={connect} height={totalHeight}>
      <StyledStaticBlock
        onMouseLeave={clearHover}
        backgroundColor={backgroundColor}
        canResize={true}
        columns={columns}
        fullHeight={fullHeight}
        fullWidth={fullWidth}
        hasShadow={hasShadow}
        height={totalHeight}
      >
        <StyledScrollFadeContainer
          ref={refFn}
          scrolled={scrolled}
          top={showCanvasGradient}
          bottom={showCanvasGradient}
          height={totalHeight}
        >
          <RootWrapper
            ref={measureRef}
            className="ContentForSyncHeight"
            hasChildren={!!children}
            {...rest}
          >
            {children || (enabled && <EmptyRootNode />)}
          </RootWrapper>
        </StyledScrollFadeContainer>
      </StyledStaticBlock>
      <StyledScrollBarDetached
        ref={scrollbarRef}
        direction="vertical"
        scrollClassName="ContentForSyncHeight"
      />
    </ScrollbarDetachedContainer>
  );
};

/**
 * Specific to the homepage builder. The width of the root/canvas is controled outside of
 * the page builder for homepages, so this version does not need to measure the width and
 * set that value in the context.
 */
export const StaticBlockRoot = (props) => {
  const { view } = useBuilderContext();

  return view === 'desktop' ? (
    <StaticBlockDesktopRoot {...props} />
  ) : (
    <StaticBlockMobileRoot {...props} />
  );
};

export default function Root({ children, ...others }) {
  const [measureRef, { width }] = useMeasure();
  const { setRootNode } = useBuilderContext();
  const [, setRootWidth] = useRootWidth();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  const {
    node,
    connectors: { connect },
  } = useNode((node) => ({ node }));
  const clearHover = useClearHover();
  const { backgroundColor } = node?.data?.props ?? {};

  const mergeRef = useCallback(
    (el) => {
      connect(el);
      measureRef(el);
    },
    [connect, measureRef]
  );

  useEffect(() => {
    setRootNode(node);
  }, [node, setRootNode]);

  useEffect(() => {
    setRootWidth(width);
  }, [width, setRootWidth]);

  return (
    <RootWrapper
      ref={mergeRef}
      onMouseLeave={clearHover}
      hasChildren={!!children}
      backgroundColor={backgroundColor}
      {...others}
    >
      {children || (enabled && <EmptyRootNode />)}
    </RootWrapper>
  );
}

export const RootView = ({ children, ...others }) => {
  const [measureRef, { width }] = useMeasure();
  const { setRootWidth, removeRootBackground, rootNode } = useViewerContext();

  useLoadNonWebFont(others.fontFamily);

  useEffect(() => {
    setRootWidth(width);
  }, [width, setRootWidth]);

  return (
    <RootWrapper
      hasChildren
      ref={measureRef}
      backgroundColor={rootNode?.data?.props?.backgroundColor}
      removeRootBackground={removeRootBackground}
      {...others}
    >
      {children}
    </RootWrapper>
  );
};

const rules = {
  canMoveIn: (incomingNodes) => {
    return incomingNodes.every((node) => node.data.type === Section);
  },
};

Root.craft = { rules };
StaticBlockRoot.craft = { rules };
