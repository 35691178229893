import React from 'react';
import PropTypes from 'prop-types';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';

const Wrapper = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid ${({ color }) => `${color}`};
`;

const CenteredIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;

  && {
    transform: translate(-50%, -50%) !important;
  }
`;

export default function TypeIcon({ className, color, icon, size }) {
  return (
    <Wrapper color={color} className={className}>
      <IconSizing size={size}>
        <CenteredIcon color={color} icon={icon} />
      </IconSizing>
    </Wrapper>
  );
}

TypeIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string, // TypeIcon should not have a hover color, just a single color value
  size: PropTypes.string,
  className: PropTypes.string,
};

TypeIcon.defaultProps = {
  icon: 'cycle-hamburger',
  color: grayScale.dark,
  size: 'intrinsic',
};
