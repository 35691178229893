import styled from '@emotion/styled';
import { useToggle } from 'react-use';
import { useCallback } from 'react';
import { SortableHeadCell as KizenSortableHeadCell } from 'components/Kizen/Table';
import { DraggableCore } from 'react-draggable';
import Icon from 'components/Kizen/Icon';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import {
  getSizing,
  ResizerLayout,
} from 'pages/Common/components/BigTableCells';

const PADDING = 30; // Right cell padding in pixels

export const SortableHeadCell = styled(
  ({
    column,
    minWidth,
    children,
    className,
    onResizeColumnWidth,
    onResizeStop,
    getColumnWidth,
    narrowistWidth = 75,
    resizable = true,
    ...others
  }) => {
    const width = getColumnWidth(column.id);
    const [resizing, toggleResizing] = useToggle(false);
    const handleResize = useCallback(
      (ev, { x }) => {
        const nextWidth = Math.max(narrowistWidth, x - PADDING / 2);
        onResizeColumnWidth({
          id: column.id,
          label: column.label,
          width: `${nextWidth}px`,
          visible: true,
        });
      },
      [column.id, column.label, onResizeColumnWidth, narrowistWidth]
    );

    const onResizeColumnStop = useCallback(() => {
      onResizeStop();
    }, [onResizeStop]);

    return (
      <KizenSortableHeadCell
        className={`${className} ${resizing && 'Resizing'}`}
        column={column}
        padding={`${PADDING}px`}
        {...getSizing(width, minWidth)}
        {...others}
      >
        {children}
        {resizable ? (
          <ResizerLayout className="ResizerLayout" padding={PADDING}>
            <DraggableCore
              onDrag={handleResize}
              onStart={toggleResizing}
              onStop={() => {
                toggleResizing();
                onResizeColumnStop();
              }}
            >
              <Icon className="Resizer" icon="column-resize" size="intrinsic" />
            </DraggableCore>
          </ResizerLayout>
        ) : null}
      </KizenSortableHeadCell>
    );
  }
)`
  &.Resizing {
    cursor: ew-resize;
    svg line {
      stroke: ${grayScale.dark};
    }
  }
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
    margin-top: 1px;
    button {
      margin-left: 10px;
    }
  }
  button {
    margin-left: ${gutters.spacing(1)}px;
  }
`;
