import { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BigTableLayout, {
  useBigTableLayoutNew,
} from 'components/Layout/BigTableLayout';
import BigTable from 'components/Tables/Big';
import { TRow } from 'components/Kizen/Table';
import TablePagination from 'components/Tables/Big/TablePagination';
import PageToolbar, {
  PageToolbarSection,
  PageToolbarTitle,
  PageBreadcrumb,
} from 'components/Layout/PageToolbar';
import { getAutomationsColumns } from './columns';
import Breadcrumbs from 'components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import { useNavBarState } from 'app/navBarController';
import {
  selectLibraryAutomationsFetching,
  selectLibraryAutomationsIsFiltering,
} from 'store/libraryPage/library.redux';
import { SUB_NAVIGATION_HEIGHT } from 'pages/Library/helpers';

const DesktopLayout = ({
  search,
  onChangeSearch,
  automations,
  automationsCount,
  parentFolder,
  currentFolder,
  pageNumber,
  pageSize,
  handleChangePageNumber,
  handleChangePageSize,
  sort,
  handleChangeSort,
  handleSelectAction,
  handleUpdateAutomation,
  directories,
  onChangeDirectory,
  ...others
}) => {
  const { t } = useTranslation();
  const isAutomationsFetching = useSelector(selectLibraryAutomationsFetching);
  const isFiltering = useSelector(selectLibraryAutomationsIsFiltering);
  const { height } = useNavBarState();
  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayoutNew(height, height + SUB_NAVIGATION_HEIGHT);
  const { scrolledToTable } = bigTablePageProps;

  const messagesCount = automations.filter(
    ({ type }) => type && type !== 'folder'
  ).length;

  const templatesHeadData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => handleChangeSort({ column, direction }),
      },
    }),
    [sort, handleChangeSort]
  );
  const broadcastsColumns = useMemo(
    () =>
      getAutomationsColumns({
        onSelectAction: handleSelectAction,
        onUpdateAutomation: handleUpdateAutomation,
        handleChangeDirectory: onChangeDirectory,
        parentFolder,
        messagesCount,
        t,
      }),
    [
      handleSelectAction,
      handleUpdateAutomation,
      messagesCount,
      onChangeDirectory,
      parentFolder,
      t,
    ]
  );
  return (
    <BigTableLayout
      breadcrumb={
        <PageBreadcrumb>
          <Breadcrumbs
            directories={directories}
            handleChangeDirectory={onChangeDirectory}
          />
        </PageBreadcrumb>
      }
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={
                currentFolder?.id ? t('Find Messages') : t('Find Automations')
              }
              value={search}
              onChange={onChangeSearch}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          {currentFolder?.id ? (
            <PageToolbarTitle
              count={messagesCount}
              single={t('Message')}
              plural={t('Messages')}
            />
          ) : (
            <PageToolbarTitle
              count={automationsCount}
              single={t('Automation')}
              plural={t('Automations')}
            />
          )}
          <PageToolbarSection />
        </PageToolbar>
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={automationsCount}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      }
      {...bigTablePageProps}
      {...others}
    >
      <BigTable
        scrolledToTable={scrolledToTable}
        columns={automations}
        head={
          <TRow head columns={broadcastsColumns} data={templatesHeadData} />
        }
        staleData={isAutomationsFetching || isFiltering}
      >
        {automations.map((item) => (
          <TRow key={item.id} columns={broadcastsColumns} data={item} />
        ))}
      </BigTable>
    </BigTableLayout>
  );
};
DesktopLayout.propTypes = {
  automations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChangeSearch: PropTypes.func.isRequired,
  automationsCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleChangePageNumber: PropTypes.func.isRequired,
  handleChangePageSize: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleSelectAction: PropTypes.func.isRequired,
  onChangeDirectory: PropTypes.func.isRequired,
  directories: PropTypes.array.isRequired,
};
export default DesktopLayout;
