import styled from '@emotion/styled';
import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';

import { colorsPrimary, colorsSecondary } from 'app/colors';

import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import ColorLabel from 'components/Fields/ColorLabel';
import { capitalize } from 'lodash/string';
import ActionCell from 'components/Tables/ActionCell';
import { fontSizes } from 'app/typography';

import DateInputInline, {
  DateInputInlineSelect,
} from 'components/Inputs/inline/DateInput';

export const WrapperStatus = styled(TextEllipsisWithTooltip)`
  font-size: ${fontSizes.text};
  margin-bottom: 0;
`;

const paddingSize = {
  basic: '70px',
  small: '20px',
};
const columnSize = {
  spacer: '20px',
  name: '350px',
  externalId: '150px',
  adProvider: '130px',
  dateConnected: '140px',
  status: '100px',
  action: '50px',
};

export const statusColors = {
  active: colorsPrimary.green.dark,
  inactive: colorsPrimary.orange.dark,
  error: colorsSecondary.red.dark,
};

export const adManagerStatus = (t) => ({
  active: t('Active'),
  inactive: t('Inactive'),
  error: t('Error'),
});

export const actions = {
  deactivate: 'deactivate',
  delete: 'delete',
  resync: 'resync',
};

const TableActionCell = ({ t, ...rest }) => {
  const options = [
    rest?.data?.status === 'active'
      ? { value: actions.deactivate, label: t('Deactivate') }
      : { value: actions.resync, label: t('Re-Sync') },
    { value: actions.delete, label: t('Delete') },
  ];
  return (
    <ActionCell
      title={t('Edit Connection')}
      menuType="absolute"
      options={options}
      {...rest}
    />
  );
};

export const getAccountColumns = ({ t, handleSelectAction }) => [
  {
    id: 'spacer-1',
    headCell: <SizedCell width={columnSize.spacer} />,
    cell: <SizedCell width={columnSize.spacer} />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        label={t('Name')}
        width={columnSize.name}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.name} padding={paddingSize.basic} />,
    format: function NameCell(value) {
      return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
    },
  },
  {
    id: 'externalId',
    headCell: (
      <SortableHeadCell
        label={t('Account ID')}
        numeric
        width={columnSize.externalId}
        padding={paddingSize.basic}
      />
    ),
    cell: (
      <SizedCell width={columnSize.externalId} padding={paddingSize.basic} />
    ),
    format: function NameCell(value) {
      return <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>;
    },
  },
  {
    id: 'adProvider',
    headCell: (
      <SortableHeadCell
        label={t('Type')}
        width={columnSize.adProvider}
        padding={paddingSize.basic}
      />
    ),
    cell: (
      <SizedCell width={columnSize.externalId} padding={paddingSize.basic} />
    ),
    format: function NameCell(value) {
      return (
        <TextEllipsisWithTooltip>
          {value === 'google' ? t('Google Ads') : t('Facebook Ads')}
        </TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'created',
    headCell: (
      <SortableHeadCell
        label={t('Date Connected')}
        width={columnSize.dateConnected}
        padding={paddingSize.basic}
        numeric
      />
    ),
    cell: (
      <SizedCell width={columnSize.dateConnected} padding={paddingSize.basic} />
    ),
    format: function NameCell(value) {
      return (
        <DateInputInline readOnly>
          <DateInputInlineSelect value={value} />
        </DateInputInline>
      );
    },
  },
  {
    id: 'status',
    headCell: (
      <SortableHeadCell
        label={t('Status')}
        width={columnSize.status}
        padding={paddingSize.basic}
      />
    ),
    cell: <SizedCell width={columnSize.status} padding={paddingSize.basic} />,
    format: function NameCell(value) {
      const color = statusColors[value];
      return (
        <WrapperStatus>
          <ColorLabel color={color}>
            {capitalize(adManagerStatus(t)[value])}
          </ColorLabel>
        </WrapperStatus>
      );
    },
  },
  {
    id: 'actions',
    headCell: (
      <SubActionHeadCell
        width={columnSize.action}
        padding={paddingSize.small}
      />
    ),
    cell: (
      <TableActionCell
        width={columnSize.action}
        padding={paddingSize.small}
        onSelectAction={handleSelectAction}
        t={t}
      />
    ),
  },
];
