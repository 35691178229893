import { useState } from 'react';
import {
  StyledAddButton,
  StyledAddButtonWrapper,
  StyledFieldInputWrapper,
} from './styles';
import { getAddButtonText, getAddValuesPlaceholder } from './helpers';
import { useTranslation } from 'react-i18next';
import FieldInput from 'components/Fields/FieldInput';

export const AddValuesButton = ({
  field,
  entity,
  addValues,
  onAddValues,
  isMobile,
}) => {
  const { t } = useTranslation();
  const [openAddValue, setOpenAddValue] = useState(false);
  return (
    <StyledAddButtonWrapper>
      <StyledAddButton
        variant="text"
        onClick={() => {
          setOpenAddValue(true);
        }}
      >
        {getAddButtonText(field, isMobile, t)}
      </StyledAddButton>
      {openAddValue ? (
        <StyledFieldInputWrapper>
          <FieldInput
            sizing={isMobile ? 'mobile' : 'auto'}
            field={field}
            object={entity}
            label={false}
            inModal
            menuIsOpen
            alwaysOpen
            autoFocus
            onChange={(v) => onAddValues(v)}
            value={addValues}
            handleClickApply={() => setOpenAddValue(false)}
            hideAddButton
            placeholder={getAddValuesPlaceholder(field, t)}
            excludeExistingValues
            shouldRefetchOnMount
          />
        </StyledFieldInputWrapper>
      ) : null}
    </StyledAddButtonWrapper>
  );
};
