import React from 'react';
import * as PropTypes from 'prop-types';

import { grayScale } from 'app/colors';
import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import {
  StyledToastContainer,
  StyledToast,
  StyledToastBody,
  StyledCloseButton,
} from './styles';

const Toast = ({
  show,
  delay,
  variant,
  autohide,
  onClose,
  children,
  className,
  ...props
}) => {
  return (
    <StyledToastContainer
      className={className}
      aria-live="polite"
      aria-atomic="true"
    >
      <StyledToast
        show={show}
        delay={delay}
        variant={variant}
        autohide={autohide}
        onClose={onClose}
        {...props}
      >
        <StyledToastBody>
          {children}
          <StyledCloseButton color={grayScale.white} onClick={onClose} />
        </StyledToastBody>
      </StyledToast>
    </StyledToastContainer>
  );
};

Toast.propTypes = {
  // Show or hide toast
  show: PropTypes.bool.isRequired,
  // Value in ms until toast is hidden
  delay: PropTypes.number,
  // Toast Appearance
  variant: PropTypes.oneOf(['success', 'alert', 'failure']),
  // Should toast autohide after delay
  autohide: PropTypes.bool,
  // Close Function
  onClose: PropTypes.func.isRequired,
  // Add negative y offset to position toast below toolbar
  yOffset: PropTypes.number,
  className: PropTypes.string,
};

Toast.defaultProps = {
  delay: 1500,
  variant: 'success',
  autohide: true,
  className: '',
  yOffset: null,
};

export default Toast;

export const ActionToast = styled(Toast)`
  ${(props) => !props.show && `display: none;`}
  z-index: ${layers.toast};
  & .toast {
    max-width: 98%;
    width: 800px;
  }
  & .toast-body {
    justify-content: space-between;
  }
`;

export const TabularActionToast = styled(ActionToast)`
  .toast-body {
    align-items: center;
    padding: 3px 0 3px 15px;
  }
`;
