import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import BaseModal, { ModalBody, ModalHeader } from 'components/Modals';
import { breakpoints, gutters } from 'app/spacing';
import { fontWeights } from 'app/typography';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from 'components/Modals';
import Button from 'components/Button';

const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: auto; // Removes unnecessary scrollbar, which allows things to size/align per designs
  overflow-x: hidden;

  > button,
  > span {
    padding: 0;
    margin: 0;
    margin-bottom: ${gutters.standard};
    &:empty {
      margin-bottom: 0;
    }

    font-weight: ${fontWeights.black};
    letter-spacing: 0.5px;
    border-width: 1px;
    line-height: 35px;
    height: 35px;

    width: 141px;
    @media (min-width: ${breakpoints.lg}px) {
      width: 180px;
    }
  }
`;
const StyledModalFooter = styled(ModalFooter)`
  padding: 0 ${gutters.spacing(3, -1)}px;
  .button-box {
    width: 100%;
    margin-bottom: ${gutters.spacing(3, -1)}px;
    display: flex;
    justify-content: flex-end;

    > button.btn {
      margin: 0;
    }
  }
`;

export default function ActionModal({
  title,
  onHide,
  onSubmit,
  disableButton,
  children,
  rightButtonTitle = 'Confirm',
  ...props
}) {
  const { t } = useTranslation();
  return (
    <BaseModal size="medium" onHide={onHide} {...props}>
      <ModalHeader onClickClose={onHide}>{title}</ModalHeader>
      <StyledModalBody>{children}</StyledModalBody>
      <StyledModalFooter showBorder={false}>
        <div className="button-box">
          <>
            <Button variant="text" color="blue" onClick={onHide}>
              {t('Close')}
            </Button>
            {/*Translation not allowed to parse variable*/}
            <Button onClick={onSubmit} disabled={disableButton}>
              {rightButtonTitle || t('Confirm')}
            </Button>
          </>
        </div>
      </StyledModalFooter>
    </BaseModal>
  );
}
ActionModal.propTypes = {
  title: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  rightButtonTitle: PropTypes.string,
};

ActionModal.defaultProps = {
  title: '',
  disableButton: false,
};
