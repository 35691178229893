import styled from '@emotion/styled';
import css from '@emotion/css';

import Select from '__components/Inputs/Select';
import InputControl from '__components/Inputs/InputControl';

import DragItem from 'components/DragAndDropLayout/DragItem';
import { layers } from 'app/spacing';
import { grayScale } from 'app/colors';
import { dropdownColors } from 'app/colors';

export const StyledDndColumns = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-bottom: 12px;
`;

export const StyledDragItem = styled(DragItem)`
  position: relative;
  z-index: ${layers.content(0, 4)};
`;

export const BuilderWrapper = styled.div`
  height: 420px;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div<{
  objectIsSelected: boolean;
  wrapperHeight: number;
  disabled: boolean;
}>`
  min-height: ${({ objectIsSelected, wrapperHeight }) =>
    objectIsSelected ? wrapperHeight : 0}px;
  display: flex;
  flex-direction: column;
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
          transition: opacity 250ms linear 500ms;
        `
      : ''}
`;

export const StyledInputControl = styled(InputControl)`
  z-index: ${layers.modals(3, 1)};
`;

export const StyledSelect = styled(Select)`
  li:hover {
    background-color: ${dropdownColors.hover};
  }
  p {
    color: ${grayScale.dark};
  }
`;
