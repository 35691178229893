import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters, breakpoints, layers } from 'app/spacing';
import { colorsButton, tableHover } from 'app/colors';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { contentWidthCss } from 'components/Layout/PageContentWidth';
import Loader from 'components/Kizen/Loader';
import Icon from 'components/Kizen/Icon';
import ValidatedField from 'components/Kizen/ValidatedField';
import BaseCard, { DateWrapper } from 'components/Board/BaseCard';
import { DateInputInlineSelect } from 'components/Inputs/inline/DateInput';
import Select from 'components/Inputs/Select';
import SelectInline from 'components/Inputs/inline/Select';

export const StyledLoader = styled(Loader)`
  margin-top: -10%;
`;

export const contentWidthStyles = css`
  width: 100%;
  padding: 0 ${gutters.standard};
  @media (min-width: ${breakpoints.md + 1}px) {
    ${contentWidthCss}
  }
`;

export const CheckboxStyles = styled(StylePassthrough)`
  label,
  label& {
    margin: 0;
    padding: 0;
    // Change hover color to dark grey
    &:hover input[type='checkbox']:not(:checked) + .CheckboxInputFacade {
      border-color: ${colorsButton.iconGray.hover};
    }
  }
  .CheckboxText {
    display: none;
  }
  .CheckboxInputFacade {
    // In chrome the input facade's position: relative creates a new stacking
    // context, causing this element to appear on top of the page's sticky header.
    z-index: ${layers.content(0)};
    margin-left: 0;
  }
`;

export const TitleWrapper = styled.div`
  & > div {
    width: calc(100% - 30px);
  }

  && > i {
    cursor: grab;
  }
`;

export const StyledValidatedField = styled(ValidatedField)`
  margin-top: -${gutters.spacing(1, -3)}px;

  input {
    margin-top: -${gutters.spacing(1, -4)}px;
  }
`;

export const StyledIconMenu = styled(Icon)`
  && {
    width: 15px;
    height: 15px;

    svg {
      width: 15px;
      height: 4px;
    }
  }
`;

export const StyledDateWrapper = styled(DateWrapper)`
  margin-right: ${gutters.spacing(2)}px;
`;

export const StyledDateInputInlineSelect = styled(DateInputInlineSelect)`
  top: -2px !important;
`;

export const StyledSelect = styled(Select)`
  top: 3px !important;
  left: -379px !important;
`;

export const StyledBaseCard = styled(BaseCard)`
  margin: ${gutters.spacing(1)}px 0;

  &:hover {
    background: ${tableHover};
  }

  ${({ isDragging }) =>
    isDragging &&
    css`
      pointer-events: none;
      & * {
        pointer-events: none;
      }
    `}

  ${({ isDragItem }) =>
    isDragItem &&
    css`
      background: ${tableHover};
    `}

  ${({ bringToTop, forceToTop }) =>
    (bringToTop || forceToTop) &&
    css`
      z-index: ${layers.content(0, 1)};
    `}
`;

export const ContentWrapper = styled.div`
  padding-top: ${gutters.spacing(2, -1)}px;

  > div {
    display: flex;
    align-items: center;
    height: 15px;
    max-width: fit-content;

    & > * {
      width: 100%;
    }

    & + div {
      margin-top: ${gutters.spacing(2)}px;
    }
  }
`;

export const StageSelect = styled(Select)`
  margin-bottom: ${gutters.spacing(1, -3)}px;
`;

export const StyledSelectInline = styled(SelectInline)`
  max-height: 15px;
`;
