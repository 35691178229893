import React, { useState, useEffect } from 'react';
import { findAncestorByClassname } from '../util';

const isElement = (element: Element | Document): element is Element => {
  return element instanceof Element;
};

// This hook is used to determine the direction in which a container should expand.
// It takes an expanded state and a target ancestor container class name as input.
// if no ancestor container is found, it will use the document borders as a fallback.
export const useExpandDirection = (
  containerRef: React.RefObject<HTMLDivElement>,
  expanded: boolean,
  targetClassName: string
) => {
  const [expandDirection, setExpandDirection] = useState<'left' | 'right'>();
  const [remainingSpace, setRemainingSpace] = useState<number>(100);

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef.current) {
        return;
      }

      const containerRect = containerRef.current.getBoundingClientRect();
      const borderElement = findAncestorByClassname(
        containerRef.current,
        targetClassName
      );

      // set document borders by default
      let borderLeft = 0;
      let borderRight = Math.max(
        document.documentElement.scrollWidth,
        document.body.scrollWidth
      );

      if (borderElement && isElement(borderElement)) {
        const borderElementRect = borderElement.getBoundingClientRect();
        borderLeft = borderElementRect.left;
        borderRight = borderElementRect.right;
      }

      const distanceToLeftBorder = Math.abs(containerRect.left - borderLeft);
      const distanceToRightBorder = Math.abs(borderRight - containerRect.right);

      setExpandDirection(
        distanceToLeftBorder > distanceToRightBorder ? 'left' : 'right'
      );
      setRemainingSpace(
        distanceToLeftBorder > distanceToRightBorder
          ? distanceToLeftBorder
          : distanceToRightBorder
      );
    };

    const current = containerRef.current;
    const resizeObserver = new ResizeObserver(handleResize);
    if (current) {
      resizeObserver.observe(current);
    }
    handleResize();
    return () => {
      if (current) {
        resizeObserver.unobserve(current);
      }
    };
  }, [expanded, targetClassName, containerRef]);

  return { containerRef, expandDirection, remainingSpace };
};
