import { forwardRef, PropsWithChildren, useCallback, useMemo } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ButtonMultiSelect, ButtonRadio } from 'ts-components/ButtonToggle';
import Select from '__components/Inputs/Select';
import TextInput from '__components/Inputs/TextInput';
import WholeNumberTextInput from '__components/Inputs/TextInput/presets/WholeNumber';
import Switch from '__components/Kizen/Switch';
import { ColorPicker } from './ColorPicker';
import { useSelectMenuPlacement } from './useSelectMenuPlacement';

type Option = {
  value: string;
  label: string;
};

type GroupedOption = {
  label: string;
  options: Option[];
};

type FlexWrapperProps = {
  direction?: 'row' | 'column';
  marginBottom?: number;
};

type FlexSelectProps = {
  value: Option;
  options: Option[] | GroupedOption[];
  onChange(option: Option): void;
  flex?: number;
  scrollContainer?: any;
  variant?: 'underline' | 'outline';
  className?: string;
  style?: any;
};

type FlexWholeNumberInputProps = {
  maxDigits?: number;
  maxValue?: number;
};

const isGroupedOptions = (x: any): x is GroupedOption[] => {
  return Array.isArray(x) && Array.isArray(x[0]?.options);
};

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  ${({ direction, marginBottom = 15 }) =>
    (!direction || direction === 'row') &&
    css`
      margin-bottom: ${marginBottom}px;
    `}
`;

export const FlexChild = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => flex || 1};

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FlexColorPicker = styled(ColorPicker)<{ flex?: number }>`
  flex: ${({ flex }) => flex || 1};

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const FlexSelectContainer = styled.div<{ flex?: number; ref: any }>`
  flex: ${({ flex }) => flex || 1};
  overflow: hidden;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FlexSelect = forwardRef(
  (
    {
      scrollContainer,
      options,
      children,
      className,
      style,
      flex,
      ...rest
    }: PropsWithChildren<FlexSelectProps>,
    ref
  ) => {
    const numOptions = useMemo(() => {
      return isGroupedOptions(options)
        ? options.reduce((acc, x) => acc + x.options.length, options.length) // count nested options + headers
        : options.length;
    }, [options]);

    const [menuRef, menuPlacement]: any = useSelectMenuPlacement({
      scrollTarget: scrollContainer,
      numOptions,
    } as any);

    return (
      <FlexSelectContainer
        ref={menuRef}
        className={className}
        style={style}
        flex={flex}
      >
        <Select
          ref={ref}
          options={options}
          menuPlacement={menuPlacement}
          menuPosition="fixed"
          {...rest}
        >
          {children}
        </Select>
      </FlexSelectContainer>
    );
  }
);

export const FlexUnderlineMultiSelect = styled(ButtonMultiSelect)`
  flex: ${({ flex }) => flex || 1};

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FlexTextInput = styled(TextInput)`
  flex: ${({ flex }) => flex || 1};
  min-width: 0;
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          & input {
            width: 100%;
          }
        `
      : ''}

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FlexSwitch = styled(Switch)<{ flex?: number }>`
  flex: ${({ flex }) => flex || 1};

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FlexUnderlineRadio = styled(ButtonRadio)`
  flex: ${({ flex }) => flex || 1};

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const StyledWholeNumberInput = styled(WholeNumberTextInput)`
  flex: ${({ flex }) => flex || 1};
  overflow: hidden;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const FlexWholeNumberInput = forwardRef(
  (
    {
      children,
      maxDigits,
      maxValue = Infinity,
      ...rest
    }: PropsWithChildren<FlexWholeNumberInputProps>,
    ref
  ) => {
    const isAllowed = useCallback(
      ({ floatValue }: any) => {
        if (floatValue === undefined) return true;

        const validDigits =
          maxDigits === undefined
            ? true
            : floatValue.toString().length <= maxDigits;

        return validDigits && floatValue <= maxValue;
      },
      [maxValue, maxDigits]
    );

    return (
      <StyledWholeNumberInput
        ref={ref}
        thousandSeparator={false}
        isAllowed={isAllowed}
        {...rest}
      >
        {children}
      </StyledWholeNumberInput>
    );
  }
);
