import AxiosService from './AxiosService';
import {
  camelToSnakeCaseKeys,
  //FORCE_ALL_RECORDS_SIZE
} from './helpers';

class FilterGroupsService {
  constructor() {
    if (!FilterGroupsService.instance) {
      FilterGroupsService.instance = this;
    }
    return FilterGroupsService.instance;
  }

  filterGroupsPath = 'filter-groups';
  customObjectPath = 'custom-objects';

  generateEndpoint = (objectId, ...pathnames) =>
    [this.customObjectPath, objectId, this.filterGroupsPath, ...pathnames]
      .filter(Boolean)
      .join('/');

  createFilterGroup = async (objectId, { name, config, sharingSettings }) => {
    const { data } = await AxiosService.post(
      this.generateEndpoint(objectId),
      {
        name,
        config,
        sharing_settings:
          sharingSettings && camelToSnakeCaseKeys(sharingSettings),
      },
      { skipErrorBoundary: true }
    );
    return data;
  };

  updateFilterGroup = async (
    objectId,
    filterGroupId,
    { name, sharingSettings, owner, config }
  ) => {
    const { data } = await AxiosService.patch(
      this.generateEndpoint(objectId, filterGroupId),
      {
        name,
        config,
        sharing_settings:
          sharingSettings && camelToSnakeCaseKeys(sharingSettings),
        owner,
      },
      { skipErrorBoundary: true }
    );
    return data;
  };

  getFilterGroup = async (objectId, filterGroupId) => {
    const { data } = await AxiosService.get(
      this.generateEndpoint(objectId, filterGroupId),
      {
        skipErrorBoundary: true,
      }
    );
    return data;
  };

  deleteFilterGroup = async (objectId, filterGroupId) => {
    const { data } = await AxiosService.delete(
      this.generateEndpoint(objectId, filterGroupId),
      {
        skipErrorBoundary: true,
      }
    );
    return data;
  };

  updateFilterGroupVisibility = async (objectId, filterGroupId, hidden) => {
    const { data } = await AxiosService.patch(
      this.generateEndpoint(objectId, filterGroupId),
      {
        hidden,
      },
      { skipErrorBoundary: true }
    );
    return data;
  };

  getFilterGroups = async (objectId, pathname, options) => {
    const { data } = await AxiosService.get(
      this.generateEndpoint(objectId, pathname),
      options
    );
    return data;
  };

  getGrantingAdmins = async (id, objectId, options) => {
    const { data } = await AxiosService.get(
      this.generateEndpoint(objectId, id, 'admins'),
      options
    );

    return data;
  };

  requestFilterGroupAccess = async (
    groupId,
    grantingAdmin,
    accessLevel,
    objectId
  ) => {
    const { data } = await AxiosService.post(
      this.generateEndpoint(objectId, 'request-access'),
      {
        entity_type: 'custom_object_filter_group',
        entity_id: groupId,
        admin_responder: grantingAdmin,
        access_level_requested: accessLevel,
      },
      { skipErrorBoundary: true }
    );

    return data;
  };

  respondToAccessRequest = async (requestId, approved, objectId) => {
    const { data } = await AxiosService.put(
      this.generateEndpoint(objectId, requestId, 'request-access-response'),
      {
        approved,
      },
      { skipErrorBoundary: true }
    );
    return data;
  };
}

const instance = new FilterGroupsService();
Object.freeze(instance);

export default instance;
