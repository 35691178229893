import { useRef } from 'react';
import MultiSelect from 'components/Inputs/MultiSelect';
import { ApplyMenuButton, ClearMenuButton } from 'components/Kizen/Menu';
import useField from 'hooks/useField';
import { useTranslation } from 'react-i18next';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { useAutoFocus } from '../hooks/useAutoFocus';
import { useAsync } from 'react-use';
import FilterGroupsService from 'services/FilterGroupsService';
import { namedToOption } from 'services/helpers';

export const FilterGroupMultiselect = ({
  field,
  model,
  onChange,
  error, // exclude from ...props
  onHide,
  value,
  isReadOnly,
  isLoading,
  autoFocus,
  ...props
}) => {
  const { t } = useTranslation();
  const [innerValue, setInnerValue] = useField(value);

  const { value: options, loading: filterGroupsLoading } =
    useAsync(async () => {
      if (!model?.id) {
        return [];
      }
      const groups = await FilterGroupsService.getFilterGroups(model.id);
      return groups.map(namedToOption);
    }, [model?.id]);

  const selectRef = useRef(null);

  useAutoFocus(selectRef.current?.select?.inputRef, isReadOnly || !autoFocus);

  const handleClickApply = () => {
    onHide();
    onChange(innerValue.map(({ value }) => value));
  };

  const handleClickClear = () => {
    onHide();
    onChange(EMPTY_ARRAY);
  };

  return (
    <MultiSelect
      ref={selectRef}
      {...props}
      options={options}
      loadItems={filterGroupsLoading}
      field={field}
      value={innerValue}
      onChange={setInnerValue}
      isReadOnly={isReadOnly}
      disabled={isReadOnly}
      menuIsOpen={!isReadOnly}
      menuLeftButton={
        !isReadOnly && (
          <ClearMenuButton onClick={handleClickClear}>
            {t('Clear & Close')}
          </ClearMenuButton>
        )
      }
      menuRightButton={
        !isReadOnly && <ApplyMenuButton onClick={handleClickApply} />
      }
      hideRemoveIcon={isReadOnly}
      placeholder={t('Find Filter Groups')}
    />
  );
};
