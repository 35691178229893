import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale, shadowLight } from 'app/colors';
import { breakpoints, borderRadii } from 'app/spacing';
import Button from 'components/Button';
import BroadcastCalendar from './components/BroadcastCalendar';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';
import { IncrementalDatePicker } from 'components/Calendar/components/IncrementalDatePicker';

/* Grid & cell/area containers */
export const borderRadius = css`
  border-radius: ${borderRadii.standard};
`;

export const tileCell = css`
  ${shadowLight}
  ${borderRadius}
  background-color: ${grayScale.white};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-template-columns: minmax(min-content, 400px) minmax(600px, 1fr);
  gap: 20px;
  margin: 20px 0 0;

  @media (max-width: ${breakpoints.lg}px) {
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    height: auto;
  }

  @media (max-width: ${breakpoints.md}px) {
    ${borderRadius}

    height: calc(100vh - ${({ height }) => height + 2 * 15}px);
    margin: 15px 0;
  }
  @media (min-width: ${breakpoints.lg + 1}px) {
    min-height: calc(100vh - ${({ height }) => height + 20}px);
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ column }) => (column ? 'flex-start' : 'center')};
`;

export const LeftColumn = styled.div`
  ${borderRadius}
  grid-column: 1;
  grid-row: 1 / 3;

  @media (min-width: ${breakpoints.lg}px) {
    height: calc(100% - 20px);
  }
`;

export const CalendarFiltersCell = styled(Cell)`
  justify-content: space-between;

  @media (max-width: ${breakpoints.lg}px) {
    grid-row: 3;
  }
`;

export const CalendarCell = styled(Cell)`
  max-height: 1024px;
  justify-content: center;
  ${({ calendarView }) =>
    calendarView === CALENDAR_VIEW_OPTIONS.month
      ? css`
          min-height: 676px;
        `
      : ''}
`;

export const ScheduleBroadcastCell = styled(Cell)`
  height: 50px;
  margin-bottom: 20px;

  @media (max-width: ${breakpoints.md}px) {
    margin-bottom: 15px;
  }
`;

export const StyledIncrementalDatePicker = styled(IncrementalDatePicker)`
  /* 90px Today Button + 200px Toggle Button + 30px margins */
  max-width: calc(100% - 320px);
`;

/* Elements */

export const ScheduleBroadcastButton = styled(Button)`
  width: 100%;
`;

export const StyledBroadcastCalendar = styled(BroadcastCalendar)`
  ${({ view }) =>
    // day and week have their own scrolls - make the height the same as LeftColumn so there is no browser scrollbar
    view !== CALENDAR_VIEW_OPTIONS.month
      ? css`
          max-height: calc(100vh - 180px);
        `
      : ''}
`;
