import {
  compileForm,
  root,
  row3,
  section,
  submitButton,
} from '@kizen/page-builder/gen';
import { getUniqueId } from '@kizen/page-builder/utils/id';
import {
  appBackground,
  grayScale,
} from '@kizen/page-builder/internal/app/colors';

const rootNodeProps = {
  backgroundColor: appBackground,
  maxWidth: '900',
};
const formSectionProps = {
  containerBorderColor: grayScale.medium,
  containerBorderWidth: '1',
  containerBorderRadius: false,
  containerBackgroundColor: grayScale.white,
};

const emptyFormPage = (businessColor) =>
  compileForm(
    root(rootNodeProps, [
      section({
        containerBackgroundColor: businessColor,
        containerBorderRadius: false,
      }),
      section(formSectionProps, [
        row3([null, null, submitButton({ label: 'Submit' })]),
      ]),
    ])
  );

export const createNewPage = (pageName, businessColor) => ({
  pageName,
  pageData: emptyFormPage(businessColor),
  isFormPage: true,
  isDeletable: true,
  isHideable: true,
  id: getUniqueId(),
  hidden: false,
});

export const createDefaultPage = (pageName, pageData) => ({
  id: getUniqueId(),
  pageData,
  pageName,
  isFormPage: true,
  isDeletable: false,
  isHideable: true,
  hidden: false,
});

export const createDefaultThankYouPage = (pageName, pageData) => ({
  id: getUniqueId(),
  pageData,
  pageName,
  isFormPage: false,
  isDeletable: false,
  isHideable: false,
  hidden: false,
});
