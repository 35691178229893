import TriggerCard from 'pages/AutomationEngine/Engine/Steps/TriggerCard';
import { dateOffsetValuesTranslated } from 'pages/AutomationEngine/helpers';
import DraggableStepCard from 'pages/AutomationEngine/Engine/Steps/DraggableStepCard';

export const content = ({ step }) => step.description;

const GetDateOffset = ({ dateOffset, dateOffsetDays }, t) => {
  const dateOffsetValues = dateOffsetValuesTranslated(t);
  if (dateOffset !== dateOffsetValues[0].value) {
    return `${dateOffsetDays} ${
      dateOffsetValues.find((item) => item.value === dateOffset).label
    }`;
  }
  return dateOffsetValues.find((item) => item.value === dateOffset).label;
};

export const getDateOffset = (step, t) => GetDateOffset(step, t);

export const getDescription = ({ step, t }) => {
  const { details } = step;
  return `${GetDateOffset(details, t)} ‘${
    details?.fieldId?.label || t('[Deleted]')
  }’ at ${details?.time} ${details?.period}`;
};

export default function Card({ step, ...others }) {
  return step?.goalType ? (
    <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>
  ) : (
    <TriggerCard {...others}>{content({ step })}</TriggerCard>
  );
}
