import React from 'react';
import { grayScale } from '../../../../app/colors';

export default function ColumnResizeIcon(props) {
  return (
    <svg
      width="3"
      height="24"
      viewBox="0 0 3 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line x1="1.5" y1="0" x2="1.5" y2="24" stroke={grayScale.medium} />
      <line
        x1="1.5"
        y1="8"
        x2="1.5"
        y2="16"
        stroke={grayScale.mediumDark}
        strokeWidth="3"
      />
    </svg>
  );
}
