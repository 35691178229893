import { forwardRef, useEffect, useState } from 'react';
import { StyledInput } from './styles';
import { useDebounce } from 'react-use';
import { usePreserveCaretPosition } from 'hooks/usePreserveCaretPosition';

const EditableText = forwardRef(
  (
    {
      className = '',
      value = '',
      placeholder = '',
      onChange,
      disabled = false,
      showLighter = false,
      sizing = 'normal',
      debounce = false,
      maxLength,
      ...others
    },
    ref
  ) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useDebounce(
      () => {
        if (debounce) {
          onChange?.(debouncedValue);
        }
      },
      200,
      [debouncedValue]
    );

    useEffect(() => {
      if (debounce) {
        setDebouncedValue(value);
      }
    }, [value, debounce]);

    const inputRef = usePreserveCaretPosition(
      debounce ? debouncedValue : value,
      maxLength
    );

    return (
      <StyledInput
        ref={ref}
        inputRef={inputRef}
        value={debounce ? debouncedValue : value}
        onChange={(event) => {
          const v = maxLength
            ? event.target.value.slice(0, maxLength)
            : event.target.value;
          debounce ? setDebouncedValue(v) : onChange?.(v, event);
        }}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        showLighter={showLighter}
        className={className}
        sizing={sizing}
        {...others}
      />
    );
  }
);

EditableText.displayName = 'EditableText';

export default EditableText;

// For use with react-number-format which requires a standard onChange handler and refs
export const EditableTextForNumberInput = forwardRef(
  ({ onChange, ...others }, ref) => {
    return (
      <EditableText
        ref={ref}
        onChange={(_, event) => onChange?.(event)}
        {...others}
      />
    );
  }
);

EditableTextForNumberInput.displayName = 'EditableTextForNumberInput';
