import {
  displayValue,
  valueExists,
  DEFAULT_LABEL_INDICATOR,
} from 'components/DashboardGrid/utils';

const MAX_ITEMS_IN_LABEL = 4;

const getPossibleOptionName = (maybeOption) => {
  // Options usually have a displayName, but if they don't, the name attribute should be used. It's
  // also possible these are select options, in which case label should be used
  return maybeOption?.displayName || maybeOption?.name || maybeOption?.label;
};

const mapOptions = (value, fieldOptions) => {
  const option = fieldOptions.find(
    // Email Status field uses code instead of id for the backend
    (o) => o.id === value || o.value === value || o.code === value
  );
  return getPossibleOptionName(option);
};

export const getDefaultLabel = ({
  min,
  max,
  currencySymbol,
  t,
  values,
  fieldOptions,
  fallback,
}) => {
  const hasMin = valueExists(min);
  const hasMax = valueExists(max);

  if (hasMin && hasMax) {
    return t('{{currencySymbol}}{{min}} to {{currencySymbol}}{{max}}', {
      min: displayValue(min),
      max: displayValue(max),
      currencySymbol,
    });
  }
  if (hasMin) {
    return t('Greater than {{currencySymbol}}{{min}}', {
      min: displayValue(min),
      currencySymbol,
    });
  }
  if (hasMax) {
    return t('Less than {{currencySymbol}}{{max}}', {
      max: displayValue(max),
      currencySymbol,
    });
  }

  if (values?.length) {
    if (values.length > MAX_ITEMS_IN_LABEL) {
      const toName = values.slice(0, MAX_ITEMS_IN_LABEL);
      const remaining = values.length - MAX_ITEMS_IN_LABEL;
      return `${toName
        .map((v) => mapOptions(v, fieldOptions))
        .filter(Boolean)
        .join(', ')} ${t('& {{remaining}} more', { remaining })}`;
    }
    return values
      .map((v) => mapOptions(v, fieldOptions))
      .filter(Boolean)
      .join(', ');
  }
  if (!hasMin && !hasMax) {
    return fallback || t('? to ?');
  }
};

export const getValueBreakdownLabel = (bucket, fieldData, t) => {
  const { min, max } = bucket ?? {};
  const currencySymbol = fieldData?.moneyOptions?.symbol ?? '';
  if (bucket.bucketLabel && bucket.bucketLabel !== DEFAULT_LABEL_INDICATOR) {
    return bucket.bucketLabel;
  }
  if (min === undefined || max === undefined) {
    return t('Unknown');
  }
  return getDefaultLabel({
    min: min ? min.value : undefined,
    max: max ? max.value : undefined,
    currencySymbol,
    t,
  });
};
