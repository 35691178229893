import { colorsPrimary } from 'app/colors';
import { DEFAULT_TEMPLATE_ID } from 'components/Modals/ApplyTemplateModal';
import { DEFAULT_ICONS, DEFAULT_ITEM_IDS } from './constants';
import {
  getCanAdmin,
  getCanChangeOwner,
  getCanEdit,
  getCanElevatePermissions,
  getCanView,
} from 'components/AccessRequests/utils';
import { monitoringMessageHelper } from 'sentry/helpers';

export const mutateOriginColumn = (mutator, item) => {
  const { fromParent } = item;
  return mutator((data) => {
    const res = [...data];

    if (fromParent) {
      const parentIdx = res.findIndex((d) => d.id === fromParent);
      const childIdx = res[parentIdx]?.children?.findIndex(
        (c) => c.id === item.id
      );

      if (childIdx > -1) {
        res[parentIdx].children.splice(childIdx, 1);
      }
    } else {
      const idx = res.findIndex((d) => d.id === item.id);
      if (idx >= 0) {
        res.splice(idx, 1);
      }
    }
    return res;
  });
};

export const mutateItem = (
  mutator,
  parentIndex,
  childIndex,
  field,
  value,
  lookupId
) => {
  const allowed_fields = [
    'customLabel',
    'color',
    'widthPercent',
    'displayName',
    'url',
    'icon',
    'config',
  ];
  if (!allowed_fields.includes(field)) {
    monitoringMessageHelper('Field modification not allowed for', {
      extra: {
        field,
      },
    });
    return;
  }

  if (parentIndex > -1) {
    mutator((data) => {
      const res = [...data];
      if (childIndex > -1) {
        res[parentIndex].children[childIndex][field] = value;
      } else {
        res[parentIndex][field] = value;
      }

      return res;
    });
  } else {
    mutator((data) => {
      const res = [...data];
      const parentIndex = data.findIndex((d) => d.id === lookupId);
      if (parentIndex > -1) {
        res[parentIndex][field] = value;
      }

      return res;
    });
  }
};

export const mutateDestinationColumn = (
  mutator,
  item,
  dropZone,
  childDropZone,
  skip,
  generateId,
  t,
  handlePopup,
  dropFirstChild,
  beforeMutation,
  itemsOrdering
) => {
  const { position } = dropZone;

  return mutator((data) => {
    const res = beforeMutation([...data]);
    if (skip?.(item) ?? false) {
      return res;
    }

    const isCategory = item.id === 'new_category';

    const newId = generateId?.(item, data) ?? item.id;

    let payload = {
      ...item,
      color: item.color ?? colorsPrimary.blue.dark,
      fromParent: undefined,
    };

    // Need to convert dynamic items to static on drop. isDynamic will get converted
    // to wasDynamic, so these initializers are designed to only be run once for a particlar item.
    // On subsequent moves within the column, these need to be skipped or configuration information
    // will be lost.
    if (item.dynamicallyPopulated && item.isDynamic) {
      payload = {
        id: newId,
        icon: 'history',
        isDynamic: false,
        wasDynamic: true,
        label: item.label,
        isDefault: true,
        isLocked: item.isLocked,
        customLabel: t('Recent Records'),
        widthPercent: 100,
        column: 1,
        hasPoppedUp: item.hasPoppedUp,
        hasPopup: item.hasPopup,
        color: item.color ?? colorsPrimary.blue.dark,
        type: 'category',
        children: [],
        dynamicallyPopulated: true,
      };
    } else if (item.isDynamic) {
      payload = {
        id: newId,
        icon: item.icon,
        isDynamic: false,
        wasDynamic: true,
        label: item.label,
        isLocked: item.isLocked,
        customLabel: isCategory ? t('New Category') : '',
        widthPercent: 100,
        column: 1,
        hasPoppedUp: item.hasPoppedUp,
        hasPopup: item.hasPopup,
        color: item.color ?? colorsPrimary.blue.dark,
      };

      if (isCategory) {
        payload.type = 'category';
        payload.children = [];
      } else {
        payload.type = 'item';
      }
    }

    if (dropFirstChild) {
      const childIdx = 0;
      let parentIdx = -1;
      parentIdx = res.findIndex((d) => d.id === dropFirstChild);

      if (parentIdx > -1) {
        if (payload.hasPopup && !payload.hasPoppedUp) {
          payload.hasPoppedUp = true;
          handlePopup?.(parentIdx, childIdx, payload);
        }
        res[parentIdx].children = [payload];
      }
    } else if (childDropZone) {
      let childIdx = -1;
      let parentIdx = -1;
      if (dropZone.id) {
        parentIdx = res.findIndex((d) => d.id === dropZone.id);
        childIdx = res[parentIdx]?.children?.findIndex(
          (d) => d.id === childDropZone.id
        );
      } else {
        // We usually have a parent ID, but in certain circumstances there isn't one,
        // so a more inefficient lookup is necessary
        parentIdx = res.findIndex((d) => {
          const secondaryIndex =
            d?.children?.findIndex((c) => c.id === childDropZone.id) ?? -1;
          if (secondaryIndex > -1) {
            childIdx = secondaryIndex;
            return true;
          }
          return false;
        });
      }

      if (childIdx > -1) {
        if (childDropZone.position === 'before') {
          if (payload.hasPopup && !payload.hasPoppedUp) {
            payload.hasPoppedUp = true;
            handlePopup?.(parentIdx, childIdx, payload);
          }
          res[parentIdx].children.splice(childIdx, 0, payload);
        } else {
          if (payload.hasPopup && !payload.hasPoppedUp) {
            payload.hasPoppedUp = true;
            handlePopup?.(parentIdx, childIdx + 1, payload);
          }
          res[parentIdx].children.splice(childIdx + 1, 0, payload);
        }
      }
    } else {
      payload.widthPercent = 100;
      if (DEFAULT_ICONS[payload.id]) {
        payload.icon = DEFAULT_ICONS[payload.id];
      }

      if (position === 'first') {
        if (payload.hasPopup && !payload.hasPoppedUp) {
          payload.hasPoppedUp = true;
          handlePopup?.(0, -1, payload);
        }
        res.unshift(payload);
      }

      if (position === 'before' || position === 'after') {
        const idx = res.findIndex((d) => d.id === dropZone.id);
        if (position === 'before') {
          if (payload.hasPopup && !payload.hasPoppedUp) {
            payload.hasPoppedUp = true;
            handlePopup?.(idx, -1, payload);
          }
          res.splice(idx, 0, payload);
        } else {
          if (payload.hasPopup && !payload.hasPoppedUp) {
            payload.hasPoppedUp = true;
            handlePopup?.(idx + 1, -1, payload);
          }
          res.splice(idx + 1, 0, payload);
        }
      }

      if (position === 'overall') {
        if (itemsOrdering) {
          const lookUp = res.reduce(
            (acc, item) => ({ ...acc, [item.id]: item }),
            {}
          );
          return itemsOrdering.reduce((acc, id) => {
            if (lookUp[id]) {
              acc.push(lookUp[id]);
            }
            if (id === item.id) {
              acc.push(payload);
            }
            return acc;
          }, []);
        }
        res.unshift(payload);
      }
    }

    return res;
  });
};

export const COLUMNS = {
  FIND_OPTIONS: 'find-options',
  YOUR_TOOLBAR: 'your-toolbar',
};

export const countUniqueDynamicItems = (data) => {
  const existingIdsIncrements = data.reduce(
    (collect, curr) => {
      if (curr?.wasDynamic) {
        collect.push(curr.id.match(/--(\d+)$/)?.[1] || 0);
      }
      if (curr?.children?.length) {
        collect.push(countUniqueDynamicItems(curr?.children));
      }
      return collect;
    },
    [0]
  );

  return Math.max(...existingIdsIncrements);
};

export const getDropId = (draggingItem, data) => {
  let newId = draggingItem.id;

  if (draggingItem.isDynamic) {
    const count = countUniqueDynamicItems(data);
    newId = `${newId}--${count + 1}`;
  }

  return newId;
};

const filterBySection = (item, sections) => {
  if (item.id === DEFAULT_ITEM_IDS.ACTIVITIES) {
    return sections.activities_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.ADS) {
    return sections.ads_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.AUTOMATIONS) {
    return sections.automations_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.BROADCASTS) {
    return sections.broadcasts_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.CONTACTS) {
    return sections.contacts_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.CUSTOM_OBJECTS) {
    return sections.custom_objects_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.DASHBOARD) {
    return sections.dashboards_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.FORMS) {
    return sections.forms_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.LIBRARY) {
    return sections.library_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.MARKETING_COSTS) {
    return sections.marketing_costs_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.SMART_CONNECTORS) {
    return sections.smart_connectors_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.MY_PROFILE) {
    return (
      sections.my_profile_section?.view ||
      sections?.api_connections_section?.oauth_authorizations ||
      sections?.api_connections_section?.standard_api_keys
    );
  }

  if (item.id === DEFAULT_ITEM_IDS.ACTIVITIES) {
    return sections.scheduled_activities_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.SETTINGS) {
    return sections.settings_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.SURVEYS) {
    return sections.surveys_section?.view;
  }

  if (item.id === DEFAULT_ITEM_IDS.MARKETPLACE) {
    return sections.marketplace_section?.view;
  }

  return true;
};

const filterByCustomObject = (item, entities) => {
  if (item.isCustomObject) {
    if (entities[item.id]) {
      return entities[item.id].enabled;
    }
  }

  return true;
};

const filterSpecificItem = (
  item,
  access,
  canChooseBusiness,
  canAddBusiness
) => {
  const sections = access?.sections || {};
  const entities = access?.custom_objects?.custom_object_entities || {};

  if (item.id === DEFAULT_ITEM_IDS.CHANGE_BUSINESS) {
    return canChooseBusiness;
  }
  if (item.id === DEFAULT_ITEM_IDS.ADD_BUSINESS) {
    return canAddBusiness;
  }

  return (
    filterBySection(item, sections) && filterByCustomObject(item, entities)
  );
};

export const filterInvalidToolbarItems = (
  config,
  access,
  canChooseBusiness,
  canAddBusiness,
  inBuilder = false
) => {
  return config
    .map((item) => {
      return {
        ...item,
        children: item.children
          ? item.children.filter((item) =>
              filterSpecificItem(
                item,
                access,
                canChooseBusiness,
                canAddBusiness
              )
            )
          : item.children,
      };
    })
    .filter((item) => {
      if (item.isDynamic) {
        return true;
      }

      if (item.type === 'category' && !item.children?.length) {
        return Boolean(
          (inBuilder && item.wasDynamic) || item.dynamicallyPopulated
        );
      }

      if (item.hasPopup && (!item.displayName || !item.url)) {
        return false;
      }

      if (
        !filterSpecificItem(item, access, canChooseBusiness, canAddBusiness)
      ) {
        return false;
      }

      return true;
    });
};

export const canViewTemplate = (template) => getCanView(template);

export const canEditTemplate = (template) => {
  return getCanEdit(template) && template.id !== DEFAULT_TEMPLATE_ID;
};

export const canAdminTemplate = (template) => {
  return getCanAdmin(template) && template.id !== DEFAULT_TEMPLATE_ID;
};

export const canElevatePermissions = (template) => {
  return (
    getCanElevatePermissions(template) && template.id !== DEFAULT_TEMPLATE_ID
  );
};

export const canChangeOwner = (template) => {
  return getCanChangeOwner(template) && template.id !== DEFAULT_TEMPLATE_ID;
};

export const canDuplicateTemplate = (template) => {
  return canViewTemplate(template) && template.id !== DEFAULT_TEMPLATE_ID;
};

export const serializeToKey = (layout, width = 0) => {
  return layout
    .reduce((acc, curr) => {
      return `${acc}:${curr.id}-${curr.label ?? ''}-${
        curr.customLabel ?? ''
      }-${curr.displayName ?? ''}-${curr.widthPercent ?? ''}-w${width}`;
    }, '')
    .replace(/\s/g, '');
};
