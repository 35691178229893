import { useMemo, useRef, useState } from 'react';

/**
 * Implements a Set but always returns an array of the Set's values
 *
 * @param {array} init - initial set values
 * @returns 2-tuple - [values, { add, has, remove, toggle }]
 */
export const useSet = <T>(init: T[] = []) => {
  const ref = useRef(new Set(init));
  const [values, setValues] = useState(init);

  const ops = useMemo(() => {
    const add = (val: T) => {
      const set = ref.current.add(val);
      const current = Array.from(set);
      setValues(current);
      return current;
    };

    const remove = (val: T) => {
      const success = ref.current.delete(val);
      const current = Array.from(ref.current);
      if (success) {
        setValues(current);
      }
      return current;
    };

    return {
      add,
      has: (val: T) => ref.current.has(val),
      remove,
      toggle: (val: T) => (ref.current.has(val) ? remove(val) : add(val)),
    };
  }, []);

  return [values, ops] as const;
};
