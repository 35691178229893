import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { tableHover } from 'app/colors';
import { fontSizes, fontWeights } from 'app/typography';
import Select from 'components/Kizen/Select';
import Loader from 'components/Kizen/Loader';
import Input from 'components/Kizen/Input';

export const ConnectEmailAlert = styled.div`
  margin-bottom: ${gutters.spacing(4)}px;
  padding: ${gutters.spacing(2, -2)}px ${gutters.spacing(2, 2)}px;
  border-radius: 4px;
  background: ${tableHover};
  font-size: ${fontSizes.text};

  > * {
    font-size: inherit;
  }
`;

export const StyledSelect = styled(Select)`
  margin-bottom: ${gutters.spacing(4)}px;
`;

export const StyledInput = styled(Input)`
  margin-bottom: ${gutters.spacing(4)}px;

  span:first-of-type {
    font-weight: ${fontWeights.bold};
  }
`;

export const StyledLabel = styled.div`
  margin-bottom: ${gutters.spacing(2, 2)}px;
  font-size: ${fontSizes.text};
  font-weight: ${fontWeights.bold};
`;

export const CenteredLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: ${gutters.standard} 0;
  padding-top: 0 !important;
  transform: translate(-50%, -50%);
`;
