import { useEffect, useRef, useState } from 'react';

type Timer = ReturnType<typeof setInterval>;

export const useCycleWithTimer = (items: any[]) => {
  const timeout = useRef<Timer>();
  const [index, setIndex] = useState(0);
  const [isCycling, setIsCycling] = useState(false);

  useEffect(
    () => () => {
      clearInterval(timeout.current);
    },
    []
  );

  const start = () => {
    timeout.current = setInterval(
      () => setIndex((last) => (last === items.length - 1 ? 0 : last + 1)),
      3000
    );
    setIsCycling(true);
  };
  const stop = () => {
    clearInterval(timeout.current);
    setIsCycling(false);
  };
  const next = () => {
    clearInterval(timeout.current);
    setIndex((last) => (last === items.length - 1 ? 0 : last + 1));
    if (isCycling) {
      start();
    }
  };
  const prev = () => {
    clearTimeout(timeout.current);
    setIndex((last) => (last === 0 ? items.length - 1 : last - 1));
    if (isCycling) {
      start();
    }
  };

  return [
    items[index],
    isCycling,
    { next, prev, start, stop, setFromIndex: setIndex },
  ] as const;
};
