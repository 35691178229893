/**
 * Mapping function to transform {@link CustomObjectsService#getAllowedRelations} into dropdown options objects.
 *
 * @param {object} relationship
 * @returns {object} { value: string, label: string }
 */
export const allowedRelationshipDropdownOptions = (relationship) => {
  return {
    value: relationship.id,
    label: relationship.objectName,
  };
};
