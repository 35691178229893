import { toastVariant, useToast } from '__components/ToastProvider';
import useModal from '__components/Modals/useModal';
import {
  FAILED_COMPLETE_ACTIVITY,
  LOG_ACTIVITY_SUCCESS,
  SCHEDULE_ACTIVITY_LOAD_FAIL,
} from 'pages/Common/Actions/activityHelpers';
import { ACTIVITIES } from 'queries/query-keys';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import ActivityService from 'services/ActivityService';
import {
  makeDefaultAssignment,
  mapFetchUrls,
} from 'components/Charts/ScheduledActivities/helpers';
import { useDefaultActivityRelationshipsQuery } from 'queries/models/activites';
import { getOriginalError } from 'services/AxiosService';
import { AxiosError } from 'axios';

export interface ScheduledActivitiesParams {
  customObject: any;
  isClient: boolean;
  entity: any;
  skipErrorBoundary?: boolean;
}

export interface FetchActivityType {
  results: any;
  next: any;
  previous: any;
  count: any;
}

export const useScheduledActivities = ({
  customObject,
  isClient,
  entity,
  skipErrorBoundary = false,
}: ScheduledActivitiesParams) => {
  const [showToast] = useToast();
  const { t } = useTranslation();
  const [loggingActivity, setLoggingActivity] = useState(false);
  const [completeActivity, setCompleteActivity] = useState({});
  const [customObjectActivitiesObject, setCustomObjectActivitiesObject] =
    useState({} as FetchActivityType);
  const [loadingCustomObjectActivities, setloadingCustomObjectActivities] =
    useState(false);
  const [clientObjectActivitiesObject, setClienttObjectActivitiesObject] =
    useState({} as FetchActivityType);
  const [loadingClientObjectActivities, setLoadingClientObjectActivities] =
    useState(false);

  // This is used solely to calculate the count of the scheduled activities for custom objects
  const {
    data: customObjectActivityMetadata,
    refetch: refetchScheduledActivitiesCountCustomObject,
  } = useQuery({
    queryKey: ACTIVITIES.CUSTOM_SCHEDULED(customObject?.id, entity?.id),
    queryFn: () => {
      return ActivityService.v2GetCustomScheduledActivities({
        params: {
          objectId: customObject?.id,
          entityId: entity?.id,
          completed: false,
          page: 1,
          pageSize: 1,
        },
        skipErrorBoundary,
      });
    },
    enabled: !isClient,
  });

  // This is used solely to calculate the count of the scheduled activities for a client
  const {
    data: clientObjectActivityMetadata,
    refetch: refetchScheduledActivitiesCountClient,
  } = useQuery({
    queryKey: ACTIVITIES.CUSTOM_SCHEDULED(customObject?.id, entity?.id),
    queryFn: () => {
      return ActivityService.v2GetContactScheduledActivities({
        params: {
          clientId: entity?.id,
          completed: false,
          page: 1,
          pageSize: 1,
        },
        skipErrorBoundary,
      });
    },
    enabled: isClient,
  });

  const scheduledActivities = useMemo(() => {
    if (isClient) {
      return clientObjectActivityMetadata;
    }

    return customObjectActivityMetadata;
  }, [customObjectActivityMetadata, isClient, clientObjectActivityMetadata]);

  const refetchScheduledActivitiesCount = useMemo(() => {
    if (isClient) {
      return refetchScheduledActivitiesCountClient;
    }
    return refetchScheduledActivitiesCountCustomObject;
  }, [
    isClient,
    refetchScheduledActivitiesCountClient,
    refetchScheduledActivitiesCountCustomObject,
  ]);

  const fetchCustomObjectActivities = useCallback(
    async (params: any) => {
      if (!isClient) {
        try {
          setLoadingClientObjectActivities(true);
          const data = await ActivityService.v2GetCustomScheduledActivities({
            params: {
              ...params,
              objectId: customObject?.id,
              entityId: entity.id,
            },
            skipErrorBoundary,
          });
          setCustomObjectActivitiesObject(data);
          setloadingCustomObjectActivities(false);
          return data;
        } catch (error) {
          setloadingCustomObjectActivities(false);
          const axiosError = error as AxiosError;
          (showToast as any)({
            message: SCHEDULE_ACTIVITY_LOAD_FAIL(t),
            variant: toastVariant.FAILURE,
          });
          return { error: axiosError?.response };
        }
      }
      return { results: [] };
    },
    [customObject, entity, isClient, skipErrorBoundary, showToast, t]
  );

  const fetchClientActivities = useCallback(
    async (params: any) => {
      if (isClient) {
        try {
          setLoadingClientObjectActivities(true);
          const data = await ActivityService.v2GetContactScheduledActivities({
            params: {
              ...params,
              clientId: entity?.id,
            },
            skipErrorBoundary,
          });
          setClienttObjectActivitiesObject(data);
          setLoadingClientObjectActivities(false);

          return data;
        } catch (error) {
          setLoadingClientObjectActivities(false);
          const axiosError = error as AxiosError;
          (showToast as any)({
            message: SCHEDULE_ACTIVITY_LOAD_FAIL(t),
            variant: toastVariant.FAILURE,
          });
          return { error: axiosError?.response };
        }
      }
      return { results: [] };
    },
    [entity, isClient, skipErrorBoundary, showToast, t]
  );

  const activitiesObject = useMemo(() => {
    if (isClient) {
      return clientObjectActivitiesObject;
    }
    return customObjectActivitiesObject;
  }, [isClient, clientObjectActivitiesObject, customObjectActivitiesObject]);

  const loadingActivities = useMemo(() => {
    if (isClient) {
      return loadingClientObjectActivities;
    }
    return loadingCustomObjectActivities;
  }, [isClient, loadingClientObjectActivities, loadingCustomObjectActivities]);

  const fetchActivities = useMemo(() => {
    if (isClient) {
      return fetchClientActivities;
    }
    return fetchCustomObjectActivities;
  }, [isClient, fetchClientActivities, fetchCustomObjectActivities]);

  const activities = useMemo(() => {
    return activitiesObject?.results ?? [];
  }, [activitiesObject]);

  const handleCompleteActivityError = useCallback(
    async (error: any) => {
      const orig = getOriginalError(error);
      showToast({
        message: orig?.message || FAILED_COMPLETE_ACTIVITY(t),
        variant: toastVariant.FAILURE,
      });
      setLoggingActivity(false);
    },
    [showToast, t]
  );

  const handleCompleteActivity = useCallback(
    async ({ activityObjectId, payload }: any) => {
      setLoggingActivity(true);
      const result = await ActivityService.v2CompleteActivity({
        activityObjectId,
        payload,
      });

      showToast({
        message: LOG_ACTIVITY_SUCCESS(t),
        variant: toastVariant.SUCCESS,
      });
      setLoggingActivity(false);
      refetchScheduledActivitiesCount();

      return result;
    },
    [showToast, t, refetchScheduledActivitiesCount]
  );

  const [completeModalProps, , completeModal] = useModal({
    handleSubmit: handleCompleteActivity,
    handleError: handleCompleteActivityError,
  });

  const defaultActivityRelationshipsQuery =
    useDefaultActivityRelationshipsQuery();
  const { fetchUrlList = {} } = useMemo(() => {
    if (defaultActivityRelationshipsQuery.isLoading) {
      return {};
    }

    const doa = defaultActivityRelationshipsQuery.data;
    return {
      fetchUrlList: mapFetchUrls(doa),
    };
  }, [
    defaultActivityRelationshipsQuery.data,
    defaultActivityRelationshipsQuery.isLoading,
  ]);

  const handleClickCompleteActivity = useCallback(() => {
    const activity = scheduledActivities?.results?.[0];
    const email_status = '';
    if (activity) {
      const defaultAssignment = makeDefaultAssignment(
        activity.associatedEntities,
        fetchUrlList
      );
      setCompleteActivity({
        activity,
        predefinedOptions: {
          defaultAssignment,
          clientOption: activity.client
            ? { ...activity.client, email_status }
            : null,
        },
      });
      completeModal.show();
    }
  }, [scheduledActivities, fetchUrlList, completeModal]);

  return {
    scheduledActivities,
    refetchScheduledActivitiesCount,
    activities,
    fetchActivities,
    loadingActivities,
    completeModalProps,
    handleClickCompleteActivity,
    completeActivity,
    loggingActivity,
  };
};
