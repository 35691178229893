import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import Button from 'components/Button';
import { StyledFooter } from './styles';
import { useCallback } from 'react';

const EditChartGroupsButton = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { handleClickEditDashboards, handleClickAddDashboard, setIsOpen } =
    props.selectProps;

  const handleClickEdit = useCallback(() => {
    handleClickEditDashboards();
    setIsOpen(false);
  }, [handleClickEditDashboards, setIsOpen]);

  const handleClickAdd = useCallback(() => {
    handleClickAddDashboard();
    setIsOpen(false);
  }, [handleClickAddDashboard, setIsOpen]);

  return (
    <components.Menu {...props}>
      {children}
      {handleClickEditDashboards || handleClickAddDashboard ? (
        <StyledFooter>
          {handleClickEditDashboards ? (
            <Button
              className="apply-button"
              variant="text"
              color="blue"
              noSpace
              onClick={handleClickEdit}
            >
              {t('Manage Chart Groups')}
            </Button>
          ) : null}
          {handleClickAddDashboard ? (
            <Button
              className="apply-button"
              variant="text"
              color="green"
              noSpace
              onClick={handleClickAdd}
            >
              {t('Add Chart Group')}
            </Button>
          ) : null}
        </StyledFooter>
      ) : null}
    </components.Menu>
  );
};

export default EditChartGroupsButton;
