import styled from '@emotion/styled';

import Switch from '__components/Kizen/Switch';
import RangeInput from '__components/Kizen/RangeInput';

export const SwitchNoMargin = styled(Switch)`
  margin: 0;
`;

export const StyledRangeInput = styled(RangeInput)`
  && {
    padding-bottom: 0px;
    & > span {
      margin-bottom: 17px;
    }
    [data-reach-slider-input][data-orientation='horizontal'] {
      width: 100%;
      min-width: 100%;
      padding-left: 52px;
      padding-right: 41px;
    }
    [data-reach-slider-marker][data-value='400'] > span {
      right: 20px;
      left: 10px;
    }
  }
`;
