import { TemplatesTable } from './subpages/TemplatesTable';
import { MyColumns } from './subpages/MyColumns';
import { TemplateColumns } from './subpages/TemplateColumns';

export const SUBPAGES = {
  MINE: 'mine',
  TEMPLATES: 'templates',
};

export const SUBROUTES = {
  myColumns: {
    label: (t) => t('My Columns'),
    component: MyColumns,
    exact: true,
    path: SUBPAGES.MINE,
    icon: 'table-columns-light',
  },
  columnTemplates: {
    label: (t) => t('Column Templates'),
    component: TemplatesTable,
    exact: true,
    path: SUBPAGES.TEMPLATES,
    icon: 'files-light',
  },
  columnTemplate: {
    component: TemplateColumns,
    exact: true,
    path: SUBPAGES.TEMPLATES + '/:id',
  },
};

export const SUBROUTES_LINKS = {
  myColumns: SUBROUTES.myColumns,
  columnTemplates: SUBROUTES.columnTemplates,
};
