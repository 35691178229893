import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { withCellWrapper } from '../helpers';
import { getPercentageChanceToCloseProps } from 'pages/CustomObjects/RecordsPage/helpers';
import { StyledInfoWrapper } from 'components/Fields/InlineTableFieldInput/styles';
import KizenTypography from 'app/kizentypo';
import WholeNumberInline from 'components/Inputs/inline/TextInput/presets/WholeNumber';
import { getAdditionalFieldProps } from 'checks/fields';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';

const _ChanceToCloseCell = forwardRef(
  (
    {
      field,
      object: record,
      onSubmit,
      value: valueProp, // remove from orhers as we get value from record
      objectId: modelId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder: placeholderProp,
      info: infoProp,
      showDisplayNameTooltip,
      column,
      readOnly: readOnlyProp,
      editable,
      onAutoFocus,
      meta,
      supportsInfiniteScroll,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { percentageChanceToClose } = record;

    const { value, placeholder, readOnly, info } =
      getPercentageChanceToCloseProps(
        field,
        [meta.stageField],
        {
          stage: { value: record.stage?.value },
          percentageChanceToClose: percentageChanceToClose,
        },
        t
      );

    const computedValue = value ?? percentageChanceToClose;

    // If we support infinite scroll, we don't want to render the input because
    // react-autosize-input will cause the table to jump around as you scroll.
    // For most fields, this is automatic, but % chance to close renders the input
    // even when it's read only, so we need to handle it here.
    if (readOnlyProp && supportsInfiniteScroll) {
      return (
        <StyledInfoWrapper isInherited={!!info}>
          <KizenTypography>{computedValue ?? placeholder}</KizenTypography>
        </StyledInfoWrapper>
      );
    }

    return (
      <StyledInfoWrapper isInherited={!!info}>
        <WholeNumberInline
          ref={ref}
          field={field}
          object={record}
          {...others}
          value={computedValue}
          onSubmit={async (data) => {
            await onSubmit(
              record.id,
              {
                percentageChanceToClose: data,
              },
              column
            );
          }}
          onAutoFocus={onAutoFocus}
          isInherited={!!info}
          readOnly={readOnly}
          {...getAdditionalFieldProps(field, info, placeholder)}
        />
        {info && editable && !readOnly ? (
          <IconWithTooltip label={info} />
        ) : null}
      </StyledInfoWrapper>
    );
  }
);

export const ChanceToCloseCell = withCellWrapper(_ChanceToCloseCell);
