import { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import useAsync from 'react-use/lib/useAsync';
import EmailService from 'services/EmailService';
import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import { CheckboxSingle } from 'components/Inputs/CheckboxGroup';
import { borderRadii, useBreakpoint } from 'app/spacing';
import { colorsButton, colorsSecondary, grayScale } from 'app/colors';
import Loader from 'components/Kizen/Loader';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { PageSizing } from 'components/Layout/PageContentWidth';
import { SettingsContentCard } from 'components/Card/Card';
import { TextEllipsis, TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { fontWeights, fontSizes } from 'app/typography';
import { useSanitizedHtml } from 'hooks/useSanitizedHtml';
import { displayBreakpoints } from 'app/spacing';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { getSearchParam } from 'hooks/useSearchParam';
import { toastVariant, useToast } from 'components/ToastProvider';

const OPTED_IN = 'opted_in';
const OPTED_OUT = 'opted_out';
const UNSUBSCRIBED_FROM_ALL = 'unsubscribed_from_all';
const frequencyDisplays = (t) => ({
  daily: t('Daily'),
  several_weekly: t('A few per week'),
  weekly: t('One per week'),
  several_monthly: t('A few per month'),
  monthly: t('One per month'),
  several_yearly: t('A few per year'),
  yearly: t('One per year'),
});

const TopBar = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${({ color }) => color || colorsButton.blue.default};
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  row-gap: 15px;
  margin-bottom: 30px;

  p {
    margin-top: 0;
  }

  @media (max-width: ${displayBreakpoints.desktop}px) {
    grid-template-columns: 1fr;
    row-gap: 7px;
    margin-bottom: 13px;
    p {
      line-height: calc(5px + px('1em'));
    }
  }
`;

const UnsubscribeAllButtonContainer = styled.div`
  grid-row: 1 / 3;
  grid-column: 2;
  justify-self: flex-end;
  align-items: center;
  display: flex;
`;

const UnsubscribeAllButtonContainerMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr fit-content(100%) 1fr;
  padding: 16px 0 15px 0;
  border-top: 1px solid ${grayScale.medium};
  column-gap: 15px;
  & > button {
    grid-column: 2;
  }
`;

const UnsubscribeAllButton = styled(Button)`
  margin: 0;
`;

const BusinessName = styled(KizenTypography)`
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: ${displayBreakpoints.desktop}px) {
    margin-bottom: 20px;
  }
`;

const ImageBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-bottom: 30px;

  @media (max-width: ${displayBreakpoints.desktop}px) {
    margin-bottom: 20px;
  }
`;

const OnSuppressionListText = styled(KizenTypography)`
  font-size: 13px;
  font-weight: ${fontWeights.regular};
  line-height: 16px;
  letter-spacing: 0.03em;
  margin: 30px auto 23px;
  width: 600px;
  padding: 15px 12px;
  border-radius: ${borderRadii.standard};
  color: rgba(224, 21, 60, 1);
  background-color: rgba(224, 21, 60, 0.1);

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
  }
`;

const StyledSettingsContentCard = styled(SettingsContentCard)`
  max-width: 865px;
  width: 100%;
  margin: -30px auto;
  padding: 30px 30px 0 30px;

  @media (max-width: ${displayBreakpoints.desktop}px) {
    margin: -45px 0 15px;
    padding: 20px 20px 0;
  }
`;

const SavedText = styled(KizenTypography)`
  color: ${({ error }) => (error ? colorsSecondary.red.light : '#9eda49')};
  opacity: 0;
  font-weight: ${fontWeights.bold};
  ${({ marginRight }) =>
    !isNaN(marginRight) &&
    css`
      margin-right: ${marginRight}px;
    `};
  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      transition: opacity 300ms;
    `}
`;

const SubscriptionCardSavedText = styled(SavedText)`
  grid-column: 4 / 6;

  @media (max-width: ${displayBreakpoints.desktop}px) {
    grid-row: 2;
    grid-column: 3;
    ${({ firstRow }) =>
      firstRow
        ? css`
            grid-row: 1;
            align-self: end;
          `
        : ''}
  }
`;

const UnsubscribeAllSavedText = styled(SavedText)`
  @media (max-width: ${displayBreakpoints.desktop}px) {
    grid-column: 3;
    grid-row: 1;
    display: flex;
    align-items: center;
    margin-right: 0;
  }
`;

const SubscriptionCardContainer = styled.div`
  display: grid;
  grid-template-columns: 17px 230px 120px 1fr minmax(0, 50%);
  grid-auto-rows: auto;
  gap: 15px;
  align-items: center;
  border-top: 1px solid ${grayScale.medium};
  padding: 30px 0;

  @media (max-width: ${displayBreakpoints.desktop}px) {
    grid-template-columns: 17px fit-content(80%) ${({ withDescription }) =>
        withDescription ? '1fr' : 'max-content'};
    grid-template-rows: auto;
    padding: 14px 0 13px 0;
    row-gap: 10px;
  }
`;

const SubscriptionName = styled(TextEllipsisWithTooltip)`
  font-weight: ${fontWeights.bold};
  font-size: 16px;

  @media (max-width: ${displayBreakpoints.desktop}px) {
    grid-row: 1;
    grid-column: ${({ shortened }) => (shortened ? '2 / 3' : '2 / 4')};
  }
`;

const FrequencyContainer = styled.div`
  display: inline-flex;

  @media (max-width: ${displayBreakpoints.desktop}px) {
    grid-row: 2;
    grid-column: 2;
    &:empty {
      display: none;
    }
    & > p:first-child {
      margin-top: 1px;
      margin-bottom: -1px; //design nude
    }
  }
`;

const Frequency = styled(TextEllipsis)`
  background-color: ${grayScale.mediumLight};
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.micro};
  line-height: 12px;
  padding: 3px 5px;
  border-radius: ${borderRadii.small};
`;

const SubscriptionDescription = styled.div`
  grid-row: 2;
  grid-column: 2 / 6;
  margin-top: 0;
  overflow-wrap: break-word;
  font-size: 14px;

  p,
  li,
  p * {
    font-size: 14px;
  }

  @media (max-width: ${displayBreakpoints.desktop}px) {
    grid-row: ${({ secondRow }) => (secondRow ? 2 : 3)};
    grid-column: 2 / 4;
    &:empty {
      display: none;
    }
    & > ol {
      margin-bottom: 0;
    }
  }
`;

const Checkbox = styled(CheckboxSingle)`
  input {
    cursor: pointer;
  }
`;

const useAssociativeBooleans = (init = {}) => {
  const [obj, setObj] = useState(init);

  const ops = useMemo(
    () => ({
      clear: () => setObj({}),
      fromEntries: (entries) => setObj(Object.fromEntries(entries)),
      set: (id, value) => setObj((o) => ({ ...o, [id]: value })),
      // function setAll(value: boolean): void;
      // function setAll(keys: (string | boolean)[], value: boolean): void;
      setAll: (...args) =>
        setObj((o) => {
          const keys = args.length === 2 ? args[0] : Object.keys(o);
          const value = args.length === 2 ? args[1] : args[0];
          return keys.reduce((acc, x) => {
            acc[x] = value;
            return acc;
          }, {});
        }),
      toggle: (id) => setObj((o) => ({ ...o, [id]: !o[id] })),
    }),
    []
  );

  return [obj, ops];
};

const SubscriptionCard = memo(
  ({
    checked,
    id,
    name,
    frequency,
    description,
    saved,
    disabled,
    onChange,
  }) => {
    const { t } = useTranslation();
    const sanitizedDescription = useSanitizedHtml(description);
    const frequencies = frequencyDisplays(t);
    const saveError = saved instanceof Error;

    return (
      <SubscriptionCardContainer withDescription={!!description}>
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          {...getDataQAForInput(id, 'checkbox')}
        />
        <SubscriptionName type="subheader" shortened={!frequencies[frequency]}>
          {name}
        </SubscriptionName>
        <FrequencyContainer>
          {frequencies[frequency] ? (
            <Frequency>{frequencies[frequency]}</Frequency>
          ) : null}
        </FrequencyContainer>
        <SubscriptionCardSavedText
          show={saved}
          error={saveError}
          firstRow={!frequencies[frequency]}
        >
          {saved ? (saveError ? t('Error') : t('Saved!')) : null}
        </SubscriptionCardSavedText>
        <SubscriptionDescription
          secondRow={!frequencies[frequency]}
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
        />
      </SubscriptionCardContainer>
    );
  }
);

const setPageTitle = (title, { name = '' }) =>
  name ? `${title} - ${name}` : title;

const SubscriptionManagement = ({ title }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const [showToast] = useToast();
  const { contactId, messageId } = params;
  const [checkboxes, checkboxesOps] = useAssociativeBooleans(); // boolean | Error
  const [saving, savingOps] = useAssociativeBooleans();
  const [saved, savedOps] = useAssociativeBooleans(); // { [k: string]: boolean | Error }
  const [hasUnsubscribedAll, setHasUnsubscribedAll] = useState(false);
  const [business, setBusiness] = useState({});
  const hasBeenCalled = useRef(false);

  useSetTitleOnLoad(title(t), business, setPageTitle);
  const [{ onSuppressionList, subscriptions }, setSubscriptionData] = useState({
    onSuppressionList: false,
    subscriptions: {},
  });
  const unsubscribeAllError = hasUnsubscribedAll instanceof Error;
  const unsubscribeAllProp = getSearchParam(
    history.location,
    'unsubscribe-all'
  );
  const isMobile = useBreakpoint(displayBreakpoints.desktop);

  const { loading } = useAsync(async () => {
    const data = await EmailService.getManageSubscriptions(
      contactId,
      messageId
    );

    const { ids, checkedEntries } = data.subscriptions.reduce(
      (acc, x) => {
        const value = data.onSuppressionList ? false : x.status === OPTED_IN;
        acc.ids.push(x.id);
        acc.checkedEntries.push([x.id, value]);
        return acc;
      },
      { ids: [], checkedEntries: [] }
    );

    checkboxesOps.fromEntries(checkedEntries);
    savedOps.setAll(ids, false);
    savingOps.setAll(ids.concat('all'), false);

    setBusiness(data.business);
    setSubscriptionData({
      onSuppressionList: data.onSuppressionList,
      subscriptions: data.subscriptions.reduce((acc, x) => {
        acc[x.id] = x;
        return acc;
      }, {}),
    });
  }, []);

  const onChangeCallbacks = useMemo(() => {
    const cb = (id) => async (checked) => {
      checkboxesOps.toggle(id);
      savedOps.set(id, false);
      savingOps.set(id, true);

      try {
        await EmailService.updateManageSubscriptions(contactId, messageId, {
          subscriptionStatus: checked ? OPTED_IN : OPTED_OUT,
          subscriptionId: id,
        });
        savedOps.set(id, true);
      } catch (error) {
        savedOps.set(id, error);
        checkboxesOps.toggle(id);
      } finally {
        savingOps.set(id, false);
      }
    };
    return Object.keys(subscriptions).reduce((acc, id) => {
      acc[id] = cb(id);
      return acc;
    }, {});
  }, [subscriptions, contactId, messageId, checkboxesOps, savedOps, savingOps]);

  const unsubscribeAll = useCallback(
    async (shouldShowToast = false) => {
      hasBeenCalled.current = true;
      const prevEntries = Object.entries(checkboxes);
      checkboxesOps.setAll(false);
      savedOps.setAll(false);
      savingOps.setAll(true);
      setHasUnsubscribedAll(false);

      try {
        await EmailService.updateManageSubscriptions(contactId, messageId, {
          subscriptionStatus: UNSUBSCRIBED_FROM_ALL,
        });
        if (shouldShowToast) {
          showToast({
            message: t('You have been unsubscribed from all communications.'),
            variant: toastVariant.ALERT,
          });
        } else {
          setHasUnsubscribedAll(true);
          savedOps.fromEntries(prevEntries);
        }
      } catch (error) {
        if (shouldShowToast) {
          showToast({
            message: t(
              'There was an error unsubscribing you from all communications. Please try again.'
            ),
            variant: toastVariant.FAILURE,
          });
        } else {
          setHasUnsubscribedAll(error);
          checkboxesOps.fromEntries(prevEntries);
        }
      } finally {
        savingOps.setAll(false);
      }
    },
    [
      checkboxes,
      checkboxesOps,
      contactId,
      messageId,
      savedOps,
      savingOps,
      showToast,
      t,
    ]
  );

  useEffect(() => {
    if (unsubscribeAllProp && !hasBeenCalled.current && !loading) {
      unsubscribeAll(true);
    }
  }, [unsubscribeAllProp, unsubscribeAll, loading]);

  const onClickUnsubscribeAll = useCallback(
    () => unsubscribeAll(false),
    [unsubscribeAll]
  );

  const unsubscribeButton = useMemo(
    () => (
      <>
        {!onSuppressionList ? (
          <UnsubscribeAllSavedText
            show={hasUnsubscribedAll}
            error={unsubscribeAllError}
            marginRight={20}
          >
            {unsubscribeAllError
              ? isMobile
                ? t('Error')
                : t('Not Saved. Please try again.')
              : t('Saved!')}
          </UnsubscribeAllSavedText>
        ) : null}
        <UnsubscribeAllButton
          disabled={onSuppressionList || saving?.all}
          variant="outline"
          color="iconGray"
          data-qa="unsubscribe-from-all"
          onClick={onClickUnsubscribeAll}
        >
          {t('Unsubscribe from all')}
        </UnsubscribeAllButton>
      </>
    ),
    [
      onClickUnsubscribeAll,
      onSuppressionList,
      hasUnsubscribedAll,
      unsubscribeAllError,
      saving,
      isMobile,
      t,
    ]
  );

  if (loading) {
    return <Loader loading />;
  }

  return (
    <>
      <TopBar color={business?.primaryColor} />
      <PageSizing>
        <StyledSettingsContentCard>
          {business?.logo ? (
            <ImageBlock>
              <img src={business.logo} alt={business.name} />
            </ImageBlock>
          ) : (
            <BusinessName type="header">{business?.name}</BusinessName>
          )}
          {onSuppressionList && (
            <OnSuppressionListText>
              {t(
                'Your email address has been added to a suppression list due to a spam complaint or too many bounces. If you feel this has been made in error, please '
              )}
              <KizenTypography
                href={`mailto:${business?.email}`}
                type="link-mail"
              >
                {t('email us and let us know')}
              </KizenTypography>
              {t('. Thank you!')}
            </OnSuppressionListText>
          )}
          <HeaderContainer>
            <KizenTypography type="subheader">
              {t('Subscription Lists')}
            </KizenTypography>
            <KizenTypography>
              {t('Let us know what kinds of messages you`d like to receive')}
            </KizenTypography>
            {!isMobile ? (
              <UnsubscribeAllButtonContainer>
                {unsubscribeButton}
              </UnsubscribeAllButtonContainer>
            ) : null}
          </HeaderContainer>
          {Object.keys(checkboxes).map((id) => (
            <SubscriptionCard
              key={id}
              id={id}
              checked={checkboxes[id]}
              name={subscriptions[id].name}
              frequency={subscriptions[id].frequency}
              description={subscriptions[id].description}
              saved={saved[id]}
              disabled={onSuppressionList || saving[id]}
              onChange={onChangeCallbacks[id]}
            />
          ))}
          {isMobile ? (
            <UnsubscribeAllButtonContainerMobile>
              {unsubscribeButton}
            </UnsubscribeAllButtonContainerMobile>
          ) : null}
        </StyledSettingsContentCard>
      </PageSizing>
    </>
  );
};

export default SubscriptionManagement;
