import { useMemo } from 'react';

export const useFontFamiliesFromText = (editorText = '') => {
  return useMemo(() => {
    return [...editorText.matchAll(/style=".*?"/g)].reduce((acc, style) => {
      const res = /font-family:.*;/.exec(style);
      if (res !== null) {
        const ff = res[0]
          .split(/(font-family:|;)/)
          .filter((s) => s !== '' && s !== ';' && s !== 'font-family:');
        acc.push(ff[0].trim());
      }
      return acc;
    }, []);
  }, [editorText]);
};
