import React, { useEffect } from 'react';

export const useClickOutside = (
  elementRef: React.RefObject<HTMLElement>,
  onClickOutside: (e?: MouseEvent) => void,
  ignoreRef?: React.RefObject<HTMLElement>,
  options?: { enabled?: boolean }
) => {
  useEffect(() => {
    if (options?.enabled !== false) {
      const handleClick = (e: MouseEvent) => {
        if (
          elementRef.current &&
          e.target &&
          !elementRef.current?.contains(e.target as Node) &&
          !ignoreRef?.current?.contains(e.target as Node)
        ) {
          onClickOutside?.(e);
        }
      };

      document.addEventListener('click', handleClick, true);

      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }
  }, [elementRef, onClickOutside, ignoreRef, options]);
};
