import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '__components/Kizen/IconButton';
import {
  StyledDiv,
  StyledRequiredSwitch,
  StyledVariableCard,
  StyledVariableWarpper,
} from './styles';
import Icon from '__components/Kizen/Icon';
import { grayScale } from '__app/colors';
import { IconSizing } from '__components/Kizen/Icon';
import Select from '__components/Inputs/Select';
import {
  ExecutionVariable,
  GenericOption,
  VariableDataType,
} from '__pages/SmartConnectors/types';
import { SmartConnectorContext } from '../../context';
import { NameInput } from './NameInput';
import { SourceSelect } from './SourceSelect';
import { useVariableErrors } from '../../hooks/useErrors';

const buttonColor = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

export type OnChangeVariableHandler = ({
  id,
  prop,
  value,
}: {
  id: string;
  prop: keyof ExecutionVariable;
  value: ExecutionVariable[keyof ExecutionVariable];
}) => ExecutionVariable[keyof ExecutionVariable];

export type VarsByHeaders = Record<string, ExecutionVariable>;

type VariableSettingsProps = {
  variable: ExecutionVariable;
  index: number;
  dataSourceOptions: {
    options: GenericOption[];
    label: string;
  }[];
  onChange: OnChangeVariableHandler;
  onDelete: (id: string, index: number) => void;
  indexToScroll: null | number;
  setIndexToScroll: Dispatch<SetStateAction<null | number>>;
  autodetectedVarsByHeaders: VarsByHeaders;
  sourceLabelsBySourceName: Record<string, string>;
};

export const VariableSettings = ({
  variable,
  index,
  dataSourceOptions,
  onChange,
  onDelete,
  indexToScroll,
  setIndexToScroll,
  autodetectedVarsByHeaders,
  sourceLabelsBySourceName,
}: VariableSettingsProps) => {
  const { t } = useTranslation();
  const { metaData } = useContext(SmartConnectorContext);

  const {
    id,
    name,
    data_type,
    data_source,
    is_array,
    required,
    input_format,
    output_format,
    array_delimiter,
  } = variable;

  const { hasError, variableError, errorKey, validate, clearError } =
    useVariableErrors(index);

  const variableMeta = useMemo(() => {
    return metaData.execution_variables.variable_data_types.find(
      ({ value }) => value === data_type
    );
  }, [metaData, data_type]);

  const onDeleteVariable = () => {
    onDelete(id, index);
  };

  const onChangeName = (value: string) => {
    const result = onChange({ id, prop: 'name', value });
    if (result) {
      clearError('name');
    }
    return result as string;
  };

  const onChangeDataType = ({ value }: { value: VariableDataType }) => {
    onChange({ id, prop: 'data_type', value });
    clearError('data_type');
  };

  const onChangeDataSource = ({ value }: { value: string }) => {
    onChange({ id, prop: 'data_source', value });
    clearError('data_source');

    if (!name) {
      onChangeName(value);
    }

    if (!data_type && autodetectedVarsByHeaders[value]?.data_type) {
      onChangeDataType({ value: autodetectedVarsByHeaders[value].data_type! });
    }
  };

  const onChangeInputFormat = ({ value }: { value: string }) => {
    onChange({ id, prop: 'input_format', value });
    clearError('input_format');
  };

  const onChangeArrayDelimiter = ({ value }: { value: string }) => {
    onChange({ id, prop: 'array_delimiter', value });
    clearError('array_delimiter');
  };

  const onChangeOutputFormat = ({ value }: { value: string }) => {
    onChange({ id, prop: 'output_format', value });
    clearError('output_format');
  };

  const onChangeRequired = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ id, prop: 'required', value: e.target.checked });
    clearError('required');
  };

  const onChangeIsArray = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({ id, prop: 'is_array', value: e.target.checked });
    clearError('is_array');
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorKey) {
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [errorKey]);

  useEffect(() => {
    if (indexToScroll === index) {
      setIndexToScroll(null);
      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [indexToScroll, index, setIndexToScroll]);

  return (
    <StyledVariableWarpper ref={wrapperRef}>
      <IconButton
        title={t('Delete')}
        sizing="dense"
        color={buttonColor}
        onClick={onDeleteVariable}
      >
        <IconSizing size="15px">
          <Icon icon="trash" />
        </IconSizing>
      </IconButton>
      <StyledVariableCard hasError={hasError}>
        <StyledDiv width={280}>
          <NameInput
            value={name}
            onChange={onChangeName}
            error={variableError?.name}
            validate={errorKey === 'name' ? validate : undefined}
          />
        </StyledDiv>
        <StyledDiv width={70}>
          <StyledRequiredSwitch
            label={t('Required?')}
            checked={required}
            onChange={onChangeRequired}
            textPlacement="regular-top"
            removeMargin
          />
        </StyledDiv>
        <StyledDiv width={280}>
          <SourceSelect
            isNew={id.startsWith('new_')}
            value={
              data_source
                ? {
                    value: data_source,
                    label: sourceLabelsBySourceName[data_source],
                  }
                : null
            }
            options={dataSourceOptions}
            onChange={onChangeDataSource}
            error={variableError?.data_source}
            validate={errorKey === 'data_source' ? validate : undefined}
          />
        </StyledDiv>
        <StyledDiv width={180}>
          <Select
            value={data_type}
            options={metaData.execution_variables.variable_data_types}
            label={t('Variable Type')}
            placeholder={t('Choose Variable Type')}
            onChange={onChangeDataType}
            error={variableError?.data_type}
            validate={errorKey === 'data_type' ? validate : undefined}
          />
        </StyledDiv>
        {variableMeta?.meta.input_format.length ? (
          <StyledDiv width={180}>
            <Select
              value={input_format}
              options={variableMeta?.meta.input_format || []}
              label={t('Input Variable Format')}
              placeholder={t('Choose Input Format')}
              onChange={onChangeInputFormat}
              error={variableError?.input_format}
              validate={errorKey === 'input_format' ? validate : undefined}
            />
          </StyledDiv>
        ) : null}
        {variableMeta?.meta.is_array_accepted ? (
          <StyledDiv width={70}>
            <StyledRequiredSwitch
              label={t('Is Array?')}
              checked={is_array}
              onChange={onChangeIsArray}
              textPlacement="regular-top"
              removeMargin
            />
          </StyledDiv>
        ) : null}
        {variableMeta?.meta.is_array_accepted && is_array ? (
          <StyledDiv width={180}>
            <Select
              value={array_delimiter}
              labelInfo={t(
                'Whitespace will be trimmed after splitting on selected delimiter.'
              )}
              labelInfoPlacement="top"
              options={metaData.execution_variables.array_delimiter}
              label={t('Delimiter')}
              placeholder={t('Choose Delimiter')}
              onChange={onChangeArrayDelimiter}
              error={variableError?.array_delimiter}
              validate={errorKey === 'array_delimiter' ? validate : undefined}
            />
          </StyledDiv>
        ) : null}
        {variableMeta?.meta.output_format.length ? (
          <StyledDiv width={180}>
            <Select
              value={output_format}
              options={variableMeta?.meta.output_format || []}
              label={t('Output Variable Format')}
              placeholder={t('Choose Output Format')}
              onChange={onChangeOutputFormat}
              error={variableError?.output_format}
              validate={errorKey === 'output_format' ? validate : undefined}
            />
          </StyledDiv>
        ) : null}
      </StyledVariableCard>
    </StyledVariableWarpper>
  );
};
