import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TopLayerSelect } from 'components/PageBuilder/components/TopLayerSelect';

export const SendFrom = React.memo(
  forwardRef(({ errorMessage, showError, modalLayer, ...rest }, ref) => {
    const { t } = useTranslation();
    return (
      <TopLayerSelect
        ref={ref}
        variant="underline"
        label={t('Send From')}
        validate={{ message: errorMessage, showMessage: showError }}
        modalLayer={modalLayer}
        {...rest}
      />
    );
  })
);

SendFrom.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.objectOf({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};
