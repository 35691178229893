import styled from '@emotion/styled';
import { clamp } from 'utility/clamp';
import { FlexWholeNumberInput } from '../components';
import { UNIT } from '../../options';

export const MaxWidthInput = styled(FlexWholeNumberInput)`
  && {
    // maintain margin for max width even when it is :last-child
    margin-right: 10px;
  }
`;

export const getMaxWidthBlurValue = (value, unit) => {
  const maybeNum = parseInt(value, 10);
  return isNaN(maybeNum)
    ? null
    : unit === 'pixel'
      ? maybeNum
      : clamp(maybeNum, 0, 100);
};

export const getProportionalSizes = ({
  proportion,
  width,
  height,
  containerWidth,
  unit = UNIT.PIXEL,
}) => {
  const getWidthForDynamicSize = () =>
    width > containerWidth ? containerWidth : width;

  const getProportionalSize = (size, byHeight = false) => {
    const value = byHeight ? size * proportion : size / proportion;
    return value ? +value.toFixed(2) : value;
  };

  const getProportionalMaxSizes = (size, byHeight = false) => {
    if (byHeight) {
      const maxWidth = getProportionalSize(size, true);
      return { maxHeight: size, maxWidth };
    } else {
      const maxHeight = getProportionalSize(size);
      return { maxWidth: size, maxHeight };
    }
  };

  if (unit === UNIT.PIXEL) {
    if (!height) {
      const w = getWidthForDynamicSize();
      const h = getProportionalSize(w);
      const maxSizes = getProportionalMaxSizes(width);
      return { width: w, height: h, ...maxSizes };
    } else {
      const w = getProportionalSize(height, true);
      const maxSizes = getProportionalMaxSizes(height, true);
      const data = { ...maxSizes };
      if (w > containerWidth) {
        data.width = containerWidth;
        data.height = getProportionalSize(containerWidth);
      } else {
        data.width = w;
        data.height = getProportionalSize(w);
      }
      return data;
    }
  } else {
    return { width };
  }
};
