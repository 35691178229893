import KizenTypography from 'app/kizentypo';
import { StyledLink, StyledText } from './styles';

const SingleValue = ({ getValue }) => {
  const val = getValue()?.[0];

  return (
    <StyledText>
      {val ? (
        <StyledLink type="link" to={`/team-member/${val.id}/timeline`}>
          {val.label}
        </StyledLink>
      ) : (
        <KizenTypography>{'—'}</KizenTypography>
      )}
    </StyledText>
  );
};

export default SingleValue;
