import { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BigTableLayout, {
  useBigTableLayoutNew,
} from 'components/Layout/BigTableLayout';
import BigTable from 'components/Tables/Big';
import { TRow } from 'components/Kizen/Table';
import TablePagination from 'components/Tables/Big/TablePagination';
import PageToolbar, {
  PageToolbarSection,
  PageToolbarTitle,
  PageBreadcrumb,
} from 'components/Layout/PageToolbar';
import { getBroadcastsColumns } from './columns';
import Breadcrumbs from 'components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import { useNavBarState } from 'app/navBarController';
import {
  selectLibraryBroadcastsFetching,
  selectLibraryBroadcastsIsFiltering,
} from 'store/libraryPage/library.redux';
import { SUB_NAVIGATION_HEIGHT } from 'pages/Library/helpers';

const DesktopLayout = ({
  search,
  onChangeSearch,
  broadcasts,
  broadcastsCount,
  parentFolder,
  pageNumber,
  pageSize,
  handleChangePageNumber,
  handleChangePageSize,
  sort,
  handleChangeSort,
  handleSelectAction,
  timezone,
  directories,
  onChangeDirectory,
  handleUpdateBroadcast,
  ...others
}) => {
  const { t } = useTranslation();
  const isBroadcastsFetching = useSelector(selectLibraryBroadcastsFetching);
  const isFiltering = useSelector(selectLibraryBroadcastsIsFiltering);
  const { height } = useNavBarState();
  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayoutNew(height, height + SUB_NAVIGATION_HEIGHT);

  const { scrolledToTable } = bigTablePageProps;
  const templatesHeadData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => handleChangeSort({ column, direction }),
      },
    }),
    [sort, handleChangeSort]
  );

  const broadcastsColumns = useMemo(
    () =>
      getBroadcastsColumns({
        timezone,
        onSelectAction: handleSelectAction,
        handleChangeDirectory: onChangeDirectory,
        onUpdateBroadcast: handleUpdateBroadcast,
        parentFolder,
        t,
      }),
    [
      timezone,
      handleSelectAction,
      onChangeDirectory,
      handleUpdateBroadcast,
      parentFolder,
      t,
    ]
  );

  return (
    <BigTableLayout
      breadcrumb={
        <PageBreadcrumb>
          <Breadcrumbs
            directories={directories}
            handleChangeDirectory={onChangeDirectory}
          />
        </PageBreadcrumb>
      }
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <PageSearchInput
              placeholder={t('Find Messages')}
              value={search}
              onChange={onChangeSearch}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={broadcastsCount}
            single={t('Message')}
            plural={t('Messages')}
          />
          <PageToolbarSection />
        </PageToolbar>
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={broadcastsCount}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      }
      {...bigTablePageProps}
      {...others}
    >
      <BigTable
        scrolledToTable={scrolledToTable}
        columns={broadcasts}
        head={
          <TRow head columns={broadcastsColumns} data={templatesHeadData} />
        }
        staleData={isBroadcastsFetching || isFiltering}
      >
        {broadcasts.map((item) => (
          <TRow key={item.id} columns={broadcastsColumns} data={item} />
        ))}
      </BigTable>
    </BigTableLayout>
  );
};

DesktopLayout.propTypes = {
  broadcasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  broadcastsCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  handleChangePageNumber: PropTypes.func.isRequired,
  handleChangePageSize: PropTypes.func.isRequired,
  sort: PropTypes.object.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleSelectAction: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default DesktopLayout;
