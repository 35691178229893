import { useRef } from 'react';
import styled from '@emotion/styled';
import { gutters, hideScrollbarCss } from 'app/spacing';
import { WeekCalendarCell } from 'components/Calendar';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import CalendarEventPill from './CalendarEventPill';
import { getTimeCellLookupKey, useScheduleBroadcast } from './utils';
import { ScheduleBroadcastCalendarButton } from './ScheduleBroadcastCalendarButton';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';
import { BROADCAST_FORMDATA } from '../ScheduleBroadcastModal/constants';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';

const StyledWeekCell = styled(WeekCalendarCell)`
  position: relative;
  padding-bottom: ${gutters.spacing(0, 3)}px;
  padding-top: ${gutters.spacing()}px;
`;

const EventsScrollContainerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const EventsScrollContainer = styled(ScrollFadeContainer)`
  ${hideScrollbarCss}
  overflow: auto;
  height: 100%;
  width: calc(100% - 9px);
  padding-left: ${gutters.spacing()}px;

  > div {
    width: 100%;
  }
`;

const buildWeekCell =
  (
    onCellClick,
    broadcastsLookup,
    openStatisticsModal,
    openBroadcastModal,
    canScheduleBroadcasts
  ) =>
  ({ date, time, ...rest }) => {
    const ref = useRef(null);
    const lookupKey = getTimeCellLookupKey(date, time);
    const broadcasts = broadcastsLookup[lookupKey] ?? [];

    const { show, onClick, ...contProps } = useScheduleBroadcast(
      onCellClick,
      date,
      lookupKey
    );

    const [refFn, scrollRef] = useSyncSizes(
      ref,
      '.ContentForSyncHeight',
      'height'
    );
    useSyncScroll(useRef([scrollRef, ref]), {
      vertical: true,
    });
    const scrolled = useEndsOfScroll(scrollRef, [broadcasts]);

    return (
      <StyledWeekCell date={date} {...rest} {...contProps}>
        {canScheduleBroadcasts ? (
          <ScheduleBroadcastCalendarButton
            view={CALENDAR_VIEW_OPTIONS.week}
            show={show}
            onClick={onClick}
          />
        ) : null}
        <EventsScrollContainerWrapper>
          <EventsScrollContainer ref={refFn} scrolled={scrolled}>
            <div className="ContentForSyncHeight">
              {broadcasts.map((b) => (
                <CalendarEventPill
                  key={b.id}
                  date={
                    b[BROADCAST_FORMDATA.rescheduleAt] ||
                    b[BROADCAST_FORMDATA.date]
                  }
                  name={b[BROADCAST_FORMDATA.name]}
                  type={b[BROADCAST_FORMDATA.type]}
                  broadcast={b}
                  openBroadcastModal={openBroadcastModal}
                  openStatisticsModal={openStatisticsModal}
                />
              ))}
            </div>
          </EventsScrollContainer>
          <ScrollBarDetached
            ref={ref}
            direction="vertical"
            scrollClassName="ContentForSyncHeight"
          />
        </EventsScrollContainerWrapper>
      </StyledWeekCell>
    );
  };

export default buildWeekCell;
