import TriggerCard from 'pages/AutomationEngine/Engine/Steps/TriggerCard';
import DraggableStepCard from 'pages/AutomationEngine/Engine/Steps/DraggableStepCard';
import { getValueOrDeleted } from '../action/action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  return t('Activity ‘{{activityName}}’ Becomes Past Due', {
    activityName: getValueOrDeleted(step.details.activity?.label, t),
  });
};

export default function Card({ step, ...others }) {
  return step?.goalType ? (
    <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>
  ) : (
    <TriggerCard {...others}>{content({ step })}</TriggerCard>
  );
}
