import { AxiosInstance, AxiosResponse } from 'axios';
import { paths } from 'schema';
import { curry1 } from './utils';

type SearchOpts = {
  filter?: Required<
    paths['/api/client/v2']['post']
  >['requestBody']['content']['application/json'];
  params?: paths['/api/client/v2']['post']['parameters'];
};

type ClientCategory =
  paths['/api/client/categories']['get']['responses']['200']['content']['application/json'][number];
type ClientField =
  paths['/api/client/fields/settings-search']['get']['responses']['200']['content']['application/json'];
type ClientFieldRequest =
  paths['/api/client/fields']['post']['requestBody']['content']['application/json'];
type ClientObject =
  paths['/api/client/custom-object']['get']['responses']['200']['content']['application/json'];
type SearchClientsResponse =
  paths['/api/client/v2']['post']['responses']['200']['content']['application/json'];

export const createClientField = async (
  instance: AxiosInstance,
  field: ClientFieldRequest
): Promise<AxiosResponse<ClientField>> => {
  return instance.post('/client/fields', field);
};

export const getClientCategories = async (
  instance: AxiosInstance
): Promise<AxiosResponse<ClientCategory[]>> => {
  return instance.get('/client/categories');
};

/**
 * @remarks this bypasses permissions to return all fields
 */
export const getAllClientFields = async (
  instance: AxiosInstance
): Promise<AxiosResponse<ClientField[]>> => {
  return instance.get('/client/fields/settings-search');
};

export const getClientObject = async (
  instance: AxiosInstance
): Promise<AxiosResponse<ClientObject>> => {
  return instance.get('/client/custom-object');
};

export const searchClients = async (
  instance: AxiosInstance,
  { filter, params }: SearchOpts = {}
): Promise<AxiosResponse<SearchClientsResponse>> => {
  return instance.post('/client/v2', filter, { params });
};

export class ClientService {
  public createClientField;
  public getAllClientFields;
  public getClientCategories;
  public getClientObject;
  public searchClients;

  constructor(private instance: AxiosInstance) {
    this.createClientField = curry1(createClientField)(this.instance);
    this.getAllClientFields = curry1(getAllClientFields)(this.instance);
    this.getClientCategories = curry1(getClientCategories)(this.instance);
    this.getClientObject = curry1(getClientObject)(this.instance);
    this.searchClients = curry1(searchClients)(this.instance);
  }
}
