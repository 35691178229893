import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  forwardRef,
  cloneElement,
} from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  useRelationshipSelect,
  useSelectEnter,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import TextInput from 'components/Inputs/TextInput';
import { TopMenuButton } from 'components/Kizen/Menu';
import BasicModal from 'components/Modals/presets/BasicModal';
import { applyRef } from 'components/Inputs/props';
import SelectInline, {
  SelectInlineLabel,
} from 'components/Inputs/inline/Select';
import { useAddObjectModal } from '../FieldInput/helpers';
import { useQueryClient } from 'react-query';
import { RELATION_FIELD } from 'queries/query-keys';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

export function RelationshipLink({ value, relationship, ...props }) {
  return (
    <SelectInlineLabel
      type="link"
      to={value && relationship.link({ id: value.value })}
      target="_blank"
      value={value}
      {...props}
    />
  );
}

// Implementation based on FieldInput/RelationshipOne

export const RelationshipOneSelect = forwardRef(
  (
    {
      relationship = {},
      disabled = false,
      onChange = null,
      onModalOpen = null,
      onFocus,
      onMenuOpen,
      onMenuClose,
      field,
      object,
      setShown,
      onSubmit,
      shouldFocusNext,
      ...props
    },
    ref
  ) => {
    const fieldId = useMemo(
      () => `${field?.id}-${object?.id}`,
      [field, object]
    );
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const selectRef = useRef();

    const [typeaheadProps, typeaheadState, { refetch }] =
      useSelectTypeaheadWithScroll({
        alwaysOpen: true, // The select mounts with the menu open
        fetch: relationship.search,
        objectToOption: relationship.toOption,
        fieldId: field.id,
        onFocus,
        onMenuOpen,
        onMenuClose,
        selectRef,
        relatedObjectId: field.relation?.relatedObject,
      });

    const handleSubmitModal = async (name) => {
      if (relationship.isNameOnlyForCreate) {
        const newObject = await relationship.create(name);
        onChange(relationship.toOption(newObject));
        await refetch();
        // need to invalidate all the fields that are related to this object
        await queryClient.invalidateQueries({
          queryKey: [
            ...RELATION_FIELD.RELATED_OBJECTS(field.relation.relatedObject),
          ],
        });
      }
    };

    const [modalProps, inputProps, { onOpen, modalSubmitting }] =
      useAddObjectModal({
        handleSubmit: handleSubmitModal,
      });

    const onStartCreate = () => {
      if (relationship.isNameOnlyForCreate) {
        onOpen(typeaheadProps.inputValue);
        typeaheadProps.onInputChange('');
      }
    };

    const selectEnterProps = useSelectEnter({
      handlePressEnter: onStartCreate,
      options: typeaheadProps.options,
      loadingOptions: typeaheadState.loading,
    });

    useEffect(() => {
      onModalOpen(modalProps.show);
    }, [onModalOpen, modalProps.show]);

    const mergeRef = useCallback(
      (el) => {
        if (el?.select) {
          el.select.getNextFocusedOption = () => null;
        }
        applyRef(selectRef, el);
        applyRef(ref, el);
      },
      [ref]
    );
    const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();
    const { ...customSelectMenuProps } = useCustomSelectMenuProps(selectRef, {
      ...props,
      ...typeaheadProps,
    });

    assignFieldHandle(fieldId, {
      customFocus: () => {
        onModalOpen?.(false);
        setShown?.(true);
        return selectRef.current?.select;
      },
      customTab: async (e) => {
        e.preventDefault();
        const { value } = selectRef.current?.state ?? {};
        setShown?.(false);
        onModalOpen?.(false);
        await onSubmit?.(true, value);
      },
      customEnter: async () => {
        const { value } = selectRef.current?.state ?? {};
        setShown?.(false);
        onModalOpen?.(false);
        await onSubmit?.(true, value);
      },
      customEscape: () => {
        onModalOpen?.(false);
      },
      customUp: () => {
        onModalOpen?.(true);
      },
      customDown: () => {
        onModalOpen?.(true);
      },
      disabled,
      shouldFocusNext: shouldFocusNext,
    });

    return (
      <>
        <Select
          ref={mergeRef}
          disabled={disabled || modalSubmitting}
          placeholder={`${t('Find')} ${relationship.name.single}`}
          menuTopButton={
            relationship.isNameOnlyForCreate && (
              <TopMenuButton onClick={onStartCreate}>
                {`${t('Add')} ${relationship.name.single}`}
              </TopMenuButton>
            )
          }
          isLoading={typeaheadState?.loading}
          endAdornment={<IconAdornment icon={'chevron-down'} />}
          onChange={onChange}
          {...selectEnterProps}
          {...props}
          {...typeaheadProps}
          {...getKeyListenersProps(fieldId)}
          {...customSelectMenuProps}
          tabSelectsValue={true}
        />
        <BasicModal
          heading={`${t('Add')} ${relationship.name.single}`}
          buttonText={t('Save')}
          defaultLeftBtnText={t('Cancel')}
          {...modalProps}
        >
          <TextInput {...inputProps} autoFocus />
        </BasicModal>
      </>
    );
  }
);

RelationshipOneSelect.displayName = 'RelationshipOneSelect';

export default function RelationshipOneInline({
  field,
  object,
  value: relationshipValue = null,
  onSubmit,
  className,
  disabled,
  enableLink = true,
  children: selectEl = <RelationshipOneSelect />,
  ...others
}) {
  const [{ displayValue: value, handleChange: handleSubmit, relationship }] =
    useRelationshipSelect({
      field,
      value: relationshipValue,
      onChange: onSubmit,
    });
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <SelectInline
      field={field}
      object={object}
      onSubmit={handleSubmit}
      rootCloseDisabled={modalOpen}
      labelEl={
        relationship.link && enableLink ? (
          <RelationshipLink relationship={relationship} />
        ) : (
          <TextEllipsisWithTooltip>
            {value?.label || '—'}
          </TextEllipsisWithTooltip>
        )
      }
      {...others}
    >
      {cloneElement(selectEl, {
        value,
        disabled,
        onModalOpen: setModalOpen,
        relationship,
        field,
        object,
        onSubmit: handleSubmit,
      })}
    </SelectInline>
  );
}
