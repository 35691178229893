import { SelectedValues } from './customize';
import styled from '@emotion/styled';

import { borderTight, colorsButton, shadowLight } from 'app/colors';

const Wrapper = styled.div`
  display: flex;
  background: #fff;
  width: 395px;
  flex-direction: column;
  ${shadowLight}
  ${borderTight}
  border-color: ${colorsButton.blue.hover};
  padding: 0px 5px 10px 5px;
`;

const SelectList = styled.div`
  display: flex;
  flex-direction: column;
`;

const emptyFn = () => {};

export const ViewOnlyMultiSelect = ({ value, ...rest }) => {
  return (
    <Wrapper>
      <SelectList>
        <SelectedValues
          value={value}
          {...rest}
          setValue={emptyFn}
          hideRemoveIcon={emptyFn}
          disabled
        />
      </SelectList>
    </Wrapper>
  );
};
