import { getAutomation } from '@kizen/api/automation';
import { UseQueryOptions, useQuery } from 'react-query';
import AxiosService from '__services/AxiosService';
import { AUTOMATIONS } from '../query-keys';

type UseAutomationQueryResponse = Awaited<
  ReturnType<typeof getAutomation>
>['data'];

export const useAutomationQuery = <TData = UseAutomationQueryResponse>(
  automationId: string,
  options: UseQueryOptions<UseAutomationQueryResponse, unknown, TData>
) => {
  return useQuery({
    queryKey: AUTOMATIONS.AUTOMATION(automationId),
    queryFn: async () => {
      const response = await getAutomation(AxiosService, automationId, {
        skipErrorBoundary: true,
      });
      return response.data;
    },
    ...options,
  });
};
