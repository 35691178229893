import { useMemo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Select from 'components/Inputs/Select';
import { Prompt, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getChosenBusiness } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';
import { webApplicationTrackerEmbed } from 'utility/embed';
import { SettingsContentCard } from 'components/Card/Card';
import { PageSizing } from 'components/Layout/PageContentWidth';
import Cols from 'components/Layout/Cols';
import MultiSelect from 'components/Inputs/MultiSelect';
import LongText from 'components/Inputs/LongText';
import Notice from 'components/Kizen/Notice';
import useModal from 'components/Modals/useModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { SubSectionWithHeader as SubSection } from 'components/Layout/SubSection';
import InputControl from 'components/Inputs/InputControl';
import {
  MISSING_URL_PROTOCOL_MESSAGE,
  MISSING_URL_TLD_MESSAGE,
} from 'constants/errorMessages';
import { gutters } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import useField from 'hooks/useField';
import { DesktopOnlyButton } from 'pages/Common/styles/ControlBar';
import {
  ACTIVITY_NOTIFICATION_EMAIL,
  ACTIVITY_NOTIFICATION_SMS,
} from 'services/ActivityService';
import { previewForm } from 'pages/Forms';
import FormService from 'services/FormService';
import { deleteForm } from 'store/formsPage/forms.redux';
import { StyledSettingsContentCard } from '../styles';
import FormNameInput from './components/FormNameInput';
import { BackBreadcrumbToolbar } from 'components/BackBreadcrumbToolbar';
import { checkUrl } from 'utility/validate';
import { toastVariant, useToast } from 'components/ToastProvider';
import { allowNavigation } from 'components/Modals/presets/ConfirmNavigation';
import RedirectUrlInput from 'components/Inputs/RedirectUrlInput';
import {
  getInitialEmailSubscribers,
  getInitialSmsSubscribers,
} from 'utility/team';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import { getOriginalError } from 'services/AxiosService';
import { useAsync } from 'react-use';

const INITIAL_ERROR_MESSAGES = { name: '', redirectUrl: '' };
const NAME_NAVIGATE_AWAY_MESSAGE = (t) =>
  t('Please name your form prior to leaving this page.');
const REDIRECT_URL_NAVIGATE_AWAY_MESSAGE_EMPTY = (t) =>
  t('Please enter a valid URL prior to leaving this page.');
const NO_NAME_MESSAGE = (t) => t('Please name your form.');
const REQUIRED_REDIRECT_URL_MESSAGE = (t) => t('Redirect URL is required.');

export const SUBMISSION_ACTIONS = {
  PAGE: 'go_to_page',
  URL: 'go_to_url',
};
const submissionOptions = (t) => [
  {
    label: t('Go to Thank You Page'),
    value: SUBMISSION_ACTIONS.PAGE,
  },
  {
    label: t('Go to URL'),
    value: SUBMISSION_ACTIONS.URL,
  },
];

const StyledCols = styled(Cols)`
  .InputControl {
    margin-bottom: ${gutters.spacing(3, 4)}px;
  }
`;
const StyleInputControlLink = styled(InputControl)`
  margin-bottom: ${gutters.spacing(3, 4)}px;
`;

const RightButton = styled(DesktopOnlyButton)`
  margin-right: 0;
`;
const StyledLongText = styled(LongText)`
  & div > div {
    min-height: 218px;
  }
`;
const ToolbarButtonsContainer = styled.div`
  display: flex;
`;

const formatError = (error = {}) => {
  const { response } = error;
  if (!response || response.status === 404) {
    return new Error(
      'Something went wrong. Check your network connection and try again.'
    );
  }
  // check for name exist error
  if (response.data && response.data.errors) {
    const { errors } = response.data;
    if (errors.redirect_url && errors.redirect_url[0]) {
      return new Error(errors.redirect_url[0]);
    }
    if (errors?.name?.length) {
      return new Error(errors.name[0]);
    }
  }
  return new Error(
    `${response.statusText} -- ${response.data ? response.data.message : ''}`
  );
};

const SettingsPage = ({ form, fetchForm }) => {
  const emailSelectRef = useRef();
  const smsSelectRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(getChosenBusiness);
  const [name, setName] = useField(form.name);
  const [submissionAction, setSubmissionAction] = useField(
    form.submissionAction
  );
  const [passVariablesOnRedirect, setPassVariablesOnRedirect] = useField(
    form.passVariablesOnRedirect
  );
  const [showToast] = useToast();

  // TODO: when hosting standalone forms we'll want to use the configurable subdomain from Settings -> Business Information
  const chosenBusinessUrl = `https://${import.meta.env.VITE_EMBED_FRONTEND_DOMAIN}`;
  // const chosenBusinessUrl = window.location.origin;
  // const chosenBusinessUrl = useSelector(
  //   ({ authentication }) =>
  //     !!authentication.chosenBusiness && authentication.chosenBusiness.url
  // );

  const [redirectUrl, setRedirectUrl] = useField(form.redirectUrl);
  const [redirectParameterFieldIds, setRedirectParameterFieldIds] = useState(
    form.redirectParameterFields.map(({ id }) => id)
  );
  const [errorMessages, setErrorMessages] = useState(INITIAL_ERROR_MESSAGES);
  const [emailTeamMembers, setEmailTeamMembers] = useState(() =>
    getInitialEmailSubscribers(form.subscribers)
  );
  const [smsTeamMembers, setSmsTeamMembers] = useState(() =>
    getInitialSmsSubscribers(form.subscribers)
  );

  const previewLink = `${chosenBusinessUrl}/form/${form.slug}`;
  const history = useHistory();

  const [confirmDeleteModalProps, , confirmDeleteModal] = useModal({
    handleSubmit: () => {
      dispatch(deleteForm(form?.id));
      setSubmissionAction(SUBMISSION_ACTIONS.PAGE);
      setErrorMessages(INITIAL_ERROR_MESSAGES);
      // we do not wait for the delete result from BE here so we need to redirect when the state of component is updated
      setTimeout(() => history.replace('/forms'));
    },
  });

  const { value: fields, loading: fieldsLoading } = useAsync(async () => {
    return FormService.getFormFields(form.id);
  }, [form.id]);

  const onExportResponses = async () => {
    try {
      await FormService.exportResponses(form);
      const message = t(
        `Responses were queued for export successfully. You will be emailed a download link once complete`
      );
      showToast({
        variant: toastVariant.SUCCESS,
        message,
      });
    } catch {
      const message = t(
        'The export was not successful. Please try again or contact Kizen support.'
      );
      showToast({
        variant: toastVariant.FAILURE,
        message,
      });
    }
  };

  const handleRedirectUrlChange = async () => {
    const url = redirectUrl.trim();
    setErrorMessages((prev) => ({ ...prev, redirectUrl: '' }));

    if (url.length === 0) {
      setErrorMessages((prev) => ({
        ...prev,
        redirectUrl: REQUIRED_REDIRECT_URL_MESSAGE(t),
      }));
    } else if (!(url.startsWith('http://') || url.startsWith('https://'))) {
      setErrorMessages((prev) => ({
        ...prev,
        redirectUrl: MISSING_URL_PROTOCOL_MESSAGE(t),
      }));
    } else if (!checkUrl(url)) {
      setErrorMessages((prev) => ({
        ...prev,
        redirectUrl: MISSING_URL_TLD_MESSAGE(t),
      }));
    } else {
      try {
        await FormService.update(form.id, {
          redirectUrl: url,
          submissionAction,
        });
      } catch (error) {
        if (error.isAxiosError) {
          const err = formatError(error);
          setErrorMessages((prev) => ({ ...prev, redirectUrl: err.message }));
        }
      } finally {
        await fetchForm();
      }
    }
  };

  const handleChange = async (field) => {
    if (!field) return;
    const payload = {};
    Object.entries(field).forEach(([key, value]) => {
      setErrorMessages((prev) => ({ ...prev, [key]: '' }));
      const prev = form[key];

      if (value !== prev && value !== '') {
        payload[key] = value;
      }
    });

    if (Object.keys(payload).length === 0) {
      return;
    }

    try {
      await FormService.update(form.id, payload);
    } catch (error) {
      let err = error;
      if (error.isAxiosError) {
        err = formatError(error);
        const [key] = Object.entries(field)[0];
        setErrorMessages((p) => ({ ...p, [key]: err.message }));
      }
    } finally {
      await fetchForm();
    }
  };

  const handleRedirectParametersChange = async (fieldIds) => {
    try {
      setRedirectParameterFieldIds(fieldIds);
      await handleChange({
        redirectParameterFieldIds: fieldIds,
      });
    } catch (error) {
      const orig = getOriginalError(error);
      if (orig?.message) {
        showToast({
          message: orig?.message,
          variant: toastVariant.FAILURE,
        });
      } else if (error.isAxiosError) {
        const err = formatError(error);
        setErrorMessages((prev) => ({
          ...prev,
          redirectParameterFieldIds: err.message,
        }));
      }
    }
  };

  const updateSubscribers = async (subscribers, notificationType) => {
    const selectedSubscribers = subscribers.map((item) => {
      return {
        employee: item.value,
        notificationType: item.notificationType || notificationType,
      };
    });

    await handleChange({ subscribers: selectedSubscribers });
  };

  const updateEmailSubscribers = async (subscribers) => {
    setEmailTeamMembers(
      subscribers.map((item) => {
        item.notificationType = ACTIVITY_NOTIFICATION_EMAIL;
        return item;
      })
    );
    await updateSubscribers(
      [...subscribers, ...smsTeamMembers],
      ACTIVITY_NOTIFICATION_EMAIL
    );
  };

  const updateSmsSubscribers = async (subscribers) => {
    setSmsTeamMembers(
      subscribers.map((item) => {
        item.notificationType = ACTIVITY_NOTIFICATION_SMS;
        return item;
      })
    );
    await updateSubscribers(
      [...subscribers, ...emailTeamMembers],
      ACTIVITY_NOTIFICATION_SMS
    );
  };

  const shouldBlockNavigation = Boolean(
    errorMessages.name ||
      (submissionAction === SUBMISSION_ACTIONS.URL &&
        (errorMessages.redirectUrl || !redirectUrl))
  );

  const handleBlockNavigation = (location, action) => {
    if (allowNavigation(location, action)) {
      return true;
    }

    if (shouldBlockNavigation) {
      setErrorMessages((prev) => ({
        ...prev,
        name: prev.name ? NAME_NAVIGATE_AWAY_MESSAGE(t) : prev.name,
        redirectUrl:
          submissionAction === SUBMISSION_ACTIONS.URL &&
          (prev.redirectUrl || !redirectUrl)
            ? REDIRECT_URL_NAVIGATE_AWAY_MESSAGE_EMPTY(t)
            : prev.redirectUrl,
      }));
    }

    return false;
  };

  const chosenEmailValueIds = useMemo(
    () => emailTeamMembers.map(({ value }) => value),
    [emailTeamMembers]
  );
  const chosenSMSValueIds = useMemo(
    () => smsTeamMembers.map(({ value }) => value),
    [smsTeamMembers]
  );

  const [emailNotificationSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: emailSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds: chosenEmailValueIds,
  });
  const [smsNotificationSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: smsSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds: chosenSMSValueIds,
  });

  const handleOnSwitchChange = async (ev) => {
    setPassVariablesOnRedirect(ev.target.checked);
    setRedirectParameterFieldIds([]);
    await handleChange({
      passVariablesOnRedirect: ev.target.checked,
      redirectParameterFieldIds: [],
    });
  };

  return (
    <>
      <Prompt when={shouldBlockNavigation} message={handleBlockNavigation} />
      <BackBreadcrumbToolbar backLabel={t('back to forms')} goBackPath="/forms">
        <ToolbarButtonsContainer>
          <DesktopOnlyButton
            onClick={() => confirmDeleteModal.show()}
            color="red"
          >
            {t('Delete')}
          </DesktopOnlyButton>
          <DesktopOnlyButton
            color="blue"
            onClick={() => previewForm(chosenBusinessUrl, form.slug)}
          >
            {t('Preview')}
          </DesktopOnlyButton>
          <RightButton color="blue" onClick={onExportResponses}>
            {t('Export Responses')}
          </RightButton>
        </ToolbarButtonsContainer>
      </BackBreadcrumbToolbar>
      <PageSizing>
        <StyledSettingsContentCard>
          <SubSection title={t('Form Settings')}>
            <Cols columns={1} gutter={`${gutters.spacing(4)}px`}>
              <InputControl margin>
                <FormNameInput
                  errorMessage={errorMessages.name}
                  value={name}
                  onChange={setName}
                  data-qa="form-name-input"
                  onBlur={async () => {
                    if (name === '') {
                      setErrorMessages((prev) => ({
                        ...prev,
                        name: NO_NAME_MESSAGE(t),
                      }));
                    } else {
                      await handleChange({ name });
                    }
                  }}
                />
              </InputControl>
            </Cols>
            <StyledCols columns={3} gutter={`${gutters.spacing(4)}px`}>
              <Select
                margin
                fullWidth
                label={t('Default Submission Action')}
                value={submissionAction}
                options={submissionOptions(t)}
                onChange={async (opt) => {
                  setSubmissionAction(opt.value);
                  await handleChange({ submissionAction: opt.value });
                }}
              />
              {submissionAction === SUBMISSION_ACTIONS.URL && (
                <RedirectUrlInput
                  redirectUrl={redirectUrl}
                  passVariablesOnRedirect={passVariablesOnRedirect}
                  redirectParameterFieldIds={redirectParameterFieldIds}
                  errorMessage={errorMessages.redirectUrl}
                  onTextChange={setRedirectUrl}
                  onTextBlur={handleRedirectUrlChange}
                  onSwitchChange={handleOnSwitchChange}
                  onRedirectParametersChange={handleRedirectParametersChange}
                  fields={fields}
                  loading={fieldsLoading}
                />
              )}
            </StyledCols>
            <StyleInputControlLink label={t('Hosted Form Link')} margin>
              <KizenTypography
                type="link-mail"
                target="_blank"
                href={previewLink}
              >
                {previewLink}
              </KizenTypography>
            </StyleInputControlLink>
            <InputControl label={t('Website Embed Code')} margin>
              <Notice>
                <KizenTypography>
                  {t(
                    'Use to embed your form within an existing website. Insert this code where you would like the form to appear.'
                  )}
                </KizenTypography>
              </Notice>
            </InputControl>
            <InputControl>
              <StyledLongText
                value={webApplicationTrackerEmbed(
                  business,
                  `KIZEN('embedForm', '${chosenBusinessUrl}/form/${form.slug}')`
                )}
                readOnly
              />
            </InputControl>
          </SubSection>
        </StyledSettingsContentCard>
        <SettingsContentCard>
          <SubSection title={t('Notifications')}>
            <Notice style={{ marginBottom: `${gutters.spacing(4)}px` }}>
              <KizenTypography as="span">
                {t(
                  'Notify team members through text or email each time this form is submitted.'
                )}
              </KizenTypography>
            </Notice>
            <Cols columns={2} gutter={`${gutters.spacing(4)}px`}>
              <MultiSelect
                ref={emailSelectRef}
                label={t('Notify Team Members via Email')}
                placeholder={t('Find Team Members')}
                value={emailTeamMembers}
                onChange={updateEmailSubscribers}
                margin="normal"
                {...emailNotificationSelectProps}
              />
              <MultiSelect
                ref={smsSelectRef}
                label={t('Notify Team Members via Text')}
                placeholder={t('Find Team Members')}
                value={smsTeamMembers}
                onChange={updateSmsSubscribers}
                {...smsNotificationSelectProps}
              />
            </Cols>
          </SubSection>
          <div />
        </SettingsContentCard>
      </PageSizing>
      <ConfirmDeletionModal {...confirmDeleteModalProps}>
        {t('This will permanently delete the form.')}
      </ConfirmDeletionModal>
    </>
  );
};

export default SettingsPage;
