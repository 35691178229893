import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, grayScale, dropdownColors } from '../../../app/colors';
import { gutters, layers } from '../../../app/spacing';
import Icon from '../../Kizen/Icon';
import { TextEllipsisWithTooltip } from '../../Kizen/Table';
import { variantProp } from '../props';

const Label = styled.label`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  margin-bottom: unset; // Undo global label style
  // These two ensure that the height of the parent doesn't
  // change when the box is checked versus unchecked. Just an inline sizing quirk.
  line-height: 0;
  vertical-align: top;
  > input {
    position: absolute;
    opacity: 0;
    z-index: ${layers.content(0)};
    & + * {
      flex-shrink: 0;
      .CheckboxCheckIcon,
      .CheckboxIndeterminateIcon {
        display: none;
      }
    }
    &:checked + * {
      border-color: ${colorsButton.blue.hover};
      background-color: ${colorsButton.blue.hover};
      .CheckboxCheckIcon {
        display: initial;
      }
      .CheckboxIndeterminateIcon {
        display: none;
      }
    }
    &:indeterminate + * {
      border-color: ${colorsButton.blue.hover};
      background-color: ${colorsButton.blue.hover};
      .CheckboxCheckIcon {
        display: none;
      }
      .CheckboxIndeterminateIcon {
        display: initial;
      }
    }
    &:disabled + * {
      border-color: ${grayScale.mediumDark};
      background-color: ${grayScale.white};
      svg {
        color: ${grayScale.mediumDark};
      }
    }

    &:disabled:checked + * {
      border-color: ${grayScale.mediumDark};
      background-color: ${grayScale.mediumDark};
      svg {
        color: ${grayScale.white};
      }
    }
    &:disabled + * + * {
      color: ${grayScale.mediumDark};
    }
    &:focus-visible + * {
      border-color: ${colorsButton.blue.hover};
      background: ${grayScale.white};
      svg {
        color: ${colorsButton.blue.hover};
      }
    }
    &:focus-visible:checked + *,
    &:indeterminate:checked + * {
      background-color: ${dropdownColors.hover};
    }
  }
  &:hover > input:not(:disabled) + *,
  &:focus > input:not(:disabled) + * {
    border-color: ${colorsButton.blue.hover};
    background: ${grayScale.white};
    svg {
      color: ${colorsButton.blue.hover};
    }
  }
  &:hover > input:checked:not(:disabled) + *,
  &:focus-visible > input:checked:not(:disabled) + *,
  &:indeterminate > input:checked:not(:disabled) + * {
    background-color: ${dropdownColors.hover};
  }
  ${({ error }) =>
    error &&
    css`
      > input {
        & + *,
        &:checked + *,
        &:indeterminate + * {
          border-color: ${colorsButton.red.hover};
        }
      }
      &:hover > input:not(:disabled) + * {
        border-color: ${colorsButton.red.hover};
      }
    `}
`;

const VisualCheckbox = styled.span`
  box-sizing: content-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${grayScale.mediumDark};
  background-color: ${grayScale.white};
  border-radius: 2px;
  &:not(:last-child) {
    margin-right: ${gutters.spacing(2)}px;
  }
  width: 15px;
  height: 15px;
  && svg {
    color: ${grayScale.white};
    height: auto;
  }
  && .CheckboxCheckIcon svg {
    width: 13px;
  }
  && .CheckboxIndeterminateIcon svg {
    width: 11px;
  }
  ${({ variant }) =>
    variant === 'underline' &&
    css`
      width: 12px;
      height: 12px;
      && .CheckboxCheckIcon svg {
        width: 10px;
      }
      && .CheckboxIndeterminateIcon svg {
        width: 8px;
      }
    `}
`;

export default function Checkbox({
  label,
  variant,
  error,
  indeterminate,
  onChange,
  className,
  ...props
}) {
  const inputRef = useRef();
  useEffect(() => {
    if (inputRef.current) inputRef.current.indeterminate = indeterminate;
  }, [indeterminate]);
  return (
    <Label className={className} error={error}>
      <input
        ref={inputRef}
        type="checkbox"
        onChange={(ev) => {
          if (onChange) onChange(ev.target.checked, ev);
        }}
        {...props}
      />
      <VisualCheckbox variant={variant}>
        <Icon className="CheckboxCheckIcon" icon="check" />
        <Icon className="CheckboxIndeterminateIcon" icon="minus" />
      </VisualCheckbox>
      {typeof label !== 'string' && label}
      {typeof label === 'string' && (
        <TextEllipsisWithTooltip as="span">{label}</TextEllipsisWithTooltip>
      )}
    </Label>
  );
}
Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: variantProp,
  label: PropTypes.node,
  error: PropTypes.bool,
  indeterminate: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: undefined,
  onChange: null,
  value: null,
  variant: null,
  label: PropTypes.node,
  error: null,
  indeterminate: null,
};
