import { useNavBarState } from 'app/navBarController';
import { breakpoints, useBreakpoint } from 'app/spacing';
import Logo from 'components/Kizen/Logo';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { shouldHideNavbarForPath } from 'routes';
import {
  ButtonIcon,
  HeadingWrapper,
  LogoWrapper,
  MobileWrapper,
  Wrapper,
} from './styles';
import { AuthenticatedWithBusiness } from 'routes/auth';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CustomNavigationWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const hideNav = shouldHideNavbarForPath(pathname);
  const { navBarRef } = useNavBarState();
  const isMobile = useBreakpoint(breakpoints.md);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const business = useSelector((s) => s.authentication.chosenBusiness);

  const hideKizenLogo = business?.entitlements?.toolbar_logo_suppress ?? false;

  useEffect(() => {
    if (!isMobile || hideNav) {
      setIsOpen(false);
    }
  }, [isMobile, hideNav]);

  const navigateHome = useCallback(() => {
    history.push('/');
  }, [history]);

  if (hideNav) {
    return null;
  }

  return isMobile ? (
    <MobileWrapper ref={navBarRef}>
      <HeadingWrapper>
        {hideKizenLogo ? null : (
          <LogoWrapper onClick={navigateHome}>
            <Logo height="30px" width="26px" />
          </LogoWrapper>
        )}
        <ButtonIcon icon="bars" onClick={() => setIsOpen((open) => !open)} />
      </HeadingWrapper>
      <div>{children(isOpen)}</div>
    </MobileWrapper>
  ) : (
    <Wrapper ref={navBarRef}>
      <div>
        {hideKizenLogo ? null : (
          <LogoWrapper onClick={navigateHome}>
            <Logo height="30px" width="26px" />
          </LogoWrapper>
        )}
        {children()}
      </div>
    </Wrapper>
  );
};

export default AuthenticatedWithBusiness(CustomNavigationWrapper);
