import { useNode } from '@craftjs/core';
import { useViewerContext } from '@kizen/page-builder/viewer';
import { useContext, createContext, useRef, useCallback } from 'react';

const context = createContext({
  activeNode: null,
  currentPage: 0,
  rootNode: null,
  rootWidth: null,
  form: null,
  activeTextEditorId: null, // craft id
  loadingMergeFields: false,
  mergeFields: null,
  multipleMergeFields: null,
  modalLayer: 0, // 0, 1, 2... (matches values of zIndexModalsControl in app/spacing)
  view: 'desktop', // 'desktop' | 'tablet' | 'mobile'
  gridLinesActive: false,
  resizingRowId: null,
  canvasScrollContainer: null,
  canvasWrappper: null,
  defaultPhoneRegion: null,
  resizingHeight: false,
  clearContentSettingsTray: () => undefined,
  onNodeCreate: () => undefined,
  onNodeDelete: () => undefined,
  onNodeUpdate: () => undefined,
  onNodeDuplicate: () => undefined,
  setActiveTextEditorId: () => undefined,
  setActiveNode: () => undefined,
  setResizingRowId: () => undefined,
  setCanvasScrollContainer: () => undefined,
  setCanvasWrapper: () => undefined,
  setRootNode: () => undefined,
  setResizingHeight: () => undefined,
  setView: () => undefined,
  toggleGridLines: () => undefined,
  setPreventCloseSettingsTray: () => undefined,
});

export const useBuilderContext = () => {
  return useContext(context);
};

export const useSetActiveNodeProp = () => {
  const { activeNode, setActiveNode } = useBuilderContext();

  return useCallback(
    (prop, value) => {
      if (activeNode) {
        setActiveNode({
          ...activeNode,
          data: {
            ...activeNode.data,
            props: {
              ...activeNode.data.props,
              [prop]: value,
            },
          },
        });
      }
    },
    [activeNode, setActiveNode]
  );
};

export const useSetActiveNode = () => {
  const setActiveNodeRef = useRef();
  const nodeRef = useRef();
  const activeNodeRef = useRef();
  const activeTextEditorIdRef = useRef();
  const { activeNode, activeTextEditorId, setActiveNode } = useBuilderContext();

  setActiveNodeRef.current = setActiveNode;
  nodeRef.current = useNode((n) => n);
  activeNodeRef.current = activeNode;
  activeTextEditorIdRef.current = activeTextEditorId;

  return useCallback(() => {
    const shouldNotToggleOff =
      activeNodeRef.current?.id === activeTextEditorIdRef.current &&
      nodeRef.current?.id === activeTextEditorIdRef.current;
    if (!shouldNotToggleOff) {
      const next =
        activeNodeRef.current?.id === nodeRef.current?.id
          ? null
          : nodeRef.current;
      setActiveNodeRef.current(next);
    }
  }, []);
};

const useActiveContext = () => {
  const builderCtx = useBuilderContext();
  const viewerCtx = useViewerContext();
  return Array.isArray(viewerCtx) ? builderCtx : viewerCtx;
};

export const useRootWidth = () => {
  const { rootWidth, setRootWidth } = useActiveContext();
  return [rootWidth, setRootWidth];
};

export const useRootNode = () => {
  return useActiveContext().rootNode;
};

export const useRootNodeProps = () => {
  return useActiveContext()?.rootNode?.data?.props ?? {};
};
export const useIsInBuilderContext = () => {
  const builderCtx = useBuilderContext();
  return !Array.isArray(builderCtx);
};

export default context;
