import styled from '@emotion/styled';
import { useNavBarState } from 'app/navBarController';
import Loader from 'components/Kizen/Loader';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useGenericPluginCustomScript } from 'ts-components/ScriptRunner/hooks/useGenericPluginCustomScript';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  DashboardErrorCodeText,
  DashboardWarningText,
} from 'pages/Dashboard/styles';
import { usePluginCustomHTML } from 'ts-components/Plugins/usePluginCustomHTML';
import { toastVariant, useToast } from 'components/ToastProvider';
import { PluginContext } from 'ts-components/Plugins/PluginContext';
import { useManualInteraction } from 'ts-components/Plugins/useManualInteraction';

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Container = styled.div`
  height: calc(100vh - ${(props) => props.navbarHeight}px - 2px);
  position: relative;
`;

const AbsoluteLoader = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: white;
`;

const Content = styled.div`
  width: 100%;
  height: ${(props) => (props.inline ? 'auto' : '100%')};
  position: relative;
`;

const FullPagePlugin = ({ location }) => {
  const params = useParams();
  const { t } = useTranslation();
  const [frameLoading, setFrameLoading] = useState(true);

  const { appAPIName, pageAPIName } = params;

  const scriptUIRef = useRef();

  const { pluginNames, routablePages, isLoading } = useContext(PluginContext);

  const pluginNameIsValid = useMemo(() => {
    return pluginNames.includes(appAPIName);
  }, [appAPIName, pluginNames]);

  const currentPage = useMemo(() => {
    return routablePages.find(
      (p) => p.plugin_api_name === appAPIName && p.api_name === pageAPIName
    );
  }, [appAPIName, pageAPIName, routablePages]);

  const iframeURL = currentPage?.iframe_url;
  const script = currentPage?.script;
  const callback = currentPage?.callback;
  const pluginArgs = currentPage?.args;

  const { height: navbarHeight } = useNavBarState();

  const [showToast] = useToast();

  const [execute, { pending }] = useGenericPluginCustomScript({
    onError: (e) => {
      showToast({
        message: `${t('Plugin error')}: ${e?.message}`,
        variant: toastVariant.FAILURE,
      });
    },
    scriptUIRef,
    plugin: currentPage,
  });

  const args = useMemo(() => {
    const queryString = new URLSearchParams(location.search);
    const query = Object.fromEntries(queryString);

    return {
      ...query,
      ...pluginArgs,
    };
  }, [location, pluginArgs]);

  const { outputUIRef, scopedCss, sanitizedHtml, interactableScriptRef } =
    usePluginCustomHTML(currentPage, args);

  useManualInteraction(execute, currentPage, scriptUIRef, pending);

  const hasRunScript = useRef(false);
  useEffect(() => {
    if (!iframeURL && !hasRunScript.current && script) {
      hasRunScript.current = true;
      execute(script, args);
    }
  }, [iframeURL, execute, script, args]);

  const pageTitle = `${currentPage?.plugin_name} - ${currentPage?.name}`;

  useSetTitleOnLoad(pageTitle);

  const handleMessage = useCallback(
    (e) => {
      const message = e.data;

      if (message?.type === 'kizen:plugin_callback') {
        const args = message.query;
        if (callback) {
          execute(callback, args);
        }
      }
    },
    [execute, callback]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  if (isLoading) {
    return <Loader loading />;
  }

  if (!currentPage && pluginNameIsValid) {
    return (
      <ErrorMessage
        title={t('Page Not Found')}
        text={
          <DashboardWarningText as="p">
            {t(
              'This page does not exist. Please contact your administrator if you believe this is in error.'
            )}
          </DashboardWarningText>
        }
        code={
          <DashboardErrorCodeText as="p">
            {t('Error code')}: 404
          </DashboardErrorCodeText>
        }
        dashboard
      />
    );
  }

  if (!currentPage) {
    return (
      <ErrorMessage
        title={t('Plugin Not Found')}
        text={
          <DashboardWarningText as="p">
            {t(
              'This plugin does not exist. Please contact your administrator if you believe this is in error.'
            )}
          </DashboardWarningText>
        }
        code={
          <DashboardErrorCodeText as="p">
            {t('Error code')}: 404
          </DashboardErrorCodeText>
        }
        dashboard
      />
    );
  }

  if (currentPage.type === 'iframe' && iframeURL) {
    return (
      <Container navbarHeight={navbarHeight}>
        {frameLoading ? <AbsoluteLoader loading /> : null}
        <Frame
          src={iframeURL}
          width="100%"
          height="100%"
          title={currentPage.plugin_name}
          onLoad={() => {
            setFrameLoading(false);
          }}
        />
      </Container>
    );
  }

  if (currentPage.type === 'html' && sanitizedHtml) {
    return (
      <Container navbarHeight={navbarHeight} ref={interactableScriptRef}>
        <Content dangerouslySetInnerHTML={{ __html: sanitizedHtml }} inline />
        <div ref={outputUIRef} />
        <style>{scopedCss}</style>
      </Container>
    );
  }

  return (
    <Container navbarHeight={navbarHeight}>
      {pending ? <AbsoluteLoader loading /> : null}
      <Content ref={scriptUIRef} />
      <style>{scopedCss}</style>
    </Container>
  );
};

export default function PluginFullPage() {
  const location = useLocation();

  return <FullPagePlugin key={location.key} location={location} />;
}
