import { TRow } from 'components/Kizen/Table';
import BasicTable from 'components/Tables/Basic';
import TablePagination from 'components/Tables/Big/TablePagination';
import useAsyncFnKeepLast from 'hooks/useAsyncFnKeepLast';
import { usePaginationSettings } from 'hooks/usePaginationSettings';
import { useEffect } from 'react';
import PerformActionService from 'services/PerformActionService';
import { useColumns } from './useColumns';
import { StyledPaginationWrapper, StyledTableWrapper } from './styles';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';
import CardToolbar, { CardToolbarTitle } from 'components/Layout/CardToolbar';
import { useTranslation } from 'react-i18next';
import TableScrollContainer from 'components/Tables/ScrollContainer';
import { EXPORT_IMPORT_SOURCES } from './constants';

const paginationSettings = { page: 1, pageSize: 25, search: '' };

const Table = ({ action, heading }) => {
  const { page, pageSize, handleChangePage, handleChangePageSize, headData } =
    usePaginationSettings(paginationSettings);

  const [
    { value: { results = EMPTY_ARRAY, count } = EMPTY_OBJECT, loading },
    refetch,
  ] = useAsyncFnKeepLast(
    async () =>
      PerformActionService.getBulkActionProggress(
        {
          action,
          page,
          page_size: pageSize,
        },
        { skipErrorBoundary: true }
      ),
    [page, pageSize, action]
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns = useColumns(action);

  return (
    <>
      <CardToolbar>
        <CardToolbarTitle>{heading}</CardToolbarTitle>
      </CardToolbar>
      <StyledTableWrapper>
        <TableScrollContainer bottom top gradient={<Gradient />}>
          <BasicTable
            stickyHeader
            head={<TRow head columns={columns} data={headData} />}
            staleData={loading}
          >
            {results.map((data) => (
              <TRow key={data.id} columns={columns} data={data} />
            ))}
          </BasicTable>
        </TableScrollContainer>
      </StyledTableWrapper>
      <StyledPaginationWrapper>
        <TablePagination
          page={page}
          perPage={pageSize}
          totalCount={count}
          onChangePage={handleChangePage}
          onChangePerPage={handleChangePageSize}
          small
        />
      </StyledPaginationWrapper>
    </>
  );
};

export const ExportImportTables = () => {
  const { t } = useTranslation();

  return (
    <>
      <Table
        action={EXPORT_IMPORT_SOURCES.IMPORT}
        heading={t('Recent Imports')}
      />
      <Table
        action={EXPORT_IMPORT_SOURCES.EXPORT}
        heading={t('Recent Exports')}
      />
    </>
  );
};
