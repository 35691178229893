import { Ref, forwardRef } from 'react';
import { getKDSClasses, merge } from '../util';

export interface TabGroupProps {
  children?: any;
  expand?: boolean;
}

const getWrapperClasses = (expand: boolean) => {
  if (expand) {
    return 'flex justify-around';
  }

  return 'flex';
};

export const TabGroup = forwardRef(
  (props: TabGroupProps, forwardedRef: Ref<HTMLDivElement>) => {
    const { children, expand = false } = props;

    return (
      <div
        className={merge(
          getKDSClasses('tab-group', expand ? 'expand' : ''),
          getWrapperClasses(expand),
          'items-center shrink-0'
        )}
        ref={forwardedRef}
      >
        {children}
      </div>
    );
  }
);
