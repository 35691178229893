import { useCallback, useMemo } from 'react';
import {
  useAllExternalAccounts,
  useExternalAccounts,
} from '__queries/models/teamMember';
import { isErrorStatusCode } from 'pages/Account/pages/Profile/Forms/EmailIntegration';
import { getValueFullNameWithEmail } from 'services/helpers';

const EMPTY_ARRAY: any = [];

type TeamMember = {
  id: string;
  name: string;
};

type IntegratedInbox = {
  created: string;
  id: string;
  email: string;
  emailStatus: number;
  provider: number;
  teamMember: TeamMember;
};

type Account = {
  id: string;
  email: string;
  teamMember: TeamMember;
};

type AccountSelectOptions = {
  value: string;
  label: string;
};

export const useIntegratedInbox = (
  useAllIntgegratedInboxes: boolean = false
) => {
  const {
    data: all_accounts = EMPTY_ARRAY,
    isLoading: is_loading_all_accounts,
  } = useAllExternalAccounts(
    { ordering: 'team_member__name' },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: useAllIntgegratedInboxes,
    }
  );
  const {
    data: your_accounts = EMPTY_ARRAY,
    isLoading: is_loading_your_accounts,
  } = useExternalAccounts({
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !useAllIntgegratedInboxes,
  });

  const { accounts = EMPTY_ARRAY, isLoadingAccounts } = useMemo(
    () =>
      useAllIntgegratedInboxes
        ? { accounts: all_accounts, isLoadingAccounts: is_loading_all_accounts }
        : {
            accounts: your_accounts,
            isLoadingAccounts: is_loading_your_accounts,
          },
    [
      useAllIntgegratedInboxes,
      all_accounts,
      is_loading_all_accounts,
      your_accounts,
      is_loading_your_accounts,
    ]
  );

  const accountSelectOptions: AccountSelectOptions[] = useMemo(
    () =>
      accounts
        .filter(
          ({ emailStatus }: IntegratedInbox) => !isErrorStatusCode(emailStatus)
        )
        .map((account: Account) =>
          getValueFullNameWithEmail({
            id: account.id,
            fullName: account.teamMember.name,
            email: account.email,
          })
        ),
    [accounts]
  );

  const findAccountById = useCallback(
    (id: string) => accountSelectOptions.find(({ value }) => value === id),
    [accountSelectOptions]
  );

  return {
    accounts,
    isLoadingAccounts,
    accountSelectOptions,
    findAccountById,
  };
};
