import { useContext } from 'react';
import { getKDSClasses, merge } from '../util';
import { TileContext } from './context';
import { Spacing } from '../spacing';

export interface PanelProps {
  children: any;
  expand?: boolean;
  padding?: [Spacing, Spacing, Spacing, Spacing] | Spacing;
}

const getSpacingDirection = (
  orientation: 'left' | 'right' | 'top' | 'bottom'
) => {
  switch (orientation) {
    case 'left':
      return 'pl';
    case 'right':
      return 'pr';
    case 'top':
      return 'pt';
    case 'bottom':
      return 'pb';
  }
};

const getSpacingClassName = (
  spacing: Spacing,
  orientation: 'left' | 'right' | 'top' | 'bottom'
) => {
  const direction = getSpacingDirection(orientation);
  return `${direction}-spacer-${spacing}`;
};

const getSpacingClassNames = (sides: [Spacing, Spacing, Spacing, Spacing]) => {
  return merge(
    getSpacingClassName(sides[0], 'top'),
    getSpacingClassName(sides[1], 'right'),
    getSpacingClassName(sides[2], 'bottom'),
    getSpacingClassName(sides[3], 'left')
  );
};

export const Panel = (props: PanelProps) => {
  const { orientation } = useContext(TileContext);
  const { children, expand, padding } = props;

  const verticalClasses = 'w-full';
  const horizontalClasses = 'h-full';

  let spacingSides: [Spacing, Spacing, Spacing, Spacing];
  if (Array.isArray(padding)) {
    spacingSides = padding;
  } else if (typeof padding !== 'undefined') {
    spacingSides = [padding, padding, padding, padding];
  } else {
    spacingSides = [0, 0, 0, 0];
  }

  const spacingClasses = getSpacingClassNames(spacingSides);

  const classes = merge(
    getKDSClasses('tile-panel', orientation),
    'flex flex-col relative min-h-0',
    expand ? 'flex-grow' : '',
    orientation === 'horizontal' ? horizontalClasses : verticalClasses,
    spacingClasses
  );

  return <div className={classes}>{children}</div>;
};
