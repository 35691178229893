import React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { breakpoints } from 'app/spacing';
import Select from 'components/Inputs/Select';

const StyledSelect = styled(Select)`
  position: absolute;
  top: -56px;
  left: calc(50% + 320px); // centered
  width: 300px;

  @media (max-width: ${breakpoints.xl}px) {
    width: 156px;
  }
`;

const ObjectSelect = ({ options, handleChange, chosenObject, ...rest }) => {
  const { t } = useTranslation();

  return (
    <StyledSelect
      options={options}
      onChange={handleChange}
      value={chosenObject}
      placeholder={t('Choose Object')}
      {...rest}
    />
  );
};

ObjectSelect.propTypes = {
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  chosenObject: PropTypes.object.isRequired,
};

export default ObjectSelect;
