import React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDebouncedPastScrollThreshold } from 'hooks/useDebouncedWindowScroll';
import { css } from '@emotion/core';
import { shadowLight, grayScale, tableHover } from 'app/colors';
import { gutters, layers } from 'app/spacing';
import { PageToolbarTitle } from '../PageToolbar';
import { useNavBarState } from 'app/navBarController';

// The toolbar will size itself to 46px + 36px = 82px, and this leaves
// about 15px beyond that, which leaves some room below the toolbar when
// the curtain appears. There should be 20px (> 15px) of room between
// the natural toolbar height and the header below;
const CURTAIN_WITHOUT_TOOLBAR_HEIGHT = '46px';
// The search bar 36px + 15 padding bottom
const CURTAIN_SEARCH_HEIGHT = '51px';
const num = (px) => parseInt(px, 10);

const Wrapper = styled.div`
  position: relative;
`;

const PageSizing = styled.div`
  padding: 0 ${gutters.spacing(4)}px;
  display: flex;
  flex-direction: column;
  min-height: calc(
    100vh - ${({ navHeight }) => navHeight}px
  ); // Everything except header
`;

const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: ${layers.content(
    0,
    1
  )}; // TODO(maybe remove?) Ensure contents incl menus appear on top of table header
`;

export const BigListTitle = styled((props) => (
  <PageToolbarTitle type="subheader-light" {...props} />
))`
  text-align: center;
  margin: ${gutters.spacing(4, -3)}px;
  margin-bottom: ${gutters.spacing(3)}px;
`;

const ScrollCurtainWrapper = styled.div`
  position: sticky;
  top: 0;
  height: 0;
  width: 100%;
  z-index: ${layers.content(0, 1)};
  overflow: visible;
  display: none;
  @supports (position: sticky) {
    display: block;
  }
`;

const calculateHeight = (props) => {
  let height = 0;
  if (props.toolbar) height += num(CURTAIN_WITHOUT_TOOLBAR_HEIGHT);
  if (props.search)
    height +=
      height > 0
        ? num(CURTAIN_SEARCH_HEIGHT)
        : num(CURTAIN_SEARCH_HEIGHT) + num(gutters.spacing(3));

  return height;
};

const ScrollCurtain = styled.div`
  width: 100%;
  height: ${(props) => calculateHeight(props)}px;
  box-sizing: content-box; // So border pokes out from under.
  border-bottom: 1px solid transparent;
  background-color: ${tableHover};
  transition: 0.5s ease-in-out;
  transition-property: box-shadow, background-color, border;
  ${({ scrolled }) =>
    scrolled &&
    css`
      ${shadowLight};
      background-color: ${grayScale.white};
      border-color: ${grayScale.mediumLight};
    `};
`;

const PaginationWrapper = styled.div`
  margin-bottom: ${gutters.spacing(2)}px;
`;

const ToolbarSpacer = styled.div`
  height: ${gutters.spacing(3)}px;
`;

export default function BigListLayout({
  toolbar,
  search,
  pagination,
  children,
  ...others
}) {
  const { height } = useNavBarState();
  const scrolled = useDebouncedPastScrollThreshold();

  return (
    <Wrapper {...others}>
      <ScrollCurtainWrapper>
        <ScrollCurtain search={search} toolbar={toolbar} scrolled={scrolled} />
      </ScrollCurtainWrapper>
      <PageSizing navHeight={height}>
        <ToolbarWrapper>
          {toolbar || <ToolbarSpacer className="toolbar-spacer" />}
          {search}
        </ToolbarWrapper>
        {children} {/* likely title then lists */}
        {pagination && (
          <PaginationWrapper>
            {React.cloneElement(pagination, { small: true })}
          </PaginationWrapper>
        )}
      </PageSizing>
    </Wrapper>
  );
}

BigListLayout.propTypes = {
  toolbar: PropTypes.element,
  search: PropTypes.element.isRequired,
  pagination: PropTypes.element,
};

BigListLayout.defaultProps = {
  toolbar: null,
  pagination: null,
};
