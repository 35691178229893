import React, { useState } from 'react';
import styled from '@emotion/styled';
import { KizenTypography } from 'app/typography';
import ImageSelector from 'components/Kizen/GridSelector';
import { gutters } from 'app/spacing';

const StyledText = styled(KizenTypography)`
  margin: ${gutters.standard} 0 ${gutters.standard} 0;
`;

const GridSelector = () => {
  const [selected, setSelected] = useState(false);

  return (
    <>
      <div>
        <StyledText type="subheader" weight="light">
          Default
        </StyledText>
        <ImageSelector
          label="Activities"
          value="default"
          icon="sticky-note"
          selected={selected}
          onChange={() => setSelected(!selected)}
        />
      </div>
      <div>
        <StyledText type="subheader" weight="light">
          Selected
        </StyledText>
        <ImageSelector
          selected
          label="Activities"
          value="selected"
          icon="sticky-note"
          onChange={() => {}}
        />
      </div>
      <div>
        <StyledText type="subheader" weight="light">
          Error
        </StyledText>
        <ImageSelector
          error
          label="Activities"
          value="error"
          icon="sticky-note"
          onChange={() => {}}
        />
      </div>
    </>
  );
};

export default GridSelector;
