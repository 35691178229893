import React, { useState, useEffect } from 'react';
import { useWindowScroll } from 'react-use';
import * as PropTypes from 'prop-types';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { colorsButton, grayScale } from 'app/colors';
import { ICON_BUTTON_SIZING } from 'components/Kizen/IconButton';
import { fontSizes } from 'app/typography';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import {
  MobileStickyNavBar,
  MobileNavBarWrapper,
  MobileTopBlock,
  MobileBottomBlock,
  MobileTopBlockActions,
} from './styles';
import DateRangePicker from 'components/Kizen/DateRangePicker';
import { StyledSubNavLabel } from '../SubNav/styles';
import EditDashboardsButton from '../EditDashboardsButton';
import TeamRoleSelector from '../TeamRoleSelector';
import { DEFAULT_DATE_RANGE } from 'components/DashboardGrid/utils';
import { useTranslation } from 'react-i18next';

const maxWidthForSubNav = 175;

const StyledIconButton = styled(IconButton)`
  margin: 0 ${gutters.spacing(1)}px;
`;
const StyledIconButtonLast = styled(IconButton)`
  margin-left: ${gutters.spacing(1)}px;
`;

const mobileMainSelectorStyles = {
  input: () => ({
    '> :first-child': {
      display: 'inline-flex !important',
      position: 'absolute',
      width: '100%',
      top: -4,
    },
    // need to specify input in order to get font size correct
    // also need !important to override inline styles that are applied
    input: {
      fontSize: `${fontSizes.subheader} !important`,
      fontWeight: '400',
      width: '0 !important',
      flexGrow: 1,
      paddingBottom: '2px !important',
    },
  }),
};

const MobileNavigation = ({
  dashboardsToShow = [],
  currentDashboard = null,
  setDashboard,
  triggerAddDashletProps,
  teamFilter = {},
  handleChangeDate,
  dateFilter = {},
  handleClickEditDashboards,
  showSettings = true,
  hasEditPermission,
  roleOptions,
  onChangeGroupedValue,
  missing,
  unavailable,
  ranges,
  todayDateInBusinessTimeZone,
}) => {
  const { t } = useTranslation();
  const [sticky, setSticky] = useState(false);
  const [mobileBottomNav, setMobileBottomNav] = useState(true);
  const [isSubMenuBtnOn, setIsSubMenuBtnOn] = useState(false);
  const { y } = useWindowScroll();

  const toggleMobileBottomNav = () => {
    setMobileBottomNav((prevState) => {
      setIsSubMenuBtnOn(!prevState);
      return !prevState;
    });
  };

  useEffect(() => {
    const isScrolled = !!y;
    setSticky(isScrolled);
    if (!isScrolled) {
      setMobileBottomNav(true);
    } else if (!isSubMenuBtnOn) {
      setMobileBottomNav(false);
    }
  }, [y, isSubMenuBtnOn]);

  return (
    <MobileStickyNavBar show={mobileBottomNav}>
      <MobileNavBarWrapper missing={missing} unavailable={unavailable}>
        <MobileTopBlock>
          {dashboardsToShow.length > 0 && !missing && !unavailable ? (
            <StyledSubNavLabel
              options={dashboardsToShow}
              value={currentDashboard}
              onChange={setDashboard}
              maxWidth={maxWidthForSubNav}
              styles={mobileMainSelectorStyles}
              components={
                showSettings
                  ? {
                      Menu: EditDashboardsButton,
                    }
                  : undefined
              }
              classNamePrefix="dashboard-menu"
              buildHref={(id) => `/dashboard/${id}`}
            />
          ) : (
            <StyledSubNavLabel
              options={dashboardsToShow}
              value={{
                name: unavailable ? t('Unavailable') : t('None'),
              }}
              onChange={setDashboard}
              handleClickEditDashboards={handleClickEditDashboards}
              components={
                showSettings
                  ? {
                      Menu: EditDashboardsButton,
                    }
                  : undefined
              }
              classNamePrefix="dashboard-menu"
              buildHref={(id) => `/dashboard/${id}`}
            />
          )}

          {!missing && !unavailable ? (
            <MobileTopBlockActions>
              {sticky ? (
                <StyledIconButton
                  sizing={ICON_BUTTON_SIZING.overlay}
                  borderColor={grayScale.medium}
                  onClick={toggleMobileBottomNav}
                >
                  <Icon
                    icon="filter"
                    noCustom
                    color={
                      isSubMenuBtnOn
                        ? colorsButton.iconGray.hover
                        : colorsButton.iconGray.default
                    }
                  />
                </StyledIconButton>
              ) : null}
              {currentDashboard && hasEditPermission ? (
                <>
                  <StyledIconButtonLast
                    sizing={ICON_BUTTON_SIZING.overlay}
                    borderColor={grayScale.medium}
                    {...triggerAddDashletProps}
                  >
                    <Icon icon="plus" />
                  </StyledIconButtonLast>
                </>
              ) : null}
            </MobileTopBlockActions>
          ) : null}
        </MobileTopBlock>
        {!missing && !unavailable ? (
          <MobileBottomBlock show={mobileBottomNav}>
            <DateRangePicker
              key={todayDateInBusinessTimeZone}
              defaultRange={DEFAULT_DATE_RANGE}
              onOk={handleChangeDate}
              placement={{ left: 0 }}
              value={
                dateFilter
                  ? [dateFilter.start, dateFilter.end]
                  : DEFAULT_DATE_RANGE
              }
              ranges={ranges}
              todayDate={todayDateInBusinessTimeZone}
            />
            <TeamRoleSelector
              roleOptions={roleOptions}
              onChange={onChangeGroupedValue}
              value={teamFilter}
              mobile
            />
          </MobileBottomBlock>
        ) : null}
      </MobileNavBarWrapper>
    </MobileStickyNavBar>
  );
};

MobileNavigation.propTypes = {
  dashboardsToShow: PropTypes.arrayOf(PropTypes.object),
  currentDashboard: PropTypes.object,
  setDashboard: PropTypes.func.isRequired,
  triggerAddDashletProps: PropTypes.object.isRequired,
  setTeamFilter: PropTypes.func.isRequired,
  teamFilter: PropTypes.object,
  teamMemberOptions: PropTypes.array,
  handleChangeDate: PropTypes.func,
  dateFilter: PropTypes.object,
  handleClickEditDashboards: PropTypes.func,
  hasEditPermission: PropTypes.bool,
};

export default MobileNavigation;
