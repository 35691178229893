import { createAction, createSlice } from '@reduxjs/toolkit';
import { isFilterSet, isFilterSetArray } from '@kizen/filters/filter-sets';
import { v4 as uuidv4 } from 'uuid';
import { doFilterSetsHaveError } from 'ts-filters/utils';
import { initialStore } from '../initialStore';

const rmNegativeValues = (object) => {
  return Object.keys(object).reduce(
    (acc, el) => (object[el] ? { ...acc, [el]: object[el] } : acc),
    {}
  );
};

const countFilters = (filters) => {
  if (isFilterSetArray(filters)) {
    return filters.flatMap((q) => q.filters).length;
  }
  if (isFilterSet(filters)) {
    return filters.filters.length;
  }
  return 0;
};

// automations
export const getAutomations = createAction(
  'automationsPage/GET_AUTOMATIONS',
  function prepare({ updatePageConfig = true } = {}) {
    return {
      payload: {
        updatePageConfig,
      },
    };
  }
);
export const deleteAutomation = createAction(
  'automationPage/DELETE_AUTOMATION'
);
export const deleteAutomationFail = createAction(
  'automationPage/DELETE_AUTOMATION_FAIL'
);
export const duplicateAutomation = createAction(
  'automationPage/DUPLICATE_AUTOMATION'
);
export const duplicateAutomationFail = createAction(
  'automationPage/DUPLICATE_AUTOMATION_FAIL'
);
// createOrUpdateGroup
export const createOrUpdateGroup = createAction(
  'automationPage/CREATE_OR_UPDATE_GROUP'
);
export const createOrUpdateGroupFail = createAction(
  'automationPage/CREATE_OR_UPDATE_GROUP_FAIL'
);
// removeGroup
export const removeGroup = createAction('automationPage/REMOVE_GROUP');
export const removeGroupFail = createAction('automationPage/REMOVE_GROUP_FAIL');
// setPageConfig
export const setPageConfig = createAction('automationPage/SET_PAGE_CONFIG');
export const setPageConfigSuccess = createAction(
  'automationPage/SET_PAGE_CONFIG_SUCCESS'
);
export const setPageConfigFail = createAction(
  'automationPage/SET_PAGE_CONFIG_FAIL'
);

export const changeGroupAction = createAction('automationPage/CHANGE_GROUP');

const generatePageConfig = (fromApi, fromState) => {
  const defaultPageConfigWhichMustBe = {
    search: '',
    sort: 'created',
    group: null,
    page: 1,
    size: 50,
    filterObject: {},
    queryFilter: null,
  };

  return Object.keys(defaultPageConfigWhichMustBe).reduce((acc, el) => {
    const compare = (property) => {
      const propertyWhichShouldBeAlwaysFromBackEnd = ['size', 'sort', 'search'];

      // highest priority from url string
      if (fromState[property]) return fromState[property];

      // check extra fields
      if (propertyWhichShouldBeAlwaysFromBackEnd.includes(property)) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      return defaultPageConfigWhichMustBe[property];
    };

    return { ...acc, [el]: compare(el) };
  }, {});
};

// Slices
const defaultState = initialStore.automationPage;

export const automationPageSlice = createSlice({
  name: 'automationPage',
  initialState: defaultState,
  reducers: {
    buildPage: (state, action) => {
      const { page } = action.payload;
      state.isFetching = true;
      state.pageConfig = {
        ...state.pageConfig,
        ...rmNegativeValues(page),
      };
    },
    buildPageComplete: (state, { payload }) => {
      const { models, groupsResponse, pageResponse, filters, t } = payload;
      const groups = [{ value: 'none', label: t('All Automations') }].concat(
        groupsResponse
          .map((el) => ({ ...el, value: el.id, label: el.name }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
      const pageConfig = generatePageConfig(pageResponse, state.pageConfig);
      const groupName =
        groups.find((g) => g.id === pageConfig.group)?.name ?? '';

      state.groups = groups;
      state.models = models;
      state.pageConfig = pageResponse;
      state.filters = {
        ...state.filters,
        config: filters,
        numberOfFilters: countFilters(filters),
        name: groupName,
      };
    },
    buildPageFinish: (state) => {
      state.isFetching = false;
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    getAutomationsSuccess: (state, { payload }) => {
      const { automationsCount, automations } = payload;
      state.automationsCount = automationsCount;
      state.automations = automations;
      state.filters.errors = null;
    },
    updatePageConfig: (state, { payload }) => {
      state.pageConfig = { ...state.pageConfig, ...payload };
    },
    updatePageConfigBySearch: (state, { payload }) => {
      state.pageConfig = { ...state.pageConfig, ...payload };
    },
    setToast: (state, { payload }) => {
      const { variant, message } = payload;
      state.toastData = {
        variant,
        message,
      };
    },
    cleanToast: (state) => {
      state.toastData = null;
    },
    updateAutomation: (state, { payload }) => {
      state.automations = state.automations.map((item) =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
    },
    createOrUpdateGroupSuccess: (state, { payload }) => {
      if (state.pageConfig.group) {
        state.groups = state.groups.map((el) =>
          el.id === payload.id
            ? { ...payload, value: payload.id, label: payload.name }
            : el
        );
        return;
      }

      const [allOption, ...rest] = state.groups;

      const groups = rest
        .concat({ ...payload, value: payload.id, label: payload.name })
        .sort((a, b) => a.name.localeCompare(b.name));

      state.groups = [allOption].concat(groups);
      state.pageConfig.group = payload.id;
      state.pageConfig.page = 1;
      state.pageConfig.filterObject = {};
      state.groupCount = state.automationsCount;
    },
    removeGroupSuccess: (state, { payload }) => {
      const { id } = payload;
      state.groups = state.groups.filter((el) => el.id !== id);
      state.filters = {
        ...initialStore.automationPage.filters,
        key: uuidv4(),
      };
      state.pageConfig.group = null;
      state.pageConfig.page = 1;
      state.pageConfig.filterObject = {};
    },
    setCreateOrUpdateGroupFail: (state, { payload }) => {
      state.groupErrors = payload;
    },
    clearGroupError: (state) => {
      state.groupErrors = null;
    },
    setFilterName: (state, { payload }) => {
      const { name } = payload;
      state.filters.name = name;
    },
    getAutomationsStart: (state) => {
      state.isFiltering = true;
    },
    getAutomationsFinish: (state) => {
      state.isFiltering = false;
    },
    setNumberOfFilters: (state, { payload: numberOfFilters }) => {
      state.filters.numberOfFilters = numberOfFilters;
    },
    resetFilters: (state) => {
      state.pageConfig.group = null;
      state.pageConfig.filterObject = {};
      state.filters = {
        ...initialStore.automationPage.filters,
        key: uuidv4(),
      };
    },
    setFilters: (state, { payload: filters }) => {
      state.filters.config = filters;
      state.filters.key = uuidv4();
      state.filters.numberOfFilters = countFilters(filters);
    },
    setFilterErrors: (state, { payload: errors }) => {
      state.filters.errors = errors;
    },
    setGroupId: (state, { payload: id }) => {
      state.pageConfig.group = id;
    },
  },
});

export const {
  buildPage,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  getAutomationsSuccess,
  updatePageConfig,
  updatePageConfigBySearch,
  setToast,
  cleanToast,
  updateAutomation,
  createOrUpdateGroupSuccess,
  setCreateOrUpdateGroupFail,
  removeGroupSuccess,
  setFilterName,
  getAutomationsStart,
  getAutomationsFinish,
  setNumberOfFilters,
  resetFilters,
  setFilters,
  setFilterErrors,
  setGroupId,
  clearGroupError,
} = automationPageSlice.actions;

export const { reducer } = automationPageSlice;

// Selectors
export const selectAutomationsIsFiltering = (state) =>
  state.automationPage.isFiltering;

export const selectHasFilterError = (state) => {
  const filters = state.automationPage.filters.config;
  const errors = state.automationPage.filters.errors;

  if (!filters || filters.query) {
    return false;
  }

  const errorsWhenLoadingSteps = doFilterSetsHaveError(filters);
  const errorsWhenPerformingSearch = Boolean(
    errors?.parsedErrors.some(Boolean)
  );

  return errorsWhenLoadingSteps || errorsWhenPerformingSearch;
};
