import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useDebounce } from 'react-use';
import { FORCE_ALL_RECORDS_SIZE, objectNameToOption } from 'services/helpers';

const getFetchParams = (paramFormatter, search, useClient, page) => {
  if (paramFormatter) {
    const result = paramFormatter(search);
    if (Array.isArray(result)) {
      return result;
    }
    return [result];
  }

  if (useClient) {
    return [{ size: FORCE_ALL_RECORDS_SIZE }];
  }

  return [{ search, page }];
};

const useReactQueryTypeaheadInfinite = (
  fetch,
  queryKey = [],
  config = {},
  dependencies = [],
  debounceDelay = 0
) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { t } = useTranslation();

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    debounceDelay,
    [search]
  );

  const {
    optionMapper = objectNameToOption,
    paramFormatter = null,
    useClient = false,
    addOptions = [],
    enabled = true,
    allowEmpty = true,
    preserveSearch = false,
    onResultsChange,
    keepPreviousData = false,
  } = config;

  const {
    data,
    isLoading,
    isRefetching,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      ...queryKey,
      ...dependencies,
      useClient ? undefined : debouncedSearch,
    ].filter(Boolean),
    ({ pageParam }) =>
      fetch(
        ...getFetchParams(paramFormatter, debouncedSearch, useClient, pageParam)
      ),
    {
      enabled: (allowEmpty ? true : Boolean(debouncedSearch)) && enabled,
      keepPreviousData,
      getNextPageParam: (lastPage) => {
        return lastPage?.next
          ? new URL(lastPage.next).searchParams.get('page')
          : undefined;
      },
      getPreviousPageParam: (previousPage) => {
        return previousPage?.prev
          ? new URL(previousPage.prev).searchParams.get('page')
          : undefined;
      },
    }
  );

  const options = useMemo(() => {
    const results =
      data?.pages.flatMap((x) => {
        return x?.results;
      }) || [];

    if (useClient) {
      return results
        .map(optionMapper)
        .concat(addOptions)
        .sort((a, b) => a.label.localeCompare(b.label));
    }

    return results.map(optionMapper);
  }, [data, optionMapper, addOptions, useClient]);

  useEffect(() => {
    onResultsChange?.();
  }, [options, onResultsChange]);

  const loading = isLoading || isRefetching || isFetching;

  return {
    selectProps: {
      onInputChange: (v, { action }) => {
        // We don't want to clear the search when the user selects a value
        if (action === 'set-value' && preserveSearch) {
          return;
        }
        setSearch(v);
        onResultsChange?.();
      },
      noOptionsMessage: () => {
        if (!search && !allowEmpty) {
          return t('Begin Typing to Search');
        }
        return t('No Options');
      },
      inputValue: search,
      options,
      isLoading: loading,
      fetchNextPage,
      isFetchingNextPage,
    },
    results: data?.results ?? [],
    isLoading: loading,
  };
};

export default useReactQueryTypeaheadInfinite;
