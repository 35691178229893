import styled from '@emotion/styled';
import { grayScale, shadowLight } from 'app/colors';
import { gutters } from 'app/spacing';
import Button from 'components/Button';
import { SettingsContentCard } from 'components/Card/Card';

export const StyledSettingsContentCard = styled(SettingsContentCard)`
  height: calc(
    100vh - ${({ navBarHeight }) => navBarHeight + 1}px - 112px - 20px
  );
  min-height: 500px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const NavBarPreviewArea = styled.div`
  border: 1px solid ${grayScale.mediumLight};
  ${shadowLight}
  margin-top: 20px;
  padding-left: 20px;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 13px;
`;

export const StyledButtonWrapper = styled.div`
  margin-left: auto;
  margin-top: -5px;
  display: flex;
  column-gap: ${gutters.spacing(2)}px;
`;

export const StyledButton = styled(Button)`
  margin: 0;
`;
