import { head, tail } from 'utility/snippets';
import DraggableStepCard from '../../DraggableStepCard';
import {
  mreChangeTypeOptionsConst,
  fieldRequiresWizard,
  fieldToModifyfieldType,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/ModifyRelatedEntities/common';
import { emptyLongDash } from 'components/Inputs/DateTimeInput/helpers';
import { getFieldValue, getValueOrDeleted } from './action-helpers';

export const content = ({ step }) => step.description;

const getDisplayValue = (relatedField, t) => {
  const payload = {
    fieldToModify: relatedField?.fieldToModify?.fieldData,
    specificFieldValue: relatedField?.specificFieldValue,
  };

  return getFieldValue(payload, t);
};

const getEnteredValue = (enteredValue) =>
  enteredValue || enteredValue === 0 ? enteredValue : emptyLongDash; // treat 0 as not empty;

const _colon = ':';

export const getDescription = ({ step, automation, t }) => {
  const { details } = step;
  const { automationTargetRelationshipFields, fieldsToModify, objectToModify } =
    details;
  const fieldsToModifyHead = head(fieldsToModify);
  const fieldsToModifyTail = tail(fieldsToModify);

  const relatedObjectName = getValueOrDeleted(objectToModify?.label, t);
  const relatedEntityName = getValueOrDeleted(objectToModify?.entityName, t);
  const relatedFieldName = getValueOrDeleted(
    fieldsToModifyHead?.fieldToModify?.label,
    t
  );
  const relationshipFieldNames = automationTargetRelationshipFields?.length
    ? `${automationTargetRelationshipFields
        .map(({ label }) => label)
        .join(', ')}`
    : t('[Deleted]');

  const enteredValue = getEnteredValue(getDisplayValue(fieldsToModifyHead, t));
  const objectName = automation?.customObject?.objectName;
  const fieldName = getValueOrDeleted(
    fieldsToModifyHead?.contextEntityField?.label,
    t
  );

  const requiresWizardType = fieldRequiresWizard(
    fieldToModifyfieldType(fieldsToModifyHead?.fieldToModify)
  );

  return `${t(
    "Change Related Entity '{{relatedObjectName}}{{_colon}} {{relatedEntityName}}' ‘{{relatedFieldName}}’ Field on '{{relationshipFieldNames}}' to ",
    {
      relatedObjectName,
      relatedEntityName,
      relatedFieldName,
      relationshipFieldNames,
      _colon,
    }
  )}${
    fieldsToModifyHead?.valueType?.value ===
    mreChangeTypeOptionsConst.SPECIFIC_VALUE
      ? t("'{{enteredValue}}'", {
          enteredValue,
        })
      : requiresWizardType
        ? `${t('Value(s) from ‘{{objectName}}{{_colon}} {{fieldName}}’ Field', {
            objectName,
            fieldName,
            _colon,
          })}`
        : `${t('Value of ‘{{objectName}}{{_colon}} {{fieldName}}’ Field', {
            objectName,
            fieldName,
            _colon,
          })}`
  }${
    fieldsToModifyTail.length > 0
      ? t(', and {{fieldCount}} Additional Field Updates', {
          fieldCount: fieldsToModifyTail.length,
        })
      : ''
  }`;
};

export default function ModifyRelatedEntitiesCard({ step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
