import {
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
  useMemo,
} from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import EditableText from 'components/Kizen/EditableText';
import IconButton from 'components/Kizen/IconButton';
import ClickAway from '../ClickAway';
import ViewDisplay from './ViewDisplay';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { applyRef } from '../../props';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const EditWrapper = styled(ClickAway)`
  display: flex;
  align-items: flex-start;
  // This is the size of the bottom padding on EditableText.
  // They should match so that there is no jitter switching between text and the input.
  margin-bottom: -7px;
  > div {
    display: flex !important; // Override inline style from react-input-autosize
    overflow: hidden;
  }
  input {
    padding-left: 0;
    padding-right: ${({ showIcon }) =>
      showIcon ? 'calc(12px + 10px)' : 0}; // width + margin
    min-width: 30px;
  }
  && input[disabled] {
    background: unset;
    color: ${grayScale.mediumDark};
  }
  && > button {
    margin-top: 1px; // nudge
    margin-left: calc(-12px + -2px); // width + nudge
    width: 12px;
    svg {
      width: 12px;
      height: auto;
    }
  }
`;

const ShortTextDisplay = forwardRef(
  (
    {
      shouldFocusNext,
      field,
      type,
      to,
      editing: editingProp,
      onEditing,
      value,
      onChange,
      onSubmit,
      autoFocus = null,
      onAutoFocus = null,
      emptyPlaceholder,
      error = false,
      onClick,
      hasMobileView = false,
      object,
      ...others
    },
    ref
  ) => {
    const fieldId = useMemo(
      () => `${field?.id}-${object?.id}`,
      [field, object]
    );
    const { t } = useTranslation();
    const editWrapperRef = useRef();
    const [editingInternal, setEditingInternal] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const isMobileView = hasMobileView && isMobile;
    const inputRef = useRef();
    const mergeRef = useCallback(
      (el) => {
        applyRef(editWrapperRef, el);
        applyRef(ref, el);
      },
      [ref]
    );

    const focusInputRef = useCallback(
      (el) => {
        if (el && !isMobileView) {
          el.focus();
          el.setSelectionRange(0, 0);
          applyRef(ref, el);
          applyRef(inputRef, el);
        }
      },
      [isMobileView, ref]
    );

    // Support controlled and uncontrolled editing
    const editing =
      typeof editingProp === 'undefined' ? editingInternal : editingProp;
    const setEditing =
      typeof onEditing === 'undefined' ? setEditingInternal : onEditing;
    const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();
    const onAutoFocusRef = useRef();
    onAutoFocusRef.current = onAutoFocus;

    useEffect(
      () => {
        if (autoFocus && !assignFieldHandle) {
          setEditing(true);
          if (onAutoFocusRef.current)
            onAutoFocusRef.current(editWrapperRef.current);
        }
      },
      // Don't worry about setEditing changing, only autoFocus switching "on".
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [autoFocus]
    );
    const handleFocus = () => {
      if (isMobileView) setEditing(true);
    };

    assignFieldHandle(fieldId, {
      customTab: async () => {
        const res = await onSubmit(value);
        setEditing(!res);
        return !res;
      },
      customFocus: () => {
        setEditing(true);
        editWrapperRef.current?.focus();
        return inputRef.current;
      },
      customEnter: async () => {
        const res = await onSubmit(value);
        setEditing(!res);
        return !res;
      },
      shouldFocusNext: shouldFocusNext,
    });

    if (editing || isMobileView) {
      const handleSubmit = async () => {
        try {
          setSubmitting(true);
          await onSubmit(value);
          setEditing(false);
        } finally {
          setSubmitting(false);
        }
      };

      return (
        <EditWrapper
          ref={mergeRef}
          onClickAway={() => {
            if (!submitting || isMobileView) {
              setEditing(false);
              handleSubmit();
            }
          }}
          {...others}
          {...getKeyListenersProps?.(fieldId)}
          showIcon={!isMobileView}
        >
          <EditableText
            inputRef={focusInputRef}
            disabled={submitting}
            value={value}
            onChange={onChange}
            error={error}
            {...getKeyListenersProps?.(fieldId)}
            onFocus={(e) => {
              getKeyListenersProps?.(fieldId)?.onFocus(fieldId);
              handleFocus(e);
            }}
          />
          {isMobileView ? null : (
            <IconButton
              sizing="dense"
              disabled={submitting}
              title={t('Save')}
              color={
                submitting
                  ? colorsButton.iconGray.default
                  : colorsButton.iconGray
              }
              onClick={async () => {
                await handleSubmit();
                getKeyListenersProps?.(fieldId)?.onBlur(fieldId, true);
              }}
            >
              <Icon icon="check" />
            </IconButton>
          )}
        </EditWrapper>
      );
    }

    return (
      <ViewDisplay
        ref={mergeRef}
        type={type}
        to={to}
        value={value}
        onClickEditButton={() => {
          setEditing(true);
          getKeyListenersProps?.(fieldId)?.onFocus(fieldId);
        }}
        emptyPlaceholder={emptyPlaceholder}
        onClick={onClick}
        {...others}
      />
    );
  }
);

export default ShortTextDisplay;

ShortTextDisplay.propTypes = {
  type: PropTypes.string,
  to: PropTypes.string,
  editing: PropTypes.bool,
  onEditing: PropTypes.func,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  onAutoFocus: PropTypes.func,
  emptyPlaceholder: PropTypes.string,
  error: PropTypes.bool,
  hasMobileView: PropTypes.bool,
};
