import React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';
import Icon from 'components/Kizen/Icon';
import { Typography } from '@kizen/kds/Typography';
import { addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
  getDateWithTimezone,
  businessLocalTimezoneOffset,
  getScheduledTimezoneAdjusted,
} from 'app/timezone';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import { ApplySelectOverlayButton } from 'components/Inputs/SelectOverlay';
import { KizenTypography } from 'app/typography';
import { colorsButton, grayScale, tableHover } from 'app/colors';
import { TextEllipsis } from 'components/Kizen/Table';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import IconButtonAbsoluteMenu from 'components/Tables/Big/IconButtonAbsoluteMenu';
import { getActions, StyledIconButton } from './Table';

import NotesPanel from './NotesPanel';

const Wrapper = styled(Container)`
  background-color: ${({ noteBox }) => (noteBox ? `${tableHover}` : 'inherit')};
  padding-right: 11px;
  padding-bottom: 10px;
  padding-top: 10px;
  .form {
    width: 48px;
  }
`;

const IconBox = styled.div``;
const FixedCol = styled.div`
  position: absolute;
  left: 0;
  top: 3px;
`;
const FlexBox = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
`;

const RowBoxType = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RowBoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  margin-bottom: 11px;
  td > a {
    max-width: 200px;
  }
`;

const BoxActivity = styled.div`
  display: flex;
  padding-top: 11px;
  padding-bottom: 11px;
`;

const TableActivityCard = styled.table`
  width: 100%;
  td {
    &:first-child {
      min-width: 25px;
      width: 25px;
      position: relative;
    }
  }
`;

const TableActivityInfo = styled.table`
  width: 100%;

  tr {
    &:last-child {
      td {
        padding-bottom: 0;
      }
    }
    &:nth-child(2) {
      td {
        padding-bottom: 5px;
      }
    }
  }

  td {
    padding-bottom: 4px;

    &:first-child {
      width: 58px;
      padding-right: 10px;
    }
  }
`;

const NoteBox = styled.div`
  width: 100%;
  overflow: hidden;

  ${({ noteBox }) =>
    noteBox
      ? `max-height: 350px; transition: max-height 0.4s ease-in;`
      : `max-height: 0; transition: max-height 0.2s ease-out;`}
`;

const IconsBox = styled.div`
  display: flex;
`;

const ActvityNameWrapper = styled.div`
  width: 200px;
`;

const NO_VALUE = '—';

const InlineTextEllipsisWrapper = styled.div`
  width: 200px;
`;

const StyledTextEllipsis = styled(TextEllipsis)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NoValue = ({ light }) => (
  <KizenTypography
    type="micro"
    href="#"
    size={light ? 'text' : undefined}
    weight={light}
    color={light ? grayScale.mediumDark : undefined}
  >
    {NO_VALUE}
  </KizenTypography>
);

const tooltipConfig = {
  tooltipInner: {
    disableMediaWidth: true,
  },
};

export default function MobileRow({
  activityObject,
  showNotes,
  client,
  now,
  onSelectAction,
  onUpdateActivity,
  index,
  activity,
  updateActivityNotes,
  timezone,
  editorKey,
}) {
  const { t } = useTranslation();
  const { dueDatetime, employee, access = {} } = activity;
  // tooltips
  const [assignedToProps, assignedToTooltip] =
    useTruncationTooltip(tooltipConfig);
  const [contactProps, contactTooltip] = useTruncationTooltip(tooltipConfig);

  const dateWithTimezone = getDateWithTimezone(dueDatetime, timezone);
  const scheduledDate = new Date(
    getScheduledTimezoneAdjusted(dueDatetime, timezone)
  );
  const options = getActions(access, showNotes, t);

  return (
    <Wrapper noteBox={showNotes}>
      <Row style={{ margin: '0px' }}>
        <ActvityNameWrapper>
          <Typography size="md" truncate weight="semibold">
            {activityObject && activityObject.name}
          </Typography>
        </ActvityNameWrapper>
      </Row>
      <BoxActivity>
        <TableActivityCard>
          <tr>
            <td>
              <FixedCol>
                {scheduledDate < now && (
                  <Icon
                    icon="circle"
                    size="1x"
                    style={{ textAlign: 'left' }}
                    color={colorsButton.red}
                  />
                )}
              </FixedCol>
            </td>
            <td>
              <FlexBox>
                <RowBoxType>
                  <DateTimeInputInline
                    inModal
                    value={dateWithTimezone}
                    onSubmit={(val) => {
                      // because the date is local we need to subtract the tzo to get back to the correct time
                      const diff = businessLocalTimezoneOffset(timezone);
                      const adjustedVAl = addMinutes(val, 0 - diff);
                      const iso = adjustedVAl.toISOString();

                      onUpdateActivity(activity, { dueDatetime: iso });
                    }}
                    dateMenuProps={{
                      menuRightButton: <ApplySelectOverlayButton />,
                    }}
                    timeMenuProps={{
                      menuRightButton: <ApplySelectOverlayButton />,
                    }}
                  />
                  <IconsBox>
                    <StyledIconButton
                      sizing="dense"
                      title={t('Save')}
                      color={colorsButton.iconGray}
                      onClick={() => onSelectAction(options[0], activity)}
                    >
                      <Icon icon="check" />
                    </StyledIconButton>
                    <IconBox>
                      <IconButtonAbsoluteMenu
                        sizing="dense"
                        title={t('Edit Activity')}
                        position="right"
                        color={colorsButton.iconGray}
                        onChange={(option) => onSelectAction(option, activity)}
                        options={getActions(access, showNotes, t)}
                      >
                        <Icon icon="three-dot" />
                      </IconButtonAbsoluteMenu>
                    </IconBox>
                  </IconsBox>
                </RowBoxType>
                <RowBoxInfo>
                  <TableActivityInfo>
                    <tr>
                      <td>
                        <KizenTypography size="text" weight="regular">
                          {t('Contact')}
                        </KizenTypography>
                      </td>
                      <td>
                        {client ? (
                          <>
                            {contactTooltip}
                            <TextEllipsis
                              type="link"
                              size="text"
                              to={`/client/${client.id}/details`}
                              target="_blank"
                              {...contactProps}
                            >
                              {client.fullName}
                            </TextEllipsis>
                          </>
                        ) : (
                          <NoValue />
                        )}
                      </td>
                    </tr>
                  </TableActivityInfo>
                </RowBoxInfo>
                {assignedToTooltip}
                <InlineTextEllipsisWrapper>
                  {employee ? (
                    <TextEllipsis
                      size="text"
                      weight="light"
                      color={grayScale.mediumDark}
                      {...assignedToProps}
                    >
                      {t('Assigned to ')}
                      {employee.displayName}
                    </TextEllipsis>
                  ) : (
                    <StyledTextEllipsis
                      size="text"
                      weight="light"
                      color={grayScale.mediumDark}
                      {...assignedToProps}
                    >
                      {t('Assigned to ')}
                      <NoValue light />
                    </StyledTextEllipsis>
                  )}
                </InlineTextEllipsisWrapper>
              </FlexBox>
            </td>
          </tr>
        </TableActivityCard>
      </BoxActivity>
      <NoteBox noteBox={showNotes}>
        {showNotes && (
          <NotesPanel
            mobileView
            index={index}
            activity={activity}
            onNotesChange={updateActivityNotes}
            key={editorKey}
          />
        )}
      </NoteBox>
    </Wrapper>
  );
}

MobileRow.propTypes = {
  showNotes: PropTypes.bool,
  activityObject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  assignedTo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
  }),
  scheduled: PropTypes.instanceOf(Date).isRequired,
  client: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string,
  }),
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  deal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  }),
  now: PropTypes.instanceOf(Date).isRequired,
  onUpdateActivity: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickNotes: PropTypes.func,
  onSelectAction: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  activity: PropTypes.object.isRequired,
  updateActivityNotes: PropTypes.func.isRequired,
};

MobileRow.defaultProps = {
  showNotes: false,
  activityObject: null,
  assignedTo: null,
  client: null,
  company: null,
  deal: null,
  onClickEdit: null,
  onClickDelete: null,
  onClickNotes: null,
};
