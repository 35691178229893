import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters, spaceBetweenGutters } from 'app/spacing';
import ButtonWithDropdown from 'components/Button/WithDropdown';
import InputControl from 'components/Inputs/InputControl';

export const StyledFormRow = styled.div`
  > * {
    margin-top: ${gutters.spacing(
      4,
      -2
    )}px; // margin set to this weird value because font types caused mismatch with design, it should be 20px margin
  }

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          &:first-of-type > *:first-of-type {
            margin-top: 0;
          }
        `
      : css`
          display: flex;
          justify-content: space-between;
          flex: 1;
          > div {
            width: ${spaceBetweenGutters({ columns: 2, gutter: '20px' })};
          }
          &:first-of-type > * {
            margin-top: 0;
          }
        `}
`;

export const StyledButtonWithDropdown = styled(ButtonWithDropdown)`
  margin: 0;
  width: auto;
  button:first-child {
    overflow: hidden;
    max-width: 161px;
    text-overflow: ellipsis;
  }

  .ButtonMenu__menu {
    max-width: max-content;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  max-width: 355px;
`;

export const SwitchControl = styled(InputControl)`
  > label {
    margin-bottom: ${gutters.spacing(4, 2)}px;
    & + * {
      margin: 1px;
      label {
        // Incidentally part of <Switch />
        margin: 0;
      }
    }
  }
`;
export const StyledFormRowLast = styled(StyledFormRow)`
  > *:last-of-type {
    padding-bottom: ${({ checked }) => (checked ? 2 : 1)}px;
  }
`;
