import { forwardRef, useCallback, useRef } from 'react';
import Select from '../../Inputs/Select';
import { applyRef } from '../../Inputs/props';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from '../../../hooks/useSelectTypeaheadWithScroll';
import { useViewerContext } from '../../../../viewer';

export const getTeamLabel = (data) => {
  if (data.label) {
    return data.label;
  }

  if (data.displayName) {
    return data.displayName;
  }

  return [`${data.firstName} ${data.lastName}`, data.email && `(${data.email})`]
    .filter(Boolean)
    .join(' ');
};

export const convertToTeamOption = (option) => {
  const label = getTeamLabel(option);
  return {
    value: option.id,
    label,
    data: {
      ...option,
      displayName: label,
    },
  };
};

const SelectTeamMember = forwardRef(
  (
    {
      value,
      options = [],
      onChange,
      onMenuOpen,
      onMenuClose,
      isMulty,
      ...rest
    },
    ref
  ) => {
    const viewerCtx = useViewerContext();
    const isViewerCtx = !Array.isArray(viewerCtx);
    const selectRef = useRef(null);
    const [selectProps] = useSelectTypeaheadWithScroll({
      selectRef,
      onMenuOpen,
      onMenuClose,
      objectToOption: convertToTeamOption,
      entity: Entities.TeamMember,
      chosenValueIds: isMulty ? value : [],
      isViewerCtx: isViewerCtx,
    });

    const mergeRef = useCallback(
      (el) => {
        applyRef(selectRef, el);
        applyRef(ref, el);
      },
      [ref]
    );
    return (
      <Select
        ref={mergeRef}
        value={value}
        onChange={onChange}
        {...rest}
        {...selectProps}
      />
    );
  }
);
export default SelectTeamMember;
