import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useContactCategories,
  useContactFields,
  useCategoriesQuery,
  useFieldsQuery,
} from 'queries/models/custom-objects';

type Category = {
  id: string;
  meta: Record<string, any>;
  name: string;
  order: number;
};

type FieldLike = {
  category: string;
  displayName: string;
  id: string;
  isHidden: boolean;
  name: string;
};

type MergeField = {
  label: string;
  relationship: string;
};

type CategorizedMergeField = {
  label: string;
  items: MergeField[];
  key?: string;
};

const teamMemberMergeFields = (t: (x: string) => string): MergeField[] => [
  {
    label: t('Team Member First Name'),
    relationship: 'team_member.first_name',
  },
  {
    label: t('Team Member Last Name'),
    relationship: 'team_member.last_name',
  },
  {
    label: t('Team Member Email'),
    relationship: 'team_member.email',
  },
  {
    label: t('Team Member Signature'),
    relationship: 'team_member.signature',
  },
];

const mapFieldOption = (
  field: FieldLike,
  createRelationship: (field: FieldLike) => string
): MergeField => ({
  label: field.displayName,
  relationship: createRelationship(field),
});

export const filterMergeField =
  (search: string) => (mergeField: MergeField) => {
    return mergeField.label.toLowerCase().includes(search.trim().toLowerCase());
  };

const buildMergeFields = (
  fields: FieldLike[],
  categories: Category[],
  createRelationship: (field: FieldLike) => string
): CategorizedMergeField[] => {
  const categorizedFields = fields.reduce<Record<string, MergeField[]>>(
    (acc, field) => {
      acc[field.category] = acc[field.category] || [];
      acc[field.category].push(mapFieldOption(field, createRelationship));
      return acc;
    },
    {}
  );

  const mergeFields: CategorizedMergeField[] = categories.map(
    (category: Category) => {
      return {
        label: category.name,
        items: categorizedFields[category.id],
        key: category.id,
      };
    }
  );

  return mergeFields;
};

/**
 * This is the original hook for email merge fields which use the string literal 'contact' along with
 * the field's name property. All other merge fields use {@link useObjectMergeFields} which use the
 * object and field ids.
 */
export const useMergeFields = ({
  listHeaders = false,
  mergeFields = null,
} = {}) => {
  const { data: contactFields, isLoading: loadingFields } =
    useContactFields(!mergeFields);
  const { data: contactCategories, isLoading: loadingCategories } =
    useContactCategories(!mergeFields);
  const { t } = useTranslation();

  const data = useMemo<MergeField[] | CategorizedMergeField[]>(() => {
    if (mergeFields) {
      return mergeFields;
    }

    if (!Array.isArray(contactFields)) {
      return [];
    }

    if (!listHeaders) {
      return contactFields
        .map((field) => mapFieldOption(field, (x) => `contact.${x.name}`))
        .concat(teamMemberMergeFields(t));
    }

    if (!Array.isArray(contactCategories)) {
      return [];
    }

    const categorizedMergeFields = buildMergeFields(
      contactFields,
      contactCategories,
      (field) => `contact.${field.name}`
    );

    return categorizedMergeFields.concat({
      label: t('Team Member'),
      items: teamMemberMergeFields(t),
    });
  }, [contactFields, contactCategories, listHeaders, mergeFields, t]);

  return { data, isLoading: loadingCategories || loadingFields };
};

export const useObjectMergeFields = (
  id: string,
  objectName: string,
  objectType: Parameters<typeof useFieldsQuery>['0']['objectType']
) => {
  const { data: fields, isLoading: modelLoading } = useFieldsQuery(
    { id, objectType },
    Boolean(id) && Boolean(objectType)
  );
  const { data: categories = [], isLoading: loadingCategories } =
    useCategoriesQuery({ id, objectType }, Boolean(id) && Boolean(objectType));

  const data = useMemo<CategorizedMergeField[]>(() => {
    if (!fields) {
      return [];
    }
    return buildMergeFields(
      fields.filter((field: FieldLike) => !field.isHidden),
      categories,
      (field) => `${objectName}.${field.name}`
    );
  }, [fields, categories, objectName]);

  return { data, isLoading: modelLoading || loadingCategories };
};
