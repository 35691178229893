import { useEffect, useMemo } from 'react';
import { useFlashTransition } from 'hooks/useFlashState';

export const useDynamicError = (error) => {
  const [message, showMessage, flash] = useFlashTransition();

  const validate = useMemo(() => {
    return { message, showMessage };
  }, [message, showMessage]);

  useEffect(() => {
    if (error) {
      flash(error);
    }
  }, [flash, error]);

  return {
    error: Boolean(error),
    validate,
  };
};
