import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { borderRadii, layers } from 'app/spacing';
import { fontWeights, KizenTypography } from 'app/typography';
import { Gradient } from 'components/Kizen/ScrollFadeContainer';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { CALENDAR_VIEW_OPTIONS } from '../constants';
import Icon from 'components/Kizen/Icon';

export const BoldCellText = styled(KizenTypography)`
  font-weight: ${fontWeights.bold};
`;

export const DateTitle = styled(TextEllipsisWithTooltip)`
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TimeIntervalCell = styled(Cell)`
  grid-column: 1;
  background-color: ${grayScale.light};
  border-bottom: 1px solid ${grayScale.medium};
  ${({ isFirstRow }) =>
    isFirstRow &&
    css`
      border-top-left-radius: ${borderRadii.small};
    `}
  ${({ isLastRow }) =>
    isLastRow &&
    css`
      border-bottom-left-radius: ${borderRadii.small};
      border-bottom-width: 0;
    `}
`;

export const WeekdayHeaderCell = styled(Cell)`
  padding: 0 5px;
  overflow: hidden;
  border-left: 1px solid ${grayScale.medium};

  :nth-child(${({ startCol }) => startCol || 1}) {
    margin-left: 1px;
    border-left-width: 0;
  }
`;

export const CalendarScrollGradient = styled(Gradient)`
  z-index: ${({ zIndex }) => zIndex ?? layers.content(1)};
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;
  width: ${({ view }) => {
    switch (view) {
      case CALENDAR_VIEW_OPTIONS.month:
        return 235; // the width for longest text "September 2022" to be visible
      case CALENDAR_VIEW_OPTIONS.week:
        return 335; // the width for longest text "September 2022 - Week 4" to be visible
      case CALENDAR_VIEW_OPTIONS.day:
        return 275; // the width for longest text "September 28, 2022" to be visible
      default:
        return 275;
    }
  }}px;
`;

export const DateText = styled(TextEllipsisWithTooltip)`
  padding: 0 15px;
`;

export const ClickableIcon = styled(Icon)`
  cursor: pointer;
`;
