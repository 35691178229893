import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import useToggle from 'react-use/lib/useToggle';
import { DraggableCore } from 'react-draggable';

import { grayScale } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import {
  updateColumnWidth,
  setPageConfig,
} from 'store/customObjectsRecordsPage/actions';
import {
  EmptyCell,
  SizedCell as KizenSizedCell,
  SortableHeadCell as KizenSortableHeadCell,
} from 'components/Kizen/Table';
import InlineTableFieldInput, {
  getColumnNumeric,
  getColumnWidth,
} from 'components/Fields/InlineTableFieldInput';
import {
  ResizerLayout,
  PADDING,
  getSizing,
  getColumnWidthFromConfig,
} from 'pages/Common/components/BigTableCells';
import { getColumns, shallowEqualList } from 'pages/Common/helpers/table';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { getValueForPayload } from 'components/Modals/CreateEntity/helpers';
import { RECORD_LIST_CONFIG_KEYS } from 'services/TeamMemberService';

export const SizedCell = styled(({ column, minWidth, ...others }) => {
  // We pull column widths from redux not just because it is persistent/stateful,
  // but also because it greatly improves the performance versus managing this state
  // and piping it down from the TabularPage, since the whole table may re-render
  // rather than just the specific columns that were resized.
  const columnSettings = useSelector(
    (s) => s.recordsPage.pageConfig.columns[column.id]
  );

  return (
    <KizenSizedCell
      column={column}
      data-qa={column.label}
      data-qa-disabled={!column.meta.editable}
      padding={`${PADDING}px`}
      {...getSizing(columnSettings && columnSettings.width, minWidth)}
      {...others}
    />
  );
})`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
  }
`;

export const SortableHeadCell = styled(
  ({ column, minWidth, children, className, columnSettings, ...others }) => {
    const dispatch = useDispatch();
    const width = useSelector((s) =>
      getColumnWidthFromConfig(s.recordsPage, column.id)
    );
    const savedColumnSettings = useSelector(
      (s) => getColumns(s.recordsPage.pageConfig),
      shallowEqualList
    );

    const [resizing, toggleResizing] = useToggle(false);
    const handleResize = useCallback(
      (ev, { x }) => {
        const nextWidth = Math.max(
          parseInt(minWidth || '0', 10),
          x - PADDING / 2
        );
        dispatch(
          // !important - set or reset all properties in object
          updateColumnWidth({
            id: column.id,
            label: column.label,
            width: `${nextWidth}px`,
            visible: true,
          })
        );
      },
      [dispatch, column.id, column.label, minWidth]
    );

    const handleUpdateColumnWidth = useCallback(() => {
      if (!savedColumnSettings) {
        const updatedColumnSettings = columnSettings.map((c) => {
          if (c.id === column.id) {
            return {
              ...c,
              width: `${width}px`,
            };
          }
          return c;
        });
        dispatch(
          setPageConfig({
            settings: updatedColumnSettings,
          })
        );
      }
      dispatch(
        setPageConfig({ updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.COLUMNS })
      );
    }, [dispatch, savedColumnSettings, column, columnSettings, width]);

    return (
      <KizenSortableHeadCell
        className={`${className} ${resizing && 'Resizing'}`}
        column={column}
        padding={`${PADDING}px`}
        {...getSizing(width, minWidth)}
        {...others}
      >
        {children}
        <ResizerLayout className="ResizerLayout">
          <DraggableCore
            onDrag={handleResize}
            onStart={toggleResizing}
            onStop={() => {
              toggleResizing();
              handleUpdateColumnWidth();
            }}
          >
            <Icon className="Resizer" icon="column-resize" size="intrinsic" />
          </DraggableCore>
        </ResizerLayout>
      </KizenSortableHeadCell>
    );
  }
)`
  &.Resizing {
    cursor: ew-resize;
    svg line {
      stroke: ${grayScale.dark};
    }
  }
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
    margin-top: 1px;
    button {
      margin-left: 10px;
    }
  }
`;

export const CustomFieldDataCell = ({
  field = null,
  column = null,
  data = null,
  onSubmit,
  children,
  model,
  serviceToUse,
  fetchUrl,
  handleUpdateFieldOption = undefined,
  handleUpdateTableRecords = undefined,
  ...others
}) => {
  if (!field) {
    return <EmptyCell {...others} />;
  }
  const onSubmitRecord = async (option) => {
    const fieldId = Object.keys(option)[0];
    const patch = {
      fields: [{ id: fieldId, value: getValueForPayload(option[fieldId]) }],
    };
    await onSubmit(data.id, patch, column);
  };

  return (
    <SizedCell
      minWidth={`${getColumnWidth(field)}px`}
      column={column}
      {...others}
    >
      {column.format(
        <InlineTableFieldInput
          field={field}
          object={data}
          onSubmit={onSubmitRecord}
          isCustomObjects
          objectId={model.id}
          serviceToUse={serviceToUse}
          fetchUrl={fetchUrl}
          handleUpdateFieldOption={handleUpdateFieldOption}
          handleUpdateTableRecords={handleUpdateTableRecords}
          {...getDataQAForInput(
            `inline-record-field-${field.name}`,
            field.fieldType
          )}
        />
      )}
    </SizedCell>
  );
};

export const CustomFieldHeadCell = ({
  field = null,
  column = null,
  ...others
}) => {
  if (!field) {
    return <EmptyCell {...others} />;
  }
  return (
    <SortableHeadCell
      by={field.id}
      numeric={getColumnNumeric(field)}
      column={column}
      {...others}
    />
  );
};
