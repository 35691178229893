import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { createTeleporter } from 'react-teleporter';
import { useQuery } from 'react-query';
import { getChartComponent } from '../Charts';
import { ENTITY_TYPES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import {
  DashletContainer,
  DashletHead,
  DashletBody,
  TitleContainer,
  DashletHeaderSection,
  EditableTitle,
  StyledLoader,
  DashletFilterShortcutButton,
  DashletFilterCount,
  PillDateRangePicker,
  StyledPillContainer,
  DashletHeaderWrapper,
  DashletControls,
} from './styles';
import {
  DASHLET_SLOW_STALE_TIME,
  editableDashlet,
  filterOutAndSort,
  getDashletFilterCount,
  getDashletQueryKey,
  isNotFromAPI,
} from '../../util';
import { getSummaryData } from '../../queries';
import { ICON_BUTTON_DELAY, useTooltip } from 'components/Kizen/Tooltip';
import HandleIcon from './HandleIcon';
import { getDashletDefaultName } from '../../util';
import { useTranslation } from 'react-i18next';
import { gutters } from 'app/spacing';
import ActivityService from 'services/ActivityService';
import { DASHBOARD, FIELDS } from 'queries/query-keys';
import DashletMenuController from './MenuController';
import usePipelineMetadata from 'pages/Dashboard/hooks/usePipelineMetadata';
import Icon from 'components/Kizen/Icon';
import useModal from 'components/Modals/useModal';
import FiltersModal from 'components/Wizards/shared/components/MetaFilters/Modal'; // KZN-8981
import useCustomObject from 'components/Wizards/shared/hooks/useCustomObject';
import { areFiltersEnabled } from 'components/Wizards/shared/components/MetaFilters/config';
import {
  FILTER_TYPES,
  isCustomFilter,
  isInGroupFilter,
  isNotInGroupFilter,
} from 'ts-filters/legacy';
import useFilterCustomObject from 'components/Wizards/MetaFilters/hooks/useFilterCustomObject';
import { useWindowSize, isMobile } from 'app/spacing';
import FieldService from 'services/FieldService';
import { getRelevantObjectIds } from './modal';
import { useSelector } from 'react-redux';
import { DEFAULT_LABEL_INDICATOR } from 'components/DashboardGrid/utils';
import useTeamMemberNameRepair from 'components/Wizards/shared/hooks/useTeamMemberNameRepair';
import useDynamicTagRepair from 'components/Wizards/shared/hooks/useDynamicTagRepair';
import { AREA_RESPONSES } from 'components/Wizards/RTDV/types';
import { isFlagEnabled } from 'debug/flags';
import { FLAGS } from 'debug/identifiers';
import persistentState from '../../context/persistentState';
import { isEqual } from 'lodash';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useVisibility } from 'pages/Dashboard/context/dataManager/useVisibility';
import { useManagedQuery } from 'pages/Dashboard/context/dataManager/useManagedQuery';
import { Button } from '@kizen/kds/Button';
import { Pill } from '@kizen/kds/Pill';
import { SearchPill } from '@kizen/kds/SearchPill';
import { Spacer } from '@kizen/kds/Spacer';
import { convertTimeToMinutes } from 'pages/CustomObjects/RecordsPage/helpers';
import { useField } from './useField';
import { useDateTooltip } from 'components/Inputs/hooks/useDateTooltip';
import DashletError from 'pages/Dashboard/components/DashletError';
import { useBasicVisibility } from 'pages/Dashboard/context/dataManager/useBasicVisibility';

const getIsSummaryQueryEnabled = (dashlet) => {
  if (
    dashlet?.config?.reportType === AREA_RESPONSES.TABLE ||
    dashlet?.config?.reportType === 'html' ||
    // Don't fetch the summary for email interactions, because
    // that's fetched differently (in the chart)
    dashlet?.config?.reportType === REPORT_TYPES.INTERACTION_STATS
  ) {
    return false;
  }

  return (
    dashlet?.config?.entityType !== ENTITY_TYPES.ACTIVITY ||
    dashlet?.config?.reportType === REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS
  );
};

const useDashletConfigKey = (dashlet = {}, mobile, breakpoint) => {
  const ref = useRef('');
  return useMemo(() => {
    switch (dashlet.config?.reportType) {
      case REPORT_TYPES.SCHEDULED_ACTIVITIES:
        return isNotFromAPI(dashlet?.config || {})
          ? ref.current
          : (ref.current = JSON.stringify({
              config: filterOutAndSort(dashlet?.config || {}),
            }));
      default:
        return JSON.stringify({ dashlet, mobile, breakpoint });
    }
  }, [dashlet, mobile, breakpoint]);
};

const DashletRenderer = ({
  dashlet,
  onDeleteDashlet,
  isDraggable,
  editDashlet,
  duplicateDashlet,
  dashboardId,
  breakpoint,
  dateFilter = EMPTY_OBJECT,
  teamFilter = EMPTY_ARRAY,
  mobile = false,
  canEdit = true,
  model,
  ModalComponent,
  search,
  filtersForCharts,
  objectForCharts,
  charts,
  onChangeColumnWidths,
  builderContent,
  onStartEditingBuilderContent,
  modalExtraProps,
  homepages,
  showDateShortcut,
  setContentHeight,
  handleBustCache,
  data,
  isLoading,
  isRefetching,
  dashletContainer,
  isManaged,
  ranges,
  todayDateInBusinessTimeZone,
  isDashletUpdating,
}) => {
  const [infiniteQueryLoading, setInfiniteQueryLoading] = useState(false);
  const [dashletSearch, setDashletSearch] = useState('');
  const isForContacts = model?.fetchUrl === 'client';
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const access = useSelector((s) => s.authentication.access);
  const chosenBusiness = useSelector((s) => s.authentication.chosenBusiness);
  const { dispatch, state, localDispatch, localState } = useContext(
    persistentState.Context
  );

  const fetchActivityType = useCallback(async () => {
    const result = await ActivityService.v2GetActivity(
      {
        activityObjectId: dashlet?.config?.objectId,
      },
      {
        skipErrorBoundary: true,
      }
    );

    return result;
  }, [dashlet?.config?.objectId]);
  const [datePickerDirection, setDatePickerDirection] = useState('left');

  const isActivityQueryEnabled =
    dashlet?.config?.entityType === 'activity' &&
    dashlet?.config?.reportType !== REPORT_TYPES.SCHEDULED_ACTIVITIES;

  const { data: activity, isLoading: activityLoading } = useQuery(
    [...DASHBOARD.DASHLET_ACTIVITY, dashlet?.config?.objectId],
    fetchActivityType,
    {
      enabled: isActivityQueryEnabled,
    }
  );

  const [pipeline, currencySymbol] = usePipelineMetadata(data);

  const isFieldQueryEnabled = Boolean(dashlet?.config?.field) && Boolean(model);

  const { bi_cache_refreshable: cacheRefreshEntitlement = true } =
    chosenBusiness?.entitlements || {};

  const displayCacheRefresh =
    isFlagEnabled(FLAGS.ALWAYS_REFRESH_DASHLET) || cacheRefreshEntitlement;

  const isClient = model?.fetchUrl === 'client';
  const { data: fieldData, isLoading: fieldIsLoading } = useQuery(
    FIELDS.GET_FIELD(dashlet?.config?.field),
    () =>
      isClient
        ? FieldService.getClientField(dashlet.config.field, {
            skipErrorBoundary: true,
          })
        : FieldService.getField(dashlet.config.field, model, {
            skipErrorBoundary: true,
          }),
    {
      enabled: isFieldQueryEnabled && Boolean(model),
    }
  );

  const { fieldToAggregate } = dashlet?.config?.aggregation ?? {};
  const { data: aggreateFieldData } = useQuery(
    FIELDS.GET_FIELD(fieldToAggregate),
    () =>
      isClient
        ? FieldService.getClientField(fieldToAggregate, {
            skipErrorBoundary: true,
          })
        : FieldService.getField(fieldToAggregate, model, {
            skipErrorBoundary: true,
          }),
    {
      enabled: Boolean(fieldToAggregate) && Boolean(model),
    }
  );

  // we need to check if the fieldToAnalyze exists
  const { isLoading: fieldToAnalyzeIsLoading } = useField({
    id: dashlet?.config?.metricTypeExtraInfo?.fieldToAnalyze,
    model,
    isClient,
  });

  const { data: columnFieldResult } = useField({
    id: dashlet?.config?.aggregation?.columns?.id,
    model,
    isClient,
  });

  const { data: rowFieldResult } = useField({
    id: dashlet?.config?.aggregation?.rows?.[0]?.id,
    model,
    isClient,
  });

  const name = useMemo(() => {
    if (dashlet.name && dashlet.name !== DEFAULT_LABEL_INDICATOR) {
      return dashlet.name;
    }
    return getDashletDefaultName(
      {
        dashletData: dashlet,
        aggreateFieldData,
        pipelineDetail: pipeline,
        currencySymbol,
        activity,
        fieldData,
        objectForCharts,
        pivotTableColumnField: columnFieldResult,
        pivotTableRowField: rowFieldResult,
      },
      t
    );
  }, [
    dashlet,
    t,
    pipeline,
    currencySymbol,
    activity,
    fieldData,
    objectForCharts,
    columnFieldResult,
    rowFieldResult,
    aggreateFieldData,
  ]);

  const [title, setTitle] = useState(name);
  const [shouldUseTooltip, setShouldUseTooltip] = useState(false);
  const [shouldShowEllipsis, setShouldShowEllipsis] = useState(true);
  const [overrideShowDashletMenu, setOverrideShowDashletMenu] = useState(false);
  const [reloadPivotTableSubRows, setReloadPivotTableSubRows] = useState(false);
  const titleInput = useRef();

  useEffect(() => setTitle(name), [name]);

  const calculateTootlip = useCallback(() => {
    // A bit of a hack to only show the tooltip if the wizard title is
    // causing an overflow.
    const scrollW = titleInput?.current?.parentNode?.scrollWidth;
    const clientW = titleInput?.current?.parentNode?.clientWidth;
    setShouldUseTooltip(clientW < scrollW);
  }, []);

  const setTitleInputRef = useCallback((el) => {
    // react-input-autosize only respects function-style refs
    titleInput.current = el;
  }, []);

  const renameDashlet = useCallback(
    (existingDashlet, title) => {
      const newDashlet = {
        ...editableDashlet(existingDashlet, t),
        name: title,
      };
      editDashlet(newDashlet);
    },
    [editDashlet, t]
  );

  const onDuplicate = useCallback(() => {
    const newDashlet = {
      ...editableDashlet(dashlet, t),
      name: !name.trim().endsWith(`(${t('copy')})`)
        ? `${name} (${t('copy')})`
        : name,
    };
    duplicateDashlet(newDashlet, builderContent);
  }, [dashlet, t, duplicateDashlet, name, builderContent]);

  const updateDashletDateFilter = useCallback(
    (range, selectedIndex) => {
      const newDashlet = {
        ...editableDashlet(dashlet, t),
        dateFilter: {
          start: range[0],
          end: range[1],
          selectedIndex,
        },
      };

      // email interaction stats has an instance of useMangedQuery that we pass the date filter state directly to
      const optimisticUpdate =
        dashlet?.config?.reportType === REPORT_TYPES.INTERACTION_STATS;

      editDashlet(newDashlet, null, optimisticUpdate);
    },
    [dashlet, t, editDashlet]
  );

  const onBlurHandler = useCallback(
    (dashlet, title) => {
      if (title !== name) {
        renameDashlet(dashlet, title);
      }
      setShouldShowEllipsis(true);
    },
    [name, renameDashlet]
  );

  const onRenameHandler = useCallback(() => {
    if (titleInput.current) {
      titleInput.current.select();
    }
  }, []);

  // if the user presses enter, attempt to save
  const onKeyDownHandler = useCallback(
    (event, dashlet, title) => {
      if (event.keyCode === 13) {
        return onBlurHandler(dashlet, title);
      }
    },
    [onBlurHandler]
  );

  const DashletAction = useMemo(createTeleporter, []);
  const DashletMenuContents = useMemo(createTeleporter, []);
  const DashletHeaderText = useMemo(createTeleporter, []);

  // This is necessary because sometimes the title width is initially not
  // rendered correctly, perhaps due to the font not having been loaded yet.
  const titleRef = useRef();
  const updateTitleWidth = useCallback(() => {
    if (titleRef.current) {
      // Comes from within react-input-autosize
      titleRef.current.updateInputWidth();
    }
  }, []);

  const onFocusHandler = useCallback(() => {
    updateTitleWidth();
    setShouldShowEllipsis(false);
  }, [updateTitleWidth]);

  const handleMouseOver = useCallback(() => {
    calculateTootlip();
    updateTitleWidth();
  }, [calculateTootlip, updateTitleWidth]);

  const { chartType, reportType, historical, pipelineLevelOfDetail } =
    dashlet.config;

  const DashletChart = useMemo(() => {
    const Component = getChartComponent({
      chartType,
      reportType,
      historical,
      pipelineLevelOfDetail,
    });

    return React.memo(Component, (prevProps, nextProps) => {
      const checks = [
        isEqual(prevProps.dashlet, nextProps.dashlet),
        isEqual(prevProps.data, nextProps.data),
        prevProps.isLoading === nextProps.isLoading,
        prevProps.dashboardId === nextProps.dashboardId,
        isEqual(prevProps.pipeline, nextProps.pipeline),
        prevProps.currencySymbol === nextProps.currencySymbol,
        prevProps.mobile === nextProps.mobile,
        isEqual(prevProps.teamFilter, nextProps.teamFilter),
        isEqual(prevProps.dateFilter, nextProps.dateFilter),
        isEqual(prevProps.dashletSearch === nextProps.dashletSearch),
        prevProps.disableFilterToggle === nextProps.disableFilterToggle,
        prevProps.showMyActivities === nextProps.showMyActivities,
        prevProps.breakpoint === nextProps.breakpoint,
        isEqual(prevProps.fieldData, nextProps.fieldData),
        isEqual(prevProps.fieldOptions, nextProps.fieldOptions),
        prevProps.search === nextProps.search,
        isEqual(prevProps.model, nextProps.model),
        isEqual(prevProps.filtersForCharts, nextProps.filtersForCharts),
        isEqual(prevProps.objectForCharts, nextProps.objectForCharts),
        prevProps.contacts === nextProps.contacts,
        prevProps.canEdit === nextProps.canEdit,
        isEqual(prevProps.builderContent, nextProps.builderContent),
        isEqual(prevProps.columnConfig, nextProps.columnConfig),
        prevProps.refreshedAt === nextProps.refreshedAt,
        prevProps.canEdit === nextProps.canEdit,
        isEqual(prevProps.persistentState, nextProps.persistentState),
        prevProps.clientObjectId === nextProps.clientObjectId,
        prevProps.reloadPivotTableSubRows === nextProps.reloadPivotTableSubRows,
      ];
      return checks.every(Boolean);
    });
  }, [chartType, reportType, historical, pipelineLevelOfDetail]);

  const [titleTooltipProps, titleTooltip] = useTooltip({
    label: title,
  });

  const filterCount = useMemo(() => {
    return getDashletFilterCount(dashlet?.config?.filters ?? {});
  }, [dashlet]);

  const hasFilters = filterCount > 0;

  const [tooltipProps, tooltip] = useTooltip(
    {
      label: hasFilters
        ? t('Edit Filters')
        : t('Filter {{entity}}', {
            entity: charts ? t('Chart') : t('Dashlet'),
          }),
      delay: ICON_BUTTON_DELAY,
    },
    () => !isMobile(width)
  );

  useEffect(() => {
    calculateTootlip(titleInput?.current?.parentNode);
  }, [name, calculateTootlip, isLoading, breakpoint]);

  const defaultCustomObject = useMemo(() => {
    return dashlet?.config?.filters?.customObjectId
      ? { value: dashlet.config.filters.customObjectId }
      : null;
  }, [dashlet]);

  const [selectedCustomObject, setSelectedCustomObject] =
    useState(defaultCustomObject);

  const filterState = useMemo(() => {
    if (dashlet?.config?.filters?.customFilters) {
      return {
        details: {
          type: FILTER_TYPES.CUSTOM,
          groups: [],
          filterConfig: dashlet.config.filters.customFilters,
        },
      };
    }
    if (dashlet?.config?.filters?.inGroupIds?.length > 0) {
      return {
        details: {
          type: FILTER_TYPES.IN_GROUP,
          groups: dashlet.config.filters.inGroupIds.map((id) => ({
            id,
          })),
        },
      };
    }
    if (dashlet?.config?.filters?.notInGroupIds?.length > 0) {
      return {
        details: {
          type: FILTER_TYPES.NOT_IN_GROUP,
          groups: dashlet.config.filters.notInGroupIds.map((id) => ({
            id,
          })),
        },
      };
    }
  }, [dashlet]);

  const handleChangeSelectedCustomObject = useCallback((co) => {
    setSelectedCustomObject(co);
  }, []);

  const [modalProps, , modal] = useModal();

  const handleShow = () => {
    if (!defaultCustomObject) {
      setSelectedCustomObject(null);
    }
    modal.show();
  };

  const handleClearDashletSearch = useCallback(() => {
    localDispatch({ [dashlet.id]: '' });
    setDashletSearch('');
  }, [dashlet.id, localDispatch]);

  const handleConfirmDashletFilters = useCallback(
    (changed, selectedObject) => {
      const type = changed?.details?.type;
      if (isCustomFilter(type)) {
        const payload = changed?.details?.filterConfig;
        const newDashlet = {
          ...editableDashlet(dashlet, t),
          customFilters: payload,
          inGroupFilters: undefined,
          notInGroupFilters: undefined,
          customObjectId: selectedObject?.value,
        };
        editDashlet(newDashlet);
      } else if (isInGroupFilter(type)) {
        const newDashlet = {
          ...editableDashlet(dashlet, t),
          customFilters: undefined,
          inGroupFilters:
            changed?.details?.groups?.map((g) => g.id) ?? undefined,
          notInGroupFilters: undefined,
          customObjectId: selectedObject?.value,
        };
        editDashlet(newDashlet);
      } else if (isNotInGroupFilter(type)) {
        const newDashlet = {
          ...editableDashlet(dashlet, t),
          customFilters: undefined,
          inGroupFilters: undefined,
          notInGroupFilters:
            changed?.details?.groups?.map((g) => g.id) ?? undefined,
          customObjectId: selectedObject?.value,
        };
        editDashlet(newDashlet);
      } else {
        const newDashlet = {
          ...editableDashlet(dashlet, t),
          customFilters: undefined,
          inGroupFilters: undefined,
          notInGroupFilters: undefined,
          customObjectId: undefined,
        };
        editDashlet(newDashlet);
      }
      modal.hide();
    },
    [dashlet, editDashlet, modal, t]
  );

  const updateDashletColumnConfig = useCallback(
    (config) => {
      const { columns, sort } = config;
      const editable = editableDashlet(dashlet, t);
      const newDashlet = {
        ...editable,
        scheduledActivitiesConfig: {
          columns,
          sort: {},
        },
      };

      dispatch({
        [dashlet.id]: sort,
      });

      if (!isEqual(editable, newDashlet) && canEdit) {
        editDashlet(newDashlet, undefined, true);
      }
    },
    [dashlet, editDashlet, t, dispatch, canEdit]
  );

  const onChangePivotTableStandardColumnSizes = useCallback(
    (cols) => {
      const editable = editableDashlet(dashlet, t);
      const newDashlet = {
        ...editable,
        pivotTableConfig: {
          ...editable.pivotTableConfig,
          cols,
        },
      };

      if (!isEqual(editable, newDashlet) && canEdit) {
        editDashlet(newDashlet, undefined, true);
      }
    },
    [canEdit, editDashlet, dashlet, t]
  );

  const onChangePivotTableFixedLeftColumnSizes = useCallback(
    (cols) => {
      const editable = editableDashlet(dashlet, t);
      const newDashlet = {
        ...editable,
        pivotTableConfig: {
          ...editable.pivotTableConfig,
          fixedLeftCols: cols,
        },
      };

      if (!isEqual(editable, newDashlet) && canEdit) {
        editDashlet(newDashlet, undefined, true);
      }
    },
    [canEdit, editDashlet, dashlet, t]
  );

  const onChangeTableSort = useCallback(
    (sort) => {
      dispatch({
        [dashlet.id]: sort,
      });
    },
    [dashlet.id, dispatch]
  );

  const onOverrideShowDashletMenu = useCallback(
    () => setOverrideShowDashletMenu(true),
    []
  );

  const selectedObjectId =
    dashlet?.config?.objectIds?.[0] ?? dashlet?.config?.objectId;

  const { customObject: customObjectData, loading: customObjectDataLoading } =
    useCustomObject(selectedObjectId, Boolean(modal.showing));

  const filtersEnabled = useMemo(() => {
    return areFiltersEnabled(
      dashlet?.config?.entityType,
      dashlet?.config?.reportType,
      dashlet?.config?.objectIds
    );
  }, [dashlet]);

  const {
    object: rawSelectedObject,
    isForCustomObject,
    clientObject,
  } = useFilterCustomObject(
    selectedCustomObject,
    defaultCustomObject,
    modal.showing
  );

  const clientObjectId = clientObject.id;

  const hasEditAccess = useMemo(() => {
    const ids = getRelevantObjectIds(editableDashlet(dashlet, t));
    const objectAccess = access.custom_objects?.custom_object_entities ?? {};
    const contactAccess = access.sections?.contacts_section?.view ?? false;

    return ids.every((id) => {
      if (id === clientObjectId) {
        return contactAccess;
      }
      return objectAccess[id]?.enabled;
    });
  }, [dashlet, access, t, clientObjectId]);

  // Should always return an object, whether custom object or client
  const selectedObjectValue = useMemo(() => {
    if (selectedObjectId && !objectForCharts?.id) {
      return rawSelectedObject;
    }

    return objectForCharts;
  }, [selectedObjectId, objectForCharts, rawSelectedObject]);

  // Should return only custom objects - if the current object is clients, return undefined
  const customObjectValue = useMemo(() => {
    if (selectedObjectId && !objectForCharts?.id) {
      if (selectedObjectId === clientObjectId) {
        return undefined;
      }
      return customObjectData;
    }

    if (objectForCharts?.id === clientObjectId) {
      return undefined;
    }
    return objectForCharts;
  }, [clientObjectId, objectForCharts, customObjectData, selectedObjectId]);

  const {
    loading: teamMemberBatchLoading,
    valuesWithNames,
    needsToFetchTeamMembers,
  } = useTeamMemberNameRepair({
    fieldData,
    values:
      dashlet?.config?.metricTypeExtraInfo?.fieldsValueBreakdown?.buckets?.reduce(
        (acc, curr) => {
          if (typeof curr === 'string') {
            return [...acc, curr];
          }

          return [...acc, ...curr.values];
        },
        []
      ) ?? [],
  });

  const {
    needsToFetchDynamicTags,
    valuesWithNames: dynamicTagsWithNames,
    loading: dynamicTagsBatchLoading,
  } = useDynamicTagRepair({
    fieldData,
    values:
      dashlet?.config?.metricTypeExtraInfo?.fieldsValueBreakdown?.buckets?.reduce(
        (acc, curr) => {
          if (typeof curr === 'string') {
            return [...acc, curr];
          }

          return [...acc, ...curr.values];
        },
        []
      ) ?? [],
  });

  const fieldOptions = useMemo(() => {
    if (needsToFetchTeamMembers) {
      return valuesWithNames;
    }
    if (needsToFetchDynamicTags) {
      return dynamicTagsWithNames;
    }
    return fieldData?.options ?? [];
  }, [
    fieldData,
    needsToFetchTeamMembers,
    needsToFetchDynamicTags,
    dynamicTagsWithNames,
    valuesWithNames,
  ]);

  const dashletDataLoading =
    isLoading ||
    isRefetching ||
    activityLoading ||
    fieldIsLoading ||
    fieldToAnalyzeIsLoading ||
    teamMemberBatchLoading ||
    dynamicTagsBatchLoading;

  const dashletContainerRef = (elem) => {
    dashletContainer.current = elem;
    const box = elem?.getBoundingClientRect();
    const right = box?.right ?? 0;
    const buffer = width - 650;

    if (right > buffer) {
      return setDatePickerDirection('left');
    }
    return setDatePickerDirection('right');
  };

  const dateFilterValue = useMemo(() => {
    if (!showDateShortcut) {
      return {};
    }

    const filter = dashlet?.config?.feExtraInfo?.dateFilter;

    return {
      range: filter && [filter.start, filter.end],
      selectedIndex: filter?.selectedIndex,
    };
  }, [showDateShortcut, dashlet]);

  const unitsWidth = dashlet?.layout?.w;

  const columnConfig = useMemo(() => {
    if (dashlet?.config?.feExtraInfo?.scheduledActivitiesConfig) {
      return {
        columns: dashlet.config.feExtraInfo.scheduledActivitiesConfig.columns,
        sort: state?.[dashlet.id] ?? {},
      };
    }
  }, [dashlet, state]);

  const dashletConfigKey = useDashletConfigKey(dashlet, mobile, breakpoint);

  const [statefulRefreshedAt, setStatefulRefreshedAt] = useState();
  const refreshedAt = useMemo(() => {
    return data?.refreshed_at || statefulRefreshedAt;
  }, [data, statefulRefreshedAt]);

  const timeSinceRefresh = useMemo(() => {
    if (!refreshedAt) {
      return null;
    }

    const refreshedTs = new Date(refreshedAt).getTime();
    const now = new Date().getTime();

    const diff = now - refreshedTs;

    return convertTimeToMinutes(diff, t)[1];
  }, [refreshedAt, t]);

  const [timeTooltipProps, timeTooltip] = useDateTooltip({
    predicate: t('Last Updated'),
    date: refreshedAt,
  });

  const isPivotTable =
    dashlet?.config?.reportType === AREA_RESPONSES.PIVOT_TABLE;

  const isTableOfRecords = dashlet?.config?.reportType === AREA_RESPONSES.TABLE;
  const isScheduledActivities =
    dashlet?.config?.reportType === REPORT_TYPES.SCHEDULED_ACTIVITIES;
  const searchPillSupported =
    isPivotTable || isTableOfRecords || isScheduledActivities;

  const reloadDashlet = useCallback(() => {
    if (isPivotTable && data?.data?.subrow_labels) {
      setReloadPivotTableSubRows(true);
    }
    handleBustCache();
  }, [isPivotTable, handleBustCache, data?.data]);

  return (
    <DashletContainer ref={dashletContainerRef}>
      {isDraggable && !dashletDataLoading && <HandleIcon />}
      {/* With dynamically-titled dashlets, we need to cover everything including the
       * title while loading, so that we don't get 'undefined' values in the title text
       */}
      <StyledLoader loading={dashletDataLoading} />
      {/* This className "dashlet-head" is targeted by the parent */}
      <DashletHead className="dashlet-head">
        <DashletHeaderSection mobile={mobile}>
          <TitleContainer
            showEllipsis={shouldShowEllipsis}
            className={`title-container ${
              dashlet?.config?.entityType === 'static_content'
                ? 'static-content'
                : ''
            }`}
          >
            {!builderContent && (
              <>
                <EditableTitle
                  className="no-drag"
                  ref={titleRef}
                  onMouseOver={handleMouseOver}
                  inputRef={setTitleInputRef}
                  value={title}
                  onChange={setTitle}
                  onKeyDown={(event) => onKeyDownHandler(event, dashlet, title)}
                  {...titleTooltipProps}
                  onBlur={() => onBlurHandler(dashlet, title)}
                  onFocus={onFocusHandler}
                  disabled={
                    !canEdit || dashletDataLoading || (homepages && mobile)
                  }
                />
                {shouldUseTooltip ? titleTooltip : ''}
              </>
            )}
          </TitleContainer>
          {dashlet?.config?.reportType === REPORT_TYPES.SCHEDULED_ACTIVITIES ? (
            <DashletHeaderWrapper
              mobile={mobile}
              variant="header"
              size={mobile ? null : 'lg'}
            >
              <DashletHeaderText.Target className="dashlet-header-text" />
            </DashletHeaderWrapper>
          ) : null}
        </DashletHeaderSection>
        <DashletControls>
          {!canEdit || (homepages && mobile) ? null : (
            <>
              <DashletMenuController
                dashlet={dashlet}
                editDashlet={
                  dashletDataLoading ||
                  (charts && mobile) ||
                  (homepages && mobile)
                    ? undefined
                    : editDashlet
                }
                onDeleteDashlet={onDeleteDashlet}
                onRenameHandler={
                  dashletDataLoading || Boolean(builderContent)
                    ? undefined
                    : onRenameHandler
                }
                onDuplicateHandler={onDuplicate}
                id={dashlet.id}
                clientObjectId={clientObjectId}
                ModalComponent={ModalComponent}
                overrideShowDashletMenu={overrideShowDashletMenu}
                setOverrideShowDashletMenu={setOverrideShowDashletMenu}
                modalComponentProps={modalExtraProps}
                model={model}
                entity={charts ? t('Chart') : t('Dashlet')}
                overrideEditHandler={
                  Boolean(builderContent) && !mobile
                    ? onStartEditingBuilderContent
                    : undefined
                }
              />
            </>
          )}
          {filtersEnabled &&
          (mobile ? filterCount > 0 : true) &&
          hasEditAccess &&
          !dashletDataLoading ? (
            <>
              {!canEdit && homepages ? null : (
                <>
                  <DashletFilterShortcutButton
                    onClick={canEdit && !mobile ? handleShow : undefined}
                    className="dashlet-filter"
                    hasFilters={hasFilters}
                    canEdit={canEdit}
                    {...tooltipProps}
                    data-qa-control="filters"
                    data-qa-filter-count={filterCount}
                  >
                    <Icon icon="filter" />
                    {hasFilters ? (
                      <DashletFilterCount>{filterCount}</DashletFilterCount>
                    ) : null}
                    {tooltip}
                  </DashletFilterShortcutButton>
                  <FiltersModal
                    {...modalProps}
                    customObject={customObjectValue}
                    onConfirm={handleConfirmDashletFilters}
                    state={filterState}
                    loading={customObjectDataLoading}
                    hideWarnings={{
                      isMeWarning: true,
                      timezoneWarning: Boolean(charts),
                    }}
                    area={dashlet?.config?.entityType}
                    reportType={dashlet?.config?.reportType}
                    onChangeSelectedObject={handleChangeSelectedCustomObject}
                    selectedObject={selectedObjectValue}
                    isForCustomObject={isForCustomObject}
                    charts={charts}
                  />
                </>
              )}
            </>
          ) : null}
          {showDateShortcut && (
            <PillDateRangePicker
              key={todayDateInBusinessTimeZone}
              direction={datePickerDirection}
              canEdit={canEdit && !mobile}
              value={dateFilterValue.range}
              selectedIndex={dateFilterValue.selectedIndex}
              onOk={updateDashletDateFilter}
              dashletUnits={unitsWidth}
              popover
              ranges={ranges}
              todayDate={todayDateInBusinessTimeZone}
            />
          )}
          {dashlet?.config?.reportType === REPORT_TYPES.SCHEDULED_ACTIVITIES ? (
            <DashletAction.Target className="dashlet-action" />
          ) : null}
          {searchPillSupported && (
            <SearchPill
              boundaryClassName="dashlet-head"
              placeholder={t('Search')}
              loading={infiniteQueryLoading && dashletSearch}
              onChange={setDashletSearch}
              onBlur={(e) => {
                localDispatch({ [dashlet.id]: e.target.value });
              }}
              value={localState?.[dashlet.id] ?? undefined}
            />
          )}
        </DashletControls>
      </DashletHead>
      <DashletBody
        bottom={
          dashlet?.config?.reportType === REPORT_TYPES.SCHEDULED_ACTIVITIES
            ? gutters.spacing(1)
            : null
        }
      >
        {breakpoint ? (
          <DashletChart
            key={dashletConfigKey}
            dashlet={dashlet}
            data={data}
            isLoading={isLoading || isRefetching || teamMemberBatchLoading}
            dashletDataLoading={dashletDataLoading}
            dashletSearch={dashletSearch}
            isDashletUpdating={isDashletUpdating}
            dashboardId={dashboardId}
            DashletAction={DashletAction.Source}
            DashletHeaderText={DashletHeaderText.Source}
            DashletMenuContents={DashletMenuContents.Source}
            onOverrideShowDashletMenu={onOverrideShowDashletMenu}
            pipeline={pipeline}
            currencySymbol={currencySymbol}
            mobile={mobile}
            teamFilter={teamFilter}
            dateFilter={dateFilter}
            disableFilterToggle
            showMyActivities={false}
            breakpoint={breakpoint}
            fieldData={fieldData}
            fieldOptions={fieldOptions}
            search={search}
            model={model}
            filtersForCharts={filtersForCharts}
            objectForCharts={objectForCharts}
            contacts={isForContacts}
            onChangeColumnWidths={onChangeColumnWidths}
            canEdit={canEdit}
            builderContent={builderContent}
            setContentHeight={setContentHeight}
            columnConfig={columnConfig}
            updateColumnConfig={updateDashletColumnConfig}
            refreshedAt={refreshedAt}
            setRefreshedAt={setStatefulRefreshedAt}
            onChangeStandardColumnSizes={onChangePivotTableStandardColumnSizes}
            onChangeFixedLeftColumnSizes={
              onChangePivotTableFixedLeftColumnSizes
            }
            onChangeSort={onChangeTableSort}
            onInfiniteQueryLoading={setInfiniteQueryLoading}
            persistentState={state?.[dashlet?.id]}
            clientObjectId={clientObjectId}
            handleBustCache={handleBustCache}
            reloadPivotTableSubRows={reloadPivotTableSubRows}
            setReloadPivotTableSubRows={setReloadPivotTableSubRows}
            clearDashletSearch={handleClearDashletSearch}
          />
        ) : null}
      </DashletBody>
      {isManaged && timeSinceRefresh && !dashletSearch && (
        <StyledPillContainer
          className="flex items-center"
          isAbsolute={isPivotTable}
        >
          <div {...timeTooltipProps} className="flex items-center">
            <Pill icon="message-last-updated" label={timeSinceRefresh} />
          </div>
          {timeTooltip}
          {canEdit && displayCacheRefresh ? (
            <>
              <Spacer mode="vertical" size={8} />
              <Button
                leftIcon="action-refresh"
                leftIconSettings={{
                  tooltipDescriptionOverride: t('Refresh Data'),
                }}
                variant="text"
                onClick={reloadDashlet}
                color="inherit"
              />
            </>
          ) : null}
        </StyledPillContainer>
      )}
    </DashletContainer>
  );
};

const Dashlet = ({
  dashlet,
  onDeleteDashlet,
  isDraggable,
  editDashlet,
  dashboardId,
  duplicateDashlet,
  breakpoint,
  dateFilter = EMPTY_OBJECT,
  teamFilter = EMPTY_ARRAY,
  mobile = false,
  canEdit = true,
  model,
  ModalComponent,
  search,
  filtersForCharts,
  objectForCharts,
  charts,
  onChangeColumnWidths,
  builderContent,
  onStartEditingBuilderContent,
  modalExtraProps,
  homepages,
  showDateShortcut,
  setContentHeight,
  index,
  handleBustCache,
  ranges,
  todayDateInBusinessTimeZone,
  isDashletUpdating,
}) => {
  const dashletContainer = useRef(null);
  const { t } = useTranslation();

  const isSummaryQueryEnabled = getIsSummaryQueryEnabled(dashlet);

  const handleFetchSummaryData = useCallback(
    async (bustCache = false) => {
      return getSummaryData({
        dashboardId,
        dashletId: dashlet.id,
        dateFilter,
        teamFilter,
        search,
        filterInfo: filtersForCharts,
        bustCache,
      });
    },
    [dashlet, dashboardId, dateFilter, teamFilter, search, filtersForCharts]
  );

  const { data, isLoading, isRefetching } = useManagedQuery({
    dashletId: dashlet.id,
    queryKey: getDashletQueryKey(
      {
        dashletData: dashlet,
        dateFilter,
        teamFilter,
        search,
        filterInfo: filtersForCharts,
      },
      {
        ignoreObjectId: homepages,
        ignoreFilters: homepages,
      }
    ),
    queryFn: handleFetchSummaryData,
    enabled: isSummaryQueryEnabled,
    additionalParams: {
      staleTime: DASHLET_SLOW_STALE_TIME,
      retry: (failureCount, error) => {
        if (
          error.response?.status === 504 ||
          (error.response?.status >= 400 && error.response?.status <= 499)
        ) {
          return false;
        }

        return failureCount < 3;
      },
    },
  });

  const isScheduledActivitiesBlock =
    dashlet?.config?.reportType === 'scheduled_activities';
  const isTableOfRecords = dashlet?.config?.reportType === AREA_RESPONSES.TABLE;
  const isHtmlBlock = dashlet?.config?.reportType === 'html';

  const isManaged =
    !isScheduledActivitiesBlock && !isTableOfRecords && !isHtmlBlock;

  useVisibility({
    id: dashlet?.id,
    index,
    ref: dashletContainer,
    // We have some dashlets that fetch their data in unique ways. This ensures that they
    // don't get registered as candidates for visibility management and processive
    // loading, as only the /data endpoint for dashlets participates in progressive loading
    shouldRegister: isManaged,
    instanceId: dashboardId,
  });

  useBasicVisibility({
    id: dashlet?.id,
    ref: dashletContainer,
    // We want to track visibility of dashlets that aren't managed as well,
    // so we can make some performance optimizations even if they don't use
    // managed queries
    shouldRegister: !isManaged,
  });

  if (model?.error) {
    return (
      <DashletError
        override={t(
          'You do not have permissions to view the object in this chart. Please contact your administrator to increase your access level.'
        )}
        dashlet={dashlet}
        charts
        model={model}
        ModalComponent={ModalComponent}
        isDraggable={isDraggable}
        // Will be passed to the ModalComponent as default props
        modalComponentProps={{ hasAccess: false }}
        // The dashlet can't be edited, but we need to pass an edit handler
        // to enable the "edit" option, in order to show the error message.
        // A no-op function is sufficient here.
        editDashlet={() => {}}
        onDelete={onDeleteDashlet}
        canEdit={canEdit}
        center
      />
    );
  }

  return (
    <DashletRenderer
      dashlet={dashlet}
      onDeleteDashlet={onDeleteDashlet}
      isDraggable={isDraggable}
      editDashlet={editDashlet}
      dashboardId={dashboardId}
      breakpoint={breakpoint}
      dateFilter={dateFilter}
      teamFilter={teamFilter}
      mobile={mobile}
      canEdit={canEdit}
      model={model}
      ModalComponent={ModalComponent}
      search={search}
      filtersForCharts={filtersForCharts}
      objectForCharts={objectForCharts}
      charts={charts}
      onChangeColumnWidths={onChangeColumnWidths}
      builderContent={builderContent}
      onStartEditingBuilderContent={onStartEditingBuilderContent}
      modalExtraProps={modalExtraProps}
      homepages={homepages}
      showDateShortcut={showDateShortcut}
      setContentHeight={setContentHeight}
      data={data}
      isLoading={isLoading}
      isRefetching={isRefetching}
      dashletContainer={dashletContainer}
      handleBustCache={handleBustCache}
      isManaged={isManaged}
      duplicateDashlet={duplicateDashlet}
      ranges={ranges}
      todayDateInBusinessTimeZone={todayDateInBusinessTimeZone}
      isDashletUpdating={isDashletUpdating}
    />
  );
};

export default Dashlet;
