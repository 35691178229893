import Select from 'components/Inputs/Select';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import icons from './iconList';
import IconSelectorControl from './components/Control';
import IconSelectorMenu from './components/Menu';
import IconSelectorOption from './components/Option';
import IconSelectorSingleValue from './components/SingleValue';
import { filterFontIcons, flattenIcons, getDefaultIconOptions } from './utils';

const getParseableValue = (value) => {
  if (typeof value === 'string') {
    return {
      selectValue: { label: value, value: value },
      isDefault: true,
    };
  } else if (value.icon && value.name) {
    return {
      selectValue: {
        label: value.name,
        value: value.name,
      },
      isDefault: false,
    };
  } else {
    return { selectValue: value, isDefault: false };
  }
};

const options = [
  ...icons
    .filter((icon) => filterFontIcons(icon, flattenIcons()))
    .map((icon) => {
      return {
        label: icon.name,
        value: icon.name,
        className: icon.className,
      };
    }),
  ...getDefaultIconOptions(),
].sort((a, b) => a.label.localeCompare(b.label));

const optionsMapping = options.reduce((acc, curr, index) => {
  return {
    ...acc,
    [curr.value]: index,
  };
}, {});

const MENU_HEIGHT = 250;

const IconSelector = ({
  value: lastSavedValue,
  onChange,
  onOpenMenu,
  onCloseMenu,
  index,
  safeWidth,
  child,
  isParent,
  ...others
}) => {
  const [internalValue, setInternalValue] = useState(lastSavedValue);
  const shouldClearRef = useRef(true);
  const container = useRef(null);

  const { selectValue, isDefault } = useMemo(() => {
    return getParseableValue(internalValue);
  }, [internalValue]);

  const { selectValue: displaySelectValue, isDefault: displayIsDefault } =
    useMemo(() => {
      return getParseableValue(lastSavedValue);
    }, [lastSavedValue]);

  const handleChange = useCallback(({ value }) => {
    setInternalValue(options[optionsMapping[value]]);
  }, []);

  const handleApply = useCallback(() => {
    shouldClearRef.current = false;
    onChange(internalValue);
  }, [onChange, internalValue]);

  const handleClear = useCallback(() => {
    setInternalValue(lastSavedValue);
  }, [lastSavedValue]);

  const handleClose = useCallback(() => {
    if (shouldClearRef.current) {
      handleClear();
    }
    onCloseMenu?.();
  }, [onCloseMenu, handleClear]);

  const [menuDirection, setMenuDirection] = useState('bottom');
  const calculateShift = useCallback(() => {
    const element = container.current;
    const bottom = element?.getBoundingClientRect().bottom ?? 0;
    const safeBottom = window.innerHeight - MENU_HEIGHT - 60;

    if (bottom > safeBottom) {
      setMenuDirection('top');
    } else {
      setMenuDirection('bottom');
    }
  }, []);

  const handleOpen = useCallback(() => {
    calculateShift();
    shouldClearRef.current = true;
    onOpenMenu?.();
  }, [onOpenMenu, calculateShift]);

  return (
    <div ref={container}>
      <Select
        value={selectValue}
        onChange={handleChange}
        options={options}
        components={{
          Control: IconSelectorControl,
          SingleValue: IconSelectorSingleValue,
          Menu: IconSelectorMenu,
          Option: IconSelectorOption,
        }}
        classNamePrefix="IconSelector"
        onMenuOpen={handleOpen}
        onMenuClose={handleClose}
        isDefault={isDefault}
        externalValue={internalValue}
        externalOptions={options}
        optionsMapping={optionsMapping}
        isSearchable={false}
        closeMenuOnSelect={false}
        displaySelectValue={displaySelectValue}
        displayIsDefault={displayIsDefault}
        lastSavedValue={lastSavedValue}
        onApply={handleApply}
        onClear={handleClear}
        shouldClearRef={shouldClearRef}
        safeWidth={safeWidth}
        child={child}
        isParent={isParent}
        menuPlacement={menuDirection}
        menuHeight={MENU_HEIGHT}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => {
            const safeRight = window.innerWidth - 460 - 20;
            const left =
              base.left > safeRight
                ? base.left - (base.left - safeRight)
                : base.left;
            return { ...base, left, zIndex: 9999 };
          },
        }}
        {...others}
      />
    </div>
  );
};

export default IconSelector;
