import { useMemo, FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useSmartConnectorsContext } from './context';
import { useColumns } from './columns';

import BigTableLayout, {
  useBigTableLayout,
} from '__components/Layout/BigTableLayout';
import PageToolbar, {
  PageSearchInput,
  PageToolbarSection,
  PageToolbarTitle,
} from '__components/Layout/PageToolbar';
import TablePagination from '__components/Tables/Big/TablePagination';
import BigTable from '__components/Tables/Big';
import { TRow } from '__components/Kizen/Table';
import { Button } from '@kizen/kds/Button';
import { SMARTCONNECTORS_PATHNAME } from './constants';
import { useSmartConnectorAccess } from './hooks/useSmartConnectorAccess';
import { getSortValue } from 'utility/SortingHelpers';

export const DesktopLayout: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { canAddSmartConnector } = useSmartConnectorAccess();

  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout();

  const context = useSmartConnectorsContext();

  const { handleChangeSort, sort } = context;

  const columns = useColumns();

  const headData = useMemo(
    () => ({
      meta: {
        sort: getSortValue(sort),
        onSort: handleChangeSort,
      },
    }),
    [sort, handleChangeSort]
  );

  return (
    <>
      <BigTableLayout
        toolbar={
          <PageToolbar>
            <PageToolbarSection>
              <PageSearchInput
                placeholder={t('Find SmartConnectors')}
                value={context.search}
                onChange={context.handleChangeSearch}
                loading={context.loadingSmartConnectors}
                isClearable
                maxWidth="200px"
              />
            </PageToolbarSection>
            <PageToolbarTitle
              count={context.totalSmartConnectors}
              single={t('SmartConnector')}
              plural={t('SmartConnectors')}
            />
            <PageToolbarSection>
              {canAddSmartConnector ? (
                <Button
                  onClick={() => {
                    history.push(`${SMARTCONNECTORS_PATHNAME}/new`);
                  }}
                  data-qa={'new-smartconnector'}
                  disabled={!canAddSmartConnector}
                >
                  {t('New SmartConnector')}
                </Button>
              ) : null}
            </PageToolbarSection>
          </PageToolbar>
        }
        pagination={
          <TablePagination
            page={context.pageNumber}
            perPage={context.pageSize}
            totalCount={context.totalSmartConnectors}
            onChangePage={context.handleChangePageNumber}
            onChangePerPage={context.handleChangePageSize}
          />
        }
        {...bigTablePageProps}
        recordTabSpacing={false}
      >
        <BigTable
          scrolledToTable={false}
          columns={columns}
          head={<TRow head columns={columns} data={headData} />}
          staleData={context.loadingSmartConnectors}
        >
          {!context.smartConnectors.length ? (
            <tr />
          ) : (
            context.smartConnectors.map((smartConnector) => (
              <TRow
                key={smartConnector.id}
                columns={columns}
                data={smartConnector}
                data-qa-smartConnector={smartConnector.id}
              />
            ))
          )}
        </BigTable>
      </BigTableLayout>
      );
    </>
  );
};
