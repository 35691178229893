import { useRef, useState } from 'react';

import { CONTEXTS } from 'components/Wizards/utils';
import { TitlesRow } from './TitlesRow';
import {
  FitWrapper,
  HiddenWrapper,
  PreviewWrapper,
  StyledSelect,
  SelectWrapper,
} from './styles';
import { useDebounce } from 'react-use';
import { COLLAPSE_WIDTH } from './constants';

export const ColumnsHeaderPreview = ({
  columns,
  context = CONTEXTS.fields,
  onChangeCollapse = null,
  collapse = false,
  onResize,
}) => {
  const [selectValue, setSelectValue] = useState(columns?.[0]);
  const navigatorRef = useRef();
  const isObjectContext = context === CONTEXTS.objects;
  // update preview section width on columns change
  useDebounce(
    () => {
      // get preview section width
      if (context === CONTEXTS.objects && onChangeCollapse) {
        onChangeCollapse(navigatorRef.current?.clientWidth > COLLAPSE_WIDTH);
      }
    },
    0,
    [context, onChangeCollapse, columns]
  );

  return (
    <PreviewWrapper>
      <FitWrapper fitContent={isObjectContext}>
        {isObjectContext && (
          <HiddenWrapper ref={navigatorRef}>
            <TitlesRow columns={columns} context={context} />
          </HiddenWrapper>
        )}
        {collapse ? (
          <SelectWrapper>
            <StyledSelect
              options={columns}
              onChange={setSelectValue}
              value={selectValue || columns[0]}
            />
          </SelectWrapper>
        ) : (
          <TitlesRow columns={columns} context={context} onResize={onResize} />
        )}
      </FitWrapper>
    </PreviewWrapper>
  );
};
