import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useViewerContext } from '@kizen/page-builder/viewer';
import { useFlashTransition } from '../../../hooks/useFlashState';
import { gutters } from '../../../app/spacing';
import FileInput from '../../Inputs/FileInput';
import CheckboxGroup, { CheckboxSingle } from '../../Inputs/CheckboxGroup';
import TextInput from '../../Inputs/TextInput';
import { ValidatedWholeNumberTextInput } from '../../Inputs/TextInput/presets/WholeNumber';
import LongText from '../../Inputs/LongText';
import RadioGroup from '../../Inputs/RadioGroup';
import EmailAddressTextInput from '../../Inputs/TextInput/presets/EmailAddress';
import { ValidatedDecimalNumberTextInput } from '../../Inputs/TextInput/presets/DecimalNumber';
import { ValidatedPriceNumberTextInput } from '../../Inputs/TextInput/presets/PriceNumber';
import Rating from '../../Inputs/Rating';
import DateInput from '../../Inputs/DateInput';
import DateTimeInput from '../../Inputs/DateTimeInput';
import StylePassthrough from '../../Kizen/StylePassthrough';
import { integerToOption, optionToInteger } from '../../helpers';
import { TransformToSelectOptions } from '../../../utility/TransformToSelectOptions';
import { FIELD_TYPES } from '../../../utility/constants';
import { FIELD_TYPE_STAGE_DROPDOWN } from '../../Fields/FieldInput/helpers';
import SelectTeamMember from './SelectTeamMember';
import Dropdown from './Dropdown';
import YesNoMaybe from './YesNoMaybe';
import PhoneNumber from './PhoneNumber';
import Timezone from './Timezone';
import { getCurrencySymbol } from '../helpers';
import { getDataQaLabel, transformDateDown, transformDateUp } from './helpers';
import ClearSelectButton from '../../Inputs/Select/ClearButton';
import ApplySelectButton from '../../Inputs/Select/ApplyButton';
import { isPercentageChanceToCloseField } from '../../../checks/fields';
const noop = () => null;

// TODO We need a quick fix for the fact that components don't have standard bottom spacing,
// yet we need them to appear uniformly in this form. So we'll make some exceptions up here and apply them below.

const BottomMarginFix = styled(StylePassthrough)`
  ${({ margin }) =>
    margin &&
    css`
      margin-bottom: ${gutters.spacing(3, -5)}px;
    `}
  // Most of the time legacy input components have labels
  // that provide some amount of bottom margin,
  // which we want to just remove for now and rely entirely
  // on the wrapper's margin (set above).
  ${({ collapseLabel }) =>
    collapseLabel &&
    css`
      label {
        margin-bottom: 0;
      }
    `}
`;

const FieldInput = ({
  object,
  field,
  fetchUrl,
  value,
  onChange = noop,
  isGetFullObject = false,
  label = '',
  error = null,
  validateSettings = {},
  serviceToUse = {},
  objectId = null,
  ...others
}) => {
  const fieldRef = useRef();
  const [message, showMessage, flash] = useFlashTransition();
  const { businessId } = useViewerContext();
  const validate = {
    message,
    showMessage,
    ...validateSettings,
  };

  useEffect(() => {
    if (error && error.message) {
      flash(error.message);
      if (fieldRef.current?.scrollIntoView) {
        fieldRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      // If the field is a select, we need to get the input ref because
      // react-select adds a bunch of stuff to the ref that it's passed
      if (fieldRef.current?.select?.inputRef?.scrollIntoView) {
        fieldRef.current.select.inputRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [error, flash]);

  const { fieldType, displayName } = field;
  others.label = label === false ? false : label || displayName;
  others.viewable = Boolean(field.access && field.access.view);
  others.variant = others.variant ?? 'outline';

  if (fieldType === FIELD_TYPES.Text.type) {
    return (
      <TextInput
        ref={fieldRef}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
        placeholder={
          typeof others.placeholder === 'string'
            ? others.placeholder
            : field.displayName
        }
      />
    );
  }
  if (fieldType === FIELD_TYPES.Email.type) {
    return (
      <EmailAddressTextInput
        ref={fieldRef}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.PhoneNumber.type) {
    return (
      <PhoneNumber
        ref={fieldRef}
        field={field}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Integer.type) {
    return (
      <ValidatedWholeNumberTextInput
        ref={fieldRef}
        value={value || value === 0 ? value : ''}
        onChange={onChange}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Decimal.type) {
    return (
      <ValidatedDecimalNumberTextInput
        ref={fieldRef}
        value={value || value === 0 ? value : ''}
        onChange={onChange}
        validate={validate}
        error={error}
        decimalOptions={field.decimalOptions}
        data-qa={getDataQaLabel(field)}
        {...others}
        {...(isPercentageChanceToCloseField(field) && {
          decimalScale: 2,
        })}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Money.type) {
    return (
      <ValidatedPriceNumberTextInput
        ref={fieldRef}
        value={value || value === 0 ? value : ''}
        onChange={onChange}
        currencySymbol={getCurrencySymbol(field)}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.LongText.type) {
    return (
      <LongText
        ref={fieldRef}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
        placeholder={
          typeof others.placeholder === 'string'
            ? others.placeholder
            : field.displayName
        }
      />
    );
  }
  if (fieldType === FIELD_TYPES.Date.type) {
    return (
      <DateInput
        ref={fieldRef}
        value={transformDateDown(value)}
        onChange={(val) => {
          onChange(transformDateUp(val));
        }}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        field={field}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(transformDateUp());
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.DateTime.type) {
    return (
      <DateTimeInput
        ref={fieldRef}
        value={value ? new Date(value) : null}
        onChange={(val) => onChange(val ? val.toISOString() : null)}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        field={field}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Checkbox.type) {
    return (
      <CheckboxSingle
        ref={fieldRef}
        checked={value}
        onChange={onChange}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Checkboxes.type) {
    return (
      <CheckboxGroup
        ref={fieldRef}
        onChange={(val) => {
          // API expects only option UUIDs for checkbox field submissions
          onChange(val.map(({ value: id }) => id));
        }}
        options={TransformToSelectOptions(field.options, 'id', 'name')}
        value={value}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Radio.type) {
    return (
      <RadioGroup
        ref={fieldRef}
        onChange={(res) => {
          // API expects only option UUID for radio field submissions
          onChange(res?.value ?? null);
        }}
        options={TransformToSelectOptions(field.options, 'id', 'name')}
        value={value}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        showMenuLeftButton
        showMenuRightButton
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.YesNoMaybe.type) {
    return (
      <YesNoMaybe
        ref={fieldRef}
        field={field}
        value={value}
        onChange={onChange}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        showMenuLeftButton
        showMenuRightButton
        {...others}
      />
    );
  }
  if (
    fieldType === FIELD_TYPES.Choices.type ||
    fieldType === FIELD_TYPES.Dropdown.type ||
    fieldType === FIELD_TYPES.Status.type ||
    fieldType === FIELD_TYPE_STAGE_DROPDOWN
  ) {
    return (
      <Dropdown
        ref={fieldRef}
        field={field}
        value={value}
        onChange={onChange}
        validate={validate}
        {...(fieldType === FIELD_TYPE_STAGE_DROPDOWN && {
          menuLeftButton: null,
        })}
        error={error}
        data-qa={getDataQaLabel(field)}
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Rating.type) {
    return (
      <Rating
        ref={fieldRef}
        allowDynamicVariant={false}
        maxRating={
          field.customObjectField?.rating?.maxValue || field.rating.maxValue
        }
        lowLabel={field.rating.minLabel}
        highLabel={field.rating.maxLabel}
        value={integerToOption(value)}
        onChange={(val) => onChange(optionToInteger(val))}
        validate={validate}
        error={error}
        data-qa={getDataQaLabel(field)}
        rating={field.customObjectField?.rating || field.rating}
        menuLeftButton={
          field.isRequired ? null : (
            <ClearSelectButton
              onClick={() => {
                onChange(null);
              }}
            />
          )
        }
        menuRightButton={<ApplySelectButton />}
        showMenuLeftButton
        showMenuRightButton
        {...others}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Files.type) {
    const { variant, margin, ...fileInputProps } = others;
    return (
      <BottomMarginFix margin={margin} collapseLabel>
        <FileInput
          ref={fieldRef}
          label="File Input"
          files={value || []}
          error={error}
          onChange={onChange}
          variant={variant === 'underline' ? 'underline' : 'outline'}
          validate={validate}
          data-qa={getDataQaLabel(field)}
          businessId={businessId}
          {...fileInputProps}
          {...others}
        />
      </BottomMarginFix>
    );
  }
  if (fieldType === FIELD_TYPES.TeamSelector.type) {
    return (
      <SelectTeamMember
        ref={fieldRef}
        value={value ?? null}
        data-qa={getDataQaLabel(field)}
        error={error}
        onChange={onChange}
        validate={validate}
        {...others}
        options={field.options}
      />
    );
  }
  if (fieldType === FIELD_TYPES.Timezone.type) {
    return (
      <Timezone
        ref={fieldRef}
        field={field}
        value={value}
        onChange={onChange}
        error={error}
        validate={validate}
        menuLeftButton={field.isRequired ? null : <ClearSelectButton />}
        menuRightButton={<ApplySelectButton />}
        fieldType={fieldType}
        {...others}
      />
    );
  }
  return null;
};

export default FieldInput;
