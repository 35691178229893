import Menu, { ApplyMenuButton, ClearMenuButton } from 'components/Kizen/Menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { StyledIconSelectorMenu } from './styles';

const MENU_WIDTH = 460;

const IconSelectorMenu = ({ children, innerRef, innerProps, ...rest }) => {
  const { selectProps } = rest;
  const { t } = useTranslation();
  return (
    <StyledIconSelectorMenu
      ref={innerRef}
      {...innerProps}
      width={MENU_WIDTH}
      height={selectProps.menuHeight}
    >
      <components.Menu {...rest}>
        <Menu
          className="InnerMenu"
          rightButton={
            <ApplyMenuButton
              onClick={() => {
                selectProps.onApply();
                selectProps.selectManager.onMenuClose();
              }}
            />
          }
          leftButton={
            <ClearMenuButton
              onClick={() => {
                selectProps.selectManager.onMenuClose();
                selectProps.onClear();
              }}
            >
              {t('Cancel')}
            </ClearMenuButton>
          }
          {...rest}
          placement={false}
        >
          {children}
        </Menu>
      </components.Menu>
    </StyledIconSelectorMenu>
  );
};

export default IconSelectorMenu;
