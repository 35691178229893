import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import {
  StyledLabelWrapper,
  StyledLabel,
  StyledTextareaWrapper,
  StyledTextarea,
} from './styles';

const OutlinedTextarea = ({
  error,
  className,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  underline,
  id,
  rows,
  resize,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={className}>
      <StyledLabelWrapper htmlFor={id}>
        {label && <StyledLabel disabled={disabled}>{label}</StyledLabel>}
        <StyledTextareaWrapper
          focused={focused}
          error={error}
          underline={underline}
          disabled={disabled}
        >
          <StyledTextarea
            value={value}
            placeholder={placeholder}
            id={id}
            onChange={(event) => onChange(event.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            disabled={disabled}
            rows={rows}
            resize={resize}
          />
        </StyledTextareaWrapper>
      </StyledLabelWrapper>
    </div>
  );
};

OutlinedTextarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  underline: PropTypes.bool,
  rows: PropTypes.string,
  resize: PropTypes.oneOf(['none', 'vertical']),
};

OutlinedTextarea.defaultProps = {
  placeholder: '',
  id: '',
  className: '',
  error: false,
  disabled: false,
  underline: false,
  rows: '3',
  resize: 'none',
};

export default OutlinedTextarea;
