import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { breakpoints, useBreakpoint } from 'app/spacing';
import { getCalendarViewToggleOptions, useCalendar } from 'components/Calendar';
import ToggleButton from 'components/Kizen/ToggleButton';
import Button from 'components/Button';
import { PageContentContainer } from 'components/Layout/PageContentWidth';
import useModal from 'components/Modals/useModal';
import ScheduleBroadcastModal from './components/ScheduleBroadcastModal/index';
import { useTranslation } from 'react-i18next';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useNavBarState } from 'app/navBarController';
import {
  CalendarCell,
  CalendarFiltersCell,
  Grid,
  LeftColumn,
  ScheduleBroadcastButton,
  ScheduleBroadcastCell,
  StyledBroadcastCalendar,
  StyledIncrementalDatePicker,
} from './styles';
import { duplicateBrodcast } from './helpers';
import { CalendarEvents } from './components/CalendarEvents';
import { useBroadcastTimeZoneNotice } from './hooks/useBroadcastTimeZoneNotice';
import { BROADCAST_FORMDATA } from './components/ScheduleBroadcastModal/constants';
import {
  adjustDateToNearestFuture,
  getBroadcastFormData,
} from './components/ScheduleBroadcastModal/helpers';
import BroadcastService from 'services/BroadcastService';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import {
  getBroadcastsAccess,
  getBusinessClientObject,
  getBusinessTimeZone,
} from 'store/authentication/selectors';
import { EVENT_ACTIONS, EVENT_STATUSES, EVENT_TYPES } from './constants';
import { getOriginalError } from 'services/AxiosService';
import { useToast, toastVariant } from 'components/ToastProvider';
import { useDefaultModelQuery } from 'queries/models/custom-objects';
import { BroadcastStatisticsModal } from './components/BroadcastStatisticsModal/index';

const BroadcastPage = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  useBroadcastTimeZoneNotice();

  const businessTimezone = useSelector((s) => getBusinessTimeZone(s));

  const [broadcast, setBroadcast] = useState(null);

  const [date, calendarView, { next, previous, setToday, setCalendarView }] =
    useCalendar();

  const [showToast] = useToast();

  const handleError = (err) => {
    const errors = getOriginalError(err) || {};
    let message = t(
      'An error occured while processing your request. The broadcast was not submited'
    );
    if (errors.query) {
      message = t(
        'A filtering error has occurred, please contact support if the problem persists.'
      );
    } else {
      const firstError = Object.values(errors)[0];
      if (Array.isArray(firstError) && typeof firstError[0] === 'string') {
        message = firstError[0];
      }
    }
    showToast({
      message,
      variant: toastVariant.FAILURE,
    });
  };

  const [
    modalProps,
    { onClick },
    { show: showBroadcastModal, hide: hideBroadcastModal },
  ] = useModal({
    handleSubmit: async (isEdit, payload) => {
      await (isEdit
        ? BroadcastService.updateBroadcast(payload.id, payload)
        : BroadcastService.addBroadcast(payload));
      setBroadcast(null);
    },
    handleError,
  });

  const [statsModalProps, , { show: showStatsModal }] = useModal();

  const [deletionModalProps, , { show: onDelete }] = useModal({
    handleSubmit: async () => {
      await BroadcastService.deleteBroadcast(broadcast?.id);
      setBroadcast(null);
      hideBroadcastModal();
    },
    handleError,
  });

  const isMobile = useBreakpoint(breakpoints.lg);
  const { height } = useNavBarState();

  const calendarViewToggleOptions = useMemo(
    () => getCalendarViewToggleOptions(t),
    [t]
  );

  const { canView, canScheduleBroadcasts, access } =
    useSelector(getBroadcastsAccess);

  const businessClientObject = useSelector(getBusinessClientObject);
  const { data: clientObject } = useDefaultModelQuery(businessClientObject.id, {
    enabled: !!access[EVENT_TYPES.email.accessKey]?.view,
  });

  const onShowBroadcastModal = useCallback(
    (b) => {
      (b.id
        ? access[EVENT_TYPES[b.type]?.accessKey]?.view
        : canScheduleBroadcasts) &&
        setBroadcast(
          getBroadcastFormData({
            ...b,
            [BROADCAST_FORMDATA.date]: new Date(
              b.id
                ? b[BROADCAST_FORMDATA.date]
                : adjustDateToNearestFuture(
                    b[BROADCAST_FORMDATA.date],
                    calendarView
                  )
            ).toISOString(),
            [BROADCAST_FORMDATA.rescheduleAt]: b[
              BROADCAST_FORMDATA.rescheduleAt
            ]
              ? new Date(b[BROADCAST_FORMDATA.rescheduleAt]).toISOString()
              : null,
          })
        );
      onClick();
    },
    [access, canScheduleBroadcasts, onClick, calendarView]
  );

  const onShowStatisticsModal = useCallback(
    (b) => {
      if (b.status !== EVENT_STATUSES.failed) {
        setBroadcast(b);
        showStatsModal();
      }
    },
    [showStatsModal]
  );

  const onSelectAction = useCallback(
    (action, b) => {
      switch (action) {
        case EVENT_ACTIONS.stats: {
          onShowStatisticsModal(b);
          break;
        }
        case EVENT_ACTIONS.edit: {
          setBroadcast(getBroadcastFormData(b));
          showBroadcastModal();
          break;
        }
        case EVENT_ACTIONS.duplicate: {
          setBroadcast(getBroadcastFormData(duplicateBrodcast(b)));
          showBroadcastModal();
          break;
        }
        case EVENT_ACTIONS.delete: {
          setBroadcast(b);
          onDelete();
          break;
        }
        default: {
          break;
        }
      }
    },
    [showBroadcastModal, onDelete, onShowStatisticsModal]
  );

  if (!canView) {
    const error = new Error();
    error.code = 404;
    throw error;
  }

  return (
    <>
      <PageContentContainer data-qa="broadcasts-page">
        <Grid height={height}>
          {isMobile ? null : (
            <>
              <CalendarFiltersCell>
                <div>
                  <Button
                    variant="light"
                    color="blue"
                    noSpace
                    onClick={setToday}
                  >
                    {t('Today')}
                  </Button>
                </div>
                <StyledIncrementalDatePicker
                  view={calendarView}
                  previous={previous}
                  next={next}
                  date={date}
                  label={
                    calendarViewToggleOptions.find(
                      ({ value }) => value === calendarView
                    )?.label || ''
                  }
                />
                <ToggleButton
                  value={calendarView}
                  options={calendarViewToggleOptions}
                  onChange={(opt) => setCalendarView(opt.value)}
                />
              </CalendarFiltersCell>
              <CalendarCell
                calendarView={calendarView}
                data-qa="scheduled-events-calendar"
              >
                <StyledBroadcastCalendar
                  date={date}
                  view={calendarView}
                  isCalendarModalOpen={modalProps.show || statsModalProps.show}
                  broadcast={broadcast}
                  onShowStatisticsModal={onShowStatisticsModal}
                  onShowBroadcastModal={onShowBroadcastModal}
                  canScheduleBroadcasts={canScheduleBroadcasts}
                />
              </CalendarCell>
            </>
          )}
          <LeftColumn
            height={height}
            navBarHeight={height}
            data-qa="scheduled-events-list"
          >
            <ScheduleBroadcastCell>
              {canScheduleBroadcasts ? (
                <ScheduleBroadcastButton
                  variant="default"
                  noSpace
                  onClick={() => {
                    setBroadcast(getBroadcastFormData());
                    onClick();
                  }}
                  data-qa-schedule-button
                >
                  {t('Schedule Broadcast')}
                </ScheduleBroadcastButton>
              ) : null}
            </ScheduleBroadcastCell>
            <CalendarEvents
              navBarHeight={height}
              access={access}
              broadcast={broadcast}
              onSelectAction={onSelectAction}
            />
          </LeftColumn>
        </Grid>
      </PageContentContainer>
      {broadcast && modalProps.show ? (
        <ScheduleBroadcastModal
          {...modalProps}
          broadcast={broadcast}
          clientObject={clientObject}
          businessTimezone={businessTimezone}
          onDelete={onDelete}
          access={access}
        />
      ) : null}
      {broadcast && statsModalProps.show ? (
        <BroadcastStatisticsModal broadcast={broadcast} {...statsModalProps} />
      ) : null}
      <ConfirmDeletionModal {...deletionModalProps}>
        {t(
          'This will permanently delete the Broadcast and all associated data with it from your database.'
        )}
      </ConfirmDeletionModal>
    </>
  );
};

export default BroadcastPage;
