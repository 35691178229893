import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import AdsService from '__services/AdvsService';
import TextInput from 'components/Inputs/TextInput';
import Select from 'components/Inputs/Select';
import LeadSourceSelect from './LeadSourceSelect';
import UrlTextInput from 'components/Inputs/TextInput/presets/Url';

const SOURCE_VALUES = {
  CUSTOM: 'custom',
  DIRECT: 'direct_traffic',
  FACEBOOK: 'facebook_ads',
  GOOGLE: 'google_ads',
  ORGANIC_SEARCH: 'organic_search',
  SITE_REFERRAL: 'site_referral',
  SOCIAL: 'social',
  UTM: 'utm',
};

const IMPRESSION_VALUES = {
  CUSTOM: 'custom_lead_source',
  DIRECT_TRAFFIC: 'direct_traffic',
  GOOGLE: 'google_ad_click',
  ORGANIC_SEARCH: 'organic_search',
  SITE_REFERRAL: 'site_referral_condition',
  SOCIAL: 'social',
  UTM: 'utm',
};

const LeadSourceOptions = (t) => [
  {
    label: t('Direct Traffic'),
    value: SOURCE_VALUES.DIRECT,
    impressionValue: IMPRESSION_VALUES.DIRECT_TRAFFIC,
  },
  {
    label: t('Organic Search'),
    value: SOURCE_VALUES.ORGANIC_SEARCH,
    impressionValue: IMPRESSION_VALUES.ORGANIC_SEARCH,
  },
  {
    label: t('Site Referral'),
    value: SOURCE_VALUES.SITE_REFERRAL,
    impressionValue: IMPRESSION_VALUES.SITE_REFERRAL,
  },
  {
    label: t('Google Ads'),
    value: SOURCE_VALUES.GOOGLE,
    impressionValue: IMPRESSION_VALUES.GOOGLE,
  },
  {
    label: t('Social'),
    value: SOURCE_VALUES.SOCIAL,
    impressionValue: IMPRESSION_VALUES.SOCIAL,
  },
  {
    label: t('UTM Parameters'),
    value: SOURCE_VALUES.UTM,
    impressionValue: IMPRESSION_VALUES.UTM,
  },
  {
    label: t('Custom Source'),
    value: SOURCE_VALUES.CUSTOM,
    impressionValue: IMPRESSION_VALUES.CUSTOM,
  },
];

const valueToSelectOption = (values) =>
  values.map((value) => ({
    ...value,
    value: value.id,
    label: value.name,
  }));

const AddLeadSourceForm = ({ values, onChange }) => {
  const { t } = useTranslation();

  const [{ value: campaigns = [] }, fetchCampaigns] = useAsyncFn(
    async (adProvider) => {
      const data = await AdsService.getCampaignsForLeadSource(adProvider);
      return valueToSelectOption(data);
    }
  );

  const [{ value: mediums = [] }, fetchMediums] = useAsyncFn(
    async (adCampaignId) => {
      const data = await AdsService.getGroupsForLeadSource(adCampaignId);
      return valueToSelectOption(data);
    }
  );

  const [{ value: terms = [] }, fetchTerms] = useAsyncFn(async (adGroupId) => {
    const data = await AdsService.getAdsForLeadSource(adGroupId);
    return valueToSelectOption(data);
  });

  const formFields = useMemo(() => {
    switch (values && values.type) {
      case SOURCE_VALUES.ORGANIC_SEARCH:
      case SOURCE_VALUES.DIRECT:
        return null;
      case SOURCE_VALUES.SITE_REFERRAL:
        return (
          <UrlTextInput
            label={t('Choose Campaign (Referring Site)')}
            value={values.referralUrl}
            onChange={(value) => onChange('referralUrl', value)}
            required
            menuInline
            inModal
            margin
          />
        );
      case SOURCE_VALUES.FACEBOOK:
      case SOURCE_VALUES.GOOGLE:
        return (
          <>
            <Select
              fullWidth
              label={t('Choose Campaign')}
              placeholder={t('Choose Campaign')}
              required
              options={campaigns}
              value={values.campaign}
              onChange={(selected) => {
                onChange('campaign', {
                  campaign: selected.value,
                  source: selected.adAccount.id,
                });
                fetchMediums(selected.value);
              }}
              menuInline
              inModal
              margin
            />
            {values.campaign && (
              <Select
                fullWidth
                label={t('Choose Medium (Ad Group)')}
                placeholder={t('Choose Medium')}
                required
                options={mediums}
                value={values.medium}
                onChange={(selected) => {
                  onChange('medium', selected.value);
                  fetchTerms(selected.value);
                }}
                menuInline
                inModal
                margin
              />
            )}
            {values.medium && (
              <Select
                fullWidth
                label={t('Choose Term (Ad Name)')}
                placeholder={t('Choose Term')}
                required
                options={terms}
                value={values.term}
                onChange={(selected) => onChange('term', selected.value)}
                menuInline
                inModal
                margin
              />
            )}
            {values.term && (
              <TextInput
                label={t('Choose Content (Keyword)')}
                placeholder={t('Feed, Stories, etc.')}
                required
                value={values.content}
                onChange={(value) => onChange('content', value)}
                menuInline
                inModal
                margin
              />
            )}
          </>
        );
      case SOURCE_VALUES.UTM:
      case SOURCE_VALUES.CUSTOM:
        return (
          <>
            <LeadSourceSelect
              label={t('Source')}
              fieldName="source"
              onChange={onChange}
              values={values}
              required
            />
            <LeadSourceSelect
              label={t('Campaign')}
              fieldName="campaign"
              values={values}
              onChange={onChange}
            />
            <LeadSourceSelect
              label={t('Medium')}
              fieldName="medium"
              values={values}
              onChange={onChange}
            />
            <LeadSourceSelect
              label={t('Term')}
              fieldName="term"
              values={values}
              onChange={onChange}
            />
            <LeadSourceSelect
              label={t('Content')}
              fieldName="content"
              values={values}
              onChange={onChange}
            />
          </>
        );
      default:
        return null;
    }
  }, [
    values,
    onChange,
    campaigns,
    mediums,
    terms,
    fetchMediums,
    fetchTerms,
    t,
  ]);

  return (
    <>
      <Select
        fullWidth
        label={t('Lead Source')}
        placeholder={t('Choose Source')}
        options={LeadSourceOptions(t)}
        value={values.type}
        onChange={async (selected) => {
          onChange('type', selected.value);
          if (selected.value === SOURCE_VALUES.FACEBOOK) {
            await fetchCampaigns('facebook');
          }
          if (selected.value === SOURCE_VALUES.GOOGLE) {
            await fetchCampaigns('google');
          }
        }}
        inModal
        menuInline
        margin
      />
      {formFields}
    </>
  );
};

export default AddLeadSourceForm;
