import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextSpan } from 'app/typography';
import { initiatorTypes } from '../../constants';

const defaultProperties = {
  prefix: (t) => t('by'),
  initiator: (t) => t('Opt In'),
};

export const CardHeader = ({
  nameWeight = 'bold',
  name,
  config: getConfig,
  event,
}) => {
  const { t } = useTranslation();

  const config = getConfig(t, event);
  const [prefix, initiator, postfix] = useMemo(() => {
    if (!event.initiator || event.initiator.type === null) {
      const emptyCase = config[initiatorTypes['empty-initiator']];
      if (emptyCase) {
        const p = emptyCase.prefix || defaultProperties.prefix(t);
        const i = emptyCase.creationType
          ? emptyCase.creationType(event.initiator, event, t)
          : defaultProperties.initiator(t);
        return [p, i];
      }

      return [defaultProperties.prefix(t), defaultProperties.initiator(t)];
    }

    const p =
      config[event.initiator.type]?.prefix || defaultProperties.prefix(t);
    const i =
      config[event.initiator.type]?.creationType(event.initiator, event, t) ||
      defaultProperties.initiator(t);

    const postfix = config[event.initiator.type]?.postfix;

    return [p, i, postfix];
  }, [config, event, t]);
  return (
    <>
      <TextSpan weight={nameWeight} data-qa="field-name" size="micro">
        {name}
      </TextSpan>
      <TextSpan size="micro">&nbsp;{prefix}&nbsp;</TextSpan>
      <TextSpan weight="bold" size="micro">
        {initiator}
      </TextSpan>
      {postfix ? <TextSpan size="micro">&nbsp;{postfix}</TextSpan> : null}
    </>
  );
};
