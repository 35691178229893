import {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@kizen/kds/Typography';
import { PivotTableChart as TSPivotTableChart } from 'ts-components/PivotTableChart';
import { useQueryClient, useQueries, useQuery } from 'react-query';
import PersistentState from 'pages/Dashboard/context/persistentState';
import FieldService from 'services/FieldService';
import DashletService from 'services/DashletService';
import { DASHBOARD, FIELDS } from 'queries/query-keys';
import DashletError from '../../DashletError';
import { StyledLoader } from '../Trend/styles';
import { PivotTableChartWrapper, PivotTableFooter } from './styles';
import {
  useSubRowReducer,
  START_FETCH,
  COLLAPSE,
  FETCH_ERROR,
  FETCH_SUCCESS,
  CLEAR_STATE,
} from './hooks';
import { getValueBreakdownLabel } from '__components/Wizards/shared/components/BreakdownAndBucketing/shared/utils';
import {
  getLinkDataForTag,
  specialTagTypes,
} from '__components/Fields/helpers';

export const PivotTableChart = ({
  dashboardId,
  clientObjectId,
  data,
  dashlet,
  onChangeStandardColumnSizes,
  onChangeFixedLeftColumnSizes,
  onChangeSort,
  persistentState,
  mobile,
  model,
  search,
  dashletSearch,
  dataSizeError,
  handleBustCache,
  clearDashletSearch,
  dateFilter,
  teamFilter,
  filtersForCharts,
  reloadPivotTableSubRows,
  setReloadPivotTableSubRows,
  ...rest
}) => {
  const { state: persistentStateContext } = useContext(PersistentState.Context);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [subRowState, dispatch, batchDispatch] = useSubRowReducer();
  const subRowStateRef = useRef(subRowState);
  const verticalScrollPosition = useRef(null);
  const horizontalScrollPosition = useRef(null);
  const dashletSearchRef = useRef(dashletSearch);
  const [dashletSearchTermChanged, setDashletSearchTermChanged] =
    useState(false);
  const [visibleRowIds, setVisibleRowIds] = useState([]);
  const [fieldsById, setFieldsById] = useState({});
  const [subRowFetchError, setSubRowFetchError] = useState(false);
  const currentEmail = persistentStateContext?.[dashlet.id];

  useEffect(() => {
    setSubRowFetchError(false);
  }, [data, dashletSearch]);

  useEffect(() => {
    subRowStateRef.current = subRowState;
  }, [subRowState]);

  useEffect(() => {
    // collapse all expanded rows when dashletSearch is cleared
    if (
      dashletSearchRef.current !== '' &&
      dashletSearch === '' &&
      subRowState?.isAnyRowExpanded
    ) {
      const collapseActions = [];
      const expandedRows = Object.keys(subRowState).filter(
        (rowId) => subRowState[rowId]?.expanded
      );
      expandedRows.forEach((rowId) => {
        collapseActions.push({ type: COLLAPSE, rowId });
      });
      if (expandedRows.length > 0) {
        batchDispatch(dispatch, collapseActions);
      }
    }
    if (dashletSearch !== dashletSearchRef.current) {
      setDashletSearchTermChanged(true);
    }
    dashletSearchRef.current = dashletSearch;
  }, [dashletSearch, subRowState, dispatch, batchDispatch]);

  useEffect(() => {
    // invalidate cache when filters change
    dispatch({ type: CLEAR_STATE });
    queryClient.invalidateQueries([
      'dashboard',
      'dashlet',
      'subrows',
      dashlet.id,
    ]);
  }, [
    search,
    teamFilter,
    dateFilter,
    filtersForCharts,
    dispatch,
    queryClient,
    dashlet.id,
  ]);

  useEffect(() => {
    // invalidate subrows and clear subrowState when busting cache of dashlet
    if (reloadPivotTableSubRows) {
      queryClient.invalidateQueries([
        ...DASHBOARD.DASHLET_SUBROWS,
        dashlet?.id,
      ]);
      dispatch({ type: CLEAR_STATE });
      setReloadPivotTableSubRows(false);
    }
  }, [
    dashlet?.id,
    dispatch,
    queryClient,
    reloadPivotTableSubRows,
    setReloadPivotTableSubRows,
  ]);

  const isClient = model?.fetchUrl === 'client';
  const title = dashlet?.config?.aggregation?.rows?.[0]?.label;
  const mainRowCustomObjectId = data?.metadata?.rows?.[0]?.custom_object_id;
  const subRowCustomObjectId = data?.metadata?.rows?.[1]?.custom_object_id;
  const standardColumnSizes =
    dashlet?.config?.feExtraInfo?.pivotTableConfig?.cols;
  const fixedLeftColumnSizes =
    dashlet?.config?.feExtraInfo?.pivotTableConfig?.fixedLeftCols;
  const footerMessage = t(
    'This pivot table currently has a search applied to it. If you wish to see column and grand totals, please clear the search.'
  );
  const { data: aggregatedField, isLoading: aggregatedFieldLoading } = useQuery(
    FIELDS.GET_FIELD(dashlet?.config?.aggregation?.fieldToAggregate),
    () =>
      isClient
        ? FieldService.getClientField(
            dashlet.config.aggregation.fieldToAggregate,
            {
              skipErrorBoundary: true,
            }
          )
        : FieldService.getField(
            dashlet.config.aggregation.fieldToAggregate,
            model,
            {
              skipErrorBoundary: true,
            }
          ),
    {
      enabled:
        Boolean(dashlet?.config?.aggregation?.fieldToAggregate) &&
        Boolean(data),
    }
  );

  const fieldIds = useMemo(() => {
    const colfieldId = dashlet?.config?.aggregation?.columns?.id;
    const rowfieldIds = dashlet?.config?.aggregation?.rows?.map(({ id }) => id);
    const ids = [colfieldId, ...rowfieldIds];
    const dedupIds = new Set([...ids]);
    return [...dedupIds];
  }, [dashlet?.config?.aggregation]);

  const fieldQuries = useQueries(
    fieldIds.map((id) => {
      return {
        queryKey: FIELDS.GET_FIELD(id),
        queryFn: () =>
          isClient
            ? FieldService.getClientField(id, {
                skipErrorBoundary: true,
              })
            : FieldService.getField(id, model, {
                skipErrorBoundary: true,
              }),
      };
    })
  );

  const fetchSubRowData = useCallback(
    async (rowIds, autoExpand = false) => {
      // Dispatch actions for collapse or fetch based on the current state
      const currentState = subRowStateRef.current;

      const collapseActions = [];
      const fetchActions = [];
      const fetchRowIds = rowIds.filter((rowId) => {
        if (currentState[rowId]?.expanded && !autoExpand) {
          collapseActions.push({
            type: COLLAPSE,
            rowId,
            collapsedManually: true,
          });
          return false;
        } else if (
          !currentState[rowId]?.loading &&
          !currentState[rowId]?.collapsedManually
        ) {
          fetchActions.push({ type: START_FETCH, rowId });
          return true;
        } else if (currentState[rowId]?.collapsedManually && !autoExpand) {
          fetchActions.push({ type: START_FETCH, rowId });
          return true;
        } else if (
          currentState[rowId]?.collapsedManually &&
          dashletSearchTermChanged &&
          autoExpand
        ) {
          setDashletSearchTermChanged(false);
          fetchActions.push({ type: START_FETCH, rowId });
          return true;
        }
        return false;
      });

      batchDispatch(dispatch, [...collapseActions, ...fetchActions]);

      if (fetchRowIds.length > 0) {
        try {
          const res = await queryClient.fetchQuery(
            [...DASHBOARD.DASHLET_SUBROWS, dashlet?.id, fetchRowIds.join(',')],
            () =>
              DashletService.getPivotTableSubRows({
                dashboardId,
                dashletId: dashlet?.id,
                tableId: data?.table_id,
                rows: fetchRowIds,
                search,
                dateFilter,
                teamFilter,
                filterInfo: filtersForCharts,
                currentEmail,
              })
          );

          const successAndErrorActions = fetchRowIds.map((rowId) => {
            if (res.error || !res?.data?.data[rowId]) {
              setSubRowFetchError(true);
              return { type: FETCH_ERROR, rowId };
            } else {
              setSubRowFetchError(false);
              const subRows = res.data.data[rowId];
              return { type: FETCH_SUCCESS, rowId, data: subRows };
            }
          });
          batchDispatch(dispatch, successAndErrorActions);
        } catch (error) {
          const errorActions = fetchRowIds.map((rowId) => ({
            type: FETCH_ERROR,
            rowId,
          }));
          batchDispatch(dispatch, errorActions);
        }
      }
    },
    [
      currentEmail,
      dashlet?.id,
      data?.table_id,
      queryClient,
      dashboardId,
      search,
      dashletSearchTermChanged,
      dateFilter,
      teamFilter,
      filtersForCharts,
      dispatch,
      batchDispatch,
    ]
  );

  const fieldQuriesLoading = fieldQuries.some((q) => q.isLoading);
  const missingFields = fieldQuries.some(
    (q) => q?.error?.response?.status === 404
  );

  useEffect(() => {
    if (
      !fieldQuriesLoading &&
      fieldQuries.length !== Object.keys(fieldsById).length
    ) {
      const object = fieldQuries.reduce((acc, q) => {
        if (q?.data?.id) {
          acc[q.data.id] = q.data;
        }
        return acc;
      }, {});
      setFieldsById(object);
    }
  }, [fieldQuries, fieldQuriesLoading, fieldsById]);

  const bucketsAndFieldsById = useMemo(() => {
    const result = dashlet?.config?.aggregation?.rows.reduce((acc, row) => {
      row?.fieldsValueBreakdown?.buckets?.forEach((bucket) => {
        acc[bucket.id] = {
          bucket,
          fieldData: fieldsById[row.id],
        };
      });
      return acc;
    }, {});
    return result;
  }, [dashlet?.config?.aggregation?.rows, fieldsById]);

  const subRowIdsByRowId = useMemo(() => {
    return data?.data?.rows?.reduce((acc, row) => {
      acc[row.id] = row.subrow_ids;
      return acc;
    }, {});
  }, [data?.data?.rows]);

  // collect rowIds that matches dashletSearch and fetch subrows
  useEffect(() => {
    if (dashletSearch !== '') {
      const subRowLabels = data?.data?.subrow_labels;
      const rows = visibleRowIds;
      const actionsMap =
        rows?.reduce((acc, id) => {
          const subrow_ids = subRowIdsByRowId[id] ?? [];
          if (subrow_ids.length > 0) {
            subrow_ids.forEach((subRowIndex) => {
              const subRowLabel = subRowLabels[subRowIndex][1];
              const { bucket, fieldData } = bucketsAndFieldsById[
                subRowLabel
              ] ?? {
                bucket: false,
                fieldData: false,
              };
              if (bucket && fieldData) {
                if (
                  getValueBreakdownLabel(bucket, fieldData, t)
                    .toLowerCase()
                    .includes(dashletSearch.toLowerCase())
                ) {
                  acc.add(id);
                }
              } else if (
                subRowLabel.toLowerCase().includes(dashletSearch.toLowerCase())
              ) {
                acc.add(id);
              }
            });
          }
          return acc;
        }, new Set()) ?? new Set();
      if (actionsMap.size > 0) {
        const nonFetchedIds = [...actionsMap].filter(
          (id) =>
            !subRowState[id] ||
            !subRowState[id]?.expanded ||
            (subRowState[id]?.data && subRowState[id]?.data.length === 0)
        );
        fetchSubRowData(nonFetchedIds, true);
      }
    }
  }, [
    dashletSearch,
    dashletSearchTermChanged,
    data,
    fetchSubRowData,
    t,
    bucketsAndFieldsById,
    subRowIdsByRowId,
    subRowState,
    visibleRowIds,
  ]);

  const currencySymbol = aggregatedField?.moneyOptions?.symbol;

  const handleVerticalScroll = useCallback((rowIds, scrollPosition) => {
    setVisibleRowIds(rowIds);
    verticalScrollPosition.current = scrollPosition;
  }, []);

  const handleHorizontalScroll = useCallback((scrollPosition) => {
    horizontalScrollPosition.current = scrollPosition;
  }, []);

  const getLink = useCallback(
    (entityId, isSubRow = false) => {
      const customObjectId = isSubRow
        ? subRowCustomObjectId
        : mainRowCustomObjectId;
      if (!customObjectId || !entityId || entityId === 'null') {
        return null;
      }

      if (customObjectId === clientObjectId) {
        return `/client/${entityId}/details`;
      }

      return `/custom-objects/${customObjectId}/${entityId}/details`;
    },
    [clientObjectId, mainRowCustomObjectId, subRowCustomObjectId]
  );

  const getDynamicTagLink = useCallback(
    (field) => {
      return getLinkDataForTag({
        fetchUrl:
          model?.name === 'client_client'
            ? 'clients'
            : `custom-objects/${model.id}`,
        fieldId: field.modelField ? field.modelField.id : field.id,
        field,
        tagType: specialTagTypes[field.name] || 'dynamictag',
      }).link;
    },
    [model]
  );

  const onRefresh = useCallback(() => {
    dispatch({ type: CLEAR_STATE });
    handleBustCache();
    clearDashletSearch();
    queryClient.invalidateQueries([
      'dashboard',
      'dashlet',
      'subrows',
      dashlet.id,
    ]);
  }, [clearDashletSearch, handleBustCache, dashlet.id, dispatch, queryClient]);

  const pivotTableErrorMessage = useMemo(() => {
    if (mobile) {
      return t(
        'This pivot table chart has not been optimized for mobile. Please revisit on a desktop for the best experience.'
      );
    } else if (data?.status === 'too_large') {
      return t(
        'Your current filter is retrieving too many records and/or field values. We could not generate the configured Pivot table due to its large size. Please refine your filter parameters to downsize your result table.'
      );
    } else if (subRowFetchError) {
      return t('Failed to fetch subrow data. please refresh dashlet');
    } else if (missingFields) {
      return t(
        'This chart contains fields that have been deleted. Please edit the dashlet to remove these fields.'
      );
    }
  }, [mobile, data?.status, subRowFetchError, missingFields, t]);

  if (pivotTableErrorMessage) {
    return (
      <DashletError onRefresh={onRefresh} override={pivotTableErrorMessage} />
    );
  }

  if (!data?.data) {
    return null;
  }

  return (
    <PivotTableChartWrapper>
      {aggregatedFieldLoading && <StyledLoader loading />}
      <TSPivotTableChart
        data={data.data}
        dashlet={dashlet}
        dashletSearch={dashletSearch}
        title={title}
        onChangeStandardColumnSizes={onChangeStandardColumnSizes}
        onChangeFixedLeftColumnSizes={onChangeFixedLeftColumnSizes}
        standardColumnSizes={standardColumnSizes}
        fixedLeftColumnSizes={fixedLeftColumnSizes}
        minimumFixedLeftWidth={160}
        onChangeSort={onChangeSort}
        onExpand={fetchSubRowData}
        onExpandCollapse={(e, rowIds, autoExpand) => {
          e.preventDefault();
          fetchSubRowData(rowIds, autoExpand);
        }}
        sort={persistentState}
        getLink={getLink}
        getDynamicTagLink={getDynamicTagLink}
        currencySymbol={currencySymbol}
        hideBottomRow={Boolean(dashletSearch)}
        fieldsById={fieldsById}
        subRowState={subRowState}
        verticalScrollPosition={verticalScrollPosition}
        horizontalScrollPosition={horizontalScrollPosition}
        onVerticalScroll={handleVerticalScroll}
        onHorizontalScroll={handleHorizontalScroll}
      />
      {dashletSearch ? (
        <PivotTableFooter>
          <Typography color="font-primary">{footerMessage}</Typography>
        </PivotTableFooter>
      ) : null}
    </PivotTableChartWrapper>
  );
};
