import BusinessPage from './pages/Business/index.jsx';
import TeamPage from './pages/Team';
import CustomFieldsPage from './pages/CustomFields';
// import ActivitiesPage from './Activities';
import DomainAndTrackingPage from './pages/DomainAndTracking';
import PrivacySettingsPage from './pages/PrivacySettings';
//import BillingPage from './pages/Billing';
import ManageConnections from './pages/ManageConnections';

import paths from './paths';

export const settingsUrlBase = '/settings';

const routes = {
  business: {
    label: (t) => t('Business Information'),
    component: BusinessPage,
    exact: false,
    icon: 'building',
  },
  team: {
    label: (t) => t('Team, Roles, & Permissions'),
    component: TeamPage,
    exact: true,
    icon: 'users-cog',
  },
  customFields: {
    label: (t) => t('Customize Fields'),
    component: CustomFieldsPage,
    icon: 'id-card',
    exact: false,
  },
  // 'activities/:id?': {
  //   label: (t) => t('Activities'),
  //   component: ActivitiesPage,
  //   exact: false,
  //   icon: 'clipboard-list'
  // },
  domainAndTracking: {
    label: (t) => t('Domain & Tracking'),
    component: DomainAndTrackingPage,
    exact: true,
    icon: 'globe',
  },
  privacySettings: {
    label: (t) => t('Privacy Settings'),
    component: PrivacySettingsPage,
    exact: true,
    icon: 'shield-check',
  },
  manageConnections: {
    label: (t) => t('Manage Connections'),
    component: ManageConnections,
    exact: true,
    icon: 'key',
  },

  // TODO - temporarily removed for go live
  // billing: {
  //   label: 'Billing',
  //   component: BillingPage,
  //   exact: true,
  //   icon: 'wallet'
  // }
};

paths.forEach((path, routeId) => {
  routes[routeId].path = path;
});

export default routes;
