import React from 'react';
import * as PropTypes from 'prop-types';
import { SingleValueLink } from './customize';

export default function LinkedSelection({ data, ...props }) {
  const { meta } = data;
  return (
    <SingleValueLink
      data={data}
      href={meta.link}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    />
  );
}

LinkedSelection.propTypes = {
  data: PropTypes.shape({
    meta: PropTypes.shape({
      link: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
