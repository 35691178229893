import {
  DISPLAY_VALUE_RESPONSES,
  REPORT_TYPES,
  VALUE_TO_DISPLAY_RESPONSES,
} from 'components/Wizards/Dashlet/types';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { DASHBOARD } from 'queries/query-keys';
import { CHART_TYPES } from 'components/Wizards/shared/components/chart/types';
import { getDefaultName } from 'components/Wizards/utils';
import { each, fill, flow, last, sortBy } from 'lodash';
import { DASHBOARD_COLUMNS } from 'components/DashboardGrid/types';
import { DEFAULT_LABEL_INDICATOR } from 'components/DashboardGrid/utils';
import { getHash } from 'utility/encode';
import {
  AREA_RESPONSES,
  SALES_REPORT_TYPES,
  VALUE_METRICS,
} from 'components/Wizards/RTDV/types';

export const getConditionalLayout = (dashlet, breakpoint) => {
  // For this special case, we want the dashlet to be 8 units high when on a smaller
  // breakpoint (8 or fewer columns). This is safe to do because the dashboard
  // layout cannot be edited at the smaller breakpoints.
  if (dashlet?.config?.reportType === REPORT_TYPES.INTERACTION_STATS) {
    const columns = DASHBOARD_COLUMNS[breakpoint];
    if (columns < 10) {
      return {
        ...dashlet,
        layout: {
          ...dashlet.layout,
          h: 8,
          maxH: 8,
        },
      };
    }
  }
  return dashlet;
};

const getDefaultLabels = (source, t) => {
  return {
    direct_traffic: t('Direct Traffic'),
    organic_search: t('Organic Search'),
    site_referral: t('Site Referral'),
    google_ads: t('Google Ads'),
    facebook_ads: t('Facebook Ads'),
    social: t('Social'),
    paid_social: t('Paid Social'),
  }[source];
};

const buildLeadSourceBreakdownObjects = (dashlet, t) => {
  return dashlet?.config?.leadSources?.map(({ source, value }) => {
    if (source === 'utm' || source === 'custom') {
      return {
        value,
        label: value,
        group: source,
      };
    }

    return {
      value: source,
      label: getDefaultLabels(source, t),
      group: 'default',
    };
  });
};

const buildPivotTableRowsBreadkdown = (dashlet) => {
  return dashlet.config?.aggregation?.rows.reduce((acc, row) => {
    const breakdowns = [];
    row?.fieldsValueBreakdown?.buckets?.forEach((bucket) => {
      breakdowns.push({
        id: bucket.id,
        min: bucket.min?.value,
        max: bucket.max?.value,
        label:
          bucket.bucketLabel === DEFAULT_LABEL_INDICATOR
            ? ''
            : bucket.bucketLabel,
      });
    });
    acc[row.id] = breakdowns;
    return acc;
  }, {});
};

const buildPivotTableColumnBreakdown = (dashlet) => {
  if (dashlet.config?.aggregation?.columns?.fieldsValueBreakdown?.buckets) {
    return dashlet.config.aggregation.columns?.fieldsValueBreakdown.buckets?.map(
      (bucket) => {
        return {
          id: bucket.id,
          min: bucket.min?.value,
          max: bucket.max?.value,
          label:
            bucket.bucketLabel === DEFAULT_LABEL_INDICATOR
              ? ''
              : bucket.bucketLabel,
        };
      }
    );
  }
};

const buildValueBreakdownForEditor = (dashlet) => {
  if (dashlet.config?.metricTypeExtraInfo?.fieldsRangeBreakdown) {
    return dashlet.config.metricTypeExtraInfo.fieldsRangeBreakdown.buckets?.map(
      (bucket) => {
        return {
          id: bucket.id,
          min: bucket.min?.value,
          max: bucket.max?.value,
          label:
            bucket.bucketLabel === DEFAULT_LABEL_INDICATOR
              ? ''
              : bucket.bucketLabel,
        };
      }
    );
  }
  if (dashlet.config?.metricTypeExtraInfo?.fieldsValueBreakdown) {
    return dashlet.config.metricTypeExtraInfo.fieldsValueBreakdown.buckets?.map(
      (bucket) => {
        if (typeof bucket === 'string') {
          return bucket;
        }

        return {
          id: bucket.id,
          values: bucket.values,
          label:
            bucket.bucketLabel === DEFAULT_LABEL_INDICATOR
              ? ''
              : bucket.bucketLabel,
        };
      }
    );
  }
};

const getEditableMetricType = (dashletData) => {
  if (dashletData?.config?.metricType === 'fields_value_breakdown') {
    return 'fields_range_breakdown';
  }
  return dashletData?.config?.metricType;
};

const getEditableSalesReportType = (dashletData) => {
  if (dashletData?.config?.reportType === AREA_RESPONSES.SALES) {
    return { value: SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME };
  }
};

const getEditableValue = (dashletData) => {
  const mt = getEditableMetricType(dashletData);

  if (dashletData?.config?.reportType === AREA_RESPONSES.SALES) {
    return mt;
  }

  return mt === VALUE_TO_DISPLAY_RESPONSES.WEIGHTED
    ? DISPLAY_VALUE_RESPONSES.VALUE
    : mt;
};

const getEditableActivityIds = (dashletData) => {
  return dashletData?.config?.activityIds?.map((id) => {
    return {
      value: id,
      label: dashletData.config.feExtraInfo?.activities?.[id],
    };
  });
};

const getMetric = (dashletData) => {
  if (dashletData.config.metricTypeExtraInfo?.fieldToAnalyze) {
    return VALUE_METRICS.NUMBER_FIELD;
  }

  if (dashletData.config.reportType === AREA_RESPONSES.PIVOT_TABLE) {
    return {
      value:
        dashletData.config.metricType === 'records_number'
          ? 'record_count'
          : 'number_field',
    };
  }

  return VALUE_METRICS.COUNT;
};

const getMetricField = (dashletData) => {
  if (dashletData.config.reportType === AREA_RESPONSES.PIVOT_TABLE) {
    return dashletData.config.aggregation?.fieldToAggregate
      ? {
          id: dashletData.config.aggregation?.fieldToAggregate,
        }
      : null;
  }

  if (dashletData.config.metricTypeExtraInfo?.fieldToAnalyze) {
    return dashletData.config.metricTypeExtraInfo?.fieldToAnalyze || null;
  }
};

const getRowCustomLabels = (dashletData) => {
  const labels = {};

  if (dashletData.config.aggregation) {
    const aggregation = dashletData.config.aggregation;

    if (aggregation.columns?.label) {
      labels[aggregation.columns.id] = aggregation.columns.label;
    }

    aggregation.rows.forEach((row) => {
      if (row.label) {
        labels[row.id] = row.label;
      }
    });
  }
  return labels;
};

const getRowDateFrequencies = (dashletData) => {
  let dateFrequencies = [];

  if (dashletData.config.aggregation) {
    const rows = dashletData.config.aggregation?.rows;
    dateFrequencies = rows?.reduce((acc, row) => {
      if (row.fieldsValueBreakdown?.frequency) {
        acc[row.id] = row.fieldsValueBreakdown?.frequency;
      }
      return acc;
    }, {});
  }
  return dateFrequencies;
};

export const editableDashlet = (oldDashlet, t) => {
  const dashlet = snakeToCamelCaseKeys(oldDashlet);
  const editablePayload = {
    area: dashlet.config.entityType,
    dashletId: dashlet.id,
    reportType: {
      value: dashlet.config.reportType,
    },
    value: {
      value: getEditableValue(dashlet),
    },
    datapointFrequency: {
      value: dashlet.config.frequency,
    },
    display: dashlet.config.chartType,
    compare: dashlet.config.historical,
    name: dashlet.name === DEFAULT_LABEL_INDICATOR ? '' : dashlet.name,
    includedRecords: {
      value: dashlet.config.recordsToInclude,
    },
    valueToDisplay: {
      value: getEditableMetricType(dashlet),
    },
    displayPercent: dashlet.config.percentageChangeOverTime,
    metric: getMetric(dashlet),
    metricField: getMetricField(dashlet),
    includeSummary: dashlet.config.metricTypeExtraInfo?.includeSummary || false,
    summaryExplanation:
      dashlet.config.metricTypeExtraInfo?.summaryExplanation || '',
    // The object ID is stored in different ways for different dashlet types,
    // to simplify the validation logic. It is simply stored under multiple keys
    // so that each wizard pathway can find it, but we can share logic for editing/creating
    selectedActivity: {
      value: dashlet.config.objectId,
    },
    activity: {
      value: dashlet.config.objectId,
    },
    pipeline: {
      value: dashlet.config.objectId,
    },
    levelOfDetail: {
      value: dashlet.config.pipelineLevelOfDetail,
    },
    stages: dashlet.config.stages?.map?.((s) => ({ value: s })) ?? [],
    customFilters: dashlet.config.filters?.customFilters,
    inGroupFilters: dashlet.config.filters?.inGroupIds,
    notInGroupFilters: dashlet.config.filters?.notInGroupIds,
    selectedFilterObject: dashlet.config.filters?.customObjectId
      ? { value: dashlet.config.filters?.customObjectId }
      : null,
    objectsWithLeads: dashlet.config.objectIds,
    sourcesToBreakDown: buildLeadSourceBreakdownObjects(dashlet, t),
    field: dashlet.config?.field ? { value: dashlet.config?.field } : undefined,
    valueBreakdown: buildValueBreakdownForEditor(dashlet),
    includeBlank:
      dashlet.config.metricTypeExtraInfo?.fieldsValueAverage
        ?.includeBlankValuesAsZero,
    bucketBlank:
      dashlet.config.metricTypeExtraInfo?.fieldsValueBreakdown
        ?.considerNullValues,
    columns: dashlet.config.feExtraInfo?.columns,
    salesReportType: getEditableSalesReportType(dashlet),
    customEndDate: Boolean(dashlet.config.customEndDate),
    endDate: dashlet.config.customEndDate,
    columnWidths: dashlet.config.feExtraInfo?.columnWidths,
    dateFilter: dashlet.config.feExtraInfo?.dateFilter,
    scheduledActivitiesConfig:
      dashlet.config.feExtraInfo?.scheduledActivitiesConfig,
    pivotTableConfig: dashlet.config.feExtraInfo?.pivotTableConfig,
    activities: getEditableActivityIds(dashlet),
    pivotTableColumns: dashlet.config.aggregation?.columns?.id
      ? [
          {
            id: dashlet.config.aggregation.columns.id,
            label: dashlet.config.aggregation.columns.label,
          },
        ]
      : [],
    pivotTableRows:
      dashlet.config.aggregation?.rows?.map(({ id, label }) => ({
        id,
        label,
      })) ?? [],
    rowCustomLabels: getRowCustomLabels(dashlet),
    columnDateFrequency:
      dashlet.config.aggregation?.columns?.fieldsValueBreakdown?.frequency,
    rowDateFrequencies: getRowDateFrequencies(dashlet),
    pivotTableRowBreakdown: buildPivotTableRowsBreadkdown(dashlet),
    pivotTableColumnBreakdown: buildPivotTableColumnBreakdown(dashlet),
    fieldToAggregateLabel: dashlet.config.aggregation?.fieldToAggregateLabel,
  };
  return editablePayload;
};

/*
 * The query key should change whenever the dashlet configuation is changed
 */
export const getDashletQueryKey = (
  { dashletData, dateFilter, teamFilter, currentEmail, search, filterInfo },
  { ignoreObjectId, ignoreFilters } = {}
) => {
  const keys = Object.keys(dashletData?.config ?? {}).sort();
  const values = keys.map((key) => {
    if (key === 'metricTypeExtraInfo') {
      // hash the extra info to keep a readable query key
      return getHash(JSON.stringify(dashletData?.config?.[key]));
    }
    // Don't include the objectId in the query key because it's not used in the query
    if (key === 'objectId' && ignoreObjectId) {
      return null;
    }
    if (key === 'filters' && ignoreFilters) {
      return null;
    }

    return getHash(
      JSON.stringify(dashletData?.config?.[key])?.replace(/"/g, '')
    );
  });
  if (dateFilter) {
    values.push(dateFilter.start, dateFilter.end, dateFilter.selectedIndex);
  }

  if (teamFilter?.teamMembers) {
    values.push('members:', teamFilter.teamMembers.join(':'));
  }

  if (teamFilter?.roles) {
    values.push('roles:', teamFilter.roles.join(':'));
  }

  if (search) {
    values.push('search:', search);
  }
  if (filterInfo) {
    // Hash the filters to keep a readable query key
    values.push('filters', getHash(JSON.stringify(filterInfo)));
  }

  return [
    ...DASHBOARD.DASHLET_DATA,
    'summary',
    dashletData.id,
    currentEmail,
    values.filter(Boolean).join(':'),
  ];
};

export const DEFAULT_NAME_KEY = '';

export const getDashletDefaultName = (
  {
    dashletData,
    pipelineDetail,
    currencySymbol,
    activity,
    fieldData,
    aggreateFieldData,
    objectForCharts,
    pivotTableRowField,
    pivotTableColumnField,
  },
  t
) => {
  const convertedDashlet = editableDashlet(dashletData, t);
  return getDefaultName(
    {
      area: convertedDashlet.area,
      aggreateFieldName: aggreateFieldData?.displayName,
      value: convertedDashlet.value,
      pipelineDetail,
      reportType: convertedDashlet.reportType,
      currencySymbol,
      isTrendDisplay: convertedDashlet.display === CHART_TYPES.TREND,
      includedRecords: convertedDashlet.includedRecords,
      valueToDisplay: convertedDashlet.valueToDisplay,
      selectedActivity: { label: activity?.name ?? '' },
      levelOfDetail: convertedDashlet.levelOfDetail,
      field: { label: fieldData?.displayName ?? '' },
      objectForCharts,
      salesReportType: convertedDashlet.salesReportType,
      datapointFrequency: convertedDashlet.datapointFrequency,
      pivotTableRowField,
      pivotTableColumnField,
      rowCustomLabels: convertedDashlet.rowCustomLabels,
    },
    t
  );
};

// Determine where to place a new dashlet by calculating the top-most, left-most
// position on the current dashboard grid into which the new dashlet can fit
export const calculateNewDashletPosition = (newDashlet, dashlets) => {
  // Empty dashboard, default to top-left
  if (!dashlets || !dashlets.length) {
    return { x: 0, y: 0 };
  }
  const layouts = dashlets.map((dashlet) => dashlet.layout);
  const lowest = flow(
    (lyts) => sortBy(lyts, [({ y }) => y, ({ h }) => h]),
    last
  )(layouts);

  const gridHeight = lowest.y + lowest.h;
  // We always locate our dashlets within a desktop grid, even if
  // being added on mobile, entirely for simplicity's sake.
  // I don't know entirely how react-grid-layout translates layout
  // data across breakpoints, but it seems that, generally, it does
  // what we want, mobile and desktop, when we blanketly assume
  // the grid is laid out at its desktop size
  const gridWidth = 12;

  // Represent the dashboard grid as a 2D array, with
  // Boolean values indicating whether or not a given square
  // is occupied by a dashlet
  const grid = Array.from({ length: gridHeight }, () =>
    fill(Array(gridWidth), false)
  );

  // Mark the filled spaces in the grid (set spaces that
  // correspond to dashlets to true)
  each(layouts, ({ x, y, w, h }) => {
    // starting at a dashlet's coordinates (x,y)
    // convert width (w) # of spaces to true
    // for each row spanned by height (h)
    for (let i = 0; i < h; i += 1) {
      grid[y + i] && grid[y + i].splice(x, w, ...Array(w).fill(true));
    }
  });
  // With this default, react-grid-layout will make sure our new
  // dashlet ends up at the bottom of our grid's first column
  let openSpace = { x: 0, y: 9999 };
  let initial = true;
  const withinRightHandSide = (x) => x <= gridWidth;
  gridScan: for (let y = 0; y < grid.length; y += 1) {
    const row = grid[y];
    rowScan: for (let x = 0; x < row.length; x += 1) {
      // Keep searching row (or continue on to next row)
      // if current space is filled; no need to check further
      // if dashlet can't start from our current position
      if (!row[x]) {
        // enough empty space in the current-row, width-wise?
        if (
          withinRightHandSide(x + newDashlet.w) &&
          row.slice(x, x + newDashlet.w).every((isFilled) => !isFilled)
        ) {
          // Determine how many rows to check as completely empty i.e. only the rows in the grid's
          // current dimensions into which the dashlet's height would extend. No need to check any
          // height of potential dashlet that would land past bottom of current grid. That's by definition
          // empty space, as it was outside the grid's dimensions, so we know that portion of the dashlet's height fits w/o checking
          const rowsWithinBottom =
            // Does dashlet overflow the bottom of the grid?
            newDashlet.h + y >= grid.length
              ? // If so, how many rows are within the bottom, need to be verified as empty? (dashlet height - amount overflowed)
                grid.length - y
              : // If not, we know each unit of dashlet's height will occupy an existing row and will therefore need to be checked for collisions
                newDashlet.h;
          for (let hi = 0; hi < rowsWithinBottom; hi += 1) {
            // is each row h (for dashlet's height), beneath the first, confirmed-empty row,
            // completely empty, too? As in, is there enough "vertical" space to
            // accommodate the dashlet's height?

            const rowIsEmpty = grid[y + hi]
              .slice(x, x + newDashlet.w)
              .every((isFilled) => !isFilled);
            // stop searching the second we find a non-empty row into which
            // our dashlet's height would extend
            if (!rowIsEmpty) {
              // Could the dashlet still theoretically fit in the current row?
              if (withinRightHandSide(x + newDashlet.w + 1)) {
                // If so, keep scanning the current row
                // eslint-disable-next-line no-continue
                continue rowScan;
              }
              // No possible way for dashlet to fit in the current row,
              // move to the next
              // eslint-disable-next-line no-continue
              continue gridScan;
            }
          }
          initial = false;
          openSpace = { x, y };
          break gridScan;
        }
      }
    }
  }

  // If we never found a slot, that means this new dashlet is the first on a row. We can't leave the y value as
  // 9999 since that will cause new dashlets to be placed above it, so instead we set it to the new row position.
  if (initial) {
    openSpace.y = grid.length;
  }
  return openSpace;
};
export const GRID_WIDTH = 12;
export const calculateNextDashletPosition = (dashlet, dashlets) => {
  const { layout } = dashlet;
  if (!layout) {
    return { x: 0, y: 0 };
  }
  const layouts = dashlets.map((dashlet) => dashlet.layout);
  const lowest = flow(
    (lyts) => sortBy(lyts, [({ y }) => y, ({ h }) => h]),
    last
  )(layouts);

  const gridHeight = lowest.y + lowest.h;
  const gridWidth = GRID_WIDTH;
  const grid = Array.from({ length: gridHeight }, () =>
    fill(Array(gridWidth), false)
  );
  each(layouts, ({ x, y, w, h }) => {
    for (let i = 0; i < h; i += 1) {
      grid[y + i] && grid[y + i].splice(x, w, ...Array(w).fill(true));
    }
  });
  let openSpace = null;
  const withinRightHandSide = (x) => x <= gridWidth;
  const layoutY = layout.y + layout.h;
  gridScan: for (let y = layout.y; y < layoutY * 2; y += 1) {
    const row = grid[y];
    const layoutX = layout.x + layout.w;
    rowScan: for (let x = layoutX; x < layoutX + layout.w; x += 1) {
      if (!row?.[x]) {
        if (
          withinRightHandSide(x + layout.w) &&
          row.slice(x, x + layout.w).every((isFilled) => !isFilled)
        ) {
          const rowsWithinBottom =
            layout.h + y >= grid.length ? grid.length - y : layout.h;
          for (let hi = 0; hi < rowsWithinBottom; hi += 1) {
            const rowIsEmpty = grid[y + hi]
              .slice(x, x + layout.w)
              .every((isFilled) => !isFilled);
            if (!rowIsEmpty) {
              if (withinRightHandSide(x + layout.w + 1)) {
                continue rowScan;
              }
              continue gridScan;
            }
          }
          openSpace = { x, y };
          break gridScan;
        }
      }
      break gridScan;
    }
  }
  const belowPosition = { x: layout.x, y: layout.y + layout.h };
  // only if y > 0, we need to check if there is a roof
  if (openSpace && openSpace.y) {
    // roof is the row above the open space and it should have at least one filled block
    const roof = grid[openSpace.y - 1]
      ?.slice(openSpace.x, openSpace.x + layout.w)
      .reduce((sum, next) => sum || next, false);
    return roof ? openSpace : belowPosition;
  }
  return openSpace || belowPosition;
};
export const DASHLET_SLOW_STALE_TIME = 120000;

export const getDashletFilterCount = (data) => {
  const formatted = snakeToCamelCaseKeys(data);

  return (
    (formatted.customFilters?.query?.reduce((acc, curr) => {
      return acc + (curr?.filters?.length ?? 0);
    }, 0) ?? 0) +
    (formatted.inGroupFilters?.length ?? 0) +
    (formatted.notInGroupFilters?.length ?? 0) +
    (formatted.inGroupIds?.length ?? 0) +
    (formatted.notInGroupIds?.length ?? 0)
  );
};

export const filterOutAndSort = (obj = {}) => {
  const keys = Object.keys(obj)
    .filter((key) => key !== 'columns' && key !== 'objectId')
    .sort();
  return keys.map((key) =>
    obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])
      ? filterOutAndSort(obj[key])
      : obj[key]
  );
};

export const isNotFromAPI = (obj = {}) => {
  return Object.keys(obj).some((key) =>
    obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])
      ? isNotFromAPI(obj[key])
      : obj[key] === undefined
  );
};
