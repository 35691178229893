import styled from '@emotion/styled';
import Select from 'components/Inputs/Select';
import { breakpoints, layers } from 'app/spacing';

const tabletBreak = breakpoints.md;
export const ControlledMenuWrapper = styled.div`
  position: relative;
  z-index: ${layers.content(0, 1)}; // lift it above content below
`;

export const ActionMenu = styled.div`
  width: 250px;
  left: -10px !important;
  /* Breakpoint consistency with MobileContent in ControlBar (layout change of ActionBar, specifically) */
  @media (min-width: ${tabletBreak + 1}px) {
    margin-top: -10px;
    left: unset;
  }
  .Select__menu {
    max-height: 240px;
    padding-bottom: 0;
  }
`;

export const StyledSelect = styled(Select)`
  z-index: ${layers.content(0, 5)};
`;
