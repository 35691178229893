import { textAugmentation, TextAugmentationRequest } from '@kizen/api/ml';
import { Editor } from '@tiptap/core';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/Modals/ConfirmationModal';

type Message = TextAugmentationRequest['messages'][number];
export type TaskType = TextAugmentationRequest['task_type'];

type AIContext = {
  // external
  isLoading: boolean;
  cancelRequest: (editor: Editor) => void;
  performRequest: (type: TaskType, editor: Editor) => Promise<void>;
  // internal
  setShowDismissModal: Dispatch<SetStateAction<boolean>>;
};

type WYSIWYGAIContextProps = {
  businessId: string;
  onRequestStart?: (type: TaskType) => void;
};

type SelectedTextData = {
  after: string;
  before: string;
  selection: string;
  all: string;
};

const context = createContext<AIContext>({
  isLoading: false,
  cancelRequest: () => {},
  performRequest: () => Promise.resolve(),
  setShowDismissModal: () => {},
});

const NonStreamingTypes: TaskType[] = ['spelling_and_grammar'];
const OverwriteSelectionTypes: TaskType[] = [
  'rephrase',
  'summarize',
  'spelling_and_grammar',
];

const getParagraphOpen = (editor: Editor) => {
  if (
    !editor.extensionManager.extensions.some((ext) => ext.name === 'lineHeight')
  ) {
    return '<p>';
  }

  const { lineHeight } = editor.getAttributes('paragraph');

  return lineHeight === 'default'
    ? '<p style="line-height: 1.25" data-line-height="default">'
    : `<p style="line-height: ${lineHeight}" data-line-height="${lineHeight}">`;
};

/**
 * Returns a stateful iterable to consume an async iterable that transforms
 * plain text into rich text. This means replacing all newline characters
 * into opening and opening and closing <p> tags.
 */
const toRichText = (paragraphOpen: string, iter: AsyncIterable<string>) => {
  let newParagraph = true;

  return {
    async *[Symbol.asyncIterator]() {
      for await (const chunk of iter) {
        let newlines = [...chunk.matchAll(/[\n]/g)].length;

        if (newlines > 0) {
          let result = chunk;

          while (newlines > 0) {
            const replacement = newParagraph ? paragraphOpen : '</p>';
            result = result.replace('\n', replacement);
            newParagraph = !newParagraph;
            newlines--;
          }

          yield result;
        } else {
          yield chunk;
        }
      }
    },
  };
};

const getSelectedTextData = (editor: Editor): SelectedTextData => {
  const { state, view } = editor;
  const { from, to } = view.state.selection;
  const end_pos = editor.state.doc.content.size;
  const selection = state.doc.textBetween(from, to);
  const before = state.doc.textBetween(0, from);
  const after = state.doc.textBetween(to, end_pos);
  const all = state.doc.textBetween(0, end_pos);
  return { before, after, selection, all };
};

const hasSelection = (editor: Editor): boolean => {
  const { from, to } = editor.view.state.selection;
  return from !== to;
};

/**
 * Note: type 'context' messages are not being sent so the responses are more
 * consistent/predictable. The model may be refined later to accept them again.
 */
const buildMessages = (data: SelectedTextData, type: TaskType): Message[] => {
  const messages: Message[] = [];

  const { all, before, selection } = data;

  if (!selection && (type === 'complete' || type === 'extend')) {
    messages.push({
      order: 1,
      content: before,
      type: 'query',
    });
    return messages;
  }

  // if (before) {
  //   messages.push({ order: 1, content: before, type: 'context' });
  // }

  messages.push({
    order: messages.length + 1,
    content: selection || all,
    type: 'query',
  });

  // if (after) {
  //   messages.push({
  //     order: messages.length + 1,
  //     content: after,
  //     type: 'context',
  //   });
  // }

  return messages;
};

export const useWYSIWYGAIContext = () => {
  return useContext(context);
};

export const WYSIWYGAIContext = ({
  businessId,
  children,
  onRequestStart,
}: PropsWithChildren<WYSIWYGAIContextProps>) => {
  const currentTaskType = useRef<TaskType>();
  const currentEditor = useRef<Editor>();
  const [aborter, setAborter] = useState<AbortController>();
  const [isLoading, setIsLoading] = useState(false);
  const [showDismissModal, setShowDismissModal] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);

  const performRequest = useCallback(
    async (task_type: TaskType, editor: Editor) => {
      currentTaskType.current = task_type;
      currentEditor.current = editor;
      const abort = new AbortController();
      setAborter(abort);

      try {
        const selectionData = getSelectedTextData(editor);

        if (!selectionData.selection && !selectionData.all) {
          return;
        }

        onRequestStart?.(task_type);
        setIsLoading(true);

        const messages = buildMessages(selectionData, task_type);
        const res = await textAugmentation(
          import.meta.env.VITE_API_BASE_PATH!,
          businessId,
          {
            task_type,
            messages,
            is_streaming: !NonStreamingTypes.includes(task_type),
          },
          { signal: abort.signal }
        );

        setIsLoading(false);

        if (OverwriteSelectionTypes.includes(task_type)) {
          if (hasSelection(editor)) {
            editor.commands.deleteSelection();
          } else {
            editor.chain().selectAll().deleteSelection().run();
          }
        } else {
          editor
            .chain()
            .focus()
            .setTextSelection(editor.view.state.selection.to)
            .run();
        }

        const paragraphOpen = getParagraphOpen(editor);

        for await (const chunk of toRichText(paragraphOpen, res)) {
          editor.commands.insertContent(chunk);
        }
      } catch (error) {
        if (!abort.signal.aborted) {
          setShowDismissModal(false);
          setShowRetryModal(true);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [businessId, onRequestStart]
  );

  const ctx = useMemo(() => {
    return {
      isLoading,
      cancelRequest: () => {
        aborter?.abort();

        if (!isLoading) {
          // cancelRequest called after the request was completed - treat as undo
          if (currentEditor.current) {
            currentEditor.current.chain().focus().undo().run();
          }
        }
      },
      performRequest,
      setShowDismissModal,
    };
  }, [isLoading, aborter, performRequest]);

  return (
    <>
      <context.Provider value={ctx}>{children}</context.Provider>
      {showDismissModal && (
        <DismissAIRequestModal
          onHide={() => setShowDismissModal(false)}
          onConfirm={() => {
            setShowDismissModal(false);
            ctx.cancelRequest();
          }}
        />
      )}
      {showRetryModal && (
        <RetryAIRequestModal
          onHide={() => setShowRetryModal(false)}
          onConfirm={async () => {
            setShowRetryModal(false);

            if (currentEditor.current && currentTaskType.current) {
              await performRequest(
                currentTaskType.current,
                currentEditor.current
              );
            }
          }}
        />
      )}
    </>
  );
};

type ModalProps = {
  onConfirm(): void;
  onHide(): void;
};

const RetryAIRequestModal = ({ onConfirm, onHide }: ModalProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      show
      heading={t('AI Request Failed')}
      buttonText={t('Retry')}
      onHide={onHide}
      onConfirm={onConfirm}
    >
      {t('Open AI did not respond in time. Would you like to retry?')}
    </ConfirmationModal>
  );
};

const DismissAIRequestModal = ({ onConfirm, onHide }: ModalProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      show
      heading={t('Discard Request in Progress')}
      buttonText={t('Discard Request')}
      actionBtnColor="red"
      onHide={onHide}
      onConfirm={onConfirm}
    >
      {t(
        'There is an AI request in progress that will be discarded if you proceed.'
      )}
    </ConfirmationModal>
  );
};
