import { useCallback, useContext, useEffect, useMemo } from 'react';
import { SmartConnectorContext } from '../context';
import { useFlashTransition } from 'hooks/useFlashState';
import { ExecutionVariable, SmartConnector } from 'pages/SmartConnectors/types';

const errorsOrder: Partial<Record<keyof SmartConnector, number>> = {
  name: 0,
  custom_object: 1,
  execution_variables: 2,
};

const variableErrorsOrder: Partial<Record<keyof ExecutionVariable, number>> = {
  name: 0,
  required: 1,
  data_source: 2,
  data_type: 3,
  input_format: 4,
  is_array: 5,
  array_delimiter: 6,
  output_format: 7,
  display_order: 8,
};

export const useErrors = (errorKey: keyof SmartConnector) => {
  const { errors, setErrors } = useContext(SmartConnectorContext);

  const [message, showMessage, flashErrorMessage, cancelFlash] =
    useFlashTransition();

  const error = errors[errorKey];

  const firstErrorKey = (Object.keys(errors) as (keyof SmartConnector)[]).sort(
    (a, b) => errorsOrder[a]! - errorsOrder[b]!
  )[0];

  const isFirstError = error && firstErrorKey === errorKey;

  useEffect(() => {
    if (isFirstError) {
      flashErrorMessage(error);
    }
  }, [error, isFirstError, flashErrorMessage]);

  const clearError = useCallback(() => {
    if (showMessage) {
      cancelFlash();
    }

    if (error) {
      setErrors((prev) => {
        const { [errorKey]: _, ...rest } = prev;
        return rest;
      });
    }
  }, [showMessage, cancelFlash, error, setErrors, errorKey]);

  const validate = useMemo(
    () => ({ showMessage, message }),
    [showMessage, message]
  );

  return {
    error: message || error,
    validate,
    clearError,
  };
};

export const useVariableErrors = (index: number) => {
  const {
    errors: { execution_variables = [] },
    setErrors,
  } = useContext(SmartConnectorContext);

  const [message, showMessage, flashErrorMessage, cancelFlash] =
    useFlashTransition();

  const variableError = (execution_variables || [])[index];

  const hasError = Boolean(
    variableError && Object.values(variableError).length
  );

  const isFirstVariableError =
    hasError &&
    !(execution_variables || []).some(
      (el, i) =>
        i < index && el && typeof el === 'object' && Object.values(el).length
    );

  const errorKey = useMemo<keyof ExecutionVariable | null>(() => {
    return isFirstVariableError
      ? (Object.keys(variableError) as (keyof ExecutionVariable)[]).sort(
          (a, b) => variableErrorsOrder[a]! - variableErrorsOrder[b]!
        )[0]
      : null;
  }, [isFirstVariableError, variableError]);

  useEffect(() => {
    if (errorKey && variableError[errorKey]) {
      flashErrorMessage(variableError[errorKey]);
    }
  }, [variableError, flashErrorMessage, errorKey]);

  const clearError = useCallback(
    (prop: keyof ExecutionVariable) => {
      if (hasError) {
        if (showMessage && errorKey === prop) {
          cancelFlash();
        }

        setErrors((prev) => ({
          ...prev,
          execution_variables: (prev.execution_variables || []).map((el, i) => {
            if (i === index) {
              const { [prop]: _, ...rest } = el;
              return rest;
            }
            return el;
          }),
        }));
      }
    },
    [showMessage, cancelFlash, hasError, setErrors, errorKey, index]
  );

  const validate = useMemo(
    () => ({ showMessage, message }),
    [showMessage, message]
  );

  return {
    hasError,
    variableError,
    errorKey,
    validate,
    clearError,
  };
};
