import React from 'react';
import * as PropTypes from 'prop-types';
import { StyledAreaBox, StyledGridSelector } from './styles';

const AreaBox = ({ entity, onChange }) => {
  const { label, icon, name, selected } = entity;
  return (
    <StyledAreaBox>
      <StyledGridSelector
        icon={icon}
        label={label}
        showToggle
        onChange={(value) => onChange(name, value)}
        selected={!!selected}
        className={selected ? 'selected' : ''}
      />
    </StyledAreaBox>
  );
};

AreaBox.propTypes = {
  entity: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AreaBox;
