import { FIELD_TYPES as ALL_FIELD_TYPES } from 'utility/constants';
import {
  TransformToOption,
  TransformToSelectOptions,
} from 'utility/TransformToSelectOptions';

// This was copied from 'components/VerticalStepWizard/WizardTypes' to avoid the import from components
export const FIELD_TYPES = {
  RATING: ALL_FIELD_TYPES.Rating.type,
  DROPDOWN: ALL_FIELD_TYPES.Dropdown.type,
  NUMBER: 'number',
  YES_NO: 'yes_no',
  RADIO: ALL_FIELD_TYPES.Radio.type,
  CHECKBOX: ALL_FIELD_TYPES.Checkbox.type,
  CUSTOM_CHECKBOX: 'custom_checkbox',
  EMAIL_STAT: ALL_FIELD_TYPES.Email.type,
  PIPELINE_VALUES: 'pipeline',
  // odd case. Not used directly in eventual API calls, but
  // rather to enable selection of particular display of pipeline metric
  // see usage in MetricTypeSelector
  PIPELINE_DISPLAY: 'pipeline_display',
};

// This was moved from services/helpers. It's ancient and may not be needed in the below mappers.
const surveyFieldType = ({ type, meta }) => {
  // Custom fields don't have always survey_ prefix in their type so we need to add this to show them in dropdown
  if (type === ALL_FIELD_TYPES.SurveyRating.type) {
    return FIELD_TYPES.RATING;
  }

  if (
    type === ALL_FIELD_TYPES.SurveyDropdown.type ||
    type === ALL_FIELD_TYPES.Dropdown.type
  ) {
    return FIELD_TYPES.DROPDOWN;
  }

  if (
    type === ALL_FIELD_TYPES.SurveyNumber.type ||
    type === ALL_FIELD_TYPES.Decimal.type ||
    type === ALL_FIELD_TYPES.Integer.type
  ) {
    return FIELD_TYPES.NUMBER;
  }

  if (
    type === ALL_FIELD_TYPES.SurveyNumber.type ||
    type === ALL_FIELD_TYPES.Number.type
  ) {
    return FIELD_TYPES.NUMBER;
  }

  if (
    type === ALL_FIELD_TYPES.SurveyYesNo.type ||
    type === ALL_FIELD_TYPES.YesNo.type
  ) {
    return FIELD_TYPES.YES_NO;
  }

  if (type === ALL_FIELD_TYPES.SurveyCheckbox.type) {
    if (meta.canSelectMultiple) {
      return FIELD_TYPES.CHECKBOX;
    }
    return FIELD_TYPES.RADIO;
  }

  if (type === ALL_FIELD_TYPES.Checkboxes.type) {
    return FIELD_TYPES.CHECKBOX;
  }

  if (type === ALL_FIELD_TYPES.Checkbox.type) {
    return FIELD_TYPES.CUSTOM_CHECKBOX;
  }

  if (type === ALL_FIELD_TYPES.Radio.type) {
    return FIELD_TYPES.RADIO;
  }

  return null;
};

const fieldMapper = (field) => {
  // create a value object with id so we can pass around the field type
  const fieldValue = {
    id: field.id,
    type: surveyFieldType(field),
  };

  return {
    // transform the field to be a Select option
    ...TransformToOption(fieldValue, field.name),
    // create choices options array if the we have choices
    choices: TransformToSelectOptions(field.choices, 'uuid', 'value'),
  };
};

/**
 * Converts any craft json page data that has been serialized to a string to a POJO.
 * @param {*} pages - array of craft json objects
 * @returns - craft json page object
 */
export const ensureDeserializedFormPages = (pages) =>
  pages.map((page) => ({
    ...page,
    pageData:
      typeof page.pageData === 'string'
        ? JSON.parse(page.pageData)
        : page.pageData,
  }));

/**
 * Prepares a form or survey for use in the page builder and hosted form/survey pages.
 * @param {*} - form or survey object
 * @returns - form or survey object
 */
export const formMapper = ({ fields, formUi, ...rest }) => {
  const parsedFormUi = typeof formUi === 'string' ? JSON.parse(formUi) : formUi;

  const pages = Array.isArray(parsedFormUi.pages)
    ? ensureDeserializedFormPages(parsedFormUi.pages)
    : [];

  return {
    ...rest,
    formUi: { ...parsedFormUi, pages },
    fields:
      fields &&
      fields
        .filter(surveyFieldType) // filter out unknown field types
        .map(fieldMapper),
  };
};
