import {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import MultiSelect from 'components/Inputs/MultiSelect';
import { getSelectedValueLink } from 'components/Inputs/MultiSelect/customize';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import {
  useRelationshipSelect,
  useSelectEnter,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { TopMenuButton } from 'components/Kizen/Menu';
import CreateEntityModalShort from 'components/Modals/CreateEntity/ShortForm';
import { useEntityCreationModal } from 'components/Modals/CreateEntity/hook';
import CreateEntityModalFull from 'components/Modals/CreateEntity/FullForm';
import { useModalControl } from 'hooks/useModalControl';
import { FIELD_TYPES } from 'utility/constants';
import FieldService from 'services/FieldService';
import { RelationshipConfirmationModal } from 'components/Modals/RelationshipConfirmationModal';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useQueryClient } from 'react-query';
import { RELATION_FIELD } from 'queries/query-keys';
import useField from 'hooks/useField';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

const RelationshipMany = forwardRef(
  (
    {
      fieldId,
      field,
      entity,
      label,
      value: valueProp = EMPTY_ARRAY,
      onChange,
      className = '',
      disabled,
      alwaysOpen = false,
      optionsReadyCallback = null,
      inModal = false,
      categorizedFields = EMPTY_ARRAY,
      fieldType,
      onFocus,
      onMenuOpen,
      onMenuClose,
      hideAddButton,
      keepPreviousData = true,
      excludeExistingValues = false,
      shouldRefetchOnMount = false,
      portal,
      ...others
    },
    ref
  ) => {
    const { assignFieldHandle } = useKeyBoardContext();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [showEntityCreateModal, setShowEntityCreateModal] = useState(false);
    const selectRef = useRef(null);

    const [value, setValue] = useField(valueProp);

    const isContact = field.relation?.fetchUrl === 'client';

    const {
      initialValues,
      setInitialValues,
      parseTypeAhead,
      objectType,
      hasAbilityToAdd,
    } = useEntityCreationModal(field);

    const [{ displayValue, handleChange, relationship }] =
      useRelationshipSelect({
        value,
        onChange,
        field,
        isMulti: true,
      });

    const chosenValueIds = useMemo(
      () => (value || []).map((item) => item.value ?? item.id),
      [value]
    );

    const [typeaheadProps, typeaheadState, { refetch }] =
      useSelectTypeaheadWithScroll({
        fetch: relationship.search,
        objectToOption: relationship.toOption,
        alwaysOpen,
        onFocus,
        fieldId: fieldId || field.id,
        onMenuOpen,
        onMenuClose,
        selectRef,
        chosenValueIds,
        keepPreviousData,
        params: excludeExistingValues
          ? { exclude_record_id: entity?.id, exclude_field_id: field?.id }
          : null,
        shouldRefetchOnMount,
        relatedObjectId: field.relation?.relatedObject,
      });

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, {
        ...others,
        ...typeaheadProps,
      });

    const onStartCreate = () => {
      if (
        selectRef.current.state.menuIsOpen !== false &&
        others.menuIsOpen !== false
      ) {
        if (typeaheadProps.inputValue) {
          parseTypeAhead(typeaheadProps.inputValue, isContact);
        }
        setShowEntityCreateModal(true);
        typeaheadProps.onInputChange('');
      }
    };

    const selectEnterProps = useSelectEnter({
      handlePressEnter: onStartCreate,
      options: typeaheadProps.options,
      loadingOptions: typeaheadState.loading,
    });

    const spreadProps = {
      ...selectEnterProps,
      ...typeaheadProps,
      ...others,
      ...customSelectMenuProps,
    };

    const { onBlur } = spreadProps;

    const entityCreatedHandler = useCallback(
      async (newEntity) => {
        await refetch();
        // need to invalidate all the fields that are related to this object
        await queryClient.invalidateQueries({
          queryKey: [
            ...RELATION_FIELD.RELATED_OBJECTS(field.relation.relatedObject),
          ],
        });
        handleChange(
          // displayValue could be a null, so we need to be sure we concat only to array
          (displayValue || []).concat(relationship.toOption(newEntity))
        );
        setInitialValues({});
        onBlur?.();
      },
      [
        refetch,
        queryClient,
        field,
        handleChange,
        displayValue,
        relationship,
        setInitialValues,
        onBlur,
      ]
    );

    const ObjectLink = useMemo(
      () =>
        relationship.link
          ? getSelectedValueLink({
              genLink: relationship.link,
            })
          : null,
      [relationship.link]
    );

    useEffect(() => {
      //  for scroll to bottom
      if (
        optionsReadyCallback &&
        typeaheadState.onOptionsReady &&
        selectRef?.current?.state.menuIsOpen
      ) {
        optionsReadyCallback(selectRef.current.select.controlRef);
      }
    });

    const [
      isConfirmModalOpen,
      { showModal: showConfirmationModal, hideModal: hideConfirmationModal },
    ] = useModalControl();

    const selectEntity = useRef(null);

    const onConfirmConfirmation = useCallback(() => {
      handleChange(value);
      hideConfirmationModal();
      onBlur?.();
    }, [value, hideConfirmationModal, handleChange, onBlur]);

    const onHideConfirmation = useCallback(() => {
      const filteredValue = displayValue.filter(
        (v) => v.value !== selectEntity.current
      );
      handleChange(filteredValue);
      hideConfirmationModal();
      onBlur?.();
    }, [displayValue, handleChange, hideConfirmationModal, onBlur]);

    const checkPrimaryField = useCallback(
      async (option, nextValue) => {
        if (
          option.action !== 'remove-value' &&
          option.action !== 'clear' &&
          field.fieldType === FIELD_TYPES.Relationship.type &&
          field.relation.cardinality === 'one_to_many'
        ) {
          selectEntity.current = option.option?.value;
          const relatedObject = field.relation.relatedObject;
          const relatedField = field.relation.relatedField;

          const entityRecord = await FieldService.getEntityRecordById(
            relatedObject,
            selectEntity.current
          );

          if (entityRecord?.fields[relatedField]?.value) {
            showConfirmationModal();
            return;
          }
        }
        handleChange(nextValue);
      },
      [field.fieldType, field.relation, showConfirmationModal, handleChange]
    );

    const handleOnCheckChange = useCallback(
      (val, option) => {
        checkPrimaryField(option, val);
        setValue(val);
      },
      [checkPrimaryField, setValue]
    );

    const Modal = field.useFullExperienceForm
      ? CreateEntityModalFull
      : CreateEntityModalShort;

    assignFieldHandle(fieldId || field.id, {
      customTab: (e) => {
        e.preventDefault();
      },
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      },
      customEscape: () => {
        setMenuIsOpen(false);
      },
      customEnter: (e) => {
        return selectRef.current.state.menuIsOpen;
      },
      customUp: () => {
        setMenuIsOpen(true);
      },
      customDown: () => {
        setMenuIsOpen(true);
      },
      disabled,
    });

    return (
      <>
        <MultiSelect
          ref={selectRef}
          className={className}
          disabled={disabled}
          label={label}
          value={displayValue}
          onChange={handleOnCheckChange}
          placeholder={`${t('Find')} ${relationship.name.plural}`}
          menuTopButton={
            hasAbilityToAdd &&
            !hideAddButton && (
              <TopMenuButton onClick={onStartCreate}>
                {`${t('Add')} ${relationship.name.single}`}
              </TopMenuButton>
            )
          }
          menuLeftButton={<ClearSelectButton />}
          menuRightButton={<ApplySelectButton />}
          endAdornment={<IconAdornment icon={'search'} />}
          isLoading={typeaheadState?.loading}
          PillComponent={ObjectLink}
          {...spreadProps}
          portal={portal}
          /*
            When a modal opens, it traps focus within the modal. This is normally fine,
            but we want to not fire the onBlur event when the modal is opens, because that's
            not an action that the user took.
          */
          onBlur={
            showEntityCreateModal || isConfirmModalOpen
              ? () => null
              : spreadProps.onBlur
          }
        />
        {showEntityCreateModal ? (
          <Modal
            objectType={objectType}
            objectId={isContact ? null : field.relation?.relatedObject}
            show={showEntityCreateModal}
            onCreated={entityCreatedHandler}
            onCancel={() => {
              setShowEntityCreateModal(false);
              selectRef.current?.select?.focus();
              setInitialValues({});
            }}
            initialValues={initialValues}
          />
        ) : null}
        <RelationshipConfirmationModal
          show={isConfirmModalOpen}
          onConfirm={onConfirmConfirmation}
          onHide={onHideConfirmation}
        />
      </>
    );
  }
);

export default RelationshipMany;
