import React, { useState } from 'react';
import styled from '@emotion/styled';
import { KizenTypography } from 'app/typography';
import GridSelector from 'components/Kizen/GridSelector';
import { gutters } from 'app/spacing';

const StyledText = styled(KizenTypography)`
  margin: ${gutters.standard} 0 ${gutters.standard} 0;
`;

const ToggleGridSelector = () => {
  const [selected, setSelected] = useState(false);

  return (
    <>
      <div>
        <StyledText type="subheader" weight="light">
          Default
        </StyledText>
        <GridSelector
          icon="sticky-note"
          label="Toggle"
          showToggle
          onChange={() => setSelected(!selected)}
          selected={!!selected}
          className={selected ? 'selected' : ''}
        />
      </div>
      <div>
        <StyledText type="subheader" weight="light">
          Selected
        </StyledText>
        <GridSelector
          icon="sticky-note"
          label="Toggle"
          showToggle
          onChange={() => {}}
          selected={true}
          className="selected"
        />
      </div>
      <div>
        <StyledText type="subheader" weight="light">
          Error
        </StyledText>
        <GridSelector
          error
          icon="sticky-note"
          label="Toggle"
          showToggle
          onChange={() => {}}
          selected={false}
        />
      </div>
    </>
  );
};

export default ToggleGridSelector;
