import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Modal } from 'react-bootstrap';

import { gutters, scrollbarCss, borderRadii, breakpoints } from 'app/spacing';
import { grayScale } from 'app/colors';

export const StyledModal = styled(Modal)`
  .modal-dialog {
    height: calc(100% - 80px);
    padding-top: ${gutters.spacing(8)}px;
    padding-bottom: ${gutters.spacing(8)}px;
    ${({ width }) =>
      // NOTE below 576px bootstrap snaps into a mobile view which
      // is not compatible with a modal min-width smaller than the
      // screen size, so we enforce it as a min.
      width &&
      css`
        @media (min-width: 576px) and (min-width: ${width}) {
          max-width: ${width};
          width: ${width};
        }
      `};

    ${({ animateSizeChange }) => {
      if (animateSizeChange) {
        return css`
          transition: all 0.3s ease-in-out;
        `;
      }
      return '';
    }}

    ${({ fitContent, fitContentWidth }) =>
      fitContent && fitContentWidth
        ? css`
            max-height: calc(100% - 80px);
            width: ${fitContentWidth};
          `
        : css`
            max-height: calc(100% - 80px);
          `};

    @media (max-width: ${breakpoints.sm}px) {
      width: calc(100% - 20px);
      margin-left: ${gutters.spacing(2)}px;
      margin-right: ${gutters.spacing(2)}px;
    }
  }

  .modal-content {
    border-radius: ${borderRadii.small};
    ${({ fitContent }) => {
      if (fitContent === 'y') {
        return css`
          max-height: 100%;
        `;
      }
      return css`
        height: 100%;
      `;
    }}
  }

  .modal-body {
    ${({ fitContent, displayFlex }) =>
      (!fitContent || fitContent === 'y') &&
      (displayFlex
        ? css`
            overflow-y: hidden;
            display: flex;
          `
        : css`
            overflow-y: scroll;
            ${scrollbarCss()}
            padding-right: ${gutters.spacing(3, -4)}px;
          `)}
  }
`;

export const StyledModalHeader = styled.div`
  border-bottom: none;
  padding: ${gutters.spacing(3, -1)}px ${gutters.spacing(8, 2)}px
    ${gutters.spacing(2, 1)}px ${gutters.standard};

  .modal-title {
    line-height: 1;
  }

  && button {
    position: absolute;
    top: ${gutters.spacing(3)}px;
    right: ${gutters.spacing(3)}px;

    i {
      width: 12px;
      height: 12px;
    }
  }
`;

export const StyledModalBody = styled(Modal.Body)`
  height: 100%;
  ${({ typeOfContent = 'default' }) => css`
    ${typeOfContent === 'no-paddings' &&
    css`
      padding: 0;
    `}
    ${typeOfContent === 'text' &&
    css`
      padding: ${gutters.standard} ${gutters.standard}
        ${gutters.spacing(1, -2)}px;
      & > * {
        text-align: center;
      }
    `}
    ${typeOfContent === 'wizard' &&
    css`&&& {
        padding: ${gutters.spacing(5)}px ${gutters.spacing(12)}px
          ${gutters.spacing(1, -2)}px ${gutters.spacing(10)}px;
        > .GenericWizardBody, .StyledWizardBody {
          // No need for the legacy margins, thanks to the padding above
          margin: 0;
        }
      `}
    ${typeOfContent === 'new-wizard' &&
    css`&& {
        padding: ${gutters.spacing(3)}px ${gutters.spacing(12, -4)}px
          ${gutters.spacing(1, -2)}px ${gutters.spacing(10)}px;
        > .GenericWizardBody, .StyledWizardBody {
          // No need for the legacy margins, thanks to the padding above
          margin: 0;
        }
    `}
    ${typeOfContent === 'new-wizard-mobile' &&
    css`
      && {
        padding: ${gutters.standard} ${gutters.spacing(3, -4)}px
          ${gutters.spacing(1, -2)}px ${gutters.standard};
        > .GenericWizardBody,
        .StyledWizardBody {
          // No need for the legacy margins, thanks to the padding above
          margin: 0;

          > div:nth-child(odd) {
            // set margin for step blocks
            margin-right: 10px;
          }
        }
      }
    `}
    ${typeOfContent === 'wizard-mobile' &&
    css`
      && {
        padding: ${gutters.standard} ${gutters.standard}
          ${gutters.spacing(1, -2)}px;
        > .GenericWizardBody,
        .StyledWizardBody {
          // No need for the legacy margins, thanks to the padding above
          margin: 0;

          > div:nth-child(odd) {
            // set margin for step blocks
            margin-right: 10px;
          }
        }
      }
    `}
    ${typeOfContent === 'default' &&
    css`
      padding: ${gutters.standard} ${gutters.standard}
        ${gutters.spacing(1, -2)}px;
    `}
  `}
`;

export const StyledModalFooter = styled(Modal.Footer)`
  ${({ showBorder }) => css`
    border: none;
    ${showBorder &&
    css`
      border-top: 2px solid ${grayScale.light};
    `}
    padding: ${gutters.standard};
    button.btn {
      margin: 0 0 0 ${gutters.spacing(1)}px;
    }
  `}

  ${({ actionBtnWithoutSpace }) =>
    // there should be 15px between buttons in the footer, so we
    // 1. remove left margin on right-most (confirmation) button, so it doesn't push the cancel button
    // 2. take for granted that cancel button has 15px padding (standard on our buttons), which
    // results in 15px between edge of its text and the border of the confirmation button
    actionBtnWithoutSpace &&
    css`
      button:last-of-type {
        margin-left: 0;
      }
    `}

  ${({ leftBtnAlign, justifyContent = 'flex-end' }) =>
    leftBtnAlign === 'left'
      ? css`
          justify-content: space-between;
          button:first-of-type {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
          }
        `
      : css`
          justify-content: ${justifyContent};
        `}
`;
