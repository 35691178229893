import { useLocation } from 'react-router-dom';

export const getSearchParam = (location, param) => {
  return new URLSearchParams(location.search).get(param);
};

export default function useSearchParam(param) {
  const location = useLocation();
  return getSearchParam(location, param);
}

export const setSearchParams = (history, values, { method = 'push' } = {}) => {
  const params = new URLSearchParams(history.location.search);
  Object.entries(values).forEach(([param, value]) => {
    if (value || value === 0) {
      params.set(param, value);
    } else {
      params.delete(param);
    }
  });
  history[method]({
    search: params.toString(),
  });
};

// use the api env var to check for valid redirect
export const safeUrlStartsWith = import.meta.env.VITE_API_BASE_PATH.replace(
  /(.*\/)api/,
  '$1'
).toLowerCase();
