import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PipelineService from 'services/PipelineService';
import BasicModal from 'components/Modals/presets/BasicModal';
import { getDataQAForInput } from 'components/Inputs/helpers';
import FieldInput from 'components/Fields/FieldInput';
import { getSelectedOptions } from './helpers';

const AddReasonDisqualifiedModal = ({
  onConfirm,
  show,
  onHide,
  model,
  reasonDisqualifiedField,
  reasonsDialogData,
  handleUpdateFieldOption,
  disabled = false,
  networkError, // This prop is not used in this component but we don't want to pass to the dom
}) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const reasonDisqualifiedFieldRef = useRef(reasonDisqualifiedField);

  useEffect(() => {
    setSelectedOptions(
      reasonDisqualifiedFieldRef.current
        ? getSelectedOptions(
            reasonsDialogData,
            reasonDisqualifiedFieldRef.current
          )
        : []
    );
  }, [reasonsDialogData]);

  return disabled || !reasonDisqualifiedField ? null : (
    <BasicModal
      buttonText={t('Save')}
      heading={t('Add Reason(s) Disqualified')}
      leftBtn={null}
      onConfirm={() => onConfirm(selectedOptions, reasonDisqualifiedField.id)}
      show={show}
      onHide={onHide}
      size="small"
    >
      {reasonsDialogData.map(({ record, stageId }, i) => {
        return (
          <FieldInput
            key={record.id}
            menuInline
            margin={i !== reasonsDialogData.length - 1}
            label={t('Choose Reason(s) Disqualified') + ' - ' + record.name}
            variant="outline"
            inModal
            disabled={!reasonDisqualifiedField.access.edit}
            field={reasonDisqualifiedField}
            fetchUrl={`custom-objects/${model.id}`}
            value={
              selectedOptions.find(
                (options) =>
                  options.stageId === stageId && options.recordId === record.id
              )?.selected
            }
            onChange={(selected) =>
              setSelectedOptions((prev) =>
                prev.map((data) =>
                  data.stageId === stageId && data.recordId === record.id
                    ? { ...data, selected }
                    : data
                )
              )
            }
            serviceToUse={PipelineService}
            objectId={model.id}
            stages={model.pipeline.stages}
            {...getDataQAForInput(
              `custom-object-record-${reasonDisqualifiedField.name}`,
              reasonDisqualifiedField.fieldType
            )}
            handleUpdateFieldOption={handleUpdateFieldOption}
          />
        );
      })}
    </BasicModal>
  );
};

export default AddReasonDisqualifiedModal;
