import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons/faGripDotsVertical';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons/faAlignJustify';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons/faEllipsisVertical';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH';
import { faBarsFilter } from '@fortawesome/pro-light-svg-icons/faBarsFilter';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons/faUserGroup';
import { faSquareList } from '@fortawesome/pro-light-svg-icons/faSquareList';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faMessageLines } from '@fortawesome/pro-light-svg-icons/faMessageLines';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import {
  ColorIconSettings,
  CompoundIconSettings,
  CustomIconSettings,
  FixedWidthIconSettings,
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';
import { faArrowsToCircle } from '@fortawesome/pro-light-svg-icons/faArrowsToCircle';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons/faUserPlus';
import { faUserMinus } from '@fortawesome/pro-light-svg-icons/faUserMinus';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { InputPipe } from '../custom/InputPipe';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp';
import { faMinus as faMinusRegular } from '@fortawesome/pro-regular-svg-icons/faMinus';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import { faHourglassStart } from '@fortawesome/pro-light-svg-icons/faHourglassStart';
import { faFlask } from '@fortawesome/pro-regular-svg-icons/faFlask';

export const SEMANTIC_CATEGORY: SemanticCategory = 'action';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  add: faPlus,
  remove: faMinus,
  close: faXmark,
  delete: faTrashCan,
  'drag-handle': faGripDotsVertical,
  minimize: faMinusRegular,
  'resize-handle': faAngleDown,
  'select-icon': faAlignJustify,
  search: faMagnifyingGlass,
  edit: faPen,
  options: faEllipsisVertical,
  'options-horizontal': faEllipsisH,
  'options-alt': null,
  filter: faBarsFilter,
  'filter-date': faCalendarDay,
  'filter-team': faUserGroup,
  'filter-roles': faBriefcase,
  'filter-fields': null,
  'filter-event-type': faSquareList,
  'filter-objects': faLayerGroup,
  'filter-comments': faMessageLines,
  loading: faSpinnerThird,
  refresh: faArrowsRotate,
  'add-user': faUserPlus,
  'remove-user': faUserMinus,
  'complete-activity': faCheck,
  'apply-calendar': faCalendarDay,
  'scroll-top': faArrowUp,
  'arrows-to-circle': faArrowsToCircle,
  'show-variables': faHourglassStart,
  'smartconnector-dry-run': faFlask,
};

export const labels: IconLabel = {
  add: {
    title: (t) => '',
    description: (t) => t('Add'),
  },
  remove: {
    title: (t) => '',
    description: (t) => t('Remove'),
  },
  close: {
    title: (t) => '',
    description: (t) => t('Close'),
  },
  delete: {
    title: (t) => '',
    description: (t) => t('Delete'),
  },
  'drag-handle': {
    title: (t) => '',
    description: (t) => t('Drag'),
  },
  'resize-handle': {
    title: (t) => '',
    description: (t) => t('Resize'),
  },
  'select-icon': {
    title: (t) => '',
    description: (t) => t('Select'),
  },
  search: {
    title: (t) => '',
    description: (t) => t('Search'),
  },
  edit: {
    title: (t) => '',
    description: (t) => t('Edit'),
  },
  options: {
    title: (t) => '',
    description: (t) => t('Options'),
  },
  'options-horizontal': {
    title: (t) => '',
    description: (t) => t('Options'),
  },
  'options-alt': {
    title: (t) => '',
    description: (t) => t('Options'),
  },
  filter: {
    title: (t) => '',
    description: (t) => t('Filter'),
  },
  'filter-date': {
    title: (t) => '',
    description: (t) => t('Filter Date'),
  },
  'filter-team': {
    title: (t) => '',
    description: (t) => t('Filter Team'),
  },
  'filter-roles': {
    title: (t) => '',
    description: (t) => t('Filter Roles'),
  },
  'filter-fields': {
    title: (t) => '',
    description: (t) => t('Filter Fields'),
  },
  'filter-event-type': {
    title: (t) => '',
    description: (t) => t('Filter Event Type'),
  },
  'filter-objects': {
    title: (t) => '',
    description: (t) => t('Filter Objects'),
  },
  'filter-comments': {
    title: (t) => '',
    description: (t) => t('Filter Comments'),
  },
  loading: {
    title: (t) => '',
    description: (t) => t('Loading'),
  },
  refresh: {
    title: (t) => '',
    description: (t) => t('Refresh'),
  },
  'add-user': {
    title: (t) => '',
    description: (t) => t('Add User'),
  },
  'remove-user': {
    title: (t) => '',
    description: (t) => t('Remove User'),
  },
  'complete-activity': {
    title: (t) => '',
    description: (t) => t('Complete Activity'),
  },
  'apply-calendar': {
    title: (t) => '',
    description: (t) => t('Apply Calendar'),
  },
  minimize: {
    title: (t) => '',
    description: (t) => t('Minimize'),
  },
  'show-variables': {
    title: (t) => '',
    description: (t) => t('Hourglass'),
  },
  'smartconnector-dry-run': {
    title: (t) => '',
    description: (t) => t('Start Dry Run'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {
  'resize-handle': -45,
};

export const animation: IconAnimation = {
  loading: 'spin',
};

export const compoundIcons: CompoundIconSettings = {
  'options-alt': {
    secondary: faEllipsisVertical as IconDefinition,
    outline: 'circle',
    secondaryPosition: 'center',
    secondaryClassName: 'scale-[70%]',
  },
};

export const colorDefinitions: ColorIconSettings = {
  loading: {
    primary: 'feedback-toast-information',
    secondary: 'background-medium',
  },
};

export const customIcons: CustomIconSettings = {
  'filter-fields': InputPipe,
};

export const fixedWidthIcons: FixedWidthIconSettings = {
  close: true,
  search: true,
  loading: true,
};
