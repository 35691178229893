import WholeNumberInput from '__components/Inputs/TextInput/presets/WholeNumber';
import { RangeInputContainer } from './styled';
import { NumberInputHandlerParams, RangeInputProps } from './types';

export const IntegerRange = ({
  step,
  value,
  placeholder,
  error,
  width,
  onChange,
}: RangeInputProps<number | null>) => {
  const min = value?.[0];
  const max = value?.[1];

  return (
    <RangeInputContainer width={width}>
      <WholeNumberInput
        value={min}
        placeholder={placeholder}
        error={error}
        onValueChange={({ floatValue }: NumberInputHandlerParams) => {
          onChange(step, [floatValue === undefined ? null : floatValue, max]);
        }}
      />
      <WholeNumberInput
        value={max}
        placeholder={placeholder}
        error={error}
        onValueChange={({ floatValue }: NumberInputHandlerParams) => {
          onChange(step, [min, floatValue === undefined ? null : floatValue]);
        }}
      />
    </RangeInputContainer>
  );
};
