import styled from '@emotion/styled';
import css from '@emotion/css';
import { appBackground, grayScale } from 'app/colors';
import { DropPlaceholder } from '__components/MultiColumnBuilder/components/BaseOption/styles';

export const BuilderWrapper = styled.div`
  height: 420px;
  display: flex;
  flex-direction: column;
`;

export const StyledDropPlaceholder = styled(DropPlaceholder)<{
  margin: number;
  over: boolean;
}>`
  & > div > div {
    border: 1px dashed ${grayScale.mediumLight};
    background: ${appBackground};
    margin-top: ${({ margin }) => margin ?? 5}px;

    padding-bottom: 14px;
    margin-right: 18px;
    ${({ over }) =>
      over
        ? css`
            border-style: solid;
          `
        : ''}
  }
  p {
    color: ${grayScale.mediumDark};
  }
  p:hover {
    color: ${grayScale.dark};
  }
`;

export const LeftColumnWrapper = styled.div`
  padding-top: 14px;
  padding-right: 20px;
  margin-left: -1px; // design nudge
`;

export const TextWrapper = styled.div`
  max-width: 400px;
`;
