import {
  DEFAULT_ROW_CONTAINER_PROPS,
  EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
} from '@kizen/page-builder/nodes/containers';

export const DEFAULT_EMAIL_ROW_PROPS = {
  ...DEFAULT_ROW_CONTAINER_PROPS,
  ...EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
};

export const DEFAULT_FORM_ROW_PROPS = DEFAULT_ROW_CONTAINER_PROPS;
