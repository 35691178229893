import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import { GridContainer, HeaderToggles } from './styled';

export const SectionContent = ({
  columnOneLabel,
  columnTwoLabel,
  columnThreeLabel,
  columnFourLabel,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <GridContainer marginBottom="17">
        <KizenTypography weight="bold">{t('Permission Area')}</KizenTypography>
        <HeaderToggles>
          <KizenTypography as="span" weight="bold">
            {columnOneLabel || t('None')}
          </KizenTypography>
          <KizenTypography as="span" weight="bold">
            {columnTwoLabel || t('View')}
          </KizenTypography>
          <KizenTypography as="span" weight="bold">
            {columnThreeLabel || t('Create/Edit')}
          </KizenTypography>
          <KizenTypography as="span" weight="bold">
            {columnFourLabel || t('Delete/All')}
          </KizenTypography>
        </HeaderToggles>
      </GridContainer>
      {children}
    </>
  );
};
