import { getOptionValue, namedToOptionDeleted } from 'services/helpers';

import {
  checkDeleted,
  nameDescriptor,
  setDelayAmount,
} from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps'; // { //edgeLabels }

const forApi = (orgDetails) => {
  const { activity, delayAmount, type, ...details } = orgDetails;
  return {
    stepGoal: {
      ...details,
      delayAmount: setDelayAmount(delayAmount),
      triggers: [
        {
          type: stepConfig.activityLogged.type,
          triggerActivityLogged: {
            activityTypeId: getOptionValue(activity),
          },
        },
      ],
    },
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { activityType } = firstTrigger.triggerActivityLogged;

  checkDeleted(
    messageDictionary,
    addErrorMessage,
    activityType,
    nameDescriptor
  );

  const activity = namedToOptionDeleted(activityType);
  const type = stepConfig.activityLoggedGoal.type;

  return { activity, type };
};

export const activityLoggedGoalDTO = {
  forApi,
  forFlow,
};
