import React, { useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale, shadowLight } from 'app/colors';
import { borderRadii, gutters } from 'app/spacing';
import useField from 'hooks/useField';
import DraggableItem from 'components/Kizen/DraggableItem';
import EditableText from 'components/Kizen/EditableText';
import Validation, { useValidation } from 'components/Inputs/Validation';
import { useTranslation } from 'react-i18next';

export const fieldMargin = gutters.spacing(3) / 2;

const FieldWrapper = styled(DraggableItem)`
  flex: 1;
  min-width: 0;
  height: 42px;
  padding: ${gutters.spacing(2)}px;
  margin: ${fieldMargin}px;
  ${({ dragging }) => dragging && shadowLight}
  .FieldHandle {
    cursor: grab;
    margin-right: ${gutters.spacing(2)}px;
  }
  && .FieldHandle:hover svg {
    color: ${grayScale.dark};
  }
  &:hover .FieldHandle svg {
    color: ${grayScale.mediumDark};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${grayScale.light};
      && .FieldHandle,
      && .FieldHandle:hover {
        cursor: default;
        svg {
          color: ${grayScale.medium};
        }
      }
    `}
`;

const FieldLayout = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;

  // Input
  > *:first-child {
    min-width: 0;
    max-width: 500px;
    input[disabled] {
      background: none;
    }
  }
`;

export default function BuilderField(props) {
  const {
    field,
    onChangeDescription,
    onFocus,
    onValidate,
    children,
    FieldBeforeText,
    Actions,
    ...others
  } = props;
  const { t } = useTranslation();

  const [stagedDescription, setStagedDescription] = useField(
    field.description,
    [field]
  );
  const fieldRef = useRef();
  const [validation, validationProps] = useValidation(fieldRef, {
    value: stagedDescription,
    validate: {
      full: (desc) => {
        if (!desc.trim()) {
          return t('This field is required');
        }
        if (onValidate) {
          return onValidate(desc, field);
        }
        return true;
      },
    },
  });

  // if it's a new field let's focus on it
  useEffect(() => {
    if (field?.newest && fieldRef.current) {
      fieldRef.current.focus();
    }
  }, [field]);

  return (
    <FieldWrapper
      disabled={field.isHidden}
      data-qa-field-id={field.id}
      {...others}
    >
      <FieldLayout>
        {FieldBeforeText && <FieldBeforeText field={field} {...others} />}
        <EditableText
          ref={fieldRef}
          onFocus={onFocus}
          disabled={field.isDefault}
          value={stagedDescription}
          onChange={setStagedDescription}
          {...validation}
          onBlur={() => {
            if (onChangeDescription) {
              onChangeDescription(stagedDescription, field);
            }
            validation.onBlur();
          }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              fieldRef.current.blur();
            }
          }}
        />
        <Validation inModal {...validationProps} />
      </FieldLayout>
      {children}
      {Actions && <Actions field={field} {...others} />}
    </FieldWrapper>
  );
}

BuilderField.propTypes = {
  field: PropTypes.object.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  onFocus: PropTypes.func,
  FieldBeforeText: PropTypes.object,
  Actions: PropTypes.object,
};

BuilderField.defaultProps = {
  onValidate: null,
  onFocus: null,
  FieldBeforeText: null,
  Actions: null,
};

export const FieldPlaceholder = styled.div`
  position: absolute;
  top: ${fieldMargin}px;
  bottom: ${fieldMargin}px;
  left: ${fieldMargin}px;
  right: ${fieldMargin}px;
  background-color: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.standard};
`;
