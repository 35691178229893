import { useParams, useHistory, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_PAGE_CONFIG } from './constants';
import { SUBPAGES, SUBROUTES } from './routes';
import FieldService from 'services/FieldService';
import TeamMemberService from 'services/TeamMemberService';
import { useAsync } from 'react-use';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import Loader from 'components/Kizen/Loader';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { setRecordTitle } from 'pages/CustomObjects/RecordsPage';
import { useBreakpoint } from 'app/spacing';
import { NavBar } from './components/NavBar';
import { getPathBack } from './helpers';
import useField from 'hooks/useField';
import { isContact } from 'components/Wizards/CustomObject/utilities';
import { enrichAndRepairQuickFilterSettings } from 'store/utilities';
import { monitoringExceptionHelper } from 'sentry/helpers';

export const QuickFiltersPage = ({ title }) => {
  const { t } = useTranslation();
  const { objectId, subpage, templateId } = useParams();
  const history = useHistory();

  const { value: model, loading: loadingModel = true } = useAsync(async () => {
    return FieldService.getModel({ id: objectId });
  }, [objectId]);

  useSetTitleOnLoad(title(t), objectId ? model : null, setRecordTitle);

  useEffect(() => {
    if (!subpage) {
      history.replace(history.location.pathname + '/' + SUBPAGES.MINE);
    }
    if (subpage === SUBPAGES.MINE && templateId) {
      history.replace(getPathBack(history.location.pathname));
    }
  }, [history, subpage, templateId]);

  const {
    value: [categories = EMPTY_ARRAY, fields = EMPTY_ARRAY] = EMPTY_ARRAY,
    loading: loadingCategories = true,
  } = useAsync(async () => {
    if (!model) {
      return EMPTY_ARRAY;
    }
    const params = {
      for: {
        ...model,
        id: objectId,
        objectClass: 'custom_objects',
      },
    };
    return Promise.all([
      FieldService.getCategories(params),
      FieldService.getFields(params),
    ]);
  }, [objectId, model]);

  const categorizedFields = useMemo(
    () =>
      categories.map(({ name, id }) => {
        return {
          label: name,
          id,
          isGroupedItem: true,
          fields: fields.filter((field) => field.category === id),
        };
      }),
    [categories, fields]
  );

  const { value: rawPageConfig, loading: loadingPageConfig = true } =
    useAsync(async () => {
      if (!model || !fields?.length) {
        return null;
      }
      const { quickFilterSettings, quickFilteringFacets } =
        await TeamMemberService.getCustomObjectRecordsListPageConfig(
          model.id,
          DEFAULT_PAGE_CONFIG
        );

      const enrichedQuickFilterSettings =
        await enrichAndRepairQuickFilterSettings(quickFilterSettings, fields);
      return {
        quickFilterSettings: enrichedQuickFilterSettings,
        quickFilteringFacets,
      };
    }, [model?.id, fields]);

  const [settingPageConfig, setSettingPageConfig] = useState(false);
  const [pageConfig, setPageConfig] = useField(rawPageConfig);

  const { quickFilterSettings, quickFilteringFacets } = pageConfig || {};

  const onSubmitQuickFilters = useCallback(
    async (payload) => {
      setSettingPageConfig(true);
      try {
        await TeamMemberService.updateCustomObjectRecordsListPageConfig(
          model?.id,
          payload
        );
        setPageConfig(payload);
      } catch (err) {
        monitoringExceptionHelper(err);
      } finally {
        setSettingPageConfig(false);
      }
    },
    [model, setPageConfig]
  );

  const isMobile = useBreakpoint();

  return (
    <>
      {templateId ? null : <NavBar routes={SUBROUTES} />}
      <Loader
        loading={
          !model ||
          !categorizedFields.length ||
          !pageConfig ||
          loadingModel ||
          loadingCategories ||
          loadingPageConfig
        }
      >
        <Switch>
          {Object.entries(SUBROUTES).map(([key, route]) => {
            const Component = route.component;
            return (
              <Route
                key={key}
                path={'*/quick-filters/' + route.path}
                exact={route.exact !== false}
              >
                <Component
                  quickFilterSettings={quickFilterSettings}
                  quickFilteringFacets={quickFilteringFacets}
                  categorizedFields={categorizedFields}
                  fields={fields}
                  onSubmitQuickFilters={onSubmitQuickFilters}
                  disabled={settingPageConfig}
                  isMobile={isMobile}
                  objectId={objectId}
                  templateId={templateId}
                  model={model}
                  goBackPath={
                    isContact(model)
                      ? '/clients'
                      : `/custom-objects/${model?.id}`
                  }
                />
              </Route>
            );
          })}
        </Switch>
      </Loader>
    </>
  );
};
