import { RefObject, createContext } from 'react';

interface ActionBarContextType {
  id: string;
  actionTrayRef?: RefObject<HTMLElement>;
}

export const ActionBarContext = createContext<ActionBarContextType>({
  id: '',
});
