import styled from '@emotion/styled';
import { fontSizes, fontWeights } from 'app/typography';
import EditableText from 'components/Kizen/EditableText';
import { contentWidthCss } from 'components/Layout/PageContentWidth';
import PageToolbar from 'components/Layout/PageToolbar';
import Subsection from 'components/Modals/Common/Subsection';
import BigTableLayout from 'components/Layout/BigTableLayout';
import BigTable from 'components/Tables/Big';
import TextInputInline from 'components/Inputs/inline/TextInput';
import Icon from 'components/Kizen/Icon';

export const StyledPageToolbar = styled(PageToolbar)`
  ${contentWidthCss};
  margin: 20px auto;
  padding: 0;
  height: 36px;
`;

export const StyledTableLayout = styled(BigTableLayout)`
  margin-top: -20px;
  & > div {
    padding: 0 20px;
  }
`;

export const Wrapper = styled.div`
  ${contentWidthCss};
  display: flex;
  flex-direction: column;
`;

export const TableTitle = styled(Subsection)`
  & > h3 {
    margin-bottom: 0;
  }
  && {
    margin: 20px 0 8px;
  }
`;

export const TemplateName = styled(EditableText)`
  max-width: 350px;
  input {
    font-size: ${fontSizes.text};
    font-weight: ${fontWeights.bold};
  }
`;

export const StyledBigTable = styled(BigTable)`
  padding: 0 20px;
  tbody > tr:hover {
    background-color: #fff;
  }
  && thead > tr {
    border: none;
  }
`;

export const NameCellInput = styled(TextInputInline)`
  max-width: 100%;
`;

export const StyledIconMenu = styled(Icon)`
  && {
    width: 20px;
    height: 5px;
    margin-left: 2px;

    svg {
      width: 20px;
      height: 5px;
      margin-bottom: 10px;
    }
  }
`;
