import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ListCard, {
  ListCardCell,
  ListCardSortButton,
} from 'components/Layout/ListCard';
import { TextEllipsis } from 'components/Kizen/Table';
import { isPipelineObject } from 'components/Modals/utilities';
import StandardListItem from './StandardListItem';
import PipelineListItem from './PipelineListItem';
import { StyledListCardHeader } from 'pages/ContactsPage/styles';

const MobileTable = ({
  model,
  sort,
  records,
  selectionOptions,
  selection,
  onChangeSort,
  onChecked,
  onCheckedSelection,
  ...others
}) => {
  const { t } = useTranslation();
  return (
    <ListCard>
      <StyledListCardHeader compactHeader {...others}>
        <ListCardCell>
          <TextEllipsis as="span" weight="bold">
            {`${model.entityName} ${t('Name')}`}
          </TextEllipsis>
          <ListCardSortButton
            sort={sort && sort.column === 'name' ? sort : null}
            onChangeSort={(direction) =>
              onChangeSort({ column: 'name', direction })
            }
          />
        </ListCardCell>
      </StyledListCardHeader>
      {records.map((item) =>
        isPipelineObject(model) ? (
          <PipelineListItem
            key={item.id}
            model={model}
            checked={item.checked}
            onChecked={onChecked}
            record={item}
          />
        ) : (
          <StandardListItem
            key={item.id}
            model={model}
            checked={item.checked}
            onChecked={onChecked}
            record={item}
          />
        )
      )}
    </ListCard>
  );
};

MobileTable.propTypes = {
  model: PropTypes.object.isRequired,
  sort: PropTypes.object.isRequired,
  records: PropTypes.array.isRequired,
  selectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selection: PropTypes.object.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChecked: PropTypes.func.isRequired,
  onCheckedSelection: PropTypes.func.isRequired,
};

export default MobileTable;
