import { getOptionValue, snakeToCamelCase } from 'services/helpers';
import { capitalize } from 'components/helpers';
import steps from 'pages/AutomationEngine/steps';
import DraggableStepCard from '../../DraggableStepCard';
import * as TypesContent from '../index';
import { getRelationshipFieldsContext } from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/CreateRelatedEntity/helpers';

// t is the first arg in other
export const getCardText = ({ step, ...other }) => {
  let key = step.type === 'manual' ? 'ManualTrigger' : step.type;
  // as we don't have separation between Goal and Trigger
  // we need this hack to get correct card name
  if (step.type === steps.goal.type) {
    key = step.details.type.substr(
      0,
      // +1 need to _ character in `*_goal` card name
      step.details.type.length - (steps.goal.type.length + 1)
    );
  }

  try {
    const contentFunction =
      TypesContent[`${snakeToCamelCase(capitalize(key))}CardContent`];
    return contentFunction({ step, ...other });
  } catch (error) {
    return 'Unknown';
  }
};

export const getDescription = ({
  step,
  automation,
  fields,
  business,
  groupedOptions,
  t,
}) => {
  const { details } = step;
  const additionalData = () => {
    const data = {};
    const createReleatedEntityAction = groupedOptions.action?.find(
      ({ type }) => type === steps.createRelatedEntity.type
    );
    if (createReleatedEntityAction) {
      const { relatedObjectName } = getRelationshipFieldsContext(
        fields,
        createReleatedEntityAction.details.contextEntityField
      );
      data.relatedObjectName = relatedObjectName;
    }
    return data;
  };

  const realDetails = () => {
    const options = getOptions({
      groupedOptions,
      automation,
      fields,
      t,
      business,
      additionalData: additionalData(),
    });

    const key = details.stepKey ? details.stepKey : details.triggerKey;
    const find = options.find((o) => o.value === getOptionValue(key));
    return {
      ...details,
      stepKey: details.stepKey ? { ...find } : null,
      triggerKey: details.triggerKey ? { ...find } : null,
    };
  };

  const data = realDetails().stepKey || realDetails().triggerKey;
  const typeDictionary = {
    Action: t('Action'),
    Condition: t('Condition'),
    Delay: t('Delay'),
    Goal: t('Goal'),
    Triggers: t('Trigger'), // note no s
  };
  return `${t('Go To Step')} [${typeDictionary[data.type] || data.type}: ${
    data.label
  }]`;
};

export const content = ({ step }) => step.description;

const getOptions = ({
  groupedOptions,
  automation,
  fields,
  t,
  business,
  additionalData,
}) => {
  return Object.entries(groupedOptions).reduce((acc, value) => {
    const arr = value[1].map((step) => {
      return {
        type: capitalize(value[0]),
        label: getCardText({
          step,
          t,
          automation,
          fields,
          business,
          ...additionalData,
        }),
        value: step.id,
      };
    });
    return [...acc, ...arr];
  }, []);
};

export default function GoToAutomationStep({ type, step, ...others }) {
  return <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>;
}
