import { getOriginalError } from 'services/AxiosService';
import { emptyLongDash } from './constants';

const parseError = (error) => {
  if (Array.isArray(error)) {
    return parseError(error[0]);
  }
  if (error && typeof error === 'object') {
    return parseError(Object.values(error)[0]);
  }
  return error;
};

export const getErrorMessage = (error) => {
  const axiosError = getOriginalError(error);
  return parseError(axiosError);
};

export const getStatusName = (status, t) => {
  switch (status) {
    case 'initialize':
      return t('Queued');
    case 'in_progress':
      return t('In progress');
    case 'completed':
      return t('Completed');
    case 'failed':
      return t('Failed');
    default:
      return emptyLongDash;
  }
};
