import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';
import IconButtonAbsoluteMenu from 'components/Tables/Big/IconButtonAbsoluteMenu';

export const StyledIconMenu = styled(Icon)`
  && {
    width: 20px;
    height: 5px;
    margin-left: 2px;

    svg {
      width: 20px;
      height: 5px;
      margin-bottom: 10px;
    }
  }
`;

export const StyledIconButtonAbsoluteMenu = styled(IconButtonAbsoluteMenu)`
  && .IconButtonMenu__menu {
    ${({ above }) => !above && 'transform: translate(-100%, -5px);'}
    ${({ above }) =>
      above && 'transform: translate(-100%, -100%) translateY(-20px);'}
  }
`;
