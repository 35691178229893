import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import Button from 'components/Button';

export const StyledButton = styled(Button)`
  height: ${gutters.spacing(4)}px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: calc(-${gutters.spacing(3)}px - 1px);
  i {
    margin-top: -2px; // nudge to center with text
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
  > svg {
    height: 12px;
    width: auto;
  }
`;
