import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from 'app/colors';
import { OutlineLabel } from 'components/Inputs/InputControl';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'components/Kizen/Tooltip';
import Icon from 'components/Kizen/Icon';

const InfoIcon = styled(Icon)`
  & svg {
    width: 10px;
    height: 10px;
  }
  margin-left: 5px;
`;
const InfoOutlineLabel = styled(OutlineLabel)`
  display: inline-flex;
`; // to center the icon

const InfoTooltip = styled(Tooltip)`
  width: 300px;
  .tooltip-inner {
    max-width: 100%;
    text-align: left;
    position: relative;
    left: -5px;
  }
  ${({ placement }) =>
    placement === 'right'
      ? css`
          .arrow {
            left: -5px;
          }
          .arrow:before {
            border-width: 6px 6px 6px 0px;
            border-right-color: ${grayScale.dark};
            border-top-color: transparent;
          }
        `
      : ''}
`;

export const LabelWithTooltip = ({
  label,
  tooltipText,
  placement = 'right',
  ...props
}) => {
  return (
    <InfoOutlineLabel {...props}>
      {label}
      <OverlayTrigger
        overlay={<InfoTooltip label={tooltipText} show placement={placement} />}
        placement={placement}
      >
        <InfoIcon icon="info-circle" />
      </OverlayTrigger>
    </InfoOutlineLabel>
  );
};

export const TextWithTooltip = ({
  label,
  tooltipText,
  placement = 'right',
}) => {
  return (
    <>
      {label}
      <OverlayTrigger
        overlay={<InfoTooltip label={tooltipText} show placement={placement} />}
        placement={placement}
      >
        <InfoIcon icon="info-circle" />
      </OverlayTrigger>
    </>
  );
};
