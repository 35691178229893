import css from '@emotion/css';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

export const StyledText = styled(TextEllipsisWithTooltip)`
  width: calc(100% - 6px);
`;

export const StyledLink = styled(KizenTypography)`
  font-size: 1em;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  flex-grow: 1;
  margin-top: -1px;
  margin-bottom: -1px;
  && {
    margin-left: 2px;
  }
  & > div {
    border: none;
    height: unset;
  }

  .OwnerSelect__menu,
  .team-member-typeahead__menu {
    border: 1px solid #d8dde1;
    border-radius: 4px;
  }

  .OwnerSelect__indicators i,
  .team-member-typeahead__indicators i {
    cursor: pointer;
  }

  .OwnerSelect__indicators i:hover,
  .team-member-typeahead__indicators i:hover {
    color: ${colorsButton.iconGray.hover};
  }

  .OwnerSelect__control,
  .team-member-typeahead__control {
    background: transparent;
    border: none;
  }

  ${({ collapse }) =>
    collapse
      ? css`
          .OwnerSelect__control,
          .team-member-typeahead__control {
            width: unset;
            max-width: 100%;
          }

          .OwnerSelect__indicators i,
          .team-member-typeahead__indicators i {
            margin-left: 0;
          }
        `
      : ''};
`;
