import { useRef, useState } from 'react';
import useModal from 'components/Modals/useModal';
import { MaybeInputControl } from '../InputControl';
import Validation, { useValidation } from '../Validation';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { StyledOutlineInputLayout, SummaryInputWrapper } from './styles';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { SummarizedPlaceholder } from './SummarizedPlaceholder';
import { SummarizedMultiSelectModal } from './Modal';

const OutlineSummarizedMultiSelect = (props) => {
  const validationRef = useRef();
  const [validation, validationProps] = useValidation(validationRef, props);
  const {
    field,
    entity,
    fieldId,
    onChange,
    inModal = false,
    errorPlacement,
    error = EMPTY_OBJECT,
    showVisibleIcon = true,
    ...inputControlProps
  } = props;

  const {
    disabled: disabledProp,
    value,
    sizing = false,
    viewable = true,
  } = props;

  const { assignFieldHandle } = useKeyBoardContext();

  const [focused, setFocused] = useState(false);
  const [modalProps, , modal] = useModal();
  const disabled = disabledProp && !viewable;
  const buttonRef = useRef(null);
  assignFieldHandle(fieldId, {
    customFocus: () => {
      buttonRef.current?.focus();
      return buttonRef.current;
    },
    disabled,
  });

  return (
    <>
      <MaybeInputControl
        forInput
        variant="outline"
        {...{
          ...inputControlProps,
          error: true,
          disabled: false,
          shrink: true,
        }}
        margin
        sizing={sizing}
      >
        <StyledOutlineInputLayout
          ref={validationRef}
          disabled={disabled}
          viewable={viewable}
          error={error || validation.error}
          focused={focused}
        >
          <SummaryInputWrapper
            ref={buttonRef}
            onClick={() => (!disabledProp || viewable) && modal.show()}
            disabled={disabled}
            showVisibleIcon={showVisibleIcon}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            padding="0 12px"
          >
            <SummarizedPlaceholder
              {...value}
              field={field}
              showVisibleIcon={showVisibleIcon && (!disabledProp || viewable)}
              isFullText
            />
          </SummaryInputWrapper>
        </StyledOutlineInputLayout>
        <Validation
          inModal={inModal}
          errorPlacement={errorPlacement}
          {...validationProps}
        />
      </MaybeInputControl>
      {modalProps.show && (
        <SummarizedMultiSelectModal
          show
          field={field}
          entity={entity}
          onChange={onChange}
          modalProps={modalProps}
          readOnly={disabledProp}
          value={value}
        />
      )}
    </>
  );
};

export default OutlineSummarizedMultiSelect;
