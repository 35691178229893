export const FORMDATA_FIELDS = {
  SCHEDULED_ACTIVITY_ID: 'scheduledActivityId',
  NOTES: 'notes',
  FIELDS: 'fields',
  RELATED_OBJECTS: 'relatedObjects',
  MENTIONS: 'mentions',
};

export const STEPS = {
  RELATED_OBJECTS: 'type',
  ACTIVITY_SETTINGS: 'settings',
};

export const EMPTY_ARRAY = [];

export const EMPTY_OBJECT = {};

export const FORM_ACTIONS = {
  update: 'UPDATE',
  reset: 'RESET',
};

export const dummyObject = {
  access: {
    edit: true,
  },
};
