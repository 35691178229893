import BasicModal from 'components/Modals/presets/BasicModal';
import { useTranslation } from 'react-i18next';
import { StyledText } from './styles';

export const DeleteConfirmationModal = ({ active, message, ...rest }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      heading={t('Please Confirm Deletion')}
      buttonText={t('Confirm')}
      {...rest}
    >
      <StyledText>{message}</StyledText>
    </BasicModal>
  );
};
