import { useMemo, useCallback, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import Collapse from 'react-bootstrap/Collapse';
import styled from '@emotion/styled';
import { layers } from 'app/spacing';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import ButtonWithDropdown from 'components/Button/WithDropdown';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { shadowLight } from 'app/colors';
import useCan from 'hooks/useCan';
import { TRow, EmptyCell as TCell } from 'components/Kizen/Table';
import PageToolbar, {
  FilterGroupSelector,
  PageSearchInput,
  PageToolbarFiltersButton,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import AddRecordTab from 'components/Tables/Big/AddRecordTab';
import ExpandTableTab from 'components/Tables/Big/ExpandTableTab';
import TablePagination from 'components/Tables/Big/TablePagination';
import { HoverableRow } from 'components/Tables/Big/NewRowMenuCell';
import { BigTableWithLayoutConsumer } from 'components/Tables/Big';
import {
  BigTableLayoutContext,
  BigTableLayoutWithProvider,
} from 'components/Layout/BigTableLayout';
import { getColumns, shallowEqualList } from 'pages/Common/helpers/table';
import BaseClientFilterDropdown from 'pages/Common/components/ClientFilterDropdown2';
import ArchiveModal from 'components/Modals/ArchiveModal';
import { StyledButtonWithDropdown } from 'pages/CustomObjects/RecordsPage/DesktopLayout/DesktopTable'; // Special for testing KZN-4208
import UploadContactsModal from './UploadContactsModal';
import { createColumns } from './columns';
import { useTranslation } from 'react-i18next';
import AddRecordTRow from 'pages/Common/components/AddRecordTRow';
import {
  getHasFilterError,
  getIsContactsFetching,
  getIsContactsFiltering,
} from 'store/contactsPage/selectors';
import FieldService from 'services/FieldService';
import { SizedCell as NewSizedCell } from 'pages/ContactsPage/Table';
import { mergeColumnSettings } from 'pages/ColumnsPage/helpers';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { getDefaultNewRecord } from 'pages/Common/components/AddRecordTRow/helpers';
import { useKeyListenersInline } from 'hooks/keyboardEventHandler/useKeyListenersInline';
import Loader from 'components/Kizen/Loader';
import { QuickFilters } from 'components/Filters/QuickFilters';
import { getDefaultColumnSettings } from 'components/Wizards/CustomObject/steps/CustomLayout/subsections/DefaultColumns/helpers';
import { useClearFilter } from 'hooks/useClearFilter';

const ClientFilterDropdown = styled(BaseClientFilterDropdown)`
  ${shadowLight}
`;

const scrollToTop = () => {
  if (window.scrollTo) {
    // If there's browser support
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
    window.scrollTop = 0;
  }
};

const FilterButtonWithLayoutConsumer = ({
  badge,
  menuIsOpen,
  scrollToTop,
  toggleMenu,
  errors,
}) => {
  const { scrollTransitioned, scrolledToTable } = useContext(
    BigTableLayoutContext
  );

  const handleClick = () => {
    if (scrolledToTable) {
      scrollToTop();
      toggleMenu(true);
    } else {
      toggleMenu((ps) => !ps);
    }
  };

  return (
    <PageToolbarFiltersButton
      badge={badge}
      showBackground={menuIsOpen && !scrollTransitioned}
      onClick={handleClick}
      errors={errors}
    />
  );
};

const FilterDropdowntWithLayoutConsumer = ({ openMenu, ...props }) => {
  const { filterDropdownRef } = useContext(BigTableLayoutContext);

  return (
    <Collapse mountOnEnter in={openMenu}>
      <ClientFilterDropdown ref={filterDropdownRef} {...props} />
    </Collapse>
  );
};

export default function ContactsTabularPage({
  model,
  contacts,
  contactsCount,
  contactsCountAndNew,
  onSubmitContact,
  groups,
  groupId = null,
  filterGroup,
  fields,
  allFields,
  onChangeGroupId,
  search = null,
  onChangeSearch,
  sort,
  onChangeSort,
  onChecked,
  onCheckedSelection,
  selectionOptions,
  categorizedFields,
  pageNumber,
  pageSize,
  onChangePageNumber,
  onChangePageSize,
  choiceAction,
  openAddContactModal,
  openPerformModal,
  applyFiltersAction,
  updateFilterCount,
  performActionOptions,
  saveGroupAction,
  deleteGroupAction,
  setFilterName,
  openMenu,
  toggleMenu,
  clearFilter,
  selection,
  filterInfo,
  quickFilters,
  quickFilterConfig,
  fullFilterObject,
  applyQuickFilters,
  onSelectViewContact,
  onSelectArchiveContact,
  handleUpdateTableRecords = undefined,
  onOpenSelectionMenu,
  errorsReturned,
  isLoadingView,
  ...others
}) {
  const isFetchingContacts = useSelector(getIsContactsFetching);
  const isFilteringContacts = useSelector(getIsContactsFiltering);
  const hasFilterError = useSelector(getHasFilterError);
  const preReleaseFeatures = usePreReleaseFeatures();
  const { t } = useTranslation();
  const [showUploader, setShowUploader] = useState(false);

  const handleChangeGroup = useCallback(
    ({ value }) => {
      onChangeGroupId(value === 'none' ? null : value);
    },
    [onChangeGroupId]
  );

  const hasBulkActions = performActionOptions && !!performActionOptions.length;
  const savedColumnSettings = useSelector(
    (s) => getColumns(s.contactPage.pageConfig),
    shallowEqualList
  );

  const defaultColumnSettings = useMemo(
    () => (model ? getDefaultColumnSettings(model, fields, t) : []),
    [model, fields, t]
  );

  const columnSettings = useMemo(() => {
    return mergeColumnSettings(defaultColumnSettings, savedColumnSettings);
  }, [savedColumnSettings, defaultColumnSettings]);

  const [archivingId, setArchivingId] = useState(null);

  const indeterminate = !selection.checked && selection.checkedCount > 0;
  const columns = useMemo(
    () =>
      createColumns({
        model,
        fields,
        columnSettings,
        hasBulkActions,
        onSubmitContact,
        indeterminate,
        categorizedFields,
        handleUpdateTableRecords,
        serviceToUse: FieldService,
        handleUpdateFieldOption: () => {},
        onSelectAction: ({ value }, contact) => {
          // see components/Tables/Big/RowMenuCell for possible actions
          if (value === 'view') {
            onSelectViewContact(contact);
          }
          if (value === 'archive') {
            setArchivingId(contact.id);
          }
        },
        t,
      }),
    [
      fields,
      columnSettings,
      hasBulkActions,
      onSubmitContact,
      indeterminate,
      categorizedFields,
      handleUpdateTableRecords,
      t,
      onSelectViewContact,
      model,
    ]
  );

  const checkableContacts = useMemo(() => {
    return contacts.map((row, index, arr) => ({
      ...row,
      meta: {
        ...row.meta,
        id: row.id,
        checked: row.checked,
        onChecked,
        // Last few data items should open their menus upwards
        openMenuAbove: Math.max(10, arr.length) - index <= 3,
      },
    }));
  }, [contacts, onChecked]);

  const headData = useMemo(
    () => ({
      meta: {
        id: 'header',
        checked: selection.checked,
        indeterminate,
        onSelectionChanged: ({ value }) => onCheckedSelection(value),
        selectionOptions,
        sort,
        onSort: (column, direction) => onChangeSort({ column, direction }),
        onOpenSelectionMenu,
      },
    }),
    [
      selection,
      onCheckedSelection,
      indeterminate,
      sort,
      onChangeSort,
      selectionOptions,
      onOpenSelectionMenu,
    ]
  );

  const [addingId, setAddingId] = useState(null);
  const handleNewContact = async (newContact) => {
    setAddingId(null);
    if (newContact) {
      return onSubmitContact(newContact.id, newContact, {
        created: true,
      });
    }
    return null;
  };

  const handleClickMenu = ({ value }) => {
    if (value === 'new-contact') {
      openAddContactModal();
    }
    if (value === 'upload_contacts') {
      setShowUploader(true);
    }
  };

  const canCreateContact = useCan({ create: 'contacts' });
  const canUploadContacts = useCan({
    action: 'contacts',
    for: 'uploadContacts',
  });

  const newContactOptions = useMemo(() => {
    return [
      canCreateContact && {
        value: 'new-contact',
        label: t('New Contact'),
      },
      canUploadContacts && {
        value: 'upload_contacts',
        label: t('Upload Contacts'),
      },
    ].filter(Boolean);
  }, [canCreateContact, canUploadContacts, t]);

  const fieldListeners = useMemo(() => {
    const id = addingId === 'new' ? 'new' : addingId;
    const updatedContacts = [
      addingId ? getDefaultNewRecord(undefined, id) : null,
      ...contacts,
    ].filter(Boolean);
    return updatedContacts.flatMap(({ id }) =>
      columns
        .filter(({ field }) => !!field)
        .map(({ field }) => {
          return {
            id: `${field?.id}-${id}`,
          };
        })
    );
  }, [columns, addingId, contacts]);

  const handleClearFilter = useClearFilter(clearFilter, toggleMenu);

  const {
    assignFieldHandle,
    getKeyListenersProps,
    forceFocusToItem,
    resetFocus,
  } = useKeyListenersInline(fieldListeners, {}, () => true, !!addingId);

  return (
    <BigTableLayoutWithProvider
      filtersOpen={openMenu}
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <FilterGroupSelector
              value={groupId || 'none'}
              options={groups}
              onChange={handleChangeGroup}
              title={t('Change Group')}
              link="/clients/filter-groups"
            />
            <FilterButtonWithLayoutConsumer
              badge={filterInfo.numberOfFilters}
              menuIsOpen={openMenu}
              scrollToTop={scrollToTop}
              toggleMenu={toggleMenu}
              errors={hasFilterError}
            />
            <PageSearchInput
              placeholder={t('Find Contacts')}
              value={search}
              onChange={onChangeSearch}
              {...getDataQAForInput('search-contacts', 'search')}
              loading={isFilteringContacts}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            data-qa="header-record-count"
            count={contactsCountAndNew}
            single={t('Contact')}
            plural={t('Contacts')}
          />
          <PageToolbarSection>
            {hasBulkActions && (
              <ButtonWithDropdown
                color="blue"
                onChange={choiceAction}
                onClick={openPerformModal}
                options={performActionOptions}
              >
                {t('Perform Action')}
              </ButtonWithDropdown>
            )}
            {(canCreateContact || canUploadContacts) && (
              <StyledButtonWithDropdown
                data-qa="new-contact"
                onChange={handleClickMenu}
                onClick={openAddContactModal}
                options={newContactOptions}
              >
                {t('New Contact')}
              </StyledButtonWithDropdown>
            )}
          </PageToolbarSection>
        </PageToolbar>
      }
      filters={
        <FilterDropdowntWithLayoutConsumer
          openMenu={openMenu}
          key={
            // the truthy value of isAllContactsGroup is a UUID
            filterInfo.isAllContactsGroup || filterInfo.key
          }
          filterData={filterInfo.config}
          and={filterInfo.and}
          applyFiltersAction={applyFiltersAction}
          saveGroupAction={saveGroupAction}
          deleteGroupAction={deleteGroupAction}
          filterName={filterInfo.name}
          setFilterName={setFilterName}
          updateFilterCount={updateFilterCount}
          numberOfFilters={filterInfo.numberOfFilters}
          groupId={groupId}
          filterGroup={filterGroup}
          clearFilter={handleClearFilter}
          betaPreview={preReleaseFeatures}
          objectType="client_client"
          errorsReturned={errorsReturned}
          customObjectId={model?.id}
          noSticky
        />
      }
      quickFiltersPills={
        quickFilters && (
          <QuickFilters
            quickFilters={quickFilters}
            quickFilterConfig={quickFilterConfig}
            fullFilterObject={fullFilterObject}
            search={search}
            resultsCount={contactsCountAndNew}
            model={model}
            fields={allFields}
            onChange={applyQuickFilters}
            errors={filterInfo?.errors?.quickFiltersErrors}
            hasFilterErrors={filterInfo?.errors}
          />
        )
      }
      pagination={
        isLoadingView ? null : (
          <TablePagination
            page={pageNumber}
            perPage={pageSize}
            totalCount={contactsCount}
            onChangePage={onChangePageNumber}
            onChangePerPage={onChangePageSize}
          />
        )
      }
      {...others}
    >
      <KeyBoardContext.Provider
        value={{ assignFieldHandle, getKeyListenersProps }}
      >
        <Loader loading={isLoadingView}>
          <BigTableWithLayoutConsumer
            columns={columns}
            head={
              <TRow head cell={<TCell />} columns={columns} data={headData} />
            }
            addRecordTab={
              canCreateContact && (
                <AddRecordTab
                  key={addingId}
                  adding={Boolean(addingId)}
                  onClick={() => {
                    resetFocus();
                    setAddingId('new');
                  }}
                />
              )
            }
            staleData={isFetchingContacts || isFilteringContacts}
            expandTableTab={<ExpandTableTab />}
            zIndex={layers.content(0, 0)} // needs to at zero - {todo} reafctor big table so the z-indexes work with out hacks
          >
            {/* Just a spacer when there are no results */}
            {!checkableContacts.length && !addingId ? <tr /> : null}
            {addingId ? (
              <AddRecordTRow
                addingId={addingId}
                fields={fields}
                columnSettings={columnSettings}
                getColumns={createColumns}
                // It's best if onAddingId doesn't change function references over time
                onAddingId={setAddingId}
                onSubmit={handleNewContact}
                forceFocusToItem={forceFocusToItem}
                handleUpdateTableRecords={handleUpdateTableRecords}
              />
            ) : null}
            {/* We can improve perf by separating the checkbox from TRow */}
            {/* Temporarily do not show the record currently being
                  added, which can happen if the user submits an existing
                  email address! */}
            {checkableContacts.map(
              (contact) =>
                addingId !== contact.id && (
                  <HoverableRow
                    key={contact.id}
                    columns={columns}
                    data={contact}
                    model={model}
                    NewSizedCell={NewSizedCell}
                  />
                )
            )}
          </BigTableWithLayoutConsumer>
        </Loader>
      </KeyBoardContext.Provider>
      <ArchiveModal
        show={!!archivingId}
        onHide={() => setArchivingId(null)}
        onConfirm={() => {
          onSelectArchiveContact({ id: archivingId });
          setArchivingId(null);
        }}
      >
        {t(
          'This will archive the Contact and all associated information, so that it can no longer be accessed.'
        )}
      </ArchiveModal>
      {showUploader && (
        <UploadContactsModal
          show
          onHide={() => setShowUploader(false)}
          categorizedFields={categorizedFields}
        />
      )}
    </BigTableLayoutWithProvider>
  );
}
