import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { colorsButton } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import { fontSizes, fontWeights } from 'app/typography';
import Button from 'components/Button';
import { useModalControl } from 'hooks/useModalControl';
import { ImageLibrary } from './ImageLibrary';

const ChangeImageContainer = styled.div`
  display: flex;
`;

const ChangeImageText = styled(KizenTypography)`
  color: ${colorsButton.blue.default};
  font-size: ${fontSizes.buttonLabel};
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};
  cursor: pointer;
`;

const RemoveButton = styled(Button)`
  height: 10px;
  margin-left: ${gutters.spacing(4)}px;
`;

const SelectedFileHeader = styled.span`
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  line-height: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FileName = styled.span`
  font-weight: ${fontWeights.regular};
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  line-height: inherit;
  break-word: break-all;
`;

export const SelectedFileLabel = ({ fileName, ...rest }) => {
  const { t } = useTranslation();

  return (
    <SelectedFileHeader {...rest}>
      {t('Selected')}: <FileName>{fileName || t('None')}</FileName>
    </SelectedFileHeader>
  );
};

export const ImageSelector = ({
  onImageChange,
  onImageDelete,
  onImageRemove,
  imageLibraryOpen = false,
  fileId = null,
  fileName = '',
  ...rest
}) => {
  const { t } = useTranslation();
  const [showFilerUploader, { showModal, hideModal }] =
    useModalControl(imageLibraryOpen);

  const handleFileChange = useCallback(
    async (file) => {
      await onImageChange(file);
      hideModal();
    },
    [onImageChange, hideModal]
  );

  const handleFileDelete = useCallback(
    async (file) => {
      await onImageDelete?.(file);
    },
    [onImageDelete]
  );

  return (
    <>
      {showFilerUploader && (
        <ImageLibrary
          onDelete={handleFileDelete}
          show={showFilerUploader}
          selectedFileId={fileId}
          onConfirm={handleFileChange}
          onHide={hideModal}
        />
      )}
      <ChangeImageContainer {...rest}>
        <ChangeImageText onClick={showModal}>
          {fileId ? t('Change Image') : t('Choose Image')}
        </ChangeImageText>
        {Boolean(fileId && onImageRemove) && (
          <RemoveButton
            noSpace
            variant="text"
            color="red"
            onClick={onImageRemove}
          >
            {t('Remove')}
          </RemoveButton>
        )}
      </ChangeImageContainer>
    </>
  );
};
