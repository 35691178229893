import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getColor, trimNumber, getPercent, getStatsTitle } from './helpers';
import {
  AutomationStatsContainer,
  AutomationStat,
  AutomationStatValue,
  AutomationStatLabel,
  AutomationStatPercent,
} from './styles';

export const AutomationStatsRow = ({ stats }) => {
  const { t } = useTranslation();
  const statsCalculated = useMemo(() => {
    const {
      active = 0,
      paused = 0,
      completed = 0,
      cancelled = 0,
      pausedByAutomation = 0,
      pausedByFailure = 0,
      failed = 0,
      started = 0,
    } = stats;
    const pausedTotal = paused + pausedByAutomation + pausedByFailure;
    const completedTotal = completed + cancelled;
    return [
      {
        title: getStatsTitle('started', t),
        value: trimNumber(started),
        color: getColor('started'),
      },
      {
        title: getStatsTitle('active', t),
        value: trimNumber(active),
        percent: getPercent(started, active),
        color: getColor('active'),
      },
      {
        title: getStatsTitle('completed', t),
        value: trimNumber(completedTotal),
        percent: getPercent(started, completedTotal),
        color: getColor('completed'),
      },
      {
        title: getStatsTitle('paused', t),
        value: trimNumber(pausedTotal),
        percent: getPercent(started, pausedTotal),
        color: getColor('paused'),
      },
      {
        title: getStatsTitle('failed', t),
        value: trimNumber(failed),
        percent: getPercent(started, failed),
        color: getColor('failed'),
      },
    ];
  }, [stats, t]);

  return (
    <AutomationStatsContainer>
      {statsCalculated.map(({ title, value, percent, color }) => (
        <AutomationStat key={title}>
          <AutomationStatValue size="header">{value}</AutomationStatValue>
          <AutomationStatLabel color={color}>{title}</AutomationStatLabel>
          {percent ? (
            <AutomationStatPercent>{percent}</AutomationStatPercent>
          ) : null}
        </AutomationStat>
      ))}
    </AutomationStatsContainer>
  );
};
