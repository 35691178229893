import React from 'react';
import styled from '@emotion/styled';
import { borderRadii, gutters } from 'app/spacing';
import { tableHover } from 'app/colors';
import KizenTypography from 'app/kizentypo';

export default styled(({ children, ...props }) => {
  if (typeof children === 'string') {
    return (
      <KizenTypography as="div" {...props}>
        {children}
      </KizenTypography>
    );
  }
  return <div {...props}>{children}</div>;
})`
  padding: ${gutters.spacing(2)}px;
  background-color: ${({ backgroundColor }) => backgroundColor || tableHover};
  border-radius: ${borderRadii.small};
`;
