import { useState, useEffect } from 'react';
import Card from 'components/Card/Card';
import DomainService from 'services/DomainService';
import { SubSectionWithHeader as SubSection } from 'components/Layout/SubSection';
import { PageSizing } from 'components/Layout/PageContentWidth';
import Section from 'components/Layout/Section';
import useModal from 'components/Modals/useModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { useTranslation } from 'react-i18next';
import { StyledCols as Cols, StyledCard, Container } from './styles';
import DomainSteps, { DOMAIN_STEPS } from './Domain/DomainSteps';
import SubdomainSteps, { SUBDOMAIN_STEPS } from './Domain/SubdomainSteps';
import TrackingScripts from './TrackingScripts';
import { PreReleaseFeature } from 'hooks/usePreReleaseFeatures';
import { toastVariant, useToast } from 'components/ToastProvider';
import { monitoringExceptionHelper } from 'sentry/helpers';
import { useEmailFromDomainEnabledEntitlement } from 'hooks/useEmailFromDomainEnabledEntitlement';

const generateErrorMessage = (error) => {
  const message =
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.message;
  return message;
};

const DomainAndTrackingPage = ({ access }) => {
  // Added eslint disable because loading is not used anywhere currently but is implemented
  // TODO: remove loading if unnecessary
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const [domainFormValues, setDomainFormValues] = useState({
    name: '',
    verification_email: '',
  });
  const [subdomainFormValues, setSubdomainFormValues] = useState({
    name: '',
  });

  const [domainData, setDomainData] = useState();
  const [subdomainData, setSubdomainData] = useState();
  const [domainErrorMessage, setDomainErrorMessage] = useState('');
  const [subdomainErrorMessage, setSubdomainErrorMessage] = useState('');

  const [domainStep, setDomainStep] = useState(DOMAIN_STEPS.DOMAIN_FORM);
  const [subdomainStep, setSubdomainStep] = useState(
    SUBDOMAIN_STEPS.SUBDOMAIN_FORM
  );

  const [showToast] = useToast();

  const handleDomainStepChange = (data) => {
    if (!data) {
      setDomainStep(DOMAIN_STEPS.DOMAIN_FORM);
      return;
    }
    if (data.state === 'active') {
      setDomainStep(DOMAIN_STEPS.COMPLETED);
      return;
    }
    if (!data.email_sending_domain.dkim_verified) {
      setDomainStep(DOMAIN_STEPS.UPDATE_DNS_RECORD);
    } else if (data.email_sending_domain.dkim_verified) {
      setDomainStep(DOMAIN_STEPS.VERIFY_EMAIL);
    }
  };

  const handleDomainData = (data) => {
    if (!data) {
      setDomainData(null);
      return;
    }
    const requiredDnsRecords = [
      {
        record_type: 'CNAME',
        name: `${data.email_sending_domain.subdomain}._domainkey`,
        value: data.email_sending_domain.dkim_cname,
        complete: data.email_sending_domain.dkim_verified,
      },
    ];

    if (data.email_sending_domain && data.email_sending_domain.ses_accounts) {
      data.email_sending_domain.ses_accounts.forEach((account) => {
        requiredDnsRecords.push({
          record_type: 'TXT',
          name: '_amazonses',
          value: account.verification_token,
          complete: account.state === 'active',
        });
      });
    }

    const newData = {
      original: data,
      requiredDnsRecords,
    };

    setDomainData(newData);
  };

  const handleDomainSubmit = async () => {
    setLoading(true);

    await DomainService.createDomain(domainFormValues)
      .then((data) => {
        setLoading(false);
        setDomainErrorMessage('');
        handleDomainStepChange(data);
        handleDomainData(data);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = generateErrorMessage(err);
        setDomainErrorMessage(errorMessage);
        monitoringExceptionHelper(err);
      });
  };

  const handleDomainDelete = async () => {
    setLoading(true);

    await DomainService.deleteDomain(domainData.original.id)
      .then(() => {
        setLoading(false);
        setDomainErrorMessage('');
        handleDomainStepChange(null);
        handleDomainData(null);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = generateErrorMessage(err);
        setDomainErrorMessage(errorMessage);
        monitoringExceptionHelper(err);
      });
  };

  const handleRestartDomainVerification = async () => {
    setLoading(true);

    await DomainService.restartDomainVerification(
      domainData.original.id,
      domainData.original.verification_email
    )
      .then((data) => {
        setLoading(false);
        setDomainErrorMessage('');
        handleDomainStepChange(data);
        handleDomainData(data);
        showToast({
          message: t('DNS verification restarted'),
          variant: toastVariant.SUCCESS,
        });
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = generateErrorMessage(err);
        setDomainErrorMessage(errorMessage);
        monitoringExceptionHelper(err);
      });
  };

  const handleSubdomainStepChange = (data) => {
    if (!data) {
      setSubdomainStep(SUBDOMAIN_STEPS.SUBDOMAIN_FORM);
      return;
    }
    if (data.state === 'active') {
      setSubdomainStep(SUBDOMAIN_STEPS.COMPLETED);
      return;
    }
    // TODO: Check about second step when does it appear
    if (!data.cname_target) {
      setSubdomainStep(SUBDOMAIN_STEPS.EMAIL_WITH_SSL_CERTIFICATE);
      return;
    }
    setSubdomainStep(SUBDOMAIN_STEPS.UPDATE_DNS_RECORD);
  };

  const handleSubdomainData = (data) => {
    if (!data) {
      setSubdomainData(null);
      return;
    }
    setSubdomainData(data);
  };

  const handleSubdomainSubmit = async () => {
    setLoading(true);

    await DomainService.createSubdomain(subdomainFormValues)
      .then(async (data) => {
        setLoading(false);
        setSubdomainErrorMessage('');
        handleSubdomainStepChange(data);
        handleSubdomainData(data);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = generateErrorMessage(err);
        setSubdomainErrorMessage(errorMessage);
        monitoringExceptionHelper(err);
      });
  };

  const handleSubdomainDelete = async () => {
    setLoading(true);

    await DomainService.deleteSubdomain(subdomainData.id)
      .then(() => {
        setLoading(false);
        setSubdomainErrorMessage('');
        handleSubdomainStepChange(null);
        handleSubdomainData(null);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = generateErrorMessage(err);
        setSubdomainErrorMessage(errorMessage);
        monitoringExceptionHelper(err);
      });
  };

  const handleSubdomainCreateDistribution = async () => {
    setLoading(true);

    await DomainService.createSubdomainDistribution(subdomainData.id)
      .then((data) => {
        setLoading(false);
        setSubdomainErrorMessage('');
        handleSubdomainStepChange(data);
        handleSubdomainData(data);
      })
      .catch((err) => {
        setLoading(false);
        // Don't show error message from backend, instead show step SUBDOMAIN_STEPS.CONFIRM_SSL_CERTIFICATE
        setSubdomainStep(SUBDOMAIN_STEPS.CONFIRM_SSL_CERTIFICATE);
        monitoringExceptionHelper(err);
      });
  };

  useEffect(() => {
    async function getDomains() {
      setLoading(true);
      try {
        const [domainApiData, subdomainApiData] = await Promise.all([
          DomainService.getDomain(),
          DomainService.getSubdomain(),
        ]);
        handleDomainStepChange(domainApiData[0]);
        handleSubdomainStepChange(subdomainApiData[0]);
        handleDomainData(domainApiData[0]);
        handleSubdomainData(subdomainApiData[0]);
      } catch (err) {
        monitoringExceptionHelper(err);
      } finally {
        setLoading(false);
      }
    }
    getDomains();
  }, []);

  const [domainDeleteModalProps, , domainDeleteModal] = useModal({
    handleSubmit: handleDomainDelete,
  });
  const [subdomainDeleteModalProps, , subdomainDeleteModal] = useModal({
    handleSubmit: handleSubdomainDelete,
  });

  const emailFromDomainEnabled = useEmailFromDomainEnabledEntitlement();

  return (
    <PageSizing>
      <Container>
        <Card>
          <Section>
            <TrackingScripts />
          </Section>
        </Card>
        {access.domainAndTracking.remove && emailFromDomainEnabled ? (
          <Cols columns={2} gutter={1}>
            <StyledCard>
              <Section>
                <SubSection title={t('Send Email from Your Domain')}>
                  <DomainSteps
                    values={domainData || domainFormValues}
                    onFieldSet={(field, value) => {
                      setDomainFormValues({
                        ...domainFormValues,
                        [field]: value,
                      });
                      setDomainErrorMessage(null);
                    }}
                    onSubmit={handleDomainSubmit}
                    onRestartVerification={handleRestartDomainVerification}
                    onDeleteDomain={() => domainDeleteModal.show()}
                    step={domainStep}
                    errorMessage={domainErrorMessage}
                  />
                </SubSection>
              </Section>
            </StyledCard>
            <StyledCard>
              <PreReleaseFeature>
                <Section>
                  <SubSection title={t('Host Content on Custom Subdomain')}>
                    <SubdomainSteps
                      values={subdomainData || subdomainFormValues}
                      onFieldSet={(field, value) => {
                        setSubdomainFormValues({
                          ...subdomainFormValues,
                          [field]: value,
                        });
                        setSubdomainErrorMessage(null);
                      }}
                      domainName={
                        subdomainData
                          ? domainData &&
                            domainData.original &&
                            domainData.original.email_sending_domain.domain
                          : domainFormValues.name
                      }
                      onSubmit={handleSubdomainSubmit}
                      onCreateDistribution={handleSubdomainCreateDistribution}
                      onDeleteDomain={() => subdomainDeleteModal.show()}
                      onChangeStep={(step) => setSubdomainStep(step)}
                      step={subdomainStep}
                      errorMessage={subdomainErrorMessage}
                    />
                  </SubSection>
                </Section>
              </PreReleaseFeature>
            </StyledCard>
          </Cols>
        ) : null}
      </Container>
      <ConfirmDeletionModal {...domainDeleteModalProps}>
        {t('This will permanently delete the Sending Domain.')}
      </ConfirmDeletionModal>
      <ConfirmDeletionModal {...subdomainDeleteModalProps}>
        {t('This will permanently delete the Subdomain.')}
      </ConfirmDeletionModal>
    </PageSizing>
  );
};

export default DomainAndTrackingPage;
