import useModal from '__components/Modals/useModal';
import { toastVariant } from '__components/ToastProvider';
import { useToast } from '__components/ToastProvider';
import { useUploadFile } from '__hooks/uploadFiles/useUploadFile';
import SmartConnectorService from '__services/SmartConnectorService';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SMARTCONNECTORS_S3_SOURCES } from '../constants';
import { useFiles } from '__hooks/uploadFiles/useFiles';
import { ScriptFile } from '../types';
import { getOriginalError } from '__services/AxiosService';
import { AxiosError } from 'axios';
import { RunSmartConnectorModalProps } from '.';

export const useRunSmartConnectorModal = (): {
  runSmartConnectorModalProps: RunSmartConnectorModalProps;
  showRunSmartConnectorModal: (
    id: string,
    isDryRun: boolean,
    canSkipDiffCheck: boolean
  ) => void;
} => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [showToast] = useToast();

  const [smartConnectorId, setSmartConnectorId] = useState<string | null>(null);
  const [isDryRun, setIsDryRun] = useState<boolean>(false);
  const [skipDiffCheck, setSkipDiffCheck] = useState(false);
  const [canSkipDiffCheck, setCanSkipDiffCheck] = useState(false);

  const uploadFile = useUploadFile(
    false,
    null,
    SMARTCONNECTORS_S3_SOURCES.IMPORT
  );

  const [, { addFiles }] = useFiles({
    uploadFile,
  });

  const handleSubmit = async () => {
    let uploadedFile: ScriptFile | null = null;

    if (file instanceof File) {
      const createdFiles = await addFiles([file]);
      if (createdFiles.length) {
        uploadedFile = createdFiles[0];
      }
    }

    await SmartConnectorService.startSmartConnectorFlow(
      smartConnectorId,
      {
        source_file_id: uploadedFile?.id || null,
        is_dry_run: isDryRun,
        disable_diff_check: skipDiffCheck,
      },
      { skipErrorBoundary: true }
    );

    showToast({
      message: `${isDryRun ? t('Dry run started.') : t('File processing started')} ${t("You'll be emailed on completion.")}`,
      variant: toastVariant.SUCCESS,
    });
  };

  const handleError = (err: AxiosError) => {
    const originalError = getOriginalError(err);
    showToast({
      message:
        originalError?.detail ||
        (isDryRun
          ? t('Dry run failed to start.')
          : t('File processing failed to start')),
      variant: toastVariant.FAILURE,
    });
  };

  const handleHide = () => {
    setFile(null);
    setSmartConnectorId(null);
    setIsDryRun(false);
    setSkipDiffCheck(false);
    setCanSkipDiffCheck(false);
  };

  const [modalProps, , { show }] = useModal({
    handleSubmit,
    handleHide,
    handleError,
  });

  const showRunSmartConnectorModal = useCallback(
    (
      id: string,
      isDryRun: boolean = false,
      canSkipDiffCheck: boolean = false
    ) => {
      setSmartConnectorId(id);
      setIsDryRun(isDryRun);
      setCanSkipDiffCheck(canSkipDiffCheck);
      show();
    },
    [show]
  );

  return {
    runSmartConnectorModalProps: {
      ...modalProps,
      file,
      setFile,
      isDryRun,
      skipDiffCheck,
      setSkipDiffCheck,
      canSkipDiffCheck,
    },
    showRunSmartConnectorModal,
  };
};
