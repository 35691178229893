import React from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';

import { SIMPLE_SUB_TYPES } from './../constants';

export const BuilderTargetField = ({ data, fieldOptions, onUpdateState }) => {
  const { t } = useTranslation();
  return (
    <Select
      value={data.targetField}
      options={fieldOptions}
      label={null}
      placeholder={t('Choose Field')}
      data-field-id="builder-target-field"
      onChange={(value) => {
        onUpdateState({
          subType: SIMPLE_SUB_TYPES.setTargetField,
          value,
        });
      }}
    />
  );
};
