import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import MultiSelect from 'components/Inputs/MultiSelect';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import FieldService from 'services/FieldService';
import { useQuery } from 'react-query';
import { CUSTOM_OBJECTS } from 'queries/query-keys';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { getBusinessClientObject } from 'store/authentication/selectors';

const OPTION_KEYS = {
  THIS_OBJECT: 'This Object',
  RELATED_OBJECTS: 'Related Objects',
};

const getDefaultOptions = (model, includeRelatedObjects, t) => {
  const defaultOptions = [
    {
      key: OPTION_KEYS.THIS_OBJECT,
      label: t('This Object'),
      options: [{ value: model.id, label: model.objectName }],
    },
  ];

  if (includeRelatedObjects) {
    defaultOptions.push({
      key: OPTION_KEYS.RELATED_OBJECTS,
      label: t('Related Objects'),
      options: [],
    });
  }

  return defaultOptions;
};

export const useObjectMultiSelect = (
  includeRelatedObjects,
  isCustomObjectPage
) => {
  const { t } = useTranslation();
  const clientObject = useSelector(getBusinessClientObject);
  const access = useSelector((s) => s.authentication.access);

  const params = useParams();
  const { objectId } = params;

  const { data: model = {}, isLoading: loadingRelatedObjectsOptions } =
    useQuery(
      CUSTOM_OBJECTS.MODEL(objectId),
      () =>
        FieldService.getModel({
          id: isCustomObjectPage ? objectId : clientObject.id,
        }),
      {
        enabled: isCustomObjectPage ? Boolean(objectId) : true,
      }
    );

  const {
    relatedObjectsOptions,
    relatedObjectsDictionary,
    isMoreThanOneRelatedOption,
  } = useMemo(() => {
    const contactAccess = access.sections?.contacts_section?.view ?? false;
    const resultOptions = model?.relatedObjects?.reduce(
      (acc, curr) => {
        const objectAccess =
          access.custom_objects?.custom_object_entities ?? {};
        const duplicate = acc[1]?.options?.find(
          (el) => el.value === curr.relatedObject
        );
        const enabled =
          curr.relatedObject === clientObject?.id
            ? contactAccess
            : objectAccess[curr.relatedObject]?.enabled;
        if (
          curr.rollupTimeline &&
          includeRelatedObjects &&
          !duplicate &&
          enabled
        ) {
          acc[1].options.push({
            value: curr.relatedObject,
            label: curr.objectName,
          });
        }

        return acc;
      },
      getDefaultOptions(model, includeRelatedObjects, t)
    );

    resultOptions &&
      resultOptions[1]?.options?.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, {
          sensitivity: 'base',
        })
      );

    // Remove empty related objects group
    const relatedObjectsOptions = (resultOptions || []).filter(
      (group) =>
        group.key !== OPTION_KEYS.RELATED_OBJECTS || group.options.length > 0
    );

    // show when finished loading an if more than one related object
    const isMoreThanOneRelatedOption =
      !loadingRelatedObjectsOptions && relatedObjectsOptions.length > 1;

    const relatedObjectsDictionary = relatedObjectsOptions
      .flatMap(({ options }) => options)
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr.value]: curr,
        }),
        {}
      );

    return {
      relatedObjectsOptions,
      relatedObjectsDictionary,
      isMoreThanOneRelatedOption,
    };
  }, [
    model,
    includeRelatedObjects,
    access,
    clientObject,
    loadingRelatedObjectsOptions,
    t,
  ]);

  return {
    relatedObjectsOptions,
    relatedObjectsDictionary,
    model,
    loadingRelatedObjectsOptions,
    isMoreThanOneRelatedOption,
  };
};

export const ObjectMultiSelect = ({
  onChange,
  isCustomObjectPage,
  objectsCount,
  customObjectIds,
  trigger,
  isShowing,
  setIsShowing,
  includeRelatedObjects = true,
  blockId,
  enableClearLocalState = false,
  relatedObjectsOptions,
  relatedObjectsDictionary,
  loadingRelatedObjectsOptions,
  ...others
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState([]);

  const [isUpdate, setIsUpdate] = useState(false);

  // we need to track the objects count to clear the selected objects when the count is zero by the clear all button
  const trackObjectsCount = useRef(objectsCount);

  if (trackObjectsCount.current !== objectsCount) {
    trackObjectsCount.current = objectsCount;

    if (enableClearLocalState && objectsCount === 0 && value.length > 0) {
      setValue([]);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback((val) => {
    setValue(val);
    setIsUpdate(true);
  }, []);

  const handleApply = useCallback(() => {
    onChange({
      customObjectIds: value.map((el) => el.value).join(),
      objectsCount: value.length,
    });
    setIsUpdate(false);
    setIsShowing(false);
  }, [value, onChange, setIsShowing]);

  const handleClear = useCallback(() => {
    setValue(EMPTY_ARRAY);
  }, []);

  const handleHide = useCallback(() => {
    setIsShowing(false);
    if (isUpdate) {
      handleApply();
    }
  }, [handleApply, isUpdate, setIsShowing]);

  useEffect(() => {
    if (customObjectIds && !loadingRelatedObjectsOptions) {
      const selectedObjects = customObjectIds.split(',');
      setValue(selectedObjects.map((id) => relatedObjectsDictionary[id]));
    }
  }, [
    customObjectIds,
    loadingRelatedObjectsOptions,
    relatedObjectsDictionary,
    setValue,
    blockId,
  ]);

  return (
    <>
      <SelectOverlay
        show={isShowing}
        target={trigger.current}
        onHide={handleHide}
        placement="bottom-end"
        popperConfig={{}}
      >
        <MultiSelect
          value={value}
          onChange={handleChange}
          placeholder={t('Choose Objects')}
          menuTopButton={null}
          menuLeftButton={<ClearSelectButton onClick={handleClear} />}
          menuRightButton={<ApplySelectButton onClick={handleApply} />}
          endAdornment={<IconAdornment icon="search" />}
          loadItems={loadingRelatedObjectsOptions}
          options={relatedObjectsOptions}
          {...others}
        />
      </SelectOverlay>
    </>
  );
};
