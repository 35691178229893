// this file contains an unstyled text ellipsis with tooltip component

import { forwardRef } from 'react';

import styled from '@emotion/styled';
import { useTruncationTooltip } from '../Kizen/Tooltip';

const NO_VALUE = '—';

export const TextEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
`;

TextEllipsis.displayName = 'TextEllipsis';

export const UnstyledTextEllipseWithTooltip = forwardRef(
  ({ children, tooltipLabel, popperConfig, ...others }, forwardedRef) => {
    const [tooltipProps, tooltip] = useTruncationTooltip({
      tooltipLabel,
      popperConfig,
    });
    children = children || children === 0 ? children : NO_VALUE;
    return (
      <>
        {tooltip}
        <TextEllipsis {...tooltipProps} {...others} ref={forwardedRef}>
          {children}
        </TextEllipsis>
      </>
    );
  }
);

UnstyledTextEllipseWithTooltip.displayName = `UnstyledTextEllipseWithTooltip`;
