import { range } from 'utility/iterables';

export const widthOptions = [
  '10',
  '20',
  '25',
  '30',
  '40',
  '50',
  '60',
  '70',
  '75',
  '80',
  '90',
  '100',
].map((x) => ({ label: `${x}%`, value: x }));

const upperCaseFirstChar = (x) => `${x[0].toUpperCase()}${x.slice(1)}`;

export const alignmentOptions = ['left', 'center', 'right'].map((x) => ({
  label: upperCaseFirstChar(x),
  value: x,
}));
export const sizeOptions = [...range(1, 13), 14, 16].map((x) => ({
  label: `${x}px`,
  value: x.toString(),
}));
export const styleOptions = (t) => [
  {
    label: t('Solid'),
    value: 'solid',
  },
  {
    label: t('Dashed'),
    value: 'dashed',
  },
  {
    label: t('Dotted'),
    value: 'dotted',
  },
];
export const ICON_HEIGHT = '12.25px';
export const fontSizeOptionXform = (v) => ({ value: `${v}px`, label: v });
export const fontSizeValueToOption = (value) => ({
  value,
  label: value?.split('px')[0] ?? '',
});
export const fontSizes = [
  8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
].map(fontSizeOptionXform);
