import { useEffect, useState } from 'react';

// multiple filter sets
export const useFilterErrors = (duration = 3000) => {
  const [errors, setErrors] = useState<
    Record<string, ({ [k: string]: string } | null)[]>
  >({});

  useEffect(() => {
    let id: any;
    if (Object.keys(errors).length) {
      id = setTimeout(() => setErrors({}), duration);
    }
    return () => clearTimeout(id);
  }, [errors, duration]);

  return [errors, setErrors] as const;
};

// single filter set
export const useFilterSetErrors = (duration = 3000) => {
  const [errors, setErrors] = useState<(Record<string, string> | null)[]>([]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setTimeout(() => setErrors([]), duration);
    }
  }, [errors, duration]);

  return [errors, setErrors] as const;
};
