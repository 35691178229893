import React, { useState, useEffect, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${grayScale.medium};
  &:hover {
    cursor: pointer;
  }

  ${({ active }) =>
    active &&
    css`
      background: ${grayScale.dark};
    `}
`;

const ScrollIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin-right: ${gutters.spacing(2, 2)}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const getScrollData = (element) => {
  const windowScroll = element.scrollLeft;
  const totalWidth = element.scrollWidth - element.clientWidth;
  return [windowScroll, totalWidth];
};

const DotsScrollbar = ({ amount, target }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const scrollListener = useCallback(() => {
    if (!target.current) {
      return;
    }

    const [windowScroll, totalWidth] = getScrollData(target.current);

    if (windowScroll === 0) {
      setScrollProgress(0);
    } else if (windowScroll > totalWidth) {
      setScrollProgress(100);
    } else {
      setScrollProgress((windowScroll / totalWidth) * 100);
    }
  }, [target]);

  useEffect(() => {
    const node = target.current;
    node.addEventListener('scroll', scrollListener);
    return () => node.removeEventListener('scroll', scrollListener);
  }, [target, scrollListener]);

  const handleClickDot = (order) => {
    const [, totalWidth] = getScrollData(target.current);
    const sizeForEachDot = totalWidth / amount;

    target.current.scroll({
      left: sizeForEachDot * order + sizeForEachDot / 2,
      behavior: 'smooth',
    });
  };

  const renderDots = () => {
    const selectedDotValue = (scrollProgress * amount) / 100;
    return [...Array(amount).keys()].map((index) => (
      <Dot
        key={index}
        active={selectedDotValue >= index && selectedDotValue <= index + 1}
        onClick={() => handleClickDot(index)}
      />
    ));
  };

  return <ScrollIndicatorContainer>{renderDots()}</ScrollIndicatorContainer>;
};

DotsScrollbar.propTypes = {
  amount: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
};

export default DotsScrollbar;
