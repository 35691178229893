import { isContact } from 'utility/fieldHelpers';

const getHref = (relatedObject) => {
  return isContact(relatedObject)
    ? '/clients'
    : `/custom-objects/${relatedObject.id}`;
};

const anchorProps = {
  type: 'anchor',
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const getCustomObjectLinkProps = (relatedObject) => {
  return relatedObject?.entityAccess
    ? {
        ...anchorProps,
        href: getHref(relatedObject),
      }
    : {};
};

export const getQueryFilterLinkProps = ({ id, relatedObject, type }) => {
  return relatedObject?.entityAccess
    ? {
        ...anchorProps,
        href: `${getHref(relatedObject)}?${new URLSearchParams({
          [type]: JSON.stringify({
            form_id: id,
            type: type === 'form' ? 'forms_v2' : 'surveys',
            subtype: type,
            payload: {
              submission: 'null',
              submitted_condition: 'submitted_any_time',
            },
          }),
        })}`,
      }
    : {};
};
