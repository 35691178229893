import styled from '@emotion/styled';
import KizenTypography from 'app/kizentypo';

export const DashboardWarningText = styled(KizenTypography)`
  max-width: 500px;
  text-align: center;
  padding-bottom: 0;
`;

export const DashboardErrorCodeText = styled(KizenTypography)`
  padding-top: 19px;
`;
