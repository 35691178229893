import { faUserGroup } from '@fortawesome/pro-light-svg-icons/faUserGroup';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import {
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';

export const SEMANTIC_CATEGORY: SemanticCategory = 'user';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  '': faUser,
  group: faUserGroup,
  circle: faUserCircle,
};

export const labels: IconLabel = {
  '': {
    title: (t) => '',
    description: (t) => t('User'),
  },
  group: {
    title: (t) => '',
    description: (t) => t('Group'),
  },
  circle: {
    title: (t) => '',
    description: (t) => t('Circle'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};
