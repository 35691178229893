import {
  AREA_RESPONSES,
  DISPLAY_VALUE_RESPONSES,
  REPORT_TYPES,
} from 'components/Wizards/Dashlet/types';
import {
  AREA_RESPONSES as RTDV_AREA_RESPONSES,
  SALES_REPORT_TYPES,
  VALUE_METRICS,
} from 'components/Wizards/RTDV/types';

export const isChartValid = ({
  reportType,
  field,
  value,
  display,
  valueBreakdownError,
  columns,
  salesReportType,
  datapointFrequency,
  customEndDate,
  endDate,
  metric,
  metricField,
  pivotTableRows,
  pivotTableColumns,
  columnValueBreakdownError,
  rowValueBreakdownError,
  subRowValueBreakdownError,
  rowCustomLabelsError,
  deletedColumn,
  deletedRows,
  deletedMetricField,
}) => {
  if (reportType?.value === RTDV_AREA_RESPONSES.PIVOT_TABLE) {
    const isMetricFieldValid =
      metric?.value === VALUE_METRICS.COUNT ? true : Boolean(metricField);
    const validPivotBreakdown =
      !columnValueBreakdownError &&
      !rowValueBreakdownError &&
      !subRowValueBreakdownError;
    const hasDeletedFields = deletedColumn || deletedRows || deletedMetricField;
    return (
      Boolean(reportType?.value) &&
      pivotTableRows?.length > 0 &&
      pivotTableColumns?.length === 1 &&
      Boolean(metric) &&
      isMetricFieldValid &&
      validPivotBreakdown &&
      !rowCustomLabelsError &&
      !hasDeletedFields
    );
  }
  if (reportType?.value === RTDV_AREA_RESPONSES.TABLE) {
    return columns?.length > 0 && !columns?.some((c) => c.label?.trim() === '');
  }
  if (reportType?.value === RTDV_AREA_RESPONSES.SALES) {
    if (
      salesReportType?.value ===
      SALES_REPORT_TYPES.PROJECTED_RECORDS_WON_OVER_TIME
    ) {
      return (
        Boolean(reportType?.value) &&
        Boolean(salesReportType?.value) &&
        Boolean(value) &&
        Boolean(datapointFrequency) &&
        (customEndDate ? Boolean(endDate) : true)
      );
    }
  }

  const metricValid =
    !metric ||
    (metric && (metric === VALUE_METRICS.COUNT || Boolean(metricField)));

  return (
    Boolean(reportType?.value) &&
    Boolean(field?.value) &&
    Boolean(value?.value) &&
    Boolean(display) &&
    metricValid &&
    !valueBreakdownError
  );
};

export const isDashletValid = (state) => {
  if (state.area === AREA_RESPONSES.PIPELINES) {
    if (state.reportType?.value === REPORT_TYPES.OPPORTUNITY_CONVERSION) {
      return (
        Boolean(state.area) &&
        Boolean(state.pipeline?.value) &&
        Boolean(state.reportType?.value) &&
        Boolean(state.value?.value) &&
        Boolean(state.valueToDisplay?.value)
      );
    }
    if (state.reportType?.value === REPORT_TYPES.VALUE_OVER_TIME) {
      return (
        Boolean(state.area) &&
        Boolean(state.pipeline?.value) &&
        Boolean(state.reportType?.value)
      );
    }
    if (state.reportType?.value === REPORT_TYPES.LEADERBOARD_WITH_PROJECTIONS) {
      return (
        Boolean(state.area) &&
        Boolean(state.pipeline?.value) &&
        Boolean(state.reportType?.value) &&
        Boolean(state.value?.value)
      );
    }
    if (state.reportType?.value === REPORT_TYPES.STAGES_OVER_TIME) {
      const mainValidation =
        Boolean(state.area) &&
        Boolean(state.reportType?.value) &&
        Boolean(state.value?.value) &&
        Boolean(state.levelOfDetail?.value) &&
        Boolean(state.datapointFrequency?.value);

      if (state.value?.value === DISPLAY_VALUE_RESPONSES.VALUE) {
        return mainValidation && Boolean(state.valueToDisplay?.value);
      }

      return mainValidation;
    }
    if (state.isSummaryDisplay) {
      return (
        Boolean(state.area) &&
        Boolean(state.pipeline?.value) &&
        Boolean(state.reportType?.value) &&
        Boolean(state.value?.value) &&
        Boolean(state.display)
      );
    } else if (state.isTrendDisplay) {
      return (
        Boolean(state.area) &&
        Boolean(state.pipeline?.value) &&
        Boolean(state.reportType?.value) &&
        Boolean(state.value?.value) &&
        Boolean(state.display) &&
        Boolean(state.datapointFrequency?.value)
      );
    }
  } else if (state.area === AREA_RESPONSES.ACTIVITIES) {
    if (state.reportType?.value === REPORT_TYPES.SCHEDULED_ACTIVITIES) {
      return (
        Boolean(state.area) &&
        Boolean(state.reportType?.value) &&
        !state.hasDeletedActivities
      );
    }
    if (
      state.reportType?.value === REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS
    ) {
      return (
        Boolean(state.area) &&
        Boolean(state.reportType?.value) &&
        Boolean(state.activity?.value) &&
        Boolean(state.display)
      );
    }
  } else if (state.area === AREA_RESPONSES.EMAILS) {
    if (state.reportType?.value === REPORT_TYPES.INTERACTION_STATS) {
      return Boolean(state.area) && Boolean(state.reportType?.value);
    }
    return (
      Boolean(state.area) &&
      Boolean(state.reportType?.value) &&
      Boolean(state.display)
    );
  } else if (state.area === AREA_RESPONSES.MARKETING) {
    if (
      state.reportType?.value === REPORT_TYPES.LEADS_ADDED_BY_SOURCE ||
      state.reportType?.value === REPORT_TYPES.LEADS_ADDED
    ) {
      return (
        Boolean(state.area) &&
        Boolean(state.reportType) &&
        Boolean(state.valueToDisplay) &&
        state.objectsWithLeads?.length > 0 &&
        Boolean(state.display) &&
        !state.isLoadingBlockingSave
      );
    }
    if (state.reportType?.value === REPORT_TYPES.LEAD_SOURCE_BREAKDOWN) {
      return (
        Boolean(state.area) &&
        Boolean(state.reportType) &&
        state.objectsWithLeads?.length > 0 &&
        Boolean(state.display) &&
        Boolean(state.datapointFrequency?.value)
      );
    }
  }

  return false;
};
