import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TextInput from 'components/Inputs/TextInput';

export const CustomName = React.memo(({ errorMessage, showError, ...rest }) => {
  const { t } = useTranslation();

  return (
    <TextInput
      variant="underline"
      placeholder={t('Enter Custom Name')}
      label={t('Custom Name')}
      validate={{ message: errorMessage, showMessage: showError }}
      {...rest}
    />
  );
});

CustomName.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  showError: PropTypes.bool,
};

CustomName.defaultProps = {
  errorMessage: '',
  showError: false,
};
