import { TEAM_MEMBERS } from 'queries/query-keys';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { FIELD_TYPES } from 'utility/constants';
import CustomObjectService from 'services/CustomObjectsService';

const useTeamMemberNameRepair = ({ fieldData, values = [] }) => {
  const fieldType = fieldData?.fieldType;
  const needsToFetchTeamMembers = fieldType === FIELD_TYPES.TeamSelector.type;

  const teamMemberValues = useMemo(() => {
    // We need to fetch team members if any of the values
    // are strings, because that means they're IDs and we need to
    // fetch the name
    return needsToFetchTeamMembers
      ? values.filter((c) => {
          return typeof c === 'string';
        }) ?? []
      : [];
  }, [needsToFetchTeamMembers, values]);

  const res = useQuery({
    queryKey: TEAM_MEMBERS.GET(teamMemberValues),
    queryFn: () => {
      return CustomObjectService.getDisplayNames({
        employee_ids: teamMemberValues,
      });
    },
    enabled: needsToFetchTeamMembers,
  });

  const teamMemberBatchLoading = needsToFetchTeamMembers && res.isLoading;

  const teamMembersWithNames = useMemo(() => {
    if (teamMemberValues.length) {
      // Map over the data results from the queries to rebuild the team member
      // metadata
      return res?.data?.results?.employees ?? [];
    }

    // Map over the existing options that already have metadata, if there were
    // no queries needed
    return values
      .filter((v) => typeof v !== 'string')
      .map((v) => {
        if (typeof v !== 'string') {
          return {
            id: v.value,
            displayName: v.label,
          };
        }
        return null;
      });
  }, [res, values, teamMemberValues]);

  return {
    loading: teamMemberBatchLoading,
    valuesWithNames: teamMembersWithNames,
    needsToFetchTeamMembers,
    fieldType,
  };
};

export default useTeamMemberNameRepair;
