import useReactQueryTypeahead from 'components/Inputs/Select/useReactQueryTypeahead';
import { LEAD_SOURCES } from 'queries/query-keys';
import { useMemo } from 'react';
import LeadSourceService from 'services/LeadSourceServiceV2';

const useLeadSourceOptions = (options = {}) => {
  const { enabled = true } = options;
  const { results: leadSourcesResults, selectProps: leadSourcesSelectProps } =
    useReactQueryTypeahead(
      ({ search }) => LeadSourceService.combinedSearch(search),
      LEAD_SOURCES.COMBINED_SEARCH,
      {
        enabled,
      }
    );

  const leadSourcesOptions = useMemo(() => {
    const buckets = leadSourcesResults.reduce((acc, curr) => {
      const res = { ...acc };

      if (!res[curr.group_key]) {
        res[curr.group_key] = {
          name: curr.group_name,
          key: curr.group_key,
          options: [],
        };
      }

      res[curr.group_key].options.push({
        label: curr.label,
        value: curr.value,
        group: curr.group_key,
      });

      return res;
    }, {});

    return ['default', 'utm', 'custom']
      .map((key) => {
        const group = buckets[key];
        if (!group) {
          return undefined;
        }
        return {
          label: group.name,
          options: group.options.sort((a, b) => a.label.localeCompare(b.label)),
        };
      })
      .filter(Boolean);
  }, [leadSourcesResults]);

  return {
    ...leadSourcesSelectProps,
    options: leadSourcesOptions,
  };
};

export default useLeadSourceOptions;
