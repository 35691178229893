import { useEffect, useState, forwardRef, useCallback, useMemo } from 'react';
import TextInput from './index';
import { getMaskedValue } from '../helpers';

const MaskedText = forwardRef(
  ({ value, displayMaskedValue, onChange, onFocus, onBlur, ...other }, ref) => {
    const [displayValue, setDisplayValue] = useState(
      displayMaskedValue ? getMaskedValue(value) : value
    );
    const [actualValue, setActualValue] = useState(value);
    const [displayToolTip, setDisplayToolTip] = useState(true);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      if (!isFocused) {
        setActualValue(value);
        setDisplayValue(displayMaskedValue ? getMaskedValue(value) : value);
      }
    }, [value, isFocused, displayMaskedValue]);

    const handleChange = useCallback(
      (eventValue, event) => {
        setActualValue(eventValue);
        setDisplayValue(eventValue);
        if (onChange) {
          onChange(eventValue, event);
        }
      },
      [onChange]
    );

    const handleBlur = useCallback(
      (e) => {
        setIsFocused(false);
        const maskedValue = displayMaskedValue
          ? getMaskedValue(actualValue)
          : actualValue;
        setDisplayValue(maskedValue);
        setDisplayToolTip(true);
        onBlur(e);
      },
      [displayMaskedValue, actualValue, onBlur]
    );

    const handleFocus = useCallback(
      (e) => {
        setIsFocused(true);
        setDisplayValue(actualValue);
        setDisplayToolTip(false);
        onFocus(e);
      },
      [actualValue, onFocus]
    );

    const tooltipLabel = useMemo(
      () => (displayMaskedValue && displayToolTip ? actualValue : undefined),
      [displayMaskedValue, displayToolTip, actualValue]
    );

    return (
      <TextInput
        ref={ref}
        {...other}
        onClick={(ev) => {
          if (other.onClick) other.onClick(ev);
        }}
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        tooltipLabel={tooltipLabel}
        tooltipMaxWdith={400}
      />
    );
  }
);

export default MaskedText;
