import { useRef } from 'react';
import * as PropTypes from 'prop-types';

import { TRow } from 'components/Kizen/Table';
import { TableRow } from 'components/Tables/Query/TableRow';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import { StyledTableScrollContainer } from './tableStyles';
import BasicTable from 'components/Tables/Basic';

const ITEMS_BUFFER = 20;
const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 50;

export default function ProfileInfiniteTable({
  columns,
  data: dataForTheTable,
  messageHeadData = {},
  messagesSort,
  setMessagesSort,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isFetching,
  ...others
}) {
  const roleTableWrapperRef = useRef();

  const tableScroll = useInfinityScroll({
    wrapperElement: roleTableWrapperRef.current,
    data: dataForTheTable,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    isFetchingNextPage: isFetchingNextPage,
    defaultHeight: ROW_HEIGHT,
    itemsBuffer: ITEMS_BUFFER,
    shouldFetchOnMount: true,
    headerHeight: HEADER_HEIGHT,
    component: 'table',
  });
  const { visibleItems, handleItemMounted } = tableScroll;

  return (
    <StyledTableScrollContainer
      className="StyledTableScrollContainer"
      ref={roleTableWrapperRef}
      bottom
      refresh={[visibleItems]}
      isTable
    >
      <BasicTable
        className="BasicTable"
        stickyHeader
        staleData={!isFetchingNextPage && isFetching} // don't fade when fetch next page
        head={
          <TRow head columns={columns} {...others} data={messageHeadData} />
        }
      >
        {visibleItems.map(({ item }, index) => (
          <TableRow
            key={item.id}
            columns={columns}
            onMount={handleItemMounted}
            item={item}
            index={index}
          />
        ))}
      </BasicTable>
    </StyledTableScrollContainer>
  );
}

ProfileInfiniteTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
};
ProfileInfiniteTable.defaultProps = {
  data: undefined,
};
