import { v4 as uuidv4 } from 'uuid';
import {
  DASHBOARD_BREAKPOINT_NAMES,
  DASHBOARD_BREAKPOINT_VALUES,
} from './types';
import TeamMemberService from 'services/TeamMemberService';
import { range } from 'components/Kizen/DateRangePicker';
import { getHash } from 'utility/encode';

export const parseValue = (minString) => {
  const thoudsandSeparator = (1000).toLocaleString().substring(1, 2);
  // remove all thousands separators, then if commas still remain they are decimal separators
  // and need to be replaced by . so parseFloat works correctly
  const strippedValue = String(minString)
    .replace(new RegExp(thoudsandSeparator, 'g'), '')
    .replace(/,/g, '.');
  return parseFloat(strippedValue);
};

export const displayValue = (value) => {
  return parseValue(value).toLocaleString(undefined, {
    maximumFractionDigits: 4,
  });
};

export const valueExists = (value) => {
  if (typeof value === 'string') {
    return Boolean(value);
  }
  if (typeof value === 'number') {
    return Boolean(String(value));
  }
  return false;
};

export const getBreakpointForWidth = (width) => {
  return width > DASHBOARD_BREAKPOINT_VALUES.XL
    ? DASHBOARD_BREAKPOINT_NAMES.XL
    : width > DASHBOARD_BREAKPOINT_VALUES.LG
      ? DASHBOARD_BREAKPOINT_NAMES.LG
      : width > DASHBOARD_BREAKPOINT_VALUES.MD
        ? DASHBOARD_BREAKPOINT_NAMES.MD
        : width > DASHBOARD_BREAKPOINT_VALUES.SM
          ? DASHBOARD_BREAKPOINT_NAMES.SM
          : width > DASHBOARD_BREAKPOINT_VALUES.XS
            ? DASHBOARD_BREAKPOINT_NAMES.XS
            : DASHBOARD_BREAKPOINT_NAMES.XXS;
};

export const getDashletKey = (
  dashlet,
  breakpoint,
  dateFilter,
  filtersForCharts
) => {
  // A unique key to represent exactly what is being queried for. The filters are hashed
  // to avoid this key being too long.
  const key = `${dashlet.id}:${breakpoint}:${dashlet.layout.x}:${
    dashlet.layout.y
  }:${dashlet.layout.w}:${dashlet.layout.h}:${dashlet.layout.maxH}:${
    dashlet.layout.maxW
  }:${dashlet.layout.minH}:${dashlet.layout.minW}:${dateFilter?.start}:${
    dateFilter?.end
  }:${getHash(JSON.stringify(filtersForCharts))}`;

  return key;
};

export const getDashletQA = (dashlet, rowHeightOverrides) => {
  return {
    'data-qa-dashlet-entity-type': dashlet?.config?.entityType,
    'data-qa-dashlet-report-type': dashlet?.config?.reportType,
    'data-qa-dashlet-chart-type': dashlet?.config?.chartType,
    'data-qa-dashlet-frequency': dashlet?.config?.frequency,
    'data-qa-dashlet-historical': dashlet?.config?.historical,
    'data-qa-dashlet-inverse': dashlet?.config?.inverse,
    'data-qa-dashlet-percentage': dashlet?.config?.percentageChangeOverTime,
    'data-qa-dashlet-included-records': dashlet?.config?.recordsToInclude,
    'data-qa-dashlet-name': dashlet?.name,
    'data-qa-dashlet-stages': dashlet?.config?.stages?.join?.(',') ?? '',
    'data-qa-level-of-detail': dashlet?.config?.pipelineLevelOfDetail,
    'data-qa-dashlet-position-x': dashlet?.layout?.x,
    'data-qa-dashlet-position-y': dashlet?.layout?.y,
    'data-qa-dashlet-position-w': dashlet?.layout?.w,
    'data-qa-dashlet-position-h':
      rowHeightOverrides?.[dashlet?.id] ?? dashlet?.layout?.h,
    'data-qa-dashlet-position-i': dashlet?.layout?.i,
    'data-qa-dashlet-position-max-w': dashlet?.layout?.maxW,
    'data-qa-dashlet-position-max-h': dashlet?.layout?.maxH,
    'data-qa-dashlet-position-min-w': dashlet?.layout?.minW,
    'data-qa-dashlet-position-min-h': dashlet?.layout?.minH,
    'data-qa-dashlet-generated-id': dashlet?.id,
  };
};

export const getDashletGridLayout = (
  dashlet,
  rowHeightOverrides = {},
  columnCount
) => {
  let targetHeight = dashlet.layout.h ?? 2;
  if (
    rowHeightOverrides[dashlet.id] &&
    targetHeight < rowHeightOverrides[dashlet.id]
  ) {
    targetHeight = rowHeightOverrides[dashlet.id];
  }

  let targetWidth = dashlet.layout.w ?? 2;
  if (dashlet.config?.chartType === 'html' && columnCount < 12) {
    targetWidth = columnCount;
  }

  return {
    ...dashlet.layout,
    x: dashlet.layout.x ?? 0,
    y: dashlet.layout.y ?? 0,
    w: targetWidth,
    h: targetHeight,
    i: dashlet.layout.i ?? uuidv4(),
  };
};

export const defaultPosition = { x: 0, y: 0, w: 2, h: 2, static: true };

export const getSortedDashboards = (dashboards) =>
  [...dashboards].sort((a, b) => a.order - b.order);

// We need to repair the filters on-the-fly if they are in the old format.
// The new format was changed with https://kizen.atlassian.net/browse/KZN-6810.
// This will run once per user after the new filtering logic rolls out.
export const fetchAndRepairDashboardConfig = async (pageConfigKey) => {
  const config = await TeamMemberService.getPageConfig(pageConfigKey);

  const dashboardKeys = Object.keys(config.filtersByDashboard ?? {});

  const needsTeamRepair = dashboardKeys.some((key) => {
    const obj = config.filtersByDashboard[key];
    const teamFilters = obj?.team ?? [];

    return teamFilters.some((f) => typeof f === 'string');
  });

  const needsRoleRepair = dashboardKeys.some((key) => {
    const obj = config.filtersByDashboard[key];
    const roleFilters = obj?.role ?? [];

    return roleFilters.some((f) => typeof f === 'string');
  });

  if (needsTeamRepair || needsRoleRepair) {
    const result = { ...config };

    if (needsTeamRepair) {
      const members = await TeamMemberService.getTeamMemberOptionsList();
      dashboardKeys.forEach((key) => {
        const object = result.filtersByDashboard[key];
        if (object) {
          object.team = (object.team ?? [])
            .map((value) => {
              if (typeof value === 'string') {
                return members.find((m) => m.value === value);
              }
              return value;
            })
            .filter(Boolean);
        }
      });
    }

    if (needsRoleRepair) {
      const roles = await TeamMemberService.getRoles();
      dashboardKeys.forEach((key) => {
        const object = result.filtersByDashboard[key];
        if (object) {
          object.role = (object.role ?? [])
            .map((value) => {
              if (typeof value === 'string') {
                const found = roles.find((r) => r.id === value);
                return {
                  value: found?.id,
                  label: found?.name,
                };
              }
              return value;
            })
            .filter(Boolean);
        }
      });
    }

    return result;
  }
  return config;
};

export const formatDate = (date) => {
  // If the date is already formatted correctly, just return it
  if (new RegExp(/^\d{4}-\d{2}-\d{2}$/).test(date)) {
    return date;
  }
  const dateObject = new Date(date);
  const dateString = dateObject.toLocaleDateString('en-US', {
    day: '2-digit',
    year: 'numeric',
    month: '2-digit',
  });
  const [M, D, Y] = dateString.split('/');

  return `${Y}-${M}-${D}`;
};

export const DEFAULT_DATE_RANGE = range.thisMonth.value;
export const DEFAULT_DATE_FILTERS = {
  start: formatDate(DEFAULT_DATE_RANGE[0]),
  end: formatDate(DEFAULT_DATE_RANGE[1]),
  selectedIndex: -1,
};

export const DEFAULT_LABEL_INDICATOR = '[default]';

export const getFilterValues = (original) => original?.map?.((o) => o.value);

export const indexMapping = [
  'today',
  'yesterday',
  'thisMonth',
  'last7Days',
  'last30Days',
  'thisYear',
  'allTime',
];
