import { ISubscribable } from '@thi.ng/rstream';
import { map } from '@thi.ng/transducers';
import { useEffect, useState } from 'react';

/**
 * Creates a subscription to a {@link https://github.com/thi-ng/umbrella/tree/develop/packages/rstream | @thi.ng/rstream}
 * Stream so the component re-renders whenever a new value is emitted from the Stream.
 *
 * @remarks The optional mapping function will be registered as a transducer on the new subscription
 * (form 3 described here: https://github.com/thi-ng/umbrella/issues/246#issuecomment-683431781). This
 * means the mapping will be applied prior to calling next. Practially, this means that if your mapping
 * function does not produce a different result from the previous your component will not rerender.
 *
 * If more flexibility beyond `map` is ever desired the hook could be updated the transducer itself, which
 * would allow for transducer composition or collecting/reducing all stream values via the scan operator.
 *
 * @param stream - the stream to subscribe to
 * @param mapper - optional mapping function
 */
export const useReactive = <T, M = T>(
  stream: ISubscribable<T>,
  mapper?: (val: T) => M
) => {
  const [current, setCurrent] = useState(() => {
    if (!stream) return;
    const init = stream.deref();
    return mapper && init !== undefined ? mapper(init) : init;
  });

  useEffect(() => {
    if (stream) {
      const opts = mapper ? { xform: map(mapper) } : undefined;
      const sub = stream.subscribe({ next: (val: M) => setCurrent(val) }, opts);
      return () => {
        sub.unsubscribe();
      };
    }
  }, [stream, mapper, setCurrent]);

  return current;
};
