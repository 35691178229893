import { useCallback, useState } from 'react';

export const useModalClickedState = (onConfirm) => {
  const [clicked, setClicked] = useState(false);

  const handleConfirm = useCallback(
    (...args) => {
      setClicked(true);
      onConfirm?.(...args);
    },
    [onConfirm]
  );

  return {
    clicked,
    handleClickConfirm: handleConfirm,
  };
};
