import { forwardRef, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import useField from 'hooks/useField';
import { colorsButton } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import FileLibraryModal from 'components/Inputs/FileInput/Modal';
import ReadonlyFileLibraryModal from './Modal/ReadonlyModal';
import useModal from 'components/Modals/useModal';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { resetButtonCss } from 'app/spacing';
import { cleanFiles } from 'components/Inputs/helpers';
import { MaybeInputControl } from '../InputControl';
import { UnderlineInputLayout } from '../TextInput/Underline';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
`;

const FileInputWrapper = styled.button`
  ${resetButtonCss}
  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}

  display: flex;
  flex: 1;
  width: 100%;

  > span {
    height: 100%;
  }

  > span:first-of-type {
    display: flex;
    flex: 1;
    align-content: center;
  }

  ${({ showVisibleIcon }) =>
    showVisibleIcon &&
    css`
      > span:last-of-type {
        display: flex;
        flex: 0 0 20px;

        align-content: center;
        justify-content: flex-end;
      }
    `}
`;

const FilledPlaceholder = ({ quantity = 0 }) => {
  const { t } = useTranslation();
  return (
    <StyledPlaceholder>
      <KizenTypography as="span" color={colorsButton.blue.default}>
        {`${quantity} ${
          quantity !== 1 ? t('Files Selected') : t('File Selected')
        }`}
      </KizenTypography>
    </StyledPlaceholder>
  );
};

const UnderlineFileInput = forwardRef((props, ref) => {
  const {
    fieldId,
    onChange,
    showVisibleIcon,
    fileLibraryOpen,
    fieldType,
    field,
    ...inputControlProps
  } = props;
  const {
    disabled,
    readonly,
    files: filesProp,
    initialFiles,
    viewable,
    source,
    publicFile = false,
  } = props;
  const { assignFieldHandle } = useKeyBoardContext();

  const [files] = useField(() => {
    return cleanFiles(filesProp);
  }, [filesProp]);
  const [focused, setFocused] = useState(false);
  const [modalProps, , modal] = useModal({ initialValue: fileLibraryOpen });
  const disabledProp = disabled && !viewable;
  const buttonRef = useRef(null);
  assignFieldHandle(fieldId, {
    customFocus: () => {
      buttonRef.current?.focus();
      return buttonRef.current;
    },
    disabled: disabledProp,
  });

  return (
    <>
      <MaybeInputControl
        forInput
        variant="underline"
        {...{
          ...inputControlProps,
          error: true,
          disabled: true,
          shrink: true,
        }}
      >
        <UnderlineInputLayout
          disabled={disabledProp}
          viewable={viewable}
          focused={focused}
        >
          <FileInputWrapper
            ref={buttonRef}
            data-qa-file-input="open-button"
            onClick={() => (!disabled || viewable) && modal.show()}
            disabled={disabledProp}
            showVisibleIcon={showVisibleIcon}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          >
            <span>
              <FilledPlaceholder quantity={files.length} />
            </span>
            {showVisibleIcon && (
              <span>
                {(!disabled || viewable) && <IconAdornment icon="visible" />}
              </span>
            )}
          </FileInputWrapper>
        </UnderlineInputLayout>
      </MaybeInputControl>
      {modalProps.show && !readonly && (
        <FileLibraryModal
          show
          files={files}
          initialFiles={initialFiles}
          source={source}
          onChange={onChange}
          modalProps={modalProps}
          modal={modal}
          disabled={disabled}
          viewable={viewable}
          publicFile={publicFile}
          field={field}
          hasUnsavedConfirmation
        />
      )}
      {modalProps.show && readonly && (
        <ReadonlyFileLibraryModal
          files={files}
          onHide={modal.hide}
          viewable={viewable}
          {...modalProps}
        />
      )}
    </>
  );
});

UnderlineFileInput.defaultProps = {
  label: null,
  id: '',
  className: '',
  error: false,
  disabled: false,
  shrink: null,
  viewable: true,
  readonly: false,
  showVisibleIcon: true,
};
export default UnderlineFileInput;
