import { getScheduledTimezoneAdjusted } from 'app/timezone';
import { isCustomFilter } from 'ts-filters/legacy';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import { CALENDAR_VIEW_OPTIONS } from 'components/Calendar/constants';
import { EMPTY_ARRAY, EMPTY_OBJECT, isContact } from 'utility/fieldHelpers';
import { BROADCAST_FORMDATA, defaultBroadcastFormData } from './constants';
import LibraryService from 'services/LibraryService';
import { isString } from 'lodash';

const HOUR_AND_HALF = 1.5 * 3_600_000;

export const adjustDateToNearestFuture = (
  date,
  view,
  shift = HOUR_AND_HALF
) => {
  if (view !== CALENDAR_VIEW_OPTIONS.month) return date;

  const monthDateMs = new Date(date).setHours(12, 0, 0, 0);
  if (monthDateMs > Date.now()) return new Date(monthDateMs);
  return new Date(new Date().setHours(new Date().getHours(), 0, 0, 0) + shift);
};

export const toBusinessTimezone = (date, businessTimezone) =>
  getScheduledTimezoneAdjusted(new Date(date), businessTimezone);

export const toLocalTimezone = (date, businessTimezone) =>
  getScheduledTimezoneAdjusted(new Date(date), businessTimezone, true);

export const validateDate = (date, businessTimezone) =>
  !date ||
  Date.parse(
    businessTimezone ? toLocalTimezone(date, businessTimezone) : date
  ) > Date.now();

export const isTodayDate = (date, businessTimezone) => {
  const todayEnd = new Date();
  todayEnd.setHours(todayEnd.getHours() + 24); // Set to 24 hours from now
  const parsedDate = Date.parse(
    businessTimezone ? toLocalTimezone(date, businessTimezone) : date
  );
  return parsedDate <= todayEnd.getTime();
};

export const getBroadcastName = (type, broadcast, t) => {
  switch (type) {
    case EVENT_TYPES.email.type: {
      const { [BROADCAST_FORMDATA.action]: email, name } = broadcast;
      return (
        name ||
        `${t('Email')}${email ? ` - ${email.name || email.subject}` : ''}`
      );
    }
    case EVENT_TYPES.automation.type: {
      const { [BROADCAST_FORMDATA.action]: action, name } = broadcast;
      return (
        name ||
        `${t('Automation')}${
          action?.automations?.length
            ? ` - ${action.automations
                .slice(0, 3)
                .map(({ label, name }) => name || label)
                .join(', ')}` +
              (action.automations.length > 3
                ? ` ${t('and {{ countEvents }} more', {
                    countEvents: action.automations.length - 3,
                  })}`
                : '')
            : ''
        }`
      );
    }
    default:
      return '';
  }
};

export const getActionForUI = (type, action) => {
  switch (type) {
    case EVENT_TYPES.automation.type: {
      return {
        automations: action?.automations || EMPTY_ARRAY,
        resumePausedAutomations: action?.resumePausedAutomations ?? true,
      };
    }
    default:
      return action;
  }
};

export const getBroadcastFormData = (data) =>
  data
    ? {
        ...defaultBroadcastFormData,
        ...data,
        [BROADCAST_FORMDATA.action]: data[BROADCAST_FORMDATA.type]
          ? getActionForUI(
              data[BROADCAST_FORMDATA.type],
              data[BROADCAST_FORMDATA.action]
            )
          : EMPTY_OBJECT,
        [BROADCAST_FORMDATA.filterGroupsIds]:
          data[BROADCAST_FORMDATA.filterGroupsIds] ||
          (data.filterGroups || []).map(({ id }) => id),
        [BROADCAST_FORMDATA.customObjectId]:
          data[BROADCAST_FORMDATA.customObjectId] || data.customObject?.id,
      }
    : defaultBroadcastFormData;
const gatActionForAPI = (data) => {
  if (!data[BROADCAST_FORMDATA.action]) return {};
  switch (data[BROADCAST_FORMDATA.type]) {
    case EVENT_TYPES.email.type:
      return { id: data[BROADCAST_FORMDATA.action].id };
    case EVENT_TYPES.automation.type:
      return {
        automationIds: (data[BROADCAST_FORMDATA.action].automations || []).map(
          ({ id }) => id
        ),
        resumePausedAutomations:
          data[BROADCAST_FORMDATA.action].resumePausedAutomations,
      };
    default:
      return data[BROADCAST_FORMDATA.action];
  }
};
export const getSmartSendTimeSplitPercentage = (data) => {
  const value =
    data?.[BROADCAST_FORMDATA.action]?.[
      BROADCAST_FORMDATA.smartSendTimeSplitPercentage
    ];
  if (value === undefined) {
    return undefined;
  } else if (value === 0) {
    return 0;
  }
  if (
    !data?.[BROADCAST_FORMDATA.action]?.[
      BROADCAST_FORMDATA.smartSendTimeSplitPercentage
    ]
  ) {
    return undefined;
  }
  return isString(
    data[BROADCAST_FORMDATA.action]?.[
      BROADCAST_FORMDATA.smartSendTimeSplitPercentage
    ]
  )
    ? Math.round(
        parseFloat(
          data[BROADCAST_FORMDATA.action]?.[
            BROADCAST_FORMDATA.smartSendTimeSplitPercentage
          ]
        ) * 100
      )
    : parseInt(
        data[BROADCAST_FORMDATA.action]?.[
          BROADCAST_FORMDATA.smartSendTimeSplitPercentage
        ]
      ) / 100;
};
export const getPayload = (broadcast, filterConfig, t) => {
  return {
    id: broadcast.id,
    [BROADCAST_FORMDATA.date]: broadcast[BROADCAST_FORMDATA.date],
    [BROADCAST_FORMDATA.type]: broadcast[BROADCAST_FORMDATA.type],
    [BROADCAST_FORMDATA.action]: gatActionForAPI(broadcast),
    [BROADCAST_FORMDATA.customObjectId]:
      broadcast[BROADCAST_FORMDATA.customObjectId],
    [BROADCAST_FORMDATA.name]:
      broadcast[BROADCAST_FORMDATA.name] ||
      getBroadcastName(broadcast[BROADCAST_FORMDATA.type], broadcast, t),
    [BROADCAST_FORMDATA.filterType]: broadcast[BROADCAST_FORMDATA.filterType],
    [BROADCAST_FORMDATA.filterConfig]: isCustomFilter(
      broadcast[BROADCAST_FORMDATA.filterType]
    )
      ? filterConfig
      : broadcast[BROADCAST_FORMDATA.filterConfig],
    [BROADCAST_FORMDATA.filterGroupsIds]:
      broadcast[BROADCAST_FORMDATA.filterGroupsIds] || [],
    [BROADCAST_FORMDATA.rescheduleAt]:
      broadcast[BROADCAST_FORMDATA.type] === EVENT_TYPES.email.type
        ? null
        : undefined,
    [BROADCAST_FORMDATA.smartSendTimeSplitPercentage]:
      broadcast[BROADCAST_FORMDATA.type] === EVENT_TYPES.email.type
        ? broadcast[BROADCAST_FORMDATA.action][
            BROADCAST_FORMDATA.smartSendTimeSplitPercentage
          ] || 0
        : undefined,
  };
};

export const getInitialFilterState = (
  customObject,
  type,
  filterConfig,
  groups,
  clientObject
) => {
  return {
    customObject,
    state: {
      details: {
        type,
        filterConfig: filterConfig?.query?.length ? filterConfig : null,
        groups,
      },
    },
    isForCustomObject: !isContact(customObject),
    highlightInvalid: true,
    clientObject,
  };
};

export const getContent = async (type, action) => {
  switch (type) {
    case EVENT_TYPES.email.type: {
      if (!action?.id)
        return {
          actionData: EMPTY_OBJECT,
          actionFormData: EMPTY_OBJECT,
        };
      if (action?.deleted)
        return {
          actionData: action,
          actionFormData: action,
        };
      try {
        const data = await LibraryService.getScheduledBroadcastById({
          id: action.id,
        });
        return { actionData: data, actionFormData: data };
      } catch (err) {
        return { actionData: action, actionFormData: action };
      }
    }
    case EVENT_TYPES.automation.type: {
      return {
        actionData: {
          automations: action.automations || EMPTY_ARRAY,
          resumePausedAutomations: action.resumePausedAutomations ?? true,
        },
        actionFormData: action,
      };
    }
    default: {
      return { actionData: action, actionFormData: action };
    }
  }
};
