import KizenTypography from 'app/kizentypo';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import { AREA_RESPONSES, VALUE_METRICS } from 'components/Wizards/RTDV/types';
import Select from 'components/Inputs/Select';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import useDataConstraint from 'components/Wizards/shared/hooks/useDataConstraint';
import {
  allowedFieldTypes,
  allowedFieldTypesWithOptions,
  entitiesFieldTypes,
} from 'components/Wizards/utils';
import { getFallbackState as valueBreakdownFallbackState } from 'components/Wizards/shared/components/BreakdownAndBucketing/ValueBreakdown';
import { useGroupedFields } from 'components/Wizards/shared/hooks/useGroupedFields';
import { isPercentageChanceToCloseField } from 'checks/fields';

const FieldMetricsArea = ({ data, isLoading, disabled }) => {
  const { t } = useTranslation();
  const { state, setState } = useContext(WizardStateContext.Context);
  const { reportType, field } = state;

  useMetadataKey('objectDetailsLoading', isLoading);

  const filteredFields = useMemo(() => {
    return (
      data?.fields?.filter(
        (f) =>
          allowedFieldTypes.includes(f.fieldType) &&
          // TODO (keegandonley): This needs to be removed once the percentage chance to close field is supported
          !isPercentageChanceToCloseField(f)
      ) ?? []
    );
  }, [data?.fields]);

  const fieldOnChange = useCallback(
    (value) => {
      const field = filteredFields.find((f) => f.id === value?.value);
      let newValueBreakdown;
      if (
        allowedFieldTypesWithOptions.includes(field?.fieldType) ||
        entitiesFieldTypes.includes(field?.fieldType)
      ) {
        newValueBreakdown = [];
        setState('bucketBlank', false);
      } else {
        newValueBreakdown = valueBreakdownFallbackState();
        setState('includeBlank', true);
      }
      setState('field', value);
      setState('value', undefined);
      setState('valueBreakdown', newValueBreakdown);
      setState('valueBreakdownError', false);
      setState('metricField', null);
      setState('metric', VALUE_METRICS.COUNT);
      setState('includeSummary', false);
      setState('summaryExplanation', '');
    },
    [setState, filteredFields]
  );

  const fieldValue = useMemo(() => {
    if (field?.value) {
      return {
        value: field.value,
        label: filteredFields?.find((p) => p.id === field?.value)?.displayName,
      };
    }
    return field;
  }, [field, filteredFields]);

  useMetadataKey('fieldObject', data);

  useDataConstraint((internalState) => {
    if (
      internalState?.field?.value &&
      !internalState?.field?.label &&
      fieldValue
    ) {
      return {
        key: 'field',
        value: fieldValue,
      };
    }
  });

  const groupedOptions = useGroupedFields(
    filteredFields,
    data?.fieldCategories
  );

  // Hide self if area is not metrics
  if (reportType?.value !== AREA_RESPONSES.METRICS) {
    return null;
  }

  return (
    <>
      <StyledWizardField>
        <KizenTypography type="subheader">{t('Choose Field')}</KizenTypography>
        <Select
          value={fieldValue}
          onChange={fieldOnChange}
          captureMenuScroll={false}
          className="modal-select field-metrics"
          placeholder={t('Choose Field')}
          options={groupedOptions}
          fullWidth
          isLoading={isLoading}
          disabled={disabled}
        />
      </StyledWizardField>
    </>
  );
};

export default FieldMetricsArea;
