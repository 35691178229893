// Takes an array of options, returns a { value, label } object
// to make react-select happy

export const TransformToOption = (value, label) => {
  return {
    value,
    label,
  };
};

export const TransformToSelectOptions = (options, valueKey, labelKey) => {
  return options.map((option) => {
    return TransformToOption(option[valueKey], option[labelKey]);
  });
};

export const getUserLabel = (userInfo, t) => {
  const { firstName, lastName, fullName, email } = userInfo || {};

  const names = fullName ? [fullName] : [firstName, lastName];

  const fallback =
    typeof t === 'function' ? `(${t('No Email')})` : '(No Email)';

  return (
    [...names, email && `(${email})`].filter(Boolean).join(' ') || fallback
  );
};
