import styled from '@emotion/styled';
import Icon from 'components/Kizen/Icon';

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;

  i {
    margin-left: 5px;
  }
`;

export const InfoIcon = styled(Icon)`
  & svg {
    width: ${({ iconSize }) => iconSize || '10px'};
    height: ${({ iconSize }) => iconSize || '10px'};
  }
`;
