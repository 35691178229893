import { Spacing, getGapClassName } from '../spacing';
import { getKDSClasses, merge } from '../util';

export interface ColumnProps {
  children?: any;
  gap: Spacing;
}

export const Column = (props: ColumnProps) => {
  const { children, gap } = props;

  const gapClassName = getGapClassName(gap);

  return (
    <div
      className={merge(
        getKDSClasses('grid-column'),
        'flex flex-col relative',
        gapClassName
      )}
    >
      {children}
    </div>
  );
};
