import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import * as PropTypes from 'prop-types';

import { gutters, layers } from 'app/spacing';
import { tableHover, grayScale } from 'app/colors';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import {
  Wrapper,
  ScrollCurtainWrapper,
  ScrollCurtain,
} from 'components/Layout/BigTableLayout';
import { ContentWidth, contentWidthCss } from '../PageContentWidth';
import { PAGE_TOOLBAR_HEIGHT } from '../PageToolbar';
import { ADD_RECORD_TAB_MAX_WIDTH } from '../../Tables/Big/AddRecordTab';
import { useNavBarState } from 'app/navBarController';

const PageSizing = styled.div`
  padding: 0 calc(${gutters.standard} + ${ADD_RECORD_TAB_MAX_WIDTH});
  display: flex;
  flex-direction: column;
  min-height: calc(
    100vh - ${({ navHeight }) => navHeight}px - 43px
  ); // Everything except header
`;

const ToolbarWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: ${layers.content(
    0,
    3
  )}; // Ensure contents incl menus appear on top of table header
`;

const ToolbarStyles = styled(StylePassthrough)`
  ${contentWidthCss}
  // We want the height to add up to PAGE_TOOLBAR_HEIGHT,
  // but we don't want the background to extend to the bottom of its height so
  // that the contact filters can appear correctly then safely scroll behind it.
  // So we replace some of the bottom with a margin. The margin is minimal so
  // that there is no gap for non-sticky content to scroll under.
  height:${PAGE_TOOLBAR_HEIGHT - 6}px;
  margin-bottom: 6px;
  padding-top: 6px; // This doesn't affect height but offsets the margin to keep things centered
  background-color: ${tableHover};
  transition: 0.2s ease-in-out;
  ${({ scrolled }) =>
    scrolled &&
    css`
      background-color: ${grayScale.white};
    `};
  transition-property: background-color;
`;

const BigBoardLayout = ({
  toolbar,
  filters,
  quickFiltersPills,
  filtersOpen,
  children,
  onCurtainTransitionEnd,
  scrolled,
  ...props
}) => {
  const { height } = useNavBarState();
  return (
    <Wrapper {...props}>
      <ScrollCurtainWrapper
        filtersOpen={filtersOpen}
        onTransitionEnd={onCurtainTransitionEnd}
      >
        <ScrollCurtain scrolled={scrolled} filtersOpen={filtersOpen} />
      </ScrollCurtainWrapper>
      <PageSizing navHeight={height}>
        <ToolbarWrapper>
          <ToolbarStyles scrolled={scrolled}>{toolbar}</ToolbarStyles>
        </ToolbarWrapper>
        {filters && <ContentWidth>{filters}</ContentWidth>}
        {quickFiltersPills && <ContentWidth>{quickFiltersPills}</ContentWidth>}
        {children}
      </PageSizing>
    </Wrapper>
  );
};

BigBoardLayout.propTypes = {
  toolbar: PropTypes.node.isRequired,
  filters: PropTypes.node,
  filtersOpen: PropTypes.bool,
  scrolled: PropTypes.bool.isRequired,
  onCurtainTransitionEnd: PropTypes.func.isRequired,
};

BigBoardLayout.defaultProps = {
  filters: null,
  filtersOpen: null,
};

export default BigBoardLayout;
