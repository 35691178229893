import { useTooltip } from 'components/Kizen/Tooltip';
import useField from 'hooks/useField';
import { useTranslation } from 'react-i18next';

export const useDateTooltip = ({ date, predicate = '', popperConfig = {} }) => {
  const {
    t,
    // we do not use localization for date and time yet, but we will in future,
    // so we will pass language as locale to toLocaleDateString and toLocaleTimeString
    // i18n: { language },
  } = useTranslation();

  const [tooltipDate] = useField(() => {
    return Number.isNaN(Date.parse(date)) ? null : new Date(date);
  }, [date]);

  const [tooltipProps, tooltip] = useTooltip({
    label: `${predicate ? predicate + (tooltipDate ? '\n\n' : '') : ''}${
      tooltipDate
        ? t('{{date}} {{at}} {{time}}', {
            date: tooltipDate.toLocaleDateString('en', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }),
            time: tooltipDate.toLocaleTimeString('en', {
              hour: 'numeric',
              minute: '2-digit',
              second: '2-digit',
            }),
            at: 'at', // should be localized in future
          })
        : ''
    }`,
    popperConfig,
  });

  return predicate || tooltipDate ? [tooltipProps, tooltip] : [null, null];
};
