/* eslint-disable react/display-name */
import FieldService, { getObjectUrl } from 'services/FieldService';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import LinkTextInline from 'components/Inputs/inline/LinkText';
import InlineTableFieldInput, {
  getColumnEditable,
} from 'components/Fields/InlineTableFieldInput';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import RowMenuCell from 'components/Tables/Big/RowMenuCell';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import {
  CheckboxCell,
  CheckboxMenuCell,
  ColumnsIconLinkCell,
  SpacerCell,
} from 'pages/Common/components/BigTableCells';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { CustomFieldDataCell, CustomFieldHeadCell } from './CustomFieldCell';
import { SizedCell, SortableHeadCell } from './Table';
import { scrollFieldIntoView } from 'components/Fields/helpers';
import TextInputInline from 'components/Inputs/inline/TextInput';

import {
  getCellForFieldType,
  EmailCell,
  PhoneNumberCell,
  DynamicTagsCell,
  DateTimeCell,
} from 'components/Layout/BigTableLayout/InlineCells';
import { getColumnWidth } from 'components/Fields/InlineTableFieldInput';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import NewRowMenuCell from 'components/Tables/Big/NewRowMenuCell';
import { snakeToCamelCase } from 'services/helpers';

const defaultFieldNameExceptions = {
  fullName: DEFAULT_FIELD_NAMES.firstName,
  [snakeToCamelCase(DEFAULT_FIELD_NAMES.mobilePhone)]:
    DEFAULT_FIELD_NAMES.mobilePhone,
};

export const getDefaultField = (name, fields) => {
  name = defaultFieldNameExceptions[name] || name;
  return fields.find((f) => f.isDefault && f.name === name);
};

export const getEditable = ({ access } = {}) => Boolean(access && access.edit);

const defaultFieldWidths = {
  fullName: 175,
  [DEFAULT_FIELD_NAMES.email]: 200,
  [DEFAULT_FIELD_NAMES.mobilePhone]: 125,
  [DEFAULT_FIELD_NAMES.titles]: 125,
  [DEFAULT_FIELD_NAMES.tags]: 250,
  [DEFAULT_FIELD_NAMES.created]: 150,
  [DEFAULT_FIELD_NAMES.updated]: 150,
};

const dataQaPrefix = 'inline-contact-';

const getBasicColumns = ({
  onSubmitContact,
  fields,
  serviceToUse,
  model,
  handleUpdateFieldOption,
  handleUpdateTableRecords,
  columnSettings,
}) => {
  const firstNameField = getDefaultField(DEFAULT_FIELD_NAMES.firstName, fields);
  const lastNameField = getDefaultField(DEFAULT_FIELD_NAMES.lastName, fields);
  const emailField = getDefaultField(DEFAULT_FIELD_NAMES.email, fields);
  const mobilePhoneField = getDefaultField(
    DEFAULT_FIELD_NAMES.mobilePhone,
    fields
  );
  const titlesField = getDefaultField(DEFAULT_FIELD_NAMES.titles, fields);
  const tagsField = getDefaultField(DEFAULT_FIELD_NAMES.tags, fields);
  const createdField = getDefaultField(DEFAULT_FIELD_NAMES.created, fields);
  const updatedField = getDefaultField(DEFAULT_FIELD_NAMES.updated, fields);

  const defaultCellProps = {
    onSubmit: onSubmitContact,
    serviceToUse,
    model,
    handleUpdateFieldOption,
    handleUpdateTableRecords,
    isCustomObjects: false,
    shouldFocusNext: false,
  };

  return {
    fullName: {
      cell: <SizedCell minWidth={`${defaultFieldWidths.fullName}px`} />,
      headCell: (
        <SortableHeadCell
          by="full_name"
          minWidth={`${defaultFieldWidths.fullName}px`}
          columnSettings={columnSettings}
        />
      ),
      meta: { editable: getEditable(firstNameField) },
      format: (_, contact, column) => {
        const first = FieldService.getFieldValue(contact, firstNameField);
        const last = lastNameField
          ? FieldService.getFieldValue(contact, lastNameField)
          : '';
        const fullName = [first, last].filter(Boolean).join(' ');
        const { id } = contact;
        if (!column.meta.editable || !getEditable(contact)) {
          return (
            <TextEllipsisWithTooltip
              type="link"
              to={id && `/client/${id}/details`}
            >
              {fullName}
            </TextEllipsisWithTooltip>
          );
        }
        const handleSubmit = async (value) => {
          const [firstName, ...lastNames] = (value || '').split(' ');
          return await onSubmitContact(
            id,
            {
              firstName,
              lastName: lastNames.join(' '),
            },
            column
          );
        };
        return id === 'new' ? (
          <TextInputInline
            isNew
            field={firstNameField}
            object={contact}
            shouldFocusNext={false}
            value={fullName}
            onSubmit={(value) => value && handleSubmit(value)}
            onAutoFocus={scrollFieldIntoView}
            submitUnchanged={true}
            {...getDataQAForInput(
              'inline-contact-full_name',
              firstNameField.fieldType
            )}
          />
        ) : (
          <LinkTextInline
            field={firstNameField}
            object={contact}
            type="link"
            shouldFocusNext={false}
            to={`/client/${id}/details`}
            value={fullName}
            onSubmit={handleSubmit}
            {...getDataQAForInput(
              'inline-contact-full_name',
              firstNameField.fieldType
            )}
          />
        );
      },
      field: firstNameField,
    },
    ...(emailField
      ? {
          [DEFAULT_FIELD_NAMES.email]: {
            cell: (
              <SizedCell
                minWidth={`${defaultFieldWidths[DEFAULT_FIELD_NAMES.email]}px`}
              />
            ),
            headCell: (
              <SortableHeadCell
                minWidth={`${defaultFieldWidths[DEFAULT_FIELD_NAMES.email]}px`}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: getEditable(emailField) },
            format: (_, contact, column) => {
              return (
                <InlineTableFieldInput
                  key={contact.id}
                  field={emailField}
                  object={contact}
                  shouldFocusNext={false}
                  onSubmit={async (value) => {
                    return await onSubmitContact(contact.id, value, column);
                  }}
                  {...getDataQAForInput(
                    `inline-contact-${DEFAULT_FIELD_NAMES.email}`,
                    emailField.fieldType
                  )}
                />
              );
            },
            bodyCell: {
              ...defaultCellProps,
              field: emailField,
              minWidth: `${defaultFieldWidths[DEFAULT_FIELD_NAMES.email]}px`,
              Component: EmailCell,
              meta: { editable: getEditable(emailField) },
              dataQaPrefix,
            },
            field: emailField,
          },
        }
      : {}),
    ...(mobilePhoneField
      ? {
          [snakeToCamelCase(DEFAULT_FIELD_NAMES.mobilePhone)]: {
            cell: (
              <SizedCell
                minWidth={`${
                  defaultFieldWidths[DEFAULT_FIELD_NAMES.mobilePhone]
                }px`}
              />
            ),
            headCell: (
              <SortableHeadCell
                numeric
                by={DEFAULT_FIELD_NAMES.mobilePhone}
                minWidth={`${
                  defaultFieldWidths[DEFAULT_FIELD_NAMES.mobilePhone]
                }px`}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: getEditable(mobilePhoneField) },
            format: (_, contact, column) => {
              return (
                <InlineTableFieldInput
                  field={mobilePhoneField}
                  object={contact}
                  shouldFocusNext={false}
                  onSubmit={async (value) => {
                    return await onSubmitContact(contact.id, value, column);
                  }}
                  {...getDataQAForInput(
                    `inline-contact-${DEFAULT_FIELD_NAMES.mobilePhone}`,
                    mobilePhoneField.fieldType
                  )}
                />
              );
            },
            bodyCell: {
              ...defaultCellProps,
              field: mobilePhoneField,
              minWidth: `${defaultFieldWidths[DEFAULT_FIELD_NAMES.email]}px`,
              Component: PhoneNumberCell,
              meta: { editable: getEditable(mobilePhoneField) },
              dataQaPrefix,
            },
            field: mobilePhoneField,
          },
        }
      : {}),
    ...(titlesField
      ? {
          [DEFAULT_FIELD_NAMES.titles]: {
            cell: (
              <SizedCell
                minWidth={`${defaultFieldWidths[DEFAULT_FIELD_NAMES.titles]}px`}
              />
            ),
            headCell: (
              <CustomFieldHeadCell
                shouldFocusNext={false}
                minWidth={`${defaultFieldWidths[DEFAULT_FIELD_NAMES.titles]}px`}
                hasSorting={false}
                field={titlesField}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: getEditable(titlesField) },
            format: (_, contact, column) => {
              return (
                <InlineTableFieldInput
                  field={titlesField}
                  object={contact}
                  fetchUrl={getObjectUrl('contacts')}
                  onSubmit={async (value) => {
                    return await onSubmitContact(contact.id, value, column);
                  }}
                  {...getDataQAForInput(
                    `inline-contact-${DEFAULT_FIELD_NAMES.titles}`,
                    titlesField.fieldType
                  )}
                />
              );
            },
            bodyCell: {
              ...defaultCellProps,
              field: titlesField,
              minWidth: `${defaultFieldWidths[DEFAULT_FIELD_NAMES.titles]}px`,
              Component: DynamicTagsCell,
              fetchUrl: getObjectUrl('contacts'),
              meta: { editable: getEditable(titlesField) },
              dataQaPrefix,
            },
            field: titlesField,
          },
        }
      : {}),
    ...(tagsField
      ? {
          [DEFAULT_FIELD_NAMES.tags]: {
            cell: (
              <SizedCell
                minWidth={`${defaultFieldWidths[DEFAULT_FIELD_NAMES.tags]}px`}
              />
            ),
            headCell: (
              <CustomFieldHeadCell
                minWidth={`${defaultFieldWidths[DEFAULT_FIELD_NAMES.tags]}px`}
                hasSorting={false}
                field={tagsField}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: getEditable(tagsField) },
            format: (_, contact, column) => {
              return (
                <InlineTableFieldInput
                  field={tagsField}
                  object={contact}
                  shouldFocusNext={false}
                  fetchUrl={getObjectUrl('contacts')}
                  onSubmit={async (value) => {
                    return await onSubmitContact(contact.id, value, column);
                  }}
                  {...getDataQAForInput(
                    `inline-contact-${DEFAULT_FIELD_NAMES.tags}`,
                    tagsField.fieldType
                  )}
                />
              );
            },
            bodyCell: {
              ...defaultCellProps,
              field: tagsField,
              minWidth: `${defaultFieldWidths[DEFAULT_FIELD_NAMES.tags]}px`,
              Component: DynamicTagsCell,
              fetchUrl: getObjectUrl('contacts'),
              meta: { editable: getEditable(tagsField) },
              dataQaPrefix,
            },
            field: tagsField,
          },
        }
      : {}),
    ...(createdField
      ? {
          [DEFAULT_FIELD_NAMES.created]: {
            cell: (
              <SizedCell
                minWidth={`${
                  defaultFieldWidths[DEFAULT_FIELD_NAMES.created]
                }px`}
              />
            ),
            headCell: (
              <SortableHeadCell
                numeric
                minWidth={`${
                  defaultFieldWidths[DEFAULT_FIELD_NAMES.created]
                }px`}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: false },
            format: (value, contact) => (
              <DateTimeInputInline
                readOnly
                field={createdField}
                object={contact}
                value={value}
                shouldFocusNext={false}
                showDateTooltip
              />
            ),
            bodyCell: {
              ...defaultCellProps,
              field: createdField,
              minWidth: `${defaultFieldWidths[DEFAULT_FIELD_NAMES.created]}px`,
              Component: DateTimeCell,
              meta: { editable: false },
              dataQaPrefix,
              showDateTooltip: true,
            },
            field: createdField,
          },
        }
      : {}),
    ...(updatedField
      ? {
          [DEFAULT_FIELD_NAMES.updated]: {
            cell: (
              <SizedCell
                minWidth={`${
                  defaultFieldWidths[DEFAULT_FIELD_NAMES.updated]
                }px`}
              />
            ),
            headCell: (
              <SortableHeadCell
                numeric
                minWidth={`${
                  defaultFieldWidths[DEFAULT_FIELD_NAMES.updated]
                }px`}
                columnSettings={columnSettings}
              />
            ),
            meta: { editable: false },
            format: (value, contact) => (
              <DateTimeInputInline
                field={updatedField}
                object={contact}
                readOnly
                shouldFocusNext={false}
                value={value}
                showDateTooltip
              />
            ),
            bodyCell: {
              ...defaultCellProps,
              field: updatedField,
              minWidth: `${defaultFieldWidths[DEFAULT_FIELD_NAMES.updated]}px`,
              Component: DateTimeCell,
              meta: { editable: false },
              dataQaPrefix,
              showDateTooltip: true,
            },
            field: updatedField,
          },
        }
      : {}),
  };
};

export const createColumns = ({
  fields,
  columnSettings,
  onSubmitContact,
  indeterminate,
  hasBulkActions,
  onSelectAction,
  categorizedFields,
  handleUpdateTableRecords,
  //
  handleUpdateFieldOption,
  serviceToUse,
  model,
  t,
}) => {
  const fieldsById = fields.reduce(
    (collect, field) => ({
      ...collect,
      [field.id]: field,
    }),
    {}
  );
  const basicColumns = getBasicColumns({
    onSubmitContact,
    fields,
    serviceToUse,
    model,
    handleUpdateFieldOption, // kzn-8229
    handleUpdateTableRecords,
    columnSettings,
  });
  return [
    hasBulkActions
      ? {
          id: 'selection',
          cell: <CheckboxCell indeterminate={indeterminate} />,
          headCell: (
            <CheckboxMenuCell
              data-qa-selection-checkbox-menu
              indeterminate={indeterminate}
            />
          ),
        }
      : {
          id: 'spacer',
          headCell: <SpacerCell width="15px" />,
          cell: <SpacerCell width="15px" />,
        },
    ...columnSettings
      .filter(({ visible }) => visible)
      .map(({ id, label }) => {
        if (basicColumns[id]) {
          return {
            id,
            label,
            ...basicColumns[id],
          };
        }
        const field = fieldsById[id];

        const fieldProps =
          field.fieldType === FIELD_TYPES.Relationship.type &&
          field.relation.fetchUrl === 'client'
            ? { categorizedFields }
            : {};

        return {
          id,
          label,
          cell: (
            <CustomFieldDataCell
              field={field}
              object={model}
              shouldFocusNext={false}
              onSubmit={onSubmitContact}
              fieldProps={fieldProps}
              fetchUrl={getObjectUrl('contacts')}
              handleUpdateTableRecords={handleUpdateTableRecords}
            />
          ),
          headCell: (
            <CustomFieldHeadCell
              field={field}
              hasSorting={field.fieldType !== FIELD_TYPES.Relationship.type}
              columnSettings={columnSettings}
            />
          ),
          bodyCell: {
            field,
            onSubmit: onSubmitContact,
            fetchUrl: getObjectUrl('contacts'),

            serviceToUse: serviceToUse,
            model,
            object: model,
            handleUpdateFieldOption, // kzn-8229
            handleUpdateTableRecords,
            displayMaskedValue: true,
            minWidth: `${getColumnWidth(field)}px`,
            menuLeftButton: <ClearSelectButton />,
            Component: getCellForFieldType(field.fieldType), // cellMap.get(field.fieldType) || MissedCell,
            isCustomObjects: false,
            shouldFocusNext: false,
            dataQaPrefix,
            fieldProps,
          },
          field: field,
          object: model,
          meta: { editable: getEditable(field) && getColumnEditable(field) },
          // Formatting happens internal to CustomFieldDataCell, but this
          // provides a hook that can be used by e.g. AddRecordTRow.
          format: (x) => x,
        };
      }),
    {
      id: 'columns',
      cell: <RowMenuCell title={t('Edit Contact')} onSelect={onSelectAction} />,
      headCell: <ColumnsIconLinkCell zIndex={2} />,
      bodyCell: {
        isMenuCell: true,
        Component: NewRowMenuCell,
        isCustomObjects: true,
        title: t('Edit Record'),
        onSelect: onSelectAction,
      },
    },
  ];
};
