import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { grayScale } from 'app/colors';
import { css } from '@emotion/core';
import { layers } from 'app/spacing';

const Wrapper = styled.div`
  position: relative;
  grid-column: span ${({ cols }) => cols};
  .Handle {
    height: 100%;
    width: 100%;
  }
  ${({ draggingDimensions }) =>
    draggingDimensions &&
    css`
      // 1 layer above drop indicator (see editorIndicatorCss in builder/PageBuilderContainer)
      z-index: ${layers.content(2)};
      pointer-events: none;
      .Handle {
        position: fixed;
        ${css(draggingDimensions)}
      }
    `}
  ${({ row }) =>
    row &&
    css`
      grid-row: ${row};
    `}
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
`;

export const DraggableAsFixed = forwardRef(
  ({ cols = 1, row = null, style, className, children }, ref) => {
    const [dragging, setDragging] = useState(null);

    return (
      <Wrapper
        ref={ref}
        style={style}
        className={className}
        draggingDimensions={dragging && dragging.dimensions}
        cols={cols}
        row={row}
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
      >
        {dragging ? <Placeholder /> : children}
      </Wrapper>
    );
  }
);

DraggableAsFixed.propTypes = {
  cols: PropTypes.number,
  row: PropTypes.number,
};
