import * as PropTypes from 'prop-types';

export const fileShape = PropTypes.shape({
  contentType: PropTypes.array.isRequired,
  created: PropTypes.array.isRequired,
  id: PropTypes.array.isRequired,
  key: PropTypes.array.isRequired,
  name: PropTypes.array.isRequired,
  sizeBytes: PropTypes.array.isRequired,
  sizeFormatted: PropTypes.array.isRequired,
  thumbnailUrl: PropTypes.array.isRequired,
  url: PropTypes.array.isRequired,
});

export const allowedFileExtensions = [
  '.pdf',
  '.csv',
  '.tsv',
  '.xlsx',
  '.xls',
  '.ods',
  '.ppt',
  '.pptx',
  '.odp',
  '.png',
  '.jpeg',
  '.jpg',
  '.svg',
  '.doc',
  '.docx',
  '.rtf',
  '.txt',
  '.odt',
  '.zip',
  '.gzip',
  '.mp4',
  '.mp3',
  '.wav',
  '.ogg',
  '.css',
  '.msg',
  '.eml',
  '.emltpl',
  '.kzn',
];

export const filterBadFiles = (goodExtensions) => (fi) => {
  const ext = fi.name.split('.').pop();
  return !ext || !goodExtensions.includes(`.${ext.toLowerCase()}`);
};

export const EMPTY_ARRAY = [];
