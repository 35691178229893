import { useMemo, useRef } from 'react';
import { camelToSnakeCase } from 'services/helpers';
import FieldService from 'services/FieldService';
import ClientService from 'services/ClientService';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useTranslation } from 'react-i18next';
import {
  getFieldsForUI,
  ownerToOption,
} from 'store/customObjectsRecordsPage/saga';

import { useInfiniteQuery } from 'react-query';
import { DETAILS_RELATED_OBJECTS } from '../query-keys';
import { monitoringExceptionHelper } from 'sentry/helpers';

const defaultData = {
  count: 0,
  errors: [],
  results: [],
  next: null,
  previous: null,
};

const emptyData = {
  ...defaultData,
  initial: true,
};

export const useListRelatedObjects = (
  objectModel,
  entityId,
  relatedObject,
  filterLookup,
  search,
  filters,
  ordering,
  pageSize = 20,
  searchWithinFieldIds = [],
  enabled = true
) => {
  const failedMap = useRef({});
  const [showToast] = useToast();
  const { t } = useTranslation();
  const lookupId = relatedObject?.value;

  const additionalKeys = useMemo(
    () => filterLookup?.[lookupId]?.filters?.map(({ field }) => field) || [],
    [filterLookup, lookupId]
  );

  const infiniteQuery = useInfiniteQuery({
    queryKey: [
      ...DETAILS_RELATED_OBJECTS.ALL,
      objectModel?.id,
      entityId,
      pageSize,
      relatedObject?.value,
      search,
      ordering,
      searchWithinFieldIds,
      ...additionalKeys,
    ],
    queryFn: async ({ pageParam }) => {
      if (!relatedObject) {
        return emptyData;
      }

      const {
        filters: additionalFilters,
        isCustom,
        model,
        activeRealtionship,
        fieldIds,
      } = filterLookup[lookupId];

      // not related no data
      if (!activeRealtionship) {
        return defaultData;
      }

      const criteria = {
        and: true,
        query: [
          {
            id: 'query-0',
            and: false,
            filters: additionalFilters,
          },
        ],
        searchWithinFieldIds,
      };

      if (filters?.customFilters?.query?.length) {
        criteria.query.push(filters?.customFilters);
      }

      if (!isCustom) {
        const page = Number(pageParam || 1);
        const data = {
          values: {
            search: search,
            ordering,
            criteria,
            page: {
              number: page,
              size: pageSize,
            },
            fieldIds,
          },
          options: { skipErrorBoundary: true },
        };

        let tableData = null;
        try {
          tableData = await ClientService.search(data.values, data.options);
          // don't return anything if we get an error
          if (tableData?.errors?.length) {
            return defaultData;
          }
        } catch (error) {
          // pretty much a 500, because of rights restrictions
          monitoringExceptionHelper(error);

          const key = [
            ...DETAILS_RELATED_OBJECTS.ALL,
            objectModel?.id,
            entityId,
            pageSize,
            relatedObject?.value,
            ...additionalKeys,
          ].join('-');

          if (!failedMap.current[key]) {
            failedMap.current = { ...failedMap.current, [key]: true };

            showToast({
              message: t(
                'Unable to load Related information. Please try again or contact Kizen support.'
              ),
              variant: toastVariant.FAILURE,
            });
          }
          const responseErrors = error?.response?.data?.errors?.errors;
          if (responseErrors) {
            throw responseErrors;
          }
          return defaultData;
        }

        const next =
          page * pageSize < (tableData?.count || 0)
            ? `https://api.dummy.org?page=${page + 1}` // the url doesn't matter but one isrequired to parse the page
            : null;

        return { ...tableData, next };
      } else {
        const body = {
          search: search,
          ordering: camelToSnakeCase(ordering),
          number: pageParam || 1,
          size: pageSize,
          modelName: model.name,
          criteria,
          fieldIds,
        };
        const params = {
          search: search,
          ordering: camelToSnakeCase(ordering),
          page: pageParam || 1,
          pageSize: pageSize,
          skipErrorBoundary: true,
          inGroupIds: filters?.inGroupFilters,
          notInGroupIds: filters?.notInGroupFilters,
        };
        // TODO: it can be removed once backend add objectClass field
        const updatedObject = { ...model, objectClass: 'customObjects' };
        let tableData;

        try {
          tableData = await FieldService.getModelRecords({
            model: updatedObject,
            params,
            body,
          });

          // don't return anything if we get an error
          if (tableData?.errors?.length) {
            return defaultData;
          }
        } catch (error) {
          // pretty much a 500, because of rights restrictions
          monitoringExceptionHelper(error);

          const key = [
            ...DETAILS_RELATED_OBJECTS.ALL,
            objectModel?.id,
            entityId,
            pageSize,
            relatedObject?.value,
            ...additionalKeys,
          ].join('-');
          if (!failedMap.current[key]) {
            failedMap.current = { ...failedMap.current, [key]: true };
            showToast({
              message: t(
                'Unable to load Related information. Please try again or contact Kizen support.'
              ),
              variant: toastVariant.FAILURE,
            });
          }

          const responseErrors = error?.response?.data?.errors?.errors;
          if (responseErrors) {
            throw responseErrors;
          }
          return { defaultData };
        }

        // need to remap taken from `store/customObjectsRecordsPage/saga.js`
        const results = tableData.results.map((item) => {
          const fields = getFieldsForUI(item.fields);
          return {
            ...item,
            owner: item.owner ? ownerToOption(item.owner) : null,
            stage: item.stage
              ? {
                  value: item.stage.id,
                  label: item.stage.name,
                }
              : null,
            fields,
          };
        });

        return {
          count: tableData.count,
          errors: tableData.errors || [],
          results,
          next: tableData.next || null,
          previous: tableData.previous || null,
        };
      }
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next
        ? new URL(lastPage.next).searchParams.get('page')
        : undefined;
    },
    getPreviousPageParam: (previousPage) => {
      return previousPage?.prev
        ? new URL(previousPage.prev).searchParams.get('page')
        : undefined;
    },
    keepPreviousData: false, // we do not want to refetch all pages when sorting or searching
    retry: false,
    useErrorBoundary: false,
    staleTime: Infinity,
    enabled,
  });

  return infiniteQuery;
};
