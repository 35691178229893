import { useCallback, useState } from 'react';
import { useAsync } from 'react-use';
import { useDispatch } from 'react-redux';
import { BusinessSelectionChangedRedirect } from 'routes/auth';
import { useTranslation } from 'react-i18next';

import AuthenticationService from 'services/AuthenticationService';
import { serviceChooseBusinessStart } from 'store/authentication/authenticationAction';
import Select from 'components/Inputs/Select';
import Loader from 'components/Kizen/Loader';
import AuthForm from 'components/Kizen/AuthForm';
import Button from 'components/Button';
import { KizenTypography } from 'app/typography';
import { breakpoints, gutters } from 'app/spacing';
import useFormSubmit from 'hooks/useFormSubmit';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { oauthSessionStorage } from 'utility/_oauthSessionStorage';
import { serviceLogoutStart } from 'store/authentication/authenticationAction';
import { useHistory } from 'react-router-dom';
import {
  DATA_MANAGER_SESSION_ACTIONS,
  useDataManagerSession,
} from 'app/dataManagerSession';

const styles = `
  .Authform-wrapper {
    width: 80%;
    @media (min-width: ${breakpoints.md}px) {
      width: 400px;
    }
  }

  .ChooseBusiness-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ChooseBusiness-select {
    margin-top: ${gutters.large};
    width: 100%;
    margin-bottom: ${gutters.large};
  }

  .ChooseBusiness-select > label > span {
    margin-bottom: ${gutters.standard};
  }

  .ChooseBusiness-button {
    margin: 0;
    margin-left: auto;
  }
`;

const mapBuisnessesToSelectOptions = ({
  id,
  name,
  isValidForSession = true,
}) => ({
  value: id,
  label: name,
  isValidForSession,
});

const ChooseBusinessPage = ({ title }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  useSetTitleOnLoad(title(t));

  const [selectedOption, setSelecetedOption] = useState(null);
  const [fetchError, setFetchError] = useState('');
  const [formError, setFormError] = useState('');
  const [newChosenBusiness, setNewChosenBusiness] = useState();
  const { dispatchSessionState } = useDataManagerSession();

  const [submitState, fetchChosenBusiness] = useFormSubmit(
    async (ev) => {
      ev.preventDefault();
      setFormError('');
      const [error, chosenBusiness] = await dispatch(
        serviceChooseBusinessStart(selectedOption?.value)
      );

      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.DELETE,
      });

      if (!error) {
        const hasRedirect = oauthSessionStorage.val;
        if (hasRedirect) {
          // we are loged in, with a valid business and have valid redirect
          oauthSessionStorage.clear();
          history.replace(hasRedirect);
        }
        return setNewChosenBusiness(chosenBusiness);
      }

      return setFormError(error.message);
    },
    [selectedOption]
  );

  const { value: businesses, loading } = useAsync(async () => {
    let options = [];

    try {
      const currentUrl = new URL(window.location);
      const showAllBusinesses = currentUrl.searchParams.get('all');
      const myBusinesses =
        await AuthenticationService.fetchBusinesses(showAllBusinesses);
      if (!myBusinesses || myBusinesses.length === 0) {
        setFetchError(t('Sorry, unable to retrieve your businesses'));
        return;
      }

      const available = myBusinesses.filter(
        ({ isValidForSession }) => isValidForSession
      );

      const requiresDifferentAuth = myBusinesses.filter(
        ({ isValidForSession }) => !isValidForSession
      );

      // Transform businesses to display form expected by Select
      options = [
        {
          label: t('Available Businesses'),
          options: available.map(mapBuisnessesToSelectOptions),
        },
        {
          label: t('Require Different Auth'),
          options: requiresDifferentAuth.map(mapBuisnessesToSelectOptions),
        },
      ].filter(({ options }) => options.length > 0);
    } catch (error) {
      setFetchError(error.message);
    }

    return options;
  }, [t]);

  const handleSelectBusiness = useCallback(
    (option) => {
      if (option?.isValidForSession) {
        setSelecetedOption(() => option);
        return;
      }
      // If the business is not valid for the current session, we redirect to the login page
      dispatch(serviceLogoutStart());
      history.replace('/login');
    },
    [setSelecetedOption, dispatch, history]
  );

  if (newChosenBusiness) {
    return <BusinessSelectionChangedRedirect />;
  }

  return (
    <AuthForm styles={styles}>
      <div className="Authform-wrapper">
        <Loader loading={loading}>
          <form action="">
            <KizenTypography type="subheader" weight="bold">
              {t('Choose Business')}
            </KizenTypography>

            <Select
              value={selectedOption}
              label={t('Choose Business')}
              className="ChooseBusiness-select"
              options={businesses}
              placeholder={t('Choose business')}
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
              }}
              onChange={handleSelectBusiness}
            />
            {/*
              We'll normally redirect away if either of these conditions evaluate to false,
              getting here only if the AuthenticationService request throws
            */}
            {!!(businesses && businesses.length) && (
              <div className="ChooseBusiness-actions">
                <Button
                  className="ChooseBusiness-button"
                  onClick={fetchChosenBusiness}
                  disabled={!selectedOption || submitState.loading}
                >
                  {t('Submit')}
                </Button>
                {formError && (
                  <KizenTypography className="Authform-error">
                    {formError}
                  </KizenTypography>
                )}
              </div>
            )}
            {fetchError && (
              <KizenTypography className="Authform-error">
                {fetchError}
              </KizenTypography>
            )}
          </form>
        </Loader>
      </div>
    </AuthForm>
  );
};

export default ChooseBusinessPage;
