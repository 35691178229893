import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from '__components/Inputs/Select/hooks';
import MultiSelect from '__components/Inputs/MultiSelect';
import { AutomationLike, GenericOption } from '__pages/SmartConnectors/types';

export type AutomationOption = GenericOption & {
  error: boolean;
  originalLabel: string;
};

type AutomationSelectProps = {
  customObjectId: string;
  selected: AutomationLike[];
  onChange: (value: AutomationOption[]) => void;
  label: string;
  labelInfo?: string;
};

export const AutomationSelect = ({
  customObjectId,
  selected,
  onChange,
  label,
  labelInfo,
}: AutomationSelectProps) => {
  const { t } = useTranslation();

  const [value, valueIds] = useMemo(() => {
    const selectedValue = selected
      .map((v) => ({
        value: v.id,
        label: v.active === false ? `[${t('Inactive')}] ${v.name}` : v.name,
        originalLabel: v.name,
        error: v.active === false,
      }))
      .sort(({ error: a }, { error: b }) => (b === a ? 0 : b ? 1 : -1));

    return [
      selectedValue,
      selected.reduce((acc, v) => {
        if (v.active !== false) {
          acc.push(v.id);
        }
        return acc;
      }, [] as string[]),
    ];
  }, [selected, t]);

  const selectRef = useRef(null);

  const params = useMemo(
    () => ({
      active: true,
      custom_object_id: customObjectId,
    }),
    [customObjectId]
  );

  const [typeaheadProps, { loading }] = useSelectTypeaheadWithScroll({
    entity: Entities.Automations,
    enabled: !!customObjectId,
    selectRef,
    params,
    chosenValueIds: valueIds,
  });

  return (
    <MultiSelect
      ref={selectRef}
      value={value}
      label={label}
      labelInfo={labelInfo}
      labelInfoPlacement="top"
      labelInfoMaxWidth={255}
      onChange={onChange}
      placeholder={t('Select Option')}
      {...typeaheadProps}
      isLoading={loading}
    />
  );
};
