import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { appBackground, colorsButton, grayScale } from 'app/colors';
import { borderRadii } from 'app/spacing';
import { isCellToday, isCellTomorrow, isTodaySaturday } from '../utils';

const Container = styled.div`
  border: 1px solid ${grayScale.medium};
  border-top-width: 0;
  border-right-width: 0;
  background-color: ${grayScale.white};
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isToday, isLastDayOfWeek }) =>
    isToday &&
    isLastDayOfWeek &&
    css`
      border-right-width: 1px;
      border-right-color: ${colorsButton.blue.default};
    `}

  ${({ isTomorrow }) =>
    isTomorrow &&
    css`
      border-left-color: ${colorsButton.blue.default};
    `}
  ${({ isToday }) =>
    isToday &&
    css`
      border-left-color: ${colorsButton.blue.default};
      background-color: ${appBackground};
    `}
  ${({ isLastRow }) =>
    isLastRow &&
    css`
      border-bottom-width: 0;
    `}
  ${({ isFirstRow, isLastColumn }) =>
    isFirstRow &&
    isLastColumn &&
    css`
      border-top-right-radius: ${borderRadii.small};
    `}
  ${({ isLastRow, isLastColumn }) =>
    isLastRow &&
    isLastColumn &&
    css`
      border-bottom-right-radius: ${borderRadii.small};
    `}
`;

const WeekCalendarCell = ({ date, children, ...rest }) => {
  return (
    <Container
      isToday={isCellToday(date)}
      isTomorrow={isCellTomorrow(date)}
      isLastDayOfWeek={isTodaySaturday()}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default WeekCalendarCell;

WeekCalendarCell.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isFirstRow: PropTypes.bool.isRequired,
  isLastRow: PropTypes.bool.isRequired,
  isLastColumn: PropTypes.bool.isRequired,
};
