import * as React from 'react';
import IntlContext from './IntlContext';
import { withTranslation } from 'react-i18next';

const FormattedMessage = ({ t, id, componentClass }) => {
  const Component = componentClass || 'span';
  return (
    <Component>
      <IntlContext.Consumer>
        {(context) => {
          if (
            context &&
            typeof id === 'string' &&
            typeof context[id] !== 'undefined'
          ) {
            return typeof context[id] === 'function'
              ? context[id](t)
              : context[id];
          }
          return id;
        }}
      </IntlContext.Consumer>
    </Component>
  );
};

export default withTranslation()(FormattedMessage);
