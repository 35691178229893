import { DesktopBuilderCard } from '__components/Wizards/CustomObject/steps/CustomLayout/styles';
import PermissionSettings from '__components/Sharing/PermissionSettings';
import { CUSTOM_LAYOUT_TABS } from 'components/Wizards/CustomObject/utilities';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactPortal, RefObject } from 'react';
import { Spacer } from '@kizen/kds/Spacer';
import { monitoringExceptionHelper } from 'sentry/helpers';

interface PermissionSectionProps {
  secondarySettingsContainer?: RefObject<HTMLDivElement>;
  handleChange: (...args: any[]) => any;
  existing?: any;
}

export const PermissionSection = (
  props: PermissionSectionProps
): ReactPortal | null => {
  const { secondarySettingsContainer, handleChange, existing } = props;
  const { t } = useTranslation();

  if (!secondarySettingsContainer?.current) {
    monitoringExceptionHelper(
      'Record layout permissions settings render error - could not target secondarySettingsContainer'
    );
    return null;
  }

  const infoText = t(
    'Matching any of the criteria below will grant the team member access to view this record layout.'
  );

  return ReactDOM.createPortal(
    <DesktopBuilderCard
      data-qa={`${CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT}_tab_secondary`}
      label={t('Record Layout Sharing Settings')}
      info={infoText}
    >
      <Spacer mode="horizontal" size={15} />
      <PermissionSettings
        onChange={handleChange}
        existing={existing}
        small
        infoBubble={infoText}
        inline
      />
    </DesktopBuilderCard>,
    secondarySettingsContainer.current
  );
};
