import { useCallback, useMemo, useRef, useState } from 'react';

const useMenuPlacement = () => {
  const [entities, setEntities] = useState();
  const targetRef = useRef(null);
  const modalBody = document.querySelector(
    '.modal-open .modal-content > div:last-child'
  );
  const observer = useMemo(() => {
    return new IntersectionObserver(
      (entries) => {
        if (entries[0]) {
          setEntities(entries[0]);
        }
      },
      {
        root: modalBody,
        rootMargin: `40px 0px -240px 0px`,
        threshold: 1,
      }
    );
    // deps should also include container Element height when the container Element has a dynamic size
  }, [modalBody]);

  const refCallback = useCallback(
    (node) => {
      if (modalBody) {
        if (targetRef.current && !node) {
          observer.unobserve(targetRef.current);
        }
        targetRef.current = node;
        if (node) {
          observer.observe(targetRef.current);
        }
      } else {
        targetRef.current = node;
      }
    },
    [modalBody, observer]
  );

  return {
    ref: refCallback,
    isIntersecting: entities?.isIntersecting,
  };
};
export default useMenuPlacement;
