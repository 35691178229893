import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { gutters, layers } from 'app/spacing';
import { colorsPrimary, grayScale } from 'app/colors';
import { fontSizes } from 'app/typography';
import LinkNavSelector from 'components/Navigation/LinkNavSelector';

export const SelectorWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  ${({ mobile }) =>
    mobile
      ? css`
          margin-bottom: 20px;
          width: 100%;
        `
      : ''}

  p {
    margin-right: ${gutters.spacing(2)}px;
    white-space: nowrap;
  }

  // Have to target a very specific div in react-select so this looks a little weird
  & > div > div > div {
    max-width: unset;
    width: ${({ mobile }) => (mobile ? '100%' : '306px')};
  }
  margin-right: ${({ mobile }) => (mobile ? 0 : gutters.spacing(4))}px;
`;

export const StyledFooter = styled.div`
  border-top: 1px solid ${grayScale.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding-left: ${gutters.spacing(2, 2)}px;
  padding-right: ${gutters.spacing(2, 2)}px;
  > .apply-button {
    height: unset;
    font-size: ${fontSizes.small};
    line-height: 1;
  }
  white-space: nowrap;
  column-gap: 20px;
`;

export const StyledGroupSelector = styled(LinkNavSelector)`
  position: relative;
  width: 100%;

  .chart-group__control {
    background: white;
    border: 1px solid ${grayScale.medium};
    padding: 0 10px;
    height: 31px;
    min-height: 31px;
    position: absolute;
    left: 0;
    top: -15px;
    width: 100%;
    flex-grow: 1;
    transition: none;
    z-index: ${layers.content(0, 9)};
  }

  .chart-group__control.chart-group__control--menu-is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .chart-group__control.chart-group__control--is-focused,
  .chart-group__control:hover {
    border-color: ${colorsPrimary.blue.dark};
  }

  & div.chart-group__value-container {
    background-image: none !important;
  }

  .chart-group__menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &&& input,
  .chart-group__single-value,
  .chart-group__single-value span {
    font-size: 1.4rem !important;
    padding-bottom: 0;
    padding-top: 1px;
  }

  .chart-group__menu {
    top: 17px; // nudge to line up with the menu control
  }
  border: none;
`;
