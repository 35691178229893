import { useCallback, useEffect, useState, useReducer, useMemo } from 'react';
import CompleteActivityFields from './sections/fields';
import CompleteActivityObjects from './sections/relatedObjects';
import ActivityService from 'services/ActivityService';
import {
  FORMDATA_FIELDS,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  FORM_ACTIONS,
} from './config';
import { useActivityAssociation } from './helpers';
import { useAsync } from 'react-use';
import { isEmptyValue, mapFields } from './helpers';
import { ASSIGNMENT_SET_MULTIPLE } from 'components/Charts/ScheduledActivities/assignmentHelpers';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useActivityErrorToastQuery } from 'queries/models/activites';

import { useActivityRules } from 'ts-activities/useActivityRules';
import { validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';
import { isEmailField } from 'checks/fields';
const { SCHEDULED_ACTIVITY_ID, NOTES, FIELDS, RELATED_OBJECTS, MENTIONS } =
  FORMDATA_FIELDS;

const activityInit = (scheduledActivity) => ({
  [SCHEDULED_ACTIVITY_ID]: scheduledActivity.id,
  [NOTES]: scheduledActivity.note || '',
  [FIELDS]: EMPTY_ARRAY,
  [RELATED_OBJECTS]: EMPTY_ARRAY,
  [MENTIONS]: scheduledActivity.mentions
    ? scheduledActivity.mentions.map(({ id }) => id)
    : EMPTY_ARRAY,
});

export const activityReducer = (state, { type, payload }) => {
  switch (type) {
    case FORM_ACTIONS.update: {
      const { field, value } = payload;
      return {
        ...state,
        [field]: typeof value === 'function' ? value(state[field]) : value,
      };
    }
    case FORM_ACTIONS.reset: {
      const { scheduledActivity } = payload;
      return activityInit(scheduledActivity);
    }
    default:
      throw new Error('Unknown action');
  }
};

export const STEP_ACTIONS = {
  validate: 'VALIDATE',
};

export const stepValReducer = (state, { type, payload }) => {
  switch (type) {
    case STEP_ACTIONS.validate: {
      const { step, isValid } = payload;
      if (step in state && state[step] === isValid) {
        return state;
      }
      if (!(step in state) && isValid === null) {
        return state;
      }
      const nextStepValidation = {
        ...state,
        [step]: isValid,
      };
      Object.keys(nextStepValidation).forEach(
        (k) => nextStepValidation[k] === null && delete nextStepValidation[k]
      );
      return nextStepValidation;
    }
    default:
      throw new Error('Unknown step validation action');
  }
};

export function useActivityConfiguration(scheduledActivity) {
  const [formData, dispatchFormData] = useReducer(
    activityReducer,
    scheduledActivity,
    activityInit
  );
  const [isDirty, setDirty] = useState(false);

  const updateField = useCallback(
    (f, value, shouldIgnoreDirty) => {
      !shouldIgnoreDirty && setDirty(true);
      dispatchFormData({
        type: FORM_ACTIONS.update,
        payload: { field: f, value },
      });
    },
    [dispatchFormData]
  );

  const resetField = useCallback(() => {
    setDirty(false);
    dispatchFormData({
      type: FORM_ACTIONS.reset,
      payload: { scheduledActivity },
    });
  }, [scheduledActivity, dispatchFormData]);

  const [stepValidation, dispatchStepValidation] = useReducer(
    stepValReducer,
    EMPTY_OBJECT
  );

  const updateValidation = useCallback(
    (step, isValid = null) => {
      dispatchStepValidation({
        type: STEP_ACTIONS.validate,
        payload: { step, isValid },
      });
    },
    [dispatchStepValidation]
  );

  const isComplete = Object.keys(stepValidation).every(
    (step) => !!stepValidation[step] || stepValidation[step] === null
  );

  return {
    formData,
    updateField,
    resetField,
    updateValidation,
    isComplete,
    isDirty,
  };
}

const CompleteActivitytWizard = ({
  onChange,
  data = EMPTY_OBJECT,
  predefinedOptions,
  showErrors,
  setShowErrors,
  setBlockSave,
}) => {
  const {
    formData,
    updateField,
    resetField,
    updateValidation,
    isComplete,
    isDirty,
  } = useActivityConfiguration(data);

  const { notes, fields, relatedObjects } = formData;
  const {
    assignments,
    dispatchAssignment,
    activityRelatedObjects,
    isFetching,
  } = useActivityAssociation(data.activityObject?.id, predefinedOptions, {
    retry: false,
  });

  const {
    data: activity,
    isError,
    loading,
  } = useActivityErrorToastQuery(data.activityObject?.id, {
    retry: false,
  });

  const { isRuleActiveForField, loading: activityRulesLoading } =
    useActivityRules(activity?.visibilityRules, fields);

  useEffect(() => {
    if (activityRulesLoading) {
      setBlockSave?.(true);
    } else {
      setBlockSave?.(false);
    }
    setBlockSave?.(isError || loading);
  }, [setBlockSave, activityRulesLoading, isError, loading]);

  useEffect(() => {
    if (data?.associatedEntities?.length && activityRelatedObjects.length) {
      const value = data.associatedEntities.reduce((acc, entity) => {
        const relObject = activityRelatedObjects.find(
          (act) => act.id === entity.customObjectId
        );
        if (relObject) {
          const { name } = relObject.component.setterProp;
          const value = {
            label: entity.displayName,
            value: entity.entityId,
          };
          acc[name] = value;
        }
        return acc;
      }, {});
      dispatchAssignment({
        type: ASSIGNMENT_SET_MULTIPLE,
        value,
      });
    }
  }, [data, activityRelatedObjects, dispatchAssignment]);

  const { value: activityFields = EMPTY_ARRAY } = useAsync(async () => {
    const rawFields = data.activityObject?.id
      ? await ActivityService.getObjectFields(
          {
            activityObjectId: data.activityObject?.id,
          },
          {
            skipErrorBoundary: (error) => error.response?.status === 404,
          }
        )
      : [];
    return rawFields.map(mapFields);
  }, [data.activityObject?.id]);

  const errors = useMemo(() => {
    return fields.map((field, i) => {
      if (isRuleActiveForField(field)) {
        if (isEmailField(field)) {
          return (
            activityFields[i]?.isRequired && validateEmail(field.value) === null
          );
        } else
          return activityFields[i]?.isRequired && isEmptyValue(field.value);
      }
      return false;
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, activityFields]);

  useEffect(() => {
    const data = {
      ...formData,
      fields: formData.fields.filter(isRuleActiveForField),
      passVariablesOnRedirect: activity?.passVariablesOnRedirect,
      redirectUrl: activity?.redirectUrl,
      redirectParameterFields: activity?.redirectParameterFields,
      submissionAction: activity?.submissionAction,
    };
    onChange({ data, isComplete, isDirty, errors });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, isComplete, isDirty, onChange, errors]);

  const fieldsData = useMemo(
    () => [
      ...activityRelatedObjects.map(({ id }) => ({ id })),
      ...fields.map(({ id }) => ({ id })),
    ],
    [activityRelatedObjects, fields]
  );

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    fieldsData,
    {},
    () => true
  );

  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <>
        <CompleteActivityObjects
          getKeyListenersProps={getKeyListenersProps}
          dispatchAssignment={dispatchAssignment}
          assignments={assignments}
          updateField={updateField}
          updateValidation={updateValidation}
          activityRelatedObjects={
            isError ? EMPTY_ARRAY : activityRelatedObjects
          }
          relatedObjects={relatedObjects}
          loading={isFetching}
          setShowErrors={setShowErrors}
          isLastIndex
        />
        <CompleteActivityFields
          getKeyListenersProps={getKeyListenersProps}
          updateField={updateField}
          resetField={resetField}
          activityObject={data.activityObject}
          fields={fields}
          activityFields={activityFields}
          notes={notes}
          relatedObjects={relatedObjects}
          errors={errors}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          isRuleActiveForField={isRuleActiveForField}
        />
      </>
    </KeyBoardContext.Provider>
  );
};

export default CompleteActivitytWizard;
