import { useCallback, useState } from 'react';

export default ({ name, id = null }) => {
  const [directories, setDirectories] = useState([{ name, id }]);
  const handleChangeDirectory = useCallback(({ name, id }) => {
    setDirectories((prev) => {
      const index = prev.findIndex((e) => e.id === id);
      return index === -1 ? [...prev, { name, id }] : prev.slice(0, index + 1);
    });
  }, []);
  return {
    directories,
    handleChangeDirectory,
    parentFolder: directories[directories.length - 2] || directories[0],
    currentFolder: directories[directories.length - 1],
  };
};
