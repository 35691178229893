import React, { useMemo } from 'react';
import { Cell } from '../../styles';
import { BarWrapper, BarInner } from './styles';
import { colorsPrimary, colorsSecondary } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import PropTypes from 'prop-types';
import { collapseNumber } from 'utility/numbers';
import useWidthMeasurement from 'hooks/useWidthMeasurement';

const MAX_OPACITY = 1;
const MIN_OPACITY = 0.3;

const getBarColor = (row, index, openCount = 0) => {
  // The first bar in the series should have an opacity of 30%, and the last bar
  // an opacity of 100%, so the range spans 70%;
  const opacityRange = MAX_OPACITY - MIN_OPACITY;

  // Calculate how large a 'stop' is in the opacity scale. For example, with 3 bars,
  // the stop should be 0.35, yielding opacities of 0.3, 0.65, and 1.
  const opacityStops = openCount > 0 ? opacityRange / (openCount - 1) : 0;

  if (row.status === 'open') {
    return {
      color: colorsPrimary.blue.dark,
      // Select an opacity based on where the bar is in the series.
      opacity: MIN_OPACITY + opacityStops * index,
    };
  }

  // Won statuses don't use opacities
  if (row.status === 'won') {
    return {
      color: colorsPrimary.green.dark,
      opacity: MAX_OPACITY,
    };
  }

  // Lost/Disqualified statuses don't use opacities
  return {
    color: colorsSecondary.red.light,
    opacity: MAX_OPACITY,
  };
};

const BarCell = ({ row, maxBarValue, index, openCount, valuePrefix }) => {
  const percent = useMemo(() => {
    return (row.value / maxBarValue) * 100;
  }, [row, maxBarValue]);

  const { ref: textRef, width: textWidth } = useWidthMeasurement();
  const { ref: barRef, width: barWidth } = useWidthMeasurement();

  const isBarSmall = useMemo(() => {
    const minBarWidth = textWidth + 10;
    return barWidth < minBarWidth;
  }, [textWidth, barWidth]);

  return (
    <Cell>
      <BarWrapper>
        <BarInner
          widthPercent={percent}
          color={getBarColor(row, index, openCount)}
          ref={barRef}
        >
          {!isBarSmall ? (
            <KizenTypography ref={textRef}>
              {valuePrefix}
              {collapseNumber(row.value)}
            </KizenTypography>
          ) : null}
        </BarInner>
        {isBarSmall ? (
          <KizenTypography className="darken" ref={textRef}>
            {valuePrefix}
            {collapseNumber(row.value)}
          </KizenTypography>
        ) : null}
      </BarWrapper>
    </Cell>
  );
};

BarCell.propTypes = {
  row: PropTypes.object.isRequired,
  maxBarValue: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  openCount: PropTypes.number.isRequired,
  valuePrefix: PropTypes.string,
};

BarCell.defaultProps = {
  valuePrefix: '',
};

export default BarCell;
