import useAccessRequest from 'components/Sharing/hooks/useAccessRequest';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ColumnsService from 'services/ColumnsService';

export const useRespondToAccessRequest = (refetch, objectId) => {
  const [showToast] = useToast();
  const { t } = useTranslation();
  const history = useHistory();

  const respond = useCallback(
    async ({ requestId, approved }) => {
      try {
        await ColumnsService.respondToAccessRequest(
          requestId,
          approved,
          objectId
        );
        if (approved) {
          showToast({
            message: t('You have successfully granted access'),
            variant: toastVariant.SUCCESS,
          });
          refetch?.();
        } else {
          showToast({
            message: t('You have denied access'),
            variant: toastVariant.FAILURE,
          });
        }
      } catch (err) {
        showToast({
          message:
            err?.response?.data?.non_field_errors?.[0] ??
            t('Could not respond to access request. An error occurred.'),
          variant: toastVariant.FAILURE,
        });
      } finally {
        history.replace(history.location.pathname);
      }
    },
    [objectId, history, refetch, showToast, t]
  );

  useAccessRequest(respond);
};
