import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryObjectUploadContext } from '../../context';
import { StyledSubtitle, StyledCols, StyledNotice } from './styles';
import { AutomationOption, AutomationSelect } from './AutomationSelect';
import Select from '__components/Inputs/Select';
import { useVariableErrors } from './useVariableErrors';
import { NameInput } from './NameInput';
import {
  getSmartConnectorVariables,
  getUniqueName,
} from '__pages/SmartConnectors/SmartConnector/helpers';
import { GenericOption, VariableDataType } from '__pages/SmartConnectors/types';
import { isContact } from 'utility/fieldHelpers';
import { VARIABLE_TYPES } from '__pages/SmartConnectors/constants';

export const Settings = () => {
  const { t } = useTranslation();
  const {
    stepData,
    setStepData,
    loadId,
    smartConnector,
    customObject,
    allVariables,
  } = useContext(PrimaryObjectUploadContext);

  const currentLoad = stepData.loads?.find((load) => load.id === loadId);

  const { hasError, validate, errorKey, clearError } = useVariableErrors();

  const variableTypeOptions = useMemo<GenericOption<VariableDataType>[]>(() => {
    return [
      { value: 'uuid', label: t('UUID (Kizen ID)') },
      {
        value: 'string',
        label: t('String ({{name}})', {
          name: !isContact(customObject) ? t('Record Name') : t('Record Email'),
        }),
      },
    ];
  }, [t, customObject]);

  useEffect(() => {
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if (load.id === loadId && !load.execution_variable) {
          return {
            ...load,
            execution_variable: {
              id: `new_${Date.now()}`,
              data_type: variableTypeOptions[0]?.value || null,
              name: getUniqueName(
                t('{{ objectName }} Matched Record', customObject),
                allVariables.map((v) => v.name)
              ),
              type: VARIABLE_TYPES.related_object_upload,
            },
          };
        }
        return load;
      }),
    }));
  }, [
    allVariables,
    smartConnector.custom_object,
    customObject,
    setStepData,
    t,
    loadId,
    variableTypeOptions,
  ]);

  const onChangeAutomationsForCreated = (value: AutomationOption[]) => {
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if (load.id === loadId) {
          return {
            ...load,
            newly_created_records_automations: value.map(
              ({ value, label, originalLabel, error = false }) => ({
                id: value,
                name: originalLabel || label,
                active: !error,
              })
            ),
          };
        }
        return load;
      }),
    }));
  };

  const onChangeAutomationsForMatched = (value: AutomationOption[]) => {
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if (load.id === loadId) {
          return {
            ...load,
            other_matches_records_automations: value.map(
              ({ value, label, originalLabel, error = false }) => ({
                id: value,
                name: originalLabel || label,
                active: !error,
              })
            ),
          };
        }
        return load;
      }),
    }));
  };

  const onChangeVariableName = (value: string) => {
    const names = getSmartConnectorVariables({
      execution_variables: smartConnector.execution_variables || [],
      flow: {
        ...smartConnector.flow,
        loads: (smartConnector.flow.loads || []).filter(
          (load) => load.id !== loadId
        ),
      },
    }).map(({ name }) => name);
    const name = getUniqueName(value.trim(), names);
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if (load.id === loadId) {
          return {
            ...load,
            execution_variable: {
              id: load.execution_variable?.id || `new_${Date.now()}`,
              data_type: load.execution_variable?.data_type || null,
              name,
              type: VARIABLE_TYPES.related_object_upload,
            },
          };
        }
        return load;
      }),
    }));

    clearError('name');

    return name;
  };

  const onChangeVariableDataType = ({ value }: { value: VariableDataType }) => {
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if (load.id === loadId) {
          return {
            ...load,
            execution_variable: {
              id: load.execution_variable?.id || `new_${Date.now()}`,
              name: load.execution_variable?.name || '',
              data_type: value,
              type: VARIABLE_TYPES.related_object_upload,
            },
          };
        }
        return load;
      }),
    }));

    clearError('data_type');
  };

  return (
    <>
      <StyledSubtitle weight="bold">{t('Settings')}</StyledSubtitle>
      <StyledNotice as="div">
        {t(
          'Once a record has been matched on this object, and all functions of this upload step have been completed for the respective row, the record that the actions were completed on will need to be assigned to a variable for use in following upload steps. You may also start automations on newly created or other matched records at the conclusion of the SmartConnector processing.'
        )}
      </StyledNotice>
      <StyledCols columns={2} gutter="20px">
        <NameInput
          value={currentLoad?.execution_variable?.name || ''}
          onChange={onChangeVariableName}
          error={!!hasError && errorKey === 'name'}
          validate={errorKey === 'name' ? validate : undefined}
        />
        <Select
          value={currentLoad?.execution_variable?.data_type}
          onChange={onChangeVariableDataType}
          label={t('Variable Type')}
          options={variableTypeOptions}
          margin
        />
      </StyledCols>
      <StyledCols columns={2} gutter="20px">
        <AutomationSelect
          customObjectId={currentLoad?.custom_object!}
          selected={currentLoad?.newly_created_records_automations || []}
          onChange={onChangeAutomationsForCreated}
          label={t('Automation(s) to Start on Newly Created Records')}
          labelInfo={t(
            'Automations will only run once on records created per upload. The automation will start once the entire upload has finished processing.'
          )}
        />
        <AutomationSelect
          customObjectId={currentLoad?.custom_object!}
          selected={currentLoad?.other_matches_records_automations || []}
          onChange={onChangeAutomationsForMatched}
          label={t('Automation(s) to Start on Other Matched Records')}
          labelInfo={t(
            'Automations will only run once, and only on matched records that were not created as part of this upload. The automation will start once the entire upload has finished processing.'
          )}
        />
      </StyledCols>
    </>
  );
};
