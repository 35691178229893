import { DEFAULT_LABEL_INDICATOR } from 'components/DashboardGrid/utils';
import { METRIC_TYPES } from 'components/Wizards/shared/components/chart/types';
import DashletService from 'services/DashletService';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { ensureCents } from 'utility/numbers';
import { range } from 'utility/iterables';
import { FIELD_TYPES } from 'utility/constants';
import { getDefaultLabel } from 'components/Wizards/shared/components/BreakdownAndBucketing/shared/utils';
import { getHash } from 'utility/encode';

export function units(numString, config) {
  const { metricType } = config;
  if (
    [
      METRIC_TYPES.TOTAL_SELECTION_PERCENTAGE,
      METRIC_TYPES.EMAIL_DELIVERY_PERCENTAGE,
      METRIC_TYPES.EMAIL_OPT_OUT_PERCENTAGE,
      METRIC_TYPES.EMAIL_COMPLAINT_PERCENTAGE,
      METRIC_TYPES.EMAIL_OPEN_PERCENTAGE,
      METRIC_TYPES.EMAIL_CLICK_PERCENTAGE,
    ].includes(metricType)
  ) {
    return `${numString}%`;
  }

  if (DashletService.isRevenueV2(config)) {
    return `$${ensureCents(numString)}`;
  }

  return numString;
}

// This can be set to return `true` to enable the loading
// indicators while the dashlet charts are being recomputed.
export const getPendingDefaultValue = () => false;

// There are two potential return values from the API, both of which
// are used in the same way when rendering
export const getBreakdown = (dashlet) => {
  return (
    dashlet.config.metricTypeExtraInfo.fieldsRangeBreakdown ??
    dashlet.config.metricTypeExtraInfo.fieldsValueBreakdown
  );
};

export const buildChartData = ({
  data,
  dashlet,
  fieldData,
  fieldOptions,
  currencySymbol,
  t,
}) => {
  return (
    data?.data
      .map((d) => {
        const converted = snakeToCamelCaseKeys(d);
        const label = converted.bucketLabel || DEFAULT_LABEL_INDICATOR;
        const bucket = getBreakdown(dashlet).buckets.find(
          (b) => b.id === converted.id
        );

        if (
          dashlet.config.metricTypeExtraInfo.includeSummary &&
          d.type === 'summary'
        ) {
          return null;
        }

        let fieldOptionsResult = fieldOptions;
        if (fieldData?.fieldType === FIELD_TYPES.Rating.type) {
          const min = fieldData.rating.minValue;
          const max = fieldData.rating.maxValue;

          fieldOptionsResult = Array.from(range(min, max + 1), (value) => {
            return {
              label: String(value),
              value: value,
            };
          });
        }

        const values = bucket?.values;
        if (values) {
          const hasDeletedField = values.some((v) => {
            // Email Status field uses code instead of id for the backend
            return !fieldOptionsResult.find(
              (opt) => opt.id === v || opt.code === v || opt.value === v
            );
          });

          if (hasDeletedField) {
            return null;
          }
        }

        const resultingLabel =
          label === DEFAULT_LABEL_INDICATOR
            ? getDefaultLabel({
                min: bucket?.min?.value,
                max: bucket?.max?.value,
                values: bucket?.values,
                fieldOptions: fieldOptionsResult,
                currencySymbol,
                t,
              })
            : label;

        return {
          category:
            converted.id ||
            String(getHash(`${converted.value}-${resultingLabel}`)),
          type: converted.type,
          value: converted.value ?? 0,
          label: resultingLabel,
        };
      })
      .filter(Boolean) ?? []
  );
};
