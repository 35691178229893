import { useSelector } from 'react-redux';

export const useAutomationPermissions = ({
  objectId,
  isClient,
  entityObject,
}: {
  objectId?: string;
  isClient?: boolean;
  entityObject?: { access: { view: boolean; edit: boolean; remove: boolean } };
}): {
  canStart: boolean;
  canManage: boolean;
} => {
  const access = useSelector((s: any) => s.authentication.access);

  const hasEditAccessToEntity = Boolean(entityObject?.access?.edit);
  const canStartContactAutomations = Boolean(
    access?.contacts?.single_record_actions?.record_layout_start_automation
  );
  const canStartCustomObjectAutomations = Boolean(
    objectId &&
      access?.custom_objects?.custom_object_entities?.[objectId]
        ?.single_record_actions?.record_layout_start_automation
  );

  const hasStartAutomationsPermission = isClient
    ? canStartContactAutomations
    : canStartCustomObjectAutomations;

  return {
    canStart: hasStartAutomationsPermission && hasEditAccessToEntity,
    canManage: hasEditAccessToEntity,
  };
};
