import {
  camelToSnakeCaseKeys,
  fieldToOptionDeleted,
  getOptionValue,
  namedToOptionDeleted,
  snakeToCamelCase,
  specificTeamMemberToOptionDeleted,
} from 'services/helpers';

import {
  dateOffsetValuesTranslated,
  RECEIVING_INBOX,
  checkDeleted,
  displayNameDescriptor,
  nameDescriptor,
  getStageName,
  validStageId,
} from 'pages/AutomationEngine/helpers';

import stepConfig, { edgeLabels } from 'pages/AutomationEngine/steps';

import { triggerDisplay } from 'pages/AutomationEngine/steps';
import { isGroupFilter, isGroupInvalid } from 'ts-filters/legacy';

import {
  activityLoggedGoalDTO,
  scheduledActivityOverdueDTO,
  contactTagAddedRemovedDTO,
  emailReceivedGoalDTO,
  fieldUpdatedGoalDTO,
  onOrAroundDateDTO,
  formSubmittedGoalDTO,
  surveySubmittedGoalDTO,
  emailLinkClickedDTO,
  stageUpdatedGoalDTO,
} from 'pages/AutomationEngine/dialogs/GoalAndTriggerWizard/subsections/dto';

import {
  assignTeamMemberDTO,
  changeFieldValueDTO,
  changeTagsDTO,
  createRelatedEntityDTO,
  goToAutomationStepDTO,
  httpRequestDTO,
  mathOperatorDTO,
  modifyAutomationDTO,
  modifyRelatedEntitiesAutomationDTO,
  modifyRelatedEntitiesDTO,
  notifyMemberViaEmailDTO,
  notifyMemberViaTextDTO,
  scheduleActivityDTO,
  sendEmailDTO,
  sendRelatedContactEmailDTO,
} from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/dto';

// dto functions for delay and conditional so we can use a look up insted a bunch of if statements
const delayDTO = {
  forApi: (step) => {
    const { daysSpecificTime, minutes, hours, days, ...rest } = {
      ...step.details,
    };
    return {
      details: {
        ...rest,
        ...(minutes ? { minutes } : null),
        ...(hours ? { hours } : null),
        ...(days ? { days } : null),
      },
    };
  },
};

export const preProcessDescriptionQues = ({
  automation,
  descriptionFallback,
}) => {
  const steps = automation.steps.reduce(
    ({ steps, conditionIds, goalIds, delayIds, actionIds }, step) => {
      const { stepCondition } = step;
      if (step.stepType === 'goal') {
        return {
          steps: [...steps, step],
          conditionIds,
          goalIds: [...goalIds, step.id],
          delayIds,
          actionIds,
        };
      }
      if (step.stepType === 'delay') {
        return {
          steps: [...steps, step],
          conditionIds,
          goalIds,
          delayIds: [...delayIds, step.id],
          actionIds,
        };
      }
      if (step.stepType !== 'condition') {
        // actions
        return {
          steps: [...steps, step],
          conditionIds,
          goalIds,
          delayIds,
          actionIds: [...actionIds, step.id],
        };
      }
      // custom filter
      if (stepCondition?.filterConfig) {
        const { filterConfig } = stepCondition;

        const query = filterConfig.query.map((q, qIndex) => {
          const filters = q.filters.map(({ description, ...rest }, fIndex) => {
            return {
              ...rest,
              description:
                description ??
                (qIndex === 0 && fIndex === 0 ? descriptionFallback : ''),
            };
          });
          return { ...q, filters };
        });

        return {
          steps: [
            ...steps,

            {
              ...step,
              stepCondition: {
                ...stepCondition,
                filterConfig: { ...filterConfig, query },
              },
            },
          ],
          conditionIds: [...conditionIds, step.id],
          goalIds,
          delayIds,
          actionIds,
        };
      }

      // groups
      return {
        steps: [...steps, step],
        conditionIds: [...conditionIds, step.id],
        goalIds,
        delayIds,
        actionIds,
      };
    },
    { steps: [], conditionIds: [], goalIds: [], delayIds: [], actionIds: [] } // initial value
  );

  return {
    automation: { ...automation, steps: steps.steps },
    conditionIds: steps.conditionIds,
    goalIds: steps.goalIds,
    delayIds: steps.delayIds,
    actionIds: steps.actionIds,
  };
};

const conditionDTO = {
  forApi: (step) => {
    const { groups, filterConfig, ...rest } = {
      ...step.details,
    };
    const groupIds = (groups || []).map(({ id }) => id);
    return {
      details: {
        ...rest,
        ...(groupIds.length ? { groupIds } : null),
        ...(filterConfig ? { filterConfig } : null),
      },
    };
  },
  forFlow: (orgData, addErrorMessage, messageDictionary, model) => {
    let { type, groups, filterErrors, filterConfig } = orgData;

    if (isGroupFilter(type)) {
      // we need to make sure group goes down this path error or not
      if (isGroupInvalid(groups)) {
        addErrorMessage(
          messageDictionary.thereWasanErrorLoadingTheAssociatedGroup
        );
      }
    } else {
      // query keys need to be snake_case
      const query = camelToSnakeCaseKeys(filterConfig.query);

      filterConfig = { ...filterConfig, query };

      if (filterErrors || filterConfig?.invalid) {
        addErrorMessage(
          messageDictionary.thereWasanErrorLoadingTheAssociatedFilters
        );
      }
    }

    return { ...orgData, filterConfig };
  },
};
/*-------------------------------------------------------------------------------------------------

 Take the step from the api and transform for the flow

 the object name comes from the api but we match it by what's defined in the steps.js file
  i.e. api === math_operator {action|step} MathOperator

 */
const goalsForFlowList = {
  [stepConfig.activityLogged.type]: activityLoggedGoalDTO.forFlow,
  [stepConfig.scheduledActivityOverdue.type]:
    scheduledActivityOverdueDTO.forFlow,
  [stepConfig.contactTagAddedRemoved.type]: contactTagAddedRemovedDTO.forFlow,
  [stepConfig.fieldUpdated.type]: fieldUpdatedGoalDTO.forFlow,
  [stepConfig.emailReceived.type]: emailReceivedGoalDTO.forFlow,
  [stepConfig.onOrAroundDate.type]: onOrAroundDateDTO.forFlow,
  [stepConfig.formSubmitted.type]: formSubmittedGoalDTO.forFlow,
  [stepConfig.surveySubmitted.type]: surveySubmittedGoalDTO.forFlow,
  [stepConfig.emailLinkClicked.type]: emailLinkClickedDTO.forFlow,
  [stepConfig.stageUpdated.type]: stageUpdatedGoalDTO.forFlow,
};

export const stepForFlow = (apiStep, model, messageDictionary) => {
  // get the action||step objects as they comes from the api
  // add any new actions here !
  const {
    id,
    parentStepId,
    stepType,
    actionAssignTeamMember,
    actionChangeFieldValue,
    actionChangeTags,
    actionSendEmail,
    actionStartCancelAutomation,
    actionNotifyMemberViaEmail,
    actionNotifyMemberViaText,
    actionScheduleActivity,
    actionGoToAutomationStep,
    actionMathOperator,
    actionHttpRequest,
    actionModifyAutomation,
    actionModifyRelatedEntities,
    actionSendRelatedContactEmail,
    actionModifyRelatedEntitiesAutomation,
    actionCreateRelatedEntity,
    stepCondition,
    stepDelay,
    stepGoal,
    order,
    ...others
  } = apiStep;

  const errorList = [];
  const addErrorMessage = (errorMessage) => {
    errorList.push(errorMessage);
  };
  try {
    if (stepGoal) {
      const { triggers } = stepGoal;

      // using only first trigger for now
      const firstTrigger = triggers[0];
      delete stepGoal.triggers;
      apiStep.stepGoal.type = 'goal';

      Object.assign(
        stepGoal,
        goalsForFlowList[firstTrigger.triggerType](
          firstTrigger,
          addErrorMessage,
          messageDictionary,
          model
        )
      );

      // end of goals
    }
  } catch (error) {
    addErrorMessage(messageDictionary.automationFailedToLoad);
  }
  const details = {
    ...((actionChangeFieldValue &&
      changeFieldValueDTO.forFlow(
        actionChangeFieldValue,
        addErrorMessage,
        messageDictionary
      )) ||
      (actionChangeTags &&
        changeTagsDTO.forFlow(
          actionChangeTags,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionSendEmail &&
        sendEmailDTO.forFlow(
          actionSendEmail,
          addErrorMessage,
          messageDictionary
        )) ||
      actionStartCancelAutomation ||
      (actionNotifyMemberViaEmail &&
        notifyMemberViaEmailDTO.forFlow(
          actionNotifyMemberViaEmail,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionNotifyMemberViaText &&
        notifyMemberViaTextDTO.forFlow(
          actionNotifyMemberViaText,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionScheduleActivity &&
        scheduleActivityDTO.forFlow(
          actionScheduleActivity,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionAssignTeamMember &&
        assignTeamMemberDTO.forFlow(
          actionAssignTeamMember,
          addErrorMessage,
          messageDictionary,
          stepType
        )) ||
      (actionGoToAutomationStep &&
        goToAutomationStepDTO.forFlow(
          actionGoToAutomationStep,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionModifyAutomation &&
        modifyAutomationDTO.forFlow(
          actionModifyAutomation,
          addErrorMessage,
          messageDictionary
        )) ||
      (stepCondition &&
        conditionDTO.forFlow(
          stepCondition,
          addErrorMessage,
          messageDictionary,
          model
        )) ||
      stepGoal ||
      stepDelay ||
      (actionMathOperator &&
        mathOperatorDTO.forFlow(
          actionMathOperator,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionModifyRelatedEntities &&
        modifyRelatedEntitiesDTO.forFlow(
          actionModifyRelatedEntities,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionSendRelatedContactEmail &&
        sendRelatedContactEmailDTO.forFlow(
          actionSendRelatedContactEmail,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionModifyRelatedEntitiesAutomation &&
        modifyRelatedEntitiesAutomationDTO.forFlow(
          actionModifyRelatedEntitiesAutomation,
          addErrorMessage,
          messageDictionary
        )) ||
      (actionCreateRelatedEntity &&
        createRelatedEntityDTO.forFlow(
          actionCreateRelatedEntity,
          addErrorMessage,
          messageDictionary
        ))),
  };

  const config = stepConfig[snakeToCamelCase(stepType)];
  if (!config) {
    addErrorMessage(messageDictionary.deletedStep);
  }

  // all the action||step objects are added to the details
  return {
    step: {
      id,
      type: stepType,
      parentKey: parentStepId,
      fromLabel: '', // It is easier to work with labels colocated with parentKey than on the parent
      prefix: config?.prefix,
      goalType: config?.goalType || false,
      details,
      hasError: errorList.length ? errorList : false,
      ...others,
      ...(actionHttpRequest && {
        actionHttpRequest: httpRequestDTO.forFlow(actionHttpRequest),
      }),
    },
    error: errorList.length ? { id, errors: errorList } : null,
  };
};

/*-------------------------------------------------------------------------------------------------

 Take the step from the UI and transform for the api

 */

const goalForApi = {
  [stepConfig.activityLoggedGoal.type]: {
    forApi: activityLoggedGoalDTO.forApi,
    deleteGoalType: true,
  },
  [stepConfig.scheduledActivityOverdueGoal.type]: {
    forApi: scheduledActivityOverdueDTO.forApi,
    deleteGoalType: true,
  },
  [stepConfig.contactTagAddedRemovedGoal.type]: {
    forApi: contactTagAddedRemovedDTO.forApi,
    deleteGoalType: false,
  },
  [stepConfig.fieldUpdatedGoal.type]: {
    forApi: fieldUpdatedGoalDTO.forApi,
    deleteGoalType: false,
  },
  [stepConfig.emailReceivedGoal.type]: {
    forApi: emailReceivedGoalDTO.forApi,
    deleteGoalType: false,
  },
  [stepConfig.onOrAroundDateGoal.type]: {
    forApi: onOrAroundDateDTO.forApi,
    deleteGoalType: false,
  },
  [stepConfig.formSubmittedGoal.type]: {
    forApi: formSubmittedGoalDTO.forApi,
    deleteGoalType: false,
  },
  [stepConfig.surveySubmittedGoal.type]: {
    forApi: surveySubmittedGoalDTO.forApi,
    deleteGoalType: false,
  },
  [stepConfig.emailLinkClickedGoal.type]: {
    forApi: emailLinkClickedDTO.forApi,
    deleteGoalType: false,
  },
  [stepConfig.stageUpdated.type]: {
    forApi: stageUpdatedGoalDTO.forApi,
    deleteGoalType: false,
  },
  [stepConfig.stageUpdatedGoal.type]: {
    forApi: stageUpdatedGoalDTO.forApi,
    deleteGoalType: false,
  },
};

const actionForApi = {
  [stepConfig.assignTeamMember.type]: {
    forApi: assignTeamMemberDTO.forApi,
  },
  [stepConfig.changeFieldValue.type]: {
    forApi: changeFieldValueDTO.forApi,
  },
  [stepConfig.changeTags.type]: {
    forApi: changeTagsDTO.forApi,
  },
  [stepConfig.createRelatedEntity.type]: {
    forApi: createRelatedEntityDTO.forApi,
  },
  [stepConfig.goToAutomationStep.type]: {
    forApi: goToAutomationStepDTO.forApi,
  },
  [stepConfig.httpRequest.type]: {
    forApi: httpRequestDTO.forApi,
  },
  [stepConfig.mathOperator.type]: {
    forApi: mathOperatorDTO.forApi,
  },
  [stepConfig.modifyAutomation.type]: {
    forApi: modifyAutomationDTO.forApi,
  },
  [stepConfig.modifyRelatedEntitiesAutomation.type]: {
    forApi: modifyRelatedEntitiesAutomationDTO.forApi,
  },
  [stepConfig.modifyRelatedEntities.type]: {
    forApi: modifyRelatedEntitiesDTO.forApi,
  },
  [stepConfig.notifyMemberViaEmail.type]: {
    forApi: notifyMemberViaEmailDTO.forApi,
  },
  [stepConfig.notifyMemberViaText.type]: {
    forApi: notifyMemberViaTextDTO.forApi,
  },
  [stepConfig.scheduleActivity.type]: {
    forApi: scheduleActivityDTO.forApi,
  },
  [stepConfig.sendEmail.type]: {
    forApi: sendEmailDTO.forApi,
  },
  [stepConfig.sendRelatedContactEmail.type]: {
    forApi: sendRelatedContactEmailDTO.forApi,
  },
  // delay
  [stepConfig.delay.type]: {
    forApi: delayDTO.forApi,
  },
  [stepConfig.condition.type]: {
    forApi: conditionDTO.forApi,
  },
};

export const stepForApi = ({ parentKey, fromLabel, ...others }, order) => {
  const step = {
    ...others,
    order,
  };

  if (step.goalType) {
    // Goal section
    const details = {
      ...step.details,
    };

    if (goalForApi[details.type].deleteGoalType) {
      delete step.goalType;
    }

    Object.assign(step, goalForApi[details.type].forApi(details));
  } else {
    // Actions section
    Object.assign(step, actionForApi[step.type].forApi(step));
  }

  const { details, id, ...rest } = step;
  return {
    // we need id especially to update action and if it's not a new step
    ...(!id.startsWith('new') && {
      id,
    }),
    [`${step.prefix}_${step.type}`]: details,
    key: id,
    parentKey,
    ...rest,
    parentYesNo: fromLabel,
    parentCondition: fromLabel,
  };
};

/*-------------------------------------------------------------------------------------------------

 Take the trigger from the api and transform for the UI

 */

export const triggerForFlow = (trigger, model, messageDictionary) => {
  const {
    id,
    triggerType,
    triggerActivityLogged,
    triggerScheduledActivityOverdue,
    triggerEmailInteraction,
    triggerFieldUpdated,
    triggerFormSubmitted,
    triggerInteractionOccurred,
    triggerOnOrAroundDate,
    triggerSurveySubmitted,
    triggerContactTagAddedRemoved,
    triggerWebsiteVisited,
    triggerEmailReceivedFromContact,
    triggerEmailLinkClicked,
    triggerStageUpdated,
    ...others
  } = trigger;

  const errorList = [];
  const addErrorMessage = (errorMessage) => {
    errorList.push(errorMessage);
  };
  try {
    if (triggerContactTagAddedRemoved) {
      const { tag } = triggerContactTagAddedRemoved;
      if (!tag) {
        addErrorMessage(
          messageDictionary.thereWasanErrorLoadingTheAssociatedTag
        );
      } else {
        triggerContactTagAddedRemoved.tagId = namedToOptionDeleted(tag);
      }
    }

    if (triggerFieldUpdated?.field) {
      const { field } = triggerFieldUpdated;
      triggerFieldUpdated.field = fieldToOptionDeleted(field);
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        field,
        displayNameDescriptor
      );
    }

    if (triggerFormSubmitted?.form) {
      const { form } = triggerFormSubmitted;
      triggerFormSubmitted.formId = namedToOptionDeleted(form);
      checkDeleted(messageDictionary, addErrorMessage, form, nameDescriptor);
    }

    if (triggerActivityLogged?.activityType) {
      const { activityType } = triggerActivityLogged;
      triggerActivityLogged.activity = namedToOptionDeleted(activityType);
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        activityType,
        nameDescriptor
      );
    }

    if (triggerScheduledActivityOverdue?.activity) {
      const { activity } = triggerScheduledActivityOverdue;
      triggerScheduledActivityOverdue.activity = namedToOptionDeleted(activity);
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        activity,
        nameDescriptor
      );
    }

    if (triggerOnOrAroundDate) {
      const { field, period, dateOffsetDays } = triggerOnOrAroundDate;
      triggerOnOrAroundDate.fieldId = fieldToOptionDeleted(field);
      triggerOnOrAroundDate.period = period.toUpperCase();
      triggerOnOrAroundDate.dateOffsetDays = dateOffsetDays || null;
      checkDeleted(
        messageDictionary,
        addErrorMessage,
        field,
        displayNameDescriptor
      );
    }

    if (triggerSurveySubmitted?.survey) {
      const { survey } = triggerSurveySubmitted;
      triggerSurveySubmitted.surveyId = namedToOptionDeleted(survey);
      checkDeleted(messageDictionary, addErrorMessage, survey, nameDescriptor);
    }

    if (triggerEmailReceivedFromContact) {
      const { specificTeamRole, specificTeamMember, receivingInbox } =
        triggerEmailReceivedFromContact;

      if (receivingInbox === RECEIVING_INBOX.teamRole) {
        // if set to be specific_team_role and the role has been deleted then there is no specificTeamRole
        if (specificTeamRole) {
          const { inboxCriteria, role } = specificTeamRole;
          triggerEmailReceivedFromContact.specificTeamRole = {
            value: role.id,
            label: role.name,
          };
          triggerEmailReceivedFromContact.inboxCriteria = inboxCriteria;
        } else {
          // so add the generic error
          addErrorMessage(
            messageDictionary.thereWasanErrorLoadingTheAssociatedRole
          );
        }
      }

      if (specificTeamMember) {
        triggerEmailReceivedFromContact.specificTeamMember =
          specificTeamMemberToOptionDeleted(specificTeamMember);
        checkDeleted(
          messageDictionary,
          addErrorMessage,
          specificTeamMember,
          displayNameDescriptor
        );
      }
    }

    if (triggerStageUpdated) {
      const { fromStage, toStage } = triggerStageUpdated;
      if (!validStageId(fromStage, model) || !validStageId(toStage, model)) {
        addErrorMessage(
          messageDictionary.thereWasanErrorLoadingTheAssociatedStage
        );
      }
      triggerStageUpdated.fromStage = getStageName(
        fromStage,
        model,
        messageDictionary
      );
      triggerStageUpdated.toStage = getStageName(
        toStage,
        model,
        messageDictionary
      );
    }
  } catch (error) {
    addErrorMessage(messageDictionary.automationFailedToLoad);
  }

  const config = stepConfig[snakeToCamelCase(triggerType)];
  if (!config) {
    addErrorMessage(messageDictionary.deletedStep);
  }
  return {
    trigger: {
      id,
      type: triggerType,
      prefix: config?.prefix || triggerDisplay.prefix,
      details: {
        ...(triggerActivityLogged ||
          triggerScheduledActivityOverdue ||
          triggerEmailInteraction ||
          triggerFieldUpdated ||
          triggerFormSubmitted ||
          triggerInteractionOccurred ||
          triggerOnOrAroundDate ||
          triggerSurveySubmitted ||
          triggerContactTagAddedRemoved ||
          triggerWebsiteVisited ||
          triggerEmailReceivedFromContact ||
          triggerEmailLinkClicked ||
          triggerStageUpdated),
      },
      hasError: Boolean(errorList.length),
      ...others,
    },
    error: errorList.length ? { id, errors: errorList } : null,
  };
};

export const triggersForFlow = (apiTriggers, model, messageDictionary) => {
  const triggersWithError = orderSteps(apiTriggers).map((trigger) =>
    triggerForFlow(trigger, model, messageDictionary)
  );

  return {
    triggers: triggersWithError.map(({ trigger }) => ({ ...trigger })),
    triggerErrors: triggersWithError
      .filter(({ error }) => !!error)
      .map(({ error }) => error),
  };
};

export const triggerForApi = (trigger, order) => {
  const result = { ...trigger, order };

  if (trigger.type === stepConfig.contactTagAddedRemoved.type) {
    const { tagId, tagOperation } = result.details;
    result.details = {
      tagId: getOptionValue(tagId),
      tagOperation,
    };
  }
  if (trigger.type === stepConfig.fieldUpdated.type) {
    result.details = {
      fieldId: getOptionValue(trigger.details.field),
    };
  }
  if (trigger.type === stepConfig.formSubmitted.type) {
    result.details = {
      formId: getOptionValue(trigger.details.formId),
    };
  }
  if (trigger.type === stepConfig.activityLogged.type) {
    result.details = {
      activityTypeId: getOptionValue(trigger.details.activity),
    };
  }
  if (trigger.type === stepConfig.scheduledActivityOverdue.type) {
    result.details = {
      activity: { id: getOptionValue(trigger.details.activity) },
    };
  }
  if (trigger.type === stepConfig.onOrAroundDate.type) {
    const { fieldId, period, ...other } = result.details;
    if (
      other.dateOffset === dateOffsetValuesTranslated((str) => str)[0].value
    ) {
      delete other.dateOffsetDays;
    }
    result.details = {
      ...other,
      fieldId: getOptionValue(fieldId),
      period: period.toLowerCase(),
    };
  }

  if (trigger.type === stepConfig.surveySubmitted.type) {
    result.details = {
      surveyId: getOptionValue(trigger.details.surveyId),
    };
  }

  if (trigger.type === stepConfig.emailReceived.type) {
    const {
      receivingInbox,
      specificTeamRole,
      inboxCriteria,
      specificTeamMember,
    } = result.details;
    result.details = {
      receivingInbox,
      ...(specificTeamRole && {
        specificTeamRole: { roleId: specificTeamRole.value, inboxCriteria },
      }),
      ...(specificTeamMember && {
        specificTeamMemberId: specificTeamMember.value,
      }),
    };
  }

  if (trigger.type === stepConfig.stageUpdated.type) {
    result.details = {
      fromStage: getOptionValue(trigger.details.fromStage),
      toStage: getOptionValue(trigger.details.toStage),
    };
  }

  const getType = () => {
    if (trigger.type === stepConfig.emailReceived.type) {
      return `${trigger.type}_from_contact`;
    }

    return trigger.type;
  };

  const { details, id, ...rest } = result;
  return {
    // we need id especially to update action and if it's not a new trigger
    ...(!id.startsWith('new') && { id }),
    [`${trigger.prefix}_${getType()}`]: details,
    key: id,
    ...rest,
  };
};

export const automationForApi = ({
  automation: {
    id,
    name,
    active,
    revision,
    customObject,
    errorNotificationEmail,
    skipNonWorkingDays,
  },
  steps: autSteps,
  triggers,
}) => {
  return {
    id, // Should be empty during creation
    name,
    active,
    lastRevision: revision, // Should be undefined for creation
    customObjectId: customObject?.id ?? null,
    returnAllStepsErrors: true,
    errorNotificationEmail,
    skipNonWorkingDays,
    steps: autSteps.map(stepForApi),
    triggers: triggers.map(triggerForApi),
  };
};

// Maintaining the record order of steps and triggers helps to
// ensure that the ordering of branches in the automation flow
// don't change when the automation is saved. Note that this
// also works for triggers, not just steps.

export const orderSteps = (apiSteps) => {
  return [...apiSteps].sort((a, b) => {
    return (a.order || 0) - (b.order || 0);
  });
};

export const stepsForFlow = (apiSteps, model, messageDictionary) => {
  const labelsById = {};
  const stepsWithErrors = orderSteps(apiSteps).map((apiStep) => {
    // Map steps for flow
    const stepWithError = stepForFlow(apiStep, model, messageDictionary);
    Object.values(edgeLabels).forEach((label) => {
      // Keep track of edge labels
      []
        .concat(stepWithError.step.details[label.detailsKey] || [])
        .forEach((labeledStep) => {
          const labeledStepId =
            typeof labeledStep === 'string' ? labeledStep : labeledStep.id;
          labelsById[labeledStepId] = label.type;
        });
      delete stepWithError.step.details[label.detailsKey];
    });
    return stepWithError;
  });

  stepsWithErrors.forEach((stepWithError) => {
    // Mark steps with edge labels
    stepWithError.step.fromLabel =
      labelsById[stepWithError.step.id] || stepWithError.step.parentCondition;
  });

  return {
    steps: stepsWithErrors.map(({ step }) => ({ ...step })),
    stepErrors: stepsWithErrors
      .filter(({ error }) => !!error)
      .map(({ error }) => error),
  };
};

export const getChildrenSteps = (parentIds, steps) => {
  const children = steps.reduce((acc, item) => {
    if (parentIds.includes(item.parentKey)) {
      return [...acc, item.id];
    }
    return acc;
  }, []);

  return children.length
    ? [...children, ...getChildrenSteps(children, steps)]
    : children;
};

export const getBranchChildrenStep = (parentIds, fromLabel, steps) => {
  const children = steps.reduce((acc, item) => {
    if (
      parentIds.includes(item.parentKey) &&
      (!fromLabel || item.fromLabel === fromLabel)
    ) {
      return [...acc, item.id];
    }
    return acc;
  }, []);

  return children;
};

// Action steps that can't have children i.e. go to steps, can't be added in the tree where there can be no branchs i.e.
// after options when there are currently child steps of the option as these steps would get orphaned
// We handle this in a couple of ways:
// When draging an existing step we hide the [drop here] where they can't be dropped.
// If the user chooses to add an action where they can't exist we hide the goto step option in the action wizard
// If the user changes an existing action where a go to step can't exist we also hide the step option in the action wizard.

export const getAllowNoChildStep = (parentKey, stepKey, fromLabel, steps) => {
  // search in steps, if not find we assume it's a trigger which allows branches
  const parentStep = steps.find((s) => s.id === parentKey);

  // if a condition make sure the branch has no kids
  if (['condition', 'goal'].includes(parentStep?.type)) {
    const childSteps = getBranchChildrenStep([stepKey], fromLabel, steps);
    return childSteps.length === 0;
  }

  return true;
};
