import styled from '@emotion/styled';
import React, { useCallback, useRef, useState, forwardRef } from 'react';
import useSyncScroll from 'react-use-sync-scroll';
import { hideScrollbarCss } from 'app/spacing';
import { fontSizes } from 'app/typography';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import css from '@emotion/css';
import { applyRef } from 'components/Inputs/props';

const DEFAULT_OPTION_HEIGHT = 37;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
const ListWrapper = styled.div`
  position: relative;
`;
export const Content = styled.div`
  width: 100%;
  overflow: auto;
  ${hideScrollbarCss}

  div > ul > div:first-of-type > * {
    // remove font size style from any nested <span>, <p>, etc elements for the
    // loading/no options text so the below selector can control the font
    font-size: unset;
  }
  div > ul > div:first-of-type {
    // This targets a div with "Loading..." text that appears under the <ul>
    // when MenuList is used as a customization component for react-select
    font-size: ${fontSizes.text};
  }
`;
const StyledScrollBarDetached = styled(ScrollBarDetached)`
  position: absolute;
  top: 0;
  right: 0;
  ${({ height }) => css`
    height: ${height}px;
  `}
  ${({ top }) => css`
    top: ${top}px;
  `}
`;
export default forwardRef(({ children, topButton, ...props }, ref) => {
  const [height, setHeight] = useState();
  const [top, setTop] = useState(topButton ? DEFAULT_OPTION_HEIGHT : 0);

  // scrollbar
  const scrollbarContentWrapperRef = useRef();
  const [contentWrapperRefFn, contentWrapperRef] = useSyncSizes(
    scrollbarContentWrapperRef,
    '.ContentForSyncHeight',
    'height',
    [props],
    true
  );

  const contentRefFn = useCallback(
    (node) => {
      if (node) {
        if (node.previousElementSibling) {
          setTop(node.previousElementSibling.getBoundingClientRect().height);
        }
        setHeight(node.getBoundingClientRect().height);
      }
      contentWrapperRefFn(node);
    },
    [contentWrapperRefFn]
  );

  const mergeRef = useCallback(
    (el) => {
      applyRef(ref, el);
      applyRef(contentRefFn, el);
    },
    [ref, contentRefFn]
  );

  useSyncScroll(useRef([contentWrapperRef, scrollbarContentWrapperRef]), {
    vertical: true,
  });

  return (
    <>
      <Content ref={mergeRef}>
        <ListWrapper className="ContentForSyncHeight">
          <List {...props}>{children}</List>
        </ListWrapper>
      </Content>
      <StyledScrollBarDetached
        height={height}
        top={top}
        ref={scrollbarContentWrapperRef}
        direction="vertical"
        scrollClassName="ContentForSyncHeight"
      />
    </>
  );
});
