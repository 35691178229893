import { useCallback, useEffect, useRef } from 'react';
import { getFieldAccessPermissions } from 'components/Fields/FieldInput/helpers';
import { isEmailStatusImmutable } from 'components/Fields/helpers';
import { KEY_CODES } from './constants';
import { getEventKey } from './useCustomSelectMenuProps';
import { isMobile } from 'react-device-detect';

const getXScrollParentOffset = (
  el,
  prevEl = null,
  offset = 0,
  minIteration = 3
) => {
  if (!el || (el.scrollWidth > el.clientWidth && minIteration <= 0)) {
    return [el, offset];
  }
  return getXScrollParentOffset(
    el.parentNode,
    el,
    offset + ((prevEl && prevEl.offsetLeft) || 0),
    minIteration - 1
  );
};
const scrollX = (el, x) => {
  el.style.scrollBehavior = 'smooth';
  el.scrollLeft = x;
  el.style.scrollBehavior = null;
};

export const scrollFieldIntoView = (el) => {
  if (el) {
    const [scrollEl, offset] = getXScrollParentOffset(el);
    if (scrollEl) {
      // Nudge away from gradient fade-out on most tables
      scrollX(scrollEl, offset - 75);
    }
  }
};

const nextFocus = async (
  e,
  focusables,
  focused,
  handles,
  cursor,
  limit = 50
) => {
  const focusableItem = focusables.indexOf(focused.current);
  const nextFocusable = e?.shiftKey
    ? focusables[(focusableItem === -1 ? cursor.current : focusableItem) - 1] ||
      focusables.at(-1)
    : focusables[(focusableItem === -1 ? cursor.current : focusableItem) + 1] ||
      focusables[0];
  focused.current = nextFocusable;
  cursor.current = focusables.indexOf(nextFocusable);

  if (
    nextFocusable &&
    (!handles.current[nextFocusable] ||
      handles.current[nextFocusable]?.disabled) &&
    limit
  ) {
    await nextFocus(e, focusables, focused, handles, cursor, limit - 1);
  }
  if (!handles.current[nextFocusable]?.disabled) {
    const elem = await handles.current[nextFocusable]?.customFocus?.(e);
    // Scroll into view after focus
    if (elem) {
      await scrollFieldIntoView(elem);
    }
  }
};
const canFocusNext = (handles, focused) => {
  const handle = handles.current[focused.current];
  return handle?.shouldFocusNext !== undefined ? handle.shouldFocusNext : true;
};
const handleKeyEvent = async (e, focusables, focused, handles, cursor) => {
  const handle = handles.current[focused.current];
  switch (getEventKey(e)) {
    case KEY_CODES.tab.type: {
      e.preventDefault();
      if (handle) {
        const shouldStayFocused = await handle.customTab?.(e);
        if (shouldStayFocused === null) {
          return;
        }
        !shouldStayFocused &&
          (await nextFocus(e, focusables.current, focused, handles, cursor));
      }
      return;
    }
    case KEY_CODES.enter.type: {
      if (handle) {
        const shouldStayFocused = await handle.customEnter?.(e);
        if (shouldStayFocused === null) {
          return;
        }
        !shouldStayFocused &&
          canFocusNext(handles, focused) &&
          (await nextFocus(e, focusables.current, focused, handles, cursor));
      }
      return;
    }
    case KEY_CODES.arrowUp.type: {
      if (handle) {
        handle.customUp?.(e);
      }
      return;
    }
    case KEY_CODES.arrowDown.type: {
      if (handle) {
        handle.customDown?.(e);
      }
      return;
    }
    case KEY_CODES.arrowLeft.type: {
      if (handle) {
        handle.customLeft?.(e);
      }
      return;
    }
    case KEY_CODES.arrowRight.type: {
      if (handle) {
        handle.customRight?.(e);
      }
      return;
    }
    case KEY_CODES.esc.type: {
      if (handle) {
        handle.customEscape?.(e);
      }
      return;
    }
    case KEY_CODES.space.type: {
      if (handle) {
        handle.customSpace?.(e);
      }
      return;
    }
    case KEY_CODES.letter.type:
    case KEY_CODES.digit.type:
    case KEY_CODES.punctuation.type: {
      if (handle) {
        handle.customInput?.(e);
      }
      return;
    }
    default: {
      return;
    }
  }
};
const DEFAULT_VALIDATION_CALLBACK = (field, object) =>
  getFieldAccessPermissions(field, object).every(Boolean) &&
  !isEmailStatusImmutable(object, field);

export const useKeyListenersInline = (
  fields,
  object,
  validationCallback = DEFAULT_VALIDATION_CALLBACK,
  initialFocus = true
) => {
  const focusables = useRef([]);
  const fieldHandles = useRef({});
  const cursor = useRef(0);
  //possible values: undefined ( initial ), null, field-id
  const focused = useRef(undefined);

  const onFocus = useCallback(
    (id) => () => {
      cursor.current =
        focusables.current.indexOf(id) > 0 ? focusables.current.indexOf(id) : 0;
      focused.current = id;
    },
    []
  );

  const onBlur = useCallback(async (e, shouldFocusNext) => {
    if (shouldFocusNext && canFocusNext(fieldHandles, focused))
      await nextFocus(e, focusables.current, focused, fieldHandles, cursor);
    else focused.current = null;
  }, []);

  const assignFieldHandle = useCallback((id, el) => {
    if (id !== undefined && !isMobile)
      fieldHandles.current[id] = {
        ...(fieldHandles.current[id] || {}),
        ...el,
      };
  }, []);

  useEffect(() => {
    focusables.current = fields
      .filter((field) => validationCallback(field, object))
      .map(({ id }) => id);
  }, [fields, object, validationCallback]);

  useEffect(() => {
    const handler = (e) =>
      focused.current &&
      handleKeyEvent(e, focusables, focused, fieldHandles, cursor);
    document.addEventListener('keydown', (e) => handler(e), false);

    return () => document.removeEventListener('keydown', handler);
  }, []);

  useEffect(() => {
    // prevent any custom handler for mobile devices
    if (!isMobile) {
      if (!initialFocus) {
        return;
      }
      if (focused.current === undefined) {
        focused.current = fields[0]?.id;
        if (
          !fieldHandles?.current[focused?.current] ||
          fieldHandles?.current[focused?.current]?.disabled
        ) {
          nextFocus(
            { shiftKey: false },
            focusables.current,
            focused,
            fieldHandles,
            cursor
          );
        }
      }
      focused.current &&
        fieldHandles?.current[focused?.current]?.customFocus?.();
    }
  });
  /**
   *
   * @type {function(*): {onBlur: function(*, *): void, shouldUseKeyboardEventHandle: boolean, onFocus: function(): void, fieldId: *}}
   */
  const getKeyListenersProps = useCallback(
    (id) => {
      return {
        fieldId: id,
        shouldUseKeyboardEventHandle: true,
        onFocus: onFocus(id),
        onBlur: onBlur,
        cursor: cursor.current,
      };
    },
    [onBlur, onFocus]
  );
  const resetFocus = () => {
    focused.current = undefined;
  };

  const forceFocusToItem = (id) => {
    fieldHandles.current[id]?.customFocus?.();
  };

  return {
    onFocus,
    onBlur,
    assignFieldHandle,
    getKeyListenersProps,
    resetFocus,
    forceFocusToItem,
  };
};
