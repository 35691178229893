import { PRIVACY } from 'queries/query-keys';
import { useQuery } from 'react-query';
import TeamMemberService from 'services/TeamMemberService';

export const useBlockedEmailsQuery = (enabled) => {
  return useQuery(PRIVACY.BLOCKED_EMAILS, TeamMemberService.getBlockedEmails, {
    enabled,
    initialData: {
      blockIncomingEmailsFrom: [],
      blockOutgoingEmailsFrom: [],
      enableInternalEmailSync: false,
    },
  });
};
