import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getColumns } from '../../util';
import AccessRequestTableOthers from 'components/AccessRequests/Tables/Others';

const DashboardSettingsAccessRequests = (props) => {
  const { t } = useTranslation();

  return (
    <AccessRequestTableOthers
      {...props}
      getColumns={getColumns}
      entitySingular={t('Dashboard')}
      entityPlural={t('Dashboards')}
    />
  );
};

DashboardSettingsAccessRequests.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
};

export default DashboardSettingsAccessRequests;
