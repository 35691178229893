import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';

import { PageContentContainer } from 'components/Layout/PageContentWidth';

import { StyledCard } from 'components/ErrorMessage/styles';
import { getErrorComoponents } from 'components/CatchError/CatchError';

const PermissionsNeeded = ({ props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const PERMISSIONS_NEEDED_CODE = 403;

  const errorComoponents = useMemo(
    () => getErrorComoponents(PERMISSIONS_NEEDED_CODE, t),
    [PERMISSIONS_NEEDED_CODE, t]
  );

  return (
    <PageContentContainer>
      <StyledCard>
        <KizenTypography as="h1" type="hero">
          {errorComoponents.title}
        </KizenTypography>

        {errorComoponents.text}
        {errorComoponents.button || (
          <Button
            color="blue"
            className="not_found_button"
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            {t('Dashboard')}
          </Button>
        )}

        {errorComoponents.supportEmail}

        {PERMISSIONS_NEEDED_CODE ? (
          <KizenTypography as="p" className="not_found_text">
            {t('Error code')}: {PERMISSIONS_NEEDED_CODE}
          </KizenTypography>
        ) : (
          <KizenTypography as="p" className="not_found_text">
            {t('General error')}
          </KizenTypography>
        )}
      </StyledCard>
    </PageContentContainer>
  );
};

export default PermissionsNeeded;
