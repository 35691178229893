import Menu, { ApplyMenuButton, ClearMenuButton } from 'components/Kizen/Menu';
import { useTranslation } from 'react-i18next';
import { FIELD_TYPES } from 'utility/constants';
import useField from 'hooks/useField';
import WholeNumberInput from 'components/Inputs/TextInput/presets/WholeNumber';
import { useFlashValidation } from 'hooks/useFlashState';
import DecimalNumberTextInput from 'components/Inputs/TextInput/presets/DecimalNumber';
import PriceNumberTextInput from 'components/Inputs/TextInput/presets/PriceNumber';
import { StyledNumberRangeRow } from '../styles';
import { isPercentageChanceToCloseField } from 'checks/fields';
import { useAutoFocus } from '../hooks/useAutoFocus';

export const NumberFieldRangeInput = ({
  field,
  onChange,
  error,
  onHide,
  value,
  isReadOnly,
  autoFocus,
}) => {
  const { t } = useTranslation();
  const [validate, showError, hideError] = useFlashValidation();
  const [innerValue, setInnerValue] = useField(
    [
      value?.[0] === 0 ? 0 : value?.[0] || null,
      value?.[1] === 0 ? 0 : value?.[1] || null,
    ],
    [value]
  );

  const onFirstValueChange = ({ floatValue }) => {
    hideError();
    setInnerValue([
      floatValue === undefined ? null : floatValue,
      innerValue[1],
    ]);
  };

  const onSecondValueChange = ({ floatValue }) => {
    hideError();
    setInnerValue([
      innerValue[0],
      floatValue === undefined ? null : floatValue,
    ]);
  };

  const handleClickApply = () => {
    if (innerValue.length === 2 && !innerValue.every((v) => v === null)) {
      if (
        innerValue.some((v) => v === null) ||
        innerValue[0] <= innerValue[1]
      ) {
        onHide();
        onChange(innerValue);
      } else {
        showError(t('The minimum value must be less than the maximum.'));
      }
    } else {
      showError(t('Required field'));
    }
  };

  const handleClickClear = () => {
    onHide();
    onChange();
  };

  const Input =
    field.fieldType === FIELD_TYPES.Decimal.type
      ? DecimalNumberTextInput
      : field.fieldType === FIELD_TYPES.Money.type
        ? PriceNumberTextInput
        : WholeNumberInput;

  const isPercentage = isPercentageChanceToCloseField(field);

  const setInputElement = useAutoFocus(null, isReadOnly || !autoFocus);
  const getRefToInput = (el) => setInputElement(el?.querySelector('input'));

  return (
    <Menu
      leftButton={
        isReadOnly ? null : (
          <ClearMenuButton onClick={handleClickClear}>
            {t('Clear & Close')}
          </ClearMenuButton>
        )
      }
      rightButton={
        isReadOnly ? null : <ApplyMenuButton onClick={handleClickApply} />
      }
    >
      <StyledNumberRangeRow>
        <Input
          ref={getRefToInput}
          label={t('Minimum')}
          value={innerValue[0]}
          error={error}
          onValueChange={onFirstValueChange}
          minValue={isPercentage ? 0 : undefined}
          maxValue={isPercentage ? 100 : undefined}
          validate={validate}
          currencySymbol={field.moneyOptions?.symbol}
          disabled={isReadOnly}
        />
        <Input
          label={t('Maximum')}
          value={innerValue[1]}
          error={error || validate.message}
          onValueChange={onSecondValueChange}
          minValue={isPercentage ? 0 : undefined}
          maxValue={isPercentage ? 100 : undefined}
          currencySymbol={field.moneyOptions?.symbol}
          disabled={isReadOnly}
        />
      </StyledNumberRangeRow>
    </Menu>
  );
};
