import { useRef, useEffect } from 'react';
import { NewTRow } from '../../Kizen/Table';
import styled from '@emotion/styled';

const StyledTrow = styled(NewTRow)`
  box-sizing: border-box;
`;
const TableRow = ({ index, item, columns, onMount, ...props }) => {
  const itemWrapper = useRef(null);
  const mountedRef = useRef(false);

  useEffect(() => {
    const wrapper = itemWrapper.current;
    // only do the mounted call back once
    if (itemWrapper.current && !mountedRef.current) {
      mountedRef.current = true;
      onMount({ index, ...item }, wrapper.getBoundingClientRect());
    }
  }, [index, item, onMount]);

  return (
    <StyledTrow
      ref={itemWrapper}
      key={item.id}
      columns={columns}
      data={item}
      {...props}
    />
  );
};

export { TableRow };
