export const getContactSettingOptions = (t, objectSettingsAccess) => [
  {
    value: 'contactSettings',
    label: t('Object Settings'),
  },
  objectSettingsAccess.customizeFields.edit && {
    value: 'customizeFields',
    label: t('Customize Fields'),
  },
];
