import React, { useCallback, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from '@emotion/styled';
import css from '@emotion/css';

import { grayScale } from 'app/colors';
import { WYSIWYG, useEditor } from './editor';
import {
  BasicToolBar,
  FloatingToolbar,
} from '../StandaloneToolBar/presets/WysiwygToolbar';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { colorsButton } from 'app/colors';
import { applyRef } from 'components/Inputs/props';

const DEFAULT_LINE_HEIGHT = 1.25;

export const defaultFonts = [
  { label: 'Arial', value: 'Arial' },
  { label: 'Courier New', value: 'Courier New' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Helvetica Neue', value: 'Helvetica Neue' },
  { label: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode' },
  { label: 'Tahoma', value: 'Tahoma' },
  { label: 'Times New Roman', value: 'Times New Roman' },
  { label: 'Verdana', value: 'Verdana' },
];

const EditorWrapper = styled.div`
  ${({ borderColor }) => css`
    & > div:first-child > div {
      border-color: ${borderColor};
    }
    & > div:last-child {
      border-color: ${borderColor};
    }
  `}
  &:hover {
    ${({ borderHoverColor }) =>
      borderHoverColor &&
      css`
        & > div:first-child > div {
          border-top-color: ${borderHoverColor};
          border-left-color: ${borderHoverColor};
          border-right-color: ${borderHoverColor};
        }
        & > div:last-child {
          border-color: ${borderHoverColor};
        }
      `}
  }
`;

/**
 * Depending on usages through the application,
 * we can either have preset editors like we see here, or
 * we can have custom editors written up like this in their
 * respective locations in the app.
 */

export const BasicRichTextEditor = React.forwardRef(
  (
    {
      className,
      fieldId,
      onChange,
      onClickAway,
      onBlur,
      onFocus,
      onInit,
      autofocus,
      placeholder,
      initialValue,
      fonts,
      bottomBarNode,
      defaultFontFamily,
      defaultFontSize,
      children,
      mergeFields = null,
      multipleMergeFields = null,

      disabled = false,
      borderColor = grayScale.medium,
      borderHoverColor = '',
      textLinkOptions = [],
      scriptExecutionOptions = [],
      fitToContent = false,
      initialHeight = 200,
      enableResize = true,
      enableAI = false,
      enableFontFamily = false,
      enableFontSize = false,
      enableLineHeight = false,
      defaultLineHeight = DEFAULT_LINE_HEIGHT,
      enableColor = false,
      enableHighlight = false,
      enableLinks = false,
      enableMergeFields = false,
      enableTextAlign = false,
      enableClearFormatting = false,
      enableLists = true,
      enableTextStyles = true,
      enableToolbar = true,
      enableHorizontalRule = false,
      enableHistoryControls = true,
      mentionConfig = null,
      appendMergeFieldExtraSpace = true,
      onToggleEncoding = null,
      encodeMergeFieldsInBody = true,
    },
    ref
  ) => {
    const { assignFieldHandle } = useKeyBoardContext();
    const noteRef = useRef();
    const [focused, setFocused] = useState(borderColor);

    const mergeRef = useCallback(
      (el) => {
        applyRef(noteRef, el);
        applyRef(ref, el);
      },
      [ref]
    );

    assignFieldHandle(fieldId, {
      customFocus: () => {
        noteRef.current?.editor?.commands?.focus();
        return noteRef.current?.editor;
      },
      customEnter: (e) => {
        if (!noteRef.current) {
          setFocused(null);
          return false;
        } else {
          setFocused(!(e.ctrlKey + e.metaKey) ? colorsButton.blue.hover : null);
          return !(e.ctrlKey + e.metaKey);
        }
      },
      disabled,
    });

    const handleOnFocus = useCallback(
      (e) => {
        fieldId && setFocused(colorsButton.blue.hover);
        onFocus && onFocus(e);
      },
      [fieldId, onFocus]
    );

    const handleOnBlur = useCallback(
      (e) => {
        fieldId && setFocused(null);
        onBlur && onBlur(e);
      },
      [fieldId, onBlur]
    );

    return (
      <EditorWrapper
        className={className}
        borderHoverColor={borderHoverColor}
        borderColor={focused}
      >
        <WYSIWYG
          fitToContent={fitToContent}
          onChange={onChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          onInit={onInit}
          autofocus={autofocus}
          disabled={disabled}
          ref={mergeRef}
          placeholder={placeholder}
          initialValue={initialValue}
          initialHeight={initialHeight}
          bottomBarNode={bottomBarNode}
          defaultFontFamily={defaultFontFamily}
          defaultFontSize={defaultFontSize}
          defaultLineHeight={defaultLineHeight}
          enableResize={enableResize}
          enableAI={enableAI}
          enableFontFamily={enableFontFamily}
          enableFontSize={enableFontSize}
          enableLineHeight={enableLineHeight}
          enableColor={enableColor}
          enableHighlight={enableHighlight}
          enableLinks={enableLinks}
          enableMergeFields={enableMergeFields}
          mergeFields={mergeFields}
          multipleMergeFields={multipleMergeFields}
          enableLists={enableLists}
          enableHorizontalRule={enableHorizontalRule}
          enableTextAlign={enableTextAlign}
          enableTextStyles={enableTextStyles}
          enableToolbar={enableToolbar}
          mentionConfig={mentionConfig}
        >
          {enableToolbar ? (
            <BasicToolBar
              enableAI={enableAI}
              enableTextStyles={enableTextStyles}
              enableColor={enableColor}
              enableHighlight={enableHighlight}
              enableFontSize={enableFontSize}
              defaultFontSize={defaultFontSize}
              enableFontFamily={enableFontFamily}
              fonts={fonts}
              defaultFontFamily={defaultFontFamily}
              enableLineHeight={enableLineHeight}
              defaultLineHeight={defaultLineHeight}
              enableLists={enableLists}
              enableTextAlign={enableTextAlign}
              enableLinks={enableLinks}
              textLinkOptions={textLinkOptions}
              scriptExecutionOptions={scriptExecutionOptions}
              enableClearFormatting={enableClearFormatting}
              enableMergeFields={enableMergeFields}
              enableHistoryControls={enableHistoryControls}
              mergeFields={mergeFields}
              multipleMergeFields={multipleMergeFields}
              appendMergeFieldExtraSpace={appendMergeFieldExtraSpace}
              onToggleEncoding={onToggleEncoding}
              encodeMergeFieldsInBody={encodeMergeFieldsInBody}
            />
          ) : null}
          {children}
        </WYSIWYG>
      </EditorWrapper>
    );
  }
);

export const BasicPlainTextEditor = ({
  onChange,
  onBlur,
  onFocus,
  autofocus,
  placeholder,
  initialValue,
  children,
}) => (
  <WYSIWYG
    fitToContent={false}
    onChange={onChange}
    onBlur={onBlur}
    onFocus={onFocus}
    autofocus={autofocus}
    placeholder={placeholder}
    initialValue={initialValue}
    enableResize={false}
  >
    {children}
  </WYSIWYG>
);

/**
 * @remarks `zIndex`, `maxToolbarRows`, and `target` are only used when `asOverlay` is true.
 * Special care must be taken to ensure the onBlur callback is called only when the toolbar active state
 * will be set to false (close toolbar). Interacting with dropdowns and popups (color pickers) within the
 * toolbar will steal focus and cause the text input's blur event to fire. In these cases (when toolbarActive
 * is set to true), we save the blur event's arguments to manually call onBlur in handleClickAway. blurArgs are
 * cleared on focus to avoid calling onBlur twice.
 */
export const FloatingRichTextEditor = React.forwardRef(
  (
    {
      onChange,
      onBlur,
      onFocus,
      autofocus = false,
      placeholder,
      initialValue,
      fonts,
      defaultFontFamily,
      defaultFontSize,
      defaultLineHeight = DEFAULT_LINE_HEIGHT,
      children,
      zIndex,
      maxToolbarRows,
      onFontFamilyChange,
      asOverlay = false,
      persistBorder = false,
      textLinkOptions = [],
      scriptExecutionOptions = [],
      enableAI = false,
      enableFontFamily = false,
      enableFontSize = false,
      enableLineHeight = false,
      enableColor = false,
      enableHighlight = false,
      enableHistoryControls = true,
      enableLinks = false,
      enableMergeFields = false,
      mergeFields = null,
      multipleMergeFields = null,
      enableClearFormatting = false,
      allowRelativeLinks = false,
    },
    ref
  ) => {
    const editorRef = useRef();
    const blurArgs = useRef(null);
    const toolbarActive = useRef(false);
    const [editorActive, setEditorActive] = useState(autofocus);

    const refCallback = useCallback(
      (editor) => {
        ref.current = editor;
        editorRef.current = editor;
      },
      [ref]
    );

    const handleFocus = useCallback(
      (args) => {
        toolbarActive.current = false;
        blurArgs.current = null;
        setEditorActive(true);
        onFocus?.(args);
      },
      [onFocus]
    );

    const handleBlur = useCallback(
      (args) => {
        if (!toolbarActive.current) {
          onBlur?.(args);
        } else {
          blurArgs.current = args;
        }
      },
      [onBlur]
    );

    const handleClickAway = useCallback(() => {
      if (editorActive && !toolbarActive.current) {
        setEditorActive(false);
        if (blurArgs.current) {
          handleBlur(blurArgs.current);
        }
      }
    }, [handleBlur, editorActive]);

    useClickAway(editorRef.current?.containerRef ?? {}, handleClickAway, [
      'mousedown',
    ]);

    return (
      <WYSIWYG
        floating
        ref={refCallback}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        showBorder={persistBorder || editorActive}
        autofocus={autofocus}
        zIndex={zIndex}
        placeholder={placeholder}
        initialValue={initialValue}
        defaultFontFamily={defaultFontFamily}
        defaultFontSize={defaultFontSize}
        defaultLineHeight={defaultLineHeight}
        enableAI={enableAI}
        enableFontFamily={enableFontFamily}
        enableLineHeight={enableLineHeight}
        enableFontSize={enableFontSize}
        enableColor={enableColor}
        enableHighlight={enableHighlight}
        enableLinks={enableLinks}
        enableMergeFields={enableMergeFields}
        mergeFields={mergeFields}
        multipleMergeFields={multipleMergeFields}
        enableLists
        enableTextAlign
        enableTextStyles
      >
        {editorActive && (
          <FloatingToolbar
            target={ref.current?.containerRef?.current}
            asOverlay={asOverlay}
            zIndex={zIndex}
            maxToolbarRows={maxToolbarRows}
            toolbarActive={toolbarActive}
            enableColor={enableColor}
            enableHighlight={enableHighlight}
            enableFontSize={enableFontSize}
            defaultFontSize={defaultFontSize}
            enableLineHeight={enableLineHeight}
            defaultLineHeight={defaultLineHeight}
            enableFontFamily={enableFontFamily}
            enableHistoryControls={enableHistoryControls}
            fonts={fonts}
            enableAI={enableAI}
            defaultFontFamily={defaultFontFamily}
            enableLinks={enableLinks}
            textLinkOptions={textLinkOptions}
            scriptExecutionOptions={scriptExecutionOptions}
            enableClearFormatting={enableClearFormatting}
            enableMergeFields={enableMergeFields}
            mergeFields={mergeFields}
            multipleMergeFields={multipleMergeFields}
            onFontFamilyChange={onFontFamilyChange}
            allowRelativeLinks={allowRelativeLinks}
          />
        )}
        {children}
      </WYSIWYG>
    );
  }
);

export const mergeFieldsOptionsToItems = (mergeFields) =>
  mergeFields.map(({ key, label, options: items }) => ({ key, label, items }));

export * from './ai-context';
export { useEditor };
export { TextViewer } from './viewer';
