import { useContext, useRef, useState } from 'react';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import { KizenTypography } from 'app/typography';
import { isCellInLastRow } from 'components/Calendar';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { WEEKDAYS_SHORT } from 'utility/datetime';
import { BusinessCalendarContext } from './CalendarContext';
import { useTranslation } from 'react-i18next';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';
import ScrollBarDetached from 'components/Layout/ScrollBarDetached';
import {
  StyledGradient,
  StyledCalendarPill,
  EventsContainer,
  EventsScrollContainer,
  EventsScrollContainerWrapper,
  MonthCellHeader,
  SelectableDateCell,
  ExpandedMonthCellHeader,
  StyledCloseButton,
  ExpandedDayOfMonth,
} from './styled';
import {
  EVENT_PILL_HEIGHT,
  COLLAPSED_HEADER_HEIGHT,
  EXPANDED_HEADER_HEIGHT,
  MAX_EVENTS_EXPANDED,
  TRANSITION_DURATION,
  WIDTH_EXPANSION,
} from './constants';

const headerIconColor = {
  default: grayScale.mediumDark,
  hover: grayScale.dark,
};

export const buildMonthCell =
  ({ onCellClick, loading }) =>
  ({ date, calendarMonth, calendarYear, rowHeight, index, total, ...rest }) => {
    const { t } = useTranslation();
    const { holidaysLookup, skipLookup, skipWeekdays } = useContext(
      BusinessCalendarContext
    );
    const [selected, setSelected] = useState(false);
    const [transitionComplete, setTransitionComplete] = useState(false);
    const events = holidaysLookup[date.toDateString()] || [];
    const skipped = skipLookup[date.toDateString()];
    const skippedByWeekday = skipWeekdays.includes(date.getDay());

    const eventPills = events.map((holiday) => (
      <StyledCalendarPill key={holiday.name} name={holiday.name} />
    ));
    const isLastRow = isCellInLastRow(index, total);
    const scrollable = selected
      ? eventPills.length > MAX_EVENTS_EXPANDED
      : eventPills.length * EVENT_PILL_HEIGHT + COLLAPSED_HEADER_HEIGHT >
        rowHeight;
    const heightExpansion =
      EVENT_PILL_HEIGHT * Math.min(MAX_EVENTS_EXPANDED, eventPills.length) +
      EXPANDED_HEADER_HEIGHT +
      gutters.spacing() -
      rowHeight;
    const expansion = [
      WIDTH_EXPANSION,
      Math.max(WIDTH_EXPANSION / 2, heightExpansion),
    ];
    const gradientColor =
      date.getMonth() === calendarMonth ? grayScale.white : grayScale.light;

    const scrollbarRef = useRef();
    const [refFn, scrollRef] = useSyncSizes(
      scrollbarRef,
      '.ContentForSyncHeight',
      'height'
    );
    useSyncScroll(useRef([scrollRef, scrollbarRef]), {
      vertical: true,
    });
    // specifying scrollable as a dependency is necessary to get the gradient to appear on initial render
    const scrolled = useEndsOfScroll(scrollRef, [
      events,
      scrollable,
      selected,
      expansion,
    ]);

    const open = () => {
      setSelected(true);
      setTimeout(() => setTransitionComplete(true), TRANSITION_DURATION);
    };

    const close = () => {
      setSelected(false);
      setTransitionComplete(false);
    };

    return (
      <SelectableDateCell
        index={index}
        total={total}
        expanded={selected}
        expansion={expansion}
        translation={[-WIDTH_EXPANSION / 2, -WIDTH_EXPANSION / 2]}
        date={date}
        calendarMonth={calendarMonth}
        calendarYear={calendarYear}
        rowHeight={rowHeight}
        pinBottom={isLastRow}
        skipped={
          skipped !== false && (skipped || skippedByWeekday || events.length)
        }
        {...rest}
      >
        {(!selected || !transitionComplete) && (
          <MonthCellHeader
            date={date}
            calendarMonth={calendarMonth}
            expanded={selected}
          >
            {scrollable && (
              <IconButton
                sizing="dense"
                title={t('Expand')}
                color={headerIconColor}
                disabled={loading}
                onClick={open}
              >
                <IconSizing size="10px">
                  <Icon icon="external-link" />
                </IconSizing>
              </IconButton>
            )}
          </MonthCellHeader>
        )}
        {selected && transitionComplete && (
          <ExpandedMonthCellHeader expanded={selected}>
            <StyledCloseButton
              sizing="dense"
              color={headerIconColor}
              title={t('Close')}
            >
              <IconSizing size="12px">
                <Icon icon="close-cross" onClick={close} />
              </IconSizing>
            </StyledCloseButton>
            <KizenTypography>
              {WEEKDAYS_SHORT[date.getDay()].toUpperCase()}
            </KizenTypography>
            <ExpandedDayOfMonth type="subheader">
              {date.getDate()}
            </ExpandedDayOfMonth>
          </ExpandedMonthCellHeader>
        )}
        <EventsContainer
          expanded={selected}
          rowHeight={rowHeight}
          expansion={expansion}
        >
          <EventsScrollContainerWrapper>
            <EventsScrollContainer
              ref={refFn}
              scrolled={scrolled}
              gradient={<StyledGradient color={gradientColor} />}
              top={scrollable}
              bottom={scrollable}
              expanded={selected}
            >
              <div className="ContentForSyncHeight">{eventPills}</div>
            </EventsScrollContainer>
            <ScrollBarDetached
              ref={scrollbarRef}
              direction="vertical"
              scrollClassName="ContentForSyncHeight"
            />
          </EventsScrollContainerWrapper>
        </EventsContainer>
      </SelectableDateCell>
    );
  };
