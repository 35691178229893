import { useSelector } from 'react-redux';
import { getCustomObjectEntitiesAccess } from 'store/authentication/selectors';
import RelationshipOneV2 from 'components/Fields/FieldInput/RelationshipOneV2';
import ContactSelector from 'components/Modals/ActivityCompletionModal/ContactSelector';
import {
  CONTACT_SELECTOR,
  CUSTOM_SELECTOR,
} from 'components/Charts/ScheduledActivities/assignmentHelpers';
import { AssociationFields, StyledLoader } from './styled';
import ChunkRender from 'components/ChunkRender';
import { useTranslation } from 'react-i18next';

const KEY_LISTENER_DEFAULT = () => ({});

export const ActivityAssosiationFields = ({
  assignments,
  getKeyListenersProps = KEY_LISTENER_DEFAULT,
  activityRelatedObjects,
  loading,
  handleChange,
}) => {
  const { t } = useTranslation();
  const globalCOAccess = useSelector(getCustomObjectEntitiesAccess);
  return (
    <StyledLoader loading={loading}>
      <AssociationFields>
        <ChunkRender>
          {activityRelatedObjects.map((act) => {
            const { type, getterProp, setterProp } = act.component;
            const value = assignments[getterProp];
            if (type === CUSTOM_SELECTOR) {
              return (
                <RelationshipOneV2
                  key={act.id}
                  {...getKeyListenersProps(act.id)}
                  disableTopMenuButton={!globalCOAccess[act.id]?.canCreate}
                  label={act.objectName || act.description || act.modelName}
                  field={act}
                  value={value}
                  onChange={handleChange(setterProp)}
                  margin
                  disabled={!act.access.edit}
                  labelInfo={
                    !act.access.edit
                      ? t(
                          'You do not have permission to this object. Please contact your administrator to increase your access level.'
                        )
                      : null
                  }
                />
              );
            }
            if (type === CONTACT_SELECTOR) {
              return (
                <ContactSelector
                  field={act}
                  key={act.id}
                  {...getKeyListenersProps(act.id)}
                  label={act.objectName || act.description || act.modelName}
                  value={value}
                  onSetClientOption={handleChange(setterProp)}
                  disabled={!act.access.edit}
                  labelInfo={
                    !act.access.edit
                      ? t(
                          'You do not have permission to this object. Please contact your administrator to increase your access level.'
                        )
                      : null
                  }
                  margin
                />
              );
            }
            return null;
          })}
        </ChunkRender>
      </AssociationFields>
    </StyledLoader>
  );
};
