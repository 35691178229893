import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { pickBy } from 'lodash';
import { TextEllipsisWithTooltip } from '../../Kizen/Table';
import { breakpoints, gutters } from '../../../app/spacing';
import { colorsButton, colorsText } from '../../../app/colors';
import { fontWeights } from '../../../app/typography';
import Icon, { IconSizing } from '../../Kizen/Icon';
import { useTooltip } from '../../Kizen/Tooltip';

export const mobileWidth = 263;

export const desktopWidth = 395;

function BaseLabel(props) {
  return <TextEllipsisWithTooltip as="label" {...props} />;
}

const BaseInputControl = styled.div`
  display: flex;
  flex-direction: column;
  ${({ sizing, margin }) => css`
    ${margin === 'dense' &&
    css`
      margin-bottom: ${gutters.spacing(2)}px;
    `}
    ${margin === 'normal' &&
    css`
      margin-bottom: ${gutters.spacing(4)}px;
    `}
    ${typeof margin === 'number' &&
    css`
      margin-bottom: ${gutters.spacing(margin)}px;
    `}
    ${sizing === 'mobile' &&
    css`
      width: ${mobileWidth}px;
    `}
    ${sizing === 'desktop' &&
    css`
      width: ${desktopWidth}px;
    `}
    ${sizing === 'auto' &&
    css`
      width: ${mobileWidth}px;
      @media (min-width: ${breakpoints.sm}px) {
        width: ${desktopWidth}px;
      }
    `}
  `}
`;

// Spacing, sizing, and label for outline inputs

export const OutlineLabel = styled(BaseLabel, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})`
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
  color: ${({ labelColor }) => labelColor || colorsText.dark};
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${colorsText.medium};
    `}
`;

const InfoIconStyles = styled(IconSizing)`
  margin-left: ${gutters.spacing(2)}px;
  vertical-align: middle;
`;

export const StyledLabel = styled(TextEllipsisWithTooltip)`
  display: inline-block;
  ${({ isRequired }) =>
    isRequired
      ? css`
          &:after {
            content: '*';
            display: inline-block;
            width: 1em;
            text-align: right;
          }
        `
      : ''}
`;

export function OutlineInputControl({
  label,
  labelInfo,
  labelInfoPlacement,
  labelColor,
  sizing,
  margin,
  disabled,
  children,
  ...props
}) {
  if (margin === true) {
    margin = 'normal';
  }
  const [tooltipProps, tooltip] = useTooltip({
    label: labelInfo,
    placement: labelInfoPlacement,
  });
  return (
    <BaseInputControl margin={margin} sizing={sizing} {...props}>
      {label && (
        <OutlineLabel disabled={disabled} labelColor={labelColor}>
          <StyledLabel as={'span'} isRequired={props.isRequired}>
            {label}
          </StyledLabel>
          {labelInfo && (
            <InfoIconStyles size="10px">
              <Icon
                icon="info-circle"
                color={colorsButton.iconGray.hover}
                {...tooltipProps}
              />
            </InfoIconStyles>
          )}
          {tooltip}
        </OutlineLabel>
      )}
      {children}
    </BaseInputControl>
  );
}

OutlineInputControl.propTypes = {
  label: PropTypes.node,
  labelInfo: PropTypes.string,
  sizing: PropTypes.oneOf(['mobile', 'desktop', 'auto', true, false]),
  margin: PropTypes.oneOfType([
    PropTypes.oneOf(['dense', 'normal']),
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  labelInfoPlacement: PropTypes.string,
  labelColor: PropTypes.string,
};

OutlineInputControl.defaultProps = {
  label: null,
  labelInfo: null,
  sizing: null,
  margin: null,
  disabled: false,
  labelInfoPlacement: 'right',
  labelColor: null,
};

// Spacing, sizing, and label for underline inputs

export const UnderlineLabel = styled(BaseLabel, {
  shouldForwardProp: (prop) => !['disabled', 'shrink'].includes(prop),
})`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ labelColor }) => labelColor || colorsText.medium};
  font-weight: ${fontWeights.regular};
  // Allow the user to focus/hover the input when the labeling has the position of the placeholder
  pointer-events: none;
  // 22px will put the label baseline exactly 10px from the bottom border
  transform: translate(0, 22px) scale(1);
  transition:
    font-weight,
    transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  transform-origin: top left;
  ${({ shrink }) =>
    shrink &&
    css`
      font-weight: ${fontWeights.bold};
      // Scaling to 78.5% will take the 14px down to the 11px we want
      // The 4px will put the label 10px above the capline of the input text
      transform: translate(0, 4px) scale(0.785);
      // Account for the scaling when computing the width of the element
      max-width: calc(100% / 0.785);
      // Allow hovering for tooltips
      pointer-events: auto;
    `};
`;

export const UnderlineInputControl = styled(function UnderlineInputControl({
  label,
  labelColor,
  shrink,
  sizing,
  margin,
  forInput,
  disabled,
  children,
  ...props
}) {
  if (margin === true) {
    margin = 'dense';
  }
  return (
    <BaseInputControl margin={margin} sizing={sizing} {...props}>
      {label && (
        <UnderlineLabel
          shrink={shrink}
          disabled={disabled}
          labelColor={labelColor}
        >
          {label}
        </UnderlineLabel>
      )}
      {children}
    </BaseInputControl>
  );
})`
  position: relative;
  label + * {
    ${({ forInput }) => css`
      margin-top: ${forInput
        ? gutters.spacing(3)
        : gutters.spacing(3 + 2, -2)}px;
    `}
  }
`;

UnderlineInputControl.propTypes = {
  label: PropTypes.node,
  labelColor: PropTypes.string,
  shrink: PropTypes.bool,
  sizing: PropTypes.oneOf(['mobile', 'desktop', 'auto', true, false]),
  margin: PropTypes.oneOfType([
    PropTypes.oneOf(['dense', 'normal']),
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
};

UnderlineInputControl.defaultProps = {
  label: null,
  labelColor: null,
  shrink: true,
  sizing: null,
  margin: null,
  disabled: false,
};

// Handles outline/underline variants behind a switch

export default function InputControl({ variant, className, ...props }) {
  const VariantInputControl =
    variant === 'underline' ? UnderlineInputControl : OutlineInputControl;
  return (
    <VariantInputControl
      // This class is used by robert to identify new input components
      className={`InputControl ${className || ''}`}
      {...props}
    />
  );
}

InputControl.propTypes = {
  variant: PropTypes.oneOf(['outline', 'underline']),
};

InputControl.defaultProps = {
  variant: 'outline',
};

const isExists = (x) => typeof x !== 'undefined' && x !== null;

export const hasInputControl = (props) =>
  isExists(props.label) || isExists(props.sizing) || isExists(props.margin);

export const getNonInputControlProps = (props) => {
  props = { ...props };
  delete props.label;
  delete props.sizing;
  delete props.margin;
  return props;
};

const validPropNames = {
  variant: true,
  label: true,
  labelInfo: true,
  labelInfoPlacement: true,
  shrink: true,
  sizing: true,
  margin: true,
  forInput: true,
  disabled: true,
  // Never want to double-up onChange handlers, which are being used inside the control
  onChange: false,
  isRequired: true,
};

// This is a defensive measure to make it trivial for components to support
// Control props by simply spreading them all through.

export const getInputControlProps = (props) => {
  return pickBy(props, (_, key) => {
    if (key in validPropNames) {
      return validPropNames[key];
    }
    return isPropValid(key);
  });
};

// Help inputs support
export const MaybeInputControl = (props) => {
  if (hasInputControl(props)) {
    return (
      <InputControl
        {...getInputControlProps(props)}
        data-qa-input-control-label={props?.label}
      />
    );
  }
  return props.children;
};
