import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { breakpoints, gutters, spaceBetweenGutters } from 'app/spacing';
import { CardBasic } from 'components/Card/Card';

// Half width, and anticipates to include a toolbar and a table

export default styled(CardBasic)`
  height: 496px; // 10 rows + header
  width: 100%;
  overflow: hidden; // ensure those border-radii show!

  ${({ singleColumn }) =>
    !singleColumn &&
    css`
      @media (min-width: ${breakpoints.lg}px) {
        width: ${spaceBetweenGutters({
          columns: 2,
          gutter: `${gutters.spacing(4)}px`,
        })};
      }
    `}
  ${({ relative }) =>
    relative
      ? css`
          position: relative;
        `
      : ''}
  display: flex;
  flex-direction: column;
  > div:last-of-type {
    flex: 1;
  }
`;
