import { useState, useEffect, useCallback, useMemo } from 'react';
import useToggle from 'react-use/lib/useToggle';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Kizen/Loader';
import ActivityService from 'services/ActivityService';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { isMobile, useWindowSize } from 'app/spacing';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import NewActivitiesModal from './NewActivitiesModal';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import ActivityTabularPage from './TabularPage';
import ActivityListPage from './ListPage';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
  buildPage,
  updatePageConfig,
  activitiesIsFetching,
  selectActivities,
  selectActivitiesCount,
  selectActivitiesPageConfig,
  getActivities,
  updateActivitySuccess,
  deleteActivity,
  duplicateActivity,
} from 'store/activitiesPage/activities.redux';
import { setSearchParams, getSearchParam } from 'hooks/useSearchParam';
import {
  cleanDeleteActivityToastMessage,
  selectActivitiesDeleteToastMessage,
} from 'store/activitiesPage/activities.redux';
import { useDebounce } from 'react-use';
import { maybeTrackSearch } from 'utility/analytics';
import { DEFAULT_DELAY } from 'utility/config';
import { deferExecution } from 'utility/defer';
import { getOriginalError } from 'services/AxiosService';
import { PermissionsError } from 'services/errors';
import useCan from 'hooks/useCan';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

export default function ActivitiesPage(props) {
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [showToast] = useToast();
  const history = useHistory();
  const [showAddModal, toggleShowAddModal] = useToggle(false);
  const { t } = useTranslation();
  useSetTitleOnLoad(props.title(t));
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();

  const pageConfig = useSelector(selectActivitiesPageConfig);
  const activitiesCount = useSelector(selectActivitiesCount);
  const activities = useSelector(selectActivities);
  const isFetching = useSelector(activitiesIsFetching);
  const activityDeleteToastMessage = useSelector(
    selectActivitiesDeleteToastMessage
  );

  const activitiesAccess = useCan({
    view: 'sections',
    for: 'activities_section',
  });

  // Track debounced changes to the search term and send to google analytics
  const { search } = pageConfig;
  useDebounce(() => maybeTrackSearch(search, 'activities'), DEFAULT_DELAY, [
    search,
  ]);

  // async effects -------------------------------------------------
  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const search = getSearchParam(history.location, 'q');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');

    syncDispatch(
      buildPage({
        page: {
          search,
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
        },
      })
    );
  }, [syncDispatch, history]);

  const handleChangeSearch = useCallback(
    (q) => {
      dispatch(
        updatePageConfig({
          search: q,
          page: 1,
        })
      );
      dispatch(getActivities());
      deferExecution(() => {
        setSearchParams(history, { q, page: null }, { method: 'replace' });
      });
    },
    [history, dispatch]
  );
  const sortValue = useMemo(
    () => getSortValue(pageConfig.sort),
    [pageConfig.sort]
  );

  const handleSort = useCallback(
    ({ column, direction }) => {
      const value = getOrderingParam({ column, direction });
      dispatch(
        updatePageConfig({
          sort: value,
        })
      );
      dispatch(getActivities());
      deferExecution(() => {
        setSearchParams(
          history,
          {
            sort: value,
          },
          { method: 'replace' }
        );
      });
    },
    [dispatch, history]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          page: value,
        })
      );
      dispatch(getActivities());
      deferExecution(() => {
        setSearchParams(history, {
          page: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          size: value,
        })
      );
      dispatch(getActivities());
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [dispatch, history]
  );
  const handleUpdateActivity = useCallback(
    async (activity, patch) => {
      try {
        const updatedActivity = await ActivityService.v2UpdateActivity({
          ...activity,
          ...patch,
        });
        dispatch(updateActivitySuccess(updatedActivity));
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = Object.entries(err.response.data.errors);
          if (errors && errors.length) {
            // we show only 1st error
            error = new Error(errors[0][1]);
          }
        }
        throw error;
      }
    },
    [dispatch]
  );

  const handleExportResponses = async ({ id: activityObjectId, name }) => {
    try {
      await ActivityService.v2ExportActivities({
        activityObjectId,
      });
      const message = t(
        `Responses were queued for export successfully. You will be emailed a download link once complete`
      );
      showToast({
        variant: toastVariant.SUCCESS,
        message,
      });
    } catch (error) {
      const orig = getOriginalError(error);
      const message =
        orig?.message ||
        t(
          'The export was not successful. Please try again or contact Kizen support.'
        );
      showToast({
        variant: toastVariant.FAILURE,
        message,
      });
    }
  };
  const handleDuplicateActivity = async ({ id }) => {
    dispatch(duplicateActivity({ id, t }));
  };
  const handleConfirmDeleteActivity = (id) => dispatch(deleteActivity(id));
  const handleDeleteActivity = (id) => setDeleteConfirmation({ id });

  useEffect(() => {
    if (activityDeleteToastMessage) {
      showToast({
        variant: activityDeleteToastMessage.variant,
        message: activityDeleteToastMessage.message,
      });
      dispatch(cleanDeleteActivityToastMessage());
    }
  }, [activityDeleteToastMessage, showToast, dispatch]);

  if (!activitiesAccess) {
    throw new PermissionsError();
  }

  if (isFetching) {
    return <Loader loading />;
  }

  return (
    <>
      {isMobile(width) ? (
        <ActivityListPage
          activities={activities}
          activitiesCount={activitiesCount}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSort}
          pageNumber={pageConfig.page || 1}
          pageSize={pageConfig.size || 50}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onExportResponses={handleExportResponses}
          onDeleteActivity={handleDeleteActivity}
          onDuplicateActivity={handleDuplicateActivity}
          {...props}
        />
      ) : (
        <ActivityTabularPage
          activities={activities}
          activitiesCount={activitiesCount}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSort}
          pageNumber={pageConfig.page || 1}
          pageSize={pageConfig.size || 50}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onUpdateActivity={handleUpdateActivity}
          onDeleteActivity={handleDeleteActivity}
          onDuplicateActivity={handleDuplicateActivity}
          onExportResponses={handleExportResponses}
          openAddModal={toggleShowAddModal}
          {...props}
        />
      )}
      {deleteConfirmation && (
        <ConfirmDeletionModal
          show
          onHide={() => setDeleteConfirmation(null)}
          onConfirm={async () => {
            const { id } = deleteConfirmation;
            try {
              await handleConfirmDeleteActivity(id);
            } finally {
              setDeleteConfirmation(null);
            }
          }}
        >
          {t('This will permanently delete the Activity.')}
        </ConfirmDeletionModal>
      )}
      <NewActivitiesModal
        show={showAddModal}
        onHide={toggleShowAddModal}
        handleCreate={toggleShowAddModal}
      />
    </>
  );
}
