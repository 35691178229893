import React, { forwardRef, useCallback, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsButton, colorsText } from 'app/colors';
import { gutters, scrollbarCss } from 'app/spacing';
import {
  fontSizes,
  fontWeights,
  KizenTypography,
  truncateCss,
} from 'app/typography';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { IconSizing } from 'components/Kizen/Icon';
import ScrollFadeContainer from 'components/Kizen/ScrollFadeContainer';
import useField from 'hooks/useField';
import useEndsOfScroll from 'hooks/useEndsOfScroll';
import { Collapse } from 'react-bootstrap';
import { TrayBackButton } from './TrayBackButton';
import { useMeasure } from 'react-use';
import { applyRef } from '@kizen/page-builder/internal/components/Inputs/props';

const DURATION = 600; // ms
const SECTION_HEADER_HEIGHT = 34;

const Header = styled.div`
  flex: 1;
  height: 34px;
  background-color: ${colorsButton.blue.default};
  display: flex;
  align-items: center;
  padding-left: ${gutters.spacing(4)}px;
  padding-right: ${gutters.spacing(3)}px;
  > h4 {
    flex: 1;
    line-height: 1; // Avoids discrepancy between styles in prod vs dev. TODO fix css leak from somewhere.
    margin-bottom: -1px;
    min-width: 0;
    ${truncateCss}
    ${({ backButton }) =>
      backButton &&
      css`
        text-align: center;
      `}
  }
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const Body = styled.div`
  > div {
    // Placing padding in inner div fixes some jank during collapse
    padding: 0 ${gutters.spacing(4)}px;
    margin: ${gutters.spacing(4)}px 0;
  }
  &.collapsing {
    transition-duration: ${DURATION}ms;
  }
`;

const CollapseIconButton = styled(IconButton)`
  margin-left: ${gutters.spacing(2)}px;
  transition: transform ${DURATION}ms ease;
  transform-origin: center;
  transform: rotate(0deg);
  ${({ open }) =>
    open &&
    css`
      transform: rotate(-90deg);
    `}
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > :last-child {
    overflow-x: hidden;
    height: 100%;
    ${scrollbarCss()}
    height: 100%;

    // targets ScrollFadeContainer
    > div {
      min-width: 320px;
    }
  }

  ${({ collapsable }) =>
    !collapsable &&
    css`
      flex: 1;
    `}

  ${({ scrollable }) =>
    scrollable &&
    css`
      min-height: 0;
    `}

  :not(div:last-of-type) {
    min-height: ${SECTION_HEADER_HEIGHT}px; // be sure to use instead of scrollable's min-height: 0
    overflow: hidden;
    margin-bottom: 1px; // This creates 1px of white space between sections when collapsed (without showing the scrollbar underneath)
  }
`;

const HeaderContainer = styled.div`
  ${({ backButton }) =>
    backButton &&
    css`
      display: flex;
      flex-direction: row;
    `}
`;

const BackHeader = styled(Header)`
  flex: 0;
  margin-right: 1px;
  padding: 0 ${gutters.spacing(3)}px;
`;

export const TraySection = forwardRef(
  (
    {
      header,
      onBackClick,
      children,
      onCollapse,
      scrollable = true,
      collapsable = true,
      open: openProp = true,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = useField(!!openProp);
    const scrollRef = useRef();
    const [sectionRef, { height }] = useMeasure();
    const scrolled = useEndsOfScroll(scrollRef, [height]); // add container height to dependency array so scrolled flags recalculate when collapsing tray sections

    const mergeRef = useCallback(
      (el) => {
        scrollRef.current = el;
        applyRef(sectionRef, el);
        applyRef(ref, el);
      },
      [ref, sectionRef]
    );

    const handleCollapse = () => {
      if (collapsable) {
        onCollapse?.(!open);
        setOpen(!open);
      }
    };

    return (
      <SectionWrapper
        scrollable={scrollable}
        collapsable={collapsable}
        {...rest}
      >
        <HeaderContainer backButton={!!onBackClick}>
          {onBackClick ? (
            <BackHeader>
              <TrayBackButton onClick={onBackClick} />
            </BackHeader>
          ) : null}
          <Header
            role={collapsable && 'button'}
            onClick={handleCollapse}
            backButton={!!onBackClick}
          >
            <KizenTypography
              as="h4"
              weight="bold"
              size="buttonLabel"
              textTransform="uppercase"
              color={colorsText.light}
            >
              {header}
            </KizenTypography>
            {collapsable && (
              <CollapseIconButton
                open={open}
                sizing="dense"
                color={colorsText.light}
              >
                <IconSizing size="12px">
                  <Icon icon="chevron-left" />
                </IconSizing>
              </CollapseIconButton>
            )}
          </Header>
        </HeaderContainer>
        <ScrollFadeContainer ref={mergeRef} scrolled={scrolled} top bottom>
          {children && ( // Collapse has 20px height w/o children - this was showing a scrollbar for our BlankSettings
            <Collapse in={open} timeout={DURATION}>
              <Body>
                <div>{children}</div>
              </Body>
            </Collapse>
          )}
        </ScrollFadeContainer>
      </SectionWrapper>
    );
  }
);

TraySection.propTypes = {
  header: PropTypes.string.isRequired,
  collapsable: PropTypes.bool,
  scrollable: PropTypes.bool,
  open: PropTypes.bool,
  onBackClick: PropTypes.func,
};

export const SubsectionHeader = styled(KizenTypography)`
  display: flex;
  font-size: ${fontSizes.text};
  font-weight: ${fontWeights.bold};
  line-height: 1;
  margin-top: -1px;
  margin-bottom: ${gutters.spacing(3)}px;
`;

export const TraySubsection = styled(
  ({ header, children, CustomHeader, ...others }) => (
    <div {...others}>
      {CustomHeader ? (
        <CustomHeader />
      ) : (
        <SubsectionHeader weight="bold">{header}</SubsectionHeader>
      )}

      {children}
    </div>
  )
)`
  &:not(:last-child) {
    margin-bottom: ${gutters.spacing(4)}px;
  }
`;
