import { layers } from 'app/spacing';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import MultiSelect from 'components/Inputs/MultiSelect';
import SelectAllButton from 'components/Inputs/MultiSelect/SelectAllButton';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TeamIconButton } from './styles';
import { popperModifiers } from 'components/helpers';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';

const DROPDOWN_TYPES = {
  ROLE: 'role',
  TEAM: 'team',
};

const FILTER_TEAM_KEY = 'team_member';

const TeamRoleSelector = ({ roleOptions = [], onChange, value, mobile }) => {
  const [showTeam, setShowTeam] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [internalValueTeam, setInternalValueTeam] = useState([]);
  const [internalValueRole, setInternalValueRole] = useState([]);
  const { t } = useTranslation();
  const teamButtonRef = useRef(null);
  const roleButtonRef = useRef(null);
  const teamSelectRef = useRef(null);

  const internalValue = useMemo(() => {
    return [...internalValueTeam, ...internalValueRole];
  }, [internalValueTeam, internalValueRole]);

  const handleApply = useCallback(
    (type) => {
      const result = internalValue.reduce((acc, curr) => {
        const res = { ...acc };

        if (!res[curr.type]) {
          res[curr.type] = [];
        }

        res[curr.type].push({ value: curr.value, label: curr.label });

        return res;
      }, {});
      if (type === DROPDOWN_TYPES.TEAM) {
        setShowTeam(false);
      } else if (type === DROPDOWN_TYPES.ROLE) {
        setShowRole(false);
      }
      onChange?.(result);
    },
    [internalValue, onChange]
  );

  useEffect(() => {
    if (value.teamMembers) {
      setInternalValueTeam(
        value.teamMembers.map((v) => {
          return {
            value: v,
            label: value.memberMetadata[v],
            type: FILTER_TEAM_KEY,
          };
        })
      );
    }

    if (value.roles) {
      setInternalValueRole(
        value.roles.map((v) => {
          return {
            value: v,
            label: value.rolesMetadata[v],
            type: DROPDOWN_TYPES.ROLE,
          };
        })
      );
    }
  }, [value]);

  const hideHandler = useCallback(
    (type) => {
      return () => {
        if (type === DROPDOWN_TYPES.TEAM) {
          setShowTeam(false);
        } else if (type === DROPDOWN_TYPES.ROLE) {
          setShowRole(false);
        }
        handleApply(type);
      };
    },
    [handleApply]
  );

  const processedRoleOptions = useMemo(() => {
    return roleOptions.map((v) => {
      return {
        ...v,
        type: DROPDOWN_TYPES.ROLE,
      };
    });
  }, [roleOptions]);

  const popperConfig = useMemo(() => {
    return mobile
      ? {
          modifiers: [
            popperModifiers.calcStyles((styles) => {
              return {
                position: 'absolute',
                top: '20px',
                left: '50%',
                transform: `translate3d(-50%, ${styles.top}px, 0px)`,
                willChange: 'transform',
                zIndex: layers.content(10, 2),
              };
            }),
          ],
        }
      : {
          modifiers: [
            popperModifiers.addStyles({
              zIndex: layers.content(10, 2),
            }),
          ],
        };
  }, [mobile]);

  const roleValues = useMemo(() => {
    return internalValue.filter((v) => v.type === DROPDOWN_TYPES.ROLE);
  }, [internalValue]);

  const teamValues = useMemo(() => {
    return internalValue.filter((v) => v.type === FILTER_TEAM_KEY);
  }, [internalValue]);

  const chosenValueIds = useMemo(() => {
    return teamValues.map((v) => v.value);
  }, [teamValues]);

  const [teamSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: teamSelectRef,
    objectToOption: (o) => {
      return {
        ...convertToTeamOption(o),
        type: FILTER_TEAM_KEY,
      };
    },
    entity: Entities.TeamMember,
    alwaysOpen: true,
    chosenValueIds,
  });

  const clearHandler = useCallback((type) => {
    return () => {
      if (type === DROPDOWN_TYPES.TEAM) {
        setInternalValueTeam([]);
      } else if (type === DROPDOWN_TYPES.ROLE) {
        setInternalValueRole([]);
      }
    };
  }, []);

  const toggler = useCallback((type) => {
    return () => {
      if (type === DROPDOWN_TYPES.TEAM) {
        setShowTeam((prevShow) => !prevShow);
      } else if (type === DROPDOWN_TYPES.ROLE) {
        setShowRole((prevShow) => !prevShow);
      }
    };
  }, []);

  const changeHandler = useCallback((type) => {
    return (data) => {
      if (type === DROPDOWN_TYPES.TEAM) {
        setInternalValueTeam(data);
      } else if (type === DROPDOWN_TYPES.ROLE) {
        setInternalValueRole(data);
      }
    };
  }, []);

  return (
    <>
      <TeamIconButton
        icon="briefcase"
        label={'Role Filter'}
        title={'Role Filter'}
        ref={roleButtonRef}
        onClick={toggler(DROPDOWN_TYPES.ROLE)}
        badge={value?.roles?.length}
        badgeMaxWidth={value?.roles?.length > 9}
      />
      <SelectOverlay
        show={showRole}
        target={roleButtonRef.current}
        onHide={hideHandler(DROPDOWN_TYPES.ROLE)}
        placement="bottom-end"
        className="team-select-overlay"
        zIndex={layers.content(2)}
        popperConfig={popperConfig}
      >
        <MultiSelect
          placeholder={t('Choose Roles')}
          value={roleValues}
          onChange={changeHandler(DROPDOWN_TYPES.ROLE)}
          menuTopButton={<SelectAllButton />}
          menuLeftButton={
            <ClearSelectButton onClick={clearHandler(DROPDOWN_TYPES.ROLE)} />
          }
          menuRightButton={
            <ApplySelectButton onClick={hideHandler(DROPDOWN_TYPES.ROLE)} />
          }
          endAdornment={<IconAdornment icon="search" />}
          options={processedRoleOptions}
        />
      </SelectOverlay>
      <TeamIconButton
        icon="people"
        label={'Team Filter'}
        title={'Team Filter'}
        ref={teamButtonRef}
        onClick={toggler(DROPDOWN_TYPES.TEAM)}
        badge={value?.teamMembers?.length}
        badgeMaxWidth={value?.teamMembers?.length > 9}
      />
      <SelectOverlay
        show={showTeam}
        target={teamButtonRef.current}
        onHide={hideHandler(DROPDOWN_TYPES.TEAM)}
        placement="bottom-end"
        className="team-select-overlay"
        zIndex={layers.content(2)}
        popperConfig={popperConfig}
      >
        <MultiSelect
          ref={teamSelectRef}
          placeholder={t('Choose Team Members')}
          value={teamValues}
          onChange={changeHandler(DROPDOWN_TYPES.TEAM)}
          menuTopButton={<SelectAllButton />}
          menuLeftButton={
            <ClearSelectButton onClick={clearHandler(DROPDOWN_TYPES.TEAM)} />
          }
          menuRightButton={
            <ApplySelectButton onClick={hideHandler(DROPDOWN_TYPES.TEAM)} />
          }
          endAdornment={<IconAdornment icon="search" />}
          {...teamSelectProps}
        />
      </SelectOverlay>
    </>
  );
};

export default TeamRoleSelector;
