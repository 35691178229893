import { useState } from 'react';
import { format, parse } from 'date-fns2';

const API_DATE_FORMAT = 'yyyy-MM-dd';

export const FIELD_TYPE_STAGE_DROPDOWN = 'stage-dropdown';
export const FIELD_TYPE_TEAM_SELECT = 'team-select';

export const transformDateUp = (date) => {
  if (date) {
    return format(date, API_DATE_FORMAT, {
      useAdditionalDayOfYearTokens: true,
      useAdditionalWeekYearTokens: true,
    });
  }

  return null;
};

export const transformDateDown = (yyyymmdd) => {
  return yyyymmdd ? parse(yyyymmdd, API_DATE_FORMAT, new Date()) : null;
};

export function useAddObjectModal({ handleSubmit }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [stagedName, setStagedName] = useState('');
  const [modalSubmitting, setModalSubmitting] = useState(false);

  const onHide = () => {
    setStagedName('');
    setModalOpen(false);
  };

  const onConfirm = async () => {
    if (stagedName) {
      setModalSubmitting(true);
      try {
        await handleSubmit(stagedName);
      } finally {
        setModalSubmitting(false);
        onHide();
      }
    }
  };

  const onOpen = (name) => {
    setModalOpen(true);
    setStagedName(name);
  };

  return [
    {
      disabled: !stagedName || modalSubmitting,
      onHide,
      onConfirm,
      show: isModalOpen,
    },
    {
      value: stagedName,
      onChange: setStagedName,
    },
    {
      onOpen,
      modalSubmitting,
    },
  ];
}

export const fieldTypeMapper = (field) => {
  const mapper = {
    owner: FIELD_TYPE_TEAM_SELECT,
    stage: FIELD_TYPE_STAGE_DROPDOWN,
  };

  if (field.isDefault) {
    return mapper[field.name] || field.fieldType;
  }

  return field.fieldType;
};

export const getDataQaLabel = (field) => {
  return `field-input-${field.displayName.toLowerCase().replace(/ /g, '-')}`;
};
