import { useEditor, useNode } from '@craftjs/core';
import {
  RecaptchaContainer,
  RecaptchaTerms,
  SharedViewBuilderButton,
} from '@kizen/page-builder/nodes/Button';
import { useTranslation } from 'react-i18next';
import { ButtonContainer } from '@kizen/page-builder/nodes/containers';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { useDraggingType } from '../useDraggingType';
import { useShowControls } from '../useShowControls';
import Control from '../Control';
import { useIsInBuilderContext } from 'components/PageBuilder/BuilderContext';

export const Button = (props) => {
  const { t } = useTranslation();
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
  }));
  const {
    id,
    connectors: { connect },
  } = useNode((node) => ({
    id: node.id,
  }));
  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const isInBuilderContext = useIsInBuilderContext();
  const { label, ...rest } = props;

  return (
    <Control
      ref={connect}
      label={t('Content')}
      show={showControls}
      mayHaveChildren={false}
      fullWidth={true}
      {...rest}
      color={colorsPrimary.green.dark}
    >
      <ButtonContainer
        draggingType={draggingType}
        isInBuilderContext={isInBuilderContext}
        {...props}
      >
        <RecaptchaContainer enableRecaptcha={Boolean(props.enableRecaptcha)}>
          <SharedViewBuilderButton
            {...props}
            disabled={false}
            loading={false}
            url={null}
          />
          {Boolean(props.enableRecaptcha) && <RecaptchaTerms />}
        </RecaptchaContainer>
      </ButtonContainer>
    </Control>
  );
};
