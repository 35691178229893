import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast, toastVariant } from 'components/ToastProvider';
import ActivityService from 'services/ActivityService';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import { CompleteActivityModal } from 'components/Modals/ActivityCompletionModal/ActivityCompletionModal';
import {
  LOG_ACTIVITY_SUCCESS,
  FAILED_COMPLETE_ACTIVITY,
} from 'pages/Common/Actions/activityHelpers';
import { StyledSelect } from './styles';
import useModal from 'components/Modals/useModal';
import { useGetActivityPredefined } from '../../../hooks/activities/useGetActivityPredefined';
import { layers } from 'app/spacing';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { getOriginalError } from 'services/AxiosService';

const CustomActivityActions = ({
  showLogActivity,
  target,
  customObject,
  customObjectModel,
  onSetVisible,
  scrolled,
  onShowScheduleActivity,
  setPredefinedOptions,
  shownScrolled,
  logActivityActions,
  fieldState,
  activityList,
  ...props
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const selectRef = useRef();
  const [logActivity, setLogActivity] = useState();
  const [loggingActivity, setLoggingActivity] = useState(false);

  const [typeaheadProps] = useSelectTypeaheadWithScroll({
    selectRef,
    fieldId: customObjectModel.id,
    entity: Entities.ActivitiesLoggable,
    alwaysOpen: showLogActivity,
  });

  useEffect(
    () => {
      if ((scrolled && !shownScrolled) || (!scrolled && shownScrolled)) {
        // makes sure the menus close if scrolled
        onSetVisible(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrolled, shownScrolled]
  );

  const handleLogActivity = async ({
    activityObjectId,
    payload,
    dirtyFields,
  }) => {
    setLoggingActivity(true);
    await logActivityActions.save();
    const result = await ActivityService.v2CompleteActivity({
      activityObjectId,
      payload,
    });
    showToast({
      message: LOG_ACTIVITY_SUCCESS(t),
      variant: toastVariant.SUCCESS,
    });
    logActivityActions.update(dirtyFields);

    setPredefinedOptions({});
    setLogActivity({});
    setLoggingActivity(false);

    return result;
  };

  const handleLogActivityError = (error) => {
    const orig = getOriginalError(error);
    showToast({
      message: orig?.message || FAILED_COMPLETE_ACTIVITY(t),
      variant: toastVariant.FAILURE,
    });
    setLoggingActivity(false);
  };

  const [completeModalProps, , completeModal] = useModal({
    handleSubmit: handleLogActivity,
    handleError: handleLogActivityError,
  });

  const activityPredefinedOption = useGetActivityPredefined(
    customObject,
    customObjectModel
  );
  return (
    <>
      <SelectOverlay
        show={showLogActivity}
        target={target}
        onHide={() => {
          onSetVisible(false);
          setLogActivity(null);
        }}
        zIndexNudge={layers.content(0, 3)}
      >
        <StyledSelect
          ref={selectRef}
          value={logActivity || null}
          onChange={({ value }) => {
            setLogActivity({
              activity: {
                activityObject: activityList.find((act) => act.id === value),
              },
              predefinedOptions: {
                ...activityPredefinedOption,
              },
            });
            completeModal.show();
            onSetVisible(false);
          }}
          autoFocus={false}
          placeholder={t('Find Activity')}
          {...props}
          {...typeaheadProps}
        />
      </SelectOverlay>
      <CompleteActivityModal
        {...logActivity}
        {...completeModalProps}
        disabled={loggingActivity}
        logActivityActions={logActivityActions}
      />
    </>
  );
};

export default CustomActivityActions;
