import React, { useCallback } from 'react';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { useFormSubmit, useViewerContext } from '../../viewer/ViewerContext';
import { useLoadNonWebFont } from '../../hooks/useLoadNonWebFont';
import { useRecaptchaWidget } from '../../hooks/useRecaptcha';
import { ButtonContainer } from '../containers';
import { grayScale } from '../../internal/app/colors';
import { ButtonActions } from './props';
import { getBackwardsCompatibleButtonProps } from './utils';

const shouldSubmitForm = (action) =>
  action === ButtonActions.SUBMIT || action === ButtonActions.GO_TO_PAGE_SUBMIT;

const shouldChangePage = (action) =>
  action === ButtonActions.GO_TO_PAGE ||
  action === ButtonActions.GO_TO_PAGE_SUBMIT ||
  action === ButtonActions.SUBMIT;

export const RecaptchaContainer = styled.div`
  ${({ enableRecaptcha }) =>
    enableRecaptcha &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`;

const RecaptchaTermsContainer = styled.div`
  margin-top: 20px;

  span {
    font-size: 10px;
    line-height: 1.25;
    text-align: center;
    color: ${grayScale.mediumDark};
  }

  a {
    color: ${grayScale.mediumDark};
    text-decoration: underline;
  }
`;

export const RecaptchaTerms = () => (
  <RecaptchaTermsContainer>
    <span>
      This site is protected by reCAPTCHA and the Google{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://policies.google.com/privacy"
      >
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://policies.google.com/terms"
      >
        Terms of Service
      </a>{' '}
      apply.
    </span>
  </RecaptchaTermsContainer>
);

const StyledViewButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  font-size: ${({ fontSize }) => `${fontSize || 12}px`};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => italic && 'italic'};
  padding: ${({ padding }) => padding};
  line-height: normal;
  letter-spacing: 0.8px;
  border-radius: ${({ borderRadius }) => borderRadius}px;
  border: ${({ borderSize, borderColor }) =>
    `${borderSize}px solid ${borderColor}`};

  text-decoration: ${({ underline }) => underline && 'underline'};

  :focus {
    outline: none;
    box-shadow: none;
  }
  :hover,
  :active,
  :focus {
    cursor: pointer;
    background-color: ${({ color }) => color};
    color: ${({ textColor }) => textColor};
    text-decoration: none;
    /* background-color: #35aa98; */
  }
  // Have to override our reset css...
  &:not([href]):not([tabindex]),
  &:not([href]):not([tabindex]):hover,
  &:not([href]):not([tabindex]):active,
  &:not([href]):not([tabindex]):focus {
    color: ${({ textColor }) => textColor};
    text-decoration: ${({ underline }) => underline && 'underline'};
  }
  :disabled {
    cursor: default;
    background-color: #d8dde1;
  }
`;
const StyledViewButtonAnchor = StyledViewButton.withComponent('a');

export const SharedViewBuilderButton = (props) => {
  const {
    action,
    url,
    label,
    color,
    disabled,
    loading,
    textColor,
    onClick,
    borderColor,
    borderRadius,
    borderSize,
    fontSize,
    textStyles,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    fontFamily,
    openLinkInNewTab,
  } = getBackwardsCompatibleButtonProps(props);
  const padding = [
    `${paddingTop || 0}px`,
    `${paddingRight || 0}px`,
    `${paddingBottom || 0}px`,
    `${paddingLeft || 0}px`,
  ].join(' ');
  const [bold, underline, italic] = textStyles.reduce(
    (acc, style) => {
      if (style === 'bold') acc[0] = true;
      else if (style === 'underline') acc[1] = true;
      else if (style === 'italic') acc[2] = true;
      return acc;
    },
    [false, false, false]
  );

  useLoadNonWebFont(fontFamily);

  // We only want to render a real <a> if we actually have a URL
  // If we do not have a URL, then we should render a button that
  // does nothing
  if (action === ButtonActions.URL && url) {
    return (
      <StyledViewButtonAnchor
        color={color}
        textColor={textColor}
        borderSize={borderSize}
        borderColor={borderColor}
        borderRadius={borderRadius}
        fontSize={fontSize}
        fontFamily={fontFamily}
        bold={bold}
        italic={italic}
        underline={underline}
        padding={padding}
        href={url}
        target={openLinkInNewTab ? '_blank' : undefined}
        rel="noopener"
      >
        {label}
      </StyledViewButtonAnchor>
    );
  }

  return (
    <StyledViewButton
      onClick={onClick}
      color={color}
      textColor={textColor}
      borderSize={borderSize}
      borderColor={borderColor}
      borderRadius={borderRadius}
      fontSize={fontSize}
      fontFamily={fontFamily}
      bold={bold}
      italic={italic}
      underline={underline}
      padding={padding}
      disabled={disabled || loading}
    >
      {label}
    </StyledViewButton>
  );
};

const RecaptchaButton = ({ sitekey, submitForm, onValidate, ...rest }) => {
  const [RecaptchaWidget, execute] = useRecaptchaWidget(sitekey, submitForm);

  return (
    <ButtonContainer {...rest}>
      <RecaptchaContainer enableRecaptcha>
        <SharedViewBuilderButton
          {...rest}
          onClick={() => {
            if (onValidate()) {
              execute();
            }
          }}
        />
        <RecaptchaWidget />
        <RecaptchaTerms />
      </RecaptchaContainer>
    </ButtonContainer>
  );
};

export const ViewButton = ({ enableRecaptcha, ...rest }) => {
  const { action, goToPage } = rest;
  const submitForm = useFormSubmit();
  const {
    isSubmitting,
    onPageChange,
    validate,
    values,
    sortedFields,
    recaptchaSitekey,
  } = useViewerContext();

  const handleValidate = useCallback(() => {
    return validate(values.current, sortedFields);
  }, [validate, values, sortedFields]);

  const handleSubmit = useCallback(
    async (recaptchaToken) => {
      if (shouldSubmitForm(action)) {
        // If we are submitting the form, we need to wait for the form to submit
        // If the form submission fails, we do not want to change the page
        const success = await submitForm(action, goToPage, recaptchaToken);
        if (success && shouldChangePage(action)) {
          onPageChange(action, goToPage, values.current);
        }
        return;
      }
      if (shouldChangePage(action)) {
        onPageChange(action, goToPage, values.current);
      }
    },
    [action, submitForm, onPageChange, goToPage, values]
  );

  const handleClick = useCallback(() => {
    if (handleValidate()) {
      handleSubmit();
    }
  }, [handleValidate, handleSubmit]);

  if (enableRecaptcha) {
    return (
      <RecaptchaButton
        onValidate={handleValidate}
        submitForm={handleSubmit}
        loading={isSubmitting}
        sitekey={recaptchaSitekey}
        {...rest}
      />
    );
  }

  return (
    <ButtonContainer {...rest}>
      <SharedViewBuilderButton
        {...rest}
        loading={isSubmitting}
        onClick={action === ButtonActions.URL ? undefined : handleClick}
      />
    </ButtonContainer>
  );
};

export const HomepageButton = (props) => {
  const { activityId, ...rest } = props;
  const { onLogActivityButtonClick } = useViewerContext();

  const handleClick = () => {
    if (activityId) {
      onLogActivityButtonClick(activityId);
    }
  };

  return (
    <ButtonContainer {...rest}>
      <SharedViewBuilderButton {...rest} onClick={handleClick} />
    </ButtonContainer>
  );
};
