import React, { useState } from 'react';
import styled from '@emotion/styled';
import Select from 'components/Kizen/Select';
import MOCK_TEAM_MEMBERS from 'services/MOCK_TEAM_MEMBERS';
import { gutters } from 'app/spacing';

const StyledSelect = styled(Select)`
  margin-bottom: ${gutters.standard};
`;

const SingleSelect = () => {
  const [valueA, setValueA] = useState(null);
  const [valueB, setValueB] = useState(null);
  const [valueC, setValueC] = useState(null);

  return (
    <>
      <StyledSelect
        fullWidth
        label="Standard"
        placeholder="Choose Option"
        options={MOCK_TEAM_MEMBERS}
        value={valueA}
        onChange={setValueA}
      />
      <StyledSelect
        fullWidth
        label="Disabled"
        options={MOCK_TEAM_MEMBERS}
        value={valueB}
        onChange={setValueB}
        disabled
      />
      <StyledSelect
        fullWidth
        label="Error"
        options={MOCK_TEAM_MEMBERS}
        value={valueC}
        onChange={setValueC}
        style={{ width: '100%' }}
        error
      />
    </>
  );
};

export default SingleSelect;
