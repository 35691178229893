import { useMemo, useCallback, useRef } from 'react';

import MultiColumnBuilder from '__components/MultiColumnBuilder';
import { useTranslation } from 'react-i18next';

import { snakeToCamelCase } from 'services/helpers';
import { isEqual } from 'lodash';
import BaseOption from '__components/MultiColumnBuilder/components/BaseOption';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import { StyledDragItem } from '__components/MultiColumnBuilder/components/BaseOption/styles';
import Icon from '__components/Kizen/Icon';
import {
  EventType,
  EventTypes,
} from 'ts-components/RecordLayout/Builder/types';

import useBasicBuilder from '__components/MultiColumnBuilder/useBasicBuilder';

import {
  BuilderWrapper,
  LeftColumnWrapper,
  StyledDropPlaceholder,
  TextWrapper,
} from './styles';

import { KizenTypography, fontWeights } from '__app/typography';

const LEFT_COLUMN_ID = 'available-columns';
const RIGHT_COLUMN_ID = 'active-columns';
const selfDisabledDropZones = [LEFT_COLUMN_ID];

interface TimelineColumnsProps {
  timelineEventTypes: EventTypes;
  initialLeftColumnItems?: EventTypes;
  initialRightColumnItems?: EventTypes | null | undefined;
  onChange?: (...args: any[]) => any;
  model?: any;
  lockedFields?: any[];
  skipSorting?: boolean;
}

const getFalse = () => false;

export const TimelineColumns = (props: TimelineColumnsProps) => {
  const {
    timelineEventTypes,
    initialLeftColumnItems = [],
    initialRightColumnItems,
    onChange,
    lockedFields = [],
    skipSorting = false,
  } = props;
  const { t } = useTranslation();

  const defaultFields = useMemo(
    () => initialRightColumnItems || [],
    [initialRightColumnItems]
  );

  const fieldNameToIdsMap = useMemo(() => {
    return (
      timelineEventTypes?.reduce((acc: any, curr: EventType) => {
        acc[snakeToCamelCase(curr.label)] = curr.value;
        return acc;
      }, {}) ?? {}
    );
  }, [timelineEventTypes]);

  const defaultRightColumnItems = useMemo(() => {
    if (initialRightColumnItems?.length) {
      return initialRightColumnItems;
    }
    return defaultFields.map((field) => {
      const value = fieldNameToIdsMap[field.value] ?? field.value;
      return {
        label: field.label,
        value,
      };
    });
  }, [defaultFields, initialRightColumnItems, fieldNameToIdsMap]);

  const itemsOrdering = useMemo(
    () => (timelineEventTypes || []).map(({ value }) => value),
    [timelineEventTypes]
  );

  const rightItemsMap = useMemo(() => {
    return defaultRightColumnItems.reduce((acc: any, item: EventType) => {
      acc[item.value] = item;
      return acc;
    }, {});
  }, [defaultRightColumnItems]);

  const filteredInitialLeftColumnItems = useMemo(() => {
    return initialLeftColumnItems
      .filter(({ value }) => {
        return !rightItemsMap[value];
      })
      .sort(({ label: labelA }, { label: labelB }) => {
        return skipSorting ? 0 : labelA.localeCompare(labelB);
      });
  }, [initialLeftColumnItems, rightItemsMap, skipSorting]);

  const builderProps = useBasicBuilder({
    initialLeftColumnItems: filteredInitialLeftColumnItems,
    initialRightColumnItems: defaultRightColumnItems.map((item) => {
      const disable = lockedFields.includes(item.value);
      return {
        ...item,
        restricted: disable,
        disabled: disable,
        isLockedToColumn: disable,
      };
    }),
    leftColumnId: LEFT_COLUMN_ID,
    rightColumnId: RIGHT_COLUMN_ID,
    selfDisabledDropZones,
    itemsOrdering,
  });

  const stableRightColumnItems = useRef(builderProps.rightColumnItems);

  if (!isEqual(stableRightColumnItems.current, builderProps.rightColumnItems)) {
    stableRightColumnItems.current = builderProps.rightColumnItems;
    onChange?.(builderProps.rightColumnItems);
  }

  const additionalActions = useCallback(
    ({
      swapItemColumn,
      index,
      element,
      section,
    }: {
      swapItemColumn: any;
      index: any;
      element: any;
      section: any;
    }) => {
      return !element?.disabled && swapItemColumn && !element.restricted ? (
        <div>
          <Icon
            className="DeleteAction"
            icon="delete"
            onClick={() => {
              swapItemColumn(index, section);
            }}
          />
        </div>
      ) : null;
    },
    []
  );

  return (
    <BuilderWrapper className="help">
      <MultiColumnBuilder
        noTopMargin
        {...builderProps}
        leftHeaderText={t('Available Event Types (Drag to Add)')}
        rightHeaderText={t('Event Types on Timeline')}
        weight={fontWeights.regular}
        searchPlaceholder={t('Find Options')}
        onChangeSearchTerm={builderProps.setOptionsSearchTerm}
        renderLeftEmpty={({ dropZone, id }: { dropZone: any; id: any }) => {
          if (dropZone?.sectionId === id) {
            return (
              <StyledDropPlaceholder isChildDragging={false}>
                <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
                  <KizenTypography>{t('Drop to Remove')}</KizenTypography>
                </StyledDragItem>
              </StyledDropPlaceholder>
            );
          }
          return (
            <LeftColumnWrapper>
              <KizenTypography>{t('No Options Found')}</KizenTypography>
            </LeftColumnWrapper>
          );
        }}
        renderOption={({
          element,
          section,
          ...providedProps
        }: {
          element: any;
          section: any;
        }) => {
          return (
            // eslint-disable-next-line react/no-unknown-property
            <div {...element} section={section}>
              <BaseOption
                iconVisible={getFalse}
                iconEditable={getFalse}
                colorEditable={getFalse}
                element={element}
                section={section}
                additionalActions={additionalActions}
                {...providedProps}
              >
                {({ element }: { element: any }) => {
                  return (
                    <TextWrapper>
                      <TextEllipsisWithTooltip>
                        {element.label}
                      </TextEllipsisWithTooltip>
                    </TextWrapper>
                  );
                }}
              </BaseOption>
            </div>
          );
        }}
      />
    </BuilderWrapper>
  );
};
