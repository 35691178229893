import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';

import Loader from 'components/Kizen/Loader';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import MultiSelect from 'components/Inputs/MultiSelect';
import {
  useRelationshipSelect,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { RELATIONSHIP_KEY } from 'queries/query-keys';

const EntityBreakdown = ({ onChange, values = [], field }) => {
  const { t } = useTranslation();
  const selectRef = useRef(null);

  const chosenValueIds = useMemo(
    () =>
      (values || []).map((item) => {
        if (typeof item === 'string') {
          return item;
        }

        return item.value ?? item.id;
      }),
    [values]
  );

  const [{ displayValue, handleChange, relationship }] = useRelationshipSelect({
    value: values,
    onChange: (values) => {
      onChange?.(
        values.map((item) => ({
          value: item.id || item.value,
          label: item.label,
        })),
        false
      );
    },
    field,
    isMulti: true,
  });

  const results = useQueries(
    relationship?.detail
      ? values.map((elem) => {
          return {
            queryKey: RELATIONSHIP_KEY(elem.value || elem),
            queryFn: async () => {
              try {
                const item = await relationship.detail(elem.value || elem, {
                  skipErrorBoundary: true,
                });
                return relationship.toOption(item);
              } catch (e) {
                return null;
              }
            },
          };
        })
      : []
  );

  useEffect(() => {
    if (
      results.every(({ isSuccess }) => isSuccess) &&
      values.some((item) => !item.value)
    ) {
      onChange?.(
        results.reduce((acc, item) => {
          if (item.isSuccess && item.data) {
            acc.push(item.data);
          }
          return acc;
        }, []),
        false
      );
    }
  }, [onChange, results, values]);

  const [typeaheadProps, { loading }] = useSelectTypeaheadWithScroll({
    fetch: relationship.search,
    objectToOption: relationship.toOption,
    fieldId: field.id,
    selectRef,
    chosenValueIds,
  });

  return (
    <MultiSelect
      ref={selectRef}
      className="relationship-select"
      value={displayValue}
      onChange={handleChange}
      placeholder={t('Find Entity Record(s)')}
      endAdornment={
        loading ? <Loader loading size={15} /> : <IconAdornment icon="search" />
      }
      isLoading={loading}
      {...typeaheadProps}
    />
  );
};

export default EntityBreakdown;
