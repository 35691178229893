import { createSlice, createAction } from '@reduxjs/toolkit'; // use current to get state
import { put, takeLatest } from 'redux-saga/effects';
import pick from 'lodash/pick';
import { ERROR_COUNT_ACTIONS } from 'pages/AutomationEngine/store/consts';
import steps from 'pages/AutomationEngine/steps';

import { createAsyncSaga, takeLeadingAndCancel } from 'store/utilities';
import history from 'routes/history';
import Automation2Service from 'services/Automation2Service';
import FieldService, { getObjectModelName } from 'services/FieldService';
import { deleteStrategy, movingStrategies } from '../Engine/dialogs/constants';
import { getStepConfig, TRIGGER, defaultTrigger } from '../steps';
import { automationStats, newAutomation } from './example-automations';
import { preProcessDescriptionQues } from 'pages/AutomationEngine/store/utilities';

import {
  defaultValidationState,
  VALIDATION_STATE_TYPES,
  VALIDATION,
  isValidStepId,
} from './validation';
import {
  deleteStep,
  deleteTrigger,
  editStep,
  updateStep,
  updateTrigger,
  clearDescriptionQue,
  setStepError,
  moveOrInsertStep,
  mergePathStrategy,
  leaveBothStrategy,
  keepOnlyStrategy,
  deleteBranchStrategy,
  moveBranch,
  moveStepsAfterGotoMove,
} from './mutations';
import {
  stepsForFlow,
  triggersForFlow,
  getChildrenSteps,
  automationForApi,
  getAllowNoChildStep,
} from './utilities';
import { toastVariant } from 'components/ToastProvider';

const isNameError = (error) => error?.original?.name;

const isParentStep = (state, { id }) => {
  return state.steps.some((step) => {
    return step.parentKey === id;
  });
};

export const loadAutomation = createAsyncSaga(
  'automationEngine/loadAutomation',
  async ({ id, descriptionFallback }) => {
    const automation = await Automation2Service.getById(id);
    let model = null;

    if (
      automation?.customObject &&
      automation?.customObject?.objectClass === 'custom_objects'
    ) {
      // fetch the whole model as it's useful, to find things like stages etc.
      // NB objectClass is the only thing contained in cutomObject that isn't in the model
      const { customObject } = automation;
      model = await FieldService.getModel({
        id: customObject?.id,
      });

      model = {
        ...model,
        ...customObject,
      };
      automation.customObject = model;
    }
    return preProcessDescriptionQues({
      automation,
      descriptionFallback,
    });
  }
);

const setValidation = createAction('automationEngine/setValidation');

export const loadFields = createAsyncSaga(
  'automationEngine/loadFields',
  async ({ customObject }) => {
    try {
      const args = {
        for:
          customObject.name.toLowerCase() !== getObjectModelName('contacts')
            ? customObject
            : customObject.objectClass,
        skipErrorBoundary: true, // so 404 doesn't throw no access
        settingsRequest: true,
      };
      const [fields, categories] = await Promise.all([
        FieldService.getFields(args),
        FieldService.getCategories(args),
      ]);

      return { fields, categories };
    } catch (error) {
      return { fields: [], categories: [] };
    }
  }
);

const persistAutomationInternal = createAsyncSaga(
  'automationEngine/persistAutomation',
  async ({ automation, steps, triggers, model }) => {
    try {
      const payload = automationForApi({ automation, steps, triggers });
      let newAutomation = null;
      if (!payload.id) {
        newAutomation = await Automation2Service.create(payload);
      } else {
        newAutomation = await Automation2Service.updateFull(payload);
      }
      const { customObject } = newAutomation;
      newAutomation.customObject = {
        ...model,
        ...customObject,
      };

      return newAutomation;
    } catch (err) {
      console.debug(err);
      throw err;
    }
  }
);

export const persistAutomation = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { automation, steps, triggers, model } = state;

    if (VALIDATION[VALIDATION_STATE_TYPES.GO_TO_STEP_LOOP](state)) {
      return dispatch(
        setValidation({
          type: VALIDATION_STATE_TYPES.GO_TO_STEP_LOOP,
          show: true,
        })
      );
    }

    return dispatch(
      persistAutomationInternal({ automation, steps, triggers, model })
    );
  };
};

const cleanUpDeletedBranchesAndErrorSteps = (state, stepSafe) => {
  const stepIds = state.steps.map(({ id }) => id);

  stepSafe
    .filter(({ id }) => !stepIds.includes(id))
    .forEach(({ id }) => {
      // remove branch of deleted step
      if (state.branches[id]) {
        delete state.branches[id];
      }
      // remove error of deleted step
      if (state.errorSteps.some((err) => err === id)) {
        state.deletedSteps = [...state.deletedSteps, id];

        state.errorSteps = state.errorSteps.filter((err) => err !== id);
      }
    });

  return state;
};

Object.assign(persistAutomation, persistAutomationInternal);

const automationsSlice = createSlice({
  name: 'automationEngine',
  initialState: {
    placing: false,
    lastDroppingStep: false,
    stepsInMovingBranch: [],
    movingConditionModal: {
      show: false,
      payload: null,
      isDropOnLastInBranch: false,
      type: null,
    },
    prevSteps: newAutomation.steps,
    interrupting: false,
    stepToDelete: { id: null, type: null },
    editStepInfo: null, // { step }
    createStepInfo: null, // { step, id, parentKey, leaf }
    interacting: false,
    dirty: true, // Tracks whether there have been unpersisted changes
    automation: { name: '', active: false },
    model: null,
    triggers: newAutomation.triggers,
    steps: newAutomation.steps,
    fields: [],
    categories: [],
    branches: {}, // parentKey
    showStats: false,
    stats: automationStats,
    loading: 0,
    persisting: 0,
    toast: null,
    deleteModal: { id: null, type: null },
    validationState: defaultValidationState,
    errorSteps: [],
    deletetedSteps: [],
    dropParent: null,
    allowNoChildStep: null, // flag that is set so the action wizard hides options when required
    newGotoStep: null,
    focusStep: null,
    messageDictionary: {},
    nameError: false,
    conditionDescriptionQue: [],
    goalDescriptionQue: [],
    triggerDescriptionQue: [],
    actionDescriptionQue: [],
    delayDescriptionQue: [],
  },
  reducers: {
    setValidation: (state, { payload }) => {
      state.validationState = payload;
    },
    placing: (state, { payload: step }) => {
      // I.e. when dragging a step around the automation flow
      if (step) {
        state.placing = true;
        state.lastPlacingStep = step;
        if (['condition', 'goal'].includes(step.type)) {
          state.stepsInMovingBranch = getChildrenSteps([step.id], state.steps);
        }
      } else {
        state.placing = false;
        state.stepsInMovingBranch = [];
      }
    },
    interacting: (state, { payload }) => {
      // I.e. when dragging/panning/zooming the automation flow
      state.interacting = Boolean(payload);
      state.interrupting = false;
      state.stepToDelete = { id: null, type: null };
    },
    interrupting: (state, { payload }) => {
      state.interrupting = Boolean(payload);
    },
    // Hack to prevent Confirmation to leave the page on every time the new notification entity added
    dirty: (state, { payload }) => {
      state.dirty = Boolean(payload);
    },
    rollBack: (state, { payload }) => {
      const { steps } = payload;
      state.steps = steps;
      state.interacting = Boolean(payload);
    },
    newGotoStep: (state, { payload }) => {
      const { newGotoStep } = payload;
      state.newGotoStep = newGotoStep;
    },
    drop: (state, { payload }) => {
      // I.e. when dropping a step onto an add button, or
      // selecting a new step type from the add button menu.
      const { step, id, parentKey, leaf, label } = payload;

      state.placing = false;
      state.lastPlacingStep = null;
      state.lastDroppingStep = step;
      state.prevSteps = state.steps;
      state.allowNoChildStep = null;
      state.dropParent = { parentKey, label };

      if (!step.id && getStepConfig(step.type).creatable) {
        state.createStepInfo = {
          step: {
            ...step,
            prefix: getStepConfig(step.type).prefix,
            goalType: getStepConfig(step.type).goalType,
          },
          id,
          parentKey,
          leaf,
          label,
        };

        const allowNoChildStep =
          leaf ||
          getAllowNoChildStep(
            parentKey,
            parentKey, // new so pass the parent
            label?.type,
            state.steps
          );
        state.allowNoChildStep = allowNoChildStep;
        return;
      }

      if (['condition', 'goal'].includes(step.type)) {
        // id is the next step after the dropped position
        state.movingConditionModal.isDropOnLastInBranch = id === null;

        state.movingConditionModal.show = true;
        state.movingConditionModal.payload = {
          step,
          id,
          parentKey,
          leaf,
          label,
        };
        state.movingConditionModal.type = step.type;
      } else {
        moveOrInsertStep(state, {
          step,
          id,
          parentKey,
          leaf,
          label,
        });
      }
    },
    moveCondition: (state, { payload: strategy }) => {
      const step = state.lastDroppingStep;

      state.dirty = true;
      state.prevSteps = state.steps;

      const stepSafe = [...state.steps];

      switch (strategy) {
        case movingStrategies.ADD_STEPS_TO_YES: {
          moveBranch(state, {
            ...state.movingConditionModal.payload,
            strategy: 'yes',
          });
          break;
        }
        case movingStrategies.ADD_STEPS_TO_NO: {
          moveBranch(state, {
            ...state.movingConditionModal.payload,
            strategy: 'no',
          });
          break;
        }
        case movingStrategies.MERGE_PATH_STRATEGY: {
          state.steps = mergePathStrategy(state.steps, step.id);
          break;
        }
        case movingStrategies.LEAVE_BOTH_PATHS_SPLIT_STRATEGY: {
          state.steps = leaveBothStrategy(state.steps, step.id);
          break;
        }
        case movingStrategies.KEEP_ONLY_YES_PATH_STRATEGY: {
          state.steps = keepOnlyStrategy(state.steps, step.id, 'yes');
          break;
        }
        case movingStrategies.KEEP_ONLY_NO_PATH_STRATEGY: {
          state.steps = keepOnlyStrategy(state.steps, step.id, 'no');
          break;
        }
        default: {
          // movingStrategies.DELETE_BOTH_PATH_STRATEGY
          state.steps = deleteBranchStrategy(state.steps, step.id);
        }
      }

      if (
        strategy !== movingStrategies.ADD_STEPS_TO_YES &&
        strategy !== movingStrategies.ADD_STEPS_TO_NO
      ) {
        moveOrInsertStep(state, state.movingConditionModal.payload);
      }

      // if the condition no longer has children and has a branch then delete the branch
      if (!isParentStep(state, step) && state.branches[step.id]) {
        delete state.branches[step.id];
      }
      state = cleanUpDeletedBranchesAndErrorSteps(state, stepSafe);

      state.interrupting = false;
      state.stepsInMovingBranch = [];
      state.movingConditionModal.show = false;
      state.movingConditionModal.payload = null;
      state.movingConditionModal.isDropOnLastInBranch = false;
      state.movingConditionModal.type = null;
    },
    createStep: (state, { payload }) => {
      // I.e. when submitting step creation from a wizard
      const { cancel, step, id, parentKey, leaf, label } = payload;

      if (!cancel && !step.id) {
        if (step?.type === 'go_to_automation_step' && id) {
          state.newGotoStep = { ...step, nextStep: id };
        }
        moveOrInsertStep(state, { step, id, parentKey, leaf, label });
      }

      state.createStepInfo = null;
      state.allowNoChildStep = null;

      // don't save prev steps if go to as we don't know until after
      if (!(step?.type === 'go_to_automation_step' && id)) {
        state.prevSteps = state.steps;
      }
    },
    editStep: (state, { payload }) => {
      // I.e. when selecting edit in the step's three-dot menu, confirming, or cancelling
      const { cancel, begin, step } = payload;
      if (begin) {
        state.editStepInfo = { step: begin };
        const allowNoChildStep = getAllowNoChildStep(
          begin.parentKey,
          begin.id,
          null,
          state.steps
        );
        state.allowNoChildStep = allowNoChildStep;
        return;
      }
      if (cancel) {
        state.editStepInfo = null;
        return;
      }
      if (step) {
        state = editStep(state, { step });
        state.editStepInfo = null;
      }
    },
    updateStep: (state, { payload }) => {
      const { step, entityName, queName } = payload;
      state = updateStep(state, { step, entityName, queName });
    },
    clearDescriptionQue: (state, { payload }) => {
      const { queName } = payload;
      state = clearDescriptionQue(state, { queName });
    },
    updateTrigger: (state, { payload }) => {
      const { trigger, entityName, queName } = payload;
      state = updateTrigger(state, { trigger, entityName, queName });
    },
    moveStepsAfterGotoMove: (state, { payload }) => {
      const { step } = payload;
      state = moveStepsAfterGotoMove(state, {
        step,
        dropParent: state.dropParent,
      });
      state.editStepInfo = null;
      state.dropParent = null;
    },
    deleteStep: (state, { payload: id }) => {
      // I.e. when selecting and confirming deletion in the step's three-dot menu
      state.deleteStepType = steps.action.type;
      state.stepToDelete = { id, type: steps.action.type };
      deleteStep(state, { id });
    },
    forceDeleteStep: (state, { payload: id }) => {
      // I.e. when deleting a step that is used by a goto step
      state.stepToDelete = { id, type: steps.action.type };

      deleteStep(state, { id, force: true });
    },
    deleteConditionRun: (state, { payload: { step, strategy } }) => {
      // I.e. when selecting and confirming deletion in the step's three-dot menu
      state.stepToDelete = { id: step.id, type: steps.trigger.type };
      state.dirty = true;
      state.prevSteps = state.steps;

      const stepSafe = [...state.steps];

      if (strategy === deleteStrategy.MERGE_PATH_STRATEGY) {
        state.steps = mergePathStrategy(state.steps, step.id);
      } else if (strategy === deleteStrategy.LEAVE_BOTH_PATHS_SPLIT_STRATEGY) {
        state.steps = leaveBothStrategy(state.steps, step.id);
      } else if (strategy === deleteStrategy.KEEP_ONLY_YES_PATH_STRATEGY) {
        state.steps = keepOnlyStrategy(state.steps, step.id, 'yes');
      } else if (strategy === deleteStrategy.KEEP_ONLY_NO_PATH_STRATEGY) {
        state.steps = keepOnlyStrategy(state.steps, step.id, 'no');
      } else if (!strategy) {
        state.steps = deleteBranchStrategy(state.steps, step.id);
      }

      state = cleanUpDeletedBranchesAndErrorSteps(state, stepSafe);

      state.interrupting = false;
      state.deleteModal = { id: null, type: null };
    },
    createTrigger: (state) => {
      // I.e. when clicking plus button on trigger's handle
      const newTrigger = {
        ...defaultTrigger,
        id: `${defaultTrigger.id}.${Date.now()}`,
        order: state.triggers.length,
      };
      state.triggers.push(newTrigger);
      state.editStepInfo = { step: newTrigger };
    },
    editTrigger: (state, { payload }) => {
      const { cancel, begin, step } = payload;

      if (begin) {
        state.editStepInfo = { step: begin };
        return;
      }
      if (cancel) {
        state.editStepInfo = null;
        return;
      }
      if (step) {
        state = editStep(state, { step }, 'triggers');
        state.editStepInfo = null;
      }
    },
    deleteTrigger: (state, { payload: id }) => {
      // I.e. when selecting and confirming deletion in the triggers's three-dot menu
      state.stepToDelete = { id, type: steps.action.trigger };
      deleteTrigger(state, { id });
    },
    forceDeleteTrigger: (state, { payload: id }) => {
      // I.e. when deleting a step that is used by a goto step
      state.stepToDelete = {
        id,
        type: steps.action.trigger,
      };
      deleteTrigger(state, {
        id,
        force: true,
      });
    },
    createBranch: (state, { payload: { parentKey, label } }) => {
      // I.e. when selecting a new step type from the add button menu.
      if (parentKey) {
        state.branches[parentKey] = label?.type || true;
      } else if (state.steps.length) {
        // Can only add a trigger branch if there are steps
        state.branches[TRIGGER] = true;
      }
    },
    setAutomation: (state, { payload }) => {
      if (!payload?.revision) {
        state.dirty = true;
      }
      Object.assign(state.automation, payload); // E.g. { name } or { active }
    },
    showStats: (state, { payload }) => {
      state.showStats = Boolean(payload);
    },
    newAutomation: (state) => {
      // Reset most automation state
      Object.assign(state, {
        placing: false,
        lastPlacingStep: null,
        createStepInfo: null,
        interacting: false,
        dirty: true,
        automation: { name: '', active: false },
        triggers: newAutomation.triggers,
        steps: newAutomation.steps,
        branches: {},
        allowNoChildStep: null,
      });
    },
    toast: (state, { payload }) => {
      // add a timestamp to make it unique
      state.toast = { ...payload, ts: Date.now() };
    },
    deleteStepWithModal: (state, { payload }) => {
      const { id, type } = payload;
      const isChildeStep = state.steps.some((el) => el.parentKey === id);
      if (!isChildeStep) {
        state.stepToDelete = { id, type: steps.action.type };
        deleteStep(state, { id });
        return;
      }
      state.deleteModal = { id, type };
    },
    closeDeleteConditionModal: (state) => {
      state.deleteModal = { id: null, type: null };
    },
    closeMoveConditionModal: (state) => {
      state.lastDroppingStep = false;
      state.stepsInMovingBranch = [];
      state.steps = state.prevSteps;
      state.movingConditionModal.show = false;
      state.movingConditionModal.payload = null;
      state.movingConditionModal.isDropOnLastInBranch = false;
      state.movingConditionModal.type = null;
    },
    updateErrors: (state, { payload }) => {
      const { id, action, step = {} } = payload;
      if (
        action === ERROR_COUNT_ACTIONS.add &&
        id &&
        !state.errorSteps.some((err) => err === id) &&
        !state.deletedSteps.some((err) => err === id)
      ) {
        state.errorSteps = [...state.errorSteps, id];
        state = setStepError(state, { step });
      } else if (
        action === ERROR_COUNT_ACTIONS.remove &&
        state.errorSteps.some((err) => err === id) &&
        !state.deletedSteps.some((err) => err === id)
      ) {
        state.deletedSteps = [...state.deletedSteps, id];

        state.errorSteps = state.errorSteps.filter((err) => err !== id);
      }
    },
    prevSteps: (state) => {
      state.prevSteps = state.steps;
    },
    setFocusStep: (state, { payload }) => {
      const { step } = payload;
      state.focusStep = step;
    },
    setMessageDictionary: (state, { payload }) => {
      state.messageDictionary = payload;
    },
    clearNameError: (state) => {
      // add a timestamp to make it unique
      state.nameError = false;
    },
  },
  extraReducers: {
    [loadAutomation.fulfilled]: (state, { payload }) => {
      const {
        automation: { steps: _steps, triggers: _triggers, ...automation },
        conditionIds,
        goalIds,
        actionIds,
        delayIds,
      } = payload;

      const { customObject: model } = automation;

      const { triggers, triggerErrors } = triggersForFlow(
        _triggers,
        model,
        state.messageDictionary
      );
      const { steps, stepErrors } = stepsForFlow(
        _steps,
        model,
        state.messageDictionary
      );
      const errorSteps = [...triggerErrors, ...stepErrors];

      const triggerIds = triggers.map(({ id }) => id);

      Object.assign(state, {
        placing: false,
        lastPlacingStep: null,
        createStepInfo: null,
        interacting: false,
        dirty: false,
        automation,
        model,
        triggers: triggers,
        steps: steps,
        branches: {},
        errorSteps: errorSteps.map(({ id }) => id),
        deletedSteps: [],
        allowNoChildStep: null,
        conditionDescriptionQue: conditionIds,
        goalDescriptionQue: goalIds,
        triggerDescriptionQue: triggerIds,
        actionDescriptionQue: actionIds,
        delayDescriptionQue: delayIds,
      });
      if (!state.triggers.length) {
        state.dirty = true;
        state.triggers = newAutomation.triggers;
      }
      state.loading -= 1;

      if (state.errorSteps.length) {
        const errorMessages = errorSteps.flatMap(({ errors }) => errors);

        state.toast = {
          variant: toastVariant.FAILURE,
          message: errorMessages?.length
            ? `${errorMessages[0]}${
                errorMessages.length > 1
                  ? `${
                      state.messageDictionary.automationFailedPrefix
                    }${errorMessages.length - 1}${
                      state.messageDictionary.automationFailedSuffix
                    }`
                  : '.'
              }`
            : state.messageDictionary.automationFailedToLoad,
        };
      }
    },

    [loadFields.fulfilled]: (state, { payload }) => {
      const { fields, categories } = payload;
      state.fields = fields;
      state.categories = categories;
      state.loading -= 1;
    },

    [loadAutomation.pending]: (state) => {
      state.loading += 1;
    },
    [loadAutomation.rejected]: (state) => {
      state.loading -= 1;
    },
    [loadFields.pending]: (state) => {
      state.loading += 1;
    },
    [loadFields.rejected]: (state) => {
      state.loading -= 1;
    },
    [persistAutomation.fulfilled]: (state, { payload }) => {
      const { steps: _steps, triggers: _triggers, ...automation } = payload;
      const { triggers, triggerErrors } = triggersForFlow(
        _triggers,
        state.model,
        state.messageDictionary
      );
      const { steps, stepErrors } = stepsForFlow(
        _steps,
        state.model,
        state.messageDictionary
      );

      Object.assign(state, {
        dirty: false,
        automation,
        triggers: triggers,
        steps: steps,
        errorSteps: [...triggerErrors, ...stepErrors].map(({ id }) => id),
        deletedSteps: [],
      });
      state.persisting -= 1;
      state.branches = pick(
        // Remove stale branch
        state.branches,
        state.steps.map((s) => s.id).concat(TRIGGER)
      );
    },
    [persistAutomation.pending]: (state) => {
      state.persisting += 1;
    },
    [persistAutomation.rejected]: (state, { error }) => {
      const { original = {} } = error;
      const nameError = Boolean(isNameError(error));

      const errorSteps = (Object.keys(original) || [])
        .map((key) => key)
        // sometimes 'errors' string exists in this array
        .filter(isValidStepId);

      const steps = state.steps.map((step) => ({
        ...step,
        hasError: errorSteps.includes(step.id),
      }));

      Object.assign(state, {
        steps: steps,
        errorSteps: [...errorSteps],
        focusStep: errorSteps.length ? errorSteps[0] : null,
        nameError: nameError,
      });

      state.persisting -= 1;
    },
  },
});

export const actions = {
  ...automationsSlice.actions,
  loadAutomation,
  loadFields,
  persistAutomation,
};

export default automationsSlice;

export function* automationEngineSagas(translations) {
  yield takeLatest(loadAutomation, loadAutomation.saga);
  yield takeLatest(loadFields, loadFields.saga);
  yield takeLeadingAndCancel(persistAutomation, persistAutomation.saga);
  yield takeLatest(loadAutomation.rejected, function* saga({ error }) {
    if (error.code === 404) throw new Error(error);
    history.replace('/automation/new');
    yield put(
      actions.toast({
        variant: toastVariant.FAILURE,
        message: translations.notLoaded,
      })
    );
  });
  yield takeLatest(persistAutomation.rejected, function* saga({ error }) {
    const nameError = isNameError(error);
    yield put(
      actions.toast({
        variant: toastVariant.FAILURE,
        message: nameError ? nameError : translations.error,
      })
    );
  });
  yield takeLatest(persistAutomation.fulfilled, function* saga() {
    yield put(
      actions.toast({
        variant: toastVariant.SUCCESS,
        message: translations.saved,
      })
    );
  });
}
