import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { borderTight } from '../../app/colors';
import Select from './Select';
import IconButton from './IconButton';

const IconButtonPositioned = styled(IconButton)`
  position: relative;
  text-align: unset;
`;

const Menu = styled(Select)`
  position: absolute;
  bottom: -2px;
  .IconButtonMenu__control {
    display: none;
  }
  .IconButtonMenu__menu {
    overflow: hidden; // Primarily for border-radius
    width: auto;
    max-width: 200px;
    ${borderTight};
  }
  ${({ position }) =>
    position === 'right'
      ? css`
          right: 0;
          .IconButtonMenu__menu {
            transform: translateX(-100%);
          }
        `
      : css`
          left: 0;
        `}
`;

export default function IconButtonMenu({
  sizing,
  color,
  className,
  children,
  onOpen,
  onClose,
  position,
  open,
  borderColor,
  ...others
}) {
  const [isOpen, setIsOpen] = useState(
    typeof open === 'boolean' ? open : false
  );
  const targetRef = useRef(null);
  useClickAway(targetRef, () => setIsOpen(false));

  return (
    <IconButtonPositioned
      ref={targetRef}
      sizing={sizing}
      color={isOpen && color ? color.light || color.hover : color}
      className={className}
      borderColor={borderColor}
      onClick={() => {
        if (onOpen) {
          onOpen(isOpen);
        }

        setIsOpen(typeof open === 'boolean' && isOpen ? open : !isOpen);
      }}
    >
      {children}
      {(typeof open === 'boolean' ? open : isOpen) && (
        <Menu
          fullWidth
          position={position}
          classNamePrefix="IconButtonMenu"
          menuIsOpen
          {...others}
        />
      )}
    </IconButtonPositioned>
  );
}

IconButtonMenu.propTypes = {
  sizing: PropTypes.any,
  color: PropTypes.any,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  borderColor: PropTypes.any,
  open: PropTypes.bool,
};

IconButtonMenu.defaultProps = {
  sizing: IconButton.defaultProps.sizing,
  color: IconButton.defaultProps.color,
  onOpen: null,
  onClose: null,
  position: 'left',
  borderColor: null,
  open: undefined,
};
