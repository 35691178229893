import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Switch from 'components/Kizen/Switch';
import Input from 'components/Kizen/Input';
import {
  STEPS,
  // LIST_OF_FIELDS as allFieldTypes,
  LIST_OF_FORM_ACTIVITY_FIELDS as allFieldTypes,
} from 'components/GenericWizard/wizards/field/config';
import FieldType from 'components/GenericWizard/wizards/field/sections/type';
import { gutters } from 'app/spacing';
import Cols from 'components/Layout/Cols';
import Section from '../section';

const exceptions = ['category', 'name', 'displayName', 'type', 'isRequired'];

const FieldSettingsBasic = ({
  updateField,
  resetField,
  updateValidation,
  formData,
  data,
  shownOn,
}) => {
  const { t } = useTranslation();
  const isValid = Boolean(formData.displayName);
  // activities has ActivityRelationshipOptions
  const fieldTypes = allFieldTypes.filter(
    (f) => !f.shownOn || (shownOn && f.shownOn.includes(shownOn))
  );

  useEffect(() => {
    updateValidation(STEPS.FIELD_SETTINGS, isValid);
  }, [isValid, updateValidation]);

  return (
    <>
      <Section index={0} header={t('Field Settings')} isActive>
        <Cols columns={2} gutter={`${gutters.spacing(4)}px`}>
          <Input
            label={t('Field Name')}
            value={formData.displayName}
            placeholder={t('Enter Field Name')}
            onChange={(value) => updateField('displayName', value)}
          />
          <Switch
            label={t('Field is Required')}
            textPlacement="regular-top"
            checked={formData.isRequired}
            value="true"
            onChange={(e) => updateField('isRequired', e.target.checked)}
            disabled={data && data.field && data.field.isHidden}
          />
        </Cols>
      </Section>
      <FieldType
        formData={formData}
        updateField={updateField}
        resetField={resetField}
        updateValidation={updateValidation}
        index={1}
        isActive
        data={data}
        fieldTypes={fieldTypes}
        exceptions={exceptions}
      />
    </>
  );
};

FieldSettingsBasic.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  resetField: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  shownOn: PropTypes.string,
};

FieldSettingsBasic.defaultProps = {
  shownOn: null,
};

export default FieldSettingsBasic;
