import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFlashState from 'hooks/useFlashState';
import TextInput from 'components/Inputs/TextInput';
import BasicModal from 'components/Modals/presets/BasicModal';
import Automation2Service from 'services/Automation2Service';
import { getOriginalError } from 'services/AxiosService';
import { useAutomationsDispatch } from '../store/react';
import { actions } from '../store';
import { toastVariant } from 'components/ToastProvider';

export const AutomationEmailModal = ({ automation, onConfirm, ...others }) => {
  const { t } = useTranslation();
  const [automationEmail, setAutomationEmail] = useState(
    automation.errorNotificationEmail
  );
  const [errors, setErrors] = useFlashState(1500, {});
  const dispatch = useAutomationsDispatch();

  const handleConfirm = async () => {
    try {
      const updatedAutomation = await Automation2Service.update({
        id: automation.id,
        errorNotificationEmail: automationEmail || null,
        lastRevision: automation.revision,
      });
      dispatch(
        actions.toast({
          variant: toastVariant.SUCCESS,
          message: t('Error Notification Settings Updated'),
        })
      );
      onConfirm(updatedAutomation);
    } catch (error) {
      const originalError = getOriginalError(error);
      if (originalError) {
        setErrors(originalError);
      } else {
        dispatch(
          actions.toast({
            variant: toastVariant.FAILURE,
            message: t('Error Notification Settings Update Failed'),
          })
        );
      }
    }
  };

  return (
    <BasicModal
      heading={t('Error Notification Settings')}
      onConfirm={handleConfirm}
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      {...others}
    >
      <TextInput
        label={t('Additional Error Notification Email')}
        labelInfo={t(
          'Error emails will always be sent to the creator by default, but you may also add another email recipient (such as a distribution group)'
        )}
        labelInfoPlacement="top"
        labelInfoMaxWidth={255}
        placeholder={t('Email')}
        value={automationEmail}
        onChange={setAutomationEmail}
        inModal
        validate={
          errors.error_notification_email && {
            message: errors.error_notification_email,
            showMessage: true,
          }
        }
      />
    </BasicModal>
  );
};
