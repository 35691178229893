import BasicModal from 'components/Modals/presets/BasicModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningBody } from './styles';

const ClearModal = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <BasicModal
      heading={t('Confirm Preferences Deletion')}
      buttonText={t('Confirm Delete')}
      {...props}
    >
      <WarningBody>
        {t(
          'Are you sure you want to delete your preferences on the current page?'
        )}
      </WarningBody>
    </BasicModal>
  );
};

export default ClearModal;
