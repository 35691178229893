import React, { useContext, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Overlay from 'react-bootstrap/Overlay';
import KizenTypography from 'app/kizentypo';
import KizenErrorCard from 'components/Kizen/ErrorCard';
import { useFlashTransition } from 'hooks/useFlashState';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { layers } from 'app/spacing';
import { ForceFieldRevalidationContext } from 'ts-components/RecordLayout/blocks/Fields/FieldErrorContext';

const ErrorCard = styled(KizenErrorCard)`
  z-index: ${({ show }) => (show ? layers.content(layers.popoverInModal) : -1)};
  margin-top: -1px;
  ${({ inModal }) =>
    inModal &&
    css`
      z-index: ${layers.content(layers.popoverInModal, 2)};
    `}
  ${({ outOfBoundaries, hideOutOfBoundaries }) =>
    outOfBoundaries && // This prop added automatically by Overlay to its direct child
    hideOutOfBoundaries &&
    css`
      &.show {
        display: none !important; // .show is !important by default and we need to beat it
      }
    `}
`;

export const viewportPopperConfig = {
  modifiers: {
    preventOverflow: {
      // Allows element to appear outside of the target's
      // scrolling parent but not outside the viewport.
      // This is useful in modals for validation messages
      // that appear near the bottom of the modal and
      // should flow on top of the modal footer.
      boundariesElement: 'viewport',
    },
  },
};

export const viewportPopperConfig2 = {
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        // Allows element to appear outside of the target's
        // scrolling parent but not outside the viewport.
        // This is useful in modals for validation messages
        // that appear near the bottom of the modal and
        // should flow on top of the modal footer.
        rootBoundary: 'viewport',
      },
    },
  ],
};

export const modalConstrainPopperConfig = {
  modifiers: {
    preventOverflow: {
      enabled: false,
    },
    autoSizing: {
      enabled: true,
      fn(data) {
        // Ensure the message stays within modal by capping it at the input width
        data.styles.maxWidth = data.offsets.reference.width;
        return data;
      },
    },
  },
};

export default function Validation({
  message,
  showMessage,
  target,
  className,
  popperConfig,
  inModal,
  hideOutOfBoundaries,
  errorPlacement,
  variant,
  onErrorShow,
  onErrorHide,
  ...props
}) {
  useEffect(() => {
    message ? onErrorShow?.() : onErrorHide?.();

    return onErrorHide;
  }, [message, onErrorHide, onErrorShow]);
  return (
    <Overlay
      transition={false}
      target={target}
      show={Boolean(message)}
      placement={errorPlacement}
      popperConfig={
        // Important to pass undefined rather than false/null if we dont want to set a popperConfig
        popperConfig ||
        (inModal && !hideOutOfBoundaries && viewportPopperConfig) ||
        undefined
      }
      {...props}
    >
      <ErrorCard
        className={className}
        light
        inModal={inModal}
        show={showMessage}
        hideOutOfBoundaries={hideOutOfBoundaries}
        duration="300ms"
        data-qa="validation-message"
      >
        <KizenTypography as="span">{message}</KizenTypography>
      </ErrorCard>
    </Overlay>
  );
}

Validation.propTypes = {
  message: PropTypes.node,
  showMessage: PropTypes.bool,
  target: PropTypes.object, // ref.current
  inModal: PropTypes.bool,
  hideOutOfBoundaries: PropTypes.bool,
  errorPlacement: PropTypes.string,
  popperConfig: PropTypes.object,
  onErrorShow: PropTypes.func,
  onErrorHide: PropTypes.func,
};

Validation.defaultProps = {
  message: null,
  showMessage: null,
  target: null,
  inModal: false,
  hideOutOfBoundaries: false,
  errorPlacement: 'bottom-start',
  popperConfig: null,
};

export function useValidation(targetRef, { value, validate }) {
  validate = validate || {};

  const [error, setError] = useState(false);
  const [message, showMessage, flashErrorMessage, cancelFlash] =
    useFlashTransition();
  const validateFull =
    validate.full &&
    ((ev) => {
      const valid = validate.full(value, ev);
      if (valid === false || typeof valid === 'string') {
        if (typeof valid === 'string') {
          flashErrorMessage(valid);
        }
        setError(true);
        return false;
      }
      setError(false);
      return true;
    });

  const maybeFieldContext = useContext(ForceFieldRevalidationContext);

  // This is a bit of a hack because of how aggressively these components are memoized, and how they're used in the
  // record details layout. We want to re-run the validation whenever the field re-renders, but there are so many fields
  // that use this hook that passing down some prop to enable this behavior isn't really feasible. Instead,
  // this context is used to enable this behavior.
  useEffect(() => {
    if (maybeFieldContext?.alwaysRevalidate) {
      validateFull?.();
    }
    // validateFull is not memoized, but we don't need to react to changes because this logic is only
    // necessary when forcing a re-render from somewhere higher in the tree
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maybeFieldContext]);

  return [
    {
      // These props are compatible with TextInput components, and are used internally to TextInput
      error: Boolean(message || validate.message || error),
      onBlur: validateFull,
      onSubmission: validateFull,
      onKeyPress: (ev) => {
        setError(false);
        if (validate.character) {
          const valid = ev.key.length !== 1 || validate.character(ev.key, ev);
          if (valid === false || typeof valid === 'string') {
            ev.preventDefault();
            if (valid === false) {
              setError(true);
            } else {
              flashErrorMessage(valid);
              setError(true);
            }
            return false;
          }
          return true;
        }
      },
    },
    {
      ...validate,
      full: undefined,
      character: undefined,
      // These props are compatible with the Validate component, e.g. used internally to TextInput
      target: targetRef.current,
      message: message || validate.message,
      showMessage: showMessage || validate.showMessage,
      popperConfig: validate.popperConfig,
    },
    {
      validateFull: validateFull || (() => true),
    },
    {
      // Expose the ability to flash an error message from outside this hook
      flashErrorMessage,
      cancelFlash,
    },
  ];
}

export class InputValidationError extends Error {}
