import { INPUT_TYPES } from './styles';

export const getInputType = (type) => {
  switch (type) {
    case INPUT_TYPES.PASSWORD:
      return 'password';
    case INPUT_TYPES.EMAIL:
      return 'email';
    case INPUT_TYPES.SEARCH:
      return 'search';
    case INPUT_TYPES.NUMBER:
    case INPUT_TYPES.CREDIT_CARD:
      return 'number';
    case INPUT_TYPES.DECIMAL:
    case INPUT_TYPES.PRICE:
    case INPUT_TYPES.PRICE_V2:
    case INPUT_TYPES.WHOLE_NUMBER:
    case INPUT_TYPES.TEXT:
    default:
      return 'text';
  }
};

export const validateEmail = (value) =>
  value.includes('@') && value.includes('.');

export const validateNumber = (value) => /^[0-9]*$/.test(value);

export const hasValue = (value) =>
  value != null && !(Array.isArray(value) && value.length === 0);
