import TrendChartManager from '../Trend/TrendChartManager';
import * as am5xy from '@amcharts/amcharts5/xy';
import { colorsPrimary, grayScale } from 'app/colors';
import * as am5 from '@amcharts/amcharts5';

class TrendWithPercentChartManager extends TrendChartManager {
  constructor(element, t, data, secondaryData, opts, ready) {
    super(
      element,
      t,
      data,
      {
        ...opts,
        chartConfig: (root) => ({
          layout: root.verticalLayout,
        }),
      },
      {},
      ready
    );
    this.secondaryData = secondaryData;
    this.secondaryEnabled =
      secondaryData.length > 0 || (opts.forceSecondaryData ?? false);
    this.isValue = opts.isValue;
    this.isWeighted = opts.isWeighted;
  }

  init = () => {
    this.yAxisTitle = this.isValue
      ? `${this.t('Value')} (${this.currencySymbol})`
      : this.t('# Records');
    this.chart.set('dx', -15);
    this.createYAxis(
      `${this.isValue ? this.currencySymbol : ''}${this.defaultFormat}`
    );
    this.maybeCreateSecondaryYAxis();
    this.createXAxis();
    this.createSeries(
      this.isValue
        ? `${
            this.isWeighted ? this.t('WEIGHTED VALUE') : this.t('TOTAL VALUE')
          } (${this.currencySymbol})`
        : this.t('# RECORDS')
    );

    if (this.data.length) {
      this.maybeCreateSecondarySeries();
      this.maybeSetSecondaryData(this.secondaryData);
      this.maybeCreateSecondaryBullets();
    }
    this.setData(this.data);
    this.createBullets();
    if (this.data.length) {
      this.createCursor(this.mobile);
    }
    this.maybeCreateLegend();

    this.show();
  };

  maybeCreateSecondaryYAxis = (format = "#'%'") => {
    if (!this.secondaryEnabled) {
      return;
    }
    this.assertRoot('secondary y-axis');
    this.assertChart('secondary y-axis');
    this.assertYAxis('secondary y-axis');

    this.secondaryYAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {
          opposite: true,
          strokeOpacity: 1,
          minGridDistance: 60,
          stroke: grayScale.mediumLight,
        }),
        numberFormat: format,
        tooltipNumberFormat: format,
        tooltip: this.createStyledTooltip(),
        start: 0,
        end: 0,
        maxDeviation: 0,
      })
    );

    this.secondaryYAxis.children.push(
      this.getYAxisLabel(this.t('% Change'), 10, -10)
    );

    // We want only a single set of horizontal gridlines, so we sync the axis with
    // the primary y-axis
    this.secondaryYAxis.set('syncWithAxis', this.yAxis);

    const renderer = this.secondaryYAxis.get('renderer');

    renderer.labels.template.setAll({
      fill: grayScale.mediumDark,
    });

    renderer.labels.template.setup = (target) => {
      target.set('dx', 10);
    };

    renderer.grid.template.setAll({
      strokeDasharray: [4, 4],
      stroke: grayScale.mediumLight,
      strokeOpacity: 1,
    });
  };

  maybeCreateSecondarySeries = () => {
    if (!this.secondaryEnabled) {
      return;
    }
    this.assertRoot('secondary series');
    this.assertSecondaryYAxis('secondary series');
    this.assertXAxis('secondary series');

    this.secondarySeries = this.chart.series.push(
      am5xy.LineSeries.new(this.root, {
        name: this.t('% CHANGE'),
        xAxis: this.xAxis,
        yAxis: this.secondaryYAxis,
        valueYField: 'value',
        valueXField: 'date',
        stroke: colorsPrimary.green.dark,
        tooltip: this.createStyledTooltip(`${this.valueYAccessor}%`),
        fill: colorsPrimary.green.dark,
      })
    );

    // This attempts to keep the second series of data off the top
    // or bottom axis. This will make sure that it is somewhere in the chart,
    // but doesn't guarantee it's in the center because we want only one set of
    // grid lines so need to adjust based on the primary y-axis data
    this.secondarySeries.events.on('datavalidated', () => {
      const min = this.secondaryYAxis.getPrivate('min');
      const max = this.secondaryYAxis.getPrivate('max');
      const absBound = Math.max(Math.abs(min), Math.abs(max));
      this.secondaryYAxis.setAll({
        min: absBound * -1,
        max: absBound,
      });
    });
  };

  maybeCreateSecondaryBullets = () => {
    if (!this.secondaryEnabled) {
      return;
    }
    this.assertRoot('secondary bullets');
    this.assertSecondarySeries('secondary bullets');

    this.secondarySeries.bullets.push((_root, _series, item) => {
      // Only show the bullet if the value is non-zero
      if (item.dataContext.value !== 0 || this.useAllBullets) {
        return am5.Bullet.new(this.root, {
          sprite: am5.Circle.new(this.root, {
            radius: 3,
            fill: colorsPrimary.green.dark,
            stroke: grayScale.white,
          }),
        });
      }
    });
  };

  maybeSetSecondaryData = (data) => {
    if (!this.secondaryEnabled) {
      return;
    }
    this.assertRoot('secondary data');
    this.assertSecondarySeries('secondary data');
    this.assertSecondaryYAxis('secondary data');
    this.secondarySeries.data.setAll(
      data?.map((d) => ({
        date: this.getMilliseconds(d.date),
        rawDate: d.date,
        value: d.value,
      }))
    );
  };

  maybeCreateLegend = () => {
    if (!this.secondaryEnabled) {
      return;
    }

    this.assertRoot('legend');
    this.assertChart('legend');

    this.legend = this.chart.children.push(
      am5.Legend.new(this.root, {
        x: am5.p50,
        centerX: am5.p50,
        useDefaultMarker: true,
        paddingTop: 4,
        paddingBottom: -2,
      })
    );
    this.legend.data.setAll(this.chart.series.values);
    this.legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });
    this.legend.markers.template.setAll({
      width: 8,
      height: 8,
    });
    this.legend.labels.template.setAll({
      fill: grayScale.dark,
    });
  };

  assertSecondaryYAxis = (entity = 'entity') => {
    if (!this.secondaryYAxis) {
      throw new Error(
        `Cannot create ${entity} without a valid secondary y-axis`
      );
    }
  };

  assertSecondarySeries = (entity = 'entity') => {
    if (!this.secondarySeries) {
      throw new Error(
        `Cannot create ${entity} without a valid secondary series`
      );
    }
  };
}

export default TrendWithPercentChartManager;
