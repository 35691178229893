import React, { createElement, useCallback, useRef } from 'react';
import { types } from '@kizen/page-builder/nodes/types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEditor } from '@craftjs/core';
import { drop, placing } from 'store/pageBuilder/pageBuilder.redux';
import SectionSvg from '../images/section.svg?react';
import RowSvg from 'components/RowPicker/images/row.svg?react';
import FormFieldSvg from '../images/custom-field.svg?react';
import CustomFieldSvg from '../images/form-field.svg?react';
import AttachmentsSvg from '../images/attachments.svg?react';
import ButtonSvg from '../images/button.svg?react';
import HTMLBlockSvg from '../images/code.svg?react';
import TextSvg from '../images/text-regular.svg?react';
import ImageSvg from '../images/image.svg?react';
import DividerSvg from '../images/horizontal-rule.svg?react';
import { DraggableAsFixed } from './DraggableAsFixed';
import { TrayButton } from './Button';

const useDragActions = (type, isCanvas = false) => {
  const dispatch = useDispatch();
  const indicatorRef = useRef();

  useEditor((state) => {
    indicatorRef.current = state.indicator;
  });

  const onDragStart = useCallback(() => {
    dispatch(placing({ type, canvas: isCanvas }));
  }, [dispatch, type, isCanvas]);

  const onDragEnd = useCallback(() => {
    if (indicatorRef.current && !indicatorRef.current.error) {
      const { placement } = indicatorRef.current;
      dispatch(
        drop({
          // index calculation taken from craft: https://github.com/kizen/craft.js/blob/develop/packages/core/src/events/DefaultEventHandlers.ts#L244-L246
          index: placement.index + (placement.where === 'after' ? 1 : 0),
          parentId: placement.parent.id,
        })
      );
    }
    dispatch(placing(null));
  }, [dispatch]);

  return { onDragStart, onDragEnd };
};

/**
 * This hook attaches craftjs event handlers to a dom node that does not yet have a node id.
 * It is very similar to the create connector as described in the [basic tutorial](https://craft.js.org/docs/guides/basic-tutorial#implementing-the-toolbox).
 * Instead of creating a new element it registers callback that are ran on drag start/end events.
 *
 * @remarks The `attach` connector is a custom patch within Kizen's fork of craftjs. Relevant commit: https://github.com/kizen/craft.js/commit/ec40bdf38c21cfb1144956f156c5d844fc8e404e.
 *
 * @param {string} type - The name of the user component registered within craft's [resolver](https://craft.js.org/docs/api/editor) prop.
 * @param {boolean} isCanvas - flag to specify if the user component associated with `type` is a [canvas node](https://craft.js.org/docs/concepts/nodes/#canvas-node). Default false.
 * @returns ref callback function
 */
const useTrayItemConnector = (type, isCanvas = false) => {
  const { onDragStart, onDragEnd } = useDragActions(type, isCanvas);
  const {
    connectors: { attach },
  } = useEditor();

  return useCallback(
    (ref) => {
      attach(ref, type, { onDragStart, onDragEnd });
    },
    [type, onDragStart, onDragEnd, attach]
  );
};

const DraggableButton = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.Button.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        image={<ButtonSvg />}
        label={t('Button')}
        hoverColor={types.Button.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableHTMLBlock = ({ cols = 1, row = null, ...rest }) => {
  const ref = useTrayItemConnector(types.HTMLBlock.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        image={<HTMLBlockSvg />}
        label="HTML"
        hoverColor={types.HTMLBlock.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableAttachments = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.Attachments.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        image={<AttachmentsSvg />}
        label={t('Attachment')}
        hoverColor={types.Attachments.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableCustomField = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.CustomField.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        image={<CustomFieldSvg />}
        label={t('Custom Field')}
        hoverColor={types.CustomField.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableFormField = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.FormField.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        cols={cols}
        image={<FormFieldSvg />}
        label={t('Form Field')}
        hoverColor={types.FormField.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableSurveyField = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.FormField.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        cols={cols}
        image={<FormFieldSvg />}
        label={t('Survey Field')}
        hoverColor={types.FormField.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableImage = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.Image.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        image={<ImageSvg />}
        label={t('Image')}
        hoverColor={types.Image.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableRow = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.Row.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        image={<RowSvg />}
        label={t('Row')}
        hoverColor={types.Row.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableSection = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.Section.type, true);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        image={<SectionSvg />}
        label={t('Section')}
        hoverColor={types.Section.color}
      />
    </DraggableAsFixed>
  );
};
const DraggableText = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.Text.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton
        image={<TextSvg />}
        label={t('Text')}
        hoverColor={types.Text.color}
      />
    </DraggableAsFixed>
  );
};

const DraggableDivider = ({ cols = 1, row = null, ...rest }) => {
  const { t } = useTranslation();
  const ref = useTrayItemConnector(types.Divider.type);

  return (
    <DraggableAsFixed ref={ref} cols={cols} row={row} {...rest}>
      <TrayButton image={<DividerSvg />} label={t('Divider')} />
    </DraggableAsFixed>
  );
};

export const ButtonTrayItem = createElement(DraggableButton);
export const HTMLBlockTrayItem = createElement(DraggableHTMLBlock, {
  group: types.HTMLBlock.group,
});
export const CustomFieldTrayItem = createElement(DraggableCustomField);
export const FormFieldTrayItem = createElement(DraggableFormField);
export const AttachmentsTrayItem = createElement(DraggableAttachments);
export const ImageTrayItem = createElement(DraggableImage);
export const DividerTrayItem = createElement(DraggableDivider);
export const SectionTrayItem = createElement(DraggableSection);
export const RowTrayItem = createElement(DraggableRow);
export const TextTrayItem = createElement(DraggableText);
export const SurveyFieldTrayItem = createElement(DraggableSurveyField);
