import { useRef, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import MultiSelect from 'components/Inputs/MultiSelect';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import SelectAllButton from 'components/Inputs/MultiSelect/SelectAllButton';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';

export const TeamMultiSelect = ({
  onChange,
  teamMemberCount = 0,
  trigger,
  isShowing,
  setIsShowing,
  ...others
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState([]);
  const selectRef = useRef();

  const chosenValueIds = useMemo(
    () => value.map(({ value }) => value),
    [value]
  );

  const [selectProps] = useSelectTypeaheadWithScroll({
    selectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds,
    alwaysOpen: isShowing,
  });

  const handleApply = useCallback(() => {
    onChange((prev) => ({
      ...prev,
      team_member: value.map((el) => el.value).join(','),
      teamMemberCount: value.length,
    }));
    setIsShowing(false);
  }, [value, onChange, setIsShowing]);

  const handleClear = useCallback(() => {
    setValue([]);
  }, []);

  return (
    <>
      <SelectOverlay
        show={isShowing}
        target={trigger.current}
        onHide={handleApply}
        placement="bottom-end"
        popperConfig={{}}
      >
        <MultiSelect
          value={value}
          onChange={setValue}
          placeholder={t('Find Team Members')}
          menuTopButton={<SelectAllButton />}
          menuLeftButton={<ClearSelectButton onClick={handleClear} />}
          menuRightButton={<ApplySelectButton onClick={handleApply} />}
          endAdornment={<IconAdornment icon="search" />}
          {...selectProps}
          {...others}
          ref={selectRef}
        />
      </SelectOverlay>
    </>
  );
};
