import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Notice from 'components/Kizen/Notice';
import { TableBoxesWrapper } from '../tableStyles';

export const APIKeysNotice = styled(Notice)`
  margin-top: 5px;
  margin-bottom: 20px;
`;

export const LastTableBoxesWrapper = styled(TableBoxesWrapper)`
  ${({ noBottomMargin }) =>
    !noBottomMargin &&
    css`
      margin-bottom: 20px;
    `}
`;
