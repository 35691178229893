import { colorsSecondary } from 'app/colors';
import BasicBlock from '../presets/BasicBlock';
import TypeIcon from '../TypeIcon';
import { initiatorTypes } from '../../constants';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import { Text, TextSpan } from 'app/typography';
import { defaultEmployeeString, defaultAutomationString } from '../helpers';

const titleByInitiator = (t) => ({
  [initiatorTypes.siteVisit]: () => t('Site Visit'),
  [initiatorTypes.automation]: defaultAutomationString,
  [initiatorTypes.employee]: defaultEmployeeString,
  [initiatorTypes.employee_api_request]: defaultEmployeeString,
  [initiatorTypes.adClick]: () => t('Ad Click'),
});

const getTitle = (t, event) => (
  <>
    <TextSpan weight="bold" size="micro">
      {titleByInitiator(t)[event.initiator.type](event.initiator)}
    </TextSpan>
    {event.initiator.type === initiatorTypes.employee_api_request ? (
      <>
        &nbsp;<TextSpan size="micro">{t('via API')}</TextSpan>
      </>
    ) : null}
  </>
);

const Heading = ({ text }) => (
  <TextEllipsisWithTooltip>
    <TextSpan weight="bold" size="micro">
      {text}
    </TextSpan>
  </TextEllipsisWithTooltip>
);

const Content = ({ type, isPending, viewValues }) => {
  const { t } = useTranslation();
  const UNKNOWN = t('Unknown');

  switch (type) {
    case 'site_referral':
      return (
        <>
          <Heading text={'Referring Site'} />
          <TextEllipsisWithTooltip>
            <KizenTypography
              type="anchor"
              href={viewValues.content}
              target="_blank"
              size="micro"
            >
              {viewValues.content}
            </KizenTypography>
          </TextEllipsisWithTooltip>
        </>
      );
    case 'social':
      return (
        <>
          <Heading text={'Social Network'} />
          <TextEllipsisWithTooltip>
            <TextSpan size="micro">{viewValues.source}</TextSpan>
          </TextEllipsisWithTooltip>
        </>
      );
    case 'google_ads':
      return (
        <>
          <Heading text={'Campaign'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <Text
                size="micro"
                fontStyle={viewValues.campaign ? 'normal' : 'italic'}
              >
                {viewValues.campaign || UNKNOWN}
              </Text>
            )}
          </TextEllipsisWithTooltip>
          <Heading text={'Ad Group'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <Text
                size="micro"
                fontStyle={viewValues.medium ? 'normal' : 'italic'}
              >
                {viewValues.medium || UNKNOWN}
              </Text>
            )}
          </TextEllipsisWithTooltip>
          <Heading text={'Ad Name'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <Text
                size="micro"
                fontStyle={viewValues.term ? 'normal' : 'italic'}
              >
                {viewValues.term || UNKNOWN}
              </Text>
            )}
          </TextEllipsisWithTooltip>
          <Heading text={'Keyword'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <Text
                size="micro"
                fontStyle={viewValues.content ? 'normal' : 'italic'}
              >
                {viewValues.content || UNKNOWN}
              </Text>
            )}
          </TextEllipsisWithTooltip>
        </>
      );
    case 'facebook_ads':
      return (
        <>
          <Heading text={'Campaign'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <TextSpan size="micro">{viewValues.campaign}</TextSpan>
            )}
          </TextEllipsisWithTooltip>
          <Heading text={'Group'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <TextSpan size="micro">{viewValues.medium}</TextSpan>
            )}
          </TextEllipsisWithTooltip>
          <Heading text={'Ad'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <TextSpan size="micro">{viewValues.term}</TextSpan>
            )}
          </TextEllipsisWithTooltip>
        </>
      );
    case 'utm':
    case 'custom':
      return (
        <>
          <Heading text={'Campaign'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <TextSpan size="micro">{viewValues.campaign}</TextSpan>
            )}
          </TextEllipsisWithTooltip>
          <Heading text={'Medium'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <TextSpan size="micro">{viewValues.medium}</TextSpan>
            )}
          </TextEllipsisWithTooltip>
          <Heading text={'Term'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <TextSpan size="micro">{viewValues.term}</TextSpan>
            )}
          </TextEllipsisWithTooltip>
          <Heading text={'Content'} />
          <TextEllipsisWithTooltip>
            {isPending ? (
              <Text size="micro" fontStyle="italic">
                {t('Pending')}
              </Text>
            ) : (
              <TextSpan size="micro">{viewValues.content}</TextSpan>
            )}
          </TextEllipsisWithTooltip>
        </>
      );
    case 'organic_search':
    case 'direct_traffic':
    default:
      return <></>;
  }
};

const LeadSourceEventBlock = ({ event, ...others }) => {
  const { t } = useTranslation();
  const { data } = event;
  const leadSourceLabels = {
    direct_traffic: (
      <TextSpan weight="bold" size="micro">
        {t('Direct Traffic')}
      </TextSpan>
    ),
    organic_search: (
      <TextSpan weight="bold" size="micro">
        {t('Organic Search')}
      </TextSpan>
    ),
    site_referral: (
      <TextSpan weight="bold" size="micro">
        {t('Site Referral')}
      </TextSpan>
    ),
    google_ads: (
      <TextSpan weight="bold" size="micro">
        {t('Google Ads')}
      </TextSpan>
    ),
    facebook_ads: (
      <TextSpan weight="bold" size="micro">
        {t('Facebook Ads')}
      </TextSpan>
    ),
    utm: (
      <>
        <TextSpan weight="bold" size="micro">
          {data.Display.source}&nbsp;
        </TextSpan>
        <TextSpan weight="bold" size="micro">
          ({t('UTM')})
        </TextSpan>
      </>
    ),
    custom: (
      <>
        <TextSpan weight="bold" size="micro">
          {data.Display.source}&nbsp;
        </TextSpan>
        <TextSpan weight="bold" size="micro">
          ({t('Custom')})
        </TextSpan>
      </>
    ),
    social: (
      <>
        <TextSpan size="micro">{t('Social Referral')}:&nbsp;</TextSpan>
        <TextSpan weight="bold" size="micro">
          {data.Display.source}
        </TextSpan>
      </>
    ),
  };

  return (
    <BasicBlock
      comments={event.comments}
      icon={
        <TypeIcon
          color={colorsSecondary.brown.dark}
          icon="signal-stream"
          size="16px"
        />
      }
      isDeletable={false}
      timestamp={event.created}
      title={
        <>
          <TextSpan size="micro">{t('Lead Source')}&nbsp;</TextSpan>
          {leadSourceLabels[data.type]}&nbsp;
          <TextSpan size="micro">{t('added by')}&nbsp;</TextSpan>
          {getTitle(t, event)}
        </>
      }
      event={event}
      {...others}
    >
      <Content
        type={data.type}
        isPending={data.dataImportPending}
        viewValues={data.Display}
      />
    </BasicBlock>
  );
};

export default LeadSourceEventBlock;
