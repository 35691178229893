import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEditor, useNode } from '@craftjs/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { DEFAULT_LINE_HEIGHT_VALUE } from '@kizen/page-builder';
import {
  useLoadNonWebFont,
  loadFontFamily,
} from '@kizen/page-builder/hooks/useLoadNonWebFont';
import { TextContainer } from '@kizen/page-builder/nodes/containers';
import { layers } from 'app/spacing';
import {
  FloatingRichTextEditor,
  WYSIWYGAIContext,
  useWYSIWYGAIContext,
} from 'components/WYSIWYG';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { useDraggingType } from '../useDraggingType';
import { useShowControls } from '../useShowControls';
import { useFonts } from '../../settings/useFonts';
import Control, { CarouselControl } from '../Control';
import {
  useBuilderContext,
  useIsInBuilderContext,
  useRootNodeProps,
  useSetActiveNode,
} from 'components/PageBuilder/BuilderContext';
import { useSanitizedHtml } from '@kizen/page-builder/internal/hooks/useSanitizedHtml';
import { useTranslation } from 'react-i18next';
import {
  TextUpdateChannelEvents,
  TextUpdateChannelTypes,
  useTextUpdateBroadcastChannel,
} from '../../utils';
import { useFontFamiliesFromText } from './useFontFamiliesFromText';
import { useCycleWithTimer } from '../useCycleWithTimer';
import { getDefaultInitialText } from './CreateText';

const TextView = styled.div`
  ${({ rootNodeFontSize = 14 }) =>
    rootNodeFontSize &&
    css`
      p,
      span,
      a {
        font-size: ${rootNodeFontSize}px;
      }
    `}

  p {
    margin: 0;
    line-height: 1.5em;
    // https://github.com/ueberdosis/tiptap/issues/412#issuecomment-593635371
    // titap issue with removed 'br' in html content
    // it is suggested method for rendering space for empty paragraphs
    &:empty::after {
      content: '\\00A0';
    }
  }
  p * {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

const MaintainScrollPositionFollowingAIRequest = ({ isActive }) => {
  const { isLoading } = useWYSIWYGAIContext();
  const { canvasScrollContainer } = useBuilderContext();
  const currentScroll = useRef(null);

  useEffect(() => {
    if (!isActive || !canvasScrollContainer) {
      return;
    }
    if (isLoading) {
      currentScroll.current = canvasScrollContainer.scrollTop;
    } else if (currentScroll.current !== null) {
      canvasScrollContainer.scrollTo({
        top: currentScroll.current,
        behavior: 'instant',
      });
      currentScroll.current = null;
    }
  }, [isActive, canvasScrollContainer, isLoading]);

  return null;
};

export const ViewText = (props) => {
  const { editorText } = useNode((node) => ({
    editorText: node.data.custom?.text,
  }));
  const sanitized = useSanitizedHtml(editorText);
  const rootNodeProps = useRootNodeProps();
  const fontFamilies = useFontFamiliesFromText(editorText);
  useLoadNonWebFont(fontFamilies);

  return (
    <TextContainer linkColor={rootNodeProps.linkColor} {...props}>
      <TextView
        {...props}
        rootNodeFontSize={rootNodeProps.fontSize}
        dangerouslySetInnerHTML={{ __html: sanitized }}
      ></TextView>
    </TextContainer>
  );
};

export const Text = (props) => {
  const {
    setActiveTextEditorId,
    setTrayProps,
    modalLayer,
    enableMergeFields,
    enableTextLinks,
    enableGoogleFonts,
    enableScriptExecution,
    textLinkOptions,
    javascriptActions,
    mergeFields,
    multipleMergeFields,
    resizingRowId,
    resizingHeight,
    allowRelativeLinks,
    enableAI,
    activeTextEditorId,
    onAIRequestStart,
  } = useBuilderContext();
  const setActiveNode = useSetActiveNode();
  const editorRef = useRef();
  const rootNodeProps = useRootNodeProps();
  const defaultLineHeight =
    rootNodeProps.lineHeight ?? DEFAULT_LINE_HEIGHT_VALUE;
  const { query, hoveredId } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
  }));
  const fonts = useFonts(enableGoogleFonts);
  const business = useSelector((s) => s.authentication.chosenBusiness);
  const showScriptExecutionOption =
    enableScriptExecution && Boolean(javascriptActions?.length);

  const { t } = useTranslation();
  const placeholder = props.placeholder || t('Add Text Here');

  const {
    id,
    editorText,
    node,
    connectors: { connect },
    actions: { setCustom },
  } = useNode((node) => ({
    id: node.id,
    editorText: node.data.custom?.text,
    node,
  }));
  const [forceRender, setForceRender] = useState(0);
  const hasDynamicText = node.data.custom?.dynamicText?.length > 0;
  const allNodes = useMemo(() => {
    return hasDynamicText
      ? [node.data].concat(node.data.custom.dynamicText)
      : [];
  }, [hasDynamicText, node.data]);
  const [cycledNode, isActive, { start, stop, next, prev, setFromIndex }] =
    useCycleWithTimer(allNodes);
  const currentText = cycledNode?.custom?.text ?? editorText;

  const fontFamilies = useFontFamiliesFromText(editorText);
  useLoadNonWebFont(fontFamilies);

  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const onTextUpdateFromSettings = useCallback(
    ({ type, id: payload }) => {
      if (type === TextUpdateChannelTypes.UPDATE_NODE && id === payload) {
        setForceRender((x) => x + 1);
      }
    },
    [id]
  );
  const textUpdatedChannel = useTextUpdateBroadcastChannel(
    onTextUpdateFromSettings
  );

  const onFocus = useCallback(() => {
    setActiveTextEditorId(id);
    setActiveNode(node); // without this there's a chance the row settings are opened if you click near the edge
  }, [node, id, setActiveTextEditorId, setActiveNode]);

  const handleSetActive = () => {
    stop();
    setTrayProps({
      textFilterId: cycledNode?.props?.filterId,
      onTextChange: onTextChangeTrayCallback,
    });
  };

  const onBlur = useCallback(
    ({ editor }) => {
      setActiveTextEditorId(null);
      const text = editor.getHTML();
      if (cycledNode?.props?.filterId) {
        setCustom((current) => {
          const n = current.dynamicText.find(
            (d) => d.props.filterId === cycledNode.props.filterId
          );
          n.custom.text = text;
        });
      } else {
        setCustom((current) => {
          current.text = text;
        });
      }
    },
    [setCustom, cycledNode, setActiveTextEditorId]
  );

  const onChange = useCallback(
    ({ editor }) => {
      // Cannot post updates on blur otherwise there will be a race
      // condition when clicking directly on the edit rules button
      const text = editor.getHTML();
      textUpdatedChannel.postMessage(
        TextUpdateChannelEvents.updateSettings(
          text,
          cycledNode?.props?.filterId
        )
      );
    },
    [textUpdatedChannel, cycledNode?.props?.filterId]
  );

  const onTextChangeTrayCallback = useCallback(
    (textData) => {
      setFromIndex(
        allNodes.findIndex(
          (img) => img.props.filterId === textData.props.filterId
        ) ?? 0
      );
    },
    [setFromIndex, allNodes]
  );

  useEffect(() => {
    if (editorRef.current?.editor) {
      setTimeout(() => {
        setCustom((current) => {
          current.text = editorRef.current.editor.getHTML();
        });
      });
    }
  }, [rootNodeProps.lineHeight, setCustom]);

  useEffect(() => {
    // update the tray props with the currently active text's filterId
    // so the settings data updates when viewing the dynamic content section
    setTrayProps({
      textFilterId: cycledNode?.props?.filterId,
      onTextChange: onTextChangeTrayCallback,
    });
  }, [cycledNode, onTextChangeTrayCallback, setTrayProps]);

  const isInBuilderContext = useIsInBuilderContext();

  // The key is used to trigger updates in two difference scenarios
  // 1. Cycling through nodes (filterId / id)
  // 2. When editing text from the dynamic content modal (forceRender)
  const key = `${cycledNode?.props?.filterId ?? id}_${forceRender}`;

  if (fonts?.length === 0) return null;

  return (
    <Control
      ref={connect}
      label={t('Content')}
      show={showControls}
      color={colorsPrimary.green.dark}
      mayHaveChildren={false}
      fullWidth={true}
      onSetActive={handleSetActive}
      rightControl={
        hasDynamicText ? (
          <CarouselControl
            isActive={isActive}
            onPreviousClick={(ev) => {
              ev.stopPropagation();
              prev();
            }}
            onNextClick={(ev) => {
              ev.stopPropagation();
              next();
            }}
            onPlayClick={(ev) => {
              ev.stopPropagation();
              if (isActive) stop();
              else {
                setActiveNode(null);
                start();
              }
            }}
          />
        ) : null
      }
      {...props}
    >
      <TextContainer
        draggingType={draggingType}
        linkColor={rootNodeProps.linkColor}
        pointerEvents={!resizingRowId && !resizingHeight}
        isInBuilderContext={isInBuilderContext}
        {...props}
      >
        <WYSIWYGAIContext
          businessId={business.id}
          onRequestStart={onAIRequestStart}
        >
          {enableAI && (
            <MaintainScrollPositionFollowingAIRequest
              isActive={id === activeTextEditorId}
            />
          )}
          <FloatingRichTextEditor
            key={key}
            ref={editorRef}
            enableFontSize
            enableFontFamily
            enableLineHeight
            enableColor
            enableHighlight
            enableClearFormatting
            asOverlay
            enableAI={enableAI}
            maxToolbarRows={2}
            enableMergeFields={enableMergeFields}
            mergeFields={mergeFields}
            multipleMergeFields={multipleMergeFields}
            enableLinks={enableTextLinks}
            textLinkOptions={textLinkOptions}
            zIndex={layers.modals(modalLayer, 10)}
            autofocus={currentText === undefined ? 'end' : false}
            defaultFontFamily={rootNodeProps.fontFamily || 'Arial'}
            defaultFontSize={`${rootNodeProps.fontSize || 14}px`}
            defaultLineHeight={defaultLineHeight}
            fonts={fonts}
            initialValue={
              currentText ||
              getDefaultInitialText(
                t('Customize this'),
                t('rich'),
                t('text'),
                t('block')
              )
            }
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            onFontFamilyChange={loadFontFamily}
            allowRelativeLinks={allowRelativeLinks}
            {...(showScriptExecutionOption && {
              scriptExecutionOptions: javascriptActions.map((action) => ({
                label: action.name,
                value: action.id,
              })),
            })}
          />
        </WYSIWYGAIContext>
      </TextContainer>
    </Control>
  );
};
