import { createSlice } from '@reduxjs/toolkit';
import { initialStore } from '../initialStore';
import { defaultToastData } from 'components/ToastProvider';

// Slices
const defaultState = initialStore.toastProvider;

export const toastProviderSlice = createSlice({
  name: 'toastProvider',
  initialState: defaultState,
  reducers: {
    addToast: (state, { payload }) => {
      const { forceShow = true, ...toastProps } = payload;
      const timestamp = new Date().getTime();
      const newToast = [
        {
          ...defaultToastData,
          ...toastProps,
          timestamp,
        },
      ];
      if (forceShow) {
        state.toastData = newToast;
      } else {
        state.toastData = [...state.toastData, ...newToast];
      }
    },
    removeToast: (state, { payload }) => {
      const { timestamp, forceClear } = payload;
      if (forceClear) {
        state.toastData = [];
      } else {
        state.toastData = (state.toastData || []).filter(
          (toast) => toast.timestamp !== timestamp
        );
      }
    },
  },
});

export const { addToast, removeToast } = toastProviderSlice.actions;

export const { reducer } = toastProviderSlice;
