import { useEffect, useState, useRef } from 'react';
import { useNavBarState } from 'app/navBarController';

// The debounce function receives our function as a parameter
export const requestAnimationFrameDebounce = (fn) => {
  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {
    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {
      // Call our function and pass any params we received
      fn(...params);
    });
  };
};

const useDebouncedWindowScroll = () => {
  const [state, setState] = useState({
    x: window.pageXOffset,
    y: window.pageYOffset,
  });

  useEffect(() => {
    const handler = () => {
      setState((state) => {
        const { pageXOffset, pageYOffset } = window;
        //Check state for change, return same state if no change happened to prevent rerender
        //(see useState/setState documentation). useState/setState is used internally in useRafState/setState.
        return state.x !== pageXOffset || state.y !== pageYOffset
          ? {
              x: pageXOffset,
              y: pageYOffset,
            }
          : state;
      });
    };

    const debounced = requestAnimationFrameDebounce(handler);

    //We have to update window scroll at mount, before subscription.
    //Window scroll may be changed between render and effect handler.
    handler();

    // set up debounced listner
    document.addEventListener('scroll', debounced, {
      passive: true,
    });

    return () => {
      document.removeEventListener('scroll', debounced);
    };
  }, []);

  return state;
};

export const useDebouncedPastScrollThreshold = (threshold) => {
  const { height } = useNavBarState();

  const refYOffset = useRef(window.pageYOffset);
  const [state, setState] = useState(
    window.pageYOffset > (threshold || height)
  );

  useEffect(() => {
    const handler = () => {
      if (window.pageYOffset !== refYOffset.current) {
        refYOffset.current = window.pageYOffset;
        setState(() => window.pageYOffset > (threshold || height));
      }
    };

    const debounced = requestAnimationFrameDebounce(handler);

    //We have to update window scroll at mount, before subscription.
    //Window scroll may be changed between render and effect handler.
    handler();

    // set up debounced listner
    document.addEventListener('scroll', debounced, {
      passive: true,
    });

    return () => {
      document.removeEventListener('scroll', debounced);
    };
  }, [height, threshold]);

  return state;
};

export default useDebouncedWindowScroll;
