import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import useField from '../../../hooks/useField';
import { colorsButton } from '../../../app/colors';
import KizenTypography from '../../../app/kizentypo';
import FileLibraryModal from '../../Inputs/FileInput/Modal';
import useModal from '../../Modals/useModal';
import IconAdornment from '../../Inputs/Adornments/IconAdornment';
import { resetButtonCss } from '../../../app/spacing';
import { cleanFiles } from '../../Inputs/helpers';
import { OutlineInputLayout } from '../../Inputs/TextInput/Outline';
import { MaybeInputControl } from '../InputControl';
import Validation, { useValidation } from '../Validation';

const StyledOutlineInputLayout = styled(OutlineInputLayout)`
  margin-top: 12px;
  padding: 0;
`;
const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
`;

const FileInputWrapper = styled.button`
  ${resetButtonCss({ padding: '0 12px' })}
  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}

  display: flex;
  flex: 1;
  width: 100%;
  > div {
    height: 100%;
  }
  > div:first-of-type {
    display: flex;
    flex: 1;
    align-content: center;
  }

  > div:last-of-type {
    display: flex;
    flex: 0 0 20px;

    align-content: center;
    justify-content: flex-end;
  }
`;

const DefaultPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <StyledPlaceholder>
      <KizenTypography as="span" color={colorsButton.blue.default}>
        {t('Add Files')}
      </KizenTypography>
    </StyledPlaceholder>
  );
};

const FilledPlaceholder = ({ children }) => (
  <StyledPlaceholder>
    <KizenTypography as="span" className="file-input-label">
      {children}
    </KizenTypography>
  </StyledPlaceholder>
);

const OutlineFileInput = (props) => {
  const validationRef = useRef();
  const { t } = useTranslation();
  const [validation, validationProps] = useValidation(validationRef, props);
  const [focused, setFocused] = useState(false);

  const { onChange, businessId, ...inputControlProps } = props;
  const {
    disabled,
    error,
    errorPlacement,
    inModal,
    files: filesProp,
    sizing,
    viewable,
    source,
  } = props;
  const [files] = useField(() => {
    return cleanFiles(filesProp);
  }, [filesProp]);
  const [modalProps, , modal] = useModal();

  return (
    <>
      <MaybeInputControl
        forInput
        variant="outline"
        {...{
          ...inputControlProps,
          error: true,
          disabled: false,
          shrink: true,
        }}
        sizing={sizing}
      >
        <StyledOutlineInputLayout
          ref={validationRef}
          disabled={disabled && !viewable}
          viewable={viewable}
          error={error || validation.error}
          focused={focused}
        >
          <FileInputWrapper
            onClick={() => (!disabled || viewable) && modal.show()}
            disabled={disabled && !viewable}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          >
            <div>
              <FilledPlaceholder>
                {' '}
                {`${files.length} ${
                  files.length !== 1 ? t('Files Selected') : t('File Selected')
                }`}
              </FilledPlaceholder>
            </div>
            <div>
              {(!disabled || viewable) && <IconAdornment icon="visible" />}
            </div>
          </FileInputWrapper>
        </StyledOutlineInputLayout>
        <Validation
          inModal={inModal}
          errorPlacement={errorPlacement}
          {...validationProps}
        />
      </MaybeInputControl>
      {modalProps.show && (
        <FileLibraryModal
          source={source}
          files={files}
          onChange={onChange}
          modalProps={modalProps}
          modal={modal}
          disabled={disabled}
          viewable={viewable}
          businessId={businessId}
          publicFile={false}
          show
        />
      )}
    </>
  );
};

OutlineFileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        created: PropTypes.oneOfType([
          PropTypes.oneOf([false]),
          PropTypes.string,
        ]).isRequired,
      }),
    ])
  ).isRequired,
  placeholder: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  shrink: PropTypes.bool,
  sizing: PropTypes.string,
  viewable: PropTypes.bool,
};

OutlineFileInput.defaultProps = {
  label: null,
  placeholder: <DefaultPlaceholder />,
  id: '',
  className: '',
  error: false,
  disabled: false,
  shrink: null,
  sizing: null,
  viewable: true,
};
export default OutlineFileInput;
