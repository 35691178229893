import { isFilterSet, isFilterSetArray } from '@kizen/filters/filter-sets';
import { createSlice } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { initialStore } from '../initialStore';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import i18nInstance from 'i18n-config';
import { EMPLOYEE_ACCESS } from 'components/AccessRequests/utils';
import { RECORD_LIST_PAGE_CONFIG_STRUCTURE } from 'services/TeamMemberService';
import { setQuickFiltersUpToDate } from 'store/utilities';

const getGroupName = (groups, config) => {
  const group = groups.find((e) => e.id === config.group);
  return group ? group.name : '';
};

const rmNegativeValues = (object) => {
  return Object.keys(object).reduce(
    (acc, el) => (object[el] ? { ...acc, [el]: object[el] } : acc),
    {}
  );
};

export const generatePageConfig = (fromApi = {}, fromState = {}) => {
  const defaultPageConfigWhichMustBe = {
    columnOrder: null,
    columns: {}, // id -> { width, visible }
    search: '',
    sort: 'full_name',
    group: null,
    page: 1,
    size: 50,
    filterObject: {},
    quickFilters: {},
    queryFilter: null,
    viewVariant: VIEW_VARIANTS.TABLE,
    currentDashboard: '',
    dashboardState: {},
    quickFilterSettings: [],
    quickFilteringFacets: false,
    selectedLayout: '',
  };

  return Object.keys(defaultPageConfigWhichMustBe).reduce((acc, el) => {
    const compare = (property) => {
      const propertyWhichShouldBeAlwaysFromBackEnd = Object.values(
        RECORD_LIST_PAGE_CONFIG_STRUCTURE
      ).flat();

      const highestPriorityFromAPI = [
        'columnOrder',
        'columns',
        'quickFilters',
        'quickFilterSettings',
        'quickFilteringFacets',
        'selectedLayout',
      ];

      if (fromApi && highestPriorityFromAPI.includes(property)) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      // highest priority from url string
      if (fromState && fromState[property]) return fromState[property];

      // check extra fields
      if (
        propertyWhichShouldBeAlwaysFromBackEnd.includes(property) &&
        fromApi
      ) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      return defaultPageConfigWhichMustBe[property];
    };

    return { ...acc, [el]: compare(el) };
  }, {});
};

// Slices
const defaultState = initialStore.contactPage;

export const contactPageSlice = createSlice({
  name: 'contactPage',
  initialState: defaultState,
  reducers: {
    buildPage: (state, action) => {
      const { page } = action.payload;
      state.isFetching = true;
      state.pageReady = false;
      state.pageConfig = {
        ...state.pageConfig,
        ...rmNegativeValues(page),
      };
    },
    buildPageComplete: (state, action) => {
      const {
        clientObject,
        categorizedFields,
        fields,
        groupsResponse,
        pageResponse,
      } = action.payload;

      const groups = [
        { value: 'none', label: i18nInstance.t('All Contacts') },
      ].concat(
        groupsResponse
          .map((el) => ({ ...el, value: el.id, label: el.name }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
      const pageConfig = generatePageConfig(pageResponse, state.pageConfig);

      state.clientObject = clientObject;
      state.allFields = fields;
      state.fields = fields.filter((f) => !f.isHidden);
      state.groups = groups;
      state.categorizedFields = categorizedFields;
      state.pageConfig = {
        ...pageConfig,
        quickFilters: setQuickFiltersUpToDate(
          state.clientObject,
          pageConfig.quickFilterSettings,
          pageConfig.quickFilters
        ),
      };
      state.filters.name = getGroupName(groups, pageConfig);
    },
    buildPageFinish: (state) => {
      state.isFetching = false;
      state.pageReady = true;
      state.filterGroupReason = null;
      state.needToLoadCounts = true;
    },
    setIsAllContactsGroup: (state, { payload }) => {
      // needs to spread the whole object because the page is memoizing the `state.filters` and passing the result as a prop
      state.filters = {
        ...state.filters,
        // this flag (not in initial state) is needed to set the key prop of ClientFilterDropdown to
        // reset the filter UI when selecting the All Contacts group. We set a UUID rather than true
        // because we use this value as the key.
        isAllContactsGroup: payload && uuidv4(),
      };
    },
    setSavedFilter: (state, { payload: { config, and } }) => {
      state.filters.and = and;
      state.filters.config = config;
      state.filters.numberOfFilters = isFilterSetArray(config)
        ? config.flatMap((q) => q.filters).length
        : isFilterSet(config)
          ? config.filters.length
          : 0;
      state.filters.loading = false;
      state.filters.key = uuidv4();
    },
    clearFilterSearch: (state) => {
      state.pageConfig.search = '';
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    getContactsSuccess: (state, action) => {
      const { payload } = action;
      const { contacts, contactsCount, refreshPage, errors, fetchedCriteria } =
        payload;
      state.isFetchingContacts = false;

      if (refreshPage) {
        // This is a very different situation: we simply want to refresh stale information that is currently in state.
        // We wont be updating the result set, we will just update data for individual records that we already have.
        const contactsById = contacts.reduce((collect, c) => {
          collect[c.id] = c;
          return collect;
        }, {});
        state.contacts.forEach((c) => {
          if (contactsById[c.id]) Object.assign(c, contactsById[c.id]);
        });
        return state;
      }
      state.filters.errors = errors;

      let { checked } = state.selection;

      if (['group', 'all', 'search'].includes(state.selection.value)) {
        state.contacts = contacts.map((el) => ({
          ...el,
          checked: !state.selection.subtractIds.includes(el.id),
        }));
        state.contactsCount = contactsCount;
        state.contactsCountAndNew = contactsCount;
        return state;
      }

      const withCheckedProperty = contacts.map((el) => ({
        ...el,
        checked: state.selection.checkedIds.includes(el.id),
      }));
      // on pagination we change if something was selected on prev page
      if (checked) {
        checked = !withCheckedProperty.find((el) => !el.checked);
      }
      state.contacts = withCheckedProperty;
      state.contactsCount = contactsCount;
      state.contactsCountAndNew = contactsCount;
      state.selection = {
        ...state.selection,
        checked,
      };

      state.fetchedCriteria = fetchedCriteria;

      return state;
    },
    getCountsSuccess: (state, action) => {
      const { payload } = action;
      const { allCount, groupCount } = payload;
      state.groupCount = groupCount;
      state.allCount = allCount;
      state.needToLoadCounts = false;
    },
    setCountsNeedToLoad: (state) => {
      state.needToLoadCounts = true;
    },
    updatePageConfig: (state, action) => {
      const { payload } = action;
      if (payload.group) {
        const group = state.groups.find((g) => g.id === payload.group);
        state.pageConfig = {
          ...state.pageConfig,
          ...payload,
          filterObject: group.config,
        };
      } else if (payload.group === null) {
        state.pageConfig = { ...state.pageConfig, ...payload };
        state.pageConfig.filterObject = {};
        state.filters = initialStore.contactPage.filters;
      } else {
        state.pageConfig = { ...state.pageConfig, ...payload };
      }
    },
    updatePageConfigBySearch: (state, action) => {
      const { payload } = action;
      state.pageConfig = { ...state.pageConfig, ...payload };
    },
    updateContact: (state, action) => {
      const {
        payload: { contact },
      } = action;
      const contacts = state.contacts.map((el) =>
        el.id === contact.id ? { ...el, ...contact } : el
      );
      state.contacts = contacts;
    },
    addNewContact: (state, action) => {
      const { payload } = action;
      const { id, contact } = payload;

      state.allCount += 1;
      state.contactsCountAndNew += 1;
      if (state.pageConfig.viewVariant === VIEW_VARIANTS.TABLE) {
        const contacts = [
          { ...contact, checked: false },
          ...state.contacts.filter((el) => el.id !== id),
        ];
        state.contacts = contacts;
        state.selection.subtractIds.push(id);
      }
    },
    archiveContactSuccess: (state, { payload }) => {
      const { id } = payload;
      const wasIncluded = state.selection.checkedIds.includes(id);
      if (wasIncluded) {
        const checkedIds = state.selection.checkedIds.filter((el) => el !== id);
        const checkedCount = state.selection.checkedCount - 1;
        state.selection = {
          ...state.selection,
          checkedCount,
          checked: checkedCount > 0,
          checkedIds: uniq(checkedIds),
        };
      }
    },
    setCheckedContact: (state, action) => {
      const { payload } = action;
      const { id } = payload;
      const contact = state.contacts.find((el) => el.id === id);
      if (contact) {
        const contacts = state.contacts.map((el) =>
          el.id === id ? { ...el, checked: !el.checked } : el
        );
        const wasIncluded = state.selection.checkedIds.includes(id);
        const checkedCount =
          state.selection.checkedCount + (!contact.checked === true ? 1 : -1);

        const checkedIds = wasIncluded
          ? state.selection.checkedIds.filter((el) => el !== id)
          : state.selection.checkedIds.concat(id);

        let subtractIds = [];
        if (['group', 'all', 'search'].includes(state.selection.value)) {
          subtractIds =
            wasIncluded || !state.selection.subtractIds.includes(id)
              ? state.selection.subtractIds.concat(id)
              : state.selection.subtractIds.filter((el) => el !== id);
        }

        state.contacts = contacts;
        state.selection = {
          ...state.selection,
          checkedCount,
          checkedIds: uniq(checkedIds),
          subtractIds: uniq(subtractIds),
        };
      }
    },
    setCheckedSelection: (state, action) => {
      const { value } = action.payload;
      const checked = value !== 'individual';
      const contacts = state.contacts.map((el) => {
        return { ...el, checked };
      });

      let checkedCount;
      let checkedIds = [];
      const subtractIds = [];
      let isDefaultCase = false;

      switch (value) {
        case 'page':
          checkedCount = !checked ? 0 : contacts.length;
          checkedIds =
            checkedCount === 0 ? [] : state.contacts.map((el) => el.id);
          break;
        case 'search':
          checkedCount = !checked ? 0 : state.contactsCount;
          checkedIds =
            checkedCount === 0 ? [] : state.contacts.map((el) => el.id);
          break;
        case 'group':
          // eslint-disable-next-line no-case-declarations
          const count = state.pageConfig.group
            ? state.groupCount
            : state.allCount;
          checkedCount = !checked ? 0 : count;
          checkedIds =
            checkedCount === 0 ? [] : state.contacts.map((el) => el.id);
          break;
        case 'all':
          checkedCount = !checked ? 0 : state.allCount;
          checkedIds =
            checkedCount === 0 ? [] : state.contacts.map((el) => el.id);
          break;
        default:
          isDefaultCase = true;
          state.selection = {
            ...initialStore.contactPage.selection,
          };
          state.contacts = state.contacts.map((el) => ({
            ...el,
            checked: false,
          }));
          break;
      }
      if (!isDefaultCase) {
        state.selection = {
          ...state.selection,
          value,
          checked,
          checkedCount,
          checkedIds,
          subtractIds,
        };
        state.contacts = contacts;
      }
    },
    createOrUpdateGroupSuccess: (state, { payload }) => {
      const { filterGroup, isPatch } = payload;
      state.pageConfig = {
        ...state.pageConfig,
        queryFilter: null,
      };
      if (isPatch) {
        state.groups = state.groups.map((el) =>
          el.id === filterGroup.id
            ? {
                ...filterGroup,
                value: filterGroup.id,
                label: filterGroup.name,
                employee_access: el.employee_access,
              }
            : el
        );
        return;
      }

      const [allOption, ...rest] = state.groups;

      const groups = rest
        .concat({
          ...filterGroup,
          value: filterGroup.id,
          label: filterGroup.name,
          employee_access: EMPLOYEE_ACCESS.OWNER,
        })
        .sort((a, b) => a.name.localeCompare(b.name));
      state.groups = [allOption].concat(groups);
      state.pageConfig = {
        ...state.pageConfig,
        group: filterGroup.id,
        page: 1,
        queryFilter: null,
      };
      state.selection = initialStore.contactPage.selection;
      state.contacts = state.contacts.map((el) => ({
        ...el,
        checked: false,
      }));
      state.groupCount = state.contactsCount;
      state.needToLoadCounts = true;
    },
    removeGroupSuccess: (state, action) => {
      const { id } = action.payload;
      const groups = state.groups.filter((el) => el.id !== id);
      state.groups = groups;
      state.filters = initialStore.contactPage.filters;
      state.pageConfig = {
        ...state.pageConfig,
        group: null,
        page: 1,
        filterObject: {},
      };
      state.needToLoadCounts = true;
    },
    resetFilterGroupSuccess: (state, { payload }) => {
      state.filterGroupReason = payload.reason;
      state.pageConfig = { ...state.pageConfig, group: null };
      state.pageConfig.filterObject = {};
      state.filters = initialStore.contactPage.filters;
    },
    updateContactsPageColumnWidth: (state, action) => {
      const { payload } = action;
      state.pageConfig = {
        ...state.pageConfig,
        columns: {
          ...state.pageConfig.columns,
          [payload.id]: {
            ...state.pageConfig.columns[payload.id],
            ...payload,
          },
        },
      };
    },
    setGroup: (state, { payload }) => {
      state.filters.name = payload.name;
      state.pageConfig.group = payload.id;
      state.filters.errors = null;
      state.pageConfig.queryFilter = null; // must clear query filter when switching groups
    },
    setPageConfig: (state, action) => {
      const { payload } = action;
      if (!payload) return state;

      const { settings } = payload;
      if (settings) {
        const columnOrder = settings.map(({ id }) => id);
        const columns = settings.reduce((acc, el) => {
          const elementFromRedux = state.pageConfig.columns[el.id];
          return { ...acc, [el.id]: { ...elementFromRedux, ...el } };
        }, {});
        state.pageConfig = {
          ...state.pageConfig,
          columnOrder,
          columns,
        };
      }
      return state;
    },
    setPageFetching: (state) => {
      state.isFetching = true;
    },
    setFilterName: (state, action) => {
      const { payload } = action;
      const { name } = payload;
      state.filters.name = name;
      state.pageConfig = {
        ...state.pageConfig,
        queryFilter: state.pageConfig.queryFilter
          ? {
              ...state.pageConfig.queryFilter,
              maybeName: name,
            }
          : null,
      };
    },
    clearFilter: (state) => {
      state.filters = initialStore.contactPage.filters;
      state.pageConfig.filterObject = {};
      state.pageConfig.quickFilters = {};
      state.pageConfig.search = '';
      state.pageConfig.group = null;
    },
    clearFilterErrors: (state) => {
      state.filters.errors = state.filters.errors?.quickFiltersErrors
        ? { ...state.filters.errors, parsedErrors: [] }
        : null;
    },
    setNumberOfFilters: (state, { payload: numberOfFilters }) => {
      state.filters.numberOfFilters = numberOfFilters;
    },
    getContactsStart: (state, { payload }) => {
      if (payload?.triggerViewLoading) {
        state.isLoadingView = true;
      }
      state.isFetchingContacts = true;
      state.isFiltering = true;
    },
    getContactsFail: (state) => {
      state.isFetchingContacts = false;
    },
    getContactsFinish: (state) => {
      state.isLoadingView = false;
      state.isFiltering = false;
    },
    updateClientObject: (state, { payload }) => {
      state.clientObject = payload;
      state.pageConfig.quickFilters = setQuickFiltersUpToDate(
        state.clientObject,
        state.pageConfig.quickFilterSettings,
        state.pageConfig.quickFilters
      );
    },
  },
});

export const { reducer } = contactPageSlice;
