import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import UUID from 'utility/UUID';
import { useDebounce } from 'react-use';
import { DEFAULT_INPUT_DELAY } from 'utility/config';
import { useTranslation } from 'react-i18next';
import { checkDuplicateName, simulateAxiosError } from '../helpers';
import { TAGS_DICTIONARY } from '../constants';
import { toastVariant, useToast } from 'components/ToastProvider';
import {
  numberOfOptionLimit,
  OPTION_NAME_MAX_LENGTH,
} from 'components/GenericWizard/wizards/field/options/common';
import { snakeToCamelCase } from 'services/helpers';

export const useDynamicTagsAggregator = ({
  onChange,
  field,
  importedOptions,
}) => {
  const [showToast] = useToast();
  const containerRef = useRef(null);
  const { t } = useTranslation();

  const [tags, setTags] = useState([]);
  const stagedTags = useRef(tags);

  const [ordering, setOrdering] = useState('name');
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useEffect(() => {
    if (
      stagedTags.current.length !== tags.length ||
      !stagedTags.current.every(({ id, name }) =>
        tags.some((tag) => tag.id === id && tag.name === name)
      )
    ) {
      onChange(tags.map(({ name }) => ({ name })));
      stagedTags.current = tags;
    }
  }, [tags, onChange]);

  const headData = useMemo(
    () => ({
      meta: {
        sort: getSortValue(ordering),
        onSort: (column, direction) => {
          containerRef.current?.scrollTo(0, 0);
          setOrdering(getOrderingParam({ column, direction }));
        },
      },
    }),
    [ordering]
  );

  const dictionary = useMemo(
    () =>
      (
        TAGS_DICTIONARY[snakeToCamelCase(field?.name)] ||
        TAGS_DICTIONARY.default
      ).getDictionary(t),
    [field?.name, t]
  );

  const onDeleteTag = useCallback(({ id }) => {
    setTags((prevTags) => prevTags.filter((tag) => tag.id !== id));
  }, []);

  const onCreateTag = useCallback(
    (name) => {
      let error = false;
      setTags((prevTags) => {
        if (prevTags.some(checkDuplicateName({ name }))) {
          error = t('{{tagLabel}} already exists.', {
            tagLabel: dictionary.tagLabel || t('Tag'),
          });
          return prevTags;
        } else if (prevTags.length >= numberOfOptionLimit) {
          error = t(
            'You have exceeded the maximum allowable options for this field type {{numberOfOptionLimit}}, so the option was not added.',
            { numberOfOptionLimit }
          );
          return prevTags;
        } else if (!name || name.length > OPTION_NAME_MAX_LENGTH) {
          error = t('Option name must be less than 256 characters');
          return prevTags;
        }
        return [...prevTags, { id: `tmp_${UUID.generate()}`, name }];
      });
      if (error) {
        return simulateAxiosError(error);
      }
    },
    [t, dictionary.tagLabel]
  );

  useEffect(() => {
    if (importedOptions && importedOptions.length > 0) {
      let trimmedValues = importedOptions;
      if (importedOptions?.length > numberOfOptionLimit) {
        trimmedValues = importedOptions?.slice(
          0,
          numberOfOptionLimit - importedOptions.length
        );
        const message = t(
          'You have exceeded the maximum allowable options for this field type {{numberOfOptionLimit}}, so some options were not added.',
          { numberOfOptionLimit }
        );
        showToast({
          message,
          variant: toastVariant.FAILURE,
        });
      }
      setTags((prev) => [
        ...prev,
        ...trimmedValues.map(({ name }) => ({
          id: `tmp_${UUID.generate()}`,
          name,
        })),
      ]);
    }
  }, [importedOptions, onCreateTag, showToast, t]);

  const onUpdateTag = useCallback(
    (name, tag) => {
      let error = false;
      setTags((prevTags) => {
        if (prevTags.some(checkDuplicateName({ name }))) {
          error = t('{{tagLabel}} already exists.', {
            tagLabel: dictionary.tagLabel || t('Tag'),
          });
          return prevTags;
        } else if (!name || name.length > OPTION_NAME_MAX_LENGTH) {
          error = t('Option name must be less than 256 characters');
          return prevTags;
        }
        const index = prevTags.findIndex(({ id }) => id === tag.id);
        const shallowCopy = [...prevTags];
        shallowCopy.splice(index, 1, { ...tag, name });
        return shallowCopy;
      });
      if (error) {
        return simulateAxiosError(error);
      }
    },
    [t, dictionary.tagLabel]
  );

  const options = useMemo(() => {
    const { column, direction } = getSortValue(ordering);
    return tags
      .filter(({ name }) =>
        name.toLowerCase().includes(debouncedSearch.toLowerCase())
      )
      .sort(({ [column]: a }, { [column]: b }) => {
        return a.localeCompare(b) * (direction === 'asc' ? 1 : -1);
      });
  }, [tags, debouncedSearch, ordering]);

  useDebounce(() => setDebouncedSearch(search), DEFAULT_INPUT_DELAY, [search]);

  return [
    {
      search,
      onChangeSearch: setSearch,
      onCreateTag,
      dictionary,
    },
    {
      tags: options,
      onDeleteTag,
      onUpdateTag,
      tableRef: containerRef,
      showTagLink: false,
      headData,
      dictionary,
    },
  ];
};
