import { useEffect, useMemo, useState } from 'react';
import { useBoundingRect } from 'hooks/useBoundingRect';
import { clamp } from 'utility/clamp';

const DEFAULT_OPTION_HEIGHT = 32;
const DEFAULT_THRESHOLD = 0.5;
const MAX_OPTIONS_SHOWN = 11;
const TOP_NAV_HEIGHT = 56;
const BUILDER_VERTICAL_PADDING = 40;

/**
 * Calculates a Select element's position on the screen and returns the proper `menuPlacement`
 * prop. If `numOptions` is supplied, 'bottom' will be returned if there is enough room below
 * to show all options. If `numOptions` is not supplied, menuPlacement will be based off of the
 * `threshold`. This defaults to returning 'bottom' when the element is in the upper half of
 * the screen and 'top' when in the lower half.
 *
 * @param {number} numOptions - length of the options list provided to a Select component.
 * @param {number} optionsHeight - height in pixels of each <li> option element (default 32).
 * @param {number} threshold - the exact screen position to change the menuPlacement. Position
 * values greater than the threshold return 'top', otherwise 'bottom' (default 0.5).
 * @param {Element} scrollTarget - the DOM element of the Select element's scrollable container.
 * If provided, `menuPlacement` will be recalculated on the container's scroll and resize events.
 * @returns [ref, 'top' | 'bottom']
 */
export const useSelectMenuPlacement = ({
  numOptions,
  optionHeight = DEFAULT_OPTION_HEIGHT,
  threshold = DEFAULT_THRESHOLD,
  scrollTarget,
} = {}) => {
  const [scrollTargetHeight, setScrollTargetHeight] = useState(0);
  const [ref, rect] = useBoundingRect({
    scrollTarget,
    deps: [scrollTargetHeight],
  });
  const thold = threshold || DEFAULT_THRESHOLD;
  const optHeight = optionHeight || DEFAULT_OPTION_HEIGHT;

  const menuPlacement = useMemo(() => {
    if (numOptions) {
      const optionsHeight = clamp(numOptions, 0, MAX_OPTIONS_SHOWN) * optHeight;
      return rect.bottom + optionsHeight > window.innerHeight
        ? rect.top < window.innerHeight - rect.bottom // still return 'bottom' if there is more room below than above
          ? 'bottom'
          : 'top'
        : 'bottom';
    }

    const verticalPosition =
      rect.bottom /
      (window.innerHeight - TOP_NAV_HEIGHT - BUILDER_VERTICAL_PADDING);
    return verticalPosition >= thold ? 'top' : 'bottom';
  }, [rect, thold, optHeight, numOptions]);

  const observer = useMemo(() => {
    let timeoutId;
    return new ResizeObserver((entries) => {
      if (entries[0]) {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setScrollTargetHeight(entries[0].contentRect.height);
        }, 300);
      }
    });
  }, []);

  useEffect(() => {
    if (scrollTarget) {
      observer.observe(scrollTarget);
      return () => observer.unobserve(scrollTarget);
    }
  }, [observer, scrollTarget]);

  return [ref, menuPlacement];
};
