import { useAsync } from 'react-use';
import { isFieldAccessible } from 'services/FieldService';
import CustomObjectsService from 'services/CustomObjectsService';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import { fieldResolutionOptionsConst } from 'pages/AutomationEngine/dialogs/ActionWizard/subsections/ModifyRelatedEntities/common';
import { EMPTY_ARRAY, truePredicate } from 'utility/fieldHelpers';

const resolutionOptions = [
  {
    label: 'Overwrite',
    value: 'overwrite',
  },
  {
    label: 'Only Add Options',
    value: 'add_only',
  },
];

const getResolutionOptions = (t, predicate = truePredicate) =>
  [
    {
      value: fieldResolutionOptionsConst.OVERWRITE,
      label: t('Overwrite'),
      filterable: false,
    },
    {
      value: fieldResolutionOptionsConst.ONLY_UPDATE_BLANK,
      label: t('Only Update Blank'),
      filterable: false,
    },
    {
      value: fieldResolutionOptionsConst.ONLY_ADD_OPTIONS,
      label: t('Only Add Options'),
      filterable: true,
    },
  ].filter(predicate);

const getResolutionValue = (val, t) => {
  const { value, label } = val || {};
  switch (value) {
    case fieldResolutionOptionsConst.OVERWRITE:
      return { value, label: label || t('Overwrite') };
    case fieldResolutionOptionsConst.ONLY_UPDATE_BLANK:
      return { value, label: label || t('Only Update Blank') };
    case fieldResolutionOptionsConst.ONLY_ADD_OPTIONS:
      return { value, label: label || t('Only Add Options') };
    default:
      return {
        value: fieldResolutionOptionsConst.OVERWRITE,
        label: t('Overwrite'),
      };
  }
};

const defaultsToUseDisplayName = [DEFAULT_FIELD_NAMES.percentageChanceToClose];

// we wrap the value in an object so it firces re-render
const getInputValue = (field, fieldValue) => {
  const { value } = fieldValue ?? '';
  switch (field.fieldType) {
    case FIELD_TYPES.Checkboxes.type: {
      return value ? value.map(({ id }) => id) : [];
    }
    case FIELD_TYPES.Radio.type:
    case FIELD_TYPES.Dropdown.type:
    case FIELD_TYPES.Status.type: {
      return value?.id || '';
    }
    case FIELD_TYPES.TeamSelector.type: {
      return value || '';
    }
    case FIELD_TYPES.Relationship.type: {
      return value || null;
    }
    default: {
      return value;
    }
  }
};

// Right now our API support only the fields listed below
const CUSTOM_DEFAULT_FIELDS_TO_IGNORE = [
  DEFAULT_FIELD_NAMES.name,
  DEFAULT_FIELD_NAMES.actualCloseDate,
];
const AUTOMATION_CONTACT_DEFAULT_FIELDS_TO_IGNORE = [
  DEFAULT_FIELD_NAMES.firstName,
  DEFAULT_FIELD_NAMES.lastName,
  DEFAULT_FIELD_NAMES.created,
  DEFAULT_FIELD_NAMES.email,
  DEFAULT_FIELD_NAMES.businessPhone,
  DEFAULT_FIELD_NAMES.mobilePhone,
  DEFAULT_FIELD_NAMES.homePhone,
  DEFAULT_FIELD_NAMES.birthday,
  DEFAULT_FIELD_NAMES.updated,
  DEFAULT_FIELD_NAMES.tags,
];
// Right now our API support only the fields listed below
const DEFAULT_FIELDS_TO_IGNORE = [
  ...CUSTOM_DEFAULT_FIELDS_TO_IGNORE,
  ...AUTOMATION_CONTACT_DEFAULT_FIELDS_TO_IGNORE,
];

const fieldsFilter = (field) =>
  isFieldAccessible({ field, allowHidden: true }) &&
  (!field.isDefault || !DEFAULT_FIELDS_TO_IGNORE.includes(field.name));

const useGetFieldOptions = ({ customObjectId }) => {
  const { value = EMPTY_ARRAY, loading } = useAsync(async () => {
    try {
      const { categorizedFields } =
        await CustomObjectsService.getCategorizedModelFields(customObjectId);
      return categorizedFields.map((cat) => ({
        label: cat.name,
        options: cat.fields.reduce((acc, field) => {
          if (
            field.access?.edit &&
            !field.isReadOnly &&
            (!field.isDefault || !DEFAULT_FIELDS_TO_IGNORE.includes(field.name))
          ) {
            acc.push({
              label: field.displayName,
              value: field.id,
              fieldData: field,
            });
          }
          return acc;
        }, []),
      }));
    } catch (err) {
      return EMPTY_ARRAY;
    }
  }, [customObjectId]);

  return { options: value, loadingOptions: loading };
};

export {
  resolutionOptions,
  getResolutionOptions,
  getResolutionValue,
  getInputValue,
  useGetFieldOptions,
  fieldsFilter,
  defaultsToUseDisplayName,
};
