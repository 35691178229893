import React from 'react';
import * as PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { grayScale } from 'app/colors';

import {
  StyledToastContainer,
  StyledToast,
  StyledToastBody,
  StyledCloseButton,
} from './styles';

const defaultStyle = {
  transition: `transform 200ms, opacity 200ms ease`,
  opacity: 0,
};
const transitionStyles = {
  entering: { transform: 'scale(0.5)', opacity: 0 },
  entered: { transform: 'scale(1.0)', opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const Toast = ({
  children,
  show,
  variant,
  onClose,
  className,
  yOffset,
  ...others
}) => {
  return (
    <Transition
      in={show}
      timeout={{
        appear: 100,
        enter: 300,
        exit: 300,
      }}
      appear
      unmountOnExit
    >
      {(state) => (
        <StyledToastContainer
          className={className}
          aria-live="polite"
          aria-atomic="true"
          zIndex={others.zIndex}
        >
          <StyledToast
            variant={variant}
            onClose={onClose}
            yOffset={yOffset}
            {...others}
            style={{ ...defaultStyle, ...transitionStyles[state] }}
          >
            <StyledToastBody
              data-qa-element="toast"
              data-qa-variant={variant}
              fullWidth={others.fullWidth}
            >
              {children}
              <StyledCloseButton color={grayScale.white} onClick={onClose} />
            </StyledToastBody>
          </StyledToast>
        </StyledToastContainer>
      )}
    </Transition>
  );
};

Toast.propTypes = {
  // Show or hide toast
  show: PropTypes.bool.isRequired,
  // Toast Appearance
  variant: PropTypes.oneOf(['success', 'alert', 'failure']),
  // Close Function
  onClose: PropTypes.func.isRequired,
  // yOffset from top of browser, or an array of breakpoints and offsets
  yOffset: PropTypes.oneOf([PropTypes.number, PropTypes.object]),
  // classname
  className: PropTypes.string,
};

Toast.defaultProps = {
  show: false,
  variant: 'success',
  yOffset: null,
  className: '',
};

export { Toast };
