import { createElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCreateItemByType } from 'store/pageBuilder/selectors';
import { useBuilderContext } from 'components/PageBuilder/BuilderContext';
import { useAddNewNode } from '../useAddNewNode';
import { DEFAULT_EMAIL_TEXT_PROPS, DEFAULT_FORM_TEXT_PROPS } from './props';

export const getDefaultInitialText = (mainText, rich, text, block) => {
  return `<p><span>${mainText} <strong>${rich}</strong> <em>${text}</em> <u>${block}</u>.</span></p>`;
};

const CreateText = ({ defaultProps }) => {
  const { t } = useTranslation();
  const addNewNode = useAddNewNode();
  const { setActiveTextEditorId } = useBuilderContext();
  const textItem = useSelector((s) => getCreateItemByType(s, 'Text'));

  useEffect(() => {
    if (textItem) {
      const node = addNewNode({
        ...textItem,
        ...defaultProps,
        custom: {
          text: getDefaultInitialText(
            t('Customize this'),
            t('rich'),
            t('text'),
            t('block')
          ),
        },
      });
      setActiveTextEditorId(node.id);
    }
  }, [addNewNode, setActiveTextEditorId, t, defaultProps, textItem]);

  return null;
};

export const EmailTextCreator = createElement(CreateText, {
  defaultProps: DEFAULT_EMAIL_TEXT_PROPS,
});

export const FormTextCreator = createElement(CreateText, {
  defaultProps: DEFAULT_FORM_TEXT_PROPS,
});
