import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getChosenBusiness } from 'store/authentication/selectors';
import { FILTER_TYPES } from './types';
import { useMemo } from 'react';
import {
  checkDataExistsInFilter,
  filterDataMatches,
} from 'services/CustomObjectsService';
import KizenTypography from 'app/kizentypo';
import { colorsSecondary } from 'app/colors';

const TimeZoneFilterWarning = ({
  type,
  groups,
  filter,
  hasStageRecordFilter,
  hideWarnings = false,
}) => {
  const { t } = useTranslation();
  const chosenBusiness = useSelector(getChosenBusiness);
  const isCustomFilterType = type === FILTER_TYPES.CUSTOM;
  const showWarning = useMemo(() => {
    return isCustomFilterType
      ? hasStageRecordFilter
      : groups.some(({ config }) =>
          checkDataExistsInFilter(config, filterDataMatches.timezoneRelated)
        );
  }, [isCustomFilterType, groups, hasStageRecordFilter]);

  const isDifferentTimezones = useMemo(() => {
    const dateSettings = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    const businessTime = new Date(
      new Date().toLocaleDateString('en-US', {
        timeZone: chosenBusiness.timezone.name,
        ...dateSettings,
      })
    );
    const userTime = new Date(
      new Date().toLocaleDateString('en-US', dateSettings)
    );
    return +businessTime !== +userTime;
  }, [chosenBusiness.timezone.name]);

  return showWarning && !hideWarnings && isDifferentTimezones ? (
    <KizenTypography fontStyle="italic" color={colorsSecondary.red.dark}>
      <br />*
      {t(
        'One of the {{area}} contains a/an "Entered/Left Stage" filter that will operate in the business timezone ({{timezone}}).',
        {
          area: isCustomFilterType ? t('filter') : t('group'),
          timezone: chosenBusiness.timezone.display_name,
        }
      )}
    </KizenTypography>
  ) : null;
};

export default TimeZoneFilterWarning;
