// class to handle local storage for login redirect
import { LocalStorage } from './LocalStorage';
export class RedirectLocalStorage extends LocalStorage {
  constructor(
    key = 'key',
    { serialize = JSON.stringify, deserialize = JSON.parse } = {}
  ) {
    super(key, { serialize, deserialize });
  }

  get path() {
    return this.val;
  }

  set path(path) {
    this.val = this.santitizePath(path);
  }

  santitizePath(path) {
    const illegalPaths = [
      '/dashboard',
      '/login',
      '/choose-business',
      '/not-found',
      '/reset',
      '/freetrial',
      '/free-trial-ended',
      '/permissions-needed',
      '/sso',
    ];
    return illegalPaths.some((illegal) => String(path).startsWith(illegal))
      ? null
      : path;
  }
}
