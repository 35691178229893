import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Section from 'components/Wizards/shared/components/section';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import { implicitSectionPropTypes } from 'components/Wizards/shared/types';
import { gutters } from 'app/spacing';
import ActivitiesAreaDisplaySettings from './areas/Activities';
import PipelineAreaDisplaySettings from './areas/Pipeline';
import { CHART_TYPES } from 'components/Wizards/shared/components/chart/types';
import { useTranslation } from 'react-i18next';
import { getDefaultName } from 'components/Wizards/utils';
import EmailsAreaDisplaySettings from './areas/Emails';
import MarketingAreaDisplaySettings from './areas/Marketing';

const DisplaySettingsWizardSection = ({
  index,
  active,
  done,
  last,
  mobile,
  ...rest
}) => {
  const { state } = useContext(WizardStateContext.Context);
  const { t } = useTranslation();
  const {
    value,
    reportType,
    pipelineDetail,
    currencySymbol,
    area,
    display,
    includedRecords,
    valueToDisplay,
    selectedActivity,
    levelOfDetail,
  } = state;

  const title = rest.title || t('Display Settings');

  const isSummaryDisplay = display === CHART_TYPES.SUMMARY;
  const isTrendDisplay = display === CHART_TYPES.TREND;

  const defaultName = useMemo(() => {
    return getDefaultName(
      {
        area,
        value,
        pipelineDetail,
        reportType,
        currencySymbol,
        isTrendDisplay,
        includedRecords,
        valueToDisplay,
        selectedActivity,
        levelOfDetail,
      },
      t
    );
  }, [
    area,
    currencySymbol,
    pipelineDetail,
    value,
    reportType,
    isTrendDisplay,
    includedRecords,
    valueToDisplay,
    selectedActivity,
    levelOfDetail,
    t,
  ]);

  useMetadataKey('defaultName', defaultName);
  useMetadataKey('isSummaryDisplay', isSummaryDisplay);
  useMetadataKey('isTrendDisplay', isTrendDisplay);

  return (
    <>
      <Section
        index={index}
        title={title}
        active={active}
        done={done}
        last={last}
      >
        <StyledWizardField top={gutters.spacing(0)}>
          {/* These "Area" components control their own rendering and will only be visible when appropriate */}
          <PipelineAreaDisplaySettings
            defaultName={defaultName}
            mobile={mobile}
            active={active || done}
          />
          <ActivitiesAreaDisplaySettings
            defaultName={defaultName}
            mobile={mobile}
            active={active || done}
          />
          <EmailsAreaDisplaySettings
            defaultName={defaultName}
            mobile={mobile}
            active={active || done}
          />
          <MarketingAreaDisplaySettings
            defaultName={defaultName}
            mobile={mobile}
            active={active || done}
          />
        </StyledWizardField>
      </Section>
    </>
  );
};

DisplaySettingsWizardSection.propTypes = {
  ...implicitSectionPropTypes,
  title: PropTypes.string,
};

export default DisplaySettingsWizardSection;
