import {
  INLINE_TABLE_DEFAULT_WIDTH_IN_PIXELS,
  getColumnNumeric,
} from 'components/Fields/InlineTableFieldInput';
import { getCellForFieldType } from 'components/Layout/BigTableLayout/InlineCells';
import { isStandardObject } from 'components/Modals/utilities';
import { getBasicColumns } from 'pages/Common/RelatedObjectFields/custom/columns';
import { CustomFieldDataCell } from 'pages/CustomObjects/RecordsPage/Table';
import CustomObjectsService from 'services/CustomObjectsService';
import PipelineService from 'services/PipelineService';
import { SortableHeadCell } from './shared';
import { FIELD_TYPES } from 'utility/constants';

export const getColumnsForObject = (
  {
    columns,
    fields,
    model,
    preReleaseFeatures,
    fieldsById,
    onResizeColumnWidth,
    onResizeStop,
    getColumnWidth,
    narrowestWidth,
    minWidth,
    resizable,
  },
  t
) => {
  const serviceToUse = isStandardObject(model)
    ? CustomObjectsService
    : PipelineService;

  const basicColumns = getBasicColumns({
    model,
    teams: [],
    stages: [],
    fields,
    preReleaseFeatures,
    serviceToUse,
    t,
    supportsInfiniteScroll: true,
  });

  return [
    ...columns.map((c) => {
      if (basicColumns[c.name]) {
        return {
          id: c.id,
          label: c.label,
          ...basicColumns[c.name],
          headCell: (
            <SortableHeadCell
              column={c}
              getColumnWidth={getColumnWidth}
              onResizeColumnWidth={onResizeColumnWidth}
              onResizeStop={onResizeStop}
              narrowestWidth={narrowestWidth}
              minWidth={minWidth}
              resizable={resizable}
              numeric={
                // Copy the numeric prop from the original headCell
                basicColumns[c.name].headCell?.props?.numeric
              }
            />
          ),
        };
      }

      const field = fieldsById?.[c.id];

      return {
        id: c.id,
        label: c.label,
        headCell: (
          <SortableHeadCell
            column={c}
            getColumnWidth={getColumnWidth}
            onResizeColumnWidth={onResizeColumnWidth}
            onResizeStop={onResizeStop}
            narrowestWidth={narrowestWidth}
            minWidth={minWidth}
            resizable={resizable}
            numeric={getColumnNumeric(field)}
            hasSorting={field.fieldType !== FIELD_TYPES.Relationship.type}
          />
        ),
        ...(!preReleaseFeatures && {
          cell: (
            <CustomFieldDataCell
              field={{ ...field, isReadOnly: true }}
              model={model}
              fetchUrl={`custom-objects/${model.id}`}
            />
          ),
        }),
        bodyCell: {
          field: { ...field, isReadOnly: true },
          fetchUrl: `custom-objects/${model.id}`,
          model,
          minWidth: INLINE_TABLE_DEFAULT_WIDTH_IN_PIXELS,
          Component: getCellForFieldType(field?.fieldType),
          forceReadOnly: true,
          editable: false,
          disabled: true,
          isCustomObjects: true,
        },
      };
    }),
  ];
};
