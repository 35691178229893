import { MouseEventHandler, useContext } from 'react';
import { Colors, NotificationColors } from '../Colors/definitions';
import { getTailwindSafeColorClassName } from '../Colors/utils';
import { getKDSClasses, merge } from '../util';
import { disableAnimations, isDebug } from '../debug';
import { ValidIcons, allIcons } from './library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KDSIcon, SecondaryIconPosition } from './types';
import { Typography } from '../Typography/Typography';
import { Spacer } from '../Spacer/Spacer';
import { SizeOptions, kdsSize } from '../sizing';
import { Tooltip } from '../Tooltip/Tooltip';
import { Position } from '../Tooltip/types';
import { useTooltip } from '../Tooltip/useTooltip';
import { TranslationContext } from '../TranslationContext';

export interface IconProps {
  icon: ValidIcons;
  color?: Colors;
  className?: string;
  size?: Omit<SizeOptions, '2xl' | '4xl'>;
  counter?: number;
  badge?: boolean;
  badgeColor?: NotificationColors;
  tooltipEnabled?: boolean;
  tooltipTitleOverride?: string;
  tooltipDescriptionOverride?: string;
  tooltipPosition?: Position;
  // This is an internal prop that is used to pass a className straight
  // to the FontAwesomeIcon component. It should never be used by a consuming application, only
  // other KDS components are allowed to use this, and only when absolutely necessary.
  __internalFontAwesomeClassName?: string;
  onMouseLeave?: () => void;
  onMouseOver?: MouseEventHandler<unknown>;
}

const getBackgroundColorForBadge = (
  color: NotificationColors = 'information'
) => {
  switch (color) {
    case 'information':
      return 'bg-feedback-toast-information';
    case 'success':
      return 'bg-feedback-toast-success';
    case 'warning':
      return 'bg-feedback-toast-warning';
    case 'error':
      return 'bg-feedback-toast-error';
  }
};

const getHeightClassName = (size: SizeOptions) => {
  switch (size) {
    case 'xs':
      return 'text-size-reset h-xs';
    case 'sm':
      return 'text-size-reset h-sm';
    case 'md':
      return 'text-size-reset h-md';
    case 'base':
      return 'text-size-reset h-base';
    case 'lg':
      return 'text-size-reset h-lg';
    case 'xl':
      return 'text-size-reset h-xl';
    case '2xl':
      return 'text-size-reset h-2xl';
    case '3xl':
      return 'text-size-reset h-3xl';
    case '4xl':
      return 'text-size-reset h-4xl';
  }
};

const getWidthClassName = (size: SizeOptions) => {
  switch (size) {
    case 'xs':
      return 'text-size-reset w-xs';
    case 'sm':
      return 'text-size-reset w-sm';
    case 'md':
      return 'text-size-reset w-md';
    case 'base':
      return 'text-size-reset w-base';
    case 'lg':
      return 'text-size-reset w-lg';
    case 'xl':
      return 'text-size-reset w-xl';
    case '2xl':
      return 'text-size-reset w-2xl';
    case '3xl':
      return 'text-size-reset w-3xl';
    case '4xl':
      return 'text-size-reset w-4xl';
  }
};

const getCountBadgeSize = (size: SizeOptions) => {
  switch (size) {
    case 'xs':
    case 'sm':
      return 'w-[6px] h-[6px]';
    case 'md':
      return 'w-[7px] h-[7px]';
    case 'base':
      return 'w-[8px] h-[8px]';
    case 'lg':
      return 'w-[9px] h-[9px]';
    case 'xl':
      return 'h-[16px] min-w-[16px]';
    case '2xl':
      return 'h-[20px] min-w-[20px]';
    case '3xl':
      return 'h-[28px] min-w-[28px]';
    case '4xl':
      return 'h-[40px] min-w-[40px]';
  }
};

const getEmptyBadgeSize = (size: SizeOptions) => {
  if (kdsSize(size).isLessThan('xl')) {
    return getCountBadgeSize(size);
  }

  switch (size) {
    case 'xl':
      return 'h-[10px] w-[10px]';
    case '2xl':
      return 'h-[12px] w-[12px]';
    case '3xl':
      return 'h-[13px] w-[13px]';
    case '4xl':
      return 'h-[13px] w-[13px]';
  }
};

const getCountTextSize = (size: SizeOptions) => {
  switch (size) {
    case 'xl':
    case '2xl':
      return 'sm';
    case '3xl':
    case '4xl':
      return 'base';
  }
};

const getCountPosition = (size: SizeOptions) => {
  if (kdsSize(size).isLessThan('xl')) {
    const badgeSize = getCountBadgeSize(size);
    if (badgeSize.includes('9px')) {
      return 'left-[calc(100%-4px)] top-[-4px]';
    } else if (badgeSize.includes('8px')) {
      return 'left-[calc(100%-4px)] top-[-3px]';
    } else if (badgeSize.includes('7px')) {
      return 'left-[calc(100%-3px)] top-[-3px]';
    } else if (badgeSize.includes('6px')) {
      return 'left-[calc(100%-2px)] top-[-2px]';
    }
  }

  return 'left-[calc(100%+5px)] top-0';
};

const getEmptyBadgePosition = (size: SizeOptions) => {
  if (kdsSize(size).isLessThan('xl')) {
    return getCountPosition(size);
  }

  const badgeSize = getEmptyBadgeSize(size);
  if (badgeSize?.includes('12px')) {
    return 'left-[calc(100%-7px)] top-[-5px]';
  } else if (badgeSize?.includes('13px')) {
    return 'left-[calc(100%-7px)] top-[-5px]';
  } else if (badgeSize?.includes('10px')) {
    return 'left-[calc(100%-4px)] top-[-3px]';
  }
};

const getSecondaryPositioniningClassName = (
  position?: SecondaryIconPosition
) => {
  switch (position) {
    case 'center': {
      return 'left-0 right-0 top-0 bottom-0 flex justify-center items-center';
    }
    case 'bottom-right': {
      return 'right-[-60%] bottom-[-20%]';
    }
  }

  return '';
};

const getDataAttributes = (iconDefinition: KDSIcon, counter?: number) => {
  return {
    'data-icon-semantic-name': iconDefinition.semanticName,
    'data-icon-animation': iconDefinition.animation,
    'data-icon-rotation': iconDefinition.rotation,
    'data-icon-compound': Boolean(iconDefinition.compoundIconSettings),
    'data-icon-compound-primary':
      iconDefinition.compoundIconSettings?.primary?.iconName,
    'data-icon-compound-secondary':
      iconDefinition.compoundIconSettings?.secondary?.iconName,
    'data-icon-fa': iconDefinition.fontAwesomeName,
    'data-icon-counter': counter ? String(counter) : undefined,
  };
};

const counterString = (counter = 0, hasCounter: boolean) => {
  const counterValue = hasCounter ? String(Math.round(counter)) : '';
  if (hasCounter && counter > 99) {
    return '99+';
  }

  return counterValue;
};

export const Icon = (props: IconProps) => {
  const {
    icon,
    color,
    className,
    size: _size = 'md',
    counter,
    badge,
    badgeColor,
    __internalFontAwesomeClassName,
    onMouseLeave,
    onMouseOver,
    tooltipEnabled,
    tooltipTitleOverride,
    tooltipDescriptionOverride,
    tooltipPosition = 'top',
  } = props;

  const size = _size as SizeOptions;

  const customColorClassName = getTailwindSafeColorClassName(color, 'text');
  const outlineColorClassName = getTailwindSafeColorClassName(color, 'border');
  const { debugClassName } = isDebug();
  const hasCounter = typeof counter === 'number';
  const heightClassName = getHeightClassName(size);
  const widthClassName = getWidthClassName(size);

  const counterValue = counterString(counter, hasCounter);
  const iconDefinition = allIcons[icon] as any;
  const { t } = useContext(TranslationContext);
  const { triggerProps, targetProps, showTooltip, tooltipProps } =
    useTooltip(tooltipEnabled);

  // If there is no valid icon, render a placeholder to indicate the error
  // rather than fail silently
  if (!iconDefinition) {
    return (
      <span
        className={merge(
          getKDSClasses('icon', 'invalid'),
          'relative flex',
          className,
          debugClassName,
          heightClassName
        )}
      >
        �
      </span>
    );
  }

  const title = iconDefinition.title;
  const description = iconDefinition.description;
  const faIcon = iconDefinition.icon;
  const rotation = iconDefinition.rotation;
  const compoundPrimaryIcon = iconDefinition.compoundIconSettings?.primary;
  const compoundSecondaryIcon = iconDefinition.compoundIconSettings?.secondary;
  const secondaryClassName =
    iconDefinition.compoundIconSettings?.secondaryClassName;
  const primaryColorDefinition = iconDefinition.colorDefinition?.primary;
  const secondaryColorDefinition = iconDefinition.colorDefinition?.secondary;
  const CustomIcon = iconDefinition.customIcon;
  const compoundOutline = iconDefinition.compoundIconSettings?.outline;
  const translatedTitle = title?.(t);
  const translatedDescription = description?.(t);
  const noTooltipText = Boolean(
    !translatedTitle &&
      !tooltipTitleOverride &&
      !translatedDescription &&
      !tooltipDescriptionOverride
  );

  const counterBadge = hasCounter ? (
    <div
      className={merge(
        'rounded-full flex items-center justify-center absolute text-font-white',
        getCountBadgeSize(size),
        getCountPosition(size),
        getBackgroundColorForBadge(badgeColor)
      )}
    >
      {kdsSize(size).isLessThan('xl') ? null : (
        <>
          <Spacer size={5} mode="vertical" />
          <Typography size={getCountTextSize(size)}>{counterValue}</Typography>
          <Spacer size={5} mode="vertical" />
        </>
      )}
    </div>
  ) : badge ? (
    <div
      className={merge(
        'rounded-full absolute',
        getEmptyBadgeSize(size),
        getEmptyBadgePosition(size),
        getBackgroundColorForBadge(badgeColor)
      )}
    />
  ) : null;

  if (compoundSecondaryIcon && (compoundPrimaryIcon || compoundOutline)) {
    const secondaryPositionClassName = getSecondaryPositioniningClassName(
      iconDefinition.compoundIconSettings?.secondaryPosition
    );

    const styleProp = {
      '--fa-rotate-angle': rotation ? `${rotation}deg` : undefined,
    } as any;

    return (
      <span
        className={merge(
          getKDSClasses('icon', iconDefinition.semanticName),
          'relative flex w-fit',
          className,
          debugClassName,
          compoundOutline ? merge(heightClassName, widthClassName) : ''
        )}
        {...getDataAttributes(iconDefinition, counter)}
        {...triggerProps}
        {...targetProps}
      >
        {compoundPrimaryIcon ? (
          <FontAwesomeIcon
            className={merge(
              heightClassName,
              customColorClassName || 'text-inherit',
              'hover:text-inherit',
              rotation ? 'fa-rotate-by' : ''
            )}
            icon={compoundPrimaryIcon}
            style={styleProp}
            fixedWidth={iconDefinition.fixedWidth}
          />
        ) : null}
        {compoundOutline ? (
          <div
            className={merge(
              heightClassName,
              widthClassName,
              'border-1 border-solid',
              compoundOutline === 'circle' ? 'rounded-full' : '',
              outlineColorClassName || 'border-inherit'
            )}
          />
        ) : null}

        <span className={merge('absolute', secondaryPositionClassName)}>
          {showTooltip && tooltipEnabled && !noTooltipText ? (
            <Tooltip
              position={tooltipPosition}
              title={tooltipTitleOverride ?? translatedTitle}
              text={tooltipDescriptionOverride ?? translatedDescription}
              modalCompatability
              {...tooltipProps}
            />
          ) : null}
          {compoundSecondaryIcon ? (
            <FontAwesomeIcon
              className={merge(
                heightClassName,
                customColorClassName || 'text-inherit',
                'hover:text-inherit',
                rotation ? 'fa-rotate-by' : '',
                secondaryClassName
              )}
              icon={compoundSecondaryIcon}
              style={styleProp}
              fixedWidth={iconDefinition.fixedWidth}
            />
          ) : null}
        </span>
        {counterBadge}
      </span>
    );
  }

  if (CustomIcon) {
    return (
      <span
        className={merge(
          getKDSClasses('icon', iconDefinition.semanticName),
          'relative flex w-fit',
          className,
          debugClassName
        )}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        {...triggerProps}
        {...targetProps}
        {...getDataAttributes(iconDefinition, counter)}
      >
        <CustomIcon
          className={merge(
            heightClassName,
            customColorClassName || 'text-inherit',
            'hover:text-inherit',
            rotation ? 'fa-rotate-by' : '',
            __internalFontAwesomeClassName
          )}
        />
        {showTooltip && tooltipEnabled && !noTooltipText ? (
          <Tooltip
            position={tooltipPosition}
            title={tooltipTitleOverride ?? translatedTitle}
            text={tooltipDescriptionOverride ?? translatedDescription}
            modalCompatability
            {...tooltipProps}
          />
        ) : null}
        {counterBadge}
      </span>
    );
  }

  return (
    <span
      className={merge(
        getKDSClasses('icon', iconDefinition.semanticName),
        'relative flex w-fit',
        className,
        debugClassName
      )}
      {...getDataAttributes(iconDefinition, counter)}
      {...triggerProps}
      {...targetProps}
    >
      <FontAwesomeIcon
        className={merge(
          heightClassName,
          customColorClassName || 'text-inherit',
          'hover:text-inherit',
          rotation ? 'fa-rotate-by' : '',
          __internalFontAwesomeClassName
        )}
        spin={iconDefinition.animation === 'spin' && !disableAnimations()}
        icon={faIcon}
        style={
          {
            '--fa-rotate-angle': rotation ? `${rotation}deg` : undefined,
            '--fa-primary-color': primaryColorDefinition
              ? `var(--color-${primaryColorDefinition})`
              : undefined,
            '--fa-secondary-color': secondaryColorDefinition
              ? `var(--color-${secondaryColorDefinition})`
              : undefined,
          } as any
        }
        fixedWidth={iconDefinition.fixedWidth}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
      {showTooltip && tooltipEnabled && !noTooltipText ? (
        <Tooltip
          position={tooltipPosition}
          title={tooltipTitleOverride ?? translatedTitle}
          text={tooltipDescriptionOverride ?? translatedDescription}
          modalCompatability
          {...tooltipProps}
        />
      ) : null}
      {counterBadge}
    </span>
  );
};
