export const getOrderingParam = ({ direction, column }) => {
  const fields = {}; // Any exceptions can be mapped here
  return `${direction === 'desc' ? '-' : ''}${fields[column] || column}`;
};

export const getSortValue = (ordering) => {
  const columns = {}; // Any exceptions can be mapped here
  const direction = ordering && ordering[0] === '-' ? 'desc' : 'asc';
  const field = ordering && ordering[0] === '-' ? ordering.slice(1) : ordering;
  return {
    direction,
    column: columns[field] || field,
  };
};
