import { useCallback, useEffect, useState } from 'react';
import { toastVariant, useToast } from 'components/ToastProvider';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@kizen/kds/Button';
import Section from '../../../section';
import { STEPS } from '../config';
import { FIELD_TYPES } from 'utility/constants';
import ImportFieldsOptionsModal from './importFieldsOptions';
import { numberOfOptionLimit, OPTION_NAME_MAX_LENGTH } from '../options/common';

const importOptionsSupportedList = [
  FIELD_TYPES.Dropdown.type,
  FIELD_TYPES.Radio.type,
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Checkboxes.type,
  FIELD_TYPES.Status.type,
];

const FieldOptions = ({
  updateField,
  updateValidation,
  formData,
  index,
  isActive,
  isValidated,
  component: Component,
  data,
  optionsLimit,
}) => {
  const [showToast] = useToast();
  const { t } = useTranslation();
  const [showImportModal, setShowImportModal] = useState(false);
  const [importedOptions, setImportedOptions] = useState([]);
  const updateOptsValidation = useCallback(
    (isValid) => {
      updateValidation(STEPS.FIELD_OPTIONS, isValid);
    },
    [updateValidation]
  );

  useEffect(() => {
    if (!isValidated) {
      updateValidation(STEPS.FIELD_OPTIONS, true);
    }
  }, [isValidated, updateValidation]);

  const importOptionSupported = importOptionsSupportedList.includes(
    formData?.type
  );

  const handleImport = useCallback(
    (values) => {
      let trimmedValues = values;

      if (
        formData.type !== FIELD_TYPES.DynamicTags.type &&
        values.length + formData.options.length > numberOfOptionLimit
      ) {
        const message =
          t(
            'You have exceeded the maximum allowable options for this field type '
          ) +
          `(${numberOfOptionLimit}),` +
          t(' so some options were not added.');

        showToast({
          message,
          variant: toastVariant.FAILURE,
        });

        trimmedValues = values.slice(
          0,
          numberOfOptionLimit - formData.options.length
        );
      }
      if (
        !trimmedValues.every((opt) => opt.name.length <= OPTION_NAME_MAX_LENGTH)
      ) {
        throw new Error(
          t('Each option name must be less than 256 characters.')
        );
      }
      setImportedOptions(trimmedValues);
      setShowImportModal(false);
    },
    [formData, showToast, t]
  );

  return (
    <>
      <Section
        header={t('Field Options')}
        index={index}
        isLastIndex
        isActive={isActive}
      >
        {importOptionSupported && (
          <Button
            color="secondary"
            onClick={() => setShowImportModal(true)}
            variant="text"
          >
            {t('Import Field Options')}
          </Button>
        )}
        <Component
          updateValidation={updateOptsValidation}
          formData={formData}
          updateField={updateField}
          optionsLimit={optionsLimit}
          data={data}
          importedOptions={importedOptions}
        />
      </Section>
      {showImportModal && (
        <ImportFieldsOptionsModal
          formData={formData}
          show={showImportModal}
          onClose={() => setShowImportModal(false)}
          onConfirm={handleImport}
        />
      )}
    </>
  );
};

FieldOptions.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isValidated: PropTypes.bool.isRequired,
  formData: PropTypes.object,
  component: PropTypes.func,
  data: PropTypes.object,
};

FieldOptions.defaultProps = {
  formData: {},
  component: null,
  data: {},
};

export default FieldOptions;
