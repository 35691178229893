import SmallTableCard from 'components/Layout/SmallTableCard';
import {
  CardToolbarTitle,
  CardToolbarSection,
  CardToolbarSearch,
} from 'components/Layout/CardToolbar';
import { useTranslation } from 'react-i18next';

import { TRow } from 'components/Kizen/Table';

import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

import { useMemo, useState } from 'react';
import { get, isString, orderBy } from 'lodash';
import Loader from 'components/Kizen/Loader';
import {
  SizedCell,
  SortableHeadCell,
  StyledCardToolbar,
  StyledBasicTable,
  StyledTableScrollContainer,
} from './styled';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const getColumnSizings = () => ({
  leftSpacer: { width: '20px' },
  label: { width: '420px', padding: '10px' },
});

export const getColumns = ({ t }) => {
  const columnSizings = getColumnSizings();
  return [
    {
      id: 'spacer',
      headCell: <SizedCell {...columnSizings.leftSpacer} />,
      cell: <SizedCell {...columnSizings.leftSpacer} />,
    },
    {
      id: 'label',
      headCell: (
        <SortableHeadCell
          by="label"
          hasSorting
          label={t('Permission Group Name')}
          {...columnSizings.label}
        />
      ),
      cell: <SizedCell {...columnSizings.label} />,
      format: (label, group) => {
        return (
          <TextEllipsisWithTooltip
            type={'link'}
            to={`/settings/team?pg=${group.value}`}
            target="_blank"
          >
            {label}
          </TextEllipsisWithTooltip>
        );
      },
    },
  ];
};

const GroupsSection = ({ groups, loading }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({ column: 'label', direction: 'asc' });

  const groupsSorted = useMemo(() => {
    const results = (groups || []).filter((item) =>
      item[sort.column].toLowerCase().includes(search.toLowerCase())
    );
    return orderBy(
      results,
      [
        (item) => {
          const iteratees = get(item, sort.column);
          return isString(iteratees) ? iteratees?.toLowerCase() : iteratees;
        },
      ],
      sort.direction
    );
  }, [groups, sort, search]);

  const columns = useMemo(() => {
    return getColumns({
      t,
    });
  }, [t]);

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => setSort({ column, direction }),
      },
    }),
    [sort, setSort]
  );

  return (
    <SmallTableCard singleColumn>
      <StyledCardToolbar tall>
        <CardToolbarTitle>{t('Object On in Groups')}</CardToolbarTitle>
        <CardToolbarSection>
          <CardToolbarSearch
            placeholder={t('Find Groups')}
            value={search}
            onChange={setSearch}
            loading={false}
          />
        </CardToolbarSection>
      </StyledCardToolbar>
      {loading ? (
        <Loader loading />
      ) : (
        <>
          <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
            <ScrollContainerBlocker compactHeader top="-2" />
          </ScrollContainerBlockerWrapper>
          <StyledTableScrollContainer bottom refresh={[groupsSorted]}>
            <StyledBasicTable
              stickyHeader
              height={0}
              head={<TRow head columns={columns} data={headData} />}
            >
              {(groupsSorted || []).map((group) => (
                <TRow key={`${group.value}`} columns={columns} data={group} />
              ))}
            </StyledBasicTable>
          </StyledTableScrollContainer>
        </>
      )}
    </SmallTableCard>
  );
};
export default GroupsSection;
