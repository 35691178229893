import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  grayScale,
  tableHover,
  colorsPrimary,
  colorsButton,
  colorsSecondary,
} from 'app/colors';
import { gutters, layers } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import {
  Table as KizenTable,
  HeaderSizedCell as KizenHeaderSizedCell,
  SizedCell as KizenSizedCell,
  SortableHeadCell as KizenSortableHeadCell,
} from 'components/Kizen/Table';
import { fontSizes } from 'app/typography';
import { StatusCodeTypes } from './EmailIntegration';
import TableScrollContainer from 'components/Tables/ScrollContainer';

const HEADER_HEIGHT = 52;
const ROW_HEIGHT = 40;

export const SizedCell = styled(KizenSizedCell)`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
  }
`;

export const HeaderSizedCell = styled(KizenHeaderSizedCell)`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
  }
`;

export const SortableHeadCell = styled(KizenSortableHeadCell)`
  > div {
    // Fixes small alignment issue with cell text
    line-height: 0;
    margin-top: 1px;
    button {
      margin-left: ${gutters.spacing(2)}px;
    }
  }
`;

export const TableText = styled(Text)`
  text-align: center;
  margin: ${gutters.standard};
`;

export const TextCell = styled.div`
  font-size: 14px;
  line-height: 100%;
`;

export const StatusCell = styled.div`
  font-size: 14px;
  line-height: 100%;
  ${(props) => {
    switch (props.statusCode) {
      case StatusCodeTypes.NOT_SETUP:
      case StatusCodeTypes.ERROR:
        return css`
          color: ${colorsSecondary.red.dark};
        `;
      case StatusCodeTypes.SYNCING:
      case StatusCodeTypes.SYNCED:
        return css`
          color: ${colorsPrimary.green.dark};
        `;
      default:
        return css`
          color: ${colorsPrimary.green.dark};
        `;
    }
  }}
`;

export const TableTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 20px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 20}px;
  h3 {
    margin-top: -1px; // Design nudge
    line-height: 1; // Sidestep bootstrap
  }
  button {
    margin: 0;
    margin-top: 1px; // Design nudge
    background: none;
    outline: none;
    padding: 0;
    color: ${colorsButton.green.default};
    &:hover {
      color: ${colorsButton.green.hover};
    }
  }
`;

export const TableBoxesWrapper = styled.div`
  padding: 0 ${gutters.spacing(4)}px;
`;

export const Table = styled(KizenTable)`
  width: 100%;
  border-collapse: separate; // Ensures bottom border on sticky th doesn't scroll away
  margin-bottom: ${gutters.spacing(4, -16)}px;
  thead > tr {
    height: ${HEADER_HEIGHT}px;
    th {
      position: sticky;
      top: 0;
      z-index: ${layers.content(0, 1)}; // Appear above cell contents
      background-color: ${grayScale.white};
      border-bottom: 1px solid ${grayScale.mediumLight};
    }
  }
  tbody > tr {
    height: ${ROW_HEIGHT}px;
    &:hover {
      background-color: ${tableHover};
    }
  }
  td {
    > * {
      font-size: unset;
      font-size: ${fontSizes.text};
      font-weight: normal;
      line-height: 100%;
      color: ${grayScale.dark};
      > p {
        font-size: 1em;
      }
    }
  }
`;

export const TableScrollWrapper = styled.div`
  overflow-y: none;
  overflow-x: auto;
  &::-webkit-scrollbar-track:vertical {
    // A hack for scrollbar to appear below sticky header.
    // Much better than the alternative hacks to detach/size/scroll the header,
    // especially since we already have a custom scrollbar.
    margin-top: calc(50px - 1px);
    border-top: 1px solid ${grayScale.mediumLight};
  }
`;

export const StyledTableScrollContainer = styled(TableScrollContainer)`
  & .BodyForSync {
    flex-direction: column;
  }
`;

export const ColorLabelWrapper = styled(KizenTypography)`
  margin-right: -10px;
`;

export const SearchContainer = styled.div`
  margin-top: -10px;
  > :not(:first-child) {
    margin-left: ${gutters.spacing(4)}px;
  }
`;
