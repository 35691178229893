import { DASHBOARD } from 'queries/query-keys';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { useParams, useHistory } from 'react-router-dom';
import useDelayedMutation from 'hooks/useDelayedMutation';
import { updatePageConfigQuery } from 'queries/models/teamMember';
import { getDashboardQuery } from 'queries/dashboard';
import {
  DEFAULT_DATE_FILTERS,
  fetchAndRepairDashboardConfig,
  formatDate,
  getFilterValues,
  indexMapping,
} from 'components/DashboardGrid/utils';
import { useResetAll } from '../context/dataManager/getters';
import { EMPLOYEE_ACCESS } from 'components/AccessRequests/utils';
import { useBusinessTimezoneRanges } from 'components/Kizen/DateRangePicker/range';

export const CONFIG_KEY = 'dashboard_list';
export const DATE_FILTER_ALL_TIME_INDEX = 6;
export const HOMEPAGE_CONFIG_KEY = 'homepage_list';

const getConfigKey = (type) => {
  if (type === 'homepage') {
    return HOMEPAGE_CONFIG_KEY;
  }
  return CONFIG_KEY;
};

const getBasePath = (type) => {
  if (type === 'homepage') {
    return '/home';
  }
  return '/dashboard';
};

const getDashboardId = (type, params) => {
  if (type === 'homepage') {
    return params.id;
  }
  return params.dashboardId;
};

const useDashboardConfig = (
  dashboards = [],
  skipRouter = false,
  type,
  overridePublishedState = true,
  respectBusinessTimezone = false
) => {
  const configKey = getConfigKey(type);
  const basePath = getBasePath(type);
  const queryClient = useQueryClient();
  const params = useParams();
  const dashboardId = getDashboardId(type, params);
  const history = useHistory();

  const resetAll = useResetAll();

  const handleGetPageConfig = useCallback(() => {
    return fetchAndRepairDashboardConfig(configKey);
  }, [configKey]);

  const queryKey = useMemo(() => {
    return [...DASHBOARD.DASHBOARD_CONFIG, type];
  }, [type]);

  const { isLoading: configLoading, data: config = {} } = useQuery(
    queryKey,
    handleGetPageConfig
  );

  const updateDashletConfigMutation = useMutation(
    (result) => {
      return updatePageConfigQuery(configKey, result);
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(queryKey, data);
      },
      onError: (_err, _data, context) => {
        queryClient.setQueryData(queryKey, context.previous);
      },
      onMutate: (vars) => {
        const previous = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, (prev) => {
          return {
            ...prev,
            ...vars,
          };
        });

        return { previous };
      },
    }
  );

  const delayedMutation = useDelayedMutation(
    updateDashletConfigMutation.mutate
  );

  const updateConfigKey = useCallback(
    (key, value) => {
      if (key) {
        return delayedMutation({ ...config, [key]: value });
      }
    },
    [delayedMutation, config]
  );

  const setCurrentDashboardId = useCallback(
    (value) => {
      return updateConfigKey('currentDashboard', value);
    },
    [updateConfigKey]
  );

  const setPersistentDashboardState = useCallback(
    (value) => {
      return updateConfigKey('dashboardState', value);
    },
    [updateConfigKey]
  );

  // Handle the initial use-case where the route is loaded without a dashboard ID
  const updateOnce = useRef(false);
  useEffect(() => {
    if (
      !configLoading &&
      Boolean(dashboardId) &&
      !config?.currentDashboard &&
      config?.currentDashboard !== dashboardId &&
      !updateOnce.current
    ) {
      updateOnce.current = true;
      setCurrentDashboardId(dashboardId);
    }
  }, [
    configLoading,
    dashboardId,
    config?.currentDashboard,
    setCurrentDashboardId,
  ]);

  // Handles the use-case where a route is loaded with a different dashboard ID than what's currently
  // loaded
  useEffect(() => {
    if (
      !configLoading &&
      Boolean(dashboardId) &&
      Boolean(config?.currentDashboard) &&
      config?.currentDashboard !== dashboardId
    ) {
      setCurrentDashboardId(dashboardId);
    }
  }, [
    configLoading,
    dashboardId,
    config?.currentDashboard,
    setCurrentDashboardId,
  ]);

  const currentDashboardKey = useMemo(() => {
    if (!config?.currentDashboard && !configLoading) {
      return dashboards?.[0]?.id;
    }
    return config?.currentDashboard;
  }, [config, configLoading, dashboards]);

  const handleFetchDashboard = useCallback(
    () => getDashboardQuery(currentDashboardKey),
    [currentDashboardKey]
  );

  const dashboardQueryKey = useMemo(() => {
    return [...DASHBOARD.DASHBOARD, currentDashboardKey];
  }, [currentDashboardKey]);

  const processConfigAfterHide = useCallback(
    (id) => {
      if (currentDashboardKey === id) {
        const nextDashboards = dashboards.filter(
          (d) => d.id !== id && !d.hidden
        );
        setCurrentDashboardId(nextDashboards?.[0]?.id);
      }
    },
    [currentDashboardKey, setCurrentDashboardId, dashboards]
  );

  const {
    data: dashboardData,
    isLoading: dashboardLoading,
    refetch: refetchDashboard,
  } = useQuery(dashboardQueryKey, handleFetchDashboard, {
    enabled: !!currentDashboardKey,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (dashboardData && !dashboardLoading && !dashboardId && !skipRouter) {
      if (
        dashboardData.id &&
        (dashboardData.published || overridePublishedState)
      ) {
        history.replace(`${basePath}/${dashboardData.id}`);
      } else {
        const nextDashboards = dashboards.filter(
          (d) => !d.hidden && (d.published || overridePublishedState)
        );
        if (nextDashboards?.[0]?.id) {
          history.replace(`${basePath}/${nextDashboards[0].id}`);
        }
      }
    }
  }, [
    dashboardData,
    dashboardLoading,
    dashboardId,
    history,
    skipRouter,
    basePath,
    dashboards,
    overridePublishedState,
  ]);

  const isDashboardHidden = dashboardData?.hidden;
  const isDashboardError = dashboardData?.error === true;
  const isDashboardUnpublishedForUser =
    dashboardData?.published === false &&
    dashboardData.employee_access !== EMPLOYEE_ACCESS.ADMIN &&
    dashboardData.type === 'homepage';

  const currentDashboard = useMemo(() => {
    if (
      isDashboardHidden ||
      isDashboardError ||
      isDashboardUnpublishedForUser
    ) {
      return null;
    }
    return snakeToCamelCaseKeys(dashboardData);
  }, [
    dashboardData,
    isDashboardError,
    isDashboardHidden,
    isDashboardUnpublishedForUser,
  ]);

  const currentDashboardPermission = useMemo(() => {
    if (currentDashboard) {
      return currentDashboard.employeeAccess;
    }
    return dashboardLoading ? 'View' : 'None';
  }, [dashboardLoading, currentDashboard]);

  const setDateFilter = useCallback(
    (filters) => {
      if (currentDashboard) {
        const currentMap = config.filtersByDashboard ?? {};
        updateConfigKey('filtersByDashboard', {
          ...currentMap,
          [currentDashboard.id]: {
            ...(currentMap?.[currentDashboard?.id] ?? {}),
            date: {
              ...(filters ?? {}),
              selectedIndex: filters?.selectedIndex ?? -1,
            },
          },
        });

        resetAll();
      }
    },
    [config, updateConfigKey, currentDashboard, resetAll]
  );

  const setTeamFilter = useCallback(
    (filters) => {
      if (currentDashboard) {
        const currentMap = config.filtersByDashboard ?? {};
        updateConfigKey('filtersByDashboard', {
          ...currentMap,
          [currentDashboard.id]: {
            ...(currentMap?.[currentDashboard?.id] ?? {}),
            team: filters.team_member ?? [],
            role: filters.role ?? [],
          },
        });

        resetAll();
      }
    },
    [config, updateConfigKey, currentDashboard, resetAll]
  );

  const { todayDateInBusinessTimeZone, ranges } = useBusinessTimezoneRanges(
    respectBusinessTimezone
  );

  const dateFilter = useMemo(() => {
    const date =
      config?.filtersByDashboard?.[currentDashboard?.id]?.date ??
      DEFAULT_DATE_FILTERS;

    if (date.selectedIndex >= 0) {
      // If a shortcut was used (i.e. "last 7 days"), rewrite the date
      // filters to always refer to that range
      const matchingDateKey = indexMapping[date.selectedIndex];
      if (matchingDateKey) {
        const config = ranges[matchingDateKey];
        if (config) {
          return {
            start: formatDate(config.value[0]),
            end: formatDate(config.value[1]),
            selectedIndex: date.selectedIndex,
          };
        }
      }
    }
    return date;
  }, [config, currentDashboard, ranges]);

  const teamFilter = useMemo(() => {
    const members =
      getFilterValues(
        config?.filtersByDashboard?.[currentDashboard?.id]?.team
      ) ?? [];
    const memberMetadata =
      config?.filtersByDashboard?.[currentDashboard?.id]?.team?.reduce(
        (acc, curr) => {
          const res = { ...acc };
          res[curr.value] = curr.label;
          return res;
        },
        {}
      ) ?? {};
    const roles =
      getFilterValues(
        config?.filtersByDashboard?.[currentDashboard?.id]?.role
      ) ?? [];
    const rolesMetadata =
      config?.filtersByDashboard?.[currentDashboard?.id]?.role?.reduce(
        (acc, curr) => {
          const res = { ...acc };
          res[curr.value] = curr.label;
          return res;
        },
        {}
      ) ?? {};
    return {
      teamMembers: members,
      teamMemberCount: members.length,
      roles,
      roleCount: roles.length,
      memberMetadata,
      rolesMetadata,
    };
  }, [config, currentDashboard]);

  const processConfigAfterCreate = useCallback(
    (id) => {
      setCurrentDashboardId(id);
      queryClient.invalidateQueries([...DASHBOARD.DASHBOARD, id]);
      queryClient.invalidateQueries(DASHBOARD.DASHBOARDS);
    },
    [setCurrentDashboardId, queryClient]
  );

  const processConfigAfterDelete = useCallback(
    (id) => {
      const currentMap = config.filtersByDashboard ?? {};
      const payload = {
        ...config,
        filtersByDashboard: {
          ...currentMap,
          [id]: undefined,
        },
      };

      if (currentDashboardKey === id) {
        const nextDashboards = dashboards.filter(
          (d) => d.id !== id && !d.hidden
        );
        payload.currentDashboard = nextDashboards?.[0]?.id;
      }
      updateDashletConfigMutation.mutate(payload);
      queryClient.invalidateQueries(DASHBOARD.DASHBOARDS);
      queryClient.invalidateQueries([...DASHBOARD.DASHBOARD, id]);
    },
    [
      config,
      currentDashboardKey,
      updateDashletConfigMutation,
      dashboards,
      queryClient,
    ]
  );

  const processConfigAfterShow = useCallback(
    (id) => {
      if (!config.currentDashboard) {
        setCurrentDashboardId(id);
      }
    },
    [config, setCurrentDashboardId]
  );

  const processConfigAfterUpdate = useCallback(
    (id, updatePayload) => {
      if (typeof updatePayload.hidden === 'boolean') {
        if (updatePayload.hidden) {
          processConfigAfterHide(id);
        } else {
          processConfigAfterShow(id);
        }
      }
      queryClient.invalidateQueries([...DASHBOARD.DASHBOARD, id]);
      queryClient.invalidateQueries(DASHBOARD.DASHBOARDS);
    },
    [processConfigAfterHide, processConfigAfterShow, queryClient]
  );

  return {
    setCurrentDashboardId,
    setDateFilter,
    setTeamFilter,
    refetchDashboard,
    afterDelete: processConfigAfterDelete,
    afterUpdate: processConfigAfterUpdate,
    afterCreate: processConfigAfterCreate,
    loading: configLoading || dashboardLoading,
    currentDashboard,
    dateFilter,
    teamFilter,
    dashboardQueryKey,
    currentDashboardPermission,
    persistentDashboardState: config?.dashboardState,
    setPersistentDashboardState,
    dashboardError:
      dashboardData?.error === true ? dashboardData?.response : undefined,
    // This is "unsafe" to use in most applications - having this ID does not guarantee that the
    // dashboard exists or that the user has access to it
    unsafeDashboardId: dashboardId,
    ranges,
    todayDateInBusinessTimeZone,
  };
};

export default useDashboardConfig;
