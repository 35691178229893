import { FIELDS } from 'queries/query-keys';
import { useQuery } from 'react-query';
import FieldService from 'services/FieldService';

export const useField = ({ id, isClient, model }) => {
  return useQuery(
    FIELDS.GET_FIELD(id),
    () =>
      isClient
        ? FieldService.getClientField(id, {
            skipErrorBoundary: true,
          })
        : FieldService.getField(id, model, {
            skipErrorBoundary: true,
          }),
    {
      enabled: Boolean(id) && Boolean(model),
    }
  );
};
