import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { AREA_RESPONSES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { useTranslation } from 'react-i18next';
import Select from 'components/Inputs/Select';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import LoggableActivityService from 'services/LoggableActivityService';
import { useQuery } from 'react-query';
import { DASHBOARD } from 'queries/query-keys';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import { gutters } from 'app/spacing';

const getActivityTypes = async () => {
  return await LoggableActivityService.getActivityFullList({
    ordering: 'name',
  });
};

const ActivitiesArea = ({ setModalSize, disabled }) => {
  const { state, setState } = useContext(WizardStateContext.Context);
  const { reportType, area, activity } = state;
  const { t } = useTranslation();

  useEffect(() => {
    if (reportType?.value === REPORT_TYPES.SCHEDULED_ACTIVITIES) {
      setModalSize('large');
    } else {
      setModalSize('medium');
    }
  }, [reportType, setModalSize]);

  const activityReportTypes = useMemo(() => {
    return [
      {
        label: t('Number of Activity Submissions'),
        value: REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS,
      },
      {
        label: t('Scheduled Activities'),
        value: REPORT_TYPES.SCHEDULED_ACTIVITIES,
      },
    ];
  }, [t]);

  const activityReportTypeValue = useMemo(() => {
    if (reportType?.value) {
      return {
        value: reportType.value,
        label: activityReportTypes.find((r) => r.value === reportType?.value)
          ?.label,
      };
    }
    return reportType;
  }, [reportType, activityReportTypes]);

  const reportTypeLabel = t('Choose Report Type');

  const activityLabel = useMemo(() => {
    return t('Choose Activity');
  }, [t]);

  const onChangeReportType = useCallback(
    (value) => {
      setState('activity', null);
      return setState('reportType', value);
    },
    [setState]
  );

  const onChangeActivity = useCallback(
    (value) => {
      return setState('activity', value);
    },
    [setState]
  );

  const { isLoading: activitiesLoading, data: activities } = useQuery(
    DASHBOARD.ACTIVITY_TYPES,
    getActivityTypes,
    {
      enabled:
        area === AREA_RESPONSES.ACTIVITIES &&
        activityReportTypeValue?.value ===
          REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS,
    }
  );

  const activitiesOptions = useMemo(() => {
    return (
      activities?.map(({ id, name }) => ({ value: id, label: name })) ?? []
    );
  }, [activities]);

  const activityValue = useMemo(() => {
    if (activity?.value && activitiesOptions.length) {
      return {
        value: activity.value,
        label: activitiesOptions.find((a) => a.value === activity?.value)
          ?.label,
      };
    }
  }, [activitiesOptions, activity]);

  useMetadataKey('selectedActivity', activityValue);

  // Hide self if area is not activities
  if (area !== AREA_RESPONSES.ACTIVITIES) {
    return null;
  }

  return (
    <>
      <StyledWizardField>
        <KizenTypography type="subheader">{reportTypeLabel}</KizenTypography>
        <Select
          value={activityReportTypeValue}
          onChange={onChangeReportType}
          captureMenuScroll={false}
          className="modal-select report-type"
          placeholder={reportTypeLabel}
          options={activityReportTypes}
          disabled={disabled}
        />
      </StyledWizardField>
      {activityReportTypeValue?.value ===
      REPORT_TYPES.NUMBER_OF_ACTIVITY_SUBMISSIONS ? (
        <StyledWizardField top={gutters.spacing(6)}>
          <KizenTypography type="subheader">{activityLabel}</KizenTypography>
          <Select
            value={activityValue}
            onChange={onChangeActivity}
            captureMenuScroll={false}
            className={`modal-select choose-activity ${
              activitiesOptions.length
                ? 'activities-populated'
                : 'activities-loading'
            }`}
            placeholder={activityLabel}
            options={activitiesOptions}
            fullWidth
            isLoading={activitiesLoading}
            disabled={disabled}
          />
        </StyledWizardField>
      ) : null}
    </>
  );
};

ActivitiesArea.propTypes = {};

export default ActivitiesArea;
