import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from 'app/colors';
import { layers } from 'app/spacing';
import { cloneElement, forwardRef } from 'react';

const fadeInCss = ({ fadeIn }) => css`
  transition: opacity 0.1s ease-in-out;
  opacity: ${fadeIn ? 1 : 0};
`;

const dimension = {
  top: 'height',
  right: 'width',
  bottom: 'height',
  left: 'width',
};

const GradientWrapper = styled.div`
  position: sticky;
  ${({ position }) => position}: 0;
  ${({ position }) => dimension[position]}: 0;
  ${({ position }) => {
    if (position === 'left' || position === 'right') {
      return css`
        top: 0;
      `;
    }
    return css`
      width: 100%;
    `;
  }}
  ${({ zIndex }) =>
    zIndex !== null
      ? css`
          z-index: ${zIndex};
        `
      : css`
          z-index: ${layers.content(0, 1)};
        `}

  overflow: visible;
  pointer-events: none;
  ${fadeInCss}
`;

const boundingPositions = {
  top: ['top', 'left', 'right'],
  right: ['right', 'top', 'bottom'],
  bottom: ['bottom', 'left', 'right'],
  left: ['left', 'top', 'bottom'],
};

const oppositePosition = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
};

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const GradientFade = styled.div`
  position: absolute;
  ${({ position }) => {
    const [b1, b2, b3] = boundingPositions[position];
    return css`
      ${b1}: 0;
      ${b2}: 0;
      ${b3}: 0;
    `;
  }}
  ${({ position, fadeStart, fadeLength }) => css`
    ${dimension[position]}: ${fadeStart + fadeLength}px
  `}
/* Need to use rgba rather than "transparent" for safari's benefit */
background: linear-gradient(
  to ${({ position }) => oppositePosition[position]},
  ${({ color }) => color} ${({ fadeStart }) => fadeStart}px,
  ${({ color }) => hexToRgba(color, 0)}
);
`;

const positions = ['top', 'right', 'bottom', 'left'];

export function Gradient({
  color,
  position,
  fadeStart,
  fadeLength,
  ...others
}) {
  if (!positions.includes(position)) {
    return null;
  }
  return (
    <GradientWrapper position={position} {...others}>
      <GradientFade
        color={color}
        position={position}
        fadeStart={fadeStart}
        fadeLength={fadeLength}
      />
    </GradientWrapper>
  );
}

Gradient.propTypes = {
  color: PropTypes.string,
  position: PropTypes.oneOf(positions),
  fadeStart: PropTypes.number,
  fadeLength: PropTypes.number,
};

Gradient.defaultProps = {
  color: grayScale.white,
  position: 'bottom',
  fadeStart: 0,
  fadeLength: 50,
};

const GradientHorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const GradientVerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ScrollFadeContainer = forwardRef(function ScrollFadeContainer(
  {
    top,
    right,
    bottom,
    left,
    // Output from useEndsOfScroll()
    scrolled: [scrolledTop, scrolledRight, scrolledBottom, scrolledLeft],
    gradient,
    children,
    ...others
  },
  ref
) {
  return (
    <GradientHorizontalContainer {...others} ref={ref}>
      {!!left &&
        cloneElement(gradient, {
          position: 'left',
          fadeIn: !scrolledLeft,
          ...left,
        })}
      <GradientVerticalContainer className="gradient-vertical-container">
        {!!top &&
          cloneElement(gradient, {
            position: 'top',
            fadeIn: !scrolledTop,
            ...top,
          })}
        {children}
        {!!bottom &&
          cloneElement(gradient, {
            position: 'bottom',
            fadeIn: !scrolledBottom,
            ...bottom,
          })}
      </GradientVerticalContainer>
      {!!right &&
        cloneElement(gradient, {
          position: 'right',
          fadeIn: !scrolledRight,
          ...right,
        })}
    </GradientHorizontalContainer>
  );
});

const sideProp = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.objectOf({
    fadeStart: PropTypes.number,
    fadeLength: PropTypes.number,
  }),
]);

ScrollFadeContainer.propTypes = {
  top: sideProp,
  right: sideProp,
  bottom: sideProp,
  left: sideProp,
  scrolled: PropTypes.arrayOf(PropTypes.bool),
  gradient: PropTypes.element,
};

ScrollFadeContainer.defaultProps = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  scrolled: [false, false, false, false],
  gradient: <Gradient />,
};

export default ScrollFadeContainer;
