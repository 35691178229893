import { useCallback } from 'react';
import { usePermissionsContext } from './context';
import { disablePermissionsForSection } from '../src/state-ops';

export const useDisableSectionPermissions = () => {
  const ctx = usePermissionsContext();

  return useCallback(
    (sectionId: string) => {
      disablePermissionsForSection(sectionId, ctx);
    },
    [ctx]
  );
};
