import { useCallback, useContext } from 'react';
import { DataManager } from './context';

export const useHasExecuted = (id) => {
  const dataManager = useContext(DataManager);
  const { executed } = dataManager;

  return executed?.has(id);
};

export const useIsAcquired = (id) => {
  const dataManager = useContext(DataManager);
  const { acquiredIds } = dataManager;

  return acquiredIds?.has(id);
};

export const useRelease = (id) => {
  const dataManager = useContext(DataManager);
  const { release } = dataManager;

  const handleRelease = useCallback(() => {
    return release(id);
  }, [release, id]);

  return handleRelease;
};

export const useMarkExecution = (id) => {
  const dataManager = useContext(DataManager);
  const { markExecution } = dataManager;

  const handleMarkExecution = useCallback(() => {
    return markExecution(id);
  }, [markExecution, id]);

  return handleMarkExecution;
};

export const useProcessDelete = () => {
  const dataManager = useContext(DataManager);
  const { processDelete } = dataManager;

  return processDelete;
};

export const useTriggerRefetch = () => {
  const dataManager = useContext(DataManager);
  const { triggerRefetch } = dataManager;

  return triggerRefetch;
};

export const useRegister = () => {
  const dataManager = useContext(DataManager);
  const { register } = dataManager;

  return register;
};

export const useSetVisibility = () => {
  const dataManager = useContext(DataManager);
  const { setVisibility } = dataManager;

  return setVisibility;
};

export const useSetUnregisteredVisibility = () => {
  const dataManager = useContext(DataManager);
  const { handleSetUnregisteredVisibility } = dataManager;

  return handleSetUnregisteredVisibility;
};

export const useUnregisteredDashletEnabled = (id) => {
  const dataManager = useContext(DataManager);
  const { persistentUnregisteredVisibility } = dataManager;

  return Boolean(persistentUnregisteredVisibility?.has(id));
};

export const useInstanceId = () => {
  const dataManager = useContext(DataManager);
  const { instanceId } = dataManager;

  return instanceId;
};

export const useIsVisible = (id) => {
  const dataManager = useContext(DataManager);
  const { candidates } = dataManager;

  return candidates.has(id);
};

export const useResetAll = () => {
  const dataManager = useContext(DataManager);
  const { resetAll } = dataManager;

  return resetAll;
};

export const useCacheBustRequests = () => {
  const dataManager = useContext(DataManager);
  const { cacheBustRequests } = dataManager;

  return cacheBustRequests;
};

export const useBustCache = () => {
  const dataManager = useContext(DataManager);
  const { bustCache } = dataManager;

  return bustCache;
};
