import React, { useMemo, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import TextInput from 'components/Inputs/TextInput';
import MultiSelect from 'components/Inputs/MultiSelect';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import SelectAllButton from 'components/Inputs/MultiSelect/SelectAllButton';
import { useTranslation } from 'react-i18next';
import { StyledFormRow } from './styles';
import { useFlashTransition } from 'hooks/useFlashState';
import {
  REGISTERED_ROLE_AND_GROUP_NAME,
  registeredRoleAndGroupError,
} from 'pages/Settings/helpers';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import Switch from 'components/Kizen/Switch';

const valueToSelectOption = (values) =>
  values.map((value) => ({
    value: value.id,
    label: value.name,
  }));

const selectOptionToValues = (options) =>
  options.map((option) => ({
    id: option.value,
    name: option.label,
  }));

const RoleForm = ({ values, onChange, permissionGroups, fieldErrors }) => {
  const { id } = values;
  const [message, showMessage, flash] = useFlashTransition({ stay: 3000 });
  const initialValue = useRef(values.name);
  const validate = {
    full: (value) => {
      if (value === REGISTERED_ROLE_AND_GROUP_NAME) {
        return registeredRoleAndGroupError(t);
      }
      return null;
    },
    message,
    showMessage,
  };

  const permissionGroupOptions = useMemo(
    () =>
      permissionGroups.map((pg) => ({
        value: pg.id,
        label: pg.name,
      })),
    [permissionGroups]
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (fieldErrors?.name) {
      flash(fieldErrors?.name);
    }
  }, [fieldErrors, flash]);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'role-name' },
      { id: 'role-groups' },
      { id: 'default-for-new-users' },
    ],
    {},
    () => true
  );

  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <StyledFormRow fullWidth>
        <TextInput
          disabled={
            id && initialValue.current === REGISTERED_ROLE_AND_GROUP_NAME
          }
          label={t('Name Your Role')}
          placeholder={t('Name Your Role')}
          value={values.name}
          error={!!fieldErrors?.name}
          validate={validate}
          onChange={(value) => onChange('name', value)}
          {...getKeyListenersProps('role-name')}
        />
        <MultiSelect
          className="select-wrapper"
          label={t('Set Default Permission Group(s)')}
          placeholder={t('Choose Permission Group(s)')}
          options={permissionGroupOptions}
          value={valueToSelectOption(values.permissionGroups)}
          disabled={
            id && initialValue.current === REGISTERED_ROLE_AND_GROUP_NAME
          }
          onChange={(value) =>
            onChange('permissionGroups', selectOptionToValues(value))
          }
          menuInline
          menuTopButton={<SelectAllButton />}
          menuLeftButton={<ClearSelectButton />}
          menuRightButton={<ApplySelectButton />}
          {...getKeyListenersProps('role-groups')}
          margin
        />
        <Switch
          label={t('Default for New Users')}
          textPlacement="regular-top"
          value={values.defaultForNewUsers}
          checked={values.defaultForNewUsers}
          onChange={(e) => onChange('defaultForNewUsers', e.target.checked)}
          {...getKeyListenersProps('default-for-new-users')}
        />
      </StyledFormRow>
    </KeyBoardContext.Provider>
  );
};

RoleForm.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  permissionGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RoleForm;

export const validateRoleForm = ({ name }) => {
  return Boolean(name) && name !== 'Admin';
};
