import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import Icon from 'components/Kizen/Icon';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import useCan from 'hooks/useCan';
import TablePagination from 'components/Tables/Big/TablePagination';
import ListCard from 'components/Layout/ListCard';
import BigListLayout, { BigListTitle } from 'components/Layout/BigListLayout';
import {
  BigListMainSelector,
  BigListSearchInput,
  BigListToolbar,
  BigListToolbarSection,
} from 'components/Layout/BigListLayout/Toolbar';
import { ICON_BUTTON_SIZING } from 'components/Kizen/IconButton';
import { useDebouncedPastScrollThreshold } from 'hooks/useDebouncedWindowScroll';
import {
  ContactListItem,
  ContactListHeader,
  EmptyContactListItem,
} from './ListItem';
import ObjectCharts from 'components/ObjectCharts';
import ViewVariant from 'components/ViewVariant';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import { getIsContactsFiltering } from 'store/contactsPage/selectors';
import Loader from 'components/Kizen/Loader';

const StyledIconButtonMenu = styled(IconButtonMenu)`
  margin-left: ${gutters.spacing(2)}px;
`;

const StyledBigListLayout = styled(BigListLayout)`
  & > div:first-child > div {
    height: 101px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${gutters.spacing(2)}px;
  flex-grow: 1;

  && > div:first-child {
    width: 100%;
  }
`;

const StyledBigListToolbar = styled(BigListToolbar)`
  padding-top: ${gutters.spacing(1)}px;
  height: 51px;
`;

const StyledBigListTitle = styled(BigListTitle)`
  margin-top: ${gutters.spacing(2)}px;
  margin-bottom: ${gutters.spacing(2, -1)}px;
`;

export default function ContactsListPage({
  contacts,
  contactsCount,
  contactsCountAndNew,
  groups,
  groupId = null,
  onChangeGroupId,
  search,
  onChangeSearch,
  sort,
  onChangeSort,
  onChecked,
  onCheckedSelection,
  selectionOptions,
  pageNumber,
  pageSize,
  onChangePageNumber,
  onChangePageSize,
  checkedCount,
  choiceAction,
  openAddContactModal,
  openPerformModal,
  selection,
  clientObject,
  isChartView,
  handleChangeViewVariant,
  chartGroupConfigProps,
  fullFilterObject,
  isLoadingView,
  ...others
}) {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();

  const groupOptions = useMemo(() => {
    return [{ value: 'none', label: t('All Contacts') }].concat(
      groups
        .filter(({ id }) => id)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        }))
    );
  }, [groups, t]);

  const handleChangeGroup = useCallback(
    ({ value }) => onChangeGroupId(value === 'none' ? null : value),
    [onChangeGroupId]
  );

  const canCreateContact = useCan({ create: 'contacts' });

  const newContactOptions = useMemo(() => {
    return [
      canCreateContact && {
        value: 'new-contact',
        label: t('New Contact'),
      },
    ].filter(Boolean);
  }, [canCreateContact, t]);

  const scrolled = useDebouncedPastScrollThreshold();
  const isFiltering = useSelector(getIsContactsFiltering);

  return (
    <StyledBigListLayout
      toolbar={
        <StyledBigListToolbar>
          <BigListToolbarSection>
            <ViewVariant hide={VIEW_VARIANTS.BOARD} isMobile />
          </BigListToolbarSection>
          {newContactOptions.length ? (
            <BigListToolbarSection>
              <div>
                <StyledIconButtonMenu
                  sizing={ICON_BUTTON_SIZING.overlay}
                  position="right"
                  options={newContactOptions}
                  borderColor={scrolled ? grayScale.medium : null}
                  onChange={({ value }) => {
                    if (value === 'new-contact') {
                      openAddContactModal();
                    }
                  }}
                >
                  <Icon icon="plus" />
                </StyledIconButtonMenu>
              </div>
            </BigListToolbarSection>
          ) : null}
        </StyledBigListToolbar>
      }
      search={
        <BigListToolbar>
          <BigListToolbarSection>
            <BigListMainSelector
              value={groupId || 'none'}
              options={groupOptions}
              onChange={handleChangeGroup}
            />
          </BigListToolbarSection>
          <SearchWrapper>
            <BigListSearchInput
              placeholder={t('Find Contacts')}
              value={search}
              onChange={onChangeSearch}
              loading={isFiltering}
            />
          </SearchWrapper>
        </BigListToolbar>
      }
      pagination={
        isChartView || isLoadingView ? undefined : (
          <TablePagination
            page={pageNumber}
            perPage={pageSize}
            totalCount={contactsCount}
            onChangePage={onChangePageNumber}
            onChangePerPage={onChangePageSize}
          />
        )
      }
      {...others}
    >
      <Loader loading={isLoadingView}>
        {isChartView ? (
          <ObjectCharts
            mobile
            model={clientObject}
            groupId={groupId}
            groups={groups}
            search={search}
            onChangeSearch={onChangeSearch}
            recordsCountAndNew={contactsCountAndNew}
            chartGroupConfigProps={chartGroupConfigProps}
            fullFilterObject={fullFilterObject}
            handleChangeGroup={handleChangeGroup}
            preReleaseFeatures={preReleaseFeatures}
          />
        ) : (
          <>
            <StyledBigListTitle
              count={contactsCountAndNew}
              single={t('Contact')}
              plural={t('Contacts')}
            />
            <ListCard>
              <ContactListHeader
                label={t('Full Name')}
                sort={sort && sort.column === 'full_name' ? sort : null}
                onChangeSort={onChangeSort}
                checked={selection.checked}
                onChangeSelection={onCheckedSelection}
                selectionOptions={selectionOptions}
              />
              {!contacts.length && <EmptyContactListItem />}
              {contacts.map((contact) => (
                <ContactListItem
                  key={contact.id}
                  checked={contact.checked}
                  onChecked={onChecked}
                  contact={contact}
                />
              ))}
            </ListCard>
          </>
        )}
      </Loader>
    </StyledBigListLayout>
  );
}
