import React, { useCallback, useState, useRef, useEffect } from 'react';
import useToggle from 'react-use/lib/useToggle';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import format from 'date-fns/format';
import Overlay from 'react-bootstrap/Overlay';
import { grayScale } from 'app/colors';
import { gutters } from 'app/spacing';
import Input, { UnderlinedInput } from './Input';
import Icon from './Icon';
import DatePicker from './DatePicker';
import { StyledDateMenuWrapper } from './DatePicker/styles';

const InputForDate = styled(Input)`
  input {
    min-width: 0; // Necessary for the date input to shrink down with narrow widths
    padding-right: 0;
    cursor: default;
  }
`;

const UnderlinedInputForDate = styled(UnderlinedInput)`
  input {
    min-width: 0; // Necessary for the date input to shrink down with narrow widths
  }
  padding-right: 0;
  cursor: default;
  i {
    margin-top: calc(${gutters.spacing(0, { baseline: true })}px + 1px);
  }
`;

const popperConfig = {
  modifiers: {
    preventOverflow: {
      // Allows element to appear outside of the target's
      // scrolling parent but not outside the viewport.
      boundariesElement: 'viewport',
    },
  },
};

const dateInputFormat = 'MM/DD/YYYY';

export default function DateInput({
  value,
  placeholder,
  closeOnSelect,
  onChange,
  format: dateFormat,
  variant,
  hideIcon,
  disabled,
  ...others
}) {
  const [stagedValue, setStagedValue] = useState(
    value ? new Date(value) : null
  );
  const [open, toggleOpen] = useToggle(false);
  const handleSelect = useCallback(
    (date) => {
      if (closeOnSelect) {
        toggleOpen(false);
      }
      setStagedValue(date);
    },
    [closeOnSelect, toggleOpen]
  );

  useEffect(() => {
    if (!value) {
      setStagedValue(value);
    }
  }, [value]);

  const handleHide = useCallback(() => {
    toggleOpen(false);
    if (onChange && Number(stagedValue) !== Number(value)) {
      const formattedDate = !stagedValue ? '' : format(stagedValue, dateFormat);
      onChange(formattedDate);
    }
  }, [toggleOpen, onChange, stagedValue, value, dateFormat]);

  const handleFocus = useCallback((ev) => ev.target.blur(), []);

  const overlayTarget = useRef(null);

  return (
    <div
      ref={overlayTarget}
      data-qa-date-input=""
      data-qa-date-placeholder={placeholder}
    >
      {variant !== 'underlined' && (
        <InputForDate
          type={Input.INPUT_TYPES.TEXT}
          placeholder={placeholder}
          endcap={
            <Icon icon="calendar" size="1x" color={grayScale.mediumDark} />
          }
          value={value || ''}
          onClick={disabled ? null : toggleOpen}
          onFocus={handleFocus}
          hideIcon={hideIcon}
          disabled={disabled}
          {...others}
        />
      )}
      {variant === 'underlined' && (
        <UnderlinedInputForDate
          type={Input.INPUT_TYPES.TEXT}
          placeholder={placeholder}
          endcap={
            <Icon icon="calendar" size="1x" color={grayScale.mediumDark} />
          }
          value={value || ''}
          onClick={disabled ? null : toggleOpen}
          onFocus={handleFocus}
          hideIcon={hideIcon}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          {...others}
        />
      )}
      <Overlay
        transition={false}
        popperConfig={popperConfig}
        target={overlayTarget.current}
        show={!disabled && open}
        placement="bottom-end"
        rootClose
        onHide={handleHide}
      >
        <StyledDateMenuWrapper
          className="no-drag no-margin"
          data-qa-date-overlay=""
        >
          <DatePicker
            value={stagedValue}
            onSelect={handleSelect}
            onHide={handleHide}
            {...others}
          />
        </StyledDateMenuWrapper>
      </Overlay>
    </div>
  );
}

DateInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  variant: PropTypes.oneOf(['underlined', 'standard']),
  hideIcon: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
};

DateInput.defaultProps = {
  value: '',
  placeholder: dateInputFormat,
  format: dateInputFormat,
  variant: 'standard',
  hideIcon: false,
  closeOnSelect: false,
};
