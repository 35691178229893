import { useCallback, useEffect, useImperativeHandle, useReducer } from 'react';

/*
 * A hook to manage the state for a wizard. Keeps track of the values entered by the user
 * in a generic way so that each wizard can control its own data types.
 *
 * @param {Ref} ref - A ref that will be given the wizard state through an imperatve handle
 *
 * @param {Object} config - Configuration options for the state manager
 *    - config.resetKey can be passed to define a state key that when updated, causes the
 *      remaining state to be cleared (essentially as a way to start over)
 *    - config.existing can be passed if there is existing data to load into the wizard
 *    - config.setData can be a function that will be called with the state data
 *      whenever it is updated
 */
const useWizardValues = (ref, config = {}) => {
  const { resetKey = 'area', existing = {}, setData } = config;

  const reducer = useCallback(
    (state, action) => {
      switch (action.type) {
        case 'set': {
          if (action.payload.name === resetKey) {
            return { [action.payload.name]: action.payload.value };
          }
          return { ...state, [action.payload.name]: action.payload.value };
        }
        default:
          return state;
      }
    },
    [resetKey]
  );

  const [state, dispatch] = useReducer(reducer, existing);

  useEffect(() => {
    setData?.(state);
  }, [state, setData]);

  const setValue = useCallback((name, value) => {
    dispatch({ type: 'set', payload: { name, value } });
  }, []);

  useImperativeHandle(ref, () => state, [state]);

  return [state, setValue];
};

export default useWizardValues;
