import { useMemo } from 'react';

export const useFieldFromCategories = (fieldId, categories) =>
  useMemo(() => {
    if (!fieldId) return null;
    if (!categories?.length) return null;
    let field = null;
    for (const cat of categories) {
      field = cat.fields.find((f) => f.id === fieldId);
      if (field) break;
    }
    return field;
  }, [categories, fieldId]);
