export const relatedObjectTypeOptions = (t) => [
  {
    value: 'primary',
    label: t('Many-to-One'),
    name: (name) =>
      name
        ? t('Primary {{name}} Record', { name })
        : `${t('Primary')} {{${t('Entity B Name')}}} ${t('Record')}`,
    reverseName: (name) =>
      name
        ? t('Primary For {{name}} Records', {
            name,
          })
        : `${t('Primary For')} {{${t('Entity A Name')}}} ${t('Records')}`,
  },
  {
    value: 'one_to_one',
    label: t('One-to-One'),
    name: (name) => {
      return name
        ? t('Primary {{name}} Record', { name })
        : `${t('Primary')} {{${t('Entity B Name')}}} ${t('Record')}`;
    },
    reverseName: (name) =>
      name
        ? t('Primary {{name}} Record', { name })
        : `${t('Primary')} {{${t('Entity A Name')}}} ${t('Record')}`,
  },
  {
    value: 'additional',
    label: t('Many-to-Many'),
    name: (name) =>
      name
        ? t('Related {{name}} Records', { name: name })
        : `${t('Related')} {{${t('Entity B Name')}}} ${t('Records')}`,
    reverseName: (name) =>
      name
        ? t('Related {{name}} Records', {
            name,
          })
        : `${t('Related')} {{${t('Entity A Name')}}} ${t('Records')}`,
  },
  {
    value: 'primary_for',
    label: t('One-to-Many'),
    name: (name) => {
      return name
        ? t('Primary For {{name}} Records', { name })
        : `${t('Primary For')} {{${t('Entity B Name')}}} ${t('Records')}`;
    },
    reverseName: (name) =>
      name
        ? t('Primary {{name}} Record', { name })
        : `${t('Primary')} {{${t('Entity A Name')}}} ${t('Record')}`,
  },
  {
    value: 'additional_for',
    label: t('Many-to-Many'),
    name: (name) =>
      name
        ? t('Related {{name}} Records', { name: name })
        : `${t('Related')} {{${t('Entity B Name')}}} ${t('Records')}`,
    reverseName: (name) =>
      name
        ? t('Related {{name}} Records', {
            name,
          })
        : `${t('Related')} {{${t('Entity A Name')}}} ${t('Records')}`,
  },
];

export const DEFAULT_RELATION_PROPS = {
  isDefault: false,
  fieldType: 'relationship',
  isRequired: false,
  allowsNulls: true,
  allowsEmpty: true,
  isReadOnly: false,
  isHidden: false,
};
export const getDefaultFields = (relationType, t) => {
  const dictionary = relatedObjectTypeOptions(t).find(
    ({ value: valueId }) => valueId === relationType
  );
  return {
    relatedObject: null,
    relationType: relationType,
    isNew: true,
    field: {
      displayName: dictionary.name(),
      relation: {
        relatedName: dictionary.reverseName(),
        rollupTimeline: true,
        inverseRelationRollupTimeline: true,
      },
    },
  };
};
