import styled from '@emotion/styled';
import { SizedCell } from 'components/Kizen/Table';

export const StyledCell = styled(SizedCell)`
  text-align: right;

  & > div > button > div {
    text-align: left;
  }
`;
