import { useMemo, useRef } from 'react';
import { StyledTableScrollContainer } from './styles';
import BasicTable from 'components/Tables/Basic';
import { TRow } from 'components/Kizen/Table';
import { TableRow } from 'components/Tables/Query/TableRow';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import { StyledLoader } from './styles';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { PADDING_IN_PIXELS } from './constants';

const ITEMS_BUFFER = 20;
const ROW_HEIGHT = 40;
const HEADER_HEIGHT = 50;
const HEADER_HEIGHT_WITH_SCROLL = 60;

export const Table = ({
  columns,
  setOrdering,
  ordering,
  dataForTheTable,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  status,
  tableHeight,
  horizontalScrollPositionRef,
  horizontalScrollPositionKey,
  loading,
  frameless,
  centerLoader, // will absolutely center position the loader
  ...rest
}) => {
  const messageHeadData = useMemo(
    () => ({
      meta: {
        sort: getSortValue(ordering),
        onSort: (column, direction) => {
          setOrdering(
            getOrderingParam({
              column,
              direction,
            })
          );
        },
      },
    }),
    [setOrdering, ordering]
  );

  const wrapperRef = useRef(null);

  const tableScroll = useInfinityScroll({
    wrapperElement: wrapperRef.current,
    data: dataForTheTable,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    isFetchingNextPage: isFetchingNextPage,
    defaultHeight: ROW_HEIGHT,
    itemsBuffer: ITEMS_BUFFER,
    shouldFetchOnMount: true,
    headerHeight: HEADER_HEIGHT_WITH_SCROLL,
    component: 'table',
  });
  const { visibleItems, handleItemMounted } = tableScroll;

  const fixedHeight =
    visibleItems.length * ROW_HEIGHT + HEADER_HEIGHT > tableHeight;

  return (
    <>
      <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
        <ScrollContainerBlocker zIndex="4" width="9" opacity="0.1" />
      </ScrollContainerBlockerWrapper>
      <StyledTableScrollContainer
        className="StyledTableScrollContainer"
        ref={wrapperRef}
        left // doesn't play well with useTableScroll
        right
        bottom
        refresh={[visibleItems, fixedHeight]}
        isTable
        fixedHeight={fixedHeight}
        fitParentWidth
      >
        <BasicTable
          className="BasicTable"
          stickyHeader
          staleData={!isFetchingNextPage && isFetching} // don't fade when fetch next page
          head={<TRow head columns={columns} data={messageHeadData} />}
        >
          {visibleItems.map(({ item }, index) => (
            <TableRow
              key={item.id}
              columns={columns}
              onMount={handleItemMounted}
              item={item}
              index={index}
              fixWidthStyle={{
                padding: PADDING_IN_PIXELS,
              }}
            />
          ))}
        </BasicTable>
        <StyledLoader
          loading={isFetchingNextPage || isFetching || loading}
          noPadding={true}
          center={centerLoader}
        />
      </StyledTableScrollContainer>
    </>
  );
};
