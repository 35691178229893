import steps, { defaultTrigger } from '../steps';

// Test layouts:
// - One or many triggers
// - Zero or many steps
// - One or many root steps (empty parentKey)
export default {
  triggers: [
    {
      id: 'trigger1',
      type: steps.manual.type,
    },
    {
      id: 'trigger2',
      type: steps.manual.type,
    },
  ],
  steps: [
    {
      id: 'step1',
      parentKey: null,
      type: steps.condition.type,
    },
    {
      id: 'step2',
      parentKey: null,
      type: steps.goal.type,
    },
    {
      id: 'step3',
      parentKey: 'step1',
      type: steps.action.type,
    },
    {
      id: 'step4',
      parentKey: 'step3',
      type: steps.splitTest.type,
    },
  ],
};

export const newAutomation = {
  triggers: [defaultTrigger],
  steps: [],
};

export const automationStats = {
  active: 1001,
  completed: 27210000,
  paused: 44000,
};
