import { useTranslation } from 'react-i18next';
import { SubSectionWithHeader as SubSection } from 'components/Layout/SubSection';
import ConfigurationService from 'services/ConfigurationService';
import { useCallback, useEffect, useState } from 'react';
import { toastVariant, useToast } from 'components/ToastProvider';
import { getErrorMessage } from './helpers';
import { useProgress } from 'hooks/uploadFiles/useProgress';
import { useUploadFile } from 'hooks/uploadFiles/useUploadFile';
import { useFiles } from 'hooks/uploadFiles/useFiles';
import FilePicker from 'components/FilePicker';
import { EXPORT_IMPORT_SOURCES } from './constants';
import { StyledButton, StyledUploadButtons } from './styles';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import useModal from 'components/Modals/useModal';
import { useSelector } from 'react-redux';
import { getChosenBusiness } from 'store/authentication/selectors';
import { ExportImportTables } from './ExportImportTables';

const allowedFileExtensions = ['.kzn', '.zip', '.gzip'];

export const ImportSection = ({ setDirty }) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const chosenBusiness = useSelector(getChosenBusiness);

  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const isDirty = !!file;

  useEffect(() => {
    setDirty(isDirty);
  }, [setDirty, isDirty]);

  const [progress, { clearProgress, initializeProgress, updateProgress }] =
    useProgress();

  const uploadFile = useUploadFile(
    false,
    updateProgress,
    EXPORT_IMPORT_SOURCES.IMPORT
  );

  const [, { addFiles }] = useFiles({
    initializeProgress,
    uploadFile,
  });

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);

    try {
      await ConfigurationService.importConfiguration(file?.id, {
        skipErrorBoundary: true,
      });
      setFile(null);

      showToast({
        message: t('Import queued. Check your email for status.'),
        variant: toastVariant.SUCCESS,
      });
    } catch (err) {
      showToast({
        message:
          getErrorMessage(err) ||
          t('Import failed. Please try again or contact Kizen support.'),
        variant: toastVariant.FAILURE,
      });
    } finally {
      setSubmitting(false);
    }
  }, [file?.id, showToast, t]);

  const handleAddFiles = useCallback(
    async (updatedFiles) => {
      const createdFiles = await addFiles(updatedFiles);
      clearProgress();
      if (createdFiles.length) {
        setFile(createdFiles[0]);
      }
    },
    [addFiles, clearProgress]
  );

  const [modalProps, , { show }] = useModal({
    handleSubmit,
  });

  return (
    <>
      <SubSection title={t('Configuration Import')}>
        <StyledUploadButtons>
          <FilePicker
            onFileAdded={handleAddFiles}
            allowedFileExtensions={allowedFileExtensions}
            uploadStatus={progress && Object.values(progress)[0].progress}
            onChange={setFile}
          />
          <StyledButton disabled={!isDirty || submitting} onClick={show}>
            {t('Import')}
          </StyledButton>
        </StyledUploadButtons>
        <ExportImportTables />
      </SubSection>
      <ConfirmationModal
        buttonText={t('Import Configuration')}
        heading={t('Configuration Import')}
        {...modalProps}
      >
        {t(
          'This will create configuration items in this business ({{ name }}). It is not reversible.',
          chosenBusiness
        )}
      </ConfirmationModal>
    </>
  );
};
