import React from 'react';
import * as PropTypes from 'prop-types';
import UUID from 'utility/UUID';
import { StyledLabel, StyledRow } from './styles';

const RadioGroup = ({
  name,
  children,
  onChange,
  value,
  variant,
  isCheckedCustom,
  customClassName,
  mode,
  ...props
}) => {
  // sets the checked prop for both display and value management
  const isChecked = (child) => {
    const childValue = child.props.value;
    if (isCheckedCustom) {
      return isCheckedCustom(child);
    }
    return childValue === value;
  };

  const mappedChildren = React.Children.map(children || null, (child) => {
    return (
      <StyledLabel
        className={customClassName}
        key={child.props.value}
        checked={isChecked(child)}
        variant={variant}
      >
        <input
          type="radio"
          name={name}
          value={child.props.value}
          checked={isChecked(child)}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        />
        <div className={`${name} child`}>{child}</div>
      </StyledLabel>
    );
  });

  if (mode === 'wizard') {
    return mappedChildren;
  }

  return <StyledRow {...props}>{mappedChildren}</StyledRow>;
};

RadioGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  isCheckedCustom: PropTypes.func, // we don't want this to have a default
  value: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.oneOf(['content', 'button', 'radio']),
  customClassName: PropTypes.string,
  mode: PropTypes.oneOf(['wizard', 'default']),
};

RadioGroup.defaultProps = {
  value: null,
  name: UUID.generate(),
  variant: 'radio',
  customClassName: 'col-12 col-md-4 col-lg-2',
  mode: 'default',
};

export default RadioGroup;

// Serves as a wrapper solely to specify the option value

export const RadioGroupOption = ({ children }) => {
  return children;
};

RadioGroupOption.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
};

RadioGroupOption.defaultProps = {
  value: null,
};
