import React, { useContext, useState, useCallback } from 'react';
import { loginSessionStorage } from 'utility/loginSessionStorage';
import { validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';

const LoginContext = React.createContext({
  email: '',
  setEmail: () => null,
  password: '',
  setPassword: () => null,
  errorMessage: '',
  setErrorMessage: () => null,
  emailErrorMessage: '',
  setEmailErrorMessage: () => null,
  submitEmail: () => null,
});

export const LoginProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const submitEmail = useCallback(
    (email) => {
      if (validateEmail(email)) {
        loginSessionStorage.val = email;
        setEmail(email);
        setErrorMessage(null);
      } else {
        setErrorMessage('Please enter a valid email address');
      }
    },
    [setEmail]
  );
  return (
    <LoginContext.Provider
      value={{
        email,
        setEmail,
        password,
        setPassword,
        errorMessage,
        setErrorMessage,
        submitEmail,
        emailErrorMessage,
        setEmailErrorMessage,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginState = () => {
  const ctx = useContext(LoginContext);

  return ctx;
};
