import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toastVariant, useToast } from 'components/ToastProvider';

import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { ACCOUNT } from 'queries/query-keys';
import TeamMemberService from 'services/TeamMemberService';

const fetchTokensQuery = async (params) => {
  const res = await TeamMemberService.getOAuthAccessTokens(params, {
    skipErrorBoundary: true,
  });
  return res;
};

const deleteOAuthQuery = async (id) => {
  const res = await TeamMemberService.deleteOAuthAccessTokens(id);
  return res;
};

export const useOAuthReactQuery = ({
  search,
  ordering,
  pageSize = 20,
  employeeId = null,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const queryClient = useQueryClient();

  const infiniteQuery = useInfiniteQuery({
    queryKey: [...ACCOUNT.OAUTH_TOKENS, employeeId, pageSize, ordering, search],
    queryFn: async ({ pageParam = 1 }) => {
      return fetchTokensQuery({
        search,
        ordering,
        pageSize,
        employeeId,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next
        ? new URL(lastPage.next).searchParams.get('page')
        : undefined;
    },
    getPreviousPageParam: (previousPage) => {
      return previousPage?.prev
        ? new URL(previousPage.prev).searchParams.get('page')
        : undefined;
    },
    keepPreviousData: true,
    retry: false,
    useErrorBoundary: false,
    staleTime: 0, // so that if the user has multiple browser windows open things stay in synch
  });

  const refetchOAuthTokens = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [...ACCOUNT.OAUTH_TOKENS],
    });
  }, [queryClient]);

  const deleteOAuthTokenMutation = useMutation(
    async (result) => {
      await deleteOAuthQuery(result);
    },
    {
      onSuccess: () => {
        refetchOAuthTokens();
      },
      onError: (payload) => {
        const message =
          payload?.response?.data?.[0] ??
          t(
            'The Token could not be deleted, please refresh your browser and try again.'
          );

        showToast({
          message,
          variant: toastVariant.FAILURE,
        });
      },
    }
  );

  return { infiniteQuery, deleteOAuthTokenMutation };
};
