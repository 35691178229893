import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MISSING_URL_TLD_MESSAGE,
  FULLY_QUALIFIED_LINK_MESSAGE,
} from 'constants/errorMessages';

export const checkUrl = (url) =>
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/gm.test(
    url
  );

export const isEmailValid = (value) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

export const urlValidation =
  (t, allowRelativeLinks = false) =>
  (url) => {
    if (!url || url.length === 0) return true;

    const hasProtocol = url.startsWith('http://') || url.startsWith('https://');

    if (!hasProtocol && !allowRelativeLinks) {
      return FULLY_QUALIFIED_LINK_MESSAGE(t);
    }

    if (allowRelativeLinks) {
      return true;
    }

    if (!checkUrl(url)) {
      return MISSING_URL_TLD_MESSAGE(t);
    }

    return true;
  };

export const useGetUrlError = (allowRelativeLinks) => {
  const { t } = useTranslation();

  return useCallback(
    (url) => {
      const error = urlValidation(t, allowRelativeLinks)(url);
      return typeof error === 'string' ? error : false;
    },
    [t, allowRelativeLinks]
  );
};

export const areFiltersEqual = (filterA, filterB) => {
  if (typeof filterA !== typeof filterB) {
    return false;
  }

  if (Array.isArray(filterA) && Array.isArray(filterB)) {
    if (filterA.length !== filterB.length) {
      return false;
    }
    for (let i = 0; i < filterA.length; i++) {
      if (!areFiltersEqual(filterA[i], filterB[i])) {
        return false;
      }
    }

    return true;
  }

  if (typeof filterA === 'object' && typeof filterB === 'object') {
    for (const key in filterA) {
      if (!areFiltersEqual(filterA?.[key], filterB?.[key])) {
        return false;
      }
    }

    for (const key in filterB) {
      if (!areFiltersEqual(filterB?.[key], filterA?.[key])) {
        return false;
      }
    }

    return true;
  }

  return filterA === filterB;
};
