import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@kizen/kds/Button';
import { useEditor, useWYSIWYGAIContext } from '../../../WYSIWYG';
import { useGridLikeFlexChildProps } from '../../components/GridLikeFlex';
import {
  TextEditorToolbarSection,
  ToolbarIconMenuContainer,
  ToolbarIconMenu,
  ToolbarIconMenuList,
  ToolbarIconMenuListItem,
} from '../../styles';

// Note: these values are also specified in the WYSIWYG context (components/WYSIWYG/context.tsx)
const buildOptions = (t) => [
  { value: 'complete', label: t('Complete') },
  { value: 'extend', label: t('Extend') },
  { value: 'rephrase', label: t('Rephrase') },
  { value: 'summarize', label: t('Summarize') },
  {
    value: 'spelling_and_grammar',
    label: `${t('Spelling')} & ${t('Grammar')}`,
  },
];

export const AIToolbarSection = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [ref, props] = useGridLikeFlexChildProps();
  const { performRequest } = useWYSIWYGAIContext();
  const editor = useEditor();
  const options = useMemo(() => {
    return buildOptions(t);
  }, [t]);

  if (!editor) {
    return null;
  }

  return (
    <TextEditorToolbarSection ref={ref} {...props}>
      <ToolbarIconMenuContainer>
        <Button
          variant="text"
          color="inherit"
          rightIcon="text-ai"
          onClick={() => setMenuOpen((x) => !x)}
        />
        {menuOpen && (
          <ToolbarIconMenu top={40}>
            <ToolbarIconMenuList>
              {options.map(({ label, value }) => (
                <ToolbarIconMenuListItem
                  key={value}
                  onClick={() => {
                    setMenuOpen(false);
                    performRequest(value, editor);
                  }}
                >
                  {label}
                </ToolbarIconMenuListItem>
              ))}
            </ToolbarIconMenuList>
          </ToolbarIconMenu>
        )}
      </ToolbarIconMenuContainer>
    </TextEditorToolbarSection>
  );
};
