import styled from '@emotion/styled';

import { gutters } from 'app/spacing';
import { fontSizes, fontWeights } from 'app/typography';
import { colorsButton, grayScale } from 'app/colors';
import BaseCategorySelect from 'components/Fields/CategorySelect';

export const CategorySelector = styled(BaseCategorySelect)`
  && {
    margin-top: -${gutters.spacing(1, -3)}px; // Allow for 15px + change between selector text and field label below
    input,
    .CategorySelector__single-value span {
      font-size: ${fontSizes.text} !important;
      font-weight: ${fontWeights.bold} !important;
    }
    .CategorySelector__menu {
      max-width: ${400 - 2 * gutters.spacing(4)}px;
      margin-top: -${gutters.spacing(2, 2)}px; // Line-up right below text
    }
    .CategorySelector__menu-list {
      max-height: 192px; // Show six items, fit inside min height for modal body
    }
    .CategorySelector__indicators {
      padding-left: ${gutters.spacing(2, -2)}px; // Just a one-off
      i,
      i svg {
        width: 10px;
      }
    }
  }
`;

export const LeadInfoSection = styled.div`
  h3 {
    text-align: center;
    line-height: 1;
    margin-bottom: ${gutters.spacing(4, -8)}px;
  }
  &&& .control {
    // Moving lead source input border to .search-container allow for
    // a hack to disable the input but still show hover states.
    border-bottom: none;
  }
  &&& .search-container {
    cursor: pointer;
    background-color: transparent;
    border-bottom: 1px solid ${grayScale.medium};
    &:hover {
      border-bottom: 1px solid ${colorsButton.blue.hover};
    }
    .search-icon {
      display: none;
    }
    & input.search {
      background-color: transparent;
      &::placeholder {
        color: ${colorsButton.blue.default};
      }
    }
    &:hover input.search::placeholder {
      color: ${colorsButton.blue.hover};
    }
  }
`;
