import { useMemo } from 'react';

import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  SizedCell,
  SortableHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import {
  COLUMNS_SIZE,
  PADDING_SIZE,
} from 'components/MessageTemplatePicker/utility';
import ActionCell from 'components/Tables/ActionCell';

const TableActionCell = (props) => {
  const { data, t } = props;
  const { type } = data;

  const options = useMemo(() => {
    return [{ value: null, label: t('View') }];
  }, [t]);

  return type !== 'folder' ? (
    <ActionCell
      title={t('View Message')}
      menuType="absolute"
      options={options}
      {...props}
    />
  ) : (
    <SizedCell {...props} />
  );
};

export const getTemplatesColumns = ({
  onSubmit,
  t,
  onActionView,
  columnSize = COLUMNS_SIZE,
}) =>
  [
    {
      id: 'spacer-1',
      headCell: <SizedCell width={columnSize.spacer} />,
      cell: <SizedCell width={columnSize.spacer} />,
    },
    {
      id: 'name',
      headCell: (
        <SortableHeadCell
          label={t('Name')}
          width={columnSize.name}
          padding={PADDING_SIZE.basic}
        />
      ),
      cell: <SizedCell width={columnSize.name} padding={PADDING_SIZE.basic} />,
      format: (value, template) => (
        <TextEllipsisWithTooltip
          type="link"
          onClick={(event) => {
            event.preventDefault();
            onSubmit(template);
          }}
        >
          {value}
        </TextEllipsisWithTooltip>
      ),
    },
    {
      id: 'subject',
      headCell: (
        <SortableHeadCell
          label={t('Subject Line')}
          width={columnSize.subject}
          padding={PADDING_SIZE.basic}
        />
      ),
      cell: (
        <SizedCell width={columnSize.subject} padding={PADDING_SIZE.basic} />
      ),
      format: (value) => (
        <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
      ),
    },
    {
      id: 'created',
      headCell: (
        <SortableHeadCell
          width={columnSize.created}
          padding={PADDING_SIZE.small}
          numeric
          label={t('Date Created')}
        />
      ),
      cell: (
        <SizedCell width={columnSize.created} padding={PADDING_SIZE.small} />
      ),
      format: (created) => (
        <DateTimeInputInline readOnly value={created} showDateTooltip />
      ),
    },
    {
      id: 'actions',
      headCell: (
        <SizedCell width={columnSize.actions} padding={PADDING_SIZE.basic}>
          <TextEllipsisWithTooltip as="span" weight="bold">
            {t('Actions')}
          </TextEllipsisWithTooltip>
        </SizedCell>
      ),
      cell: (
        <TableActionCell
          width={columnSize.actions}
          padding={PADDING_SIZE.basic}
          title={t('View Message')}
          menuType="absolute"
          onSelectAction={onActionView}
          t={t}
        />
      ),
    },
  ].filter(Boolean);
