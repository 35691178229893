import { DASHBOARD } from 'queries/query-keys';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import CustomObjectsService from 'services/CustomObjectsService';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import { isContact } from 'utility/fieldHelpers';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const defaultOnActivities = true;

const useFilterCustomObject = (selectedObject, defaultObject, enabled) => {
  const clientObject = useSelector(getBusinessClientObject);

  const { data: customObjectOptions, isLoading: customObjectOptionsLoading } =
    useQuery(
      [
        ...DASHBOARD.CUSTOM_OBJECT_LIST,
        { defaultOnActivities, customOnly: false },
      ],
      () =>
        CustomObjectsService.getCustomObjectList({
          size: FORCE_ALL_RECORDS_SIZE,
          defaultOnActivities,
          customOnly: false,
        }),
      {
        enabled,
      }
    );

  const fallbackObjectValue = useMemo(() => {
    const obj = selectedObject ?? defaultObject;
    return obj?.value ?? obj?.id;
  }, [selectedObject, defaultObject]);

  const rawSelectedObject = useMemo(() => {
    return customObjectOptions?.results?.find(
      ({ id }) => id === fallbackObjectValue
    );
  }, [customObjectOptions, fallbackObjectValue]);

  return {
    object: rawSelectedObject,
    isForCustomObject: !isContact(rawSelectedObject),
    clientObject,
    customObjectOptionsLoading,
  };
};

export default useFilterCustomObject;
