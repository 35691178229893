import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BasicModal from 'components/Modals/presets/BasicModal';

export const ConfirmationModal = React.memo((props) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      size="small"
      typeOfContent="text"
      heading={t('You Have Unsaved Changes')}
      buttonText={t('Confirm Deletion')}
      {...props}
    >
      {t(
        'If you choose a new template, all unsaved changes will be lost. To save your message, cancel and click Save instead.'
      )}
    </BasicModal>
  );
});

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
