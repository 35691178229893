import { FIELD_TYPES } from 'utility/constants';
import { getDateOffset } from 'pages/AutomationEngine/Engine/Steps/type/trigger/OnOrAroundDateCard';
import {
  emptyLongDash,
  getDate,
  formatTimestamp,
} from 'components/Inputs/DateTimeInput/helpers';

import parsePhoneNumber, { AsYouType } from 'libphonenumber-js/max';

export { emptyLongDash, getDate, formatTimestamp };

export const getStringFromObject = (
  value,
  selector = (obj) => String(obj.name)
) => (value ? selector(value) : emptyLongDash);

export const getStringFromArray = (arr, delimeter = ', ') => {
  if (!arr || !Array.isArray(arr) || arr.length === 0)
    return typeof arr === 'string' && arr.length ? arr : emptyLongDash;
  return arr.map((item) => item.name).join(delimeter);
};

export const getPhoneNumber = ({ countryCode, extension, nationalNumber }) => {
  if (!nationalNumber) return emptyLongDash;
  const number = `+${countryCode} ${nationalNumber}`;
  if (extension) return `+${countryCode} ${nationalNumber} x. ${extension}`;
  return number;
};

export const getNumber = (value) =>
  new Intl.NumberFormat('en', { maximumFractionDigits: 20 }).format(value);

export const getPrice = ({ symbol, amount }) => {
  const amountString = new Intl.NumberFormat('en', {
    minimumFractionDigits: 2,
  }).format(parseFloat(amount).toFixed(2));

  return `${symbol} ${amountString}`;
};

const isCustomObject = (metaInfo) =>
  metaInfo &&
  metaInfo.relation &&
  (metaInfo.relation.fetchUrl === 'pipeline' ||
    metaInfo.relation.fetchUrl === 'standard');

const isNullish = (val) => val === null || val === undefined;
export const applyEventDefaults = ({ comments, data, initiator, ...rest }) => ({
  ...rest,
  // Both of these can be nullish or not (even within the same type of event)
  // so default to objects if so to make deep access safe at the Block level
  data: isNullish(data) ? {} : data,
  initiator: isNullish(initiator) ? {} : initiator,
  comments: isNullish(comments)
    ? []
    : comments.sort((a, b) => (a.updated > b.updated ? 1 : -1)),
});

export const getRelationshipLabel = (
  metaInfo,
  { name, displayName, firstName, lastName, email }
) => {
  if (isCustomObject(metaInfo)) return displayName || name;

  return `${firstName ? firstName : ''} ${lastName ? ` ${lastName}` : ''}${
    email ? ` (${email})` : ''
  }`;
};

export const getLink = ({ id, name }, { type, metaInfo }) => {
  if (type === FIELD_TYPES.Relationship.type) {
    if (isCustomObject(metaInfo)) {
      return `/custom-objects/${metaInfo.relation.relatedObject}/${id}/details`;
    }
    return `/client/${id}/details`;
  }

  if (
    type === FIELD_TYPES.Selector.type || // we need this type for timeline
    type === FIELD_TYPES.TeamSelector.type
  ) {
    return `/team-member/${id}/timeline`;
  }

  return `/clients?tag=${encodeURIComponent(JSON.stringify({ id, name }))}`;
};

export const getStatusColor = ({ metaInfo, value }) => {
  const option = metaInfo.options.find((e) => e.id === value.id);
  return option.meta.color;
};

export const FIELDS = {
  [FIELD_TYPES.Files.type]: ({ value }) => getStringFromArray(value),
  [FIELD_TYPES.YesNoMaybe.type]: ({ value }) => getStringFromObject(value),
  [FIELD_TYPES.Radio.type]: ({ value }) => getStringFromObject(value),
  [FIELD_TYPES.Dropdown.type]: ({ value }) => getStringFromObject(value),
  [FIELD_TYPES.Timezone.type]: ({ value }) => getStringFromObject(value),
  [FIELD_TYPES.Status.type]: ({ value }) => getStringFromObject(value),
  [FIELD_TYPES.DateTime.type]: ({ value }) => formatTimestamp(value),
  [FIELD_TYPES.Date.type]: ({ value }) => getDate(value),
  [FIELD_TYPES.Money.type]: ({ value }) =>
    value !== null ? getPrice(value) : emptyLongDash,
  [FIELD_TYPES.PhoneNumber.type]: ({ value }) => {
    if (!value?.e164) return emptyLongDash;
    const formatedNumber = parsePhoneNumber(value.e164);
    return (
      new AsYouType().input(value.e164) +
      (formatedNumber.ext ? ' x ' + formatedNumber.ext : '')
    );
  },
  [FIELD_TYPES.Checkbox.type]: ({ value }) => (value ? 'Yes' : 'No'),
  [FIELD_TYPES.Integer.type]: ({ value }) =>
    value !== null ? getNumber(value) : emptyLongDash,
  [FIELD_TYPES.Decimal.type]: ({ value }) =>
    value !== null ? getNumber(value) : emptyLongDash,
  [FIELD_TYPES.Rating.type]: ({ value = '', metaInfo }) =>
    `${value || emptyLongDash} out of ${metaInfo?.rating?.maxValue}`,
  [FIELD_TYPES.Email.type]: ({ value }) =>
    value ? String(value) : emptyLongDash,
  [FIELD_TYPES.Text.type]: ({ value }) =>
    value ? String(value) : emptyLongDash,
  [FIELD_TYPES.LongText.type]: ({ value }) =>
    value ? String(value) : emptyLongDash,
  // complex
  [FIELD_TYPES.DynamicTags.type]: ({ value }) => getStringFromArray(value),
  [FIELD_TYPES.Checkboxes.type]: ({ value }) => getStringFromArray(value),
};

export const defaultEmployeeString = (initiator) =>
  [
    initiator.firstName,
    initiator.lastName,
    initiator.email ? `(${initiator.email})` : null,
  ]
    .filter(Boolean)
    .join(' ');

export const defaultAutomationString = (initiator, _, t) =>
  `${t('an Automation')} (${initiator.name})`;

export const defaultSmartConnectorString = (initiator, _, t) =>
  `${t('a SmartConnector')} (${initiator.name})`;

export const defaultFormString = (initiator, _, t) =>
  `${t('Form')} (${initiator.name})`;

export const defaultSurveyString = (initiator, _, t) =>
  `${t('Survey')} (${initiator.name})`;

const tagOperationTranslated = {
  added: (t) => t('Added'),
  removed: (t) => t('Removed'),
};
export const triggerData = {
  field_updated: (event, t) =>
    `${t('Field')} '${event.data.trigger.field.displayName}' ${t('Updated')}`,
  new_client_created: (event, t) =>
    `${t('New')} ${event.instance.entityRecordName} ${t('Created')}`,
  stage_updated: (event, t) => `${t('Stage Updated')}`,
  email_received: (event, t) => `${t('Email Received')}`,
  new_entity_created: (event, t) => `${t('New Entity Created')}`,
  activity_logged: (event, t) =>
    `${t('Activity')} '${event.data.trigger.activityType.name}' ${t('Logged')}`,
  form_submitted: (event, t) =>
    `${t('Form')} '${event.data.trigger.form.name}' ${t('Submitted')}`,
  on_or_around_date: (event, t) =>
    `${getDateOffset(event.data.trigger, t)} ‘${
      event.data.trigger.field.displayName
    }’ ${t('at')} ${
      event.data.trigger?.time
    } ${event.data.trigger?.period.toUpperCase()}`,
  contact_tag_added_removed: (event, t) =>
    `${t('Tag')} '${event.data.trigger.tag.name}' ${tagOperationTranslated[
      event.data.trigger.tagOperation
    ](t)}`,
  survey_submitted: (event, t) =>
    `${t('Survey')} '${event.data.trigger.survey.name}' ${t('Submitted')}`,
  scheduled_activity_overdue: (event, t) =>
    `${t('Scheduled Activity')} '${event.data.trigger.activity.name}' ${t(
      'Overdue'
    )}`,
};

export const getFieldsSubmittedEvent = (event, t) => {
  const { data } = event;
  const fields = data.fields
    .filter(({ id }) => data.fieldsWithValues[id])
    .map(({ id, ...metaInfo }) => {
      return {
        label: data.fieldsWithValues[id].displayName,
        value: data.fieldsWithValues[id].value,
        type: data.fieldsWithValues[id].fieldType,
        id: data.fieldsWithValues[id].id,
        metaInfo,
      };
    });
  return {
    ...event,
    data: {
      ...data,
      fields,
    },
  };
};

export const checkBlankHtml = (value) => {
  if (!value?.trim()) return false;
  const doc = new DOMParser().parseFromString(value, 'text/html');
  const texts = Array.from(doc.querySelectorAll('p'));
  return !!texts.length && texts.some((el) => el.textContent.trim());
};
