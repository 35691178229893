import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { breakpoints, gutters } from 'app/spacing';
import Select from 'components/Inputs/Select';

// [TODO} stuart i think this needs to be changed to get rid of the 98% which should be removed
const pageSizingStyles = css`
  // These styles should match the topmost navbar
  width: 1400px;
  max-width: 98%;
  margin: 0 auto;
  // Desired visual width is 1372.
  // (1400 - 1372) / 2 = 14 ~= 15 = gutters.spacing(3)
  padding: 0 ${gutters.spacing(3, -1)}px;

  @media (max-width: ${breakpoints.md}px) {
    width: 100%;
    max-width: unset;
    padding: 0 ${gutters.standard};
  }
`;

export const ViewSelect = styled(Select)`
  min-width: 200px;
`;

export default pageSizingStyles;
