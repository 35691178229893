import AxiosService from './AxiosService';
import { AuthenticationServiceError } from './errors';
import * as auth from 'utility/Authentication';

import { snakeToCamelCaseKeys } from './helpers';

import { publicBusinessDataToOptions } from 'utility/config';
import {
  changeLanguageInStorage,
  PSEUDO_LANGUAGE,
  getCurrentLanguageFromStorage,
} from 'i18n-config';
import { monitoringExceptionHelper } from 'sentry/helpers';

class AuthenticationService {
  /*
   * Singleton ES6 pattern
   * https://www.sitepoint.com/javascript-design-patterns-singleton/
   */
  constructor() {
    if (!AuthenticationService.instance) {
      AuthenticationService.instance = this;
    }
    return AuthenticationService.instance;
  }

  endpoint = '/auth';

  formatError = (error = {}) => {
    const { response } = error;
    if (!response || response.status === 404) {
      return new Error(
        'Something went wrong. Check your network connection and try again.'
      );
    }

    return new Error(
      `${response.statusText} -- ${response.data ? response.data.message : ''}`
    );
  };

  classifyError = (error = {}) => {
    // Something very odd happened; punt
    if (!(error instanceof Error)) {
      return new Error(
        'Something went wrong. Check your network connection and try again.'
      );
    }

    if (!error.isAxiosError) {
      throw error;
    }

    const { response } = error;
    // non-recoverable catch-call e.g. exceptions, Mixed content / network errors
    if (!response || response.status >= 500) {
      throw error;
    }

    throw new AuthenticationServiceError(error.message, {
      status: response.status,
      payload: response.data,
    });
  };

  async fetchAccess() {
    try {
      const { data } = await AxiosService.get(`${this.endpoint}/access`);
      return data;
    } catch (error) {
      let err = error;
      if (error.isAxiosError) {
        err = this.formatError(error);
      }
      throw err;
    }
  }

  async bootstrapSession() {
    try {
      let user = null;
      let business = null;
      let access = null;
      let teamMember = null;
      let filterMetaData = null;
      let externalApps = {};
      let pluginApps = [];
      const authorised = auth.status.authorized;
      if (authorised) {
        const [bootstrapResponse, filtersResponse] = await Promise.all([
          AxiosService.get('/auth/bootstrap'),
          AxiosService.get('/filters/metadata'),
        ]);
        ({
          data: {
            me: user,
            business,
            access,
            team: teamMember,
            external_apps: externalApps,
            enabled_plugin_apps: pluginApps,
          },
        } = bootstrapResponse);
        ({ data: filterMetaData } = filtersResponse);
      }

      // eek should not realy be a service level thing

      if (teamMember?.language) {
        changeLanguageInStorage(
          getCurrentLanguageFromStorage() === PSEUDO_LANGUAGE
            ? PSEUDO_LANGUAGE
            : teamMember.language
        );
      }
      return {
        user,
        business,
        access,
        teamMember,
        filterMetaData,
        externalApps,
        pluginApps,
      };
    } catch (error) {
      let err = error;
      if (error.isAxiosError) {
        const currentError = this.formatError(error);
        err =
          typeof currentError === 'string'
            ? currentError
            : currentError.toString();
      }
      throw err;
    }
  }

  async login(username, password) {
    let user = {};
    try {
      const response = await AxiosService.post(
        `/login`,
        {
          username,
          password,
        },
        {
          timeout: null,
        }
      );

      user = response.data;

      // Only single-business users will receive a complete-scoped token and cookie
      // multi-business users and superusers will later have to select a business
      const firstBusinessId = user?.first_business?.id;
      if (firstBusinessId) {
        auth.status.businessId = firstBusinessId;
      }

      return { token: null, user };
    } catch (error) {
      throw this.classifyError(error);
    }
  }

  async logout() {
    try {
      await AxiosService.post(`/logout`);
    } catch (error) {
      monitoringExceptionHelper(error);
      let err = error;
      if (error.isAxiosError) {
        err = this.formatError(error);
      }
      throw err;
    }
  }

  async fetchBusinesses(showAllBusinesses) {
    try {
      let url = '/my-businesses';
      // This doesn't do anything unless the current user is a super user:
      if (showAllBusinesses) {
        url += '?show_all=true';
      }
      const { data } = await AxiosService.get(url); // under team
      return snakeToCamelCaseKeys(data.businesses);
    } catch (error) {
      monitoringExceptionHelper(error);
      let err = error;
      if (error.isAxiosError) {
        err = this.formatError(error);
      }
      throw err;
    }
  }

  async fetchBusiness(businessId) {
    try {
      const response = await AxiosService.get(
        `/business/${businessId}?v2=true`
      );
      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      let err = error;
      if (error.isAxiosError) {
        err = this.formatError(error);
      }
      throw err;
    }
  }

  async chooseBusiness(businessId) {
    try {
      const response = await AxiosService.post(`select-business`, {
        businessId,
      });
      return response.data;
    } catch (error) {
      monitoringExceptionHelper(error);
      let err = error;
      if (error.isAxiosError) {
        err = this.formatError(error);
      }
      throw err;
    }
  }

  async requestPasswordReset(email) {
    try {
      const response = await AxiosService.post(`/request-password-reset`, {
        email,
      });
      return response.data;
    } catch (error) {
      throw this.classifyError(error);
    }
  }

  async resetPassword(user_token, password) {
    try {
      const response = await AxiosService.post(`/reset-password`, {
        password,
        user_token,
      });
      return response.data;
    } catch (error) {
      throw this.classifyError(error);
    }
  }

  async verifyaccount(user_token, password, firstName, lastName, phone) {
    try {
      const response = await AxiosService.post(`/verify-account`, {
        password,
        user_token,
        first_name: firstName,
        last_name: lastName,
        ...(phone ? { phone } : null),
      });
      return response.data;
    } catch (error) {
      throw this.classifyError(error);
    }
  }

  async fetchCurrentUserUsingToken(token) {
    try {
      const response = await AxiosService.post(`/me`, {
        token,
      });
      return response.data;
    } catch (error) {
      throw this.classifyError(error);
    }
  }

  async getBusinessInformation() {
    try {
      const response = await AxiosService.get('/business/mine?v2=true');
      return response.data;
    } catch (error) {
      throw this.classifyError(error);
    }
  }

  async updateBusinessInformation(data) {
    try {
      const response = await AxiosService.patch('/business/mine?v2=true', data);
      return response.data;
    } catch (error) {
      throw this.classifyError(error);
    }
  }

  async createUser(user) {
    const response = await AxiosService.post(`/register`, user);
    return response.data;
  }

  async createBusiness(businessInfo, { no_cc, skipErrorBoundary = false }) {
    const response = await AxiosService.post(
      '/business?v2=true',
      businessInfo,
      {
        params: { no_cc }, // send no_cc param so the trial can be activated and billing page avoided
        skipErrorBoundary,
      }
    );
    return response.data;
  }

  async updateBusiness(businessInfo) {
    const response = await AxiosService.patch(
      '/business?v2=true',
      businessInfo
    );
    return response.data;
  }

  async getBusinessTypesAndTimezones(cb) {
    const response = await AxiosService.get('/public/business/config-options');
    return cb && typeof cb === 'function'
      ? cb(response.data)
      : publicBusinessDataToOptions(response.data);
  }

  async getBusinessTextNumber() {
    const response = await AxiosService.get('/business/sms-account');

    return response.data;
  }

  async preLogin({ username }) {
    const { data } = await AxiosService.post('/auth/pre-login', {
      username,
    });
    return snakeToCamelCaseKeys(data);
  }

  async getBusinessCalendarSettings(options) {
    const { data } = await AxiosService.get('/business/calendar', options);
    return data;
  }

  async updateBusinessCalendarSettings(payload, options) {
    const { data } = await AxiosService.post(
      '/business/calendar',
      payload,
      options
    );
    return data;
  }

  async getBusinessHolidaysByCountry(body, options) {
    const { data } = await AxiosService.post(
      '/business/calendar/holidays',
      body,
      options
    );
    return data;
  }

  async getBusinessHolidaysCountries(options) {
    const { data } = await AxiosService.get(
      '/business/calendar/countries',
      options
    );
    return data;
  }
}

const instance = new AuthenticationService();
Object.freeze(instance);

export default instance;
