import { gutters } from 'app/spacing';
import Section from 'components/Wizards/shared/components/section';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import FilterSummary from 'components/Wizards/shared/components/MetaFilters/Summary';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import ButtonGroup from 'components/Inputs/ButtonGroup';
import { AREA_RESPONSES, VALUE_RESPONSES } from 'components/Wizards/RTDV/types';
import useDataConstraint from 'components/Wizards/shared/hooks/useDataConstraint';
import useDefaultValues from 'components/Wizards/shared/hooks/useDefaultValue';
import {
  isCustomFilter,
  isInGroupFilter,
  isNotInGroupFilter,
} from 'components/Wizards/MetaFilters/types';
import {
  allowedFieldTypesWithOptions,
  allowedNumberFieldTypes,
  BUCKETING_TYPES,
  canChooseDisplayValueTypes,
  simpleFieldTypes,
  entitiesFieldTypes,
} from 'components/Wizards/utils';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import BreakdownValueType from './valueTypes/Breakdown';
import AverageValueType from './valueTypes/Average';
import TableReportType from './reportTypes/Table';
import SalesRecordsWonReportType from './reportTypes/RecordsWon';
import { PivotTableReportType } from './reportTypes/PivotTable';

const ValuesAndConstraintsWizardSection = ({
  index,
  active,
  done,
  last,
  mobile,
  objectId,
  contacts,
  ...rest
}) => {
  const { t } = useTranslation();
  const title = rest.title || t('Values & Constraints');
  const { state, setState, filterCount, filterState } = useContext(
    WizardStateContext.Context
  );

  const { reportType, fieldObject, value, field } = state;

  const selectedField = useMemo(() => {
    return fieldObject?.fields?.find((f) => f.id === field?.value);
  }, [fieldObject, field]);

  useEffect(() => {
    if (simpleFieldTypes.includes(selectedField?.fieldType)) {
      if (state.valueBreakdown?.length) {
        setState('valueBreakdown', []);
      }
      if (state.valueBreakdownError) {
        setState('valueBreakdownError', false);
      }
      if (!state.bucketBlank) {
        setState('bucketBlank', true);
      }
    }
  }, [
    selectedField,
    state.valueBreakdown,
    state.valueBreakdownError,
    setState,
    state.bucketBlank,
  ]);

  const bucketingType = useMemo(() => {
    if (allowedNumberFieldTypes.includes(selectedField?.fieldType)) {
      return BUCKETING_TYPES.NUMBER;
    }
    if (allowedFieldTypesWithOptions.includes(selectedField?.fieldType)) {
      return BUCKETING_TYPES.OPTIONS;
    }
    if (entitiesFieldTypes.includes(selectedField?.fieldType)) {
      return BUCKETING_TYPES.ENTITIES;
    }
    return BUCKETING_TYPES.NONE;
  }, [selectedField?.fieldType]);

  useMetadataKey('bucketingType', bucketingType);

  const canChooseDisplayValue =
    canChooseDisplayValueTypes.includes(selectedField?.fieldType) &&
    reportType?.value === AREA_RESPONSES.METRICS;

  useMetadataKey('canChooseDisplayValue', canChooseDisplayValue);

  const displayValueSum = useMemo(() => {
    return {
      value: VALUE_RESPONSES.SUM,
      label: t('Sum'),
    };
  }, [t]);

  const displayValueBreakdown = useMemo(() => {
    return {
      value: VALUE_RESPONSES.BREAKDOWN,
      label: t('Value Breakdown'),
    };
  }, [t]);

  const valueOptions = useMemo(() => {
    return [
      displayValueSum,
      {
        value: VALUE_RESPONSES.AVERAGE,
        label: t('Average'),
      },
      displayValueBreakdown,
    ];
  }, [t, displayValueSum, displayValueBreakdown]);

  useDataConstraint((internalState) => {
    if (
      internalState.value?.value !== VALUE_RESPONSES.BREAKDOWN &&
      internalState.valueBreakdownError
    ) {
      return {
        key: 'valueBreakdownError',
        value: undefined,
      };
    }
  });

  useDefaultValues(
    {
      value: displayValueSum,
      includeBlank: true,
    },
    (active || done) && canChooseDisplayValue
  );

  useDefaultValues(
    {
      value: displayValueBreakdown,
    },
    (active || done) && !canChooseDisplayValue
  );

  const handleChangeFilters = useCallback(
    (filters) => {
      setState('customFilters', undefined);
      setState('inGroupFilters', undefined);
      setState('notInGroupFilters', undefined);

      if (isCustomFilter(filters?.details?.type)) {
        setState('customFilters', filters?.details?.filterConfig);
      } else if (isInGroupFilter(filters?.details?.type)) {
        setState(
          'inGroupFilters',
          filters?.details?.groups?.map((g) => g.id)
        );
      } else if (isNotInGroupFilter(filters?.details?.type)) {
        setState(
          'notInGroupFilters',
          filters?.details?.groups?.map((g) => g.id)
        );
      }
    },
    [setState]
  );

  return (
    <>
      <Section
        index={index}
        title={title}
        active={active}
        done={done}
        last={last}
      >
        <StyledWizardField top={gutters.spacing(2, 2)}>
          <FilterSummary
            filterName={t('Chart Filters')}
            filterCount={filterCount}
            top={gutters.spacing(2, 2)}
            customObject={fieldObject}
            state={filterState}
            mobile={mobile}
            area={reportType?.value}
            onChange={handleChangeFilters}
            charts
          />
          <StyledWizardField top={gutters.spacing(3, 1)} />
        </StyledWizardField>
        {canChooseDisplayValue ? (
          <StyledWizardField top={gutters.spacing(2, 2)}>
            <KizenTypography type="subheader">
              {t('Select Value to Display')}
            </KizenTypography>
            <StyledWizardField top={gutters.spacing(1, 1)}>
              <ButtonGroup
                value={value}
                onChange={(v) => setState('value', v)}
                sizing={!mobile ? 'auto' : ''}
                options={valueOptions}
                className="wizard-buttons value-to-display"
                columns={mobile ? 2 : 4}
                mode="wizard"
                gutter={`${gutters.spacing(mobile ? 2 : 3)}px`}
              />
            </StyledWizardField>
          </StyledWizardField>
        ) : null}
        {reportType?.value !== AREA_RESPONSES.SALES ? (
          <>
            {/* Only renders when value is active */}
            <AverageValueType selectedField={selectedField} />
            {/* Only renders when breakdown type is active */}
            <BreakdownValueType selectedField={selectedField} />
            {/* Only renders when report type is table */}
            <TableReportType
              selectedField={selectedField}
              objectId={objectId}
              contacts={contacts}
            />
          </>
        ) : null}

        {/* Only renders when report type is sales records won */}
        <SalesRecordsWonReportType
          mobile={mobile}
          objectId={objectId}
          contacts={contacts}
        />

        <PivotTableReportType
          objectId={objectId}
          contacts={contacts}
          mobile={mobile}
          active={active}
        />
      </Section>
    </>
  );
};

export default ValuesAndConstraintsWizardSection;
