import styled from '@emotion/styled';
import { fontSizes, fontWeights } from 'app/typography';
import { SettingsContentCard } from 'components/Card/Card';
import EditableText from 'components/Kizen/EditableText';
import { contentWidthCss } from 'components/Layout/PageContentWidth';
import PageToolbar from 'components/Layout/PageToolbar';
import Subsection from 'components/Modals/Common/Subsection';
import { DndColumns } from 'components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/DnDColumns';
import { NAV_BAR_HEIGHT, TOOLBAR_HEIGHT } from './constants';
import BigTableLayout from 'components/Layout/BigTableLayout';

export const StyledPageToolbar = styled(PageToolbar)`
  ${contentWidthCss};
  margin: 20px auto;
  padding: 0;
  height: 36px;
`;

export const StyledTableLayout = styled(BigTableLayout)`
  margin-top: -20px;
`;

export const Wrapper = styled.div`
  ${contentWidthCss};
  display: flex;
  flex-direction: column;
`;

export const ContentCard = styled(SettingsContentCard)`
  height: calc(
    100vh - ${NAV_BAR_HEIGHT + TOOLBAR_HEIGHT + 20}px +
      ${({ heightOffset }) => heightOffset || 0}px
  );
`;

export const CardTitle = styled(Subsection)`
  & > h3 {
    margin-bottom: 0;
  }
  && {
    margin-bottom: 25px;
  }
`;

export const StyledDndColumns = styled(DndColumns)`
  min-height: calc(100% - 66px);
  margin-bottom: 12px;
`;

export const TemplateName = styled(EditableText)`
  max-width: 350px;
  input {
    font-size: ${fontSizes.text};
    font-weight: ${fontWeights.bold};
  }
`;
