import React, { useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { gutters } from 'app/spacing';
import { TextEllipsis } from 'components/Kizen/Table';
import ButtonGroupButton from '../ButtonGroup/Button';
import { hasInputControl } from '../InputControl';
import { selectedValueProp } from '../props';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

export const minValueRating = 5;
export const maxValueRating = 10;

const BottomLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > p {
    margin-top: 0;
    width: calc((100% - ${gutters.spacing(4)}px) / 2);
  }

  & > p:last-child {
    text-align: right;
  }
`;

const TopLabelsWrapper = styled.div`
  display: flex;
  margin-bottom: ${gutters.spacing(2, -2)}px;

  p {
    width: calc((100% - ${gutters.spacing(4)}px) / 2);
  }
`;

const ButtonLayout = styled.div`
  display: grid;
  margin-bottom: ${gutters.spacing(2, 1)}px;
  ${({ maxRating }) => css`
    grid-template-columns: repeat(${maxRating}, minmax(25px, 36px));
  `}
  column-gap: ${gutters.spacing(2)}px;
  ${({ hasSecondRow, maxRating }) =>
    hasSecondRow &&
    maxRating === maxValueRating &&
    css`
      grid-template-columns: repeat(5, minmax(25px, 36px));
      grid-template-rows: repeat(2, 1fr);
      row-gap: ${gutters.spacing(2)}px;
    `}

  & > * {
    display: inline-block;
  }
`;

const OutlineRating = React.forwardRef(function OutlineRating(props, ref) {
  const {
    fieldId,
    value,
    onChange,
    error,
    disabled,
    className,
    maxRating,
    hasSecondRow,
    lowLabel,
    highLabel,
    options,
    required,
    mergeRef,
  } = props;
  const { assignFieldHandle } = useKeyBoardContext();
  const showSecondRow = hasSecondRow && maxRating === maxValueRating;
  const buttonRef = useRef([]);

  const selectedElem = useMemo(() => {
    const index = options.map((i) => i.value).indexOf(value?.value);
    return index >= 0 ? index : 0;
  }, [options, value]);

  const focused = useRef(selectedElem);

  assignFieldHandle(fieldId, {
    customRight: (e) => {
      e.preventDefault();
      if (focused.current === buttonRef.current?.length - 1) return false;
      focused.current += 1;
      buttonRef.current[focused.current]?.focus();
      return true;
    },
    customLeft: (e) => {
      e.preventDefault();
      if (options.length === 1) {
        return false;
      }
      if (focused.current === 0) return false;
      focused.current -= 1;
      buttonRef.current[focused.current]?.focus();
      return true;
    },
    customFocus: () => {
      buttonRef.current?.[selectedElem]?.focus();
      focused.current = selectedElem;
      return buttonRef.current?.[selectedElem];
    },
    customSpace: (e) => {
      e.preventDefault();
      buttonRef.current[focused.current]?.click();
      buttonRef.current[focused.current]?.focus();
    },
    disabled,
  });
  return (
    <div ref={mergeRef}>
      {showSecondRow && (
        <TopLabelsWrapper>
          <TextEllipsis type="micro">{lowLabel}</TextEllipsis>
        </TopLabelsWrapper>
      )}
      <ButtonLayout
        className={!hasInputControl(props) && className}
        maxRating={maxRating}
        hasSecondRow={hasSecondRow}
      >
        {options.map((opt, index) => {
          const selected = Boolean(value && value.value === opt.value);
          return (
            <ButtonGroupButton
              ref={(el) => {
                buttonRef.current[index] = el;
              }}
              option={opt}
              key={opt.value}
              selected={selected}
              error={error}
              disabled={disabled}
              onClick={(ev) => {
                if (onChange) {
                  if (selected && !required) {
                    ev.currentTarget.blur();
                    onChange(null, ev);
                  } else {
                    onChange(opt, ev);
                  }
                }
              }}
            />
          );
        })}
      </ButtonLayout>
      <BottomLabelsWrapper>
        {!showSecondRow ? (
          <TextEllipsis type="micro">{lowLabel}</TextEllipsis>
        ) : (
          <div />
        )}
        <TextEllipsis type="micro">{highLabel}</TextEllipsis>
      </BottomLabelsWrapper>
    </div>
  );
});

OutlineRating.propTypes = {
  value: selectedValueProp,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  lowLabel: PropTypes.string,
  highLabel: PropTypes.string,
  hasSecondRow: PropTypes.bool.isRequired,
  maxRating: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
};

OutlineRating.defaultProps = {
  value: null,
  onChange: null,
  error: null,
  disabled: null,
  lowLabel: 'Unsatisfied',
  highLabel: 'Satisfied',
};

export default Object.assign(OutlineRating, {
  // eslint-disable-next-line react/forbid-foreign-prop-types
  propTypes: OutlineRating.propTypes,
  defaultProps: OutlineRating.defaultProps,
});
