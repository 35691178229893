import BasicModal from '__components/Modals/presets/BasicModal';
import { useMemo, useState } from 'react';
import { JSONEditor } from '../JSONEditor';
import { useTranslation } from 'react-i18next';
import { Typography } from '@kizen/kds/Typography';
import { Spacer } from '@kizen/kds/Spacer';
import { MarketplacePlugin } from 'pages/Marketplace/types';
import { ValidationSizer } from 'pages/Marketplace/styles';

interface IntegrationModalProps {
  onConfirm: (...args: any[]) => any;
  onHide: (...args: any[]) => any;
  show: boolean;
  config: Record<string, any>;
  plugin: MarketplacePlugin;
}

export const IntegrationModal = ({
  onConfirm,
  onHide,
  show = false,
  config,
  plugin,
}: IntegrationModalProps) => {
  const [value, setValue] = useState(() => JSON.stringify(config, null, 2));
  const { t } = useTranslation();

  const { isValid, parsed } = useMemo(() => {
    try {
      const parsed = JSON.parse(value);
      return { isValid: true, parsed };
    } catch (e) {
      return { isValid: false, parsed: {} };
    }
  }, [value]);

  if (!show) {
    return null;
  }

  return (
    <BasicModal
      heading={t('Configure {{pluginName}} Installation', {
        pluginName: plugin.name,
      })}
      buttonText="Save"
      actionBtnColor="green"
      onConfirm={() => onConfirm(parsed)}
      onHide={onHide}
      show
      size="large"
      disabled={!isValid}
    >
      <JSONEditor value={value} onChange={setValue} />
      <Spacer size={5} mode="horizontal" />
      <ValidationSizer className="flex">
        {isValid ? (
          <>
            <Typography color="feedback-toast-success" className="ml-auto">
              {t('JSON is valid')}
            </Typography>
          </>
        ) : (
          <>
            <Typography color="feedback-toast-error" className="ml-auto">
              {t('JSON is invalid')}
            </Typography>
          </>
        )}
      </ValidationSizer>
    </BasicModal>
  );
};
