import styled from '@emotion/styled';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

const Title = styled(TextEllipsisWithTooltip)`
  max-width: 440px;
  line-height: 1.2; // Weirdly fighting against some styles from the DateRangePicker
`;

export const PageToolbarTitle = ({
  count,
  single,
  plural,
  children,
  ...props
}) => (
  <Title as="h2" type="header" {...props}>
    {children || (
      <>
        {count.toLocaleString()} {count === 1 ? single : plural}
      </>
    )}
  </Title>
);
