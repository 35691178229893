import React, { forwardRef, useEffect, useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useViewerContext } from '../viewer';
import { ImageContainer } from './containers';
import Icon, { IconSizing } from '../internal/components/Kizen/Icon';
import { colorsButton } from '../internal/app/colors';

const justifyContent = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const StyledIconSizingWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ height, isViewerCtx }) =>
    height &&
    isViewerCtx &&
    css`
      height: ${height}px;
    `}
`;
export const ViewImageContainer = styled(ImageContainer)`
  display: ${({ display }) => display || 'flex'};
  justify-content: ${({ position }) => justifyContent[position] || 'center'};
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
`;

export const BaseImage = styled.img`
  max-width: 100%;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}

  ${({ size, unit, width, height }) => {
    if (size === 'dynamic') {
      return css`
        height: auto;
        ${width && unit === 'pixel' && `width: ${width}px;`}
        ${height && unit === 'pixel' && `height: ${height}px;`}
      ${width && unit === 'percent' && `width: ${width}%;`}
      `;
    } else if (size === 'fixed') {
      return css`
        ${width && `width: ${width}px;`}
        ${height && `height: ${height}px;`}
      `;
    }
  }}
`;

export const LinkWrapper = styled.a`
  display: flex;
  justify-content: ${({ position }) => justifyContent[position] || 'center'};
`;

export const NoImageIcon = ({ onClick, height, isViewerCtx, alt }) => {
  return (
    <StyledIconSizingWrapper height={height} isViewerCtx={isViewerCtx}>
      <IconSizing
        size="14px"
        style={{ cursor: isViewerCtx ? 'default' : 'pointer' }}
      >
        <Icon
          clickable
          onClick={!isViewerCtx && onClick}
          icon="image"
          alt={alt}
          color={colorsButton.blue.default}
        />
      </IconSizing>
    </StyledIconSizingWrapper>
  );
};

export const ViewImage = forwardRef((props, ref) => {
  const {
    nodeId,
    src,
    alt,
    link,
    unit,
    display,
    position,
    height,
    width,
    minHeight,
    onNoImageClick,
    removeLink = false,
    clickable = false,
    ...rest
  } = props;

  const [imageError, setImageError] = useState(false);
  const viewerCtx = useViewerContext();

  useEffect(() => {
    setImageError(false);
  }, [src]);

  const { size, naturalHeight, naturalWidth } = rest;
  const ImageWrapper =
    !removeLink && link?.length
      ? ({ children, ...rest }) => (
          <a href={link} target="_blank" rel="noreferrer" {...rest}>
            {children}
          </a>
        )
      : React.Fragment;
  return (
    <ViewImageContainer
      ref={ref}
      display={display}
      position={position}
      minHeight={minHeight}
      {...rest}
    >
      <ImageWrapper>
        {imageError || !src ? (
          <NoImageIcon
            isViewerCtx={!Array.isArray(viewerCtx)}
            height={naturalHeight}
            alt={alt}
            onClick={onNoImageClick}
          />
        ) : (
          <BaseImage
            onError={() => setImageError(true)}
            src={src}
            alt={alt}
            unit={unit}
            height={height}
            width={width}
            size={size}
            naturalHeight={naturalHeight}
            naturalWidth={naturalWidth}
            clickable={clickable}
          />
        )}
      </ImageWrapper>
    </ViewImageContainer>
  );
});
