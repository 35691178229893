import React from 'react';
import styled from '@emotion/styled';
import { grayScale, shadowLight } from 'app/colors';
import { breakpoints, gutters, borderRadii } from 'app/spacing';
import { contentWidthCss } from 'components/Layout/PageContentWidth';
import { BaseSubNavigationNavBar } from './SubNavigationNavBar';

export const AltSubNavigationNavBar = styled(BaseSubNavigationNavBar)`
  ${contentWidthCss}
  & .navbar {
    margin: ${gutters.spacing(2)}px auto 0 auto;
    border-radius: 0;
    width: 100%;
    background: ${grayScale.white};
    padding: 0;
  }
  & .navbar-nav {
    flex: 1;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row !important;
  }
  & .navbar-toggler {
    margin: ${gutters.spacing(2)}px;
  }

  @media (min-width: ${breakpoints.md + 1}px) {
    & .navbar {
      margin: ${gutters.spacing(4)}px auto;
      border-radius: ${borderRadii.standard};

      background: ${grayScale.white};
    }
    & .navbar-nav {
      flex: 1;
      justify-content: space-evenly;
      flex-direction: row !important;
    }
    & .navbar-toggler {
      margin: unset;
    }
  }
`;

export const AltSmallSubNavigationNavBar = styled(({ children, ...props }) => (
  <div {...props}>
    <nav>{children}</nav>
  </div>
))`
  display: flex;
  justify-content: center;
  margin-bottom: ${gutters.spacing(4)}px;
  > nav {
    display: flex;
    background-color: ${grayScale.white};
    ${shadowLight}
  }
`;
