import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Kizen/Loader';
import { setSearchParams, getSearchParam } from 'hooks/useSearchParam';
import { isMobile, useWindowSize } from 'app/spacing';
import CustomObjectsService from 'services/CustomObjectsService';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { setOpenMenuAbove } from 'components/Tables/helpers';
import { useToast } from 'components/ToastProvider';
import { getCustomObjectAccess } from 'store/authentication/selectors';
import {
  buildPage,
  getCustomObjects,
  updatePageConfig,
  getCustomObjectBySearch,
  updatePageConfigBySearch,
  updateCustomObjectField,
  deleteCustomObject,
  cleanDeleteCustomObjectToastMessage,
  getCustomObjectsSelector,
  getCustomObjectsCountSelector,
  getCustomObjectsPageConfigSelector,
  getCustomObjectDeleteToastMessageSelector,
  getCustomObjectsPageIsFetching,
} from 'store/customObjectsPage/customObjects.redux';
import CustomObjectTabularPage from './TabularPage';
import CustomObjectListPage from './ListPage';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useDebounce } from 'react-use';
import { maybeTrackSearch } from 'utility/analytics';
import { DEFAULT_DELAY } from 'utility/config';
import { deferExecution } from 'utility/defer';
import {
  CUSTOMIZE_FIELDS_STEP_KEY,
  GO_BACK_KEYS,
} from 'components/Wizards/CustomObject/constants';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

export default function CustomObjectsPagePage(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  useSetTitleOnLoad(props.title(t));

  const [showToast] = useToast();
  const [isMenuOpen, toggleMenu] = useState(false);
  const isFetching = useSelector(getCustomObjectsPageIsFetching);
  const pageConfig = useSelector(getCustomObjectsPageConfigSelector);
  const customObjectsCount = useSelector(getCustomObjectsCountSelector);
  const customObjectsWithoutMeta = useSelector(getCustomObjectsSelector);
  const groups = useSelector((s) => s.customObjectsPage.groups);
  const globalCustomObjectPermission = useSelector(getCustomObjectAccess);

  // Track debounced changes to the search term and send to google analytics
  const { search } = pageConfig;
  useDebounce(() => maybeTrackSearch(search, 'custom_objects'), DEFAULT_DELAY, [
    search,
  ]);

  // async effects -------------------------------------------------
  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const group = getSearchParam(history.location, 'group');
    const search = getSearchParam(history.location, 'q');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');

    syncDispatch(
      buildPage({
        page: {
          search: search || '',
          group,
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
        },
      })
    );
  }, [syncDispatch, history]);

  const customObjects = useMemo(() => {
    return (
      customObjectsWithoutMeta && customObjectsWithoutMeta.map(setOpenMenuAbove)
    );
  }, [customObjectsWithoutMeta]);

  const handleChangeSearch = useCallback(
    (q) => {
      const { group, filterObject, queryFilter } = pageConfig;

      dispatch(
        updatePageConfigBySearch({
          search: q,
          page: 1,
          filterObject: group || queryFilter ? filterObject : {},
        })
      );
      dispatch(
        getCustomObjectBySearch({
          updatePageConfig: false,
        })
      );
      deferExecution(() => {
        setSearchParams(
          history,
          { q, page: null, group: group || null },
          {
            method: 'replace',
          }
        );
      });
    },
    [dispatch, history, pageConfig]
  );

  const sortValue = useMemo(
    () => getSortValue(pageConfig.sort),
    [pageConfig.sort]
  );
  const handleSort = useCallback(
    ({ column, direction }) => {
      const value = getOrderingParam({
        column,
        direction,
      });

      dispatch(
        updatePageConfig({
          sort: value,
        })
      );
      dispatch(getCustomObjects());
      deferExecution(() => {
        setSearchParams(
          history,
          {
            sort: value,
          },
          {
            method: 'replace',
          }
        );
      });
    },
    [dispatch, history]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          page: value,
        })
      );
      dispatch(getCustomObjects());
      deferExecution(() => {
        setSearchParams(history, { page: value });
      });
    },
    [dispatch, history]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          size: value,
        })
      );
      dispatch(getCustomObjects());
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleUpdateCustomObjectField = useCallback(
    async (customObject, patch) => {
      try {
        const updatedCustomObject =
          await CustomObjectsService.updateCustomObject({
            customObject,
            updatedCustomObjectValues: patch,
          });
        dispatch(updateCustomObjectField(updatedCustomObject));
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = Object.entries(err.response.data.errors);
          if (errors && errors.length) {
            // we show only 1st error
            error = new Error(errors[0][1]);
          }
        }
        throw error;
      }
    },
    [dispatch]
  );

  const handleEditCustomFields = (id) => {
    history.push({
      pathname: `/custom-objects/${id}/settings`,
      search: history.location.search,
      state: {
        from: history.location.pathname,
        fromKey: GO_BACK_KEYS.OBJECTS,
        focusStepKey: CUSTOMIZE_FIELDS_STEP_KEY,
      },
    });
  };

  const handleConfirmDeleteCustomObject = (id) =>
    dispatch(deleteCustomObject(id));

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const handleDeleteCustomObject = (id) =>
    setDeleteConfirmation({
      id,
    });

  const customObjectDeleteToastMessage = useSelector(
    getCustomObjectDeleteToastMessageSelector
  );

  useEffect(() => {
    if (customObjectDeleteToastMessage) {
      showToast(customObjectDeleteToastMessage);
      dispatch(cleanDeleteCustomObjectToastMessage());
    }
  }, [customObjectDeleteToastMessage, showToast, dispatch]);

  const handleSelectEditObjectSettings = useCallback(
    async (id) => {
      history.push({
        pathname: `/custom-objects/${id}/settings`,
        search: history.location.search,
        state: {
          from: history.location.pathname,
          fromKey: GO_BACK_KEYS.OBJECTS,
        },
      });
    },
    [history]
  );

  if (isFetching) {
    return <Loader loading />;
  }

  return (
    <>
      {deleteConfirmation && (
        <ConfirmDeletionModal
          show
          onHide={() => setDeleteConfirmation(null)}
          onConfirm={async () => {
            const { id } = deleteConfirmation;
            try {
              await handleConfirmDeleteCustomObject(id);
            } finally {
              setDeleteConfirmation(null);
            }
          }}
        >
          {t(
            'This will permanently delete the Custom Object, all associated records and data.'
          )}
        </ConfirmDeletionModal>
      )}
      {isMobile(width) ? (
        <CustomObjectListPage
          customObjects={customObjects || []}
          customObjectsCount={customObjectsCount}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSort}
          pageNumber={pageConfig.page || 1}
          pageSize={pageConfig.size || 50}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onSelectDeleteCustomObject={handleDeleteCustomObject}
          {...props}
        />
      ) : (
        <CustomObjectTabularPage
          customObjects={customObjects || []}
          customObjectsCount={customObjectsCount}
          globalCustomObjectPermission={globalCustomObjectPermission}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSort}
          pageNumber={pageConfig.page || 1}
          pageSize={pageConfig.size || 50}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onUpdateCustomObjectField={handleUpdateCustomObjectField}
          onSelectEditObjectSettings={handleSelectEditObjectSettings}
          onSelectEditCustomFields={handleEditCustomFields}
          onSelectDeleteCustomObject={handleDeleteCustomObject}
          groups={groups}
          openMenu={isMenuOpen}
          toggleMenu={toggleMenu}
          {...props}
          data-qa="custom-objects-table"
        />
      )}
    </>
  );
}
