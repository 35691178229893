import { useState, useCallback, useMemo, useRef, cloneElement } from 'react';
import { useClickAway } from 'react-use';
import Overlay from 'react-bootstrap/Overlay';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import KizenTypography from 'app/kizentypo';
import { getAppRoot } from 'utility/app';
import FieldService from 'services/FieldService';
import PipelineService from 'services/PipelineService';
import { getOriginalError } from 'services/AxiosService';
import { EmptyCell } from 'components/Kizen/Table';
import {
  getFieldsForUI,
  ownerToOption,
} from 'store/customObjectsRecordsPage/saga';
import { isPipelineObject } from 'components/Modals/utilities';
import { snakeToCamelCaseKeys } from 'services/helpers';
import {
  UnarchiveConflictModal,
  UnarchiveForbiddenModal,
} from 'components/Modals/UnarchiveModal';
import {
  CONTACT,
  PERMISSION_DENIED,
  UNARCHIVE_CONFLICT,
  UNARCHIVE_PROMPT,
  UNARCHIVE,
  dummyObject,
} from 'components/Modals/CreateEntity/helpers';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import {
  getAuthTeamMember,
  getBusinessTimeZone,
} from 'store/authentication/selectors';
import {
  cloneFormattedElement,
  getCreationFieldIds,
  getDefaultNewRecord,
  getNextField,
} from './helpers';
import { ErrorCard, TRow } from './styles';
import { PADDING_IN_PIXELS } from 'pages/Common/components/BigTableCells';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import { monitoringMessageHelper } from 'sentry/helpers';

export default function AddRecordTRow({
  model,
  addingId,
  fields,
  getColumns,
  columnSettings,
  onSubmit,
  onAddingId,
  teams = [],
  stages = [],
  handleUpdateTableRecords,
  ...others
}) {
  const teamMember = useSelector(getAuthTeamMember);
  const businessTimezone = useSelector(getBusinessTimeZone);

  const [creationFieldId, conflictFieldId] = useMemo(
    () => getCreationFieldIds(!!model, fields),
    [model, fields]
  );

  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();
  const [isUnarchiveForbidden, setIsUnarchiveForbidden] = useToggle(false);
  const [isUnarchiveConflict, setIsUnarchiveConflict] = useToggle(false);

  const [newRecord, setNewRecord] = useState(
    getDefaultNewRecord(model && ownerToOption(teamMember))
  );
  const readyRecord = useRef();
  const [autoFocusField, setAutoFocusField] = useState(() =>
    getNextField(
      {
        getColumns,
        columnSettings,
        fields,
        model,
        teams,
        businessTimezone,
        t,
        preReleaseFeatures,
      },
      creationFieldId
    )
  );

  const [showOverlay, setShowOverlay] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [uniqueNameError, setUniqueNameError] = useState('');

  const submittingRef = useRef(false);
  const clickAwayCount = useRef(0);
  const patchNew = useRef(null);
  const overlayTarget = useRef();
  const currentColumnRef = useRef();
  const rowRef = useRef();

  const currentName = model
    ? `${model?.objectName}: "${model?.entityName}"`
    : CONTACT;

  const hideMessage = () => {
    if (showMessage) {
      setTimeout(() => setShowMessage(false), 1500);
    } else {
      setShowOverlay(false);
    }
  };

  const onSubmitRecord = useCallback(
    async (id, patch, col, unarchive = UNARCHIVE_PROMPT) => {
      if (clickAwayCount.current >= 1 && col.id === conflictFieldId) {
        return false;
      }
      setShowOverlay(false);
      submittingRef.current = col.id;
      let nextRecord;
      const { fields: patchedFields = [], ...rest } = patch;
      let updatedData = {
        ...newRecord,
        ...rest,
        fields: newRecord.fields.map((field) => {
          const patchedField = patchedFields.find(({ id }) => field.id === id);
          return patchedField ? { ...field, value: patchedField.value } : field;
        }),
      };

      try {
        setNewRecord(updatedData);
        patchNew.current = patch;

        if (model) {
          const isPipelineType = isPipelineObject(model);

          if (!isPipelineType) {
            if (id === 'new') {
              nextRecord = await FieldService.createCustomModelRecord(
                {
                  model: model.id,
                  ...patch,
                  unarchive,
                },
                { skipErrorBoundary: true }
              );
            } else {
              nextRecord = await FieldService.patchCustomModelRecord(
                id,
                model.id,
                patch,
                { params: { return_all_fields: true } }
              );
            }
          } else if (id === 'new') {
            updatedData = {
              ...updatedData,
              stageId: updatedData.stageId || stages[0].value,
            };
            nextRecord = await PipelineService.createPipelineRecord(
              model.id,
              {
                ...updatedData,
                unarchive,
              },
              { skipErrorBoundary: true }
            );
          } else {
            nextRecord = await PipelineService.updatePipelineRecord(
              id,
              model.id,
              patch,
              { params: { return_all_fields: true } }
            );
          }
          nextRecord = {
            access: { view: true, edit: true, remove: true },
            ...nextRecord,
            fields: getFieldsForUI(nextRecord.fields),
            owner: nextRecord.owner ? ownerToOption(nextRecord.owner) : null,
            stage: nextRecord.stage
              ? {
                  value: nextRecord.stage.id,
                  label: nextRecord.stage.name,
                }
              : null,
          };
        } else {
          if (id === 'new') {
            const payload = {
              ...FieldService.getFormValues(dummyObject, fields),
              ...patch,
            };
            nextRecord = await FieldService.createObject(
              { for: 'contacts' },
              payload,
              fields,
              unarchive,
              { skipErrorBoundary: true }
            );
          } else {
            nextRecord = await FieldService.patchObject(
              { id, for: 'contacts', params: { return_all_fields: true } },
              patch,
              fields,
              unarchive
            );
          }
        }

        if (!nextRecord.access) {
          monitoringMessageHelper(
            'API response did not contain ACCESS value; assuming full permissions'
          );
        }

        const nextField = getNextField(
          {
            getColumns,
            columnSettings,
            fields,
            model,
            teams,
            t,
            nextRecord,
          },
          creationFieldId,
          col.id || creationFieldId
        );

        if (
          unarchive === UNARCHIVE ||
          (col && nextField === null && nextRecord[creationFieldId]) ||
          clickAwayCount.current > 1
        ) {
          return onSubmit(nextRecord, unarchive === UNARCHIVE);
        }

        if (currentColumnRef.current === col.id) {
          setAutoFocusField(nextField);
        }

        onAddingId(nextRecord.id);
        setNewRecord(nextRecord);
        readyRecord.current = nextRecord;
        clickAwayCount.current = 0;
        return true;
      } catch (error) {
        const orig = snakeToCamelCaseKeys(getOriginalError(error));
        if (orig?.code) {
          setAutoFocusField(null);
          if (orig.code === PERMISSION_DENIED) {
            setIsUnarchiveForbidden();
          }
          if (orig.code === UNARCHIVE_CONFLICT) {
            setIsUnarchiveConflict();
          }
        }
        if (orig?.nonFieldErrors) {
          setUniqueNameError(orig.nonFieldErrors);
          setTimeout(() => {
            setAutoFocusField(creationFieldId);
            setShowOverlay(true);
            setShowMessage(true);
          }, 2);
        }
        if (orig?.[conflictFieldId]) {
          setNewRecord(updatedData);
          setUniqueNameError(orig[conflictFieldId]);
          setTimeout(() => {
            setAutoFocusField(conflictFieldId);
            setShowOverlay(true);
            setShowMessage(true);
          }, 2);
        }
        return false;
      } finally {
        await new Promise((res) => setTimeout(res, 1));
        submittingRef.current = false;
      }
    },
    [
      fields,
      getColumns,
      creationFieldId,
      conflictFieldId,
      columnSettings,
      onAddingId,
      onSubmit,
      model,
      teams,
      newRecord,
      t,
      stages,
      setIsUnarchiveConflict,
      setIsUnarchiveForbidden,
    ]
  );

  const columns = useMemo(() => {
    const cols = getColumns({
      model,
      fields,
      columnSettings,
      onSubmitRecord,
      onSubmitContact: onSubmitRecord,
      teams,
      stages,
      businessTimezone,
      t,
      preReleaseFeatures,
      handleUpdateTableRecords,
    });
    const eventHandler = (col, ev) => {
      if (
        col.isRequired ||
        col.id === conflictFieldId ||
        col.id === creationFieldId
      ) {
        overlayTarget.current = ev.currentTarget;
        overlayTarget.current.errorMessage = col.errorMessage;
      } else if (submittingRef.current !== conflictFieldId) {
        overlayTarget.current = null;
      }
      if (autoFocusField !== col.id) setAutoFocusField(null);
      currentColumnRef.current = col.id;
    };

    cols.forEach((col) => {
      if (col.id !== 'columns' && (col.cell || col.bodyCell)) {
        if (col.id === 'selection') {
          col.cell = <EmptyCell />;
        } else if (col.cell) {
          col.cell = cloneElement(col.cell, {
            onFocus: (ev) => {
              eventHandler(col, ev);
            },
            onClick: (ev) => {
              clickAwayCount.current = 0;
              eventHandler(col, ev);
            },
            shouldFocusNext: true,
          });
        }
        if (col.bodyCell) {
          col.bodyCell.shouldFocusNext = true;
        }
        col.format = cloneFormattedElement(col.format, {
          emptyPlaceholder: '',
          isAdding: true,
          shouldFocusNext: true,
          autoFocus: autoFocusField === col.id,
          submitUnchanged: [
            conflictFieldId,
            creationFieldId,
            DEFAULT_FIELD_NAMES.owner,
            DEFAULT_FIELD_NAMES.stage,
          ].some((el) => col.id === el),
        });

        col.onFocus = (ev) => {
          eventHandler(col, ev);
        };
        col.onClick = (ev) => {
          clickAwayCount.current = 0;
          eventHandler(col, ev);
        };
        col.autoFocus = autoFocusField === col.id;
      }
    });
    return cols;
  }, [
    model,
    fields,
    columnSettings,
    autoFocusField,
    conflictFieldId,
    creationFieldId,
    onSubmitRecord,
    teams,
    stages,
    businessTimezone,
    t,
    getColumns,
    preReleaseFeatures,
    handleUpdateTableRecords,
  ]);
  useClickAway(
    rowRef,
    async (ev) => {
      if (!getAppRoot().contains(ev.target)) {
        return;
      }
      await new Promise((resolve) => setTimeout(resolve));
      clickAwayCount.current += 1;
      if (clickAwayCount.current > 1) {
        onSubmit(newRecord.id === 'new' ? null : readyRecord.current);
      }
    },
    ['mousedown']
  );

  const handleUnarchive = useCallback(
    async (unarchive) => {
      clickAwayCount.current = 0;
      await onSubmitRecord(
        'new',
        patchNew.current,
        { id: currentColumnRef.current },
        unarchive
      );
      setIsUnarchiveConflict();
    },
    [onSubmitRecord, setIsUnarchiveConflict]
  );

  const handleHide = () => {
    setIsUnarchiveConflict();
    setAutoFocusField(conflictFieldId);
  };

  const hideForbiddenModal = () => {
    setIsUnarchiveForbidden();
    setAutoFocusField(conflictFieldId);
  };

  const submitUnchangedList = useMemo(
    () => [
      conflictFieldId,
      creationFieldId,
      DEFAULT_FIELD_NAMES.owner,
      DEFAULT_FIELD_NAMES.stage,
      ...columns
        .filter(
          (col) =>
            col.bodyCell?.field?.fieldType === FIELD_TYPES.PhoneNumber.type
        )
        .map(({ id }) => id),
    ],
    [columns, conflictFieldId, creationFieldId]
  );

  return (
    <>
      <TRow
        ref={rowRef}
        columns={columns}
        data={newRecord}
        autoFocusField={autoFocusField}
        submitUnchangedList={submitUnchangedList}
        fixWidthStyle={{
          padding: PADDING_IN_PIXELS,
        }}
        {...others}
      />
      <Overlay
        transition={false}
        target={overlayTarget.current}
        show={showOverlay}
        placement="bottom-start"
      >
        <ErrorCard show={showMessage} onTransitionEnd={hideMessage}>
          <KizenTypography>
            {uniqueNameError || overlayTarget.current?.errorMessage}
          </KizenTypography>
        </ErrorCard>
      </Overlay>
      <UnarchiveForbiddenModal
        show={isUnarchiveForbidden}
        onConfirm={hideForbiddenModal}
        onHide={hideForbiddenModal}
        name={currentName}
      />
      <UnarchiveConflictModal
        show={isUnarchiveConflict}
        onConfirm={() => handleUnarchive(UNARCHIVE)}
        onAdditionalConfirm={() => handleUnarchive('overwrite')}
        onHide={handleHide}
        name={currentName}
      />
    </>
  );
}
