import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale, colorsButton, colorsText } from 'app/colors';
import { gutters } from 'app/spacing';
import { TextSpan, fontWeights, fontSizes } from 'app/typography';

const placeholderCommonStyles = css`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.00125em;
`;

export const StyledLabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
  outline: none;
`;

export const StyledLabel = styled(TextSpan)`
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

export const StyledFileInputWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 4px;
  padding: 0 ${gutters.standard};
  min-height: 36px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${escape(
    grayScale.medium
  )}' stroke-width='2' stroke-dasharray='4%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

  ${(props) =>
    props.error &&
    css`
      &:not(:hover) {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${escape(
          colorsButton.red.hover
        )}' stroke-width='2' stroke-dasharray='0' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}


  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${escape(
          colorsButton.blue.hover
        )}' stroke-width='2' stroke-dasharray='4%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
    `}
`;

export const StyledPlaceholder = styled.span`
  ${placeholderCommonStyles};

  margin: ${({ size }) => (size === 'small' ? '9px 0' : '10px 0')};
  color: ${colorsButton.blue.default};
  font-weight: 600;

  & > span {
    ${placeholderCommonStyles};

    color: ${grayScale.dark};

    input[disabled] ~ & {
      color: ${grayScale.medium};
      cursor: default;
    }
  }

  input[disabled] ~ & {
    cursor: default;
    color: ${grayScale.medium};
  }
`;

export const StyledFile = styled.span`
  margin: 10px 0 0;
  display: flex;
  max-width: 100%;

  &:first-of-type {
    margin-top: ${gutters.standard};
  }

  & > button {
    width: 12px;
    height: 12px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/images/remove-file.svg);
    background-color: transparent;
    overflow: hidden;
    text-indent: -999em;
    outline: none;
  }

  & > span {
    ${placeholderCommonStyles};

    color: ${({ created }) =>
      created ? grayScale.mediumDark : grayScale.medium};
    padding-left: ${gutters.standard};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// Underlined variant

export const StyledSmallLabel = styled(TextSpan)`
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.small};
  margin-bottom: -2px;
  margin-top: 4px;
`;

export const StyledUnderlinedFileInputWrapper = styled(StyledFileInputWrapper)`
  border-radius: 0px;
  background-image: url(/images/inputUnderline.svg);
  background-position: left bottom;
  background-repeat: repeat-x;
  padding-bottom: 2px;
  padding: 0;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-height: 30px;

  ${(props) =>
    props.error &&
    css`
      &:not(:hover) {
        background-image: url(/images/inputUnderline--error.svg);
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}


  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-image: url(/images/inputUnderline--hover.svg);
      }
    `}
`;
