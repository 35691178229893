import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { format, isMatch } from 'date-fns2';
import {
  borderRadii,
  gutters,
  scrollbarCss,
  useWindowSize,
} from '../../../app/spacing';
import { grayScale } from '../../../app/colors';
import useField from '../../../hooks/useField';
import DatePicker from '../../Kizen/DatePicker';
import StylePassthrough from '../../Kizen/StylePassthrough';
import DatePickerTextInput from '../TextInput/presets/DatePicker';
import IconAdornment from '../Adornments/IconAdornment';
import {
  MaybeInputControl,
  getNonInputControlProps,
  hasInputControl,
} from '../InputControl';
import Select from '../Select';
import {
  Menu as CustomMenu,
  Control as CustomControl,
} from '../Select/customize';
import { applyRef } from '../props';
import { getDataQAForInput } from '../helpers';

export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';
export const DATE_EMPTY_FORMAT = '--/--/--';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm';

export const MIN_DATE_LENGTH = 10;

export const getDateOption = (value) => {
  if (
    value &&
    typeof value === 'object' &&
    'date' in value &&
    'value' in value &&
    'label' in value
  ) {
    // It's already a Select-style option
    return value;
  }
  // we always use UTC for DateInput. Agreement with BE developers
  const date = value && new Date(value);
  if (!date) {
    return {
      date: null,
      value: '',
      label: '',
    };
  }
  return (
    date && {
      date,
      value: date.toISOString(),
      label: format(date, 'MM/dd/yyyy'),
    }
  );
};

export const Menu = styled(CustomMenu)`
  width: fit-content;
  overflow: hidden;
  && {
    border-top-left-radius: ${borderRadii.small};
    border-top-right-radius: ${borderRadii.small};
    border-bottom-left-radius: ${borderRadii.small};
    border-bottom-right-radius: ${borderRadii.small};
    border: 1px solid ${grayScale.medium};
    ${({ selectProps: { menuInline, rightPosition } }) => {
      if (rightPosition && menuInline) {
        return css`
          float: right;
        `;
      }
      if (rightPosition && !menuInline) {
        return css`
          right: 0;
        `;
      }
      return '';
    }}

    ${({ placement }) =>
      placement === 'top' &&
      css`
        margin-bottom: -1px;
      `}
    ${({ placement }) =>
      placement !== 'top' &&
      css`
        margin-top: -1px;
      `}
  }
  .rs-calendar {
    width: 270px;
    .rs-calendar-header {
      max-width: 100%;
    }
    // TODO These styles below may need to eventually be ported into the base date picker.
    // Just trying to not break other areas of the app for now :)
    padding-top: ${gutters.spacing(3, -8)}px;
    .rs-calendar-header {
      .rs-calendar-header-month-toolbar {
        padding: 0 ${gutters.spacing(3, -5)}px; // 5px of incidental padding next to the left/right buttons
      }
    }
    .rs-calendar-view {
      padding: ${gutters.spacing(4, -15)}px ${gutters.spacing(2, -2)}px
        ${gutters.spacing(1)}px;
    }
    .rs-calendar-table-cell {
      padding-bottom: 0;
    }
    .rs-calendar-table-cell-content {
      height: 30px;
      width: 30px;
    }
    .rs-calendar-month-dropdown-row-wrapper {
      ${scrollbarCss}
    }
  }
`;

const ControlStyles = styled(StylePassthrough)`
  ${({ selectProps: { variant } }) =>
    variant !== 'underline' &&
    css`
      && {
        border-top-left-radius: ${borderRadii.small};
        border-top-right-radius: ${borderRadii.small};
        border-bottom-left-radius: ${borderRadii.small};
        border-bottom-right-radius: ${borderRadii.small};
      }
    `}
`;

const Control = (props) => (
  <ControlStyles {...props}>
    <CustomControl {...props} isFocused={false} />
  </ControlStyles>
);

export const MenuList = ({ getValue, setValue }) => {
  const [value] = [].concat(getValue() || []);
  return (
    <DatePicker
      value={value && value.date}
      onSelect={(date) => setValue(getDateOption(date), 'set-value')}
      showClearButton={false}
      showApplyButton={false}
    />
  );
};

MenuList.propTypes = {
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

const DateInput = React.forwardRef((props, ref) => {
  const {
    onChange,
    variant,
    value,
    className,
    isDateTime,
    inModal,
    ...others
  } = props;
  const [rightPosition, setRightPosition] = useState(false);
  const [formattedValue, setFormattedValue] = useField(
    () => getDateOption(value),
    [value]
  );
  const [showMenu, setShowMenu] = useState(false);
  const [initValue, setInitValue] = useState(value || new Date());
  const windowSize = useWindowSize();
  const measuredRef = useCallback(
    (stateManager) => {
      if (stateManager && windowSize) {
        const parent = document.body.getBoundingClientRect();
        const child = stateManager.getBoundingClientRect();
        setRightPosition(child.left - parent.left > parent.right - child.right);
      }
      applyRef(ref, stateManager);
    },
    [ref, windowSize]
  );

  const handleInputChange = (v) => {
    setShowMenu(false);
    setFormattedValue((prev) => ({ ...prev, label: v }));
  };

  const handleOnBlur = () => {
    const isValidDate =
      formattedValue?.label.length === MIN_DATE_LENGTH &&
      isMatch(formattedValue?.label, 'MM/dd/yyyy');
    setShowMenu(false);
    if (!isValidDate) {
      setFormattedValue(getDateOption(initValue));
      return;
    }
    setInitValue(formattedValue.label);
    setFormattedValue(getDateOption(formattedValue.label));
    onChange(new Date(formattedValue.label));
  };

  const selectProps = getNonInputControlProps(props);
  const isShowMenu = selectProps.menuOnly || showMenu;

  return (
    <MaybeInputControl
      forInput
      {...props}
      {...getDataQAForInput(props.field?.id, props.field?.fieldType)}
    >
      <div ref={measuredRef}>
        {!selectProps.menuOnly && (
          <DatePickerTextInput
            onBlur={handleOnBlur}
            value={formattedValue && formattedValue.label}
            onChange={handleInputChange}
            onClick={() => setShowMenu(true)}
            placeholder={others.placeholder || others.label}
            variant={variant}
            error={selectProps.error}
            validate={selectProps.validate}
            {...getDataQAForInput('date-input', selectProps.field?.fieldType)}
          />
        )}
        {isShowMenu && (
          <Select
            isSearchable={false}
            menuInline={false}
            endAdornment={<IconAdornment icon="calendar" />}
            {...selectProps}
            handleClose={() => setShowMenu(false)}
            isDateTime={isDateTime}
            rightPosition={rightPosition}
            className={!hasInputControl(props) && className}
            components={{ Control, Menu, MenuList, ...selectProps.components }}
            value={formattedValue}
            menuOnly
            onChange={(opt) => {
              setFormattedValue(getDateOption(opt));
              setShowMenu(false);
              setInitValue(opt && opt.label);
              if (props.onChange) props.onChange(opt && opt.date);
            }}
          />
        )}
      </div>
    </MaybeInputControl>
  );
});

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isDateTime: PropTypes.bool,
  variant: PropTypes.string,
  inModal: PropTypes.bool,
};

DateInput.defaultProps = {
  value: null,
  onChange: null,
  placeholder: DATE_DISPLAY_FORMAT,
  isDateTime: false,
  variant: 'underline',
  inModal: false,
};

export default DateInput;
