const snakeToCamelCase = (str) => {
  return str && str.replace(/_./g, ([, ch]) => ch.toUpperCase());
};

export const snakeToCamelCaseKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(snakeToCamelCaseKeys);
  }
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  return Object.entries(obj).reduce(
    (collect, [key, value]) => ({
      ...collect,
      [snakeToCamelCase(key)]: snakeToCamelCaseKeys(value),
    }),
    {}
  );
};
