import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TopLayerSelect } from 'components/PageBuilder/components/TopLayerSelect';

export const SENDER_TYPE = {
  TEAM_MEMBER: 'specific_team_member',
  TEAM_MEMBER_FIELD: 'team_member_field',
  LAST_ROLE: 'last_team_member_role',
  LAST_ANY: 'last_team_member',
  BUSINESS: 'business',
  ENTITY_RECORD_OWNER: 'entity_record_owner',
  SPECIFIC_INTEGRATED_INBOX: 'specific_integrated_inbox',
};

export const SenderType = React.memo(
  ({ businessName, modalLayer, ...props }) => {
    const { t } = useTranslation();
    const options = useMemo(
      () => [
        { value: SENDER_TYPE.TEAM_MEMBER, label: t('Specific Team Member') },
        {
          value: SENDER_TYPE.TEAM_MEMBER_FIELD,
          label: t('Custom Team Selector Field'),
        },
        {
          value: SENDER_TYPE.LAST_ROLE,
          label: t('Last Team Member (Specific Role)'),
        },
        {
          value: SENDER_TYPE.LAST_ANY,
          label: t('Last Team Member (Any Role)'),
        },
        {
          value: SENDER_TYPE.BUSINESS,
          label: businessName,
        },
        {
          value: SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX,
          label: t('Integrated Inbox'),
        },
      ],
      [t, businessName]
    );

    return (
      <TopLayerSelect
        variant="underline"
        label={t('Sender Type')}
        options={options}
        modalLayer={modalLayer}
        {...props}
      />
    );
  }
);

SenderType.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  businessName: PropTypes.string.isRequired,
};
