import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Icon from 'components/Kizen/Icon';
import { TextSpan } from 'app/typography';
import { grayScale, colorsButton, colorsText, colorsPrimary } from 'app/colors';
import { gutters, layers } from 'app/spacing';
import Switch from '../Switch';

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 1px solid ${grayScale.medium};
  border-radius: 4px;

  :hover,
  :focus-within {
    cursor: pointer;
    border-color: ${colorsButton.blue.hover};
  }

  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
      border-color: ${colorsButton.blue.hover};
    `}

  ${(props) =>
    props.checked &&
    css`
      border: 1px solid ${colorsPrimary.blue.dark};
    `}

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${colorsButton.red.hover};
    `}
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  z-index: ${layers.content(0)};
`;

export const StyledIcon = styled(Icon)`
  color: ${colorsText.medium};

  .fa-2x {
    font-size: 24px;
  }

  ${(props) =>
    props.checked &&
    css`
      color: ${colorsPrimary.blue.dark};

      &:hover {
        color: ${colorsPrimary.blue.dark};
      }
    `}
`;

export const StyledText = styled(TextSpan)`
  margin-top: ${gutters.standard};
  font-size: 14px;
  color: ${colorsText.medium};
  text-align: center;

  ${(props) =>
    props.checked &&
    css`
      color: ${colorsPrimary.blue.dark};
    `}
`;

export const StyledSwitch = styled(Switch)`
  margin: 8px 0 0 0;
`;
