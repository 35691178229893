import styled from '@emotion/styled';
import { colorsButton, grayScale } from 'app/colors';
import KizenTypography from 'app/kizentypo';
import { breakpoints, spaceBetweenGutters } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import Icon from 'components/Kizen/Icon';
import EditableSelect from 'components/Kizen/EditableSelect';
import Section from '../../section';
import css from '@emotion/css';
import { TextSpan } from 'app/typography';

export const StyledSection = styled(Section)`
  margin-top: 17px;
  margin-left: -2px;
  margin-bottom: 24px;
  ${({ margin }) => css`
    margin: ${margin}px 0;
  `}
  min-width: 0;
  p {
    margin-bottom: unset;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const EditableSelectValueOnly = styled(EditableSelect)`
  margin-left: 5px;
  margin-right: 19px;
  width: 90px;
  .Select__control {
    background: none;
    &:hover {
      background: none;
    }
  }
  .Select__single-value {
    margin-left: 14px;
  }
  &.shifted {
    margin-left: 7px;
    margin-right: 18px;
    .Select__single-value {
      margin-left: 12px;
    }
  }
  .Select__dropdown-indicator {
    color: ${grayScale.mediumDark};
    padding-left: 0;
  }
  .Select__menu {
    margin-bottom: 0;
    border-radius: 8px;
    border: 1px solid ${grayScale.medium};
  }
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SmallIcon = styled(Icon)`
  font-size: 14px;
  > * {
    font-size: inherit;
  }
`;

export const SettingsFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ margin }) =>
    margin &&
    css`
      margin-bottom: 21px;
    `}
  > * {
    width: 100%;
    @media (min-width: ${breakpoints.lg}px) {
      &:nth-child(2),
      &:nth-child(3) {
        width: ${spaceBetweenGutters({ columns: 2, gutter: '20px' })};
      }
    }
  }
`;

export const StyledLoader = styled(Loader)`
  padding: 0 !important;
`;

export const StyledAddNotificationButton = styled(KizenTypography)`
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  color: ${colorsButton.green.default};
  :hover {
    color: ${colorsButton.green.hover};
  }
  :active {
    color: ${colorsButton.green.hover};
  }
`;

export const NotificationWrapper = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${grayScale.medium};
  border-radius: 8px;
  padding: 6px 14px;
  margin-bottom: 15px;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
`;

export const StyledTextSpan = styled(TextSpan)`
  line-height: 28px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;
