import { useMemo } from 'react';
import { useCurrentLayout } from './useCurrentLayout';
import { useBreakpoint } from 'app/spacing';

interface ObjectModel {
  recordLayouts: any[];
}

interface PageConfig {
  selectedLayout: string;
}

export const usePartialLayoutControl = (
  objectModel: ObjectModel,
  pageConfig: PageConfig
) => {
  const isMobile = useBreakpoint(839); // This value matches when the KDS grid breaks to the mobile layout

  const selectedLayout = useCurrentLayout(
    objectModel?.recordLayouts ?? [],
    pageConfig?.selectedLayout
  );

  const selectedLayoutOption = useMemo(() => {
    if (!selectedLayout) {
      return undefined;
    }

    return {
      value: selectedLayout?.id,
      label: selectedLayout?.name,
    };
  }, [selectedLayout]);

  const layoutOptions = useMemo(() => {
    return (
      objectModel?.recordLayouts?.map((item) => ({
        value: item.id,
        label: item.name,
      })) ?? []
    );
  }, [objectModel]);

  return {
    layoutOptions: isMobile ? [] : layoutOptions,
    selectedLayoutOption,
  };
};
