import EmailAddressInput from '__components/Inputs/TextInput/presets/EmailAddress';
import { InputProps } from './types';

export const EmailAddress = ({
  step,
  value,
  placeholder,
  width,
  error,
  onChange,
}: InputProps) => (
  <EmailAddressInput
    value={value}
    width={width}
    placeholder={placeholder}
    error={error}
    onChange={(val: string) => onChange(step, val)}
  />
);
