import { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { colorsButton, grayScale } from 'app/colors';
import { borderRadii, breakpoints, layers } from 'app/spacing';
import { fontSizes, fontWeights, textCss, TextSpan } from 'app/typography';
import { components } from 'react-select';
import BaseInput from '../TextInput/BaseInput';
import {
  Control as BaseControl,
  DropdownIndicator as BaseDropdownIndicator,
  Menu as BaseMenu,
} from '../Select/customize';
import { desktopWidth, mobileWidth } from '../InputControl';
import useWidthMeasurement from 'hooks/useWidthMeasurement';

const EXPANDED_WIDTH = 222; // This is the maximum possible width, including the dropdown controls

export const ControlStyles = styled.div`
  && {
    ${({ expand }) =>
      expand
        ? css`
            width: ${EXPANDED_WIDTH}px;
          `
        : ''}
    border-color: transparent;
    ${({ focused }) =>
      focused &&
      css`
        border-color: ${colorsButton.blue.hover};
      `}
    padding-right: 0;
    z-index: ${layers.content(
      0,
      2
    )}; // Above menu, which has z-index of 1, in order to show overlapping borders
    & > div:first-of-type {
      max-width: 200px;
      margin-left: 0;
      & > div {
        // This is the input wrapper and single value (if present).
        // TODO drilling into the elements like this isn't ideal, but
        // react-select does not provide a reliable way to target the input wrapper
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
`;

export const Control = (props) => {
  const { isFocused } = props;
  const { expandOnFocus, menuIsOpen } = props.selectProps;
  const { ref, width } = useWidthMeasurement();

  // If the input field is focused, we want the input field to expand.
  // However, if the field is being shrunk becuase of the screen size
  // or the title text being long, we don't want it to expand if it's only
  // going to change by a few pixels. This ensures that if it expands, it's by
  // at least 50 pixels so it's clear it's intentional.
  const expansionDifference = EXPANDED_WIDTH - width;

  const shouldExpand =
    width && (width === EXPANDED_WIDTH || expansionDifference > 50);

  return (
    <div ref={ref}>
      <ControlStyles
        focused={isFocused}
        as={StylePassthrough}
        expand={expandOnFocus && menuIsOpen && shouldExpand}
      >
        <BaseControl {...props} />
      </ControlStyles>
    </div>
  );
};

Control.propTypes = {
  isFocused: PropTypes.bool.isRequired,
};

export const DropdownIndicatorStyles = styled.div`
  && {
    color: ${colorsButton.blue.default};
    svg {
      width: 12px;
    }
  }
`;

export const DropdownIndicator = (props) => (
  <DropdownIndicatorStyles as={StylePassthrough}>
    <BaseDropdownIndicator {...props} />
  </DropdownIndicatorStyles>
);

const InputStyles = styled(BaseInput)`
  width: 100%; // This allows us to measure the input's desired width before typing
  ${textCss(fontSizes.subheader, fontWeights.bold)}
`;

// eslint-disable-next-line react/prop-types
export const Input = ({ value, onChange, ...props }) => {
  const [width, setWidth] = useState(null);
  const handleChange = useCallback(
    (ev) => {
      if (onChange) onChange(ev);
      if (!value && ev.target.value) {
        // We use getBoundingClientRect() rather than offsetWidth
        // because if we don't capture fractional sizing, there may be
        // jitter when the user starts typing.
        const rect = ev.target.parentElement.getBoundingClientRect();
        setWidth(rect.width);
      }
    },
    [onChange, value]
  );
  return (
    <InputStyles as={StylePassthrough}>
      <components.Input
        style={value ? { width } : null}
        value={value}
        onChange={handleChange}
        {...props}
      />
    </InputStyles>
  );
};

const SingleValueStyles = styled(TextSpan)`
  max-width: 100%;
  position: relative;
  & + div {
    position: absolute;
    width: 100%; // This allows us to measure the input's desired width before typing
  }
  top: unset;
  transform: none;
  ${textCss(fontSizes.subheader, fontWeights.bold)}
`;

export const SingleValue = (props) => {
  return (
    <SingleValueStyles as={StylePassthrough}>
      <components.SingleValue {...props} />
    </SingleValueStyles>
  );
};

const MenuStyles = styled.div`
  width: auto;
  min-width: 100%;
  max-width: ${mobileWidth}px;
  @media (min-width: ${breakpoints.sm}px) {
    max-width: ${desktopWidth}px;
  }
  margin-top: -1px; // Overlap with Select's underline
  border-top: 1px solid ${grayScale.medium};
  ${({ topRightRadius }) =>
    topRightRadius &&
    css`
      border-top-right-radius: ${borderRadii.small};
    `}
`;

export const Menu = ({ innerRef, ...props }) => {
  const [widerThanContainer, setWiderThanContainer] = useState(false);
  const ref = useCallback(
    (el) => {
      if (innerRef) innerRef(el);
      if (el) {
        setWiderThanContainer(el.offsetWidth > el.parentElement.offsetWidth);
      }
    },
    [innerRef]
  );
  return (
    <MenuStyles topRightRadius={widerThanContainer} as={StylePassthrough}>
      <BaseMenu {...props} innerRef={ref} />
    </MenuStyles>
  );
};

Menu.propTypes = {
  innerRef: PropTypes.func.isRequired,
};
