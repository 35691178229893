import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { FormFieldProps } from '../../types';
import { getTypeInfo, NodeType } from '../types';
import {
  ElementContainer,
  DEFAULT_ELEMENT_CONTAINER_PROPS,
} from './ElementContainer';

type FieldContainerProps = FormFieldProps & {
  children: ReactNode;
  draggingType?: NodeType;
  pointerEvents: boolean;
};

export const DEFAULT_FIELD_CONTAINER_PROPS = DEFAULT_ELEMENT_CONTAINER_PROPS;

const FieldWrapper = styled(ElementContainer)<FieldContainerProps>`
  ${({ pointerEvents }) =>
    !pointerEvents &&
    // TODO: this doesn't work - existing issue in stage
    css`
      > * {
        pointer-events: none;
      }
    `}
`;

export const FieldContainer = forwardRef(
  (
    {
      children,
      draggingType,
      pointerEvents = true,
      ...rest
    }: FieldContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <FieldWrapper
      ref={ref}
      ignoreChildrenDrag={getTypeInfo(draggingType)?.isContent}
      pointerEvents={pointerEvents}
      {...rest}
    >
      {children}
    </FieldWrapper>
  )
);
