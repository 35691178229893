import { getOptionValue, namedToOptionDeleted } from 'services/helpers';

import {
  setDelayAmount,
  checkDeleted,
  nameDescriptor,
} from 'pages/AutomationEngine/helpers';

import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { formId, delayAmount } = orgDetails;
  return {
    stepGoal: {
      ...orgDetails,
      delayAmount: setDelayAmount(delayAmount),
      triggers: [
        {
          type: stepConfig.formSubmitted.type,
          triggerFormSubmitted: {
            formId: getOptionValue(formId),
          },
        },
      ],
    },
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { form } = firstTrigger.triggerFormSubmitted;

  const formId = namedToOptionDeleted(form);

  const type = stepConfig.formSubmittedGoal.type;

  checkDeleted(messageDictionary, addErrorMessage, form, nameDescriptor);

  return { formId, type };
};

export const formSubmittedGoalDTO = {
  forApi,
  forFlow,
};
