import { forwardRef } from 'react';
// import { useTranslation } from 'react-i18next';

import TextInputInline from 'components/Inputs/inline/TextInput';
import { withCellWrapper } from './helpers';

const _TextCell = forwardRef(
  (
    {
      field,
      object,
      onSubmit,
      value,
      objectId,
      serviceToUse,
      fetchUrl,
      handleUpdateFieldOption,
      handleUpdateTableRecords,
      menuLeftButton,
      menuRightButton,
      placeholder,
      info,
      showDisplayNameTooltip,
      column,
      readOnly,
      onAutoFocus,
      ...others
    },
    ref
  ) => {
    return (
      <TextInputInline
        field={field}
        object={object}
        ref={ref}
        {...others}
        value={value}
        onSubmit={onSubmit}
        readOnly={readOnly}
        onAutoFocus={onAutoFocus}
      />
    );
  }
);

export const TextCell = withCellWrapper(_TextCell);
