import { usePermissionsContext } from './context';
import { useReactive } from './useReactive';
import type { PermissionResultData } from '../src/types';

const missingPermissionError = (key: string) => `
No permission was found in the context for ${key}. The component consuming this hook is 
being rendered before the necessary permission has been created. See state-ops.ts in 
@kizen/permissions for helpers that add permissions to sections.
`;

export const usePermissionValue = <T = never>(
  key: string,
  mapper?: (x: PermissionResultData) => T
) => {
  const ctx = usePermissionsContext();

  if (!ctx.permissions.has(key)) {
    throw Error(missingPermissionError(key));
  }

  return useReactive(ctx.permissions.get(key)!.value, mapper);
};
