import { Dispatch, useCallback, useMemo, useState } from 'react';
import { LayoutState } from './types';
import { useQuery } from 'react-query';
import { CUSTOM_OBJECTS } from 'queries/query-keys';
import CustomObjectService from 'services/CustomObjectsService';
import Loader from '__components/Kizen/Loader';
import { buildSharingSettings } from './util';
import { ActiveHandler } from './ActiveHandler';

interface RecordLayoutBuilderProps {
  oldData?: any;
  objectId: string;
  setStagedLayoutChanges: (...args: any[]) => any;
  stagedLayoutChanges: any;
  handleSaveLayoutChanges: () => Promise<any>;
  layoutErrors: any;
  setLayoutErrors: Dispatch<any>;
  secondarySettingsContainer: any;
  categorizedFields: any;
  isClient: boolean;
}

export const RecordLayoutBuilder = ({
  oldData,
  objectId,
  setStagedLayoutChanges,
  stagedLayoutChanges,
  handleSaveLayoutChanges,
  layoutErrors,
  setLayoutErrors,
  categorizedFields,
  secondarySettingsContainer,
  isClient,
}: RecordLayoutBuilderProps) => {
  const canUseRelatedPipelinesBlock: boolean = useMemo(() => {
    return (
      oldData?.relatedObjects?.some((o: any) => o.objectType === 'pipeline') ??
      false
    );
  }, [oldData]);

  const layoutsQueryKey = CUSTOM_OBJECTS.LIST_LAYOUTS(objectId);
  const [revision, setRevision] = useState(0);
  const [autoSelectFirstItem, setAutoSelectFirstItem] = useState(false);
  const isDirty = Boolean(stagedLayoutChanges);

  const { data, isLoading, refetch } = useQuery({
    queryKey: layoutsQueryKey,
    enabled: Boolean(objectId),
    queryFn: () => CustomObjectService.listRecordLayouts(objectId),
  });

  const layouts = useMemo(() => {
    if (!isLoading && data?.length) {
      return data.map((d: any) => {
        return {
          id: d.id,
          name: d.name,
          layout: d.config,
          active: d.active,
          tabs: d.tabs,
          sharingSettings: d.layout_sharing_settings,
        };
      });
    }

    return [];
  }, [data, isLoading]);

  const handleChange = useCallback(
    (
      id: string,
      name?: string,
      layout?: LayoutState,
      showAutomationsTab?: boolean,
      permissions?: any,
      active?: boolean,
      showMessagesTab?: boolean
    ) => {
      setLayoutErrors(undefined);
      setStagedLayoutChanges((prev: any) => {
        const result = {
          ...prev,
          id,
        };

        if (typeof name === 'string') {
          result.name = name;
        }

        if (layout) {
          result.layout = layout;
        }

        if (typeof showAutomationsTab === 'boolean') {
          result.tabs = {
            ...result.tabs,
            automations: showAutomationsTab,
          };
        }

        if (typeof showMessagesTab === 'boolean') {
          result.tabs = {
            ...result.tabs,
            messages: showMessagesTab,
          };
        }

        if (permissions) {
          result.layout_sharing_settings = buildSharingSettings(permissions);
        }

        if (typeof active === 'boolean') {
          result.active = active;
        }

        return result;
      });
    },
    [setStagedLayoutChanges, setLayoutErrors]
  );

  const clearStagedChanges = useCallback(() => {
    setLayoutErrors(undefined);
    setStagedLayoutChanges(undefined);
  }, [setStagedLayoutChanges, setLayoutErrors]);

  if (isLoading) {
    return <Loader loading />;
  }

  return (
    <ActiveHandler
      layouts={layouts}
      formData={oldData}
      handleChange={handleChange}
      revision={revision}
      setRevision={setRevision}
      objectId={objectId}
      refetchLayouts={refetch}
      autoSelectFirstItem={autoSelectFirstItem}
      setAutoSelectFirstItem={setAutoSelectFirstItem}
      clearStagedChanges={clearStagedChanges}
      dirty={isDirty}
      handleSaveLayoutChanges={handleSaveLayoutChanges}
      layoutErrors={layoutErrors}
      setLayoutErrors={setLayoutErrors}
      canUseRelatedPipelinesBlock={canUseRelatedPipelinesBlock}
      categorizedFields={categorizedFields}
      secondarySettingsContainer={secondarySettingsContainer}
      isClient={isClient}
    />
  );
};
