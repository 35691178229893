import React, { useCallback, useState } from 'react';

import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import LoadingButton from 'components/Button/LoadingButton';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../index';
import { gutters } from 'app/spacing';

const WrapperButton = styled.div`
  padding-right: ${gutters.standard};
`;

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
export default function BasicModal({
  size,
  heading,
  buttonText: buttonTextProp,
  disabled,
  onHide,
  onConfirm,
  children,
  typeOfContent,
  leftBtn,
  showBorder,
  defaultLeftBtnText: defaultLeftBtnTextProp,
  defaultLeftBtnHandler,
  leftBtnAlign,
  actionBtnColor,
  actionButton,
  showLoadingIndicator,
  modalBodyProps,
  additionalButtonText,
  onAdditionalConfirm,
  additionalButtonColor,
  hideFooter = false,
  rightBtn,
  rightBtnClickHandler = null,
  animateSizeChange = false,
  justifyFooterContent = null,
  ...others
}) {
  const { t } = useTranslation();

  const buttonText = buttonTextProp || t('Save');
  const defaultLeftBtnText = defaultLeftBtnTextProp || t('Cancel');

  const [submitting, setSubmitting] = useState(false);
  const handleClick = async () => {
    try {
      setSubmitting(true);
      await onConfirm();
    } finally {
      setSubmitting(false);
    }
  };

  const handleBtnClick = useCallback(async () => {
    try {
      setSubmitting(true);
      await onAdditionalConfirm();
    } finally {
      setSubmitting(false);
    }
  }, [onAdditionalConfirm]);

  return (
    <Modal
      size={size}
      onHide={onHide}
      {...others}
      data-qa-modal-header={heading}
      animateSizeChange={animateSizeChange}
    >
      <ModalHeader onClickClose={onHide}>{heading}</ModalHeader>
      <ModalBody typeOfContent={typeOfContent} {...(modalBodyProps ?? {})}>
        {typeof children !== 'string' && children}
        {typeof children === 'string' && (
          <KizenTypography>{children}</KizenTypography>
        )}
      </ModalBody>
      {!hideFooter ? (
        <ModalFooter
          showBorder={showBorder}
          actionBtnWithoutSpace={leftBtn && leftBtn.props.variant === 'text'}
          leftBtnAlign={leftBtnAlign}
          justifyContent={justifyFooterContent}
        >
          {leftBtn &&
            React.cloneElement(leftBtn, {
              children: defaultLeftBtnText,
              onClick: defaultLeftBtnHandler || onHide,
            })}
          {additionalButtonText && (
            <WrapperButton>
              <LoadingButton
                onClick={() => handleBtnClick(true)}
                disabled={disabled || submitting}
                loading={showLoadingIndicator && submitting}
                color={additionalButtonColor}
              >
                {additionalButtonText}
              </LoadingButton>
            </WrapperButton>
          )}
          <ConditionalWrapper
            condition={justifyFooterContent === 'space-between'}
            wrapper={(children) => <div>{children}</div>}
          >
            {rightBtn &&
              React.cloneElement(rightBtn, {
                onClick: rightBtnClickHandler || handleClick,
              })}
            {actionButton && (
              <LoadingButton
                onClick={handleClick}
                disabled={disabled || submitting}
                loading={showLoadingIndicator && submitting}
                color={actionBtnColor}
              >
                {buttonText}
              </LoadingButton>
            )}
          </ConditionalWrapper>
        </ModalFooter>
      ) : null}
    </Modal>
  );
}

BasicModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  heading: PropTypes.node.isRequired,
  buttonText: PropTypes.node,
  disabled: PropTypes.bool,
  typeOfContent: PropTypes.oneOf(['wizard', 'text', 'default']),
  leftBtn: PropTypes.node,
  showBorder: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  defaultLeftBtnText: PropTypes.string,
  defaultLeftBtnHandler: PropTypes.func,
  leftBtnAlign: PropTypes.oneOf(['right', 'left']),
  actionBtnColor: PropTypes.oneOf(['green', 'red', 'blue']),
  actionButton: PropTypes.bool,
  showLoadingIndicator: PropTypes.bool,
  additionalButtonText: PropTypes.string,
  onAdditionalConfirm: PropTypes.func,
  additionalButtonColor: PropTypes.oneOf(['green', 'red', 'blue']),
  animateSizeChange: PropTypes.bool,
};

BasicModal.defaultProps = {
  buttonText: null,
  disabled: false,
  typeOfContent: 'default',
  leftBtn: <Button variant="text" color="blue" />,
  showBorder: false,
  size: 'small',
  defaultLeftBtnText: null,
  defaultLeftBtnHandler: null,
  leftBtnAlign: 'right',
  actionBtnColor: 'green',
  actionButton: true,
  showLoadingIndicator: false,
  additionalButtonText: null,
};
