import type { FilterMetadata } from '@kizen/filters/types';

const getConditions = (t: (x: string) => string) => ({
  contains: {
    value: 'contains',
    label: t('Contains'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', 'is_any_of']],
  },
  does_not_contain: {
    value: 'does_not_contain',
    label: t('Does Not Contain'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', 'is_none_of']],
  },
  equals: {
    value: 'equals',
    label: t('Equals'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', '=']],
  },
  does_not_equal: {
    value: 'does_not_equal',
    label: t('Does Not Equal'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', '!=']],
  },
  has: {
    value: 'has',
    label: t('Has Value'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', 'has_any']],
  },
  has_not: {
    value: '!has',
    label: t("Doesn't Have Value"),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', '!has_any']],
  },
  has_any: {
    value: 'has_any',
    label: t('Contains Any Of'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', 'has_any']],
  },
  has_not_any: {
    value: '!has_any',
    label: t('Contains None Of'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', '!has_any']],
  },
  is_any_of: {
    value: 'is_any_of',
    label: t('Contains Any Of'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', 'is_any_of']],
  },
  is_none_of: {
    value: 'is_none_of',
    label: t('Contains None Of'),
    next: [{ step: 'value', unset: true }],
    ops: [['condition', 'is_none_of']],
  },
  is_blank: {
    value: 'is_blank',
    label: t('Is Blank'),
    next: ['__END__'],
    ops: [
      ['condition', 'is_blank'],
      ['value', true],
    ],
  },
  is_not_blank: {
    value: 'is_not_blank',
    label: t("Isn't Blank"),
    next: ['__END__'],
    ops: [
      ['condition', 'is_blank'],
      ['value', false],
    ],
  },
});

export const getMetadata = (t = (x: string) => x): FilterMetadata => {
  const conditions = getConditions(t);

  return {
    initial_steps: [['field_id']],
    order: {
      field_id: 1,
      condition: 2,
      value: 3,
    },
    steps: {
      field_id: {
        input_type: 'choices',
        placeholder: t('Choose Field'),
        width: 250,
        url: ['xf_join', '/activities/', '$activity_id', '/fields'],
        method: 'GET',
        option_paths: {
          value: [],
          label: ['display_name'],
        },
        value_id_path: ['id'],
        option_filter: [
          ['xf_eq', ['is_hidden'], false],
          [
            'xf_or',
            [
              ['xf_eq', ['field_type'], 'dynamictags'],
              ['xf_eq', ['field_type'], 'dropdown'],
              ['xf_eq', ['field_type'], 'relationship'],
              ['xf_eq', ['custom_object_field', 'field_type'], 'dynamictags'],
              ['xf_eq', ['custom_object_field', 'field_type'], 'dropdown'],
              ['xf_eq', ['custom_object_field', 'field_type'], 'relationship'],
            ],
          ],
        ],
        next: [{ step: 'condition', unset: true }, '__END__'],
        ops: [
          ['type', 'activity_field'],
          ['activity_field_id', ['$field_id', 'id']],
        ],
        save_value: {
          url: [
            'xf_join',
            '/activities/',
            '$activity_id',
            '/fields/',
            ['$field_id', 'id'],
          ],
          method: 'GET',
          error_response: {
            field_type: [
              ['$field_id', 'custom_object_field', 'field_type'],
              ['$field_id', 'field_type'],
            ],
          },
          error_message: {
            404: t('The field has been deleted'),
          },
        },
      },
      condition: {
        input_type: 'choices',
        placeholder: t('Choose Condition'),
        width: 180,
        options: ['@__options_lookup', '@__field_type'],
        __field_type: [
          '$field_id',
          [['custom_object_field', 'field_type'], ['field_type']],
        ],
        __options_lookup: {
          dropdown: [
            conditions.equals,
            conditions.does_not_equal,
            conditions.is_blank,
            conditions.is_not_blank,
          ],
          dynamictags: [
            conditions.has,
            conditions.has_not,
            conditions.has_any,
            conditions.has_not_any,
            conditions.is_blank,
            conditions.is_not_blank,
          ],
          relationship: [
            conditions.contains,
            conditions.does_not_contain,
            conditions.is_any_of,
            conditions.is_none_of,
            conditions.is_blank,
            conditions.is_not_blank,
          ],
        },
      },
      value: {
        input_type: [['@__input_types', '$condition'], 'choices'],
        width: [['@__widths', '@__field_type', '$condition'], 160],
        paginated: ['xf_eq', '@__field_type', 'relationship'],
        placeholder: [
          ['@__placeholders', '@__field_type', '$condition'],
          ['@__placeholders', '@__field_type'],
        ],
        value_id_path: ['id'],
        options: ['@__options_lookup', '@__field_type', 'options'],
        option_paths: [
          ['@__options_lookup', '@__field_type', 'paths'],
          {
            value: [],
            label: ['name'],
          },
        ],
        url: ['@__url_lookup', '@__field_type', 'url'],
        method: ['@__url_lookup', '@__field_type', 'method'],
        params: ['@__url_lookup', '@__field_type', 'params'],
        result_path: ['@__url_lookup', '@__field_type', 'result_path'],
        ops: [
          ['@__ops_lookup', '$condition'],
          [['value', ['xf_pick', '$value', ['id']]]],
        ],
        save_value: [
          ['@__save_value_lookup', '@__field_type', '@input_type'],
          ['@__save_value_lookup', ['$field_id', 'name']],
          ['@__save_value_lookup', '@__field_type'],
        ],
        __field_type: [
          '$field_id',
          [['custom_object_field', 'field_type'], ['field_type']],
        ],
        __input_types: {
          has_any: 'multiselect',
          '!has_any': 'multiselect',
          is_any_of: 'multiselect',
          is_none_of: 'multiselect',
        },
        __widths: {
          dynamictags: {
            has: 250,
            '!has': 250,
            has_any: 300,
            '!has_any': 300,
          },
          relationship: {
            contains: 250,
            does_not_contain: 250,
            is_any_of: 300,
            is_none_of: 300,
          },
        },
        __placeholders: {
          dropdown: t('Choose Option'),
          dynamictags: {
            has: t('Find Option'),
            '!has': t('Find Option'),
            has_any: t('Find Options'),
            '!has_any': t('Find Options'),
          },
          relationship: {
            contains: t('Find Record'),
            does_not_contain: t('Find Record'),
            is_any_of: t('Find Records'),
            is_none_of: t('Find Records'),
          },
        },
        __url_lookup: {
          dynamictags: {
            url: [
              'xf_join',
              '/activities/',
              '$activity_id',
              '/fields/',
              ['$field_id', 'id'],
              '/options',
            ],
            method: 'GET',
            params: {
              ordering: 'name',
              page_size: 10000,
            },
          },
          relationship: {
            url: [
              'xf_join',
              '/records/',
              ['$field_id', 'relation', 'related_object'],
              '/typeahead-search',
            ],
            method: 'GET',
            params: {
              page_size: 100,
            },
            result_path: ['results'],
          },
        },
        __options_lookup: {
          dropdown: {
            options: ['$field_id', 'options'],
            paths: {
              value: [],
              label: ['name'],
              order: ['order'],
            },
          },
          relationship: {
            paths: {
              value: [],
              label: ['display_name'],
            },
          },
        },
        __ops_lookup: {
          contains: [['value', ['xf_concat', ['xf_pick', '$value', ['id']]]]],
          does_not_contain: [
            ['value', ['xf_concat', ['xf_pick', '$value', ['id']]]],
          ],
          has: [['value', ['xf_concat', ['xf_pick', '$value', ['id']]]]],
          '!has': [['value', ['xf_concat', ['xf_pick', '$value', ['id']]]]],
          has_any: [['value', ['xf_map', '$value', ['xf_pick', '$', ['id']]]]],
          '!has_any': [
            ['value', ['xf_map', '$value', ['xf_pick', '$', ['id']]]],
          ],
          is_any_of: [
            ['value', ['xf_map', '$value', ['xf_pick', '$', ['id']]]],
          ],
          is_none_of: [
            ['value', ['xf_map', '$value', ['xf_pick', '$', ['id']]]],
          ],
        },
        __save_value_body_value: {
          choices: ['xf_concat', ['xf_pick', '$value', ['id']]],
          multiselect: ['xf_map', '$value', ['xf_pick', '$', ['id']]],
        },
        __save_value_lookup: {
          stage: {
            method: 'POST',
            url: '/records/display-names',
            body: {
              option_ids: ['@__save_value_body_value', '@input_type'],
            },
            result_path: [],
            result_transform: [
              'xf_hoist',
              [
                'xf_concat',
                [
                  'xf_map',
                  '@result_path/not_found/options',
                  {
                    id: ['xf_pick', '$', ['id']],
                    name: [
                      'xf_join',
                      '[Deleted] ',
                      ['xf_pick', '$', ['display_name']],
                    ],
                    not_found: true,
                    error_message: t('The option has been deleted'),
                  },
                ],
                [
                  'xf_map',
                  '@result_path/results/options',
                  {
                    id: ['xf_pick', '$', ['id']],
                    name: ['xf_pick', '$', ['display_name']],
                  },
                ],
              ],
            ],
          },
          dynamictags: {
            multiselect: {
              method: 'POST',
              url: '/records/display-names',
              body: {
                activity_tag_ids: ['@__save_value_body_value', '@input_type'],
              },
              result_path: [],
              result_transform: [
                'xf_concat',
                [
                  'xf_map',
                  '@result_path/not_found/activity_tags',
                  {
                    id: ['xf_pick', '$', ['id']],
                    name: [
                      'xf_join',
                      '[Deleted] ',
                      ['xf_pick', '$', ['display_name']],
                    ],
                    not_found: true,
                    error_message: t('The option has been deleted'),
                  },
                ],
                [
                  'xf_map',
                  '@result_path/results/activity_tags',
                  {
                    id: ['xf_pick', '$', ['id']],
                    name: ['xf_pick', '$', ['display_name']],
                  },
                ],
              ],
            },
            choices: {
              method: 'POST',
              url: '/records/display-names',
              body: {
                activity_tag_ids: ['@__save_value_body_value', '@input_type'],
              },
              result_path: [],
              result_transform: [
                'xf_hoist',
                [
                  'xf_concat',
                  [
                    'xf_map',
                    '@result_path/not_found/activity_tags',
                    {
                      id: ['xf_pick', '$', ['id']],
                      name: [
                        'xf_join',
                        '[Deleted] ',
                        ['xf_pick', '$', ['display_name']],
                      ],
                      not_found: true,
                      error_message: t('The option has been deleted'),
                    },
                  ],
                  [
                    'xf_map',
                    '@result_path/results/activity_tags',
                    {
                      id: ['xf_pick', '$', ['id']],
                      name: ['xf_pick', '$', ['display_name']],
                    },
                  ],
                ],
              ],
            },
          },
          relationship: {
            multiselect: {
              method: 'POST',
              url: '/records/display-names',
              body: {
                record_ids: ['@__save_value_body_value', '@input_type'],
              },
              result_path: [],
              result_transform: [
                'xf_concat',
                [
                  'xf_map',
                  '@result_path/not_found/records',
                  {
                    id: ['xf_pick', '$', ['id']],
                    display_name: [
                      'xf_join',
                      '[Deleted] ',
                      ['xf_pick', '$', ['display_name']],
                    ],
                    not_found: true,
                    error_message: t('The record has been deleted'),
                  },
                ],
                [
                  'xf_map',
                  '@result_path/results/records',
                  {
                    id: ['xf_pick', '$', ['id']],
                    display_name: ['xf_pick', '$', ['display_name']],
                  },
                ],
              ],
            },
            choices: {
              method: 'POST',
              url: '/records/display-names',
              body: {
                record_ids: ['@__save_value_body_value', '@input_type'],
              },
              result_path: [],
              result_transform: [
                'xf_hoist',
                [
                  'xf_concat',
                  [
                    'xf_map',
                    '@result_path/not_found/records',
                    {
                      id: ['xf_pick', '$', ['id']],
                      display_name: [
                        'xf_join',
                        '[Deleted] ',
                        ['xf_pick', '$', ['display_name']],
                      ],
                      not_found: true,
                      error_message: t('The record has been deleted'),
                    },
                  ],
                  [
                    'xf_map',
                    '@result_path/results/records',
                    {
                      id: ['xf_pick', '$', ['id']],
                      display_name: ['xf_pick', '$', ['display_name']],
                    },
                  ],
                ],
              ],
            },
          },
          dropdown: {
            method: 'POST',
            url: '/records/display-names',
            body: {
              activity_option_ids: ['@__save_value_body_value', '@input_type'],
            },
            result_path: [],
            result_transform: [
              'xf_hoist',
              [
                'xf_concat',
                [
                  'xf_map',
                  '@result_path/not_found/activity_options',
                  {
                    id: ['xf_pick', '$', ['id']],
                    name: [
                      'xf_join',
                      '[Deleted] ',
                      ['xf_pick', '$', ['display_name']],
                    ],
                    not_found: true,
                    error_message: t('The option has been deleted'),
                  },
                ],
                [
                  'xf_map',
                  '@result_path/results/activity_options',
                  {
                    id: ['xf_pick', '$', ['id']],
                    name: ['xf_pick', '$', ['display_name']],
                  },
                ],
              ],
            ],
          },
        },
      },
    },
  };
};
