import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton } from '../../../app/colors';
import KizenTypography from '../../../app/kizentypo';
import { resetButtonCss } from '../../../app/spacing';
import useField from '../../../hooks/useField';
import FileLibraryModal from '../FileInput/Modal';
import useModal from '../../Modals/useModal';
import IconAdornment from '../Adornments/IconAdornment';
import { cleanFiles } from '../helpers';
import { MaybeInputControl } from '../InputControl';
import { UnderlineInputLayout } from '../TextInput/Underline';

const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
`;

const FileInputWrapper = styled.button`
  ${resetButtonCss}
  ${({ disabled }) =>
    !disabled
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}

  display: flex;
  flex: 1;
  width: 100%;
  > div {
    height: 100%;
  }
  > div:first-of-type {
    display: flex;
    flex: 1;
    align-content: center;
  }

  > div:last-of-type {
    display: flex;
    flex: 0 0 20px;

    align-content: center;
    justify-content: flex-end;
  }
`;
const DefaultPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <StyledPlaceholder>
      <KizenTypography as="span" color={colorsButton.blue.default}>
        {t('Add Files')}
      </KizenTypography>
      <KizenTypography as="span">
        &nbsp; {t('or drop files here')}
      </KizenTypography>
    </StyledPlaceholder>
  );
};

const UnderlineFileInput = (props) => {
  const { t } = useTranslation();

  const { onChange, ...inputControlProps } = props;
  const { disabled, files: filesProp, viewable, source } = props;
  const [focused, setFocused] = useState(false);
  const [files] = useField(() => {
    return cleanFiles(filesProp);
  }, [filesProp]);
  const [modalProps, , modal] = useModal();

  const FilledPlaceholder = () => {
    return (
      <StyledPlaceholder>
        <KizenTypography as="span" color={colorsButton.blue.default}>
          {`${files.length} ${
            files.length !== 1 ? t('Files Selected') : t('File Selected')
          }`}
        </KizenTypography>
      </StyledPlaceholder>
    );
  };

  return (
    <>
      <MaybeInputControl
        forInput
        variant="underline"
        {...{
          ...inputControlProps,
          error: true,
          disabled: true,
          shrink: true,
        }}
      >
        <UnderlineInputLayout
          disabled={disabled && !viewable}
          viewable={viewable}
          focused={focused}
        >
          <FileInputWrapper
            onClick={() => (!disabled || viewable) && modal.show()}
            disabled={disabled && !viewable}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          >
            <div>
              <FilledPlaceholder />
            </div>
            <div>
              {(!disabled || viewable) && <IconAdornment icon="visible" />}
            </div>
          </FileInputWrapper>
        </UnderlineInputLayout>
      </MaybeInputControl>
      {modalProps.show && (
        <FileLibraryModal
          source={source}
          show
          publicFile
          files={files}
          onChange={onChange}
          modalProps={modalProps}
          modal={modal}
          disabled={disabled}
          viewable={viewable}
        />
      )}
    </>
  );
};

UnderlineFileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        created: PropTypes.oneOfType([
          PropTypes.oneOf([false]),
          PropTypes.string,
        ]).isRequired,
      }),
    ])
  ).isRequired,
  placeholder: PropTypes.node,
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  shrink: PropTypes.bool,
  viewable: PropTypes.bool,
};

UnderlineFileInput.defaultProps = {
  label: null,
  placeholder: <DefaultPlaceholder />,
  id: '',
  className: '',
  error: false,
  disabled: false,
  shrink: null,
  viewable: true,
};
export default UnderlineFileInput;
