import React from 'react';
import * as PropTypes from 'prop-types';
import { grayScale } from 'app/colors';
import {
  StyledBusinessBox,
  StyledIcon,
  StyledLabel,
  StyledActionButton,
  StyledSelectBox,
  StyledTextLabel,
} from './styles';

const BusinessBox = ({
  className,
  icon,
  label,
  value,
  selected,
  additionalLabel = 'tesst',
}) => {
  return (
    <StyledBusinessBox className={className} selected={selected}>
      <StyledSelectBox selected={selected}>
        <StyledIcon
          className="business-icon"
          icon={icon}
          size="3x"
          color={grayScale.dark}
          selected={selected}
        />
        <StyledLabel
          weight="bold"
          size="buttonLabel"
          textTransform="uppercase"
          selected={selected}
        >
          {label}
        </StyledLabel>
        <StyledActionButton
          className="select-button"
          selected={selected}
          value={value}
        >
          {selected ? 'Selected' : 'Select'}
        </StyledActionButton>
      </StyledSelectBox>
      {additionalLabel && <StyledTextLabel>{additionalLabel}</StyledTextLabel>}
    </StyledBusinessBox>
  );
};

BusinessBox.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  additionalLabel: PropTypes.string,
};

BusinessBox.defaultProps = {
  additionalLabel: null,
};

export default BusinessBox;
