import EmailAddressTextInput from '__components/Inputs/TextInput/presets/EmailAddress';
import { useTranslation } from 'react-i18next';
import { useErrors } from '../../hooks/useErrors';

type EmailInputProps = {
  value?: string | null;
  onChange: (value: string) => void;
};

export const EmailInput = ({ value, onChange }: EmailInputProps) => {
  const { t } = useTranslation();

  const { clearError, validate, error } = useErrors('error_notification_email');

  const handleChange = (v: string) => {
    onChange(v);
    clearError();
  };

  return (
    <EmailAddressTextInput
      value={value}
      label={t('Additional Error Notification Email')}
      labelInfo={t(
        'Error emails will always be sent to the uploading user by default, but you may also add another email recipient (such as a distribution group).'
      )}
      labelInfoPlacement="top"
      placeholder={t('Email')}
      error={error}
      validate={validate}
      onChange={handleChange}
      margin
    />
  );
};
