import { useEffect, useMemo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useRafLoop } from 'react-use';
import { useStore, useReactFlow } from 'reactflow';

import { useAutomationsSelector } from 'pages/AutomationEngine/store/react';
import { getMouseX, getMouseY } from 'components/DragAndDropLayout/helpers';
import { getExtent, nodeHasDimension } from './layout';
import { clamp } from 'utility/snippets';

const REFRESH_PX = 7;
const Y_FRAG = 110; // extra space to allow the menu to show
// The "play" is how far beyond the edge of the viewport the flow can scroll
export const playX = 75;

export const playYTop = 100;
// We want the bottom play to appear as 100 too, but we accout
// for extent being aware of max card height for bottom trigger,
// which causes it to leave some space. Honestly this number was
// from measuring in-browser, but there may be a better way to derive it.
export const playYBottom = 67;

const nearLeft = ({ x }, { left }, delta = 100) => {
  return Math.abs(left - x) < delta;
};

const nearRight = ({ x }, { right }, delta = 100) => {
  return Math.abs(right - x) < delta;
};

const nearTop = ({ y }, { top }, delta = 100) => {
  return Math.abs(top - y) < delta;
};

const nearBottom = ({ y }, { bottom }, delta = 100) => {
  return Math.abs(bottom - y) < delta;
};

export function useScrollFlowWhilePlacingCore(placing) {
  const transform = useStore((state) => state.transform);

  const d3Zoom = useStore((s) => s.d3Zoom);
  const d3Selection = useStore((s) => s.d3Selection);
  const stateRef = useRef({
    dX: 0,
    dY: 0,
    nativeEvent: null,
    flowElRect: null,
    mouseOnFlow: false,
    hasInteractedWithoutScroll: false,
  });

  // This interval is the loop that regularly reads dX and dY,
  // and uses it to continuous scroll by dX and dY pixels.
  const [stopScrolling, startScrolling] = useRafLoop(() => {
    // Reset on an interval, allows us to recompute less often but keep it up to date
    stateRef.current.flowElRect = null;
    const { dX, dY } = stateRef.current;
    if (dX !== 0 || dY !== 0) {
      d3Zoom.translateBy(d3Selection, dX, dY);
    }
  });

  useEffect(() => {
    if (placing) {
      startScrolling();
    } else {
      stopScrolling();
    }
  }, [placing, startScrolling, stopScrolling]);

  useEffect(() => {
    const { nativeEvent, mouseOnFlow } = stateRef.current;
    // Re-dispatch the mousemove event on the document after the transform occurred:
    // this will allow the step being dragged to have its position updated by
    // react-draggable even when the user's mouse is not moving.
    if (placing && nativeEvent && mouseOnFlow) {
      const ownerDocument =
        nativeEvent.target.ownerDocument || nativeEvent.target;
      ownerDocument.dispatchEvent(nativeEvent);
    }
    if (!placing) {
      // Reset some state when placing ends
      Object.assign(stateRef.current, {
        dX: 0,
        dY: 0,
        nativeEvent: null,
        flowElRect: null,
        hasInteractedWithoutScroll: false,
      });
    }
  }, [placing, transform]);

  return {
    // These should be spread into <ReactFlow /> to enable scrolling
    // when the user is placing an item and is near the outer edge of the flow.
    onMouseMove: (ev) => {
      if (placing) {
        const { nativeEvent, currentTarget: flowEl } = ev;

        stateRef.current.nativeEvent = nativeEvent;
        stateRef.current.flowElRect =
          stateRef.current.flowElRect || flowEl.getBoundingClientRect();

        const { flowElRect } = stateRef.current;
        const mouseCoords = { x: getMouseX(ev), y: getMouseY(ev) };

        stateRef.current.dX = 0;
        stateRef.current.dY = 0;

        if (nearLeft(mouseCoords, flowElRect)) {
          stateRef.current.dX += REFRESH_PX;
        }
        if (nearRight(mouseCoords, flowElRect)) {
          stateRef.current.dX -= REFRESH_PX;
        }
        if (nearTop(mouseCoords, flowElRect)) {
          stateRef.current.dY += REFRESH_PX;
        }
        if (nearBottom(mouseCoords, flowElRect)) {
          stateRef.current.dY -= REFRESH_PX;
        }

        if (stateRef.current.dX === 0 && stateRef.current.dY === 0) {
          stateRef.current.hasInteractedWithoutScroll = true;
        }

        if (!stateRef.current.hasInteractedWithoutScroll) {
          // When the user starts dragging it cannot already be scrolling.
          // This check cancels the scroll until the user has had an interaction
          // that did not result in a scroll.  This occurs e.g. if the user
          // drags a step in from the control bar and immediately triggers nearTop().
          stateRef.current.dX = 0;
          stateRef.current.dY = 0;
        }
      }
    },
    onMouseEnter: () => {
      stateRef.current.mouseOnFlow = true;
    },
    onMouseLeave: () => {
      stateRef.current.mouseOnFlow = false;
    },
  };
}

export function useScrollFlowWhilePlacing() {
  const placing = useAutomationsSelector((state) => state.placing);
  return useScrollFlowWhilePlacingCore(placing);
}

// Calculate the translate extent such that the box containing
// the rendered elements is always visible in the automation flow.
//
// What is "translate extent"?  It is a concept in d3-zoom and by
// extension, a concept in react-flow-renderer.  This defines the
// coordinates of the full space that needs to be navigable via scrolling.
//
// The requirements are that the automation flow will be centered on the
// screen, and the screen will not scroll until the top, bottom, left
// or right of the flow are within 100px of the side of the screen.
// As it gets wider than that, a scrollbar will appear.  The automation
// can be scrolled such that any of its extents may be <=100px from
// the edge of the flow.

const boxToRect = ({ x, y, x2, y2 }) => ({
  x,
  y,
  width: x2 - x,
  height: y2 - y,
});

const getTransformForBounds = (
  bounds,
  width,
  height,
  minZoom,
  maxZoom,
  padding = 0.1,
  minX = playX
) => {
  const xZoom = width / (bounds.width * (1 + padding));
  const yZoom = height / (bounds.height * (1 + padding));
  const zoom = Math.min(xZoom, yZoom);
  const clampedZoom = clamp(zoom, minZoom, maxZoom);
  const boundsCenterX = bounds.x + bounds.width / 2;
  const boundsCenterY = bounds.y + bounds.height / 2;
  const x = Math.max(width / 2 - boundsCenterX * clampedZoom, minX);
  const y = height / 2 - boundsCenterY * clampedZoom;

  return [x, y, clampedZoom];
};

export function useTranslateExtent(minZoom) {
  const height = useStore((state) => state.height);
  const width = useStore((state) => state.width);
  const zoom = useStore((state) => state.transform[2]);
  const internalNodes = useStore((state) => state.nodeInternals);
  const lastTranslateExtentRef = useRef();
  const nodes = useMemo(() => [...internalNodes.values()], [internalNodes]);
  const [[minX, minY], [maxX, maxY]] = getExtent(nodes);
  const [translateExtent, sX, sY] = useMemo(() => {
    // First the x (horizontal) scroll direction:

    // The "extent" is the width of the automation flow per it caluclated extent
    const extentX = maxX - minX;

    // We only scroll when the automation is wider than the screen, with some play
    const scrollingX = extentX > (width - 2 * playX) / zoom;
    // At this point we just have to draw some diagrams to determine
    // how to calculate the translate extent in the case of a. scrolling
    // and b. not scrolling in the horizontal direction.
    const [minTranslateX, maxTranslateX] = scrollingX
      ? [minX + -playX / zoom, minX + playX / zoom + extentX]
      : [
          minX + (extentX - width / zoom) / 2,
          minX + (extentX + width / zoom) / 2,
        ];

    // Now the y (vertical) scroll direction
    // (see analogous comments above for horizontal scroll direction):
    const yFrag = Y_FRAG / zoom;
    const extentY = maxY - minY + yFrag;

    const scrollingY = extentY > (height - (playYTop + playYBottom)) / zoom;
    const [minTranslateY, maxTranslateY] = scrollingY
      ? [minY + -playYTop / zoom, minY + playYBottom / zoom + extentY]
      : [
          minY + (extentY - height / zoom) / 2,
          minY + (extentY + height / zoom) / 2,
        ];

    return [
      [
        [minTranslateX, minTranslateY],
        [maxTranslateX, maxTranslateY],
      ],
      scrollingX,
      scrollingY,
    ];
  }, [height, width, zoom, minX, maxX, minY, maxY]);

  const d3Zoom = useStore((s) => s.d3Zoom);
  const d3Selection = useStore((s) => s.d3Selection);
  const useTransitionsRef = useRef(false);

  const viewportFit = useRef(null);
  const viewportFitOffscreen = useRef(false);

  const { setViewport } = useReactFlow();
  useEffect(() => {
    if (!d3Zoom || !d3Selection) {
      return;
    }

    if (!nodes.length || !nodes.every(nodeHasDimension)) {
      // Avoid repositioning until the nodes are drawn with their measurements
      if (!viewportFitOffscreen.current) {
        // one time move the viewport way off screen so that it doesn't Pop
        viewportFitOffscreen.current = true;
        setViewport({ x: 20000, y: 20000, zoom: 1 });
      }
      return;
    }

    // Notes on below: translateBy(0, 0) is a way to get d3Zoom to enforce translateExtent.
    // On first load we enforce it immediately, and for future changes
    // we use a quick transition to make the experience less choppy.

    if (useTransitionsRef.current) {
      if (!isEqual(translateExtent, lastTranslateExtentRef.current)) {
        // Avoid kicking off a transition when it shouldn't translate anything
        d3Selection.transition().duration(80).call(d3Zoom.translateBy, 0, 0);
        lastTranslateExtentRef.current = translateExtent;
      }
    } else {
      d3Zoom.translateTo(d3Selection, 0, 0); // Scroll all the way to top left.
      d3Zoom.translateBy(d3Selection, 0, 0);
      useTransitionsRef.current = true;
      lastTranslateExtentRef.current = translateExtent;

      // Calculate the viewport fit for the initial view
      const nodeRect = boxToRect({ x: minX, y: minY, x2: maxX, y2: maxY });
      const [x, y, zoom] = getTransformForBounds(
        nodeRect,
        width,
        height - Y_FRAG,
        minZoom,
        1.0, // maxZoom on intial viewportFit view is 1.0
        0.1 // padding stolen form d3-zoom fitView
      );
      viewportFit.current = { x, y, zoom };
    }
  }, [
    translateExtent,
    nodes,
    d3Zoom,
    d3Selection,
    minX,
    minY,
    maxX,
    maxY,
    width,
    height,
    minZoom,
    setViewport,
  ]);

  return [translateExtent, sX, sY, viewportFit.current];
}
