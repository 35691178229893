import React, { useState } from 'react';
import styled from '@emotion/styled';
import RadioButton from 'components/Kizen/RadioButton';
import { TextSpan, fontWeights } from 'app/typography';
import { grayScale } from 'app/colors';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .Sample-stacked-radio + .Sample-stacked-radio {
    margin-top: 0;
  }
`;

export const StyledLabel = styled(TextSpan)`
  color: ${grayScale.mediumDark};
  font-weight: ${fontWeights.bold};
  font-size: 1.1em;
  margin-bottom: -2px;
  margin-top: 0px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const options = [
  {
    label: 'Option 1',
    value: 'option-1',
  },
  {
    label: 'Option 2',
    value: 'option-2',
  },
  {
    label: 'Option 3',
    value: 'option-3',
  },
];

export function RadioButtons() {
  const [defaultSelected, setDefaultSelected] = useState(false);
  const [errorSelected, setErrorSelected] = useState(false);

  return (
    <StyledWrapper>
      <RadioButton
        label="Default"
        name="Default"
        value="default"
        checked={defaultSelected}
        onChange={() => setDefaultSelected(!defaultSelected)}
        className="Sample-stacked-radio"
      />
      <RadioButton
        label="Selected"
        name="Selected"
        value="selected"
        checked
        onChange={() => {}}
        className="Sample-stacked-radio"
      />
      <RadioButton
        disabled
        checked
        label="Disabled (selected)"
        name="Disabled (selected)"
        value="disabled"
        onChange={() => {}}
        className="Sample-stacked-radio"
      />
      <RadioButton
        disabled
        label="Disabled (not selected)"
        name="Disabled (not selected)"
        value="disabled"
        onChange={() => {}}
        className="Sample-stacked-radio"
      />
      <RadioButton
        error
        label="Error"
        name="Error"
        value="error"
        checked={errorSelected}
        onChange={() => setErrorSelected(!errorSelected)}
        className="Sample-stacked-radio"
      />
    </StyledWrapper>
  );
}

export function RadioButtonGroup({ label, size, ...props }) {
  const [selectedOption, setSelectedOption] = useState();

  const renderOptions = ({ options: opts, name }) => {
    return (
      <StyledContainer {...props}>
        {label && <StyledLabel>{label}</StyledLabel>}
        {opts.map((option) => {
          return (
            <RadioButton
              label={option.label}
              value={option.value}
              name={name}
              key={option.value}
              checked={selectedOption === option.value}
              onChange={(optionValue) => setSelectedOption(optionValue)}
              className="Sample-stacked-radio"
              size={size}
            />
          );
        })}
      </StyledContainer>
    );
  };

  return (
    <fieldset>
      <StyledWrapper>
        {renderOptions({ options, name: 'Radio Group' })}
      </StyledWrapper>
    </fieldset>
  );
}
