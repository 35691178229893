import { memo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Kizen/Icon';
import { grayScale } from 'app/colors';
import { breakpoints } from 'app/spacing';
import { Label } from 'app/typography';

const StyledIcon = styled(Icon)`
  display: none;
  @media (min-width: ${breakpoints.md + 1}px) {
    display: flex;
  }
`;

const NavItemContent = memo(({ icon, label, noCustomIcon }) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledIcon icon={icon} color={grayScale.dark} noCustom={noCustomIcon} />
      <Label as="span" textTransform="none">
        {typeof label === 'function' ? label(t) : label}
      </Label>
    </>
  );
});

export default NavItemContent;

NavItemContent.displayName = 'NavItemContent';
