import {
  LegendContainer,
  LegendParent,
  LegendItem,
  LegendButton,
  LegendControls,
  LegendDot,
  Arrow,
} from './styles';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import PropTypes from 'prop-types';
import { gutters } from 'app/spacing';

const getAdditionalData = (
  isDisabled,
  data,
  chartTotal,
  {
    displaySecondaryData = false,
    isSecondaryData = () => {},
    primaryTotal = 0,
    secondaryTotal = 0,
  } = {}
) => {
  if (!isDisabled && typeof data.value === 'number') {
    let value = 0;
    if (displaySecondaryData && data.value === 0) {
      return ' (0%)';
    } else if (displaySecondaryData && isSecondaryData(data)) {
      value = (data.value / secondaryTotal) * 100;
    } else if (displaySecondaryData && !isSecondaryData(data)) {
      value = (data.value / primaryTotal) * 100;
    }
    return ` (${value.toFixed(value < 1 && value >= 0.1 ? 1 : 0)}%)`;
  }
  return null;
};

const CustomLegend = ({
  chartData = [],
  colors = [],
  hidden = [],
  handleClick,
  legendRange = [0, 1],
  hasPreviousRows = false,
  rotateRowDown,
  rotateRowUp,
  hasMoreRows = false,
  columnCount = 4,
  columns = 4,
  fixedWidth = false,
  prePad = false,
  getLabel = (d) => d.category.name.toUpperCase(),
  getId = (d) => d.id,
  mobile = false,
  rows = 1,
  fullWidth = false,
  chartTotal,
  secondaryTotalOptions,
  compact = false,
  verticalControls = false,
}) => {
  const shouldWrap = rows > 1;
  const isOnePage = !hasMoreRows && !hasPreviousRows;
  const neededRows = Math.ceil(chartData.length / columns);

  const items = chartData
    .map((d, index) => {
      const color = colors[index % colors.length].light;
      const isDisabled = hidden.includes(getId(d));

      return (
        <LegendItem
          fixedWidth={fixedWidth || isOnePage || shouldWrap}
          fullWidth={fullWidth}
          onClick={mobile ? null : () => handleClick(getId(d))}
          key={d.id}
          columnCount={isOnePage ? columns : columnCount / rows}
        >
          <LegendButton disabled={isDisabled} fullWidth={fullWidth}>
            <LegendDot
              legendColor={color}
              disabled={isDisabled}
              onClick={mobile ? () => handleClick(getId(d)) : null}
            />
            <TextEllipsisWithTooltip type="small">
              {getLabel(d)}
              {getAdditionalData(
                isDisabled,
                d,
                chartTotal,
                secondaryTotalOptions
              )}
            </TextEllipsisWithTooltip>
          </LegendButton>
        </LegendItem>
      );
    })
    .slice(legendRange[0], legendRange[1]);

  const deficit =
    fixedWidth && hasPreviousRows ? columnCount - items.length : 0;

  // If using fixed-width mode, fill in the remaining slots
  // with empty spaces, either at the start or end depending on configuration
  const placeholder = Array(deficit)
    .fill(true)
    .map((_, index) => {
      return (
        <LegendItem
          fixedWidth={fixedWidth}
          key={`missing-${index}`}
          columnCount={columnCount}
        />
      );
    });

  return (
    <LegendParent fullWidth={fullWidth} compact={compact}>
      <LegendContainer
        shouldWrap={shouldWrap}
        fullWidth={fullWidth}
        rows={neededRows < rows ? neededRows : rows}
      >
        {prePad ? placeholder : null}
        {items}
        {!prePad ? placeholder : null}
      </LegendContainer>
      {hasPreviousRows || hasMoreRows ? (
        <LegendControls fullWidth={fullWidth} vertical={verticalControls}>
          {hasPreviousRows ? (
            <Arrow
              src="/images/arrow-enabled.svg"
              alt="enabled arrow pointing up"
              onClick={rotateRowUp}
              flip
            />
          ) : (
            <Arrow
              src="/images/arrow-disabled.svg"
              alt="disabled arrow pointing up"
              flip
            />
          )}
          {hasMoreRows ? (
            <Arrow
              src="/images/arrow-enabled.svg"
              alt="enabled arrow pointing down"
              onClick={rotateRowDown}
              left={verticalControls ? 0 : gutters.spacing(1)}
              top={verticalControls ? gutters.spacing(1) : 0}
            />
          ) : (
            <Arrow
              src="/images/arrow-disabled.svg"
              alt="disabled arrow pointing down"
              left={verticalControls ? 0 : gutters.spacing(1)}
              top={verticalControls ? gutters.spacing(1) : 0}
            />
          )}
        </LegendControls>
      ) : null}
    </LegendParent>
  );
};

CustomLegend.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
  colors: PropTypes.arrayOf(PropTypes.object),
  hidden: PropTypes.arrayOf(PropTypes.string),
  handleClick: PropTypes.func.isRequired,
  legendRange: PropTypes.arrayOf(PropTypes.number),
  hasPreviousRows: PropTypes.bool,
  rotateRowDown: PropTypes.func.isRequired,
  rotateRowUp: PropTypes.func.isRequired,
  hasMoreRows: PropTypes.bool,
  onClick: PropTypes.func,
  columnCount: PropTypes.number,
  fixedWidth: PropTypes.bool,
  prePad: PropTypes.bool,
};

export default CustomLegend;
