import React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BaseConfirmationModal from 'components/Modals/presets/ConfirmationModal';

export default function ConfirmationModal({ onHide, ...others }) {
  const { t } = useTranslation();
  return (
    <BaseConfirmationModal
      heading={t('Please Confirm Modification')}
      buttonText={t('Confirm')}
      onHide={onHide}
      defaultLeftBtnHandler={onHide}
      {...others}
    />
  );
}

ConfirmationModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};
