import TeamMemberService from 'services/TeamMemberService';
import {
  EXTERNAL_ACCOUNT,
  EXTERNAL_ACCOUNT_ALL,
  TEAM_MEMBERS,
} from '../query-keys';
import { useQuery } from 'react-query';

export const toTeamMemberListOption = (tm) => {
  return {
    value: tm.id,
    label: tm.displayName,
    data: tm,
  };
};

export const useExternalAccounts = (rqOpts = {}) => {
  return useQuery(
    [...EXTERNAL_ACCOUNT.ALL],
    TeamMemberService.getExternalAccounts,
    rqOpts
  );
};

export const useAllExternalAccounts = (opts, rqOpts = {}) => {
  return useQuery(
    [...EXTERNAL_ACCOUNT_ALL.ALL],
    () => TeamMemberService.getAllExternalAccounts(opts),
    rqOpts
  );
};

export const useTypeAheadTeamMemberList = (enabled = true) => {
  return useQuery(
    [...TEAM_MEMBERS.SEARCH({})],
    TeamMemberService.getTeamMemberTypeahead,
    {
      select: (result) => {
        return result.map((tm) => ({
          value: tm.id,
          label: `${tm.firstName} ${tm.lastName} (${tm.email})`,
          data: tm,
        }));
      },
      enabled,
    }
  );
};

export const useTeamMemberList = (enabled = true) => {
  return useQuery([...TEAM_MEMBERS.ALL], TeamMemberService.getTeamMemberList, {
    select: (result) => result.map(toTeamMemberListOption),
    enabled,
  });
};

export const useTeamMember = (id, { enabled = true, select } = {}) => {
  return useQuery({
    queryKey: TEAM_MEMBERS.GET(id),
    queryFn: () => {
      TeamMemberService.get(id, { skipErrorBoundary: true });
    },
    enabled,
    select,
  });
};

export const updatePageConfigQuery = (key, config, objectId) => {
  if (objectId) {
    return TeamMemberService.updateCustomObjectRecordsListPageConfig(
      objectId,
      config
    );
  } else {
    return TeamMemberService.setPageConfig(key, config);
  }
};
