import React from 'react';
import { Pill, PillWrapper } from '../../components/PillNodeView';

export default (props) => {
  const { id, label: name, stamp } = props.node.attrs || {};

  const setCursor = () => {
    const pos = props.getPos();

    props.editor
      .chain()
      .focus()
      .setTextSelection(pos + 1)
      .run();
  };

  const handleRemove = (_, ev) => {
    ev.stopPropagation();

    const pos = props.getPos();
    props.deleteNode();
    props.editor.chain().focus().setTextSelection(pos).run();
  };
  return (
    <PillWrapper as="button">
      <Pill
        hideRemoveIcon={false}
        maxWidth="250px"
        id={id}
        name={name}
        stamp={stamp}
        onClick={setCursor}
        onRemove={handleRemove}
      />
    </PillWrapper>
  );
};
