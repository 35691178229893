import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  grayScale,
  shadowLight,
  colorsSecondary,
  colorsButton,
} from '__app/colors';

// TODO The original color for this was dark red when developed for inline editing.
// Over time the designs have displayed it lighter, so we support this variant.
// Hopefully we can standardize the color at some point. It may also be intentional
// depending on the context of the error, currently unsure.

export default styled.div<{
  light?: boolean;
  show?: boolean;
  duration?: string;
}>`
  border: 1px solid ${colorsSecondary.red.dark};
  ${({ light }) =>
    light &&
    css`
      border-color: ${colorsButton.red.hover};
    `}
  border-radius: 4px;
  background: ${grayScale.white};
  ${shadowLight}
  padding: 10px;
  max-width: 90%;

  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity ${({ duration = '0.5s' }) => duration} ease-in-out;
`;
