import { FIELD_TYPES } from 'utility/constants';
import SimpleOptions from './options/Simple';
import StatusOptions from './options/Status';
import PhoneNumberOptions from './options/PhoneNumber';
import TextOptions from './options/Text';
import PriceOptions from './options/Price';
import RatingOptions from './options/Rating';
import YesNoOptions from './options/YesNo';
import RelationshipOptions from './options/Relationship';
import ActivityRelationshipOptions from './options/ActivityRelationship';
import DynamicTagOptions from './options/DynamicTag';

export const STEPS = {
  FIELD_SETTINGS: 'settings',
  FIELD_TYPE: 'type',
  FIELD_OPTIONS: 'options',
};

export const LIST_OF_FIELDS = [
  FIELD_TYPES.Checkbox,
  {
    ...FIELD_TYPES.Checkboxes,
    options: SimpleOptions,
    optionsValidation: true,
    optionsLimit: 250,
  },
  FIELD_TYPES.Date,
  FIELD_TYPES.DateTime,
  {
    ...FIELD_TYPES.Dropdown,
    options: SimpleOptions,
    optionsValidation: true,
    optionsLimit: 250,
  },
  {
    ...FIELD_TYPES.DynamicTags,
    options: DynamicTagOptions,
    optionsValidation: false,
    shownOn: ['FieldWizard', 'ActivityFieldWizard'],
  },
  FIELD_TYPES.Email,
  FIELD_TYPES.Files,
  {
    ...FIELD_TYPES.LongText,
    options: TextOptions,
    optionsValidation: false,
  },
  FIELD_TYPES.Decimal,
  FIELD_TYPES.Integer,
  {
    ...FIELD_TYPES.PhoneNumber,
    options: PhoneNumberOptions,
    optionsValidation: false,
  },
  {
    ...FIELD_TYPES.Money,
    options: PriceOptions,
    optionsValidation: false,
  },
  {
    ...FIELD_TYPES.Radio,
    options: SimpleOptions,
    optionsValidation: true,
    optionsLimit: 250,
  },
  {
    ...FIELD_TYPES.Rating,
    options: RatingOptions,
    optionsValidation: true,
  },
  {
    ...FIELD_TYPES.Relationship,
    options: RelationshipOptions,
    optionsValidation: true,
    shownOn: ['FieldWizard'],
  },
  {
    ...FIELD_TYPES.Relationship,
    options: ActivityRelationshipOptions,
    optionsValidation: true,
    shownOn: ['ActivityFieldWizard'],
  },
  {
    ...FIELD_TYPES.Status,
    options: StatusOptions,
    optionsValidation: true,
    optionsLimit: 250,
  },
  {
    ...FIELD_TYPES.TeamSelector,
    shownOn: ['FieldWizard', 'ActivityFieldWizard', 'FormFieldWizard'],
  },
  {
    ...FIELD_TYPES.Text,
    options: TextOptions,
    optionsValidation: false,
  },
  {
    ...FIELD_TYPES.YesNoMaybe,
    options: YesNoOptions,
    optionsValidation: false,
  },
];

// do not show text options for activity, survey, and form fields
export const LIST_OF_FORM_ACTIVITY_FIELDS = [
  FIELD_TYPES.Checkbox,
  {
    ...FIELD_TYPES.Checkboxes,
    options: SimpleOptions,
    optionsValidation: true,
    optionsLimit: 250,
  },
  FIELD_TYPES.Date,
  FIELD_TYPES.DateTime,
  {
    ...FIELD_TYPES.Dropdown,
    options: SimpleOptions,
    optionsValidation: true,
    optionsLimit: 250,
  },
  {
    ...FIELD_TYPES.DynamicTags,
    options: DynamicTagOptions,
    optionsValidation: false,
    shownOn: ['FieldWizard', 'ActivityFieldWizard'],
  },
  FIELD_TYPES.Email,
  FIELD_TYPES.Files,
  FIELD_TYPES.LongText,
  FIELD_TYPES.Decimal,
  FIELD_TYPES.Integer,
  {
    ...FIELD_TYPES.PhoneNumber,
    options: PhoneNumberOptions,
    optionsValidation: false,
  },
  {
    ...FIELD_TYPES.Money,
    options: PriceOptions,
    optionsValidation: false,
  },
  {
    ...FIELD_TYPES.Radio,
    options: SimpleOptions,
    optionsValidation: true,
    optionsLimit: 250,
  },
  {
    ...FIELD_TYPES.Rating,
    options: RatingOptions,
    optionsValidation: true,
  },
  {
    ...FIELD_TYPES.Relationship,
    options: RelationshipOptions,
    optionsValidation: true,
    shownOn: ['FieldWizard'],
  },
  {
    ...FIELD_TYPES.Relationship,
    options: ActivityRelationshipOptions,
    optionsValidation: true,
    shownOn: ['ActivityFieldWizard'],
  },
  {
    ...FIELD_TYPES.Status,
    options: StatusOptions,
    optionsValidation: true,
    optionsLimit: 250,
  },
  {
    ...FIELD_TYPES.TeamSelector,
    shownOn: ['FieldWizard', 'ActivityFieldWizard', 'FormFieldWizard'],
  },
  FIELD_TYPES.Text,
  {
    ...FIELD_TYPES.YesNoMaybe,
    options: YesNoOptions,
    optionsValidation: false,
  },
];
