import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FIELD_TYPES } from '../../../utility/constants';
import { getNonInputControlProps, hasInputControl } from '../InputControl';
import { selectedValueProp } from '../props';
import Select from '../Select';

const UnderlineYesNoMaybe = (props) => {
  const { className } = props;
  const selectProps = getNonInputControlProps(props);

  const { t } = useTranslation();

  return (
    <Select
      {...selectProps}
      className={!hasInputControl(props) && className}
      isSearchable={false}
      placeholder={FIELD_TYPES.YesNoMaybe.localizedPlaceholder(t)}
      variant="underline"
      error={false}
    />
  );
};

UnderlineYesNoMaybe.propTypes = {
  value: selectedValueProp,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

UnderlineYesNoMaybe.defaultProps = {
  value: null,
  onChange: null,
  error: null,
  disabled: null,
};

export default UnderlineYesNoMaybe;
