import { forwardRef, useCallback, useState } from 'react';
import { ConfigurationPanelBodyVirtual } from './styles';

export const VirtualParent = forwardRef((props, ref) => {
  const [bottom, setBottom] = useState(true);

  const handleChangeHeight = useCallback(
    (innerHeight) => {
      const outerHeight = props.style.height;
      if (innerHeight < outerHeight) {
        setBottom(false);
      } else {
        setBottom(true);
      }
    },
    [props]
  );

  return (
    <ConfigurationPanelBodyVirtual
      ref={ref}
      noHeader
      bottom={bottom}
      {...props}
      onChangeHeight={handleChangeHeight}
    />
  );
});

export const InnerElement = forwardRef(({ style, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        ...style,
        height: `${parseFloat(style.height) + 6 * 2}px`,
      }}
      {...rest}
    />
  );
});
