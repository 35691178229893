import { forwardRef, useMemo } from 'react';
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import { PluginKey } from '@tiptap/pm/state';
import { SuggestionList200 } from './components/SuggestionList';
import { MergeFieldPill } from './components/MergeFieldPill';
import { filterMergeField, useMergeFields } from './use-merge-fields';
import { insertMergeField } from './utils/commands';
import { renderWith } from './utils/suggestions';

const MergeFieldPluginKey = new PluginKey('mergeField');

export const MergeFieldList = forwardRef(
  ({ editor, range, items: query, mergeFields, listHeaders, ...rest }, ref) => {
    const { data, isLoading } = useMergeFields({ mergeFields, listHeaders });

    const items = useMemo(() => {
      return data.length && data[0].items
        ? data
            .map(({ label, items, key }) => ({
              label,
              key,
              items: items?.length ? items.filter(filterMergeField(query)) : [],
            }))
            .filter(({ items }) => items.length)
        : data.filter(filterMergeField(query));
    }, [data, query]);

    return (
      <SuggestionList200
        ref={ref}
        onSelect={(attrs) => {
          editor
            .chain()
            .focus()
            .setTextSelection(range)
            .command(insertMergeField(attrs))
            .run();
        }}
        items={items}
        isLoading={isLoading}
        {...rest}
      />
    );
  }
);

export const MergeField = Node.create({
  name: 'mergeField',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'kzn-merge-field',
      },
      renderLabel(node) {
        return `{{ ${node.attrs.relationship} }}`;
      },
      suggestion: {
        char: '{{',
        allowSpaces: true,
        pluginKey: MergeFieldPluginKey,
        allow: ({ editor, range }) => {
          // copied from: https://github.com/ueberdosis/tiptap/blob/9afadeb7fe368f95064f84424d6a3dd6cd85b43d/packages/extension-mention/src/mention.ts#L54-L60
          const $from = editor.state.doc.resolve(range.from);
          const type = editor.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);
          return allow;
        },
        render: renderWith(MergeFieldList),
        items: async (query) => {
          // items passes through the query text so the render node can be responsible for getting and filtering data
          // `items` will be the prop on the render node - be sure to alias to `query` to avoid confusion.
          // some times an object gets passed {Editor, query}
          return typeof query === 'string'
            ? query?.trim()
            : query?.query?.trim();
        },
      },
    };
  },

  group: 'inline',

  inline: true,

  selectable: true,

  atom: true,

  addAttributes() {
    return {
      label: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-merge-field-fallback-label'),
        renderHTML: (attributes) => {
          return attributes.label
            ? { 'data-merge-field-fallback-label': attributes.label }
            : {};
        },
      },
      relationship: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-merge-field-relationship'),
        renderHTML: (attributes) => {
          return attributes.relationship
            ? { 'data-merge-field-relationship': attributes.relationship }
            : {};
        },
      },
      objectname: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-merge-field-objectname'),
        renderHTML: (attributes) => {
          return attributes.objectname
            ? { 'data-merge-field-objectname': attributes.objectname }
            : {};
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.kzn-merge-field',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel(node),
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel(node);
  },

  addNodeView() {
    return ReactNodeViewRenderer(MergeFieldPill);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
