export const getPlacing = ({ pageBuilder }) => pageBuilder.placing;

export const getCreating = ({ pageBuilder }) => !!pageBuilder.createItemInfo;

export const getPlacingItem = ({ pageBuilder }) =>
  (pageBuilder.placing && pageBuilder.lastPlacingItem) ||
  pageBuilder.createItemInfo;

export const getCreateItemByType = (
  { pageBuilder: { createItemInfo } },
  type
) => {
  return createItemInfo?.type === type ? createItemInfo : null;
};

// In particular, can help detect when placing has ended but a following "drop" hasn't occurred,
// i.e. when placing is false but there is still a lastPlacingItem.
export const getDropSettled = ({ pageBuilder }) =>
  !pageBuilder.placing && !pageBuilder.lastPlacingItem;
