import React from 'react';
import * as PropTypes from 'prop-types';

import Card from 'components/Card/Card';
import { INPUT_TYPES } from 'components/Kizen/Input/styles';
import {
  StyledHeader as Header,
  StyledRow as Row,
  StyledCol as Col,
  StyledFormInput,
  StyledFormRow,
  StyledDateInput,
  StyledFormButton,
  StyledActionsContainer,
} from '../../styles';
import { creditCards } from '../../model';

const CompleteSubscriptionForm = ({ values, onFieldChange, onSubmit }) => {
  return (
    <Card>
      <Header type="header" margin="lg">
        Complete Subscription
      </Header>
      <Row className="no-margin">
        <Col>
          <StyledFormInput
            className="first-row"
            label="Name on Card"
            placeholder="Enter Full Name"
            value={values.card_name}
            onChange={(value) => onFieldChange('card_name', value)}
            required
          />
          <StyledFormInput
            className="credit-card"
            label="Card Number"
            placeholder="Enter Card Number"
            value={values.card_number}
            onChange={(value) => onFieldChange('card_number', value)}
            type={INPUT_TYPES.CREDIT_CARD}
            creditCards={creditCards}
            required
          />
          <StyledFormRow>
            <StyledDateInput
              label="Expiration Date"
              placeholder="MM/DD"
              value={values.expiration_date}
              onChange={(value) => onFieldChange('expiration_date', value)}
              format="MM/DD"
              hideIcon
            />
            <StyledFormInput
              label="CVV/CVC"
              placeholder="Enter CVV/CVC"
              value={values.cvv_cvc}
              onChange={(value) => onFieldChange('cvv_cvc', value)}
              required
            />
          </StyledFormRow>
          <StyledActionsContainer>
            <StyledFormButton
              onClick={onSubmit}
              disabled={
                !values.card_name ||
                !values.card_number ||
                !values.expiration_date ||
                !values.cvv_cvc ||
                (!values.marketing_cloud &&
                  !values.sales_cloud &&
                  !values.experience_cloud &&
                  !values.business_intelligence)
              }
            >
              COMPLETE
            </StyledFormButton>
          </StyledActionsContainer>
        </Col>
      </Row>
    </Card>
  );
};

CompleteSubscriptionForm.propTypes = {
  values: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CompleteSubscriptionForm;
