import styled from '@emotion/styled';
import EventPill from 'components/Kizen/EventPill';
import { isPast } from 'utility/datetime';
import { getEventDataQA } from 'pages/Broadcasts/helpers';
import { isBroadcastInvalid } from '../../helpers';
import { useMemo } from 'react';

export const StyledEventPill = styled(EventPill)`
  margin-bottom: 5px;
  min-height: 21px;
  cursor: pointer;
`;

const CalendarEventPill = ({
  date,
  type,
  name,
  broadcast,
  openBroadcastModal,
  openStatisticsModal,
  ...rest
}) => {
  const isInvalid = useMemo(() => isBroadcastInvalid(broadcast), [broadcast]);
  return (
    <StyledEventPill
      date={date}
      type={type}
      name={name}
      isInvalid={isInvalid}
      onClick={(ev) => {
        // container date cell opens modal on click - stop that handler from being called
        ev.stopPropagation();
        if (isPast(date)) {
          openStatisticsModal(broadcast);
        } else {
          openBroadcastModal(broadcast);
        }
      }}
      {...rest}
      {...getEventDataQA(broadcast, isInvalid)}
    />
  );
};

export default CalendarEventPill;
