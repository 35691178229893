import React from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { grayScale } from 'app/colors';
import { buildTimeIntervals } from 'utility/datetime';
import Select, {
  UnderlinedSelect,
  SingleValue as SingleValueBase,
} from './Select';
import Icon from './Icon';
import StylePassthrough from './StylePassthrough';
import BaseMenu from 'components/Kizen/Menu';
import { Button } from '@kizen/kds/Button';
import { useTranslation } from 'react-i18next';

const Endcap = () => {
  return <Icon icon="clock" size="1x" color={grayScale.mediumDark} />;
};

const ButtonsWrapper = styled.div`
  border-top: 1px solid ${grayScale.medium};
  padding: 13px 15px;
  display: flex;
  justify-content: flex-end;
`;

const Menu = ({ children, ...rest }) => {
  const { onApply } = rest.selectProps;
  const { t } = useTranslation();

  return (
    <BaseMenu {...rest}>
      {children}
      <ButtonsWrapper>
        <Button variant="text" size="xs" onClick={onApply}>
          {t('Apply')}
        </Button>
      </ButtonsWrapper>
    </BaseMenu>
  );
};

// This makes 10px extra room for all time selections when it
// appears within the datetime input of a half-width custom field.
const SingleValueStyles = styled(StylePassthrough)`
  max-width: calc(100% - 25px);
`;

const SingleValue = (props) => (
  <SingleValueStyles>
    <SingleValueBase {...props} />
  </SingleValueStyles>
);

const components = { SingleValue, DropdownIndicator: Endcap };
const applyButtonComponents = { ...components, Menu };

export const TIME_VALUE_FORMAT = 'HH:mm';

export const createOptionFromDatetime = (date) => ({
  value: format(date, TIME_VALUE_FORMAT),
  label: format(date, 'h:mm A'),
});

const options = (() => buildTimeIntervals(15, createOptionFromDatetime))();

export default function TimePicker(props) {
  return (
    <Select
      options={options}
      components={props.onApply ? applyButtonComponents : components}
      {...props}
    />
  );
}

export const OutlinedTimePicker = TimePicker;

export function UnderlinedTimePicker(props) {
  return (
    <UnderlinedSelect options={options} components={components} {...props} />
  );
}
