import { isStandardObject } from 'components/Modals/utilities';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { camelToSnakeCase } from 'services/helpers';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';
import CategorySelect from 'components/Fields/CategorySelect';
import FieldService, { getObjectUrl } from 'services/FieldService';
import { FIELD_TYPES } from 'utility/constants';
import { Panel, VerticalTile } from '@kizen/kds/Tile';
import { Form } from './Form';
import { defaultFieldMetadata } from 'ts-components/RecordLayout/Wizard/FieldSettingsWizard';
import { FieldsLoader, useFieldsLoader } from './FieldsLoader';
import { withErrorBoundary } from '../ErrorBoundary';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { PluginContext } from 'ts-components/Plugins/PluginContext';

interface FieldsBlockProps {
  id: string;
  blockId: string;
  contact: any;
  fieldData: any;
  customObjectModel: any;
  customObject: any;
  fieldState: any;
  initialFieldState: any;
  onChangeFieldState: (...args: any[]) => any;
  validationProps: any;
  recordStatus: any;
  categoryIdWithInvalidField?: string;
  resetCategoryIdWithInvalidField: (...args: any[]) => any;
  validationFunc: (...args: any[]) => any;
  isClient: boolean;
  metadata: any;
  loadFieldsForCategory: (...args: any[]) => any;
  loadingCategory: string[];
  setChosenFieldCategories: (...args: any[]) => any;
  handleFieldBlur: (...args: any[]) => any;
  category: { value: string; label: string };
  setCategory: (...args: any[]) => any;
  errors: any;
}

const setFormExperience = (fieldType: string) =>
  fieldType === FIELD_TYPES.Relationship.type
    ? { useFullExperienceForm: true }
    : {};

const Block = (props: FieldsBlockProps) => {
  const {
    blockId,
    fieldData,
    customObjectModel,
    customObject,
    fieldState,
    initialFieldState,
    onChangeFieldState,
    validationProps,
    recordStatus,
    categoryIdWithInvalidField,
    resetCategoryIdWithInvalidField,
    validationFunc,
    isClient,
    contact,
    metadata,
    loadFieldsForCategory,
    loadingCategory = [],
    setChosenFieldCategories,
    handleFieldBlur,
    category,
    setCategory,
    errors,
  } = props;

  const clientObject = useSelector(getBusinessClientObject);

  const formattedErrors = useMemo(() => {
    if (errors) {
      return Object.keys(errors).map((key) => {
        return {
          fieldId: key,
          message: errors[key],
        };
      });
    }
  }, [errors]);

  const { showSpinner, categoryToShow, setLastChosenCategory } =
    useFieldsLoader(loadingCategory, category);

  const categories = useMemo(() => {
    const {
      autoInclude = true,
      included = [],
      excluded = [],
    } = metadata ?? defaultFieldMetadata;

    return (fieldData || [])
      .filter(({ id }: { id: string }) => {
        return autoInclude
          ? !excluded.some((optId: string) => optId === id)
          : included.some((optId: string) => optId === id);
      })
      .map(({ id, name }: { id: string; name: string }) => ({
        value: id,
        label: name,
      }));
  }, [fieldData, metadata]);

  const handleSetCategory = useCallback(
    (newCategory?: any) => {
      setLastChosenCategory(category);

      if (newCategory) {
        loadFieldsForCategory(newCategory.value);

        setCategory(newCategory);
        setChosenFieldCategories(blockId, newCategory.value);
      }
    },
    [
      setCategory,
      loadFieldsForCategory,
      category,
      setLastChosenCategory,
      setChosenFieldCategories,
      blockId,
    ]
  );

  useEffect(() => {
    if (categories && categories.length && !category) {
      handleSetCategory(categories[0]);
    } else if (category?.value && categoryIdWithInvalidField) {
      const newCategoryOption = categories.find(
        (opt: any) => opt.value === categoryIdWithInvalidField
      );
      resetCategoryIdWithInvalidField();
      validationFunc();
      handleSetCategory(newCategoryOption);
    }
  }, [
    categories,
    category,
    categoryIdWithInvalidField,
    resetCategoryIdWithInvalidField,
    validationFunc,
    handleSetCategory,
  ]);

  const { fields = [] } = useMemo(() => {
    return (
      (fieldData &&
        categoryToShow &&
        (fieldData || []).find(
          ({ id }: { id: string }) => id === categoryToShow?.value
        )) ||
      {}
    );
  }, [categoryToShow, fieldData]);

  const transformedFields = useMemo(() => {
    if (isClient) {
      if (fields.length !== 0) {
        return fields.map((el: any) => {
          const isRelationshipField = setFormExperience(el.fieldType);

          return {
            ...el,
            ...isRelationshipField,
          };
        });
      }
    } else {
      if (fields.length !== 0 && Object.keys(customObjectModel).length !== 0) {
        return fields.map((el: any) => {
          const isRelationshipField = setFormExperience(el.fieldType);

          return {
            ...el,
            name: el.originalSnakeCaseName || camelToSnakeCase(el.name),
            ...isRelationshipField,
          };
        });
      }
    }
    return [];
  }, [customObjectModel, fields, isClient]);

  const fieldsAdditionalProps = useMemo(() => {
    return transformedFields.reduce((acc: any, item: any) => {
      if (
        item.fieldType === FIELD_TYPES.Relationship.type &&
        item.relation.fetchUrl === 'client'
      ) {
        return {
          ...acc,
          [item.id]: {
            categorizedFields: fieldData,
          },
        };
      }
      return acc;
    }, {});
  }, [fieldData, transformedFields]);

  const { dataAdornments } = useContext(PluginContext);

  const head = (
    <div className="w-full pt-[1px]">
      <CategorySelect
        options={categories}
        value={category}
        onChange={handleSetCategory}
        objectId={customObjectModel?.id}
        kdsCompatability
      />
    </div>
  );
  const body = isClient ? (
    <div className="w-full">
      <Form
        target="contact-info"
        fields={transformedFields}
        fetchUrl={getObjectUrl('contacts')}
        object={contact}
        values={fieldState}
        initialValues={initialFieldState}
        onChange={onChangeFieldState}
        serviceToUse={FieldService}
        objectId={clientObject?.id}
        isGetFullObject
        fieldsAdditionalProps={fieldsAdditionalProps}
        validationProps={validationProps}
        popoverMenus
        suppressAutoScroll
        handleFieldBlur={handleFieldBlur}
        errors={formattedErrors}
        adornments={dataAdornments}
      />
    </div>
  ) : (
    <div className="w-full">
      <Form
        fields={transformedFields}
        fetchUrl={`custom-objects/${customObjectModel.id}`}
        object={customObject}
        values={fieldState}
        initialValues={initialFieldState}
        onChange={onChangeFieldState}
        serviceToUse={
          isStandardObject(customObjectModel)
            ? CustomObjectsService
            : PipelineService
        }
        objectId={customObjectModel?.id}
        isGetFullObject
        target="custom-object-record"
        stages={customObjectModel?.pipeline?.stages}
        validationProps={validationProps}
        recordStatus={recordStatus}
        popoverMenus
        suppressAutoScroll
        handleFieldBlur={handleFieldBlur}
        errors={formattedErrors}
        adornments={dataAdornments}
      />
    </div>
  );

  return (
    <FieldsLoader
      isLoading={loadingCategory?.includes(category?.value || '')}
      showSpinner={showSpinner}
    >
      <VerticalTile
        gap={0}
        padding={0}
        middle={<Panel padding={[10, 20, 20, 20]}>{body}</Panel>}
        top={<Panel padding={[15, 20, 0, 20]}>{head}</Panel>}
      />
    </FieldsLoader>
  );
};

export const FieldsBlock = withErrorBoundary(Block);
