import { useRef } from 'react';
import * as PropTypes from 'prop-types';
import FileBlock from './FileBlock';
import {
  StyledFilesList,
  StyledScrollBarDetached,
  FilesScrollContainer,
} from './styles';
//import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';
import { fileShape } from '../defaults';
import { useSyncSizes } from '../../../../hooks/useSyncSizes';

export const FilesList = ({
  files,
  selectedFile,
  setSelectedFile,
  deselectFile,
  disabled,
  viewable,
}) => {
  const scrollbarRef = useRef();
  const [refFn, scrollRef] = useSyncSizes(
    scrollbarRef,
    '.ContentForSyncHeight',
    'height'
  );
  useSyncScroll(useRef([scrollRef, scrollbarRef]), {
    vertical: true,
  });
  return (
    <>
      <FilesScrollContainer ref={refFn}>
        <StyledFilesList className="ContentForSyncHeight">
          {files.map((file) => (
            <FileBlock
              key={file.id}
              file={file}
              onClick={setSelectedFile}
              focused={file.id === selectedFile?.id}
              onDeselectFile={deselectFile}
              disabled={disabled}
              viewable={viewable}
            />
          ))}
        </StyledFilesList>
      </FilesScrollContainer>
      <StyledScrollBarDetached
        ref={scrollbarRef}
        direction="vertical"
        scrollClassName="ContentForSyncHeight"
      />
    </>
  );
};

FilesList.propTypes = {
  files: PropTypes.arrayOf(fileShape),
  selectedFile: PropTypes.objectOf(fileShape),
  setSelectedFile: PropTypes.func,
  deselectFile: PropTypes.func,
  deleteFile: PropTypes.func,
  disabled: PropTypes.bool,
  viewable: PropTypes.bool,
};
