import PropTypes from 'prop-types';
import { useCallback, useContext, useMemo } from 'react';
import WizardStateContext from 'components/Wizards/shared/context/wizardstate';
import Section from 'components/Wizards/shared/components/section';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';
import { implicitSectionPropTypes } from 'components/Wizards/shared/types';
import usePipelineCurrencyData from '../../hooks/usePipelineCurrencyData';
import MainReportType from './reportTypes/Main';
import PipelineValueOverTimeReportType from './reportTypes/PipelineValueOverTime';
import StagesOverTimeReportType from './reportTypes/StagesOverTime';
import { gutters } from 'app/spacing';
import FilterSummary from 'components/Wizards/shared/components/MetaFilters/Summary';
import { areFiltersEnabled } from 'components/Wizards/shared/components/MetaFilters/config';
import {
  isCustomFilter,
  isInGroupFilter,
  isNotInGroupFilter,
} from 'ts-filters/legacy';
import LeadsAddedReportType from './reportTypes/LeadsAdded';
import { AREA_RESPONSES, REPORT_TYPES } from 'components/Wizards/Dashlet/types';
import { useTranslation } from 'react-i18next';
import LeadSourceBreakdownReportType from './reportTypes/LeadSourceBreakdown';
import { DateColumn, FilterRow, FilterSummaryColumn } from './styles';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import DateRangePicker from 'components/Kizen/DateRangePicker/DateRangePicker';
import { ScheduledActivitiesReportType } from './reportTypes/scheduledActivities';
import { useBusinessTimezoneRanges } from 'components/Kizen/DateRangePicker/range';

const getCurrencySymbolEvaluator = (
  pipelineDetail,
  selectedObjectsMetadata
) => {
  if (pipelineDetail?.id) {
    return pipelineDetail;
  }

  return selectedObjectsMetadata?.find((o) => Boolean(o?.trackEntityValue));
};

const FILTERS_WITH_CONSTRAINTS = [
  REPORT_TYPES.LEADS_ADDED_BY_SOURCE,
  REPORT_TYPES.LEADS_ADDED,
  REPORT_TYPES.LEAD_SOURCE_BREAKDOWN,
];

const areFiltersUsingConstraints = (filtersEnabled, reportType) => {
  return filtersEnabled && FILTERS_WITH_CONSTRAINTS.includes(reportType?.value);
};

const ValuesAndContraintsWizardSection = ({
  index,
  active,
  done,
  last,
  mobile,
  scrollActvitiyBuilderIntoView,
  ...rest
}) => {
  const { t } = useTranslation();
  const title = rest.title || t('Values & Constraints');
  const { state, filterCount, setState, filterState, homepage } = useContext(
    WizardStateContext.Context
  );
  const {
    pipelineDetail,
    selectedObjectsMetadata,
    area,
    reportType,
    selectedFilterObject,
    objectsWithLeads,
    dateFilter,
  } = state;

  const { currencySymbol: currencyResult } = usePipelineCurrencyData(
    getCurrencySymbolEvaluator(pipelineDetail, selectedObjectsMetadata)
  );

  useMetadataKey('currencySymbol', currencyResult);

  const handleChangeFilters = useCallback(
    (filters) => {
      setState('customFilters', undefined);
      setState('inGroupFilters', undefined);
      setState('notInGroupFilters', undefined);

      if (isCustomFilter(filters?.details?.type)) {
        setState('customFilters', filters?.details?.filterConfig);
      } else if (isInGroupFilter(filters?.details?.type)) {
        setState(
          'inGroupFilters',
          filters?.details?.groups?.map((g) => g.id)
        );
      } else if (isNotInGroupFilter(filters?.details?.type)) {
        setState(
          'notInGroupFilters',
          filters?.details?.groups?.map((g) => g.id)
        );
      }
    },
    [setState]
  );

  const handleChangeDate = useCallback(
    ([start, end], selectedIndex) => {
      setState('dateFilter', {
        selectedIndex,
        start,
        end,
      });
    },
    [setState]
  );

  const filtersEnabled = useMemo(() => {
    return areFiltersEnabled(area, reportType?.value);
  }, [area, reportType]);

  const handleChangeSelectedFilterObject = useCallback(
    (f) => {
      setState('customFilters', undefined);
      setState('inGroupFilters', undefined);
      setState('notInGroupFilters', undefined);
      setState('selectedFilterObject', f);
    },
    [setState]
  );

  const usingConstraints = areFiltersUsingConstraints(
    filtersEnabled,
    reportType
  );

  const { todayDateInBusinessTimeZone, ranges } =
    useBusinessTimezoneRanges(!!homepage);

  if (area === AREA_RESPONSES.EMAILS) {
    return (
      <>
        <Section
          index={index}
          title={title}
          active={active}
          done={done}
          last={last}
        >
          <StyledWizardField top={0}>
            <FilterRow>
              {homepage && (
                <DateColumn>
                  <StyledWizardField top={usingConstraints ? 5 : 12}>
                    <KizenTypography type="subheader">
                      {t('Dashlet Date Range')}
                    </KizenTypography>
                    <StyledWizardField top={gutters.spacing(2, 2)} flex>
                      <DateRangePicker
                        label={t('Date Range')}
                        title={t('Date Range')}
                        value={
                          dateFilter
                            ? [dateFilter.start, dateFilter.end]
                            : undefined
                        }
                        selectedIndex={dateFilter?.selectedIndex ?? 6}
                        inModal
                        onOk={handleChangeDate}
                        ranges={ranges}
                        todayDate={todayDateInBusinessTimeZone}
                      />
                    </StyledWizardField>
                  </StyledWizardField>
                </DateColumn>
              )}
            </FilterRow>
          </StyledWizardField>
        </Section>
      </>
    );
  }

  return (
    <>
      <Section
        index={index}
        title={title}
        active={active}
        done={done}
        last={last}
      >
        <MainReportType mobile={mobile} active={active || done} />
        <StagesOverTimeReportType mobile={mobile} active={active} />
        <PipelineValueOverTimeReportType
          mobile={mobile}
          active={active || done}
        />
        <LeadsAddedReportType
          mobile={mobile}
          active={active || done}
          bySource
        />
        <LeadsAddedReportType mobile={mobile} active={active || done} />
        <LeadSourceBreakdownReportType
          mobile={mobile}
          active={active || done}
        />
        <ScheduledActivitiesReportType
          mobile={mobile}
          active={active || done}
          scrollActvitiyBuilderIntoView={scrollActvitiyBuilderIntoView}
        />
        <StyledWizardField>
          <FilterRow>
            <FilterSummaryColumn>
              {usingConstraints ? (
                <FilterSummary
                  filterName={t('Dashlet Filters')}
                  filterCount={filterCount}
                  top={5}
                  customObject={
                    selectedObjectsMetadata?.[0]?.fetchUrl === 'client'
                      ? undefined
                      : selectedObjectsMetadata?.[0]
                  }
                  onChange={handleChangeFilters}
                  state={filterState}
                  mobile={mobile}
                  area={area}
                  selectedObject={selectedObjectsMetadata?.[0]}
                  onChangeSelectedObject={handleChangeSelectedFilterObject}
                  disabled={objectsWithLeads?.length !== 1}
                  disabledMessage={t(
                    'Dashlet filters can only be applied to 1 object selection'
                  )}
                />
              ) : (
                <FilterSummary
                  filterName={t('Dashlet Filters')}
                  filterCount={filterCount}
                  top={12}
                  customObject={pipelineDetail}
                  onChange={handleChangeFilters}
                  state={filterState}
                  mobile={mobile}
                  area={area}
                  selectedObject={selectedFilterObject}
                  onChangeSelectedObject={handleChangeSelectedFilterObject}
                />
              )}
            </FilterSummaryColumn>
            {homepage && (
              <DateColumn>
                <StyledWizardField top={usingConstraints ? 5 : 12}>
                  <KizenTypography type="subheader">
                    {t('Dashlet Date Range')}
                  </KizenTypography>
                  <StyledWizardField top={gutters.spacing(2, 2)} flex>
                    <DateRangePicker
                      label={t('Date Range')}
                      title={t('Date Range')}
                      value={
                        dateFilter
                          ? [dateFilter.start, dateFilter.end]
                          : undefined
                      }
                      selectedIndex={dateFilter?.selectedIndex ?? 6}
                      inModal
                      onOk={handleChangeDate}
                      ranges={ranges}
                      todayDate={todayDateInBusinessTimeZone}
                    />
                  </StyledWizardField>
                </StyledWizardField>
              </DateColumn>
            )}
          </FilterRow>
        </StyledWizardField>
      </Section>
    </>
  );
};

ValuesAndContraintsWizardSection.propTypes = {
  ...implicitSectionPropTypes,
  title: PropTypes.string,
  disabled: PropTypes.func,
};
export default ValuesAndContraintsWizardSection;
