import {
  getOptionValue,
  namedToOption,
  relatedfieldIdToOption,
  namedToOptionError,
  getObjectToModifyDeleted,
  getObjectToModifyError,
} from 'services/helpers';

import { ModifyRelatedEntitiesAutomationsActionTypes } from './common';
import {
  checkDeleted,
  _checkDeleted,
  checkListDeleted,
  displayNameDescriptor,
  descriptionDescriptor,
  nameDescriptor,
} from 'pages/AutomationEngine/helpers';

const initialState = {
  type: 'modify_related_entities_automation',
  objectToModify: null,
  automationTargetRelationshipFields: [],
  actionType: '',
  resumePausedAutomations: false,
  automationIds: [],
};

const forApi = (step) => {
  const details = { ...step.details };
  const {
    objectToModify,
    automationTargetRelationshipFields,
    actionType,
    resumePausedAutomations,
    automationIds,
  } = details;

  const api = {
    details: {
      objectToModify: getOptionValue(objectToModify),
      automationTargetRelationshipFields:
        automationTargetRelationshipFields.map((f) => getOptionValue(f)),
      actionType,
      ...(actionType === ModifyRelatedEntitiesAutomationsActionTypes.START && {
        resumePausedAutomations,
      }),
      automationIds: automationIds.map(getOptionValue),
    },
  };

  return api;
};

const forFlow = (apiObject, addErrorMessage, messageDictionary) => {
  const {
    objectToModify,
    automationTargetRelationshipFields,
    actionType,
    resumePausedAutomations,
    automations,
  } = apiObject;

  _checkDeleted({
    messageDictionary,
    addErrorMessage,
    objectToCheck: objectToModify,
    descriptorCallback: descriptionDescriptor,
    objectPath: 'objectToModify',
    objectErrorTrans: getObjectToModifyError,
  });

  checkDeleted(
    messageDictionary,
    addErrorMessage,
    automationTargetRelationshipFields?.[0],
    displayNameDescriptor,
    null,
    'automationTargetRelationshipFields'
  );

  checkListDeleted(
    messageDictionary,
    addErrorMessage,
    automations,
    nameDescriptor,
    messageDictionary.automation,
    'automations',
    namedToOptionError,
    true
  );

  try {
    const flow = {
      objectToModify: getObjectToModifyDeleted(objectToModify),
      automationTargetRelationshipFields: automationTargetRelationshipFields
        .filter(({ deleted }) => !deleted)
        .map(relatedfieldIdToOption),
      actionType,
      resumePausedAutomations,
      automationIds: automations
        .filter(({ deleted }) => !deleted)
        .map(namedToOption),
    };
    return flow;
  } catch (error) {
    addErrorMessage(messageDictionary.thereWasanErrorLoadingTheAssociatedItem);
    return initialState;
  }
};

export const modifyRelatedEntitiesAutomationDTO = {
  forApi,
  forFlow,
};
