import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { FIELD_TYPES } from 'utility/constants';
import { useGetFieldOptions } from 'hooks/useChangeField';
import FieldService, { getObjectUrl } from 'services/FieldService';
import { getDataQAForInput } from 'components/Inputs/helpers';
import Select from 'components/Inputs/Select';
import FieldInput from 'components/Fields/FieldInput';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import { isPipelineObject, isClientObject } from 'components/Modals/utilities';
import PipelineService from 'services/PipelineService';
import CustomObjectsService from 'services/CustomObjectsService';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 17px;
  margin-bottom: 15px;
  & .css-2lho2f-menu {
    position: relative !important;
  }
  .remove-tags-wrapper {
    margin: 18px 0 5px;
  }
`;

const WrapperFieldInput = styled(FieldInput)`
  &.InputControl {
    margin-top: 18px;
    margin-bottom: 5px;
  }
  ${({ field }) =>
    field.fieldType === FIELD_TYPES.Files.type &&
    css`
      margin-top: 18px;
      margin-bottom: 5px;
    `};
`;

const WrapperResolution = styled(Select)`
  margin-top: 20px;
`;

const getResolutionOptions = (t) => [
  {
    label: t('Overwrite'),
    value: 'overwrite',
  },
  {
    label: t('Only Add Options'),
    value: 'add_only',
  },
];

export const ChangeFieldValue = ({
  field,
  fieldValue,
  resolution = null,
  removeTags,
  setField,
  setFieldValue,
  setResolutionValue,
  setRemoveTags,
  model,
}) => {
  const { t } = useTranslation();
  const { options, loading } = useGetFieldOptions({
    customObjectId: model.id,
  });

  const newValuePlaceholder = useMemo(() => {
    if (field) {
      if (field.fieldType === FIELD_TYPES.Relationship.type) {
        let placeholder = '';
        const isClientURL = field?.relation?.fetchUrl === 'client';

        if (FieldService.getAllowMultiple(field)) {
          if (isClientURL) {
            placeholder = t('Contacts');
          } else {
            placeholder = t('Records');
          }
        } else {
          if (isClientURL) {
            placeholder = t('Contact');
          } else {
            placeholder = t('Record');
          }
        }
        return `${t('Find')} ${placeholder}`;
      }

      const typeDictionary = Object.values(FIELD_TYPES).reduce(
        (acc, { type, placeholder, localizedPlaceholder }) => {
          const value = localizedPlaceholder || placeholder;
          if (value) {
            acc = {
              ...acc,
              [type]: {
                placeholder: typeof value === 'function' ? value(t) : value,
              },
            };
          }
          return acc;
        },
        {}
      );

      if (typeDictionary[field.fieldType]) {
        return typeDictionary[field.fieldType].placeholder;
      }
      return field.displayName;
    }
    return null;
  }, [field, t]);

  const handleChangeFieldValue = (data) => {
    let option;
    // intentional info output about current selected field type
    switch (field.fieldType) {
      case FIELD_TYPES.Dropdown.type:
      case FIELD_TYPES.Radio.type:
      case FIELD_TYPES.Status.type: {
        option = field.options.find(({ id }) => id === data);
        break;
      }
      case FIELD_TYPES.Checkboxes.type: {
        option = data.map((item) =>
          field.options.find(({ id }) => id === item)
        );
        break;
      }
      default: {
        option = data;
      }
    }
    setFieldValue(option);
  };

  const handleChangeRemoveTags = (tags) => {
    setRemoveTags(tags);
  };

  const fieldValueByFieldType = useMemo(() => {
    switch (field?.fieldType) {
      case FIELD_TYPES.Checkboxes.type: {
        return fieldValue ? fieldValue.map(({ id }) => id) : [];
      }
      case FIELD_TYPES.Radio.type:
      case FIELD_TYPES.Dropdown.type:
      case FIELD_TYPES.Status.type: {
        return fieldValue?.id || '';
      }
      case FIELD_TYPES.TeamSelector.type: {
        return fieldValue || '';
      }
      case FIELD_TYPES.Relationship.type: {
        return fieldValue || null;
      }
      default: {
        return fieldValue;
      }
    }
  }, [field?.fieldType, fieldValue]);

  const isDynamicTagType =
    field && field.fieldType === FIELD_TYPES.DynamicTags.type;

  const isShowResolutionField = useMemo(() => {
    let showRelation = false;

    if (field && field.fieldType === FIELD_TYPES.Relationship.type) {
      if (
        field?.relation?.fetchUrl === 'client' &&
        field?.relation?.cardinality === 'many_to_one'
      ) {
        showRelation = false;
        setResolutionValue({
          label: 'Overwrite',
          value: 'overwrite',
        });
      } else {
        showRelation = true;
        setResolutionValue(null);
      }
    }

    return (
      field &&
      (showRelation ||
        [FIELD_TYPES.Checkboxes.type, FIELD_TYPES.Files.type].includes(
          field.fieldType
        ))
    );
  }, [field, setResolutionValue]);

  const entityProps = useMemo(() => {
    const isContact = isClientObject(model);
    return isContact
      ? {
          fetchUrl: getObjectUrl('contacts'),
        }
      : {
          serviceToUse: isPipelineObject(model)
            ? PipelineService
            : CustomObjectsService,
          fetchUrl: `custom-objects/${model.id}`,
        };
  }, [model]);

  return (
    <Wrapper>
      <Select
        label={t('Choose Field to Change')}
        placeholder={t('Choose Field')}
        options={options}
        value={
          field
            ? { label: field.displayName, value: field.id, fieldData: field }
            : field
        }
        onChange={(op) => {
          if (
            op.fieldData.fieldType === FIELD_TYPES.TeamSelector.type &&
            !op.fieldData.isDefault
          ) {
            setResolutionValue({ label: 'Overwrite', value: 'overwrite' });
          } else {
            setResolutionValue(null);
          }

          if (removeTags?.length) {
            handleChangeRemoveTags([]);
          }

          setField(op.fieldData);
          setFieldValue('');
        }}
        menuInline
        inModal
        {...getDataQAForInput('value-to-change', 'dropdown')}
        loadItems={loading}
      />
      {field &&
        (isDynamicTagType ? (
          <>
            <WrapperFieldInput
              variant="outline"
              field={field}
              label={t('Choose Options to Add')}
              placeholder={t('Find Options to Add')}
              value={fieldValue}
              onChange={handleChangeFieldValue}
              menuInline
              inModal
              endAdornment={<IconAdornment icon="search" />}
              menuLeftButton={false}
              menuRightButton={false}
              objectId={model?.id}
              {...entityProps}
              {...getDataQAForInput('add-option', 'dropdown')}
            />
            <WrapperFieldInput
              variant="outline"
              field={field}
              label={t('Choose Options to Remove')}
              placeholder={t('Find Options to Remove')}
              fetchUrl={getObjectUrl('contacts')}
              value={removeTags}
              onChange={handleChangeRemoveTags}
              menuInline
              inModal
              endAdornment={<IconAdornment icon="search" />}
              menuTopButton={false}
              menuLeftButton={false}
              menuRightButton={false}
              objectId={model?.id}
              {...entityProps}
              {...getDataQAForInput('remove-option', 'dropdown')}
            />
          </>
        ) : (
          <>
            <WrapperFieldInput
              variant="outline"
              field={field}
              label={t('New Field Value')}
              placeholder={newValuePlaceholder}
              isGetFullObject
              value={fieldValueByFieldType}
              onChange={handleChangeFieldValue}
              menuInline
              inModal
              menuLeftButton={false}
              menuRightButton={false}
              {...entityProps}
              {...getDataQAForInput('new-field', field.fieldType)}
              keepPreviousData={false}
            />
            {isShowResolutionField && (
              <WrapperResolution
                menuInline
                inModal
                label={t('Choose Field Resolution')}
                placeholder={t('Choose Resolution')}
                options={getResolutionOptions(t)}
                value={resolution}
                onChange={(op) => {
                  setResolutionValue(op);
                }}
              />
            )}
          </>
        ))}
    </Wrapper>
  );
};
