import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import Switch from 'components/Kizen/Switch';
import { useBusinessHolidaysCountriesQuery } from 'queries/models/business';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { getWeekdays } from '../helpers';
import { StyledSwitch, StyledSwitches } from '../styled';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import { WEEKDAYS_INDICES } from 'utility/datetime';
import { toastVariant, useToast } from 'components/ToastProvider';

export const LeftColumnControls = ({
  disabled,
  calendarSettings,
  setCalendarSettings,
}) => {
  const { t } = useTranslation();

  const [showToast] = useToast();

  const { data: countries = EMPTY_ARRAY, isLoading: loadingCountries } =
    useBusinessHolidaysCountriesQuery();

  const options = useMemo(
    () =>
      countries.map(({ name, alpha2 }) => ({
        value: alpha2,
        label: t('{{countryName}} Holidays', { countryName: name }),
      })),
    [countries, t]
  );

  const weekdays = useMemo(() => {
    return getWeekdays(t);
  }, [t]);

  const onChangeCountry = ({ value }) =>
    setCalendarSettings((prev) => ({ ...prev, holiday_country: value }));

  const onClearCountry = () =>
    setCalendarSettings((prev) => ({ ...prev, holiday_country: undefined }));

  const onCheckWeekday =
    (day) =>
    ({ target: { checked } }) => {
      setCalendarSettings((prev) => {
        const weekdays = new Set(prev.skip_weekdays);
        if (checked) {
          weekdays.add(day);
        } else {
          weekdays.delete(day);
        }
        if (weekdays.size === 7) {
          showToast({
            variant: toastVariant.ALERT,
            message: t('At least one day of the week must be a work day'),
          });
          return prev;
        }
        return {
          ...prev,
          skip_weekdays: [...weekdays].sort(
            (a, b) => WEEKDAYS_INDICES[a] - WEEKDAYS_INDICES[b]
          ),
        };
      });
    };

  return (
    <>
      <KizenTypography type="header">{t('Business Calendar')}</KizenTypography>
      <StyledSwitches>
        <KizenTypography>{t('Non-Working Days')}</KizenTypography>
        {weekdays.map(({ weekday, weekdayKey }) => (
          <StyledSwitch key={weekdayKey}>
            <Switch
              onClick={onCheckWeekday(weekdayKey)}
              checked={calendarSettings.skip_weekdays.includes(weekdayKey)}
              removeMargin
              disabled={disabled}
            />
            <KizenTypography>{weekday}</KizenTypography>
          </StyledSwitch>
        ))}
      </StyledSwitches>
      <Select
        label={t('Holidays')}
        placeholder={t('Select Country')}
        options={options}
        value={calendarSettings.holiday_country}
        onChange={onChangeCountry}
        isLoading={loadingCountries}
        disabled={disabled}
        menuLeftButton={<ClearSelectButton onClick={onClearCountry} />}
      />
    </>
  );
};
