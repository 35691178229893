import { DEFAULT_LABEL_INDICATOR } from 'components/DashboardGrid/utils';
import { METRIC_TYPES } from 'components/Wizards/shared/components/chart/types';
import { DATE_FILTER_ALL_TIME_INDEX } from 'pages/Dashboard/hooks/useDashboardConfig';
import UUID from 'utility/UUID';
import AxiosService from './AxiosService';
import { camelToSnakeCaseKeys, snakeToCamelCaseKeys } from './helpers';
import { TIMEZONE_OFFSET_HEADER } from './constants';
import { getTimezoneOffset } from './utils';

export const dashletForAPI = ({ name, ...dashlet }, layoutUpdate = false) => {
  if (layoutUpdate) {
    return camelToSnakeCaseKeys(dashlet);
  }
  return camelToSnakeCaseKeys({
    ...dashlet,
    config: {
      // An object_id is required for the API to work. If it doesn't exist on the
      // dashlet, we'll create a random one because as long as it is a valid UUID
      // the API will accept it.
      object_id: UUID.generate(),
      chart_type: '',
      ...dashlet.config,
    },
    name: name === '' || name === null ? DEFAULT_LABEL_INDICATOR : name,
  });
};

export const dashletForApp = ({ name, id, layout, ...dashlet }) =>
  snakeToCamelCaseKeys({
    ...dashlet,
    // This condition is strictly for backwards compatibility
    // At one point, we switched how we input data to the dashboard's grid
    // component such that we depended on the dashlet layout's i
    // property being always equal to the dashlet's id. This change broke
    // pre-existing dashlets, since we previously allowed those 2 ids to
    // diverge (albeit temporarily, since we'd still eventually sync them)
    ...(id !== layout.i
      ? {
          layout: {
            ...layout,
            i: id,
          },
        }
      : { layout }),
    id,
    name: name === DEFAULT_LABEL_INDICATOR ? '' : name,
  });

class DashletService {
  /*
   * Singleton ES6 pattern
   * https://www.sitepoint.com/javascript-design-patterns-singleton/
   */
  constructor() {
    if (!DashletService.instance) {
      DashletService.instance = this;
    }
    return DashletService.instance;
  }

  endpoint = '/dashboards';

  async addDashlet(dashboardId, payload) {
    const url = `${this.endpoint}/${dashboardId}/dashlet`;
    const response = await AxiosService.post(url, dashletForAPI(payload), {
      headers: { [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset() },
    });
    return dashletForApp(response.data);
  }

  async updateDashlet(payload, layoutUpdate) {
    const { id, dashboard, ...others } = payload;
    const url = `${this.endpoint}/${dashboard}/dashlet/${id}`;
    const response = await AxiosService.patch(
      url,
      dashletForAPI(others, layoutUpdate)
    );
    return dashletForApp(response.data);
  }

  async deleteDashlet(dashboardId, dashletId) {
    const url = `${this.endpoint}/${dashboardId}/dashlet/${dashletId}`;
    return AxiosService.delete(url);
  }

  async getDashletSummary({
    dashboardId,
    dashletId,
    dateFilter,
    teamFilter,
    currentEmail,
    search,
    filterInfo = {},
    bustCache = false,
  }) {
    const url = `${this.endpoint}/${dashboardId}/dashlet/${dashletId}/data`;
    const payload = camelToSnakeCaseKeys(filterInfo) || {};

    if (bustCache) {
      payload.refresh_now = true;
    }

    const params = {
      search: search ? encodeURIComponent(search) : undefined,
      email_id: currentEmail,
      refresh_now: bustCache ? 'true' : undefined,
    };

    if (
      dateFilter?.start &&
      dateFilter?.end &&
      dateFilter?.selectedIndex !== DATE_FILTER_ALL_TIME_INDEX
    ) {
      payload.from_date = dateFilter.start;
      payload.to_date = dateFilter.end;
    }
    if (teamFilter?.teamMembers?.length > 0) {
      payload.employee_ids = teamFilter.teamMembers;
    }
    if (teamFilter?.roles?.length > 0) {
      payload.role_ids = teamFilter.roles;
    }
    const response = await AxiosService.post(url, payload, {
      skipErrorBoundary: true,
      params,
      headers: {
        [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
      },
    });
    return response.data;
  }

  async getPivotTableSubRows({
    dashboardId,
    dashletId,
    rows,
    tableId,
    search,
    dateFilter,
    teamFilter,
    filterInfo,
    currentEmail,
  }) {
    const url = `${this.endpoint}/${dashboardId}/dashlet/${dashletId}/data/subrows`;
    const params = {
      search: search ? encodeURIComponent(search) : undefined,
      email_id: currentEmail ? currentEmail : undefined,
    };
    const payload = camelToSnakeCaseKeys(filterInfo) || {};

    // API expects certain mapped values for null, true, and false
    const mappedNullRowId = 'ffffffff-ffff-ffff-ffff-ffffffffffff';
    const mappedTrueRowId = 1;
    const mappedFalseRowId = 0;

    payload.table_id = tableId;
    payload.rows = rows.map((row) => {
      if (row === 'null') {
        return mappedNullRowId;
      } else if (row === 'true') {
        return mappedTrueRowId;
      } else if (row === 'false') {
        return mappedFalseRowId;
      }
      return row;
    });

    if (
      dateFilter?.start &&
      dateFilter?.end &&
      dateFilter?.selectedIndex !== DATE_FILTER_ALL_TIME_INDEX
    ) {
      payload.from_date = dateFilter.start;
      payload.to_date = dateFilter.end;
    }
    if (teamFilter?.teamMembers?.length > 0) {
      payload.employee_ids = teamFilter.teamMembers;
    }
    if (teamFilter?.roles?.length > 0) {
      payload.role_ids = teamFilter.roles;
    }
    try {
      const response = await AxiosService.post(url, payload, {
        skipErrorBoundary: true,
        params,
      });
      return { data: response.data, error: false };
    } catch (error) {
      return {
        data: {},
        error: true,
        response: error,
      };
    }
  }

  async getEmailList(
    dashboardId,
    dashletId,
    size = 20,
    urlWithCursor,
    searchTerm,
    sort,
    dateFilter
  ) {
    const url =
      urlWithCursor ||
      `${
        this.endpoint
      }/${dashboardId}/dashlet/${dashletId}/email/interaction-stats-dashlet-list?size=${size}${
        searchTerm ? `&search=${searchTerm}` : ''
      }&ordering=${sort.direction === 'asc' ? '' : '-'}${sort.column}${
        dateFilter?.start ? `&from_date=${dateFilter.start}` : ''
      }${dateFilter?.end ? `&to_date=${dateFilter.end}` : ''}`;

    const response = await AxiosService.get(url, {
      skipErrorBoundary: true,
    });

    return response.data;
  }

  isRevenueV2 = (config) => {
    const { metricType } = config;
    return metricType === METRIC_TYPES.VALUE;
  };
}

const instance = new DashletService();
Object.freeze(instance);

export default instance;
