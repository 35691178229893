import { useTooltip } from 'components/Kizen/Tooltip';
import { InfoIcon } from './styled';

export const IconWithTooltip = ({
  label,
  icon = 'info-circle',
  iconSize = null,
  placement = 'top',
  className = '',
  ...rest
}) => {
  const [tooltipProps, tooltip] = useTooltip({
    label,
    placement,
  });

  return (
    <>
      <InfoIcon
        icon={icon}
        iconSize={iconSize}
        {...tooltipProps}
        className={className}
        {...rest}
      />
      {tooltip}
    </>
  );
};
