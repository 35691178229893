import styled from '@emotion/styled';
import { gutters, breakpoints } from 'app/spacing';

export const FieldLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); // Force equal col widths
  gap: 0px ${gutters.standard};
  padding-bottom: ${gutters.spacing(
    5
  )}px; // for correct display validation message for last fields

  // half-width components need no styles,
  // full-width components need this class:
  .span2 {
    grid-column: span 2;
  }

  @media (max-width: ${breakpoints.sm}px) {
    // Make all items full-width on small screen sizes
    .span1 {
      grid-column: span 2;
    }
  }

  // Don't display unsupported fields
  > :empty {
    display: none;
  }
`;
