import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

import classNames from 'classnames';

import Input from 'components/Kizen/Input';
import AuthForm from 'components/Kizen/AuthForm';
import Button from 'components/Button';
import { KizenTypography } from 'app/typography';

import loginCounter from './LoginCounter';

import { useLoginState } from './LoginContext';
import { validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';
import { styles } from './common';

import { NOBUSINESS_ERROR_CODE } from 'services/AxiosService';
import { loginSessionStorage } from 'utility/loginSessionStorage';
import { hasAnyBusinesses } from 'store/authentication/helpers';

export const ChooseEmail = () => {
  const { setEmail, email, errorMessage, setErrorMessage } = useLoginState();

  const { t } = useTranslation();

  const [username, setUsername] = useState(loginSessionStorage.val);

  const formError = useSelector(
    ({ authentication }) => authentication.errors.login
  );

  const loginErrMessage = useMemo(() => {
    if (formError?.status === 403) {
      return t(
        'Your account is temporarily locked due to too many failed login attempts. Please wait and try again or click "Need Help Logging In?" above to reset your password.'
      );
    }

    if (loginCounter.isAllow(email)) {
      return t(
        'Incorrect password. Please try again or click "Need Help Logging In?" above to reset it.'
      );
    }

    return t(
      'Incorrect password. Further failed login attempts may temporarily lock your account. Please try again or click "Need Help Logging In?" above to reset it.'
    );
  }, [email, formError, t]);

  useEffect(() => {
    if (formError) {
      const defaultMessage = (
        <Trans
          ns="translation"
          i18nKey="An unknown error has occurred, please try again. If you continue to experience issues logging in, please contact our team at <emailLink>support@kizen.com</emailLink> for assistance."
          components={{
            emailLink: (
              <KizenTypography
                type="link-mail"
                size="micro"
                weight="bold"
                href="mailto:support@kizen.com"
              />
            ),
          }}
        />
      );
      const { name, payload, status } = formError;

      if (name !== 'AuthenticationServiceError' || !payload) {
        return setErrorMessage(defaultMessage);
      }

      // 400 or 403 show log in err message
      if ([400, 403].includes(status)) {
        return setErrorMessage(loginErrMessage);
      }

      if (!hasAnyBusinesses(payload?.user)) {
        const error = new Error('No Business Associated');
        error.code = NOBUSINESS_ERROR_CODE;
        throw error;
      }

      // anything else show the default
      return setErrorMessage(defaultMessage);
    }

    return setErrorMessage('');
  }, [formError, t, loginErrMessage, setErrorMessage]);

  const isShowInactivityMessage = useSelector(
    ({ authentication }) => authentication.isShowInactivityMessage
  );

  const isShowTimeoutMessage = useSelector(
    ({ authentication }) => authentication.isShowTimeoutMessage
  );

  const showMessage = isShowTimeoutMessage || isShowInactivityMessage;
  const submitEmail = useCallback(
    (email) => {
      if (validateEmail(email)) {
        loginSessionStorage.val = email;
        setEmail(email);
      }
    },
    [setEmail]
  );

  const timeoutMessage = useMemo(() => {
    if (isShowTimeoutMessage) {
      return t(
        'You have been logged out for security reasons due to overall session timeout. Please Login again.'
      );
    } else if (isShowInactivityMessage) {
      return t(
        'You have been logged out due to inactivity. Please Login again.'
      );
    }

    return '';
  }, [isShowTimeoutMessage, isShowInactivityMessage, t]);

  return (
    <AuthForm styles={styles} isShowInactivityMessage={showMessage}>
      {showMessage ? (
        <KizenTypography
          as="h2"
          size="header"
          className="Auth-inactivity-message"
        >
          {timeoutMessage}
        </KizenTypography>
      ) : null}
      <div
        className={classNames({
          'Authform-wrapper': !showMessage,
          'Authform-wrapper-with-message': showMessage,
        })}
      >
        <form action="">
          <KizenTypography
            type="header"
            className="Authform-header"
            data-qa="login-header"
          >
            {t('Login to Kizen')}
          </KizenTypography>

          <Input
            value={username}
            label={t('Email Address')}
            type={Input.INPUT_TYPES.EMAIL}
            required
            className="Authform-input"
            placeholder={t('Enter your email')}
            onChange={setUsername}
            id="username"
            autoComplete="username"
            name="username"
          />

          <KizenTypography
            type="link"
            weight="bold"
            textTransform="uppercase"
            to="/reset"
            className="Login-help"
          >
            {t('Need help logging in?')}
          </KizenTypography>

          <div className="Authform-actions">
            <div></div>

            <Button
              className="Authform-button"
              onClick={() => {
                submitEmail(username);
              }}
              data-qa="continue-button"
              disabled={!username || !validateEmail(username)}
            >
              {t('Continue')}
            </Button>
          </div>
          {errorMessage ? (
            <KizenTypography className="Authform-error">
              {errorMessage}
            </KizenTypography>
          ) : (
            false
          )}
        </form>
      </div>
    </AuthForm>
  );
};
