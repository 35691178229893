import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import KizenTypography from 'app/kizentypo';
import { breakpoints, gutters } from 'app/spacing';
import BasicModal from 'components/Modals/presets/BasicModal';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import Loader from 'components/Kizen/Loader';
import TimelineService from 'services/TimelineService';
import { TextEllipsis } from 'components/Kizen/Table';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';

const ModalContentText = styled(KizenTypography)`
  margin-top: ${gutters.spacing(2, 3)}px;
  margin-bottom: ${gutters.spacing(2, 1)}px;
`;

const ModalBoldText = styled(KizenTypography)`
  margin-bottom: ${gutters.spacing(2, 3)}px;
`;

const ModalContentWrapper = styled.div`
  margin-top: -${gutters.spacing(2, 1)}px;
`;

const ModalContentDiv = styled.div`
  &&:first-child {
    margin-bottom: ${gutters.spacing(4, 5)}px;
  }
`;

const StyledIconWithTooltip = styled(IconWithTooltip)`
  margin-left: 5px;
`;

const getClientLink = (id) => `/client/${id}/details`;
const getCOLink = (coId, recordId) =>
  `/custom-objects/${coId}/${recordId}/details`;

const InfoLabel = styled.span`
  display: inline-block;
  text-align: left;
  font-size: inherit;
  width: calc(100vw - ${gutters.spacing(6)}px);
  @media screen and (min-width: ${breakpoints.sm}px) {
    width: 220px;
  }
`;

export default function RelationshipDetailsModal({
  event,
  ...relationModalProps
}) {
  const { t } = useTranslation();

  const {
    value: { primaryCustomObject, primaryEntityRecord, relatedRecords } = {
      primaryCustomObject: null,
      primaryEntityRecord: null,
      relatedRecords: null,
    },
    loading,
  } = useAsync(async () => {
    try {
      const result = await TimelineService.getTimelineRelationships(event.id);
      return {
        primaryCustomObject: result.primaryRecord?.customObject,
        primaryEntityRecord: result.primaryRecord?.entityRecord,
        relatedRecords: result.relatedRecords,
      };
    } catch (err) {
      return null;
    }
  }, [event.id]);

  return (
    <BasicModal
      heading={t('Timeline Entry Details')}
      buttonText={t('Close')}
      actionBtnColor="blue"
      leftBtn={null}
      {...relationModalProps}
    >
      <Loader loading={loading}>
        <ModalContentWrapper>
          <ModalContentDiv>
            <ModalBoldText weight="bold">
              {t('Primary Record')}
              <StyledIconWithTooltip
                label={
                  <InfoLabel>
                    {t(
                      'This is the record that had the original timeline event.'
                    )}
                  </InfoLabel>
                }
                placement="right"
              />
            </ModalBoldText>
            <ModalContentText>
              {primaryCustomObject?.objectName}
            </ModalContentText>
            <TextEllipsisWithTooltip
              key={primaryEntityRecord?.id}
              type="anchor"
              href={
                primaryCustomObject?.objectName === 'Contacts'
                  ? getClientLink(primaryEntityRecord?.id)
                  : getCOLink(primaryCustomObject?.id, primaryEntityRecord?.id)
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {primaryEntityRecord?.displayName || primaryEntityRecord?.name}
            </TextEllipsisWithTooltip>
          </ModalContentDiv>

          {relatedRecords && (
            <>
              <ModalContentDiv>
                <ModalBoldText weight="bold">
                  {t('Related Records')}
                  <StyledIconWithTooltip
                    label={
                      <InfoLabel>
                        {t(
                          'This is a list of records that had the timeline event propagated via timeline sharing.'
                        )}
                      </InfoLabel>
                    }
                    placement="right"
                  />
                </ModalBoldText>
                {relatedRecords.map((el) => (
                  <>
                    <ModalContentText>
                      {el.customObject.objectName}
                    </ModalContentText>
                    {el.entityRecords.map((record, i) => (
                      <>
                        <TextEllipsisWithTooltip
                          key={record.id}
                          type="anchor"
                          href={
                            el.customObject.objectName === 'Contacts'
                              ? getClientLink(record.id)
                              : getCOLink(el.customObject.id, record.id)
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {record.displayName || record.name}
                        </TextEllipsisWithTooltip>
                        <TextEllipsis>
                          {el.entityRecords.length - 1 === i ? '' : ',\u00A0'}
                        </TextEllipsis>
                      </>
                    ))}
                  </>
                ))}
              </ModalContentDiv>
            </>
          )}
        </ModalContentWrapper>
      </Loader>
    </BasicModal>
  );
}
