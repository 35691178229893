import { createContext } from 'react';
import { PanelCount, TileDimensions } from './types';
import { Spacing } from '../spacing';

interface TileContextType {
  orientation: 'horizontal' | 'vertical';
  panelCount: PanelCount;
  spacerCount: number;
  padding: Spacing;
  gap: Spacing;
  dimensions?: TileDimensions;
  divideEvenly?: boolean;
}

export const TileContext = createContext<TileContextType>({
  orientation: 'horizontal',
  panelCount: 1,
  spacerCount: 0,
  padding: 0,
  gap: 0,
});
