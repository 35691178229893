import ResponsiveGridLayout from './ResponsiveGridLayout';
import { GridWrapper } from './styles';
import { DASHBOARD_BREAKPOINTS, DASHBOARD_COLUMNS } from './types';
import { useMemo } from 'react';
import { flow, last, sortBy } from 'lodash';

const DashboardGrid = ({
  children,
  updateLayouts,
  resizeHandles,
  rowHeight,
  editable,
  setBreakpoint,
  setContainerWidth,
}) => {
  // in case we use measureBeforeMount we need to calculate
  // the minimum height of to avoid scroll to top on zero height
  const minHeight = useMemo(() => {
    const layouts = children.map((child) => {
      const props = child.props;

      return {
        h: props['data-qa-dashlet-position-h'],
        i: props['data-qa-dashlet-position-i'],
        w: props['data-qa-dashlet-position-w'],
        x: props['data-qa-dashlet-position-x'],
        y: props['data-qa-dashlet-position-y'],
      };
    });

    const highest = flow(
      (layouts) => sortBy(layouts, [({ y }) => y, ({ h }) => h]),
      last
    )(layouts || []);

    if (!highest || !rowHeight) {
      return 0;
    }

    return (highest.y + highest.h) * rowHeight;
  }, [children, rowHeight]);

  return (
    <GridWrapper
      rowHeight={rowHeight}
      enableTouchActions={!editable}
      minHeight={minHeight || rowHeight}
    >
      <ResponsiveGridLayout
        className="layout"
        key={`responsive-grid-layout-children-${children?.length}`}
        breakpoints={DASHBOARD_BREAKPOINTS}
        cols={DASHBOARD_COLUMNS}
        onBreakpointChange={setBreakpoint}
        isDraggable={editable}
        isResizable={editable}
        compactType="vertical"
        measureBeforeMount
        onDragStop={updateLayouts}
        onResizeStop={updateLayouts}
        draggableCancel=".no-drag"
        draggableHandle=".drag-handle"
        resizeHandles={resizeHandles}
        onWidthChange={setContainerWidth}
        ref={(c) => {
          setContainerWidth(c?.elementRef?.current?.offsetWidth);
        }}
        // We can't use the CSS transforms, otherwise the grid item shadows
        // will appear on top of the adjacent dashlets in some cases
        useCSSTransforms={false}
        rowHeight={rowHeight}
      >
        {children}
      </ResponsiveGridLayout>
    </GridWrapper>
  );
};

export default DashboardGrid;
