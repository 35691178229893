import {
  isChartValid,
  isDashletValid,
} from 'components/DashboardGrid/validation';
import { ButtonGroupImageButton } from 'components/Inputs/ButtonGroup/Button';
import Icon from 'components/Kizen/Icon';
import BasicModal from 'components/Modals/presets/BasicModal';
import useModal from 'components/Modals/useModal';
import DashletWizard from 'components/Wizards/Dashlet';
import RTDVWizard from 'components/Wizards/RTDV';
import useWizardInModal from 'components/Wizards/shared/hooks/useWizardInModal';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockTypeModal, ButtonGrid, CompoundIcon } from './styles';
import { HomepageBuilder } from 'ts-components/StaticBlockBuilder/HomepageBuilder';
import { useModalClickedState } from 'components/DashboardGrid/hooks/useModalClickedState';
import { useSelector } from 'react-redux';
import { getBusinessClientObject } from 'store/authentication/selectors';

const hasAccess = true;

const BlockModal = (props) => {
  const { existing = {}, onConfirm, ...rest } = props;
  const [chosenOption, setChosenOption] = useState(null);
  const [modalSize, setModalSize] = useState('medium');
  const [builderOpen, setBuilderOpen] = useState(false);
  const [builderData, setBuilderData] = useState(null);

  const { t } = useTranslation();

  const { modalProps, wizardProps } = useWizardInModal(
    (chart) => onConfirm(chart, builderData),
    // The initial modal is always valid
    (state) => {
      if (chosenOption === 'dashlet') {
        return isDashletValid(state);
      }
      if (chosenOption === 'chart') {
        return isChartValid(state);
      }
      return false;
    },
    existing
  );

  const isEditing = Object.keys(existing).length > 0;

  const options = useMemo(() => {
    return [
      {
        value: 'dashlet',
        label: t('Dashlet (Time Series)'),
        image: <Icon icon="chart-line" />,
      },
      {
        value: 'chart',
        label: t('Chart (Object Data)'),
        image: <Icon icon="chart-pie-light" />,
      },
      {
        value: 'static',
        label: t('Static Content (Custom Builder)'),
        image: (
          <CompoundIcon>
            <Icon icon="text" />
            <Icon icon="image-landscape" />
          </CompoundIcon>
        ),
      },
    ];
  }, [t]);

  const clientObject = useSelector(getBusinessClientObject);

  const [addDashletModalProps, addDashletModalTriggerProps] = useModal();

  const secondaryModalHeading = (() => {
    const noun = chosenOption === 'dashlet' ? t('Dashlet') : t('Chart');
    return isEditing
      ? t('Edit {{noun}}', { noun })
      : t('Add New {{noun}}', { noun });
  })();

  const { clicked, handleClickConfirm } = useModalClickedState(
    modalProps?.onConfirm
  );

  return (
    <>
      {/* This is the wizard for building the block,
	  determined by which option they chose in the first modal (below) */}
      {builderOpen && (
        <HomepageBuilder
          onCancel={() => {
            setBuilderOpen(false);
            setChosenOption(null);
            props.onHide();
          }}
          onSave={(data) => {
            setBuilderData(data);
            onConfirm(undefined, data);
          }}
          onSaveAndClose={(data) => {
            setBuilderOpen(false);
            onConfirm(undefined, data);
            props.onHide();
          }}
          modalLayer={1}
        />
      )}
      <BasicModal
        defaultLeftBtnText={hasAccess ? t('Cancel') : undefined}
        leftBtn={hasAccess ? undefined : false}
        buttonText={hasAccess ? (isEditing ? t('Save') : t('Add')) : t('Close')}
        actionBtnColor={hasAccess ? 'green' : 'blue'}
        className="no-drag"
        fitContent={hasAccess ? false : 'y'}
        heading={secondaryModalHeading}
        size={hasAccess ? modalSize : undefined}
        disabled={(hasAccess ? modalProps.disabled : false) || clicked}
        typeOfContent={hasAccess ? modalProps.typeOfContent : 'text'}
        {...addDashletModalProps}
        onConfirm={hasAccess ? handleClickConfirm : undefined}
        onHide={rest.onHide}
        animateSizeChange
      >
        {chosenOption === 'dashlet' ? (
          <DashletWizard
            homepage
            existing={existing}
            setModalSize={setModalSize}
            {...wizardProps}
          />
        ) : null}
        {chosenOption === 'chart' ? (
          <RTDVWizard
            homepage
            existing={existing}
            setModalSize={setModalSize}
            modalSize={modalSize}
            clientObjectId={clientObject.id}
            {...wizardProps}
          />
        ) : null}
      </BasicModal>

      {/* This is the default modal shown to the user, to select
	  from the 3 types of blocks they can add */}
      {isEditing ? null : (
        <BlockTypeModal
          actionBtnColor={'blue'}
          className="no-drag"
          heading={t('Add New Block')}
          buttonText={t('Cancel')}
          leftBtn={false}
          size="medium"
          {...modalProps}
          {...rest}
          disabled={false}
          modalBodyProps={{
            typeOfContent: 'no-paddings',
          }}
          onConfirm={rest.onHide}
        >
          <ButtonGrid>
            {options.map((option) => {
              return (
                <ButtonGroupImageButton
                  key={option.value}
                  selected={chosenOption === option.value}
                  option={option}
                  onClick={() => {
                    setChosenOption(option.value);
                    if (option.value === 'static') {
                      setBuilderOpen(true);
                    } else {
                      addDashletModalTriggerProps.onClick();
                    }
                  }}
                />
              );
            })}
          </ButtonGrid>
        </BlockTypeModal>
      )}
    </>
  );
};

export default BlockModal;
