import { useBuilderContext } from 'components/PageBuilder/BuilderContext';
import React from 'react';
import {
  SectionSettings,
  ElementContainerSettingsSection,
} from '../../settings';

const DefaultMaxWidth = '900';

export const EmailSectionSettings = ({ node }) => {
  const { rootNode } = useBuilderContext();

  return (
    <>
      <SectionSettings
        node={node}
        enableAlignment={false}
        defaultMaxWidth={rootNode?.data?.props?.maxWidth ?? DefaultMaxWidth}
      />
      <ElementContainerSettingsSection
        node={node}
        defaultBackgroundPositionX="center"
        defaultBackgroundPositionY="top"
        enableMargin={false}
        enableBorderRadius={false}
      />
    </>
  );
};

export const FormSectionSettings = ({ node }) => {
  const { rootNode } = useBuilderContext();

  return (
    <>
      <SectionSettings
        node={node}
        defaultMaxWidth={rootNode?.data?.props?.maxWidth ?? DefaultMaxWidth}
      />
      <ElementContainerSettingsSection node={node} />
    </>
  );
};
