import React, { useState, useEffect, useRef } from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { grayScale, colorsButton, shadowLight } from 'app/colors';
import { gutters, borderRadii } from 'app/spacing';
import EditableText from 'components/Kizen/EditableText';
import Icon from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import Input from 'components/Kizen/Input';
import useField from 'hooks/useField';
import { fontSizes, fontWeights, KizenTypography } from 'app/typography';
import useModal from 'components/Modals/useModal';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import Validation, { useValidation } from 'components/Inputs/Validation';

export const categoryMargin = gutters.spacing(4) / 2;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${grayScale.white};
  border: 1px solid ${grayScale.medium};
  border-radius: ${borderRadii.small};
  margin: ${categoryMargin}px;
  min-width: 0;
  ${({ dragging }) => dragging && shadowLight}
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 30px;
  margin: ${gutters.spacing(2)}px;
  margin-bottom: 0;
  // Drag icon
  > *:first-child {
    width: 15px;
    cursor: grab;
  }
  // Delete icon
  > *:last-child {
    width: 15px;
    svg {
      width: 15px;
      height: auto;
    }
  }
`;

const EditableNameText = styled(EditableText)`
  align-self: flex-end;
  margin: 0 ${gutters.spacing(3)}px;
  min-width: 0;
  max-width: 350px;
  input {
    font-size: ${fontSizes.text};
    font-weight: ${fontWeights.bold};
  }
`;

const DeleteIconButton = styled(IconButton)`
  ${({ visible }) =>
    !visible &&
    css`
      visibility: hidden;
    `}
`;

export default function BuilderCategory(props) {
  const {
    category,
    onConfirmDelete,
    onChangeName,
    handleProps,
    allowDeletion,
    children,
    ...others
  } = props;
  const { t } = useTranslation();
  const [confirmDeletionModalProps, confirmDeletionModalTriggerProps] =
    useModal({
      handleSubmit: async () => {
        await onConfirmDelete(category);
      },
    });
  const [stagedName, setStagedName] = useField(category.name, [category]);
  const wrapperRef = useRef();
  const [validation, validationProps] = useValidation(wrapperRef, {
    value: stagedName,
    validate: {
      full: (desc) => {
        if (!desc.trim()) {
          return t('This field is required');
        }
        if (onChangeName) {
          return onChangeName(stagedName, category);
        }
        return true;
      },
    },
  });

  return (
    <CategoryWrapper {...others}>
      <Header>
        <Icon icon="reorder" color={colorsButton.iconGray} {...handleProps} />
        <EditableNameText
          ref={wrapperRef}
          value={stagedName}
          onChange={setStagedName}
          {...validation}
        />
        <Validation inModal {...validationProps} />
        <DeleteIconButton
          visible={allowDeletion}
          sizing="dense"
          title={t('Remove Category')}
          color={colorsButton.iconGray}
          {...confirmDeletionModalTriggerProps}
        >
          <Icon icon="delete" />
        </DeleteIconButton>
      </Header>
      {children}
      <ConfirmDeletionModal
        headerText={t('Please Confirm Deletion')}
        confirmButtonText={t('Confirm Delete')}
        {...confirmDeletionModalProps}
      >
        {t(
          'This will permanently delete the Category, all associated Fields, and all associated data from your database.'
        )}
      </ConfirmDeletionModal>
    </CategoryWrapper>
  );
}

BuilderCategory.propTypes = {
  category: PropTypes.object.isRequired,
  handleProps: PropTypes.object.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  allowDeletion: PropTypes.bool.isRequired,
};

export const CategoryPlaceholder = styled.div`
  position: absolute;
  top: ${categoryMargin}px;
  bottom: ${categoryMargin}px;
  left: ${categoryMargin}px;
  right: ${categoryMargin}px;
  background-color: ${grayScale.light};
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.small};
`;

const NewCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 113px;
  align-items: center;
  margin: ${categoryMargin}px;
  border: 1px dashed ${grayScale.medium};
  border-radius: ${borderRadii.small};
  cursor: pointer;
  > div:first-child {
    margin-top: ${gutters.spacing(4)}px;
    margin-bottom: ${gutters.spacing(6, -13)}px;
  }
  color: ${colorsButton.green.default};
  &:hover {
    color: ${colorsButton.green.hover};
  }
`;

export function NewCategory({ onConfirmCreate, ...others }) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [modalProps, triggerProps, modal] = useModal({
    handleSubmit: async () => {
      await onConfirmCreate({ name });
    },
  });
  useEffect(() => {
    setName('');
  }, [modal.showing]);

  return (
    <>
      <NewCategoryWrapper {...triggerProps} {...others}>
        <KizenTypography
          as="div"
          weight="bold"
          size="buttonLabel"
          textTransform="uppercase"
          color="inherit"
        >
          {t('Add New Category')}
        </KizenTypography>
        <KizenTypography as="div" size="48px" color="inherit">
          +
        </KizenTypography>
      </NewCategoryWrapper>
      <ConfirmationModal
        heading={t('Add Category')}
        leftBtn={false}
        {...modalProps}
      >
        <div style={{ textAlign: 'left' }}>
          <Input
            autoFocus
            label={t('Category Name')}
            placeholder={t('Enter Category Name')}
            value={name}
            onChange={setName}
          />
        </div>
      </ConfirmationModal>
    </>
  );
}

NewCategory.propTypes = {
  onConfirmCreate: PropTypes.func.isRequired,
};
