import { settingsUrlBase } from 'pages/Settings/paths';

const base = `${settingsUrlBase}/custom-fields`;

const paths = new Map([
  ['contacts', `${base}/contacts/:field_id?`],
  ['products', `${base}/products`],
  ['custom-objects/:id?', `${base}/custom-objects/:id?/:field_id?`],
]);

export default paths;
