import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import KizenTypography from 'app/kizentypo';
import Button from 'components/Button';
import GenericWizard from 'components/GenericWizard';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  _modalSize,
} from 'components/Modals';
import BasicModal from 'components/Modals/presets/BasicModal';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import { useModalControl } from 'hooks/useModalControl';
import { useWindowSize } from 'react-use';

const EMPTY_ACTIVITY = {};

export const ConfirmNoteEdits = ({ onCancel, onDiscard, onSave }) => {
  const { t } = useTranslation();

  return (
    <Modal show size="small" onHide={onCancel}>
      <ModalHeader onClickClose={onCancel}>
        {t('Your Note Has Unsaved Changes')}
      </ModalHeader>
      <ModalBody typeOfContent="text">
        <KizenTypography>
          {t('The note has unsaved changes. How would you like to proceed?')}
        </KizenTypography>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onDiscard} color="red">
          {t('Discard Changes')}
        </Button>
        <Button onClick={onSave} color="green">
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ConfirmNoteEditsWithMentions = ({
  onCancel,
  onDiscard,
  onSave,
  onSaveAndNotify,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show size="medium" onHide={onCancel}>
      <ModalHeader onClickClose={onCancel}>
        {t('Your Note Has Unsaved Changes')}
      </ModalHeader>
      <ModalBody typeOfContent="text">
        <KizenTypography>
          {t(
            'The note has unsaved changes and has a team member mention/notificaiton. How would you like to proceed?'
          )}
        </KizenTypography>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onDiscard} color="red">
          {t('Discard Changes')}
        </Button>
        <Button onClick={onSave} color="green">
          {t('Save')}
        </Button>
        <Button onClick={onSaveAndNotify} color="green">
          {t('Save and Notify')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ConfirmSendNotification = ({ onCancel, onDontSend, onSend }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show
      size="small"
      onHide={onCancel}
      data-qa="notification-interstitial"
    >
      <ModalHeader onClickClose={onCancel}>
        {t('Would You Like To Send a Notification?')}
      </ModalHeader>
      <ModalBody typeOfContent="text">
        <KizenTypography>
          {t(
            'The note has a team member mention/notification. Would you like to send a notification?'
          )}
        </KizenTypography>
      </ModalBody>
      <ModalFooter>
        <Button color="blue" onClick={onDontSend}>
          {t("Don't Send")}
        </Button>
        <Button color="green" onClick={onSend}>
          {t('Send')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ScheduleActivityModal = ({
  show,
  predefinedOptions,
  activity = EMPTY_ACTIVITY,
  onConfirm,
  onHide,
  disabled,
  scheduleActivityList, //whitelist of allowed activities configured in Action Block Layout settings. If none, will use typeahesd to fetch all.
  currentEntity,
  currentObject,
  ...rest
}) => {
  const { t } = useTranslation();
  const [confirmDiscardOpen, confirmDiscard] = useModalControl();
  const [confirmWithSaveOpen, confirmWithSave] = useModalControl();
  const [confirmWithNotifyOpen, confirmWithNotify] = useModalControl();
  const [confirmSendOpen, confirmSend] = useModalControl();
  const [{ data, isComplete, isDirty }, setWizardData] = useState({
    data: activity,
    isComplete: false,
  });

  const handleClose = () => {
    if (isDirty) {
      if (activity === EMPTY_ACTIVITY && !isComplete) {
        confirmDiscard.showModal();
      } else if (isComplete && data.mentions.length > 0) {
        confirmWithNotify.showModal();
      } else if (
        isComplete &&
        activity === EMPTY_ACTIVITY &&
        data.note.length
      ) {
        confirmWithSave.showModal();
      } else if (isComplete && (activity?.note ?? '') !== data.note) {
        confirmWithSave.showModal();
      } else {
        confirmDiscard.showModal();
      }
    } else {
      onHide();
    }
  };

  const handleConfirm = () => {
    if (data.mentions.length > 0) {
      confirmSend.showModal();
    } else {
      onConfirm(data);
    }
  };

  const { width } = useWindowSize();

  return show ? (
    <>
      <BasicModal
        show
        size="medium"
        typeOfContent={width >= _modalSize.medium ? 'wizard' : 'wizard-mobile'}
        buttonText={activity?.activityObject ? t('Save') : t('Schedule')}
        disabled={disabled || !isComplete}
        heading={
          activity?.activityObject
            ? `${t('Edit Scheduled Activity')}`
            : t('Schedule Activity')
        }
        onConfirm={handleConfirm}
        onHide={handleClose}
        {...rest}
      >
        <GenericWizard
          wizard="ScheduleActivitytWizard"
          onChange={setWizardData}
          data={activity || {}}
          predefinedOptions={predefinedOptions}
          scheduleActivityList={scheduleActivityList}
          currentEntity={activity?.activityObject ? undefined : currentEntity}
          currentObject={activity?.activityObject ? undefined : currentObject}
        />
      </BasicModal>
      {confirmDiscardOpen && (
        <ConfirmationModal
          show
          heading={t('You Have Unsaved Changes')}
          buttonText={t('Discard Changes')}
          actionBtnColor="red"
          onHide={confirmDiscard.hideModal}
          onConfirm={() => {
            confirmDiscard.hideModal();
            onHide();
          }}
        >
          {t('Unsaved changes will be lost, would you like to continue?')}
        </ConfirmationModal>
      )}
      {confirmWithSaveOpen && (
        <ConfirmNoteEdits
          onCancel={confirmWithSave.hideModal}
          onDiscard={() => {
            confirmWithSave.hideModal();
            onHide();
          }}
          onSave={async () => {
            confirmWithSave.hideModal();
            await onConfirm(data);
            onHide();
          }}
        />
      )}
      {confirmWithNotifyOpen && (
        <ConfirmNoteEditsWithMentions
          onCancel={confirmWithNotify.hideModal}
          onDiscard={() => {
            confirmWithNotify.hideModal();
            onHide();
          }}
          onSave={async () => {
            confirmWithNotify.hideModal();
            await onConfirm(data);
            onHide();
          }}
          onSaveAndNotify={async () => {
            confirmWithNotify.hideModal();
            await onConfirm({ ...data, notifyMentioned: true });
            onHide();
          }}
        />
      )}
      {confirmSendOpen && (
        <ConfirmSendNotification
          onCancel={confirmSend.hideModal}
          onDontSend={async () => {
            confirmSend.hideModal();
            await onConfirm(data);
            onHide();
          }}
          onSend={async () => {
            confirmSend.hideModal();
            await onConfirm({ ...data, notifyMentioned: true });
            onHide();
          }}
        />
      )}
    </>
  ) : null;
};
