import React from 'react';
import * as PropTypes from 'prop-types';
import { StyledFieldsetLabel } from './styles';

const CheckboxesLabel = ({ standaloneLabel, children, ...others }) => (
  <StyledFieldsetLabel standaloneLabel={standaloneLabel} {...others}>
    {children}
  </StyledFieldsetLabel>
);

CheckboxesLabel.propTypes = {
  // The condensed, above-the-input label
  standaloneLabel: PropTypes.bool,
};

CheckboxesLabel.defaultProps = {
  standaloneLabel: true,
};

export default CheckboxesLabel;
