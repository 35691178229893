import { useState, useEffect, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import Collapse from 'react-bootstrap/Collapse';
import { useTranslation } from 'react-i18next';
import { DraggingContext } from './draggingContext';
import { createBehaviorSubject, useObservable } from './behaviorSubject';
import { applyDropzone } from 'components/DragAndDropLayout/helpers';
import { shadowLight } from 'app/colors';
import BigBoardLayout from 'components/Layout/BigBoardLayout';
import { useBigTableLayout } from 'components/Layout/BigTableLayout';
import PageToolbar, {
  FilterGroupSelector,
  PageSearchInput,
  PageToolbarFiltersButton,
  PageToolbarSection,
} from 'components/Layout/PageToolbar';
import BigBoard from 'components/Board/BigBoard';
import ExpandTableTab from 'components/Tables/Big/ExpandTableTab';
import BaseClientFilterDropdown from 'pages/Common/components/ClientFilterDropdown2';
import {
  getMetricValue,
  getSecondaryMetricValue,
} from 'store/customObjectsRecordsPage/records.redux';
import DesktopColumn from './DesktopColumn';
import { NewRecordButtonText, StyledButtonWithDropdown } from './DesktopTable'; // Special for testing KZN-4208
import { newRecordOptions } from '../index';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { useLocalStorage } from 'react-use';
import { PageToolbarTitle } from './PageToolbarTitle';

import {
  getHasFilterError,
  getRecordsIsFilteringSelector,
} from 'store/customObjectsRecordsPage/selectors';
import Loader from 'components/Kizen/Loader';
import { QuickFilters } from 'components/Filters/QuickFilters';
import { useClearFilter } from 'hooks/useClearFilter';

const FilterDropdown = styled(BaseClientFilterDropdown)`
  ${shadowLight}
`;

const DesktopBoard = ({
  model,
  createAccess,
  search = '',
  recordsCountAndNew,
  boardData,
  teams,
  fields,
  allFields,
  stages,
  getMoreCards,
  onChangeSearch,
  onSelectStageColumn,
  onChoseCardMenu,
  onChoseNewRecord,
  onSubmitRecord,
  onMoveCard,
  onChangeStage,
  groupId = null,
  filterGroup,
  groups,
  openMenu,
  toggleMenu,
  filterInfo,
  quickFilters,
  quickFilterConfig,
  fullFilterObject,
  applyFiltersAction,
  applyQuickFilters,
  setFilterName,
  clearFilter,
  saveGroupAction,
  deleteGroupAction,
  handleUpdateFieldOption = undefined,
  handleChangeGroup,
  performActionOptions = null,
  choiceAction,
  openPerformModal,
  hasUploadButton,
  setMoving,
  updateFilterCount,
  errorsReturned,
  isLoadingView,
  ...others
}) => {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();

  // const hasBulkActions = performActionOptions && !!performActionOptions.length; removed KZN-3660
  const [columns, setColumns] = useState(boardData.stages);
  const [cardHeight, setCardHeight] = useState(0);
  const preDragMouseCoords = useRef(null);
  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout();

  const draggingObservable = useRef(createBehaviorSubject());
  const mouseCoordsObservable = useRef(createBehaviorSubject());
  const cardDropzoneObservable = useRef(createBehaviorSubject());
  const stageDropzoneObservable = useRef(createBehaviorSubject());
  const isFiltering = useSelector(getRecordsIsFilteringSelector);
  const hasFilterError = useSelector(getHasFilterError);

  const [localPageConfig, setLocalPageConfig] = useLocalStorage(
    window.location.pathname,
    {
      scroll: {
        left: 0,
      },
      boardExpanded: false,
    }
  );

  const handleUpdateLocalPageConfig = useCallback(
    (newVal) => {
      setLocalPageConfig({ ...localPageConfig, ...newVal });
    },
    [localPageConfig, setLocalPageConfig]
  );
  useEffect(() => {
    setColumns(boardData.stages);
  }, [boardData.stages]);

  const setDraggingData = useCallback((next) => {
    draggingObservable.current.next(next);
  }, []);

  const setCardDropzone = useCallback((next) => {
    cardDropzoneObservable.current.next(next);
  }, []);

  const setStageDropzone = useCallback((next) => {
    stageDropzoneObservable.current.next(next);
  }, []);

  const setMouseCoords = useCallback((next) => {
    mouseCoordsObservable.current.next(next);
  }, []);

  useEffect(() => {
    const itemEl = document.querySelector('.CardItemWrapper');

    if (itemEl) {
      setCardHeight(itemEl.offsetHeight);
    } else {
      setCardHeight(0);
    }
  }, []);

  const handleChangeSelection = (data, stageId) => {
    if (data.value === 'all') {
      onSelectStageColumn({ selection: true, stageId });
    } else {
      onSelectStageColumn({ selection: false, stageId });
    }
  };

  const draggingData = useObservable(draggingObservable.current);
  setMoving(!!draggingData);

  const getPosition = useCallback(
    (item) => {
      if (draggingData?.item.id === item.id) {
        return {
          x:
            (mouseCoordsObservable.current.getValue()?.x ||
              preDragMouseCoords.current.x) - draggingData.mouseCoords.x,
          y:
            (mouseCoordsObservable.current.getValue()?.y - window.pageYOffset ||
              preDragMouseCoords.current.y) - draggingData.mouseCoords.y,
        };
      }
      return { x: 0, y: 0 };
    },
    [draggingData]
  );

  const applyCardDropzone = async (cards, data, setStagedCards) => {
    const stageDropzone = stageDropzoneObservable.current.getValue();
    const cardDropzone = cardDropzoneObservable.current.getValue();

    if (!draggingData || !cardDropzone || !stageDropzone) {
      return;
    }

    // the case when we drop in the same stage where we started to drag
    if (stageDropzone.id === data.id) {
      const [, toItems] = applyDropzone(
        draggingData.item,
        [cards],
        cardDropzone
      );
      const oldIndex = cards.findIndex((el) => el.id === draggingData.item.id);
      const newIndex = toItems.findIndex(
        (el) => el.id === draggingData.item.id
      );

      if (oldIndex !== newIndex) {
        setStagedCards(toItems);
        onMoveCard({
          stagedCards: toItems,
          recordIds: [draggingData.item.id],
          stageId: data.id,
          index: newIndex,
          card: draggingData.item,
        });
      }
    } else {
      // the case when we drop in the different stage
      const [, toItems] = applyDropzone(
        draggingData.item,
        [columns.find((item) => item.id === stageDropzone.id).cards],
        cardDropzone
      );
      const newIndex = toItems.findIndex(
        (el) => el.id === draggingData.item.id
      );
      const newStage = stages.find(({ value }) => value === stageDropzone.id);
      const updatedColumns = columns.map((col) => {
        // calculate the value to update the summary by based on the summeryMetric type
        const metricValue = getMetricValue({
          item: draggingData.item,
          settings: boardData.settings,
          data,
          fields,
        }); // calculate the value to update the summary by based on the summeryMetric type
        const secondaryMetricValue = getSecondaryMetricValue({
          item: draggingData.item,
          settings: boardData.settings,
          data,
          fields,
        });
        if (col.id === data.id) {
          return {
            ...col,
            summary: col.summary - metricValue,
            secondarySummary: col.secondarySummary - secondaryMetricValue,
            cards: cards.filter(({ id }) => id !== draggingData.item.id),
          };
        }

        if (col.id === stageDropzone.id) {
          // optomistically add the card to the new stage
          return {
            ...col,
            summary: col.summary + metricValue,
            secondarySummary: col.secondarySummary + secondaryMetricValue,
            cards: toItems.map((item) => ({ ...item, stage: newStage })),
          };
        }

        return col;
      });

      setColumns(updatedColumns);
      onMoveCard({
        stagedCards: toItems,
        updatedColumns,
        recordIds: [draggingData.item.id],
        stageId: stageDropzone.id,
        index: newIndex,
        fromStageId: data.id,
        card: draggingData.item,
      });
    }
  };

  const toggle = () => {
    toggleMenu((ps) => !ps);
  };

  const handleClearFilter = useClearFilter(clearFilter, toggleMenu);

  return (
    <BigBoardLayout
      filtersOpen={openMenu}
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <FilterGroupSelector
              value={groupId || 'none'}
              options={groups}
              onChange={handleChangeGroup}
              title={t('Change Group')}
              link={`/custom-objects/${model?.id}/filter-groups`}
            />
            <PageToolbarFiltersButton
              badge={filterInfo.numberOfFilters}
              showBackground={openMenu && !scrollTransitioned}
              onClick={toggle}
              errors={hasFilterError}
            />
            <PageSearchInput
              placeholder={`${t('Find')} ${model.entityName} ${t('Records')}`}
              value={search}
              onChange={onChangeSearch}
              loading={isFiltering}
              isClearable
              {...getDataQAForInput('search-co-records', 'search')}
            />
          </PageToolbarSection>
          <PageToolbarTitle
            data-qa="header-record-count"
            count={recordsCountAndNew}
            single={`${model.entityName} ${t('Record')}`}
            plural={`${model.entityName} ${t('Records')}`}
          />
          <PageToolbarSection>
            {/* Removed in KZN-3660 {hasBulkActions && (
              <ButtonWithDropdown
                color="blue"
                onChange={choiceAction}
                onClick={openPerformModal}
                options={performActionOptions}
              >
                Perform Action
              </ButtonWithDropdown>
            )} */}
            {createAccess && (
              <StyledButtonWithDropdown
                color="green"
                options={newRecordOptions(t, hasUploadButton)}
                onChange={onChoseNewRecord}
                onClick={() => onChoseNewRecord({ value: 'newRecord' })}
              >
                <NewRecordButtonText>{`${t('New')} ${
                  model.entityName
                }`}</NewRecordButtonText>
              </StyledButtonWithDropdown>
            )}
          </PageToolbarSection>
        </PageToolbar>
      }
      filters={
        <Collapse mountOnEnter in={openMenu}>
          <FilterDropdown
            key={
              // the truthy value of isAllContactsGroup is a UUID
              filterInfo.isAllContactsGroup || filterInfo.key
            }
            ref={filterDropdownRef}
            filterData={filterInfo.config}
            and={filterInfo.and}
            applyFiltersAction={applyFiltersAction}
            saveGroupAction={saveGroupAction}
            deleteGroupAction={deleteGroupAction}
            filterName={filterInfo.name}
            setFilterName={setFilterName}
            updateFilterCount={updateFilterCount}
            numberOfFilters={filterInfo.numberOfFilters}
            groupId={groupId}
            filterGroup={filterGroup}
            clearFilter={handleClearFilter}
            betaPreview={preReleaseFeatures}
            objectType={model?.objectType} // this is alwasy goiong to be pipeline
            errorsReturned={errorsReturned}
            customObjectId={model?.id}
            noSticky
          />
        </Collapse>
      }
      quickFiltersPills={
        quickFilters && (
          <QuickFilters
            quickFilters={quickFilters}
            quickFilterConfig={quickFilterConfig}
            fullFilterObject={fullFilterObject}
            search={search}
            resultsCount={recordsCountAndNew}
            model={model}
            fields={allFields}
            onChange={applyQuickFilters}
            errors={filterInfo?.errors?.quickFiltersErrors}
            hasFilterErrors={filterInfo?.errors}
          />
        )
      }
      {...bigTablePageProps}
      {...others}
    >
      <Loader loading={isLoadingView}>
        <BigBoard
          hasQuickFilters={!!quickFilters}
          expandTab={<ExpandTableTab />}
          columnWidth={boardData.settings.columnWidth}
          onMouseMove={(ev) => {
            if (draggingObservable.current) {
              setMouseCoords({ x: ev.clientX, y: ev.clientY });
            }
          }}
          onMouseDown={(ev) => {
            preDragMouseCoords.current = { x: ev.clientX, y: ev.clientY };
          }}
          handleUpdateLocalPageConfig={handleUpdateLocalPageConfig}
          localPageConfig={localPageConfig}
        >
          <DraggingContext.Provider
            value={{
              draggingData: draggingObservable.current,
              mouseCoords: mouseCoordsObservable.current,
              cardDropzone: cardDropzoneObservable.current,
              stageDropzone: stageDropzoneObservable.current,
            }}
          >
            {columns.map((item, i) => (
              <DesktopColumn
                key={i}
                className="ColumnItem"
                data={item}
                allowEdit={createAccess}
                cards={item.cards}
                columns={boardData.stages}
                model={model}
                settings={boardData.settings}
                teams={teams}
                fields={fields}
                stages={stages}
                cardHeight={cardHeight}
                setColumns={setColumns}
                setMouseCoords={setMouseCoords}
                getMoreCards={getMoreCards}
                setDraggingData={setDraggingData}
                setCardDropzone={setCardDropzone}
                setStageDropzone={setStageDropzone}
                onSubmitRecord={onSubmitRecord}
                onMoveCard={onMoveCard}
                onChangeSelection={(data) =>
                  handleChangeSelection(data, item.id)
                }
                onChoseMenu={(data, card) =>
                  onChoseCardMenu(data, card, item.id)
                }
                onChangeStage={onChangeStage}
                onClickPlus={() =>
                  onChoseNewRecord({ value: 'newRecord', stageId: item.id })
                }
                applyCardDropzone={applyCardDropzone}
                getPosition={getPosition}
                search={search}
                handleUpdateFieldOption={handleUpdateFieldOption}
              />
            ))}
          </DraggingContext.Provider>
        </BigBoard>
      </Loader>
    </BigBoardLayout>
  );
};

export default DesktopBoard;
