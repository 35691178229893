import KizenTypography from 'app/kizentypo';
import { unitDisplayTranslated } from 'pages/AutomationEngine/helpers';
import DraggableStepCard from '../DraggableStepCard';

export const content = ({ step }) => step.description;

export const getDelayUnits = ({ details: { minutes, hours, days } }) =>
  (minutes && 'minutes') || (hours && 'hours') || (days && 'days') || 'minutes';

export const getDelayDuration = ({ details: { minutes, hours, days } }) =>
  minutes || hours || days;

export const getDescription = ({ step, t }) => {
  const units = getDelayUnits(step);
  const duration = getDelayDuration(step);
  return `${duration.toLocaleString()} ${
    unitDisplayTranslated(t)[units][duration === 1 ? 'single' : 'plural']
  }`;
};
export default function DelayCard({ type, step, ...others }) {
  return (
    <DraggableStepCard {...others}>
      <KizenTypography as="span">{content({ step })}</KizenTypography>
    </DraggableStepCard>
  );
}
