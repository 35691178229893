import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Cols from 'components/Layout/Cols';
import Button from 'components/Button';
import { UPLOAD_INPUT_TYPES } from 'components/Kizen/FileInputWithImage';
import PhoneNumberTextInput from 'components/Inputs/TextInput/presets/PhoneNumber';
import { DEFAULT_LANGUAGE, availableLanguages } from 'i18n-config';
import { BasicRichTextEditor } from 'components/WYSIWYG';
import { StyledSubSection } from '../styles';
import { useSelector } from 'react-redux';
import { getChosenBusiness } from 'store/authentication/selectors';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import Dropdown from 'components/Fields/FieldInput/Dropdown';
import { FIELD_TYPES } from 'utility/constants';
import {
  StyledRow as Row,
  StyledCol as Col,
  StyledFormInput,
  WysiwygWrapper,
  StyledLabel,
  StyledFormError,
  ImageFileInputWithUpload,
} from './styles';

const MyProfileForm = ({
  values,
  imagePreviewSource = '',
  imageFile = null,
  onFieldChange,
  onImageUploadInputChange,
  onImageRemove,
  onSubmit,
  formError = null,
  validationProps,
}) => {
  const { t } = useTranslation();
  const chosenBusiness = useSelector(getChosenBusiness);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'first-name' },
      { id: 'last-name' },
      values.email && { id: 'email' },
      { id: 'phone-number' },
      { id: 'email-signature' },
      { id: 'language' },
    ].filter(Boolean),
    {},
    () => true
  );

  const inputRef = useRef(null);
  const initialLanguage = useRef(values.language);

  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <StyledSubSection
        title={t('My Profile')}
        action={
          <Button noSpace onClick={onSubmit}>
            {t('Save')}
          </Button>
        }
      >
        <ImageFileInputWithUpload
          label={t('My Avatar')}
          actionLabel="UPLOAD"
          id="upload-button"
          onChange={onImageUploadInputChange}
          onRemove={onImageRemove}
          imagePreviewSource={imagePreviewSource}
          type={UPLOAD_INPUT_TYPES.AVATAR}
          file={imageFile}
        />
        <Cols columns={2} gutter={'20px'}>
          <StyledFormInput
            margin
            label={t('First Name')}
            placeholder={t('Enter First Name')}
            value={values.first_name}
            required
            onChange={(value, ev, err) =>
              onFieldChange('first_name', value, err)
            }
            {...validationProps('first_name')}
            {...getKeyListenersProps('first-name')}
          />
          <StyledFormInput
            margin
            label={t('Last Name')}
            placeholder={t('Enter Last Name')}
            value={values.last_name}
            onChange={(value, ev, err) =>
              onFieldChange('last_name', value, err)
            }
            {...validationProps('last_name')}
            {...getKeyListenersProps('last-name')}
          />
          <StyledFormInput
            margin
            label={t('Email/Username')}
            placeholder={t('Enter Email/Username')}
            value={values.email}
            disabled={!!values.email}
            required
            onChange={(value, ev, err) => onFieldChange('email', value, err)}
            {...validationProps('email')}
            {...getKeyListenersProps('email')}
          />
          <PhoneNumberTextInput
            margin
            label={t('Phone Number')}
            placeholderNumber={t('Enter Phone Number')}
            value={values.phone}
            defaultCountry={chosenBusiness.phone_default_region}
            onChange={(value, ev, err) => onFieldChange('phone', value, err)}
            {...validationProps('phone')}
            {...getKeyListenersProps('phone-number')}
          />
        </Cols>
        <Row>
          <Col>
            <StyledLabel>{t('Email Signature')}</StyledLabel>
            <WysiwygWrapper>
              <BasicRichTextEditor
                initialValue={values.email_signature}
                onChange={({ editor }) =>
                  onFieldChange('email_signature', editor.getHTML())
                }
                initialHeight={174}
                placeholder={t('Enter Text')}
                enableHorizontalRule
                {...validationProps('email_signature')}
                {...getKeyListenersProps('email-signature')}
              />
            </WysiwygWrapper>
          </Col>
        </Row>
        {/* If business does not have profile_language_enabled entitlement 
        we won't let user change language from English to any other*/}
        {chosenBusiness.entitlements?.profile_language_enabled ||
        initialLanguage.current !== DEFAULT_LANGUAGE ? (
          <Row>
            <Col>
              <Dropdown
                ref={inputRef}
                label={t('Application Language')}
                options={availableLanguages}
                value={values.language}
                onChange={(value) => onFieldChange('language', value)}
                menuInline
                field={{
                  fieldType: FIELD_TYPES.Dropdown,
                  options: availableLanguages,
                }}
                menuLeftButton={null}
                menuRightButton={null}
                {...getKeyListenersProps('language')}
                onMenuOpen={() => {
                  setTimeout(() =>
                    inputRef.current.select.inputRef.scrollIntoView(true)
                  );
                }}
              />
            </Col>
          </Row>
        ) : null}
        {formError && (
          <Row>
            <Col>
              <StyledFormError>{formError}</StyledFormError>
            </Col>
          </Row>
        )}
      </StyledSubSection>
    </KeyBoardContext.Provider>
  );
};

export default MyProfileForm;
