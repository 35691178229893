import format from 'date-fns/format';

import AxiosService from './AxiosService';
import {
  snakeToCamelCaseKeys,
  camelToSnakeCaseKeys,
  API_DATE_FORMAT,
  FORCE_ALL_RECORDS_SIZE,
} from './helpers';

let messages = [];

class EmailService {
  constructor() {
    if (!EmailService.instance) {
      EmailService.instance = this;
    }
    return EmailService.instance;
  }

  getTemplates = async ({
    page,
    pageSize = 25,
    ordering,
    fromDate,
    toDate,
  } = {}) => {
    const { data } = await AxiosService.get('/sent-email-template', {
      params: {
        page,
        page_size: pageSize,
        ordering,
        from_date: (fromDate && format(fromDate, API_DATE_FORMAT)) || undefined,
        to_date: (toDate && format(toDate, API_DATE_FORMAT)) || undefined,
      },
    });

    return data;
  };

  getMessages = async ({ page }) => {
    const { data } = await AxiosService.get(`/email-template?page=${page}`);

    messages = messages.concat(data.results);

    if (data.next) {
      return this.getMessages({ page: page + 1 });
    }

    return messages.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  };

  getSubscriptionList = async () => {
    const { data } = await AxiosService.get('subscription-list', {
      params: { page_size: FORCE_ALL_RECORDS_SIZE },
    });
    return snakeToCamelCaseKeys(data.results);
  };

  getSentMessage = async ({ messageId }) => {
    const { data } = await AxiosService.get(`/sent-email/${messageId}`);
    return snakeToCamelCaseKeys(data);
  };

  sendEmail = async ({ id, subject, body, clientId, ...rest }) => {
    await AxiosService.post(`external-account/${id}/send_email`, {
      id,
      subject,
      body,
      client_id: clientId,
      ...rest,
    });
  };

  getMessageStats = async (clientId) => {
    const { data } = await AxiosService.get('message-stats', {
      params: { client_id: clientId },
    });

    return snakeToCamelCaseKeys(data);
  };

  getMessageHistory = async ({ clientId, ordering }) => {
    const { data } = await AxiosService.get('message-history', {
      params: { client_id: clientId, ordering },
    });

    return data
      .map(snakeToCamelCaseKeys)
      .map(({ body, subject, ...other }) => ({
        message: (body || subject).trim(),
        ...other,
      }));
  };

  getSubscriptionData = async ({ clientId, ordering }) => {
    const { data } = await AxiosService.get(
      'subscription-list/with_client_status',
      {
        params: {
          client_id: clientId,
          ordering,
          page_size: FORCE_ALL_RECORDS_SIZE,
        },
      }
    );

    return data.results.map(snakeToCamelCaseKeys);
  };

  setSubscriptionStatus = async ({ clientId, subId, status }) => {
    await AxiosService.post(`subscription-list/${subId}/set_status`, {
      client_id: clientId,
      id: subId,
      status,
    });
  };

  getAllSubscriptions = async () => {
    let allSubscriptions = [];

    const getSubs = async (page) => {
      const params = { page };
      const { data } = await AxiosService.get('subscription-list', { params });
      allSubscriptions = [
        ...allSubscriptions,
        ...snakeToCamelCaseKeys(data.results),
      ];
      if (data.next) {
        await getSubs(page + 1);
      }
    };
    await getSubs(1);

    return allSubscriptions;
  };

  setSubscriptionsOrdering = async (subscriptions) => {
    const payload = subscriptions.reduce(
      (collect, { id }, i) => ({
        ...collect,
        [id]: i,
      }),
      {}
    );
    const { data } = await AxiosService.post(
      `/subscription-list/set_ordering`,
      payload
    );
    return snakeToCamelCaseKeys(data.results);
  };

  updateSubscription = async (subscription) => {
    const { id, ...payload } = subscription;
    const { data } = await AxiosService.patch(
      `/subscription-list/${id}`,
      camelToSnakeCaseKeys(payload)
    );
    return snakeToCamelCaseKeys(data.results);
  };

  createSubscription = async (subscription) => {
    const { data } = await AxiosService.post(
      `/subscription-list`,
      camelToSnakeCaseKeys(subscription)
    );
    return snakeToCamelCaseKeys(data.results);
  };

  deleteSubscription = async (id) => {
    await AxiosService.delete(`/subscription-list/${id}`);
  };

  getManageSubscriptions = async (contactId, messageId) => {
    const { data } = await AxiosService.get(
      `/public/manage-subscriptions/${contactId}/${messageId}`
    );
    return snakeToCamelCaseKeys(data);
  };

  updateManageSubscriptions = async (contactId, messageId, payload) => {
    const { data } = await AxiosService.patch(
      `/public/manage-subscriptions/${contactId}/${messageId}`,
      camelToSnakeCaseKeys(payload)
    );
    return snakeToCamelCaseKeys(data);
  };

  getSyncedEmailMessageContent = async (id) => {
    const { data } = await AxiosService.get(
      `/synced-email/${id}/full_response`,
      { skipErrorBoundary: true }
    );
    return data;
  };
}

const instance = new EmailService();
Object.freeze(instance);

export default instance;
