import {
  DEFAULT_IMAGE_CONTAINER_PROPS,
  EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
} from '@kizen/page-builder/nodes/containers';

export const DEFAULT_EMAIL_IMAGE_PROPS = {
  ...DEFAULT_IMAGE_CONTAINER_PROPS,
  ...EMAIL_ELEMENT_CONTAINER_PROPS_OVERRIDES,
};

export const DEFAULT_FORM_IMAGE_PROPS = DEFAULT_IMAGE_CONTAINER_PROPS;
