import { createContext, useState, useMemo, useCallback } from 'react';

const defaultNotes = {
  currentId: null,
  setCurrentId: () => {},
  isDirty: false,

  setIsDirty: () => {},
  originalNote: null,
  setOriginalNote: () => {},
};

export const NotesContext = createContext(defaultNotes);

export const useNotesContext = () => {
  const [currentId, setCurrentId] = useState(defaultNotes.currentId);
  const [isDirty, setIsDirty] = useState(defaultNotes.isDirty);
  const [originalNote, setOriginalNote] = useState(defaultNotes.originalNote);

  const reset = useCallback(() => {
    setCurrentId(null);
    setIsDirty(false);
    setOriginalNote(null);
  }, []);

  const value = useMemo(
    () => ({
      currentId,
      setCurrentId,
      isDirty,
      setIsDirty,
      originalNote,
      setOriginalNote,
      reset,
    }),
    [currentId, isDirty, originalNote, reset]
  );
  return value;
};
