import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { ChartLayout, Chart, StyledLoader } from './styles';
import TrendChartManager from './TrendChartManager';
import { useTranslation } from 'react-i18next';
import NoData from '../components/NoData';
import { StyledNoData } from '../components/NoData/styles';
import { getPendingDefaultValue } from '../helpers';
import { isEqual } from 'lodash';
import { sanitizeTextForCharts } from '../ChartManager';

const TrendChart = ({
  data,
  isLoading,
  mobile,
  yAxisTitle,
  tooltipLabels,
  currencySymbol,
  frequency,
  isPercentage,
  id,
  isValue,
}) => {
  const chartRef = useRef();
  const { t } = useTranslation();
  const [chartRenderKey, setChartRenderKey] = useState(() => Date.now());
  const [chartPending, setChartPending] = useState(getPendingDefaultValue);
  const [chartRendered, setChartRendered] = useState(false);

  const sanitizedTooltipLabels = useMemo(() => {
    const res = { ...tooltipLabels };
    for (const [key, value] of Object.entries(res)) {
      res[key] = sanitizeTextForCharts(value);
    }
    return res;
  }, [tooltipLabels]);

  useEffect(() => {
    if (chartRef.current) {
      const manager = new TrendChartManager(
        chartRef.current,
        t,
        data,
        {
          currencySymbol,
          mobile,
          useAllBullets: true,
          frequency,
          tooltipLabels: sanitizedTooltipLabels,
          yAxisTitle: sanitizeTextForCharts(yAxisTitle),
          isPercentage,
          currencyMode: isValue,
        },
        {},
        () => {
          setChartPending(false);
          setChartRendered(true);
        }
      );

      manager.init();

      return manager.destroy;
    }
  }, [
    data,
    currencySymbol,
    chartRenderKey,
    isLoading,
    mobile,
    frequency,
    sanitizedTooltipLabels,
    yAxisTitle,
    isPercentage,
    t,
    isValue,
  ]);

  const createChart = useCallback((elem) => {
    if (elem) {
      chartRef.current = elem;
      setChartRenderKey(Date.now());
    }
  }, []);

  return (
    <ChartLayout>
      {/*
          This absolutely positioned loader is used to keep the chart area hidden until the calculations
          and rendering are done, as they can be quite slow for large datasets and will just
          show a blank white dashlet otherwise
        */}
      <StyledLoader loading={chartPending || isLoading} />
      {data.length === 0 ? (
        <StyledNoData>
          <NoData data-qa-dashlet-id={id} data-qa-no-data />
        </StyledNoData>
      ) : null}
      <Chart
        data-qa-chart="trend"
        data-qa-chart-pending={chartPending || isLoading || !chartRendered}
        ref={createChart}
      />
    </ChartLayout>
  );
};

TrendChart.propTypes = {
  data: PropTypes.object,
  dashlet: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  mobile: PropTypes.bool,
};

TrendChart.defaultProps = {
  data: { data: [] },
  isLoading: true,
  mobile: false,
};

const propsAreEqual = (prevProps, nextProps) => {
  const dataEqual = isEqual(prevProps.data, nextProps.data);
  const isLoadingEqual = prevProps.isLoading === nextProps.isLoading;
  const mobileEqual = prevProps.mobile === nextProps.mobile;
  const yAxisTitleEqual = prevProps.yAxisTitle === nextProps.yAxisTitle;
  const tooltipLabelsEqual = isEqual(
    prevProps.tooltipLabels,
    nextProps.tooltipLabels
  );
  const currencySymbolEqual =
    prevProps.currencySymbol === nextProps.currencySymbol;
  const frequencyEqual = prevProps.frequency === nextProps.frequency;
  const isPercentageEqual = prevProps.isPercentage === nextProps.isPercentage;
  const idEqual = prevProps.id === nextProps.id;
  const isValueEqual = prevProps.isValue === nextProps.isValue;

  return (
    dataEqual &&
    isLoadingEqual &&
    mobileEqual &&
    yAxisTitleEqual &&
    tooltipLabelsEqual &&
    currencySymbolEqual &&
    frequencyEqual &&
    isPercentageEqual &&
    idEqual &&
    isValueEqual
  );
};

export default React.memo(TrendChart, propsAreEqual);
