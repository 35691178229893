import React, { forwardRef, useCallback, useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from 'components/Inputs/Select';
import { FIELD_TYPES } from 'utility/constants';
import { useBusinessConfigQuery } from 'queries/models/business';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const Timezone = forwardRef(
  ({ field, onChange, value, fieldType, fieldId, ...others }, ref) => {
    const { t } = useTranslation();
    const { assignFieldHandle } = useKeyBoardContext();

    const { data: publicData } = useBusinessConfigQuery(
      field.options.length === 0
    );

    const options = useMemo(() => {
      if (!publicData?.timezones) {
        return field.options.map((opt) => ({
          value: opt.code,
          label: opt.name,
        }));
      }

      return publicData?.timezones || [];
    }, [publicData, field]);

    const stagedValue = useMemo(() => {
      if (!value) return null;
      if (typeof value === 'string') return value;

      // case for log activity
      if (Object.prototype.hasOwnProperty.call(value, 'id')) return value.id;

      // fallback
      return value;
    }, [value]);

    const handleChange = useCallback(
      (opt) => onChange(opt ? opt.value : null),
      [onChange]
    );

    const selectRef = useRef(null);

    const { setMenuIsOpen, ...customSelectMenuProps } =
      useCustomSelectMenuProps(selectRef, others);

    assignFieldHandle(fieldId, {
      customFocus: () => {
        selectRef.current?.select?.focus();
        setMenuIsOpen(false);
        return selectRef.current?.select;
      },
      customEscape: () => {
        setMenuIsOpen(false);
      },
      customUp: () => {
        setMenuIsOpen(true);
      },
      customDown: () => {
        setMenuIsOpen(true);
      },
    });

    return (
      <Select
        ref={selectRef}
        options={options}
        placeholder={
          others.placeholder || FIELD_TYPES.Timezone.localizedPlaceholder(t)
        }
        onChange={handleChange}
        value={stagedValue}
        {...others}
        {...customSelectMenuProps}
      />
    );
  }
);

Timezone.propTypes = {
  onChange: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

export default Timezone;
