import { faAlignRight } from '@fortawesome/pro-light-svg-icons/faAlignRight';
import { faAlignLeft } from '@fortawesome/pro-light-svg-icons/faAlignLeft';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons/faAlignJustify';
import { faLineHeight } from '@fortawesome/pro-light-svg-icons/faLineHeight';
import { faAlignCenter } from '@fortawesome/pro-light-svg-icons/faAlignCenter';
import { faBold } from '@fortawesome/pro-light-svg-icons/faBold';
import { faItalic } from '@fortawesome/pro-light-svg-icons/faItalic';
import { faUnderline } from '@fortawesome/pro-light-svg-icons/faUnderline';
import { faStrikethrough } from '@fortawesome/pro-light-svg-icons/faStrikethrough';
import { faTextSlash } from '@fortawesome/pro-light-svg-icons/faTextSlash';
import { faLinkHorizontalSlash } from '@fortawesome/pro-light-svg-icons/faLinkHorizontalSlash';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faImage } from '@fortawesome/pro-light-svg-icons/faImage';
import { faImageLandscape } from '@fortawesome/pro-light-svg-icons/faImageLandscape';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faListOl } from '@fortawesome/pro-light-svg-icons/faListOl';
import { faText } from '@fortawesome/pro-light-svg-icons/faText';
import { faMicrochipAi } from '@fortawesome/pro-light-svg-icons/faMicrochipAi';
import { faArrowTurnRight } from '@fortawesome/pro-light-svg-icons/faArrowTurnRight';
import { faArrowTurnLeft } from '@fortawesome/pro-light-svg-icons/faArrowTurnLeft';
import {
  IconAnimation,
  IconRotation,
  SemanticCategory,
  IconLabel,
} from '../types';

export const SEMANTIC_CATEGORY: SemanticCategory = 'text';
export type SemanticCategoryName = typeof SEMANTIC_CATEGORY;

export const icons = {
  'align-left': faAlignLeft,
  'align-right': faAlignRight,
  'align-justify': faAlignJustify,
  'line-height': faLineHeight,
  'align-center': faAlignCenter,
  bold: faBold,
  italic: faItalic,
  underline: faUnderline,
  strikethrough: faStrikethrough,
  'clear-formatting': faTextSlash,
  'remove-link': faLinkHorizontalSlash,
  'add-link': faLink,
  image: faImage,
  'image-landscape': faImageLandscape,
  'bullet-list-circle': faList,
  'bullet-list': faListUl,
  'numbered-list': faListOl,
  block: faText,
  ai: faMicrochipAi,
  redo: faArrowTurnRight,
  undo: faArrowTurnLeft,
};

export const labels: IconLabel = {
  'align-left': {
    title: (t) => '',
    description: (t) => t('Align Left'),
  },
  'align-right': {
    title: (t) => '',
    description: (t) => t('Align Right'),
  },
  'align-justify': {
    title: (t) => '',
    description: (t) => t('Align Justify'),
  },
  'line-height': {
    title: (t) => '',
    description: (t) => t('Line Height'),
  },
  'align-center': {
    title: (t) => '',
    description: (t) => t('Align Center'),
  },
  bold: {
    title: (t) => '',
    description: (t) => t('Bold'),
  },
  italic: {
    title: (t) => '',
    description: (t) => t('Italic'),
  },
  underline: {
    title: (t) => '',
    description: (t) => t('Underline'),
  },
  strikethrough: {
    title: (t) => '',
    description: (t) => t('Strikethrough'),
  },
  'clear-formatting': {
    title: (t) => '',
    description: (t) => t('Clear Formatting'),
  },
  'remove-link': {
    title: (t) => '',
    description: (t) => t('Remove Link'),
  },
  'add-link': {
    title: (t) => '',
    description: (t) => t('Add Link'),
  },
  image: {
    title: (t) => '',
    description: (t) => t('Image'),
  },
  'image-landscape': {
    title: (t) => '',
    description: (t) => t('Image Landscape'),
  },
  'bullet-list-circle': {
    title: (t) => '',
    description: (t) => t('Bullet List Circle'),
  },
  'bullet-list': {
    title: (t) => '',
    description: (t) => t('Bullet List'),
  },
  'numbered-list': {
    title: (t) => '',
    description: (t) => t('Numbered List'),
  },
  block: {
    title: (t) => '',
    description: (t) => t('Block'),
  },
  ai: {
    title: (t) => '',
    description: (t) => t('AI'),
  },
  redo: {
    title: (t) => '',
    description: (t) => t('Redo'),
  },
  undo: {
    title: (t) => '',
    description: (t) => t('Undo'),
  },
};

export type ValidIcons = keyof typeof icons;

export const rotation: IconRotation = {};

export const animation: IconAnimation = {};
