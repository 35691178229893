import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import KizenTypography from 'app/kizentypo';
import { useAutomationsDispatch } from 'pages/AutomationEngine/store/react';
import { actions } from 'pages/AutomationEngine/store';
import TriggerCard from '../TriggerCard';

export const content = ({ t }) => {
  return t('Add Trigger');
};

export default function AddTriggerCard({ type, step, ...others }) {
  const { t } = useTranslation();
  const dispatch = useAutomationsDispatch();
  return (
    <TriggerCard
      {...others}
      addMenu={false} // never show a menu
      onClick={() => dispatch(actions.createTrigger())}
      cardComponentType="anchor"
    >
      <KizenTypography
        data-qa="add-trigger-btn"
        as="span"
        className="button-text"
      >
        {t('Click to add new trigger')}
      </KizenTypography>
    </TriggerCard>
  );
}

AddTriggerCard.propTypes = {
  type: PropTypes.string.isRequired,
  step: PropTypes.object.isRequired,
};
