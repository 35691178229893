import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';

export const ONLY_UPDATE_BLANK = 'only_update_blank';
export const OVERWRITE = 'overwrite';
export const OVERWRITE_NOT_BLANK = 'overwrite_except_null';
export const ONLY_ADD_OPTIONS = 'only_add_options';
export const NO_UPLOAD = '__NO_UPLOAD__';

export const subscriptionField = {
  id: 'active_subscription_lists_id',
  fieldType: 'active_subscription_lists',
  displayName: 'Active Subscription Lists',
  canonicalDisplayName: 'Active Subscription Lists',
  name: 'active_subscription_lists',
};

export const isCheckOnlyAddOptions = (fieldType, field) => {
  // special condition
  if (
    field?.fieldType === FIELD_TYPES.Relationship.type &&
    ['many_to_one', 'one_to_one'].includes(field.relation.cardinality)
  ) {
    return false;
  }
  return (
    fieldType === FIELD_TYPES.Relationship.type ||
    fieldType === FIELD_TYPES.DynamicTags.type ||
    fieldType === FIELD_TYPES.Checkboxes.type ||
    fieldType === subscriptionField.fieldType
  );
};

export const getConflictResolution = (fieldType, field) => {
  return isCheckOnlyAddOptions(fieldType, field)
    ? ONLY_ADD_OPTIONS
    : ONLY_UPDATE_BLANK;
};

export const getInitialFinalizeUploadData = (el, newObjValues) => {
  return Object.keys(newObjValues).reduce((acc, curr) => {
    acc[curr] = el[newObjValues[curr].csvColumn];
    return acc;
  }, {});
};

export const changeCSVColumnType = (obj) => {
  return Object.keys(obj).reduce((acc, curr) => {
    if (obj[curr].csvColumn === NO_UPLOAD) {
      return acc;
    }
    acc[curr] = { ...obj[curr], csvColumn: +obj[curr].csvColumn };
    return acc;
  }, {});
};

export const defaultFieldsOrderForUpload = [
  // Contacts
  DEFAULT_FIELD_NAMES.firstName,
  DEFAULT_FIELD_NAMES.lastName,
  DEFAULT_FIELD_NAMES.email,
  DEFAULT_FIELD_NAMES.businessPhone,
  DEFAULT_FIELD_NAMES.mobilePhone,
  DEFAULT_FIELD_NAMES.homePhone,
  DEFAULT_FIELD_NAMES.titles,
  DEFAULT_FIELD_NAMES.tags,
  DEFAULT_FIELD_NAMES.birthday,
  DEFAULT_FIELD_NAMES.emailStatus,
  DEFAULT_FIELD_NAMES.timezone,

  // CO
  DEFAULT_FIELD_NAMES.name,
  DEFAULT_FIELD_NAMES.owner,
  DEFAULT_FIELD_NAMES.entityValue,
  DEFAULT_FIELD_NAMES.estimatedCloseDate,
  DEFAULT_FIELD_NAMES.actualCloseDate,
  DEFAULT_FIELD_NAMES.percentageChanceToClose,
  DEFAULT_FIELD_NAMES.stage,
  DEFAULT_FIELD_NAMES.reasonsLost,
  DEFAULT_FIELD_NAMES.reasonsDisqualified,
];

export const fieldWithOptionsForUpload = [
  FIELD_TYPES.Radio.type,
  FIELD_TYPES.Checkboxes.type,
  FIELD_TYPES.Status.type,
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Dropdown.type,
];

export const getFieldsForUpload = (allFields, dynamicTagsWithOptions) => {
  const defaultFields = defaultFieldsOrderForUpload.reduce((acc, item) => {
    const field = allFields.find((el) => el.name === item);
    return field
      ? [
          ...acc,
          dynamicTagsWithOptions[field.id]
            ? { ...field, options: dynamicTagsWithOptions[field.id] }
            : field,
        ]
      : acc;
  }, []);
  const customFields = allFields
    .filter((el) => !el.isDefault && !defaultFields.some((f) => f.id === el.id))
    .map((el) =>
      fieldWithOptionsForUpload.includes(el.fieldType)
        ? {
            ...el,
            options:
              dynamicTagsWithOptions[el.id] ||
              [...el.options].sort((a, b) => a.order - b.order),
          }
        : el
    );

  return [defaultFields, customFields];
};
