import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Select from 'components/Inputs/Select';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ADMIN_ID = 'Admin';
export const EDIT_ID = 'Edit';
export const VIEW_ID = 'View';

export const VIEW_VALUE = 1;
export const EDIT_VALUE = 2;
export const ADMIN_VALUE = 3;

const AccessRequest = ({
  grantingAdminOptions,
  employee_access,
  defaultAdmin,
  loading,
  autoscroll = true,
  onChange,
  labels,
  useFirstAdmin = false,
}) => {
  const { t } = useTranslation();
  const footerRef = useRef(null);

  const accessLevelOptions = useMemo(() => {
    return [
      {
        label: labels?.view ?? t('View'),
        id: VIEW_ID,
        value: VIEW_VALUE,
      },
      {
        label: labels?.edit ?? t('Edit'),
        id: EDIT_ID,
        value: EDIT_VALUE,
      },
      {
        label: labels?.admin ?? t('Admin'),
        id: ADMIN_ID,
        value: ADMIN_VALUE,
      },
    ].filter((al) => {
      const { id } = al;
      if (id === ADMIN_ID) {
        return employee_access !== ADMIN_ID;
      }
      if (id === EDIT_ID) {
        return employee_access !== EDIT_ID && employee_access !== ADMIN_ID;
      }
      if (id === VIEW_ID) {
        return (
          employee_access !== EDIT_ID &&
          employee_access !== ADMIN_ID &&
          employee_access !== VIEW_ID
        );
      }
      return true;
    });
  }, [t, employee_access, labels]);

  const [selectedGrantingAdmin, setSelectedGrantingAdmin] = useState(() => ({
    label: defaultAdmin?.display_name,
    value: defaultAdmin?.id,
  }));

  useEffect(() => {
    if (
      !selectedGrantingAdmin?.value &&
      useFirstAdmin &&
      grantingAdminOptions?.length > 0
    ) {
      setSelectedGrantingAdmin(grantingAdminOptions[0]);
    }
  }, [grantingAdminOptions, selectedGrantingAdmin, useFirstAdmin]);

  const [selectedAccessLevel, setSelectedAccessLevel] = useState(
    () => accessLevelOptions[0]
  );

  const handleAnyDropdownFocus = useCallback(() => {
    if (autoscroll) {
      setTimeout(() => {
        footerRef.current?.scrollIntoView();
      });
    }
  }, [autoscroll]);

  useEffect(() => {
    onChange?.({
      grantingAdmin: selectedGrantingAdmin?.value,
      accessLevel: selectedAccessLevel?.value,
    });
  }, [selectedGrantingAdmin, selectedAccessLevel, onChange]);

  return (
    <>
      <StyledWizardField top={0}>
        <KizenTypography>{t('Granting Admin')}</KizenTypography>
        <StyledWizardField top={gutters.spacing(2, 2)}>
          <Select
            options={grantingAdminOptions}
            isLoading={loading}
            value={selectedGrantingAdmin}
            onChange={setSelectedGrantingAdmin}
            onMenuOpen={handleAnyDropdownFocus}
          />
        </StyledWizardField>
        <StyledWizardField>
          <KizenTypography>{t('Access Level for Request')}</KizenTypography>
          <StyledWizardField top={gutters.spacing(2, 2)}>
            <Select
              options={accessLevelOptions}
              onMenuOpen={handleAnyDropdownFocus}
              value={selectedAccessLevel}
              onChange={setSelectedAccessLevel}
              classNamePrefix="AccessLevel"
            />
          </StyledWizardField>
        </StyledWizardField>
      </StyledWizardField>
      <div ref={footerRef} />
    </>
  );
};

export default AccessRequest;
