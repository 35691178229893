import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { createTeleporter } from 'react-teleporter';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { KizenTypography } from 'app/typography';
import RadioGroup from 'components/Kizen/RadioGroup';
import SummaryImage from '../images/summary.png';
import SummaryHistoricalImage from '../images/summary_historical.png';
import TrendImage from '../images/trend.png';
import DonutImage from '../images/donut.png';
import DonutSummaryImage from '../images/donut_summary.png';
import BarImage from '../images/bar.png';
import { CHART_TYPES, FREQUENCY_TYPES } from './types';

const Target = createTeleporter();
const Historical = createTeleporter();

const getFullChartList = ({ historical, t, includeSummary }) => ({
  [CHART_TYPES.SUMMARY]: {
    label: historical ? t('Summary/Comparison') : t('Summary'),
    imgSrc: historical ? SummaryHistoricalImage : SummaryImage,
    slot: Historical.Target,
  },
  [CHART_TYPES.TREND]: {
    label: t('Trend'),
    imgSrc: TrendImage,
  },
  [CHART_TYPES.DONUT]: {
    label: includeSummary ? t('Donut w/Summary') : t('Donut'),
    imgSrc: includeSummary ? DonutSummaryImage : DonutImage,
  },
  [CHART_TYPES.BAR]: {
    label: t('Bar'),
    imgSrc: BarImage,
  },
});

const toChart = (chartList) => (type) => ({ type, ...chartList[type] });

const getChartList = ({ metricType, historical, t, only, includeSummary }) => {
  const fullChartList = getFullChartList({
    metricType,
    historical,
    includeSummary,
    t,
  });

  return only?.map(toChart(fullChartList)) ?? [];
};

const ChartTypeSelector = ({
  onChange,
  value,
  metricType,
  reportType,
  historical,
  includeSummary,
  only,
  columns,
  shrinkGutter,
  mode,
}) => {
  const { t } = useTranslation();

  const charts = useMemo(
    () =>
      getChartList({
        metricType,
        reportType,
        historical,
        t,
        only,
        includeSummary,
      }),
    [metricType, reportType, historical, t, only, includeSummary]
  );

  const renderOption = (chart) => {
    return (
      <div
        value={chart.type}
        data-qa-type={chart.type}
        data-qa-label={chart.label}
        key={chart.type}
      >
        <img src={chart.imgSrc} alt={chart.label} />
        <KizenTypography type="small">{chart.label}</KizenTypography>
      </div>
    );
  };

  const columnClassName = columns
    ? `col-${12 / columns} ${shrinkGutter ? 'shrink-gutter' : ''}`
    : `col-4 ${shrinkGutter ? 'shrink-gutter' : ''}`;

  const renderSlot = (chart) => {
    if (!chart.slot) {
      return (
        <div
          key={chart.type}
          data-qa-type={chart.type}
          className={columnClassName}
        />
      );
    }
    return (
      <chart.slot
        key={chart.type}
        data-qa-type={chart.type}
        className={columnClassName}
      />
    );
  };

  const isChecked = (child) => {
    const childValue = child.props.value;
    if (childValue === FREQUENCY_TYPES.WEEK) {
      return Object.values(FREQUENCY_TYPES).includes(value);
    }
    return childValue === value;
  };

  return (
    <>
      <RadioGroup
        customClassName={mode === 'wizard' ? 'wizard-chart-selector' : 'col-4'}
        value={value}
        isCheckedCustom={isChecked}
        variant="content"
        onChange={(selection) => {
          return onChange(selection);
        }}
        name="chartType"
        mode={mode}
      >
        {charts.map(renderOption)}
      </RadioGroup>
      {mode !== 'wizard' ? <Row>{charts.map(renderSlot)}</Row> : ''}
    </>
  );
};

ChartTypeSelector.propTypes = {
  metricType: PropTypes.string,
  reportType: PropTypes.string,
  historical: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  only: PropTypes.arrayOf(PropTypes.string),
  columns: PropTypes.number,
  shrinkGutter: PropTypes.bool,
  mode: PropTypes.oneOf(['wizard', 'default']),
};

ChartTypeSelector.defaultProps = {
  metricType: null,
  reportType: null,
  historical: null,
  value: null,
  only: [],
  columns: 3,
  shrinkGutter: false,
  mode: 'default',
};

export default ChartTypeSelector;

export const TargetSource = Target.Source;

export const HistoricalSource = Historical.Source;
