import {
  all,
  takeLatest,
  put,
  call,
  select,
  debounce,
} from 'redux-saga/effects';
import { DEFAULT_DELAY } from 'utility/config';
import LibraryService from 'services/LibraryService';
import TeamMemberService from 'services/TeamMemberService';
import { addToast } from 'store/toastProvider/toastProvider.redux';
import {
  buildPage as buildPageAction,
  buildPageFail,
  buildPageComplete,
  buildPageFinish,
  getTemplates as getTemplatesAction,
  getBroadcasts as getBroadcastsAction,
  getBroadcastsFail,
  getTemplatesFail,
  getTemplatesSuccess,
  getBroadcastsStart,
  getBroadcastsSuccess,
  getAutomations as getAutomationsAction,
  getAutomationsStart,
  getAutomationsFail,
  getAutomationsSuccess,
  deleteTemplate as deleteTemplateAction,
  deleteTemplateFail,
  setPageConfig as setPageConfigAction,
  duplicateTemplate as duplicateTemplateAction,
  duplicateTemplateFail,
  duplicateTemplateSuccess,
  updatePageConfig,
  getTemplatesStart,
  getTemplatesFinish,
  getAutomationsFinish,
  getBroadcastsFinish,
} from './library.redux';
import { toastVariant } from 'components/ToastProvider';

const PAGE_CONFIG_KEY = 'message_library_list';

function* getTemplates() {
  try {
    yield put(getTemplatesStart());
    const pageConfig = yield select((s) => s.libraryPage.pageConfig.templates);
    const data = {
      ordering: pageConfig.sort,
      page: {
        search: pageConfig.search,
        number: pageConfig.page,
        size: pageConfig.size,
      },
    };
    const { results, count } = yield call(LibraryService.getAllTemplates, data);
    yield put(
      getTemplatesSuccess({
        templates: results,
        templatesCount: count,
      })
    );
  } catch (error) {
    yield put(getTemplatesFail(error));
  } finally {
    yield put(getTemplatesFinish());
  }
}

function* deleteTemplate({ payload }) {
  try {
    yield call(LibraryService.delete, payload);
    yield put(
      addToast({
        variant: toastVariant.SUCCESS,
        message: 'The template has been successfully deleted.',
      })
    );
    yield getTemplates({ payload: { updatePageConfig: false } });
  } catch (error) {
    yield put(
      addToast({
        variant: toastVariant.FAILURE,
        message: 'The template has not been successfully deleted.',
      })
    );
    yield put(deleteTemplateFail(error));
  }
}

function* duplicateTemplate({ payload }) {
  try {
    yield call(LibraryService.duplicate, payload);
    yield put(duplicateTemplateSuccess());
    yield getTemplates({ payload: { updatePageConfig: false } });
  } catch (error) {
    yield put(duplicateTemplateFail(error));
  }
}

function* getAutomations({ payload }) {
  try {
    yield put(getAutomationsStart());

    const { page, search, id, callback } = payload;
    const pageConfig = yield select(
      (s) => s.libraryPage.pageConfig.automations
    );
    const data = {
      ordering: pageConfig.sort,
      page: {
        number: page || pageConfig.page,
        size: pageConfig.size,
        search: id ? search || '' : search || pageConfig.search, // id means we are in a sub folder so just the search value should be considered
      },
    };
    const { results, count } = id
      ? yield call(LibraryService.getAllAutomationsInFolder, { id, ...data })
      : yield call(LibraryService.getAllAutomations, data);
    yield put(
      getAutomationsSuccess({
        automations: results,
        automationsCount: count,
      })
    );
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(getAutomationsFail(error));
  } finally {
    yield put(getAutomationsFinish());
  }
}

function* getBroadcasts({ payload }) {
  try {
    yield put(getBroadcastsStart());
    const { page, search, id, callback } = payload;
    const pageConfig = yield select((s) => s.libraryPage.pageConfig.broadcasts);
    const pageData = {
      ordering: id ? pageConfig.singleSendsSort : pageConfig.sort,
      page: {
        number: page || id ? pageConfig.singleSendsPage : pageConfig.page,
        size: id ? pageConfig.singleSendsSize : pageConfig.size,
        search: search || id ? pageConfig.singleSendsSearch : pageConfig.search,
      },
    };
    const { results, count } = id
      ? yield call(LibraryService.getAllBroadcastsInFolder, {
          ...pageData,
          folderId: id,
        })
      : yield call(LibraryService.getAllBroadcastsInFolder, pageData);
    yield put(
      getBroadcastsSuccess({
        broadcasts: results,
        broadcastsCount: count,
      })
    );
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(getBroadcastsFail(error));
  } finally {
    yield put(getBroadcastsFinish());
  }
}

function* buildPage() {
  try {
    const [pageResponse] = yield all([
      call(TeamMemberService.getPageConfig, PAGE_CONFIG_KEY),
    ]);
    yield put(
      buildPageComplete({
        pageResponse,
      })
    );

    yield getTemplates({ payload: { updatePageConfig: false } });
    yield getAutomations({ payload: { updatePageConfig: false } });
    yield getBroadcasts({ payload: { updatePageConfig: false } });
    yield put(buildPageFinish());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* setPageConfig({ payload }) {
  try {
    const { type, ...config } = payload;
    const pageConfig = yield select((s) => s.libraryPage.pageConfig);
    const newConfig = {
      ...pageConfig,
      [type]: {
        ...pageConfig[type],
        ...config,
      },
    };
    const savedConfig = {
      automations: { ...newConfig.automations, page: undefined },
      broadcasts: { ...newConfig.broadcasts, page: undefined },
      templates: { ...newConfig.templates, page: undefined },
      [type]: { ...newConfig[type], page: undefined },
    };
    yield all([
      call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, savedConfig),
      put(updatePageConfig(newConfig)),
    ]);
  } catch (error) {}
}

function* libraryPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield debounce(DEFAULT_DELAY, getTemplatesAction.type, getTemplates);
  yield debounce(DEFAULT_DELAY, getBroadcastsAction.type, getBroadcasts);
  yield debounce(DEFAULT_DELAY, getAutomationsAction.type, getAutomations);
  yield takeLatest(duplicateTemplateAction.type, duplicateTemplate);
  yield takeLatest(deleteTemplateAction.type, deleteTemplate);
  yield takeLatest(setPageConfigAction.type, setPageConfig);
}

export default libraryPage;
