import React, { useEffect, useCallback, useRef } from 'react';
import * as PropTypes from 'prop-types';
import { STEPS, FORMDATA_FIELDS } from '../config';
import { useTranslation } from 'react-i18next';
import { StyledSection } from '../styled';
import { ActivityAssosiationFields } from '../components';
import { isContact } from 'utility/fieldHelpers';

const { RELATED_OBJECTS } = FORMDATA_FIELDS;

const checkValidObjects = ({ relatedObjects }) =>
  Boolean(relatedObjects?.length);

const CompleteActivityObjects = ({
  assignments,
  dispatchAssignment,
  activityRelatedObjects,
  relatedObjects,
  updateValidation,
  updateField,
  loading,
  setShowErrors,
  getKeyListenersProps,
}) => {
  const { t } = useTranslation();
  const isValid = checkValidObjects({ relatedObjects });

  const initRef = useRef(true);

  useEffect(() => {
    updateValidation(STEPS.RELATED_OBJECTS, isValid);
  }, [isValid, updateValidation]);

  const handleOnChange = useCallback(
    (setterProp) => (value) => {
      setShowErrors([]);
      initRef.current = false;
      dispatchAssignment({
        ...setterProp,
        value,
      });
    },
    [dispatchAssignment, setShowErrors]
  );

  useEffect(() => {
    if (activityRelatedObjects.length) {
      updateField(
        RELATED_OBJECTS,
        activityRelatedObjects
          .filter((v) => v.name !== '')
          .map((obj) => ({
            customObject: obj.id,
            entityId:
              assignments[obj.component.getterProp]?.id ||
              assignments[obj.component.getterProp]?.value,
            isContact: isContact(obj),
          }))
          .filter((v) => v.entityId),
        initRef.current
      );
    }
  }, [assignments, activityRelatedObjects, updateField]);

  return (
    <StyledSection
      index={0}
      header={t('Verify Associations')}
      isActive
      isEmpty={!activityRelatedObjects.length}
    >
      <ActivityAssosiationFields
        getKeyListenersProps={getKeyListenersProps}
        activityRelatedObjects={activityRelatedObjects}
        loading={loading}
        handleChange={handleOnChange}
        assignments={assignments}
      />
    </StyledSection>
  );
};

CompleteActivityObjects.propTypes = {
  updateField: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  relatedObjects: PropTypes.array.isRequired,
  assignments: PropTypes.object.isRequired,
  dispatchAssignment: PropTypes.func.isRequired,
  activityRelatedObjects: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  setShowErrors: PropTypes.func,
};

export default CompleteActivityObjects;
