import { format, addMinutes } from 'date-fns';
import { invalidate } from 'queries/invalidate';
import pluralize from 'pluralize';
import { get } from 'lodash';
import whichServiceToUse, { getTimezoneOffset } from 'services/utils';
import PipelineService from './PipelineService';
import CustomObjectsService, {
  checkDataExistsInFilter,
  filterDataMatches,
} from './CustomObjectsService';
import { getUserLabel } from 'utility/TransformToSelectOptions';
import { FIELD_TYPES } from 'utility/constants';
import AxiosService, { DefaultAxiosRetryConfig } from './AxiosService';
import ClientService, { clientForApp } from './ClientService';
import TeamMemberService from './TeamMemberService';
import {
  camelToSnakeCaseKeys,
  FORCE_ALL_RECORDS_SIZE,
  snakeToCamelCaseKeys,
  snakeToCamelCase,
  fieldForApp,
  categoryForApp,
  descriptionToOption,
  getOptionValue,
} from './helpers';
import { isSummarizedValue } from 'components/Fields/helpers';
import {
  ENTITIES_TYPEAHEAD_PAGE_SIZE,
  TIMEZONE_OFFSET_HEADER,
} from './constants';
import { isStageField } from 'checks/fields';

const FIELD_VALUES = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  BIRTHDAY: 'birthday',
  EMAIL: 'email',
  COMPANY: 'company',
  CREATED: 'created',
  UPDATED: 'updated',
  PHONE_NUMBER: 'phonenumber',
  MOBILE_PHONE: 'mobile_phone',
  BUSINESS_PHONE: 'business_phone',
  CITY: 'city',
  COUNTRY_STATE: 'country_state',
  STATE: 'state',
  COUNTRY: 'country',
  CHECKBOX: 'checkbox',
  FILES: 'files',
  TEXT: 'text',
  DATE: 'date',
  EMAIL_STATUS: 'email_status',
  CHECKBOXES: 'checkboxes',
  INTEGER: 'integer',
  MONEY: 'money',
  SELECTOR: 'team_selector',
  DECIMAL: 'decimal',
  DROPDOWN: 'dropdown',
  TIMEZONE: 'timezone',
  LONGTEXT: 'longtext',
  DATETIME: 'datetime',
  RATING: 'rating',
  RADIO: 'radio',
  DYNAMIC_TAGS: 'dynamictags',
  STATUS: 'status',
  YES_NO_MAYBE: 'yesnomaybe',
  RELATIONSHIP: 'relationship',
  YES_NO: 'yesno',
  TAGS: 'tags',
};

const getRelatedObjectId = (field) => {
  const relation = field?.relation || field?.relations?.[0];
  return relation?.relatedObject || relation?.model || '';
};

const objectUrls = {
  contacts: {
    url: 'client',
    model: 'client_client',
  },
  companies: {
    url: 'company',
    model: 'client_clientcompany',
  },
  customObjects: {
    url: ({ id, objectType = 'standard' }) => {
      return objectType === 'pipeline'
        ? `pipelines/${id}`
        : `custom-objects/${id}`;
    },
  },
  products: {
    url: 'commerce/product',
    model: null, // TODO
  },
};

const customObjectsUrlMap = {
  standard: 'custom-objects',
  pipeline: 'pipelines',
};

const getFetchUrl = (fetchUrl, relation) => {
  if (fetchUrl === 'standard' || fetchUrl === 'pipeline') {
    return `${customObjectsUrlMap[fetchUrl]}/${relation.relatedObject}/entity-records`;
  }

  return fetchUrl;
};

// ID is not used by the backend, it is added only on the frontend and is used as a key for the Select dropdown
export const cityObject = {
  label: 'City',
  value: '001',
  fieldData: {
    id: '001',
    description: 'City',
    isDefault: true,
    name: FIELD_VALUES.CITY,
    fieldType: FIELD_VALUES.TEXT,
  },
};
// ID is not used by the backend, it is added only on the frontend and is used as a key for the Select dropdown
export const countryStateObject = {
  label: 'Country/State',
  value: '002',
  fieldData: {
    id: '002',
    description: 'Country/State',
    isDefault: true,
    name: FIELD_VALUES.COUNTRY_STATE,
    fieldType: FIELD_VALUES.DROPDOWN,
  },
};

const assertObject = (forObject) => {
  if (typeof forObject === 'string' && !objectUrls[forObject]) {
    throw new Error(`Kizen object ${forObject} is unknown`);
  }
  if (forObject?.id && !objectUrls[snakeToCamelCase(forObject?.objectClass)]) {
    throw new Error(`Kizen object ${forObject.id} is unknown`);
  }
};

export const getObjectUrl = (forObject) => {
  if (typeof forObject === 'string')
    return objectUrls[forObject] && objectUrls[forObject].url;
  return (
    forObject.objectClass &&
    objectUrls[snakeToCamelCase(forObject.objectClass)].url(forObject)
  );
};

export const pluralizeFetchUrl = (name) => pluralize(name);

export const getObjectModelName = (name) =>
  objectUrls[name] && objectUrls[name].model;

export const getFullFieldValue = (fv, field, isCustom = false) => {
  if (!isCustom && field.isDefault) {
    if (field.fieldType === FIELD_TYPES.Dropdown.type) {
      // sometines like with the stages option swe also need to look for the id
      const option = field.options.find(
        (opt) => opt.code === fv[field.name] || opt.id === fv[field.name]
      );
      if (option?.id) {
        return option.id;
      }
      if (fv[field.name]) {
        return fv[field.name];
      }
    }

    if (field.fieldType === FIELD_TYPES.TeamSelector.type) {
      return fv[field.name];
    }

    return fv[field.name];
  }
  if (field.fieldType === FIELD_TYPES.Files.type) {
    // id on the way up
    return fv.name;
  }
  if (
    !isCustom &&
    (field.fieldType === FIELD_TYPES.Relationship.type ||
      field.fieldType === FIELD_TYPES.DynamicTags.type)
  ) {
    // id on the way up
    return {
      id: fv.value,
      label: fv.display_name || fv.displayName || fv.name,
    };
  }
  if (isCustom && field.fieldType === FIELD_TYPES.Checkboxes.type) {
    // id on the way up
    return fv.value.map(({ id }) => id);
  }
  if (isCustom && field.fieldType === FIELD_TYPES.Money.type && fv.value) {
    // id on the way up
    return fv.value.amount;
  }

  if (!isCustom && field.fieldType === FIELD_TYPES.TeamSelector.type) {
    return { id: fv.value, label: fv.name };
  }

  return fv.value;
};

export const DATETIME_VALUE_FORMAT = 'YYYY-MM-DD HH:mm'; // It goes up in this format, but comes down in ISO

// The value format doesn't have a timezone, so we need to ensure to make it relative to UTC
const formatForUtc = (date) => {
  const offsetMins = date.getTimezoneOffset();
  return format(addMinutes(date, offsetMins), DATETIME_VALUE_FORMAT);
};

export const getPayloadFieldValue = (val, field, isCustom = false) => {
  // Handles both "full" field values (see function above) and already-mapped field values (e.g. inline fields)
  if (!val) {
    return val;
  }

  if (!isCustom && field.isDefault) {
    if (field.fieldType === FIELD_TYPES.Dropdown.type) {
      const option = field.options.find((opt) => opt.id === val);
      return option.code;
    }

    return val;
  }
  if (field.fieldType === FIELD_TYPES.Files.type) {
    return typeof val === 'string' ? val : val.id;
  }
  if (field.fieldType === FIELD_TYPES.DateTime.type) {
    const date = val && new Date(val);
    return date && formatForUtc(date);
  }
  if (
    field.fieldType === FIELD_TYPES.Relationship.type ||
    field.fieldType === FIELD_TYPES.DynamicTags.type
  ) {
    return typeof val === 'string' ? val : val.id;
  }

  if (field.fieldType === FIELD_TYPES.TeamSelector.type) {
    return val.id;
  }

  return val;
};

export const isFieldAccessible = ({ field, allowHidden = false }) => {
  return (
    (allowHidden === true || !field.isHidden) &&
    field.access.view &&
    (field.fieldType === FIELD_TYPES.Relationship.type
      ? !!field.relation
      : true)
  );
};

export const isFieldAvailable = (field) => {
  return isFieldAccessible({ field, allowHidden: false });
};

// NOTE in the webapp codebase, see apps/fields/constants.py for relevant config and constants

class FieldService {
  constructor() {
    if (!FieldService.instance) {
      FieldService.instance = this;
    }
    return FieldService.instance;
  }
  // because the service gets frozen, we put the abortControllers in an object so they can be updated
  abortControllers = {
    getModelRecords: undefined,
  };

  objectEndpoint = 'custom-objects';

  pipelineEndpoint = 'pipelines';

  clientEndpoint = 'client';

  getModel = async ({ id }, options) => {
    const { data } = await AxiosService.get(`/custom-objects/${id}`, options);
    return snakeToCamelCaseKeys(data);
  };

  // get models starting with the client, and then optional custom fields
  getModelsWithPriority = async ({
    clientObjectId,
    includeCustomObjects = false,
  }) => {
    const client = await this.getModel({ id: clientObjectId });
    let objects = [descriptionToOption(client)];

    if (includeCustomObjects) {
      const {
        data: { results },
      } = await AxiosService.get(`/custom-objects`, {
        params: { page_size: FORCE_ALL_RECORDS_SIZE },
      });

      const customObjects = results
        .map(descriptionToOption)
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        );

      objects = [...objects, ...customObjects];
    }

    return snakeToCamelCaseKeys(objects);
  };

  getCategorizedFields = async (...args) => {
    const [fields, categories] = await Promise.all([
      FieldService.instance.getFields(...args),
      FieldService.instance.getCategories(...args),
    ]);
    return categories.map((category) => {
      return {
        ...category,
        fields: fields.filter((field) => field.category === category.id),
      };
    });
  };

  getCategorizedCustomFields = async (
    { id, ...object },
    withoutRestrictions = false
  ) => {
    const serviceToUse = whichServiceToUse(object);
    const [fields, categories] = await Promise.all([
      withoutRestrictions
        ? serviceToUse.getObjectFieldsWithoutRestrictions(id)
        : serviceToUse.getObjectFields(id),
      serviceToUse.getObjectCategories(id, object.fetchUrl === 'client'),
    ]);
    return categories
      .map((category) => {
        return {
          ...category,
          fields: fields.filter((field) => field.category === category.id),
        };
      })
      .map((cat) => {
        return {
          id: cat.id,
          label: cat.name,
          options: cat.fields.map((item) => ({
            label: item.displayName,
            value: item.id,
            item,
          })),
        };
      });
  };

  getTeamMemberFields = async () => {
    const { data } = await AxiosService.get(
      `/client/fields/search?field_type=team_selector`
    );
    return snakeToCamelCaseKeys(data);
  };

  getCategorizedTeamMemberFields = async () => {
    const [fields, categories] = await Promise.all([
      FieldService.instance.getTeamMemberFields(),
      FieldService.instance.getCategories({ for: 'contacts' }),
    ]);
    return categories
      .map((category) => {
        return {
          ...category,
          fields: fields.filter((f) => f.category === category.id),
        };
      })
      .map((cat) => {
        return {
          label: cat.name,
          options: cat.fields.map((item) => {
            return {
              label: item.displayName,
              value: item.id,
              item,
            };
          }),
        };
      })
      .filter(({ options = [] }) => options.length);
  };

  getObjectCategories = async (id, isClient = false) => {
    const url =
      id && !isClient
        ? `/${this.objectEndpoint}/${id}/categories`
        : `/${this.clientEndpoint}/categories`;
    const { data } = await AxiosService.get(url);
    return snakeToCamelCaseKeys(data);
  };

  // for speed only gets the one required field
  getCategorizedCustomField = async ({ object, objectFieldId }) => {
    const serviceToUse = whichServiceToUse(object);
    const [field, categories] = await Promise.all([
      serviceToUse.getObjectField(objectFieldId),
      serviceToUse.getObjectCategories(object.id),
    ]);
    const fields = [field];

    return categories
      .map((category) => {
        return {
          ...category,
          fields: fields.filter((f) => f.category === category.id),
        };
      })
      .map((cat) => {
        return {
          label: cat.name,
          options: cat.fields.map((item) => ({
            label: item.description,
            value: item.id,
            item,
          })),
        };
      });
  };

  getGroupedFields = async () => {
    const cats = await this.getCategorizedFields({ for: 'contacts' });
    const data = cats.reduce((accumulator, cat) => {
      const option = {
        label: cat.name,
        options: cat.fields.filter(isFieldAvailable).map((item) => ({
          label: item.displayName,
          value: item.id,
          fieldData: item, // [stuart] because the new selector needs value to be a string
        })),
      };
      accumulator.push(option);
      return accumulator;
    }, []);
    data.push({
      label: 'Other',
      options: [cityObject, countryStateObject],
    });
    return data;
  };

  getField = async (id, model, args) => {
    const { data } = model
      ? await AxiosService.get(`/${getObjectUrl(model)}/fields/${id}`, args)
      : await AxiosService.get(`/field/${id}`, args);

    if (data.access && !data.access.view) {
      return null;
    }
    return fieldForApp(data);
  };

  getCustomObjectField = async (modelId, fieldId, options) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${modelId}/fields/${fieldId}`,
      options
    );

    if (data.access && !data.access.view) {
      return null;
    }
    return fieldForApp(data);
  };

  getClientField = async (id, opts) => {
    const { data } = await AxiosService.get(`/client/fields/${id}`, opts);
    if (data.access && !data.access.view) {
      return null;
    }
    return fieldForApp(data);
  };

  getFields = async ({
    for: forObject,
    includeVisible = false,
    settingsRequest = false,
    params = {},
    skipErrorBoundary = false,
  } = {}) => {
    assertObject(forObject);
    const { data } = settingsRequest
      ? await AxiosService.get(
          `/${getObjectUrl(forObject)}/fields/settings-search`,
          {
            skipErrorBoundary,
          }
        )
      : await AxiosService.post(
          `/${getObjectUrl(forObject)}/fields/search`,
          {
            params,
          },
          {
            skipErrorBoundary,
          }
        );
    // Fields are an exception to permissions rules in that they always come
    // down from the API even if the user doesn't have "view" access.
    // So we will need to filter those out most of the time, just not in the
    // PermissionsGroup modal, since we need the full field list to build permission
    // groups payloads.
    if (settingsRequest) {
      return data
        .map(({ access, ...f }) => ({
          ...f,
          access: { ...access, edit: true, view: true },
        }))
        .map(fieldForApp);
    } else if (!includeVisible) {
      return data.filter(({ access }) => access.view).map(fieldForApp);
    }

    return data.map(fieldForApp);
  };

  getRelationFieldValue = async (field, optionId) => {
    const { relation = {} } = field;
    const { fetchUrl } = relation;
    const { data } = await AxiosService.get(
      `/${getFetchUrl(fetchUrl, relation)}/${optionId}`
    );
    return snakeToCamelCaseKeys(data);
  };

  getFieldOptions = async ({ for: forObject, field }, options) => {
    if (field.properties) {
      if (field.properties.allowOptions) {
        const { data } = await AxiosService.get(
          `/${getObjectUrl(forObject)}/fields/${field.id}/options`,
          options
        );
        return data;
      }
      if (field.properties.allowRelations && field.relation) {
        const { relation } = field;
        const { data } = await AxiosService.get(`/${relation.fetchUrl}`);
        return data.results;
      }
    }

    return [];
  };

  getFieldOption = async ({ for: forObject, field, optionId }, options) => {
    if (field.properties) {
      if (field.properties.allowOptions) {
        const { data } = await AxiosService.get(
          `/${getObjectUrl(forObject)}/fields/${field.id}/options/${optionId}`,
          options
        );
        return data;
      }
    }

    return null;
  };

  getStateOptions = async () => {
    const { data } = await AxiosService.get(
      '/geography/countries/all/subdivisions'
    );

    return data.results;
  };

  getCountryOptions = async () => {
    const { data } = await AxiosService.get('/geography/countries');

    return data;
  };

  getCategories = async ({
    for: forObject,
    params = {},
    skipErrorBoundary,
  }) => {
    assertObject(forObject);
    const { data } = await AxiosService.get(
      `/${getObjectUrl(forObject)}/categories`,
      { params, skipErrorBoundary }
    );
    return data.map(categoryForApp);
  };

  getModelRecords = async (
    { model, params: paramsProp, body, signal = null },
    options
  ) => {
    const { criteria, fieldIds } = body;
    const additionalConfig = options || {};
    const { skipErrorBoundary = () => false, ...params } = paramsProp;
    if (
      criteria &&
      checkDataExistsInFilter(criteria, filterDataMatches.timezoneRelated)
    ) {
      additionalConfig.headers = {
        [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
      };
    }
    const bodyParams = {
      ...camelToSnakeCaseKeys(criteria),
      field_ids: fieldIds || [],
    };

    const { data } = await AxiosService.post(
      `/${getObjectUrl(model)}/entity-records`,
      bodyParams,
      {
        signal,
        params: camelToSnakeCaseKeys(params),
        skipErrorBoundary,
        ...additionalConfig,
      }
    );
    return {
      ...data,
      results: snakeToCamelCaseKeys(data.results),
    };
  };

  getModelRecordsCancelable = async (props, options) => {
    // Check if there are any previous pending requests
    if (this.abortControllers.getModelRecords) {
      this.abortControllers.getModelRecords.abort();
    }

    // Save the cancel token for the current request
    this.abortControllers.getModelRecords = new AbortController();

    return this.getModelRecords(
      {
        ...props,
        signal: this.abortControllers.getModelRecords.signal,
      },
      options
    );
  };

  getModelRecordsCount = async ({ model, params, body }, options) => {
    const { count } = await this.getModelRecords(
      {
        model,
        params: { ...params, ordering: null, page: 1, pageSize: 1, search: '' },
        body,
      },
      options
    );

    return count;
  };

  getCustomModelRecords = async ({
    modelId,
    search = '',
    page = 1,
    ordering = 'name',
    pageSize = 50,
    groupId = '',
  }) => {
    const { data } = await AxiosService.get(
      `/custom-objects/${modelId}/entity-records?search=${search}&page=${page}&page_size=${pageSize}&ordering=${ordering}&group_id=${groupId}`
    );
    return snakeToCamelCaseKeys(data);
  };

  getCustomModelRecord = async ({ id, modelId }) => {
    const { data } = await AxiosService.get(
      `/custom-objects/${modelId}/entity-records/${id}`
    );
    return snakeToCamelCaseKeys(data);
  };

  deleteCustomModelRecord = async ({ id, modelId }) => {
    return AxiosService.delete(
      `/custom-objects/${modelId}/entity-records/${id}`
    );
  };

  createCustomModelRecord = async (payload, params) => {
    const { data } = await AxiosService.post(
      `/custom-objects/${payload.model}/entity-records/add`,
      camelToSnakeCaseKeys(payload),
      params
    );

    return snakeToCamelCaseKeys(data);
  };

  patchCustomModelRecord = async (
    id,
    modelId,
    body,
    options,
    updateTimeline = true
  ) => {
    const { data } = await AxiosService.patch(
      `/custom-objects/${modelId}/entity-records/${id}`,
      camelToSnakeCaseKeys(body),
      options
    );

    if (updateTimeline) {
      invalidate.TIMELINE.ALL();
    }

    return snakeToCamelCaseKeys(data);
  };

  getCategory = async ({ category }) => {
    const { data } = await AxiosService.get(
      `/field-category?search=${category}`
    );
    return data.map(categoryForApp);
  };

  createField = async (payload) => {
    const { data } = await AxiosService.post(
      `/field`,
      camelToSnakeCaseKeys(payload)
    );
    return fieldForApp(data);
  };

  patchField = async (id, payload) => {
    const { data } = await AxiosService.patch(
      `/field/${id}`,
      camelToSnakeCaseKeys(payload)
    );
    return fieldForApp(data);
  };

  deleteField = async (id) => {
    const { data } = await AxiosService.delete(`/field/${id}`);
    return data;
  };

  createObject = async (
    { for: forObject },
    values,
    fields,
    unarchive,
    params = {}
  ) => {
    assertObject(forObject);
    const { data } = await AxiosService.post(
      `/${getObjectUrl(forObject)}`,
      {
        ...FieldService.instance.getPayload(
          camelToSnakeCaseKeys(values),
          fields
        ),
        unarchive,
      },
      params
    );
    // We do this to account for the desktop and mobile views of the contacts page
    // expecting different shapes for the same contacts data.
    // This mapping is additive, not destructive: the resulting object contains
    // both camel-cased and snake-cased keys.
    if (forObject === 'contacts') {
      return clientForApp(data);
    }
    return data;
  };

  patchObject = async (
    { for: forObject, id, params },
    values,
    fields,
    unarchive
  ) => {
    assertObject(forObject);
    const { data } = await AxiosService.patch(
      `/${getObjectUrl(forObject)}/${id}`,
      {
        ...FieldService.instance.getPayload(
          camelToSnakeCaseKeys(values),
          fields
        ),
        unarchive,
      },
      { params }
    );
    // We do this to account for the desktop and mobile views of the contacts page
    // expecting different shapes for the same contacts data.
    // This mapping is additive, not destructive: the resulting object contains
    // both camel-cased and snake-cased keys.
    if (forObject === 'contacts') {
      return clientForApp(data);
    }
    invalidate.FORMS.FORM_RELATED_OBJECT_FIELDS(id);
    return data;
  };

  updateObject = async (forObject, id, patch) => {
    assertObject(forObject);
    const { data } = await AxiosService.patch(
      `/${getObjectUrl(forObject)}/${id}`,
      camelToSnakeCaseKeys(patch)
    );
    return data;
  };

  getAllowMultiple = (field) => {
    const { relation, properties } = field;
    if (relation) {
      return (
        relation.cardinality === 'one_to_many' ||
        relation.cardinality === 'many_to_many'
      );
    }
    return properties?.allowMultiple || false;
  };

  getFieldValue = (object, field, isCustom = false) => {
    const isSummarized = object.fields.find(
      (fv) => field.id === fv.field && fv.valueSummary
    );
    if (isSummarized) {
      return isSummarized.valueSummary;
    }
    const allowMultiple = FieldService.instance.getAllowMultiple(field);
    if (!isCustom && field.isDefault) {
      const value = getFullFieldValue(object, field);
      if (allowMultiple) {
        return [].concat(value || []);
      }
      return value;
    }

    const values = object.fields
      .filter((f) => f.field === field.id)
      .reduce((collect, fv) => {
        const fieldValue = getFullFieldValue(fv, field, isCustom);

        if (
          fv.fieldType === FIELD_TYPES.TeamSelector.type &&
          Array.isArray(fieldValue)
        ) {
          return [...collect, ...fieldValue];
        }

        if (
          isCustom &&
          (fv.fieldType === FIELD_TYPES.Checkboxes.type ||
            fv.fieldType === FIELD_TYPES.Relationship.type ||
            fv.fieldType === FIELD_TYPES.DynamicTags.type) &&
          Array.isArray(fieldValue)
        ) {
          return [...collect, ...fieldValue];
        }

        return [...collect, fieldValue];
      }, []);
    if (allowMultiple) {
      return values;
    }
    return values.length ? values[0] : null;
  };

  getFormValues = (object, fields) => {
    return fields.reduce(
      (collect, field) => ({
        ...collect,
        [field.id]: FieldService.instance.getFieldValue(object, field),
      }),
      {}
    );
  };

  getFieldName = (object, field) => {
    if (field.isDefault) {
      return object[field.name];
    }
    const objectField =
      object.fields && object.fields.filter((f) => f.field === field.id);
    if (!objectField) {
      return [];
    }
    return objectField.map(({ name }) => name);
  };

  getCorrespondingName = (object, valueId) => {
    const objectField =
      object.fields && object.fields.find((f) => f.value === valueId);
    if (!objectField) {
      return null;
    }
    return objectField.name;
  };

  // NOTE: when updating some but not all fields use PATCH rather than PUT
  getPayload = (values, fields) => {
    // Support passing a list of fields or categorized fields
    fields = fields
      .flatMap((field) => field.fields || field)
      .filter((f) => f.access.edit);
    const fieldsByIdOrName = fields.reduce(
      (collect, field) => ({
        ...collect,
        [field.id]: field,
        [field.isDefault ? field.name : field.id]: field,
      }),
      {}
    );

    return Object.entries(values).reduce(
      (collect, [fieldIdOrName, value]) => {
        // Support values consisting of either field id or field name keys

        // TODO: we learned that field.name is actually not unique, so we need
        // to remove support for keying by name, unfortunately. Not doing now
        // so that we don't break anything that is mostly-working for August demos.

        const field = fieldsByIdOrName[fieldIdOrName];
        if (!field) {
          return collect;
        }
        if (field.isDefault) {
          // Default fields go directly on the record
          return {
            ...collect,
            [field.name]: getPayloadFieldValue(value, field),
          };
        }

        if ([FIELD_TYPES.PhoneNumber.type].includes(field.fieldType)) {
          value = getPayloadFieldValue(value, field, true);
        }

        const valueIsEmpty =
          (!value && value !== 0) ||
          (Array.isArray(value) && value.length === 0);

        // Non-default fields go inside the fields list
        return {
          ...collect,
          fields: [
            ...collect.fields,
            {
              field: field.id,
              values: valueIsEmpty
                ? []
                : []
                    .concat(value)
                    .map((val) => getPayloadFieldValue(val, field)),
            },
          ],
        };
      },
      {
        fields: [],
      }
    );
  };

  getCustomPayload = (values, fields) => {
    // Support passing a list of fields or categorized fields
    fields = fields
      .flatMap((field) => field.fields || field)
      .filter((f) => f.access.edit && !f.isHidden);

    const fieldsByIdOrName = fields.reduce(
      (collect, field) => ({
        ...collect,
        [field.id]: field,
        [field.isDefault ? field.name : field.id]: field,
      }),
      {}
    );

    return Object.entries(values).reduce(
      (collect, [fieldIdOrName, value]) => {
        // Support values consisting of either field id or field name keys

        const field = fieldsByIdOrName[fieldIdOrName];
        if (!field) {
          return collect;
        }

        //Special case for summarized values when we have more than 100 items in value
        if (isSummarizedValue(value)) {
          const nextValue = {
            add_values: (value.add_values || []).map((val) =>
              getPayloadFieldValue(val, field, true)
            ),
            remove_values: (value.remove_values || []).map((val) =>
              getPayloadFieldValue(val, field, true)
            ),
          };
          return field.isDefault
            ? {
                ...collect,
                [field.name]: nextValue,
              }
            : {
                ...collect,
                fields: [
                  ...collect.fields,
                  {
                    id: field.id,
                    ...nextValue,
                  },
                ],
              };
        }

        if (field.fieldType === FIELD_TYPES.Timezone.type) {
          if (typeof value === 'object' && value !== null && 'id' in value) {
            value = value.id;
          }
        }

        // the api is expecting an array for relationships, selector and dynamictags, either empty or an array of id's
        // it can be passed as either an object or null so we take care of it here
        if (
          [
            FIELD_TYPES.Relationship.type,
            FIELD_TYPES.DynamicTags.type,
          ].includes(field.fieldType)
        ) {
          if (value && typeof value === 'object' && 'id' in value) {
            value = [value];
          } else if (!value) {
            value = [];
          }
        }
        if (
          [FIELD_TYPES.Dropdown.type, FIELD_TYPES.Status.type].includes(
            field.fieldType
          )
        ) {
          if (value && typeof value === 'object' && 'id' in value) {
            value = [value];
          } else if (!value) {
            value = null;
          }
        }
        if ([FIELD_TYPES.PhoneNumber.type].includes(field.fieldType)) {
          value = getPayloadFieldValue(value, field, true);
        }
        // we don't want to consider falsy i.e. null but allow false
        // except Number fields can be null
        if (
          (![
            FIELD_TYPES.Dropdown.type,
            FIELD_TYPES.Status.type,
            FIELD_TYPES.Decimal.type,
            FIELD_TYPES.Integer.type,
            FIELD_TYPES.Number.type,
            FIELD_TYPES.Money.type,
            FIELD_TYPES.Price.type,
            FIELD_TYPES.PhoneNumber.type,
            FIELD_TYPES.Rating.type,
            FIELD_TYPES.YesNo.type,
            FIELD_TYPES.YesNoMaybe.type,
            FIELD_TYPES.DateTime.type,
            FIELD_TYPES.Date.type,
            FIELD_TYPES.TeamSelector.type,
          ].includes(field.fieldType) &&
            value === null) ||
          value === undefined
        ) {
          return collect;
        }

        if (field.isDefault) {
          // stage is a special case as it needs to return not as part of the fields but as the stageId
          if (isStageField(field) && value) {
            return {
              ...collect,
              stageId: value,
            };
          }
          if (field.fieldType === FIELD_TYPES.TeamSelector.type) {
            return {
              ...collect,
              ownerId: value?.id,
            };
          }
          return {
            ...collect,
            [field.name]: Array.isArray(value)
              ? []
                  .concat(value)
                  .map((val) => getPayloadFieldValue(val, field, true))
              : value,
          };
        }
        if (field.fieldType === FIELD_TYPES.TeamSelector.type) {
          value = value ? value?.id : [];
        }
        // Non-default fields go inside the fields list
        return {
          ...collect,
          fields: [
            ...collect.fields,
            {
              id: field.id,
              value:
                value !== null && Array.isArray(value)
                  ? []
                      .concat(value)
                      .map((val) => getPayloadFieldValue(val, field, true))
                  : value,
            },
          ],
        };
      },
      {
        fields: [],
      }
    );
  };

  getCurrencies = async () => {
    const { data } = await AxiosService.get(`/constants/currencies`);

    return data;
  };

  validate = (values, fields, { patch = false } = {}) => {
    // Support passing a list of fields or categorized fields
    fields = fields.flatMap((field) => field.fields || field);
    return fields.every((field) => {
      const value = field.id in values ? values[field.id] : values[field.name];
      return FieldService.instance.validateValue(value, field, { patch });
    });
  };

  validateValue = (value, field, { patch = false } = {}) => {
    const isRequired = !patch && field.isRequired;
    if (typeof value === 'undefined') {
      return !isRequired;
    }
    if (value === null) {
      return !isRequired && field.allowsNulls;
    }
    const allowMultiple = FieldService.instance.getAllowMultiple(field);
    if (
      (Array.isArray(value) && !allowMultiple) ||
      (!Array.isArray(value) && allowMultiple)
    ) {
      return false;
    }
    if (value === '') {
      return !isRequired && field.allowsEmpty;
    }
    if (Array.isArray(value) && !value.length) {
      return field.allowsEmpty;
    }
    return true;
  };

  getSelectedRelationMappers = (field) => {
    const { isDefault, customObjectField = null } = field;
    let toOption;

    // if it has a custom object on intialization it will be name after update it will be name as the isDefault will be false
    if (customObjectField) {
      toOption = (selection) => {
        return (
          selection && {
            value: selection.id,
            label: customObjectField.isDefault
              ? selection.displayName || selection.name || selection.label
              : selection.displayName || selection.label,
          }
        );
      };
    } else {
      toOption = (selection) => {
        if (field.fieldType === FIELD_TYPES.TeamSelector.type) {
          return (
            selection && {
              value: selection.id,
              label: selection.label || selection.name,
            }
          );
        }
        return (
          selection && {
            value: selection.id || selection.value,
            label: field.isDefault
              ? selection.displayName || selection.name
              : selection.displayName || selection.label || selection.name,
          }
        );
      };
    }

    // TODO Reference that we're reversing FieldService.getFullFieldValue
    // These should be the reverse of each other
    const toValue = (option) =>
      option && {
        id: option.value,
        shortLabel: option.shortLabel,
        ...(isDefault
          ? { name: option.label }
          : {
              label: option.label,
            }),
      };

    return { toOption, toValue };
  };

  getLinkModelId = (field) => {
    return 'relation' in field
      ? get(field, 'relation.relatedObject', get(field, 'relation.model', ''))
      : get(field, 'relations[0].model', '');
  };

  getRelationshipInfo = (field, t, preReleaseFeatures) => {
    const localizedFunction = t && typeof t === 'function' ? t : (s) => s;
    const fetchUrl =
      'relation' in field
        ? get(field, 'relation.fetchUrl', '')
        : get(field, 'relations[0].fetchUrl', '');

    const config = {
      client: {
        create: async (value) => {
          if (typeof value === 'string') {
            const [firstName, ...lastNames] = (value || '').split(' ');
            return ClientService.create({
              firstName,
              lastName: lastNames.join(' '),
            });
          } else {
            return ClientService.create(value);
          }
        },
        label: getUserLabel,
        link: ({ id }) => `/client/${id}/details`,
        name: {
          single: localizedFunction('Contact'),
          plural: localizedFunction('Contacts'),
        },
        typeahead: ClientService.typeahead,
        detail: ClientService.getById,
        search: async (params, options) => {
          // In activities have a case when the field is the model,
          // so field.id is the id of the client model
          const objectId = getRelatedObjectId(field) || field.id;
          return await this.getRecordsTypeahead(objectId, {
            ...options,
            params: {
              search: '',
              page: 1,
              ordering: 'full_name',
              page_size: ENTITIES_TYPEAHEAD_PAGE_SIZE,
              ...params,
            },
          });
        },
      },
      company: {
        name: {
          single: localizedFunction('Company'),
          plural: localizedFunction('Companies'),
        },
      },
      team: {
        isNameOnlyForCreate: false,
        label: getUserLabel,
        link: preReleaseFeatures
          ? ({ id }) => `/team-member/${id}/timeline`
          : null,
        name: {
          single: localizedFunction('Team Member'),
          plural: localizedFunction('Team Members'),
        },
        typeahead: TeamMemberService.getTeamMemberList,
        detail: TeamMemberService.get,
        search: async (args) => {
          return await TeamMemberService.getTeamMemberTypeahead({
            search: '',
            page: 1,
            ordering: 'first_name',
            page_size: 20,
            ...args,
          });
        },
      },
      'field-model-custom': {
        create: async (name) => {
          const model =
            'relation' in field
              ? get(field, 'relation.relatedObject', '')
              : get(field, 'relations[0].relatedObject', '');
          const { data } = await AxiosService.post(
            `/custom-objects/${model}/entity-records/add`,
            {
              name,
            }
          );
          return data;
        },
        // To decide whether a user can create an object from a simple text input e.g. Select
        // or if the object must be created via a more complex form
        isNameOnlyForCreate: true,
        label: ({ name, displayName }) => displayName || name,
        link: (option) =>
          `/custom-objects/${this.getLinkModelId(field)}/${getOptionValue(
            option
          )}/details`,
        name: {
          single: pluralize(
            get(field, 'relations[0].objectName', t('Item')),
            1
          ),
          plural: pluralize(
            get(field, 'relations[0].objectName', t('Items')),
            2
          ),
        },
        typeahead: async ({ search }, options) => {
          const { data } = await AxiosService.get(
            `/records/${field?.id}/typeahead-search`,
            {
              ...options,
              params: {
                search,
                ...options?.params,
                page_size: ENTITIES_TYPEAHEAD_PAGE_SIZE,
              },
            }
          );
          return snakeToCamelCaseKeys(
            Array.isArray(data) ? data : data.results
          );
        },
        search: async (params, options) => {
          const objectId = getRelatedObjectId(field);
          return await this.getRecordsTypeahead(objectId, {
            ...options,
            params: {
              search: '',
              page: 1,
              ordering: 'name',
              page_size: ENTITIES_TYPEAHEAD_PAGE_SIZE,
              ...params,
            },
          });
        },
      },
      standard: {
        create: async (name) => {
          const { data } = await AxiosService.post(
            `/custom-objects/${get(
              field,
              'relation.relatedObject'
            )}/entity-records/add`,
            {
              name,
            }
          );
          return data;
        },
        // To decide whether a user can create an object from a simple text input e.g. Select
        // or if the object must be created via a more complex form
        isNameOnlyForCreate: true,
        label: ({ name, displayName }) => displayName || name,
        link: (option) =>
          `/custom-objects/${getRelatedObjectId(field)}/${getOptionValue(
            option
          )}/details`,

        name: {
          single: localizedFunction('Record'),
          plural: localizedFunction('Records'),
        },
        typeahead: async ({ search }, options) => {
          const { data } = await AxiosService.get(
            `/records/${get(
              field,
              'relation.relatedObject'
            )}/typeahead-search?search=${encodeURIComponent(search)}`,
            {
              ...options,
              params: {
                ...options?.params,
                page_size: ENTITIES_TYPEAHEAD_PAGE_SIZE,
              },
            }
          );
          return snakeToCamelCaseKeys(
            Array.isArray(data) ? data : data.results
          );
        },
        search: async (params, options) => {
          const objectId = getRelatedObjectId(field);
          return await this.getRecordsTypeahead(objectId, {
            ...options,
            params: {
              search: '',
              page: 1,
              ordering: 'name',
              page_size: ENTITIES_TYPEAHEAD_PAGE_SIZE,
              ...params,
            },
          });
        },
        detail: (id, config) => {
          const objectId = getRelatedObjectId(field);
          return CustomObjectsService.getCustomObjectRecord(
            { id, objectId },
            config
          );
        },
      },
      pipeline: {
        create: async (name) => {
          const { data } = await AxiosService.post(
            `/pipelines/${get(
              field,
              'relation.relatedObject',
              null
            )}/entity-records/add`,
            {
              name,
            }
          );
          return data;
        },
        // To decide whether a user can create an object from a simple text input e.g. Select
        // or if the object must be created via a more complex form
        isNameOnlyForCreate: false,
        label: ({ name, displayName }) => displayName || name,
        link: (option) =>
          `/custom-objects/${getRelatedObjectId(field)}/${getOptionValue(
            option
          )}/details`,
        name: {
          single: localizedFunction('Record'),
          plural: localizedFunction('Records'),
        },
        typeahead: async ({ search }, options) => {
          const { data } = await AxiosService.get(
            `/records/${get(
              field,
              'relation.relatedObject'
            )}/typeahead-search?search=${encodeURIComponent(search)}`,
            {
              ...options,
              params: {
                ...options?.params,
                page_size: ENTITIES_TYPEAHEAD_PAGE_SIZE,
              },
            }
          );
          return snakeToCamelCaseKeys(
            Array.isArray(data) ? data : data.results
          );
        },
        search: async (params, options) => {
          const objectId = getRelatedObjectId(field);
          return await this.getRecordsTypeahead(objectId, {
            ...options,
            params: {
              search: '',
              page: 1,
              ordering: 'name',
              page_size: ENTITIES_TYPEAHEAD_PAGE_SIZE,
              ...params,
            },
          });
        },
        detail: (id, config) => {
          const objectId = getRelatedObjectId(field);
          return PipelineService.getPipelineRecord({ id, objectId }, config);
        },
      },
      default: {
        create: async (name) => {
          const { data } = await AxiosService.post(`/${fetchUrl}`, { name });
          return data;
        },
        // To decide whether a user can create an object from a simple text input e.g. Select
        // or if the object must be created via a more complex form
        isNameOnlyForCreate: true,
        label: ({ name }) => name,
        link: ({ id }) => `/${fetchUrl}/${id}/details`,
        name: {
          single: localizedFunction('Item'),
          plural: localizedFunction('Items'),
        },
        typeahead: async ({ search }, options) => {
          const { data } = await AxiosService.get(`/${fetchUrl}`, {
            params: { search },
            ...options,
          });
          return snakeToCamelCaseKeys(
            Array.isArray(data) ? data : data.results
          );
        },
      },
    };
    // todo we need the fetch url in teams
    const info = {
      ...config.default,
      ...config[fetchUrl],
    };
    return {
      ...info,
      toOption: (object) => ({
        value: object.id,
        label: info.label(object),
        shortLabel: object?.name || object?.email || object?.id,
      }),
      fetchUrl,
    };
  };

  replaceDynamicTag = async (origTagId, replacementTagId, fieldId) => {
    const { data } = await AxiosService.post(
      `/${this.clientEndpoint}/fields/${fieldId}/options/${origTagId}/replace`,
      { option_id: replacementTagId }
    );
    return data;
  };

  createDynamicTag = async ({ fieldId, name }, options) => {
    const { data } = await AxiosService.post(
      `/${this.clientEndpoint}/fields/${fieldId}/options`,
      {
        name,
      },
      options
    );
    return data;
  };

  deleteDynamicTag = async ({ fieldId, tagId }, options) => {
    await AxiosService.delete(
      `/${this.clientEndpoint}/fields/${fieldId}/options/${tagId}`,
      options
    );
  };

  updateDynamicTag = async ({ fieldId, tagId, name }, options) => {
    const { data } = await AxiosService.patch(
      `/${this.clientEndpoint}/fields/${fieldId}/options/${tagId}`,
      {
        name,
      },
      options
    );
    return data;
  };

  getDynamicTagsOptions = async (fieldId, objectId, page, search, options) => {
    const { data } = await AxiosService.get(
      `/${
        this.clientEndpoint
      }/fields/${fieldId}/tags?ordering=name&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}`,
      options
    );
    return data;
  };

  getDynamicTagsOptionsOrdered = async (
    fieldId,
    objectId,
    page,
    search,
    ordering = 'name',
    options
  ) => {
    const { data } = await AxiosService.get(
      `/${
        this.clientEndpoint
      }/fields/${fieldId}/options?ordering=${ordering}&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}&include_entity_count=true`,
      options
    );
    return data;
  };

  archiveCustomObjectRecord = async (recordId, { for: forObject }) => {
    await AxiosService.delete(
      `/${getObjectUrl(forObject)}/entity-records/${recordId}`
    );
  };

  // these are the "new" endpoints with matching names in the PipelineService

  getObjects = async (params = { pageSize: FORCE_ALL_RECORDS_SIZE }) => {
    const { data } = await AxiosService.get(`/${this.objectEndpoint}`, {
      params: camelToSnakeCaseKeys(params),
    });

    return data.results.map(snakeToCamelCaseKeys);
  };

  createObjectCategory = async (objectId, payload) => {
    const { data } = await AxiosService.post(
      `/${this.clientEndpoint}/categories`,
      camelToSnakeCaseKeys(payload)
    );
    invalidate.CUSTOM_OBJECTS.CONTACT_CATEGORIES();
    return categoryForApp(data);
  };

  patchObjectCategory = async (objectId, id, payload) => {
    const { data } = await AxiosService.patch(
      `/${this.clientEndpoint}/categories/${id}`,
      camelToSnakeCaseKeys(payload)
    );
    invalidate.CUSTOM_OBJECTS.CONTACT_CATEGORIES();
    return categoryForApp(data);
  };

  deleteObjectCategory = async (objectId, id) => {
    const { data } = await AxiosService.delete(
      `/${this.clientEndpoint}/categories/${id}`
    );
    invalidate.CUSTOM_OBJECTS.CONTACT_CATEGORIES();
    return data;
  };

  getObjectFields = async () => {
    const { data } = await AxiosService.get(`/${this.clientEndpoint}/fields`);
    return fieldForApp(data);
  };

  getObjectFieldsWithoutRestrictions = async () => {
    const { data } = await AxiosService.get(
      `/${this.clientEndpoint}/fields/settings-search`
    );
    return fieldForApp(data);
  };

  getObjectField = async (id) => {
    const { data } = await AxiosService.get(
      `/${this.clientEndpoint}/fields/${id}`
    );
    return fieldForApp(data);
  };

  createObjectField = async (objectId, payload, options) => {
    const { data } = await AxiosService.post(
      `/${this.clientEndpoint}/fields`,
      camelToSnakeCaseKeys(payload),
      { ...options, 'axios-retry': DefaultAxiosRetryConfig }
    );
    invalidate.CUSTOM_OBJECTS.CONTACT_FIELDS();
    invalidate.FORMS.FORM_RELATED_OBJECT_FIELDS(objectId);
    return fieldForApp(data);
  };

  patchObjectField = async (objectId, id, payload, options) => {
    const { data } = await AxiosService.patch(
      `/${this.clientEndpoint}/fields/${id}`,
      camelToSnakeCaseKeys(payload),
      { ...options, 'axios-retry': DefaultAxiosRetryConfig }
    );
    invalidate.CUSTOM_OBJECTS.CONTACT_FIELDS();
    invalidate.FORMS.FORM_RELATED_OBJECT_FIELDS(objectId);
    return fieldForApp(data);
  };

  deleteObjectField = async (objectId, id) => {
    const { data } = await AxiosService.delete(
      `/${this.clientEndpoint}/fields/${id}`
    );
    invalidate.CUSTOM_OBJECTS.CONTACT_FIELDS();
    invalidate.FORMS.FORM_RELATED_OBJECT_FIELDS(objectId);
    return data;
  };

  updateObjectStyles = async (objectId, categories) => {
    const { data } = await AxiosService.post(
      `/${this.clientEndpoint}/update-styles`,
      camelToSnakeCaseKeys({
        categories: categories.map(({ id, name, fields }) => ({
          id,
          name,
          fields: fields.map((field) => ({
            id: field.id,
            isHidden: field.isHidden,
            // Must ensure cols are set
            meta: { ...field.meta, cols: field.meta.cols || 1 },
          })),
        })),
      })
    );
    invalidate.FIELDS.CUSTOM_OBJECTS('standard', objectId);
    return snakeToCamelCaseKeys(data);
  };

  customObjectUploader = async (model, payload) => {
    const { data } = await AxiosService.post(
      `/${getObjectUrl(model)}/uploader`,
      camelToSnakeCaseKeys(payload)
    );
    return data;
  };

  createCustomObjectsRecord = async (objectId, body, params) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/entity-records/add`,
      camelToSnakeCaseKeys(body),
      params
    );
    return snakeToCamelCaseKeys(data);
  };

  getEntityRecordById = async (objectId, id) => {
    const { data } = await AxiosService.get(`/records/${objectId}/${id}`);
    return snakeToCamelCaseKeys(data);
  };

  getFieldValues = async (entityId, fieldId, params, config) => {
    const { data } = await AxiosService.get(
      `/records/${entityId}/field-values/${fieldId}`,
      {
        params: camelToSnakeCaseKeys(params),
        ...config,
      }
    );
    return {
      count: data.count,
      results: data.results.map(snakeToCamelCaseKeys),
    };
  };

  getRecordsTypeahead = async (objectId, options) => {
    const { data } = await AxiosService.get(
      `/records/${objectId}/typeahead-search`,
      options
    );

    return snakeToCamelCaseKeys(data);
  };

  getRelatedPipelines = async (id, params, body, skipErrorBoundary = false) => {
    const { data } = await AxiosService.post(
      `/records/${id}/related-pipeline-records`,
      camelToSnakeCaseKeys(body),
      {
        params: { ...params },
        skipErrorBoundary,
      }
    );
    return snakeToCamelCaseKeys(data);
  };
}

const instance = new FieldService();
Object.freeze(instance);

export default instance;
