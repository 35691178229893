import { useMemo } from 'react';
import {
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import ActionCell, { ActionHeadCell } from 'components/Tables/ActionCell';
import { useTranslation } from 'react-i18next';
import { TAG_ACTIONS } from '../constants';
import { getTagLink } from '../helpers';

export const useColumns = ({
  model,
  field,
  onSelectAction,
  tableRef,
  showTagLink,
  countLabel,
  tagLabel,
}) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        {
          id: 'spacer',
          headCell: <SizedCell width="20px" />,
          cell: <SizedCell width="20px" />,
        },
        {
          id: 'name',
          headCell: (
            <SortableHeadCell
              label={tagLabel || t('Tag')}
              minWidth="150px"
              padding="60px"
            />
          ),
          cell: <SizedCell minWidth="150px" padding="60px" />,
          format: (value, tag) => {
            return (
              <TextEllipsisWithTooltip
                {...(showTagLink
                  ? {
                      type: 'link',
                      to: getTagLink(model, field, tag),
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    }
                  : { as: 'span' })}
              >
                {value}
              </TextEllipsisWithTooltip>
            );
          },
        },
        field?.id && {
          id: 'entity_count',
          headCell: (
            <SortableHeadCell
              label={
                countLabel ||
                t('{{objectName}} with {{tagLabel}}', {
                  objectName: model.objectName || t('Submissions'),
                  tagLabel: tagLabel || t('Tag'),
                })
              }
              padding="60px"
              numeric
            />
          ),
          cell: <SizedCell padding="60px" />,
          format: (value) => {
            return (
              <TextEllipsisWithTooltip as="span">
                {value}
              </TextEllipsisWithTooltip>
            );
          },
        },
        {
          id: 'actions',
          headCell: <ActionHeadCell padding="16px" />,
          cell: (
            <ActionCell
              padding="16px"
              onSelectAction={onSelectAction}
              title={t('Edit {{tagLabel}}', { tagLabel: tagLabel || t('Tag') })}
              onOpen={(_, menuRef) => {
                if (menuRef && tableRef?.current) {
                  const { bottom } = menuRef.getBoundingClientRect();
                  const { bottom: tableBottom } =
                    tableRef.current.getBoundingClientRect();
                  const offset = bottom + 100 - tableBottom;
                  if (offset > 0) {
                    // use timeout to be able to scroll after menu is opened and container height is updated
                    setTimeout(() =>
                      tableRef.current.scrollBy({
                        top: offset,
                        behavior: 'smooth',
                      })
                    );
                  }
                }
              }}
              options={[
                { value: TAG_ACTIONS.edit, label: t('Edit') },
                field?.id && {
                  value: TAG_ACTIONS.replace,
                  label: t('Replace'),
                },
                { value: TAG_ACTIONS.delete, label: t('Delete') },
              ].filter(Boolean)}
            />
          ),
        },
      ].filter(Boolean),
    [
      model,
      field,
      onSelectAction,
      tableRef,
      showTagLink,
      countLabel,
      tagLabel,
      t,
    ]
  );
};
