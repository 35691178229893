import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { breakpoints, gutters } from 'app/spacing';
import FieldInput from 'components/Fields/FieldInput';
import { getDataQAForInput } from 'components/Inputs/helpers';
import { getPercentageChanceToCloseProps } from 'pages/CustomObjects/RecordsPage/helpers';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';

const FieldLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); // Force equal col widths
  gap: 0 ${gutters.standard};
  margin-bottom: -${gutters.spacing(2)}px; // Account for bottom margin on fields. See components/Fields/FieldInput/index.js

  // half-width components need no styles,
  // full-width components need this class:
  .span2 {
    grid-column: span 2;
  }

  @media (max-width: ${breakpoints.sm}px) {
    // Make all items full-width on small screen sizes
    .span1 {
      grid-column: span 2;
    }
  }

  // Don't display unsupported fields
  > :empty {
    display: none;
  }
`;

export const StagedField = ({
  fields,
  field,
  errors,
  stages,
  object,
  objectId,
  isGetFullObject,
  inModal,
  fetchUrl,
  values,
  initialValues,
  validateSettings,
  onChange,
  serviceToUse,
  fieldsAdditionalProps,
  target,
  onFocus,
  onBlur,
  validationProps,
  recordStatus,
  portal,
  adornments,
}) => {
  const { t } = useTranslation();
  const { id, name, fieldType, meta } = field;
  const [innerValue, setInnerValue] = useState(values[id]);

  useEffect(() => {
    setInnerValue(values[id]);
  }, [values, id]);

  const handleChange = useCallback(
    (val, event, err) => {
      onChange({ ...values, [id]: val }, field, err);
      setInnerValue(val);
    },
    [onChange, field, id, values]
  );

  const error = errors.find((item) => item.fieldId === id);
  const additionalProps = fieldsAdditionalProps[id] || {};

  const fieldAdornments = useMemo(() => {
    return adornments?.filter((a) => a?.field_type === fieldType) ?? [];
  }, [adornments, fieldType]);

  return (
    <div className={`span${meta.cols || 1}`}>
      <FieldInput
        fieldId={id}
        margin
        variant="underline"
        inModal={inModal}
        field={field}
        fetchUrl={fetchUrl}
        object={object}
        value={innerValue}
        initialValue={initialValues[id]}
        error={error ? { message: error.message } : null}
        validateSettings={validateSettings}
        onChange={handleChange}
        serviceToUse={serviceToUse}
        objectId={objectId}
        isGetFullObject={isGetFullObject}
        stages={stages}
        {...getPercentageChanceToCloseProps(
          field,
          fields,
          {
            stage: { value: object.stage },
            percentageChanceToClose: values[field.id],
          },
          t,
          recordStatus
        )}
        {...additionalProps}
        {...getDataQAForInput(`${target}-${name}`, fieldType)}
        onFocus={onFocus}
        onBlur={onBlur}
        shouldUseKeyboardEventHandle
        {...validationProps(field.id)}
        portal={portal}
        adornments={fieldAdornments}
      />
    </div>
  );
};

export const FieldsFormStable = memo((props) => {
  const {
    fields,
    object,
    onChange,
    stages,
    values,
    validationProps,
    popoverMenus,
    suppressAutoScroll = false,
    ...rest
  } = props;
  const filteredFields = useMemo(
    () => fields.filter((field) => !field.isHidden),
    [fields]
  );

  const { onFocus, onBlur, assignFieldHandle } = useKeyListeners(
    filteredFields,
    object,
    undefined,
    suppressAutoScroll
  );

  return (
    <KeyBoardContext.Provider value={{ assignFieldHandle }}>
      <FieldLayout {...rest}>
        {filteredFields.map((field) => (
          <StagedField
            key={field.id}
            field={field}
            {...props}
            onChange={onChange}
            onFocus={onFocus(field.id)}
            onBlur={onBlur}
            validationProps={validationProps}
            portal={popoverMenus}
          />
        ))}
      </FieldLayout>
    </KeyBoardContext.Provider>
  );
}, shallowEqual);

FieldsFormStable.defaultProps = {
  inModal: null,
  errors: [],
  validateSettings: {},
  fieldsAdditionalProps: {},
  objectId: null,
  isGetFullObject: null,
  stages: undefined,
};
