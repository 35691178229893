import { Button } from '@kizen/kds/Button';
import { Typography } from '@kizen/kds/Typography';
import { merge } from '@kizen/kds/util';
import BasicModal from '__components/Modals/presets/BasicModal';
import useModal from '__components/Modals/useModal';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridState } from './state';
import { RowProps } from './types';
import Draggable from 'react-draggable';
import { HorizontalDropzone } from 'components/DragAndDropLayout/Dropzone';

export const Row = (props: RowProps) => {
  const {
    children,
    className,
    id,
    draggable,
    placeholderClassName,
    setCanStartDrag,
  } = props;
  const { t } = useTranslation();
  const {
    handleDeleteRow,
    layout,
    setDragGroup,
    dragGroup,
    dragItem,
    handleDrop,
    setGroupDropZones,
    currentDropZone,
    setPlaceholderPosition,
    placeholderPosition,
  } = useContext(GridState);

  const [deleteRowModalProps, , deleteRowModalControl] = useModal({
    handleSubmit: handleDeleteRow.bind(id),
  });

  const rowRef = useCallback(
    (element: HTMLDivElement) => {
      setGroupDropZones((dropZones) => {
        return { ...dropZones, [id]: element };
      });
    },
    [id, setGroupDropZones]
  );

  const isDragging = dragGroup && dragGroup.id === id;
  const isDropZoneRow = currentDropZone?.id === id;
  const isAbove = currentDropZone?.direction === 'up';
  const topDropZoneVisible = isDropZoneRow && isAbove;
  const bottomDropZoneVisible = isDropZoneRow && !isAbove;

  const currentRow = useMemo(
    () => layout.find((row) => row.id === id),
    [layout, id]
  );

  const canDeleteWithNoConfirmation = currentRow?.columns.every(
    (column) => !column.items.length
  );

  const content = (
    <div ref={rowRef} className="kds pt-spacer-5 pb-spacer-5">
      <div
        className={merge(
          'kds p-spacer-10 flex min-w-0 relative group bg-background-white',
          className
        )}
      >
        {!dragItem ? (
          <span
            className={`kds left-0 top-0 ${!isDragging ? 'hidden' : ''} ${
              !dragGroup ? 'group-hover:flex' : ''
            } absolute z-[99] ${isDragging ? 'flex' : ''}`}
            /*
             * Due to how stacking contexts work, we need to only enable draggable rows when
             * the user's intent to start dragging is detected. This is to prevent other rows
             * forming a new stacking context which would prevent the drag item from being shown on
             * top of them. The lock gets released here when the mouse leaves without a drag, or in the parent's
             * drop handler when the row is dropped somewhere. We can't just lock row drags when an item is being dragged,
             * because then the row re-renders breaking the drag event.
             */
            onMouseEnter={() => {
              setCanStartDrag(id);
            }}
            onMouseLeave={() => {
              if (!isDragging) {
                setCanStartDrag('');
              }
            }}
          >
            <span
              className={`kds row-drag-handle bg-orange-500 py-spacer-5 px-spacer-10 rounded-tl-[4px] flex items-center ${
                draggable ? 'cursor-move' : ''
              }`}
            >
              <Typography color="font-white" size="sm">
                {t('Row')}
              </Typography>
            </span>
            <div className="h-full w-[1px]" />
            <div className="kds bg-orange-500 py-spacer-5 px-spacer-10 flex items-center">
              <Button
                rightIcon="action-delete"
                rightIconSettings={{
                  size: 'sm',
                }}
                size="xs"
                color="white"
                variant="text"
                onClick={
                  canDeleteWithNoConfirmation
                    ? handleDeleteRow.bind(id)
                    : deleteRowModalControl.show
                }
              />
            </div>
          </span>
        ) : null}
        {children}
        {deleteRowModalProps.show ? (
          <BasicModal
            {...deleteRowModalProps}
            heading={t('Please Confirm Deletion')}
            actionBtnColor="green"
            buttonText={t('Confirm Delete')}
            displayFlex
          >
            <div className="flex justify-center w-full">
              <Typography>
                {t('This will permanently delete the row and its contents.')}
              </Typography>
            </div>
          </BasicModal>
        ) : null}
      </div>
    </div>
  );

  const placeholderStyle: any = useMemo(() => {
    if (isDragging && placeholderPosition) {
      return {
        '--drag-item-height': `${placeholderPosition.height}px`,
        '--drag-item-width': `${placeholderPosition.width}px`,
        '--drag-item-top': `${placeholderPosition.top}px`,
      };
    }

    return undefined;
  }, [isDragging, placeholderPosition]);

  if (!draggable) {
    return (
      <>
        {topDropZoneVisible ? <HorizontalDropzone orange /> : null}
        {content}
        {bottomDropZoneVisible ? <HorizontalDropzone orange /> : null}
      </>
    );
  }

  return (
    <>
      {topDropZoneVisible ? <HorizontalDropzone orange /> : null}
      <Draggable
        handle=".row-drag-handle"
        position={isDragging ? undefined : { x: 0, y: 0 }}
        onStart={(ev: any) => {
          ev.preventDefault();
          setDragGroup({
            id,
          });
          if (ev.currentTarget) {
            setPlaceholderPosition({
              height: ev.currentTarget.offsetHeight,
              width: ev.currentTarget.offsetWidth,
              top: ev.currentTarget.offsetTop,
            });
          }
        }}
        onStop={handleDrop}
        defaultClassNameDragging="kds-dragging"
      >
        {content}
      </Draggable>
      {placeholderStyle ? (
        <div
          className={merge(
            'kds absolute h-[var(--drag-item-height)] w-[var(--drag-item-width)] top-[var(--drag-item-top)] left-[20px] pointer-events-none pt-spacer-5 pb-spacer-5'
          )}
          style={placeholderStyle}
        >
          <div className={`${placeholderClassName} w-full h-full`} />
        </div>
      ) : null}
      {bottomDropZoneVisible ? <HorizontalDropzone orange /> : null}
    </>
  );
};
