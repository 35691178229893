import { gutters, layers } from 'app/spacing';
import { popperModifiers } from 'components/helpers';

export const preventOverflow = {
  name: 'preventOverflow',
  options: {
    padding: gutters.spacing(4),
    rootBoundary: 'viewport',
  },
};

export const basePopperConfig = {
  modifiers: [
    preventOverflow,
    popperModifiers.addStyles({ zIndex: 2 }), // Appear above table headers, action buttons, gradients.
  ],
};

export const baseModalPopperConfig = {
  modifiers: [
    preventOverflow,
    popperModifiers.addStyles({ zIndex: layers.popoverInModal }), // Appear above table headers, action buttons, gradients.
  ],
};
