import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConfirmationModal from './ConfirmationModal';
import KizenTypography from 'app/kizentypo';

export const RelationshipConfirmationModal = memo((props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      heading={t('Please Confirm Modification')}
      buttonText={t('Confirm Update')}
      {...props}
    >
      <KizenTypography onClick={(e) => e.stopPropagation()}>
        {t(
          `This relationship interferes with a previously set entity relationship. If you proceed, this will update the primary value and remove the old value from both related records.`
        )}
      </KizenTypography>
    </ConfirmationModal>
  );
});

RelationshipConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};
