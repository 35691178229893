import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Bar from '..';
import { buildChartData } from '../../helpers';
import { BarDashletAdapterWrapper } from './styles';
import { useQuery } from 'react-query';
import FieldService from 'services/FieldService';
import { FIELDS } from 'queries/query-keys';

export const BarChartGroupAdapter = ({
  data,
  dashlet,
  fieldData,
  fieldOptions,
  model,
  isLoading,
  ...rest
}) => {
  const { t } = useTranslation();

  const isClient = model?.fetchUrl === 'client';

  const { data: breakdownField, isLoading: breakdownFieldLoading } = useQuery(
    FIELDS.GET_FIELD(dashlet?.config?.metricTypeExtraInfo?.fieldToAnalyze),
    () =>
      isClient
        ? FieldService.getClientField(
            dashlet.config.metricTypeExtraInfo.fieldToAnalyze,
            {
              skipErrorBoundary: true,
            }
          )
        : FieldService.getField(
            dashlet.config.metricTypeExtraInfo.fieldToAnalyze,
            model,
            {
              skipErrorBoundary: true,
            }
          ),
    {
      enabled:
        Boolean(dashlet?.config?.metricTypeExtraInfo?.fieldToAnalyze) &&
        Boolean(data),
    }
  );

  const breakdownCurrencySymbol = breakdownField?.moneyOptions?.symbol;
  const isBreakdownFieldDecimal = breakdownField?.fieldType === 'decimal';

  const currencySymbol = fieldData?.moneyOptions?.symbol;
  const chartData = useMemo(() => {
    return buildChartData({
      data,
      dashlet,
      fieldData,
      fieldOptions,
      currencySymbol,
      t,
    });
  }, [data, dashlet, t, currencySymbol, fieldOptions, fieldData]);

  return (
    <BarDashletAdapterWrapper>
      <Bar
        data={chartData}
        yAxisTitle={
          breakdownField
            ? t('{{fieldName}} Sum', {
                fieldName: breakdownField.displayName,
              })
            : t('# Records')
        }
        dashlet={dashlet}
        skipSorting
        showXAxisLabel={false}
        getLabel={(d) => {
          if (d?.label) {
            return String(d.label).toUpperCase();
          }
        }}
        isLoading={isLoading || breakdownFieldLoading}
        breakdownCurrencySymbol={breakdownCurrencySymbol}
        currencyMode={Boolean(breakdownCurrencySymbol)}
        forceDecimal={isBreakdownFieldDecimal}
        {...rest}
      />
    </BarDashletAdapterWrapper>
  );
};
