// Copied from components/Kizen/RangeInput
import '@reach/slider/styles.css';
import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colorsButton, grayScale } from 'app/colors';
import { TextSpan, fontWeights } from 'app/typography';
import Input from 'components/Kizen/Input';
import {
  SliderInput,
  SliderHandle,
  SliderMarker,
  SliderTrack,
  SliderTrackHighlight,
} from '@reach/slider';
import '@reach/slider/styles.css';
import { StyledLabelWrapper, INPUT_TYPES } from 'components/Kizen/Input/styles';

const StyledSliderWrapper = styled(StyledLabelWrapper)({
  display: 'flex',
  justifyContent: 'center',
  '[data-reach-slider-track][data-orientation="horizontal"]': {
    backgroundColor: grayScale.mediumLight,
    height: 6,
  },
  pointerEvents: 'none',
});

export const StyledSliderInput = styled(SliderInput)`
  &[data-reach-slider-input][data-orientation='horizontal'] {
    display: flex;
    align-self: center;
    height: 6px;
    padding: 0px;
    margin: 30px 0;
    margin-left: -25px;
    margin-bottom: calc(14px - 6px - 4px - 8px);
    max-width: 275px;
    pointer-events: all;
  }
  & [data-reach-slider-handle] {
    width: 14px;
    height: 14px;
    background: ${grayScale.white};
    border: 1px solid ${colorsButton.blue.default};
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    &:focus {
      outline: none;
    }
  }
  & [data-reach-slider-marker] {
    font-size: 14px;
    border: none;
    width: 6px;
    height: 6px;
    background-color: ${colorsButton.blue.default};
    > span {
      font-size: 11px;
      font-weight: ${fontWeights.bold};
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  & [data-reach-slider-track-highlight] {
    background-color: ${colorsButton.blue.default};
    pointer-events: all;
  }

  ${(props) => css`
    [data-reach-slider-marker][data-state='under-value'] {
      background-color: ${props.value > props.min
        ? colorsButton.blue.default
        : grayScale.mediumLight};
      & > span {
        left: -20px;
      }
    }
    [data-reach-slider-marker][data-value='${props.min
        ? props.min.toString()
        : 0}'] {
      & > span {
        left: -20px;
      }
    }
    [data-reach-slider-marker][data-state='over-value'] {
      background-color: ${grayScale.mediumLight};
      left: auto;
      right: 0;
      & > span {
        left: 20px;
      }
    }
    [data-reach-slider-marker][data-state='over-value'][data-value='${props.value ===
      props.min
        ? props.value?.toString()
        : props.value?.toString()}'] {
      & > span {
        left: -20px;
      }
    }
    [data-reach-slider-marker][data-value='${props.max
        ? props.max.toString()
        : 100}'] {
      left: auto;
      right: 0;
      & > span {
        right: 20px;
        left: 20px;
      }
    }
  `}
`;

export const StyledHandleTooltip = styled(Input)({
  width: 60,
  padding: 0,
  textAlign: 'center',
  transform: 'translateY(-100%)',
  boxShadow: '0px 18px 26px rgba(0, 0, 0, 0.03)',
  '& label': {
    margin: 0,
  },
  '& .input-wrapper': {
    margin: 0,
  },
  '& input': {
    width: 60,
    height: 'auto',
    padding: '5px 10px',
    textAlign: 'center',
    fontSize: 11,
    fontWeight: fontWeights.bold,
    pointerEvents: 'all',
    '&:focus': {
      borderColor: grayScale.medium,
    },
  },
  '& input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none !important',
    margin: 0,
  },
  '& input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none !important',
    margin: 0,
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
});

const StyledSliderHandle = styled(SliderHandle)({});
const StyledSliderTrack = styled(SliderTrack)({});
const StyledSliderMarker = styled(SliderMarker)`
  /* TODO uncomment this to customize disabled steps style in future ticket once assigned*/
  &&[disabled] {
    opacity: 0 !important;
    /* position: relative;
    z-index: 1;
    width: 14px !important;
    height: 14px !important;
    cursor: not-allowed !important; */
  }
`;
const StyledSliderTrackHighlight = styled(SliderTrackHighlight)({});
const StyledLabel = styled(TextSpan)({
  marginBottom: 12,
});

const FORMAT_TYPES = {
  COMMAS: 'commas',
  PIXELS: 'px',
};

const numberWithCommas = (x) => x.toLocaleString();

const formatValue = (value, format) => {
  if (format === FORMAT_TYPES.COMMAS) {
    return numberWithCommas(value);
  }
  if (format === FORMAT_TYPES.PIXELS) {
    return `${value}px`;
  }
  return null;
};

export const RangeInput = ({
  id = '',
  label = '',
  className = '',
  withSteps = false,
  min = 0,
  max = 100,
  step = 1,
  formatMarker = null,
  showTooltip = true,
  tooltipValue = undefined,
  value,
  onChange,
  onTooltipChange,
  disabledSteps = [],
  stepsLabels = [],
  ...props
}) => {
  let sliderMarkers = null;

  if (withSteps) {
    const range = max - min;
    const steps = Array.from(Array(range / step + 1).keys());
    sliderMarkers = steps.map((key) => {
      const val = min + key * step;
      return (
        <StyledSliderMarker
          key={key}
          value={val}
          disabled={disabledSteps.some((step) => step === val)}
        >
          <span>
            {formatMarker
              ? formatValue(val, formatMarker)
              : stepsLabels
                ? stepsLabels[val].label
                : val}
          </span>
        </StyledSliderMarker>
      );
    });
  } else {
    sliderMarkers = (
      <>
        <StyledSliderMarker value={min}>
          <span>{formatMarker ? formatValue(min, formatMarker) : min}</span>
        </StyledSliderMarker>
        <StyledSliderMarker value={max}>
          <span>{formatMarker ? formatValue(max, formatMarker) : max}</span>
        </StyledSliderMarker>
      </>
    );
  }

  const handleChange = (val, config) => {
    if (!disabledSteps.some((step) => step === val)) onChange(val, config);
  };

  const handleSubmitTooltipChange = () => {
    if (tooltipValue < min) {
      handleChange(min);
    } else if (tooltipValue > max) {
      handleChange(max);
    } else {
      const result = step * Math.ceil(tooltipValue / step);
      handleChange(result);
    }
  };

  return (
    <StyledSliderWrapper className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSliderInput
        onChange={handleChange}
        step={step}
        min={min}
        max={max}
        value={value}
        {...props}
      >
        <StyledSliderTrack>
          {sliderMarkers}
          <StyledSliderTrackHighlight />
          <StyledSliderHandle>
            {showTooltip && (
              <StyledHandleTooltip
                type={INPUT_TYPES.WHOLE_NUMBER}
                value={tooltipValue}
                onChange={onTooltipChange}
                onBlur={handleSubmitTooltipChange}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    handleSubmitTooltipChange();
                  }
                }}
                onPointerDown={(e) => e.preventDefault()}
              />
            )}
          </StyledSliderHandle>
        </StyledSliderTrack>
      </StyledSliderInput>
    </StyledSliderWrapper>
  );
};
