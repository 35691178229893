import { useTranslation } from 'react-i18next';
import React from 'react';
import { ToggleButton, ToolbarIcon } from '../../styles';
import { grayScale } from 'app/colors';

export const ClearFormatting = (props) => {
  const { t } = useTranslation();

  return (
    <ToggleButton title={t('Clear Formatting')} {...props}>
      <ToolbarIcon icon="remove-format" color={grayScale.dark} />
    </ToggleButton>
  );
};
