import {
  useCallback,
  useMemo,
  useRef,
  createElement,
  useState,
  useEffect,
} from 'react';
import steps, { getStepByKey } from './steps';
import { RELATED_OBJECTS_STEP_KEY } from './constants';
import { useToast, toastVariant } from 'components/ToastProvider';
import { Header } from './components/Header';
import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';
import { useCustomObjectConfiguration } from './useCustomObjectConfiguration';
import { isContact, PIPELINE_CO } from './utilities';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utility/fieldHelpers';

import ConfirmationManager from './components/ConfirmationManager';
import { useWizardControl } from './useWizardControl';
import { breakpoints, isMobile, useWindowSize } from 'app/spacing';
import {
  StyledFullscreenModal,
  StyledLoader,
  StyledSubNavigationNavBar,
  StyledSubNavigationNavLink,
  MobileContainer,
  StyledKizenTypography,
} from './styles';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { CustomObjectWizardProvider } from './CustomObjectWizardContext';
import { RightActionButton } from './components/RightActionButton';
import { useEndsOfYScroll } from 'components/Kizen/LazyLoad/helpers';
import CustomObjectsService from 'services/CustomObjectsService';
import { useQueryClient } from 'react-query';
import { CUSTOM_OBJECTS } from 'queries/query-keys';
import { ASSOCIATION_SOURCE_TYPES } from 'components/Wizards/CustomObject/constants';
import { isEmpty } from 'lodash';

export default function CustomObjectWizard({
  show = true,
  onConfirm = () => {},
  refetchCustomObject = () => {},
  onHide = () => {},
  model = EMPTY_OBJECT,
  isCreateMode = true,
  isFetching = false,
  isAddingContext = false,
  objectIdRef = false,
  setIsAddingContext = () => {},
  backButtonTitle = '',
  handleDeleteCustomObject = () => {},
  redirectOnDeleteHandler,
  focusStep = null,
  goBackPath,
}) {
  const [showToast] = useToast();
  const [isContactNeedsToBeAdded, setIsContactNeedsToBeAdded] =
    useState(isAddingContext);
  const isFetchingOnce = useRef(true);
  const isFirstTimeCreation = useRef(isCreateMode);

  useEffect(() => {
    if (isFetchingOnce.current) isFetchingOnce.current = isFetching;
  }, [isFetching]);

  const bodyRef = useRef();
  const { width } = useWindowSize();
  const mobileDisplay = isMobile(width, breakpoints.lg);

  useEffect(() => {
    setIsContactNeedsToBeAdded(isAddingContext);
  }, [isAddingContext]);

  const [stagedLayoutChanges, setStagedLayoutChanges] = useState();
  const [layoutErrors, setLayoutErrors] = useState();
  const hasStagedLayoutChanges = Boolean(stagedLayoutChanges);
  const queryClient = useQueryClient();

  const handleSaveLayoutChanges = useCallback(async () => {
    if (stagedLayoutChanges && model?.id) {
      setLayoutErrors(undefined);
      try {
        await CustomObjectsService.updateRecordLayout(
          model.id,
          stagedLayoutChanges.id,
          {
            config: stagedLayoutChanges.layout,
            name: stagedLayoutChanges.name,
            tabs: stagedLayoutChanges.tabs,
            layout_sharing_settings:
              stagedLayoutChanges.layout_sharing_settings,
            active: stagedLayoutChanges.active,
          }
        );

        setStagedLayoutChanges(undefined);
        queryClient.invalidateQueries(CUSTOM_OBJECTS.LIST_LAYOUTS(model.id));
        return true;
      } catch (ex) {
        setLayoutErrors(ex.response?.data?.errors);
        return false;
      }
    } else {
      return true;
    }
  }, [model?.id, queryClient, stagedLayoutChanges]);

  const {
    updateField,
    isDirty,
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    validate,
    resetField,
    resetDirty,
    ...builderProps
  } = useCustomObjectConfiguration(model, EMPTY_ARRAY);

  const routesProp = useMemo(() => {
    return steps.filter((value) => {
      return !(
        value?.workflowOnly && builderProps.formData.objectType !== PIPELINE_CO
      );
    });
  }, [builderProps.formData.objectType]);

  const {
    activeStep,
    isLastStep,
    handlePrevious,
    handleNext,
    handleClose,
    handleSave,
    handleConfirm,
    handleGoBack,
    handleDelete,
    errors,
    modalProps,
    isEditing,
    saving,
    preSave,
    goingStepRef,
    handleGoToStep,
  } = useWizardControl({
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    onHide,
    isDirty: isDirty || hasStagedLayoutChanges,
    model,
    isPipeline: builderProps.formData.objectType === PIPELINE_CO,
    routesProp,
    validate,
    resetDirty,
    resetField,
    onConfirm,
    refetchCustomObject,
    builderProps,
    setIsAddingContext,
    setIsContactNeedsToBeAdded,
    focusStep,
    setStagedLayoutChanges,
  });

  const oneTimeNextRef = useRef(false);
  // This is a one-time effect that will automatically go to the next step if the model has been created
  useEffect(() => {
    if (
      !oneTimeNextRef.current &&
      !isEmpty(model) &&
      objectIdRef.current === 'new'
    ) {
      oneTimeNextRef.current = true;
      handleNext();
    }
  }, [handleNext, isAddingContext, model, objectIdRef]);

  const handleGroupedSave = useCallback(
    async (skipNavigation) => {
      const successful = await handleSaveLayoutChanges();

      // Abort the save if we got an error saving the layout, because that needs to be fixed
      // first
      if (!successful) return;

      const result = await handleSave(skipNavigation);
      if (activeStep.key === 'relatedObjects' && builderProps?.formData?.id) {
        queryClient.refetchQueries(
          CUSTOM_OBJECTS.FIELDS_UNRESTRICTED(builderProps.formData.id)
        );
      }
      return result;
    },
    [
      handleSave,
      handleSaveLayoutChanges,
      queryClient,
      builderProps?.formData?.id,
      activeStep,
    ]
  );

  const { t } = useTranslation();

  const activeStepIdx = useMemo(() => {
    const { key } = activeStep;
    return routesProp.findIndex((step) => step.key === key);
  }, [activeStep, routesProp]);

  const setValue = useCallback(
    (name, value, ...props) => {
      return updateField(name, value, ...props);
    },
    [updateField]
  );
  const [scrolledTop] = useEndsOfYScroll(bodyRef, [builderProps.formData]);

  const checkAssociatedSourceRef = useRef(false);
  if (model && !checkAssociatedSourceRef.current) {
    if (
      model.associationSource === ASSOCIATION_SOURCE_TYPES.related &&
      model.associationSourceFields?.length === 0
    ) {
      showToast({
        variant: toastVariant.FAILURE,
        message: t(`There are no Related Objects for Associations`),
      });
      handleGoToStep(getStepByKey(RELATED_OBJECTS_STEP_KEY));
    }
    checkAssociatedSourceRef.current = true;
  }

  return (
    <CustomObjectWizardProvider
      preSave={preSave}
      modalProps={modalProps}
      isAddingContext={isAddingContext}
      setIsAddingContext={setIsAddingContext}
      isContactNeedsToBeAdded={isContactNeedsToBeAdded}
      setIsContactNeedsToBeAdded={setIsContactNeedsToBeAdded}
      isCreateMode={isCreateMode}
      startedCreateMode={isFirstTimeCreation.current}
      bodyRef={bodyRef}
      isContact={isContact(model)}
      refetchCustomObject={refetchCustomObject}
      customLayoutSelectedTab={customLayoutSelectedTab}
      setCustomLayoutSelectedTab={setCustomLayoutSelectedTab}
      isDirty={isDirty || hasStagedLayoutChanges}
    >
      <StyledFullscreenModal
        enforceFocus={false}
        show={show}
        ref={bodyRef}
        data-qa={'custom_object_wizard'}
        scrolledTop={scrolledTop}
        header={
          <Header
            scrolledTop={scrolledTop}
            backButtonTitle={backButtonTitle}
            rightActionButton={
              <RightActionButton
                saving={saving}
                handleDelete={handleDelete}
                activeStep={activeStep}
                handleNext={handleNext}
                handleGoBack={handleGoBack}
                handleSave={handleGroupedSave}
                isLastStep={isLastStep}
                model={model}
                isDirty={isDirty || hasStagedLayoutChanges}
                onHide={onHide}
                routesProp={routesProp}
                isCreateMode={isCreateMode}
              />
            }
            centerContent={
              !model ? null : (
                <>
                  <StyledKizenTypography as="h2" type="header" weight={300}>
                    {isAddingContext || isCreateMode
                      ? t('Adding New Custom Object')
                      : isContact(model)
                        ? t('Editing Object')
                        : t('Editing Custom Object')}
                    {isEditing && ' - '}
                  </StyledKizenTypography>
                  <TextEllipsisWithTooltip as="h2" type="header" weight={300}>
                    &nbsp;{isEditing ? model.objectName : ''}
                  </TextEllipsisWithTooltip>
                </>
              )
            }
            onGoBack={handleClose}
            goBackPath={goBackPath}
          />
        }
      >
        {mobileDisplay ? (
          <MobileContainer>
            <KizenTypography>
              {t(
                'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
              )}
            </KizenTypography>
          </MobileContainer>
        ) : (
          <>
            <StyledSubNavigationNavBar>
              {routesProp.map((step, idx, arr) => {
                const { label, key } = step;
                const common = {
                  isLast: idx === arr.length - 1,
                  isActive: () => step.key === activeStep.key,
                  'data-qa': `step-${key}`,
                  'data-qa-step-index': idx,
                };
                return (
                  <StyledSubNavigationNavLink
                    key={label}
                    disabled={
                      isEditing && !step?.disabled
                        ? false
                        : !(idx <= activeStepIdx && !step.disabled)
                    }
                    icon={`circle-${idx + 1}`}
                    route={step}
                    onClick={async (event) => {
                      event.preventDefault();
                      if (!isDirty && !hasStagedLayoutChanges) {
                        await handleGoToStep(step);
                      } else {
                        goingStepRef.current = step;
                        modalProps.intermediate.modal.show();
                      }
                    }}
                    {...common}
                  />
                );
              })}
            </StyledSubNavigationNavBar>
            {isFetchingOnce.current ? (
              <StyledLoader data-qa={'wizard-loader'} loading />
            ) : (
              createElement(activeStep.component, {
                label: activeStep.label(t),
                ...builderProps,
                resetField,
                saving: saving,
                errors: errors,
                updateStepField: setValue,
                activeStep,
                setStagedLayoutChanges,
                stagedLayoutChanges,
                handleSaveLayoutChanges,
                layoutErrors,
                setLayoutErrors,
              })
            )}
          </>
        )}
      </StyledFullscreenModal>
      <ConfirmationManager
        isCreateMode={isCreateMode}
        modalProps={modalProps}
        handleConfirm={handleConfirm}
        handleSave={handleGroupedSave}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        activeStep={activeStep}
        goingStepRef={goingStepRef}
        saving={saving}
        model={model}
        onHide={onHide}
        handleDeleteCustomObject={handleDeleteCustomObject}
        redirectOnDeleteHandler={redirectOnDeleteHandler}
        handleGoToStep={handleGoToStep}
        isPipeline={builderProps.formData.objectType === PIPELINE_CO}
        setCustomLayoutSelectedTab={setCustomLayoutSelectedTab}
        customLayoutSelectedTab={customLayoutSelectedTab}
      />
    </CustomObjectWizardProvider>
  );
}
