import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import isNumber from 'lodash/isNumber';
import { format } from 'date-fns';

import { KizenTypography, fontWeights, fontSizes } from 'app/typography';
import { tableHover } from 'app/colors';
import { gutters } from 'app/spacing';
import { FIELD_TYPES } from 'utility/constants';
import { useSummaryCounts } from 'hooks/useSummaryCounts';
import TextTemplater from 'components/Kizen/TextTemplater';
import KizenLoader from 'components/Kizen/Loader';
import {
  DATE_DISPLAY_FORMAT,
  DATETIME_DISPLAY_FORMAT,
} from 'components/Inputs/DateInput';
import {
  SEND_EMAIL_IDENTIFIER,
  SEND_TEXT_IDENTIFIER,
  CHANGE_TAGS_IDENTIFIER,
  MANAGE_TEAM_IDENTIFIER,
  MANAGE_SUBSCRIPTIONS,
  EXPORT_IDENTIFIER,
  ARCHIVE_IDENTIFIER,
  CHANGE_FIELD_IDENTIFIER,
  SEND_SURVEY_IDENTIFIER,
  MODIFY_AUTOMATION_IDENTIFIER,
  moneyFormatter,
  numberFormatter,
} from './model';
import { subscriptionStatusLabel } from '../../ContactDetail/pages/Messages/columns';
import { SECONDARY_ACTIONS } from './actions';

const InfoWrapper = styled.div`
  background: ${tableHover};
  border-radius: 4px;
  padding: ${gutters.spacing(2)}px ${gutters.spacing(2)}px
    ${gutters.spacing(2, -4)}px;
  margin-bottom: ${gutters.spacing(4)}px;
  margin-top: ${gutters.spacing(4, -3)}px;
`;

const TextWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: ${gutters.spacing(3)}px;
  }
  span {
    line-height: 110%;
  }

  .field-value {
    white-space: break-spaces;
    word-break: break-all;
    font-size: ${fontSizes.micro};
  }
  ${(props) =>
    props &&
    props.marginBottom &&
    `
      margin-bottom: ${gutters.spacing(2, 3)}px
    `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .text-templater {
    > span {
      font-weight: ${fontWeights.bold};
    }
    margin-bottom: ${gutters.spacing(4)}px;
    & > div {
      height: 148px;
    }
  }
`;

const getActiveAutomations = (summary) => {
  return summary.activeCount;
};

const getPausedAutomations = (summary) => {
  return summary.pausedCount;
};

const Steps = ({
  userAction,
  sendTextMergeFields,
  sendTextValue,
  tagsToAdd,
  tagsToRemove,
  teamMembers,
  subscriptionItem,
  subscriptionStatus,
  fieldData,
  sendEmailStep,
  surveyValue,
  automation,
  objectInfo,
  summary,
  secondaryAction,
}) => {
  const { t } = useTranslation();
  if (userAction) {
    switch (userAction.value) {
      case SEND_TEXT_IDENTIFIER:
        return (
          <TextTemplater
            onSave={() => {}}
            textareaOptions={{
              disabled: true,
              enableToolbar: false,
              enableResize: false,
              initialHeight: 140,
            }}
            defaultValue={sendTextValue}
            customize={{
              showSelectComponent: true,
              showCountComponent: false,
              disableFocus: true,
              wrapperClassName: 'text-templater',
              mergeFields: sendTextMergeFields,
            }}
          />
        );
      case SEND_EMAIL_IDENTIFIER:
        return (
          <TextWrapper marginBottom>
            <KizenTypography weight="bold" as="span">
              {t('Email')}:&nbsp;
            </KizenTypography>
            <KizenTypography as="span">
              {sendEmailStep.email && sendEmailStep.email.name}
            </KizenTypography>
          </TextWrapper>
        );
      case CHANGE_TAGS_IDENTIFIER:
        return (
          <>
            {!!tagsToAdd.length && (
              <TextWrapper>
                <KizenTypography weight="bold" as="span">
                  {t('Tags to Add')}:&nbsp;
                </KizenTypography>
                <KizenTypography as="span">
                  {tagsToAdd.map((tag) => tag.label).join(', ')}
                </KizenTypography>
              </TextWrapper>
            )}
            {!!tagsToRemove.length && (
              <TextWrapper marginBottom>
                <KizenTypography weight="bold" as="span">
                  {t('Tags to Remove')}:&nbsp;
                </KizenTypography>
                <KizenTypography as="span">
                  {tagsToRemove.map((tag) => tag.label).join(', ')}
                </KizenTypography>
              </TextWrapper>
            )}
          </>
        );
      case MANAGE_TEAM_IDENTIFIER:
        return (
          <TextWrapper marginBottom>
            <KizenTypography weight="bold" as="span">
              {t('Team {{entity}} to Associate', {
                entity: teamMembers.length === 1 ? t('Member') : t('Members'),
              })}
              :&nbsp;
            </KizenTypography>
            <KizenTypography as="span">
              {teamMembers.map((tag) => tag.label).join(', ')}
            </KizenTypography>
          </TextWrapper>
        );
      case MANAGE_SUBSCRIPTIONS:
        return (
          <TextWrapper marginBottom>
            <KizenTypography weight="bold" as="span">
              {t('Subscription Lists to Mark as')}{' '}
              {subscriptionStatusLabel[subscriptionStatus](t)}:{' '}
              {subscriptionItem.label}
            </KizenTypography>
          </TextWrapper>
        );
      case EXPORT_IDENTIFIER:
        return (
          <TextWrapper marginBottom>
            <KizenTypography weight="bold" as="span">
              {objectInfo.names.singular} {t('Export')}
            </KizenTypography>
          </TextWrapper>
        );
      case ARCHIVE_IDENTIFIER:
        return (
          <TextWrapper marginBottom>
            <KizenTypography weight="bold" as="span">
              {objectInfo.names.singular} {t('Archive')}
            </KizenTypography>
          </TextWrapper>
        );
      case CHANGE_FIELD_IDENTIFIER: {
        const getFieldValue = () => {
          const { fieldValue } = fieldData;
          switch (fieldData.field.fieldType) {
            case FIELD_TYPES.Date.type:
              if (!fieldValue) {
                return fieldValue;
              }
              return format(fieldValue, DATE_DISPLAY_FORMAT);
            case FIELD_TYPES.DateTime.type:
              if (!fieldValue) {
                return fieldValue;
              }
              return format(fieldValue, DATETIME_DISPLAY_FORMAT);
            case FIELD_TYPES.TeamSelector.type: {
              const key = 'displayName';
              if (Array.isArray(fieldValue)) {
                return fieldValue.map((item) => item[key]).join(', ');
              }
              return fieldValue?.[key] ?? null;
            }
            case FIELD_TYPES.Dropdown.type:
            case FIELD_TYPES.Radio.type:
            case FIELD_TYPES.Status.type:
              return fieldValue.name || '';
            case 'yesno':
            case FIELD_TYPES.YesNoMaybe.type: {
              const option = fieldData.field.options.find(
                ({ id }) => id === fieldValue
              );
              return option ? option.name : '';
            }
            case FIELD_TYPES.Checkbox.type:
              return String(!!fieldValue);
            case FIELD_TYPES.Relationship.type: {
              if (Array.isArray(fieldValue)) {
                return fieldValue.map(({ label }) => label).join(', ');
              }
              return fieldValue.label;
            }
            case FIELD_TYPES.Checkboxes.type:
            case FIELD_TYPES.Files.type:
              return fieldValue
                ? fieldValue.map(({ name }) => name).join(', ')
                : '';
            case FIELD_TYPES.Money.type: {
              const result = isNumber(fieldValue)
                ? moneyFormatter(fieldValue.toString())
                : '';
              return (
                `${
                  result ? fieldData.field?.moneyOptions?.symbol || '' : ''
                }${result}` || ''
              );
            }
            case FIELD_TYPES.Decimal.type: // save as it has no moneyoptions to add
            case FIELD_TYPES.Integer.type:
              return (
                `${fieldData.field?.moneyOptions?.symbol || ''}${
                  typeof fieldValue === 'number'
                    ? numberFormatter(
                        isNumber(fieldValue)
                          ? fieldValue.toString()
                          : fieldValue
                      )
                    : ''
                }` || ''
              );
            default:
              return isNumber(fieldValue)
                ? fieldValue.toString()
                : fieldValue || '';
          }
        };
        if (fieldData.field.fieldType === FIELD_TYPES.DynamicTags.type) {
          return (
            <div data-qa="summary-value">
              {!!fieldData.fieldValue && fieldData.fieldValue.length > 0 && (
                <TextWrapper marginBottom>
                  <KizenTypography weight="bold" as="span">
                    {t('"{{displayName}}" Options to Add', fieldData.field)}:
                  </KizenTypography>
                  <KizenTypography as="span">
                    {fieldData.fieldValue.map(({ label }) => label).join(', ')}
                  </KizenTypography>
                </TextWrapper>
              )}
              {!!fieldData.removeTags && fieldData.removeTags.length > 0 && (
                <TextWrapper marginBottom>
                  <KizenTypography weight="bold" as="span">
                    {t('"{{displayName}}" Options to Remove', fieldData.field)}:
                  </KizenTypography>
                  <KizenTypography as="span">
                    {fieldData.removeTags.map(({ label }) => label).join(', ')}
                  </KizenTypography>
                </TextWrapper>
              )}
            </div>
          );
        }
        return (
          <div data-qa="summary-value">
            <TextWrapper marginBottom>
              <KizenTypography weight="bold" as="span">
                {t('"{{displayName}}" Value', fieldData.field)}:
              </KizenTypography>
              <KizenTypography as="span">
                <span className="field-value">{` ${getFieldValue()}`}</span>
              </KizenTypography>
            </TextWrapper>
            {fieldData.resolution && (
              <TextWrapper marginBottom>
                <KizenTypography weight="bold" as="span">
                  {t('Field Resolution')}:{' '}
                </KizenTypography>
                <KizenTypography as="span">
                  {fieldData.resolution.label}
                </KizenTypography>
              </TextWrapper>
            )}
          </div>
        );
      }
      case SEND_SURVEY_IDENTIFIER: {
        return (
          <TextWrapper marginBottom>
            <KizenTypography weight="bold" as="span">
              {t('Survey to Send')}:&nbsp;
            </KizenTypography>
            <KizenTypography as="span">{surveyValue.label}</KizenTypography>
          </TextWrapper>
        );
      }
      case MODIFY_AUTOMATION_IDENTIFIER: {
        return (
          <>
            <TextWrapper marginBottom>
              <KizenTypography weight="bold" as="span">
                {t('Automation')}:&nbsp;
              </KizenTypography>
              <KizenTypography as="span">{automation.label}</KizenTypography>
            </TextWrapper>

            {summary.automations ? (
              <TextWrapper marginBottom data-qa="automation-summary">
                {secondaryAction === SECONDARY_ACTIONS.PAUSE ? (
                  <>
                    <KizenTypography weight="bold" as="span">
                      {t('{{count}} active automations', {
                        count: getActiveAutomations(summary.automations),
                      })}
                    </KizenTypography>{' '}
                    <KizenTypography as="span">
                      {t('will be paused')}
                    </KizenTypography>
                  </>
                ) : null}
                {secondaryAction === SECONDARY_ACTIONS.CANCEL ? (
                  <>
                    <KizenTypography weight="bold" as="span">
                      {t('{{count}} active automations', {
                        count: getActiveAutomations(summary.automations),
                      })}
                    </KizenTypography>{' '}
                    <KizenTypography as="span">
                      {t('will be canceled')}
                    </KizenTypography>
                    <TextWrapper>
                      <KizenTypography weight="bold" as="span">
                        {t('{{count}} paused automations', {
                          count: getPausedAutomations(summary.automations),
                        })}
                      </KizenTypography>{' '}
                      <KizenTypography as="span">
                        {t('will be canceled')}
                      </KizenTypography>
                    </TextWrapper>
                  </>
                ) : null}
              </TextWrapper>
            ) : null}
          </>
        );
      }

      default: {
        return <div>{t('Unknown step')} 3</div>;
      }
    }
  }
  return null;
};

const Loader = styled(KizenLoader)`
  width: 100%;
  text-align: center;
  padding: ${gutters.spacing(2)}px !important;
`;

const SummaryStep = ({
  sendTextValue,
  summary,
  userAction,
  tagsToAdd,
  tagsToRemove,
  teamMembers,
  subscriptionItem,
  subscriptionStatus,
  fieldData,
  sendEmailStep,
  surveyValue,
  automation,
  objectInfo,
  secondaryAction,
  ...others
}) => {
  const { t } = useTranslation();
  const { count, countNum, selected, notIncludedString } = useSummaryCounts(
    summary,
    objectInfo.isContacts
  );

  return !summary || !userAction ? (
    <Loader loading />
  ) : (
    <Wrapper {...others}>
      <>
        <InfoWrapper>
          <TextWrapper>
            <KizenTypography as="span">
              {t('This action will be performed on')}&nbsp;
            </KizenTypography>
            <KizenTypography weight="bold" as="span" data-qa="total">
              {count}{' '}
              {countNum === 1
                ? objectInfo.names.singular
                : objectInfo.names.plural}
            </KizenTypography>
          </TextWrapper>
          <TextWrapper>
            <KizenTypography weight="bold" as="span">
              {t('Selected')} {objectInfo.names.plural}:&nbsp;
            </KizenTypography>
            <KizenTypography as="span" data-qa="selected">
              {selected}
            </KizenTypography>
          </TextWrapper>
          {userAction.value === SEND_EMAIL_IDENTIFIER && (
            <TextWrapper>
              <KizenTypography weight="bold" as="span">
                {t('Subscription Lists')}:&nbsp;
              </KizenTypography>
              <KizenTypography as="span">
                {!sendEmailStep.subscriptionLists && t('Any Opted In')}
                {sendEmailStep.subscriptionLists &&
                  sendEmailStep.subscriptionListOptions
                    .filter((opt) => {
                      if (!sendEmailStep.subscriptionLists.length) {
                        // When this list is empty we want to include all subscription lists
                        return true;
                      }
                      return sendEmailStep.subscriptionLists.includes(
                        opt.value
                      );
                    })
                    .map((opt) => opt.label)
                    .join(', ')}
              </KizenTypography>
            </TextWrapper>
          )}
          {notIncludedString ? (
            <TextWrapper>
              <KizenTypography weight="bold" as="span">
                {t('Not Included')}:&nbsp;
              </KizenTypography>
              <KizenTypography as="span" data-qa="not-included">
                {notIncludedString}
              </KizenTypography>
            </TextWrapper>
          ) : null}
        </InfoWrapper>
        <Steps
          summary={summary}
          sendTextValue={sendTextValue}
          userAction={userAction}
          tagsToAdd={tagsToAdd}
          tagsToRemove={tagsToRemove}
          teamMembers={teamMembers}
          subscriptionItem={subscriptionItem}
          subscriptionStatus={subscriptionStatus}
          fieldData={fieldData}
          sendEmailStep={sendEmailStep}
          surveyValue={surveyValue}
          automation={automation}
          objectInfo={objectInfo}
          secondaryAction={secondaryAction}
        />
      </>
    </Wrapper>
  );
};

export default SummaryStep;

SummaryStep.defaultProps = {
  onBackClick: () => {},
  onConfirmClick: () => {},
  sendTextValue: '',
  summary: {},
  userAction: null,
};
