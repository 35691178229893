import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import useField from 'hooks/useField';
import { colorsButton, grayScale } from 'app/colors';
import { borderRadii, gutters } from 'app/spacing';
import { linkCss } from 'app/typography';
import BaseSelect from 'components/Inputs/Select';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import IconButton from 'components/Kizen/IconButton';
import { OVERLAY_ICON_BUTTON_SIZE } from 'components/Kizen/IconButton';
import Handle from './Handle';
import steps from '../steps';
import { useTooltip, truncationPredicates } from 'components/Kizen/Tooltip';

const overflowXOrY = (el) =>
  truncationPredicates.x(el) || truncationPredicates.y(el);

export const ADD_BUTTON_HEIGHT = 36;
export const ADD_BUTTON_WIDTH = OVERLAY_ICON_BUTTON_SIZE;

// At times we're going to replace the OverlayButton with PlacementButton,
// and Flow layout works out nicer if they are the same height, so they don't
// need any repositioning.
const ConsistentHeightWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${ADD_BUTTON_HEIGHT}px;
`;

const PlacementButton = styled.button`
  position: relative;
  height: ${ADD_BUTTON_HEIGHT}px;
  // Having a predictable width makes it easy to line-up centered and non-centered versions.
  // It is naturally just shy of 90px anyways, so this works out well.
  min-width: 90px;
  text-overflow: ellipsis; // ensure truncation when translated
  overflow: hidden;
  white-space: nowrap;
  max-width: 90px;

  padding: 1px ${gutters.spacing(2)}px 0;
  ${linkCss()}
  background: ${grayScale.white};
  border-radius: ${borderRadii.small};
  &,
  &:focus {
    outline: none;
  }
  border: 1px dashed ${colorsButton.blue.default};
  ${({ dropping }) =>
    dropping &&
    css`
      border-style: solid;
    `}
  ${({ centered }) =>
    centered &&
    css`
      margin-left: calc(-50% + 30px);
    `}
`;

export const addButtonOptionsTranslated = (t) => [
  {
    value: steps.action.type,
    label: t('Add Action'),
    isDisabled: !steps.action.creatable,
  },
  {
    value: steps.condition.type,
    label: t('Add Condition'),
    isDisabled: !steps.condition.creatable,
  },
  {
    value: steps.delay.type,
    label: t('Add Delay'),
    isDisabled: !steps.delay.creatable,
  },
  {
    value: steps.goal.type,
    label: t('Add Goal'),
    isDisabled: !steps.goal.creatable,
  },
  {
    value: steps.splitTest.type,
    label: t('Add Split Test'),
    isDisabled: !steps.splitTest.creatable,
  },
  {
    value: 'create-branch',
    label: t('Create New Branch'),
  },
];

const Select = styled(BaseSelect)`
  && {
    width: auto;
  }
`;

const DropArea = styled.span`
  position: absolute;
  top: -${gutters.spacing(5)}px;
  bottom: -${gutters.spacing(5)}px;
  right: -${gutters.spacing(11)}px;
  left: -${gutters.spacing(11)}px;
`;

export default function AddButton(props) {
  const { t } = useTranslation();
  const [tooltipProps, tooltip] = useTooltip({}, overflowXOrY);
  const {
    placing,
    centered,
    onClick,
    onDrop,
    showMenu: showMenuProp,
    options,
    onSelectOption,
    ...others
  } = props;
  const propsRef = useRef();
  propsRef.current = props;
  const buttonRef = useRef();
  const [showMenu, setShowMenu] = useField(
    (prev) => {
      if (Boolean(showMenuProp) === showMenuProp) {
        // Is boolean
        return showMenuProp;
      }
      if (prev === undefined) {
        return false;
      }
      return prev;
    },
    [showMenuProp]
  );
  const [dropping, setDropping] = useState(false);
  useEffect(() => {
    if (dropping && !placing) {
      setDropping(false);
      if (propsRef.current.onDrop) propsRef.current.onDrop();
    }
    if (!dropping && !placing) {
      tooltipProps?.onBlur();
    }
  }, [placing, dropping, tooltipProps]);

  if (placing) {
    return (
      <PlacementButton
        dropping={dropping}
        centered={centered}
        onClick={onClick}
        {...others}
        {...tooltipProps}
      >
        {t('Place Here')}
        <Handle
          id="handle-left"
          type="target"
          position="left"
          style={{ left: 0 }}
        />
        <Handle
          id="handle-right"
          type="source"
          position="right"
          style={{ right: 0 }}
        />
        <DropArea
          onMouseEnter={() => setDropping(true)}
          onMouseLeave={() => setDropping(false)}
        />
        {tooltip}
      </PlacementButton>
    );
  }
  return (
    <ConsistentHeightWrapper>
      <IconButton
        title={t('Add Item')}
        ref={buttonRef}
        sizing="overlay"
        color={showMenu ? colorsButton.iconGray.hover : colorsButton.iconGray}
        onClick={(ev) => {
          setShowMenu(true);
          if (onClick) onClick(ev);
        }}
        {...others}
        data-qa={`add-step-button`}
      >
        <IconSizing size="16px" force={1}>
          {/* Effectively 12px wide due to icon's aspect ratio and padding */}
          <Icon icon="plus" />
        </IconSizing>
        <Handle
          id="handle-left"
          type="target"
          position="left"
          style={{ left: 0 }}
        />
        <Handle
          id="handle-right"
          type="source"
          position="right"
          style={{ right: 0 }}
        />
      </IconButton>
      <SelectOverlay
        target={buttonRef.current}
        show={showMenu}
        onHide={() => setShowMenu(false)}
      >
        <Select
          menuOnly
          onChange={(val) => {
            setShowMenu(false);
            if (onSelectOption) onSelectOption(val);
          }}
          options={options}
          className="add-step-menu" // data-qa doesn't propogate
        />
      </SelectOverlay>
    </ConsistentHeightWrapper>
  );
}

AddButton.propTypes = {
  placing: PropTypes.bool,
  centered: PropTypes.bool,
  onClick: PropTypes.func,
  onDrop: PropTypes.func,
  showMenu: PropTypes.bool,
  options: PropTypes.array,
  onSelectOption: PropTypes.func,
};

AddButton.defaultProps = {
  placing: null,
  centered: null,
  onClick: null,
  onDrop: null,
  showMenu: null,
  options: null,
  onSelectOption: null,
};
