export const FORMDATA_FIELDS = {
  SCHEDULED_ACTIVITY_ID: 'id',
  ACTIVITY_ID: 'activityObjectId',
  NOTE: 'note',
  DATETIME: 'dueDatetime',
  EMPLOYEE: 'employeeId',
  ROLE: 'roleId',
  ENTITIES: 'associatedEntities',
  NOTIFICATIONS: 'notifications',
  MENTIONS: 'mentions',
};

export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'";
export const DATE_TIME_FORMAT_WITH_TIME_ZONE = "yyyy-MM-dd'T'HH:mm:ssXXX'";
export const DATE_VALUE_FORMAT = 'yyyy-MM-dd';
export const TIME_VALUE_FORMAT = 'HH:mm';

export const STEPS = {
  ACTIVITY_SETTINGS: 'settings',
  RELATED_OBJECTS: 'type',
  NOTIFICATIONS: 'options',
};

export const EMPTY_ARRAY = [];

export const EMPTY_OBJECT = {};

export const FORM_ACTIONS = {
  update: 'UPDATE',
  reset: 'RESET',
  setAssignmentType: 'SET_ASSIGNMENT_TYPE',
};

export const DEFAULT_NOTIFICATION = {
  type: 'email',
  timeAmount: 0,
  timeUnit: 'minute',
};

export const ASSIGNMENT_TYPES = {
  ROLE_ASSIGNMENT: 'role',
  TEAMMEMBER_ASSIGNMENT: 'emploee',
};
