import { useState, useCallback } from 'react';
import { xor } from 'lodash';
import { FIELD_TYPES } from 'utility/constants';
import FieldService from 'services/FieldService';

export const useRelationshipSubmit = ({
  onHide,
  onSubmit,
  field,
  stagedValue,
  value,
  setStagedValue,
  handleUpdateTableRecords,
  setShow,
  displayValue,
  handleChange,
}) => {
  const [modal, setModal] = useState(false);
  const [conflicts, setConflicts] = useState([]);
  const [isHideClick, setIsHideClick] = useState(true);

  const hasConflict = !!conflicts.length;

  const handleHide = useCallback(
    async (e) => {
      if (!isHideClick) {
        return;
      }
      if (modal) {
        const confirmModal = document.querySelector('.rel-confirm-modal');
        if (confirmModal && confirmModal.contains(e?.target)) {
          return;
        }
      }
      if (!stagedValue) {
        setShow(false);
        onSubmit?.(true);
      }

      setShow(false);
      onHide?.();
      await onSubmit?.();
      if (hasConflict) {
        handleUpdateTableRecords();
        setConflicts([]);
      }
      setIsHideClick(true);
    },
    [
      onHide,
      onSubmit,
      modal,
      stagedValue,
      isHideClick,
      setShow,
      hasConflict,
      handleUpdateTableRecords,
    ]
  );

  const handleChangeWithConfirmation = useCallback(
    async (selectedValue, action) => {
      handleChange(selectedValue);

      if (
        action?.action === 'select-option' &&
        field?.fieldType === FIELD_TYPES.Relationship.type &&
        ['one_to_many', 'one_to_one'].includes(field?.relation?.cardinality)
      ) {
        const value = !Array.isArray(selectedValue)
          ? selectedValue
          : action.option;

        const relatedField = field?.relation?.relatedField;

        setIsHideClick(false);

        const entityRecord = await FieldService.getEntityRecordById(
          field?.relation?.relatedObject,
          value.value
        );

        const conflict = entityRecord?.fields[relatedField]?.value && value;

        if (conflict) {
          setConflicts((prev) => prev.concat(conflict));
          setModal(true);
        } else {
          setIsHideClick(true);
        }
      }
    },
    [field, handleChange]
  );

  const handleConfirm = useCallback(() => {
    setIsHideClick(true);
    setTimeout(() => setModal(false));
  }, []);

  const handleHideConfirm = () => {
    setIsHideClick(true);
    setStagedValue(
      field.relation.cardinality === 'one_to_many'
        ? xor(displayValue, [conflicts.at(-1)])
        : value
    );
    setConflicts([]);
    setTimeout(() => setModal(false));
  };

  return {
    handleHide,
    handleConfirm,
    handleHideConfirm,
    modal,
    handleChangeWithConfirmation,
  };
};
