import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { useTranslation } from 'react-i18next';

export default function DeleteDynamicTagsModal({
  tag,
  onHide,
  onConfirm,
  tagLabel,
  field,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <ConfirmDeletionModal
      {...props}
      onConfirm={async () => {
        try {
          await onConfirm(tag);
        } finally {
          onHide();
        }
      }}
      onHide={onHide}
    >
      {field?.id
        ? t(
            'This will permanently delete the {{tagLabel}} and all associated data with it from your database.',
            { tagLabel: tagLabel || t('Tag') }
          )
        : t('This will permanently delete the {{tagLabel}}', {
            tagLabel: tagLabel || t('Tag'),
          })}
    </ConfirmDeletionModal>
  );
}
