import React from 'react';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import IconButton from 'components/Kizen/IconButton';
import Icon, { IconSizing } from 'components/Kizen/Icon';
import { colorsButton } from 'app/colors';
import { useTooltip } from 'components/Kizen/Tooltip';

const InfoIconStyles = styled(IconSizing)`
  margin-left: 5px;
  vertical-align: middle;
  margin-bottom: 2px;
`;

const Layout = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  && > button {
    margin-left: ${gutters.spacing(2)}px;
  }
  ${({ hoverable }) =>
    hoverable &&
    css`
      && > button {
        display: none;
      }
      &&:hover,
      tr:hover &&,
      .InlineHoverable:hover && {
        > button {
          display: inline-flex;
        }
      }
    `}
`;

const TextControl = React.forwardRef((props, ref) => {
  const {
    icon,
    color,
    hoverable,
    onSelect,
    selected,
    disabled,
    children,
    title,
    tooltipDisabled = true,
    iconSize,
    labelInfo,
    labelInfoPlacement,
    labelInfoMaxWidth,
    ...others
  } = props;

  let iconColor = selected ? color.hover : color;

  if (disabled) {
    iconColor = color.default;
  }
  const [tooltipProps, tooltip] = useTooltip({
    label: labelInfo,
    placement: labelInfoPlacement,
    maxWidth: labelInfoMaxWidth,
  });

  return (
    <Layout ref={ref} hoverable={hoverable && !selected} {...others}>
      {children}
      {icon && (
        <IconButton
          data-qa="show-element-button"
          sizing="dense"
          onClick={onSelect}
          disabled={disabled}
          title={title}
          tooltipDisabled={tooltipDisabled}
          color={iconColor}
        >
          <IconSizing
            size={icon === 'chevron-down' ? '12px' : iconSize || '10px'}
          >
            {/* Intended to have 10px width, icon has a funky aspect ratio */}
            <Icon icon={icon} />
          </IconSizing>
        </IconButton>
      )}
      {labelInfo && (
        <InfoIconStyles size="10px">
          <Icon icon="info-circle" {...tooltipProps} />
        </InfoIconStyles>
      )}
      {tooltip}
    </Layout>
  );
});

TextControl.displayName = 'TextControl';

TextControl.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),
  color: PropTypes.object,
  hoverable: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextControl.defaultProps = {
  icon: 'chevron-down',
  color: colorsButton.iconGray,
  hoverable: null,
  onSelect: null,
  selected: null,
  disabled: null,
};

export default TextControl;
