import produce from 'immer';

export const filterMetaDataSuccess = produce((draft, action) => {
  const {
    filterMetaData: { name, version, definition },
  } = action;
  draft.name = name;
  draft.version = version;
  draft.definition = definition;
});
