import { forwardRef, useRef } from 'react';
import FileBlock from './FileBlock';
import {
  StyledFilesList,
  StyledScrollBarDetached,
  FilesScrollContainer,
} from './styles';
import { useSyncSizes } from 'components/Tables/Big/hooks';
import useSyncScroll from 'react-use-sync-scroll';
import { applyRef } from 'components/Inputs/props';

export const FilesList = forwardRef(
  (
    {
      files,
      selectedFile,
      setSelectedFile,
      deselectFile,
      deleteFile,
      disabled,
      viewable,
      canDeleteFile,
      canEditFile,
      highlightSelectedOnly = false,
      showPreviewOption = true,
      enableFileBlockHoverOptions = false,
    },
    ref
  ) => {
    const scrollbarRef = useRef();
    const [refFn, scrollRef] = useSyncSizes(
      scrollbarRef,
      '.ContentForSyncHeight',
      'height'
    );
    useSyncScroll(useRef([scrollRef, scrollbarRef]), {
      vertical: true,
    });

    return (
      <>
        <FilesScrollContainer
          ref={(node) => {
            refFn(node);
            applyRef(ref, node);
          }}
        >
          <StyledFilesList className="ContentForSyncHeight">
            {files.map((file) => (
              <FileBlock
                key={file.id}
                file={file}
                onClick={setSelectedFile}
                focused={file.id === selectedFile?.id}
                selected={
                  highlightSelectedOnly
                    ? file.id === selectedFile?.id
                    : undefined
                }
                onDeselectFile={deselectFile}
                onDeleteFile={deleteFile}
                disabled={disabled}
                viewable={viewable}
                showPreviewOption={showPreviewOption}
                deletable={canDeleteFile?.(file.id)}
                editable={canEditFile?.(file.id)}
                enableHoverOptions={enableFileBlockHoverOptions}
              />
            ))}
          </StyledFilesList>
        </FilesScrollContainer>
        <StyledScrollBarDetached
          ref={scrollbarRef}
          direction="vertical"
          scrollClassName="ContentForSyncHeight"
        />
      </>
    );
  }
);
