import styled from '@emotion/styled';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useActivityRulesContext } from 'ts-activities/ActivityRulesContext';
import { ActivityRulesLocationState, FieldLike } from 'ts-activities/types';
import Card from 'components/Card/Card';
import { appBackground } from '__app/colors';
import { borderRadii } from '__app/spacing';
import { KizenTypography } from '__app/typography';
import AddButton from '__components/Button/AddButton';
import Loader from '__components/Kizen/Loader';
import { ActivityRuleCard, Option } from './ActivityRuleCard';

const BigCard = styled(Card)`
  padding: 20px 15px;
  margin: 15px auto 20px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > div:last-of-type {
    flex: 1;
  }
`;

const Title = styled.div`
  h3 {
    margin-top: -1px;
    margin-bottom: -4px;
    line-height: 1;
  }
`;

const InstructionText = styled(KizenTypography)`
  background-color: ${appBackground};
  border-radius: ${borderRadii.small};
  line-height: 15.4px;
  padding: 10px;
`;

const Footer = styled.div`
  display: flex;
`;

const AddActivityRuleButton = styled(AddButton)`
  line-height: unset;
  height: unset;
`;

const PageLoader = styled(Loader)`
  padding: 100px 0 200px;
`;

/**
 * Valid options for a card are any activity fields that have not
 * yet been selected for other activity rules.
 *
 * @param fields - all activity fields
 * @param cardIds - field ids selected for the card
 * @param allIds - field ids selected across all cards
 * @returns Option[]
 */
const getOptionsForCard = (
  fields: FieldLike[],
  cardIds: string[],
  allIds: string[]
) => {
  return fields.reduce<Option[]>((acc, x) => {
    if (cardIds.includes(x.id) || !allIds.includes(x.id)) {
      acc.push({ value: x.id, label: x.displayName });
    }
    return acc;
  }, []);
};

export const RulesPage = () => {
  const { t } = useTranslation();
  const {
    conditionOps,
    errors,
    fields: fieldsCtx,
    isLoading,
    rules,
    ops,
  } = useActivityRulesContext();
  const prevLength = useRef(rules.length);
  const scrollId = useRef<string>('');
  const allSelected = rules.flatMap((rule) => rule.fields);
  const location = useLocation<ActivityRulesLocationState>();
  const { scrollCardIndex, addRuleField } = location.state ?? {};

  scrollId.current = '';
  if (rules.length > prevLength.current || (addRuleField && rules.length > 0)) {
    scrollId.current = rules[rules.length - 1].id;
    prevLength.current = rules.length;
  }

  if (isLoading) {
    return <PageLoader loading />;
  }

  return (
    <BigCard>
      <Title>
        <KizenTypography as="h3" type="subheader" weight="bold">
          {t('Advanced Rules')}
        </KizenTypography>
      </Title>
      <InstructionText>
        {t(
          'The rules below will be processed in order from top to bottom. All required fields will not be required if they are hidden from the user.'
        )}
      </InstructionText>
      {rules.map(({ fields, conditions, id, operation }, i, arr) => {
        const hasError =
          errors[i] && (errors[i].fields || errors[i].conditions.hasErrors);

        if (hasError && !scrollId.current) {
          scrollId.current = id;
        }

        return (
          <ActivityRuleCard
            key={id}
            id={id}
            isFirst={i === 0}
            isLast={i === arr.length - 1}
            conditions={conditions.map((cond) => ({
              steps: [...cond],
              ops: conditionOps.get(cond)!,
            }))}
            errors={errors[i]}
            fields={fields}
            fieldOptions={getOptionsForCard(fieldsCtx, fields, allSelected)}
            operation={operation}
            onAddCondition={() => ops.addCondition(i)}
            onDeleteCondition={(index) => ops.deleteCondition(i, index)}
            onDelete={() => ops.deleteRule(i)}
            onFieldsChange={(ids) => ops.fieldsChanged(i, ids)}
            onReorderUp={() => ops.reorderUp(i)}
            onReorderDown={() => ops.reorderDown(i)}
            onOperationChange={(op) => ops.operationChanged(i, op)}
            scrollToSelf={scrollId.current === id || scrollCardIndex === i}
          />
        );
      })}
      <Footer>
        <AddActivityRuleButton noSpace onClick={() => ops.addRule()}>
          {t('Add Activity Rule')}
        </AddActivityRuleButton>
      </Footer>
    </BigCard>
  );
};
