import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Kizen/Loader';
import SurveyService from 'services/SurveyService';
import { setSearchParams, getSearchParam } from 'hooks/useSearchParam';
import { isMobile, useWindowSize } from 'app/spacing';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { setOpenMenuAbove } from 'components/Tables/helpers';
import useModal from 'components/Modals/useModal';
import {
  buildPage,
  getSurveys,
  updatePageConfig,
  updateSurveyFieldSuccess,
  duplicateSurvey,
  deleteSurvey,
  cleanDeleteSurveyToastMessage,
  selectAddSurveyModels,
  selectSurveys,
  selectSurveysCount,
  selectSurveysPageConfig,
  selectSurveysDeleteToastMessage,
  addNewSurvey,
  surveysIsFetching,
} from 'store/surveysPage/surveys.redux';
import SurveyTabularPage from './TabularPage';
import SurveyListPage from './ListPage';
import AddModalComponent from './AddModal';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useToast } from 'components/ToastProvider';
import { useDebounce } from 'react-use';
import { maybeTrackBuilderPreview, maybeTrackSearch } from 'utility/analytics';
import { DEFAULT_DELAY } from 'utility/config';
import { deferExecution } from 'utility/defer';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';

export const previewSurvey = (...args) => {
  const preview =
    (businessUrl) =>
    (slug, windowName = '_blank') => {
      maybeTrackBuilderPreview('survey', slug);
      const win = window.open(`${businessUrl}/survey/${slug}`, windowName);
      win.focus();
    };

  const [url, slug, windowName] = args;
  return slug ? preview(url)(slug, windowName) : preview(url);
};

export default function SurveysPage(props) {
  const { t } = useTranslation();
  useSetTitleOnLoad(props.title(t));
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();

  const [showToast] = useToast();

  // TODO: when hosting standalone forms we'll want to use the configurable subdomain from Settings -> Business Information
  const chosenBusinessUrl = `https://${import.meta.env.VITE_EMBED_FRONTEND_DOMAIN}`;
  // const chosenBusinessUrl = useSelector(
  //   ({ authentication }) =>
  //     !!authentication.chosenBusiness && authentication.chosenBusiness.url
  // );

  const models = useSelector(selectAddSurveyModels);
  const pageConfig = useSelector(selectSurveysPageConfig);
  const surveysCount = useSelector(selectSurveysCount);
  const surveysWithoutMeta = useSelector(selectSurveys);
  const isFetching = useSelector(surveysIsFetching);

  // Track debounced changes to the search term and send to google analytics
  const { search } = pageConfig;
  useDebounce(() => maybeTrackSearch(search, 'surveys'), DEFAULT_DELAY, [
    search,
  ]);

  // async effects -------------------------------------------------
  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const search = getSearchParam(history.location, 'q');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');

    syncDispatch(
      buildPage({
        page: {
          search,
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
        },
      })
    );
  }, [syncDispatch, history]);

  const surveys = useMemo(() => {
    return surveysWithoutMeta && surveysWithoutMeta.map(setOpenMenuAbove);
  }, [surveysWithoutMeta]);

  const handleChangeSearch = useCallback(
    (q) => {
      dispatch(
        updatePageConfig({
          search: q,
          page: 1,
        })
      );
      dispatch(getSurveys());
      deferExecution(() => {
        setSearchParams(history, { q, page: null }, { method: 'replace' });
      });
    },
    [dispatch, history]
  );

  const sortValue = useMemo(
    () => getSortValue(pageConfig.sort),
    [pageConfig.sort]
  );

  const handleSort = useCallback(
    ({ column, direction }) => {
      const value = getOrderingParam({ column, direction });

      dispatch(
        updatePageConfig({
          sort: value,
        })
      );
      dispatch(getSurveys());
      deferExecution(() => {
        setSearchParams(
          history,
          {
            sort: value,
          },
          { method: 'replace' }
        );
      });
    },
    [dispatch, history]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          page: value,
        })
      );
      dispatch(getSurveys());
      deferExecution(() => {
        setSearchParams(history, {
          page: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          size: value,
        })
      );
      dispatch(getSurveys());
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleUpdateSurveyField = useCallback(
    async (survey, patch) => {
      try {
        const updatedSurvey = await SurveyService.update(survey.id, patch);
        dispatch(updateSurveyFieldSuccess(updatedSurvey));
      } catch (err) {
        let error = err;
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = Object.entries(err.response.data.errors);
          if (errors && errors.length) {
            // we show only 1st error
            error = new Error(errors[0][1]);
          }
        }
        throw error;
      }
    },
    [dispatch]
  );

  const handleEditSurvey = useCallback(
    (id) => {
      history.push(`/surveys/${id}`);
    },
    [history]
  );

  const handleDuplicateSurvey = (id) => dispatch(duplicateSurvey(id));

  const handleConfirmDeleteSurvey = (id) => dispatch(deleteSurvey(id));

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const handleDeleteSurvey = (id) => setDeleteConfirmation({ id });

  const surveyDeleteToastMessage = useSelector(selectSurveysDeleteToastMessage);
  useEffect(() => {
    if (surveyDeleteToastMessage) {
      showToast({
        variant: surveyDeleteToastMessage.variant,
        message: surveyDeleteToastMessage.message,
      });
      dispatch(cleanDeleteSurveyToastMessage());
    }
  }, [dispatch, surveyDeleteToastMessage, showToast]);

  const handleCreate = useCallback(
    async (newSurvey) => {
      dispatch(addNewSurvey(newSurvey));
      handleEditSurvey(newSurvey.id);
    },
    [dispatch, handleEditSurvey]
  );

  const [addModalProps, , addModal] = useModal({
    handleSubmit: handleCreate,
  });

  if (isFetching) {
    return <Loader loading />;
  }

  return (
    <>
      {deleteConfirmation && (
        <ConfirmDeletionModal
          show
          onHide={() => setDeleteConfirmation(null)}
          onConfirm={async () => {
            const { id } = deleteConfirmation;
            try {
              await handleConfirmDeleteSurvey(id);
            } finally {
              setDeleteConfirmation(null);
            }
          }}
        >
          {t('This will permanently delete the survey.')}
        </ConfirmDeletionModal>
      )}
      {isMobile(width) ? (
        <SurveyListPage
          surveys={surveys || []}
          surveysCount={surveysCount}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSort}
          pageNumber={pageConfig.page || 1}
          pageSize={pageConfig.size || 50}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onSelectPreviewSurvey={previewSurvey(chosenBusinessUrl)}
          onSelectDeleteSurvey={handleDeleteSurvey}
          {...props}
        />
      ) : (
        <SurveyTabularPage
          surveys={surveys || []}
          surveysCount={surveysCount}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSort}
          pageNumber={pageConfig.page || 1}
          pageSize={pageConfig.size || 50}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onUpdateSurveyField={handleUpdateSurveyField}
          onSelectEditSurvey={handleEditSurvey}
          onSelectPreviewSurvey={previewSurvey(chosenBusinessUrl)}
          onSelectDuplicateSurvey={handleDuplicateSurvey}
          onSelectDeleteSurvey={handleDeleteSurvey}
          openAddModal={addModal.show}
          {...props}
        />
      )}
      {addModal.show && (
        <AddModalComponent models={models} {...addModalProps} />
      )}
    </>
  );
}
