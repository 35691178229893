import { useMemo } from 'react';

export const useScrollSubscription = () =>
  useMemo(() => {
    const subs = {};

    const subscribe = (key, cb) => {
      subs[key] = cb;
    };

    const unsubscribe = (key) => {
      delete subs[key];
    };

    const onStart = () =>
      Object.values(subs).forEach((cb) => typeof cb === 'function' && cb());

    return [subscribe, unsubscribe, onStart];
  }, []);
