import React, {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { borderRadii, gutters, scrollbarCss } from '../../../app/spacing';
import { grayScale, colorsText } from '../../../app/colors';
import { TextSpan } from '../../../app/typography';
import { useLength } from '../../../hooks/useLength';
import useField from '../../../hooks/useField';
import InsertMergeField from '../../Kizen/InsertMergeField';
import BaseInput from '../TextInput/BaseInput';
import BaseInputLayout from '../TextInput/BaseInputLayout';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import ResizeWrapper from '../ResizeWrapper';
import { applyRef } from '../props';
import Validation, { useValidation } from '../Validation';

const MERGE_HEIGHT = 39;

const OutlineTextarea = styled(BaseInput)`
  ${scrollbarCss}
  // Makes top spacing similar to the natural vertical flow of OutlineTextInput
  ${({ showMerge }) =>
    showMerge
      ? css`
          margin-top: ${gutters.spacing(1, 4)}px;
          height: calc(100% - ${MERGE_HEIGHT + gutters.spacing(4, 3)}px);
        `
      : css`
          margin-top: ${gutters.spacing(1, 4)}px;
          height: calc(100% - ${gutters.spacing(4, 3)}px);
        `};
  // Account for baseline to get accurate spacing
  margin-bottom: ${gutters.spacing(2)}px;
  // Account for right scrollbar width
  padding-right: ${gutters.spacing(2, -4)}px;
  padding-left: ${gutters.spacing(2)}px;
  overflow-y: scroll;
  width: 100%;
  line-height: 1.15;

  resize: none;
`;

export const OutlineLayout = styled(BaseInputLayout)`
  border-radius: ${borderRadii.small};
  border: none;
  // no blue on hover
  ${({ disabled, focused }) =>
    !disabled &&
    !focused &&
    css`
      &:hover {
        border-color: ${grayScale.medium};
      }
    `}
`;

export const MergeColumn = styled.div`
  width: 100%;
  ${({ showMerge }) =>
    showMerge &&
    css`
      div.merge {
        width: 100%;
        padding: ${gutters.spacing(0, 3)}px 0 ${gutters.spacing(0, 2)}px; // vertically center button
        border-bottom: 1px solid ${grayScale.medium};
        height: ${MERGE_HEIGHT}px;
      }
    `}
`;

export const CountWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  margin: ${gutters.spacing(2, 2)}px 0 ${gutters.spacing(1)}px;
`;

export const StyledLabel = styled(TextSpan)`
  line-height: 125%;
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
  margin-bottom: ${gutters.spacing(3, { baseline: true })}px;
`;

const useRunAfterUpdate = () => {
  const afterPainRef = useRef(null);
  useLayoutEffect(() => {
    if (afterPainRef.current) {
      afterPainRef.current();
      afterPainRef.current = null;
    }
  });
  const runAfterUpdate = (fn) => {
    afterPainRef.current = fn;
    return afterPainRef.current;
  };
  return runAfterUpdate;
};

// export default function OutlineLongText(props) {
const OutlineLongText = React.forwardRef((props, ref) => {
  const {
    className,
    value: valueProp = '',
    placeholder,
    disabled,
    error,
    onChange,
    onFocus,
    onBlur,
    showCountComponent,
    mergeFields,
    enableResize,
    inModal,
    setMenuOpen,
    selectionStart,
    ...others
  } = props;
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [value, setValue] = useField(valueProp);
  const [isCompact, setIsCompact] = useState(false);
  const validationRef = useRef();
  const textareaElem = useRef(null);
  const wrapperRef = useRef();
  const [, validationProps] = useValidation(validationRef, props);

  const mergeRef = useCallback(
    (el) => {
      applyRef(textareaElem, el);
      applyRef(ref, el);
    },
    [ref]
  );

  const [
    {
      maxLength,
      singleMessageCount,
      maxLengthForSingleMessage,
      countOfMessages,
      maxCountOfMessages,
      totalCount,
    },
    updateEvent,
  ] = useLength();

  useEffect(() => {
    if (!valueProp && value) {
      setValue('');
    }
  }, [valueProp, value, setValue]);

  const runAfterUpdate = useRunAfterUpdate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (wrapperRef.current) {
      // InsertMergeField button incorrectly stacks below ~160px width
      // we adjust at 170 just for a little cushion
      setIsCompact(wrapperRef.current.offsetWidth <= 170);
    }
  });

  const textareaChangeEvent = useCallback(
    (text) => {
      // TODO Relocate merge field parsing seen in pages/AutomationEngine/dialogs/ActionWizard/utilities
      // for the send text action to this component if that approach becomes standard
      // for handling merge fields

      if (maxLength >= (text || '').length) {
        if (onChange) {
          onChange(text);
        }
        setValue(text);
        updateEvent(text);
      } else {
        const truncated = text.substring(0, maxLength);
        // TODO Be more polite here than cutting off any overflow
        if (onChange) {
          onChange(truncated);
        }
        setValue(truncated);
        updateEvent(truncated);
      }
    },
    [maxLength, updateEvent, onChange, setValue]
  );

  const handleSelect = useCallback(
    ([{ value: newValue }]) => {
      const selectValueDropdown = () => {
        const txtarea = textareaElem.current;
        const start = txtarea.selectionStart;
        const end = txtarea.selectionEnd;
        const sel = txtarea.value.substring(start, end);
        const finText = `${txtarea.value.substring(
          0,
          start
        )} ${newValue} ${txtarea.value.substring(end)}`;

        textareaChangeEvent(finText);
        txtarea.focus();
        runAfterUpdate(() => {
          txtarea.selectionEnd = end - sel.length + newValue.length + 1;
        });
      };

      selectValueDropdown(newValue);
    },
    [runAfterUpdate, textareaChangeEvent]
  );
  useEffect(() => {
    if (selectionStart !== null && textareaElem && textareaElem.current) {
      textareaElem.current.selectionStart = selectionStart;
      textareaElem.current.selectionEnd = selectionStart;
      textareaElem.current.focus({ preventScroll: true });
    }
  }, [textareaElem, selectionStart]);

  return (
    <MaybeInputControl variant="outline" forInput {...props}>
      <OutlineLayout
        ref={validationRef}
        className={!hasInputControl(props) && className}
        focused={focused}
        disabled={disabled}
        error={error}
      >
        <MergeColumn ref={wrapperRef} showMerge={mergeFields}>
          <ResizeWrapper
            enableResize={!disabled && enableResize}
            minHeight={mergeFields ? 135 : 63}
            addBorder={!error}
            error={!!error}
          >
            {mergeFields && (
              <div className="merge">
                <InsertMergeField
                  onChange={handleSelect}
                  mergeFields={
                    Array.isArray(mergeFields) ? mergeFields : undefined
                  }
                  inModal // stop it getting pushed up
                  setMenuOpen={setMenuOpen}
                  isCompact={isCompact}
                />
              </div>
            )}
            <OutlineTextarea
              showMerge={mergeFields}
              ref={mergeRef}
              as="textarea"
              value={value}
              onChange={(ev) => {
                textareaChangeEvent(ev.target.value, ev);
              }}
              placeholder={placeholder}
              disabled={disabled}
              onFocus={(ev) => {
                setFocused(true);
                if (onFocus) onFocus(ev);
              }}
              onBlur={(ev) => {
                setFocused(false);
                if (onBlur) onBlur(ev);
              }}
              enableResize={false}
              {...others}
            />
          </ResizeWrapper>
        </MergeColumn>
      </OutlineLayout>
      <Validation {...validationProps} />
      {showCountComponent && (
        <CountWrapper>
          <StyledLabel>
            {`${singleMessageCount}/${maxLengthForSingleMessage} ${t(
              'characters left in message'
            )} ${countOfMessages} ${t('of')} ${maxCountOfMessages}`}
          </StyledLabel>
          <StyledLabel>
            {`${totalCount} ${t('of')} ${maxLength} ${t('total characters')}`}
          </StyledLabel>
        </CountWrapper>
      )}
    </MaybeInputControl>
  );
});

OutlineLongText.displayName = 'OutlineLongText';

OutlineLongText.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  enableResize: PropTypes.bool,
  showCountComponent: PropTypes.bool,
  mergeFields: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  ]),
  inModal: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  selectionStart: PropTypes.number,
};

OutlineLongText.defaultProps = {
  value: '',
  onChange: null,
  error: null,
  disabled: null,
  placeholder: null,
  onFocus: null,
  onBlur: null,
  showCountComponent: false,
  mergeFields: false,
  enableResize: true,
  inModal: false,
  setMenuOpen: () => {},
  selectionStart: null,
};

export default OutlineLongText;
