import { CO_LAYOUT_ACTION_BLOCK_ACTIONS } from 'components/Wizards/CustomObject/utilities';

export const getToggles = (t) => [
  {
    key: CO_LAYOUT_ACTION_BLOCK_ACTIONS.ADD_NOTES,
    label: t('Add Notes'),
  },
  {
    key: CO_LAYOUT_ACTION_BLOCK_ACTIONS.SEND_EMAILS,
    label: t('Send Emails'),
    forContacts: true,
  },
  {
    key: CO_LAYOUT_ACTION_BLOCK_ACTIONS.SCHEDULE_ACTIVITIES,
    label: t('Schedule Activities'),
  },
  {
    key: CO_LAYOUT_ACTION_BLOCK_ACTIONS.LOG_ACTIVITIES,
    label: t('Log Activities'),
  },
  {
    key: CO_LAYOUT_ACTION_BLOCK_ACTIONS.START_AUTOMATIONS,
    label: t('Start Automations'),
  },
];

export const createPayload = (values) => {};
