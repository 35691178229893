import { ContentWidth } from 'components/Layout/PageContentWidth';
import { BigListTitle } from 'components/Layout/BigListLayout';
import { TextEllipsis } from 'components/Kizen/Table';
import {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
} from 'components/Layout/ListCard';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import DateInputInline, {
  DateInputInlineSelect,
} from 'components/Inputs/inline/DateInput';
import StatusWidget from '../StatusWidget';
import ActionWidget from '../ActionWidget';
import { MobileTableCard } from '../styles';
import {
  MobileToolbar,
  MobileTBSearchInput,
  TableScrollWrapper,
  StyledWrapper,
} from './styles';
import { AutomationName } from '../columns';
import { useTranslation } from 'react-i18next';
import { SORT_BY } from '../contstants';
import { getSortValue } from 'utility/SortingHelpers';
import { useAutomationPermissions } from 'ts-components/hooks/permissions/automations';
import { useRef } from 'react';

const MobileView = ({
  executions,
  pageNameSingular,
  pageName,
  search,
  onHandleChangeSearch,
  ordering,
  onHandleSort,
  onUpdateAutomation,
  totalCount = 0,
  customObjectId,
  entity,
  isClient,
}) => {
  const { t } = useTranslation();

  const sort = getSortValue(ordering);

  const { canManage, canStart } = useAutomationPermissions({
    objectId: customObjectId,
    entityObject: entity,
    isClient,
  });

  const scrollContainerRef = useRef(null);

  return (
    <>
      <ContentWidth>
        <MobileToolbar>
          <MobileTBSearchInput
            placeholder={`${t('Find')} ${pageName}`}
            value={search}
            onChange={onHandleChangeSearch}
            endAdornment={<IconAdornment icon="search" />}
          />
          <BigListTitle
            count={totalCount}
            single={pageNameSingular}
            plural={pageName}
            style={{ 'margin-bottom': '-6px' }}
          />
        </MobileToolbar>
      </ContentWidth>
      <MobileTableCard as={ContentWidth}>
        <TableScrollWrapper
          compactHeader
          shadow
          bottom
          ref={scrollContainerRef}
        >
          {/* need a div inside TableScrollWrapper other wise it crushes everything inside as it thinks it's height is small than it is */}
          <StyledWrapper>
            <ListCardHeader
              compactHeader
              stickyHeader
              action={<div style={{ width: 18 }} />} // make the width equal 43px to match <ActionWidgets /> below
              withScrollbarPadding
              className="sticky-header-with-shadow"
            >
              <ListCardCell>
                <TextEllipsis as="span" weight="bold">
                  {t('Automations Name')}
                </TextEllipsis>
                <ListCardSortButton
                  sort={sort && sort.column === SORT_BY.NAME ? sort : null}
                  onChangeSort={(direction) =>
                    onHandleSort(SORT_BY.NAME, direction)
                  }
                />
              </ListCardCell>
            </ListCardHeader>
            {executions.map((automation) => (
              <ListCardItem
                key={automation.id}
                action={null}
                withScrollbarPadding
              >
                <ListCardRow>
                  <ListCardCell>
                    <AutomationName {...automation} />
                  </ListCardCell>
                  <ListCardCell>
                    {canManage ? (
                      <ActionWidget
                        data={automation}
                        onUpdateAutomation={onUpdateAutomation}
                        scrollContainerRef={scrollContainerRef}
                        canStart={canStart}
                      />
                    ) : null}
                  </ListCardCell>
                </ListCardRow>

                <ListCardRow>
                  <ListCardCell>
                    <StatusWidget automation={automation} />
                  </ListCardCell>
                  <ListCardCell>
                    <DateInputInline readOnly>
                      <DateInputInlineSelect value={automation.startTime} />
                    </DateInputInline>
                  </ListCardCell>
                </ListCardRow>
              </ListCardItem>
            ))}
          </StyledWrapper>
        </TableScrollWrapper>
      </MobileTableCard>
    </>
  );
};

export default MobileView;
