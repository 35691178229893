import { grayScale } from 'app/colors';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { EVENT_TYPES } from 'pages/Broadcasts/constants';
import { isEventTypeAvailable } from 'pages/Broadcasts/helpers';
import { useMemo } from 'react';
import { EventFilterIcon } from '../EventIcon';
import { FilterContainer } from './styles';
import { PopoverSearch } from 'components/PopoverSearch';

export const EventsFilter = ({
  filters = [],
  setFilters,
  searchProps,
  scrolled,
  access,
}) => {
  const preReleaseFeature = usePreReleaseFeatures();

  const eventTypes = useMemo(() => {
    return Object.values(EVENT_TYPES)
      .filter(isEventTypeAvailable(preReleaseFeature, access))
      .map((props) => ({
        ...props,
        enabled: filters.some((filterType) => filterType === props.type),
      }));
  }, [preReleaseFeature, filters, access]);

  const handleSetFilter = (type) => () => setFilters(type);

  return (
    <FilterContainer scrolled={scrolled} data-qa-events-filter>
      {eventTypes.map(({ type, enabled, color, ...restProps }) => (
        <EventFilterIcon
          key={type}
          {...restProps}
          color={enabled ? color : grayScale.medium}
          onClick={handleSetFilter(type)}
        />
      ))}
      <PopoverSearch {...searchProps} />
    </FilterContainer>
  );
};
