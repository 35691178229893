import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { SectionProps } from '../../types';
import { NodeType } from '../types';
import {
  ElementContainer,
  DEFAULT_ELEMENT_CONTAINER_PROPS,
} from './ElementContainer';
import { applyLegacyContainerPadding } from './utils';

type SectionContainerProps = SectionProps & {
  children: ReactNode;
  draggingType?: NodeType;
};

type SectionWrapperProps = SectionContainerProps & {
  applyLegacyContainerPadding: boolean;
};

const alignSelf = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const DEFAULT_SECTION_CONTAINER_PROPS = {
  ...DEFAULT_ELEMENT_CONTAINER_PROPS,
  containerBackgroundColor: 'inherit',
  containerPaddingTop: '10',
  containerPaddingRight: '10',
  containerPaddingBottom: '10',
  containerPaddingLeft: '10',
};

const SectionWrapper = styled(ElementContainer)<SectionWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
  }
  ${({ applyLegacyContainerPadding }) =>
    applyLegacyContainerPadding &&
    css`
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
    `}
  align-self: ${({ alignment = 'center' }) => alignSelf[alignment]};
  width: ${({ width = 100 }) => `${width}%`};
  max-width: ${({ maxWidth }) =>
    maxWidth &&
    css`
      ${maxWidth}px;
    `};
`;

export const SectionContainer = forwardRef(
  (
    { children, draggingType, ...rest }: SectionContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <SectionWrapper
      ref={ref}
      applyLegacyContainerPadding={applyLegacyContainerPadding(rest)}
      ignoreChildrenDrag={draggingType === 'Section'}
      {...rest}
    >
      {children}
    </SectionWrapper>
  )
);
