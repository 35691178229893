import { useMemo, useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import KizenTypography from 'app/kizentypo';
import Icon from 'components/Kizen/Icon';
import KizenIconButtonMenu from 'components/Kizen/IconButtonMenu';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { gutters, borderRadii, breakpoints } from 'app/spacing';
import { grayScale, colorsButton } from 'app/colors';
import {
  fileShape,
  previewFileExtensions,
} from 'components/FilePicker/defaults';
import FileService from 'services/FileService';
import * as auth from 'utility/Authentication';

const Wrapper = styled.div`
  width: 120px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: 0 ${gutters.spacing(4)}px ${gutters.spacing(4)}px 0;
  position: relative;
  @media screen and (max-width: ${breakpoints.sm}px) {
    height: unset;
    margin: 0 ${gutters.spacing(4)}px ${gutters.spacing(2, 2)}px 0;
  }
`;

const PreviewImageContainer = styled.div`
  width: 120px;
  height: 120px;
  flex: 0 0 120px;
  border-radius: ${borderRadii.small};
  background: ${grayScale.light};
  ${({ enableBlueBorder }) =>
    enableBlueBorder &&
    css`
      border: 1px solid ${colorsButton.blue.default};
    `}
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  span {
    color: ${grayScale.mediumDark};
  }

  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${gutters.spacing(2)}px;
  box-sizing: border-box;

  > p {
    font-size: 32px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    height: unset;
    margin-bottom: ${gutters.spacing(1, 3)}px;
  }
`;

const PreviewImageHeader = styled.div`
  top: 0;
  right: 0;
  left: 0;
  height: 15px;
  color: ${colorsButton.iconWhite.default};
  display: flex;
  justify-content: ${({ showOptions }) =>
    showOptions ? 'space-between' : 'center'};
  align-items: center;
  border-top-left-radius: ${borderRadii.small};
  border-top-right-radius: ${borderRadii.small};
  ${({ selected, showOptions }) =>
    selected || showOptions
      ? css`
          background: ${colorsButton.blue.default};
        `
      : css`
          cursor: pointer;
        `}
  box-sizing: border-box;
  position: absolute;
`;
const PreviewImageHeaderTitle = styled.div``;
const PreviewImageHeaderEnds = styled.div`
  flex: 0 0 25px;
  width: 25px;
`;

const AlignText = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const IconButtonMenu = styled(KizenIconButtonMenu)`
  && .IconButtonMenu__menu {
    ${({ above }) => !above && 'transform: translate(-100%, -5px);'}
    ${({ above }) =>
      above && 'transform: translate(-100%, -100%) translateY(-20px);'}
  }
`;

const FileBlock = ({
  file,
  focused = false,
  onClick,
  onDeselectFile,
  onDeleteFile,
  disabled,
  viewable,
  deletable = true,
  editable = true,
  selected = true,
  showPreviewOption = true,
  enableHoverOptions = false,
}) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const ext = file && (file?.name || '').split('.').pop();
  const isImage = previewFileExtensions.includes(`.${ext.toLowerCase()}`);
  const canDeselect = editable && !!onDeselectFile;
  const canDelete = deletable && !!onDeleteFile && !file?.is_common;

  const options = useMemo(
    () =>
      [
        viewable &&
          showPreviewOption &&
          auth.status.authorized && { value: 'preview', label: t('Preview') },
        viewable &&
          file.url &&
          auth.status.authorized && { value: 'open', label: t('Open File') },
        viewable &&
          file.url &&
          auth.status.authorized && { value: 'download', label: t('Download') },
        viewable &&
          !disabled &&
          canDeselect && { value: 'deselect', label: t('Deselect') },
        viewable &&
          !disabled &&
          canDelete &&
          auth.status.authorized && { value: 'delete', label: t('Delete') },
      ].filter(Boolean),
    [viewable, disabled, showPreviewOption, canDeselect, canDelete, file.url, t]
  );

  const onSelectAction = ({ value }) => {
    switch (value) {
      case 'deselect':
        onDeselectFile(file);
        break;

      case 'delete':
        onDeleteFile(file);
        break;

      case 'open': {
        const anchor = document.createElement('A');
        anchor.href = file.url;
        anchor.setAttribute('target', '_blank');
        anchor.click();
        break;
      }
      case 'download': {
        const anchor = document.createElement('A');
        anchor.href = `${file.url}?disposition=attachment`;
        anchor.click();
        break;
      }

      default:
        // also preview
        onClick(file);
        break;
    }
  };

  const hasOptions = (showPreviewOption || selected) && options.length > 0;
  const showHoverOptions = enableHoverOptions && isHovered;
  const showOptions = hasOptions || showHoverOptions;

  return (
    <Wrapper
      onClick={() => onClick(file)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <PreviewImageHeader selected={selected} showOptions={showOptions}>
        {showOptions ? <PreviewImageHeaderEnds /> : null}
        {selected && (
          <PreviewImageHeaderTitle>
            <KizenTypography type="micro" color={grayScale.white}>
              {t('Selected')}
            </KizenTypography>{' '}
          </PreviewImageHeaderTitle>
        )}
        {showOptions ? (
          <PreviewImageHeaderEnds
            onClick={(ev) => {
              ev.stopPropagation();
            }}
            data-qa-file={file?.name}
          >
            <IconButtonMenu
              sizing="dense"
              title={t('Edit File')}
              position="right"
              color={colorsButton.iconWhite}
              onChange={(value) => onSelectAction(value)}
              options={options}
            >
              <Icon icon="three-dot" />
            </IconButtonMenu>
          </PreviewImageHeaderEnds>
        ) : null}
      </PreviewImageHeader>
      <PreviewImageContainer
        enableBlueBorder={selected || showHoverOptions}
        focused={focused}
        style={{
          backgroundImage:
            isImage && `url(${FileService.getThumbnailUrl(file.id)})`,
        }}
      >
        {!isImage && ext && (
          <KizenTypography type="header" weight="bold">
            .{ext.toUpperCase()}
          </KizenTypography>
        )}
      </PreviewImageContainer>
      <AlignText>
        <TextEllipsisWithTooltip>{file?.name}</TextEllipsisWithTooltip>
      </AlignText>
    </Wrapper>
  );
};

FileBlock.propTypes = {
  file: PropTypes.objectOf(fileShape).isRequired,
  focused: PropTypes.bool,
  selected: PropTypes.bool,
  showPreviewOption: PropTypes.bool,
  onClick: PropTypes.func,
  onDeselectFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  viewable: PropTypes.bool.isRequired,
};

export default FileBlock;
