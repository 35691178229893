import React, { useEffect, useRef } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsText, grayScale } from '../../../app/colors';
import { layers } from '../../../app/spacing';
import { fontSizes, TextSpan, UtilityLink } from '../../../app/typography';
import { enrichEl } from '../../helpers';
import ListItem from '../../Kizen/List/ListItem';
import ListSubheader from '../../Kizen/List/ListSubheader';
import BaseMenu, { MenuList as BaseMenuList } from '../../Kizen/Menu';
import StylePassthrough from '../../Kizen/StylePassthrough';
import { TextEllipsis, TextEllipsisWithTooltip } from '../../Kizen/Table';
import { FIELD_TYPES } from '../../../utility/constants';
import IconAdornment from '../Adornments/IconAdornment';
import BaseInput from '../TextInput/BaseInput';
import { Dot } from '../../Fields/ColorLabel';
import { OutlineInputLayout } from '../TextInput/Outline';
import { UnderlineInputLayout } from '../TextInput/Underline';

const controlCss = ({ menuIsOpen, placement, isReadOnly }) => css`
  min-height: 0;
  box-shadow: none;
  transition: none;
  // Using type here to skip over Dot if it is present
  > div:first-of-type {
    margin-left: -2px;
    padding: 0;
    // Maintain height when isSearchable is false and input is hidden
    line-height: ${fontSizes.text};
  }
  ${menuIsOpen &&
  placement !== 'top' &&
  css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  ${menuIsOpen &&
  placement === 'top' &&
  css`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
  ${isReadOnly &&
  css`
    && {
      border-color: ${grayScale.medium};
    }
  `}
`;

const OutlineControlStyles = styled(OutlineInputLayout)`
  ${controlCss}
`;

const UnderlineControlStyles = styled(UnderlineInputLayout)`
  border-radius: 0;
  ${controlCss}
`;

export const Control = (props) => {
  const {
    isFocused,
    isDisabled,
    menuIsOpen,
    getValue,
    selectProps: {
      isColored,
      isReadOnly,
      inputValue,
      variant,
      error,
      menuOnly,
      computedMenuPlacement,
    },
    children,
  } = props;
  if (menuOnly) {
    return null;
  }
  const VariantControlStyles =
    variant === 'underline' ? UnderlineControlStyles : OutlineControlStyles;
  const [value] = [].concat(getValue() || []); // getValue() seems to be an array. Playing it safe.
  return (
    <VariantControlStyles
      as={StylePassthrough}
      focused={isFocused}
      disabled={isDisabled}
      error={error}
      menuIsOpen={menuIsOpen}
      isReadOnly={isReadOnly}
      placement={computedMenuPlacement}
    >
      <components.Control {...props}>
        {isColored && <Dot color={!inputValue && value && value.color} />}
        {children}
      </components.Control>
    </VariantControlStyles>
  );
};

Control.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
  getValue: PropTypes.func.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export const IndicatorSeparator = () => null;

export const DropdownIndicator = (props) => {
  const {
    className,
    isDisabled,
    selectProps: { variant, endAdornment },
  } = props;
  if (variant === 'underline' && isDisabled) {
    return null;
  }
  return React.cloneElement(
    endAdornment || <IconAdornment icon="chevron-down" />,
    {
      variant,
      end: 'true',
      className,
    }
  );
};

const InputStyles = styled(BaseInput)``;

export const Input = (props) => {
  return (
    <InputStyles as={StylePassthrough}>
      <components.Input {...props} />
    </InputStyles>
  );
};

const SingleValueStyles = styled(TextSpan)`
  max-width: 100%;
`;

export const SingleValue = (props) => {
  return (
    <SingleValueStyles as={StylePassthrough}>
      <components.SingleValue {...props} />
    </SingleValueStyles>
  );
};

// This is can be used to build SingleValues that appear actionable
export const SingleValueLink = ({
  href,
  to,
  onClick,
  onMouseDown,
  as,
  target,
  rel,
  children,
  ...props
}) => {
  return (
    <SingleValue {...props}>
      <TextEllipsisWithTooltip
        style={{
          // Relative fontsizing was leading to incorrectly enlarged font size
          fontSize: 'unset',
        }}
      >
        <UtilityLink
          as={as || (!href && 'span') || undefined}
          size="inherit"
          to={to}
          href={href}
          target={target}
          rel={rel}
          onClick={onClick}
          onMouseDown={(ev) => {
            ev.stopPropagation(); // Avoid opening menu with click
            if (onMouseDown) onMouseDown(ev);
          }}
        >
          {children}
        </UtilityLink>
      </TextEllipsisWithTooltip>
    </SingleValue>
  );
};

SingleValueLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  as: PropTypes.elementType,
  target: PropTypes.string,
  rel: PropTypes.string,
};

SingleValueLink.defaultProps = {
  to: null,
  href: null,
  onClick: null,
  onMouseDown: null,
  as: null,
  target: null,
  rel: null,
};

export const Placeholder = (props) => {
  const {
    isDisabled,
    selectProps: { variant },
  } = props;
  if (variant === 'underline' && isDisabled) {
    return (
      <TextSpan color={colorsText.dark} as={StylePassthrough}>
        <components.Placeholder {...props}>—</components.Placeholder>
      </TextSpan>
    );
  }
  return (
    <TextEllipsis color={colorsText.medium} as={StylePassthrough}>
      <components.Placeholder {...props} />
    </TextEllipsis>
  );
};

Placeholder.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  selectProps: PropTypes.object.isRequired,
};

// This is can be used to build Placeholders that appear actionable
export const PlaceholderLink = ({
  href,
  to,
  onClick,
  as,
  children,
  ...props
}) => {
  return (
    <Placeholder {...props}>
      <UtilityLink
        as={as || (!href && 'span') || undefined}
        size="inherit"
        to={to}
        href={href}
        onClick={onClick}
      >
        {children}
      </UtilityLink>
    </Placeholder>
  );
};

PlaceholderLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  as: PropTypes.elementType,
};

PlaceholderLink.defaultProps = {
  to: null,
  href: null,
  onClick: null,
  as: null,
};

const StyledMenu = styled(BaseMenu)`
  position: absolute;
  width: 100%;
  // Needs to open above radio buttons and checkboxes, which have z-index of 1
  z-index: ${layers.content(0, 2)};
  ${({ maxHeight }) => css`
    max-height: ${maxHeight || 350}px;
  `}
  ${({ placement }) =>
    placement === 'top'
      ? css`
          bottom: 100%;
        `
      : css`
          top: 100%;
        `}
  ${({ inline }) =>
    // Do not set top/bottom (as above) when inline
    inline &&
    css`
      top: auto;
      bottom: auto;
    `}
`;

export const Menu = (props) => {
  const {
    placement: menuPlacement,
    children,
    className,
    cx,
    innerRef,
    innerProps,
    selectProps: {
      menuOnly,
      menuInline,
      menuTopButton,
      menuLeftButton,
      menuRightButton,
      menuPlacement: rawMenuPlacement,
      setComputedMenuPlacement,
    },
  } = props;
  useEffect(() => {
    // This is computed specifically for Menu, but we want Control to have access too
    setComputedMenuPlacement(menuPlacement);
  }, [setComputedMenuPlacement, menuPlacement]);
  const placement = menuPlacement === 'top' ? 'top' : 'bottom';
  return (
    <StyledMenu
      // The ref is used for react-select's menu placement. We only want to respect this when explicitly set to "auto."
      ref={rawMenuPlacement === 'auto' ? innerRef : null}
      className={cx({ menu: true }, className)}
      placement={menuOnly ? null : placement}
      inline={menuInline}
      // Allow click handlers to access props, e.g. to clear value
      topButton={enrichEl(menuTopButton, { select: props })}
      leftButton={enrichEl(menuLeftButton, { select: props })}
      rightButton={enrichEl(menuRightButton, { select: props })}
      {...innerProps}
    >
      {children}
    </StyledMenu>
  );
};

Menu.propTypes = {
  placement: PropTypes.oneOf(['bottom', 'top']).isRequired,
  cx: PropTypes.func.isRequired,
  innerRef: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export const MenuList = ({ children, innerRef }) => {
  return <BaseMenuList ref={innerRef}>{children}</BaseMenuList>;
};

export const Option = ({
  innerProps,
  isSelected,
  isFocused,
  isDisabled,
  data,
  selectProps: { isColored, onMountOption, inputValue },
  children,
}) => {
  const itemWrapper = useRef(null);
  const needUpdateRef = useRef(false);

  useEffect(() => {
    needUpdateRef.current = false;
  }, [inputValue]);

  useEffect(() => {
    const wrapper = itemWrapper.current;
    if (wrapper && !needUpdateRef.current && onMountOption) {
      needUpdateRef.current = true;
      onMountOption({ id: data.value }, wrapper.getBoundingClientRect());
    }
  }, [data.value, onMountOption]);

  if (data.groupPlaceholder) {
    return null;
  }

  return (
    <ListItem
      ref={itemWrapper}
      selected={isSelected}
      focused={isFocused}
      disabled={isDisabled}
      {...innerProps}
    >
      {isColored && <Dot color={data.color} />}
      {children}
    </ListItem>
  );
};

Option.propTypes = {
  innerRef: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export const GroupHeading = ListSubheader;

export const Group = ({ Heading, headingProps, label, children }) => (
  <>
    <Heading {...headingProps}>{label}</Heading>
    {children}
  </>
);

Group.propTypes = {
  Heading: PropTypes.elementType.isRequired,
  headingProps: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
};

export const NoOptionsMessage = ({ children, ...props }) => {
  return (
    <components.NoOptionsMessage {...props}>
      <TextSpan>{children}</TextSpan>
    </components.NoOptionsMessage>
  );
};

export const needsStyleFn = (fieldType) =>
  [
    FIELD_TYPES.Choices.type,
    FIELD_TYPES.Dropdown.type,
    FIELD_TYPES.Status.type,
  ].includes(fieldType);

export const applyStyles = (styles) => {
  const newStyles = Object.entries(styles).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: (provided) => ({ ...provided, ...value }),
    }),
    {}
  );
  return newStyles;
};

const floorFiveteen = (value) => Math.floor(value / 15) * 15 || '00';
const isValueValid = (value) =>
  typeof value === 'string' && value.match(/\d\d:\d\d/);

export const TrackingMenuList = ({
  children,
  getValue,
  options,
  selectProps,
}) => {
  let matchValue = selectProps?.initValue || '12:00';
  const SPLITTER_CHAR = ':';
  const [value] = [].concat(getValue() || []);
  const menuRef = useRef();
  if (isValueValid(value?.value)) {
    const splitValue = value.value.split(SPLITTER_CHAR);
    splitValue[1] = floorFiveteen(splitValue[1]);
    matchValue = splitValue.join(SPLITTER_CHAR);
  }

  useEffect(() => {
    const index = Math.max(
      0,
      options.findIndex((opt) => opt.value === matchValue)
    );
    const children = menuRef?.current?.childNodes;
    if (children) {
      menuRef.current.scrollTop = Math.max(0, children[index]?.offsetTop);
    }
  }, [matchValue, options]);
  return <BaseMenuList ref={menuRef}>{children}</BaseMenuList>;
};

TrackingMenuList.propTypes = {
  children: PropTypes.array.isRequired,
  getValue: PropTypes.func.isRequired,
  option: PropTypes.array.isRequired,
};

// The below two components were copied (and edited) from react-app just to get DateTime
// time field placeholders to show again - the original purpose for SingleValue isn't used
const TextEllipsisUnset = styled(TextEllipsis)`
  // Relative fontsizing was leading to incorrectly enlarged font size
  font-size: unset;

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      color: ${grayScale.mediumDark};
      margin-top: 3px;
    `}
`;
export const SingleValueEllipse = ({ children, ...props }) => {
  return (
    <SingleValue className="withEclipse" {...props}>
      {children ? (
        <TextEllipsisUnset>{children}</TextEllipsisUnset>
      ) : (
        <TextEllipsisUnset as="div" isPlaceholder={true}>
          {props?.selectProps?.placeholder ?? ''}
        </TextEllipsisUnset>
      )}
    </SingleValue>
  );
};
